import React, { useRef } from "react";
import styles from "./ReturnTypeBox.module.scss";
import { Form } from "react-bootstrap";
import useClickAway from "src/hooks/useClickAway";
import {
  ReturnMethodType,
  showInstruction,
  updateReturnMethodType,
  validateChanges,
} from "src/store/slices/initiateReturn/step14Configuration.slice";
import { useAppDispatch } from "src/store/store";
function ReturnTypeBox(props: {
  enabled: boolean;
  message: string;
  returnMethodId: string;
  returnMethodType: ReturnMethodType;
  title: string;
  errorMessage: string | null;
}) {
  const dispatch = useAppDispatch();
  const shipWithCarrierInputRef = useRef(null);
  useClickAway(shipWithCarrierInputRef, () => {
    dispatch(
      showInstruction({
        showInstruction: false,
        selectedId: props.returnMethodId,
        type: "Label",
      })
    );
  });

  return (
    <div className="mt-3">
      <div className={`px-3 py-2 ${styles.formBox} `}>
        <div className={`d-flex flex-column flex-md-row  cursor-pointer justify-content-between ${props.enabled ? "mb-3":"" }`}>
          <div className={`d-flex align-items-center`}>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                role="switch"
                id={"checkbox" + props.returnMethodType}
                checked={props.enabled}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onChange={() => {
                  dispatch(
                    updateReturnMethodType({
                      message: "",
                      returnMethodId: props.returnMethodId,
                      type: props.returnMethodType,
                    })
                  );
                  dispatch(
                    validateChanges()
                  );
                }}
              />
            </div>
          <label
            htmlFor={"checkbox" + props.returnMethodType}
            className={`${styles.actionHead}`}
            style={
              props.enabled
                ? {
                    color: "#0B68BB",
                    fontWeight: "normal",
                  }
                : {
                    color: "#000000",
                    fontWeight: "normal",
                  }
            }
          >
            {" "}
            {props.title}
          </label>
          </div>
          {/* Dec 20 Adding arrow icon as per xd */}
          {props.enabled && 
          <span className="d-flex align-items-center justify-content-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="9.793"
              height="6.324"
              viewBox="0 0 9.793 6.324"
            >
              <path
                id="Icon_awesome-angle-down"
                data-name="Icon awesome-angle-down"
                d="M4.379.216.216,4.379a.732.732,0,0,0,0,1.038l.692.692a.732.732,0,0,0,1.038,0L4.9,3.158,7.848,6.108a.732.732,0,0,0,1.038,0l.692-.692a.732.732,0,0,0,0-1.038L5.414.216A.728.728,0,0,0,4.379.216Z"
                fill="#0b68bb"
              />
            </svg>
          </span>
          }
        </div>
        {props.enabled ? (
          <>
            <div className="mt-2">
              <h3 className={` ${styles.lineText}`}>
                What messaging would you like to be displayed to the customer
                for this?{" "}
              </h3>
              <div>
                <Form.Control
                  ref={shipWithCarrierInputRef}
                  placeholder={`Type your instruction`}
                  as="textarea"
                  rows={3}
                  className={`my-2 ${styles.infoText} ${props.enabled && props.errorMessage ? "border border-danger" : ""}`}
                  value={props.message}
                  onChange={(e) => {
                    dispatch(
                      updateReturnMethodType({
                        message: e.target.value,
                        returnMethodId: props.returnMethodId,
                        type: props.returnMethodType,
                      })
                    );
                    dispatch(
                      validateChanges()
                    );
                  }}
                  onClick={() => {
                    dispatch(
                      showInstruction({
                        showInstruction: true,
                        selectedId: props.returnMethodId,
                        type: "Label",
                      })
                    );
                  }}
                />
                { props.enabled && props.errorMessage ? (
                  <p className={`${styles.errorStyle}`}>{props.errorMessage}</p>
                ) : null}
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default ReturnTypeBox;
