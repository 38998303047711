import { useCallback, useMemo, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { MdDelete } from "react-icons/md";
import dots from "src/assets/images/dots.svg";
import { StringListValueType } from "src/features/ReturnAutoWorkFlow/ReturnAutoWorkFlow.types";
import styles from "./StringList.module.scss";

const StringListInput = ({
  value,
  onChange,
  showErrors,
  isDuplicate,
  placeholder = "Enter value...",
}: {
  value: string;
  onChange: (val: string) => void;
  showErrors: boolean;
  isDuplicate: boolean;
  placeholder?: string;
}) => {
  const isError = useMemo(() => {
    return showErrors && (!value.trim() || isDuplicate);
  }, [showErrors, value, isDuplicate]);

  /**
   * Handles changes in the text input field.
   *
   * @param e - The change event from the input field.
   */
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      onChange(e.target.value);
    },
    [onChange, value],
  );

  return (
    <input
      type="text"
      placeholder={placeholder}
      className={`px-2 py-1 ${styles.stringListInput} ${
        isError ? "border-danger" : ""
      }`}
      value={value}
      onChange={handleChange}
    />
  );
};

const StringList = ({
  value,
  onChange,
  showErrors,
  stringListAddButtonLabel = "Add More",
  placeholder,
}: {
  value: StringListValueType[];
  onChange: (val: StringListValueType[]) => void;
  showErrors: boolean;
  stringListAddButtonLabel?: string;
  placeholder?: string;
}) => {
  const reorderList = useCallback(
    (sourceIndex: number, destinationIndex: number) => {
      const updatedList = Array.from(value);
      const [removed] = updatedList.splice(sourceIndex, 1);
      updatedList.splice(destinationIndex, 0, removed);
      onChange(updatedList);
    },
    [onChange, value],
  );

  const updateValue = useCallback(
    (index: number, newValue: string) => {
      const updatedList = [...value];
      updatedList[index] = { id: null, value: newValue };
      onChange(updatedList);
    },
    [onChange, value],
  );

  const handleDelete = useCallback(
    (index: number) => {
      const updatedList = value.filter((_, i) => i !== index);
      onChange(updatedList);
    },
    [onChange, value],
  );

  const handleAddMoreItems = useCallback(() => {
    const updatedList = [...value];
    updatedList.push({ id: null, value: "" });
    onChange(updatedList);
  }, [onChange, value]);

  // Calculate duplicates and map to their index positions
  const duplicates = useMemo(() => {
    const valueCounts: Record<string, number> = {};
    const duplicateIndices: Record<number, boolean> = {};
    // Count occurrences of each value
    value.forEach((item, index) => {
      const trimmedValue = item.value.trim();
      if (trimmedValue) {
        valueCounts[trimmedValue] = (valueCounts[trimmedValue] || 0) + 1;
      }
    });
    // Mark indices of duplicated items
    value.forEach((item, index) => {
      if (valueCounts[item.value.trim()] > 1) {
        duplicateIndices[index] = true;
      }
    });

    return duplicateIndices;
  }, [value]);

  return (
    <div>
      <div
        className={`d-flex align-items-center mb-0 mt-2 w-100 ${styles.stringListWrapper}`}
      >
        <DragDropContext
          onDragEnd={(result) => {
            if (!result.destination) {
              return;
            }

            if (
              result.destination.droppableId === result.source.droppableId &&
              result.destination.index === result.source.index
            ) {
              return;
            }

            reorderList(result.source.index, result.destination.index);
          }}
        >
          <Droppable droppableId="droppable">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {value.map((listValue, idx) => {
                  return (
                    <div key={idx}>
                      <Draggable
                        key={listValue.id ?? ""}
                        draggableId={listValue.id ?? ""}
                        index={idx}
                        isDragDisabled={listValue.id === null}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div
                              className={`px-3 py-3 mb-2 d-flex justify-content-between align-items-center ${styles.stringListBox}`}
                            >
                              <img
                                src={dots}
                                className={`pe-2`}
                                alt=""
                              />
                              <StringListInput
                                showErrors={showErrors}
                                isDuplicate={duplicates[idx] == true}
                                value={listValue.value}
                                onChange={(value) => updateValue(idx, value)}
                                placeholder={placeholder}
                              />
                              {value.length > 1 ? (
                                <MdDelete
                                  style={{
                                    color: "#707070",
                                    fontSize: "21px",
                                  }}
                                  className="ms-2"
                                  role="button"
                                  onClick={() => handleDelete(idx)}
                                />
                              ) : (
                                <div
                                  className="ms-2"
                                  style={{ width: "1em" }}
                                ></div>
                              )}
                            </div>

                            {/* Show error for specific duplicate items */}
                            {showErrors && duplicates[idx] ? (
                              <div className={`${styles.errorText}`}>
                                Please enter a unique reason
                              </div>
                            ) : null}
                          </div>
                        )}
                      </Draggable>
                    </div>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <div
        onClick={handleAddMoreItems}
        className={`d-flex align-items-center mt-2 pb-2 cursor-pointer ${
          value.some((reason) => reason.value.trim().length === 0)
            ? styles.disabled
            : ""
        }`}
      >
        <div
          className={`d-flex align-items-center justify-content-center me-2 me-md-0 mb-2 ${styles.addMore}`}
        >
          +
        </div>
        <div className={`${styles.blueText} ms-2`}>
          {stringListAddButtonLabel}
        </div>
      </div>
    </div>
  );
};

export default StringList;
