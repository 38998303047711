import { useCallback, useMemo, useRef, useState } from "react";
import Select, {
  GroupBase,
  NoticeProps,
  OptionProps,
  StylesConfig,
} from "react-select";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { EAutomationType } from "src/routes/BotSettings/Children/AutomationList/AutomationList";
import { createCategoryService } from "src/services/Automation/DispatchRulesConfigModal/createCategory.service";
import { DispatchRuleCategory } from "src/services/Automation/DispatchRulesConfigModal/getAllCategory.service";
import { IssueDescriptionDetail } from "src/services/Automation/DispatchRulesConfigModal/getDispatchRuleConfigById.service";
import { v4 as uuid } from "uuid";
import { useDispatchSectionContext } from "../../Hooks/useDispatchSectionContext";
import styles from "./IssueDispatchCategories.module.scss";
import useIssueDispatchCategories from "./useIssueDispatchCategories";
import useClickAway from "src/hooks/useClickAway";

const IS_MULTI = true;
interface Option {
  value: string;
  label: string;
  isAddCategory?: boolean;
}

const AddCategoryForm = ({
  type,
  onSave,
  onClose,
  initialName,
  formRef,
}: {
  onSave: (category: DispatchRuleCategory) => void;
  type: EAutomationType;
  onClose: () => void;
  initialName: string;
  formRef: React.MutableRefObject<HTMLDivElement | null>;
}) => {
  const id = uuid();
  const [name, setName] = useState<string>(initialName);
  const [description, setDescription] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);

  const addCategory = useCallback(async () => {
    if (name.trim() === "" || description.trim() === "") {
      setShowError(true);
      return;
    }

    try {
      setIsLoading(true);
      const payload = { name, description };
      const id = await createCategoryService({
        type,
        payload,
      });
      onSave({ id, name: payload.name, description: payload.description });
      onClose();
    } catch (err) {
      pushTheToast({
        position: "top-right",
        type: "danger",
        text: typeof err === "string" ? err : "Cannot create categories",
      });
    } finally {
      setIsLoading(false);
    }
  }, [description, name, onClose, onSave, type]);

  return (
    <div
      className={`${styles.categoryForm}`}
      ref={formRef}
    >
      <div className="mb-2">
        <label
          htmlFor={`addName_${id}`}
          className={`form-label ${styles.formLabel}`}
        >
          Category name
        </label>
        <input
          type="email"
          className={`form-control ${styles.inputBox} ${
            showError && name.trim() === "" ? "border-danger" : ""
          }`}
          id={`addName_${id}`}
          placeholder="Add category name"
          value={name}
          disabled={isLoading}
          onChange={(e) => {
            setName(e.target.value);
            setShowError(false);
          }}
        />
      </div>
      <div className="mb-4">
        <label
          htmlFor={`addDescription_${id}`}
          className={`form-label ${styles.formLabel}`}
        >
          Category description
        </label>
        <textarea
          className={`form-control ${styles.textBox} ${
            showError && description.trim() === "" ? "border-danger" : ""
          }`}
          id={`addDescription_${id}`}
          placeholder="Add category description"
          disabled={isLoading}
          rows={3}
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
            setShowError(false);
          }}
        ></textarea>
      </div>
      <div className="d-flex align-items-center">
        <button
          className={`me-3 ${styles.addBtn}`}
          onClick={addCategory}
          disabled={isLoading}
        >
          Add category
        </button>
        <button
          className={`${styles.cancelBtn}`}
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

const addCategoryOption = {
  value: "",
  label: `+ Add new category "Live chat issues"`,
  isAddCategory: true,
};

const NoOptionComponent: React.ComponentType<
  NoticeProps<Option, true, GroupBase<Option>>
> = (props) => {
  return (
    <div
      className={`${styles.addCategory} px-4 cursor-pointer`}
      onClick={() => {
        props.selectOption(addCategoryOption);
      }}
    >
      <span className="me-2">+</span>
      <span>
        Add new category{" "}
        {props.selectProps.inputValue
          ? `"${props.selectProps.inputValue}"`
          : ""}
      </span>
    </div>
  );
};

const Category = ({
  idx,
  desc,
  onAddCategory,
  setShowAddCategory,
  onSelectCategory,
  showAddCategory,
  automationType,
  optionsMap,
  fetchNextPageHandler,
  showLoader,
  showErrors,
  optionIds,
}: {
  idx: number;
  desc: IssueDescriptionDetail;
  showAddCategory: number | null;
  setShowAddCategory: React.Dispatch<React.SetStateAction<number | null>>;
  onAddCategory: (category: DispatchRuleCategory) => void;
  onSelectCategory: (issueIdx: number, categoryIds: string[]) => void;
  automationType: EAutomationType;
  optionsMap: Record<string, DispatchRuleCategory>;
  fetchNextPageHandler: () => void;
  showLoader: boolean;
  showErrors: boolean;
  optionIds: string[];
}) => {
  const [initialValue, setInitialValue] = useState("");

  const addFormRef = useRef<HTMLDivElement | null>(null);
  useClickAway(addFormRef, () => setShowAddCategory(null));

  const allOptions = useMemo(() => {
    return optionIds.map(
      (val) =>
        ({
          value: optionsMap[val].id,
          label: optionsMap[val].name,
        }) as Option,
    );
  }, [optionIds, optionsMap]);

  const selectedValue: Option[] = useMemo(() => {
    return (
      desc.categoryDetails?.map(
        (val) => ({ label: val.name, value: val.id }) as Option,
      ) ?? []
    );
  }, [desc.categoryDetails]);

  const OptionComponent: React.ComponentType<
    OptionProps<Option, true, GroupBase<Option>>
  > = useCallback(
    (props) => {
      return (
        <div
          className={`d-flex align-items-center mx-3 ${styles.optionWrapper}`}
        >
          <input
            type="checkbox"
            checked={props.isSelected}
            className={`form-check-input ${styles.optionCheckbox} cursor-pointer`}
            onChange={(e) => {
              if (e.target.checked) {
                props.selectOption(props.data);
              } else {
                const newValue = [...selectedValue].filter(
                  (val) => val.value !== props.data.value,
                );
                props.setValue(newValue, "deselect-option");
              }
            }}
          />
          <span className={`ms-2 ${styles.optionName}`}>
            {props.data.label}
          </span>
        </div>
      );
    },
    [selectedValue],
  );

  const customErrorStyles = useMemo(() => {
    const styles = { ...customStyles };

    if (showErrors && selectedValue.length === 0) {
      styles.control = (provided, _) => ({
        ...provided,
        backgroundColor: "#fff",
        borderColor: "#FF0000",
        minHeight: "32px",
        height: "32px",
        display: "flex",
        alignItems: "center",
        boxShadow: "none",
        borderRadius: "5px",
        font: "normal normal normal 12px/18px Poppins",
        letterSpacing: "0px",
        color: "#000000",
        paddingLeft: "32px",
        "&:hover": {
          borderColor: "#FF0000",
        },
      });
    }

    return styles;
  }, [selectedValue.length, showErrors]);

  return (
    <div className="d-flex align-items-center w-100 mb-4">
      <span className={`me-3 ${styles.dropText}`}>
        Issue {idx + 1}: {desc.description}
      </span>
      <div style={{ width: "67%" }}>
        <div className={`position-relative`}>
          {showAddCategory === idx && (
            <AddCategoryForm
              onClose={() => setShowAddCategory(null)}
              onSave={onAddCategory}
              type={automationType}
              initialName={initialValue}
              formRef={addFormRef}
            />
          )}
          <div className={`position-relative`}>
            <Select
              styles={customErrorStyles}
              value={selectedValue}
              onInputChange={(val, { action, prevInputValue }) => {
                if (action === "set-value") {
                  setInitialValue(prevInputValue);
                }
              }}
              options={allOptions}
              placeholder={"Search Issue category"}
              hideSelectedOptions={false}
              onChange={(value, action) => {
                if (action.option?.isAddCategory) {
                  setShowAddCategory(idx);
                  return;
                }

                const values = value.map((v) => v.value);
                onSelectCategory(idx, values);
              }}
              isSearchable={true}
              onMenuScrollToBottom={fetchNextPageHandler}
              closeMenuOnSelect={false}
              isLoading={showLoader}
              isMulti={IS_MULTI}
              components={{
                DropdownIndicator: () => null, // Hides the arrow
                ClearIndicator: () => null, // Hides the clear indicator (global close)
                IndicatorSeparator: () => null, // Hides the separator
                NoOptionsMessage: NoOptionComponent,
                Option: OptionComponent,
              }}
            />
            <span className={`${styles.searchIcon}`}>
              <i className="fa-solid fa-magnifying-glass"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const IssueDispatchCategories = () => {
  const { state, automationType, updateState, showErrors, setShowErrors } =
    useDispatchSectionContext();
  const { issueDescription } = state;
  const [showAddCategory, setShowAddCategory] = useState<number | null>(null);
  const {
    optionsIds,
    optionsMap,
    onAddCategory,
    fetchNextPageHandler,
    showLoader,
  } = useIssueDispatchCategories(automationType);

  const onSelectCategory = useCallback(
    (issueIdx: number, categoryIds: string[]) => {
      const options = categoryIds.map((id) => optionsMap[id]);
      const newIssueDescription = [...issueDescription];
      newIssueDescription[issueIdx].categoryDetails = options;

      updateState({ issueDescription: newIssueDescription });
      setShowErrors(false);
    },
    [issueDescription, optionsMap, setShowErrors, updateState],
  );

  return (
    <div>
      <p className={`mb-4 ${styles.heading}`}>Categorise issues by areas</p>
      <div className={`${styles.categoryBox}`}>
        <h4 className={`mb-1 ${styles.categoryHead}`}>
          Please define the categories under which reported issues should be
          classified?
        </h4>
        <div className={`${styles.categoryBox}`}>
          {issueDescription.map((desc, idx) => {
            return (
              <Category
                automationType={automationType}
                desc={desc}
                idx={idx}
                showErrors={showErrors}
                optionIds={optionsIds}
                optionsMap={optionsMap}
                key={idx}
                onSelectCategory={onSelectCategory}
                fetchNextPageHandler={fetchNextPageHandler}
                showLoader={showLoader}
                onAddCategory={onAddCategory}
                setShowAddCategory={setShowAddCategory}
                showAddCategory={showAddCategory}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

const customStyles: StylesConfig<Option, typeof IS_MULTI, GroupBase<Option>> = {
  control: (provided) => ({
    ...provided,
    backgroundColor: "#fff",
    borderColor: "#707070",
    minHeight: "32px",
    height: "32px",
    display: "flex",
    alignItems: "center",
    boxShadow: "none",
    borderRadius: "5px",
    font: "normal normal normal 12px/18px Poppins",
    letterSpacing: "0px",
    color: "#000000",
    paddingLeft: "32px",
    "&:hover": {
      borderColor: "#707070",
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "0 6px",
    font: "normal normal normal 12px/18px Poppins",
    letterSpacing: "0px",
  }),
  input: (provided) => ({
    ...provided,
    margin: "0px",
    padding: "0 3px",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#6c757d",
  }),
  menu: (provided, state) => ({
    ...provided,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 16px #00000029",
    borderRadius: "5px",
    maxWidth: "299px",
    // padding: state.selectProps.options.length === 0 ? "" : "23px 0px 0 0",
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "#E9E9E9",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    padding: "0px 5px",
    // margin: "2px",
    font: "normal normal normal 12px/18px Poppins",
    letterSpacing: "0px",
    color: "#000000",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: "#000",
    fontWeight: "500",
  }),
  indicatorSeparator: (provided) => ({
    display: "none", // Hides the separator
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: "#000",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#E2E2E2",
      color: "#000",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    font: state.data.isAddCategory
      ? "normal normal normal 12px/16px Poppins"
      : "normal normal 500 12px/18px Poppins",
    letterSpacing: "0px",
    backgroundColor: state.data.isAddCategory
      ? "#e6f0f8"
      : state.isSelected
        ? "#fff"
        : "#fff",
    color: state.data.isAddCategory
      ? "#0b68bb"
      : state.isSelected
        ? "#fff"
        : "#000",
    cursor: "pointer",
    minHeight: state.data.isAddCategory ? "34px" : undefined,
    // "&:hover": {
    //   backgroundColor: state.isSelected ? "#E2E2E2" : "#f8f9fa",
    //   color: state.isSelected ? "#fff" : "#212529",
    // },
  }),
};

export default IssueDispatchCategories;
