import { ICustomizationSetting } from "./customizationSettings.slice";

const fetchCustomizationDataFulfilled = (
  state: ICustomizationSetting,
  { payload }: {payload: any}
) => {
  state = {
    ...state,
    customizationData: payload.customizationData.customization,
    languagesList: payload.languagesList,
    customizationDataAjaxStatus: "fulfilled",
  };
  return state;
};

export default { fetchCustomizationDataFulfilled };
