import { ReactFragment } from "react";
import { Form } from "react-bootstrap";
import { ReturnReason } from "src/store/slices/initiateReturn/step14Configuration.slice";
import styles from "./Condition.module.scss";
import { ConditionTypes } from "src/store/slices/initiateReturn/Helpers/returnExchange.helpers";

function Condition(props: {
  id: string;
  idx: number;
  condition: ConditionTypes | ReturnReason;
  deleteCondition: Function;
  validateCondition: Function;
  children: ReactFragment | JSX.Element;
  hideDelete?: boolean;
}) {
  // Jul 23 Adding codium pr suggestion
  const handleDeleteAndValidate = () => {
    props.deleteCondition(props.condition.id, props.id);
    props.validateCondition(props.id);
  };

  return (
    <div className=" ">
      {props.idx === 0 ? (
        <></>
      ) : (
        <div className="d-flex mt-3  justify-content-center">
          <div className={`px-2 ${styles.devider}`}>AND</div>
        </div>
      )}
      <div
        className={`mt-3 d-flex flex-column flex-lg-row p-3 justify-content-between ${styles.formBox} w-100`}
      >
        <div
          className={`d-flex flex-column flex-lg-row w-100 w-md-75 ${styles.mobView} `}
        >
          {props.children}
        </div>
        {props.hideDelete !== true ? (
          <div onClick={handleDeleteAndValidate}>
            {/* <MdDelete style={{ color: "#707070",width: "14px" ,height: "18px" }} className="" role="button" /> */}
            <span
              style={{ color: "#707070", width: "14px", height: "18px" }}
              className=""
              role="button"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="18"
                viewBox="0 0 14 18"
              >
                <path
                  id="Icon_material-delete"
                  data-name="Icon material-delete"
                  d="M8.5,20.5a2.006,2.006,0,0,0,2,2h8a2.006,2.006,0,0,0,2-2V8.5H8.5Zm13-15H18l-1-1H12l-1,1H7.5v2h14Z"
                  transform="translate(-7.5 -4.5)"
                  fill="#707070"
                />
              </svg>
            </span>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Condition;
