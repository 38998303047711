import React, { useMemo } from "react";
import styles from "./CommentPreview.module.scss";
import helpLogo from "src/assets/images/helpCenter.png";
import user from "src/assets/images/userImg.png";
import useThemeBox from "../../../../../../ArticleSideBar/ThemeSelection/Children/ThemeBox/useThemeBox";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import AxiosImg from "src/components/AxiosImg";
import themeDefaultBackground from "src/assets/images/themeDefaultBackground.png";
import saufterLogo from "src/assets/images/saufter-logo.svg";

type Props = {
  enableComments: boolean;
  reqApproval: boolean;
  approvedComment: boolean;
  unApprovedComment: boolean;
};
// Changed the html and css in this file to dynamic data and styles from backend
const CommentPreview = ({
  enableComments,
  reqApproval,
  approvedComment,
  unApprovedComment,
}: Props) => {
  //Getting data to show theme data dynamically (05 Dec 2023)
  const {
    data,
    parentStyle,
    categoryStyle,
    isLoading,
    updateKBSettings,
    isError,
    isFetching,
  } = useThemeBox();
  // Define a memoized function to get the store name
  const storeName = useMemo(() => {
    // Check if 'data' exists and has a non-null 'storeName' property
    if (data && data?.storeName) {
      // If available, return the 'storeName' property
      return data?.storeName;
    }

    // If 'data' or 'storeName' is not available or is null, return the default store name "Help Center"
    return "Help Center";
  }, [data]); // Re-run the function when the 'data' object changes

  const backgroundImage = useMemo(() => {
    if (data?.backgroundImage) {
      return data?.backgroundImage;
    } else if (data?.themeType === "night_in_the_oscars") {
      return themeDefaultBackground;
    }
    return "";
  }, [data?.backgroundImage, data?.themeType]);

  if (isError) {
    pushTheToast({
      position: "top-right",
      text: "Something went wrong",
      type: "danger",
    });

    return (
      <>
        <div className={`p-2 ${styles.themeWrapper}`}>
          <span className={`${styles.errorMsg}`}>Something Went Wrong</span>
        </div>
      </>
    );
  }

  return (
    <div
      className={`p-2 p-md-3 p-lg-4 ${styles.themeWrapper}`}
      style={{
        filter:
          isLoading || updateKBSettings.isLoading || isFetching
            ? "blur(3px)"
            : "",
        position: "relative",
      }}
    >
      {isLoading || updateKBSettings.isLoading || isFetching ? (
        <div
          style={{
            position: "absolute",
            zIndex: 1,
            top: "50%",
            left: "50%",
          }}
        >
          <div className="spinner-border text-secondary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : null}
      <div
        className={`pt-3 ${styles.previewBox}`}
        style={{
          backgroundColor: `${
            data?.themeType === "night_in_the_oscars" ? "#393939" : "#ffffff"
          }`,
        }}
      >
        <div className="px-3 d-flex align-items-center">
          <div className={`${styles.dots}`}></div>
          <div className={`${styles.dots} ${styles.red}`}></div>
          <div className={`${styles.dots} ${styles.yellow}`}></div>
        </div>
        <div
          className={`mt-2 text-center d-flex flex-column justify-content-center align-items-center ${styles.previewHeader}`}
          style={{
            background: data?.backgroundColor ?? "#0b68bb",
            backgroundImage: `url(${backgroundImage})`, //added default background image
            backgroundSize: "cover",
          }}
        >
          <p className="mb-1">
            {data?.logoImage ? (
              <AxiosImg
                url={data?.logoImage}
                className={`${styles.helpLogo}`}
              />
            ) : (
              <img src={helpLogo} alt="" className={`${styles.helpLogo}`} />
            )}
            <span className={`ms-2 ${styles.acme}`} style={parentStyle}>
              {storeName}
            </span>
          </p>
          <h4 className={`mb-1 ${styles.helpText}`} style={parentStyle}>
            {data?.heading}
          </h4>
          <p className={`${styles.previewName}`} style={parentStyle}>
            {data?.subheading}
          </p>
        </div>
        <div>
          <div className={`d-flex align-items-center ps-4 ${styles.searchBox}`}>
            <span className={`${styles.searchText}`} style={parentStyle}>
              {" "}
              <i className="fa-solid fa-magnifying-glass"></i>{" "}
            </span>
            <span className={`ps-2 ${styles.searchText}`}>
              {data?.placeholder}
            </span>
          </div>
        </div>

        <div className={`p-3 mb-2 ${styles.commentSection}`}>
          {enableComments && (
            <div>
              <div className="ps-2">
                <h4
                  className={`mb-0 ${styles.commentHead} saufter_h5  ${
                    data?.themeType === "night_in_the_oscars"
                      ? "text-white"
                      : ""
                  }`}
                >
                  Comments
                </h4>
                <span
                  className={`${styles.commentsCount} saufter_h5  ${
                    data?.themeType === "night_in_the_oscars"
                      ? "text-white"
                      : ""
                  }`}
                >
                  0 comments
                </span>
              </div>
              <hr />

              <div className="d-flex ps-2">
                <img src={user} alt="" className={`${styles.userImg}`} />
                <div
                  className={`ms-3 p-2 text-center w-100 ${styles.commentBox}`}
                >
                  <>
                    <span className={`position-relative ${styles.caution}`}>
                      <i className="fa-solid fa-circle-exclamation"></i>
                      <span className={`${styles.timer}`}>
                        <i className="fa-regular fa-clock"></i>
                      </span>
                    </span>
                    {reqApproval ? (
                      <>
                        {approvedComment ? (
                          <p
                            className={`mb-0 ${styles.commentPara} saufter_h5`}
                          >
                            Your comment has been approved!
                          </p>
                        ) : unApprovedComment ? (
                          <>
                            <p
                              className={`mb-0 ${styles.commentPara} saufter_h5`}
                            >
                              Your comment has been sent for approval!
                            </p>
                            <span className={`${styles.commentSpan}`}>
                              Once approved will be published immediately...
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      <p className={`mb-0 ${styles.commentPara} saufter_h5`}>
                        Your comment has been published!
                      </p>
                    )}
                  </>
                </div>
              </div>
              {!reqApproval ? (
                <div className="d-flex flex-row-reverse mt-3">
                  <button
                    type="button"
                    className={`btn btn-dark btn-sm ${styles.submitBtn}`}
                  >
                    Submit
                  </button>
                </div>
              ) : null}
            </div>
          )}
        </div>
        <div className={`text-center mt-4 ${styles.footer}`}>
          {data?.helplamaBranding && (
            <p
              className={`mb-1 ${styles.brand} d-flex align-items-center justify-content-center`}
            >
              {/* Dec 05 hided the footer logo and showing the saufter logo */}
              <span
                className={`${
                  data?.themeType === "night_in_the_oscars"
                    ? styles.nightThemeFooter
                    : styles.aliceThemeFooter
                }`}
              >
                Powered by
              </span>
              <div
                className={`mx-1 ${
                  data?.themeType === "night_in_the_oscars" &&
                  styles.nightThemeFooterImg
                }`}
              >
                <img
                  src={saufterLogo}
                  alt=""
                  className={`${styles.helpLogo}`}
                />
              </div>

              <span
                className={`${styles.invertCss} ${
                  data?.themeType === "night_in_the_oscars"
                    ? styles.nightThemeFooterLogo
                    : styles.aliceThemeFooterLogo
                }`}
              >
                {data?.helplamaBrandingText ?? "Saufter.io"}
              </span>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CommentPreview;
