import React, { useContext, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import styles from "../CustomerEdit/CustomerEdit.module.scss";
import messenger from "src/assets/images/messenger-new.png";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { deleteContact } from "src/store/slices/editCustomerDetails/editCustomerDetails.slice";
import useInputFields, {
  InputFieldsContext,
} from "../CustomerEdit/useInputFields";
import AddMore from "../AddMore/AddMore";

function Facebook() {
  const facebook = useAppSelector(
    (state) => state.editcustomerdetails.customer.contacts?.facebook,
  );

  // let defaultValue;
  // if (facebook !== undefined) {
  //   if (facebook?.length > 0) {
  //     defaultValue = facebook[props.idx];
  //   } else {
  //     defaultValue = "";
  //   }
  // }

  const {
    addField,
    updateField,
    removeField,
    setFieldsOfKey,
    fields,
    areDuplicateFields,
  } = useContext(InputFieldsContext);

  useEffect(() => {
    setFieldsOfKey(facebook ?? [], "facebook");
  }, [facebook]);

  const facebookFields = fields.get("facebook");

  const isAddButtonEnabled = facebookFields?.length
    ? facebookFields[facebookFields.length - 1]?.isValid
      ? true
      : false
    : false;

  const { hasDuplicates, duplicateIds } =
    areDuplicateFields.duplicates["facebook"];

  return (
    <>
      {fields.get("facebook")?.map((field, idx) => {
        const hasError = hasDuplicates && duplicateIds.includes(field.id);
        return (
          <div
            className="d-flex flex-column"
            key={field.id}
          >
            {idx === 0 ? (
              <label
                htmlFor="facebook"
                className={`d-flex justify-content-between align-items-center form-label ${styles.labelName} m-0`}
              >
                <div className={`d-flex align-items-center`}>
                  <div
                    className={`d-flex justify-content-center align-items-center ${styles.msgBg}`}
                  >
                    <img
                      src={messenger}
                      className={`${styles.instaImg}`}
                      alt="facebook"
                    />
                  </div>{" "}
                  <div>
                    <span className={`ps-1`}>Facebook</span>
                  </div>
                </div>
              </label>
            ) : (
              <></>
            )}

            <div
              className="d-flex mt-2 "
              style={{ width: "103.5%", overflowX: "hidden" }}
            >
              <input
                data-lpignore="true"
                type="url"
                className={`form-control ${styles.formInput} ${
                  hasError && "border-danger"
                }`}
                id="facebook"
                aria-describedby="emailHelp"
                placeholder="eg. www.facebook.com/user"
                required
                name="Facebook"
                value={field.value as string}
                onChange={(e) => {
                  var regex = /^\s*$/;
                  updateField(
                    field,
                    {
                      ...field,
                      value: e.target.value,
                      isValid: !regex.test(e.target.value),
                    },
                    "facebook",
                  );
                }}
              />
              <div
                className={`${styles.customCrossPosition}`}
                onClick={() => {
                  // dispatch(
                  //   deleteContact({
                  //     id: id as string,
                  //     type: "facebook",
                  //     idx: props.idx,
                  //   })
                  // );
                  removeField(field, "facebook");
                }}
              >
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className={`${styles.toolTipCustom}`}>
                      Remove
                    </Tooltip>
                  }
                >
                  {({ ref, ...triggerHandler }) => (
                    <div
                      className={` `}
                      {...triggerHandler}
                      ref={ref}
                    >
                      <div
                        className={`d-flex justify-content-center align-items-center ${styles.clearText}`}
                      >
                        <i className="fa-solid fa-xmark"></i>
                      </div>
                    </div>
                  )}
                </OverlayTrigger>
              </div>
            </div>
            {hasError && (
              <span className={`${styles.validateSpan}`}>
                Duplicate Facebook ID not allowed
              </span>
            )}
          </div>
        );
      })}
      <span>
        <AddMore
          type="Account"
          isValid={isAddButtonEnabled}
          onClick={() => {
            if (!isAddButtonEnabled) {
              return;
            }
            console.count("addFieldClick");
            addField({ value: "" }, "facebook");
          }}
          isVisible={(fields.get("facebook")?.length ?? 0) > 0}
        />
      </span>
    </>
  );
}

export default Facebook;
