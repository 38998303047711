import styles from "./ViewMsgCommon.module.scss";
import TicketMsg from "../TicketMsg/TicketMsg";
import UserAvatar from "src/components/UserAvatar";
import { getTimeFromNow } from "src/utils/dateLibrary";
import { getChannelFAIcon } from "src/utils/utils";
import AxiosImg from "src/components/AxiosImg";
import { useRef, useState } from "react";
import { MessagesData } from "src/services/LiveChat/liveChatHistory/getLiveChatCustomerAllMessages.service";
import useUTCToUserTime from "src/hooks/dateHooks/useUTCToUserTime";
import { Dropdown } from "react-bootstrap";
import { POPPER_CONFIG } from "src/globals/constants";
import slack from "src/assets/images/slack.png";
const DropDownMenuData = ({
  messageData,
  labelBy,
}: {
  messageData: MessagesData;
  labelBy: string;
}) => {
  const generatedDate = useUTCToUserTime({
    timeInUTC: messageData.messageDateTimeUTC,
  });

  return (
    <Dropdown.Menu
      bsPrefix={`dropdown-menu ${styles.dropWrapper}`}
      popperConfig={POPPER_CONFIG}
    >
      <span className={`${styles.customDropdownItem}`}>
        <span>{generatedDate}</span>
      </span>
      {messageData.to && (
        <span className={`${styles.customDropdownItem} d-flex`}>
          <span className={`me-1 ${styles.prefixOfEmail}`}>To:</span>
          <span className={`${styles.customDropdownItemInner}`}>
            <span className={`${styles.sendMsgCC}`}>{messageData.to}</span>
          </span>
        </span>
      )}
      {messageData.from && (
        <span className={`${styles.customDropdownItem} d-flex`}>
          <span className={`me-1 ${styles.prefixOfEmail}`}>From:</span>
          <span className={`${styles.customDropdownItemInner}`}>
            <span className={`${styles.sendMsgCC}`}>{messageData.from}</span>
          </span>
        </span>
      )}
      {messageData.bcc.length > 0 && (
        <span className={`${styles.customDropdownItem} d-flex`}>
          <span className={`me-1 ${styles.prefixOfEmail}`}>Bcc:</span>
          <span
            className={`${styles.customDropdownItemInner} d-flex flex-column`}
          >
            {messageData.bcc.map((bcc, index) => {
              const comma = messageData.bcc.length > index + 1 ? "," : "";
              return (
                <span key={index} className={`${styles.sendMsgCC}`}>
                  {bcc}
                  {comma}
                </span>
              );
            })}
          </span>
        </span>
      )}
      {messageData.cc.length > 0 && (
        <span className={`${styles.customDropdownItem} d-flex`}>
          <span className={`me-1 ${styles.prefixOfEmail}`}>Cc:</span>
          <span
            className={`${styles.customDropdownItemInner} d-flex flex-column`}
          >
            {messageData.cc.map((cc, index) => {
              const comma = messageData.cc.length > index + 1 ? "," : "";
              return (
                <span key={index} className={`${styles.sendMsgCC}`}>
                  {cc}
                  {comma}
                </span>
              );
            })}
          </span>
        </span>
      )}
    </Dropdown.Menu>
  );
};

const ShowSentDetails = ({
  messageData,
  rightAlign,
}: {
  messageData: MessagesData;
  rightAlign: boolean;
}) => {
  const [ddOpen, setDdOpen] = useState(false);
  const dropdownArrowSpanRef = useRef<any>();
  const dropdownRef = useRef<any>();

  return (
    <span className={`${styles.created}`}>
      <div
        className={`dropdown d-flex ${
          rightAlign ? "justify-content-end" : "justify-content-start"
        }`}
        ref={dropdownRef}
      >
        <div>
          {/* <button
            className={`btn dropdown-toggle ${styles.sendDrop} ${styles.dropdownToggle} `}
            type={`button`}
            id={`dropdownMenuButton2`}
            data-bs-toggle={`dropdown`}
            aria-expanded={`false`}
          >
            {`${getTimeFromNow({
              date: new Date(messageData.messageDateTimeUTC),
            })}${
              messageData.dataType === "Message" && messageData.from
                ? `, Via: ${messageData.from}`
                : ""
            }`}
            <span ref={dropdownArrowSpanRef}>
              <i
                className={`ms-1 fa-solid fa-caret-${ddOpen ? "up" : "down"}`}
              ></i>
            </span>
          </button>
          <DropDownMenuData
            messageData={messageData}
            labelBy={"dropdownMenuButton2"}
          /> */}
          <Dropdown onToggle={setDdOpen}>
            <Dropdown.Toggle
              id="dropdown-basic"
              as="div"
              bsPrefix={`dropdown-toggle cursor-pointer ${styles.sendDrop} ${styles.dropdownToggle}`}
            >
              {`${getTimeFromNow({
                date: new Date(messageData.messageDateTimeUTC),
              })}${
                messageData.dataType === "Message" && messageData.from
                  ? `, Via: ${messageData.from}`
                  : ""
              }`}
              <span ref={dropdownArrowSpanRef}>
                {" "}
                {ddOpen ? (
                  <i
                    className={`fa-solid fa-caret-up
                        `}
                  ></i>
                ) : (
                  <i
                    className={`fa-solid fa-caret-down
                          `}
                  ></i>
                )}
              </span>
            </Dropdown.Toggle>
            <DropDownMenuData
              messageData={messageData}
              labelBy={"dropdownMenuButton2"}
            />
          </Dropdown>
        </div>
      </div>
    </span>
  );
};

const ViewMsgCommon = (messageData: MessagesData) => {
  const rightAlign = messageData.sentBy === "agent";
  const avatarName = messageData.senderName;
  const avatarURL = messageData.senderImgURL;

  return (
    <div
      className={`d-flex justify-content-start align-items-center ${
        rightAlign ? "flex-row-reverse" : ""
      } ${styles.msgMargin} ${rightAlign ? styles.right : styles.left} ${
        styles.mSet
      }`}
    >
      <div
        className={`d-flex flex-column align-items-center ${styles.profileSec}`}
      >
        <div>
          {avatarURL ? (
            <AxiosImg
              url={avatarURL}
              className={`${styles.profileIcon} rounded-circle`}
              isDirectURL={messageData.isPublicAttachmentUrl}
            />
          ) : (
            <UserAvatar
              className={`${styles.profileIcon}`}
              name={avatarName || "NA"}
              size={35}
            />
          )}
        </div>
        {/* <span className={`dropdown ${styles.insta}`}>
          <i
            className={`d-block cursor-pointer dropdown-toggle ${getChannelFAIcon(
              messageData.dataType === "Note"
                ? "note"
                : messageData.channel.name
            )} override ${styles.dropdownToggle}`}
            id={`dropdownMenuButton1`}
            data-bs-toggle={`dropdown`}
            aria-expanded={`false`}
          ></i>
          {messageData && (
            <DropDownMenuData
              messageData={messageData}
              labelBy={"dropdownMenuButton1"}
            />
          )}
        </span> */}
        <Dropdown>
          <Dropdown.Toggle
            id="dropdown-basic"
            as="span"
            bsPrefix={`dropdown me-1 ${styles.insta}`}
          >
            {messageData?.channel?.name === "Slack" ? ( // if channel is slack show this image
              <img
                src={slack}
                alt="slack channel"
                width={12}
                height={12}
              />
            ) : (
              <i
                className={`d-block cursor-pointer dropdown-toggle ${getChannelFAIcon(
                  messageData.dataType === "Note"
                    ? "note"
                    : messageData.channel.name,
                )} override ${styles.dropdownToggle}`}
                id={`dropdownMenuButton1`}
                data-bs-toggle={`dropdown`}
                aria-expanded={`false`}
              ></i>
            )}
          </Dropdown.Toggle>
          {messageData && (
            <DropDownMenuData
              messageData={messageData}
              labelBy={"dropdownMenuButton1"}
            />
          )}
        </Dropdown>
      </div>
      <div className={`mx-3 ${styles.messageParentEle}`}>
        <TicketMsg
          messageData={messageData}
          rightAlign={rightAlign}
        />
        <ShowSentDetails
          messageData={messageData}
          rightAlign={rightAlign}
        />
      </div>
    </div>
  );
};

export default ViewMsgCommon;
