import { axiosJSON } from "src/globals/axiosEndPoints";
import { IFilterOption } from "../SideBar/getTicketsCountInSideBar";
export interface ITicketIdListParam {
  filters: {
    tableType: string;
    options?: Array<IFilterOption>;
    searchOptions?: {
      value: string;
      keys: string[];
    };
    viewId?: number;
  };
  ticketId: number;
  limit?: number;
}
export async function getAllTicketsIdList(payload: ITicketIdListParam) {
  const { data } = await axiosJSON.post(`/api/ticket/ticketIdList`, payload);
  if (data.err === true) {
    throw new Error(data.msg as string);
  }

  return data.data as Array<number|string>;
}
