import { axiosJSON } from "src/globals/axiosEndPoints";

export interface EmailSetupDetails {
  brandId: string;
  brandName: string;
  brandOutboundEmail: string;
  verificationDone: boolean;
  firstEmailSent: boolean;
  emailsForwardedTo: string;
  emailIntegrationId: string;
  isEmailAdded: boolean;
  isEmailVerified: boolean;
  isDomainVerified: boolean;
  isDomainAddedForAuth: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  domainAuthData: Array<any>;
  emailIntegrationChannel: string;
  mailType: {
    id: string;
    typeName: string;
  };
}

export interface EmailSetupData {
  setupData: EmailSetupDetails | null;
  isCompleted: true;
}

/**
 * Interface for Brands in Live Chat
 */
interface ILiveChatBrand {
  id: number;
  name: string;
  email: string;
  signature: string;
  enabled: boolean;
  isDefault: boolean;
  isActive: boolean;
  isVerified: boolean;
  imageURL: string | null;
}

/**
 * Interface for the structure of the Live Chat setup details
 */
export interface ILiveChatSetupDetails {
  integration_id: string;
  store_url: string;
  embedded_script: string;
  live_chat_enabled: boolean;
  installed_successfully: boolean;
  integration_web_socket_channel_name: string;
  brand: ILiveChatBrand;
  website_name: string;
  domain_branding_name: string;
  widget_url?: string;
}

export interface LiveChatSetupData {
  setupData: ILiveChatSetupDetails | null;
  isCompleted: boolean;
}

/**
 * Interface for the Setup Details Response
 */
export interface SetupDetails {
  emailSetup: EmailSetupData;
  liveChatSetup: LiveChatSetupData;
  trainingBotSetup: {
    isCompleted: boolean;
    botProfileId?: string;
    emailBotIsCompleted: boolean;
    emailBotProfileId: string | null;
  };
}

/**
 * Service for getting setup details
 */
const getTodoListService = async () => {
  const { data: res } = await axiosJSON.get("/api/firstLogin/getTodoList");

  // Checking for errors in the response
  if (res.err || res.error) {
    // Throwing an error with a custom message if an error is detected in the response
    throw res.msg ?? res.message ?? "Something went wrong!";
  }

  return res as SetupDetails;
};

export default getTodoListService;
