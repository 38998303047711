import { useState } from "react";
import { Collapse } from "react-bootstrap";
import styles from "../../CSSideBar.module.scss";

const NavItemToggleList = ({
  children,
  id,
  title,
  isHeading = false,
  initialOpenState = false,
}: {
  children: React.ReactNode;
  id: string;
  title: string;
  isHeading?: boolean;
  initialOpenState?: boolean;
}) => {
  const [toggle, setToggle] = useState<boolean>(initialOpenState);

  return (
    <>
      {/* Toggle for Sub List */}
      <div
        className={`${styles.nav_link} ${
          isHeading && styles.navLinkHeading
        } px-2 cursor-pointer d-flex align-items-center justify-content-between`}
        onClick={() => setToggle((prev) => !prev)}
        id={`${id}_toggle_btn`}
        aria-expanded={toggle}
        aria-controls={`${id}_collapse_div`}
      >
        <span className={`w-100 cursor-pointer`}>{title}</span>
        <i className={`fa ${toggle ? "fa-angle-up" : "fa-angle-down"}`} />
      </div>

      {/* Collapse List */}
      <Collapse in={toggle}>
        <div id={`${id}_collapse_div`}>{children}</div>
      </Collapse>
    </>
  );
};

export default NavItemToggleList;
