/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import {
  IAssignMultipleAgentsRes,
  assignMultipleAgents,
} from "src/services/LiveChat/messageExchange/assignMultipleAgents";
import { Customer } from "src/services/LiveChat/messageExchange/getAllCustomers";
import { actions } from "src/store/slices/liveChatSetting/chatWidExchCust/chatWidExchCust.slice";
import {
  fetchCustomerData,
  fetchLiveChatIntegratedBrandsThunk,
} from "src/store/slices/liveChatSetting/chatWidExchCust/chatWidExchCust.thunks";
import {
  resetAssignToAgentList,
  resetChatWidExchMsg,
  setActiveCustomerInfo,
} from "src/store/slices/liveChatSetting/chatWidExchMsg/chatWidExchMsg.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { MouseEvent } from "react";

interface Props {
  showDropdown: boolean;
  setShowDropdown: React.Dispatch<React.SetStateAction<boolean>>;
}

function useMouseFilter( { setShowDropdown, showDropdown }: Props ) {
  const dispatch = useAppDispatch();
  const { chatStatus } = useParams();
  const navigate = useNavigate();

  const filterStartDate = useAppSelector(
    ( state ) => state.chatWidExchCust.filterStartDate
  );

  const filterEndDate = useAppSelector(
    ( state ) => state.chatWidExchCust.filterEndDate
  );

  const selectedAgents = useAppSelector( ( state ) => {
    const agentIds = state.chatWidExchCust.idFilterApplied.selectedIds.map(
      ( id ) => parseInt( id + "" )
    );
    return agentIds;
  } );

  const { activeCustomerInfo, selectedAgentIds } = useAppSelector(
    ( state ) => state.chatWidExhMsg
  );

  const brandList = useAppSelector(
    ( state ) => state.chatWidExchCust.liveChatIntegratedBrands.brands
  );

  const brandIdsList = useAppSelector(
    ( state ) => state.chatWidExchCust.liveChatIntegratedBrands.brandIds
  );

  const fetchLiveChatIntegratedBrandAJAXStatus = useAppSelector(
    ( state ) =>
      state.chatWidExchCust.liveChatIntegratedBrands
        .fetchLiveChatIntegratedBrandAJAXStatus
  );

  const appliedBrandIds = useAppSelector(
    ( state ) => state.chatWidExchCust.brandIdsApplied
  );

  const selectedBrands = useAppSelector(
    ( state ) => state.chatWidExchCust.selectedBrandIdsForFilter
  );

  const appliedStartDate = useAppSelector( ( state ) => {
    return state.chatWidExchCust.appliedFilterStartDate;
  } );

  const appliedEndDate = useAppSelector( ( state ) => {
    return state.chatWidExchCust.appliedFilterEndDate;
  } );

  const idFilterApplied = useAppSelector(
    ( state ) => state.chatWidExchCust.idFilterApplied
  );

  const statusApplied = useAppSelector(
    ( state ) => state.chatWidExchCust.statusFilterApplied
  );

  const selectedStatusForFilter = useAppSelector(
    ( state ) => state.chatWidExchCust.selectedStatusForFilter
  );

  const allStatusFilterSelected = useAppSelector( ( state ) => {
    return state.chatWidExchCust.statusAllSelected;
  } );

  const [startDate, setStartDate] = useState<Date | null>( filterStartDate );
  const [endDate, setEndDate] = useState<Date | null>( filterEndDate );

  const [searchStatusQuery, setSearchStatusQuery] = useState( "" );

  const [selectedBrandIds, setSelectedBrandIds] = useState<any[]>(
    selectedBrands ?? []
  );

  const [resolvedChecked, setResolvedChecked] = useState(
    selectedStatusForFilter?.includes( "resolved" ) ? true : false
  );
  const [unresolvedChecked, setUnresolvedChecked] = useState(
    selectedStatusForFilter?.includes( "unresolved" ) ? true : false
  );
  const [unresolvedAndArchivedChecked, setUnresolvedAndArchivedChecked] =
    useState(
      selectedStatusForFilter?.includes( "unresolved_and_archived" )
        ? true
        : false
    );
  const [resolvedAndArchivedChecked, setResolvedAndArchivedChecked] = useState(
    selectedStatusForFilter?.includes( "resolved_and_archived" ) ? true : false
  );
  const [unresolvedAndUnarchivedChecked, setUnresolvedAndUnarchivedChecked] =
    useState(
      selectedStatusForFilter?.includes( "unresolved_and_unarchived" )
        ? true
        : false
    );
  const [selectAllStatus, setSelectAllStatus] = useState( false );
  const [showStatus, setShowStatus] = useState( false );
  const [showDate, setShowDate] = useState( false );

  const [prevState, setPrevState] = useState<
    | "selectAll"
    | "deselectAll"
    | "resolved"
    | "unresolved"
    | "unresolved_and_archived"
    | "resolved_and_archived"
    | "unresolved_and_unarchived"
  >();

  const [searchQuery, setSearchQuery] = useState( "" );

  const [selectAllChecked, setSelectAllChecked] = useState(
    brandList?.length === selectedBrandIds.length
  );

  const [showAsigneeList, setShowAsigneeList] = useState( false );

  const [showBrandList, setShowBrandList] = useState( false );

  const [showValidation, setShowValidation] = useState<{
    startDataValidation?: boolean;
    endDateValidation?: boolean;
  }>( {
    startDataValidation: false,
    endDateValidation: false,
  } );

  const currentState = useRef( {
    activeCustomerInfo,
    selectedAgentIds,
    chatStatus,
  } );

  const handleCancel = useCallback( () => {
    dispatch(
      actions.removeAllAgentForAssignToFilter( { initialValue: selectedAgents } )
    );
  }, [] );

  const handleSubmit = useCallback( () => {
    const payloadAgentIds = currentState.current.selectedAgentIds;
    if ( !currentState.current.activeCustomerInfo?.chatId ) {
      return;
    }

    const chatId = currentState.current.activeCustomerInfo.chatId;
    assignMultipleAgents( {
      chatId: chatId,
      agentIds: payloadAgentIds,
    } )
      .then( ( res: IAssignMultipleAgentsRes ) => {
        // if (res.statusCode === ) {
        const removedAgentIds = res.agentsRemoved;
        const addedAgents = res.agentsAdded;
        const chatAssignedTo = res.chatAssignedTo;

        let toastText = "Chat assigned!";

        if ( addedAgents.length ) {
          const newlyAddedAgents = chatAssignedTo.filter(
            ( agentInfo: { id: any } ) => {
              return addedAgents.includes( agentInfo.id );
            }
          );

          if ( newlyAddedAgents.length === 1 ) {
            toastText = `Assigned to ${newlyAddedAgents[0]?.name}`;
          } else {
            toastText = `Assigned to ${newlyAddedAgents[0]?.name} and others`;
          }
        } else if ( removedAgentIds.length ) {
          if ( chatAssignedTo.length === 0 ) {
            toastText = "Unassigned all agents!";
          } else {
            toastText = "Unassigned Successfully!";
          }
        } else {
          if ( chatAssignedTo.length ) {
            toastText = "Chat assigned!";
          } else {
            toastText = "Unassigned all agents!";
          }
        }

        pushTheToast( {
          position: "top-right",
          type: "success",
          text: toastText,
        } );

        if ( currentState.current.activeCustomerInfo ) {
          const customer: Customer = {
            ...currentState.current.activeCustomerInfo,
          };

          customer["chatAssignedTo"] = res.chatAssignedTo.map(
            ( agentInfo: any ) => {
              return {
                id: agentInfo?.id,
                name: agentInfo?.name,
                imgUrl: agentInfo?.imageURL,
              };
            }
          );

          dispatch(
            actions.setCustomerData( {
              customer: customer,
              checkForExistingCust: true,
            } )
          );
          dispatch( setActiveCustomerInfo( { customerInfo: customer } ) );
        }
        dispatch( resetAssignToAgentList() );
      } )
      .catch( ( err ) => {
        pushTheToast( {
          position: "top-right",
          type: "danger",
          text: "Something went wrong",
        } );
      } );
  }, [selectedAgentIds] );

  const handleOnApply = useCallback( () => {
    //todo validate if there is no change in data
    dispatch( resetChatWidExchMsg() );
    navigate( `/live-chat/chats/${currentState.current.chatStatus}/all` );
    dispatch( actions.selectedFilterIdApplied() );
    dispatch( actions.selectedBrandIdApplied() );
    dispatch( actions.selectedStatusApplied() );
    dispatch( actions.appliedFilterDateToSelectedDate() );
    dispatch( actions.resetCustomerData() );
    dispatch( fetchCustomerData( { start: 0 } ) );
  }, [handleSubmit] );

  const concatinateAgentNamesInitials = useCallback( ( assignedAgents: any ) => {
    return assignedAgents
      .map(
        ( agentInfo: any ) =>
          agentInfo.name
            .split( " " )
            .map( ( word: string ) => word.charAt( 0 ) )
            .join( "" ) ?? "NA"
      )
      .join( ", " );
  }, [] );

  const concatinateAgentNames = useCallback( ( assignedAgents: any ) => {
    return assignedAgents
      .map( ( agentInfo: any ) => agentInfo.name ?? "NA" )
      .join( ", " );
  }, [] );

  const filteredOptions = [
    "Resolved",
    "Unresolved",
    "Unresolved and Archived",
    "Resolved and Archived",
    "Unresolved and Unarchived",
  ].filter( ( option ) =>
    option.toLowerCase().includes( searchStatusQuery.toLowerCase() )
  );

  const handleSelectAll = ( e: { target: { checked: any } } ) => {
    const checked = e.target.checked;
    setResolvedChecked( checked );
    setUnresolvedChecked( checked );
    setResolvedAndArchivedChecked( checked );
    setUnresolvedAndArchivedChecked( checked );
    setUnresolvedAndUnarchivedChecked( checked );
    setSelectAllStatus( checked );
    dispatch( actions.setAllStatusSelected( { isSelected: checked } ) );
    if ( checked ) {
      dispatch( actions.selectedStatusForFilter( { status: ["all"] } ) );
    } else {
      dispatch( actions.selectedStatusForFilter( { status: [] } ) );
    }
  };

  const handleResolved = ( e: { target: { checked: any } } ) => {
    const checked = e.target.checked;
    setResolvedChecked( checked );
    if ( selectAllStatus ) {
      dispatch( actions.setAllStatusSelected( { isSelected: checked } ) );
    } else {
      dispatch( actions.setAllStatusSelected( { isSelected: false } ) );
    }
    if ( !checked ) {
      setSelectAllStatus( false );
      if ( selectedStatusForFilter?.includes( "all" ) ) {
        dispatch( actions.selectedStatusForFilter( { status: ["unresolved"] } ) );
        dispatch( actions.unCheckStatusFilter( { status: "resolved" } ) );
        dispatch(
          actions.selectedStatusForFilter( {
            status: ["unresolved_and_archived"],
          } )
        );
        dispatch(
          actions.selectedStatusForFilter( {
            status: ["unresolved_and_unarchived"],
          } )
        );
        dispatch(
          actions.selectedStatusForFilter( { status: ["resolved_and_archived"] } )
        );
      }
      dispatch(
        actions.unCheckStatusFilter( {
          status: "resolved",
        } )
      );
      if (
        selectedStatusForFilter?.includes( "unresolved" ) ||
        unresolvedChecked
      ) {
        dispatch( actions.selectedStatusForFilter( { status: ["unresolved"] } ) );
      }
      if (
        selectedStatusForFilter?.includes( "resolved_and_archived" ) ||
        resolvedAndArchivedChecked
      ) {
        dispatch(
          actions.selectedStatusForFilter( { status: ["resolved_and_archived"] } )
        );
      }
      if (
        selectedStatusForFilter?.includes( "unresolved_and_archived" ) ||
        unresolvedAndArchivedChecked
      ) {
        dispatch(
          actions.selectedStatusForFilter( {
            status: ["unresolved_and_archived"],
          } )
        );
      }
      if (
        selectedStatusForFilter?.includes( "unresolved_and_unarchived" ) ||
        unresolvedAndUnarchivedChecked
      ) {
        dispatch(
          actions.selectedStatusForFilter( {
            status: ["unresolved_and_unarchived"],
          } )
        );
      }
      if (
        !selectedStatusForFilter.includes( "unresolved" ) &&
        !unresolvedChecked &&
        !selectedStatusForFilter.includes( "resolved_and_archived" ) &&
        !resolvedAndArchivedChecked &&
        !selectedStatusForFilter.includes( "unresolved_and_archived" ) &&
        !unresolvedAndArchivedChecked &&
        !selectedStatusForFilter.includes( "unresolved_and_unarchived" ) &&
        !unresolvedAndUnarchivedChecked
      ) {
        dispatch( actions.selectedStatusForFilter( { status: [] } ) );
      }
    } else {
      if (
        ( unresolvedChecked &&
          unresolvedAndArchivedChecked &&
          unresolvedAndUnarchivedChecked &&
          resolvedAndArchivedChecked ) ||
        ( selectedStatusForFilter?.includes( "unresolved" ) &&
          selectedStatusForFilter?.includes( "unresolved_and_archived" ) &&
          selectedStatusForFilter?.includes( "unresolved_and_unarchived" ) &&
          selectedStatusForFilter?.includes( "resolved_and_archived" ) )
      ) {
        dispatch( actions.selectedStatusForFilter( { status: ["all"] } ) );
        setSelectAllStatus( true );
        dispatch( actions.setAllStatusSelected( { isSelected: true } ) );
      } else {
        dispatch( actions.selectedStatusForFilter( { status: ["resolved"] } ) );
      }
    }
  };

  const handleUnresolved = ( e: { target: { checked: any } } ) => {
    const checked = e.target.checked;
    setUnresolvedChecked( checked );
    if ( selectAllStatus ) {
      dispatch( actions.setAllStatusSelected( { isSelected: checked } ) );
    } else {
      dispatch( actions.setAllStatusSelected( { isSelected: false } ) );
    }
    if ( !checked ) {
      if ( selectedStatusForFilter?.includes( "all" ) ) {
        dispatch( actions.selectedStatusForFilter( { status: ["resolved"] } ) );
        dispatch( actions.unCheckStatusFilter( { status: "unresolved" } ) );
        dispatch(
          actions.selectedStatusForFilter( { status: ["resolved_and_archived"] } )
        );
        dispatch(
          actions.selectedStatusForFilter( {
            status: ["unresolved_and_archived"],
          } )
        );
        dispatch(
          actions.selectedStatusForFilter( {
            status: ["unresolved_and_unarchived"],
          } )
        );
      }
      setSelectAllStatus( false );
      dispatch(
        actions.unCheckStatusFilter( {
          status: "unresolved",
        } )
      );
      if ( selectedStatusForFilter?.includes( "resolved" ) || resolvedChecked ) {
        dispatch( actions.selectedStatusForFilter( { status: ["resolved"] } ) );
      }
      if (
        selectedStatusForFilter?.includes( "resolved_and_archived" ) ||
        resolvedAndArchivedChecked
      ) {
        dispatch(
          actions.selectedStatusForFilter( { status: ["resolved_and_archived"] } )
        );
      }
      if (
        selectedStatusForFilter?.includes( "unresolved_and_archived" ) ||
        unresolvedAndArchivedChecked
      ) {
        dispatch(
          actions.selectedStatusForFilter( {
            status: ["unresolved_and_archived"],
          } )
        );
      }
      if (
        selectedStatusForFilter?.includes( "unresolved_and_unarchived" ) ||
        unresolvedAndUnarchivedChecked
      ) {
        dispatch(
          actions.selectedStatusForFilter( {
            status: ["unresolved_and_unarchived"],
          } )
        );
      }
      if (
        !selectedStatusForFilter.includes( "resolved" ) &&
        !resolvedChecked &&
        !selectedStatusForFilter.includes( "resolved_and_archived" ) &&
        !resolvedAndArchivedChecked &&
        !selectedStatusForFilter.includes( "unresolved_and_archived" ) &&
        !unresolvedAndArchivedChecked &&
        !selectedStatusForFilter.includes( "unresolved_and_unarchived" ) &&
        !unresolvedAndUnarchivedChecked
      ) {
        dispatch( actions.selectedStatusForFilter( { status: [] } ) );
      }
    } else {
      if (
        ( resolvedChecked &&
          resolvedAndArchivedChecked &&
          unresolvedAndArchivedChecked &&
          unresolvedAndUnarchivedChecked ) ||
        ( selectedStatusForFilter?.includes( "resolved" ) &&
          selectedStatusForFilter?.includes( "resolved_and_archived" ) &&
          selectedStatusForFilter?.includes( "unresolved_and_archived" ) &&
          selectedStatusForFilter?.includes( "unresolved_and_unarchived" ) )
      ) {
        dispatch( actions.selectedStatusForFilter( { status: ["all"] } ) );
        setSelectAllStatus( true );
        dispatch( actions.setAllStatusSelected( { isSelected: true } ) );
      } else {
        dispatch( actions.selectedStatusForFilter( { status: ["unresolved"] } ) );
      }
    }
  };

  const handleResolvedAndArchived = ( e: { target: { checked: any } } ) => {
    const checked = e.target.checked;
    setResolvedAndArchivedChecked( checked );
    if ( selectAllStatus ) {
      dispatch( actions.setAllStatusSelected( { isSelected: checked } ) );
    } else {
      dispatch( actions.setAllStatusSelected( { isSelected: false } ) );
    }
    if ( !checked ) {
      if ( selectedStatusForFilter?.includes( "all" ) ) {
        dispatch( actions.selectedStatusForFilter( { status: ["unresolved"] } ) );
        dispatch(
          actions.unCheckStatusFilter( { status: "resolved_and_archived" } )
        );
        dispatch( actions.selectedStatusForFilter( { status: ["resolved"] } ) );
        dispatch(
          actions.selectedStatusForFilter( {
            status: ["unresolved_and_archived"],
          } )
        );
        dispatch(
          actions.selectedStatusForFilter( {
            status: ["unresolved_and_unarchived"],
          } )
        );
      }
      setSelectAllStatus( false );
      dispatch(
        actions.unCheckStatusFilter( {
          status: "resolved_and_archived",
        } )
      );
      if (
        selectedStatusForFilter?.includes( "unresolved_and_archived" ) ||
        unresolvedAndArchivedChecked
      ) {
        dispatch(
          actions.selectedStatusForFilter( {
            status: ["unresolved_and_archived"],
          } )
        );
      }
      if (
        selectedStatusForFilter?.includes( "unresolved_and_unarchived" ) ||
        unresolvedAndUnarchivedChecked
      ) {
        dispatch(
          actions.selectedStatusForFilter( {
            status: ["unresolved_and_unarchived"],
          } )
        );
      }
      if ( selectedStatusForFilter?.includes( "resolved" ) || resolvedChecked ) {
        dispatch( actions.selectedStatusForFilter( { status: ["resolved"] } ) );
      }
      if (
        selectedStatusForFilter?.includes( "unresolved" ) ||
        unresolvedChecked
      ) {
        dispatch( actions.selectedStatusForFilter( { status: ["unresolved"] } ) );
      }
      if (
        !selectedStatusForFilter.includes( "resolved" ) &&
        !resolvedChecked &&
        !selectedStatusForFilter.includes( "unresolved_and_archived" ) &&
        !unresolvedAndArchivedChecked &&
        !selectedStatusForFilter.includes( "unresolved_and_unarchived" ) &&
        !unresolvedAndUnarchivedChecked &&
        !selectedStatusForFilter.includes( "unresolved" ) &&
        !unresolvedChecked
      ) {
        dispatch( actions.selectedStatusForFilter( { status: [] } ) );
      }
    } else {
      if (
        ( unresolvedAndArchivedChecked &&
          unresolvedAndUnarchivedChecked &&
          unresolvedChecked &&
          resolvedChecked ) ||
        ( selectedStatusForFilter?.includes( "unresolved_and_archived" ) &&
          selectedStatusForFilter?.includes( "unresolved_and_unarchived" ) &&
          selectedStatusForFilter?.includes( "unresolved" ) &&
          selectedStatusForFilter?.includes( "resolved" ) )
      ) {
        dispatch( actions.selectedStatusForFilter( { status: ["all"] } ) );
        setSelectAllStatus( true );
        dispatch( actions.setAllStatusSelected( { isSelected: true } ) );
      } else {
        dispatch(
          actions.selectedStatusForFilter( { status: ["resolved_and_archived"] } )
        );
      }
    }
  };

  const handleUnresolvedAndArchived = ( e: { target: { checked: any } } ) => {
    const checked = e.target.checked;
    setUnresolvedAndArchivedChecked( checked );
    if ( selectAllStatus ) {
      dispatch( actions.setAllStatusSelected( { isSelected: checked } ) );
    } else {
      dispatch( actions.setAllStatusSelected( { isSelected: false } ) );
    }
    if ( !checked ) {
      if ( selectedStatusForFilter?.includes( "all" ) ) {
        dispatch( actions.selectedStatusForFilter( { status: ["resolved"] } ) );
        dispatch(
          actions.unCheckStatusFilter( { status: "unresolved_and_archived" } )
        );
        dispatch( actions.selectedStatusForFilter( { status: ["unresolved"] } ) );
        dispatch(
          actions.selectedStatusForFilter( { status: ["resolved_and_archived"] } )
        );
        dispatch(
          actions.selectedStatusForFilter( {
            status: ["unresolved_and_unarchived"],
          } )
        );
      }
      setSelectAllStatus( false );
      dispatch(
        actions.unCheckStatusFilter( {
          status: "unresolved_and_archived",
        } )
      );
      if (
        selectedStatusForFilter?.includes( "resolved_and_archived" ) ||
        resolvedAndArchivedChecked
      ) {
        dispatch(
          actions.selectedStatusForFilter( { status: ["resolved_and_archived"] } )
        );
      }
      if (
        selectedStatusForFilter.includes( "unresolved_and_unarchived" ) ||
        unresolvedAndUnarchivedChecked
      ) {
        dispatch(
          actions.selectedStatusForFilter( {
            status: ["unresolved_and_unarchived"],
          } )
        );
      }
      if ( selectedStatusForFilter.includes( "resolved" ) || resolvedChecked ) {
        dispatch( actions.selectedStatusForFilter( { status: ["resolved"] } ) );
      }
      if ( selectedStatusForFilter.includes( "unresolved" ) || unresolvedChecked ) {
        dispatch( actions.selectedStatusForFilter( { status: ["unresolved"] } ) );
      }
      if (
        !selectedStatusForFilter.includes( "resolved_and_archived" ) &&
        !resolvedAndArchivedChecked &&
        !selectedStatusForFilter.includes( "unresolved_and_unarchived" ) &&
        !unresolvedAndUnarchivedChecked &&
        !selectedStatusForFilter.includes( "resolved" ) &&
        !resolvedChecked &&
        !selectedStatusForFilter.includes( "unresolved" ) &&
        !unresolvedChecked
      ) {
        dispatch( actions.selectedStatusForFilter( { status: [] } ) );
      }
    } else {
      if (
        ( resolvedAndArchivedChecked &&
          unresolvedAndUnarchivedChecked &&
          unresolvedChecked &&
          resolvedChecked ) ||
        ( selectedStatusForFilter?.includes( "resolved_and_archived" ) &&
          selectedStatusForFilter?.includes( "unresolved_and_unarchived" ) &&
          selectedStatusForFilter?.includes( "unresolved" ) &&
          selectedStatusForFilter?.includes( "resolved" ) )
      ) {
        dispatch( actions.selectedStatusForFilter( { status: ["all"] } ) );
        setSelectAllStatus( true );
        dispatch( actions.setAllStatusSelected( { isSelected: true } ) );
      } else {
        dispatch(
          actions.selectedStatusForFilter( {
            status: ["unresolved_and_archived"],
          } )
        );
      }
    }
  };

  const handleUnresolvedAndUnarchived = ( e: { target: { checked: any } } ) => {
    const checked = e.target.checked;
    setUnresolvedAndUnarchivedChecked( checked );
    if ( selectAllStatus ) {
      dispatch( actions.setAllStatusSelected( { isSelected: checked } ) );
    } else {
      dispatch( actions.setAllStatusSelected( { isSelected: false } ) );
    }
    if ( !checked ) {
      if ( selectedStatusForFilter?.includes( "all" ) ) {
        dispatch( actions.selectedStatusForFilter( { status: ["resolved"] } ) );
        dispatch(
          actions.unCheckStatusFilter( { status: "unresolved_and_unarchived" } )
        );
        dispatch( actions.selectedStatusForFilter( { status: ["unresolved"] } ) );
        dispatch(
          actions.selectedStatusForFilter( { status: ["resolved_and_archived"] } )
        );
        dispatch(
          actions.selectedStatusForFilter( {
            status: ["unresolved_and_archived"],
          } )
        );
      }
      setSelectAllStatus( false );
      dispatch(
        actions.unCheckStatusFilter( {
          status: "unresolved_and_unarchived",
        } )
      );
      if (
        selectedStatusForFilter?.includes( "resolved_and_archived" ) ||
        resolvedAndArchivedChecked
      ) {
        dispatch(
          actions.selectedStatusForFilter( { status: ["resolved_and_archived"] } )
        );
      }
      if (
        selectedStatusForFilter.includes( "unresolved_and_archived" ) ||
        unresolvedAndArchivedChecked
      ) {
        dispatch(
          actions.selectedStatusForFilter( {
            status: ["unresolved_and_archived"],
          } )
        );
      }
      if ( selectedStatusForFilter.includes( "resolved" ) || resolvedChecked ) {
        dispatch( actions.selectedStatusForFilter( { status: ["resolved"] } ) );
      }
      if ( selectedStatusForFilter.includes( "unresolved" ) || unresolvedChecked ) {
        dispatch( actions.selectedStatusForFilter( { status: ["unresolved"] } ) );
      }
      if (
        !selectedStatusForFilter.includes( "resolved_and_archived" ) &&
        !resolvedAndArchivedChecked &&
        !selectedStatusForFilter.includes( "unresolved_and_archived" ) &&
        !unresolvedAndArchivedChecked &&
        !selectedStatusForFilter.includes( "resolved" ) &&
        !resolvedChecked &&
        !selectedStatusForFilter.includes( "unresolved" ) &&
        !unresolvedChecked
      ) {
        dispatch( actions.selectedStatusForFilter( { status: [] } ) );
      }
    } else {
      if (
        ( resolvedAndArchivedChecked &&
          unresolvedAndArchivedChecked &&
          unresolvedChecked &&
          resolvedChecked ) ||
        ( selectedStatusForFilter?.includes( "resolved_and_archived" ) &&
          selectedStatusForFilter?.includes( "unresolved_and_archived" ) &&
          selectedStatusForFilter?.includes( "unresolved" ) &&
          selectedStatusForFilter?.includes( "resolved" ) )
      ) {
        dispatch( actions.selectedStatusForFilter( { status: ["all"] } ) );
        setSelectAllStatus( true );
        dispatch( actions.setAllStatusSelected( { isSelected: true } ) );
      } else {
        dispatch(
          actions.selectedStatusForFilter( {
            status: ["unresolved_and_unarchived"],
          } )
        );
      }
    }
  };

  function handleCancelForStatus() {
    if ( prevState === "selectAll" ) {
      setSelectAllStatus( true );
      setResolvedChecked( true );
      setUnresolvedChecked( true );
      setResolvedAndArchivedChecked( true );
      setUnresolvedAndArchivedChecked( true );
      setUnresolvedAndUnarchivedChecked( true );
    } else if ( prevState === "resolved" ) {
      setSelectAllStatus( false );
      setResolvedChecked( true );
      setUnresolvedChecked( false );
      setResolvedAndArchivedChecked( false );
      setUnresolvedAndArchivedChecked( false );
      setUnresolvedAndUnarchivedChecked( false );
    } else if ( prevState === "unresolved" ) {
      setSelectAllStatus( false );
      setResolvedChecked( false );
      setUnresolvedChecked( true );
      setResolvedAndArchivedChecked( false );
      setUnresolvedAndArchivedChecked( false );
      setUnresolvedAndUnarchivedChecked( false );
    } else if ( prevState === "resolved_and_archived" ) {
      setSelectAllStatus( false );
      setResolvedChecked( false );
      setUnresolvedChecked( false );
      setResolvedAndArchivedChecked( true );
      setUnresolvedAndArchivedChecked( false );
      setUnresolvedAndUnarchivedChecked( false );
    } else if ( prevState === "unresolved_and_archived" ) {
      setSelectAllStatus( false );
      setResolvedChecked( false );
      setUnresolvedChecked( false );
      setResolvedAndArchivedChecked( false );
      setUnresolvedAndArchivedChecked( true );
      setUnresolvedAndUnarchivedChecked( false );
    } else if ( prevState === "unresolved_and_unarchived" ) {
      setSelectAllStatus( false );
      setResolvedChecked( false );
      setUnresolvedChecked( false );
      setResolvedAndArchivedChecked( false );
      setUnresolvedAndArchivedChecked( false );
      setUnresolvedAndUnarchivedChecked( true );
    } else {
      setSelectAllStatus( false );
      setResolvedChecked( false );
      setUnresolvedChecked( false );
      setResolvedAndArchivedChecked( false );
      setUnresolvedAndArchivedChecked( false );
      setUnresolvedAndUnarchivedChecked( false );
    }

    if ( prevState === "selectAll" ) {
      dispatch( actions.setAllStatusSelected( { isSelected: true } ) );
    } else {
      dispatch( actions.setAllStatusSelected( { isSelected: false } ) );
    }
  }

  function handleApplyForStatus() {
    if (
      resolvedChecked &&
      unresolvedChecked &&
      resolvedAndArchivedChecked &&
      unresolvedAndArchivedChecked &&
      unresolvedAndUnarchivedChecked &&
      selectAllStatus
    ) {
      setPrevState( "selectAll" );
    } else if (
      resolvedChecked &&
      !unresolvedChecked &&
      !selectAllStatus &&
      !resolvedAndArchivedChecked &&
      !unresolvedAndArchivedChecked &&
      !unresolvedAndUnarchivedChecked
    ) {
      setPrevState( "resolved" );
    } else if (
      !resolvedChecked &&
      unresolvedChecked &&
      !selectAllStatus &&
      !resolvedAndArchivedChecked &&
      !unresolvedAndArchivedChecked &&
      !unresolvedAndUnarchivedChecked
    ) {
      setPrevState( "unresolved" );
    } else if (
      resolvedAndArchivedChecked &&
      !resolvedChecked &&
      !unresolvedChecked &&
      !selectAllStatus &&
      !unresolvedAndArchivedChecked &&
      !unresolvedAndUnarchivedChecked
    ) {
      setPrevState( "resolved_and_archived" );
    } else if (
      unresolvedAndArchivedChecked &&
      !resolvedChecked &&
      !unresolvedChecked &&
      !selectAllStatus &&
      !resolvedAndArchivedChecked &&
      !unresolvedAndUnarchivedChecked
    ) {
      setPrevState( "unresolved_and_archived" );
    } else if (
      unresolvedAndUnarchivedChecked &&
      !resolvedChecked &&
      !unresolvedChecked &&
      !selectAllStatus &&
      !resolvedAndArchivedChecked &&
      !unresolvedAndArchivedChecked
    ) {
      setPrevState( "unresolved_and_unarchived" );
    } else {
      setPrevState( "deselectAll" );
    }
  }

  const handleMouseEnter = () => {
    setShowAsigneeList( true );
  };

  const handleMouseLeave = () => {
    setShowAsigneeList( false );
  };

  const handleMouseEnterForBrand = () => {
    setShowBrandList( true );
  };

  const handleMouseLeaveForBrand = () => {
    setShowBrandList( false );
  };
  const handleMouseEnterForStatus = () => {
    setShowStatus( true );
  };

  const handleMouseLeaveForStatus = () => {
    setShowStatus( false );
  };

  const handleMouseEnterForDate = () => {
    setShowDate( true );
  };

  const handleMouseLeaveForDate = () => {
    setShowDate( false );
  };

  function dropdownHandeler() {
    setShowDropdown( !showDropdown );
    if ( showDropdown ) {
      handleCancel();
    }
    dispatch( actions.idsAppliedToSelectedIdFilter() );
    handleCancelForStatus();
    setStartDate( appliedStartDate );
    setEndDate( appliedEndDate );
    setShowValidation( {
      startDataValidation: false,
      endDateValidation: false,
    } );
  }

  // Switch from date.toLocaleDateString() to Intl.DateTimeFormat is done to provide more flexibility and control over the date formatting process.
// Function to format a date into a specific format
function formatDate(date: Date | null) {
  if (date) {
    // Create a new Intl.DateTimeFormat instance with the desired format options
    const formatter = new Intl.DateTimeFormat('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    });

    // Format the date into an array of parts using formatToParts method
    const parts = formatter.formatToParts(date);

    if (parts) {
      // Find the day, month, and year parts from the formatted date
      const day = parts.find(part => part.type === "day");
      const month = parts.find(part => part.type === "month")?.value;
      const year = parts.find(part => part.type === "year")?.value;

      // Return the formatted date string with the day, abbreviated month, and year
      return `${day?.value} ${month?.slice(0, 3)} ${year}`;
    }
  }

  // Return "Date" if the date is null or invalid
  return "Date";
}

// Format the start date using the formatDate function and useMemo hook
const formattedStartDate = useMemo(() => formatDate(startDate), [startDate]);

// Format the end date using the formatDate function and useMemo hook
const formattedEndDate = useMemo(() => formatDate(endDate), [endDate]);

  const selectAllBrand = (
    e: MouseEvent<HTMLInputElement, globalThis.MouseEvent>
  ) => {
    const isChecked = ( e.target as HTMLInputElement ).checked;
    if ( isChecked ) {
      setSelectAllChecked( isChecked );
      if ( brandList ) {
        setSelectedBrandIds( brandList.map( ( brand ) => brand.id ) );
      }
      dispatch(
        actions.selectAllBrand( {
          brandList: brandList,
        } )
      );
    } else {
      setSelectAllChecked( false );
      setSelectedBrandIds( [] );
      dispatch( actions.allBrandsSelected() );
    }
  };

  useEffect( () => {
    currentState.current.chatStatus = chatStatus;
  }, [chatStatus] );

  useEffect( () => {
    dispatch(
      actions.updateDateForFilter( {
        startDate: startDate,
        endDate: endDate,
      } )
    );
  }, [startDate, endDate] );

  useEffect( () => {
    setSelectedBrandIds( selectedBrands );
  }, [selectedBrands] );

  useEffect( () => {
    setSelectAllChecked( brandList?.length === selectedBrandIds.length );
  }, [selectedBrandIds] );

  //JAN 18 2024, no need to fetch the brands on load, as it is already fetching the data in Search component callback
  // useEffect( () => {
  //   dispatch( fetchLiveChatIntegratedBrandsThunk( {} ) );
  // }, [] );

  function clearAllFilterOutside() {
    dispatch( actions.removeAllAgentForAssignToFilter( { initialValue: [] } ) );
    setStartDate( null );
    setEndDate( null );
    setSelectAllChecked( false );
    setSelectedBrandIds( [] );
    dispatch( actions.allBrandsSelected() );
    dispatch( actions.selectedStatusForFilter( { status: [] } ) );
    setSelectAllStatus( false );
    setResolvedChecked( false );
    setUnresolvedChecked( false );
    setResolvedAndArchivedChecked( false );
    setUnresolvedAndArchivedChecked( false );
    setUnresolvedAndUnarchivedChecked( false );
    dispatch( actions.selectedFilterIdApplied() );
    dispatch( actions.selectedBrandIdApplied() );
    dispatch( actions.selectedStatusApplied() );
    dispatch( actions.resetDateFilter() );
    dispatch( actions.setAllStatusSelected( { isSelected: false } ) );
    setPrevState( "deselectAll" );
    setShowDropdown( false );
  }

  function clearAllFitlerInside() {
    // Dates
    setStartDate( null );
    setEndDate( null );

    // Agents
    dispatch( actions.removeAllAgentForAssignToFilter( { initialValue: [] } ) );

    // Brands
    setSelectAllChecked( false );
    setSelectedBrandIds( [] );
    dispatch( actions.allBrandsSelected() );

    // Status
    setSelectAllStatus( false );
    setResolvedChecked( false );
    setUnresolvedChecked( false );
    setResolvedAndArchivedChecked( false );
    setUnresolvedAndArchivedChecked( false );
    setUnresolvedAndUnarchivedChecked( false );
    dispatch( actions.selectedStatusForFilter( { status: [] } ) );
    dispatch( actions.setAllStatusSelected( { isSelected: false } ) );
  }

  const clearAllAppliedBrands = useCallback( () => {
    setSelectAllChecked( false );
    setSelectedBrandIds( [] );
    dispatch( actions.allBrandsSelected() );
  }, [] );

  const clearAllAppliedTags = useCallback( () => {
    setResolvedChecked( false );
    setUnresolvedChecked( false );
    setSelectAllStatus( false );
    setResolvedAndArchivedChecked( false );
    setUnresolvedAndArchivedChecked( false );
    setUnresolvedAndUnarchivedChecked( false );
    dispatch( actions.setAllStatusSelected( { isSelected: false } ) );
    dispatch( actions.selectedStatusForFilter( { status: [] } ) );
  }, [] );

  const statusDisplayNames = {
    resolved: "Resolved",
    unresolved: "Unresolved",
    resolved_and_archived: "Resolved and Archived",
    unresolved_and_archived: "Unresolved and Archived",
    unresolved_and_unarchived: "Unresolved and Unarchived",
    all: "All Selected",
  };

  return {
    dispatch,
    chatStatus,
    filterStartDate,
    filterEndDate,
    activeCustomerInfo,
    brandList,
    brandIdsList,
    fetchLiveChatIntegratedBrandAJAXStatus,
    appliedBrandIds,
    appliedStartDate,
    appliedEndDate,
    idFilterApplied,
    statusApplied,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    searchStatusQuery,
    setSearchStatusQuery,
    selectedBrandIds,
    setSelectedBrandIds,
    resolvedChecked,
    unresolvedChecked,
    selectAllStatus,
    searchQuery,
    setSearchQuery,
    selectAllChecked,
    setSelectAllChecked,
    showAsigneeList,
    showBrandList,
    handleCancel,
    handleOnApply,
    concatinateAgentNames,
    handleSelectAll,
    handleResolved,
    handleUnresolved,
    handleCancelForStatus,
    handleApplyForStatus,
    handleMouseEnter,
    handleMouseLeave,
    handleMouseEnterForBrand,
    handleMouseLeaveForBrand,
    filteredOptions,
    concatinateAgentNamesInitials,
    allStatusFilterSelected,
    formattedStartDate,
    formattedEndDate,
    dropdownHandeler,
    selectAllBrand,
    handleMouseEnterForStatus,
    handleMouseLeaveForStatus,
    showStatus,
    handleMouseEnterForDate,
    handleMouseLeaveForDate,
    showDate,
    clearAllFilterOutside,
    clearAllFitlerInside,
    showValidation,
    setShowValidation,
    clearAllAppliedBrands,
    clearAllAppliedTags,
    resolvedAndArchivedChecked,
    unresolvedAndArchivedChecked,
    unresolvedAndUnarchivedChecked,
    handleResolvedAndArchived,
    handleUnresolvedAndArchived,
    handleUnresolvedAndUnarchived,
    statusDisplayNames,
  };
}

export default useMouseFilter;
