import React from "react";
import styles from "./VariableModal.module.scss";
interface Props{
  handleOnSubmit: (e:any)=>void
  onHide: (e:any)=>void
}

const VariableModal = ({handleOnSubmit, onHide}: Props) => {
  return (
    <div>
      <h3 className={`${styles.variableHead}`}>Variables</h3>
      <p>Are you sure you do not wish to use any variables?</p>
      <div className="d-flex justify-content-end mt-4 pt-4 mb-2">
        <button className={`me-3 ${styles.cancelBtn}`} onClick={onHide}>No</button>
        <button  className={`${styles.cancelBtn}`} onClick={handleOnSubmit}>Yes</button>
      </div>
    </div>
  );
};

export default VariableModal;
