import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";

export interface deleteCustomQAParams {
  botProfileId: number | string;
  customQAId: number | string;
}

const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/customQa/deleteCustomQa`
  : "/api/botProfile/customQA/delete";

export async function deleteCustomQA(params: deleteCustomQAParams) {
  const { data: res } = await axiosJSON.delete(apiEndPoint, { params });

  if (res.error) {
    throw new Error(res.message as string);
  }

  return res.data;
}
