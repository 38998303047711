import styles from "./BaseFooterButtonSolid.module.scss";

function BaseFooterButtonSolid(props: any) {
  return (
    <button className={`px-3 ${styles.addTagAdd}`} {...props}>
      {props.children}
    </button>
  );
}

export default BaseFooterButtonSolid;
