import { axiosJSON } from "src/globals/axiosEndPoints";
import {
  EmailForwardingDetails,
  SavedStepAndQuestionData,
  StepQuestionAndValues,
  StripeDetails,
} from "src/features/ReturnAutoWorkFlow/ReturnAutoWorkFlow.types";

export interface StepQuestionData
  extends Omit<StepQuestionAndValues, "specificQuestionValue"> {
  /**
   * Specific question value details (optional).
   * In case of email verification this API will be called again with `EmailForwardingDetails["activeEmailForVerification"]` value to check if it is verified.
   */
  specificQuestionValue?:
    | null
    | EmailForwardingDetails["activeEmailForVerification"]
    | Omit<StripeDetails, "verified" | "error">;

  /**
   * Flag used to move user to the next question and bring next question data as well if needed.
   */
  moveToNextQuestion?: boolean;
}

/**
 * Interface representing the parameters for saving base question and step data.
 */
export interface SaveBaseQuestionAndStepParams {
  /**
   * The ID of the integration.
   */
  integrationId: string;
  /**
   * The ID of the step.
   */
  stepId: string;
  /**
   * An array of objects representing the step data.
   */
  stepData: Array<StepQuestionData>;
}

/**
 * Saves the base question and step data for a given integration ID and step ID.
 *
 * @param params - The parameters object containing the integration ID, step ID, and step data.
 *
 * @returns A promise that resolves to an object containing an array of saved step and question IDs.
 *
 * @throws Will throw an error if the request fails or if the response contains an error message.
 */
async function saveBaseQuestionAndStepService(
  params: SaveBaseQuestionAndStepParams,
) {
  const { data: res } = await axiosJSON.post(
    "/api/setting/automation/refund/saveStepData",
    params,
  );

  if (res.err || res.error) {
    throw new Error(
      res.msg ?? res.message ?? "Some Error Occurred while saving data.",
    );
  }

  const ret = res.data as {
    savedStepAndQuestionIds: Array<SavedStepAndQuestionData>;
    configured: boolean;
    published: boolean;
    activeStepId?: null | string;
  };

  ret.activeStepId =
    typeof ret.activeStepId === "number"
      ? ret.activeStepId + ""
      : ret.activeStepId;

  return ret;
}

export default saveBaseQuestionAndStepService;
