import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Filters,
  getCurrentViewData,
  removeActiveFilter,
  resetOnlyOtherFilters,
  setFilterByLastUpdated,
  TicketFilterCol,
} from "src/store/slices/ticketFilters/ticketFilters.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { isObjOrStrEmpty } from "src/utils/utils";
import {
  setFilterByAgents,
  setFilterByCreatedDate,
  setFilterByClosedDate,
  setFilterByChannels,
  setFilterByBrands,
  setFilterByTags,
  setFilterByEmails,
  setFilterByUserType,
  setFilterByLastMessage,
  setFilterByNotRepliedSince,
  setFilterByTicketStatus,
  setFilterByDraftStatus,
  setFilterByDraftedBy,
} from "src/store/slices/ticketFilters/ticketFilters.slice";
import {
  addView,
  setActiveViewId,
  setFormDataByKey,
  setFormDataFromTicketFilter,
} from "src/store/slices/ticketViews/ticketViews.slice";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import {
  resetCachedTickets,
  resetPagination,
} from "src/store/slices/tickets/tickets.slice";
import { useParams } from "react-router-dom";
import { ITicketBtn } from "src/containers/SideBar/children/SubSB/SubSB";

interface IFilterValue {
  id: number;
  name: string;
  compare: string;
  isValueRequired: any;
  viewFilter: boolean;
}

interface IOtherFilters {
  key: string;
  name: string;
  compare: string;
  value: any;
}

/**
 * helper function to format date value object in filters
 */
const formatFilterDate = (dateValues: any) => {
  let { startDate, startTime, selectsRange, endDate, endTime }: any =
    dateValues;
  var options: any = { year: "numeric", month: "short", day: "numeric" };

  let dateValue: string = "";
  let formattedDate = "";
  let formattedTime = "";
  if (startDate !== null && startTime !== null) {
    formattedDate = new Date(startDate).toLocaleDateString("en-US", options);
    formattedTime = new Date(startTime).toLocaleTimeString("en-US");
  } else {
    return null;
  }

  if (selectsRange === true) {
    let formattedEndDate = "";
    let formattedEndTime = "";
    if (endDate !== null && endTime !== null) {
      formattedEndDate = new Date(endDate).toLocaleDateString("en-US", options);
      formattedEndTime = new Date(endTime).toLocaleTimeString("en-US");
      dateValue = `${formattedDate} ${formattedTime} to ${formattedEndDate} ${formattedEndTime}`;
    } else {
      return null;
    }
  } else {
    dateValue = `${formattedDate} ${formattedTime}`;
  }

  return dateValue;
};

/**
 * helper function formats date string using formatFilterDate and adds prefix like "Between" | "After"
 */
const getDateValue = (dateName: string, filterDateValueObj: any) => {
  if (
    (dateName.toLowerCase() == "between" ||
      dateName.toLocaleLowerCase() == "pick date") &&
    filterDateValueObj
  ) {
    let formatedDate = formatFilterDate(filterDateValueObj);
    return formatedDate ?? dateName;
  } else if (
    (dateName.toLocaleLowerCase() == "before" ||
      dateName.toLocaleLowerCase() == "after") &&
    filterDateValueObj
  ) {
    let formatedDate = formatFilterDate(filterDateValueObj);
    if (formatedDate) {
      return dateName + " " + formatedDate;
    } else {
      return dateName;
    }
  } else {
    return dateName;
  }
};

/**
 * Helper to map over filters array and get a combined filters name string
 */
function getValue(filterArray: []) {
  return filterArray.map((filterObj: any) => filterObj.name).join(", ");
}

/**
 * Helper to separate view filters and other filters
 */
const separateViewAndOtherFilters = (
  filter: Array<any>,
  key: string,
  name: string,
  compare: string
) => {
  let viewFilter: any = Object.values(filter).filter(
    (activeFilter) => activeFilter?.viewFilter === true
  );
  let otherFilter: any = Object.values(filter).filter(
    (activeFilter) => !activeFilter?.viewFilter
  );

  let viewFilterData: any = null;
  let otherFilterData: any = null;

  if (viewFilter.length) {
    viewFilterData = {
      key,
      name,
      compare,
      value: getValue(viewFilter),
    };
  }

  if (otherFilter.length) {
    otherFilterData = {
      key,
      name,
      compare,
      value: getValue(otherFilter),
    };
  }
  return {
    viewFilterData,
    otherFilterData,
  };
};

/**
 * custom hook gets applied sidebar filter and filters from filter dropdown and returns applied filters data
 */
export const useAppliedFilters = () => {
  //getting the sidebar data
  const { ticketBtnData, selectedBtn } = useAppSelector(
    (state) => state.ticketSideBar
  );
  //getting the filter dropdown applied filters data
  const { activeFilters, filtersColData } = useAppSelector(
    (state) => state.ticketFilter
  );
  //getting the viewId from url
  const { tableType }: any = useParams();

  //using useMemo as we dont need to calculate on every state update, only updating viewId on tableType updation
  const viewId = useMemo(() => {
    //checking if url param contains view if yes then returning viewId else returing null
    const viewId = tableType.includes("viewId")
      ? parseInt(tableType.split("::")[1])
      : null;
    return viewId;
  }, [tableType]);

  const dispatch = useAppDispatch();

  //using useMomo hook to prevent redefine this constant on every render
  const otherFilterKeys = useMemo(() => {
    //get the object of key value pair filter name and filter key of filters, which is used later to show the filter name
    const filterKeys: { [key: string]: TicketFilterCol } = {};
    Object.values(filtersColData).forEach((filter) => {
      filterKeys[filter.key] = filter;
    });
    return filterKeys;
  }, [filtersColData]);

  //getting the add view ajax status, used to show the loading state in save as new view modal
  const addViewState = useAppSelector(
    (state) => state.ticketViews.addViewState
  );

  //getting viewId filters data from redux
  const currentViewFilters = useAppSelector(
    (state) => state.ticketFilter.currentViewFilters
  );

  //getting the viewName, which is used as payload to send in save as new view modal
  const viewName = useAppSelector(
    (state) => state.ticketViews.addViewFromData.viewName
  );

  const [viewFilters, setViewFilters] = useState<Array<IOtherFilters>>([]);
  const [otherFilters, setOtherFilters] = useState<Array<IOtherFilters>>([]);
  //useState for add new view loading status
  const [addViewLoading, setAddViewLoading] = useState<boolean>(false);
  //useState for showing any error in save as view modal
  const [showViewNameError, setShowViewNameError] = useState("");

  useEffect(() => {
    if (viewId) {
      //fetching view filters from backend
      dispatch(getCurrentViewData(viewId));
    }
  }, [viewId]);

  /**
   * helper function which process the filter dropdown slice filters and returns array of IOtherFilters object
   */
  const parseFilters = useCallback(
    (filter: Filters) => {
      const {
        filterByCreatedDate,
        filterByClosedDate,
        filterByCreatedDateValue,
        filterByClosedDateValue,
        filterByChannels,
        filterByBrands,
        filterByAgents,
        filterByTags,
        filterByEmails,
        filterByAssignees,
        filterByUserType,
        filterByLastMessage,
        filterByNotRepliedSince,
        filterByNotRepliedSinceValue,
        filterByTicketStatus,
        filterByDraftStatus,
        filterByDraftedBy,
        filterByLastUpdated,
        filterByLastUpdatedValue,
      }: any = filter;
      let filterTableData: any = {};
      let viewFilterTableData: any = {};

      if (!isObjOrStrEmpty(filterByCreatedDate)) {
        let ticketDateGmt = {
          name: otherFilterKeys["ticket_date_gmt"]?.name ?? "Created Date",
          key: "ticket_date_gmt",
          compare: filterByCreatedDate.compare,
          value: getDateValue(
            filterByCreatedDate.name,
            filterByCreatedDateValue
          ),
        };
        if (filterByCreatedDate?.viewFilter === true) {
          viewFilterTableData["ticket_date_gmt"] = ticketDateGmt;
        } else {
          filterTableData["ticket_date_gmt"] = ticketDateGmt;
        }
      }

      // Check if filter exists
      if (!isObjOrStrEmpty(filterByLastUpdated)) {
        // Initialize date object
        const ticketDateGmt = {
          name: otherFilterKeys["ticket_modified_gmt"]?.name ?? "Last updated",
          key: "ticket_modified_gmt",
          compare: filterByLastUpdated.compare,
          value: getDateValue(
            filterByLastUpdated.name,
            filterByLastUpdatedValue
          ),
        };
        // Check view filter or other filter
        if (filterByLastUpdated?.viewFilter === true) {
          viewFilterTableData["ticket_modified_gmt"] = ticketDateGmt;
        } else {
          filterTableData["ticket_modified_gmt"] = ticketDateGmt;
        }
      }

      if (!isObjOrStrEmpty(filterByClosedDate)) {
        // log.debug("in filterByClosedDate");
        let ticketDateGmt = {
          name:
            otherFilterKeys["ticket_closed_date_gmt"]?.name ?? "Closed Date",
          key: "ticket_closed_date_gmt",
          compare: filterByClosedDate.compare,
          value: getDateValue(filterByClosedDate.name, filterByClosedDateValue),
        };
        if (filterByClosedDate?.viewFilter === true) {
          viewFilterTableData["ticket_closed_date_gmt"] = ticketDateGmt;
        } else {
          filterTableData["ticket_closed_date_gmt"] = ticketDateGmt;
        }
      }

      if (!isObjOrStrEmpty(filterByNotRepliedSince)) {
        let ticketDateGmt = {
          name:
            otherFilterKeys["not_replied_since"]?.name ?? "Not Replied Since",
          key: "not_replied_since",
          compare: filterByNotRepliedSince.compare,
          value: getDateValue(
            filterByNotRepliedSince.name,
            filterByNotRepliedSinceValue
          ),
        };
        if (filterByNotRepliedSince?.viewFilter === true) {
          viewFilterTableData["not_replied_since"] = ticketDateGmt;
        } else {
          filterTableData["not_replied_since"] = ticketDateGmt;
        }
      }

      if (!isObjOrStrEmpty(filterByChannels)) {
        const separtedFilters = separateViewAndOtherFilters(
          filterByChannels,
          "ticket_channel",
          otherFilterKeys["ticket_channel"]?.name ?? "Channel",
          "="
        );

        if (separtedFilters.viewFilterData) {
          viewFilterTableData["ticket_channel"] =
            separtedFilters.viewFilterData;
        }

        if (separtedFilters.otherFilterData) {
          filterTableData["ticket_channel"] = separtedFilters.otherFilterData;
        }
      }

      if (!isObjOrStrEmpty(filterByBrands)) {
        const separtedFilters = separateViewAndOtherFilters(
          filterByBrands,
          "ticket_brand_id",
          otherFilterKeys["ticket_brand_id"]?.name ?? "Brand",
          "="
        );

        if (separtedFilters.viewFilterData) {
          viewFilterTableData["ticket_brand_id"] =
            separtedFilters.viewFilterData;
        }

        if (separtedFilters.otherFilterData) {
          filterTableData["ticket_brand_id"] = separtedFilters.otherFilterData;
        }
      }
      if (!isObjOrStrEmpty(filterByAgents)) {
        const separtedFilters = separateViewAndOtherFilters(
          filterByAgents,
          "ticket_agent",
          otherFilterKeys["ticket_agent"]?.name ?? "Ticket Agent",
          "="
        );

        if (separtedFilters.viewFilterData) {
          viewFilterTableData["ticket_agent"] = separtedFilters.viewFilterData;
        }

        if (separtedFilters.otherFilterData) {
          filterTableData["ticket_agent"] = separtedFilters.otherFilterData;
        }
      }
      if (!isObjOrStrEmpty(filterByTags)) {
        const separtedFilters = separateViewAndOtherFilters(
          filterByTags,
          "tags",
          otherFilterKeys["tags"]?.name ?? "Tags",
          "="
        );

        if (separtedFilters.viewFilterData) {
          viewFilterTableData["tags"] = separtedFilters.viewFilterData;
        }

        if (separtedFilters.otherFilterData) {
          filterTableData["tags"] = separtedFilters.otherFilterData;
        }
      }
      if (!isObjOrStrEmpty(filterByEmails)) {
        let filterData = {
          key: "filter_email",
          name: otherFilterKeys["filter_email"]?.name ?? "Emails",
          compare: filterByEmails.compare,
          value: filterByEmails.id,
        };

        if (filterByEmails?.viewFilter === true) {
          viewFilterTableData["filter_email"] = filterData;
        } else {
          filterTableData["filter_email"] = filterData;
        }
      }

      if (!isObjOrStrEmpty(filterByUserType)) {
        const separtedFilters = separateViewAndOtherFilters(
          filterByUserType,
          "user_type",
          otherFilterKeys["user_type"]?.name ?? "User Type",
          "="
        );

        if (separtedFilters.viewFilterData) {
          viewFilterTableData["user_type"] = separtedFilters.viewFilterData;
        }

        if (separtedFilters.otherFilterData) {
          filterTableData["user_type"] = separtedFilters.otherFilterData;
        }
      }
      if (!isObjOrStrEmpty(filterByLastMessage)) {
        const separtedFilters = separateViewAndOtherFilters(
          filterByLastMessage,
          "last_message",
          otherFilterKeys["last_message"]?.name ?? "Last Message",
          "="
        );

        if (separtedFilters.viewFilterData) {
          viewFilterTableData["last_message"] = separtedFilters.viewFilterData;
        }

        if (separtedFilters.otherFilterData) {
          filterTableData["last_message"] = separtedFilters.otherFilterData;
        }
      }

      if (!isObjOrStrEmpty(filterByDraftStatus)) {
        const separtedFilters = separateViewAndOtherFilters(
          filterByDraftStatus,
          "ticket_draft_status",
          otherFilterKeys["ticket_draft_status"]?.name ?? "Draft Status",
          "="
        );

        if (separtedFilters.viewFilterData) {
          viewFilterTableData["ticket_draft_status"] =
            separtedFilters.viewFilterData;
        }

        if (separtedFilters.otherFilterData) {
          filterTableData["ticket_draft_status"] =
            separtedFilters.otherFilterData;
        }
      }
      if (!isObjOrStrEmpty(filterByDraftedBy)) {
        const separtedFilters = separateViewAndOtherFilters(
          filterByDraftStatus,
          "drafted_by",
          otherFilterKeys["drafted_by"]?.name ?? "Drafted By",
          "="
        );

        if (separtedFilters.viewFilterData) {
          viewFilterTableData["drafted_by"] = separtedFilters.viewFilterData;
        }

        if (separtedFilters.otherFilterData) {
          filterTableData["drafted_by"] = separtedFilters.otherFilterData;
        }
      }

      if (!isObjOrStrEmpty(filterByTicketStatus)) {
        const separtedFilters = separateViewAndOtherFilters(
          filterByTicketStatus,
          "ticket_status_id",
          otherFilterKeys["ticket_status_id"]?.name ?? "Ticket Status",
          "="
        );

        if (separtedFilters.viewFilterData) {
          viewFilterTableData["ticket_status_id"] =
            separtedFilters.viewFilterData;
        }

        if (separtedFilters.otherFilterData) {
          filterTableData["ticket_status_id"] = separtedFilters.otherFilterData;
        }
      }

      return { filterTableData, viewFilterTableData };
    },
    [otherFilterKeys]
  );

  const currentState = useRef({ activeFilters, currentViewFilters });

  //helper function to reset filter by key
  const removeFilter = useCallback((filterKey: string) => {
    //resetting filter on dropdown filter
    switch (filterKey) {
      case "ticket_date_gmt":
        dispatch(setFilterByCreatedDate(null));
        break;
      case "ticket_closed_date_gmt":
        dispatch(setFilterByClosedDate(null));
        break;
      case "ticket_modified_gmt":
        dispatch(setFilterByLastUpdated(null));
        break;
      case "ticket_channel":
        dispatch(
          setFilterByChannels(
            currentState.current.currentViewFilters.filterByChannels
          )
        );
        break;
      case "ticket_brand_id":
        dispatch(
          setFilterByBrands(
            currentState.current.currentViewFilters.filterByChannels
          )
        );
        break;
      case "ticket_agent":
        dispatch(
          setFilterByAgents(
            currentState.current.currentViewFilters.filterByAgents
          )
        );
        break;
      case "tags":
        dispatch(
          setFilterByTags(currentState.current.currentViewFilters.filterByTags)
        );
        break;
      case "filter_email":
        dispatch(setFilterByEmails(null));
        break;
      case "user_type":
        dispatch(
          setFilterByUserType(
            currentState.current.currentViewFilters.filterByUserType
          )
        );
        break;
      case "last_message":
        dispatch(
          setFilterByLastMessage(
            currentState.current.currentViewFilters.filterByLastMessage
          )
        );
        break;
      case "not_replied_since":
        dispatch(setFilterByNotRepliedSince(null));
        break;
      case "ticket_status_id":
        dispatch(
          setFilterByTicketStatus(
            currentState.current.currentViewFilters.filterByTicketStatus
          )
        );
        break;
      case "ticket_draft_status":
        dispatch(
          setFilterByDraftStatus(
            currentState.current.currentViewFilters.filterByDraftStatus
          )
        );
        break;
      case "drafted_by":
        dispatch(
          setFilterByDraftedBy(
            currentState.current.currentViewFilters.filterByDraftedBy
          )
        );
        break;
    }

    //removing filter on outer ticket view
    dispatch(removeActiveFilter(filterKey));
  }, []);

  /**
   * helper function to merge view filters with dropdown filters
   */
  const mergeViewFilterAndOtherFilters = useCallback(
    (activeFilters: Filters, viewFilters: Filters) => {
      const tempActiveFilters: Filters = { ...activeFilters };
      const {
        filterByCreatedDate,
        filterByClosedDate,
        filterByCreatedDateValue,
        filterByClosedDateValue,
        filterByChannels,
        filterByBrands,
        filterByAgents,
        filterByTags,
        filterByEmails,
        filterByAssignees,
        filterByUserType,
        filterByLastMessage,
        filterByNotRepliedSince,
        filterByNotRepliedSinceValue,
        filterByTicketStatus,
        filterByDraftStatus,
        filterByDraftedBy,
        filterByLastUpdated,
        filterByLastUpdatedValue,
      } = viewFilters;

      if (!isObjOrStrEmpty(filterByCreatedDate)) {
        // log.debug("in filterByCreatedDate");
        tempActiveFilters.filterByCreatedDate = filterByCreatedDate;
        tempActiveFilters.filterByCreatedDateValue = filterByCreatedDateValue;
      }

      if (!isObjOrStrEmpty(filterByClosedDate)) {
        tempActiveFilters.filterByClosedDate = filterByClosedDate;
        tempActiveFilters.filterByClosedDateValue = filterByClosedDateValue;
      }

      // Check if filter exists
      if (!isObjOrStrEmpty(filterByLastUpdated)) {
        tempActiveFilters.filterByLastUpdated = filterByLastUpdated;
        tempActiveFilters.filterByLastUpdatedValue = filterByLastUpdatedValue;
      }

      if (!isObjOrStrEmpty(filterByNotRepliedSince)) {
        tempActiveFilters.filterByNotRepliedSince = filterByNotRepliedSince;
        tempActiveFilters.filterByNotRepliedSinceValue =
          filterByNotRepliedSinceValue;
      }

      if (!isObjOrStrEmpty(filterByChannels)) {
        let channelFilters: any = [...tempActiveFilters.filterByChannels];

        filterByChannels.map((activeFilterValue: any) => {
          //if all selected no need to add it to the filter array
          if (activeFilterValue.id == 0) {
            return;
          }
          let isExists = channelFilters.filter(
            (filter: any) => filter.id == activeFilterValue.id
          );

          if (!isExists.length) {
            channelFilters.push(activeFilterValue);
          }
        });
        tempActiveFilters.filterByChannels = channelFilters;
      }
      if (!isObjOrStrEmpty(filterByBrands)) {
        let brandFilters: any = [...tempActiveFilters.filterByBrands];

        filterByBrands.map((activeFilterValue: any) => {
          let isExists = brandFilters.filter(
            (filter: any) => filter.id == activeFilterValue.id
          );

          if (!isExists.length) {
            brandFilters.push(activeFilterValue);
          }
        });
        tempActiveFilters.filterByBrands = brandFilters;
      }
      if (!isObjOrStrEmpty(filterByAgents)) {
        let filters: any = [...tempActiveFilters.filterByAgents];

        filterByAgents.map((activeFilterValue: any) => {
          let isExists = filters.filter(
            (filter: any) => filter.id == activeFilterValue.id
          );

          if (!isExists.length) {
            filters.push(activeFilterValue);
          }
        });
        tempActiveFilters.filterByAgents = filters;
      }
      if (!isObjOrStrEmpty(filterByTags)) {
        let filters: any = [...tempActiveFilters.filterByTags];

        filterByTags.map((activeFilterValue: any) => {
          let isExists = filters.filter(
            (filter: any) => filter.id == activeFilterValue.id
          );

          if (!isExists.length) {
            filters.push(activeFilterValue);
          }
        });
        tempActiveFilters.filterByTags = filters;
      }
      if (!isObjOrStrEmpty(filterByEmails)) {
        tempActiveFilters.filterByEmails = filterByEmails;
      }

      if (!isObjOrStrEmpty(filterByUserType)) {
        let filters: any = [...tempActiveFilters.filterByUserType];

        filterByUserType.map((activeFilterValue: any) => {
          let isExists = filters.filter(
            (filter: any) => filter.id == activeFilterValue.id
          );

          if (!isExists.length) {
            filters.push(activeFilterValue);
          }
        });
        tempActiveFilters.filterByUserType = filters;
      }
      if (!isObjOrStrEmpty(filterByLastMessage)) {
        let filters: any = [...tempActiveFilters.filterByLastMessage];

        filterByLastMessage.map((activeFilterValue: any) => {
          let isExists = filters.filter(
            (filter: any) => filter.id == activeFilterValue.id
          );

          if (!isExists.length) {
            filters.push(activeFilterValue);
          }
        });
        tempActiveFilters.filterByLastMessage = filters;
      }

      if (!isObjOrStrEmpty(filterByDraftStatus)) {
        let filters: any = [...tempActiveFilters.filterByDraftStatus];

        filterByDraftStatus.map((activeFilterValue: any) => {
          //if all selected no need to add it to the filter array
          if (activeFilterValue.id == 0) {
            return;
          }
          let isExists = filters.filter(
            (filter: any) => filter.id == activeFilterValue.id
          );

          if (!isExists.length) {
            filters.push(activeFilterValue);
          }
        });
        tempActiveFilters.filterByDraftStatus = filters;
      }
      if (!isObjOrStrEmpty(filterByDraftedBy)) {
        let filters: any = [...tempActiveFilters.filterByDraftedBy];

        filterByDraftedBy.map((activeFilterValue: any) => {
          //if all selected no need to add it to the filter array
          if (activeFilterValue.id == 0) {
            return;
          }
          let isExists = filters.filter(
            (filter: any) => filter.id == activeFilterValue.id
          );

          if (!isExists.length) {
            filters.push(activeFilterValue);
          }
        });
        tempActiveFilters.filterByDraftedBy = filters;
      }

      if (!isObjOrStrEmpty(filterByTicketStatus)) {
        let filters: any = [...tempActiveFilters.filterByTicketStatus];

        filterByTicketStatus.map((activeFilterValue: any) => {
          //if all selected no need to add it to the filter array
          if (activeFilterValue.id == 0) {
            return;
          }
          let isExists = filters.filter(
            (filter: any) => filter.id == activeFilterValue.id
          );

          if (!isExists.length) {
            filters.push(activeFilterValue);
          }
        });

        tempActiveFilters.filterByTicketStatus = filters;
      }

      return tempActiveFilters;
    },
    []
  );

  //helper function which adds active "all" filter to filter array
  const margeAllFiltersToFilter = useCallback(
    (ticketBtnData: ITicketBtn[], filters: any, selectedBtn: any) => {
      const data = ticketBtnData[0];

      let activeChannelFilters: IFilterValue[] = [];
      let activeDraftStatusFilters: IFilterValue[] = [];
      let activeTicketStatusFilters: IFilterValue[] = [];

      //iterating over each sidebar button data and getting the applied filters
      data.ticketBtnList.forEach((list) => {
        //checking if selected button is "My" or "all" ticket
        if (list.keyName === selectedBtn) {
          //getting the selected options in active channels dropdown
          let activeChannelIds = list.ddOuterList[0]?.ddSelectedIndex;
          //iterating over selected ids
          activeChannelIds?.forEach((selectedId) => {
            //initial object of filter value
            let filter: IFilterValue = {
              id: selectedId,
              name: list.ddOuterList[0].ddList[selectedId].name,
              compare: "=",
              isValueRequired: true,
              viewFilter: true,
            };

            if (selectedId == 0) {
              activeChannelFilters.push(filter);
            }
          });
        }

        if (list.keyName === selectedBtn) {
          let activeDraftStatusIds = list.ddOuterList[2]?.ddSelectedIndex ?? [];
          activeDraftStatusIds?.forEach((selectedId) => {
            //initial object of filter value
            let filter: IFilterValue = {
              id: selectedId,
              name: list.ddOuterList[2].ddList[selectedId].name,
              compare: "=",
              isValueRequired: true,
              viewFilter: true,
            };

            //checking if "all" selected,
            if (selectedId == 0) {
              activeDraftStatusFilters.push(filter);
            }
          });
        }

        if (list.keyName === selectedBtn) {
          let activeTicketStatusIds = list.ddOuterList[1].ddSelectedIndex;

          activeTicketStatusIds?.forEach((selectedId) => {
            //initial object of filter value
            let filter: IFilterValue = {
              id: selectedId,
              name: list.ddOuterList[1].ddList[selectedId].name,
              compare: "=",
              isValueRequired: true,
              viewFilter: true,
            };

            //checking if "all" selected,
            if (selectedId == 0) {
              activeTicketStatusFilters.push(filter);
            }
          });
        }
      });
      let allFilters: any = {
        ...filters,
      };
      if (activeTicketStatusFilters.length) {
        allFilters.filterByTicketStatus = activeTicketStatusFilters;
      }
      if (activeDraftStatusFilters.length) {
        allFilters.filterByDraftStatus = activeDraftStatusFilters;
      }
      if (activeChannelFilters.length) {
        allFilters.filterByChannels = activeChannelFilters;
      }
      // console.log(activeChannelFilters,
      //   activeDraftStatusFilters,
      //   activeTicketStatusFilters,);

      return allFilters;
    },
    []
  );

  useEffect(() => {
    if (activeFilters) {
      let allFilters = parseFilters(activeFilters);
      setOtherFilters(Object.values(allFilters.filterTableData));
    }

    if (ticketBtnData.length) {
      let viewFilter = parseFilters(
        margeAllFiltersToFilter(ticketBtnData, currentViewFilters, selectedBtn)
      );
      setViewFilters(Object.values(viewFilter.viewFilterTableData));
    }

    currentState.current.activeFilters = activeFilters;
    currentState.current.currentViewFilters = currentViewFilters;
  }, [
    viewId,
    activeFilters,
    currentViewFilters,
    parseFilters,
    ticketBtnData,
    JSON.stringify(selectedBtn),
  ]);

  //helper function to set ticket filters to addView filters state (ticketView.slice.ts)
  const setAddViewTicketFilters = useCallback(() => {
    //updating redux activeViewId to 0, addView thunk does both edit and add , so had set the viewId to 0 to prevent updating the existing view
    dispatch(setActiveViewId({ activeViewId: 0 }));

    let mergedFilters = mergeViewFilterAndOtherFilters(
      currentState.current.currentViewFilters,
      currentState.current.activeFilters
    );

    dispatch(setFormDataFromTicketFilter({ filters: mergedFilters }));
  }, []);

  //helper function to set ticket filters to addView filters state (ticketView.slice.ts)
  const saveFilterAsView = useCallback(
    (e: any, addViewSuccessCallback: any) => {
      e.preventDefault();
      //checking if view name length is not 0, throw error if its 0
      if (viewName.trim().length === 0) {
        setShowViewNameError("view name is required");
        pushTheToast({
          type: "warning",
          text: "View Name is required!",
          position: "top-right",
        });
        return;
      }
      dispatch(addView({ callback: addViewSuccessCallback }));
    },
    [viewName]
  );

  //callback function handles on change of view name
  const viewNameOnChange = useCallback((e: any) => {
    dispatch(setFormDataByKey({ key: "viewName", data: e.target.value }));
  }, []);

  useEffect(() => {
    if (addViewState === "pending") {
      setAddViewLoading(true);
    } else if (addViewState === "rejected") {
      setShowViewNameError("view name might be already exists");
      setAddViewLoading(false);
    } else {
      setAddViewLoading(false);
    }
  }, [addViewState]);

  //callback function handler restore to default filter
  const restoreToDefaultFilters = useCallback((e: any) => {
    dispatch(resetOnlyOtherFilters());
    dispatch(resetPagination());
    dispatch(resetCachedTickets());
  }, []);

  return {
    viewFilters,
    otherFilters,
    removeFilter,
    setAddViewTicketFilters,
    saveFilterAsView,
    addViewLoading,
    viewName,
    viewNameOnChange,
    showViewNameError,
    restoreToDefaultFilters,
  };
};
