/**
 * This file is the service file used for making api request.
 * It contains the updateArticleFeedback function service which is explained below.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants"; // this is used for testing purpose only for frontend.

export interface UpdateArticleFeedbackPayload {
  integrationId: number;
  articleFeedbackId: number | string;
  ratingStatus?: 1 | 2 | 3; // 1: approved, 2: pending, 3: rejected
  commentAction?: 1 | 2 | 3; // 1: approved, 2: pending, 3: rejected
}

export interface UpdateArticleFeedbackResponse {
  err: boolean;
  msg: string;
}

/**
 * For testing frontend with own api link if backend is not prepared.
 */
const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/updateArticleFeedback`
  : "/api/chatSetting/updateArticleFeedback";

/**
 * This service is created to update the permission actions on the feedbacks given by the customers on the articles on the helpcenter page.
 */
export const updateArticleFeedback = async (
  params: UpdateArticleFeedbackPayload
) => {
  const { data: res } = await axiosJSON.post(apiEndPoint, params);
  return res as UpdateArticleFeedbackResponse;
};
