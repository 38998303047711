import styles from "./TrafficItem.module.scss";
import wait from "src/assets/images/hourglass.png";
import alert from "src/assets/images/exclamationMark.png";
import { ITrafficListItem } from "src/services/LiveChat/traffic/getTrafficList.service";
import { ELiveChatTrafficActions } from "src/enums/ELiveChatTrafficActions";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  resetChatAssignStatusCode,
  setAssignChatAjaxStatus,
} from "src/store/slices/liveChatTraffic/liveChatTraffic.slice";
import {
  assignChat,
  IAssignChatRes,
} from "src/services/LiveChat/traffic/assignChatFromTraffic.service";
import { ELiveChatAssignChatStatusCode } from "src/enums/ELiveChatAssignChatStatusCode";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";

const TrafficItem = ({ itemData }: { itemData: ITrafficListItem }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { assignChatAjaxStatus, assignChatStatusCode } = useAppSelector(
    (state) => state.liveChatTraffic
  );

  const handleActionButtonClick = (actionType: string) => {
    if (actionType === ELiveChatTrafficActions.gotoChat) {
      // goto chat
      navigate(`/live-chat/chats/live/${itemData.id}/chattingTab/conversation`);
    } else if (actionType === ELiveChatTrafficActions.startChat) {
      dispatch(setAssignChatAjaxStatus({ ajaxStatus: "pending" }));
      assignChat({ chatRequestId: itemData.id })
        .then((res: IAssignChatRes) => {
          dispatch(setAssignChatAjaxStatus({ ajaxStatus: "fulfilled" }));
          if (res.statusCode === ELiveChatAssignChatStatusCode.CHAT_ASSIGNED) {
            pushTheToast({
              position: "top-right",
              type: "success",
              text: "Chat assigned.",
            });
            navigate(
              `/live-chat/chats/live/${itemData.id}/chattingTab/conversation`
            );
          } else if (
            res.statusCode === ELiveChatAssignChatStatusCode.CHAT_NOT_ASSIGNED
          ) {
            pushTheToast({
              position: "top-right",
              type: "warning",
              text: "Failed to assign chat.",
            });
          } else if (
            res.statusCode ===
            ELiveChatAssignChatStatusCode.INVALID_CHAT_REQUEST_ID
          ) {
          } else if (
            res.statusCode === ELiveChatAssignChatStatusCode.INVALID_USER
          ) {
          } else if (
            res.statusCode === ELiveChatAssignChatStatusCode.LIMIT_REACHED
          ) {
            pushTheToast({
              position: "top-right",
              type: "warning",
              text: "Chat limit reached.",
            });
          }
        })
        .catch((err) => {
          dispatch(setAssignChatAjaxStatus({ ajaxStatus: "rejected" }));
          pushTheToast({
            position: "top-right",
            type: "danger",
            text: err,
          });
        });
    } else if (actionType === ELiveChatTrafficActions.supervise) {
      // supervise the chat
      navigate(`/live-chat/chats/live/${itemData.id}/chattingTab/conversation`);
    }
  };

  const concatinateAgentNames = useCallback((assignedAgents: any) => {
    return assignedAgents
      .map((agentInfo: any) => agentInfo.name ?? "NA")
      .join(", ");
  }, []);

  return (
    <div className={`${styles.trafficRow}`}>
      <div className={`ps-3 ${styles.widthSet}`}>
        <span className={`${styles.rowName}`}>{itemData.customerName}</span>
      </div>
      <div className={`${styles.widthSet}`}>
        <span className={`${styles.rowEmail}`}>{itemData.customerEmail}</span>
      </div>
      <div className={`${styles.widthSet} pe-1`}>
        <span className={`${styles.rowChatWith}`}>
          {itemData?.brand.name ?? ""}
        </span>
      </div>
      <div className={`d-flex align-items-center ${styles.widthSet} ${styles.mobWidth}`}>
        <div>
          {itemData.action === ELiveChatTrafficActions.gotoChat && (
            <button
              className={`me-2 ${styles.rowBtn}`}
              onClick={(e) =>
                handleActionButtonClick(ELiveChatTrafficActions.gotoChat)
              }
            >
              Go to Chat
            </button>
          )}
          {itemData.action === ELiveChatTrafficActions.supervise && (
            <button
              className={`me-2 text-center ${styles.rowBtnSupervise}`}
              onClick={(e) =>
                handleActionButtonClick(ELiveChatTrafficActions.supervise)
              }
            >
              Supervise
            </button>
          )}
          {itemData.action === ELiveChatTrafficActions.startChat && (
            <button
              className={`me-2 ${styles.rowBtnStartChat}`}
              onClick={(e) =>
                handleActionButtonClick(ELiveChatTrafficActions.startChat)
              }
            >
              Start Chat
            </button>
          )}
        </div>
        <div>
          <img src={alert} alt="#" />
        </div>
      </div>
      <div className={`${styles.widthSet}`}>
        {/* ---chatting--- */}
        {itemData.status === "chatting" && (
          <span>
            <span className={`me-2 ${styles.activityDot}`}></span>
            <span className={`${styles.rowChatWith}`}>Chatting</span>
          </span>
        )}

        {/* ---invited--- */}
        {itemData.status === "invited" && (
          <span>
            <span className={`me-2 ${styles.invitedDot}`}></span>
            <span className={`${styles.rowChatWith}`}>Invited</span>
          </span>
        )}
        {/* ---waiting for reply--- */}
        {itemData.status === "waiting_for_reply" && (
          <span>
            <span className={`me-2`}>
              <img className={`${styles.waitImg}`} src={wait} alt={`#`} />
            </span>
            <span className={`${styles.rowChatWith}`}>Waiting for reply</span>
          </span>
        )}
        {/* ---In Queued--- */}
        {itemData.status === "queued" && (
          <span>
            <span className={`me-2`}>
              <span className={`${styles.waiting}`}>
                {" "}
                <i className="fa-solid fa-clock"></i>
              </span>
            </span>
            <span className={`${styles.rowChatWith}`}>In Queue</span>
          </span>
        )}
        {/* ---left website--- */}
        {/* <span className={`${styles.leftWeb}`}>Left website</span> */}
      </div>
      <div className={`${styles.widthSet}`}>
        <span className={`${styles.rowChatWith}`}>
          {itemData.assignedAgents && itemData.assignedAgents.length !== 0
            ? concatinateAgentNames(itemData.assignedAgents)
            : "NA"}
        </span>
      </div>
    </div>
  );
};

export default TrafficItem;
