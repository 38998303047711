import { axiosJSON } from "src/globals/axiosEndPoints";

interface MetaData {
  count: number;
  hasNextPage: boolean;
}

export interface GetAllProductTags {
  data: string[];
  metaData: MetaData;
}

export interface GetAllProductTagsParams {
  limit: number;
}

export async function getAllProductTags(payload: GetAllProductTagsParams) {
  const { data } = await axiosJSON.post("/api/shopify/getAllProductTags");

  if (data.error === true) {
    throw new Error("Some Error Occurred while fetching data");
  }

  return { data: data.data, metaData: data.metaData };
}
