import { useCallback, useMemo, useState } from "react";
import { useAutomationContext } from "src/routes/BotSettings/Children/Automation/Hooks/useAutomationContext";
import {
  IRadioQuestion,
  Question,
  QuestionType,
} from "src/services/Automation/getAutomationById.service";
import styles from "./RadioQuestion.module.scss";
import { useParams } from "react-router-dom";
import {
  EAutomationType,
  IAutomationListParams,
} from "src/routes/BotSettings/Children/AutomationList/AutomationList";

interface QuestionsProps {
  questionData: IRadioQuestion;
  stepId: string;
  setShowErrorModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

const isRadioQuestion = (question: Question): question is IRadioQuestion => {
  return question.questionType === QuestionType.Radio;
};

const RadioQuestion = ({
  questionData,
  stepId,
  setShowErrorModal,
}: QuestionsProps) => {
  const { updateQuestions, saveQuestionsData, formData } =
    useAutomationContext();
  const { type } = useParams<IAutomationListParams>();

  const onChangeHandler = useCallback(
    (optionKey: string) => {
      if (type == EAutomationType.TICKET_ROUTING && formData?.published) {
        if (setShowErrorModal) setShowErrorModal(true);
        return;
      }
      if (isRadioQuestion(questionData)) {
        updateQuestions(
          {
            ...questionData,
            questionValue: optionKey,
            isAnswered: optionKey !== "",
          },
          formData.moveToNextQuestionKey !== questionData.questionKey,
        );

        if (optionKey !== "") {
          saveQuestionsData({
            stepId,
            stepQuestions: {
              [questionData.questionKey]: optionKey,
            },
          });
        }
      }
    },
    [
      formData.moveToNextQuestionKey,
      questionData,
      saveQuestionsData,
      stepId,
      updateQuestions,
    ],
  );

  const showConfigButton = useMemo(
    () => {
      return false;
      // return (
      //   questionData.questionKey == "issueMonitoring" &&
      //   questionData.questionValue === "RequestsForSpecificUserTypes"
      // );
    },
    [
      // questionData
    ],
  );

  const [isConfigClicked, setIsConfigClicked] = useState(false);

  return (
    <div>
      {questionData.listOfOptions &&
        questionData.listOfOptions.map(
          ({ optionKey, optionLabel, isDisabled, isHidden }, index) => {
            if (isHidden) {
              return null;
            }

            return (
              <div
                className="form-check"
                key={index}
              >
                <input
                  className={`form-check-input ${styles.checkBox}`}
                  type="radio"
                  id={`${optionKey}_${index}`}
                  disabled={
                    isDisabled ||
                    (questionData.isBrandRequired && formData.brand === null)
                  }
                  checked={questionData.questionValue === optionKey}
                  onChange={() => onChangeHandler(optionKey)}
                />
                <label
                  className={`form-check-label ${styles.inputCheck}`}
                  htmlFor={`${optionKey}_${index}`}
                >
                  {optionLabel}
                </label>
              </div>
            );
          },
        )}
      {/* 09 Sep 2024 Hiding this as it is added in exceptions */}
      {/* {type === EAutomationType.ISSUE_DISPATCH && showConfigButton ? (
        <div className={`mt-2 d-flex`}>
          <a
            className={`d-flex align-items-center justify-content-center ${
              styles.createSegmentLink
            } ${isConfigClicked ? styles.clicked : ""} `}
            href="/customer-segments"
            target="_blank"
            onClick={() => setIsConfigClicked(true)}
          >
            + Configure user segment
          </a>
        </div>
      ) : null} */}
    </div>
  );
};

export default RadioQuestion;
