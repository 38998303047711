import { SetStateAction, useContext, useEffect } from "react";
import { addContact } from "src/store/slices/editCustomerDetails/editCustomerDetails.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import styles from "../CustomerEdit/CustomerEdit.module.scss";
import { InputFieldsContext } from "../CustomerEdit/useInputFields";
import OtherEmail from "../OtherEmail/OtherEmail";
import { Dropdown } from "react-bootstrap";

function AddContact() {
  const inputFieldsContext = useContext(InputFieldsContext);

  function getIsAddBtnEnabled(key: string) {
    const fields = inputFieldsContext.fields.get(key);

    if (fields && fields.length === 0) {
      return true;
    }

    return fields?.length
      ? fields[fields.length - 1]?.isValid
        ? true
        : false
      : false;
  }
  const whatsappNumbers = inputFieldsContext.fields.get("whatsapp");
  return (
    <div>
      <Dropdown>
        <Dropdown.Toggle
          as="div"
          id="dropdown-basic"
          className={`d-flex justify-content-center align-items-center ${styles.dropBtn}`}
        >
          <div
            className={`d-flex align-items-center justify-content-center
    ${styles.addIcon}`}
          >
            <i className="fa-solid fa-plus"></i>
          </div>
          <span className={`${styles.addSpan}`}>Add contact</span>
        </Dropdown.Toggle>

        <Dropdown.Menu
          bsPrefix={`pe-1 mt-2 dropdown-menu ${styles.customerDropDown} ${styles.customerDropDown2}`}
        >
          <li
            className={`d-flex`}
            role="button"
            onClick={() => {
              if (!getIsAddBtnEnabled("otherEmails")) {
                return;
              }
              // dispatch(
              //   addContact({
              //     id: id as string,
              //     type: "otherEmails",
              //     value: "",
              //   })
              // );
              inputFieldsContext.addField({ value: "" }, "otherEmails");
            }}
          >
            <div className={`${styles.hoverBar}`}></div>
            <span className="dropdown-item">Email</span>
          </li>
          <li
            className={`d-flex`}
            role="button"
            onClick={() => {
              if (!getIsAddBtnEnabled("phoneNumber")) {
                return;
              }
              // dispatch(
              //   addContact({
              //     id: id as string,
              //     type: "phone",
              //     value: "",
              //     countryCode: "",
              //   })
              // );
              inputFieldsContext.addField(
                { value: { phoneNumber: "", countryCode: "91" } },
                "phoneNumber"
              );
            }}
          >
            <div className={`${styles.hoverBar}`}></div>
            <span className="dropdown-item">Phone</span>
          </li>

          <li
            className={`d-flex`}
            role="button"
            onClick={() => {
              if (!getIsAddBtnEnabled("instagram")) {
                return;
              }
              // dispatch(
              //   addContact({
              //     id: id as string,
              //     type: "instagram",
              //     value: "",
              //   })
              // );
              inputFieldsContext.addField({ value: "" }, "instagram");
            }}
          >
            <div className={`${styles.hoverBar}`}></div>
            <span className="dropdown-item">Instagram</span>
          </li>
          <li
            className={`d-flex`}
            role="button"
            onClick={() => {
              if (!getIsAddBtnEnabled("facebook")) {
                return;
              }
              // dispatch(
              //   addContact({
              //     id: id as string,
              //     type: "facebook",
              //     value: "",
              //   })
              // );
              inputFieldsContext.addField({ value: "" }, "facebook");
            }}
          >
            <div className={`${styles.hoverBar}`}></div>
            <span className="dropdown-item">Facebook</span>
          </li>
          <li
            className={`d-flex`}
            role="button"
            onClick={() => {
              if (!getIsAddBtnEnabled("twt")) {
                return;
              }
              // dispatch(
              //   addContact({
              //     id: id as string,
              //     type: "twitter",
              //     value: "",
              //   })
              // );
              inputFieldsContext.addField({ value: "" }, "twt");
            }}
          >
            <div className={`${styles.hoverBar}`}></div>
            <span className="dropdown-item">Twitter</span>
          </li>
          {/* 22 Jan Adding whatsapp in the list */}
          {/* <li
            className={`d-flex`}
            role="button"
            onClick={() => {
              if (!getIsAddBtnEnabled("whatsapp")) {
                return;
              }
              // dispatch(
              //   addContact({
              //     id: id as string,
              //     type: "twitter",
              //     value: "",
              //   })
              // );
              // Jan 27 Added condition to allow only once whatsapp number
              if(!whatsappNumbers || (whatsappNumbers && whatsappNumbers?.length < 1)){
                inputFieldsContext.addField(
                  { value: { phoneNumber: "", countryCode: "91" } },
                  "whatsapp"
                );
              }
            }}
          >
            <div className={`${styles.hoverBar}`}></div>
            <span className="dropdown-item">Whatsapp</span>
          </li> */}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

export default AddContact;
