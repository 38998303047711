import { axiosJSON } from "src/globals/axiosEndPoints";

interface CategoryDetails {
  categoryId: number;
  categoryName: string;
}

interface CustomerDetails {
  customerId: number;
  customerName: string;
  customerEmail: string;
  imageUrl: string | null;
  isPublicAttachment: boolean;
}

interface BugAttachmentDetails {
  attachmentId: number;
  attachmentBatchNumber: string;
  attachmentName: string;
  attachmentSize: number;
  attachmentType: string;
  attachmentUrl: string;
  isPublicAttachmentUrl: boolean;
}

export interface MergeBugReport {
  bugId: number;
  bugDescription: string;
  createdAtGmt: string;
  updatedAtGmt: string;
  bugStatusKey: string | null;
  priorityKey: string | null;
  sessionId: string;
  categoryDetails: CategoryDetails;
  ticketId: number[];
  customerDetails: CustomerDetails[];
  bugAttachmentsDetails: BugAttachmentDetails[];
  companiesName: Array<string | null>;
  bugReportedUrl: string[];
}


const mergeBug = async (param: { bugIds: number[] }) => {
  const { data: res } = await axiosJSON.post(
    `/api/selfService/bugManagement/mergeBugs`,
    param,
  );

  if (res.err || res.error) {
    throw res.msg ?? res.message ?? "Something went wrong!";
  }

  return res.data as MergeBugReport;
};

export default mergeBug;