import styles from "./LiveChatApi.module.scss";
const LiveChatApi = ({goalValue,setGoalValue, goalValueType, setGoalValueType}:any) => {
  return (
    <div className={`${styles.chatApi}`}>
      <p className={`${styles.heading}`}>
        This goal must be marked as successful using the Goals API.
      </p>
      <span className={`${styles.subHeading}`}>
        Note: use this method when a successful chat is supposed to end on a
        site without LiveChat tracking code.
      </span>
    </div>
  );
};
export default LiveChatApi;
