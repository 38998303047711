import { useMemo, useState } from "react";
import styles from "./UploadedCsvModal.module.scss";
import { useReturnAutoWorkFlow } from "src/features/ReturnAutoWorkFlow/hooks/useReturnAutoWorkFlow";
import Loader from "src/components/Loader";
import getAllImportedProducts, {
  GetAllImportedProductsParams,
} from "src/services/ReturnAutoWorkFlow/ConfigModals/getAllImportedProducts.service";
import ProductsTable from "./ProductsTable/ProductsTable";
import { useQuery } from "@tanstack/react-query";
import { ImportedCSVTable } from "src/features/ReturnAutoWorkFlow/ReturnAutoWorkFlow.types";
import SkeletonModalStructure from "src/components/AutomationComponents/SkeletonModalStructure/SkeletonModalStructure";

/**
 * Custom hook to handle the data for the Uploaded CSV modal.
 *
 * This hook uses the `useQuery` hook from React Query to fetch and manage the state of product variant data.
 * It transforms the raw data into a structured format suitable for use in the modal, including processing
 * table headings and variant information.
 *
 * @param {GetAllImportedProductsParams} params - The parameters used to fetch product variant data.
 * @returns object - An object containing the formatted products details, loading state, and error state.
 */
export const useUploadedCSVModal = ({
  isImportLoading,
  ...params
}: GetAllImportedProductsParams & { isImportLoading?: boolean }) => {
  const { data, isLoading, isError } = useQuery<ImportedCSVTable>({
    queryKey: ["getAllImportedProducts", params, isImportLoading],
    queryFn: () => getAllImportedProducts({ ...params }),
    enabled: isImportLoading ? false : true,
  });
  return {
    data: data,
    isLoading,
    isError,
  };
};

const UploadedCsvModal = ({ onHide }: { onHide: () => void }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const { returnAutoWorkFlow } = useReturnAutoWorkFlow();
  const limit = 10;
  /**
   * Starting index for API call
   */
  const start = useMemo(() => {
    return limit * currentPage - limit;
  }, [currentPage]);

  const params: GetAllImportedProductsParams = {
    integrationId: returnAutoWorkFlow?.integrationId ?? "",
    start: start,
    limit: limit,
  };

  const { isError, isLoading, data } = useUploadedCSVModal(params);

  /**
   * Total pages available
   */
  const totalPages = useMemo(() => {
    return data?.totalVarients ? Math.ceil(data?.totalVarients / limit) : 0;
  }, [data?.totalVarients]);

  return (
    <div>
      <SkeletonModalStructure
        hasTwoSection={false}
        heading="Uploaded CSV file of your products to automate the returns process"
        closeFunction={onHide}
      >
        {isLoading ? (
          <div className={`mt-3 ${styles.contentWrapper}`}>
            <Loader />
          </div>
        ) : isError ? (
          <div className={`mt-3 ${styles.contentWrapper}`}>
            <div className="d-flex align-items-center justify-content-center text-danger">
              {"Something went wrong!"}
            </div>
          </div>
        ) : data ? (
          <div className={`mt-3 ${styles.contentWrapper}`}>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4 className={`${styles.heading}`}>Imported file</h4>
                <p className={`mb-0 ${styles.subHeading}`}>
                  <span className={`${styles.fileName}`}>File name- </span>
                  <span className={`${styles.fileDesc}`}>
                    {data?.fileName ?? ""}
                  </span>
                </p>
              </div>
              <div className="d-flex">
                <div className="text-center me-4">
                  <p className={`mb-1 ${styles.prodValue}`}>
                    {(data?.totalProducts ?? 0) > 9
                      ? data?.totalProducts ?? 0
                      : `0${data?.totalProducts}`}
                  </p>
                  <span className={`${styles.prod}`}>Products</span>
                </div>
                <div className="text-center">
                  <p className={`mb-1 ${styles.prodValue}`}>
                    {data?.totalSKU ?? 0}
                  </p>
                  <span className={`${styles.prod}`}>Total SKU</span>
                </div>
              </div>
            </div>
            <div className={`mt-3 ${styles.table}`}>
              <ProductsTable
                data={data}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPages={totalPages}
                integrationId={returnAutoWorkFlow?.integrationId ?? ""}
                viewContext="oldUploads"
              />
            </div>
          </div>
        ) : null}
      </SkeletonModalStructure>
    </div>
  );
};

export default UploadedCsvModal;
