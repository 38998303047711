import { ImportedCSVTable } from "src/features/ReturnAutoWorkFlow/ReturnAutoWorkFlow.types";
import TableHeader from "../TableHeader/TableHeader";
import TableRow from "../TableRow/TableRow";
import Pagination from "src/routes/TicketList/children/Pagination/Pagination";
import styles from "./ProductsTable.module.scss";

const ProductsTable = ({
  data,
  currentPage,
  totalPages,
  setCurrentPage,
  integrationId,
  viewContext = "oldUploads",
}: {
  data: ImportedCSVTable;
  currentPage: number;
  totalPages: number;
  setCurrentPage: (currentPage: number) => void;
  integrationId: string;
  viewContext?: "oldUploads" | "newUploads";
}) => {
  return (
    <div>
      <div
        className={`${styles.container} ${
          viewContext === "newUploads" ? styles.forNewUpload : ""
        } mb-1`}
      >
        <TableHeader
          tableHeadings={data?.tableHeadings ?? {}}
          tableHeadingIds={data?.tableHeadingIds ?? []}
        />
        {data?.varientIds && data?.varientIds?.length > 0 ? (
          <div>
            {data?.varientIds.map((id) => {
              const varientData = data?.varients[id];
              return (
                <TableRow
                  key={id}
                  varientData={varientData ?? null}
                  integrationId={integrationId}
                />
              );
            })}
          </div>
        ) : (
          <div className="d-flex align-items-center justify-content-center mt-5">
            No Products Found
          </div>
        )}
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
};

export default ProductsTable;
