import ParseHTML from "src/components/ParseHTML";
import styles from "./Message.module.scss";
import { dateFormate } from "../dateFormate";
import { BugComments } from "src/services/BugReport/getBugComment";
import UserAvatar from "src/components/UserAvatar";
import { capitalizeFirstLetter } from "../../../../../helper/helperFunction";
import downloadFile from "src/services/BugReport/downloadFile.service";
import InnerChatAttachments from "src/components/InnerChatAttachments/InnerChatAttachments";
import AxiosImg from "src/components/AxiosImg";
import useUTCToUserTime from "src/hooks/dateHooks/useUTCToUserTime";

interface MessageProps {
  messageData: BugComments;
}

const Message = ({ messageData }: MessageProps) => {
  const createDate = useUTCToUserTime({
    timeInUTC: messageData.commentCreatedAtGmt
  });
  return (
    <div className="d-flex mb-4">
      {/* image or userAvatar of the sender */}
      {messageData.sentBy.imageURL ? (
        <AxiosImg
          url={messageData.sentBy.imageURL}
          alt=""
          className={`${styles.userImg}`}
        />
      ) : (
        <UserAvatar
          name={messageData.sentBy.name}
          className={`${styles.userImg}`}
          size={21}
        />
      )}
      <div className="ps-2 mt-1">
        <div className={`mb-1 ${styles.userHead}`}>
          {capitalizeFirstLetter(messageData.sentBy.name)}: {/* msg show */}
          {messageData.commentMessage ? (
            <ParseHTML html={messageData.commentMessage} />
          ) : messageData?.message ? (
            <ParseHTML html={messageData.message} />
          ) : (
            ""
          )}
          {/* attachment show */}
          {messageData.attachmentDetails?.map((file) => (
            <div key={file.attachmentId}>
              {file.attachmentType.split("/")[0] === "image" &&
              file.attachmentType.split("/")[1] !== "tiff" &&
              file.isPublicAttachmentUrl ? (
                <a
                  href={file.attachmentUrl}
                  key={file.attachmentId}
                  target="_blank"
                >
                  <AxiosImg
                    url={file.attachmentUrl}
                    alt="attachment file"
                    className={`${styles.playImg} mb-2`}
                  />
                </a>
              ) : (
                <InnerChatAttachments messageData={{ attachments: [file] }} />
              )}
            </div>
          ))}
        </div>
        <span className={`${styles.userSub}`}>
          {" "}
          {createDate ? dateFormate(createDate, true) : "Date not available"}
        </span>
      </div>
    </div>
  );
};

export default Message;
