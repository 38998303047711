import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import backArrow from "src/assets/images/backArrow.png";
import Loader from "src/components/Loader";
import SlackIntegratedBrand from "./Childern/SlackInegratedBrand/SlackIntegratedBrand";
import styles from "./SlackIntegration.module.scss";
import useGetAllSlackIntegrations from "./useGetAllSlackIntegrations";
import celebration from "src/assets/images/celebration.json";
import Lottie from "lottie-light-react";
import { Modal } from "react-bootstrap";

const SlackIntegration = () => {
  const navigate = useNavigate();
  const [slackIntegrationData, setSlackIntegrationData] = useState<any>([]);
  const { integratedSlack, intgraatedSlackId, isLoading } =
    useGetAllSlackIntegrations();

  useEffect(() => {
    if (integratedSlack) {
      const slackData = Object.entries(integratedSlack).map(
        ([key, slack]) => slack,
      );
      setSlackIntegrationData(slackData);
    }
  }, [integratedSlack]);

  // State for showing and hiding Modal
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const status = queryParams.get("status");
    if (status === "success") {
      setShowModal(true);
    }
  }, []);

  return (
    <div className={styles.mainWrapper}>
      <div className="d-flex w-100">
        <div className="edit-icons">
          <img
            src={backArrow}
            alt="backArrow"
            width={25}
            onClick={() => navigate("/settings/browse-integration")}
          />
        </div>
        <div className="ms-3 w-100">
          <div className={styles.headingWrapper}>
            <h1 className={`mb-0 ${styles.heading}`}>Slack</h1>
            <span className={`${styles.subHeading}`}>
              Connect your helpdesk with Slack to manage customer queries in
              real-time.
            </span>
          </div>
          <div className={styles.chooseBoxMain}>
            <div className={`${styles.chooseBox} py-3 px-4`}>
              <div className="d-flex justify-content-between">
                <div className={`mb-2 ${styles.chooseBoxHead} mt-0`}>
                  Your Linked Slack accounts.
                </div>
              </div>

              {!intgraatedSlackId || intgraatedSlackId.length === 0 ? (
                isLoading ? (
                  <div className="w-100 d-flex justify-content-center align-items-center">
                    <Loader />
                  </div>
                ) : (
                  <div>
                    <div className={`mb-1`}>
                      <span className={`${styles.chooseBoxSpan}`}>
                        You have no Slack account linked at the moment.
                      </span>
                    </div>
                    <div className={styles.addSlackBtnDiv}>
                      <button
                        className={`${styles.addSlackBtn} mt-4 px-2 d-flex justify-content-center align-items-center me-3 mb-2`}
                        onClick={() => {
                          navigate("/settings/integrations/slack/addNew");
                        }}
                      >
                        <span> Add Integration</span>
                      </button>
                    </div>
                  </div>
                )
              ) : intgraatedSlackId?.length ? (
                <div>
                  {integratedSlack &&
                    slackIntegrationData.map((slack: any) => (
                      <SlackIntegratedBrand
                        brand={slack}
                        setSlackIntegrationData={setSlackIntegrationData}
                      />
                    ))}
                </div>
              ) : (
                ""
              )}
            </div>
            {intgraatedSlackId?.length ? (
              <div
                className={styles.addNew}
                onClick={() => navigate("/settings/integrations/slack/addNew")}
              >
                + &nbsp;Add new
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName={`${styles.modalDialog}`}
        contentClassName={`${styles.modalContent}`}
        centered={true}
      >
        <div className={styles.succesDiv}>
          <div>
            <div className={styles.successlogo}>
              <i className="fa-solid fa-check"></i>
            </div>
          </div>
          <div className={styles.successMsgHeading}>
            <h5>Slack integration was successful!</h5>
          </div>
          <div className={styles.successMsg}>
            <p>
              Your Slack integration with Saufter Helpdesk has been scucessfully
              set up. You're now connected and can start receiving notifications
              directly in your Slack channels.
            </p>
          </div>
          <div className={`${styles.btnContainer} cursor-pointer`}>
            <button
              className={styles.done}
              onClick={() => setShowModal(false)}
            >
              <span className={styles.doneText}>Done</span>
            </button>
          </div>
          <Lottie
            loop={false}
            className={`${styles.celebrationAnimation}`}
            animationData={celebration}
          ></Lottie>
        </div>
      </Modal>
    </div>
  );
};

export default SlackIntegration;
