import { axiosJSON } from "src/globals/axiosEndPoints";

export interface IShopifyCustomerAddress{
    firstName: string,
    lastName: string,
    name: string,
    company: string | null,
    address1: string | null,
    address2: string | null,
    city: string | null,
    province: string | null,
    country: string | null,
    zip: string | null,
    phone:  string | null,
    provinceCode:  string | null,
    countryCode:  string | null,
    countryName:  string | null,

}

export interface IShopifyCustomerData{
    customerId: number,
    customerEmail: string,
    customerName: string,
    customerPhone: string,
    totalOrdersCount: number,
    totalAmountSpent:{
        amount: string,
        currencyCode: String,
    },
    customerAddress: [IShopifyCustomerAddress]
}

export interface IGetShopifyCustomerDataParams{
    customerId: number
}

export async function getShopifyCustomerData(params: IGetShopifyCustomerDataParams){
    let {data: res} = await axiosJSON.post(
        `/api/shopify/customer/get`,
        params
    );

    if(res.error === true){
        throw new Error(res.message as string);
    }

    return res.data as IShopifyCustomerData;
}