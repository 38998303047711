import { Dropdown } from "react-bootstrap";
import InfiniteScroll from "src/components/InfiniteScrollBothSide";
import SendMessage from "src/routes/Ticket/children/MainChat/children/SendMessage";
import ResponseModal from "./children/ResponseModal/ResponseModal";
import useSendMessage from "./hooks/useSendMessage";
import styles from "./SendMessageWrapper.module.scss";
import { POPPER_CONFIG } from "src/globals/constants";
import { EChannel } from "src/enums/EChannel";
import { useEffect, useMemo, useState } from "react";
import { useAppSelector } from "src/store/store";
import isIntegratedChannelService from "src/services/TicketService/InternalChatBot/isIntegratedChannels.service";

function SendMessageWrapper({
  customerId,
  fetchLastMessages,
  smallWindowChat = false,
  internalChat = false,
}: {
  customerId: string;
  fetchLastMessages: () => void;
  smallWindowChat?: boolean;
  internalChat?: boolean;
}) {
  const {
    ticketStatusList,
    ticketsData,
    selectedTicket,
    handleTicketChange,
    sendMessages,
    changeTheCustomer,
    hasMoreTickets,
    fetchNextTickets,
    showModal,
    setShowModal,
    preSendMessage,
    showDropDown,
    setShowDropDown,
    sendType,
    setSendType,
    sendStatus,
    integrationFailureData,
    replyOption,
    setReplyOption,
  } = useSendMessage({ customerId, fetchLastMessages });
  const [msgChannel, setMsgChannle] = useState(EChannel.email);

  const [isSlackIntegrated, setIsSlackIntegrated] = useState(false);

  const brandId = useMemo(() => {
    return integrationFailureData?.brand?.id;
  }, [integrationFailureData]);

  const allowedNoteChannels = useMemo(() => {
    const noteOptions: Array<EChannel> = [];

    if (!noteOptions.includes(EChannel.slack)) {
      noteOptions.push(EChannel.slack);
    }

    return noteOptions.filter((v) => {
      if (v === EChannel.slack) {
        if (isSlackIntegrated) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    });
  }, [isSlackIntegrated]);

  useEffect(() => {
    if (brandId) {
      isIntegratedChannelService({
        brandId: brandId,
        channels: ["slack"],
      }).then((res) => {
        const isSlackIntegrated = res.some(
          (val) => val.channelName === EChannel.slack && val.isIntegrated,
        );
        setIsSlackIntegrated(isSlackIntegrated);
      });
    } else {
      setIsSlackIntegrated(false);
    }
  }, [brandId, customerId]);

  return (
    <SendMessage
      TicketSelectorComponent={
        <div className={`${styles.replyBox} mb-2`}>
          <p
            className={`mb-0 ${styles.replyPara} ${
              smallWindowChat && styles.smallFont
            }`}
          >
            Reply to ticket :
          </p>
          <div className={`${styles.dropBtn}`}>
            <Dropdown
              show={showDropDown}
              onToggle={setShowDropDown}
              bsPrefix={`dropdown w-100 cursor-pointer`}
            >
              <Dropdown.Toggle
                bsPrefix={`${styles.customDropDownReply}`}
                as="div"
              >
                <p className="d-flex align-items-center mb-0 w-100">
                  <span className={`pe-1 ${styles.dropText}`}>
                    {selectedTicket
                      ? `Ticket ID: ${selectedTicket.ticket_id} <${selectedTicket.ticket_subject}>`
                      : `Select`}
                  </span>
                  <span className={`${styles.arrowBtn}`}>
                    {showDropDown ? (
                      <>
                        <span>
                          {" "}
                          <i className="fa-solid fa-caret-up"></i>
                        </span>
                      </>
                    ) : (
                      <>
                        <span>
                          {" "}
                          <i className="fa-solid fa-caret-down"></i>
                        </span>
                      </>
                    )}
                  </span>
                </p>
              </Dropdown.Toggle>

              <Dropdown.Menu
                popperConfig={POPPER_CONFIG}
                bsPrefix={`dropdown-menu ${styles.dropMenuReply}`}
              >
                <InfiniteScroll
                  className={`${styles.dorpDownScroll}`}
                  hasMoreBottom={hasMoreTickets}
                  loadMoreFromBottom={fetchNextTickets}
                >
                  {ticketsData.ticketIds.map((ticket_id) => {
                    const ticket = ticketsData.tickets[ticket_id];
                    return (
                      <Dropdown.Item
                        data-value={ticket_id}
                        key={ticket_id}
                        onClick={handleTicketChange}
                      >
                        <span className="d-inline-block w-100 text-truncate">
                          {`Ticket ID: ${ticket_id} <${ticket.ticket_subject}>`}
                        </span>
                      </Dropdown.Item>
                    );
                  })}
                </InfiniteScroll>
              </Dropdown.Menu>
            </Dropdown>
            {selectedTicket && (
              <ResponseModal
                showModal={showModal}
                setShowModal={setShowModal}
                selectedTicket={selectedTicket}
                sendMessages={sendMessages}
                setShowDropDown={setShowDropDown}
                sendType={sendType}
              />
            )}
          </div>
        </div>
      }
      ticketId={
        selectedTicket?.ticket_id ? selectedTicket.ticket_id + "" : undefined
      }
      autosavePrefix={`HELPDESK_LIVE_CHAT_HISTORY_${customerId}`}
      allowDraft={false}
      ticket_channel={selectedTicket?.ticket_channel ?? "Email"}
      message_thread={selectedTicket?.message_thread}
      ticket_from={selectedTicket?.ticket_from ?? ""}
      ticketStatusList={ticketStatusList}
      ticket_to={selectedTicket?.ticket_to ?? ""}
      ticket_cc={selectedTicket?.ticket_cc ?? ""}
      sendMessages={preSendMessage}
      changeTheCustomer={changeTheCustomer}
      sendType={sendType}
      setSendType={setSendType}
      sendStatus={sendStatus}
      isToDisabled={true}
      integrationFailure={integrationFailureData}
      replyOption={replyOption}
      setReplyOption={setReplyOption}
      allowedChannels={[EChannel.email]}
      messageChannel={msgChannel}
      setMessageChannel={setMsgChannle}
      smallWindowChat={smallWindowChat}
      allowedNoteChannels={allowedNoteChannels}
      internalChat={internalChat}
      // isFromDisabled={true}
    />
  );
}

export default SendMessageWrapper;
