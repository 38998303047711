/**
 * This file is the react component file.
 * It contains the KbPreview component.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 * @author Navjyot
 */

import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useRef, useState } from "react";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Loader from "src/components/Loader";
import ToastContainer from "src/containers/ToastContainer/ToastContainer";
import ArticleInfo from "src/routes/KnowledgeBaseUpdated/Children/TabSection/KnowledgeSetting/Children/ArticleSideBar/ThemeSelection/Children/ThemeSetting/Children/PreviewSection/Children/ArticleInfo/ArticleInfo";
import PassProtect from "src/routes/KnowledgeBaseUpdated/Children/TabSection/KnowledgeSetting/Children/ArticleSideBar/ThemeSelection/Children/ThemeSetting/Children/PreviewSection/Children/PassProtect/PassProtect";
import PreviewSection from "src/routes/KnowledgeBaseUpdated/Children/TabSection/KnowledgeSetting/Children/ArticleSideBar/ThemeSelection/Children/ThemeSetting/Children/PreviewSection/PreviewSection";
import { getHelpCenterPage } from "src/services/KnowledgeBase/Settings/Preview/helpCenterPage.service";
import styles from "./KBPreview.module.scss";
import CategoryInfo from "src/routes/KnowledgeBaseUpdated/Children/TabSection/KnowledgeSetting/Children/ArticleSideBar/ThemeSelection/Children/ThemeSetting/Children/PreviewSection/Children/CategoryInfo/CategoryInfo";
import usePassProtect from "src/routes/KnowledgeBaseUpdated/Children/TabSection/KnowledgeSetting/Children/ArticleSideBar/ThemeSelection/Children/ThemeSetting/Children/PreviewSection/Children/PassProtect/usePassProtect";
import ResumeHeader from "src/routes/KnowledgeBaseUpdated/Children/TabSection/KnowledgeSetting/Children/ArticleSideBar/ThemeSelection/Children/ThemeSetting/Children/PreviewSection/Children/ResumeHeader/ResumeHeader";

/**
 * This component is created to show the poreview of the KB.
 */
function KbPreview() {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const helpCenterPageUri = location.pathname.split("/")[2];
  const isFirstLogin = useRef(searchParams.get("isFirstLogin"));

  const {
    showPass,
    setShowPass,
    passWord,
    setPassword,
    showValidation,
    setShowValidation,
    handleSignIn,
    hostname,
    callSignIn,
    isProtected,
    setIsProtected,
    initialized,
    token,
    isError,
    error,
    integrationIdentifier,
    statusMsg,
  } = usePassProtect({ helpCenterPageUri, isFirstLogin: isFirstLogin.current });

  /**
   * If not initialized then showing loader.
   */
  if (!initialized)
    return (
      <div>
        <Loader />
      </div>
    );

  /**
   * If password protected and token is not present then we are showing the login modal.
   */
  if (isProtected && !token) {
    return (
      <div className={`${styles.modalContent}`}>
        <PassProtect
          callSignIn={callSignIn}
          handleSignIn={handleSignIn}
          showPass={showPass}
          setShowPass={setShowPass}
          passWord={passWord}
          setPassword={setPassword}
          showValidation={showValidation}
          setShowValidation={setShowValidation}
        />
      </div>
    );
  }

  /**
   * Error handelling for forbidden or any other api failure
   */
  if (isError) {
    if (
      error &&
      (error as any)?.response?.status &&
      (error as any)?.response?.status === 403
    ) {
      return (
        <div className={`p-3 ${styles.errorMsg} d-flex flex-column`}>
          <h1>Forbidden</h1>
          <p>You don't have access to this resource.</p>
        </div>
      );
    } else {
      return (
        <div className={`p-3 ${styles.errorMsg}`}>
          We were unable to load this section. Please try again by reloading the
          page.
        </div>
      );
    }
  }

  /**
   * Returning th ehelpcenter page.
   */
  return (
    <>
      {/* Jan 10 check for the status message,if exists then show that message and does not call any other apis */}
      {statusMsg && statusMsg.length > 0 ? (
        <div className={`p-3 ${styles.errorMsg}`}>{statusMsg}</div>
      ) : (
        <div>
          <ToastContainer />
          {isFirstLogin.current && <ResumeHeader />}
          <Routes>
            <Route
              path="/helpcenterpage/:helpCenterPageUri"
              element={
                <PreviewSection
                  integrationIdentifier={integrationIdentifier || ""}
                />
              }
            />
            <Route
              path="/helpcenterpage/:helpCenterPageUri/article/:id"
              element={
                <ArticleInfo
                  integrationIdentifier={integrationIdentifier || ""}
                />
              }
            />
            <Route
              path="/helpcenterpage/:helpCenterPageUri/category/:categoryId"
              element={
                <CategoryInfo
                  integrationIdentifier={integrationIdentifier || ""}
                />
              }
            />
          </Routes>
        </div>
      )}
    </>
  );
}

export default KbPreview;
