/**
 * This file defines a custom hook for displaying and managing
 * all available bot profiles services. It handles functionalities like edit on click, previewing,
 * and creating new bot profiles.
 *
 * @author @yuvaraj-busibud
 * @author @Anubhav-busibud
 */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { FreePlanError, FreePlanErrorMessage } from "src/globals/constants";
import { createBotProfile } from "src/services/Bot/BotProfiles/createBotProfile.service";
import {
  BotProfileResponse,
  getAllBotProfiles,
} from "src/services/Bot/BotProfiles/getAllBotProfiles.service";
import { updatePriorities } from "src/services/Bot/BotProfiles/updatePriorities.service";
import { getAllBrandsInfiScroll } from "src/store/slices/settings/brands/brandSettings.slice";
import { useAppDispatch } from "src/store/store";

function useBotProfiles() {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  // Callback to show modal to add new bot profile
  const onShow = useCallback((e: any) => {
    e.stopPropagation();
    setShowModal(true);
  }, []);

  // Callback to hide add new bot profile modal
  const onHide = useCallback(() => {
    setShowModal(false);
  }, []);
  // Mutation for creating a new botprofile
  const createProfile = useMutation({
    mutationKey: ["createBotProfile"],
    mutationFn: createBotProfile,
    onSuccess: (data) => {
      navigate(`/bot/botProfiles/${data.data.bot_profile_id}/settings`);
      onHide();
    },
    onError: (err: any) => {
      if (
        typeof err == "object" &&
        (err.statusCode === FreePlanError.LIMIT_REACHED ||
          err.statusCode === FreePlanError.RESTRICTED)
      ) {
        const statusCode = err.statusCode as keyof typeof FreePlanErrorMessage;
        // If the free plan limit is exceeded, show free plan error
        pushTheToast({
          text: err.message ?? FreePlanErrorMessage[statusCode],
          type: "danger",
          position: "top-right",
        });
      }
    },
  });
  // Get the loading status of the mutation
  const isCreating = createProfile.isLoading;

  //Callback to check for valid brandId and creating new bot Profile
  const handleNextClick = useCallback((brandId: number | string) => {
    if (brandId) {
      // Call the mutation
      createProfile.mutate({
        brand_id: brandId,
      });
    }
  }, []);
  // Mutation for updating the order
  const changeOrder = useMutation({
    mutationFn: updatePriorities,
  });
  // initial payload
  const payload = {
    start: 0,
    limit: 15,
  };

  const {
    data,
    isLoading,
    isError,
    hasNextPage,
    fetchNextPage,
    refetch,
    isRefetching,
    isFetchingNextPage,
    status,
    fetchStatus,
    isFetching,
  } = useInfiniteQuery({
    queryKey: ["getAllProfiles", payload],
    queryFn: ({ pageParam = payload }) => getAllBotProfiles(pageParam),
    getNextPageParam: (lastPage: any, allPages) => {
      const data = allPages.flatMap((data: any) => data.data);
      if (data.length < lastPage.metaData?.total) {
        const nextPageParam = {
          ...payload,
          start: data.length,
        };
        return nextPageParam;
      }
      return null;
    },
    // Automatically refetch when the component mounts
    refetchOnMount: true,
  });

  const [botProfileData, setBotProfileData] = useState<
    BotProfileResponse[] | undefined
  >([]);

  const queryClient = useQueryClient();
  // Update botProfiles while fetching next profiles
  useEffect(() => {
    if (data) {
      const botProfiles = data.pages.flatMap((data: any) => data.data);
      setBotProfileData(botProfiles);
    }
  }, [data]);

  // Fetch initial data on component mount
  useEffect(() => {
    dispatch(getAllBrandsInfiScroll({ start: 0 }));
  }, []);

  const handleOnDragEnd = useCallback(
    (result: any) => {
      // Destructure the 'destination' and 'source' properties from the 'result' object.
      const { destination, source } = result;
      // If there's no valid destination, return (no action needed).
      if (!destination) {
        return;
      }
      // If the item is dropped in the same location, return (no action needed)
      if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
      ) {
        return;
      }
      // Check if 'botProfileData' is defined
      if (botProfileData === undefined) return;
      const newOrder = [...botProfileData];
      const botData = [...botProfileData];

      // Remove the item from the source index and insert it at the destination index.
      const [removed] = newOrder.splice(source.index, 1);
      newOrder.splice(destination.index, 0, removed);

      // Clone the original data to avoid mutations
      const updatedData = { ...data };
      // Find the source and destination pages by their IDs
      const sourcePage = updatedData.pages?.find((page) =>
        page.data.some((item: { id: number }) => item.id == result.draggableId),
      );
      const destinationPage = updatedData.pages?.find((page) =>
        page.data.some(
          (item: { id: number }) => item.id === botData[destination.index]?.id,
        ),
      );
      const sourceIndex = updatedData.pages?.indexOf(sourcePage);
      const destinationIndex = updatedData.pages?.indexOf(destinationPage);
      if (!sourcePage || !destinationPage) {
        return;
      }
      // Find the source and destination items index
      const sourceItemIndex = sourcePage.data.findIndex(
        (item: { id: number }) => item.id === botData[source.index]?.id,
      );
      const destinationItem = destinationPage.data.find(
        (item: { id: number }) => item.id === botData[destination.index]?.id,
      );
      let destinationItemIndex = destinationPage.data.indexOf(destinationItem);

      if (sourceIndex != undefined && destinationIndex != undefined) {
        if (sourceIndex < destinationIndex) {
          destinationItemIndex += Math.abs(sourceIndex - destinationIndex);
        }
      }
      if (sourceItemIndex === -1 || destinationItemIndex === -1) {
        return;
      }

      // Remove the source item from the source page
      const [movedItem] = sourcePage.data.splice(sourceItemIndex, 1);

      // Insert the source item at the destination index in the destination page
      destinationPage.data.splice(destinationItemIndex, 0, movedItem);
      // console.log("updated data",updatedData)
      // queryClient.setQueryData(["getAllProfiles", payload], updatedData);

      // Call the 'changeOrder' mutation with the updated order.
      changeOrder.mutate(
        {
          ids: newOrder.map((item) => item.id),
        },
        {
          onSuccess: () => {
            console.log(updatedData);
            // Update the query data with the new order.
            queryClient.setQueryData(["getAllProfiles", payload], updatedData);
          },
        },
      );
      // Update the 'botProfileData' state with the new order.
      setBotProfileData(newOrder);
    },
    [botProfileData],
  );

  const handleInfiniteScroll = () => {
    // Fetch the next page only when there is no ongoing fetch
    if (!isFetching) {
      fetchNextPage();
    }
  };

  return {
    showModal,
    onShow,
    onHide,
    isCreating,
    handleNextClick,
    data,
    isLoading,
    isError,
    hasNextPage,
    fetchNextPage,
    refetch,
    isRefetching,
    isFetchingNextPage,
    status,
    fetchStatus,
    botProfileData,
    handleOnDragEnd,
    isFetching,
    handleInfiniteScroll,
  };
}

export default useBotProfiles;
