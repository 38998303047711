import { useNavigate } from "react-router-dom";
import styles from "./UpdatedArticleBox.module.scss";
import { useState } from "react";
interface Props {
  article: any;
  articleIdx: number;
  helpCenterPageUri: string;
  brandColor: string;
  font: string;
}

const FA_NightInTheOscars = ({
  article,
  articleIdx,
  helpCenterPageUri,
  brandColor,
  font,
}: Props) => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const buttonStyle: React.CSSProperties = {
    color: isHovered ? `${brandColor}` : `#000`,
    borderColor: isHovered ? `${brandColor}` : `#000`,
    fontFamily: font ?? "Roboto",
  };
  return (
    <div className={`${styles.articleWrapper}`}>
      <h3
        className={`text-truncate ${styles.heading}`}
        style={{
          fontFamily: font ?? "Roboto", // font family
        }}
      >
        {article.articleName}
      </h3>
      <span className={`${styles.desc}`}>{article.articleDescription}</span>
      <button
        className={`px-3 mt-2 ${styles.viewBtn}`}
        style={buttonStyle}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => {
          navigate(
            `/helpcenterpage/${helpCenterPageUri}/article/${article.articleId}`,
            {
              state: {
                path: "featuredArticle",
              },
            }
          );
        }}
      >
        View Article
      </button>
    </div>
  );
};

export default FA_NightInTheOscars;
