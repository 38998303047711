import {ICannedResponses, initialState} from "./cannedResponse.slice";

const resetCannedResponse = (state: ICannedResponses) => {
  state = {...initialState};
  return state;
};

const setCannedResponseSearchValue = (
  state: ICannedResponses,
  {payload}: {payload: {searchValue: string}}
) => {
  state.filters.searchValue = payload.searchValue;
};

const resetCannedResponseSearchValue = (state: ICannedResponses) => {
  state.filters.searchValue = "";
};

const setCurrentPage = (
  state: ICannedResponses,
  {payload}: {payload: number}
) => {
  state.cannedResponseCurrentPage = payload;
  return state;
};

const setTotalPages = (
  state: ICannedResponses,
  {payload}: {payload: number}
) => {
  state.cannedResponseTotalPages = payload;
};

const resetPagination = (state: ICannedResponses) => {
  state.cannedResponseCurrentPage = 0;
  state.cannedResponseTotalPages = 0;
  return state;
};

export default {
  resetCannedResponse,
  setCannedResponseSearchValue,
  resetCannedResponseSearchValue,
  setCurrentPage,
  resetPagination,
  setTotalPages,
};
