import ParseHTML from "src/components/ParseHTML";
import styles from "./TicketMsg.module.scss";
import InnerChatAttachments from "src/components/InnerChatAttachments/InnerChatAttachments";
import { showAttachment } from "src/services/Attachment/showAttachment";
import { useRef } from "react";
import { MessagesData } from "src/services/LiveChat/liveChatHistory/getLiveChatCustomerAllMessages.service";

const TicketMsg = ({
  messageData,
  rightAlign,
}: {
  messageData: MessagesData;
  rightAlign: boolean;
}) => {
  const parentRef = useRef(null);
  return (
    <>
      <div
        className={`d-flex justify-content-end ${
          rightAlign === true ? "" : `flex-row-reverse`
        }`}
        ref={parentRef}
      >
        <div
          className={`${
            messageData.dataType === "Note"
              ? styles.noteDataDiv
              : styles.messageDataDiv
          } ${rightAlign ? styles.right : ""}`}
        >
          <ParseHTML
            className={`${styles.msgText} ${rightAlign ? styles.right : ""} ${
              messageData.dataType === "Note" ? styles.note : ""
            }`}
            html={messageData.message}
          />
          {messageData &&
            messageData.attachments &&
            messageData.attachments.length > 0 && (
              <InnerChatAttachments messageData={messageData} />
            )}
        </div>
      </div>
    </>
  );
};
export default TicketMsg;
