import { useMemo } from "react";
import { SegmentColumn } from "src/services/CustomerSegments/getSegmentColumns.service";
import { useSegmentView } from "../../hooks/useSegmentView";
import styles from "./TableHeader.module.scss";
import group from "src/assets/images/group.svg";

const TableHeader = () => {
  const { segmentView } = useSegmentView();

  const columns = useMemo(() => {
    const columns: SegmentColumn[] = [];
    segmentView.allColumnKeys.forEach((key) => {
      const column = segmentView.allColumns[key];
      if (column?.selected || column?.required) {
        columns.push(column);
      }
    });
    return columns;
  }, [segmentView]);

  return (
    <div className={` mb-2 position-relative ${styles.tableHeader}`}>
      {columns.map((column, index) => {
        return (
          <div
            className={`${styles.headerElement} ${
              index === 0 ? styles.firstEle : ""
            } ${index === columns.length - 1 ? styles.lastEle : ""}`}
            key={column.key}
          >
            {index == 0 ? (
              <img
                src={group}
                alt="group"
                className="pe-1"
              />
            ) : (
              ""
            )}

            <span className={`${styles.headerText}`}>{column.name}</span>
            {/* use below icon to sort as per xd  */}
            <span className={`ps-1 ${styles.arrows} d-none`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6.735"
                height="3.821"
                viewBox="0 0 6.735 3.821"
              >
                <path
                  id="Icon_awesome-caret-down"
                  data-name="Icon awesome-caret-down"
                  d="M.453,0H6.282A.452.452,0,0,1,6.6.773L3.688,3.689a.454.454,0,0,1-.641,0L.134.773A.452.452,0,0,1,.453,0Z"
                  transform="translate(6.735 3.821) rotate(180)"
                  fill="#0b68bb"
                />
              </svg>
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default TableHeader;

// Html
// {
/* <div className={`px-3 mb-2 ${styles.tableHeader}`}>
  <div className={`${styles.headerElement}`}>
    <span className={`${styles.headerText}`}>Name</span>
  </div>
  <div className={`${styles.companySeen}`}>
    <span className={`${styles.headerText}`}>
      Company last seen
      <span className="ps-1">
        <i className="fa-solid fa-caret-down"></i>
      </span>
    </span>
  </div>
  <div className={`${styles.people}`}>
    <span className={`${styles.headerText}`}>People</span>
  </div>
  <div className={`${styles.webSession}`}>
    <span className={`${styles.headerText}`}>Company web sessions</span>
  </div>
  <div className={`${styles.companySize}`}>
    <span className={`${styles.headerText}`}>Company size</span>
  </div>
  <div className={`${styles.companyIndustry}`}>
    <span className={`${styles.headerText}`}>
      Company industry{" "}
      <span className="ps-1">
        <i className="fa-solid fa-caret-down"></i>
      </span>
    </span>
  </div>
  <div className={`${styles.companyIndustry}`}>
    <span className={`${styles.headerText}`}>
      Company Website{" "}
      <span className="ps-1">
        <i className="fa-solid fa-caret-down"></i>
      </span>
    </span>
  </div>
</div>; */
// }
