import { forwardRef } from "react";
import styles from "./OpenClose.module.scss";

const OpenClose = forwardRef(function (
  {
    onClick = (e) => {},
    show = false,
    className = "",
    ...props
  }: {
    onClick?: (e: any) => void;
    show?: boolean;
    className?: string;
    [key: string]: any;
  },
  ref: any
) {
  return (
    <div
      className={`${styles.openClose} ${className} d-flex flex-column justify-content-center`}
      onClick={onClick}
      {...props}
      ref={ref}
    >
      <i
        className={`fa-solid fa-caret-${show ? "up" : "down"} ${styles.open}`}
      ></i>
    </div>
  );
});

export default OpenClose;
