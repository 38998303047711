import { AntiRefundStrategiesReason } from "../../../store/slices/AntiRefundStrategiesSlice/antiRefundStrategies.slice";
import styles from "./RefundBox.module.scss";
import Scenario from "./Scenario/Scenario";

interface Props {
  reasonData: AntiRefundStrategiesReason;
  question: string;
}

const ReasonRequestBox = ({ reasonData, question }: Props) => {
  const { reasonName, scenarios } = reasonData;

  return (
    <div className={`ps-3 pe-2 pb-3 pt-2 mb-2 ${styles.refundBox}`}>
      <span className={`pb-1 ${styles.boxHead}`}>Reason: {reasonName}</span>
      {/* Map through each scenario in a reason */}
      {scenarios.map((scenario, index) => {
        return (
          <Scenario
            scenario={scenario}
            index={index}
            reasonData={reasonData}
            question={question}
          />
        );
      })}
    </div>
  );
};

export default ReasonRequestBox;
