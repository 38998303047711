import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { validateEmail } from "src/utils/utils";
import styles from "../../../UpdateSegmentDetails.module.scss";

interface Props {
  value: Array<string> | null | undefined;
  onChange: (value: Array<string | number>) => void;
  placeholder: string;
  isEmailError: boolean;
  error?: string;
}

interface PropsInput {
  placeholder: string;
  value: string | null | Array<string | number>;
  handleChange: (value: Array<string | number>) => void;
  error?: string | null;
}

const MultiSelectTextInput = ({
  placeholder,
  value,
  handleChange,
  error,
}: PropsInput) => {
  const [tags, setTags] = useState<Array<string | number>>([]);
  const [currentTag, setCurrentTag] = useState<string>("");

  const inputRef = useRef<HTMLDivElement>(null);
  const tagChanged = useRef(false);

  useEffect(() => {
    if (value && Array.isArray(value)) {
      setTags(value);
    } else {
      setTags([]);
    }
  }, [value]);

  useEffect(() => {
    if (tagChanged.current) {
      handleChange(tags);
      tagChanged.current = false;
    }
  }, [tags]);

  const handleInputChange = useCallback((inputValue: string) => {
    setCurrentTag(inputValue);
  }, []);

  const addTag = useCallback((tag: string) => {
    tagChanged.current = true;
    setTags((prevTags) => [...prevTags, tag]);
    setCurrentTag("");
    if (inputRef.current) {
      inputRef.current.innerHTML = "";
    }
  }, []);

  const removeTag = useCallback((tag: string | number) => {
    tagChanged.current = true;
    setTags((prevTags) => prevTags.filter((t) => t !== tag));
    inputRef.current?.focus();
  }, []);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === "Enter" && currentTag) {
        event.preventDefault();
        addTag(currentTag);
      } else if (event.key === "Backspace" && !currentTag && tags.length > 0) {
        event.preventDefault();
        removeTag(tags[tags.length - 1]);
      }
    },
    [addTag, currentTag, removeTag, tags],
  );

  return (
    <div>
      <div
        className={`${styles.tagInputContainer} ${
          error ? styles.errorBorder : "mb-1"
        }`}
        onClick={() => {
          inputRef.current?.focus();
        }}
      >
        <div className={`${styles.tagsContainer}`}>
          {tags.map((tag, index) => (
            <div
              key={index}
              className={`${styles.tag}`}
            >
              {tag}
              <span
                className={`${styles.removeTag}`}
                onClick={() => removeTag(tag)}
              >
                &#x2715;
              </span>
            </div>
          ))}
          <div
            className={`${styles.tagInput}`}
            contentEditable
            ref={inputRef}
            onInput={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(event.target.innerText)
            }
            onKeyDown={handleKeyDown}
            onBlur={(e) => {
              handleInputChange(e.target.innerText);
              if (currentTag.trim() !== "") {
                addTag(currentTag);
              }
            }}
            placeholder={tags.length ? "" : placeholder}
          ></div>
        </div>
      </div>
      {error ? (
        <span className={`${styles.errText} text-danger`}>{error}</span>
      ) : null}
    </div>
  );
};

const MultiSelectStringInput = ({
  value,
  onChange,
  isEmailError,
  placeholder,
  error = "",
}: Props) => {
  const handleChange = useCallback(
    (value: (string | number)[]) => {
      onChange(value);
    },
    [onChange],
  );

  const errorString = useMemo(() => {
    if (error !== "") {
      if (!value || value.length === 0) {
        return error;
      }

      if (isEmailError && value.some((email) => !validateEmail(email))) {
        return "Invalid Emails";
      }
    }

    return null;
  }, [error, isEmailError, value]);

  return (
    <MultiSelectTextInput
      handleChange={handleChange}
      placeholder={placeholder}
      value={value ?? ""}
      error={errorString}
    />
  );
};

export default MultiSelectStringInput;
