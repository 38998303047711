import { axiosJSON } from "src/globals/axiosEndPoints";
import APIResponse from "src/services/Settings/Teams/APIReponse";


export interface IUpdateTicketChatFormDataParam{

  integrationId: number | string;
  enabled?: boolean;
  elements?: Array<IUpdateTicketChatFormElement> ;
  sendTicketFormMessageTo?: "custom" | "livechat";
  customEmail?: string;
}

export interface IUpdateTicketChatFormElement{
  elementId : string | number;
  elementKey : string;
  elementValue : string;
  elementOptions : Array<string>;
  isRequired : boolean;
  isNewlyAdded: boolean;
  isDefault: boolean;
}



export async function updateTicketChatFormData(params: IUpdateTicketChatFormDataParam) {
  
  const { data: res } = await axiosJSON.post(
    "/api/chatSetting/forms/updateTicketForm",
    params
  );

  if (res.error === true) {
    throw new Error(res.message as string);
  }

  return res.data;
}
