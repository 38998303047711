import { ActionReducerMapBuilder, current } from "@reduxjs/toolkit";
import {
  AntiRefundConfigState,
  RefundReason,
  items,
} from "../antiRefundConfig.slice";
import {
  fetchGetRefundRequestFollowUpQuestions,
  fetchRefundRequestList,
  fetchUpdateAntiRefundRequest,
  fetchPostAntiRefundConfig,
} from "../Thunks/antiRefund.thunks";
import { v4 as uuidv4 } from "uuid";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";

const updateRefundRequestList = (
  state: AntiRefundConfigState,
  payload: any,
) => {
  const { allItems, allTags, refundRequestList } = payload;
  refundRequestList.forEach((reason: RefundReason) => {
    let addRemainScenario = false;
    const allProductItems = allItems.map((item: any) => {
      return {
        id: item.productId,
        label: item.productName,
        type: "item",
        value: item.productName,
        imgSrc: item.imageUrl,
        price: item.productSku,
      };
    });
    reason.scenarios.forEach((scenario) => {
      if (
        (scenario.items as Array<any>).includes("*") &&
        scenario.tags.length == 1 &&
        scenario.tags.includes("*")
      ) {
        // Convert response items into items array used for anti-refund
        scenario.items = allProductItems;
        scenario.tags = allTags?.map((tag: any) => tag);
        scenario.itemsAndTagAllSelected = true;
      } else {
        // If scenario items are available
        if (scenario.items.length) {
          // Convert response items into items array used for anti-refund
          scenario.items = scenario.items.map((item: any) => {
            return {
              id: item.productId,
              label: item.productName,
              type: "item",
              value: item.productName,
              imgSrc: item.imageUrl,
              price: item.productSku,
            };
          });
        }
        // If scenario tags are available
        if (scenario.tags.length) {
          // Convert response tags into tags array used for anti-refund
          scenario.tags = scenario.tags.map((tag: any) => tag);
        }
      }

      if (
        scenario.items.length === 0 &&
        scenario.tags.length === 0 &&
        (scenario.followUpQuestions.length === 0 ||
          (scenario.followUpQuestions as Array<any>).includes("*"))
      ) {
        scenario.itemsAndTagAllSelected = true;
        addRemainScenario = true;
        scenario.items = [];
        scenario.tags = [];
      }
      if (
        scenario.followUpQuestions.length === 1 &&
        scenario.followUpQuestions[0] === ("*" as any)
      ) {
        scenario.followUpQuestions =
          state.refundRequests.refundRequestFollowUpQuestions.map((element) => {
            return {
              elementId: element.elementId,
              elementTypeId: element.elementTypeId,
              elementValue: element.elementValue,
              additionalOptions: element.additionalOptions,
              selectedValue: "",
              // selectedId: "",
            };
          });
      }

      scenario.followUpQuestions.forEach((followUpQuestion) => {
        if (
          followUpQuestion.selectedValue.length === 1 &&
          followUpQuestion.selectedValue[0] === "*"
        ) {
          followUpQuestion.selectedValue =
            followUpQuestion.additionalOptions.map((option) => option.id);
        }
      });
    });

    if (!addRemainScenario) {
      // payload.forEach((reason: RefundReason) => {
      reason.scenarios.push({
        id: uuidv4(),
        items: [],
        tags: [],
        followUpQuestions: [],
        enabled: false,
        itemsAndTagAllSelected: true,
        isNewScenario: true,
      });
      // });
    }
  });
  state.refundRequests.reasons = refundRequestList;
};

const updateRefundRequestFollowUpQuestions = (
  state: AntiRefundConfigState,
  payload: any,
) => {
  state.refundRequests.refundRequestFollowUpQuestions = payload;

  state.refundRequests.reasons.forEach((reason: RefundReason) => {
    reason.scenarios.forEach((scenario) => {
      if (
        scenario.followUpQuestions.length === 1 &&
        scenario.followUpQuestions[0] === ("*" as any)
      ) {
        scenario.followUpQuestions =
          state.refundRequests.refundRequestFollowUpQuestions.map((element) => {
            return {
              elementId: element.elementId,
              elementTypeId: element.elementTypeId,
              elementValue: element.elementValue,
              additionalOptions: element.additionalOptions,
              selectedValue: "",
              // selectedId: "",
            };
          });
      }

      scenario.followUpQuestions.forEach((followUpQuestion) => {
        if (
          followUpQuestion.selectedValue.length === 1 &&
          followUpQuestion.selectedValue[0] === "*"
        ) {
          followUpQuestion.selectedValue =
            followUpQuestion.additionalOptions.map((option) => option.id);
          // followUpQuestion.selectedId =
          //   followUpQuestion.additionalOptions.map(
          //     (option) => option.id
          //   );
        }
      });
    });
  });
};

const antiRefundBuilder = (
  builder: ActionReducerMapBuilder<AntiRefundConfigState>,
) => {
  builder.addCase(
    fetchRefundRequestList.pending,
    (state: AntiRefundConfigState) => {
      state.fetchAjaxStatus = "pending";
    },
  );

  builder.addCase(
    fetchRefundRequestList.rejected,
    (state: AntiRefundConfigState) => {
      state.fetchAjaxStatus = "rejected";
    },
  );

  builder.addCase(
    fetchRefundRequestList.fulfilled,
    (state: AntiRefundConfigState, { payload }) => {
      state.fetchAjaxStatus = "fulfilled";
      updateRefundRequestList(state, payload);
    },
  );

  builder.addCase(
    fetchGetRefundRequestFollowUpQuestions.pending,
    (state: AntiRefundConfigState) => {
      state.followUpQuestionsAjaxStatus = "pending";
    },
  );

  builder.addCase(
    fetchGetRefundRequestFollowUpQuestions.rejected,
    (state: AntiRefundConfigState) => {
      state.followUpQuestionsAjaxStatus = "rejected";
    },
  );

  builder.addCase(
    fetchGetRefundRequestFollowUpQuestions.fulfilled,
    (state: AntiRefundConfigState, { payload }) => {
      state.followUpQuestionsAjaxStatus = "fulfilled";
      updateRefundRequestFollowUpQuestions(state, payload);
    },
  );

  builder.addCase(
    fetchUpdateAntiRefundRequest.pending,
    (state: AntiRefundConfigState) => {
      state.updateAntiRefundRequestAjaxStatus = "pending";
    },
  );

  builder.addCase(
    fetchUpdateAntiRefundRequest.rejected,
    (state: AntiRefundConfigState, { payload }) => {
      state.updateAntiRefundRequestAjaxStatus = "rejected";
      pushTheToast({
        position: "top-right",
        text: "Something went wrong while updating the anti refund request",
        type: "danger",
      });
    },
  );

  builder.addCase(
    fetchUpdateAntiRefundRequest.fulfilled,
    (state: AntiRefundConfigState, { payload }) => {
      state.updateAntiRefundRequestAjaxStatus = "fulfilled";
      pushTheToast({
        type: "success",
        text: "Configuration Saved",
        position: "top-right",
      });
    },
  );
  builder.addCase(
    fetchPostAntiRefundConfig.fulfilled,
    (state: AntiRefundConfigState, { payload }) => {
      state.updateAjaxStatus = "fulfilled";
    },
  );
  builder.addCase(
    fetchPostAntiRefundConfig.pending,
    (state: AntiRefundConfigState) => {
      state.updateAjaxStatus = "pending";
    },
  );

  builder.addCase(
    fetchPostAntiRefundConfig.rejected,
    (state: AntiRefundConfigState, { payload }) => {
      state.updateAjaxStatus = "rejected";
      pushTheToast({
        position: "top-right",
        text: "Something went wrong while updating the anti refund request",
        type: "danger",
      });
    },
  );
};

export default antiRefundBuilder;
