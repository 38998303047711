import React, { useCallback, useEffect, useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import UserAvatar from "src/components/UserAvatar";
import CommentModal from "../CommentModal/CommentModal";
import styles from "./FeedbackTable.module.scss";
import { GetAllArticleFeedback } from "src/services/KnowledgeBase/Settings/Feedback/getAllArticleFeedback.service";
import { useMutation } from "@tanstack/react-query";
import { updateArticleFeedback } from "src/services/KnowledgeBase/Settings/Feedback/updateArticleFeedback.service";
import { useParams } from "react-router-dom";
import useFeedbackTable from "./useFeedbackTable";
import getEmoji from "./getEmoji";

type Props = {
  feedbackData: GetAllArticleFeedback;
};

export function GetStatus(status: string) {
  switch (status) {
    case "approved":
    case "Approved":
      return "Approved";
    case "rejected":
    case "Rejected":
      return "Rejected";
    case "pending":
    case "Pending":
      return "Pending";
    default:
      return "";
  }
}

const FeedbackTable = ({ feedbackData }: Props) => {
  const {
    pageIntegrationId,
    showModal,
    onShow,
    onHide,
    updateStatus,
    commentStatus,
    setCommentStatus,
    ratingStatus,
    setRatingStatus,
  } = useFeedbackTable({
    feedbackData,
  });

  const handleViewArticle = useCallback(() => {
    window.open(
      `/knowledge-base/${pageIntegrationId}/articles/${feedbackData.articleId}`
    );
  }, [feedbackData]);

  return (
    <div className={`position-relative ${styles.tableHeader} mb-2`}>
      <div className={`${styles.hoverBar}`}></div>

      {/* Commentator  */}
      <div className={`text-truncate pe-2 ${styles.commentatorWrapper}`}>
        <p className={`mb-0 ${styles.userName}`}>
          {feedbackData.feedbackCustomerName}
        </p>
        <span className={`${styles.userEmail}`}>
          {feedbackData.feedbackCustomerEmail}
        </span>
      </div>

      {/* Article  */}
      <div className={`text-truncate pe-2 d-flex ${styles.articleWrapper}`}>
        <p className={`mb-0 text-truncate ${styles.articleName}`}>
          {feedbackData.articleName}
        </p>
        <span
          className={`ps-1 ${styles.articleName}`}
          onClick={handleViewArticle}
        >
          <i className="ps-1 cursor-pointer fa-solid fa-arrow-up-right-from-square"></i>
        </span>
        <span className={`${styles.articleMail} text-truncate `}>
          {feedbackData.articleUrl}
        </span>
      </div>

      {/* Rating Provided  */}
      <div className={`${styles.ratingWrapper} ms-3`}>
        <span className={`${styles.emoji}`}>
          {getEmoji(feedbackData.ratingProvided)}
        </span>
      </div>

      {/* Rating Status  */}
      {feedbackData.ratingProvided ? (
        <div className={`${styles.statusWrapper}`}>
          <span className={`${styles.headerName}`}>
            <Dropdown>
              <Dropdown.Toggle
                as={`div`}
                id="dropdown-basic"
                bsPrefix={`py-1 px-2 cursor-pointer dropdown-toggle ${
                  ratingStatus === "approved" || ratingStatus === "Approved"
                    ? styles.customBtn
                    : ratingStatus === "rejected" || ratingStatus === "Rejected"
                    ? styles.RejectBtn
                    : styles.PendingBtn
                }`}
              >
                <span className="pe-2">{GetStatus(ratingStatus)}</span>
              </Dropdown.Toggle>

              <Dropdown.Menu
                bsPrefix={`dropdown-menu p-2 ${styles.dropdownMenu}`}
              >
                <p className={`mb-0 pb-1 ${styles.dropHead}`}>
                  Mark Rating status as
                </p>
                <div className={`${styles.hr}`}></div>
                <div className="mt-3">
                  {/* if approved is already selected then hiding the approved option in the dropdown */}
                  {ratingStatus?.toLowerCase() !== "approved" ? (
                    <li
                      className={`d-flex justify-content-start ${styles.mainLi}`}
                    >
                      <div className={`${styles.bar}`}></div>
                      <Dropdown.Item
                        className={`${styles.changeStatusbox}`}
                        onClick={() => {
                          setRatingStatus("approved");

                          // Calling the mutation function to update the status
                          updateStatus.mutate({
                            articleFeedbackId: feedbackData.id,
                            ratingStatus: 1,
                            integrationId: Number(pageIntegrationId),
                          });
                        }}
                        eventKey={"Approved"}
                        // defaultChecked={ratingStatus === "approved"}
                      >
                        <span className={`${styles.darkColor}`}>Approved</span>
                      </Dropdown.Item>
                    </li>
                  ) : null}
                  {/* Nov 14 2023 commenting the Pending Status as approved or reject will never go back to pending status */}
                  {/* <li
                    className={`d-flex justify-content-start ${styles.mainLi}`}
                  >
                    <div className={`${styles.bar}`}></div>
                    <Dropdown.Item
                      className={`${styles.changeStatusbox}`}
                      onClick={() => {
                        setRatingStatus("pending");

                        // Calling the mutation function to update the status
                        updateStatus.mutate({
                          articleFeedbackId: feedbackData.id,
                          ratingStatus: 2,
                          integrationId: Number(pageIntegrationId),
                        });
                      }}
                      eventKey={"Pending"}
                      defaultChecked={ratingStatus === "pending"}
                    >
                      <span className={`${styles.darkColor}`}>Pending</span>
                    </Dropdown.Item>
                  </li> */}
                  {/* if rejected is already selected then hiding the rejected option in the dropdown */}
                  {ratingStatus?.toLowerCase() !== "rejected" ? (
                    <li
                      className={`d-flex justify-content-start ${styles.mainLi}`}
                    >
                      <div className={`${styles.bar}`}></div>
                      <Dropdown.Item
                        className={`${styles.changeStatusbox}`}
                        onClick={() => {
                          setRatingStatus("rejected");

                          // Calling the mutation function to update the status
                          updateStatus.mutate({
                            articleFeedbackId: feedbackData.id,
                            ratingStatus: 3,
                            integrationId: Number(pageIntegrationId),
                          });
                        }}
                        eventKey={"Rejected"}
                        // defaultChecked={ratingStatus === "rejected"}
                      >
                        <span className={`${styles.darkColor}`}>Rejected</span>
                      </Dropdown.Item>
                    </li>
                  ) : null}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </span>
        </div>
      ) : (
        <div className={`${styles.statusWrapper}`}></div>
      )}

      {/* Comment */}
      {feedbackData.comment.commentText ? (
        <div className={`ps-2 pe-3 ${styles.commentWrapper}`}>
          <div className={`p-2 ${styles.commentBox}`} onClick={onShow}>
            <UserAvatar size={22} name={feedbackData.feedbackCustomerName} />
            <p className={`mb-0 pt-1 ${styles.desc}`}>
              {feedbackData.comment.commentText}
            </p>

            {/* Time */}
            <span className={`${styles.timeShow}`}>
              <span className={`${styles.time}`}>
                <i className="fa-solid fa-clock"></i>
              </span>{" "}
              {new Date(feedbackData.comment.commentCreatedAt).toLocaleString(
                "en-US",
                {
                  month: "2-digit",
                  day: "2-digit",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: true,
                }
              )}
            </span>
          </div>

          {/* Modal on Comment Saction */}
          <Modal
            show={showModal}
            onHide={onHide}
            dialogClassName={`${styles.modalDialog}`}
            contentClassName={`${styles.modalContent}`}
            backdropClassName={`${styles.backDropCol}`}
          >
            <CommentModal
              feedbackData={feedbackData}
              onHide={onHide}
              updateStatus={updateStatus}
              commentStatus={commentStatus}
              setCommentStatus={setCommentStatus}
            />
          </Modal>
        </div>
      ) : (
        <div className={`ps-2 pe-3 ${styles.commentWrapper}`}></div>
      )}

      {/* Comment Action */}
      {feedbackData.comment.commentText ? (
        <div className={`ps-3 ${styles.actionWrapper}`}>
          <Dropdown>
            <Dropdown.Toggle
              as={"div"}
              id="dropdown-basic"
              bsPrefix={`py-1 px-2  cursor-pointer dropdown-toggle ${
                commentStatus === "approved" || commentStatus === "Approved"
                  ? styles.customBtn
                  : commentStatus === "rejected" || commentStatus === "Rejected"
                  ? styles.RejectBtn
                  : styles.PendingBtn
              }`}
            >
              <span className="pe-2">{GetStatus(commentStatus)}</span>
            </Dropdown.Toggle>

            <Dropdown.Menu
              bsPrefix={`dropdown-menu p-2 ${styles.dropdownMenu}`}
            >
              <p className={`mb-0 pb-1 ${styles.dropHead}`}>
                Mark Rating status as
              </p>
              <div className={`${styles.hr}`}></div>
              <div className="mt-3">
                  {/* if approved is already selected then hiding the approved option in the dropdown */}
                {commentStatus?.toLowerCase() !== "approved" ? (
                  <li
                    className={`d-flex justify-content-start ${styles.mainLi}`}
                  >
                    <div className={`${styles.bar}`}></div>
                    <Dropdown.Item
                      className={`${styles.changeStatusbox}`}
                      onClick={() => {
                        setCommentStatus("approved");
                        updateStatus.mutate({
                          articleFeedbackId: feedbackData.id,
                          commentAction: 1,
                          integrationId: Number(pageIntegrationId),
                        });
                      }}
                    >
                      <span className={`${styles.darkColor}`}>Approved</span>
                    </Dropdown.Item>
                  </li>
                ) : null}
                {/* Nov 14 2023 commenting the Pending Status as approved or reject will never go back to pending status */}
                {/* <li className={`d-flex justify-content-start ${styles.mainLi}`}>
                  <div className={`${styles.bar}`}></div>
                  <Dropdown.Item
                    className={`${styles.changeStatusbox}`}
                    onClick={() => {
                      setCommentStatus("pending");
                      updateStatus.mutate({
                        articleFeedbackId: feedbackData.id,
                        commentAction: 2,
                        integrationId: Number(pageIntegrationId),
                      });
                    }}
                  >
                    <span className={`${styles.darkColor}`}>Pending</span>
                  </Dropdown.Item>
                </li> */}
                {/* if rejected is already selected then hiding the rejected option in the dropdown */}
                {commentStatus?.toLowerCase() !== "rejected" ? (
                  <li
                    className={`d-flex justify-content-start ${styles.mainLi}`}
                  >
                    <div className={`${styles.bar}`}></div>
                    <Dropdown.Item
                      className={`${styles.changeStatusbox}`}
                      onClick={() => {
                        setCommentStatus("rejected");
                        updateStatus.mutate({
                          articleFeedbackId: feedbackData.id,
                          commentAction: 3,
                          integrationId: Number(pageIntegrationId),
                        });
                      }}
                    >
                      <span className={`${styles.darkColor}`}>Rejected</span>
                    </Dropdown.Item>
                  </li>
                ) : null}
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ) : (
        <div className={`ps-3 ${styles.actionWrapper}`}></div>
      )}
    </div>
  );
};

export default FeedbackTable;
