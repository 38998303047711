import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import backArrow from "src/assets/images/backArrow.png";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import startSlackIntegrationService from "src/services/Integrations/Slack/startSlackIntegration.service";
import useGetAllLinkableBrnad from "../../useGetAllLinkableBrnad";
import styles from "./AddNewSlack.module.scss";

const customComponents = {
  IndicatorSeparator: () => null,
  DropdownIndicator: ({ selectProps }: any) => (
    <div style={{ paddingRight: "17px" }}>
      <i
        className={`${
          selectProps.menuIsOpen
            ? "fa-solid fa-caret-up"
            : "fa-solid fa-caret-down"
        } `}
      ></i>
    </div>
  ),
};

const AddNewSlack = () => {
  const navigate = useNavigate();
  const [selectedBrandId, setselectedBrandId] = useState<string | number>("");
  const { linkableBrands } = useGetAllLinkableBrnad();

  const handleAddSlack = useCallback(async () => {
    try {
      if (selectedBrandId) {
        const url = await startSlackIntegrationService({
          brandId: selectedBrandId,
        });
        if (url) {
          window.open(url, "_self");
        }
      }
    } catch (err) {
      pushTheToast({
        position: "top-right",
        type: "danger",
        text: typeof err === "string" ? err : "SomeThing went wrong",
      });
    }
  }, [selectedBrandId]);

  return (
    <div className={styles.mainWrapper}>
      <div className="d-flex w-100">
        <div
          className="edit-icons"
          style={{ marginTop: "" }}
        >
          <img
            src={backArrow}
            alt="backArrow"
            width={25}
            onClick={() => navigate("../settings/integrations/slack")}
          />
        </div>
        <div className="w-100 ms-3">
          <div className={styles.headingWrapper}>
            <h1 className={styles.heading}>Slack</h1>
            <span className={`${styles.subHeading}`}>
              Connect your helpdesk with Slack to manage customer queries in
              real-time.
            </span>
          </div>
          <div className={` ${styles.chooseBoxMain}`}>
            <div className={`${styles.chooseBox} py-3 px-4`}>
              <div className="d-flex justify-content-between">
                <h3 className={`${styles.chooseBoxHead} mb-2`}>Brand</h3>
              </div>
              <div className={styles.selectBrand}>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isMulti={false}
                  components={customComponents}
                  name="select-brand"
                  placeholder={"Choose brand"}
                  options={linkableBrands?.map((brand: any) => {
                    return {
                      value: brand.id,
                      label: brand.name,
                    };
                  })}
                  onChange={(option: any) => {
                    setselectedBrandId(option.value);
                  }}
                />
              </div>
              <div className={styles.connectSlackBtnDiv}>
                <button
                  className={`${styles.addSlackBtn} mt-4 px-2 d-flex justify-content-center align-items-center mb-2`}
                  onClick={handleAddSlack}
                >
                  <span>Connect to slack</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewSlack;
