import { axiosJSON } from "src/globals/axiosEndPoints";
import { KeyExpiresAt } from "./createSnooze";

export interface IUpdateSnooze {
  id: number;
  // ticketId: number;
  expireAt: KeyExpiresAt;
  expireAtValue?: string; //only need when expireAt set to 'custom-datetime'
  initialStatus?: number;
}

const statusCodeMsg:any = {
  "snoozeNotFound": "Snooze Not Found",
}

export async function updateSnooze(params: IUpdateSnooze) {
  const { data: res } = await axiosJSON.post(
    `/api/ticket/snooze/update`,
    params
  );
  let defaultErrorMsg = "Something Went Wrong";
  if (res.error === true) {
    if(res?.statusCode){
      if(statusCodeMsg[res.statusCode] != undefined){
        defaultErrorMsg = statusCodeMsg[res.statusCode];
      }
      throw new Error(defaultErrorMsg);
    }
  }
  return true;
}
