import {
  getLiveChatCustomerAllMessages,
  LiveChatCustomerAllMessage,
  LiveChatCustomerAllMessagePayload,
} from "src/services/LiveChat/liveChatHistory/getLiveChatCustomerAllMessages.service";
import {
  getLiveChatHistoryConversation,
  LiveChatHistoryConversationPayload,
  LiveChatHistoryConversationRes,
} from "src/services/LiveChat/liveChatHistory/getLiveChatHistoryConversation.service";

export interface CombinedHistoryDataI {
  historySidebar: LiveChatHistoryConversationRes;
  historyMessages: LiveChatCustomerAllMessage | null;
}
interface ExtendedCombinedHistoryDataI extends CombinedHistoryDataI {
  customerId: number | string;
}
const fetchCombinedHistoryData = async ({
  payload,
  messagePayload,
  applyStartId,
}: {
  payload: LiveChatHistoryConversationPayload;
  messagePayload: LiveChatCustomerAllMessagePayload;
  applyStartId?: boolean;
}): Promise<ExtendedCombinedHistoryDataI> => {
  messagePayload = { ...messagePayload };
  const historySidebar = await getLiveChatHistoryConversation(payload);
  let historyMessages: null | LiveChatCustomerAllMessage = null;
  if (historySidebar.conversationDataIds.length) {
    const first = historySidebar.conversationDataIds[0].split("::");
    const last =
      historySidebar.conversationDataIds[
        historySidebar.conversationDataIds.length - 1
      ].split("::");
    if (applyStartId) {
      if (first[0] === "ticket") {
        messagePayload.ticketId = first[1];
      } else if (first[0] === "chat") {
        messagePayload.chatId = first[1];
      }
    }
    if (last[0] === "ticket") {
      messagePayload.ticketIdEnd = last[1];
    } else if (last[0] === "chat") {
      messagePayload.chatIdEnd = last[1];
    }
    historyMessages = await getLiveChatCustomerAllMessages(messagePayload);
  }
  return {
    historySidebar,
    historyMessages,
    customerId: payload.customerId,
  };
};

export default fetchCombinedHistoryData;
