import styles from "./BrandSetting.module.scss";
import helpLogo from "src/assets/images/helpCenter.png";
import useBrandSetting from "./useBrandSetting";
import AxiosImg from "src/components/AxiosImg";
import { useMemo } from "react";
import themeDefaultBackground from "src/assets/images/themeDefaultBackground.png";
import arrowRight from "src/assets/images/arrowRight.png";
const BrandSetting = () => {
  const {
    isLoading,
    isError,
    error,
    data,
    dropdown,
    setDropDown,
    setStyleTheme,
    styleTheme,
    backgroundColor,
    handleBackgroundColorChange,
    brandColor,
    handleBrandColorChange,
    handleReplaceImage,
    handleResetImage,
    helplamaBranding,
    handleHelplamaBranding,
    textStatus,
    imagesLoading,
  } = useBrandSetting();

  /**
   * Memoized function to determine the Helplama branding text.
   *
   * @returns {string} The Helplama branding text, or a default "Powered by Helplama" if not available.
   */
  const helplamaBrandingText = useMemo(() => {
    // Check if data exists and if helplamaBrandingText is a non-empty string
    if (data?.helplamaBrandingText && data?.helplamaBrandingText?.trim()) {
      // If available, return the trimmed Helplama branding text
      return data.helplamaBrandingText + "";
    }

    // If data or helplamaBrandingText is not available or is an empty string, return the default text
    return "Powered by Helplama";
  }, [data?.helplamaBrandingText]);

  const domainBranding = useMemo(() => {
    // Check if data exists and if domainBranding is a non-empty string
    if (data?.domainBranding && data?.domainBranding?.trim()) {
      // If available, return the trimmed Helplama branding text
      return data.domainBranding + "";
    }

    // If data or domainBranding is not available or is an empty string, return the default text
    return "Helplama";
  }, [data?.domainBranding]);

  const backgroundImage = useMemo(() => {
    if (data?.backgroundImage) {
      return data?.backgroundImage;
    } else if (data?.themeType === "night_in_the_oscars") {
      return themeDefaultBackground;
    }
    return helpLogo;
  }, [data?.backgroundImage, data?.themeType]);

  if (isLoading) {
    return <span>Loading...</span>;
  }

  if (isError) {
    return <span>Error : {error as any}</span>;
  }

  return (
    <div className={`p-3 ${styles.settingWrapper}`}>
      {/* Brand Setting Header */}
      <p className={`${styles.heading} saufter_h4`}>Brand settings</p>

      {/* Branding */}
      <div
        className={`d-flex justify-content-between align-items-center px-2 saufter_medium_h4 ${
          styles.dropBtn
        }  ${dropdown && styles.borderSet}`}
        onClick={() => {
          setDropDown(!dropdown);
          setStyleTheme(false);
        }}
      >
        <span>Branding</span>
        <span className={`${styles.arrow}`}>
          {dropdown ? (
            <img src={arrowRight} alt="" className={`${styles.arrowDown}`} />
          ) : (
            <img src={arrowRight} alt="" />
          )}
        </span>
      </div>
      {dropdown ? (
        <div className={`p-2 p-md-3 mb-2 ${styles.dropDown}`}>
          <div className="d-flex justify-content-between mb-3">
            <div className={`pe-1 ${styles.textWrapper}`}>
              <h6 className={`mb-0 ${styles.logoText} saufter_medium_h4`}>
                Logo
              </h6>
              <span className={`${styles.info} pe-1 saufter_h6 mb-1`}>
                Company Logo
              </span>
            </div>
            <div>
              <div className={`${styles.imgWrapper}`}>
                {data?.logoImage ? (
                  <AxiosImg
                    url={data?.logoImage}
                    className={`${styles.logo}`}
                  />
                ) : (
                  <img src={helpLogo} alt="" className={`${styles.logo}`} />
                )}
              </div>
              <div className={`d-flex align-items-center`}>
                <span
                  className={`${styles.replaceBtn} pe-2 saufter_h6 ${
                    imagesLoading ? styles.disabled : ""
                  }`}
                  onClick={() => {
                    handleReplaceImage("logoImage");
                  }}
                >
                  Replace
                </span>
                <span
                  className={`${styles.resetBtn} ${
                    imagesLoading || !data?.logoImage ? styles.disabled : ""
                  }`}
                  onClick={() => {
                    handleResetImage("logoImage");
                  }}
                >
                  Reset
                </span>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between mb-3">
            <div className={`pe-1 ${styles.textWrapper}`}>
              <h6 className={`mb-0 ${styles.logoText} saufter_medium_h4`}>
                Fav Icon
              </h6>
              <span className={`${styles.info} pe-1 saufter_h6 mb-1`}>
                Icon displayed in the address bar of your browser.
              </span>
            </div>
            <div>
              <div className={`${styles.imgWrapper}`}>
                {data?.favicon ? (
                  <AxiosImg url={data?.favicon} className={`${styles.logo}`} />
                ) : (
                  <img src={helpLogo} alt="" className={`${styles.logo}`} />
                )}
              </div>
              <div className={`d-flex align-items-center`}>
                <span
                  className={`${styles.replaceBtn} pe-2  saufter_h6 ${
                    imagesLoading ? styles.disabled : ""
                  }`}
                  onClick={() => {
                    handleReplaceImage("favicon");
                  }}
                >
                  Replace
                </span>
                <span
                  className={`${styles.resetBtn} ${
                    imagesLoading || !data?.favicon ? styles.disabled : ""
                  }`}
                  onClick={() => {
                    handleResetImage("favicon");
                  }}
                >
                  Reset
                </span>
              </div>
            </div>
          </div>
          {/*  {/* Nov 29 hided the footer logo, default saufter logo is shown in the footer  */}
          {/* <div className="d-flex justify-content-between mb-3">
            <div className={`pe-1 ${styles.textWrapper} disabled `}>
              <h6 className={`mb-0 saufter_h5`}>Footer Logo</h6>
              <span className={`${styles.desc} pe-1 saufter_h6 mb-1`}>
                This is your brand’s logo, shown on the top left and in the
                footer of your knowledge base.
              </span>
            </div>
            <div>
              <div className={`${styles.imgWrapper}`}>
                {data?.footerLogo ? (
                  <AxiosImg
                    url={data?.footerLogo}
                    className={`${styles.logo}`}
                  />
                ) : (
                  <img src={helpLogo} alt="" className={`${styles.logo}`} />
                )}
              </div>
              <div className={`d-flex align-items-center`}>
                <span
                  className={`${
                    imagesLoading ? styles.disabled : ""
                  } pe-2 saufter_h6 ${styles.replaceBtn} `}
                  onClick={() => {
                    handleReplaceImage("footerLogo");
                  }}
                >
                  Replace
                </span>
                <span
                  className={` ${styles.resetBtn} ${
                    imagesLoading || !data?.footerLogo ? styles.disabled : ""
                  }`}
                  onClick={() => {
                    handleResetImage("footerLogo");
                  }}
                >
                  Reset
                </span>
              </div>
            </div>
          </div> */}
          <div className="d-flex justify-content-between mb-3 row">
            <div className="pe-1 col-7">
              {/* conditionally showing the "Helplama" | "Saufter" based on branding text */}
              <h6 className={`mb-0 ${styles.logoText} saufter_h5`}>{domainBranding}{" "}Branding</h6>
              <span className={`${styles.info} pe-1 saufter_h6 mb-1`}>
                  {/* dynamically rendering helplama branding text */}
                Company Remove the “{helplamaBrandingText}” link in your knowledge
                base footer.
              </span>
            </div>
            <div className="col-5 ">
              <div className="form-check form-switch d-flex justify-content-end">
                <input
                  className={`form-check-input ${styles.toggleCheck}`}
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckDefault"
                  checked={helplamaBranding}
                  onChange={handleHelplamaBranding}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {/* Color and Background */}
      <div
        className={`d-flex justify-content-between align-items-center px-2 mt-2 saufter_medium_h4 ${
          styles.dropBtn
        } ${styleTheme && styles.borderSet}`}
        onClick={() => {
          setStyleTheme(!styleTheme);
          setDropDown(false);
        }}
      >
        <span>Color and Background</span>
        <span className={`${styles.arrow}`}>
          {styleTheme ? (
            <img src={arrowRight} alt="" className={`${styles.arrowDown}`} />
          ) : (
            <img src={arrowRight} alt="" />
          )}
        </span>
      </div>
      {styleTheme ? (
        <div className={`p-2 p-md-3 ${styles.dropDown2}`}>
          {/* Background Color */}
          <div className="row mb-2 p-2 p-md-3 d-flex flex-row">
            <div className={`col-7 w-50 ps-0 ms-0 text-break`}>
              <p className={`mb-0 d-inline-block saufter_h5`}>
                Background colour
              </p>

              <span className={`${styles.colorInfo} saufter_h6`}>
                This color is used as the background for the header and the
                mobile menu.
              </span>
            </div>
            <div className={`col-5 w-50 ${styles.colPicker} d-flex h-25`}>
              <input
                id={"kb-brand-settings-bg-color"}
                className={`w-25 border ${styles.colorPick}`}
                type="color"
                value={backgroundColor}
                onChange={handleBackgroundColorChange}
              />
              <label
                className="small w-75 cursor-pointer"
                htmlFor="kb-brand-settings-bg-color"
              >
                <small>{backgroundColor}</small>
              </label>
            </div>
            <span className={`d-flex justify-content-end ${styles.saveText}`}>
              {textStatus.backgroundColor}
            </span>
          </div>

          {/* Brand Color */}
          <div className="row mb-2 p-2 p-md-3 d-flex flex-row">
            <div className={`col-7 ps-0 ms-0 w-50`}>
              <p className={`mb-0 d-inline-block saufter_h5`}>Brand colour</p>
              <span className={`${styles.colorInfo} saufter_h6`}>
                This color is used for links and buttons.
              </span>
            </div>
            <div className={`col-5 w-50 ${styles.colPicker} d-flex h-25`}>
              <input
                id={"kb-brand-settings-button-color"}
                className={`w-25 border ${styles.colorPick}`}
                type="color"
                value={brandColor}
                onChange={handleBrandColorChange}
              />
              <label
                className="small w-75 cursor-pointer"
                htmlFor="kb-brand-settings-button-color"
              >
                <small>{brandColor}</small>
              </label>
            </div>
            <span className={`d-flex justify-content-end ${styles.saveText}`}>
              {textStatus.brandColor}
            </span>
          </div>

          {/* Background Image */}
          <div className="d-flex flex-wrap justify-content-between mb-3 px-1">
            <div className={`pe-1 ${styles.textWrapper}`}>
              <h6 className={`mb-0 saufter_h5`}>BACKGROUND IMAGE</h6>
              <span className={`${styles.bginfo} saufter_h6 pe-1`}>
                Show in the header of your website.
              </span>
              <p className={`mb-0 ${styles.bginfo} saufter_h6 pe-1`}>
                {" "}
                Ideal image size should be 1200px x 280px .
              </p>
            </div>
            <div>
              <div className={`${styles.imgWrapper}`}>
                {data?.backgroundImage ? (
                  <AxiosImg
                    url={data?.backgroundImage}
                    className={`${styles.logo}`}
                  />
                ) : (
                  <img src={backgroundImage} alt="" className={`${styles.logo}`} />
                )}
              </div>
              <div className={`d-flex align-items-center`}>
                <span
                  className={`d-inline-block ${styles.replaceBtn} saufter_h6 ${
                    imagesLoading ? styles.disabled : ""
                  } pe-2 `}
                  onClick={() => {
                    handleReplaceImage("backgroundImage");
                  }}
                >
                  Replace
                </span>
                <span
                  className={`${styles.resetBtn} ${
                    imagesLoading || !data?.backgroundImage
                      ? styles.disabled
                      : ""
                  }`}
                  onClick={() => {
                    handleResetImage("backgroundImage");
                  }}
                >
                  Reset
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default BrandSetting;
