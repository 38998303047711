import { axiosJSON } from "src/globals/axiosEndPoints";
// import log from 'loglevel';

export type ChangeDraftStatusPayload = {
  ticketId: number;
  draftStatusId: number;
  msgId: number;
};

export async function changeDraftStatus(
  servicePayload: ChangeDraftStatusPayload
) {
  const { data } = await axiosJSON.post(
    `/api/ticket/updateMsgDraftStatus`,
    servicePayload
  );
  if (data.err === true) {
    throw new Error(data.msg as string);
  }
  return data;
}
