import { MutableRefObject, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import TinyMCEContainer from "src/components/TinyMCEContainer";
import useGetVariables from "src/hooks/automation/useGetVariables";
import { replaceKeyWithSpan, replaceSpanWithKey } from "src/utils/utils";
import { v4 as uuid } from "uuid";
import styles from "./MessageSection.module.scss";

function MessageSection(props: {
  updateOrder: Function;
  pushCurrentHash: Function;
  validateTabType: Function;
  placeholder?: string;
  message: { value: string; error?: string | null };
  id: string;
  text?: string;
  hideHeader?: boolean;
  hideError?: boolean;
  insertPlaceholderRef?: MutableRefObject<any>;
}) {
  const [message, setMessage] = useState<string>(props.message.value ?? "");

  // Custom hook to get variables data
  const { variables } = useGetVariables();

  useEffect(() => {
    // Initialize the data by replacing Variable Key if any with proper styling
    const value = replaceKeyWithSpan(message, variables ?? {});
    setMessage(value);
  }, [variables]);

  // Function to update Store data
  const updateValues = (e: string) => {
    // TinyMCEContainer returns a html string
    // Parse the text from html string
    const value = replaceSpanWithKey(e, variables ?? {});
    // Set the value in store
    props.updateOrder(props.id, {
      messageForCustomer: {
        value: value,
      },
    });
    // Update the hash for config
    props.pushCurrentHash(props.id);
    // Validate the data
    props.validateTabType(props.id);
  };

  return (
    <div className="">
      {props.hideHeader !== true ? (
        <div className="mt-4">
          <div className={`${styles.lineText}`}>
            {props.text ? props.text : ""}
          </div>
        </div>
      ) : null}

      <div>
        <Form.Group
          className={`mb-3 mt-3 ${styles.textareaWrapper}`}
          controlId="exampleForm.ControlTextarea1"
        >
          <TinyMCEContainer
            className={`border ${styles.textBox} ${
              props.message.error ? "border-danger" : ""
            }`}
            value={message}
            onChange={(e) => {
              // Updates the state for message and sets the data in store
              setMessage(e);
              updateValues(e);
            }}
            options={{
              placeholder: props.placeholder
                ? props.placeholder
                : "Write your message...",
            }}
            uniqueID={uuid()}
            showAttachmentOverlay={false}
            showToolbar={false}
            getMentionRef={(data) => {
              if (props.insertPlaceholderRef) {
                props.insertPlaceholderRef.current = data;
              }
            }}
            hideAllControls={true}
          />
          {/* <Form.Control
            as="textarea"
            style={{ resize: "none",font: "normal normal normal 12px/19px Poppins"  }}
            rows={3}
            placeholder="write your message..."
            onChange={(e) => {
              props.updateOrder(props.id, {
                messageForCustomer: {
                  value: e.target.value,
                },
              });
              props.pushCurrentHash(props.id);
              props.validateTabType(props.id);
            }}
            value={props.message.value ?? ""}
            className={`${styles.textArea} ${props.message.error ? "border border-danger" : ""}`}
          /> */}
          {props.hideError !== true && props.message.error ? (
            <p className={`${styles.errorStyle}`}>{props.message.error}</p>
          ) : null}
        </Form.Group>
      </div>
    </div>
  );
}

export default MessageSection;
