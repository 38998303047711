import { axiosJSON } from "src/globals/axiosEndPoints";
import { BetweenRange } from "./createSegment.service";

export interface SegmentFilterOption {
  key: string;
  name: string;
  showDateTimePicker?: boolean;
  defaultDateTime?: string | BetweenRange<string>;
  isRange?: boolean;
}

export interface SegmentFilter {
  filterKey: string;
  name: string;
  type: "string" | "number" | "select";
  placeholder?: string;
  selectOptions?: SegmentFilterOption[];
  isMulti?: boolean;
  defaultValue?:
    | Array<SegmentFilterOption> // For type - select and isMulti - true
    | SegmentFilterOption // For type - select
    | string
    | number
    | null;
}

/**
 * Retrieves the segment filters for a given segment type.
 *
 * @param payload.segmentType The type of customer (company, people, reviews, accounts).
 * @param payload.segmentId The segment id of the active segment.
 *
 * @returns A promise that resolves to the segment filters response.
 * @throws If an error occurs during the API request or if the response contains an error message.
 */
const getSegmentFiltersService = async ({
  segmentType,
  ...payload
}: {
  segmentType: string;
  segmentId: string;
}): Promise<SegmentFilter[]> => {
  const { data: res } = await axiosJSON.post(
    `/api/segment/${segmentType}/getFilters`,
    payload,
  );

  if (res.error || res.err) {
    throw new Error(res.msg ?? res.message ?? "Something Unexpected Occured!");
  }

  const ret: SegmentFilter[] = res.data;

  for (let i = 0; i < ret.length; i++) {
    ret[i].filterKey = ret[i].filterKey + "";
    for (let j = 0; j < (ret[i].selectOptions?.length ?? 0); j++) {
      (ret[i].selectOptions as any)[j].key =
        (ret[i].selectOptions as any)[j].key + "";
    }
  }

  return ret;
};

export default getSegmentFiltersService;
