import { useRef } from "react";
import ContentEditable from "react-contenteditable";
import styles from "./ComponentStyles.module.scss";

function ReactContentEditable(props: {
  orderTypeName: string | null | undefined;
  orderSequence?: number;
  onContentChange: (value: string) => void;
}) {
  const text = useRef(
    `
    <div id="orderTypeContent" class="${styles.outlineProp}" >
      ${props.orderTypeName} 
    </div>
  `,
  );

  return (
    <div>
      <ContentEditable
        html={text.current} // innerHTML of the editable div
        onChange={(e) => {
          text.current = e.target.value;
          const contentEl = e.currentTarget.querySelector("#orderTypeContent");
          if (contentEl) props.onContentChange(contentEl.innerText);
        }} // handle innerHTML change
        onKeyDown={(e) => {
          const keyCode = e.which || e.keyCode;
          if ((e.shiftKey && keyCode === 13) || e.key === "Enter") {
            e.preventDefault();
            return false;
          }
        }}
      />
    </div>
  );
}

export default ReactContentEditable;
