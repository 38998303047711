import { axiosJSON } from "src/globals/axiosEndPoints";
export interface SendWidgetScriptEmailPayload{
  integrationId: number;
  message: string;
  toEmails: string;
}

//function to make POST request to sendWidgetScriptEmail api with required payload
export const sendWidgetScriptEmail = async (payload:SendWidgetScriptEmailPayload) => {

  const { data: res } = await axiosJSON.post(`/api/chatSetting/widget/sendWidgetScriptEmail`, payload);

  //checking if error occured
  if (res.err) {
    throw res.msg;
  }

  //returning the response
  return res;
};
