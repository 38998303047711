import { Channel } from "pusher-js";
import { Message } from "src/services/LiveChat/messageExchange/getMessages";

export type ChatEndedEventCB = (res: any) => void;

export const getChatEndedEvent = (
  channel: Channel,
  callback: ChatEndedEventCB
) => {
  channel.bind("chat_disconnected_from_customer_widget_side", callback);
};

export const getChatEndedEventUnbind = (
  channel: Channel,
) => {
  channel.unbind("chat_disconnected_from_customer_widget_side");
};

export const getAgentChatEndedEvent = (
  channel: Channel,
  callback: ChatEndedEventCB
) => {
  channel.bind("chat_disconnected_from_agent_side", callback);
};
