/**
 * This file contains the custom hook for handling KB settings.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { deleteKnowledgeBase } from "src/services/KnowledgeBase/deleteKnowledgeBase.service";
import { GetAllKnowledgeBaseRes } from "src/services/KnowledgeBase/getAllKnowledgeBase.service";

// Interface defining the props for the hook
interface Props {
  knowledgeBaseId: string | number;
  setShowSuccessModal: (value: boolean) => void;
  onHide: () => void;
}

/**
 * Custom hook for handling the deletion of a Knowledge Base.
 * Manages the deletion mutation, updating query data, and handling success or error scenarios.
 */
function useDeleteKb({ knowledgeBaseId, setShowSuccessModal, onHide }: Props) {
  // Mutation for deleting the Knowledge Base
  const delelteKb = useMutation({
    mutationFn: deleteKnowledgeBase,
  });

  // Query client for updating data in cache
  const queryClient = useQueryClient();

  // Callback function for handling the delete operation
  const handleDelete = useCallback(() => {
    delelteKb.mutate(
      {
        knowledgeBaseId: knowledgeBaseId,
      },
      {
        onSuccess: () => {
          // Update query data in cache after successful deletion
          queryClient.setQueriesData(
            ["getAllKnowledgeBase"],
            (oldData: any) => {
              const pages = oldData.pages.map(
                (page: GetAllKnowledgeBaseRes) => {
                  const filteredData = page.data.filter(
                    (kb: any) => kb.knowledgeBaseId !== knowledgeBaseId
                  );
                  return {
                    ...page,
                    data: filteredData,
                    metaData: {
                      currentCount: filteredData.length,
                      totalCount: page.metaData.totalCount - 1,
                      addNewEnabled: true,
                    },
                  } as GetAllKnowledgeBaseRes;
                }
              );

              return {
                oldData,
                pages: pages,
              };
            }
          );
          // Show success modal after successful deletion
          setShowSuccessModal(true);
        },
        onError: () => {
          // Show error toast in case of deletion failure
          pushTheToast({
            text: "Something went wrong",
            type: "danger",
            position: "top-right",
          });
        },
      }
    );
  }, []);

  // Return relevant data and functions for external use
  return {
    delelteKb,
    handleDelete,
    isDeleting: delelteKb.isLoading,
  };
}

export default useDeleteKb;
