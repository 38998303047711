import React from "react";
import styles from "./TemplateMainSection.module.scss";
import TemplateComp from "./Children/TemplateComp/TemplateComp";
import AllTemplate from "src/components/Template/children/AllTemplate/AllTemplate";
import AllTemplates from "./Children/AllTemplates/AllTemplates";

const TemplateMainSection = ({
  setTemplates,
  selectedCategory,
  setSelectedCategory,
  templates,
  setSelectedTemplateData = (template: any) => {},
  onTemplateSelect = (template: string) => {},
}: {
  setTemplates: any;
  selectedCategory: any;
  templates: any;
  setSelectedCategory: any;
  setSelectedTemplateData: any;
  onTemplateSelect: any;
}) => {
  return (
    <div className={`p-2 ${styles.templateWrapper}`} id="allTemplate">
      <div className="h-100">
        <AllTemplates
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          templates={templates}
          setTemplates={setTemplates}
          setSelectedTemplateData={(template: any) => {
            setSelectedTemplateData(template);
          }}
          onTemplateSelect={(template: string) => {
            onTemplateSelect(template);
          }}
        />
      </div>
    </div>
  );
};

export default TemplateMainSection;
