import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  GetLinkableAccountsResponse,
  getLinkableAccounts,
} from "src/services/Integrations/WhatsappIntegration.service";

interface Props {
  integrationId?: string;
  initialFetchDone?: () => void;
  selectedPhoneNumber: GetLinkableAccountsResponse | null;
  additionalLinkableAccount?: GetLinkableAccountsResponse;
}

/**
 * Custom hook to fetch the whatsapp linkable accounts
 */
export const useFetchLinkableAccounts = ({
  integrationId,
  initialFetchDone,
  selectedPhoneNumber,
  additionalLinkableAccount,
}: Props) => {
  // Use the useQuery hook to fetch WhatsApp linkable accounts
  const { data, isLoading } = useQuery(["getLinkableWhatsappAccounts"], {
    queryFn: getLinkableAccounts,
    staleTime: 60000, // Time in milliseconds before query is considered stale
    cacheTime: 60000, // Time in milliseconds to keep query results in cache
    onSuccess: () => {
      // Callback function to be executed on successful data fetching
      if (initialFetchDone) {
        initialFetchDone();
      }
    },
    // Enable query only if integrationId is present
    enabled: integrationId !== undefined,
  });

  /**
   * Object containing all accounts
   */
  const allLinkableAccounts = useMemo(() => {
    // Extract values from data.accounts and convert to an array
    return Object.values(data?.accounts ?? {});
  }, [data]);

  /**
   * Memoized Linkable accounts with filtered selected account
   */
  const linkableAccounts = useMemo(() => {
    if (additionalLinkableAccount) {
      return [additionalLinkableAccount, ...allLinkableAccounts].filter(
        (account) => account.id !== selectedPhoneNumber?.id,
      );
    }
    // Filter out the selectedPhoneNumber from allLinkableAccounts
    return allLinkableAccounts.filter(
      (account) => account.id !== selectedPhoneNumber?.id,
    );
  }, [allLinkableAccounts, selectedPhoneNumber?.id, additionalLinkableAccount]);

  return {
    linkableAccounts,
    isLoading,
  };
};
