import Pusher, { Channel } from "pusher-js";

/**
 * @remarks
 * If in development mode, logs Pusher events to the console.
 */
if (process.env.REACT_APP_NODE_ENV === "development") {
  Pusher.logToConsole = true;
}

let NEW_PUSHER = null as null | Pusher;

/**
 * Initialize Pusher instance.
 * @returns Initialized Pusher instance.
 */
export const setNewPusher = ({
  name,
  value,
  pusherKey,
  pusherCluster,
}: {
  /**
   * Name of the header for authentication.
   */
  name: string;
  /**
   * Value of the header for authentication.
   */
  value: string;
  /**
   * Pusher API key.
   */
  pusherKey: string;
  /**
   * Pusher cluster.
   */
  pusherCluster: string;
}) => {
  if (NEW_PUSHER) {
    NEW_PUSHER.disconnect();
  }
  NEW_PUSHER = new Pusher(pusherKey, {
    cluster: pusherCluster,
    authEndpoint: `${
      process.env.REACT_APP_SITE_URL ?? window.location.origin
    }/api/common/pusher/auth`,
    auth: {
      headers: {
        [name]: value,
      },
    },
  });
  return NEW_PUSHER;
};

/**
 * Subscribe to a Pusher channel.
 * @param name - The name of the channel to subscribe to.
 * @returns The subscribed Pusher channel, or null if name is not provided.
 */
export const channelSubscriber = (name?: string | null) => {
  // Check if name parameter is provided
  if (name) {
    // If name is provided, check if the channel is already subscribed
    const channel = NEW_PUSHER?.allChannels()?.find(
      (value) => value.name === name
    );
    if (channel) {
      // Return the channel if already subscribed
      return channel;
    } else {
      // If not subscribed, subscribe to the channel
      const c: any = NEW_PUSHER?.subscribe(name);
      if (c) {
        c.oldbind = c.bind;
        // Wrap bind function to check if document is hidden before executing callback
        c.bind = (eventName: any, callback: any, context: any) => {
          return c.oldbind(
            eventName,
            context?.pauseOnInActiveTab
              ? (res: any) => {
                  // Check if an API call is needed based on response
                  // if (res.needAPICall) {
                  // Check if the document is not hidden before executing the callback
                  if (!document.hidden) {
                    // Execute callback if document is visible
                    callback(res);
                  }
                  // } else {
                  //   // Execute callback without checking if API call is needed
                  //   callback(res);
                  // }
                }
              : callback,
            context
          );
        };
        return c as Channel;
      } else {
        return null;
      }
    }
  } else {
    // Return null if name is not provided
    return null;
  }
};

/**
 * Unsubscribe from a Pusher channel.
 * @param name - The name of the channel to unsubscribe from.
 * @returns Always returns null.
 */
export const channelUnsubscriber = (name?: string) => {
  if (name) {
    NEW_PUSHER?.unsubscribe(name);
  }
  return null;
};

/**
 * Bind a callback to the "connected" event of the Pusher connection.
 * @param {Function} callback - The callback function to be executed when the "connected" event occurs.
 */
export const bindPusherConnected = (callback: Function) => {
  NEW_PUSHER?.connection.bind("connected", callback);
};

/**
 * Bind a callback to the "disconnected" event of the Pusher connection.
 * @param {Function} callback - The callback function to be executed when the "disconnected" event occurs.
 */
export const bindPusherDisconnected = (callback: Function) => {
  NEW_PUSHER?.connection.bind("disconnected", callback);
};

/**
 * Unbind a callback from the "connected" event of the Pusher connection.
 * @param {Function} callback - The callback function to be unbound from the "connected" event.
 */
export const unbindPusherConnected = (callback: Function) => {
  NEW_PUSHER?.connection.unbind("connected", callback);
};

/**
 * Unbind a callback from the "disconnected" event of the Pusher connection.
 * @param {Function} callback - The callback function to be unbound from the "disconnected" event.
 */
export const unbindPusherDisconnected = (callback: Function) => {
  NEW_PUSHER?.connection.unbind("disconnected", callback);
};
