import styles from "./CallRecording.module.scss";
import RecordMain from "./children/RecordMain";
import RecordTabSec from "./children/RecordTabSec";
const CallRecording = () => {
  return (
    <div className={`${styles.recordMain}`}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <h1 className={`${styles.callHeading}`}>Call Recordings</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <RecordTabSec />
            <RecordMain />
          </div>
        </div>
      </div>
    </div>
  );
};
export default CallRecording;
