import { Channel } from "pusher-js";
import { IMessageData } from "src/store/slices/innerTicket/innerTicket.slice";
export interface MessageStatusUpdatedRes {
  messageId: number | string;
  ticketId: number | string;
  messageInfo: IMessageData["messageInfo"];
}

export type messageStatusUpdateCB = (res: MessageStatusUpdatedRes) => void;

export const bindTicketMessageStatusUpdatedEvent = (
  channel: Channel,
  callback: messageStatusUpdateCB
) => {
  channel.bind("ticket_event_message_status_updated", callback, {
    pauseOnInActiveTab: true,
  });
};

export const unbindTicketMessageStatusUpdatedEvent = (channel: Channel) => {
  channel.unbind("ticket_event_message_status_updated");
};
