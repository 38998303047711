import { axiosJSON } from "src/globals/axiosEndPoints";
import { AJAXSTATUS } from "src/globals/constants";

interface ImgObj {
  imageData?: { image: string };
  loading: AJAXSTATUS;
  callbacks?: Array<() => void>;
}

const imgObj: { [key: string]: ImgObj } = {};

const getTheSavedImage = (key: string) => {
  return imgObj[key];
};
const saveTheFetchedImage = (key: string, res: ImgObj) => {
  if (res.loading === "fulfilled") {
    const callbacks = imgObj[key]?.callbacks;
    imgObj[key] = res;
    if (callbacks?.length) {
      callbacks.forEach((fun) => {
        fun();
      });
    }
  } else {
    imgObj[key] = { ...res, callbacks: [] };
  }
};
const pushIntoCallback = (key: string, callback: () => void) => {
  imgObj[key].callbacks?.push(callback);
};
const deleteTheImage = (key: string) => {
  delete imgObj[key];
};

export async function getAxiosImage(url: string) {
  const imgDataValue = getTheSavedImage(url);
  if (imgDataValue?.loading === "pending") {
    return new Promise((resolve, reject) => {
      pushIntoCallback(url, () => {
        const newImgDataValue = getTheSavedImage(url);
        if (
          newImgDataValue?.loading === "fulfilled" &&
          newImgDataValue?.imageData
        ) {
          resolve(newImgDataValue.imageData);
        } else {
          reject("Something is wrong in fetching image!");
        }
      });
    }) as Promise<{ image: string }>;
  } else if (imgDataValue?.loading === "fulfilled" && imgDataValue?.imageData) {
    return imgDataValue.imageData;
  } else {
    saveTheFetchedImage(url, {
      loading: "pending",
    });
    const img = await axiosJSON.get(url, { responseType: "blob" });
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(img.data);
      reader.onloadend = () => {
        const base64data = reader.result;
        if (typeof base64data === "string") {
          const theImgData = {
            imageData: { image: base64data },
            loading: "fulfilled",
          };
          saveTheFetchedImage(url, theImgData as ImgObj);
          resolve(theImgData.imageData);
        } else {
          deleteTheImage(url);
          reject("Url is not valid!");
        }
      };
      reader.onerror = (error) => {
        deleteTheImage(url);
        reject(error);
      };
    }) as Promise<{ image: string }>;
  }
}

export async function downloadFile({
  url,
  fileName,
}: {
  url: string;
  fileName: string;
}) {
  const file = await axiosJSON.get(url, { responseType: "blob" });
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.data);
    reader.onloadend = () => {
      if (typeof reader.result === "string") {
        const a = document.createElement("a");
        a.href = reader.result;
        a.setAttribute("download", fileName);
        a.click();
        resolve(true);
      } else {
        reject("Url is not valid!");
      }
    };
    reader.onerror = (error) => {
      reject(error);
    };
  }) as Promise<boolean>;
}
