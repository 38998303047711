import React from "react";
import ThemeBox from "../ArticleSideBar/ThemeSelection/Children/ThemeBox/ThemeBox";
import styles from "./Brand.module.scss";
import BrandSetting from "./Children/BrandSetting/BrandSetting";
import useThemeBox from "../ArticleSideBar/ThemeSelection/Children/ThemeBox/useThemeBox";
import Loader from "src/components/Loader";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { useNavigate } from "react-router-dom";

const Brand = () => {
  const navigate = useNavigate();
  const { isError, isLoading } = useThemeBox();
  // The useThemeBox hook is used to check if there is an error or if the data is still loading.  If isLoading is true, it renders a Loader component. // If isError is true, it displays an error message using pushTheToast and renders an error message. // If neither isError nor isLoading is true, it renders the main content of the Brand section, including the heading and subheading, as well as the ThemeBox and BrandSetting components.
  if (isLoading) {
    return (
      <>
        <div className={`w-100 ${styles.loaderWrapper}`}>
          <span>
            <Loader />
          </span>
        </div>
      </>
    );
  }

  if (isError) {
    pushTheToast({
      position: "top-right",
      text: "Something went wrong",
      type: "danger",
    });

    return (
      <>
        <div className={`p-2 w-100`}>
          <span className={`${styles.errorMsg}`}>Something Went Wrong</span>
        </div>
      </>
    );
  }

  return (
    <div className={`w-100 pt-4 px-3 ${styles.brandWrapper}`}>
      <div className="mb-4 d-flex">
        <div>
          <div
            className={`d-flex justify-content-center align-items-center ${styles.backChat}`}
            onClick={() => navigate(-1)}
          >
            <i className="fa-solid fa-arrow-left"></i>
          </div>
        </div>
        <div>
          <h3 className={`mb-0 ${styles.heading} saufter_h3`}>
            Style Helpcenter page / Brand
          </h3>
          <p className={`${styles.subHeading} saufter_subHeading`}>
            Let customers style the help center right inside your chat widget
            instead of starting a conversation.
          </p>
        </div>
      </div>
      <div className={`d-block d-md-flex`}>
        <ThemeBox />
        <BrandSetting />
      </div>
    </div>
  );
};

export default Brand;
