const lsKey = "HELPDESK_VIEW_DATA_";

export const setViewLS = ({
  viewId,
  formData,
}: {
  viewId: string | null;
  formData: Object;
}) => {
  window.localStorage.setItem(`${lsKey}${viewId}`, JSON.stringify(formData));
};

export const getViewLS = ({ viewId }: { viewId: string | null }) => {
  const data = window.localStorage.getItem(`${lsKey}${viewId}`);
  try {
    if (data) {
      return JSON.parse(data);
    } else {
      return null;
    }
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const removeViewLS = ({ viewId }: { viewId: string | null }) => {
  window.localStorage.removeItem(`${lsKey}${viewId}`);
};
