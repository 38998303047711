import {axiosJSON} from "src/globals/axiosEndPoints";

interface DeleteCannedResponse{
    cannedResponesId: string | number;
    status : boolean;
}

export interface DeleteCannedResponseParam{
    cannedResponseId: string | number;
}

export async function deleteCannedResponse(params: DeleteCannedResponseParam){

    const {data: res} = await axiosJSON.delete(
        "/api/chatSetting/cannedResponse/delete",
        {params}
    );

    if(res.error === true){
        throw new Error(res.message as string);
    }

    return res.data as DeleteCannedResponse;
}