import UserAvatar from "src/components/UserAvatar";
import styles from "./ChatHandleData.module.scss";
import saufterImg from "src/assets/images/saufter.gif";
import {
  CurrentStatusEndPoints,
  TicketData,
} from "src/services/report/reportTicketList.service";
import { useAppSelector } from "src/store/store";
import { getTimeFromNow } from "src/utils/dateLibrary";
import AxiosImg from "src/components/AxiosImg";
import { getChannelFAIcon } from "src/utils/utils";

interface Props {
  endPoint: CurrentStatusEndPoints;
  data: TicketData;
  onClick: any;
}

const ChatHandleData = ({ endPoint, data, onClick }: Props) => {
  // Get user timezone from Redux store
  const userTimezone = useAppSelector(
    (state) => state.globals.currentUserData?.userTimezone,
  );

  return (
    <div
      className={`d-flex align-items-center mb-1 ${styles.chatWrapper}`}
      onClick={onClick}
      data-chat-id={data?.chatId}
      data-chat-status={data?.customerStatus}
      key={data.ticket_id}
    >
      <div
        className={`${
          endPoint.includes("percentageCernFoundUseful") ? styles.chatItems : ""
        } ${
          !endPoint.includes("percentageCernFoundUseful") &&
          !endPoint.includes("chatSatisfactionReports") &&
          !endPoint.includes("chatAvailabilityReports") &&
          !endPoint.includes("revenueGeneratedByTime") &&
          "w-100"
        } ${
          (endPoint.includes("chatSatisfactionReports") ||
            endPoint.includes("chatAvailabilityReports")) &&
          styles.chatItem
        } ${
          endPoint.includes("revenueGeneratedByTime") && styles.revenueItem
        } `}
      >
        <div className={`d-flex`}>
          {/* Render customer avatar */}
          {!endPoint.includes("chatSatisfactionReports") &&
          !endPoint.includes("chatAvailabilityReports") &&
          !endPoint.includes("revenueGeneratedByTime") ? (
            data?.customer?.imgUrl && data?.customer?.imgUrl.length !== 0 ? (
              <AxiosImg
                url={data?.customer?.imgUrl}
                className={`${styles.profileIcon} rounded-circle`}
              />
            ) : (
              <div>
                <UserAvatar
                  name={
                    data?.customer?.customerName &&
                    data?.customer?.customerName !== ""
                      ? data?.customer?.customerName
                      : "NA"
                  }
                  size={21}
                />
              </div>
            )
          ) : null}
          {endPoint.includes("chatAvailabilityReports") ? (
            data?.agentImageURL && data?.agentImageURL?.length !== 0 ? (
              <AxiosImg
                url={data?.agentImageURL}
                className={`${styles.profileIcon} rounded-circle`}
              />
            ) : (
              <div>
                <UserAvatar
                  name={
                    data?.agentName && data?.agentName !== ""
                      ? data?.agentName
                      : "NA"
                  }
                  size={21}
                />
              </div>
            )
          ) : null}

          <div
            className={`ps-3 pe-2 ${
              endPoint.includes("chatSatisfactionReports") ||
              endPoint.includes("chatAvailabilityReports")
                ? "w-100"
                : "w-75"
            } ${
              endPoint.includes("chatAvailabilityReports") &&
              "d-flex align-items-center"
            }`}
          >
            {endPoint.includes("chatSatisfactionReports") && (
              <p className={`mb-0 ${styles.msgHead}`}>
                {data.customer?.customerName}
              </p>
            )}
            {endPoint.includes("revenueGeneratedByTime") && (
              <p className={`mb-0 ${styles.msgHead}`}>{data.ticket_subject}</p>
            )}
            {endPoint.includes("chatAvailabilityReports") && (
              <p className={`mb-0 ${styles.msgHead}`}>{data?.agentName}</p>
            )}
            {/* Render last message and its time */}
            {!endPoint.includes("chatAvailabilityReports") && (
              <p
                className={`mb-0 text-truncate ${
                  endPoint.startsWith("bot") && "w-75"
                } ${styles.handleHead}`}
              >
                {data?.lastMessage}
              </p>
            )}
            {!endPoint.includes("chatSatisfactionReports") &&
              !endPoint.includes("chatAvailabilityReports") &&
              !endPoint.includes("revenueGeneratedByTime") && (
                <span className={`d-block ${styles.handleTime}`}>
                  {getTimeFromNow({
                    date: new Date(data?.lastMessageTime ?? ""),
                    timeZone: userTimezone,
                  })}
                </span>
              )}

            {/* Last Message Attachments if any */}
            {data &&
              data.lastMessageAttachmentCount !== undefined &&
              data.lastMessageAttachmentCount > 0 && (
                <span
                  className={`d-block d-flex mt-2 max-content-width ${styles.attachments}`}
                >
                  <i className="fa-solid fa-paperclip my-auto ms-2 me-0"></i>
                  <span className="mx-2 my-auto">
                    {`${data.lastMessageAttachmentCount} attachment${
                      data.lastMessageAttachmentCount > 1 ? "s" : ""
                    }`}
                  </span>
                </span>
              )}
          </div>
          {!endPoint.includes("percentageCernFoundUseful") &&
            !endPoint.includes("chatSatisfactionReports") &&
            !endPoint.includes("chatAvailabilityReports") &&
            !endPoint.includes("revenueGeneratedByTime") &&
            (data?.unreadCount ?? 0) > 0 && (
              <span className={`me-5 ${styles.count}`}>
                {data?.unreadCount ?? 0}
              </span>
            )}
        </div>
      </div>
      {endPoint.includes("revenueGeneratedByTime") && (
        <div className={styles.revenueChannel}>
          <p className={`mb-0 ${styles.msgHead}`}>
            <i
              className={`override me-1 ${getChannelFAIcon(
                data.channel ? data.channel : undefined,
                true,
              )}`}
              style={data.channel ? { color: "#25D366" } : undefined}
            ></i>
            {data.channel}
          </p>
        </div>
      )}
      {(endPoint.includes("chatSatisfactionReports") ||
        endPoint.includes("chatAvailabilityReports")) && (
        <div
          className={`ps-3 ${
            endPoint.includes("chatAvailabilityReports")
              ? styles.handleAvailItem
              : styles.handleItem
          }`}
        >
          <div className={`d-flex align-items-center  ${styles.handleHead}`}>
            {endPoint.includes("chatSatisfactionReports")
              ? data?.chatRating
              : endPoint.includes("chatAvailabilityReports")
                ? data?.availabilityHours
                : 0}
          </div>
        </div>
      )}
      {/* Check if endpoint includes "percentageCernFoundUseful" */}
      {(endPoint.includes("percentageCernFoundUseful") ||
        endPoint.includes("revenueGeneratedByTime")) && (
        <>
          {/* Render last CERN response */}
          {!endPoint.includes("revenueGeneratedByTime") && (
            <div
              className={`d-flex justify-content-between align-items-center ps-3 ${styles.resItems}`}
            >
              <p className={`mb-0 text-truncate w-75 ${styles.handleHead}`}>
                {data?.customerResponse ?? ""}
              </p>
              {/* Render unread count if greater than 0 */}
              {(data?.unreadCount ?? 0) > 0 && (
                <span className={`me-5 ${styles.count}`}>
                  {data?.unreadCount ?? 0}
                </span>
              )}
            </div>
          )}
          <div
            className={`ps-3 ${
              endPoint.includes("revenueGeneratedByTime")
                ? styles.revenueHandle
                : styles.handleItem
            }`}
          >
            <div className="d-flex align-items-center">
              {data.handledBy?.map((bot, index) => {
                return (
                  <>
                    {/* Render bot details */}
                    {index == 0 && (
                      <>
                        {bot.imgUrl && bot.imgUrl.length !== 0 ? (
                          <AxiosImg
                            url={bot.imgUrl}
                            className={`${styles.profileIcon} rounded-circle`}
                          />
                        ) : (
                          <div>
                            {/* 23 Apr 2024 If type is agent show Avatar otherwise show bot image */}
                            {bot.type === "agent" ? (
                              <UserAvatar
                                name={bot.name || "NA"}
                                size={21}
                              />
                            ) : (
                              <img
                                src={saufterImg}
                                alt=""
                                width={21}
                                height={21}
                                className={`${styles.saufterImg}`}
                              />
                            )}
                          </div>
                        )}
                      </>
                    )}

                    {index === 1 && (
                      <div className="d-flex align-items-center">
                        <div className="d-flex align-items-center">
                          {/* <UserAvatar name="NA" size={21} /> */}
                          <div className={`${styles.transformLeft}`}>
                            {bot.imgUrl && bot.imgUrl.length !== 0 ? (
                              <AxiosImg
                                url={bot.imgUrl}
                                className={`${styles.profileIcon} rounded-circle`}
                              />
                            ) : (
                              <div>
                                {/* 23 Apr 2024 If type is agent show Avatar otherwise show bot image */}
                                {bot.type === "agent" ? (
                                  <UserAvatar
                                    name={bot.name || "NA"}
                                    size={21}
                                  />
                                ) : (
                                  <img
                                    src={saufterImg}
                                    alt=""
                                    width={21}
                                    height={21}
                                    className={`${styles.saufterImg}`}
                                  />
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                );
              })}
              {data.handledBy && data.handledBy?.length > 2 && (
                <div className={`${styles.transformLeft}`}>
                  <div className={`${styles.countImg}`}>
                    <span className={`${styles.botText}`}>
                      {"+"}
                      {data.handledBy?.length - 2}
                    </span>
                  </div>
                </div>
              )}
              <span className={`ps-2 ${styles.botText}`}>
                Bot
                {data.handledBy && data.handledBy?.length > 1 && (
                  <span className={`ps-2 ${styles.botText}`}>
                    and+{data.handledBy && data.handledBy?.length - 1}
                  </span>
                )}
              </span>
            </div>
          </div>
        </>
      )}
      {endPoint.includes("revenueGeneratedByTime") && (
        <div className={`ps-3 ${styles.handleAvailItem}`}>
          <div className={`d-flex align-items-center  ${styles.handleHead}`}>
            {data.revenue ?? "$0"}
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatHandleData;
