/**
 * ConfigureModal Component
 *
 * This component displays a modal for configuring audience conditions. It allows users to add, configure, and save multiple audience conditions.
 *
 * @author @yuvaraj-busibud
 * @author @navjyot-busibud
 */
import styles from "./ConfigureModal.module.scss";
import { Spinner } from "react-bootstrap";
import Loader from "src/components/Loader";
import Condition from "./Children/Condition/Condition";
import useConfigureModal from "./useConfigureModal";
interface Props {
  profileId: number | null | undefined;
  onHide: (e: any) => void;
}

const ConfigureModal = ({ profileId, onHide }: Props) => {
  const {
    audienceConfigConditions,
    handleAddCondition,
    handleSubmit,
    showSaveLoader,
    isLoading,
    saveButtonText,
    isChange,
    setIschange,
  } = useConfigureModal({ profileId });
  return (
    <>
      <div className="d-flex align-items-center pt-3">
        <div className={`${styles.closeModal}`} onClick={onHide}>
          <span className="cursor-pointer">
            <i className="fa-solid fa-xmark"></i>
          </span>
        </div>
        <h3 className={`mb-0 ms-2 ${styles.heading}`}>Configure Audience</h3>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className={`${styles.mainContent}`}>
            <div className={`p-3 mb-3 ${styles.shadowBox}`}>
              {audienceConfigConditions.map((condition, index) => {
                return (
                  <Condition
                    key={condition.id}
                    condition={condition}
                    index={index}
                    setIschange={setIschange}
                  />
                );
              })}

              <div className="d-flex align-items-center mt-2">
                <div
                  className={`${styles.addBox}`}
                  role="button"
                  onClick={handleAddCondition}
                >
                  <span>+</span>
                </div>
                <span
                  className={`ps-2 cursor-pointer ${styles.addText}`}
                  onClick={handleAddCondition}
                >
                  Add more
                </span>
              </div>
            </div>
          </div>
          <div
            className={`d-flex justify-content-end align-items-center px-3 ${styles.btnWrapper}`}
          >
            <button className={`me-2 ${styles.discardBtn}`} onClick={onHide}>
              Discard changes
            </button>
            <button
              className={`${styles.saveBtn}`}
              disabled={
                showSaveLoader ||
                saveButtonText === "Saved!" ||
                isChange === false
              }
              onClick={handleSubmit}
            >
              {showSaveLoader ? (
                <>
                  <Spinner
                    className="my-auto mx-1"
                    animation="border"
                    color="white"
                    size="sm"
                  />
                  <span>Saving...</span>
                </>
              ) : (
                saveButtonText
              )}
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default ConfigureModal;
