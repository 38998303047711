import { useCallback, useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";

import styles from "./BillingPay.module.scss";

import leftBox from "src/assets/images/leftBox.png";
import rightBox from "src/assets/images/rightBox.png";
import credit from "src/assets/images/creditCard.png";
import SeatModal from "../SeatModal/SeatModal";

import { useAppSelector } from "src/store/store";
import { useBillingContext } from "../../BillingContext";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import processCardService from "src/services/Billing/processCard.service";
import { BillingStatus } from "../SuccessModal/SuccessModal";
import { useSearchParams } from "react-router-dom";

import creditCard from "src/assets/images/icon-credit.png";
/**
 * This component is used to render Billing Details Section
 */
const BillingPay = ({
  setShowSuccessModal,
}: {
  setShowSuccessModal: (status: BillingStatus | null) => void;
}) => {
  const { billingDetails } = useBillingContext();

  const [URLSearchParams, SetURLSearchParams] = useSearchParams();

  const userTimezone = useAppSelector(
    (state) => state.globals.currentUserData?.userTimezone,
  );

  // State for Additional Seats Modal
  const [showAddSeatsModal, setShowAddSeatsModal] = useState(false);

  /**
   * Handler for showing Additional Seats modal
   */
  const handleShowAddSeatsModal = () => {
    setShowAddSeatsModal(true);
  };
  /**
   * Handler for hiding Additional Seats modal
   */
  const handleHideAddSeatsModal = () => {
    setShowAddSeatsModal(false);
  };

  const [loader, setLoader] = useState(false);

  const handleEditBilling = useCallback(async () => {
    // Process Payment
    try {
      setLoader(true);
      await processCardService();
    } catch (e) {
      console.error(e);
      pushTheToast({
        position: "top-right",
        text: "Cannot Edit Billing Details at the moment",
        type: "danger",
      });
    } finally {
      setLoader(false);
    }
  }, []);

  useEffect(() => {
    const modal = URLSearchParams.get("modal");

    if (modal === "payment") {
      // Show Add seats modal
      handleShowAddSeatsModal();

      // Remove Params
      URLSearchParams.delete("modal");
      SetURLSearchParams(URLSearchParams);
    }
  }, [SetURLSearchParams, URLSearchParams]);

  return (
    <div className={`${styles.payWrapper}`}>
      {/* Seats Details */}
      <div
        className={`d-flex flex-column flex-md-row justify-content-between align-items-lg-center ${styles.seatBox}`}
      >
        {/* Seat Count */}
        <div className="d-flex align-items-center mb-1 mb-lg-0">
          <img
            src={leftBox}
            alt=""
          />
          <div className={`${styles.seatWrapper}`}>
            <span>
              <i className="fa-solid fa-users"></i>
            </span>
            <span className={`ps-1 ${styles.seats}`}>
              {billingDetails?.seats?.totalCount} Seats
            </span>
          </div>
          <img
            src={rightBox}
            alt=""
          />
        </div>

        {/* Additional Seats Details */}
        {billingDetails?.activePlan.isBillingComplete === true &&
          billingDetails?.seats?.additionalSeats && (
            <div>
              {/* Additional Seats Add Button */}
              <button
                className={`${styles.addBtn}`}
                onClick={handleShowAddSeatsModal}
              >
                + Add additional seats{" "}
                {billingDetails.seats.additionalSeats.perUnitPrice &&
                  `@ ${billingDetails.seats.additionalSeats.perUnitPrice}/seat`}
              </button>

              {/* Additional Seats Modal */}
              <Modal
                backdropClassName={`${styles.seatBack}`}
                show={showAddSeatsModal}
                onHide={handleHideAddSeatsModal}
                dialogClassName={`${styles.seatDialog}`}
                contentClassName={`${styles.seatContent}`}
                centered={true}
                backdrop="static"
              >
                <SeatModal
                  onHide={handleHideAddSeatsModal}
                  onSuccess={(status) => {
                    setShowSuccessModal(status);
                  }}
                />
              </Modal>
            </div>
          )}
      </div>

      {/* Edit Billing Section */}
      <div
        className={`d-flex justify-content-between align-items-end ${styles.editBill}`}
      >
        {/* Last Payment Details */}
        <div className="mt-2 mb-1">
          {/* Additional Seats */}
          {billingDetails?.seats?.additionalSeats?.quantity &&
            billingDetails?.seats?.additionalSeats?.dateTimeGMT && (
              <div className="d-flex align-items-start align-items-md-center">
                <span className={`${styles.additionalSeat}`}>
                  {`Additional seats : ${
                    billingDetails.seats.additionalSeats.perUnitPrice ?? ""
                  } / user x ${
                    billingDetails.seats.additionalSeats.quantity ?? ""
                  } , ${new Date(
                    billingDetails.seats.additionalSeats.dateTimeGMT + "Z",
                  ).toLocaleDateString("en-IN", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                    timeZone: userTimezone,
                  })}`}
                </span>
              </div>
            )}

          {/* Last Payment Date */}
          {billingDetails?.seats?.lastPayment && (
            <div className="d-flex align-items-start align-items-md-center">
              <div>
                <img
                  src={creditCard}
                  alt=""
                />
              </div>

              <p className="mb-0 ms-1">
                <span className={`${styles.lastPara}`}>Last payment :</span>
                <span className={`ps-2 ${styles.lastPara}`}>
                  {billingDetails?.seats?.lastPayment.price},{" "}
                  {new Date(
                    billingDetails?.seats?.lastPayment.paymentDateGMT + "Z",
                  ).toLocaleDateString("en-IN", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                    timeZone: userTimezone,
                  })}
                </span>
              </p>
            </div>
          )}

          {/* Next Payment Date */}
          {billingDetails?.seats?.nextPayment && (
            <div className="d-flex align-items-start align-items-md-center">
              <div>
                <img
                  src={credit}
                  alt=""
                />
              </div>

              <p className="mb-0 ms-1">
                <span className={`${styles.lastPara}`}>Next payment :</span>
                <span className={`ps-2 ${styles.lastPara}`}>
                  {billingDetails?.seats?.nextPayment.price},{" "}
                  {new Date(
                    billingDetails?.seats?.nextPayment.paymentDateGMT + "Z",
                  ).toLocaleDateString("en-IN", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                    timeZone: userTimezone,
                  })}
                </span>
              </p>
            </div>
          )}
        </div>

        {/* Edit Billing Button */}
        <button
          className={`${styles.editBtn}`}
          onClick={handleEditBilling}
          disabled={loader}
        >
          Edit billing and payment
          {loader && (
            <Spinner
              className="ms-2"
              size="sm"
              animation="border"
            />
          )}
        </button>
      </div>
    </div>
  );
};

export default BillingPay;
