import { ReactNode, useState } from "react";
import styles from "./ConditionGroup.module.scss";

function ConditionGroup(props: {
  title: string;
  children: ReactNode;
  initialShow?: boolean;
  disableHide?: boolean;
  errorMessage?: string | null;
}) {
  const [showConditions, setShowConditions] = useState(
    props.initialShow ? true : false,
  );

  return (
    <>
      <div
        className={` p-3 ${props.errorMessage ? "mb-0" : "mb-2"} ${
          styles.shadowBox
        } ${props.errorMessage ? "border border-danger" : ""}`}
      >
        <div
          className={`d-flex justify-content-between align-items-center cursor-pointer`}
          onClick={() => {
            if (props?.disableHide === true) {
              return;
            }
            setShowConditions(!showConditions);
          }}
        >
          <span
            className={`${styles.toggleName}`}
            style={
              showConditions
                ? {
                    color: "#0B68BB",
                  }
                : {
                    color: "#000000",
                  }
            }
          >
            {props.title}
          </span>
          <span className={`${styles.shadowText}`}>
            {!props?.disableHide ? (
              showConditions ? (
                //     <i
                //       className={`fa-solid fa-chevron-up ${styles.activeDrop}
                // `}
                //     ></i>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9.793"
                  height="6.324"
                  viewBox="0 0 9.793 6.324"
                >
                  <path
                    id="Icon_awesome-angle-down"
                    data-name="Icon awesome-angle-down"
                    d="M4.379.216.216,4.379a.732.732,0,0,0,0,1.038l.692.692a.732.732,0,0,0,1.038,0L4.9,3.158,7.848,6.108a.732.732,0,0,0,1.038,0l.692-.692a.732.732,0,0,0,0-1.038L5.414.216A.728.728,0,0,0,4.379.216Z"
                    fill="#0b68bb"
                  />
                </svg>
              ) : (
                // <i className={`fa-solid fa-chevron-down `}></i>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9.793"
                  height="6.325"
                  viewBox="0 0 9.793 6.325"
                >
                  <path
                    id="Icon_awesome-angle-down"
                    data-name="Icon awesome-angle-down"
                    d="M4.379,6.109.216,1.945a.732.732,0,0,1,0-1.038L.908.216a.732.732,0,0,1,1.038,0L4.9,3.167,7.848.216a.732.732,0,0,1,1.038,0l.692.692a.732.732,0,0,1,0,1.038L5.414,6.109A.728.728,0,0,1,4.379,6.109Z"
                    fill="#707070"
                  />
                </svg>
              )
            ) : null}
          </span>
        </div>
        {showConditions ? props.children : null}
      </div>
      {props.errorMessage ? (
        <div className={`${styles.errorStyle}`}>{props.errorMessage}</div>
      ) : null}
    </>
  );
}

export default ConditionGroup;
