import { axiosJSON } from "src/globals/axiosEndPoints";

export interface CancelOrder {
  orderId: number;
}

export interface CancelOrderParams {
  orderId: number;
  reasonId: string; // id of the reason
  isNotifyCustomer: boolean;
}

export async function cancelOrder(params: CancelOrderParams) {
  const { data: res }: any = await axiosJSON.post(
    `/api/shopify/order/cancel`,
    params
  );

  if (res.errors) {
    throw new Error(res.errors.join(""));
  }

  if (res.error === true) {
    throw new Error(res.message as string);
  }

  return res.data as CancelOrder;
}
