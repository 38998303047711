/**
 * This file is the entry point of the entire app.
 * It contains the redux and react query providers.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 * @author Navjyot
 */

import "./initializeDate";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "./index.scss";
// import "bootstrap";
import App from "./App";
import { helpCenterPageQuery, mainQuery } from "./globals/queryClients";
import { store } from "src/store/store";
import { Provider } from "react-redux";
import { setLogConfig } from "src/utils/utils";
import log from "loglevel";
import "react-datepicker/dist/react-datepicker.css";
import { BrowserRouter } from "react-router-dom";
import { SET_TAB_FOCUSED } from "./globals/globalFunctions";
import { QueryClientProvider } from "@tanstack/react-query";
import KbPreview from "./routes/LiveChat/Children/ChatSetting/Children/KBPreview";

document.querySelector(`head title`)!.innerHTML =
  window.location.hostname.includes(process.env.REACT_APP_LOGO_HOST_NAME + "")
    ? "Saufter - Helpdesk"
    : "Helplama - Helpdesk";
const link = document.createElement("link");
link.rel = "icon";
link.href = window.location.hostname.includes(
  process.env.REACT_APP_LOGO_HOST_NAME + "",
)
  ? "/saufter.png"
  : "/favicon-helplama.ico";
document.querySelector("head")!.appendChild(link);

const appleTouchLink = document.createElement("link");
appleTouchLink.rel = "apple-touch-icon";
appleTouchLink.href = window.location.hostname.includes(
  process.env.REACT_APP_LOGO_HOST_NAME + "",
)
  ? "/saufter.png"
  : "/favicon-helplama.ico";
document.querySelector("head")!.appendChild(appleTouchLink);

// setLogConfig("production", log)
setLogConfig(process.env.REACT_APP_NODE_ENV, log);

/**
 * This event listner is required for tinymce.
 */
document.addEventListener("focusin", (e: any) => {
  SET_TAB_FOCUSED(true);
  if (
    e.target.closest(
      ".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root, .ignore-model-btn",
    ) !== null
  ) {
    e.stopImmediatePropagation();
  }
  document.querySelectorAll("input[id^=react-select]").forEach((ele) => {
    ele.setAttribute("data-lpignore", "true");
  });
});

window.addEventListener("blur", () => {
  SET_TAB_FOCUSED(false);
});

const container = document.querySelector("app");

const root = createRoot(container!);

/**
 * conditionally checked if the helpcenter page should be loaded or the dashboard.
 */
if (window.location.pathname.startsWith("/helpcenterpage")) {
  root.render(
    <StrictMode>
      <QueryClientProvider client={helpCenterPageQuery}>
        <BrowserRouter>
          <KbPreview />
        </BrowserRouter>
      </QueryClientProvider>
    </StrictMode>,
  );
} else {
  root.render(
    <StrictMode>
      <Provider store={store}>
        <QueryClientProvider client={mainQuery}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </QueryClientProvider>
      </Provider>
    </StrictMode>,
  );
}
