import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";
import { AutomationAPIPayload } from "./createAutomation.service";

export interface GetAllAutomationParams {
  start: number;
  limit: number;
}

export interface BrandData {
  id: number | string;
  name: string;
  email: string;
  imgURL?: string | null;
}

export interface IAutomationListItem {
  integrationId: string;
  configured: boolean;
  /** Currently linked brand - Added null to handle the case of brand deletion */
  brand: BrandData | null;
  published: boolean;
  automationName: string;
  lastEditedAtGmt?: string;
  /** Added for Automation ticket routing */
  automationDescription?: string;
  trigger?: string;
}

export interface GetAllAutomationsResponse {
  allAutomations: Record<string, IAutomationListItem>;
  allAutomationIds: string[];
  metaData: {
    /** Frontend Only */
    hasMore: boolean;
    currentCount: number;
  };
}

export const getAllAutomationsService = async ({
  type,
  payload,
}: AutomationAPIPayload<GetAllAutomationParams>): Promise<GetAllAutomationsResponse> => {
  const url = IS_TEST_ACTIVE
    ? `${process.env.REACT_APP_TEST_SITE_URL}/${type}/getAll`
    : `/api/automation/${type}/getAll`;

  const { data: res } = await axiosJSON.post(url, payload);

  if (res.error || res.err) {
    throw new Error(
      res.msg || res.message || "Could not fetch the Automations",
    );
  }

  const ret: GetAllAutomationsResponse = {
    allAutomationIds: [],
    allAutomations: {},
    metaData: { currentCount: 0, hasMore: false },
  };

  res.data.forEach((item: IAutomationListItem) => {
    const id = item.integrationId + "";
    ret.allAutomations[id] = item;
    ret.allAutomationIds.push(id);
  });

  if (ret.allAutomationIds.length < payload.limit) {
    ret.metaData.hasMore = false;
  } else {
    ret.metaData.hasMore = true;
  }

  ret.metaData.currentCount = payload.start + ret.allAutomationIds.length + 1;

  return ret;
};
