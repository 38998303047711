import { initialState, IUserSettings } from "./userSettings.slice";

const resetUserSettings = (state: IUserSettings) => {
  state = { ...initialState };
  return state;
};

const resetUserData = (state: IUserSettings) => {
  state.users = {};
  state.userIds = [];
  state.metaData = {
    count: 0,
    total: 0
  };
};

const setSearchValue = (
  state: IUserSettings,
  { payload }: { payload: { searchValue: string } }
) => {
  state.filters.searchValue = payload.searchValue;
};

export default { resetUserSettings, resetUserData, setSearchValue };
