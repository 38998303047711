import { axiosJSON } from "src/globals/axiosEndPoints";
import APIResponse from "src/services/Settings/Teams/APIReponse";

export interface AskForEmailWhenAgentOfflineData{
  messageText: string;
  confirmationText: string;
  ticketFormConfirmationText: string;
  askForEmail: boolean;
  autoReply: boolean;
  ticketFormId?: string|number;
};

export interface IUpdateAskForEmailDataParamV3{
  integrationId: number | string;
  whenAgentOffline?: {
    outsideBusinessHours?: AskForEmailWhenAgentOfflineData;
    withinBusinessHours?: AskForEmailWhenAgentOfflineData;
  }
  whenAgentNotResponding?: {
    canSendMessage?: boolean;
    sendMessageAfter?: number | string;
    messageText?: string;
    confirmationText?: string;
    canCustomerLeaveEmail?: boolean;
  },
  
}

interface updateAskForEmailResponse{
  status: boolean;
}

export async function updateAskForEmailDataV3(params: IUpdateAskForEmailDataParamV3) {
  
  const { data: res } = await axiosJSON.post<APIResponse<updateAskForEmailResponse>>(
    "/api/chatSetting/forms/updateAskForEmail",
    params
  );

  if (res.error === true) {
    throw new Error(res.message as string);
  }

  return res.data as updateAskForEmailResponse;
}
