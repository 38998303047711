import SearchBar from "src/components/SearchBar/SearchBar";
import styles from "./OrderInfoModal.module.scss";
import TableHeader from "./TableHeader/TableHeader";
import TableRow from "./TableRow/TableRow";
import Pagination from "src/routes/TicketList/children/Pagination/Pagination";
import { useEffect, useMemo, useRef, useState } from "react";
import useOrderInfoModal from "./useOrderInfoModal";
import { GetOrderModalInfoParams } from "src/services/ReturnAutoWorkFlow/StaticModals/getOrderModalInfo.service";
import Loader from "src/components/Loader";
import { useReturnAutoWorkFlow } from "src/features/ReturnAutoWorkFlow/hooks/useReturnAutoWorkFlow";
interface Props {
  onHide: () => void;
}
const OrderInfoModal = ({ onHide }: Props) => {
  // state to manage search text
  const [searchText, setSearchText] = useState<string>("");
  // state to manage sorting
  const [sortOrder, setSortOrder] = useState<Record<string, "asc" | "desc">>(
    {},
  );
  const [currentPage, setCurrentPage] = useState(1);
  const { returnAutoWorkFlow } = useReturnAutoWorkFlow();
  const limit = 8;
  /**
   * Starting index for API call
   */
  const start = useMemo(() => {
    return limit * currentPage - limit;
  }, [currentPage]);

  const params: GetOrderModalInfoParams = {
    integrationId: returnAutoWorkFlow?.integrationId ?? "",
    filters: { searchText, sortBy: sortOrder },
    start: start,
    limit: limit,
  };

  const { isError, isLoading, data } = useOrderInfoModal(params);

  /**
   * Total pages available
   */
  const totalPages = useMemo(() => {
    return Math.ceil(data?.totalOrders / limit);
  }, [data?.totalOrders]);
  /**
   * Updates the sorting order for a given table heading.
   *
   * @param {string} tableHeadingId - The ID of the table heading.
   * @param {"asc" | "desc"} order - The sorting order ("asc" or "desc").
   */
  const handleSort = (tableHeadingId: string, order: "asc" | "desc") => {
    setSortOrder((prevSortOrder) => {
      return {
        ...prevSortOrder,
        [tableHeadingId]: order,
      };
    });
  };

  return (
    <div>
      {/* Modal Header + Close Button */}
      <div className="d-flex align-items-center">
        <span
          className={`${styles.closeModal}`}
          onClick={onHide}
        >
          <i className="fa-solid fa-xmark"></i>
        </span>
        <h3 className={`ms-2 mb-0 ${styles.heading}`}>
          Order information received
        </h3>
      </div>
      <div className={`mt-3 ${styles.contentWrapper}`}>
        <h4 className={`${styles.heading}`}>
          Orders <span>({data?.allOrderIds?.length ?? 0})</span>
        </h4>
        <SearchBar
          className={`${styles.search} px-2`}
          inputClassName={`${styles.input}`}
          placeholder={`Search order id, customer name, and customer email...`}
          value={searchText}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setSearchText(e.target.value)
          }
          onSearch={(value: string) => setSearchText(value)}
        />
        {isLoading ? (
          <div className={`p-3 ${styles.settingWrap}`}>
            <Loader />
          </div>
        ) : isError ? (
          <div className={`p-3 ${styles.settingWrap}`}>
            <div className="d-flex align-items-center justify-content-center text-danger">
              {"Something went wrong!"}
            </div>
          </div>
        ) : (
          <div className="mt-4">
            <TableHeader
              tableHeadings={data?.tableHeadings ?? {}}
              tableHeadingIds={data?.tableHeadingIds ?? []}
              onSort={handleSort}
              sortOrder={sortOrder}
            />
            {data?.allOrderIds && data?.allOrderIds?.length > 0 ? (
              <div>
                {data?.allOrderIds.map((id) => {
                  const orderData = data?.allOrders[id];
                  return (
                    <TableRow
                      key={id}
                      orderData={orderData ?? null}
                      searchText={searchText}
                    />
                  );
                })}
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            ) : (
              <div className="d-flex align-items-center justify-content-center mt-5">
                No Orders Found
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderInfoModal;
