import { GetConfigModalParams } from "src/features/ReturnAutoWorkFlow/children/ConfigModals/oldReturnModals.types";
import { axiosJSON } from "src/globals/axiosEndPoints";
import { ReturnReasonOrderAndItemsTab } from "./getReturnReasonOrderAndItems.service";

interface Payload extends GetConfigModalParams {
  configTabs: ReturnReasonOrderAndItemsTab[];
}

async function updateReturnReasonOrderAndItemsService(payload: Payload) {
  const { data: res } = await axiosJSON.post(
    "/api/setting/automation/refund/step/12/configuration",
    payload,
  );

  if (res.err || res.error) {
    throw new Error(res.msg ?? res.message ?? "Some Error Occurred.");
  }
}

export default updateReturnReasonOrderAndItemsService;
