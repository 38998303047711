import { useMemo } from "react";
import { useReturnAutoWorkFlow } from "src/features/ReturnAutoWorkFlow/hooks/useReturnAutoWorkFlow";
import workflowProcess from "src/assets/images/workflowProcess.png";
import styles from "./MoveNextModal.module.scss";

function MoveNextModal({ onHide }: { onHide: () => void }) {
  const { returnAutoWorkFlow } = useReturnAutoWorkFlow();

  const canTestWorkFlow = useMemo(() => {
    if (
      returnAutoWorkFlow.activeStaticModalStepId &&
      returnAutoWorkFlow.configuredSteps &&
      returnAutoWorkFlow.configuredSteps[
        returnAutoWorkFlow.activeStaticModalStepId
      ] &&
      returnAutoWorkFlow.configuredSteps[
        returnAutoWorkFlow.activeStaticModalStepId
      ].canTestWorkFlowAfterThisStepCompleted
    ) {
      return true;
    } else {
      return false;
    }
  }, [returnAutoWorkFlow]);

  return (
    <div className={`mx-3 mt-3 ${styles.secondSection}`}>
      <div className="m-auto  text-center">
        <div className="my-2">
          <img
            src={workflowProcess}
            alt=""
            className={`${styles.logo}`}
          />
        </div>
        <div className="my-1 mt-4">
          <span className={`${styles.NoReturnText} text-center`}>
            You've moved to next step!
          </span>
        </div>
        <div className="my-2">
          <span className={`${styles.enableText} text-center mx-3`}>
            You have successfully moved to next step. You can now test the flow
            that you have configured uptill now.
          </span>
        </div>
        <div className="my-4 d-flex justify-content-center flex-wrap">
          {canTestWorkFlow && (
            <div>
              <span
                className={`px-2 cursor-pointer mb-2 mb-md-0 ${styles.btnTest}`}
              >
                Test Workflow
              </span>
            </div>
          )}
          <div>
            <span
              className={`cursor-pointer ${styles.configBtn}`}
              onClick={onHide}
            >
              Configure next steps
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MoveNextModal;
