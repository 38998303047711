import { axiosJSON } from "src/globals/axiosEndPoints";
import APIResponse from "./APIReponse";


interface IRoutingRuleConditionList{
  location: Array<RoutingRuleCondition>;
  url: Array<RoutingRuleCondition>;
  domain: Array<RoutingRuleCondition>;
  [key: string]: Array<RoutingRuleCondition>;
}

interface RoutingRuleCondition{
  conditionId: string| number;
  conditionValue: string;
  conditionConstraint: string;
  isNewlyAdded: boolean;
}

export interface updateRoutingRuleParams {
  ruleId?: string;
  ruleName?: string;
  ruleAction?: string;
  teamId?: number|string;
  conditions?: IRoutingRuleConditionList;
  isGeneralTeamUpdate? : boolean;
  integrationId? : number|string; 
}


export async function updateRoutingRules(params: updateRoutingRuleParams) {
  const { data } = await axiosJSON.post(
    `/api/chatSetting/routingRule/update`,
    params
  );
  if (data.error === true) {
    throw new Error(data.message as string);
  }
  return data.data;
}