import styles from "./PreDraftModal.module.scss";
interface Props {onHide : (e:any) => void};
const PreDraftModal = ({onHide} : Props) => {
  return (
    <div>
      <div className={`d-flex justify-content-between`}>
        <h5 className={`modal-title ${styles.preDraft_Title}`}>
          <span className={`me-2 ${styles.preDraftIcon}`}>
            <i className="fa-solid fa-note-sticky"></i>
          </span>
          Pre-draft-Details :
        </h5>
        <span className={`${styles.close}`} onClick={onHide}>
          <i className="fa-solid fa-xmark"></i>
        </span>
      </div>
      <div className="">
      <p className={`${styles.resAllDraft}`}> 
      <span className={`me-1`}><i className="fas fa-user"></i></span>
      <span>sk , 24/1/2022, 11.24pm</span>
      </p>
        <span className={`${styles.messageHead}`}>Order details:</span>
        <br />
        <span className={`${styles.subSpan}`}> order no:1556456</span>
        <br />
        <span className={`${styles.subSpan}`}>Tracking ID: https://..</span>
        <br />
        <span className={`${styles.subSpan}`}>Shipment Id: https://..</span>
        <br />
        <span className={`${styles.subSpan}`}>Shipment status: Delivered</span>
        <br />
        <span className={`${styles.subSpan}`}>Return Label: Delivered</span>
        <br />

        <span className={`${styles.messageHead} mt-1`}>E-mail details:</span>
        <br />
        <span className={`${styles.subSpan}`}>Sentiment:Normal</span>
        <br />
        <span className={`${styles.subSpan}`}>Other tickets: 2</span>
        <br />

        <span className={`${styles.messageHead} mt-1`}>Customer details:</span>
        <br />
        <span className={`${styles.subSpan}`}>Name:Jhon Doe</span>
        <br />
        <span className={`${styles.subSpan}`}>E-mail : jhondoe@gmail.com</span>
        <br />
      </div>
    </div>
  );
}
export default PreDraftModal;
