import { axiosJSON } from "src/globals/axiosEndPoints";

export interface IUpdateTracking {
  orderId: number;
  fulfillmentId: string;
  status: boolean;
}

export interface IUpdateTrackingParams {
  orderId: number;
  fulfillmentId: string;
  notifyCustomer: boolean;
  trackingNumber: string;
  shippingCarrier: string;
  trackingUrl?: string;
}

export async function updateTracking(
  params: IUpdateTrackingParams,
  onSuccessCallBack: Function
) {
  const { data: res } = await axiosJSON.post(
    `/api/shopify/order/fulfillment/updateTracking`,
    params
  );

  if (res.error === true) {
    throw new Error(res.message as string);
  }
  if (res.error === false) {
    setTimeout(() => {
      onSuccessCallBack();
    }, 0);
  }

  return res.data as IUpdateTracking;
}
