import React from "react";
import { useAppSelector } from "src/store/store";
import styles from "./UnAssignModal.module.scss";
const UnassignModal = (props: { onHide: Function }) => {
  const handleClick = () => {
    props.onHide();
  }

  const handleCancel = () => {
    props.onHide();
  }
  const activeCustomerInfo = useAppSelector((state) => state.chatWidExhMsg.activeCustomerInfo);
  const assignedListLength = activeCustomerInfo?.chatAssignedTo?.length ?? 0;
  const AssignedNames = activeCustomerInfo?.chatAssignedTo?.map((item: any) => item.name).join(", ");
  const lastPersonAssignedName = activeCustomerInfo?.chatAssignedTo?.[0]?.name;


  const names = () => {
    if (assignedListLength === 1) {
      return lastPersonAssignedName;
    }
    else if (assignedListLength > 2) {
      return `${lastPersonAssignedName} and ${assignedListLength - 1} more`;
    }
    else {
      return AssignedNames;
    }
  }

  return (
    <div className={`text-center ${styles.unassignWrapper}`}>
      <span className={`${styles.cautionImg}`}>
        <i className="fa-solid fa-triangle-exclamation"></i>
      </span>
      <h3 className={`${styles.modalHead}`}>Cannot unassign agent(s)</h3>
      <span className={`${styles.modalSub}`}>
        You cannot unassign {names()} from this chat, because after {names()} {assignedListLength > 1 ? "are" : "is"} unassigned,
        there will be no one left assigned to this chat. Please assign this chat
        to another agent to unassign this chat from above agent(s)
      </span>
      <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mt-4">
        <button className={`mb-2 ${styles.cancelBtn}`} onClick={handleCancel}>Cancel</button>
        <button className={`mb-2 ${styles.submitBtn}`} onClick={handleClick}>Assign some one else</button>
      </div>
    </div>
  );
};

export default UnassignModal;
