import { Modal, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import FinalChat from "../../../../../Customization/Children/FinalChat/FinalChat";
import styles from "./ArticleFeatures.module.scss";
import caution from "src/assets/images/caution.png";
import sample_featured_articles from "src/assets/images/sample_featured_articles.png";
import AddFeaturesModal from "./Children/AddFeaturesModal/AddFeaturesModal";
import ArticleURL from "./Children/ArticleURL/ArticleURL";
import Loader from "src/components/Loader";
import hii from "src/assets/images/hifi.gif";
import toggle from "src/assets/images/chat/blackToggle.png";

import InfiniteScroll from "src/components/InfiniteScroll";
import useArticleFeatures from "./useArticleFeatures";
import list from "src/assets/images/list.png";
import { useAppSelector } from "src/store/store";
import { useNavigate } from "react-router-dom";
const ArticleFeatures = () => {
  const navigate = useNavigate();
  const {
    showModal,
    showEnabledLoader,
    setEnabledLoader,
    featuredArticles,
    featuredArticlesIds,
    handleInfiniteScroll,
    hasMoreFeaturedArticles,
    initialFetchLoading,
    handleEnableMinimizedWindow,
    minimizedViewEnabled,
    handleSelectedFeaturedArticle,
    minimizedFeaturedArticles,
    suggestedArticles,
    onShow,
    onHide,
    knowledgeBasedData,
    activeFeaturedArticle, //Added to show active article style
  } = useArticleFeatures();

  const { customizationData } = useAppSelector(
    (state) => state.customizationSettings,
  );

  return (
    <div className={`${styles.featuresWrapper}`}>
      {/* Left Section */}
      <div className={`p-3 pb-0 ${styles.leftSection}`}>
        <div className="d-flex">
          <div>
            <div
              className={`d-flex justify-content-center align-items-center ${styles.backChat}`}
              onClick={() => navigate(-1)}
            >
              <i className="fa-solid fa-arrow-left"></i>
            </div>
          </div>
          <div className="">
            <h2 className={`${styles.heading} saufter_h3`}>
              Featured articles
            </h2>
            <p className={`saufter_subHeading`}>
              Let customers style the help center right inside your chat widget
              instead of starting a conversation.
            </p>
          </div>
        </div>
        <div className="">
          {initialFetchLoading === true ? (
            <Loader />
          ) : (
            <>
              {featuredArticlesIds.length !== 0 ? (
                <>
                  <div className="mt-3 d-flex justify-content-between align-items-center">
                    <h4 className={`${styles.fearureHead}`}>
                      Featured Articles
                    </h4>
                    <span
                      className={`cursor-pointer ${styles.addMore}`}
                      onClick={onShow}
                    >
                      + Add more URLS
                    </span>
                  </div>
                  {/* To show interaction ui below section is hide  */}
                  <InfiniteScroll
                    className={`${styles.infScroll} px-1 py-2`}
                    loadMore={handleInfiniteScroll}
                    initialDataLoaded={true}
                    hasMore={hasMoreFeaturedArticles()}
                  >
                    {featuredArticlesIds.map((featuredArticleId) => {
                      const featuredArticle =
                        featuredArticles[featuredArticleId];

                      return (
                        <ArticleURL
                          key={featuredArticleId}
                          featuredArticle={featuredArticle}
                          showEditModal={onShow}
                          selectActiveFeaturedArticle={
                            handleSelectedFeaturedArticle
                          }
                          activeFeaturedArticle={activeFeaturedArticle} //Added to show active article style
                        />
                      );
                      // <SpecificUrl />
                      //   <div>
                      //   <ArticleList />
                      // </div>
                    })}
                  </InfiniteScroll>
                </>
              ) : (
                <div className={`text-center  ${styles.addfeatureBox}`}>
                  <span className={`${styles.newsPaper}`}>
                    <img
                      src={list}
                      alt=""
                      className={`${styles.listImg}`}
                    />
                  </span>
                  <p className={`mb-0 ${styles.featureHead}`}>
                    No featured articles to show
                  </p>
                  <span className={`d-inline-block ${styles.featureSub}`}>
                    Add featured articles that will appear in your live chat
                    widget as shown in the preview in the right
                  </span>
                  <button
                    className={`${styles.addBtn}`}
                    onClick={onShow}
                  >
                    Add featured articles
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {/* Right Section  */}
      <div className={`pt-2 ps-4 pe-3 ${styles.rightSection}`}>
        {initialFetchLoading === true ? (
          <Loader />
        ) : (
          <>
            <div className="d-flex align-items-center">
              <div className="form-check d-block d-md-flex form-switch">
                <div>
                  <input
                    className="form-check-input me-1"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckChecked"
                    checked={minimizedViewEnabled}
                    onChange={(e) =>
                      handleEnableMinimizedWindow(e, setEnabledLoader)
                    }
                  />
                  {showEnabledLoader === true ? (
                    <Spinner
                      animation="border"
                      variant="secondary"
                      className={`d-block mx-1`}
                      style={{ height: "20px", width: "20px" }}
                    />
                  ) : null}
                </div>
                <div>
                  {/* Conditionally show text when enabled or disabled as xd */}
                  {minimizedViewEnabled ? (
                    <label
                      className={`ms-1 form-check-label ${styles.checkLabel}`}
                      htmlFor="flexSwitchCheckChecked"
                    >
                      Disable Featured articles in minimised view
                    </label>
                  ) : (
                    <label
                      className={`ms-1 form-check-label ${styles.checkLabel}`}
                      htmlFor="flexSwitchCheckChecked"
                    >
                      Enable Featured articles in minimised view
                    </label>
                  )}
                </div>
              </div>
              <div className="ms-2">
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip className={`text-start`}>
                      <div className="text-start p-2">
                        <span className="d-inline-block">
                          If enabled the feature articles will appear on the
                          pages on your website even when the widget is
                          minimized. See then below{" "}
                        </span>
                        <img
                          src={sample_featured_articles}
                          alt=""
                          height="80px"
                          width="100px"
                          className="pt-1"
                        />
                      </div>
                    </Tooltip>
                  }
                >
                  {({ ref, ...triggerHandler }) => (
                    <img
                      {...triggerHandler}
                      ref={ref}
                      src={caution}
                      alt="close"
                      className={`cursor-pointer ${styles.cautionImg}`}
                    />
                  )}
                </OverlayTrigger>
              </div>
              <Modal
                show={showModal}
                onHide={onHide}
                dialogClassName={`${styles.modalDialog}`}
                contentClassName={`${styles.modalContent}`}
                backdropClassName={`${styles.backdropClass}`}
              >
                <AddFeaturesModal onHide={onHide} />
              </Modal>
            </div>
            <div className={`${styles.widgetwrapper}`}>
              {/* showing data from customization in this section */}
              <FinalChat
                customizationData={{
                  topbarHeadingText:
                    customizationData?.topbarHeading ?? "Hi There !",
                  topbarSubHeadingText:
                    customizationData?.topbarSubHeading ??
                    "Welcome to Acme support. How can we help you?",
                  statusTextWhenTeamOnline: {
                    readonly: "we generally reply in",
                    value: "few minutes",
                  },
                  statusTextWhenTeamOffline: {
                    readonly: "We'll be back at",
                    value: "",
                  },
                  autoreplayText: customizationData?.autoreplayText ?? "",
                  minimizedWindowColour:
                    customizationData?.appearances?.appearanceWindowColor
                      ?.value,
                  liveChatThemeColor:
                    customizationData?.appearances?.liveChatThemeColor?.value,
                  showAutoReplyText: false,
                  selectedWidgetPosition:
                    customizationData?.appearances?.widgetPosition != null
                      ? customizationData?.appearances.widgetPosition == "right"
                        ? "right"
                        : "left"
                      : "right",
                  submitButtonText: "Start a chat",
                  showOnlineStatusText: true,
                }}
                knowledgeBasedData={{
                  chatWidgetTitle: knowledgeBasedData.chatWidgetTitle,
                  chatWidgetPlaceholder:
                    knowledgeBasedData.chatWidgetPlaceholder,
                }}
                featuredArticles={minimizedFeaturedArticles}
                suggestedArticles={suggestedArticles}
                show={{
                  homePage: true,
                  askForEmail: undefined,
                  chatForm: undefined,
                  minimizedFeaturedArticles: minimizedViewEnabled,
                  showSuggestedArticles: true,
                }}
                showStartChat={false}
              />
              {/* for widget with article  */}
              <div className={`d-none ${styles.widgetWrapper}`}>
                <div className="">
                  <div className={`p-2 mb-2 ms-auto ${styles.questBox}`}>
                    <img
                      src={hii}
                      alt=""
                      className={`${styles.hifi}`}
                    />
                    <span>Have Questions?</span>
                  </div>
                  <div className={`p-2 w-100 my-1 ${styles.questions}`}>
                    <span
                      className={`text-truncate d-inline-block ${styles.queText}`}
                    >
                      How do I initiate an exchange...
                    </span>
                    <span>
                      <i className="fa-solid fa-arrow-right"></i>
                    </span>
                  </div>
                  <div className={`p-2 w-100 my-1 ${styles.questions}`}>
                    <span
                      className={`text-truncate d-inline-block ${styles.queText}`}
                    >
                      Status of my current order ?
                    </span>
                    <span>
                      <i className="fa-solid fa-arrow-right"></i>
                    </span>
                  </div>

                  <div className={`p-2 w-100 my-1 ${styles.questions}`}>
                    <span
                      className={`text-truncate d-inline-block ${styles.queText}`}
                    >
                      How do I initiate an exchange...
                    </span>
                    <span>
                      <i className="fa-solid fa-arrow-right"></i>
                    </span>

                    <div
                      className={`p-2 my-1 ms-2 ${styles.questions} ${styles.exhchangeBox}`}
                    >
                      <span
                        className={`text-truncate d-inline-block ${styles.queText}`}
                      >
                        How do I initiate an exchange...
                      </span>
                      <span>
                        <i className="fa-solid fa-arrow-right"></i>
                      </span>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end mt-3">
                    <img
                      src={toggle}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ArticleFeatures;
