import { useCallback, useMemo, useState } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { useReturnAutoWorkFlow } from "src/features/ReturnAutoWorkFlow/hooks/useReturnAutoWorkFlow";
import {
  SavedStepAndQuestions,
  StepData,
  TransferToAgentType,
} from "src/features/ReturnAutoWorkFlow/ReturnAutoWorkFlow.types";
import TransferToAgentModel from "src/routes/Setting/children/MainSetting/children/SetRulesNew/childerns/childern/AddAutomations/StepsConfigured/Childern/TransferToAgent/TransferToAgentModel";
import saveStepTrensferToAgentService from "src/services/ReturnAutoWorkFlow/saveStepTrensferToAgent.service";
import GoLiveButton from "../../../BaseSteps/children/GoLiveStep/children/GoLiveButton/GoLiveButton";
import styles from "./ConfiguredActiveStep.module.scss";

const Step = ({
  stepData,
  integrationId,
  isStepActive,
  stepNumber,
  updateData,
}: {
  stepData: StepData;
  integrationId: string;
  isStepActive: boolean;
  stepNumber: number;
  updateData: (
    stepId: string,
    transferToAgent: Partial<TransferToAgentType>,
  ) => void;
}) => {
  /**
   * Need to show red cross icon if transfer to agent is not configured after enabling.
   */
  const transferToAgentHaveErrors = useMemo(() => {
    if (stepData.transferToAgent?.enabled) {
      if (stepData.transferToAgent.confirmationMessage?.trim()) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }, [stepData]);

  const [showTransferModal, setShowTransferModal] = useState<boolean>(false);

  const onSubmitTransfer = useCallback(
    async (confirmationMessage: string) => {
      try {
        await saveStepTrensferToAgentService({
          integrationId,
          stepId: stepData.stepId,
          transferToAgent: { confirmationMessage, enabled: true },
        });
        updateData(stepData.stepId, { confirmationMessage, enabled: true });
        setShowTransferModal(false);
      } catch (err) {
        pushTheToast({
          position: "top-right",
          text: (err as Error)?.message ?? "Could not tranfer to agent",
          type: "danger",
        });
      }
    },
    [integrationId, stepData.stepId, updateData],
  );

  const onChangeTransferAgent = useCallback(
    async (checked: boolean) => {
      try {
        await saveStepTrensferToAgentService({
          integrationId,
          stepId: stepData.stepId,
          transferToAgent: { enabled: checked },
        });
        updateData(stepData.stepId, { enabled: checked });
        setShowTransferModal(false);
      } catch (err) {
        pushTheToast({
          position: "top-right",
          text: (err as Error)?.message ?? "Could not update tranfer to agent",
          type: "danger",
        });
      }
    },
    [integrationId, stepData.stepId, updateData],
  );

  return (
    <div className={`${styles.steps} mb-2`}>
      <div
        className={`d-flex py-2 px-2 ${
          stepData.transferToAgent?.enabled ? "cursor-pointer" : ""
        }`}
        onClick={() => {
          if (stepData.transferToAgent?.enabled) {
            setShowTransferModal(true);
          }
        }}
      >
        <div
          className={`col-md-2 d-flex align-items-center justify-content-center`}
        >
          <div
            className={`${
              transferToAgentHaveErrors
                ? styles.numberRed
                : isStepActive || stepData.stepStatus === "completed"
                  ? styles.numberBlue
                  : styles.number
            } m-auto d-flex justify-content-center align-items-center`}
          >
            {transferToAgentHaveErrors === true ? (
              <i className="fa-solid fa-x"></i>
            ) : stepData.stepStatus === "completed" ? (
              <i className="fa-solid fa-check"></i>
            ) : (
              `${stepNumber}`
            )}
          </div>
        </div>
        <div className={`col-md-10 ps-2 ps-lg-0`}>
          <span
            className={`${styles.stepHeading} mb-0 ${
              isStepActive || stepData.stepStatus === "completed"
                ? styles.doneSegment
                : ""
            }`}
          >
            {stepData.stepTitle}
          </span>

          {stepData.transferToAgent && (
            <div className="d-flex">
              <div className={`col-md-9 ${styles.stepSubHeading}`}>
                Transfer to an agent after this step
              </div>
              <div className="form-check form-switch col-md-5 ms-0">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className={` ${styles.toolTipCustom}`}>
                      {stepData.transferToAgent?.enabled
                        ? "Enabled"
                        : "Disabled"}
                    </Tooltip>
                  }
                  trigger="hover"
                >
                  <input
                    className={`form-check-input cursor-pointer ${styles.switchBox}`}
                    type="checkbox"
                    role="switch"
                    id={`step_transfer_${stepData.stepId}`}
                    checked={stepData.transferToAgent?.enabled}
                    onChange={(e) => {
                      e.stopPropagation();
                      onChangeTransferAgent(e.target.checked);
                    }}
                  />
                </OverlayTrigger>
              </div>
              <Modal
                className={`shadow-sm rounded`}
                show={showTransferModal}
                onHide={() => setShowTransferModal(false)}
                dialogClassName={`${styles.modalDialog2}`}
                contentClassName={`${styles.modalContent2}`}
                backdropClassName={`${styles.modalBackDrop}`}
                centered={true}
              >
                <TransferToAgentModel
                  onCancel={() => setShowTransferModal(false)}
                  onSubmit={onSubmitTransfer}
                  confirmationMsg={stepData.transferToAgent.confirmationMessage}
                />
              </Modal>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const LiveStep = ({ stepNumber }: { stepNumber: number }) => {
  const { returnAutoWorkFlow } = useReturnAutoWorkFlow();

  return (
    <div className={`${styles.steps}`}>
      <div className={`d-flex py-2 px-2`}>
        <div className={`col-md-2`}>
          <div
            className={`${styles.number} ${
              returnAutoWorkFlow.published === true ? styles.numberBlue : ""
            } m-auto mt-2 d-flex justify-content-center align-items-center`}
          >
            {returnAutoWorkFlow.published === true ? (
              <i className="fa-solid fa-check"></i>
            ) : (
              `${stepNumber}`
            )}
          </div>
        </div>
        <div className={`col-md-10 ps-2 ps-lg-0`}>
          <span
            className={`${
              returnAutoWorkFlow.published
                ? styles.doneSegment
                : styles.stepHeading
            }`}
          >
            {returnAutoWorkFlow.published
              ? "You're Live"
              : "You're ready to go! Go Live"}
          </span>
          <div className={`col-md-9 ${styles.stepSubHeading}`}>
            {`Returns automation flow ${
              returnAutoWorkFlow.published ? "is" : "will be"
            } live for ${returnAutoWorkFlow.brand?.name ?? "NA"}`}
          </div>
          <GoLiveButton useModal={false} />
        </div>
      </div>
    </div>
  );
};

const ConfiguredActiveStep = () => {
  const { returnAutoWorkFlow, dispatch } = useReturnAutoWorkFlow();

  const stepIsActive = useCallback(
    (stepId: string) => {
      return returnAutoWorkFlow.activeStepId &&
        returnAutoWorkFlow.activeStepId + "" === stepId + ""
        ? true
        : false;
    },
    [returnAutoWorkFlow],
  );

  const updateData = useCallback(
    (stepId: string, transferToAgent: Partial<TransferToAgentType>) => {
      dispatch("updateTransferToAgent", { stepId, value: transferToAgent });
    },
    [dispatch],
  );

  const { allSteps, transFerToAgentAvailable } = useMemo(() => {
    const steps: {
      savedStepData: SavedStepAndQuestions;
      stepData: StepData;
    }[] = [];

    if (returnAutoWorkFlow.savedStepAndQuestionIds) {
      returnAutoWorkFlow.savedStepAndQuestionIds.forEach((val) => {
        const data = returnAutoWorkFlow.configuredSteps?.[val.stepId];

        if (data?.showInStepsConfigured) {
          steps.push({
            savedStepData: val,
            stepData: data,
          });
        }
      });
    }

    let transFerToAgentAvailable = false;

    for (const stepId in returnAutoWorkFlow.configuredSteps) {
      const step = returnAutoWorkFlow.configuredSteps[stepId];
      if (step.transferToAgent) {
        transFerToAgentAvailable = true;
        break;
      }
    }

    return { allSteps: steps, transFerToAgentAvailable };
  }, [
    returnAutoWorkFlow.configuredSteps,
    returnAutoWorkFlow.savedStepAndQuestionIds,
  ]);

  return (
    <div>
      {transFerToAgentAvailable && (
        <div>
          <p className={`mt-3 ${styles.subHeading}`}>
            Note: You can transfer any complex scenario to the agent to handle
            complex portions of steps.
          </p>
        </div>
      )}

      <div className={`mt-3`}>
        {allSteps.map(({ savedStepData, stepData }, idx) => {
          return (
            <Step
              stepData={stepData}
              key={stepData.stepId}
              isStepActive={stepIsActive(stepData.stepId)}
              stepNumber={idx + 1}
              updateData={updateData}
              integrationId={returnAutoWorkFlow.integrationId ?? ""}
            />
          );
        })}

        {returnAutoWorkFlow.configured ? (
          <LiveStep stepNumber={allSteps.length + 1} />
        ) : null}
      </div>
    </div>
  );
};

export default ConfiguredActiveStep;
