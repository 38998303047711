import { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import styles from "./ToggleSidebar.module.scss";

const ToggleContent = ({
  showSettings,
  handleToggle,
}: {
  showSettings: boolean;
  handleToggle: () => void;
}) => {
  return (
    <div
      className={`${styles.subSideBarCloseBtnDiv}`}
      onClick={handleToggle}
    >
      <OverlayTrigger
        trigger={["hover", "focus"]}
        placement="top"
        overlay={
          <Tooltip className={`${styles.toolTipCustom}`}>
            <span className={`${styles.tooltipHead}`}>
              {showSettings ? "Hide sidebar" : "Show sidebar"}
            </span>
          </Tooltip>
        }
      >
        <span className={`${styles.subSideBarCloseBtnToggleArrow} m-auto`}>
          {showSettings ? (
            <i className="fa-solid fa-chevron-left"></i>
          ) : (
            <i className="fa-solid fa-chevron-right"></i>
          )}
        </span>
      </OverlayTrigger>
    </div>
  );
};

const ToggleSidebar = ({ children }: { children: React.ReactNode }) => {
  const [showSettingsSubSB, setShowSettingsSubSB] = useState<boolean>(true);

  const handleToggleSubSideBar = () => {
    setShowSettingsSubSB((prevState) => !prevState);
  };

  return (
    <>
      <div
        className={`${styles.subSideToggle}`}
        id="customerSidebarWrapper"
      >
        <div
          className={`${showSettingsSubSB ? styles.subsideToggleInner : ""}`}
        >
          <div className={`${showSettingsSubSB ? "" : "d-none"}`}>
            {children}
          </div>
          <ToggleContent
            handleToggle={handleToggleSubSideBar}
            showSettings={showSettingsSubSB}
          />
        </div>
      </div>
    </>
  );
};

export default ToggleSidebar;
