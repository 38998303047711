import { axiosJSON } from "src/globals/axiosEndPoints";
import { SegmentFilterOption } from "./getSegmentFilters.service";

export interface FiltersDDParams {
  segmentType: string;
  segmentId: string;
  filterKey: string;
  start: number;
  limit: number;
}

/**
 * Retrieves the segment filters option for a given segment filter type.
 *
 * @param payload.segmentType The type of customer (company, people, reviews, accounts).
 * @param payload.segmentId The segment id of the active segment.
 * @param payload.filterKey The filter type of the active segment filter.
 *
 * @returns A promise that resolves to the segment filters response.
 * @throws If an error occurs during the API request or if the response contains an error message.
 */
const getSegmentFiltersDDService = async ({
  segmentType,
  ...payload
}: FiltersDDParams): Promise<SegmentFilterOption[]> => {
  const { data: res } = await axiosJSON.post(
    `/api/segment/${segmentType}/getFiltersDD`,
    payload,
  );

  if (res.error || res.err) {
    throw new Error(res.msg ?? res.message ?? "Something Unexpected Occured!");
  }

  const ret: SegmentFilterOption[] = res.data;

  for (let i = 0; i < ret.length; i++) {
    ret[i].key = ret[i].key + "";
  }

  return ret;
};

export default getSegmentFiltersDDService;
