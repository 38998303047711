/**
 * This file is the react component file.
 * It contains the "ReportInternalFilter" component for managing filters in reports.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 * @author Navjyot
 */

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState, useEffect } from "react";
import SearchBar from "src/components/SearchBar/SearchBar";
import styles from "./ReportInternalFilter.module.scss";
import { Dropdown } from "react-bootstrap";
import Brands from "./Brands/Brands";
import AssignedTo from "./AssignedTo/AssignedTo";
import TicketTags from "./TicketTags/TicketTags";
import DraftStatus from "./DraftStatus/DraftStatus";
import DraftedBy from "./DraftedBy/DraftedBy";
import TicketStatus from "./TicketStatus/TicketStatus";
import {
  reportInternalFiltersActions,
  useReportInternalFilters,
} from "../../hooks/reportFilters/useReportInternalFilters";
import { CurrentStatusEndPoints } from "src/services/report/reportTicketList.service";
import AuthoredBy from "./AuthoredBy/AuthoredBy";
import DateAdded from "./DateAdded/DateAdded";
import Ratings from "./Ratings/Ratings";
import ArticleCategory from "./ArticleCateogry/ArticleCategory";
import ChatStatus from "./ChatStatus/ChatStatus";
import Agent from "./Agent/Agent";
import {
  reportCurrentStatusActions,
  useReportFilters,
} from "../../hooks/reportFilters/useReportFilters";
import { useAppSelector } from "src/store/store";
import ChatRatings from "./ChatRatings/ChatRatings";
import BotArticleCategory from "./BotArticleCateogry/BotArticleCategory";
import ArticleRatings from "./ArticleRatings/ArticleRatings";

// Interface for the ReportInternalFilterProps
interface ReportInternalFilterProps {
  endpoint?: CurrentStatusEndPoints;
}

// ReportInternalFilter component definition
const ReportInternalFilter = ({ endpoint }: ReportInternalFilterProps) => {
  // State for dropdown visibility and search input value
  const [ddOpen, setDdOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  // Custom hook for managing internal filters state
  const { dispatch, appliedFiltersLength } = useReportInternalFilters();

  const { dispatch: dispatchRF } = useReportFilters();

  // Clear all filters function
  const clearAllFilters = useCallback(() => {
    if (appliedFiltersLength) {
      dispatch([reportInternalFiltersActions.resetState, undefined]);
      dispatchRF([reportCurrentStatusActions.resetListViewPage, endpoint]);
      setDdOpen(false);
    }
  }, [appliedFiltersLength]);

  // Cancel filters function
  const cancelFilters = useCallback(() => {
    dispatch([reportInternalFiltersActions.discardFilters, undefined]);
    setDdOpen(false);
  }, []);

  // Apply filters function
  const applyFilters = useCallback(() => {
    dispatch([reportInternalFiltersActions.applyFilters, undefined]);
    dispatchRF([reportCurrentStatusActions.resetListViewPage, endpoint]);
    setDdOpen(false);
  }, []);

  // Toggle dropdown visibility
  const onToggle = useCallback((show: boolean) => {
    setDdOpen(show);
    if (!show) {
      cancelFilters();
    }
  }, []);

  // Handle search input change
  const searchChange = useCallback((e: any) => {
    setSearchValue(e.target.value);
  }, []);

  // Fetching disabledFeatures from the Redux store
  const disabledFeatures = useAppSelector(
    (state) => state.globals.currentUserData?.disabledFeatures,
  );

  // Render the component UI
  return (
    <Dropdown
      show={ddOpen}
      onToggle={onToggle}
      autoClose="outside"
    >
      <Dropdown.Toggle
        as="div"
        bsPrefix={`dropdown-toggle cursor-pointer ${styles.filterButton} ${
          appliedFiltersLength ? styles.selected : ""
        } d-flex justify-content-between ${
          disabledFeatures &&
          disabledFeatures?.includes("reports") &&
          styles.disabled
        }`}
      >
        <div className={`${styles.leftSection} me-2`}>
          <div
            className="d-flex position-relative"
            style={{ flexShrink: "0" }}
          >
            <span
              className={`position-relative d-flex me-2 text-truncate ${styles.chatFilter}`}
            >
              Filters
              {appliedFiltersLength ? (
                <span className={`ms-2 ${styles.filterCount}`}>
                  {appliedFiltersLength}
                </span>
              ) : (
                ""
              )}
            </span>
          </div>
        </div>
        <span>
          {ddOpen ? (
            <i className={`fa-solid fa-caret-up`}></i>
          ) : (
            <i className={`fa-solid fa-caret-down`}></i>
          )}
        </span>
      </Dropdown.Toggle>

      <Dropdown.Menu bsPrefix={`dropdown-menu border-0 ${styles.filterMenu}`}>
        <div>
          <div className="px-2 pb-2">
            <span
              className={`${styles.clearSpan}`}
              onClick={clearAllFilters}
            >
              Clear all filters
            </span>
          </div>
          <div className="px-2 pb-2">
            <SearchBar
              className={`${styles.search} px-2`}
              inputClassName={`${styles.input}`}
              placeholder={`Search`}
              value={searchValue}
              onChange={searchChange}
            />
          </div>
          <div
            className={`${styles.filterWrap}`}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {"brands".includes(searchValue.trim().toLowerCase()) &&
              endpoint?.startsWith("tickets") && <Brands />}
            {"assigned to".includes(searchValue.trim().toLowerCase()) &&
              endpoint?.startsWith("tickets") && <AssignedTo />}
            {"ticket tags".includes(searchValue.trim().toLowerCase()) &&
              endpoint?.startsWith("tickets") && <TicketTags />}
            {"draft status".includes(searchValue.trim().toLowerCase()) &&
              endpoint?.startsWith("tickets") &&
              // Added this as response sent by time internal filter not include draft status filter
              !endpoint?.includes("tickets/volumeActivity/responseSent") && <DraftStatus />}
            {/* 29 Apr 2024 Hiding draft by internal filter as it is not implemented on backend */}
            {/* {"drafted by".includes(searchValue.trim().toLowerCase()) &&
              endpoint?.startsWith("tickets") && 
              // Added this as response sent by time internal filter not include drafted by filter
              !endpoint?.includes("tickets/volumeActivity/responseSent") && <DraftedBy />} */}
            {"ticket status".includes(searchValue.trim().toLowerCase()) &&
              endpoint?.startsWith("tickets") && <TicketStatus />}
            {"article Category".includes(searchValue.trim().toLowerCase()) &&
              endpoint?.startsWith("knowledgeBase") && <ArticleCategory />}
            {/* 20 Apr 2024 Hiding Article catogory internal filternal filter in bot reports for now */}
            {/* {"article Category".includes(searchValue.trim().toLowerCase()) &&
              endpoint?.startsWith("bot") && <BotArticleCategory />} */}
            {"chat Rating".includes(searchValue.trim().toLowerCase()) &&
              endpoint?.startsWith("bot") && <ChatRatings />}
            {"authored by".includes(searchValue.trim().toLowerCase()) &&
              endpoint?.startsWith("knowledgeBase") && <AuthoredBy />}
            {/* {"date added".includes(searchValue.trim().toLowerCase()) &&
              endpoint?.startsWith("knowledgeBase") && <DateAdded />} */}
            {"ratings".includes(searchValue.trim().toLowerCase()) &&
              endpoint?.startsWith("knowledgeBase") && <Ratings />}
            {/* 25 Apr 2024 Hiding Article rating internal filternal filter in bot reports for now */}
            {/* {"article Rating".includes(searchValue.trim().toLowerCase()) &&
              endpoint?.startsWith("bot") && <ArticleRatings />} */}
            {/* Render ChatStatus component if "chat status" is present in the searchValue, 
            the endpoint starts with "liveChat", and the condition is met */}
            {("chat status".includes(searchValue.trim().toLowerCase()) &&
              endpoint?.startsWith("liveChat")) ||
              (endpoint?.startsWith("bot") &&
                !endpoint.includes("chatAvailabilityReports") && (
                  <ChatStatus />
                ))}

            {/* {"ticket status".includes(searchValue.trim().toLowerCase()) &&
              endpoint?.startsWith("bot") && <TicketStatus />} */}
            {/* Render AssignedTo component if "assigned to" is present in the searchValue, 
              the endpoint starts with "liveChat", and the condition is met */}
            {"assigned to".includes(searchValue.trim().toLowerCase()) &&
              endpoint?.startsWith("liveChat") && <AssignedTo />}

            {/* Render Brands component if "brands" is present in the searchValue, 
              the endpoint starts with "liveChat", and the condition is met */}
            {"brands".includes(searchValue.trim().toLowerCase()) &&
              endpoint?.startsWith("liveChat") &&
              !endpoint.includes("chatAvailabilityReports") && <Brands />}

            {/* Render Agent component if "agent" is present in the searchValue, 
              the endpoint starts with "liveChat", and the condition is met */}
            {"agent".includes(searchValue.trim().toLowerCase()) &&
              endpoint?.startsWith("liveChat") && <Agent />}
          </div>
          <div className="px-2 pb-2 mt-1">
            <div className="d-flex justify-content-between align-items-center">
              <button
                className={`btn ${styles.cancelBtn}`}
                onClick={cancelFilters}
              >
                Cancel
              </button>
              <button
                className={`btn ${styles.applyBtn}`}
                onClick={applyFilters}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ReportInternalFilter;
