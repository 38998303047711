import { useNavigate, useParams } from "react-router-dom";
import styles from "./CategoryDetails.module.scss";
interface Props {
  newArticles: any[];
  categoryName: string;
  helpCenterPageUri: string;
  font: string;
}

const CategoryDetails = ({
  categoryName,
  newArticles,
  helpCenterPageUri,
  font,
}: Props) => {
  const navigate = useNavigate();
  const { categoryId } = useParams();
  return (
    <>
      <div className={`${styles.wrapper}`}>
        <h3
          className={`mb-3 ${styles.catHead}`}
          style={{
            fontFamily: font ?? "Roboto", // font family
          }}
        >
          {categoryName}
        </h3>
        {newArticles?.map((article) => {
          return (
            <>
              <div
                className={`d-flex justify-content-between align-items-center px-3 py-2 ${styles.categoryWrap}`}
                onClick={() => {
                  navigate(
                    `/helpcenterpage/${helpCenterPageUri}/article/${article.articleId}`,
                    {
                      state: {
                        path: "category",
                        categoryName: categoryName,
                        categoryId: categoryId,
                      },
                    }
                  );
                }}
              >
                <div>
                  <p
                    className={`mb-2 ${styles.heading}`}
                    style={{
                      fontFamily: font ?? "Roboto", // font family
                    }}
                  >
                    {article.articleName}
                  </p>
                  <span
                    className={`pe-1 ${styles.subHeading}`}
                    dangerouslySetInnerHTML={{
                      __html: article?.articleDescription,
                    }}
                  ></span>
                </div>
                <div>
                  <span className={`${styles.more}`}>
                    {/* <i className="fa-solid fa-ellipsis"></i> */}
                  </span>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};

export default CategoryDetails;
