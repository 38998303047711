/**
 * This file defines a React component responsible for delete confirmation modal and handling delete ajax.
 *
 * @author @Anubhav-busibud
 * @author @navjyot-busibud
 */
import styles from "./DeleteSourceModal.module.scss";
import { Spinner } from "react-bootstrap";
import useDeleteExternalSouce from "../External/useDeleteExternalSouce";
import { Source } from "src/services/Bot/AnswerSources/getAllExternal.service";
interface Props {
  selectedSource: Source | undefined;
  onHide: () => void;
  updateSource?: (id: number, source: Partial<Source>) => void;
}
const DeleteSourceModal = ({ selectedSource, onHide, updateSource }: Props) => {
  const {
    handleDeleteProfile,
    isError,
    isLoading,
    isSuccess,
    isDeleteInProgress,
  } = useDeleteExternalSouce({
    id: selectedSource?.id,
    updateSource: updateSource,
  });

  return (
    <div>
      {!isSuccess ? (
        <div className="text-center px-4">
          <span className={`d-block mb-3 ${styles.enquiry}`}>
            <i className="fa-solid fa-circle-exclamation"></i>
          </span>
          <p className={`${styles.modalHead}`}>
            {`Are you sure to delete this source`}
            <span className="d-block mt-1">
              {`"${selectedSource?.sourceUrl ?? ""}"`}
            </span>
          </p>
          {/* <span className={`${styles.modalSubHeading}`}>
            This will remove all the setting related to this profile and will no
            longer appear to the audiences
          </span> */}
          <div className="d-flex justify-content-center align-items-center mt-3 mb-2">
            <button
              className={`px-1 bg-white ${styles.cancelBtn}`}
              onClick={onHide}
            >
              Cancel
            </button>
            <button
              className={`px-1 ${styles.confirmBtn}`}
              disabled={isLoading}
              onClick={handleDeleteProfile}
            >
              {isLoading ? (
                <Spinner
                  className="my-auto mx-1"
                  animation="border"
                  color="white"
                  size="sm"
                />
              ) : (
                `Yes I'm sure`
              )}
            </button>
          </div>
        </div>
      ) : null}

      {/* if click on yes show below ui  */}
      {isSuccess ? (
        <div className="text-center">
          <div className={`mx-auto mt-2 ${styles.checkBox}`}>
            <span>
              <i className="fa-solid fa-check"></i>
            </span>
          </div>
          <p className={`${styles.deleteText}`}>
            {isDeleteInProgress ? "Delete In Progress!" : "Deleted!"}
          </p>
          <button
            className={`px-2 mx-auto mb-2 ${styles.okBtn}`}
            onClick={onHide}
          >
            Okay
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default DeleteSourceModal;
