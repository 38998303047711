import UserAvatar from "src/components/UserAvatar";
import { GetOrderViewModel } from "src/services/ShopifySidebar/SingleOrder/BasicOrder/getOrderViewModel";

import { useAppSelector } from "src/store/store";
import OrderTimelineMap from "./OrderTimelineMap";
import styles from "./Timeline.module.scss";

function Timeline() {
  const {
    loading,
    error,
    getOrderViewModel: getOrder,
  } = useAppSelector((state) => state.singleOrder);
  const { currentUserData } = useAppSelector((state) => state.globals);

  function renderData(order: GetOrderViewModel) {
    return (
      <div>
        <div className={`${styles.lead} d-flex ms-3 justify-content-between`}>
          <div className={`${styles.TimelineText} ms-4 pt-1`}>Timeline</div>

          {/* <div className={` ${styles.showCommentsCheckbox} me-2`}>
            <input
              className="form-check-input me-1 "
              type="checkbox"
              value=""
              id="flexCheckDefault"
            />
            <span className="ms-1 mt-1">Show Comments</span>
          </div> */}
        </div>

        <div className="ms-4">
          <hr />
        </div>

        {/* //Timeline */}

        <div className="d-flex flex-row ms-0 ms-lg-3 ">
          {/* <div className="d-flex flex-column me-2 ">
            <div className={` ${styles.avatar} ms-4`}>
              <div className={` ${styles.avatar__letters}`}>
                <b>RM</b>
              </div>
            </div>
          </div> */}
          <div className="d-flex flex-column me-2 ">
            <div className={`${styles.avatar} ms-0 ms-lg-4`}>
              <UserAvatar
                size={48}
                name={currentUserData?.firstName ?? "N A"}
                className={`${styles.agentImg}`}
              />
            </div>
          </div>

          <div className={` ${styles.lead} d-flex flex-column w-100 `}>
            {/* <div
              className={` ${styles.commentBox} border border-2 d-flex justify-content-between   `}
            >
              <div className="">
                <input data-lpignore="true" 
                  className={` ${styles.lead} ${styles.inputbox} h6  mt-3 ms-3 fw-light flex-grow-1 `}
                  placeholder="Leave a comment"
                ></input>
              </div>
              <div className="d-flex">
                <div className={`  ${styles.commentBoxIcons} h6 `}>
                  <div className="d-flex mt-3">
                    <div className=" ms-3 ">
                      <i className="fa-solid fa-face-smile" role="button"></i>{" "}
                    </div>
                    <div className=" ms-3 ">
                      <i className="fa-solid fa-at" role="button"></i>
                    </div>
                    <div className=" ms-3 ">
                      <i className="fa-solid fa-hashtag" role="button"></i>
                    </div>
                    <div className=" ms-3 me-2">
                      <i className="fa-solid fa-paperclip" role="button"></i>
                    </div>
                  </div>
                </div>

                <div>
                  <div>
                    <button
                      className={` ${styles.postButton} mt-1 me-1 mb-1 btn btn-light`}
                    >
                      Post
                    </button>
                  </div>
                </div>
              </div>
            </div> */}
            <div
              className={` ${styles.lead} ${styles.timelineWidth} d-flex flex-row-reverse flex-wrap fw-light  `}
            >
              <div>Only you and other staff can see comments</div>
            </div>

            <div
              className={`d-flex flex-column mt-0 mt-lg-4  ${styles.timelineWidth} `}
            >
              <div className="mt-2">{}</div>

              <ul className={`${styles.timeline} `}>
                {/* <div
                  className={` ${styles.resendMailBox} p-1 mt-1 ms-5 border d-inline fw-normal`}
                  role="button"
                >
                  Resend Email
                </div> */}
                {order?.orderTimeline.map((timeline, index) => {
                  return <OrderTimelineMap key={index} timeline={timeline} />;
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return <div>{!loading && getOrder && renderData(getOrder)}</div>;
}

export default Timeline;
