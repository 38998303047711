import { initialState, ITeamData, TeamsType } from "./teamSettings.slice";

// reset team settings
const resetTeamSettings = (state: TeamsType) => {
  state = {...initialState};
  return state;
};

// reset teams data
const resetTeamsData = (state: TeamsType) => {
  state.teamData = {};
  state.teamIdList = [];
  state.fetchTeamsAjaxStatus = "pending";
  state.metaData.count =0;
  state.metaData.total =0;
};

// reset members in teams data
const resetMembersInTeamData = (state: TeamsType) => {
  state.usersInTeam = {};
  state.usersInTeamIdList = [];
  // state.teamMateSearchText = "";
  state.fetchTeamMemberLimit = 15;
  state.usersInTeamCount = 0;
  state.usersInTeamTotalCount = 0;
  state.fetchUsersInTeamAjaxStatus = "pending";
};

// reset members in teams data
const resetAllUsersData = (state: TeamsType) => {
  state.allUsers = {};
  state.allUsersIdList = [];
  state.addMemberSearchText = "";
  state.fetchAllUsersLimit = 10;
  state.allUsersCount = 0;
  state.allUsersTotalCount = 0;
  state.fetchAllUsersAjaxStatus = "pending";
};

const resetActiveTeamData = (state: TeamsType) => {
  state.activeTeam = {
    teamId: 0,
    teamName: "",
    users: 0
  };
}

const setActiveTeamData = (
  state: TeamsType,
  { payload }: { payload: ITeamData }
) => {
  state.activeTeam = payload;
  return state;
};

const setTeamMateSearchValue = (
  state: TeamsType,
  { payload }: { payload: { searchValue: string } }
) => {
  state.teamMateSearchText = payload.searchValue;
};

const resetTeamMateSearchValue = (state: TeamsType) => {
  state.teamMateSearchText = "";
}

export default { 
  resetTeamSettings,
  resetTeamsData, 
  resetMembersInTeamData, 
  resetAllUsersData, 
  resetActiveTeamData, 
  setActiveTeamData, 
  setTeamMateSearchValue,
  resetTeamMateSearchValue
};