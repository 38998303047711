/**
 * This file is the service file used for making api request.
 * It contains the getAllArticleOnly function service which is explained below.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants"; // this is used for testing purpose only for frontend.

export interface GetAllArticleOnlyParams {
  integrationId: number | string;
  start: number | string;
  limit: number | string;
}

export interface Articles {
  articleId: number | string;
  articleName: string;
  createdAtGmt: string;
}
export interface GetAllArticleOnlyRes {
  data: Articles[];
  metaData: {
    currentCount: number | string;
    totalCount: number | string;
  };
}

/**
 * For testing frontend with own api link if backend is not prepared.
 */
const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/getAllArticleOnly`
  : "/api/chatSetting/article/getAllArticleOnly";

/**
 * This funcytion is used to get all the articles.
 */
export const getAllArticleOnly = async (params: GetAllArticleOnlyParams) => {
  const { data: res } = await axiosJSON.post(apiEndPoint, params);
  return res as GetAllArticleOnlyRes;
};
