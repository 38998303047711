import React, { useContext, useMemo, useReducer, useState } from "react";

export interface BugManagementCurrentStatus {
  bugCategory: Array<string>;
  status: Array<string>;
  priority: Array<string>;
}

export interface BugFilters {
  category?: Array<string>;
  status?: Array<string>;
  priority?: Array<string>;
}

/**
 * Initializing filters.
 */
const bugManagementCurrentStatus: BugManagementCurrentStatus = {
  bugCategory: [],
  status: [],
  priority: [],
};

const reducerMethod = (
  state: BugManagementCurrentStatus,
  [action, payload]: [
    (value: BugManagementCurrentStatus, payload?: any) => void,
    undefined | any,
  ],
) => {
  action(state, payload);
  return { ...state };
};

/**
 * Creating the context.
 */
const CurrentStatusContext = React.createContext([
  bugManagementCurrentStatus,
  () => {},
] as [
  BugManagementCurrentStatus,
  React.Dispatch<
    [
      (value: BugManagementCurrentStatus, payload?: any) => void,
      undefined | any,
    ]
  >,
]);

/**
 * Creating reducer and combining it with the context.
 */
export const useBugManagementFiltersCreate = (props?: {
  globalDateApplicable?: boolean;
}) => {
  const [state, dispatch] = useReducer(
    reducerMethod,
    (() => {
      const is = {
        ...bugManagementCurrentStatus,
      };
      return is;
    })(),
  );

  return [state, dispatch, CurrentStatusContext.Provider] as [
    BugManagementCurrentStatus,
    typeof dispatch,
    typeof CurrentStatusContext.Provider,
  ];
};

/**
 * Using the context in this hook and returning the dispatch to update data in reducer and da data extracted for context.
 */
export const useBugManagementFilters = () => {
  const [currentStatus, dispatch] = useContext(CurrentStatusContext);

  const filtersForAPI = useMemo(() => {
    const f: BugFilters = {};
    if (currentStatus.bugCategory.length) {
      f.category = currentStatus.bugCategory;
    }
    if (currentStatus.status.length) {
      f.status = currentStatus.status;
    }
    if (currentStatus.priority.length) {
      f.priority = currentStatus.priority;
    }
    if (Object.keys(f).length === 0) {
      return undefined;
    } else {
      return f;
    }
  }, [currentStatus]);
  return { currentStatus, dispatch, filtersForAPI };
};


/**
 * These are the action functions which will help to apply the the state changes in the created reducer.
 */
export const bugManagementCurrentStatusActions = {
  resetState: (state: BugManagementCurrentStatus) => {
    state.bugCategory = [];
    state.status = [];
    state.priority = [];
  },
  setFilters: (
    state: BugManagementCurrentStatus,
    payload: Partial<BugManagementCurrentStatus>,
  ) => {
    if (payload.bugCategory) {
      state.bugCategory = payload.bugCategory;
    }
    if (payload.status) {
      state.status = payload.status;
    }
    if (payload.priority) {
      state.priority = payload.priority;
    }
  },
  clearOneAppliedFilter: (
    state: BugManagementCurrentStatus,
    payload: keyof BugManagementCurrentStatus,
  ) => {
    state[payload] = [];
  },
};


// //initial params for pagination
//   const [payload, setPayload] = useState<PaginationParam>({
//     // botProfileId: pageIntegrationId,
//     start: 0,
//     limit: 10,
//   });