/**
 * This file is the service file used for making api request.
 * It contains the updatePriorities function service which is explained below.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants"; // this is used for testing purpose only for frontend.

export interface UpdatePrioritiesPayload {
  integrationId: number | string;
  updateType: "article" | "category";
  ids: Array<number | string>;
  categoryId?: number | string; // if updateType is "article" then required
}

/**
 * For testing frontend with own api link if backend is not prepared.
 */
const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/updatePriorities`
  : "/api/chatSetting/article/updatePriorities";

/**
 * This service is used to update the priorities.
 */
export const updatePriorities = async (params: UpdatePrioritiesPayload) => {
  const { data: res } = await axiosJSON.post(apiEndPoint, params);
  return res;
};
