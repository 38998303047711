import React, { useState } from "react";
import styles from "./ChatRating.module.scss";
import ratingDislikeIcon from "src/assets/images/chat/chatRatingdislike.png";
import ratingLikeIcon from "src/assets/images/chat/chatRatinglike.png";

function ChatRating({ element, inputName }: any) {
  const [liked, setLiked] = useState<boolean>();
  // console.log(element);
  return (
    <div className={`text-muted ${styles.fonts} mt-2`}>
      <p>
        {element.elementValue}
        {element.isRequired ? (
          <span className="text-danger align-top"> *</span>
        ) : null}
        <div className="mt-2">
          <input
            name={inputName}
            type="checkbox"
            required={element.isRequired === true}
            style={{ display: "none" }}
          />
          <span
            className={`${styles.ratingIcon} me-2`}
            onClick={(e) => {
              setLiked(true);
            }}
          >
            {/* <img src={ratingLikeIcon}  /> */}
            {liked === true ? (
              <i className="fas fa-thumbs-up text-primary"></i>
            ) : (
              <i className="fas fa-thumbs-up text-dark"></i>
            )}
          </span>
          <span
            className={`${styles.ratingIcon} ${styles.disLike}`}
            onClick={(e) => {
              setLiked(false);
            }}
          >
            {/* <img src={ratingDislikeIcon}  /> */}
            {liked === false ? (
              <i
                className={`fas fa-thumbs-up text-primary ${styles.disLike}`}
              ></i>
            ) : (
              <i className={`fas fa-thumbs-up text-dark ${styles.disLike}`}></i>
            )}
          </span>
        </div>
        <hr />
        <div>
          <textarea
            className="rounded w-100"
            style={{
              resize: "none",
              minHeight: "40px",
              border: "none",
              padding: "10px",
              outline :"none"
            }}
            placeholder="Thank you for the rating! You can also leave a comment:"
            value={element?.commentLabelValue}
            rows={2}
          ></textarea>
        </div>
        <hr />
      </p>
    </div>
  );
}

export default ChatRating;
