import { useEffect } from "react";

interface CustomCodeProps {
  customCodeHead : string;
  customCodeBody : string;
}

function CustomCode({
  customCodeHead,
  customCodeBody,
} : CustomCodeProps) {
  useEffect(() => {
    const head = document.head;
    const body = document.body;

    if (customCodeHead) {
      head.appendChild(document.createRange().createContextualFragment(customCodeHead));
    }
    if (customCodeBody) {
      body.appendChild(document.createRange().createContextualFragment(customCodeBody));
    }
  }, []);
  return <></>;
}

export default CustomCode;