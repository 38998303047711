import styles from "./ShowImgVid.module.scss";
import userImg from "src/assets/images/user.png";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import {
  deleteAttachment,
  DeleteAttachmentParam,
} from "src/services/Attachment/deleteAttachment";
import AxiosImg from "src/components/AxiosImg";
import {
  STATUS_UPLOAD,
  TinyMCEUploadFile,
} from "src/interfaces/TinyMCEContainer/ITinyMCEContainer";
import ImagePreviewModal from "./children/ImagePreviewModal/ImagePreviewModal"; // Importing ImagePreviewModal.tsx file
interface Props {
  file: TinyMCEUploadFile;
  onDeleteFile: Function;
}
function ShowImgVid({ file, onDeleteFile }: Props) {
  const [isUploading, setIsUploading] = useState<boolean>(true);
  const [loading, setLoading] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  useEffect(() => {
    if (file.status === STATUS_UPLOAD.success) {
      // file is uploaded
      setIsUploading(false);
    }
  }, [file.status]);

  const [showImagePreview, setShowImagePreview] = useState(false); // State for showing image preview

  return (
    <>
      <div
        className={`${styles.showImgDiv}`}
        // Setting state for showing image preview on click
        onClick={() => {
          setShowImagePreview(true);
        }}
        role="button" // Setting role as button
      >
        {file.attachmentURL ? (
          <AxiosImg
            url={file.attachmentURL}
            className={`${styles.showImg}`}
            isLoaded={imageLoaded}
            setIsLoaded={setImageLoaded}
            isDirectURL={file.isPublic}
          />
        ) : (
          <img
            className={`${styles.showImg}`}
            src={
              file.file
                ? (file.file as any).customPreviewURL ??
                  URL.createObjectURL(file.file)
                : ""
            }
          />
        )}

        <span
          className={`${styles.closeNProgress} ${
            isUploading === true && isHovered === false
              ? styles.progressBadgeBg
              : styles.CloseBadgeBg
          }`}
          onMouseOver={() => {
            setIsHovered(true);
          }}
          onMouseLeave={() => {
            setIsHovered(false);
          }}
          onClick={
            // isUploading === false ? (e) => removeAttachment(e) : undefined
            (e) => {
              e.stopPropagation(); // Preventing event bubbling
              onDeleteFile(file);
            }
          }
        >
          {isUploading === false || isHovered === true ? (
            <i className={`fa-solid fa-times ${styles.clossIcon}`}></i>
          ) : (
            <Spinner
              className={`my-auto mx-1 ${styles.spinner}`}
              animation="border"
              // variant="primary"
              // style={{width:"12px", height:"12px"}}
              // style={{color: "#0B68BB", width:"12px", height:"12px"}}
              // size="sm"
            />
          )}
        </span>
      </div>
      {/* Component For Preview Modal of Attachments  */}
      <ImagePreviewModal
        imgSrc={file}
        onHide={() => {
          setShowImagePreview(false);
        }}
        show={showImagePreview}
      />
    </>
  );
}

export default ShowImgVid;
