import {createAsyncThunk} from "@reduxjs/toolkit";
import {getLiveChatCustomerAllMessages} from "src/services/LiveChat/liveChatHistory/getLiveChatCustomerAllMessages.service";
import {getLiveChatHistoryConversation} from "src/services/LiveChat/liveChatHistory/getLiveChatHistoryConversation.service";
import getLiveChatHistoryChats from "src/services/LiveChat/liveChatHistory/liveChatHistoryChats.service";
import getLiveChatHistoryMessages from "src/services/LiveChat/liveChatHistory/liveChatHistoryMessages.service";
import {getCustomerByChatId} from "src/services/LiveChat/messageExchange/getCustomerByChatId";
import {RootState} from "src/store/store";

async function fetchHistoryChatsThunk(
  payload: undefined,
  {getState, dispatch}: {getState: Function; dispatch: Function}
) {
  const currentState: RootState = getState();
  if (currentState.liveChatHistory.selectedChatId) {
    let customer = currentState.liveChatHistory.customer;
    if (currentState.liveChatHistory.selectedChatId != null) {
      customer = (
        await getCustomerByChatId({
          chatId: currentState.liveChatHistory.selectedChatId,
        })
      ).data;
    }
    if (customer?.customerId) {
      const data = await getLiveChatHistoryChats({
        customerId: customer.customerId,
        start: currentState.liveChatHistory.historyChats.chatListIds.length,
        limit: currentState.liveChatHistory.historyChatsLimit,
      });
      return {...data, customer};
    } else {
      throw "Customer id not selected!";
    }
  } else {
    throw "Chat id not selected!";
  }
}

export const fetchHistoryChats = createAsyncThunk(
  "liveChatHistory/fetchHistoryChats",
  fetchHistoryChatsThunk
);

async function fetchHistoryMessagesThunk(
  payload: undefined,
  {getState}: {getState: Function}
) {
  const currentState: RootState = getState();
  if (currentState.liveChatHistory.selectedInternalChatId) {
    const data = await getLiveChatHistoryMessages({
      chatRequestId: currentState.liveChatHistory.selectedInternalChatId,
      start:
        currentState.liveChatHistory.selectedChatMessages.messageListIds.length,
      limit: currentState.liveChatHistory.selectedChatMessagesLimit,
    });
    return data;
  } else {
    throw "Customer id not selected!";
  }
}

export const fetchHistoryMessages = createAsyncThunk(
  "liveChatHistory/fetchHistoryMessages",
  fetchHistoryMessagesThunk
);

async function LiveChatHistoryConversationThunk(
  payload: any,
  {getState}: {getState: Function}
) {
  const currentState: RootState = getState();
  const data = await getLiveChatHistoryConversation(payload);
  return data;
}

export const fetchLiveChatHistoryConversation = createAsyncThunk(
  "liveChatHistory/fetchLiveChatHistoryConversation",
  LiveChatHistoryConversationThunk
);

export default {
  fetchHistoryChatsThunk,
  fetchHistoryMessagesThunk,
  LiveChatHistoryConversationThunk,
};
