import { useCallback, useMemo, useState } from "react";
import { AJAXSTATUS } from "src/globals/constants";
import { useReturnAutoWorkFlow } from "./useReturnAutoWorkFlow";
import { BrandOption } from "src/routes/BotSettings/Children/BotBox/BrandModal/BrandModal";
import { useAppSelector } from "src/store/store";
import publishReturnIntegrationService from "src/services/ReturnAutoWorkFlow/publishReturnIntegration.service";

/**
 * Custom hook to handle publishing and updating the status of a return automation workflow.
 *
 * This hook manages the process of publishing the return automation workflow by
 * making an API call to update the return integration. It also manages the loading
 * status of the operation, providing feedback on whether the action is pending,
 * fulfilled, or rejected.
 *
 * @returns An object containing the `publishReturnAutoWorkFlow` function to trigger the
 * publishing process and the `loadingStatus` state to monitor the status of the
 * API call.
 *
 * @example
 * const { publishReturnAutoWorkFlow, loadingStatus } = usePublishReturnAutoWorkFlow();
 *
 * // Call the publishReturnAutoWorkFlow function to publish or unpublish the workflow
 * publishReturnAutoWorkFlow(true); // To publish
 * publishReturnAutoWorkFlow(false); // To unpublish
 *
 * // Use loadingStatus to show a loading spinner or an error message
 * if (loadingStatus === "pending") {
 *   // Show loading spinner
 * } else if (loadingStatus === "fulfilled") {
 *   // Show success message
 * } else if (loadingStatus === "rejected") {
 *   // Show error message
 * }
 */
function usePublishReturnAutoWorkFlow() {
  const brandsData = useAppSelector((state) => {
    return state.globals.brandSignatureData;
  });

  const { returnAutoWorkFlow, dispatch } = useReturnAutoWorkFlow();
  const { returnAutoWorkFlowBrand, integrationId } = useMemo(
    () => ({
      returnAutoWorkFlowBrand: returnAutoWorkFlow.brand ?? null,
      integrationId: returnAutoWorkFlow.integrationId + "",
    }),
    [returnAutoWorkFlow],
  );

  const [loadingStatus, setLoadingStatus] = useState<AJAXSTATUS>("fulfilled");

  const [selectedBrand, setSelectedBrand] = useState(
    (returnAutoWorkFlowBrand
      ? {
          label: returnAutoWorkFlowBrand.name,
          value: returnAutoWorkFlowBrand.id as any,
        }
      : null) as BrandOption | null,
  );

  //Set the selected brand initially
  useMemo(() => {
    if (returnAutoWorkFlowBrand) {
      setSelectedBrand({
        label: returnAutoWorkFlowBrand.name,
        value: returnAutoWorkFlowBrand.id as any,
      });
    }
  }, [returnAutoWorkFlowBrand]);

  // Callback to handle the change event of the dropdown
  const handleChange = useCallback((selectedOption: BrandOption | null) => {
    if (selectedOption) {
      setSelectedBrand(selectedOption);
    }
  }, []);

  const publishReturnAutoWorkFlow = useCallback(
    async (publish: boolean) => {
      if (integrationId && selectedBrand) {
        try {
          setLoadingStatus("pending");

          await publishReturnIntegrationService({
            integrationId,
            brandId: selectedBrand.value + "",
            publish,
          });

          const newBrand =
            brandsData.brands?.find(
              (brand) => brand.id + "" === selectedBrand.value + "",
            ) ?? null;

          dispatch("setState", {
            published: publish,
            brand: newBrand
              ? {
                  id: newBrand.id + "",
                  name: newBrand.name,
                  email: newBrand.email,
                  imageURL: newBrand.imageURL ?? newBrand.imageUrl,
                }
              : null,
          });

          setLoadingStatus("fulfilled");
        } catch (error) {
          console.error(error);
          setLoadingStatus("rejected");
        } finally {
        }
      }
    },
    [dispatch, selectedBrand, integrationId, brandsData],
  );

  return {
    returnAutoWorkFlow,
    returnAutoWorkFlowBrand,
    integrationId,
    publishReturnAutoWorkFlow,
    loadingStatus,
    selectedBrand,
    setSelectedBrand,
    handleChange,
  };
}

export default usePublishReturnAutoWorkFlow;
