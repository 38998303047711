import styles from "./WidgetPreview.module.scss";
import QuerySection from "./Children/QuerySection/QuerySection";
import SelectAction from "./Children/SelectAction/SelectAction";
import ConfigureSetting from "./Children/ConfigureSetting/ConfigureSetting";
import PolicyMessage from "./Children/PolicyMessage/PolicyMessage";
import AddReturnItems from "../OrderSection/AddReturnItems/AddReturnItems";
import SelectReturnType from "./Children/SelectReturnType/SelectReturnType";
import AddReasons from "./Children/AddReasons/AddReasons";
import OrderInformation from "./Children/OrderInformation/OrderInformation";
import StagePreview from "./Children/StagePreview/StagePreview";
import CheckoutOffers from "./Children/CheckoutOffers/CheckoutOffers";
import SelectExchange from "./Children/SelectExchange/SelectExchange";
import { useReturnPreviewState } from "../../../../hooks/useReturnPreview";
import NotReturnableItems from "./Children/NotReturnableItems/NotReturnableItems";
import MoreDetails from "./Children/MoreDetails/MoreDetails";
import ARSReturnType from "./Children/ARSReturnTypes/ARSReturnType";
import ReturnMethod from "./Children/ReturnMethod/ReturnMethod";
import ReviewRestockingFee from "./Children/ReviewReskoingFee/ReviewRestockingFee";
import OrderSummary from "./Children/OrderSummary/OrderSummary";

const WidgetPreview = () => {
  const { selectedOrder } = useReturnPreviewState();
  return (
    <div className={`${styles.widgetWrapper}`}>
      <div className={`${styles.header}`}>
        <div className="d-flex align-items-center">
          {/* <img
            src={homeImg}
            alt=""
          /> */}
          <span>
            <i className="fa-solid fa-house"></i>
          </span>
          <span className="ps-2">Issues/Questions with existing order</span>
        </div>
      </div>
      <div className={`${styles.initiateHeader} d-none`}>
        <div className="d-flex align-items-center">
          {/* <img
            src={homeImg}
            alt=""
          /> */}
          <span>
            <i className="fa-solid fa-house"></i>
          </span>
          <span className="ps-2">Initiate return order</span>
        </div>
      </div>
      <div>
        <div>
          {selectedOrder?.activeStep === "selectOrder" ? (
            <QuerySection />
          ) : selectedOrder?.activeStep === "selectAction" ? (
            <SelectAction />
          ) : selectedOrder?.activeStep === "notReturnable" ? (
            <PolicyMessage />
          ) : selectedOrder?.activeStep === "configureNextSteps" ? (
            <ConfigureSetting />
          ) : selectedOrder?.activeStep === "addReturnItems" ? (
            <AddReturnItems />
          ) : selectedOrder?.activeStep === "notReturnableItems" ? (
            <NotReturnableItems />
          ) : selectedOrder?.activeStep === "returnType" ? (
            <SelectReturnType />
          ) : selectedOrder?.activeStep === "reason" ? (
            <AddReasons />
          ) : selectedOrder?.activeStep === "moreDetails" ? (
            <MoreDetails />
          ) : selectedOrder?.activeStep === "arsInformation" ? (
            <OrderInformation />
          ) : selectedOrder?.activeStep === "antiRefundStagePreview" ? (
            <StagePreview />
          ) : selectedOrder?.activeStep === "arsOffers" ? (
            <CheckoutOffers />
          ) : selectedOrder?.activeStep === "arsReturnTypes" ? (
            <ARSReturnType />
          ) : selectedOrder?.activeStep === "arsExchange" ? (
            <SelectExchange />
          ) : selectedOrder?.activeStep === "returnMethod" ? (
            <ReturnMethod />
          ) : selectedOrder?.activeStep === "reviewRestockingFee" ? (
            <ReviewRestockingFee />
          ) : selectedOrder?.activeStep === "orderSummary" ? (
            <OrderSummary />
          ) : (
            <QuerySection />
          )}

          {/* <ReturnPolicy /> */}
        </div>
      </div>
    </div>
  );
};

export default WidgetPreview;
