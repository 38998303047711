import { ChangeEvent, useCallback, useState } from "react";
import styles from "./ResoltionUi.module.scss";
import editIcon from "src/assets/images/editIcon.png";
import homeIcon from "src/assets/images/homeIcon.png";
import downloadFile from "src/assets/images/solarLinear.png";
import TimeLine from "../BugReportModal/Children/TimeLine/TimeLine";
import { useMutation } from "@tanstack/react-query";
import updateBugResolution from "src/services/BugReport/updateBugResolution";
import { Spinner } from "react-bootstrap";

interface params {
  bugId: number;
  resolutionMessage: string;
}
interface BugStatus {
  id?: number;
  description?: string;
  severity?: string;
  status: string;
  isMerge: boolean;
  setTimeLineStep: React.Dispatch<React.SetStateAction<number>>;
  isLoading: boolean;
  handleStatusUpdate: ({ bugId, resolutionMessage }: params) => void;
}

const ResolutionUi = ({
  description,
  severity,
  status,
  id,
  isMerge,
  setTimeLineStep,
  handleStatusUpdate,
  isLoading,
}: BugStatus) => {
  const [editText, setEditText] = useState(status !== "resolved");

  // initialValue of the resolution msg
  const message =
    "Thank you for being patient, we have looked into your complaints and resolved the bug reported by you.";

  const [editMessage, setEditMessage] = useState(message);

  // handle msg type in tinyMCE
  const handleEditMessage = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setEditMessage(event.target.value);
  };

  // reset msg to it's original state
  const handleResetMessage = () => {
    setEditMessage(message);
  };

  return (
    <div className="d-flex mt-5">
      <div className="w-50 ms-1">
        <div className="mb-3">
          <label
            htmlFor="exampleFormControlInput1"
            className="form-label"
          >
            Change status
          </label>
          <div className={`py-3 px-3 ${styles.inputBox}`}>
            <div
              className={`${styles.optionText} d-flex justify-content-between align-items-center`}
            >
              <span>Resolved</span>
              <i
                className="fa fa-chevron-down"
                aria-hidden="true"
              ></i>
            </div>
          </div>
        </div>
        <div className="mb-3">
          <label
            htmlFor="exampleFormControlTextarea1"
            className="form-label"
          >
            Resolution Message:
          </label>
          <textarea
            className={`form-control p-3 ${styles.textMsg} ${
              editText ? styles.textGrey : styles.textBlack
            }`}
            id="exampleFormControlTextarea1"
            rows={7}
            value={editMessage}
            disabled={editText || status === "resolved"}
            onChange={handleEditMessage}
          ></textarea>

          {/* this will be only appear in the merge modal */}
          {isMerge && (
            <div className="d-flex">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="11.296"
                  height="11.297"
                  viewBox="0 0 11.296 11.297"
                >
                  <path
                    id="Icon_material-outline-info"
                    data-name="Icon material-outline-info"
                    d="M8.083,5.824h1.13v1.13H8.083Zm0,2.259h1.13v3.389H8.083ZM8.648,3A5.648,5.648,0,1,0,14.3,8.648,5.65,5.65,0,0,0,8.648,3Zm0,10.166a4.518,4.518,0,1,1,4.518-4.518A4.524,4.524,0,0,1,8.648,13.166Z"
                    transform="translate(-3 -3)"
                  />
                </svg>
              </div>
              <span className={`pt-1 ps-1 ${styles.cautionText}`}>
                This message was already sent to the customer previously. If
                this is not the right resolution message, you can edit the
                resolution message which will appear on the portal to the
                customer.
              </span>
            </div>
          )}
          {/* check bug status to view button according to it */}
          <div className={`${styles.editWrap}`}>
            {status === "resolved" ? (
              ""
            ) : editText ? (
              <div onClick={() => setEditText((oldState) => !oldState)}>
                <img
                  src={editIcon}
                  alt=""
                />
                <span className="ps-1">Edit/send a new resolution message</span>
              </div>
            ) : (
              <div className="d-flex justify-content-between mt-2">
                <span
                  className="ps-1"
                  onClick={handleResetMessage}
                >
                  Reset to default message
                </span>
                <span className={styles.grayText}>
                  You are now editing the message
                </span>
              </div>
            )}
          </div>
          <div className="d-flex justify-content-between mt-3">
            <button
              className={`px-4 ${styles.resolveBtn}`}
              onClick={() => setTimeLineStep((prevState) => prevState - 1)}
              disabled={isLoading}
            >
              <span className="pe-1">
                <i className="fa-solid fa-angle-left"></i>
              </span>{" "}
              Back
            </button>
            <button
              className={`px-4 ${styles.resolveBtn}`}
              disabled={isLoading}
              onClick={
                status === "resolved"
                  ? () => setTimeLineStep((prevState) => prevState + 1)
                  : () =>
                      handleStatusUpdate({
                        bugId: id ?? 0,
                        resolutionMessage: editMessage,
                      })
              }
            >
              {status === "resolved" ? "Next" : "Confirm and update"}
              {isLoading ? (
                <Spinner
                  size="sm"
                  className="ms-1"
                />
              ) : (
                <span className="ps-1">
                  <i className="fa-solid fa-angle-right"></i>
                </span>
              )}
            </button>
          </div>
        </div>
      </div>

      {/* right side widget preview modal */}
      <div className="w-50 px-3">
        <h5 className={`${styles.customerPreview}`}>Customer Preview</h5>
        <div className={`${styles.widgetPreview}`}>
          <div className={`d-flex align-items-center ${styles.header}`}>
            <img
              src={homeIcon}
              alt=""
              width={20}
            />
            <span className={`ps-2 ${styles.heading}`}>
              Manage or report bugs
            </span>
          </div>
          <div className="d-flex align-items-center justify-content-center my-4">
            <TimeLine
              stepText={["Describe issue", "Done!", "Resolution"]}
              activeStep={3}
              textMargin={["", "ms-0"]}
            />
          </div>
          <div className="px-3">
            <p className={`mb-2 ${styles.resolution}`}>Resolution</p>
            <div className={`px-4 ${styles.resolutionBox}`}>
              <p className={`mb-2 ${styles.thanksMsg}`}>{editMessage}</p>
              <div className="d-flex justify-content-between align-content-center">
                <button className={`px-3 ${styles.downLoadBtn}`}>
                  <img
                    src={downloadFile}
                    alt=""
                  />
                  <span>Download recording</span>
                </button>
                <button className={`px-3 ${styles.viewTicket}`}>
                  View ticket
                </button>
              </div>
              <div className={`mt-3 ${styles.statusBox}`}>
                <p className={`mb-2 ${styles.resHeading}`}>Status</p>
                <span className={`${styles.resSub}`}>
                  {description && description?.length > 50
                    ? description?.slice(0, 47) + "..."
                    : description}
                </span>
                <p className={`my-2 ${styles.errorMsg}`}>{severity}</p>
                <div className="d-flex align-items-center">
                  <div className={`${styles.greyDot}`}></div>
                  <span className={`px-1 ${styles.submitText}`}>Submitted</span>
                </div>
                {/* <span className={`${styles.updatedTime}`}>
                    Last updated : {date}
                  </span> */}
                <p className={`mb-0 mt-2 ${styles.readLess}`}> read less</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResolutionUi;
