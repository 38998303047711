import { createAsyncThunk } from "@reduxjs/toolkit";
import { RefundScenarioState } from "src/routes/Setting/children/MainSetting/children/SetRulesNew/childerns/childern/AddAutomations/AddAutomationStep4/Children/AntiRefundModal/Children/RefundBox/Scenario/ScenarioReducer";
import { fetchRefundRequestFollowUpQuestions } from "src/services/UiAutomation/ReturnExchange/Configuration/AntiRefund/getFollowUpQuestion";
import { fetchGetRefundRequestList } from "src/services/UiAutomation/ReturnExchange/Configuration/AntiRefund/getRefundRequestList";
import {
  UpdateAntiRefundRequestPayload,
  updateAntiRefundRequests,
} from "src/services/UiAutomation/ReturnExchange/Configuration/AntiRefund/updateAntiRefundRequests.service";
import { fetchUpdateRefundRequestSelectedItem } from "src/services/UiAutomation/ReturnExchange/Configuration/AntiRefund/updateRefundRequest";
import { RootState } from "src/store/store";
import {
  fetchGetAllProductTags,
  fetchGetAllShopifyProducts,
} from "../returnExchange.thunk";

interface UpdatePayload {
  reasonId: number;
  scenarioId: string | null;
  // localState: RefundScenarioState | null;
}

export const fetchRefundRequestList = createAsyncThunk(
  "fetchRefundRequestList",
  async (payload, { dispatch, getState }) => {
    const currentState = getState() as RootState;
    const integrationId = currentState.returnExchange.activeIntegrationId;
    // Getting all tags and products to assign incase of ["*"]
    let allTags: any = currentState.returnExchange.getAllProductTags.data;
    let allItems: any = currentState.returnExchange.getAllShopifyProducts;
    try {
      const getRefundRequestList = await fetchGetRefundRequestList({
        integrationId,
      });
      if (allTags?.length == 0) {
        const productTags: any = await dispatch(
          fetchGetAllProductTags({ limit: 1000 }),
        );
        allTags = productTags?.payload?.data ?? [];
      }
      if (allItems?.length == 0) {
        const allShopifyProducts: any = await dispatch(
          fetchGetAllShopifyProducts({
            limit: 1000,
            searchTerm: "",
            start: 0,
          }),
        );
        allItems = allShopifyProducts.payload?.data ?? [];
      }

      return {
        refundRequestList: getRefundRequestList,
        allItems: allItems,
        allTags: allTags,
      };
    } catch (error) {
      console.error("Failed to fetch refund request list:", error);
      throw error;
    }
  },
);

export const fetchGetRefundRequestFollowUpQuestions = createAsyncThunk(
  "fetchGetRefundRequestFollowUpQuestions",
  async (payload: { id: string }, { dispatch, getState }) => {
    const currentState = getState() as RootState;
    const integrationId = currentState.returnExchange.activeIntegrationId;
    const getRefundRequestFollowUpQuestions =
      fetchRefundRequestFollowUpQuestions({
        integrationId,
        id: payload.id,
      });

    if (getRefundRequestFollowUpQuestions) {
      return getRefundRequestFollowUpQuestions;
    }
  },
);

export const fetchPostAntiRefundConfig = createAsyncThunk(
  "fetchPostAntiRefundConfig",
  async (payload: UpdatePayload, { dispatch, getState }) => {
    const currentState = getState() as RootState;
    const integrationId = currentState.returnExchange.activeIntegrationId;
    const reasonIndex =
      currentState.antiRefundConfig.refundRequests.reasons.findIndex(
        (reason) => reason.id === payload.reasonId,
      );
    const scenarioIndex = currentState.antiRefundConfig.refundRequests.reasons[
      reasonIndex
    ].scenarios.findIndex((scenario) => scenario.id === payload.scenarioId);
    const selectedScenario =
      currentState.antiRefundConfig.refundRequests.reasons[reasonIndex]
        .scenarios[scenarioIndex];
    const res = await fetchUpdateRefundRequestSelectedItem({
      id: payload.reasonId,
      integrationId: Number(integrationId),
      scenarioId: payload.scenarioId,
      itemIds: selectedScenario.itemsAndTagAllSelected
        ? ["*"]
        : Array.isArray(selectedScenario.items)
          ? selectedScenario.items.map((item) => item.id as number)
          : [],
      tags: selectedScenario.itemsAndTagAllSelected
        ? ["*"]
        : Array.isArray(selectedScenario.tags)
          ? selectedScenario.tags.map((tag) => tag)
          : [],
      enabled: selectedScenario.enabled,
      additionalInformation: selectedScenario.additionalInformation,
      giveOffer: selectedScenario.giveOffer?.enabled
        ? ({
            ...selectedScenario.giveOffer,
            name: "",
          } as any)
        : undefined,
      offerExchange: selectedScenario.offerExchange,
      followUpQuestions: selectedScenario.followUpQuestions.map((question) => {
        if (
          question.selectedValue.length === question.additionalOptions.length
        ) {
          return {
            ...question,
            selectedValue: ["*"],
            // selectedId: ["*"],
          };
        } else {
          return question;
        }
      }),
    });
  },
);

export const fetchUpdateAntiRefundRequest = createAsyncThunk(
  "fetchUpdateAntiRefundRequest",
  async (payload: {}, { dispatch, getState }) => {
    const currentState = getState() as RootState;
    const integrationId = currentState.returnExchange.activeIntegrationId;
    const param: UpdateAntiRefundRequestPayload = {
      integrationId,
      refundRequests: currentState.antiRefundConfig.refundRequests.reasons.map(
        (reason) => {
          return {
            id: reason.id.toString(),
            scenarios: reason.scenarios.map((scenario) => {
              return {
                // If Scenario is new then don't send scenarioId
                scenarioId: scenario.isNewScenario ? undefined : scenario.id,
                // Need to filter it because somehow the non selected tags are stored as null.
                tags:
                  scenario.itemsAndTagAllSelected === true
                    ? ["*"]
                    : (scenario.tags as string[]).filter(
                        (v) => v !== null && v !== undefined,
                      ),
                itemIds:
                  scenario.itemsAndTagAllSelected === true
                    ? ["*"]
                    : scenario.items
                        .filter((item) => item.id !== undefined)
                        .map((item) => item.id?.toString() || ""),
                enabled: (scenario.enabled as boolean) ?? false,
                additionalInformation: {
                  enabled: scenario.additionalInformation?.enabled ?? false,
                  message: scenario.additionalInformation?.message ?? "",
                  extendReturnPeriod: {
                    enabled:
                      scenario.additionalInformation?.extendReturnPeriod
                        ?.enabled ?? false,
                    days:
                      scenario.additionalInformation?.extendReturnPeriod
                        ?.days ?? 0,
                  },
                },
                giveOffer: {
                  enabled: scenario.giveOffer?.enabled ?? false,
                  message: scenario.giveOffer?.message ?? "",
                  offerSelectedItems:
                    (scenario.giveOffer?.offerSelectedItems as number[]) ?? [],
                  offerSelectedTags: scenario.giveOffer
                    ?.offerSelectedTags as string[],
                  offerType:
                    (scenario.giveOffer?.offerType as string) ?? "other",
                  offerValue: (scenario.giveOffer?.offerValue as number) ?? 0,
                  offerUnit: scenario.giveOffer?.offerUnit ?? "None",
                  name: scenario.giveOffer?.name ?? "",
                },
                offerExchange: {
                  enabled: scenario.offerExchange?.enabled ?? false,
                  message: scenario.offerExchange?.message ?? "",
                  exchangeWithOtherItems:
                    scenario.offerExchange?.exchangeWithOtherItems ?? false,
                  exchangeWithSameItems:
                    scenario.offerExchange?.exchangeWithSameItems ?? false,
                },
                followUpQuestions:
                  scenario.followUpQuestions.length > 0
                    ? scenario.followUpQuestions.map((question) => {
                        if (
                          question.selectedValue.length ===
                          question.additionalOptions.length
                        ) {
                          return {
                            elementId: question.elementId,
                            value: ["*"],
                            // selectedId: ["*"],
                          };
                        } else {
                          return {
                            elementId: question.elementId,
                            value: question.selectedValue,
                          };
                        }
                      })
                    : [],
              };
            }),
          };
        },
      ),
    };
    await updateAntiRefundRequests(param);

    // Refetch updated data
    const getRefundRequestList = await fetchGetRefundRequestList({
      integrationId,
    });

    return getRefundRequestList;
  },
);
