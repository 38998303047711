import styles from "./TicketTabs.module.scss";
import useTicketViews from "src/hooks/useTicketViews";
import { v4 as uuidv4 } from "uuid";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { RouteNames } from "src/containers/SideBar/SideBar";
import { TableView } from "src/hooks/useTicketViews/ticketViewsConfig";
import { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "src/store/store";

function TicketTabs() {
  const { tableType, viewType }: any = useParams();
  const { ticketViews } = useTicketViews();
  const navigate = useNavigate();
  // const dispatch = useAppDispatch();
  const currentUserData = useAppSelector(
    (state) => state.globals.currentUserData
  );
  const handleOnClick = (newView: TableView) => {
    navigate(
      `${RouteNames.tickets}/${tableType}/${newView.routeString}`.replace(
        "//",
        "/"
      ) + window.location.search
    );
  };

  return (
    <>
      <div
        className={`mx-2 mt-3 mt-md-5 d-flex flex-wrap ${styles.tabsMain}`}
        id="ticketTabs"
      >
        {currentUserData &&
          currentUserData.scopes &&
          ticketViews.map(
            (view: TableView) =>
              currentUserData.scopes?.includes(
                view.routeString.replace("-", "_") as any
              ) &&
              (currentUserData?.ui_visibility === undefined ||
                currentUserData?.ui_visibility?.includes(
                  view.visibilityKeyName as any
                )) && (
                <Tab
                  key={uuidv4()}
                  onClick={() => handleOnClick(view)}
                  tabIsActive={view.routeString === viewType}
                  ticketView={view}
                />
              )
          )}
      </div>
    </>
  );
}

const Tab = ({ tabIsActive, ticketView, ...props }: any) => {
  const style = {
    color: tabIsActive
      ? ticketView.tabIconColourActive
      : ticketView.tabIconColourInactive,
  };
  return (
    <button
      className={`max-content-width px-3 py-2 ${
        tabIsActive ? styles.active : ""
      } ${styles.ticketTabs}`}
      {...props}
      id={`${ticketView.viewName.toLowerCase().replace(" ", "_")}`}
    >
      <span className={`mx-1 `} style={style}>
        <i className={ticketView.tabIcon} />
      </span>
      {ticketView.viewName}
    </button>
  );
};

export default TicketTabs;
