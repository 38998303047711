/**
 * This file is the React component file for displaying a list of tickets or articles in a report.
 * It includes filters, pagination, and additional information.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 * @author Navjyot
 */

import Pagination from "src/routes/TicketList/children/Pagination/Pagination";
import styles from "./ReportTicketList.module.scss";
import useReportTicketList from "./hooks/useReportTicketList";
import { getChannelFAIcon } from "src/utils/utils";
import Loader from "src/components/Loader";
import FoundNoTickets from "src/components/FoundNoTickets";
import { CurrentStatusEndPoints } from "src/services/report/reportTicketList.service";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useMemo, useState } from "react";
import { ReportDateType } from "../../hooks/reportFilters/useReportInternalFilters";
import AxiosImg from "src/components/AxiosImg";
import UserAvatar from "src/components/UserAvatar";
import { v4 as uuid } from "uuid";

// Define the interface for the component's props
interface Props {
  endPoint: CurrentStatusEndPoints;
  reportDateType?: ReportDateType;
}

// Define the interface for agent details
interface AgentDetails {
  agentId?: string | number;
  agentImageURL?: string | null;
  agentName?: string;
  articleCount?: number;
}

// React component for displaying a list of tickets or articles in a report
const ReportTicketList = ({ endPoint, reportDateType }: Props) => {
  // Custom hook to fetch and manage data for the report ticket list
  const {
    reportTicketList,
    status,
    allSidebarFilters,
    allSidebarFilterIds,
    selectedSBFilterIds,
    selectAllSBFilters,
    checkUncheckSBFilters,
    currentPage,
    setCurrentPage,
    totalPages,
    handleTicketClick,
    isPreviousData,
    clearAllSBFilters,
  } = useReportTicketList({ endPoint, reportDateType });

  // State to control overlay visibility
  const [showOverlay, setShowOveray] = useState(false);

  // Added this on change of Daily / monthly / weekly filter need to clear previous sidebar filters
  useMemo(() => {
    clearAllSBFilters();
  }, [reportDateType]);
  // Apr 23 2024 Adding uuid to "all" checkbox to make labels unique
  const uuidAll = uuid();

  return (
    <div className={`${styles.ticketStatShow}`}>
      <div className="d-block d-md-flex">
        {/* Left Section: Filters */}
        <div className={`${styles.leftSection}`}>
          <div className={`${styles.allChannels}`}>
            {/* Checkbox for selecting all filters */}
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value="all"
                id={"report-list-flexCheckDefault" + uuidAll}
                checked={
                  selectedSBFilterIds.length === allSidebarFilterIds.length &&
                  allSidebarFilterIds.length !== 0
                }
                onChange={selectAllSBFilters}
              />
              {/* Label for selecting all filters */}
              <label
                className={`form-check-label ${styles.channelText} d-flex justify-content-between`}
                htmlFor={"report-list-flexCheckDefault" + uuidAll}
              >
                <div>
                  {/* Displaying the appropriate label based on the endpoint */}
                  {endPoint === "tickets/currentStatus/ticketsMostBackForth"
                    ? "Clusters"
                    : endPoint ===
                          "tickets/currentStatus/unresolvedTicketsPendingLongest" ||
                        endPoint.includes("performance") ||
                        endPoint.includes("totalChatReports") ||
                        endPoint.includes("missedChatsReports")
                      ? "Time Clusters"
                      : endPoint.includes("newArticlesAddedByTime") &&
                          endPoint.startsWith("knowledgeBase")
                        ? "Time"
                        : endPoint.includes("authorsMostArticles") &&
                            endPoint.startsWith("knowledgeBase")
                          ? "Agents"
                          : endPoint.includes("volumeActivity")
                            ? reportDateType === "daily"
                              ? "Daily"
                              : reportDateType === "monthly"
                                ? "Monthly"
                                : reportDateType === "weekly"
                                  ? "Weekly"
                                  : "Daily"
                            : "All Channels"}
                </div>
                {/* Additional information for specific endpoints */}
                {endPoint.includes("authorsMostArticles") &&
                  endPoint.startsWith("knowledgeBase") && (
                    <div>Number of Submissions</div>
                  )}
              </label>
            </div>
          </div>
          {/* List of filters */}
          <div
            style={{ height: "300px" }}
            className="overflow-auto"
          >
            {allSidebarFilterIds.map((id) => {
              const agentDetails = allSidebarFilters[id] as AgentDetails;
              // Apr 23 2024 Adding uuid as category id is not unique for each graph
              const categoryUUID = uuid();
              return (
                <div
                  key={id}
                  className={`${styles.timeWrapper}`}
                >
                  <div className="form-check">
                    <input
                      className="cursor-pointer form-check-input"
                      type="checkbox"
                      value={id}
                      id={"report-list-flexCheckDefault" + id + categoryUUID}
                      checked={selectedSBFilterIds.includes(id)}
                      onChange={checkUncheckSBFilters}
                    />
                    <label
                      className={`cursor-pointer form-check-label ${styles.rowText} d-flex justify-content-between`}
                      htmlFor={
                        "report-list-flexCheckDefault" + id + categoryUUID
                      }
                    >
                      {/* Displaying filter name and icon */}
                      <div>
                        {endPoint !==
                          "tickets/currentStatus/unresolvedTicketsPendingLongest" &&
                          endPoint !==
                            "tickets/currentStatus/ticketsMostBackForth" &&
                          !endPoint.startsWith("knowledgeBase") &&
                          !endPoint.includes("performance") &&
                          !endPoint.includes("totalChatReports") &&
                          !endPoint.includes("missedChatsReports") &&
                          !endPoint.includes("volumeActivity") && (
                            <i
                              className={`override me-1 ${getChannelFAIcon(
                                allSidebarFilters
                                  ? allSidebarFilters[id].name
                                  : undefined,
                                true,
                              )}`}
                            ></i>
                          )}
                        {allSidebarFilters &&
                        allSidebarFilters[id].name !== undefined ? (
                          allSidebarFilters[id].name
                        ) : endPoint.includes("authorsMostArticles") &&
                          agentDetails ? (
                          <>
                            {/* Displaying agent details with image or avatar */}
                            {agentDetails.agentImageURL ? (
                              <AxiosImg
                                url={agentDetails.agentImageURL}
                                className={`${styles.agentImg} me-2`}
                              />
                            ) : (
                              <UserAvatar
                                name={agentDetails.agentName}
                                size={100}
                                className={`${styles.agentImg} me-2`}
                              />
                            )}

                            {agentDetails.agentName}
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      {/* Additional information for specific endpoints */}
                      <div>
                        {endPoint.includes("authorsMostArticles") && (
                          <span className={`${styles.articleCount}`}>
                            {agentDetails.articleCount}
                          </span>
                        )}
                      </div>
                    </label>
                  </div>
                </div>
              );
            })}
          </div>
          {/* 19 April Label for  ticketsFullResolutionOverTime and ticketsFirstReplyOverTime*/}
          {(endPoint.includes("ticketsFullResolutionOverTime") ||
            endPoint.includes("ticketsFirstReplyOverTime")) && (
            <div className={`${styles.missedChatLabel} mt-1`}>
              <span>
                {endPoint.includes("ticketsFullResolutionOverTime")
                  ? `Median resolution time : ${
                      reportTicketList?.metaData?.medianFullResolutionTime ?? 0
                    }hrs`
                  : endPoint.includes("ticketsFirstReplyOverTime")
                    ? `Median first reply time : ${
                        reportTicketList?.metaData?.medianFirstReplyTime ?? 0
                      }hrs`
                    : ""}
              </span>
            </div>
          )}
          {/* 19 April Label for  metadata*/}
          {(endPoint.includes("missedChatsReports") ||
            endPoint.includes("backlogPercentage") ||
            endPoint.includes("averageTicketsGraph") ||
            endPoint.includes("ticketsFullResolutionOverTime") ||
            endPoint.includes("ticketsFirstReplyOverTime")) && (
            <div className={`${styles.missedChatLabel} mt-1`}>
              <span>
                {endPoint.includes("missedChatsReports")
                  ? `Missed Chats: ${
                      reportTicketList?.metaData?.totalCount ?? 0
                    }`
                  : endPoint.includes("backlogPercentage")
                    ? `Backlog percentage : ${
                        //Rounding to 2 decimal places when it is floating number
                        Number.isInteger(
                          reportTicketList?.metaData?.backlogPercentage ?? 0,
                        )
                          ? reportTicketList?.metaData?.backlogPercentage ?? 0
                          : (
                              reportTicketList?.metaData?.backlogPercentage ?? 0
                            ).toFixed(2)
                      }%`
                    : endPoint.includes("averageTicketsGraph")
                      ? `Average number of tickets : ${
                          //Rounding to 2 decimal places when it is floating number
                          Number.isInteger(
                            reportTicketList?.metaData
                              ?.averageNumberOfTicketsCreated ?? 0,
                          )
                            ? reportTicketList?.metaData
                                ?.averageNumberOfTicketsCreated ?? 0
                            : (
                                reportTicketList?.metaData
                                  ?.averageNumberOfTicketsCreated ?? 0
                              ).toFixed(2)
                        }`
                      : endPoint.includes("ticketsFirstReplyOverTime")
                        ? `Average first reply time : ${
                            reportTicketList?.metaData?.averageFirstReplyTime ??
                            0
                          }hrs`
                        : endPoint.includes("ticketsFullResolutionOverTime")
                          ? `Average resolution time : ${
                              reportTicketList?.metaData
                                ?.averageFullResolutionTime ?? 0
                            }hrs`
                          : ""}
              </span>
            </div>
          )}
        </div>

        {/* Right Section: Ticket/Article List */}
        <div className={`${styles.rightSection}`}>
          {/* Header for Tickets/Articles section */}
          <div className={`${styles.allArticles}`}>
            <span className={`ps-2 form-check-label ${styles.channelText}`}>
              {/* Displaying the appropriate label based on the endpoint */}
              {(endPoint.includes("newArticlesAddedByTime") ||
                endPoint.includes("authorsMostArticles")) &&
              endPoint.startsWith("knowledgeBase")
                ? "Articles"
                : endPoint.includes("totalChatReports") ||
                    endPoint.includes("missedChatsReports")
                  ? `Chats(${reportTicketList?.metaData.totalCount ?? 0})`
                  : endPoint.includes("volumeActivity") ||
                      endPoint.includes("performance")
                    ? `Tickets(${reportTicketList?.metaData.totalCount ?? 0})`
                    : "Tickets"}
            </span>
          </div>

          {/* Displaying the list of Tickets/Articles */}
          <div className={`${styles.msgWrapper} pb-2`}>
            {/* Checking loading status and displaying content accordingly */}
            {!isPreviousData &&
            status === "success" &&
            reportTicketList?.ticketIds.length ? (
              <>
                {/* Mapping through ticket/article IDs and displaying details */}
                {reportTicketList?.ticketIds.map((id: any) => {
                  const data = reportTicketList?.ticketData[id];
                  return (
                    <div
                      key={id}
                      className={`mb-3 position-relative ${
                        styles.chatMessage
                      } ${data.is_read ? styles.read : ""}`}
                      onClick={handleTicketClick}
                      data-ticket-id={id}
                      data-chat-id={data?.chatId}
                      data-chat-status={data?.customerStatus}
                    >
                      <div
                        key={id}
                        className={`${
                          (!data.article_description ||
                            data.article_description.length === 0) &&
                          (!data.last_message ||
                            data.last_message.length === 0) &&
                          (endPoint.includes("totalChatReports") ||
                          endPoint.includes("missedChatsReports")
                            ? !data.lastMessage ||
                              data.lastMessage?.length === 0
                            : true)
                            ? styles.hoverBarNew
                            : ""
                        } ${
                          (data.article_description &&
                            data.article_description.length > 0) ||
                          (data.last_message && data.last_message.length > 0) ||
                          (data.lastMessage && data.lastMessage?.length > 0)
                            ? styles.hoverBar
                            : ""
                        }`}
                      ></div>
                      <div>
                        <p className={`mb-0 ${styles.msgHead}`}>
                          {/* Displaying ticket subject or article name */}
                          {(endPoint.includes("newArticlesAddedByTime") ||
                            endPoint.includes("authorsMostArticles")) &&
                          endPoint.startsWith("knowledgeBase")
                            ? data.article_name
                            : endPoint.includes("totalChatReports") ||
                                endPoint.includes("missedChatsReports")
                              ? data.customer?.customerName
                              : data.ticket_subject}
                        </p>
                        {/* Displaying tooltip on hover with additional information */}
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            data.lastMessage ||
                            data.last_message ||
                            data.article_description ? (
                              <Tooltip className={`${styles.toolTipCustom}`}>
                                <div className={`${styles.tooltipBody}`}>
                                  {/* Displaying last message or article description */}
                                  {(endPoint.includes(
                                    "newArticlesAddedByTime",
                                  ) ||
                                    endPoint.includes("authorsMostArticles")) &&
                                  endPoint.startsWith("knowledgeBase")
                                    ? data.article_description
                                    : endPoint.includes("totalChatReports") ||
                                        endPoint.includes("missedChatsReports")
                                      ? data.lastMessage
                                      : data.last_message}
                                </div>
                              </Tooltip>
                            ) : (
                              <></>
                            )
                          }
                        >
                          {({ ref, ...triggerHandler }) => (
                            <span
                              {...triggerHandler}
                              ref={ref}
                            >
                              {/* Displaying last message or article description */}
                              <span
                                className={`${styles.msgSpan} ${styles.msgSpanBase}`}
                                onMouseOver={() => {
                                  setShowOveray(true);
                                }}
                                onMouseOut={() => {
                                  setShowOveray(false);
                                }}
                              >
                                <span>
                                  {(endPoint.includes(
                                    "newArticlesAddedByTime",
                                  ) ||
                                    endPoint.includes("authorsMostArticles")) &&
                                  endPoint.startsWith("knowledgeBase")
                                    ? data.article_description
                                    : endPoint.includes("totalChatReports") ||
                                        endPoint.includes("missedChatsReports")
                                      ? data.lastMessage
                                      : data.last_message}
                                </span>
                              </span>

                              {/* Last Message Attachments if any */}
                              {data.lastMessageAttachmentCount !== undefined &&
                                data.lastMessageAttachmentCount > 0 && (
                                  <span
                                    className={`d-block d-flex mt-2 max-content-width ${styles.attachments}`}
                                  >
                                    <i className="fa-solid fa-paperclip my-auto ms-2 me-0"></i>
                                    <span className="mx-2 my-auto">
                                      {`${
                                        data.lastMessageAttachmentCount
                                      } attachment${
                                        data.lastMessageAttachmentCount > 1
                                          ? "s"
                                          : ""
                                      }`}
                                    </span>
                                  </span>
                                )}
                            </span>
                          )}
                        </OverlayTrigger>
                      </div>
                    </div>
                  );
                })}
                {/* Pagination component */}
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  setCurrentPage={setCurrentPage}
                />
              </>
            ) : isPreviousData ||
              (!isPreviousData &&
                status === "loading" &&
                reportTicketList?.ticketIds.length === undefined) ? (
              // Loading indicator
              <Loader className={styles.loading} />
            ) : !isPreviousData &&
              status === "success" &&
              reportTicketList?.ticketIds.length === 0 ? (
              // No tickets or articles found
              <FoundNoTickets
                className={styles.notFound}
                text={
                  endPoint.startsWith("tickets")
                    ? "No Ticket Found"
                    : "No Articles Found"
                }
              />
            ) : (
              // Empty content for other cases
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportTicketList;
