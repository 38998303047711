import { useInfiniteQuery } from "@tanstack/react-query";
import { useCallback, useMemo, useState } from "react";
import Select, { GroupBase, StylesConfig } from "react-select";
import useDebounce from "src/hooks/useDebounce";
import { useCustomerSegments } from "src/routes/CustomerSegments/hooks/useCustomerSegments";
import getConditionSelectDDService from "src/services/CustomerSegments/getConditionSelectDD.service";
import { hasDuplicates } from "src/utils/utils";
import styles from "../../../UpdateSegmentDetails.module.scss";

interface Props {
  value: Array<string> | null | undefined;
  onChange: (value: Array<string | number>) => void;
  placeholder: string;
  error?: string;
  fieldKey: string;
}

interface Option {
  label: string;
  value: string;
}

const CONDITIONDD_LIMIT = 100;

/**
 * Custom hook to manage the state and data fetching for a searchable dropdown.
 *
 * @param fieldKey - The key representing the field for which data is being fetched.
 * @param debouncedSearch - The debounced search term.
 * @returns - The state and handlers for the searchable dropdown.
 */
const useSearchableDropdown = (fieldKey: string, debouncedSearch: string) => {
  const { segmentDataForUpdate } = useCustomerSegments();

  // Define the initial payload for the API request
  const initialPayload = useMemo(() => {
    return {
      fieldKey,
      segmentType: segmentDataForUpdate?.segmentType ?? "",
      searchText: debouncedSearch,
      limit: CONDITIONDD_LIMIT,
      start: 0,
    };
  }, [fieldKey, debouncedSearch, segmentDataForUpdate?.segmentType]);

  // Use react-query's useInfiniteQuery to handle data fetching with pagination
  const { data, isLoading, fetchNextPage, hasNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: [
        "getConditionSelectDDService",
        initialPayload,
        debouncedSearch,
      ],
      queryFn: ({ pageParam = initialPayload }) =>
        getConditionSelectDDService(pageParam),
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.data.length < initialPayload.limit) {
          return null;
        }

        const data = allPages.flatMap((page) => page.data);
        return {
          ...initialPayload,
          searchText: debouncedSearch,
          start: data.length,
        };
      },
      enabled: debouncedSearch.trim() !== "",
    });

  /**
   * Handler to fetch the next page of data
   */
  const fetchNextPageHandler = useCallback(() => {
    if (hasNextPage && !isFetching) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isFetching]);

  /**
   * Memoized transformation of API data into the options format for react-select
   */
  const apiOptions: Option[] = useMemo(() => {
    if (data) {
      return data.pages
        .flatMap((page) => page.data)
        .map((op) => ({
          value: op.key,
          label: op.name,
        }));
    }
    return [];
  }, [data]);

  return {
    apiOptions,
    isLoading: debouncedSearch.trim() !== "" && (isLoading || isFetching),
    fetchNextPageHandler,
  };
};

const SEPERATOR = "-::::-";

/**
 * Component for a searchable dropdown input using react-select and react-query.
 *
 * @param props - The properties for the component.
 * @returns - The JSX element for the searchable dropdown input.
 */
const SearchableDropdownInput = ({
  value,
  onChange,
  placeholder,
  error = "",
  fieldKey,
}: Props) => {
  const [search, setSearch] = useState<string>("");
  const debouncedSearch = useDebounce(search, 500);

  const { isLoading, fetchNextPageHandler, apiOptions } = useSearchableDropdown(
    fieldKey,
    debouncedSearch,
  );

  // Memoize the selected values to optimize performance
  const selectedValues: Option[] = useMemo(() => {
    if (!value) return [];
    return value.map(
      (val, index) =>
        ({ label: val, value: `${val}${SEPERATOR}${index}` }) as Option,
    );
  }, [value]);

  // Memoize the options to optimize performance
  const options = useMemo(
    () =>
      debouncedSearch
        ? apiOptions.map((op, index) => ({
            ...op,
            value: `${op.value}${SEPERATOR}${index}`,
          }))
        : [],
    [apiOptions, debouncedSearch],
  );

  /**
   * Handle the Enter key to add the search value to the selection
   */
  const onKeyDown: React.KeyboardEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      if (event.key === "Enter" && search.trim() !== "") {
        event.preventDefault();
        onChange(value ? [...value, search] : [search]);
        setSearch("");
      }
    },
    [onChange, search, setSearch, value],
  );

  // Conditionally show the error message
  const errorString = useMemo(() => {
    if (error) {
      if (!value || value.length === 0) {
        return error;
      }

      if (hasDuplicates(value, true)) {
        return "Please remove the duplicates";
      }
    }

    return null;
  }, [error, value]);

  return (
    <div>
      <Select
        options={options}
        value={selectedValues}
        isMulti={true}
        inputValue={search}
        onChange={(val) =>
          onChange(val.map((v) => v.value?.split(SEPERATOR)[0]))
        } // Extract original value
        onKeyDown={onKeyDown}
        // Enable this if need to add the search value when focus on dropdown is dropped
        // onBlur={() => {
        //   if (search.trim() !== "") {
        //     onChange(value ? [...value, search] : [search]);
        //   }
        // }}
        isSearchable={true}
        isLoading={isLoading}
        onMenuScrollToBottom={fetchNextPageHandler}
        placeholder={placeholder}
        onInputChange={(val, action) => {
          // This is needed so it doesn't clear the search input when menu is closed or loses focus
          if (
            action.action === "input-change" ||
            action.action === "set-value"
          ) {
            setSearch(val);
          }
        }}
        styles={customStyles}
        className={`${errorString && styles.errorBorder}`}
        noOptionsMessage={() => (
          <span>
            {!search ? "Start typing to search..." : "No result found"}
          </span>
        )}
      />
      {errorString ? (
        <span className={`${styles.errText}`}>{errorString}</span>
      ) : null}
    </div>
  );
};

/**
 * Custom styles for the react-select component.
 */
const customStyles: StylesConfig<Option, true, GroupBase<Option>> = {
  control: (provided, _) => ({
    ...provided,
    background: "#fff",
    borderColor: "#9e9e9e",
    minHeight: "37px",
    width: "100%",
    border: "1px solid #BBBBBB",
    borderRadius: "4px",
    font: "normal normal 500 12px/18px Poppins",
    letterSpacing: "0.4px",
    color: "#000000",
  }),

  valueContainer: (provided, _) => ({
    ...provided,
    minWidth: "180px",
    font: "normal normal 500 12px/18px Poppins",
    letterSpacing: "0.4px",
    color: "#000000",
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "#F5F5F5", // Light blue background
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    padding: "0px 3px",
    marginRight: "2px",
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: "#707070",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "unset",
      color: "#707070",
    },
  }),
  input: (provided, _) => ({
    ...provided,
    margin: "0px",
  }),
  dropdownIndicator: (provided, _) => ({
    ...provided,
    padding: "0px !important",
    display: "none",
  }),
  indicatorSeparator: (provided) => ({
    display: "none", // Hides the separator
  }),
  indicatorsContainer: (provided) => ({
    display: "none", // Hides the separator
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#FFF5F6" : "",
    color: state.isFocused ? "#000000" : "",
    padding: "8px 14px",
    "&:hover": {
      backgroundColor: "#FFF5F6",
      color: "#000000",
    },
    font: "normal normal 500 12px/18px Poppins",
    letterSpacing: "0.4px",
  }),
  noOptionsMessage: (provided, _) => ({
    ...provided,
    backgroundColor: "#FFF5F6",
    color: "#000000",
    padding: "8px 14px",
    "&:hover": {
      backgroundColor: "#FFF5F6",
      color: "#000000",
    },
    font: "normal normal 500 12px/18px Poppins",
    letterSpacing: "0.4px",
  }),
  loadingMessage: (provided, _) => ({
    ...provided,
    backgroundColor: "#FFF5F6",
    color: "#000000",
    padding: "8px 14px",
    "&:hover": {
      backgroundColor: "#FFF5F6",
      color: "#000000",
    },
    font: "normal normal 500 12px/18px Poppins",
    letterSpacing: "0.4px",
  }),
};

export default SearchableDropdownInput;
