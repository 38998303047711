import { axiosJSON } from "src/globals/axiosEndPoints";

export interface BrandData {
  id: string;
  name: string;
  email: string;
  imageURL: string | null;
}

export interface GetAllBrandData {
  brands: Record<string, BrandData>;
  brandIds: string[];
  metaData: {
    currentCount: number;
    totalCount: number;
  };
}

export interface GetLinkableBrandPayload {
  limit?: number;
  start?: number;
}

export async function getLinkableBrands(
  payload: GetLinkableBrandPayload,
): Promise<GetAllBrandData> {
  const { data: res } = await axiosJSON.post(
    `/api/setting/automation/refund/getLinkableBrands`,
    payload,
  );

  if (res.err || res.error) {
    throw new Error(
      res.msg ??
        res.message ??
        "Couldn't fetch available brands for linking automation",
    );
  }

  const ret: GetAllBrandData = {
    brands: {},
    brandIds: [],
    metaData: {
      currentCount: res.metaData.currentCount,
      totalCount: res.metaData.totalCount,
    },
  };

  res.data.forEach((brand: BrandData) => {
    ret.brands[brand.id + ""] = brand;
    ret.brandIds.push(brand.id + "");
  });

  return ret;
}
