import { axiosJSON } from "src/globals/axiosEndPoints";
import {
  BaseConfigModalTabType,
  GetConfigModalParams,
} from "src/features/ReturnAutoWorkFlow/children/ConfigModals/oldReturnModals.types";

export interface NonEligibleItemsTab extends BaseConfigModalTabType {
  notEligibleResponseMessage: string;
}

async function getNonEligibleItemsService(params: GetConfigModalParams) {
  const { data: res } = await axiosJSON.get(
    "/api/setting/automation/refund/step/10/configuration",
    { params },
  );

  if (res.err || res.error) {
    throw new Error(res.msg ?? res.message ?? "Some Error Occurred.");
  }

  return res.data as NonEligibleItemsTab[];
}

export default getNonEligibleItemsService;
