import { Fragment } from "react";
import ShowTopTimeBar from "src/components/ShowTopTimeBar";
import { Messages } from "src/services/LiveChat/messageExchange/getMessages";
import { isDaySame } from "src/utils/dateLibrary";
import MessageIn from "../../MessageIn/MessageIn";
import MessageInternalNote from "../../MessageInternalNote/MessageInternalNote";
import MessageOut from "../../MessageOut/MessageOut";
import ChatFormBox from "../ChatFormBox/ChatFormBox";
import MessageEvent from "../../MessageEvent";
import HistoryMessageIn from "../HistoryMessageIn/HistoryMessageIn";
import { HistoryMessageOut } from "../HistoryMessageOut/HistoryMessageOut";
import HistoryInternalNote from "../HistoryInternalNote/HistoryInternalNote";
import BotInternalNote from "../../BotInternalNote/BotInternalNote";
import LiveChatForwardMessage from "../LiveChatForwardMessage/LiveChatForwardMessage";

function renderAllMessages(
  messageListIds: (string | number)[],
  messages: Messages,
  isHistory = false,
  scrolledId?: string | number | null,
  scrollToRef?: React.MutableRefObject<any>,
) {
  if (messages) {
    return messageListIds.map((messageId, index) => {
      const message = messages[messageId];
      const msgPrev = messages[messageListIds[index - 1]];
      const ref = messageId + "" === scrolledId + "" ? scrollToRef : undefined;
      let timeComp = null as any;
      if (
        (msgPrev?.sendTimeGmt == undefined ||
          !isDaySame(
            msgPrev?.sendTimeGmt ? new Date(msgPrev?.sendTimeGmt) : null,
            message?.sendTimeGmt ? new Date(message?.sendTimeGmt) : null,
          )) &&
        isHistory === false
      ) {
        timeComp = (
          <ShowTopTimeBar
            className={`mb-4`}
            dateTimeUTC={message.sendTimeGmt}
            isHistory={isHistory}
          />
        );
      }

      if (message.isInternalNote && message?.senderType === "Bot") {
        return (
          <Fragment key={message.messageId}>
            {timeComp}
            <div
              ref={ref}
              className={`${isHistory ? "px-4" : ""}`}
            >
              <BotInternalNote {...message} />
            </div>
          </Fragment>
        );
      } else if (message.isInternalNote) {
        return (
          <Fragment key={message.messageId}>
            {timeComp}
            <div
              ref={ref}
              className={`${isHistory ? "px-4" : ""}`}
            >
              {isHistory ? (
                <HistoryInternalNote {...message} />
              ) : (
                <MessageInternalNote {...message} />
              )}
            </div>
          </Fragment>
        );
      } else if (message?.messageType == "chatForm") {
        if (
          message?.formDetails?.formData !== undefined &&
          message?.formDetails?.formData !== null
        ) {
          return (
            <Fragment key={message.messageId}>
              {timeComp}
              <div
                ref={ref}
                className={`${isHistory ? "px-4" : ""}`}
              >
                <ChatFormBox {...message} />
              </div>
            </Fragment>
          );
        } else {
          return null;
        }
      }
      if (message?.messageType == "forward") {
        return (
          <Fragment key={message.messageId}>
            {timeComp}
            <div
              ref={ref}
              className={`${isHistory ? "px-4" : ""}`}
            >
              <LiveChatForwardMessage {...message} />
            </div>
          </Fragment>
        );
      } else if (
        message?.isEvent === true ||
        message?.messageType === "event"
      ) {
        return (
          <Fragment key={message.messageId}>
            {timeComp}
            <div
              ref={ref}
              className={`${isHistory ? "px-4" : ""}`}
            >
              <MessageEvent {...message} />
            </div>
          </Fragment>
        );
      } else if (message.senderType === "Customer") {
        return (
          <Fragment key={message.messageId}>
            {timeComp}
            <div
              ref={ref}
              className={`${isHistory ? "px-4" : ""}`}
            >
              {isHistory ? (
                <HistoryMessageIn {...message} />
              ) : (
                <MessageIn {...message} />
              )}
            </div>
          </Fragment>
        );
      } else {
        return (
          <Fragment key={message.messageId}>
            {timeComp}
            <div
              ref={ref}
              className={`${isHistory ? "px-4" : ""}`}
            >
              {isHistory ? (
                <HistoryMessageOut {...message} />
              ) : (
                <MessageOut {...message} />
              )}
            </div>
          </Fragment>
        );
      }
    });
  } else {
    return null;
  }
}

export default renderAllMessages;
