import { axiosJSON } from "src/globals/axiosEndPoints";

export async function getBrandSignature() {
  const { data: res } = await axiosJSON.get("/api/setting/getBrandSignature");

  if (res.error === true) {
    throw new Error(res.message as string);
  }

  return res.data;
}

export interface BrandAndSignature {
  id: string | number;
  name: string;
  email: string;
  signature: string;
  enabled: boolean;
  imageUrl: string;
  isEmailActive: boolean;
  emailIntegrationId: string;
  isDefault: boolean;
  isActive: boolean;
  isVerified: boolean;
  imageURL: string | null;
  isPublicAttachmentUrl: boolean;
  disabledUserIds: string[];
  hasPermission: boolean;
}

export async function getBrands() {
  const { data: res } = await axiosJSON.get("/api/brand/getBrands");
  if (res.err) {
    throw new Error(res.msg);
  }
  return res.data as Array<BrandAndSignature>;
}
