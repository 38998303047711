import { Modal } from "react-bootstrap";
import NotFrequency from "../NotFrequency/NotFrequency";
import styles from "../../EditNotification.module.scss";
import { useState } from "react";
import { useShowFreqData } from "../ShowFreqData/ShowFreqData";

function ShowFrequency() {
  const { timeString } = useShowFreqData();

  const [showModal, setShowModal] = useState(false);

  // Function for show modal
  const showNotificationModal = () => {
    setShowModal(true);
  };
  // Function for hide modal
  const onHide = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className={`${styles.timeBox}`} onClick={showNotificationModal}>
        <span className={`pe-1 ${styles.userSvg}`}>
          <i className="fa-regular fa-clock"></i>
        </span>
        <span className={`pe-2 cursor-pointer w-90 text-truncate ${styles.sentMsg}`}>
          {timeString}
        </span>
        <span className={`${styles.downArrow}`}>
          <i className="fa-solid fa-caret-down"></i>
        </span>
      </div>
      <Modal
        backdropClassName={`${styles.modalBack}`}
        show={showModal}
        onHide={onHide}
        dialogClassName={`${styles.modalDialog}`}
        contentClassName={`${styles.modalContent}`}
        // centered={true}
        enforceFocus={false}
      >
        <NotFrequency onHide={onHide} />
      </Modal>
    </>
  );
}

export default ShowFrequency;
