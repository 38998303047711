import axios from "axios";
import { axiosJSON } from "src/globals/axiosEndPoints";
import APIResponse from "./APIReponse";

interface DuplicateRoutingRule {
  ruleId : number|string;
}

export interface DuplicateRoutingRuleParams {
  ruleId : number|string;
}



export async function duplicateRoutingRule (params : DuplicateRoutingRuleParams) {

    const { data:res } = await axiosJSON.post(
        "/api/chatSetting/routingRule/duplicate",{
        ...params
      }
    );

    if(res.error === true )
    {
      throw new Error(res.message as string)
    }

    return res.data as DuplicateRoutingRule 
  } 
