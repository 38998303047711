import {
  assignChat,
  IAssignChatParam,
  IAssignChatRes,
} from "src/services/LiveChat/traffic/assignChatFromTraffic.service";
import {
  getLiveChatTrafficList,
  ITrafficListParams,
  ITrafficListRes,
} from "src/services/LiveChat/traffic/getTrafficList.service";
import { RootState } from "src/store/store";
import axios from "axios";
import { AjaxError } from "src/types/AjaxError";
import {
  getLiveChatTrafficFullList,
  ITrafficUpdatedListRes,
} from "src/services/LiveChat/traffic/getTrafficFullList.service";

let cont: any = []; //controller list

const getCont = () => cont;

const fetchLiveChatTrafficDataThunk = async (
  payload: undefined,
  { getState, rejectWithValue }: { getState: Function; rejectWithValue: any }
) => {
  cont.forEach((c: any) => {
    c.abort();
  });
  const controller = new AbortController();
  cont.push(controller);

  try {
    const currentState: RootState = getState();

    const queryData: ITrafficListParams = {
      start: currentState.liveChatTraffic.trafficItemIdsList.length,
      limit: currentState.liveChatTraffic.trafficFetchLimit,
      type: currentState.liveChatTraffic.trafficFilterType,
    };

    const trafficData: ITrafficListRes = await getLiveChatTrafficList(
      queryData,
      controller.signal
    );

    return {
      trafficItemList: trafficData.trafficItemList,
      trafficItemIdsList: trafficData.trafficItemIdsList,
      trafficTypeTabCount: trafficData.trafficTypeTabCount,
      metaData: trafficData.metaData,
    };
  } catch (error) {
    if (axios.isCancel(error)) {
      //uncommented the below line as it is crashing the extraReducer with undefined return value
      return rejectWithValue(true);
    } else {
      let ajaxError: AjaxError = {
        message: (error as Error).message,
      };
      throw ajaxError;
    }
  } finally {
    cont = getCont().filter((c: any) => c !== controller);
  }
};

const fetchLiveChatTrafficFullDataThunk = async (
  payload: undefined,
  { getState, rejectWithValue, dispatch }: { getState: Function; rejectWithValue: any, dispatch: Function }
) => {
  cont.forEach((c: any) => {
    c.abort();
  });
  const controller = new AbortController();
  cont.push(controller);

  try {
    const currentState: RootState = getState();

    const trafficUpdatedData: ITrafficUpdatedListRes =
      await getLiveChatTrafficFullList(payload, controller.signal);

    return {
      tabCount: trafficUpdatedData.tabCount,
      trafficData: trafficUpdatedData.trafficData,
      currentState: currentState
    };
  } catch (error) {
    if (axios.isCancel(error)) {
      // return rejectWithValue(true);
    } else {
      let ajaxError: AjaxError = {
        message: (error as Error).message,
      };
      throw ajaxError;
    }
  } finally {
    cont = getCont().filter((c: any) => c !== controller);
  }
};

const assignLiveChatThunk = async (
  payload: IAssignChatParam,
  { getState }: { getState: Function }
) => {
  try {
    const assignChatData: IAssignChatRes = await assignChat(payload);

    return assignChatData;
  } catch (error) {
    throw error;
  }
};

export default {
  fetchLiveChatTrafficDataThunk,
  assignLiveChatThunk,
  fetchLiveChatTrafficFullDataThunk,
};
