import { BugAttachmentDetails } from "src/services/BugReport/getBugByCategory.service";
import styles from "./MediaPreview.module.scss";
import closeModal from "src/assets/images/close.png";
import { useCallback, useState } from "react";
import AxiosImg from "src/components/AxiosImg";
import UserAvatar from "src/components/UserAvatar";

interface MediaProps {
  media: BugAttachmentDetails[];
  onHide: () => void;
  senderName: string;
  senderImgUrl: string;
}

const MediaPreview = ({
  media,
  onHide,
  senderName,
  senderImgUrl,
}: MediaProps) => {
  const [attachmentIndex, setAttachmentIndex] = useState(0);

  const handlNextClick = useCallback(() => {
    setAttachmentIndex((index) => {
      if (index === 0) return media.length - 1;
      return index - 1;
    });
  }, [media.length]);

  const handlPrevClick = useCallback(() => {
    setAttachmentIndex((index) => {
      if (index === media.length - 1) return 0;
      return index + 1;
    });
  }, [media.length]);

  return (
    <div
      className={`${styles.container} d-flex justify-content-center align-items-center`}
    >
      {media.length > 1 && (
        <div
          className={`${styles.btnContainer} d-flex justify-content-between align-items-center`}
        >
          <i
            className="fa fa-chevron-left p-3 cursor-pointer"
            aria-hidden="true"
            onClick={handlPrevClick}
          ></i>
          <i
            className="fa fa-chevron-right p-3 cursor-pointer"
            aria-hidden="true"
            onClick={handlNextClick}
          ></i>
        </div>
      )}
      <div
        className={`d-flex justify-content-between ps-2 pe-5 w-100 ${styles.nameContainer}`}
      >
        <div className="ms-1">
          {senderImgUrl ? (
            <AxiosImg
              url={senderImgUrl}
              className={`${styles.profileIcon}`}
            />
          ) : (
            <UserAvatar
              className={`${styles.profileIcon}`}
              name={senderName}
              size={34}
            />
          )}
          <span className={styles.fileName}>
            {media[attachmentIndex].attachmentName}
          </span>
        </div>
        <img
          src={closeModal}
          width={12}
          height={12}
          className="cursor-pointer"
          onClick={onHide}
        />
      </div>
      {/* <div className={`d-flex`}> */}
      {media[attachmentIndex].attachmentType.split("/")[0] === "image" && (
        <img
          key={media[attachmentIndex].attachmentId}
          src={media[attachmentIndex].attachmentUrl}
          className={styles.attachementWidth}
        />
      )}
      {media[attachmentIndex].attachmentType.split("/")[0] === "video" && (
        <video
          id="videoPlayer"
          key={media[attachmentIndex].attachmentId}
          className={styles.attachementWidth}
          controls
        >
          <source
            src={media[attachmentIndex].attachmentUrl}
            type="video/mp4"
          />
          <source
            src={media[attachmentIndex].attachmentUrl}
            type="video/webm"
          />
          <source
            src={media[attachmentIndex].attachmentUrl}
            type="video/ogg"
          />
          Your browser does not support the video tag.
        </video>
      )}
      {/* </div> */}
    </div>
  );
};

export default MediaPreview;
