import styles from "./MissedChats.module.scss";
import { useReportFiltersCreate } from "../../hooks/reportFilters/useReportFilters";
import ReportBar from "src/components/svg/ReportBar";
import ReportDatePicker from "../ReportDatePicker/ReportDatePicker";
import { reportCurrentStatusActions } from "../../hooks/reportFilters/useReportFilters";
import FilterWrapper from "../FilterWrapper/FilterWrapper";
import MissedChatReports from "./Children/MissedChatReports/MissedChatReports";
import FiltersApplied from "../FiltersApplied/FiltersApplied";

const MissedChats = () => {
  const [state, dispatch, Provider] = useReportFiltersCreate();

  return (
    <Provider value={[state, dispatch]}>
      <div>
        <div
          className={`d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center `}
        >
          <div className={`d-flex align-items-center`}>
            <span>
              <ReportBar />
            </span>
            <span className={`ms-2 ${styles.heading}`}>Missed Chats</span>
          </div>
          <ReportDatePicker
            startDate={state.startDate}
            endDate={state.endDate}
            onChange={([startDate, endDate]) => {
              dispatch([reportCurrentStatusActions.setStartDate, startDate]);
              dispatch([reportCurrentStatusActions.setEndDate, endDate]);
            }}
          />
        </div>
        <div>
          <FilterWrapper scope="liveChat" />
          <FiltersApplied
            endPoint={"liveChat/missedChats/missedChatsReports"}
            showInternalFilters={false}
            isGlobalFilter={true}
          />
          <MissedChatReports />
        </div>
      </div>
    </Provider>
  );
};

export default MissedChats;
