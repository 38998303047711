import { axiosJSON } from "src/globals/axiosEndPoints";
import { AJAXSTATUS } from "src/globals/constants";
import { Customer } from "./getAllCustomers";
import { getCustomerByChatId } from "./getCustomerByChatId";
import { IntegrationFailureMetaData } from "src/types/IntegrationFailure";

export interface LiveMEssageSender {
  // In this interface, I've added the undefined type to the properties
  id: number | string | undefined;
  name: string | undefined;
  email: string | undefined;
  imageURL: string | undefined | null;
  isPublicAttachmentUrl?: boolean;
}

export interface MessageFormItem {
  key: string;
  label: string;
  value: string;
  type?: string;
}

interface MessageEventDetails {
  data: {
    integrationFailure?: IntegrationFailureMetaData; // For integration failure
    ticketId?: string;
  };
  eventText: string;
  type:
    | "event_chat_note_added"
    | "event_chat_ticket_created"
    | "event_chat_resolved";
}

export interface Message {
  messageId: string | number;
  messageText: string;
  sentBy: LiveMEssageSender;
  sendTime: string;
  sendTimeGmt: string;
  isEvent: boolean;
  sentByType?: "in" | "out";
  senderType: "Agent" | "Customer" | "Bot";
  isInternalNote: boolean;
  messageType?:
    | "chatForm"
    | "message"
    | "event"
    | "note"
    | "botMessage"
    | "forward";
  eventDetails?: MessageEventDetails;
  formDetails?: {
    formType: "preChat" | "postChat" | "ticketForm" | "askForEmail";
    formName: string;
    formData?: Array<MessageFormItem>;
  };
  attachments?: Array<any>;
  messageUuid?: string | null; //unique id for message, used for try again feature in send message
  messageStatus?: AJAXSTATUS; //message sent status, only used on frontend to identify if message is sent (try again feature)
  scheduled?: {
    scheduledAgent: LiveMEssageSender;
    createdAtUTC: string;
    scheduledToUTC: string;
  };
  messageSeenStatus: boolean; //message seen status, only used on frontend to identify if message is seen
  messageChannel?: string;
  from?: {
    name: string;
    email: string;
  };
  to?: {
    name: string;
    email: string;
  };
  integrationFailure?: IntegrationFailureMetaData; // For integration failure
}

export interface Messages {
  [messageId: string | number]: Message;
}

export interface MessageReturnType {
  messages: Messages;
  messageIdList: Array<number | string>;
  metaData: {
    waitingForFromComplete: boolean; //flag which denotes whether we have to show loader in the messages section
    limitReachedOld?: boolean;
    limitReachedNew?: boolean;
    unreadMentionMessageId?: string | number;
  };
  activeCustomerInfo: Customer | null;
}

export interface GetLiveMessagePayload {
  chatRequestId: string | number;
  sortBy?: "asc" | "desc";
  lastMessageId?: number | string;
  scrollDirection?: "old" | "new";
  limit: number | string;
  getActiveCustomer?: boolean;
  context?: string;
  tabContext: string;
  filters?: {
    limitToUnreadMention?: boolean;
  };
}

export async function getAllMessages(
  payload: GetLiveMessagePayload,
  signal?: any,
) {
  try {
    const { data, status } = await axiosJSON.post(
      `/api/liveChat/agent/fetchLiveChatMessagesV2`,
      payload,
      { signal },
    );
    if (data.err === true) {
      throw new Error(data.msg as string);
    }

    const normalizedData = normalizeData(
      data.data.messageData,
      data.metaData,
      data.data.customerData,
      parseInt(payload.limit + ""),
      payload.scrollDirection,
    );
    // console.log(normalizedData);
    return normalizedData;
  } catch (err) {
    throw err;
  }
}

function normalizeData(
  messageData: any,
  meta: any,
  activeCustomerInfo: any,
  limit: number,
  scrollDirection: "old" | "new" | undefined,
) {
  const messages: Messages = {};
  const messageIdList: Array<number | string> = [];

  messageData.map((message: Message) => {
    const msgId = parseInt(message.messageId + "");
    messageIdList.push(msgId);
    messages[msgId] = message;
  });

  const metaData: MessageReturnType["metaData"] = {
    waitingForFromComplete: meta.waitingForFromComplete ?? false,
    unreadMentionMessageId: meta.unreadMentionMessageId,
  }; //"waitingForFromComplete" which denotes whether we have to show loader in the messages section

  // Checking if payload limit is there then checking if limit is reached.
  if (!isNaN(limit)) {
    if (messageIdList.length < limit) {
      if (scrollDirection === "old") {
        metaData.limitReachedOld = true;
      } else if (scrollDirection === "new") {
        metaData.limitReachedNew = true;
      } else {
        metaData.limitReachedOld = true;
        metaData.limitReachedNew = true;
      }
    }
  }

  return {
    messages,
    messageIdList,
    metaData,
    activeCustomerInfo,
  } as MessageReturnType;
}
