import { MouseEventHandler, useCallback, useEffect, useState } from "react";
import { BiErrorCircle } from "react-icons/bi";

import { useAppDispatch, useAppSelector } from "src/store/store";
import { fetchVerifyEmailForwarding } from "src/store/slices/emailIntegration/emailIntegration.thunks";
import { VerifyEmailEvent } from "src/pusherServices/emailIntegration/verifyEmail";

import styles from "../AddModal.module.scss";

interface Props {
  verifyText: string;
  setupName: "domain" | "email forwarding";
  onHide: () => void;
  showVerifyModal?: (bool: boolean) => void;
}

/**
 * Modal Component for showing Couldn't verify Setup in Email integration
 */
function CouldntVerifyModal({
  onHide,
  showVerifyModal,
  verifyText,
  setupName,
}: Props) {
  const dispatch = useAppDispatch();

  const emailIntegrationId = useAppSelector((state) => {
    return state.emailIntegration.selectedUser.emailIntegrationId;
  });

  const channelName = useAppSelector((state) => {
    return state.emailIntegration.selectedUser.emailIntegrationChannel;
  });

  const onVerifiedEmailCallback = useCallback((event: VerifyEmailEvent) => {
    console.log({ event });
  }, []);

  const navigateCallback = useCallback(() => {}, []);

  const [errorMessage, setErrorMessage] = useState(
    `We recommend that you go thorough instructions and check whether the ${setupName} setup is done correctly.`,
  );

  /**
   * Effect for updating error message from url
   */
  useEffect(() => {
    // Get Search params
    const searchparams = new URLSearchParams(window.location.search);
    // Get message from params
    const message = searchparams.get("message");

    // If we have an error message
    if (message) {
      // Update the error message shown
      setErrorMessage(message);

      // Clear browser history state
      // Delete the error message from URL
      searchparams.delete("message");

      // Get the updated search string
      const searchString = searchparams.toString();

      // Update history state with updated search string if it exists
      window.history.pushState(
        {},
        "",
        window.location.pathname + (searchString ? "?" + searchString : ""),
      );
    }
  }, []);

  /**
   * Handler for action button
   */
  const clickHandler: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.preventDefault();
      // If Email forwarding
      if (setupName === "email forwarding") {
        // Retry verification
        dispatch(
          fetchVerifyEmailForwarding({
            forwardingParams: {
              emailIntegrationId: emailIntegrationId,
            },
            eventParams: {
              callback: onVerifiedEmailCallback,
              navigateCallback: navigateCallback,
              channelName: channelName,
            },
          }),
        );
        // Show Verify Modal
        if (showVerifyModal) {
          showVerifyModal(true);
        }
      }
      // dispatch(fetchResendVerificationCode({ emailIntegrationId: 1 }));

      // Hide the coudn't verify modal
      onHide();
    },
    [
      channelName,
      dispatch,
      emailIntegrationId,
      navigateCallback,
      onHide,
      onVerifiedEmailCallback,
      setupName,
      showVerifyModal,
    ],
  );

  return (
    <div className=" rounded d-flex-column justify-content-center">
      {/* Error Circle */}
      <div className="d-flex justify-content-center">
        <BiErrorCircle className="text-danger fs-1 mt-3" />
      </div>

      {/* Heading Text */}
      <div className={`d-flex justify-content-center ${styles.headText} `}>
        {`We couldn’t verify ${verifyText}`}
      </div>

      {/* Sub Heading Text */}
      <div
        className={`d-flex justify-content-center m-3 text-center ${styles.subText}`}
      >
        {errorMessage}
      </div>

      {/* Action Button */}
      <div className="d-flex justify-content-center ">
        {/* <div className={`  `}>
          <button className={`m-1 ${styles.blueBtn} p-2 `}>Find code</button>
        </div> */}
        <div className="">
          <button
            className={`m-1 mb-3 ${
              setupName === "domain" ? styles.blueBtn : styles.verifyBtn
            } p-2`}
            onClick={clickHandler}
          >
            {setupName === "domain" ? "Ok" : "Re-send verification code"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default CouldntVerifyModal;
