import { NavLink, useParams } from "react-router-dom";
import livelogo from "src/assets/images/live.gif";
import styles from "../../LiveChat.module.scss";
import ChatSetting from "../ChatSetting/ChatSetting";
import NewAnnouncement from "../ChatSetting/Children/Engagement/TargetedMessages/NewTargetedMessage/NewTargetedMessage";
import MainChat from "../MainChat";
import TrafficTable, {
  TrafficFilterTabNames,
} from "../TrafficTable/TrafficTable";

export const TabNamesLC: {
  [key: string]: { name: string; component: any };
} = {
  chats: { name: "chats", component: MainChat },
  traffic: { name: "traffic", component: TrafficTable },
  analytics: { name: "analytics", component: null },
  settings: { name: "settings", component: ChatSetting },
};

function TabSection() {
  const tabName = window.location.pathname.split("/")[2];
  return (
    <div className={`${styles.btnWrapper}`}>
      <NavLink
        to={`/live-chat/${TabNamesLC.chats.name}`}
        className={`btn ${styles.liveChatBtn} ${
          tabName === TabNamesLC.chats.name ? styles.active : ""
        }`}
      >
        <span>
          <img className={`${styles.liveLogo}`} src={livelogo} alt="live" />
        </span>
        Live chat
      </NavLink>
      {/* <NavLink
        to={`/live-chat/${TabNamesLC.traffic.name}/${TrafficFilterTabNames.allCustomers.name}`}
        className={`btn ${styles.liveChatBtn} ${
          tabName === TabNamesLC.traffic.name ? styles.active : ""
        }`}
      >
        <span className={`me-1`}>
          <i className="fa-solid fa-network-wired"></i>
        </span>
        Traffic
      </NavLink> */}
      {/* <NavLink
        to={`/live-chat/${TabNamesLC.analytics.name}`}
        className={`btn ${styles.liveChatBtn} ${
          tabName === TabNamesLC.analytics.name ? styles.active : ""
        }`}
      >
        <span className={`me-1`}>
          <i className="fa-solid fa-chart-line"></i>
        </span>
        Analytics
      </NavLink> */}
      <NavLink
        to={`/live-chat/${TabNamesLC.settings.name}`}
        className={`btn ${styles.liveChatBtn} ${
          tabName === TabNamesLC.settings.name ? styles.active : ""
        }`}
      >
        <span className={`me-1`}>
          <i className="fa-solid fa-gear"></i>
        </span>
        Chat settings
      </NavLink>
    </div>
  );
}

export default TabSection;
