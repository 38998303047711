import { useAppSelector } from "src/store/store";
import ReportTicketBody from "../../../ReportTicketBody/ReportTicketBody";

const PerformanceReports = () => {
  const ui_visibility = useAppSelector(
    (state) => state.globals.currentUserData?.ui_visibility,
  );

  return (
    <div className="mb-2">
      {/* ticketsFirstReply */}
      {ui_visibility?.includes("tickets_performance_ticketsFirstReply") && (
        <ReportTicketBody
          endPoint={"tickets/performance/ticketsFirstReply"}
          title={"Tickets by first reply time brackets"}
          tooltip={
            "This graph shows the number of tickets created that received their first reply within the specified time brackets."
          }
          tabProps={{
            showComparison: false,
            showDailyPart: false,
            showListView: true,
            showNotifyTab: false,
          }}
        />
      )}
      {/* ticketsFullResolution */}
      {ui_visibility?.includes("tickets_performance_ticketsFullResolution") && (
        <ReportTicketBody
          endPoint={"tickets/performance/ticketsFullResolution"}
          title={"Tickets by full resolution time brackets"}
          tooltip={
            "This graph displays the number of tickets created that fall within the resolution duration time brackets shown. Resolution time is defined as the period between when a ticket is created and when its latest status is recorded as closed."
          }
          tabProps={{
            showComparison: false,
            showDailyPart: false,
            showListView: true,
            showNotifyTab: false,
          }}
        />
      )}
      {/* ticketsFirstReplyOverTime */}
      {ui_visibility?.includes(
        "tickets_performance_ticketsFirstReplyOverTime",
      ) && (
        <ReportTicketBody
          endPoint={"tickets/performance/ticketsFirstReplyOverTime"}
          title={"First reply time (median and avg) over time"}
          tooltip={
            "This graph illustrates the median and average time from the creation of the first ticket to the moment the first reply is sent after the ticket is created."
          }
          tabProps={{
            showComparison: false,
            showDailyPart: true,
            showListView: true,
            showNotifyTab: false,
          }}
        />
      )}
      {/* ticketsFullResolutionOverTime */}
      {ui_visibility?.includes(
        "tickets_performance_ticketsFullResolutionOverTime",
      ) && (
        <ReportTicketBody
          endPoint={"tickets/performance/ticketsFullResolutionOverTime"}
          title={"Full resolution time (median and avg)"}
          tooltip={
            "This graph displays the median and average time from the creation of the first ticket to the moment the ticket is last recorded as closed."
          }
          tabProps={{
            showComparison: false,
            showDailyPart: true,
            showListView: true,
            showNotifyTab: false,
          }}
        />
      )}
      {/* averageTicketRating */}
      {ui_visibility?.includes("tickets_performance_averageTicketRating") && (
        <ReportTicketBody
          endPoint={"tickets/performance/averageTicketRating"}
          title={"Average Ticket Rating"}
          tooltip={"This graph displays the average ticket rating."}
          tabProps={{
            showComparison: false,
            showDailyPart: true,
            showListView: false,
            showNotifyTab: false,
          }}
        />
      )}
    </div>
  );
};

export default PerformanceReports;
