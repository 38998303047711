import { useContext, useEffect, useRef, useState } from "react";
import styles from "./Order.module.scss";
import OrderDetailsModal from "./children/OrderDetailsModal";
import { ModalContext, ModalProvider } from "../Modal/ModalContext";
import { IShopifyOrders } from "src/store/slices/shopifySidebar/shopifySidebar.slice";
import { Button, OverlayTrigger, Popover, Spinner } from "react-bootstrap";
import OrderDetailTrackingModal from "./children/OrderTracking/OrderDetailTrackingModal";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { fetchGetTrackingData } from "src/store/slices/singleOrder/order.thunks";

interface Props {
  order: IShopifyOrders;
}

function OrderContext({ order }: Props) {
  const modalContext = useContext(ModalContext);
  const dispatch = useAppDispatch();
  const { trackingData, trackingDataAjaxStatus } = useAppSelector(
    (state) => state.singleOrder.getTrackingData,
  );

  const [OrderPlaced, setOrderPlaced] = useState<any>();
  const [OrderFulfilled, setOrderFulfilled] = useState<any>();
  const [Shipped, setShipped] = useState<any>();
  const [Delivered, setDelivered] = useState<any>();
  const [Return, setReturn] = useState<any>();
  const [Refund, setRefund] = useState<any>();
  const [latestStatusKey, setLatestStatusKey] = useState<string>("");

  // let timeOut: NodeJS.Timeout;
  let timeOut = useRef<any>(null);

  useEffect(() => {
    if (
      trackingData &&
      trackingData?.trackingDetails &&
      trackingDataAjaxStatus === "fulfilled"
    ) {
      for (let i = 0; i < trackingData?.trackingDetails?.length; i++) {
        if (trackingData?.trackingDetails[i].key === "orderPlaced") {
          setOrderPlaced(trackingData?.trackingDetails[i]);
        } else if (trackingData?.trackingDetails[i].key === "orderFulfilled") {
          setOrderFulfilled(trackingData?.trackingDetails[i]);
        } else if (trackingData?.trackingDetails[i].key === "orderShipped") {
          setShipped(trackingData?.trackingDetails[i]);
        } else if (trackingData?.trackingDetails[i].key === "orderDelivered") {
          setDelivered(trackingData?.trackingDetails[i]);
        } else if (trackingData?.trackingDetails[i].key === "orderReturn") {
          setReturn(trackingData?.trackingDetails[i]);
        } else if (trackingData?.trackingDetails[i].key === "orderRefund") {
          setRefund(trackingData?.trackingDetails[i]);
        }
      }

      if (
        trackingData?.trackingDetails[trackingData?.trackingDetails?.length - 1]
      ) {
        let latestStatus =
          trackingData?.trackingDetails[
            trackingData?.trackingDetails?.length - 1
          ];
        setLatestStatusKey(latestStatus.key);
      }
    }
  }, [trackingData]);

  const getStatusDate = (statusDate: string) => {
    let date = new Date(statusDate).toLocaleString("en-us", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
    let splittedDate = date.split(",");
    return (
      <>
        {splittedDate[0].trim() + ","}
        <br />
        {splittedDate[1].trim()}
      </>
    );
  };

  const popover = (
    <Popover
      className={`${styles.popOver}`}
      onMouseEnter={() => {
        setPopOverState(true);
      }}
      onMouseLeave={() => {
        setPopOverState(false);
      }}
    >
      <div className={`${styles.trackingPopoverMainDiv}`}>
        {trackingDataAjaxStatus === "pending" ? (
          <Spinner
            className={`m-auto ${styles.popupLoader}`}
            animation="border"
            size="sm"
            color="grey"
          />
        ) : (
          <div className={`d-flex flex-column m-2`}>
            <div
              className={`d-flex justify-content-center ${styles.popoverHead}`}
            >
              Order Tracking Details
            </div>
            <div
              className={`d-flex justify-content-center mt-2 ${styles.trackingTimeLineFont}  `}
            >
              <span
                className={`${styles.paymentDetail} ${styles.trackingTimeLineFont}`}
              >
                Shipped By :
              </span>{" "}
              <span className={`h6 ${styles.shipName} `}>
                &nbsp;{" "}
                {trackingData?.shippedBy ? (
                  <>
                    {trackingData?.shippedBy?.company} -{" "}
                    {trackingData?.shippedBy?.number}
                  </>
                ) : (
                  "NA"
                )}
              </span>
            </div>
            <div
              className={`d-flex flex-column mt-3 ${styles.trackingTimeLineFontTerms} pe-2`}
            >
              <div className="d-flex  fw-bold ">
                <div className=" lh-1 me-3">
                  Order <br /> placed
                </div>
                <div className=" lh-1 ms-2 me-3">
                  Ful <br /> Fillment
                </div>
                <div className="me-3">Shipped</div>
                <div className="me-3">Delivered</div>
                <div className="me-1">Refunded</div>
              </div>

              <div
                className={`d-flex w-100  justify-content-between  position-relative mt-2   `}
              >
                <div
                  className={`${
                    latestStatusKey === "orderPlaced"
                      ? styles.greenDotActive
                      : latestStatusKey === "orderFulfilled" ||
                          latestStatusKey === "orderShipped" ||
                          latestStatusKey === "orderDelivered" ||
                          latestStatusKey === "orderReturn" ||
                          latestStatusKey === "orderRefund"
                        ? styles.greenDot
                        : styles.greyDot
                  } ms-2  `}
                ></div>

                {latestStatusKey === "orderFulfilled" ||
                latestStatusKey === "orderShipped" ||
                latestStatusKey === "orderDelivered" ||
                latestStatusKey === "orderReturn" ||
                latestStatusKey === "orderRefund" ? (
                  <div className={`${styles.greenLineFirst}`}></div>
                ) : (
                  <div className={`${styles.greyLineFirst}`}></div>
                )}
                <div
                  className={`${
                    latestStatusKey === "orderFulfilled"
                      ? styles.greenDotActive
                      : latestStatusKey === "orderShipped" ||
                          latestStatusKey === "orderDelivered" ||
                          latestStatusKey === "orderReturn" ||
                          latestStatusKey === "orderRefund"
                        ? styles.greenDot
                        : styles.greyDot
                  } ms-2 `}
                ></div>

                {latestStatusKey === "orderShipped" ||
                latestStatusKey === "orderDelivered" ||
                latestStatusKey === "orderReturn" ||
                latestStatusKey === "orderRefund" ? (
                  <div className={`${styles.greenLineSecond}`}></div>
                ) : (
                  <div className={`${styles.greyLineSecond}`}></div>
                )}

                <div
                  className={`${
                    latestStatusKey === "orderShipped"
                      ? styles.greenDotActive
                      : latestStatusKey === "orderDelivered" ||
                          latestStatusKey === "orderReturn" ||
                          latestStatusKey === "orderRefund"
                        ? styles.greenDot
                        : styles.greyDot
                  }  `}
                ></div>

                {latestStatusKey === "orderDelivered" ||
                latestStatusKey === "orderReturn" ||
                latestStatusKey === "orderRefund" ? (
                  <div className={`${styles.greenLineThird}`}></div>
                ) : (
                  <div className={`${styles.greyLineThird}`}></div>
                )}

                <div
                  className={`${
                    latestStatusKey === "orderDelivered" ||
                    latestStatusKey === "orderReturn" ||
                    latestStatusKey === "orderReturn"
                      ? styles.greenDotActive
                      : latestStatusKey === "orderRefund"
                        ? styles.greenDot
                        : styles.greyDot
                  } `}
                ></div>

                {latestStatusKey === "orderRefund" ? (
                  <div className={`${styles.redLineFourth}`}></div>
                ) : (
                  <div className={`${styles.greyLineFourth}`}></div>
                )}

                <div
                  className={`${
                    latestStatusKey === "orderRefund"
                      ? styles.redDotActive
                      : styles.greyDot
                  } me-2`}
                ></div>
              </div>

              <div className="d-flex w-100    mt-1 ">
                <div className={` lh-1 ms-2 ${styles.width60Px}`}>
                  {OrderPlaced && OrderPlaced?.createdAt
                    ? getStatusDate(OrderPlaced.createdAt.toString())
                    : "-"}
                </div>
                <div className={` lh-1 ms-2 ${styles.width60Px}`}>
                  {OrderFulfilled && OrderFulfilled?.createdAt
                    ? getStatusDate(OrderFulfilled.createdAt.toString())
                    : "-"}
                </div>
                <div className={` lh-1 ms-2 ${styles.width60Px}`}>
                  {Shipped && Shipped?.createdAt
                    ? getStatusDate(Shipped.createdAt.toString())
                    : "-"}
                </div>
                <div
                  className={` lh-1`}
                  style={{
                    minWidth: "50px",
                  }}
                >
                  {Delivered && Delivered?.createdAt
                    ? getStatusDate(Delivered.createdAt.toString())
                    : "-"}
                </div>
                <div
                  className={` lh-1`}
                  style={{
                    minWidth: "50px",
                    paddingRight: "20px",
                  }}
                >
                  {Refund && Refund?.createdAt
                    ? getStatusDate(Refund.createdAt.toString())
                    : "-"}
                </div>
              </div>
            </div>

            <div className={`d-flex justify-content-center   mt-4`}>
              <Button
                className={`border-0 bg-white text-primary ${styles.trackingTimeLineFontTerms}`}
                onClick={() => {
                  modalContext.handleModal(
                    <OrderDetailTrackingModal getTrackingData={trackingData} />,
                  );
                  setPopOverState(false);
                }}
              >
                SEE MORE <i className="fa-solid fa-angle-down"></i>
              </Button>
            </div>
          </div>
        )}
      </div>
    </Popover>
  );

  const [popOverState, setPopOverState] = useState(false);

  return (
    <OverlayTrigger
      rootClose
      show={popOverState}
      placement="left"
      overlay={popover}
    >
      <div
        className={`${styles.order} p-2 my-2`}
        onClick={() =>
          modalContext.handleModal(
            <OrderDetailsModal orderId={order.orderId} />,
          )
        }
        onMouseEnter={() => {
          // if(getTrackingData && getTrackingData?.orderId && getTrackingData?.orderId !== order?.orderId)
          timeOut.current = setTimeout(() => {
            setOrderPlaced(null);
            setOrderFulfilled(null);
            setShipped(null);
            setDelivered(null);
            setReturn(null);
            setRefund(null);
            dispatch(fetchGetTrackingData({ orderId: order?.orderId }));
            setPopOverState(true);
          }, 998);
        }}
        onMouseLeave={() => {
          if (timeOut.current) {
            clearTimeout(timeOut.current);
          }
          setPopOverState(false);
        }}
      >
        <div className={`${styles.orderNo}`}>Order {order.orderName}</div>
        <div className={`${styles.time} d-flex`}>
          <i className="fa-regular fa-clock my-auto mx-0"></i>
          <span className={`my-auto mx-1`}>
            {new Date(order?.createdAt).toLocaleString()}
          </span>
        </div>
        {order && order.orderItems?.length > 0 && (
          <div className={`${styles.detail} d-flex my-2`}>
            <img
              src={order?.orderItems[0]?.image?.url}
              className={`mt-0 mx-0 ${styles.img}`}
              alt=""
            />
            <div className={`mt-0 mx-2`}>
              <div
                className={`${styles.name}`}
              >{`${order?.orderItems[0]?.quantity}x ${order?.orderItems[0]?.itemName}`}</div>
              <div className={`${styles.desc}`}>
                SKU: {order?.orderItems[0]?.sku}
              </div>
              {/* Showint total item count */}
              {order?.totalItemCount > 1 && (
                <div className={`${styles.name}`}>
                  {`+${order?.totalItemCount - 1} more items`}
                </div>
              )}
              {/* Showing total price */}
              <div className={`${styles.price}`}>{`${
                order?.totalAmount?.currencyCode ?? ""
              } ${order?.totalAmount?.amount ?? ""}`}</div>
            </div>
          </div>
        )}
        {/* Following div is show if there is multiple orders  */}
        {order?.orderItems?.length > 2 && (
          <div>
            <span className={`${styles.moreItems}`}> …+3 more items</span>
          </div>
        )}
        <div className={`${styles.status} my-2`}>
          <div className={`${styles.div} d-flex my-1 p-1`}>
            <div className={`${styles.statusPoint}`}>
              <i className={`fa-solid fa-circle`}></i>{" "}
            </div>
            <span className={`my-auto mx-1`}>{order?.paymentStatus}</span>
            {/* <span className={`my-auto mx-1`}>2d ago</span> */}
          </div>
          <div className={`${styles.div} d-flex my-1 p-1`}>
            <div className={`${styles.statusPoint}`}>
              <i className={`fa-solid fa-circle`}></i>{" "}
            </div>
            <span className={`my-auto mx-1`}>{order?.fulfillmentStatus}</span>
            {/* <span className={`my-auto mx-1`}>2d ago</span> */}
          </div>
          {/* <div className={`${styles.div} d-flex my-1 p-1 ${styles.green}`}>
            <i className={`fa-solid fa-circle my-auto mx-1`}></i>
            <span className={`my-auto mx-1`}>Delivered</span>
            <span className={`my-auto mx-1`}>2d ago</span>
          </div> */}
        </div>
      </div>
    </OverlayTrigger>
  );
}

function Order(props: Props) {
  return (
    <ModalProvider>
      <OrderContext {...props} />
    </ModalProvider>
  );
}

export default Order;
