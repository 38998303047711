function useDeleteArticle({handleConfirm, articleId, sendArticleId}: any) {
  const handleSubmit = () => {
    if (sendArticleId === true) {
      handleConfirm(articleId);
    } else {
      handleConfirm();
    }
  };

  return {
    handleSubmit,
  };
}

export default useDeleteArticle;
