import { axiosJSON } from "src/globals/axiosEndPoints";

interface Params {
  message: string;
}

/**
 * Service for sending contact sales request
 */
const contactUsService = async (params: Params) => {
  const { data: res } = await axiosJSON.post(`/api/billing/contactUs`, params);

  // Handle error scenarios in the response.
  if (res.err || res.error) {
    // Throw an error with the message from the response.
    throw new Error(res.msg || res.message || "Something Unexpected occured!");
  }
};

export default contactUsService;
