import { useMemo, useState } from "react";
import { Dropdown } from "react-bootstrap";
import InfiniteScroll from "src/components/InfiniteScrollBothSide";
import Loader from "src/components/Loader";
import {
  useFetchReturnProducts,
  useFetchReturnProductTags,
} from "src/features/ReturnAutoWorkFlow/hooks/useFetchReturnConditions";
import { RefundScenario } from "../../../../../store/slices/AntiRefundStrategiesSlice/antiRefundStrategies.slice";
import styles from "../Scenario.module.scss";

interface Props {
  scenario: RefundScenario;
  showSpecificEleModalBtn: () => void;
}

const AllItemsComponent = () => {
  const { fetchProducts, hasMore, loadingStatus, products } =
    useFetchReturnProducts();

  return (
    <InfiniteScroll
      hasMoreBottom={hasMore}
      loadMoreFromBottom={fetchProducts}
      className={`overflow-auto px-1 py-1 w-100 ${styles.scrollWrapper}`}
    >
      {loadingStatus === "pending" && products.length === 0 ? <Loader /> : ""}
      {products.map((item, index) => (
        <div
          key={index}
          className={`mb-2 d-flex align-items-start`}
        >
          {item.imageUrl ? (
            <img
              src={item.imageUrl}
              alt=""
              className={`${styles.orderImage}`}
            />
          ) : (
            <></>
          )}
          <div className="ms-2">
            <p className={`mb-0 ${styles.orderTitle}`}>{item.productName}</p>
            {/* <span className={`${styles.orderSpan}`}>
              {typeof item.productPrice === "string"
                ? item.productPrice
                : `${item.productPrice.currencyCode}${item.productPrice.amount}`}
            </span> */}
          </div>
        </div>
      ))}
    </InfiniteScroll>
  );
};

const TagsComponent = ({ productTags }: { productTags: string[] }) => {
  return (
    <div>
      {productTags.map((tag) => {
        return <p className={`${styles.tagText}`}>{tag}</p>;
      })}
    </div>
  );
};

const ScenarioItemsAndTags = ({ scenario, showSpecificEleModalBtn }: Props) => {
  const [isItemsMenuOpen, setItemsMenuOpen] = useState(false);
  const { productTags } = useFetchReturnProductTags();
  const { products } = useFetchReturnProducts();

  const areAllTagsSelected = useMemo(() => {
    return (
      Array.isArray(scenario.tags) &&
      scenario.tags.length === 1 &&
      scenario.tags[0] === "*"
    );
  }, [scenario.tags]);

  const areAllItemsSelected = useMemo(() => {
    return (
      Array.isArray(scenario.items) &&
      scenario.items.length === 1 &&
      typeof scenario.items[0] === "string" &&
      scenario.items[0] === "*"
    );
  }, [scenario.items]);

  return (
    <div className={`d-flex align-items-center mb-1 ${styles.itemWrapper}`}>
      {scenario.items &&
        scenario.items.length === 0 &&
        scenario.tags &&
        scenario.tags.length > 0 && (
          <>
            {scenario.tags.length > 0 && (
              <span className={`${styles.items} `}>Tags:</span>
            )}
            {areAllTagsSelected ? (
              <>
                <span className={`${styles.itemText} ms-2`}>
                  {productTags[0] ? productTags[0] : ""}
                </span>
                <span className={`${styles.itemText} ms-2`}>
                  {productTags[1] ? productTags[1] : ""}
                </span>
              </>
            ) : (
              <>
                <span className={`${styles.itemText} ms-2`}>
                  {scenario.tags[0] ? scenario.tags[0] : ""}
                </span>
                <span className={`${styles.itemText} ms-2`}>
                  {scenario.tags[1] ? scenario.tags[1] : ""}
                </span>
              </>
            )}
          </>
        )}
      {scenario.items && scenario.items.length > 0 && (
        <span className={`${styles.items}`}>Items:</span>
      )}
      {areAllItemsSelected ? (
        <>
          <div className="px-2">
            {products[0] &&
            typeof products[0] !== "string" &&
            products[0].imageUrl ? (
              <img
                src={products[0].imageUrl}
                alt=""
                className={`${styles.orderImg} me-2`}
              />
            ) : (
              <></>
            )}
            <span className={`${styles.itemText}`}>
              {products[0] && typeof products[0] !== "string"
                ? products[0].productName
                : ""}
            </span>
            {products.length > 1 && (
              <span className={`${styles.itemText}`}>{" , "}</span>
            )}
          </div>
          {products.length > 1 && (
            <div className="pe-2">
              {products[1] ? (
                typeof products[1] !== "string" &&
                products[1].imageUrl && (
                  <img
                    src={products[1].imageUrl}
                    alt=""
                    className={`${styles.orderImg} me-2`}
                  />
                )
              ) : (
                <></>
              )}
              <span className={`${styles.itemText}`}>
                {products[1] && typeof products[1] !== "string"
                  ? products[1].productName
                  : ""}
              </span>
            </div>
          )}
        </>
      ) : (
        <>
          <div className="px-2">
            {scenario.items[0] &&
            typeof scenario.items[0] !== "string" &&
            scenario.items[0].imgSrc ? (
              <img
                src={scenario.items[0].imgSrc}
                alt=""
                className={`${styles.orderImg} me-2`}
              />
            ) : (
              <></>
            )}
            <span className={`${styles.itemText}`}>
              {scenario.items[0] && typeof scenario.items[0] !== "string"
                ? scenario.items[0].label
                : ""}
            </span>
            {scenario.items.length > 1 && (
              <span className={`${styles.itemText}`}>{" , "}</span>
            )}
          </div>
          {scenario.items.length > 1 && (
            <div className="pe-2">
              {scenario.items[1] &&
              typeof scenario.items[1] !== "string" &&
              scenario.items[1].imgSrc ? (
                <img
                  src={scenario.items[1].imgSrc}
                  alt=""
                  className={`${styles.orderImg} me-2`}
                />
              ) : (
                <></>
              )}
              <span className={`${styles.itemText}`}>
                {scenario.items[1] && typeof scenario.items[1] !== "string"
                  ? scenario.items[1].label
                  : ""}
              </span>
            </div>
          )}
        </>
      )}
      {/* See All Details */}
      <div className="d-flex align-items-center">
        <Dropdown onToggle={(opened) => setItemsMenuOpen(opened)}>
          <Dropdown.Toggle
            as="span"
            id="dropdown-basic"
            bsPrefix={`${styles.editToggle}`}
          >
            <span
              className={`${styles.seeAll} ${
                isItemsMenuOpen ? styles.opened : ""
              }`}
            >
              see all details
            </span>
          </Dropdown.Toggle>

          <Dropdown.Menu bsPrefix={`dropdown-menu ${styles.editMenu2}`}>
            {/* Tags */}
            {scenario.tags.length > 0 && (
              <p className={`${styles.tags}`}>Tags</p>
            )}

            {typeof scenario.tags !== "string" && (
              <TagsComponent
                productTags={areAllTagsSelected ? productTags : scenario.tags}
              />
            )}

            {/* Items */}
            <div>
              <p className={`${styles.itemHead}`}>Items</p>
              {areAllItemsSelected ? (
                <AllItemsComponent />
              ) : (
                scenario.items.map((item, index) => (
                  <div
                    key={index}
                    className={`mb-2 d-flex align-items-start`}
                  >
                    {typeof item !== "string" && item.imgSrc ? (
                      <img
                        src={item.imgSrc}
                        alt=""
                        className={`${styles.orderImage}`}
                      />
                    ) : (
                      <></>
                    )}
                    <div className="ms-2">
                      <p className={`mb-0 ${styles.orderTitle}`}>
                        {typeof item !== "string" ? item.label : ""}
                      </p>
                      <span className={`${styles.orderSpan}`}>
                        {/* {typeof item !== "string" ? item.price : ""} */}
                      </span>
                    </div>
                  </div>
                ))
              )}

              <span
                className={`${styles.allItem}`}
                onClick={showSpecificEleModalBtn}
                role="button"
              >
                See all
              </span>
            </div>
          </Dropdown.Menu>
        </Dropdown>
        <span
          className={`ps-2 ${styles.edit}`}
          role="button"
          onClick={showSpecificEleModalBtn}
        >
          Edit
        </span>
      </div>
    </div>
  );
};

export default ScenarioItemsAndTags;
