/**
 * This file is the redux slice reducers file used for storing reducers to update the KB data in the store.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { KnowledgeBaseSlice } from "./knowledgeBase.declarations";

/**
 * This reducer is used to update the styling property in the store.
 */
const updatePageStylingProperty = (
  state: KnowledgeBaseSlice,
  {
    payload,
  }: {
    payload: { property: keyof KnowledgeBaseSlice["pageStyling"]; value: any };
  }
) => {
  const { property, value } = payload;
  (state.pageStyling as any)[property] = value;
};

/**
 * This is used to update the branding data of KB in store.
 */
const updateBranding = (
  state: KnowledgeBaseSlice,
  {
    payload,
  }: {
    payload: {
      property: keyof KnowledgeBaseSlice["pageStyling"]["branding"];
      value: any;
    };
  }
) => {
  const { property, value } = payload;
  (state.pageStyling.branding as any)[property] = value;
};

/**
 * This reducer is ud=sed to update the wording data in the store.
 */
const updateWording = (
  state: KnowledgeBaseSlice,
  {
    payload,
  }: {
    payload: {
      property: keyof KnowledgeBaseSlice["pageStyling"]["wording"];
      value: any;
    };
  }
) => {
  const { property, value } = payload;
  (state.pageStyling.wording as any)[property] = value;
};

/**
 * Exporting all the reducers.
 */
export default { updatePageStylingProperty, updateWording, updateBranding };
