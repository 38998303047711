import React from "react";
import styles from "./UserHeader.module.scss";
const UserHeader = () => {
  return (
    <div
      className={`d-flex align-items-center mb-3 ps-3 pe-2 ${styles.headerWrapper}`}
    >
      <div className={`pe-2 ${styles.userInfo}`}>
        <span>User</span>
      </div>
      <div className={`${styles.userRole}`}>
        <span>Role</span>
      </div>
      <div className={`${styles.userActiveStatus}`}>
        <span>Activation status</span>
      </div>
      <div className={`${styles.userEdit}`}>
        <span>Edit user</span>
      </div>
      <div className={`${styles.userAction}`}>
        <span>Action</span>
      </div>
      <div className={`${styles.loginStatus}`}>
        <span>Login Status</span>
      </div>
    </div>
  );
};

export default UserHeader;
