import { Channel } from "pusher-js";

export type ChatAssignedEventCB = (res: any) => void;

export const agentRemovedFromAssignedChatEvent = (
  channel: Channel,
  callback: ChatAssignedEventCB
) => {
  channel.bind("live_chat_multiple_agent_assigned_removed", callback);
};

export const agentRemovedFromAssignedChatEventUnbind = (
  channel: Channel,
) => {
  channel.unbind("live_chat_multiple_agent_assigned_removed");
};
