import { GoalSettingData, initialState } from "./GoalSetting.slice";

// reset goalSetting settings
const resetGoalSettings = (state: GoalSettingData) => {
  state = {...initialState};
  return state;
};

// reset goalSettings data
const resetGoalSettingsData = (state: GoalSettingData) => {
  state.goalSettingData = {};
  state.goalSettingIdList = [];
  state.fetchGoalSettingAjaxStatus = "pending";
  state.metaData.count =0;
  state.metaData.total =0;
};


export default { 
  resetGoalSettings,
  resetGoalSettingsData, 
};