import downArrow from "src/assets/images/downArrow.png";
import styles from "./CategoryDropdown.module.scss";
import { ReactElement, useState } from "react";
interface Props {
  heading: string;
  children: ReactElement;
}
const CategoryDropdown = ({ heading, children }: Props) => {
  const [showDrop, setShowDrop] = useState<Boolean>(true);
  return (
    <>
      <div className={`${styles.dropBtn}`}>
        <div
          className={`d-flex justify-content-between align-items-center cursor-pointer mb-2 ${styles.header}`}
          onClick={() => setShowDrop(!showDrop)}
        >
          <p className="mb-0">{heading} </p>
          <img
            src={downArrow}
            alt="arrow"
          />
        </div>
        {showDrop && <> {children}</>}
      </div>
    </>
  );
};

export default CategoryDropdown;
