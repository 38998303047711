import { axiosJSON } from "src/globals/axiosEndPoints";


export interface addGoalSettingParams{
  goalName : string,
  isActive? : boolean,
  goalValueType : string;
  goalType: string;
  goalValue : string,
  integrationId : number | string
}


export async function addGoalSetting(params: addGoalSettingParams) {
  const { data } = await axiosJSON.post(
    `/api/chatSetting/goals/create`,
    params
  );
  if (data.error === true) {
    throw new Error(data.message as string);
  }
  return data.data;
}