import {
  addBrand,
  addBrandPayload,
} from "src/services/Settings/Brands/addBrand";
import {
  deleteBrand,
  DeleteBrandPayload,
} from "src/services/Settings/Brands/deleteBrand";
import {
  getAllBrands,
  GetAllOutboundEmailParam,
  getAllOutboundEmails,
  searchBrands,
} from "src/services/Settings/Brands/getAllBrands";
import {
  getBrandById,
  GetBrandPayload,
} from "src/services/Settings/Brands/getBrand";
import {
  GetBrandAgentPayload,
  getBrandAgents,
} from "src/services/Settings/Brands/getBrandAgents";
import { getBrandSignaturePlaceholders } from "src/services/Settings/Brands/getBrandSignaturePlaceholders";
import { getBrandVariableValues } from "src/services/Settings/Brands/getBrandVariableValues";
import {
  updateBrand,
  updateBrandPayload,
} from "src/services/Settings/Brands/updateBrand";
import { updateBrandProfileImage } from "src/services/Settings/Brands/updateBrandProfile";
import {
  calculateTotalPages,
  pageSlice,
  paginationLogic,
} from "src/services/TicketService/pagination";
import { RootState } from "src/store/store";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { FreePlanError, FreePlanErrorMessage } from "src/globals/constants";

async function fetchAllBrandThunk(
  payload: undefined,
  { getState }: { getState: Function },
) {
  const currentState: RootState = getState();
  let brandPlaceholders = currentState.brandSettings.brandPlaceholderOptions;
  if (brandPlaceholders.length === 0) {
    brandPlaceholders = await getBrandSignaturePlaceholders();
  }

  const currentPage =
    currentState.brandSettings.currentPage === 0
      ? 1
      : currentState.brandSettings.currentPage;

  const { startPage, endPage, start, limit } = paginationLogic(
    currentPage,
    currentState.brandSettings.brandFetchLimit,
    currentState.brandSettings.totalPages,
    currentState.brandSettings.cachingPageNoLimit,
  );

  let brandData: any = {};
  if (currentState.brandSettings.filters.searchValue.trim() === "") {
    brandData = await getAllBrands({
      start,
      limit,
      type: "all",
      signatureType: "unfiltered",
    });
  } else {
    brandData = await searchBrands({
      searchTerm: currentState.brandSettings.filters.searchValue,
      start,
      limit,
      type: "all",
      signatureType: "unfiltered",
    });
  }

  const totalPages = calculateTotalPages(
    brandData.metaData.total,
    currentState.brandSettings.brandFetchLimit,
  );

  const cachedData: { [pageNo: string | number]: Array<number | string> } = {};
  let count = 0;
  for (let i = startPage; i <= endPage; i++) {
    cachedData[i] = pageSlice(
      brandData.brandIds,
      currentState.brandSettings.brandFetchLimit,
      count,
    );
    count++;
  }
  return { brandData, brandPlaceholders, totalPages, cachedData, currentPage };
}
interface AddBrand extends addBrandPayload {
  callback?: (statusCode: string, brandId?: number | string) => void;
  profileImage?: File;
  addBrandIdInCallback?: boolean;
}
async function addBrandThunk(
  { callback, profileImage, addBrandIdInCallback, ...payload }: AddBrand,
  { getState, dispatch }: { getState: Function; dispatch: Function },
) {
  let statusCode = "";
  let brandId = "";
  try {
    const data = await addBrand(payload);
    brandId = data.id;
    if (profileImage) {
      await updateBrandProfileImage({
        brandId: data.id,
        profileImage: profileImage,
      });
    }

    return data;
  } catch (err: any) {
    if (
      typeof err == "object" &&
      (err.statusCode === FreePlanError.LIMIT_REACHED ||
        err.statusCode === FreePlanError.RESTRICTED)
    ) {
      const errStatusCode = err.statusCode as keyof typeof FreePlanErrorMessage;
      // If the free plan limit is exceeded, show free plan error
      pushTheToast({
        text: err.message ?? FreePlanErrorMessage[errStatusCode],
        type: "danger",
        position: "top-right",
      });
      statusCode = errStatusCode;
    } else {
      statusCode = err.message.trim();
    }
  } finally {
    setTimeout(() => {
      if (addBrandIdInCallback && callback) {
        callback(statusCode, brandId);
      } else if (callback) {
        callback(statusCode);
      }
    }, 0);
  }
}

interface EditBrand extends updateBrandPayload {
  profileImage?: File;
  callback?: (statusCode: string) => void;
}

async function updateBrandThunk(
  { callback, profileImage, ...payload }: EditBrand,
  { getState, dispatch }: { getState: Function; dispatch: Function },
) {
  let statusCode = "";

  try {
    const data = await updateBrand(payload);

    if (profileImage) {
      await updateBrandProfileImage({
        brandId: payload.id,
        profileImage: profileImage,
      });
    }

    const brandData = await getBrandById({
      id: payload.id,
      signatureType: "unfiltered",
    });

    return brandData;
  } catch (e: any) {
    statusCode = e.message.trim();
  } finally {
    setTimeout(() => {
      if (callback) {
        callback(statusCode);
      }
    }, 0);
  }
}

interface DeleteBrand extends DeleteBrandPayload {
  callback?: () => void;
}

async function deleteBrandThunk(
  { callback, ...payload }: DeleteBrand,
  { getState, dispatch }: { getState: Function; dispatch: Function },
) {
  const data = await deleteBrand(payload);

  setTimeout(() => {
    if (callback) {
      callback();
    }
  }, 0);

  return payload.id;
}

interface GetBrand extends GetBrandPayload {
  callback?: () => void;
}
async function getBrandThunk(
  { callback, ...payload }: GetBrand,
  { getState, dispatch }: { getState: Function; dispatch: Function },
) {
  const brandData = await getBrandById({
    id: payload.id,
    signatureType: "unfiltered",
  });

  setTimeout(() => {
    if (callback) {
      callback();
    }
  }, 0);

  return brandData;
}

interface GetBrandAgent extends GetBrandAgentPayload {
  callback?: () => void;
}
async function getBrandAgentsThunk(
  { callback, ...payload }: GetBrandAgent,
  { getState, dispatch }: { getState: Function; dispatch: Function },
) {
  const currentState: RootState = getState();

  let brandPayload = {
    ...payload,
    start: currentState.brandSettings.brandAgentIds.length,
    limit: currentState.brandSettings.brandAgentFetchLimit,
  };

  const data = await getBrandAgents(brandPayload);

  setTimeout(() => {
    if (callback) {
      callback();
    }
  }, 0);

  return data;
}

async function getAllBrandAgentsThunk(
  { callback, ...payload }: GetBrandAgent,
  { getState, dispatch }: { getState: Function; dispatch: Function },
) {
  const currentState: RootState = getState();

  const currentPage =
    currentState.brandSettings.usersMeta.userCurrentPage === 0
      ? 1
      : currentState.brandSettings.usersMeta.userCurrentPage;

  const { startPage, endPage, start, limit } = paginationLogic(
    currentPage,
    currentState.brandSettings.userFetchLimit,
    currentState.brandSettings.usersMeta.totalPages,
    0,
  );

  let brandPayload: GetBrandAgent = {
    ...payload,
    withTicketCounts: true,
    start,
    limit,
    // isExcludeUserAdmin: true,
  };

  const searchTerm = currentState.brandSettings.userSearchValue;

  if (searchTerm && searchTerm.trim() !== "") {
    brandPayload["searchTerm"] = searchTerm;
  }

  const data = await getBrandAgents(brandPayload);

  const totalPages = calculateTotalPages(
    data.metaData.total,
    currentState.brandSettings.brandFetchLimit,
  );

  setTimeout(() => {
    if (callback) {
      callback();
    }
  }, 0);

  return { userData: data, totalPages };
}
interface AllOutboundEmail {
  callback?: () => void;
}

async function getAllOutboundEmailsThunk(
  { callback, ...payload }: AllOutboundEmail,
  { getState, dispatch }: { getState: Function; dispatch: Function },
) {
  const currentState: RootState = getState();

  let emailPayload: GetAllOutboundEmailParam = {
    start: currentState.brandSettings.outboundMailsIds.length,
    limit: currentState.brandSettings.outBoundEmailFetchLimit,
  };

  if (
    currentState.brandSettings.outBoundEmailSearchValue &&
    currentState.brandSettings.outBoundEmailSearchValue.length !== 0
  ) {
    emailPayload["searchTerm"] =
      currentState.brandSettings.outBoundEmailSearchValue;
  }

  const data = await getAllOutboundEmails(emailPayload);

  setTimeout(() => {
    if (callback) {
      callback();
    }
  }, 0);

  return data;
}

async function fetchBrandPlaceholdersThunk(
  payload: undefined,
  { getState }: { getState: Function },
) {
  const brandPlaceholders = await getBrandSignaturePlaceholders();
  return { brandPlaceholders };
}
async function fetchBrandVariableValuesThunk(
  payload: undefined,
  { getState }: { getState: Function },
) {
  const data = await getBrandVariableValues();
  return data;
}

const fetchAllBrandsInfiScrollThunk = async (
  payload: { start?: number },
  { getState }: { getState: Function },
) => {
  const currentState: RootState = getState();

  let start =
    payload.start !== undefined
      ? payload.start
      : currentState.brandSettings.brandIds.length;
  let limit = 25;
  let brandData: any = {};
  if (currentState.brandSettings.filters.searchValue.trim() === "") {
    brandData = await getAllBrands({
      start,
      limit,
      type: "all",
      signatureType: "unfiltered",
    });
  } else {
    brandData = await searchBrands({
      searchTerm: currentState.brandSettings.filters.searchValue,
      start,
      limit,
      type: "all",
      signatureType: "unfiltered",
    });
  }

  return brandData;
};
export default {
  fetchAllBrandThunk,
  addBrandThunk,
  updateBrandThunk,
  deleteBrandThunk,
  getBrandThunk,
  getBrandAgentsThunk,
  getAllBrandAgentsThunk,
  getAllOutboundEmailsThunk,
  fetchBrandPlaceholdersThunk,
  fetchBrandVariableValuesThunk,
  fetchAllBrandsInfiScrollThunk,
};
