import React, { useCallback, useState } from "react";
import styles from "./FeaturedArticle.module.scss";
import caution from "src/assets/images/caution.png";
import { useAppSelector } from "src/store/store";
import { AddFeatureModalTypes } from "../../AddFeaturesModal";
import { useParams } from "react-router-dom";
import useFetchArticleAndCategory from "../../../../../CustomHooks/useFetchArticleAndCategory";
import CategoryFolder from "./CategoryFolder/CategoryFolder";
import InfiniteScroll from "src/components/InfiniteScroll";
import { Spinner } from "react-bootstrap";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
interface Props {
  onHide: Function;
  setShowComponent: (value: AddFeatureModalTypes) => void;
}
const FeaturedArticle = ({ setShowComponent }: Props) => {
  const { integrationId } = useParams();

  const {
    categoryData,
    categoryIdList,
    handleCategoryInfiniteScroll,
    hasMoreCategory,
    handleArticleInfiniteScroll,
    hasMoreArticle,
    initialFetchLoading,
  } = useFetchArticleAndCategory({ integrationId });
  const { type, featuredArticles } = useAppSelector(
    (state) => state.knowledgeBasedSettings.activeFeaturedArticle,
  );

  const handleNext = useCallback(() => {
    if (Object.values(featuredArticles).length) {
      setShowComponent("shortTitle");
    } else {
      //SHow toast message when no article selected and click on next
      pushTheToast({
        type: "warning",
        text: "Atleast one article should be selected!",
        position: "top-right",
      });
      return;
    }
  }, [featuredArticles]);
  // These buttons allow the user to go back to the previous step or proceed to the next step in the feature selection process.
  const handleBack = useCallback(() => {
    if (type === "custom") {
      setShowComponent("domainUrl");
    } else {
      setShowComponent("initial");
    }
  }, [type]);

  return (
    <div className="p-4">
      <div className="d-flex align-items-center">
        <h4 className={`mb-0 me-2 ${styles.articleHead}`}>
          Choose featured articles
        </h4>
        <span
          className={`p-1 ${
            type === "default" ? styles.defaultSpan : styles.customSpan
          }`}
        >
          <span className="pe-1">
            {type === "default" ? "Default" : "Custom"}
          </span>
          <span className={`${styles.cautionImg}`}>
            {" "}
            <img
              src={caution}
              alt=""
              className=""
            />{" "}
          </span>
        </span>
      </div>
      <p className={`${styles.subHeading}`}>
        Choose featured articles for which will appear on the pages of the URL
        added by you
      </p>
      <div className={`${styles.featureWrapper}`}>
        {/* Added loader while fetching initially */}
        {initialFetchLoading ? (
          <div className="p-2 mt-3 d-flex justify-content-center">
            <Spinner
              animation="border"
              variant="secondary"
            />
          </div>
        ) : (
          <InfiniteScroll
            className={`${styles.infScroll}`}
            loadMore={handleCategoryInfiniteScroll}
            initialDataLoaded={true}
            loader={
              <div className="p-2">
                <Spinner
                  animation="border"
                  variant="secondary"
                />
              </div>
            }
            hasMore={hasMoreCategory()}
          >
            {categoryIdList.map((categoryId) => {
              const category = categoryData[categoryId];
              return (
                <CategoryFolder
                  key={categoryId}
                  categoryData={category}
                  handleArticleInfiniteScroll={handleArticleInfiniteScroll}
                  hasMoreArticles={hasMoreArticle}
                />
              );
            })}
            {/* 05 Dec 2023 added message when there are no article found */}
            {categoryIdList.length === 0 && (
              <span className="d-flex justify-content-center">
                No Article found
              </span>
            )}
          </InfiniteScroll>
        )}
        {/* <div className="d-flex">
          <span>
            <i className="fa-regular fa-folder"></i>
          </span>
          <div className="w-100">
            <span className={`ps-2 d-inline-block mb-1 ${styles.category}`}>
              Category 3
            </span>
            <div className={`p-3 mb-2 w-100 ${styles.featureBox}`}>
              <div className="form-check mb-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                />
                <label
                  className={`form-check-label ${styles.formLabel}`}
                  htmlFor="flexCheckDefault"
                >
                  How do I initiate an exchange on acme items ?
                  <span className={`ps-2 ${styles.viewText}`}>View</span>
                </label>
              </div>
              <div className="form-check mb-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                />
                <label
                  className={`form-check-label ${styles.formLabel}`}
                  htmlFor="flexCheckDefault"
                >
                  #3 tips to maintain your acme shoes
                  <span className={`ps-2 ${styles.viewText}`}>View</span>
                </label>
              </div>
              <div className="form-check mb-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                />
                <label
                  className={`form-check-label ${styles.formLabel}`}
                  htmlFor="flexCheckDefault"
                >
                  What are the costs of shipping to NYC ?
                  <span className={`ps-2 ${styles.viewText}`}>View</span>
                </label>
              </div>
              <div className="form-check mb-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                />
                <label
                  className={`form-check-label ${styles.formLabel}`}
                  htmlFor="flexCheckDefault"
                >
                  How can we claim a warranty in the order ?
                  <span className={`ps-2 ${styles.viewText}`}>View</span>
                </label>
              </div>
              <div className="form-check mb-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                />
                <label
                  className={`form-check-label ${styles.formLabel}`}
                  htmlFor="flexCheckDefault"
                >
                  How do I initiate an exchange on acme items ?
                  <span className={`ps-2 ${styles.viewText}`}>View</span>
                </label>
              </div>
              <div className="form-check mb-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                />
                <label
                  className={`form-check-label ${styles.formLabel}`}
                  htmlFor="flexCheckDefault"
                >
                  #3 tips to maintain your acme shoes
                  <span className={`ps-2 ${styles.viewText}`}>View</span>
                </label>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <div className={`mt-2 ${styles.actionBtn}`}>
        <button
          className={`d-flex justify-content-center align-items-center ${styles.nextBtn}`}
          onClick={handleBack}
        >
          <span className={`ps-1 pe-2 ${styles.arrow}`}>
            <i className="fa-solid fa-chevron-left"></i>
          </span>
          <span> Back</span>
        </button>
        <button
          className={`d-flex justify-content-center align-items-center ${styles.nextBtn}`}
          onClick={handleNext}
        >
          <span> Next</span>
          <span className={`ps-2 pe-1 ${styles.arrow}`}>
            <i className="fa-solid fa-chevron-right"></i>
          </span>
        </button>
      </div>
    </div>
  );
};

export default FeaturedArticle;
