import Lottie from "lottie-light-react";
import ripple from "src/assets/images/nextBtnGif.json";
import styles from "./MoveToNext.module.scss";
import useSaveStepsAndQuestions from "src/features/ReturnAutoWorkFlow/hooks/useSaveStepsAndQuestions";
import { useCallback } from "react";
import { Spinner } from "react-bootstrap";

function MoveToNext({
  stepId,
  questionId,
}: {
  stepId: string;
  questionId: string;
}) {
  const { stepQuestion, savingStepAndQuestionData, saveStepAndQuestionData } =
    useSaveStepsAndQuestions({ stepId, questionId });

  const handleMoveToNext = useCallback(() => {
    saveStepAndQuestionData({ moveToNextBtnClick: true });
  }, []);

  return (
    <button
      className={`my-auto ps-1 pe-2 ${styles.moveBtn}`}
      onClick={handleMoveToNext}
    >
      <span
        className="d-inline-flex align-items-center"
        style={{ width: "33px", height: "29px" }}
      >
        {savingStepAndQuestionData === "moveToNext" ? (
          <Spinner
            className="ms-2"
            size="sm"
          />
        ) : (
          <Lottie animationData={ripple}></Lottie>
        )}
      </span>
      <span className="ms-2">
        {stepQuestion.showMoveToNext === "step"
          ? "Move to next segment"
          : stepQuestion.showMoveToNext === "question"
            ? "Move to next question"
            : "Save"}
      </span>
    </button>
  );
}

export default MoveToNext;
