import { conditionType } from "src/services/Bot/BotProfiles/getConditionOptions.service";
import {
  botProfilesSlice,
  AudienceConfigCondition,
} from "./botProfiles.declarations";
import { v4 as uuid } from "uuid";
var objectHash = require("object-hash");
// Reducer function for handling the state update after fetching audience configuration conditions.
const fetchConfigureAudienceConditionFulfilled = (
  state: botProfilesSlice,
  {
    payload,
  }: {
    payload: {
      conditions: AudienceConfigCondition[];
      conditionOptions: conditionType[];
    };
  }
) => {
  state.audienceConfigOptions = payload.conditionOptions;

  if (payload.conditions.length === 0) {
    let newCondition: AudienceConfigCondition = {
      id: uuid(),
      ruleType: "AND",
      conditionKey: null,
      values: null,
      operator: undefined,
      isNewlyAdded: true,
    };
    state.audienceConfigConditions = [newCondition];
    state.initialConfigHash = "";
  } else {
    state.audienceConfigConditions = payload.conditions;
    state.initialConfigHash = objectHash(state.audienceConfigConditions);
  }
  
  state.audienceConfigConditionAJAX = "fulfilled";
  return state;
};
// * Reducer function for handling the state update after updating audience configuration conditions.
const updateConfigureAudienceConditionFulfilled = (
  state: botProfilesSlice,
  { payload }: { payload: { conditions: AudienceConfigCondition[] , validationErrors?: boolean } }
) => {
  state.audienceConfigConditions = payload.conditions;
  if(!payload.validationErrors){
    state.initialConfigHash = objectHash(payload.conditions);
  }
};

export default {
  fetchConfigureAudienceConditionFulfilled,
  updateConfigureAudienceConditionFulfilled,
};
