import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import {
  ConditionTypes,
  createNewObjectHash,
  getConditionValueOrDefault,
} from "src/store/slices/initiateReturn/Helpers/returnExchange.helpers";
import { v4 as uuid } from "uuid";
import {
  fetchPostStep9Config,
  fetchStep9GetConfig,
} from "../Thunks/returnExchangeModal.thunk";
import { step9Configuration } from "../step9Configuration.slice";

const updateConfig = (state: step9Configuration, payload: any) => {
  const { step9ConditionOptionsRes, step9ConfigRes, defaultReturnWindow } =
    payload;

  if (step9ConditionOptionsRes) {
    state.step9Configuration.conditionOptions =
      step9ConditionOptionsRes.conditions;
    state.step9Configuration.returnWindowOptions =
      step9ConditionOptionsRes.returnWindowConditions;
    state.step9Configuration.defaultOptions =
      step9ConditionOptionsRes.defaultConditions;
    state.step9Configuration.variableNames = {};
    [
      ...state.step9Configuration.conditionOptions,
      ...state.step9Configuration.returnWindowOptions,
      ...state.step9Configuration.defaultOptions,
    ].forEach((option) => {
      state.step9Configuration.variableNames[option.id] = option.name;
      state.step9Configuration.operatorsNames[option.name] =
        option.operators.map((operator) => {
          state.step9Configuration.operatorSymbolMap[operator.value] =
            operator.name;
          return operator.name;
        });
      state.step9Configuration.valueTypes[option.valueType] = null;
      state.step9Configuration.variableNameToValueType[option.name] =
        option.valueType;
    });
  }
  if (step9ConfigRes) {
    const step9Config = step9ConfigRes;

    if (step9Config.length > 0) {
      state.step9Configuration.selectedOrderTypeId =
        step9Config[0].configTabId.toString();
      state.step9Configuration.orderTypes = step9Config.map(
        (tab: any, idx: any) => {
          const variableIdx: any = Number(tab.returnWindow.conditionTypeId);
          const variableName =
            state.step9Configuration.variableNames[variableIdx];
          const operatorSymbol = tab.returnWindow.operator;
          const operator =
            state.step9Configuration.operatorSymbolMap[operatorSymbol];
          const valueType =
            state.step9Configuration.variableNameToValueType[variableName];
          const returnWindowValues = getConditionValueOrDefault(
            valueType,
            tab.returnWindow.conditionValue,
          );
          return {
            orderTypeName: tab.configTabName,
            orderTypeId: tab.configTabId.toString(),
            messageForCustomer: {
              value: tab.notEligibleResponseMessage,
              error: null,
            },
            error: null,
            isAddConditionEnabled: true,
            errorCount: 0,
            tabHash: "",
            orderSequence: idx,
            isValid: false,
            returnWindow: {
              id: tab.returnWindow.id,
              variableName,
              operator,
              values: returnWindowValues,
            },
            conditions: tab.conditions.map((condition: any) => {
              const variableIdx: any = Number(condition.conditionTypeId);
              const variableName =
                state.step9Configuration.variableNames[variableIdx];
              const operatorSymbol = condition.operator;
              const operator =
                state.step9Configuration.operatorSymbolMap[operatorSymbol];
              const valueType =
                state.step9Configuration.variableNameToValueType[variableName];
              const values = getConditionValueOrDefault(
                valueType,
                condition.conditionValue,
              );

              return {
                id: condition.id.toString(),
                variableName,
                operator,
                values,
              } as ConditionTypes;
            }),
          };
        },
      );
    } else {
      if (defaultReturnWindow) {
        const variableName = defaultReturnWindow.variableName;
        const valueType =
          state.step9Configuration.variableNameToValueType[variableName];
        const operatorSymbol = defaultReturnWindow.operator;
        const operator =
          state.step9Configuration.operatorSymbolMap[operatorSymbol];
        const values = getConditionValueOrDefault(
          valueType,
          defaultReturnWindow.values,
        );

        let condition = {
          id: uuid(),
          variableName,
          ruleType: defaultReturnWindow.ruleType,
          operator,
          values,
        } as ConditionTypes;

        state.step9Configuration.orderTypes[0].returnWindow = condition;
      }

      if (step9ConditionOptionsRes.defaultConditions) {
        let conditions = step9ConditionOptionsRes.defaultConditions.map(
          (condition: any) => {
            const selectedVariable: any =
              state.step9Configuration?.variableNames[condition.id];

            if (selectedVariable) {
              const variableName = selectedVariable;
              const operatorSymbol = condition.operators[0].value;
              const operator =
                state.step9Configuration.operatorSymbolMap[operatorSymbol];
              const valueType =
                state.step9Configuration.variableNameToValueType[variableName];
              const values = getConditionValueOrDefault(valueType, null);

              // const values = productIdToDetails(Conditionvalues);

              return {
                id: uuid(),
                variableName,
                operator,
                values,
              } as ConditionTypes;
            }
          },
        );

        state.step9Configuration.orderTypes[0].conditions = conditions;
        state.step9Configuration.orderTypes[0].orderTypeName = "All Orders";
      }
    }
    if (step9ConfigRes) {
      state.step9Configuration.getOrderType.data = step9ConfigRes;
    }
  }

  const hash = createNewObjectHash({
    excludeKeys: state.excludeKeysForHash,
    hashObject: state.step9Configuration.orderTypes,
  });

  if (hash) {
    state.initialHash = hash;
    state.currentHash = hash;
  }
};

const step9Builders = (
  builder: ActionReducerMapBuilder<step9Configuration>,
) => {
  builder.addCase(fetchPostStep9Config.pending, (state: step9Configuration) => {
    state.updateAjaxStatus = "pending";
  });
  builder.addCase(
    fetchPostStep9Config.fulfilled,
    (state: step9Configuration) => {
      state.updateAjaxStatus = "fulfilled";
    },
  );
  builder.addCase(
    fetchPostStep9Config.rejected,
    (state: step9Configuration) => {
      state.updateAjaxStatus = "rejected";
      pushTheToast({
        type: "danger",
        text: "failed to update!",
        position: "top-right",
      });
    },
  );
  builder.addCase(
    fetchStep9GetConfig.pending,
    (state: step9Configuration, { payload }) => {
      state.fetchAjaxStatus = "pending";
    },
  );
  builder.addCase(
    fetchStep9GetConfig.rejected,
    (state: step9Configuration, { payload }) => {
      state.fetchAjaxStatus = "rejected";
      pushTheToast({
        type: "danger",
        text: "failed to fetch configuration!",
        position: "top-right",
      });
    },
  );
  builder.addCase(
    fetchStep9GetConfig.fulfilled,
    (state: step9Configuration, { payload }) => {
      state.fetchAjaxStatus = "fulfilled";
      updateConfig(state, payload);
    },
  );
};

export default step9Builders;
