import { useState } from "react";
import styles from "./TimeOutComp.module.scss";
interface Props {
  labelOne: string;
  labelTwo: string;
  subLabel: string;
  enabled: boolean;
  count: number;
  setCount: any;
}
const TimeOutComp = ({ labelOne, labelTwo, subLabel, enabled, count, setCount }: Props) => {

  const handleIncr = () => {
    if (count === 60) {
      setCount({ enabled: enabled, value: count });
    } else {
      setCount({ enabled: enabled, value: count + 1 });
    }
  };
  const handleDec = () => {
    if (count === 0) {
      setCount({ enabled: enabled, value: count });
    } else {
      setCount({ enabled: enabled, value: count - 1 });
    }
  };
  const handleEnable = (e:any) => {
      setCount({ enabled: e.target.checked, value: count });
  }
  return (
    <>
      <div className="mb-1">
        <div className="form-check">
          <input
            className={`form-check-input ${styles.checkBox}`}
            type="checkbox"
            value=""
            id="flexCheckDefault"
            checked={enabled === true}
            onChange={handleEnable}
          />
          <label className="form-check-label">
            <span className={`d-flex align-items-start flex-column flex-md-row align-items-md-center ${styles.timeOutLabel}`}>
              <span> {labelOne}</span>
              <span className="mx-md-1">
                <div className={`d-flex ${styles.timeSet}`}>
                  <div className={`${styles.timeSet}`}>
                    <span>{count}</span>
                  </div>
                  <div className={`d-flex flex-column ${styles.arrowBox}`}>
                    <span className={`${styles.upArrow}`} onClick={handleIncr}>
                      <i className="fa-solid fa-sort-up"></i>
                    </span>
                    <span className={`${styles.downArrow}`} onClick={handleDec}>
                      <i className="fa-solid fa-caret-down"></i>
                    </span>
                  </div>
                </div>
              </span>
              <span> {labelTwo}</span>
            </span>
            <p className={`${styles.subLable}`}>{subLabel}</p>
          </label>
        </div>
      </div>
    </>
  );
};
export default TimeOutComp;
