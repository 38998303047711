import SearchBar from "src/components/SearchBar/SearchBar";
import LiveConversation from "../LiveConversation/LiveConversation";
import styles from "./LiveCustomerSB.module.scss";
import InfiniteScroll from "src/components/InfiniteScroll";
import logo from "src/assets/images/missed.png";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  actions,
  sortChatByMessageDateSelector,
} from "src/store/slices/liveChatSetting/chatWidExchCust/chatWidExchCust.slice";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  fetchCustomerData,
  fetchLiveChatSidebar,
} from "src/store/slices/liveChatSetting/chatWidExchCust/chatWidExchCust.thunks";
import { useNavigate, useParams } from "react-router-dom";
import EmptySideBar from "src/assets/images/EmptySideBar.png";
import { Modal, Spinner } from "react-bootstrap";
import emptyBackPng from "src/assets/images/emptyBack.png";
import {
  resetChatWidExchMsg,
  setActiveCustomerInfo,
} from "src/store/slices/liveChatSetting/chatWidExchMsg/chatWidExchMsg.slice";
import CreateTicket from "../MainChat/Children/ChatTabs/Children/CreateTicket/CreateTicket";
import SuccessMsg from "../MainChat/Children/ChatTabs/Children/CreateTicket/SuccessMsg/SuccessMsg";
import { Customer } from "src/services/LiveChat/messageExchange/getAllCustomers";
import AssignChatFilter from "./Children/AssignChatTo/AssignChatFilter";
import SearchMention from "./Children/AssignChatTo/SearchMention/SearchMention";
import MultipleFilter from "./Children/MultipleFilter/MultipleFilter";
import Midi from "src/assets/images/Midi.png";
import Missed from "src/assets/images/missedLogo.png";
import StartChatModal from "./Children/StartChatModal/StartChatModal";
import ChatBotHandle from "./Children/ChatBotHandle/ChatBotHandle";

const LiveCustomerSB = ({
  searchText,
  setSearchText,
}: {
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const initialized = useRef(false);
  const { chatId: currentChatId, chatStatus } = useParams();

  const [showModal, setShowModal] = useState(false);
  // Initing load more button and setting it true
  const [showLoadMore, setShowLoadMore] = useState(true);
  const [showTicketSuccess, setShowTicketSuccess] = useState(false);

  const [resolveAndCreateTicket, setResolveAndCreateTicket] =
    useState<boolean>(true);
  const [createdTicketId, setCreatedTicketId] = useState<number | string>(0);

  const [selectedChat, setSelectedChat] = useState<Customer | undefined>();

  const {
    chatIdList: originalChatIdList,
    customers,
    fetchCustAJAXStatus,
    metaData,
    selectedCustomerStatus,
    sidebar,
    filters,
    fetchCustomerLimit,
  } = useAppSelector((state) => state.chatWidExchCust);

  // Get a sorted list of chat IDs using a selector
  const sortedChatIdList = useAppSelector(sortChatByMessageDateSelector);

  // Use useMemo to conditionally update chatIdList based on searchValue
  const chatIdList = useMemo(() => {
    // Check if the searchValue in filters is empty and mentions filter is also not applied
    if (filters.searchValue.trim() === "" && !filters.showMentions) {
      // If it's empty, use the sorted chat ID list
      return sortedChatIdList;
    } else {
      // If there is a search value, use the original chat ID list
      return originalChatIdList;
    }
  }, [
    sortedChatIdList,
    originalChatIdList,
    filters.searchValue,
    filters.showMentions,
  ]); //added mention filter in dependency to not apply last message sort

  const currentState = useRef({
    chatIdList: chatIdList,
    customers: customers,
    fetchCustAJAXStatus: fetchCustAJAXStatus,
    metaData: metaData,
    filters,
  });

  function handleStatusTabClick(status: "live" | "missed" | "archived") {
    if (fetchCustAJAXStatus !== "pending") {
      dispatch(resetChatWidExchMsg());
      dispatch(fetchLiveChatSidebar());
      // dispatch(
      //   actions.resetCustomersData({ loading: false, initialized: true })
      // );
      // dispatch(actions.selectCustomerStatus({ customerStatus: status }));
      // dispatch(fetchCustomerData({ start: 0 }));
      navigate(`/live-chat/chats/${status}/all`);
    }
  }

  useEffect(() => {
    initialized.current = true;
  }, []);

  useMemo(() => {
    currentState.current = {
      chatIdList: chatIdList,
      customers: customers,
      fetchCustAJAXStatus: fetchCustAJAXStatus,
      metaData: metaData,
      filters,
    };
  }, [
    chatIdList,
    customers,
    fetchCustAJAXStatus,
    metaData,
    selectedCustomerStatus,
    sidebar,
    filters,
  ]);

  const handleInfiniteScroll = () => {
    if (
      currentState.current !== null &&
      currentState.current.fetchCustAJAXStatus === "fulfilled"
    ) {
      if (currentState.current.metaData.limitReached ? false : true) {
        // Setting load more button show false
        setShowLoadMore(false);
        dispatch(
          fetchCustomerData({ start: currentState.current.chatIdList.length }),
        );
      }
    }
  };

  const showFilter = useMemo(() => {
    if (filters.searchValue.trim()) {
      return "search";
    } else if (filters.showMentions) {
      return "mentions";
    } else {
      return "allfilters";
    }
  }, [filters]);

  const handelSearch = (value: any, callback: any) => {
    if (initialized.current) {
      setSearchText(value);
      dispatch(
        actions.resetCustomersData({ loading: false, initialized: true }),
      );
      dispatch(actions.setSearchValue({ searchValue: value }));
      if (value === "") {
        dispatch(fetchCustomerData({ start: 0 }));
      } else {
        dispatch(fetchCustomerData({ start: 0 }));
      }
    }
  };

  const handleMentionClick = useCallback(() => {
    dispatch(actions.resetCustomersData({ loading: false, initialized: true }));
    if (currentState.current.filters.showMentions === false) {
      dispatch(actions.setShowMentions(true));
      dispatch(resetChatWidExchMsg());
      navigate(`/live-chat/chats/${chatStatus}/all`);
      dispatch(fetchCustomerData({ start: 0 }));
    } else {
      dispatch(actions.setShowMentions(false));
      dispatch(resetChatWidExchMsg());
      navigate(`/live-chat/chats/${chatStatus}/all`);
      dispatch(fetchCustomerData({ start: 0 }));
    }
  }, [chatStatus]);

  const onShow = useCallback(() => {
    setShowModal(true);
    setShowTicketSuccess(false);
  }, []);

  const onHide = useCallback(() => {
    // setResolveAndCreateTicket(false);
    setShowModal(false);
    setShowTicketSuccess(false);
  }, []);

  const live = {
    heading: "Live",
    subHeading: "The ongoing chats will appear here",
  };
  const missed = {
    heading: "Missed",
    subHeading:
      "When customers leave without having a reply those chats appear here",
  };
  const archived = {
    heading: "Archived",
    subHeading: "The chats that are no longer active appear here",
  };

  const searched = {
    heading: "Searched",
    subHeading: "The searched chats will appear here",
  };

  const hasSearchValue =
    useAppSelector((state) => state.chatWidExchCust.filters.searchValue)
      .length > 0;

  const {
    brandIdsApplied,
    statusAllSelected,
    statusFilterApplied,
    idFilterApplied,
    appliedFilterStartDate,
    appliedFilterEndDate,
  } = useAppSelector((state) => state.chatWidExchCust);

  const hasFilteredApplied = useMemo(() => {
    if (
      brandIdsApplied.length > 0 ||
      statusFilterApplied.includes("resolved") ||
      statusFilterApplied.includes("unresolved") ||
      statusFilterApplied.includes("resolved_and_archived") ||
      statusFilterApplied.includes("unresolved_and_archived") ||
      statusFilterApplied.includes("unresolved_and_unarchived") ||
      (statusAllSelected ? true : false) ||
      appliedFilterStartDate !== null ||
      appliedFilterEndDate !== null ||
      idFilterApplied.selectedIds.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  }, [
    brandIdsApplied,
    statusFilterApplied,
    statusAllSelected,
    appliedFilterStartDate,
    appliedFilterEndDate,
    idFilterApplied.selectedIds,
  ]);

  const [showArchNotification, setShowArchNotification] = useState(false);
  const [showMissedNotification, setShowMissedNotification] = useState(false);

  const hideArchivedNotification = useMemo(() => {
    return (
      !showArchNotification &&
      selectedCustomerStatus !== "archived" &&
      sidebar.archiveChatIds &&
      sidebar.archiveChatIds > 0
    );
  }, [showArchNotification, selectedCustomerStatus, sidebar.archiveChatIds]);

  const hideMissedNotification = useMemo(() => {
    return (
      !showMissedNotification &&
      selectedCustomerStatus !== "missed" &&
      sidebar.missedChatIds &&
      sidebar.missedChatIds > 0
    );
  }, [showMissedNotification, selectedCustomerStatus, sidebar.missedChatIds]);

  let InfiniteScrollStyles = styles.contentSB;
  if (hideArchivedNotification && hideMissedNotification) {
    InfiniteScrollStyles = styles.contentSB;
  } else if (hideMissedNotification === false && hideArchivedNotification) {
    InfiniteScrollStyles = styles.contentSBUpdate;
  } else if (hideArchivedNotification === false && hideMissedNotification) {
    InfiniteScrollStyles = styles.contentSBNewUpdate;
  } else {
    InfiniteScrollStyles = styles.contentSBNoNotification;
  }

  const [showDropDown, setShowDropDown] = useState(false);
  const [showChatModal, setShowChatModal] = useState(false);
  const onModalShow = (e: any) => {
    e.stopPropagation();
    setShowChatModal(true);
  };
  const onModalHide = () => {
    setShowChatModal(false);
  };

  // Created has more memo to do optimize it and reuse it
  const hasMore = useMemo(() => {
    return fetchCustAJAXStatus === "rejected"
      ? false
      : metaData.limitReached
        ? false
        : true;
  }, [fetchCustAJAXStatus, metaData]);

  // Created show loader memo to do optimize it and reuse it
  const showLoader = useMemo(() => {
    return fetchCustAJAXStatus === "pending" && chatIdList.length === 0;
  }, [fetchCustAJAXStatus, chatIdList]);

  // Created show error memo to do optimize it and reuse it
  const showError = useMemo(() => {
    return chatIdList.length === 0 && fetchCustAJAXStatus === "fulfilled";
  }, [fetchCustAJAXStatus, chatIdList]);

  // Created show network error memo to do optimize it and reuse it
  const showErrorRej = useMemo(() => {
    return fetchCustAJAXStatus === "rejected";
  }, [fetchCustAJAXStatus, chatIdList]);

  // Effect to show the load more button again if loading done.
  useEffect(() => {
    if (fetchCustAJAXStatus === "fulfilled") {
      setShowLoadMore(true);
    }
  }, [fetchCustAJAXStatus]);

  return (
    <div className="position-relative">
      {/* <div className={`${styles.whatsappWrap}`}>
        <div className={`${styles.startChat}`} onClick={onModalShow}>
          <div className="d-flex align-items-center">
            <span className={`${styles.addIcon}`}>
              <i className="fa-solid fa-plus"></i>
            </span>
            <p className={`mb-0 ${styles.startText}`}>Start new chat</p>
          </div>
        </div>
      </div>
      <Modal
        enforceFocus={false}
        backdropClassName={`${styles.chatModalBack}`}
        show={showChatModal}
        onHide={onModalHide}
        dialogClassName={`${styles.chatModalDialog}`}
        contentClassName={`${styles.chatModalContent}`}
      >
        <StartChatModal onHide={onModalHide} />
      </Modal> */}
      <div
        className={`d-flex justify-content-between align-items-center mb-1 ${styles.sidebarHead}`}
      >
        <h3 className={`mb-0 ${styles.chatHead}`}>All Chats</h3>
        <div className={`${showFilter === "search" ? "d-none" : ""}`}>
          <button
            className={`${styles.mentionBtn} ${
              showFilter === "mentions" ? styles.btnSelected : ""
            }`}
            onClick={handleMentionClick}
          >
            <span className={`${styles.mentionText}`}>@</span>
            <span className={`px-1 ${styles.mentionText}`}>Mentions</span>
            <span
              className={`px-1 ${styles.mentionCount} ${styles.selectCount}`}
            >
              {metaData.unreadMentionsCount ?? 0}
            </span>
          </button>
        </div>
      </div>
      <div className={`${styles.searchMain}`}>
        <SearchBar
          value={searchText}
          className={`${styles.search}`}
          inputClassName={`${styles.input}`}
          placeholder={`Search Chats`}
          onChange={(e: any) => {
            setSearchText(e.target.value);
          }}
          onSearch={handelSearch}
        />
      </div>
      <div className="">
        {/* Tabs for filter by customer status */}
        <div
          className={`d-flex flex-wrap justify-content-between mt-3 ${
            styles.filterMain
          } ${
            showFilter === "allfilters" || showFilter === "mentions"
              ? ""
              : "d-none"
          }`}
        >
          <div
            className={`d-flex me-1 ${
              selectedCustomerStatus === "live" ? styles.filterActive : ""
            }`}
            id="liveTab"
            onClick={() => {
              if (selectedCustomerStatus !== "live") {
                handleStatusTabClick("live");
              }
            }}
            role="button"
          >
            <span className={`${styles.filterChat}`}>Live</span>
            {sidebar.liveChatIds && sidebar.liveChatIds != 0 ? (
              <div className={` ms-1 px-2 ${styles.quantity}`}>
                {sidebar.liveChatIds}
              </div>
            ) : null}
          </div>
          <div
            className={`d-flex me-1 ${
              selectedCustomerStatus === "missed" ? styles.filterActive : ""
            }`}
            id="missedTab"
            onClick={() => {
              if (selectedCustomerStatus !== "missed") {
                handleStatusTabClick("missed");
              }
            }}
            role="button"
          >
            <span className={`${styles.filterChat}`}>Missed</span>
            {sidebar.missedChatIds && sidebar.missedChatIds != 0 ? (
              <div className={`ms-1 px-2 ${styles.missed}`}>
                <img
                  className={`${styles.missLogo}`}
                  src={logo}
                  alt=""
                />
                <span className={`${styles.missChatNos}`}>
                  {sidebar.missedChatIds}
                </span>
              </div>
            ) : null}
          </div>
          <div
            className={`d-flex ${
              selectedCustomerStatus === "archived" ? styles.filterActive : ""
            }`}
            id="archivedTab"
            onClick={() => {
              if (selectedCustomerStatus !== "archived") {
                handleStatusTabClick("archived");
              }
            }}
            role="button"
          >
            <span className={`me-1 ${styles.filterChat}`}>Archived</span>
            {sidebar.archiveChatIds && sidebar.archiveChatIds != 0 ? (
              <div className={` ms-1 px-2 ${styles.quantity}`}>
                {sidebar.archiveChatIds}
              </div>
            ) : null}
          </div>
          {showFilter === "mentions" ? (
            <div className="w-100">
              <button className={`mb-2 ${styles.tags}`}>
                <span>Mentions</span>
                <span
                  className={`ps-1 ${styles.closeTag}`}
                  onClick={handleMentionClick}
                >
                  <i className="fa-solid fa-xmark"></i>
                </span>
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>
        {showFilter === "allfilters" && (
          <MultipleFilter
            setShowDropdown={setShowDropDown}
            showDropdown={showDropDown}
          />
        )}
        <div
          className={`p-2 justify-content-center ${styles.showHead} ${
            showFilter === "search" ? "" : "d-none"
          }`}
        >
          {chatIdList.length || fetchCustAJAXStatus === "fulfilled"
            ? `Showing results ${chatIdList.length} of ${
                currentState.current.metaData.searchTotalCount
                  ? currentState.current.metaData.searchTotalCount
                  : "all"
              }`
            : fetchCustAJAXStatus === "pending"
              ? "Loading Results..."
              : "Error Loading Results"}
        </div>
        <div className="pt-2">
          {/* Unseen archieved message box */}
          {!showArchNotification &&
          selectedCustomerStatus !== "archived" &&
          sidebar.archiveChatIds &&
          sidebar.archiveChatIds > 0 ? (
            <div
              className={`mb-1 ${styles.showWrapper}`}
              onClick={() => {
                handleStatusTabClick("archived");
              }}
              role="button"
            >
              <div
                className={`px-3 ${styles.archivedBox} w-100 d-flex align-items-center justify-content-between`}
              >
                <p className={`mb-0 text-truncate ${styles.textWrapper}`}>
                  <img
                    src={Midi}
                    alt=""
                    className={`${styles.midi}`}
                  />
                  <span className={`ps-1 ${styles.chatText}`}>
                    {sidebar.archiveChatIds} Unseen Archived Chats
                  </span>
                </p>
                <span className={`${styles.closeChat} `}>
                  <i
                    className="fa-solid fa-xmark"
                    onClick={(e) => {
                      setShowArchNotification(true);
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  ></i>
                </span>
              </div>
            </div>
          ) : null}

          {/* Unseen missed message box */}

          {!showMissedNotification &&
          selectedCustomerStatus !== "missed" &&
          sidebar.missedChatIds &&
          sidebar.missedChatIds > 0 ? (
            <div
              className={`mb-1 ${styles.showWrapper}`}
              onClick={() => {
                handleStatusTabClick("missed");
              }}
              role="button"
            >
              <div
                className={`px-3 ${styles.archivedBox} d-flex align-items-center justify-content-between`}
              >
                <p className={`mb-0 text-truncate  ${styles.textWrapper}`}>
                  <img
                    src={Missed}
                    alt=""
                    className={`${styles.missedLogo}`}
                  />
                  <span className={`ps-1 ${styles.missedText}`}>
                    {sidebar.missedChatIds} Unseen Missed Chats
                  </span>
                </p>
                <span className={`${styles.closeChat}`}>
                  <i
                    className="fa-solid fa-xmark"
                    onClick={(e) => {
                      setShowMissedNotification(true);
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  ></i>
                </span>
              </div>
            </div>
          ) : null}
        </div>
        <InfiniteScroll
          // key={uuid()}
          initialDataLoaded={true}
          loadMore={handleInfiniteScroll}
          className={`pt-1 ${
            hasSearchValue
              ? styles.contentSbWhenSearched
              : hasFilteredApplied
                ? styles.contentSbFiltersApplied && !showDropDown
                : styles.contentSB
          } ${InfiniteScrollStyles}`}
          // Hiding the flex class in infinite scroll when data is prepent to make it smooth. It is kept there to show the loader in middle
          hideClassDisplayFlex={
            showLoader === false &&
            showError === false &&
            showErrorRej === false
          }
          hasMore={hasMore}
          loader={
            showLoader ? (
              <div className={`${styles.loading} m-auto`}>
                <Spinner
                  animation="border"
                  variant="primary"
                  size="sm"
                  className={`d-block m-auto mb-2`}
                />
                <span>Loading chats...</span>
              </div>
            ) : showLoadMore ? (
              // Rendering the load button conditionally.
              <div
                className={`${styles.loaderText}`}
                onClick={handleInfiniteScroll}
              >
                <span>Load More</span>
                <i className="ms-1 fa-solid fa-rotate-right"></i>
              </div>
            ) : (
              // Rendering the load text conditionally.
              <div className={`${styles.loadingText}`}>
                <span>Loading...</span>
                <div>
                  <Spinner
                    className={`ms-1 ${styles.spinner}`}
                    size="sm"
                    animation="border"
                  />
                </div>
              </div>
            )
          }
          errorMessage={
            showError ? (
              <div className={`${styles.nothingFound} m-auto`}>
                <img
                  src={EmptySideBar}
                  className={`d-block m-auto`}
                />
                <p className={`${styles.backtext} mt-2 mb-0`}>
                  No{" "}
                  {filters.searchValue.trim()
                    ? searched.heading
                    : selectedCustomerStatus === "live"
                      ? live.heading
                      : selectedCustomerStatus === "missed"
                        ? missed.heading
                        : archived.heading}{" "}
                  Chats
                </p>
                <p className={`${styles.subBacktext} d-block mt-1`}>
                  {filters.searchValue.trim()
                    ? searched.subHeading
                    : selectedCustomerStatus === "live"
                      ? live.subHeading
                      : selectedCustomerStatus === "missed"
                        ? missed.subHeading
                        : archived.subHeading}
                </p>
              </div>
            ) : showErrorRej ? (
              <div className={`${styles.nothingFound} m-auto`}>
                <img
                  src={EmptySideBar}
                  className={`d-block m-auto`}
                />
                <p className={`${styles.backtext} mt-2 mb-0`}>
                  {`We were unable to load this section. Please try again by reloading the page.`}
                </p>
              </div>
            ) : (
              ""
            )
          }
        >
          {/* If no errors or not loading then only load data. */}
          {showLoader === false &&
            showError === false &&
            showErrorRej === false && (
              // Showing the data with a height to make sure the data should always scroll if window height is larger.
              <div
                className="d-flex flex-column justify-content-top"
                style={{
                  minHeight:
                    chatIdList.length >= parseInt(fetchCustomerLimit + "")
                      ? "calc(100vh - 200px)"
                      : "auto",
                  height: "auto",
                }}
              >
                {chatIdList &&
                  chatIdList.map((chatId) => {
                    const customer = customers[chatId];

                    if (customer) {
                      return (
                        <div
                          key={"customer_sb_" + customer.chatId}
                          onClick={() => {
                            if (currentChatId != customer.chatId) {
                              dispatch(
                                actions.setActiveChatId({
                                  id: customer.chatId,
                                }),
                              );
                              dispatch(
                                setActiveCustomerInfo({
                                  customerInfo: customer,
                                }),
                              );
                              navigate(
                                window.location.pathname.replace(
                                  `chats/${chatStatus}/${currentChatId}`,
                                  `chats/${chatStatus}/${customer.chatId}`,
                                ),
                              );
                            }
                          }}
                        >
                          {
                            // If the showFilter is set to "mentions", render the SearchMention component.
                            showFilter === "mentions" ? (
                              <SearchMention {...customer} />
                            ) : // If the chatStatus is "chat_in_progress_ai", render the ChatBotHandle component.
                            customer.chatStatus &&
                              customer.chatStatus === "chat_in_progress_ai" ? (
                              <div role="button">
                                <ChatBotHandle customer={customer} />
                              </div>
                            ) : (
                              // For other cases, render the LiveConversation component.
                              <>
                                <LiveConversation
                                  customer={customer}
                                  setResolveAndCreateTicket={
                                    setResolveAndCreateTicket
                                  }
                                  onShow={onShow}
                                  setSelectedChat={setSelectedChat}
                                />
                              </>
                            )
                          }
                        </div>
                      );
                    }
                  })}
              </div>
            )}
        </InfiniteScroll>

        {selectedChat && (
          <Modal
            backdropClassName={`${styles.modalBack}`}
            show={showModal}
            onHide={onHide}
            dialogClassName={`${styles.modalDialog}`}
            contentClassName={`${styles.modalContent}`}
            enforceFocus={false}
          >
            {showTicketSuccess === true ? (
              <>
                <SuccessMsg
                  createdTicketId={createdTicketId}
                  onHide={onHide}
                  resolveAndCreateTicket={resolveAndCreateTicket}
                />
              </>
            ) : (
              <CreateTicket
                key={selectedChat.chatId}
                chatId={selectedChat?.chatId}
                selectedCustomerEmail={selectedChat.email}
                onHide={onHide}
                resolve={resolveAndCreateTicket}
                heading={
                  resolveAndCreateTicket == true
                    ? "Resolve and create a ticket:"
                    : "Create a ticket:"
                }
                subHeading={
                  resolveAndCreateTicket == true
                    ? "The chat will be marked as resolved and a ticket will be created."
                    : "A ticket will be created for this chat."
                }
                submitBtn={
                  resolveAndCreateTicket == true
                    ? "Resolve and create a ticket"
                    : "Create a ticket"
                }
                onSuccessCallback={(createdTicketId: number | string) => {
                  setShowTicketSuccess(true);
                  setCreatedTicketId(createdTicketId);
                }}
                isAnonymouseUser={selectedChat.isAnonymousCustomer ?? false} //Adding flag to find ananymous user
              />
            )}
          </Modal>
        )}
      </div>
    </div>
  );
};

export default LiveCustomerSB;
