import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Form, Modal, OverlayTrigger, Popover } from "react-bootstrap";
import ReactSelect, {
  ControlProps,
  OptionProps,
  SingleValueProps,
  components,
} from "react-select";
// import styles from "src/routes/Setting/children/MainSetting/children/SetRulesNew/childerns/childern/AddAutomations/AddAutomation.module.scss";
import Lottie from "lottie-light-react";
import penBlue from "src/assets/images/PenBlue.png";
import PendingImg from "src/assets/images/PendingImg.png";
import ripple from "src/assets/images/nextBtnGif.json";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import {
  EReturnAutomationHiddenKeys,
  EReturnAutomationQuestionKeys,
} from "src/enums/EReturnAutomationQuestionKeys";
import useClickAway from "src/hooks/useClickAway";
import useDebounce from "src/hooks/useDebounce";
import { updateReturnAutomationSingleQuestion } from "src/store/slices/initiateReturn/Thunks/returnExchangeModal.thunk";
import {
  ActiveModal,
  IReturnWindowValue,
  handleSingleQuestionVisibility,
  isSegementCompletedSelector,
  moveToNextStep,
  setActiveModal,
  setShowPreviewer,
  updateQuestionStepNine,
  updateStepConfigStatus,
  validateStepQuestions,
} from "src/store/slices/initiateReturn/retrunExchange.slice";
import { isStepTransferedToAgent } from "src/store/slices/initiateReturn/returnExchange.selectors";
import { pushInitialHash as pushStep10InitialHash } from "src/store/slices/initiateReturn/step10Configuration.slices";
import { pushInitialHash as pushStep9InitialHash } from "src/store/slices/initiateReturn/step9Configuration.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { useQuestionAnsweredChecker } from "../../../CustomHooks/useQuestionAnsweredChecker";
import ConfigureElegibilityItem from "../../ConfigurationModels/ConfigureElegibilityItem/ConfigureElegibilityItem";
import ConfigureElegibilityStep1 from "../../ConfigurationModels/ConfigureElegibilityStep1/ConfigureElegibilityStep1";
import MoveNextModel from "../MoveNextModel/MoveNextModel";
import styles from "./AddAutomationStep1.module.scss";
import {
  handleValidateSteps,
  isValidMessage,
} from "src/store/slices/initiateReturn/Helpers/returnExchange.helpers";
import { getQuestionNumber } from "src/store/slices/initiateReturn/Helpers/returnExchange.helpers";
import { useReturnPreviewState } from "../WidgetPreviewSection/hooks/useReturnPreview";

// Interface representing an option with a value and label
interface Option {
  value: string | number;
  label: string;
}

// CustomControl component for rendering the control container
export const CustomControl: React.FC<ControlProps<Option>> = ({
  children,
  innerRef,
  innerProps,
  isFocused,
  className,
}) => {
  return (
    <div
      ref={innerRef}
      className={`custom-control-container`}
    >
      <div
        {...innerProps}
        className={className}
      >
        {children}
      </div>
    </div>
  );
};

// CustomOption component for rendering each option in the dropdown menu
export const CustomOption: React.FC<OptionProps<Option>> = (props) => (
  <components.Option {...props}>
    <div className={`${styles.items}`}>
      <span>{props.data?.label}</span>
    </div>
  </components.Option>
);

// CustomSingleValue component for rendering the selected value in the dropdown
export const CustomSingleValue: React.FC<SingleValueProps<Option>> = (
  props,
) => (
  <components.SingleValue {...props}>
    <div className={`${styles.items}`}>
      <span>{props.data?.label}</span>
    </div>
  </components.SingleValue>
);

// CustomDropdownIndicator component for rendering the dropdown indicator icon
export const CustomDropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <i
        className={`fa-solid ${styles.arrowColor} ${
          props.selectProps.menuIsOpen ? "fa-caret-up" : "fa-caret-down"
        }`}
      ></i>
    </components.DropdownIndicator>
  );
};

const STEP_ID = 9;

function Step1() {
  const [showModel, setShowModel] = useState(false);
  const [showConfigModal1, setShowConfigModal1] = useState(false);

  const [moveNext, setMoveNext] = useState(false);

  const [returnPolicySameForAllItems, setReturnPolicySameForAllItems] =
    useState<null | boolean>(null);
  const [returnPolicySameForAllOrders, setReturnPolicySameForAllOrders] =
    useState<null | boolean>(null);

  const [selectedDayType, setSelectedDayType] = useState<null | {
    label: string;
    value: string;
  }>(null);

  const [selectedAfter, setSelectedAfter] = useState<null | {
    label: string;
    value: string;
  }>(null);

  const dispatch = useAppDispatch();

  // step questions
  const stepQuestions = useAppSelector(
    (state) => state.returnExchange.step9.stepData.stepQuestions,
  );
  //active step
  const selectedStep = useAppSelector(
    (state) => state.returnExchange.selectedStep,
  );

  const step9ConfigStatus = useAppSelector(
    (state) => state.returnExchange.step9.stepData.configStatus,
  );
  const step10ConfigStatus = useAppSelector(
    (state) => state.returnExchange.step10.stepData.configStatus,
  );

  const { step11Visibility, nextStepTransferedStatus } = useAppSelector(
    (state) => {
      const step11TransferedStatus = isStepTransferedToAgent(state, 11);
      return {
        step11Visibility:
          state.returnExchange.step11.showStep === true &&
          step11TransferedStatus === false,
        nextStepTransferedStatus: step11TransferedStatus,
      };
    },
  );

  const goLiveStepVisiblity = useAppSelector(
    (state) => state.returnExchange.showStepGoLive,
  );

  const showErrors = useAppSelector((state) => state.returnExchange.showErrors);

  const [enableStep9Config, setEnableStep9Config] = useState(true);
  const [enableStep10Config, setEnableStep10Config] = useState(true);
  const [showPopover, setShowPopover] = useState(true);

  const returnWindowStepError =
    stepQuestions[EReturnAutomationQuestionKeys.returnWindow].error;

  useEffect(() => {
    if (returnWindowStepError) {
      setShowPopover(true);
    } else {
      setShowPopover(false);
    }
  }, [returnWindowStepError]);

  const popover =
    returnWindowStepError && returnWindowStepError.length > 0 ? (
      <Popover
        id="popover-basic"
        className="rounded-3"
      >
        <Popover.Body className="d-flex flex-row">
          <div className="border px-2 bg-warning text-white me-1 rounded-3 ">
            !
          </div>
          <div className="mt-1">
            {stepQuestions[EReturnAutomationQuestionKeys.returnWindow].error}
          </div>
        </Popover.Body>
      </Popover>
    ) : (
      <div></div>
    );

  //getting this compontent questions, answered or not flag from custom hook
  const {
    isReturnPolicySameForAllItemAnswered,
    returnWindowAnswered,
    returnNotEligibleMessageAnswered,
    isReturnPolicySameForAllOrdersAnswered,
    updateSelectedStep,
  } = useQuestionAnsweredChecker();

  const { updateContext, clearModalData } = useReturnPreviewState();

  const prevMsg = useRef<string | undefined>(undefined);

  // Debounce the term with a 500ms delay
  const debouncedTerm = useDebounce(
    (stepQuestions[
      EReturnAutomationQuestionKeys.returnNotEligibleResponseMessage
    ].value as string) ?? "",
    500,
  );

  useEffect(() => {
    // Check if the previous message is different from the current debounced message
    if (prevMsg.current !== undefined && prevMsg.current !== debouncedTerm) {
      // Only dispatch if the message is valid
      if (isValidMessage(debouncedTerm)) {
        dispatch(
          updateReturnAutomationSingleQuestion({
            questionKey:
              EReturnAutomationQuestionKeys.returnNotEligibleResponseMessage,
            questionValue: debouncedTerm,
            stepId: STEP_ID,
            updateContext,
          }),
        );
      }
    }
    // Update the previous message with the current debounced message
    prevMsg.current = debouncedTerm;
  }, [debouncedTerm, dispatch]);

  useEffect(() => {
    if (stepQuestions) {
      // is return policy same for all items
      if (
        stepQuestions[
          EReturnAutomationQuestionKeys.isReturnPolicySameForAllItem
        ]
      ) {
        setReturnPolicySameForAllItems(
          stepQuestions[
            EReturnAutomationQuestionKeys.isReturnPolicySameForAllItem
          ].value === true
            ? true
            : stepQuestions[
                  EReturnAutomationQuestionKeys.isReturnPolicySameForAllItem
                ].value === null
              ? null
              : false,
        );
      }

      // is return policy same for all orders
      if (
        stepQuestions[
          EReturnAutomationQuestionKeys.isReturnPolicySameForAllOrders
        ]
      ) {
        setReturnPolicySameForAllOrders(
          stepQuestions[
            EReturnAutomationQuestionKeys.isReturnPolicySameForAllOrders
          ].value === true
            ? true
            : stepQuestions[
                  EReturnAutomationQuestionKeys.isReturnPolicySameForAllOrders
                ].value === null
              ? null
              : false,
        );
      }

      // is return policy same for all orders
      // if (
      //   stepQuestions[EReturnAutomationQuestionKeys.canItemNotEligibleForReturn]
      // ) {
      //   setCanItemNotEligibleForReturn(
      //     stepQuestions[
      //       EReturnAutomationQuestionKeys.canItemNotEligibleForReturn
      //     ].value === true
      //       ? true
      //       : false
      //   );
      // }

      // question type return window
      if (stepQuestions[EReturnAutomationQuestionKeys.returnWindow]) {
        let retWindowValue: IReturnWindowValue = stepQuestions[
          EReturnAutomationQuestionKeys.returnWindow
        ].value as IReturnWindowValue;

        setSelectedDayType({
          label:
            retWindowValue.day_type === "business_days"
              ? "business days"
              : "days",
          value: retWindowValue.day_type,
        });
      }

      // question type return window
      if (stepQuestions[EReturnAutomationQuestionKeys.returnWindow]) {
        let retWindowValue: IReturnWindowValue = stepQuestions[
          EReturnAutomationQuestionKeys.returnWindow
        ].value as IReturnWindowValue;

        setSelectedAfter({
          label:
            retWindowValue.after === "order_placed"
              ? "order placed"
              : "order delivered",
          value: retWindowValue.after,
        });
      }

      // if (
      //   stepQuestions[
      //     EReturnAutomationQuestionKeys.returnNotEligibleResponseMessage
      //   ]
      // ) {
      //   let message: string = stepQuestions[
      //     EReturnAutomationQuestionKeys.returnNotEligibleResponseMessage
      //   ].value as string;

      //   setReturnNotEligibleMessageTxt(message);
      // }
    }
  }, [stepQuestions]);

  const handleDayTypeChange = (selectedValue: any) => {
    let retWindowValue: IReturnWindowValue = stepQuestions[
      EReturnAutomationQuestionKeys.returnWindow
    ].value as IReturnWindowValue;

    dispatch(
      updateQuestionStepNine({
        question: {
          ...stepQuestions[EReturnAutomationQuestionKeys.returnWindow],
          value: {
            ...retWindowValue,
            day_type: selectedValue.value,
          },
        },
      }),
    );
    if (retWindowValue.day_type !== selectedValue.value) {
      dispatch(
        updateReturnAutomationSingleQuestion({
          questionKey: EReturnAutomationQuestionKeys.returnWindow,
          questionValue: {
            ...retWindowValue,
            day_type: selectedValue.value,
          },
          stepId: 9,
          updateContext,
        }),
      );
    }
  };

  const handleAfterTypeChange = (selectedValue: any) => {
    let retWindowValue: IReturnWindowValue = stepQuestions[
      EReturnAutomationQuestionKeys.returnWindow
    ].value as IReturnWindowValue;

    dispatch(
      updateQuestionStepNine({
        question: {
          ...stepQuestions[EReturnAutomationQuestionKeys.returnWindow],
          value: {
            ...retWindowValue,
            after: selectedValue.value,
          },
        },
      }),
    );
    if (retWindowValue.after !== selectedValue.value) {
      dispatch(
        updateReturnAutomationSingleQuestion({
          questionKey: EReturnAutomationQuestionKeys.returnWindow,
          questionValue: {
            ...retWindowValue,
            after: selectedValue.value,
          },
          stepId: STEP_ID,
          updateContext,
        }),
      );
    }
  };

  useEffect(() => {
    if (step9ConfigStatus === "pending") {
      setEnableStep9Config(true);
    } else {
      setEnableStep9Config(false);
    }
  }, [step9ConfigStatus]);

  useEffect(() => {
    if (step10ConfigStatus === "pending") {
      setEnableStep10Config(true);
    } else {
      setEnableStep10Config(false);
    }
  }, [step10ConfigStatus]);

  const isSegementCompleted = useAppSelector((state) =>
    isSegementCompletedSelector(state, 1),
  );

  const notElibileInputRef = useRef(null);

  useClickAway(notElibileInputRef, () => {
    dispatch(
      setShowPreviewer({
        showNotEligible: false,
      }),
    );
  });

  function setActiveModalState(
    activeModal: ActiveModal | null,
    activeSubModal = null,
  ) {
    dispatch(setActiveModal({ activeModal, activeSubModal }));
  }

  const dayTypeOptions = useMemo(
    () => [
      { label: "business days", value: "business_days" },
      { label: "days", value: "days" },
    ],
    [],
  );

  const afterTypeOptions = useMemo(
    () => [
      { label: "order placed", value: "order_placed" },
      { label: "order delivered", value: "order_delivered" },
    ],
    [],
  );

  const CustomControlDaysWrapper = useCallback(
    (props: any) => (
      <CustomControl
        {...props}
        className={`custom-control mb-1 ${styles.brandDrop} ${styles.daysDrop}`}
      />
    ),
    [],
  );

  const CustomControlOrderWrapper = useCallback(
    (props: any) => (
      <CustomControl
        {...props}
        className={`custom-control mb-1 ${styles.brandDrop} ${styles.orderDrop}`}
      />
    ),
    [],
  );

  return (
    <div
      className={`${styles.questionContainer} ${
        showErrors ? styles.canShowErrors : ""
      }`}
      onClick={() => updateSelectedStep(STEP_ID)}
    >
      <div
        className={`px-3 ${styles.selectIdBox} ${styles.stepNumber} ${
          isSegementCompleted === true ? styles.completed : ""
        } ${selectedStep === STEP_ID ? styles.active : ""} pb-2 pt-3 mb-2 `}
      >
        <div
          className={`${styles.activeLine}`}
          role="button"
        >
          <span
            className={`${
              selectedStep === STEP_ID
                ? styles.selectText
                : styles.changeTextColor
            }`}
          >
            Select related order id /number to return
          </span>
          <p className={`mb-3 d-inline-block ${styles.selectPara}`}>
            In this step ask customers among all their order which order they
            want to return/exchange for
          </p>
        </div>

        {/* checking if questions visibility is collapsed */}
        {selectedStep === STEP_ID && (
          <div>
            <div>
              {/* Question1 */}
              {!EReturnAutomationHiddenKeys.includes(
                stepQuestions[EReturnAutomationQuestionKeys.returnWindow].key,
              ) &&
                stepQuestions[EReturnAutomationQuestionKeys.returnWindow]
                  .showQuestion === true && (
                  <div>
                    <div
                      className={`pb-3 ${styles.query} ${
                        returnWindowAnswered === true ? styles.activeLine : ""
                      }
                  ${
                    stepQuestions[EReturnAutomationQuestionKeys.returnWindow]
                      .error
                      ? styles.errorLine
                      : ""
                  }`}
                      data-question-key={`${
                        stepQuestions[
                          EReturnAutomationQuestionKeys.returnWindow
                        ].key
                      }`}
                    >
                      <div className={`mb-2 mt-2 ${styles.questionsHead}`}>
                        Q
                        {getQuestionNumber(
                          EReturnAutomationQuestionKeys.returnWindow,
                          false,
                        )}
                        . What is the returns window that you use for a majority
                        or all of your orders?
                      </div>
                      <div
                        className={`d-flex flex-column flex-md-row align-items-center flex-wrap mb-0`}
                      >
                        <div
                          className={`px-1 text-center mb-1 ${styles.afterText}`}
                        >
                          Within
                        </div>
                        <div className={`mb-1`}>
                          <div
                            className={`d-flex justify-content-between align-items-center py-1 mx-lg-2 ${
                              styles.daysCounter
                            }   ${
                              stepQuestions[
                                EReturnAutomationQuestionKeys.returnWindow
                              ].error
                                ? "border-danger shadow-none"
                                : ""
                            }`}
                          >
                            <div
                              className="px-1"
                              role="button"
                            >
                              <div
                                className={`d-flex align-items-center justify-content-center me-2 me-md-0 ${styles.addMore}`}
                                onClick={(e) => {
                                  let retWindowValue: IReturnWindowValue =
                                    stepQuestions[
                                      EReturnAutomationQuestionKeys.returnWindow
                                    ].value as IReturnWindowValue;

                                  let days: number = retWindowValue.days - 1;

                                  if (days > 0) {
                                    dispatch(
                                      updateQuestionStepNine({
                                        question: {
                                          ...stepQuestions[
                                            EReturnAutomationQuestionKeys
                                              .returnWindow
                                          ],
                                          value: {
                                            ...retWindowValue,
                                            days: days,
                                          },
                                        },
                                      }),
                                    );
                                    // dispatch(validateStepQuestions());
                                    // Only calling api for valid days
                                    dispatch(
                                      updateReturnAutomationSingleQuestion({
                                        questionKey:
                                          EReturnAutomationQuestionKeys.returnWindow,
                                        questionValue: {
                                          ...retWindowValue,
                                          days: days,
                                        },
                                        stepId: STEP_ID,
                                        updateContext,
                                      }),
                                    );
                                  }
                                }}
                                style={{
                                  pointerEvents:
                                    (
                                      stepQuestions[
                                        EReturnAutomationQuestionKeys
                                          .returnWindow
                                      ].value as IReturnWindowValue
                                    ).days <= 0
                                      ? "none"
                                      : "auto",
                                  opacity:
                                    (
                                      stepQuestions[
                                        EReturnAutomationQuestionKeys
                                          .returnWindow
                                      ].value as IReturnWindowValue
                                    ).days <= 0
                                      ? 0.5
                                      : 1,
                                }}
                              >
                                <span className="d-block">
                                  {" "}
                                  <i className="fa-solid fa-minus"></i>
                                </span>
                              </div>
                            </div>
                            <div className="px-1">
                              {/*  19 Jan Added to show popover when return window days is 0 */}
                              <OverlayTrigger
                                show={showPopover}
                                placement="bottom"
                                overlay={popover}
                                rootClose={true}
                                rootCloseEvent="mousedown"
                                // defaultShow={showPopover} // Set the defaultShow property based on the state variable
                                onToggle={(isShown) => {
                                  // Set the state variable based on the popover visibility
                                  setShowPopover(isShown);
                                }}
                              >
                                <div className={`px-1 ${styles.selectItem}`}>
                                  {/* Display the days with a leading zero if it is a single digit */}
                                  {(
                                    stepQuestions[
                                      EReturnAutomationQuestionKeys.returnWindow
                                    ].value as IReturnWindowValue
                                  ).days < 10
                                    ? `0${
                                        (
                                          stepQuestions[
                                            EReturnAutomationQuestionKeys
                                              .returnWindow
                                          ].value as IReturnWindowValue
                                        ).days
                                      }`
                                    : (
                                        stepQuestions[
                                          EReturnAutomationQuestionKeys
                                            .returnWindow
                                        ].value as IReturnWindowValue
                                      ).days}
                                </div>
                              </OverlayTrigger>
                            </div>
                            <div
                              className="px-1"
                              role="button"
                            >
                              <div
                                className={`d-flex align-items-center justify-content-center me-2 me-md-0 ${styles.addMore}`}
                                onClick={(e) => {
                                  let retWindowValue: IReturnWindowValue =
                                    stepQuestions[
                                      EReturnAutomationQuestionKeys.returnWindow
                                    ].value as IReturnWindowValue;
                                  dispatch(
                                    updateQuestionStepNine({
                                      question: {
                                        ...stepQuestions[
                                          EReturnAutomationQuestionKeys
                                            .returnWindow
                                        ],
                                        value: {
                                          ...retWindowValue,
                                          days: retWindowValue.days + 1,
                                        },
                                      },
                                    }),
                                  );
                                  // dispatch(validateStepQuestions());
                                  dispatch(
                                    updateReturnAutomationSingleQuestion({
                                      questionKey:
                                        EReturnAutomationQuestionKeys.returnWindow,
                                      questionValue: {
                                        ...retWindowValue,
                                        days: retWindowValue.days + 1,
                                      },
                                      stepId: STEP_ID,
                                      updateContext,
                                    }),
                                  );
                                }}
                              >
                                <span className="d-block">
                                  {" "}
                                  <i className="fa-solid fa-plus"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={`mb-1 mb-md-0`}>
                          <ReactSelect
                            menuPortalTarget={document.body}
                            //Added for react-select to solve Z-index issue.
                            options={dayTypeOptions}
                            value={selectedDayType}
                            styles={customStyles}
                            onChange={handleDayTypeChange}
                            className={`${styles.selectItem}`}
                            components={{
                              Option: CustomOption,
                              SingleValue: CustomSingleValue,
                              Control: CustomControlDaysWrapper,
                              DropdownIndicator: CustomDropdownIndicator,
                              IndicatorSeparator: null,
                            }}
                          />
                        </div>
                        <div
                          className={`px-1 text-center mb-1 ${styles.afterText}`}
                        >
                          after
                        </div>
                        <div className={`mb-1 mb-md-0`}>
                          <ReactSelect
                            menuPortalTarget={document.body}
                            //Added for react-select to solve Z-index issue.
                            options={afterTypeOptions}
                            onChange={handleAfterTypeChange}
                            value={selectedAfter}
                            styles={customStyles}
                            components={{
                              Option: CustomOption,
                              SingleValue: CustomSingleValue,
                              Control: CustomControlOrderWrapper,
                              DropdownIndicator: CustomDropdownIndicator,
                              IndicatorSeparator: null,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

              {/* Question2 */}
              {!EReturnAutomationHiddenKeys.includes(
                stepQuestions[
                  EReturnAutomationQuestionKeys.returnNotEligibleResponseMessage
                ].key,
              ) &&
                stepQuestions[
                  EReturnAutomationQuestionKeys.returnNotEligibleResponseMessage
                ].showQuestion === true && (
                  <div>
                    <div
                      className={`${styles.query} ${
                        returnNotEligibleMessageAnswered === true
                          ? styles.activeLine
                          : ""
                      } ${
                        stepQuestions[
                          EReturnAutomationQuestionKeys
                            .returnNotEligibleResponseMessage
                        ].error
                          ? styles.errorLine
                          : ""
                      }`}
                      data-question-key={`${
                        stepQuestions[
                          EReturnAutomationQuestionKeys
                            .returnNotEligibleResponseMessage
                        ].key
                      }`}
                    >
                      <span className={`mt-2 ${styles.questionsHead}`}>
                        Q
                        {getQuestionNumber(
                          EReturnAutomationQuestionKeys.returnNotEligibleResponseMessage,
                          false,
                        )}
                        . Reason to be communicated if an order is not eligible
                        for return/exchange:
                      </span>
                      <Form>
                        <Form.Group
                          className={`mb-3 mt-2 ${styles.textareaWrapper}`}
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <Form.Control
                            ref={notElibileInputRef}
                            as="textarea"
                            style={{
                              resize: "none",
                              font: "normal normal normal 12px/19px Poppins",
                            }}
                            rows={3}
                            className={` ${
                              stepQuestions[
                                EReturnAutomationQuestionKeys
                                  .returnNotEligibleResponseMessage
                              ].error
                                ? "border-danger shadow-none"
                                : ""
                            }`}
                            placeholder="write your message..."
                            key={
                              EReturnAutomationQuestionKeys.returnNotEligibleResponseMessage
                            }
                            onChange={(e) => {
                              dispatch(
                                updateQuestionStepNine({
                                  question: {
                                    ...stepQuestions[
                                      EReturnAutomationQuestionKeys
                                        .returnNotEligibleResponseMessage
                                    ],
                                    value: e.target.value,
                                  },
                                }),
                              );
                              dispatch(validateStepQuestions());
                            }}
                            value={
                              (stepQuestions[
                                EReturnAutomationQuestionKeys
                                  .returnNotEligibleResponseMessage
                              ].value as string) ?? ""
                            }
                            onFocus={() => {
                              dispatch(
                                setShowPreviewer({ showNotEligible: true }),
                              );
                            }}
                          />
                          {/*  19 Jan Added to show error when message is empty */}
                          {stepQuestions[
                            EReturnAutomationQuestionKeys
                              .returnNotEligibleResponseMessage
                          ].error && (
                            <span className={`${styles.errorText}`}>
                              {
                                stepQuestions[
                                  EReturnAutomationQuestionKeys
                                    .returnNotEligibleResponseMessage
                                ].error
                              }
                            </span>
                          )}
                        </Form.Group>
                      </Form>
                    </div>
                  </div>
                )}

              {/* Question 3 */}

              {!EReturnAutomationHiddenKeys.includes(
                stepQuestions[
                  EReturnAutomationQuestionKeys.isReturnPolicySameForAllOrders
                ].key,
              ) &&
                stepQuestions[
                  EReturnAutomationQuestionKeys.isReturnPolicySameForAllOrders
                ] !== undefined &&
                stepQuestions[
                  EReturnAutomationQuestionKeys.isReturnPolicySameForAllOrders
                ].showQuestion === true && (
                  <div>
                    <div
                      className={`${styles.query} ${
                        isReturnPolicySameForAllOrdersAnswered === true
                          ? styles.activeLine
                          : ""
                      }
                    ${
                      stepQuestions[
                        EReturnAutomationQuestionKeys
                          .isReturnPolicySameForAllOrders
                      ].error
                        ? styles.errorLine
                        : ""
                    } mb-2
                    `}
                      data-question-key={`${
                        stepQuestions[
                          EReturnAutomationQuestionKeys
                            .isReturnPolicySameForAllOrders
                        ].key
                      }`}
                    >
                      <span className={`${styles.questionsHead} mb-2`}>
                        Q
                        {getQuestionNumber(
                          EReturnAutomationQuestionKeys.isReturnPolicySameForAllOrders,
                          false,
                        )}
                        . Is the return policy the same for all orders
                        regardless of the shipping method used, discount code
                        used or any other factors?
                      </span>
                      <div className={`d-flex align-items-center mb-0`}>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault7"
                            id="flexRadioDefault7"
                            style={{
                              fontSize: "13px",
                              marginTop: "6px",
                              cursor: "pointer",
                            }}
                            checked={
                              returnPolicySameForAllOrders === null
                                ? undefined
                                : returnPolicySameForAllOrders === true
                            }
                            onChange={() => {}}
                            onClick={(e) => {
                              dispatch(
                                updateQuestionStepNine({
                                  question: {
                                    ...stepQuestions[
                                      EReturnAutomationQuestionKeys
                                        .isReturnPolicySameForAllOrders
                                    ],
                                    value: true,
                                  },
                                }),
                              );
                              dispatch(validateStepQuestions());
                              if (returnPolicySameForAllOrders !== true) {
                                dispatch(
                                  updateReturnAutomationSingleQuestion({
                                    questionKey:
                                      EReturnAutomationQuestionKeys.isReturnPolicySameForAllOrders,
                                    questionValue: true,
                                    stepId: STEP_ID,
                                    updateContext,
                                  }),
                                );
                              }
                            }}
                          />
                          <label
                            className={`form-check-label ${styles.questions} mb-0`}
                            htmlFor={"flexRadioDefault7"}
                          >
                            Yes
                          </label>
                        </div>
                      </div>
                      <div className={`d-flex align-items-center mb-0`}>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault9"
                            id="flexRadioDefault9"
                            style={{
                              fontSize: "13px",
                              marginTop: "6px",
                              cursor: "pointer",
                            }}
                            checked={
                              returnPolicySameForAllOrders === null
                                ? undefined
                                : returnPolicySameForAllOrders === false
                            }
                            onChange={() => {}}
                            onClick={(e) => {
                              dispatch(
                                updateQuestionStepNine({
                                  question: {
                                    ...stepQuestions[
                                      EReturnAutomationQuestionKeys
                                        .isReturnPolicySameForAllOrders
                                    ],
                                    value: false,
                                  },
                                }),
                              );
                              dispatch(validateStepQuestions());
                              if (returnPolicySameForAllOrders !== false) {
                                dispatch(
                                  updateReturnAutomationSingleQuestion({
                                    questionKey:
                                      EReturnAutomationQuestionKeys.isReturnPolicySameForAllOrders,
                                    questionValue: false,
                                    stepId: STEP_ID,
                                    updateContext,
                                  }),
                                );
                              }
                            }}
                          />
                          <label
                            className={`form-check-label ${styles.questions} mb-0`}
                            htmlFor={"flexRadioDefault9"}
                          >
                            No
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

              {/* Config for question 3 */}
              <div
                className={`pb-1 ${
                  (stepQuestions[
                    EReturnAutomationQuestionKeys.isReturnPolicySameForAllOrders
                  ].value === false &&
                    step9ConfigStatus === "completed") ||
                  stepQuestions[
                    EReturnAutomationQuestionKeys.isReturnPolicySameForAllOrders
                  ].value === true
                    ? styles.activeLine
                    : ""
                }
              ${
                stepQuestions[
                  EReturnAutomationQuestionKeys.isReturnPolicySameForAllOrders
                ].error
                  ? styles.errorLine
                  : ""
              }`}
              >
                {/* if question three is selected no, show config modal */}
                {stepQuestions[
                  EReturnAutomationQuestionKeys.isReturnPolicySameForAllOrders
                ].value === false && (
                  <div>
                    <span className={`${styles.selectPara} d-none pb-1`}>
                      Add order conditions may not be eligible for
                      return/exchange
                    </span>
                    <span className={`${styles.configuretext} d-block pb-1`}>
                      Configure return/exchange policy.
                    </span>
                  </div>
                )}

                <div className="d-flex flex-column flex-md-row">
                  {/* if question three is selected no, show config modal */}
                  {stepQuestions[
                    EReturnAutomationQuestionKeys.isReturnPolicySameForAllOrders
                  ].value === false && (
                    <div>
                      <div>
                        <button
                          className={`${styles.nextStepButton} btn px-3`}
                          disabled={!enableStep9Config}
                          onClick={() => {
                            setShowConfigModal1(true);
                            // To set in redux store
                            setActiveModalState("nonEligibleOrder");
                          }}
                        >
                          <div className="d-flex align-items-start">
                            <div>
                              {step9ConfigStatus === "completed" && (
                                <span
                                  className={`${styles.checkLogo} px-1 mx-1`}
                                >
                                  <i className="fa-solid fa-check"></i>
                                </span>
                              )}
                            </div>
                            <p className="mb-0">
                              <span className={`d-block ${styles.configBtn}`}>
                                {" "}
                                Configure non- eligible orders
                              </span>
                              <span className={`${styles.btnSubText}`}>
                                (for multiple order types)
                              </span>
                            </p>
                          </div>
                        </button>
                      </div>

                      {/* Add the config modal */}
                      <ConfigureElegibilityStep1
                        onHide={() => {
                          setShowConfigModal1(false);
                          // To clear orders in return preview context
                          clearModalData({
                            activeModal: "nonEligibleOrder",
                          });
                          // To set in redux store
                          setActiveModalState(null);
                        }}
                        showModal={showConfigModal1}
                        onSave={(isSaved) => {
                          dispatch(pushStep9InitialHash());
                          if (isSaved) {
                            dispatch(
                              updateStepConfigStatus({
                                stepId: STEP_ID,
                                status: "completed",
                              }),
                            );
                            pushTheToast({
                              type: "success",
                              text: "Configuration Saved",
                              position: "top-right",
                            });
                          }
                        }}
                      />
                    </div>
                  )}

                  {stepQuestions[
                    EReturnAutomationQuestionKeys.isReturnPolicySameForAllOrders
                  ].showQuestion === true &&
                    stepQuestions[
                      EReturnAutomationQuestionKeys
                        .isReturnPolicySameForAllOrders
                    ].value !== null &&
                    step9ConfigStatus !== "pending" &&
                    stepQuestions[
                      EReturnAutomationQuestionKeys.isReturnPolicySameForAllItem
                    ].showQuestion === false && (
                      <div>
                        <div className="ms-0 ms-md-2 mt-2 mt-md-0 mb-3">
                          <button
                            className={`${styles.moveBtn}`}
                            onClick={(e) => {
                              e.stopPropagation();
                              // disptach move to next question
                              dispatch(
                                handleSingleQuestionVisibility({
                                  questionKey:
                                    EReturnAutomationQuestionKeys.isReturnPolicySameForAllItem,
                                  stepId: STEP_ID,
                                }),
                              );
                            }}
                          >
                            <span style={{ width: "33px", height: "29px" }}>
                              <Lottie animationData={ripple}></Lottie>
                            </span>{" "}
                            <span> Move to next question</span>
                          </button>
                        </div>
                      </div>
                    )}
                </div>

                {stepQuestions[
                  EReturnAutomationQuestionKeys.isReturnPolicySameForAllOrders
                ].value === false && (
                  <div>
                    {step9ConfigStatus === "completed" ? (
                      <span
                        className={`${styles.editText} mb-2`}
                        role={"button"}
                        onClick={() => setEnableStep9Config(true)}
                      >
                        {" "}
                        <img
                          className={`${styles.brushImg} me-2`}
                          src={penBlue}
                          alt="msg"
                        />
                        Edit configuration
                      </span>
                    ) : (
                      <div className={`${styles.pending} mt-1 mb-2`}>
                        <img
                          src={PendingImg}
                          alt=""
                        />{" "}
                        Pending
                      </div>
                    )}
                  </div>
                )}
              </div>

              {/* Question 4 */}

              {stepQuestions[
                EReturnAutomationQuestionKeys.isReturnPolicySameForAllItem
              ] !== undefined &&
                stepQuestions[
                  EReturnAutomationQuestionKeys.isReturnPolicySameForAllItem
                ].showQuestion === true && (
                  <div>
                    <div
                      className={`${styles.query} ${
                        isReturnPolicySameForAllItemAnswered === true
                          ? styles.activeLine
                          : ""
                      }
                    ${
                      stepQuestions[
                        EReturnAutomationQuestionKeys
                          .isReturnPolicySameForAllItem
                      ].error
                        ? styles.errorLine
                        : ""
                    }
                    `}
                      data-question-key={`${
                        stepQuestions[
                          EReturnAutomationQuestionKeys
                            .isReturnPolicySameForAllItem
                        ].key
                      }`}
                    >
                      <span className={`${styles.questionsHead}`}>
                        Q4. Is the return policy the same for all the items that
                        you sell?
                      </span>
                      <div className={`d-flex align-items-center mb-0`}>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault10"
                            id="flexRadioDefault10"
                            style={{
                              fontSize: "13px",
                              marginTop: "6px",
                              cursor: "pointer",
                            }}
                            checked={
                              returnPolicySameForAllItems === null
                                ? undefined
                                : returnPolicySameForAllItems === true
                            }
                            onClick={(e) => {
                              dispatch(
                                updateQuestionStepNine({
                                  question: {
                                    ...stepQuestions[
                                      EReturnAutomationQuestionKeys
                                        .isReturnPolicySameForAllItem
                                    ],
                                    value: true,
                                  },
                                }),
                              );
                              dispatch(validateStepQuestions());
                              if (returnPolicySameForAllItems !== true) {
                                dispatch(
                                  updateReturnAutomationSingleQuestion({
                                    questionKey:
                                      EReturnAutomationQuestionKeys.isReturnPolicySameForAllItem,
                                    questionValue: true,
                                    stepId: STEP_ID,
                                    updateContext,
                                  }),
                                );
                              }
                            }}
                          />
                          <label
                            className={`form-check-label ${styles.questions} mb-0`}
                            htmlFor={"flexRadioDefault10"}
                          >
                            Yes
                          </label>
                        </div>
                      </div>
                      <div className={`d-flex align-items-center mb-0`}>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault11"
                            id="flexRadioDefault11"
                            style={{
                              fontSize: "13px",
                              marginTop: "6px",
                              cursor: "pointer",
                            }}
                            checked={
                              returnPolicySameForAllItems === null
                                ? undefined
                                : returnPolicySameForAllItems === false
                            }
                            onClick={(e) => {
                              dispatch(
                                updateQuestionStepNine({
                                  question: {
                                    ...stepQuestions[
                                      EReturnAutomationQuestionKeys
                                        .isReturnPolicySameForAllItem
                                    ],
                                    value: false,
                                  },
                                }),
                              );
                              dispatch(validateStepQuestions());
                              if (returnPolicySameForAllItems !== false) {
                                dispatch(
                                  updateReturnAutomationSingleQuestion({
                                    questionKey:
                                      EReturnAutomationQuestionKeys.isReturnPolicySameForAllItem,
                                    questionValue: false,
                                    stepId: STEP_ID,
                                    updateContext,
                                  }),
                                );
                              }
                            }}
                          />
                          <label
                            className={`form-check-label ${styles.questions} mb-0`}
                            htmlFor={"flexRadioDefault11"}
                          >
                            No
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
            </div>
            <div
              className={`${
                (stepQuestions[
                  EReturnAutomationQuestionKeys.isReturnPolicySameForAllOrders
                ].value === false &&
                  step10ConfigStatus === "completed") ||
                stepQuestions[
                  EReturnAutomationQuestionKeys.isReturnPolicySameForAllOrders
                ].value === true
                  ? styles.activeLine
                  : ""
              }
          ${
            stepQuestions[
              EReturnAutomationQuestionKeys.isReturnPolicySameForAllItem
            ].error
              ? styles.errorLine
              : ""
          }`}
            >
              {/* if question four is selected yes, show config modal */}
              {stepQuestions[
                EReturnAutomationQuestionKeys.isReturnPolicySameForAllItem
              ].value === false && (
                <div>
                  <span className={`${styles.selectPara} d-none pb-1`}>
                    Add order conditions may not be eligible for return/exchange
                  </span>
                  <span className={`pt-2 ${styles.configuretext} d-block pb-1`}>
                    Configure return/exchange policy.
                  </span>
                </div>
              )}

              <div className="d-flex flex-column flex-md-row">
                {/* if question four is selected no, show config modal */}
                {stepQuestions[
                  EReturnAutomationQuestionKeys.isReturnPolicySameForAllItem
                ].value === false && (
                  <div>
                    <div>
                      <button
                        className={`${styles.nextStepButton} btn px-3`}
                        disabled={!enableStep10Config}
                        onClick={() => {
                          setShowModel(true);
                          // To set in redux store
                          setActiveModalState("nonEligibleItems");
                        }}
                      >
                        <div>
                          {step10ConfigStatus === "completed" && (
                            <span className={`${styles.checkLogo} px-1 mx-1`}>
                              <i className="fa-solid fa-check"></i>
                            </span>
                          )}

                          <p className="mb-0">
                            <span className={`d-block ${styles.configBtn}`}>
                              {" "}
                              Configure non- eligible items
                            </span>
                            <span className={`${styles.btnSubText}`}>
                              (for multiple order types)
                            </span>
                          </p>
                        </div>
                      </button>
                    </div>

                    <ConfigureElegibilityItem
                      showModal={showModel}
                      onHide={() => {
                        setShowModel(false);
                        // To clear orders in return preview context
                        clearModalData({
                          activeModal: "nonEligibleItems",
                        });
                        // To set in redux store
                        setActiveModalState(null);
                      }}
                      onSave={(isSaved) => {
                        if (isSaved) {
                          dispatch(pushStep10InitialHash());
                          dispatch(
                            updateStepConfigStatus({
                              stepId: 10,
                              status: "completed",
                            }),
                          );
                          pushTheToast({
                            type: "success",
                            text: "Configuration Saved",
                            position: "top-right",
                          });
                        }
                      }}
                    />
                  </div>
                )}

                {/* {stepQuestions[
                  EReturnAutomationQuestionKeys.isReturnPolicySameForAllItem
                ].value != null &&
                  stepQuestions[
                    EReturnAutomationQuestionKeys.isReturnPolicySameForAllItem
                  ].value != null &&
                  stepQuestions[
                    EReturnAutomationQuestionKeys.isReturnPolicySameForAllOrders
                  ].value != null &&
                  stepQuestions[EReturnAutomationQuestionKeys.returnWindow]
                    .value != null &&
                  (stepQuestions[
                    EReturnAutomationQuestionKeys.isReturnPolicySameForAllItem
                  ].value === true ||
                    step10ConfigStatus !== "pending") &&
                  step11Visibility === false &&
                  goLiveStepVisiblity === false && ( */}
                {stepQuestions[EReturnAutomationQuestionKeys.returnWindow]
                  .showQuestion === true &&
                  stepQuestions[EReturnAutomationQuestionKeys.returnWindow]
                    .value != null &&
                  (
                    stepQuestions[EReturnAutomationQuestionKeys.returnWindow]
                      .value as IReturnWindowValue
                  ).days > 0 &&
                  (
                    stepQuestions[EReturnAutomationQuestionKeys.returnWindow]
                      .value as IReturnWindowValue
                  ).day_type &&
                  stepQuestions[
                    EReturnAutomationQuestionKeys
                      .returnNotEligibleResponseMessage
                  ].showQuestion === true &&
                  stepQuestions[
                    EReturnAutomationQuestionKeys
                      .returnNotEligibleResponseMessage
                  ].value &&
                  step11Visibility === false &&
                  goLiveStepVisiblity === false && (
                    <div>
                      <div className="ms-0 ms-md-2 mt-2 mt-md-0 mb-3">
                        <button
                          className={`${styles.moveBtn}`}
                          onClick={(e) => {
                            // June 21 Adding validation helper to validate step questions and move to next if it is validated
                            handleValidateSteps({
                              e,
                              stepId: STEP_ID,
                              nextStepTransferedStatus,
                              dispatch,
                              updateSelectedStep,
                              setMoveNext,
                            });
                          }}
                        >
                          <span style={{ width: "33px", height: "29px" }}>
                            <Lottie animationData={ripple}></Lottie>
                          </span>{" "}
                          <span> Move to next segment</span>
                        </button>
                      </div>
                    </div>
                  )}

                <Modal
                  show={moveNext}
                  onHide={() => {
                    setMoveNext(false);
                  }}
                  contentClassName={`${styles.modalMove}`}
                  centered={true}
                  enforceFocus={false}
                  backdropClassName={`${styles.backDrop}`}
                >
                  <MoveNextModel
                    onHide={() => {
                      setMoveNext(false);
                    }}
                  />
                </Modal>

                <div></div>
              </div>

              {stepQuestions[
                EReturnAutomationQuestionKeys.isReturnPolicySameForAllItem
              ].value === false && (
                <div>
                  {step10ConfigStatus === "completed" ? (
                    <span
                      className={`${styles.editText} mb-2`}
                      role={"button"}
                      onClick={() => setEnableStep10Config(true)}
                    >
                      {" "}
                      <img
                        className={`${styles.brushImg} me-2`}
                        src={penBlue}
                        alt="msg"
                      />
                      Edit configuration
                    </span>
                  ) : (
                    <div className={`${styles.pending} mt-1 mb-2`}>
                      <img
                        src={PendingImg}
                        alt=""
                      />{" "}
                      Pending
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
// custom styles css
const customStyles = {
  // Other styles for the React Select can be defined here

  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#f5f5f5" : "#fff", // Change the hover color here
    "&:hover": {
      backgroundColor: "#f5f5f5", // Change the hover color here as well
    },
    font: "normal normal normal 14px/16px Poppins",
    padding: "10px",
    color: "black",
  }),
  menuList: (provided: any) => ({
    ...provided,
    margin: "0 5px", // Add 5px margin on the left and right sides of the menu
  }),
};

export default Step1;
