import { axiosJSON } from "src/globals/axiosEndPoints";

const updateEscalateMsgStatus = async (payload: {
  messageId: number;
  status: "paused" | "cancelled" | "completed";
}) => {
  const { data: res } = await axiosJSON.post(
    `/api/message/updateEscalateMsgStatus`,
    payload,
  );

  if (res.err || res.error) {
    throw res.msg ?? res.message ?? "Something went wrong!";
  }

  return res.data;
};

export default updateEscalateMsgStatus;
