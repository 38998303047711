import { axiosJSON } from "src/globals/axiosEndPoints";

export interface ICreateTicketFromChatParams {
  chatRequestId: string | number;
  ticketSubject: string;
  ticketBrand: string;
  ticketDescription?: string; //optional,
  sendEmail?: boolean; //optional
  threadText?: string; // optional
  internalNote?: string; // optional
  cc?: Array<string>; // optional
  bcc?: Array<string>; // optional
  resolve?: boolean; // optional
  customerName?: string; // optional
  customerEmail?: string; // optional
}

export interface ICreateTicketFromChatRes {
  ticket_id: string | number;
}

export async function createTicketFromChat(
  payload: ICreateTicketFromChatParams,
) {
  try {
    // console.log("createTicketFromChat payload:: ", payload);
    if (!payload) {
      throw new Error("Payload is required to create a ticket.");
    }
    const { data } = await axiosJSON.post(
      `/api/liveChat/agent/createTicket`,
      payload,
    );
    // Check if there is an error in the response data
    if (data.err === true) {
      // Check specific cases for errors
      if (data.statusCode) {
        switch (data.statusCode) {
          case "integrationFailure":
            throw {
              statusCode: "integrationFailure",
              message: data.msg ?? data.message,
              failureMetaData: data.failureMetaData,
            };
          default:
            throw {
              statusCode: data.statusCode,
              message: data.msg ?? data.message,
            };
        }
      } else {
        // Otherwise, throw a general error
        throw new Error(data?.msg || data?.message || "Something went wrong!");
      }
    }

    // Return the data if no errors occurred
    return data.data as ICreateTicketFromChatRes;
  } catch (e: any) {
    // Catch and handle errors during the request
    if (typeof e === "object") {
      throw e;
    }
    // Log the error to the console
    console.error(e);

    // Rethrow a general error message
    throw new Error("Something went wrong!");
  }
}
