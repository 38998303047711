import { Channel } from "pusher-js";

export type LiveChatRequestWaitingForReplySinceEventCB = (res: any) => void;

export const getLiveChatRequestWaitingForReplySinceEvent = (
  channel: Channel,
  callback: LiveChatRequestWaitingForReplySinceEventCB
) => {
  channel.bind("live_chat_request_waiting_for_reply_since", callback);
};

export const getLiveMessageRecEventUnbind = (
  channel: Channel,
) => {
  channel.unbind("live_chat_request_waiting_for_reply_since");
};