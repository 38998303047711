import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "src/store/store";
import styles from "../../../../LiveChat.module.scss";
import ChatHistoryUpdated from "../ChatHistoryUpdated/ChatHistory";
import { LiveMainChatTabs } from "../ChatTabs";
import Conversation from "../Conversation";

export interface EventChatIds {
  [chatId: number | string]: number | string;
}

function ChatSection() {
  let { tabNameMainChat } = useParams();
  const selectedCustomerStatus = useAppSelector(
    (state) => state.chatWidExchCust.selectedCustomerStatus
  );
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (selectedCustomerStatus === "archived") {
  //     navigate(window.location.pathname.replace("/conversation", "/history"));
  //   }
  // }, [selectedCustomerStatus]);

  return (
    <>
      {(tabNameMainChat === "conversation" || tabNameMainChat == undefined) && (
        <Conversation />
      )}
      {/* {tabNameMainChat === "history" && <ChatHistory />} */}
      {tabNameMainChat === "history" && <ChatHistoryUpdated />}
    </>
  );
}

export default ChatSection;
