import {
  InfiniteData,
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import addTextVariablesService, {
  AddTextVariablesParams,
} from "src/services/ReturnAutoWorkFlow/addTextVariables.service";
import deleteTextVariablesService, {
  DeleteTextVariablesParams,
} from "src/services/ReturnAutoWorkFlow/deleteTextVariables.service";
import getTextVariablesService, {
  GetTextVariablesParams,
} from "src/services/ReturnAutoWorkFlow/getTextVariables.service";
import {
  AllVariableTexts,
  VariableTextData,
} from "../ReturnAutoWorkFlow.types";

const INITIAL_PAYLOAD = {
  limit: 100,
  start: 0,
};

const useReturnWorkflowVariables = (editorVariablesKey: string | null) => {
  const queryClient = useQueryClient();

  const payload: GetTextVariablesParams = useMemo(() => {
    return {
      editorVariablesKey: editorVariablesKey ?? "",
      limit: INITIAL_PAYLOAD.limit,
      start: INITIAL_PAYLOAD.start,
    };
  }, [editorVariablesKey]);

  const { data, isLoading, fetchNextPage, hasNextPage, isFetching, isError } =
    useInfiniteQuery<AllVariableTexts, Error, AllVariableTexts>({
      queryKey: ["getTextVariablesService", payload],
      queryFn: ({ pageParam = payload }) => getTextVariablesService(pageParam),
      getNextPageParam: (lastPage, allPages) => {
        if (!lastPage.hasMore || !editorVariablesKey) {
          return null;
        }

        const data = allPages.flatMap((page) => page);
        const nextPageParam: GetTextVariablesParams = {
          editorVariablesKey,
          limit: payload.limit,
          start: data.length,
        };

        return nextPageParam;
      },
      enabled: editorVariablesKey !== null,
    });

  const addVariablesMutation = useMutation<
    VariableTextData,
    Error,
    AddTextVariablesParams,
    AddTextVariablesParams
  >({
    mutationKey: ["addTextVariablesService", editorVariablesKey],
    mutationFn: addTextVariablesService,
    onSuccess: (addedVariable) => {
      queryClient.setQueryData<InfiniteData<AllVariableTexts> | undefined>(
        ["getTextVariablesService", editorVariablesKey],
        (oldData) => {
          if (!oldData) return { pages: [], pageParams: [] }; // Handle the initial data
          const lastPage = oldData.pages[oldData.pages.length - 1];
          lastPage.variables[addedVariable.id] = addedVariable;
          lastPage.variableIds.push(addedVariable.id);
          return {
            ...oldData,
            pages: [...oldData.pages.slice(0, -1), lastPage],
          };
        },
      );
    },
  });

  const deleteVariableMutation = useMutation<
    boolean,
    Error,
    DeleteTextVariablesParams,
    DeleteTextVariablesParams
  >({
    mutationKey: ["deleteTextVariablesService", editorVariablesKey],
    mutationFn: deleteTextVariablesService,
    onSuccess: (isDeleted, params) => {
      if (isDeleted) {
        queryClient.setQueryData<InfiniteData<AllVariableTexts> | undefined>(
          ["getTextVariablesService", editorVariablesKey],
          (oldData) => {
            if (!oldData) return { pages: [], pageParams: [] }; // Handle the initial data
            const lastPage = oldData.pages[oldData.pages.length - 1];
            delete lastPage.variables[params.variableId];
            lastPage.variableIds = lastPage.variableIds.filter(
              (id) => id !== params.variableId,
            );
            return {
              ...oldData,
              pages: [...oldData.pages.slice(0, -1), lastPage],
            };
          },
        );
      }
    },
  });

  const addVariable = useCallback(
    async (variableName: string, example: string) => {
      if (!editorVariablesKey) {
        return null;
      }

      await addVariablesMutation.mutateAsync({
        variableName,
        example,
        editorVariablesKey,
      });

      return addVariablesMutation.isSuccess;
    },
    [addVariablesMutation, editorVariablesKey],
  );

  const deleteVariable = useCallback(
    async (variableId: string) => {
      if (!editorVariablesKey) {
        return null;
      }

      await deleteVariableMutation.mutateAsync({
        editorVariablesKey,
        variableId,
      });

      return deleteVariableMutation.isSuccess;
    },
    [deleteVariableMutation, editorVariablesKey],
  );

  const fetchNextPageHandler = useCallback(() => {
    if (hasNextPage && !isFetching) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isFetching]);

  const { variableIds, allVariables } = useMemo(() => {
    let variableIds: string[] = [];
    const allVariables: Record<string, VariableTextData> = {};

    if (data) {
      data.pages.forEach((page) => {
        page.variableIds.forEach((id) => {
          variableIds.push(id);
          allVariables[id] = page.variables[id];
        });
      });
    }

    return { variableIds, allVariables };
  }, [data]);

  return {
    variableIds,
    allVariables,
    addVariable,
    deleteVariable,
    fetchNextPageHandler,
    isLoadingVariables: isLoading || isFetching,
    isAddingVariable: addVariablesMutation.isLoading,
    isDeletingVariable: deleteVariableMutation.isLoading,
    isError,
    isErrorAddingVariable: addVariablesMutation.isError,
    isErrorDeletingVariable: deleteVariableMutation.isError,
  };
};

export default useReturnWorkflowVariables;
