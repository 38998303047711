import {
  createAsyncThunk,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { AJAXSTATUS } from "src/globals/constants";
import { GetAllUser } from "src/services/Settings/Users/getAllUser";
import { UserRoleList } from "src/services/Settings/Users/getUserRoles";
import userSettingsExtraReducers from "./userSettings.extraReducers";
import userSettingsReducers from "./userSettings.reducers";
import userSettingsThunks from "./userSettings.thunks";

export const fetchAllUser = createAsyncThunk(
  "userSettings/fetchAllUser",
  userSettingsThunks.fetchAllUserThunk
);
export const addUser = createAsyncThunk(
  "userSettings/addUser",
  userSettingsThunks.addUserThunk
);
export const editUser = createAsyncThunk(
  "userSettings/editUser",
  userSettingsThunks.editUserThunk
);
export const deleteUser = createAsyncThunk(
  "userSettings/deleteUser",
  userSettingsThunks.deleteUserThunk
);
export const logoutUserFromAllSession = createAsyncThunk(
  "userSettings/logoutUserFromAllSession",
  userSettingsThunks.logoutUserFromAllSessionThunk
);
export const deactivateUser = createAsyncThunk(
  "userSettings/deactivateUser",
  userSettingsThunks.deactivateUserThunk
);

export interface UserFilters {
  searchValue: string;
}

export interface IUserSettings extends GetAllUser {
  usersLimit: number;
  filters: UserFilters;
  userDataAjaxStatus: AJAXSTATUS;
  addUserAjaxStatus: AJAXSTATUS;
  deleteUserAjaxStatus: AJAXSTATUS;
  userRoles: UserRoleList;
}

export const initialState: IUserSettings = {
  users: {},
  userIds: [],
  metaData: {
    count: 0,
    total: 0
  },
  usersLimit: 20,
  filters: {
    searchValue: ""
  },
  userDataAjaxStatus: "pending",
  addUserAjaxStatus: "idle",
  deleteUserAjaxStatus: "idle",
  userRoles: {
    roles: {},
    rolesIds: []
  }
};

export const userSettingsSlice = createSlice({
  name: "userSettings",
  initialState,
  reducers: userSettingsReducers,
  extraReducers: (builder) => {
    builder.addCase(
      fetchAllUser.fulfilled,
      userSettingsExtraReducers.fetchAllUserFulfilled
    );
    builder.addCase(fetchAllUser.rejected, (state) => {
      state.userDataAjaxStatus = "rejected";
      pushTheToast({
        position: "top-right",
        type: "danger",
        text: "Something went wrong!"
      });
    });
    builder.addCase(fetchAllUser.pending, (state) => {
      state.userDataAjaxStatus = "pending";
    });

    builder.addCase(
      addUser.fulfilled,
      userSettingsExtraReducers.addUserFulfilled
    );
    builder.addCase(addUser.rejected, (state, {error}) => {
      state.addUserAjaxStatus = "rejected";
      pushTheToast({
        position: "top-right",
        type: "danger",
        text: error.message ?? "Something went wrong"
      });
    });
    builder.addCase(addUser.pending, (state) => {
      state.addUserAjaxStatus = "pending";
    });

    builder.addCase(
      editUser.fulfilled,
      userSettingsExtraReducers.editUserFulfilled
    );
    builder.addCase(editUser.rejected, (state, {error}) => {
      state.addUserAjaxStatus = "rejected";
      pushTheToast({
        position: "top-right",
        type: "danger",
        text: error.message ?? "Something went wrong"
      });
    });
    builder.addCase(editUser.pending, (state) => {
      state.addUserAjaxStatus = "pending";
    });

    builder.addCase(
      deleteUser.fulfilled,
      userSettingsExtraReducers.deleteUserFulfilled
    );
    builder.addCase(deleteUser.rejected, (state, {error}) => {
      state.deleteUserAjaxStatus = "rejected";
      pushTheToast({
        position: "top-right",
        type: "danger",
        text: error.message ?? "Something went wrong"
      });
    });
    builder.addCase(deleteUser.pending, (state) => {
      state.deleteUserAjaxStatus = "pending";
    });
    builder.addCase(
      logoutUserFromAllSession.fulfilled,
      userSettingsExtraReducers.logoutUserFromAllSessionFulfilled
    );
    builder.addCase(
      deactivateUser.fulfilled,
      userSettingsExtraReducers.deactivateUserFulfilled
    );
    
  }
});

export const { resetUserSettings, resetUserData, setSearchValue } =
  userSettingsSlice.actions;

export default userSettingsSlice.reducer;
