import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { downloadFile } from "src/services/GetFiles";

function DownloadFile({
  url,
  fileName,
  loading = false,
  setLoading = () => {},
  stopPropagation = false,
  ...props
}: {
  url: string;
  fileName: string;
  loading?: boolean;
  setLoading?: (loading: boolean) => void;
  stopPropagation?: boolean;
  [key: string]: any;
}) {
  const handelClick = (e: any) => {
    if (stopPropagation) {
      e.stopPropagation();
    }
    setLoading(true);
    downloadFile({ url, fileName })
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };
  return (
    <button {...props} onClick={props.onClick ? props.onClick : handelClick}>
      {props.children}
    </button>
  );
}

export default DownloadFile;
