import ReactSelect from "react-select";
import styles from "../ReportInternalFilter.module.scss";
import { useCallback, useMemo } from "react";
import {
  reportInternalFiltersActions,
  useReportInternalFilters,
} from "src/routes/Report/hooks/reportFilters/useReportInternalFilters";
import { useQuery } from "@tanstack/react-query";
import { fetchTicketsTagsApi } from "src/services/TicketService/fetchTicketsTags";
import { TicketTags as ITicketTags } from "src/store/slices/ticketTags/ticketTags.slice";

const useIntTagFilter = () => {
  const { data: allTags } = useQuery(["useTagFilter/fetchTicketsTagsApi"], {
    queryFn: async () =>
      fetchTicketsTagsApi() as Promise<{
        allTags: ITicketTags;
        allTagsIds: number[];
      }>,
    staleTime: 600000,
    cacheTime: 600000,
  });

  const options = useMemo(() => {
    return (
      allTags?.allTagsIds.map((id) => {
        return {
          label: allTags.allTags[id].tagName,
          value: allTags.allTags[id],
        };
      }) ?? []
    );
  }, [allTags]);

  const { internalFilters, dispatch } = useReportInternalFilters();

  const selectedTags = useMemo(() => {
    return allTags?.allTagsIds.length
      ? (
          allTags.allTagsIds.filter((id) =>
            internalFilters.addedFilters.tags?.includes(id + "")
          ) ?? []
        ).map((id) => {
          return {
            label: allTags.allTags[id].tagName,
            value: allTags.allTags[id],
          };
        })
      : [];
  }, [internalFilters, allTags]);

  const onChangeTags = useCallback(
    (value: typeof selectedTags) => {
      dispatch([
        reportInternalFiltersActions.setFilters,
        { tags: value.map((data) => data.value.id + "") },
      ]);
    },
    [selectedTags]
  );
  return {
    options,
    selectedTags,
    onChangeTags,
  };
};

function TicketTags() {
  const { options, selectedTags, onChangeTags } = useIntTagFilter();

  return (
    <div className="px-2 pb-2">
      <label className={`${styles.labelText}`}>Ticket Tag(s)</label>
      <ReactSelect
        isMulti
        options={options}
        value={selectedTags}
        onChange={onChangeTags as any}
        className={`${styles.text}`}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
            font: "normal normal normal 12px/18px Poppins",
            color: "#000000",
          }),
          multiValue: (base, { data }) => ({
            ...base,
            backgroundColor: '#F4F4F4', // Background color for selected values
            borderRadius: '6px',
            opacity: 1,
          }),
          multiValueLabel: (base) => ({
            ...base,
            font: "normal normal 500 12px/18px Poppins",
            letterSpacing: "0px",
            color: "#1F2223",
            paddingLeft:"12px",
          }),
          multiValueRemove: (base, { data }) => ({
            ...base,
            // color: '#000000', // Remove icon color for selected values
            fontSize: "6px",
            // ':hover': {
            //   backgroundColor: 'red', // Change the background color on hover if needed
            // },
          }),
        }}
      />
    </div>
  );
}

export default TicketTags;
