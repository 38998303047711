import { createAsyncThunk } from "@reduxjs/toolkit";
import getRefundRequestFollowUpQuestionsService from "src/services/ReturnAutoWorkFlow/OldModals/antiRefundStrategies/getRefundRequestFollowUpQuestions.service";
import getAllReturnReasonListService from "src/services/ReturnAutoWorkFlow/OldModals/General/getAllReturnReasonList.service";
import getAntiRefundStrategiesOrdersService, {
  AntiRefundStrategiesOrdersTab,
} from "src/services/ReturnAutoWorkFlow/OldModals/antiRefundStrategiesOrders/getAntiRefundStrategiesOrders.service";
import getAntiRefundStrategiesOrdersConditionsService from "src/services/ReturnAutoWorkFlow/OldModals/antiRefundStrategiesOrders/getAntiRefundStrategiesOrdersConditions.service";
import updateAntiRefundStrategiesOrdersService from "src/services/ReturnAutoWorkFlow/OldModals/antiRefundStrategiesOrders/updateAntiRefundStrategiesOrders.service";
import { getValueFromConditionType } from "src/features/ReturnAutoWorkFlow/children/ConfigModals/store/returnModalsSliceHelpers";
import { RootState } from "src/store/store";
import { SelectedConditionType } from "../../../oldReturnModals.types";
import { ReturnModalsThunkPayload } from "../../returnModalsStore";
import { validateAntiRefundOrders } from "./antiRefundStrategiesOrders.slice";

export const getRefundRequestFollowUpQuestionsOrdersThunk = createAsyncThunk(
  "getRefundRequestFollowUpQuestionsOrders",
  async (payload: { id: string; integrationId: string }) => {
    const integrationId = payload.integrationId;
    const getRefundRequestFollowUpQuestions =
      await getRefundRequestFollowUpQuestionsService({
        integrationId,
        id: payload.id,
      });

    return getRefundRequestFollowUpQuestions;
  },
);

export const getAllReturnReasonListOrdersThunk = createAsyncThunk(
  "getAllReturnReasonListOrders",
  async (payload: { integrationId: string }) => {
    const integrationId = payload.integrationId;
    const getRefundRequestList = await getAllReturnReasonListService({
      integrationId,
    });

    if (getRefundRequestList) {
      return getRefundRequestList;
    }
  },
);

export const fetchAntiRefundStrategiesOrdersThunk = createAsyncThunk(
  "fetchAntiRefundStrategiesOrders",
  async (payload: { integrationId: string }) => {
    const integrationId = payload.integrationId;

    const [conditionResponse, configRes, getRefundRequestList] =
      await Promise.all([
        await getAntiRefundStrategiesOrdersConditionsService({ integrationId }),
        await getAntiRefundStrategiesOrdersService({ integrationId }),
        await getAllReturnReasonListService({ integrationId }),
      ]);

    return {
      conditionResponse,
      configRes,
      getRefundRequestList,
    };
  },
);

interface UpdateAntiRefundStrategiesOrdersPayload
  extends ReturnModalsThunkPayload {
  integrationId: string;
}

export const updateAntiRefundStrategiesOrdersThunk = createAsyncThunk(
  "updateAntiRefundStrategiesOrders",
  async (payload: UpdateAntiRefundStrategiesOrdersPayload, { getState }) => {
    const state = getState() as RootState;

    const integrationId = payload.integrationId;
    const orderTypes =
      state.antiRefundStrategiesOrders.antiRefundOrderTypesData
        .antiRefundOrderTypes;

    const configTabs = orderTypes.map((orderType) => {
      const operatorSymbolMap =
        state.antiRefundStrategiesOrders.antiRefundOrderTypesData
          .operatorSymbolMap;
      const updatedReasonsPayload = orderType.refundRequests.reasons.map(
        (reason) => {
          return {
            id: reason.id,
            scenarios: reason.scenarios.map((scenario) => {
              return {
                scenarioId: scenario.isNewScenario ? undefined : scenario.id,
                tags:
                  scenario.itemsAndTagAllSelected === true
                    ? ["*"]
                    : (scenario.tags as string[]),
                itemIds:
                  scenario.itemsAndTagAllSelected === true
                    ? ["*"]
                    : (scenario.items.map((item) => item.id) as number[]) ?? [], //adding items to send in payload
                enabled: scenario.enabled ?? false,
                additionalInformation: {
                  enabled: scenario.additionalInformation?.enabled ?? false,
                  message: scenario.additionalInformation?.message ?? "",
                  extendReturnPeriod: {
                    enabled:
                      scenario.additionalInformation?.extendReturnPeriod
                        .enabled ?? false,
                    days:
                      scenario.additionalInformation?.extendReturnPeriod.days ??
                      0,
                  },
                },
                giveOffer: {
                  enabled: scenario.giveOffer?.enabled ?? false,
                  message: scenario.giveOffer?.message ?? "",
                  offerSelectedItems:
                    (scenario.giveOffer?.offerSelectedItems as number[]) ?? [],
                  offerSelectedTags: scenario.giveOffer
                    ?.offerSelectedTags as string[],
                  offerType:
                    (scenario.giveOffer?.offerType as string) ?? "other",
                  offerValue: (scenario.giveOffer?.offerValue as number) ?? 0,
                  offerUnit: scenario.giveOffer?.offerUnit ?? "None",
                  name: scenario.giveOffer?.name ?? "",
                },
                offerExchange: {
                  enabled: scenario.offerExchange?.enabled ?? false,
                  message: scenario.offerExchange?.message ?? "",
                  exchangeWithOtherItems:
                    scenario.offerExchange?.exchangeWithOtherItems ?? false,
                  exchangeWithSameItems:
                    scenario.offerExchange?.exchangeWithSameItems ?? false,
                },
                followUpQuestions:
                  scenario.followUpQuestions.length > 0
                    ? scenario.followUpQuestions.map((question) => {
                        if (
                          Array.isArray(question.selectedValue) &&
                          question.additionalOptions &&
                          question.selectedValue.length ===
                            question.additionalOptions.length
                        ) {
                          return {
                            elementId: question.elementId,
                            value: ["*"],
                            // selectedId: ["*"],
                          };
                        } else {
                          return {
                            elementId: question.elementId,
                            value: question.selectedValue,
                          };
                        }
                      })
                    : [],
              };
            }),
          };
        },
      );

      return {
        configTabId: Number(orderType.orderTypeId),
        configTabName: orderType.orderTypeName,
        // applyToAllOrders: orderType.applyToAllOrders ?? true,
        conditions: orderType.conditions.map((condition) => {
          const selectedVariable =
            state.antiRefundStrategiesOrders.antiRefundOrderTypesData.variableNames.find(
              (variable: any) => variable.name === condition.variableName,
            );

          if (selectedVariable) {
            return {
              conditionId: Number(condition.id),
              conditionTypeId: selectedVariable.id,
              conditionValue: getValueFromConditionType(condition.values),
              operator: Object.keys(operatorSymbolMap).find((key) => {
                if (
                  (condition.operator === "are less than" ||
                    condition.operator === "is less than") &&
                  key === "<"
                ) {
                  return true;
                }
                return operatorSymbolMap[key] === condition.operator;
              }),
            } as SelectedConditionType;
          } else {
            return {
              conditionId: Number(condition.id),
              conditionTypeId: -1,
            };
          }
        }),
        refundRequests: updatedReasonsPayload.length
          ? updatedReasonsPayload
          : {},
      } as AntiRefundStrategiesOrdersTab;
    });

    await updateAntiRefundStrategiesOrdersService({
      integrationId,
      configTabs,
    });

    setTimeout(() => {
      if (payload.callback) {
        payload.callback();
      }
    }, 0);
    // Refetch data

    const configRes = await getAntiRefundStrategiesOrdersService({
      integrationId,
    });

    return {
      configRes,
    };
  },
);

export const validateAntiRefundOrdersThunk = createAsyncThunk(
  "validateAntiRefundOrders",
  async (_: void, { getState, dispatch }) => {
    await dispatch(validateAntiRefundOrders());

    const state = getState() as RootState;

    let errorCount = 0;
    state.antiRefundStrategiesOrders.antiRefundOrderTypesData.antiRefundOrderTypes.forEach(
      (orderType) => {
        if (orderType.errorCount !== 0) {
          errorCount += 1;
        }
      },
    );

    return errorCount;
  },
);
