import { useContext, useState } from "react";
import AllOrdersContext from "../../AllOrdersContext";
import styles from "./AllOrderSubHeader.module.scss";
const AllOrderSubHeader = (props: {
  isCheckAll: boolean;
  setIsCheckAll: Function;
}) => {
  const allOrdersContext = useContext(AllOrdersContext);

  function toggleAllOrdersChecked(ev: any) {
    allOrdersContext.toggleCheckedOrders(ev.target.checked);
  }

  return (
    <div className={`my-3 ${styles.mainDiv}`}>
      <div className={`d-flex ${styles.setPadding}`}>
        <div className={`${styles.TableOrders}`}>
          <div className="form-check">
            <input
              data-lpignore="true"
              className="form-check-input"
              type="checkbox"
              name="selectAll"
              id="selectAll"
              checked={allOrdersContext?.areAllOrdersChecked()}
              style={{
                border: "1px solid #707070",
                borderRadius: "4px",
                width: "15px",
                height: "15px",
              }}
              onChange={() => {}}
              onClick={toggleAllOrdersChecked}
            />{" "}
            <span className={`ms-2 ${styles.tableHeading}`}>Orders</span>
          </div>
        </div>
        <div className={`${styles.TableDate}`}>
          <span className={`${styles.tableHeading}`}>Date </span>
        </div>
        <div className={`${styles.TableCustomer}`}>
          <span className={`${styles.tableHeading}`}>Customer </span>
        </div>
        <div className={`${styles.TableTotal}`}>
          <span className={`${styles.tableHeading}`}>Total </span>
        </div>
        <div className={`${styles.TablePayment}`}>
          <span className={`${styles.tableHeading}`}>Payment status </span>
        </div>
        <div className={`${styles.TableFullfillment}`}>
          <span className={`${styles.tableHeading}`}>Fullfillment status </span>
        </div>
        <div className={`${styles.TableItem}`}>
          <span className={`${styles.tableHeading}`}>Items </span>
        </div>
        <div className={`${styles.TableMethod}`}>
          <span className={`${styles.tableHeading}`}>Delivery method </span>
        </div>
        <div className={`${styles.TableTags}`}>
          <span className={`${styles.tableHeading}`}>Tags </span>
        </div>
      </div>
    </div>
  );
};
export default AllOrderSubHeader;
