import { useMemo } from "react";
import styles from "./SearchProducts.module.scss";
import closeBtn from "src/assets/images/closeModal.png";
import shirt1 from "src/assets/images/shirt1.png";
import shirt2 from "src/assets/images/shirt2.png";
import shirt3 from "src/assets/images/shirt3.png";
import shirt4 from "src/assets/images/shirt4.png";
import dummy from "src/assets/images/order1.png";

export interface MiniProductVarient {
  id: string;
  name: string;
  sku: string;
  imgUrl: string;
  price: {
    amount: number;
    currencyCode: string;
  };
}

export const dummyProducts: MiniProductVarient[] = [
  {
    id: "prod1",
    name: "Acme White T- shirt",
    sku: "1234",
    imgUrl: shirt1,
    price: {
      amount: 230,
      currencyCode: "$",
    },
  },
  {
    id: "prod2",
    name: "Acme White T- shirt",
    sku: "1234",
    imgUrl: shirt2,
    price: {
      amount: 230,
      currencyCode: "$",
    },
  },
  {
    id: "prod3",
    name: "Acme White T- shirt",
    sku: "1234",
    imgUrl: shirt3,
    price: {
      amount: 230,
      currencyCode: "$",
    },
  },
  {
    id: "prod4",
    name: "Acme White T- shirt",
    sku: "1234",
    imgUrl: shirt4,
    price: {
      amount: 230,
      currencyCode: "$",
    },
  },
  {
    id: "prod5",
    name: "Acme White T- shirt",
    sku: "1234",
    imgUrl: shirt1,
    price: {
      amount: 230,
      currencyCode: "$",
    },
  },
  {
    id: "prod6",
    name: "Acme White T- shirt",
    sku: "1234",
    imgUrl: shirt1,
    price: {
      amount: 230,
      currencyCode: "$",
    },
  },
  {
    id: "prod7",
    name: "Acme White T- shirt",
    sku: "1234",
    imgUrl: shirt1,
    price: {
      amount: 230,
      currencyCode: "$",
    },
  },
];
// export const sameProducts: MiniProductVarient[] = [
//   {
//     id: "prod8",
//     name: "Acme T-Shirt",
//     sku: "0101",
//     imgUrl: dummy,
//     price: {
//       amount: 789,
//       currencyCode: "$",
//     },
//   },
// ];
const SearchProducts = ({
  setSelectedProductVariendId,
  selectedProductVarientId,
  isExchangeWithSameItem,
  sameProducts,
}: {
  setSelectedProductVariendId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  selectedProductVarientId?: string;
  isExchangeWithSameItem: boolean;
  sameProducts: MiniProductVarient[];
}) => {
  const filteredProducts = useMemo(() => {
    if (isExchangeWithSameItem) {
      return sameProducts;
    } else {
      return dummyProducts;
    }
  }, [dummyProducts, isExchangeWithSameItem]);

  return (
    <div>
      <div className={`position-relative ${styles.searchbar}`}>
        <input
          type="text"
          name=""
          id=""
          disabled
          value={"   T-Shirts"}
        />
        <span className={`${styles.searchIcon}`}>
          <i className="fa-solid fa-magnifying-glass"></i>
        </span>
        <span
          className={`${styles.closeIcon} d-flex align-items-center justify-content-center`}
        >
          <img
            src={closeBtn}
            alt=""
          />
        </span>
      </div>
      <div>
        <p className={`${styles.showRes}`}>
          Showing 1 of 234 results <span>Visit Acme</span>
        </p>
        <div className="">
          {filteredProducts.map((product) => {
            return (
              <div
                className={`mb-2 ${styles.searchBox} cursor-pointer`}
                onClick={() => {
                  setSelectedProductVariendId(product.id);
                }}
              >
                <div className={`d-flex`}>
                  <div className={`${styles.cart}`}>
                    <img
                      src={product.imgUrl}
                      alt="order"
                      className={`${styles.orderImg}`}
                    />
                  </div>
                  <div className={`ms-2`}>
                    <span className={`${styles.orderName}`}>
                      {product.name}
                    </span>
                    <span className={`${styles.orderDesc}`}>
                      SKU : {product.sku}
                    </span>
                    <span className={`${styles.orderValue}`}>
                      {product.price.currencyCode} {product.price.amount}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SearchProducts;
