import { useCallback, useMemo } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import ReactSelect, {
  GroupBase,
  MultiValue as MulValue,
  MultiValueProps,
  OptionProps,
  StylesConfig,
  components,
} from "react-select";
import { ListOption } from "src/services/Automation/DispatchRulesConfigModal/getDispatchRuleConfigById.service";
import { useDispatchSectionContext } from "../../../Hooks/useDispatchSectionContext";
import styles from "./SuggestArticles.module.scss";
import useSuggestArticles from "./useSuggestArticles";

interface OptionType {
  value: string;
  label: string;
}

const IS_MULTI = true;

const defaultStyles: StylesConfig<
  OptionType,
  typeof IS_MULTI,
  GroupBase<OptionType>
> = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "#FCFCFC",
    color: "#000",
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "18px",
    minHeight: "37px",
    borderRadius: "2px",
    "&:hover": {
      borderColor: "#ECECEC",
    },
    border: state.isFocused ? "0.5px solid #0B68BB" : "1px solid #ECECEC",
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "#F5F5F5",
    color: "#000",
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "18px",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: "#000",
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "18px",
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: "#707070",
    ":hover": {
      color: "#707070",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    color: "#000",
    backgroundColor: "#FFF",
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "18px",
    cursor: "pointer",
    ":hover": {
      backgroundColor: "",
    },
  }),
  menu: (provided) => ({
    ...provided,
    padding: "10px",
  }),
};

const popover = (
  <Popover
    id="popover-basic"
    bsPrefix={`popover ${styles.customPopver}`}
  >
    <Popover.Body>
      <h6 className={`${styles.heading}`}>What is issue dispatching?</h6>
      <span className={`text-start ${styles.subHeading}`}>
        Issue dispatching typically refers to the process of immediately
        escalating urgent bugs to the appropriate individuals or teams within
        your organization via phone, email or text for immediate resolution or
        action.
      </span>
    </Popover.Body>
  </Popover>
);

// Custom MultiValue component to display selected options
const MultiValue: React.ComponentType<
  MultiValueProps<OptionType, typeof IS_MULTI, GroupBase<OptionType>>
> = (props) => (
  <components.MultiValue {...props}>
    <span>{props.data.label}</span>
  </components.MultiValue>
);

const Option: React.ComponentType<
  OptionProps<OptionType, typeof IS_MULTI, GroupBase<OptionType>>
> = (props) => {
  return (
    <components.Option {...props}>
      <input
        type="checkbox"
        checked={props.isSelected}
        onChange={() => {}}
        className={`${styles.selectCheckBox}`}
      />{" "}
      <label className={`ms-2 ${styles.selectLabel}`}>{props.label}</label>
    </components.Option>
  );
};

const SuggestArticles = ({
  index,
  description,
}: {
  index: number;
  description: string;
}) => {
  const {
    updateState,
    state: { issueDescription, integrationId },
    automationType,
    showErrors,
    setShowErrors,
  } = useDispatchSectionContext();

  const { fetchNextPageHandler, showLoader, options, optionsMap } =
    useSuggestArticles(integrationId, automationType);

  const handleChange = useCallback(
    (selected: MulValue<OptionType>) => {
      const descriptions = issueDescription.map((desc, idx) => {
        if (idx === index) {
          desc.articlesDetails = selected.map(
            (val) => ({ id: val.value, name: val.label }) as ListOption,
          );
        }

        return desc;
      });

      updateState({
        issueDescription: [...descriptions],
      });

      setShowErrors(false);
    },
    [index, issueDescription, setShowErrors, updateState],
  );

  const selectedValue = useMemo(() => {
    return (
      issueDescription[index].articlesDetails
        ?.map((article) => optionsMap[article.id] || null)
        .filter(Boolean) ?? []
    );
  }, [index, issueDescription, optionsMap]);

  const customStyles = useMemo(() => {
    const styles = { ...defaultStyles };

    if (showErrors && selectedValue.length === 0) {
      styles.control = (provided, state) => ({
        ...provided,
        backgroundColor: "#FCFCFC",
        color: "#000",
        fontSize: "12px",
        fontWeight: "500",
        lineHeight: "18px",
        minHeight: "37px",
        borderRadius: "2px",
        borderColor: "#FF0000",
        "&:hover": {
          borderColor: "#FF0000",
        },
      });
    }

    return styles;
  }, [selectedValue.length, showErrors]);

  return (
    <div className={`mb-2 ${styles.greyBox}`}>
      <label
        htmlFor="exampleFormControlTextarea1"
        className={`mb-0 form-label ${styles.desc}`}
      >
        Issue Description {index + 1}:
        <OverlayTrigger
          trigger={["hover", "focus"]}
          placement="top"
          overlay={popover}
        >
          <span className={`ps-1 ${styles.info}`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13"
              height="13"
              viewBox="0 0 14.098 14.098"
            >
              <g
                id="Component_356_4"
                data-name="Component 356 – 4"
                transform="translate(0.5 0.5)"
              >
                <g
                  id="Icon_ion-information-circle-outline"
                  data-name="Icon ion-information-circle-outline"
                  transform="translate(-4.5 -4.5)"
                >
                  <path
                    id="Path_7337"
                    data-name="Path 7337"
                    d="M11.049,4.5A6.549,6.549,0,1,0,17.6,11.049,6.549,6.549,0,0,0,11.049,4.5Z"
                    fill="none"
                    stroke="#000"
                    strokeWidth="1"
                  />
                  <path
                    id="Path_7338"
                    data-name="Path 7338"
                    d="M15.469,15.469h1.139V19.6"
                    transform="translate(-5.416 -5.416)"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                  />
                  <path
                    id="Path_7339"
                    data-name="Path 7339"
                    d="M14.625,23.906h3.132"
                    transform="translate(-5 -9.583)"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeWidth="1"
                  />
                  <path
                    id="Path_7340"
                    data-name="Path 7340"
                    d="M16.535,9.141a.925.925,0,1,0,.925.925.925.925,0,0,0-.925-.925Z"
                    transform="translate(-5.486 -2.292)"
                  />
                </g>
              </g>
            </svg>
          </span>
        </OverlayTrigger>
      </label>
      <p className={`mb-0 ${styles.descSub}`}>{description}</p>
      <div className="mt-2">
        <label
          htmlFor=""
          className={`mb-0 form-label ${styles.artLabel}`}
        >
          Articles
        </label>
        <ReactSelect
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          options={options}
          components={{
            Option,
            MultiValue,
          }}
          isMulti={IS_MULTI}
          value={selectedValue}
          onChange={handleChange}
          styles={customStyles}
          isLoading={showLoader}
          onMenuScrollToBottom={fetchNextPageHandler}
        />
      </div>
    </div>
  );
};

export default SuggestArticles;
