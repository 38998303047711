import { v4 as uuidv4 } from "uuid";
import { useCallback, useMemo, useRef } from "react";
import styles from "./CheckOptions.module.scss";

/**
 * Option component represents a single checkbox or radio input.
 *
 * @param label - The label for the input.
 * @param value - The value of the input.
 * @param checked - Whether the input is checked or not.
 * @param onChange - Event handler when the input changes.
 * @param type - The type of input, either checkbox or radio.
 */
export function Option({
  label,
  value,
  checked,
  onChange,
  type,
  showErrors,
}: {
  label: string;
  value: string;
  checked: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  type: "checkbox" | "radio";
  showErrors: boolean;
}) {
  const optionId = useRef(
    `${type}--base-question-return-auto-workflow--${value}--${uuidv4()}`,
  );

  return (
    <div className={`mt-2 d-flex mb-0`}>
      <input
        className={`form-check-input cursor-pointer mt-1 ${styles.radioCheck}`}
        type={type}
        data-value={value}
        id={optionId.current}
        checked={checked}
        onChange={onChange}
      />
      <label
        className={`${styles.option} ms-2`}
        htmlFor={optionId.current}
      >
        {label}
      </label>
    </div>
  );
}

/**
 * CheckOptions component handles a list of options with checkboxes.
 *
 * @param options - List of options to render.
 * @param value - List of selected values.
 * @param onChange - Callback function when the selected values change.
 */
function CheckOptions({
  options,
  value,
  onChange,
  showErrors,
}: {
  options: Array<{ label: string; value: string }>;
  value: Array<string>;
  onChange: (value: Array<string>) => void;
  showErrors: boolean;
}) {
  const valueStr = useMemo(() => {
    return value.map((v) => v + "");
  }, [value]);

  const isChecked = useCallback(
    (v: string) => {
      return valueStr.includes(v + "");
    },
    [valueStr],
  );

  const handleOnChange: React.ChangeEventHandler<HTMLInputElement> =
    useCallback(
      (e) => {
        const ele = e.currentTarget as HTMLElement;
        const thisValue = ele?.getAttribute("data-value");
        if (thisValue) {
          if (valueStr.includes(thisValue)) {
            onChange(valueStr.filter((v) => v !== thisValue));
          } else {
            onChange([...valueStr, thisValue]);
          }
        }
      },
      [valueStr, onChange],
    );

  return (
    <>
      {options.map((option) => {
        return (
          <Option
            key={option.value}
            {...option}
            checked={isChecked(option.value)}
            onChange={handleOnChange}
            type="checkbox"
            showErrors={showErrors}
          />
        );
      })}
    </>
  );
}

export default CheckOptions;
