import styles from "./KnowledgeBased.module.scss";
import { Spinner } from "react-bootstrap";
import msg from "src/assets/images/comment.png";
import pencil from "src/assets/images/pencil.png";
import useKnowledgeBased from "./useKnowledgeBased";
const KnowledgeBased = () => {
  const {
    handleEnable,
    handleNavigate,
    isEnabled,
    showSaveLoader,
  } = useKnowledgeBased();

  return (
    <div className={`${styles.knowledgeBased}`}>
      <div className={`${styles.customLeft}`}>
        <div className="mb-3">
          <h2 className={`mb-0 ${styles.knowPara}`}>Knowledge Base</h2>
          <p className={`${styles.knowSub}`}>
            Let customers read articles right inside your chat widget instead of
            starting a conversation.
          </p>
        </div>
        <div className="d-flex flex-wrap flex-column flex-md-row justify-content-start align-items-start align-items-md-center mb-2">
          <label
            className={` form-check-label ${styles.knowSub}`}
            htmlFor="mySwitch"
          >
            Enable Knowledge Base:
          </label>
          <div className="ms-0 ms-md-2 form-check form-switch">
            <input
              data-lpignore="true"
              className="form-check-input"
              type="checkbox"
              id="mySwitch"
              name="darkmode"
              checked={isEnabled}
              onChange={handleEnable}
            />
            <span
              className={`pe-1 d-inline-block ${styles.toggleKnowledgeBase}`}
            >
              {isEnabled ? "on" : "off"}{" "}
            </span>
          </div>
          {showSaveLoader === true ? (
            <Spinner
              animation="border"
              variant="secondary"
              className={`d-block`}
              style={{ height: "20px", width: "20px" }}
            />
          ) : null}
        </div>

        {isEnabled === true ? (
          <>
            <div
              className={`d-flex justify-content-between align-items-center mb-3 ${styles.knowledgeBox}`}
              onClick={(e) => {
                handleNavigate("articles");
              }}
            >
              <div className={`${styles.textWrapper}`}>
                <img
                  className={`${styles.brushImg}`}
                  src={pencil}
                  alt="pencil"
                />
                <span className={`${styles.installPara}`}>Create Articles</span>
              </div>
              <div>
                <span>
                  <i className="fa-solid fa-angle-right"></i>
                </span>
              </div>
            </div>
            {/* Jan 26 2023 //Yuvaraj,//comment start here // hided the Helpcenter Page as it is not implemented right now */}
            {/* <div
          className={`d-flex justify-content-between align-items-center mb-3 ${styles.knowledgeBox}`}
          onClick={(e)=>{handleNavigate("HelpCenterSetting")}}
        >
          <div>
            <img className={`${styles.brushImg}`}      
              src={question}
              alt="question" />
            <span className={`${styles.installPara}`}>
            Style Helpcenter Page
            </span>
          </div>
          <div>
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
          </div>
        </div> */}
            {/* Jan 26 2023 //comment ends here*/}
            <div
              className={`d-flex justify-content-between align-items-center mb-3 ${styles.knowledgeBox}`}
              onClick={(e) => {
                handleNavigate("CustomKnowledgeBased");
              }}
            >
              <div className={`${styles.textWrapper}`}>
                <img className={`${styles.brushImg}`} src={msg} alt="msg" />
                <span className={`${styles.installPara}`}>
                  Customise Knowledge Base section in chat widget
                </span>
              </div>
              <div>
                <span>
                  <i className="fa-solid fa-angle-right"></i>
                </span>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};
export default KnowledgeBased;
