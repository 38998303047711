import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Editor as TinyMceEditor, RawEditorOptions } from "tinymce";

import { uploadAttachment } from "src/services/Attachment/uploadAttachment";
import { v4 as uuidv4 } from "uuid";
import loading from "src/assets/images/loading.gif";
import {
  convertBase64ToBlob,
  findImgInHTMLToFile,
  getTypeFromFileName,
  selectFile,
} from "src/utils/utils";
import {
  STATUS_UPLOAD,
  TinyMCEEditorContainerProps,
  TINY_MCE_ATTACHEMENT_SUPPORTED_IMG_TYPES,
  TINY_MCE_ATTACHEMENT_SUPPORTED_TYPES,
  TINY_MCE_ATTACHEMENT_SUPPORTED_VIDEO_TYPES,
  TINY_MCE_TOOLBAR_CUSTOM_IMAGE_UPLOAD_BTN,
  TINY_MCE_TOOLBAR_CUSTOM_MEDIA_UPLOAD_BTN,
  TinyMCEEditorProps,
  TinyMCEUploadFile,
} from "../../interfaces/TinyMCEContainer/ITinyMCEContainer";
import {
  fileDeleteHandler,
  fileUploader,
} from "./Children/FileUploader/FileUploader";
import { TinyMCEEditor } from "./Children/TinyMCEEditor";
import useFileUpload from "src/hooks/TinyMCEContainer/useFileUpload";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import UploadedFilesList from "./Children/UploadedFilesList/UploadedFilesList";
import styles from "./TinyMCEContainer.module.scss";
import CustomMediaPicker from "./Children/CustomMediaPicker/CustomMediaPicker";
import { Modal } from "react-bootstrap";
import AdditionalOptionList from "./Children/AdditionalOptionList/AdditionalOptionList";
import AttachmentPicker from "./Children/AttachmentPicker/AttachmentPicker";
import FileSizeWarning from "./Children/FileSizeWarning/FileSizeWarning";
import useTinymceAttachmentDeleteListener from "./Children/customHook/useTinymceAttachmentDeleteListener";
import useDragAndDropStyling from "./Children/customHook/useDragAndDropStyling";

export const variableSpanGenerator = ({
  name,
  id,
  inlineStyles,
  variableKey,
}: {
  name: string;
  id: string;
  inlineStyles?: string;
  variableKey?: string;
}) => {
  return `<span class="quill-mention ${styles.quillMention}" ${
    inlineStyles ? `style="${inlineStyles}"` : ""
  } contenteditable="false" data-name="${name}" data-id="${id}" ${
    variableKey ? `data-variable-key="${variableKey}"` : ""
  }>${name}</span>`;
};

const TinyMCEContainer = ({
  name, //{form name} of the textarea
  className = "",
  value, //will contain the value of editor inner html
  onChange, //this method will be called on change of editor content
  editorClassName = "",
  disableAttachment = true,
  // onClickAddAttachmentBtn
  attachmentUploadData,
  setAttachmentUploadData,
  isFileUploading, // status of overall uploading
  setIsFileUploading = () => {}, // updates the overall file upload status
  selectedFiles = [], //useState to store uploaded attachments info
  setSelectedFiles = () => {}, //state function to update selectedFiles
  uniqueID,
  canDeleteInline = true,
  threadData,
  sendThread,
  setSendThread,
  showThread,
  setShowThread,
  additionalOptions,
  options = {}, //editor settings for overiding default
  buttons = <></>,
  showToolbar = true, //by default toolbar will be shown, using this prop it can be overwritten
  toolbar, //= " emoticons bold italic underline link bullist numlist removeformat", //can be used to override toolbar buttons
  fileUploadHandler = fileUploader,
  onFileDelete = fileDeleteHandler,
  areAttachmentsPublic = false, //flag that denotes uploading attachments will be public or private
  inlineAttachmentOnly = false, //to allow inline attachments
  showAttachmentOverlay = true, //to show or hide uploaded attachment list
  showCustomFilePicker = false, //to show custom file picker which is used on create article
  signatureText,
  getMentionRef = (data) => {}, //function to get useRef of addMention function which can be used to insert placholder variables inside editor
  readonly = false,
  allowedEmbbedTypes = ["image", "gif"], //allowed embedded attachment type, by default enabled image and gif
  disableFormatingsAndEmbedding = false,
  disableEmbedding = false,
  autoCompleteMenuConfigs,
  autosavePrefix,
  mentionsEnabled = false,
  setSigEdited,
  sigEdited,
  hideAllControls = false, //to hide all controls
  compareOverAllAttachmentSize = true, //flag resposible for calculating the overall upload file limit
  buttonContainerClass = "",
  customAllowedAttachmentTypeForFilePicker,
  tinymceEditorRef,
  customAutoCompleteOptions,
}: TinyMCEEditorContainerProps) => {
  //creating useRef to store the instace of (tinyMCE) Editor instace and can be used interact with editor from this component
  const fallbackEditorRef = useRef<TinyMceEditor | null>(null);
  const editorRef = tinymceEditorRef ?? fallbackEditorRef;
  const containerRef = useRef<any>(null);

  //creating useRef to pass the addMention function from this component to parent component using getMentionRef function
  const addMentionRef = useRef({ addMention: null } as any);

  //initializing useFileUpload custom hook which will handle the select, upload and delete attachments
  const {
    showFileWarning,
    setShowFileWarning,
    removeUploadFiles,
    imageUploadHandler,
    handleOnFileDrop,
    selectFileFromLocal,
    deleteAttachmentByFileId,
    parseSelectedFiles,
  } = useFileUpload({
    fileUploadHandler: fileUploadHandler,
    areAttachmentsPublic,
    onFileDelete: onFileDelete,
    setUploadFiles: setSelectedFiles,
    uploadFiles: selectedFiles,
    inlineAttachmentOnly: inlineAttachmentOnly,
    isFileUploading,
    setIsFileUploading,
    editorRef: editorRef,
    disableAttachment,
    allowedEmbbedTypes: disableEmbedding === true ? [] : allowedEmbbedTypes,
    compareOverAllAttachmentSize: compareOverAllAttachmentSize, //flag resposible for calculating the overall upload file limit
  });

  const tinymceAttachmentDeleteListener = useTinymceAttachmentDeleteListener({
    editorRef,
    deleteAttachmentByFileId,
    canDeleteInline,
  });

  const { dragClass, setDragClass } = useDragAndDropStyling({
    editorRef,
    disableAttachment: disableAttachment,
    dragClasses: {
      dragging: styles.dragging,
    },
  });

  //keeping some states in useRef to avoid stale state
  const currentState = useRef({ value });

  setTimeout(() => {
    //passing the addMentionRef to parent component
    getMentionRef({ addMentionRef });
  }, 0);

  //helper method which will hide the file size|type warning modal
  const onFileWarningHide = () => {
    setShowFileWarning({ show: false, fileName: "", type: "" });
  };

  //preparing the editor config values passed from prop {options} and adding imageUploadHandler and required settings to config variable
  //using the useMemo to avoid reinitialization of config variable on every rerender
  const config = useMemo(() => {
    let editorIntiProps: RawEditorOptions = {
      file_picker_types: "file image media",
      ...options,
      images_upload_handler: imageUploadHandler,
      setup: (editor) => {
        //adding custom button for upload image button
        editor.ui.registry.addButton(TINY_MCE_TOOLBAR_CUSTOM_IMAGE_UPLOAD_BTN, {
          icon: "image",
          tooltip: "Upload Image",
          onAction: (_) => {
            //onclick
            //calling the file picker from the useFileUpload custom hook to pick file from local
            selectFileFromLocal(TINY_MCE_ATTACHEMENT_SUPPORTED_IMG_TYPES, true);
          },
        });

        //adding custom button for upload media button
        editor.ui.registry.addButton(TINY_MCE_TOOLBAR_CUSTOM_MEDIA_UPLOAD_BTN, {
          icon: "embed",
          tooltip: "Upload Video",
          onAction: (_) => {
            //onclick
            //calling the file picker from the useFileUpload custom hook to pick file from local
            selectFileFromLocal(
              TINY_MCE_ATTACHEMENT_SUPPORTED_VIDEO_TYPES,
              true,
            );
          },
        });
      },
      // file_picker_callback: handleFilePickerCallback,
    };

    return editorIntiProps;
  }, [options, imageUploadHandler, selectFileFromLocal]);

  useEffect(() => {
    //updating the useRef with updated values to avoid stale state
    currentState.current.value = value;
  }, [selectedFiles, value, toolbar]);

  //inserting mentions which used in brands... example "{{agent.name}}"
  const addMention = (mentionParams: {
    name: string;
    id: string;
    inlineStyles?: string;
    variableKey?: string;
  }) => {
    //checking if editor exists in useRef
    if (editorRef.current) {
      //inserting the content to editor
      //using the same quill class only for retrospective change
      editorRef.current.insertContent(variableSpanGenerator(mentionParams));
    }
  };

  useEffect(() => {
    //adding the addMention function to addMentionRef
    addMentionRef.current.addMention = addMention;
  }, [addMention]);

  return (
    <>
      <div
        ref={containerRef}
        className={`${styles.drag} ${dragClass}`}
      >
        <div>
          <TinyMCEEditor
            editorRef={editorRef}
            attachmentUploadData={attachmentUploadData}
            name={name}
            className={`${className}`}
            editorClassName={`${editorClassName}`}
            value={value}
            onChange={onChange}
            options={config}
            uniqueID={uniqueID}
            toolbar={disableFormatingsAndEmbedding ? "emoticons" : toolbar}
            // disableFormatingsAndEmbedding={disableFormatingsAndEmbedding}
            threadData={threadData}
            sendThread={sendThread}
            setSendThread={setSendThread}
            showThread={showThread}
            setShowThread={setShowThread}
            onDrop={(event, editor) => {
              setDragClass("");
              handleOnFileDrop(event, editor);
            }}
            onDragOver={(event, editor) => {
              event.preventDefault();
              setDragClass(styles.dragged);
            }}
            showToolbar={showToolbar}
            signatureText={signatureText}
            readonly={readonly}
            hidePlusIcon={showCustomFilePicker}
            autoCompleteMenuConfigs={autoCompleteMenuConfigs}
            autosavePrefix={autosavePrefix}
            mentionsEnabled={mentionsEnabled}
            setSigEdited={setSigEdited}
            sigEdited={sigEdited}
            customAutoCompleteOptions={customAutoCompleteOptions}
          />
        </div>
        {!hideAllControls && (
          <div className={`h-0 ${styles.ResHeight} ${buttonContainerClass}`}>
            <div
              className={`d-flex justify-content-between my-1 ${styles.sendButtonSec}`}
            >
              <div className={`my-auto d-flex`}>
                {showCustomFilePicker === false && showToolbar && (
                  <div
                    className={`my-2 mx-2 d-flex flex-column justify-content-center ${styles.attach} ${styles["translate-up"]} ${styles.fixtAddPos}`}
                  >
                    {disableAttachment === false ? (
                      <AttachmentPicker
                        additionalOptions={additionalOptions}
                        handleAddAttachmentClick={() =>
                          selectFileFromLocal(
                            customAllowedAttachmentTypeForFilePicker
                              ? customAllowedAttachmentTypeForFilePicker
                              : TINY_MCE_ATTACHEMENT_SUPPORTED_TYPES,
                            inlineAttachmentOnly === true ? true : false,
                          )
                        }
                        editorRef={editorRef}
                      />
                    ) : (
                      <AdditionalOptionList
                        additionalOptions={additionalOptions}
                        editorRef={editorRef}
                      />
                    )}
                  </div>
                )}
                {/* This is a modal for warning for file exceed or image type issue  */}
                <Modal
                  backdropClassName={`${styles.modalBack}`}
                  show={showFileWarning.show}
                  onHide={onFileWarningHide}
                  dialogClassName={`${styles.modalDialogWarning}`}
                  contentClassName={`${styles.modalContentWarning}`}
                  // centered={true}
                  enforceFocus={false}
                >
                  <FileSizeWarning
                    sizeInMB={20}
                    errorType={showFileWarning.type as any}
                    fileName={showFileWarning.fileName}
                    onHide={onFileWarningHide}
                    errorText={showFileWarning.errorText} //passing error text prop to dynamically render error text
                  />
                </Modal>
              </div>
              <div
                className={`mx-0 max-content-width d-flex ${styles["translate-up"]}`}
                data-btncontainer="true"
              >
                {buttons}
              </div>
            </div>
            {showCustomFilePicker === true && showToolbar && (
              <CustomMediaPicker
                selectFileFromLocal={selectFileFromLocal}
                allowedEmbbedTypes={allowedEmbbedTypes}
              />
            )}
          </div>
        )}
        {selectedFiles &&
          Object.values(selectedFiles).length > 0 &&
          showAttachmentOverlay && (
            <UploadedFilesList
              selectedFiles={parseSelectedFiles(selectedFiles)}
              onDeleteFile={(file: any) => removeUploadFiles([file])}
            />
          )}
      </div>
    </>
  );
};
export default TinyMCEContainer;
