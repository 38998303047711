import styles from "./BrowseIntegration.module.scss";
import BrowseHomePage from "./Children/BrowseHomePage/BrowseHomePage";
import FbIntegration from "./Children/FbIntegration/FbIntegration";
import ShopifyIntegration from "./Children/ShopifyIntegration/ShopifyIntegration";
import InstaIntegration from "./Children/SmsIntegration/InstaIntegration/InstaIntegrstion";
import SmsIntegration from "./Children/SmsIntegration/SmsIntegration";
import WhatsappIntegration from "./Children/WhatsappIntegration/WhatsappIntegration";
const BrowseIntegration = () => {
  return (
    <div className={`w-100`}>
      {/* Home page for Browse Integration */}
      <BrowseHomePage />
      {/* <MyIntegration/> */}
      {/* Home page for SMS integration */}
      {/* <SmsIntegration /> */}

      {/* Home page for fb integration */}
      {/* <FbIntegration /> */}

      {/* Home page for Instagram integration  */}
      {/* <InstaIntegration /> */}

      {/* Home page for Shopify integration  */}
      {/* <ShopifyIntegration /> */}

      {/* Whatsapp page for Whatsapp integration */}
      {/* <WhatsappIntegration /> */}
    </div>
  );
};
export default BrowseIntegration;
