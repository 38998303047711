import ReactSelect from "react-select";
import styles from "../ReportInternalFilter.module.scss";
import { useAppSelector } from "src/store/store";
import { useCallback, useMemo } from "react";
import {
  reportInternalFiltersActions,
  useReportInternalFilters,
} from "src/routes/Report/hooks/reportFilters/useReportInternalFilters";

const useIntBrandFilter = () => {
  const allBrands = useAppSelector(
    (state) => state.globals.brandSignatureData.brands
  );

  const options = useMemo(() => {
    return (
      allBrands?.map((data) => {
        return {
          label: data.name,
          value: data,
        };
      }) ?? []
    );
  }, [allBrands]);

  const { internalFilters, dispatch } = useReportInternalFilters();

  const selectedBrands = useMemo(() => {
    return (
      allBrands?.filter((data) =>
        internalFilters.addedFilters.brands?.includes(data.id + "")
      ) ?? []
    ).map((value) => {
      return {
        label: value.name,
        value,
      };
    });
  }, [internalFilters, allBrands]);

  const onChangeBrands = useCallback(
    (value: typeof selectedBrands) => {
      dispatch([
        reportInternalFiltersActions.setFilters,
        { brands: value.map((data) => data.value.id + "") },
      ]);
    },
    [selectedBrands]
  );
  return {
    options,
    selectedBrands,
    onChangeBrands,
  };
};

function Brands() {
  const { options, selectedBrands, onChangeBrands } = useIntBrandFilter();

  return (
    <div className="px-2 pb-2">
      <label className={`${styles.labelText}`}>Brand(s)</label>
      <ReactSelect
        isMulti
        options={options}
        value={selectedBrands}
        onChange={onChangeBrands as any}
        menuPortalTarget={document.body}
        className={`${styles.text}`}
        styles={{
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
            font: "normal normal normal 12px/18px Poppins",
            color: "#000000",
          }),
          multiValue: (base, { data }) => ({
            ...base,
            backgroundColor: '#F4F4F4', // Background color for selected values
            borderRadius: '6px',
            opacity: 1,
          }),
          multiValueLabel: (base) => ({
            ...base,
            font: "normal normal 500 12px/18px Poppins",
            letterSpacing: "0px",
            color: "#1F2223",
            paddingLeft:"12px",
          }),
          multiValueRemove: (base, { data }) => ({
            ...base,
            // color: '#000000', // Remove icon color for selected values
            fontSize: "6px",
            // ':hover': {
            //   backgroundColor: 'red', // Change the background color on hover if needed
            // },
          }),
        }}
      />
    </div>
  );
}

export default Brands;
