import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { AJAXSTATUS } from "src/globals/constants";
import { ChatWidgetInstalledEvent } from "src/pusherServices/livechat/websiteSettings";
import {
  AllWebIntegrations,
  WebsiteIntegDet,
} from "src/services/LiveChat/Settings/website/getWebIntegrations";
import websiteSettingsExtraReducers from "./websiteSettings.extraReducers";
import websiteSettingsReducers from "./websiteSettings.reducers";
import websiteSettingsThunks from "./websiteSettings.thunks";

export interface WebsiteSettingsData {
  allWebIntegrations: AllWebIntegrations;
  allWebIntAJAXStatus: AJAXSTATUS;
  lcInstallationStatus: AJAXSTATUS;
  activeWebIntegration: WebsiteIntegDet;
  activeWebIntAJAXStatus: AJAXSTATUS;
  createdIntId: number | string | null;
  createNewWebIntAJAXStatus: AJAXSTATUS;
  updateNewWebIntAJAXStatus: AJAXSTATUS;
  sendWidgetScriptEmailAJAXStatus: AJAXSTATUS;
}

// initial states for the slice
export const initialState: WebsiteSettingsData = {
  allWebIntegrations: [],
  allWebIntAJAXStatus: "idle",
  lcInstallationStatus: "idle",
  activeWebIntegration: {
    integration_id: 0,
    store_url: null,
    embedded_script: null,
    installed_successfully: false,
    live_chat_enabled: false,
    integration_web_socket_channel_name: null,
    whatsapp_details: {
      enabled: false,
      id: 0,
      phone_numbers: [],
    },
    whatsapp_available: false,
  },
  activeWebIntAJAXStatus: "idle",
  createdIntId: null,
  createNewWebIntAJAXStatus: "idle",
  updateNewWebIntAJAXStatus: "idle",
  sendWidgetScriptEmailAJAXStatus: "idle",
};

export const fetchallWebIntegrations = createAsyncThunk(
  "websiteSettings/fetchallWebIntegrationsThunk",
  websiteSettingsThunks.fetchallWebIntegrationsThunk
);
export const fetchWebIntegration = createAsyncThunk(
  "websiteSettings/fetchWebIntegrationThunk",
  websiteSettingsThunks.fetchWebIntegrationThunk
);
export const createNewWebIntegration = createAsyncThunk(
  "websiteSettings/createNewWebIntegrationThunk",
  websiteSettingsThunks.createNewWebIntegrationThunk
);
export const updateTheWebIntegration = createAsyncThunk(
  "websiteSettings/updateTheWebIntegrationThunk",
  websiteSettingsThunks.updateTheWebIntegrationThunk
);
export const installShopifyWidget = createAsyncThunk(
  "websiteSettings/installShopifyWidgetThunk",
  websiteSettingsThunks.installShopifyWidgetThunk
);
export const checkLCInstallationT = createAsyncThunk(
  "websiteSettings/checkLCInstallationThunk",
  websiteSettingsThunks.checkLCInstallationThunk
);

export const sendWidgetScriptEmail = createAsyncThunk(
  "websiteSettings/sendWidgetScriptEmail",
  websiteSettingsThunks.sendWidgetScriptEmailThunk
);
export const websiteSettingsSlice = createSlice({
  name: "websiteSettings",
  initialState,
  reducers: websiteSettingsReducers,
  extraReducers: (builder) => {
    builder.addCase(
      fetchallWebIntegrations.fulfilled,
      websiteSettingsExtraReducers.fetchallWebIntegrationsFulfilled
    );
    builder.addCase(fetchallWebIntegrations.pending, (state) => {
      state.allWebIntAJAXStatus = "pending";
    });
    builder.addCase(fetchallWebIntegrations.rejected, (state) => {
      state.allWebIntAJAXStatus = "rejected";
      pushTheToast({
        type: "danger",
        text: "Unable to fetch!",
        position: "top-right",
      });
    });
    builder.addCase(
      fetchWebIntegration.fulfilled,
      websiteSettingsExtraReducers.fetchWebIntegrationFulfilled
    );
    builder.addCase(fetchWebIntegration.pending, (state) => {
      state.activeWebIntAJAXStatus = "pending";
    });
    builder.addCase(fetchWebIntegration.rejected, (state) => {
      state.activeWebIntAJAXStatus = "rejected";
      pushTheToast({
        type: "danger",
        text: "Unable to fetch!",
        position: "top-right",
      });
    });
    builder.addCase(
      createNewWebIntegration.fulfilled,
      websiteSettingsExtraReducers.createNewWebIntegrationFulfilled
    );
    builder.addCase(createNewWebIntegration.pending, (state) => {
      state.createNewWebIntAJAXStatus = "pending";
    });
    builder.addCase(createNewWebIntegration.rejected, (state) => {
      state.createNewWebIntAJAXStatus = "rejected";
      pushTheToast({
        type: "danger",
        text: "Unable to add new!",
        position: "top-right",
      });
    });
    builder.addCase(
      updateTheWebIntegration.fulfilled,
      websiteSettingsExtraReducers.updateTheWebIntegrationFulfilled
    );
    builder.addCase(
      installShopifyWidget.fulfilled,
      websiteSettingsExtraReducers.installShopifyWidgetFulfilled
    );
    builder.addCase(updateTheWebIntegration.pending, (state) => {
      state.updateNewWebIntAJAXStatus = "pending";
    });
    builder.addCase(updateTheWebIntegration.rejected, (state) => {
      state.updateNewWebIntAJAXStatus = "rejected";
      pushTheToast({
        type: "danger",
        text: "Update failed!",
        position: "top-right",
      });
    });
    builder.addCase(
      checkLCInstallationT.fulfilled,
      (state, { payload }: any) => {
        state.lcInstallationStatus = "fulfilled";
        websiteSettingsReducers.setInstalledWebInt(state, { payload });
      }
    );
    builder.addCase(checkLCInstallationT.pending, (state) => {
      state.lcInstallationStatus = "pending";
    });
    builder.addCase(checkLCInstallationT.rejected, (state) => {
      state.lcInstallationStatus = "rejected";
    });
    builder.addCase(
      sendWidgetScriptEmail.fulfilled,
      (state, { payload }: any) => {
        state.sendWidgetScriptEmailAJAXStatus = "fulfilled";
      }
    );
    builder.addCase(sendWidgetScriptEmail.pending, (state) => {
      state.sendWidgetScriptEmailAJAXStatus = "pending";
    });
    builder.addCase(sendWidgetScriptEmail.rejected, (state) => {
      pushTheToast({
        type: "danger",
        text: "Failed to send email!",
        position: "top-right",
      });
      state.sendWidgetScriptEmailAJAXStatus = "rejected";
    });
  },
});

export const { resetWebsiteSettings, setInstalledWebInt, setIntegrationInstalledStatus } =
  websiteSettingsSlice.actions;

export default websiteSettingsSlice.reducer;
