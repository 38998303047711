import { axiosJSON } from "src/globals/axiosEndPoints";

interface Params{
  bugId: number;
  start: number;
  limit: number;
}

interface SentBy {
  id: number;
  name: string;
  email: string;
  imageURL: string | null;
  isPublicAttachmentUrl: boolean;
}

interface AttachmentDetails {
  attachmentId: number;
  attachmentBatchNumber: string;
  attachmentName: string;
  attachmentSize: number;
  attachmentType: string;
  attachmentUrl: string;
  isPublicAttachmentUrl: boolean;
}

export interface BugComments {
  commentId: number;
  commentMessage: string;
  sentBy: SentBy;
  commentCreatedAtGmt: string;
  sentByType: string;
  senderType: string;
  attachmentDetails: AttachmentDetails[];
  message?: string;
}

interface MetaData {
  limitReached: boolean;
}

export interface BugCommentsResponse {
  err: boolean;
  data: BugComments[];
  metaData: MetaData;
}


const getBugComments = async (param: Params ) => {
  const { data: res } = await axiosJSON.post(
    `/api/selfService/bugManagement/fetchBugComments`, param
  );

  if (res.err || res.error) {
    throw res.msg ?? res.message ?? "Something went wrong!";
  }

  let ret: BugCommentsResponse = {
    err: false,
    data: [],
    metaData: {
      limitReached: false,
    },
  };

  ret.data = res.data;

  if (!isNaN(param.limit)) {
    if (ret.data.length < param.limit) {
      ret.metaData.limitReached = true;
    }
  } 

  return ret;
};

export default getBugComments;
