import styles from "../../UpdateSegmentDetails.module.scss";
import { useUpdateSegmentDetails } from "../../hooks/useUpdateSegmentDetails";

const SegmentName = () => {
  const { updateSegmentDetails, dispatch } = useUpdateSegmentDetails();

  return (
    <div className="mt-4 pt-2">
      {/* Segment Name */}
      <div className="mb-3">
        <label
          htmlFor="segmentName"
          className={`form-label ${styles.formLabel}`}
        >
          Name
        </label>
        <input
          type="text"
          className={`form-control ${styles.formInput} ${
            updateSegmentDetails.showErrors &&
            !updateSegmentDetails.segmentDetails.segmentName?.trim()
              ? styles.errBorder
              : ""
          }`}
          id="segmentName"
          placeholder="Segment name"
          value={updateSegmentDetails.segmentDetails.segmentName ?? ""}
          onChange={(e) => {
            dispatch("updateSegmentDetails", { segmentName: e.target.value });
          }}
        />
        {updateSegmentDetails.showErrors &&
        !updateSegmentDetails.segmentDetails.segmentName?.trim() ? (
          <div className={styles.errText}>{"Please enter value!"}</div>
        ) : (
          ""
        )}
      </div>

      {/* Segment Description */}
      <div className="mb-3">
        <label
          htmlFor="segmentDescription"
          className={`form-label ${styles.formLabel}`}
        >
          Description
        </label>
        <textarea
          className={`form-control ${styles.formInput} ${
            updateSegmentDetails.showErrors &&
            !updateSegmentDetails.segmentDetails.segmentDescription?.trim()
              ? styles.errBorder
              : ""
          }`}
          id="segmentDescription"
          rows={3}
          placeholder="Segment description..."
          value={updateSegmentDetails.segmentDetails.segmentDescription ?? ""}
          onChange={(e) => {
            dispatch("updateSegmentDetails", {
              segmentDescription: e.target.value,
            });
          }}
        ></textarea>
        {updateSegmentDetails.showErrors &&
        !updateSegmentDetails.segmentDetails.segmentDescription?.trim() ? (
          <div className={styles.errText}>{"Please enter value!"}</div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default SegmentName;
