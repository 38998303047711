import React, { useEffect, useState } from "react";
import styles from "./EditContactInformation.module.scss";
import { Button, Form, Modal } from "react-bootstrap";
import { ModalStyleClasses } from "../../../../../Modal/ModalContext";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { validateEmail } from "src/utils/utils";
import { updateContactDetailsThunk } from "src/store/slices/singleOrder/order.thunks";

function EditContactInformation(props: { onHide: () => void }) {
  const dispatch = useAppDispatch();
  const { getOrderViewModel, updateOrder } = useAppSelector(
    (state) => state.singleOrder
  );
  const [emailInput, setEmailInput] = useState("");
  const [isValidEmail, setIsvalidEmail] = useState<any>(null);
  const [isUpdateCustomerProfileChecked, setIsUpdateCustomerProfileChecked] =
    useState<boolean>(false);
  const [isValidCheck, setIsvalidCheck] = useState<any>(null);

  useEffect(() => {
    if (
      getOrderViewModel &&
      getOrderViewModel?.contactInformation &&
      getOrderViewModel?.contactInformation?.email
    )
      setEmailInput(getOrderViewModel?.contactInformation?.email);
  }, [getOrderViewModel]);

  // handle the email change
  const handleEmailChange = (e: any) => {
    let email = e.target.value;
    if (isValidEmail !== null) {
      if (!validateEmail(email)) {
        setIsvalidEmail(false);
      } else {
        setIsvalidEmail(true);
      }
    }
    setEmailInput(email);
  };

  // handle the check box change
  const handleCheckedChange = (e: any) => {
    let checked = e.target.checked;
    if (isValidCheck !== null) {
      if (checked === false) {
        setIsvalidCheck(false);
      } else {
        setIsvalidCheck(true);
      }
    }
    setIsUpdateCustomerProfileChecked(checked);
  };

  // validate the input
  const validateInput = () => {
    if (!validateEmail(emailInput)) {
      setIsvalidEmail(false);
    }
    if (!isUpdateCustomerProfileChecked) {
      setIsvalidCheck(false);
    }
    if (!validateEmail(emailInput) || !isUpdateCustomerProfileChecked) {
      return false;
    }

    return true;
  };

  // to update contact information
  const handleUpdateContactInformation = (e: any) => {
    e.preventDefault();
    if (
      validateInput() &&
      getOrderViewModel?.orderId &&
      updateOrder?.contactDetailsUpdateAjaxStatus !== "pending"
    ) {
      // input is valid, update contact information
      dispatch(
        updateContactDetailsThunk({
          orderId: getOrderViewModel?.orderId,
          email: emailInput,
          isUpdateCustomer: isUpdateCustomerProfileChecked,
        })
      );
      // hide the modal
      props.onHide();
    }
  };

  return (
    <div>
      <>
        <Modal.Header closeButton>
          <Modal.Title className={`${styles.paymentDetail} text-dark fs-6`}>
            Edit Contact Information
          </Modal.Title>
        </Modal.Header>

        <Modal.Body
          className={`d-flex flex-column justify-content-between ${styles.paymentDetail}`}
        >
          <div>
            <div>
              <form className={`${styles.paymentDetails}`}>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1" className="text-dark ">
                    Email{" "}
                  </label>
                  {isValidEmail === false ? (
                    <span className={styles.errorSpan}>
                      * Please enter valid email
                    </span>
                  ) : (
                    <></>
                  )}

                  <input
                    type="email"
                    className="form-control"
                    aria-describedby="emailHelp"
                    placeholder="Enter email"
                    value={emailInput}
                    onChange={(e) => handleEmailChange(e)}
                  />
                  <div className="d-flex align-items-start mt-3 ">
                    <Form.Check
                      type="checkbox"
                      className="me-2 "
                      checked={isUpdateCustomerProfileChecked}
                      onClick={(e: any) => handleCheckedChange(e)}
                    />
                    <div className=" small ">
                      Update customer profile{" "}
                      {isValidCheck === false ? (
                        <span className={styles.errorSpan}>
                          * Please select Update customer profile to save
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div
            className={`d-flex  justify-content-end  w-100 ${styles.paymentDetail} `}
          >
            <div className="d-flex ">
              <div className="me-1 ">
                <Button
                  variant="outline-secondary"
                  className="text-dark"
                  onClick={() => props.onHide()}
                >
                  Cancel
                </Button>
              </div>

              <div>
                <Button
                  variant="outline-light border"
                  className="text-white "
                  style={{ backgroundColor: "#2b7554" }}
                  onClick={(e: any) => handleUpdateContactInformation(e)}
                >
                  Save{" "}
                </Button>{" "}
              </div>
            </div>
          </div>
        </Modal.Footer>
      </>
    </div>
  );
}

export default EditContactInformation;
