import { useId } from "react";
import styles from "./FeatureBox.module.scss";
import { ComingSoonFeatureTypes } from "src/services/ComingSoon/reqCmngSnFeature.service";

interface Props
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label: string;
  value: ComingSoonFeatureTypes;
}
const FeatureBox = ({ label, ...props }: Props) => {
  const id = useId();

  return (
    <div className={`ps-4 pe-3 my-2 py-4 me-3 mb-3 ${styles.featureBox}`}>
      <div className="form-check">
        <input
          {...props}
          className={`form-check-input cursor-pointer ${styles.inputBox} ${props.className}`}
          type="checkbox"
          id={id}
        />
      </div>
      <label htmlFor={id} className={`mb-1 mt-2 ${styles.fetureLabel}`}>
        {label}
      </label>
    </div>
  );
};

export default FeatureBox;
