import { useInfiniteQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import {
  IGetInvoiceDetailResponse,
  getInvoiceList,
} from "src/services/Billing/getInvoiceList";

/**
 * Custom hook for getting invoices list in billing UI
 */
export const useInvoiceList = () => {
  /**
   * Initial payload params
   */
  const initialPayload = useMemo(() => {
    return {
      start: 0,
      limit: 10,
    };
  }, []);

  /**
   * Infinite query function to get invoices
   */
  const { status, data, hasNextPage, fetchNextPage, isLoading, fetchStatus } =
    useInfiniteQuery({
      queryKey: ["setting/billing/getInvoiceList", initialPayload],
      queryFn: ({ pageParam = initialPayload }) => getInvoiceList(pageParam),
      getNextPageParam: (
        prevPage: IGetInvoiceDetailResponse,
        _allPages: IGetInvoiceDetailResponse[]
      ) => {
        // Return next page params if we have more pages
        if (prevPage.metaData.hasMore) {
          return {
            limit: initialPayload.limit,
            start: prevPage.metaData.currentCount,
          };
        }
        return null;
      },
    });

  /**
   * Memoized Invoices Object containing all Invoices data mapped to ids
   */
  const invoices = useMemo(() => {
    let invoicesData: IGetInvoiceDetailResponse["invoices"] = {};
    data?.pages.forEach((page) => {
      invoicesData = { ...invoicesData, ...page.invoices };
    });
    return invoicesData;
  }, [data]);

  /**
   * Memoized Array of Invoice Ids for mapping with Invoices object
   */
  const invoicesIds = useMemo(() => {
    const ids = data?.pages.flatMap((page) => page.invoiceIds) ?? [];
    return Array.from(new Set([...ids]));
  }, [data]);

  /**
   * Memoized Error Message for no invoice found
   */
  const errorMessage = useMemo(() => {
    if (
      status === "error" ||
      (status !== "success" && fetchStatus !== "fetching") ||
      (status !== "loading" && invoicesIds.length === 0)
    ) {
      return "No Invoices Found";
    }
  }, [fetchStatus, invoicesIds.length, status]);

  return {
    invoices,
    invoicesIds,
    fetchNextPage,
    hasNextPage,
    errorMessage,
    isLoading,
    status,
  };
};
