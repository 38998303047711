import styles from "./LiveConversation.module.scss";
import UserAvatar from "src/components/UserAvatar";
import {
  OverlayTrigger,
  Tooltip,
  Button,
  Image,
  Modal,
  Spinner,
} from "react-bootstrap";
import logo from "../../../../assets/images/missed.png";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  Customer,
  SOCIAL_CHANNELS,
  clearGetAllCustomersCache,
} from "src/services/LiveChat/messageExchange/getAllCustomers";
import ParseHTML from "src/components/ParseHTML";
import { useNavigate, useParams } from "react-router-dom";
import AxiosImg from "src/components/AxiosImg";
import {
  convertGmtToUserTimezone,
  getTimeFromNow,
} from "src/utils/dateLibrary";
import { getInnerText } from "src/utils/utils";
import CreateTicket from "../MainChat/Children/ChatTabs/Children/CreateTicket/CreateTicket";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import SuccessMsg from "../MainChat/Children/ChatTabs/Children/CreateTicket/SuccessMsg/SuccessMsg";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { resolveChat } from "src/services/LiveChat/createTicket/resolveChat.service";
import { actions } from "src/store/slices/liveChatSetting/chatWidExchCust/chatWidExchCust.slice";
import { setIsResolved } from "src/store/slices/liveChatSetting/chatWidExchMsg/chatWidExchMsg.slice";
import messenger from "src/assets/images/messenger.png";
import instagram from "src/assets/images/instagram.png";
import sms from "src/assets/images/smsIcon.png";
import whatsapp from "src/assets/images/whatsapp.png";
import botImg from "src/assets/images/botImg.png";

const renderAssingedAgentInfo = (assignedAgents: Array<any>) => {
  if (assignedAgents.length > 2) {
    const assignedAgent = assignedAgents[0];
    return (
      <>
        <span className={`position-relative ${styles.chatImg}`}>
          {assignedAgent.imgUrl && assignedAgent.imgUrl.length !== 0 ? (
            <AxiosImg
              url={assignedAgent.imgUrl}
              className={`${styles.profileImg}`}
              isDirectURL={assignedAgent.isPublicAttachmentUrl}
            />
          ) : (
            <UserAvatar
              name={
                assignedAgent.name && assignedAgent.name !== ""
                  ? assignedAgent.name
                  : "NA"
              }
              size={32}
              className={`img-fluid`}
            />
          )}
        </span>
        <span className={`position-relative ${styles.chatImg}`}>
          <span
            className={`d-flex align-items-center justify-content-center ${styles.agentImg} ${styles.agentImg2} ${styles.plusImageIcon}`}
          >
            +{assignedAgents.length - 1}
          </span>
        </span>
      </>
    );
  } else if (assignedAgents.length === 2) {
    return (
      <>
        {assignedAgents.map((assignedAgent: any, index: number) => {
          return (
            <span
              className={`position-relative ${styles.agentImg} ${
                index !== 0 ? styles.agentImg2 : ""
              }`}
            >
              {assignedAgent.imgUrl && assignedAgent.imgUrl.length !== 0 ? (
                <AxiosImg
                  url={assignedAgent.imgUrl}
                  className={`img-fluid ${styles.profileImg}`}
                  isDirectURL={assignedAgent.isPublicAttachmentUrl}
                />
              ) : (
                <UserAvatar
                  name={
                    assignedAgent.name && assignedAgent.name !== ""
                      ? assignedAgent.name
                      : "NA"
                  }
                  size={32}
                  className={`img-fluid`}
                />
              )}
            </span>
          );
        })}
      </>
    );
  } else {
    const assignedAgent = assignedAgents[0];

    return (
      <span className={`position-relative ${styles.agentImg}`}>
        {assignedAgent.imgUrl && assignedAgent.imgUrl.length !== 0 ? (
          <AxiosImg
            url={assignedAgent.imgUrl}
            className={`${styles.profileImg}`}
            isDirectURL={assignedAgent.isPublicAttachmentUrl}
          />
        ) : (
          <UserAvatar
            name={
              assignedAgent.name && assignedAgent.name !== ""
                ? assignedAgent.name
                : "NA"
            }
            size={32}
            className={`img-fluid`}
          />
        )}
      </span>
    );
  }
};

const LiveConversation = ({
  customer,
  setResolveAndCreateTicket,
  onShow,
  setSelectedChat,
}: {
  customer: Customer;
  setResolveAndCreateTicket: Function;
  onShow: Function;
  setSelectedChat: Function;
}) => {
  const { chatId, chatStatus } = useParams();

  const userTimezone = useAppSelector(
    (state) => state.globals.currentUserData?.userTimezone,
  );

  const currentUserData = useAppSelector(
    (state) => state.globals.currentUserData,
  );

  const {
    chatIdList,
    selectedCustomerStatus,
    selectedCustomerChatId,
    customers,
  } = useAppSelector((state) => state.chatWidExchCust);

  const [isResolveLoading, setIsResolveLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const currentState = useRef({
    chatIdList,
    selectedCustomerStatus,
    selectedCustomerChatId,
    customers,
    currentUserData,
    chatStatus,
  });
  useEffect(() => {
    currentState.current = {
      chatIdList,
      selectedCustomerStatus,
      selectedCustomerChatId,
      customers,
      currentUserData,
      chatStatus,
    };
  }, [
    chatIdList,
    selectedCustomerStatus,
    selectedCustomerChatId,
    customers,
    currentUserData,
    chatStatus,
  ]);

  // function to handle resolve chat
  const handleResolveChat = (e: any) => {
    setIsResolveLoading(true);
    const payloadChatId = customer.chatId;
    if (customer.chatId) {
      resolveChat({ chatRequestId: customer.chatId })
        .then((res) => {
          // update is resolved or not
          // refresh sidebar data
          // if (fetchCustAJAXStatus !== "pending") {
          // dispatch(resetChatWidExchMsg());
          // dispatch(
          //   actions.resetCustomersData({ loading: false, initialized: true })
          // );
          // dispatch(fetchCustomerData({ start: 0 }));

          //checking if there is any other chat exists in same tab if yes then will delete the resolved chat from current list ..
          //if there will be no chat left after removing resolved chat ..then redirect user to archived
          if (
            currentState.current.selectedCustomerStatus !== "archived" &&
            (currentState.current.chatIdList.length === 0 ||
              (currentState.current.chatIdList.length === 1 &&
                currentState.current.chatIdList.includes(payloadChatId)))
          ) {
            clearGetAllCustomersCache();

            navigate(`/live-chat/chats/archived/${payloadChatId}`);
            if (currentState.current.selectedCustomerChatId == payloadChatId) {
              dispatch(setIsResolved({ isResolved: true }));
            }
            //removing chat from currect chat list
          } else if (
            currentState.current.selectedCustomerStatus !== "archived"
          ) {
            if (payloadChatId) {
              let previousChatId: number | null = null;

              const chatIds = currentState.current.chatIdList.filter(
                (chatId, index) => {
                  if (payloadChatId != chatId) {
                    return true;
                  } else {
                    previousChatId =
                      index === 0
                        ? null
                        : parseInt(
                            currentState.current.chatIdList[index - 1] + "",
                          );
                    return false;
                  }
                },
              );

              dispatch(
                actions.removeCustomerFromChatList({
                  chatId: parseInt(payloadChatId + ""),
                }),
              );

              // Clear the cache for all customers api call to refetch on changing tabs
              clearGetAllCustomersCache();

              const redirectChatId =
                previousChatId && previousChatId !== null
                  ? previousChatId
                  : chatIds[0];

              navigate(
                chatStatus && chatStatus !== undefined
                  ? window.location.pathname.replace(
                      `chats/${chatStatus}/${chatId}`,
                      `chats/${chatStatus}/${redirectChatId}`,
                    )
                  : `/live-chat/chats/${currentState.current.selectedCustomerStatus}/${redirectChatId}`,
              );
            }
          } else {
            const updatedCustomer =
              currentState.current?.customers[payloadChatId];
            if (customer) {
              dispatch(
                actions.setCustomerData({
                  customer: { ...updatedCustomer, isResolved: true },
                  checkForExistingCust: true,
                }),
              );
            }
            if (currentState.current.selectedCustomerChatId == payloadChatId) {
              dispatch(setIsResolved({ isResolved: true }));
            }
          }

          // }
          pushTheToast({
            text: `Chat is resolved${
              currentState.current.chatStatus !== "archived"
                ? " and moved to archive"
                : ""
            }`,
            position: "top-right",
            type: "success",
          });
          setIsResolveLoading(false);
        })
        .catch((err) => {
          pushTheToast({
            text: "Failed to resolve chat",
            position: "top-right",
            type: "danger",
          });
          setIsResolveLoading(false);
        });
    }
  };

  const concatinateAgentNames = useCallback(
    (
      assignedAgents: Array<{
        id: number | string;
        name: string;
        imgUrl: string | null;
      }>,
    ) => {
      if (assignedAgents.length === 1) {
        if (
          currentState.current.currentUserData?.userId == assignedAgents[0].id
        ) {
          if (customer.isConnected) {
            return "You are chatting";
          } else {
            return "Handled by You";
          }
        } else {
          if (customer.isConnected) {
            return `${assignedAgents[0].name ?? "NA"} is chatting`;
          } else {
            return `Handled by ${assignedAgents[0].name ?? "NA"}`;
          }
        }
      } else {
        const includesCurrentAgent = assignedAgents.filter((agentInfo) => {
          if (agentInfo.id == currentState.current.currentUserData?.userId) {
            return true;
          }

          return false;
        });

        if (customer.isConnected) {
          return includesCurrentAgent.length === 0
            ? `${assignedAgents[0].name ?? "NA"} and +${
                assignedAgents.length - 1
              } others are chatting`
            : `You and +${assignedAgents.length - 1} are chatting`;
        } else {
          return includesCurrentAgent.length === 0
            ? `Handled by ${assignedAgents[0].name ?? "NA"} and +${
                assignedAgents.length - 1
              } others`
            : `Handled by You and +${assignedAgents.length - 1}`;
        }
      }
    },
    [],
  );

  return (
    <div className="mb-2">
      <div
        className={`cursor-pointer ${styles.chatBox} ${
          chatId == customer.chatId ? `${styles.chatBoxActive}` : ""
        }`}
      >
        <div className={`d-flex justify-content-between flex-wrap mb-1`}>
          <div className={`w-100 d-flex overflow-hidden`}>
            {customer.imageURL && customer.imageURL.length !== 0 ? (
              <AxiosImg
                url={customer.imageURL}
                className={`${styles.profileIcon}`}
                isDirectURL={customer.isPublicAttachmentUrl}
              />
            ) : (
              <div>
                <UserAvatar
                  name={
                    customer.name && customer.name !== "" ? customer.name : "NA"
                  }
                  size={32}
                />
              </div>
            )}

            <div className={`w-100 mx-1 overflow-hidden text-truncate`}>
              <div className="d-flex justify-content-between align-items-center">
                <div className="w-50 d-flex align-items-center">
                  <p className={`${styles.liveChatName} pe-1 text-truncate`}>
                    {customer.name}
                  </p>
                </div>
                <div className="d-flex align-items-center justify-content-space-between">
                  {customer.chatChannelType &&
                    customer.chatChannelType == "whatsapp" && (
                      <span className={`${styles.mediaBox}`}>
                        <span>
                          <i className="fa-brands fa-whatsapp"></i>
                        </span>
                      </span>
                    )}
                  {customer.chatChannelType &&
                    customer.chatChannelType == "messenger" && (
                      <span className={`${styles.mediaBox}`}>
                        <img
                          src={messenger}
                          alt=""
                        />
                      </span>
                    )}
                  {customer.chatChannelType &&
                    customer.chatChannelType == "instagram" && (
                      <span className={`${styles.mediaBox}`}>
                        <img
                          src={instagram}
                          alt=""
                        />
                      </span>
                    )}
                  {customer.chatChannelType &&
                    customer.chatChannelType == "email" && (
                      <span className={`${styles.mediaBox}`}>
                        <i className="fas fa-envelope"></i>
                      </span>
                    )}
                  <span className={`${styles.timeShow}`}>
                    {getTimeFromNow({
                      date: new Date(customer.lastMessageTime),
                      timeZone: userTimezone,
                    })}
                  </span>
                </div>
              </div>
              <div className="w-100 d-flex align-items-center">
                <span
                  className={`${styles.liveChatEmail} d-block w-50 text-truncate`}
                >
                  {/* Adding Whatsapp number for whatsapp chat */}
                  {customer?.chatChannelType &&
                  SOCIAL_CHANNELS.includes(customer?.chatChannelType) &&
                  customer.chatChannelType != "messenger" ? (
                    <span className={`${styles.whatsappNumber}`}>
                      {customer.chatChannelToDetail &&
                      "phoneNumber" in customer.chatChannelToDetail // Check if it's PhoneNumberData
                        ? customer.chatChannelToDetail.phoneNumber // Access phoneNumber property
                        : customer.chatChannelToDetail?.value // Check if it's IChatChannelDetail
                          ? customer.chatChannelToDetail.value // Access value property
                          : "NA"}{" "}
                    </span>
                  ) : (
                    <span>{customer.email}</span>
                  )}
                </span>
                <p
                  className={`w-50 mb-0 d-block text-truncate ps-1 ${styles.location} d-flex align-items-center justify-content-center`}
                >
                  <span className="pe-1">
                    {" "}
                    <i className="fa-solid fa-location-dot"></i>
                  </span>
                  {/* Adding location */}
                  {customer.customerMeta?.customerLocation &&
                  customer.customerMeta?.customerLocation.countryName
                    ? customer.customerMeta?.customerLocation.countryName
                    : "unknown"}
                </p>
              </div>
            </div>
          </div>
          {/* <div className="ms-auto">
            <span className={`${styles.timeShow}`}>
              {getTimeFromNow({
                date: new Date(customer.lastMessageTime),
                timeZone: userTimezone,
              })}
            </span>
          </div> */}
        </div>
        <div className={`${styles.brandInfo}`}>
          <span className={`${styles.brandName} flex-grow-1 me-2 pt-1`}>
            {customer.brand?.name}
          </span>
          {customer.brand?.imgURL ? (
            <AxiosImg
              url={customer.brand?.imgURL}
              alt="Brand Logo"
              className={`${styles.brandImage} rounded-circle`}
              isDirectURL={customer.brand?.isPublicAttachmentUrl}
            />
          ) : null}
        </div>
        <div className={`d-flex justify-content-between`}>
          <div className={`${styles.textMsg}`}>
            <ParseHTML html={getInnerText(customer.lastMessage ?? "")} />
          </div>
          <div>
            {customer.customerStatus === "missed" ? (
              customer.missedMessages && customer.missedMessages !== 0 ? (
                <div className={`px-2 ${styles.missed}`}>
                  <img
                    className={`${styles.missLogo}`}
                    src={logo}
                    alt=""
                  />
                  <span className={`${styles.missChatNos}`}>
                    {customer.missedMessages}
                  </span>
                </div>
              ) : null
            ) : customer.missedMessages && customer.missedMessages !== 0 ? (
              <div className={`${styles.quantity}`}>
                {customer.missedMessages}
              </div>
            ) : null}
          </div>
        </div>
        {/* <div>
          <span className={`${styles.liveChatEmail}`}>
            Adam<span></span> is typing...{" "}
          </span>
        </div> */}
        {/* <div className={`d-flex justify-content-between align-items-center`}>
          <div className={`${styles.markRead}`}>
            <span>
              <i className="fa-solid fa-reply"></i>
            </span>{" "}
            <span className={`ms-1`}> Mark Replied</span>
          </div>
          <div className="d-flex">
            <div className={`px-2 ${styles.checkBox}`}>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip className={` ${styles.toolTipCustom}`}>
                    Resolve
                  </Tooltip>
                }
              >
                {({ ref, ...triggerHandler }) => (
                  <div {...triggerHandler} ref={ref}>
                    <span>
                      <i className="fa-solid fa-check-double"></i>
                    </span>
                  </div>
                )}
              </OverlayTrigger>
            </div>
            <div className={`px-2 ${styles.checkBox2}`}>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip className={` ${styles.toolTipCustom}`}>
                    Resolve and create ticket
                  </Tooltip>
                }
              >
                {({ ref, ...triggerHandler }) => (
                  <div {...triggerHandler} ref={ref}>
                    <span>
                      {" "}
                      <i className="fa-solid fa-check-double"></i>
                    </span>
                    <span className="mx-1">
                      <i className="fa-solid fa-plus"></i>
                    </span>
                    <span>
                      {" "}
                      <i className="fa-solid fa-ticket"></i>
                    </span>
                  </div>
                )}
              </OverlayTrigger>
            </div>
          </div>
        </div> */}
        {/* <div className={`text-end ${styles.resolved}`}>
          <span>
            <i className="fa-solid fa-check-double"></i>
          </span>
          <span className={`ms-2`}>Resolved</span>
        </div> */}

        {customer.chatStatus == "queued" &&
        customer.queuedDatetimeGmt !== null &&
        customer.queuedDatetimeGmt !== undefined ? (
          <>
            <div className={`${styles.waiting}`}>
              <span className="me-2">
                <i className="fa-solid fa-hourglass"></i>
              </span>
              <span className="">
                Waiting for{" "}
                {getTimeFromNow({
                  need: "time",
                  date: new Date(
                    convertGmtToUserTimezone(
                      customer.queuedDatetimeGmt,
                      userTimezone,
                    ),
                  ),
                  timeZone: userTimezone,
                  showAgo: false,
                  showJustNow: false,
                  secondString: {
                    secondText: " sec",
                    secondsText: " secs",
                  },
                  minuteString: {
                    minuteText: " m",
                    minutesText: " m",
                  },
                })}
              </span>
            </div>
          </>
        ) : (
          customer.isCustomerWaitingForReply === true &&
          customer.customerWaitingForReplySince !== null && (
            <>
              <div className={`${styles.waiting}`}>
                <span className="me-2">
                  <i className="fa-solid fa-clock"></i>
                </span>
                <span className="">
                  {customer.customerWaitingForReplySince}
                </span>
              </div>
            </>
          )
        )}

        {customer &&
          SOCIAL_CHANNELS.includes(customer.chatChannelType + "") && (
            <div
              className={`d-flex align-items-center pt-2 justify-content-beween`}
            >
              {!customer?.isResolved ? (
                <>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip className={` ${styles.toolTipCustom}`}>
                        Resolve
                      </Tooltip>
                    }
                  >
                    {({ ref, ...triggerHandler }) => (
                      <div
                        {...triggerHandler}
                        className={`me-2 ${styles.checkBox}`}
                        ref={ref}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleResolveChat(e);
                        }}
                      >
                        {isResolveLoading ? (
                          <>
                            {" "}
                            <Spinner
                              className="my-auto mx-1"
                              animation="border"
                              color="black"
                              size="sm"
                            />
                          </>
                        ) : (
                          <>
                            <span>
                              <i
                                className={`${styles.iconFonts} fa-solid fa-check-double`}
                              ></i>
                            </span>
                          </>
                        )}
                      </div>
                    )}
                  </OverlayTrigger>
                </>
              ) : (
                <>
                  {" "}
                  <div className="me-2">
                    <span className={`${styles.ticketCreatedText}`}>
                      <i
                        className={`${styles.iconFonts} fa-solid fa-check-double`}
                      ></i>{" "}
                      Resolved
                    </span>
                  </div>
                </>
              )}

              <div className={` p-1 ${styles.assignedAgents} d-flex ms-auto`}>
                <div
                  className="d-flex"
                  style={{ flexShrink: "0" }}
                >
                  {customer.chatStatus === "chat_in_progress_ai" ? (
                    <span className={`position-relative ${styles.chatImg}`}>
                      <img
                        src={botImg}
                        className={`rounded-circle img-fluid`}
                      />
                    </span>
                  ) : customer?.chatAssignedTo &&
                    customer.chatAssignedTo.length !== 0 ? (
                    renderAssingedAgentInfo(customer?.chatAssignedTo)
                  ) : (
                    <span className={`position-relative ${styles.chatImg}`}>
                      <UserAvatar
                        name={"NA"}
                        size={20}
                        className="img-fluid"
                      />
                    </span>
                  )}
                </div>
                <div>
                  <span className={`ms-1 ${styles.assignedAgentsName}`}>
                    {customer.chatStatus === "chat_in_progress_ai"
                      ? "Handled by Cern Bot"
                      : customer?.chatAssignedTo &&
                          customer.chatAssignedTo.length
                        ? concatinateAgentNames(customer?.chatAssignedTo)
                        : "Agent Not Assigned"}
                  </span>
                </div>
              </div>
            </div>
          )}

        {customer &&
          customer?.isResolved !== undefined &&
          !SOCIAL_CHANNELS.includes(customer?.chatChannelType + "") && (
            <div
              className={`d-flex align-items-center pt-2 justify-content-beween`}
            >
              {/* It is commented for now on 10/10/2022. Bugs/Updates given by the Yuvaraj */}
              {/* First Section */}
              {customer?.isResolved === true && !customer?.ticketData ? (
                <>
                  {customer?.chatStatus !== "chat_in_progress_ai" ? ( // Condition to check if user is still connected to bot then hiding the create ticket and resolve buttons
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip className={` ${styles.toolTipCustom}`}>
                          Create Ticket
                        </Tooltip>
                      }
                    >
                      {({ ref, ...triggerHandler }) => (
                        <div
                          {...triggerHandler}
                          className={`me-2 ${styles.checkBox} ${
                            customer.isWhatsappChat ? styles.disabled : ""
                          }}`}
                          ref={ref}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (customer.isWhatsappChat) {
                              return;
                            }
                            setResolveAndCreateTicket(false);
                            setSelectedChat(customer);
                            onShow();
                          }}
                          id="createTicket"
                        >
                          <span>
                            <i
                              className={`${styles.iconFonts} fa-solid fa-ticket`}
                            ></i>
                          </span>
                        </div>
                      )}
                    </OverlayTrigger>
                  ) : null}
                </>
              ) : (
                <>
                  {customer?.ticketData && (
                    <div className="me-2">
                      {customer?.isResolved === true ? (
                        <>
                          <span className={`${styles.ticketCreatedText}`}>
                            <i
                              className={`${styles.iconFonts} fa-solid fa-check-double`}
                            ></i>{" "}
                            +{" "}
                          </span>
                          <span className={`${styles.ticketCreatedText}`}>
                            <i
                              className={`${styles.iconFonts} fa-solid fa-ticket`}
                            ></i>{" "}
                            Resolved
                          </span>
                        </>
                      ) : (
                        <>
                          <span className={`${styles.ticketCreatedText}`}>
                            <i
                              className={`${styles.iconFonts} fa-solid fa-ticket`}
                            ></i>{" "}
                            Ticket Created
                          </span>
                        </>
                      )}
                    </div>
                  )}
                </>
              )}

              {/* Resolved/Resolve Section */}
              {!customer?.isResolved &&
              customer?.chatStatus !== "chat_in_progress_ai" ? ( // Condition to check if the user is connected to bot, if user is still connected to bot then hiding create ticket the buttons
                <>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip className={` ${styles.toolTipCustom}`}>
                        Resolve
                      </Tooltip>
                    }
                  >
                    {({ ref, ...triggerHandler }) => (
                      <div
                        {...triggerHandler}
                        className={`me-2 ${styles.checkBox}`}
                        ref={ref}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleResolveChat(e);
                        }}
                      >
                        {isResolveLoading ? (
                          <>
                            {" "}
                            <Spinner
                              className="my-auto mx-1"
                              animation="border"
                              color="black"
                              size="sm"
                            />
                          </>
                        ) : (
                          <>
                            <span>
                              <i
                                className={`${styles.iconFonts} fa-solid fa-check-double`}
                              ></i>
                            </span>
                          </>
                        )}
                      </div>
                    )}
                  </OverlayTrigger>
                </>
              ) : (
                <>
                  {" "}
                  {!customer?.ticketData && (
                    <div className="me-2">
                      <span className={`${styles.ticketCreatedText}`}>
                        <i
                          className={`${styles.iconFonts} fa-solid fa-check-double`}
                        ></i>{" "}
                        Resolved
                      </span>
                    </div>
                  )}
                </>
              )}
              {/* Resolve and Create Ticket Section  */}
              {!customer?.isResolved &&
                !customer?.ticketData &&
                customer?.chatStatus !== "chat_in_progress_ai" && ( // Condition to check if the user is connected to bot, if user is still connected to bot then hiding create ticket the buttons
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip className={` ${styles.toolTipCustom}`}>
                        Resolve and create ticket
                      </Tooltip>
                    }
                  >
                    {({ ref, ...triggerHandler }) => (
                      <div
                        {...triggerHandler}
                        className={`me-2 ${styles.checkBox2} ${
                          customer.isWhatsappChat ? styles.disabled : ""
                        }`}
                        ref={ref}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (customer.isWhatsappChat) {
                            return;
                          }
                          setResolveAndCreateTicket(true);
                          setSelectedChat(customer);
                          onShow();
                        }}
                        id="resolveNCreate"
                      >
                        <span>
                          {" "}
                          <i
                            className={`${styles.iconFonts} fa-solid fa-check-double`}
                          ></i>
                        </span>
                        <span className="mx-1">
                          <i
                            className={`${styles.iconFonts} fa-solid fa-plus`}
                          ></i>
                        </span>
                        <span>
                          {" "}
                          <i
                            className={`${styles.iconFonts} fa-solid fa-ticket`}
                          ></i>
                        </span>
                      </div>
                    )}
                  </OverlayTrigger>
                )}

              {/* WhatsApp Icon Section */}
              {/* {customer?.isWhatsappChat && (
                <div className={`me-2 ${styles.checkBox2}`}>
                  <span>
                    <i className="fa-brands fa-whatsapp"></i>
                  </span>
                </div>
              )} */}

              {/* Agent Assigned/NotAssigned Section */}
              <div className={` p-1 ${styles.assignedAgents} d-flex ms-auto`}>
                <div
                  className="d-flex"
                  style={{ flexShrink: "0" }}
                >
                  {customer.chatStatus === "chat_in_progress_ai" ? (
                    <span className={`position-relative ${styles.chatImg}`}>
                      <img
                        src={botImg}
                        className={`rounded-circle img-fluid`}
                      />
                    </span>
                  ) : customer?.chatAssignedTo &&
                    customer.chatAssignedTo.length !== 0 ? (
                    renderAssingedAgentInfo(customer?.chatAssignedTo)
                  ) : (
                    <span className={`position-relative ${styles.chatImg}`}>
                      <UserAvatar
                        name={"NA"}
                        size={20}
                        className="img-fluid"
                      />
                    </span>
                  )}
                </div>
                <div>
                  <span className={`ms-1 ${styles.assignedAgentsName}`}>
                    {customer.chatStatus === "chat_in_progress_ai"
                      ? "Handled by Cern Bot"
                      : customer?.chatAssignedTo &&
                          customer.chatAssignedTo.length
                        ? concatinateAgentNames(customer?.chatAssignedTo)
                        : "Agent Not Assigned"}
                  </span>
                </div>
              </div>
            </div>
          )}
        <div>
          {/* Assigned agent info */}
          {/* <div
        className={`btn ${styles.assignedAgents} d-flex`}
      >
        <div className="d-flex" style={{ flexShrink: "0" }}>
          {customer?.chatAssignedTo &&
          customer.chatAssignedTo.length !== 0 ? (
            renderAssingedAgentInfo(customer?.chatAssignedTo)
          ) : (
            <span className={`position-relative ${styles.chatImg}`}>
              <UserAvatar name={"NA"} size={20} className="img-fluid" />
            </span>
          </div>
        </div>
        <span className={`my-auto mx-2 ${styles.assignedAgentsName}`}>
          {customer?.chatAssignedTo &&
          customer.chatAssignedTo.length
            ? concatinateAgentNames(customer?.chatAssignedTo)
            : "Agent Not Assigned"}
        </span>
      </div> */}
        </div>
      </div>
    </div>
  );
};
export default LiveConversation;
