import { axiosJSON } from "src/globals/axiosEndPoints";
import log from "loglevel";
import { IMessageData } from "src/store/slices/innerTicket/innerTicket.slice";

export type Ticket = {
  defaultColumns: any;
  moreColumns: any;
};

export type Tickets = {
  [ticket_id: string]: Ticket;
};

export interface MessageQuery {
  ticketId: number;
  sortByTime: "descending" | "ascending";
  //made start property optional
  start?: number;
  // Added lastMessageId, lastMessageType properties
  lastMessageId?: number | string;
  lastMessageType?: "message" | "chat";
  scrollDirection?: "old" | "new";
  limit: number;
  messageIds?: Array<number>;
  messageAfterId?: number;
  ticketColumns: Array<
    | "ticket_id"
    | "ticket_created_date"
    | "ticket_created_date_gmt"
    | "ticket_subject"
    | "ticket_description"
    | "ticket_brand"
    | "ticket_excerpt"
    | "customer_id"
    | "ticket_customer_name"
    | "ticket_channel"
    | "not_replied_since"
    | "ticket_tags"
    | "ticket_subject"
    | "ticket_status"
    | "ticket_draft_status"
    | "ticket_drafted_by"
    | "ticket_assigned_to"
    | "last_message_date"
    | "last_message_received"
    | "ticket_modified_date"
    | "ticket_integration"
    | "number_of_tickets"
    | "ticket_assignee_team"
    | "ticket_to"
    | "ticket_from"
    | "ticket_bcc"
    | "ticket_cc"
    | "ticket_type"
    | "ticket_created_by"
    | "can_add_new_draft"
    | "merged_tickets" // modified to get merged ticket ids while fetching messages
    | "ticket_attachments" // to get attachments of ticket, attachments added while creating tickets
    | "merged_parent_tickets" //to get the list of ticket id's to which the current ticket is merged into
    | "ticket_schedule_message_count" // to get the number of scheduled messages in a ticket
    | "last_mention_message"
    | "mentioned_by"
    | "unread_mention_count"
    | "unread_mention_message_id"
    | "is_ai_handled"
    | "is_ai_drafted"
    | "ticket_customer_details" // To get the customer details
  >;
  includeCustomerChats?: boolean;
  filters?: {
    limitToUnreadMention?: boolean;
  };
}

export async function getMessagesApi(
  servicePayload: MessageQuery,
  signal?: any,
) {
  const { data } = await axiosJSON.post(
    `/api/message/getMessages`,
    servicePayload,
    { signal },
  );
  if (data.err === true) {
    throw new Error(data.msg as string, { cause: data });
  }
  // log.debug("fetch message raw data:", data);
  const normalizedData = normalizeData(data);
  // log.debug("fetch message normalized data:", normalizedData);
  return normalizedData;
}

function normalizeData({ ticketInfo, ticketMessages, metaData }: any) {
  const messageData: { [key: number | string]: IMessageData } = {};
  const messageList: Array<number | string> = [];

  for (let i = 0; i < ticketMessages.length; i++) {
    const message = ticketMessages[i];
    if (message.messageType === "chat") {
      const mappedId = `chat::${message.chatMeta.chatRequestId}`;
      messageData[mappedId] = message;
      messageList.push(mappedId);
    } else {
      const messageId = parseInt(message.messageId);
      messageData[messageId] = message;
      messageList.push(messageId);
    }
  }
  return { messageData, messageList, metaData, ticketInfo };
}
