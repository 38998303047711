import { useState } from "react";
import DesktopView from "./Children/DesktopView/DesktopView";
import styles from "./EmailPreview.module.scss";
import DesktopViewFollowup from "./Children/DesktopViewFollowup/DesktopViewFollowup";
const EmailPreview = ({
  model,
  companyName,
  data,
  variableList,
}: {
  model: "email" | "followup";
  companyName: string;
  data: any;
  variableList?: any;
}) => {
  const [view, setView] = useState<"Desktop" | "Mobile">("Desktop");

  return (
    <div
      className={`${styles.emailPreviewWrapper} d-flex justify-content-center align-item-center flex-column   mt-4`}
    >
      <div>
        <div
          className={`d-flex flex-md-row align-items-md-center justify-content-center ${styles.btnWrapper}`}
        >
          <button
            className={`mb-2 mb-md-0 ${styles.tabsBtn} ${
              view === "Desktop" && styles.active
            }`}
            onClick={() => setView("Desktop")}
          >
            <span className="pe-1"></span>
            Desktop
          </button>
          <button
            className={`mb-2 mb-md-0 ${styles.mobileTabsBtn} ${
              view === "Mobile" && styles.active
            }`}
            onClick={() => setView("Mobile")}
          >
            <span className="pe-1"></span>
            Mobile
          </button>
        </div>
      </div>
      <div
        className={`py-4 px-4 ${styles.previewSection} ${
          view === "Mobile" && styles.mobilePreviewSection
        }`}
      >
        {model && model === "email" && (
          <DesktopView
            companyName={companyName}
            data={data}
            variableList={variableList}
            view={view}
          />
        )}
        {model && model === "followup" && (
          <DesktopViewFollowup
            companyName={companyName}
            data={data}
            variableList={variableList}
          />
        )}
      </div>
    </div>
  );
};

export default EmailPreview;
