import { axiosJSON } from "src/globals/axiosEndPoints";

/**
 * Interface representing the parameters required to delete a text variable.
 */
export interface DeleteTextVariablesParams {
  /**
   * A key used to identify the set of editor variables.
   */
  editorVariablesKey: string;
  /**
   * The id of the variable to be deleted.
   */
  variableId: string;
}

/**
 * Deletes a text variable from the server.
 *
 * @param params - The parameters required to delete the text variable.
 * @returns `true`.
 * @throws An error if the server response contains an error.
 */
async function deleteTextVariablesService(params: DeleteTextVariablesParams) {
  const { data: res } = await axiosJSON.post(
    "/api/setting/automation/refund/deleteTextVariables",
    params,
  );

  if (res.err || res.error) {
    throw new Error(res.msg ?? res.message ?? "Some Error Occurred.");
  }

  return true;
}

export default deleteTextVariablesService;
