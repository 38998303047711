/**
 * This file is the react component file.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 * @author Navjyot
 */

import ArticleFilters from "src/routes/Report/Children/FilterWrapper/children/ArticleFilters/ArticleFilters";
import AgentFilter from "./children/AgentFilter/AgentFilter";
import AllBrandFilter from "./children/AllBrandFilter/AllBrandFiter";
import AllTags from "./children/AllTags/AllTags";
import ChannelFilterSection from "./children/ChannelFilter/ChannelFilterSection";
import StatusFilterSection from "./children/FilterSection/StatusFilterSection";
import AllCategory from "./children/AllCategory/AllCategory";
import SuggestedBy from "./children/SuggestedBy/SuggestedBy";
import AssignmentFilter from "./children/AssignmentFilter/AssignmentFilter";
import ChatStatusFilter from "./children/ChatStatusFilter/ChatStatusFilter";
import { useAppSelector } from "src/store/store";
import { useMemo } from "react";
import CustomAnswer from "./children/CustomAnswer/CustomAnswer";
import AllCategoryBot from "./children/AllCategoryBot/AllCategoryBot";
import ArticleFiltersBot from "./children/ArticleFiltersBot/ArticleFiltersBot";

// Define the interface for the component's props
export interface FilterWrapperProps {
  isSegement?: boolean;
  scope?:
    | "tickets"
    | "knowledgebase"
    | "liveChat"
    | "revenue"
    | "liveChatAvailability"
    | "bot"; //added new scope for bot reports to hide few filters
}

// React component for rendering filters based on the specified scope
const FilterWrapper = ({
  isSegement,
  scope = "tickets",
}: FilterWrapperProps) => {
  // Fetching disabledFeatures from the Redux store
  const disabledFeatures = useAppSelector(
    (state) => state.globals.currentUserData?.disabledFeatures,
  );

  const disabled = useMemo(() => {
    return (disabledFeatures && disabledFeatures.includes("reports")) ?? false;
  }, [disabledFeatures]);

  return (
    // Container div with flex properties
    <div className={`d-flex flex-wrap align-items-center mt-4`}>
      {/* Conditionally render filters based on the specified scope */}
      {scope === "tickets" ? (
        // Filters for ticket scope
        <>
          <StatusFilterSection
            isSegement={isSegement}
            disabled={disabled}
          />
          <ChannelFilterSection
            isSegement={isSegement}
            disabled={disabled}
          />
          <AgentFilter
            isSegement={isSegement}
            disabled={disabled}
          />
          <AllTags
            isSegement={isSegement}
            disabled={disabled}
          />
          <AllBrandFilter
            isSegement={isSegement}
            disabled={disabled}
          />
        </>
      ) : scope === "liveChat" ? (
        <>
          <ChatStatusFilter
            isSegment={isSegement}
            disabled={disabled}
          />
          <AssignmentFilter
            isSegment={isSegement}
            disabled={disabled}
          />
          <AgentFilter
            isSegement={isSegement}
            disabled={disabled}
          />
        </>
      ) : scope === "liveChatAvailability" ? ( //added new scope for chat availability graph to hide few filters
        <>
          {/* <ChatStatusFilter isSegment={isSegement} /> hided the chat status for availability as it is calculated based on agent not chat */}
          {/* <AssignmentFilter isSegment={isSegement} />  hided the chat status for availability as it is calculated based on agent not chat */}
          <AgentFilter
            isSegement={isSegement}
            disabled={disabled}
          />
        </>
      ) : scope === "revenue" ? (
        <>
          {/* <StatusFilterSection isSegement={isSegement} /> */}
          <ChannelFilterSection
            isSegement={isSegement}
            disabled={disabled}
          />
          <AgentFilter
            isSegement={isSegement}
            disabled={disabled}
          />
          <AllTags
            isSegement={isSegement}
            disabled={disabled}
          />
          <AllBrandFilter
            isSegement={isSegement}
            disabled={disabled}
          />
        </>
      ) : scope === "bot" ? (
        <>
          {/* 20 April 2024 Hiding these filters for now  */}
          {/* <ArticleFiltersBot isSegement={isSegement} /> */}
          {/* <AllCategoryBot isSegement={isSegement} /> */}
          {/* <CustomAnswer /> */}
          <ChatStatusFilter isSegment={isSegement} />
          {/* <SuggestedBy isSegement={isSegement} /> */}
          {/* <StatusFilterSection isSegement={isSegement} /> */}
          {/* <AllBrandFilter isSegement={isSegement} /> */}
        </>
      ) : (
        // Filters for knowledge base article scope
        <>
          <ArticleFilters
            isSegement={isSegement}
            disabled={disabled}
          />
          <AllCategory
            isSegement={isSegement}
            disabled={disabled}
          />
          {/* SuggestedBy filter (commented out for now) */}
          {/* <SuggestedBy isSegement={isSegement} /> */}
        </>
      )}
    </div>
  );
};

export default FilterWrapper;
