import { useCallback, useMemo, useState } from "react";
import { useAutomationContext } from "src/routes/BotSettings/Children/Automation/Hooks/useAutomationContext";
import {
  IBugCategoriesQuestion,
  Question,
  QuestionType,
} from "src/services/Automation/getAutomationById.service";

interface HookProps {
  questionData: IBugCategoriesQuestion;
  stepId: string;
}

const isBugCategoriesQuestion = (
  question: Question,
): question is IBugCategoriesQuestion => {
  return question.questionType === QuestionType.BugCategories;
};

const useCategoryQuestion = ({ stepId, questionData }: HookProps) => {
  const { updateQuestions, saveQuestionsData, formData } =
    useAutomationContext();

  const isQuestionDisabled = useMemo(
    () => questionData.isBrandRequired === true && formData.brand === null,
    [formData.brand, questionData.isBrandRequired],
  );

  // State for URL validation errors
  const [errors, setErrors] = useState<Array<string | null>>([]);

  const addMoreCategories = useCallback(() => {
    if (isBugCategoriesQuestion(questionData)) {
      const newCategories: IBugCategoriesQuestion["questionValue"] = [
        ...questionData.questionValue,
        {
          id: null,
          name: "",
          isDefault: false,
          description: "",
        },
      ];

      updateQuestions({
        ...questionData,
        questionValue: newCategories,
        isAnswered: false,
      });
    }
  }, [questionData, updateQuestions]);

  const deleteCategory = useCallback(
    (index: number) => {
      if (isBugCategoriesQuestion(questionData)) {
        const newCategories = [
          ...questionData.questionValue.slice(0, index),
          ...questionData.questionValue.slice(index + 1),
        ];

        setErrors((prev) =>
          prev.map((err, idx) => (idx === index ? null : err)),
        );

        const isAnswered =
          newCategories.find((cat) => cat.name === "") === undefined;

        if (newCategories.length !== 0 && isAnswered) {
          saveQuestionsData({
            stepId,
            stepQuestions: {
              [questionData.questionKey]: newCategories,
            },
          });
        }

        updateQuestions({
          ...questionData,
          questionValue: newCategories,
          isAnswered,
        });
      }
    },
    [questionData, saveQuestionsData, stepId, updateQuestions],
  );

  const handleDescriptionChange = useCallback(
    (idx: number, description: string) => {
      if (isBugCategoriesQuestion(questionData)) {
        let isAnswered = true;
        const newCategories = questionData.questionValue.map((category, i) => {
          if (category.name === "") {
            isAnswered = false;
          }
          return i === idx ? { ...category, description } : category;
        });

        updateQuestions({
          ...questionData,
          questionValue: newCategories,
          isAnswered: isAnswered,
        });

        if (newCategories.length !== 0 && isAnswered) {
          saveQuestionsData(
            {
              stepId,
              stepQuestions: {
                [questionData.questionKey]: newCategories,
              },
            },
            true,
          );
        }
      }
    },
    [questionData, saveQuestionsData, stepId, updateQuestions],
  );

  const handleNameChange = useCallback(
    (idx: number, name: string) => {
      if (isBugCategoriesQuestion(questionData)) {
        let isAnswered = true;
        let isDuplicate = false;

        const newCategories = questionData.questionValue.map((category, i) => {
          if (i === idx && name.trim() === "") {
            isAnswered = false;
          } else if (i !== idx && category.name === "") {
            isAnswered = false;
          }

          if (i !== idx && name === category.name) {
            isDuplicate = true;
            isAnswered = false;
          }

          return i === idx ? { ...category, name: name } : category;
        });

        // Get the validation message
        const validationMessage =
          name.trim() === ""
            ? "Name cannot be empty"
            : isDuplicate
              ? "This category already exists in database for this brand, please use another category name."
              : null;

        // Update the errors
        const newErrors = [...errors];
        newErrors[idx] = validationMessage; // Set the error message for the current index
        setErrors(newErrors); // Set the new error state

        updateQuestions({
          ...questionData,
          questionValue: newCategories,
          isAnswered,
        });

        if (newCategories.length !== 0 && isAnswered) {
          saveQuestionsData(
            {
              stepId,
              stepQuestions: {
                [questionData.questionKey]: newCategories,
              },
            },
            true,
          );
        }
      }
    },
    [questionData, saveQuestionsData, stepId, updateQuestions, errors],
  );

  return {
    addMoreCategories,
    errors,
    isQuestionDisabled,
    deleteCategory,
    handleDescriptionChange,
    handleNameChange,
  };
};

export default useCategoryQuestion;
