import styles from "./MoveNextModel.module.scss";
import workflowProcess from "src/assets/images/workflowProcess.png";

function MoveNextModel({ onHide }: { onHide: any }) {
  // handle move to next step
  const handleConfigureNextStep = () => {
    onHide();
  };

  return (
    <>
      <div className={`mx-3 mt-4 mb-1 ${styles.sectionWrapper}`}>
        <div className="m-auto  text-center">
          <div className="my-2">
            <img
              src={workflowProcess}
              alt=""
              className={`${styles.logo}`}
            />
          </div>
          <div className="my-1 mt-4">
            <span className={`${styles.NoReturnText} text-center`}>
              You've moved to next step!
            </span>
          </div>
          <div className="my-2">
            <span className={`${styles.enableText} text-center mx-3`}>
              You have successfully moved to next step. <br /> You can now test
              the flow that you have configured up till now.
            </span>
          </div>
          <div className="my-4 d-flex justify-content-center flex-wrap">
            <div>
              <span
                className={`px-2 cursor-pointer mb-2 mb-md-0 ${styles.btnTest}`}
              >
                Test Workflow
              </span>
            </div>
            <div>
              <span
                className={`cursor-pointer ms-4 ${styles.configBtn}`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleConfigureNextStep();
                }}
              >
                Configure next steps
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MoveNextModel;
