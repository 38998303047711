import {
  createAsyncThunk,
  createSlice,
  PayloadAction
} from "@reduxjs/toolkit";
import log from "loglevel";
import { RootState } from "src/store/store";
import { AjaxError } from "src/types/AjaxError"
import TicketService from "src/services/TicketService/TicketService";
import { getBulkSelectedTicketIds, getTicketListFilterQuery } from "src/store/slices/tickets/tickets.selectors"
import { ChangeBulkTicketStatusPayload } from "src/services/TicketService/changeBulkTicketStatus";
import { AllStatusesNormalizedData, TicketStatus } from "src/services/TicketService/getAllStatuses";
import { fetchTickets, resetCachedTickets, setBulkUpdatedTicketCount } from "../tickets/tickets.slice";

type TicketStatusSlice = {
  selectedStatusId: number;
  allTicketStatuses: { [key: string]: TicketStatus };
  allTicketStatusesIds: number[];
  bulkAddStatusState: "idle" | "pending" | "fulfilled" | "rejected";
  fetchAllStatusesState: "idle" | "pending" | "fulfilled" | "rejected";
};

const initialState: TicketStatusSlice = {
  selectedStatusId: -1,
  allTicketStatuses: {},
  allTicketStatusesIds: [],
  bulkAddStatusState: "idle",
  fetchAllStatusesState: "idle",
};

export const bulkAddStatus = createAsyncThunk(
  "ticket/bulkAddStatus",
  async (payload: undefined, { getState, dispatch }: { getState: Function, dispatch: Function }) => {
    try {
      const currentState: RootState = getState();
      const servicePayload: ChangeBulkTicketStatusPayload = {
        ticketIds: getBulkSelectedTicketIds(currentState),
        ticketStatusId: currentState.ticketStatus.selectedStatusId,
      }
      
      //checking if all tickets selected in bulk action
      if(currentState.ticket.bulkActionFilters.selectedAllTickets === true){

        //deleting the ticketIds list from payload 
        delete servicePayload['ticketIds'];

        //setting allTickets flag to true
        servicePayload["allTickets"] = true;

        const ticketsQuery = getTicketListFilterQuery(currentState);
        //adding ticket filters to payload
        servicePayload["filters"] = ticketsQuery['filters'];

        //setting if it is a customer view
        servicePayload["isCustomerView"] = currentState.ticket.currentView === "customer-view";
      }
  
      const bulkAddStatusResponse = await TicketService.changeBulkTicketStatus(servicePayload);
      // if the status is updated, refetch the tickets
      // dispatch(resetCachedTickets());
      // dispatch(fetchTickets());
      if(bulkAddStatusResponse?.metaData?.updatedCount){

        dispatch(setBulkUpdatedTicketCount({count: bulkAddStatusResponse?.metaData?.updatedCount}))
      }else{
        dispatch(setBulkUpdatedTicketCount({count: 0}))

      }
      return bulkAddStatusResponse;
    } catch (error) {
      let ajaxError: AjaxError = {
        message: (error as Error).message
      };
      return ajaxError;
    }
  }
);

export const fetchAllStatuses = createAsyncThunk(
  "ticket/fetchAllStatuses",
  async () => {
    try {
      const { allTicketStatuses, allTicketStatusesIds } = await TicketService.getAllStatuses();
      // log.debug({ allTicketStatuses, allTicketStatusesIds })
      return { allTicketStatuses, allTicketStatusesIds };
    } catch (error) {
      let ajaxError: AjaxError = {
        message: (error as Error).message
      };
      return ajaxError;
    }
  }
);


export const ticketStatusSlice = createSlice({
  name: "ticketStatus",
  initialState,
  reducers: {
    setSelectedStatusId: (state, action: PayloadAction<number>) => {
      const statusId = action.payload;
      state.selectedStatusId = statusId;
    },
    resetSelectedStatusId: (state) => {
      state.selectedStatusId = -1
    },
    resetFetchAllStatuses: (state) => {
      state.fetchAllStatusesState = "idle"
    },
    resetBulkAddStatusState: (state) => {
      state.bulkAddStatusState = "idle"
    },
    resetBulkTicketsStatuses: (state) => {
      state.selectedStatusId = -1
      state.bulkAddStatusState = "idle"
      state.fetchAllStatusesState = "idle"
    },
  },
  extraReducers: (builder) => {
    /* 
    Reducers for bulk action for assigning tickets user
    */
    builder.addCase(bulkAddStatus.fulfilled, (state, { payload }) => {
      // fulfilled adding bulk status
      state.bulkAddStatusState = "fulfilled";
    });
    builder.addCase(bulkAddStatus.rejected, (state) => {
      state.bulkAddStatusState = "rejected";
    });
    builder.addCase(bulkAddStatus.pending, (state) => {
      state.bulkAddStatusState = "pending";
    });

    builder.addCase(fetchAllStatuses.fulfilled, (state, { payload }) => {
      // log.debug("bulk action payload:", payload)
      const { allTicketStatuses, allTicketStatusesIds } = payload as AllStatusesNormalizedData
      state.fetchAllStatusesState = "fulfilled";
      if (allTicketStatuses){
        state.allTicketStatuses = allTicketStatuses
      }
      if (allTicketStatusesIds){
        state.allTicketStatusesIds = allTicketStatusesIds
      }
    });
    builder.addCase(fetchAllStatuses.rejected, (state) => {
      state.fetchAllStatusesState = "rejected";
    });
    builder.addCase(fetchAllStatuses.pending, (state) => {
      state.fetchAllStatusesState = "pending";
    });
  }
});

export const { setSelectedStatusId, resetSelectedStatusId, resetFetchAllStatuses, resetBulkTicketsStatuses
} = ticketStatusSlice.actions;

export default ticketStatusSlice.reducer;
