/**
 * This file is the service file used for making api request.
 * It contains the updateThemeStyles function service which is explained below.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants"; // this is used for testing purpose only for frontend.

interface updateThemeStylesPayload {
  integrationId: number | string;
  themeId: number | string;
  helplamaBranding?: boolean;
  backgroundColor?: string;
  brandColor?: string;
  heading?: string;
  subheading?: string;
  placeholder?: string;
  storeName?: string;
  font?: string;
  language?: string;
  zeroArticle?: string;
  oneArticle?: string;
  articleCount?: number;
  noTitleArticleText?: string;
  customCodeHead?: number[];
  customCodeBody?: number[];
}

interface updateThemeStylesRes {
  err: boolean;
  msg: string;
}

/**
 * For testing frontend with own api link if backend is not prepared.
 */
const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/updateKnowledgeBaseSettings`
  : "/api/chatSetting/updateThemeStyles";

/**
 * This service is used to update the theme styles.
 */
export const updateThemeStyles = async (params: updateThemeStylesPayload) => {
  const { data: res } = await axiosJSON.post(apiEndPoint, params);
  return res.data as updateThemeStylesRes;
};
