/**
 * This file defines a React component representing the main settings for the bot.
 * It includes configurations related to user settings
 *
 * @author @Anubhav-busibud
 * @author @navjyot-busibud
 * @author @yuvaraj-busibud
 * @author @Jayalakshmi-busibud
 */
import styles from "./UserSetting.module.scss";
import ConfigureSteps from "../Children/ConfigureSteps/ConfigureSteps";
import Loader from "src/components/Loader";
import AxiosImg from "src/components/AxiosImg";
import UserAvatar from "src/components/UserAvatar";
import useUserSettings from "./useUserSettings";
import Lottie from "lottie-light-react";
import liveDot from "src/assets/images/liveDot.json";
import BrandDropdown from "src/routes/BotSettings/Children/BrandDropdown/BrandDropdown";

const UserSetting = () => {
  const {
    isFetching,
    handleBackClick,
    selectedBrand,
    updateBrandChange,
    name,
    setName,
    refetch,
    updateBotSettings,
    data,
    setSelectedBrand,
    status,
  } = useUserSettings();

  if (isFetching) {
    return (
      <div style={{ height: "calc(100vh - 140px)" }}>
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div
        className={`p-3 ${styles.settingWrap}`}
        id={`setting_${data?.id}`}
      >
        <div className="d-flex flex-column flex-md-row justify-content-between w-100">
          <div className="w-50">
            <div className="d-flex align-items-center ">
              <div
                className={`${styles.backArrow}`}
                onClick={handleBackClick}
                role="button"
              >
                <span>
                  <i className="fa-solid fa-arrow-left"></i>
                </span>
              </div>
              <input
                type="text"
                className={`ps-3 ${styles.botName}`}
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Untitled cern"
                id={`${data?.id}`}
              />
            </div>
            <span className={`${styles.subHead}`}>
              Only one bot profile will be matched per user.
            </span>
          </div>

          <div className={`${styles.brandDrop}`}>
            {(status !== "" ? status === "draft" : data?.status === "draft") ? (
              <>
                {data && data.brandData === null ? (
                  <BrandDropdown
                    selectedBrand={selectedBrand}
                    setSelectedBrand={setSelectedBrand}
                    updateBrandChange={updateBrandChange}
                  />
                ) : (
                  <p className="mb-0 px-2 d-flex align-items-center">
                    {/* Brand Image */}
                    <div className="px-1">
                      {selectedBrand?.imageUrl &&
                      selectedBrand?.imageUrl.trim() !== "" ? (
                        <AxiosImg
                          url={selectedBrand?.imageUrl}
                          className={`rounded-circle`}
                          style={{ width: "20px", height: "20px" }}
                        />
                      ) : (
                        <UserAvatar
                          name={selectedBrand?.label ?? "NA"}
                          size={20}
                        />
                      )}
                    </div>
                    {/* Brand Name */}
                    <span className={`${styles.liveText}`}>
                      {selectedBrand?.label ?? ""}
                    </span>
                  </p>
                )}
              </>
            ) : (
              <>
                <div className="px-2 d-flex align-items-center">
                  {/* <div className={`${styles.greenDot}`}></div> */}
                  <span
                    style={{
                      height: 20,
                      width: 20,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Lottie animationData={liveDot}></Lottie>
                  </span>
                  <p className="mb-0 px-2 d-flex align-items-center">
                    <span className={`${styles.liveText}`}>Live on</span>
                    <div className="px-1">
                      {selectedBrand?.imageUrl &&
                      selectedBrand?.imageUrl.trim() !== "" ? (
                        <AxiosImg
                          url={selectedBrand?.imageUrl}
                          className={`rounded-circle`}
                          style={{ width: "20px", height: "20px" }}
                        />
                      ) : (
                        <UserAvatar
                          name={selectedBrand?.label ?? "NA"}
                          size={20}
                        />
                      )}
                    </div>

                    <span className={`${styles.liveText}`}>
                      {selectedBrand?.label ?? ""}
                    </span>
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
        <div>
          {data && (
            <ConfigureSteps
              botSettings={data}
              updateBotSettings={updateBotSettings}
              selectedBrand={selectedBrand}
              setSelectedBrand={setSelectedBrand}
              refetchGetBot={refetch}
              updateBrandChange={updateBrandChange}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default UserSetting;
