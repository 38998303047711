import styles from "./ScheduleBox.module.scss";
import send from "src/assets/images/sendClock.png";
import pencil from "src/assets/images/pencilOutline.png";
import wait from "src/assets/images/wait.png";
import UserAvatar from "src/components/UserAvatar";
import QuillEditorContainer from "src/components/QuillEditorContainer";
import { Modal, OverlayTrigger, Popover } from "react-bootstrap";
import ScheduleFollowUp from "./Children/ScheduleFollowUp/ScheduleFollowUp";
import DeleteSchedule from "./Children/ScheduleFollowUp/DeleteSchedule/DeleteSchedule";
import useScheduleBox from "./useScheduleBox";
import { useEffect, useMemo, useState } from "react";
import { ScheduleData } from "src/services/LiveChat/ScheduleFollowups/getAllSchedules";
import AxiosImg from "src/components/AxiosImg";
import { UpdateSchedulePayload } from "src/services/LiveChat/ScheduleFollowups/updateSchedule";
import ParseHTML from "src/components/ParseHTML";
import { useAppSelector } from "src/store/store";
import { v4 as uuid } from "uuid";
import { showAttachment } from "src/services/Attachment/showAttachment";
import InnerChatAttachments from "src/components/InnerChatAttachments/InnerChatAttachments";
import { findAndReplaceCID, trimPDIVBR } from "src/utils/utils";
import TinyMCEContainer from "src/components/TinyMCEContainer";
import {
  OnFileDeleteRes,
  TinyMCEUploadFile,
} from "src/interfaces/TinyMCEContainer/ITinyMCEContainer";

interface Props {
  schedule: ScheduleData;
  updateScheduleData: (payload: UpdateSchedulePayload) => Promise<void>;
  deleteSchedule: (id: number) => Promise<void>;
  scheduleMessageFor: "innerTicket" | "liveChat";
  allSchedules: { [key: string | number]: ScheduleData };
}
const ScheduleBox = ({
  schedule,
  updateScheduleData,
  deleteSchedule,
  scheduleMessageFor,
  allSchedules,
}: Props) => {
  const userTimezone = useAppSelector(
    (state) => state.globals.currentUserData?.userTimezone,
  );
  const [message, setMessage] = useState(schedule.message);
  const [attachments, setAttachments] = useState(schedule.attachments ?? []);
  const [removedAttachmentIds, setRemovedAttachmentIds] = useState<
    Array<number | string>
  >([]);
  const [loader, setLoader] = useState(false);
  const {
    isFileUploading,
    setIsFileUploading,
    selectedFiles,
    setSelectedFiles,
    showModal,
    onShow,
    onHide,
    deleteModal,
    onShowModal,
    onHideMod,
  } = useScheduleBox({ schedule });
  const editPopver = (
    <Popover id="popover-basic">
      <span className={`d-inline-block p-2 ${styles.hoverText}`}>
        Edit Message
      </span>
    </Popover>
  );
  const sendPopver = (
    <Popover id="popover-basic">
      <span className={`d-inline-block p-2 ${styles.hoverText}`}>
        Reschedule Message
      </span>
    </Popover>
  );
  const deletePopver = (
    <Popover id="popover-basic">
      <span className={`d-inline-block p-2 ${styles.hoverText}`}>
        Delete Message
      </span>
    </Popover>
  );

  const [editMsgOpen, setEditMsgOpen] = useState(false);

  function handleEditMessage() {
    setEditMsgOpen(!editMsgOpen);
  }

  function handleUpdate() {
    // if (message === schedule.message) {
    //   return;
    // }
    console.log(removedAttachmentIds);
    let payload: UpdateSchedulePayload = {
      id: schedule.id,
      message:
        scheduleMessageFor === "innerTicket"
          ? trimPDIVBR(findAndReplaceCID(message))
          : message,
      removedAttachmentIds: Array.from(new Set(removedAttachmentIds)),
    };
    setLoader(true);
    if (selectedFiles && selectedFiles.length) {
      payload["attachmentBatchNumber"] = selectedFiles
        ? selectedFiles.map((value: any, index: any) => {
            return (
              value.batchNumber || value.attachmentBatchNumber // value.attachmentBatchNumber is used because, it is the key coming for already uploaded
            );
          })
        : [];
    }
    updateScheduleData(payload)
      .then(() => {
        setLoader(false);
        setEditMsgOpen(false);
      })
      .catch(() => {
        setLoader(false);
      });
  }

  const messageScheduleAt = useMemo(() => {
    const date = new Date(schedule.scheduledToUTC + "Z");
    if (schedule.condition === "none") {
      const localDateStr = date.toLocaleString("en-US", {
        timeZone: userTimezone,
        hour12: true,
        month: "long",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
      });
      const formattedDateStr = localDateStr.replace(/\b(AM|PM)\b/g, (match) =>
        match.toLowerCase(),
      );

      return `Send on ${formattedDateStr}`;
    }

    let conditionText = "";
    if (schedule.condition === "noNewOrderPlacedAfterScheduling") {
      conditionText = "if no new order placed after scheduling";
    } else if (
      schedule.condition === "noNewResponseOrMessageReceivedFromTheCustomer"
    ) {
      conditionText =
        "if no new response or message has been received from the customer";
    }

    const localDateStr = date.toLocaleString("en-US", {
      timeZone: userTimezone,
      hour12: true,
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
    const formattedDateStr = localDateStr.replace(/\b(AM|PM)\b/g, (match) =>
      match.toLowerCase(),
    );

    if (schedule.scheduleFollowUpType === "dateAndTime") {
      return `Send on ${formattedDateStr} ${conditionText}`;
    } else {
      return `Send on ${formattedDateStr} ${conditionText}`;
      // return `Send on ${schedule.scheduleFollowUpValue} ${schedule.scheduleFollowUpType} when ${conditionText}`;
    }
  }, [schedule]);

  return (
    <>
      <div className={`p-3 mb-3 ${styles.scheduleBox}`}>
        <div
          className={`d-flex justify-content-between align-items-start ${styles.userBox}`}
        >
          <div className="d-flex w-md-25">
            <div>
              {schedule.agent.imgUrl && schedule.agent.imgUrl.trim() ? (
                <AxiosImg
                  className={`${styles.profileIcon} rounded-circle`}
                  url={schedule.agent.imgUrl}
                />
              ) : (
                <UserAvatar
                  size={32}
                  name={schedule.agent.name}
                />
              )}
            </div>
            <div className="ms-2">
              <p className={`mb-0 ${styles.name}`}>{schedule.agent.name} </p>
              <span className={`d-block ${styles.email}`}>
                {schedule.agent.email}{" "}
              </span>
            </div>
          </div>
          <div className="w-md-75">
            <span className={`ms-2 ps-3 ${styles.sendText}`}>
              <span className="ps-4">{messageScheduleAt}</span>

              <span className="ms-2 ">
                <img
                  src={wait}
                  alt=""
                />{" "}
              </span>
            </span>
          </div>
        </div>
        <div className="mt-2">
          {editMsgOpen ? (
            <div className="mb-3">
              <TinyMCEContainer
                value={message}
                className={`${styles.quillBox}`}
                onChange={(value: string) => {
                  setMessage(value);
                }}
                disableAttachment={false}
                disableEmbedding={false} //live chat also supports embedded now, so enabled the embedding attachments
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
                isFileUploading={isFileUploading}
                setIsFileUploading={setIsFileUploading}
                uniqueID={uuid()}
                canDeleteInline={false}
                attachmentUploadData={attachments as any}
                areAttachmentsPublic={
                  scheduleMessageFor === "innerTicket" ? false : true
                }
                onFileDelete={(file: TinyMCEUploadFile) => {
                  return new Promise<OnFileDeleteRes>((resolve, reject) => {
                    if (file.attachmentId) {
                      setRemovedAttachmentIds((prev) => [
                        ...prev,
                        file.attachmentId + "",
                      ]);
                    }
                    resolve({ file: file, status: true });
                  });
                }}
              />
            </div>
          ) : (
            <>
              <ParseHTML
                className={`${styles.msgDetails} mt-1`}
                html={
                  schedule.attachments == undefined ||
                  schedule.attachments.length === 0
                    ? schedule.message
                    : showAttachment({
                        html: schedule.message,
                      })
                }
                showReadMoreLess={true}
              />
              {schedule.attachments && schedule.attachments.length !== 0 ? (
                <div className="mt-2">
                  <InnerChatAttachments
                    messageData={{ attachments: schedule.attachments }}
                  />
                </div>
              ) : null}
            </>
          )}
        </div>
        <div
          className={`d-flex justify-content-between ${styles.footerWrapper} mt-2`}
        >
          <div className="d-flex align-items-center">
            <OverlayTrigger
              trigger={["hover", "focus"]}
              placement="top"
              overlay={editPopver}
            >
              <span
                className={`cursor-pointer ${styles.deleteBtn}`}
                onClick={handleEditMessage}
              >
                <img
                  src={pencil}
                  alt=""
                />
              </span>
            </OverlayTrigger>
            <OverlayTrigger
              trigger={["hover", "focus"]}
              placement="top"
              overlay={sendPopver}
            >
              <span
                className={`d-inline-block mx-1 cursor-pointer ${styles.deleteBtn}`}
                onClick={onShow}
              >
                <img
                  src={send}
                  alt=""
                />
              </span>
            </OverlayTrigger>
            <Modal
              className={`${styles.modalClass}`}
              backdropClassName={`${styles.modalBack}`}
              show={showModal}
              onHide={onHide}
              dialogClassName={`${styles.modalDialog}`}
              contentClassName={`${styles.modalContent}`}
              animation={false}
            >
              <ScheduleFollowUp
                schedule={schedule}
                updateScheduleData={updateScheduleData}
                onHide={onHide}
                allSchedules={allSchedules}
              />
            </Modal>
            <OverlayTrigger
              trigger={["hover", "focus"]}
              placement="top"
              overlay={deletePopver}
            >
              <span
                className={`cursor-pointer ${styles.deleteBtn}`}
                onClick={onShowModal}
              >
                <i className="fa-solid fa-trash"></i>
              </span>
            </OverlayTrigger>
            <Modal
              className={`${styles.modalClass}`}
              backdropClassName={`${styles.deleteModalBack}`}
              show={deleteModal}
              onHide={onHideMod}
              dialogClassName={`${styles.deleteModalDialog}`}
              contentClassName={`${styles.deleteModalContent}`}
              animation={false}
            >
              <DeleteSchedule
                id={schedule.id}
                deleteSchedule={deleteSchedule}
                onHide={onHideMod}
              />
            </Modal>
          </div>
          {editMsgOpen ? (
            <div>
              <button
                className={`d-flex align-items-center justify-content-center ${styles.updateBtn}`}
                onClick={handleUpdate}
                disabled={loader}
              >
                {loader ? "Updating..." : "Update"}
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default ScheduleBox;
