import { getChatSettingData } from "src/services/LiveChat/Settings/getChatSettingData";
import { RootState } from "src/store/store";

const fetchChatSettingDataThunk = async (
  payload: number|string,
  { getState, dispatch }: { getState: Function; dispatch: Function }
) => {
  const currentState: RootState = getState();

  const chatSettingData = await getChatSettingData({
    integrationId: payload,
    chatSetting: true,
  });

  return chatSettingData;
};


export default { fetchChatSettingDataThunk};
