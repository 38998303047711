import { axiosJSON } from "src/globals/axiosEndPoints";
import { IGetStep9to15Params } from "../step9/getStep9";

export async function fetchGetStep14(params: IGetStep9to15Params) {
  const { data } = await axiosJSON.get(
    "/api/setting/automation/refund/step/14",
    { params }  //passing the integration id in payload
  );

  if (data.error === true) {
    throw new Error("Some Error Occurred while fetching data");
  }

  return data.data;
}
