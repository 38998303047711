import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchAllRoutingRulesData, resetRoutingRulesData } from "src/store/slices/liveChatSetting/RoutingRules/RoutingRulesSetting.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import RuleTable from "./Children/RuleTable/RuleTable";
import styles from "./SetupRoute.module.scss";
const SetupRoute = () => {

  const { integrationId } = useParams();
  const routeIntegrationId = integrationId ? decodeURIComponent(integrationId) : "";

  const navigate = useNavigate();
  const handleAddNew = () => {
      navigate('/live-chat/settings/'+routeIntegrationId+'/routeRule/new');
  };

  const dispatch = useAppDispatch();
  const {routingRuleData, routingRuleIdList,generalTeam, metaData, fetchRoutingRulesAjaxStatus} = useAppSelector((state) => state.routingRulesSetting);
  const currentState = useRef({
    routingRuleData, 
    routingRuleIdList,
    metaData,
    fetchRoutingRulesAjaxStatus
  });

  const totalRules: any = useRef(metaData.total);
  useEffect(() => {
    totalRules.current = metaData.total;
  }, [metaData.total]);

  useEffect(() => {
    dispatch(resetRoutingRulesData());
    dispatch(fetchAllRoutingRulesData(routeIntegrationId));
  }, []);

  const handleInfiniteScroll = useCallback(() => {
    if (totalRules.current !== null) {
      if (totalRules.current > 0) {
        dispatch(fetchAllRoutingRulesData(5));
      }
    }
  }, [metaData.total]);
  return (
    <>
        <div className={`${styles.setupRoute}`}>
          <h1 className={`${styles.setupHead}`}>Routing Rules</h1>
          <h3 className={`${styles.setRule}`}>Setup Routing Rules</h3>
          <p className={`${styles.setupSub}`}>
            Route customers from specific pages or locations to the right team
            of agents. Rules are run in priority order, from the top of the
            list.
          </p>
          <button className={`${styles.addRuleBtn}`} onClick={handleAddNew}>
            <span>
              <i className="fa-solid fa-plus"></i>
            </span>{" "}
            Add new rule
          </button>
          { 
          routingRuleData && Object.keys(routingRuleData).length !== 0 && (

            <RuleTable routingRuleData={routingRuleData} generalTeam={generalTeam} routingRuleIdList={routingRuleIdList} metaData={metaData} handleInfiniteScroll={handleInfiniteScroll} integrationId={routeIntegrationId}/>
            )
          }
        </div>
      {/* ----Show NewRule component when click on add new rule button---- */}
      {/* {newRule && (
        <>
          <NewRule />
        </>
      )} */}
    </>
  );
};
export default SetupRoute;
