import { useState } from "react";
import styles from "./QuerySection.module.scss";
import orderImg from "src/assets/images/order1.png";
import orderImg2 from "src/assets/images/order2.png";
import {
  IOrder,
  useReturnPreviewState,
} from "../../../../../../hooks/useReturnPreview";
import ButtonWrapper from "../ButtonWrapper/ButtonWrapper";
import WidgetFooter from "../WidgetFooter/WidgetFooter";
import TimeLine from "../Timeline/Timeline";

const QuerySection = () => {
  const [showError, setShowError] = useState(false);
  const { selectedOrder, orders, updateState, ...contextValues } =
    useReturnPreviewState();
  const handleSelectOrder = (order: IOrder) => {
    setShowError(false);
    updateState({
      ...contextValues,
      selectedOrder: {
        ...order,
        activeStep: "selectOrder",
      },
    });
  };
  const handleNext = () => {
    if (!selectedOrder) {
      setShowError(true);
      return;
    }
    updateState({
      ...contextValues,
      selectedOrder: {
        ...selectedOrder,
        activeStep: "selectAction",
      },
    });
  };
  return (
    <div>
      <div className={`${styles.widgetBody}`}>
        <TimeLine activeStep={selectedOrder?.activeStep ?? "selectOrder"} />
        <h4 className={`${styles.queryHead} mt-2`}>
          Which Order Is Your Query Regarding?
        </h4>
        {showError && (
          <span className={`p-1 mb-1 ${styles.errorMsg}`}>
            *Please select a order
          </span>
        )}
        {orders.map((order, index) => {
          return (
            <div
              className={`mb-2 ${styles.orderSection} ${
                selectedOrder?.orderId === order.orderId
                  ? styles.selectedOrder
                  : ""
              } cursor-pointer `}
              onClick={() => {
                handleSelectOrder(order);
              }}
            >
              <div className="d-flex align-items-center justify-content-between w-100 mb-2">
                <div>
                  <h6 className={`mb-0 ${styles.orderHead}`}>
                    Order Id : #6979890
                  </h6>
                  <span className={`d-block ${styles.orderSubHead}`}>
                    {order.itemsInOrderCount} Items ordered on 03/07/2022
                  </span>
                </div>
                <div>
                  <span className={`${styles.sampleOrder}`}>
                    {order.orderName}
                  </span>
                </div>
              </div>
              {order.itemsInOrder.map((item) => {
                return (
                  <div
                    className={`d-flex pt-1 mb-2`}
                    key={`${order.orderId}--${item.id}`}
                  >
                    <div className={`${styles.cart}`}>
                      <img
                        src={item.name == "Acme T-Shirt" ? orderImg : orderImg2}
                        alt="order"
                        className={`${styles.orderImg}`}
                      />
                    </div>
                    <div className={`ms-2`}>
                      <span className={`${styles.orderName}`}>
                        {item.unitPrice.currencyCode} {item.unitPrice.amount} x{" "}
                        {item.quantity}
                      </span>
                      <span className={`${styles.orderDesc}`}>
                        SKU : {item.sku}
                      </span>
                    </div>
                  </div>
                );
              })}
              <div className="d-flex justify-content-end">
                <span className={`${styles.orderTotal}`}>
                  Order Total : <span>{order.orderTotal.currencyCode}</span>
                  <span>{order.orderTotal.amount} </span>
                </span>
              </div>
            </div>
          );
        })}
      </div>
      <ButtonWrapper
        onBackClick={() => {}}
        onNextClick={handleNext}
      />
      <WidgetFooter />
    </div>
  );
};

export default QuerySection;
