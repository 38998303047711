import TicketService from "src/services/TicketService/TicketService";
import log from "loglevel";
import { RootState } from "src/store/store";
import {
  getActiveChannelId,
  getActiveDraftStatusId,
  getActiveTicketStatusId,
  getActiveListIndex,
} from "src/store/slices/ticketSidebar/ticketSidebar.selectors";
import { AjaxError } from "src/types/AjaxError";
import {
  calculateTotalPages,
  pageSlice,
  paginationLogic,
} from "src/services/TicketService/pagination";
import { ETableTypeIndex } from "src/services/TicketService/linkGenerator";
import {
  getClosedDateValue,
  getCreatedDateValue,
  getLastUpdatedDateValue,
  getNotRepliedSinceDateValue,
} from "src/store/slices/ticketFilters/ticketFilters.selectors";
import { getActiveColKeysFromCols } from "src/utils/utils";
import axios from "axios";
import { getAllCachedTicketIds } from "./tickets.selectors";
import { getKeysForColumnIds } from "src/hooks/useTicketViews/ticketViewsConfig";
import { ITicketBtn } from "src/containers/SideBar/children/SubSB/SubSB";

let cont: any = [];

const getCont = () => cont;

interface fetchTicketPayload {
  callback?: () => void;
}
const fetchTicketsThunk = async (
  payload: undefined,
  { getState, rejectWithValue }: { getState: Function; rejectWithValue: any },
) => {
  cont.forEach((c: any) => {
    c.abort();
  });
  const controller = new AbortController();
  cont.push(controller);
  try {
    const currentState: RootState = getState();
    let allColumnList =
      currentState.ticket.currColumnList[currentState.ticket.currentView];
    if (currentState.ticket.cachedTickets[currentState.ticket.currentPage]) {
      return {
        allTickets: currentState.ticket.allTickets,
        allTicketsIds:
          currentState.ticket.cachedTickets[currentState.ticket.currentPage],
        cachedTickets: currentState.ticket.cachedTickets,
        totalTicketsCount: currentState.ticket.totalTicketsCount,
        totalPages: currentState.ticket.totalPages,
        currentPage: currentState.ticket.currentPage, // Added currentPage to the returned object for better state management
      };
    } else {
      let currentPage =
        currentState.ticket.currentPage === 0
          ? 1
          : currentState.ticket.currentPage;
      const activeListIndex: string = getActiveListIndex(currentState);
      const activeChannelId: number[] = getActiveChannelId(currentState);
      const activeTicketStatusId: number[] =
        getActiveTicketStatusId(currentState);
      const activeDraftStatusId: number[] =
        getActiveDraftStatusId(currentState);
      let { startPage, endPage, start, limit } = paginationLogic(
        currentPage,
        currentState.ticket.ticketsPerPageLimit,
        currentState.ticket.totalPages,
      );
      //rest current page if total pages is 0
      if (currentState.ticket.totalPages <= 0) {
        currentPage = 1;
      }
      const createdDateValue = getCreatedDateValue(currentState);
      const closedDateValue = getClosedDateValue(currentState);
      const lastUpdatedValue = getLastUpdatedDateValue(currentState);
      const notRepliedSinceDateValue =
        getNotRepliedSinceDateValue(currentState);
      const filterSearchString = currentState.ticket.filterSearchString;

      const filterOptions = {
        activeChannelId,
        activeDraftStatusId,
        activeTicketStatusId,
        createdDateValue,
        closedDateValue,
        notRepliedSinceDateValue,
        filterSearchString,
        lastUpdatedValue,
        ...currentState.ticketFilter.activeFilters,
      };

      // log.debug(
      //   startPage,
      //   endPage,
      //   start,
      //   limit,
      //   activeChannelId,
      //   activeDraftStatusId,
      //   activeTicketStatusId,
      //   filterOptions
      // );
      let queryData: any = TicketService.prepareGetTicketsQuery({
        start,
        limit,
        allColumnList,
        tableType: activeListIndex,
        filterOptions,
      });
      // If active tab type is ai_handled add respective filter
      if (
        activeListIndex == "ai_handled" &&
        currentState.ticketSideBar.selectedAiBtn !== null
      ) {
        let openId, closedId;
        //Get the ticket status open and close ids
        currentState.ticketSideBar?.ticketBtnData?.forEach(
          (section: ITicketBtn) => {
            section?.ticketBtnList?.forEach((btn) => {
              btn?.ddOuterList?.forEach((dd) => {
                Object.values(dd?.ddList).forEach((status) => {
                  if (status?.name === "Open") {
                    openId = status.id;
                  } else if (status?.name === "Closed") {
                    closedId = status.id;
                  }
                });
              });
            });
          },
        );
        // Get the status id based on ai closed or open button selected
        const value =
          currentState.ticketSideBar.selectedAiBtn == "Open"
            ? openId
            : currentState.ticketSideBar.selectedAiBtn == "Closed"
              ? closedId
              : 0;
        if (value !== 0) {
          // Append ticket_status_id filter if there are already options
          if (queryData.filters.options) {
            queryData.filters.options.push({
              compare: "=",
              key: "ticket_status_id",
              value: value,
            });
          } else {
            // If there is no option add it and add filter
            // console.log("no option")
            queryData.filters.options = [];
            queryData.filters.options.push({
              compare: "=",
              key: "ticket_status_id",
              value: value,
            });
          }
        }
      }
      // log.debug({ queryData });

      // const { tickets, ticketMeta } = await TicketService.getTicketsApi(
      //   queryData
      // );
      let { tickets, ticketMeta } = await TicketService.getTicketsV2(
        queryData,
        true,
        currentState.ticket.currentView,
        controller.signal,
      );
      if (
        ticketMeta.totalCount > 0 &&
        start > 0 &&
        Object.keys(tickets).length === 0
      ) {
        currentPage = 1;
        const paginationData = paginationLogic(
          currentPage,
          currentState.ticket.ticketsPerPageLimit,
          0,
        );
        startPage = paginationData.startPage;
        endPage = paginationData.endPage;
        start = paginationData.start;
        limit = paginationData.limit;
        queryData = TicketService.prepareGetTicketsQuery({
          start,
          limit,
          allColumnList,
          tableType: activeListIndex,
          filterOptions,
        });
        const ticketsData = await TicketService.getTicketsV2(
          queryData,
          true,
          currentState.ticket.currentView,
          controller.signal,
        );
        tickets = ticketsData.tickets;
        ticketMeta = ticketsData.ticketMeta;
      }
      // log.debug({ tickets });
      const totalPages = calculateTotalPages(
        ticketMeta.totalCount,
        currentState.ticket.ticketsPerPageLimit,
      );
      const cachedTickets: any = {};
      let count = 0;
      for (let i = startPage; i <= endPage; i++) {
        cachedTickets[i] = pageSlice(
          ticketMeta.ticketIds,
          currentState.ticket.ticketsPerPageLimit,
          count,
        );
        count++;
      }
      // log.debug({ cachedTickets });
      return {
        allTickets: tickets,
        allTicketsIds: cachedTickets[currentPage],
        cachedTickets,
        totalTicketsCount: ticketMeta.totalCount,
        totalPages,
        currentPage, // Added currentPage to the returned object for better state management
      };
    }
  } catch (error) {
    if (axios.isCancel(error)) {
      return rejectWithValue(true);
    } else {
      let ajaxError: AjaxError = {
        message: (error as Error).message,
      };
      log.warn(ajaxError);
      throw ajaxError;
    }
  } finally {
    cont = getCont().filter((c: any) => c !== controller);
  }
};

const fetchTicketsByIdsThunk = async (
  {
    ticketIds,
    ticketColunmns,
  }: {
    ticketIds: Array<string | number>;
    ticketColunmns: Array<string>;
  },
  { getState }: { getState: Function },
) => {
  const currentState: RootState = getState();
  const activeListIndex: string = getActiveListIndex(currentState);
  const queryData = {
    columns: ticketColunmns,
    filters: {
      tableType:
        activeListIndex === "my"
          ? "my"
          : activeListIndex === "mentions"
            ? "mentions"
            : "all",
    },
    start: 0,
    limit: ticketIds.length,
    ticketIds,
  };
  const { tickets, ticketMeta } = await TicketService.getTicketsV2(
    queryData,
    true,
    currentState.ticket.currentView,
  );
  return tickets;
};

const fetchMoreColThunk = async (
  {
    columnId,
  }: {
    columnId: number;
  },
  {
    getState,
    rejectWithValue,
  }: { getState: Function; rejectWithValue: Function },
) => {
  try {
    const currentState: RootState = getState();
    const ticketIds = Object.keys(currentState.ticket.allTickets);
    return {
      columnId,
      data: await fetchTicketsByIdsThunk(
        {
          ticketIds,
          ticketColunmns: getKeysForColumnIds([columnId]),
        },
        { getState },
      ),
    };
  } catch (e) {
    console.error(e);
    return rejectWithValue({ columnId });
  }
};

export default { fetchTicketsThunk, fetchTicketsByIdsThunk, fetchMoreColThunk };
