import styles from "./AgentTimeSlot.module.scss";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import { useAppSelector } from "src/store/store";
import { useEffect, useState } from "react";
import { ITimeZoneItem } from "src/services/Settings/Timezone/fetchTimeZoneList";
import Select from "react-select";

const AgentTimeSlot = ({
  index,
  availableDayOptions,
  agentAvailableAtData,
  setAgentAvailableAtData,
  updateAvailablityData,
}: any) => {
  const { timezoneListData, fetchTimezoneListAjaxStatus } = useAppSelector(
    (state) => state.profileSettings
  );
  const [enableTimezone, setEnableTimezone] = useState(false);
  const [selectedOption, setSelectedOption] = useState<any>();
  const [selectedTimeValueInNumber, setSelectedTimeValueInNumber] = useState(0);
  const handleAvailableDayChange = (event: any) => {
    setAgentAvailableAtData((state: any) => {
      const list = state.map((item: any, j: number) => {
        if (j === index) {
          return {
            ...item,
            availableDay: event.target.value,
          };
        } else {
          return item;
        }
      });
      return list;
    });
  };

  const handleAvailableAtFromChange = (event: any) => {
    const fromTime: string = event.target.value;
    const timeInNumber = fromTime.split(":")[0];

    setAgentAvailableAtData((state: any) => {
      const list = state.map((item: any, j: number) => {
        if (j === index) {
          const toTime = item.availableAtTo.split(":")[0];

          if (toTime !== undefined) {
            const toTimeInNumber = parseInt(toTime);
            if (
              parseInt(timeInNumber) >= toTimeInNumber &&
              toTimeInNumber !== 0
            ) {
              return {
                ...item,
                availableAtFrom: event.target.value,
                availableAtTo:
                  (parseInt(timeInNumber) == 23
                    ? "00"
                    : parseInt(timeInNumber) + 1
                  ).toString() + ":00",
              };
            }
          }
          return {
            ...item,
            availableAtFrom: event.target.value,
          };
        } else {
          return item;
        }
      });
      return list;
    });
  };

  const handleAvailableAtToChange = (event: any) => {
    setAgentAvailableAtData((state: any) => {
      const list = state.map((item: any, j: number) => {
        if (j === index) {
          return {
            ...item,
            availableAtTo: event.target.value,
          };
        } else {
          return item;
        }
      });
      return list;
    });
  };

  const handleDelete = () => {
    if (index === 0) {
      return false;
    }
    setAgentAvailableAtData((state: any) => {
      const list = state.filter((item: any, j: number) => j !== index);
      return list;
    });
  };

  const handleAvailableAtTimezoneChange = (event: any) => {
    setAgentAvailableAtData((state: any) => {
      const list = state.map((item: any, j: number) => {
        if (j === index) {
          return {
            ...item,
            timezone: event.value,
          };
        } else {
          return item;
        }
      });
      return list;
    });
  };

  const handleSelectedOption = (): any => {
    const selectedOption =
      timezoneListData && timezoneListData.data
        ? timezoneListData.data.filter(function (option) {
            return option.timezone === agentAvailableAtData.timezone;
          })
        : [];

    if (selectedOption.length !== 0) {
      setSelectedOption({
        label: selectedOption[0].label,
        value: selectedOption[0].timezone,
      });
    }
  };

  const customStyles = {
    // control represent the select component
    control: (provided: any) => ({
      ...provided,
      width: "100%",
    }),
  };
  useEffect(() => {
    handleSelectedOption();
  }, [agentAvailableAtData.timezone]);

  useEffect(() => {
    if (agentAvailableAtData != null) {
      const fromTime: string = agentAvailableAtData.availableAtFrom;
      const timeInNumber = fromTime.split(":")[0];

      if (timeInNumber !== undefined) {
        setSelectedTimeValueInNumber(parseInt(timeInNumber));
      }
    }
  }, [agentAvailableAtData]);

  return (
    <>
      <div className={`d-flex flex-wrap align-items-center mt-2`}>
        <div className="me-3 mb-2">
          <Form.Select
            aria-label="Default select example "
            bsPrefix={`mb-2 mb-lg-0 ${styles.selectDay}`}
            value={
              agentAvailableAtData != null
                ? agentAvailableAtData.availableDay
                : ""
            }
            onChange={handleAvailableDayChange}
          >
            {Object.values(availableDayOptions.availableDayOptions).map(
              (option: any) => (
                <option value={option.key}>{option.name}</option>
              )
            )}
          </Form.Select>
        </div>
        <div className="me-3 mb-2">
          <Form.Select
            aria-label="Default select example"
            bsPrefix={`mb-2 mb-lg-0 ${styles.selectDay} ${styles.selectDay2}`}
            value={
              agentAvailableAtData != null
                ? agentAvailableAtData.availableAtFrom
                : ""
            }
            onChange={handleAvailableAtFromChange}
          >
            <option value="1:00">1:00</option>
            <option value="2:00">2:00</option>
            <option value="3:00">3:00</option>
            <option value="4:00">4:00</option>
            <option value="5:00">5:00</option>
            <option value="6:00">6:00</option>
            <option value="7:00">7:00</option>
            <option value="8:00">8:00</option>
            <option value="9:00">9:00</option>
            <option value="10:00">10:00</option>
            <option value="11:00">11:00</option>
            <option value="12:00">12:00</option>
            <option value="13:00">13:00</option>
            <option value="14:00">14:00</option>
            <option value="15:00">15:00</option>
            <option value="16:00">16:00</option>
            <option value="17:00">17:00</option>
            <option value="18:00">18:00</option>
            <option value="19:00">19:00</option>
            <option value="20:00">20:00</option>
            <option value="21:00">21:00</option>
            <option value="22:00">22:00</option>
            <option value="23:00">23:00</option>
            <option value="00:00">00:00</option>
          </Form.Select>
        </div>
        <span className="me-3 mb-2">to</span>
        <div className="me-3 mb-2">
          <Form.Select
            aria-label="Default select example"
            bsPrefix={`mb-2 mb-lg-0 ${styles.selectDay}  ${styles.selectDay2}`}
            value={
              agentAvailableAtData != null
                ? agentAvailableAtData.availableAtTo
                : ""
            }
            onChange={handleAvailableAtToChange}
          >
            <option disabled={selectedTimeValueInNumber >= 1} value="1:00">
              1:00
            </option>
            <option disabled={selectedTimeValueInNumber >= 2} value="2:00">
              2:00
            </option>
            <option disabled={selectedTimeValueInNumber >= 3} value="3:00">
              3:00
            </option>
            <option disabled={selectedTimeValueInNumber >= 4} value="4:00">
              4:00
            </option>
            <option disabled={selectedTimeValueInNumber >= 5} value="5:00">
              5:00
            </option>
            <option disabled={selectedTimeValueInNumber >= 6} value="6:00">
              6:00
            </option>
            <option disabled={selectedTimeValueInNumber >= 7} value="7:00">
              7:00
            </option>
            <option disabled={selectedTimeValueInNumber >= 8} value="8:00">
              8:00
            </option>
            <option disabled={selectedTimeValueInNumber >= 9} value="9:00">
              9:00
            </option>
            <option disabled={selectedTimeValueInNumber >= 10} value="10:00">
              10:00
            </option>
            <option disabled={selectedTimeValueInNumber >= 11} value="11:00">
              11:00
            </option>
            <option disabled={selectedTimeValueInNumber >= 12} value="12:00">
              12:00
            </option>
            <option disabled={selectedTimeValueInNumber >= 13} value="13:00">
              13:00
            </option>
            <option disabled={selectedTimeValueInNumber >= 14} value="14:00">
              14:00
            </option>
            <option disabled={selectedTimeValueInNumber >= 15} value="15:00">
              15:00
            </option>
            <option disabled={selectedTimeValueInNumber >= 16} value="16:00">
              16:00
            </option>
            <option disabled={selectedTimeValueInNumber >= 17} value="17:00">
              17:00
            </option>
            <option disabled={selectedTimeValueInNumber >= 18} value="18:00">
              18:00
            </option>
            <option disabled={selectedTimeValueInNumber >= 19} value="19:00">
              19:00
            </option>
            <option disabled={selectedTimeValueInNumber >= 20} value="20:00">
              20:00
            </option>
            <option disabled={selectedTimeValueInNumber >= 21} value="21:00">
              21:00
            </option>
            <option disabled={selectedTimeValueInNumber >= 22} value="22:00">
              22:00
            </option>
            <option disabled={selectedTimeValueInNumber >= 23} value="23:00">
              23:00
            </option>
            <option disabled={selectedTimeValueInNumber >= 24} value="00:00">
              00:00
            </option>
          </Form.Select>
        </div>
        <div className="me-3 mb-2">
          <Select
            className={`mb-2 mx-0 px-0 mb-lg-0 ${styles.selectDay}`}
            styles={customStyles}
            options={
              timezoneListData && timezoneListData.data
                ? timezoneListData.data.map((timeZoneItem: ITimeZoneItem) => {
                    return {
                      label: timeZoneItem.label,
                      value: timeZoneItem.timezone,
                    };
                  })
                : []
            }
            placeholder="Search your timezone"
            isLoading={fetchTimezoneListAjaxStatus === "pending"}
            value={selectedOption}
            onChange={handleAvailableAtTimezoneChange}
            isSearchable={true}
            isDisabled={!enableTimezone}
          />
        </div>
        {index !== 0 ? (
          <div className="mt-2">
            <span
              className={`ps-1 ms-2 ${styles.trash}`}
              onClick={handleDelete}
            >
              <i className="fa-solid fa-trash"></i>
            </span>
          </div>
        ) : null}
      </div>
      <span
        className={`${styles.editTime}`}
        onClick={(e) => setEnableTimezone(true)}
      >
        Edit timezone
      </span>
    </>
  );
};
export default AgentTimeSlot;
