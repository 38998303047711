import { axiosJSON } from "src/globals/axiosEndPoints";

export interface IAddVariable {
  name: string;
  value: string;
}

export async function addVariable(payload: IAddVariable) {
  const { data } = await axiosJSON.post(
    "/api/setting/automation/refund/variable/add",
    payload
  );

  if (data.err === true) {
    throw new Error(
      data.message ?? "Some Error Occurred while adding Variable"
    );
  }

  return data;
}
