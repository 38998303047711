import { axiosJSON } from "src/globals/axiosEndPoints";

export interface MarkOrderAsPaid {
  data: {
    orderId: number;
    paymentStatus: string;
  };
  error: boolean;
  message: string;
}

export interface MarkOrderAsPaidParams {
  orderId: number;
}

export async function markOrderAsPaid(params: MarkOrderAsPaidParams) {
  const { data: res }: any = await axiosJSON.post(
    `/api/shopify/order/markAsPaid`,
    params
  );

  if (res.error === true) {
    throw new Error(res.message as string);
  }

  return res.data as MarkOrderAsPaid;
}
