import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import getCustomerTicketsHistory, {
  CustomerTicketsHistory,
} from "src/services/LiveChat/liveChatHistory/getCustomerTicketsHistory";
import { Customer } from "src/services/LiveChat/messageExchange/getAllCustomers";
import {
  changeCustomer,
  ChangeCustomerProps,
  ISendMessageService,
  sendMessageService,
} from "src/services/SendMessageService";
import {
  getAllStatuses,
  TicketStatus,
} from "src/services/TicketService/getAllStatuses";
import { THModalViewType } from "../children/ResponseModal/ResponseModal";
import {
  AJAXSTATUS,
  FreePlanError,
  FreePlanErrorMessage,
} from "src/globals/constants";
import { IntegrationFailureMetaData } from "src/types/IntegrationFailure";
import { useAppSelector } from "src/store/store";
import { ReplyOption } from "src/routes/Ticket/children/MainChat/children/SendMessage/children/SendingAsV2";

function useSendMessage({
  customerId,
  fetchLastMessages,
}: {
  customerId: string;
  fetchLastMessages: (callback?: () => void) => void;
}) {
  const [sendType, setSendType] = useState(1);
  const [sendStatus, setSendStatus] = useState("idle" as AJAXSTATUS);
  const [showModal, setShowModal] = useState("" as THModalViewType);
  const [showDropDown, setShowDropDown] = useState(false);
  const [replyOption, setReplyOption] = useState<ReplyOption>(
    ReplyOption.Reply,
  );

  const payloadObj = useRef({ data: null } as {
    data: null | ISendMessageService;
    callback?: () => void;
  });
  const [ticketStatusList, setTicketStatusList] = useState(
    {} as {
      [key: string]: TicketStatus;
    },
  );
  const [selectedTicketId, setSelectedTicketId] = useState(
    null as null | string | number,
  );
  const [ticketsData, setTicketsData] = useState({
    tickets: {},
    ticketIds: [],
    metaData: {
      currentCount: 0,
      totalCount: 0,
    },
  } as CustomerTicketsHistory);

  const hasMoreTickets = useMemo(() => {
    return ticketsData.ticketIds.length < ticketsData.metaData.totalCount;
  }, [ticketsData]);

  const selectedTicket = useMemo(() => {
    if (selectedTicketId != null) {
      return { ...(ticketsData.tickets[selectedTicketId] ?? {}) };
    } else {
      return null;
    }
  }, [selectedTicketId, ticketsData]);

  const handleTicketChange = useCallback(
    (e: any) => {
      const ticketId = e.currentTarget.getAttribute("data-value");
      setSelectedTicketId(ticketId);
    },
    [ticketsData, setSelectedTicketId],
  );

  const { brands } = useAppSelector(
    (state) => state.globals.brandSignatureData,
  );

  const integrationFailure: IntegrationFailureMetaData | null = useMemo(() => {
    if (brands) {
      // Get the selected brand
      const brandData = brands.find(
        (brand) => brand.email === selectedTicket?.ticket_from,
      );

      // Check if we have brand data
      if (brandData) {
        // Check if it is an email integration error
        if (!brandData.isEmailActive) {
          // Return failure metadata response for email integration

          return {
            type: "email",
            integrationId: brandData.emailIntegrationId,
            brand: {
              id: brandData.id,
              email: brandData.email,
              name: brandData.name,
            },
          } as IntegrationFailureMetaData;

          // Check if we have brand integration error
        } else if (!brandData.isActive || !brandData.isVerified) {
          // Return failure metadata response for brand integration

          return {
            type: "brand",
            integrationId: brandData.emailIntegrationId,
            brand: {
              id: brandData.id,
              email: brandData.email,
              name: brandData.name,
            },
          } as IntegrationFailureMetaData;
        }
      }
    }

    return null;
  }, [brands, selectedTicket]);

  const sendMessages = useCallback(() => {
    if (payloadObj.current.data) {
      setShowModal("Pending");
      setSendStatus("pending");
      let payload: any = {
        ...payloadObj.current.data,
      };
      delete payload.attachments;
      sendMessageService(payload)
        .then((res) => {
          fetchLastMessages(() => {
            setShowModal("PostSend");
            setSendStatus("fulfilled");
          });
        })
        .catch((err: any) => {
          // Check if the error message indicates that the attachment size limit was exceeded
          if (
            typeof err == "object" &&
            err.statusCode === "attachmentSizeLimitExceeded"
          ) {
            pushTheToast({
              text: err.message,
              type: "danger",
              position: "top-right",
            });
          } else if (
            typeof err == "object" &&
            (err.statusCode === FreePlanError.LIMIT_REACHED ||
              err.statusCode === FreePlanError.RESTRICTED)
          ) {
            const statusCode =
              err.statusCode as keyof typeof FreePlanErrorMessage;
            // If the free plan limit is exceeded, show limit reached error
            pushTheToast({
              text: err.message ?? FreePlanErrorMessage[statusCode],
              type: "danger",
              position: "top-right",
            });
          } else if (err.message && err.statusCode) {
            pushTheToast({
              text: err.message,
              type: "danger",
              position: "top-right",
            });
          } else {
            // Display a toast message for the general unable to send message scenario
            pushTheToast({
              text: "Unable to send message!",
              type: "danger",
              position: "top-right",
            });
          }
          setShowModal("Error");
          setSendStatus("rejected");
        });
      if (payloadObj.current.callback) {
        payloadObj.current.callback();
      }
    }
  }, [fetchLastMessages]);

  const preSendMessage = useCallback(
    (payload: ISendMessageService, callback?: () => void) => {
      payloadObj.current.data = payload;
      payloadObj.current.callback = callback;
      setShowModal("PreSend");
    },
    [],
  );

  const changeTheCustomer = useCallback(
    (payload: ChangeCustomerProps) => {
      if (selectedTicketId) {
        changeCustomer(payload)
          .then((res) => {
            setTicketsData((prev) => {
              const data = { ...prev };
              if (payload.customerEmail) {
                data.tickets[selectedTicketId].ticket_to =
                  payload.customerEmail;
              }
              if (payload.from) {
                data.tickets[selectedTicketId].ticket_from = payload.from;
              }
              if (payload.cc) {
                const cc = payload.cc.join(",");
                if (cc) {
                  data.tickets[selectedTicketId].ticket_cc = cc;
                } else {
                  data.tickets[selectedTicketId].ticket_cc = null;
                }
              }
              return data;
            });
          })
          .catch((err) => {
            pushTheToast({
              text: "Unable to change customer!",
              type: "danger",
              position: "top-right",
            });
          });
      }
    },
    [selectedTicketId],
  );

  const fetchNextTickets = useCallback(() => {
    getCustomerTicketsHistory({
      start: ticketsData.ticketIds.length,
      limit: 10,
      customerId: customerId,
    })
      .then((res) => {
        setTicketsData((prev) => {
          const data = { ...prev };
          data.metaData = res.metaData;
          data.tickets = { ...data.tickets, ...res.tickets };
          data.ticketIds = [...data.ticketIds, ...res.ticketIds];
          return data;
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }, [customerId, ticketsData]);

  useEffect(() => {
    getAllStatuses()
      .then((res) => {
        setTicketStatusList(res.allTicketStatuses);
      })
      .catch((err) => {
        console.error(err);
      });
    getCustomerTicketsHistory({
      start: 0,
      limit: 10,
      customerId: customerId,
    })
      .then((res) => {
        setTicketsData(res);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return {
    ticketStatusList,
    selectedTicketId,
    setSelectedTicketId,
    ticketsData,
    selectedTicket,
    handleTicketChange,
    sendMessages,
    changeTheCustomer,
    hasMoreTickets,
    fetchNextTickets,
    showModal,
    setShowModal,
    preSendMessage,
    showDropDown,
    setShowDropDown,
    sendType,
    setSendType,
    sendStatus,
    integrationFailureData: integrationFailure,
    replyOption,
    setReplyOption,
  };
}

export default useSendMessage;
