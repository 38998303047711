import { useNavigate } from "react-router-dom";
import styles from "./CategoryNight.module.scss";

interface Props {
  category: any;
  helpCenterPageUri: string;
  font: string;
}
const CategoryNight = ({ category, helpCenterPageUri, font }: Props) => {
  const navigate = useNavigate();
  return (
    <div
      className={`mb-1 d-flex align-items-center ${styles.categoryWrapper}`}
      onClick={() => {
        navigate(
          `/helpcenterpage/${helpCenterPageUri}/category/${category.categoryId}`
        );
      }}
      role="button"
    >
      <h3
        className={`mb-0 ${styles.heading}`}
        style={{
          fontFamily: font ?? "Roboto", // font family
        }}
      >
        {category.categoryName}
      </h3>
    </div>
  );
};

export default CategoryNight;
