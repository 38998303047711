import { useState } from "react";
import { Button } from "react-bootstrap";
import AxiosImg from "src/components/AxiosImg";
import InnerChatAttachments from "src/components/InnerChatAttachments/InnerChatAttachments";
import ParseHTML from "src/components/ParseHTML";
import UserAvatar from "src/components/UserAvatar";
import {
  addEditRemarkData,
  deleteDraftRemarkData,
  IDraftMsgRemark,
} from "src/store/slices/draftFlow/draftFlow.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import ShowTime from "../../../ShowTime";
import styles from "./RemarkComp.module.scss";
import { v4 as uuid } from "uuid";
import { findAndReplaceCID } from "src/utils/utils";
import { showAttachment } from "src/services/Attachment/showAttachment";
import TinyMCEContainer from "src/components/TinyMCEContainer";

interface Props extends IDraftMsgRemark {
  msgId: number;
}

function RemarkComp({ msgId, ...props }: Props) {
  const [isEditing, setIsEditing] = useState(false);
  const [edited, setEdited] = useState(props.remarkContent);
  const [selectedFilesEditRemark, setSelectedFilesEditRemark] = useState<any>(
    [],
  );
  const [isFileUploadingEditRemark, setIsFileUploadingEditRemark] =
    useState<boolean>(false);
  const dispatch = useAppDispatch();
  const scopes = useAppSelector(
    (state) => state.globals.currentUserData?.scopes,
  );

  return (
    <div>
      <div className={`d-flex justify-content-between mt-2`}>
        <div className="d-flex align-items-center">
          <div>
            {props.agentImgUrl ? (
              <AxiosImg
                url={props.agentImgUrl}
                className={`${styles.profileImg}`}
                isPublicAttachmentUrl={props.isPublicAttachmentUrl}
              />
            ) : (
              <UserAvatar
                name={props.agentName}
                size={25}
                className={`${styles.profileImg}`}
              />
            )}
            <span className={`${styles.msgSectionSub}`}>
              {props.agentName.split(" ")[0]} added remarks for this draft
            </span>
          </div>
          <div>
            <ShowTime
              className={``}
              dateTime={new Date(props.remarkDate)}
            />
          </div>
        </div>
        <div className="d-flex ">
          {scopes && scopes.includes("add_draft_remark") && (
            <>
              <span
                className={`me-2 ${styles.draft}`}
                onClick={(e) => {
                  setSelectedFilesEditRemark(props.attachments || []);
                  setIsEditing(true);
                }}
              >
                <i className="fa-solid fa-pen"></i>
              </span>
              <span
                className={`${styles.delete}`}
                onClick={(e) => {
                  dispatch(
                    deleteDraftRemarkData({
                      msgId,
                      remarkId: parseInt(props.remarkId + ""),
                    }),
                  );
                }}
              >
                <i className="fa-solid fa-trash"></i>
              </span>
            </>
          )}
        </div>
      </div>
      {isEditing === false ? (
        <div className={`${styles.remark}`}>
          <ParseHTML
            html={props.remarkContent}
            className={`${styles.remark} p-3 mt-2`}
          />
          <InnerChatAttachments messageData={props} />
        </div>
      ) : (
        <TinyMCEContainer
          value={edited}
          onChange={(value) => {
            setEdited(value);
          }}
          editorClassName={`${styles.remark} p-1 mt-2`}
          options={{ placeholder: "Type here..." }}
          disableAttachment={false}
          attachmentUploadData={props.attachments}
          selectedFiles={selectedFilesEditRemark}
          setSelectedFiles={setSelectedFilesEditRemark}
          isFileUploading={isFileUploadingEditRemark}
          setIsFileUploading={setIsFileUploadingEditRemark}
          uniqueID={uuid()}
          canDeleteInline={false}
          buttons={
            <>
              <Button
                className={`d-flex my-auto mx-2 cancelBtn`}
                onClick={(e) => {
                  setSelectedFilesEditRemark([]);
                  setEdited(props.remarkContent);
                  setIsEditing(false);
                }}
              >
                <span className={`my-auto`}>Cancel</span>
              </Button>
              <button
                className={`btn d-flex my-auto mx-0 saveBtn`}
                disabled={isFileUploadingEditRemark === true}
                onClick={(e) => {
                  dispatch(
                    addEditRemarkData({
                      remarkId: parseInt(props.remarkId + ""),
                      remark: findAndReplaceCID(edited),
                      attachmentBatchNumber: selectedFilesEditRemark
                        ? selectedFilesEditRemark.map(function (
                            value: any,
                            index: any,
                          ) {
                            return (
                              value.batchNumber || value.attachmentBatchNumber
                            ); // value.attachmentBatchNumber is used because, it is the key coming for already uploaded
                          })
                        : [],
                      callback: () => {
                        setSelectedFilesEditRemark([]);
                        setIsEditing(false);
                      },
                    }),
                  );
                }}
              >
                <span className={`my-auto`}>Save</span>
              </button>
            </>
          }
        />
      )}
    </div>
  );
}

export default RemarkComp;
