import { IArticleSetting } from "./articleSetting.slice";


export const fetchArticleSettingFulfilled = (
  state: IArticleSetting,
  { payload }: any
) => {
  state.articleData = payload;
  state.articleSettingAjaxStatus = "fulfilled";
  console.log("fetchArticleSetting ::: ", payload);
}

export default { fetchArticleSettingFulfilled };