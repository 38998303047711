import { useState } from "react";
import styles from "./ProductDetail.module.scss";
import orderImg from "src/assets/images/order1.png";
import dummy from "src/assets/images/orderImg.png";
import { MiniProductVarient } from "../SearchProducts/SearchProducts";
const img1 = "src/assets/images/order1.png";
const img2 = "src/assets/images/order2.png";

const ProductDetail = ({ product }: { product: MiniProductVarient | null }) => {
  const [selectedSize, setSelectedSize] = useState<string | null>("M");
  const [count, setCount] = useState(1);
  const sizes = ["XS", "S", "M", "L", "XL", "XXL"];

  const handleSizeClick = (size: string) => {
    setSelectedSize(size);
  };

  return (
    <div>
      <div>
        <div className={`mb-2 ${styles.searchBox}`}>
          <div className={`d-flex`}>
            <div className={`${styles.cart}`}>
              <img
                src={product?.imgUrl ?? orderImg}
                alt="order"
                className={`${styles.orderImg}`}
              />
            </div>
            <div className={`ms-2 w-100`}>
              <div className="d-flex justify-content-between align-items-lg-end">
                <div>
                  <span className={`${styles.orderName}`}>
                    {product?.name ?? ""}
                  </span>
                  <span className={`${styles.orderDesc}`}>
                    SKU : {product?.sku}
                  </span>
                  <span className={`${styles.orderValue}`}>
                    {product?.price?.currencyCode} {product?.price?.amount}
                  </span>
                </div>
                <div>
                  <span className={`${styles.details}`}>See Full Details</span>
                </div>
              </div>
            </div>
          </div>
          <div className="position-relative mb-2">
            <img
              src={product?.imgUrl ? product?.imgUrl : dummy}
              alt=""
              className="w-100"
              height={250}
            />
            <button className={`${styles.slideBtn} ${styles.previous}`}>
              <span>
                <i className="fa-solid fa-angle-left"></i>
              </span>
            </button>
            <button className={`${styles.slideBtn} ${styles.next}`}>
              <span>
                <i className="fa-solid fa-angle-right"></i>
              </span>
            </button>
          </div>
          <p className={`mb-2 ${styles.selectSize}`}>Select size</p>
          <div className="d-flex flex-wrap pe-4">
            {sizes.map((size) => (
              <span
                key={size}
                className={`me-1 mb-1 ${styles.sizeBox} cursor-pointer ${
                  selectedSize === size ? styles.selected : ""
                }`}
                onClick={() => handleSizeClick(size)}
              >
                {size}
              </span>
            ))}
          </div>
          <div className="mt-3">
            <p className={`mb-2 ${styles.selectSize}`}>Select Quantity:</p>
            <div className="d-flex align-items-center">
              <button
                className={`${styles.decreBtn}`}
                disabled={count <= 1}
                onClick={() => setCount((prev) => prev - 1)}
              >
                <span>
                  {" "}
                  <i className="fa-solid fa-minus"></i>
                </span>
              </button>
              <input
                type="text"
                name=""
                id=""
                className={`mx-1 ${styles.inputBox}`}
                value={count}
                disabled
              />
              <button
                className={`${styles.decreBtn}`}
                // disabled
                onClick={() => setCount((prev) => prev + 1)}
              >
                <span>
                  <i className="fa-solid fa-plus"></i>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
