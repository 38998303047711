import { axiosFormData } from "src/globals/axiosEndPoints";

export interface IAttachment {
  attachmentId: number;
  attachmentName: string;
  batchNumber: string;
  status: boolean;
  contentId: null | string;
  publicURL?: null | string;
  displableError?: string; //if any error occured in server, this value will be used to show error on frontend
}
export interface UploadAttachmentRes {
  attachments: Array<IAttachment>;
}

export interface UploadAttachmentParam {
  // files: Array<File>,
  files: Array<File>;
  dropped?: boolean;
  // batchNumber: string
  abortControllerSignal?: any;
}

// interface UploadAttachment{
//   fData: FormData,
//   batchNumber: string
// }

export async function uploadAttachment(
  // files: Array<File>
  uploadParams: UploadAttachmentParam,
  onUploadProgress: (progressEvent: any) => void,
  isPublic = false,
) {
  // // console.log("Upload attachments:: Params:: ", JSON.stringify(uploadParams));
  // let uParams:UploadAttachment = {
  //   fData: new FormData(),
  //   batchNumber: ""
  // }

  // uParams.batchNumber = uploadParams.batchNumber;
  // if(uParams.batchNumber?.length === 0){
  //   delete uParams.batchNumber;
  // }

  const fData = new FormData();
  fData.append("attachment[]", uploadParams.files[0]);
  if (uploadParams.dropped) {
    fData.append("dropped", "true");
  }
  /*for(let i=0; i<uploadParams.files.length; i++)
  {
    fData.append("attachment[]", uploadParams.files[i]);
  }*/

  // check there is a batch number or not
  /*if(uploadParams.batchNumber.length !==0 ){
    fData.append("batchNumber", uploadParams.batchNumber)
  }*/
  const { data: res } = await axiosFormData.post(
    `/api/attachment/${isPublic ? "public/upload" : "add"}`,
    fData,
    {
      signal: uploadParams.abortControllerSignal,
      onUploadProgress: onUploadProgress,
    },
  );

  // // console.log("Uploaded attachments:: Res:: ", JSON.stringify(res));
  if (res === false) {
    throw new Error(res.message as string);
  }

  let ret: UploadAttachmentRes = {
    attachments: [],
  };

  ret.attachments = res;

  return ret;
}
