import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";

export interface IFeatureLimitVariable {
  key: string;
  value: string;
  color: string;
}

export interface IFeatureLimitItem {
  type: "tag" | "additional_cost_per_unit";
  value: string;
  color: string;
  // backgroundColor: string;
  // valueColor: string;
  variables?: Array<IFeatureLimitVariable>;
  description?: string; //"--value-- per additional useful conversation";
}

export interface IplanExtra {
  icon: string; //"fa-solid fa-gift",
  text: string; //"Get 2 months extra",
  color: string; //"#CDBFCB"
}

export interface IFeatureItem {
  enabled: boolean;
  displayName: string;
  limits?: Array<IFeatureLimitItem>;
}

export interface IPaymentPlan {
  shortName: string; //FREE | PRO | SUPER
  price: string;
  planId: string;
  features: Array<IFeatureItem>;
  extras?: Array<IplanExtra>;
  isContactSales?: boolean;
}

export interface IPlanTypes {
  displayName: string; //Billed Monthly | Billed Anually
  type: "monthly" | "yearly";
  disabled?: boolean; //will be used to disable user from selecting this plan
  plans: Array<IPaymentPlan>;
  planDetails: {
    freeSeats: number;
    upgradeCost: {
      currency: string;
      price: number;
    };
    addtionalSeatsCost: {
      currency: string;
      price: number;
    };
  };
}

const url = IS_TEST_ACTIVE
  ? "http://localhost:30311/getPaymentPlans"
  : `/api/getPaymentPlans`;

const getPaymentPlansService = async () => {
  const { data: res } = await axiosJSON.get(url);

  if (res.error || res.err) {
    throw new Error(res.msg ?? res.message ?? "Something went wrong!");
  }

  return res.data as Array<IPlanTypes>;
};

export default getPaymentPlansService;
