import { LiveChatIntegrationsData } from "./LiveChatIntegrations.slice";

export const fetchAllLiveChatIntegrationsFulfilled = (
  state: LiveChatIntegrationsData,
  { payload }: any
) => {
  const {
    liveChatIntegrationsData,
    liveChatIntegrationsIdList,
    count,
    totalCount,
  }: any = payload as any;

  state.liveChatIntegrationsData = {
    ...state.liveChatIntegrationsData,
    ...liveChatIntegrationsData
  };
  console.log("fetchAllLiveChatIntegrationsFulfilled ::: integrationsData:: ", liveChatIntegrationsIdList);
  state.liveChatIntegrationsIdList = [
    ...state.liveChatIntegrationsIdList,
    ...liveChatIntegrationsIdList
  ]
  state.metaData.count = count;
  state.metaData.total = totalCount
  state.fetchLiveChatIntegrationsAjaxStatus = "fulfilled";

}

export default { fetchAllLiveChatIntegrationsFulfilled };