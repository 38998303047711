import styles from "./PlanDetails.module.scss";
import { useBillingContext } from "../../BillingContext";
import { useAppSelector } from "src/store/store";
import { useCallback, useState } from "react";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import processDueAmountService from "src/services/Billing/processDueAmount.service";
import { Spinner } from "react-bootstrap";
import { BillingStatus } from "../SuccessModal/SuccessModal";

/**
 * Component is used for rendering Plan Details
 */
const PlanDetails = ({
  setShowSuccessModal,
}: {
  setShowSuccessModal: (status: BillingStatus | null) => void;
}) => {
  const { billingDetails } = useBillingContext();
  const [loader, setLoader] = useState(false);

  const userTimezone = useAppSelector(
    (state) => state.globals.currentUserData?.userTimezone,
  );

  /**
   * Callback Handler for calling pay amount service
   */
  const payAmountHandler = useCallback(async () => {
    try {
      setLoader(true);
      const res = await processDueAmountService();
      if (res) {
        // Show success modal
        setShowSuccessModal(res);
      }
    } catch (e) {
      pushTheToast({
        position: "top-right",
        text: "Cannot process payment",
        type: "danger",
      });
    } finally {
      setLoader(false);
    }
  }, [setShowSuccessModal]);

  return (
    <div
      className={`mt-3 ${styles.planWrapper} d-flex flex-column flex-md-row justify-content-between`}
    >
      {/* Left-Side */}
      <div
        className={`d-flex flex-column align-items-start justify-content-between`}
      >
        {/* Active Plan Name */}
        <div className="d-flex">
          <div
            className={`mb-2 ${styles.planBox}`}
            style={{
              backgroundColor: billingDetails?.activePlan.color.background,
              color: billingDetails?.activePlan.color.text,
            }}
          >
            {billingDetails?.activePlan.displayName}
          </div>
          {billingDetails?.activePlan.isBillingComplete === false && (
            <div className={`mb-2 mx-2 ${styles.planBox} ${styles.amountDue}`}>
              Amount Due
            </div>
          )}
        </div>

        {/* Heading */}
        <h5 className={`${styles.subscription}`}>Subscription and billing</h5>

        {/* Active Plan Info */}
        <p className={`mb-0 ${styles.activePlan}`}>
          You are currently active on
          <span className={`${styles.planText}`}>
            {" "}
            {billingDetails?.activePlan.displayName}
          </span>
        </p>

        {/* Offer Details */}
        <div>
          {billingDetails?.activePlan.appliedOffer && (
            <p className={`mb-0 ${styles.offerPara}`}>
              % Promo code{" "}
              <span>{billingDetails?.activePlan.appliedOffer?.name}</span>{" "}
              applied :
              <span>
                {" -"}
                {billingDetails?.activePlan.appliedOffer?.price}
              </span>
            </p>
          )}

          {/* Active Plan Cycle Details */}
          {billingDetails?.activePlan.planCycle && (
            <span className={`${styles.cycle}`}>
              <span>Current cycle: </span>

              {new Date(
                billingDetails?.activePlan.planCycle.startDateGMT + "Z",
              ).toLocaleDateString("en-IN", {
                day: "numeric",
                month: "short",
                year: "numeric",
                timeZone: userTimezone,
              })}
              {" - "}
              {new Date(
                billingDetails?.activePlan.planCycle.endDateGMT + "Z",
              ).toLocaleDateString("en-IN", {
                day: "numeric",
                month: "short",
                year: "numeric",
                timeZone: userTimezone,
              })}
            </span>
          )}
        </div>

        {/* Unpaid invoice message */}
        {billingDetails?.activePlan.isBillingComplete === false && (
          <p
            className={`mb-0 mt-1 ${styles.activePlan} ${styles.activePlanUnpaid}`}
          >
            <span className={`me-1`}>
              <i className="fa-solid fa-triangle-exclamation"></i>
            </span>
            The latest invoice for this subscription hasn't been paid
          </p>
        )}
      </div>

      {/* Right-Side */}
      <div
        className={`d-flex flex-column align-items-start align-items-md-end ${
          billingDetails?.activePlan.isBillingComplete === false
            ? "justify-content-between"
            : "justify-content-end"
        }`}
      >
        {/* Unpaid Buttons */}
        {billingDetails?.activePlan.isBillingComplete === false && (
          <div className="mt-3 mt-md-0 d-flex flex-md-column align-items-start align-items-md-end">
            <button
              className={`me-1 me-md-0 mb-md-1 px-3 ${styles.amountDueBtn}`}
              onClick={payAmountHandler}
              disabled={loader}
            >
              Pay Amount Due
              {loader && (
                <Spinner
                  className="ms-2"
                  size="sm"
                  animation="border"
                />
              )}
            </button>
            <button className={`px-3 d-none ${styles.amountDueBtn}`}>
              Cancel plan
            </button>
          </div>
        )}

        {/* Active Plan Details */}
        <div>
          <p className={`mb-0 mt-2 ${styles.price}`}>
            {billingDetails?.activePlan.price}/
            {billingDetails?.activePlan.type.shortName}
          </p>
          <span className={`${styles.dayCycle}`}>
            Paid {billingDetails?.activePlan.type.displayName}
          </span>
        </div>
      </div>
    </div>
  );
};

export default PlanDetails;
