import { useCallback, useMemo, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Loader from "src/components/Loader";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { IPlanTypes } from "src/services/Billing/getPaymentPlans.service";
import processUpgradePlanService from "src/services/Billing/processUpgradePlan.service";
import usePlanModal from "../../hooks/usePlanModal";
import PaymentPlan from "../PaymentPlan/PaymentPlan";
import styles from "./PlanModal.module.scss";
import closeModal from "src/assets/images/close(3).png";
import planUpgrade from "src/assets/images/planUpgrade.png";
import customerUser from "src/assets/images/SeatUsers.png";
/**
 * UI for the Payment Plan Modal
 */
const PlanModalUI = ({
  plans,
  selectedPlan,
  setSelectedPlanType,
  showSeatsModal,
}: {
  plans: IPlanTypes[];
  selectedPlan?: IPlanTypes;
  setSelectedPlanType: (plan: IPlanTypes["type"]) => void;
  showSeatsModal: () => void;
}) => {
  return (
    <>
      {/* Plan Types */}
      <div className={`text-center mx-auto ${styles.billWrapper} mb-3`}>
        {plans.map((plan) => {
          return (
            <button
              className={`${styles.billBtn} ${
                plan.type === selectedPlan?.type && styles.activebtn
              } me-1`}
              id={`${plan.displayName.replace(" ", "_")}`}
              disabled={plan.disabled}
              onClick={() => setSelectedPlanType(plan.type)}
            >
              {plan.displayName}
            </button>
          );
        })}
      </div>

      {/* Selected Plan type */}
      {selectedPlan && (
        <div className="d-flex flex-column flex-md-row justify-content-between gap-3">
          {selectedPlan.plans.map((plan) => (
            <PaymentPlan
              key={plan.planId}
              plan={plan}
              selectedPlan={selectedPlan}
            />
          ))}
        </div>
      )}

      {/* Button for upgrade */}
      <button
        className={`mt-3 ${styles.upgradeBtn}`}
        id="upgrade_Pro_Btn"
        onClick={showSeatsModal}
      >
        <span>
          <img
            src={planUpgrade}
            alt="UpgradeButton"
            className={`${styles.upgradeImg}`}
          />
        </span>
        <span className="ps-2">Upgrade to Pro plan</span>
      </button>
    </>
  );
};

const SelectSeatsUI = ({
  planId,
  selectedPlan,
  hideModal,
  handleCancel,
}: {
  hideModal: () => void;
  planId: string;
  selectedPlan?: IPlanTypes;
  handleCancel: () => void;
}) => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [input, setInput] = useState("");
  const [error, setError] = useState(false);

  /**
   * Validated input for number of seats
   */
  const validatedInput = useMemo(() => {
    const seats = Number.parseInt(input, 10);
    if (Number.isNaN(seats) || seats <= 0) {
      return null;
    }
    return seats;
  }, [input]);

  /**
   * Calculated extra cost.
   */
  const extraCost = useMemo(() => {
    // Check if input is validated else return 0
    if (!validatedInput) {
      return 0;
    }

    // Calculate extra seats number
    const extraSeats =
      validatedInput - (selectedPlan?.planDetails?.freeSeats ?? 0);

    // Return cost of extra seats
    const extraSeatsCost =
      extraSeats * (selectedPlan?.planDetails?.addtionalSeatsCost?.price ?? 0);

    // If less than 0 extra cost is calculated return 0
    return extraSeatsCost > 0 ? extraSeatsCost : 0;
  }, [
    selectedPlan?.planDetails?.addtionalSeatsCost?.price,
    selectedPlan?.planDetails?.freeSeats,
    validatedInput,
  ]);

  /**
   * Calculated Due Amount
   */
  const calculatedDueAmount = useMemo(() => {
    return (
      (validatedInput !== null
        ? selectedPlan?.planDetails?.upgradeCost?.price ?? 0
        : 0) + extraCost
    );
  }, [
    extraCost,
    selectedPlan?.planDetails?.upgradeCost?.price,
    validatedInput,
  ]);

  /**
   * Handler for upgrade plan
   */
  const handleUpgradePlan = useCallback(async () => {
    // Validate Input
    if (!validatedInput) {
      setError(true);
      return;
    }

    try {
      setLoader(true);
      const res = await processUpgradePlanService({
        planId: planId,
        seats: validatedInput,
      });
      if (res) {
        hideModal();
        navigate(`/settings/billing?status=${res}`);
      }
    } catch (e) {
      const err = e as Error;
      pushTheToast({
        position: "top-right",
        text: err.message,
        type: "danger",
      });
    } finally {
      setLoader(false);
    }
  }, [validatedInput, planId, hideModal, navigate]);

  return (
    <>
      <div className={`${styles.planWrapper} d-flex flex-column`}>
        <div className="px-3">
          <div className={`d-flex align-items-center mb-1`}>
            <div className={`${styles.planBox} p-1`}>Pro Plan</div>
            <div className={`${styles.price} ms-2`}>
              <span>
                {selectedPlan?.planDetails?.upgradeCost?.currency}
                {selectedPlan?.planDetails?.upgradeCost?.price}
              </span>{" "}
              /{selectedPlan?.type === "monthly" ? "month" : "year"}
            </div>
          </div>

          <span className={`${styles.planType}`}>Commitment</span>
          <span className={`${styles.billType}`}>
            Billed ${selectedPlan?.type === "monthly" ? "Monthly" : "Yearly"}
          </span>

          <div className={`d-flex align-items-center`}>
            <span className={`${styles.userIcon}`}>
              <img
                src={customerUser}
                alt="users"
                className={`${styles.usersImg}`}
              />
            </span>
            <span className={`${styles.seatInfo} ms-1`}>
              {selectedPlan?.planDetails?.freeSeats} Free Seats -
              {selectedPlan?.planDetails?.addtionalSeatsCost?.currency}
              {selectedPlan?.planDetails?.addtionalSeatsCost?.price} per
              additional seat
            </span>
          </div>
          <div className={`${styles.seatBox} my-2  d-flex align-items-center`}>
            <div>
              <input
                type="number"
                name="seats"
                id="seats"
                min={1}
                step={1}
                value={input}
                onChange={(e) => setInput(e.target.value)}
                className={`form-control ${styles.numberInput} ${
                  error ? "border-danger" : ""
                }`}
              />
            </div>
            {selectedPlan?.planDetails?.addtionalSeatsCost && (
              <span className="ms-2">
                + {selectedPlan.planDetails.addtionalSeatsCost.currency}
                {extraCost}
                {validatedInput !== null &&
                validatedInput > selectedPlan.planDetails.freeSeats
                  ? ` (${validatedInput} additional seat)`
                  : ""}
              </span>
            )}
          </div>
        </div>

        <div
          className={`${styles.dueInfo} mt-2 pt-2 px-3 d-flex align-items-center justify-content-between`}
        >
          <span>DUE NOW</span>
          <span>
            {selectedPlan?.planDetails?.addtionalSeatsCost?.currency}
            {calculatedDueAmount}
          </span>
        </div>
      </div>

      <div
        className={`${styles.buttons} mt-3 d-flex align-items-center justify-content-end`}
      >
        <button
          className={`${styles.cancelBtn}`}
          onClick={handleCancel}
          id="cancelBtn"
        >
          Cancel
        </button>
        <button
          className={`ms-1 ${styles.processBtn}`}
          onClick={handleUpgradePlan}
          disabled={loader}
          id="processBtn"
        >
          Process Payment
          {loader && (
            <Spinner
              size="sm"
              className="mx-2"
              animation="border"
            />
          )}
        </button>
      </div>
    </>
  );
};

const PlanModal = ({
  hideModal,
  showModal,
}: {
  hideModal: () => void;
  showModal: boolean;
}) => {
  const [showSeat, setShowSeat] = useState(false);
  const {
    isError,
    isLoading,
    plans,
    selectedPlan,
    planId,
    setSelectedPlanType,
  } = usePlanModal();

  const showSeatsModal = useCallback(() => setShowSeat(true), []);

  const hideSeatsModal = useCallback(() => setShowSeat(false), []);

  const onHideModal = useCallback(() => {
    hideModal();
    setShowSeat(false);
  }, [hideModal]);

  return (
    <Modal
      backdropClassName={`${styles.modalBack}`}
      show={showModal}
      onHide={onHideModal}
      dialogClassName={`${
        showSeat ? styles.seatsModalDialog : styles.modalDialog
      }`}
      contentClassName={`${
        showSeat ? styles.seatsModalContent : styles.modalContent
      }`}
      backdrop="static"
      centered={true}
      enforceFocus={false}
    >
      {showSeat ? (
        <div className={`${styles.seatsModalWrapper}`}>
          <div className="position-relative">
            <h2>Your plan</h2>
            <span
              className={`${styles.closeModal}`}
              onClick={onHideModal}
              id="closeModal"
            >
              <img
                src={closeModal}
                alt="closeModal"
                width={10}
                height={10}
              />
            </span>
          </div>

          <SelectSeatsUI
            hideModal={onHideModal}
            handleCancel={hideSeatsModal}
            planId={planId}
            selectedPlan={selectedPlan}
          />
        </div>
      ) : (
        <div className={` ${styles.modalWrapper}`}>
          <div className="position-relative">
            <h2 className={`text-center ${styles.heading}`}>
              Upgrade to Pro plan
            </h2>
            <span
              className={`${styles.closeModal}`}
              onClick={onHideModal}
            >
              <img
                src={closeModal}
                alt="closeModal"
                width={10}
                height={10}
              />
            </span>
          </div>
          {isLoading ? (
            <div className="d-flex align-items-center justify-content-center">
              <Loader />
            </div>
          ) : isError ? (
            <div className="d-flex align-items-center justify-content-center text-danger">
              Error Loading Payment Plans...
            </div>
          ) : (
            <PlanModalUI
              showSeatsModal={showSeatsModal}
              plans={plans}
              selectedPlan={selectedPlan}
              setSelectedPlanType={setSelectedPlanType}
            />
          )}
        </div>
      )}
    </Modal>
  );
};

export default PlanModal;
