import { IBotIndexingStatus } from "src/components/BotIndexingStatus/BotIndexingStatus";
import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";

export interface Articles {
  articleId: number;
  articleUrl: string;
  articleTitle: string;
  articleMainSource: string;
  noOfTimesAppeared: number;
  articleDescription: string;
  isSelected: boolean;
  botTrainingStatus: IBotIndexingStatus;
}

export interface GetSourceArticlesResponse {
  data: Articles[];
  metaData: {
    count: number;
    total: number;
  };
}

export interface GetSourceArticlesParams {
  searchTerm?: string;
  start?: number;
  limit?: number;
  sourceId: number | string;
}

const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/botProfile/external/getAll`
  : "/api/botProfile/external/getSourceArticles";

export async function getSourceArticles(params: GetSourceArticlesParams) {
  const { data: res } = await axiosJSON.post(apiEndPoint, params);
  console.log(res.data);
  if (res.err) {
    throw new Error(res.message as string);
  }

  return res as GetSourceArticlesResponse;
}
