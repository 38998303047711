import { AJAXSTATUS } from "src/globals/constants";
import {
  ITabTypeCount,
  ITrafficListItem,
} from "src/services/LiveChat/traffic/getTrafficList.service";
import {
  ILiveChatTrafficState,
  initialState,
  TRAFFIC_FILTER_TYPE,
} from "./liveChatTraffic.slice";

const resetLiveChatTrafficData = (state: ILiveChatTrafficState) => {
  state = { ...initialState };
  return state;
};

const resetLiveChatTrafficDataExceptTabCount = (
  state: ILiveChatTrafficState
) => {
  state.trafficItemList = {};
  state.trafficItemIdsList = [];
  state.fetchTrafficDataAjaxStatus = "pending";
  state.assignChatAjaxStatus = "idle";
  state.trafficFetchLimit = 10;
  state.trafficFilterType = "all";
  state.assignChatStatusCode = "";
  state.metaData = {
    count: 0,
    total: null,
  };

  return state;
};

const setTrafficFilterType = (
  state: ILiveChatTrafficState,
  { payload }: { payload: { filterType: TRAFFIC_FILTER_TYPE } }
) => {
  state.trafficFilterType = payload.filterType;
};

const resetTrafficFilterType = (state: ILiveChatTrafficState) => {
  state.trafficFilterType = "all";
};

const resetChatAssignStatusCode = (state: ILiveChatTrafficState) => {
  state.assignChatStatusCode = "";
};

const setAssignChatAjaxStatus = (
  state: ILiveChatTrafficState,
  { payload }: { payload: { ajaxStatus: AJAXSTATUS } }
) => {
  state.assignChatAjaxStatus = payload.ajaxStatus;
};

const setTrafficTypeTabCount = (
  state: ILiveChatTrafficState,
  { payload }: { payload: { tabCount: ITabTypeCount } }
) => {
  state.trafficTypeTabCount = {
    all: payload.tabCount.all,
    chatting: payload.tabCount.chatting,
    queued: payload.tabCount.queued,
    waitingForReply: payload.tabCount.waitingForReply,
  };
  // console.log("setTrafficTypeTabCount traffic tab count updated");
};

//  function to set traffic data
const setTrafficData = (
  state: ILiveChatTrafficState,
  {
    payload,
  }: {
    payload: {
      trafficItemList: { [id: number | string]: ITrafficListItem };
      trafficItemIdsList: Array<number | string>;
      metaData: any;
    };
  }
) => {
  state.trafficItemList = payload.trafficItemList;
  state.trafficItemIdsList = payload.trafficItemIdsList;
  state.metaData = {
    count: payload.metaData.count,
    total: payload.metaData.total,
  }
  // console.log("setTrafficData traffic data updated");
};

export default {
  resetLiveChatTrafficData,
  setTrafficFilterType,
  resetTrafficFilterType,
  resetChatAssignStatusCode,
  setAssignChatAjaxStatus,
  resetLiveChatTrafficDataExceptTabCount,
  setTrafficTypeTabCount,
  setTrafficData
};
