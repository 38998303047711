import { axiosJSON } from "src/globals/axiosEndPoints";

export const createWebIntegration = async () => {
  const { data: res } = await axiosJSON.post(`/api/chatSetting/widget/create`);
  if (res.err) {
    throw res.msg;
  }
  return res.data as {
    integration_id: number | string;
  };
};
