export interface LoginCustomerInfo {
  userId: number | string;
  name: string;
  email: string;
}

export interface LoginData {
  status: string | number;
  message: string;
  expires_in: number;
  token_type: string;
  auth_header_name: string;
  access_token: string;
  current_user_info: LoginCustomerInfo;
}

export function getLocalLoginData() {
  let data = null as null | LoginData;
  let temp = localStorage.getItem("loginData");
  if (temp) {
    data = JSON.parse(temp) as LoginData;
  }
  return data;
}
export function getLocalLoginCustomerInfo() {
  let data = null as null | LoginData;
  let temp = localStorage.getItem("loginData");
  if (temp) {
    data = JSON.parse(temp) as LoginData;
  }
  return data ? data.current_user_info : null;
}
