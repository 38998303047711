/**
 * This file contains the custom hook for handling KB settings.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { getAllThemes } from "src/services/KnowledgeBase/Settings/Styles/getAllThemes.service";

/**
 * Custom hook for managing the theme modal in KB settings.
 * Handles data fetching, pagination, and error states.
 */
function useThemeModal() {
  // Extract integrationId from the URL parameters
  const { integrationId } = useParams();
  // Decode integrationId if present
  const pageIntegrationId = integrationId
    ? decodeURIComponent(integrationId)
    : "";

  // Payload for initial data fetch
  const payload = {
    integrationId: pageIntegrationId,
    start: 0,
    limit: 10,
  };

  // useInfiniteQuery for handling infinite scroll and pagination
  const {
    data,
    isLoading,
    hasNextPage,
    fetchNextPage,
    refetch,
    isRefetching,
    isFetchingNextPage,
    status,
    fetchStatus,
    isError,
    error,
  } = useInfiniteQuery({
    queryKey: ["themes", payload],
    queryFn: ({ pageParam = payload }) => getAllThemes(pageParam),
    getNextPageParam: (lastPage: any, allPages) => {
      const data = allPages.flatMap((data: any) => data.data);
      // Calculate the next page parameters based on the fetched data
      if (data.length < lastPage.metaData.totalCount) {
        return {
          integrationId: pageIntegrationId,
          start: data.length,
          limit: 10,
        };
      }
      return null;
    },
  });

  // Flatten data to a single array
  const getAllThemesCall = data?.pages.flatMap((data: any) => data.data);

  // Return relevant data and functions for external use
  return {
    getAllThemesCall,
    isLoading,
    hasNextPage,
    fetchNextPage,
    refetch,
    isRefetching,
    isFetchingNextPage,
    status,
    fetchStatus,
    isError,
    error,
  };
}

export default useThemeModal;
