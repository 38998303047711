import React from "react";
import styles from "../../../LiveChatWidget.module.scss";
interface Props{
  onClick?: any;
};
function DeleteButton({onClick}:Props) {
  return (
    <div>
      <button className={`${styles.del}`} onClick={(e)=>onClick && onClick(e)}>
        <span className={`${styles.trash}`}>
          <i className="fa-solid fa-trash"></i>
        </span>
      </button>
    </div>
  );
}

export default DeleteButton;
