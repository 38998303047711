import React from "react";
import styles from "./HistoryInternalNote.module.scss";
import UserAvatar from "src/components/UserAvatar";
import AxiosImg from "src/components/AxiosImg";
import ParseHTML from "src/components/ParseHTML";
import InnerChatAttachments from "src/components/InnerChatAttachments/InnerChatAttachments";
import { Message } from "src/services/LiveChat/messageExchange/getMessages";

const HistoryInternalNote = ({
  messageText,
  sentBy,
  sendTimeGmt,
  attachments,
  sendTime,
  messageId,
  messageType,
}: Message) => {
  return (
    <>
      <div
        className={`d-flex justify-content-end mb-2 ${styles.msgOut}`}
        style={{ marginLeft: "38px" }}
      >
        <div>
          <div className={`${styles.noteDataDiv} ${styles.msgText}`}>
            <ParseHTML
              html={messageText}
              // replaceNewLineWithBr={true}
            />

            {attachments && attachments.length > 0 && (
              <div className="mt-2">
                <InnerChatAttachments
                  messageData={{
                    attachments: attachments,
                    senderName: sentBy.name,
                    messageDateTime: sendTime,
                    messageId: messageId,
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <div className="d-flex flex-column align-items-center">
          {sentBy?.imageURL && sentBy?.imageURL.length !== 0 ? (
            <AxiosImg
              url={sentBy.imageURL}
              className={`rounded-circle ${styles.profileImage} mb-1`}
              isDirectURL={sentBy?.isPublicAttachmentUrl}
            />
          ) : (
            <UserAvatar
              name={sentBy?.name ?? "NA"}
              size={60}
              className={`rounded-circle ${styles.profileImage} mb-1`}
            />
          )}
          {/* <span className={` ${styles.insta}`}>
              <i className={`d-block ${getChannelFAIcon("note")}`}></i>
          </span> */}
        </div>
      </div>
    </>
  );
};

export default HistoryInternalNote;
