import { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import Lottie from "lottie-light-react";
import usePublishReturnAutoWorkFlow from "src/features/ReturnAutoWorkFlow/hooks/usePublishReturnAutoWorkFlow";
import BrandDropdown from "../BrandDropdown/BrandDropdown";
import LiveDot from "src/assets/images/liveDot.json";
import celebration from "src/assets/images/celebration.json";
import cross from "src/assets/images/featuredArticledCross.png";
import saufterLogo from "src/assets/images/saufter.png";
import styles from "./GoLiveButton.module.scss";

function GoLiveButton({ useModal }: { useModal: boolean }) {
  const {
    returnAutoWorkFlow,
    returnAutoWorkFlowBrand,
    publishReturnAutoWorkFlow,
    loadingStatus,
    selectedBrand,
    handleChange,
  } = usePublishReturnAutoWorkFlow();

  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div className={`d-flex align-items-center mt-2`}>
        <div>
          <button
            className="btn btn-dark"
            onClick={() => {
              if (useModal) {
                setShowModal(true);
              } else {
                publishReturnAutoWorkFlow(
                  returnAutoWorkFlow.published ? false : true,
                );
              }
            }}
          >
            <span className={`${styles.btnSecond}`}>
              {loadingStatus === "pending" ? (
                <Spinner
                  className="my-auto mx-2"
                  animation="border"
                  size="sm"
                />
              ) : (
                <i className="fa-regular fa-eye mx-1"></i>
              )}

              {returnAutoWorkFlow.published ? "Unpublish" : "Go live"}
            </span>
          </button>
        </div>
        {returnAutoWorkFlow.published && (
          <div className="d-flex align-items-center ms-2">
            <span
              className="d-block me-1"
              style={{
                width: "20px",
                height: "20px",
              }}
            >
              <Lottie animationData={LiveDot}></Lottie>
            </span>
            <span className={`${styles.info} mx-2 pt-1`}>Live</span>
          </div>
        )}
      </div>

      {useModal ? (
        <Modal
          show={showModal}
          onHide={
            loadingStatus === "pending"
              ? undefined
              : () => {
                  setShowModal(false);
                }
          }
          contentClassName={`${styles.modalMove} p-3`}
          centered={true}
          enforceFocus={false}
        >
          {loadingStatus === "pending" ? (
            <>
              <div className={`${styles.load}`}>
                <div className={`position-relative ${styles.loaderWrapper}`}>
                  <span
                    className="spinner-border"
                    role="status"
                  ></span>
                  <img
                    src={saufterLogo}
                    alt=""
                    className={`${styles.loaderImage} position-absolute`}
                  />
                </div>
              </div>
            </>
          ) : loadingStatus === "rejected" ? (
            <>
              <div className={`${styles.load}`}>
                <div className={`position-relative ${styles.loaderWrapper}`}>
                  <img
                    src={saufterLogo}
                    alt=""
                    className={`${styles.loaderImage} position-absolute`}
                  />
                  <div className="text-danger text-center">
                    Something went wrong! Please check your network or try again
                    later.
                  </div>
                </div>
              </div>
            </>
          ) : loadingStatus === "fulfilled" ? (
            <>
              {returnAutoWorkFlow.published ? (
                <div className="">
                  <div className="text-center py-2">
                    {/* 11 Dec Adding lottie animation as per xd */}
                    <div className={`${styles.checkBox} mb-4`}>
                      <Lottie
                        loop={false}
                        className={`${styles.celebrationAnimation}`}
                        animationData={celebration}
                      ></Lottie>
                      <span>
                        <i className="fa-solid fa-check"></i>
                      </span>
                    </div>
                    <p className={`mb-1 pt-2 ${styles.setup}`}>
                      The setup is completed!
                    </p>
                    <span className={`${styles.successSpan}`}>
                      {`Returns automation flow is live for "${
                        returnAutoWorkFlowBrand &&
                        (returnAutoWorkFlowBrand.name ?? "NA")
                      }"`}
                      {/* checking if selectedbrand exists before displaying label */}
                    </span>
                  </div>
                  <div className="d-flex justify-content-center mt-4 mb-2 pt-2">
                    <button
                      className={`me-4 ${styles.unPublishBtn}`}
                      onClick={() => {
                        publishReturnAutoWorkFlow(false);
                        setShowModal(false);
                      }}
                    >
                      <span>
                        <i className="fa-solid fa-eye"></i>
                      </span>
                      <span className="ps-1">Unpublish</span>
                    </button>
                    <button
                      className={`${styles.okBtn}`}
                      onClick={() => {
                        setShowModal(false);
                      }}
                    >
                      Okay
                    </button>
                  </div>
                </div>
              ) : (
                <div className="">
                  <div className="d-flex justify-content-between">
                    <div className={`${styles.headingModel} ms-2 mb-3`}>
                      Choose a brand
                    </div>
                    <div
                      onClick={() => {
                        setShowModal(false);
                      }}
                    >
                      <span className="cursor-pointer">
                        {/* 11 Dec Adding cross icon as per xd */}
                        <img
                          src={cross}
                          alt="close"
                          className={`${styles.closeBtn}`}
                        />
                      </span>
                    </div>
                  </div>
                  <div className={`mt-2 ms-2 ${styles.subheadingModel}`}>
                    This will appear in your live chat for the following brand:
                  </div>
                  <div className={`mt-2 mx-2 ${styles.dropDown}`}>
                    <BrandDropdown
                      selectedBrand={selectedBrand}
                      setSelectedBrand={handleChange}
                    />
                  </div>
                  <div
                    className={`${styles.btnModel} d-flex justify-content-end`}
                  >
                    <button
                      className={`${styles.btn} ${styles.publishBtn}`}
                      onClick={() => {
                        publishReturnAutoWorkFlow(true);
                      }}
                    >
                      Publish
                    </button>
                  </div>
                </div>
              )}
            </>
          ) : (
            <></>
          )}
        </Modal>
      ) : (
        ""
      )}
    </>
  );
}

export default GoLiveButton;
