import styles from "./MoreDetails.module.scss";
import refresh from "src/assets/images/chat/refreshButton.png";
import dummy1 from "src/assets/images/order1.png";
import dummy2 from "src/assets/images/order2.png";
import caution from "src/assets/images/caution.png";
import gift from "src/assets/images/chat/gift.png";
import item from "src/assets/images/chat/item1.png";
import { useState } from "react";
import FinalDetail from "./Children/FinalDetail/FinalDetail";
const MoreDetails = () => {
  const [isActive, setIsActive] = useState(false);
  const [isActive2, setIsActive2] = useState(false);
  const [isActive3, setIsActive3] = useState(false);
  const [isActive4, setIsActive4] = useState(false);

  const handleClick = () => {
    setIsActive(true);
    setIsActive2(false);
  };
  const handleClick2 = () => {
    setIsActive2(true);
    setIsActive(false);
  };
  const handleClick3 = () => {
    setIsActive3(true);
    setIsActive4(false);
  };
  const handleClick4 = () => {
    setIsActive4(true);
    setIsActive3(false);
  };

  return (
    <div>
      <div className={`d-flex align-items-center`}>
        <div>
          <img
            src={refresh}
            alt="refresh"
            className={`${styles.refresh}`}
          />{" "}
        </div>
        <span className={`ms-1 ${styles.heading}`}>
          Initiate Return/Exchange{" "}
        </span>
      </div>

      <span className={`d-inline-block my-2 ${styles.detailSpan}`}>
        {" "}
        Give us more details:
      </span>
      {/* Below html is hide for further component show */}
      <div className={`d-none`}>
        {/* First section  */}
        <div>
          <div>
            <span className={`d-block px-1 my-2 py-1 ${styles.errormsg}`}>
              *Please add required details for this item
            </span>
          </div>
          <div className={`py-2 px-2 mb-3 ${styles.cardBox}`}>
            <div>
              <span className={`pe-1 d-inline-block ${styles.orderName}`}>
                Acme T-shirt
              </span>
              <span className={`d-inline-block ${styles.moreInfo}`}>
                *Exchange with other item
              </span>
            </div>
            <div className="d-flex mb-3">
              <img
                src={dummy1}
                className={`${styles.orderImg}`}
              />
              <div className="ms-1">
                <span className={`d-block ${styles.orderData}`}>$ 789 x 1</span>

                <span className={`d-block ${styles.orderData}`}>SKU: 0101</span>
              </div>
            </div>
            <div className="d-flex">
              <span className={`${styles.reason}`}> Reasons:</span>{" "}
              <span className={`${styles.reasonIssue}`}> Fitting Issues</span>{" "}
            </div>
            <div className={`d-flex my-1 py-1 px-2 ${styles.cautionBox}`}>
              <img
                src={caution}
                alt=""
                className={`mt-1 ${styles.caution}`}
              />
              <span className="ps-1">
                {" "}
                This item gets smaller after a wash. Taking a size larger is
                recommended.
              </span>
            </div>
            <div className={`mt-2`}>
              <span className={`${styles.reason}`}>More details:</span>{" "}
              <div className="form-check">
                <input
                  className={`form-check-input ${styles.radioCheck}`}
                  type="radio"
                  name="item"
                  id="item1"
                />
                <label
                  className={`form-check-label ${styles.labelItem}`}
                  htmlFor="item1"
                >
                  Too Small
                </label>
              </div>
              <div className="form-check">
                <input
                  className={`form-check-input ${styles.radioCheck}`}
                  type="radio"
                  name="item"
                  id="item2"
                />
                <label
                  className={`form-check-label ${styles.labelItem}`}
                  htmlFor="item2"
                >
                  Too Large
                </label>
              </div>
              <div className="form-check">
                <input
                  className={`form-check-input ${styles.radioCheck}`}
                  type="radio"
                  name="item"
                  id="item3"
                />
                <label
                  className={`form-check-label ${styles.labelItem}`}
                  htmlFor="item3"
                >
                  Did not like the fit
                </label>
              </div>
            </div>
            <div className={`p-2 mt-2 ${styles.discountOffer}`}>
              <div className={`d-flex`}>
                <div>
                  <img
                    src={gift}
                    className={`me-1 ${styles.giftBox}`}
                    alt=""
                  />
                </div>
                <div>
                  <h6 className={`${styles.disHead}`}>
                    Get 10 % discount on your next order instead of this
                    exchange
                  </h6>
                </div>
              </div>
              <div
                className={`mt-2 mb-1 d-flex  justify-content-between flex-column flex-md-row`}
              >
                <button
                  className={`px-2 mb-1 mb-md-0 ${styles.commonBtn}`}
                  onClick={handleClick}
                  style={{
                    backgroundColor: isActive ? "#000" : "",
                    color: isActive ? "#fff" : "",
                  }}
                >
                  Not intrested
                </button>
                <button
                  className={`px-2 ${styles.commonBtn} ${styles.activeBtn}`}
                  onClick={handleClick2}
                  style={{
                    backgroundColor: isActive2 ? "#000" : "",
                    color: isActive2 ? "#fff" : "",
                  }}
                >
                  {isActive2 ? "Applied Offer !" : " Apply Offer"}
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Second Section  */}
        <div>
          {/* <div>
          <span className={`d-block px-1 my-2 py-1 ${styles.errormsg}`}>
            *Please add required details for this item
          </span>
        </div> */}

          <div className={`py-2 px-2 ${styles.cardBox}`}>
            <div>
              <span className={`pe-1 ${styles.orderName}`}>Acme T-shirt</span>
              <span className={`d-inline-block ${styles.moreInfo}`}>
                *Exchange with other item
              </span>
            </div>
            <div className="d-flex mb-3">
              <img
                src={dummy1}
                className={`${styles.orderImg}`}
              />
              <div className="ms-1">
                <span className={`d-block ${styles.orderData}`}>$ 789 x 1</span>

                <span className={`d-block ${styles.orderData}`}>SKU: 0101</span>
              </div>
            </div>
            <div className="d-flex">
              <span className={`${styles.reason}`}> Reasons:</span>{" "}
              <span className={`${styles.reasonIssue}`}>Quality issues</span>{" "}
            </div>
            <div>
              <span className={`${styles.reason}`}>More details:</span>{" "}
              <div className="form-check">
                <input
                  className={`form-check-input ${styles.radioCheck}`}
                  type="radio"
                  name="item"
                  id="item1"
                />
                <label
                  className={`form-check-label ${styles.labelItem}`}
                  htmlFor="item1"
                >
                  Fabric is too thin
                </label>
              </div>
              <div className="form-check">
                <input
                  className={`form-check-input ${styles.radioCheck}`}
                  type="radio"
                  name="item"
                  id="item2"
                />
                <label
                  className={`form-check-label ${styles.labelItem}`}
                  htmlFor="item2"
                >
                  Colour is different
                </label>
              </div>
              <div className="form-check">
                <input
                  className={`form-check-input ${styles.radioCheck}`}
                  type="radio"
                  name="item"
                  id="item3"
                />
                <label
                  className={`form-check-label ${styles.labelItem}`}
                  htmlFor="item3"
                >
                  Damages item
                </label>
              </div>
            </div>
            <div className="mb-3">
              <label
                htmlFor="describe"
                className={`form-label ${styles.textLabel}`}
              >
                Please describe the issue:
              </label>
              <textarea
                className={`form-control ${styles.desTextArea} ${styles.dangerBorder}`}
                id="describe"
                rows={3}
                placeholder={`Please describe your reason here`}
              ></textarea>
            </div>
            <div className={`p-2 mt-2 ${styles.discountOffer}`}>
              <div className={`d-flex`}>
                <div>
                  <img
                    src={gift}
                    className={`me-1 ${styles.giftBox}`}
                    alt=""
                  />
                </div>
                <div>
                  <h6 className={`mb-0 ${styles.disHead}`}>
                    Get a free Item from clearance sale instead of this exchange
                  </h6>
                  <span>You will be selecting free item in next steps</span>
                </div>
              </div>
              <div
                className={`mt-2 mb-1 d-flex justify-content-between flex-column flex-md-row`}
              >
                <button
                  className={`px-2 mb-1 mb-md-0 ${styles.commonBtn}`}
                  onClick={handleClick3}
                  style={{
                    backgroundColor: isActive3 ? "#000" : "",
                    color: isActive3 ? "#fff" : "",
                  }}
                >
                  Not intrested
                </button>
                <button
                  className={`px-2 ${styles.commonBtn} ${styles.activeBtn}`}
                  onClick={handleClick4}
                  style={{
                    backgroundColor: isActive4 ? "#000" : "",
                    color: isActive4 ? "#fff" : "",
                  }}
                >
                  {isActive4 ? "Applied Offer !" : " Apply Offer"}
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Third section  */}
        <div>
          {/* <div>
          <span className={`d-block px-1 my-2 py-1 ${styles.errormsg}`}>
            *Please add required details for this item
          </span>
        </div> */}

          <div className={`py-2 px-2 ${styles.cardBox}`}>
            <div>
              <span className={`pe-1 ${styles.orderName}`}>Acme T-shirt</span>
              <span className={`d-inline-block ${styles.moreInfo}`}>
                *Exchange with other item
              </span>
            </div>
            <div className="d-flex mb-3">
              <img
                src={dummy2}
                className={`${styles.orderImg}`}
              />
              <div className="ms-1">
                <span className={`d-block ${styles.orderData}`}>$ 789 x 1</span>

                <span className={`d-block ${styles.orderData}`}>SKU: 0101</span>
              </div>
            </div>
            <div className="d-flex">
              <span className={`${styles.reason}`}> Reasons:</span>{" "}
              <span className={`${styles.reasonIssue}`}>
                Received different item
              </span>{" "}
            </div>
            <div className="mt-2  mb-3">
              <label
                htmlFor="text"
                className={`form-label ${styles.textLabel}`}
              >
                Was the order properly packed at the time of delivery ?
              </label>
              <input
                className={`form-control ${styles.inputBox}`}
                type="text"
                id="text"
                placeholder="Your answer"
                aria-label=""
              />
            </div>
            <div className="mb-3">
              <label
                htmlFor="describe"
                className={`form-label ${styles.textLabel}`}
              >
                Please describe the issue:
              </label>
              <textarea
                className={`form-control ${styles.desTextArea} ${styles.dangerBorder}`}
                id="describe"
                rows={3}
                placeholder={`Please describe your reason here`}
              ></textarea>
            </div>
            <div className="mb-3">
              <label
                htmlFor="describe"
                className={`form-label ${styles.textLabel}`}
              >
                Add Images:
              </label>
              <div className="d-flex flex-wrap">
                <div className={`me-2`}>
                  <img
                    src={item}
                    alt=""
                    className={`${styles.addedImg}`}
                  />
                </div>
                <div
                  className={`d-flex justify-content-center align-items-center cursor-pointer ${styles.addimg}`}
                >
                  <span>+</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Html of 40/62 of xd onward  */}
      </div>
      <div>
        <FinalDetail />
      </div>
    </div>
  );
};
export default MoreDetails;
