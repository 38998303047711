import { useCallback, useState } from "react";
import { Modal } from "react-bootstrap";
import { IAction } from "src/services/Automation/getAutomationById.service";
import styles from "./ConfigurationAction.module.scss";
import { EAutomationType } from "src/routes/BotSettings/Children/AutomationList/AutomationList";
import DispatchModal from "../../../Components/ConfigureDispatchRule/DispatchModal";

const ConfigurationAction = ({
  data,
  integrationId,
  automationType,
  isQuestionDisabled,
  updateActionStatus,
  refetchAutomation,
  disableRefetch,
}: {
  data: IAction;
  integrationId: string;
  automationType: EAutomationType;
  isQuestionDisabled: boolean;
  updateActionStatus: (status: IAction["status"]) => void;
  refetchAutomation: () => void;
  disableRefetch: boolean;
}) => {
  const [showModal, setShowModal] = useState(false);
  const { displayName, status, key } = data;

  const onOpenModal = useCallback(() => {
    setShowModal(true);

    if (!disableRefetch) {
      refetchAutomation();
    }
  }, [disableRefetch, refetchAutomation]);

  return (
    <div className="mt-3">
      <button
        className={`me-1 ${styles.configureBtn} px-3 d-flex align-items-center justify-content-center`}
        onClick={onOpenModal}
        disabled={isQuestionDisabled}
      >
        {status === "completed" ? (
          <span className={`${styles.checkLogo} mx-1 px-1`}>
            <i className="fa-solid fa-check"></i>
          </span>
        ) : (
          <span className={`pe-1`}>+</span>
        )}
        {displayName}
      </button>

      <Modal
        backdropClassName={`${styles.modalBack}`}
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName={`${styles.modalDialog}`}
        contentClassName={`${styles.modalContent}`}
        centered
      >
        {key === "dispatchRules" && (
          <DispatchModal
            onHide={() => setShowModal(false)}
            updateStatus={updateActionStatus}
            integrationId={integrationId}
            automationType={automationType}
          />
        )}
      </Modal>
    </div>
  );
};

export default ConfigurationAction;
