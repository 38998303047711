import { useMemo } from "react";
import { useParams } from "react-router-dom";
import automationSetting from "src/assets/images/automation-setting.png";
import { EAutomationType, IAutomationListParams } from "../../AutomationList";
import styles from "./NoAutomation.module.scss";

/**
 * Props for No Automation Component
 */
interface Props {
  setShow?: (value: boolean) => void;
}

/**
 * Component for showing each Automation
 */
const NoAutomation = ({ setShow }: Props) => {
  const { type } = useParams<IAutomationListParams>();

  const text = useMemo(() => {
    switch (type) {
      // case EAutomationType.ORDER_MODIFICATION:
      //   return "No Modify or Cancel Order automation is live on your store";
      // case EAutomationType.RETURN:
      //   return "No Return / Refund Order automation is live on your store";
      // case EAutomationType.SUBSCRIPTION_CANCELLATION:
      //   return "No subscription cancellation automation is live on your store";
      case EAutomationType.BUG_MANAGEMENT:
        return "No bug management automation is live on your store";
      case EAutomationType.TICKET_ROUTING:
        return "No ticket routing automation is live on your store";
      case EAutomationType.ISSUE_DISPATCH:
        return "No issue dispatch automation is currently active in your system";
    }

    return "";
  }, [type]);

  const subText = useMemo(() => {
    switch (type) {
      // case EAutomationType.ORDER_MODIFICATION:
      //   return "Enable Modify or Cancel Order for your store.";
      // case EAutomationType.RETURN:
      //   return "Enable Return / Refund Order for your store.";
      // case EAutomationType.SUBSCRIPTION_CANCELLATION:
      //   return "Enable subscription cancellation for your store.";
      case EAutomationType.BUG_MANAGEMENT:
      case EAutomationType.ISSUE_DISPATCH:
        return "Enable Returns/Exchange for your store.";
      case EAutomationType.TICKET_ROUTING:
        return "Enable ticket routing for your store.";
    }

    return "";
  }, [type]);

  return (
    <div className={`${styles.nothingFound} m-auto`}>
      <div className={`mt-3`}>
        <div className="m-auto  text-center">
          <div className="my-3">
            <img
              src={automationSetting}
              alt=""
              className={`${styles.logo}`}
            />
          </div>
          <div className="">
            <span className={`d-block mb-2 ${styles.NoReturnText} text-center`}>
              {text}
            </span>
          </div>
          <div className="">
            <span className={`d-block mb-2 ${styles.enableText} text-center`}>
              {subText}
            </span>
          </div>
          {setShow && (
            <div className="mb-2 pt-1 w-100 d-flex justify-content-center">
              <span
                className={`px-2 ${styles.addBtn}`}
                role="button"
                onClick={() => setShow(true)}
              >
                Add new automation
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NoAutomation;
