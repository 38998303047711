import LiveChatProcess from "src/routes/Home/ToDoList/LiveChatSetup/EmailProcess/LiveChatProcess";
import StoreDetail from "./StoreDetail/StoreDetail";
import CopyCode from "./CopyCode/CopyCode";
import CheckInstallation from "./CheckInstallation/CheckInstallation";
import styles from "../../ListStep.module.scss";
import useLiveChatToDo from "../../../hooks/useLiveChatToDo";
import Loader from "src/components/Loader";
import closeBtn from "src/assets/images/close(3).png";
/**
 * Component for Next button
 */
const NextButton = ({
  handleNext,
  disableNext = false,
}: {
  handleNext: () => void;
  disableNext?: boolean;
}) => {
  return (
    <div className={`mt-3 d-flex align-items-center ${styles.pageWrap}`}>
      <button
        className={`py-2 px-md-3 ${styles.nextBtn}`}
        disabled={disableNext}
        onClick={handleNext}
        id="nextBtn"
      >
        Next{" "}
        <span className="ps-1">
          <i className="fa-solid fa-arrow-right"></i>
        </span>
      </button>
    </div>
  );
};

/**
 * Component for Live Chat To-Do Step Modal Steps
 */
const LiveChatToDo = ({
  hideModal,
  integrationId,
  stepNumber,
}: {
  hideModal: () => void;
  integrationId?: string;
  stepNumber: number;
}) => {
  const {
    activeStep,
    disableNext,
    storeDetail,
    loading,
    installLoading,
    showValidation,
    checkInstallation,
    handleNext,
    updateStoreDetails,
  } = useLiveChatToDo({ integrationId, stepNumber, onHide: hideModal });

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h3 className={`mb-0 ${styles.modalHead}`}>Setup live chat widget</h3>
        <img
          src={closeBtn}
          alt=""
          className={`${styles.closeBtn}`}
          onClick={hideModal}
        />
      </div>
      <LiveChatProcess
        step={activeStep}
        isStep1Done={!disableNext && activeStep !== 1}
        isStep4Done={activeStep === 4}
      />

      {loading ? (
        <Loader />
      ) : (
        <>
          {/* Store Details Step  */}
          {activeStep === 1 && storeDetail && (
            <StoreDetail
              storeDetail={storeDetail}
              integrationId={integrationId}
              updateStoreDetails={updateStoreDetails}
              showValidation={showValidation}
            />
          )}

          {/* Copy Code and embed to website Step  */}
          {activeStep === 2 && <CopyCode />}

          {/* Check Installation Step  */}
          {(activeStep === 3 || activeStep === 4) && (
            <CheckInstallation
              handleCheck={checkInstallation}
              loading={installLoading}
              success={activeStep === 4}
              hideModal={hideModal}
            />
          )}

          {/* Next Button */}
          {(activeStep === 1 || activeStep === 2) && (
            <NextButton
              handleNext={handleNext}
              // disableNext={disableNext}
            />
          )}
        </>
      )}
    </div>
  );
};

export default LiveChatToDo;
