import InfiniteScroll from "src/components/InfiniteScrollBothSide";
import HistoryUpdatedTickets from "./Children/HistoryUpdatedTickets/HistoryUpdatedTickets";
import styles from "./ChatHistory.module.scss";
import MenuDropdown from "./Children/MenuDropdown/MenuDropdown";
import HistoryInnerView from "./Children/HistoryInnerView/HistoryInnerView";
import useCombinedHistoryData from "./Children/CustomHooks/useCombinedHistoryData";
import { useAppSelector } from "src/store/store";

const ChatHistory = () => {
  const {
    historyMessages,
    historySidebar,
    fetchNextData,
    fetchStatus,
    selectedItemKey,
    setSelectedItemKey,
    customerData,
    chatId,
    selectedHistory,
    setSlectedHistory,
    scrollToRef,
    scrolledId,
    setScrolledId,
    errorMessage,
    chatHistoryISLoader,
    hasMore,
    historySidebarIds,
    historyMessagesIds,
    fetchLastMessages,
  } = useCombinedHistoryData();

  const activeCustomerInfo = useAppSelector(
    (state) => state.chatWidExhMsg.activeCustomerInfo,
  );

  return (
    <div className="d-flex flex-column flex-md-row">
      <div className={`${styles.ticketData}`}>
        {/* <MenuDropdown
          setSelectedItemKey={setSelectedItemKey}
          selectedItemKey={selectedItemKey}
        /> */}
        <InfiniteScroll
          className={`${styles.chats}`}
          loadMoreFromTop={fetchNextData}
          hasMoreTop={hasMore}
          scrollToChildRef={scrollToRef}
          scrollToDependency={[scrolledId]}
        >
          {chatHistoryISLoader
            ? chatHistoryISLoader
            : errorMessage
              ? errorMessage
              : historySidebarIds.map((conversationId) => {
                  const conversation =
                    historySidebar?.conversationData[conversationId];
                  const ref =
                    conversationId === scrolledId ? scrollToRef : undefined;
                  if (conversation) {
                    return (
                      <HistoryUpdatedTickets
                        key={conversationId}
                        coversationDetails={conversation}
                        customerData={customerData}
                        id={conversationId}
                        scrollRef={ref}
                        selectedHistory={selectedHistory}
                        setSlectedHistory={setSlectedHistory}
                      />
                    );
                  }
                })}
        </InfiniteScroll>
      </div>
      <HistoryInnerView
        historyMessages={historyMessages}
        fetchNextData={fetchNextData}
        fetchStatus={fetchStatus}
        selectedHistoryId={selectedHistory?.id ?? null}
        hasMore={hasMore}
        historyMessagesIds={historyMessagesIds}
        customerId={customerData?.customerId}
        fetchLastMessages={fetchLastMessages}
        customerData={
          activeCustomerInfo
            ? {
                id: activeCustomerInfo.customerId,
                name: activeCustomerInfo.name,
                email: activeCustomerInfo.email,
                img: activeCustomerInfo.imageURL,
                isAnonymousCustomer:
                  activeCustomerInfo.isAnonymousCustomer ?? false, //Adding flag to find ananymous user
              }
            : undefined
        }
      />
    </div>
  );
};
export default ChatHistory;
