import styles from "./Footer.module.scss";
import helpLogo from "src/assets/images/helpCenter.png";
import saufterLogo from "src/assets/images/saufter-logo.svg";

interface Props {
  show: boolean;
  footerLogo: string;
  font?: string;
  helplamaBrandingText?: string; //property to render powered by text dynamically
  nightTheme?: boolean;
}

function Footer({
  show,
  footerLogo,
  font,
  helplamaBrandingText,
  nightTheme,
}: Props) {
  return (
    <div
      className="text-center d-flex justify-content-center align-items-center p-2"
      style={{ visibility: show ? "visible" : "hidden" }}
    >
      <span className={`${nightTheme ? styles.nightThemeFooter : styles.aliceThemeFooter}`}>
        Powered by
      </span>
      <div className={`mx-1 ${nightTheme && styles.nightThemeFooterImg}`}>
        <img
          // src={footerLogo && footerLogo.trim() ? footerLogo : helpLogo}
          src={saufterLogo} //default render saufter logo in footer
          alt="logo"
          className={`${styles.footerLogo} my-auto`}
        />
      </div>
      <p
        className={`${styles.brand} ${
          nightTheme ? styles.nightThemeFooterLogo : styles.aliceThemeFooterLogo
        }`}
        style={{
          fontFamily: font ?? "Roboto", // font family
        }}
      >
        {/* dynamically render powered by text from backend */}
        {helplamaBrandingText ?? "Saufter.io"}
      </p>
    </div>
  );
}

export default Footer;
