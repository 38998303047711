import incomingCall from "src/assets/images/incomingCall.png";
import outgoingCall from "src/assets/images/outgoingCall.png";
import startedLogo from "src/assets/images/startedLogo.png";
import notPlayed from "src/assets/images/notPlayed.png";
import callBackRequestLogo from "src/assets/images/callBackRequest.jpg";
import UserAvatar from "src/components/UserAvatar";
import { CallerData } from "src/store/slices/callRecording/callRecording.declarations";
import styles from "./RecordTableRow.module.scss";
import AxiosImg from "src/components/AxiosImg";

const callerTypes: { [type: string]: { iconComp: any; color: string } } = {
  other: {
    iconComp: (
      <i
        className="fa-solid fa-phone mx-1 my-auto"
        style={{ color: "#707070", width: "10px", fontSize: "0.8em" }}
      ></i>
    ),
    color: "#707070",
  },
  outbound: {
    iconComp: (
      <img
        src={outgoingCall}
        style={{ width: "10px" }}
        className={`my-auto mx-1`}
      />
    ),
    color: "#707070",
  },
  missed: {
    iconComp: (
      <i
        className="material-icons mx-1 my-auto"
        style={{ color: "#BB0B47", fontSize: "1.2em", width: "10px" }}
      >
        &#xe0b4;
      </i>
    ),
    color: "#BB0B47",
  },
  incoming: {
    iconComp: (
      <img
        src={incomingCall}
        style={{ width: "10px" }}
        className={`my-auto mx-1`}
      />
    ),
    color: "#60BC04",
  },
  ["call back request"]: {
    iconComp: (
      <img
        src={callBackRequestLogo}
        style={{ width: "10px" }}
        className={`my-auto mx-1`}
      />
    ),
    color: "#E4734E",
  },
};

const RecordTableRow = (caller: CallerData) => {
  return (
    <div>
      <div className={`${styles.tableRow}`}>
        <div className={`${styles.headerDiv}`}>
          <span className={`${styles.callerRow} d-flex`}>
            {callerTypes[caller.callType.name.toLowerCase()].iconComp}
            <span className={`my-auto mx-1`}>{caller.caller}</span>
          </span>
        </div>
        <div className={`${styles.headerDiv}`}>
          <span
            className={`${styles.callType}`}
            style={{
              color: callerTypes[caller.callType.name.toLowerCase()].color,
            }}
          >
            {caller.callType.name}
          </span>
        </div>
        <div className={`${styles.headerDiv}`}>
          {caller.agent.profilePic ? (
            <AxiosImg
              url={caller.agent.profilePic}
              style={{ width: "16px", height: "16px" }}
              className={`rounded-circle img-fluid me-2`}
              isDirectURL={caller.agent.isPublicAttachmentUrl}
            />
          ) : (
            <UserAvatar
              name={caller.agent.name || "N A"}
              size={16}
              className={`rounded-circle img-fluid me-2`}
            />
          )}
          <span className={`${styles.agentName}`}>{caller.agent.name}</span>
        </div>
        <div className={`${styles.headerDiv}`}>
          <span className={`${styles.brandName}`}>{caller.brand.name}</span>
        </div>
        <div className={`${styles.headerDiv}`}>
          <span className={`${styles.callReceived}`}>
            <span className={`me-1`}>
              <i className="fa-solid fa-clock"></i>
            </span>
            <span>{new Date(caller.callReceivedDate).toLocaleString()}</span>
          </span>
        </div>
        <div className={`${styles.headerDiv}`}>
          {/* <div>
            <div className={`d-flex align-items-center`}>
              <div>
                <img
                  className={`me-2 ${styles.recordDiv}`}
                  src={notPlayed}
                  alt=""
                />
              </div>
              <div>
                <img
                  className={`${styles.recordingImg}`}
                  src={startedLogo}
                  alt=""
                />
              </div>
            </div>
            <div></div>
          </div>
          <div>
            <span className={`text-center ${styles.timing}`}>0.30</span>
          </div> */}
          {caller.recordedVoiceURL ? (
            <audio controls={true} className={`w-100`}>
              <source src={caller.recordedVoiceURL} />
            </audio>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};
export default RecordTableRow;
