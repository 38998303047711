import Lottie from "lottie-light-react";
import { useCallback, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Delete from "src/assets/images/deleteAutomation.png";
import liveDot from "src/assets/images/liveDot.json";
import AxiosImg from "src/components/AxiosImg";
import UserAvatar from "src/components/UserAvatar";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { IReturnIntegrationData } from "src/services/UiAutomation/ReturnExchange/getAllReturnIntegrations";
import { updateReturnIntegrationData } from "src/store/slices/initiateReturn/retrunExchange.slice";
import { useAppDispatch } from "src/store/store";
import styles from "./CompleteSetup.module.scss";
import publishReturnIntegrationService from "src/services/ReturnAutoWorkFlow/publishReturnIntegration.service";

interface Props {
  integration: IReturnIntegrationData;
  deleteIntegration: (integrationId: number) => void;
}

const CompleteSetup = ({ integration, deleteIntegration }: Props) => {
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [publishLoader, setPublishLoader] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  //helper function handle onclick of delete icon
  const handleDeleteIntegration = useCallback(() => {
    if (deleteLoader === false) {
      //show the delete loader
      setDeleteLoader(true);
      //calling the deleteIntegration prop to call the api
      deleteIntegration(parseInt(integration.integrationId + ""));
    }
  }, [deleteIntegration, deleteLoader, integration.integrationId]);

  //  handle publish and unpublish button clicks
  const handlePublishAndUnpublish = useCallback(() => {
    let publish = integration?.published ? false : true;
    if (!integration?.integrationId) {
      return;
    }
    setPublishLoader(true);
    publishReturnIntegrationService({
      integrationId: integration?.integrationId + "",
      brandId: integration.brand?.id + "",
      publish: publish,
    })
      .then((res) => {
        dispatch(
          updateReturnIntegrationData({
            integrationId: integration.integrationId,
            integration: {
              published: publish,
            },
          }),
        );
      })
      .catch((err) => {
        pushTheToast({
          text: `Failed to ${publish ? "publish" : "unpublish"}`,
          position: "top-right",
          type: "danger",
        });
      })
      .finally(() => {
        setPublishLoader(false);
      });
  }, [
    integration?.published,
    integration.integrationId,
    integration.brand?.id,
    dispatch,
  ]);

  //function to redirect on click of go to settings
  const handleGoToSettings = useCallback(() => {
    navigate(`/automations/return/${integration.integrationId}`);
  }, [integration.integrationId, navigate]);

  return (
    <div className={`${styles.integrationWrapper}`}>
      <div
        className={`d-flex flex-wrap justify-content-between align-items-center px-3 pt-3`}
      >
        <div>
          <p className="mb-1 mb-lg-2">
            <span className={`${styles.requestCategory}`}>
              Request Category:
            </span>
            <span className={`${styles.category} ms-1`}>
              {integration.RequestCategory}
            </span>
          </p>
          <p className="mb-1 mb-lg-2">
            <span className={`${styles.requestCategory}`}>
              Brand Installed on:
            </span>
            <span className={`ps-1 ${styles.category}`}>
              {integration.brand ? (
                <>
                  {/* rendering brand image here, if exist, else rendering brand name image */}
                  {integration.brand.imageURL ? (
                    <AxiosImg
                      className={`${styles.brandImage} rounded-circle`}
                      url={integration.brand.imageURL}
                    />
                  ) : (
                    <UserAvatar
                      name={integration.brand.name ?? "NA"}
                      size={20}
                    />
                  )}
                  <span className="ms-2">{integration.brand.name ?? "NA"}</span>
                </>
              ) : (
                "---"
              )}
            </span>
          </p>
        </div>
        {/*checking if integration configuration completed, if yes showing the publish/unpublish button otherwise showing complete setup info*/}
        <div className={"d-flex align-items-center"}>
          {integration.configured === false ? (
            <div>
              <span className={`d-inline-block mb-2 mb-md-0 ${styles.error}`}>
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 16 16"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098L9.05.435zM8 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path>
                </svg>
              </span>
              <span className={`ps-1 ${styles.completeSetup}`}>
                Complete setup
              </span>
            </div>
          ) : (
            <div className={`d-flex align-items-center`}>
              {integration.published === true ? (
                <div className="me-2 d-flex align-items-center">
                  {/* <div className={`${styles.greenDot}`}></div> */}
                  <span
                    style={{
                      height: 20,
                      width: 20,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Lottie animationData={liveDot}></Lottie>
                  </span>
                  <div className="ps-1">
                    <span className={`${styles.liveText}`}>Live</span>
                  </div>
                </div>
              ) : (
                <></>
              )}

              <div>
                <button
                  className={`btn btn-dark ${styles.btnSecond} d-flex align-items-center`}
                  disabled={publishLoader || deleteLoader}
                  onClick={handlePublishAndUnpublish}
                >
                  <span className={`px-2 py-1`}>
                    {/* <i className="fa-thin fa-eye"></i> */}
                    <i className="fa-regular fa-eye mx-1"></i>
                    {integration.published === true ? "Unpublish" : "Publish"}
                  </span>
                  {publishLoader ? (
                    <Spinner
                      className="ms-1"
                      animation="border"
                      color="white"
                      size="sm"
                    />
                  ) : null}
                </button>
              </div>
            </div>
          )}
          <div className="ms-0">
            <span
              className={`btn ${styles.deleteBtn} py-0 mx-0 px-0`}
              onClick={handleDeleteIntegration}
            >
              <img
                src={Delete}
                alt="Delete button"
                className={`ms-2 ${styles.deleteIntegration}`}
              />
              {deleteLoader === true && (
                <Spinner
                  animation="border"
                  color="white"
                  size="sm"
                />
              )}
            </span>
          </div>
        </div>
      </div>
      <div
        className={`${styles.settingBtn} d-flex justify-content-between px-3 py-2`}
        onClick={handleGoToSettings}
      >
        <span className={`${styles.settingText}`}>Go to settings</span>
        <span className={`pe-2 ${styles.arrow}`}>
          <i className="fa-solid fa-chevron-right"></i>
        </span>
      </div>
    </div>
  );
};

export default CompleteSetup;
