import { useCallback } from "react";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "src/store/store";
import { Swiper as ISwiper } from "swiper";
import { useCreateReturnIntegration } from "../../CustomHooks/useCreateReturnIntegration";
import styles from "./NextStepButton.module.scss";

function NextStepButton(props: {
  isVisible: boolean;
  swiper: ISwiper | null;
  hide: boolean;
}) {
  const { stepData, selectedBrandId } = useAppSelector(
    (state) => state.returnExchange.step7,
  );

  const navigate = useNavigate();

  const handleSuccessCallback = useCallback(
    (integrationId: number) => {
      navigate(`/automations/return/${integrationId}`);
    },
    [navigate],
  );

  const { addNewIntegration, loading } = useCreateReturnIntegration({
    automateType:
      stepData.fullyAutoMate === true ? "fully_automate" : "partially_automate",
    brandId: selectedBrandId,
    successCallback: handleSuccessCallback,
  });

  return (
    <>
      {props.hide === true ? (
        <button
          className={`${styles.nextStepButton} `}
          disabled={loading}
          onClick={() => addNewIntegration()}
        >
          <div className="">
            <span className={`${styles.goBackText}`}>
              {loading === true ? (
                <Spinner
                  className="my-auto mx-1"
                  animation="border"
                  color="white"
                  size="sm"
                />
              ) : (
                "Start Automating"
              )}
            </span>
          </div>
        </button>
      ) : (
        <button
          className={`${styles.nextStepButton} ${styles.small} px--md-3`}
          disabled={!props.isVisible}
          onClick={() => props.swiper?.slideNext()}
        >
          <div className="">
            <span className={`${styles.goBackText}`}>Next Step</span>
          </div>
        </button>
      )}
    </>
  );
}

export default NextStepButton;
