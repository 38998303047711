import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "src/store/store";
import { AjaxError } from "src/types/AjaxError";
import TicketService from "src/services/TicketService/TicketService";
import {
  getBulkSelectedTicketIds,
  getTicketListFilterQuery,
} from "src/store/slices/tickets/tickets.selectors";
import { SendBulkMessagePayload } from "src/services/TicketService/sendBulkMessage";
import { setBulkUpdatedTicketCount } from "../tickets/tickets.slice";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { FreePlanError, FreePlanErrorMessage } from "src/globals/constants";
import { TicketIntegrationFailure } from "src/types/IntegrationFailure";

type TicketSendMessageSlice = {
  message: string;
  bulkSendMessageState: "idle" | "pending" | "fulfilled" | "rejected";
  bulkSendMessageIds: Array<string>;
  integrationFailureTickets: TicketIntegrationFailure[] | null;
  integrationFailureTicketsCount: number;
};

const initialState: TicketSendMessageSlice = {
  message: "",
  bulkSendMessageState: "idle",
  bulkSendMessageIds: [],
  integrationFailureTickets: null,
  integrationFailureTicketsCount: 0,
};

export const bulkSendMessage = createAsyncThunk(
  "ticket/bulkSendMessage",
  async (
    payload: undefined,
    {
      getState,
      dispatch,
      rejectWithValue,
    }: { getState: Function; dispatch: Function; rejectWithValue: any },
  ) => {
    try {
      const currentState: RootState = getState();
      const servicePayload: SendBulkMessagePayload = {
        ticketIds: getBulkSelectedTicketIds(currentState),
        message: currentState.ticketSendMessage.message,
      };

      //checking if all tickets selected in bulk action
      if (currentState.ticket.bulkActionFilters.selectedAllTickets === true) {
        //deleting the ticketIds list from payload
        delete servicePayload["ticketIds"];

        //setting allTickets flag to true
        servicePayload["allTickets"] = true;

        const ticketsQuery = getTicketListFilterQuery(currentState);
        //adding ticket filters to payload
        servicePayload["filters"] = ticketsQuery["filters"];

        //adding flag in payload if it is a customer-view or not
        servicePayload["isCustomerView"] =
          currentState.ticket.currentView === "customer-view";
      }
      // Reset integration failure data before sending message
      dispatch(resetIntegrationFailureData());

      const bulkSendMessageResponse =
        await TicketService.sendBulkMessage(servicePayload);

      //checking if response has updated ticket count
      if (bulkSendMessageResponse?.metaData?.messagedTicketsCount) {
        //setting the updated ticket count in ticket slice to show it on modal
        dispatch(
          setBulkUpdatedTicketCount({
            count: bulkSendMessageResponse?.metaData?.messagedTicketsCount,
          }),
        );
      } else {
        //setting the count to 0, to avoid showing incorrect counts
        dispatch(setBulkUpdatedTicketCount({ count: 0 }));
      }
      //dispatching updated ticket count to show on the success modal
      return bulkSendMessageResponse;
    } catch (err: any) {
      if (
        typeof err == "object" &&
        (err.statusCode === FreePlanError.LIMIT_REACHED ||
          err.statusCode === FreePlanError.RESTRICTED)
      ) {
        const statusCode = err.statusCode as keyof typeof FreePlanErrorMessage;
        // If the free plan limit is exceeded, show free plan error
        pushTheToast({
          text: err.message ?? FreePlanErrorMessage[statusCode],
          type: "danger",
          position: "top-right",
        });

        throw err;
      } else if (
        typeof err == "object" &&
        err.statusCode === "integrationFailure"
      ) {
        return rejectWithValue(err);
      } else {
        let ajaxError: AjaxError = {
          message: (err as Error).message,
        };
        return ajaxError;
      }
    }
  },
);

export const ticketSendMessageSlice = createSlice({
  name: "ticketTags",
  initialState,
  reducers: {
    setMessage: (state, action: PayloadAction<string>) => {
      const message = action.payload;
      state.message = message;
    },
    resetBulkSendMessageState: (state) => {
      state.bulkSendMessageState = "idle";
    },
    resetbulkSendMessage: (state) => {
      state.message = "";
      state.bulkSendMessageState = "idle";
    },
    // To reset integration failure data , before sending bulk message again
    resetIntegrationFailureData: (state) => {
      state.integrationFailureTickets = [];
      state.integrationFailureTicketsCount = 0;
    },
  },
  extraReducers: (builder) => {
    /* 
    Reducers for bulk action for assigning tickets user
    */
    builder.addCase(bulkSendMessage.fulfilled, (state, { payload }) => {
      // console.log("bulkSendMessage fulfilled payload:: ", payload);
      state.bulkSendMessageIds = payload.data.messagedTicketsId;
      state.bulkSendMessageState = "fulfilled";
    });
    builder.addCase(bulkSendMessage.rejected, (state, action) => {
      const payload = action.payload as any;
      state.bulkSendMessageState = "rejected";
      if (
        typeof payload === "object" &&
        payload?.statusCode === "integrationFailure"
      ) {
        state.integrationFailureTickets = payload?.failedTickets;
        // Assigning integration failure tickets count
        state.integrationFailureTicketsCount = payload?.failedTicketCount;
      }
    });
    builder.addCase(bulkSendMessage.pending, (state) => {
      state.bulkSendMessageState = "pending";
    });
  },
});

export const {
  setMessage,
  resetBulkSendMessageState,
  resetbulkSendMessage,
  resetIntegrationFailureData,
} = ticketSendMessageSlice.actions;

// export const getSelectedUserSelector = createSelector(
//   [getSelectedUser], getSelectedUser => (getSelectedUser)
// );

export default ticketSendMessageSlice.reducer;
