import { ITicketSideBarState } from "./ticketSidebar.slice";
import { addUsersViewInTicketBtnData } from "src/services/SideBar/utils";
import { ITicketBtn } from "src/containers/SideBar/children/SubSB/SubSB";
import { uniqueArray } from "src/utils/utils";
import { ISidebarTicketsCountRes } from "src/services/SideBar/getTicketsCountInSideBar";
import { ListView, ListViewResponse } from "src/services/SideBar/getSubSB";

const fetchSideBarDataFulfilled = (
  state: ITicketSideBarState,
  {
    payload,
  }: {
    payload: {
      ticketBtnData: Array<ITicketBtn>;
      userViews: ListViewResponse | null;
      viewById: ListView | null;
    };
  }
) => {
  state.ticketBtnData = payload.ticketBtnData;
  state.ajaxStatus = "fulfilled";
  state.selectedBtn = state.selectedBtn ?? "all";
  // set all tickets count to show in main sidebar ticket button
  let allTicketCount = 0;
  try {
    allTicketCount =
      payload.ticketBtnData[0].ticketBtnList[0].ddOuterList[1].ddList[4].count;
  } catch {}
  state.mainSideBarTotalTicketCount = allTicketCount;
  state.usersViewData = {};
  state.usersViewList = [];
  state.usersViewTotal = 0;
  if (payload.viewById) {
    state.usersViewData[payload.viewById.viewId] = payload.viewById;
    state.usersViewList.push(payload.viewById.viewId);
    state.usersViewListFlag = -1;
  }
  if (payload.userViews) {
    fetchSideBarUsersViewsFulfilled(state, { payload: payload.userViews });
  }
};

const fetchAddedViewSideBarDataFulfilled = (
  state: ITicketSideBarState,
  {
    payload,
  }: {
    payload: any;
  }
) => {
  if (payload && state.selectedBtn) {
    state.usersViewData[payload.viewId] = payload;
    state.usersViewList.unshift(payload.viewId);
    state.usersViewListFlag = -1;
    state.usersViewTotal += 1;
    addUsersViewInTicketBtnData(state);
  }
};
const fetchSideBarUsersViewsFulfilled = (
  state: ITicketSideBarState,
  { payload }: { payload: ListViewResponse }
) => {
  state.usersViewAjaxStatus = "fulfilled";
  state.usersViewData = {
    ...state.usersViewData,
    ...payload.usersViewData,
  };
  state.usersViewList = uniqueArray([
    ...state.usersViewList,
    ...payload.usersViewList,
  ]).map((viewId) => parseInt(viewId + ""));
  state.usersViewTotal = payload.usersViewTotal;
  addUsersViewInTicketBtnData(state);
};

/**
 * Reducer for refreshing user views state
 */
const refreshSideBarUsersViewsFulfilled = (
  state: ITicketSideBarState,
  { payload }: { payload: ListViewResponse }
) => {
  state.usersViewAjaxStatus = "fulfilled";
  state.usersViewData = payload.usersViewData;
  state.usersViewList = uniqueArray(payload.usersViewList).map((viewId) =>
    parseInt(viewId + "")
  );
  state.usersViewTotal = payload.usersViewTotal;
  addUsersViewInTicketBtnData(state);
};

const countMSDAllMy = (countData: ISidebarTicketsCountRes) => {
  if (countData.content) {
    let contentsWithAllCount = [];
    for (let i = 0; i < countData.content.length; i++) {
      let dropdown = countData.content[i];
      let dropdownMenuAllCount = 0;
      Object.keys(dropdown.content).forEach((key: any) => {
        dropdownMenuAllCount += dropdown.content[key].count;
      });
      contentsWithAllCount.push({
        name: dropdown.name,
        content: {
          0: { name: "All", count: dropdownMenuAllCount },
          ...dropdown.content,
        },
      });
    }

    return {
      name: countData.name,
      count: countData.count,
      content: contentsWithAllCount,
    };
  } else {
    return null;
  }
};

// fulfilled case for fetchMultipleSidebarDataFulfilled
const fetchMultipleSidebarDataFulfilled = (
  state: ITicketSideBarState,
  { payload }: { payload: { [key: string]: ISidebarTicketsCountRes } }
) => {
  if (state.ticketBtnData.length) {
    // for each main sidebar buttons like ticket and your views
    for (let i = 0; i < state.ticketBtnData.length; i++) {
      if (
        state.ticketBtnData[i].ticketBtnList &&
        state.ticketBtnData[i].ticketBtnList.length
      ) {
        // for each buttons in ticket button data like all tickets and my tickets
        for (let j = 0; j < state.ticketBtnData[i].ticketBtnList.length; j++) {
          let dropdownBtnHead: any = state.ticketBtnData[i].ticketBtnList[j];

          if (payload[dropdownBtnHead.keyName] === undefined) {
            continue;
          }
          // if type view, update the ticket button count
          if (dropdownBtnHead.keyName.includes("viewId::")) {
            state.usersViewData[
              dropdownBtnHead.keyName.split("::")[1]
            ].viewsTicketCount =
              payload[dropdownBtnHead.keyName].viewsTicketCount ?? 0;
            dropdownBtnHead.ticketBtnCount =
              payload[dropdownBtnHead.keyName].viewsTicketCount ?? 0;
          } else {
            // for my tickets and all tickets, update the ticket button count
            const theData = countMSDAllMy(payload[dropdownBtnHead.keyName]);
            dropdownBtnHead.ticketBtnCount = theData?.count ?? 0;
            // if there is any dropdown
            if (
              dropdownBtnHead.ddOuterList &&
              dropdownBtnHead.ddOuterList.length
            ) {
              // for each dropdown like channel, ticket status, draft status
              for (let k = 0; k < dropdownBtnHead.ddOuterList.length; k++) {
                let dropdownItemsContentFromPayload: any =
                  theData?.content?.filter((payloadDdBtn: any) => {
                    return (
                      payloadDdBtn.name ===
                      dropdownBtnHead.ddOuterList[k].ddName
                    );
                  });
                if (dropdownItemsContentFromPayload?.length) {
                  // need to consider [0] means all count
                  dropdownBtnHead.ddOuterList[k].ddList[0].count =
                    theData?.count ?? 0;
                  // for each dropdown item
                  Object.keys(dropdownBtnHead.ddOuterList[k].ddList).forEach(
                    (dropdownItemId: any) => {
                      if (
                        dropdownItemsContentFromPayload[0].content &&
                        dropdownItemsContentFromPayload[0].content[
                          dropdownItemId
                        ] !== undefined
                      ) {
                        dropdownBtnHead.ddOuterList[k].ddList[
                          dropdownItemId
                        ].count =
                          dropdownItemsContentFromPayload[0].content[
                            dropdownItemId
                          ].count;
                      } // end of if
                    }
                  ); // end of map
                } // end of dropdownItemsContentFromPayload.length
              } // end for:k
            } // if ddOuterList.length
          } // else of dropdownBtnHead.keyName.includes("viewId::")
        } // end of for: j
      } // end of if
    } // end of for: i
    // set all tickets count to show in main sidebar ticket button
    let allTicketCount = 0;
    try {
      allTicketCount =
        state.ticketBtnData[0].ticketBtnList[0].ddOuterList[1].ddList[4].count;
    } catch {}
    // state.mainSideBarTotalTicketCount = payload.count ?? payload.viewsTicketCount ;
    state.mainSideBarTotalTicketCount = allTicketCount;
    if (payload.mentions) {
      state.unreadMentionsCount = payload.mentions.unreadMentionsCount ?? 0;
    }
    if (payload.ai_closed) {
      state.aiClosedTicketCount = payload.ai_closed.totalTicketCount ?? 0;
    }
    if (payload.ai_open) {
      state.aiOpenTicketCount = payload.ai_open.totalTicketCount ?? 0;
    }
  } // end of if  state.ticketBtnData.length
  return state;
};

const refetchSideBarDataFulfilled = (
  state: ITicketSideBarState,
  {
    payload,
  }: {
    payload: {
      ticketBtnData: Array<ITicketBtn>;
      // userViews: ListViewResponse | null;
      // viewById: ListView | null;
    };
  }
) => {
  state.ticketBtnData[0] = payload.ticketBtnData[0];
  // state.ajaxStatus = "fulfilled";
  // state.selectedBtn = state.selectedBtn ?? "all";
  // set all tickets count to show in main sidebar ticket button
  // let allTicketCount = 0;
  // try {
  //   allTicketCount =
  //     payload.ticketBtnData[0].ticketBtnList[0].ddOuterList[1].ddList[4].count;
  // } catch {}
  // state.mainSideBarTotalTicketCount = allTicketCount;
  // state.usersViewData = {};
  // state.usersViewList = [];
  // state.usersViewTotal = 0;
  // if (payload.viewById) {
  //   state.usersViewData[payload.viewById.viewId] = payload.viewById;
  //   state.usersViewList.push(payload.viewById.viewId);
  //   state.usersViewListFlag = -1;
  // }
  // if (payload.userViews) {
  //   fetchSideBarUsersViewsFulfilled(state, { payload: payload.userViews });
  // }
};
export default {
  fetchSideBarUsersViewsFulfilled,
  fetchSideBarDataFulfilled,
  fetchMultipleSidebarDataFulfilled,
  fetchAddedViewSideBarDataFulfilled,
  refreshSideBarUsersViewsFulfilled,
  refetchSideBarDataFulfilled,
};
