import styles from "./Analytics.module.scss";
import caution from "src/assets/images/caution.png";
import logo from "src/assets/images/helpCenter.png";
import img1 from "src/assets/images/seo.png";
import img2 from "src/assets/images/graph.png";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Loader from "src/components/Loader";
import useAnalytics from "./useAnalytics";
import loadingGif from "src/assets/images/loading.gif";
import AxiosImg from "src/components/AxiosImg";
import arrowRight from "src/assets/images/arrowRight.png";

const Analytics = () => {
  const {
    dropdown,
    setDropDown,
    seo,
    setSeo,
    graph,
    setGraph,
    isLoading,
    isAnalyticsEnabled,
    handleIsAnalyticsEnabled,
    isVersion4,
    handleChangeIsVersion4,
    handleChangeOldVersion,
    trackingId,
    handleChangeTrackingId,
    showKBPublicSearch,
    handleShowKBPublicSearch,
    pageTitle,
    handleChangePageTitle,
    metaDescription,
    handleChangeMetaDescription,
    openGraphTitle,
    handleChangeOpenGraphTitle,
    openGraphDescription,
    handleChangeOpenGraphDescription,
    handleSaveChanges,
    handleReplaceImage,
    handleRevertImage,
    updateAnalytics,
    data,
    replaceOrRevertOpenGraphImageData,
    openGraphImage,
  } = useAnalytics();

  // Popover for graph
  const popover = (
    <Popover.Body>
      <div className={`${styles.popWrapper}`}>
        <img
          src={img2}
          alt=""
          className=""
        />
      </div>
    </Popover.Body>
  );

  // Popover for seo
  const SeoPopover = (
    <Popover.Body>
      <div className={`${styles.popWrapper}`}>
        <img
          src={img1}
          alt=""
          className=""
        />
      </div>
    </Popover.Body>
  );

  return (
    <div className={`w-100 pt-4 px-3 ${styles.analyticsWrapper}`}>
      {/* Header section */}
      <div className="mb-4 pb-2 d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center">
        <div>
          <h3 className={`mb-0 ${styles.heading} saufter_h3`}>
            Domain and Acess / Analytics & SEO
          </h3>
          <p className={`mb-0 ${styles.subHeading} saufter_subHeading`}>
            Let customers style the help center right inside your chat widget
            instead of starting a conversation.
          </p>
        </div>
        {/* Save Changes and Delete Button */}
        <div className="d-flex mt-2 mt-md-0 align-items-center">
          <div
            className={`${styles.delBox} d-none `}
            aria-disabled={true}
          >
            <i className="fa-solid fa-trash"></i>
          </div>
          <button
            className={`ms-1 px-3 d-flex align-items-center justify-content-center ${styles.saveBtn}`}
            onClick={handleSaveChanges}
            disabled={updateAnalytics.isLoading}
          >
            {updateAnalytics.isLoading ? "Saving..." : "Save Changes"}
          </button>
        </div>
      </div>

      {/* Loading Check  */}
      {isLoading ? (
        <>
          <div className={`${styles.loaderWrapper}`}>
            <Loader />
          </div>
        </>
      ) : (
        <>
          <p className={`mb-3 ${styles.head}`}>Analytics & SEO Settings</p>
          <div
            className={`d-flex justify-content-between align-items-center px-2 saufter_medium_h4 ${
              styles.dropBtn
            } ${dropdown && styles.borderSet}`}
            onClick={() => setDropDown(!dropdown)}
          >
            <span>Analytics & SEO</span>
            <span className={`${styles.arrow}`}>
              {dropdown ? (
                <img
                  src={arrowRight}
                  alt=""
                  className={`${styles.arrowDown}`}
                />
              ) : (
                <img
                  src={arrowRight}
                  alt=""
                />
              )}
            </span>
          </div>

          {/* Dropdown Body */}
          {dropdown ? (
            <div className={`p-2 p-md-3 mb-2 ${styles.dropDown}`}>
              <div className="">
                <p className={`mb-0 ${styles.accessName} saufter_h5`}>
                  Google Analytics
                </p>
                {/* Google Analytics Checkboxes and Input Boxes */}
                <div className="form-check mb-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                    checked={isAnalyticsEnabled}
                    onChange={handleIsAnalyticsEnabled}
                  />
                  <div>
                    <label
                      className={`form-check-label ${styles.formLabel} saufter_h6`}
                      htmlFor="flexCheckDefault"
                    >
                      Enable Google Analytics tracking on my Knowledge base
                    </label>
                    <div className="form-check">
                      <input
                        className={`form-check-input `}
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                        checked={isVersion4 === "flexRadioDefault1"}
                        onChange={handleChangeIsVersion4}
                        disabled={!isAnalyticsEnabled}
                      />
                      <label
                        className={`form-check-label ${styles.formLabel} saufter_h6`}
                        htmlFor="flexRadioDefault1"
                      >
                        I am using the new Google Analytics 4 version
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        checked={isVersion4 === "flexRadioDefault2"}
                        onChange={handleChangeOldVersion}
                        disabled={!isAnalyticsEnabled}
                      />
                      <label
                        className={`form-check-label ${styles.formLabel} saufter_h6`}
                        htmlFor="flexRadioDefault2"
                      >
                        I am using the older Google Analytics version
                      </label>
                    </div>
                  </div>
                </div>

                {/* Trackig Id Input Box  */}
                <div className="mb-3">
                  <p className={`mb-2 ${styles.accessName} saufter_h5`}>
                    Tracking ID
                  </p>

                  <div className={`position-relative`}>
                    <input
                      type="text"
                      className={`${styles.idBox}`}
                      value={trackingId}
                      onChange={handleChangeTrackingId}
                    />
                    <span className={`${styles.idBtn}`}>UA-</span>
                  </div>
                </div>

                {/* SEO and Open Graph Data */}

                {/* SEO Settings */}
                <div
                  className={`d-flex justify-content-between align-items-center px-2 ${
                    styles.seoBtn
                  } ${seo && styles.borderSet}`}
                  onClick={() => setSeo(!seo)}
                >
                  <div>
                    <p
                      className={`mb-1 d-flex align-items-center ${styles.seoHead} saufter_medium_h4`}
                    >
                      <span> SEO settings</span>
                      <span>
                        <OverlayTrigger
                          placement="right-end"
                          overlay={SeoPopover}
                        >
                          <img
                            src={caution}
                            alt=""
                            className={`ms-3 ${styles.cautionImg}`}
                          />
                        </OverlayTrigger>
                      </span>
                    </p>
                    <span className={`${styles.seoSpan} saufter_h6`}>
                      Configure the SEO settings & meta data that will be
                      displayed for in search listings.
                    </span>
                  </div>
                  <span className={`${styles.arrow}`}>
                    {seo ? (
                      <img
                        src={arrowRight}
                        alt=""
                        className={`${styles.arrowDown}`}
                      />
                    ) : (
                      <img
                        src={arrowRight}
                        alt=""
                      />
                    )}
                  </span>
                </div>
                {seo ? (
                  <div className={`p-2 p-md-3 mb-2 ${styles.dropDown}`}>
                    {/* Show my knowledge base in public search results  */}
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="defaultCheck1"
                        checked={showKBPublicSearch}
                        onChange={handleShowKBPublicSearch}
                      />
                      <label
                        className={`form-check-label ${styles.searchLabel} saufter_h5`}
                        htmlFor="defaultCheck1"
                      >
                        Show my knowledge base in public search results
                      </label>
                    </div>

                    {/* Page Title  */}
                    <div className="mb-3">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className={`form-label ${styles.metaLabel} saufter_h5`}
                      >
                        Page Title
                      </label>
                      <input
                        type="text"
                        className={`form-control ${styles.pageInput}`}
                        id="text"
                        placeholder="Demo"
                        value={pageTitle}
                        onChange={handleChangePageTitle}
                      />
                    </div>

                    {/* Meta Description  */}
                    <div className="mb-3">
                      <label
                        htmlFor="text"
                        className={`form-label ${styles.metaLabel} saufter_h5`}
                      >
                        Meta Description
                      </label>
                      <textarea
                        className={`form-control ${styles.textAreaBox}`}
                        id="exampleFormControlTextarea1"
                        rows={3}
                        placeholder="192.01.07.234"
                        value={metaDescription}
                        onChange={handleChangeMetaDescription}
                      ></textarea>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {/* Open Graph Data */}
                <div
                  className={`d-flex justify-content-between align-items-center px-2 mt-3 ${
                    styles.seoBtn
                  } ${graph && styles.borderSet}`}
                  onClick={() => setGraph(!graph)}
                >
                  <div>
                    <p
                      className={`mb-1 d-flex align-items-center ${styles.seoHead} saufter_medium_h4`}
                    >
                      <span> Open graph data</span>
                      <span>
                        <OverlayTrigger
                          placement="right-end"
                          overlay={popover}
                        >
                          <img
                            src={caution}
                            alt=""
                            className={`ms-3 ${styles.cautionImg}`}
                          />
                        </OverlayTrigger>
                      </span>
                    </p>
                    <span className={`${styles.seoSpan} saufter_h6`}>
                      Configure the information displayed when sharing your
                      Knowledge Base on social media.
                    </span>
                  </div>
                  <span className={`${styles.arrow}`}>
                    {graph ? (
                      <img
                        src={arrowRight}
                        alt=""
                        className={`${styles.arrowDown}`}
                      />
                    ) : (
                      <img
                        src={arrowRight}
                        alt=""
                      />
                    )}
                  </span>
                </div>
                {graph ? (
                  <div className={`p-2 p-md-3 mb-2 ${styles.dropDown}`}>
                    {/* Open Graph Title */}
                    <div className="mb-3">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className={`form-label ${styles.metaLabel} saufter_h5`}
                      >
                        Open Graph Title
                      </label>
                      <input
                        type="text"
                        className={`form-control ${styles.pageInput}`}
                        id="text"
                        placeholder="Enter open graph title"
                        value={openGraphTitle}
                        onChange={handleChangeOpenGraphTitle}
                      />
                    </div>
                    {/* Open Graph Description */}
                    <div className="mb-3">
                      <label
                        htmlFor="text"
                        className={`form-label ${styles.metaLabel} saufter_h5`}
                      >
                        Open Graph Description
                      </label>
                      <textarea
                        className={`form-control ${styles.textAreaBox}`}
                        id="exampleFormControlTextarea1"
                        rows={3}
                        placeholder="Enter open graph description"
                        value={openGraphDescription}
                        onChange={handleChangeOpenGraphDescription}
                      ></textarea>
                    </div>
                    {/* Open Graph Image */}
                    <div className="mb-1">
                      <label
                        htmlFor="text"
                        className={`form-label mb-0 ${styles.metaLabel} saufter_h5`}
                      >
                        Open Graph Image
                      </label>
                      <span className={`d-block mb-1 ${styles.showText}`}>
                        This is the image shown with the shared content.
                      </span>
                      <div
                        className={`d-flex justify-content-center align-items-center ${styles.imgWrapper} `}
                      >
                        {openGraphImage && openGraphImage !== null ? (
                          <AxiosImg
                            url={openGraphImage}
                            className={`${styles.logo}`}
                          />
                        ) : (
                          <img
                            src={logo}
                            alt=""
                            className={`${styles.logo}`}
                          />
                        )}
                      </div>
                    </div>

                    {/* Replace and Revert Button */}
                    <div>
                      <p className="mb-1">
                        <span
                          className={`${styles.replace}`}
                          onClick={handleReplaceImage}
                          role="button"
                        >
                          Replace
                        </span>
                        <span
                          className={`ps-2 ${styles.revert} ${
                            replaceOrRevertOpenGraphImageData.isLoading ||
                            !openGraphImage
                              ? styles.disabled
                              : ""
                          } `}
                          role="button"
                          onClick={handleRevertImage}
                        >
                          Revert
                        </span>
                      </p>
                      <span className={`${styles.support} saufter_h6`}>
                        Supported image types: JPEG, PNG.
                      </span>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
};

export default Analytics;
