import { axiosJSON } from "src/globals/axiosEndPoints";

interface Params {
  emailIntegrationId: number;
}

export async function addEmailDomainService(params: Params) {
  const { data } = await axiosJSON.post(
    `/api/setting/integration/addEmailDomain`,
    params
  );

  if (data.err === true || data.error === true) {
    throw data.msg ?? data.message ?? "Some error occured";
  }
}
