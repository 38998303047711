import { useEffect, useRef, useState } from "react";
import { setAutomateType } from "src/store/slices/initiateReturn/retrunExchange.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { useSwiper } from "swiper/react";
import styles from "./Step3.module.scss";

function Step3({ setHideBtn }: any) {
  const dispatch = useAppDispatch();
  const { fullyAutoMate, partiallyAutomate } = useAppSelector(
    (state) => state.returnExchange.step7.stepData,
  );

  const fullyAutomateInputRef = useRef<HTMLInputElement | null>(null);
  const partiallyAutomateInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (fullyAutomateInputRef.current) {
      fullyAutomateInputRef.current.checked = fullyAutoMate;
    }
    if (partiallyAutomateInputRef.current) {
      partiallyAutomateInputRef.current.checked = partiallyAutomate;
    }
  }, [fullyAutoMate, partiallyAutomate]);

  const swiper = useSwiper();
  const [activeIndex, setActiveIndex] = useState(-1);

  swiper.on("slideChange", (swiper) => {
    setActiveIndex(swiper.activeIndex);
  });

  // fetch the step data
  useEffect(() => {
    if (activeIndex === 2) {
      setHideBtn(true);
    } else {
      setHideBtn(false);
    }
  }, [activeIndex, setHideBtn]);

  if (activeIndex === 2) {
    return (
      <>
        <div
          className={`${styles.stepsContent}  justify-content-center  align-items-center`}
        >
          <p className="d-block">
            <p className={`${styles.step3Text}`}>
              How automated would you like the Return/exchange requests to be:
            </p>

            <div
              className={`my-3 mx-2 mx-md-2 mx-lg-5 mt-5 position-relative ${
                styles.choseBox
              } ${styles.activeBox}
           ${fullyAutoMate === true ? styles.activeDiv : ""}   
          `}
              onClick={(e) => {
                dispatch(
                  setAutomateType({
                    automationType: "fullyAutomate",
                  }),
                );
              }}
            >
              <div className={`ps-3 pe-2 ${styles.recommended}`}>
                <span>Recommended</span>
              </div>
              <div className={`d-flex mb-0`}>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    style={{ fontSize: "13px" }}
                    // checked={status === "fully"}
                    // onClick={(e) => setStatus("fully")}
                    ref={fullyAutomateInputRef}
                    onChange={(e) => {
                      dispatch(
                        setAutomateType({
                          automationType: "fullyAutomate",
                        }),
                      );
                    }}
                    checked={fullyAutoMate}
                  />
                </div>
                <div className="ms-2">
                  <h5 className={`${styles.always}`}>Fully Automate</h5>
                  <div>
                    <p className={`${styles.choseBoxPara}`}>
                      You will be able to automate all stages of the returns
                      process.
                    </p>
                    {/* Hidden due to exclusion */}
                    <p className={`${styles.view} d-none`}>View demo</p>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`mb-2 mb-md-5 my-3 mx-2 mx-md-2 mx-lg-5 mt-0  ${
                styles.choseBox
              } ${styles.activeBox} 
           ${partiallyAutomate === true ? styles.activeDiv : ` `}`}
              onClick={(e) => {
                dispatch(
                  setAutomateType({
                    automationType: "partial",
                  }),
                );
              }}
            >
              <div className={`d-flex mb-0`}>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    style={{ fontSize: "13px" }}
                    // checked={status === "Partially"}
                    // onClick={(e) => setStatus("Partially")}
                    ref={partiallyAutomateInputRef}
                    onChange={(e) => {
                      dispatch(
                        setAutomateType({
                          automationType: "partial",
                        }),
                      );
                    }}
                    checked={partiallyAutomate}
                  />
                </div>
                <div className="ms-2">
                  <h5 className={`${styles.always}`}>Partially Automate</h5>
                  <div>
                    <p className={`${styles.choseBoxPara}`}>
                      If you choose this, at some point, the ticket will be
                      transferred to your internal team for approval, before the
                      final resolution is provided.
                    </p>
                    {/* Hidden due to exclusion */}
                    <p className={`${styles.view} d-none`}>View demo</p>
                  </div>
                </div>
              </div>
            </div>
          </p>
        </div>
      </>
    );
  } else {
    return null;
  }
}

export default Step3;
