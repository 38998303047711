import { axiosJSON } from "src/globals/axiosEndPoints";

interface CustomFilter {
  conditionFilter: string[];
  groupLogicalOperator: string | null;
}

interface CustomeFiltersData {
  customFilters: CustomFilter[];
  numberOfFilters: number;
}

/**
 * Retrieves the custom segment filters for a given segment type.
 *
 * @param payload.segmentType The type of customer (company, people, reviews, accounts).
 * @param payload.segmentId The segment id of the active segment.
 *
 * @returns A promise that resolves to the segment filters response.
 * @throws If an error occurs during the API request or if the response contains an error message.
 */
const getCustomFiltersService = async ({
  segmentType,
  ...payload
}: {
  segmentType: string;
  segmentId: string;
}): Promise<CustomeFiltersData> => {
  const { data: res } = await axiosJSON.post(
    `/api/segment/${segmentType}/getCustomFilters`,
    payload,
  );

  if (res.error || res.err) {
    throw new Error(res.msg ?? res.message ?? "Something Unexpected Occured!");
  }

  const ret: CustomeFiltersData = {
    customFilters: res.data ?? [],
    numberOfFilters: res.metaData?.numberOfFilters ?? res.data?.length ?? 0,
  };

  return ret;
};

export default getCustomFiltersService;
