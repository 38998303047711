import { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Conversation } from "src/services/LiveChat/liveChatHistory/getLiveChatHistoryConversation.service";
import { fetchCustomerDataByChatId } from "src/store/slices/liveChatSetting/chatWidExchCust/chatWidExchCust.thunks";
import { useAppDispatch, useAppSelector } from "src/store/store";
import useChatHistoryISLoader from "../ChatHistoryISLoader/ChatHistoryISLoader";
import useErrorMessage from "../ErrorMessage/errorMessage";
import styles from "../../ChatHistory.module.scss";
import useCombinedHistory from "./useCombinedHistory";
import { actions } from "src/store/slices/liveChatSetting/chatWidExchCust/chatWidExchCust.slice";

function useCombinedHistoryData() {
  const dispatch = useAppDispatch();
  const [selectedItemKey, setSelectedItemKey] = useState("newestFirst" as any);
  const customerData = useAppSelector(
    (state) => state.chatWidExhMsg.activeCustomerInfo
  );
  const { chatId } = useParams();
  const [selectedHistory, setSlectedHistory] = useState(
    null as null | Conversation
  );
  const scrollToRef = useRef(null as any);
  const [scrolledId, setScrolledId] = useState(null as null | string);

  const filters = useMemo(() => {
    return {
      customerId: customerData?.customerId,
      filters: {
        sortBy: selectedItemKey,
      },
      start: 0,
      limit: 10,
    };
  }, [selectedItemKey, customerData?.customerId]);

  const {
    historyMessages,
    historySidebar,
    fetchNextData,
    fetchStatus,
    fetchLastMessages,
  } = useCombinedHistory(filters);

  const hasMore = useMemo(() => {
    if (historySidebar) {
      return !historySidebar.metaData.limitReached;
    }
    return false;
  }, [historySidebar]);

  const historySidebarIds = useMemo(() => {
    setScrolledId((prev) => {
      if (prev === null) {
        return historySidebar?.conversationDataIds[0] ?? null;
      } else {
        return (
          historySidebar?.conversationDataIds[
            historySidebar.conversationDataIds.length -
              historySidebar.metaData.currentCount -
              1
          ] ?? null
        );
      }
    });
    const data = [...(historySidebar?.conversationDataIds ?? [])];
    if (selectedItemKey === "newestFirst") {
      return [...data].reverse();
    } else {
      return data;
    }
  }, [historySidebar, selectedItemKey]);
  const historyMessagesIds = useMemo(() => {
    const data = [...(historyMessages?.messageIds ?? [])];
    if (selectedItemKey === "newestFirst") {
      return [...data].reverse();
    } else {
      return data;
    }
  }, [historyMessages, selectedItemKey]);

  const errorMessage = useErrorMessage(
    fetchStatus === "rejected" ||
      (fetchStatus !== "pending" && historySidebarIds.length === 0),
    styles.nothingFound
  );
  const chatHistoryISLoader = useChatHistoryISLoader(
    fetchStatus === "pending",
    undefined,
    styles.loading
  );

  useEffect(() => {
    if (chatId) {
      dispatch(actions.setActiveChatId({ id: parseInt(chatId) }));
      dispatch(fetchCustomerDataByChatId({ payload: chatId }));
    }
  }, [chatId]);

  return {
    historyMessages,
    historySidebar,
    fetchNextData,
    fetchStatus,
    selectedItemKey,
    setSelectedItemKey,
    customerData,
    chatId,
    selectedHistory,
    setSlectedHistory,
    scrollToRef,
    scrolledId,
    setScrolledId,
    errorMessage,
    chatHistoryISLoader,
    hasMore,
    historySidebarIds,
    historyMessagesIds,
    fetchLastMessages,
  };
}

export default useCombinedHistoryData;
