import useUTCToUserTime from "src/hooks/dateHooks/useUTCToUserTime";
import styles from "../../../../HistoryInnerView.module.scss";

function Head({ startTime }: { startTime: string }) {
  const createdDate = useUTCToUserTime({
    timeInUTC: startTime,
  });
  return (
    <div className={`d-flex mb-2 ${styles.chatStarts}`}>
      <span>
        <i className="fa-regular fa-message"></i>
      </span>
      <span className="ps-2">Live chat started on {createdDate}</span>
    </div>
  );
}

export default Head;
