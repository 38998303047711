import { axiosJSON } from "src/globals/axiosEndPoints";
import { ELiveChatTrafficType } from "src/enums/ELiveChatTrafficType";

export interface ITrafficListParams {
  type: string;
  start: number;
  limit: number;
}

export interface IAssignedAgent {
  id: number | string;
  name: string;
  email: string;
}

export interface ITrafficListItem {
  id: number | string;
  customerName: string;
  customerEmail: string;
  action: string;
  status: string;
  assignedAgents: Array<IAssignedAgent>;
  brand: {
    id: number|string;
    name: string;
    email: string;
  };
}

export interface ITabTypeCount {
  all: number;
  chatting: number;
  queued: number;
  waitingForReply: number;
}

export interface ITrafficListRes {
  trafficItemList: { [id: number | string]: ITrafficListItem };
  trafficItemIdsList: Array<number | string>;
  trafficTypeTabCount: ITabTypeCount;
  metaData: {
    count: number;
    total: number;
  };
}

export async function getLiveChatTrafficList(
  params: ITrafficListParams,
  signal?: any
) {
  const { data: res } = await axiosJSON.post(
    `/api/liveChat/traffic/getList`,
    params,
    { signal }
  );

  if (res.err) {
    throw res.msg;
  }

  const ret: ITrafficListRes = {
    trafficItemList: {},
    trafficItemIdsList: [],
    trafficTypeTabCount: {
      all: 0,
      chatting: 0,
      queued: 0,
      waitingForReply: 0,
    },
    metaData: {
      count: 0,
      total: 0,
    },
  };

  ret.trafficTypeTabCount.all = res.count.all;
  ret.trafficTypeTabCount.chatting = res.count.chatting;
  ret.trafficTypeTabCount.queued = res.count.queued;
  ret.trafficTypeTabCount.waitingForReply = res.count.waiting_for_reply;

  ret.metaData.count = res.metaData.count;
  ret.metaData.total = res.metaData.total;

  res.data.forEach((trafficItem: ITrafficListItem) => {
    ret.trafficItemList[trafficItem.id] = trafficItem;
    ret.trafficItemIdsList.push(trafficItem.id);
  });

  return ret;
}
