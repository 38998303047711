import { axiosJSON } from "src/globals/axiosEndPoints";

export const getBugStatus = async () => {
  const { data: res } = await axiosJSON.get(
    `/api/selfService/bugManagement/getBugStatus`,
    {
      params: { cacheIt: true, cacheTime: 3600000 },
    },
  );

  if (res.err || res.error) {
    throw res.msg ?? res.message ?? "Something went wrong!";
  }

  const ret = normalizeData(res);

  return ret;
};

export type AllStatusNormalizedData = {
  allBugStatus: Record<string, { id: string; name: string, key: string }>;
  allBugStatusIds: number[];
};

// convert incoming data to the required format
function normalizeData(response: any): AllStatusNormalizedData {
  const statusesList = response.data;
  const normalizedData: AllStatusNormalizedData = {
    allBugStatus: {},
    allBugStatusIds: [],
  };
  statusesList.forEach((status: any, index: number) => {
    normalizedData.allBugStatus[`${index + 1}`] = {
      id: index + 1 + "",
      name: status.statusName,
      key: status.statusKey
    };
    normalizedData.allBugStatusIds.push(index + 1);
  });
  return normalizedData;
}
