import { initialState, IShopifySidebar } from "./shopifySidebar.slice";

const resetShopifySidebar = () => initialState;
const setCustomerId = (
  state: IShopifySidebar,
  { payload }: { payload: { id: number } }
) => {
  state.customerData.id = payload.id;
};

const setCustomerProfileImg = (state: IShopifySidebar, { payload }: any) => {
  state.customerData.customerProfileImageUrl = payload.url;
  state.customerData.profileImageId = payload.id;
};

const setCustomerName = (state: IShopifySidebar, { payload }: { payload: { name: string } }) => {
  state.customerData.name = payload.name;
};

export default { resetShopifySidebar, setCustomerId, setCustomerProfileImg, setCustomerName };
