import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";
import { Message } from "../messageExchange/getMessages";

// Payload Interface
export interface LiveChatCustomerAllMessagePayload {
  customerId: string | number;
  start: number;
  limit: number;
  chatId?: string | number;
  ticketId?: string | number;
  chatIdEnd?: string | number;
  ticketIdEnd?: string | number;
  filters?: {
    // default asc
    sortBy: "asc" | "desc";
  };
  startFromId?: number | string;
  startFromIdType?: "ticket" | "chat" | "message";
}

interface TicketAssignedTo {
  id: string | number;
  name: string;
  imgURL: string | null;
}

interface TicketStatus {
  id: string | number;
  name: string | number;
}

export type MessagesDataType =
  | "Message"
  | "Note"
  | "Event"
  | "ticket"
  | "chat"
  | "NoteBotAiQuery";

export interface MessagesData {
  // This is the data type of the message
  dataType: MessagesDataType;
  messageId: number | string;
  messageDateTimeUTC: string;
  message: string;
  cc: [string];
  bcc: [string];
  senderName: string;
  senderImgURL: string | null;
  isPublicAttachmentUrl?: boolean;
  sentBy: "agent" | "customer";
  to: string;
  from: string;
  eventSubType: number | null;
  attachments: [];

  // For data type "ticket" this will be present
  ticketId: number | string | null;
  channel: { id: string; name: string };
  lastMessageDateUTC: string;
  createdDateUTC: string;
  ticketStatus: TicketStatus;
  subject: string;
  ticketCreatedBy: "agent" | "customer";

  // For data type "chat" this will be present
  chatRequestId: string | number;
  chatStartedOnUTC: string;
  lastMessageTimeUTC: string;
  customerEmail?: string;
  chatStatus: "missed" | "live" | "archived";
  isResolved: boolean;
  messages: Array<Message>;
  messagesTotalCount?: number;
}

export interface LiveChatCustomerAllMessage {
  messages: {
    [id: string]: MessagesData;
  };
  messageIds: Array<string>;
  metaData: {
    currentCount: number;
    limitReached: boolean;
    currentStartPosition?: number;
    currentLimit?: number;
    payloadStartPosition?: number;
    payloadLimit?: number;
    customerId?: number | string;
  };
}

const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/customerAllMessages`
  : "/api/liveChat/chat/customerAllMessagesV2";

export const getLiveChatCustomerAllMessages = async (
  payload: LiveChatCustomerAllMessagePayload,
) => {
  const { data: res } = await axiosJSON.post(apiEndPoint, payload);
  const ret: LiveChatCustomerAllMessage = {
    messageIds: [],
    messages: {},
    metaData: {
      currentCount: res.metaData.currentCount,
      limitReached: false,
      currentStartPosition: res.metaData.currentStartPosition,
      payloadStartPosition: res.metaData.currentStartPosition ?? payload.start,
      customerId: payload.customerId,
    },
  };
  res.data.forEach((msg: MessagesData) => {
    switch (msg.dataType) {
      case "ticket": {
        const id = `${msg.dataType}::${msg.ticketId}`;
        ret.messages[id] = msg;
        ret.messageIds.push(id);
        break;
      }
      case "chat": {
        const id = `${msg.dataType}::${msg.chatRequestId}`;
        ret.messages[id] = msg;
        ret.messageIds.push(id);
        break;
      }
      default: {
        const id = `${msg.dataType}::${msg.messageId}`;
        ret.messages[id] = msg;
        ret.messageIds.push(id);
        break;
      }
    }
  });

  if (ret.messageIds.length < payload.limit) {
    ret.metaData.limitReached = true;
  }

  return ret;
};
