import { CreateDispatchRuleConfigParams } from "src/services/Automation/DispatchRulesConfigModal/createDispatchRuleConfig.service";
import {
  IDispatchRuleData,
  ListOption,
} from "src/services/Automation/DispatchRulesConfigModal/getDispatchRuleConfigById.service";
import { UpdateDispatchRuleConfigParams } from "src/services/Automation/DispatchRulesConfigModal/updateDispatchRuleConfig.service";
import { deepCompare } from "src/utils/utils";
import { EAutomationType } from "../../AutomationList/AutomationList";
import { EDispatchSteps } from "../AutomationTabSettings/Children/ConfigureSteps/Children/Components/ConfigureDispatchRule/Children/DispatchSection/DispatchStepsConfig";

const isInvalidString = (value: string) => {
  return typeof value !== "string" || value === "" || value.trim() === "";
};

const isInvalidListOption = (value: ListOption[]) => {
  return (
    !Array.isArray(value) ||
    value.length === 0 ||
    value.some((val) => val.id === "" || val.name === "")
  );
};

/**
 * Validator for Dispatch Rule Steps
 * @param stepType Step to validate
 * @returns Boolean representing if step is valid or not
 */
export const validateDispatchRuleSteps = ({
  state,
  stepType,
  automationType,
  validateUserSegments = false,
}: {
  stepType: EDispatchSteps;
  state: IDispatchRuleData;
  automationType: EAutomationType;
  validateUserSegments?: boolean;
}): boolean => {
  let isValid = true;

  switch (stepType) {
    case EDispatchSteps.RuleName:
      if (isInvalidString(state.ruleName)) {
        isValid = false;
      }
      break;

    case EDispatchSteps.Conditions:
      if (
        automationType === EAutomationType.BUG_MANAGEMENT &&
        state.bugCategoryDetails &&
        isInvalidListOption(state.bugCategoryDetails)
      ) {
        isValid = false;
        break;
      }

      if (
        automationType === EAutomationType.BUG_MANAGEMENT &&
        state.bugPriorityDetails &&
        isInvalidListOption(state.bugPriorityDetails)
      ) {
        isValid = false;
        break;
      }

      if (
        validateUserSegments &&
        state.userSegmentDetails &&
        isInvalidListOption(state.userSegmentDetails)
      ) {
        isValid = false;
      }

      break;

    case EDispatchSteps.IssueDetails:
      if (typeof state.isAiReadIssueDescriptionForDispatch !== "boolean") {
        isValid = false;
        break;
      }

      if (state.isAiReadIssueDescriptionForDispatch === false) {
        break;
      }

      if (!Array.isArray(state.issueDescription)) {
        isValid = false;
        break;
      }

      if (
        automationType === EAutomationType.ISSUE_DISPATCH &&
        state.issueDescription.some((val) => isInvalidString(val.description))
      ) {
        isValid = false;
        break;
      } else if (
        automationType === EAutomationType.BUG_MANAGEMENT &&
        state.issueDescription.some((val) => {
          if (isInvalidString(val.description)) {
            return true;
          }

          if (typeof state.isSuggestArticles !== "boolean") {
            return true;
          }

          if (
            state.isSuggestArticles === true &&
            isInvalidListOption(val.articlesDetails ?? [])
          ) {
            return true;
          }

          return false;
        })
      ) {
        isValid = false;
        break;
      }

      break;

    case EDispatchSteps.IssueResponse:
      // if (typeof state.isAiReadIssueDescriptionForDispatch !== "boolean") {
      //   isValid = false;
      //   break;
      // }

      // if (state.isAiReadIssueDescriptionForDispatch === false) {
      //   break;
      // }

      // if (automationType === EAutomationType.ISSUE_DISPATCH) {
      //   const isInvalid = state.issueDescription.some((val) => {
      //     if (typeof val.isSuggestArticles !== "boolean") {
      //       return true;
      //     }

      //     if (
      //       val.isSuggestArticles === true &&
      //       (isInvalidListOption(val.articlesDetails ?? []) ||
      //         isInvalidString(val.articleConfiguredResponse ?? ""))
      //     ) {
      //       return true;
      //     }

      //     if (typeof val.isCernReplyToCustomerResponse !== "boolean") {
      //       return true;
      //     }

      //     if (
      //       val.isSuggestArticles === true &&
      //       val.isCernReplyToCustomerResponse === true &&
      //       (!Array.isArray(val.cernReplyToCustomerResponse) ||
      //         val.cernReplyToCustomerResponse.length === 0 ||
      //         val.cernReplyToCustomerResponse.some(
      //           (val) =>
      //             isInvalidString(val.reply) || isInvalidString(val.response),
      //         ))
      //     ) {
      //       return true;
      //     }

      //     return false;
      //   });

      //   isValid = !isInvalid;
      //   break;
      // }

      break;

    case EDispatchSteps.IssueCategories:
      if (typeof state.isAiReadIssueDescriptionForDispatch !== "boolean") {
        isValid = false;
        break;
      }

      if (state.isAiReadIssueDescriptionForDispatch === false) {
        break;
      }

      if (automationType === EAutomationType.ISSUE_DISPATCH) {
        for (let i = 0; i < state.issueDescription.length; i++) {
          const issue = state.issueDescription[i];

          if (isInvalidListOption(issue.categoryDetails ?? [])) {
            isValid = false;
            break;
          }
        }
      }

      break;

    case EDispatchSteps.DispatchTo:
      if (
        !Array.isArray(state.dispatchToDetails) ||
        state.dispatchToDetails.length === 0 ||
        state.dispatchToDetails.some((val) => {
          if (val.agentDetails && isInvalidListOption(val.agentDetails)) {
            return true;
          }

          if (val.channelDetails && isInvalidListOption(val.channelDetails)) {
            return true;
          }

          return false;
        })
      ) {
        isValid = false;
        break;
      }

      break;

    case EDispatchSteps.Success:
      break;
    default:
      isValid = false;
  }

  return isValid;
};

export const getCreateDispatchPayload = ({
  state,
  automationType,
  showUserSegment = false,
}: {
  state: IDispatchRuleData;
  automationType: EAutomationType;
  showUserSegment?: boolean;
}): CreateDispatchRuleConfigParams => {
  const payload: CreateDispatchRuleConfigParams = {
    dispatchToDetails: state.dispatchToDetails.map((val) => {
      return {
        agentIds: val.agentDetails.map((val) => val.id),
        channels: val.channelDetails.map((val) => val.id),
        teamId: val.teamDetails?.id,
      };
    }),
    integrationId: state.integrationId,
    isAiReadIssueDescriptionForDispatch:
      state.isAiReadIssueDescriptionForDispatch ?? false,
    isSuggestArticles: state.isSuggestArticles,
    issueDescription: state.issueDescription.map((val) => {
      if (automationType === EAutomationType.BUG_MANAGEMENT) {
        return {
          description: val.description,
          articlesIds: val.articlesDetails?.map((val) => val.id),
        };
      }

      return {
        description: val.description,
        articleConfiguredResponse: val.articleConfiguredResponse,
        articlesIds: val.articlesDetails?.map((val) => val.id),
        categoryIds: val.categoryDetails?.map((val) => val.id),
        cernReplyToCustomerResponse: val.cernReplyToCustomerResponse ?? [],
        isCernReplyToCustomerResponse:
          val.isCernReplyToCustomerResponse ?? false,
        isSuggestArticles: val.isSuggestArticles,
      };
    }),
    ruleName: state.ruleName,
    bugCategoryIds: state.bugCategoryDetails?.map((val) => val.id),
    priorityIds: state.bugPriorityDetails?.map((val) => val.id),
    userSegmentIds: state.userSegmentDetails?.map((val) => val.id),
  };

  if (showUserSegment === false) {
    delete payload.userSegmentIds;
  }

  if (automationType === EAutomationType.ISSUE_DISPATCH) {
    delete payload.bugCategoryIds;
    delete payload.priorityIds;
    delete payload.isSuggestArticles;
  }

  return payload;
};

export const getUpdateDispatchPayload = ({
  state,
  originalData,
  stepType,
  automationType,
  showUserSegment = false,
}: {
  state: IDispatchRuleData;
  originalData?: IDispatchRuleData;
  stepType: EDispatchSteps;
  automationType: EAutomationType;
  showUserSegment?: boolean;
}): UpdateDispatchRuleConfigParams | null => {
  const newPayload: UpdateDispatchRuleConfigParams = {
    configurationId: state.configurationId,
    integrationId: state.integrationId,
  };

  let hasChanges = false;

  switch (stepType) {
    case EDispatchSteps.RuleName:
      if (originalData?.ruleName !== state.ruleName) {
        newPayload.ruleName = state.ruleName;
        hasChanges = true;
      }
      break;
    case EDispatchSteps.Conditions:
      if (
        automationType === EAutomationType.BUG_MANAGEMENT &&
        !deepCompare(originalData?.bugCategoryDetails, state.bugCategoryDetails)
      ) {
        hasChanges = true;
      }

      if (
        automationType === EAutomationType.BUG_MANAGEMENT &&
        !deepCompare(originalData?.bugPriorityDetails, state.bugPriorityDetails)
      ) {
        hasChanges = true;
      }

      if (
        showUserSegment &&
        !deepCompare(originalData?.userSegmentDetails, state.userSegmentDetails)
      ) {
        hasChanges = true;
      }

      if (hasChanges) {
        if (automationType === EAutomationType.BUG_MANAGEMENT) {
          newPayload.bugCategoryIds = state.bugCategoryDetails?.map(
            (val) => val.id,
          );

          newPayload.priorityIds = state.bugPriorityDetails?.map(
            (val) => val.id,
          );
        }

        if (showUserSegment) {
          newPayload.userSegmentIds = state.userSegmentDetails?.map(
            (val) => val.id,
          );
        }
      }

      break;
    case EDispatchSteps.IssueDetails:
    case EDispatchSteps.IssueResponse:
    case EDispatchSteps.IssueCategories:
      if (
        originalData?.isAiReadIssueDescriptionForDispatch !==
          state.isAiReadIssueDescriptionForDispatch ||
        (automationType === EAutomationType.BUG_MANAGEMENT &&
          originalData?.isSuggestArticles !== state.isSuggestArticles) ||
        !deepCompare(originalData?.issueDescription, state.issueDescription)
      ) {
        newPayload.isAiReadIssueDescriptionForDispatch =
          state.isAiReadIssueDescriptionForDispatch;

        if (automationType === EAutomationType.BUG_MANAGEMENT) {
          newPayload.isSuggestArticles = state.isSuggestArticles;
        }

        newPayload.issueDescription = state.issueDescription.map((val) => {
          if (automationType === EAutomationType.BUG_MANAGEMENT) {
            return {
              descriptionId: val.descriptionId,
              description: val.description,
              articlesIds: val.articlesDetails?.map((val) => val.id),
            };
          }

          return {
            descriptionId: val.descriptionId,
            description: val.description,
            articleConfiguredResponse: val.articleConfiguredResponse,
            articlesIds: val.articlesDetails?.map((val) => val.id),
            categoryIds: val.categoryDetails?.map((val) => val.id),
            cernReplyToCustomerResponse: val.cernReplyToCustomerResponse ?? [],
            isCernReplyToCustomerResponse:
              val.isCernReplyToCustomerResponse ?? false,
            isSuggestArticles: val.isSuggestArticles,
          };
        });

        hasChanges = true;
      }
      break;

    case EDispatchSteps.DispatchTo:
      if (
        !deepCompare(originalData?.dispatchToDetails, state.dispatchToDetails)
      ) {
        newPayload.dispatchToDetails = state.dispatchToDetails.map((val) => {
          return {
            id: val.id,
            agentIds: val.agentDetails.map((val) => val.id),
            channels: val.channelDetails.map((val) => val.id),
            teamId: val.teamDetails?.id,
          };
        });
        hasChanges = true;
      }
      break;
    case EDispatchSteps.Success:
    default:
  }

  return hasChanges ? newPayload : null;
};
