import { Channel } from "pusher-js";

export interface ChatWidgetInstalledEvent {
  integration_id: string | number;
  installed_successfully: boolean;
  enabled: boolean; //current enabled status from backend,
}

export const addChatWidgetInstalledEvent = (
  channel: Channel,
  callback: (res: ChatWidgetInstalledEvent) => void
) => {
  // console.log("tlc", channel);
  channel.bind("chat_widget_installed", callback);
};
