import React, { useMemo, useCallback } from "react";
import {
  bugManagementCurrentStatusActions,
  useBugManagementFilters,
} from "../../../Hooks/useBugManagementFilter";
import FilterSelect from "../FilterSelect/FilterSelect";
import useBugPriorityList from "./useBugPriorityList";
import useBugStatusList from "../StatusFilter/useBugStatusList";

interface Props {
  addFilters: (
    selectedStatus: Array<string | undefined>,
    selectedPriority: Array<string | undefined>,
    selectedCategory: Array<string>,
  ) => void;
}

const Priority = ({ addFilters }: Props) => {
  const { allBugPriority } = useBugPriorityList();
  const { allBugStatus } = useBugStatusList();

  const allPriorityId = useMemo(() => {
    if (!allBugPriority?.allBugPriorityIds?.length) {
      return [];
    }
    return allBugPriority?.allBugPriorityIds.map((id) => id + "");
  }, [allBugPriority]);

  // get current selected filter ids
  const { currentStatus, dispatch } = useBugManagementFilters();

  const selectedStatus = useMemo(() => {
    return currentStatus.status.map((id) => allBugStatus?.allBugStatus[id].key);
  }, [currentStatus.status]);

  const selectedPriorityFilter = useMemo(() => {
    return currentStatus.priority.map(
      (id) => allBugPriority?.allBugPriority[id].key,
    );
  }, [currentStatus.priority]);

  const selectedPriority = useMemo(() => {
    return currentStatus.priority;
  }, [currentStatus]);

  // fucntion to add and remove selected filter to useContenxt
  const checkUncheckPriority = useCallback(
    (e: any) => {
      if (e.target.checked) {
        dispatch([
          bugManagementCurrentStatusActions.setFilters,
          { priority: [...selectedPriority, e.target.value] },
        ]);
      } else {
        dispatch([
          bugManagementCurrentStatusActions.setFilters,
          {
            priority: selectedPriority.filter((id) => id !== e.target.value),
          },
        ]);
      }
    },
    [selectedPriority],
  );

  return (
    <FilterSelect
      filterName={"Priority"}
      allFilters={allBugPriority?.allBugPriority ?? {}}
      allFilterIds={allPriorityId}
      selectedFilters={selectedPriority}
      checkUncheckFilters={checkUncheckPriority}
      applyFilters={() =>
        addFilters(
          selectedStatus,
          selectedPriorityFilter,
          currentStatus.bugCategory,
        )
      }
      cancelFilters={() => {}}
      nextPageLoad={() => {}}
    />
  );
};

export default Priority;
