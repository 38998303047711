import styles from "./MergeModal.module.scss";
import emptyBackPng from "src/assets/images/emptyBack.png";
import { useCallback, useEffect, useReducer, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import Loader from "../Loader";
import InfiniteScroll from "../InfiniteScroll";
import { useAppSelector, useAppDispatch } from "src/store/store";
import {
  fetchAllMergableTickets,
  removeAllSelectedMergeTickets,
  resetTicketMerge,
} from "src/store/slices/innerTicket/innerTicket.slice";
import TicketData from "src/routes/Ticket/children/GlobalSearch/TicketData/TicketData";
import SelectAllTicketCheckBox from "./children/SelectAllTicketCheckBox/SelectAllTicketCheckBox";
import { totalMergeTicketsSelected } from "src/store/slices/innerTicket/innerTicket.selectors";
import NoteNClose from "./children/NoteNClose/NoteNClose";

interface Props {
  onHide: () => void;
  onShow: () => void;
  showModal: boolean;
}

const MergeModal = ({ onHide, onShow, showModal }: Props) => {
  const dispatch = useAppDispatch();
  // toggle next and back buttons
  const [toggleNextNBack, setToggleNextNBack] = useState(false);
  // get the state data
  const { customerData, active } = useAppSelector((state) => state.innerTicket);
  const {
    ticketList,
    ticketData,
    mergableTicketFetchAjaxStatus,
    ticketListTotal,
  } = useAppSelector((state) => state.innerTicket.ticketMerge);
  // count of selected tickets
  const selectedTicketCount = useAppSelector(totalMergeTicketsSelected);

  const ticketListTotalCurrent: any = useRef(ticketListTotal);

  useEffect(() => {
    ticketListTotalCurrent.current = ticketListTotal;
  }, [ticketListTotal]);

  // to handle the infinite scroll
  const handleInfiniteScroll = useCallback(() => {
    if (ticketListTotalCurrent.current !== null) {
      if (ticketListTotalCurrent.current > 0) {
        dispatch(fetchAllMergableTickets());
      }
    }
  }, [ticketListTotal]);

  useEffect(() => {
    dispatch(fetchAllMergableTickets());
  }, []);

  // function to cancel/ hide merge modal
  const hideNCancelMerge = () => {
    onHide();
    // reset selected data(can be removed)
    if (selectedTicketCount > 0) {
      dispatch(removeAllSelectedMergeTickets());
    }
    dispatch(resetTicketMerge());
  };

  // handle click on next and back
  const handleOnClickNextNBack = () => {
    setToggleNextNBack(!toggleNextNBack);
  };

  return (
    <>
      <div>
        <div
          className={`position-absolute end-0 top-0 mx-4 mt-4 ${styles.closeIconDiv}`}
        >
          <span className={`mx-2`}>
            <i
              className={`fas fa-times ${styles.closeIcon}`}
              onClick={(e) => {
                hideNCancelMerge();
              }}
            />
          </span>
        </div>
      </div>
      <Modal
        show={true}
        onHide={hideNCancelMerge}
        backdropClassName={`${styles.modalBackDrop}`}
        dialogClassName={`${styles.modalDialog}`}
        contentClassName={`${styles.modalContent}`}
      >
        <div className={`${styles.mergeMainDiv}`}>
          {toggleNextNBack == false ? (
            <>
              <p className={`${styles.mergeHeading}`}>
                Select to Merge other tickets of {customerData.name} into
                current Ticket ID : {active.ticketId}
              </p>

              <p className={`${styles.selectAllCheckBoxP}`}>
                {(mergableTicketFetchAjaxStatus === "fulfilled" ||
                  mergableTicketFetchAjaxStatus === "pending") &&
                ticketList.length > 0 ? (
                  <SelectAllTicketCheckBox />
                ) : undefined}
              </p>

              <InfiniteScroll
                className={`${styles.ticketDataDiv}`}
                loadMore={handleInfiniteScroll}
                loader={
                  mergableTicketFetchAjaxStatus === "pending" &&
                  ticketList.length === 0 ? (
                    <div
                      className={`d-flex flex-column m-auto ${styles.loading}`}
                    >
                      <Loader />
                      <span className="text-center">Fetching tickets...</span>
                    </div>
                  ) : undefined
                }
                errorMessage={
                  ticketListTotal === 0 ||
                  mergableTicketFetchAjaxStatus === "rejected" ? (
                    <div className={`${styles.nothingFound} m-auto`}>
                      <img
                        src={emptyBackPng}
                        className={`d-block m-auto mb-2`}
                      />
                      <span>No Ticket Found To Merge</span>
                    </div>
                  ) : undefined
                }
                hasMore={
                  mergableTicketFetchAjaxStatus === "rejected"
                    ? false
                    : ticketListTotal === null
                    ? true
                    : ticketList.length < ticketListTotal
                }
                initialDataLoaded={true}
              >
                {ticketList.map((id, index) => {
                  return (
                    <TicketData
                      key={id}
                      ticketData={ticketData[id]}
                      showArrow={false}
                      showCheckBox={true}
                      hideNCancelMerge={hideNCancelMerge}
                      enableOnClickOpenTicket={false}
                    />
                  );
                })}
              </InfiniteScroll>
              {/* bottom division  */}
              <div className={`${styles.bottomCtrlBar}`}>
                <button
                  className={`btn mx-2 cancelBtn`}
                  onClick={(e) => {
                    hideNCancelMerge();
                  }}
                >
                  <span className={`mx-2 my-auto`}>
                    <i className="fas fa-chevron-left" />
                  </span>
                  <span className={`my-auto`}>Cancel</span>
                </button>
                <span className={``}>
                  {selectedTicketCount > 0 ? (
                    <span className={`${styles.selectedCountTxt} end-0`}>
                      Selected tickets of {customerData.name} (
                      {selectedTicketCount})
                    </span>
                  ) : undefined}
                  <button
                    className={`btn mx-2 saveBtn`}
                    onClick={(e) => {
                      handleOnClickNextNBack();
                    }}
                    disabled={selectedTicketCount > 0 ? false : true}
                  >
                    <span className={`my-auto`}>Next</span>
                    <span className={`mx-2 my-auto`}>
                      <i className="fas fa-chevron-right" />
                    </span>
                  </button>
                </span>
              </div>
            </>
          ) : (
            <NoteNClose
              ticketId={active.ticketId}
              selectedTicketCount={selectedTicketCount}
              onClickBack={handleOnClickNextNBack}
              onMergeComplete={hideNCancelMerge}
            />
          )}
        </div>
      </Modal>
    </>
  );
};

export default MergeModal;
