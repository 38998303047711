import { Dropdown } from "react-bootstrap";
import { useState } from "react";
import styles from "./LogicalOperator.module.scss";
import { LogicalOperatorTypes } from "src/services/CustomerSegments/getConditionOptions.service";

interface Props {
  selectedValue: LogicalOperatorTypes | null;
  onChange: (value: LogicalOperatorTypes) => void;
}

const LogicalOperator = ({ selectedValue, onChange }: Props) => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <Dropdown
      show={showMenu}
      onToggle={(isOpen) => setShowMenu(isOpen)}
    >
      <Dropdown.Toggle
        id="dropdown-basic"
        as="div"
        className={`ms-2 ${styles.dropHead}`}
      >
        {selectedValue === null
          ? "Select Operator"
          : selectedValue === "and"
            ? "AND"
            : selectedValue === "or"
              ? "OR"
              : "Error"}
        <span className={`ps-2 ${styles.arrowDown}`}>
          <i className={`fa-solid fa-chevron-${showMenu ? "up" : "down"}`}></i>
        </span>
      </Dropdown.Toggle>

      <Dropdown.Menu bsPrefix={`dropdown-menu ${styles.dropMenu}`}>
        <div>
          <span
            className={`${styles.dropItem}`}
            onClick={() => {
              setShowMenu(false);
              onChange("and");
            }}
          >
            {"AND"}
          </span>
          <span
            className={`${styles.dropItem}`}
            onClick={() => {
              setShowMenu(false);
              onChange("or");
            }}
          >
            {"OR"}
          </span>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LogicalOperator;
