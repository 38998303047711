import styles from "./ChatNotFound.module.scss";
import traffic_Empty from "src/assets/images/traffic_Empty.png";

function ChatNotFound({
  text = "No Chat Found",
  subText = "",
  img = traffic_Empty,
  className = "",
}: {
  text?: string;
  subText?: string;
  img?: string;
  className?: string;
}) {
  return (
    <div className={`${styles.nothingFound} ${className}`}>
      <img
        src={img}
        className={`d-flex justify-content-center img-fuild m-auto mb-2 ${styles.noDataImg}`}
      />
      <p className={`${styles.backtext} mt-3 mb-0`}>{text}</p>

      {subText.length !== 0 && (
        <p className={`${styles.subBacktext} d-block mt-1`}>{subText}</p>
      )}
    </div>
  );
}

export default ChatNotFound;
