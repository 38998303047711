import { axiosJSON } from "src/globals/axiosEndPoints";
import { isObjOrStrEmpty } from "src/utils/utils";

export interface IDropDownItem {
  name: string;
  count: number;
}

export interface IDropDownItemList {
  [key: number]: IDropDownItem;
}

export interface IFilterDropDownType {
  name: string;
  content: IDropDownItemList;
}

//  res
export interface ISidebarTicketsCountRes {
  //  in case of all or my
  name?: string;
  count?: number;
  unreadMentionsCount?: number;
  content?: Array<IFilterDropDownType>;
  //   in case of view
  viewId?: number;
  viewName?: string;
  viewsTicketCount?: number;
  totalTicketCount?: number; //cern closed or open ticket count
}

export interface IFilterOption {
  compare: string;
  key: string;
  value: number | string;
}

// params
export interface ISidebarTicketsCountParams {
  filters: {
    tableType: string;
    options?: Array<IFilterOption>;
    searchOptions?: {
      value: string;
      keys: string[];
    };
    viewId?: number;
  };
}

export async function getTicketsCountInSideBar(
  params: ISidebarTicketsCountParams,
  signal?: any
) {
  const { data }: { data: ISidebarTicketsCountRes } = await axiosJSON.post(
    `/api/ticket/getCalculatedSidebar`,
    params,
    { signal }
  );

  return data;
}

// to prepare the query for fetching count of tickets
export function prepareGetSidebarTicketsCountQuery({
  tableType,
  filterOptions,
}: {
  tableType: string;
  filterOptions: any;
}) {
  const {
    activeChannelId,
    activeDraftStatusId,
    activeTicketStatusId,
    filterByAgents,
    filterByCreatedDate,
    filterByCreatedDateValue,
    createdDateValue,
    closedDateValue,
    notRepliedSinceDateValue,
    filterByClosedDate,
    filterByClosedDateValue,
    filterByChannels,
    filterByBrands,
    filterByTags,
    filterByEmails,
    filterByAssignees,
    filterByUserType,
    filterByLastMessage,
    filterByNotRepliedSince,
    filterByNotRepliedSinceValue,
    filterByTicketStatus,
    filterByDraftStatus,
    filterByDraftedBy,
    filterSearchString,
    filterByLastUpdated,
    lastUpdatedDateValue,
  } = filterOptions;

  const queryData: ISidebarTicketsCountParams = {
    filters: {
      tableType:
        tableType === "my"
          ? "my"
          : tableType === "mentions"
          ? "mentions"
          : tableType === "ai_handled"
          ? "ai_handled"
          :"all",
    },
  };
  if (tableType.includes("viewId")) {
    queryData.filters.viewId = parseInt(tableType.split("::")[1]);
  }

  let filterTableData: any = {};
  //checking if active channel id is set and it is not empty and it doesn't contain 0 as it is referring to all we don't have to add it to payload
  if (
    activeChannelId &&
    activeChannelId.length &&
    !activeChannelId.includes(0)
  ) {
    filterTableData["ticket_channel"] = {
      compare: "=",
      value: activeChannelId.join(","),
    };
  }
  //checking if active draft id is set and it is not empty and it doesn't contain 0 as it is referring to all we don't have to add it to payload
  if (
    activeDraftStatusId &&
    activeDraftStatusId.length &&
    !activeDraftStatusId.includes(0)
  ) {
    filterTableData["ticket_draft_status"] = {
      compare: "=",
      value: activeDraftStatusId.join(","),
    };
  }
  //checking if active ticket status id is set and it is not empty and it doesn't contain 0 as it is referring to all we don't have to add it to payload
  if (
    activeTicketStatusId &&
    activeTicketStatusId.length &&
    !activeTicketStatusId.includes(0)
  ) {
    filterTableData["ticket_status_id"] = {
      compare: "=",
      value: activeTicketStatusId.join(","),
    };
  }

  if (!isObjOrStrEmpty(filterByCreatedDate)) {
    filterTableData["ticket_date_gmt"] = {
      compare: filterByCreatedDate.compare,
      value:
        createdDateValue === null
          ? filterByCreatedDate.defaultValue
          : createdDateValue,
    };
  }

  // Build filter table data for fetching sidebar count
  if (!isObjOrStrEmpty(filterByLastUpdated)) {
    filterTableData["ticket_modified_gmt"] = {
      compare: filterByLastUpdated.compare,
      value:
        lastUpdatedDateValue === null
          ? filterByLastUpdated.defaultValue
          : lastUpdatedDateValue,
    };
  }

  if (!isObjOrStrEmpty(filterByClosedDate)) {
    filterTableData["ticket_closed_date_gmt"] = {
      compare: filterByClosedDate.compare,
      value:
        closedDateValue === null
          ? filterByClosedDate.defaultValue
          : closedDateValue,
    };
  }

  if (!isObjOrStrEmpty(filterByNotRepliedSince)) {
    filterTableData["not_replied_since"] = {
      compare: filterByNotRepliedSince.compare,
      value:
        notRepliedSinceDateValue === null
          ? filterByNotRepliedSince.defaultValue
          : notRepliedSinceDateValue,
    };
  }

  if (!isObjOrStrEmpty(filterByChannels)) {
    let value = getValue(filterByChannels, true);
    if (value.trim()) {
      if (filterTableData.hasOwnProperty("ticket_channel")) {
        // If the property already exists, update its value
        let existingValue = filterTableData["ticket_channel"].value;
        let updatedValueArray = [
          ...new Set([...existingValue.split(","), value.split(",")]),
        ];
        filterTableData["ticket_channel"].value = updatedValueArray.join(",");
      } else {
        filterTableData["ticket_channel"] = {
          compare: "=",
          value: value,
        };
      }
    }
  }
  if (!isObjOrStrEmpty(filterByBrands)) {
    let value = getValue(filterByBrands, true);
    if (value.trim()) {
      filterTableData["ticket_brand_id"] = {
        compare: "=",
        value: value,
      };
    }
  }
  if (!isObjOrStrEmpty(filterByAgents)) {
    let value = getValue(filterByAgents, true);
    if (value.trim()) {
      filterTableData["ticket_agent"] = {
        compare: "=",
        value: value,
      };
    }
  }
  if (!isObjOrStrEmpty(filterByTags)) {
    let value = getValue(filterByTags, true);
    if (value.trim()) {
      filterTableData["tags"] = {
        compare: "=",
        value: value,
      };
    }
  }
  if (!isObjOrStrEmpty(filterByEmails) && !filterByEmails?.viewFilter) {
    filterTableData["filter_email"] = {
      compare: filterByEmails.compare,
      value: filterByEmails.id,
    };
  }

  if (!isObjOrStrEmpty(filterByUserType)) {
    let value = getValue(filterByUserType, true);
    if (value.trim()) {
      filterTableData["user_type"] = {
        compare: "=",
        value: value,
      };
    }
  }
  if (!isObjOrStrEmpty(filterByLastMessage)) {
    let value = getValue(filterByLastMessage, true);
    if (value.trim()) {
      filterTableData["last_message"] = {
        compare: "=",
        value: value,
      };
    }
  }

  if (!isObjOrStrEmpty(filterByTicketStatus)) {
    let value = getValue(filterByTicketStatus, true);
    if (value.trim()) {
      if (filterTableData.hasOwnProperty("ticket_status_id")) {
        // If the property already exists, update its value
        let existingValue = filterTableData["ticket_status_id"].value;
        let updatedValueArray = [
          ...new Set([...existingValue.split(","), value.split(",")]),
        ];
        filterTableData["ticket_status_id"].value = updatedValueArray.join(",");
      } else {
        filterTableData["ticket_status_id"] = {
          compare: "=",
          value: value,
        };
      }
    }
  }
  if (!isObjOrStrEmpty(filterByDraftStatus)) {
    let value = getValue(filterByDraftStatus, true);
    if (value.trim()) {
      if (filterTableData.hasOwnProperty("ticket_draft_status")) {
        // If the property already exists, update its value
        let existingValue = filterTableData["ticket_draft_status"].value;
        let updatedValueArray = [
          ...new Set([...existingValue.split(","), value.split(",")]),
        ];
        filterTableData["ticket_draft_status"].value =
          updatedValueArray.join(",");
      } else {
        filterTableData["ticket_draft_status"] = {
          compare: "=",
          value: value,
        };
      }
    }
  }
  if (!isObjOrStrEmpty(filterByDraftedBy)) {
    let value = getValue(filterByDraftedBy, true);
    if (value.trim()) {
      filterTableData["drafted_by"] = {
        compare: "=",
        value: value,
      };
    }
  }

  if (Object.keys(filterTableData).length > 0) {
    queryData.filters.options = addKeyToAllFilters(filterTableData);
  }

  if (filterSearchString.trim().length > 0) {
    queryData.filters.searchOptions = {
      value: filterSearchString.trim(),
      keys: ["*"],
    };
  }

  return queryData;
}

function getValue(filterArray: [], ignoreViewFilter = true) {
  let filters: any = filterArray;
  if (ignoreViewFilter) {
    filters = filterArray.filter((filterObj: any) => !filterObj?.viewFilter);
  }
  return filterArray.map((filterObj: any) => filterObj.id).join(",");
}

function addKeyToAllFilters(filterTableData: any): {
  key: string;
  compare: string;
  value: number;
}[] {
  const keyAddedObj = filterTableData;
  Object.entries(filterTableData).forEach(([key, value]) => {
    keyAddedObj[key].key = key;
  });
  return Object.values(keyAddedObj);
}
