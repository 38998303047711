import log from "loglevel";
import { axiosJSON } from "src/globals/axiosEndPoints";
import { isObjOrStrEmpty } from "src/utils/utils";

function useLoadOptions() {
  const fetchOptions = async (url: string) => {
    const responseJSON = await axiosJSON.get(url);
    return responseJSON.data.data;
  };

  const getLoadOptions =
    (url: string, currValue: any) =>
    async (searchTerm: string, prevOptions: any) => {
      // log.debug({ searchTerm, prevOptions, currValue });
      let options = await fetchOptions(url);

      // filter from search term
      if (searchTerm) {
        const searchLower = searchTerm.trim().toLowerCase();
        options = options.filter((option: any) =>
          option.name.toLowerCase().includes(searchLower)
        );
      }

      // filter from previously selected items
      if (isObjOrStrEmpty(currValue)) {
        const searchLower = searchTerm.trim().toLowerCase();
        if (typeof currValue === "string") {
          options = options.filter((option: any) =>
            option.name.toLowerCase().includes(searchLower)
          );
        }
        if (typeof currValue === "object") {
          // options = options.filter((option: any) => !currValue.includes(option));
          if (currValue.length !== undefined) {
            options = options.filter(
              (option1: any) =>
                !currValue.some((option2: any) =>
                  option1.compare !== undefined
                    ? option1.compare === option2.compare
                    : option1.id === option2.id
                )
            );
          }
        }
      }
      return {
        options,
        hasMore: false
      };
    };

  return { getLoadOptions };
}

export default useLoadOptions;
