import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";

import { useAppDispatch, useAppSelector } from "src/store/store";
import { fetchGetEmailIntegrationById } from "src/store/slices/emailIntegration/emailIntegration.thunks";
import {
  MailTypeEnum,
  actions,
} from "src/store/slices/emailIntegration/emailIntegration.slices";
import { addEmailDomainService } from "src/services/EmailIntegration/addEmailDomain.service";

import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { OutgoingRecieveType } from "src/services/EmailIntegration/getEmailIntegrationById";
import { updateEmailIntegration } from "src/services/EmailIntegration/updateEmailIntegration";

/**
 * Custom hook to initialize the domain integration data and check auth
 */
const useDomainIntegration = (
  integrationId?: string,
  navigateCallback?: (val: number) => void,
) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Auth Check Loader states
  const [checkingAuth, setCheckingAuth] = useState(false);
  const fetching = useRef<boolean>(false);

  /**
   * Effect for updating email integration data
   */
  useEffect(() => {
    dispatch(
      fetchGetEmailIntegrationById({
        emailIntegrationId: Number(integrationId),
        // Callback for redirecting user
        callback: (status) => {
          if (status === "email_not_verified") {
            if (navigateCallback) {
              navigateCallback(2);
            } else {
              navigate(
                `/settings/integrations/email/${integrationId}/verifyEmail`,
              );
            }
          } else if (status === undefined) {
            if (navigateCallback) {
              navigateCallback(-1);
            } else {
              navigate(`/settings/integrations/email/connectEmail`);
            }
          }
        },
      }),
    );
    return () => {
      // Reset loader state
      dispatch(actions.setDomainLoading({ domainLoading: "idle" }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrationId, navigateCallback]);

  const selectedUserDetail = useAppSelector(
    (state) => state.emailIntegration.selectedUser,
  );

  /**
   * Handler for checking if auth is added or not
   */
  const handleCheckAuth = useCallback(async () => {
    if (fetching.current) {
      return;
    }
    try {
      // Check if Domain Auth is added
      setCheckingAuth(true);
      fetching.current = true;
      await addEmailDomainService({
        emailIntegrationId: selectedUserDetail.emailIntegrationId,
      });
      // Update auth data
      dispatch(
        fetchGetEmailIntegrationById({
          emailIntegrationId: Number(selectedUserDetail.emailIntegrationId),
        }),
      );
    } catch (e) {
      const err = e as string | AxiosError;
      // Show error text
      pushTheToast({
        position: "top-right",
        text: typeof err === "string" ? err : err.message,
        type: "danger",
      });
      // Go to step 2
      if (navigateCallback) {
        navigateCallback(2);
      } else {
        navigate(
          `/settings/integrations/email/${selectedUserDetail.emailIntegrationId}/verifyEmail`,
        );
      }
    } finally {
      setCheckingAuth(false);
      fetching.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkingAuth, selectedUserDetail.emailIntegrationId, navigateCallback]);

  const currentState = useRef({
    outgoingMailSettings: selectedUserDetail.outgoingMailSettings,
  });

  useMemo(() => {
    currentState.current.outgoingMailSettings =
      selectedUserDetail.outgoingMailSettings;
  }, [selectedUserDetail.outgoingMailSettings]);

  /**
   * Effect for redirecting user
   */
  useEffect(() => {
    // Check data is loaded and domain auth is not updated
    if (selectedUserDetail.emailIntegrationId !== -1) {
      if (
        (!selectedUserDetail.isDomainVerified &&
          !selectedUserDetail.isDomainAddedForAuth) ||
        (!(
          currentState.current.outgoingMailSettings?.integrations &&
          currentState.current.outgoingMailSettings.integrations[
            "currentHostWithDNS"
          ]?.verified
        ) &&
          !selectedUserDetail.isDomainAddedForAuth)
      ) {
        handleCheckAuth();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedUserDetail.emailIntegrationId,
    selectedUserDetail.isDomainAddedForAuth,
  ]);

  /**
   * Overall data loading state
   */
  const { emailLoading: loader } = useAppSelector((state) => {
    return state.emailIntegration;
  });

  /**
   * State for managing selected outgoing mail type
   */
  const [selectedType, setSelectedType] = useState<OutgoingRecieveType>(
    selectedUserDetail.outgoingMailSettings?.outgoingType ??
      "currentHostWithDNS",
  );

  useEffect(() => {
    setSelectedType(
      selectedUserDetail.outgoingMailSettings?.outgoingType ??
        (selectedUserDetail.mailType.id === MailTypeEnum.gmail
          ? "gmail"
          : "currentHostWithDNS"),
    );
  }, [
    selectedUserDetail.mailType.id,
    selectedUserDetail.outgoingMailSettings?.outgoingType,
  ]);

  /**
   * Disable next button or not
   */
  const disableNextButton = useMemo(() => {
    return (
      !selectedUserDetail.isDomainVerified ||
      !(
        selectedUserDetail.outgoingMailSettings?.integrations &&
        selectedUserDetail.outgoingMailSettings.integrations[selectedType]
          ?.verified
      )
    );
  }, [
    selectedType,
    selectedUserDetail.isDomainVerified,
    selectedUserDetail.outgoingMailSettings?.integrations,
  ]);

  /**
   * Handler for selecting outgoing mail type
   */
  const handleSelect = useCallback(
    (selectedType: OutgoingRecieveType) => {
      setSelectedType(selectedType);
      if (
        selectedUserDetail.outgoingMailSettings?.integrations &&
        selectedUserDetail.outgoingMailSettings?.integrations[selectedType]
          ?.verified
      ) {
        updateEmailIntegration({
          emailIntegrationId: Number(selectedUserDetail.emailIntegrationId),
          outgoingMailSettingType: selectedType,
        });
      }
    },
    [selectedUserDetail.outgoingMailSettings?.integrations],
  );

  return {
    loader,
    checkingAuth,
    navigate,
    disableNextButton,
    selectedType,
    handleSelect,
    selectedUser: selectedUserDetail,
  };
};

export default useDomainIntegration;
