import { useCallback, useEffect, useId, useMemo, useState } from "react";
import useBugPriorityList from "../../../../../FilterSection/Priority/useBugPriorityList";
import useBugCategoryList from "../../../../../FilterSection/BugCategory/useBugCategoryList";
import mergeBug, { MergeBugReport } from "src/services/BugReport/mergeBug.service";
import styles from "./MergeModalView.module.scss";
import { Dropdown, Spinner } from "react-bootstrap";
import Loader from "src/components/Loader";
import InfiniteScroll from "src/components/InfiniteScroll";
import { capitalizeFirstLetter } from "../../../../../helper/helperFunction";
import ProfileImgGroup from "../../../../../ProfileImgGroup/ProfileImgGroup";
import { useMutation } from "@tanstack/react-query";
import updateBug from "src/services/BugReport/updateBug.service";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";

const MergeModalView = ({
  mergeData,
}: {
  mergeData: MergeBugReport | undefined;
}) => {
  const [selectedCategory, setSelectedCategory] = useState({
    id: mergeData?.categoryDetails.categoryId,
    name: capitalizeFirstLetter(mergeData?.categoryDetails.categoryName ?? ""),
  });
  const [selectedPriority, setSelectedPriority] = useState(
    mergeData?.priorityKey,
  );
  const [showLoadMore, setShowLoadMore] = useState(false);
  const [ddOpen, setDdOpen] = useState(false);
  const componentId = useId();

  const { allBugPriority } = useBugPriorityList();
  const {
    bugCategories,
    isError,
    isLoading,
    nextPageLoad,
    hasNextPage,
    bugCategoriesIds,
  } = useBugCategoryList();

  const {
    mutate: updateCategory,
    isLoading: updateCategoryLoading,
  } = useMutation({
    mutationFn: updateBug,
    onSuccess: () => {
      pushTheToast({
        type: "success",
        text: "Successfully category updated",
        position: "top-right",
      });
    },
    onError: () => {
      pushTheToast({
        type: "danger",
        text: "Unable to updated!!",
        position: "top-right",
      });
    },
  });

  const {
    mutate: updatePriority,
    isLoading: updatePriorityLoading,
  } = useMutation({
    mutationFn: updateBug,
    onSuccess: () => {
      pushTheToast({
        type: "success",
        text: "Successfully Priority updated",
        position: "top-right",
      });
    },
    onError: () => {
      pushTheToast({
        type: "danger",
        text: "Unable to updated!!",
        position: "top-right",
      });
    },
  });

  const showPriority = Object.values(allBugPriority?.allBugPriority ?? {});

  const fetchNextPageHandler = useCallback(() => {
    nextPageLoad();
    setShowLoadMore(!showLoadMore);
  }, [nextPageLoad]);

  return (
    <div className="d-flex align-items-center flex-wrap pb-2">
      {mergeData?.customerDetails.length ? (
        <div className="d-flex align-items-center mt-3">
          <ProfileImgGroup customers={mergeData.customerDetails} />
          {/* <BugCategory /> */}
          <Dropdown
            show={ddOpen}
            onToggle={setDdOpen}
          >
            {/* Dropdown toggle button */}
            <Dropdown.Toggle
              as="div"
              bsPrefix={`dropdown-toggle cursor-pointer border-0 px-2 py-1 me-2  mb-2 mb-lg-0 ${styles.filterButton}`}
            >
              {updateCategoryLoading && (
                <Spinner
                  size="sm"
                  className="me-1"
                />
              )}
              Category: {selectedCategory.name}
              {/* Display dropdown arrow */}
            </Dropdown.Toggle>

            {/* Dropdown menu */}
            <Dropdown.Menu
              bsPrefix={`dropdown-menu border-0 ${styles.filterMenu}`}
            >
              <div className={`${styles.dropMenu}`}>
                {/* List of filters */}
                <div className={`${styles.itemWrapper}`}>
                  <div>
                    {isLoading && bugCategoriesIds.length === 0 ? (
                      <div>
                        <Loader />
                      </div>
                    ) : (
                      <InfiniteScroll
                        loadMore={fetchNextPageHandler}
                        hasMore={hasNextPage}
                        initialDataLoaded={true}
                        className={`${styles.filterList}`}
                        loader={
                          <div>
                            {showLoadMore ? (
                              // Rendering the load button conditionally.
                              <div
                                className={`${styles.loaderText}`}
                                onClick={fetchNextPageHandler}
                              >
                                <span>Load More</span>
                                <i className="ms-1 fa-solid fa-rotate-right"></i>
                              </div>
                            ) : (
                              // Rendering the load text conditionally.
                              <div className={`${styles.loadingText}`}>
                                <span>Loading...</span>
                                <div>
                                  <Spinner
                                    className={`ms-1 ${styles.spinner}`}
                                    size="sm"
                                    animation="border"
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        }
                      >
                        {isError ? (
                          <span
                            className={`d-flex align-items-center justify-content-center mt-3 ${styles.errorMessage}`}
                          >
                            Something went worng...
                          </span>
                        ) : (
                          bugCategoriesIds.map((categoryId: any) => {
                            return (
                              <div
                                className={`${styles.specificItem}`}
                                key={categoryId}
                                onClick={() => {
                                  setSelectedCategory({
                                    id: categoryId,
                                    name: capitalizeFirstLetter(
                                      bugCategories[categoryId].name,
                                    ),
                                  });
                                  setDdOpen(false);
                                  updateCategory({id: mergeData.bugId, categoryId: categoryId});
                                }}
                              >
                                <div
                                  className={``}
                                  key={categoryId}
                                >
                                  <label
                                    className={`cursor-pointer form-check-label w-100 pe-2 ${styles.checktext}`}
                                    id={
                                      componentId + "selectFilter" + categoryId
                                    }
                                    htmlFor={
                                      componentId + "selectFilter" + categoryId
                                    }
                                  >
                                    {/* Display user information */}
                                    <div className="d-flex justify-content-between align-items-center">
                                      <div className="w-100 text-truncate">
                                        <span className="ps-2">
                                          {capitalizeFirstLetter(
                                            bugCategories[categoryId].name,
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  </label>
                                </div>
                              </div>
                            );
                          })
                        )}
                      </InfiniteScroll>
                    )}
                  </div>
                </div>
              </div>
            </Dropdown.Menu>
          </Dropdown>
          {/* <Priority /> */}
          <Dropdown className="ms-1">
            <Dropdown.Toggle
              variant=""
              id="dropdown-basic"
              className={`d-flex align-items-center ${styles.dropdownBorder}
              ${
                selectedPriority === "normal"
                  ? styles.priorityBlue
                  : selectedPriority === "high"
                    ? styles.priorityGreen
                    : styles.priorityRed
              }
              `}
            >
              {updatePriorityLoading && (
                <Spinner
                  size="sm"
                  className="me-1"
                />
              )}
              <div>Priority:</div>
              <span className={`px-1 ${styles.submitText}`}>
                {selectedPriority === "needThisFixedRightAway"
                  ? "I need this fixed right away"
                  : capitalizeFirstLetter(selectedPriority ?? "")}
              </span>
            </Dropdown.Toggle>

            <Dropdown.Menu className={`${styles.dropdownBox}`}>
              {showPriority.map((priority) => (
                <Dropdown.Item
                  className={`d-flex align-items-center ${styles.dropdownItem}`}
                  onClick={() => {
                    setSelectedPriority(priority.key);
                    updatePriority({ id: mergeData.bugId, priorityKey: priority.key });
                  }}
                  key={priority.id}
                >
                  {priority.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default MergeModalView;
