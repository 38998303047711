/**
 * This file is the service file used for making api request.
 * It contains the reportTicketGraphService function service which is explained below.
 *
 * @author Yash Aditya
 * @author Anubhav Jain
 */

import { axiosJSON } from "src/globals/axiosEndPoints";
import { FilterInParam } from "src/routes/Report/hooks/reportFilters/useReportFilters";
import {
  ReportDateType,
  ReportInternalFilters,
} from "src/routes/Report/hooks/reportFilters/useReportInternalFilters";
import { CurrentStatusEndPoints } from "./reportTicketList.service";

interface Titles {
  title: string;
  style?: { [key: string]: string };
  min?: number;
  max?: number;
  tickPositions?: Array<number>;
  labels?: {
    enabled: boolean;
    labelName: {
      [key: string]: string;
    };
  };
}
type ChartTypes = "line" | "column" | "heatmap" | "spline";
interface SeriesValue {
  name: string;
  type: ChartTypes;
  data: Array<string>;
  color?: string;
}

interface ColorAxis {
  min: number;
  minColor: string;
  maxColor: string;
}

export interface ReportTicketGraphParams {
  viewType: "chartView" | "heatmapView";
  filters?: FilterInParam;
  internalFilters?: ReportInternalFilters;
  reportDateType?: ReportDateType;
  integrationId?: string;
  botProfileId?: string;
}

export interface ReportTicketGraphData {
  categories: Array<string>; // category names
  xAxis: Titles;
  yAxis: Titles;
  graphTitle: Titles;
  chartType: ChartTypes;
  series: Array<SeriesValue>;
  colorAxis: ColorAxis;
  seriesDataWholeNumber?: boolean; //flag whether to show whole number or decimal number
}

/**
 * This service is dynamic for all the graphs and brings graph data from backend based on the given parameters and endpoint.
 */
export const reportTicketGraphService = async (
  params: ReportTicketGraphParams,
  endPoint: CurrentStatusEndPoints
) => {
  const { data: res } = await axiosJSON.post(
    `/api/reports/${endPoint}`,
    params
  );

  if (res.err || res.error) {
    throw res.msg;
  }
  return res.data as ReportTicketGraphData;
};

export default reportTicketGraphService;
