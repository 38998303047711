import styles from "./ComponentStyles.module.scss";

function NumberInputRange(props: any) {
  return (
    <div className="d-flex flex-column">
      <div className="ms-md-2 mb-2 mb-md-0 d-flex flex-row align-items-center">
        <div>
          <input
            type="number"
            className={`${styles.textBox} ${
              props.value.error ? styles.borderDanger : ""
            }`}
            placeholder={props.placeholder}
            value={props.value.currentValue.minValue}
            onChange={(e) => {
              props.onChange({
                maxValue: props.value.currentValue.maxValue,
                minValue: e.target.value,
              });
            }}
            min={0}
          />
        </div>
        <span className="ms-2">and</span>
        <div className="ms-2">
          <input
            type="number"
            className={`${styles.textBox} ${
              props.value.error ? "border-danger" : ""
            }`}
            placeholder={props.placeholder}
            value={props.value.currentValue.maxValue}
            onChange={(e) =>
              props.onChange({
                maxValue: e.target.value,
                minValue: props.value.currentValue.minValue,
              })
            }
          />
        </div>
      </div>
      <div className={`${styles.errorStyle} d-flex`}>
        <span className={`max-content-width ${styles.errorText}`}>
          {props.value.error &&
            props.value.error.length > 0 &&
            props.value.error}
        </span>
      </div>
    </div>
  );
}

export default NumberInputRange;
