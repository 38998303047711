import { axiosJSON } from "src/globals/axiosEndPoints";
import APIResponse from "./APIReponse";

export interface DeactivateUserPayload {
  userId: number;
  isActivate: boolean;
}

export async function deactivateUser(payload: DeactivateUserPayload) {
  const { data: res } = await axiosJSON.post(
    `/api/setting/user/deactivateUser`,
    payload
  );

  if (res.err === true) {
    throw new Error(res.msg as string);
  }
  return res.data;
}
