import React, { useState } from "react";
import styles from "./CreateTemp.module.scss";
import CreateTempHeader from "./Children/CreateTempHeader/CreateTempHeader";
import TemplateSidebar from "./Children/TemplateSidebar/TemplateSidebar";
import TemplateForm from "./Children/TemplateForm/TemplateForm";
import { addTemplate } from "src/services/Templates/addTemplate";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { Spinner } from "react-bootstrap";

const CreateTemp = ({
  createTemplateText = "",
  currentView,
  setCurrentView = () => {},
  hideBack = false,
  setAllTemplateSelectedCategory,
}: {
  createTemplateText?: string;
  currentView: any;
  setCurrentView?: any;
  hideBack?: boolean;
  setAllTemplateSelectedCategory?: (value:any)=>void;
}) => {
  const [selectedCategory, setSelectedCategory] = useState<any>({});
  const [selectedCategoryError, setSelectedCategoryError] =
    useState<boolean>(false);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [templateHeading, setTemplateHeading] = useState<string>("");
  const [templateHeadingError, setTemplateHeadingError] =
    useState<boolean>(false);
  const [templateBody, setTemplateBody] = useState<string>(createTemplateText);
  const [templateBodyError, setTemplateBodyError] = useState<boolean>(false);

  function addTemplateHandler() {
    if (
      templateBody.trim() === "" ||
      templateHeading.trim() === "" ||
      !selectedCategory
    ) {
      pushTheToast({
        type: "warning",
        text: "Please fill all the fields",
        position: "top-right",
      });
    }
    // setAddScreen(false);
    // setShowAddTemplate(false);
    setShowLoader(true);
    addTemplate({
      template_category_id: selectedCategory.id,
      template_body: templateBody,
      templateHeading: templateHeading,
    })
      .then((data) => {
        pushTheToast({
          type: "success",
          text: "Template Created Successfully",
          position: "top-right",
        });
        setTemplateHeading("");
        setTemplateBody("");
        if(setAllTemplateSelectedCategory){
          setAllTemplateSelectedCategory(selectedCategory);
          setCurrentView("allTemplates");
        }
        // setShowTemplateDone(true);
        // setTemplateText(template);
      })
      .catch((err) => {
        pushTheToast({
          type: "danger",
          text: err.message,
          position: "top-right",
        });
      })
      .finally(() => {
        setShowLoader(false);
      });
  }

  // function used to validate input
  const validateCreateTemplateInput = () => {
    let hasError: boolean = false;

    if (selectedCategory && selectedCategory.id) {
      setSelectedCategoryError(false);
    } else {
      hasError = true;
      setSelectedCategoryError(true);
    }

    if (templateHeading.trim().length) {
      setTemplateHeadingError(false);
    } else {
      hasError = true;
      setTemplateHeadingError(true);
    }

    if (templateBody.trim().length) {
      setTemplateBodyError(false);
    } else {
      hasError = true;
      setTemplateBodyError(true);
    }

    return hasError;
  };

  return (
    <div>
      <CreateTempHeader
        currentView={currentView}
        setCurrentView={setCurrentView}
        hideBack={hideBack}
      />
      <div className={`${styles.scrollHeight}`}>
        <div className="d-flex flex-column flex-md-row">
          <TemplateSidebar
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            selectedCategoryError={selectedCategoryError}
          />
          <TemplateForm
            selectedCategory={selectedCategory}
            templateHeading={templateHeading}
            setTemplateHeading={setTemplateHeading}
            templateBody={templateBody}
            setTemplateBody={setTemplateBody}
            selectedCategoryError={selectedCategoryError}
            templateHeadingError={templateHeadingError}
            templateBodyError={templateBodyError}
          />
        </div>
      </div>
      <button
        className={`w-100 ${styles.createBtn}`}
        onClick={() => {
          if (!showLoader && !validateCreateTemplateInput()) {
            addTemplateHandler();
          }
        }}
      >
        {showLoader && (
          <>
            <Spinner
              className="my-auto mx-1"
              animation="border"
              color="white"
              size="sm"
            />
          </>
        )}
        Create{" "}
      </button>
    </div>
  );
};

export default CreateTemp;
