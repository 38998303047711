import React from "react";
import styles from "./ReturnMethodInstructions.module.scss";
import download from "src/assets/images/chat/Download.png";
import { useAppSelector } from "src/store/store";
import refresh from "src/assets/images/chat/refreshButton.png";

function ReturnMethodInstructions() {
  const selectedReturnMethod = useAppSelector((state) => {
    return state.step14Config.returnMethods.find(
      (retM) =>
        retM.returnMethodId === state.step14Config.selectedReturnMethodId
    );
  });

  return (
    <>
        <div className={`d-flex align-items-center`}>
          <div>
            <img src={refresh} alt="refresh" className={`${styles.refresh}`} />{" "}
          </div>
          <span className={`ms-1 ${styles.heading}`}>
              Return Method{" "}
          </span>
        </div>

      {selectedReturnMethod?.shipWithAnyCarrier.showInstruction ? (
        <div className={`d-block`}>
          <p className={`pt-3 mb-2 ${styles.return}`}>Return Instructions :</p>
          <div>
            <p 
              className={`${styles.instruction}`} 
              // style={{wordBreak: "break-all", whiteSpace: "pre-wrap"}}
            >
              {selectedReturnMethod.returnMethodType.message}
            </p>
          </div>
          {selectedReturnMethod?.returnMethodType.value ===
          "returnRequiredWithLabels" ? (
            <button
              className={`d-block text-white w-100 py-1 ${styles.downloadBtn}`}
            >
              <span className={`${styles.downloadSpan}`}>
                Download return label
              </span>{" "}
              <img
                src={download}
                className={`ms-1 ${styles.downloadImg}`}
                alt=""
              />{" "}
            </button>
          ) : null}
        </div>
      ) : (
        <div className={`d-block`}>
          <p className={`pt-3 mb-2 ${styles.return}`}>Return Instructions :</p>
          <div>
            <p className={`${styles.instruction}`} style={{wordBreak: "break-all", whiteSpace: "pre-wrap"}}>
              {selectedReturnMethod?.returnMethodType.message}
            </p>
          </div>
          {selectedReturnMethod?.returnMethodType.value ===
          "returnRequiredWithLabels" ? (
            <button
              className={`d-block text-white w-100 py-1 ${styles.downloadBtn}`}
            >
              <span className={`${styles.downloadSpan}`}>
                Download return label
              </span>{" "}
              <img
                src={download}
                className={`ms-1 ${styles.downloadImg}`}
                alt=""
              />{" "}
            </button>
          ) : null}
        </div>
      )}
    </>
  );
}

export default ReturnMethodInstructions;
