import { axiosJSON } from "src/globals/axiosEndPoints";
import {
  ReturnAutomationWorkFlowDetails,
  StepData,
  StepQuestion,
} from "src/features/ReturnAutoWorkFlow/ReturnAutoWorkFlow.types";

/**
 * Fetches Return Automation Workflow details by integration ID.
 *
 * @param payload - The payload object containing the integration ID.
 * @param payload.integrationId - The ID of the integration to fetch the workflow details for.
 * @param payload.steps - steps filter is added to get the updates for those specific steps or questions.
 *
 * @returns A promise that resolves to the details of the return automation workflow.
 *
 * @throws Will throw an error if the request fails or if the response contains an error message.
 *
 * @example
 * ```typescript
 * const payload = { integrationId: "12345" };
 * const res = await getReturnAutoWorkFlowByIdService(payload);
 * ```
 *
 */
async function getReturnAutoWorkFlowByIdService(payload: {
  integrationId: string;
  steps?: Array<{
    stepId: string;
    questionIds: Array<string>;
  }>;
}) {
  const { data: res } = await axiosJSON.post(
    "/api/setting/automation/refund/getById",
    payload,
  );

  if (res.err || res.error) {
    throw new Error(
      res.msg ?? res.message ?? "Some Error Occurred while fetching data.",
    );
  }

  const ret: ReturnAutomationWorkFlowDetails = {
    integrationId: res.data.integrationId,
    configured: res.data.configured,
    published: res.data.published,
    brand: res.data.brand,
    configuredSteps: {},
    configuredStepIds: [],
    savedStepAndQuestionIds: res.data.savedStepAndQuestionIds,
    activeStepId:
      typeof res.data.activeStepId === "number"
        ? res.data.activeStepId + ""
        : res.data.activeStepId,
  };

  /**
   * `res.data.configuredSteps` is sent as `Array<StepData>` from backend.
   * Hence we need to map it to record.
   */
  for (let i = 0; i < res.data.configuredSteps.length; i++) {
    const step = res.data.configuredSteps[i] as StepData;
    step.stepId = step.stepId + "";

    /**
     * `step.stepQuestions` is sent as `Array<StepQuestion>` from backend.
     * Hence we need to map it to record.
     * Hence saving the question in another variable first.
     */
    const questions = step.stepQuestions as unknown as Array<StepQuestion>;
    /**
     * Then making it record.
     */
    step.stepQuestions = {};
    /**
     * Creating Array of question ids.
     */
    step.stepQuestionIds = [];
    /**
     * Mapping the questions here.
     */
    for (let j = 0; j < questions.length; j++) {
      const question = questions[j];
      question.questionId = question.questionId + "";
      step.stepQuestions[question.questionId] = question;
      step.stepQuestionIds[j] = question.questionId;
    }

    ret.configuredSteps[step.stepId] = step;
    ret.configuredStepIds[i] = step.stepId;
  }

  return ret;
}

export default getReturnAutoWorkFlowByIdService;
