import styles from "./SetupRow.module.scss";
import { IoIosCopy } from "react-icons/io";
import { useState } from "react";
import { OverlayTrigger } from "react-bootstrap";
interface Props {
  status: boolean;
  record: string;
  host: string;
  value: string;
  cValue: string;
}
const SetupRow = ({ status, record, host, value, cValue }: Props) => {
  const [copied, setCopied] = useState(false);
  const [copiedValue, setCopiedValue] = useState(false);

  const copyToClipBoard = async (copyMe: string, isValue?: boolean) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      if (isValue) {
        setCopiedValue(true);
      } else {
        setCopied(true);
      }
      await new Promise((resolve) => setTimeout(resolve, 1000));
    } catch (err) {
      console.log(err);
    } finally {
      setCopied(false);
      setCopiedValue(false);
    }
  };
  return (
    <>
      <div
        className={`d-flex my-3 px-2 py-3 overflow-hidden align-items-center ${styles.tableHeader} `}
      >
        {status ? (
          <div className={`${styles.status}`}>
            <span className={`${styles.statusSpanVerified}`}>Verified </span>
          </div>
        ) : (
          <div className={`${styles.status}`}>
            <span className={`${styles.statusSpan}`}>Not Verified </span>
          </div>
        )}

        <div className={`${styles.record}`}>
          <span className={`${styles.headerText}`}>
            {record.toUpperCase()}{" "}
          </span>
        </div>
        <div
          className={`${styles.host} d-flex align-items-center justify-content-between p-2 me-2`}
        >
          <span className={`${styles.headerText} px-1`}>{host} </span>
          <OverlayTrigger
            placement="top"
            overlay={
              <div className={`${styles.tooltip} p-2`}>
                {copied ? "Copied!" : "Copy Host"}
              </div>
            }
          >
            <span className={`${styles.copyBtn} px-1`} role="button">
              <IoIosCopy
                onClick={(e) => {
                  e.preventDefault();
                  copyToClipBoard(host);
                }}
              />
            </span>
          </OverlayTrigger>
        </div>
        <div
          className={`${styles.value} d-flex align-items-center justify-content-between p-2 me-2`}
        >
          <span className={`${styles.headerText} px-1`}>{value} </span>
          <OverlayTrigger
            placement="top"
            overlay={
              <div className={`${styles.tooltip} p-2`}>
                {copiedValue ? "Copied!" : "Copy Value"}
              </div>
            }
          >
            <span className={`${styles.copyBtn} px-1`} role="button">
              <IoIosCopy
                onClick={(e) => {
                  e.preventDefault();
                  copyToClipBoard(value, true);
                }}
              />
            </span>
          </OverlayTrigger>
        </div>
        <div className={`${styles.cValue}`}>
          <span className={`${styles.headerText}`}> {cValue} </span>
        </div>
      </div>
    </>
  );
};
export default SetupRow;
