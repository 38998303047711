import { GetTeam } from "src/services/Settings/Teams/getTeam";
import { TeamsType } from "./teamSettings.slice";

export const fetchAllTeamsFulfilled = (state: TeamsType, { payload }: any) => {
  const { teamsData, teamIdList, count, totalCount }: any = payload as any;

  state.teamData = {
    ...state.teamData,
    ...teamsData,
  };

  // Create a unique array of ids
  state.teamIdList = Array.from(new Set([...state.teamIdList, ...teamIdList]));
  state.metaData.count = count;
  state.metaData.total = totalCount;
  state.fetchTeamsAjaxStatus = "fulfilled";
};

export const fetchAllTeamMembersFulfilled = (
  state: TeamsType,
  { payload }: any,
) => {
  const { users, userIds, teamId, teamName, count, totalCount }: any =
    payload as any;

  state.usersInTeam = {
    ...state.usersInTeam,
    ...users,
  };
  state.usersInTeamIdList = [...state.usersInTeamIdList, ...userIds];
  state.usersInTeamCount = count;
  state.usersInTeamTotalCount = totalCount;
  state.fetchUsersInTeamAjaxStatus = "fulfilled";
};

export default {
  fetchAllTeamsFulfilled,
  fetchAllTeamMembersFulfilled,
};
