import React, { useCallback, useMemo, useState } from "react";
import styles from "./ResponseModal.module.scss";
import sendLogo from "src/assets/images/send.png";
import successImg from "src/assets/images/success.png";
import { Modal, Spinner } from "react-bootstrap";
import { CustomerTH } from "src/services/LiveChat/liveChatHistory/getCustomerTicketsHistory";
import { useNavigate } from "react-router-dom";
import success from "src/assets/images/donePic.gif";
import { ESendTypes } from "src/routes/Ticket/children/MainChat/children/SendMessage/children/SendingAs";

export type THModalViewType = "PreSend" | "PostSend" | "Error" | "Pending" | "";
interface Props {
  showModal: THModalViewType;
  setShowModal: React.Dispatch<React.SetStateAction<THModalViewType>>;
  selectedTicket: CustomerTH;
  sendMessages: () => void;
  setShowDropDown: React.Dispatch<React.SetStateAction<boolean>>;
  sendType: number;
}

const ResponseModal = ({
  showModal,
  setShowModal,
  selectedTicket,
  sendMessages,
  setShowDropDown,
  sendType,
}: Props) => {
  const code = `Ticket ID: ${selectedTicket.ticket_id ?? ""} <${
    selectedTicket.ticket_subject ?? ""
  }>`;
  const isNote = useMemo(() => ESendTypes.Note === sendType, [sendType]);
  const navigate = useNavigate();
  const show = useMemo(() => {
    return showModal !== "";
  }, [showModal]);
  const isPre = useMemo(() => {
    return (
      showModal === "PreSend" ||
      showModal === "Pending" ||
      showModal === "Error"
    );
  }, [showModal]);
  const isPost = useMemo(() => {
    return showModal === "PostSend";
  }, [showModal]);
  const isLoading = useMemo(() => {
    return showModal === "Pending";
  }, [showModal]);

  const onHide = useCallback(() => {
    if (showModal !== "Pending") {
      setShowModal("");
    }
  }, [setShowModal, showModal]);
  const onClickChange = useCallback(() => {
    setShowModal("");
    setShowDropDown(true);
  }, [setShowModal, setShowDropDown]);
  const handleGoToTicket = useCallback(() => {
    navigate(`/tickets/all/ticket-view/ticket/${selectedTicket.ticket_id}/`);
  }, [selectedTicket, navigate]);

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        dialogClassName={`${styles.modalDialog}`}
        contentClassName={`${styles.modalContent}`}
        backdropClassName={`${styles.backdropClassName}`}
      >
        <div>
          <span
            className={`d-flex justify-content-end cursor-pointer`}
            onClick={onHide}
          >
            <i className="fa-solid fa-xmark"></i>
          </span>
          <div>
            <h3 className={`${styles.heading}`}>
              {isPre
                ? isNote
                  ? `Add note on Ticket id: ${selectedTicket.ticket_id}`
                  : `Your response will go as a response on Ticket id: ${selectedTicket.ticket_id}`
                : isNote
                ? `Added note on Ticket id: ${selectedTicket.ticket_id}`
                : `Response sent on Ticket id: ${selectedTicket.ticket_id}`}
            </h3>
            <p className={`mb-2 w-100 d-flex align-items-center row px-0 mx-0`}>
              {isPre && (
                <span
                  className={`col-${isNote ? "3" : "2"} px-0 me-2 ${
                    styles.replyTicket
                  }`}
                >
                  {isNote ? `Add note` : `Reply`} to ticket:
                </span>
              )}
              <span
                className={`p-2 col-${isNote ? "6" : "7"} text-truncate ${
                  styles.code
                }`}
              >
                {code}
              </span>
              {isPre && (
                <span
                  className={`col-2 ${styles.change}`}
                  onClick={onClickChange}
                >
                  Change
                </span>
              )}
            </p>
            {isPre && !isNote && (
              <span className={`w-100 text-truncate ${styles.subject}`}>
                Subject: {selectedTicket.ticket_subject}
              </span>
            )}
            {isPost && (
              <div className="text-center my-5">
                <img src={success} alt="" className={`${styles.successImg}`} />
              </div>
            )}
            <div
              className={`d-flex justify-content-between align-items-center mt-4 pt-2`}
            >
              {isPre && (
                <>
                  <button
                    className={`me-1 px-2 d-flex justify-content-center align-items-center ${styles.noBtn}`}
                    onClick={onHide}
                  >
                    No, Let me change
                  </button>
                  <button
                    className={`d-flex px-2 justify-content-center align-items-center ${styles.yesBtn}`}
                    onClick={sendMessages}
                  >
                    Yes, {isNote ? "Add" : "Send"}
                    {isLoading ? (
                      <Spinner
                        size="sm"
                        animation="border"
                        className={`my-auto ms-2`}
                      />
                    ) : (
                      <img
                        src={sendLogo}
                        className={`my-auto ms-2 ${styles.sendImg}`}
                      />
                    )}
                  </button>
                </>
              )}
              {isPost && (
                <>
                  <button
                    className={`px-3   me-1 ${styles.noBtn}`}
                    onClick={onHide}
                  >
                    Okay
                  </button>
                  <button
                    className={`px-3 ${styles.yesBtn}`}
                    onClick={handleGoToTicket}
                  >
                    Go to ticket
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ResponseModal;
