import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { ICreateTicketFromChatRes } from "src/services/LiveChat/createTicket/createTicketFromChat.service";
import { IGetLiveChatThreadRes } from "src/services/LiveChat/createTicket/getLiveChatThread.service";
import { ILiveChatTabQuickActionsState } from "./liveChatTabQuickActions.slice";

const fetchLiveChatThreadFulfilled = (
  state: ILiveChatTabQuickActionsState,
  { payload }: { payload: IGetLiveChatThreadRes }
) => {
  const { threadText }: IGetLiveChatThreadRes = payload as any;

  state.threadText = threadText;

  state.fetchLiveChatThreadAjaxStatus = "fulfilled";
};

const createTicketFromChatFulfilled = (
  state: ILiveChatTabQuickActionsState,
  { payload }: { payload: ICreateTicketFromChatRes }
) => {
  const { ticket_id }: ICreateTicketFromChatRes = payload as any;

  state.createdTicketId = ticket_id;

  state.createTicketFromLiveChatAjaxStatus = "fulfilled";

    // pushTheToast({
    //   text: "A ticket has been created",
    //   position: "top-right",
    //   type: "success",
    // });
};

export default { fetchLiveChatThreadFulfilled, createTicketFromChatFulfilled };
