import { createContext, useCallback, useContext, useMemo } from "react";
import { AutomationAPIPayload } from "src/services/Automation/createAutomation.service";
import {
  IAutomationData,
  Question,
} from "src/services/Automation/getAutomationById.service";
import { UpdateAutomationParams } from "src/services/Automation/updateAutomation.service";
import {
  UpdateQuestionsParams,
  UpdateQuestionsStep,
} from "src/services/Automation/updateQuestions.service";
import { EAutomationType } from "../../AutomationList/AutomationList";
import {
  findMoveToNextButtonPosition,
  updateVisibilityAndStatus,
} from "../helpers/Automation.helpers";
import useAutomationUpdate, {
  automationInitialState,
} from "./useAutomationUpdate";

const debounce = <T extends (...args: any[]) => any>(
  func: T,
  delay: number,
): ((...args: Parameters<T>) => void) => {
  let timeoutId: ReturnType<typeof setTimeout>; // Timeout ID, inferred to be of the type returned by setTimeout

  return (...args: Parameters<T>): void => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

interface AutomationContextType {
  formData: IAutomationData;
  type?: EAutomationType;
  integrationId?: string;
  updateFormData: (data: Partial<IAutomationData>) => void;
  refetchAutomation: () => void;
  updateQuestions: (
    updatedQuestion: Question,
    updateRelatedQuestionVisibility?: boolean,
  ) => void;
  saveQuestionsData: (
    data: UpdateQuestionsStep,
    needDebounce?: boolean,
  ) => void;
  saveData: (
    data: Omit<UpdateAutomationParams, "integrationId">,
    needDebounce?: boolean,
  ) => void;
  isLoading: boolean; // Loading state
  isSaving: boolean; // Saving state
  errorMessage: string | null; // Error message, either string or null if no error
}

const initialState: AutomationContextType = {
  formData: automationInitialState, // Initial formData state
  updateFormData: () => {}, // Initial empty function for setFormData
  updateQuestions: () => {}, // Initial empty function for updateQuestions
  refetchAutomation: () => {},
  saveQuestionsData: () => {},
  saveData: () => {},
  isLoading: false, // Initial loading state
  isSaving: false, // Initial saving state
  errorMessage: null, // Initial error message state
};

const AutomationContext = createContext<AutomationContextType>(initialState);

export const useAutomationContext = () => {
  const context = useContext(AutomationContext);

  if (!context) {
    throw new Error(
      "useAutomationContext must be used within a AutomationContextProvider",
    );
  }

  return context;
};

interface AutomationProviderReturn extends AutomationContextType {
  Provider: React.Provider<AutomationContextType>;
}

export const useAutomationProvider = (): AutomationProviderReturn => {
  const {
    type,
    refetch,
    integrationId,
    isFetching,
    isSaving,
    errorMessage,
    formData,
    setFormData,
    updateConfigMutation,
    updateQuestionsMutation,
  } = useAutomationUpdate();

  // State to track if updates have occurred
  // const [hasUpdates, setHasUpdates] = useState(false);

  const updateFormData = useCallback(
    (data: Partial<IAutomationData>) => {
      setFormData((currentFormData) => {
        if (currentFormData.integrationId) {
          return {
            ...currentFormData,
            ...data,
          };
        }

        return currentFormData;
      });
    },
    [setFormData],
  );

  // Create a debounced version of the save function using custom debounce
  const debouncedSaveQuestions = useMemo(
    () =>
      debounce((params: AutomationAPIPayload<UpdateQuestionsParams>) => {
        // setHasUpdates(true); // Mark updates flag
        updateQuestionsMutation(params);
      }, 700), // Adjust the debounce delay as needed
    [updateQuestionsMutation],
  );

  // Create a debounced version of the save function using custom debounce
  const debouncedSave = useMemo(
    () =>
      debounce((params: AutomationAPIPayload<UpdateAutomationParams>) => {
        // setHasUpdates(true); // Mark updates flag
        updateConfigMutation(params);
      }, 700), // Adjust the debounce delay as needed
    [updateConfigMutation],
  );

  const saveQuestionsData = useCallback(
    (data: UpdateQuestionsStep, needDebounce = false) => {
      if (type && integrationId) {
        const params: AutomationAPIPayload<UpdateQuestionsParams> = {
          payload: {
            integrationId,
            stepData: [data],
          },
          type,
        };

        if (needDebounce) {
          debouncedSaveQuestions(params);
        } else {
          // setHasUpdates(true); // Mark updates flag
          updateQuestionsMutation(params);
        }
      }
    },
    [debouncedSaveQuestions, integrationId, type, updateQuestionsMutation],
  );

  const saveData = useCallback(
    (
      data: Omit<UpdateAutomationParams, "integrationId">,
      needDebounce = false,
    ) => {
      if (type && integrationId) {
        const params: AutomationAPIPayload<UpdateAutomationParams> = {
          payload: {
            integrationId,
            ...data,
          },
          type,
        };

        if (needDebounce) {
          debouncedSave(params);
        } else {
          // setHasUpdates(true); // Mark updates flag
          updateConfigMutation(params);
        }
      }
    },
    [debouncedSave, integrationId, type, updateConfigMutation],
  );

  const updateQuestions = useCallback(
    (updatedQuestion: Question, updateRelatedQuestionVisibility = true) => {
      // Update the question data
      const updatedFormData = {
        ...formData,
        questionsData: {
          ...formData.questionsData,
          [updatedQuestion.questionKey]: updatedQuestion,
        },
      };

      // Recalculate visibility and status
      const recalculatedFormData = updateVisibilityAndStatus(
        updatedFormData,
        updateRelatedQuestionVisibility,
      );

      // Find the position to move to next question if applicable
      const moveToNextQuestionKey = findMoveToNextButtonPosition(
        recalculatedFormData.stepsData,
        recalculatedFormData.questionsData,
      );

      // Update the form data in context
      updateFormData({
        ...recalculatedFormData,
        moveToNextQuestionKey,
      });
    },
    [formData, updateFormData],
  );

  const refetchAutomation = useCallback(() => {
    // if (hasUpdates) {
    refetch();
    // setHasUpdates(false); // Reset the updates flag after fetching
    // }
  }, [refetch]);

  return {
    formData,
    type,
    integrationId,
    updateFormData,
    refetchAutomation,
    updateQuestions,
    saveQuestionsData,
    saveData,
    isLoading: isFetching,
    isSaving,
    errorMessage,
    Provider: AutomationContext.Provider,
  };
};
