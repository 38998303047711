import React, { useMemo, useCallback } from "react";
import { bugManagementCurrentStatusActions, useBugManagementFilters } from "../../../Hooks/useBugManagementFilter";
import FilterSelect from "../FilterSelect/FilterSelect";
import useBugStatusList from "./useBugStatusList";
import useBugPriorityList from "../Priority/useBugPriorityList";

interface Props {
  addFilters: (
    selectedStatus: Array<string | undefined>,
    selectedPriority: Array<string | undefined>,
    selectedCategory: Array<string>,
  ) => void;
}

const StatusFilter = ({addFilters}: Props) => {
  const { allBugStatus } = useBugStatusList();
  const { allBugPriority } = useBugPriorityList();

  const allStatusId = useMemo(() => {
    if (!allBugStatus?.allBugStatusIds.length) {
      return [];
    }
    return allBugStatus?.allBugStatusIds.map((id) => id + "");
  }, [allBugStatus]);

  // get current selected filter ids
  const { currentStatus, dispatch } = useBugManagementFilters();

  const selectedStatusFilter = useMemo(() => {
    return currentStatus.status.map(
      (id) => allBugStatus?.allBugStatus?.[id]?.key ?? "",
    );
  }, [currentStatus.status]);

  const selectedPriority = useMemo(() => {
    return currentStatus.priority.map(
      (id) => allBugPriority?.allBugPriority?.[id]?.key ?? "",
    );
  }, [currentStatus.priority]);

  const selectedStatus = useMemo(() => {
    return currentStatus.status;
  }, [currentStatus]);

  // fucntion to add and remove selected filter to useContenxt
  const checkUncheckStatus = useCallback(
    (e: any) => {
      if (e.target.checked) {
        dispatch([
          bugManagementCurrentStatusActions.setFilters,
          { status: [...selectedStatus, e.target.value] },
        ]);
      } else {
        dispatch([
          bugManagementCurrentStatusActions.setFilters,
          {
            status: selectedStatus.filter((id) => id !== e.target.value),
          },
        ]);
      }
    },
    [selectedStatus],
  );

  return (
    <FilterSelect
      filterName={"Status"}
      allFilters={allBugStatus?.allBugStatus ?? {}}
      allFilterIds={allStatusId}
      selectedFilters={selectedStatus}
      checkUncheckFilters={checkUncheckStatus}
      applyFilters={() =>
        addFilters(
          selectedStatusFilter,
          selectedPriority,
          currentStatus.bugCategory,
        )
      }
      cancelFilters={() => {}}
      nextPageLoad={() => {}}
    />
  );
};

export default StatusFilter;
