import AxiosImg from "src/components/AxiosImg";
import ParseHTML from "src/components/ParseHTML";
import ShowRelativeTime from "src/components/ShowRelativeTime";
import UserAvatar from "src/components/UserAvatar";
import { Message } from "src/services/LiveChat/messageExchange/getMessages";
import { getChannelFAIcon } from "src/utils/utils";
import InnerChatAttachments from "src/components/InnerChatAttachments/InnerChatAttachments";
import styles from "./MessageInternalNote.module.scss";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  sendLiveMsg,
  SendLiveMsgP,
} from "src/store/slices/liveChatSetting/chatWidExchMsg/chatWidExchMsg.thunks";
import { useMemo, useCallback } from "react";
import { messageSendTryAgainPressed } from "src/store/slices/liveChatSetting/chatWidExchMsg/chatWidExchMsg.slice";
import tryAgainIcon from "src/assets/images/tryAgainIcon.png";

const MessageInternalNote = ({
  messageText,
  sentBy,
  sendTimeGmt,
  attachments,
  sendTime,
  messageId,
  messageType,
}: Message) => {
  const dispatch = useAppDispatch();

  const fetchMessageAjaxStatus = useAppSelector(
    (state) => state.chatWidExhMsg.fetchMsgAJAXStatus
  );
  const sendMessageAjaxStatus = useAppSelector(
    (state) => state.chatWidExhMsg.sendMsgAJAXStatus
  );
  const activeCustomerInfo = useAppSelector(
    (state) => state.chatWidExhMsg.activeCustomerInfo
  );

  const messageStatus = useAppSelector((state) =>
  messageId
    ? state.chatWidExhMsg.messages[messageId]?.messageStatus
    : undefined
);

  // This is used to send the message again when the user clicks on the "Try Again" button
  const tryAgainMessagePayload: SendLiveMsgP = useMemo(() => {
    return {
      chat_id: activeCustomerInfo ? activeCustomerInfo.chatId : "",
      message: messageText,
      messageType: messageType as any,
      attachmentBatchNumber: attachments?.map((attachment) => {
        return attachment.attachmentBatchNumber || attachment.batchNumber;
      }),
      attachments: attachments,
      uuid: messageId as string,
    };
  }, [
    activeCustomerInfo,
    messageText,
    fetchMessageAjaxStatus,
    messageId,
    sendMessageAjaxStatus,
  ]);

  const statusRendering = (status: boolean) => {
    if (status) {
      if (sendMessageAjaxStatus === "pending") {
        return (
          <span className={`d-flex justify-content-end mt-1 ${styles.sending}`}>
            Sending...
          </span>
        );
      } else {
        return "";
      }
    } else {
      if (sendTimeGmt?.trim()) {
        return <ShowRelativeTime dateTimeUTC={sendTimeGmt} />;
      } else {
        return "";
      }
    }
  };
  const tryAgainRendering = () => {
    return (
      <div className={`small text-secondary`}>
        <span className={`${styles.failedText}`}>Failed....</span>
        <button
          id="tryAgain"
          className={` border-0 bg-white ${styles.tryText}`}
          onClick={() => {
            handleSendTryAgainMessage();
          }}
        >
          <img src={tryAgainIcon} alt="" className="me-1" />
          Try again
        </button>
      </div>
    );
  };


  // This is the function that is called when the user clicks on the "Try Again" button
  const handleSendTryAgainMessage = useCallback(() => {
    dispatch(
      sendLiveMsg({
        ...tryAgainMessagePayload,
        tryAgain: true,
      })
    );
    // dispatch(messageSendTryAgainPressed());//commented this dispatch as it is not needed, try again is hided based on message status 
  }, [dispatch, tryAgainMessagePayload]);

  return (
    <>
      <div
        className={`d-flex justify-content-end mb-2 ${styles.msgOut}`}
        style={{ marginLeft: "38px" }}
      >
        <div>
          <div className={`${styles.noteDataDiv} ${styles.msgText}`}>
            <ParseHTML
              html={messageText}
              //  replaceNewLineWithBr={true}
            />

            {attachments && attachments.length > 0 && (
              <div className="mt-2">
                <InnerChatAttachments
                  messageData={{
                    attachments: attachments,
                    senderName: sentBy.name,
                    messageDateTime: sendTime,
                    messageId: messageId,
                  }}
                />
              </div>
            )}
          </div>
          <div className={`d-flex justify-content-start ms-1 mt-2`}>
            {/* Conditional Rendering For Status Like Pending & Rejected */}
            {messageStatus === "pending"
              ? statusRendering(true)
              : messageStatus === "rejected"
              ? tryAgainRendering()
              : statusRendering(false)}
          </div>
        </div>
        <div className="d-flex flex-column align-items-center">
          {sentBy?.imageURL && sentBy?.imageURL.length !== 0 ? (
            <AxiosImg
              url={sentBy.imageURL}
              className={`rounded-circle ${styles.profileImage} mb-1`}
              isDirectURL={sentBy?.isPublicAttachmentUrl}
            />
          ) : (
            <div className="mb-1">
              <UserAvatar name={sentBy?.name ?? "NA"} size={32} />
            </div>
          )}
          {/* <span className={` ${styles.insta}`}>
              <i className={`d-block ${getChannelFAIcon("note")}`}></i>
          </span> */}
        </div>
      </div>
    </>
  );
};
export default MessageInternalNote;
