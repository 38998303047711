/**
 * This file is the component file.
 *
 * @author Yash Aditya
 */

import { useState } from "react";
import styles from "./AgentNotification.module.scss";
import reportImg from "src/assets/images/ReportImg.png";
import AgentsDD from "./Children/AgentsDD/AgentsDD";
import FrequencyDD from "./Children/FrequencyDD/FrequencyDD";
import NotifyEnabled from "./Children/NotifyEnabled/NotifyEnabled";
import { useFreqSettingsCreate } from "./hooks/useFreqSettings";
import Loader from "src/components/Loader";

/**
 * Individual notification settings element responsible for displaying
 * agent notification options and related settings.
 * @param {boolean} notifyEnabled - Flag indicating whether notifications are enabled.
 */
const NotificationSettingsEle = ({
  notifyEnabled,
}: {
  notifyEnabled: boolean;
}) => {
  // State to manage the visibility of dropdown elements
  const [showDropDown, setShowDropDown] = useState<boolean>(false);

  return (
    <div
      className={`w-100 py-3 ps-3 pe-3 pe-lg-5 ${styles.notificationWrapper}`}
    >
      <div className="d-flex justify-content-between align-items-start">
        <div>
          <h3 className={`${styles.heading}`}>Agents Notification</h3>
          <p className={`mb-0 ${styles.subHeading}`}>
            Get notified about conversation activity
          </p>
        </div>
        {/* Component to handle notification enable/disable */}
        <NotifyEnabled />
      </div>
      <div>
        <hr />
        <div
          className={`p-3 ${styles.reportWrap} ${
            !notifyEnabled && styles.disabled
          }`}
        >
          <div
            className="d-flex justify-content-between align-items-center"
            onClick={() => setShowDropDown(!showDropDown)}
            role="button"
            style={{ cursor: notifyEnabled ? "pointer" : "default" }}
          >
            <div className="d-flex align-items-center">
              <img
                src={reportImg}
                alt=""
              />
              <span className={`ps-1 ${styles.mainDropHead}`}>Reports</span>
            </div>
            <div>
              <span>
                <i
                  className={`fa-solid fa-chevron-${
                    showDropDown && notifyEnabled ? "up" : "down"
                  }`}
                ></i>
              </span>
            </div>
          </div>
          {/* Render dropdown elements based on showDropDown state */}
          {showDropDown && notifyEnabled ? (
            <>
              <FrequencyDD />
              <AgentsDD />
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

/**
 * Parent component encapsulating the entire agent notification settings.
 * Fetches and manages frequency settings data using a custom hook.
 */
const AgentNotification = () => {
  // Custom hook to fetch and manage frequency settings data
  const [state, dispatch, Provider] = useFreqSettingsCreate();

  return (
    <Provider value={[state, dispatch]}>
      {/* Display error message if initial data loading is rejected */}
      {state.initialDataLoadingStatus === "rejected" && (
        <div className="d-flex align-items-center justify-content-center w-100">
          <div className="text-danger text-center">
            Error loading content...
          </div>
        </div>
      )}
      {/* Display loader if initial data is still pending */}
      {state.initialDataLoadingStatus === "pending" && (
        <div className="d-flex align-items-center justify-content-center w-100 mt-5">
          <Loader />
        </div>
      )}
      {/* Display notification settings when initial data loading is fulfilled */}
      {state.initialDataLoadingStatus === "fulfilled" && (
        <NotificationSettingsEle notifyEnabled={state.notificationsEnabled} />
      )}
    </Provider>
  );
};

export default AgentNotification;
