import { useCallback, useState } from "react";
import { Modal } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import DiscardModal from "../../Engagement/TargetedMessages/NewTargetedMessage/Children/DiscardModal/DiscardModal";
import styles from "./NewTargetedMessageSidebar.module.scss";
import Select, { SingleValue } from "react-select";

function NewTargetedMessageSidebar(props: {
  heading: string | undefined;
  GetDropdownType: Function;
}) {
  const [showModal, setShowModal] = useState(false);

  const onShow = useCallback(() => {
    setShowModal(true);
  }, []);
  const onHide = useCallback(() => {
    setShowModal(false);
  }, []);

  const options = [
    { value: 1, label: "Card" },
    { value: 2, label: "Quick Replies" },
    { value: 3, label: "Plain Text" },
  ];

  let [selectedOption, setSelectedOption] = useState(1);

  return (
    <div className={`mb-1  ${styles.sidebar} shadow-sm  `}>
      <div className="d-flex ms-2 mt-4 ">
        <div
          className="rounded-circle "
          style={{
            backgroundColor: "#F5F5F5",
            width: "38px",
            height: "38px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={onShow}
          role="button"
        >
          <i className="fa-solid fa-arrow-left p-2  "></i>
        </div>
        <div>
          <Modal
            backdropClassName={`${styles.modalBack}`}
            show={showModal}
            onHide={onHide}
            dialogClassName={`${styles.modalDialog}`}
            contentClassName={`${styles.modalContent}`}
            // centered={true}
            enforceFocus={false}
          >
            <DiscardModal onHide={onHide} />
          </Modal>
        </div>
        <div className="ms-3 mt-1 fw-bold fs-5 text-truncate ">
          New {props.heading}
        </div>
      </div>
      <div className="mt-4 ms-2 ">
        <div className="fw-bold fs-6">{props.heading} Type</div>
        <div className={`mt-2`}>
          <Select
            options={options}
            defaultValue={options[0]}
            onChange={(e) => {
              setSelectedOption(e?.value ?? -1);
              {
                props.GetDropdownType(e?.value);
              }
            }}
          />
        </div>
        <span className="text-muted" style={{ fontSize: "13px" }}>
          An interactive element with text, images and buttons.
        </span>
      </div>
      {/* <ul
        className={`${styles.ul} d-flex flex-column  justify-content-between ms-2 mt-4 ${styles.sidebar_nav} `}
        style={{ height: "62%" }}
        id="sidebar_nav"
      >
        <h6>Templates</h6>
        <div>Announcement</div>
        <div>First purchase</div>
        <div>Event announcement</div>
        <div>Pre-order</div>
        <div>Gift fot the new users</div>
        <div>Last minute annoucement</div>
        <div>Flash Sale</div>
        <div>We're hiring!</div>
      </ul> */}
      <ul className={`${styles.ul} ${styles.sidebar_nav}  `}>
        <li className={` ${styles.nav_item} `}>
          <span>Templates</span>
          <i className="bi bi-grid" />
        </li>

        {props.heading === "Greeting" ? (
          <>
            {selectedOption == 1 ? (
              <>
                <li>
                  <div className={`${styles.liItem}`}>
                    <NavLink to={"/Announcement"}>
                      <span className="text-dark">Show your product </span>
                    </NavLink>
                  </div>
                </li>
                <li>
                  <div className={`${styles.liItem}`}>
                    <NavLink to={"/FirstPurchase"}>
                      <span className="text-dark">Feature announce</span>
                    </NavLink>
                  </div>
                </li>
                <li>
                  <div className={`${styles.liItem}`}>
                    <NavLink to={"/EventAnnouncement"}>
                      <span className="text-dark">Engage customers</span>
                    </NavLink>
                  </div>
                </li>
                <li>
                  <div className={`${styles.liItem}`}>
                    <NavLink to={"/PreOrder"}>
                      <span className="text-dark">Share knowledge</span>
                    </NavLink>
                  </div>
                </li>
                <li>
                  <div className={`${styles.liItem}`}>
                    <NavLink to={"/NewUserGift"}>
                      <span className="text-dark">Redirect to sign up</span>
                    </NavLink>
                  </div>
                </li>
                <li>
                  <div className={`${styles.liItem}`}>
                    <NavLink to={"/LastMinuteAnnouncment"}>
                      <span className="text-dark">Offer help</span>
                    </NavLink>
                  </div>
                </li>
              </>
            ) : selectedOption == 2 ? (
              <>
                <li>
                  <div className={`${styles.liItem}`}>
                    <NavLink to={"/PreOrder"}>
                      <span className="text-dark">Help with product</span>
                    </NavLink>
                  </div>
                </li>
                <li>
                  <div className={`${styles.liItem}`}>
                    <NavLink to={"/NewUserGift"}>
                      <span className="text-dark">Ask for opinion</span>
                    </NavLink>
                  </div>
                </li>
                <li>
                  <div className={`${styles.liItem}`}>
                    <NavLink to={"/LastMinuteAnnouncment"}>
                      <span className="text-dark">Support customers</span>
                    </NavLink>
                  </div>
                </li>
              </>
            ) : (
              <>
                <li>
                  <div className={`${styles.liItem}`}>
                    <NavLink to={"/PreOrder"}>
                      <span className="text-dark">Simple hello</span>
                    </NavLink>
                  </div>
                </li>
                <li>
                  <div className={`${styles.liItem}`}>
                    <NavLink to={"/PreOrder"}>
                      <span className="text-dark">Offer help</span>
                    </NavLink>
                  </div>
                </li>
                <li>
                  <div className={`${styles.liItem}`}>
                    <NavLink to={"/NewUserGift"}>
                      <span className="text-dark">Order assurance</span>
                    </NavLink>
                  </div>
                </li>
                <li>
                  <div className={`${styles.liItem}`}>
                    <NavLink to={"/LastMinuteAnnouncment"}>
                      <span className="text-dark">Welcome question</span>
                    </NavLink>
                  </div>
                </li>
              </>
            )}
          </>
        ) : selectedOption == 1 ? (
          <>
            <li>
              <div className={`${styles.liItem}`}>
                <NavLink to={"/Announcement"}>
                  <span className="text-dark">Announcement</span>
                </NavLink>
              </div>
            </li>
            <li>
              <div className={`${styles.liItem}`}>
                <NavLink to={"/FirstPurchase"}>
                  <span className="text-dark">First purchase</span>
                </NavLink>
              </div>
            </li>

            <li>
              <div className={`${styles.liItem}`}>
                <NavLink to={"/EventAnnouncement"}>
                  <span className="text-dark">Event announcement</span>
                </NavLink>
              </div>
            </li>
            <li>
              <div className={`${styles.liItem}`}>
                <NavLink to={"/PreOrder"}>
                  <span className="text-dark">Pre-order</span>
                </NavLink>
              </div>
            </li>

            <li>
              <div className={`${styles.liItem}`}>
                <NavLink to={"/NewUserGift"}>
                  <span className="text-dark">Gift for the new users</span>
                </NavLink>
              </div>
            </li>

            <li>
              <div className={`${styles.liItem}`}>
                <NavLink to={"/LastMinuteAnnouncment"}>
                  <span className="text-dark">Last minute announcemnet</span>
                </NavLink>
              </div>
            </li>
            <li>
              <div className={`${styles.liItem}`}>
                <NavLink to={"/FlashSale"}>
                  <span className="text-dark">Flash Sale</span>
                </NavLink>
              </div>
            </li>
            <li>
              <div className={`${styles.liItem}`}>
                <NavLink to={"/We'reHring"}>
                  <span className="text-dark">We're hiring</span>
                </NavLink>
              </div>
            </li>
          </>
        ) : selectedOption == 2 ? (
          <>
            <li>
              <div className={`${styles.liItem}`}>
                <NavLink to={"/FlashSale"}>
                  <span className="text-dark">Coupon question</span>
                </NavLink>
              </div>
            </li>
            <li>
              <div className={`${styles.liItem}`}>
                <NavLink to={"/We'reHring"}>
                  <span className="text-dark">Webinar question</span>
                </NavLink>
              </div>
            </li>
          </>
        ) : (
          <>
            <li>
              <div className={`${styles.liItem}`}>
                <NavLink to={"/FlashSale"}>
                  <span className="text-dark">Coupon </span>
                </NavLink>
              </div>
            </li>
            <li>
              <div className={`${styles.liItem}`}>
                <NavLink to={"/We'reHring"}>
                  <span className="text-dark">Friendly reminder</span>
                </NavLink>
              </div>
            </li>
            <li>
              <div className={`${styles.liItem}`}>
                <NavLink to={"/We'reHring"}>
                  <span className="text-dark">Registration</span>
                </NavLink>
              </div>
            </li>
          </>
        )}
      </ul>
    </div>
  );
}

export default NewTargetedMessageSidebar;
