import styles from "./AboutIntegShopify.module.scss";
import backArrow from "src/assets/images/backArrow.png";
import shopifyImg from "src/assets/images/shopLogo.png";
import { useCallback, useState } from "react";
import DisconnectIntegration from "../ShopifyStore/Children/DisconnectIntegration/DisconnectIntegration";
import { useAppSelector } from "src/store/store";
import {
  convertGmtToUserTimezone,
  getTimeFromNow,
} from "src/utils/dateLibrary";
import { deleteShopifyIntegration } from "src/services/Integrations/deleteShopifyIntegration";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { Dropdown, Spinner } from "react-bootstrap";
import SettingToast from "src/routes/Setting/children/SettingToast/SettingToast";

const AboutIntegShopify = ({
  showWhat,
  setShowWhat,
  fetchAllShopifyIntegrations,
  currentStoreData,
}: any) => {
  const userTimezone = useAppSelector(
    (state) => state.globals.currentUserData?.userTimezone,
  );
  const [isConnected, setIsConnected] = useState(currentStoreData.connected);
  const [showModal, setShowModal] = useState(false);
  const [showConnecting, setShowConnecting] = useState(false);
  const [loading, setLoading] = useState(false);

  // Function to handle the connection and disconnection logic for a Shopify integration
  const hanldeConnectAndDisconnect = () => {
    // Check if already connected
    if (isConnected) {
      // If connected, show modal and return
      setShowModal(true);
      return;
    } else {
      // If not connected, initiate the disconnection process
      setShowConnecting(true);

      // Call the deleteShopifyIntegration API to disconnect the integration
      deleteShopifyIntegration({
        integrationId: currentStoreData.integrationId,
        is_disconnect: true,
      })
        .then((res) => {
          // On successful disconnection
          setIsConnected(true);
          setShowConnecting(false);

          // Show success toast notification
          pushTheToast({
            type: "success",
            text: SettingToast,
            customCompProps: {
              //passing custom component instead of text to show the info with icon
              text: (
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ margin: "-7px" }}
                >
                  <span>
                    <i className={`fa-solid fa-circle-check me-1`}></i>
                  </span>
                  <span>Store connected</span>
                </div>
              ),
            },
            isTextComponent: true,
            position: "top-center",
          });
        })
        .catch((err) => {
          // On error during disconnection
          setShowConnecting(false);

          // Show error toast notification
          pushTheToast({
            type: "danger",
            text: err.message,
            position: "top-right",
          });
        });
    }
  };

  const handleDeleteShopifyIntegration = () => {
    setLoading(true);
    deleteShopifyIntegration({ integrationId: currentStoreData.integrationId })
      .then((res) => {
        pushTheToast({
          type: "success",
          text: "Integration successfully deleted!",
          position: "top-right",
        });
        setLoading(false);
        fetchAllShopifyIntegrations();
        setShowWhat("storesPage");
      })
      .catch((err) => {
        pushTheToast({
          type: "danger",
          text: err.message,
          position: "top-right",
        });
        setLoading(false);
      });
  };

  // const onShow = useCallback(() => {
  //   setShowModal(true);
  // }, []);

  // const onHide = useCallback(() => {
  //   setShowModal(false);
  // }, []);

  return (
    <>
      <div className={`${styles.aboutMainDiv}`}>
        <div className={`d-flex justify-content-between align-items-center`}>
          <div className={`d-flex`}>
            <div>
              <img
                className={`${styles.leftArrow}`}
                src={backArrow}
                onClick={() => {
                  fetchAllShopifyIntegrations();
                  setShowWhat("storesPage");
                }}
              />
            </div>
            <div className={`${styles.shopMainDiv}`}>
              <h1 className={`${styles.shop}`}>{currentStoreData.storeUrl}</h1>
            </div>
          </div>
        </div>
        {/* <div className={`text-center`}>
          <div className={`${styles.successMsg}`}>
            <span>
              <i className={`fa-solid fa-circle-check me-1`}></i>
            </span>
            // <span>Store connected</span> 
            <span>Store Disconnected</span>
          </div>
        </div> */}

        <div className={`${styles.aboutMain}`}>
          <h3 className={`${styles.aboutIntegrate}`}>About this integration</h3>
          <div className={`${styles.aboutBox}`}>
            <div
              className={`d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center ${styles.box}`}
            >
              <div className={`d-flex align-items-center`}>
                <div>
                  {" "}
                  <img
                    className={`${styles.shopifyImg}`}
                    src={shopifyImg}
                  />{" "}
                </div>
                <div className={`ms-3`}>
                  <p className={`${styles.shopName}`}>
                    <span className={`pe-2`}>Integration store URL : </span>
                    <span className={`${styles.mailId}`}>
                      {currentStoreData.storeUrl}
                    </span>
                  </p>
                  <span className={`${styles.pageInstall}`}>
                    <span className={`pe-3`}> Brand installed on:</span>
                    <span className={`${styles.installName}`}>
                      {getTimeFromNow({
                        date: new Date(
                          convertGmtToUserTimezone(
                            currentStoreData.createdAtGmt,
                            userTimezone,
                          ),
                        ),
                        timeZone: userTimezone,
                      })}
                    </span>
                  </span>
                </div>
              </div>
              <div className={`d-flex align-items-center flex-wrap`}>
                <span className={`${styles.connection}`}>
                  {isConnected ? "Disconnect Page" : "Connect Page"}
                </span>
                <div className="form-check form-switch me-2">
                  {showConnecting && (
                    <Spinner
                      className="mx-1 my-auto"
                      animation="border"
                      size="sm"
                    />
                  )}
                  <input
                    className={`form-check-input ${styles.formCheck}`}
                    type="checkbox"
                    disabled={loading === true || showConnecting === true}
                    checked={isConnected}
                    onChange={hanldeConnectAndDisconnect}
                    // onClick={onShow}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={`d-flex justify-content-end mt-4`}>
            <div className={``}>
              {/* <button
                className={`dropdown-toggle ${
                  loading === true ? `disabled` : ``
                } ${styles.delBtn}`}
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span>
                  {loading == true ? (
                    <Spinner className="mx-1" animation="border" size="sm" />
                  ) : (
                    <span className={`me-1`}>
                      {" "}
                      <i className="fa-solid fa-trash"></i>
                    </span>
                  )}
                </span>
                Delete Integration
              </button>
              <ul
                className={`dropdown-menu ${
                  loading === true ? `disabled` : ``
                } ${styles.addNewBox}`}
                aria-labelledby="dropdownMenuButton1"
              ></ul> */}

              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  as="div"
                  bsPrefix={`dropdown-toggle cursor-pointer ${
                    loading === true ? `disabled` : ``
                  } ${styles.delBtn} px-2`}
                >
                  <span>
                    {loading == true ? (
                      <Spinner
                        className="mx-1"
                        animation="border"
                        size="sm"
                      />
                    ) : (
                      <span className={`me-1`}>
                        {" "}
                        <i className="fa-solid fa-trash"></i>
                      </span>
                    )}
                  </span>
                  Delete Integration
                </Dropdown.Toggle>

                <Dropdown.Menu
                  bsPrefix={`dropdown-menu  mt-1 ${
                    loading === true ? `disabled` : ``
                  } ${styles.addNewBox}`}
                >
                  <li>
                    <div className={`${styles.isSure}`}>
                      <span className={`${styles.isSurePara}`}>
                        Are you sure?
                      </span>
                    </div>
                    <div className={`${styles.confirmDiv}`}>
                      <span className={`${styles.confirmSpan}`}>
                        Are you sure you want to delete this integration? All
                        associated views and rules will be disabled.
                      </span>
                      <div>
                        <button
                          className={`px-2 ${styles.confirmBtn}`}
                          onClick={(e) => {
                            if (!loading) {
                              handleDeleteShopifyIntegration();
                            }
                          }}
                        >
                          confirm
                        </button>
                      </div>
                    </div>
                  </li>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>

      {/* ---Show modal when form check is to be disable--- */}

      {showModal === true && (
        <DisconnectIntegration
          showModal={showModal}
          storeData={currentStoreData}
          setShowModal={setShowModal}
          isConnected={isConnected}
          showConnecting={showConnecting}
          setIsConnected={setIsConnected}
          setShowConnecting={setShowConnecting}
        />
      )}
    </>
  );
};
export default AboutIntegShopify;
