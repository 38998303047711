import { useEffect, useMemo, useState } from "react";
import ParseHTML from "src/components/ParseHTML";
import useGetAutomationData from "src/features/ReturnAutoWorkFlow/hooks/useGetAutomationData";
import useGetVariables from "src/hooks/automation/useGetVariables";
import { replaceKeyWithVariableValue } from "src/utils/utils";

const ParseVariablesWithValue = ({
  message,
  isReturnMethodMsg,
}: {
  message: string | null;
  isReturnMethodMsg?: boolean;
}) => {
  // Custom hook to get variables data
  const { variables } = useGetVariables();
  const { getReturnWindowData } = useGetAutomationData();
  const { returnWindow, day_type, orderCondition } = getReturnWindowData();
  const msg = useMemo(() => {
    let value = replaceKeyWithVariableValue(message ?? "", variables);
    if (isReturnMethodMsg && value.includes("{{return_window}}")) {
      value = value.replace(
        "{{return_window}}",
        `${returnWindow} ${
          day_type === "business_days" ? "business days" : "days"
        } after ${
          orderCondition === "order_placed" ? "order placed" : "order delivered"
        }`,
      );
    }
    return value;
  }, [message, variables, returnWindow]);

  return (
    <div>
      {msg ? (
        <div>
          <ParseHTML html={msg} />
        </div>
      ) : null}
    </div>
  );
};

export default ParseVariablesWithValue;
