import { useQueries } from "@tanstack/react-query";
import { useMemo, useCallback } from "react";
import { EAutomationType } from "src/routes/BotSettings/Children/AutomationList/AutomationList";
import {
  UserAgent,
  getAllAgentsService,
} from "src/services/Automation/DispatchRulesConfigModal/getAllAgents.service";
import {
  DispatchRuleTeamData,
  getTeamsDataService,
} from "src/services/Automation/DispatchRulesConfigModal/getTeamsData.service";

const useAgentData = (
  integrationId: string,
  automationType: EAutomationType,
) => {
  const [
    {
      data: allAPITeams,
      isError: isTeamsError,
      isLoading: isTeamsLoading,
      refetch: refetchTeam,
    },
    {
      data: allAPIAgents,
      isError: isAgentsError,
      isLoading: isAgentsLoading,
      refetch: refetchAgent,
    },
  ] = useQueries({
    queries: [
      {
        queryKey: ["/automation/bugManagement/getTeamsData", integrationId],
        queryFn: () =>
          getTeamsDataService({
            payload: {
              integrationId,
              start: 0,
              limit: 100,
            },
            type: automationType,
          }),
        staleTime: 60000,
        cacheTime: 60000,
      },
      {
        queryKey: ["/automation/bugManagement/getAllAgents", integrationId],

        queryFn: () =>
          getAllAgentsService({
            payload: {
              integrationId,
              start: 0,
              limit: 100,
            },
            type: automationType,
          }),
        staleTime: 60000,
        cacheTime: 60000,
      },
    ],
  });

  const { allTeamIds, allTeams } = useMemo(() => {
    const allTeamIds: string[] = [];
    const allTeams: Record<string, DispatchRuleTeamData> = {};

    if (allAPITeams) {
      allAPITeams.forEach((data) => {
        const id = data.teamId + "";
        allTeamIds.push(id);
        allTeams[id] = data;
      });
    }

    return { allTeams, allTeamIds };
  }, [allAPITeams]);

  const { allAgentIds, allAgents } = useMemo(() => {
    const allAgentIds: string[] = [];
    const allAgents: Record<string, UserAgent> = {};

    if (allAPIAgents) {
      allAPIAgents.forEach((data) => {
        const id = data.id + "";
        allAgentIds.push(id);
        allAgents[id] = data;
      });
    }

    return { allAgents, allAgentIds };
  }, [allAPIAgents]);

  const isError = useMemo(() => {
    return isAgentsError || isTeamsError;
  }, [isAgentsError, isTeamsError]);

  const refetch = useCallback(() => {
    refetchAgent();
    refetchTeam();
  }, [refetchAgent, refetchTeam]);

  return {
    allAgents,
    allTeams,
    allAgentIds,
    allTeamIds,
    isAgentsLoading,
    isTeamsLoading,
    isError,
    isAgentsError,
    isTeamsError,
    refetch,
  };
};

export default useAgentData;
