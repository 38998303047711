import { IGetAllOrders } from "./allOrders.slice";

export const fetchAllOrdersFulfilled = (
  state: IGetAllOrders,
  { payload }: any
) => {
  state.customer = {
    id: payload.allOrdersData.customer.id,
    name: payload.allOrdersData.customer.name,
    email: payload.allOrdersData.customer.email,
    phone: payload.allOrdersData.customer.phone,
  };

  // state.orders = [...state.orders, ...payload.allOrdersData.orders];
  state.orders = payload.allOrdersData.orders;
  
  state.total = payload.allOrdersData.total;
  state.count = payload.allOrdersData.count;

  state.totalPage = Math.ceil(payload.allOrdersData.total/state.fetchOrdersLimit);

  state.error = payload.allOrdersData.error;

  state.fetchAllOrdersAjaxStatus = "fulfilled";

}

export default {fetchAllOrdersFulfilled};
