import { useCallback, useEffect, useRef, useState } from "react";
import styles from "./AssignBox.module.scss";
import {
  ActionGroup,
  CompareDetail,
  ConditionI,
  Group,
  IActionForModal,
} from "src/services/Automation/getAutomationById.service";
import useFetchActionOptions from "../../../hooks/useActionConditionOptions";
import { useParams } from "react-router-dom";
import ParseHTML from "src/components/ParseHTML";
import useFetchConditionOptions from "../../../hooks/useFetchConditionOptions";
import {
  BetweenRange,
  CompareData,
} from "src/services/Automation/AutomationTicketRouting/getConditionOptions.service";
const AssignBox = ({
  selectedTrigger,
  allowedActions,
  conditionGroups,
  actionGroups,
  integrationId,
  showEdit,
  edit,
}: {
  selectedTrigger: string;
  allowedActions: string[];
  conditionGroups: Group[];
  actionGroups: ActionGroup[];
  integrationId: string;
  showEdit: boolean;
  edit?: (type: "actions" | "conditionGroups" | "triggeredBy") => void;
}) => {
  const { type } = useParams();
  const { actionOptions } = useFetchActionOptions(type ?? "", integrationId);
  const { conditionOptions } = useFetchConditionOptions(
    type ?? "",
    integrationId,
  );

  const [viewMoreArray, setViewMoreArray] = useState<boolean[]>([]);
  const [toggleArray, setToggleArray] = useState<boolean[]>([]);

  const MESSAGE_LINE_HEIGHT = 18; // Adjust based on actual line-height of the text
  const MAX_LINE_NUMBER = 2;

  const messageRefs = useRef<HTMLParagraphElement[]>([]);

  useEffect(() => {
    const viewMoreStates =
      actionGroups[0]?.conditions?.map((_, idx) => {
        const height = messageRefs.current[idx]?.offsetHeight;
        return height > MESSAGE_LINE_HEIGHT * MAX_LINE_NUMBER;
      }) || [];

    setViewMoreArray(viewMoreStates);
    setToggleArray(new Array(viewMoreStates.length).fill(false));
  }, [messageRefs, actionGroups]);

  const handleToggle = (idx: number) => {
    setToggleArray((prev) =>
      prev.map((toggle, i) => (i === idx ? !toggle : toggle)),
    );
  };

  const getActionName = useCallback(
    (fieldKey: string) => {
      const action = actionOptions?.actionOptions?.find(
        (option) => option.fieldKey === fieldKey,
      );
      return action ? action.fieldName : "";
    },
    [actionOptions],
  );

  const getActionValue = useCallback(
    (condition: IActionForModal) => {
      const action = actionOptions?.actionOptions?.find(
        (option) => option.fieldKey === condition?.fieldKey,
      );
      if (action && action.isMulti) {
        if (condition.fieldKey === "addTag" && condition?.valuesOfSelectKeys) {
          return Array.isArray(condition?.value)
            ? (condition.value as string[])
                .map((val) =>
                  condition?.valuesOfSelectKeys &&
                  condition?.valuesOfSelectKeys[val]
                    ? `#${condition.valuesOfSelectKeys[val]}`
                    : "",
                )
                .filter(Boolean) // To remove any empty values
                .join(", ")
            : "";
        }
        return "";
      } else if (condition?.valuesOfSelectKeys) {
        return condition?.valuesOfSelectKeys[condition?.value as string].join(
          ",",
        );
      }
      return condition?.value;
    },
    [actionOptions],
  );

  const getConditionType = useCallback(
    (conditionType: string | null) => {
      if (conditionType && conditionOptions?.conditionOptions) {
        for (const field of conditionOptions?.conditionOptions) {
          const conditionTypeObj = field.conditionTypes.find(
            (ct) => ct.conditionTypeKey === conditionType,
          );
          if (conditionTypeObj) {
            return conditionTypeObj.conditionTypeName;
          }
        }
      }
      return "";
    },
    [conditionOptions],
  );

  const getCompareName = useCallback(
    (compareKey: string | null) => {
      if (compareKey && conditionOptions?.conditionOptions) {
        for (const field of conditionOptions?.conditionOptions) {
          for (const conditionType of field.conditionTypes) {
            for (const compareDetail of conditionType.compareDetails) {
              // Handle both grouped and non-grouped compareDetails
              if (
                Array.isArray((compareDetail as CompareData)?.compareDetails)
              ) {
                for (const groupedCompare of (compareDetail as CompareData)
                  .compareDetails) {
                  if (
                    (groupedCompare as CompareDetail).compareType === compareKey
                  ) {
                    return (groupedCompare as CompareDetail).compareName;
                  }
                }
              } else if (
                (compareDetail as CompareDetail).compareType === compareKey
              ) {
                return (compareDetail as CompareDetail).compareName;
              }
            }
          }
        }
      }
      return "";
    },
    [conditionOptions],
  );

  const getConditionValue = useCallback(
    (condition: ConditionI) => {
      if (condition) {
        const newCondition = conditionOptions?.conditionOptions?.find(
          (option) => option.fieldKey === condition?.fieldKey,
        );
        const conditionTypeObj = newCondition?.conditionTypes.find(
          (ct) => ct.conditionTypeKey === condition?.conditionType,
        );

        if (newCondition && conditionTypeObj?.isMulti) {
          if (condition.conditionType === "tag") {
            return Array.isArray(condition?.value)
              ? (condition.value as string[]).map((val) => `#${val}`).join(", ")
              : "";
          }
          return Array.isArray(condition?.value)
            ? (condition.value as string[]).map((val) => val).join(", ")
            : "";
        } else if (condition?.valuesOfSelectKeys) {
          return condition?.valuesOfSelectKeys[condition?.value as string].join(
            ",",
          );
        } else if (condition?.compareType === "isBetween") {
          return `${(condition.value as BetweenRange)?.start} and ${(
            condition.value as BetweenRange
          )?.end}`;
        }
        return condition?.value as string;
      }
      return "";
    },
    [conditionOptions],
  );

  return (
    <div className={`mb-3 ${styles.actionBox}`}>
      <div className="mb-2">
        <div className={`d-flex align-items-center mb-2 ${styles.heading}`}>
          <p className={`mb-0 ${styles.header}`}>Triggers</p>
          {showEdit ? (
            <div
              className={`ms-2 ${styles.editText} cursor-pointer`}
              onClick={() => (edit ? edit("triggeredBy") : () => {})}
            >
              Edit
            </div>
          ) : null}
        </div>
        <div className={`${styles.descBox}`}>
          <div className={`${styles.triggerUi}`}>
            <p className={`mb-0 ${styles.heading}`}>When ticket is created</p>
            <div className="d-flex">
              <span className={`w-50 ${styles.heading}`}>
                Rule will be fired{" "}
                <span className={`${styles.semiBold}`}>
                  {" "}
                  {selectedTrigger ?? ""}
                </span>{" "}
                , when agent
              </span>
              <div className="">
                {allowedActions?.map((action: string, idx: number) => (
                  <span
                    key={idx}
                    className={`d-block ${styles.desc}`}
                  >
                    {action}
                    {idx < allowedActions.length - 1 && ", "}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-2">
        <div className={`d-flex align-items-center mb-2 ${styles.heading}`}>
          <p className={`mb-0 ${styles.header}`}>Conditions</p>
          {showEdit ? (
            <div
              className={`ms-2 ${styles.editText} cursor-pointer`}
              onClick={() => (edit ? edit("conditionGroups") : () => {})}
            >
              Edit
            </div>
          ) : null}
        </div>
        <div className={`${styles.descBox}`}>
          <div className={`${styles.triggerUi}`}>
            {conditionGroups?.map((group, groupIndex) => {
              return (
                <div>
                  {(group.conditions as ConditionI[])?.map(
                    (condition, conditionIdx) => {
                      return (
                        <p className="mb-0">
                          {conditionIdx != 0 ? (
                            <div className={`${styles.faint}`}>
                              {" "}
                              {group.conditionsLogicalOperator?.toUpperCase()}
                            </div>
                          ) : (
                            ""
                          )}
                          <span className={`${styles.heading}`}>
                            <span className={`${styles.faint}`}> If</span>{" "}
                            <span className={`${styles.semiBold}`}>
                              {" "}
                              {getConditionType(condition?.conditionType)}
                            </span>{" "}
                            <span className={`${styles.faint}`}>
                              {" "}
                              {getCompareName(condition?.compareType)}
                            </span>
                          </span>
                          <span className={`ps-1 ${styles.desc}`}>
                            {getConditionValue(condition)}
                          </span>
                          {conditionGroups &&
                          groupIndex != conditionGroups?.length - 1 &&
                          conditionIdx === group?.conditions?.length - 1 ? (
                            <div className={`${styles.faint}`}>
                              {" "}
                              {group.logicalOperator?.toUpperCase()}
                            </div>
                          ) : (
                            ""
                          )}
                        </p>
                      );
                    },
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="mb-2">
        <div className={`d-flex align-items-center mb-2 ${styles.heading}`}>
          <p className={`mb-0 ${styles.header}`}>Action(s)</p>
          {showEdit ? (
            <div
              className={`ms-2 ${styles.editText} cursor-pointer`}
              onClick={() => (edit ? edit("actions") : () => {})}
            >
              Edit
            </div>
          ) : null}
        </div>
        <div className={`${styles.descBox}`}>
          <div className={`${styles.triggerUi}`}>
            {actionGroups &&
              actionGroups[0] &&
              actionGroups[0]?.conditions?.map((condition, idx) => {
                const actionValue = getActionValue(
                  condition as IActionForModal,
                );

                return (
                  <div
                    className="d-flex mb-1"
                    key={idx}
                  >
                    <span className={` ${styles.heading} pe-1`}>
                      {getActionName(condition.fieldKey ?? "")}
                    </span>
                    <div
                      className={`position-relative d-flex align-items-center ${styles.actionValue}`}
                    >
                      <div
                        ref={(el) => (messageRefs.current[idx] = el!)}
                        className={`${styles.desc}  ${
                          viewMoreArray[idx]
                            ? `${styles.truncate} ${styles.clamped}`
                            : ""
                        } ${toggleArray[idx] ? "" : styles.clamped}`}
                      >
                        <ParseHTML
                          html={actionValue as string}
                          className={`${
                            viewMoreArray[idx] ? styles.clamped : ""
                          }`}
                        />
                      </div>
                      {viewMoreArray[idx] && (
                        <button
                          className={`${styles.viewMore}`}
                          onClick={() => handleToggle(idx)}
                        >
                          {toggleArray[idx] ? "View less" : "View full"}
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignBox;
