import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { LiveChatHistorySlice } from "./liveChatHistory.declarations";
import liveChatHistoryExtraReducers from "./liveChatHistory.extraReducers";
import {
  fetchHistoryChats,
  fetchHistoryMessages,
  fetchLiveChatHistoryConversation,
} from "./liveChatHistory.thunks";

export function builderFunction(
  builder: ActionReducerMapBuilder<LiveChatHistorySlice>
) {
  builder.addCase(
    fetchHistoryChats.fulfilled,
    liveChatHistoryExtraReducers.fetchHistoryChatsFulfilled
  );
  builder.addCase(
    fetchHistoryChats.pending,
    liveChatHistoryExtraReducers.fetchHistoryChatsPending
  );
  builder.addCase(
    fetchHistoryChats.rejected,
    liveChatHistoryExtraReducers.fetchHistoryChatsRejected
  );

  builder.addCase(
    fetchHistoryMessages.fulfilled,
    liveChatHistoryExtraReducers.fetchHistoryMessagesFulfilled
  );
  builder.addCase(
    fetchHistoryMessages.pending,
    liveChatHistoryExtraReducers.fetchHistoryMessagesPending
  );
  builder.addCase(
    fetchHistoryMessages.rejected,
    liveChatHistoryExtraReducers.fetchHistoryMessagesRejected
  );

  // Builder Case for fetchLiveChatHistoryConversation API
  builder.addCase(
    fetchLiveChatHistoryConversation.fulfilled,
    liveChatHistoryExtraReducers.fetchLiveChatHistoryConversationFulfilled
  );

  builder.addCase(
    fetchLiveChatHistoryConversation.pending,
    liveChatHistoryExtraReducers.fetchLiveChatHistoryConversationPending
  );

  builder.addCase(
    fetchLiveChatHistoryConversation.rejected,
    liveChatHistoryExtraReducers.fetchLiveChatHistoryConversationRejected
  );
}
