/**
 * This file contains the custome hook for component.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { useParams } from "react-router-dom";

/**
 * This hook is used for the tab section.
 */
function useTabSection() {
  const { integrationId, brandId } = useParams();
  const pageIntegrationId = integrationId
    ? decodeURIComponent(integrationId)
    : "";

  const pageBrandId = brandId ? decodeURIComponent(brandId) : "";

  // Getting the tab name from the url
  const tabName = window.location.pathname.split("/")[3];

  return { pageIntegrationId, tabName, pageBrandId };
}

export default useTabSection;
