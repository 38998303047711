import { useAppSelector } from "src/store/store";
import { totalTicketsSelectedSeletector, updatedTicketsCountSeletector } from "src/store/slices/tickets/tickets.slice";
import { getSelectedUserSelector } from "src/store/slices/ticketUsers/ticketUsers.slice";
import BaseSuccessModalBody from "src/components/BulkActionComponents/commons/BaseSuccessModalBody/BaseSuccessModalBody";

const AddTagsSuccess = () => {
  const totalTicketsSelected = useAppSelector(totalTicketsSelectedSeletector);
  const updatedTicketsCount = useAppSelector(updatedTicketsCountSeletector);
  const selectedUser = useAppSelector(getSelectedUserSelector);

  return (
    <BaseSuccessModalBody>
      {updatedTicketsCount} ticket(s) assigned to {selectedUser.name} !
    </BaseSuccessModalBody>
  );
};

export default AddTagsSuccess;
