import {ArticleCategoryData, initialState} from "./articleCategory.slice";

// reset articleCategory settings
const resetArticleCategories = (state: ArticleCategoryData) => {
  state = {...initialState};
  return state;
};

const setCategorySearchValue = (
  state: ArticleCategoryData,
  {payload}: {payload: {searchValue: string}}
) => {
  state.filters.searchValue = payload.searchValue;
};

const resetCategorySearchValue = (state: ArticleCategoryData) => {
  state.filters.searchValue = "";
};

export default {
  resetArticleCategories,
  setCategorySearchValue,
  resetCategorySearchValue,
};
