/**
 * This file defines a React component responsible for import modal in external source
 * also handles stop import and continue on backgroud functionality
 *
 * @author @yuvaraj-busibud
 */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styles from "./ImportModal.module.scss";
import ProgressBar from "react-bootstrap/ProgressBar";
import importInProgress from "src/assets/images/importInProgress.gif";
import { useMutation } from "@tanstack/react-query";
import {
  ExternalSourceImportStatus,
  importExternalSource,
} from "src/services/Bot/AnswerSources/importExternalSource.service";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { useParams } from "react-router-dom";
import { validateURL } from "src/utils/validations";
import { Spinner } from "react-bootstrap";
import { updateSourceImportStatus } from "src/services/Bot/AnswerSources/updateSourceImportStatus";
import {
  importSourceProgress,
  unBindImportSourceProgress,
} from "src/pusherServices/botProflies/importProgress";
import { Channel } from "pusher-js";
import { FreePlanError, FreePlanErrorMessage } from "src/globals/constants";

interface Props {
  onHide: (e: any) => void;
  refechSources: () => void;
  channel?: Channel | null; // channel is a optional parameter of type  Channel | null
}
const ImportModal = ({ onHide, refechSources, channel }: Props) => {
  const { profileId } = useParams();
  const [isImportInProgress, setIsImportInProgress] = React.useState(false);
  const [isImported, setIsImported] = React.useState(false);
  const [url, setUrl] = React.useState("");
  const [channelName, setChannelName] = React.useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [stopImportLoading, setStopImportLoading] = useState(false);
  const [importStatus, setImportStatus] = useState<
    ExternalSourceImportStatus | string
  >("");
  const [sourceId, setSourceId] = useState(0);
  const [importPercentage, setImportPercentage] = useState(0);
  const [importedPages, setImportedPages] = useState<number | undefined>(0);

  const currentState = useRef({
    sourceId: sourceId,
  });

  useMemo(() => {
    currentState.current.sourceId = sourceId;
  }, [sourceId]);

  const importNew = useMutation({
    mutationFn: importExternalSource,
    onError: (err: any) => {
      if (
        typeof err == "object" &&
        (err.statusCode === FreePlanError.LIMIT_REACHED ||
          err.statusCode === FreePlanError.RESTRICTED)
      ) {
        const statusCode = err.statusCode as keyof typeof FreePlanErrorMessage;
        // If the free plan limit is exceeded, show free plan error
        pushTheToast({
          text: err.message ?? FreePlanErrorMessage[statusCode],
          type: "danger",
          position: "top-right",
        });
      }
    },
  });

  const stopImportMutation = useMutation({
    mutationFn: updateSourceImportStatus,
  });

  //function to initiate start import with new url
  const handleImport = () => {
    if (!url || !validateURL(url)) {
      setError("Please enter a valid URL");
      // pushTheToast({
      //   position: "top-right",
      //   text: "Please enter a valid URL",
      //   type: "warning",
      // });
      return;
    }
    setError("");
    setLoading(true);
    importNew.mutate(
      {
        url: url,
        botProfileId: profileId + "",
      },
      {
        onSuccess: (data) => {
          if (data.status === "importing") {
            setIsImportInProgress(true);
          }
          if (data.status === "completed" || data.status === "stopped") {
            setIsImportInProgress(false);
            setIsImported(true);
          }
          setImportPercentage(data.importPercentage);
          setImportStatus(data.status);
          setSourceId(data.id);
          setImportedPages(data.pagesImported);
          refechSources();
          setLoading(false);
        },
        onError: (error: any, variables, context) => {
          setImportStatus("");
          setLoading(false);
          pushTheToast({
            position: "top-right",
            text: error?.message ?? "Failed to import",
            type: "danger",
          });
          // setIsImportInProgress(false);
        },
      },
    );
    // setIsImportInProgress(true);
  };

  //function to handle stop the import request
  const handleStopImport = () => {
    if (!sourceId) {
      return;
    }
    setError("");
    setStopImportLoading(true);
    stopImportMutation.mutate(
      {
        sourceId: sourceId,
        action: "stop",
      },
      {
        onSuccess: (data) => {
          setImportStatus(data.status);
          refechSources();
          setStopImportLoading(false);
          if (data.status !== "completed" && data.status !== "stopped") {
            setIsImportInProgress(true);
            setIsImported(false);
          } else {
            setIsImportInProgress(false);
            setIsImported(true);
          }
          setImportPercentage(0);
          setImportStatus(data.status);
          setImportedPages(data.numberOfPages);
        },
        onError: () => {
          setImportStatus("");
          setStopImportLoading(false);
          pushTheToast({
            position: "top-right",
            text: "Failed to stop import",
            type: "danger",
          });
          // setIsImportInProgress(false);
        },
      },
    );
    // setIsImportInProgress(true);
  };

  //handles pusher update for the realtime percentage updates
  const handleSourceProgressCallback = useCallback(
    (res: any) => {
      const sourceData = res.data;
      const externalSourceId = sourceData?.external_source_id;
      if (
        externalSourceId &&
        externalSourceId == currentState.current.sourceId
      ) {
        const status: ExternalSourceImportStatus = sourceData?.status;
        const importPercentage = sourceData?.importPercentage;
        const pagesImported = sourceData?.pagesImported;
        if (status !== "completed" && status !== "stopped") {
          setIsImportInProgress(true);
          setIsImported(false);
        } else {
          setIsImportInProgress(false);
          setIsImported(true);
        }
        setImportPercentage(importPercentage);
        setImportStatus(status);
        setImportedPages(pagesImported);
      }
    },
    [sourceId],
  );

  //useEffect to bind pusher event to start receiving realtime updates
  useEffect(() => {
    if (channel) {
      importSourceProgress(channel, handleSourceProgressCallback);
    }
    return () => {
      if (channel) {
        unBindImportSourceProgress(channel, handleSourceProgressCallback);
      }
    };
  }, [channel]);

  return (
    <div className="p-3">
      <div className="d-flex justify-content-between">
        <h4 className={`${styles.modalHead}`}>Import from external sources</h4>
        <span
          className={`${styles.close}`}
          onClick={onHide}
        >
          <i className="fa-solid fa-xmark"></i>
        </span>
      </div>
      <div className="mb-3">
        <input
          type="url"
          className={`form-control ${styles.inputBox} ${
            error ? "border border-danger" : ""
          }`}
          placeholder="Enter URL eg . https://myhelpcenter.com"
          required
          value={url}
          onChange={(e) => setUrl(e.target.value)}
        />
        {error ? <span className="small text-danger">{error}</span> : null}
        {!isImportInProgress && !isImported && (
          <div className="mt-3 d-flex justify-content-end">
            <button
              className={`${styles.importBtn}`}
              disabled={loading}
              onClick={handleImport}
            >
              {loading ? (
                <Spinner
                  className="my-auto mx-1"
                  animation="border"
                  color="white"
                  size="sm"
                />
              ) : (
                "Import"
              )}
            </button>
          </div>
        )}

        {/* Import in Progress */}
        {isImportInProgress && (
          <div className={`justify-content-between mt-3 d-flex`}>
            <div
              className={`d-flex align-items-center ${styles.progressWrapper}`}
            >
              <img
                src={importInProgress}
                alt=""
                className={`me-2 ${styles.importInProgress}`}
              />

              <span className={`ps-1 ${styles.importText}`}>
                {/* 100 % imported */}
                {importStatus === "importing"
                  ? importPercentage === 0
                    ? "importing"
                    : `${importPercentage} % imported`
                  : null}
              </span>
            </div>
            <div className="d-flex align-items-center">
              <button
                className={`me-2 ${styles.stopBtn}`}
                disabled={stopImportLoading}
                onClick={(e) => {
                  e.preventDefault();
                  handleStopImport();
                }}
              >
                {stopImportLoading ? (
                  <Spinner
                    className="my-auto mx-1"
                    animation="border"
                    color="white"
                    size="sm"
                  />
                ) : (
                  "Stop import"
                )}
              </button>
              <button
                className={`d-block ${styles.continueBtn}`}
                onClick={onHide}
              >
                Continue importing in background
              </button>
            </div>
          </div>
        )}

        {/* Import Done */}
        {isImported && (
          <div className="mt-3 d-flex justify-content-between align-items-center ">
            {/* Import Done */}
            <div className={`d-flex align-items-center`}>
              <span className={`${styles.checkMsg}`}>
                <i className="fa-solid fa-check"></i>
              </span>
              <span className={`ps-2 ${styles.importText}`}>
                {`${importedPages} pages imported !`}
              </span>
            </div>

            {/* Ok Button */}
            <div className="">
              <button
                className={`px-3 ${styles.okBtn}`}
                onClick={onHide}
              >
                Ok
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImportModal;
