import { AJAXSTATUS } from "src/globals/constants";
import { CustomerReturnType } from "src/services/LiveChat/messageExchange/getAllCustomers";
import { ILiveChatAgent } from "../chatWidExchMsg/chatWidExchMsg.declarations";
import { ILiveChatIntegratedBrandList } from "src/services/LiveChat/messageExchange/getLiveChatIntegratedBrands";
import { ChatCountUpdatedData } from "src/pusherServices/livechat/chatCountUpdatedEvent";

export type LiveChatStatusTypes = "live" | "missed" | "archived";

interface ILiveChatIntegratedBrandData extends ILiveChatIntegratedBrandList {
  fetchLiveChatIntegratedBrandAJAXStatus: AJAXSTATUS;
}
/**
 * Available status filters
 */
export type StatusFilters =
  | "resolved"
  | "unresolved"
  | "resolved_and_archived"
  | "unresolved_and_archived"
  | "unresolved_and_unarchived"
  | "all";
export interface ChatWidExchCustSlice extends CustomerReturnType {
  sidebar: {
    missedChatIds: number; //Array<number | string>;
    liveChatIds: number; //Array<number | string>;
    archiveChatIds: number; //Array<number | string>;
    timestamp?: number;
  };
  sidebarFromApi: {
    missedChatIds: number; //Array<number | string>;
    liveChatIds: number; //Array<number | string>;
    archiveChatIds: number; //Array<number | string>;
    timestamp?: number;
  };
  fetchCustAJAXStatus: AJAXSTATUS;
  fetchSingleCustomerAJAXStatus: AJAXSTATUS;
  loading: boolean;
  selectedCustomerChatId: string | number;
  fetchCustomerLimit: string | number;
  selectedCustomerStatus: LiveChatStatusTypes;
  filters: {
    searchValue: string;
    showMentions: boolean;
  };
  initialized: boolean; //used to indentify if the page is already loaded with chats
  chatAssignedNotification: {
    showModal: boolean;
    chatId: number | string;
    assigner: {
      id: number | string;
      name: string;
      imageURL: string | null;
      isPublicAttachmentUrl?: boolean;
    };
    customer: {
      id: number | string;
      name: string;
      email: string;
      imageURL: string | null;
      isPublicAttachmentUrl?: boolean;
    };
    preChatFrom?: {
      id: number | string;
      message: string;
    };
    chatStatus: LiveChatStatusTypes;
  };

  // State Variables for Sidebar Filter
  selectedAgentIdsForFilter: Array<number | string>;
  idFilterApplied: {
    selectedIds: Array<number | string>;
    selectedIdsInfo: Array<ILiveChatAgent>;
  };

  // State Variable for Brand Filter
  selectedBrandIdsForFilter: Array<number | string>;
  liveChatIntegratedBrands: ILiveChatIntegratedBrandData;
  brandIdsApplied: Array<number | string>;

  // State Variable for Status Filter
  selectedStatusForFilter: StatusFilters[];
  statusFilterApplied: StatusFilters[];
  statusAllSelected: boolean;

  // State Variable for Date Filter
  filterStartDate: Date | null;
  appliedFilterStartDate: Date | null;

  filterEndDate: Date | null;
  appliedFilterEndDate: Date | null;

  agents: {
    [id: number | string]: {
      id: number | string;
      name: string;
      email: string;
      imageURL: string;
      isPublicAttachmentUrl?: boolean;
    };
  };
  agentIds: Array<number | string>;
  agentMeta: {
    count: number;
    total: number;
  };
  selectedAgentIds: Array<number | string>;
  agentFetchLimit: number;
  agentListAJAXStatus: AJAXSTATUS;
  chatUpdatedCountQueue: Array<ChatCountUpdatedData>;
}

export const initialState: ChatWidExchCustSlice = {
  customers: {},
  selectedCustomerChatId: "",
  chatIdList: [],
  metaData: { unreadMentionsCount: 0 },
  filters: {
    searchValue: "",
    showMentions: false,
  },
  sidebar: {
    missedChatIds: 0,
    liveChatIds: 0,
    archiveChatIds: 0,
  },
  sidebarFromApi: {
    missedChatIds: 0,
    liveChatIds: 0,
    archiveChatIds: 0,
  },
  //someone assigned you chat modal
  chatAssignedNotification: {
    showModal: false,
    chatId: 0,
    assigner: {
      id: 0,
      name: "",
      imageURL: null,
    },
    customer: {
      id: 0,
      name: "",
      email: "",
      imageURL: null,
    },
    chatStatus: "live",
  },
  fetchCustomerLimit: 8,
  fetchCustAJAXStatus: "pending",
  fetchSingleCustomerAJAXStatus: "idle",
  loading: false,
  selectedCustomerStatus: "live",
  initialized: false,

  // Intial State Variables for Sidebar Filter
  selectedAgentIdsForFilter: [],
  idFilterApplied: {
    selectedIds: [],
    selectedIdsInfo: [],
  },

  selectedBrandIdsForFilter: [],
  liveChatIntegratedBrands: {
    fetchLiveChatIntegratedBrandAJAXStatus: "pending",
    // brands: {},
    brands: [],
    brandIds: [],
    metaData: {
      count: 0,
      total: 0,
    },
  },
  brandIdsApplied: [],
  selectedStatusForFilter: [],
  statusFilterApplied: [],

  statusAllSelected: false,

  agents: {},
  agentIds: [],
  agentMeta: {
    count: 0,
    total: 0,
  },
  selectedAgentIds: [],
  agentFetchLimit: 15,
  agentListAJAXStatus: "idle",

  filterStartDate: null,
  appliedFilterStartDate: null,
  filterEndDate: null,
  appliedFilterEndDate: null,
  chatUpdatedCountQueue: [],
};
