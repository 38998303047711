import React, { useCallback } from "react";
import styles from "./ArticleURL.module.scss";
import caution from "src/assets/images/caution.png";
import { IFeaturedArticlesData } from "src/services/LiveChat/Settings/knowledgeBased/featuredArticles/getAllFeaturedArticles";

import { setActiveFeaturedArticleData } from "src/store/slices/liveChatSetting/chatWidgetSetting/knowledgeBased/knowledgeBasedSetting.slice";
import { useAppDispatch } from "src/store/store";
interface Props {
  featuredArticle: IFeaturedArticlesData;
  showEditModal: (e: any) => void;
  selectActiveFeaturedArticle: (featuredArticle: IFeaturedArticlesData) => void;
  activeFeaturedArticle: any;
}
const ArticleURL = ({
  featuredArticle,
  showEditModal,
  selectActiveFeaturedArticle,
  activeFeaturedArticle,
}: Props) => {
  const dispatch = useAppDispatch();
  const handleOnClick = useCallback(
    (e: any) => {
      selectActiveFeaturedArticle(featuredArticle);  // Added this line to highlight active article even when edit button is clicked
      dispatch(
        setActiveFeaturedArticleData({
          id: featuredArticle.id,
          integrationId: featuredArticle.integrationId,
          domainConstraint: featuredArticle.domainConstraint,
          domainUrl: featuredArticle.domainUrl,
          featuredArticles: featuredArticle.featuredArticles,
          type: featuredArticle.type,
          isDefault: featuredArticle.type == "default",
        })
      );
      showEditModal(e);
    },
    [featuredArticle, showEditModal]
  );
  return (
    <div
      className={`p-3 mb-3 ${styles.urlBox} ${
        featuredArticle.id === activeFeaturedArticle ? styles.activeArticle : ""
      }`} //Added style to highlight active article
      onClick={() => selectActiveFeaturedArticle(featuredArticle)}
    >
      <div className={`d-flex justify-content-between`}>
        <div className="d-flex flex-wrap align-items-center">
          <h4 className={`mb-0 me-2 ${styles.articleHead}`}>
            {featuredArticle.type === "default"
              ? "Default URL (All Pages)"
              : "Specific URL"}
          </h4>
          {featuredArticle.type === "default" ? (
            <span className={`p-1 ${styles.defaultSpan}`}>
              <span className="pe-1">Default</span>
              <span className={`${styles.cautionImg}`}>
                {" "}
                <img src={caution} alt="" className="" />{" "}
              </span>
            </span>
          ) : null}
        </div>
        {/* Mar 20 commenting this show/hide div as it is not implemented */}
        {/* <div>
          <span className={`${styles.previewText}`}>Show Preview</span>
          <span className={`ms-2 ${styles.previewText}`}>
            <i className="fa-solid fa-chevron-left"></i>
          </span>
        </div> */}
      </div>
      <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center w-100 mt-3">
        <span className={`d-inline-block mb-2 mb-md-0 ${styles.url}`}>URL</span>
        <span className={`mb-2 mb-md-0 px-2 ${styles.containBox}`}>
          {featuredArticle.domainConstraint === "contains"
            ? "contains"
            : "is Exactly"}
        </span>
        <span className={`mb-2 mb-md-0 px-2 text-truncate ${styles.mailBox}`}>
          {featuredArticle.domainUrl}
        </span>
      </div>
      <div className={`mt-3 flex-column flex-md-row ${styles.actionBtn}`}>
        <a
          className={`me-md-2 mb-2 mb-md-0 px-2 d-flex justify-content-center align-items-center ${styles.viewBtn}`}
          target={"_blank"}
          href={featuredArticle.domainUrl}
        >
          {"View on Page"}
        </a>
        <button
          className={`px-2 d-flex justify-content-center align-items-center ${styles.nextBtn}`}
          onClick={handleOnClick}
        >
          Edit Changes
        </button>
      </div>
    </div>
  );
};

export default ArticleURL;
