import styles from "../../Billing.module.scss";
import Loader from "src/components/Loader";
import BillingPay from "../BillingPay/BillingPay";
import PlanDetails from "../PlanDetails/PlanDetails";
import InVoiceTable from "../InVoiceTable/InVoiceTable";
import { useBillingContext } from "../../BillingContext";
import { Modal } from "react-bootstrap";
import SuccessModal, { BillingStatus } from "../SuccessModal/SuccessModal";
import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Featurelimits from "../FeatureLimits/FeatureLimits";

/**
 * Helper function to map string to SuccessModalType
 */
const mapStringToSuccessModalType = (value: string): BillingStatus | null => {
  const validValues: BillingStatus[] = [
    "payment_success",
    "card_success",
    "payment_cancel",
    "card_cancel",
    "payment_failed",
    "card_failed",
    "upgrade_success",
    "upgrade_cancel",
    "upgrade_failed",
  ];

  return validValues.includes(value as BillingStatus)
    ? (value as BillingStatus)
    : null;
};

const BillingSection = () => {
  const { isLoading, billingDetails, error } = useBillingContext();

  const [billingStatus, setBillingStatus] = useState<BillingStatus | null>(
    null,
  );

  /**
   * States for managing payment success modal
   */
  const [URLSearchParams, SetURLSearchParams] = useSearchParams();

  /**
   * Handler for hiding Success modal
   */
  const handleHideSuccessModal = useCallback(
    (status?: "payment" | "upgrade") => {
      setBillingStatus(null);
      if (status) {
        URLSearchParams.set("modal", status);
        SetURLSearchParams(URLSearchParams);
      }
    },
    [SetURLSearchParams, URLSearchParams],
  );

  /**
   * Effect for showing payment success modal
   *
   * This effect should only run once when rendering the component and if billing details change
   */
  useEffect(() => {
    if (!billingDetails) {
      return;
    }
    const status = URLSearchParams.get("status");
    const successModalType = status
      ? mapStringToSuccessModalType(status)
      : null;

    if (successModalType) {
      // Show Payment Success Modal
      setBillingStatus(successModalType);

      // Remove Params
      URLSearchParams.delete("status");
      SetURLSearchParams(URLSearchParams);
    }
  }, [SetURLSearchParams, URLSearchParams, billingDetails]);

  return (
    <div className={`w-100 ${styles.mainContent}`}>
      <h3 className={`mb-0 ${styles.heading}`}>Plan and Pricing</h3>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <div className="h-100 w-100 d-flex align-items-center justify-content-center text-danger">
          {error}
        </div>
      ) : (
        <>
          <span className={`${styles.subSpan}`}>
            You are active on {billingDetails?.activePlan.displayName ?? ""}
          </span>
          <div>
            <PlanDetails setShowSuccessModal={setBillingStatus} />

            {billingDetails?.seats && (
              <BillingPay setShowSuccessModal={setBillingStatus} />
            )}

            <Featurelimits />

            <InVoiceTable />

            {/* Success Modal */}
            <Modal
              backdropClassName={`${styles.successBack}`}
              show={billingStatus !== null}
              onHide={handleHideSuccessModal}
              dialogClassName={`${styles.successDialog}`}
              contentClassName={`${styles.successContent}`}
              centered={true}
              backdrop="static"
            >
              <SuccessModal
                onHide={handleHideSuccessModal}
                isSuccess={
                  billingStatus === "payment_success" ||
                  billingStatus === "card_success" ||
                  billingStatus === "upgrade_success"
                }
                status={billingStatus ?? ""}
              />
            </Modal>
          </div>
        </>
      )}
    </div>
  );
};

export default BillingSection;
