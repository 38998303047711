import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import styles from "./Status.module.scss";
import useChangeTicketStatus from "src/hooks/useChangeTicketStatus";
import {
  fetchMessagesUpdates,
  setInnerTicketStatus,
  setSidebarTicketStatus,
} from "src/store/slices/innerTicket/innerTicket.slice";
import { TicketStatus } from "src/services/TicketService/getAllStatuses";
import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "src/store/store";
import ticketIcon from "src/assets/images/TicketIcon.svg";
interface Props {
  ticketId: number | string;
  status: string | undefined;
  displayBtn: string;
  canChangeStatus?: boolean;
}
function Status({ ticketId, status, displayBtn, canChangeStatus }: Props) {
  const dispatch = useAppDispatch();
  const activeTicketId = useAppSelector(
    (state) => state.innerTicket.activeTicketInfo.ticket_id,
  );
  const onChange = useCallback(
    (ticketStatus: TicketStatus) => {
      if (activeTicketId == ticketId) {
        dispatch(setInnerTicketStatus({ status: ticketStatus, ticketId }));
        dispatch(fetchMessagesUpdates());
      } else {
        dispatch(
          setSidebarTicketStatus({ ticketId: ticketId, status: ticketStatus }),
        );
      }
    },
    [ticketId, activeTicketId],
  );

  const { changeStatus, loading, ticketStatusButtons } = useChangeTicketStatus({
    ticketId: ticketId + "",
    currentStatusName: status + "",
    onChange,
  });

  return (
    <div className={`d-flex justify-content-between align-items-center`}>
      <div className="d-flex align-items-center status">
        <p className="mb-0 me-2 d-flex align-items-center">
          <span className={`${styles.ticket}`}>Ticket</span>
          <span className={`px-1 ${styles.ticket}`}>
            <img
              src={ticketIcon}
              alt="ticket"
              width={10}
              height={7}
            />
          </span>
        </p>
        <span
          className={`d-flex align-items-center ${
            status === "Open" ? styles.actionBtn : styles.pendingStatus
          } ${status === "Closed" ? styles.closeBox : ""}`}
        >
          <div
            className={`me-1 ${
              status === "Open" ? styles.dot : styles.pendingDot
            } ${status === "Closed" ? styles.closedDot : ""}`}
          ></div>{" "}
          <span className={`d-inline-block`}> {status} </span>
        </span>
      </div>

      {canChangeStatus === true ? (
        <div className={`${displayBtn} align-items-center`}>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip className={` ${styles.toolTipCustom}`}>
                {ticketStatusButtons.markAsText.text}
              </Tooltip>
            }
          >
            {({ ref, ...triggerHandler }) => (
              <span
                {...triggerHandler}
                ref={ref}
                className={`${
                  loading === "" ? "cursor-pointer" : "cursor-disabled"
                } ${styles.statusBox}`}
                onClick={() => {
                  if (loading === "") {
                    changeStatus(ticketStatusButtons.markAsText.status);
                  }
                }}
              >
                {ticketStatusButtons.markAsText.status === loading ? (
                  <Spinner
                    style={{ height: 10, width: 10 }}
                    variant="secondary"
                    animation="border"
                    size="sm"
                  />
                ) : (
                  ticketStatusButtons.markAsText.icon
                )}
              </span>
            )}
          </OverlayTrigger>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip className={` ${styles.toolTipCustom}`}>
                {ticketStatusButtons.button.text} ticket
              </Tooltip>
            }
          >
            {({ ref, ...triggerHandler }) => (
              <span
                {...triggerHandler}
                ref={ref}
                className={`ms-1 ${
                  loading === "" ? "cursor-pointer" : "cursor-disabled"
                } ${styles.statusBox}`}
                onClick={() => {
                  if (loading === "") {
                    changeStatus(ticketStatusButtons.button.status);
                  }
                }}
              >
                {ticketStatusButtons.button.status === loading ? (
                  <Spinner
                    style={{ height: 10, width: 10 }}
                    variant="secondary"
                    animation="border"
                    size="sm"
                  />
                ) : (
                  ticketStatusButtons.button.icon
                )}
              </span>
            )}
          </OverlayTrigger>
          {/* <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip className={` ${styles.toolTipCustom}`}>
              Close Ticket
            </Tooltip>
          }
        >
          {({ ref, ...triggerHandler }) => (
            <span
              {...triggerHandler}
              ref={ref}
              className={`ms-1 cursor-pointer ${styles.statusBox}`}
            >
              <i className="fa-solid fa-check"></i>
            </span>
          )}
        </OverlayTrigger> */}
        </div>
      ) : null}
    </div>
  );
}

export default Status;
