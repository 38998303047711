import { useCallback, useEffect, useRef } from "react";
import { useAppSelector } from "src/store/store";
import { useChannel } from "./pusherHooks/useChannel";

/**
 * Custom hook to manage the agent status channel and its lifecycle.
 * Uses the useChannel hook to subscribe to a Pusher channel.
 * Unsubscribes when the component unmounts or when the agent status channel changes.
 */
function useAgentStatusChannel() {

  // Ref to track the current state of the component to prevent unnecessary cleanup
  const currentState = useRef(true);

  // Retrieve the agent status channel from the Redux store
  const agentStatusChannel = useAppSelector((state) => state.globals.currentUserData?.agentStatusChannel ?? null);

  // Use the useChannel hook to subscribe to the agent status channel
  const { channel, unsubscribe, unsubscribeUsingPusher } = useChannel({
    channelName: agentStatusChannel
  });

  // useEffect to handle cleanup when the component is unmounted or the agent status channel changes
  useEffect(() => {
    return () => {
      // Check if the component is still mounted before attempting cleanup
      if (currentState.current) {
        // Unsubscribe from the agent status channel using the provided unsubscribe function
        unsubscribeUsingPusher();
        // Update the ref to indicate that the component is no longer mounted
        currentState.current = false;
      }
    };
  }, []); // Empty dependency array ensures the cleanup effect runs only once during unmount

  // Return the channel and unsubscribe functions for use in the component
  return {
    channel,
    unsubscribe,
  };
}


export default useAgentStatusChannel;
