import styles from "./PublishModal.module.scss";
import BrandDropdown from "src/routes/BotSettings/Children/BrandDropdown/BrandDropdown";
import hLogo from "src/assets/images/hLogo.png";
import usePublishModal from "./usePublishModal";
import Lottie from "lottie-light-react";
import celebration from "src/assets/images/celebration.json";
import { useNavigate } from "react-router-dom";
import dangerExclamation from "src/assets/images/dangerExclamation.png";

interface Props {
  onHide: () => void;
  // onComplete: () => void;
  selectedBrand: BrandOption | undefined;
  setSelectedBrand: (brand: BrandOption) => void;
  handlePublish: (force?: boolean) => void;
  publishLoader: boolean;
  showSetup: boolean;
  handleUnpublish: Function;
  name: string;
  selectedBrandName: string;
  showAlreadyPublishedModal: boolean;
  // updateBrandChange: Function;
}
export interface BrandOption {
  value: number;
  label: string;
  imageUrl?: string | null;
}

const PublishModal = ({
  onHide,
  selectedBrand,
  setSelectedBrand,
  handlePublish,
  publishLoader,
  showSetup,
  handleUnpublish,
  name,
  selectedBrandName,
  showAlreadyPublishedModal,
}: // updateBrandChange
// onComplete
Props) => {
  const { handleChange } = usePublishModal({
    setSelectedBrand,
  });

  const navigate = useNavigate();
  const handleOnOkay = () => {
    navigate("/bot/botProfiles");
  };

  return (
    <div className={`${styles.wrapper} ${showSetup ? "overflow-hidden" : ""}`}>
      {!publishLoader ? (
        !showSetup && !showAlreadyPublishedModal ? (
          <>
            <div
              className={`d-flex align-items-center justify-content-between`}
            >
              <h4 className={`mb-0 ${styles.modalHead}`}>Choose a brand</h4>
              <span
                className={`${styles.closeBtn}`}
                onClick={onHide}
              >
                <i className="fa-solid fa-xmark"></i>
              </span>
            </div>
            <div className="mt-4">
              <p className={`mb-1 ${styles.subHeading}`}>
                This will appear in your live chat for the following brand :
              </p>
              <BrandDropdown
                selectedBrand={selectedBrand}
                setSelectedBrand={handleChange}
                // updateBrandChange={updateBrandChange}
              />
              <div className={`d-flex justify-content-end mt-4 pt-3`}>
                <button
                  className={`${styles.nextBtn}`}
                  disabled={false}
                  onClick={() => {
                    handlePublish();
                  }}
                >
                  Publish
                </button>
              </div>
            </div>
          </>
        ) : !showSetup && showAlreadyPublishedModal ? (
          <div className={`text-center ${styles.successWrapper}`}>
            <span className={`mx-auto mx-3 my-3 d-block ${styles.dangerIcon}`}>
              <img
                src={dangerExclamation}
                className="img-fluid"
              />
            </span>
            <p className={`mb-1 ${styles.successHead}`}>Already published</p>
            <span className={`d-block mb-3 ${styles.successSpan}`}>
              A bot profile with the same audience and channels is already
              active. The existing profile will be deactivated if you publish
              this profile. Are you sure?
            </span>
            <div className="d-flex justify-content-center align-items-center">
              <button
                className={`me-4 ${styles.unpublishBtn}`}
                onClick={() => {
                  onHide();
                }}
              >
                <span className="pe-1">
                  <i className="fa-solid fa-eye"></i>
                </span>
                Go Back
              </button>
              <button
                className={`${styles.okayBtn}`}
                onClick={() => {
                  handlePublish(true);
                }}
              >
                Yes , I'm sure
              </button>
            </div>
          </div>
        ) : (
          <>
            <div className={`text-center ${styles.successWrapper}`}>
              <Lottie
                loop={false}
                className={`${styles.celebrationAnimation}`}
                animationData={celebration}
              ></Lottie>
              <span className={`mx-auto mb-3 ${styles.successIcon}`}>
                <i className="fa-solid fa-check"></i>
              </span>
              <p className={`mb-0 ${styles.successHead}`}>
                The setup is completed!
              </p>
              <span className={`d-block mb-3 ${styles.successSpan}`}>
                {name} now is live for {selectedBrandName} brand
              </span>
              <div className="d-flex justify-content-center align-items-center">
                <button
                  className={`me-4 ${styles.unpublishBtn}`}
                  onClick={() => {
                    handleUnpublish();
                  }}
                >
                  <span className="pe-1">
                    <i className="fa-solid fa-eye"></i>
                  </span>
                  Unpublish
                </button>
                <button
                  className={`${styles.okayBtn}`}
                  onClick={handleOnOkay}
                >
                  Okay
                </button>
              </div>
            </div>
          </>
        )
      ) : (
        <div className={`position-relative ${styles.loaderWrapper}`}>
          <span
            className="spinner-border"
            role="status"
          ></span>
          <img
            src={hLogo}
            alt=""
            className={`${styles.loaderImage} position-absolute`}
          />
        </div>
      )}
    </div>
  );
};

export default PublishModal;
