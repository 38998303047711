import {
  AntiRefundFollowUpQuestion,
  GetConfigModalParams,
} from "src/features/ReturnAutoWorkFlow/children/ConfigModals/oldReturnModals.types";
import { axiosJSON } from "src/globals/axiosEndPoints";

interface Payload extends GetConfigModalParams {
  id: string;
}

async function getRefundRequestFollowUpQuestionsService(params: Payload) {
  const { data: res } = await axiosJSON.get(
    "/api/setting/automation/refund/step/12/getRefundRequestFollowUpQuestions",
    { params },
  );

  if (res.err || res.error) {
    throw new Error(res.msg ?? res.message ?? "Some Error Occurred.");
  }

  return res.data as AntiRefundFollowUpQuestion[];
}

export default getRefundRequestFollowUpQuestionsService;
