/**
 * This file is the service file used for making api request.
 * It contains the getDomainSSLAndAccesControl function service which is explained below.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants"; // this is used for testing purpose only for frontend.

export interface GetDomainAndSslControlPayload {
  integrationId: number | string;
}

export interface DoaminSSLAndDomainAccessRes {
  customDomain: boolean;
  customDomainUrl: string;
  sslCertificate: "freeSSL" | "customSSL";
  passwordRestriction: boolean;
  password: string;
  isIpWhitelist: boolean;
  ipWhitelistList: string;
}

/**
 * For testing frontend with own api link if backend is not prepared.
 */
const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/getDomainSSLAndAccesControl`
  : "/api/chatSetting/getDomainSSLAndAccesControl";

/**
 * This service is made to fetch the domain and ssl settings saved data. And its params are related to its update function.
 */
export const getDomainSSLAndAccesControl = async (
  params: GetDomainAndSslControlPayload
) => {
  const { data: res } = await axiosJSON.post(apiEndPoint, params);
  return res.data as DoaminSSLAndDomainAccessRes;
};
