import { axiosJSON } from "src/globals/axiosEndPoints";

export interface LiveChatIntegration{
    integrationId : number|string,
    integrationName : string,
    enabled : boolean,
    integrationType : string,
}
export interface LiveChatIntegrationList {
  integrations : {[id: number | string] : LiveChatIntegration},
  integrationIds : Array<number | string>,
  metaData: {
    count: number;
    total: number;
  };
}

export interface GetAllliveChatIntegrationsParam{
    limit: number;
    start: number;
}

export async function getAllLiveChatIntegrations($param:GetAllliveChatIntegrationsParam) {
  const { data: res } = await axiosJSON.post(
    `/api/chatSetting/getAllLiveChatIntegrations`,
    $param
  );
  if (res.err) {
    throw res.msg;
  }

  const ret: LiveChatIntegrationList = {
    integrationIds: [],
    integrations:{},
    metaData:{
      count: 0,
      total: 0,
    }
  }

  ret.metaData.count = res.count;
  ret.metaData.total = res.total;

  res.data.forEach((integration: LiveChatIntegration) => {
    ret.integrations[integration.integrationId] = integration;
    ret.integrationIds.push(integration.integrationId);
  });

  return ret;
}
