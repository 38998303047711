import { Channel } from "pusher-js";
export interface UserUpdatedEventData{
  userId: number|string;
  eventNames: Array<"user_session_expired"|"user_deleted">;
}

export const getUserUpdatedEvent = (
  channel: Channel,
  callback: (res: UserUpdatedEventData) => void
) => {
  channel.bind("user_updated", callback);
};

export const getUserUpdatedEventUnbind = (
  channel: Channel,
) => {
  channel.unbind("user_updated");
};
