import {
  createAsyncThunk,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { AJAXSTATUS } from "src/globals/constants";
import chatSettingExtraReducers from "./chatSetting.extraReducers";
import chatSettingReducers from "./chatSetting.reducers";
import chatSettingThunks from "./chatSetting.thunks";

export const fetchChatSettingData = createAsyncThunk(
  "chatSetting/fetchChatSettingData",
  chatSettingThunks.fetchChatSettingDataThunk
);


export interface IChatSettingData {
  chatAssignment: "auto" | "manual",
  transcriptForwarding: string,
  fileSharingForVisitors: boolean,
  fileSharingForAgents: boolean,
  inactivityTimeouts : {
    whenNoMessageCloseChat : {
        enabled : boolean,
        value : number|string
    },
    whenNoMessageMakeChatInactive: {
        enabled: boolean,
        value: number|string
    },
    whenAgentNotResponding: {
        enabled : boolean,
        value : number|string
    }
  }
}

export interface IChatSetting{
  chatSettingData: IChatSettingData,
  chatSettingDataAjaxStatus: AJAXSTATUS,
}

export const initialState : IChatSetting = {
  chatSettingData: {
    chatAssignment: "auto",
    transcriptForwarding: "",
    fileSharingForVisitors: false,
    fileSharingForAgents: false,
    inactivityTimeouts : {
      whenNoMessageCloseChat : {
          enabled : false,
          value : 0
      },
      whenNoMessageMakeChatInactive: {
          enabled: false,
          value: 0
      },
      whenAgentNotResponding: {
          enabled : false,
          value : 0
      }
    }
  },
  chatSettingDataAjaxStatus: "pending",
}

export const chatSettingSlice = createSlice({
  name: "chatSetting",
  initialState,
  reducers: chatSettingReducers,
  extraReducers: (builder) => {
    builder.addCase(
      fetchChatSettingData.fulfilled,
      chatSettingExtraReducers.fetchChatSettingDataFulfilled
    );
    builder.addCase(fetchChatSettingData.rejected, (state) => {
      state.chatSettingDataAjaxStatus = "rejected";
      pushTheToast({
        position: "top-right",
        type: "danger",
        text: "Something went wrong!"
      });
    });
    builder.addCase(fetchChatSettingData.pending, (state) => {
      state.chatSettingDataAjaxStatus = "pending";
    });

  }
});

export const { resetChatSettingData } = chatSettingSlice.actions;

export default chatSettingSlice.reducer;
