import { useAppDispatch, useAppSelector } from "src/store/store";
import useResetBulkActions from "src/hooks/useResetBulkActions";
import BaseFooterButtonLight from "src/components/BulkActionComponents/commons/BaseFooterButtonLight";
import BaseFooterButtonSolid from "src/components/BulkActionComponents/commons/BaseFooterButtonSolid";
import { bulkAddStatus } from "src/store/slices/ticketStatus/ticketStatus.slice";
import styles from "./ModalFooter.module.scss";
import { useMemo } from "react";

const ModalFooter = ({ setModalShow }: any) => {
  const resetBulkActions = useResetBulkActions();
  const { selectedStatusId, bulkAddStatusState } = useAppSelector(
    (state) => state.ticketStatus
  );
  const dispatch = useAppDispatch();

  const handleOnClickAddBulkStatus = (e: any) => {
    e.preventDefault();
    // add bulk status action 
    dispatch(bulkAddStatus());
  };

  const handleOnClickExit = (e: any) => {
    e.preventDefault();
    resetBulkActions();
  };

  const handleOnClickAddMore = (e: any) => {
    e.preventDefault();
    setModalShow(false);
    resetBulkActions(); //resetting bulk action filters, in new select all filter implementation we can't let user add more cause select all works based on filters
  };

  const disableBtn = useMemo(() => {
    if (selectedStatusId === -1 || bulkAddStatusState === "pending") {
      return true;
    }
    return false;
  }, [selectedStatusId, bulkAddStatusState]);

  return (
    <div className={styles.root}>
      {bulkAddStatusState === "fulfilled" ? (
        <div className={`w-100 d-flex justify-content-between`}>
          <BaseFooterButtonLight onClick={handleOnClickExit}>
            Exit Bulk actions
          </BaseFooterButtonLight>

          <BaseFooterButtonSolid onClick={handleOnClickAddMore}>
            Add more
          </BaseFooterButtonSolid>
        </div>
      ) : (
        <BaseFooterButtonSolid
          onClick={handleOnClickAddBulkStatus}
          style={disableBtn ? { opacity: "50%" } : undefined}
          disabled={disableBtn}
        >
          Add
        </BaseFooterButtonSolid>
      )}
    </div>
  );
};

export default ModalFooter;
