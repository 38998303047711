import { axiosJSON } from "src/globals/axiosEndPoints";
import { FacebookPageInfo } from "./getFacebookPageWebhooks";

export interface WebhookInfo {
  label: string;
  key: string;
  enabled: boolean;
  hasPermission: boolean;
}
export interface FacebookIntegration {
  facebookAccount?: {
    id: string;
    name: string;
    imageUrl?: string | null;
  };
  brand?: {
    id: string;
    name: string;
    email: string;
    imageUrl?: string | null;
  };
  integrationId: number | string;
  pages: Array<FacebookPageInfo>;
}

export interface IGetAllFacebookIntegration {
  integrationIds: Array<string | number>;
  integrations: { [key: string | number]: FacebookIntegration };
  metaData: {
    allowMultipleIntegrations: boolean;
    totalCount: number;
  };
}
export interface IGetAllFacebookIntegrationParam {
  start: number;
  limit: number;
}
export async function getAllFacebookIntegrations(
  payload: IGetAllFacebookIntegrationParam,
) {
  const { data: res } = await axiosJSON.post(
    `/api/setting/integration/facebook/getAllFacebookIntegrations`,
    payload,
  );

  if (res.err || res.error) {
    throw new Error(res?.msg ?? res?.message ?? "Something went wrong!");
  }

  const ret: IGetAllFacebookIntegration = {
    integrationIds: [],
    integrations: {},
    metaData: {
      allowMultipleIntegrations: res.metaData?.allowMultipleIntegrations,
      totalCount: res.metaData?.totalCount,
    },
  };

  res.data.forEach((integration: FacebookIntegration) => {
    const id = parseInt(integration.integrationId + "");
    ret.integrations[id] = integration;
    ret.integrationIds.push(id);
  });

  return ret;
}
