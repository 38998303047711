import { RootState } from "src/store/store";
import { formatDate, formatTime, isObjOrStrEmpty } from "src/utils/utils";
import { Filters } from "./ticketFilters.slice";

/* 
++++ after
{
    "key":"ticket_date_gmt",
    "compare":"after",
    "value":"2022-03-08 00:00:00"
}

++++ between
 {
    "key":"ticket_date_gmt",
    "compare":"between",
    "value":"2022-03-08 00:00:00 to 2022-03-10 23:00:00"
}


*/
export function getCreatedDateValue(state: RootState): string | null {
  return formatFilterDate(
    state.ticketFilter.activeFilters.filterByCreatedDateValue
  );
}

/**
 * Selector for getting last updated date filter data
 * @param state Redux root State
 * @returns Formatted Last Updated Filter Date
 */
export function getLastUpdatedDateValue(state: RootState): string | null {
  return formatFilterDate(
    state.ticketFilter.activeFilters.filterByLastUpdatedValue
  );
}

export function getClosedDateValue(state: RootState): string | null {
  return formatFilterDate(
    state.ticketFilter.activeFilters.filterByClosedDateValue
  );
}

export function getNotRepliedSinceDateValue(state: RootState): string | null {
  return formatFilterDate(
    state.ticketFilter.activeFilters.filterByNotRepliedSinceValue
  );
}

export function filtersValidated(filters: any): boolean {
  let filtersAreOk = true;

  // validate created date filter
  if (!isObjOrStrEmpty(filters.filterByCreatedDate)) {
    // log.debug({ filtersssssssss: filters.filterByCreatedDate });

    if (filters.filterByCreatedDate.isValueRequired === true) {
      const dateValue = JSON.parse(
        JSON.stringify(filters.filterByCreatedDateValue)
      );
      // log.debug({ dateValueeeeeeeeeee: dateValue });
      if (formatFilterDate(dateValue) === null) {
        filtersAreOk = false;
      }
    }
  }

  // validate closed date filter
  if (!isObjOrStrEmpty(filters.filterByClosedDate)) {
    if (filters.filterByClosedDate.isValueRequired === true) {
      const dateValue = JSON.parse(
        JSON.stringify(filters.filterByClosedDateValue)
      );
      if (formatFilterDate(dateValue) === null) {
        filtersAreOk = false;
      }
    }
  }

  // Validate last updated filter
  if (!isObjOrStrEmpty(filters.filterByLastUpdated)) {
    if (filters.filterByLastUpdated.isValueRequired === true) {
      const dateValue = JSON.parse(
        JSON.stringify(filters.filterByLastUpdatedValue)
      );
      if (formatFilterDate(dateValue) === null) {
        filtersAreOk = false;
      }
    }
  }

  // validate not replied since date filter
  if (!isObjOrStrEmpty(filters.filterByNotRepliedSince)) {
    if (filters.filterByNotRepliedSince.isValueRequired === true) {
      const dateValue = JSON.parse(
        JSON.stringify(filters.filterByNotRepliedSinceValue)
      );
      if (formatFilterDate(dateValue) === null) {
        filtersAreOk = false;
      }
    }
  }

  return filtersAreOk;
}

export function formatFilterDate(dateValues: any) {
  let { startDate, startTime, selectsRange, endDate, endTime }: any =
    dateValues; //todo: create validate action

  let dateValue: string = ""; // todo
  // log.debug({ startDate, startTime, selectsRange, endDate, endTime });
  let formattedDate = "";
  let formattedTime = "";
  // log.debug(typeof startDate);
  // log.debug("inside");
  if (startDate !== null && startTime !== null) {
    const theTime =
      formatDate(new Date(startDate)) + "T" + formatTime(new Date(startTime));
    formattedDate = new Date(theTime).toISOString().slice(0, 10);
    formattedTime = new Date(theTime).toISOString().slice(11, 19);
  } else {
    return null;
  }

  if (selectsRange === true) {
    let formattedEndDate = "";
    let formattedEndTime = "";
    if (endDate !== null && endTime !== null) {
      const theEndTime =
        formatDate(new Date(endDate)) + "T" + formatTime(new Date(endTime));
      formattedEndDate = new Date(theEndTime).toISOString().slice(0, 10);
      formattedEndTime = new Date(theEndTime).toISOString().slice(11, 19);
      dateValue = `${formattedDate} ${formattedTime} to ${formattedEndDate} ${formattedEndTime}`;
    } else {
      return null;
    }
  } else {
    dateValue = `${formattedDate} ${formattedTime}`;
  }

  // log.debug({ dateValue });

  return dateValue;
}

export function showCreatedDateSelectRange(state: RootState): boolean {
  return state.ticketFilter.activeFilters.filterByCreatedDateValue.selectsRange;
}

export function getTotalFiltersAppliedCount(filters: Filters): number {
  return Object.values(filters).reduce((previousValue, currentValue) => {
    //by defult saving the filter values in local variable
    let withoutViewFilter: any = currentValue;
    //checking the filter value type is array
    if (Array.isArray(currentValue)) {
      //iterating over each value and filtering the view filter out
      withoutViewFilter = currentValue.filter((filter) => !filter?.viewFilter);
    } else if (typeof currentValue === "object") {
      //values is object, checking if it has viewFilter property set to true if yes returing empty object
      withoutViewFilter = currentValue?.viewFilter === true ? {} : currentValue;
    }
    return previousValue + (isObjOrStrEmpty(withoutViewFilter) ? 0 : 1);
  }, 0);
}
