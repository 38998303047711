import React, { useEffect, useMemo, useRef, useState } from "react";
import styles from "./TemplateSidebar.module.scss";
import SearchBar from "src/components/SearchBar/SearchBar";
import { NavLink } from "react-router-dom";
import { Modal } from "react-bootstrap";
import CreateCategory from "./Children/CreateCategory/CreateCategory";
import {
  IGetCategory,
  getViewTemplateCategories,
  searchCategory,
} from "src/services/Templates/viewTemplateCategories";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import InfiniteScroll from "src/components/InfiniteScroll";
import CategoryItem from "../../../TemplateFolder/Children/TemplateMainSection/Children/AllTemplates/Children/TemplateCategory/CategoryItem/CategoryItem";
import Loader from "src/components/Loader";
import { useGetTemplateCategories } from "../../../CustomHook/useGetTemplateCategories";

interface ITemplateCategory {
  id: number;
  name: string;
  subCategoryExists: boolean;
}
const TemplateSidebar = ({
  selectedCategory,
  setSelectedCategory,
  selectedCategoryError,
}: {
  selectedCategory: any;
  setSelectedCategory: any;
  selectedCategoryError: boolean;
}) => {
  const [showModal, setShowModal] = useState(false);

  const [searchText, setSearchText] = useState<string>("");
  const [searchInput, setSearchInput] = useState<string>("");

  const filters = useMemo(() => {
    return {
      start: 0,
      limit: 25,
      searchTerm: searchText,
    };
  }, [searchText]);

  const {
    status,
    categoryIds,
    categories,
    hasNextPage,
    fetchNextPage,
    fetchStatus,
    isLoading,
    refetch,
    addCategory,
  } = useGetTemplateCategories(filters);

  function selectedCategoryHandler(category: ITemplateCategory) {
    if (category.id === undefined) {
      return;
    }
    setSelectedCategory(category);
  }

  const onShow = (e: any) => {
    e.stopPropagation();
    setShowModal(true);
    // console.log("show");
  };
  const onHide = () => {
    setShowModal(false);
    // console.log("hide");
  };

  const errorMessage = useMemo(() => {
    if (
      status === "error" ||
      (status !== "success" && fetchStatus !== "fetching") ||
      (status !== "loading" && categoryIds.length === 0)
    ) {
      return (
        <span className={`ps-2 ${styles.noResultFound}`}>No result found</span>
      );
    }
  }, [status, fetchStatus, categoryIds.length]);
  return (
    <div className={`pe-3 ${styles.sidebarWrapper}`}>
      <p className={`mb-0 ${styles.templateHead}`}>Template Category:</p>
      <SearchBar
        className={`w-100 ${styles.search}  ${styles.searchInputBorder} px-1 mt-1`}
        inputClassName={` ${styles.input}`}
        placeholder={`Search category`}
        value={searchInput}
        onChange={(e: any) => {
          setSearchInput(e.target.value);
        }}
        onSearch={(value: string) => setSearchText(value)}
      />
      <div className="mt-3">
        {selectedCategoryError ? (
          <div className={`mb-1 ${styles.searchInputError}`}>
            *Please select a category
          </div>
        ) : (
          <></>
        )}
        {isLoading === true && categoryIds.length === 0 ? (
          <>
            <Loader />
          </>
        ) : (
          <>
            <InfiniteScroll
              style={{ maxHeight: "50vh" }}
              loadMore={fetchNextPage}
              hasMore={hasNextPage}
              initialDataLoaded={true}
            >
              {errorMessage
                ? errorMessage
                : categoryIds.map((categoryId) => {
                    const category = categories[categoryId];
                    return (
                      <CategoryItem
                        category={category}
                        selectedCategoryHandler={selectedCategoryHandler}
                        selectedCategory={selectedCategory}
                      />
                    );
                  })}
            </InfiniteScroll>

            <span
              className={`ps-2 cursor-pointer ${styles.addCategory}`}
              onClick={onShow}
            >
              {searchInput !== "" &&
              isLoading === false &&
              categoryIds.length === 0
                ? `+ Add New "${searchInput.trim()}"`
                : "+ Add Category"}
            </span>
          </>
        )}
        <Modal
          backdropClassName={`${styles.modalBack}`}
          show={showModal}
          onHide={onHide}
          dialogClassName={`${styles.modalDialog}`}
          contentClassName={`${styles.modalContent}`}
          centered={true}
        >
          <CreateCategory
            onHide={onHide}
            refreshCategories={refetch}
            searchedCategoryText={searchInput.trim()}
            addCategoryToList={addCategory}
          />
        </Modal>
      </div>
    </div>
  );
};

export default TemplateSidebar;
