import { useNavigate } from "react-router-dom";
import CommentPreview from "./Children/CommentSetting/CommentPreview/CommentPreview";
import CommentSetting from "./Children/CommentSetting/CommentSetting";
import styles from "./Comments.module.scss";
import useComments from "./useComments";
// The useComments hook is also imported to handle the state and logic related to comments. The component returns a div element with the necessary child components and props passed to them. The aim is to allow customers to style the helpcenter within the chat widget and provide feedback and comments.
const Comments = () => {
  const navigate = useNavigate();
  const {
    enableComments,
    reqApproval,
    setEnableComments,
    setReqApproval,
    approvedComment,
    unapprovedComment,
    setApprovedComment,
    setUnapprovedComment,
  } = useComments();

  return (
    <div className={`w-100 pt-4 px-3 ${styles.commentsWrapper}`}>
      <div className="mb-4 d-flex">
        <div>
          <div
            className={`d-flex justify-content-center align-items-center ${styles.backChat}`}
            onClick={() => navigate(-1)}
          >
            <i className="fa-solid fa-arrow-left"></i>
          </div>
        </div>
        <div>
          <h3 className={`mb-0 ${styles.heading} saufter_h3`}>
            Feedbacks / Comments and Questions
          </h3>
          <p className={`${styles.subHeading} saufter_subHeading`}>
            Let customers style the help center right inside your chat widget
            instead of starting a conversation.
          </p>
        </div>
      </div>
      <div className={`d-block d-md-flex`}>
        {/* Comment Setting - Left Section */}
        <CommentSetting
          enableComments={enableComments}
          setEnableComments={setEnableComments}
          reqApproval={reqApproval}
          setReqApproval={setReqApproval}
          approvedComment={approvedComment}
          unApprovedComment={unapprovedComment}
          setApprovedComment={setApprovedComment}
          setUnapprovedComment={setUnapprovedComment}
        />

        {/* Comment Preview - Right Section */}
        <CommentPreview
          enableComments={enableComments}
          reqApproval={reqApproval}
          approvedComment={approvedComment}
          unApprovedComment={unapprovedComment}
        />
      </div>
    </div>
  );
};

export default Comments;
