import { SetStateAction, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { AJAXSTATUS } from "src/globals/constants";
import {
  IUpdateAskForEmailDataParam,
  updateAskForEmailData,
} from "src/services/LiveChat/Settings/askForEmail/updateAskForEmailData";
import { fetchAskForEmailData } from "src/store/slices/liveChatSetting/Forms/askForEmail/askForEmailSetting.slice";
import { useAppDispatch } from "src/store/store";
import MessageBox from "../../../AskMail/OfflineMode/Children/MessageBox";
import styles from "./NoResponse.module.scss";

interface Props {
  data: {
    canSendMessage: boolean;
    setCanSendMessage: any;
    sendMessageAfter: string | number;
    setSendMessageAfter: any;
    messageText: string;
    setMessageText: any;
    confirmationText: string;
    setConfirmationText: any;
    canCustomerLeaveEmail: boolean;
    setCanCustomerLeaveEmail: any;
    offlineMessageText?: string;
    setOfflineMessageText?: any;
    offlineConfirmationText?: string;
    setOfflineConfirmationText?: any;
    askForEmailDataAjaxStatus: AJAXSTATUS;
  };
  handleCancel: any;
  isOfflineDataChanged?: boolean;
  isNotRespondDataChanged: boolean;
  setIsOfflineDataChanged?: any;
  setIsNotRespondDataChanged: any;
  integrationId: number | string;
  availabilityStartChatAlways: boolean;
}
const NoResponse = ({
  data,
  handleCancel,
  isNotRespondDataChanged,
  setIsNotRespondDataChanged,
  integrationId,
}: Props) => {
  const [count, setCount] = useState<number>(0);

  const [showSaveChange, setShowSaveChange] = useState(false);
  const [showMessageTextErr, setShowMessageTextErr] = useState(false);
  const [showConfirmationTextErr, setShowConfirmationErr] = useState(false);
  const [showSaveLoader, setShowSaveLoader] = useState(false);

  useEffect(() => {
    if (
      data.sendMessageAfter === null ||
      isNaN(parseInt(data.sendMessageAfter + ""))
    ) {
      setCount(0);
    } else {
      setCount(parseInt(data.sendMessageAfter + ""));
    }
  }, [data.sendMessageAfter]);

  useEffect(() => {
    if (isNotRespondDataChanged !== null) {
      setShowSaveChange(isNotRespondDataChanged);
    }
  }, [isNotRespondDataChanged]);
  const dispatch = useAppDispatch();

  const handleIncr = () => {
    if (count === 60) {
      setCount(count);
    } else {
      setCount(count + 1);
    }
    setShowSaveChange(true);
  };
  const handleDec = () => {
    if (count === 0 || isNaN(count)) {
      setCount(0);
    } else {
      setCount(count - 1);
    }
    setShowSaveChange(true);
  };

  const handleSave = () => {
    if (data.askForEmailDataAjaxStatus !== "pending") {
      //validating data
      if (data.canSendMessage === true) {
        if (data.messageText.trim() === "") {
          setShowMessageTextErr(true);
          return;
        } else {
          setShowMessageTextErr(false);
        }
        if (
          data.canCustomerLeaveEmail === true &&
          data.confirmationText.trim() === ""
        ) {
          setShowConfirmationErr(true);
          return;
        } else {
          setShowConfirmationErr(false);
        }
      }
      let payload: IUpdateAskForEmailDataParam = {
        integrationId: integrationId,
        whenAgentNotResponding: {
          canSendMessage: data.canSendMessage,
          sendMessageAfter: count,
          canCustomerLeaveEmail: data.canCustomerLeaveEmail,
        },
      };
      if (data.canSendMessage === true && payload.whenAgentNotResponding) {
        payload.whenAgentNotResponding["messageText"] = data.messageText;
      }

      if (
        data.canSendMessage === true &&
        data.canCustomerLeaveEmail === true &&
        payload.whenAgentNotResponding
      ) {
        payload.whenAgentNotResponding["confirmationText"] =
          data.confirmationText;
      }
      setShowSaveLoader(true);
      updateAskForEmailData(payload)
        .then((res) => {
          pushTheToast({
            type: "success",
            text: "Successfully changed",
            position: "top-right",
          });
          dispatch(fetchAskForEmailData(integrationId));
          setIsNotRespondDataChanged(false);
          setShowSaveLoader(false);
        })
        .catch((err) => {
          pushTheToast({
            type: "danger",
            text: "Something went wrong!",
            position: "top-right",
          });
          setShowSaveLoader(false);
        });
    }
  };

  return (
    <div>
      <span className={`${styles.notRespond}`}>
        Decide what to send when agent assigned is online does not respond.
      </span>
      <div className={`mt-2`}>
        <div className={`d-flex mb-1`}>
          <div className="form-check">
            <input
              data-lpignore="true"
              className="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id="noSend"
              checked={data.canSendMessage === false}
              onClick={(e) => {
                data.setCanSendMessage(false);
              }}
            />
          </div>

          <label
            htmlFor="noSend"
            className={`form-label cursor-pointer ${styles.radioText} ${
              !data.canSendMessage && styles.activeText2
            }`}
          >
            Don't send anything
          </label>
        </div>
        <div className={`d-flex mb-1`}>
          <div className="form-check">
            <input
              data-lpignore="true"
              className="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id="send"
              checked={data.canSendMessage === true}
              onClick={(e) => {
                data.setCanSendMessage(true);
              }}
            />
          </div>
          <label
            htmlFor="send"
            className={`form-label cursor-pointer ${styles.radioText} ${
              data.canSendMessage && styles.activeText
            }`}
          >
            Send a message when an agent doesn't respond for
          </label>
        </div>
      </div>
      {/* ----Show  when (Send a message when an agent doesn't respond for) radio 
    button is checked---- */}
      {data.canSendMessage === true && (
        <>
          <div className={`${styles.sendWrapper}`}>
            <div className={`d-flex`}>
              <div className={`${styles.timeSet}`}>
                <span>{isNaN(count) ? 0 : count} minutes</span>
              </div>
              <div className={`d-flex flex-column ${styles.arrowBox}`}>
                <div
                  className={`${styles.arrow_up}`}
                  onClick={handleIncr}
                ></div>
                <div
                  className={`${styles.arrow_down}`}
                  onClick={handleDec}
                ></div>
              </div>
            </div>

            <div className="mt-2">
              <MessageBox
                msgHead={`Message text`}
                messageText={data.messageText ?? ""}
                setMessageText={data.setMessageText}
                showWarning={showMessageTextErr}
              />
              <div>
                <div className="form-check">
                  <input
                    className={`form-check-input ${styles.checkBoxInput}`}
                    type="checkbox"
                    value=""
                    id="defaultCheck1"
                    checked={data.canCustomerLeaveEmail === true}
                    onClick={(e) =>
                      data.setCanCustomerLeaveEmail(!data.canCustomerLeaveEmail)
                    }
                  />
                  <label
                    className={`form-check-label ${styles.radioText}`}
                    htmlFor="defaultCheck1"
                  >
                    Ask for e-mail
                  </label>
                </div>
              </div>
              {data.canCustomerLeaveEmail === true && (
                <div className="mt-2">
                  <MessageBox
                    msgHead={`Confirmation text`}
                    messageText={data.confirmationText ?? ""}
                    setMessageText={data.setConfirmationText}
                    showWarning={showConfirmationTextErr}
                  />
                </div>
              )}
            </div>
          </div>
        </>
      )}
      {showSaveChange === true ? (
        <div
          className={`d-flex flex-column flex-md-row align-items-start justify-content-start justify-content-md-end mt-2`}
        >
          <button
            className={`me-2 bg-white ${styles.cancelBtn}`}
            onClick={(e) => {
              setShowSaveChange(false);
              handleCancel();
            }}
          >
            Cancel
          </button>
          <button
            className={`mt-2 mt-md-0 ${styles.saveBtn}`}
            disabled={showSaveLoader}
            onClick={handleSave}
          >
            {showSaveLoader === true ? (
              <Spinner
                className="my-auto mx-1"
                animation="border"
                color="white"
                size="sm"
              />
            ) : (
              "Save Changes"
            )}
          </button>
        </div>
      ) : undefined}
    </div>
  );
};
export default NoResponse;
