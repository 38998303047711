import styles from "../../SnoozeModal.module.scss";
import snooze1 from "src/assets/images/snoozImg.png";
import close from "src/assets/images/close.png";
import AlarmTime from "../AlarmTime/AlarmTime";
import snooze from "src/assets/images/snooze.png";
import { useEffect, useState } from "react";
import { ISnoozeData } from "src/services/Snooze/getSnooze";
import { KeyExpiresAt } from "src/services/Snooze/createSnooze";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import {
  convertUserTimeZoneToAnother,
  generateLocal,
  getTimeFromNow,
} from "src/utils/dateLibrary";
import { SnoozeDateOptions } from "src/services/Snooze/getSnoozeDateOptions";

interface Props {
  onHide: () => void;
  onSnooze: (key: KeyExpiresAt, dateTime?: string) => void;
  onRemoveSnooze: () => void;
  snoozeData: ISnoozeData | null;
  snoozeOptions: SnoozeDateOptions;
  reOpenStr: string | null;
  userTimezone?: string;
}

function SelectModal({
  onHide,
  onSnooze,
  onRemoveSnooze,
  snoozeData,
  snoozeOptions,
  reOpenStr,
  userTimezone, //getting usertime in props
}: Props) {
  const [key, setKey] = useState(null as KeyExpiresAt | null);
  // Using the useState hook to manage the 'date' state
  const [date, setDate] = useState(() => {
    // Creating a new Date object with the user's timezone
    const current = new Date(
      new Date().toLocaleString("en-US", { timeZone: userTimezone }),
    );

    // Generating a local date and time representation
    const currentDateTime = generateLocal(current);

    // Extracting and returning the date part
    return currentDateTime.split("T")[0];
  });

  // Using the useState hook to manage the 'time' state
  const [time, setTime] = useState(() => {
    // Creating a new Date object with the user's timezone
    const current = new Date(
      new Date().toLocaleString("en-US", { timeZone: userTimezone }),
    );

    // Generating a local date and time representation
    const currentDateTime = generateLocal(current);

    // Extracting and returning the time part
    return currentDateTime.split("T")[1];
  });

  useEffect(() => {
    if (snoozeData !== null) {
      if (snoozeData.tillCustomerReplies === 1) {
        setKey("till-customer-replies");
      } else if (snoozeData.expireAtGmt) {
        setKey("custom-datetime");
        const current = new Date(
          new Date(snoozeData.expireAtGmt + "Z").toLocaleString("en-US", {
            timeZone: userTimezone,
          }),
        );
        const currentDateTime = generateLocal(current);
        setDate(currentDateTime.split("T")[0]);
        setTime(currentDateTime.split("T")[1]);
      }
    }
  }, [snoozeData, userTimezone]);

  return (
    <>
      <div className={`${styles.Header}`}>
        <div className="d-flex align-items-center">
          <img
            src={snooze1}
            alt="Snooze button icon"
            className={`${styles.snoozeImg}`}
          />
          <span className={`${styles.snoozeText}`}>Snooze</span>
        </div>
        <div>
          <img
            className={`${styles.closeBtn}`}
            src={close}
            alt="close"
            onClick={onHide}
          />
        </div>
      </div>
      <div className={`p-2`}>
        {snoozeOptions.map((option) => {
          switch (option.key) {
            case "till-customer-replies":
              return (
                <p
                  className={`${styles.custReply} ${
                    key === option.key ? styles.active : ""
                  } my-2 px-2 py-3`}
                  onClick={(e) => {
                    setKey(option.key);
                  }}
                >
                  Till Customer Replies
                </p>
              );
            case "custom-datetime":
              return (
                <>
                  <div className={`py-2`}>
                    <div
                      className={`${styles.dateBtn} ${
                        key === option.key ? styles.active : ""
                      }`}
                      onClick={() => setKey(option.key)}
                    >
                      <span>Custom date & time</span>
                      <span>
                        {" "}
                        <i className="fa-solid fa-angle-right"></i>
                      </span>
                    </div>
                  </div>
                  {key === option.key && (
                    <div className={`pt-2`}>
                      <div>
                        <p className={`mb-1 ${styles.chooseDate}`}>
                          Choose date & time
                        </p>

                        <div
                          className={`d-flex justify-content-between mb-2 ${styles.dateTime}`}
                        >
                          <input
                            type="date"
                            data-lpignore="true"
                            value={date}
                            onChange={(e) => {
                              setDate(e.target.value);
                            }}
                          />
                          <input
                            type="time"
                            data-lpignore="true"
                            value={time}
                            onChange={(e) => {
                              setTime(e.target.value);
                            }}
                            className="ms-2"
                          />
                        </div>
                        {reOpenStr !== null && (
                          <div className="d-flex align-items-center pt-1 ms-2">
                            <img
                              src={snooze}
                              alt=""
                            />
                            <span className={`ms-2 ${styles.reOpens}`}>
                              {reOpenStr}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </>
              );
            default:
              return (
                <AlarmTime
                  log={option.name}
                  dateTime={option.dateTimeString}
                  onClick={(e) => {
                    setKey(option.key);
                  }}
                  active={option.key === key}
                />
              );
          }
        })}
      </div>
      {key !== null && (
        <div className="mb-2 d-flex flex-wrap justify-content-between px-2 pt-1 pb-2">
          <div>
            {snoozeData !== null && (
              <button
                className={`me-1 me-md-0 mb-1 ${styles.removeSnooze}`}
                onClick={onRemoveSnooze}
              >
                Remove Snooze
              </button>
            )}
          </div>
          <div>
            <button
              className={`mb-1 ${styles.cancel} me-1`}
              onClick={onHide}
            >
              Cancel
            </button>
            <button
              className={`${styles.submit}`}
              onClick={() => {
                const dateTime =
                  key === "custom-datetime"
                    ? date !== ""
                      ? `${date} ${
                          time !== ""
                            ? time.length === 8
                              ? time
                              : `${time}:00`
                            : "00:00:00"
                        }`
                      : undefined
                    : undefined;
                if (dateTime !== undefined && key === "custom-datetime") {
                  if (
                    new Date(
                      convertUserTimeZoneToAnother(
                        //converting selected datetime to gmt before comparing
                        dateTime,
                        userTimezone,
                        "GMT",
                      ),
                    ).getTime() > new Date().getTime()
                  ) {
                    onSnooze(key, dateTime);
                  } else {
                    pushTheToast({
                      type: "warning",
                      text: "Please keep the date and time greater than current!",
                      position: "top-right",
                    });
                  }
                } else if (key !== "custom-datetime") {
                  onSnooze(key);
                } else {
                  pushTheToast({
                    type: "warning",
                    text: "Please fill the date!",
                    position: "top-right",
                  });
                }
              }}
            >
              Snooze
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default SelectModal;
