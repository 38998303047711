/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Button, Col, Spinner } from "react-bootstrap";
import { FormEventHandler, useCallback, useMemo, useState } from "react";
import { Link, NavLink } from "react-router-dom";

import styles from "./LoginForm.module.scss";
import { InitialConfig } from "src/services/Auth/getInitialConfig";
/**
 * Login form component for user authentication.
 */
const LoginForm = ({
  handelLogInSubmit,
  handleForgetPassClick,
  recaptchaDiv,
  loading,
  blockError = "",
  data,
}: {
  handelLogInSubmit: (e: any) => void;
  handleForgetPassClick: (e: any) => void;
  recaptchaDiv: React.ReactNode;
  loading: boolean;
  blockError?: string;
  data?: InitialConfig;
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className={`col-12 col-md-6 ${styles.loginWrapper}`}>
      <div className="mt-3 pt-3 mb-3 mb-md-0">
        <h3 className={`mx-0 mb-0 ${styles.loginHeading}`}>Login</h3>
        {/* Form to be filled by user */}
        <Form
          className="row g-3"
          id="loginForm"
          onSubmit={handelLogInSubmit}
        >
          {/* Email input field */}
          <Col
            md={12}
            className="pt-2"
          >
            <Form.Group controlId="email">
              <Form.Label className={`${styles.formLabel}`}>E-mail</Form.Label>
              <Form.Control
                name="email"
                className={`${styles.inputBox} py-3`}
                type="email"
                disabled={loading}
                placeholder="Please enter your e-mail address"
                required={true}
              />
            </Form.Group>
          </Col>

          {/* Password input field */}
          <Col
            md={12}
            className="pt-2"
          >
            <Form.Group controlId="password">
              <Form.Label className={`${styles.formLabel}`}>
                Password
              </Form.Label>
              <div className={`position-relative`}>
                <Form.Control
                  name="password"
                  className={`${styles.inputBox} py-3`}
                  type={showPassword ? "text" : "password"}
                  disabled={loading}
                  placeholder="Please enter your password"
                  required={true}
                />
                <div
                  className={`${styles.eyeShow}`}
                  onClick={toggleShowPassword}
                >
                  <span>
                    {!showPassword ? (
                      <i className="fa-solid fa-eye"></i>
                    ) : (
                      <i className="fa-solid fa-eye-slash"></i>
                    )}
                  </span>
                </div>
              </div>
            </Form.Group>
          </Col>

          {/* Block error message */}
          {blockError.trim() ? (
            <Col
              md={12}
              className="pt-2 d-none"
            >
              <div className="is-invalid"></div>
              <Form.Control.Feedback type="invalid">
                {blockError}
              </Form.Control.Feedback>
            </Col>
          ) : (
            ""
          )}
          {recaptchaDiv}
          <Col
            md={12}
            className="mt-0"
          >
            <div
              className="d-flex flex-row align-items-center justify-content-start"
              id="login__remember"
            >
              <div className="form-check d-none">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                />
                <label
                  className={`form-check-label ${styles.saufterSubHead}`}
                  htmlFor="flexCheckDefault"
                >
                  Remember me
                </label>
              </div>
              <div
                className={`d-flex d-none flex-column ${styles.verticleSeparator}`}
              ></div>
              <div
                className={`cursor-pointer d-flex flex-column text-center ${styles.saufterSubHead}`}
                id="forgotPass"
                onClick={handleForgetPassClick}
              >
                Forgot your password?
              </div>
            </div>
          </Col>

          {/* Submit button and social login buttons */}
          <Col
            md={12}
            className="mt-0"
          >
            <Button
              className={`btn btn-primary bg-dark w-100 text-center border-0 ${
                styles.signUpBtn
              } ${loading ? " disabledbtn" : ""}`}
              type="submit"
              disabled={loading}
            >
              {loading ? <Spinner /> : <>Login</>}
            </Button>

            {/* Social login buttons */}
            <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mt-4 pt-1">
              {data?.socialLogins?.map((socialLogin) => {
                return (
                  <a
                    href={`${process.env.REACT_APP_SITE_URL ?? ""}${
                      socialLogin.route
                    }`}
                    className={`${styles.socialBtn} m-1 mb-2 mb-md-0`}
                  >
                    <img
                      src={socialLogin.icon}
                      alt=""
                      className="me-2"
                    />
                    <span>{socialLogin.name}</span>
                  </a>
                );
              })}
            </div>
          </Col>
        </Form>
      </div>
    </div>
  );
};

export default LoginForm;
