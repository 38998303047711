import { initialState, LiveChatIntegrationsData } from "./LiveChatIntegrations.slice";

// reset goalSettings data
const resetLiveChatIntegrationsData = (state: LiveChatIntegrationsData) => {
  state.liveChatIntegrationsData = {};
  state.liveChatIntegrationsIdList = [];
  state.fetchLiveChatIntegrationsAjaxStatus = "pending";
  state.metaData.count =0;
  state.metaData.total =0;
};


export default { 
  resetLiveChatIntegrationsData, 
};