import { axiosJSON } from "src/globals/axiosEndPoints";
import APIResponse from "./APIReponse";

interface DeleteTagRes {
 status: boolean;
}

export interface DeleteTagParam {
  id: number;
}

export async function deleteTag(params: DeleteTagParam) {
  
  const { data } = await axiosJSON.delete(
    "/api/tags/deleteTag",
    {params}
  );

  if (data === false) {
    throw new Error("Falied to delete tag!!");
  }

  return data;
}
