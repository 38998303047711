import { useCallback, useEffect } from "react";
import Loader from "src/components/Loader";
import { useReturnAutoWorkFlow } from "src/features/ReturnAutoWorkFlow/hooks/useReturnAutoWorkFlow";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  fetchAntiRefundStrategies,
  updateAntiRefundStrategies,
} from "../../store/slices/AntiRefundStrategiesSlice/antiRefundStrategies.thunk";
import styles from "./AntiRefundStrategies.module.scss";
import ReasonRequestBox from "./RefundBox/RefundBox";
import {
  useFetchReturnProducts,
  useFetchReturnProductTags,
} from "src/features/ReturnAutoWorkFlow/hooks/useFetchReturnConditions";

const AntiRefundStrategies = ({
  onHide,
  onSave = () => {},
}: {
  onHide: () => void;
  onSave?: () => void;
}) => {
  const { returnAutoWorkFlow } = useReturnAutoWorkFlow();
  const dispatch = useAppDispatch();
  const { loadingStatus, fetchProducts, products } = useFetchReturnProducts();
  const {
    fetchProductTags,
    loadingStatus: loadingStatusTags,
    productTags,
  } = useFetchReturnProductTags();

  useEffect(() => {
    if (returnAutoWorkFlow.integrationId) {
      dispatch(
        fetchAntiRefundStrategies({
          integrationId: returnAutoWorkFlow.integrationId,
        }),
      );
    }
  }, [dispatch, returnAutoWorkFlow.integrationId]);

  useEffect(() => {
    if (loadingStatusTags === "idle" && productTags.length === 0) {
      fetchProductTags();
    }

    if (loadingStatus === "idle" && products.length === 0) {
      fetchProducts();
    }
  }, [
    fetchProductTags,
    fetchProducts,
    loadingStatus,
    loadingStatusTags,
    productTags.length,
    products.length,
  ]);

  const fetchAjaxStatus = useAppSelector(
    (state) => state.antiRefundStrategies.fetchAjaxStatus,
  );

  const reasons = useAppSelector(
    (state) => state.antiRefundStrategies.refundRequests.reasons,
  );

  // This function is called when a save button is clicked.
  const handleSave = useCallback(async () => {
    if (!returnAutoWorkFlow.integrationId) {
      return;
    }

    await dispatch(
      updateAntiRefundStrategies({
        integrationId: returnAutoWorkFlow.integrationId,
      }),
    );

    onSave();
  }, [dispatch, onSave, returnAutoWorkFlow.integrationId]);

  const updateAntiRefundRequestAjaxStatus = useAppSelector(
    (state) => state.antiRefundStrategies.updateAntiRefundRequestAjaxStatus,
  );

  if (fetchAjaxStatus === "pending") {
    return (
      <div className={`${styles.loaderHeight}`}>
        <Loader />
      </div>
    );
  }

  if (fetchAjaxStatus === "rejected") {
    return <p>Something went wrong while fetching data</p>;
  }

  return (
    <div className="position-relative">
      {updateAntiRefundRequestAjaxStatus === "pending" ? (
        <div className={`${styles.loadingBlock}`}></div>
      ) : (
        ""
      )}
      {/* Modal Header + Close Button */}
      <div className="p-3 pb-0 d-flex align-items-center">
        <span
          className={`${styles.closeModal}`}
          onClick={onHide}
        >
          <i className="fa-solid fa-xmark"></i>
        </span>
        <h3 className={`ms-2 mb-0 ${styles.heading}`}>
          Configure anti- refund strategies
        </h3>
      </div>
      {/* Content */}
      <div className={`p-3 pb-0 mt-4 ${styles.contentWrapper}`}>
        <p className={`${styles.requestHeader}`}>Refund requests</p>
        {reasons.map((reasonData) => {
          return (
            <ReasonRequestBox
              reasonData={reasonData}
              question="question11"
            />
          );
        })}
      </div>
      {/* Below Buttons */}
      <div className={`p-3 d-flex justify-content-end ${styles.btnWrapper}`}>
        <button
          className={`${styles.cancelBtn}`}
          onClick={onHide}
        >
          Back
        </button>
        <button
          className={`ms-3 ${styles.saveBtn}`}
          onClick={handleSave}
        >
          {/* Dispay Loading text while updating the anti refund request */}
          {updateAntiRefundRequestAjaxStatus === "pending" ? (
            <>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Loading...</span>
            </>
          ) : (
            "Save"
          )}
        </button>
      </div>
    </div>
  );
};

export default AntiRefundStrategies;
