import styles from "./TagItemDisplay.module.scss";

const TagItemDisplay = ({ tag, onClickRemove }: any) => {
  const handleOnClick = (e: any) => {
    e.preventDefault();
    onClickRemove(tag);
  };
  return (
    <span className={`${styles.addTagEmail} d-flex justify-content-between`}>
      <span className={`my-auto text-truncate ${styles.maxW}`}>
        {tag.tagName}
      </span>
      <span
        className={`${styles.addTagMark} d-flex flex-column justify-content-center my-auto mx-1`}
      >
        <i className="fas solid fa-xmark m-auto" onClick={handleOnClick} />
      </span>
    </span>
  );
};

export default TagItemDisplay;
