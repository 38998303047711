import { useNavigate, useParams } from "react-router-dom";
import FinalChat from "../../../Customization/Children/FinalChat/FinalChat";
import styles from "./CustomKnowledgeBased.module.scss";
import useCustomKnowledgeBased from "./useCustomKnowledgeBased";
import { useAppSelector } from "src/store/store";
const CustomKnowledgeBased = () => {
  const { integrationId } = useParams();
  const navigate = useNavigate();
  const pageIntegrationId = integrationId
    ? decodeURIComponent(integrationId)
    : "";

  const { customizationData } = useAppSelector(
    (state) => state.customizationSettings,
  );
  const {
    chatWidgetTitle,
    setChatWidgetTitle,
    chatWidgetPlaceholder,
    setChatWidgetPlaceholder,
    showSaveChanges,
    handleCancel,
    handleEdit,
  } = useCustomKnowledgeBased();

  return (
    // Overall, this component represents a knowledge base customization feature in a chat widget interface.
    <div className={`flex-wrap ${styles.knowledgeWrapper}`}>
      <div className={`position-relative p-3 ${styles.leftDiv}`}>
        <div className={`d-flex flex-column flex-md-row`}>
          {/* <div className={`${styles.backArrow}`}>
            <span
              onClick={(e) => {
                navigate(
                  "/live-chat/settings/" + pageIntegrationId + "/Knowledge"
                );
              }}
            >
              <i className="fa-solid fa-arrow-left"></i>{" "}
            </span>
          </div> */}
          <div>
            <div className="d-flex">
              {/* header section  */}
              <div>
                <div
                  className={`d-flex justify-content-center align-items-center ${styles.backChat}`}
                  onClick={() => navigate(-1)}
                >
                  <i className="fa-solid fa-arrow-left"></i>
                </div>
              </div>
              <div>
                <h3 className={`mb-1 ${styles.heading} saufter_h3`}>
                  Customise KnowledgeBase in the chat widget
                </h3>
                <span className={`${styles.subHeading} saufter_subHeading`}>
                  Let customers style the help center right inside your chat
                  widget instead of starting a conversation.
                </span>
              </div>
            </div>
            {/* Form Section */}
            <div className="mt-3">
              <form>
                <div className="mb-3">
                  <label
                    className={`form-label ${styles.formLabel} saufter_h4`}
                  >
                    Title
                  </label>
                  <input
                    data-lpignore="true"
                    type="text"
                    className={`form-control ${styles.titleInput}`}
                    placeholder={`Search Related queries`}
                    aria-describedby="emailHelp"
                    value={chatWidgetTitle}
                    onChange={(e) => setChatWidgetTitle(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label
                    className={`form-label ${styles.formLabel} saufter_h4`}
                  >
                    Placeholder
                  </label>
                  <input
                    data-lpignore="true"
                    type="text"
                    className={`form-control ${styles.titleInput}`}
                    placeholder={`Search FAQs and related Artticles`}
                    aria-describedby="emailHelp"
                    value={chatWidgetPlaceholder}
                    onChange={(e) => setChatWidgetPlaceholder(e.target.value)}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* Button's */}
        {showSaveChanges && (
          <div className={`text-end ${styles.btnSave}`}>
            <button
              type="button"
              className={`${styles.cancelBtn}`}
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button
              className={`${styles.saveBtn}`}
              onClick={handleEdit}
            >
              Save changes
            </button>
          </div>
        )}
      </div>

      <div className={`${styles.rightDiv}`}>
        {/* showing data from customization in this section */}
        <FinalChat
          customizationData={{
            topbarHeadingText: customizationData?.topbarHeading ?? "Hi There !",
            topbarSubHeadingText:
              customizationData?.topbarSubHeading ??
              "Welcome to Acme support. How can we help you?",
            statusTextWhenTeamOnline: {
              readonly: "we generally reply in",
              value: "few minutes",
            },
            statusTextWhenTeamOffline: {
              readonly: "We'll be back at",
              value: "",
            },
            autoreplayText: customizationData?.autoreplayText ?? "",
            minimizedWindowColour:
              customizationData?.appearances?.appearanceWindowColor?.value,
            liveChatThemeColor:
              customizationData?.appearances?.liveChatThemeColor?.value,
            showAutoReplyText: false,
            selectedWidgetPosition:
              customizationData?.appearances?.widgetPosition != null
                ? customizationData?.appearances.widgetPosition == "right"
                  ? "right"
                  : "left"
                : "right",
            submitButtonText: "Start a chat",
            showOnlineStatusText: true,
          }}
          knowledgeBasedData={{
            chatWidgetTitle: chatWidgetTitle,
            chatWidgetPlaceholder: chatWidgetPlaceholder,
          }}
          show={{ homePage: true }}
        />
      </div>
    </div>
  );
};
export default CustomKnowledgeBased;
