import { useCallback } from "react";
import styles from "./FeedbackTable.module.scss";

// Function to get the emoji by using switch case
const getEmoji = (emoji: string) => {
  switch (emoji) {
    case "happy":
      return (
        <>
          <div className={`d-flex align-items-center ${styles.emojiText}`}>
            <span> 😁 </span>
            <span className={`${styles.emoji}`}> Happy</span>
          </div>
        </>
      );
    case "sad":
      return (
        <>
          <div className={`d-flex align-items-center ${styles.emojiText}`}>
            <span> 😞 </span>
            <span className={`${styles.emoji}`}> Sad</span>
          </div>
        </>
      );
    case "neutral":
      return (
        <>
          <div className={`d-flex align-items-center ${styles.emojiText}`}>
            <span> 😐 </span>
            <span className={`${styles.emoji}`}> Neutral</span>
          </div>
        </>
      );
    case "yes":
      return (
        <>
          <div className={`d-flex align-items-center ${styles.emojiText}`}>
            <span> 👍 </span>
            <span className={`${styles.emoji}`}> Yes</span>
          </div>
        </>
      );
    case "no":
      return (
        <>
          <div className={`d-flex align-items-center ${styles.emojiText}`}>
            <span> 👎 </span>
            <span className={`${styles.emoji}`}> No</span>
          </div>
        </>
      );
    default:
      return "";
  }
};

export default getEmoji;
