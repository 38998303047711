/**
 * This file contains the custome hook for component.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import {
  deleteArticle,
  deleteArticleParams,
} from "src/services/LiveChat/Settings/knowledgeBased/article/deleteArticle";
import {
  duplicateArticle,
  duplicateArticleParams,
} from "src/services/LiveChat/Settings/knowledgeBased/article/duplicateArticle";
import {
  addArticleCategory,
  addArticleCategoryParams,
} from "src/services/LiveChat/Settings/knowledgeBased/articleCategory/addArticleCategory";
import {
  deleteArticleCategory,
  deleteArticleCategoryParams,
} from "src/services/LiveChat/Settings/knowledgeBased/articleCategory/deleteArticleCategory";
import {
  updateArticleCategory,
  updateArticleCategoryParams,
} from "src/services/LiveChat/Settings/knowledgeBased/articleCategory/updateArticleCategory";
import { setCategorySearchValue } from "src/store/slices/liveChatSetting/chatWidgetSetting/articleCategory/articleCategory.slice";
import {
  addDuplicateArticle,
  deleteArticleFromList,
  fetchAllArticleCategories,
  fetchAllArticles,
  fetchDuplicateArticle,
  resetArticleCategoriesData,
  setNewOrderOfCategories,
  updateCategoryName,
} from "src/store/slices/liveChatSetting/chatWidgetSetting/knowledgeBased/knowledgeBasedSetting.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import useCategoryLoader from "./Children/Loaders/CategoryLoader";
import styles from "./CreateArticle.module.scss";
import useCategoryError from "./Children/Errors/CategoryError";
import { QueryClient, useMutation } from "@tanstack/react-query";
import { updatePriorities } from "src/services/KnowledgeBase/updatePriorities.service";

const fetchLimit = 15;

/**
 * This hook is used for managing article creation and related actions
 */
function useCreateArticle() {
  //  Constants & States //
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { integrationId } = useParams();

  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [newCategoryName, setNewCategory] = useState<string>("");
  const [activeCategoryId, setActiveCategoryId] = useState<string | number>(0);
  const [showCategoryLoader, setShowCategoryLoader] = useState(false);
  const [selectedArticleId, setSelectedArticleId] = useState<number | string>(
    0
  );
  const [showArticleDeleteModal, setShowArticleDeleteModal] = useState(false);
  const [showCategoryDeleteModal, setShowCategoryDeleteModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [showDeleteLoader, setShowDeleteLoader] = useState(false);
  const [showValdation, setShowValidation] = useState(false);

  const pageIntegrationId = integrationId
    ? decodeURIComponent(integrationId)
    : "";

  // Redux States Variables
  const {
    categoryData,
    categoryIdList,
    fetchCategoryAjaxStatus: fetchArticleCategoryAjaxStatus,
    categoryMetaData: metaData,
  } = useAppSelector((state) => state.knowledgeBasedSettings);

  // Refs
  const currentState = useRef({
    fetchArticleCategoryAjaxStatus,
    categoryData,
    categoryIdList,
    metaData,
    initialFetch: false,
  });

  // Use Effects
  useEffect(() => {
    currentState.current = {
      categoryData,
      categoryIdList,
      fetchArticleCategoryAjaxStatus,
      metaData,
      initialFetch: currentState.current.initialFetch,
    };
  }, [categoryData, categoryIdList, fetchArticleCategoryAjaxStatus, metaData]);

  // Function to handle update category
  const handleUpdateCategory = (
    categoryId: number | string,
    categoryName: string
  ) => {
    isCategoryNameUpdate.current = true;
    setNewCategory(categoryName);
    setActiveCategoryId(categoryId);
    setShowModal(true);
    setShowCategoryLoader(false);
  };

  const [deletedArticleCategoryId, setDeletedArticleCategory] = useState<
    string | number
  >();

  // Function to handle article delete modal
  const handleArticleDeleteModal = (
    articleId: string | number,
    categoryId: string | number
  ) => {
    if (categoryId !== null && categoryId !== undefined) {
      setDeletedArticleCategory(categoryId);
    }
    if (articleId !== null && articleId !== undefined) {
      setSelectedArticleId(articleId);
      setShowArticleDeleteModal(true);
      setShowCategoryDeleteModal(false);
    }
  };

  // Function to handle category delete modal
  const handleCategoryDeleteModal = (articleId: string | number) => {
    if (articleId !== null && articleId !== undefined) {
      setSelectedArticleId(articleId);
      setShowArticleDeleteModal(false);
      setShowCategoryDeleteModal(true);
    }
  };

  // Function to handle article duplicate
  const handleArticleDuplicate = (
    articleId: number | string,
    articleName: string
  ) => {
    if (categoryData !== null && fetchArticleCategoryAjaxStatus !== "pending") {
      let payload: duplicateArticleParams = {
        integrationId: pageIntegrationId,
        articleId: articleId,
        articleName: articleName,
      };
      // Api call to duplicate article
      dispatch(fetchDuplicateArticle(payload)).then(() => {
        setSearchTerm("");
      });
    }
  };

  // Function to handle search
  const handleSearch = useCallback(() => {
    dispatch(resetArticleCategoriesData());
    dispatch(setCategorySearchValue({ searchValue: searchTerm }));
    dispatch(
      fetchAllArticleCategories({
        integrationId: pageIntegrationId,
        start: 0,
        limit: fetchLimit,
        searchTerm: searchTerm,
        hasArticleOnly: false,
      })
    );
  }, [pageIntegrationId, searchTerm]);

  // Dispatching action to fetch all article categories
  useEffect(() => {
    dispatch(
      fetchAllArticleCategories({
        integrationId: pageIntegrationId,
        start: 0,
        limit: fetchLimit,
        hasArticleOnly: false,
      })
    );
  }, [pageIntegrationId]);

  // Function to handle new category
  const handleNewCategory = () => {
    isCategoryNameUpdate.current = false;
    setNewCategory("");
    setActiveCategoryId(0);
    setShowModal(true);
    setShowCategoryLoader(false);
  };

  // Function to handle submit
  const handleSubmit = (isUpdate: boolean, categoryId: string | number) => {
    if (categoryData !== null && fetchArticleCategoryAjaxStatus !== "pending") {
      setShowCategoryLoader(true);
      if (isUpdate === true) {
        if (newCategoryName === "") {
          setShowValidation(true);
          setShowCategoryLoader(false);
          return;
        }
        let payload: updateArticleCategoryParams = {
          integrationId: pageIntegrationId,
          categoryId: activeCategoryId,
          categoryName: newCategoryName,
        };

        // Api call to update article category
        updateArticleCategory(payload)
          .then((res) => {
            pushTheToast({
              type: "success",
              text: "successfully updated!",
              position: "top-right",
            });
            dispatch(
              updateCategoryName({
                categoryId: categoryId as string,
                categoryName: newCategoryName,
              })
            );
            setSearchTerm("");
            onHide();
            setShowCategoryLoader(false);
          })
          .catch((err) => {
            pushTheToast({
              type: "danger",
              text: "Something went wrong!",
              position: "top-right",
            });
            setShowCategoryLoader(false);
          });
      } else {
        let payload: addArticleCategoryParams = {
          integrationId: pageIntegrationId,
          categoryName: newCategoryName,
        };

        if (newCategoryName === "") {
          setShowValidation(true);
          setShowCategoryLoader(false);
          return;
        }

        // Api call to add article category
        addArticleCategory(payload)
          .then((res) => {
            pushTheToast({
              type: "success",
              text: `New Category "${res.categoryName}" Added!`,
              position: "top-right",
            });
            setSearchTerm("");
            dispatch(resetArticleCategoriesData());
            dispatch(
              fetchAllArticleCategories({
                integrationId: pageIntegrationId,
                start: 0,
                limit: fetchLimit,
                hasArticleOnly: false,
              })
            );
            onHide();
            setShowCategoryLoader(false);
          })
          .catch((err) => {
            pushTheToast({
              type: "danger",
              text: "Something went wrong !!!",
              position: "top-right",
            });
            setShowCategoryLoader(false);
          });
      }
    }
  };

  // Function to handle add new article
  const handleAddNewArticle = (categoryId: any, isWithoutCategory: boolean) => {
    navigate(`/knowledge-base/${pageIntegrationId}/articles/new`);
  };

  // Function to hide article modal
  const hideArticleModal = useCallback(() => {
    setShowArticleDeleteModal(false);
    setShowCategoryDeleteModal(false);
  }, []);

  // Function to handle category delete
  const handleCategoryDelete = (categoryId: number | string) => {
    if (categoryData !== null && fetchArticleCategoryAjaxStatus !== "pending") {
      let payload: deleteArticleCategoryParams = {
        integrationId: pageIntegrationId,
        categoryId: categoryId,
      };

      setShowDeleteLoader(true);
      // Api call to delete article category
      deleteArticleCategory(payload)
        .then((res) => {
          pushTheToast({
            type: "success",
            text: `Category Deleted Successfully!`,
            position: "top-right",
          });
          setSearchTerm("");
          dispatch(resetArticleCategoriesData());
          dispatch(
            fetchAllArticleCategories({
              integrationId: pageIntegrationId,
              start: 0,
              limit: fetchLimit,
              hasArticleOnly: false,
            })
          );
          hideArticleModal();
          setShowDeleteLoader(false);
        })
        .catch((err) => {
          pushTheToast({
            type: "danger",
            text: "Something went wrong!",
            position: "top-right",
          });
          setShowDeleteLoader(false);
        });
    }
  };

  // Function to handle article delete
  const handleArticleDelete = (articleId: number | string) => {
    if (categoryData !== null && fetchArticleCategoryAjaxStatus !== "pending") {
      let payload: deleteArticleParams = {
        integrationId: pageIntegrationId,
        articleId: articleId,
      };
      setShowDeleteLoader(true);
      // Api call to delete article
      deleteArticle(payload)
        .then((res) => {
          // If id is not null then dispatch action to fetch all articles
          if (deletedArticleCategoryId) {
            dispatch(
              fetchAllArticles({
                integrationId: pageIntegrationId,
                categoryId: deletedArticleCategoryId,
                start: 0,
                limit: fetchLimit,
              })
            );
            dispatch(
              deleteArticleFromList({
                articleId: articleId,
                categoryId: deletedArticleCategoryId as string,
              })
            );
          }
          pushTheToast({
            type: "success",
            text: `Deleted Article`,
            position: "top-right",
          });
          setSearchTerm("");
          hideArticleModal();
          setShowDeleteLoader(false);
        })
        .catch((err) => {
          console.log(err, "error");
          pushTheToast({
            type: "danger",
            text: "Something went wrong!",
            position: "top-right",
          });
          setShowDeleteLoader(false);
        });
    }
  };

  //  Function to handle add feature
  const handleAddFeature = () => {
    navigate(`/knowledge-base/${pageIntegrationId}/settings/featuredArticles`);
  };

  // Ref to check if category name is updated
  const isCategoryNameUpdate = useRef<boolean>(false);

  // Function to handle infinite scroll
  const handleInfiniteScroll = useCallback(() => {
    dispatch(
      fetchAllArticleCategories({
        integrationId: integrationId as string,
        start: currentState.current.categoryIdList.length,
        limit: fetchLimit,
        hasArticleOnly: false,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrationId]);

  // Constant to check if there are more categories
  const hasMoreCategory = useMemo(() => {
    return currentState.current.fetchArticleCategoryAjaxStatus === "rejected"
      ? false
      : currentState.current.metaData.total === null
      ? true
      : metaData.count === 0
      ? false
      : categoryIdList.length < currentState.current.metaData.total;
  }, [currentState, metaData]);

  // Function to hide modal
  const onHide = useCallback(() => {
    setShowModal(false);
  }, []);

  // Constant for Loader
  const categoryLoader = useCategoryLoader(
    fetchArticleCategoryAjaxStatus === "pending" && categoryIdList.length === 0,
    styles.loading
  );

  // Constant for Error
  const categoryError = useCategoryError(
    categoryIdList.length === 0 &&
      fetchArticleCategoryAjaxStatus === "fulfilled"
  );

  const changeOrder = useMutation({
    mutationFn: updatePriorities,
  });

  const queryClient = new QueryClient();

  const handleOnDragEnd = useCallback(
    (result: any) => {
      const { destination, source } = result;
      if (!destination) {
        return;
      }
      if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
      ) {
        return;
      }
      const newOrder = Array.from(categoryIdList);
      newOrder.splice(source.index, 1);
      newOrder.splice(destination.index, 0, categoryIdList[source.index]);
      changeOrder.mutate({
        integrationId: pageIntegrationId,
        updateType: "category",
        ids: newOrder,
      });
      dispatch(
        setNewOrderOfCategories({
          categoryIdList: newOrder,
        })
      );
    },
    [categoryIdList]
  );

  return {
    handleAddFeature,
    setShow,
    show,
    handleNewCategory,
    showModal,
    onHide,
    activeCategoryId,
    isCategoryNameUpdate,
    newCategoryName,
    setNewCategory,
    handleSubmit,
    showCategoryLoader,
    handleAddNewArticle,
    showCategoryDeleteModal,
    hideArticleModal,
    selectedArticleId,
    handleCategoryDelete,
    showDeleteLoader,
    searchTerm,
    setSearchTerm,
    handleSearch,
    handleInfiniteScroll,
    fetchArticleCategoryAjaxStatus,
    categoryIdList,
    metaData,
    categoryData,
    handleUpdateCategory,
    handleArticleDeleteModal,
    handleCategoryDeleteModal,
    handleArticleDuplicate,
    showArticleDeleteModal,
    handleArticleDelete,
    hasMoreCategory,
    categoryLoader,
    categoryError,
    handleOnDragEnd,
    showValdation,
    setShowValidation,
  };
}

export default useCreateArticle;
