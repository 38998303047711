import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  TicketsQuery,
  getTicketsV2,
} from "src/services/TicketService/getTicketsV2";
import { RootState } from "src/store/store";

const fetchAllSearchTicketsThunk = async (
  payload: undefined,
  { getState }: { getState: Function }
) => {
  try {
    const currentState: RootState = getState();
    const queryData: TicketsQuery = {
      columns: [
        "ticket_subject",
        // "ticket_excerpt",
        "ticket_channel",
        "ticket_customer_name",
        // "ticket_tags",
        // "not_replied_since",
        "last_message",
        // "last_message_received",
        // "ticket_draft_status",
        // "ticket_drafted_by",
        "ticket_id",
        // "ticket_assigned_to",
        "last_message_date",
        // "ticket_created_date",
        // "ticket_modified_date",
        // "ticket_status",
        // "number_of_tickets",
        // "ticket_assignee_team",
        // "ticket_integration",
        // "customer_id",
        "ticket_to",
        "ticket_brand"
      ],
      filters: {
        tableType:
          currentState.ticketGlobalSearch.tableType === "my"
            ? "my"
            : currentState.ticketGlobalSearch.tableType === "mentions"
            ? "mentions"
            : "all",
        searchOptions: {
          value: currentState.ticketGlobalSearch.searchText,
          keys: ["*"],
          isOuterTicket: true,
        },
      },
      start: currentState.ticketGlobalSearch.ticketList.length,
      limit: currentState.ticketGlobalSearch.ticketDataLimit,
    };
    const { tickets, ticketMeta } = await getTicketsV2(
      queryData,
      true,
      "ticket-view"
    );
    return {
      ticketData: tickets,
      ticketList: ticketMeta.ticketIds.map((data) => parseInt(data)),
      ticketListTotal: ticketMeta.totalCount,
    };
  } catch (error) {
    throw error;
  }
};

export default { fetchAllSearchTicketsThunk };

export const fetchAllSearchTickets = createAsyncThunk(
  "ticketGlobalSearch/fetchAllSearchTickets",
  fetchAllSearchTicketsThunk
);
