import { IDispatchRuleConfig } from "src/services/Automation/DispatchRulesConfigModal/getAllDispatchRuleConfig.service";
import styles from "./DispatchTable.module.scss";
import { useMemo } from "react";

const DispatchTable = ({
  columns,
  showActionBtns,
}: {
  columns: IDispatchRuleConfig["columns"];
  showActionBtns: boolean;
}) => {
  const allColumns = useMemo(() => {
    if (!columns) {
      return [];
    }

    const cols = [...columns];
    if (showActionBtns) {
      cols.push({
        heading: "Action",
        key: "action_btns",
      });
    }

    return cols;
  }, [columns, showActionBtns]);

  const widthPercentage = useMemo(() => {
    if (columns.length <= 6) {
      const length = showActionBtns ? columns.length + 1 : columns.length;
      return 100 / length;
    }

    return;
  }, [columns.length, showActionBtns]);

  return (
    <div className={`d-flex align-items-center mb-2 ${styles.tableHeader}`}>
      {allColumns.map(({ heading, key }, idx) => {
        return (
          <div
            className={`${styles.eleWrapper} ${idx !== 0 ? "text-center" : ""}`}
            style={{
              width: widthPercentage ? `${widthPercentage}%` : undefined,
            }}
            key={key}
          >
            <span className={`${styles.element}`}>{heading}</span>
          </div>
        );
      })}
    </div>
  );
};

export default DispatchTable;
