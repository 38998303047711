import SearchBar from "src/components/SearchBar/SearchBar";
import styles from "./ApproveFeedback.module.scss";
import FeedbackTable from "./Children/FeedbackTable/FeedbackTable";
import { GetAllArticleFeedback } from "src/services/KnowledgeBase/Settings/Feedback/getAllArticleFeedback.service";
import Loader from "src/components/Loader";
import InfiniteScroll from "src/components/InfiniteScrollBothSide";
import useApproveFeedback from "./useApproveFeedback";
import { useNavigate } from "react-router-dom";
const ApproveFeedback = () => {
  const navigate = useNavigate();
  const {
    feedbacks,
    isLoading,
    hasNextPage,
    fetchNextPage,
    searchTerm,
    setSearchTerm,
    onSearch,
    isRefetching,
  } = useApproveFeedback();
  return (
    <div className={`${styles.approveFeedbackCont} pt-4 px-3`}>
      {/* Heading And Sub Heading  */}
      <div className="mb-4 d-flex">
        <div>
          <div
            className={`d-flex justify-content-center align-items-center ${styles.backChat}`}
            onClick={() => navigate(-1)}
          >
            <i className="fa-solid fa-arrow-left"></i>
          </div>
        </div>
        <div>
          <h3 className={`mb-0 ${styles.heading} saufter_h3`}>
            Feedbacks / Approve/Reject Feedback
          </h3>
          <p className={`${styles.subHeading} saufter_subHeading`}>
            Let customers style the help center right inside your chat widget
            instead of starting a conversation.
          </p>
        </div>
      </div>

      <div className={`p-3 ${styles.feedbackTable}`}>
        {/* Search Bar */}
        <SearchBar
          className={`${styles.search} px-2`}
          inputClassName={`${styles.input}`}
          placeholder={`Search by Customer name or article title`}
          onChange={(e: { target: { value: any } }) => {
            setSearchTerm(e.target.value);
          }}
          value={searchTerm}
          onSearch={onSearch}
        />

        {/* Feedback Table */}
        <div className={`mt-3 ${styles.tableMain}`}>
          <div className={`${styles.tableWrapper}`}>
            {/* Table Header */}
            <div className={`mb-3 ${styles.tableHeader}`}>
              <div className={`${styles.commentatorWrapper}`}>
                <span className={`${styles.headerName}`}>Commentator</span>
              </div>
              <div className={`${styles.articleWrapper}`}>
                <span className={`${styles.headerName}`}>Article</span>
              </div>

              <div className={`${styles.ratingWrapper}`}>
                <span className={`${styles.headerName}`}>Rating provided</span>
              </div>
              <div className={`${styles.statusWrapper}`}>
                <span className={`${styles.headerName}`}>Rating status</span>
              </div>
              <div className={`${styles.commentWrapper} pe-2`}>
                <span className={`${styles.headerName}`}>Comment</span>
              </div>
              <div className={`${styles.actionWrapper}`}>
                <span className={`${styles.headerName}`}>Comment Action</span>
              </div>
            </div>

            {/* Table Body */}
            <div>
              {isLoading || isRefetching ? (
                <div>
                  <Loader />
                </div>
              ) : (
                // Infinite Scroll Component to load more data on scroll
                <div className={`${styles.infiW}`}>
                  <InfiniteScroll
                    loadMoreFromBottom={fetchNextPage}
                    hasMoreBottom={hasNextPage}
                    className={`h-100 overflow-auto`}
                  >
                    {feedbacks?.length === 0 ? (
                      <div className="text-center mt-5">
                        <h4>No feedbacks found</h4>
                      </div>
                    ) : (
                      feedbacks?.map((item: GetAllArticleFeedback) => {
                        return (
                          <div>
                            <FeedbackTable feedbackData={item} />
                          </div>
                        );
                      })
                    )}
                  </InfiniteScroll>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApproveFeedback;
