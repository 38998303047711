import React, { useCallback, useMemo, useRef, useState } from "react";
import styles from "./ChannelFilterSection.module.scss";
import { Dropdown } from "react-bootstrap";
import { useQuery } from "@tanstack/react-query";
import {
  useReportFilters,
  reportCurrentStatusActions,
} from "src/routes/Report/hooks/reportFilters/useReportFilters";
import { getChannelList } from "src/services/InnerTicket/getChannelList";
import { v4 as uuid } from "uuid";

const useChannelFilter = () => {
  const { data: allChannels } = useQuery(["useChannelFilter/getChannelList"], {
    queryFn: async () => {
      return getChannelList({ context: "reports" });
    },
    staleTime: 600000,
    cacheTime: 600000,
  });
  const allChannelId = useMemo(() => {
    return Object.keys(allChannels ?? {});
  }, [allChannels]);

  const { currentStatus, dispatch } = useReportFilters();

  const selectedChannels = useMemo(() => {
    return currentStatus.selectedChannels;
  }, [currentStatus]);

  const selectAllChannels = useCallback(
    (e: any) => {
      if (e.target.checked) {
        dispatch([
          reportCurrentStatusActions.setFilters,
          {
            selectedChannels: [...allChannelId],
          },
        ]);
      } else {
        dispatch([
          reportCurrentStatusActions.setFilters,
          { selectedChannels: [] },
        ]);
      }
    },
    [allChannelId]
  );
  const clearAllChannels = useCallback(() => {
    dispatch([reportCurrentStatusActions.setFilters, { selectedChannels: [] }]);
  }, []);
  const checkUncheckChannels = useCallback(
    (e: any) => {
      if (e.target.checked) {
        dispatch([
          reportCurrentStatusActions.setFilters,
          { selectedChannels: [...selectedChannels, e.target.value] },
        ]);
      } else {
        dispatch([
          reportCurrentStatusActions.setFilters,
          {
            selectedChannels: selectedChannels.filter(
              (id) => id !== e.target.value
            ),
          },
        ]);
      }
    },
    [selectedChannels]
  );
  return {
    allChannels,
    allChannelId,
    selectedChannels,
    selectAllChannels,
    clearAllChannels,
    checkUncheckChannels,
  };
};
interface Props {
  isSegement?: boolean;
  disabled?: boolean;
}
const ChannelFilterSection = ({ isSegement, disabled }: Props) => {
  const {
    allChannels,
    allChannelId,
    selectedChannels,
    selectAllChannels,
    clearAllChannels,
    checkUncheckChannels,
  } = useChannelFilter();
  const [ddOpen, setDdOpen] = useState(false);
  const dropdownArrowSpanRef = useRef<any>();
  const uuidRef = useRef(uuid());

  return (
    <div>
      <Dropdown onToggle={setDdOpen}>
        <Dropdown.Toggle
          as="div"
          bsPrefix={`dropdown-toggle cursor-pointer border-0 px-2 py-1 me-2  mb-2 mb-lg-0 ${
            isSegement === true ? styles.segementBtn : styles.filterButton
          } ${disabled && styles.disable}`}
        >
          All Channels
          {selectedChannels.length ? (
            <span className={`mx-1 ${styles.filterCount}`}>
              {/* added condition to show 0 before count if count is less than 10 */}
              {selectedChannels.length < 10
                ? `0${selectedChannels.length}`
                : selectedChannels.length}
            </span>
          ) : (
            ""
          )}
          <span className="ps-1" ref={dropdownArrowSpanRef}>
            {ddOpen ? (
              <i className={`fa-solid fa-caret-up`}></i>
            ) : (
              <i className={`fa-solid fa-caret-down`}></i>
            )}
          </span>
        </Dropdown.Toggle>

        <Dropdown.Menu bsPrefix={`dropdown-menu border-0 ${styles.filterMenu}`}>
          <span className={`${styles.dropItem}`}>
            <div
              className={`${styles.itemWrapper} ${
                selectedChannels.length === allChannelId.length &&
                styles.selectedText
              }`}
            >
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={"all"}
                  id={uuidRef.current + "report-filter-channel-all-select"}
                  checked={selectedChannels.length === allChannelId.length}
                  onChange={selectAllChannels}
                />
                <label
                  className={`form-check-label ${styles.checktext}`}
                  htmlFor={uuidRef.current + "report-filter-channel-all-select"}
                >
                  Select All
                </label>
              </div>
            </div>
          </span>
          {allChannelId.map((id) => {
            return (
              <span key={id} className={`${styles.dropItem}`}>
                <div
                  className={`${styles.itemWrapper} ${
                    selectedChannels.includes(id) && styles.selectedOne
                  }`}
                >
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={id}
                      id={uuidRef.current + "report-filter-channel" + id}
                      checked={selectedChannels.includes(id)}
                      onChange={checkUncheckChannels}
                    />
                    <label
                      className={`form-check-label ${styles.checktext}`}
                      htmlFor={uuidRef.current + "report-filter-channel" + id}
                    >
                      {allChannels ? allChannels[id].name : ""}
                    </label>
                  </div>
                </div>
              </span>
            );
          })}
          <span className={`${styles.clearSpan}`} onClick={clearAllChannels}>
            Clear all
          </span>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default ChannelFilterSection;
