import {
  createAsyncThunk,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { AJAXSTATUS } from "src/globals/constants";
import { UserLanguageList } from "src/services/LiveChat/Settings/getAllLanguages";
import customizationSettingsExtraReducers from "./customizationSettings.extraReducers";
import customizationSettingsReducers from "./customizationSettings.reducers";
import customizationSettingsThunks from "./customizationSettings.thunks";

export const fetchCustomizationData = createAsyncThunk(
  "customizationSetting/fetchCustomizationData",
  customizationSettingsThunks.fetchCustomizationDataThunk
);

export interface IAppearanceData{
  appearanceWindowColor : {color: string , value:string};
  liveChatThemeColor: {color: string , value:string};
  widgetPosition: string;
}

interface PartialInput{
  readonly : string;
  value: string;
}

export interface ICustomizationData {
  appearances : IAppearanceData;
  languageName : string;
  languageId : string | number;
  topbarHeading : string;
  topbarSubHeading : string;
  statusTextWhenTeamOnline : PartialInput;
  statusTextWhenTeamOffline : PartialInput;
  autoreplayText : string;
}

export interface ICustomizationSetting {
  customizationData : ICustomizationData,
  languagesList : UserLanguageList,
  customizationDataAjaxStatus: AJAXSTATUS,

}

export const initialState : ICustomizationSetting = {
  customizationData: {
    appearances : {
      appearanceWindowColor:{color: "", value: ""} ,
      liveChatThemeColor: {color: "", value: ""},
      widgetPosition:"" ,
    },
    languageName : "",
    languageId : 0,
    topbarHeading : "",
    topbarSubHeading : "",
    statusTextWhenTeamOnline : {
      readonly: "",
      value:""
    },
    statusTextWhenTeamOffline : {
      readonly: "",
      value:""
    },
    autoreplayText : "",
  },
  languagesList: {
    languages: {},
    languageIds: [],
  },
  customizationDataAjaxStatus: "pending",
}

export const customizationSettingSlice = createSlice({
  name: "customizationSetting",
  initialState,
  reducers: customizationSettingsReducers,
  extraReducers: (builder) => {
    builder.addCase(
      fetchCustomizationData.fulfilled,
      customizationSettingsExtraReducers.fetchCustomizationDataFulfilled
    );
    builder.addCase(fetchCustomizationData.rejected, (state) => {
      state.customizationDataAjaxStatus = "rejected";
      pushTheToast({
        position: "top-right",
        type: "danger",
        text: "Something went wrong!"
      });
    });
    builder.addCase(fetchCustomizationData.pending, (state) => {
      state.customizationDataAjaxStatus = "pending";
    });
  }
});

export const { resetCustomizationSettings, setCustomizationData } = customizationSettingSlice.actions;

export default customizationSettingSlice.reducer;
