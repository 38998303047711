import { axiosJSON } from "src/globals/axiosEndPoints";


export interface updateArticleCategoryParams{
  categoryId: string|number;
  categoryName : string;
  integrationId : number|string;
}

export async function updateArticleCategory(params: updateArticleCategoryParams) {
  const { data } = await axiosJSON.post(
    `/api/chatSetting/article/updateCategory`,
    params
  );
  if (data.error === true) {
    throw new Error(data.message as string);
  }
  return data.data;
}