import { axiosJSON } from "src/globals/axiosEndPoints";

export type KeyExpiresAt =
  | "till-customer-replies"
  | "1-hour"
  | "3-hours"
  | "6-hours"
  | "1-day"
  | "3-days"
  | "7-days"
  | "custom-datetime";

export interface ICreateSnooze {
  ticketId: number;
  expireAt: KeyExpiresAt;
  expireAtValue?: string; //only need when expireAt set to 'custom-datetime'
  initialStatus?: number;
}

const statusCodeMsg:any = {
  "snoozeAlreadyExists": "Snooze Already Exists",
  "snoozeNotFound": "Snooze Not Found",
}
export async function createSnooze(params: ICreateSnooze) {
  const { data: res } = await axiosJSON.post(
    `/api/ticket/snooze/create`,
    params
  );
  let defaultErrorMsg = "Something Went Wrong";
  if (res.error === true) {
    if(res?.statusCode){
      if(statusCodeMsg[res.statusCode] != undefined){
        defaultErrorMsg = statusCodeMsg[res.statusCode];
      }
      throw new Error(defaultErrorMsg);
    }
  }
  return res.data as { id: number };
}
