/* eslint-disable jsx-a11y/anchor-is-valid */
import { Dropdown, Modal } from "react-bootstrap";
import SearchBar from "src/components/SearchBar/SearchBar";
import DeleteArticle from "src/routes/LiveChat/Children/ChatSetting/Children/KnowledgeBased/Children/CreateArticle/children/DeleteArticle/DeleteArticle";
import NewCategory from "src/routes/LiveChat/Children/ChatSetting/Children/KnowledgeBased/Children/KnowledgeBasedArticles/Children/NewCategory/NewCategory";
import ArticleFolder from "./Children/ArticleFolder/ArticleFolder";
import styles from "./CreateArticle.module.scss";
import useCreateArticle from "./useCreateArticle";
import InfiniteScroll from "src/components/InfiniteScrollBothSide";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import arrowImg from "src/assets/images/whitearrow.png";
import edit from "src/assets/images/edit.svg";
import folder from "src/assets/images/folder.svg";
import file from "src/assets/images/file-text.svg";

type Props = {
  setShowSubFolder: Function;
  showSubFolder: boolean;
};

const CreateArticleUpdated = ({ setShowSubFolder, showSubFolder }: Props) => {
  const navigate = useNavigate();
  const {
    handleAddFeature,
    setShow,
    show,
    activeCategoryId,
    handleNewCategory,
    handleSubmit,
    isCategoryNameUpdate,
    newCategoryName,
    onHide,
    setNewCategory,
    showCategoryLoader,
    showModal,
    handleAddNewArticle,
    hideArticleModal,
    showCategoryDeleteModal,
    handleCategoryDelete,
    searchTerm,
    selectedArticleId,
    setSearchTerm,
    showDeleteLoader,
    categoryIdList,
    handleInfiniteScroll,
    handleSearch,
    categoryData,
    handleArticleDeleteModal,
    handleArticleDuplicate,
    handleCategoryDeleteModal,
    handleUpdateCategory,
    showArticleDeleteModal,
    handleArticleDelete,
    hasMoreCategory,
    categoryLoader,
    categoryError,
    handleOnDragEnd,
    showValdation,
    setShowValidation,
  } = useCreateArticle();
  const { integrationId } = useParams();
  const pageIntegrationId: string = integrationId
    ? decodeURIComponent(integrationId)
    : "";
  const [ddOpen, setDdOpen] = useState(false);
  const dropdownArrowSpanRef = useRef<any>();

  return (
    <div className={`p-1 p-md-3 w-100 ${styles.createArticleWrapper}`}>
      {/* Header and Buttons */}
      <div
        className={`d-flex justify-content-between align-items-center flex-wrap mb-2`}
      >
        {/* Header  */}
        <div className={`d-flex flex-wrap`}>
          <div
            className={`mt-2 me-2 d-flex justify-content-center align-items-center ${styles.backChat}`}
            onClick={() => navigate(-1)}
          >
            <i className="fa-solid fa-arrow-left fa-sm"></i>
          </div>
          <div>
            <p className={`mb-0 mt-1 saufter_h3 ${styles.heading}`}>
              Create new articles & categories
            </p>
            <span className={`saufter_subHeading ${styles.subheading}`}>
              {/* 19 Jan Changing text as per a bug ticket */}
              You can add articles and classify them in categories here. 
            </span>
          </div>
        </div>
        {/* Buttons */}
        <div className="d-flex align-items-center flex-wrap">
          {/* Add Featured Articles Button */}

          <button
            className={`d-flex align-items-center justify-content-center me-1 ${styles.featuredArticle}`}
            onClick={(e) => {
              handleAddFeature();
            }}
          >
            Add Featured Articles
          </button>

          {/* Add New Button */}
          <Dropdown onToggle={setDdOpen}>
            <Dropdown.Toggle
              as="div"
              id="dropdown-basic"
              bsPrefix={`dropdown-toggle cursor-pointer my-1 my-md-0 d-flex justify-content-center align-items-center ${styles.addNewBtn}`}
            >
              <span
                className={`d-flex align-items-center`}
                onClick={() => setShow(!show)}
              >
                <span> Add new</span>
                <span className={`px-1 ${styles.arrowDown}`}>
                  {ddOpen ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="7.648"
                      height="4.939"
                      viewBox="0 0 7.648 4.939"
                      className={`${styles.newArrowDown}`}
                    >
                      <path
                        id="Icon_awesome-angle-down"
                        data-name="Icon awesome-angle-down"
                        d="M3.416,15.511.165,12.26a.571.571,0,0,1,0-.81l.54-.54a.571.571,0,0,1,.81,0l2.3,2.3,2.3-2.3a.571.571,0,0,1,.81,0l.54.54a.571.571,0,0,1,0,.81L4.225,15.511a.569.569,0,0,1-.808,0Z"
                        transform="translate(0.004 -10.74)"
                        fill="#fff"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="7.648"
                      height="4.939"
                      viewBox="0 0 7.648 4.939"
                    >
                      <path
                        id="Icon_awesome-angle-down"
                        data-name="Icon awesome-angle-down"
                        d="M3.416,15.511.165,12.26a.571.571,0,0,1,0-.81l.54-.54a.571.571,0,0,1,.81,0l2.3,2.3,2.3-2.3a.571.571,0,0,1,.81,0l.54.54a.571.571,0,0,1,0,.81L4.225,15.511a.569.569,0,0,1-.808,0Z"
                        transform="translate(0.004 -10.74)"
                        fill="#fff"
                      />
                    </svg>
                  )}
                </span>
              </span>
            </Dropdown.Toggle>

            {/* Dropdown Items */}
            <Dropdown.Menu bsPrefix={`dropdown-menu ${styles.addNewBox}`}>
              <li>
                <span
                  className={`${styles.innerDropDown}`}
                  onClick={handleNewCategory}
                >
                  <span className="me-2">
                    <img
                      src={folder}
                      alt="folder"
                      className={`${styles.icon}`}
                    />
                  </span>
                  New Category
                </span>
              </li>
              <li>
                <span
                  className={`${styles.innerDropDown}`}
                  onClick={() => handleAddNewArticle(null, true)}
                >
                  <span className="me-2">
                    <img src={file} alt="edit" className={`${styles.icon}`} />
                  </span>
                  New Article
                </span>
              </li>
              {/* <li>
                <span className={`${styles.innerDropDown}`}>
                  <span className="me-2">
                    <img src={edit} alt="edit" className={`${styles.icon}`} />
                  </span>
                  New template
                </span>
              </li> */}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      {/* Search Bar & Categories Foleders */}
      <div className={`my-2`}>
        <SearchBar
          className={`${styles.search} px-2`}
          inputClassName={`${styles.input}`}
          placeholder={`Search Articles`}
          value={searchTerm}
          onChange={(e: any) => setSearchTerm(e.target.value)}
          onSearch={handleSearch}
        />
      </div>
      <div>
        <InfiniteScroll
          loadMoreFromBottom={handleInfiniteScroll}
          hasMoreBottom={hasMoreCategory}
          className={` overflow-auto pb-3 pt-4 w-100`}
          style={{ borderRadius: "13px", height: "calc(100vh - 234px)" }}
        >
          <DragDropContext onDragEnd={(result) => handleOnDragEnd(result)}>
            <Droppable droppableId="parentList">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {categoryLoader
                    ? categoryLoader
                    : categoryError
                    ? categoryError
                    : categoryIdList.map((categoryId: any, index) => {
                        const category = categoryData[categoryId];
                        return (
                          <Draggable
                            key={categoryId}
                            draggableId={
                              categoryData[categoryId].categoryName + categoryId
                            }
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                <ArticleFolder
                                  key={categoryId}
                                  category={category}
                                  handleUpdateCategory={handleUpdateCategory}
                                  handleArticleDelete={handleArticleDeleteModal}
                                  handleCategoryDelete={
                                    handleCategoryDeleteModal
                                  }
                                  handleArticleDuplicate={
                                    handleArticleDuplicate
                                  }
                                  setShowSubFolder={setShowSubFolder}
                                  provided={provided}
                                />
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </InfiniteScroll>
      </div>
      {/* Modals Used in this Component */}

      {/* Add New Category Modal */}
      <Modal
        backdropClassName={`${styles.modalBack}`}
        show={showModal}
        onHide={onHide}
        dialogClassName={`${styles.modalDialog}`}
        contentClassName={`${styles.modalContent}`}
        enforceFocus={false}
      >
        <NewCategory
          categoryId={activeCategoryId}
          isUpdate={isCategoryNameUpdate.current}
          categoryName={newCategoryName}
          setCategoryName={setNewCategory}
          handleSubmit={handleSubmit}
          onHide={onHide}
          showLoader={showCategoryLoader}
          showValidation={showValdation}
          setShowValidation={setShowValidation}
        />
      </Modal>

      {/* Delete Article Modal */}
      {showArticleDeleteModal === true ? (
        <Modal
          backdropClassName={`${styles.modalBack}`}
          show={showArticleDeleteModal}
          onHide={hideArticleModal}
          dialogClassName={`${styles.modalDialog}`}
          contentClassName={`${styles.modalContent}`}
          enforceFocus={false}
        >
          <DeleteArticle
            HeadingText="Are you sure you want to remove this article?"
            articleId={selectedArticleId}
            sendArticleId={true}
            handleConfirm={handleArticleDelete}
            onHide={hideArticleModal}
            cancelBtnText="No"
            submitBtnText="Yes"
            showDeleteLoader={showDeleteLoader}
          />
        </Modal>
      ) : null}

      {/* Modal For Cateogry Delete */}
      {showCategoryDeleteModal === true ? (
        <Modal
          backdropClassName={`${styles.modalBack}`}
          show={showCategoryDeleteModal}
          onHide={hideArticleModal}
          dialogClassName={`${styles.modalDialog}`}
          contentClassName={`${styles.modalContent}`}
          // centered={true}
          enforceFocus={false}
        >
          <DeleteArticle
            HeadingText="Are you sure you want to remove this Categroy?"
            articleId={selectedArticleId}
            sendArticleId={true}
            handleConfirm={handleCategoryDelete}
            onHide={hideArticleModal}
            cancelBtnText="No"
            submitBtnText="Yes"
            showDeleteLoader={showDeleteLoader}
          />
        </Modal>
      ) : null}
    </div>
  );
};

export default CreateArticleUpdated;
