import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "src/components/Loader";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import {
  IUpdateChatSettingParams,
  updateChatSetting,
} from "src/services/LiveChat/Settings/updateChatSettingData";
import { fetchChatSettingData } from "src/store/slices/liveChatSetting/chatWidgetSetting/chatSetting/chatSetting.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import styles from "./ChatAssignment.module.scss";

const ChatAssignment = () => {
  const [status, setStatus] = useState<"auto" | "manual">("auto");
  const dispatch = useAppDispatch();

  const { integrationId } = useParams();
  const pageIntegrationId = integrationId
    ? decodeURIComponent(integrationId)
    : "";

  useEffect(() => {
    if (chatSettingData !== null && chatSettingData.chatAssignment !== status) {
      setShowSaveChange(true);
    } else {
      setShowSaveChange(false);
    }
  }, [status]);

  const { chatSettingData, chatSettingDataAjaxStatus } = useAppSelector(
    (state) => state.liveChatSetting
  );
  const [showSaveChange, setShowSaveChange] = useState(false);

  useEffect(() => {
    dispatch(fetchChatSettingData(pageIntegrationId));
  }, [false]);

  useEffect(() => {
    if (chatSettingData !== null) {
      setStatus(chatSettingData.chatAssignment);
    }
  }, [chatSettingData]);

  const handleSave = () => {
    if (chatSettingData !== null && chatSettingDataAjaxStatus !== "pending") {
      let payload: IUpdateChatSettingParams = {
        chatAssignment: status,
      };

      updateChatSetting({
        integrationId: pageIntegrationId,
        chatSetting: payload,
      })
        .then((res) => {
          pushTheToast({
            type: "success",
            text: "Successfully changed",
            position: "top-right",
          });
          dispatch(fetchChatSettingData(pageIntegrationId));
          setShowSaveChange(false);
        })
        .catch((err) => {
          pushTheToast({
            type: "danger",
            text: "Something went wrong!",
            position: "top-right",
          });
        });
    }
  };

  const handleCancel = () => {
    if (chatSettingData !== null) {
      setStatus(chatSettingData.chatAssignment);
    }
    setShowSaveChange(false);
  };

  return (
        <div className={`${styles.chatAssignment}`}>
          <h2 className={`${styles.chatHead}`}>Chat Assignment</h2>
          <p className={`${styles.chatSub}`}>
            Choose how chats are distributed
          </p>
          {chatSettingDataAjaxStatus === "pending" ? (
            <Loader />
          ) : (
            chatSettingData !== null && (
              <div>
                <div
                  className={`mb-3 ${styles.choseBox} ${styles.activeBox} ${
                    status === "auto" ? styles.activeDiv : ""
                  } `}
                >
                  <div className={`d-flex align-items-center mb-2`}>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        checked={status === "auto"}
                        onClick={(e) => setStatus("auto")}
                      />
                    </div>
                    <h5 className={`${styles.always}`}>Auto assignment</h5>
                  </div>
                  <div>
                    <p className={`ms-1 ms-md-2 mb-0 ${styles.choseBoxPara}`}>
                      Chats are evenly distributed among agents with accepting
                      chats status. When all agents hit their limit, new
                      visitors are queued.
                    </p>
                  </div>
                </div>
                <div
                  className={`${styles.choseBox} mb-3 ${
                    status === "manual" ? styles.activeDiv : ""
                  }
                  ${styles.disabledSelectOption}`}
                >
                  <div className={`d-flex align-items-center mb-2`}>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        checked={status === "manual"}
                        onClick={(e) => setStatus("manual")}
                        disabled={true} //01 Dec 22 //disabling the chat assignment manual selection toggle button as it is not implemented right now
                      />
                    </div>
                    <h5 className={`ms-1 ms-md-2 mb-0 ${styles.always}`}>
                      Manual Selection
                    </h5>
                  </div>
                  <div>
                    <p className={`${styles.choseBoxPara}`}>
                      All agents get notified about a customer waiting in the
                      queue. Chat will be assigned to the first agent who picks
                      it up.
                    </p>
                  </div>
                </div>
                {showSaveChange === true ? (
                  <div className={`${styles.actionBtn}`}>
                    <button
                      className={`me-2 bg-white ${styles.cancelBtn}`}
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                    <button
                      className={`mt-2 mt-md-0 ${styles.saveBtn}`}
                      onClick={handleSave}
                    >
                      Save Changes
                    </button>
                  </div>
                ) : undefined}
              </div>
            )
          )}
        </div>
  );
};
export default ChatAssignment;
