import { axiosJSON } from "src/globals/axiosEndPoints";

export interface IMyIntegration {
  integrationTypeId: string | number;
  integrationTypeName: string;
  integratedCount: number;
}

export async function getMyIntegrations() {
  const { data: res } = await axiosJSON.get(
    `/api/setting/integration/getAllMyIntegrations`
  );
  if (res.err) {
    throw "Something went wrong!";
  }
  const integrations: { [key: string | number]: IMyIntegration } = {};
  const integrationIds: Array<string | number> = [];
  const total: number = res.total;
  const count: number = res.count;
  res.data.forEach((integration: IMyIntegration) => {
    integrations[integration.integrationTypeId] = integration;
    integrationIds.push(integration.integrationTypeId);
  });
  return { integrations, integrationIds, total, count };
}
