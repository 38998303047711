import React from "react";
import styles from "./Articles.module.scss";
import ArticleSideBar from "./Children/ArticleSideBar/ArticleSideBar";
import CreatePublish from "./Children/CreateArticle/Children/CreatePublish/CreatePublish";
import CreateArticleUpdated from "./Children/CreateArticle/CreateArticle";
import useArticles from "./useArticles";
const Articles = () => {
  const {
    convertedArticleId,
    setShowSubFolder,
    showSubFolder,
    brandDetails,
    brandDetailsError,
    brandDetailsLoading,
  } = useArticles();
  return (
    <>
      {/* For article homepage  */}
      <div className={`w-100 ${styles.articleWrapper}`}>
        {/*If CoverterArticleId is empty or null then show ArticleSideBar and
        CreateArticleUpdated component else show CreatePublish component  */}
        {convertedArticleId === "" || convertedArticleId === null ? (
          <>
            <ArticleSideBar
              showSubFolder={showSubFolder}
              brandDetails={brandDetails}
              brandDetailsError={brandDetailsError}
              brandDetailsLoading={brandDetailsLoading}
            />
            <div className="w-100">
              <CreateArticleUpdated
                setShowSubFolder={setShowSubFolder}
                showSubFolder={showSubFolder}
              />
            </div>
          </>
        ) : (
          <CreatePublish articleId={convertedArticleId} />
        )}
      </div>
    </>
  );
};

export default Articles;

// Old Implementation
//  1 - ArticlePath is replaced with CreateArticleUpdated
// 2 - CreateArticle is replaced with CreatePublish
