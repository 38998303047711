/**
 * This file is the service file used for making api request.
 * It contains the getArticleById function service which is explained below.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { axiosKbPreview } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants"; // this is used for testing purpose only for frontend.
import { ArticleAuthorDetails } from "./getAllArticle.service";

export interface getArticleByIdData {
  articleId: number | string;
  articleName: string;
  articleDescription: string;
  createdAtGmt: string;
  updatedAtGmt: string;
  authorDetails: ArticleAuthorDetails | null;
}

export interface getArticleByIdResponse {
  data: getArticleByIdData;
  err: string;
  tokenExpire: boolean;
}

export interface getArticleByIdPayload {
  integrationIdentifier: string;
  articleId: number | string;
}

/**
 * For testing frontend with own api link if backend is not prepared.
 */
const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/`
  : "/api/knowledgebase/helpCenterPage/";

/**
 * This service is created to get the article details by the id.
 */
export const getArticleById = async (params: getArticleByIdPayload) => {
  const { data: res } = await axiosKbPreview.post(
    `${apiEndPoint}${params.integrationIdentifier}/getArticleById`,
    {
      articleId: params.articleId,
    }
  );

  return res as getArticleByIdResponse;
};
