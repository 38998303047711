import styles from "./ChatSettingHome.module.scss";
import GoToSetting from "./Children/GoToSetting/GoToSetting";
import shopify from "src/assets/images/shopify.png";
import browser from "src/assets/images/internet.png";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import {
  createNewWebIntegration,
  fetchallWebIntegrations,
  resetWebsiteSettings,
} from "src/store/slices/liveChatSetting/websiteSettings/websiteSettings.slice";
import { v4 as uuid } from "uuid";
import Loader from "src/components/Loader";

const ChatSettingHome = () => {
  const {
    allWebIntAJAXStatus,
    allWebIntegrations,
    createNewWebIntAJAXStatus,
    createdIntId,
  } = useAppSelector((state) => state.websiteSettings);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(resetWebsiteSettings());
    dispatch(fetchallWebIntegrations());
  }, [false]);

  useEffect(() => {
    if (createdIntId !== null && createNewWebIntAJAXStatus === "fulfilled") {
      navigate("/live-chat/settings/" + createdIntId + "/Website");
    }
  }, [createNewWebIntAJAXStatus, createdIntId]);

  const handleAddNew = () => {
    if (createdIntId === null) {
      dispatch(createNewWebIntegration());
    } else {
      navigate("/live-chat/settings/" + createdIntId + "/Website");
    }
  };

  return (
    <div className={`${styles.ChatSettingHome}`}>
      <div className={`d-flex justify-content-between align-items-center mb-4`}>
        <h2 className={`${styles.chatHead}`}>Live Chat Settings</h2>
        <button
          className={`${styles.AddNewBtn}`}
          disabled={
            createNewWebIntAJAXStatus === "pending" ||
            allWebIntAJAXStatus === "pending"
          }
          onClick={handleAddNew}
        >
          {createNewWebIntAJAXStatus === "pending" ? (
            <Spinner
              className="my-auto mx-1"
              animation="border"
              color="white"
              size="sm"
            />
          ) : (
            "Add new"
          )}
        </button>
      </div>
      <div>
        {allWebIntAJAXStatus === "pending" ? (
          <>
            <div className="d-flex justify-content-center w-100">
              <Loader />
            </div>
          </>
        ) : (
          <>
            {allWebIntegrations.map((data, index) => {
              return (
                <GoToSetting
                  key={data.integration_id}
                  link={data.url}
                  integrationId={data.integration_id}
                  user={
                    data.live_chat_integration_type === "shopify" //conditionally rendering shopify icon based on integration type
                      ? shopify
                      : browser
                  }
                  liveChatEnabled={data.live_chat_enabled}
                  installed={data.installed}
                />
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};
export default ChatSettingHome;
