import { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import PlanModal from "src/components/PlanUpgrade/Children/PlanModal/PlanModal";
import { useBillingContext } from "../../BillingContext";
import ContactSaleModal from "./ContactSaleModal/ContactSaleModal";
import styles from "./FeatureLimits.module.scss";
import { getTimeFromNow } from "src/utils/dateLibrary";
import { useSearchParams } from "react-router-dom";
import planUpgrade from "src/assets/images/planUpgrade.png";

/**
 * Featurelimits component.
 *
 * This component displays the feature limits for the user's billing details.
 * It also provides buttons to contact sales or upgrade to a pro plan.
 *
 * Usage:
 * ```tsx
 * <Featurelimits />
 * ```
 *
 * @returns {JSX.Element} The Featurelimits component.
 */
const Featurelimits = () => {
  const { billingDetails } = useBillingContext();
  const [URLSearchParams, SetURLSearchParams] = useSearchParams();
  const [showContactUsModal, setShowContactUsModal] = useState<boolean>(false);
  const [showPaymentPlan, setShowPaymentPlan] = useState(false);

  /**
   * Handler for showing modal based on current details
   */
  const showModal = useCallback(() => {
    if (billingDetails?.featureLimit?.isContactSales) {
      setShowContactUsModal(true);
    } else if (billingDetails?.isFreePlan) {
      setShowPaymentPlan(true);
    }
  }, [
    billingDetails?.featureLimit?.isContactSales,
    billingDetails?.isFreePlan,
  ]);

  /**
   * Effect for getting modal value from params and open it
   */
  useEffect(() => {
    const modal = URLSearchParams.get("modal");

    if (modal === "upgrade") {
      // Show Upgrade Plan Modals
      showModal();

      // Remove Params
      URLSearchParams.delete("modal");
      SetURLSearchParams(URLSearchParams);
    }
  }, [SetURLSearchParams, URLSearchParams, showModal]);

  return (
    <>
      {/* Feature Limit List UI */}
      {billingDetails?.featureLimit && (
        <div className={`mb-3 ${styles.featureWrap}`}>
          {/* Heading */}
          <h2 className={`${styles.feautureHead}`}>Feature limits</h2>

          {/* Features List */}
          {billingDetails?.featureLimit?.limits &&
            billingDetails.featureLimit.limits.map((feature) => (
              <div className={`px-2 ${styles.feature}`}>
                <p className={`mb-0 ${styles.featureName}`}>{feature.name}</p>
                <span className={`${styles.featureCount}`}>
                  {feature.count}/{feature.maxCount} used
                </span>
              </div>
            ))}

          {/* Buttons */}
          <div className="d-flex justify-content-between align-items-center">
            {/* Contact Sales button */}
            {billingDetails?.featureLimit?.isContactSales && (
              <button
                className={`mt-2 ${styles.upgradeBtn}`}
                onClick={() => setShowContactUsModal(true)}
              >
                <span className="">Contact sales</span>
              </button>
            )}

            {/* Upgrade to Pro Button */}
            {billingDetails?.isFreePlan && (
              <button
                className={`mt-2 ${styles.upgradeBtn}`}
                onClick={() => setShowPaymentPlan(true)}
              >
                <span>
                  <img
                    src={planUpgrade}
                    alt="UpgradeButton"
                    className={`${styles.upgradeImg}`}
                  />
                </span>
                <span className="ps-2">Upgrade to Pro plan</span>
              </button>
            )}

            {/* Last Contacted At */}
            {billingDetails?.featureLimit?.lastContactedAtGMT && (
              <span className={`${styles.contactedAt}`}>
                Contacted{" "}
                {getTimeFromNow({
                  date: new Date(
                    billingDetails.featureLimit.lastContactedAtGMT,
                  ),
                  showJustNow: false,
                })}
              </span>
            )}
          </div>
        </div>
      )}

      {/* Contact Sales Modal */}
      {billingDetails?.featureLimit?.isContactSales && (
        <Modal
          backdropClassName={`${styles.modalBack}`}
          show={showContactUsModal}
          onHide={() => setShowContactUsModal(false)}
          dialogClassName={`${styles.modalDialog}`}
          contentClassName={`${styles.modalContent}`}
          centered={true}
          enforceFocus={false}
        >
          <ContactSaleModal onHide={() => setShowContactUsModal(false)} />
        </Modal>
      )}

      {/* Upgrade to Pro Plan Modal */}
      {billingDetails?.isFreePlan && (
        <PlanModal
          hideModal={() => setShowPaymentPlan(false)}
          showModal={showPaymentPlan}
        />
      )}
    </>
  );
};

export default Featurelimits;
