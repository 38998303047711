import { useMemo } from "react";
import { useAppSelector } from "src/store/store";
import { convertGmtToUserTimezone } from "src/utils/dateLibrary";

const useUTCToUserTime = ({
  timeInUTC,
  dateOnly = false,
}: {
  timeInUTC: string;
  dateOnly?: boolean;
}) => {
  const userTimezone = useAppSelector(
    (state) => state.globals.currentUserData?.userTimezone
  );
  const generatedDate = useMemo(() => {
    return convertGmtToUserTimezone(timeInUTC, userTimezone, dateOnly);
  }, [timeInUTC, userTimezone, dateOnly]);

  return generatedDate;
};

export default useUTCToUserTime;
