import styles from "./Customer.module.scss";
import { CloseButton, Modal, Spinner } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { GetOrderViewModel } from "src/services/ShopifySidebar/SingleOrder/BasicOrder/getOrderViewModel";
import { useContext, useState } from "react";
import { ModalContext } from "../../../Modal/ModalContext";
import EditShippingAddress from "../EditShippingAddress/EditShippingAddress";
import EditContactInformation from "./children/EditContactInformation/EditContactInformation";
import { resetShopifyCustomerData } from "src/store/slices/singleOrder/order.slice";

function Customer() {
  const modalContext = useContext(ModalContext);
  const dispatch = useAppDispatch();
  const {
    loading,
    error,
    getOrderViewModel: getOrder,
    updateOrder,
  } = useAppSelector((state) => state.singleOrder);
  const { shopifyData } = useAppSelector((state) => state.shopifySidebar);

  // edit contact info modal
  const [showEditContactInfoModal, setShowEditContactInfoModal] =
    useState<boolean>(false);

  const handleOnShowEditContactInfo = () => {
    setShowEditContactInfoModal(true);
  };

  const handleOnHideEditContactInfo = () => {
    setShowEditContactInfoModal(false);
  };

  // edit shipping address modal
  const [showEditShippingAddressModal, setShowEditShippingAddressModal] =
    useState<boolean>(false);

  const handleOnShowEditShippingAddress = () => {
    setShowEditShippingAddressModal(true);
  };

  const handleOnHideEditShippingAddress = () => {
    // reset the shopify single customer data
    dispatch(resetShopifyCustomerData());
    setShowEditShippingAddressModal(false);
  };

  const [copyEmailStyle, setCopyEmailStyle] = useState<React.CSSProperties>({});

  const [copyAddressStyle, setCopyAddressStyle] = useState<React.CSSProperties>(
    {}
  );

  function checkIfEmail(str: string) {
    // Regular expression to check if string is email
    const regexExp =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi;

    return regexExp.test(str);
  }

  const copyToClipBoard = async (copyMe: string) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      if (checkIfEmail(copyMe)) {
        setCopyEmailStyle((copyEmailStyle) => {
          return {
            color: "blue",
          };
        });
        setCopyAddressStyle({});
      } else {
        setCopyAddressStyle((copyAddressStyle) => {
          return {
            color: "blue",
          };
        });
        setCopyEmailStyle({});
      }
    } catch (err) {
      setCopyEmailStyle({});
    }
  };

  const shippingAddress = ` ${getOrder?.customerData?.name} ${getOrder?.shippingAddress?.address1} ${getOrder?.shippingAddress?.city} ${getOrder?.shippingAddress?.country} ${getOrder?.shippingAddress?.countryCodeV2} `;

  function renderData(order: GetOrderViewModel, modalContext: any) {
    return (
      <>
        <div
          className={`${styles.Customer} ${styles.lead} ms-3 py-3 mb-3 d-flex flex-column `}
        >
          <div className="d-flex px-3 flex-row justify-content-between">
            <div className={`${styles.customerHead}`}>Customer</div>

            {/* Nov 24 commented the cross button as it is not handled */}
            {/* <div className={`${styles.closeIcon}`}>
              <CloseButton className={`${styles.closeButton}`} />
            </div> */}
          </div>

          <div className="mt-1 px-3" role="button">
            <p>
              {" "}
              <span className={`${styles.customerName}`}>
                {order?.customerData?.name}{" "}
              </span>{" "}
              <br />
              {/* {order?.orderItems[0]?.status?.itemCount ?? 0} orders{" "} */}
              <span className={`${styles.lead}`}>
                {shopifyData?.totalOrders ?? 0} orders{" "}
              </span>
            </p>
          </div>

          <div className={`${styles.hr}`}>
            <hr />
          </div>

          <div>
            <div className="d-flex flex-row justify-content-between px-3">
              <div className={`${styles.contactHead}`}>CONTACT INFORMATION</div>

              {/* <div
                className={`link-primary ${styles.bootstrapColorChange}`}
                role="button"
                onClick={() => {
                  // we don't need edit now
                  // handleOnShowEditContactInfo();
                }}
              >
                Edit
              </div> */}
            </div>
            <div className="d-flex flex-row justify-content-between px-3">
              <div className="mt-2">
                {updateOrder?.contactDetailsUpdateAjaxStatus === "pending" ? (
                  <div className={`m-auto text-center ${styles.spinnerDiv}`}>
                    <Spinner
                      className={`m-auto text-center`}
                      animation="border"
                      color="white"
                      size="sm"
                    />
                  </div>
                ) : (
                  <p>
                    {" "}
                    <span
                      className={` ${styles.bootstrapColorChange}`}
                      role="button"
                    >
                      {order?.contactInformation?.email}{" "}
                    </span>
                  </p>
                )}
              </div>
              <div className={`mt-2 ms-1 ${styles.copyBtn}`}>
                <i
                  className={`fa-solid fa-copy `}
                  style={copyEmailStyle}
                  role="button"
                  onClick={(e) =>
                    copyToClipBoard(order?.contactInformation?.email ?? "")
                  }
                ></i>
              </div>
            </div>
          </div>

          <div className={`${styles.hr}`}>
            <hr />
          </div>

          <div>
            <div className="d-flex flex-row justify-content-between px-3">
              <div className={`${styles.shipHead}`}>SHIPPING ADDRESS</div>

              {/* <div
                className={`link-primary ${styles.bootstrapColorChange}`}
                role="button"
                onClick={() => {
                  // we don't need edit now
                  // handleOnShowEditShippingAddress();
                }}
              >
                Edit
              </div> */}
            </div>

            <div className="d-flex flex-row justify-content-between px-3">
              <div className="mt-2">
                {updateOrder?.orderShippingAddressUpdateAjaxStatus ===
                "pending" ? (
                  <div className={`m-auto text-center ${styles.spinnerDiv}`}>
                    <Spinner
                      className={`m-auto text-center`}
                      animation="border"
                      color="white"
                      size="sm"
                    />
                  </div>
                ) : (
                  <p className={`${styles.shipDetails}`}>
                    {order?.customerData?.name ?? "NA"}
                    <br />
                    {order?.shippingAddress?.address1 ?? "NA"} <br />
                    {order?.shippingAddress?.city ?? "NA"} <br />
                    {order?.shippingAddress?.country ?? "NA"}
                    <br />({order.shippingAddress?.countryCodeV2 ?? "NA"}){" "}
                    {order?.shippingAddress?.zip ?? "NA"}
                    <br />
                    {/* <span
                      className={`link-primary text-decoration-underline ${styles.bootstrapColorChange}`}
                      role="button"
                    >
                      View map&nbsp;&nbsp;
                      <i className="fa-solid fa-arrow-up-right-from-square"></i>{" "}
                    </span> */}
                  </p>
                )}
              </div>
              <div className={`mt-2 ms-1 ${styles.copyBtn}`}>
                <i
                  className={`fa-solid fa-copy `}
                  style={copyAddressStyle}
                  role="button"
                  onClick={(e) => copyToClipBoard(shippingAddress)}
                ></i>
              </div>
            </div>
          </div>

          <div className={`${styles.hr}`}>
            <hr />
          </div>

          <div className="px-3">
            <div className={`${styles.billDetails}`}>BILLING ADDRESS</div>

            <div className={``}>
              <p className={`${styles.shipDetails}`}>
                {order?.customerData?.name ?? "NA"}
                <br />
                {order?.shippingAddress?.address1 ?? "NA"} <br />
                {order?.shippingAddress?.city ?? "NA"} <br />
                {order?.shippingAddress?.country ?? "NA"}
                <br />({order?.shippingAddress?.countryCodeV2 ?? "NA"}){" "}
                {order?.shippingAddress?.zip ?? "NA"}
                <br />
              </p>
            </div>
          </div>
        </div>
        {showEditContactInfoModal === true ? (
          <>
            <Modal
              show={showEditContactInfoModal}
              onHide={handleOnHideEditContactInfo}
              className={`shadow-sm p-3 mb-5  rounded ${styles.editContactModal}`}
              backdropClassName={styles.backdropEditContactModal}
              dialogClassName={styles.modalDialogEditContactModal}
              contentClassName={styles.modalContentEditContactModal}
              centered={true}
              enforceFocus={true}
            >
              <EditContactInformation onHide={handleOnHideEditContactInfo} />
            </Modal>
          </>
        ) : (
          <></>
        )}

        {showEditShippingAddressModal === true ? (
          <>
            <Modal
              show={showEditShippingAddressModal}
              onHide={handleOnHideEditShippingAddress}
              className={`shadow-sm p-3 mb-5  rounded ${styles.editContactModal}`}
              backdropClassName={styles.backdropEditContactModal}
              dialogClassName={styles.modalDialogEditShippingAddressModal}
              contentClassName={styles.modalContentEditContactModal}
              centered={true}
              enforceFocus={true}
            >
              <EditShippingAddress onHide={handleOnHideEditShippingAddress} />
            </Modal>
          </>
        ) : (
          <></>
        )}
      </>
    );
  }

  return (
    <div>{!loading && getOrder && renderData(getOrder, modalContext)}</div>
  );
}

export default Customer;
