import React from "react";
import { AJAXSTATUS } from "src/globals/constants";
import { useAppSelector } from "src/store/store";
import { convertGmtToUserTimezone, getTimeFromNow } from "src/utils/dateLibrary";
import styles from "./MissedChatPickBox.module.scss";
interface Prop{
  chatId: number|string,
  onClick: (chatId: number|string) => void,
  showLoader: boolean
}

const MissedChatPickBox = ({chatId, onClick , showLoader}: Prop) => {
  const userTimezone = useAppSelector(
    (state) => state.globals.currentUserData?.userTimezone
  );

 
  return (
    <div className={`${styles.pickBox} py-4 px-2 text-center`}>
      <p className={`pt-2 ${styles.pickHead}`}>
        {"This chat is unassigned"}
      </p>
      <div className="pt-3 pb-2">
        {showLoader === true ? (
          <button className={`border-0 py-2 px-4 ${styles.pickBtn} ${styles.notActive}`} disabled={true}>Assigning…..</button>
        ) : (
          <button className={`border-0 py-2 px-4 ${styles.pickBtn}`} onClick={(e)=>{onClick(chatId)}}>Accept Chat</button>
          )}
      </div>
    </div>
  );
};

export default MissedChatPickBox;
