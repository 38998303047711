import { axiosJSON } from "src/globals/axiosEndPoints";

export interface DuplicateOrder {
  error: boolean;
  message: string;
  data: {
    orderId: number;
    draft: string;
  };
}

export interface DuplicateOrderParams {
  orderId: number;
}

export async function duplicateOrder(params: DuplicateOrderParams) {
  const { data: res }: any = await axiosJSON.post(
    `/api/shopify/order/duplicate`,
    params
  );

  if (res.error === true) {
    throw new Error(res.message as string);
  }

  return res.data as DuplicateOrder;
}
