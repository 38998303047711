import { RootState } from "src/store/store";
import {
  ETableTypeIndex,
  ETableTypeNames,
} from "src/services/TicketService/linkGenerator";
import { ITicketSideBarState } from "./ticketSidebar.slice";
import { getJSONSearchParam } from "src/utils/utils";

export function getActiveListName(state: RootState) {
  const keyName = state.ticketSideBar.selectedBtn;
  const selectedAiBtn = state.ticketSideBar.selectedAiBtn;
  if (keyName.includes("viewId")) {
    const viewId = keyName.split("::")[1];
    return state.ticketSideBar.usersViewData[viewId]
      ? state.ticketSideBar.usersViewData[viewId].viewName
      : "";
  } else if (keyName === "mentions") {
    return `@${state.globals.currentUserData?.firstName ?? ""}`;
  }else if (keyName === "ai_handled") {
    if(selectedAiBtn == "Open"){
      return `Open Cern Tickets`;
    }
    if(selectedAiBtn == "Closed"){
      return `Closed Cern Tickets`;
    }
    return `Cern Tickets`
  } else {
    // return ETableTypeNames[keyName as any]
    //   ? ETableTypeNames[keyName as any]
    //   : "";
    const channel =
      state.ticketSideBar.ticketBtnData[0]?.ticketBtnList[
        ETableTypeIndex[keyName as any] as any
      ]?.ddOuterList[0];
    const ticketStatus =
      state.ticketSideBar.ticketBtnData[0]?.ticketBtnList[
        ETableTypeIndex[keyName as any] as any
      ]?.ddOuterList[1];

    //checking if all is selected if yes then retuning All status key and
    const ticketStatusViewName = ticketStatus?.ddSelectedIndex?.includes(0)
      ? ticketStatus?.ddList[0]?.name
      : ticketStatus?.ddSelectedIndex
          .map((selectedIndex) => ticketStatus?.ddList[selectedIndex].name)
          .join(", ");
    const channelViewName = channel?.ddSelectedIndex?.includes(0)
      ? channel?.ddList[0]?.name
      : channel?.ddSelectedIndex
          .map((selectedIndex) => channel?.ddList[selectedIndex].name)
          .join(", ");
    const ret = `${
      keyName === "my" && ticketStatusViewName === "All"
        ? "My"
        : `${keyName === "my" ? "My " : ""}${ticketStatusViewName}`
    } Tickets ${
      channelViewName === "All" && ticketStatusViewName === "All"
        ? ""
        : `(${channelViewName})`
    }`;
    if (ret.includes("undefined")) {
      return "Fetching...";
    } else {
      return ret;
    }
  }
}

export function getActiveListIndex(state: RootState) {
  return state.ticketSideBar.selectedBtn;
}

export function getActiveTicketPageCount(state: RootState) {
  const { selectedBtn, ticketBtnData } = state.ticketSideBar;
  let activeTicketPageCount = 0;
  for (let i = 0; i < ticketBtnData.length; i++) {
    const data = ticketBtnData[i];
    for (let j = 0; j < data.ticketBtnList.length; j++) {
      const list = data.ticketBtnList[j];
      if (list.keyName === selectedBtn) {
        activeTicketPageCount = list.ticketBtnCount;
        break;
      }
    }
    if (activeTicketPageCount !== 0) {
      break;
    }
  }
  return activeTicketPageCount;
}

export function getActiveChannelId(state: RootState) {
  const { selectedBtn, ticketBtnData } = state.ticketSideBar;
  let activeChannelId: number[] = [0]; //updated activeChannelId variable the type to array as it is now ddSelectedIndex is expected to be array
  if (ticketBtnData.length) {
    const data = ticketBtnData[0];
    for (let j = 0; j < data.ticketBtnList.length; j++) {
      const list = data.ticketBtnList[j];
      if (list.keyName === selectedBtn) {
        activeChannelId = list.ddOuterList[0].ddSelectedIndex;
        break;
      }
    }
  }
  return activeChannelId;
}

export function getActiveDraftStatusId(state: RootState) {
  const { selectedBtn, ticketBtnData } = state.ticketSideBar;
  let activeDraftStatusId: number[] = [0]; //updated activeDraftStatusId variable the type to array as it is now ddSelectedIndex is expected to be array
  if (ticketBtnData.length) {
    const data = ticketBtnData[0];
    for (let j = 0; j < data.ticketBtnList.length; j++) {
      const list = data.ticketBtnList[j];
      if (list.keyName === selectedBtn) {
        activeDraftStatusId = list.ddOuterList[2]?.ddSelectedIndex ?? [];
        break;
      }
    }
  }
  return activeDraftStatusId;
}

export function getActiveTicketStatusId(state: RootState) {
  const { selectedBtn, ticketBtnData } = state.ticketSideBar;
  let activeTicketStatusId: number[] = [0]; //updated activeTicketStatusId variable the type to array as it is now ddSelectedIndex is expected to be array
  if (ticketBtnData.length) {
    const data = ticketBtnData[0];
    for (let j = 0; j < data.ticketBtnList.length; j++) {
      const list = data.ticketBtnList[j];
      if (list.keyName === selectedBtn) {
        activeTicketStatusId = list.ddOuterList[1].ddSelectedIndex;
        break;
      }
    }
  }
  return activeTicketStatusId;
}

export function defaultDDFiltersBase(state: ITicketSideBarState) {
  let activeTicketStatusId = [0];
  const statusData =
    state?.ticketBtnData[0]?.ticketBtnList[0]?.ddOuterList[1]?.ddList;
  if (statusData) {
    activeTicketStatusId = [
      parseInt(
        Object.entries(statusData).filter(
          ([key, data]) => data.name === "Open"
        )[0][0]
      ),
    ];
  }
  return {
    obj: {
      activeChannelId: [0],
      activeTicketStatusId,
      activeDraftStatusId: [0],
    },
    arr: [[0], activeTicketStatusId, [0]],
  };
}

export function defaultDDFilters(state: RootState) {
  return defaultDDFiltersBase(state.ticketSideBar).obj;
}

// to get the already saved ticketAppliedData of a particular table
// if there is no search ticketAppliedData return empty string
export function getSavedTicketAppliedData(
  state: ITicketSideBarState,
  tableType: string
) {
  let ticketAppliedData = "";
  if (tableType) {
    if (tableType === "mentions") {
      ticketAppliedData = state.mentionsTicketAppliedData ?? "{}";
    } else if (!tableType.includes("viewId")) {
      if (
        state.ticketBtnData.length &&
        ETableTypeIndex[tableType as any] !== undefined
      ) {
        // when table type is all / my
        ticketAppliedData =
          state.ticketBtnData[0].ticketBtnList[
            parseInt(ETableTypeIndex[tableType as any] + "")
          ].ticketAppliedData ?? "";
      }
    } else {
      ticketAppliedData =
        state.usersViewData[tableType.split("::")[1]]?.ticketAppliedData ?? "";
    }
  }
  try {
    return JSON.parse(ticketAppliedData);
  } catch {
    return {};
  }
}

export const getActiveSidebarCount = (state: RootState) => {
  const btn = state.ticketSideBar.selectedBtn;
  let allTicketCount = 0;
  if (btn.includes("viewId")) {
    try {
      allTicketCount =
        state.ticketSideBar.usersViewData[btn.split("::")[1]].viewsTicketCount;
    } catch {}
  } else if (btn === "mentions") {
    allTicketCount = state.ticketSideBar.unreadMentionsCount;
  } else if (btn) {
    try {
      allTicketCount =
        state.ticketSideBar.ticketBtnData[0].ticketBtnList[
          ETableTypeIndex[btn as "my" | "all"]
        ].ticketBtnCount;
    } catch {}
  } else {
    try {
      allTicketCount =
        state.ticketSideBar.ticketBtnData[0].ticketBtnList[0].ticketBtnCount;
    } catch {}
  }
  return allTicketCount;
};
