import { useCallback, useEffect, useState } from "react";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import {
  ConditionTypes,
  getConditionValueOrDefault,
} from "src/features/ReturnAutoWorkFlow/children/ConfigModals/store/returnModalsSliceHelpers";
import { useReturnAutoWorkFlow } from "src/features/ReturnAutoWorkFlow/hooks/useReturnAutoWorkFlow";
import { IVariableName } from "src/store/slices/initiateReturn/antiRefundOrderTypesConfig.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  addCondition,
  addReasonType,
  deleteCondition,
  pushCurrentHashForSelectedTab,
  resetMoreQuestionModal,
  toggleAllItem,
  toggleAllOrder,
  updateCondition,
  updateReasonType,
  validateMoreQuestion,
} from "../../store/slices/ReturnReasonOrderAndItems/returnReasonOrderAndItems.slice";
import {
  fetchReturnReasonOrderAndItems,
  updateReturnReasonOrderAndItems,
  validateReturnReasonOrderAndItemsThunk,
} from "../../store/slices/ReturnReasonOrderAndItems/returnReasonOrderAndItems.thunk";

const useReturnReasonOrderAndItems = ({
  onSave,
}: {
  onSave: (isSaved: boolean) => void;
}) => {
  const { returnAutoWorkFlow } = useReturnAutoWorkFlow();

  const dispatch = useAppDispatch();

  const { reasonTypes, formElementOptions, moreQuestionsModal } =
    useAppSelector((state) => {
      return state.returnReasonOrderAndItems;
    });

  const selectedReasonTypeId = useAppSelector((state) => {
    return state.returnReasonOrderAndItems.selectedReasonTypeId;
  });

  const operators = useAppSelector(
    (state) => state.returnReasonOrderAndItems.operatorsNames,
  );

  const isAddBtnEnabled = useAppSelector((state) => {
    const root = state.returnReasonOrderAndItems;
    const selectedReason = root.reasonTypes.find(
      (res) => res.reasonTypeId === root.selectedReasonTypeId,
    );
    if (selectedReason !== undefined) {
      return selectedReason.isAddConditionEnabled;
    } else {
      return false;
    }
  });

  const isAddItemBtnEnabled = useAppSelector((state) => {
    const root = state.returnReasonOrderAndItems;
    const selectedReason = root.reasonTypes.find(
      (res) => res.reasonTypeId === root.selectedReasonTypeId,
    );
    if (selectedReason !== undefined) {
      return selectedReason.isAddItemConditionEnabled;
    } else {
      return false;
    }
  });

  const step12Configuration = useAppSelector(
    (state) => state.returnReasonOrderAndItems,
  );

  const errorInOtherTabs = step12Configuration.reasonTypes.flatMap((resT) => {
    return resT.errorCount;
  });

  const [otherTabsHasError, setOtherTabsHasError] = useState(false);

  const sumWithInitial = errorInOtherTabs.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0,
  );

  const valuesTypes = useAppSelector(
    (state) => state.returnReasonOrderAndItems.variableNameToValueType,
  );
  useEffect(() => {
    if (sumWithInitial > 0) {
      setOtherTabsHasError(true);
    } else {
      setOtherTabsHasError(false);
    }
  }, [sumWithInitial]);

  useEffect(() => {
    if (returnAutoWorkFlow.integrationId) {
      dispatch(
        fetchReturnReasonOrderAndItems({
          integrationId: returnAutoWorkFlow.integrationId,
          // TODO -
          questionValue: "",
          reasonList: [],
        }),
      );
    }
  }, [dispatch, returnAutoWorkFlow.integrationId]);

  const isAddReasonEnabled = useAppSelector((state) => {
    const root = state.returnReasonOrderAndItems;
    const selectedReason = root.reasonTypes.find(
      (res) => res.reasonTypeId === root.selectedReasonTypeId,
    );
    if (selectedReason !== undefined) {
      return selectedReason.isAddReasonEnabled;
    } else {
      return false;
    }
  });

  const dispUpdateReasonType = useCallback(
    (id: string, updateObj: any) => {
      dispatch(
        updateReasonType({
          reasonTypeDetail: {
            ...reasonTypes,
            reasonTypeId: id,
            ...updateObj,
          },
        }),
      );
    },
    [dispatch, reasonTypes],
  );

  const dispPushCurrentHashForSelectedTab = useCallback(
    (id: string) => {
      dispatch(
        pushCurrentHashForSelectedTab({
          reasonTypeId: id,
        }),
      );
    },
    [dispatch],
  );

  const dispAddTab = useCallback(() => {
    dispatch(addReasonType());
  }, [dispatch]);

  const dispUpdateConditionForVariables = useCallback(
    (
      id: string,
      e: { target: { value: string } },
      condition: ConditionTypes,
    ) => {
      dispatch(
        updateCondition({
          reasonTypeId: id,
          conditionToUpdate: {
            ...condition,
            variableName: e.target.value as IVariableName,
            operator: operators[e.target.value as IVariableName][0],
            values: getConditionValueOrDefault(
              valuesTypes[e.target.value as IVariableName],
            ),
          },
        }),
      );
    },
    [dispatch, operators, valuesTypes],
  );

  const dispUpdateConditonForOperator = useCallback(
    (
      id: string,
      e: { target: { value: string } },
      condition: ConditionTypes,
    ) => {
      dispatch(
        updateCondition({
          reasonTypeId: id,
          conditionToUpdate: {
            ...condition,
            operator: e.target.value,
          },
        }),
      );
    },
    [dispatch],
  );

  const dispUpdateConditionForValues = useCallback(
    (id: string, type: string, value: any, condition: ConditionTypes) => {
      switch (type) {
        case "tags":
          dispatch(
            updateCondition({
              reasonTypeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  tags: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;
        case "items":
          dispatch(
            updateCondition({
              reasonTypeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  items: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;
        case "days":
          dispatch(
            updateCondition({
              reasonTypeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  days: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;
        case "day_type":
          dispatch(
            updateCondition({
              reasonTypeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  day_type: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;

        case "codes":
          dispatch(
            updateCondition({
              reasonTypeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  codes: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;
        case "numbers":
          dispatch(
            updateCondition({
              reasonTypeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  numbers: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;
        case "countryName":
          dispatch(
            updateCondition({
              reasonTypeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  countryName: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;
        case "amount":
          dispatch(
            updateCondition({
              reasonTypeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  amount: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;
        case "currency":
          dispatch(
            updateCondition({
              reasonTypeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  currency: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;

        default:
          break;
      }
    },
    [dispatch],
  );

  const dispDeleteCondition = useCallback(
    (conId: string, id: string, isItem?: boolean) => {
      dispatch(
        deleteCondition({
          conditionId: conId,
          reasonTypeId: id,
          isItemCondition: isItem,
        }),
      );
      // dispatch(validateChanges());
    },
    [dispatch],
  );

  const selectedReasonType = useAppSelector((state) =>
    state.returnReasonOrderAndItems.reasonTypes.find(
      (resType) => resType.reasonTypeId === selectedReasonTypeId,
    ),
  );

  const initialHashOtherTab = useAppSelector(
    (state) => state.returnReasonOrderAndItems.initialHashOtherTab,
  );

  const currentHashOtherTab = useAppSelector(
    (state) => state.returnReasonOrderAndItems.currentHashOtherTab,
  );

  const { conditionOptions, itemOptions, defaultOptions } = useAppSelector(
    (state) => {
      const conditionOptions = Object.values(
        state.returnReasonOrderAndItems.conditionOptions,
      ).map((option) => option.name);
      const itemOptions = Object.values(
        state.returnReasonOrderAndItems.itemOptions,
      ).map((option) => option.name);
      const defaultOptions = Object.values(
        state.returnReasonOrderAndItems.defaultOptions,
      ).map((option) => option.name);
      return {
        conditionOptions,
        itemOptions,
        defaultOptions,
      };
    },
  );

  const dispAddCondition = useCallback(
    (id: string, isItem?: boolean, e?: { target: { value: string } }) => {
      dispatch(
        addCondition({
          reasonTypeId: id,
          condition: e ? e.target.value : "",
          isItemCondition: isItem,
        }),
      );
      // dispatch(validateChanges());
    },
    [dispatch],
  );

  const dispValidateMoreQuestions = useCallback(
    (reasonTypeId: string) => {
      dispatch(validateMoreQuestion({ reasonTypeId }));
    },
    [dispatch],
  );

  const dispPostConfig = useCallback(async () => {
    if (!returnAutoWorkFlow.integrationId) {
      return;
    }

    const res = await dispatch(validateReturnReasonOrderAndItemsThunk());
    if (res.payload === 0) {
      dispatch(
        updateReturnReasonOrderAndItems({
          callback: () => {
            onSave(true);
            dispatch(resetMoreQuestionModal());
          },
          // TODO
          integrationId: returnAutoWorkFlow.integrationId,
          isSelectReasonEnabled: false,
        }),
      );
    } else {
      pushTheToast({
        type: "warning",
        text: "Please fill the required fields!",
        position: "top-right",
      });
    }
  }, [dispatch, onSave, returnAutoWorkFlow.integrationId]);

  const { fetchAjaxStatus, updateAjaxStatus } = useAppSelector(
    (state) => state.returnReasonOrderAndItems,
  );

  const onToggleAllOrder = useCallback(
    (checked: boolean, reasonTypeId: string) => {
      dispatch(
        toggleAllOrder({
          reasonTypeId,
          value: checked,
        }),
      );
      dispPushCurrentHashForSelectedTab(reasonTypeId);
    },
    [dispPushCurrentHashForSelectedTab, dispatch],
  );

  const onToggleAllItems = useCallback(
    (checked: boolean, reasonTypeId: string) => {
      dispatch(
        toggleAllItem({
          reasonTypeId,
          value: checked,
        }),
      );
      dispPushCurrentHashForSelectedTab(reasonTypeId);
    },
    [dispPushCurrentHashForSelectedTab, dispatch],
  );

  return {
    fetchAjaxStatus,
    updateAjaxStatus,
    selectedReasonTypeId,
    reasonTypes,
    dispUpdateReasonType,
    dispPushCurrentHashForSelectedTab,
    otherTabsHasError,
    dispAddTab,
    dispValidateReturnType: () => {},
    initialHashOtherTab,
    currentHashOtherTab,
    dispValidateCondition: () => {},
    dispValidateMoreQuestions,
    itemOptions,
    dispAddCondition,
    dispDeleteCondition,
    dispUpdateConditionForVariables,
    dispUpdateConditonForOperator,
    operators,
    isAddItemBtnEnabled,
    defaultOptions,
    conditionOptions,
    isAddReasonEnabled,
    dispUpdateConditionForValues,
    isAddBtnEnabled,
    dispPostConfig,
    onToggleAllOrder,
    onToggleAllItems,
    selectedReasonType,
    formElementOptions,
    moreQuestionsModal,
  };
};

export default useReturnReasonOrderAndItems;
