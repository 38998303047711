import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Dropdown, OverlayTrigger, Popover } from "react-bootstrap";
import MessageSection from "src/components/AutomationComponents/ContentSection/MessageSection";
import { SelectOptionType } from "src/components/AutomationComponents/ContentSection/Values";
import Variables, {
  addVariableOnClick,
} from "src/components/AutomationComponents/ContentSection/VariableSection";
import CustomizeSelect from "src/components/AutomationComponents/IndividualComponents/CustomizeSelect/CustomizeSelect";
import Tags from "src/components/AutomationComponents/Other/Components/Tags";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { useFetchReturnProducts } from "src/features/ReturnAutoWorkFlow/hooks/useFetchReturnConditions";
import { IVariable } from "src/services/UiAutomation/ReturnExchange/Configuration/Variables/getAllVariables";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  updateAdditionalInfoConfigOrderTypes,
  updateArsResponse,
  validateChanges,
} from "../../../../store/slices/AntiRefundStrategiesOrdersSlice/antiRefundStrategiesOrders.slice";
import {
  RefundScenario,
  updateAdditionalInfoConfig,
} from "../../../../store/slices/AntiRefundStrategiesSlice/antiRefundStrategies.slice";
import {
  RefundScenarioState,
  ScenarioDispatchContext,
  ScenarioStateContext,
} from "../Scenario/ScenarioReducer";
import styles from "./RefundMethod.module.scss";
import useGetAutomationData from "src/features/ReturnAutoWorkFlow/hooks/useGetAutomationData";
import { useReturnAutoWorkFlow } from "src/features/ReturnAutoWorkFlow/hooks/useReturnAutoWorkFlow";

interface Props {
  reasonId: string;
  onHide: () => void;
  scenario: RefundScenario;
  question: string;
  returnReason: string;
}
const Limit = 25;

const AntiRefundMethodModal = ({
  onHide,
  reasonId,
  scenario,
  question,
  returnReason,
}: Props) => {
  // Get the reducer local state
  const localState: RefundScenarioState = useContext(ScenarioStateContext);
  const { fetchProducts, products } = useFetchReturnProducts();

  //Get the reducer dispatch function
  const localDispatch = useContext(ScenarioDispatchContext);

  const dispatch = useAppDispatch();

  const { getArsRespone } = useGetAutomationData();
  const { returnAutoWorkFlow } = useReturnAutoWorkFlow();
  // Will trigger when localstate changes, and will set it in the redux store to be used for return preview
  // As we can not use the context , storing in redux store
  useMemo(() => {
    if (
      returnAutoWorkFlow?.activeModalConfigDetails?.configureKey ===
        "antiRefundStrategies" ||
      returnAutoWorkFlow?.activeModalConfigDetails?.configureKey ===
        "antiRefundStrategiesOrders"
    ) {
      const arsReponse = getArsRespone(localState);
      dispatch(updateArsResponse({ arsResponse: arsReponse }));
    }
  }, [localState]);

  const [showPopover, setShowPopover] = useState(true);

  const additionalInfo = useMemo(
    () => localState.additionalInformation,
    [localState.additionalInformation],
  );
  const giveOffer = useMemo(() => localState.giveOffer, [localState.giveOffer]);
  const offerExchange = useMemo(
    () => localState.offerExchange,
    [localState.offerExchange],
  );

  const orderTypeId = useAppSelector(
    (state) =>
      state.antiRefundStrategiesOrders.antiRefundOrderTypesData
        .selectedOrderTypeId,
  );

  const allOptions = useMemo(
    () =>
      products.map((product) => {
        return {
          id: product.productId,
          imgSrc: product.imageUrl,
          label: product.productName,
          price: product.productPrice,
          value: product.productName,
        } as SelectOptionType;
      }),
    [products],
  );

  //will load the local state with redux state initially
  useEffect(() => {
    localDispatch({
      type: "UPDATE_LOCAL_STATE",
      payload: {
        scenario: scenario,
      },
    });
  }, []);

  const handleLoadOptions = useCallback(
    async (searchTerm: string, loadOptions: any, additional: any) => {
      searchTerm = searchTerm.trim();

      const res = await fetchProducts(Limit, searchTerm);

      if (!res) {
        return {
          options: [],
          hasMore: false,
          additional: {
            page: 0,
          },
        };
      }

      const options = res.data.map((product) => {
        return {
          id: product.productId,
          imgSrc: product.imageUrl,
          label: product.productName,
          price: product.productPrice,
          value: product.productName,
        } as SelectOptionType;
      });

      const meta = res.metaData;

      return {
        options: options,
        hasMore:
          meta?.total > parseInt(additional?.page ?? 0 + "") + options.length,
        additional: {
          page: additional ? additional.page + meta.count : meta.count,
        },
      };
    },
    [fetchProducts],
  );

  //Initialize showValidation state to set the validations for different fields
  const [showValidation, setShowValidation] = useState<{
    additionalInfoMessage: boolean;
    giveOfferMessage: boolean;
    givOfferOfferType: boolean;
    discountValue: boolean;
    giveOfferProductOrTag: boolean;
    offerExchangeMessage: boolean;
  }>({
    additionalInfoMessage: false,
    giveOfferMessage: false,
    givOfferOfferType: false,
    discountValue: false,
    giveOfferProductOrTag: false,
    offerExchangeMessage: false,
  });

  //Function execute when "cancel" button is clicked
  const handleCancel = useCallback(() => {
    localDispatch({
      type: "CLEAR_LOCAL_STATE_ADDITIONAL_INFO",
    });
    onHide();
  }, [localDispatch, onHide]);

  //Function execute when "save" button is clicked
  const handleSave = useCallback(() => {
    let shouldDispatch = false;

    if (
      additionalInfo?.enabled &&
      additionalInfo?.message &&
      additionalInfo?.message.length < 1
    ) {
      setShowValidation({
        ...showValidation,
        additionalInfoMessage: true,
      });
      pushTheToast({
        text: "Please enter a message for additional information",
        type: "warning",
        position: "top-right",
      });
      shouldDispatch = false; // Set the flag to false
      return; // Exit the loop
    }

    if (
      giveOffer?.enabled &&
      giveOffer?.message &&
      giveOffer?.message.length < 1
    ) {
      setShowValidation({
        ...showValidation,
        giveOfferMessage: true,
      });
      pushTheToast({
        text: "Please enter a message for give offer",
        type: "warning",
        position: "top-right",
      });
      shouldDispatch = false; // Set the flag to false
      return; // Exit the loop
    }

    if (giveOffer?.enabled && giveOffer?.offerType === null) {
      setShowValidation({
        ...showValidation,
        givOfferOfferType: true,
      });
      pushTheToast({
        text: "Please select an offer type",
        type: "warning",
        position: "top-right",
      });
      shouldDispatch = false; // Set the flag to false
      return; // Exit the loop
    }

    if (
      giveOffer?.enabled &&
      (giveOffer?.offerType === "discount_of" ||
        giveOffer.offerType === "partial_refund_of") &&
      (giveOffer?.offerValue === null ||
        giveOffer?.offerValue === 0 ||
        !giveOffer?.offerValue)
    ) {
      setShowValidation({
        ...showValidation,
        discountValue: true,
      });
      pushTheToast({
        text: "Please enter a discount value",
        type: "warning",
        position: "top-right",
      });
      shouldDispatch = false; // Set the flag to false
      return; // Exit the loop
    }

    if (
      giveOffer?.enabled &&
      giveOffer?.offerType === "offer_clearance_product_for_free" &&
      (giveOffer?.offerSelectedItems?.length ?? 0) < 1 &&
      (giveOffer?.offerSelectedTags?.length ?? 0) < 1
    ) {
      setShowValidation({
        ...showValidation,
        giveOfferProductOrTag: true,
      });
      pushTheToast({
        text: "Please select a product or tag",
        type: "warning",
        position: "top-right",
      });
      shouldDispatch = false; // Set the flag to false
      return; // Exit the loop
    }

    if (offerExchange?.enabled && offerExchange?.message.length < 1) {
      setShowValidation({
        ...showValidation,
        offerExchangeMessage: true,
      });
      pushTheToast({
        text: "Please enter a message for offer exchange",
        type: "warning",
        position: "top-right",
      });
      shouldDispatch = false; // Set the flag to false
      return; // Exit the loop
    }

    shouldDispatch = true; // Set the flag to true

    if (shouldDispatch) {
      if (question === "question11") {
        dispatch(
          updateAdditionalInfoConfig({
            // orderTypeId: orderTypeId,
            reasonId: reasonId,
            scenarioId: scenario.id,
            localState: localState,
          }),
        );
      }
      if (question === "question12") {
        dispatch(
          updateAdditionalInfoConfigOrderTypes({
            orderTypeId: orderTypeId ?? "",
            reasonId: reasonId,
            scenarioId: scenario.id,
            localState: localState,
          }),
        );
        dispatch(validateChanges());
      }
      onHide();
    }
  }, [
    additionalInfo?.enabled,
    additionalInfo?.message,
    dispatch,
    giveOffer?.enabled,
    giveOffer?.message,
    giveOffer?.offerSelectedItems?.length,
    giveOffer?.offerSelectedTags?.length,
    giveOffer?.offerType,
    giveOffer?.offerValue,
    localState,
    offerExchange?.enabled,
    offerExchange?.message.length,
    onHide,
    orderTypeId,
    question,
    reasonId,
    scenario.id,
    showValidation,
  ]);

  // For adding variables functioanlity
  const additionalMessageVariableRef = useRef(null as any);
  const offerExchangeMessageVariableRef = useRef(null as any);

  const handleAddVariableOnClick = useCallback(
    (placeholder: IVariable, isStage1: boolean) => {
      if (isStage1) {
        addVariableOnClick(placeholder, additionalMessageVariableRef);
      } else {
        addVariableOnClick(placeholder, offerExchangeMessageVariableRef);
      }
    },
    [],
  );

  useEffect(() => {
    if (showValidation.discountValue) {
      setShowPopover(true);
    } else {
      setShowPopover(false);
    }
  }, [showValidation.discountValue]);

  const popover = useMemo(
    () =>
      showValidation.discountValue ? (
        <Popover
          id="popover-basic"
          className="rounded-3"
        >
          <Popover.Body className="d-flex flex-row">
            <div className="border px-2 bg-warning text-white me-1 rounded-3 ">
              !
            </div>
            <div className="mt-1">Please enter a discont value.</div>
          </Popover.Body>
        </Popover>
      ) : (
        <></>
      ),
    [showValidation.discountValue],
  );

  return (
    <>
      <div
        className={`d-flex align-items-start w-100 ${styles.contentWrapper}`}
      >
        <div>
          <span
            className={`pe-3 d-block ${styles.leftArrow}`}
            onClick={onHide}
            role="button"
          >
            <i className="fa-solid fa-angle-left"></i>
          </span>
        </div>
        <div className="w-100 mt-1">
          <h3 className={`d-flex mb-0 align-items-center ${styles.heading}`}>
            <span className="pe-3 d-block">Choose anti - refund methods</span>
          </h3>
          <div>
            <p className={`${styles.subHeading}`}>
              Return/exchange reason: {returnReason}
            </p>
          </div>

          {/* Stage 1 */}
          <div className={`p-3 mb-3 ${styles.stageBox}`}>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                checked={additionalInfo?.enabled}
                onChange={(e) => {
                  // dispatch(
                  //   updateAdditionalInfo({
                  //     reasonId: reasonId,
                  //     scenarioId: scenario.id,
                  //     additionalInfo: {
                  //       enabled: e.target.checked,
                  //     },
                  //   })
                  // );
                  localDispatch({
                    type: "UPDATE_ADDITIONAL_INFO",
                    payload: {
                      reasonId: reasonId,
                      scenarioId: scenario.id,
                      additionalInfo: {
                        enabled: e.target.checked,
                      },
                    },
                  });
                }}
                id="additionalInfo"
              />
              <div>
                <label
                  className={`form-check-label mb-0 ${styles.labelText}`}
                  htmlFor="additionalInfo"
                >
                  Stage 1: Additional message / information
                </label>
                <span className={`d-block ${styles.labelSpan}`}>
                  Give additional information to change refund decision
                </span>

                {additionalInfo?.enabled && (
                  <div className="pt-1">
                    <div className="mb-3">
                      {/* <textarea
                        className={`form-control ${styles.textarea} ${
                          showValidation.additionalInfoMessage &&
                          "border border-danger"
                        } `}
                        id="exampleFormControlTextarea1"
                        placeholder={`Write your message for eg. "This item gets smaller after a wash . Are you sure you want to return this item "….`}
                        rows={3}
                        value={additionalInfo?.message}
                        onChange={(e) => {
                          setShowValidation({
                            ...showValidation,
                            additionalInfoMessage: false,
                          });
                          localDispatch({
                            type:"UPDATE_ADDITIONAL_INFO",
                            payload:{
                              reasonId: reasonId,
                              scenarioId: scenario.id,
                              additionalInfo: {
                                message: e.target.value,
                              },
                            }}
                          );
                        }}
                      ></textarea> */}
                      <MessageSection
                        updateOrder={(_id: string, msg: any) => {
                          const value = msg.messageForCustomer.value;
                          setShowValidation({
                            ...showValidation,
                            additionalInfoMessage: false,
                          });
                          localDispatch({
                            type: "UPDATE_ADDITIONAL_INFO",
                            payload: {
                              reasonId: reasonId,
                              scenarioId: scenario.id,
                              additionalInfo: {
                                message: value,
                              },
                            },
                          });
                        }}
                        message={{
                          value: additionalInfo.message,
                          error: showValidation.additionalInfoMessage
                            ? "Please enter Additional message/information."
                            : null,
                        }}
                        pushCurrentHash={() => {}}
                        validateTabType={() => {}}
                        id={scenario.id}
                        hideError={false}
                        placeholder={`Write your message for eg. "This item gets smaller after a wash . Are you sure you want to return this item "….`}
                        insertPlaceholderRef={additionalMessageVariableRef}
                        hideHeader={true}
                      />
                      {/* {showValidation.additionalInfoMessage && (
                        <span className={`${styles.validationStyles}`}>
                          Please enter Additional message/information.{" "}
                        </span>
                      )} */}
                    </div>
                    {/* Available Variables */}
                    {/* 14 Sep 2024 Hiding these as told by MS sir */}
                    {/* <Variables
                      numberOfItems={3}
                      handleAddVariableOnClick={(placeholder: IVariable) => {
                        handleAddVariableOnClick(placeholder, true);
                      }}
                    /> */}
                    <div className={`d-flex flex-row ms-4 mt-4`}>
                      {/* Input */}
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={additionalInfo?.extendReturnPeriod.enabled}
                        onChange={(e) => {
                          localDispatch({
                            type: "UPDATE_ADDITIONAL_INFO",
                            payload: {
                              reasonId: reasonId,
                              scenarioId: scenario.id,
                              additionalInfo: {
                                extendReturnPeriod: {
                                  ...additionalInfo?.extendReturnPeriod,
                                  enabled: e.target.checked,
                                },
                              },
                            },
                          });
                        }}
                      />

                      {/* Label */}
                      <div className={`${styles.arrowLabel} ms-1`}>
                        Offer to extend return period by{" "}
                      </div>

                      {/* Counter */}
                      <div
                        className={`${styles.arrowBox} d-flex flex-row ms-1`}
                      >
                        <div className={`${styles.timeSet}`}>
                          <span>{additionalInfo.extendReturnPeriod.days}</span>
                        </div>
                        <div className={`d-flex flex-column ms-2 mt-1`}>
                          <div
                            className={`${styles.arrow_up}`}
                            onClick={(e) => {
                              localDispatch({
                                type: "UPDATE_ADDITIONAL_INFO",
                                payload: {
                                  reasonId: reasonId,
                                  scenarioId: scenario.id,
                                  additionalInfo: {
                                    extendReturnPeriod: {
                                      ...additionalInfo?.extendReturnPeriod,
                                      days:
                                        additionalInfo?.extendReturnPeriod
                                          .days + 1,
                                    },
                                  },
                                },
                              });
                            }}
                          ></div>
                          <div
                            className={`${styles.arrow_down}`}
                            onClick={(e) => {
                              if (additionalInfo?.extendReturnPeriod.days === 1)
                                return;

                              localDispatch({
                                type: "UPDATE_ADDITIONAL_INFO",
                                payload: {
                                  reasonId: reasonId,
                                  scenarioId: scenario.id,
                                  additionalInfo: {
                                    extendReturnPeriod: {
                                      ...additionalInfo?.extendReturnPeriod,
                                      days:
                                        additionalInfo?.extendReturnPeriod
                                          .days - 1,
                                    },
                                  },
                                },
                              });
                            }}
                          ></div>
                        </div>
                      </div>

                      {/* Days */}
                      <div className={`${styles.arrowLabel} ms-1 `}>
                        <span>Day(s)</span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Stage 2  */}
          <div className={`p-3 mb-3 ${styles.stageBox}`}>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                checked={giveOffer?.enabled}
                onChange={(e) => {
                  localDispatch({
                    type: "UPDATE_GIVE_OFFER",
                    payload: {
                      reasonId: reasonId,
                      scenarioId: scenario.id,
                      giveOffer: {
                        enabled: e.target.checked,
                      },
                    },
                  });
                }}
                id="giveOffer"
              />
              <div>
                <label
                  className={`form-check-label mb-0 ${styles.labelText}`}
                  htmlFor="giveOffer"
                >
                  Stage 2: Give rewards/offers
                </label>
                <span className={`d-block ${styles.labelSpan}`}>
                  Give offers to cancel refunds
                </span>
                {giveOffer?.enabled && (
                  <div className="">
                    <span className={`${styles.reward}`}>Reward</span>
                    <div className="d-flex align-items-start align-items-md-center flex-column flex-md-row">
                      <Dropdown
                        bsPrefix=""
                        onSelect={(eventKey: any) => {
                          setShowValidation({
                            ...showValidation,
                            givOfferOfferType: false,
                          });

                          localDispatch({
                            type: "UPDATE_GIVE_OFFER",
                            payload: {
                              reasonId: reasonId,
                              scenarioId: scenario.id,
                              giveOffer: {
                                offerType: eventKey,
                              },
                            },
                          });
                        }}
                        role="menu"
                      >
                        <Dropdown.Toggle
                          as="div"
                          id="dropdown-basic"
                          bsPrefix={`dropdown-toggle ${styles.dropBtn} ${
                            showValidation.givOfferOfferType &&
                            "border border-danger"
                          }`}
                        >
                          <span className={`text-truncate ${styles.selName}`}>
                            {giveOffer.offerType === null
                              ? "Select offer type"
                              : giveOffer.offerType === "partial_refund_of"
                                ? "Offer partial refund of"
                                : giveOffer.offerType === "discount_of"
                                  ? "Offer future discount of"
                                  : giveOffer.offerType ===
                                      "offer_clearance_product_for_free"
                                    ? "Offer clearance product for free"
                                    : giveOffer.offerType === "other" &&
                                      "Other"}
                          </span>
                        </Dropdown.Toggle>
                        {showValidation.givOfferOfferType && (
                          <span className={`${styles.validationStyles}`}>
                            Please select an offer type.{" "}
                          </span>
                        )}

                        <Dropdown.Menu
                          bsPrefix={`dropdown-menu ${styles.dropMenu2}`}
                        >
                          <Dropdown.Item
                            className={`${styles.offerItem}`}
                            eventKey="partial_refund_of"
                          >
                            Offer partial refund of
                          </Dropdown.Item>
                          <Dropdown.Item
                            className={`${styles.offerItem}`}
                            eventKey="discount_of"
                          >
                            Offer future discount of
                          </Dropdown.Item>
                          <Dropdown.Item
                            className={`${styles.offerItem}`}
                            eventKey="offer_clearance_product_for_free"
                          >
                            Offer clearance product for free
                          </Dropdown.Item>
                          <Dropdown.Item
                            className={`${styles.offerItem}`}
                            eventKey="other"
                          >
                            Other
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>

                      {(giveOffer.offerType === "partial_refund_of" ||
                        giveOffer.offerType === "discount_of") && (
                        <>
                          {/* Value Section */}
                          <div
                            className={`d-flex justify-content-between position-relative mx-md-2 my-2 my-md-0 ${
                              styles.percentValue
                            } d-flex ${
                              showValidation.discountValue
                                ? "border border-danger"
                                : ""
                            }`}
                          >
                            <OverlayTrigger
                              show={showPopover}
                              placement="bottom"
                              overlay={popover}
                              rootClose={true}
                              rootCloseEvent="mousedown"
                              onToggle={(isShown) => {
                                // Set the state variable based on the popover visibility
                                setShowPopover(isShown);
                              }}
                            >
                              <div>
                                <input
                                  type="number"
                                  value={giveOffer.offerValue}
                                  className={`w-75 border-0 ps-2 ${styles.percentInput}`}
                                  placeholder="0"
                                  onChange={(e) => {
                                    setShowValidation({
                                      ...showValidation,
                                      discountValue: false,
                                    });

                                    localDispatch({
                                      type: "UPDATE_GIVE_OFFER",
                                      payload: {
                                        reasonId: reasonId,
                                        scenarioId: scenario.id,
                                        giveOffer: {
                                          value: e.target.value,
                                        },
                                      },
                                    });
                                  }}
                                />
                                <span
                                  style={{ position: "absolute", right: "7%" }}
                                >
                                  {giveOffer.offerUnit === "fixed" ? "$" : "%"}
                                </span>
                              </div>
                            </OverlayTrigger>
                          </div>

                          {/* Type Section */}
                          <Dropdown
                            bsPrefix=""
                            onSelect={(eventKey) => {
                              localDispatch({
                                type: "UPDATE_GIVE_OFFER",
                                payload: {
                                  reasonId: reasonId,
                                  scenarioId: scenario.id,
                                  giveOffer: {
                                    type: eventKey ?? "fixed",
                                  },
                                },
                              });
                            }}
                            role="menu"
                          >
                            <Dropdown.Toggle
                              as="div"
                              id="dropdown-basic"
                              bsPrefix={`dropdown-toggle ${styles.dropBtn2}`}
                            >
                              <span
                                className={`text-truncate ${styles.selName}`}
                              >
                                {giveOffer.offerUnit === "fixed"
                                  ? "USD"
                                  : "Percent"}
                              </span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu
                              bsPrefix={`dropdown-menu ${styles.dropMenu}`}
                            >
                              <div className={`${styles.formcheckWraper} mb-1`}>
                                <Dropdown.Item
                                  className={`${styles.offerItem}`}
                                  eventKey="fixed"
                                >
                                  USD
                                </Dropdown.Item>
                                <Dropdown.Item
                                  className={`${styles.offerItem}`}
                                  eventKey="percentage"
                                >
                                  Percent
                                </Dropdown.Item>
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        </>
                      )}
                    </div>

                    {/* Items And Tag Section */}
                    {giveOffer.offerType ===
                      "offer_clearance_product_for_free" && (
                      <div className="d-flex flex-row mt-3">
                        <CustomizeSelect
                          showValidation={showValidation.giveOfferProductOrTag}
                          id={scenario.id}
                          error={""}
                          condition={""}
                          updateCondition={(value: any) => {
                            setShowValidation({
                              ...showValidation,
                              giveOfferProductOrTag: false,
                            });

                            localDispatch({
                              type: "UPDATE_GIVE_OFFER",
                              payload: {
                                reasonId: reasonId,
                                scenarioId: scenario.id,
                                giveOffer: {
                                  offerSelectedItems: value,
                                },
                              },
                            });
                          }}
                          allOptions={allOptions}
                          intialSelectedOptions={
                            giveOffer.offerSelectedItems ?? []
                          }
                          loadOptions={handleLoadOptions}
                        />

                        <Tags
                          tags={giveOffer.offerSelectedTags}
                          isItemTag={true}
                          showValidation={showValidation.giveOfferProductOrTag}
                          onChange={(tags: any) => {
                            setShowValidation({
                              ...showValidation,
                              giveOfferProductOrTag: false,
                            });

                            localDispatch({
                              type: "UPDATE_GIVE_OFFER",
                              payload: {
                                reasonId: reasonId,
                                scenarioId: scenario.id,
                                giveOffer: {
                                  offerSelectedTags: tags,
                                },
                              },
                            });
                          }}
                        />
                      </div>
                    )}
                    {giveOffer.offerType ===
                      "offer_clearance_product_for_free" &&
                      showValidation.giveOfferProductOrTag && (
                        <span className={`${styles.validationStyles}`}>
                          Please select an item or a tag.{" "}
                        </span>
                      )}

                    {/* Message Section */}
                    <div className="mb-3 mt-2 ">
                      <span className={`${styles.reward}`}>Reward message</span>
                      <textarea
                        className={`form-control ${styles.textarea} ${
                          showValidation.giveOfferMessage &&
                          "border border-danger"
                        }`}
                        id="exampleFormControlTextarea1"
                        placeholder={`Add offer description`}
                        rows={3}
                        value={giveOffer?.message}
                        onChange={(e) => {
                          setShowValidation({
                            ...showValidation,
                            giveOfferMessage: false,
                          });

                          localDispatch({
                            type: "UPDATE_GIVE_OFFER",
                            payload: {
                              reasonId: reasonId,
                              scenarioId: scenario.id,
                              giveOffer: {
                                message: e.target.value,
                              },
                            },
                          });
                        }}
                      ></textarea>
                      {showValidation.giveOfferMessage && (
                        <span className={`${styles.validationStyles}`}>
                          Please enter Give rewards/offers message.{" "}
                        </span>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Stage 3 */}
          <div className={`p-3 mb-3 ${styles.stageBox}`}>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                checked={offerExchange?.enabled}
                onChange={(e) => {
                  localDispatch({
                    type: "UPDATE_OFFER_EXCHANGE",
                    payload: {
                      reasonId: reasonId,
                      scenarioId: scenario.id,
                      offerExchange: {
                        enabled: e.target.checked,
                      },
                    },
                  });
                }}
                id="offerExchange"
              />
              <div>
                <label
                  className={`form-check-label mb-0 ${styles.labelText}`}
                  htmlFor="offerExchange"
                >
                  Stage 3: Offer Exchange
                </label>
                <span className={`d-block ${styles.labelSpan}`}>
                  Offer to exchange instead of refund
                </span>
                {offerExchange?.enabled && (
                  <>
                    <div className="mb-3 mt-2 ">
                      <span className={`${styles.message}`}>Message</span>

                      <MessageSection
                        updateOrder={(_id: string, msg: any) => {
                          const value = msg.messageForCustomer.value;
                          setShowValidation({
                            ...showValidation,
                            offerExchangeMessage: false,
                          });
                          localDispatch({
                            type: "UPDATE_OFFER_EXCHANGE",
                            payload: {
                              reasonId: reasonId,
                              scenarioId: scenario.id,
                              offerExchange: {
                                message: value,
                              },
                            },
                          });
                        }}
                        message={{
                          value: offerExchange.message,
                          error: showValidation.offerExchangeMessage
                            ? "Please enter a message for offer exchange."
                            : null,
                        }}
                        pushCurrentHash={() => {}}
                        validateTabType={() => {}}
                        id={scenario.id}
                        hideError={false}
                        placeholder="Would you prefer"
                        insertPlaceholderRef={offerExchangeMessageVariableRef}
                        hideHeader={true}
                      />
                    </div>
                    {/* 14 Sep 2024 Hiding these as told by MS sir */}
                    {/* <Variables
                      numberOfItems={3}
                      handleAddVariableOnClick={(placeholder: IVariable) => {
                        handleAddVariableOnClick(placeholder, true);
                      }}
                    /> */}

                    <div className="mt-3 mb-4">
                      <p className={`${styles.offerHead}`}>
                        Offers customers to:
                      </p>
                      <div
                        className={`p-2 mb-2 d-flex justify-content-between ${styles.conditionBox}`}
                      >
                        <p className={`mb-0 p-2 ${styles.boxPara}`}>
                          Exchange with same items
                        </p>
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            onChange={(e) => {
                              localDispatch({
                                type: "UPDATE_OFFER_EXCHANGE",
                                payload: {
                                  reasonId: reasonId,
                                  scenarioId: scenario.id,
                                  offerExchange: {
                                    exchangeWithSameItems: e.target.checked,
                                  },
                                },
                              });
                            }}
                            checked={offerExchange?.exchangeWithSameItems}
                          />
                          <label
                            className={`form-check-label ${styles.enable}`}
                            htmlFor="flexSwitchCheckDefault"
                          >
                            {offerExchange?.exchangeWithSameItems
                              ? "Disable"
                              : "Enable"}
                          </label>
                        </div>
                      </div>
                      <div
                        className={`p-2 mb-2 d-flex justify-content-between ${styles.conditionBox}`}
                      >
                        <p className={`mb-0 p-2 ${styles.boxPara}`}>
                          Exchange with other items
                        </p>
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            onChange={(e) => {
                              localDispatch({
                                type: "UPDATE_OFFER_EXCHANGE",
                                payload: {
                                  reasonId: reasonId,
                                  scenarioId: scenario.id,
                                  offerExchange: {
                                    exchangeWithOtherItems: e.target.checked,
                                  },
                                },
                              });
                            }}
                            checked={offerExchange?.exchangeWithOtherItems}
                          />
                          <label
                            className={`form-check-label ${styles.enable}`}
                            htmlFor="flexSwitchCheckDefault"
                          >
                            {offerExchange?.exchangeWithOtherItems
                              ? "Disable"
                              : "Enable"}
                          </label>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`d-flex justify-content-end ${styles.btnWrapper}`}>
        <button
          className={`${styles.cancelBtn}`}
          onClick={handleCancel}
        >
          Cancel
        </button>
        <button
          className={`ms-3 ${styles.saveBtn}`}
          onClick={handleSave}
        >
          Save
        </button>
      </div>
    </>
  );
};

export default AntiRefundMethodModal;
