import { Channel } from "pusher-js";

export type newTicketEventCB = (res: any) => void;

export const getNewTicketEvent = (
  channel: Channel,
  callback: newTicketEventCB
) => {
  channel.bind("ticket_event_new_ticket", callback, {
    pauseOnInActiveTab: true,
  });
};

export const getNewTicketEventUnbind = (channel: Channel) => {
  channel.unbind("ticket_event_new_ticket");
};
