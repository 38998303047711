import TicketBtnDropDown from "../TicketBtnDropDown/TicketBtnDropDown";
import { ITicketBtn } from "../../SubSB";
import InfiniteScroll from "src/components/InfiniteScroll";
import {
  fetchSideBarUsersViews,
  ITicketSideBarState,
} from "src/store/slices/ticketSidebar/ticketSidebar.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { useEffect, useRef, useState } from "react";
import styles from "./TicketBtnModule.module.scss";
import { Spinner } from "react-bootstrap";

interface ITicketBtnModule extends ITicketBtn {
  keyIndex: string | number;
  selectedBtn: string;
}

function TicketBtnModule({
  keyIndex,
  header,
  margin,
  ticketBtnList,
  selectedBtn,
  paginate,
}: ITicketBtnModule) {
  const dispatch = useAppDispatch();
  // Initing load more button and setting false
  const [showLoadMore, setShowLoadMore] = useState(false);
  const {
    usersViewAjaxStatus,
    usersViewList,
    usersViewTotal,
  }: ITicketSideBarState = useAppSelector((state) => state.ticketSideBar);
  const userDataCurrent = useRef({
    usersViewAjaxStatus,
    usersViewList,
    usersViewTotal,
  });
  useEffect(() => {
    userDataCurrent.current = {
      usersViewAjaxStatus,
      usersViewList,
      usersViewTotal,
    };
  }, [usersViewAjaxStatus, usersViewList, usersViewTotal]);
  const handleInfiniteScroll = () => {
    if (userDataCurrent.current.usersViewAjaxStatus === "fulfilled") {
      // Setting load more button show false
      setShowLoadMore(false);
      dispatch(fetchSideBarUsersViews());
    }
  };

  // Effect to show the load more button again if loading done.
  useEffect(() => {
    if (usersViewAjaxStatus === "fulfilled") {
      setShowLoadMore(true);
    }
  }, [usersViewAjaxStatus]);

  return (
    // ${margin} is removed and updated 4 instead of ${margin}.
    <div className={`d-block dropdown-tickets mt-4`} id="ticketSidebar">
      <h2 className={`${styles.topHeading}`}>{header}</h2>
      <InfiniteScroll
        hasMore={
          paginate
            ? usersViewAjaxStatus === "rejected"
              ? false
              : usersViewList.length < usersViewTotal
            : false
        }
        initialDataLoaded={true}
        loader={
          <>
            {showLoadMore ? (
              // Rendering the load button conditionally.
              <div
                className={`${styles.loaderText}`}
                onClick={handleInfiniteScroll}
              >
                <span>Load More</span>
                <i className="ms-1 fa-solid fa-rotate-right"></i>
              </div>
            ) : (
              // Rendering the load text conditionally.
              <div className={`${styles.loadingText}`}>
                <span>Loading...</span>
                <div>
                  <Spinner
                    className={`ms-1 ${styles.spinner}`}
                    size="sm"
                    animation="border"
                  />
                </div>
              </div>
            )}
          </>
        }
        className={
          paginate
            ? `paginate pb-2 ${
                selectedBtn !== "all" && selectedBtn !== "my"
                  ? "viewActive"
                  : ""
              }`
            : "notPaginate"
        }
      >
        {ticketBtnList.map((data, key) => {
          return (
            <TicketBtnDropDown
              key={key}
              keyIndex={`${keyIndex}.${key}`}
              ticketBtnName={data.ticketBtnName}
              ticketBtnCount={data.ticketBtnCount}
              ddOuterList={data.ddOuterList}
              selectedBtn={selectedBtn}
              listViewFilters={data.listViewFilters}
              keyName={data.keyName}
            />
          );
        })}
      </InfiniteScroll>
    </div>
  );
}

export default TicketBtnModule;
