import { useEffect, useState } from "react";
import { getAxiosImage } from "src/services/GetFiles";
import loading from "src/assets/images/loading.gif";
import noData from "src/assets/images/no-data.png";

function AxiosImg({
  url,
  isLoaded = false,
  setIsLoaded = () => {},
  isDirectURL = false,
  ...props
}: {
  url: string;
  isLoaded?: boolean;
  isDirectURL?: boolean;
  setIsLoaded?: (IsLoading: boolean) => void;
  [key: string]: any;
}) {
  const [src, setSrc] = useState({ image: loading });

  useEffect(() => {
    // If isDirectURL is false, initiate the image loading process
    if (!isDirectURL) {
      setSrc({ image: loading }); // Set the source to the loading state
      setIsLoaded(false); // Set the loaded state to false

      getAxiosImage(url) //retrieves the image using Axios
        .then((res) => {
          setSrc(res); // Set the source to the received response (image)
          setIsLoaded(true); // Set the loaded state to true
        })
        .catch((err) => {
          setSrc({ image: noData }); // Set the source to a default value (noData) in case of error
          setIsLoaded(false); // Set the loaded state to false
          console.error(err);
        });
    } else {
      // If isDirectURL is true, set the source directly to the provided URL
      setIsLoaded(true); // Set the loaded state to true
      setSrc({ image: url }); // Set the source to the provided URL
    }
  }, [isDirectURL, url]);

  return <img src={src.image} {...props} />;
}

export default AxiosImg;
