import styles from "../TeamPage.module.scss";
import user from "src/assets/images/user.png";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { upperCaseEachFirst } from "src/utils/utils";
import { removeMemberFromTeam } from "src/services/Settings/Teams/removeMemberFromTeam";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import {
  fetchMembersInTeam,
  resetMembersInTeamData,
} from "src/store/slices/teamSettings/teamSettings.slice";
import { useCallback, useState } from "react";
import UserAvatar from "src/components/UserAvatar";
import AxiosImg from "src/components/AxiosImg";
import SettingToast from "src/routes/Setting/children/SettingToast/SettingToast";
import DeleteModal from "./DeleteModal/DeleteModal";

function TeamMember(props: any) {
  // get the user data
  const userData = props.userData;
  const dispatch = useAppDispatch();
  const { activeTeam } = useAppSelector((state) => state.teamSettings);
  const [disableRemoveBtn, setDisableRemoveBtn] = useState<boolean>(false);
  const [showModal, setShowModal] = useState(false);
  // function to remove member from team
  const handleRemoveMemberFromTeam = (e: any) => {
    e.preventDefault();
    setDisableRemoveBtn(true);
    // Todo: show removing loader
    removeMemberFromTeam({
      userId: props.userData.userId,
      teamId: activeTeam.teamId,
    })
      .then((res) => {
        setTimeout(() => {
          // reset the members in team data
          dispatch(resetMembersInTeamData());
          // fetch the members in team
          dispatch(fetchMembersInTeam(activeTeam.teamId));
          // pushTheToast({
          //   type: "success",
          //   text: "Removed User successfully !!",
          //   position: "top-right",
          // });
          pushTheToast({
            type: "success",
            text: SettingToast,
            customCompProps: { text: "Removed User successfully !!" },
            isTextComponent: true,
            position: "top-center",
          });
          setDisableRemoveBtn(true);
        }, 0);
      })
      .catch((err) => {
        setDisableRemoveBtn(false);
        pushTheToast({
          type: "danger",
          text: "Failed To Remove User!!",
          position: "top-right",
        });
      });
  };

  const onHide = useCallback(() => {
    setShowModal(false);
  }, []);
  const onShow = useCallback(() => {
    setShowModal(true);
  }, []);

  return (
    <div className={` ${styles.teamMemWrapper}`}>
      <div
        className={`d-flex mt-4 ${styles.teamsett}`}
        style={
          disableRemoveBtn === true
            ? { filter: "alpha(opacity=50)", opacity: "0.18" }
            : {}
        }
      >
        <div className={`${styles.memberInfoUp}`}>
          <div className="d-flex">
            <div>
              <div className={`${styles.status_image}  `}>
                {userData.profileImageUrl === null ? (
                  <UserAvatar
                    className={`rounded-circle img-fluid ${styles.memberImg} `}
                    name={`${
                      `${userData.name}`.trim()
                        ? `${userData.name}`
                        : userData.email
                    }`}
                    size={50}
                  />
                ) : (
                  <AxiosImg
                    className={`rounded-circle img-fluid ${styles.memberImg} `}
                    url={userData.profileImageUrl}
                    isDirectURL={userData?.isPublicAttachmentUrl}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={`${styles.name_email}`}>
                <div className={`${styles.name}`}>
                  {userData.name == "" ? "NA" : userData.name}
                </div>
                <div className={`${styles.email}`}>{userData.email}</div>
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles.memberStatusUp}`}>
          <div className={`${styles.user_status} user-status mt-3 `}>
            <div className={`d-flex justify-content-end align-items-center`}>
              <span className={`${styles.pendingText}`}>
                {userData.userStatus.statusName == null
                  ? "NA"
                  : upperCaseEachFirst(userData.userStatus.statusName)}
              </span>

              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={(props) => (
                  <Tooltip {...props}>Remove User from Team</Tooltip>
                )}
              >
                <i
                  className={`fa-solid fa-circle-minus ms-2 ${
                    disableRemoveBtn === false ? styles.removeIcon : ""
                  }`}
                  style={{ color: "#FF8499" }}
                  onClick={onShow}
                ></i>
              </OverlayTrigger>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={onHide}
        dialogClassName={`${styles.deleteDialog}`}
        contentClassName={`${styles.deleteContent}`}
        centered={false}
        enforceFocus={false}
      >
        <DeleteModal confirmText="Are you sure you want to remove the user from this team?" handleCancel={onHide} handleSubmit={handleRemoveMemberFromTeam} isLoading={disableRemoveBtn}/>
      </Modal>
    </div>
  );
}

export default TeamMember;
