import { axiosJSON } from "src/globals/axiosEndPoints";

const startSlackIntegrationService = async (payload: { brandId: string | number}) => {
  const { data: res } = await axiosJSON.post(
    "/api/setting/integration/slack/start",
    payload,
  );

  if (res.err || res.error) {
    throw new Error(res.msg ?? res.message ?? "Something went wrong!");
  }

  return res.url as string;
};

export default startSlackIntegrationService;
