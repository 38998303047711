import UserAvatar from "src/components/UserAvatar";
import { useAppSelector } from "src/store/store";

import styles from "./AllOrderHeader.module.scss";

function AllOrderHeader() {
  const { getOrderViewModel: order } = useAppSelector(
    (state) => state.singleOrder
  );

  return (
    <div className={`${styles.allOrderHeader} d-flex flex-wrap `}>
      {order && (
        <>
          <div className={`${styles.profileIcon}`}>
            <UserAvatar
              size={21}
              name={order?.customerData?.name ?? "N A"}
              className={`${styles.allOrderImg}`}
            />
            <span className={`${styles.profileName}`}>
              {order?.customerData?.name}
            </span>
          </div>

          <div className={`${styles.mailTo}`}>
            <span className={`${styles.messeanger}`}>
              <span>
                <i className={`fa-solid fa-envelope`}></i>
              </span>
              <span className={`ms-1`}>{order?.customerData?.email}</span>
            </span>
          </div>

          {order?.billingAddress?.phone ? (
            <div className={`${styles.callTo}`}>
              <span className={`${styles.messeanger}`}>
                <span>
                  <i className={`fa-solid fa-phone`}></i>
                </span>
                <span className={`ms-2`}>{order?.billingAddress?.phone}</span>
              </span>
            </div>
           ) : (
            "" 
           )} 
        </>
      )}
    </div>
  );
}
export default AllOrderHeader;
