import React, { useEffect, useState } from "react";
import styles from "./CreateCategory.module.scss";
import { Form, Spinner } from "react-bootstrap";
import { addCategory } from "src/services/Templates/addCategory";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
interface ITemplateCategory {
  id: number;
  name: string;
  subCategoryExists: boolean;
}

const CreateCategory = ({
  onHide,
  refreshCategories,
  searchedCategoryText,
  addCategoryToList,
}: {
  onHide: any;
  refreshCategories: any;
  searchedCategoryText: string;
  addCategoryToList: (category: ITemplateCategory) => void
}) => {
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [categoryText, setCategoryText] =
    useState<string>(searchedCategoryText);
  const [error, setError] = useState<string>("");

  function onAddCategory() {
    if (categoryText.trim().length === 0) {
      setError("template heading cannot be empty");
      return;
    }
    setShowLoader(true);
    addCategory({
      name: categoryText,
    })
      .then((data) => {
        pushTheToast({
          type: "success",
          text: "New Category Added!",
          position: "top-right",
        });
        setShowLoader(false);
        onHide();
        addCategoryToList({
          id: data.id,
          name: categoryText,
          subCategoryExists: false,
        });
        refreshCategories();
      })
      .catch((err) => {
        setShowLoader(false);
        pushTheToast({
          type: "danger",
          text: err.message,
          position: "top-right",
        });
      });
  }

  return (
    <div>
      <h3 className={`${styles.heading}`}>New Category</h3>
      <Form>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label className={`${styles.formLabel}`}>
            {/* Template Heading: */}
            Category Name
          </Form.Label>
          <Form.Control
            type="text"
            className={`${styles.inputBox} ${error ? styles.redBorder : ""}`}
            placeholder=""
            value={categoryText}
            onChange={(e) => {
              setCategoryText(e.target.value);
            }}
          />
          {error ? (
            <span className={`${styles.formLabel} text-danger`}>{error}</span>
          ) : null}
        </Form.Group>
        <div className="d-flex flex-column flex-md-row mt-1 justify-content-end align-items-center">
          <button
            className={`me-md-4 mb-2 mb-md-0 ${styles.cancelBtn}`}
            onClick={(e) => {
              e.preventDefault();
              onHide();
            }}
          >
            Cancel
          </button>
          <button
            className={`${styles.createBtn}`}
            onClick={(e) => {
              e.preventDefault();
              if (!showLoader) {
                onAddCategory();
              }
            }}
          >
            {showLoader === true ? (
              <>
                <Spinner
                  className="my-auto mx-1"
                  animation="border"
                  color="white"
                  size="sm"
                />
              </>
            ) : (
              <>Create</>
            )}
          </button>
        </div>
      </Form>
    </div>
  );
};

export default CreateCategory;
