import { axiosJSON } from "src/globals/axiosEndPoints";
import log from 'loglevel';

export type TicketStatus = {
  id: number,
  statusName: string,
}

export async function getAllStatuses() {
  const { data } = await axiosJSON.get(`/api/group/getTicketStatusList`, {
    params: { cacheIt: true, cacheTime: 3600000 },
  });
  if (data.err === true) {
    throw new Error(data.msg as string)
  }
  const normalizedData = normalizeData(data)
  // log.debug('fetch ticket statuss raw response:', data);
  // log.debug('fetch ticket statuss normalized response:', normalizedData);
  return normalizedData;
}

export type AllStatusesNormalizedData = {
  allTicketStatuses: { [key: string]: TicketStatus };
  allTicketStatusesIds: number[];
}

// convert incoming data to the required format
function normalizeData(response: any): AllStatusesNormalizedData {
  const statusesList = response.data
  const normalizedData: AllStatusesNormalizedData = {
    allTicketStatuses: {},
    allTicketStatusesIds: [],
  }
  statusesList.forEach((status: any) => {
    normalizedData.allTicketStatuses[`${status.id}`] = { id: status.id, statusName: status.statusName }
    normalizedData.allTicketStatusesIds.push(status.id)
  });
  return normalizedData
}