import styles from "./SendingInfo.module.scss";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { addCustomerApi } from "src/apis/addCustomer/addCustomer.insert.api";
import {
  ICustomerData,
  readCompanyEmails,
  readCustomerEmails,
} from "src/apis/addTicket/read/addTicket.read.api";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { validateEmail } from "src/utils/utils";
import SelectAsyncData from "src/components/ReactSelectUtilities/SecectAsyncData/SecectAsyncData";
import { Overlay } from "react-bootstrap";
import { POPPER_CONFIG } from "src/globals/constants";
import { GroupBase, StylesConfig } from "react-select";
import { SelectComponents } from "react-select/dist/declarations/src/components";

let prevAddUserCallBack: any = null;

function AddUser({
  addUser,
  addUserWithName,
  callback,
  setAddUserCallback,
  blueBtn = false,
  componentTitle = "",
  nameTitle = "",
  emailTitle = "",
  btnText = "",
}: any) {
  // console.log("Add user :: " + addUser);
  // console.log("Add addUserWithName :: " + addUserWithName);
  const [name, setName] = useState(addUserWithName);
  const [email, setEmail] = useState(addUser);
  const [loading, setLoading] = useState(false);
  const nameInputRef = useRef(null as any);
  const targetRef = useRef(null as any);
  const addCustomer = useCallback((name: any, email: any) => {
    if (!loading) {
      setLoading(true);
      addCustomerApi({ name, email })
        .then((data) => {
          callback({
            label: `${name} (${email})`,
            value: {
              id: data.id,
              name,
              email,
            },
          });
          setAddUserCallback({ show: false, value: null });
        })
        .catch((err) => {
          pushTheToast({
            type: "danger",
            text: err,
            position: "top-right",
          });
          setLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    if (prevAddUserCallBack) {
      prevAddUserCallBack({ show: false, value: null });
    }
    setTimeout(() => {
      prevAddUserCallBack = setAddUserCallback;
    }, 0);
    return () => {
      setTimeout(() => {
        prevAddUserCallBack = null;
      }, 0);
    };
  }, [false]);

  useEffect(() => {
    if (addUser) {
      setEmail(addUser);
    }
  }, [addUser]);
  useEffect(() => {
    if (addUserWithName) {
      setName(addUserWithName);
    }
  }, [addUserWithName]);

  useEffect(() => {
    nameInputRef.current.focus();
  }, []);

  // const onClickWindow = useCallback((e: any) => {
  //   if (!targetRef.current?.contains(e.target)) {
  //     setAddUserCallback({ show: false, value: null });
  //   }
  // }, []);

  // useEffect(() => {
  //   setTimeout(() => {
  //     window.addEventListener("click", onClickWindow);
  //   }, 0);
  //   return () => {
  //     window.removeEventListener("click", onClickWindow);
  //   };
  // }, [onClickWindow]);

  return (
    <div
      ref={targetRef}
      className={`${styles["add-ticket-user-name"]} p-3`}
    >
      {componentTitle && (
        <span className={`${styles.componentTitle} mb-2`}>
          {componentTitle}
        </span>
      )}
      <div
        className={`${styles["title-sec"]} ${
          nameTitle && styles.titleText
        } my-1`}
      >
        {nameTitle ? nameTitle : "Customer Name"}
      </div>
      <input
        ref={nameInputRef}
        id="sendInfoName"
        type="text"
        data-lpignore="true"
        className={`${styles["input-sec"]} p-1 w-100`}
        value={name}
        onChange={(e: any) => {
          setName(e.target.value);
        }}
      />
      <div
        className={`${styles["title-sec"]} ${
          emailTitle && styles.titleText
        } my-1`}
      >
        {emailTitle ? emailTitle : "Customer E-mail"}
      </div>
      <input
        type="text"
        id="sendInfoEmail"
        data-lpignore="true"
        className={`${styles["input-sec"]} p-1 w-100`}
        value={email}
        onChange={(e: any) => {
          setEmail(e.target.value);
        }}
      />
      <button
        className={`btn ${styles["add-btn"]} w-100 my-2 ${
          blueBtn && styles.btnBlueBg
        }`}
        id="sendInfoAddBtn"
        onClick={(e: any) => {
          e.preventDefault();
          addCustomer(name, email);
        }}
      >
        {loading ? (
          <div
            className={`d-flex justify-content-center ${styles["btn-load"]}`}
          >
            <div
              className={`spinner-grow spinner-grow-sm ${styles["w-small"]} my-auto mx-1`}
            ></div>
            <div
              className={`spinner-grow spinner-grow-sm ${styles["w-small"]} my-auto mx-1`}
            ></div>
            <div
              className={`spinner-grow spinner-grow-sm ${styles["w-small"]} my-auto mx-1`}
            ></div>
            <div
              className={`spinner-grow spinner-grow-sm ${styles["w-small"]} my-auto mx-1`}
            ></div>
          </div>
        ) : btnText ? (
          btnText
        ) : (
          "Add Customer"
        )}
      </button>
      <button
        className={`btn ${styles["cancel-btn"]} w-100  ${
          blueBtn && styles.btnBlueBorder
        }`}
        id="sendInfoCancelBtn"
        onClick={(e: any) => {
          e.preventDefault();
          setAddUserCallback({ show: false, value: null });
        }}
      >
        Cancel
      </button>
    </div>
  );
}

export interface SendingInfoLabel {
  imgURL?: null | string;
  name: string;
  email?: string;
  isPublicAttachmentUrl?: boolean;
}
interface Props {
  name: string;
  addUser?: boolean;
  isDisabled?: boolean;
  isMulti?: boolean;
  currentValue?: string;
  getCustomerName?: (name: string) => void;
  getValue?: (value: string) => void;
  placeholder?: string;
  getIdValue?: (value: string) => void;
  LabelComponent?: (props: SendingInfoLabel) => JSX.Element;
  initialFetchDone?: () => void;
  textToHighlight?: string;
  addUserZIndex?: number;
  canFetchInitial?: boolean; //flag to enable/disable the default initial fetch
  isError?: boolean;
  selectStylesObject?: StylesConfig<any, boolean, GroupBase<any>>;
  components?: Partial<SelectComponents<any, false, GroupBase<any>>>;
  blueBtn?: boolean;
  componentTitle?: string;
  nameTitle?: string;
  emailTitle?: string;
  btnText?: string;
}

const placeholders: any = {
  to: "Add recipient email",
  from: "Add company email",
  cc: "Add Cc- email",
  bcc: "Add Bcc- email",
};

function SendingInfo({
  name,
  addUser = false,
  isDisabled = false,
  isMulti = false,
  currentValue = "",
  getCustomerName = (name) => {},
  getValue = (value) => {},
  placeholder = "",
  getIdValue = (value) => {},
  LabelComponent,
  initialFetchDone = () => {},
  textToHighlight = "",
  addUserZIndex = 1055,
  canFetchInitial = true, //flag to enable/disable the default initial fetch, default set to enabled
  isError = false,
  selectStylesObject,
  components,
  blueBtn = false,
  componentTitle = "",
  nameTitle = "",
  emailTitle = "",
  btnText = "",
}: Props) {
  const formRef = useRef(null);
  const [fromEmailList, setFromEmailList]: any = useState([]);
  const [email, setEmail] = useState(currentValue);
  const [addUserCallback, setAddUserCallback] = useState({
    show: false,
    value: null,
  });

  const showOverlay = useMemo(() => {
    return addUserCallback.show &&
      addUserCallback.value &&
      addUser &&
      name !== "from"
      ? true
      : false;
  }, [addUserCallback, addUser, name]);

  const handleRemoveCustPopup = useCallback(
    (e: any) => {
      if (!showOverlay && prevAddUserCallBack) {
        prevAddUserCallBack({ show: false, value: null });
      }
    },
    [showOverlay],
  );

  const fetchTo = useCallback(
    async (searchTerm: string, loadOptions: any, additional: any) => {
      searchTerm = searchTerm.trim();
      if (searchTerm) {
        const limit = 25;
        const { data, metaData }: ICustomerData = await readCustomerEmails({
          searchTerm,
          start: additional ? additional.page : 0,
          limit,
        });
        const retValue: any = data.map((values, key) => {
          return {
            label: LabelComponent ? (
              <LabelComponent
                name={values.name}
                email={values.email}
              />
            ) : (
              `${values.name} (${values.email})`
            ),
            value: values,
          };
        });
        // console.log(data.map((values, key) => values.email));
        if (!data.map((values, key) => values.email).includes(searchTerm)) {
          if (validateEmail(searchTerm)) {
            // console.log("fetchTo searchTerm:: ", searchTerm);
            retValue.push({
              label: (
                <div
                  className={`${styles["add-ticket-user"]}`}
                >{`Add customer "${searchTerm}"`}</div>
              ),
              value: { id: 0, email: searchTerm, name: "" },
              component: true,
            });
          }
        }

        if (!data.map((values, key) => values.name).includes(searchTerm)) {
          if (!validateEmail(searchTerm)) {
            retValue.push({
              label: (
                <div
                  className={`${styles["add-ticket-user"]}`}
                >{`Add customer "${searchTerm}"`}</div>
              ),
              value: { id: 0, email: "", name: searchTerm },
              component: true,
            });
          }
        }
        return {
          options: retValue,
          hasMore: metaData.count === limit,
          additional: {
            page: additional
              ? additional.page + metaData.count
              : metaData.count,
          },
        };
      } else {
        return {
          options: [
            {
              label: "Type atleast one character",
              value: { id: 0, email: searchTerm, name: "" },
              disabled: true,
            },
          ],
          hasMore: false,
        };
      }
    },
    [],
  );
  const fetchCcBcc = useCallback(
    async (searchTerm: string, loadOptions: any, additional: any) => {
      searchTerm = searchTerm.trim();
      if (searchTerm) {
        const limit = 25;
        const { data, metaData }: ICustomerData = await readCustomerEmails({
          searchTerm,
          start: additional ? additional.page : 0,
          limit,
        });
        const retValue: any = data.map((values, key) => {
          return {
            label: LabelComponent ? (
              <LabelComponent
                name={values.name}
                email={values.email}
              />
            ) : (
              `${values.name} (${values.email})`
            ),
            value: values,
          };
        });
        return {
          options: retValue,
          hasMore: metaData.count === limit,
          additional: {
            page: additional
              ? additional.page + metaData.count
              : metaData.count,
          },
        };
      } else {
        return {
          options: [
            {
              label: "Type atleast one character",
              value: { id: 0, email: searchTerm, name: "" },
              disabled: true,
            },
          ],
          hasMore: false,
        };
      }
    },
    [],
  );
  const fetchFrom = useCallback(
    async (searchTerm: string, loadOptions: any, additional: any) => {
      searchTerm = searchTerm.trim().toLowerCase();
      const retValue: any = fromEmailList
        .filter(
          (value: any) =>
            value.name.search(searchTerm) !== -1 ||
            value.email.search(searchTerm) !== -1 ||
            searchTerm === "",
        )
        .map((values: any, key: number) => {
          return {
            label: LabelComponent ? (
              <LabelComponent
                imgURL={values.imageURL}
                name={values.name}
                email={values.email}
              />
            ) : (
              `${values.name} (${values.email})`
            ),
            value: values,
          };
        });
      return {
        options: retValue,
        hasMore: false,
      };
    },
    [fromEmailList],
  );
  useEffect(() => {
    const limit = 1000;
    if (name === "from" && canFetchInitial) {
      readCompanyEmails({
        searchTerm: "",
        start: 0,
        limit,
        onlyVerified: true,
      })
        .then(({ data, metaData }: ICustomerData) => {
          setFromEmailList(data);
          initialFetchDone();
        })
        .catch((err) => {});
    }
  }, [name, canFetchInitial]);
  useEffect(() => {
    getValue(email);
  }, [email]);
  useEffect(() => {
    setEmail(currentValue);
  }, [currentValue]);
  // useEffect(() => {
  //   setTimeout(() => {
  //     try {
  //       let x = (document as any).body.lastElementChild.firstElementChild;
  //       if (x.tagName === "IMG") {
  //         x.style.display = "none";
  //       }
  //     } catch {}
  //   }, 2500);
  // }, [false]);

  const customEditedStyles = useMemo(() => {
    // Spread to make a copy of customStyles
    const styles = selectStylesObject
      ? { ...selectStylesObject }
      : { ...customStyles };

    // If we have error reassign control with error styles
    if (isError) {
      styles.control = (provided: any, _: any) => ({
        ...provided,
        borderColor: "#FF0000",
        "&:active": {
          border: "1px solid #000000",
        },
        "&:hover": {
          border: "1px solid #FF0000",
        },
      });
    }

    return styles;
  }, [isError, selectStylesObject]);

  return (
    <>
      <input
        type="hidden"
        name={name}
        value={email}
        id="fromEmail"
      />
      <div
        ref={formRef}
        className={`w-100`}
        onClick={handleRemoveCustPopup}
      >
        <SelectAsyncData
          isMulti={isMulti}
          styles={customEditedStyles}
          currentValue={email}
          isDisabled={isDisabled}
          className={`w-100 border-danger`}
          placeholder={
            placeholder
              ? placeholder
              : placeholders[name]
                ? placeholders[name]
                : ""
          }
          fetchFunction={
            name === "from" ? fetchFrom : addUser ? fetchTo : fetchCcBcc
          }
          onChange={(newValueArr: any) => {
            // console.log(newValueArr);
            if (newValueArr.length) {
              const newValue = newValueArr[newValueArr.length - 1];

              if (newValue.addUser) {
                setAddUserCallback({ show: true, value: newValue });
              } else if (newValue.addUserWithName) {
                setAddUserCallback({ show: true, value: newValue });
              } else {
                setEmail(
                  newValueArr.map((data: any) => data.value.email).join(","),
                );
                getIdValue(
                  newValueArr.map((data: any) => data.value.id).join(","),
                );
                getCustomerName(
                  newValueArr.map((data: any) => data.value.name).join(","),
                );
              }
            } else {
              setEmail("");
              getIdValue("");
              getCustomerName("");
            }
          }}
          textToHighlight={textToHighlight}
          components={components}
        />
      </div>
      <Overlay
        target={formRef.current}
        show={showOverlay}
        popperConfig={POPPER_CONFIG}
        placement="bottom-start"
      >
        {({
          placement: _placement,
          arrowProps: _arrowProps,
          show: _show,
          popper: _popper,
          hasDoneInitialMeasure: _hasDoneInitialMeasure,
          ...props
        }) => (
          <div
            {...props}
            style={{
              zIndex: addUserZIndex,
              ...props.style,
            }}
          >
            {showOverlay && (
              <AddUser
                {...(addUserCallback.value as any)}
                setAddUserCallback={setAddUserCallback}
                blueBtn={blueBtn}
                componentTitle={componentTitle}
                nameTitle={nameTitle}
                emailTitle={emailTitle}
                btnText={btnText}
              />
            )}
          </div>
        )}
      </Overlay>
    </>
  );
}

const customStyles = {
  control: (provided: any, _: any) => ({
    ...provided,
    background: "#f5f5f5 0% 0% no-repeat padding-box",
    borderColor: "#9e9e9e",
    minHeight: "24px",
    width: "100%",
    border: "1px solid transparent",
    borderRadius: "8px",
    "&:active": {
      border: "1px solid #000000",
    },
  }),

  valueContainer: (provided: any, _: any) => ({
    ...provided,
    minHeight: "24px",
    padding: "0 6px",
    fontSize: "12px",
  }),

  input: (provided: any, _: any) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorsContainer: (provided: any, _: any) => ({
    ...provided,
    minHeight: "24px",
    padding: "0px !important",
    marginRight: "6px",
  }),
  dropdownIndicator: (provided: any, _: any) => ({
    ...provided,
    padding: "0px !important",
  }),
  MenuPortal: (provided: any, _: any) => ({
    ...provided,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 16px 48px #00000029",
    borderRadius: "10px",
    border: "1px solid #fff",
  }),
  option: (provided: any, _: any) => ({
    ...provided,
    textAlign: "left",
    font: "normal normal 500 12px/18px Poppins",
    letterSpacing: "0px",
    color: "#000000",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    padding: "6px 12px",
    "&:hover": {
      background: "#fff5f6 0% 0% no-repeat padding-box",
    },
  }),
  clearIndicator: (provided: any, _: any) => ({
    ...provided,
    padding: "0px !important",
  }),
  indicatorSeparator: (provided: any, _: any) => ({
    ...provided,
    marginBottom: "4px",
    marginTop: "4px",
    width: "1px",
    boxSizing: "border-box",
    marginRight: "6px",
  }),

  multiValueLabel: (provided: any, _: any) => ({
    ...provided,
    padding: "0",
    textAlign: "left",
    font: "normal normal normal 12px/18px Poppins",
    letterSpacing: "0px",
    color: "#707070",
    opacity: "1",
  }),
  multiValue: (provided: any, _: any) => ({
    ...provided,
    paddingRight: "2px",
    background: "white",
  }),
  placeholder: (provided: any) => ({ ...provided, fontSize: "12px" }),
};

export default SendingInfo;
