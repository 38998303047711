import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  addCustomerProfile,
  AddCustomerProfileParams,
} from "src/services/ShopifySidebar/EditCustomerDetails/addCustomerProfile";
import { editCustomer } from "src/services/ShopifySidebar/EditCustomerDetails/editCustomer";
import {
  removeCustomerProfile,
  RemoveCustomerProfileParams,
} from "src/services/ShopifySidebar/EditCustomerDetails/removeCustomerProfile";
import {
  setActiveCustomerName,
  updateSideBarTicketsCustomerName,
} from "../innerTicket/innerTicket.slice";
import {
  fetchCustomerData,
  ICustomerData,
  setCustomerName,
  setCustomerProfileImg,
} from "../shopifySidebar/shopifySidebar.slice";
import {
  editCustomerProfile,
  updateCustomerDetails,
  removeCustomerProfile as removeProfile,
} from "./editCustomerDetails.slice";
import { selectFile } from "src/utils/utils";
import { ATTACHEMENT_SUPPORTED_IMG_TYPES } from "src/components/QuillEditorContainer/children/AttachmentPicker/AttachmentPicker";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { actions } from "../liveChatSetting/chatWidExchCust/chatWidExchCust.slice";
import { updateCustomerInfo } from "../liveChatSetting/chatWidExchMsg/chatWidExchMsg.slice";

const editCustomerAsyncAction = createAsyncThunk(
  "editCustomerDetails/editCustomer",
  async function editCustomerThunk(
    payload: {
      customer: Omit<ICustomerData, "channels">;
    },
    { dispatch },
  ) {
    const res = await editCustomer(payload.customer);

    if (res.isUpdated) {
      dispatch(
        editCustomerProfile({
          customerProfileImageUrl:
            payload?.customer?.customerProfileImageUrl ?? "",
        }),
      );
      dispatch(updateCustomerDetails(payload.customer));
      dispatch(
        fetchCustomerData({
          includeTimeline: true,
        }),
      );
      if (payload.customer.name && payload.customer.name.length !== 0) {
        dispatch(setCustomerName({ name: payload.customer.name }));
        dispatch(
          setActiveCustomerName({ customerName: payload.customer.name }),
        );
        dispatch(
          updateSideBarTicketsCustomerName({
            customerId: parseInt(payload.customer.id + ""),
            name: payload.customer.name,
          }),
        );
      }
    }
  },
);

interface EditCustomProfilePayload {
  customerId: string;
}

const editCustomerProfileAsyncAction = createAsyncThunk(
  "editCustomerDetails/editCutomerProfile",
  async function editCustomerProfileThunk(
    payload: EditCustomProfilePayload,
    { dispatch },
  ) {
    //moved the file upload logic to thunk, file object cannot be serialized
    selectFile(ATTACHEMENT_SUPPORTED_IMG_TYPES as any, true)
      .then((selectResOri) => {
        let selectRes: any = selectResOri;

        let file: any = selectRes[0];

        addCustomerProfile({
          customerId: payload.customerId,
          profileImage: file,
        }).then((res) => {
          if (res) {
            dispatch(
              editCustomerProfile({
                customerProfileImageUrl: res.profileImageUrl,
              }),
            );
            dispatch(
              setCustomerProfileImg({
                url: res.profileImageUrl,
                id: res.profileImageId,
              } as any),
            );
            dispatch(
              updateCustomerInfo({
                customerId: payload?.customerId,
                customerInfo: {
                  imageURL: res.profileImageUrl,
                },
              }),
            );
            dispatch(
              actions.updateCustomerDataByCustomerId({
                customerId: payload.customerId,
                customer: {
                  imageURL: res.profileImageUrl,
                },
              }),
            );
          }
        });
      })
      .catch((err) => {
        if (err.status !== "cancelled") {
          pushTheToast({
            text: err.msg,
            position: "top-right",
            type: "danger",
          });
        }
      });

    return true;
  },
);

const removeCustomerProfileAsyncAction = createAsyncThunk(
  "editCustomerDetails/removeCutomerProfile",
  async function removeCustomerProfileThunk(
    payload: RemoveCustomerProfileParams & { customerProfileImageUrl: string },
    { dispatch },
  ) {
    const res = await removeCustomerProfile({ customerId: payload.customerId });
    if (res) {
      dispatch(
        removeProfile({
          customerProfileImageUrl: payload.customerProfileImageUrl,
        }),
      );
      dispatch(setCustomerProfileImg({ url: undefined, id: undefined } as any));
      dispatch(
        updateCustomerInfo({
          customerId: payload?.customerId,
          customerInfo: {
            imageURL: null,
          },
        }),
      );
      dispatch(
        actions.updateCustomerDataByCustomerId({
          customerId: payload.customerId,
          customer: {
            imageURL: null,
          },
        }),
      );
    }
  },
);

export default {
  editCustomerAsyncAction,
  editCustomerProfileAsyncAction,
  removeCustomerProfileAsyncAction,
};
