import { axiosJSON } from "src/globals/axiosEndPoints";

export interface TimelineSubEventsParam {
  customerId: string | number;
  cursor: string | null;
}

export async function getTimelineSubEvents(payload: TimelineSubEventsParam) {
  const { data: res } = await axiosJSON.post(
    `/api/customer/getTimelineSubEvents`,
    payload,
  );

  if (res.error || res.err) {
    throw new Error(res.msg ?? res.message ?? "Something went wrong!");
  }

  return res.data as any;
}
