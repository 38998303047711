import React, { useEffect, useRef, useState } from "react";
import { fetchTicketFiltersColData } from "src/store/slices/ticketFilters/ticketFilters.slice";
import { useAppDispatch } from "src/store/store";
import FilterList from "../FilterList";

const FilterMenu = React.forwardRef<any>(
  ({ style, className, "aria-labelledby": labeledBy }: any, ref, ...props) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
      dispatch(fetchTicketFiltersColData());
    }, []);

    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (ref) {
        if (typeof ref === "function") {
          ref(containerRef.current);
        } else {
          ref.current = containerRef.current;
        }
      }
    }, [ref]);

    return (
      <div
        ref={containerRef}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
        {...props}
      >
        <FilterList containerTarget={containerRef.current} />
      </div>
    );
  }
);

export default FilterMenu;
