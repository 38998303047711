import { useCallback, useState } from "react";
import { Spinner } from "react-bootstrap";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Lottie from "lottie-light-react";
import AxiosImg from "src/components/AxiosImg";
import UserAvatar from "src/components/UserAvatar";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import deleteReturnIntegrationService from "src/services/ReturnAutoWorkFlow/deleteReturnIntegration.service";
import { useReturnAutoWorkFlow } from "../../hooks/useReturnAutoWorkFlow";
import Delete from "src/assets/images/deleteAutomation.png";
import LiveDot from "src/assets/images/liveDot.json";
import styles from "./HeadingSection.module.scss";

/**
 * HeadingSection component.
 *
 * @returns Element for step heading.
 */
function HeadingSection() {
  const navigate = useNavigate();
  const { returnAutoWorkFlow } = useReturnAutoWorkFlow();
  const [showDeleteLoader, setShowDeleteLoader] = useState(false);

  // handle delete automation
  const handleDeleteAutomation = useCallback(() => {
    if (!showDeleteLoader) {
      setShowDeleteLoader(true);
      deleteReturnIntegrationService({
        integrationId: (returnAutoWorkFlow.integrationId ?? "") as string,
      })
        .then((res) => {
          pushTheToast({
            type: "success",
            text: "Deleted Successfully!",
            position: "top-right",
          });
          navigate(`/automations/return/`);
        })
        .catch((err) => {
          pushTheToast({
            type: "danger",
            text: "Failed to delete!",
            position: "top-right",
          });
        })
        .finally(() => {
          setShowDeleteLoader(false);
        });
    }
  }, [showDeleteLoader, returnAutoWorkFlow, navigate]);

  return (
    <div className={`d-flex w-100 flex-wrap ${styles.headingWrapper}`}>
      <div className="ms-1 me-2">
        <NavLink
          to={`/automations/return/`}
          className={`d-flex justify-content-center align-items-center ${styles.backChat}`}
        >
          <i className="fa-solid fa-arrow-left"></i>
        </NavLink>
      </div>
      <div className={`ms-0 me-auto`}>
        <div className={`mb-0 ${styles.heading}`}>Return/Exchange An Order</div>
        <div
          className={`${styles.info} d-flex align-items-center mt-2 mt-md-0 `}
        >
          {returnAutoWorkFlow.brand && returnAutoWorkFlow.brand.name && (
            <>
              {/* check for published status and display Live on text */}
              {returnAutoWorkFlow.published === true && (
                <>
                  <span
                    className="d-block me-1"
                    style={{
                      width: "20px",
                      height: "20px",
                      marginTop: "-18px",
                    }}
                  >
                    <Lottie animationData={LiveDot}></Lottie>
                  </span>{" "}
                  <span>Live on </span>
                </>
              )}
              {/* rendering brand image here, if exist, else rendering brand name image */}
              {returnAutoWorkFlow.brand.imageURL ? (
                <AxiosImg
                  className={`${styles.logo} mx-1 rounded-circle`}
                  url={returnAutoWorkFlow.brand.imageURL}
                />
              ) : (
                <UserAvatar
                  name={returnAutoWorkFlow.brand.name ?? "N A"}
                  size={17}
                  className="mx-1"
                />
              )}
              {returnAutoWorkFlow.brand.name}
            </>
          )}
        </div>
      </div>

      <div
        className="ms-auto me-0"
        onClick={handleDeleteAutomation}
      >
        <span
          className={`d-flex justify-content-center align-items-center cursor-pointer ${styles.deleteBtn}`}
        >
          {showDeleteLoader ? (
            <Spinner
              className="my-auto mx-2"
              animation="border"
              size="sm"
            />
          ) : (
            <img
              src={Delete}
              alt=" "
              className="me-2"
            />
          )}
          Delete automation
        </span>
      </div>
    </div>
  );
}

export default HeadingSection;
