/**
 * This file is the react component file.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 * @author Navjyot
 */

import ReactSelect from "react-select";
import styles from "../ReportInternalFilter.module.scss";
import { useCallback, useMemo } from "react";
import {
  reportInternalFiltersActions,
  useReportInternalFilters,
} from "src/routes/Report/hooks/reportFilters/useReportInternalFilters";
import { useQuery } from "@tanstack/react-query";
import { getRatingOptions } from "src/services/TicketService/getRatingOptions";

// Custom hook for managing the "Ratings" filter
const useIntDraftStatusFilter = () => {
  // The useIntDraftStatusFilter function is a custom hook that fetches the rating options using the useQuery hook from the @tanstack/react-query library.
  const { data } = useQuery(["ratings/getRatingOptions"], {
    queryFn: getRatingOptions,
    staleTime: 600000,
    cacheTime: 600000,
  });

  // Memoizing options for the React Select dropdown
  const options = useMemo(() => {
    return (
      data?.map((rating) => {
        return {
          label: rating.name,
          value: rating.id,
        };
      }) ?? []
    );
  }, [data]);

  // Accessing internal filters and dispatch function
  const { internalFilters, dispatch } = useReportInternalFilters();

  // Memoizing selected ratings based on internal filters
  const selectedRatings = useMemo(() => {
    return data?.length
      ? (
          data?.filter((rating) =>
            internalFilters.addedFilters.ratings?.includes(rating.id + "")
          ) ?? []
        ).map((rating) => {
          return {
            label: rating.name,
            value: rating.id,
          };
        })
      : [];
  }, [internalFilters, data]);

  // The onChangeDraftStatus function updates the internal filters state with the selected ratings.
  const onChangeDraftStatus = useCallback(
    (value: typeof selectedRatings) => {
      // Dispatching an action to update the internal filters
      dispatch([
        reportInternalFiltersActions.setFilters,
        { ratings: value.map((data) => data.value + "") },
      ]);
    },
    [selectedRatings]
  );

  // Returning options, selected ratings, and the callback
  return {
    options,
    selectedRatings,
    onChangeDraftStatus,
  };
};

// React component for the "Ratings" filter
function Ratings() {
  // Using the custom hook to get options, selected ratings, and the callback
  const { options, selectedRatings, onChangeDraftStatus } =
    useIntDraftStatusFilter();

  // Rendering the component UI
  return (
    <div className="px-2 pb-2">
      <label className={`${styles.labelText}`}>Ratings</label>
      {/* ReactSelect component is rendered with the appropriate props, including the options, selected values, and onChange event handler. */}
      <ReactSelect
        isMulti
        options={options}
        value={selectedRatings}
        onChange={onChangeDraftStatus as any}
        className={`${styles.text}`}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
            font: "normal normal normal 12px/18px Poppins",
            color: "#000000",
          }),
          multiValue: (base, { data }) => ({
            ...base,
            backgroundColor: '#F4F4F4', // Background color for selected values
            borderRadius: '6px',
            opacity: 1,
          }),
          multiValueLabel: (base) => ({
            ...base,
            font: "normal normal 500 12px/18px Poppins",
            letterSpacing: "0px",
            color: "#1F2223",
            paddingLeft:"12px",
          }),
          multiValueRemove: (base, { data }) => ({
            ...base,
            // color: '#000000', // Remove icon color for selected values
            fontSize: "6px",
            // ':hover': {
            //   backgroundColor: 'red', // Change the background color on hover if needed
            // },
          }),
        }}
      />
    </div>
  );
}

export default Ratings;
