import  { useState } from "react";


function useThemeSetting () {
  // States for ThemeSetting
  const [dropdown, setDropDown] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [resetModal, setResetModal] = useState(false);

  // On Show and Hide for ThemeSetting
  const onShow = (e: any) => {
    e.stopPropagation();
    setShowModal(true);
  };
  const onHide = () => {
    setShowModal(false);
  };

  // Show and Hide for ResetModal
  const showResetModal = (e: any) => {
    e.stopPropagation();
    setResetModal(true);
  };
  const hideResetModal = () => {
    setResetModal(false);
  };

  
  return {
    dropdown,
    setDropDown,
    showModal,
    setShowModal,
    resetModal,
    setResetModal,
    onShow,
    onHide,
    showResetModal,
    hideResetModal,
  }
}

export default useThemeSetting