import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Installation from "./Installation";
import InstallationFailed from "./InstallationFailed";
import InstallationLoading from "./InstallationLoading";
import styles from "./ToastModals.module.scss";

export type ModalTypes = "" | "Installation";

interface Props {
  showModal: ModalTypes;
  setShowModal: (show: ModalTypes | ((show: ModalTypes) => void)) => void;
  loading: boolean;
  success: boolean;
  callAgain: () => void;
}

interface Params {
  showModal: ModalTypes;
  loading: boolean;
  success: boolean;
}

const getCurrentModal = ({ showModal, loading, success }: Params) => {
  switch (showModal) {
    case "Installation":
      if (loading) {
        return InstallationLoading;
      } else if (success) {
        return Installation;
      } else {
        return InstallationFailed;
      }
    default:
      return () => <></>;
  }
};

function ToastModals({
  showModal,
  setShowModal,
  loading,
  success,
  callAgain,
}: Props) {
  const onHide = () => {
    if (!loading) {
      setShowModal("");
    }
  };

  const CurrentModal = getCurrentModal({ showModal, loading, success });

  return (
    <Modal
      show={showModal ? true : false}
      onHide={onHide}
      centered
      dialogClassName={`${styles.modalDialog}`}
      backdropClassName={`${styles.modalBack}`}
      contentClassName={`${styles.modalContent}`}
    >
      <CurrentModal callAgain={callAgain} onHide={onHide} />
    </Modal>
  );
}

export default ToastModals;
