import styles from "./Generalinfo.module.scss";
import UserAvatar from "src/components/UserAvatar";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import AxiosImg from "src/components/AxiosImg";
import InputFieldsContextProvider from "src/routes/Ticket/children/CustomerSB/children/CustomerInfo/Children/CustomerEdit/InputFieldsContextProvider";
import { useEffect, useState } from "react";
import CustomerEdit from "src/routes/Ticket/children/CustomerSB/children/CustomerInfo/Children/CustomerEdit/CustomerEdit";
import {
  ICustomerData,
  fetchCustomerData,
  setCustomerId,
} from "src/store/slices/shopifySidebar/shopifySidebar.slice";
import copy11 from "src/assets/images/copy1.png";
import envelop from "src/assets/images/envelop.png";
import insta from "src/assets/images/insta-new.png";
import messenger from "src/assets/images/messenger-new.png";
import twitterImage from "src/assets/images/twitter.png";
import call from "src/assets/images/call-new.png";
import {
  ChannelType,
  PhoneNumberData,
} from "src/services/LiveChat/startNewChat/startNewChat.services";
import {
  IChatChannelDetail,
  LiveCustomerMeta,
} from "src/services/LiveChat/messageExchange/getAllCustomers";
import Loader from "src/components/Loader";
import BotProfileInfo from "../MainChat/Children/BotProfileInfo/BotProfileInfo";
import whasappIcon from "src/assets/images/Whastapp.svg";
import userLocation from "src/assets/images/location.svg";
import { EChannel } from "src/enums/EChannel";
interface GeneralInfoProps {
  customerId?: string;
  customerEmail?: string;
  imageURL?: string | null;
  isPublicAttachmentUrl?: boolean;
  customerName?: string;
  chatChannelType?: EChannel;
  chatChannelToDetail?: PhoneNumberData | IChatChannelDetail;
  customerMeta?: LiveCustomerMeta;
  isSearchedCustomer?: boolean;
}

const Generalinfo = ({
  customerId,
  customerEmail,
  customerName,
  imageURL,
  isPublicAttachmentUrl,
  chatChannelType,
  chatChannelToDetail,
  customerMeta,
  isSearchedCustomer = false,
}: GeneralInfoProps) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    // This is for fetching customer data when customer is changed
    dispatch(setCustomerId({ id: Number(customerId) }));
    dispatch(fetchCustomerData());
  }, [customerId]);

  const [showModal, setShowModal] = useState(false); // This is for edit customer modal

  const onShow = (e: any) => {
    e.stopPropagation();
    setShowModal(true);
    // console.log("show");
  };
  const onHide = () => {
    setShowModal(false);
    // console.log("hide");
  };

  const { customerDataStatus, customerData } = useAppSelector(
    (state) => state.shopifySidebar,
  );

  const emailSC = customerData?.email;

  const nameSC = customerData?.name;

  const profileImageUrlSC = customerData?.customerProfileImageUrl;

  const isPublicUrlSC = customerData?.isPublicAttachmentUrl;

  const imageUrl = isSearchedCustomer ? profileImageUrlSC : imageURL;

  const isPublicAttachment = isSearchedCustomer
    ? isPublicUrlSC
    : isPublicAttachmentUrl;

  const name = isSearchedCustomer ? nameSC : customerName;

  // Extract the primary email from the activeCustomerInfo object and trim any leading or trailing whitespace
  const primaryMail = isSearchedCustomer ? emailSC : customerEmail?.trim();

  // Extract the otherEmails property from the customerData.contacts object in the Redux store
  const otherEmails = customerData?.contacts?.otherEmails;

  // Extract the phone property from the customerData.contacts object in the Redux store
  const phone = customerData?.contacts?.phone;

  // Extract the instagram property from the customerData.contacts object in the Redux store
  const instagram = customerData?.contacts?.instagram;

  // Extract the facebook property from the customerData.contacts object in the Redux store
  const facebook = customerData?.contacts?.facebook;

  // Extract the twitter property from the customerData.contacts object in the Redux store
  const twitter = customerData?.contacts?.twitter;

  // Extract the whatsapp property from the customerData.contacts object in the Redux store
  const whatsapp = customerData?.contacts?.whatsapp;

  // State variables to control the visibility of popovers for email, phone, Instagram, Facebook, and Twitter
  const [showPopOverEmail, setShowPopoverEmail] = useState(false);
  const [showPopOverPhone, setShowPopoverPhone] = useState(false);
  const [showPopOverInsta, setShowPopoverInsta] = useState(false);
  const [showPopOverFB, setShowPopoverFB] = useState(false);
  const [showPopOverTwt, setShowPopoverTwt] = useState(false);

  // Event handlers for mouse enter and leave events for email popover
  function handleMouseEnterEmail() {
    setShowPopoverEmail(true);
  }
  function handleMouseLeaveEmail() {
    setShowPopoverEmail(false);
  }

  // Event handlers for mouse enter and leave events for phone popover
  function handleMouseEnterPhone() {
    setShowPopoverPhone(true);
  }
  function handleMouseLeavePhone() {
    setShowPopoverPhone(false);
  }

  // Event handlers for mouse enter and leave events for Instagram popover
  function handleMouseEnterInsta() {
    setShowPopoverInsta(true);
  }
  function handleMouseLeaveInsta() {
    setShowPopoverInsta(false);
  }

  // Event handlers for mouse enter and leave events for Facebook popover
  function handleMouseEnterFB() {
    setShowPopoverFB(true);
  }
  function handleMouseLeaveFB() {
    setShowPopoverFB(false);
  }

  // Event handlers for mouse enter and leave events for Twitter popover
  function handleMouseEnterTwt() {
    setShowPopoverTwt(true);
  }
  function handleMouseLeaveTwt() {
    setShowPopoverTwt(false);
  }

  // State variable to track if the text has been copied
  const [copied, setCopied] = useState(false);

  // Function to handle copying text to clipboard
  const handleCopy = (copyMe: string) => {
    window.navigator.clipboard.writeText(copyMe);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  // Show a loader if still loading customer data
  if (customerDataStatus === "pending") {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <div>
      {/* Section One - Customer Details */}
      <div className={`mb-2 ${styles.generalInfo} pb-2`}>
        <div className={`${styles.infoMain}`}>
          <h3 className={`${styles.infoLead}`}>General Info</h3>

          {/* Customer Info */}
          <div
            className={`d-flex mb-3 flex-md-wrap flex-lg-nowrap`}
            style={{ overflow: "hidden", flexWrap: "wrap" }}
          >
            {imageUrl ? (
              <AxiosImg
                className={`rounded-circle ${styles.profileImage}`}
                url={imageUrl}
                isDirectURL={isPublicAttachment}
              />
            ) : (
              <div>
                <UserAvatar
                  name={
                    name?.trim()
                      ? name
                      : primaryMail?.trim()
                        ? primaryMail
                        : "N A"
                  }
                  size={20}
                />
              </div>
            )}
            <div
              className={`px-2 pt-1 w-100`}
              style={{ overflow: "hidden" }}
            >
              <div className={`${styles.infoName}`}>
                {name?.trim() ? name : "NA"}
              </div>
              {primaryMail?.trim() ? (
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip className={` ${styles.toolTipCustom}`}>
                      {primaryMail?.trim() ? primaryMail : "NA"}
                    </Tooltip>
                  }
                >
                  {({ ref, ...triggerHandler }) => (
                    <div
                      {...triggerHandler}
                      ref={ref}
                      className={`${styles.infoEmail}`}
                    >
                      {primaryMail?.trim() ? primaryMail : "NA"}
                    </div>
                  )}
                </OverlayTrigger>
              ) : null}
            </div>
          </div>

          {/* Whatsapp Detail */}
          {/* {true && (
            <div className={`mb-1 ${styles.infoEmail}`}>
              <span>
                <i
                  className={`fa-brands fa-whatsapp fa-lg ${styles.whatsappIcon} `}
                ></i>
              </span>
              <span className={`ms-1`}>{true ? `(415) 555‑0132` : "NA"}</span>
            </div>
          )} */}

          {/* Whatsapp Number */}
          {chatChannelType && chatChannelType === "whatsapp" && (
            <div className={`mb-1 d-flex align-items-center`}>
              <span
                className={`px-1 ${styles.mediaBox} ${styles.whatsappIcon}`}
              >
                <img
                  src={whasappIcon}
                  alt="whatsapp"
                  width={13}
                  height={13}
                />
              </span>
              <span className={`ms-1 ${styles.whatsappText}`}>
                {chatChannelToDetail &&
                "phoneNumber" in chatChannelToDetail &&
                chatChannelToDetail.phoneNumber
                  ? chatChannelToDetail.phoneNumber
                  : "NA"}
              </span>
            </div>
          )}

          {isSearchedCustomer && whatsapp && whatsapp.length > 0 ? (
            <>
              {whatsapp.map((number) => {
                return (
                  <div className={`mb-1 d-flex align-items-center`}>
                    <span
                      className={`p-1 ${styles.mediaBox} ${styles.whatsappIcon}`}
                    >
                      <i className="fa-brands fa-whatsapp fa-sm"></i>
                    </span>
                    <span className={`ms-1 ${styles.whatsappText}`}>
                      {number.phoneNumber}
                    </span>
                  </div>
                );
              })}
            </>
          ) : (
            <></>
          )}

          {/* Customer Location */}
          <div className="mb-2">
            {customerMeta?.customerLocation?.countryName ? (
              <p className={`${styles.country}`}>
                <span className={`ms-1 me-2`}>
                  <img
                    src={userLocation}
                    alt=""
                    width={7}
                    height={10}
                  />
                </span>
                <span>
                  {customerMeta.customerLocation.countryCode
                    ? customerMeta.customerLocation.countryCode + " - "
                    : ""}
                  {customerMeta.customerLocation.countryName}
                </span>
              </p>
            ) : (
              <></>
            )}
            {/* <p className={`mb-0 ${styles.state}`}>
              <span className={`me-1`}>
                <i className="fa-solid fa-earth-asia"></i>
              </span>
              <span> 3:33 pm, North America</span>
            </p> */}
          </div>
        </div>
        <div className="">
          {/* This section is hide fro now 10/10/2022 @Navjyot  */}
          {/* <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d387190.279909073!2d-74.25987368715491!3d40.69767006458873!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sin!4v1652512200686!5m2!1sen!2sin"
            width="100%"
            height="124"
            style={{ border: "0" }}
            loading="lazy"
          ></iframe> */}
        </div>

        {/* Customer Info */}

        {/* Updated ui for customer info */}
        <div
          className={`d-flex justify-content-center align-items-center p-2 d-none`}
        >
          {/* Mail Icon */}
          {(primaryMail && primaryMail.trim()) ||
          (otherEmails && otherEmails.length > 0) ? (
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 1000 }}
              overlay={
                <Tooltip
                  className={`override-white px-3 py-2  ${styles.toolTipCustom}`}
                  onMouseEnter={handleMouseEnterEmail}
                  onMouseLeave={handleMouseLeaveEmail}
                >
                  <div className={``}>
                    {/* Primary Mail */}
                    {primaryMail && primaryMail.trim() ? (
                      <div className={`mb-1 px-2 pt-2 pb-1`}>
                        <h6
                          className={`d-flex justify-content-start mb-1 ${styles.tipHead}`}
                        >
                          Primary email:
                        </h6>
                        <div
                          className={`d-flex align-items-center justify-content-between`}
                        >
                          <span className={`${styles.tipSpan} text-truncate`}>
                            {primaryMail}
                          </span>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip className={` ${styles.toolTipCustom} `}>
                                {copied ? "Copied" : "Copy"}
                              </Tooltip>
                            }
                          >
                            {({ ref, ...triggerHandler }) => (
                              <div
                                className={`ms-0 me-0`}
                                {...triggerHandler}
                                ref={ref}
                              >
                                <div
                                  className={`d-flex justify-content-center align-items-center mx-2 ${styles.customInfo} ${styles.customInfo3}`}
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  onClick={() => {
                                    handleCopy(primaryMail || "");
                                  }}
                                >
                                  <img
                                    src={copy11}
                                    alt=""
                                    className={`${styles.editLogo}`}
                                  />
                                </div>
                              </div>
                            )}
                          </OverlayTrigger>
                        </div>
                      </div>
                    ) : null}

                    {/* Other Mail */}

                    {otherEmails !== undefined && otherEmails.length > 0 ? (
                      <div className={`mb-2 px-2 pt-2 pb-1`}>
                        <h6
                          className={`d-flex justify-content-start  mb-1 ${styles.tipHead}`}
                        >
                          Other emails:
                        </h6>
                        {otherEmails.map((mail) => {
                          return (
                            <div
                              className={`d-flex align-items-center justify-content-between mb-2`}
                            >
                              <span
                                className={`${styles.tipSpan} text-truncate`}
                              >
                                {mail}
                              </span>
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip
                                    className={` ${styles.toolTipCustom}`}
                                  >
                                    {copied ? "Copied" : "Copy"}
                                  </Tooltip>
                                }
                              >
                                {({ ref, ...triggerHandler }) => (
                                  <div
                                    className={`ms-0 me-0`}
                                    {...triggerHandler}
                                    ref={ref}
                                  >
                                    <div
                                      className={`d-flex justify-content-center align-items-center mx-2 ${styles.customInfo} ${styles.customInfo3}`}
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                      onClick={() => {
                                        handleCopy(mail);
                                      }}
                                    >
                                      <img
                                        src={copy11}
                                        alt=""
                                        className={`${styles.editLogo}`}
                                      />
                                    </div>
                                  </div>
                                )}
                              </OverlayTrigger>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Tooltip>
              }
              show={showPopOverEmail}
            >
              {({ ref, ...triggerHandler }) => (
                <div
                  className={`ms-0 me-0`}
                  {...triggerHandler}
                  ref={ref}
                >
                  <div
                    className={`d-flex justify-content-center align-items-center ${styles.customInfo} ${styles.customInfo2} mx-2`}
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    onMouseEnter={handleMouseEnterEmail}
                    onMouseLeave={handleMouseLeaveEmail}
                  >
                    <img
                      src={envelop}
                      alt=""
                      className={`${styles.envelop}`}
                    />
                  </div>
                </div>
              )}
            </OverlayTrigger>
          ) : null}

          {/* Phone Icon */}

          {phone && phone?.length > 0 ? (
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip
                  className={`override-white px-3 py-2  ${styles.toolTipCustom}`}
                  onMouseEnter={handleMouseEnterPhone}
                  onMouseLeave={handleMouseLeavePhone}
                >
                  {phone !== undefined && phone.length > 0 ? (
                    <div className={``}>
                      {phone.map((phone) => {
                        return (
                          <div className={`mb-2 px-2 pt-2`}>
                            <div
                              className={`d-flex align-items-center justify-content-between mb-2`}
                            >
                              <span
                                className={`${styles.tipSpan} text-truncate`}
                              >
                                {phone.phoneNumber}
                              </span>
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip
                                    className={` ${styles.toolTipCustom}`}
                                  >
                                    {copied ? "Copied" : "Copy"}
                                  </Tooltip>
                                }
                              >
                                {({ ref, ...triggerHandler }) => (
                                  <div
                                    className={`ms-0 me-0`}
                                    {...triggerHandler}
                                    ref={ref}
                                  >
                                    <div
                                      className={`d-flex justify-content-center align-items-center mx-2 ${styles.customInfo} ${styles.customInfo3}`}
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                      onClick={() => {
                                        handleCopy(
                                          `${phone.countryCode} ${phone.phoneNumber}`,
                                        );
                                      }}
                                    >
                                      <img
                                        src={copy11}
                                        alt=""
                                        className={`${styles.editLogo}`}
                                      />
                                    </div>
                                  </div>
                                )}
                              </OverlayTrigger>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    ""
                  )}
                </Tooltip>
              }
              show={showPopOverPhone}
            >
              {({ ref, ...triggerHandler }) => (
                <div
                  className={`ms-0 me-0`}
                  {...triggerHandler}
                  ref={ref}
                >
                  <div
                    className={`d-flex justify-content-center align-items-center ${styles.customInfo} ${styles.customInfo2} mx-2`}
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    onMouseEnter={handleMouseEnterPhone}
                    onMouseLeave={handleMouseLeavePhone}
                  >
                    <img
                      src={call}
                      alt=""
                      className={`${styles.editLogo}`}
                    />
                  </div>
                </div>
              )}
            </OverlayTrigger>
          ) : (
            ""
          )}

          {/* Insta Icon */}
          {instagram && instagram.length > 0 ? (
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip
                  className={`override-white px-3 py-2  ${styles.toolTipCustom}`}
                  onMouseEnter={handleMouseEnterInsta}
                  onMouseLeave={handleMouseLeaveInsta}
                >
                  <div className={``}>
                    {instagram.map((ig) => {
                      return (
                        <div className={`mb-2 px-2 pt-1`}>
                          <div
                            className={`d-flex align-items-center justify-content-between mb-2`}
                          >
                            <span className={`${styles.tipSpan} text-truncate`}>
                              {ig}
                            </span>

                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip className={` ${styles.toolTipCustom}`}>
                                  {copied ? "Copied" : "Copy"}
                                </Tooltip>
                              }
                            >
                              {({ ref, ...triggerHandler }) => (
                                <div
                                  className={`ms-0 me-0`}
                                  {...triggerHandler}
                                  ref={ref}
                                >
                                  <div
                                    className={`d-flex justify-content-center align-items-center mx-2 ${styles.customInfo} ${styles.customInfo3}`}
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    onClick={() => {
                                      handleCopy(ig);
                                    }}
                                  >
                                    <img
                                      src={copy11}
                                      alt=""
                                      className={`${styles.editLogo}`}
                                    />
                                  </div>
                                </div>
                              )}
                            </OverlayTrigger>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Tooltip>
              }
              show={showPopOverInsta}
            >
              {({ ref, ...triggerHandler }) => (
                <div
                  className={`ms-0 me-0`}
                  {...triggerHandler}
                  ref={ref}
                >
                  <div
                    className={`d-flex justify-content-center align-items-center ${styles.customInfo} ${styles.customInfo2} mx-2`}
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    onMouseEnter={handleMouseEnterInsta}
                    onMouseLeave={handleMouseLeaveInsta}
                  >
                    <img
                      src={insta}
                      alt=""
                      className={`${styles.editLogo}`}
                    />
                  </div>
                </div>
              )}
            </OverlayTrigger>
          ) : (
            ""
          )}

          {/* Messenger Icon */}
          {facebook && facebook.length > 0 ? (
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip
                  className={`override-white px-3 py-2  ${styles.toolTipCustom}`}
                  onMouseEnter={handleMouseEnterFB}
                  onMouseLeave={handleMouseLeaveFB}
                >
                  <div className={``}>
                    {facebook.map((fb) => {
                      return (
                        <div className={`mb-2 px-2 pt-1`}>
                          <div
                            className={`d-flex align-items-center justify-content-between mb-2`}
                          >
                            <span className={`${styles.tipSpan} text-truncate`}>
                              {fb}
                            </span>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip
                                  className={` Copy ${styles.toolTipCustom}`}
                                >
                                  {copied ? "Copied" : "Copy"}
                                </Tooltip>
                              }
                            >
                              {({ ref, ...triggerHandler }) => (
                                <div
                                  className={`ms-0 me-0`}
                                  {...triggerHandler}
                                  ref={ref}
                                >
                                  <div
                                    className={`d-flex justify-content-center align-items-center mx-2 ${styles.customInfo} ${styles.customInfo3}`}
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    onClick={() => {
                                      handleCopy(fb);
                                    }}
                                  >
                                    <img
                                      src={copy11}
                                      alt=""
                                      className={`${styles.editLogo}`}
                                    />
                                  </div>
                                </div>
                              )}
                            </OverlayTrigger>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Tooltip>
              }
              show={showPopOverFB}
            >
              {({ ref, ...triggerHandler }) => (
                <div
                  className={`ms-0 me-0`}
                  {...triggerHandler}
                  ref={ref}
                >
                  <div
                    className={`d-flex justify-content-center align-items-center ${styles.customInfo} ${styles.customInfo2} mx-2`}
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    onMouseEnter={handleMouseEnterFB}
                    onMouseLeave={handleMouseLeaveFB}
                  >
                    <img
                      src={messenger}
                      alt=""
                      className={`${styles.editLogo}`}
                    />
                  </div>
                </div>
              )}
            </OverlayTrigger>
          ) : (
            ""
          )}

          {/* Twitter Icon */}
          {twitter && twitter.length > 0 ? (
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip
                  className={`override-white px-3 py-2  ${styles.toolTipCustom}`}
                  onMouseEnter={handleMouseEnterTwt}
                  onMouseLeave={handleMouseLeaveTwt}
                >
                  {twitter.map((twt) => {
                    return (
                      <div className={``}>
                        <div className={`mb-2 px-2 pt-1`}>
                          <div
                            className={`d-flex align-items-center justify-content-between `}
                          >
                            <span className={`${styles.tipSpan} text-truncate`}>
                              {twt}
                            </span>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip className={` ${styles.toolTipCustom}`}>
                                  {copied ? "Copied" : "Copy"}
                                </Tooltip>
                              }
                            >
                              {({ ref, ...triggerHandler }) => (
                                <div
                                  className={`ms-0 me-0`}
                                  {...triggerHandler}
                                  ref={ref}
                                >
                                  <div
                                    className={`d-flex justify-content-center align-items-center mx-2 ${styles.customInfo} ${styles.customInfo3}`}
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    onClick={() => {
                                      handleCopy(twt);
                                    }}
                                  >
                                    <img
                                      src={copy11}
                                      alt=""
                                      className={`${styles.editLogo}`}
                                    />
                                  </div>
                                </div>
                              )}
                            </OverlayTrigger>
                          </div>
                        </div>
                        <div></div>
                      </div>
                    );
                  })}
                </Tooltip>
              }
              show={showPopOverTwt}
            >
              {({ ref, ...triggerHandler }) => (
                <div
                  className={`ms-0 me-0`}
                  {...triggerHandler}
                  ref={ref}
                >
                  <div
                    className={`d-flex justify-content-center align-items-center ${styles.customInfo} ${styles.customInfo2} mx-2`}
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    onMouseEnter={handleMouseEnterTwt}
                    onMouseLeave={handleMouseLeaveTwt}
                  >
                    <img
                      src={twitterImage}
                      alt=""
                      className={`${styles.twit}`}
                    />
                  </div>
                </div>
              )}
            </OverlayTrigger>
          ) : (
            ""
          )}
        </div>

        {/* Edit Customer Button */}
        <div
          onClick={onShow}
          role="button"
          className={`${styles.editCustomerDetails} d-flex align-items-center justify-content-center py-2`}
        >
          <span>
            <i
              className={`fa-solid fa-pen-to-square fa-sm ${styles.editLogo} me-1`}
            ></i>
          </span>
          Edit customer
        </div>
      </div>

      <Modal
        show={showModal}
        onHide={onHide}
        dialogClassName={`${styles.modalDialog}`}
        contentClassName={`${styles.modalContent}`}
        backdropClassName={`${styles.backDrop}`}
        centered={true}
      >
        <InputFieldsContextProvider>
          <CustomerEdit onHide={onHide} />
        </InputFieldsContextProvider>
      </Modal>
      <BotProfileInfo />
      {/* Section Two - Customer Meta  */}
      {customerMeta?.chatStartedUrl?.trim() ||
      customerMeta?.currentChatUrl?.trim() ? (
        <div className={`mb-2 ${styles.generalInfo}`}>
          <div className={`${styles.infoMain}`}>
            {customerMeta?.currentChatUrl?.trim() ? (
              <div className={`${styles.state} d-flex mb-2`}>
                <span className={`me-2`}>
                  <i className="fa-solid fa-earth-asia"></i>
                </span>
                <div>
                  <div className={`${styles.infoName}`}>Current Page</div>
                  <a
                    target={"_blank"}
                    href={customerMeta.currentChatUrl.trim()}
                    className={`${styles.infoEmail} ${styles.breakWord}`}
                  >
                    {customerMeta.currentChatUrl.trim()}
                  </a>
                </div>
              </div>
            ) : (
              <></>
            )}
            {customerMeta?.chatStartedUrl?.trim() ? (
              <div className={`${styles.state} d-flex mb-2`}>
                <span className={`me-2`}>
                  <i className="fas fa-clock"></i>
                </span>
                <div>
                  <div className={`${styles.infoName}`}>Chat Started</div>
                  <a
                    target={"_blank"}
                    href={customerMeta.chatStartedUrl.trim()}
                    className={`${styles.infoEmail} ${styles.breakWord}`}
                  >
                    {customerMeta.chatStartedUrl.trim()}
                  </a>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
export default Generalinfo;
