import { createSlice } from "@reduxjs/toolkit";
import { builderFunction } from "./liveChatHistory.builder";
import { initialState } from "./liveChatHistory.declarations";
import liveChatHistoryReducer from "./liveChatHistory.reducers";

export const liveChatHistorySlice = createSlice({
  name: "liveChatHistory",
  initialState,
  reducers: liveChatHistoryReducer,
  extraReducers: builderFunction,
});

export const {
  resetLiveChatHistory,
  resetLiveChatHistoryMessages,
  setSelectedChatId,
  setSelectedCustomerId,
  setSelectedInternalChatId,
} = liveChatHistorySlice.actions;

export default liveChatHistorySlice.reducer;
