import styles from "./OrderSection.module.scss";
import orderImg from "src/assets/images/order1.png";
import settingImg from "src/assets/images/botSetting.png";
import { useReturnPreviewState } from "../../../../hooks/useReturnPreview";

const OrderSection = () => {
  const { selectedOrder, orders, ...contextValues } = useReturnPreviewState();
  const handleBackClick = () => {
    contextValues.updateState({
      ...contextValues,
      selectedOrder: null,
    });
  };

  return (
    <div className={``}>
      <div className={`d-flex align-items-center mb-3`}>
        <div
          className={`${styles.backBtn} cursor-pointer`}
          onClick={handleBackClick}
        >
          <span>
            <i className="fa-solid fa-arrow-left"></i>
          </span>
        </div>
        <span className={`ps-2 ${styles.heading}`}>
          {selectedOrder?.orderName}
        </span>
      </div>
      <div className={`${styles.orderDetails}`}>
        <p className={`${styles.orderHead}`}>{selectedOrder?.orderName}</p>
        <p className="d-flex">
          <span className={`${styles.orderDesc}`}>
            <i className="fa-regular fa-clock"></i>
          </span>
          <span className={`ps-1 ${styles.orderDesc}`}>
            4/02/ 2022 at 8:52 am
          </span>
        </p>
        <p className="d-flex">
          <span className={`${styles.orderDesc}`}>
            <i className="fa-regular fa-clock"></i>
          </span>
          <span className={`ps-1 ${styles.orderDesc}`}>
            Gregory Cartwright,936 Kiehn Route,West Ned,Tennessee,11230, USA
          </span>
        </p>
        <div className="d-flex flex-wrap mb-2">
          <button
            className={`d-flex align-items-center mb-1 me-1 ${styles.paidBtn}`}
          >
            <div className={`me-1 ${styles.dot}`}></div> Paid
          </button>
          <button
            className={`d-flex align-items-center mb-1 me-1 ${styles.paidBtn}`}
          >
            <div className={`me-1 ${styles.dot}`}></div>Fullfilled
          </button>
          <button
            className={`d-flex align-items-center me-1 ${styles.fullFilledBtn}`}
          >
            <div className={`me-1 ${styles.dot2}`}></div> Delivered.2d ago
          </button>
        </div>
        <div>
          <p className={`mb-0 ${styles.orderHeading}`}>
            {selectedOrder?.orderName}
          </p>

          <div className={`d-flex`}>
            <div className={`${styles.cart}`}>
              <img
                src={orderImg}
                alt="order"
                className={`${styles.orderImg}`}
              />
            </div>
            <div className={`ms-2`}>
              <span className={`${styles.orderName}`}>
                {selectedOrder?.orderTotal?.currencyCode}{" "}
                {selectedOrder?.orderTotal?.amount} x 2
              </span>
              <span className={`${styles.orderDesc}`}>SKU : 0101</span>
            </div>
          </div>
        </div>
        <span className={`${styles.moreItem}`}>+2 more items</span>
      </div>
      <div className="mt-3">
        <h4 className={`${styles.conditionHead}`}>
          Conditions of this sample order
        </h4>
        <div className={styles.conditionBox}>
          {selectedOrder?.conditions?.map((condition, index) => (
            <div
              key={index}
              className={`d-flex mb-2 ${styles.conditionInnerBox}`}
            >
              <img
                src={settingImg}
                alt="setting"
                width={10}
                height={10}
                className="mt-1"
              />
              <span className="ps-1">
                {condition.variableName} {condition.operator}{" "}
                {Array.isArray(condition.values)
                  ? condition.values.slice(0, 3).join(", ")
                  : condition.values}
                {Array.isArray(condition.values) &&
                  condition.values.length > 3 && (
                    <span className="ps-1">
                      and{" "}
                      <span className={styles.moreItem}>
                        {condition.values.length - 3} more
                      </span>
                    </span>
                  )}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OrderSection;
