import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";
import { AJAXSTATUS } from "src/globals/constants";
import {
  ConditionOptionsResponse,
  getConditionOptionsService,
} from "src/services/CustomerSegments/getConditionOptions.service";

/**
 * Custom hook to fetch condition options based on the provided segment type.
 *
 * This hook utilizes the `useQuery` hook from `@tanstack/react-query` to fetch
 * condition options from the `getConditionOptionsService` based on the specified
 * segment type. The query result is cached for an hour (`cacheTime` and `staleTime`
 * set to 3600000 ms), ensuring that the data remains fresh for the specified duration.
 *
 * @param segmentType - The type of the segment for which condition options are to be fetched.
 * @returns An object containing the fetched condition options and the status of the request.
 * conditionOptions - The fetched condition options, or null if the request failed.
 * conditionOptionsStatus - The status of the request, which can be one of "fulfilled", "pending", or "rejected".
 *
 * @example
 * const { conditionOptions, conditionOptionsStatus } = useFetchConditionOptions('segmentType');
 *
 * @see {@link getConditionOptionsService} for the service used to fetch condition options.
 */
function useFetchConditionOptions(segmentType: string) {
  const { data: conditionOptions, status } = useQuery(
    ["useFetchConditionOptions", segmentType],
    {
      queryFn: async () => {
        return await getConditionOptionsService(segmentType);
      },
      cacheTime: 3600000,
      staleTime: 3600000,
      networkMode: "always",
    },
  );

  const findOptionByFieldKey = useCallback(
    (fieldKey: string) => {
      let value = conditionOptions?.defaultConditions?.find(
        (v) => v.fieldKey === fieldKey,
      );
      if (value) {
        return value;
      }
      value = conditionOptions?.customeConditions?.find(
        (v) => v.fieldKey === fieldKey,
      );
      if (value) {
        return value;
      }
      return null;
    },
    [conditionOptions],
  );

  return {
    conditionOptions: conditionOptions
      ? conditionOptions
      : (null as ConditionOptionsResponse | null),
    conditionOptionsStatus:
      status === "success"
        ? "fulfilled"
        : status === "loading"
          ? "pending"
          : ("rejected" as AJAXSTATUS),
    findOptionByFieldKey,
  };
}

export default useFetchConditionOptions;
