import React from "react";
import { Spinner } from "react-bootstrap";
import styles from "./DeleteResponse.module.scss";
const DeleteResponse = (props: { onHide: Function, handleCannedResponseDelete: Function, deleteLoader: boolean }) => {

  const handleCancel = () => {

    props.onHide();
  };

  const handleConfirm = () => {
    props.handleCannedResponseDelete()
    props.onHide();
  };


  return (
    <div className="text-center">
      <h4 className={`${styles.heading}`}>Are you sure ?</h4>
      <div>
        <p className={`${styles.subHeading}`}>
          Are you sure you want to delete this Response?
        </p>
        <div
          className={`d-flex justify-content-center align-items-center ${styles.actionBox}`}
        >
          <button className={`d-flex justify-content-center align-items-center me-4 ${styles.cancelBtn}`} onClick={handleCancel}>Cancel</button>
          <button className={`d-flex justify-content-center align-items-center ${styles.confirmBtn}`} onClick={handleConfirm}>{props.deleteLoader ? (
            <Spinner
              className="my-auto mx-1"
              animation="border"
              color="white"
              size="sm"
            />
          ) : `Confirm`}</button>
        </div>
      </div>
    </div>
  );
};

export default DeleteResponse;
