import {
  GetAllOrder,
  GetAllOrderParams,
  getAllOrders,
} from "src/services/ShopifySidebar/AllOrders/getAllOrders";
import { RootState } from "src/store/store";

// thunk that fetches all orders
const fetchAllOrdersThunk = async (
  payload: undefined,
  { getState }: { getState: Function }
) => {
  try {
    const currentState: RootState = getState();
    const queryData: GetAllOrderParams = {
      customerId: currentState.innerTicket.filters.customerId,
      filters: {
        sortBy: currentState.allOrders.sortBy,
        searchTerm: currentState.allOrders.serachTerm,
      },

      start: (currentState.allOrders.currentPage - 1) * currentState.allOrders.fetchOrdersLimit,
      limit: currentState.allOrders.fetchOrdersLimit,
    };

    // check search term is empty or not
    if((queryData.filters.searchTerm?.length === 0)){
      delete queryData.filters.searchTerm;
    }
    const allOrdersData: GetAllOrder = await getAllOrders(queryData);
    
    return {
      allOrdersData,
    };
  } catch (error) {
    throw error;
  }
};

export default { fetchAllOrdersThunk };
