import { axiosJSON } from "src/globals/axiosEndPoints";
import { BugData } from "./getBugByCategory.service";

export interface Comment {
  id?: number;
  message?: string;
  attachmentBatchNumber?: Array<string>;
  bugId: number;
  agent_id?: number | null;
  sender?: string;
  created_at_gmt?: string;
}


const getBugById = async (param: { bugId: number | string }) => {
  const { data: res } = await axiosJSON.post(
    `/api/selfService/bugManagement/getBugDetailsById`,
    param,
  );

  if (res.err || res.error) {
    throw res.msg ?? res.message ?? "Something went wrong!";
  }

  let ret: BugData

  ret = res.data;

  return ret;
};

export default getBugById;
