import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useBillingContext } from "../../BillingContext";

import users from "src/assets/images/billUsers.png";

import styles from "./SeatModal.module.scss";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import processPaymentService from "src/services/Billing/processPayment.service";
import { Spinner } from "react-bootstrap";
interface Props {
  onHide: () => void;
  onSuccess: (
    status: "payment_success" | "payment_cancel" | "payment_failed"
  ) => void;
}

/**
 * Modal component for adding additional seats
 */
const SeatModal = ({ onHide, onSuccess }: Props) => {
  const { billingDetails } = useBillingContext();
  const navigate = useNavigate();

  const [error, setError] = useState(false);
  const [input, setInput] = useState("");
  const [loader, setLoader] = useState(false);

  /**
   * Submit Handler for processing payment
   */
  const submitHandler = useCallback(async () => {
    const seats = parseInt(input);
    // Validate Input
    if (isNaN(seats) || seats === 0) {
      setError(true);
      return;
    }

    // Process Payment
    try {
      setLoader(true);

      // Initiate payment
      const res = await processPaymentService({ seats: seats + "" });

      // Hide the additional seats modal
      onHide();

      // If we have a status then show success status modal
      if (res) {
        onSuccess(res);
      }
    } catch (e) {
      pushTheToast({
        position: "top-right",
        text: "Cannot Process Payment at the moment",
        type: "danger",
      });
    } finally {
      setLoader(false);
    }
  }, [input, onHide, onSuccess]);

  return (
    <div className={`${styles.seatModalWrapper}`}>
      {/* Close Modal */}
      <div>
        <span className={`${styles.closeModal}`} role="button" onClick={onHide}>
          <i className="fa-solid fa-xmark"></i>
        </span>
      </div>

      {/* Heading */}
      <div className="text-center">
        <p className={`mb-1 ${styles.header}`}>Add seats</p>
        <img src={users} alt="user" />
      </div>

      {/* Seats Details */}
      <div className={`mt-2 ${styles.seatsWrapper}`}>
        {/* Available Seats */}
        <div className="d-flex justify-content-center">
          <div className="d-flex align-items-center">
            <p className={`mb-0 ${styles.count}`}>Current Seats Available</p>
            <span className={`ms-1 ${styles.mediator}`}>:</span>
            <span className={`${styles.value}`}>
              {billingDetails?.seats?.availableCount ?? ""}
            </span>
          </div>
        </div>

        {/* Utilized Seats */}
        <div className="d-flex justify-content-center">
          <div className="d-flex align-items-center">
            <p className={`mb-0 ${styles.count}`}>Current Seats Utilized</p>
            <span className={`ms-1 ${styles.mediator}`}>:</span>
            <span className={`${styles.value}`}>
              {billingDetails?.seats?.utilizedCount ?? ""}
            </span>
          </div>
        </div>

        {/* Invite Agents Link */}
        {billingDetails?.seats &&
          billingDetails.seats.utilizedCount <
            billingDetails.seats.availableCount && (
            <div className="d-flex justify-content-center">
              <div className="d-flex align-items-center">
                <p
                  className={`mb-0 ${styles.invite}`}
                  onClick={() => navigate("/settings/User")}
                >
                  Invite agents to Utilize seats
                </p>
                <span className={`ms-1 ${styles.mediator}`}></span>
                <span className={`${styles.value}`}></span>
              </div>
            </div>
          )}

        {/* Number of Seats */}
        <div
          className={`d-flex ${
            error ? "justify-content-end" : "justify-content-center"
          }`}
        >
          <div className="d-flex align-items-center">
            {/* Label */}
            <p className={`mb-0 ${styles.count} ${styles.addSeat}`}>
              Number of seats to add
            </p>
            <span className={`ms-1 ${styles.mediator}`}>:</span>

            {/* Input */}
            <div>
              <input
                type="number"
                placeholder="- "
                value={input}
                onChange={(e) => {
                  setInput(e.currentTarget.value);
                  setError(false);
                }}
                className={`${styles.inputBox} ${error ? styles.errorBox : ""}`}
              />
              {/* Error */}
              {error && (
                <p className={`mb-0 ${styles.errorMsg}`}>1 agent minimum</p>
              )}
            </div>
          </div>
        </div>

        {/* Process Charge Button */}
        <div className="d-flex justify-content-center align-items-center mt-2 mb-1">
          <button
            className={`${styles.continueBtn}`}
            onClick={submitHandler}
            disabled={loader}
          >
            Process Additional Charge
            {loader && (
              <Spinner className="ms-2" size="sm" animation="border" />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SeatModal;
