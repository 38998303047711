import { useCallback, useEffect, useState } from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import { getAllTeam } from "src/services/Settings/Teams/getAllTeam";

function SelectTeams({
  value,
  setValue,
  canBDisabled // modified to use for Create user from team page
}: {
  value: Array<number | string>;
  setValue: (id: Array<number | string>) => void;
  canBDisabled: boolean
}) {
  const fetchTeams = useCallback(
    async (searchTerm: string, loadOptions: any, additional: any) => {
      searchTerm = searchTerm.trim();
      const limit = 25;
      const { teams, teamIds, metaData } = await getAllTeam({
        start: additional ? additional.page : 0,
        limit
      });
      return {
        options: Object.values(teams).map((data: any) => ({
          label: data.teamName,
          value: data
        })),
        hasMore: metaData.count === limit,
        additional: {
          page: additional ? additional.page + metaData.count : metaData.count
        }
      } as any;
    },
    []
  );

  return (
    <div>
      <AsyncPaginate
        loadOptions={fetchTeams}
        isMulti={true}
        isSearchable={false}
        isDisabled = {canBDisabled} // modified to use for Create user from team page
        value={value.map((data: any) => ({
          label: data.teamName,
          value: data
        }))}
        onChange={(value: any) => {
          setValue(value.map((data: any) => data.value));
        }}
        styles={
          customStyles 
        }
      />
    </div>
  );
}

const customStyles = {
  option: (provided: any, _: any) => ({
    ...provided,
    textAlign: "left",
    font: "normal normal 500 12px/18px Poppins",
    letterSpacing: "0px",
    color: "#000000",
    '&:hover':{
      background: "#F9F9F9 0% 0% no-repeat padding-box",
     borderRadius: "4px"
    },
    '&:active': {
      background: "#EEEEEE 0% 0% no-repeat padding-box"
    }
  }),
  control: (provided: any, _: any) => ({
    ...provided,
    margin: "0px",
    textAlign: "left",
    font: "normal normal normal 12px/18px Poppins",
    letterSpacing: "0px",
  }),

};

export default SelectTeams;
