import { axiosJSON } from "src/globals/axiosEndPoints";

export interface updateArticleParams {
  articleId: number | string;
  articleName?: string;
  categoryId?: number | string;
  integrationId: number | string;
  articleStatus?: string;
  attachmentBatchNumber?: string[];
  articleDescription?: string;
  searchKeyword?: string;
}

export async function updateArticle(params: updateArticleParams) {
  const { data } = await axiosJSON.post(
    `/api/chatSetting/article/update`,
    params,
  );
  if (data.error === true) {
    throw new Error(data.message as string);
  }
  return data.data;
}
