import { ITicketBtn } from "src/containers/SideBar/children/SubSB/SubSB";
import {
  ITicketSideBarState,
  UsersViewData,
} from "src/store/slices/ticketSidebar/ticketSidebar.slice";
import { ListViewOption } from "./getSubSB";

export function addUsersViewInTicketBtnData(state: ITicketSideBarState) {
  const { usersViewData, usersViewList } = state;
  const ticketBtnDataTemp: ITicketBtn = {
    header: "Your views",
    margin: 4,
    paginate: true,
    ticketBtnList: usersViewList.map((viewId, key1) => {
      const viewData: UsersViewData = usersViewData[viewId];
      return {
        ticketBtnName: viewData.viewName,
        ticketBtnCount: viewData.viewsTicketCount,
        keyName: `viewId::${viewId}`,
        ddOuterList: [],
        listViewFilters:
          viewData.options?.map((option: ListViewOption) => {
            return {
              key: option.key,
              value:
                option.key === "viewTCreatedOn" ||
                option.key === "viewTClosedOn"
                  ? option.value
                  : option.idNName?.map(({ name }) => name).join(", ") ?? "",
            };
          }) ?? [],
      };
    }),
  };
  state.ticketBtnData[1] = ticketBtnDataTemp;
}
