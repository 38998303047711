/**
 * This file is the custome hook file.
 * It contains custome hooks and context api to handle internal filters.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import React, { useContext, useMemo, useReducer } from "react";

export interface ReportSidebarFilters {
  channels?: Array<string>; //channel ids
  timeClusters?: Array<string>;
  msgClusters?: Array<string>;
  timeRange?: Array<string>;
  agentIds?: Array<string | number>; //agent ids
}

export interface ReportInternalFilters extends ReportSidebarFilters {
  brands?: Array<string>;
  assignedTo?: Array<string>;
  tags?: Array<string>;
  ticketStatus?: Array<string>;
  draftStatus?: Array<string>;
  draftedBy?: Array<string>;
  articleCategoryIds?: Array<string>;
  ratings?: Array<string>;
  authorIds?: Array<string>;
  dateAdded?: Array<string>;
  agentTypes?: Array<"all" | "agent" | "lead_agents" | "admin" | "support">;
  chatStatus?: "unresolved" | "resolved" | "all";
  chatRatings?: Array<string>;
  botArticleCategoryIds?: Array<string>; //For bot profile article category
  articleRating?: Array<string>; //For bot article rating
}

export type ReportDateType = "daily" | "weekly" | "monthly";

interface ReportInternalFiltersState {
  filters: ReportInternalFilters;
  addedFilters: ReportInternalFilters;
  reportDateType: ReportDateType;
  sidebarFilters?: ReportSidebarFilters;
}

/**
 * Initializing filters.
 */
const reportInternalFilters: ReportInternalFiltersState = {
  filters: {},
  addedFilters: {},
  reportDateType: "daily",
};

/**
 * This reducer method is used with the context and the UseReducer hook in the useReportFiltersCreate hook.
 */
const reducerMethod = (
  state: ReportInternalFiltersState,
  [action, payload]: [
    (value: ReportInternalFiltersState, payload?: any) => void,
    undefined | any,
  ],
) => {
  action(state, payload);
  return { ...state };
};

/**
 * Creating the context.
 */
const ReportInternalFiltersContext = React.createContext([
  reportInternalFilters,
  () => {},
] as [
  ReportInternalFiltersState,
  React.Dispatch<
    [
      (value: ReportInternalFiltersState, payload?: any) => void,
      undefined | any,
    ]
  >,
]);

/**
 * Creating reducer and combining it with the context.
 */
export const useReportInternalFiltersCreate = () => {
  const [state, dispatch] = useReducer(reducerMethod, {
    ...reportInternalFilters,
  });
  return [state, dispatch, ReportInternalFiltersContext.Provider] as [
    ReportInternalFiltersState,
    typeof dispatch,
    typeof ReportInternalFiltersContext.Provider,
  ];
};

/**
 * Using the context in this hook and returning the dispatch to update data in reducer and da data extracted for context.
 */
export const useReportInternalFilters = () => {
  const [internalFilters, dispatch] = useContext(ReportInternalFiltersContext);

  const appliedFiltersLength = useMemo(() => {
    return Object.keys(internalFilters.filters).length;
  }, [internalFilters]);

  return { internalFilters, dispatch, appliedFiltersLength };
};

/**
 * These are the action functions which will help to apply the the state changes in the created reducer.
 */
export const reportInternalFiltersActions = {
  resetState: (state: ReportInternalFiltersState) => {
    state.addedFilters = {};
    state.filters = {};
  },
  setFilters: (
    state: ReportInternalFiltersState,
    payload: ReportInternalFilters,
  ) => {
    state.addedFilters = { ...state.addedFilters, ...payload };
    for (const key in state.addedFilters) {
      if (
        //empty checking addedFiler by length
        state.addedFilters[key as keyof typeof state.addedFilters]?.length ===
          0 ||
        state.addedFilters[key as keyof typeof state.addedFilters] === undefined
      ) {
        delete state.addedFilters[key as keyof typeof state.addedFilters];
      }
    }
  },
  clearOneAppliedFilter: (
    state: ReportInternalFiltersState,
    payload: keyof typeof state.filters,
  ) => {
    delete state.filters[payload];
    state.addedFilters = { ...state.filters };
  },
  applyFilters: (state: ReportInternalFiltersState) => {
    state.filters = { ...state.addedFilters };
  },
  discardFilters: (state: ReportInternalFiltersState) => {
    state.addedFilters = { ...state.filters };
  },
  setReportDateType: (
    state: ReportInternalFiltersState,
    payload: typeof state.reportDateType,
  ) => {
    state.reportDateType = payload;
  },
  setSidebarFilters: (
    state: ReportInternalFiltersState,
    payload: ReportSidebarFilters | null,
  ) => {
    if (payload) {
      state.sidebarFilters = payload;
    } else {
      delete state.sidebarFilters;
    }
  },
};
