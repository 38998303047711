import objectHash from "object-hash";
import { conditionType } from "src/services/UiAutomation/ReturnExchange/Configuration/Step9/step9GetOptionsConditions";
import { v4 as uuid } from "uuid";
import { listOfOperators } from "src/globals/constants";
import { isHTMLEmpty, trimPDIVBR, validateRange } from "src/utils/utils";
import { ReasonTypes } from "src/services/UiAutomation/ReturnExchange/Configuration/Step12/step12GetConfig";
import {
  moveToNextStep,
  setShowValidationErros,
} from "../retrunExchange.slice";
import { validateAllQuestions } from "../returnExchange.thunk";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { EReturnAutomationHiddenKeys } from "src/enums/EReturnAutomationQuestionKeys";

export type IVariableName = (typeof variableNames)[number];

export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

export interface ConditionTypes {
  id: string;
  ruleType: (typeof ruleTypes)[number];
  variableName: IVariableName;
  values: any;
  operator: string[] | undefined | string;
}

export const variableNames = [
  "Item",
  "Tags",
  "Order Date",
  "Order Delivery Date",
  "Order Tag",
  "Discount Code",
  "Previous Exchanges On This Order",
  "Order Country",
  "Order Value",
  "None",
  "Return Reason",
] as const;

export const operatorNames: {
  [key in IVariableName]: (typeof listOfOperators)[keyof typeof listOfOperators];
} = {
  Item: listOfOperators.is,
  Tags: listOfOperators.is,
  "Order Date": listOfOperators.within,
  "Order Delivery Date": listOfOperators.within,
  "Order Tag": listOfOperators.is,
  "Discount Code": listOfOperators.include,
  "Previous Exchanges On This Order": listOfOperators.areLessThan,
  "Order Country": listOfOperators.is,
  "Order Value": listOfOperators.is,
  "Return Reason": listOfOperators.is,
  None: listOfOperators.none,
};

export const conditionValues: { [key in IVariableName]: any } = {
  Item: {
    items: {
      currentValue: [],
      error: undefined,
    },
  },
  Tags: {
    tags: {
      currentValue: [],
      error: undefined,
    },
  },
  "Order Date": {
    days: {
      currentValue: 0,
      error: undefined,
    },
    day_type: {
      currentValue: "days",
      error: undefined,
    },
  },
  "Order Delivery Date": {
    days: {
      currentValue: 0,
      error: undefined,
    },
    day_type: {
      currentValue: "days",
      error: undefined,
    },
  },
  "Order Tag": {
    tags: {
      currentValue: [],
      error: undefined,
    },
  },
  "Discount Code": {
    codes: {
      currentValue: [],
      error: undefined,
    },
  },
  "Previous Exchanges On This Order": {
    numbers: {
      currentValue: 0,
      error: undefined,
    },
  },
  "Order Country": {
    countryName: {
      currentValue: "",
      error: undefined,
    },
  },
  "Order Value": {
    amount: {
      currentValue: 0,
      error: undefined,
    },
    currency: {
      currentValue: "USD",
      error: undefined,
    },
  },
  "Return Reason": {
    return_reason: {
      reasonId: "",
      elementId: "",
      error: undefined,
      value: "",
      operator: listOfOperators.is,
    },
  },
  None: {
    none: {
      error: undefined,
    },
  },
};

export const ruleTypes = ["AND", "OR"] as const;

const lastVariable = variableNames[variableNames.length - 1];

export const conditionTypeInitialState = {
  ruleType: ruleTypes[0],
  variableName: lastVariable,
  values: conditionValues[lastVariable],
  operator: operatorNames[lastVariable][0],
};

export function getConditionValueOrDefault(
  valueType: any,
  conditionValue: any = null,
) {
  let values: any = {};
  let defaultCurrentValue = null;
  switch (valueType) {
    case "shopify_discount": {
      if (conditionValue === undefined || conditionValue === null) {
        defaultCurrentValue = [];
      }
      let _value;
      if (!Array.isArray(conditionValue)) {
        _value = [conditionValue];
      } else {
        _value = conditionValue;
      }
      values["codes"] = {
        currentValue: defaultCurrentValue ?? _value,
      };
      break;
    }
    case "country": {
      if (conditionValue === undefined || conditionValue === null) {
        defaultCurrentValue = "";
      }
      values["countryName"] = {
        currentValue: defaultCurrentValue ?? conditionValue,
      };
      break;
    }
    case "number": {
      if (conditionValue === undefined || conditionValue === null) {
        defaultCurrentValue = 0;
      }
      let _value;
      const conditionValueParsed = Number.parseInt(conditionValue);
      if (Number.isNaN(conditionValueParsed)) {
        _value = 0;
      } else {
        _value = conditionValueParsed;
      }
      values["numbers"] = {
        currentValue: defaultCurrentValue ?? _value,
      };
      break;
    }
    case "shopify_order_tags": {
      if (conditionValue === undefined || conditionValue === null) {
        defaultCurrentValue = [];
      }
      let _value;
      if (!Array.isArray(conditionValue)) {
        _value = [conditionValue];
      } else {
        _value = conditionValue;
      }
      values["tags"] = {
        currentValue: defaultCurrentValue ?? _value,
      };
      break;
    }
    case "shopify_currency": {
      let defaultCurrencyCode = null;
      if (conditionValue !== null) {
        if (
          conditionValue.value === undefined ||
          conditionValue.value === null
        ) {
          defaultCurrentValue = 0;
        }

        if (
          conditionValue.currencyCode === undefined ||
          conditionValue.currencyCode === null
        ) {
          defaultCurrencyCode = "USD";
        }
        values["amount"] = {
          currentValue: defaultCurrentValue ?? conditionValue.value,
        };
        values["currency"] = {
          currentValue: defaultCurrencyCode ?? conditionValue.currencyCode,
        };
      } else {
        values["amount"] = {
          currentValue: 0,
        };
        values["currency"] = {
          currentValue: "USD",
        };
      }

      break;
    }
    case "shopify_product": {
      if (conditionValue === undefined || conditionValue === null) {
        defaultCurrentValue = [];
      }
      let _value;
      if (!Array.isArray(conditionValue)) {
        // _value = productIdToDetails(conditionValue);
        _value = [conditionValue];
      } else {
        _value = conditionValue;
      }
      values["items"] = {
        currentValue: defaultCurrentValue ?? _value,
      };
      break;
    }
    case "shopify_item_tags": {
      if (conditionValue === undefined || conditionValue === null) {
        defaultCurrentValue = [];
      }
      let _value;
      if (!Array.isArray(conditionValue)) {
        _value = [conditionValue];
      } else {
        _value = conditionValue;
      }
      values["tags"] = {
        currentValue: defaultCurrentValue ?? _value,
      };
      break;
    }
    case "order_date": {
      let defaultDateType = null;
      if (conditionValue !== null) {
        if (conditionValue.days === undefined || conditionValue.days === null) {
          defaultCurrentValue = 0;
        }

        if (
          conditionValue.day_type === undefined ||
          conditionValue.day_type === null
        ) {
          defaultDateType = "business_days";
        }
        values["days"] = {
          currentValue: defaultCurrentValue ?? conditionValue.days,
        };
        values["day_type"] = {
          currentValue: defaultDateType ?? conditionValue.day_type,
        };
      } else {
        values["days"] = {
          currentValue: 0,
        };
        values["day_type"] = {
          currentValue: "business_days",
        };
      }

      break;
    }
    case "return_reason":
      let defaultValue = {
        reasonId: "",
        elementId: "",
        operator: "",
        value: "",
      };
      if (conditionValue !== null) {
        if (conditionValue.value) {
          defaultValue.value = conditionValue.value;
        }

        if (conditionValue.operator) {
          defaultValue.operator = conditionValue.operator;
        }
        if (conditionValue.elementId) {
          defaultValue.elementId = conditionValue.elementId;
        }
        if (conditionValue.reasonId) {
          defaultValue.reasonId = conditionValue.reasonId;
        }
      }

      break;
  }

  return values;
}

/**
 * Mutates the selected tab in place with new condition and returns it
 * @returns The selected tab after mutating it
 */
export function addConditionHandler({
  selectedTab,
  payloadCondition,
  conditionOptions,
  isItemCondition = false,
}: {
  selectedTab: any;
  payloadCondition: string;
  conditionOptions: conditionType[];
  isItemCondition?: boolean;
}) {
  if (selectedTab) {
    let condition = conditionOptions[0];
    if (selectedTab.conditions && selectedTab.conditions.length > 0) {
      // Already added condition names
      const allSelectedConditionNames: string[] = selectedTab.conditions.map(
        (condition: ConditionTypes) => condition.variableName,
      );

      // Filter out conditions that are not already present to stop duplicates
      const filteredCondition = conditionOptions.find(
        (condition) => !allSelectedConditionNames.includes(condition.name),
      );

      // Check if we found an usable condition
      if (filteredCondition) {
        condition = filteredCondition;
      } else {
        // No more conditions available we cannot add anything
        if (isItemCondition) {
          selectedTab.isAddItemConditionEnabled = false;
        } else {
          selectedTab.isAddConditionEnabled = false;
        }
        return selectedTab;
      }
    }

    if (payloadCondition) {
      let addCondition = conditionOptions.find(
        (value) => value.name === payloadCondition,
      );
      if (addCondition) {
        condition = addCondition;
      }
    }

    const operator = condition.operators[0];
    const valueType = condition.valueType;
    const name = condition.name;

    if (operator && valueType && name) {
      selectedTab.conditions.push({
        id: uuid(),
        operator: operator.name,
        ruleType: "AND",
        values: getConditionValueOrDefault(valueType),
        variableName: condition.name,
      });

      if (isItemCondition) {
        selectedTab.isAddItemConditionEnabled = false;
        if (selectedTab.itemError !== null) {
          selectedTab.itemError = null;
        }
      } else {
        // Check if the default value exists
        if (
          selectedTab.conditions[selectedTab.conditions.length - 1].values[
            "days"
          ]?.currentValue > 0
        ) {
          // Add new condition enabled if default value exists
          selectedTab.isAddConditionEnabled = true;
        } else {
          // Add new condition disabled if no default exists
          selectedTab.isAddConditionEnabled = false;
        }
        // Remove error if applied
        if (selectedTab.error !== null) {
          selectedTab.error = null;
        }
      }
    }
  }

  return selectedTab;
}

export const addConditionButtonStateHelper = ({
  prevCondition,
  returnReasonList = [],
}: {
  prevCondition: any;
  returnReasonList?: ReasonTypes[];
}) => {
  let isAddItemConditionEnabled: boolean | null = null;
  let isAddOrderConditionEnabled: boolean | null = null;
  let isAddReasonConditionEnabled: boolean | null = null;
  if (prevCondition?.values) {
    Object.entries<any>(prevCondition.values).forEach(([key, value]) => {
      switch (key) {
        case "tags":
        case "items":
          isAddItemConditionEnabled = value.currentValue.length > 0;
          break;
        case "none":
          isAddItemConditionEnabled = false;
          isAddOrderConditionEnabled = false;
          break;
        case "days":
        case "numbers":
          isAddOrderConditionEnabled = value.currentValue > 0;
          break;
        case "codes":
        case "countryName":
        case "returns":
        case "reasons":
          isAddOrderConditionEnabled = value.currentValue.length > 0;
          break;
        case "amount":
          if (prevCondition.operator === "is between") {
            if (
              value.currentValue.minValue !== undefined &&
              value.currentValue.maxValue !== undefined &&
              !isNaN(value.currentValue.minValue) &&
              !isNaN(value.currentValue.maxValue) &&
              validateRange(
                value.currentValue.minValue.toString(),
                value.currentValue.maxValue.toString(),
              )
            ) {
              isAddOrderConditionEnabled = true;
            } else {
              isAddOrderConditionEnabled = false;
            }
          } else {
            isAddOrderConditionEnabled =
              value.currentValue.length > 0 && parseInt(value.currentValue) > 0;
          }
          break;
        case "return_reason":
          //When no reason selected
          if (!value.reasonId) {
            value.error = "Please fill out this field";
          } else {
            let reason: ReasonTypes | undefined;
            // Get the reason based on id from default return reasons
            if (returnReasonList && returnReasonList.length > 0) {
              reason = returnReasonList.find(
                (item) => item.id === value.reasonId,
              );
            }
            // Check if a reason is found
            if (reason) {
              // Always check for reasonId first
              if (!value.reasonId) {
                isAddReasonConditionEnabled = false;
              } else {
                // Check if there are more questions for the reason
                if (reason.moreQuestions && reason.moreQuestions.length > 0) {
                  const moreQuestion = reason.moreQuestions.find(
                    (question) => question.elementId === value.elementId,
                  );
                  if (moreQuestion) {
                    // If additionalOptions exist, check all properties
                    if (
                      moreQuestion.additionalOptions &&
                      moreQuestion.additionalOptions.length > 0
                    ) {
                      if (
                        !value.reasonId ||
                        !value.operator ||
                        !value.elementId ||
                        !value.value
                      ) {
                        isAddReasonConditionEnabled = false;
                      } else {
                        isAddReasonConditionEnabled = true;
                      }
                    } else {
                      // If no additionalOptions, check for reasonId and elementId
                      if (!value.reasonId || !value.elementId) {
                        isAddReasonConditionEnabled = false;
                      } else {
                        isAddReasonConditionEnabled = true;
                      }
                    }
                  } else {
                    isAddReasonConditionEnabled = false;
                  }
                }
              }
            } else {
              isAddReasonConditionEnabled = false;
            }
          }
          break;
        default:
          break;
      }
    });
  }

  return {
    isAddItemConditionEnabled,
    isAddOrderConditionEnabled,
    isAddReasonConditionEnabled,
  };
};

export const deleteConditionHelper = ({
  conditionId,
  conditions,
  returnReasonList,
  conditionType,
}: {
  conditionId: string;
  conditions: ConditionTypes[];
  returnReasonList?: ReasonTypes[];
  conditionType?: "order" | "item";
}) => {
  const conditionIdx = conditions.findIndex(
    (condition) => condition.id === conditionId,
  );

  if (conditionIdx === -1) {
    return {
      updatedConditions: conditions,
      isAddItemConditionEnabled: null,
      isAddOrderConditionEnabled: null,
    };
  }

  const updatedConditions = conditions.filter(
    (condition) => condition.id !== conditionId,
  );
  if (updatedConditions.length === 0) {
    return {
      updatedConditions,
      isAddItemConditionEnabled: conditionType === "item" ? true : null,
      isAddOrderConditionEnabled: conditionType === "order" ? true : null,
    };
  }

  const lastCondition = updatedConditions[updatedConditions.length - 1];
  if (lastCondition !== undefined) {
    const {
      isAddItemConditionEnabled,
      isAddOrderConditionEnabled,
      isAddReasonConditionEnabled,
    } = addConditionButtonStateHelper({
      prevCondition: lastCondition,
      returnReasonList,
    });
    return {
      updatedConditions,
      isAddItemConditionEnabled,
      isAddOrderConditionEnabled,
      isAddReasonConditionEnabled,
    };
  } else {
    const firstCondition = updatedConditions[0];
    const {
      isAddItemConditionEnabled,
      isAddOrderConditionEnabled,
      isAddReasonConditionEnabled,
    } = addConditionButtonStateHelper({
      prevCondition: firstCondition,
      returnReasonList,
    });
    return {
      updatedConditions,
      isAddItemConditionEnabled,
      isAddOrderConditionEnabled,
      isAddReasonConditionEnabled,
    };
  }
};
export const deleteTabHelper = ({
  tabs,
  tabId,
  selectedTabId,
  idKey,
}: {
  tabs: any;
  tabId: string;
  selectedTabId: string | null;
  idKey: string;
}) => {
  // Find the index of the tab to be deleted using the provided key name
  const tabIdx = tabs?.findIndex(
    (tab: { [x: string]: string }) => tab[idKey] === tabId,
  );

  // If the tab to be deleted is currently selected, update the selected tab
  let newSelectedTabId = selectedTabId;
  if (tabIdx !== -1 && tabId === selectedTabId) {
    newSelectedTabId =
      tabs?.at(tabIdx + 1)?.[idKey] ?? tabs?.at(tabIdx - 1)?.[idKey] ?? null;
  }

  // Filter out the tab to be deleted using the provided key name
  const updatedTabs = tabs.filter(
    (tab: { [x: string]: string }) => tab[idKey] !== tabId,
  );

  return {
    updatedTabs,
    newSelectedTabId,
  };
};

export const updateConditionHelper = ({
  conditionToUpdate,
  conditions,
  returnReasonList,
}: {
  conditionToUpdate: RecursivePartial<ConditionTypes>;
  conditions: ConditionTypes[];
  returnReasonList?: ReasonTypes[];
}) => {
  const conditionIdx = conditions.findIndex(
    (condition) => condition.id === conditionToUpdate.id,
  );

  if (conditionIdx === -1) {
    return {
      updatedConditions: conditions,
      isAddItemConditionEnabled: null,
      isAddOrderConditionEnabled: null,
      isAddReasonConditionEnabled: null,
    };
  }

  const updatedConditions = [...conditions];
  const prevCondition = updatedConditions[conditionIdx];
  Object.assign(prevCondition, conditionToUpdate);
  updatedConditions[conditionIdx] = prevCondition;

  const {
    isAddItemConditionEnabled,
    isAddOrderConditionEnabled,
    isAddReasonConditionEnabled,
  } = addConditionButtonStateHelper({
    prevCondition,
    returnReasonList,
  });

  return {
    updatedConditions,
    isAddItemConditionEnabled,
    isAddOrderConditionEnabled,
    isAddReasonConditionEnabled,
  };
};

export const createNewObjectHash = ({
  excludeKeys = [],
  hashObject,
}: {
  excludeKeys?: string[];
  hashObject: unknown;
}) => {
  if (hashObject) {
    return objectHash(hashObject, {
      excludeKeys: function (key) {
        if (excludeKeys.includes(key)) {
          return true;
        }
        return false;
      },
      // Stops hashing of special type attributes (.prototype, .__proto__, .constructor)
      respectType: false,
    });
  }

  return null;
};

export const validateChangeHandler = ({
  initialHash,
  excludeKeys,
  stateObject,
}: {
  initialHash: string;
  excludeKeys?: string[];
  stateObject: unknown;
}) => {
  const newHash = createNewObjectHash({ excludeKeys, hashObject: stateObject });

  return {
    isChanged: newHash !== initialHash,
    newHash: newHash,
  };
};

// Validate conditions helper
export const validateConditionsHelper = ({
  selectedTab,
  step,
  itemOptions,
  conditionOptions,
  returnReasonList,
}: {
  selectedTab: any;
  step: "step9" | "step10" | "step11" | "step12" | "step14" | "step15";
  itemOptions?: conditionType[];
  conditionOptions?: conditionType[];
  returnReasonList?: ReasonTypes[];
}) => {
  const conditions: ConditionTypes[] = selectedTab.conditions ?? [];
  let isAddItemConditionEnabled: boolean | null = null;
  let isAddOrderConditionEnabled: boolean | null = null;
  let isAddReasonConditionEnabled: boolean | null = null;

  conditions.forEach((condition, idx) => {
    Object.entries<any>(condition.values).forEach(([key, value]) => {
      switch (key) {
        case "days": {
          if (value.currentValue === 0) {
            value.error = "Please enter the value for the number of days.";
          }
          break;
        }
        case "codes":
        case "returns":
        case "reasons":
        case "items":
          if (value.currentValue.length === 0) {
            value.error = "Please fill out this field";
          }
          break;
        case "tags": {
          if (value.currentValue.length === 0) {
            value.error = "The order tag field cannot be empty.";
          }
          break;
        }
        case "numbers": {
          if (value.currentValue === 0) {
            value.error = "This field cannot be empty.";
          } else if (value.currentValue < 0) {
            value.error = "Value must be non-negative.";
          }
          break;
        }
        case "countryName": {
          if (value.currentValue.length === 0) {
            value.error = "The order country field cannot be empty.";
          }
          break;
        }
        case "amount": {
          if (condition.operator === "is between") {
            if (
              // Check if both inputs are empty
              (value.currentValue.minValue === undefined &&
                value.currentValue.maxValue === undefined) ||
              (value.currentValue.minValue === "" &&
                value.currentValue.maxValue === "")
            ) {
              value.error = "Both input fields are required.";
              break;
            }
            // Check if any field is empty
            else if (
              value.currentValue.minValue === undefined ||
              value.currentValue.maxValue === undefined ||
              value.currentValue.maxValue === "" ||
              value.currentValue.minValue === ""
            ) {
              value.error = "This field cannot be empty.";
              break;
            } else if (
              //Check they are valid non-negative numbers or not
              isNaN(value.currentValue.minValue) ||
              isNaN(value.currentValue.maxValue) ||
              parseFloat(value.currentValue.minValue) < 0 ||
              parseFloat(value.currentValue.maxValue) < 0
            ) {
              value.error =
                "Enter numeric values only and value must be non-negative.";
              break;
            } else if (
              // Check if min value is greater than max value or both are equal
              parseFloat(value.currentValue.minValue) >=
              parseFloat(value.currentValue.maxValue)
            ) {
              value.error =
                "The first value needs to be smaller than the second value, and they cannot be the same.";
              break;
            } else {
              //Check if decimal value is upto 2 decimal places or not
              const decimalPattern = /^\d+(\.\d{1,2})?$/;
              if (
                !decimalPattern.test(value.currentValue.minValue) ||
                !decimalPattern.test(value.currentValue.maxValue)
              ) {
                value.error =
                  "Decimal values are allowed up to two decimal places.";
                break;
              }
            }
          } else {
            const decimalPattern = /^\d+(\.\d{1,2})?$/;
            //Check if the operator is not between and value is 0 or not filled yet
            if (value.currentValue?.toString()?.length === 0) {
              value.error = "This field cannot be empty.";
              break;
            } else if (value.currentValue == 0) {
              value.error = "This field cannot be zero.";
              break;
            } else if (
              ////Check they are valid non-negative numbers or not
              isNaN(value.currentValue)
            ) {
              value.error = "Enter numeric value only.";
              break;
            } else if (
              ////Check they are valid non-negative numbers or not
              parseFloat(value.currentValue) < 0
            ) {
              value.error = "Value must be non-negative.";
              break;
            } //Check if decimal value is upto 2 decimal places or not
            else if (!decimalPattern.test(value.currentValue)) {
              value.error =
                "Decimal value is allowed up to two decimal places.";
              break;
            }
          }
          break;
        }
        case "return_reason": {
          //When no reason selected
          if (!value.reasonId) {
            value.error = "Please fill out this field";
            break;
          }
          let reason;
          // Check if there are default return reasons available in the state
          if (returnReasonList && returnReasonList.length > 0) {
            // Find the reason based on the provided reason ID
            reason = (returnReasonList as ReasonTypes[]).find(
              (item) => item.id === value.reasonId,
            );
          }

          // Check if a reason is found
          if (reason) {
            // Always validate reasonId
            if (!value.reasonId) {
              value.error = "Please fill out this field";
            } else {
              // Check if the reason has more questions
              if (reason.moreQuestions && reason.moreQuestions.length > 0) {
                // If more questions exist, validate reasonId and elementId
                const moreQuestion = reason.moreQuestions.find(
                  (question) => question.elementId === value.elementId,
                );
                if (moreQuestion) {
                  if (
                    moreQuestion.additionalOptions &&
                    moreQuestion.additionalOptions.length > 0
                  ) {
                    // If additionalOptions exist, validate all fields
                    if (
                      !value.reasonId ||
                      !value.operator ||
                      !value.elementId ||
                      !value.value
                    ) {
                      // Set an error message if any required field is missing
                      value.error = "Please fill out this field";
                    }
                  } else {
                    // Validate reasonId and elementId
                    if (!value.reasonId || !value.elementId) {
                      value.error = "Please fill out this field";
                    }
                  }
                }
              }
            }
          }
          break;
        }
        case "none": {
          value.error = "*Please select an option";
          break;
        }
        default:
          break;
      }
      if (
        (step === "step10" || step === "step11" || step === "step12") &&
        value.error !== undefined
      ) {
        if (key === "tags" || key === "items") {
          isAddItemConditionEnabled = false;
        } else {
          isAddOrderConditionEnabled = false;
        }
      }

      if (step === "step9") {
        if (conditions.length - 1 === idx && value.error !== undefined) {
          isAddOrderConditionEnabled = false;
        }
      }
      if (itemOptions && (step === "step14" || step === "step15")) {
        let itemConditionNames = Object.values(itemOptions).map(
          (option) => option.name,
        );
        if (
          key !== "return_reason" &&
          conditions.length &&
          itemConditionNames.includes(key) &&
          value.error !== undefined
        ) {
          isAddItemConditionEnabled = false;
        }
      }
      if (conditionOptions && step === "step14") {
        let conditionNames = Object.values(conditionOptions).map(
          (option) => option.name,
        );
        if (
          key !== "return_reason" &&
          conditions.length &&
          conditionNames.includes(key) &&
          value.error !== undefined
        ) {
          isAddOrderConditionEnabled = false;
        }
      }

      if (
        step === "step14" &&
        key === "return_reason" &&
        selectedTab.selectReturnReason?.length &&
        value.error !== undefined
      ) {
        isAddReasonConditionEnabled = false;
      }

      if (
        step === "step15" &&
        key !== "return_reason" &&
        value.error !== undefined
      ) {
        if (key === "tags" || key === "items") {
          selectedTab.isAddConditionEnabled = false;
        } else {
          isAddItemConditionEnabled = false;
        }
      }
      if (
        key === "return_reason" &&
        selectedTab.selectReturnReason?.length &&
        value.error !== undefined
      ) {
        isAddReasonConditionEnabled = false;
      }
    });
  });
  return {
    updatedConditions: conditions,
    isAddItemConditionEnabled,
    isAddOrderConditionEnabled,
    isAddReasonConditionEnabled,
  };
};

// Interface for helper function
interface HandleValidateStepParams {
  e: React.MouseEvent<HTMLButtonElement>;
  stepId: number;
  nextStepTransferedStatus?: boolean;
  updateSelectedStep: (stepId: number) => void;
  setMoveNext: (value: React.SetStateAction<boolean>) => void;
  dispatch: any;
}

/**
 * This helper function validates all the questions upto that step when the "Move to Next" button is clicked.
 * If there are any validation errors, it will scroll to the first question with an error.
 * If no errors are found, it will dispatch the moveTONext action to proceed.
 */
export const handleValidateSteps = async ({
  e,
  stepId,
  nextStepTransferedStatus,
  updateSelectedStep,
  setMoveNext,
  dispatch,
}: HandleValidateStepParams): Promise<void> => {
  e.stopPropagation();
  dispatch(setShowValidationErros({ show: true }));

  const validationResponse = await dispatch(validateAllQuestions({ stepId }));
  const validationErrors = validationResponse?.payload;

  if (
    validationErrors &&
    Array.isArray(validationErrors) &&
    validationErrors.length === 0
  ) {
    dispatch(setShowValidationErros({ show: false }));
    dispatch(
      moveToNextStep({
        moveFromStep: stepId,
        nextStepTransferedStatus,
      }),
    );
    setMoveNext(true);
  } else {
    pushTheToast({
      type: "warning",
      position: "top-right",
      text: "Complete the steps and configurations!",
    });

    setTimeout(async () => {
      if (validationErrors && validationErrors.length > 0) {
        const firstError = validationErrors[0];
        await updateSelectedStep(firstError.stepId);

        const questionRef = document.querySelector(
          `[data-question-key="${firstError.questionKey}"]`,
        );

        if (questionRef) {
          questionRef.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }
    }, 300);
  }
};

// Helper to check if the message is valid or not
export const isValidMessage = (message?: string) => {
  // return true if it is non-empty
  return message && !isHTMLEmpty(trimPDIVBR((message ?? "").trim()));
};

// Define the dependent questions for `selectReturnReason`
const dependentQuestions = [
  "retunReasonList",
  "antiReturnStrategies",
  "configureAntiReturnStrategies",
  "isReturnReasonOrFollowUpQuestionsSame",
];

export enum EReturnAutomationQuestionKeys {
  // segment 1
  returnWindow = "returnWindow",
  returnNotEligibleResponseMessage = "returnNotEligibleResponseMessage",

  isReturnPolicySameForAllOrders = "isReturnPolicySameForAllOrders",
  isReturnPolicySameForAllItem = "isReturnPolicySameForAllItem",

  // segment 2
  resolutionOffer = "resolutionOffer",
  // resolutionOfferMessage = "resolutionOfferMessage",
  isResolutionOfferedSameForAllOrders = "isResolutionOfferedSameForAllOrders",

  // segment 3
  selectReturnReason = "selectReturnReason",
  retunReasonList = "retunReasonList",
  isReturnReasonSameForAll = "isReturnReasonSameForAll",

  // Anti refund strategies
  antiReturnStrategies = "antiReturnStrategies",
  configureAntiReturnStrategies = "configureAntiReturnStrategies",
  isReturnReasonOrFollowUpQuestionsSame = "isReturnReasonOrFollowUpQuestionsSame",

  // Return method
  returnMethodForMajorityOfOrders = "returnMethodForMajorityOfOrders",
  returnMethodDisplayMessage = "returnMethodDisplayMessage",
  isReturnMethodSameForAllOrders = "isReturnMethodSameForAllOrders",

  // Order summary
  isRestockingFeeChargedForMajorityOrders = "isRestockingFeeChargedForMajorityOrders",
  usuallyChargedRestockingFee = "usuallyChargedRestockingFee",
  restockingFeeDisplayMessage = "restockingFeeDisplayMessage",
  isRestockingFeeSameForAll = "isRestockingFeeSameForAll",
}

export const getQuestionNumber = (
  key: string,
  isQuestion7AnswerIncludeReasons: boolean,
) => {
  const hiddenKeys = new Set(EReturnAutomationHiddenKeys);

  // Get all keys from the enum
  const allKeys = Object.keys(EReturnAutomationQuestionKeys) as Array<
    keyof typeof EReturnAutomationQuestionKeys
  >;

  // Filter out hidden keys
  let visibleKeys = allKeys.filter((k) => !hiddenKeys.has(k));

  // Adjust the questions based on `question7Answer`
  if (!isQuestion7AnswerIncludeReasons) {
    visibleKeys = visibleKeys.filter((k) => !dependentQuestions.includes(k));
  }
  // Find the index of the provided key in the visible keys
  const questionIndex = visibleKeys.indexOf(
    key as keyof typeof EReturnAutomationQuestionKeys,
  );

  if (questionIndex !== -1) {
    // Return the question number (index + 1 for 1-based indexing)
    return questionIndex + 1;
  }

  // Return undefined if the key is hidden or not found
  return undefined;
};
