import styles from "./VarientImage.module.scss";
import { FileDrop } from "react-file-drop";
import AxiosImg from "src/components/AxiosImg";
import { useRef, useState } from "react";
import { v4 as uuidV4 } from "uuid";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { fileUploader } from "./FileUploader";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import uploadImg from "src/assets/images/ImgUpload.svg";
import deleteImg from "src/assets/images/delete.png";

import { getTypeFromFileName } from "src/utils/utils";
import { CSVTableVarientImage } from "src/features/ReturnAutoWorkFlow/ReturnAutoWorkFlow.types";
import deleteImage from "src/services/ReturnAutoWorkFlow/ConfigModals/deleteImage.service";

interface Props {
  varientImg: null | CSVTableVarientImage;
  setVarientImg: React.Dispatch<
    React.SetStateAction<null | CSVTableVarientImage>
  >;
  setShowFileWarning: React.Dispatch<React.SetStateAction<Warning>>;
  showFileWarning: Warning;
  productId: string;
  integrationId: string;
}

/**
 * Component responsible for drag and dropd / upload image in uploaded csv modal
 * @param param
 * @returns
 */
const ImageUpload = ({
  varientImg,
  setVarientImg,
  setShowFileWarning,
  showFileWarning,
  productId,
  integrationId,
}: Props) => {
  // All required states
  const imgRef = useRef<HTMLInputElement | null>(null);
  const [dragClass, setDragClass] = useState("");
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [imgName, setImgName] = useState(varientImg?.name);
  const [progress, setProgres] = useState<number>();
  const [deleteLoading, setDeleteLoading] = useState(false);

  const ALLOWED_FILE_TYPES = ".png, .jpg, .jpeg , .gif, .svg";
  const SUPPORTED_IMG_TYPES = ["png", "jpg", "jpeg", "gif", "svg"];
  // const ALLOWED_FILES_SIZE = 5 * 1024 * 1024; // 5 MB in bytes

  const filePicker = () => {
    if (imgRef.current) {
      imgRef.current.click();
    } else {
      console.error("imgRef is not initialized");
    }
  };

  /**
   * Helper function to check if file extention is supported or not
   * @param fileName
   * @returns
   */
  const isFileImgSupported = (fileName: string) => {
    const type = getTypeFromFileName(fileName);
    return SUPPORTED_IMG_TYPES.includes(type.toLowerCase().trim());
  };

  /**
   * Helper function to trim file name to show on progress bar
   * @param name File name
   * @param maxLength length of file name to show on progress bar
   * @returns
   */
  const trimFileName = (name: string, maxLength: number) => {
    if (name.length <= maxLength) return name;
    return `${name.substring(0, maxLength)}...`;
  };

  /**
   * Function to set progress percentage
   * @param fileId
   * @param value
   */
  const onProgress = (fileId: string | number, value: number) => {
    setProgres(value);
  };
  /**
   * Function to execute when uploading fails
   * @param fileId
   */
  const onFailed = (fileId: string | number) => {
    pushTheToast({
      position: "top-right",
      text: "Something went wrong while uploading",
      type: "danger",
    });
  };
  /**
   * Function to execute when file uploding is success
   * @param param
   */
  const onSuccess = ({
    varientImgData,
  }: {
    varientImgData: CSVTableVarientImage;
  }) => {
    setVarientImg(varientImgData);
    setShowFileWarning({
      show: false,
      type: null,
    });
  };

  /**
   * Function to execute when a file is selected to upload
   * @param files
   * @param e
   */
  const onFileInputChange = (files: FileList | null, e: any) => {
    if (files) {
      const uploadableFiles = Array.from(files).filter((file) =>
        // Check if file is supported or not
        isFileImgSupported(file.name),
      );
      if (uploadableFiles.length !== 0) {
        const file = uploadableFiles[0];
        const abortController = new AbortController(); //creating instance of abort controller
        // Prepare payload to upload file
        const newAttachments = [
          {
            file,
            id: uuidV4(),
            abortController,
          },
        ];
        setImgName(file.name);
        // Upload file
        fileUploader({
          files: newAttachments,
          onProgress,
          onFailed,
          onSuccess,
          setIsFileUploading,
          integrationId: integrationId,
          productId: productId,
        });
        setShowFileWarning({ show: false, type: null });
      } else {
        // invalid file type
        setShowFileWarning({
          show: true,
          type: "extensionsupport",
        });
        setVarientImg(null);
      }
    }
  };

  /**
   * Handles the deletion of an image associated with a product.
   *
   * This function sets the loading state, attempts to delete the image,
   * and updates the UI based on the success or failure of the operation.
   *
   * @function handleDelete
   * @returns {void}
   */
  const handleDelete = () => {
    setDeleteLoading(true);
    deleteImage({
      integrationId: integrationId,
      productId: productId,
    })
      .then(() => {
        setVarientImg(null);
      })
      .catch((err) => {
        console.error(err);
        pushTheToast({
          position: "top-right",
          text: "Something went wrong while deleting!",
          type: "danger",
        });
      })
      .finally(() => {
        setDeleteLoading(false);
      });
  };

  return (
    <>
      {varientImg === null || varientImg?.imageURL === "" ? (
        <div
          className={`${styles.fileUplaod} ${
            showFileWarning.show ? styles.errorBorder : ""
          }`}
        >
          <input
            accept={ALLOWED_FILE_TYPES}
            style={{ display: "none" }}
            ref={imgRef}
            type="file"
            onChange={(e) => onFileInputChange(e.target.files, e)}
          />
          <FileDrop
            onTargetClick={filePicker}
            onDrop={(files, e) => {
              e.preventDefault();
              onFileInputChange(files, e);
            }}
            onFrameDragEnter={() => setDragClass(styles.dragging)}
            onFrameDragLeave={() => setDragClass("")}
            onDragOver={() => setDragClass(styles.dragged)}
            onDragLeave={() => setDragClass(styles.dragging)}
            onFrameDrop={() => setDragClass("")}
            className={`${styles.dnd} ${dragClass}`}
          >
            <span className={styles.dndSpan}>
              <div
                className={`d-flex align-items-center justify-content-center cursor-pointer `}
              >
                {!showFileWarning.show ? (
                  <div>
                    {isFileUploading ? (
                      <div className="d-flex align-items-center">
                        <img
                          src={uploadImg}
                          alt="upload"
                          height={25}
                          width={25}
                        />
                        <p className={`mb-0 ${styles.imgName}`}>Uploading...</p>
                      </div>
                    ) : (
                      <div className="d-flex align-items-center">
                        <img
                          src={uploadImg}
                          alt="upload"
                          height={25}
                          width={25}
                        />
                        <p className={`mb-0 ${styles.dropHead}`}>
                          Drop your file, or browse
                        </p>
                      </div>
                    )}
                  </div>
                ) : null}
                {showFileWarning &&
                showFileWarning.type === "extensionsupport" ? (
                  <div className="d-flex align-items-center">
                    <img
                      src={uploadImg}
                      alt="upload"
                      height={25}
                      width={25}
                    />
                    <p className={`mb-0 ms-1 ${styles.errorDesc}`}>Failed</p>
                    <OverlayTrigger
                      placement="top"
                      trigger={"hover"}
                      overlay={
                        <Tooltip className={` ${styles.toolTipCustom}`}>
                          Upload files ending in .jpg, .jpeg, .gif, .png, .svg.
                        </Tooltip>
                      }
                    >
                      {({ ref, ...triggerHandler }) => (
                        <div
                          {...triggerHandler}
                          ref={ref}
                        >
                          <i
                            className={`fa-solid fa-circle-exclamation ms-1 ${styles.caution}`}
                          ></i>
                        </div>
                      )}
                    </OverlayTrigger>
                  </div>
                ) : null}
              </div>
            </span>
          </FileDrop>
        </div>
      ) : (
        <div
          className={`d-flex align-items-center justify-content-between ${styles.fileUplaoded}`}
        >
          <div className="d-flex align-items-center">
            <div className={`${styles.imgDnd}`}>
              <img
                src={varientImg?.imageURL}
                className={styles.showImg}
                alt="img"
              />
            </div>
            <span className={`${styles.imgName} ms-1`}>
              {trimFileName(imgName ?? "", 10)}
            </span>
          </div>
          <div onClick={handleDelete}>
            <img
              src={deleteImg}
              alt="delete"
              className={`${styles.deleteImg} me-2`}
            />
            {deleteLoading ? (
              <Spinner
                size="sm"
                variant="secondary"
                className=" me-2"
              />
            ) : (
              ""
            )}
          </div>
        </div>
      )}
    </>
  );
};

export interface Warning {
  show: boolean; // flag to show error
  type: "extensionsupport" | null;
}

const VarientImage = ({
  value,
  productId,
  integrationId,
}: {
  value: null | CSVTableVarientImage;
  productId: string;
  integrationId: string;
}) => {
  const [varientImg, setVarientImg] = useState<null | CSVTableVarientImage>(
    value,
  );
  const [showFileWarning, setShowFileWarning] = useState<Warning>({
    show: false, // flag to show error
    type: null,
  });
  return (
    <div>
      <ImageUpload
        varientImg={varientImg}
        setVarientImg={setVarientImg}
        showFileWarning={showFileWarning}
        setShowFileWarning={setShowFileWarning}
        integrationId={integrationId}
        productId={productId}
      />
    </div>
  );
};

export default VarientImage;
