import { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import AxiosImg from "src/components/AxiosImg";
import InnerChatAttachments from "src/components/InnerChatAttachments/InnerChatAttachments";
import ParseHTML from "src/components/ParseHTML";
import QuillEditorContainer from "src/components/QuillEditorContainer";
import ShowTime from "src/components/ShowTime";
import UserAvatar from "src/components/UserAvatar";
import Pagination from "src/routes/TicketList/children/Pagination/Pagination";
import {
  findAndReplaceCID,
  getDraftTextColor,
  // getMessageWithReadMoreNLess,
} from "src/utils/utils";
import styles from "./DraftMsg.module.scss";
import { v4 as uuid } from "uuid";
import { showAttachment } from "src/services/Attachment/showAttachment";
import { useAppDispatch } from "src/store/store";
import { setIsDraftEditing } from "src/store/slices/draftFlow/draftFlow.slice";
import TinyMCEContainer from "src/components/TinyMCEContainer";

interface Props {
  statusName: string;
  agentImgURL?: string;
  agentName: string;
  messageDate: string;
  showHistory?: boolean;
  currentPage?: number;
  totalPages?: number;
  setCurrentPage?: (page: number) => void;
  canEdit?: boolean;
  editDraftMsg?: (editedMsg: string, callback: () => void) => void;
  messageContent: string;
  showChatIcon?: boolean;
  chatIconAction?: (e: any) => void;
  showRemarkCountIcon?: boolean;
  remarkCount?: number;
  selectedFilesEditDraft?: any;
  setSelectedFilesEditDraft?: Function;
  isFileUploadingEditDraft?: any;
  setIsFileUploadingEditDraft?: Function;
  messageData?: any;
  attachments?: any;
  isFromInnerTicket?: boolean | undefined;
  isPublicAttachmentUrl?: boolean;
}

function DraftMsgView({
  statusName,
  agentImgURL,
  agentName,
  messageDate,
  showHistory = false,
  currentPage = 0,
  totalPages = 0,
  setCurrentPage = () => {},
  canEdit = false,
  editDraftMsg = () => {},
  messageContent,
  showChatIcon = false,
  chatIconAction = () => {},
  showRemarkCountIcon = false,
  remarkCount = 0,
  selectedFilesEditDraft = [],
  setSelectedFilesEditDraft = () => {},
  isFileUploadingEditDraft,
  setIsFileUploadingEditDraft = () => {},
  messageData = [], // added to show attachments
  attachments,
  isFromInnerTicket = false,
  isPublicAttachmentUrl = false,
}: Props) {
  const [isEditing, setIsEditing] = useState(false);

  const [edited, setEdited] = useState(messageContent);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (currentPage !== totalPages) {
      setIsEditing(false);
      dispatch(setIsDraftEditing({ isDraftEditing: false }));
      setEdited(messageContent);
    }
  }, [currentPage]);

  useEffect(() => {
    setEdited(messageContent);
  }, [messageContent]);

  return (
    <div
      className={`${styles.bottomDraftContainer} p-3 my-2`}
      style={{ borderColor: getDraftTextColor(statusName) }}
    >
      {loading && (
        <div className="h-0">
          <div
            className={`${styles.loading} d-flex flex-column justify-content-center`}
          >
            <Spinner
              animation="border"
              className={`m-auto`}
            />
          </div>
        </div>
      )}
      <div
        className={`d-flex justify-content-between mx-0 mb-3 ${styles.draftMsg_header}`}
      >
        <div className={`d-flex mt-0`}>
          {agentImgURL && agentImgURL.trim() !== "" ? (
            <AxiosImg
              url={agentImgURL}
              className={`${styles.profileImg}`}
              isDirectURL={isPublicAttachmentUrl}
            />
          ) : (
            <UserAvatar
              name={agentName}
              size={35}
              className={`${styles.profileImg}`}
            />
          )}
          <div className={`d-flex flex-column my-auto mx-2`}>
            <div className={`mt-0 ${styles.name}`}>
              {agentName.split(" ")[0]} changed draft status for this ticket
            </div>
            <div className={`${styles.status} d-flex`}>
              <span className={`mx-0 my-auto`}>Draft Status:</span>
              <span
                className={`mx-1 my-auto`}
                style={{ color: getDraftTextColor(statusName) }}
              >
                {statusName}
              </span>
              {showRemarkCountIcon && (
                <span className={`mx-3 my-auto ${styles.remarkCount}`}>
                  <i className={`far fa-comment-alt m-auto`}></i>
                  {` ${remarkCount}`}
                </span>
              )}
            </div>
          </div>
          <ShowTime
            className={`mt-0`}
            dateTime={new Date(messageDate)}
          />
        </div>
        <div className={`d-flex mt-0 ${styles.pagination_history}`}>
          {showHistory ? (
            <div className={`d-flex mt-0 ${styles.history}`}>
              <span className={`mt-0 mx-0`}>
                <i className="fa-solid fa-clock-rotate-left"></i> See Version
                History
              </span>
              <div className={`mt-0 mx-0 ${styles.buttons}`}>
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  setCurrentPage={setCurrentPage}
                  showLR={false}
                  size={17}
                  colorCombo={"bg"}
                />
              </div>
            </div>
          ) : (
            <div></div>
          )}
          {showChatIcon && (
            <div
              className={`${styles.chatIcon} d-flex justify-content-center`}
              onClick={chatIconAction}
            >
              <i className={`fa-solid fa-message m-auto`}></i>
              <span className={`w-0 h-0`}>
                <i className={`fa-solid fa-circle ${styles.circle}`}></i>
              </span>
            </div>
          )}
        </div>
      </div>
      {isEditing === false ? (
        <>
          <ParseHTML
            html={messageContent}
            className={`${styles.draftPreview} px-1`}
            showReadMoreLess={isFromInnerTicket === true}
          />
          <InnerChatAttachments messageData={messageData} />
          {canEdit && (
            <Button
              className={`d-flex mt-2 mx-0 ${styles.editDraft}`}
              onClick={(e) => {
                setSelectedFilesEditDraft(attachments || []); // when edit is clicked show the attachments as selected files
                setIsEditing(true);
                dispatch(setIsDraftEditing({ isDraftEditing: true }));
              }}
            >
              <i className="fa-solid fa-pen my-auto mx-1"></i>
              <span className={`my-auto`}>Edit Draft</span>
            </Button>
          )}
        </>
      ) : (
        <TinyMCEContainer
          value={edited}
          onChange={(value) => {
            setEdited(value);
          }}
          className={`${
            isEditing === true ? styles.draftPreviewEditorMain : ``
          }`}
          editorClassName={`${styles.draftPreview} px-1 max-content-height important`}
          options={{ placeholder: "Type here..." }}
          disableAttachment={false}
          selectedFiles={selectedFilesEditDraft}
          setSelectedFiles={setSelectedFilesEditDraft}
          isFileUploading={isFileUploadingEditDraft}
          setIsFileUploading={setIsFileUploadingEditDraft}
          uniqueID={uuid()}
          canDeleteInline={false}
          attachmentUploadData={attachments}
          buttons={
            <>
              <button
                className={`btn d-flex my-auto mx-2 cancelBtn`}
                onClick={(e) => {
                  // todo: need to cancel uploads if there is any
                  setSelectedFilesEditDraft([]); // clear the selected files
                  setEdited(messageContent);
                  setIsEditing(false);
                  dispatch(setIsDraftEditing({ isDraftEditing: false }));
                }}
              >
                <span className={`my-auto`}>Cancel</span>
              </button>
              <button
                className={`btn d-flex my-auto mx-0 saveBtn`}
                disabled={isFileUploadingEditDraft === true}
                onClick={(e) => {
                  setLoading(true);
                  editDraftMsg(findAndReplaceCID(edited), () => {
                    setSelectedFilesEditDraft([]);
                    setIsEditing(false);
                    dispatch(setIsDraftEditing({ isDraftEditing: false }));
                    setLoading(false);
                  });
                }}
              >
                <span className={`my-auto`}>Submit as Edited</span>
              </button>
            </>
          }
        />
      )}
    </div>
  );
}

export default DraftMsgView;
