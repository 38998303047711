import { useCallback, useEffect, useState } from "react";
import Loader from "src/components/Loader";
import { EAutomationType } from "src/routes/BotSettings/Children/AutomationList/AutomationList";
import Pagination from "src/routes/TicketList/children/Pagination/Pagination";
import { IAction } from "src/services/Automation/getAutomationById.service";
import DispatchSection from "./Children/DispatchSection/DispatchSection";
import DispatchTableRows from "./Children/DispatchTableRows/DispatchTableRows";
import DispatchTable from "./Children/DispatchTable/DispatchTable";
import NoData from "./Children/NoData/NoData";
import styles from "./DispatchModal.module.scss";
import useConfigureDispatchRule from "./useConfigureDispatchRule";

interface Props {
  onHide: () => void;
  updateStatus: (status: IAction["status"]) => void;
  integrationId: string;
  automationType: EAutomationType;
}

export interface DispatchModalType {
  type: "create" | "edit";
  configurationId?: string;
}

const DispatchModal = ({
  onHide,
  updateStatus,
  integrationId,
  automationType,
}: Props) => {
  const [showDispatch, setShowDispatch] = useState<DispatchModalType | null>(
    null,
  );

  const {
    data,
    currentPage,
    totalPages,
    isFetching,
    refetch,
    setCurrentPage,
    invalidateQuery,
  } = useConfigureDispatchRule({ integrationId, type: automationType });

  /**
   * Effect for updating the configuration status
   */
  useEffect(() => {
    // Check if we have atleast one dispatch rule
    if (totalPages !== 0) {
      updateStatus("completed");
    } else {
      updateStatus("pending");
    }
  }, [totalPages, updateStatus]);

  const onHideDispatchRules = useCallback(
    (configId: string | null) => {
      if (configId) {
        refetch();
      }

      setShowDispatch(null);
    },
    [refetch],
  );

  return (
    <div>
      <div className="d-flex align-items-center">
        <div>
          <div
            className={`${styles.modalBack}`}
            onClick={onHide}
            id="bug_close_modal"
          >
            <span className="cursor-pointer">
              <i className="fa-solid fa-xmark"></i>
            </span>
          </div>
        </div>
        <h3 className={`mb-2 ms-3 ${styles.heading}`}>
          {showDispatch?.type === "edit"
            ? "Edit dispatching rules"
            : "Configure Dispatching Rules"}
        </h3>
      </div>
      {showDispatch ? (
        <DispatchSection
          dispatchType={showDispatch}
          setDispatchType={setShowDispatch}
          onHide={onHideDispatchRules}
          onEdit={invalidateQuery}
          integrationId={integrationId}
          automationType={automationType}
        />
      ) : (
        <div className={`mt-5 ${styles.ruleBox}`}>
          <div className={`${styles.innerBox}`}>
            <div className="d-flex justify-content-between align-items-center">
              <h4 className={`mb-0 ${styles.heading}`}>
                All dispatching rules
              </h4>
              <button
                className={`me-1 ${styles.configureBtn} px-3`}
                onClick={() => setShowDispatch({ type: "create" })}
                disabled={
                  data &&
                  data.dispatchConfig.rows.length >= data.metaData.maximumLimit
                }
              >
                <span>+ Create new</span>
              </button>
            </div>
            {isFetching ? (
              <Loader />
            ) : (
              <div className={`mt-4 ${styles.table}`}>
                <DispatchTable
                  columns={data?.dispatchConfig.columns ?? []}
                  showActionBtns={
                    data !== undefined &&
                    data.dispatchConfig.actions.length !== 0
                  }
                />
                {!data || data.dispatchConfig.rows.length === 0 ? (
                  <NoData
                    configureRule={() => setShowDispatch({ type: "create" })}
                  />
                ) : (
                  data.dispatchConfig.rows.map((config) => {
                    return (
                      <DispatchTableRows
                        key={config.configurationId}
                        configData={config}
                        actions={data.dispatchConfig.actions}
                        columns={data.dispatchConfig.columns}
                        integrationId={integrationId}
                        automationType={automationType}
                        onDelete={() => refetch()}
                        showEdit={() => {
                          setShowDispatch({
                            type: "edit",
                            configurationId: config.configurationId,
                          });
                        }}
                      />
                    );
                  })
                )}

                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DispatchModal;
