/**
 * This file is the custome hook file.
 * It contains the useArticleFilter hook which is explained below.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

/* eslint-disable react-hooks/exhaustive-deps */
import { useInfiniteQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  reportCurrentStatusActions,
  useReportFilters,
} from "src/routes/Report/hooks/reportFilters/useReportFilters";
import {
  Articles,
  GetAllArticleOnlyParams,
  getAllArticleOnly,
} from "src/services/KnowledgeBase/Analytics/getAllArticleOnly.service";

/**
 * This is a custome hook created to maintain the article filters.
 */
const useArticleFilter = () => {
  const [ddOpen, setDdOpen] = useState(false);
  const dropdownArrowSpanRef = useRef<any>();

  /**
   * Getting the integration id for fetching KB reports.
   */
  const { integrationId } = useParams();
  const pageIntegrationId = integrationId
    ? decodeURIComponent(integrationId)
    : "";

  const payload: GetAllArticleOnlyParams = {
    start: 0,
    limit: 15,
    integrationId: pageIntegrationId,
  };

  const {
    data,
    isLoading,
    isError,
    hasNextPage,
    fetchNextPage,
    refetch,
    isRefetching,
    isFetchingNextPage,
    status,
    fetchStatus,
  } = useInfiniteQuery({
    queryKey: ["getAllArticleOnly", payload],
    /**
     * Here we are using getAllArticleOnly to get the all category data based on the pageParam parameters applied.
     */
    queryFn: ({ pageParam = payload }) => getAllArticleOnly(pageParam),
    /**
     * This is the callback function used when the infinite query is trigered to fetch the next page of infinite scroll.
     */
    getNextPageParam: (lastPage: any, allPages) => {
      const data = allPages.flatMap((data: any) => data.data);
      if (data.length < lastPage.metaData?.total) {
        const nextPageParam = {
          ...payload,
          start: data.length,
        };
        return nextPageParam;
      }
      return null;
    },
  });

  const [allArticles, setAllArticles] = useState<Articles[] | undefined>();

  useEffect(() => {
    if (data) {
      /**
       * Page returns the array of array so flat mapping them to get the data in required format.
       */
      const allArticles = data?.pages.flatMap(
        (data: any) => data.data
      ) as any as Articles[];
      /**
       * Then setting up the data in allArticles state
       */
      setAllArticles(allArticles);
    }
  }, [data]);

  /**
   * Extracting all the article ids in an array.
   */
  const allArticleIds = useMemo(() => {
    return allArticles?.map((article) => article.articleId.toString());
  }, [allArticles]);

  const { currentStatus, dispatch } = useReportFilters();

  const selectedArticles = useMemo(() => {
    return currentStatus.selectedArticles;
  }, [currentStatus]);

  /**
   * Function is used to select all the articles in the top level filters.
   */
  const selectAllArticles = useCallback(
    (e: any) => {
      if (e.target.checked) {
        dispatch([
          reportCurrentStatusActions.setFilters,
          {
            selectedArticles: allArticleIds,
          },
        ]);
      } else {
        dispatch([
          reportCurrentStatusActions.setFilters,
          { selectedArticles: [] },
        ]);
      }
    },
    [allArticleIds]
  );

  /**
   * Function is used to clear all the articles in the top level filters.
   */
  const clearAllArticles = useCallback(() => {
    dispatch([reportCurrentStatusActions.setFilters, { selectedArticles: [] }]);
  }, []);

  /**
   * Function is used to check uncheck all the articles in the top level filters.
   */
  const checkUncheckArticles = useCallback(
    (e: any) => {
      if (e.target.checked) {
        dispatch([
          reportCurrentStatusActions.setFilters,
          { selectedArticles: [...selectedArticles, e.target.value] },
        ]);
      } else {
        dispatch([
          reportCurrentStatusActions.setFilters,
          {
            selectedArticles: selectedArticles.filter(
              (id) => id !== e.target.value
            ),
          },
        ]);
      }
    },
    [selectedArticles]
  );

  return {
    ddOpen,
    setDdOpen,
    dropdownArrowSpanRef,
    allArticles,
    isLoading,
    isError,
    hasNextPage,
    fetchNextPage,
    refetch,
    isRefetching,
    isFetchingNextPage,
    status,
    fetchStatus,
    selectAllStatus: selectAllArticles,
    clearAllStatus: clearAllArticles,
    checkUncheckStatus: checkUncheckArticles,
    selectedArticles,
    allArticleIds,
  };
};

export default useArticleFilter;
