import { useRef, useState } from "react";
import Select, { components } from "react-select";
import { GetBrandParams } from "src/services/EmailIntegration/getBrands";
import AddBrand from "../AddBrand/AddBrand";
import styles from "../ConnectAccount.module.scss";
import useBrandsDropdown from "./useBrandsDropdown";

interface Props {
  isDisabled: boolean;
  onSelectBrand: (
    brandEmail: string,
    brandName: string,
    id?: number | string,
  ) => void;
  defaultEmail?: string;
  hideTitle?: boolean;
}

/**
 * Constant Payload to initialize data
 */
const PAYLOAD: GetBrandParams = {
  limit: 100,
  start: 0,
};

/**
 * React Component for Showing Dropdown with brands data and add brand popup in email integration
 */
const BrandsDropdown = ({
  isDisabled,
  onSelectBrand,
  defaultEmail,
  hideTitle,
}: Props) => {
  const targetRef = useRef<HTMLDivElement>(null);

  const {
    showAddBrand,
    selectedOption,
    options,
    showLoader,
    handleBrandChange,
    fetchNextPageHandler,
    handleAddBrand,
    handleHideAddBrand,
  } = useBrandsDropdown(PAYLOAD, onSelectBrand, isDisabled);
  // Modify DropdownIndicator to use isMenuOpen state
  // Add state to manage menu open/close
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const DropdownIndicator = ({ ...props }: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <span className={`pe-2 ${styles.dropdonArrow}`}>
          {/* Toggle icon based on isMenuOpen */}
          <i className={`fa-solid fa-caret-${isMenuOpen ? "up" : "down"}`}></i>
        </span>
      </components.DropdownIndicator>
    );
  };
  return (
    <div className="mb-3 position-relative">
      {!hideTitle && (
        <span className={`form-label d-block ${styles.labelName}`}>
          Choose Brand
        </span>
      )}

      {/* Add Brand Popup */}
      {showAddBrand && (
        <AddBrand
          targetRef={targetRef}
          onHide={handleHideAddBrand}
          selectedBrand={handleAddBrand}
        />
      )}

      {/* Brands List */}
      <div className={`w-100`}>
        <Select
          className={`${styles.formInput}`}
          placeholder="Choose brand"
          options={[
            ...options,
            {
              value: "addBrand",
              label: "+ Add a brand",
              isAddBrand: true,
            },
          ]}
          value={
            defaultEmail
              ? {
                  value: defaultEmail,
                  label: defaultEmail,
                  isAddBrand: false,
                }
              : selectedOption
          }
          isDisabled={isDisabled}
          onChange={handleBrandChange}
          components={{
            IndicatorSeparator: null,
            DropdownIndicator,
          }}
          styles={customStyles}
          classNamePrefix="custom-select"
          isSearchable={false}
          isMulti={false}
          onMenuScrollToBottom={fetchNextPageHandler}
          isLoading={showLoader}
          onMenuOpen={() => setIsMenuOpen(true)} // Set menu open state
          onMenuClose={() => setIsMenuOpen(false)} // Set menu close state
        />
      </div>
    </div>
  );
};

const customStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    textAlign: "left",
    font: "normal normal normal 14px/21px Poppins",
    letterSpacing: "0px",
    color:
      state.data && state.data.value === "addBrand" ? "#0B68BB" : "#333333",
    background: "FFFFFF 0% 0% no-repeat padding-box",
    padding: "6px 12px",
    backgroundColor: state.isSelected ? "#F9F9F9" : "#FFFFFF",
    "&:hover": {
      background: "#F9F9F9 0% 0% no-repeat padding-box",
      borderRadius: "4px",
    },
    "&:active": {
      background: "#EEEEEE 0% 0% no-repeat padding-box",
    },
  }),
  menu: (provided: any, _: any) => ({
    ...provided,
    marginTop: "0px",
    borderRadius: "9px",
    boxShadow: "0px 3px 6px #00000029",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
  }),
  valueContainer: (provided: any, _: any) => ({
    ...provided,
    borderRadius: "9px",
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    minHeight: "41px",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#FCFCFC",
    font: "normal normal normal 12px/18px Poppins",
    border: state.isFocused ? "1px solid #ECECEC" : "1px solid #ECECEC",
    boxShadow: state.isFocused
      ? "0 0 0 0.2rem rgba(93, 147, 255, 0.25)"
      : "none",
    "&:hover": {
      borderColor: "#5d93ff",
    },
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: "#707070",
  }),
};

export default BrandsDropdown;
