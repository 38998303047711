import { useCallback, useEffect, useState } from "react";
import { Editor as TinyMceEditor } from "tinymce";

interface Props {
  editorRef: React.MutableRefObject<TinyMceEditor | null>;
  disableAttachment?: boolean;
  dragClasses: { [key: string]: string };
}
function useDragAndDropStyling({
  editorRef,
  disableAttachment,
  dragClasses,
}: Props) {
  const [dragClass, setDragClass] = useState("");

  const handleDragLeave = useCallback(()=>{
    setDragClass('');
  },[]);

  useEffect(() => {
    const editorContainer = editorRef.current?.getContainer();
    const doc = editorRef.current?.getDoc();

    if (editorContainer && !disableAttachment) {
      // Prevent the default behavior of the dragleave event within the iframe
      doc?.addEventListener('dragleave', handleDragLeave);
    }

    return ()=>{
      doc?.removeEventListener('dragleave', handleDragLeave);
    }
    
  }, [editorRef.current, disableAttachment, dragClasses]);

  return {
    dragClass,
    setDragClass,
  };
}

export default useDragAndDropStyling;
