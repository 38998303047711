/**
 * TaggableTextField Component
 *
 * This component represents a multi-select input for entering and displaying multiple tags
 *
 * @author @yuvaraj-busibud
 */
import React, { useEffect, useRef, useState } from "react";
import styles from "./TaggableTextField.module.scss";

interface Props {
  placeholder: string;
  value: string | null | Array<string>;
  handleChange: (value: Array<string>) => void;
  type?: "text" | "email";
  error?: string | null;
}
const TaggableTextField = ({
  placeholder,
  value,
  handleChange,
  type = "text",
  error,
}: Props) => {
  // State for managing tags and the current input value
  const [tags, setTags] = useState<Array<string>>([]);
  const [currentTag, setCurrentTag] = useState<string>("");

  // Reference to the input element for managing focus
  const inputRef = useRef<HTMLDivElement>(null);

  // Ref to track whether tags have changed during input
  const tagChanged = useRef(false);

  // Effect to update tags when the 'value' prop changes
  useEffect(() => {
    if (value && Array.isArray(value)) {
      setTags(value);
    } else {
      setTags([]);
    }
  }, [value]);

  // Effect to handle tag changes and call the 'handleChange' callback
  useEffect(() => {
    if (tagChanged.current) {
      handleChange(tags);
      tagChanged.current = false;
    }
  }, [tags]);

  const handleInputChange = () => {
    if (inputRef.current) {
      const inputValue = inputRef.current.innerText;
      const tagsArray = inputValue
        .split("\n")
        .filter((tag) => tag.trim().length > 0);
      if (tagsArray.length > 0) {
        tagChanged.current = true;
        setTags((prev) => {
          let uniqueTags = new Set([
            ...prev,
            ...tagsArray.map((tag) => tag.trim()).filter(Boolean),
          ]);
          return Array.from(uniqueTags);
        });

        inputRef.current.innerHTML = "";
      }
    }
  };

  const removeTag = (tag: string | number) => {
    tagChanged.current = true;
    setTags((prevTags) => prevTags.filter((t) => t !== tag));
    inputRef.current?.focus();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Escape") {
      event.preventDefault();
      if (inputRef.current) {
        inputRef.current.innerHTML = "";
      }
    } else if (event.key === "Enter") {
      event.preventDefault();
      handleInputChange();
    }
  };

  return (
    <>
      <div
        className={`${styles.tagInputContainer} ${
          error ? "border border-danger" : "mb-1"
        } d-flex flex-column justify-content-between`}
        onClick={() => {
          inputRef.current?.focus();
        }}
      >
        <div className={`${styles.tagsContainer}`}>
          {tags.map((tag, index) => (
            <div
              key={index}
              className={`${styles.tag}`}
            >
              {tag}
              <span
                className={`${styles.removeTag}`}
                onClick={() => removeTag(tag)}
              >
                &#x2715;
              </span>
            </div>
          ))}
          <div
            className={`${styles.tagInput}`}
            contentEditable
            ref={inputRef}
            onKeyDown={handleKeyDown}
            onBlur={(e) => handleInputChange()}
            placeholder={tags.length ? "" : placeholder}
          >
            {/* {currentTag} */}
          </div>
        </div>
        <div className={`${styles.footer}`}>
          <div className={`d-flex justify-content-end pe-2`}>
            <span className={`me-3 ${styles.toConfirm}`}>
              <strong className="pe-1">↵</strong>
              to confirm
            </span>
            <span className={`${styles.toConfirm}`}>
              <span className={`${styles.escText} pe-1`}>esc</span>
              to dismiss
            </span>
          </div>
        </div>
      </div>

      {error ? (
        <span className={`${styles.errorText} text-danger`}>{error}</span>
      ) : null}
    </>
  );
};

export default TaggableTextField;
