import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";
import { FollowUpQuestions, GiveOfferType } from "src/store/slices/initiateReturn/antiRefundConfig.slice";


export interface updateRequestRefundSelectedItemParams {
  id: number;
  integrationId: number;
  scenarioId: string | null;
  itemIds: number[] | string[] | undefined;
  tags: string[] | undefined;
  enabled?: boolean;
  followUpQuestions?: FollowUpQuestions[];
  additionalInformation?: {
    enabled: boolean;
    message: string;
    extendReturnPeriod: {
      enabled: boolean;
      days: number;
    };
  };
  giveOffer?: GiveOfferType & {
    enabled: boolean;
    message: string;
  };
  offerExchange?: {
    enabled: boolean;
    message: string;
    exchangeWithSameItems: boolean;
    exchangeWithOtherItems: boolean;
  };
}

const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/updateRefundRequestSelectedItems`
  : "/api/setting/automation/refund/step/12/updateRefundRequestSelectedItems";

export async function fetchUpdateRefundRequestSelectedItem(
  params: updateRequestRefundSelectedItemParams
) {
  const { data } = await axiosJSON.post(apiEndPoint,params);

  if (data.error === true) {
    throw new Error("Some Error Occurred while fetching data");
  }

  return data.data;
}
