import { OverlayTrigger, Popover } from "react-bootstrap";
import { BsExclamationDiamondFill } from "react-icons/bs";

import "./CustomPopover.css";

function index(props: { errorCount: number }) {
  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 250, hide: 400 }}
      overlay={
        <Popover
          id="popover-basic"
          bsPrefix={`popover red`}
          className="p-1"
          style={{ backgroundColor: "#FFDCDC" }}
        >
          <Popover.Body className="p-1">
            <ul
              className={
                "small text-danger me-1 mb-0 ps-3 d-flex flex-column justify-content-center"
              }
            >
              <li>Please save this tab </li>
              <li>{props.errorCount} errors in this tab </li>
            </ul>
          </Popover.Body>
        </Popover>
      }
    >
      {props.errorCount > 0 ? (
        <div className="fs-6 ms-2 text-danger ">
          <BsExclamationDiamondFill />
        </div>
      ) : (
        <></>
      )}
    </OverlayTrigger>
  );
}

export default index;
