import { getAxiosImage } from "../GetFiles";
import loading from "src/assets/images/loading.gif";
import noData from "src/assets/images/no-data.png";

export interface IShowAttachment {
  html: string;
  root?: Document;
}

const CIDURL = `/api/attachment/uploads/cid/`;

export function showAttachment({ html, root }: IShowAttachment) {
  try {
    const contentIds: Array<string> = [];
    const ele = (root ?? document).createElement("div");
    ele.innerHTML = html;
    let images = ele.querySelectorAll("img[src]");
    images.forEach((image) => {
      try {
        let contentId = image.getAttribute("src");
        if (contentId?.includes("cid:")) {
          contentId = contentId.split(":")[1];
          contentIds.push(contentId);
          image.setAttribute("content-id", contentId);
          image.setAttribute("src", loading);
          image.setAttribute("data-img-loading", "true");
        }
      } catch (e) {
        console.error(e);
      }
    });
    setTimeout(() => {
      contentIds.forEach((id) => {
        let url = CIDURL + id;
        getAxiosImage(url)
          .then((res) => {
            (root ?? document)
              .querySelectorAll(`img[content-id="${id}"]`)
              ?.forEach((ele) => {
                ele?.setAttribute("src", res.image);
                ele?.removeAttribute("data-img-loading");
              });
          })
          .catch((err) => {
            console.error(err);
            (root ?? document)
              .querySelectorAll(`img[content-id="${id}"]`)
              ?.forEach((ele) => {
                ele?.setAttribute("src", noData);
                ele?.removeAttribute("data-img-loading");
              });
          });
      });
    }, 0);
    return ele.innerHTML;
  } catch (e) {
    // console.error(e);
    return html;
  }
}

export async function showAttachmentAsync({ html, root }: IShowAttachment) {
  try {
    const contentIds: Array<string> = [];
    const ele = (root ?? document).createElement("div");
    ele.innerHTML = html;
    let images = ele.querySelectorAll("img[src]");
    images.forEach((image) => {
      try {
        let contentId = image.getAttribute("src");
        if (contentId?.includes("cid:")) {
          contentId = contentId.split(":")[1];
          contentIds.push(contentId);
          image.setAttribute("content-id", contentId);
          image.setAttribute("src", loading);
        }
      } catch (e) {
        console.error(e);
      }
    });
    for (let i = 0; i < contentIds.length; i++) {
      const id = contentIds[i];
      let url = CIDURL + id;
      try {
        const res = await getAxiosImage(url);
        ele.querySelectorAll(`img[content-id="${id}"]`)?.forEach((ele) => {
          ele?.setAttribute("src", res.image);
        });
      } catch {
        ele.querySelectorAll(`img[content-id="${id}"]`)?.forEach((ele) => {
          ele?.setAttribute("src", noData);
        });
      }
    }
    return ele.innerHTML;
  } catch (e) {
    // console.error(e);
    return html;
  }
}
