import { NavLink } from "react-router-dom";
import { Message } from "src/services/LiveChat/messageExchange/getMessages";
import { useAppSelector } from "src/store/store";
import {
  convertGmtToUserTimezone,
  formatDateTimeString,
  getMonthNameDateAmPmTime,
} from "src/utils/dateLibrary";
import styles from "../../../../LiveChat.module.scss";
import { BsExclamationDiamondFill } from "react-icons/bs";
import IntegrationFailureText from "src/components/IntegrationFailureModal/children/IntegrationFailureText/IntegrationFailureText";

function MessageEvent({
  messageText,
  sentBy,
  sendTimeGmt,
  eventDetails,
}: Message) {
  const userTimezone = useAppSelector(
    (state) => state.globals.currentUserData?.userTimezone,
  );
  return (
    <>
      <p className={`${styles.mark}`}>
        <span className={`me-1 ${styles.markDot}`}></span>
        {messageText ? <span>{messageText} </span> : ""}
        {eventDetails !== undefined &&
        eventDetails.type === "event_chat_ticket_created" ? (
          <NavLink
            className={`ps-1`}
            target={"_blank"}
            to={`/tickets/all/ticket-view/ticket/${eventDetails?.data?.ticketId}`}
          >
            {"see ticket"}
          </NavLink>
        ) : null}{" "}
        {", "}
        {getMonthNameDateAmPmTime(
          formatDateTimeString(
            convertGmtToUserTimezone(sendTimeGmt, userTimezone),
          ),
          true,
        )}
        {/* June 12 Incase of integration failure event, show integarion not available text */}
        {eventDetails?.data?.integrationFailure && (
          <IntegrationFailureText
            integrationFailure={eventDetails?.data?.integrationFailure ?? null}
          />
        )}
      </p>
    </>
  );
}

export default MessageEvent;
