import { Dropdown, Modal, Spinner } from "react-bootstrap";
import CreateTicket from "src/routes/LiveChat/Children/MainChat/Children/ChatTabs/Children/CreateTicket/CreateTicket";
import SuccessMsg from "src/routes/LiveChat/Children/MainChat/Children/ChatTabs/Children/CreateTicket/SuccessMsg/SuccessMsg";
import styles from "../../../../HistoryInnerView.module.scss";
import useResolveHead from "./hooks/useResolveHead";
import style from "./ResolveHead.module.scss";
import { POPPER_CONFIG } from "src/globals/constants";
import { RouteNames } from "src/containers/SideBar/SideBar";
function ResolveHead({
  chatRequestId,
  isResolved = false,
  ticketId,
  customerEmail,
  setIsResolved,
  setTicketId,
  hideActionBtn = false,
  showGoToChat = false,
  chatStatus,
  isAnonymousCustomer,
}: {
  chatRequestId: string | number;
  isResolved?: boolean;
  customerEmail?: string;
  ticketId: string | number | null;
  setIsResolved: React.Dispatch<React.SetStateAction<boolean>>;
  setTicketId: React.Dispatch<React.SetStateAction<string | number | null>>;
  hideActionBtn?: boolean;
  showGoToChat?: boolean;
  chatStatus?: "missed" | "live" | "archived";
  isAnonymousCustomer?: boolean;
}) {
  const {
    isResolving,
    handleResolveChat,
    showModal,
    onShow,
    onHide,
    showTicketSuccess,
    setShowTicketSuccess,
  } = useResolveHead({
    chatRequestId,
    setIsResolved,
    chatStatus,
  });
  return (
    <div
      className={`px-4 d-flex justify-content-between align-items-center ${style.headerWrapper}`}
    >
      <p className={`mb-0 ${styles.header}`}>
        {isResolved ? (
          <>
            <span>
              <i className="fa fa-solid fa-check-double"></i>
            </span>
            <span className="ps-1">Chat is resolved</span>
          </>
        ) : (
          <></>
        )}
      </p>
      <div>
        {showGoToChat === true && chatRequestId ? (
          <a
            target={"_blank"}
            href={`${RouteNames.liveChat}/chats/${
              chatStatus ?? "all"
            }/${chatRequestId}`}
            className={`${styles.header}`}
          >
            Go to chat
          </a>
        ) : ticketId ? (
          <a
            target={"_blank"}
            href={`/tickets/all/ticket-view/ticket/${ticketId}/`}
            className={`${styles.header}`}
          >
            Go to ticket
          </a>
        ) : (
          <></>
        )}
        {hideActionBtn === false &&
        (ticketId == null || !isResolved) &&
        customerEmail ? (
          <Dropdown>
            <Dropdown.Toggle
              as={"div"}
              bsPrefix={`${styles.customDropDown} px-0 ms-2 cursor-pointer`}
            >
              <p className="d-flex justify-content-between align-items-center mb-0">
                <span className="me-2">Actions</span>
                <span>
                  <i className="fa-solid fa-caret-down"></i>
                </span>
              </p>
            </Dropdown.Toggle>

            <Dropdown.Menu
              bsPrefix={`dropdown-menu ${style.dropMenu}`}
              popperConfig={POPPER_CONFIG}
            >
              <div className="p-1">
                {ticketId == null && (
                  <span
                    className={`d-block ${style.dropItem}`}
                    onClick={onShow}
                  >
                    Create ticket
                  </span>
                )}
                {!isResolved && (
                  <span
                    className={`d-block ${style.dropItem}`}
                    onClick={handleResolveChat}
                  >
                    {isResolving && (
                      <Spinner animation="border" size="sm" className="me-2" />
                    )}
                    Resolve chat
                  </span>
                )}
                {!isResolved && ticketId == null && (
                  <span className={`d-none ${style.dropItem}`}>
                    Resolve and create ticket
                  </span>
                )}
              </div>
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          <></>
        )}
      </div>
      {(ticketId === null || showTicketSuccess === true) && customerEmail && (
        <Modal
          show={showModal}
          onHide={onHide}
          dialogClassName={`${style.modalDialog}`}
          contentClassName={`${style.modalContent}`}
        >
          {showTicketSuccess === true ? (
            ticketId !== null && (
              <>
                <SuccessMsg
                  createdTicketId={ticketId}
                  onHide={onHide}
                  resolveAndCreateTicket={false}
                />
              </>
            )
          ) : (
            <CreateTicket
              onHide={onHide}
              resolve={false}
              heading={"Create Ticket :"}
              subHeading={"A ticket will be created for this chat"}
              submitBtn={"Create Ticket"}
              chatId={chatRequestId}
              selectedCustomerEmail={customerEmail}
              onSuccessCallback={(createdTicketId: number | string) => {
                setShowTicketSuccess(true);
                setTicketId(createdTicketId);
              }}
              isAnonymouseUser={isAnonymousCustomer}   //Adding flag to find ananymous user
            />
          )}
        </Modal>
      )}
    </div>
  );
}

export default ResolveHead;
