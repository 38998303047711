import React, { forwardRef } from "react";
import styles from "./ActionButton.module.scss";

const ActionButton = (
  { children, active, className, ...props }: any,
  ref: any
) => (
  <button
    ref={ref}
    className={`${styles.coloumn_btn} ${
      active ? styles.active : ""
    } ${className}`}
    type="button"
    {...props}
  >
    {children}
  </button>
);

export default forwardRef(ActionButton);
