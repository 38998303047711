import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { EReturnAutomationQuestionKeys } from "src/enums/EReturnAutomationQuestionKeys";
import {
  IAntiReturnStategiesOption,
  IIsConfigured,
  IResolutionOffersValue,
  IRestockingFee,
  IReturnReason,
  IReturnWindowValue,
  IStepQuestion,
  IStepQuestions,
  ReturnExchangeInterface,
} from "./retrunExchange.slice";
import returnExchangeReducers from "./returnExchange.reducers";
import {
  addReturnIntegration,
  fetchAllReturnIntegrations,
  fetchAllReturnReasonList,
  fetchGetAllDiscountCodes,
  fetchGetAllOrderTags,
  fetchGetAllProductTags,
  fetchGetAllShopifyProducts,
  fetchReturnIntegrationById,
  fetchStep10,
  fetchStep10Post,
  fetchStep11,
  fetchStep11Post,
  fetchStep12,
  fetchStep12Post,
  fetchStep13,
  fetchStep13Post,
  fetchStep14,
  fetchStep14Post,
  fetchStep15,
  fetchStep15Post,
  fetchStep7,
  fetchStep9,
  fetchStep9Post,
  removeReturnIntegration,
} from "./returnExchange.thunk";
import { updateReturnAutomationBulkStepData } from "./Thunks/returnExchangeModal.thunk";

const returnExchangeBuilders = (
  builder: ActionReducerMapBuilder<ReturnExchangeInterface>,
) => {
  builder.addCase(fetchStep7.fulfilled, (state, { payload }) => {
    const step7 = payload;
    if (step7) {
      if (step7.stepData.selected_auto_type === "fully_automate") {
        state.step7.stepData.fullyAutoMate = true;
        state.step7.stepData.noAutoMate = false;
        state.step7.stepData.partiallyAutomate = false;
      } else if (step7.stepData.selected_auto_type === "partially_automate") {
        state.step7.stepData.fullyAutoMate = false;
        state.step7.stepData.noAutoMate = false;
        state.step7.stepData.partiallyAutomate = true;
      } else {
        state.step7.stepData.fullyAutoMate = false;
        state.step7.stepData.noAutoMate = true;
        state.step7.stepData.partiallyAutomate = false;
      }
    }
  });

  builder.addCase(fetchStep9.pending, (state) => {
    state.step9.fetchStepDataAjaxStatus = "pending";
  });
  builder.addCase(fetchStep9.fulfilled, (state, { payload }) => {
    const step9 = payload;
    if (step9) {
      state.step9.stepData.fully_automate_current_step =
        step9.stepData.fully_automate_current_step;
      state.step9.stepData.transfer_to_agent = step9.stepData.transfer_to_agent;
      state.step9.stepData.confirmationMessage =
        step9.stepData.confirmationMessage;
      state.step9.stepData.stepStatus = step9.stepData.stepStatus;
      state.step9.stepData.configStatus = step9.stepData.configStatus;

      // step questions
      let stepQuestionsTemp: IStepQuestions = {
        ...state.step9.stepData.stepQuestions,
      };

      if (step9.stepData.stepQuestions) {
        step9.stepData.stepQuestions.forEach((stepQuestion: IStepQuestion) => {
          stepQuestionsTemp[stepQuestion.key].value = stepQuestion.value;
        });
      }

      state.step9.stepData.stepQuestions = stepQuestionsTemp;

      // add logic to show or hide other questions
      Object.keys(state.step9.stepData.stepQuestions).forEach((questionKey) => {
        let question: IStepQuestion =
          state.step9.stepData.stepQuestions[questionKey];
        if (state.step9.stepData.stepStatus !== "transfered") {
          state.step9.showStep = true;
        }

        switch (questionKey) {
          case EReturnAutomationQuestionKeys.returnWindow:
            //  show question 1
            if (question.value != null) {
              state.step9.stepData.stepQuestions[questionKey].showQuestion =
                true;
              // Dec 21 Added to show returnNotEligibleResponseMessage question when returnWindow question is answered
              const { days } = question.value as IReturnWindowValue;
              if (question.value && days > 0) {
                state.step9.stepData.stepQuestions[
                  EReturnAutomationQuestionKeys.returnNotEligibleResponseMessage
                ].showQuestion = true;
              }
              // else {
              //   state.step9.stepData.stepQuestions[
              //     EReturnAutomationQuestionKeys.returnWindow
              //   ].error = "please fill this field";
              // }
            }
            break;

          case EReturnAutomationQuestionKeys.returnNotEligibleResponseMessage:
            //  show question 2
            if (question.value) {
              state.step9.stepData.stepQuestions[questionKey].showQuestion =
                true;
              // Dec 21 Added to show isReturnPolicySameForAllOrders question when returnNotEligibleResponseMessage question is answered
              const message = state.step9.stepData.stepQuestions[questionKey]
                .value as string;
              if (message && message.length > 0) {
                state.step9.stepData.stepQuestions[
                  EReturnAutomationQuestionKeys.isReturnPolicySameForAllOrders
                ].showQuestion = true;
              }
            }
            break;

          case EReturnAutomationQuestionKeys.isReturnPolicySameForAllOrders:
            //  show question 3
            if (question.value != null) {
              state.step9.stepData.stepQuestions[questionKey].showQuestion =
                true;
              // Dec 21 Added to show isReturnPolicySameForAllItem question when isReturnPolicySameForAllOrders question is answered
              state.step9.stepData.stepQuestions[
                EReturnAutomationQuestionKeys.isReturnPolicySameForAllItem
              ].showQuestion = true;
            }
            break;

          case EReturnAutomationQuestionKeys.isReturnPolicySameForAllItem:
            //  show question 3
            if (question.value != null) {
              state.step9.stepData.stepQuestions[questionKey].showQuestion =
                true;
            }
            break;
        }
      });

      state.step9.fetchStepDataAjaxStatus = "fulfilled";

      //checking and updating global loader state
      if (state.isAllStepFetchedInitially === false) {
        returnExchangeReducers.updateReturnGlobalLoader(state);
      }
    }
  });
  builder.addCase(fetchStep10.pending, (state) => {
    state.step10.fetchStepDataAjaxStatus = "pending";
  });
  builder.addCase(fetchStep10.fulfilled, (state, { payload }) => {
    const step10 = payload;
    if (step10) {
      state.step10.stepData.fully_automate_current_step =
        step10.stepData.fully_automate_current_step;
      state.step10.stepData.transfer_to_agent =
        step10.stepData.transfer_to_agent;
      state.step10.stepData.confirmationMessage =
        step10.stepData.confirmationMessage;
      state.step10.stepData.stepStatus = step10.stepData.stepStatus;

      state.step10.fetchStepDataAjaxStatus = "fulfilled";
      state.step10.stepData.configStatus = step10.stepData.configStatus;
    }

    //checking and updating global loader state
    if (state.isAllStepFetchedInitially === false) {
      returnExchangeReducers.updateReturnGlobalLoader(state);
    }
  });
  builder.addCase(fetchStep11.pending, (state) => {
    state.step11.fetchStepDataAjaxStatus = "pending";
  });

  builder.addCase(fetchStep11.fulfilled, (state, { payload }) => {
    const step11 = payload;
    if (step11) {
      state.step11.stepData.fully_automate_current_step =
        step11.stepData.fully_automate_current_step;
      state.step11.stepData.transfer_to_agent =
        step11.stepData.transfer_to_agent;
      state.step11.stepData.confirmationMessage =
        step11.stepData.confirmationMessage;
      state.step11.stepData.stepStatus = step11.stepData.stepStatus;
      state.step11.stepData.configStatus = step11.stepData.configStatus;

      let stepQuestionsTemp: IStepQuestions = {
        ...state.step11.stepData.stepQuestions,
      };

      if (step11.stepData.stepQuestions) {
        step11.stepData.stepQuestions.forEach((stepQuestion: IStepQuestion) => {
          if (
            stepQuestion.key === EReturnAutomationQuestionKeys.resolutionOffer
          ) {
            let resolutionOfferData: any = stepQuestion.value;
            stepQuestionsTemp[stepQuestion.key].value = {
              exchangeWithSameItems:
                resolutionOfferData.exchangeWithSameItems ?? null,
              exchangeWithOtherItems:
                resolutionOfferData.exchangeWithOtherItems ?? null,
              refundToStoreCredits:
                resolutionOfferData.refundToStoreCredits ?? null,
              refundToOrigialPaymentMethod:
                resolutionOfferData.refundToOrigialPaymentMethod ?? null,
              // added in v3
              resolutionOfferMessage: {
                value: resolutionOfferData.resolutionOfferMessage ?? "",
                showQuestion: true,
              },
            };
          } else {
            stepQuestionsTemp[stepQuestion.key].value = stepQuestion.value;
          }
        });

        state.step11.stepData.stepQuestions = stepQuestionsTemp;

        // add logic to show or hide other questions
        Object.keys(state.step11.stepData.stepQuestions).forEach(
          (questionKey) => {
            let question: IStepQuestion =
              state.step11.stepData.stepQuestions[questionKey];
            switch (questionKey) {
              case EReturnAutomationQuestionKeys.resolutionOffer:
                let questionValue: IResolutionOffersValue =
                  question.value as IResolutionOffersValue;
                if (
                  questionValue &&
                  (questionValue.exchangeWithOtherItems != null ||
                    questionValue.exchangeWithSameItems != null ||
                    questionValue.refundToOrigialPaymentMethod != null ||
                    questionValue.refundToStoreCredits != null ||
                    questionValue.resolutionOfferMessage?.value?.trim() !== "")
                ) {
                  if (state.step11.stepData.stepStatus !== "transfered") {
                    state.step11.showStep = true;
                  }
                  state.step11.stepData.stepQuestions[
                    questionKey
                  ].showQuestion = true;

                  // Dec 21 Added to show isResolutionOfferedSameForAllOrders(Q6) question when atleast one return type is selected or the message is filled (Q5)
                  state.step11.stepData.stepQuestions[
                    EReturnAutomationQuestionKeys.isResolutionOfferedSameForAllOrders
                  ].showQuestion = true;

                  // any one option is selected, hide message box

                  if (
                    !questionValue.exchangeWithOtherItems &&
                    !questionValue.exchangeWithSameItems &&
                    !questionValue.refundToOrigialPaymentMethod &&
                    !questionValue.refundToStoreCredits
                  ) {
                    (
                      state.step11.stepData.stepQuestions[questionKey]
                        .value as IResolutionOffersValue
                    ).resolutionOfferMessage.showQuestion = true;
                  } else {
                    (
                      state.step11.stepData.stepQuestions[questionKey]
                        .value as IResolutionOffersValue
                    ).resolutionOfferMessage.showQuestion = false;
                  }
                } else {
                  (
                    state.step11.stepData.stepQuestions[questionKey]
                      .value as IResolutionOffersValue
                  ).resolutionOfferMessage.showQuestion = true;
                }
                if (
                  questionValue.exchangeWithOtherItems == null ||
                  questionValue.exchangeWithSameItems == null ||
                  questionValue.refundToOrigialPaymentMethod == null ||
                  questionValue.refundToStoreCredits == null ||
                  questionValue.resolutionOfferMessage?.value?.trim() === ""
                ) {
                  state.step11.stepData.stepQuestions[questionKey].error =
                    "please fill this field";
                }
                break;

              case EReturnAutomationQuestionKeys.isResolutionOfferedSameForAllOrders:
                if (question.value != null) {
                  state.step11.stepData.stepQuestions[
                    EReturnAutomationQuestionKeys.isResolutionOfferedSameForAllOrders
                  ].showQuestion = true;
                  // If isResolutionOfferedSameForAllOrders is answered then show step
                  if (state.step11.stepData.stepStatus !== "transfered") {
                    state.step11.showStep = true;
                  }
                }
                break;
            }
          },
        );
      }

      state.step11.fetchStepDataAjaxStatus = "fulfilled";

      //checking and updating global loader state
      if (state.isAllStepFetchedInitially === false) {
        returnExchangeReducers.updateReturnGlobalLoader(state);
      }
    }
  });
  builder.addCase(fetchStep12.pending, (state) => {
    state.step12.fetchStepDataAjaxStatus = "pending";
  });

  builder.addCase(fetchStep12.fulfilled, (state, { payload }) => {
    const step12 = payload;
    if (step12) {
      state.step12.stepData.fully_automate_current_step =
        step12.stepData.fully_automate_current_step;
      state.step12.stepData.transfer_to_agent =
        step12.stepData.transfer_to_agent;
      state.step12.stepData.confirmationMessage =
        step12.stepData.confirmationMessage;
      state.step12.stepData.stepStatus = step12.stepData.stepStatus;
      state.step12.stepData.configStatus = step12.stepData.configStatus;

      state.step12.fetchStepDataAjaxStatus = "fulfilled";
      //checking and updating global loader state
      if (state.isAllStepFetchedInitially === false) {
        returnExchangeReducers.updateReturnGlobalLoader(state);
      }

      // step questions
      let stepQuestionsTemp: IStepQuestions = {
        ...state.step12.stepData.stepQuestions,
      };

      if (step12.stepData.stepQuestions) {
        step12.stepData.stepQuestions.forEach((stepQuestion: IStepQuestion) => {
          if (
            stepQuestion.key ===
              EReturnAutomationQuestionKeys.returnReasonList &&
            stepQuestion.value
          ) {
            let reasonList: Array<IReturnReason> = [];
            (stepQuestion.value as Array<string>).forEach((reason: any) => {
              reasonList.push({
                id: reason.id,
                value: reason.value,
              });
            });
            stepQuestionsTemp[stepQuestion.key].value = reasonList;
          } else {
            stepQuestionsTemp[stepQuestion.key].value = stepQuestion.value;
          }
        });
      }

      state.step12.stepData.stepQuestions = stepQuestionsTemp;

      // add logic to show or hide other questions
      Object.keys(state.step12.stepData.stepQuestions).forEach(
        (questionKey) => {
          let question: IStepQuestion =
            state.step12.stepData.stepQuestions[questionKey];

          switch (questionKey) {
            case EReturnAutomationQuestionKeys.selectReturnReason:
              if (question.value != null) {
                if (
                  state.step12.stepData.stepStatus !== "transfered" &&
                  typeof question.value === "string"
                ) {
                  state.step12.showStep = true;
                }
                state.step12.stepData.stepQuestions[
                  EReturnAutomationQuestionKeys.selectReturnReason
                ].showQuestion = true;
                if (question.value === "notRequired") {
                  state.step12.stepData.stepQuestions[
                    EReturnAutomationQuestionKeys.isReturnReasonSameForAll
                  ].showQuestion = true;
                }
              }
              // Dec 21 Added to show returnReasonList when Q7 is required or requiredAndCustom
              if (
                question.value != null &&
                (question.value === "required" ||
                  question.value === "requiredAndCustom")
              ) {
                state.step12.stepData.stepQuestions[
                  EReturnAutomationQuestionKeys.returnReasonList
                ].showQuestion = true;
              }
              break;

            case EReturnAutomationQuestionKeys.returnReasonList:
              if (
                (question.value != null &&
                  (question.value as Array<IReturnReason>).length &&
                  state.step12.stepData.stepQuestions[
                    EReturnAutomationQuestionKeys.selectReturnReason
                  ].value === "required") ||
                state.step12.stepData.stepQuestions[
                  EReturnAutomationQuestionKeys.selectReturnReason
                ].value === "requiredAndCustom"
              ) {
                state.step12.stepData.stepQuestions[
                  EReturnAutomationQuestionKeys.returnReasonList
                ].showQuestion = true;
                state.step12.stepData.stepQuestions[
                  EReturnAutomationQuestionKeys.isReturnReasonSameForAll
                ].showQuestion = true;
              } else {
                // Dec 21 Add a default reason to return reason list
                returnExchangeReducers.addReturnReason(state, {
                  payload: {
                    reason: "",
                  },
                });
              }
              break;

            case EReturnAutomationQuestionKeys.isReturnReasonSameForAll:
              if (
                question.value != null &&
                state.step12.stepData.stepQuestions[
                  EReturnAutomationQuestionKeys.selectReturnReason
                ].value != null
              ) {
                state.step12.stepData.stepQuestions[
                  EReturnAutomationQuestionKeys.isReturnReasonSameForAll
                ].showQuestion = true;
              }

              break;
          }
        },
      );
    }
  });

  builder.addCase(fetchStep13.pending, (state) => {
    state.step13.fetchStepDataAjaxStatus = "pending";
  });

  builder.addCase(fetchStep13.fulfilled, (state, { payload }) => {
    const step13 = payload;
    if (step13) {
      state.step13.stepData.fully_automate_current_step =
        step13.stepData.fully_automate_current_step;
      state.step13.stepData.transfer_to_agent =
        step13.stepData.transfer_to_agent;
      state.step13.stepData.confirmationMessage =
        step13.stepData.confirmationMessage;
      state.step13.stepData.stepStatus = step13.stepData.stepStatus;
      state.step13.fetchStepDataAjaxStatus = "fulfilled";
      state.step13.stepData.configStatus = step13.stepData.configStatus;

      if (state.isAllStepFetchedInitially === false) {
        //checking and updating global loader state
        returnExchangeReducers.updateReturnGlobalLoader(state);
      }

      const stepQuestionsTemp: IStepQuestions = {
        ...state.step13.stepData.stepQuestions,
      };
      let antiReturnStrategiesAnswered: boolean | null;
      if (step13.stepData.stepQuestions) {
        // Loop through step questions to process antiReturnStrategies options
        step13.stepData.stepQuestions.forEach((stepQuestion: IStepQuestion) => {
          if (
            stepQuestion.key ===
              EReturnAutomationQuestionKeys.antiReturnStrategies &&
            (stepQuestion.value as IAntiReturnStategiesOption)
          ) {
            // Extract antiReturnStrategiesOptions
            let antiReturnStrategiesOptions: IAntiReturnStategiesOption =
              stepQuestion.value as IAntiReturnStategiesOption;

            // Check if any anti-return strategy options are selected
            if (
              antiReturnStrategiesOptions.offerAnExchange !== null ||
              antiReturnStrategiesOptions.offerSomeSuggestion !== null ||
              antiReturnStrategiesOptions.offerSuggestionExtendReturnDeadline !==
                null ||
              antiReturnStrategiesOptions.offerDiscountOnFutureOrder !== null
            ) {
              // Set showStep and showQuestion flags based on conditions
              state.step13.showStep = true;
              stepQuestionsTemp[stepQuestion.key].showQuestion = true;
              stepQuestionsTemp[
                EReturnAutomationQuestionKeys.configureAntiReturnStrategies
              ].showQuestion = true;
            }

            // Set values for antiReturnStrategiesOptions
            stepQuestionsTemp[stepQuestion.key].value = {
              offerAnExchange:
                antiReturnStrategiesOptions.offerAnExchange ?? false,
              offerSomeSuggestion:
                antiReturnStrategiesOptions.offerSomeSuggestion ?? false,
              offerSuggestionExtendReturnDeadline:
                antiReturnStrategiesOptions.offerSuggestionExtendReturnDeadline ??
                false,
              offerDiscountOnFutureOrder:
                antiReturnStrategiesOptions.offerDiscountOnFutureOrder ?? false,
            };

            // Update antiReturnStrategiesAnswered based on antiReturnStrategiesOptions
            antiReturnStrategiesAnswered =
              (antiReturnStrategiesOptions.offerAnExchange ?? false) ||
              (antiReturnStrategiesOptions.offerSomeSuggestion ?? false) ||
              (antiReturnStrategiesOptions.offerSuggestionExtendReturnDeadline ??
                false) ||
              (antiReturnStrategiesOptions.offerDiscountOnFutureOrder ?? false);
          } else if (
            stepQuestion.key ===
            EReturnAutomationQuestionKeys.configureAntiReturnStrategies
          ) {
            // Extract isConfiguredVal
            let isConfiguredVal: IIsConfigured =
              stepQuestion.value as IIsConfigured;

            // Set value for isConfigured
            stepQuestionsTemp[stepQuestion.key].value = {
              isConfigured: isConfiguredVal.isConfigured === true,
            };
            // Check conditions and set showQuestion flags accordingly
            stepQuestionsTemp[stepQuestion.key].showQuestion =
              antiReturnStrategiesAnswered === true;
            stepQuestionsTemp[
              EReturnAutomationQuestionKeys.isReturnReasonOrFollowUpQuestionsSame
            ].showQuestion =
              isConfiguredVal.isConfigured === true &&
              antiReturnStrategiesAnswered === true;
          } else if (
            stepQuestion.key ===
              EReturnAutomationQuestionKeys.isReturnReasonOrFollowUpQuestionsSame &&
            stepQuestion.value !== null &&
            antiReturnStrategiesAnswered &&
            stepQuestionsTemp[
              EReturnAutomationQuestionKeys.configureAntiReturnStrategies
            ].value
          ) {
            // Set value for isReturnReasonOrFollowUpQuestionsSame based on conditions
            stepQuestionsTemp[stepQuestion.key].value = stepQuestion.value;
          }
        });
      }
      state.step13.stepData.stepQuestions = stepQuestionsTemp;
    }
  });

  builder.addCase(fetchStep14.pending, (state) => {
    state.step14.fetchStepDataAjaxStatus = "pending";
  });

  builder.addCase(fetchStep14.fulfilled, (state, { payload }) => {
    const step14 = payload;
    if (step14) {
      state.step14.stepData.fully_automate_current_step =
        step14.stepData.fully_automate_current_step;
      state.step14.stepData.transfer_to_agent =
        step14.stepData.transfer_to_agent;
      state.step14.stepData.confirmationMessage =
        step14.stepData.confirmationMessage;
      state.step14.stepData.stepStatus = step14.stepData.stepStatus;
      state.step14.stepData.configStatus = step14.stepData.configStatus;

      // step questions
      let stepQuestionsTemp: IStepQuestions = {
        ...state.step14.stepData.stepQuestions,
      };

      if (step14.stepData.stepQuestions) {
        step14.stepData.stepQuestions.forEach((stepQuestion: IStepQuestion) => {
          if (stepQuestionsTemp[stepQuestion.key]) {
            stepQuestionsTemp[stepQuestion.key].value = stepQuestion.value;
          }
        });
      }

      state.step14.stepData.stepQuestions = stepQuestionsTemp;

      // add logic to show or hide other questions
      Object.keys(state.step14.stepData.stepQuestions).forEach(
        (questionKey) => {
          let question: IStepQuestion =
            state.step14.stepData.stepQuestions[questionKey];

          switch (questionKey) {
            case EReturnAutomationQuestionKeys.returnMethodForMajorityOfOrders:
              if (question.value != null) {
                if (state.step14.stepData.stepStatus !== "transfered") {
                  state.step14.showStep = true;
                }
                state.step14.stepData.stepQuestions[
                  EReturnAutomationQuestionKeys.returnMethodForMajorityOfOrders
                ].showQuestion = true;
                // Dec 22 Added to show returnMethodDisplayMessage when returnMethodForMajorityOfOrders is answered
                state.step14.stepData.stepQuestions[
                  EReturnAutomationQuestionKeys.returnMethodDisplayMessage
                ].showQuestion = true;
              }

              break;

            case EReturnAutomationQuestionKeys.returnMethodDisplayMessage:
              const message = question.value as string;
              if (question.value != null && message && message.length > 0) {
                state.step14.stepData.stepQuestions[
                  EReturnAutomationQuestionKeys.returnMethodDisplayMessage
                ].showQuestion = true;
                // Dec 22 Added to show isReturnMethodSameForAllOrders Question when returnMethodDisplayMessage is answered
                state.step14.stepData.stepQuestions[
                  EReturnAutomationQuestionKeys.isReturnMethodSameForAllOrders
                ].showQuestion = true;
              }

              break;

            case EReturnAutomationQuestionKeys.isReturnMethodSameForAllOrders:
              if (question.value != null) {
                state.step14.stepData.stepQuestions[
                  EReturnAutomationQuestionKeys.isReturnMethodSameForAllOrders
                ].showQuestion = true;
              }

              break;
          }
        },
      );

      state.step14.fetchStepDataAjaxStatus = "fulfilled";

      //checking and updating global loader state
      if (state.isAllStepFetchedInitially === false) {
        returnExchangeReducers.updateReturnGlobalLoader(state);
      }
    }
  });

  builder.addCase(fetchStep15.pending, (state) => {
    state.step15.fetchStepDataAjaxStatus = "pending";
  });

  builder.addCase(fetchStep15.fulfilled, (state, { payload }) => {
    const step15 = payload;
    if (step15) {
      state.step15.stepData.fully_automate_current_step =
        step15.stepData.fully_automate_current_step;
      state.step15.stepData.transfer_to_agent =
        step15.stepData.transfer_to_agent;
      state.step15.stepData.confirmationMessage =
        step15.stepData.confirmationMessage;
      state.step15.stepData.stepStatus = step15.stepData.stepStatus;
      state.step15.stepData.configStatus = step15.stepData.configStatus;

      // step questions
      let stepQuestionsTemp: IStepQuestions = {
        ...state.step15.stepData.stepQuestions,
      };

      if (step15.stepData.stepQuestions) {
        step15.stepData.stepQuestions.forEach((stepQuestion: IStepQuestion) => {
          stepQuestionsTemp[stepQuestion.key].value = stepQuestion.value;
        });
      }

      state.step15.stepData.stepQuestions = stepQuestionsTemp;

      // add logic to show or hide other questions
      Object.keys(state.step15.stepData.stepQuestions).forEach(
        (questionKey) => {
          let question: IStepQuestion =
            state.step15.stepData.stepQuestions[questionKey];

          switch (questionKey) {
            case EReturnAutomationQuestionKeys.isRestockingFeeChargedForMajorityOrders:
              if (question.value != null) {
                if (state.step15.stepData.stepStatus !== "transfered") {
                  state.step15.showStep = true;
                }
                state.step15.stepData.stepQuestions[
                  EReturnAutomationQuestionKeys.isRestockingFeeChargedForMajorityOrders
                ].showQuestion = true;
              }

              break;

            case EReturnAutomationQuestionKeys.usuallyChargedRestockingFee:
              if (question.value != null) {
                state.step15.stepData.stepQuestions[
                  EReturnAutomationQuestionKeys.usuallyChargedRestockingFee
                ].showQuestion = true;
                // Dec 22 Added to show restockingFeeDisplayMessage Question when usuallyChargedRestockingFee is answered and price is more than 0
                const val = question.value as IRestockingFee;
                if (
                  val &&
                  val.price !== "0" &&
                  val.price !== "00" &&
                  val.priceType != null
                ) {
                  state.step15.stepData.stepQuestions[
                    EReturnAutomationQuestionKeys.restockingFeeDisplayMessage
                  ].showQuestion = true;
                }
              }

              break;

            case EReturnAutomationQuestionKeys.restockingFeeDisplayMessage:
              if (question.value != null && question.value !== "") {
                state.step15.stepData.stepQuestions[
                  EReturnAutomationQuestionKeys.restockingFeeDisplayMessage
                ].showQuestion = true;
                // Dec 22 Added to show isRestockingFeeSameForAll Question when restockingFeeDisplayMessage is answered
                state.step15.stepData.stepQuestions[
                  EReturnAutomationQuestionKeys.isRestockingFeeSameForAll
                ].showQuestion = true;
              }

              break;

            case EReturnAutomationQuestionKeys.isRestockingFeeSameForAll:
              if (question.value != null) {
                state.step15.stepData.stepQuestions[
                  EReturnAutomationQuestionKeys.isRestockingFeeSameForAll
                ].showQuestion = true;
              }

              break;
          }
        },
      );

      state.step15.fetchStepDataAjaxStatus = "fulfilled";

      //checking and updating global loader state
      if (state.isAllStepFetchedInitially === false) {
        returnExchangeReducers.updateReturnGlobalLoader(state);
      }
    }
  });

  builder.addCase(fetchGetAllOrderTags.pending, (state, { payload }) => {
    state.getAllOrderTagsAjax = "pending";
  });
  builder.addCase(fetchGetAllOrderTags.rejected, (state, { payload }) => {
    state.getAllOrderTagsAjax = "rejected";
  });
  builder.addCase(fetchGetAllOrderTags.fulfilled, (state, { payload }) => {
    const orderTags = payload;
    state.getAllOrderTagsAjax = "fulfilled";
    if (orderTags) {
      state.getAllOrderTags = orderTags;
    }
  });

  builder.addCase(fetchGetAllProductTags.fulfilled, (state, { payload }) => {
    const productTags = payload;
    state.getAllProductTagsAjax = "fulfilled";
    if (productTags) {
      state.getAllProductTags = productTags;
    }
  });
  builder.addCase(fetchGetAllProductTags.pending, (state, { payload }) => {
    state.getAllProductTagsAjax = "pending";
  });

  builder.addCase(fetchGetAllProductTags.rejected, (state, { payload }) => {
    state.getAllProductTagsAjax = "rejected";
  });

  builder.addCase(fetchGetAllDiscountCodes.fulfilled, (state, { payload }) => {
    const discountCodes = payload;
    state.getAllDiscountCodesAjax = "fulfilled";
    if (discountCodes) {
      state.getAllDiscountCodes = discountCodes;
    }
  });

  builder.addCase(fetchGetAllDiscountCodes.pending, (state, { payload }) => {
    state.getAllDiscountCodesAjax = "pending";
  });

  builder.addCase(fetchGetAllDiscountCodes.rejected, (state, { payload }) => {
    state.getAllDiscountCodesAjax = "rejected";
  });

  builder.addCase(
    fetchGetAllShopifyProducts.fulfilled,
    (state, { payload }) => {
      const { data: shopifyProducts, meta } = payload;
      state.getAllShopifyProductAjax = "fulfilled";
      state.getAllShopifyProductMeta = meta;
      if (shopifyProducts) {
        state.getAllShopifyProducts = shopifyProducts;
        state.getAllShopifyProducts = shopifyProducts;
      }
    },
  );

  builder.addCase(fetchGetAllShopifyProducts.pending, (state) => {
    state.getAllShopifyProductAjax = "pending";
  });

  builder.addCase(fetchGetAllShopifyProducts.rejected, (state) => {
    state.getAllShopifyProductAjax = "rejected";
  });
  builder.addCase(fetchAllReturnIntegrations.pending, (state) => {
    state.fetchAllIntegrationsAjaxStatus = "pending";
  });
  builder.addCase(fetchAllReturnIntegrations.rejected, (state) => {
    state.fetchAllIntegrationsAjaxStatus = "rejected";
  });
  builder.addCase(
    fetchAllReturnIntegrations.fulfilled,
    (state, { payload }) => {
      state.fetchAllIntegrationsAjaxStatus = "fulfilled";
      const returnIntegrations = payload;
      if (returnIntegrations) {
        state.integrationIds = Array.from(
          new Set([
            ...state.integrationIds,
            ...returnIntegrations.integrationIds,
          ]),
        );
        state.integrations = {
          ...state.integrations,
          ...returnIntegrations.integrations,
        };
        state.integrationMetaData = returnIntegrations.metaData;
      }
    },
  );

  builder.addCase(addReturnIntegration.pending, (state) => {
    state.addIntegrationAjaxStatus = "pending";
  });
  builder.addCase(addReturnIntegration.rejected, (state) => {
    state.addIntegrationAjaxStatus = "rejected";
  });
  builder.addCase(addReturnIntegration.fulfilled, (state, { payload }) => {
    state.addIntegrationAjaxStatus = "fulfilled";
    const integrationCreated = payload;
    if (integrationCreated) {
      state.activeIntegrationId = integrationCreated.integrationId;
    }
  });

  builder.addCase(removeReturnIntegration.fulfilled, (state, { payload }) => {
    const integrationId = payload;
    if (integrationId) {
      //removing deleted integration from integration ids list
      state.integrationIds = state.integrationIds.filter(
        (id: any) => id !== integrationId,
      );
      //removing deleted integration data from integrations object
      delete state.integrations[integrationId];

      //decrementing total integration count
      state.integrationMetaData.total =
        parseInt(state.integrationMetaData.total + "") - 1;
    }
  });

  builder.addCase(removeReturnIntegration.rejected, (state) => {
    pushTheToast({
      type: "danger",
      text: "failed to delete return integration!",
      position: "top-right",
    });
  });

  builder.addCase(
    fetchReturnIntegrationById.fulfilled,
    (state, { payload }) => {
      const integration = payload;
      //checking if payload integration id and active integration id same,if yes updating active integration state
      if (integration.integrationId === state.activeIntegrationId) {
        state.activeIntegrationData = integration;
        // If automation is published or configured , show go live step
        if (integration?.published == true || integration?.configured == true) {
          state.showStepGoLive = true;
        }
      }
    },
  );

  builder.addCase(fetchReturnIntegrationById.rejected, (state) => {
    pushTheToast({
      type: "danger",
      text: "Something went wrong!",
      position: "top-right",
    });
  });

  // builders for bulk updation
  builder.addCase(updateReturnAutomationBulkStepData.pending, (state) => {
    state.isBulkSaveRunning = true;
  });

  builder.addCase(updateReturnAutomationBulkStepData.fulfilled, (state) => {
    state.isBulkSaveRunning = false;
    returnExchangeReducers.addInitialData(state);
    // pushTheToast({
    //   type: "success",
    //   text: "Step data updated!",
    //   position: "top-right",
    // });
  });

  builder.addCase(updateReturnAutomationBulkStepData.rejected, (state) => {
    state.isBulkSaveRunning = false;
    pushTheToast({
      type: "danger",
      text: "Something went wrong!",
      position: "top-right",
    });
  });

  builder.addCase(fetchStep9Post.fulfilled, (state, { payload }) => {
    if (payload.confirmationMessage) {
      state.step9.stepData.confirmationMessage = payload.confirmationMessage;
    }
    if (payload.transfer_to_agent !== undefined) {
      state.step9.stepData.transfer_to_agent = payload.transfer_to_agent;
    }
  });
  builder.addCase(fetchStep10Post.fulfilled, (state, { payload }) => {
    if (payload.confirmationMessage) {
      state.step10.stepData.confirmationMessage = payload.confirmationMessage;
    }
    if (payload.transfer_to_agent !== undefined) {
      state.step10.stepData.transfer_to_agent = payload.transfer_to_agent;
    }
  });
  builder.addCase(fetchStep11Post.fulfilled, (state, { payload }) => {
    if (payload.confirmationMessage) {
      state.step11.stepData.confirmationMessage = payload.confirmationMessage;
    }
    if (payload.transfer_to_agent !== undefined) {
      state.step11.stepData.transfer_to_agent = payload.transfer_to_agent;
    }
  });
  builder.addCase(fetchStep12Post.fulfilled, (state, { payload }) => {
    if (payload.confirmationMessage) {
      state.step12.stepData.confirmationMessage = payload.confirmationMessage;
    }
    if (payload.transfer_to_agent !== undefined) {
      state.step12.stepData.transfer_to_agent = payload.transfer_to_agent;
    }
  });
  builder.addCase(fetchStep13Post.fulfilled, (state, { payload }) => {
    if (payload.confirmationMessage) {
      state.step13.stepData.confirmationMessage = payload.confirmationMessage;
    }
    if (payload.transfer_to_agent !== undefined) {
      state.step13.stepData.transfer_to_agent = payload.transfer_to_agent;
    }
  });
  builder.addCase(fetchStep14Post.fulfilled, (state, { payload }) => {
    if (payload.confirmationMessage) {
      state.step14.stepData.confirmationMessage = payload.confirmationMessage;
    }
    if (payload.transfer_to_agent !== undefined) {
      state.step14.stepData.transfer_to_agent = payload.transfer_to_agent;
    }
  });
  builder.addCase(fetchStep15Post.fulfilled, (state, { payload }) => {
    if (payload.confirmationMessage) {
      state.step15.stepData.confirmationMessage = payload.confirmationMessage;
    }
    if (payload.transfer_to_agent !== undefined) {
      state.step15.stepData.transfer_to_agent = payload.transfer_to_agent;
    }
  });

  builder.addCase(fetchAllReturnReasonList.pending, (state) => {
    state.returnReasonAjaxStatus = "pending";
  });

  builder.addCase(fetchAllReturnReasonList.rejected, (state) => {
    state.returnReasonAjaxStatus = "rejected";
  });

  builder.addCase(fetchAllReturnReasonList.fulfilled, (state, { payload }) => {
    state.returnReasonList = payload.reasons;
    state.returnReasonListIds = payload.reasonIds;
    state.returnReasonAjaxStatus = "fulfilled";
  });
};

export default returnExchangeBuilders;
