import { useQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import getPaymentPlansService, {
  IPlanTypes,
} from "src/services/Billing/getPaymentPlans.service";

const usePlanModal = () => {
  const [selectedPlanType, setSelectedPlanType] =
    useState<IPlanTypes["type"]>("monthly");

  const { data, isLoading, isError } = useQuery(["getPaymentPlans"], {
    queryFn: getPaymentPlansService,
    cacheTime: 60000,
    staleTime: 60000,
  });

  const plans = useMemo(() => {
    return data?.map((plan) => plan) ?? [];
  }, [data]);

  const selectedPlan = useMemo(() => {
    return data?.find((plan) => plan.type === selectedPlanType);
  }, [data, selectedPlanType]);

  const planId = useMemo(() => {
    return (
      selectedPlan?.plans.find((plan) => plan.shortName === "PRO")?.planId ?? ""
    );
  }, [selectedPlan?.plans]);

  return {
    plans,
    selectedPlan,
    planId,
    isLoading,
    isError,
    setSelectedPlanType,
  };
};

export default usePlanModal;
