import styles from "./FinalDetail.module.scss";
import dummy1 from "src/assets/images/order1.png";
import dummy3 from "src/assets/images/order2.png";
import dummy2 from "src/assets/images/chat/item2.png";
import gift from "src/assets/images/chat/gift.png";
import { useState } from "react";
const FinalDetail = () => {
  const [select, setSelect] = useState(true);
  const [detail, setDetail] = useState(false);
  const HideSelect = () => {
    setSelect(false);
    setDetail(true);
  };
  return (
    <>
      <div>
        <div className={`py-2 px-2 mb-3 ${styles.cardBox}`}>
          <span className={`${styles.orderName}`}>Acme T-shirt</span>
          <div className="d-flex mb-2">
            <img
              src={dummy1}
              className={`${styles.orderImg}`}
            />
            <div className="ms-1">
              <span className={`d-block ${styles.orderData}`}>$ 789 x 1</span>

              <span className={`d-block ${styles.orderData}`}>SKU: 0101</span>
            </div>
          </div>
          <div className="d-flex">
            <span className={` ${styles.reason}`}> Reasons:</span>{" "}
            <span className={`${styles.reasonIssue}`}> Fitting Issues</span>{" "}
          </div>
          <div className={`p-2 mt-2 mb-2 ${styles.discountOffer}`}>
            <div className={`d-flex`}>
              <div>
                <img
                  src={gift}
                  className={`me-1 ${styles.giftBox}`}
                  alt=""
                />
              </div>
              <div>
                <h6 className={`mb-0 ${styles.disHead}`}>
                  Get 10 % discount on your next order instead of this exchange
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div className={`py-2 px-2 mb-3 ${styles.cardBox}`}>
          <div>
            <span className={`pe-1 ${styles.orderName}`}>Acme T-shirt</span>
            <span className={`d-inline-block ${styles.moreInfo}`}>
              *Exchange with other item
            </span>
          </div>
          <div className="d-flex mb-2">
            <img
              src={dummy1}
              className={`${styles.orderImg}`}
            />
            <div className="ms-1">
              <span className={`d-block ${styles.orderData}`}>$ 789 x 1</span>

              <span className={`d-block ${styles.orderData}`}>SKU: 0101</span>
            </div>
          </div>
          <div>
            <span className={`${styles.reason}`}> Reasons:</span>{" "}
            <span className={`${styles.reasonIssue}`}> Fitting Issues</span>{" "}
          </div>
          {select && (
            <div
              className={`px-3 mt-2 mb-2 py-1 d-flex justify-content-between cursor-pointer ${styles.selectBox} ${styles.errBorder}`}
              onClick={HideSelect}
            >
              <span className={`${styles.leftBox}`}>Select exchange item</span>
              <span className={`text-end ${styles.rightBox}`}>
                <i className="fa-solid fa-angle-right"></i>
              </span>
            </div>
          )}
          {detail && (
            <div>
              <div
                className={`py-2 px-2 mb-3 position-relative mt-2 ${styles.detailBox}`}
              >
                <div className="d-flex ">
                  <img
                    src={dummy2}
                    className={`${styles.orderImg}`}
                  />
                  <div className="ms-1">
                    <span className={`d-block ${styles.orderName}`}>
                      Acme White T- shirt
                    </span>
                    <span className={`d-block ${styles.quant}`}>
                      SKU : 1234
                    </span>

                    <span className={`d-block ${styles.calc}`}>$ 230 x1</span>
                  </div>
                </div>
                <div
                  className={`d-flex justify-content-center align-items-center cursor-pointer ${styles.closeDiv}`}
                >
                  <span>
                    <i className="fa-solid fa-xmark"></i>
                  </span>
                </div>
              </div>
              <div className="d-flex justify-content-end mb-2">
                <span className={`cursor-pointer ${styles.addItem}`}>
                  {" "}
                  + Add more items{" "}
                </span>
              </div>
            </div>
          )}
        </div>
        <div className={`py-2 px-2 mb-3 ${styles.cardBox}`}>
          <div>
            <span className={`pe-1 ${styles.orderName}`}>Acme T-shirt</span>
            <span className={`d-inline-block ${styles.moreInfo}`}>
              *Refund to original payment
            </span>
          </div>
          <div className="d-flex mb-2">
            <img
              src={dummy3}
              className={`${styles.orderImg}`}
            />
            <div className="ms-1">
              <span className={`d-block ${styles.orderData}`}>$ 789 x 1</span>

              <span className={`d-block ${styles.orderData}`}>SKU: 0101</span>
            </div>
          </div>
          <div>
            <span className={`${styles.reason}`}> Reasons:</span>{" "}
            <span className={`${styles.reasonIssue}`}>
              Received different item
            </span>{" "}
          </div>
        </div>
      </div>
    </>
  );
};
export default FinalDetail;
