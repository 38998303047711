import { axiosJSON } from "src/globals/axiosEndPoints";

async function markCSVUploadCompletedService(params: {
  integrationId: string;
  attachmentId: string;
}) {
  const { data: res } = await axiosJSON.post(
    "/api/setting/automation/refund/product/csv/markAsCompleted",
    params,
  );

  if (res.err || res.error) {
    throw new Error(res.msg ?? res.message ?? "Something went wrong!");
  }

  return true;
}

export default markCSVUploadCompletedService;
