import { useCallback, useMemo, useState } from "react";
import Select from "react-select";
import deleteIcon from "src/assets/images/delete.png";
import { useAutomationContext } from "src/routes/BotSettings/Children/Automation/Hooks/useAutomationContext";
import {
  IBugChannelQuestion,
  Question,
  QuestionType,
} from "src/services/Automation/getAutomationById.service";
import styles from "./ChannelQuestion.module.scss";
import { validateURL } from "src/utils/utils";

interface QuestionsProps {
  questionData: IBugChannelQuestion;
  stepId: string;
}

interface Option {
  value: string;
  label: string;
}

const isBugChannelsQuestion = (
  question: Question,
): question is IBugChannelQuestion => {
  return question.questionType === QuestionType.BugChannel;
};

const ChannelQuestion = ({ stepId, questionData }: QuestionsProps) => {
  const { updateQuestions, saveQuestionsData, formData } =
    useAutomationContext();

  const isQuestionDisabled = useMemo(
    () => questionData.isBrandRequired === true && formData.brand === null,
    [formData.brand, questionData.isBrandRequired],
  );

  // State for URL validation errors
  const [urlErrors, setUrlErrors] = useState<Array<string | null>>([]);

  const { options, optionsMap } = useMemo(() => {
    const options: Option[] = [];
    const optionsMap: Record<string, Option> = {};

    questionData.listOfOptions?.forEach((op) => {
      const option = {
        value: op.optionKey,
        label: op.optionLabel,
      };
      options.push(option);
      optionsMap[op.optionKey] = option;
    });

    return { options, optionsMap };
  }, [questionData.listOfOptions]);

  const addMoreUrl = useCallback(() => {
    if (isBugChannelsQuestion(questionData)) {
      const newChannels: IBugChannelQuestion["questionValue"] = [
        ...questionData.questionValue,
        {
          id: null,
          optionKey: options[0].value,
          url: "",
        },
      ];

      updateQuestions({
        ...questionData,
        questionValue: newChannels,
        isAnswered: false,
      });
    }
  }, [options, questionData, updateQuestions]);

  const handleOptionChange = useCallback(
    (idx: number, optionKey: string) => {
      if (isBugChannelsQuestion(questionData)) {
        let isAnswered = true;

        const newChannels = questionData.questionValue.map((channel, i) => {
          if (channel.url === "") {
            isAnswered = false;
          }
          return i === idx ? { ...channel, optionKey } : channel;
        });

        updateQuestions({
          ...questionData,
          questionValue: newChannels,
        });

        if (newChannels.length !== 0 && isAnswered) {
          saveQuestionsData(
            {
              stepId,
              stepQuestions: {
                [questionData.questionKey]: newChannels,
              },
            },
            true,
          );
        }
      }
    },
    [questionData, saveQuestionsData, stepId, updateQuestions],
  );

  const handleURLChange = useCallback(
    (idx: number, url: string) => {
      if (isBugChannelsQuestion(questionData)) {
        let isAnswered = true;
        const validationMessage = validateURL(url); // Get the validation message

        // Update the URL validation errors
        const newUrlErrors = [...urlErrors];
        newUrlErrors[idx] = validationMessage; // Set the error message for the current index
        setUrlErrors(newUrlErrors); // Set the new error state

        const newChannels = questionData.questionValue.map((channel, i) => {
          if (i === idx && validationMessage) {
            isAnswered = false;
          } else if (i !== idx && validateURL(channel.url)) {
            isAnswered = false;
          }
          return i === idx ? { ...channel, url: url.trim() } : channel;
        });

        updateQuestions({
          ...questionData,
          questionValue: newChannels,
          isAnswered,
        });

        if (newChannels.length !== 0 && isAnswered) {
          saveQuestionsData(
            {
              stepId,
              stepQuestions: {
                [questionData.questionKey]: newChannels,
              },
            },
            true,
          );
        }
      }
    },
    [questionData, saveQuestionsData, stepId, updateQuestions, urlErrors],
  );

  const deleteChannel = useCallback(
    (index: number) => {
      if (isBugChannelsQuestion(questionData)) {
        const newChannels = [
          ...questionData.questionValue.slice(0, index),
          ...questionData.questionValue.slice(index + 1),
        ];

        const isAnswered =
          newChannels.find((channel) => channel.url === "") === undefined;

        updateQuestions({
          ...questionData,
          questionValue: newChannels,
          isAnswered,
        });

        if (newChannels.length !== 0 && isAnswered) {
          saveQuestionsData({
            stepId,
            stepQuestions: {
              [questionData.questionKey]: newChannels,
            },
          });
        }
      }
    },
    [questionData, saveQuestionsData, stepId, updateQuestions],
  );

  return (
    <div className="ms-3">
      {questionData.questionValue.map((channel, idx) => {
        return (
          <div key={idx}>
            <div className="d-flex flex-column w-100">
              <div className="d-flex align-items-center w-100">
                <span className={`${styles.urlText}`}>URL</span>
                {questionData.listOfOptions && (
                  <Select
                    options={options}
                    value={{
                      value: channel.optionKey,
                      label: optionsMap[channel.optionKey].label,
                    }}
                    isDisabled={isQuestionDisabled}
                    onChange={(val) => {
                      if (val?.value) {
                        handleOptionChange(idx, val.value);
                      }
                    }}
                    className="mx-2"
                    styles={defaultStyles}
                  />
                )}

                <input
                  type="text"
                  className={`form-control w-100 ${styles.inputBox} ${
                    urlErrors[idx] ? styles.errBorder : ""
                  }`}
                  disabled={isQuestionDisabled}
                  value={channel.url}
                  onChange={(e) => handleURLChange(idx, e.target.value)}
                  id={`${channel.optionKey}_${idx}`}
                  placeholder="Enter the URL"
                />

                {idx !== 0 && (
                  <span
                    className={`ps-2 ${isQuestionDisabled ? "opacity-50" : ""}`}
                    onClick={() => {
                      if (isQuestionDisabled) {
                        return;
                      }

                      deleteChannel(idx);
                    }}
                  >
                    <img
                      src={deleteIcon}
                      alt="delete"
                      width={14}
                      height={19}
                      className="cursor-pointer"
                    />
                  </span>
                )}
              </div>
              {/* Conditional rendering of error message */}
              {urlErrors[idx] && (
                <div className={`${styles.errorText}`}>{urlErrors[idx]}</div>
              )}
            </div>

            {idx !== questionData.questionValue.length - 1 && (
              <span className={`${styles.andText} my-2`}>AND</span>
            )}
          </div>
        );
      })}

      <div
        className={`cursor-pointer d-flex align-items-center max-content-width pt-2 ${
          isQuestionDisabled ? "opacity-50" : ""
        }`}
        onClick={() => {
          if (isQuestionDisabled) {
            return;
          }

          addMoreUrl();
        }}
      >
        <div className="dropdown">
          <div
            className={`me-2 d-flex align-items-center ${styles.addMoreBtn}`}
          >
            <span className="">
              {" "}
              <i className="fa-solid fa-plus"></i>
            </span>
          </div>
        </div>
        <div className="">
          <span className={`${styles.addMoreText}`}>Add more</span>
        </div>
      </div>
    </div>
  );
};

const defaultStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: "white",
    minWidth: "130px",
    color: "#000",
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "18px",
    minHeight: "37px",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    color: state.isSelected ? "#FFF" : "#000",
    // backgroundColor: state.isSelected ? "" : "#FFF",
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "18px",
    "&:hover": {
      backgroundColor: "#f5f5f5",
      color: "#000",
    },
  }),
  menu: (provided: any) => ({
    ...provided,
    padding: "10px",
  }),
};

export default ChannelQuestion;
