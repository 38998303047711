import { useMemo } from "react";
import { replaceKeyWithVariableName } from "src/utils/utils";
import styles from "./DesktopViewFollowup.module.scss";
import ParseHTML from "src/components/ParseHTML";

const DesktopViewFollowup = ({
  companyName,
  data,
  variableList,
}: {
  companyName: string;
  data: any;
  variableList?: any;
}) => {
  const salutation = useMemo(
    () => replaceKeyWithVariableName(data?.salutation, variableList),
    [data?.salutation, variableList],
  );

  const body = useMemo(
    () => replaceKeyWithVariableName(data?.body, variableList),
    [data?.body, variableList],
  );

  return (
    <div className={`${styles.deskTopView}`}>
      <div className={`px-4 py-5 ${styles.emailWrap}`}>
        <div className={`${styles.msg}`}>
          <div>
            <ParseHTML
              html={salutation}
              showReadMoreLess={false}
            />
          </div>
          {/* We sincerely apologize for any inconvenience you may have
        experienced, and we are committed to resolving this matter for you as
        quickly as possible.
        <br/> <br/>To assist you more efficiently, could you please
        provide us with your order number? This information will help us locate
        your specific transaction and enable us to investigate the issue
        thoroughly. */}
          <div>
            <ParseHTML
              html={body}
              showReadMoreLess={false}
            />
          </div>
        </div>
        <br />
        <p className={`${styles.msg}`}>
          Best Regards,
          <br /> Team {companyName}
        </p>
      </div>
      <div className="pb-3">
        <hr />
        {/* <p className={`${styles.templateString}`}>
          To stop receiving Update emails, unsubscribe.
        </p> */}
        <span className={`${styles.templateString}`}>
          Copyright © {new Date().getFullYear()} {companyName ?? ""} <br /> All
          rights reserved.
        </span>
      </div>
    </div>
  );
};

export default DesktopViewFollowup;
