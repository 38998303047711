/**
 * This file is the service file used for making api request.
 * It contains the addOrUpdateSettingService function service which is explained below.
 *
 * @author Yash Aditya
 */

// Import statements for types
import { axiosJSON } from "src/globals/axiosEndPoints";

// Type for time values
export interface TimeValue {
  time: string; // Format - 23:59:59
  timeZoneId: string; // TimeZoneId
}

export interface NotifYFreqTimeValues {
  weekDayKey?: string; // (id sent in meta data) compulsory for weekly type
  monthKey?: string; // (id sent in meta data) compulsory for yearly type
  date?: string; // (1,31) compulsory for yearly and monthly types
  // Will be sent when the fixedNoOfTimes will be present and it will not be 0.
  times?: Array<TimeValue>;
  // Will be sent if fixedNoOfTimes will not be present and number of time will exceed 8.
  xNoOfTimes?: number;
  timeZoneId?: string; // TimeZoneId might be sent if times not sent
}

// Type for daily, weekly, monthly, and yearly notification frequency data
export interface NotificationFrequencyData {
  frequencyTypeId: string | number; // NotificationFrequencyTypeId
  type: "daily" | "weekly" | "monthly" | "yearly";
  timeValues?: Array<NotifYFreqTimeValues>;
}

// Function to add or update notification settings
export const addOrUpdateSettingService = async (
  params: NotificationFrequencyData
) => {
  const { data: res } = await axiosJSON.post(
    "/api/reports/notificationSettings/updateSetting",
    params
  );

  if (res.err) {
    throw res.msg;
  }

  return true;
};
