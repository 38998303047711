import { axiosJSON } from "src/globals/axiosEndPoints";

interface IntegrateFacebookParam {
  brand_id?: number;
}
export async function integrateFacebook(params: IntegrateFacebookParam) {
  const { data: res } = await axiosJSON.get(
    "/api/setting/integration/facebook/start",
    { params },
  );
  if (res.err) {
    throw res.msg;
  }
  return res.url;
}

export interface FacebookIntegrationData {
  name: string;
}

export async function isFacebookIntegrated() {
  const { data: res } = await axiosJSON.get(
    "/api/setting/integration/facebook/isIntegrated",
  );
  if (res.err) {
    throw res.msg;
  }
  return res.data as FacebookIntegrationData;
}

export interface FacebookPage {
  id: string | number;
  name: string;
  imageUrl?: string | null;
  category: string;
  integrated: boolean;
}

export async function getFacebookPages() {
  const { data: res } = await axiosJSON.get(
    "/api/setting/integration/facebook/getPages",
  );
  if (res.err) {
    throw "Something went wrong!";
  }
  const pages: { [key: string | number]: FacebookPage } = {};
  const pageIds: Array<string | number> = [];
  res.data.forEach((page: FacebookPage) => {
    pages[page.id] = page;
    pageIds.push(page.id);
  });
  return {
    pages,
    pageIds,
    integrationId: res?.metaData?.integration_id,
    metaData: res?.metaData,
  };
}

export async function subscribeFacebookPage(params: {
  page_ids: Array<string | number>;
}) {
  const { data: res } = await axiosJSON.post(
    "/api/setting/integration/facebook/integratePage",
    params,
  );

  if (res.err || res.error) {
    if (res && res["display-error"]) {
      throw new Error(res["display-error"]);
    } else {
      throw new Error(res.msg ?? "Failed to subscribe page");
    }
  }

  return res.msg;
}

export async function unsubscribeFacebookPage(params: {
  page_id: string | number;
}) {
  const { data: res } = await axiosJSON.post(
    "/api/setting/integration/facebook/unsubscribePage",
    params,
  );
  if (res.err || res.error) {
    if (res && res["display-error"]) {
      throw new Error(res["display-error"]);
    } else {
      throw new Error(res.msg ?? "Failed to unsubscribe page");
    }
  }
  return res.msg;
}
export interface IDeleteFacebookIntegrationParam {
  integrationId: string | number;
}
export async function deleteFacebookIntegration(
  params: IDeleteFacebookIntegrationParam,
) {
  const { data: res } = await axiosJSON.delete(
    `/api/setting/integration/facebook/deleteFacebookUser`,
    { params },
  );

  if (res.err || res.error) {
    if (res && res["display-error"]) {
      throw new Error(res["display-error"]);
    } else {
      throw new Error(res.msg ?? "Failed to delete integration");
    }
  }

  return res;
}
export interface IntegrationFacebookPageParams {
  integration_id: number;
}

//same api is used for integration facebook and page, separating this service function just incase if integrate page api is separated on future
export async function integrateFacebookPage(
  params: IntegrationFacebookPageParams,
) {
  const { data: res } = await axiosJSON.get(
    "/api/setting/integration/facebook/start",
    { params },
  );
  if (res.err) {
    throw res.msg;
  }
  return res.url;
}
