import { useCallback, useEffect, useRef, useState } from "react";
import InfiniteScroll from "src/components/InfiniteScroll";
import Loader from "src/components/Loader";
import UserAvatar from "src/components/UserAvatar";
import { AJAXSTATUS } from "src/globals/constants";
import { BrandAgent } from "src/services/Settings/Brands/getBrand";
import UserService from "src/services/UserService";
import { getBrandDisabledAgentIdsSelector } from "src/store/slices/settings/brands/brandSettings.slice";
import { useAppSelector } from "src/store/store";
import styles from "./ReassignToDropDown.module.scss";
import AxiosImg from "src/components/AxiosImg";


interface Props {
  selectedUser: BrandAgent;
  onSelect: (selectedUser: BrandAgent) => void;
  show: boolean;
  userIds: Array<number|string>,
  users: {[key: string|number]: BrandAgent}
  metaData: {
    total: number,
    count: number,
  }
  ajaxStatus: AJAXSTATUS,
  handleInfiniteScroll: any,
  defaultUserIds: Array<number|string>
}

interface UserItemProp {
  user: any;
  userSelected: any;
  onSelect: any;
  isDisabled: boolean
}

const UserListItem = ({ user, userSelected, onSelect, isDisabled }: UserItemProp) => {
  const handleOnClick = (e: any) => {
    if(!isDisabled){
      onSelect(user);
    }
  };
  return (
    <div
      className={`mt-1 py-1 ${
        userSelected === user.id ? styles.addBoxSelected : styles.addBox
      } ${isDisabled ? `${styles.disabledUser}` : ''}`}
      onClick={handleOnClick}
    >
      <span>
        <span className="me-2">
        { user?.profileImageUrl && user?.profileImageUrl.trim().length !== 0 ?
            <AxiosImg
            className={`${styles.profileIcon}`}
            url={user.profileImageUrl}
            isDirectURL={user.isPublicAttachmentUrl}
          />
          :
          <UserAvatar name={user.name || "NA"} size={25} />
        }
        </span>
        <span className={`${styles.assignName}`}>{user.name}</span>
      </span>
    </div>
  );
};


export function ReassignToDropDown({
  selectedUser,
  onSelect,
  userIds,
  users,
  defaultUserIds = [],
  metaData,
  show,
  ajaxStatus,
  handleInfiniteScroll
}: Props) {

  const brandDisabledAgentIds = useAppSelector(getBrandDisabledAgentIdsSelector);

  return (
    <div className={`${styles.relative}`}>
      {show && (
        <div className={`${styles.body}`}>
          <h5 className={`${styles["addTag_Title"]}`}>Assign to</h5>
          {(ajaxStatus === "pending" &&
            userIds.length === 0) ? (
            <Loader
              style={{
                marginTop: "-90px"
              }}
            />
          ): null}
            <div
              className={`${styles.userListContainer} text-start`}
            >
              <InfiniteScroll
                className={`${styles.infScroll}`}
                loadMore={handleInfiniteScroll}
                initialDataLoaded={true}
                hasMore={
                  userIds.length < metaData.total || metaData.total === 0
                }
                errorMessage={
                  metaData.total === 0 &&
                  (ajaxStatus === "rejected" ||
                    ajaxStatus === "fulfilled") ? (
                    <></>
                  ) : undefined
                }
              >
              {userIds.map((userId) => {
                const user = users[userId];
                  return (
                    <UserListItem
                      key={user.id}
                      user={user}
                      userSelected={selectedUser.id}
                      onSelect={onSelect}
                      isDisabled={brandDisabledAgentIds.includes(userId) || defaultUserIds.includes(userId)}
                    />
                  );
              })}
              </InfiniteScroll>
            </div>
        </div>
      )}
    </div>
  );
}
