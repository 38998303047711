import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { initialState } from "./botProfiles.declarations";
import botProfilesReducers from "./botProfiles.reducers";
import { builderFunction } from "./botProfiles.builder";

export const botProfilesSlice = createSlice({
  name: "botProfiles",
  initialState,
  reducers: botProfilesReducers,
  extraReducers: builderFunction,
});

export const {
  resetBotProfiles,
  addAudienceConfigCondition,
  updateAudienceConfigCondition,
  removeAudienceConfigCondition,
  resetAudienceConfigCondition,
  setBackButtonRedirectPath,
} = botProfilesSlice.actions;

export default botProfilesSlice.reducer;
