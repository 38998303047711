import FinalChat from "../../Customization/Children/FinalChat/FinalChat";
import styles from "./TicketForm.module.scss";
import TicketChatComp from "./Children/TicketChatComp/TicketChatComp";
import { useEffect, useRef, useState } from "react";
import textLogo from "src/assets/images/text.png";
import choiceImg from "src/assets/images/choiceList.png";
import dropdown from "src/assets/images/dropdown.png";
import multipleChoice from "src/assets/images/multipleChoice.png";
import formDisable from "src/assets/images/formDisable.png";
import chatRating from "src/assets/images/chatRating.png";
import thankYou from "src/assets/images/thankYou.png";
import { fetchTicketChatFormData } from "src/store/slices/liveChatSetting/Forms/ticketChatForm/ticketChatFormSetting.slice";
import ElementText from "../FormElements/Text/ElementText";
import ElementMultiChoiceList from "../FormElements/MultiChoiceList/ElementMultiChoiceList";
import ElementDropDown from "../FormElements/DropDown/ElementDropDown";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { v4 as uuidv4 } from "uuid";
import Loader from "src/components/Loader";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import {
  IUpdateTicketChatFormDataParam,
  updateTicketChatFormData,
} from "src/services/LiveChat/Settings/ticketChatForm/updateTicketChatFormData";
import cautionImg from "src/assets/images/blueCaution.png";
import { Link, useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const TicketForm = () => {
  const {
    ticketChatFormData,
    formElementOptions,
    ticketChatFormDataAjaxStatus,
    updateTicketChatFormDataAjaxStatus,
  } = useAppSelector((state) => state.ticketChatFormSetting);
  const dispatch = useAppDispatch();

  const { integrationId } = useParams();
  const pageIntegrationId = integrationId
    ? decodeURIComponent(integrationId)
    : "";

  const [formElements, setFormElements] = useState<any>([]);
  const [elementOptions, setElementOptions] = useState<any>([]);
  const [enabled, setEnabled] = useState(false);
  const [sendTicketFormTo, setSendTicketFormTo] = useState(
    ticketChatFormData.sendTicketFormMessageTo
  );
  const [showSaveChanges, setShowSaveChanges] = useState(false);
  const [customEmail, setCustomEmail] = useState("");
  const [showSaveLoader, setShowSaveLoader] = useState(false);

  const handleInput = () => {
    setEnabled(!enabled);
  };

  const prevElementState = useRef<any>();

  const elementTypesList: {
    [key: string]: { component: any };
  } = {
    email: { component: ElementText },
    text: { component: ElementText },
    longText: { component: ElementText },
    choiceList: { component: ElementMultiChoiceList },
    multipleChoiceList: { component: ElementMultiChoiceList },
    information: { component: ElementText },
    dropDown: { component: ElementDropDown },
  };

  useEffect(() => {
    dispatch(fetchTicketChatFormData(pageIntegrationId));
  }, [false]);

  useEffect(() => {
    if (ticketChatFormData !== null) {
      if (
        ticketChatFormData.enabled !== enabled ||
        JSON.stringify(prevElementState.current) !==
          JSON.stringify(formElements) ||
        ticketChatFormData.sendTicketFormMessageTo !== sendTicketFormTo ||
        ticketChatFormData.customEmail !== customEmail
      ) {
        setShowSaveChanges(true);
      } else {
        setShowSaveChanges(false);
      }
    }
  }, [formElements, enabled, customEmail, sendTicketFormTo]);

  useEffect(() => {
    if (ticketChatFormData !== null) {
      setEnabled(ticketChatFormData.enabled);

      let formElementList = ticketChatFormData.elements.map((element: any) => {
        let key = uuidv4();
        return {
          ...element,
          uniqueId: key,
          isNewlyAdded: false,
        };
      });

      setSendTicketFormTo(ticketChatFormData.sendTicketFormMessageTo);
      setCustomEmail(ticketChatFormData.customEmail);
      setFormElements(formElementList);
      prevElementState.current = formElementList;
    }
  }, [ticketChatFormData]);

  useEffect(() => {
    if (formElementOptions !== null && formElements !== null) {
      const options = formElementOptions.formElementOptionIds.map(
        (optionId, index) => {
          let elementOption = formElementOptions.formElementOptions[optionId];
          let option = {
            ...elementOption,
            isAlreadyExists: false,
          };
          let element = formElements.filter(
            (element: any) => optionId === element.elementId
          );
          if (element.length === 0 || elementOption.canHaveMultiple === true) {
            option.isAlreadyExists = false;
          } else {
            option.isAlreadyExists = true;
          }

          return option;
        }
      );
      setElementOptions(options);
    }
  }, [formElements, formElementOptions]);

  const handleElementChange = (element: any) => {
    if (formElements === null) {
      return;
    }
    const stateFormElements = formElements.map((formElement: any) => {
      if (element.uniqueId === formElement.uniqueId) {
        return element;
      } else {
        return formElement;
      }
    });
    setFormElements(stateFormElements);
  };

  const handleNewElement = (optionId: any) => {
    if (optionId !== null) {
      let elementOption = elementOptions.filter(
        (element: any) => optionId === element.elementId
      );

      if (elementOption.length === 0) {
        return;
      }

      elementOption = elementOption[0];

      if (
        elementOption.canHaveMultiple === false &&
        elementOption.isAlreadyExists === true
      ) {
        return;
      }

      if (
        ["choiceList", "multipleChoiceList", "dropDown"].includes(
          elementOption.elementType
        )
      ) {
        let newElement = {
          elementId: elementOption.elementId,
          elementType: elementOption.elementType,
          elementKey: elementOption.elmentType + "_" + uuidv4(),
          elementLabel: elementOption.elementLabel,
          elementValue: "Question",
          elementOptions: ["Answer 1", "Answer 2"],
          isRequired: false,
          isDefault: false,
          uniqueId: uuidv4(),
          isNewlyAdded: true,
        };

        const stateFormElements = [...formElements, newElement];
        setFormElements(stateFormElements);
      } else {
        let newElement = {
          elementId: elementOption.elementId,
          elementType: elementOption.elementType,
          elementKey: elementOption.elementType + "_" + uuidv4(),
          elementLabel: elementOption.elementLabel,
          elementValue: "Question",
          elementOptions: [],
          isRequired: false,
          isDefault: false,
          uniqueId: uuidv4(),
          isNewlyAdded: true,
        };
        const stateFormElements = [...formElements, newElement];
        setFormElements(stateFormElements);
      }
    }
  };

  const handleElementDelete = (element: any) => {
    if (formElements === null) {
      return;
    }
    const stateFormElements = formElements.filter(
      (formElement: any) => element.uniqueId !== formElement.uniqueId
    );

    setFormElements(stateFormElements);
  };

  const handleCancelButton = () => {
    if (formElements === null && ticketChatFormData !== null) {
      return;
    }

    setEnabled(ticketChatFormData.enabled);

    let formElementList = ticketChatFormData.elements.map((element: any) => {
      let key = uuidv4();
      return {
        ...element,
        uniqueId: key,
      };
    });

    setFormElements(formElementList);
    prevElementState.current = formElementList;

    setShowSaveChanges(false);
  };

  const isEmailsValid = (emailList: any) => {
    for (let i = 0; i < emailList.length; i += 1) {
      const regEx = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
      if (!regEx.test(emailList[i].trim())) {
        return false;
      }
    }
    return true;
  };
  const handleSubmit = () => {
    if (ticketChatFormDataAjaxStatus !== "pending" && formElements !== null) {
      if (formElements.length === 0) {
        pushTheToast({
          type: "warning",
          text: "Atleast one element is required",
          position: "top-right",
        });
      }

      let payload: IUpdateTicketChatFormDataParam = {
        integrationId: pageIntegrationId,
      };
      if (enabled !== ticketChatFormData.enabled) {
        payload.enabled = enabled;
      }
      if (sendTicketFormTo !== ticketChatFormData.sendTicketFormMessageTo) {
        payload.sendTicketFormMessageTo = sendTicketFormTo;
      }

      if (sendTicketFormTo === "custom") {
        //validate input
        if (customEmail === "") {
          pushTheToast({
            type: "warning",
            text: "Custom Email Cannot be empty!",
            position: "top-right",
          });
          return;
        }
        const emailList = customEmail.split(",");
        const emailValidationResult = isEmailsValid(emailList);

        if (!emailValidationResult) {
          pushTheToast({
            type: "warning",
            text: "Custom Email is invalid!",
            position: "top-right",
          });
          return;
        }
        payload.customEmail = customEmail;
      }

      const emptyValueElements = formElements.filter(
        (element: any) => element.elementValue.length === 0
      );

      if (emptyValueElements.length > 0) {
        pushTheToast({
          type: "warning",
          text: "Please fill all the fields",
          position: "top-right",
        });
        return;
      }

      let payloadElements = formElements.map((element: any) => {
        return {
          elementId: element.elementId,
          elementKey: element.elementKey,
          elementValue: element.elementValue,
          elementOptions: element.elementOptions,
          isRequired: element.isRequired,
          isDefault: element.isDefault,
          isNewlyAdded: element.isNewlyAdded,
        };
      });

      payload.elements = payloadElements;
      setShowSaveLoader(true);

      updateTicketChatFormData(payload)
        .then((res) => {
          pushTheToast({
            type: "success",
            text: "Successfully changed",
            position: "top-right",
          });
          setShowSaveChanges(false);
          setShowSaveLoader(false);

          dispatch(fetchTicketChatFormData(pageIntegrationId));
        })
        .catch((err) => {
          pushTheToast({
            type: "danger",
            text: "Something went wrong!",
            position: "top-right",
          });
          setShowSaveLoader(false);
        });
      // setShowSaveLoader(false);
    }
  };

  return (
    <div>
      <div className={`d-flex flex-wrap`}>
        <div className={`${styles.customLeft}`}>
          <div className={`${styles.contentWrapper}`}>
            <h2 className={`${styles.preChatHead}`}>Ticket Forms</h2>
            <span className={`${styles.preChatSub}`}>
              {" "}
              Let your customers leave messages when you’re offline. These
              messages are saved as tickets.
            </span>
            {ticketChatFormDataAjaxStatus !== "pending" &&
              ticketChatFormData.availability.canCustomerStartChatAlways ===
                false && (
                <p className={`mt-2 d-flex align-items-center`}>
                  <span className={`${styles.enablePre}`}>
                    Show Ticket Form :
                  </span>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      onChange={handleInput}
                      checked={enabled}
                    />
                    <label
                      className={`ms-1 form-check-label ${styles.toggleSpan}`}
                      htmlFor="flexSwitchCheckDefault"
                    >
                      {enabled ? "on" : "off"}
                    </label>
                  </div>
                </p>
              )}

            {ticketChatFormDataAjaxStatus === "pending" ? (
              <Loader />
            ) : (
              ticketChatFormData !== null &&
              (ticketChatFormData.availability.canCustomerStartChatAlways ===
              true ? (
                <div className="mt-3">
                  <div className={`${styles.formBox} d-flex`}>
                    <span
                      className="me-2 ms-2
                  "
                    >
                      <img
                        src={cautionImg}
                        className={`${styles.cautionIcon}`}
                      />
                    </span>
                    <div>
                      <p className={`${styles.cautionText}`}>
                        Chats can start anytime even if agents are away.
                      </p>
                      <span className={`${styles.cautionSpan}`}>
                        You can change this in{" "}
                        <Link
                          to={`/live-chat/settings/${pageIntegrationId}/Availability`}
                          className={`text-decoration-none`}
                        >
                          availability
                        </Link>{" "}
                        section
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className={`${styles.ticketWrapper}`}>
                    {enabled && (
                      <>
                        <div>
                          <h3 className={`${styles.sendTicket}`}>
                            Send ticket form messages to:
                          </h3>
                          <div
                            className={`d-flex mb-1 ${styles.paddingLeftFourPx}`}
                          >
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id="flexRadioDefault2"
                                checked={sendTicketFormTo === "livechat"}
                                onClick={(e) => setSendTicketFormTo("livechat")}
                              />
                            </div>
                            <h5 className={`${styles.radioText}`}>LiveChat</h5>
                          </div>
                          <div
                            className={`d-flex mb-1 ${styles.paddingLeftFourPx}`}
                          >
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id="flexRadioDefault2"
                                checked={sendTicketFormTo === "custom"}
                                onClick={(e) => setSendTicketFormTo("custom")}
                              />
                            </div>
                            <h5 className={`${styles.radioText}`}>
                              Custom email address
                            </h5>
                          </div>
                        </div>
                        {enabled && (
                          <>
                            <div className={`mb-3`}>
                              {sendTicketFormTo === "custom" && (
                                <>
                                  <input
                                    className={`${styles.mailInput}`}
                                    type={`text`}
                                    placeholder="youremail@company.com"
                                    value={customEmail}
                                    onChange={(e) =>
                                      setCustomEmail(e.target.value)
                                    }
                                  />
                                  <span className={`${styles.mailText}`}>
                                    You can separate many addresses with a
                                    comma.
                                  </span>
                                </>
                              )}

                              <h4 className={`mt-3 ${styles.customHead}`}>
                                Customize your ticket form
                              </h4>
                            </div>
                            {enabled === true ? (
                              <DragDropContext
                                onDragEnd={(param: any) => {
                                  const srcI = param.source.index;
                                  const desI = param.destination?.index;
                                  if (desI !== undefined && desI !== null) {
                                    const updatedList = [...formElements];
                                    updatedList.splice(
                                      desI,
                                      0,
                                      updatedList.splice(srcI, 1)[0]
                                    );
                                    setFormElements(updatedList);
                                  }
                                }}
                              >
                                <Droppable droppableId="droppable-1">
                                  {(provided: any, _: any) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.droppableProps}
                                      className={`${styles.overIssue}`}
                                    >
                                      {console.log(formElements)}
                                      {formElements.map(
                                        (element: any, i: number) => {
                                          const ShowComp =
                                            elementTypesList[
                                              element.elementType
                                            ].component;
                                          const hideDelete =
                                            formElements.length === 1;
                                          return (
                                            <Draggable
                                              key={element.uniqueId}
                                              draggableId={
                                                "draggable-" + element.uniqueId
                                              }
                                              index={i}
                                            >
                                              {(
                                                provided: any,
                                                snapshot: any
                                              ) => (
                                                <ShowComp
                                                  customProvided={provided}
                                                  element={element}
                                                  handleElementDelete={
                                                    handleElementDelete
                                                  }
                                                  handleElementChange={
                                                    handleElementChange
                                                  }
                                                  hideDelete={hideDelete}
                                                />
                                              )}
                                            </Draggable>
                                          );
                                        }
                                      )}
                                      {provided.placeholder}
                                    </div>
                                  )}
                                </Droppable>
                              </DragDropContext>
                            ) : undefined}
                          </>
                        )}
                      </>
                    )}
                    {enabled && (
                      <>
                        <div className={`d-flex pt-2`}>
                          <div className="dropdown">
                            <button
                              className={`${styles.addBox} me-2 dropdown-toggle`}
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i className="fa-solid fa-plus"></i>
                            </button>
                            <ul
                              className={`dropdown-menu ${styles.dropBox}`}
                              aria-labelledby="dropdownMenuButton1"
                            >
                              {elementOptions.map((option: any) => {
                                return (
                                  <li key={uuidv4()}>
                                    <span
                                      onClick={(e) => {
                                        handleNewElement(option.elementId);
                                      }}
                                      className={
                                        option.isAlreadyExists === true
                                          ? `dropdown-item ${styles.dropItem}`
                                          : `dropdown-item ${styles.dropItem2}`
                                      }
                                    >
                                      <img
                                        src={textLogo}
                                        className={`${styles.textLogo}`}
                                        alt=""
                                      />
                                      {option.elementLabel}
                                    </span>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                          <div className="d-flex align-items-center">
                            <span className={`${styles.addElement}`}>
                              Add Element
                            </span>
                          </div>
                        </div>
                      </>
                    )}{" "}
                  </div>
                  {/* Add element dropdown  */}
                </>
              ))
            )}
          </div>
          {showSaveChanges && (
            <>
              <div className={`flex-column flex-md-row ${styles.actionBtn}`}>
                <button
                  className={`me-2 bg-white px-2 ${styles.cancelBtn}`}
                  onClick={handleCancelButton}
                >
                  Cancel
                </button>
                <button
                  className={`mt-2 mt-md-0 px-2 ${styles.saveBtn}`}
                  disabled={showSaveLoader === true}
                  onClick={handleSubmit}
                >
                  {showSaveLoader === true ? (
                    <Spinner
                      className="my-auto mx-1"
                      animation="border"
                      color="white"
                      size="sm"
                    />
                  ) : (
                    "Save changes"
                  )}
                </button>
              </div>
            </>
          )}
        </div>
        <div className={`${styles.customRight}`}>
          {enabled &&
          ticketChatFormData &&
          ticketChatFormData.availability.canCustomerStartChatAlways ===
            false ? (
            <FinalChat
              formData={{
                elements: formElements,
                submitButtonText: "Submit",
              }}
              show={{ chatForm: true }}
            />
          ) : (
            <div
              className={`d-flex justify-content-center align-items-center text-center ${styles.disableChat}`}
            >
              <img className={`mb-2`} src={formDisable} alt="disable" />
              <p className={`${styles.disableSpan}`}>
                {" "}
                "Ticket form is disabled."
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default TicketForm;
