import { useCallback, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Col, Form, Modal, Nav, Row, Tab } from "react-bootstrap";
import AddConditionButton from "src/components/AutomationComponents/ContentSection/AddConditionButton";
import Condition from "src/components/AutomationComponents/ContentSection/Condition";
import OperatorSelect from "src/components/AutomationComponents/ContentSection/OperatorSelect";
import Values from "src/components/AutomationComponents/ContentSection/Values";
import VariableSelect from "src/components/AutomationComponents/ContentSection/VariableSelect";
import DiscardAndSave from "src/components/AutomationComponents/DiscardAndSave";
import DiscardModal from "src/components/AutomationComponents/DiscardModal/DiscardModal";
import SkeletonModalStructure from "src/components/AutomationComponents/SkeletonModalStructure/SkeletonModalStructure";
import AddMore from "src/components/AutomationComponents/TabSection/AddMore";
import AutomationTab from "src/components/AutomationComponents/TabSection/AutomationTab";
import CloseButtonForTab from "src/components/AutomationComponents/TabSection/CloseButtonForTab";
import ConditionGroup from "src/components/AutomationComponents/TabSection/ConditionGroup";
import TabError from "src/components/AutomationComponents/TabSection/TabError";
import TabName from "src/components/AutomationComponents/TabSection/TabName";
import Loader from "src/components/Loader";
import { useFetchReturnProducts } from "src/features/ReturnAutoWorkFlow/hooks/useFetchReturnConditions";
import { useReturnAutoWorkFlow } from "src/features/ReturnAutoWorkFlow/hooks/useReturnAutoWorkFlow";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  addMoreQuestion,
  addMoreQuestionOption,
  addReason,
  deleteReasonType,
  ReasonFollowUpQuestion,
  removeMoreQuestion,
  removeMoreQuestionOption,
  reorder,
  reorderMoreQuestion,
  saveMoreQuestion,
  setMoreQuestionModal,
  setSelectedReasonTypeId,
  undoConfig,
  updateMoreQuestionOption,
  updateMoreQuestionType,
  updateMoreQuestionValue,
  updateReasonOption,
  validateChanges,
} from "../../store/slices/ReturnReasonOrderAndItems/returnReasonOrderAndItems.slice";
import { fetchReturnReasonOrderAndItems } from "../../store/slices/ReturnReasonOrderAndItems/returnReasonOrderAndItems.thunk";
import AskQuestion from "./children/AskQuestion/AskQuestion";
import DraggableInput from "./children/DraggableInput/DraggableInput";
import styles from "./ReturnReasonOrderAndItems.module.scss";
import useReturnReasonOrderAndItems from "./useReturnReasonOrderAndItems";

const Component = ({ onSave }: { onSave: () => void }) => {
  const { returnAutoWorkFlow } = useReturnAutoWorkFlow();
  const dispatch = useAppDispatch();
  const {
    fetchAjaxStatus,
    updateAjaxStatus,
    selectedReasonTypeId,
    reasonTypes,
    dispUpdateReasonType,
    dispPushCurrentHashForSelectedTab,
    dispAddTab,
    dispValidateReturnType,
    initialHashOtherTab,
    currentHashOtherTab,
    dispValidateCondition,
    dispValidateMoreQuestions,
    itemOptions,
    dispAddCondition,
    dispDeleteCondition,
    dispUpdateConditionForVariables,
    dispUpdateConditonForOperator,
    operators,
    isAddItemBtnEnabled,
    defaultOptions,
    conditionOptions,
    isAddReasonEnabled,
    dispPostConfig,
    dispUpdateConditionForValues,
    isAddBtnEnabled,
    onToggleAllOrder,
    onToggleAllItems,
    formElementOptions,
    moreQuestionsModal,
  } = useReturnReasonOrderAndItems({ onSave });
  const { loadingStatus, fetchProducts, products } = useFetchReturnProducts();

  useEffect(() => {
    if (loadingStatus !== "pending" && products.length === 0) {
      fetchProducts();
    }
  }, [fetchProducts, loadingStatus, products.length]);

  if (fetchAjaxStatus === "pending") {
    return (
      <div className={`${styles.loaderHeight}`}>
        <Loader />
      </div>
    );
  }

  return (
    <div className="position-relative">
      {updateAjaxStatus === "pending" ? (
        <div className={`${styles.loadingBlock}`}></div>
      ) : (
        ""
      )}
      <Tab.Container
        id="left-tabs-example"
        activeKey={selectedReasonTypeId ?? ""}
        onSelect={(eventKey) => {
          if (selectedReasonTypeId !== eventKey) {
            dispatch(setSelectedReasonTypeId({ reasonTypeId: eventKey ?? "" }));
          }
        }}
      >
        <Row bsPrefix={`row ${styles.rowSet}`}>
          <Col>
            <Nav
              variant="tabs"
              className={`d-flex align-items-center border-0 ${styles.tabSectionWrapper} `}
            >
              {reasonTypes.map((res, idx) => {
                return (
                  <Nav.Item
                    key={res.reasonTypeId}
                    className=""
                    role="button"
                  >
                    <Nav.Link
                      eventKey={res.reasonTypeId}
                      className={`py-1 px-3  text-dark shadow-sm  ${styles.tabStyles}`}
                      style={{
                        background:
                          selectedReasonTypeId !== null
                            ? selectedReasonTypeId === res.reasonTypeId
                              ? res.errorCount > 0
                                ? "#FFDCDC 0% 0% no-repeat padding-box"
                                : "#FFFFFF 0% 0% no-repeat padding-box "
                              : res.errorCount > 0
                                ? "#FFDCDC 0% 0% no-repeat padding-box"
                                : "#f5f5f5 0% 0% no-repeat padding-box"
                            : "",
                      }}
                    >
                      <AutomationTab idx={idx}>
                        <TabName
                          id={res.reasonTypeId}
                          name={res.reasonTypeName}
                          updateType={dispUpdateReasonType}
                          pushCurrentHash={dispPushCurrentHashForSelectedTab}
                        />

                        <TabError errorCount={res.errorCount} />

                        <div className="ms-1">
                          <CloseButtonForTab
                            index={idx}
                            name={res.reasonTypeName}
                            hasStaticTab={false}
                            onDelete={() => {
                              dispatch(
                                deleteReasonType({ id: res.reasonTypeId }),
                              );
                            }}
                          />
                        </div>
                      </AutomationTab>
                    </Nav.Link>
                  </Nav.Item>
                );
              })}
              <AddMore
                AddTab={dispAddTab}
                text="Add custom return reasons type"
                selectedTabId={selectedReasonTypeId}
                validateTabType={dispValidateReturnType}
              />
            </Nav>

            <Tab.Content
              className={`shadow ${styles.contentStyles} pt-4  px-4 ${
                initialHashOtherTab &&
                currentHashOtherTab &&
                initialHashOtherTab === currentHashOtherTab &&
                styles.noDiscardAndSave
              }`}
            >
              {reasonTypes.map((res, idx) => {
                const allSelectedConditionNames: string[] = res.conditions.map(
                  (condition) => condition.variableName,
                );

                const filteredConditions = conditionOptions.filter(
                  (condition) => !allSelectedConditionNames.includes(condition),
                );

                return (
                  <Tab.Pane
                    key={res.reasonTypeId}
                    eventKey={res.reasonTypeId}
                    unmountOnExit={true}
                    onEnter={() => {
                      dispValidateMoreQuestions(res.reasonTypeId);
                    }}
                    onEntered={() => {
                      dispValidateMoreQuestions(res.reasonTypeId);
                    }}
                    onExited={() => {
                      dispValidateMoreQuestions(res.reasonTypeId);
                    }}
                  >
                    {/* Select Items Conditions */}
                    {/* Dec 12 Conditionally show select order and items conditions,as those are not required for initial tab as per xd*/}
                    {idx === 0 ? (
                      <>
                        <div className={`py-4 ps-3 pe-2 ${styles.createBox}`}>
                          <ConditionGroup
                            key={res.reasonTypeId + "other_condition_group"}
                            title=""
                            initialShow={true}
                            disableHide={true}
                          >
                            <VariableSelect
                              key={res.reasonTypeId + "Default_option"}
                              id={res.reasonTypeId}
                              condition={{} as any}
                              variablesName={defaultOptions}
                              pushCurrentHash={
                                dispPushCurrentHashForSelectedTab
                              }
                              updateConditon={() => {}}
                              selectedVariableName={""}
                              showPlaceholder={false}
                            />
                          </ConditionGroup>
                          <span>
                            To create specific return reasons for specific kinds
                            of orders and items,
                            <button
                              className={`ps-1 ${styles.clickLink}`}
                              onClick={dispAddTab}
                            >
                              click here
                            </button>
                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        {/* Select order types  */}
                        <ConditionGroup
                          key={res.reasonTypeId + "other_condition"}
                          title="Select order types"
                          errorMessage={res.error}
                        >
                          <div>
                            <div className="d-flex align-items-center">
                              <Form.Label
                                htmlFor={`${res.reasonTypeId}_apply_all_orders_check`}
                                className={`mb-0 me-2 ${styles.applyFormCheck}`}
                              >
                                Apply for all orders
                              </Form.Label>
                              <Form.Check
                                type="switch"
                                id={`${res.reasonTypeId}_apply_all_orders_check`}
                                className={`mb-0 mt-1`}
                                role="button"
                                checked={res.applicableForAllOrders}
                                onChange={(e) =>
                                  onToggleAllOrder(
                                    e.target.checked,
                                    res.reasonTypeId,
                                  )
                                }
                              />
                            </div>
                          </div>

                          {res.applicableForAllOrders ? (
                            <div>
                              <ConditionGroup
                                key={res.reasonTypeId + "other_condition_group"}
                                title=""
                                initialShow={true}
                                disableHide={true}
                              >
                                <div
                                  className={`d-flex align-items-center ${styles.allSelect}`}
                                >
                                  All Orders
                                </div>
                              </ConditionGroup>
                            </div>
                          ) : (
                            <div>
                              {res.conditions.filter(
                                (condition) =>
                                  !itemOptions.includes(condition.variableName),
                              ).length === 0 ? (
                                <div className="">
                                  <ConditionGroup
                                    key={res.reasonTypeId + "other_condition_1"}
                                    title=""
                                    initialShow={true}
                                    disableHide={true}
                                    errorMessage={res.error}
                                  >
                                    <VariableSelect
                                      id={res.reasonTypeId}
                                      condition={{} as any}
                                      variablesName={filteredConditions}
                                      pushCurrentHash={
                                        dispPushCurrentHashForSelectedTab
                                      }
                                      updateConditon={(id: any, e: any) => {
                                        dispAddCondition(id, false, e);
                                      }}
                                      selectedVariableName={""}
                                      showPlaceholder={true}
                                      errorMessage={res.error}
                                    />
                                  </ConditionGroup>
                                </div>
                              ) : null}

                              {res.conditions
                                .filter(
                                  (condition) =>
                                    !itemOptions.includes(
                                      condition.variableName,
                                    ),
                                )
                                .map((condition: any, idx) => {
                                  let isDefault = defaultOptions.includes(
                                    condition.variableName,
                                  );
                                  return (
                                    <Condition
                                      key={res.reasonTypeId}
                                      id={res.reasonTypeId}
                                      condition={condition}
                                      deleteCondition={dispDeleteCondition}
                                      idx={idx}
                                      validateCondition={dispValidateCondition}
                                      // hideDelete={isDefault || idx === 0}
                                    >
                                      <VariableSelect
                                        id={res.reasonTypeId}
                                        condition={condition}
                                        variablesName={
                                          isDefault
                                            ? defaultOptions
                                            : filteredConditions
                                        }
                                        pushCurrentHash={
                                          dispPushCurrentHashForSelectedTab
                                        }
                                        updateConditon={
                                          dispUpdateConditionForVariables
                                        }
                                        selectedVariableName={
                                          condition.variableName
                                        }
                                        showPlaceholder={true}
                                        errorMessage={res.error}
                                      />
                                      {isDefault === false ? (
                                        <>
                                          <OperatorSelect
                                            condition={condition}
                                            id={res.reasonTypeId}
                                            pushCurrentHash={
                                              dispPushCurrentHashForSelectedTab
                                            }
                                            updateConditon={
                                              dispUpdateConditonForOperator
                                            }
                                            operatorNames={
                                              operators[condition.variableName]
                                            }
                                            selectedOperator={
                                              condition.operator
                                            }
                                          />

                                          <Values
                                            id={res.reasonTypeId}
                                            condition={condition}
                                            updateCondition={
                                              dispUpdateConditionForValues
                                            }
                                            pushCurrentHash={
                                              dispPushCurrentHashForSelectedTab
                                            }
                                            useNewComponent={true}
                                          />
                                        </>
                                      ) : null}
                                    </Condition>
                                  );
                                })}
                              <div className="mb-lg-2">
                                <AddConditionButton
                                  AddCondition={dispAddCondition}
                                  id={res.reasonTypeId}
                                  isAddBtnEnabled={isAddBtnEnabled}
                                />
                              </div>
                            </div>
                          )}
                        </ConditionGroup>
                        <ConditionGroup
                          key={res.reasonTypeId + "item_condition"}
                          title="Select Items"
                          errorMessage={res.itemError}
                        >
                          <div>
                            <div className="d-flex align-items-center">
                              <Form.Label
                                htmlFor={`${res.reasonTypeId}_apply_all_items_check`}
                                className={`mb-0 me-2 ${styles.applyFormCheck}`}
                              >
                                Apply for all items
                              </Form.Label>
                              <Form.Check
                                type="switch"
                                id={`${res.reasonTypeId}_apply_all_items_check`}
                                className={`mb-0 mt-1`}
                                role="button"
                                checked={res.applicableForAllItems}
                                onChange={(e) =>
                                  onToggleAllItems(
                                    e.target.checked,
                                    res.reasonTypeId,
                                  )
                                }
                              />
                            </div>
                          </div>

                          {res.applicableForAllItems ? (
                            <div>
                              <ConditionGroup
                                key={res.reasonTypeId + "other_condition_group"}
                                title=""
                                initialShow={true}
                                disableHide={true}
                              >
                                <div
                                  className={`d-flex align-items-center ${styles.allSelect}`}
                                >
                                  All Items
                                </div>
                              </ConditionGroup>
                            </div>
                          ) : (
                            <div className={``}>
                              {res.conditions.filter((condition) =>
                                itemOptions.includes(condition.variableName),
                              ).length === 0 ? (
                                <ConditionGroup
                                  key={res.reasonTypeId + "other_condition"}
                                  title=""
                                  initialShow={true}
                                  disableHide={true}
                                  errorMessage={res.itemError}
                                >
                                  <VariableSelect
                                    id={res.reasonTypeId}
                                    condition={{} as any}
                                    variablesName={itemOptions}
                                    pushCurrentHash={
                                      dispPushCurrentHashForSelectedTab
                                    }
                                    updateConditon={(id: any, e: any) => {
                                      dispAddCondition(id, true, e);
                                    }}
                                    selectedVariableName={""}
                                    showPlaceholder={true}
                                    errorMessage={res.itemError}
                                  />
                                </ConditionGroup>
                              ) : null}
                              {res.conditions
                                .filter((condition) =>
                                  itemOptions.includes(condition.variableName),
                                )
                                .map((condition: any, idx) => {
                                  return (
                                    <Condition
                                      key={res.reasonTypeId}
                                      id={res.reasonTypeId}
                                      condition={condition}
                                      deleteCondition={(
                                        conId: string,
                                        id: string,
                                      ) => {
                                        dispDeleteCondition(conId, id, true);
                                      }}
                                      idx={idx}
                                      validateCondition={dispValidateCondition}
                                    >
                                      <VariableSelect
                                        id={res.reasonTypeId}
                                        condition={condition}
                                        variablesName={itemOptions}
                                        pushCurrentHash={
                                          dispPushCurrentHashForSelectedTab
                                        }
                                        updateConditon={
                                          dispUpdateConditionForVariables
                                        }
                                        selectedVariableName={
                                          condition.variableName
                                        }
                                      />
                                      <OperatorSelect
                                        condition={condition}
                                        id={res.reasonTypeId}
                                        pushCurrentHash={
                                          dispPushCurrentHashForSelectedTab
                                        }
                                        updateConditon={
                                          dispUpdateConditonForOperator
                                        }
                                        operatorNames={
                                          operators[condition.variableName]
                                        }
                                        selectedOperator={condition.operator}
                                      />

                                      <Values
                                        id={res.reasonTypeId}
                                        condition={condition}
                                        updateCondition={
                                          dispUpdateConditionForValues
                                        }
                                        pushCurrentHash={
                                          dispPushCurrentHashForSelectedTab
                                        }
                                        useNewComponent={true}
                                      />
                                    </Condition>
                                  );
                                })}
                              <div className="mb-lg-2">
                                <AddConditionButton
                                  AddCondition={(id: string) =>
                                    dispAddCondition(id, true)
                                  }
                                  id={res.reasonTypeId}
                                  isAddBtnEnabled={isAddItemBtnEnabled}
                                />
                              </div>
                            </div>
                          )}
                        </ConditionGroup>
                      </>
                    )}
                    {/* 02 Jan Added these options as per new xd */}
                    <div className="mt-2">
                      <ConditionGroup
                        title="Would you like your customers to explain why they are returning their order?"
                        errorMessage={res.explainWhyTheyReturnError}
                      >
                        <>
                          <div
                            className={`d-flex align-items-center mb-2 mt-2`}
                          >
                            <div className={`form-check ${styles.formCheck}`}>
                              <input
                                className="form-check-input"
                                type="radio"
                                name="reasonOption1"
                                id="reasonOption1"
                                style={{
                                  fontSize: "13px",
                                  marginTop: "6px",
                                  cursor: "pointer",
                                }}
                                checked={
                                  res.explainWhyTheyReturn === "notRequired"
                                }
                                onChange={() => {}}
                                onClick={(e) => {
                                  dispatch(
                                    updateReasonOption({
                                      reasonTypeId: res.reasonTypeId,
                                      value: "notRequired",
                                    }),
                                  );
                                  // dispatch(validateChanges()); //Added to show save button on change
                                }}
                              />
                              <label
                                className={`${styles.orderQuestions}`}
                                htmlFor={`reasonOption1`}
                              >
                                No, let them process the return without asking
                                them why.
                              </label>
                            </div>
                          </div>

                          <div className={`d-flex align-items-center mb-2`}>
                            <div className={`form-check ${styles.formCheck}`}>
                              <input
                                className="form-check-input"
                                type="radio"
                                name="reasonOption2"
                                id="reasonOption2"
                                style={{
                                  fontSize: "13px",
                                  marginTop: "6px",
                                  cursor: "pointer",
                                }}
                                checked={
                                  res.explainWhyTheyReturn === "required"
                                }
                                onChange={() => {}}
                                onClick={(e) => {
                                  dispatch(
                                    updateReasonOption({
                                      reasonTypeId: res.reasonTypeId,
                                      value: "required",
                                    }),
                                  );
                                  // dispatch(validateChanges()); //Added to show save button on change
                                }}
                              />
                              <label
                                className={`${styles.orderQuestions} mb-0`}
                                htmlFor={`reasonOption2`}
                              >
                                They can simply select from a list of return
                                reasons that I provide. That is all that I need.
                              </label>
                            </div>
                          </div>

                          <div className={`d-flex align-items-center mb-2`}>
                            <div className={`form-check ${styles.formCheck}`}>
                              <input
                                className="form-check-input"
                                type="radio"
                                name="reasonOption3"
                                id="reasonOption3"
                                style={{
                                  fontSize: "13px",
                                  marginTop: "6px",
                                  cursor: "pointer",
                                }}
                                checked={
                                  res.explainWhyTheyReturn ===
                                  "requiredAndCustom"
                                }
                                onChange={() => {}}
                                onClick={(e) => {
                                  dispatch(
                                    updateReasonOption({
                                      reasonTypeId: res.reasonTypeId,
                                      value: "requiredAndCustom",
                                    }),
                                  );
                                  // dispatch(validateChanges()); //Added to show save button on change
                                }}
                              />
                              <label
                                className={`${styles.orderQuestions} mb-0`}
                                htmlFor={`reasonOption3`}
                              >
                                They can select from a list of return reasons
                                that I provide and based on the reason, I might
                                need more details, for example I might ask for
                                pictures or might want to share some information
                                with them which can prevent the return.
                              </label>
                            </div>
                          </div>
                        </>
                      </ConditionGroup>
                    </div>
                    {/* Select Return Reasons */}
                    {/* Reasons will be displayed only when explainWhyTheyReturn value is required or requiredAndCustom */}
                    {(res.explainWhyTheyReturn === "requiredAndCustom" ||
                      res.explainWhyTheyReturn === "required") && (
                      <div className="mt-4">
                        <ConditionGroup
                          title="Add Return Reasons To Be Displayed To The Customer:"
                          errorMessage={res.reasonError}
                        >
                          <DragDropContext
                            onDragEnd={(result) => {
                              if (!result.destination) {
                                return;
                              }
                              dispatch(
                                reorder({
                                  startIndex: result.source.index,
                                  endIndex: result.destination.index,
                                  reasonTypeId: res.reasonTypeId,
                                }),
                              );
                            }}
                          >
                            <div className="mt-2">
                              <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                  <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                  >
                                    {res.reasons.map((re, idx) => {
                                      return (
                                        <Draggable
                                          key={re.id}
                                          draggableId={re.id}
                                          index={idx}
                                        >
                                          {(provided, snapshot) => (
                                            <div
                                              className="d-flex align-items-center mb-2"
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                            >
                                              <div>
                                                <DraggableInput
                                                  res={re}
                                                  resTypeId={res.reasonTypeId}
                                                  pushCurrentHash={
                                                    dispPushCurrentHashForSelectedTab
                                                  }
                                                />
                                              </div>
                                              {/* Followup questions will be displayed only when explainWhyTheyReturn value is requiredAndCustom */}
                                              {res.explainWhyTheyReturn ===
                                                "requiredAndCustom" && (
                                                <AskQuestion
                                                  reason={re}
                                                  reasonTypeId={
                                                    res.reasonTypeId
                                                  }
                                                  pushCurrentHash={
                                                    //Added prop to show save button when morequestions changes
                                                    dispPushCurrentHashForSelectedTab
                                                  }
                                                  setMoreQuestionModalHandler={(
                                                    moreQuestions: ReasonFollowUpQuestion[],
                                                    reasonId: string,
                                                    reasonTypeId: string,
                                                  ) => {
                                                    dispatch(
                                                      setMoreQuestionModal({
                                                        moreQuestionsModal:
                                                          moreQuestions,
                                                        moreQuestionsModalReasonId:
                                                          reasonId,
                                                        moreQuestionsModalReasonTypeId:
                                                          reasonTypeId,
                                                      }),
                                                    );
                                                  }}
                                                  saveMoreQuestionHandler={(
                                                    reasonId: string,
                                                    reasonTypeId: string,
                                                  ): void => {
                                                    dispatch(
                                                      saveMoreQuestion({
                                                        reasonId: reasonId,
                                                        reasonTypeId,
                                                      }),
                                                    );
                                                  }}
                                                  reorderMoreQuestionHandler={(
                                                    endIndex: number,
                                                    startIndex: number,
                                                  ): void => {
                                                    dispatch(
                                                      reorderMoreQuestion({
                                                        endIndex: endIndex,
                                                        startIndex: startIndex,
                                                      }),
                                                    );
                                                  }}
                                                  removeMoreQuestionHandler={(
                                                    moreQuestionId: string,
                                                  ): void => {
                                                    dispatch(
                                                      removeMoreQuestion({
                                                        moreQuestionId:
                                                          moreQuestionId,
                                                      }),
                                                    );
                                                  }}
                                                  updateMoreQuestionTypeHandler={(
                                                    questionId: string,
                                                    type: string,
                                                    isAdditionalOptions:
                                                      | boolean
                                                      | undefined,
                                                  ): void => {
                                                    dispatch(
                                                      updateMoreQuestionType({
                                                        questionId: questionId,
                                                        type: type,
                                                        isAdditionalOptions:
                                                          isAdditionalOptions,
                                                      }),
                                                    );
                                                  }}
                                                  updateMoreQuestionValueHandler={(
                                                    questionId: string,
                                                    value: string,
                                                  ): void => {
                                                    dispatch(
                                                      updateMoreQuestionValue({
                                                        questionId: questionId,
                                                        value: value,
                                                      }),
                                                    );
                                                  }}
                                                  removeMoreQuestionOptionHandler={(
                                                    questionId: string,
                                                    optionIndex: number,
                                                  ): void => {
                                                    dispatch(
                                                      removeMoreQuestionOption({
                                                        questionId: questionId,
                                                        optionIndex:
                                                          optionIndex,
                                                      }),
                                                    );
                                                  }}
                                                  updateMoreQuestionOptionHandler={(
                                                    option: string,
                                                    questionId: string,
                                                    optionIndex: number,
                                                  ): void => {
                                                    dispatch(
                                                      updateMoreQuestionOption({
                                                        option: option,
                                                        questionId: questionId,
                                                        optionIndex:
                                                          optionIndex,
                                                      }),
                                                    );
                                                  }}
                                                  addMoreQuestionOptionHandler={(
                                                    option: string,
                                                    questionId: string,
                                                  ): void => {
                                                    dispatch(
                                                      addMoreQuestionOption({
                                                        option: option,
                                                        questionId: questionId,
                                                      }),
                                                    );
                                                  }}
                                                  addMoreQuestionHandler={() => {
                                                    dispatch(addMoreQuestion());
                                                  }}
                                                  formElementOptions={
                                                    formElementOptions
                                                  }
                                                  followUpQuestions={
                                                    moreQuestionsModal
                                                  }
                                                />
                                              )}
                                            </div>
                                          )}
                                        </Draggable>
                                      );
                                    })}
                                  </div>
                                )}
                              </Droppable>

                              {/* Add Condition Button  */}
                              <div
                                className={`d-flex align-items-center pt-2 ${
                                  isAddReasonEnabled ? "" : styles.disable
                                }`}
                                role="button"
                                onClick={() => {
                                  if (isAddReasonEnabled) {
                                    dispatch(
                                      addReason({
                                        reasonTypeId: res.reasonTypeId,
                                      }),
                                    );
                                    dispPushCurrentHashForSelectedTab(
                                      res.reasonTypeId,
                                    );
                                  }
                                }}
                              >
                                <div className={`${styles.addBtn}`}>
                                  <span>+</span>
                                </div>
                                <span className={`ps-2 ${styles.addText}`}>
                                  Add more reason
                                </span>
                              </div>
                            </div>
                          </DragDropContext>
                        </ConditionGroup>
                      </div>
                    )}
                  </Tab.Pane>
                );
              })}
            </Tab.Content>
          </Col>
        </Row>
        <DiscardAndSave
          onSave={dispPostConfig}
          showLoading={updateAjaxStatus === "pending"}
          onDiscard={() => {
            if (!returnAutoWorkFlow.integrationId) {
              return;
            }

            dispatch(
              fetchReturnReasonOrderAndItems({
                integrationId: returnAutoWorkFlow.integrationId,
                questionValue: "",
                reasonList: [],
              }),
            );
          }}
        />
      </Tab.Container>
    </div>
  );
};

const ReturnReasonOrderAndItems = ({
  onHide,
  onSave = () => {},
}: {
  onHide: () => void;
  onSave?: () => void;
}) => {
  const dispatch = useAppDispatch();
  const { isChanged, intialStep12Config } = useAppSelector(
    (state) => state.returnReasonOrderAndItems,
  );

  const [isValidating, setIsValidating] = useState(false);
  const [showDiscardModel, setShowDiscardModal] = useState(false);

  useEffect(() => {
    if (isValidating) {
      if (isChanged) {
        setShowDiscardModal(true);
      } else {
        onHide();
      }
      setIsValidating(false);
    }
  }, [isChanged, isValidating, onHide]);

  const onClose = useCallback(() => {
    dispatch(validateChanges());
    setIsValidating(true);
  }, [dispatch]);

  return (
    <div>
      <SkeletonModalStructure
        hasTwoSection={false}
        heading="Configure return reasons for orders and items"
        closeFunction={onClose}
      >
        <Component onSave={onSave} />
      </SkeletonModalStructure>
      <Modal
        className={`shadow-sm p-3 rounded`}
        show={showDiscardModel}
        onHide={() => setShowDiscardModal(false)}
        dialogClassName={`${styles.modalDialog2}`}
        contentClassName={`${styles.modalContent2}`}
        backdropClassName={`${styles.modalBackDrop}`}
        enforceFocus={false}
        keyboard={false}
      >
        <DiscardModal
          setShow={setShowDiscardModal}
          closeMainModel={onHide}
          onConfirm={() => {
            dispatch(
              undoConfig({
                initialConfig: {
                  returnTypes: intialStep12Config.returnTypes,
                },
              }),
            );
          }}
        />
      </Modal>
    </div>
  );
};

export default ReturnReasonOrderAndItems;
