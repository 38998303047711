import { useInfiniteQuery } from "@tanstack/react-query";
import { useRef } from "react";
import {
  GetAllTemplatesPayload,
  GetAllTemplatesResponse,
  getAllTemplatesLiveChat,
} from "src/services/LiveChat/ScheduleFollowups/getAllTemplates";

type Props = {
  payload: GetAllTemplatesPayload;
  // scheduleMessageFor?: "innerTicket" | "liveChat";
};

export const useGetAllTemplates = ({ payload}: Props) => {
  const currentStartPositionRef = useRef(null as null | number);
  // let queryKey;
  // let queryFn;

  // if (scheduleMessageFor === "innerTicket") {
  //   queryKey = ["getAllTemplatesInnerTicker", payload];
  //   queryFn = ({pageParam = payload}) => {
  //     return getAllTemplatesLiveChat(pageParam);
  //   };
  // }
  // else {
  //   queryKey = ["getAllTemplatesLiveChat", payload];
  //   queryFn = ({pageParam = payload}) => {
  //     return getAllTemplatesLiveChat(pageParam);
  //   };
  // }

  const queryFn = ({ pageParam = payload }) => {
    return getAllTemplatesLiveChat(pageParam);
  };

  const {
    data,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isError,
    fetchStatus,
    status,
  } = useInfiniteQuery({
    queryKey: ["getAllTemplates", payload],
    queryFn: queryFn,
    getNextPageParam: (
      prevPage: GetAllTemplatesResponse,
      allPages: GetAllTemplatesResponse[]
    ) => {
      const data = allPages.flatMap((page) => page.data.map((d) => d));

      const theParam = {
        ...payload,
        start: data.length + (currentStartPositionRef.current ?? 0),
      };

      if (
        data.length + (currentStartPositionRef.current ?? 0) <
        prevPage.metaData.totalCount
      ) {
        return theParam;
      }
    },
  });
  return {
    data,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isError,
    fetchStatus,
    status,
  };
};
