import React, { useEffect, useState } from "react";
import styles from "./CommentSetting.module.scss";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import useComments from "../../useComments";
import useRating from "../../../Rating/useRating";
import { useParams } from "react-router-dom";
import Loader from "src/components/Loader";
import { useCommentSetting } from "./useCommentSetting";
import arrowRight from "src/assets/images/arrowRight.png";
type Props = {
  enableComments: boolean;
  setEnableComments: (value: boolean) => void;
  reqApproval: boolean;
  setReqApproval: (value: boolean) => void;
  approvedComment: boolean;
  unApprovedComment: boolean;
  setApprovedComment: Function;
  setUnapprovedComment: Function;
};

const CommentSetting = ({
  enableComments,
  reqApproval,
  setEnableComments,
  setReqApproval,
  approvedComment,
  unApprovedComment,
  setApprovedComment,
  setUnapprovedComment,
}: Props) => {
  const {
    dropdown,
    setDropDown,
    selectedRadio,
    handleRadioChange1,
    handleRadioChange2,
    handleCommentChange,
    handleRequireApprovalChange,
    updateRatingAndComments,
  } = useCommentSetting({
    enableComments,
    reqApproval,
    setEnableComments,
    setReqApproval,
    approvedComment,
    unApprovedComment,
    setApprovedComment,
    setUnapprovedComment,
  });

  const { getRatingsAndCommentData } = useRating();

  useEffect(() => {
    getRatingsAndCommentData.refetch();
  }, []);

  if (
    getRatingsAndCommentData.isLoading ||
    getRatingsAndCommentData.isRefetching
  ) {
    return (
      <div className={`p-2 ${styles.settingWrapper}`}>
        <Loader />
      </div>
    );
  }

  return (
    <div className={`p-2 ${styles.settingWrapper}`}>
      <p className={`${styles.heading} saufter_h4`}>
        Comments And Questions Settings
      </p>

      {/* Dropdown Header */}
      <div
        className={`d-flex justify-content-between align-items-center px-2 saufter_medium_h4 ${
          styles.dropBtn
        }  ${dropdown && styles.borderSet}`}
        onClick={() => setDropDown(!dropdown)}
      >
        <span>Comments and Questions</span>
        <span className={`${styles.arrow}`}>
          {dropdown ? (
            <img src={arrowRight} alt="" className={`${styles.arrowDown}`} />
          ) : (
            <img src={arrowRight} alt="" />
          )}
        </span>
      </div>

      {/* Dropdown Body */}
      {dropdown ? (
        <div className={`p-2 p-md-3 mb-2 ${styles.dropDown}`}>
          {/* Enable Comment Input  */}
          <div className="d-flex justify-content-between align-items-center mb-3">
            <p className={`mb-0 ${styles.checkInput} saufter_h5`}>
              Enable comments?
            </p>
            <div className="form-check form-switch">
              <input
                className={`form-check-input ${styles.check}`}
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
                checked={enableComments}
                onChange={handleCommentChange}
              />
            </div>
          </div>

          {/* Require Approval Input  */}
          <div className="d-flex justify-content-between align-items-center">
            <p className={`mb-0 ${styles.checkInput} saufter_h5`}>
              Require comment approval before showing?
            </p>
            {enableComments ? (
              <div className="form-check form-switch">
                <input
                  className={`form-check-input ${styles.check}`}
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckDefault"
                  disabled={!enableComments}
                  checked={reqApproval}
                  onChange={handleRequireApprovalChange}
                />
              </div>
            ) : (
              <OverlayTrigger
                placement="bottom-end"
                trigger="hover"
                overlay={
                  <Tooltip id="tooltip-disabled">
                    You need to turn on the Enable comments to Configure this
                  </Tooltip>
                }
              >
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckDefault"
                    disabled={!enableComments}
                    checked={reqApproval}
                    onChange={(e) => setReqApproval(e.target.checked)}
                  />
                </div>
              </OverlayTrigger>
            )}
          </div>
          {/* TickBoxes */}
          {/* If require approval is enabled, then below options will be available */}
          {reqApproval ? (
            <div className="ps-3 mt-3">
              {/* Unapproved Comments Input */}
              <div className={`form-check`} onClick={handleRadioChange1}>
                <span className={`${styles.radioTick}`}>
                  <i
                    className={`form-check-input ${styles.formIcon} ${
                      selectedRadio === "exampleRadios1" ? "fas" : "far"
                    } ${
                      selectedRadio === "exampleRadios1"
                        ? "fa-check-circle"
                        : "fa-circle"
                    }`}
                    role="radio"
                    aria-checked={selectedRadio === "exampleRadios1"}
                  ></i>
                </span>
                <label
                  className={`form-check-label ${styles.formLabel} saufter_h5 `}
                  onClick={() => handleRadioChange1()}
                >
                  Unapproved comments
                </label>
              </div>

              {/* Approved Comments Input */}
              <div className={`form-check mt-1 `} onClick={handleRadioChange2}>
                <span className={`${styles.radioTick}`}>
                  <i
                    className={`form-check-input ${styles.formIcon} ${
                      selectedRadio === "exampleRadios2" ? "fas" : "far"
                    } ${
                      selectedRadio === "exampleRadios2"
                        ? "fa-check-circle"
                        : "fa-circle"
                    }`}
                    role="radio"
                    aria-checked={selectedRadio === "exampleRadios2"}
                  ></i>
                </span>
                <label
                  className={`form-check-label ${styles.formLabel} saufter_h5 `}
                  onClick={() => handleRadioChange2()}
                >
                  Approved comments
                </label>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default CommentSetting;
