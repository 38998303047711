import { Channel } from "pusher-js";

export interface VerifyEmailEvent {
  emailIntegrationId: string | number;
  verifiedSuccessfully: boolean;
}

export const verifiedEmailEvent = (
  channel: Channel,
  callback: (res: VerifyEmailEvent) => void
) => {
  channel.bind("email_forwarding_verified", callback);
};
