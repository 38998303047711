import { createAsyncThunk } from "@reduxjs/toolkit";
import getReturnReasonOrderAndItemsService, {
  ReturnReasonOrderAndItemsTab,
} from "src/services/ReturnAutoWorkFlow/OldModals/returnReasonOrderAndItems/getReturnReasonOrderAndItems.service";
import getReturnReasonOrderAndItemsConditionsService from "src/services/ReturnAutoWorkFlow/OldModals/returnReasonOrderAndItems/getReturnReasonOrderAndItemsConditions.service";
import getReturnReasonOrderAndItemsFormElementOptions from "src/services/ReturnAutoWorkFlow/OldModals/returnReasonOrderAndItems/getReturnReasonOrderAndItemsFormElementOptions.service";
import updateReturnReasonOrderAndItemsService from "src/services/ReturnAutoWorkFlow/OldModals/returnReasonOrderAndItems/updateReturnReasonOrderAndItems.service";
import { ConditionTypes } from "src/features/ReturnAutoWorkFlow/children/ConfigModals/store/returnModalsSliceHelpers";
import { IReturnReason } from "src/store/slices/initiateReturn/retrunExchange.slice";
import { getValueFromConditionType } from "src/features/ReturnAutoWorkFlow/children/ConfigModals/store/returnModalsSliceHelpers";
import { RootState } from "src/store/store";
import { SelectedConditionType } from "../../../oldReturnModals.types";
import { ReturnModalsThunkPayload } from "../../returnModalsStore";
import { validateReturnReasonOrderAndItems } from "./returnReasonOrderAndItems.slice";

interface UpdateReturnReasonOrderAndItems extends ReturnModalsThunkPayload {
  integrationId: string;
  isSelectReasonEnabled: boolean;
}

export const updateReturnReasonOrderAndItems = createAsyncThunk(
  "updateReturnReasonOrderAndItems",
  async (payload: UpdateReturnReasonOrderAndItems, { getState }) => {
    const state = getState() as RootState;

    const integrationId = payload.integrationId;
    const reasonTypes = state.returnReasonOrderAndItems.reasonTypes;

    const configTabs = reasonTypes.map((reasonType) => {
      const operatorSymbolMap =
        state.returnReasonOrderAndItems.operatorSymbolMap;
      let reasonsPayload: any = [];
      // 03 Jan If explainWhyTheyReturn id "requiredAndCustom" or "required",then only include reasons in payload
      if (
        reasonType.explainWhyTheyReturn === "requiredAndCustom" ||
        reasonType.explainWhyTheyReturn === "required"
      ) {
        reasonsPayload = reasonType.reasons.map((reason) => {
          const newReason = {
            id: reason.isNew ? null : reason.id,
            value: reason.reason.value,
            moreQuestions: reason.reason.moreQuestions.map((question) => {
              const newQuestion = {
                elementTypeId: question.elementTypeId,
                elementId: question.isNewElement ? null : question.elementId,
                additionalOptions: question.additionalOptions.map((option) => {
                  return {
                    id: option.isNew ? null : option.id,
                    value: option.value,
                  };
                }),
                elementValue: question.elementValue,
              };
              return newQuestion;
            }),
          };

          return {
            ...newReason,
            moreQuestions:
              reasonType.explainWhyTheyReturn === "requiredAndCustom"
                ? newReason.moreQuestions
                : [], // 03 Jan If explainWhyTheyReturn id "requiredAndCustom",then only include morequestions in payload
          };
        });
      }

      return {
        configTabId: Number(reasonType.reasonTypeId),
        configTabName: reasonType.reasonTypeName,
        applicableForAllItems: reasonType.applicableForAllItems,
        applicableForAllOrders: reasonType.applicableForAllOrders,
        conditions: reasonType.conditions.map((condition) => {
          const selectedVariable =
            state.returnReasonOrderAndItems.variableNames.find(
              (variable: any) => variable.name === condition.variableName,
            );

          if (selectedVariable) {
            return {
              conditionId: Number(condition.id),
              conditionTypeId: selectedVariable.id,

              conditionValue: getValueFromConditionType(condition.values),
              operator: Object.keys(operatorSymbolMap).find((key) => {
                if (
                  (condition.operator === "are less than" ||
                    condition.operator === "is less than") &&
                  key === "<"
                ) {
                  return true;
                }
                return operatorSymbolMap[key] === condition.operator;
              }),
            } as SelectedConditionType;
          } else {
            return {
              conditionId: Number(condition.id),
              conditionTypeId: -1,
            };
          }
        }),
        reasons: reasonsPayload,
        explainWhyTheyReturn: reasonType.explainWhyTheyReturn ?? null, //03 Jan Adding "explainWhyTheyReturn" property as needed as per new xd
      } as ReturnReasonOrderAndItemsTab;
    });

    await updateReturnReasonOrderAndItemsService({ integrationId, configTabs });
    setTimeout(() => {
      if (payload.callback) {
        payload.callback();
      }
    }, 0);

    // Refetch the data after updating it
    const configRes = await getReturnReasonOrderAndItemsService({
      integrationId,
    });

    return {
      configRes,
    };
  },
);

interface FetchReturnReasonOrderAndItemsPayload {
  integrationId: string;
  questionValue: string;
  reasonList: IReturnReason[];
}

export const fetchReturnReasonOrderAndItems = createAsyncThunk(
  "fetchReturnReasonOrderAndItems",
  async (payload: FetchReturnReasonOrderAndItemsPayload) => {
    const integrationId = payload.integrationId;
    const reasonList = payload.reasonList;

    const defaultConditionOrderDate = {
      id: "",
      ruleType: "AND",
      variableName: "Order Date",
      values: payload.questionValue,
      operator: "within",
    } as ConditionTypes;

    const [conditionOptionsRes, configRes, formElementOptions] =
      await Promise.all([
        getReturnReasonOrderAndItemsConditionsService({ integrationId }),
        getReturnReasonOrderAndItemsService({ integrationId }),
        getReturnReasonOrderAndItemsFormElementOptions({ integrationId }),
      ]);

    return {
      conditionOptionsRes,
      configRes,
      defaultConditionOrderDate,
      defaultConditionReasonsList: reasonList,
      formElementOptions,
    };
  },
);

export const validateReturnReasonOrderAndItemsThunk = createAsyncThunk(
  "validateReturnReasonOrderAndItems",
  async (_: void, { getState, dispatch }) => {
    await dispatch(validateReturnReasonOrderAndItems());

    const state = getState() as RootState;

    let errorCount = 0;
    state.returnReasonOrderAndItems.reasonTypes.forEach((item) => {
      if (item.errorCount !== 0) {
        errorCount += 1;
      }
    });

    return errorCount;
  },
);
