import {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  FacebookPageInfo,
  WebhookInfo,
  getFacebookPageWebhooks,
} from "src/services/Integrations/getFacebookPageWebhooks";
import { updateFacebookPageWebhook } from "src/services/Integrations/updateFacebookPageWebhook";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
var objectHash = require("object-hash");

// Define your initial state
const initialState: FacebookPageInfo = {
  id: "",
  name: "",
  category: "",
  integrated: false,
  imageUrl: null,
  webhooks: [],
};

enum ActionTypes {
  SET_PAGE_INFO = "SET_PAGE_INFO",
  SET_WEBHOOKS = "SET_WEBHOOKS",
  UPDATE_WEBHOOK = "UPDATE_WEBHOOK",
}

// Define action types
interface SetWebhooksAction {
  type: ActionTypes.SET_WEBHOOKS;
  payload: WebhookInfo[];
}

interface UpdateWebhookAction {
  type: ActionTypes.UPDATE_WEBHOOK;
  payload: {
    key: string;
    updatedWebhook: Partial<WebhookInfo>;
  };
}

interface SetPageInfoAction {
  type: ActionTypes.SET_PAGE_INFO;
  payload: FacebookPageInfo;
}

// Combine all possible action types
type FacebookPageAction =
  | SetPageInfoAction
  | SetWebhooksAction
  | UpdateWebhookAction;

// Update the reducer function with the new action types
const facebookPageReducer = (
  state: FacebookPageInfo,
  action: FacebookPageAction,
) => {
  switch (action.type) {
    case ActionTypes.SET_PAGE_INFO:
      return { ...state, ...action.payload };
    case ActionTypes.SET_WEBHOOKS:
      return { ...state, webhooks: action.payload };
    case ActionTypes.UPDATE_WEBHOOK:
      // Find the index of the webhook to update
      const updatedWebhooks = state.webhooks.map((webhook) =>
        webhook.key === action.payload.key
          ? { ...webhook, ...action.payload.updatedWebhook }
          : webhook,
      );
      return { ...state, webhooks: updatedWebhooks };
    default:
      return state;
  }
};

interface Props {
  pageId: number | string;
  currentPageData: any;
  setCurrentPageData: (value: any) => void;
}

export const useFacebookPageInfo = ({
  pageId,
  currentPageData,
  setCurrentPageData,
}: Props) => {
  const [isConnected, setIsConnected] = useState(currentPageData.integrated);
  const [showToggleError, setShowToggleError] = useState(false);
  const [state, dispatch] = useReducer(facebookPageReducer, initialState);
  const [hasDataChanged, setHasDataChanged] = useState(false);
  const queryClient = useQueryClient();

  //query to fetch queued chats
  const { isLoading, error, data, refetch } = useQuery(
    ["Facebook/getPageWebhooks", pageId],
    async () => {
      const response = await getFacebookPageWebhooks({ pageId: pageId });
      return response;
    },
    {
      onSuccess: (data) => {
        dispatch({ type: ActionTypes.SET_PAGE_INFO, payload: data });
      },
    },
  );

  const updateWebhook = (key: string, updatedWebhook: Partial<WebhookInfo>) => {
    dispatch({
      type: ActionTypes.UPDATE_WEBHOOK,
      payload: {
        key,
        updatedWebhook,
      },
    });
  };

  const updatePageInfoMutation = useMutation({
    mutationFn: updateFacebookPageWebhook,
    onSuccess: (data, variables, context) => {
      // console.log("Success: successfully updated");
      queryClient.setQueryData(["Facebook/getPageWebhooks", pageId], state);
      let currentData = { ...currentPageData };
      currentData.integrated = isConnected;
      setCurrentPageData(currentData);
      pushTheToast({
        type: "success",
        text: "Saved Changes!",
        position: "top-right",
      });
    },
    onError: (error, variables, context) => {
      // console.log("Error: failed to updated column");
      pushTheToast({
        type: "danger",
        text: "Failed to save!",
        position: "top-right",
      });
    },
  });

  const handleSaveChanges = () => {
    const allWebhookDisabled =
      state.webhooks.length === 0 ||
      state.webhooks.filter((webhookInfo) => webhookInfo.enabled).length === 0;

    updatePageInfoMutation.mutate({
      pageId: pageId,
      webhooks: state.webhooks.map((webhookInfo) => {
        return {
          enabled: webhookInfo.enabled,
          key: webhookInfo.key,
        };
      }),
    });
  };

  useEffect(() => {
    if (data && state) {
      // Compare the hashes to determine if there have been changes in the form elements.
      if (
        objectHash(data) != objectHash(state) ||
        currentPageData.integrated !== isConnected
      ) {
        // If there are changes, set the 'saveButtonText' to "Save" and set 'hasDataChanged' to 'true'.
        setHasDataChanged(true);
      } else {
        setHasDataChanged(false);
      }
    }
  }, [currentPageData, data, state, isConnected]);

  const isSaveLoading = updatePageInfoMutation.isLoading;
  const allWebhookDisabled = useMemo(() => {
    let disabled =
      state.webhooks.length === 0 ||
      state.webhooks.filter((webhookInfo) => webhookInfo.enabled).length === 0;

    if (disabled) {
      setIsConnected(false);
    } else {
      setShowToggleError(false);
    }
    return disabled;
  }, [state.webhooks]);

  return {
    isLoading,
    error,
    data: state,
    refetch,
    updateWebhook,
    handleSaveChanges,
    hasDataChanged,
    isSaveLoading,
    allWebhookDisabled,
    isConnected,
    setIsConnected,
    showToggleError,
    setShowToggleError,
  };
};
