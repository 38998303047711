import { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";

interface CustomStorageDataI {
  [name: string]: string;
}

const setDataObj: any = {};

class CustomStorage {
  private data: CustomStorageDataI = {};
  constructor(data?: CustomStorage | string) {
    if (data !== undefined) {
      if (typeof data === "string") {
        // const str = new URLSearchParams(data);
        // const newData: CustomStorageDataI = {};
        // for (const [key, value] of str.entries()) {
        //   newData[key] = value;
        // }
        try {
          this.data = JSON.parse(data);
        } catch {
          this.data = {};
        }
      } else {
        this.data = data.data;
      }
      sessionStorage.setItem(
        "HELPDESK_CustomeStoreData",
        JSON.stringify(this.data)
      );
    } else {
      const theSD = sessionStorage.getItem("HELPDESK_CustomeStoreData");
      this.data = theSD?.trim() ? JSON.parse(theSD) : {};
    }
  }
  getStringifiedData() {
    if (Object.keys(this.data).length > 0) {
      return JSON.stringify(this.data);
    } else {
      return "";
    }
  }
  set(name: string, value: string) {
    this.data[name] = value;
  }
  get(name: string) {
    if (this.data[name] !== undefined) {
      return this.data[name];
    } else {
      return null;
    }
  }
  delete = (name: string) => {
    delete this.data[name];
  };
}

const updateDataEveryWhere = (data: CustomStorage | string) => {
  for (const x in setDataObj) {
    const setDataFun = setDataObj[x];
    setDataFun(new CustomStorage(data));
  }
};

const useSearchParamsStorage = (): [
  CustomStorage,
  (data: CustomStorage | string) => void
] => {
  const [data, setData] = useState(new CustomStorage());
  useEffect(() => {
    const key = uuid();
    setDataObj[key] = setData;
    return () => {
      delete setDataObj[key];
    };
  }, [setData]);
  return [data, updateDataEveryWhere];
};

export default useSearchParamsStorage;
