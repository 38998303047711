import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { sendWidgetScriptEmail } from "src/store/slices/liveChatSetting/websiteSettings/websiteSettings.slice";
import { useAppDispatch } from "src/store/store";
import { isEmailsValid } from "src/utils/utils";


interface Props {
  toEmailAddress: string;
  integrationId?: string;
  onSendEmailSuccessCallback?: Function;
}

export const useWidgetScriptSendEmail = ({
  toEmailAddress,
  integrationId,
  onSendEmailSuccessCallback
}: Props) => {


  //useRef hook to prevent stale state
  const currentState = useRef({
    toEmailAddress,
    integrationId,
  });

  useEffect(() => {
    currentState.current = {
      toEmailAddress,
      integrationId,
    };

  }, [toEmailAddress, integrationId]);
  
  const dispatch = useAppDispatch();

  const validateEmailAddresses = useCallback(() => {
    //empty checking email input
    if (currentState.current.toEmailAddress.trim() === "") {
      return "Please enter a valid email";
    }
    //validating all the emails
    if (!isEmailsValid(currentState.current.toEmailAddress.split(","))) {
      return "Please enter a valid email";
    }
    let allEmails = currentState.current.toEmailAddress.split(",");
    let duplicateExists: any = false;
    let checkedEmails: string[] = []; //variable to store checked emails temporarily
    //checking for duplicate emails
    allEmails.forEach((email) => {
      if (checkedEmails.includes(email.trim())) {
        duplicateExists = true;
        return false;
      }
      checkedEmails.push(email.trim());
    });

    if (duplicateExists === true) {
      return "Same email has been given multiple times, please enter unqiue emails";
    }

    return "";
  }, []);

  //function to call send email api
  const sendEmail = useCallback((message:string) => {
    //validate payload
    let error = validateEmailAddresses();

    if (error !== "") {
      pushTheToast({
        type: "warning",
        text: error,
        position: "top-right",
      });
      return;
    }

    //preparing the payload
    dispatch(
      sendWidgetScriptEmail({
        integrationId: parseInt(currentState.current.integrationId+""),
        message: message,
        toEmails: currentState.current.toEmailAddress,
        callback: ()=>{
          if(onSendEmailSuccessCallback){
            onSendEmailSuccessCallback();
          }
          
        }
      })
    );
  }, []);

  return {
    sendEmail,
    validateEmailAddresses
  };
};
