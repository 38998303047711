import { useCallback, useEffect, useMemo, useState } from "react";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { useReturnAutoWorkFlow } from "src/features/ReturnAutoWorkFlow/hooks/useReturnAutoWorkFlow";
import {
  ConditionTypes,
  IVariableName,
  conditionValues,
  operatorNames,
} from "src/features/ReturnAutoWorkFlow/children/ConfigModals/store/returnModalsSliceHelpers";
import {
  addCondition,
  addReturnType,
  deleteCondition,
  pushCurrentHashForSelectedTab,
  returnTypeBasedOnOrderOrItemsErrorCountSelector,
  updateCondition,
  updateReturnType,
  validateChanges,
  validateCondition,
  validateReturnType,
} from "../../store/slices/ReturnTypeBasedOnOrderOrItems/returnTypeBasedOnOrderOrItems.slice";

import {
  fetchReturnTypeBasedOnOrderOrItemsThunk,
  updateReturnTypeBasedOnOrderOrItemsThunk,
  validateReturnTypeBasedOnOrderOrItems,
} from "../../store/slices/ReturnTypeBasedOnOrderOrItems/returnTypeBasedOnOrderOrItems.thunk";
import { useAppDispatch, useAppSelector } from "src/store/store";

const useReturnTypeBasedOnOrderOrItemsModal = ({
  onSave,
}: {
  onSave: (isSaved: boolean) => void;
}) => {
  const dispatch = useAppDispatch();
  const { returnAutoWorkFlow } = useReturnAutoWorkFlow();

  const {
    fetchAjaxStatus,
    updateAjaxStatus,
    initialHashOtherTab,
    currentHashOtherTab,
    returnTypes,
    selectedReturnTypeId,
    conditionOptions: conditionOptionsRaw,
    defaultOptions: defaultOptionsRaw,
    itemOptions: itemOptionsRaw,
  } = useAppSelector((state) => state.returnTypeBasedOnOrderOrItems);

  const [selectedReturnType, selectedReturnTypeIndex] = useMemo(() => {
    const index = returnTypes.findIndex(
      (returnType) => returnType.returnTypeId === selectedReturnTypeId,
    );
    const returnType = index !== -1 ? returnTypes[index] : undefined;
    return [returnType, index];
  }, [returnTypes, selectedReturnTypeId]);

  const isAddBtnEnabled = useMemo(
    () => selectedReturnType?.isAddConditionEnabled ?? false,
    [selectedReturnType?.isAddConditionEnabled],
  );

  const isAddItemBtnEnabled = useMemo(
    () => selectedReturnType?.isAddItemConditionEnabled ?? false,
    [selectedReturnType?.isAddItemConditionEnabled],
  );

  const itemOptions = useMemo(
    () => Object.values(itemOptionsRaw).map((option) => option.name),
    [itemOptionsRaw],
  );

  const conditionOptions = useMemo(
    () => Object.values(conditionOptionsRaw).map((option) => option.name),
    [conditionOptionsRaw],
  );

  const defaultOptions = useMemo(
    () => Object.values(defaultOptionsRaw).map((option) => option.name),
    [defaultOptionsRaw],
  );

  const [otherTabsHasError, setOtherTabsHasError] = useState(false);

  const otherTabErrorCount = useAppSelector(
    returnTypeBasedOnOrderOrItemsErrorCountSelector,
  );

  useEffect(() => {
    if (otherTabErrorCount <= 0) {
      setOtherTabsHasError(false);
    } else {
      setOtherTabsHasError(true);
    }
  }, [otherTabErrorCount]);

  useEffect(() => {
    if (!returnAutoWorkFlow.integrationId) {
      return;
    }

    // TODO -
    dispatch(
      fetchReturnTypeBasedOnOrderOrItemsThunk({
        integrationId: returnAutoWorkFlow.integrationId,
        defaultResolutionOfferValue: "",
      }),
    );
    if (selectedReturnTypeId) {
      dispatch(validateReturnType({ returnTypeId: selectedReturnTypeId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // all the dispatch function which will be used in this file

  //function to update tab name
  const dispUpdateReturnType = useCallback(
    (id: string, updateObj: any) => {
      dispatch(
        updateReturnType({
          returnTypeDetail: {
            ...returnTypes,
            returnTypeId: id,
            ...updateObj,
          },
        }),
      );
      dispatch(validateChanges());
    },
    [dispatch, returnTypes],
  );

  //function to update the current tab hash string, which is used to identify if the data is changed or not
  const dispPushCurrentHashForSelectedTab = useCallback(
    (id: string) => {
      dispatch(
        pushCurrentHashForSelectedTab({
          returnTypeId: id,
        }),
      );
      dispatch(validateChanges());
    },
    [dispatch],
  );

  //function to add new tab
  const dispAddTab = useCallback(() => {
    dispatch(addReturnType());
    dispatch(validateChanges());
  }, [dispatch]);

  //function to validate condition of passed tab id
  const dispValidateCondition = useCallback(
    (id: string) => {
      dispatch(
        validateCondition({
          returnTypeId: id,
        }),
      );
      dispatch(validateChanges());
    },
    [dispatch],
  );

  //function to update conditionType of a condition
  const dispUpdateConditionForVariables = useCallback(
    (
      id: string,
      e: { target: { value: string } },
      condition: ConditionTypes,
    ) => {
      dispatch(
        updateCondition({
          returnTypeId: id,
          conditionToUpdate: {
            ...condition,
            variableName: e.target.value as IVariableName,
            operator: operatorNames[e.target.value as IVariableName][0],
            values: conditionValues[e.target.value as IVariableName],
          },
        }),
      );
    },
    [dispatch],
  );

  //20 Jan Added to show validation on modal load itself
  useMemo(() => {
    if (selectedReturnTypeId && dispValidateCondition) {
      if (selectedReturnTypeIndex !== -1 && selectedReturnTypeIndex !== 0)
        dispValidateCondition(selectedReturnTypeId);
    }
  }, [dispValidateCondition, selectedReturnTypeId, selectedReturnTypeIndex]);

  //function to update operator of a condition
  const dispUpdateConditonForOperator = useCallback(
    (
      id: string,
      e: { target: { value: string } },
      condition: ConditionTypes,
    ) => {
      dispatch(
        updateCondition({
          returnTypeId: id,
          conditionToUpdate: {
            ...condition,
            operator: e.target.value,
          },
        }),
      );
    },
    [dispatch],
  );

  //function to update condition value by its value type
  const dispUpdateConditionForValues = useCallback(
    (id: string, type: string, value: any, condition: ConditionTypes) => {
      switch (type) {
        case "tags":
          dispatch(
            updateCondition({
              returnTypeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  tags: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;
        case "items":
          dispatch(
            updateCondition({
              returnTypeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  items: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;
        case "day_type":
          dispatch(
            updateCondition({
              returnTypeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  day_type: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;
        case "days":
          dispatch(
            updateCondition({
              returnTypeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  days: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;

        case "codes":
          dispatch(
            updateCondition({
              returnTypeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  codes: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;
        case "numbers":
          dispatch(
            updateCondition({
              returnTypeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  numbers: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;
        case "countryName":
          dispatch(
            updateCondition({
              returnTypeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  countryName: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;
        case "amount":
          dispatch(
            updateCondition({
              returnTypeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  amount: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;
        case "currency":
          dispatch(
            updateCondition({
              returnTypeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  currency: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;

        default:
          break;
      }
      dispatch(validateChanges());
    },
    [dispatch],
  );

  //function to delete a condition by its id
  const dispDeleteCondition = useCallback(
    (conId: string, id: string, isItem?: boolean) => {
      dispatch(
        deleteCondition({
          conditionId: conId,
          returnTypeId: id,
          isItemCondition: isItem,
        }),
      );
    },
    [dispatch],
  );

  const dispValidateReturnType = useCallback(
    (id: string) => {
      dispatch(
        validateReturnType({
          returnTypeId: id,
        }),
      );
    },
    [dispatch],
  );

  //function to save/submit the configuration modal data
  const dispPostConfig = useCallback(async () => {
    if (!returnAutoWorkFlow.integrationId) {
      return;
    }
    //dispatching the validation thunk and
    // then if the validation passes then calling the submit api otherwise showing warning toast
    const res = await dispatch(validateReturnTypeBasedOnOrderOrItems());
    if (res.payload === 0) {
      dispatch(
        updateReturnTypeBasedOnOrderOrItemsThunk({
          callback: () => {
            onSave(true);
          },
          // TODO -
          integrationId: returnAutoWorkFlow.integrationId,
          defaultResolutionOfferValue: "",
        }),
      );
    } else {
      pushTheToast({
        type: "warning",
        text: "Please fill the required fields!",
        position: "top-right",
      });
    }
  }, [dispatch, onSave, returnAutoWorkFlow.integrationId]);

  //function to add new condition for the passed tab id
  const dispAddCondition = useCallback(
    (id: string, isItem?: boolean, e?: { target: { value: string } }) => {
      dispatch(
        addCondition({
          returnTypeId: id,
          condition: e ? e.target.value : "",
          isItemCondition: isItem,
        }),
      );
      dispatch(validateChanges());
    },
    [dispatch],
  );

  return {
    dispAddCondition,
    dispAddTab,
    dispDeleteCondition,
    dispPostConfig,
    dispPushCurrentHashForSelectedTab,
    dispUpdateConditionForValues,
    dispUpdateConditionForVariables,
    dispUpdateConditonForOperator,
    dispUpdateReturnType,
    dispValidateCondition,
    dispValidateReturnType,
    returnTypes,
    fetchAjaxStatus,
    updateAjaxStatus,
    initialHashOtherTab,
    currentHashOtherTab,
    selectedReturnType,
    selectedReturnTypeId,
    isAddBtnEnabled,
    isAddItemBtnEnabled,
    otherTabsHasError,
    defaultOptions,
    itemOptions,
    conditionOptions,
    operatorNames,
  };
};
export default useReturnTypeBasedOnOrderOrItemsModal;
