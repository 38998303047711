/**
 * This file is the component file.
 *
 * @author Yash Aditya
 */

import { useCallback } from "react";
import styles from "./XTimes.module.scss";

interface Props {
  value: number;
  onChange: (value: number) => void;
  disabled?: boolean;
}

/**
 * XTimes component for handling increment and decrement of a numeric value.
 */
function XTimes({ value, onChange, disabled = false }: Props) {
  /**
   * Handles the increment action by calling the onChange callback with the incremented value.
   */
  const increment = useCallback(() => {
    if (!disabled) {
      onChange(value + 1);
    }
  }, [value, onChange, disabled]);

  /**
   * Handles the decrement action by calling the onChange callback with the decremented value.
   */
  const decrement = useCallback(() => {
    if (!disabled) {
      onChange(value - 1 < 1 ? 1 : value - 1);
    }
  }, [value, onChange, disabled]);

  return (
    <div
      className={`ms-md-2 d-flex justify-content-between mb-2 mb-md-0 align-items-center ${
        styles.timeWrap
      } ${disabled ? styles.disabled : ""}`}
    >
      <div
        className={`${styles.decrementNumber}`}
        style={{ cursor: disabled ? "default" : "pointer" }}
        onClick={decrement}
      >
        <span>
          <i className="fa-solid fa-minus"></i>
        </span>
      </div>
      <span className={`px-3 ${styles.value}`}>{value}</span>
      <div
        className={`${styles.decrementNumber}`}
        style={{ cursor: disabled ? "default" : "pointer" }}
        onClick={increment}
      >
        <span>
          <i className="fa-solid fa-plus"></i>
        </span>
      </div>
    </div>
  );
}

export default XTimes;
