import { NavLink, useParams } from "react-router-dom";
import styles from "./Announcement.module.scss";
import NewAnnouncement from "../../TargetedMessages/NewTargetedMessage/NewTargetedMessage";
import GreetingTable from "../Greetings/Children/GreetingTable/GreetingTable";
export const Links: {
  [key: string]: { name: string; component: any };
} = {
  newAnnouncement: { name: "new-annoucement", component: NewAnnouncement },
};
const Announcement = () => {
  const { subSubSettingName } = useParams();
  // console.log(subSubSettingName);
  const { integrationId } = useParams();
  const pageIntegrationId: string = integrationId
    ? decodeURIComponent(integrationId)
    : "";

  return (
    <div>
      <div className={`${styles.tableHeader}`}>
        <div className={`${styles.widthSet1}`}>
          <span className={`${styles.headerName}`}>Name</span>
        </div>
        <div className={`${styles.widthSet2}`}>
          <span className={`${styles.headerName}`}>Status</span>
        </div>
        <div className={`${styles.widthSet3}`}>
          <span className={`${styles.headerName}`}>Displayed</span>
        </div>
      </div>
      <GreetingTable imgText={`Run`} display={0} chat={0} conversation={`-`} />
      <div className={`text-center ${styles.announceDiv}`}>
        <p className={`${styles.announce}`}>No announcement yet</p>
        <p className={`${styles.announcePara}`}>
          Create your first announcement to engage with customers on your
          website.
        </p>
        <button className={`${styles.addNewBtn}`}>
          <span>
            <i className="fa-solid fa-plus"></i>{" "}
          </span>
          <NavLink
            to={`/live-chat/settings/${pageIntegrationId}/newAnnouncement`}
            className={`me-3 ${styles.mailRes} text-white`}
          >
            Create new announcement
          </NavLink>
        </button>
      </div>
    </div>
  );
};
export default Announcement;
