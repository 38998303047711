import styles from "./MessageTitle.module.scss";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import merge from "src/assets/images/merge.png";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { useCallback, useEffect, useRef, useState } from "react";
import SnoozeModal from "src/components/SnoozeModal";
import { ISnoozeData } from "src/services/Snooze/getSnooze";
import {
  fetchInnerTickets,
  fetchMessagesUpdates,
  resetInnerTicketData,
  setInnerTicketActive,
  setInnerTicketFilters,
  setTicketSubject,
} from "src/store/slices/innerTicket/innerTicket.slice";
import { updateTicket } from "src/services/TicketService/updateTicket";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { highlightSearchedText } from "src/routes/TicketList/children/OuterTickets/Chidren/SearchedTickets/SearchedTickets";

interface Props {
  setShowMerge: Function;
}
const MessageTitle = ({ setShowMerge }: Props) => {
  const dispatch = useAppDispatch();
  const scopes = useAppSelector(
    (state) => state.globals.currentUserData?.scopes,
  );
  const ticketSubjectHRef: any = useRef<any>(null);
  const [showModal, setShowModal] = useState(false);
  const [snoozeData, setSnoozeData] = useState(null as ISnoozeData | null);
  const onShow = useCallback(() => {
    setShowModal(true);
  }, []);
  const onHide = useCallback(() => {
    dispatch(fetchMessagesUpdates());
    setShowModal(false);
  }, []);
  const [dataCopied, setDataCopied] = useState(false);
  const { tableType, ticketId, viewType }: any = useParams();
  const activeTicketInfo: any = useAppSelector(
    (state) => state.innerTicket.activeTicketInfo,
  );
  const allTicketStatus = useAppSelector(
    (state) => state.innerTicket.ticketStatusList,
  );
  const ticketDataAjaxStatus = useAppSelector(
    (state) => state.innerTicket.ticketDataAjaxStatus,
  );

  // ticket subject
  const [ticketSubjectToEdit, setTicketSubjectToEdit] = useState<string>(
    activeTicketInfo.ticket_subject ? activeTicketInfo.ticket_subject : "",
  );
  // enable disable ticket subject editor
  const [enableTicketSubjectEditor, setEnableTicketSubjectEditor] =
    useState<boolean>(false);
  const subjectInputRef: any = useRef(null);
  const [showSubjectOverlay, setShowSubjectOverlay] = useState<boolean>(false);
  const currentState = useRef({
    allTicketStatus,
    activeTicketInfo,
    ticketDataAjaxStatus,
  });

  useEffect(() => {
    currentState.current = {
      allTicketStatus,
      activeTicketInfo,
      ticketDataAjaxStatus,
    };
    setTicketSubjectToEdit(
      activeTicketInfo.ticket_subject ? activeTicketInfo.ticket_subject : "",
    );
  }, [allTicketStatus, activeTicketInfo, ticketDataAjaxStatus]);

  const handelOnPending = useCallback(() => {
    if (
      Object.keys(currentState.current.allTicketStatus).length &&
      currentState.current.ticketDataAjaxStatus === "fulfilled"
    ) {
      let value = 0;
      for (const x in currentState.current.allTicketStatus) {
        if (
          currentState.current.allTicketStatus[x].statusName.toLowerCase() ===
          "pending"
        ) {
          value = currentState.current.allTicketStatus[x].id;
        }
      }
      dispatch(
        setInnerTicketFilters({
          ticketStatus: value,
        }),
      );
      onHide();
    }
  }, []);

  useEffect(() => {
    setDataCopied(false);
    setShowSubjectOverlay(false);
  }, [tableType, ticketId, viewType]);

  // to update ticket subject
  const handleTicketSubjectEdit = () => {
    if (activeTicketInfo.ticket_subject.trim() !== ticketSubjectToEdit.trim()) {
      dispatch(
        setTicketSubject({
          updatedSubject: ticketSubjectToEdit,
          ticketId: activeTicketInfo.ticket_id,
        }),
      );
      updateTicket({
        id: activeTicketInfo.ticket_id,
        subject: ticketSubjectToEdit,
      })
        .then((res) => {
          if (res.err === false) {
            pushTheToast({
              type: "success",
              text: "Ticket subject updated!",
              position: "top-right",
            });
          } else {
            // pushTheToast({
            //   type: "danger",
            //   text: "Failed to update ticket subject!",
            //   position: "top-right",
            // });
            // dispatch(fetchMessagesUpdates());
          }

          // dispatch(resetInnerTicketData());
          // dispatch(setInnerTicketActive({ ticketId: ticketId }));
          // dispatch(
          //   setInnerTicketFilters({
          //     tableType,
          //     currentView: viewType
          //   })
          // );
          // dispatch(fetchInnerTickets());
          // dispatch(fetchMessagesUpdates());
          // dispatch(
          //   setTicketSubject({
          //     updatedSubject: ticketSubjectToEdit,
          //     ticketId: activeTicketInfo.ticket_id,
          //   })
          // );
        })
        .catch((err) => {
          pushTheToast({
            type: "danger",
            text: "Failed to update ticket subject!",
            position: "top-right",
          });
          // if failed, reset with actual subject
          dispatch(fetchMessagesUpdates());
        });
    }
  };

  // const onClickOutsideDiv = useCallback((e: any) => {
  //   if (
  //     ticketSubjectHRef.current &&
  //     e.target !== ticketSubjectHRef.current &&
  //     !ticketSubjectHRef.current.contains(e.target)
  //   ) {
  //     if (
  //       ticketSubjectHRef.current &&
  //       ticketSubjectHRef.current.classList.contains(styles.ticketInfoExpand)
  //     ) {
  //       ticketSubjectHRef.current.classList.remove(styles.ticketInfoExpand);
  //     }
  //     ticketSubjectHRef.current.classList.add(styles.ticketInfo);
  //   }
  // }, []);

  // useEffect(() => {
  //   window.addEventListener("click", onClickOutsideDiv);
  //   return () => {
  //     window.removeEventListener("click", onClickOutsideDiv);
  //   };
  // }, [onClickOutsideDiv]);

  // const handleDoubleClickOnTitle = (e: any) => {
  //   e.preventDefault();
  //   if (
  //     ticketSubjectHRef.current &&
  //     ticketSubjectHRef.current.classList.contains(styles.ticketInfo)
  //   ) {
  //     ticketSubjectHRef.current.classList.remove(styles.ticketInfo);
  //   }
  //   ticketSubjectHRef.current.classList.add(styles.ticketInfoExpand);
  // };

  // Search Term from the global search
  const searchTerm = useAppSelector(
    (state) => state.ticketGlobalSearch.searchText,
  );

  return (
    <>
      <div
        className={`px-2 py-1 ${styles.orderBox} pe-4 justify-content-between w-100`}
      >
        <div
          className={`d-flex justify-content-between align-items-center ${styles.shipHead}`}
        >
          <h6
            className={`d-flex justify-content-between mb-0 me-2 pe-2 ${styles.ticketInfo}`}
            ref={ticketSubjectHRef}
            // onDoubleClick={(e: any) => handleDoubleClickOnTitle(e)}
            onClick={() => {
              if (enableTicketSubjectEditor === false) {
                setShowSubjectOverlay(false);
                setEnableTicketSubjectEditor(true);
                if (subjectInputRef !== null && subjectInputRef.current) {
                  subjectInputRef.current.focus();
                }
              }
            }}
          >
            {/* <div className={styles.reDiv}>Re: </div> */}
            <div className={styles.subjectMainDiv}>
              {enableTicketSubjectEditor === true ? (
                <input
                  data-lpignore="true"
                  type="text"
                  className={`${styles.editSubjectInput}`}
                  value={ticketSubjectToEdit}
                  ref={subjectInputRef}
                  onChange={(e) => setTicketSubjectToEdit(e.target.value)}
                  autoFocus={true}
                  onBlur={() => {
                    if (enableTicketSubjectEditor === true) {
                      setShowSubjectOverlay(false);
                      setEnableTicketSubjectEditor(false);
                      handleTicketSubjectEdit();
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setShowSubjectOverlay(false);
                      setEnableTicketSubjectEditor(false);
                      handleTicketSubjectEdit();
                    }
                  }}
                />
              ) : (
                <>
                  <OverlayTrigger
                    show={showSubjectOverlay}
                    placement="bottom"
                    overlay={
                      <Tooltip
                        className={`${styles.toolTipCustomTicketSub} text-center justify-content-center`}
                      >
                        <p
                          className={`${styles.tooltipHead} text-justify m-auto p-2`}
                        >
                          {ticketSubjectToEdit}
                        </p>
                      </Tooltip>
                    }
                  >
                    {({ ref, ...triggerHandler }) => (
                      <div
                        {...triggerHandler}
                        ref={ref}
                        onMouseOver={() => {
                          setShowSubjectOverlay(true);
                        }}
                        onMouseOut={() => {
                          setShowSubjectOverlay(false);
                        }}
                      >
                        <span className={styles.ticketSubjectSpan}>
                          {highlightSearchedText(
                            ticketSubjectToEdit,
                            searchTerm,
                          )}
                        </span>
                      </div>
                    )}
                  </OverlayTrigger>
                </>
              )}
            </div>
          </h6>
        </div>
        <div className={`d-flex ms-1 align-items-center`}>
          {activeTicketInfo.transferredTicketUrl ? (
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip className={`override-white ${styles.toolTipCustom}`}>
                  Copy Transferred Ticket's Link
                </Tooltip>
              }
            >
              {({ ref, ...triggerHandler }) => (
                <div
                  className={`${styles.mergeIconSpan} d-flex mx-1`}
                  {...triggerHandler}
                  ref={ref}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      activeTicketInfo.transferredTicketUrl,
                    );
                    setDataCopied(true);
                  }}
                >
                  <i
                    className={`fa-solid fa-link m-auto ${styles.copyLink} ${
                      dataCopied ? styles.active : ""
                    }`}
                  ></i>
                </div>
              )}
            </OverlayTrigger>
          ) : (
            <></>
          )}
          {scopes && scopes.includes("change_status") && (
            <span
              onClick={onShow}
              className={`me-1 `}
            >
              <OverlayTrigger
                placement="top-end"
                overlay={
                  <Tooltip className={`snoozeToolTip ${styles.toolTipCustom}`}>
                    Snooze
                  </Tooltip>
                }
              >
                {({ ref, ...triggerHandler }) => (
                  <div
                    {...triggerHandler}
                    ref={ref}
                    className={`${styles.snoozeImg}`}
                  >
                    <i
                      className={`material-icons  ${
                        snoozeData !== null ? styles.blue : ""
                      }`}
                    >
                      &#xe046;
                    </i>
                  </div>
                )}
              </OverlayTrigger>
            </span>
          )}

          {scopes && scopes.includes("merge_tickets") && (
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip className={`override-white ${styles.toolTipCustom}`}>
                  Merge Other tickets here
                </Tooltip>
              }
            >
              {({ ref, ...triggerHandler }) => (
                <div
                  className={`${styles.mergeIconSpan} ms-0 me-0`}
                  {...triggerHandler}
                  ref={ref}
                  onClick={() => {
                    setShowMerge(true);
                  }}
                >
                  <img
                    src={merge}
                    className={`${styles.mergeIcon}`}
                    alt="merge"
                    width={12}
                    height={15}
                  />
                </div>
              )}
            </OverlayTrigger>
          )}
          {/* <i
            className={`fa-solid fa-ellipsis-vertical ${styles.ThreeDot} ms-3 me-0`}
          ></i> */}
        </div>
      </div>
      {scopes && scopes.includes("change_status") && (
        <SnoozeModal
          show={showModal}
          onShow={onShow}
          onHide={onHide}
          ticketId={ticketId}
          snoozeData={snoozeData}
          setSnoozeData={setSnoozeData}
          handelOnPending={handelOnPending}
        />
      )}
    </>
  );
};
export default MessageTitle;
