import {
  Message,
  MessageReturnType,
} from "src/services/LiveChat/messageExchange/getMessages";
import { ChatWidExchMsgSlice } from "./chatWidExchMsg.declarations";

const fetchMsgDataFulfilled = (
  state: ChatWidExchMsgSlice,
  {
    payload,
  }: { payload: { data: MessageReturnType; scrollDirection?: "old" | "new" } }
) => {
  state.messages = { ...state.messages, ...payload.data.messages };
  if (payload.scrollDirection === "new") {
    state.messageIdList = Array.from(
      new Set([...payload.data.messageIdList, ...state.messageIdList])
    );
  } else {
    state.messageIdList = Array.from(
      new Set([...state.messageIdList, ...payload.data.messageIdList])
    );
  }

  // Setting up all the meta data after verifying them.
  state.metaData.unreadMentionMessageId =
    payload.data.metaData.unreadMentionMessageId;
  state.metaData.waitingForFromComplete =
    payload.data.metaData.waitingForFromComplete;
  if (payload.data.metaData.limitReachedNew != undefined) {
    state.metaData.limitReachedNew = payload.data.metaData.limitReachedNew;
  }
  if (payload.data.metaData.limitReachedOld != undefined) {
    state.metaData.limitReachedOld = payload.data.metaData.limitReachedOld;
  }
  if (payload.data.activeCustomerInfo) {
    state.activeCustomerInfo = payload.data.activeCustomerInfo;
  }
  state.fetchMsgAJAXStatus = "fulfilled";

  return state;
};

const sendLiveMsgFulfilled = (
  state: ChatWidExchMsgSlice,
  { payload }: { payload: Message }
) => {
  // // If the message is already present in the list, remove it
  // if (state.messages[payload.messageId]) {
  //   // Delete the message from list
  //   state.messageIdList = state.messageIdList.filter(
  //     (id) => id !== payload.messageId
  //   );
  // }
  const uuid = payload.messageUuid + "";
  const index = state.messageIdList.indexOf(uuid);
  if (index !== -1) {
    // Replace the UUID with messageId
    state.messageIdList[index] = payload.messageId;
    delete state.messages[uuid];
    state.messages[payload.messageId] = payload;
  }

  state.sendMsgAJAXStatus = "fulfilled";
  return state;
};

interface MessageUpdates extends MessageReturnType {
  chatId: number | string;
}
const fetchLiveChatMessgeUpdatesFulfilled = (
  state: ChatWidExchMsgSlice,
  { payload }: { payload: MessageUpdates }
) => {
  const messageUuids = Object.values(payload.messages).map(
    (message) => message.messageUuid
  );

  if (
    state.activeCustomerInfo?.chatId &&
    state.activeCustomerInfo?.chatId == payload.chatId
  ) {
    messageUuids.forEach((uuid) => {
      if (uuid != null) {
        if (state.messages[uuid]) {
          // Remove the message from the messages object
          delete state.messages[uuid];

          // Remove the UUID from the messageIdList array
          const uuidIndex = state.messageIdList.indexOf(uuid);
          if (uuidIndex !== -1) {
            const newMessageId = payload.messages[uuid]?.messageId;
            if (newMessageId) {
              state.messageIdList[uuidIndex] = newMessageId;
            } else {
              state.messageIdList.splice(uuidIndex, 1);
            }
          }
        }
      }
    });

    // Append the new messages to the state object
    //Mar 01 ... message ids order is [recent, ...old] ... the recent message should be in index 0 and old should be after that
    //reversing the array to insert the recent id in index 0
    payload.messageIdList.reverse().forEach((msgId: any) => {
      if (!state.messageIdList.includes(msgId)) {
        state.messageIdList.unshift(msgId);
      }
      state.messages[msgId] = payload.messages[msgId];
    });

    state.metaData.waitingForFromComplete =
      payload.metaData.waitingForFromComplete;
    if (payload.activeCustomerInfo) {
      state.activeCustomerInfo = payload.activeCustomerInfo;
    }
  }

  // state.fetchMsgAJAXStatus = "fulfilled";
  return state;
};

function fetchAssignToAgentsFulfilled(
  state: ChatWidExchMsgSlice,
  { payload }: any
) {
  const { agentIds, agents, metaData } = payload;

  state.assignToAgentListAJAXStatus = "fulfilled";
  state.agentIds = Array.from(new Set([...state.agentIds, ...agentIds]));

  state.agents = { ...state.agents, ...agents };
  state.agentMeta.count = metaData.count;
  state.agentMeta.total = metaData.total;

  return state;
}

export default {
  fetchMsgDataFulfilled,
  sendLiveMsgFulfilled,
  fetchLiveChatMessgeUpdatesFulfilled,
  fetchAssignToAgentsFulfilled,
};
