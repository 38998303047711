import React, { createContext } from "react";

export interface OrderCheckedState {
  id: number;
  checked: boolean;
}

const AllOrdersContext = createContext<{
  ordersChecked: OrderCheckedState[];
  upsertOrderChecked: (orderToUpdate: OrderCheckedState) => void;
  getOrderCheckedById(id: number):
    | {
        id: number;
        checked: boolean;
      }
    | undefined;
  areAllOrdersChecked(): boolean;
  toggleCheckedOrders(checkedAll: boolean): void;
  resetState(): void;
}>({
  ordersChecked: [],
  upsertOrderChecked(orderToUpdate: OrderCheckedState) {},
  getOrderCheckedById(id: number) {
    return undefined;
  },
  areAllOrdersChecked() {
    return false;
  },
  toggleCheckedOrders() {},
  resetState() {},
});

export default AllOrdersContext;
