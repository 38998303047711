import styles from "./AddTicket.module.scss";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  addTicketApi,
  IAddTicketApi,
} from "src/apis/addTicket/insert/addTicket.api";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { fetchMultipleSidebarData } from "src/store/slices/ticketSidebar/ticketSidebar.slice";
import {
  fetchTickets,
  resetCachedTickets,
} from "src/store/slices/tickets/tickets.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import Add from "./children/Add/Add";
import Success from "./children/Success/Success";
import { Modal } from "react-bootstrap";
import { UploadAttachmentRes } from "src/services/Attachment/uploadAttachment";
import { findAndReplaceCID, trimPDIVBR } from "src/utils/utils";
import { FreePlanError, FreePlanErrorMessage } from "src/globals/constants";
import PlanUpgradeOverlay from "src/components/PlanUpgrade/PlanUpgradeOverlay/PlanUpgradeOverlay";
import useIntegrationFailure from "src/hooks/useIntegrationFailure";
import { IntegrationFailureMetaData } from "src/types/IntegrationFailure";
import IntegrationFailureModal from "src/components/IntegrationFailureModal/IntegrationFailureModal";

function AddTicket() {
  const dispatch = useAppDispatch();
  const [showModel, setShowModel] = useState<string>("");
  const [ticketId, setTicketid] = useState<string>("");
  const [customerName, setCustomerName] = useState("");
  const [toValue, setToValue] = useState("");
  const [subjectValue, setSubjectValue] = useState("");
  const [fromValue, setFromValue] = useState("");
  const [cCValue, setCCValue] = useState("");
  const [bCCValue, setBCCValue] = useState("");

  const disabledFeatures = useAppSelector(
    (state) => state.globals.currentUserData?.disabledFeatures,
  );

  // const [attachmentBatchNumber, setAttachmentBatchNumber] = useState<string>("");
  // stores the attachment upload res such as attachment id, batch number, attachment name, status etc..
  const [attachmentUploadData, setAttachmentUploadData] =
    useState<UploadAttachmentRes>();
  // stores the selected files
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [showError, setShowError] = useState(false);
  // store the batch number of uploaded attachments
  const [currentAttachmentBatchNumber, setCurrentAttachmentBatchNumber] =
    useState<string>("");

  const addTicketModal: any = useRef(null);
  const [sendAs, setSendAs] = useState("Open");
  const [sendType, setSendType] = useState(1);
  const formRef: any = useRef(null);
  const onCloseModel = useCallback(() => {
    setShowModel("");
    setTicketid("");
  }, [setShowModel]);

  const { getIntegrationFailureData } = useIntegrationFailure();
  const [integrationFailureAPIData, setIntegrationFailureAPIData] =
    useState<IntegrationFailureMetaData | null>(null);
  /**
   * Integration failure data
   */
  const { integrationFailure, isIntegrationFailure } = useMemo(() => {
    return getIntegrationFailureData({
      fromEmail: formRef?.current?.elements?.from?.value,
      integrationFailureAPIData: integrationFailureAPIData,
    });
  }, [formRef?.current?.elements?.from?.value, integrationFailureAPIData]);

  // Used to open integration failure modal , when click on see why
  const [showCannotSendModal, setShowCannotSendModal] = useState(false);
  const handleCannotSend: React.MouseEventHandler<HTMLButtonElement> =
    useCallback((e) => {
      e.preventDefault();
      e.stopPropagation();
      setShowCannotSendModal(true);
    }, []);

  const addTicketSubmit = useCallback(
    (e: any) => {
      e.preventDefault();
      const ticketData: IAddTicketApi = {
        to: formRef.current.elements.to.value.trim(),
        name: formRef.current.elements.name.value.trim(),
        cc: formRef.current.elements.cc.value.trim(),
        bcc: formRef.current.elements.bcc.value.trim(),
        from: formRef.current.elements.from.value.trim(),
        ticket_title: formRef.current.elements.ticket_title.value.trim(),
        ticket_description: trimPDIVBR(
          findAndReplaceCID(
            formRef.current.elements.ticket_description.value.trim(),
          ),
        ),
        sendAs,
        sendType,
        attachmentBatchNumber: selectedFiles
          ? selectedFiles.map(function (value: any, index: any) {
              return value.batchNumber;
            })
          : [],
        // attachmentUploadData?.attachments[0].batchNumber.trim()
        // selectedFiles[0] && selectedFiles[0].batchNumber
        //   ? selectedFiles[0].batchNumber.trim()
        //   : ""
      };
      setShowModel("success");
      addTicketApi(ticketData)
        .then((res) => {
          if (res.id) {
            setTicketid(res.id);
            dispatch(resetCachedTickets());
            dispatch(fetchTickets());
            // dispatch(fetchMultipleSidebarData());
          } else {
            pushTheToast({
              type: "danger",
              text: "Error Creating Ticket!",
              position: "top-right",
            });
          }
        })
        .catch((err) => {
          // Check if we get status code for free plan limit reached
          if (
            typeof err == "object" &&
            (err.statusCode === FreePlanError.LIMIT_REACHED ||
              err.statusCode === FreePlanError.RESTRICTED)
          ) {
            const statusCode =
              err.statusCode as keyof typeof FreePlanErrorMessage;
            // If the free plan limit is exceeded, show free plan error
            setShowModel("add");
            setShowError(true);
            pushTheToast({
              text: err.message ?? FreePlanErrorMessage[statusCode],
              type: "danger",
              position: "top-right",
            });
          } else if (
            typeof err == "object" &&
            err.statusCode === "integrationFailure"
          ) {
            // If there is an integration failure in response , show error
            setShowModel("add");
            setIntegrationFailureAPIData(err.failureMetaData);
          } else {
            setShowModel("add");
            setShowError(true);
            pushTheToast({
              type: "danger",
              text: err,
              position: "top-right",
            });
          }
        });
    },
    [
      sendAs,
      sendType,
      /*attachmentBatchNumber*/ /*attachmentUploadData*/ selectedFiles,
    ],
  );

  return (
    <>
      {/* Plan Upgrade UI with disabled button */}
      <PlanUpgradeOverlay
        showOverlay={disabledFeatures?.includes("add_ticket") ?? false}
        message="Upgrade to add more tickets"
        placement="bottom"
      >
        <button
          className={`${styles.addTicket} mb-2`}
          ref={addTicketModal}
          onClick={() => {
            setShowModel("add");
          }}
          disabled={disabledFeatures?.includes("add_ticket") ?? false}
          id="AddBtn"
        >
          <span className="me-1">
            <i className="fas fa-plus" />
          </span>{" "}
          Add Ticket
        </button>
      </PlanUpgradeOverlay>

      {/* addTicket Modal */}
      <Modal
        enforceFocus={false}
        className={`addTickModal`}
        contentClassName={`${styles.modalContent}`}
        backdropClassName={`${styles.backDropCol}`}
        show={showModel === "add"}
        onHide={onCloseModel}
        id="addTicketModal"
        backdrop="static" // Prevents clicking outside the modal to close it
      >
        <Add
          ref={formRef}
          addTicketSubmit={addTicketSubmit}
          // attachmentBatchId={attachmentBatchNumber}
          // setAttachmentBatchId={setAttachmentBatchNumber}
          attachmentUploadData={attachmentUploadData} // in case of save as draft, this data should be shown on next visit on drafted ticket
          setAttachmentUploadData={setAttachmentUploadData}
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
          currentAttachmentBatchNumber={currentAttachmentBatchNumber}
          setCurrentAttachmentBatchNumber={setCurrentAttachmentBatchNumber}
          sendAs={sendAs}
          setSendAs={setSendAs}
          showError={showError}
          setShowError={setShowError}
          sendType={sendType}
          setSendType={setSendType}
          onCloseModel={onCloseModel}
          customerName={customerName}
          setCustomerName={setCustomerName}
          toValue={toValue}
          setToValue={setToValue}
          subjectValue={subjectValue}
          setSubjectValue={setSubjectValue}
          fromValue={fromValue}
          setFromValue={setFromValue}
          cCValue={cCValue}
          setCCValue={setCCValue}
          bCCValue={bCCValue}
          setBCCValue={setBCCValue}
          isIntegrationFailure={isIntegrationFailure}
          integrationFailure={integrationFailure}
          handleCannotSend={handleCannotSend}
        />
      </Modal>
      {/* Integration Failure Modal  */}
      {isIntegrationFailure && integrationFailure && (
        <IntegrationFailureModal
          show={showCannotSendModal}
          onHide={() => setShowCannotSendModal(false)}
          data={integrationFailure}
        />
      )}
      {showModel === "success" && (
        <Modal
          enforceFocus={false}
          className={`addTickModal ${showModel === "success" && "successMsg"}`}
          show={true}
          onHide={() => {
            setShowModel("");
          }}
        >
          <Success
            ticketId={ticketId}
            onCloseModel={onCloseModel}
          />
        </Modal>
      )}
    </>
  );
}
export default AddTicket;
