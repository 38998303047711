import { v4 as uuidv4 } from "uuid";
import { PayloadAction } from "@reduxjs/toolkit";
import { IAskForEmailSetting, initialState } from "./askForEmailSetting.slice";

const resetAskForEmailSetting = (state: IAskForEmailSetting) => {
  state = { ...initialState };
  return state;
};

// const validateChanges = (state: IAskForEmailSetting) => {
//   const { initialHash } = state;
//   const newHash = objectHash(state.askForEmailData);

//   if (newHash === initialHash) {
//     state.isChanged = false;
//   } else {
//     state.isChanged = true;
//   }

//   state.currentHash = newHash;
// };

// const resetHashes = (state: IAskForEmailSetting) => {
//   state.initialHash = "";
//   state.currentHash = "";
// };

const AddFormNewElement = (
  state: IAskForEmailSetting,
  action: PayloadAction<{
    optionId: any;
    type: "withinBusinessHours" | "outsideBusinessHours";
  }>
) => {
  const optionId = action.payload.optionId;
  const type = action.payload.type;

  if (optionId !== null) {
    let elementOptions = Object.values(
      state.askForEmailData.whenAgentOffline.formElementOptions
        .formElementOptions
    ).filter((element) => optionId === element.elementId);

    if (elementOptions.length === 0) {
      return;
    }

    const elementOption = elementOptions[0];

    let elements = [];

    if (type === "outsideBusinessHours") {
      elements =
        state.askForEmailData.whenAgentOffline.outsideBusinessHours
          .ticketChatFormData.elements;
    } else {
      elements =
        state.askForEmailData.whenAgentOffline.withinBusinessHours
          .ticketChatFormData.elements;
    }

    if (
      elementOption.canHaveMultiple === false &&
      elements.filter((element) => element.elementId == elementOption.elementId)
        .length !== 0
    ) {
      return;
    }

    if (
      ["choiceList", "multipleChoiceList", "dropDown"].includes(
        elementOption.elementType
      )
    ) {
      let newElement = {
        elementId: elementOption.elementId,
        elementType: elementOption.elementType,
        elementKey: elementOption.elementType + "_" + uuidv4(),
        elementLabel: elementOption.elementLabel,
        elementValue: "Question",
        elementOptions: ["Answer 1", "Answer 2"],
        isRequired: false,
        isDefault: false,
        uniqueId: uuidv4(),
        isNewlyAdded: true,
      };

      if (type == "outsideBusinessHours") {
        state.askForEmailData.whenAgentOffline.outsideBusinessHours.ticketChatFormData.elements.push(
          newElement
        );
      } else {
        state.askForEmailData.whenAgentOffline.withinBusinessHours.ticketChatFormData.elements.push(
          newElement
        );
      }
      // setFormElements(stateFormElements);
    } else {
      let newElement = {
        elementId: elementOption.elementId,
        elementType: elementOption.elementType,
        elementKey: elementOption.elementType + "_" + uuidv4(),
        elementLabel: elementOption.elementLabel,
        elementValue: "Question",
        elementOptions: [],
        isRequired: false,
        isDefault: false,
        uniqueId: uuidv4(),
        isNewlyAdded: true,
      };
      // setFormElements(stateFormElements);
      if (type == "outsideBusinessHours") {
        state.askForEmailData.whenAgentOffline.outsideBusinessHours.ticketChatFormData.elements.push(
          newElement
        );
      } else {
        state.askForEmailData.whenAgentOffline.withinBusinessHours.ticketChatFormData.elements.push(
          newElement
        );
      }
    }
  }
};

const removeFormElement = (
  state: IAskForEmailSetting,
  action: PayloadAction<{
    element: any;
    type: "withinBusinessHours" | "outsideBusinessHours";
  }>
) => {
  const element = action.payload.element;
  const type = action.payload.type;

  if (type == "outsideBusinessHours") {
    const stateFormElements =
      state.askForEmailData.whenAgentOffline.outsideBusinessHours.ticketChatFormData.elements.filter(
        (formElement: any) => element.uniqueId !== formElement.uniqueId
      );
    state.askForEmailData.whenAgentOffline.outsideBusinessHours.ticketChatFormData.elements =
      stateFormElements;
  } else {
    const stateFormElements =
      state.askForEmailData.whenAgentOffline.withinBusinessHours.ticketChatFormData.elements.filter(
        (formElement: any) => element.uniqueId !== formElement.uniqueId
      );
    state.askForEmailData.whenAgentOffline.withinBusinessHours.ticketChatFormData.elements =
      stateFormElements;
  }
};

const setBrandEmailData = (
  state: IAskForEmailSetting,
  action: PayloadAction<{
    email?: [];
    enabled?: boolean;
    type: "withinBusinessHours" | "outsideBusinessHours";
  }>
) => {
  const type = action.payload.type;

  if (action.payload.enabled) {
    if (type == "outsideBusinessHours") {
      state.askForEmailData.whenAgentOffline.outsideBusinessHours.ticketChatFormData.brandEmails.enabled =
        action.payload.enabled;
    } else {
      state.askForEmailData.whenAgentOffline.withinBusinessHours.ticketChatFormData.brandEmails.enabled =
        action.payload.enabled;
    }
  }

  if (action.payload.email) {
    if (type == "outsideBusinessHours") {
      state.askForEmailData.whenAgentOffline.outsideBusinessHours.ticketChatFormData.brandEmails.email =
        action.payload.email;
    } else {
      state.askForEmailData.whenAgentOffline.withinBusinessHours.ticketChatFormData.brandEmails.email =
        action.payload.email;
    }
  }

  return state;
};

const setCustomEmailData = (
  state: IAskForEmailSetting,
  action: PayloadAction<{
    email?: string;
    enabled?: boolean;
    type: "withinBusinessHours" | "outsideBusinessHours";
  }>
) => {
  const type = action.payload.type;
console.log(action.payload.enabled);
  if (action.payload.enabled) {
    if (type == "outsideBusinessHours") {
      state.askForEmailData.whenAgentOffline.outsideBusinessHours.ticketChatFormData.customEmails.enabled =
        action.payload.enabled;
    } else {
      state.askForEmailData.whenAgentOffline.withinBusinessHours.ticketChatFormData.customEmails.enabled =
        action.payload.enabled;
    }
  }

  if (action.payload.email) {
    if (type == "outsideBusinessHours") {
      state.askForEmailData.whenAgentOffline.outsideBusinessHours.ticketChatFormData.customEmails.email =
        action.payload.email;
    } else {
      state.askForEmailData.whenAgentOffline.withinBusinessHours.ticketChatFormData.customEmails.email =
        action.payload.email;
    }
  }
};
const setTicketFormConfirmationTextData = (
  state: IAskForEmailSetting,
  action: PayloadAction<{
    value: string;
    type: "withinBusinessHours" | "outsideBusinessHours";
  }>
) => {
  const type = action.payload.type;

  if (type == "outsideBusinessHours") {
    state.askForEmailData.whenAgentOffline.outsideBusinessHours.ticketFormConfirmationText =
      action.payload.value;
  } else {
    state.askForEmailData.whenAgentOffline.withinBusinessHours.ticketFormConfirmationText =
      action.payload.value;
  }
};

const updateFormElement = (
  state: IAskForEmailSetting,
  action: PayloadAction<{
    element: any;
    type: "withinBusinessHours" | "outsideBusinessHours";
  }>
) => {
  const element = action.payload.element;
  const type = action.payload.type;

  if (type == "outsideBusinessHours") {
    const stateFormElements =
      state.askForEmailData.whenAgentOffline.outsideBusinessHours.ticketChatFormData.elements.map(
        (formElement: any) => {
          if (element.uniqueId === formElement.uniqueId) {
            return element;
          } else {
            return formElement;
          }
        }
      );
    state.askForEmailData.whenAgentOffline.outsideBusinessHours.ticketChatFormData.elements =
      stateFormElements;
  } else {
    const stateFormElements =
      state.askForEmailData.whenAgentOffline.withinBusinessHours.ticketChatFormData.elements.map(
        (formElement: any) => {
          if (element.uniqueId === formElement.uniqueId) {
            return element;
          } else {
            return formElement;
          }
        }
      );
    state.askForEmailData.whenAgentOffline.withinBusinessHours.ticketChatFormData.elements =
      stateFormElements;
  }
};

const setFormElements = (
  state: IAskForEmailSetting,
  action: PayloadAction<{
    elementList: any;
    type: "withinBusinessHours" | "outsideBusinessHours";
  }>
) => {
  const elementList = action.payload.elementList;
  const type = action.payload.type;

  if (type == "outsideBusinessHours") {
    state.askForEmailData.whenAgentOffline.outsideBusinessHours.ticketChatFormData.elements =
      elementList;
  } else {
    state.askForEmailData.whenAgentOffline.withinBusinessHours.ticketChatFormData.elements =
      elementList;
  }
};

const setAgentOfflineMessageText = (
  state: IAskForEmailSetting,
  action: PayloadAction<{
    value: string;
    type: "withinBusinessHours" | "outsideBusinessHours";
  }>
) => {
  const value = action.payload.value;
  const type = action.payload.type;

  if (type == "outsideBusinessHours") {
    state.askForEmailData.whenAgentOffline.outsideBusinessHours.messageText =
      value;
  } else {
    state.askForEmailData.whenAgentOffline.withinBusinessHours.messageText =
      value;
  }
};

const setAgentOfflineConfirmationText = (
  state: IAskForEmailSetting,
  action: PayloadAction<{
    value: string;
    type: "withinBusinessHours" | "outsideBusinessHours";
  }>
) => {
  const value = action.payload.value;
  const type = action.payload.type;

  if (type == "outsideBusinessHours") {
    state.askForEmailData.whenAgentOffline.outsideBusinessHours.confirmationText =
      value;
  } else {
    state.askForEmailData.whenAgentOffline.withinBusinessHours.confirmationText =
      value;
  }
};

const setAgentOfflineAskForEmail = (
  state: IAskForEmailSetting,
  action: PayloadAction<{
    value: boolean;
    type: "withinBusinessHours" | "outsideBusinessHours";
  }>
) => {
  const value = action.payload.value;
  const type = action.payload.type;

  if (type == "outsideBusinessHours") {
    state.askForEmailData.whenAgentOffline.outsideBusinessHours.askForEmail =
      value;
  } else {
    state.askForEmailData.whenAgentOffline.withinBusinessHours.askForEmail =
      value;
  }
};

const setAgentOfflineAutoReply = (
  state: IAskForEmailSetting,
  action: PayloadAction<{
    value: boolean;
    type: "withinBusinessHours" | "outsideBusinessHours";
  }>
) => {
  const value = action.payload.value;
  const type = action.payload.type;

  if (type == "outsideBusinessHours") {
    state.askForEmailData.whenAgentOffline.outsideBusinessHours.autoReply =
      value;
  } else {
    state.askForEmailData.whenAgentOffline.withinBusinessHours.autoReply =
      value;
  }
};

const setShowOutsideBusinessForm = (
  state: IAskForEmailSetting,
  action: PayloadAction<{
    value: boolean;
  }>
) => {
  
  state.showOutsideBusinessHours = action.payload.value;  
};

const setShowTicketForm = (
  state: IAskForEmailSetting,
  action: PayloadAction<{
    value: boolean;
  }>
) => {
  
  state.showTicketForm = action.payload.value;  
};

const setAgentNotAvailableFormValidationErrors = (
  state: IAskForEmailSetting,
  action: PayloadAction<{
    validationErrors: {
      messageTextErr: boolean,
      confirmationTextErr: boolean,
      brandEmailsErr: boolean,
      customEmailsErr: boolean,
      ticketConfirmationTextErr: boolean,
    },
    type: "withinBusinessHours" | "outsideBusinessHours",
  }>
) => {
  
  const type = action.payload.type;
  const validationErrors = action.payload.validationErrors;
  if(type === "outsideBusinessHours"){

    state.askForEmailData.whenAgentOffline.outsideBusinessHours.validationErrors = validationErrors;

  }else{

    state.askForEmailData.whenAgentOffline.withinBusinessHours.validationErrors = validationErrors;

  }
};


export default {
  resetAskForEmailSetting,
  AddFormNewElement,
  removeFormElement,
  updateFormElement,
  setBrandEmailData,
  setCustomEmailData,
  setTicketFormConfirmationTextData,
  setFormElements,
  setAgentOfflineMessageText,
  setAgentOfflineConfirmationText,
  setAgentOfflineAskForEmail,
  setAgentOfflineAutoReply,
  setShowOutsideBusinessForm,
  setShowTicketForm,
  setAgentNotAvailableFormValidationErrors
};
