import { useEffect } from "react";
import { Editor as TinyMceEditor } from "tinymce";

interface Props {
  editorRef: React.MutableRefObject<TinyMceEditor | null>;
  canDeleteInline?: boolean;
  deleteAttachmentByFileId: ({
    id,
    attachmentURL,
  }: {
    id?: string | null | undefined;
    attachmentURL?: string | null | undefined;
  }) => void;
}
function useTinymceAttachmentDeleteListener({
  editorRef,
  canDeleteInline,
  deleteAttachmentByFileId,
}: Props) {
  //useEffect to detect image deletion via cut
  useEffect(() => {
    const editor = editorRef.current;
    const handleCut = (e: any) => {
      const removedNodes = e.clipboardData?.getData("text/html");
      const parser = new DOMParser();
      const removedDoc = parser.parseFromString(removedNodes, "text/html");
      const removedImages = removedDoc.querySelectorAll("img");
      removedImages.forEach((img) => {
        const id = img.getAttribute("data-temp-id");
        setTimeout(() => {
          // console.log("Image deleted:", {
          //   id: id,
          //   src: img.src,
          //   alt: img.alt,
          //   width: img.width,
          //   height: img.height,
          // },id, editor?.getContent().includes(id+""));

          if (id && !editor?.getContent().includes(id)) {
            deleteAttachmentByFileId({
              id: id,
              attachmentURL: img.src,
            });
          }
        }, 0);
      });
    };

    if (editor && canDeleteInline) {
      editor.on("Cut", handleCut);
    }

    return () => {
      if (editor && canDeleteInline) {
        editor.off("Cut", handleCut);
      }
    };
  }, [editorRef.current, canDeleteInline]);

  //useEffect to detect dom deletion in editor
  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.removedNodes && canDeleteInline) {
          Array.from(mutation.removedNodes).forEach((node) => {
            if (node.nodeName === "IMG") {
              const imageNode = node as HTMLImageElement;
              // console.log("Image deleted:", {
              //   src: imageNode.src,
              //   alt: imageNode.alt,
              //   width: imageNode.width,
              //   height: imageNode.height,
              // });

              let id = imageNode.getAttribute("data-temp-id");

              if (id && !editorRef.current?.getContent().includes(id)) {
                deleteAttachmentByFileId({
                  id: id,
                  attachmentURL: imageNode.src,
                });
              }
            }
          });
        }
      });
    });
    // added check to verify document object is not null or undefined
    if (editorRef.current && editorRef.current.getDoc()) {
      observer.observe(editorRef.current.getDoc(), {
        childList: true,
        subtree: true,
      });
    }
    return () => observer.disconnect();
  }, [editorRef.current, canDeleteInline]);
}

export default useTinymceAttachmentDeleteListener;
