import styles from "./TargetLinks.module.scss";
import { NavLink, useParams } from "react-router-dom";
import Greetings from "../../Children/Greetings/Greetings";
import Announcement from "../../Children/Announcement/Announcement";
export const Links: {
  [key: string]: { name: string; component: any };
} = {
  greeting: { name: "greeting", component: Greetings },
  announcement: { name: "announcement", component: Announcement },
};
const TargetLinks = () => {
  const { settingName, subSettingName } = useParams();

  console.log(settingName, subSettingName);

  const params = useParams();

  return (
    <>
      <div className={`d-flex flex-wrap d-md-inline-block ${styles.setUp}`}>
        <NavLink
          to={`/live-chat/settings/${params.integrationId}/${params.settingName}/${Links.greeting.name}`}
          className={`me-3 ${styles.mailRes} ${
            subSettingName === Links.greeting.name ? styles.active : ""
          }`}
        >
          Greetings
        </NavLink>
        <NavLink
          to={`/live-chat/settings/${params.integrationId}/${params.settingName}/${Links.announcement.name}`}
          className={`me-3 ${styles.mailRes} ${
            subSettingName === Links.announcement.name ? styles.active : ""
          }`}
        >
          Announcements
        </NavLink>
      </div>
    </>
  );
};
export default TargetLinks;
