import { axiosJSON } from "src/globals/axiosEndPoints";
import log from 'loglevel';
import { TicketsQuery } from "./getTicketsV2";

export type AssignTicketsToPayload = {
  userId: number,
  ticketId?: string[]
  allTickets?: boolean;
  filters?: TicketsQuery["filters"];
  isCustomerView?: boolean;
}

export async function assignTicketsTo(servicePayload: AssignTicketsToPayload) {
  const { data } = await axiosJSON.post(`/api/ticket/assign`, servicePayload);
  if (data.err === true) {
    throw new Error(data.msg as string)
  }
  const normalizedData = normalizeData(data)
  // log.debug('bulk assign ticket raw response:', data);
  // log.debug('bulk assign ticket normalized response:', normalizedData);
  return normalizedData;
}


// convert incoming data to the required format
function normalizeData(response: any) {

  const normalizedData = response
  return normalizedData
}