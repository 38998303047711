import { axiosJSON } from "src/globals/axiosEndPoints";
import APIResponse from "./APIReponse";

export interface LogoutUserFromAllSessionPayload {
  userId: number;
}

export async function logoutUserFromAllSession(payload: LogoutUserFromAllSessionPayload) {
  const { data: res } = await axiosJSON.post(
    "/api/setting/user/logoutUserFromAllSessions",
    payload
  );

  if (res.err === true) {
    throw new Error(res.msg as string);
  }

  return res.data;
}
