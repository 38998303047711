import { createSlice } from "@reduxjs/toolkit";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { AJAXSTATUS } from "src/globals/constants";
import { RefundRequestFollowUpQuestions } from "src/services/UiAutomation/ReturnExchange/Configuration/AntiRefund/getFollowUpQuestion";
import { conditionType } from "src/services/UiAutomation/ReturnExchange/Configuration/Step9/step9GetOptionsConditions";
import { v4 as uuidv4 } from "uuid";
import {
  fetchGetRefundRequestFollowUpQuestionsStep13,
  fetchPostStep13Config,
  fetchRefundRequestListStep13,
  fetchStep13GetConfigThunk,
} from "./Thunks/returnExchangeModal.thunk";
import { RefundReason } from "./antiRefundConfig.slice";
import antiRefundOrderTypesConfigExtraReducers from "./antiRefundOrderTypesConfig.extraReducers";
import antiRefundOrderTypesConfigReducers from "./antiRefundOrderTypesConfig.reducers";
import { ConditionTypes } from "./Helpers/returnExchange.helpers";
import { ARSResponse } from "src/routes/Setting/children/MainSetting/children/SetRulesNew/childerns/childern/AddAutomations/WidgetPreviewSection/hooks/useGetAutomationData";

export interface IItem {
  productId: number | string;
  productName: string;
  productSku: string | null;
  imageUrl: string | null;
}

export interface IFollowUpQuestion {
  elementId: string;
  elementTypeId: string | number;
  elementValue: string;
  additionalOptions: { id: string; value: string }[];
  selectedValue: string[] | string;
  // selectedId: string[] | string;
}

export interface IRefundScenario {
  id: string;
  items: IItem[];
  followUpQuestions: IFollowUpQuestion[];
  enabled: boolean;
}

export type IVariableName = (typeof variableNames)[number];

export const variableNames = [
  "Item",
  "Tags",
  "Order Date",
  "Order Delivery Date",
  "Order Tag",
  "Discount Code",
  "Previous Exchanges On This Order",
  "Order Country",
  "Order Value",
  "None",
  "Return Reason",
] as const;

export const ruleTypes = ["AND", "OR"] as const;

export interface IConditionTypes {
  id: string;
  ruleType: (typeof ruleTypes)[number];
  variableName: IVariableName;
  values: any;
  operator: string | undefined;
}

export interface IAntiRefundOrderType {
  orderTypeId: string | number;
  orderTypeName: string;
  // scenarios: IRefundScenario[];
  conditions: ConditionTypes[];
  // additionalInformation?: {
  //   enabled?: boolean;
  // };
  refundRequests: {
    reasons: RefundReason[];
  };
  errorCount: number;
  error: string | null;
  isAddConditionEnabled: boolean;
  isValid: boolean;
  applyToAllOrders: boolean; //Added as per new design
}

export interface IAntiRefundOrderTypeData {
  selectedOrderTypeId: string | number | null;
  antiRefundOrderTypes: Array<IAntiRefundOrderType>;
  selectedTabIntialHash: string | null;
  selectedTabCurrentHash: string | null;
  refundRequestFollowUpQuestions: RefundRequestFollowUpQuestions[];
  refundRequestReasons: RefundReason[];
  conditionOptions: conditionType[];
  defaultOptions: conditionType[];
  // itemOptions: conditionType[];
  operatorsNames: any;
  operatorSymbolMap: any;
  variableNames: any;
  valueTypes: any;
  variableNameToValueType: any;
}
export interface IAntiRefundOrderTypesConfig {
  antiRefundOrderTypesData: IAntiRefundOrderTypeData;
  error: string | null;
  initialHash: string;
  currentHash: string;
  fetchAjaxStatus: AJAXSTATUS;
  updateAjaxStatus: AJAXSTATUS;
  followUpQuestionsAjaxStatus: AJAXSTATUS;
  isChanged: boolean;
  excludeKeysForHash: string[];
  arsResponse: ARSResponse | null; // Used for return preview
}
const excludeKeys = [
  "error",
  "isAddConditionEnabled",
  "isValid",
  "errorCount",
  "itemConditionError",
  "isNewScenario",
];
const firstOrderTypeId = uuidv4();

export const initialState: IAntiRefundOrderTypesConfig = {
  isChanged: false,
  antiRefundOrderTypesData: {
    refundRequestFollowUpQuestions: [],
    refundRequestReasons: [],
    selectedOrderTypeId: firstOrderTypeId,
    antiRefundOrderTypes: [
      {
        orderTypeId: firstOrderTypeId,
        orderTypeName: "Order Type 1",
        errorCount: 0,
        error: null,
        isAddConditionEnabled: true,
        isValid: false,
        // scenarios: [],
        conditions: [],
        // additionalInformation: {},
        refundRequests: {
          reasons: [],
        },
        applyToAllOrders: true, //By default enabled all orders toggle button
      },
    ],
    selectedTabIntialHash: "",
    selectedTabCurrentHash: "",
    operatorsNames: {},
    variableNames: [],
    valueTypes: {},
    operatorSymbolMap: {},
    variableNameToValueType: {},
    conditionOptions: [],
    defaultOptions: [],
    // itemOptions: [],
  },
  excludeKeysForHash: excludeKeys,
  error: null,
  initialHash: "",
  currentHash: "",
  fetchAjaxStatus: "pending",
  updateAjaxStatus: "idle",
  followUpQuestionsAjaxStatus: "idle",
  arsResponse: null,
};

export const antiRefundOrderTypesConfigSlice = createSlice({
  name: "antiRefundOrderTypesConfig",
  initialState,
  reducers: antiRefundOrderTypesConfigReducers,
  extraReducers: (builder) => {
    builder.addCase(
      fetchStep13GetConfigThunk.fulfilled,
      antiRefundOrderTypesConfigExtraReducers.fetchStep13GetConfigThunkFulfilled,
    );
    builder.addCase(fetchStep13GetConfigThunk.pending, (state) => {
      state.fetchAjaxStatus = "pending";
    });

    builder.addCase(fetchStep13GetConfigThunk.rejected, (state) => {
      state.fetchAjaxStatus = "rejected";
    });

    builder.addCase(fetchPostStep13Config.fulfilled, (state, action) => {
      state.updateAjaxStatus = "fulfilled";
      antiRefundOrderTypesConfigExtraReducers.fetchStep13GetConfigThunkFulfilled(
        state,
        action,
      );
      pushTheToast({
        type: "success",
        text: "Configuration Saved",
        position: "top-right",
      });
    });
    builder.addCase(fetchPostStep13Config.rejected, (state) => {
      state.updateAjaxStatus = "rejected";
      pushTheToast({
        position: "top-right",
        text: "Something went wrong while updating the anti refund request strategies",
        type: "danger",
      });
    });

    builder.addCase(fetchPostStep13Config.pending, (state) => {
      state.updateAjaxStatus = "pending";
    });
    builder.addCase(fetchRefundRequestListStep13.pending, (state) => {
      state.fetchAjaxStatus = "pending";
    });

    builder.addCase(fetchRefundRequestListStep13.rejected, (state) => {
      state.fetchAjaxStatus = "rejected";
    });

    builder.addCase(
      fetchRefundRequestListStep13.fulfilled,
      (state, { payload }) => {
        state.fetchAjaxStatus = "fulfilled";
        if (payload?.reasons) {
          const reasons = payload.reasons ?? [];
          const returnReasons = Object.values(reasons).map((obj) => {
            return {
              id: obj.id,
              reasonName: obj.reasonName,
              scenarios: [
                {
                  id: uuidv4(),
                  items: [],
                  tags: [],
                  enabled: false,
                  itemsAndTagAllSelected: false,
                  followUpQuestions: [],
                  //  obj.moreQuestions.map((element) => {
                  //   return {
                  //     elementId: element.elementId,
                  //     elementTypeId: element.elementTypeId,
                  //     elementValue: element.elementValue,
                  //     additionalOptions: element.additionalOptions,
                  //     selectedValue: "",
                  //     selectedId: "",
                  //   };
                  // }),
                },
              ],
            };
          });
          if (returnReasons) {
            state.antiRefundOrderTypesData.refundRequestReasons =
              returnReasons as unknown as RefundReason[];
          }
        }
      },
    );
    builder.addCase(
      fetchGetRefundRequestFollowUpQuestionsStep13.pending,
      (state) => {
        state.followUpQuestionsAjaxStatus = "pending";
      },
    );

    builder.addCase(
      fetchGetRefundRequestFollowUpQuestionsStep13.rejected,
      (state) => {
        state.followUpQuestionsAjaxStatus = "rejected";
      },
    );

    builder.addCase(
      fetchGetRefundRequestFollowUpQuestionsStep13.fulfilled,
      (state, { payload }) => {
        state.followUpQuestionsAjaxStatus = "fulfilled";
        state.antiRefundOrderTypesData.refundRequestFollowUpQuestions = payload;

        //       const selectedOrderTypeId = state.antiRefundOrderTypesData.selectedOrderTypeId;

        //       // Find the order type with a matching ID
        //       const selectedOrderTypeIndex = state.antiRefundOrderTypesData.antiRefundOrderTypes.findIndex(
        //         (orderType) => orderType.orderTypeId === selectedOrderTypeId
        //       );
        //       if (selectedOrderTypeIndex > -1) {
        //         state.antiRefundOrderTypesData.antiRefundOrderTypes[selectedOrderTypeIndex].refundRequests.reasons.forEach((reason: RefundReason) => {
        //         reason.scenarios.forEach((scenario) => {
        //           if (
        //             scenario.followUpQuestions.length === 1 &&
        //             scenario.followUpQuestions[0] === ("*" as any)
        //           ) {
        //             scenario.followUpQuestions =
        //               state.antiRefundOrderTypesData.refundRequestFollowUpQuestions.map(
        //                 (element) => {
        //                   return {
        //                     elementId: element.elementId,
        //                     elementTypeId: element.elementTypeId,
        //                     elementValue: element.elementValue,
        //                     additionalOptions: element.additionalOptions,
        //                     selectedValue: "",
        //                     // selectedId: "",
        //                   };
        //                 }
        //               );
        //           }

        //           scenario.followUpQuestions.forEach((followUpQuestion) => {
        //             if (
        //               followUpQuestion.selectedValue.length === 1 &&
        //               followUpQuestion.selectedValue[0] === "*"
        //             ) {
        //               followUpQuestion.selectedValue =
        //                 followUpQuestion.additionalOptions.map(
        //                   (option) => option.id
        //                 );
        //               // followUpQuestion.selectedId =
        //               //   followUpQuestion.additionalOptions.map(
        //               //     (option) => option.id
        //               //   );
        //             }
        //           });
        //         });
        //       });
        //      }
      },
    );
  },
});

export const {
  setSelectedOrderTypeId,
  updateOrderType,
  pushCurrentHashForSelectedTab,
  validateChanges,
  addOrderType,
  // validateOrderType,
  validateCondition,
  addCondition,
  deleteCondition,
  updateCondition,
  deselectAllFollowUpQuestions,
  removeFollowUpQuestions,
  selectAllFollowUpQuestions,
  toggleAntiRefundMethodStep13,
  updateAdditionalInfo,
  updateGiveOffer,
  updateFollowUpQuestionSelectedValue,
  updateFollowUpQuestions,
  updateItemsAndTagsAllSelected,
  updateOfferExchange,
  updateRefundReasons,
  updateScenarioItems,
  updateScenarioTags,
  validateStep13,
  resetConfig,
  deleteOrderTypeTab,
  setStep13OptionConditons,
  pushInitialHash,
  updateAdditionalInfoConfigOrderTypes,
  updateFollowUpQuestionsConfigOrderTypes,
  toggleAllOrder,
  updateArsResponse,
} = antiRefundOrderTypesConfigSlice.actions;

export default antiRefundOrderTypesConfigSlice.reducer;
