import { axiosJSON } from "src/globals/axiosEndPoints";
import APIResponse from "./APIReponse";

interface UpdateTeam {
    teamId : number;
}

export interface UpdateTeamParams {
  teamId : number;
  teamName : string;
}



export async function updateTeam (params : UpdateTeamParams) {
  

    const { data:res } = await axiosJSON.post<APIResponse<UpdateTeam>>(
      "/api/setting/team/update",
        params
    );

    if(res.error === true )
    {
      throw new Error(res.message as string)
    }

    return res.data as UpdateTeam 
  } 
