import { axiosJSON } from "src/globals/axiosEndPoints";
import APIResponse from "./APIReponse";

interface DeleteProfileImageUserParams {
  userId: number;
}
interface DeleteProfileImageUser {
  userId: number;
}

export async function deleteProfileImageUser(
  params: DeleteProfileImageUserParams
) {
  const { data: res } = await axiosJSON.delete<
    APIResponse<DeleteProfileImageUser>
  >(`/api/setting/user/deleteProfileImage?userId=${params.userId}`);

  if (res.error === true) {
    throw new Error(res.message as string);
  }

  return res.data as DeleteProfileImageUser;
}
