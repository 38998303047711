import { useState, createContext, useEffect, useRef } from "react";
import { Dropdown } from "react-bootstrap";
import { setTicketFilterToggleStateForPusherEvent } from "src/hooks/ticketPusherHook/useTicketEventBinder";
import { setFilterToggleStateG } from "src/routes/TicketList/TicketList";
import { setActiveFiltersToFilters } from "src/store/slices/ticketFilters/ticketFilters.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import FilterMenu from "./children/FilterMenu";
import FilterToggle from "./children/FilterToggle";
import styles from "./Filter.module.scss";

export const FilterToggleStateContext = createContext<any>({});

function Filter() {
  const dispatch = useAppDispatch();
  const ticketsLoadingState = useAppSelector(
    (state) => state.ticket.ticketsLoadingState
  );
  const [toggleState, setToggleState] = useState<boolean>(false);

  useEffect(() => {
    setFilterToggleStateG(toggleState);
    setTicketFilterToggleStateForPusherEvent(toggleState);
    dispatch(setActiveFiltersToFilters());
  }, [toggleState]);

  const menuRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <FilterToggleStateContext.Provider
        value={{ toggleState, setToggleState }}
      >
        <Dropdown
          onToggle={setToggleState}
          show={toggleState} // && ticketsLoadingState !== "pending" removed this condition as we don't need to hide the dropdown on apply
          align="end"
          id="ticketFilterBtn"
        >
          <Dropdown.Toggle as={FilterToggle} />
          <Dropdown.Menu
            as={FilterMenu}
            className={styles.dropdownMenu}
            ref={menuRef}
            id="ticketFilterMenu"
          />
        </Dropdown>
      </FilterToggleStateContext.Provider>
    </>
  );
}

export default Filter;
