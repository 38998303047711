import { axiosJSON } from "src/globals/axiosEndPoints";
import APIResponse from "./APIReponse";

interface RemoveMemberFromTeam {
    userId : number;
}

export interface RemoveMemberFromTeamParams {
    teamId : number;
    userId: number;
}



export async function removeMemberFromTeam (params : RemoveMemberFromTeamParams) {
  

    const { data : res }: any = await axiosJSON.post<APIResponse<RemoveMemberFromTeam>>(
      "/api/setting/team/removeMemberFromTeam",
        params
    );

    if(res.error === true )
    {
      throw new Error(res.message as string)
    }

    return res.data as RemoveMemberFromTeam 
  } 
