import {
  BaseConfigModalTabType,
  GetConfigModalParams,
} from "src/features/ReturnAutoWorkFlow/children/ConfigModals/oldReturnModals.types";
import { AntiRefundStrategiesReason } from "src/features/ReturnAutoWorkFlow/children/ConfigModals/store/slices/AntiRefundStrategiesSlice/antiRefundStrategies.slice";
import { axiosJSON } from "src/globals/axiosEndPoints";

export interface FollowUpFormElementType {
  elementId: number;
  elementValue: string;
  elementTypeId: string;
  additionalOptions: string[];
}

export interface OfferType {
  name: string;
  offerType: string;
  offerDescription: string;
  offerSelectedItems: string[];
  offerSelectedTags: string[];
}

export interface AntiRefundStrategiesOrdersTab extends BaseConfigModalTabType {
  refundRequests: {
    reasons: AntiRefundStrategiesReason[];
  };
}

async function getAntiRefundStrategiesOrdersService(
  params: GetConfigModalParams,
) {
  const { data: res } = await axiosJSON.get(
    "/api/setting/automation/refund/step/13/configuration",
    { params },
  );

  if (res.err || res.error) {
    throw new Error(res.msg ?? res.message ?? "Some Error Occurred.");
  }

  return res.data as AntiRefundStrategiesOrdersTab[];
}

export default getAntiRefundStrategiesOrdersService;
