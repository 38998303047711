import { getAvailableDayOptions, getChatSettingData } from "src/services/LiveChat/Settings/getChatSettingData";
import { IUpdateAvailabilityDataParams, updateChatSetting } from "src/services/LiveChat/Settings/updateChatSettingData";
import { RootState } from "src/store/store";
import { setCustomizationData } from "../customization/customizationSettings.slice";

const fetchAvailabilityDataThunk = async (
  payload: number|string,
  { getState, dispatch }: { getState: Function; dispatch: Function }
) => {
  const currentState: RootState = getState();

  let availableDayOptions = currentState.availabilitySettings.availableDayOptions;
  if (availableDayOptions.availableDayOptionKeys.length === 0) {
    availableDayOptions = await getAvailableDayOptions({integrationId: payload});
  }
  const availabilityData = await getChatSettingData({
    integrationId: payload,
    availability: true,
    customization: true
  });

  if(availabilityData?.customization){
    dispatch(setCustomizationData(availabilityData.customization));
  }

  return {availabilityData ,availableDayOptions };
};

interface UpdateAvaliabilityParams extends IUpdateAvailabilityDataParams{
  integrationId: number|string;
  callback?: ()=> void;
}

async function updateAvailabilityDataThunk(
  {callback, integrationId,...payload}: UpdateAvaliabilityParams,
  { getState }: { getState: Function }
) {
  const currentState: RootState = getState();
  if (currentState.availabilitySettings.availabilityData === null) {
    throw "something went wrong!";
  }
  const availabilityData = await updateChatSetting({
    integrationId: integrationId,
    availability: payload,
  });
  if((callback)){
    setTimeout(()=>{
      callback();
    },0);
  }

  return availabilityData;
}

export default { fetchAvailabilityDataThunk,updateAvailabilityDataThunk};
