import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createTicketFromChat,
  ICreateTicketFromChatParams,
} from "src/services/LiveChat/createTicket/createTicketFromChat.service";
import {
  getLiveChatThread,
  IGetLiveChatThreadParams,
} from "src/services/LiveChat/createTicket/getLiveChatThread.service";
import { RootState } from "src/store/store";
import { setTicketData } from "../chatWidExchMsg/chatWidExchMsg.slice";

// thunk to fetch live chat thread
export const fetchLiveChatThreadThunk = createAsyncThunk(
  "liveChatTabQuickActionsSlice/fetchLiveChatThreadThunk",
  async (
    payload: { params: IGetLiveChatThreadParams; callBack: Function },
    { getState, rejectWithValue }: { getState: Function; rejectWithValue: any },
  ) => {
    try {
      const data = await getLiveChatThread(payload.params);
      payload.callBack();
      return data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
);

interface ICreateTicketFromChatParam extends ICreateTicketFromChatParams {
  callback?: (ticketData: any) => void;
}
// thunk to create ticket
export const createLiveChatTicketThunk = createAsyncThunk(
  "liveChatTabQuickActionsSlice/createLiveChatTicketThunk",
  async (
    payload: ICreateTicketFromChatParam,
    {
      getState,
      rejectWithValue,
      dispatch,
    }: { getState: Function; rejectWithValue: any; dispatch: Function },
  ) => {
    try {
      const data = await createTicketFromChat(payload);

      // dispatch(setTicketData({ ticketData: { id: data.ticket_id } }));

      setTimeout(() => {
        if (payload.callback !== undefined) {
          payload.callback({
            chatId: payload.chatRequestId,
            ticketId: data.ticket_id,
          });
        }
      }, 0);
      return data;
    } catch (e: any) {
      console.error(e);
      if (typeof e == "object") {
        if (e.statusCode && e.statusCode === "integrationFailure") {
          return rejectWithValue(e);
        }
      }
    }
  },
);
