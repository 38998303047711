import SearchBar from "src/components/SearchBar/SearchBar";
import styles from "./SearchBarFilter.module.scss";
import { Dropdown } from "react-bootstrap";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  fetchAllOrders,
  resetAllOrders,
  resetAllOrdersSpecificData,
  setSearchTerm,
  setSortBy,
} from "src/store/slices/allOrders/allOrders.slice";
import { v4 as uuidv4 } from "uuid";

interface ISortOption {
  name: string;
  key: string;
}

const SearchBarFilter = () => {
  const dispatch = useAppDispatch();
  const { sortBy } = useAppSelector((state) => state.allOrders);
  const [searchText, setSearchText] = useState("");
  const sortOptionsList: Array<ISortOption> = [
    {
      name: "Order name",
      key: "ORDER_NAME",
    },
    {
      name: "Order date",
      key: "ORDER_DATE",
    },
    {
      name: "Payment status",
      key: "PAYMENT_STATUS",
    },
    {
      name: "Fulfillment status",
      key: "FULFILLMENT_STATUS",
    },
  ];
  const [sortOptions, setSortOptions] =
    useState<Array<ISortOption>>(sortOptionsList);

  const handleSearch = () => {
    // clears all orders data except customer data, search term, sort by
    dispatch(resetAllOrdersSpecificData());
    dispatch(setSearchTerm({ serachTerm: searchText }));
    dispatch(fetchAllOrders());
  };

  const handleSortingDropdownClick = (e: any, sortOption: any) => {
    dispatch(resetAllOrdersSpecificData());
    dispatch(setSortBy({ sortBy: sortOption }));
    dispatch(fetchAllOrders());
  };

  return (
    <div className={`d-flex align-items-center flex-wrap`}>
      <div className={`${styles.searchDiv}`}>
        <SearchBar
          className={`${styles.search} px-2`}
          inputClassName={`${styles.input}`}
          placeholder={`Search order`}
          value={searchText}
          onChange={(e: any) => {
            setSearchText(e.target.value);
          }}
          onSearch={(value: any) => {
            handleSearch();
          }}
        />
      </div>
      {/* <div className={`ms-2 ${styles.filterDiv}`}>
        <Dropdown>
          <Dropdown.Toggle
            id="dropdown-basic"
            className={`${styles.allOrderDay} ${styles.hideArrow2} ${styles.hideArrow}`}
          >
            <span>
              <i className="fa-solid fa-bars-filter"></i>
              <i className="fa-solid fa-arrow-down-short-wide"></i>
            </span>{" "}
            Filter
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              href="#/action-1"
              className={`${styles.openDropDown}`}
            >
              Action
            </Dropdown.Item>
            <Dropdown.Item
              href="#/action-2"
              className={`${styles.openDropDown}`}
            >
              Another action
            </Dropdown.Item>
            <Dropdown.Item
              href="#/action-3"
              className={`${styles.openDropDown}`}
            >
              Something else
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className={`${styles.filterDiv}`}>
        <Dropdown>
          <Dropdown.Toggle
            id="dropdown-basic"
            className={`${styles.allOrderDay} ${styles.hideArrow}`}
          >
            <span>
              <i className="fa-solid fa-table-columns"></i>
            </span>{" "}
            Coloumns
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              href="#/action-1"
              className={`${styles.openDropDown}`}
            >
              Action
            </Dropdown.Item>
            <Dropdown.Item
              href="#/action-2"
              className={`${styles.openDropDown}`}
            >
              Another action
            </Dropdown.Item>
            <Dropdown.Item
              href="#/action-3"
              className={`${styles.openDropDown}`}
            >
              Something else
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div> */}

      <div className={`${styles.filterDiv} ms-2`}>
        <Dropdown>
          <Dropdown.Toggle
            id="dropdown-basic"
            className={`${styles.allOrderDay} ${styles.hideArrow}`}
          >
            <span>
              <i className="fa-solid fa-arrow-down-wide-short"></i>
            </span>{" "}
            Sort
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {sortOptions.map((sortOption: ISortOption) => {
              return (
                <Dropdown.Item
                  // href="#/action-1"
                  key={uuidv4()}
                  active={sortOption.key === sortBy}
                  onClick={(e: any) => {
                    handleSortingDropdownClick(e, sortOption.key);
                  }}
                  className={`${styles.openDropDown}`}
                >
                  {sortOption.name}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </div>

      {/* <div className={`${styles.filterDiv}`}>
        <Dropdown>
          <Dropdown.Toggle
            id="dropdown-basic"
            className={`ms-2 ${styles.allOrderDay} ${styles.hideArrow} ${styles.hideArrow3}`}
          >
            <span>
              <i className="fa-solid fa-ellipsis"></i>
            </span>{" "}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              href="#/action-1"
              className={`${styles.openDropDown}`}
            >
              Action
            </Dropdown.Item>
            <Dropdown.Item
              href="#/action-2"
              className={`${styles.openDropDown}`}
            >
              Another action
            </Dropdown.Item>
            <Dropdown.Item
              href="#/action-3"
              className={`${styles.openDropDown}`}
            >
              Something else
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div> */}
    </div>
  );
};
export default SearchBarFilter;
