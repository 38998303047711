import { getAllLanguages } from "src/services/LiveChat/Settings/getAllLanguages";
import { getChatSettingData } from "src/services/LiveChat/Settings/getChatSettingData";
import { RootState } from "src/store/store";

const fetchCustomizationDataThunk = async (
  payload: number|string,
  { getState, dispatch }: { getState: Function; dispatch: Function }
) => {
  const currentState: RootState = getState();
  let languagesList = currentState.customizationSettings.languagesList;
  if (languagesList.languageIds.length === 0) {
    languagesList = await getAllLanguages();
  }

  const customizationData = await getChatSettingData({
    integrationId: payload,
    customization: true,
  });
  return {customizationData, languagesList};
};

export default { fetchCustomizationDataThunk};
