import { ReactElement } from "react";
import { JsxElement } from "typescript";
import styles from "./Timeline.module.scss";

type TimelineElementActiveType =
  | "success"
  | "danger"
  | "success active"
  | "danger active";

export interface TimelineItem {
  title: JSX.Element;
  subtitle: JSX.Element;
  content: JSX.Element;
  dotActiveType?: TimelineElementActiveType;
  lineActiveType?: TimelineElementActiveType;
}

export interface TimelineItemActive {
  dotActiveType?: TimelineElementActiveType;
  lineActiveType?: TimelineElementActiveType;
}

type BaseTimelineItems = TimelineItem[];

type FnRenderTimelineItem<T> = (timelineItem: T) => ReactElement;

interface TimelineProps<T> {
  timelineItems: BaseTimelineItems | T[];
  renderTimelineItems?: FnRenderTimelineItem<T>;
  isNested?: boolean;
}

const TimelineWrapper: React.FC<{ isNested?: boolean; children: any }> = (
  props
) => {
  if (props.isNested) {
    return <>{props.children}</>;
  } else {
    return <ul className={`${styles.timeline}`}>{props.children}</ul>;
  }
};

TimelineWrapper.defaultProps = {
  isNested: false,
};

function getStyleClassesForActiveType(item: {
  dotActiveType?: TimelineElementActiveType;
  lineActiveType?: TimelineElementActiveType;
}) {
  console.log({ item });
  const dotActiveType =
    item.dotActiveType !== undefined ? item.dotActiveType : "";
  const dotActiveStyleClass = dotActiveType
    .split(" ")
    .map((_class) => {
      const foundClass =
        _class.length > 0
          ? _class[0].toLocaleUpperCase() + _class.substring(1)
          : "";
      console.log({ foundClass });
      return styles[`dot${foundClass}`];
    })
    .join(" ")
    .trim();

  const lineActiveType =
    item.lineActiveType !== undefined ? item.lineActiveType : "";
  const lineActiveStyleClass = lineActiveType
    .split(" ")
    .map((_class) => {
      const foundClass =
        _class.length > 0
          ? _class[0].toLocaleUpperCase() + _class.substring(1)
          : "";
      console.log({ foundClass });
      return styles[`line${foundClass}`];
    })
    .join(" ")
    .trim();

  console.log({ dotActiveStyleClass, lineActiveStyleClass });
  return lineActiveStyleClass.concat(" ", dotActiveStyleClass);
}

function Timeline<
  T extends {
    dotActiveType?: TimelineElementActiveType;
    lineActiveType?: TimelineElementActiveType;
  }
>({ timelineItems, renderTimelineItems, isNested }: TimelineProps<T>) {
  if (timelineItems && renderTimelineItems === undefined) {
    const baseTimelineItems = timelineItems as BaseTimelineItems;

    return (
      <TimelineWrapper isNested={isNested}>
        {baseTimelineItems.map((item, idx) => {
          const activeStyleClasses = getStyleClassesForActiveType({
            dotActiveType: item.dotActiveType,
            lineActiveType: item.lineActiveType,
          });
          return (
            <li
              key={idx}
              className={`${styles["timeline-item"]} ${activeStyleClasses}`}
            >
              <div>
                <p className={`${styles.itemTitle}`}>{item.title}</p>
                <span className={`${styles.itemSubTitle}`}>
                  {item.subtitle}
                </span>
                <p>{item.content}</p>
              </div>
            </li>
          );
        })}
      </TimelineWrapper>
    );
  }
  if (timelineItems && renderTimelineItems !== undefined) {
    const customTimelineItems = timelineItems as T[];
    return (
      <TimelineWrapper isNested={isNested}>
        {customTimelineItems.map((item, idx) => {
          const activeStyleClasses = getStyleClassesForActiveType({
            dotActiveType: item.dotActiveType,
            lineActiveType: item.lineActiveType,
          });

          return (
            <li
              key={idx}
              className={`${styles["timeline-item"]} ${activeStyleClasses}`}
            >
              <div>{renderTimelineItems(item)}</div>
            </li>
          );
        })}
      </TimelineWrapper>
    );
  }
  return <p>No Items</p>;
}
Timeline.defaultProps = {
  isNested: false,
};
export default Timeline;
