import { Dropdown } from "react-bootstrap";
import styles from "./LiveChatForwardMessage.module.scss";
import forwardImg from "src/assets/images/forward.svg";
import { useCallback, useMemo, useRef, useState } from "react";
import { Message } from "src/services/LiveChat/messageExchange/getMessages";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  SendLiveMsgP,
  sendLiveMsg,
} from "src/store/slices/liveChatSetting/chatWidExchMsg/chatWidExchMsg.thunks";
import {
  convertGmtToUserTimezone,
  formatDateTimeString,
  getMonthNameDateAmPmTime,
  getTimeFromNow,
} from "src/utils/dateLibrary";
import ShowRelativeTime from "src/components/ShowRelativeTime";
import tryAgainIcon from "src/assets/images/tryAgainIcon.png";
import { EChannel } from "src/enums/EChannel";
import { getChannelFAIcon } from "src/utils/utils";
import ParseHTML from "src/components/ParseHTML";
import InnerChatAttachments from "src/components/InnerChatAttachments/InnerChatAttachments";
import { DropDownMenuData } from "src/routes/Ticket/children/MainChat/children/SendMsgCommon/SendMsgCommon";

const LiveChatForwardMessage = ({
  messageText,
  sentBy,
  sendTimeGmt,
  attachments,
  sendTime,
  messageId,
  messageType,
  scheduled,
  senderType,
  messageChannel,
  from,
  to,
}: Message) => {
  const dispatch = useAppDispatch();
  const fetchMessageAjaxStatus = useAppSelector(
    (state) => state.chatWidExhMsg.fetchMsgAJAXStatus,
  );
  const sendMessageAjaxStatus = useAppSelector(
    (state) => state.chatWidExhMsg.sendMsgAJAXStatus,
  );
  const activeCustomerInfo = useAppSelector(
    (state) => state.chatWidExhMsg.activeCustomerInfo,
  );
  const userTimezone = useAppSelector(
    (state) => state.globals.currentUserData?.userTimezone,
  );

  const messageStatus = useAppSelector((state) =>
    messageId
      ? state.chatWidExhMsg.messages[messageId]?.messageStatus
      : undefined,
  );

  // This is used to send the message again when the user clicks on the "Try Again" button
  const tryAgainMessagePayload: SendLiveMsgP = useMemo(() => {
    return {
      chat_id: activeCustomerInfo ? activeCustomerInfo.chatId : "",
      message: messageText,
      messageType: messageType as any,
      attachmentBatchNumber: attachments?.map((attachment) => {
        return attachment.attachmentBatchNumber || attachment.batchNumber;
      }),
      attachments: attachments,
      uuid: messageId as string,
      channel: messageChannel,
      to: messageChannel === EChannel.email ? to?.email : undefined,
    };
  }, [
    activeCustomerInfo,
    messageText,
    fetchMessageAjaxStatus,
    messageId,
    sendMessageAjaxStatus,
    messageChannel,
    to,
  ]);

  // This is the function that is called when the user clicks on the "Try Again" button
  const handleSendTryAgainMessage = useCallback(() => {
    const unsentMessage = JSON.parse(
      localStorage.getItem("unsentMessagesLiveChat") || "[]",
    );

    const unsentMessageIndex = unsentMessage.findIndex(
      (msg: any) => msg.uuid === messageId,
    );

    if (unsentMessageIndex !== -1) {
      unsentMessage.splice(unsentMessageIndex, 1);
      localStorage.setItem(
        "unsentMessagesLiveChat",
        JSON.stringify(unsentMessage),
      );
    }

    // messageStatus = "pending";
    dispatch(
      sendLiveMsg({
        ...tryAgainMessagePayload,
        tryAgain: true,
      }),
    );
    // dispatch(messageSendTryAgainPressed());//commented this dispatch as it is not needed, try again is hided based on message status
  }, [dispatch, messageId, tryAgainMessagePayload]);

  const statusRendering = (status: boolean) => {
    if (status) {
      if (messageStatus === "pending") {
        return (
          <span
            className={`d-flex justify-content-end mt-1 ${styles.sending} ${styles.processingText}`}
          >
            {messageChannel ? "sending via " + messageChannel : "sending"}...
          </span>
        );
      } else {
        return "";
      }
    } else {
      if (scheduled?.createdAtUTC) {
        return (
          <span className={`${styles.time}`}>
            {`Scheduled by ${
              scheduled.scheduledAgent.name ?? "NA"
            } on ${getMonthNameDateAmPmTime(
              formatDateTimeString(
                new Date(scheduled.createdAtUTC + "Z").toLocaleString("en-US", {
                  timeZone: userTimezone,
                }),
              ),
            )}`}
          </span>
        );
      } else if (sendTimeGmt?.trim()) {
        return (
          <div className="d-flex align-items-center">
            <div>
              <Dropdown
                show={show}
                onToggle={setShow}
              >
                <Dropdown.Toggle
                  id="dropdown-basic"
                  as="div"
                  ref={dropdownRef}
                  bsPrefix={`dropdown-toggle cursor-pointer ${styles.sendDrop}`}
                >
                  {`${getTimeFromNow({
                    date: new Date(
                      convertGmtToUserTimezone(sendTimeGmt, userTimezone),
                    ),
                    timeZone: userTimezone,
                  })}${
                    messageChannel
                      ? `, Via: ${
                          messageChannel === EChannel.instagram
                            ? "Instagram DM"
                            : from?.email
                        }`
                      : ""
                  }`}
                  <span ref={dropdownArrowSpanRef}>
                    {" "}
                    {show ? (
                      <i className={`fa-solid fa-caret-up`}></i>
                    ) : (
                      <i className={`fa-solid fa-caret-down`}></i>
                    )}
                  </span>
                </Dropdown.Toggle>
                <DropDownMenuData
                  messageData={
                    {
                      from: from?.email,
                      to: to?.email,
                      messageDateTime: convertGmtToUserTimezone(
                        sendTimeGmt,
                        userTimezone,
                      ),
                    } as any
                  }
                  labelBy={"dropdownMenuButton2"}
                />
              </Dropdown>
            </div>
            <div>
              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-basic"
                  as="span"
                  ref={dropdownRef}
                  bsPrefix={`dropdown me-1 ${styles.insta}`}
                >
                  <i
                    className={`d-block cursor-pointer dropdown-toggle ${getChannelFAIcon(
                      messageChannel,
                    )} override ${styles.dropdownToggle}`}
                    id={`dropdownMenuButton1`}
                    data-bs-toggle={`dropdown`}
                    aria-expanded={`false`}
                  ></i>{" "}
                </Dropdown.Toggle>
                <DropDownMenuData
                  messageData={
                    {
                      from: from?.email,
                      to: to?.email,
                      messageDateTime: convertGmtToUserTimezone(
                        sendTimeGmt,
                        userTimezone,
                      ),
                    } as any
                  }
                  labelBy={"dropdownMenuButton1"}
                />
              </Dropdown>
            </div>
          </div>
        ); // in: message received, out: message sent passed as prop // true: message seen, false: message not seen
      } else {
        return "";
      }
    }
  };

  // This is the function that is called when the message fails to send due to a network error
  const handleSendFailedMessage = useCallback(() => {
    if (tryAgainMessagePayload) {
      const unsentMessagesString = localStorage.getItem(
        "unsentMessagesLiveChat",
      );
      const unsentMessages = unsentMessagesString
        ? JSON.parse(unsentMessagesString)
        : [];
      // Check if the array already contains a message with the same messageId
      const existingMessageIndex = unsentMessages.findIndex(
        (msg: any) => msg.uuid === messageId,
      );
      if (existingMessageIndex === -1) {
        // If the array does not contain a message with the same messageId, push the new message into the array
        unsentMessages.push({
          ...tryAgainMessagePayload,
          tryAgain: true,
        });
        // Convert the updated array into a string and store in localStorage
        localStorage.setItem(
          "unsentMessagesLiveChat",
          JSON.stringify(unsentMessages),
        );
      }
    }
  }, [messageId, tryAgainMessagePayload]);

  const tryAgainRendering = () => {
    handleSendFailedMessage();
    return (
      <div className={`small text-secondary`}>
        <span className={`${styles.failedText}`}>Failed....</span>
        <button
          id="tryAgain"
          className={` border-0 bg-white ${styles.tryText}`}
          onClick={() => {
            handleSendTryAgainMessage();
          }}
        >
          <img
            src={tryAgainIcon}
            alt=""
            className="me-1"
          />
          Try again
        </button>
      </div>
    );
  };

  const [show, setShow] = useState(false);
  const dropdownRef = useRef<any>();
  const dropdownArrowSpanRef = useRef<any>();
  return (
    <div className={`${styles.boxWrapper}`}>
      <div className="">
        <div
          className={`${styles.chatWrapper} ${
            messageStatus !== "pending" && messageStatus !== "rejected"
              ? ""
              : styles.pending
          }`}
        >
          {/* Conditionally render the header only if the message status is not pending or rejected */}
          {messageStatus !== "pending" && messageStatus !== "rejected" ? (
            <div className={`${styles.header}`}>
              <span className={`pe-2 ${styles.forwardText}`}>
                Forwarded to {`${to?.name} (${to?.email})`}
              </span>
              <img
                src={forwardImg}
                alt="forward"
                width={12}
                height={12}
              />
            </div>
          ) : null}
          <div className={`py-2 ${styles.bodyWrapper}`}>
            <ParseHTML
              html={messageText}
              //  replaceNewLineWithBr={true}
            />

            {attachments && attachments.length > 0 && (
              <div className="mt-2">
                <InnerChatAttachments
                  messageData={{
                    attachments: attachments,
                    senderName: sentBy.name,
                    messageDateTime: sendTime,
                    messageId: messageId,
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <div className="d-flex justify-content-end mt-1">
          {messageStatus === "pending"
            ? statusRendering(true)
            : messageStatus === "rejected"
              ? tryAgainRendering()
              : statusRendering(false)}
        </div>
      </div>
    </div>
  );
};

export default LiveChatForwardMessage;
