import { axiosJSON } from "src/globals/axiosEndPoints";

export interface IStepData {
  step_id: number;
  stepQuestions: any;
}

export interface IReturnAutomationBulkStepUpdateParams {
  integrationId: number;
  step_data: Array<IStepData>;
}

export interface IReturnAutomationBulkStepUpdateRes {}

export async function bulkStepUpdate(
  payload: IReturnAutomationBulkStepUpdateParams,
) {
  const { data } = await axiosJSON.post(
    `/api/setting/automation/refund/updateQuestions`,
    payload,
  );

  if (data.err === true) {
    throw new Error("Some Error Occurred while updating data");
  }

  return data.data as IReturnAutomationBulkStepUpdateRes;
}
