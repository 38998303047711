import { createSelector } from "@reduxjs/toolkit";
import {
  Customer,
  Customers,
} from "src/services/LiveChat/messageExchange/getAllCustomers";
import { RootState } from "src/store/store";

export const selectAllCustomers = (state: RootState) => {
  return Object.values(state.chatWidExchCust.customers);
};

const getAllCustomers = (state: RootState) => {
  return state.chatWidExchCust.customers;
};

const getSelectedCustomerChatId = (state: RootState) => {
  return state.chatWidExchCust.selectedCustomerChatId;
};

export const selectedCustomerSelector = createSelector(
  [getAllCustomers, getSelectedCustomerChatId],
  (customers: Customers, selectedCustomerChatId: string | number) => {
    const allCustomers = Object.values(customers);

    if (allCustomers.length === 0) {
      return null;
    } else {
      const selectedCustomer = allCustomers.find(
        (customer) => customer.chatId == selectedCustomerChatId
      );
      return selectedCustomer !== undefined ? selectedCustomer : null;
    }
  }
);

// memoize previous result of function
// getAllCustomer returns same reference of customers than output function will no run again
export const allCustomersSelector = createSelector(
  // Input function
  [getAllCustomers],
  // Output function
  (customers: Customers) => {
    return Object.values(customers);
  }
);

export const getSelectedCustomerStatus = (state: RootState) => {
  return state.chatWidExchCust.selectedCustomerStatus;
};

export const selectCustomersByStatus = createSelector(
  [allCustomersSelector, getSelectedCustomerStatus],
  (customers, customerStatus) => {
    return customers.filter((customer) => {
      if (customer.customerStatus === customerStatus) {
        return customer;
      }
    });
  }
);

export function isAgentIdSelectedForFilter(
  state: RootState,
  agentId: number | string
) {
  const selectedAgentIdsForFilter =
    state.chatWidExchCust.selectedAgentIdsForFilter;
  return (
    selectedAgentIdsForFilter && selectedAgentIdsForFilter.includes(agentId)
  );
}

export function areAllAgentSelectedForFilter(state: RootState): boolean {
  if (state.chatWidExchCust.agentIds.length > 0) {
    const allAgentIds = state.chatWidExchCust.agentIds;
    const allSelectedAgentIds = state.chatWidExchCust.selectedAgentIdsForFilter;
    return (
      allAgentIds.length !== 0 &&
      allAgentIds.every(
        (u) => allSelectedAgentIds && allSelectedAgentIds.includes(u)
      )
    );
  } else {
    return false;
  }
}

// Function to sort chat IDs based on the last message time in descending order
export function sortChatByMessageDate(state: RootState) {
  // Check if there are no chat IDs in the state
  if (state.chatWidExchCust.chatIdList.length === 0) {
    return [];
  }

  // Separate chats into two arrays based on chatStatus
  const botChats: Array<string> = [];
  const chats: Array<string> = [];

  // Get the customer data from the state
  const chatData = state.chatWidExchCust.customers;

  // Iterate through each customer in the customer data
  for (const key in chatData) {
    const ele = chatData[key];
    // Create a string combining the lastMessageTime and chatId for sorting
    const dateString = `${ele.lastMessageTime}__${ele.chatId}`;

    if (
      state.chatWidExchCust.selectedCustomerStatus === "live" &&
      ele.chatStatus === "chat_in_progress_ai"
    ) {
      botChats.push(dateString);
    } else {
      chats.push(dateString);
    }
  }

  // Sort the chats array in descending order
  const sortedChats = chats.sort().reverse();

  // Sort the bot chats array in descending order
  const sortedBotChats = botChats.sort().reverse();

  // Concatenate the sorted arrays
  const ret = sortedChats
    .concat(sortedBotChats)
    .map((ele) => parseInt(ele.split("__")[1])); // Extract chatId and convert to numbers

  // Return the sorted chat IDs as an array of numbers or strings
  return ret as Array<number | string>;
}
