import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";

const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/botProfile/getBrowserLanguageList`
  : "/api/botProfile/getBrowserLanguageList";

export interface Language {
  label: string;
  value: string;
}

export const getBrowserLanguageList = async () => {
  const { data: res } = await axiosJSON.get(apiEndPoint);
  if (res.err) {
    throw res.msg;
  }
  return res.data as Language[];
};
