import { axiosJSON } from "src/globals/axiosEndPoints";
import { validateEmail } from "src/utils/utils";

export interface IAddCustomerApi {
  email: string;
  name: string;
}
export async function addCustomerApi(customerData: IAddCustomerApi) {
  if (!validateEmail(customerData.email)) {
    throw "E-mail not valid!";
  }
  if (customerData.name.length === 0) {
    throw "Name empty!";
  }
  try {
    const { data } = await axiosJSON.post(`/api/customer/addNew`, customerData);

    if (data.err) {
      if (data.statusCode === "email_already_used") {
        throw data.msg ?? data.message ?? "Email already exists";
      }
      throw "Something went wrong!";
    } else {
      return data.data;
    }
  } catch (e) {
    throw typeof e === "string" ? e : "Something went wrong!";
  }
}
