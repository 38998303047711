import Loader from "src/components/Loader";
import { GetOrderViewModel } from "src/services/ShopifySidebar/SingleOrder/BasicOrder/getOrderViewModel";

import { useAppSelector } from "src/store/store";
import styles from "./Paid.module.scss";

function Paid() {
  const {
    loading,
    error,
    getOrderViewModel: order,
  } = useAppSelector((state) => state.singleOrder);
  function renderData(order: GetOrderViewModel) {
    // console.log("Paid Component:: order:: ", JSON.stringify(order));
    return (
      <div className={`${styles.Paid} d-flex flex-column mb-3  ms-2`}>
        <div className="d-flex flex-row aling-items-center ms-2 mt-3 ps-3">
          <div>
            <span>
              <i className={`fa-solid fa-check ${styles.paidIcon}`}></i>
            </span>
          </div>
          <div className={`${styles.lead} ms-3`}>Paid</div>
        </div>

        <div
          className={`row ${styles.lead} ${styles.paidDiv} flex-row mt-3 justify-content-between ms-4`}
        >
          <div
            className={`col-md-5 col-lg-5  ${styles.lead} ${styles.paidColumnName} flex-column `}
          >
            <div className={`${styles.tableData} mt-2`}>Discount</div>
            <div className={`${styles.tableData} mt-2`}>Subtotal</div>
            <div className={`${styles.tableData} mt-2`}>Shipping</div>
            <div className={`${styles.tableData} mt-2`}>Tax</div>
            <div className={`${styles.tableData} mt-2`}>Total</div>
          </div>

          <div
            className={`col-md-5 col-lg-5 ${styles.paidDetail} d-flex flex-column`}
          >
            <div className={`${styles.tableData} mt-2`}>
              {/* {order?.paymentDetails?.discount?.title} */}
              {order.paymentDetails?.discount.title !== null
                ? order?.paymentDetails?.discount?.title
                : "NA"}
            </div>
            <div className={`${styles.tableData} mt-2 `}>
              {order?.paymentDetails?.subtotalItemCount + " item(s)"}
            </div>
            <div className={`${styles.tableData} mt-2 `}>
              {order.paymentDetails?.shipping !== null
                ? order.paymentDetails?.shipping?.title
                : "NA"}
            </div>
            <div className={`${styles.tableData} mt-2 `}>
              {order?.paymentDetails?.tax !== null
                ? order?.paymentDetails?.tax[0]?.title
                : "NA"}
            </div>
          </div>

          <div
            className={`col-md-2 col-lg-2 ${styles.paidAmountDetail} d-flex flex-column`}
          >
            <div className={`${styles.tableData} mt-2`}>
              {order?.paymentDetails?.discount?.currencyCode}{" "}
              {order?.paymentDetails?.discount?.amount}
            </div>
            <div className={`${styles.tableData} mt-2`}>
              {order?.paymentDetails?.subtotal?.currencyCode}{" "}
              {order?.paymentDetails?.subtotal?.amount}
            </div>
            <div className={`${styles.tableData} mt-2`}>
              {/* {order?.paymentDetails?.shipping?.amount?.currencyCode}{" "}
              {order?.paymentDetails?.shipping?.amount?.amount} */}
              {order.paymentDetails?.shipping !== null
                ? order.paymentDetails?.shipping?.amount.currencyCode
                : "NA"}
              {order.paymentDetails?.shipping !== null &&
                order.paymentDetails?.shipping?.amount.amount}
            </div>
            <div className={`${styles.tableData} mt-2`}>
              {order.paymentDetails?.tax !== null
                ? order.paymentDetails?.tax[0].currencyCode
                : ""}
              {order.paymentDetails?.tax !== null &&
                order.paymentDetails?.tax[0].amount}
            </div>
            <div className={`${styles.tableData} mt-2`}>
              {order?.paymentDetails?.totalPrice?.currencyCode}{" "}
              {order?.paymentDetails?.totalPrice?.amount}
            </div>
          </div>
        </div>

        <div>
          <hr></hr>
        </div>

        <div
          className={`row ${styles.lead} ${styles.paidByCustomer} mb-3 ps-0 ps-lg-4`}
        >
          <div className={`col-8 col-lg-10  ${styles.paidByCustomerText}`}>
            Paid by customer
          </div>

          <div className={`col-4 col-lg-2 ${styles.finalAmount}`}>
            {order?.paymentDetails?.amountPaidByCustomer?.currencyCode}{" "}
            {order?.paymentDetails?.amountPaidByCustomer?.amount}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      {loading && <Loader />}
      {!loading && error ? <div>Something went wrong!</div> : null}
      {!loading && order && renderData(order)}
    </div>
  );
}
export default Paid;
