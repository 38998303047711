import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";

import saufterImg from "src/assets/images/saufter.png";
import { DropDownMenuData } from "../SendMsgCommon/SendMsgCommon";
import { getTimeFromNow } from "src/utils/dateLibrary";
import { useAppSelector } from "src/store/store";
import { IMessageData } from "src/store/slices/innerTicket/innerTicket.slice";

import styles from "./BotMessage.module.scss";
import AnswerSourceModal from "src/components/AnswerSourceModal/AnswerSourceModal";
import CopyPasteDraft from "../CopyPasteDraft/CopyPasteDraft";
import ParseHTML from "src/components/ParseHTML";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";

/**
 * Component for showing Message Details below Bot Message
 */
const MessageDetails = ({ messageData }: { messageData: IMessageData }) => {
  const userTimezone = useAppSelector(
    (state) => state.globals.currentUserData?.userTimezone,
  );
  const dropdownRef = useRef<any>();
  const [show, setShow] = useState(false);

  return (
    <div className={`${styles.created} d-flex justify-content-end`}>
      <div
        className={`dropdown d-flex justify-content-end`}
        ref={dropdownRef}
      >
        <Dropdown onToggle={setShow}>
          <Dropdown.Toggle
            id="dropdown-basic"
            as="div"
            ref={dropdownRef}
            bsPrefix={`dropdown-toggle cursor-pointer ${styles.sendDrop} ${styles.dropdownToggle}`}
          >
            {`${getTimeFromNow({
              date: new Date(messageData.messageDateTime),
              timeZone: userTimezone,
            })}, ${
              messageData.messageType === "DraftMessageBot" ? "draft " : ""
            } response by ${messageData.botInfo?.name} ${
              messageData.messageType === "MessageBot"
                ? messageData.botInfo?.brandName
                : ""
            }
          `}
            <span>
              {" "}
              {show ? (
                <i className={`fa-solid fa-caret-up`}></i>
              ) : (
                <i className={`fa-solid fa-caret-down`}></i>
              )}
            </span>
          </Dropdown.Toggle>
          <DropDownMenuData
            messageData={messageData}
            labelBy={"dropdownMenuButton2"}
          />
        </Dropdown>
      </div>
    </div>
  );
};

/**
 * Component to render the Feedback Form of Bot Email
 */
const FeedbackForm = ({
  status,
  source,
  botName,
}: {
  status: "resolved" | "connectToHuman" | null;
  botName: string;
  source?: string;
}) => {
  return (
    <div className="mt-2">
      {/* Header */}
      <div className="d-flex justify-content-between">
        {/* Heading  */}
        <p className={`mb-1 ${styles.queryHead}`}>
          Was your query resolved by {botName} AI?
        </p>

        {/* Answer Source */}
        {source && (
          <span
            className={`${styles.lead}`}
            role="button"
            onClick={() => {
              window.open(source, "_blank", "noreferrer");
            }}
          >
            Answer Source{" "}
            <span className="ps-1">
              <i className="fa-solid fa-up-right-from-square"></i>
            </span>
          </span>
        )}
      </div>

      {/* Info */}
      <h5 className={`${styles.answer}`}>
        This response is created and sent by a AI bot and responses may not be
        accurate please rate this response and an human will get in touch with
        you soon to provide resolution
      </h5>

      {/* Status Buttons */}
      <div className={`${styles.buttonWrapper}`}>
        {/* Resolved Button */}
        <span
          className={`${styles.cernBtn} ${
            status === "resolved" ? styles.selectedCernBtn : ""
          }`}
        >
          {status === "resolved" && (
            <span className="pe-1">
              <i className="fa-solid fa-check"></i>
            </span>
          )}{" "}
          This resolves my enquiry
        </span>

        {/* Connect to Human Button */}
        <span
          className={`${styles.cernBtn} ${
            status === "connectToHuman" ? styles.selectedConnectnBtn : ""
          }`}
        >
          {status === "connectToHuman" && (
            <span className="pe-1">
              <i className="fa-solid fa-check"></i>
            </span>
          )}{" "}
          Connect me to a human
        </span>
      </div>

      {/* Status */}
      <div className={`text-center mt-2 ${styles.waitingText}`}>
        {status === null ? (
          "Waiting for response from receiver"
        ) : (
          <>
            Customer rated this message as{" "}
            <span className={`${styles.resolvedText}`}>
              {status === "resolved" ? "Resolved" : "Connect me to human"}
            </span>
          </>
        )}
      </div>
    </div>
  );
};

/**
 * Component to render Follow Up Mail message
 */
const FollowUpStatus = ({
  status,
  botName,
}: {
  status: "resolved" | "connectToHuman";
  botName: string;
}) => {
  return (
    <div className="mt-2">
      <span className={`text-start ${styles.waitingText}`}>
        <span>
          This is a follow-up mail by {botName} as customer rated {botName}
          's resolution as
        </span>

        <span className={`d-block ${styles.resolvedText}`}>
          {/* {status === "resolved" ? "Resolved" : "Connect me to human"} */}
          Connect me to human
        </span>
      </span>
    </div>
  );
};

/**
 * Must be same as in BotMessage.module.scss
 */
const MESSAGE_LINE_HEIGHT = 18;
const MAX_LINE_NUMBER = 5;

/**
 * Component for rendering the AI Handled Chat Message
 */
const BotMessage = ({ messageData }: { messageData: IMessageData }) => {
  // Hide the view more initially as we use the message height to determine if we need to show view more
  const [viewMore, setViewMore] = useState<boolean>(false);
  const [showModal, setShowModal] = useState(false);
  const messageRef = useRef<HTMLParagraphElement>(null);

  // Bot Name with Capital First Letter
  const botName = useMemo(
    () =>
      messageData.botInfo?.name
        ? messageData.botInfo?.name.charAt(0).toUpperCase() +
          messageData.botInfo?.name.slice(1)
        : "Cern",
    [messageData.botInfo?.name],
  );

  const onHide = () => {
    setShowModal(false);
  };

  /**
   * Effect to update View more if message is longer than LINE_NUMBER lines
   */
  useEffect(() => {
    if (messageRef.current) {
      const height = messageRef.current.offsetHeight;
      // If message paragraph has a height of more than 5 lines
      if (height > MESSAGE_LINE_HEIGHT * MAX_LINE_NUMBER) {
        setViewMore(true);
      }
    }
  }, [messageData.message]);

  const onAnswerSourceClick = useCallback(() => {
    if (
      messageData.answerSource?.type === "url" &&
      messageData.answerSource?.url
    ) {
      window.open(messageData.answerSource?.url, "_blank");
    } else if (
      messageData.answerSource?.type === "customQA" &&
      messageData.answerSource?.questions &&
      messageData.answerSource.questions.length > 0
    ) {
      setShowModal(true);
    } else {
      pushTheToast({
        position: "top-right",
        type: "warning",
        text: "We don't have that information at the moment.",
      });
    }
  }, [
    messageData.answerSource?.questions,
    messageData.answerSource?.type,
    messageData.answerSource?.url,
  ]);

  return (
    <div className={`d-flex justify-content-start flex-row-reverse`}>
      {/* Profile Image */}
      <div
        className={`d-flex flex-column align-items-center ${styles.profileSec} `}
      >
        <div className={`${styles.imgWrap}`}>
          <img
            src={saufterImg}
            alt=""
            className={`${styles.profileIcon}`}
          />
        </div>
      </div>

      {/* Message */}
      <div className="d-flex flex-column align-items-end">
        {/* Message Body */}
        <div
          className={`mx-3 ${styles.messageParentEle} ${
            messageData.messageType === "MessageBot" &&
            styles.handleMessageParentEle
          } ${
            messageData.messageType === "DraftMessageBot" &&
            styles.draftMessageParentEle
          }`}
        >
          <div
            style={{
              width: "fit-content",
            }}
          >
            {/* Heading */}
            {messageData.messageType === "MessageBot" ? (
              <span
                className={`mb-1 ${styles.heading} ${styles.handledHeading}`}
              >
                AI handled
              </span>
            ) : messageData.messageType === "DraftMessageBot" ? (
              <span className={`mb-1 ${styles.heading} ${styles.draftHeading}`}>
                AI Drafted
              </span>
            ) : null}

            {/* Message */}
            <p
              ref={messageRef}
              className={`${styles.lead} ${
                viewMore ? `${styles.truncate} ${styles.clamped}` : ""
              }`}
              style={
                messageData.messageType === "MessageBot"
                  ? { color: "#ffffff" }
                  : { color: "#000000" }
              }
            >
              <ParseHTML
                html={messageData.message}
                className={`${viewMore ? styles.clamped : ""}`}
              />
            </p>

            {/* View More Button */}
            {viewMore && (
              <span
                role="button"
                className={`mb-1 ${styles.viewMore}`}
                onClick={() => setViewMore((val) => !val)}
              >
                {" "}
                <span>
                  <i className="fa-solid fa-ellipsis"></i>
                </span>{" "}
              </span>
            )}
            {/* Answer Source Button */}
            {messageData.messageType === "DraftMessageBot" &&
              messageData.answerSource?.type && (
                <div>
                  <span
                    className={`d-inline-block mb-1 ${styles.note} ${styles.answerSource}`}
                    onClick={onAnswerSourceClick}
                  >
                    Answer Source
                    <span className="ps-2">
                      {/* <i className="ps-2 cursor-pointer fa-solid fa-arrow-up-right-from-square"></i> */}
                      <i className="fa-solid fa-up-right-from-square"></i>
                    </span>
                  </span>
                </div>
              )}
            {/* Info and Note */}
            {messageData.messageType === "DraftMessageBot" && (
              <div>
                <div className={`${styles.info} mb-1`}>
                  This response is created and sent by a AI bot and responses
                  may not be accurate
                </div>
                <div className={`${styles.note}`}>
                  Note: This is not sent to the customer and is a draft, only
                  admins and agents see this.
                </div>
              </div>
            )}
            {/* Feedback Section */}
            {messageData.customerFeedback &&
              (messageData.isFollowUpBotMail ? (
                messageData.customerFeedback.feedback !== null ? (
                  <FollowUpStatus
                    status={messageData.customerFeedback.feedback}
                    botName={botName}
                  />
                ) : null
              ) : (
                <FeedbackForm
                  status={messageData.customerFeedback.feedback}
                  source={messageData.botInfo?.sourceURL}
                  botName={botName}
                />
              ))}
          </div>
        </div>

        <div
          className={`d-flex align-items-center mx-3 ${
            messageData.messageType === "DraftMessageBot"
              ? "justify-content-between"
              : "justify-content-end"
          } ${styles.msgDetails}`}
        >
          {messageData.messageType === "DraftMessageBot" && (
            <div className="pe-1 mt-2">
              <CopyPasteDraft draftMessage={messageData.message} />
            </div>
          )}
          {/* Message Details */}
          <MessageDetails messageData={messageData} />
        </div>
        <Modal
          backdropClassName={`${styles.modalBack}`}
          show={showModal}
          onHide={onHide}
          centered
          dialogClassName={`${styles.modalDialog}`}
          contentClassName={`${styles.modalContent}`}
        >
          <AnswerSourceModal
            onHide={onHide}
            messageData={messageData}
          />
        </Modal>
      </div>
    </div>
  );
};

export default BotMessage;
