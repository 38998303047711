import { useRef, useState } from "react";
import { Warning } from "../../UploadCsv";
import { FileDrop } from "react-file-drop";
import uploadImg from "src/assets/images/upload.svg";
import alertImg from "src/assets/images/alert.svg";
import googleSheet from "src/assets/images/googlesheets.svg";
import styles from "./UploadBox.module.scss";

interface Props {
  uploadedAttachmentUrl: null | string;
  uploadedAttachmentName: null | string;
  setShowFileWarning: React.Dispatch<React.SetStateAction<Warning>>;
  showFileWarning: Warning;
  onChangeAttachment: (file: File, abortController?: any) => void;
  attachment?: File | null;
}

const UploadBox = ({
  uploadedAttachmentUrl,
  uploadedAttachmentName,
  setShowFileWarning,
  showFileWarning,
  onChangeAttachment,
  attachment,
}: Props) => {
  const fileRef = useRef<HTMLInputElement | null>(null);
  const [dragClass, setDragClass] = useState("");
  const [attachmentName, setAttachmentName] = useState(
    uploadedAttachmentName ?? "",
  );

  const ALLOWED_FILE_TYPES = ".csv, .txt";
  const SUPPORTED_EXTENSION_TYPES = ["csv", "txt"];

  const filePicker = () => {
    if (fileRef.current) {
      fileRef.current.click();
    } else {
      console.error("fileRef is not initialized");
    }
  };

  const isFileSupported = (fileName: string) => {
    const type = fileName.split(".").pop() || "";
    return SUPPORTED_EXTENSION_TYPES.includes(type.toLowerCase().trim());
  };

  const onFileInputChange = (files: FileList | null, e: any) => {
    if (files) {
      const uploadableFiles = Array.from(files).filter((file) =>
        isFileSupported(file.name),
      );
      if (uploadableFiles.length !== 0) {
        const file = uploadableFiles[0];
        const abortController = new AbortController();
        setAttachmentName(file.name);
        onChangeAttachment(file, abortController);
      } else {
        setShowFileWarning({
          show: true,
          type: "extensionsupport",
        });
      }
    }
  };

  return (
    <>
      <div
        className={`${styles.uploadWrap} mt-4 mx-auto d-flex justify-content-center`}
      >
        <input
          accept={ALLOWED_FILE_TYPES}
          style={{ display: "none" }}
          ref={fileRef}
          type="file"
          onChange={(e) => onFileInputChange(e.target.files, e)}
        />
        {(uploadedAttachmentUrl === null || uploadedAttachmentUrl === "") &&
        !attachment ? (
          <FileDrop
            onTargetClick={filePicker}
            onDrop={(files, e) => {
              e.preventDefault();
              onFileInputChange(files, e);
            }}
            onFrameDragEnter={() => setDragClass(styles.dragging)}
            onFrameDragLeave={() => setDragClass("")}
            onDragOver={() => setDragClass(styles.dragged)}
            onDragLeave={() => setDragClass(styles.dragging)}
            onFrameDrop={() => setDragClass("")}
            className={`${styles.dnd} ${dragClass}`}
          >
            <span className={styles.dndSpan}>
              <div
                className={`d-flex align-items-center justify-content-center cursor-pointer `}
              >
                {!showFileWarning.show ? (
                  <div>
                    <div className="mt-5">
                      <img
                        src={uploadImg}
                        alt="upload"
                      />
                      <span className={`ps-1 ${styles.drag}`}>
                        Drag and drop your CSV file here
                      </span>
                      <p className={`${styles.select}`}>
                        Or select a file from your computer.
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="mt-5 d-flex align-items-center justify-content-center flex-column">
                    <div className="text-center">
                      <img
                        src={alertImg}
                        alt="alert"
                      />
                      <span className={`ps-1 ${styles.errorText}`}>
                        {showFileWarning.type == "extensionsupport"
                          ? `Please upload a .csv file. If you have a spreadsheet,
                        save it as a CSV file before uploading.`
                          : showFileWarning.type === "empty"
                            ? `Please upload a .csv file.`
                            : `Please upload a .csv file. If you have a spreadsheet,
                        save it as a CSV file before uploading.`}
                      </span>
                    </div>
                    <div className="mt-5">
                      <button className={`${styles.prevBtn}`}>Add file</button>
                    </div>
                  </div>
                )}
              </div>
            </span>
          </FileDrop>
        ) : (
          <div className="mt-5">
            <div className="text-center">
              <div>
                <img
                  src={googleSheet}
                  alt="googleSheet"
                />
                <span className={`ps-1 ${styles.drag}`}>
                  {attachmentName ?? ""}
                </span>
              </div>

              <button
                className={`me-2 text-center ${styles.replace}`}
                id="modal_prev_btn"
                onClick={() => {
                  filePicker();
                }}
              >
                <p className={`mt-4 text-center ${styles.replaceFile}`}>
                  Replace file
                </p>
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default UploadBox;
