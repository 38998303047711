/**
 * This file is a component file.
 *
 * @author Jayalakshmi
 * @author Yash Aditya
 */

import { useState } from "react";
import { Dropdown, Spinner } from "react-bootstrap";
import SearchBar from "src/components/SearchBar/SearchBar";
import AgentListItem from "../AgentListItem/AgentListItem";
import useTeamsAndUsers from "../../hooks/useTeamsAndUsers";
import Loader from "src/components/Loader";
import styles from "../../AgentNotification.module.scss"; // Importing styles
import reportGroup from "src/assets/images/ReportGroup.png"; // Importing an image
import AxiosImg from "src/components/AxiosImg";
import UserAvatar from "src/components/UserAvatar";

// Component to update agents and teams
const UpdateAgants = ({ show }: { show: boolean }) => {
  // Destructuring values from custom hook
  const {
    selectedUsers,
    handleUpdate,
    searchText,
    handleSearch,
    loadingUpdateUsers,
    fetchStatus,
    userAndTeams,
    ddOpen,
    handleOnToggle,
    ddLoading,
    showSelectedAgents,
    agentIdsToRender,
    adminIdsToRender,
    teamIdsToRender,
    activeUserIds,
    notifyPermission,
  } = useTeamsAndUsers();

  if (show === false) {
    return <></>; // If show prop is false, return an empty fragment
  }

  /* Display error message if data is rejected */
  if (fetchStatus === "rejected") {
    return (
      <div className="d-flex align-items-center justify-content-center w-100">
        <div className="text-danger text-center">Error loading content...</div>
      </div>
    );
  }

  /* Display loader if data is still pending */
  if (fetchStatus === "pending") {
    return (
      <div className="d-flex align-items-center justify-content-center w-100">
        <Loader
          className="pt-0 mt-0"
          imgClassName="pt-0 mt-0"
        />
      </div>
    );
  }

  return (
    <>
      <div>
        {/* Dropdown for selecting agents and teams */}
        <Dropdown
          bsPrefix="dropdown w-100"
          onToggle={handleOnToggle}
          show={ddOpen}
        >
          {/* Dropdown toggle button */}
          <Dropdown.Toggle
            as="div"
            id="dropdown-basic"
            bsPrefix={`dropdown-toggle d-flex justify-content-between w-100 px-2 ${styles.dropBtn}`}
            style={{
              cursor: notifyPermission === "edit" ? "pointer" : "not-allowed",
            }}
          >
            <div
              className={`d-flex justify-content-start me-2 ${styles.agentsList}`}
            >
              <span className="my-auto">
                <i className="fa-solid fa-user"></i>
              </span>
              {showSelectedAgents.length ? (
                <span className={`d-flex`}>
                  {/* Display selected agents' avatars */}
                  <span className="ms-4 me-2">
                    {showSelectedAgents.map((value, index) => {
                      // Only show images for first 8 agents
                      if (index >= 8) {
                        return null;
                      }

                      if (value.imgURL) {
                        return (
                          <AxiosImg
                            url={value.imgURL}
                            className={`${styles.selectedAgentsImg}`}
                          />
                        );
                      } else {
                        return (
                          <UserAvatar
                            size={100}
                            name={value.label}
                            removePAndM={true}
                            className={`${styles.selectedAgentsImg}`}
                          />
                        );
                      }
                    })}
                  </span>
                  {/* Display selected agents' labels */}
                  <span className={`mx-2 ${styles.selectedAgentsLabel}`}>
                    {showSelectedAgents
                      .map((value) => {
                        return value.label.split(" ")[0];
                      })
                      .join(", ")}
                  </span>
                </span>
              ) : (
                <span className={"ps-2"}>All admin and lead agents</span>
              )}
            </div>
            {/* Display spinner or caret based on loading status */}
            <span>
              {ddLoading ? (
                <Spinner
                  animation="border"
                  size="sm"
                />
              ) : (
                <i
                  className={`fa-solid fa-caret-${ddOpen ? "up" : "down"}`}
                ></i>
              )}
            </span>
          </Dropdown.Toggle>

          {/* Dropdown menu */}
          <Dropdown.Menu bsPrefix={`dropdown-menu px-2 ${styles.dropMenu}`}>
            {/* Search bar for filtering agents and teams */}
            <div className={`${styles.dropdownText} mt-1 mb-2 mx-1`}>
              Only admin and lead agents of all teams and who has acess to all
              brands will receive notifications.
            </div>
            <SearchBar
              className={`${styles.search}`}
              inputClassName={`${styles.input}`}
              placeholder={`Search admin, lead agents or teams`}
              value={searchText}
              onChange={handleSearch}
            />

            {/* Display message if no agents or teams found */}
            {(userAndTeams?.userIds?.length ?? 0) === 0 &&
              (userAndTeams?.teamIds?.length ?? 0) === 0 && (
                <span className={`ps-2 ${styles.text}`}>
                  No agents or teams found
                </span>
              )}

            {/* For - All Agents for applied brands */}
            {userAndTeams &&
              (userAndTeams?.agentIdsForAppliedBrands?.length ?? 0) > 0 &&
              "all agents for applied brands".includes(
                searchText.trim().toLowerCase(),
              ) && (
                <AgentListItem
                  userName={"All Agents for applied brands"}
                  id={"agentsForAppliedBrands"}
                  userIds={userAndTeams.agentIdsForAppliedBrands}
                  handleChange={handleUpdate}
                  selectedValues={selectedUsers}
                  isLoading={loadingUpdateUsers}
                  scope={"static"}
                />
              )}
            {/* Display All admin and lead agents  option*/}
            {
              <AgentListItem
                userName={"All admin and lead agents"}
                id={"allAdminLeadAgents"}
                userIds={activeUserIds}
                handleChange={handleUpdate}
                selectedValues={selectedUsers}
                isLoading={loadingUpdateUsers}
                scope={"static"}
              />
            }
            {/* Display teams if available */}
            {teamIdsToRender.length > 0 && (
              <span className={`mt-2 ${styles.teamsText}`}>Teams</span>
            )}
            {teamIdsToRender.map((teamId) => {
              const team = userAndTeams?.teams[teamId];
              //Ignore disabled users ids
              const filteredUserIds = team?.userIds
                .map((id) => id + "")
                .filter((userId) => {
                  const user = userAndTeams?.users[userId];
                  return user && !user.disabled;
                });
              if (team) {
                return (
                  <AgentListItem
                    key={team.id}
                    userName={team.name ?? ""}
                    imgURL={team.imageUrl}
                    id={team.id}
                    userIds={filteredUserIds ?? []}
                    handleChange={handleUpdate}
                    selectedValues={selectedUsers}
                    isLoading={loadingUpdateUsers}
                    scope={"team"}
                  />
                );
              } else {
                return <></>;
              }
            })}

            {/* Display lead agents if available */}
            {agentIdsToRender.length > 0 && (
              <span className={`${styles.teamsText}`}>Select lead agents</span>
            )}
            {agentIdsToRender.map((userId) => {
              const user = userAndTeams?.users[userId];
              if (user) {
                return (
                  <AgentListItem
                    key={user.id}
                    userName={user.name ?? user.email ?? ""}
                    imgURL={user.imageUrl}
                    id={user.id}
                    userIds={[user.id + ""]}
                    handleChange={handleUpdate}
                    selectedValues={selectedUsers}
                    isLoading={loadingUpdateUsers}
                    scope={"user"}
                    disabled={user.disabled}
                  />
                );
              } else {
                return <></>;
              }
            })}
            {/* Display agents if available */}
            {adminIdsToRender.length > 0 && (
              <span className={`${styles.teamsText}`}>Select Admins</span>
            )}
            {adminIdsToRender.map((userId) => {
              const user = userAndTeams?.users[userId];
              if (user) {
                return (
                  <AgentListItem
                    key={user.id}
                    userName={user.name ?? user.email ?? ""}
                    imgURL={user.imageUrl}
                    id={user.id}
                    userIds={[user.id + ""]}
                    handleChange={handleUpdate}
                    selectedValues={selectedUsers}
                    isLoading={loadingUpdateUsers}
                    scope={"user"}
                    disabled={user.disabled}
                  />
                );
              } else {
                return <></>;
              }
            })}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
};

// Component to manage team members who will receive notifications
function AgentsDD() {
  // State to track whether the notification section is open or closed
  const [notification, setNotification] = useState(false);

  return (
    <div className={`px-2 py-1 mt-2 ${styles.reportWrap}`}>
      <div
        className={`p-2 d-flex justify-content-between align-items-center cursor-pointer`}
        onClick={() => setNotification(!notification)}
      >
        <div className="d-flex align-items-center">
          <img
            src={reportGroup}
            alt=""
            className={`${styles.reportGroup}`}
          />
          <span className={`ps-2 ${styles.dropHead}`}>
            Team members who will receive notifications
          </span>
        </div>
        <div className="ps-2">
          <i
            className={`fa-solid fa-chevron-${notification ? "up" : "down"}`}
          ></i>
        </div>
      </div>
      {/* Render UpdateAgants component if notification is open */}
      <UpdateAgants show={notification} />
    </div>
  );
}

export default AgentsDD;
