import styles from "./ReturnDiscardModal.module.scss";

const ReturnDiscardModal = ({
  onDiscard,
  onHide,
  heading,
  subText,
}: {
  onHide: () => void;
  heading: string;
  subText: string;
  onDiscard: () => void;
}) => {
  return (
    <div className={`text-center `}>
      <h3 className={`mb-2 mb-lg-3 ${styles.modalHead}`}>{heading}</h3>
      <span className={`d-inline-block mb-2 ${styles.modalSpan}`}>
        {subText}
      </span>
      <div
        className={`d-flex flex-column flex-md-row justify-content-center align-items-center mt-3`}
      >
        <button
          className={`bg-white me-0 me-md-4 mb-2 mb-md-0 px-4 py-1 ${styles.cancelBtn}`}
          onClick={onHide}
        >
          Cancel
        </button>
        <button
          className={`border-0 px-4 py-1 ${styles.saveBtn}`}
          onClick={() => {
            onDiscard();
            onHide();
          }}
        >
          Confirm
        </button>
      </div>
    </div>
  );
};

export default ReturnDiscardModal;
