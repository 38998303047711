/**
 * This file is the service file used for making api request.
 * It contains the replaceThemeImages function service which is explained below.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants"; // this is used for testing purpose only for frontend.

export type ImageType =
  | "logoImage"
  | "backgroundImage"
  | "favicon"
  | "footerLogo";

interface replaceThemeImagesPayload {
  type: ImageType;
  img: File;
  integrationId: number | string;
  themeId: number | string;
}

/**
 * For testing frontend with own api link if backend is not prepared.
 */
const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/replaceKnoweledgeBaseImages`
  : "/api/chatSetting/replaceThemeImages";

/**
 * This service is used to replave the theme images in s theme.
 */
export const replaceThemeImages = async (params: replaceThemeImagesPayload) => {
  const fData = new FormData();
  fData.append("type", params.type);
  fData.append("img", params.img);
  fData.append("integrationId", params.integrationId.toString());
  fData.append("themeId", params.themeId.toString());
  const { data: res } = await axiosJSON.post(apiEndPoint, fData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return res.data;
};
