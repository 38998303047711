import { useCallback, useEffect, useRef } from "react";
import InfiniteScroll from "src/components/InfiniteScroll";
import Loader from "src/components/Loader";
import {
  fetchMembersInTeam,
  resetMembersInTeamData,
} from "src/store/slices/teamSettings/teamSettings.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import NoMembers from "../NoMembers/NoMembers";

import styles from "./UsersInTeam.module.scss";
import { v4 as uuidv4 } from "uuid";
import TeamMember from "../TeamMember";

function UsersInTeam(props: any) {
  const dispatch = useAppDispatch();
  /*for team members */
  const {
    usersInTeam,
    usersInTeamIdList,
    fetchUsersInTeamAjaxStatus,
    usersInTeamCount,
    usersInTeamTotalCount,
  } = useAppSelector((state) => state.teamSettings);

  const totalMembers: any = useRef(usersInTeamTotalCount);
  useEffect(() => {
    totalMembers.current = usersInTeamTotalCount;
  }, [usersInTeamTotalCount]);

  const handleInfiniteScroll = useCallback(() => {
    if (totalMembers.current !== null) {
      if (totalMembers.current > 0) {
        dispatch(fetchMembersInTeam(parseInt(props.teamId)));
      }
    }
  }, [usersInTeamTotalCount]);
  return (
    <div className="">
      <InfiniteScroll
        className={`mb-5 w-100 overflow-auto px-0 px-md-5 ${styles.infScroll}`}
        loadMore={handleInfiniteScroll}
        loader={
          fetchUsersInTeamAjaxStatus === "pending" &&
          Object.keys(usersInTeam).length === 0 ? (
            <div className={`d-flex flex-column m-auto ${styles.loading}`}>
              <Loader />
              <span className="text-center">Fetching members...</span>
            </div>
          ) : undefined
        }
        errorMessage={
          usersInTeamTotalCount === 0 &&
          (fetchUsersInTeamAjaxStatus === "rejected" ||
            fetchUsersInTeamAjaxStatus === "fulfilled") ? (
            <NoMembers />
          ) : undefined
        }
        initialDataLoaded={false}
        hasMore={
          fetchUsersInTeamAjaxStatus === "rejected"
            ? false
            : usersInTeamTotalCount === 0
            ? true
            : usersInTeamIdList.length < usersInTeamTotalCount
        }
      >
        {Object.keys(usersInTeam).length > 0 &&
          Object.keys(usersInTeam).map(function (key, index) {
            return (
              <>
                <TeamMember
                  key={"user_in_team_" + key}
                  userData={usersInTeam[parseInt(key)]}
                />
              </>
            );
          })}
      </InfiniteScroll>
    </div>
  );
}

export default UsersInTeam;
