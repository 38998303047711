import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";


export interface selectQaParams {
  botProfileId: number | string;
  questionId?: number | string;
  isSelected?: boolean;
  isAllSelected?: boolean;
}

const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/customQa/selectQa`
  : "/api/botProfile/customQA/select";

export async function selectQa(params: selectQaParams) {
  const { data: res } = await axiosJSON.post(apiEndPoint, params);

  if (res.error) {
    throw new Error(res.message as string);
  }

  return res.data;
}
