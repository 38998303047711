import { useState } from "react";
import { Modal } from "react-bootstrap";
import AddBrand from "src/components/AddBrand/AddBrand";
import BrandModal from "src/components/BrandModal/BrandModal";
import { useAppSelector } from "src/store/store";
import styles from "./Brands.module.scss";
import SearchSection from "./Children/SearchSection/SearchSection";
import PlanUpgradeOverlay from "src/components/PlanUpgrade/PlanUpgradeOverlay/PlanUpgradeOverlay";
const Brands = () => {
  const [showModal, setShowModal] = useState(false);
  const scopes = useAppSelector(
    (state) => state.globals.currentUserData?.scopes,
  );

  const disabledFeatures = useAppSelector(
    (state) => state.globals.currentUserData?.disabledFeatures,
  );

  const onShow = (e: any) => {
    e.stopPropagation();
    setShowModal(true);
    // console.log("show");
  };
  const onHide = () => {
    setShowModal(false);
    // console.log("hide");
  };
  return (
    <>
      <div className={`${styles.main__content}`}>
        <div className={`container-fluid`}>
          <div className={`row`}>
            <div className={`col-12`}>
              <div className={`row my-2 pb-2 row align-items-center`}>
                <div className="col-12 col-md-6 mt-3">
                  <h2 className={`${styles.brandHead}`}>Brands</h2>
                  <span className={`${styles.brandSub}`}>
                    Create multiple brands represent multiple products or
                    services, or they might literally be multiple brands owned
                    and represented by your company
                  </span>
                </div>
                {scopes &&
                scopes.includes("settings_can_edit_email_signature") ? (
                  <div className="col-12 col-md-6 text-md-end">
                    {/* <button
                      className={`border-0 py-2 px-2 me-md-4 my-1 my-md-0 ${styles.createBtn}`}
                      onClick={onShow}
                    >
                      Create Another Brand
                    </button> */}
                    {/* Updated button as per update in design 25-02-2023 https://xd.adobe.com/view/1e837314-84a1-4cca-8f1e-4ec558c89541-4bd0/specs/ */}
                    <PlanUpgradeOverlay
                      showOverlay={
                        disabledFeatures?.includes("add_brand") ?? false
                      }
                      message="Upgrade to add more brands"
                      placement="bottom"
                    >
                      <button
                        className={`border-0 me-md-4 my-1 my-md-0 ${styles.createBtn}`}
                        onClick={onShow}
                        disabled={
                          disabledFeatures?.includes("add_brand") ?? false
                        }
                      >
                        Create new brand
                      </button>
                    </PlanUpgradeOverlay>
                    <Modal
                      backdropClassName={`${styles.modalBack}`}
                      show={showModal}
                      onHide={onHide}
                      dialogClassName={`${styles.modalDialog}`}
                      contentClassName={`${styles.modalContent}`}
                      centered={false}
                      enforceFocus={false}
                    >
                      <BrandModal
                        isAdd={true}
                        brandData={{
                          id: "",
                          name: "",
                          email: "",
                          signature: "",
                          enabled: false,
                          isDefault: false,
                          isActive: true,
                          imageURL: null,
                          hasPermission: false,
                          disabledUserIds: [],
                          isVerified: false,
                        }}
                        onHide={onHide}
                      />
                    </Modal>
                  </div>
                ) : null}
              </div>
              <div className={`px-4 py-2 ${styles.contentWrapper}`}>
                <SearchSection />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Brands;
