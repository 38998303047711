import React, { useEffect, useRef, useState } from "react";
import AxiosImg from "src/components/AxiosImg";
import UserAvatar from "src/components/UserAvatar";
import styles from "./ChatFormBox.module.scss";
import { Message } from "src/services/LiveChat/messageExchange/getMessages";
import { v4 as uuid } from "uuid";
import ParseHTML from "src/components/ParseHTML";
const ChatFormBox = ({
  messageText,
  sentBy,
  sendTimeGmt,
  formDetails,
}: Message) => {
  const [showReadMoreLessBtn, setShowReadMoreLessBtn] =
    useState<boolean>(false);
  const [showAllContent, setShowAllContent] = useState(false);
  const htmlRef = useRef(null as any);

  useEffect(() => {
    if (htmlRef.current) {
      if (htmlRef.current.scrollHeight > 170) {
        setShowReadMoreLessBtn(true);
      } else {
        setShowReadMoreLessBtn(false);
      }
    }
  }, [formDetails?.formData]);

  function getELementValue(value: any, elementType: string | undefined) {
    // Check if the elementType is either "chat_rating" or "chatRating"
    if (elementType === "chat_rating" || elementType === "chatRating") {
      return (
        <div>
          {/* Check if the value is equal to 1, if 1  Render a thumbs-up icon with the "ratingIcon" style */}
          {value == 1 ? (
            <span className={`${styles.ratingIcon} me-2`}>
              <i className="fas fa-thumbs-up text-dark"></i>
            </span>
          ) : (
            <span className={`${styles.ratingIcon} ${styles.disLike}`}>
              <i className={`fas fa-thumbs-up text-dark ${styles.disLike}`}></i>
            </span>
          )}
        </div>
      );
    } else if (typeof value === "string") {
      return <ParseHTML html={value} />;
    } else if (Array.isArray(value)) {
      return <ParseHTML html={value.join(", ")} />;
    } else {
      return null;
    }
  }
  const getFormTitle = () => {
    //checking if formName property is given from backend, if yes use it directly
    if (formDetails?.formName) {
      return formDetails.formName;
    }

    if (formDetails?.formType === "preChat") {
      return "Prechat form";
    } else if (formDetails?.formType === "askForEmail") {
      return "Email form";
    } else if (formDetails?.formType === "ticketForm") {
      return "Ticket form";
    } else if (formDetails?.formType === "postChat") {
      return "Post Chat form";
    }

    return "Prechat form";
  };
  return (
    <div className="d-flex mt-2 mb-2">
      {sentBy?.imageURL && sentBy?.imageURL.length !== 0 ? (
        <AxiosImg
          url={sentBy?.imageURL}
          className={`rounded-circle ${styles.profileImage}`}
          isDirectURL={sentBy?.isPublicAttachmentUrl}
        />
      ) : (
        <div>
          <UserAvatar name={sentBy?.name ?? "NA"} size={32} />
        </div>
      )}

      <div className={`${styles.preChatBox}`}>
        <div className={`${styles.prechatBg} p-2 text-center`}>
          <h2 className={`mb-0 ${styles.prechatHead}`}>{getFormTitle()}</h2>
        </div>
        <form
          className={`px-3 py-2 ${
            showReadMoreLessBtn === true && showAllContent === false
              ? styles.formData
              : ""
          }`}
          ref={htmlRef}
        >
          {formDetails?.formData &&
            formDetails.formData.map((formItem) => {
              return (
                <div className="mb-2" key={uuid()}>
                  <label className={`${styles.formLabel}`}>
                    {/* Removed a colon : from here  */}
                    {formItem.label}
                  </label>
                  <div>
                    <p className={`mb-0 ${styles.formDetail}`}>
                      {/* {formItem.value} */}
                      {getELementValue(formItem.value, formItem.type)}
                    </p>
                  </div>
                </div>
              );
            })}

          {/* <div className="mb-2">
            <label className={`${styles.formLabel}`}>Email:</label>
            <div>
              <p className={`mb-0 ${styles.formDetail}`}>Rocklama@gmail.com</p>
            </div>
          </div>
          <div className="mb-2">
            <label className={`${styles.formLabel}`}>Message:</label>
            <div>
              <p className={`mb-0 ${styles.formDetail}`}>
                How ro implement a queue using stack? Also… How is a stack
                different.
              </p>
            </div>
          </div> */}
        </form>
        {showReadMoreLessBtn === true && (
          <div
            className="d-flex  justify-content-center"
            role={"button"}
            onClick={(e) => setShowAllContent(!showAllContent)}
          >
            <div className="mt-1">
              <h6 className={`${styles.showMoreText} text-center`}>
                {showAllContent === true ? "Show less" : "Show more"}
              </h6>
            </div>
            <div className="ms-2 mt-1">
              <span className={`${styles.arrowImage}`}>
                {" "}
                <i
                  className={`fa-solid fa-chevron-down ${
                    showAllContent === true ? `${styles.rotateArrow}` : ""
                  }`}
                ></i>{" "}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatFormBox;
