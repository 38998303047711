import exclamation from "src/assets/images/exclamation.png";
import styles from "./AutomationError.module.scss";

const AutomationError = ({ onHide }: { onHide: () => void }) => {
  return (
    <div className="p-4">
      <div className="text-center py-2">
        <div className={`mb-4`}>
          <img
            src={exclamation}
            alt="exclamation"
            className={`${styles.exclamation}`}
          />
        </div>
        <p className={`mb-2 pt-2 ${styles.error}`}>Error</p>
        <span className={`${styles.successSpan}`}>
          Steps in the workflow once set, cannot be edited. If you need
          different settings, please create a new workflow.
        </span>
      </div>
      <div className="d-flex justify-content-center mt-4 mb-2 pt-2">
        <button
          className={`${styles.okBtn}`}
          onClick={onHide}
        >
          Back
        </button>
      </div>
    </div>
  );
};

export default AutomationError;
