import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Customer,
  getAllCustomers,
  getQueuedChats,
} from "src/services/LiveChat/messageExchange/getAllCustomers";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  isNotificationAudioIntervalRunning,
  startNotificationAudioWithInterval,
  stopNotificationAudioInterval,
} from "src/utils/utils";
import { useQuery } from "@tanstack/react-query";
import { fetchLiveChatSidebar } from "src/store/slices/liveChatSetting/chatWidExchCust/chatWidExchCust.thunks";

export const useQueuedChatRecurringNotification = () => {
  const { chatIdList, customers } = useAppSelector(
    (state) => state.chatWidExchCust
  );
  const { currentUserData } = useAppSelector((state) => state.globals);
  const [queuedChats, setQueuedChats] = useState<{
    [key: string | number]: Partial<Customer> & {
      chatId: string | number;
      customerId: string;
    };
  }>({});

  const currentState = useRef({
    customers: customers,
  });

  const queuedChatLength = useMemo(() => {
    return Object.values(queuedChats).length;
  }, [queuedChats]);

  const dispatch = useAppDispatch();

  useMemo(() => {
    currentState.current = {
      customers: customers,
    };
  }, [chatIdList, customers]);

  // Function to add a chat to queuedChats
  const addChat = useCallback((customer: Customer) => {
    setQueuedChats((prevChats) => {
      if (!prevChats[customer.chatId]) {
        const newChats = { ...prevChats, [customer.chatId]: customer };
        return newChats;
      }

      return prevChats;
    });
  }, []);

  // Function to remove a chat from queuedChats
  const removeChatFromNotifications = useCallback((chatId: number) => {
    setQueuedChats((prevChats) => {
      if (prevChats[parseInt(chatId + "")]) {
        const newChats = { ...prevChats };
        delete newChats[parseInt(chatId + "")];
        if (Object.values(newChats).length === 0) {
          handleQueuedChatRefetch();
        }
        return newChats;
      }

      return prevChats;
    });
  }, []);

  //query to fetch queued chats
  const { isLoading, error, data, refetch } = useQuery(
    ["LiveChat/QueuedChatNotifications"],
    async () => {
      const response = await getQueuedChats({
        start: 0,
        limit: 10,
      });
      return response;
    },
    {
      onSuccess: (data) => {
        //updating state with fetched data
        setQueuedChats(data);
      },
      onError: (error) => {
        setQueuedChats({});
        console.error("Error fetching active chats:", error);
      },
      enabled: currentUserData?.userId ? true : false, //disabling the on initialize fetch cause login data is might not be initialized!
      refetchInterval: Object.values(queuedChats).length > 0 ? 60000 : false, // Refetch only if there are any chats available
    }
  );

  useEffect(() => {
    if (refetch && currentUserData?.userId) {
      refetch();
      dispatch(fetchLiveChatSidebar());
    } else {
      //if user id is not then user logged out, so reset the notification
      setQueuedChats({});
      stopNotificationAudioInterval();
    }
  }, [refetch, currentUserData?.userId]);

  useEffect(() => {
    //if chats are empty then clear notification
    if (queuedChatLength === 0) {
      stopNotificationAudioInterval();
    } else {
      //checking if notification audio is already playing
      if (!isNotificationAudioIntervalRunning()) {
        // const chatId = Object.keys(queuedChats)[0];

        //if not then creating one
        startNotificationAudioWithInterval({
          interval: 10000,
          soundType: "NewChatSound",
        });
      }
    }
  }, [queuedChatLength]);

  const handleQueuedChatRefetch = useCallback(() => {
    if (refetch && !isLoading) {
      refetch();
    }
  }, [refetch, isLoading]);

  return {
    removeChatFromNotifications,
    addChat,
    refetch: handleQueuedChatRefetch,
  };
};
