import styles from "./AddMore.module.scss";

function AddMore(props: {
  AddTab: Function;
  text: string;
  validateTabType: Function;
  selectedTabId: string | null | number;
}) {
  return (
    <div
      className="ms-0 ms-md-2 d-flex"
      role="button"
      onClick={() => {
        props.AddTab();
        props.validateTabType(props.selectedTabId);
      }}
    >
      <div
        className={`d-flex align-items-center justify-content-center me-2 me-md-0 ${styles.addMore}`}
      >
        +
      </div>
      <div className={`${styles.blueText} ms-2`}>{props.text}</div>
    </div>
  );
}

export default AddMore;
