import { useEffect, useMemo, useState } from "react";
import AttrTableHeader from "./AttrTableHeader/AttrTableHeader";
import AttrTableRows from "./AttrTableRows/AttrTableRows";
import styles from "./MapAttribute.module.scss";
import getAllProducts, {
  GetAllProductsParams,
} from "src/services/ReturnAutoWorkFlow/ConfigModals/getAllProducts.service";
import { useUploadNewCsvModalContext } from "../Hooks/useUploadNewCsvModalContext";
import { useQuery } from "@tanstack/react-query";
import { ConfigureCSVTableMappedAttributes } from "src/features/ReturnAutoWorkFlow/ReturnAutoWorkFlow.types";
import Pagination from "src/routes/TicketList/children/Pagination/Pagination";
import Loader from "src/components/Loader";
import { useParams } from "react-router-dom";

/**
 * Custom hook to fetch and format products information for the modal.
 *
 * This hook uses `useQuery` to fetch products data and formats it into a structured
 * object suitable for displaying product details in the modal.
 *
 * @param {GetAllProductsParams} params - Parameters required to fetch the products information.
 *
 * @returns object - An object containing the formatted product details, loading state, and error state.
 */
const useGetAllProducts = (params: GetAllProductsParams) => {
  const { data, isLoading, isError } =
    useQuery<ConfigureCSVTableMappedAttributes>({
      queryKey: [
        "getAllProducts",
        params.integrationId,
        params.start,
        params.limit,
      ],
      queryFn: () => getAllProducts({ ...params }),
    });

  return {
    data: data,
    isLoading,
    isError,
  };
};

const MapAttribute = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const { integrationId } = useParams();
  const { state, updateState } = useUploadNewCsvModalContext();
  const limit = 8;
  /**
   * Starting index for API call
   */
  const start = useMemo(() => {
    return limit * currentPage - limit;
  }, [currentPage]);

  const params: GetAllProductsParams = {
    integrationId: integrationId ?? "",
    start: start,
    limit: limit,
    attachmentId: state?.uploadCSV?.attachmentId ?? "",
  };

  const { isError, isLoading, data } = useGetAllProducts(params);
  useEffect(() => {
    if (data) {
      updateState({
        mapAttributes: {
          ...data,
          updatedTableHeadingIds: state.mapAttributes?.updatedTableHeadingIds
            ? state.mapAttributes?.updatedTableHeadingIds
            : data.updatedTableHeadingIds,
        },
      });
    }
  }, [data]);

  /**
   * Total pages available
   */
  const totalPages = useMemo(() => {
    return data?.totalVarients ? Math.ceil(data?.totalVarients / limit) : 0;
  }, [data?.totalVarients]);

  return (
    <div>
      <h4 className={`${styles.heading}`}>Map attributes</h4>
      <p className={`${styles.subHeading}`}>
        Please review matching column titles and make necessary changes using
        the dropdowns.
      </p>
      {isLoading ? (
        <div className={`p-3 ${styles.settingWrap}`}>
          <Loader />
        </div>
      ) : isError ? (
        <div className={`p-3 ${styles.settingWrap}`}>
          <div className="d-flex align-items-center justify-content-center text-danger">
            {"Something went wrong!"}
          </div>
        </div>
      ) : (
        <div>
          <div className={`mt-4 mb-1 ${styles.container}`}>
            <div className="w-100">
              <div className={`d-flex`}>
                <AttrTableHeader />
              </div>
              <AttrTableRows
                varients={data?.varients ?? {}}
                varientIds={data?.varientIds ?? []}
              />
            </div>
          </div>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
          />
        </div>
      )}
    </div>
  );
};

export default MapAttribute;
