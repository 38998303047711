import { useMemo } from "react";
import { NavLink } from "react-router-dom";
import emptyBackPng from "src/assets/images/EmptyHistory.png";

function useShowError({
  errorInConversation,
  errorInCustFetchOrLoading,
  justErrorInConversation,
  styles,
}: any) {
  return useMemo(() => {
    return errorInConversation ? (
      <div
        className={`${styles.nothingFound} ${
          errorInCustFetchOrLoading ? styles.bigNothingFound : ""
        } m-auto d-flex flex-column justify-content-center align-items-center`}
      >
        <img src={emptyBackPng} />
        <p className={`${styles.backtext} mt-2 mb-0`}>
          {justErrorInConversation
            ? "We were unable to load this section. Please try again by reloading the page."
            : "No conversations here"}
        </p>
        {!justErrorInConversation && (
          <>
            <p className={`${styles.subBacktext} d-block mt-1`}>
              The chats with visitors will appear here .
            </p>
            {/* <span className={`mt-2 btn btn-primary ${styles.sendBtn}`}>
              <NavLink
                to={"/live-chat/traffic/allCustomers"}
                style={{ color: "#fff" }}
              >
                Start chat
              </NavLink>
            </span> */}
          </>
        )}
      </div>
    ) : undefined;
  }, [errorInConversation, errorInCustFetchOrLoading, styles]);
}

export default useShowError;
