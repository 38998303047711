/**
 * This file defines a React component responsible for rendering table header of external source
 *
 * @author @yuvaraj-busibud
 * @author @navjyot-busibud
 * @author @Anubhav-busibud
 */
import React from "react";
import styles from "./TableHeader.module.scss";
import { Articles } from "src/services/Bot/AnswerSources/getAllExternal.service";
import BotIndexingStatus from "src/components/BotIndexingStatus/BotIndexingStatus";
interface Props {
  // selectedArticles?: any;
  // setSelectedArticles?: any;
  // articles?: Articles[];
  isAllArticleSelected: boolean;
  setSelectAll: (isAllSelected: boolean) => void;
  hideSelectAll?: boolean;
}

const TableHeader = ({
  isAllArticleSelected,
  setSelectAll,
  hideSelectAll,
}: Props) => {
  return (
    <div>
      <div
        className={`d-flex align-items-center px-2 mb-2 ${styles.tableHead}`}
      >
        <div className={`${styles.article}`}>
          <div className="form-check ms-1">
            {hideSelectAll !== true ? (
              <input
                className={`form-check-input ${styles.checkbox}`}
                type="checkbox"
                checked={isAllArticleSelected}
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectAll(!isAllArticleSelected);
                }}
              />
            ) : null}

            <label
              className={`form-check-label ${styles.tablePara}`}
              htmlFor="article"
            >
              Article
            </label>
          </div>
        </div>
        <div className={`${styles.articleUrl}`}>
          <span className={`${styles.tablePara}`}>Article URL</span>
        </div>
        <div className={`${styles.mainSource}`}>
          <span className={`${styles.tablePara}`}>Main Source</span>
        </div>
        <div className={`${styles.appearance}`}>
          <span className={`${styles.tablePara}`}>No. of times appeared</span>
        </div>
        <div className={`${styles.status}`}>
          <span className={`${styles.tablePara}`}>Status</span>
        </div>
      </div>
    </div>
  );
};

export default TableHeader;
