import styles from "./SetCondition.module.scss";
import GreetingCondition from "./Children/GreetingCondition/GreetingCondition";
import WidgetSection from "./Children/WidgetSection/WidgetSection";
import backArrow from "src/assets/images/backArrow2.png";
import { useCallback, useState } from "react";
import Select, { SingleValue } from "react-select";
import { Modal } from "react-bootstrap";
import SaveGreetModal from "./Children/SaveGreetModal/SaveGreetModal";
import { useNavigate, useParams } from "react-router-dom";

import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  updateData,
  UPDATE_SINGLE_VALUES,
} from "src/store/slices/targetedMessage/targetedMessage.slice";

const SetCondition = () => {
  const [showModal, setShowModal] = useState(false);

  const dispatch = useAppDispatch();

  let GetconditionType: string = "";

  function handleSaveGreet() {
    dispatch(
      UPDATE_SINGLE_VALUES({
        conditionType: GetconditionType,
      })
    );
  }
  const onShow = useCallback(() => {
    // console.log(conditionType);
    setShowModal(true);
  }, []);

  const onHide = useCallback(() => {
    // console.log("Reached");

    setShowModal(false);
  }, []);

  const params = useParams();

  let headingType;

  const navigate = useNavigate();

  if (params.settingName === "newGreeting") {
    headingType = "Greeting";
  } else {
    headingType = "Announcement";
  }

  let [selectedOption, setSelectedOption] = useState(1);

  const conditionType = useAppSelector((state) => {
    return state.targetedMessage.conditionType;
  });

  return (
    <div className="w-100  d-flex flex-column  justify-content-between  h-100 ">
      <div className="d-flex   h-100  ">
        <div className={`${styles.greetDiv} `}>
          <div className={`${styles.headingWrapper} `}>
            <img
              className={`${styles.backArrow}`}
              src={backArrow}
              alt=""
              onClick={() => navigate(-1)}
            />
            <span className={`${styles.greetHead}`}>New {headingType}</span>{" "}
          </div>

          <div className={` ${styles.greetinCondition}  `}>
            <GreetingCondition
              headingType={headingType}
              conditionType={GetconditionType}
            />
          </div>
        </div>
        <div className={`${styles.widgetDiv} `}>
          <WidgetSection />
        </div>
      </div>

      <div className={`${styles.actionBox}  `}>
        <button className={`${styles.backBtn}`} onClick={() => navigate(-1)}>
          Back
        </button>

        <button
          className={`${styles.saveBtn} w-auto`}
          onClick={() => {
            onShow();
            handleSaveGreet();
          }}
        >
          Save {headingType}
        </button>
        <Modal
          backdropClassName={`${styles.modalBack}`}
          show={showModal}
          onHide={onHide}
          dialogClassName={`${styles.modalDialog}`}
          contentClassName={`${styles.modalContent}`}
          //   centered={true}
          enforceFocus={false}
        >
          <SaveGreetModal heading={headingType} Hide={onHide} />
        </Modal>
      </div>
    </div>
  );
};
export default SetCondition;

// <div className={`${styles.conditionMain}`}>
//         <div className={`${styles.greetDiv}`}>

//           <div>
//             <GreetingCondition />
//           </div>
//         </div>
//         <div className={`${styles.widgetDiv}`}>
//           <WidgetSection />
//         </div>
//       </div>
//       <div className={`${styles.actionBox}`}>
//         <button className={`${styles.backBtn}`}>Back</button>
//         <button className={`${styles.saveBtn}`} onClick={onShow}>
//           Save Greeting
//         </button>
//       </div>
//       <Modal
//         backdropClassName={`${styles.modalBack}`}
//         show={showModal}
//         onHide={onHide}
//         dialogClassName={`${styles.modalDialog}`}
//         contentClassName={`${styles.modalContent}`}
//         enforceFocus={false}
//       >
//         <SaveGreetModal onHide={onHide} />
//       </Modal>
