import styles from "./ResponseTitleExcerpt.module.scss";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { fetchTicketsbyIds } from "src/store/slices/tickets/tickets.slice";
import { selectTicketCellData } from "src/store/slices/tickets/tickets.slice";
import { TableCellProps } from "..";
import UserAvatar from "src/components/UserAvatar";
import { useState } from "react";
import DraftModal from "src/components/DraftModal";
import ParseHTML from "src/components/ParseHTML";
import ShowTime from "src/components/ShowTime";
import { getDraftBGColor, getDraftTextColor } from "src/utils/utils";
import { showAttachment } from "src/services/Attachment/showAttachment";
import { getKeysForColumnIds } from "src/hooks/useTicketViews/ticketViewsConfig";
import AxiosImg from "src/components/AxiosImg";

const style = {
  boxShadow: `0 8px 6px -6px #9E9E9E`,
  position: "absolute",
  // top: "50%",
  // transform: "translate(-50%, -50%)",
  ":not(:firstChild)": {
    left: "50%",
    // width: "100%",
    // backgroundColor: "red",
  },
};

function ResponseTitleExcerpt({ ticketId, colKeys }: TableCellProps) {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useAppDispatch();
  const onShow = () => {
    setShowModal(true);
  };
  const onHide = () => {
    setShowModal(false);
  };
  const ticketCellData: any = useAppSelector((state) =>
    selectTicketCellData(state, ticketId, colKeys),
  );

  return (
    <div className={`${styles.root} ignore-click d-flex`}>
      <div
        style={{
          width: " 24px",
          height: "24px",
        }}
      ></div>
      <div className={`${styles["allDraftMain"]}`}>
        <div
          className={`ignoreClick ${styles["allTicketsTbody"]} ms-2 ${styles.bgChange}`}
          style={{
            backgroundColor: getDraftBGColor(
              ticketCellData[0]?.canAddNewDraft
                ? ""
                : (ticketCellData[1]?.draftStatusValue + "").toLowerCase() ===
                    "void"
                  ? ticketCellData[2]?.draftStatusValue + ""
                  : ticketCellData[1]?.draftStatusValue + "",
            ),
            opacity:
              (ticketCellData[1]?.draftStatusValue + "").toLowerCase() ===
              "void"
                ? 0.5
                : 1,
          }}
          onClick={onShow}
          onMouseOver={(e) => {
            e.currentTarget.style.borderColor = getDraftTextColor(
              ticketCellData[0]?.canAddNewDraft
                ? ""
                : (ticketCellData[1]?.draftStatusValue + "").toLowerCase() ===
                    "void"
                  ? ticketCellData[2]?.draftStatusValue + ""
                  : ticketCellData[1]?.draftStatusValue + "",
            );
          }}
          onMouseOut={(e) => {
            e.currentTarget.style.removeProperty("border-color");
          }}
        >
          {ticketCellData[0] != null ? (
            <>
              <div
                className={`d-flex justify-content-between align-items-center mb-2`}
              >
                {ticketCellData[0].involvedAgentsData != null &&
                ticketCellData[0].canAddNewDraft === false ? (
                  <div className={`${styles["imgsContainer"]} d-flex`}>
                    {ticketCellData[0].involvedAgentsData.map(
                      (data: any, index: number) => {
                        return (
                          <div
                            className={`${styles.overlap}`}
                            key={index}
                          >
                            {data.agentImgUrl &&
                            data.agentImgUrl.trim() !== "" ? (
                              <AxiosImg
                                className={`${styles["color" + (index % 4)]} ${
                                  styles.profileIcon
                                }`}
                                style={{
                                  ...style,
                                  zIndex: index,
                                }}
                                onMouseOver={(e: any) => {
                                  e.target.style.zIndex =
                                    ticketCellData[0].involvedAgentsData
                                      .length + 10;
                                }}
                                onMouseOut={(e: any) => {
                                  e.target.style.zIndex = index;
                                }}
                                url={data.agentImgUrl}
                                isDirectURL={data.isPublicAttachmentUrl}
                              />
                            ) : (
                              <UserAvatar
                                name={data.name}
                                size={30}
                                style={{
                                  ...style,
                                  zIndex: index,
                                }}
                                className={`${styles["color" + (index % 4)]}`}
                                onMouseOver={(e: any) => {
                                  e.target.style.zIndex =
                                    ticketCellData[0].involvedAgentsData
                                      .length + 10;
                                }}
                                onMouseOut={(e: any) => {
                                  e.target.style.zIndex = index;
                                }}
                              />
                            )}
                          </div>
                        );
                      },
                    )}
                  </div>
                ) : (
                  <div></div>
                )}
                <div className={`${styles.unsetDrafts}`}>
                  +{ticketCellData[0].unsentDraftMsgCount} Unsent Drafts
                </div>
              </div>
              {ticketCellData[0].canAddNewDraft ? (
                <div className={`${styles.addDraftHere} py-3`}>
                  Add draft here...
                </div>
              ) : (
                <>
                  <ParseHTML
                    html={ticketCellData[0]?.messageContent}
                    truncate={styles.truncate}
                    className={`${styles["subSpan"]} mb-1`}
                  />
                  <div className={`${styles.before}`}>...</div>
                  <ShowTime
                    className={``}
                    dateTime={new Date(ticketCellData[0].messageDate)}
                  />
                </>
              )}
            </>
          ) : (
            <div className={`${styles.addDraftHere} py-3`}>
              Add draft here...
            </div>
          )}
        </div>
        <DraftModal
          ticketId={ticketId}
          onHide={onHide}
          onShow={onShow}
          show={showModal}
          onAnyChange={() => {
            dispatch(
              fetchTicketsbyIds({
                ticketIds: [ticketId],
                ticketColunmns: getKeysForColumnIds([6, 8]),
              }),
            );
          }}
        />
      </div>
    </div>
  );
}

export default ResponseTitleExcerpt;

/* 
{
  '6': {
    messageId: 291,
    agentId: 1111,
    agentName: 'Abcd Efg',
    messageDate: '2022-04-20 09:20:13',
    messageDateGmt: '2022-04-20 16:20:13',
    messageContent: '<p><strong>kjfrufgferufrefskfhdfghdfugfccbffgfgdffgfffgdsgdgdgdfg</strong></p><p><strong>fghdgdfgdfg</strong></p><p><strong>dfgdgdgdvfgfdiugh dghhgdfui gdfgidfgdfiugdfgiidfgd fdgfdhdfgfgd</strong></p><p><strong>dgdfgdfgdfjhjguihidfgdddfgdfgdgfhfhgfhghgffghfgghghchgchg</strong></p>',
    unsentDraftMsgCount: 0,
    involvedAgentsData: [
      {
        id: 1111,
        name: 'Abcd Efg',
        agentImgUrl: 'https://www.somedomain.com/user-pic/18w8s83s.png'
      }
    ]
  }
}


    {
      draftStatusId: 12,
      draftStatusValue: 'Approved(Don\'t send)',
      msgId: 291
    },
*/
