import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "src/components/Loader";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import {
  getMyIntegrations,
  IMyIntegration,
} from "src/services/Integrations/myIntegrations";
import ShowIntegration from "./Children/ShowIntegration/ShowIntegration";
import styles from "./MyIntegration.module.scss";
import { v4 as uuidv4 } from "uuid";

const MyIntegration = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [count, setCount] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [myIntegrationIds, setMyIntegrationIds] = useState(
    [] as Array<number | string>
  );
  const [myIntegrationData, setMyIntegrationData] = useState(
    {} as { [id: number | string]: IMyIntegration }
  );

  useEffect(() => {
    setLoading(true);
    getMyIntegrations()
      .then((res) => {
        setMyIntegrationData(res.integrations);
        setMyIntegrationIds(res.integrationIds);
        setCount(res.count);
        setTotalCount(res.total);
        setLoading(false);
      })
      .catch((err) => {
        pushTheToast({
          type: "danger",
          text: err.message,
          position: "top-right",
        });
        setLoading(false);
      });
  }, [false]);

  const gotoBrowseIntegration = () => {
    navigate("/settings/browse-integration");
  };

  return (
    <div className={`${styles.myIntegMain}`}>
      <div className={`d-flex justify-content-between`}>
        <div>
          <h1 className={`${styles.heading}`}>My Integrations</h1>
          <span className={`${styles.subHeading}`}>
            Manage your integrations and connect your existing tools with
            Helplama
          </span>
        </div>
        <div>
          <button
            className={`${styles.addInt}`}
            onClick={(e) => {
              gotoBrowseIntegration();
            }}
          >
            + Add Integration
          </button>
        </div>
      </div>
      {loading === true ? (
        <Loader />
      ) : (
        <>
          <div className={`${styles.integrateBox}`}>
            <div className={`py-2 px-3`}>
              <h4 className={`${styles.myInteg}`}>My Integrations</h4>
              <span className={`${styles.show}`}>
                {myIntegrationIds.length === 0 ? (
                  "You have no integration at the moment."
                ) : (
                  <>
                    Showing {count} of {totalCount}
                  </>
                )}
              </span>
            </div>
            {myIntegrationIds.length !== 0 &&
              myIntegrationIds.map((integrationTypeId) => {
                let integrationItem: IMyIntegration =
                  myIntegrationData[integrationTypeId];
                return (
                  <ShowIntegration
                    key={uuidv4()}
                    myIntegrationItemData={integrationItem}
                  />
                );
              })}
          </div>
          {/* add integrations */}
          <p className={`mt-3`}>
            <span
              className={styles.addIntegrationBlueBtn}
              onClick={(e) => {
                gotoBrowseIntegration();
              }}
            >
              {" "}
              + Add Integration{" "}
            </span>
          </p>
        </>
      )}
    </div>
  );
};
export default MyIntegration;
