import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import objectHash from "object-hash";
import { AJAXSTATUS } from "src/globals/constants";
import { ReasonTypes } from "src/services/UiAutomation/ReturnExchange/Configuration/Step12/step12GetConfig";
import { Step14Config } from "src/services/UiAutomation/ReturnExchange/Configuration/Step14/step14GetConfig";
import { conditionType } from "src/services/UiAutomation/ReturnExchange/Configuration/Step9/step9GetOptionsConditions";
import {
  addConditionHandler,
  ConditionTypes,
  createNewObjectHash,
  deleteConditionHelper,
  deleteTabHelper,
  IVariableName,
  RecursivePartial,
  ruleTypes,
  updateConditionHelper,
  validateChangeHandler,
  validateConditionsHelper,
} from "src/store/slices/initiateReturn/Helpers/returnExchange.helpers";
import { validateRange } from "src/utils/utils";
import { v4 as uuid } from "uuid";
import step14Builders from "./Builders/step14.builders";
import { step14ConfigErrorCount } from "./returnExchange.selectors";

export interface ReturnReason {
  id: string;
  ruleType: (typeof ruleTypes)[number];
  variableName: IVariableName;
  values: {
    return_reason: {
      reasonId: string; // Selected Reason Id
      elementId: string; // Selected Follow Up Question Element Id
      value: string; // Selected Follow Up Question's Answer Id
      operator: string | undefined; // Selected Operator for condition
      error?: string;
    };
  };
  operator: string | undefined;
}

interface EnableDisableTypes {
  currentState: boolean;
  instructions: string;
  showInstruction: boolean;
}

export type ReturnMethodType =
  | "notRequired"
  | "returnRequired"
  | "returnRequiredWithLabels";
export interface ReturnMethodsDetails {
  returnMethodId: string;
  returnMethodName: string | null | undefined;
  returnMethodSequence: number;
  conditions: ConditionTypes[];
  selectReturnReason: ReturnReason[];
  error: string | null;
  itemConditionError: string | null;
  selectReturnReasonError: string | null;
  errorCount: number;
  isAddConditionEnabled: boolean;
  isAddOrderConditionEnabled: boolean;
  isAddSelectReasonConditionEnabled: boolean;
  returnReasons: boolean;
  shipWithAnyCarrier: EnableDisableTypes;
  shipWithPrepaidLabels: EnableDisableTypes;
  returnMethodType: {
    value: ReturnMethodType;
    message: string;
    error: string | null;
  };

  isValid: boolean;
}

export interface step14Config {
  selectedReturnMethodId: string | null;
  returnMethods: ReturnMethodsDetails[];
  error: string | null;
  initialHash: string;
  currentHash: string;
  excludeKeysForHash: string[];
  isChanged: boolean;
  intialStep14Config: ReturnMethodsDetails[] | null;

  overAllInitialHash: string;
  overCurrentHash: string;
  returnReasonList: ReasonTypes[]; // Dec 20 Adding property to store default return reasons to check for validations based on moreQuestions
  conditionOptions: conditionType[];
  selectReasonOptions: conditionType[];
  defaultOptions: conditionType[];
  itemOptions: conditionType[];

  getItemType: Step14Config;

  variableNames: any;
  operatorsNames: any;
  operatorSymbolMap: any;
  valueTypes: any;
  variableNameToValueType: any;
  fetchAjaxStatus: AJAXSTATUS;
  updateAjaxStatus: AJAXSTATUS;
}

const excludeKeys = [
  "error",
  "isAddConditionEnabled",
  "isAddOrderConditionEnabled",
  "isValid",
  "errorCount",
  "returnMethodSequence",
  "itemConditionError",
  "selectReturnReasonError",
  "returnReasons",
];

const firstReturnMethodId = uuid();

export const initialState: step14Config = {
  selectedReturnMethodId: firstReturnMethodId,
  returnMethods: [
    {
      returnMethodId: firstReturnMethodId,
      returnMethodName: "Return Method 1",
      returnMethodSequence: 1,
      conditions: [],
      error: null,
      errorCount: 0,
      itemConditionError: null,
      selectReturnReasonError: null,
      isAddConditionEnabled: true,
      isAddOrderConditionEnabled: true,
      returnReasons: false,
      isAddSelectReasonConditionEnabled: false,
      returnMethodType: {
        value: "notRequired",
        message: "",
        error: null,
      },
      shipWithAnyCarrier: {
        currentState: true,
        instructions: "",
        showInstruction: false,
      },
      shipWithPrepaidLabels: {
        currentState: true,
        instructions: "",
        showInstruction: false,
      },
      selectReturnReason: [],
      isValid: false,
    },
  ],

  error: null,
  initialHash: "",
  currentHash: "",
  overAllInitialHash: "",
  overCurrentHash: "",
  excludeKeysForHash: excludeKeys,

  isChanged: false,
  intialStep14Config: null,

  returnReasonList: [],
  conditionOptions: [],
  itemOptions: [],
  defaultOptions: [],
  selectReasonOptions: [],
  getItemType: {
    data: [],
  },
  variableNames: [],
  operatorsNames: {},
  operatorSymbolMap: {},
  valueTypes: {},
  variableNameToValueType: {},
  fetchAjaxStatus: "pending",
  updateAjaxStatus: "idle",
};

export const step14ConfigSlice = createSlice({
  name: "step14Config",
  initialState,
  reducers: {
    // Add Return Method
    addReturnMethod: (
      state: step14Config,
      params: PayloadAction<{ returnReasons: boolean }>,
    ) => {
      const newReturnMethodId = uuid();
      const { returnReasons } = params.payload;
      state.selectedReturnMethodId = newReturnMethodId;

      state.returnMethods.push({
        returnMethodId: newReturnMethodId,
        returnMethodName: `Return Method ${state.returnMethods.length + 1}`,
        conditions: [],
        selectReturnReasonError: null,
        isAddSelectReasonConditionEnabled: returnReasons,
        returnReasons: returnReasons,
        selectReturnReason: [],
        returnMethodSequence: state.returnMethods.length + 1,
        error: "",
        errorCount: 0,
        itemConditionError: null,
        isAddConditionEnabled: true,
        isAddOrderConditionEnabled: true,
        returnMethodType: {
          value: "notRequired",
          message: "",
          error: null,
        },
        shipWithAnyCarrier: {
          currentState: true,
          showInstruction: false,
          instructions: "",
        },
        shipWithPrepaidLabels: {
          currentState: true,
          showInstruction: false,
          instructions: "",
        },
        isValid: false,
      });
    },

    // Update Return Method
    updateReturnMethod(
      state: step14Config,
      params: PayloadAction<{
        returnMethodDetail: RecursivePartial<ReturnMethodsDetails>;
      }>,
    ) {
      const { returnMethodId, returnMethodName } =
        params.payload.returnMethodDetail;

      const selectedIdx = state.returnMethods.findIndex(
        (ret) => ret.returnMethodId === returnMethodId,
      );

      const selectedMethod = state.returnMethods[selectedIdx];

      if (returnMethodName) selectedMethod.returnMethodName = returnMethodName;
    },

    // Delete Return Method
    deleteReturnMethod: (state, { payload }: { payload: { id: string } }) => {
      const { updatedTabs, newSelectedTabId } = deleteTabHelper({
        tabs: state.returnMethods,
        tabId: payload.id,
        selectedTabId: state.selectedReturnMethodId,
        idKey: "returnMethodId",
      });

      state.returnMethods = updatedTabs;
      state.selectedReturnMethodId = newSelectedTabId;
    },

    pushInitialHash: (state) => {
      const hash = createNewObjectHash({
        excludeKeys: state.excludeKeysForHash,
        hashObject: state.returnMethods,
      });

      if (hash) {
        state.initialHash = hash;
        state.currentHash = hash;
      }
    },

    validateChanges: (state) => {
      const { initialHash } = state;

      const { isChanged, newHash } = validateChangeHandler({
        stateObject: state.returnMethods,
        excludeKeys: state.excludeKeysForHash,
        initialHash,
      });

      if (newHash) {
        state.isChanged = isChanged;
        state.currentHash = newHash;
      }
    },

    resetHashes: (state) => {
      state.initialHash = "";
      state.currentHash = "";
    },

    undoConfig: (
      state,
      params: PayloadAction<{ initialConfig: ReturnMethodsDetails[] | null }>,
    ) => {
      if (params.payload.initialConfig !== null)
        state.returnMethods = params.payload.initialConfig;
    },

    // Validate Return Method
    validateReturnMethod(
      state: step14Config,
      params: PayloadAction<{
        returnMethodId: string;
      }>,
    ) {
      const selectedMethodIdx = state.returnMethods.findIndex(
        (returnMethod) =>
          returnMethod.returnMethodId === params.payload.returnMethodId,
      );

      // 20 Jan Added to validate message on initial load

      let selectedReturn: ReturnMethodsDetails;

      selectedReturn = state.returnMethods[selectedMethodIdx];

      if (
        selectedReturn.returnMethodType.message !== undefined &&
        selectedReturn.returnMethodType.message.length <= 0
      ) {
        selectedReturn.returnMethodType.error = "*Please fill out this field";
      } else {
        selectedReturn.returnMethodType.error = null;
      }
      const errors = [
        selectedReturn.returnMethodType.error,
        selectedReturn.error,
      ];

      selectedReturn.errorCount = errors.reduce((p, c) => {
        if (c !== null && c !== undefined) {
          return p + 1;
        }
        return p;
      }, 0);

      if (state.returnMethods == null) {
        state.error = "Add a Return Method ";
      }
    },

    // Add A Condition
    addCondition: (
      state: step14Config,
      params: PayloadAction<{
        returnMethodId: string;
        condition: string;
        isItemCondition?: boolean;
      }>,
    ) => {
      let selectedMethod = state.returnMethods.find(
        (returnMethod) =>
          returnMethod.returnMethodId === params.payload.returnMethodId,
      );

      const selectedTab = addConditionHandler({
        selectedTab: selectedMethod,
        conditionOptions: params.payload?.isItemCondition
          ? state.itemOptions
          : state.conditionOptions,
        payloadCondition: params.payload.condition,
        isItemCondition: params.payload?.isItemCondition,
      });

      selectedMethod = selectedTab;

      // if (selectedMethod !== undefined) {
      //   let operator: any = "";
      //   let variableValues = null;
      //   let variableName: any = "";

      //   if (params.payload?.isItemCondition === true) {
      //     let itemCondition = state.itemOptions[0];

      //     if (params.payload.condition !== "") {
      //       let addCondition = state.itemOptions.filter(
      //         (value) => value.name === params.payload.condition,
      //       );
      //       if (addCondition.length) {
      //         itemCondition = addCondition[0];
      //       }
      //     }

      //     variableName = itemCondition.name;
      //     operator = itemCondition.operators[0].name;
      //     variableValues = getConditionValueOrDefault(itemCondition.valueType);
      //   } else {
      //     let otherCondition = state.conditionOptions[0];

      //     if (params.payload.condition !== "") {
      //       let addCondition = state.conditionOptions.filter(
      //         (value) => value.name === params.payload.condition,
      //       );
      //       if (addCondition.length) {
      //         otherCondition = addCondition[0];
      //       }
      //     }
      //     variableName = otherCondition.name;
      //     operator = otherCondition.operators[0].name;
      //     variableValues = getConditionValueOrDefault(otherCondition.valueType);
      //   }
      //   selectedMethod.conditions.push({
      //     id: uuid(),
      //     operator: operator,
      //     ruleType: "AND",
      //     values: variableValues,
      //     variableName: variableName,
      //   });
      //   if (params.payload.isItemCondition) {
      //     selectedMethod.isAddConditionEnabled = false;
      //   } else {
      //     // Check if the default value exists
      //     if (
      //       selectedMethod.conditions[selectedMethod.conditions.length - 1]
      //         .values["days"]?.currentValue > 0
      //     ) {
      //       // Add new condition enabled if default value exists
      //       selectedMethod.isAddOrderConditionEnabled = true;
      //     } else {
      //       // Add new condition disabled if no default exists
      //       selectedMethod.isAddOrderConditionEnabled = false;
      //     }
      //   }
      //   if (params.payload.isItemCondition === true) {
      //     if (selectedMethod.itemConditionError !== null) {
      //       selectedMethod.itemConditionError = null;
      //     }
      //   } else {
      //     if (selectedMethod.error !== null) {
      //       selectedMethod.error = null;
      //     }
      //   }
      // }
    },

    addSelectReasonCondition: (
      state: step14Config,
      params: PayloadAction<{
        returnMethodId: string;
      }>,
    ) => {
      const selectedMethod = state.returnMethods.find(
        (returnMethod) =>
          returnMethod.returnMethodId === params.payload.returnMethodId,
      );

      if (selectedMethod !== undefined) {
        let otherCondition = state.selectReasonOptions[0];

        let variableName: any = otherCondition.name;
        let operator = otherCondition.operators[0].name;

        selectedMethod.selectReturnReason.push({
          id: uuid(),
          operator: operator,
          ruleType: "AND",
          values: {
            return_reason: {
              reasonId: "",
              elementId: "",
              operator: "=",
              value: "",
            },
          },
          variableName: variableName,
        });

        selectedMethod.isAddSelectReasonConditionEnabled = false;
        if (selectedMethod.error !== null) {
          selectedMethod.error = null;
        }
        if (selectedMethod.selectReturnReasonError !== null) {
          selectedMethod.selectReturnReasonError = null;
        }
      }
    },

    updateSelectReasonCondition(
      state: step14Config,
      params: PayloadAction<{
        returnMethodId: string;
        conditionToUpdate: RecursivePartial<ReturnReason>;
      }>,
    ) {
      const { returnMethodId, conditionToUpdate } = params.payload;

      const selectedMethodIdx = state.returnMethods.findIndex(
        (returnMethod) => returnMethod.returnMethodId === returnMethodId,
      );

      if (conditionToUpdate) {
        const selectedMethod = state.returnMethods[selectedMethodIdx];

        const conditions = selectedMethod.selectReturnReason;
        const conditionIdx = conditions.findIndex(
          (selectReturnReason) =>
            selectReturnReason.id === conditionToUpdate.id,
        );
        if (conditionIdx !== -1) {
          const prevCondition = conditions[conditionIdx];
          Object.assign(prevCondition, conditionToUpdate);
          // prevCondition.values.return_reason.error = undefined;
          // Added to fix run time error i.e.,error is read only
          const updatedCondition = {
            ...prevCondition,
            values: {
              ...conditions[conditionIdx].values,
              return_reason: {
                ...conditions[conditionIdx].values.return_reason,
                error: undefined,
              },
            },
          };
          conditions[conditionIdx] = updatedCondition;
        }

        const lastCondition = conditions[conditionIdx];
        if (lastCondition?.values) {
          Object.entries<any>(lastCondition.values).forEach(([key, value]) => {
            switch (key) {
              case "tags": {
                if (value.currentValue.length > 0) {
                  selectedMethod.isAddSelectReasonConditionEnabled = true;
                } else {
                  selectedMethod.isAddSelectReasonConditionEnabled = false;
                }
                break;
              }

              case "returns": {
                if (value.currentValue.length > 0) {
                  selectedMethod.isAddSelectReasonConditionEnabled = true;
                } else {
                  selectedMethod.isAddSelectReasonConditionEnabled = false;
                }
                break;
              }

              case "none": {
                selectedMethod.isAddSelectReasonConditionEnabled = false;
                break;
              }

              case "items": {
                if (value.currentValue.length > 0) {
                  selectedMethod.isAddSelectReasonConditionEnabled = true;
                } else {
                  selectedMethod.isAddSelectReasonConditionEnabled = false;
                }
                break;
              }

              case "days": {
                if (value.currentValue > 0) {
                  selectedMethod.isAddSelectReasonConditionEnabled = true;
                } else {
                  selectedMethod.isAddSelectReasonConditionEnabled = false;
                }
                break;
              }

              case "codes": {
                if (value.currentValue.length > 0) {
                  selectedMethod.isAddSelectReasonConditionEnabled = true;
                } else {
                  selectedMethod.isAddSelectReasonConditionEnabled = false;
                }
                break;
              }

              case "numbers": {
                if (value.currentValue > 0) {
                  selectedMethod.isAddSelectReasonConditionEnabled = true;
                } else {
                  selectedMethod.isAddSelectReasonConditionEnabled = false;
                }
                break;
              }

              case "countryName": {
                if (value.currentValue.length > 0) {
                  selectedMethod.isAddSelectReasonConditionEnabled = true;
                } else {
                  selectedMethod.isAddSelectReasonConditionEnabled = false;
                }
                break;
              }
              case "amount": {
                if (lastCondition.operator === "is between") {
                  if (
                    value.currentValue.minValue !== undefined ||
                    value.currentValue.maxValue !== undefined
                  ) {
                    if (
                      value.currentValue.minValue === undefined ||
                      value.currentValue.maxValue === undefined
                    ) {
                      selectedMethod.isAddSelectReasonConditionEnabled = false;
                      break;
                    } else if (
                      isNaN(value.currentValue.minValue) ||
                      isNaN(value.currentValue.maxValue)
                    ) {
                      selectedMethod.isAddSelectReasonConditionEnabled = false;
                      break;
                    } else {
                      const valid = validateRange(
                        value.currentValue.minValue.toString(),
                        value.currentValue.maxValue.toString(),
                      );
                      if (!valid) {
                        selectedMethod.isAddSelectReasonConditionEnabled =
                          false;
                        break;
                      } else {
                        selectedMethod.isAddSelectReasonConditionEnabled = true;
                        break;
                      }
                    }
                  } else {
                    selectedMethod.isAddSelectReasonConditionEnabled = false;
                    break;
                  }
                }
                if (value.currentValue.length > 0) {
                  selectedMethod.isAddSelectReasonConditionEnabled = true;
                } else {
                  selectedMethod.isAddSelectReasonConditionEnabled = false;
                }
                break;
              }

              case "reasons": {
                if (value.currentValue.length > 0) {
                  selectedMethod.isAddSelectReasonConditionEnabled = true;
                } else {
                  selectedMethod.isAddSelectReasonConditionEnabled = false;
                }
                break;
              }
              case "return_reason": {
                //When no reason selected
                if (!value.reasonId) {
                  value.error = "Please fill out this field";
                  break;
                }

                let reason;
                // Get the reason based on id from default return reasons
                if (
                  state.returnReasonList &&
                  state.returnReasonList.length > 0
                ) {
                  reason = (state.returnReasonList as ReasonTypes[]).find(
                    (item) => item.id === value.reasonId,
                  );
                }
                // Check if a reason is found
                if (reason) {
                  // Always check for reasonId first
                  if (!value.reasonId) {
                    selectedMethod.isAddSelectReasonConditionEnabled = false;
                  } else {
                    // Check if there are more questions for the reason
                    if (
                      reason.moreQuestions &&
                      reason.moreQuestions.length > 0
                    ) {
                      const moreQuestion = reason.moreQuestions.find(
                        (question) => question.elementId === value.elementId,
                      );
                      if (moreQuestion) {
                        // If additionalOptions exist, check all properties
                        if (
                          moreQuestion.additionalOptions &&
                          moreQuestion.additionalOptions.length > 0
                        ) {
                          if (
                            !value.reasonId ||
                            !value.operator ||
                            !value.elementId ||
                            !value.value
                          ) {
                            selectedMethod.isAddSelectReasonConditionEnabled =
                              false;
                          } else {
                            selectedMethod.isAddSelectReasonConditionEnabled =
                              true;
                          }
                        } else {
                          // If no additionalOptions, check for reasonId and elementId
                          if (!value.reasonId || !value.elementId) {
                            selectedMethod.isAddSelectReasonConditionEnabled =
                              false;
                          } else {
                            selectedMethod.isAddSelectReasonConditionEnabled =
                              true;
                          }
                        }
                      } else {
                        selectedMethod.isAddSelectReasonConditionEnabled =
                          false;
                      }
                    }
                  }
                } else {
                  selectedMethod.isAddSelectReasonConditionEnabled = false;
                }
                break;
              }

              default:
                break;
            }
            if (selectedMethod.isAddSelectReasonConditionEnabled) {
              if (selectedMethod.selectReturnReasonError !== null) {
                selectedMethod.selectReturnReasonError = null;
              }
            }
          });
        }
      }
    },

    // Delete Condition
    deleteSelectReasonCondition: (
      state: step14Config,
      params: PayloadAction<{ returnMethodId: string; conditionId: string }>,
    ) => {
      const { returnMethodId, conditionId } = params.payload;

      const selectedMethodIdx = state.returnMethods.findIndex(
        (returnMethod) => returnMethod.returnMethodId === returnMethodId,
      );
      if (selectedMethodIdx !== -1) {
        const selectedReturnMethods = state.returnMethods[selectedMethodIdx];
        selectedReturnMethods.selectReturnReason =
          selectedReturnMethods.selectReturnReason.filter(
            (condition) => condition.id !== conditionId,
          );

        if (selectedReturnMethods.selectReturnReason.length === 0) {
          // selectedReturnMethods.isAddConditionEnabled = true;
          // selectedReturnMethods.isAddOrderConditionEnabled = true;
          selectedReturnMethods.isAddSelectReasonConditionEnabled = true;
        }
        selectedReturnMethods.isAddSelectReasonConditionEnabled = true;
      }
    },

    // Update Conditon
    updateCondition(
      state: step14Config,
      params: PayloadAction<{
        returnMethodId: string;
        conditionToUpdate: RecursivePartial<ConditionTypes>;
      }>,
    ) {
      const { returnMethodId, conditionToUpdate } = params.payload;

      const selectedMethodIdx = state.returnMethods.findIndex(
        (returnMethod) => returnMethod.returnMethodId === returnMethodId,
      );

      if (conditionToUpdate) {
        const selectedMethod = state.returnMethods[selectedMethodIdx];

        const conditions = selectedMethod.conditions;

        const {
          updatedConditions,
          isAddOrderConditionEnabled,
          isAddItemConditionEnabled,
          isAddReasonConditionEnabled,
        } = updateConditionHelper({
          conditions,
          conditionToUpdate,
          returnReasonList: state.returnReasonList,
        });

        state.returnMethods[selectedMethodIdx].conditions = updatedConditions;

        if (isAddOrderConditionEnabled !== null) {
          state.returnMethods[selectedMethodIdx].isAddOrderConditionEnabled =
            isAddOrderConditionEnabled;
        }

        if (isAddItemConditionEnabled !== null) {
          state.returnMethods[selectedMethodIdx].isAddConditionEnabled =
            isAddItemConditionEnabled;
        }

        if (isAddReasonConditionEnabled !== null) {
          state.returnMethods[
            selectedMethodIdx
          ].isAddSelectReasonConditionEnabled = isAddReasonConditionEnabled;
        }
      }
    },

    // Delete Condition
    deleteCondition: (
      state: step14Config,
      params: PayloadAction<{
        returnMethodId: string;
        conditionId: string;
        isItemCondition?: boolean;
      }>,
    ) => {
      const { returnMethodId, conditionId, isItemCondition } = params.payload;

      const selectedMethodIdx = state.returnMethods.findIndex(
        (returnMethod) => returnMethod.returnMethodId === returnMethodId,
      );
      if (selectedMethodIdx !== -1) {
        const selectedReturnMethod = state.returnMethods[selectedMethodIdx];
        const {
          updatedConditions,
          isAddOrderConditionEnabled,
          isAddItemConditionEnabled,
        } = deleteConditionHelper({
          conditionId: conditionId,
          conditions: selectedReturnMethod.conditions,
          conditionType: isItemCondition ? "item" : "order",
        });

        state.returnMethods[selectedMethodIdx].conditions = updatedConditions;

        if (isAddOrderConditionEnabled !== null) {
          state.returnMethods[selectedMethodIdx].isAddOrderConditionEnabled =
            isAddOrderConditionEnabled;
        }

        if (isAddItemConditionEnabled !== null) {
          state.returnMethods[selectedMethodIdx].isAddConditionEnabled =
            isAddItemConditionEnabled;
        }
      }
    },

    // Validate Condition
    validateCondition(
      state: step14Config,
      params: PayloadAction<{
        returnMethodId: string;
      }>,
    ) {
      const selectedMethodIdx = state.returnMethods.findIndex(
        (returnMethod) =>
          returnMethod.returnMethodId === params.payload.returnMethodId,
      );

      const selectedMethod = state.returnMethods[selectedMethodIdx];

      if (selectedMethod.returnMethodType.message === "") {
        selectedMethod.returnMethodType.error = "Please fill out this field";
      }

      let itemConditionNames = Object.values(state.itemOptions).map(
        (option) => option.name,
      );

      const conditions = selectedMethod.conditions;

      if (selectedMethod.returnReasons) {
        if (
          selectedMethodIdx !== 0 &&
          selectedMethod.selectReturnReason.length <= 0
        ) {
          selectedMethod.selectReturnReasonError =
            "Atleast add one condition required";
        } else {
          selectedMethod.selectReturnReasonError = null;
        }
      }

      if (
        conditions.filter(
          (condition) => !itemConditionNames.includes(condition.variableName),
        ).length <= 0
      ) {
        selectedMethod.error = "Atleast add one condition required";
      }

      if (
        selectedMethodIdx !== 0 &&
        conditions.filter((condition) =>
          itemConditionNames.includes(condition.variableName),
        ).length <= 0
      ) {
        selectedMethod.itemConditionError =
          "Atleast add one condition required";
      }

      // Validating conditions using helper
      const {
        updatedConditions,
        isAddOrderConditionEnabled,
        isAddItemConditionEnabled,
        isAddReasonConditionEnabled,
      } = validateConditionsHelper({
        selectedTab: selectedMethod,
        itemOptions: state.itemOptions,
        conditionOptions: state.conditionOptions,
        step: "step14",
        returnReasonList: state.returnReasonList,
      });

      state.returnMethods[selectedMethodIdx].conditions = updatedConditions;
      if (isAddOrderConditionEnabled !== null) {
        state.returnMethods[selectedMethodIdx].isAddOrderConditionEnabled =
          isAddOrderConditionEnabled;
      }

      if (isAddItemConditionEnabled !== null) {
        state.returnMethods[selectedMethodIdx].isAddConditionEnabled =
          isAddItemConditionEnabled;
      }

      if (isAddReasonConditionEnabled !== null) {
        state.returnMethods[
          selectedMethodIdx
        ].isAddSelectReasonConditionEnabled = isAddReasonConditionEnabled;
      }

      const errors = [...conditions]
        .concat(
          selectedMethod.returnReasons
            ? [...selectedMethod.selectReturnReason]
            : [],
        )
        .flatMap((condition) => {
          return Object.entries(condition.values).flatMap(
            ([key, value]: [any, any]) => {
              if (value.error === undefined) return null;
              return value.error;
            },
          );
        })
        .concat([
          selectedMethod.error,
          selectedMethod.returnMethodType.error,
          selectedMethod.itemConditionError,
          selectedMethod.isAddSelectReasonConditionEnabled
            ? selectedMethod.selectReturnReasonError
            : null,
        ]);

      selectedMethod.errorCount = errors.reduce((p, c) => {
        if (c !== null && c !== undefined) {
          return p + 1;
        }
        return p;
      }, 0);

      if (selectedMethod.errorCount > 0) {
        selectedMethod.isValid = false;
      } else {
        selectedMethod.isValid = true;
      }
    },

    validateStep14(state: step14Config) {
      let itemConditionNames = Object.values(state.itemOptions).map(
        (option) => option.name,
      );

      state.returnMethods.forEach((selectedMethod, selectedMethodIdx) => {
        const conditions = selectedMethod.conditions;
        // validateCondition({
        //   returnMethodId:selectedMethod.returnMethodId
        // })
        if (
          conditions.filter(
            (condition) => !itemConditionNames.includes(condition.variableName),
          ).length <= 0
        ) {
          selectedMethod.error = "Atleast add one condition required";
        }

        if (selectedMethod.returnReasons) {
          if (
            selectedMethodIdx !== 0 &&
            selectedMethod.selectReturnReason.length <= 0
          ) {
            selectedMethod.selectReturnReasonError =
              "Atleast add one condition required";
          }
        }

        if (
          selectedMethodIdx !== 0 &&
          conditions.filter((condition) =>
            itemConditionNames.includes(condition.variableName),
          ).length <= 0
        ) {
          selectedMethod.itemConditionError =
            "Atleast add one condition required";
        }
        if (
          selectedMethod.returnMethodType.message !== undefined &&
          selectedMethod.returnMethodType.message.length <= 0
        ) {
          selectedMethod.returnMethodType.error = "Please fill out this field";
        }

        // Validating conditions using helper
        const {
          updatedConditions,
          isAddOrderConditionEnabled,
          isAddItemConditionEnabled,
          isAddReasonConditionEnabled,
        } = validateConditionsHelper({
          selectedTab: selectedMethod,
          itemOptions: state.itemOptions,
          conditionOptions: state.conditionOptions,
          step: "step14",
          returnReasonList: state.returnReasonList,
        });

        state.returnMethods[selectedMethodIdx].conditions = updatedConditions;
        if (isAddOrderConditionEnabled !== null) {
          state.returnMethods[selectedMethodIdx].isAddOrderConditionEnabled =
            isAddOrderConditionEnabled;
        }

        if (isAddItemConditionEnabled !== null) {
          state.returnMethods[selectedMethodIdx].isAddConditionEnabled =
            isAddItemConditionEnabled;
        }

        if (isAddReasonConditionEnabled !== null) {
          state.returnMethods[
            selectedMethodIdx
          ].isAddSelectReasonConditionEnabled = isAddReasonConditionEnabled;
        }

        const errors = [...conditions]
          .concat(
            selectedMethod.returnReasons
              ? [...selectedMethod.selectReturnReason]
              : [],
          )
          .flatMap((condition) => {
            return Object.entries(condition.values).flatMap(
              ([key, value]: [any, any]) => {
                if (value.error === undefined) return null;
                return value.error;
              },
            );
          })
          .concat([
            selectedMethod.error,
            selectedMethod.returnMethodType.error,
            selectedMethod.itemConditionError,
            selectedMethod.selectReturnReasonError,
          ]);

        selectedMethod.errorCount = errors.reduce((p, c) => {
          if (c !== null && c !== undefined) {
            return p + 1;
          }
          return p;
        }, 0);

        if (selectedMethod.errorCount > 0) {
          selectedMethod.isValid = false;
        } else {
          selectedMethod.isValid = true;
        }
      });
    },
    // Set Selected Return Method
    setSelectedReturnMethodId(
      state: step14Config,
      params: PayloadAction<{ returnMethodId: string }>,
    ) {
      state.selectedReturnMethodId = params.payload.returnMethodId;
    },

    // Update Enable/Disable Current State

    updateEnableDisableCurrentState(
      state,
      params: PayloadAction<{
        selectedId: string;
        type: string;
        currentState: boolean;
      }>,
    ) {
      const selectedMethodIdx = state.returnMethods.findIndex(
        (returnMethod) =>
          returnMethod.returnMethodId === params.payload.selectedId,
      );

      switch (params.payload.type) {
        case "Carrier":
          state.returnMethods[
            selectedMethodIdx
          ].shipWithAnyCarrier.currentState = params.payload.currentState;
          break;

        case "Label":
          state.returnMethods[
            selectedMethodIdx
          ].shipWithPrepaidLabels.currentState = params.payload.currentState;
          break;

        default:
          break;
      }
    },

    updateEnableDisableinstructions(
      state,
      params: PayloadAction<{
        selectedId: string;
        type: string;
        instructions: string;
      }>,
    ) {
      const selectedMethodIdx = state.returnMethods.findIndex(
        (returnMethod) =>
          returnMethod.returnMethodId === params.payload.selectedId,
      );

      switch (params.payload.type) {
        case "Carrier":
          state.returnMethods[
            selectedMethodIdx
          ].shipWithAnyCarrier.instructions = params.payload.instructions;
          break;

        case "Label":
          state.returnMethods[
            selectedMethodIdx
          ].shipWithPrepaidLabels.instructions = params.payload.instructions;
          break;

        default:
          break;
      }
    },
    pushCurrentHashForSelectedTab: (
      state,
      params: PayloadAction<{ returnMethodId: string | null }>,
    ) => {
      const selectedReturnTypeIdx = state.returnMethods.findIndex(
        (rType) => rType.returnMethodId === params.payload.returnMethodId,
      );

      state.currentHash = objectHash(
        state.returnMethods[selectedReturnTypeIdx],
      );
    },

    updateReturnMethodType(
      state,
      params: PayloadAction<{
        returnMethodId: string | null;
        type: ReturnMethodType;
        message: string;
      }>,
    ) {
      const selectedMethodIdx = state.returnMethods.findIndex(
        (returnMethod) =>
          returnMethod.returnMethodId === params.payload.returnMethodId,
      );
      state.returnMethods[selectedMethodIdx].returnMethodType = {
        message: params.payload.message,
        value: params.payload.type,
        error: null,
      };
    },

    showInstruction(
      state,
      params: PayloadAction<{
        selectedId: string;
        type: string;
        showInstruction: boolean;
      }>,
    ) {
      const selectedMethodIdx = state.returnMethods.findIndex(
        (returnMethod) =>
          returnMethod.returnMethodId === params.payload.selectedId,
      );

      switch (params.payload.type) {
        case "Carrier":
          state.returnMethods[
            selectedMethodIdx
          ].shipWithAnyCarrier.showInstruction = params.payload.showInstruction;
          break;

        case "Label":
          state.returnMethods[
            selectedMethodIdx
          ].shipWithPrepaidLabels.showInstruction =
            params.payload.showInstruction;
          break;

        default:
          break;
      }
    },
    //Reset the step 14 configuration to the initial state
    resetStep14Config: (state: step14Config) => {
      return initialState;
    },
  },
  extraReducers: step14Builders,
});

export default step14ConfigSlice.reducer;

export const step14ConfigErrorCountSelector = createSelector(
  [step14ConfigErrorCount],
  (step14ConfigErrorCount) => step14ConfigErrorCount,
);

export const {
  addReturnMethod,
  updateReturnMethod,
  setSelectedReturnMethodId,
  deleteReturnMethod,
  validateReturnMethod,
  updateCondition,
  validateCondition,
  deleteCondition,
  addCondition,
  updateEnableDisableCurrentState,
  updateEnableDisableinstructions,
  showInstruction,
  pushInitialHash,
  validateChanges,
  undoConfig,
  resetHashes,
  pushCurrentHashForSelectedTab,
  updateReturnMethodType,
  validateStep14,
  deleteSelectReasonCondition,
  addSelectReasonCondition,
  updateSelectReasonCondition,
  resetStep14Config,
} = step14ConfigSlice.actions;
