import doneIcon from "src/assets/images/doneProcess.png";
import successImg from "src/assets/images/successMsg.png";

import styles from "./TimelineUi.module.scss";
import { useAppSelector } from "src/store/store";
import { Spinner } from "react-bootstrap";

/**
 * Props for the TimelineUi component
 */
interface StepProps {
  /** Step number */
  stepNumber: number;
  /** Step title */
  title: string;
  /** Indicates whether the step is completed */
  done: boolean;
  /** Indicates whether the step is active */
  active: boolean;
  /** Function to show modal */
  showModal: () => void;
  configureId: string;
  type: "emailSetup" | "liveChatSetup";
  finalStepNumber: number;
}

/**
 * Component for displaying timeline steps
 * @param props Props object containing step number, title, completion status, activity status, and function to show modal
 * @returns TimelineUi component
 */
const TimelineUi = ({
  stepNumber,
  title,
  done,
  active,
  showModal,
  configureId,
  type,
  finalStepNumber,
}: StepProps) => {
  const { createNewWebIntAJAXStatus } = useAppSelector(
    (state) => state.websiteSettings,
  );

  return (
    <div className={`d-flex align-items-start`}>
      <div>
        <div
          className={`d-flex align-items-center justify-content-center`}
          style={{ width: "11px" }}
        >
          {/* Step Status */}
          {done ? (
            <img
              src={active ? successImg : doneIcon}
              alt=""
              className={`${styles.doneImg}`}
            />
          ) : (
            <div>
              {/* Step Number */}
              <span
                className={`d-flex justify-content-center align-items-center ${
                  styles.step2
                } ${active ? styles.active : ""}`}
              >
                {stepNumber}
              </span>
            </div>
          )}
        </div>

        {/* Dash between steps */}
        {stepNumber !== finalStepNumber && (
          <div
            className={`my-1 ${styles.borderSpace} ${
              done && styles.doneBorder
            } ${active && styles.activeBorder}`}
          ></div>
        )}
      </div>

      {/* Step Title and configure status */}
      <div>
        <p
          className={`mb-0 ps-2 ${styles.stepTitle} ${
            active ? styles.activeTitle : ""
          }`}
        >
          {title}
        </p>
        {!done && active && (
          <>
            {/* Configure button */}
            <div className="">
              <button
                className={`${styles.btn}`}
                onClick={showModal}
                id={`${configureId}`}
                disabled={
                  type === "liveChatSetup" &&
                  createNewWebIntAJAXStatus === "pending"
                }
              >
                Configure
                {/* If it is live chat setup and we have pending status then show loader */}
                {type === "liveChatSetup" &&
                  createNewWebIntAJAXStatus === "pending" && (
                    <Spinner
                      className="mx-2"
                      size="sm"
                      animation="border"
                    />
                  )}
              </button>

              {/* Step Pending - Hide for train ai setup */}
              {stepNumber !== 5 && (
                <span className={`ps-1 ${styles.pendingText}`}>
                  <i className="pe-1 fa-solid fa-circle-exclamation"></i>{" "}
                  Pending
                </span>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TimelineUi;
