/**
 * This file is the service file used for making api request.
 * It contains the notifyEnableDisableService function service which is explained below.
 *
 * @author Yash Aditya
 */

// Import statements for types
import { axiosJSON } from "src/globals/axiosEndPoints";

// Type for the enable or disable notifications API request parameters
export interface NotifyEnableDisableParams {
  enabled: boolean;
}

// Function to update the enable or disable notifications status
export const notifyEnableDisableService = async (
  params: NotifyEnableDisableParams
) => {
  const { data: res } = await axiosJSON.post(
    "/api/reports/notificationSettings/enableOrDisableNotifications",
    params
  );

  if (res.err) {
    throw res.msg;
  }

  return true;
};
