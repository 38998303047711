import { useState } from "react";
import styles from "./GmailStepsImap.module.scss";

const googleLink =
  "https://myaccount.google.com/apppasswords?pli=1&rapt=AEjHL4Ps_8L-LuiCBkuqaGpZDt2GaQYGo0P-zV9QSo4ZM2_Lcu5vVI7M9v-kTIDmtR_W_jnv9AMBzLPlrAPbH8TOaZH85nqxTD14604GepwQoCSlLOs2Yi0";

const GmailStepsImap = () => {
  const [ddOpen, setDDOpen] = useState(false);

  return (
    <>
      <div className={`px-3 py-2  ${styles.setupBox}`}>
        <div
          className={`cursor-pointer d-flex justify-content-between`}
          onClick={() => setDDOpen((prev) => !prev)}
        >
          <span className={`pe-2 ${styles.setupSpan}`}>
            How to create app password in Gmail ?
          </span>
          <span className={`cursor-pointer ${styles.setupSpan}`}>
            <i
              className={
                ddOpen ? "fa-solid fa-chevron-up" : "fa-solid fa-chevron-down"
              }
            ></i>
          </span>
        </div>
      </div>
      {ddOpen && (
        <div className={`px-3 py-2 ${styles.ddWrapper}`}>
          <div className={`d-flex`}>
            <span className={`mb-1 ${styles.stepSpan} ${styles.stepDiv}`}>
              Step 1 :
            </span>
            <span className={` mb-1 ${styles.stepSpan}  ${styles.msgDiv}`}>
              Navigate to "Manage Google Account."
            </span>
          </div>
          <div className={`d-flex`}>
            <span className={` ${styles.stepSpan} ${styles.stepDiv}`}>
              Step 2 :
            </span>
            <span className={`mb-1 ${styles.stepSpan} ${styles.msgDiv}`}>
              Access the "Security" section and enable 2-step authentication.
            </span>
          </div>
          <div className={`d-flex`}>
            <span className={`mb-1 ${styles.stepSpan} ${styles.stepDiv}`}>
              Step 3 :
            </span>
            <span className={`mb-1 ${styles.stepSpan}  ${styles.msgDiv}`}>
              Once 2-step authentication is completed, proceed to the "Add
              Password" option or{" "}
              <a
                href={googleLink}
                target="_blank"
                rel="noreferrer"
              >
                click here
              </a>
              . Enter the app name (e.g., Saufter), copy the 16-digit password
              (e.g., "sjhu xjql uhgo oxar"), and make a note of it for future
              reference.
            </span>
          </div>
          <div className={`d-flex`}>
            <span className={`mb-1 ${styles.stepSpan} ${styles.stepDiv}`}>
              Step 4 :
            </span>
            <span className={`mb-1 ${styles.stepSpan}  ${styles.msgDiv}`}>
              Finalize the mail settings provided above for IMAP
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default GmailStepsImap;
