import styles from "./ThemeModal.module.scss";

import {
  Theme,
  ThemeData,
} from "src/services/KnowledgeBase/Settings/Styles/getAllThemes.service";
import PreviewBox from "./Children/PreviewBox/PreviewBox";
import Loader from "src/components/Loader";
import useThemeModal from "./useThemeModal";
import InfiniteScroll from "src/components/InfiniteScrollBothSide";
import preview from "src/assets/images/theme-preview.png";
import preview2 from "src/assets/images/preview2.png";
interface Props {
  onHide: () => void;
  themeType: "alice_in_wonderland" | "night_in_the_oscars";
}

const ThemeModal = ({ onHide, themeType }: Props) => {
  const {
    getAllThemesCall,
    isLoading,
    isError,
    error,
    fetchNextPage,
    hasNextPage,
  } = useThemeModal();

  if (isLoading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  if (isError) {
    return <span>Error : {error as any}</span>;
  }
  return (
    <div className={`${styles.themeWrapper} pb-2`}>
      <div className="d-flex justify-content-end align-items-center">
        <span className={`cursor-pointer ${styles.closeBtn}`} onClick={onHide}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <g id="MDI_close" data-name="MDI / close" opacity="0.6">
              <g
                id="Boundary"
                stroke="rgba(0,0,0,0)"
                stroke-width="1"
                opacity="0"
              >
                <rect width="24" height="24" stroke="none" />
                <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
              </g>
              <path
                id="Path_close"
                data-name="Path / close"
                d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
              />
            </g>
          </svg>
        </span>
      </div>
      <div className={`text-center ${styles.themeTop}`}>
        <h1 className={`${styles.modalheading}`}> Choose your theme</h1>
        <p className={`${styles.modalSubHeading}`}>
          You will be able to change this at anytime in the future.
        </p>
      </div>
      <div className={`pt-5 d-flex justify-content-center `}>
        {isLoading ? (
          <div>
            <Loader />
          </div>
        ) : (
          <div style={{ height: "55vh" }}>
            <InfiniteScroll
              loadMoreFromBottom={fetchNextPage}
              hasMoreBottom={hasNextPage}
              className={`h-100 overflow-auto d-flex justify-content-center flex-wrap`}
            >
              {getAllThemesCall?.length === 0 ? (
                <div className="text-center mt-5">
                  <h4 className={`${styles.errorMsg}`}>No themes found</h4>
                </div>
              ) : (
                getAllThemesCall?.map((theme: Theme) => {
                  console.log(theme);
                  return (
                    <div
                      className={`mb-2 ${styles.previewBox} ${
                        theme.themeType === themeType
                          ? styles.previewBoxActive
                          : ""
                      }`}
                    >
                      <PreviewBox themeModalHide={onHide} themeData={theme} />
                    </div>
                  );
                })
              )}
            </InfiniteScroll>
          </div>
        )}
      </div>
    </div>
  );
};

export default ThemeModal;
