import styles from "./PassProtect.module.scss";
import caution from "src/assets/images/errorImg.png";
import passShow from "src/assets/images/passShow.png";
import passHide from "src/assets/images/passHide.png";
import { useState } from "react";
import { UseMutationResult, useMutation } from "@tanstack/react-query";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import Loader from "src/components/Loader";
import usePassProtect from "./usePassProtect";
import {
  signInPayload,
  signInResponse,
} from "src/services/KnowledgeBase/Settings/Preview/signIn.service";
import { useLocation } from "react-router-dom";

export interface Props {
  callSignIn: UseMutationResult<
    signInResponse,
    unknown,
    signInPayload,
    unknown
  >;
  handleSignIn: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  showPass: boolean;
  setShowPass: React.Dispatch<React.SetStateAction<boolean>>;
  passWord: string;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  showValidation: boolean;
  setShowValidation: React.Dispatch<React.SetStateAction<boolean>>;
}

const PassProtect = ({
  callSignIn,
  handleSignIn,
  showPass,
  setShowPass,
  passWord,
  setPassword,
  showValidation,
  setShowValidation,
}: Props) => {
  const location = useLocation();

  const hostname = window.location.hostname;

  return (
    <div className={`${styles.passWrapper}`}>
      <h4 className={`mb-3 ${styles.header}`}>Sign in</h4>
      <p className={`mb-3 ${styles.url}`}>
        {`http://${hostname}${location.pathname}`}
      </p>
      <form>
        <div className="mb-3 row">
          <label
            htmlFor="staticEmail"
            className={`col-sm-2 col-form-label ${styles.formLabel}`}
          >
            Password
          </label>
          <div className="ps-lg-4 col-sm-10 position-relative">
            <input
              type={showPass ? "text" : "password"}
              className={`form-control ${styles.inputBox}`}
              id="inputPassword"
              required={true}
              onChange={(e) => {
                setShowValidation(false);
                setPassword(e.target.value);
              }}
              value={passWord}
            />
            {/* show below img to show pass  */}
            {!showValidation ? (
              <img
                src={showPass ? passShow : passHide}
                alt="hide"
                className={`${showPass ? styles.passShow : styles.passHide}`}
                onClick={() => setShowPass(!showPass)}
              />
            ) : (
              <img src={caution} alt="" className={` ${styles.errorImg}`} />
            )}
            {showValidation && (
              <span className={` ${styles.errorMsg}`}>
                Please enter a valid password here
              </span>
            )}
          </div>
        </div>
        <div className="pt-2 d-flex justify-content-end">
          <button className={`me-3 ${styles.signIn}`} onClick={handleSignIn}>
            Sign in
            {callSignIn.isLoading && (
              <div
                className="spinner-border spinner-border-sm ms-2"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            )}
            <span></span>
          </button>
          <button className={`${styles.cancelBtn}`}>Cancel</button>
        </div>
      </form>
    </div>
  );
};

export default PassProtect;
