/**
 * This file is the custom hook and context file.
 * It contains useSegmentViewCreate and useSegmentView custom hooks.
 *
 * @author Yash Aditya
 * @fileoverview Custom hooks and context for managing segment view state.
 */

import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
  useRef,
} from "react";
import { AJAXSTATUS, ActionDispatch } from "src/globals/constants";
import { useCustomerSegments } from "src/routes/CustomerSegments/hooks/useCustomerSegments";
import { SegmentColumnsResponse } from "src/services/CustomerSegments/getSegmentColumns.service";
import { SegmentFilterOption } from "src/services/CustomerSegments/getSegmentFilters.service";
import getSegmentValuesService, {
  AllSegmentValues,
  AppliedFilters,
  CustomerSegmentParams,
} from "src/services/CustomerSegments/getSegmentValues.service";
import useFetchColumns from "./useFetchColumns";

/**
 * Constant for segment values limit.
 */
const SEGMENT_VALUES_LIMIT = 50;

/**
 * Interface representing the state for segment view state.
 *
 * @interface SegmentViewState
 */
export interface SegmentViewState
  extends SegmentColumnsResponse,
    AllSegmentValues {
  // Status for fetching more columns.
  moreColsFetching: AJAXSTATUS;
  // Status for fetching segment values.
  segmentValuesFetching: AJAXSTATUS;
  localSegmentFetching: AJAXSTATUS;
  // Text for searching.
  searchText: string;
  /**
   * Here string key in Record is filterKey
   */
  selectedFilters: Record<string, AppliedFilters>;
  /**
   * Here string key in Record is filterKey
   */
  appliedFilters: Record<string, AppliedFilters>;
  // Params to figure out start
  segmentValuesParams: CustomerSegmentParams | null;
}

/**
 * Initial state for the segment view state.
 */
const segmentView: SegmentViewState = {
  // Initial status for more columns fetching.
  moreColsFetching: "pending",
  // Initial status for segment values fetching.
  segmentValuesFetching: "fulfilled",
  localSegmentFetching: "fulfilled",
  // Initial columns.
  allColumns: {},
  // Initial column keys.
  allColumnKeys: [],
  // Initial segment values.
  segmentValues: {},
  // Initial segment value IDs.
  segmentValueIds: [],
  // Initial status for more segment view.
  hasMoreSegmentView: false,
  // Initial total segment values.
  totalSegmentValues: 0,
  // Initial search text.
  searchText: "",
  // Initial selected filters.
  selectedFilters: {},
  // Initial applied filters.
  appliedFilters: {},
  // Initial applied filters.
  segmentValuesParams: null,
};

/**
 * Reducer function to handle state modifications.
 * @function
 * @param state - The current state.
 * @param actionPayload - Array containing the action and payload.
 * @returns The new state after applying the action.
 */
const reducerMethod = (
  state: SegmentViewState,
  [action, payload]: [
    (value: SegmentViewState, payload?: any) => void,
    undefined | any,
  ],
): SegmentViewState => {
  // Execute the action function with current state and payload
  action(state, payload);
  // Return a new state object to ensure immutability
  return { ...state };
};

/**
 * Context for segment view state.
 */
const SegmentViewContext = createContext([segmentView, () => {}] as [
  SegmentViewState,
  ActionDispatch<typeof SegmentViewAction>,
]);

/**
 * Custom hook to create context with a reducer.
 */
export const useSegmentViewCreate = () => {
  const { activeSegmentDetails } = useCustomerSegments();
  // Fetch columns.
  const { columns, columnsStatus } = useFetchColumns(
    activeSegmentDetails.activeSegmentType ?? "",
    activeSegmentDetails.activeSegmentId ?? undefined,
  );
  // Use the reducer to manage state
  const [state, dispatch] = useReducer(reducerMethod, {
    // Initialize state with default values
    ...segmentView,
  });

  // Memoize the modified dispatch function using useCallback
  const modifiedDispatch: ActionDispatch<typeof SegmentViewAction> =
    useCallback(
      (action, payload) => {
        // Wrap dispatch with action and payload
        dispatch([SegmentViewAction[action], payload]);
      },
      [dispatch],
    );

  /**
   * Fetches the initial segment data if an update action is required.
   */
  useMemo(() => {
    if (
      // Check if activeSegmentId exists.
      activeSegmentDetails.activeSegmentId &&
      // Check if activeSegmentType exists.
      activeSegmentDetails.activeSegmentType
    ) {
      modifiedDispatch("setSegmentView", {
        moreColsFetching: columnsStatus,
        // Set column keys if available.
        allColumnKeys: columns?.allColumnKeys ?? [],
        // Set columns if available.
        allColumns: columns?.allColumns ?? {},
      });
    }
  }, [
    modifiedDispatch,
    `${activeSegmentDetails.activeSegmentId}::${activeSegmentDetails.activeSegmentType}::${columns}::${columnsStatus}`,
  ]);

  // Return the state, modified dispatch, and context provider
  return [state, modifiedDispatch, SegmentViewContext.Provider] as [
    SegmentViewState,
    ActionDispatch<typeof SegmentViewAction>,
    typeof SegmentViewContext.Provider,
  ];
};

/**
 * Convert to specific format with GMT date required on backend
 *
 * @param date Takes the date string
 * @returns Returns the converted date string
 */
const formatToGMT = (date: string) => {
  if (!date) return date;
  const d = new Date(date);
  const yyyy = d.getUTCFullYear();
  const mm = String(d.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
  const dd = String(d.getUTCDate()).padStart(2, "0");
  const hh = String(d.getUTCHours()).padStart(2, "0");
  const min = String(d.getUTCMinutes()).padStart(2, "0");
  const ss = String(d.getUTCSeconds()).padStart(2, "0");
  return `${yyyy}-${mm}-${dd} ${hh}:${min}:${ss}`;
};

/**
 * Custom hook to consume the segment view state context.
 */
export const useSegmentView = () => {
  const { activeSegmentDetails, dispatch: customerDispatch } =
    useCustomerSegments();
  const { refetchColumns } = useFetchColumns(
    activeSegmentDetails.activeSegmentType ?? "",
    activeSegmentDetails.activeSegmentId ?? undefined,
  );
  // Destructure context values
  const [segmentView, dispatch] = useContext(SegmentViewContext);

  // Need to create a reference to store current state to prevent stale state in fetchMoreSegmentView.
  const currentState = useRef({ segmentView });

  useMemo(() => {
    currentState.current.segmentView = segmentView;
  }, [segmentView]);

  /**
   * Refetch Columns after editing the segment
   */
  const refetchCurrentColumns = useCallback(async () => {
    const res = await refetchColumns();

    dispatch("setSegmentView", {
      allColumnKeys: res.columns?.allColumnKeys ?? [],
      allColumns: res.columns?.allColumns ?? {},
    });
  }, [dispatch, refetchColumns]);

  const fetchMoreSegmentView = useCallback(() => {
    // Get current segment view.
    const segmentView = currentState.current.segmentView;

    if (
      // Check if activeSegmentType exists.
      activeSegmentDetails.activeSegmentType &&
      // Check if activeSegmentId exists.
      activeSegmentDetails.activeSegmentId &&
      // Check if column keys exist.
      segmentView.allColumnKeys &&
      // Check if more columns fetching is fulfilled.
      segmentView.moreColsFetching === "fulfilled" &&
      // Check if segment values fetching is not pending.
      (segmentView.segmentValuesFetching !== "pending" ||
        // Check if segment value IDs length is 0.
        segmentView.segmentValueIds.length === 0)
    ) {
      // Create a new applied filters object.
      const appliedFilters: typeof segmentView.appliedFilters = {};

      // Iterate over applied filters.
      for (const filterKey in segmentView.appliedFilters) {
        // Get the filter.
        const filter = segmentView.appliedFilters[filterKey];

        if (filter.selectedValue) {
          // Get date value
          let valueDate = filter.valueDate;

          // Convert to GMT is available
          if (typeof valueDate === "object") {
            valueDate = {
              end: valueDate.end ? formatToGMT(valueDate.end) : valueDate.end,
              start: valueDate.start
                ? formatToGMT(valueDate.start)
                : valueDate.start,
            };
          } else if (typeof valueDate === "string") {
            valueDate = formatToGMT(valueDate);
          }

          // Check if selected value exists.
          appliedFilters[filterKey] = {
            filterKey,
            valueDate,
          };
          if (
            // Check if selected value is a string.
            typeof filter.selectedValue === "string" ||
            // Check if selected value is a number.
            typeof filter.selectedValue === "number"
          ) {
            // Parse selected value to a number.
            const numericValue = parseFloat(filter.selectedValue + "");
            // Check if parsed value is NaN.
            appliedFilters[filterKey].value = isNaN(numericValue)
              ? filter.selectedValue
              : numericValue;
          } else if (typeof filter.selectedValue === "object") {
            // Check if selected value is an object.
            if (Array.isArray(filter.selectedValue)) {
              // Check if selected value is an array.
              // Map selected value array to keys.
              appliedFilters[filterKey].value = (
                filter.selectedValue as Array<SegmentFilterOption>
              ).map((v) => v.key);
            } else {
              // Set selected value key.
              appliedFilters[filterKey].value = (
                filter.selectedValue as SegmentFilterOption
              ).key;
            }
          }
        }
      }

      const isSameSegment =
        activeSegmentDetails.activeSegmentType ===
          segmentView.segmentValuesParams?.segmentType &&
        activeSegmentDetails.activeSegmentId ===
          segmentView.segmentValuesParams?.segmentId &&
        segmentView.searchText === segmentView.segmentValuesParams.searchText;

      const start = isSameSegment ? segmentView.segmentValueIds.length : 0;

      if (isSameSegment) {
        dispatch("setSegmentView", { localSegmentFetching: "pending" });
      } else {
        // Set segment values fetching to pending.
        dispatch("setSegmentView", { segmentValuesFetching: "pending" });
      }

      const params: CustomerSegmentParams = {
        segmentType: activeSegmentDetails.activeSegmentType,
        segmentId: activeSegmentDetails.activeSegmentId,
        start,
        limit: SEGMENT_VALUES_LIMIT,
        // columns: segmentView.allColumnKeys.filter(
        //   (key) =>
        //     segmentView.allColumns[key]?.required ||
        //     segmentView.allColumns[key]?.selected,
        // ),
        columns: ["*"],
        filters: !activeSegmentDetails.isCustomSegment ? appliedFilters : {},
        searchText: segmentView.searchText,
      };

      getSegmentValuesService(params)
        .then((res) => {
          dispatch("appendSegmentValues", {
            response: res,
            params: params,
            resetData: !isSameSegment,
          });
        })
        .catch((err) => {
          console.error(err);
          dispatch("setSegmentView", { segmentValuesFetching: "rejected" });
        });
    }
  }, [dispatch, activeSegmentDetails]);

  // Return context values
  return {
    segmentView,
    dispatch,
    customerDispatch,
    activeSegmentDetails,
    fetchMoreSegmentView,
    refetchCurrentColumns,
  };
};

/**
 * Actions to modify the segment view state state.
 */
const SegmentViewAction = {
  /**
   * Resets the segment view state state.
   * @function
   * @param {SegmentViewState} state - The current state.
   */
  resetState: (state: SegmentViewState) => {
    state.moreColsFetching = "pending";
    state.segmentValuesFetching = "pending";
    state.allColumns = {};
    state.allColumnKeys = [];
    state.segmentValues = {};
    state.segmentValueIds = [];
    state.hasMoreSegmentView = false;
    state.totalSegmentValues = 0;
    state.searchText = "";
    state.selectedFilters = {};
    state.appliedFilters = {};
  },

  /**
   * Sets the segment view state state.
   * @function
   * @param {SegmentViewState} state - The current state.
   * @param {Partial<SegmentViewState>} payload - The state to set.
   */
  setSegmentView: (
    state: SegmentViewState,
    payload: Partial<SegmentViewState>,
  ) => {
    if (payload.moreColsFetching !== undefined) {
      // Check if moreColsFetching is defined.
      state.moreColsFetching = payload.moreColsFetching;
    }
    if (payload.segmentValuesFetching !== undefined) {
      // Check if segmentValuesFetching is defined.
      state.segmentValuesFetching = payload.segmentValuesFetching;
    }
    if (payload.allColumns !== undefined) {
      // Check if allColumns is defined.
      state.allColumns = payload.allColumns;
    }
    if (payload.allColumnKeys !== undefined) {
      // Check if allColumnKeys is defined.
      state.allColumnKeys = payload.allColumnKeys;
    }
    if (payload.segmentValues !== undefined) {
      // Check if segmentValues is defined.
      state.segmentValues = payload.segmentValues;
    }
    if (payload.segmentValueIds !== undefined) {
      // Check if segmentValueIds is defined.
      state.segmentValueIds = payload.segmentValueIds;
    }
    if (payload.hasMoreSegmentView !== undefined) {
      // Check if hasMoreSegmentView is defined.
      state.hasMoreSegmentView = payload.hasMoreSegmentView;
    }
    if (payload.totalSegmentValues !== undefined) {
      // Check if totalSegmentValues is defined.
      state.totalSegmentValues = payload.totalSegmentValues;
    }
    if (payload.searchText !== undefined) {
      // Check if searchText is defined.
      state.searchText = payload.searchText;
    }
    if (payload.selectedFilters !== undefined) {
      // Check if selectedFilters is defined.
      state.selectedFilters = payload.selectedFilters;
    }
    if (payload.appliedFilters !== undefined) {
      // Check if appliedFilters is defined.
      state.appliedFilters = payload.appliedFilters;
    }
    if (payload.localSegmentFetching !== undefined) {
      state.localSegmentFetching = payload.localSegmentFetching;
    }
  },
  appendFilters: (
    state: SegmentViewState,
    payload: Record<string, AppliedFilters>,
  ) => {
    // Append new filters to selectedFilters.
    state.selectedFilters = { ...state.selectedFilters, ...payload };
  },
  appendFilter: (state: SegmentViewState, payload: AppliedFilters) => {
    state.selectedFilters[payload.filterKey] = {
      // Merge with existing filter if exists.
      ...(state.selectedFilters[payload.filterKey] ?? {}),
      // Append new filter.
      ...payload,
    };
  },
  applyFilters: (state: SegmentViewState) => {
    // Loop over the selected filters
    Object.entries(state.selectedFilters).forEach(([key, value]) => {
      // Delete the empty string filters
      if (
        typeof value.selectedValue === "string" &&
        value.selectedValue === ""
      ) {
        delete state.selectedFilters[key];
      }
    });

    // Apply selected filters to applied filters.
    state.appliedFilters = JSON.parse(JSON.stringify(state.selectedFilters));
  },
  discardFiltersChanges: (state: SegmentViewState) => {
    // Discard changes and revert to applied filters.
    state.selectedFilters = JSON.parse(JSON.stringify(state.appliedFilters));
  },
  clearAllFilters: (state: SegmentViewState) => {
    // Clear all applied filters.
    state.appliedFilters = {};
    // Clear all selected filters.
    state.selectedFilters = {};
  },
  setColumnSelect: (
    state: SegmentViewState,
    { key, value }: { key: string; value: boolean },
  ) => {
    if (state.allColumns[key]) {
      // Check if column key exists.
      // Set column selection value.
      state.allColumns[key].selected = value;
    }
  },
  /**
   * Appends segment values to the state.
   * @function
   * @param state - The current state.
   * @param payload - The segment values to append.
   */
  appendSegmentValues: (
    state: SegmentViewState,
    payload: {
      response: AllSegmentValues;
      params: CustomerSegmentParams;
      resetData: boolean;
    },
  ) => {
    const { response, params, resetData } = payload;

    if (!resetData) {
      // Check and filter if id is already in the list.
      response.segmentValueIds = response.segmentValueIds.filter(
        (v) => !state.segmentValues[v],
      );
    }

    // Append new custom segment keys to the existing ones
    state.segmentValueIds = resetData
      ? response.segmentValueIds
      : [
          ...state.segmentValueIds,
          // Append new segment value IDs.
          ...response.segmentValueIds,
        ];
    // Append new custom segments to the existing ones
    state.segmentValues = resetData
      ? response.segmentValues
      : {
          ...state.segmentValues,
          // Append new segment values.
          ...response.segmentValues,
        };
    state.hasMoreSegmentView = response.hasMoreSegmentView;
    state.totalSegmentValues = response.totalSegmentValues;
    state.localSegmentFetching = "fulfilled";
    state.segmentValuesFetching = "fulfilled";
    state.segmentValuesParams = params;
  },
};
