import { useCallback, useEffect, useMemo, useState } from "react";
import styles from "./AddBrand.module.scss";
import { Form, Spinner } from "react-bootstrap";
import { validateEmail } from "src/utils/utils";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { useAppDispatch } from "src/store/store";
import { addBrand } from "src/store/slices/settings/brands/brandSettings.slice";
import { FreePlanError } from "src/globals/constants";

/**
 * Component for adding brand
 */
const AddBrand = ({
  targetRef,
  onHide,
  selectedBrand,
}: {
  targetRef: React.RefObject<HTMLDivElement>;
  onHide: () => void;
  selectedBrand: (
    brandId: number | string,
    brandName: string,
    brandEmail: string,
  ) => void;
}) => {
  const [brand, setBrand] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [brandNameError, setBrandNameError] = useState("");
  const [brandEmailError, setBrandEmailError] = useState("");
  const [showValidation, setShowValidation] = useState(false);

  const isInvalidBrand = useMemo(() => brand.trim() === "", [brand]);

  const isInvalidEmail = useMemo(() => !validateEmail(email.trim()), [email]);
  const dispatch = useAppDispatch();

  const pushErrorNotification = (statusCode: string) => {
    if (statusCode === "profileImageFailed") {
      pushTheToast({
        type: "danger",
        text: "Failed to upload brand profile image!",
        position: "top-right",
      });
    } else if (statusCode === "brandEmailAlreadyExists") {
      setBrandEmailError("* Brand email is already exists");
      pushTheToast({
        type: "danger",
        text: "Brand with this email address already exists!",
        position: "top-right",
      });
    } else if (statusCode === "brandNameAlreadyExists") {
      setBrandNameError("* Brand name is already exists");
      pushTheToast({
        type: "danger",
        text: "Brand name is already exists!",
        position: "top-right",
      });
    } else if (
      statusCode === FreePlanError.LIMIT_REACHED ||
      statusCode === FreePlanError.RESTRICTED
    ) {
      return;
    } else {
      pushTheToast({
        type: "danger",
        text: "Something went wrong!",
        position: "top-right",
      });
    }
  };

  const handleSubmit = useCallback(async () => {
    if (isInvalidBrand || isInvalidEmail) {
      setShowValidation(true);
      return;
    }
    let brandName = brand;
    let brandEmail = email;
    setLoading(true);
    dispatch(
      addBrand({
        brandName: brand,
        brandEmail: email,
        callback: (statusCode, brandId) => {
          if (statusCode.trim().length !== 0) {
            pushErrorNotification(statusCode);
          } else {
            if (brandId) {
              selectedBrand(brandId, brandName, brandEmail);
            }
            pushTheToast({
              type: "success",
              text: "Brand successfully added!",
              position: "top-right",
            });
            onHide();
          }
        },
        addBrandIdInCallback: true,
      }),
    ).finally(() => {
      setLoading(false);
    });
  }, [brand, email, isInvalidBrand, isInvalidEmail]);

  const onClickWindow = useCallback(
    (e: any) => {
      const selectElement = targetRef.current;
      const isClickWithinSelect =
        selectElement && selectElement.contains(e.target);
      //This will check if the click event is from react select - selecting +Add brand option
      const isClickWithinOptions = e.target.classList.contains(
        "custom-select__option",
      );
      if (
        targetRef.current &&
        !targetRef.current.contains(e.target) &&
        !isClickWithinSelect &&
        !isClickWithinOptions
      ) {
        onHide();
      }
    },
    [onHide],
  );

  useEffect(() => {
    window.addEventListener("click", onClickWindow);
    return () => {
      window.removeEventListener("click", onClickWindow);
    };
  }, [onClickWindow]);

  return (
    <div
      className={`${styles.container}`}
      ref={targetRef}
    >
      <div className={styles.heading}>Add brand</div>
      <p className={`mb-2 ${styles.subText}`}>
        Create multiple brands represent multiple products or services, or they
        might literally be multiple brands owned and represented by your company
      </p>

      <Form>
        <Form.Group>
          <Form.Label className={styles.heading}>Brand name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Eg. Acme"
            name="brand"
            value={brand}
            data-lpignore="true"
            onChange={(e) => setBrand(e.target.value)}
            className={`${styles.inputText} mt-1 px-3 py-2 ${
              isInvalidBrand && showValidation && "border-danger"
            }`}
          />
          {isInvalidBrand && showValidation && (
            <span className={`${styles.errorText}`}>
              Please enter a brand Name
            </span>
          )}
        </Form.Group>

        <Form.Group className="mt-2">
          <Form.Label className={styles.heading}>Outbound E-mail</Form.Label>
          <Form.Control
            type="text"
            placeholder="Support@brandname.com"
            name="email"
            data-lpignore="true"
            onChange={(e) => setEmail(e.target.value)}
            className={`${styles.inputText} mt-1 px-3 py-2 ${
              isInvalidEmail && showValidation && "border-danger"
            }`}
          />
          {isInvalidEmail && showValidation && (
            <span className={`${styles.errorText}`}>
              Please enter an valid email id.
            </span>
          )}
        </Form.Group>

        <button
          type="button"
          className={`${styles.addBrandButton} mt-2 mb-4`}
          onClick={handleSubmit}
          disabled={loading}
        >
          Add brand
          {loading && (
            <Spinner
              className={`ms-2`}
              animation="border"
              size="sm"
            />
          )}
        </button>
      </Form>
    </div>
  );
};

export default AddBrand;
