import { useState } from "react";
import { Carousel } from "react-bootstrap";
import styles from "./CarouselPoster.module.scss";
interface Prop {
  scope?: "login" | "signup";
}

/**
 * Carousel poster component for displaying slides with information.
 */
const CarouselPoster = ({ scope = "signup" }: Prop) => {
  // State to track the active index of the carousel
  const [index, setIndex] = useState(0);

  // Function to handle slide selection
  const handleSelect = (selectedIndex: number) => {
    setIndex(selectedIndex);
  };

  return (
    <div className="col-12 col-md-6 col-lg-5 offset-lg-1">
      <div className={`text-center pt-5 pb-2 ${styles.imgBox} mx-auto pb-4`}>
        {/* Carousel component */}
        {/* Interval used is in ms - Current change at 5 seconds */}
        <Carousel
          activeIndex={index}
          onSelect={handleSelect}
          interval={5000}
          controls={false}
          className={`carouselSlider`}
        >
          {/* Slide 1 */}
          <Carousel.Item>
            {/* Carousel Heading */}
            <h2 className={`mb-3 ${styles.signupHeading}`}>
              {scope === "login" && `Hey there, welcome back!`}
              {scope === "signup" && (
                <>
                  {`You’re in good hands with`}
                  <br />
                  <span>{` Saufter.io! `}</span>
                </>
              )}
            </h2>
            {/* Carousel Image */}
            <img
              src="https://share1.cloudhq-mkt3.net/4f5ba11a418d6e.png"
              alt="Slide 1"
              className={`d-block w-100 ${styles.sliderImg1}`}
            />
            {/* Carousel Sub Heading */}
            <h6
              className={`mt-4 pt-4 mb-4 ${styles.saufterSubHead} ${styles.minHeight}`}
            >
              {`Saufter.io is trusted by customer success teams all over the world to automate processes and get important customer insights.`}
            </h6>
          </Carousel.Item>

          {/* Slide 2 */}
          <Carousel.Item>
            {/* Carousel Heading */}
            <h2 className={`mb-3  ${styles.signupHeading}`}>
              {`👋 Want to hear something cool?`}
            </h2>
            {/* Carousel Image */}
            <img
              src="https://share1.cloudhq-mkt3.net/c2e698c21a4adf.png"
              alt="Slide 2"
              className={`d-block w-100 ${styles.sliderImg2}`}
            />
            {/* Carousel Sub Heading */}
            <h6
              className={`mt-4 pt-4 mb-4 ${styles.saufterSubHead} ${styles.minHeight}`}
            >
              {`Elevate Efficiency, Amplify Experience with Seamless Saufter.io's Helpdesk Automations!`}
            </h6>
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  );
};

export default CarouselPoster;
