import {axiosJSON} from "src/globals/axiosEndPoints";

interface UpdateCannedResponse {
  cannedResponseId: string | number;
  integrationId: string | number;
  shortcut: string;
  messageText: string;
  title: string;
}

export interface UpdateCannedResponseParams {
  cannedResponseId: string | number;
  integrationId: string | number;
  shortcut?: string;
  messageText?: string;
  title?: string;
}

export async function updateCannedResponse(params: UpdateCannedResponseParams) {
  const {data: res} = await axiosJSON.post(
    "/api/chatSetting/cannedResponse/update",
    params
  );

  if (res.error === true) {
    throw new Error(res.message as string);
  }

  return res.data as UpdateCannedResponse;
}
