import { useInfiniteQuery } from "@tanstack/react-query";
import { useCallback, useMemo, useState } from "react";
import { AllCategoryNormalizedData, getBugCategory } from "src/services/BugReport/getBugCategory.service";

const initialPayload = {
  start: 0,
  limit: 10,
};

const useBugCategoryList: any = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const { status, data, hasNextPage, fetchNextPage, isLoading, fetchStatus, isError } =
    useInfiniteQuery({
      queryKey: ["bugCategory/getBugCategory", initialPayload],
      queryFn: ({ pageParam = initialPayload }) => getBugCategory(pageParam),
      getNextPageParam: (
        prevPage: AllCategoryNormalizedData,
        _allPages: AllCategoryNormalizedData[],
      ) => {
        const data = _allPages.flatMap((data: any) => data.data);
        
        // Return next page params if we have more pages
        if (prevPage.metaData && !prevPage.metaData.limitReached) {
          return {
            limit: initialPayload.limit,
            start: currentPage * prevPage.allBugCategoryIds.length,
          };
        }
        return null;
      },
    });

    //get bug categories data
    const bugCategories = useMemo(() => {
      let categoriesData: AllCategoryNormalizedData["allBugCategory"] = {};
      data?.pages.forEach((page) => {
        categoriesData = { ...categoriesData, ...page.allBugCategory };
      });
      return categoriesData;
    }, [data]);

    //get bug categories ids
    const bugCategoriesIds = useMemo(() => {
      const ids = data?.pages.flatMap((page) => page.allBugCategoryIds) ?? [];
      return Array.from(new Set([...ids]));
    }, [data]);

    //set current page number and fetch next page
    const nextPageLoad = useCallback(() => {
      setCurrentPage(currentPage + 1)
      fetchNextPage();
    }, [currentPage])
    

    return {
      bugCategories,
      bugCategoriesIds,
      nextPageLoad,
      hasNextPage,
      isLoading,
      status,
      isError,
      fetchStatus,
    };
}

export default useBugCategoryList;