import { useCallback, useMemo } from "react";
import { useUploadNewCsvModalContext } from "../../Hooks/useUploadNewCsvModalContext";
import styles from "./AttrTableHeader.module.scss";
import CustomSelect from "./CustomSelect/CustomSelect";
import { v4 as uuid } from "uuid";
import alert from "src/assets/images/alert.svg";

const AttrTableHeader = () => {
  const { state, updateState } = useUploadNewCsvModalContext();

  const tableHeadings = useMemo(() => {
    return state.mapAttributes?.tableHeadings ?? {};
  }, [state.mapAttributes?.tableHeadings]);

  const tableHeadingIds = useMemo(() => {
    return state.mapAttributes?.tableHeadingIds ?? [];
  }, [state.mapAttributes?.tableHeadingIds]);

  const updatedTableHeadingIds = useMemo(() => {
    return state.mapAttributes?.updatedTableHeadingIds ?? [];
  }, [state.mapAttributes?.updatedTableHeadingIds]);

  const options = useMemo(() => {
    return tableHeadingIds.map((id) => ({
      key: id,
      value: tableHeadings[id]?.tableHeadingName || "",
    }));
  }, [tableHeadingIds, tableHeadings]);

  // Helper to check for duplicates in selected table heading IDs
  const findDuplicates = (ids: string[]) => {
    const counts = ids.reduce(
      (acc, id) => {
        acc[id] = (acc[id] || 0) + 1;
        return acc;
      },
      {} as Record<string, number>,
    );

    return Object.keys(counts).filter((id) => counts[id] > 1);
  };

  // Compute duplicates with useMemo
  const duplicateTableHeadingIds = useMemo(() => {
    return findDuplicates(updatedTableHeadingIds);
  }, [updatedTableHeadingIds]);

  const handleSelect = useCallback(
    (originalTableHeadingIndex: number, newTableHeadingId: string) => {
      // Prepare an array of selected table heading IDs
      const newIds = [...updatedTableHeadingIds];
      // Replace the item at the specified index
      if (originalTableHeadingIndex !== -1) {
        newIds.splice(originalTableHeadingIndex, 1, newTableHeadingId); // Remove one item at index, insert newTableHeadingId
        if (state.mapAttributes) {
          updateState({
            mapAttributes: {
              ...state?.mapAttributes,
              updatedTableHeadingIds: newIds,
            },
          });
        }
      }
    },
    [state?.mapAttributes, updateState, updatedTableHeadingIds],
  );

  // Find if there's an error for a specific tableHeadingId
  const getErrorForTableHeadingId = useCallback(
    (tableHeadingId: string) => {
      return duplicateTableHeadingIds.includes(tableHeadingId);
    },
    [duplicateTableHeadingIds],
  );

  return (
    <div
      className={`${styles.tableHeader} d-flex flex-column flex-md-row align-items-start mt-2`}
    >
      <div className={`${styles.colHeadLabel} d-flex align-items-center`}>
        <span className={`${styles.element}`}>Column heading</span>
      </div>

      {updatedTableHeadingIds.map((tableHeadingId, index) => {
        // Check if there's an error for this tableHeadingId
        const isDuplicate = getErrorForTableHeadingId(tableHeadingId);

        return (
          <div
            className="mt-2 mb-2"
            key={uuid()}
          >
            <div
              className={`${styles.colHead} ${
                isDuplicate ||
                state.mapAttributes?.mapAttributeErrors[tableHeadingId]
                  ? styles.borderDanger
                  : ""
              }`}
            >
              <CustomSelect
                items={options}
                selectedItem={tableHeadings[tableHeadingId]?.tableHeadingName}
                className={`${styles.customSelect}`}
                onSelect={(key) => {
                  handleSelect(index, key);
                }}
              />
            </div>
            {isDuplicate ||
            state.mapAttributes?.mapAttributeErrors[tableHeadingId] ? (
              <div
                className="d-flex mt-1"
                style={{ padding: "0 12px" }}
              >
                <div className="d-flex align-items-start me-1">
                  <img
                    src={alert}
                    alt="alert"
                    className={styles.alert}
                  />
                </div>
                <div className={styles.errorTxt}>
                  {isDuplicate
                    ? `This field has already been selected previously. Avoid
                  duplicates`
                    : state.mapAttributes?.mapAttributeErrors[tableHeadingId]
                          ?.errorDescription
                      ? state.mapAttributes?.mapAttributeErrors[tableHeadingId]
                          ?.errorDescription
                      : ""}
                </div>
              </div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
};

export default AttrTableHeader;
