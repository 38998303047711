/**
 * This file defines a React component responsible for rendering knowledgebase articles table
 *
 * @author @yuvaraj-busibud
 * @author @navjyot-busibud
 * @author @Jayalakshmi-busibud
 */
import React from "react";
import styles from "./ArticleBrand.module.scss";
import ArticleBrandData from "./ArticleBrandData/ArticleBrandData";
import Pagination from "src/routes/TicketList/children/Pagination/Pagination";
import { Article } from "src/services/Bot/AnswerSources/getAllKbArticles.service";
import Loader from "src/components/Loader";
import useArticleBrand from "./useArticleBrand";
import { SelectArticlesResponse } from "src/services/Bot/AnswerSources/selectKbArticles.service";
import emptyBackPng from "src/assets/images/emptyBack.png";

interface Props {
  articles: Article[];
  isLoading?: boolean;
  isRefetching?: boolean;
  fetchNextPage: () => void;
  hasNextPage: boolean | undefined;
  onSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  totalPages: number;
  currentPage: number;
  changePage: (page: number) => void;
  integrationId: number | string | undefined;
  updateArticlebotTrainingStatus: (data: SelectArticlesResponse) => void;
  isbotLoading: boolean
  disabledFeatures: (
    "send_email" |
    "reports" |
    "bot_profile" |
    "knowledge_base" |
    "email_integration" |
    "add_email_integration" |
    "facebook_integration" |
    "whatsapp_integration" |
    "add_users" |
    "send_chat" |
    "add_brand" |
    "add_ticket" |
    "external_source" |
    "add_teams"
  )[] | null | undefined;
  handleButtonClick: () => void
}

const ArticleBrand = ({
  articles,
  isLoading,
  isRefetching,
  fetchNextPage,
  hasNextPage,
  currentPage,
  totalPages,
  changePage,
  integrationId,
  updateArticlebotTrainingStatus,
  isbotLoading,
  disabledFeatures,
  handleButtonClick
}: Props) => {
  const {
    selectedArticles,
    setSelectedArticles,
    handleSelectAll,
    handleChange,
  } = useArticleBrand({
    articles,
    updateArticlebotTrainingStatus

  });

  return (
    <div>
      <div className={`p-3 ${styles.compWrapper}`}>
        {articles?.length !== 0 && (<><div
          className={`mt-3 d-flex align-items-center justify-content-between px-3 ${styles.tableHead}`}
        >
          <div className={`${styles.article}`}>
            <div className="form-check">
              <input
                className={`form-check-input ${styles.checkbox}`}
                type="checkbox"
                checked={
                  articles !== undefined &&
                  articles.length > 0 &&
                  selectedArticles.length === articles?.length
                }
                onClick={handleSelectAll}
              />
              <label className={`form-check-label ${styles.tablePara}`}>
                Article
              </label>
            </div>
          </div>
          <div className={`${styles.articleUrl}`}>
            <span className={`${styles.tablePara}`}>Published on</span>
          </div>

          <div className={`${styles.mainSource}`}>
            <span className={`${styles.tablePara}`}>Brand</span>
          </div>
          <div className={`${styles.appearance}`}>
            <span className={`${styles.tablePara}`}>No. of times appeared</span>
          </div>
          <div className={`${styles.botTrainingStatus}`}>
            <span className={`${styles.tablePara}`}>Bot training status</span>
          </div>
        </div></>)}

        <div>
          <div>
            {isLoading || isRefetching ? (
              <div>
                <Loader />
              </div>
            ) : articles?.length > 0 ? (
              <div>
                {articles.map((article: any) => {
                  if (!article) {
                    return null;
                  }
                  return (
                    <ArticleBrandData
                      article={article}
                      selectedArticles={selectedArticles}
                      setSelectedArticles={setSelectedArticles}
                      integrationId={integrationId}
                      handleChange={handleChange}
                      articles={articles}
                      updateArticlebotTrainingStatus={updateArticlebotTrainingStatus}
                    />
                  );
                })}
              </div>
            ) : (
              <div
                className={`${styles.noResultFound} d-flex justify-content-center align-items-center h-100`}
              >
                <div className={styles.noResultFoundImage}>
                  <img src={emptyBackPng} alt="no kb article added yet" />
                </div>
                <div className={styles.noResultFoundText}>No knowledge base articles added yet</div>
                <div>
                  <button
                    className={`${styles.importBtn} ${isbotLoading ||
                      (disabledFeatures && disabledFeatures.includes("bot_profile"))
                      ? styles.buttonDisabled
                      : ""
                      }`}
                    onClick={handleButtonClick}
                  >
                    <span className="pe-1">
                      <i className="fa-solid fa-plus"></i>
                    </span>{" "}
                    Add new article
                  </button>
                </div>


              </div>
            )}
          </div>
          {articles !== undefined && articles.length > 1 && (
            <div className="pt-2">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                setCurrentPage={(page) => {
                  changePage(page);
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ArticleBrand;
