/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styles from "./RatingSetting.module.scss";
import useRating from "../../useRating";
import { useParams } from "react-router-dom";
import Loader from "src/components/Loader";
import useRatingSetting from "./useRatingSetting";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import arrowRight from "src/assets/images/arrowRight.png";

type Props = {
  allowUsers: boolean;
  setAllowUsers: (value: boolean) => void;
  showCount: boolean;
  setShowCount: (value: boolean) => void;
  selectedRadio: string | undefined;
  setSelectedRadio: (value: string | undefined) => void;
  setEmojiClick: (value: boolean) => void;
  reqApproval: boolean; //required approval flag for rating
  setReqApproval: (value: boolean) => void; //function to update required approval rating flag
  selectedRatingApproval: string; //selected option for approval default type
  setSelectedRatingApproval: (value: string) => void; //function to update default approval type
};

const RatingSetting = ({
  allowUsers,
  setAllowUsers,
  setShowCount,
  showCount,
  selectedRadio,
  setSelectedRadio,
  setEmojiClick,
  reqApproval, //required approval flag for rating
  setReqApproval, //function to update required approval rating flag
  selectedRatingApproval, //selected option for approval default type
  setSelectedRatingApproval, //function to update default approval type
}: Props) => {
  const {
    updateRatingAndComments,
    dropdown,
    setDropDown,
    handleChangeAllowUsers,
    handleCountChange,
    handleRadioChange1,
    handleRadioChange2,
    handleApprovalRatingChange,
    handleRequireApprovalChange,
  } = useRatingSetting({
    allowUsers,
    setAllowUsers,
    setShowCount,
    showCount,
    selectedRadio,
    setSelectedRadio,
    setEmojiClick,
    reqApproval, //required approval flag for rating
    setReqApproval, //function to update required approval rating flag
    selectedRatingApproval, //selected option for approval default type
    setSelectedRatingApproval, //function to update default approval type
  });

  const { getRatingsAndCommentData } = useRating();

  useEffect(() => {
    getRatingsAndCommentData.refetch();
  }, []);

  // Handling the loading state
  if (
    getRatingsAndCommentData.isLoading ||
    getRatingsAndCommentData.isRefetching
  ) {
    return (
      <div className={`p-2 ${styles.settingWrapper}`}>
        <Loader />
      </div>
    );
  }

  return (
    <div className={`p-2 ${styles.settingWrapper}`}>
      <p className={`${styles.heading} saufter_h4 `}>Ratings Settings</p>
      {/* Drpodown Header */}
      <div
        className={`d-flex justify-content-between align-items-center px-2 saufter_medium_h4 ${
          styles.dropBtn
        } ${dropdown && styles.borderSet}`}
        onClick={() => setDropDown(!dropdown)}
      >
        <span className={`saufter_medium_h4 ${styles.subHeading}`}>
          Ratings
        </span>
        <span className={`${styles.arrow}`}>
          {dropdown ? (
            <img src={arrowRight} alt="" className={`${styles.arrowDown}`} />
          ) : (
            <img src={arrowRight} alt="" />
          )}
        </span>
      </div>

      {/* Dropdown Section */}
      {dropdown ? (
        <>
          <div className={`p-2 p-md-3 mb-2 ${styles.dropDown}`}>
            {/* Allow user to rate the article input */}
            <div className="form-check mb-2">
              <input
                className={`form-check-input ${styles.check}`}
                type="checkbox"
                checked={allowUsers}
                id="flexCheckDefault"
                onChange={handleChangeAllowUsers}
              />
              <label
                className={`form-check-label ${styles.formLabel} saufter_h5`}
                htmlFor="flexCheckDefault"
              >
                Allow users to rate your articles
              </label>
            </div>

            {/* Changed these things to make the radio buttons as per xd */}
            {/* Radio Buttons */}
            <div className="ps-4 mb-2">
              {/* Emojis */}
              <div
                className={`form-check ${!allowUsers ? styles.disabled : ""}`}
                onClick={handleRadioChange1}
              >
                <span className={`${styles.radioTick}`}>
                  <i
                    className={`form-check-input ${styles.formIcon} ${
                      selectedRadio === "flexRadioDefault1" ? "fas" : "far"
                    } ${
                      selectedRadio === "flexRadioDefault1"
                        ? "fa-check-circle"
                        : "fa-circle"
                    } ${!allowUsers ? styles.disabled : ""}`}
                  ></i>
                </span>
                <label
                  className={`form-check-label ${styles.formLabel} saufter_h5 ${
                    !allowUsers ? styles.disabled : ""
                  }`}
                >
                  {"Emojis ( scale out of 4 )"}
                </label>
              </div>

              {/* Thumbs up and down */}
              <div
                className={`form-check ${!allowUsers ? styles.disabled : ""}`}
                onClick={handleRadioChange2}
              >
                <span className={`${styles.radioTick}`}>
                  <i
                    className={`form-check-input ${styles.formIcon} ${
                      selectedRadio === "flexRadioDefault2" ? "fas" : "far"
                    } ${
                      selectedRadio === "flexRadioDefault2"
                        ? "fa-check-circle"
                        : "fa-circle"
                    } ${!allowUsers ? styles.disabled : ""}`}
                  ></i>
                </span>
                <label
                  className={`form-check-label ${styles.formLabel} saufter_h5 ${
                    !allowUsers ? styles.disabled : ""
                  }`}
                >
                  {"Thumbs up & down"}
                </label>
              </div>
            </div>
            {/* Show help count input */}
            <div className="form-check">
              <input
                className={`form-check-input ${styles.check}`}
                type="checkbox"
                checked={showCount}
                id="helpCount"
                onChange={handleCountChange}
              />
              <label
                className={`form-check-label ${styles.formLabel} saufter_h5`}
                htmlFor="helpCount"
              >
                Show help count
              </label>
            </div>
          </div>
          <div className="d-none">
            {/* Allow user to rate the article input */}
            <div className="form-check mb-2">
              <input
                className="form-check-input"
                type="checkbox"
                checked={reqApproval}
                id="ratingApprovalRequired"
                onChange={handleRequireApprovalChange}
              />
              <label
                className={`form-check-label ${styles.formLabel}`}
                htmlFor="ratingApprovalRequired"
              >
                Require rating approval before showing?
              </label>
            </div>

            {/* Radio Buttons */}
            <div className="ps-4 mb-2">
              {/* Unapproved rating */}
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="unapprovedRating"
                  id="unapprovedRating"
                  checked={selectedRatingApproval === "unApproved"}
                  onChange={() => {
                    handleApprovalRatingChange("unApproved");
                  }}
                  disabled={!allowUsers}
                />
                <label
                  className={`form-check-label ${styles.formLabel}`}
                  htmlFor="unapprovedRating"
                >
                  Unapproved rating
                </label>
              </div>
              {/* Approved rating */}
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="approvedRating"
                  id="approvedRating"
                  checked={selectedRatingApproval === "approved"}
                  onChange={() => {
                    handleApprovalRatingChange("approved");
                  }}
                  disabled={!allowUsers}
                />
                <label
                  className={`form-check-label ${styles.formLabel}`}
                  htmlFor="approvedRating"
                >
                  Approved rating
                </label>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default RatingSetting;
