import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Editor as TinyMceEditor } from "tinymce";

interface Props {
  signatureText?: string;
  setSigEdited?: React.Dispatch<React.SetStateAction<boolean>>;
  sigEdited?: boolean;
  autosavePrefix?: string;
}

export const convertTOSignature = (html: string) => {
  const ele = document.createElement("div");
  ele.innerHTML = html;

  if (ele.children.length === 1) {
    ele.innerHTML = ele.firstElementChild?.innerHTML ?? ele.innerHTML;
  }

  ele.classList.add("email-signature");

  return ele.outerHTML;
};

function useEmailSignatureHandler({
  signatureText,
  setSigEdited,
  sigEdited,
  autosavePrefix,
}: Props) {
  const editorRef = useRef<TinyMceEditor | null>(null);
  const currentState = useRef({ signatureText, sigEdited, autosavePrefix });

  useMemo(() => {
    currentState.current.autosavePrefix = autosavePrefix;
    if (autosavePrefix !== undefined) {
      const isEditedV = window.localStorage.getItem(
        autosavePrefix + "__IS_SIG_EDITED"
      );
      if (isEditedV && setSigEdited) {
        currentState.current.sigEdited = true;
        setSigEdited(true);
      }
    }
  }, [autosavePrefix]);
  useMemo(() => {
    currentState.current.sigEdited = sigEdited;
    currentState.current.signatureText = signatureText;
    if (sigEdited) {
      window.localStorage.setItem(
        currentState.current.autosavePrefix + "__IS_SIG_EDITED",
        sigEdited + ""
      );
    } else {
      window.localStorage.removeItem(
        currentState.current.autosavePrefix + "__IS_SIG_EDITED"
      );
    }
  }, [signatureText, sigEdited]);

  const loadOrRemoveSignature = useCallback(() => {
    if (editorRef.current && currentState.current.sigEdited === false) {
      try {
        editorRef.current.execCommand("mceAutoResize");
        let signatureContainer =
          editorRef.current.dom.select(".email-signature")[0];
        if (signatureContainer) {
          if (currentState.current.signatureText) {
            signatureContainer.outerHTML = currentState.current.signatureText;
          } else {
            signatureContainer.parentElement?.removeChild(signatureContainer);
          }
          editorRef.current.execCommand("mceAutoResize");
          if (setSigEdited) {
            setSigEdited(false);
          }
        } else {
          if (currentState.current.signatureText) {
            const sc = editorRef.current.contentDocument.createElement("div");
            sc.innerHTML = currentState.current.signatureText;
            if (sc.firstElementChild) {
              editorRef.current.dom.add(
                editorRef.current.getBody(),
                sc.firstElementChild
              );
              editorRef.current.execCommand("mceAutoResize");
              if (setSigEdited) {
                setSigEdited(false);
              }
            }
          }
        }
      } catch (error: any) {
        console.error(error);
      }
    } else if (editorRef.current) {
      editorRef.current.execCommand("mceAutoResize");
    }
  }, []);

  useEffect(() => {
    // Checking if the signature is initialized from ajax or not.
    setTimeout(() => {
      loadOrRemoveSignature();
    }, 0);
  }, [signatureText]);

  const handleEditorInit = useCallback((editor: TinyMceEditor) => {
    editorRef.current = editor;
    setTimeout(() => {
      loadOrRemoveSignature();
    }, 0);
  }, []);

  return { handleEditorInit };
}

export default useEmailSignatureHandler;
