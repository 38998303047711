import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AJAXSTATUS } from "src/globals/constants";
import { RefundScenarioState } from "src/routes/Setting/children/MainSetting/children/SetRulesNew/childerns/childern/AddAutomations/AddAutomationStep4/Children/AntiRefundModal/Children/RefundBox/Scenario/ScenarioReducer";
import { RefundRequestFollowUpQuestions } from "src/services/UiAutomation/ReturnExchange/Configuration/AntiRefund/getFollowUpQuestion";
import { v4 as uuidv4 } from "uuid";
import antiRefundBuilder from "./Builders/anitRefund.builder";

export interface GiveOfferType {
  offerType:
    | "partial_refund_of"
    | "discount_of"
    | "offer_clearance_product_for_free"
    | "other"
    | null;
  offerSelectedItems?: string[] | number[];
  offerSelectedTags?: string[];
  offerValue?: number;
  offerUnit?: "fixed" | "percentage";
  name?: string;
}
export interface items {
  id?: number;
  value: string;
  label: string;
  price?: {
    amount?: string;
    currencyCode?: string;
  };
  imgSrc?: string | null;
  type: "item";
}

export interface FollowUpQuestions {
  elementId: string;
  elementTypeId: string | number;
  elementValue: string;
  additionalOptions: { id: string; value: string }[];
  selectedValue: string[] | string;
  // selectedId: string[] | string;
}

export interface RefundScenario {
  id: string;
  items: items[];
  tags: string[];
  followUpQuestions: FollowUpQuestions[];
  enabled: boolean;
  itemsAndTagAllSelected: boolean;
  additionalInformation?: {
    enabled: boolean;
    message: string;
    extendReturnPeriod: {
      enabled: boolean;
      days: number;
    };
  };
  giveOffer?: GiveOfferType & {
    enabled: boolean;
    message: string;
  };
  offerExchange?: {
    enabled: boolean;
    message: string;
    exchangeWithSameItems: boolean;
    exchangeWithOtherItems: boolean;
  };
  isNewScenario?: boolean;
}

export interface RefundReason {
  id: number;
  reasonName: string;
  scenarios: RefundScenario[];
}

export interface AntiRefundConfigState {
  refundRequests: {
    reasons: RefundReason[];
    refundRequestFollowUpQuestions: RefundRequestFollowUpQuestions[];
  };
  fetchAjaxStatus: AJAXSTATUS;
  updateAjaxStatus: AJAXSTATUS;
  followUpQuestionsAjaxStatus: AJAXSTATUS;
  updateAntiRefundRequestAjaxStatus: AJAXSTATUS;
}

export const initialState: AntiRefundConfigState = {
  refundRequests: {
    reasons: [],
    refundRequestFollowUpQuestions: [],
  },
  fetchAjaxStatus: "pending",
  updateAjaxStatus: "idle",
  followUpQuestionsAjaxStatus: "idle",
  updateAntiRefundRequestAjaxStatus: "idle",
};

export const antiRefundConfigSlice = createSlice({
  name: "antiRefundConfig",
  initialState,
  reducers: {
    updateRefundReason: (
      state,
      action: PayloadAction<{ id: number; scenarios?: RefundScenario[] }>,
    ) => {
      const { id, scenarios } = action.payload;
      const refundReason = state.refundRequests.reasons.find(
        (reason) => reason.id === id,
      );
      if (refundReason) {
        if (scenarios) {
          refundReason.scenarios = scenarios;
        }
      }
    },
    addRefundScenario: (
      state,
      action: PayloadAction<{
        reasonId: number;
        scenario: RefundScenario;
      }>,
    ) => {
      const { reasonId, scenario } = action.payload;
      const refundReason = state.refundRequests.reasons.find(
        (reason) => reason.id === reasonId,
      );
      if (refundReason) {
        refundReason.scenarios.push(scenario);
      }
    },
    //Update the enable value for a specific reason in Q11 modal
    toggleAntiRefundMethod: (
      state,
      action: PayloadAction<{
        id: number;
        enabled: boolean;
        scenarioId: number | string;
      }>,
    ) => {
      const { id, enabled } = action.payload;
      const refundReason = state.refundRequests.reasons.find(
        (reason) => reason.id === id,
      );

      if (refundReason) {
        // Find the respective scenario
        const refundScenario = refundReason.scenarios.find(
          (scenario) => scenario.id === action.payload.scenarioId,
        );

        if (refundScenario) {
          refundScenario.enabled = enabled;
        }
      }
    },

    updateRefundScenario: (
      state,
      action: PayloadAction<{
        reasonId: number;
        scenarioId: string;
        scenario: RefundScenario;
      }>,
    ) => {
      const { reasonId, scenarioId, scenario } = action.payload;
      const refundReason = state.refundRequests.reasons.find(
        (reason) => reason.id === reasonId,
      );

      if (refundReason) {
        // Find the respective scenario
        const refundScenario = refundReason.scenarios.find(
          (scenario) => scenario.id === scenarioId,
        );

        if (refundScenario) {
          refundScenario.items = scenario.items;
          refundScenario.tags = scenario.tags;
          refundScenario.followUpQuestions = scenario.followUpQuestions;
        }
      }
    },

    updateScenarioItems: (
      state,
      action: PayloadAction<{
        reasonId: string | number;
        scenarioId: string;
        items: {
          id?: number;
          value: string;
          label: string;
          price?: {
            amount: string;
            currencyCode: string;
          };
          imgSrc?: string;
          type: "tag" | "item";
        }[];
      }>,
    ) => {
      const { reasonId, scenarioId, items } = action.payload;
      const refundReason = state.refundRequests.reasons.find(
        (reason) => reason.id === reasonId,
      );

      if (refundReason) {
        // Find the respective scenario
        const refundScenario = refundReason.scenarios.find(
          (scenario) => scenario.id === scenarioId,
        );

        // If scenario is found
        if (refundScenario) {
          // Create items array
          refundScenario.items = items.map((item) => ({
            id: item.id,
            imgSrc: item.imgSrc,
            label: item.label,
            type: "item",
            value: item.value,
            price: {
              amount: item.price?.amount,
              currencyCode: item.price?.currencyCode,
            },
          }));
        }
      }
    },

    updateScenarioTags: (
      state,
      action: PayloadAction<{
        reasonId: string | number;
        scenarioId: string;
        tags: string[];
      }>,
    ) => {
      const { reasonId, scenarioId, tags } = action.payload;
      const refundReason = state.refundRequests.reasons.find(
        (reason) => reason.id === reasonId,
      );

      if (refundReason) {
        // Find the respective scenario
        const refundScenario = refundReason.scenarios.find(
          (scenario) => scenario.id === scenarioId,
        );

        if (refundScenario) {
          refundScenario.tags = tags;
        }
      }
    },

    addDefaultScenario: (
      state,
      action: PayloadAction<{ reasonId: number }>,
    ) => {
      const { reasonId } = action.payload;
      const refundReason = state.refundRequests.reasons.find(
        (reason) => reason.id === reasonId,
      );
      if (refundReason) {
        refundReason.scenarios.push({
          id: uuidv4(),
          items: [],
          tags: [],
          followUpQuestions: [],
          enabled: false,
          itemsAndTagAllSelected: false,
          isNewScenario: true,
        });
      }
    },

    updateItemAndTagsAllSelected: (
      state,
      action: PayloadAction<{
        reasonId: string | number;
        scenarioId: string;
        itemsAndTagAllSelected: boolean;
      }>,
    ) => {
      const { reasonId, scenarioId, itemsAndTagAllSelected } = action.payload;
      const refundReason = state.refundRequests.reasons.find(
        (reason) => reason.id === reasonId,
      );

      if (refundReason) {
        // Find the respective scenario
        const refundScenario = refundReason.scenarios.find(
          (scenario) => scenario.id === scenarioId,
        );

        if (refundScenario) {
          refundScenario.itemsAndTagAllSelected = itemsAndTagAllSelected;
        }
      }
    },

    updateFollowUpQuestions: (
      state,
      action: PayloadAction<{
        reasonId: string | number;
        scenarioId: string;
        elementId: string;
        elementTypeId: string;
      }>,
    ) => {
      const { reasonId, scenarioId, elementId, elementTypeId } = action.payload;
      const refundReason = state.refundRequests.reasons.find(
        (reason) => reason.id === reasonId,
      );

      const element =
        state.refundRequests.refundRequestFollowUpQuestions.filter(
          (element) =>
            element.elementId === elementId &&
            element.elementTypeId === elementTypeId,
        )[0];

      if (refundReason) {
        // Find the respective scenario
        const refundScenario = refundReason.scenarios.find(
          (scenario) => scenario.id === scenarioId,
        );

        if (refundScenario) {
          const elementIndex = refundScenario.followUpQuestions.findIndex(
            (element) =>
              element.elementId === elementId &&
              element.elementTypeId === elementTypeId,
          );
          if (elementIndex === -1) {
            refundScenario.followUpQuestions.push({
              elementId: elementId,
              elementTypeId: elementTypeId,
              elementValue: element.elementValue,
              additionalOptions: element.additionalOptions,
              selectedValue: "",
              // selectedId:"",
            });
          }
        }
      }
    },

    removeFollowUpQuestions: (
      state,
      action: PayloadAction<{
        reasonId: string | number;
        scenarioId: string;
        elementId: string;
        elementTypeId: string;
      }>,
    ) => {
      const { reasonId, scenarioId, elementId, elementTypeId } = action.payload;
      const refundReason = state.refundRequests.reasons.find(
        (reason) => reason.id === reasonId,
      );

      if (refundReason) {
        // Find the respective scenario
        const refundScenario = refundReason.scenarios.find(
          (scenario) => scenario.id === scenarioId,
        );

        if (refundScenario) {
          const elementIndex = refundScenario.followUpQuestions.findIndex(
            (element) =>
              element.elementId === elementId &&
              element.elementTypeId === elementTypeId,
          );
          if (elementIndex !== -1) {
            refundScenario.followUpQuestions.splice(elementIndex, 1);
          }
        }
      }
    },

    selectAllFollowUpQuestions: (
      state,
      action: PayloadAction<{ reasonId: string | number; scenarioId: string }>,
    ) => {
      const { reasonId, scenarioId } = action.payload;
      const refundReason = state.refundRequests.reasons.find(
        (reason) => reason.id === reasonId,
      );
      if (refundReason) {
        // Find the respective scenario
        const refundScenario = refundReason.scenarios.find(
          (scenario) => scenario.id === scenarioId,
        );
        if (refundScenario) {
          refundScenario.followUpQuestions =
            state.refundRequests.refundRequestFollowUpQuestions.map(
              (element) => {
                return {
                  elementId: element.elementId,
                  elementTypeId: element.elementTypeId,
                  elementValue: element.elementValue,
                  additionalOptions: element.additionalOptions,
                  selectedValue: "",
                  // selectedId: "",
                };
              },
            );
        }
      }
    },

    deselectAllFollowUpQuestions: (
      state,
      action: PayloadAction<{ reasonId: string | number; scenarioId: string }>,
    ) => {
      const { reasonId, scenarioId } = action.payload;
      const refundReason = state.refundRequests.reasons.find(
        (reason) => reason.id === reasonId,
      );

      if (refundReason) {
        // Find the respective scenario
        const refundScenario = refundReason.scenarios.find(
          (scenario) => scenario.id === scenarioId,
        );

        if (refundScenario) {
          refundScenario.followUpQuestions = [];
        }
      }
    },

    updateFollowUpQuestionsSelectedValue: (
      state,
      action: PayloadAction<{
        reasonId: string | number;
        scenarioId: string;
        elementId: string;
        elementTypeId: string;
        selectedValue: string | string[];
        // selectedId: string | string[],
      }>,
    ) => {
      const { reasonId, scenarioId, elementId, elementTypeId, selectedValue } =
        action.payload;
      const refundReason = state.refundRequests.reasons.find(
        (reason) => reason.id === reasonId,
      );
      if (refundReason) {
        // Find the respective scenario
        const refundScenario = refundReason.scenarios.find(
          (scenario) => scenario.id === scenarioId,
        );

        if (refundScenario) {
          const elementIndex = refundScenario.followUpQuestions.findIndex(
            (element) =>
              element.elementId === elementId &&
              element.elementTypeId === elementTypeId,
          );
          if (elementIndex !== -1) {
            if (selectedValue === "*") {
              refundScenario.followUpQuestions[elementIndex].selectedValue =
                refundScenario.followUpQuestions[
                  elementIndex
                ].additionalOptions.map((val) => val.id);
              // refundScenario.followUpQuestions[elementIndex].selectedId =
              //   refundScenario.followUpQuestions[
              //     elementIndex
              //   ].additionalOptions.map((val) => val.id);
            } else {
              refundScenario.followUpQuestions[elementIndex].selectedValue =
                selectedValue;
              // refundScenario.followUpQuestions[elementIndex].selectedId =
              //   selectedId;
            }
          }
        }
      }
    },

    updateAdditionalInfo: (
      state,
      action: PayloadAction<{
        reasonId: string | number;
        scenarioId: string;
        additionalInfo: {
          enabled?: boolean;
          message?: string;
          extendReturnPeriod?: {
            enabled: boolean;
            days: number;
          };
        };
      }>,
    ) => {
      const { reasonId, scenarioId, additionalInfo } = action.payload;
      const refundReason = state.refundRequests.reasons.find(
        (reason) => reason.id === reasonId,
      );

      if (refundReason) {
        const refundScenario = refundReason.scenarios.find(
          (scenario) => scenario.id === scenarioId,
        );

        if (refundScenario) {
          if (refundScenario.additionalInformation === undefined) {
            refundScenario.additionalInformation = {
              enabled: false,
              message: "",
              extendReturnPeriod: {
                enabled: false,
                days: 0,
              },
            };
          }

          if (refundScenario && refundScenario.additionalInformation) {
            if (additionalInfo.enabled !== undefined) {
              refundScenario.additionalInformation.enabled =
                additionalInfo.enabled;
            }
            if (additionalInfo.message !== undefined) {
              refundScenario.additionalInformation.message =
                additionalInfo.message;
            }
            if (additionalInfo.extendReturnPeriod !== undefined) {
              refundScenario.additionalInformation.extendReturnPeriod.enabled =
                additionalInfo.extendReturnPeriod.enabled;
              refundScenario.additionalInformation.extendReturnPeriod.days =
                additionalInfo.extendReturnPeriod.days;
            }
          }
        }
      }
    },

    updateGiveOffer: (
      state,
      action: PayloadAction<{
        reasonId: string | number;
        scenarioId: string;
        giveOffer: {
          offerType?:
            | "partial_refund_of"
            | "discount_of"
            | "offer_clearance_product_for_free"
            | "other"
            | null;
          offerSelectedItems?: string[];
          offerSelectedTags?: string[];
          enabled?: boolean;
          message?: string;
          value?: string;
          type?: string;
        };
      }>,
    ) => {
      const { reasonId, scenarioId, giveOffer } = action.payload;
      const refundReason = state.refundRequests.reasons.find(
        (reason) => reason.id === reasonId,
      );

      if (refundReason) {
        const refundScenario = refundReason.scenarios.find(
          (scenario) => scenario.id === scenarioId,
        );

        if (refundScenario) {
          if (refundScenario.giveOffer === undefined) {
            refundScenario.giveOffer = {
              enabled: false,
              message: "",
              offerSelectedItems: [],
              offerSelectedTags: [],
              offerType: null,
              offerUnit: "percentage",
            };
          }

          if (refundScenario && refundScenario.giveOffer) {
            if (giveOffer?.enabled !== undefined) {
              refundScenario.giveOffer.enabled = giveOffer.enabled;
            }
            if (giveOffer?.message !== undefined) {
              refundScenario.giveOffer.message = giveOffer.message;
            }
            if (giveOffer?.offerSelectedItems !== undefined) {
              refundScenario.giveOffer.offerSelectedItems =
                giveOffer.offerSelectedItems;
            }
            if (giveOffer?.offerSelectedTags !== undefined) {
              refundScenario.giveOffer.offerSelectedTags =
                giveOffer.offerSelectedTags;
            }
            if (giveOffer?.offerType !== undefined) {
              refundScenario.giveOffer.offerType = giveOffer.offerType;
            }

            if (giveOffer?.value !== undefined) {
              refundScenario.giveOffer.offerValue = Number(giveOffer.value);
            }

            if (giveOffer?.type !== undefined) {
              refundScenario.giveOffer.offerUnit = giveOffer.type as
                | "fixed"
                | "percentage";
            }
          }
        }
      }
    },

    updateOfferExchange: (
      state,
      action: PayloadAction<{
        reasonId: string | number;
        scenarioId: string;
        offerExchange: {
          enabled?: boolean;
          message?: string;
          exchangeWithSameItems?: boolean;
          exchangeWithOtherItems?: boolean;
        };
      }>,
    ) => {
      const { reasonId, scenarioId, offerExchange } = action.payload;
      const refundReason = state.refundRequests.reasons.find(
        (reason) => reason.id === reasonId,
      );

      if (refundReason) {
        const refundScenario = refundReason.scenarios.find(
          (scenario) => scenario.id === scenarioId,
        );

        if (refundScenario) {
          if (refundScenario.offerExchange === undefined) {
            refundScenario.offerExchange = {
              enabled: false,
              message: "",
              exchangeWithSameItems: false,
              exchangeWithOtherItems: false,
            };
          }

          if (refundScenario && refundScenario.offerExchange) {
            if (offerExchange.enabled !== undefined) {
              refundScenario.offerExchange.enabled = offerExchange.enabled;
            }
            if (offerExchange.message !== undefined) {
              refundScenario.offerExchange.message = offerExchange.message;
            }
            if (offerExchange.exchangeWithSameItems !== undefined) {
              refundScenario.offerExchange.exchangeWithSameItems =
                offerExchange.exchangeWithSameItems;
            }
            if (offerExchange.exchangeWithOtherItems !== undefined) {
              refundScenario.offerExchange.exchangeWithOtherItems =
                offerExchange.exchangeWithOtherItems;
            }
          }
        }
      }
    },
    //Update redux state followup questions with the local state on saving followup questions
    updateFollowUpQuestionsConfig: (
      state,
      action: PayloadAction<{
        reasonId: string | number;
        scenarioId: string;
        localState: RefundScenarioState;
      }>,
    ) => {
      const { reasonId, scenarioId, localState } = action.payload;

      const refundReason = state.refundRequests.reasons.find(
        (reason) => reason.id === reasonId,
      );

      if (refundReason) {
        // Find the respective scenario
        const refundScenario = refundReason.scenarios.find(
          (scenario) => scenario.id === scenarioId,
        );
        if (refundScenario) {
          refundScenario.items = localState.items;
          refundScenario.tags = localState.tags;
          refundScenario.followUpQuestions = localState.followUpQuestions;
          refundScenario.itemsAndTagAllSelected =
            localState.itemsAndTagAllSelected;
        }
      }
    },
    //Update redux state additional info with the local state on saving additional info
    updateAdditionalInfoConfig: (
      state,
      action: PayloadAction<{
        reasonId: string | number;
        scenarioId: string;
        localState: RefundScenarioState;
      }>,
    ) => {
      const { reasonId, scenarioId, localState } = action.payload;

      const refundReason = state.refundRequests.reasons.find(
        (reason) => reason.id === reasonId,
      );

      if (refundReason) {
        // Find the respective scenario
        const refundScenario = refundReason.scenarios.find(
          (scenario) => scenario.id === scenarioId,
        );
        if (refundScenario) {
          refundScenario.additionalInformation =
            localState.additionalInformation;
          refundScenario.giveOffer = localState.giveOffer;
          refundScenario.offerExchange = localState.offerExchange;
        }
      }
    },
    //Reset the antirefund configuration to the initial state
    resetAntiRefundConfig: (state: AntiRefundConfigState) => {
      return initialState;
    },
  },
  extraReducers: antiRefundBuilder,
});

export default antiRefundConfigSlice.reducer;

export const {
  toggleAntiRefundMethod,
  addRefundScenario,
  addDefaultScenario,
  updateScenarioItems,
  updateScenarioTags,
  updateRefundReason,
  updateRefundScenario,
  updateItemAndTagsAllSelected,
  updateFollowUpQuestions,
  removeFollowUpQuestions,
  selectAllFollowUpQuestions,
  deselectAllFollowUpQuestions,
  updateFollowUpQuestionsSelectedValue,
  updateAdditionalInfo,
  updateGiveOffer,
  updateOfferExchange,
  updateFollowUpQuestionsConfig,
  updateAdditionalInfoConfig,
  resetAntiRefundConfig,
} = antiRefundConfigSlice.actions;
