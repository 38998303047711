import { useMutation } from "@tanstack/react-query";
import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import {
  CreateAutomationParams,
  createAutomationService,
} from "src/services/Automation/createAutomation.service";
import { deleteAutomationService } from "src/services/Automation/deleteAutomation.service";
import { IAutomationParams } from "../../AutomationList/AutomationList";
import { toggleAutomationService } from "src/services/Automation/toggleAutomation.service";

/**
 * Custom hook for creating new automations
 */
export const useCreateAutomation = () => {
  const { type } = useParams<IAutomationParams>();
  const navigate = useNavigate();

  const { isLoading, isError, mutateAsync } = useMutation({
    mutationFn: createAutomationService,
  });

  /**
   * Function used to add new automation
   * @returns Integration Data or null
   */
  const addNewAutomation = useCallback(
    async (payload: CreateAutomationParams) => {
      try {
        if (type && payload.brandId && payload.brandId !== -1) {
          // Execute the mutation
          const { integrationId } = await mutateAsync({ payload, type });

          if (integrationId) {
            navigate(`/automations/${type}/${integrationId}`);
          }
        }
      } catch (e) {
        console.error(e);
        pushTheToast({
          position: "top-right",
          text: (e as Error).message || "Something went wrong!",
          type: "danger",
        });
      }

      // Error encountered
      return null;
    },
    [type, mutateAsync, navigate],
  );

  return {
    addNewAutomation,
    isLoading,
    isError,
  };
};

/**
 * Custom hook for deleting automations
 */
export const useDeleteAutomation = (
  onDelete: (integrationId: string) => void | undefined,
) => {
  const { type } = useParams<IAutomationParams>();
  const { isLoading, isError, mutateAsync } = useMutation({
    mutationFn: deleteAutomationService,
    onSuccess: (_, payload) => {
      pushTheToast({
        position: "top-right",
        text: "Deleted Successfully!",
        type: "success",
      });

      if (onDelete && payload.payload.integrationId) {
        onDelete(payload.payload.integrationId + "");
      }
    },
    onError: (err) => {
      const error = err as Error;
      pushTheToast({
        position: "top-right",
        text: error?.message ?? "Could not delete the automation!",
        type: "danger",
      });
    },
  });

  /**
   * Function used to delete automation using integration id
   */
  const deleteAutomation = useCallback(
    async (integrationId: number | string) => {
      if (type && integrationId !== -1) {
        mutateAsync({ type, payload: { integrationId } });
      }
    },
    [type, mutateAsync],
  );

  return {
    deleteAutomation,
    isLoading,
    isError,
  };
};

/**
 * Custom hook for toggle rule (Ticket Routing)
 */
export const useToggleAutomation = (
  onRuleToggle: (enabled: boolean) => void | undefined,
) => {
  const { type } = useParams<IAutomationParams>();
  const { isLoading, isError, mutateAsync } = useMutation({
    mutationFn: toggleAutomationService,
    onSuccess: (_, payload) => {
      pushTheToast({
        position: "top-right",
        text: "Updated Successfully!",
        type: "success",
      });

      if (onRuleToggle && payload.payload.integrationId) {
        onRuleToggle(payload.payload.enabled);
      }
    },
    onError: (err) => {
      const error = err as Error;
      pushTheToast({
        position: "top-right",
        text: error?.message ?? "Could not update the automation!",
        type: "danger",
      });
    },
  });

  /**
   * Function used to toggle rule automation using integration id
   */
  const toggleAutomation = useCallback(
    async (integrationId: number | string, enabled: boolean) => {
      if (type && integrationId !== -1) {
        mutateAsync({ type, payload: { integrationId, enabled } });
      }
    },
    [type, mutateAsync],
  );

  return {
    toggleAutomation,
    isLoading,
    isError,
  };
};
