import { OverlayTrigger, Popover } from "react-bootstrap";
import styles from "./IssueDescription.module.scss";

const popover = (
  <Popover
    id="popover-basic"
    bsPrefix={`popover ${styles.customPopver}`}
  >
    <Popover.Body>
      <h6 className={`${styles.heading}`}>What is issue dispatching?</h6>
      <span className={`text-start ${styles.subHeading}`}>
        Issue dispatching typically refers to the process of immediately
        escalating urgent bugs to the appropriate individuals or teams within
        your organization via phone, email or text for immediate resolution or
        action.
      </span>
    </Popover.Body>
  </Popover>
);

export const IssueDispatchInfoPopover = () => {
  return (
    <OverlayTrigger
      trigger={["hover", "focus"]}
      placement="top"
      overlay={popover}
    >
      <span className={`ps-1 ${styles.info}`}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="13"
          height="13"
          viewBox="0 0 14.098 14.098"
        >
          <g
            id="Component_356_4"
            data-name="Component 356 – 4"
            transform="translate(0.5 0.5)"
          >
            <g
              id="Icon_ion-information-circle-outline"
              data-name="Icon ion-information-circle-outline"
              transform="translate(-4.5 -4.5)"
            >
              <path
                id="Path_7337"
                data-name="Path 7337"
                d="M11.049,4.5A6.549,6.549,0,1,0,17.6,11.049,6.549,6.549,0,0,0,11.049,4.5Z"
                fill="none"
                stroke="#000"
                strokeWidth="1"
              />
              <path
                id="Path_7338"
                data-name="Path 7338"
                d="M15.469,15.469h1.139V19.6"
                transform="translate(-5.416 -5.416)"
                fill="none"
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
              <path
                id="Path_7339"
                data-name="Path 7339"
                d="M14.625,23.906h3.132"
                transform="translate(-5 -9.583)"
                fill="none"
                stroke="#000"
                strokeLinecap="round"
                strokeWidth="1"
              />
              <path
                id="Path_7340"
                data-name="Path 7340"
                d="M16.535,9.141a.925.925,0,1,0,.925.925.925.925,0,0,0-.925-.925Z"
                transform="translate(-5.486 -2.292)"
              />
            </g>
          </g>
        </svg>
      </span>
    </OverlayTrigger>
  );
};

const IssueDescriptionComp = ({
  index,
  handleChange,
  handleDelete,
  description,
  error,
}: {
  index: number;
  handleChange: (index: number, value: string) => void;
  handleDelete?: (index: number) => void;
  description: string;
  error: boolean | undefined;
}) => {
  return (
    <div className="mb-2">
      <div className="d-flex justify-content-between">
        <label
          htmlFor={`${index}_issue_description`}
          className={`form-label ${styles.desc}`}
        >
          Issue Description {index + 1}:
          <IssueDispatchInfoPopover />
        </label>
        {handleDelete !== undefined && (
          <button
            className={`${styles.deleteDetails}`}
            onClick={() => handleDelete(index)}
          >
            Delete
          </button>
        )}
      </div>
      <textarea
        className={`form-control ${styles.textBox} ${
          error ? styles.errorBorder : ""
        }`}
        id={`${index}_issue_description`}
        rows={3}
        value={description}
        placeholder="Type description of issue here..."
        onChange={(event) => handleChange(index, event.target.value)}
      ></textarea>
    </div>
  );
};

export default IssueDescriptionComp;
