import { selectTicketCellData } from 'src/store/slices/tickets/tickets.slice';
import { useAppSelector } from 'src/store/store';
import { TableCellProps } from '..';
import styles from './OpenTickets.module.scss';

const OpenTickets = ({ ticketId, colKeys }: TableCellProps) => {
  const ticketCellData: any = useAppSelector((state) =>
    selectTicketCellData(state, ticketId, colKeys)
  );
  return(
    <div className={`${styles.subject}`}>
   <span>{ ticketCellData[0] }</span>
    </div>
  )
}
export default OpenTickets;