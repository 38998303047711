import React, {
  useState,
  useCallback,
  useMemo,
  useRef,
  useEffect,
} from "react";
import styles from "./TimePicker.module.scss"; // Assuming you are using SCSS modules

// Define the props for the TimePicker component
interface TimePickerProps {
  selectedTime: Date | null;
  onChangeTime: (value: Date | null) => void;
}

// Option component represents a selectable time option
const Option = ({
  option,
  isActive,
  type,
  onChangeOption,
}: {
  option: string;
  isActive: boolean;
  type: "h" | "m" | "s" | "i";
  onChangeOption: (data: {
    value: string;
    type: "h" | "m" | "s" | "i";
  }) => void;
}) => {
  // Ref to the DOM element for scrolling into view
  const optRef = useRef(null as any);

  // Handle option click
  const handleOnClickOption = useCallback(() => {
    onChangeOption({ value: option, type });
  }, [option, onChangeOption, type]);

  // Scroll into view when the option is active
  useEffect(() => {
    if (isActive && optRef.current) {
      optRef.current.scrollIntoView();
    }
  }, [isActive]);

  // Render the option
  return (
    <div
      ref={optRef}
      onClick={handleOnClickOption}
      className={`${styles.option} ${isActive ? styles.active : ""}`}
    >
      {option}
    </div>
  );
};

// TimePicker component
const TimePicker: React.FC<TimePickerProps> = ({
  selectedTime,
  onChangeTime,
}) => {
  // Arrays for hours, minutes, seconds, and am/pm options
  const [hours] = useState(
    Array.from({ length: 12 }, (_, i) => (i + 1).toString().padStart(2, "0"))
  );
  const [minutes] = useState(
    Array.from({ length: 60 }, (_, i) => i.toString().padStart(2, "0"))
  );
  const [seconds] = useState(
    Array.from({ length: 60 }, (_, i) => i.toString().padStart(2, "0"))
  );
  const [ampm] = useState(["AM", "PM"]);

  // Memoize time parts based on the selected time
  const timePartsFromSelectedTime = useMemo(() => {
    if (selectedTime === null) {
      return {
        hours: "--",
        minutes: "--",
        seconds: "--",
        ampm: "--",
      };
    }

    // Extract hours, minutes, seconds, and am/pm from the selected time
    const hours = selectedTime.getHours() % 12 || 12; // Convert 0 to 12
    const minutes = selectedTime.getMinutes();
    const seconds = selectedTime.getSeconds();
    const ampm = selectedTime.getHours() >= 12 ? "PM" : "AM";

    return {
      hours: hours.toString().padStart(2, "0"),
      minutes: minutes.toString().padStart(2, "0"),
      seconds: seconds.toString().padStart(2, "0"),
      ampm,
    };
  }, [selectedTime]);

  // State variables for selected time parts
  const [selectedHours, setSelectedHours] = useState(
    timePartsFromSelectedTime.hours
  );
  const [selectedMinutes, setSelectedMinutes] = useState(
    timePartsFromSelectedTime.minutes
  );
  const [selectedSeconds, setSelectedSeconds] = useState(
    timePartsFromSelectedTime.seconds
  );
  const [selectedAmpm, setSelectedAmpm] = useState(
    timePartsFromSelectedTime.ampm
  );

  // Update selected time parts when the selected time changes
  useMemo(() => {
    setSelectedHours(timePartsFromSelectedTime.hours);
    setSelectedMinutes(timePartsFromSelectedTime.minutes);
    setSelectedSeconds(timePartsFromSelectedTime.seconds);
    setSelectedAmpm(timePartsFromSelectedTime.ampm);
  }, [timePartsFromSelectedTime]);

  // Generate a Date object from the selected time parts
  const generateTimeSelected = useCallback(
    ({
      selectedHours,
      selectedMinutes,
      selectedSeconds,
      selectedAmpm,
    }: {
      selectedHours: string;
      selectedMinutes: string;
      selectedSeconds: string;
      selectedAmpm: string;
    }) => {
      if (
        selectedHours !== "--" ||
        selectedMinutes !== "--" ||
        selectedSeconds !== "--" ||
        selectedAmpm !== "--"
      ) {
        let hours = parseInt(selectedHours === "--" ? "01" : selectedHours, 10);
        const minutes = parseInt(
          selectedMinutes === "--" ? "00" : selectedMinutes,
          10
        );
        const seconds = parseInt(
          selectedSeconds === "--" ? "00" : selectedSeconds,
          10
        );
        const ampm = selectedAmpm === "--" ? "AM" : selectedAmpm;

        // Adjust hours based on AM/PM
        if (ampm === "PM" && hours !== 12) {
          hours += 12;
        } else if (ampm === "AM" && hours === 12) {
          hours = 0;
        }

        // Create a new Date object with the given values
        const currentDate = new Date();
        currentDate.setHours(hours, minutes, seconds);

        return currentDate;
      }

      return null; // Return null if any of the values is still "--"
    },
    []
  );

  // Set the selected time parts and update the parent component
  const setSelectedTimes = useCallback(
    ({ value, type }: { value: string; type: "h" | "m" | "s" | "i" }) => {
      let params = {
        selectedHours,
        selectedMinutes,
        selectedSeconds,
        selectedAmpm,
      };
      if (type === "h") {
        setSelectedHours(value);
        params.selectedHours = value;
      } else if (type === "m") {
        setSelectedMinutes(value);
        params.selectedMinutes = value;
      } else if (type === "s") {
        setSelectedSeconds(value);
        params.selectedSeconds = value;
      } else {
        setSelectedAmpm(value);
        params.selectedAmpm = value;
      }
      onChangeTime(generateTimeSelected(params));
    },
    [
      generateTimeSelected,
      selectedHours,
      selectedMinutes,
      selectedSeconds,
      selectedAmpm,
    ]
  );

  // Render the TimePicker component
  return (
    <div className={`${styles.timePicker}`}>
      {/* Hour Selection */}
      <div className={`${styles.scrollTop}`}>
        <i
          className={`fa-solid fa-chevron-up ${styles.up}`}
          onClick={useCallback(() => {
            // Decrease selected hour by 1 (min: 1)
            let value =
              parseInt(selectedHours === "--" ? "01" : selectedHours, 10) - 1;
            if (value < 1) {
              value = 1;
            }
            setSelectedTimes({
              value: value.toString().padStart(2, "0"),
              type: "h",
            });
          }, [selectedHours, setSelectedTimes])}
        ></i>
        <div className={`${styles.scrollable}`}>
          {hours.map((option, index) => (
            <Option
              key={option}
              option={option}
              isActive={option === selectedHours}
              type="h"
              onChangeOption={setSelectedTimes}
            />
          ))}
        </div>
        <i
          className={`fa-solid fa-chevron-down ${styles.down}`}
          onClick={useCallback(() => {
            // Increase selected hour by 1 (max: 12)
            let value =
              parseInt(selectedHours === "--" ? "01" : selectedHours, 10) + 1;
            if (value > 12) {
              value = 12;
            }
            setSelectedTimes({
              value: value.toString().padStart(2, "0"),
              type: "h",
            });
          }, [selectedHours, setSelectedTimes])}
        ></i>
      </div>

      {/* Minute Selection */}
      <div className={`${styles.scrollTop}`}>
        <i
          className={`fa-solid fa-chevron-up ${styles.up}`}
          onClick={useCallback(() => {
            // Decrease selected minute by 1 (min: 0)
            let value =
              parseInt(selectedMinutes === "--" ? "00" : selectedMinutes, 10) -
              1;
            if (value < 0) {
              value = 0;
            }
            setSelectedTimes({
              value: value.toString().padStart(2, "0"),
              type: "m",
            });
          }, [selectedMinutes, setSelectedTimes])}
        ></i>
        <div className={`${styles.scrollable}`}>
          {minutes.map((option, index) => (
            <Option
              key={option}
              option={option}
              isActive={option === selectedMinutes}
              type="m"
              onChangeOption={setSelectedTimes}
            />
          ))}
        </div>
        <i
          className={`fa-solid fa-chevron-down ${styles.down}`}
          onClick={useCallback(() => {
            // Increase selected minute by 1 (max: 59)
            let value =
              parseInt(selectedMinutes === "--" ? "00" : selectedMinutes, 10) +
              1;
            if (value > 59) {
              value = 59;
            }
            setSelectedTimes({
              value: value.toString().padStart(2, "0"),
              type: "m",
            });
          }, [selectedMinutes, setSelectedTimes])}
        ></i>
      </div>

      {/* Second Selection */}
      <div className={`${styles.scrollTop}`}>
        <i
          className={`fa-solid fa-chevron-up ${styles.up}`}
          onClick={useCallback(() => {
            // Decrease selected second by 1 (min: 0)
            let value =
              parseInt(selectedSeconds === "--" ? "00" : selectedSeconds, 10) -
              1;
            if (value < 0) {
              value = 0;
            }
            setSelectedTimes({
              value: value.toString().padStart(2, "0"),
              type: "s",
            });
          }, [selectedSeconds, setSelectedTimes])}
        ></i>
        <div className={`${styles.scrollable}`}>
          {seconds.map((option, index) => (
            <Option
              key={option}
              option={option}
              isActive={option === selectedSeconds}
              type="s"
              onChangeOption={setSelectedTimes}
            />
          ))}
        </div>
        <i
          className={`fa-solid fa-chevron-down ${styles.down}`}
          onClick={useCallback(() => {
            // Increase selected second by 1 (max: 59)
            let value =
              parseInt(selectedSeconds === "--" ? "00" : selectedSeconds, 10) +
              1;
            if (value > 59) {
              value = 59;
            }
            setSelectedTimes({
              value: value.toString().padStart(2, "0"),
              type: "s",
            });
          }, [selectedSeconds, setSelectedTimes])}
        ></i>
      </div>

      {/* AM/PM Selection */}
      <div className={`${styles.scrollTop}`}>
        <i
          className={`fa-solid fa-chevron-up ${styles.up}`}
          onClick={useCallback(() => {
            // Set selected time to "AM"
            setSelectedTimes({
              value: "AM",
              type: "i",
            });
          }, [setSelectedTimes])}
        ></i>
        <div className={`${styles.scrollable}`}>
          {ampm.map((option, index) => (
            <Option
              key={option}
              option={option}
              isActive={option === selectedAmpm}
              type="i"
              onChangeOption={setSelectedTimes}
            />
          ))}
        </div>
        <i
          className={`fa-solid fa-chevron-down ${styles.down}`}
          onClick={useCallback(() => {
            // Set selected time to "PM"
            setSelectedTimes({
              value: "PM",
              type: "i",
            });
          }, [setSelectedTimes])}
        ></i>
      </div>
    </div>
  );
};

export default TimePicker;
