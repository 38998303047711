import { Link, useNavigate } from "react-router-dom";
import styles from "./TeamList.module.scss";
interface props {
  name: string;
}

function TeamList(props: any) {
  // console.log("TeamList::: Team::: ", props.team);
  let navigate = useNavigate();
  return (
    <div className={`w-100 mt-4`}>
      <div className={`col-12 ${styles.teamWrapper}`}>
        <div className="">
          <div className="choose-team">
            <div>
              <div className={`d-flex align-items-center`}>
                <div className={`${styles.teamName}`}>
                  <div className="d-flex align-items-center choose-team">
                    <div className="status-image position-relative">
                      <i className="fas fa-users"></i>
                    </div>
                    <div className={`name-email ms-4 `}>
                      <div className={`${styles.name}`}>
                        {props.team.teamName}
                      </div>
                      <div className={`${styles.memberCount}`}>
                        {props.team.users} members
                      </div>
                    </div>
                  </div>
                </div>

                <div className={`${styles.teamInfo}`}>
                  <div className="edr d-flex justify-content-center align-items-center">
                    <span className="edit-icons">
                      <button
                        style={{ border: "none", background: "none" }}
                        onClick={() => {
                          navigate("/settings/Teams/" + props.team.teamId);
                        }}
                      >
                        <svg
                          width="29"
                          height="29"
                          viewBox="0 0 29 29"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.625 6.04191V22.9586C3.625 23.5995 3.87961 24.2142 4.33283 24.6674C4.78604 25.1206 5.40073 25.3752 6.04167 25.3752H22.9583C23.5993 25.3752 24.214 25.1206 24.6672 24.6674C25.1204 24.2142 25.375 23.5995 25.375 22.9586V6.04191C25.375 5.40097 25.1204 4.78628 24.6672 4.33307C24.214 3.87986 23.5993 3.62524 22.9583 3.62524H6.04167C5.40073 3.62524 4.78604 3.87986 4.33283 4.33307C3.87961 4.78628 3.625 5.40097 3.625 6.04191ZM8.45833 13.2919H14.5V8.45858L20.5417 14.5002L14.5 20.5419V15.7086H8.45833V13.2919Z"
                            stroke="black"
                            strokeWidth="2"
                          />
                          <path
                            d="M22.9584 14.5L14.7876 7.25V13.2917H7.78406V15.7083H14.7876V21.75L22.9584 14.5Z"
                            fill="black"
                          />
                        </svg>
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TeamList;
