/**
 * This file defines a React component responsible for displaying and managing
 * all available bot profiles. It handles functionalities like editing, previewing, deleting bot profiles
 * and creating new bot profiles.
 *
 * @author @navjyot-busibud
 * @author @yuvaraj-busibud
 * @author @Anubhav-busibud
 */
import { Modal } from "react-bootstrap";
import { BotProfileResponse } from "src/services/Bot/BotProfiles/getAllBotProfiles.service";
import Loader from "src/components/Loader";
import BotBox from "../BotBox/BotBox";
import BrandModal from "../BotBox/BrandModal/BrandModal";
import styles from "../../BotSettings.module.scss";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import useBotProfiles from "./useBotProfiles";
import InfiniteScroll from "src/components/InfiniteScroll";
import { useEffect, useState } from "react";
import DeleteBot from "../BotBox/ChatBot/Children/DeleteBot/DeleteBot";
import UpdaBotProfile from "../UpdateBotProfile/UpdaBotProfile";
import { useAppSelector } from "src/store/store";
import PlanModal from "src/components/PlanUpgrade/Children/PlanModal/PlanModal";
import PlanUpgradeOverlay from "src/components/PlanUpgrade/PlanUpgradeOverlay/PlanUpgradeOverlay";

const BotProfiles = () => {
  const {
    showModal,
    onShow,
    onHide,
    isCreating,
    handleNextClick,
    isLoading,
    isError,
    hasNextPage,
    fetchNextPage,
    isRefetching,
    botProfileData,
    handleOnDragEnd,
    handleInfiniteScroll,
    status,
    isFetching,
  } = useBotProfiles();

  // state to show/hide delete modal
  const [showDelete, setShowDelete] = useState(false);
  const [selectedDeleteProfile, setSelectedDeleteProfile] =
    useState<BotProfileResponse>();
  // Delete modal open function
  const onShowDeleteModal = (botProfile: BotProfileResponse) => {
    setSelectedDeleteProfile(botProfile);
    setShowDelete(true);
    // console.log("show");
  };
  //Delete  modal close function
  const onHideDeleteModal = () => {
    setShowDelete(false);
    // console.log("hide");
  };

  // Fetching disabledFeatures from the Redux store
  const disabledFeatures = useAppSelector(
    (state) => state.globals.currentUserData?.disabledFeatures,
  );

  // To show plan modal
  const [showPlanModal, setShowPlanModal] = useState(false);
  const handleShowPlanModal = (value: boolean) => {
    setShowPlanModal(value);
  };

  return (
    <>
      {/* Bot Profiles */}
      <div className={`w-100 ${styles.contentWrapper}`}>
        <div className="d-flex justify-content-between ps-2">
          <h4 className={`mb-1 ${styles.botHeading}`}>Bot Profiles</h4>
          <PlanUpgradeOverlay
            showOverlay={disabledFeatures?.includes("bot_profile") ?? false}
            message="Upgrade to add a different bot for a specific audience, on a specific page"
          >
            <button
              className={`ms-1 ms-md-0 px-3 d-flex align-items-center justify-content-center ${
                styles.editBtn
              } ${
                disabledFeatures?.includes("bot_profile") && styles.disabled
              }`}
              onClick={onShow}
              disabled={disabledFeatures?.includes("bot_profile")}
              id="addProfileBtn"
            >
              <span>
                <i className="fa-solid fa-plus"></i>
              </span>{" "}
              <span className="ps-1"> Add new profile </span>
            </button>
          </PlanUpgradeOverlay>

          {disabledFeatures?.includes("bot_profile") && (
            <PlanModal
              hideModal={() => handleShowPlanModal(false)}
              showModal={showPlanModal}
            />
          )}

          <Modal
            backdropClassName={`${styles.modalBack}`}
            show={showModal}
            onHide={onHide}
            dialogClassName={`${styles.modalDialog}`}
            contentClassName={`${styles.modalContent}`}
          >
            <BrandModal
              onHide={onHide}
              handleNextClick={handleNextClick}
              isCreating={isCreating}
            />
          </Modal>
        </div>
        <span className={`pb-3 pt-2 pt-md-0 px-2 ${styles.subHeading}`}>
          Only one bot profile will be matched per user. Priority order ranges
          from top to bottom. Drag a profile to rearrange their order.
        </span>
        {isLoading || isRefetching ? (
          <Loader />
        ) : isError ? (
          <>
            <div className={`w-100 ${styles.errorMsg}`}>
              <span>Something went wrong while Fetching the data </span>{" "}
            </div>
            <div></div>
          </>
        ) : (
          <div className={`${styles.botWrappper}`}>
            <InfiniteScroll
              className={`h-100 overflow-auto px-2 py-2`}
              loadMore={handleInfiniteScroll}
              loader={
                isFetching && botProfileData?.length === 0 ? (
                  // <div className={`d-flex flex-column m-auto ${styles.loading}`}>
                  <Loader />
                ) : // </div>
                undefined
              }
              errorMessage={
                status === "error" && botProfileData?.length === 0 ? (
                  <div className={`d-flex flex-column m-auto`}>
                    No Data Found
                  </div>
                ) : (
                  ""
                )
              }
              initialDataLoaded={true}
              hasMore={hasNextPage}
            >
              <div>
                <DragDropContext
                  onDragEnd={(result) => handleOnDragEnd(result)}
                >
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {botProfileData?.map(
                          (botProfile: BotProfileResponse, idx: number) => {
                            return (
                              <Draggable
                                key={botProfile.id}
                                draggableId={
                                  botProfile.id ? botProfile.id.toString() : ""
                                }
                                index={idx}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    className="d-flex align-items-center mb-2"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    {botProfile.id && (
                                      <BotBox
                                        key={botProfile.id}
                                        botProfile={botProfile}
                                        handleDelete={onShowDeleteModal}
                                      />
                                    )}
                                  </div>
                                )}
                              </Draggable>
                            );
                          },
                        )}
                        {provided.placeholder}
                        {!isLoading &&
                          !isFetching &&
                          botProfileData?.length === 1 &&
                          disabledFeatures?.includes("bot_profile") && (
                            <UpdaBotProfile
                              showModal={() => handleShowPlanModal(true)}
                            />
                          )}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </InfiniteScroll>
          </div>
        )}
        <Modal
          dialogClassName={`${styles.modalDialog}`}
          contentClassName={`${styles.modalContent}`}
          show={showDelete}
          onHide={onHideDeleteModal}
          backdropClassName={`${styles.backDropClass}`}
        >
          <DeleteBot
            selectedProfile={selectedDeleteProfile}
            onHide={onHideDeleteModal}
          />
        </Modal>
      </div>
    </>
  );
};

export default BotProfiles;
