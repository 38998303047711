import { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import ParseHTML from "src/components/ParseHTML";
import QuillEditorContainer from "src/components/QuillEditorContainer";
import UserAvatar from "src/components/UserAvatar";
import Pagination from "src/routes/TicketList/children/Pagination/Pagination";
import {
  addEditDraftMsgData,
  fetchDraftMsgHistory,
  IDraftMsg,
  IDraftMsgHistoryObj,
} from "src/store/slices/draftFlow/draftFlow.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { getDraftTextColor } from "src/utils/utils";
import ShowTime from "../../../ShowTime";
import styles from "./DraftMsg.module.scss";
import DraftMsgView from "./DraftMsgView";

interface Props extends IDraftMsg {
  canEdit?: boolean;
  draftMsgHistory?: IDraftMsgHistoryObj;
  onAnyChange?: () => void;
}

function DraftMsg({
  canEdit = true,
  draftMsgHistory,
  onAnyChange = () => {},
  ...props
}: Props) {
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState(
    props.draftMsgHistoryCount + 1
  );
  const scopes = useAppSelector(
    (state) => state.globals.currentUserData?.scopes
  );
  const currentState = useRef({ draftMsgHistory, currentPage });
  const [selectedFilesEditDraft, setSelectedFilesEditDraft] = useState<any>([]);
  const [isFileUploadingEditDraft, setIsFileUploadingEditDraft] =
    useState<boolean>(false);
  useEffect(() => {
    currentState.current = { draftMsgHistory, currentPage };
  }, [draftMsgHistory, currentPage]);
  const current =
    currentPage === props.draftMsgHistoryCount + 1 ||
    draftMsgHistory == undefined
      ? props
      : draftMsgHistory[currentPage];

  const handelSetCurrentPage = (page: number) => {
    if (
      currentState.current.draftMsgHistory !== undefined &&
      props.messageId != null
    ) {
      if (
        currentState.current.draftMsgHistory[page] == undefined &&
        page < props.draftMsgHistoryCount + 1
      ) {
        dispatch(
          fetchDraftMsgHistory({
            msgId: props.messageId,
            historyNum: page,
            callback: () => {
              setCurrentPage(page);
            },
          })
        );
      } else {
        setCurrentPage(page);
      }
    }
  };
  const handelOnEdit = (value: string) => {
    dispatch(
      addEditDraftMsgData({
        msgId: props.messageId,
        msgContent: value,
        fetchAll: true,
        draftAttachmentBatchNumbers: selectedFilesEditDraft
          ? selectedFilesEditDraft.map(function (value: any, index: any) {
              return value.batchNumber || value.attachmentBatchNumber;
            })
          : [],
        callback: onAnyChange,
      })
    );
  };

  return (
    <>
      <DraftMsgView
        statusName={current.draftMsgStatusName}
        agentName={current.agentName}
        agentImgURL={current.agentImgUrl}
        messageDate={current.messageDate}
        showHistory={draftMsgHistory !== undefined}
        currentPage={currentPage}
        totalPages={props.draftMsgHistoryCount + 1}
        setCurrentPage={handelSetCurrentPage}
        canEdit={
          canEdit && props.draftMsgHistoryCount + 1 === currentPage
            ? scopes && scopes.includes("add_draft")
              ? true
              : false
            : false
        }
        editDraftMsg={handelOnEdit}
        messageContent={current.messageContent}
        selectedFilesEditDraft={selectedFilesEditDraft}
        setSelectedFilesEditDraft={setSelectedFilesEditDraft}
        isFileUploadingEditDraft={isFileUploadingEditDraft}
        setIsFileUploadingEditDraft={setIsFileUploadingEditDraft}
        messageData={current} // added to show attachments
        attachments={props.attachments}
        isPublicAttachmentUrl={current.isPublicAttachmentUrl}
      />
    </>
  );
}

export default DraftMsg;
