import styles from "./DiscardModal.module.scss";

interface Props {
  setShow: (isShow: boolean) => void;
  closeMainModel: () => void;
  onConfirm: () => void;
}

const DiscardModal = ({ setShow, closeMainModel, onConfirm }: Props) => {
  return (
    <div className={`text-center `}>
      <h3 className={`mb-2 mb-lg-3 ${styles.modalHead}`}>
        Do you want to proceed without saving the changes?
      </h3>
      <span className={`d-inline-block mb-2 ${styles.modalSpan}`}>
        This will discard all the changes that you have made
      </span>
      <div
        className={`d-flex flex-column flex-md-row justify-content-center align-items-center mt-3`}
      >
        <button
          className={`bg-white me-0 me-md-4 mb-2 mb-md-0 px-4 py-1 ${styles.cancelBtn}`}
          onClick={() => {
            setShow(false);
          }}
        >
          Cancel
        </button>
        <button
          className={`border-0 px-4 py-1 ${styles.saveBtn}`}
          onClick={() => {
            onConfirm();
            setShow(false);
            closeMainModel();
          }}
        >
          Confirm
        </button>
      </div>
    </div>
  );
};

export default DiscardModal;
