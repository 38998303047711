import { axiosJSON } from "src/globals/axiosEndPoints";

export interface AddSmtpParams {
  emailIntegrationId: number;
  smtpUsername: string;
  smtpPassword: string;
  smtpHost?: string;
  smtpPort?: string;
  smtpUseSSL?: boolean;
}

/**
 * Service for adding smtp details
 */
const addSmtpDetailsService = async (params: AddSmtpParams) => {
  const { data: res } = await axiosJSON.post(
    "/api/setting/integration/addOutgoingDetails",
    params,
  );

  if (res.err === true || res.error === true) {
    if (res.statusCode === "auth_failed") {
      return false;
    }
    throw new Error(res.msg ?? res.message ?? "Some error occured");
  }
  return true;
};

export default addSmtpDetailsService;
