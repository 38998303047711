import React, { useMemo, useState } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { viewIdLink } from "src/services/TicketService/linkGenerator";
import { useAppliedFilters } from "./Children/CustomHook/useAppliedFilters";
import { useMapSidebarFilters } from "./Children/CustomHook/useMapSidebarFilters";
import SaveAsModal from "./Children/SaveAsModal/SaveAsModal";
import styles from "./ViewsFilter.module.scss";
import { v4 as uuid } from "uuid";

const ViewsFilter = () => {
  const { tableType } = useParams();

  const {
    viewFilters,
    otherFilters,
    removeFilter,
    setAddViewTicketFilters,
    addViewLoading,
    saveFilterAsView,
    viewName,
    viewNameOnChange,
    showViewNameError,
    restoreToDefaultFilters,
  } = useAppliedFilters();

  const {} = useMapSidebarFilters();

  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const onShow = (e: any) => {
    e.stopPropagation();
    setAddViewTicketFilters();
    setShowModal(true);
    // console.log("show");
  };
  const onHide = () => {
    setShowModal(false);
    // console.log("hide");
  };

  //function handles on success of add view api response
  const addViewSuccessCallback = (viewId: number) => {
    onHide();

    //generating link for navigating user to created view
    let viewLink = viewIdLink(viewId);

    pushTheToast({
      type: "success",
      text: "New View Saved Successfully!",
      position: "top-right",
    });

    if (viewId) {
      navigate(viewLink);
    }
  };

  const onSubmitHandler = (e: any) => {
    saveFilterAsView(e, addViewSuccessCallback);
  };

  /**
   * Calculate if current view is default view or not
   */
  const isDefaultView = useMemo(() => {
    // If table is my or all
    if (tableType === "my" || tableType === "all") {
      // We have only one other filter
      if (otherFilters.length === 1) {
        // Get the filter
        const filter = otherFilters[0];

        // If the filter is last updated and filter value is last 30 days
        if (
          filter.key === "ticket_modified_gmt" &&
          filter.compare === "last-30-days"
        ) {
          // It is default view
          return true;
        }
      }
    } else {
      // If it is a user custom view and there are no other filters selected
      if (otherFilters.length === 0) {
        // It is default view
        return true;
      }
    }

    // Not default view
    return false;
  }, [otherFilters, tableType]);

  return (
    <>
      <div className={`pt-3`} id="viewsFilter">
        <div className={`${styles.lineHeight}`}>
          {viewFilters.length !== 0 && (
            <>
              <span className={`pe-1 ${styles.viewFilter}`}>
                Views Filters :
              </span>
              {viewFilters.map((viewFilter) => {
                if (viewFilter.value) {
                  return (
                    <div
                      key={uuid()}
                      className={`px-2 py-1 me-2 ${styles.filterBox}`}
                    >
                      <span>
                        {viewFilter.name} - {viewFilter.value}
                      </span>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip className={` ${styles.toolTipCustom}`}>
                            <span className={`${styles.tooltipCss}`}>
                              “This filter is coming from a view. Please change
                              the view or the view settings to remove it.”
                            </span>
                          </Tooltip>
                        }
                      >
                        {({ ref, ...triggerHandler }) => (
                          <span {...triggerHandler} ref={ref}>
                            <span
                              className={`ps-2 cursor-pointer ${styles.exclaim}`}
                            >
                              <i className="fa-solid fa-circle-exclamation"></i>
                            </span>
                          </span>
                        )}
                      </OverlayTrigger>
                    </div>
                  );
                }
              })}
            </>
          )}

          {otherFilters.length !== 0 && (
            <>
              <span className={`pe-2 ${styles.viewFilter}`}>
                Other Filters :
              </span>
              {otherFilters.map((otherFilter) => {
                return (
                  <div
                    key={uuid()}
                    className={`px-2 py-1 me-1 ${styles.otherFilter}`}
                  >
                    <span>
                      {otherFilter?.name} : {otherFilter.value}
                    </span>
                    <span
                      className={`ps-2 cursor-pointer ${styles.remove}`}
                      onClick={() => removeFilter(otherFilter.key)}
                    >
                      <i className="fa-solid fa-xmark"></i>
                    </span>
                  </div>
                );
              })}

              {/*               
              <div className={`px-2 py-1 me-1 ${styles.otherFilter}`}>
                <span>Facebook message</span>
                <span className={`ps-2 cursor-pointer ${styles.remove}`}>
                  <i className="fa-solid fa-xmark"></i>
                </span>
              </div>
              <div className={`px-2 py-1 me-1 ${styles.otherFilter}`}>
                <span>Pallavi</span>
                <span className={`ps-2 cursor-pointer ${styles.remove}`}>
                  <i className="fa-solid fa-xmark"></i>
                </span>
              </div>
              <div className={`px-2 py-1 me-1 ${styles.otherFilter}`}>
                <span>Return order, Exchange</span>
                <span className={`ps-2 cursor-pointer ${styles.remove}`}>
                  <i className="fa-solid fa-xmark"></i>
                </span>
              </div>
              <div className={`px-2 py-1 me-1 ${styles.otherFilter}`}>
                <span>Avi</span>
                <span className={`ps-2 cursor-pointer ${styles.remove}`}>
                  <i className="fa-solid fa-xmark"></i>
                </span>
              </div>
              <div className={`px-2 py-1 me-1 ${styles.otherFilter}`}>
                <span>Pending to edit</span>
                <span className={`ps-2 cursor-pointer ${styles.remove}`}>
                  <i className="fa-solid fa-xmark"></i>
                </span>
              </div>
              <div className={`px-2 py-1 me-1 ${styles.otherFilter}`}>
                <span>Pending to Approve</span>
                <span className={`ps-2 cursor-pointer ${styles.remove}`}>
                  <i className="fa-solid fa-xmark"></i>
                </span>
              </div> */}
            </>
          )}
        </div>
      </div>
      {!isDefaultView ? (
        <div>
          <button
            className={`me-2 ${styles.restoreBtn}`}
            onClick={restoreToDefaultFilters}
          >
            {" "}
            Restore to default view
          </button>
          <button className={`ms-1 ${styles.restoreBtn}`} onClick={onShow}>
            Save as new View
          </button>
          <Modal
            // backdropClassName={`${styles.modalBack}`}
            show={showModal}
            onHide={onHide}
            dialogClassName={`${styles.modalDialog}`}
            contentClassName={`${styles.modalContent}`}
            centered={true}
          >
            <SaveAsModal
              showViewNameError={showViewNameError}
              viewName={viewName}
              onSubmitHandler={onSubmitHandler}
              onChange={viewNameOnChange}
              addViewLoading={addViewLoading}
              onHide={onHide}
            />
          </Modal>
        </div>
      ) : null}
    </>
  );
};

export default ViewsFilter;
