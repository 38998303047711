import styles from "./BaseSuccessModalBody.module.scss";

function BaseSuccessModalBody(props: any) {
  return (
    <div className={`text-center ${styles.successWrapper}`}>
      <span className={`${styles.addTagSuccessIcon}`}>
        <i className="fa-solid fa-circle-check"></i>
      </span>
      <p className={`${styles.addTagSuccess}`}>
        {/* {totalTicketsSelected} ticket(s) assigned to {selectedUser.name} ! */}
        {props.children}
      </p>
    </div>
  );
}

export default BaseSuccessModalBody;
