import { useEffect, useState } from "react";
import { useSwiper } from "swiper/react";
import styles from "./Step.module.scss";

function Step1(props: { setBackBtn: Function }) {
  const swiper = useSwiper();
  const [activeIndex, setActiveIndex] = useState(0);

  swiper.on("slideChange", (swiper) => {
    setActiveIndex(swiper.activeIndex);
  });

  useEffect(() => {
    if (activeIndex == 0) {
      props.setBackBtn(true);
    } else {
      props.setBackBtn(false);
    }
  }, [activeIndex]);

  return (
    <div className={``}>
      <span
        className={` ${styles.stepsContent} text-center  d-flex justify-content-center  align-items-center  `}
      >
        We will now begin configuring each step of the process.
        <br /> Press "Next Step" when ready.
      </span>
    </div>
  );
}

export default Step1;
