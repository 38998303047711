import { useCallback, useMemo, useRef, useState } from "react";
import styles from "./AssignmentFilter.module.scss";
import { Dropdown } from "react-bootstrap";
import {
  useReportFilters,
  reportCurrentStatusActions,
} from "src/routes/Report/hooks/reportFilters/useReportFilters";
import { v4 as uuid } from "uuid";

const useAssignmentFilter = () => {
  // Get the filters dispatch and state
  const { currentStatus, dispatch } = useReportFilters();

  // All assignment types
  const assignmentTypes = useMemo(() => {
    return [
      { id: "assigned", name: "Assigned" },
      { id: "unassigned", name: "Unassigned" },
    ];
  }, []);

  // Changes the selected assignment on state change
  const selectedAssignment = useMemo(() => {
    return currentStatus.isSelectedAssigned;
  }, [currentStatus]);

  // Clears all assignment filters applied
  const clearAllAssignmentFilters = useCallback(() => {
    dispatch([
      reportCurrentStatusActions.setFilters,
      { isSelectedAssigned: null },
    ]);
  }, []);

  // Check or uncheck the filter and fire a dispatch action to update state.
  const checkUncheckAssignment = useCallback(
    (e: any) => {
      dispatch([
        reportCurrentStatusActions.setFilters,
        {
          isSelectedAssigned:
            e.target.value === "assigned"
              ? true
              : e.target.value === "unassigned"
              ? false
              : null,
        },
      ]);
    },
    [selectedAssignment]
  );

  return {
    selectedAssignment,
    assignmentTypes,
    clearAllAssignmentFilters,
    checkUncheckAssignment,
  };
};

interface Props {
  isSegment?: boolean;
  disabled?: boolean;
}
const AssignmentFilter = ({ isSegment, disabled }: Props) => {
  const {
    selectedAssignment,
    assignmentTypes,
    clearAllAssignmentFilters,
    checkUncheckAssignment,
  } = useAssignmentFilter();
  // State for dropdown open status
  const [ddOpen, setDdOpen] = useState(false);
  // Used to manage the state of arrow as dropdown opens
  const dropdownArrowSpanRef = useRef<any>();
  // Unique id for filter
  const uuidRef = useRef(uuid());

  return (
    <div className="me-2 mb-2 mb-lg-0">
      <Dropdown onToggle={setDdOpen}>
        <Dropdown.Toggle
          as="div"
          id="dropdown-chat-status-filter"
          bsPrefix={`dropdown-toggle cursor-pointer border-0 px-2 py-1 ${
            isSegment === true ? styles.segementBtn : styles.filterButton
          }  ${disabled && styles.disable}`}
        >
          Assignment
          {selectedAssignment !== null ? (
            <span className={`mx-1 ${styles.filterCount}`}>{"01"}</span>
          ) : (
            ""
          )}
          <span className="ps-1" ref={dropdownArrowSpanRef}>
            {ddOpen ? (
              <i className={`fa-solid fa-caret-up`}></i>
            ) : (
              <i className={`fa-solid fa-caret-down`}></i>
            )}
          </span>
        </Dropdown.Toggle>

        <Dropdown.Menu bsPrefix={`dropdown-menu border-0 ${styles.filterMenu}`}>
          <span key={assignmentTypes[0].id} className={`${styles.dropItem}`}>
            <div className={`${styles.itemWrapper}`}>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  value={assignmentTypes[0].id}
                  name="assignment-filter-radio-buttons"
                  id={
                    uuidRef.current +
                    "reports-filter-chat-status-type" +
                    assignmentTypes[0].id
                  }
                  checked={selectedAssignment !== null && selectedAssignment}
                  onChange={checkUncheckAssignment}
                />
                <label
                  className={`form-check-label ${styles.checktext}`}
                  htmlFor={
                    uuidRef.current +
                    "reports-filter-chat-status-type" +
                    assignmentTypes[0].id
                  }
                >
                  {assignmentTypes[0].name}
                </label>
              </div>
            </div>
          </span>
          <span key={assignmentTypes[1].id} className={`${styles.dropItem}`}>
            <div className={`${styles.itemWrapper}`}>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  value={assignmentTypes[1].id}
                  name="assignment-filter-radio-buttons"
                  id={
                    uuidRef.current +
                    "reports-filter-chat-status-type" +
                    assignmentTypes[1].id
                  }
                  checked={selectedAssignment !== null && !selectedAssignment}
                  onChange={checkUncheckAssignment}
                />
                <label
                  className={`form-check-label ${styles.checktext}`}
                  htmlFor={
                    uuidRef.current +
                    "reports-filter-chat-status-type" +
                    assignmentTypes[1].id
                  }
                >
                  {assignmentTypes[1].name}
                </label>
              </div>
            </div>
          </span>

          <span
            className={`${styles.clearSpan}`}
            onClick={clearAllAssignmentFilters}
          >
            Clear all
          </span>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default AssignmentFilter;
