import { useCallback, useMemo, useRef } from "react";
import {
  InfiniteData,
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import {
  FacebookIntegration,
  IGetAllFacebookIntegration,
  getAllFacebookIntegrations,
} from "src/services/Integrations/getAllFacebookIntegrations";
import {
  deleteFacebookIntegration,
  subscribeFacebookPage,
  unsubscribeFacebookPage,
} from "src/services/Integrations/FacebookIntegration";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { refetchSideBarData } from "src/store/slices/ticketSidebar/ticketSidebar.slice";
import { useAppDispatch } from "src/store/store";

// Define filters for pagination
const filters = {
  start: 0,
  limit: 25,
};

export const useGetAllFacebookIntegrations = () => {
  // Use the useInfiniteQuery hook to fetch data with infinite scrolling
  const {
    status,
    error,
    data,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isError,
    fetchStatus,
    dataUpdatedAt,
  } = useInfiniteQuery({
    queryKey: ["facebook/getAll"],
    queryFn: ({ pageParam = filters }) => getAllFacebookIntegrations(pageParam),
    getNextPageParam: (
      prevPage: IGetAllFacebookIntegration,
      allPages: IGetAllFacebookIntegration[],
    ) => {
      // Calculate the next page parameters
      const data = allPages.flatMap((page) => page.integrationIds);
      if (data.length < prevPage.metaData.totalCount) {
        return {
          ...filters,
          start: data.length,
        };
      }
      return null;
    },
  });

  // Memoize the integrations and total count to avoid unnecessary recalculations
  const { integrations, totalCount, metaData } = useMemo(() => {
    let integrationData: IGetAllFacebookIntegration["integrations"] = {};
    data?.pages.forEach((page) => {
      integrationData = { ...integrationData, ...page.integrations };
    });

    const totalPages = data?.pages || [];
    const lastPage: undefined | IGetAllFacebookIntegration =
      totalPages[totalPages.length - 1];
    const total = lastPage?.metaData?.totalCount ?? 0;
    const metaData = lastPage?.metaData;

    return {
      integrations: integrationData,
      totalCount: total,
      metaData: metaData as undefined | IGetAllFacebookIntegration["metaData"],
    };
  }, [data, dataUpdatedAt]);

  // Memoize the integration IDs to avoid unnecessary recalculations
  const integrationIds = useMemo(() => {
    const ids = data?.pages.flatMap((page) => page.integrationIds) ?? [];
    return Array.from(new Set([...ids]));
  }, [data, dataUpdatedAt]);

  // Determine if there are more pages to load
  const hasMore = integrationIds.length < totalCount;

  return {
    handleInfiniteScroll: fetchNextPage,
    hasNextPage,
    status,
    integrations,
    integrationIds,
    isLoading,
    hasMore,
    metaData,
  };
};

export const useDeleteFacebookIntegration = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  return useMutation(
    // Mutation function to delete a Facebook integration
    (integrationId: string | number) =>
      deleteFacebookIntegration({ integrationId }),
    {
      onSuccess: (_, id) => {
        // Dispatch an action to refetch sidebar data
        dispatch(refetchSideBarData());

        // Update the query data for "facebook/getAll" to reflect the deletion
        queryClient.setQueryData<InfiniteData<IGetAllFacebookIntegration>>(
          ["facebook/getAll"],
          (oldData) => {
            if (!oldData) return oldData;

            // Create a new data object to avoid direct mutation
            const newData = { ...oldData };
            const newPages = newData.pages.map(
              (page: IGetAllFacebookIntegration) => {
                if (page.integrationIds.includes(id)) {
                  // Remove the deleted integration from the integrations object
                  const { [id]: _, ...remainingIntegrations } =
                    page.integrations;

                  // Decrement the total count of integrations
                  page.metaData.totalCount -= 1;
                  if (page.metaData.totalCount < 0) {
                    page.metaData.totalCount = 0;
                  }

                  return {
                    ...page,
                    integrationIds: page.integrationIds.filter(
                      (integrationId) => integrationId + "" !== id + "",
                    ),
                    integrations: remainingIntegrations,
                  };
                }

                // Decrement the total count of integrations
                page.metaData.totalCount -= 1;
                if (page.metaData.totalCount < 0) {
                  page.metaData.totalCount = 0;
                }

                return page;
              },
            );

            return { ...newData, pages: newPages };
          },
          {
            updatedAt: Date.now(), // Update the timestamp to trigger re-rendering
          },
        );
      },
    },
  );
};

export const useUpdateFacebookIntegrationPage = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({
      integrationId,
      integrated,
      id,
    }: {
      integrationId: number | string;
      integrated: boolean;
      id: string | number;
    }) => {
      // Toggle integration status based on the current state
      if (integrated) {
        return await unsubscribeFacebookPage({ page_id: id });
      } else {
        return await subscribeFacebookPage({ page_ids: [id] });
      }
    },
    {
      onSuccess: (data, variables) => {
        const { integrated, id, integrationId } = variables;

        // Update the query data to reflect the new integration status
        queryClient.setQueryData<InfiniteData<IGetAllFacebookIntegration>>(
          ["facebook/getAll"],
          (oldData) => {
            if (!oldData) return oldData;
            const newData = { ...oldData };

            // Update the specific page's integration status
            const newPages = newData.pages.map(
              (page: IGetAllFacebookIntegration) => {
                if (page.integrationIds.includes(integrationId)) {
                  return {
                    ...page,
                    integrations: {
                      ...page.integrations,
                      [integrationId]: {
                        ...page.integrations[integrationId],
                        pages: page.integrations[integrationId].pages.map(
                          (p) => {
                            if (p.id === id) {
                              p.integrated = !integrated;
                            }
                            return p;
                          },
                        ),
                      },
                    },
                  };
                }
                return page;
              },
            );

            return { ...newData, pages: newPages };
          },
          {
            updatedAt: Date.now(), // Trigger re-rendering by updating the timestamp
          },
        );
      },
      onError: (error: any) => {
        // Log the error and show a toast notification
        console.log(error);
        pushTheToast({
          type: "danger",
          text: error.message,
          position: "top-right",
        });
      },
    },
  );
};
