/**
 * This file contains the custom hook for handling KB settings.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import useThemeBox from "../../../ArticleSideBar/ThemeSelection/Children/ThemeBox/useThemeBox";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";

/**
 * Custom hook for managing the CodeSetting component in KB settings.
 */
function useCodeSetting() {
  // States and variables
  const [dropdown, setDropDown] = useState(false);

  // Variable for code and body
  let code = `<head>`;
  let body = `<body>`;

  const { integrationId } = useParams();
  const pageIntegrationId = integrationId
    ? decodeURIComponent(integrationId)
    : "";

  const { data, updateKBSettings } = useThemeBox();

  const [head, setHead] = useState(data.customCodeHead);

  const [bodyCode, setBodyCode] = useState(data.customCodeBody);

  // Timeout Ref for performing the debounce
  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    return () => {
      if (timeoutIdRef.current !== null) {
        clearTimeout(timeoutIdRef.current);
      }
    };
  }, []);

  // Text status for showing the status of the update
  const [textStatus, setTextStatus] = useState({
    head: "",
    body: "",
  });

  function convertToAscii(input: string) {
    let asciiArray = [];
    for (let i = 0; i < input.length; i++) {
      asciiArray.push(input.charCodeAt(i));
    }
    return asciiArray;
  }

  // Handle the change of the head
  const handleHeadChange = (e: { target: { value: string } }) => {
    setHead(e.target.value);
    // Clear the timeout if it is not null
    if (timeoutIdRef.current !== null) {
      clearTimeout(timeoutIdRef.current);
    }

    // Set the timeout
    timeoutIdRef.current = setTimeout(() => {
      setTextStatus((prev) => ({ ...prev, head: "Updating..." }));
      // Update the KB settings
      updateKBSettings.mutate(
        {
          integrationId: pageIntegrationId,
          themeId: data.themeId,
          customCodeHead: convertToAscii(e.target.value),
        },
        {
          onSuccess: () => {
            // Set the text status to updated
            setTextStatus((prev) => ({ ...prev, head: "Updated" }));
            // Set the text status to empty after 700ms
            setTimeout((prev) => {
              setTextStatus({
                ...prev,
                head: "",
              });
            }, 700);
          },
          onError: () => {
            pushTheToast({
              position: "top-right",
              text: "Failed to update",
              type: "danger",
            });
            setTextStatus((prev) => ({ ...prev, head: "Failed" }));
          },
        }
      );
      // Set the timeout to 500ms
    }, 500);
  };

  // Handle the change of the body
  const handleBodyChange = (e: { target: { value: string } }) => {
    //  Set the body code
    setBodyCode(e.target.value);
    // Clear the timeout if it is not null
    if (timeoutIdRef.current !== null) {
      clearTimeout(timeoutIdRef.current);
    }
    // Set the timeout to 500ms
    timeoutIdRef.current = setTimeout(() => {
      //  Set the text status to updating
      setTextStatus((prev) => ({ ...prev, body: "Updating..." }));
      // Update the KB settings
      updateKBSettings.mutate(
        {
          integrationId: pageIntegrationId,
          themeId: data.themeId,
          customCodeBody: convertToAscii(e.target.value),
        },
        {
          // Set the text status to updated
          onSuccess: () => {
            setTextStatus((prev) => ({ ...prev, body: "Updated" }));
            // Set the text status to empty after 700ms
            setTimeout((prev) => {
              setTextStatus({
                ...prev,
                body: "",
              });
            }, 700);
          },
          onError: () => {
            pushTheToast({
              position: "top-right",
              text: "Failed to update",
              type: "danger",
            });
            setTextStatus((prev) => ({ ...prev, body: "Failed" }));
          },
        }
      );
    }, 500);
  };

  return {
    dropdown,
    setDropDown,
    head,
    setHead,
    bodyCode,
    setBodyCode,
    code,
    body,
    handleHeadChange,
    handleBodyChange,
    textStatus,
  };
}

export default useCodeSetting;
