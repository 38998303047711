import { useAppSelector } from "src/store/store";
import { totalTicketsSelectedSeletector, updatedTicketsCountSeletector } from "src/store/slices/tickets/tickets.slice";
import BaseSuccessModalBody from "src/components/BulkActionComponents/commons/BaseSuccessModalBody/BaseSuccessModalBody";

const AddBulkStatusSuccess = () => {
  const totalTicketsSelected = useAppSelector(totalTicketsSelectedSeletector);
  const updatedTicketsCount = useAppSelector(updatedTicketsCountSeletector);
  const { selectedStatusId, allTicketStatuses } = useAppSelector(
    (state) => state.ticketStatus
  );

  return (
    <BaseSuccessModalBody>
      {updatedTicketsCount} ticket(s) marked as{" "}
      {allTicketStatuses[selectedStatusId].statusName}
    </BaseSuccessModalBody>
  );
};

export default AddBulkStatusSuccess;
