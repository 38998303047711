import { axiosJSON } from "src/globals/axiosEndPoints";

export interface ResendVerificationCode {
  data: [];
}

export interface ResendVerificationCodeParams {
  emailIntegrationId: number;
}

export async function resendVerificationCode(
  params: ResendVerificationCodeParams
) {
  const { data } = await axiosJSON.post(
    `/api/setting/integration/resendVerifyEmailForwardingMail`,
    params
  );

  // const data = await resendVerification(params);

  // if (res.errors) {
  //   throw new Error(res.errors.join(""));
  // }

  // if (res.error === true) {
  //   throw new Error(res.message as string);
  // }

  // return res.data as ResendVerificationCode;
  if (data.err === true || data.error === true) {
    throw new Error(data.msg as string);
  }
  return data as ResendVerificationCode;
}

// let resendVerification = function (params: ResendVerificationCodeParams) {
//   return new Promise(function (resolve, reject) {
//     if (params) {
//       setTimeout(function () {
//         resolve({
//           isEmailVerified: true,
//         });
//       }, 2000);
//     } else {
//       reject(new Error("Something is not right!"));
//     }
//   });
// };
