import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";
import { TemplateStep as TemplateStepRes } from "./getAllTemplates";

export interface TemplateStep {
  scheduleFollowUpType: "days" | "weeks" | "months" | "dateAndTime";
  scheduleFollowUpValue: number | string;
  sendMessageCondition:
    | "noNewOrderPlacedAfterScheduling"
    | "noNewResponseOrMessageReceivedFromTheCustomer"
    | "none";
  templateHeading?: string;
  templateMessage: string;
  attachmentBatchNumber?: string[];
  stepId?: string | number;
}

export interface CreateTemplateParams {
  templateSteps?: Array<TemplateStep>;
  scheduleMessage?: boolean; //should be true when scheduling message
  chatId?: number | string;
  ticketId?: number | string;
  status?: "open" | "closed" | "pending";
  templateType: "live_chat_template" | "inner_ticket_template";
  scheduleWith?: string | null;
}

interface CreateTemplateResponse {
  data: {
    templateId: number | string;
    templateSteps: TemplateStepRes[];
  };
  err?: boolean;
  metaData?: {
    scheduledMessagesCount: number;
  };
}

const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/template/create`
  : "/api/liveChat/agent/message/template/create";

export const createTemplateLiveChat = async (params: CreateTemplateParams) => {
  try {
    const { data } = await axiosJSON.post(apiEndPoint, params);
    return data as CreateTemplateResponse;
  } catch (err) {
    return {
      err: true,
      data: {},
    } as any;
  }
};
