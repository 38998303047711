import { useState, useEffect, useCallback, useRef } from "react";

import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import {
  addFeaturedArticle,
  fetchAllFeaturedArticles,
  resetFeaturedArticlesData,
  updateFeaturedArticle,
} from "src/store/slices/liveChatSetting/chatWidgetSetting/knowledgeBased/knowledgeBasedSetting.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";

interface Props {
  integrationId?: number | string;
  fetchLimit?: number;
}
/**
 * custom hook to fetch featured articles and return featured article data
 */
function useFeaturedArticles({ integrationId, fetchLimit = 15 }: Props) {
  const [submitLoading, setSubmitLoading] = useState(false);
  const { activeFeaturedArticle,updateFeaturedArticlesAjaxStatus,addFeaturedArticlesAjaxStatus } = useAppSelector(
    (state) => state.knowledgeBasedSettings
  );

  const dispatch = useAppDispatch();

  const handleFeaturedArticleSubmit = useCallback((successCallback: ()=>void) => {
    if (integrationId) {
      //validating payload
      if (
        activeFeaturedArticle.type === "custom" &&
        activeFeaturedArticle.domainUrl.trim().length === 0
      ) {
        pushTheToast({
          type: "warning",
          text: "Domain Url cannot be empty!",
          position: "top-right",
        });
        return;
      }
      if (Object.values(activeFeaturedArticle.featuredArticles).length === 0) {
        pushTheToast({
          type: "warning",
          text: "Atleast one article should be selected!",
          position: "top-right",
        });
        return;
      }

      setSubmitLoading(true);
      if (activeFeaturedArticle.id) {
        dispatch(
          updateFeaturedArticle({
            integrationId: integrationId,
            callback: ()=>{
            setSubmitLoading(false);
            successCallback()
            }
          })
        );
      } else {
        dispatch(
          addFeaturedArticle({
            integrationId: integrationId,
            callback: ()=>{
              setSubmitLoading(false);
              successCallback()
            }
          })
        );
      }
    }
  }, [integrationId, activeFeaturedArticle]);


  useEffect(() => {

    if (updateFeaturedArticlesAjaxStatus !== "pending") {
      setSubmitLoading(false);
    }

  }, [updateFeaturedArticlesAjaxStatus]);

  useEffect(() => {

    if(addFeaturedArticlesAjaxStatus !== "pending"){

      setSubmitLoading(false);
    }

  }, [addFeaturedArticlesAjaxStatus]);
  
  
 
  return {
    handleFeaturedArticleSubmit,
    submitLoading
  };
}

export default useFeaturedArticles;
