import {
  DummyOrder,
  IAddedItem,
  IOrder,
  StepName,
  useReturnPreviewState,
} from "./useReturnPreview";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { EReturnAutomationQuestionKeys } from "src/enums/EReturnAutomationQuestionKeys";
import {
  IResolutionOffersValue,
  IRestockingFee,
  IReturnWindowValue,
} from "src/store/slices/initiateReturn/retrunExchange.slice";
import { ConditionTypes } from "src/store/slices/initiateReturn/Helpers/returnExchange.helpers";
import ScenarioReducer, {
  RefundScenarioState,
  ScenarioStateContext,
} from "../../AddAutomationStep4/Children/AntiRefundModal/Children/RefundBox/Scenario/ScenarioReducer";
import { useContext, useMemo } from "react";
import { SelectOptionType } from "src/components/AutomationComponents/ContentSection/Values";
import { fetchGetAllShopifyProducts } from "src/store/slices/initiateReturn/returnExchange.thunk";
import { IAntiRefundOrderType } from "src/store/slices/initiateReturn/antiRefundOrderTypesConfig.slice";
import { ReasonQuestion } from "src/store/slices/initiateReturn/step12Config.slice";
import { v4 as uuidv4 } from "uuid";

export interface Condition {
  id: string;
  conditionType: string;
  variableName: string;
  values: string | string[];
  operator: string;
}

type ReturnType =
  | "exchangeWithSameItems"
  | "exchangeWithDifferentItems"
  | "refundToStoreCredit"
  | "refundOriginalPayment";

export interface IReturnType {
  id: string;
  name: string;
  type: ReturnType;
  hidden: boolean;
  message?: string;
}
export interface IARSReturnType {
  id: string;
  name: string;
  type:
    | "exchangeWithSameItems"
    | "exchangeWithDifferentItems"
    | "refundToStoreCredit"
    | "refundOriginalPayment"
    | "proceedToReturn";
  hidden: boolean;
  message?: string;
}
// Type definition for ARSInformationValue
export interface ARSInformationValue {
  itemId: string;
  description: string;
}

// Type definition for ARSOffer
export type ARSOffer = {
  offerId: string;
  sideTitle?: string;
  sideSubTitle?: string;
  backgroundColor?: string;
  text?: string;
};

// Type definition for ARSOffersValue
export type ARSOffersValue = {
  itemId: string;
  message: string;
  offers: ARSOffer[];
};

// Type definition for ARSReturnTypes
export type ARSReturnTypes = {
  itemId: string;
  message: string;
  types: IARSReturnType[];
};
export interface ARSResponse {
  information: ARSInformationValue[];
  offers: ARSOffersValue[];
  returnTypes: ARSReturnTypes[];
  isInformationAvailable: boolean;
  isOffersAvailable: boolean;
  isExchangeAvailable: boolean;
}
export interface IReturnReason {
  id: string;
  value: string;
  moreQuestions?: ReasonQuestion[];
}

const returnTypes: IReturnType[] = [
  {
    id: "1",
    name: "Exchange with Same Items",
    type: "exchangeWithSameItems",
    hidden: false,
  },
  {
    id: "2",
    name: "Exchange with Different Items",
    type: "exchangeWithDifferentItems",
    hidden: false,
  },
  {
    id: "3",
    name: "Refund to Store Credit",
    type: "refundToStoreCredit",
    hidden: false,
  },
  {
    id: "4",
    name: "Refund to Original Payment Method",
    type: "refundOriginalPayment",
    hidden: false,
  },
];
// const dummyMoreDetails: ReasonQuestion[] = [
//   {
//     elementTypeId: "87",
//     elementId: "6a78ea2d-9c53-47bb-ba39-8662dde39f5c",
//     additionalOptions: [],
//     elementValue: "Order packaging is not opened when you got it",
//   },
//   {
//     elementTypeId: "74",
//     elementId: "133c053f-3817-4f1f-a024-b53eefee5a52",
//     additionalOptions: [],
//     elementValue: "Please describe your issue in more detail",
//   },
//   {
//     elementTypeId: "77",
//     elementId: "33310ec6-2fd9-4979-abf2-24f466aa995f",
//     additionalOptions: [
//       {
//         id: "option177",
//         value: "Pink",
//       },
//       {
//         id: "option277",
//         value: "Orange",
//       },
//     ],
//     elementValue: "Select the colour you got",
//   },
//   {
//     elementTypeId: "76",
//     elementId: "4e81a4fa-f7d8-40ef-b5d8-3a3d3e97f6b4",
//     additionalOptions: [
//       {
//         id: "option176",
//         value: "Color is pale",
//       },
//       {
//         id: "option276",
//         value: "It is ripped",
//       },
//     ],
//     elementValue: "Select the damage type",
//   },
// ];

// Custom Hook
const useGetAutomationData = () => {
  const { orders, selectedOrder, setShowAntiRefundPreview, updateState } =
    useReturnPreviewState();
  const dispatch = useAppDispatch();
  const step9Questions = useAppSelector(
    (state) => state.returnExchange.step9.stepData.stepQuestions,
  );
  const step11Questions = useAppSelector(
    (state) => state.returnExchange.step11.stepData.stepQuestions,
  );
  const step12Questions = useAppSelector(
    (state) => state.returnExchange.step12.stepData.stepQuestions,
  );
  const step14Questions = useAppSelector(
    (state) => state.returnExchange.step14.stepData.stepQuestions,
  );

  const nonEligbleOrderModalData = useAppSelector(
    (state) => state.step9Configuration.step9Configuration,
  );
  const nonEligbleItemsModalData = useAppSelector(
    (state) => state.step10Configuration,
  );
  const returnTypesModalData = useAppSelector((state) => state.step11Config);
  const returnReasonsModalData = useAppSelector((state) => state.step12Config);
  const { activeModal, activeSubModal } = useAppSelector(
    (state) => state.returnExchange,
  );
  const countryList = useAppSelector((state) => state.globals.countryList);

  const allShopifyProducts = useAppSelector(
    (state) => state.returnExchange.getAllShopifyProducts,
  );
  const step15Questions = useAppSelector(
    (state) => state.returnExchange.step15.stepData.stepQuestions,
  );
  const arsResponse = useAppSelector(
    (state) => state.antiRefundOrderTypesConfig.arsResponse,
  );
  const antiRefundStrategiesModalData = useAppSelector(
    (state) => state.antiRefundOrderTypesConfig.antiRefundOrderTypesData,
  );

  const antiRefundSelectedOrderId = useAppSelector(
    (state) =>
      state.antiRefundOrderTypesConfig.antiRefundOrderTypesData
        .selectedOrderTypeId,
  );

  const antiRefundStrategiesData = useAppSelector(
    (state) => state.antiRefundConfig.refundRequests,
  );
  const returnMethodModalData = useAppSelector((state) => state.step14Config);
  const orderSummaryModalData = useAppSelector(
    (state) => state.step15Configuration,
  );

  const getReturnWindowData = () => {
    if (activeModal && activeModal === "nonEligibleOrder") {
      const selectedOrderType = nonEligbleOrderModalData.orderTypes.find(
        (orderType) =>
          orderType.orderTypeId == nonEligbleOrderModalData.selectedOrderTypeId,
      );
      return {
        returnWindow: selectedOrderType?.returnWindow?.values.days.currentValue,
        day_type: selectedOrderType?.returnWindow?.values.day_type.currentValue,
        message: selectedOrderType?.messageForCustomer?.value,
      };
    } else {
      return {
        returnWindow:
          (
            step9Questions[EReturnAutomationQuestionKeys.returnWindow]
              .value as IReturnWindowValue
          ).days ?? 0,
        day_type:
          (
            step9Questions[EReturnAutomationQuestionKeys.returnWindow]
              .value as IReturnWindowValue
          ).day_type ?? "bussiness_days",
        message:
          (step9Questions[
            EReturnAutomationQuestionKeys.returnNotEligibleResponseMessage
          ].value as string) ?? "",
      };
    }
  };

  const getAllReturnTypes = () => {
    const allowedReturnTypes: IReturnType[] = [];
    let message = "";

    const setType = (
      typeKey: string,
      condition: boolean | undefined,
      msg: string,
    ) => {
      let type = returnTypes.find((rt) => rt.type === typeKey);
      if (type) {
        let newType = { ...type }; // Create a new object by spreading the properties of the found type object
        if (condition !== undefined) {
          newType.hidden = !condition; // Set hidden based on the condition
          if (!condition) {
            message = msg; // Set message if the condition is false
          }
        }
        allowedReturnTypes.push(newType); // Add the new object to allowedReturnTypes regardless of the condition
      }
    };

    if (activeModal === "returnTypes") {
      const selectedTab = returnTypesModalData.returnTypes.find(
        (returnType) =>
          returnType.returnTypeId === returnTypesModalData.selectedReturnTypeId,
      );

      if (selectedTab) {
        setType(
          "exchangeWithSameItems",
          selectedTab.ExchangeWithSameItem,
          selectedTab.messageForCustomer.value ?? "",
        );
        setType(
          "exchangeWithDifferentItems",
          selectedTab.ExchangeWithOtherItem,
          selectedTab.messageForCustomer.value ?? "",
        );
        setType(
          "refundOriginalPayment",
          selectedTab.RefundToOriginalPayment,
          selectedTab.messageForCustomer.value ?? "",
        );
        setType(
          "refundToStoreCredit",
          selectedTab.RefundtoStoreCredit,
          selectedTab.messageForCustomer.value ?? "",
        );
      }
    } else {
      const resolutionOffer = step11Questions[
        EReturnAutomationQuestionKeys.resolutionOffer
      ].value as IResolutionOffersValue;
      setType(
        "exchangeWithSameItems",
        resolutionOffer.exchangeWithSameItems ?? false,
        resolutionOffer?.resolutionOfferMessage?.value ?? "",
      );
      setType(
        "exchangeWithDifferentItems",
        resolutionOffer.exchangeWithOtherItems ?? false,
        resolutionOffer?.resolutionOfferMessage?.value ?? "",
      );
      setType(
        "refundToStoreCredit",
        resolutionOffer.refundToStoreCredits ?? false,
        resolutionOffer?.resolutionOfferMessage?.value ?? "",
      );
      setType(
        "refundOriginalPayment",
        resolutionOffer.refundToOrigialPaymentMethod ?? false,
        resolutionOffer?.resolutionOfferMessage?.value ?? "",
      );
    }

    // Include all return types and disable unchecked ones if not already included
    returnTypes.forEach((rt) => {
      if (!allowedReturnTypes.some((allowedRt) => allowedRt.type === rt.type)) {
        rt.hidden = true; // Disable unchecked ones
        allowedReturnTypes.push(rt);
      }
    });

    return { allReturnTypes: allowedReturnTypes, message: message };
  };

  const getAllReasons = () => {
    if (activeModal === "returnReasons") {
      const selectedTab = returnReasonsModalData.reasonTypes.find(
        (reasonType) =>
          reasonType.reasonTypeId ===
          returnReasonsModalData.selectedReasonTypeId,
      );
      if (selectedTab) {
        return (selectedTab.reasons ?? []).map(
          (reason) =>
            ({
              id: reason.id,
              value: reason.reason.value,
              moreQuestions: reason.reason.moreQuestions,
            }) as IReturnReason,
        );
      }
    } else if (activeModal == "antiRefundMultipleTabs") {
      return (antiRefundStrategiesModalData.refundRequestReasons ?? []).map(
        (reason) =>
          ({
            id: reason.id.toString(),
            value: reason.reasonName,
            moreQuestions: [],
          }) as IReturnReason,
      );
    } else if (activeModal == "antiRefund") {
      return (antiRefundStrategiesData.reasons ?? []).map(
        (reason) =>
          ({
            id: reason.id.toString(),
            value: reason.reasonName,
            moreQuestions: [],
          }) as IReturnReason,
      );
    } else if (activeModal == "returnMethod") {
      return (returnMethodModalData.returnReasonList ?? []).map(
        (reason) =>
          ({
            id: reason.id.toString(),
            value: reason.value,
            moreQuestions: reason.moreQuestions,
          }) as IReturnReason,
      );
    } else if (activeModal == "orderSummary") {
      return (orderSummaryModalData.returnReasonList ?? []).map(
        (reason) =>
          ({
            id: reason.id.toString(),
            value: reason.value,
            moreQuestions: reason.moreQuestions,
          }) as IReturnReason,
      );
    } else {
      return (
        (step12Questions[EReturnAutomationQuestionKeys.returnReasonList]
          ?.value as IReturnReason[]) ?? []
      );
    }
  };
  const getReturnMethodData = () => {
    if (activeModal === "returnMethod") {
      const selectedTab = returnMethodModalData.returnMethods.find(
        (returnMethod) =>
          returnMethod.returnMethodId ===
          returnMethodModalData.selectedReturnMethodId,
      );
      return {
        returnMethod: selectedTab?.returnMethodType.value ?? "notRequired",
        returnMethodMessage: selectedTab?.returnMethodType.message ?? "",
      };
    } else {
      return {
        returnMethod:
          step14Questions[
            EReturnAutomationQuestionKeys.returnMethodForMajorityOfOrders
          ].value,
        returnMethodMessage:
          step14Questions[
            EReturnAutomationQuestionKeys.returnMethodDisplayMessage
          ].value,
      };
    }
  };
  const getMoreDetailsByReasonId = (reasonId: string) => {
    if (
      activeSubModal &&
      activeSubModal.name == "moreQuestions" &&
      reasonId == activeSubModal.reasonId
    ) {
      return (returnReasonsModalData.moreQuestionsModal ?? []).map(
        (item: any) => {
          // Check if additionalOptions exists and is an array
          const updatedItem = { ...item }; // Create a new object
          if (Array.isArray(updatedItem.additionalOptions)) {
            // Add a unique id to each additional option
            updatedItem.additionalOptions = updatedItem.additionalOptions.map(
              (option: any, index: number) => ({
                ...option,
                id: index + 1,
              }),
            );
          }
          return updatedItem;
        },
      );
    } else {
      const allReasons = getAllReasons();
      const reason = allReasons?.find((reason) => reason.id === reasonId);
      const updatedReason = { ...reason }; // Create a new object
      if (Array.isArray(updatedReason?.moreQuestions)) {
        updatedReason.moreQuestions = updatedReason.moreQuestions.map(
          (item: any) => {
            const updatedItem = { ...item }; // Create a new object
            if (Array.isArray(updatedItem.additionalOptions)) {
              // Add a unique id to each additional option only if it doesn't have one
              updatedItem.additionalOptions = updatedItem.additionalOptions.map(
                (option: any, index: number) => ({
                  ...option,
                  id: option.id ?? index + 1,
                }),
              );
            }
            return updatedItem;
          },
        );
      }

      return updatedReason?.moreQuestions ?? [];
    }
  };
  // Flag to check return method question is visible
  const isReturnMethodAvailable = useMemo(() => {
    return (
      step14Questions[
        EReturnAutomationQuestionKeys.returnMethodForMajorityOfOrders
      ].showQuestion ?? false
    );
  }, [step9Questions]);

  const getArsRespone = (localState: RefundScenarioState | null) => {
    // Dummy data for ARSInformationValue
    const arsInformationValues: ARSInformationValue[] = [
      {
        itemId: DummyOrder.itemsInOrder[0].id,
        description: localState?.additionalInformation?.message ?? "",
      },
    ];
    const isInformationAvailable =
      localState?.additionalInformation?.enabled ?? false;
    const isOffersAvailable = localState?.giveOffer?.enabled ?? false;
    const isExchangeAvailable = localState?.offerExchange?.enabled ?? false;
    const giveOffer = localState?.giveOffer;
    let arsOffers: ARSOffersValue[] | null = null;
    if (giveOffer) {
      if (giveOffer?.offerType) {
        switch (giveOffer.offerType) {
          case "partial_refund_of":
          case "discount_of": {
            // Dummy data for ARSOfferValue
            arsOffers = [
              {
                itemId: DummyOrder.itemsInOrder[0].id,
                message: localState?.giveOffer?.message ?? "",
                offers: [
                  {
                    offerId: "offer001",
                    sideTitle: `${giveOffer?.offerValue}${
                      giveOffer?.offerUnit === "percentage"
                        ? "%"
                        : giveOffer?.offerUnit === "fixed"
                          ? "$"
                          : "%"
                    }`,
                    sideSubTitle: "OFF",
                    backgroundColor: "#5F54C6",
                    text: `${giveOffer?.message ?? ""}`,
                  },
                ],
              },
            ];
            break;
          }
          case "offer_clearance_product_for_free": {
            // Dummy data for ARSOfferValue
            arsOffers = [
              {
                itemId: DummyOrder.itemsInOrder[0].id,
                message: localState?.giveOffer?.message ?? "",
                offers: [
                  {
                    offerId: "offer002",
                    sideTitle: `Clearance Sale!`,
                    sideSubTitle: "",
                    backgroundColor: "#54c682",
                    text: `${giveOffer?.message ?? ""}`,
                  },
                ],
              },
            ];
            break;
          }
          case "other": {
            // Dummy data for ARSOfferValue
            arsOffers = [
              {
                itemId: DummyOrder.itemsInOrder[0].id,
                message: localState?.giveOffer?.message ?? "",
                offers: [
                  {
                    offerId: "offer003",
                    sideTitle: `Offer`,
                    sideSubTitle: "",
                    backgroundColor: "#5F54C6",
                    text: `${giveOffer?.message ?? ""}`,
                  },
                ],
              },
            ];
            break;
          }
        }
      }
    }

    const allowedReturnTypes: IARSReturnType[] = [];
    const setType = (typeKey: string, condition: boolean | undefined) => {
      let type = returnTypes.find((rt) => rt.type === typeKey);
      if (type) {
        let newType = { ...type }; // Create a new object by spreading the properties of the found type object
        if (condition !== undefined) {
          newType.hidden = !condition; // Set hidden based on the condition
        }
        allowedReturnTypes.push(newType); // Add the new object to allowedReturnTypes regardless of the condition
      }
    };
    setType(
      "exchangeWithSameItems",
      localState?.offerExchange?.exchangeWithSameItems ?? false,
    );
    setType(
      "exchangeWithDifferentItems",
      localState?.offerExchange?.exchangeWithOtherItems ?? false,
    );
    allowedReturnTypes.push({
      id: "5",
      name: "Proceed with refund",
      type: "proceedToReturn",
      hidden: false,
    });

    // Dummy data for ARSReturnTypes
    const arsReturnTypes: ARSReturnTypes[] =
      allowedReturnTypes.length > 0
        ? [
            {
              itemId: DummyOrder.itemsInOrder[0].id,
              message: localState?.offerExchange?.message ?? "",
              types: allowedReturnTypes,
            },
          ]
        : [];

    // Dummy data for ARSResponse
    const arsResponse: ARSResponse = {
      information: arsInformationValues,
      offers: arsOffers ?? [],
      returnTypes: arsReturnTypes,
      isInformationAvailable: isInformationAvailable,
      isOffersAvailable: isOffersAvailable,
      isExchangeAvailable: isExchangeAvailable,
    };
    return arsResponse;
  };

  const isRestockingFeeCharged = () => {
    return step15Questions[
      EReturnAutomationQuestionKeys.isRestockingFeeChargedForMajorityOrders
    ].value as boolean;
  };
  const getRestockingFeeData = () => {
    let restockingFee = {
      price: "0",
      priceType: "percentage",
    };
    let restockMessage = "";
    if (activeModal && activeModal === "orderSummary") {
      const selectedTab = orderSummaryModalData.additionalCharges.find(
        (additionalCharge) =>
          additionalCharge.additionalChargeId ===
          orderSummaryModalData.selectedAdditionalChargeId,
      );
      if (selectedTab) {
        restockMessage = selectedTab.messageForCustomer.value ?? "";
        restockingFee = {
          price: selectedTab.restockingFees.amount.toString(),
          priceType: selectedTab.restockingFees.priceType,
        };
      }
    } else {
      restockingFee = step15Questions[
        EReturnAutomationQuestionKeys.usuallyChargedRestockingFee
      ].value as IRestockingFee;
      restockMessage = step15Questions[
        EReturnAutomationQuestionKeys.restockingFeeDisplayMessage
      ].value as string;
    }
    return {
      restockingFee,
      restockMessage,
    };
  };

  // Flag to check return window message question is visible
  const isReturnWindowMessageAvailable = useMemo(() => {
    return (
      step9Questions[
        EReturnAutomationQuestionKeys.returnNotEligibleResponseMessage
      ].showQuestion ?? false
    );
  }, [step9Questions]);

  // Flag to check return types question is visible
  const isReturnTypesAvailable = useMemo(() => {
    return (
      step11Questions[EReturnAutomationQuestionKeys.resolutionOffer]
        .showQuestion ?? false
    );
  }, [step9Questions]);

  // Flag to check return reasons question is visible
  const isReturnReasonsAvailable = useMemo(() => {
    if (activeModal && activeModal === "returnReasons") {
      const selectedTab = returnReasonsModalData.reasonTypes.find(
        (reasonTab) =>
          reasonTab.reasonTypeId == returnReasonsModalData.selectedReasonTypeId,
      );
      if (selectedTab) {
        return (
          (selectedTab.explainWhyTheyReturn &&
            selectedTab.explainWhyTheyReturn !== "notRequired") ??
          false
        );
      }
    } else {
      return (
        (step12Questions[EReturnAutomationQuestionKeys.returnReasonList]
          .showQuestion &&
          step12Questions[EReturnAutomationQuestionKeys.returnReasonList]
            .value !== "notRequired") ??
        false
      );
    }
    return false;
  }, [step12Questions, returnReasonsModalData]);

  const explainAnswer = useMemo(() => {
    if (activeModal && activeModal === "returnReasons") {
      const selectedTab = returnReasonsModalData.reasonTypes.find(
        (reasonTab) =>
          reasonTab.reasonTypeId == returnReasonsModalData.selectedReasonTypeId,
      );
      if (selectedTab) {
        return selectedTab.explainWhyTheyReturn ?? null;
      }
    }
    return null;
  }, [returnReasonsModalData]);

  // Define the interface for updateOrders function parameters
  interface IUpdateOrdersParams {
    orders: IOrder[];
    modalData: any[];
    orderTabIdProperty: string;
    modalType: string | null;
    returnExchangeApplicableOrders: boolean;
    returnExchangeApplicableItems: boolean;
    addReturnType: boolean;
    applyReturnWindow: boolean;
    applyReturnWindowOperator: string;
    conditionType: "reason" | "order" | "returnWindow" | "item";
    selectedOrderTypeId: string | null;
    activeStep: StepName;
    selectedAction: "return";
  }

  /**
   * Helper function to generate order based modal tabs
   * And add the conditions
   */
  const updateOrders = ({
    orders, // List of current orders
    modalData, // Data from the modal
    orderTabIdProperty, // Property to identify order tab ID
    modalType, // Type of the modal
    returnExchangeApplicableOrders, // Flag for return/exchange applicable orders
    returnExchangeApplicableItems, // Flag for return/exchange applicable items
    addReturnType, // Flag to add return type
    applyReturnWindow, // Flag to apply return window
    applyReturnWindowOperator, // Operator for applying return window
    conditionType, // Type of condition to apply
    selectedOrderTypeId, // ID of the selected order type
    activeStep, // Current active step
    selectedAction, // Selected action
  }: IUpdateOrdersParams) => {
    const updatedOrders = [...orders]; // Clone the current orders

    // Iterate over each order tab in modal data
    modalData.forEach((orderTab) => {
      const orderExists = updatedOrders.some(
        (order) => order.orderId === orderTab[orderTabIdProperty], // Check if order already exists
      );

      // If order does not exist, create a new order
      if (!orderExists) {
        const addedItems: Record<string, IAddedItem> = {};
        DummyOrder.itemsInOrder.forEach((item) => {
          addedItems[item.id] = createAddedItem({
            itemId: item.id,
            quantity: item.quantity,
            addReturnType: addReturnType,
            isReturnExchangeApplicable: returnExchangeApplicableItems,
          });
        });

        updatedOrders.push({
          ...DummyOrder,
          orderId: orderTab[orderTabIdProperty], // Assign new order ID
          orderName: `Sample Order ${updatedOrders.length + 1}`, // Create a sample order name
          modalType,
          returnExchangeApplicableOrders,
          addedItems,
          conditions: [],
        } as IOrder);
      }

      const updatedOrderIndex = updatedOrders.findIndex(
        (order) => order.orderId === orderTab[orderTabIdProperty], // Find index of the updated order
      );

      if (updatedOrderIndex !== -1) {
        const { updatedOrder } = applyConditionToOrder({
          order: updatedOrders[updatedOrderIndex], // Get the order to update
          conditions: orderTab.conditions,
          conditionType,
        });
        updatedOrders[updatedOrderIndex] = updatedOrder; // Update the order with new conditions

        // Apply return window if applicable
        if (orderTab.returnWindow || applyReturnWindow) {
          const { updatedReturnWindowOrder } = applyReturnWindowToOrder({
            order: updatedOrders[updatedOrderIndex],
            operator: applyReturnWindowOperator,
          });
          updatedOrders[updatedOrderIndex] = updatedReturnWindowOrder; // Update order with return window
        }
      }
    });

    // Find the updated selected order
    const updatedSelectedOrder = updatedOrders.find(
      (order) => order.orderId === selectedOrderTypeId,
    );

    // Update selected order with active step and selected action
    if (updatedSelectedOrder) {
      updatedSelectedOrder.activeStep = activeStep ?? "configureNextSteps";
      updatedSelectedOrder.prevStepForConfig = activeStep ?? "selectAction";
      updatedSelectedOrder.selectedAction = selectedAction;
    }

    return {
      updatedOrders, // Return the updated orders
      updatedSelectedOrder, // Return the updated selected order
    };
  };

  /**
   * Function to generate orders for orders not eligible modal
   */
  const getNonEligibleOrders = () => {
    return updateOrders({
      orders: orders,
      modalData: nonEligbleOrderModalData.orderTypes,
      orderTabIdProperty: "orderTypeId",
      modalType: activeModal,
      returnExchangeApplicableOrders: false,
      returnExchangeApplicableItems: false,
      addReturnType: false,
      applyReturnWindow: true,
      applyReturnWindowOperator: "is not within",
      conditionType: "order",
      selectedOrderTypeId: nonEligbleOrderModalData.selectedOrderTypeId,
      activeStep: "notReturnable",
      selectedAction: "return",
    });
  };

  /**
   * Function to generate orders for items not eligible modal
   */
  const getNonEligibleItemOrders = () => {
    return updateOrders({
      orders: orders,
      modalData: nonEligbleItemsModalData.itemTypes,
      orderTabIdProperty: "itemTypeId",
      modalType: activeModal,
      returnExchangeApplicableOrders: true,
      returnExchangeApplicableItems: false,
      addReturnType: false,
      applyReturnWindow: true,
      applyReturnWindowOperator: "is within",
      conditionType: "item",
      selectedOrderTypeId: nonEligbleItemsModalData.selectedItemTypeId,
      activeStep: "notReturnableItems",
      selectedAction: "return",
    });
  };

  /**
   * Function to generate orders for return types modal
   */
  const getReturnTypeOrders = () => {
    const { updatedNonEligibleOrders } = addNonEligibleItemsOrder(orders);
    return updateOrders({
      orders: updatedNonEligibleOrders,
      modalData: returnTypesModalData.returnTypes,
      orderTabIdProperty: "returnTypeId",
      modalType: activeModal,
      returnExchangeApplicableOrders: true,
      returnExchangeApplicableItems: true,
      addReturnType: false,
      applyReturnWindow: true,
      applyReturnWindowOperator: "is within",
      conditionType: "item",
      selectedOrderTypeId: returnTypesModalData.selectedReturnTypeId,
      activeStep: "returnType",
      selectedAction: "return",
    });
  };

  /**
   * Function to generate orders for return reasons modal
   */
  const getReturnReasonsOrders = () => {
    // Add non-eligible items to orders
    const { updatedNonEligibleOrders } = addNonEligibleItemsOrder(orders);

    // Generate orders with order tabs
    const { updatedOrders: ordersWithReasons, updatedSelectedOrder } =
      updateOrders({
        orders: updatedNonEligibleOrders,
        modalData: returnReasonsModalData.reasonTypes,
        orderTabIdProperty: "reasonTypeId",
        modalType: activeModal,
        returnExchangeApplicableOrders: true,
        returnExchangeApplicableItems: true,
        addReturnType: true,
        applyReturnWindow: true,
        applyReturnWindowOperator: "is within",
        conditionType: "item",
        selectedOrderTypeId: returnReasonsModalData.selectedReasonTypeId,
        activeStep: "reason",
        selectedAction: "return",
      });

    // Update selected order with active step and reason ID
    if (updatedSelectedOrder) {
      let activeStep: StepName = "configureNextSteps";
      let prevStep: StepName = "reason";
      const selectedTab = returnReasonsModalData.reasonTypes.find(
        (reasonTab) =>
          reasonTab.reasonTypeId == returnReasonsModalData.selectedReasonTypeId,
      );
      const reasons = getAllReasons();
      if (activeSubModal && activeSubModal.name === "moreQuestions") {
        const selectedReason = reasons?.find(
          (reason) => reason.id == activeSubModal?.reasonId,
        );
        if (selectedReason) {
          activeStep = prevStep = "moreDetails";
        }
      } else if (
        selectedTab &&
        (selectedTab.explainWhyTheyReturn === "required" ||
          selectedTab.explainWhyTheyReturn === "requiredAndCustom")
      ) {
        activeStep = prevStep = "reason";
      } else if (
        selectedTab &&
        selectedTab.explainWhyTheyReturn === "notRequired"
      ) {
        activeStep = prevStep = "returnType";
      }
      // Update items in the selected order with the reason ID
      updatedSelectedOrder.itemsInOrder.forEach((item) => {
        if (updatedSelectedOrder.addedItems)
          updatedSelectedOrder.addedItems[item.id].addedReasonId =
            activeSubModal?.reasonId;
      });
      updatedSelectedOrder.activeStep = activeStep;
      updatedSelectedOrder.prevStepForConfig = prevStep;
      updatedSelectedOrder.selectedAction = "return";
    }
    // Return the updated orders and selected order
    return {
      updatedOrders: ordersWithReasons,
      updatedSelectedOrder,
    };
  };

  /**
   * Function to generate orders for return method modal
   */
  const getReturnMethodOrders = () => {
    const { updatedNonEligibleOrders } = addNonEligibleItemsOrder(orders);
    return updateOrders({
      orders: updatedNonEligibleOrders,
      modalData: returnMethodModalData.returnMethods,
      orderTabIdProperty: "returnMethodId",
      modalType: activeModal,
      returnExchangeApplicableOrders: true,
      returnExchangeApplicableItems: true,
      addReturnType: true,
      applyReturnWindow: true,
      applyReturnWindowOperator: "is within",
      conditionType: "item",
      selectedOrderTypeId: returnMethodModalData.selectedReturnMethodId,
      activeStep: "returnMethod",
      selectedAction: "return",
    });
  };

  /**
   * Function to generate orders for order summry modal
   */
  const getOrderSummaryOrders = () => {
    const { updatedNonEligibleOrders } = addNonEligibleItemsOrder(orders);
    return updateOrders({
      orders: updatedNonEligibleOrders,
      modalData: orderSummaryModalData.additionalCharges,
      orderTabIdProperty: "additionalChargeId",
      modalType: activeModal,
      returnExchangeApplicableOrders: true,
      returnExchangeApplicableItems: true,
      addReturnType: true,
      applyReturnWindow: true,
      applyReturnWindowOperator: "is within",
      conditionType: "item",
      selectedOrderTypeId: orderSummaryModalData.selectedAdditionalChargeId,
      activeStep: "reviewRestockingFee",
      selectedAction: "return",
    });
  };

  /**
   * Function to generate orders for anti-refund strategies modal
   */
  const getAntiRefundOrders = () => {
    let updatedOrders: IOrder[] = orders;
    // It will add items not eligible order
    const { updatedNonEligibleOrders } =
      addNonEligibleItemsOrder(updatedOrders);
    updatedOrders = updatedNonEligibleOrders;
    const orderExists = updatedOrders.some(
      (order) => order.orderId === "antiRefund",
    );
    if (!orderExists) {
      // Add `addedItems` to each item in the order
      const addedItems: Record<string, IAddedItem> = {};
      addedItems[DummyOrder.itemsInOrder[0].id] = createAddedItem({
        itemId: DummyOrder.itemsInOrder[0].id,
        quantity: DummyOrder.itemsInOrder[0].quantity,
        addReturnType: true,
        isReturnExchangeApplicable: true,
        selectedReturnType: "refundOriginalPayment",
      });
      updatedOrders.push({
        ...DummyOrder,
        orderId: "antiRefund",
        orderName: `Sample Order ${updatedOrders.length + 1}`,
        modalType: activeModal,
        returnExchangeApplicable: true,
        addedItems: addedItems,
        conditions: [],
      } as IOrder);
      const updatedOrderIndex = updatedOrders.findIndex(
        (order) => order.orderId === "antiRefund",
      );
      const { updatedReturnWindowOrder } = applyReturnWindowToOrder({
        order: updatedOrders[updatedOrderIndex],
        operator: "is within",
      });
      updatedOrders[updatedOrderIndex] = updatedReturnWindowOrder;
    }
    const updatedSelectedOrder = updatedOrders.find(
      (order) => order.orderId == "antiRefund",
    );
    if (updatedSelectedOrder) {
      // Update items in the selected order with the reason ID
      if (updatedSelectedOrder.addedItems)
        updatedSelectedOrder.addedItems[
          DummyOrder.itemsInOrder[0].id
        ].addedReasonId = activeSubModal?.reasonId;

      updatedSelectedOrder.activeStep = "configureNextSteps";
      updatedSelectedOrder.prevStepForConfig = "reason";
      updatedSelectedOrder.selectedAction = "return";
    }
    const { updatedOrders: updatedOrdersEligibleItems } =
      applyAllItemsEligible(updatedOrders);

    // Update state with the updated orders and selected order
    updateState({
      orders: updatedOrdersEligibleItems,
      selectedOrder: updatedSelectedOrder,
    });
  };

  /**
   * Function to generate orders for anti refund strategies (multiple orders) modal
   */
  const getAntiRefundMultipleOrders = () => {
    const updatedOrders: IOrder[] = [...orders];
    // It will add items not eligible order
    const { updatedNonEligibleOrders } =
      addNonEligibleItemsOrder(updatedOrders);
    // updatedOrders = updatedNonEligibleOrders;
    antiRefundStrategiesModalData?.antiRefundOrderTypes?.forEach((orderTab) => {
      const orderExists = updatedOrders.some(
        (order) => order.orderId === orderTab?.orderTypeId,
      );
      if (!orderExists) {
        // Add addedItems to each item in the order
        const addedItems: Record<string, IAddedItem> = {};
        addedItems[DummyOrder.itemsInOrder[0].id] = createAddedItem({
          itemId: DummyOrder.itemsInOrder[0].id,
          quantity: DummyOrder.itemsInOrder[0].quantity,
          addReturnType: true,
          isReturnExchangeApplicable: true,
          selectedReturnType: "refundOriginalPayment",
        });
        updatedOrders.push({
          ...DummyOrder,
          orderId: orderTab?.orderTypeId,
          orderName: `Sample Order ${updatedOrders.length + 1}`,
          modalType: activeModal,
          returnExchangeApplicable: true,
          addedItems: addedItems,
          conditions: [],
        } as IOrder);
      }
      const updatedOrderIndex = updatedOrders.findIndex(
        (order) => order.orderId === orderTab?.orderTypeId,
      );
      if (updatedOrderIndex != -1) {
        const { updatedOrder } = applyConditionToOrder({
          order: updatedOrders[updatedOrderIndex],
          conditions: orderTab.conditions,
          conditionType: "item",
        });
        updatedOrders[updatedOrderIndex] = updatedOrder; // Update the order with new conditions

        // Apply return window if applicable
        const { updatedReturnWindowOrder } = applyReturnWindowToOrder({
          order: updatedOrders[updatedOrderIndex],
          operator: "is within",
        });
        updatedOrders[updatedOrderIndex] = updatedReturnWindowOrder;
      }
    });
    // Find the updated selected order
    const updatedSelectedOrder = updatedOrders.find(
      (order) =>
        order.orderId == antiRefundStrategiesModalData.selectedOrderTypeId,
    );

    if (updatedSelectedOrder) {
      // Update items in the selected order with the reason ID
      if (updatedSelectedOrder.addedItems)
        // Update items in the selected order with the reason ID
        updatedSelectedOrder.addedItems[
          DummyOrder.itemsInOrder[0].id
        ].addedReasonId = activeSubModal?.reasonId;
      updatedSelectedOrder.activeStep = "configureNextSteps";
      updatedSelectedOrder.prevStepForConfig = "reason";
      updatedSelectedOrder.selectedAction = "return";
    }
    const { updatedOrders: updatedOrdersEligibleItems } =
      applyAllItemsEligible(updatedOrders);

    // Update state with the updated orders and selected order
    updateState({
      orders: updatedOrdersEligibleItems,
      selectedOrder: updatedSelectedOrder,
    });
  };
  // Define a function to create an added item
  function createAddedItem({
    itemId,
    quantity,
    addReturnType,
    returnTypes,
    isReturnExchangeApplicable,
    selectedReturnType,
  }: {
    itemId: string;
    quantity: number;
    addReturnType: boolean;
    returnTypes?: IReturnType[];
    isReturnExchangeApplicable?: boolean;
    selectedReturnType?: ReturnType;
  }): IAddedItem {
    // Generate indexes based on quantity
    const indexes = Array.from({ length: quantity }, (_, i) => i);

    // Create the initial object with mandatory properties
    const addedItem: IAddedItem = {
      itemId,
      quantity: indexes.length,
      indexes: indexes,
      isReturnExchangeApplicable:
        isReturnExchangeApplicable != undefined
          ? isReturnExchangeApplicable
          : true,
    };

    // Conditionally add the selectedReturnType property
    if (addReturnType) {
      if (selectedReturnType) {
        addedItem.selectedReturnType = selectedReturnType;
      } else {
        if (returnTypes && returnTypes.length > 0) {
          addedItem.selectedReturnType = returnTypes[0].type;
        } else {
          addedItem.selectedReturnType = "exchangeWithSameItems";
        }
      }
    }
    return addedItem;
  }

  // Function to get country label by code
  const getCountryLabels = (countryCodes: string[]): string[] => {
    if (countryList?.data) {
      return countryCodes.map((countryCode) => {
        const country = countryList?.data?.find(
          (option) => option.code === countryCode,
        );
        return country ? country.name : "Unknown"; // Default to 'Unknown' if country not found
      });
    }
    return countryCodes.map(() => ""); // Return empty labels if data is not yet fetched
  };
  const currencySymbols: { [key: string]: string } = {
    USD: "$",
    INR: "₹",
  };

  // Function to get item label
  const getItemLabels = (items: string[]): string[] => {
    if (!allShopifyProducts || allShopifyProducts.length === 0) {
      dispatch(
        fetchGetAllShopifyProducts({
          limit: 15,
          searchTerm: "",
          start: 0,
        }),
      );
    }
    const itemLabels = items.map((item) => {
      const shopifyItem = allShopifyProducts?.find(
        (product) => product?.productId.toString() === item,
      );
      return shopifyItem ? shopifyItem.productName : "Unknown"; // Default to 'Unknown' if item not found
    });

    return itemLabels;
  };

  const applyReturnWindowToDefaultOrders = () => {
    const { day_type, returnWindow } = getReturnWindowData();

    let updatedOrders = orders;
    const orderWithinReturnWindowIdx = updatedOrders.findIndex(
      (order) => order.orderId === "SampleOrderWithinReturnWindow",
    );
    const orderOutsideReturnWindowIdx = updatedOrders.findIndex(
      (order) => order.orderId === "SampleOrderOutSideReturnWindow",
    );

    const addCondition = (orderIndex: number, operator: string) => {
      if (orderIndex != -1) {
        const existingConditionIndex = updatedOrders[
          orderIndex
        ].conditions.findIndex((con) => con.id === "returnWindow");
        if (existingConditionIndex == -1) {
          updatedOrders[orderIndex].conditions.unshift({
            id: "returnWindow",
            conditionType: "returnWindow",
            variableName: "Order ",
            values: `return window of ${returnWindow} ${
              day_type === "business_days" ? "business days" : "days"
            }`,
            operator: operator,
          } as Condition);
        }
      }
    };

    addCondition(orderWithinReturnWindowIdx, "is within");
    addCondition(orderOutsideReturnWindowIdx, "is outside");

    return {
      updatedOrders: updatedOrders,
    };
  };

  const applyAllItemsEligible = (orders: IOrder[]) => {
    let updatedOrders = orders;
    updatedOrders.forEach((order) => {
      if (
        order.orderId !== "SampleOrderOutSideReturnWindow" &&
        order.modalType !== "nonEligibleOrder" &&
        order.orderId !== "SampleOrderItemsNotEligible"
      ) {
        const conditionExists = order.conditions.some(
          (con) => con.id === "allItemsEligible",
        );
        if (!conditionExists) {
          order.conditions.unshift({
            id: "allItemsEligible",
            conditionType: "returnWindow",
            variableName: "All items ",
            values: "eligible",
            operator: "are ",
          } as Condition);
        }
      }
    });
    return {
      updatedOrders: updatedOrders,
    };
  };
  const applyAllItemsNotEligible = (orders: IOrder[]) => {
    let updatedOrders = orders.map((order) => {
      if (order.modalType === "nonEligibleItems") {
        const conditionIndex = order.conditions.findIndex(
          (con) => con.id === "allItemsEligible",
        );
        const conditionExists = order.conditions.some(
          (con) => con.id === "allItemsNotEligible",
        );
        if (conditionIndex !== -1) {
          // Remove "allItemsEligible" condition
          order.conditions.splice(conditionIndex, 1);
        }
        if (!conditionExists)
          // Add "allItemsNotEligible" condition
          order.conditions.unshift({
            id: "allItemsNotEligible",
            conditionType: "returnWindow",
            variableName: "Item ",
            values: "not eligible",
            operator: "is ",
          } as Condition);
      }
      return order;
    });

    return {
      updatedOrders: updatedOrders,
    };
  };

  const applyReturnWindowToOrder = ({
    order,
    operator,
  }: {
    order: IOrder;
    operator: string;
  }) => {
    const { day_type, returnWindow } = getReturnWindowData();
    if (order && operator) {
      let updatedOrder = order;
      const conditionExists = updatedOrder.conditions.some(
        (con) => con.id === "returnWindow",
      );
      const newCondition: Condition = {
        id: "returnWindow",
        conditionType: "returnWindow",
        variableName: "Order ",
        values: `return window of ${returnWindow} ${
          day_type === "business_days" ? "business days" : "days"
        }`,
        operator: operator,
      } as Condition;

      if (conditionExists) {
        const existingConditionIndex = updatedOrder.conditions.findIndex(
          (con) => con.id === "returnWindow",
        );
        if (existingConditionIndex !== -1) {
          updatedOrder.conditions[existingConditionIndex] = {
            ...newCondition,
          } as Condition;
        }
      } else {
        updatedOrder.conditions.unshift({
          ...newCondition,
        } as Condition);
      }
      return {
        updatedReturnWindowOrder: updatedOrder,
      };
    }
    return {
      updatedReturnWindowOrder: order,
    };
  };

  const applyConditionToOrder = ({
    order,
    conditions,
    conditionType,
  }: {
    order: IOrder;
    conditions: ConditionTypes[];
    conditionType: "item" | "order" | "returnWindow" | "reason";
  }) => {
    let updatedOrder = order;
    conditions.forEach((condition) => {
      const conditionExists = updatedOrder.conditions.some(
        (con) => con.id === condition.id,
      );

      Object.entries<any>(condition.values).forEach(([key, value]) => {
        let newCondition: Condition | null = null;
        switch (key) {
          case "items": {
            if (
              Array.isArray(value.currentValue) &&
              value.currentValue.length > 0
            ) {
              newCondition = {
                id: condition.id,
                conditionType: key,
                variableName: condition.variableName,
                values: getItemLabels(value.currentValue),
                operator: condition.operator,
              } as Condition;
            }
            break;
          }
          case "codes":
          case "numbers":
          case "tags": {
            if (
              (Array.isArray(value.currentValue) &&
                value.currentValue.length > 0) ||
              (typeof value.currentValue === "number" && value.currentValue > 0)
            ) {
              newCondition = {
                id: condition.id,
                conditionType: key,
                variableName: condition.variableName,
                values: value.currentValue,
                operator: condition.operator,
              } as Condition;
            }
            break;
          }
          case "countryName": {
            if (value.currentValue.length > 0) {
              newCondition = {
                id: condition.id,
                conditionType: key,
                variableName: condition.variableName,
                values: getCountryLabels(value.currentValue),
                operator: condition.operator,
              } as Condition;
            }
            break;
          }
          case "days": {
            if (value.currentValue > 0) {
              newCondition = {
                id: condition.id,
                conditionType: key,
                variableName: condition.variableName,
                values: `${condition.values.days.currentValue} ${
                  condition.values.day_type.currentValue == "business_days"
                    ? "business days"
                    : "days"
                }`,
                operator: condition.operator,
              } as Condition;
            }
            break;
          }
          case "amount": {
            const currencySymbol =
              currencySymbols[condition.values.currency.currentValue] ||
              condition.values.currency.currentValue;
            const amountValue = parseFloat(value.currentValue);
            if (condition.operator === "is between") {
              const maxValue = parseFloat(value.currentValue.maxValue);
              const minValue = parseFloat(value.currentValue.minValue);
              if (
                !isNaN(maxValue) &&
                !isNaN(minValue) &&
                maxValue >= minValue
              ) {
                newCondition = {
                  id: condition.id,
                  conditionType: key,
                  variableName: condition.variableName,
                  values: `${currencySymbol}${minValue} and ${currencySymbol}${maxValue}`,
                  operator: condition.operator,
                } as Condition;
                updatedOrder = adjustOrderTotal(
                  updatedOrder,
                  minValue,
                  maxValue,
                  currencySymbol,
                  condition.operator,
                );
              }
            } else if (!isNaN(amountValue)) {
              newCondition = {
                id: condition.id,
                conditionType: key,
                variableName: condition.variableName,
                values: `${currencySymbol}${amountValue}`,
                operator: condition.operator,
              } as Condition;
              updatedOrder = adjustOrderTotal(
                updatedOrder,
                amountValue,
                null,
                currencySymbol,
                condition.operator as string,
              );
            }
            break;
          }
          default:
            break;
        }

        if (newCondition) {
          if (conditionExists) {
            const existingConditionIndex = updatedOrder.conditions.findIndex(
              (con) => con.id === condition.id,
            );
            if (existingConditionIndex !== -1) {
              updatedOrder.conditions[existingConditionIndex] = {
                ...newCondition,
              } as Condition;
            }
          } else {
            updatedOrder.conditions.push({
              ...newCondition,
            } as Condition);
          }
        }
      });
    });
    return { updatedOrder: updatedOrder };
  };

  const adjustOrderTotal = (
    order: IOrder,
    minValue: number | null,
    maxValue: number | null,
    currencyCode: string,
    operator: string,
  ) => {
    let totalAmount = 0;

    // Calculate current total amount
    order.itemsInOrder.forEach((item) => {
      totalAmount += item.unitPrice.amount * item.quantity;
    });

    // Adjust the order total based on the condition operator
    if (operator === "is between" && minValue !== null && maxValue !== null) {
      if (totalAmount < minValue) {
        const difference = minValue - totalAmount;
        order.itemsInOrder[0].unitPrice.amount +=
          difference / order.itemsInOrder[0].quantity;
      } else if (totalAmount > maxValue) {
        const difference = totalAmount - maxValue;
        order.itemsInOrder[0].unitPrice.amount -=
          difference / order.itemsInOrder[0].quantity;
      }
    } else if (operator === "is more than" && minValue !== null) {
      if (totalAmount <= minValue) {
        const difference = minValue - totalAmount + 1;
        order.itemsInOrder[0].unitPrice.amount +=
          difference / order.itemsInOrder[0].quantity;
      }
    } else if (operator === "is less than" && maxValue !== null) {
      if (totalAmount >= maxValue) {
        const difference = totalAmount - maxValue + 1;
        order.itemsInOrder[0].unitPrice.amount -=
          difference / order.itemsInOrder[0].quantity;
      }
    } else if (operator === "is" && minValue !== null) {
      const difference = minValue - totalAmount;
      order.itemsInOrder[0].unitPrice.amount +=
        difference / order.itemsInOrder[0].quantity;
    }

    // Recalculate total amount
    totalAmount = order.itemsInOrder.reduce(
      (sum, item) => sum + item.unitPrice.amount * item.quantity,
      0,
    );
    order.orderTotal = { amount: totalAmount, currencyCode };
    return order;
  };

  const addNonEligibleItemsOrder = (orders: IOrder[]) => {
    const updatedOrders = orders;
    const nonEligbleItemsIdx = updatedOrders.findIndex(
      (order) => order.orderId === "SampleOrderItemsNotEligible",
    );
    if (nonEligbleItemsIdx == -1) {
      const { nonEligibleItemsOrder } = getNonEligibleOrder();
      updatedOrders.push({
        ...nonEligibleItemsOrder,
      });
      const conditionExists = updatedOrders[
        updatedOrders.length - 1
      ].conditions.some((con) => con.id === "allItemsNotEligible");
      if (!conditionExists) {
        updatedOrders[updatedOrders.length - 1].conditions.push({
          id: "allItemsNotEligible",
          conditionType: "returnWindow",
          variableName: "Item ",
          values: "not eligible",
          operator: "is ",
        } as Condition);
      }
    }
    return {
      updatedNonEligibleOrders: updatedOrders,
    };
  };

  const getNonEligibleOrder = () => {
    // Add `addedItems` to each item in the order
    const addedItems: Record<string, IAddedItem> = {};
    // Iterate through each item in the order to add to `addedItems`
    DummyOrder.itemsInOrder.forEach((item) => {
      addedItems[item.id] = createAddedItem({
        itemId: item.id,
        quantity: item.quantity,
        addReturnType: false,
        isReturnExchangeApplicable: false,
      });
    });
    const nonEligibleItemsOrder: IOrder = {
      ...DummyOrder,
      orderId: "SampleOrderItemsNotEligible",
      conditions: [],
      orderName: "Sample Order 3",
      addedItems: addedItems,
      returnExchangeApplicable: true,
    };
    return { nonEligibleItemsOrder };
  };

  return {
    activeModal,
    activeSubModal,
    getArsRespone,
    getReturnWindowData,
    getAllReturnTypes,
    getAllReasons,
    arsResponse,
    getNonEligibleOrders,
    getNonEligibleItemOrders,
    getReturnTypeOrders,
    getReturnReasonsOrders,
    getMoreDetailsByReasonId,
    getAntiRefundOrders,
    createAddedItem,
    getAntiRefundMultipleOrders,
    getReturnMethodOrders,
    getReturnMethodData,
    applyReturnWindowToDefaultOrders,
    applyAllItemsEligible,
    applyAllItemsNotEligible,
    getNonEligibleOrder,
    addNonEligibleItemsOrder,
    isRestockingFeeCharged,
    getRestockingFeeData,
    getOrderSummaryOrders,
    isReturnWindowMessageAvailable,
    isReturnReasonsAvailable,
    isReturnTypesAvailable,
    isReturnMethodAvailable,
    explainAnswer,
    antiRefundSelectedOrderId,
  };
};

export default useGetAutomationData;
