import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getCustomerDataApi,
  getShopifySidebarDataApi,
} from "src/services/ShopifySidebar/getData";
import { RootState } from "src/store/store";

const fetchShopifySidebarDataThunk = async (
  payload: undefined,
  { getState, dispatch }: { getState: Function; dispatch: Function },
) => {
  const currentState: RootState = getState();
  if (parseInt(currentState.shopifySidebar.customerData.id + "") === 0) {
    throw "CustomerId not set";
  }
  const shopifyData = await getShopifySidebarDataApi({
    customerId: parseInt(currentState.shopifySidebar.customerData.id + ""),
    start: currentState.shopifySidebar.shopifyData.orderIds.length,
    limit: 25,
  });
  return shopifyData;
};
const fetchCustomerDataThunk = async (
  payload: undefined | { includeTimeline: boolean },
  { getState, dispatch }: { getState: Function; dispatch: Function },
) => {
  const currentState: RootState = getState();
  if (parseInt(currentState.shopifySidebar.customerData.id + "") === 0) {
    throw "CustomerId not set";
  }
  const customerData = await getCustomerDataApi({
    id: parseInt(currentState.shopifySidebar.customerData.id + ""),
    includeTimeline: payload?.includeTimeline,
  });
  return customerData;
};

export default { fetchShopifySidebarDataThunk, fetchCustomerDataThunk };
