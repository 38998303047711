import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { OverlayTrigger } from "react-bootstrap";
import { TRUE } from "sass";
import { selectDDIndex } from "src/store/slices/ticketSidebar/ticketSidebar.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { IDropDownList } from "../../SubSB";
import styles from "./DropDownModule.module.scss";
import {v4 as uuid} from "uuid";

interface IDropDownModule extends IDropDownList {
  keyIndex: string | number;
}

function DropDownModule({
  keyIndex,
  ddName,
  ddList,
  ddSelectedIndex,
}: IDropDownModule) {
  const dispatch = useAppDispatch();
  const [showOver, setShowOver] = useState(false);
  const dropdownRef = useRef<any>(null);
  const dropdownArrowSpanRef = useRef<any>(null);

  const handleOnMouseEnter = (event: any) => {
    setShowOver(true);
  };

  const handleOnMouseLeave = (event: any) => {
    setShowOver(false);
  };

  const ticketStatusViewName = useMemo(()=>{

    let status =  ddSelectedIndex?.map((selectedIndex)=>ddList[selectedIndex]?.name).join(", ");

    return status;
  },[ddList, ddSelectedIndex]);

  return (
    <div className={`d-flex justify-content-between mb-1 all-tickets-li`}>
      <span className={`ticket-name width-sm`}>{ddName}:</span>
      <span className={`count text-end`}>
        <div className={`btn-group w-100 all-btn dropright`}>
          <OverlayTrigger
            // show={showOver}
            placement="right-start"
            target={dropdownRef.current}
            trigger="click"
            rootClose={true}
            overlay={
              <div
                className={`select-channel-p-ul max-content-height max-content-width`}
                // onMouseEnter={handleOnMouseEnter}
                // onMouseLeave={handleOnMouseLeave}
              >
                <ul
                  className={`ps-3 py-2 select-channel ${styles.dropDownUL}`}
                  style={{ overflow: "auto" }}
                >
                  <li>
                    <span className={`${styles.selectHead}`}>
                      Select {ddName}:
                    </span>
                  </li>
                  {Object.entries(ddList).map(([ddKey, list], key) => {
                    return (
                      // <li key={key}>
                      //   <a
                      //     className={`dropdown-item ${
                      //       parseInt(ddKey) === ddSelectedIndex
                      //         ? "active-line"
                      //         : ""
                      //     }`}
                      //     onClick={(e) => {
                      //       e.preventDefault();
                      //       dispatch(
                      //         selectDDIndex({
                      //           index: parseInt(
                      //             (keyIndex + "").slice(
                      //               (keyIndex + "").lastIndexOf(".") + 1
                      //             )
                      //           ),
                      //           selected: parseInt(ddKey),
                      //         })
                      //       );
                      //     }}
                      //   >
                      //     <span>
                      //       {list.name} ({list.count})
                      //     </span>
                      //   </a>
                      // </li>
                      <li
                        className={`position-relative  ${styles.hoverShow}`}
                        key={uuid()} // adding uuid id, checkbox is not updating properly due to old key so using uuid here 
                      >
                        <div
                          className={`position-absolute ${styles.hoverBar}`}
                        ></div>
                        <div className="form-check cursor-pointer">
                          <input
                            className={`form-check-input cursor-pointer ${styles.radioBtn}`}
                            type="checkbox"
                            id={`${ddKey}-${keyIndex}-input-radio`}
                            checked={ddSelectedIndex?.includes(parseInt(ddKey))} //marking check box as checked if filter is selected
                            onChange={(e) => {
                              e.preventDefault();
                              dispatch(
                                selectDDIndex({
                                  index: parseInt(
                                    (keyIndex + "").slice(
                                      (keyIndex + "").lastIndexOf(".") + 1
                                    )
                                  ),
                                  selected: parseInt(ddKey),
                                  remove: ddSelectedIndex?.includes(parseInt(ddKey))
                                } as any)
                              );
                            }}
                          />
                          <label
                            htmlFor={`${ddKey}-${keyIndex}-input-radio`}
                            className={`form-check-label cursor-pointer w-100 ${styles.labelText}`}
                          >
                            {list.name} ({list.count})
                          </label>
                        </div>
                      </li>
                    );
                  })}
                  {/* <li>
                    <div className="form-check">
                      <input
                        className={`form-check-input ${styles.radioBtn}`}
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                      />
                      <label
                        className={`form-check-label ${styles.labelText}`}
                        htmlFor="flexRadioDefault1"
                      >
                        <span>All </span>
                        <span> (13)</span>
                      </label>
                    </div>
                  </li>
                  <li>
                    <div className="form-check">
                      <input
                        className={`form-check-input ${styles.radioBtn}`}
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                      />
                      <label
                        className={`form-check-label ${styles.labelText}`}
                        htmlFor="flexRadioDefault1"
                      >
                        <span>All </span>
                        <span> (13)</span>
                      </label>
                    </div>
                  </li>
                  <li>
                    <div className="form-check">
                      <input
                        className={`form-check-input ${styles.radioBtn}`}
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                      />
                      <label
                        className={`form-check-label ${styles.labelText}`}
                        htmlFor="flexRadioDefault1"
                      >
                        <span>All </span>
                        <span> (13)</span>
                      </label>
                    </div>{" "}
                  </li>
                  <li>
                    <div className="form-check">
                      <input
                        className={`form-check-input ${styles.radioBtn}`}
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                      />
                      <label
                        className={`form-check-label ${styles.labelText}`}
                        htmlFor="flexRadioDefault1"
                      >
                        <span>All </span>
                        <span> (13)</span>
                      </label>
                    </div>
                  </li> */}
                </ul>
              </div>
            }
          >
            <button
              type="button"
              ref={dropdownRef}
              className={`btn-grey justify-content-between d-flex align-items-center dropdown-toggle border-0 w-100 text-start all-dropdown ${styles.dropdownToggle}`}
              id={`multilevelDropdownMenu${keyIndex}`}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            >
              <span className={styles.dropDownTextSpan}>
                {ticketStatusViewName}
              </span>
              <span
                ref={dropdownArrowSpanRef}
                className={styles.dropDownArrowSpan}
              >
                <i className={`fa-solid fa-caret-right`}></i>
              </span>
            </button>
          </OverlayTrigger>
        </div>
      </span>
    </div>
  );
}
export default DropDownModule;
