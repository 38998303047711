import { useCallback, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import InfiniteScroll from "src/components/InfiniteScroll";
import Loader from "src/components/Loader";
import {
  fetchAllInnerTickets,
  resetInnerTicket,
  setGlobalData,
  setInnerTicketFilters
} from "src/store/slices/innerTicket/innerTicket.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import styles from "./GlobalSearch.module.scss";
import TicketData from "./TicketData/TicketData";
import emptyBackPng from "src/assets/images/emptyBack.png";
import { showGlobalLoader } from "src/components/GlobalLoader/GlobalLoader";

export default function GlobalSearch({ searchText }: { searchText: string }) {
  const dispatch = useAppDispatch();
  const { tableType, viewType }: any = useParams();
  const { ticketList, ticketData, ticketDataAjaxStatus, ticketListTotal } =
    useAppSelector((state) => state.innerTicket);
  const ticketListTotalCurrent: any = useRef(ticketListTotal);
  useEffect(() => {
    ticketListTotalCurrent.current = ticketListTotal;
  }, [ticketListTotal]);
  const handleInfiniteScroll = useCallback(() => {
    if (ticketListTotalCurrent.current !== null) {
      if (ticketListTotalCurrent.current > 0) {
        dispatch(fetchAllInnerTickets());
      }
    }
  }, [ticketListTotal]);
  useEffect(() => {
    dispatch(resetInnerTicket());
    dispatch(setInnerTicketFilters({currentView: viewType}));
    dispatch(
      setGlobalData({
        tableType,
        searchText
      })
    );
    dispatch(fetchAllInnerTickets());
  }, [searchText]);

  useEffect(()=>{
    //stopping the loader on load of global search
    showGlobalLoader("None");
  },[false]);

  return (
    <div className={`${styles.GlobalSearchMain}`}>
      <p className={`${styles.searchPara}`}>Search Results</p>
      <InfiniteScroll
        className={`${styles.searchDiv}`}
        loadMore={handleInfiniteScroll}
        loader={
          ticketDataAjaxStatus === "pending" && ticketList.length === 0 ? (
            <div className={`d-flex flex-column m-auto ${styles.loading}`}>
              <Loader />
              <span className="text-center">Searching...</span>
            </div>
          ) : undefined
        }
        initialDataLoaded={true}
        errorMessage={
          ticketListTotal === 0 || ticketDataAjaxStatus === "rejected" ? (
            <div className={`${styles.nothingFound} m-auto`}>
              <img src={emptyBackPng} className={`d-block m-auto mb-2`} />
              <span>No Ticket Found</span>
            </div>
          ) : undefined
        }
        hasMore={
          ticketDataAjaxStatus === "rejected"
            ? false
            : ticketListTotal === null
            ? true
            : ticketList.length < ticketListTotal
        }
      >
        {ticketList.map((id, index) => {
          return <TicketData key={id} ticketData={ticketData[id]} />;
        })}
      </InfiniteScroll>
    </div>
  );
}
