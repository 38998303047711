import { Channel } from "pusher-js";
import { Message } from "src/services/LiveChat/messageExchange/getMessages";

export type ConnectedCustomerRecEventCB = (res: any) => void;

export const getNewlyConnectedCustomerEvent = (
  channel: Channel,
  callback: ConnectedCustomerRecEventCB
) => {
  channel.bind("chat_connected_agent", callback);
};

export const getNewlyConnectedCustomerEventUnbind = (
  channel: Channel,
) => {
  channel.unbind("chat_connected_agent");
};
