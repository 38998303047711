import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";

export interface UpdatePriorityParams {
  type: string;
  id: number; //rule id
  priority: number; //updated index + 1 (shouldn't be 0)
}

export const updatePriorityService = async ({
  type,
  ...params
}: UpdatePriorityParams) => {
  const url = IS_TEST_ACTIVE
    ? `${process.env.REACT_APP_TEST_SITE_URL}/${type}/updatePriority`
    : `/api/automation/${type}/updatePriority`;

  try {
    const { data: res } = await axiosJSON.post(url, params);

    if (res.err || res.error) {
      throw res.msg || res.message || "Cannot Update automation data";
    }
    return res.data;
  } catch (err) {
    if (typeof err === "string") {
      throw err;
    }
  }
};
