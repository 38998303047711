/**
 * NotificationSend Component function file.
 *
 * @author Yash Aditya
 */

import styles from "./NotificationSend.module.scss";
import { ShowAgents } from "../ShowAgents/ShowAgents";
import { NavLink } from "react-router-dom";
import closeModal from "src/assets/images/close(3).png";

interface Props {
  onHide: any;
}

/**
 * This component renders a modal for configuring notification frequency.
 * It displays a list of all agents to whome the notifications will be sent.
 */
const NotificationSend = ({ onHide }: Props) => {
  return (
    <div className="p-3">
      {/* Modal Header */}
      <div className="d-flex justify-content-between">
        <h4 className={`${styles.modalHead}`}>Notification Frequency</h4>
        {/* <span
          className={`cursor-pointer ${styles.closeModal}`}
          onClick={onHide}
        >
          <i className="fa-solid fa-xmark"></i>
        </span> */}
        <img
          src={closeModal}
          alt=""
          className={`${styles.closeFredModal} cursor-pointer`}
          onClick={onHide}
        />
      </div>

      {/* Modal Body */}
      <div>
        {/* Unresolved Tickets Information */}
        <p className={`mb-0 ${styles.modalSub}`}>
          Total number of unresolved tickets
        </p>
        <p className={`${styles.redirectLink}`}>
          <span>To change frequency of notifications go to </span>
          <NavLink to={`/settings/notification`} className={`${styles.linkTo}`}>
            notification settings
          </NavLink>
        </p>

        {/* List of All Agents */}
        <p className={`mb-0 ${styles.sendNote}`}>All agents</p>
        <div className={`me-2 mt-2 ${styles.sentBox}`}>
          <ShowAgents />
        </div>
      </div>

      {/* Modal Footer */}
      <div className="d-flex justify-content-end">
        <button className={`mt-4 ${styles.okBtn}`} onClick={onHide}>
          Ok
        </button>
      </div>
    </div>
  );
};

export default NotificationSend;
