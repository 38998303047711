/**
 * This file contains the custom hook for managing article settings in a component.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { useCallback, useEffect, useRef } from "react";
import { fetchAllArticleCategories } from "src/store/slices/liveChatSetting/chatWidgetSetting/knowledgeBased/knowledgeBasedSetting.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";

/**
 * Custom hook for managing article settings.
 */
function useArticleSetting({
  integrationId,
  isNew,
  setSelectedCategory,
}: {
  integrationId: number | string;
  isNew: boolean;
  setSelectedCategory: (value: any) => void;
}) {
  // Extracting relevant state and dispatch from Redux store using custom hooks.
  const {
    categoryData,
    categoryIdList,
    fetchCategoryAjaxStatus: fetchArticleCategoryAjaxStatus,
    categoryMetaData: metaData,
  } = useAppSelector((state) => state.knowledgeBasedSettings);

  const dispatch = useAppDispatch();
  const categorySelectedOptionInitialized = useRef(false);

  // storing total categories metadata in ref to prevent stale state problem.
  const totalCategories = useRef(metaData);

  // useEffect to handle changes in categoryData.
  useEffect(() => {
    if (categoryData !== null) {
      // Mapping categoryData to create options for a select component.
      let categoryOptions = Object.keys(categoryData).map(function (
        key,
        index
      ) {
        let category = categoryData[parseInt(key)];
        return { label: category.categoryName, value: category.categoryId };
      });

      if (categoryOptions.length === 0) {
        return;
      }
      // setOptions(categoryOptions);

      // Setting the initial selected category if it's a new article.
      if (
        categorySelectedOptionInitialized.current === false &&
        isNew === true
      ) {
        setSelectedCategory(categoryOptions[0]);
        categorySelectedOptionInitialized.current = true;
      }
    }
  }, [categoryData]);

  // Updating totalCategories ref when metaData changes.
  useEffect(() => {
    totalCategories.current = {
      count: metaData.count,
      total: metaData.total,
    };
  }, [metaData]);

  // Handling infinite scroll and fetch more categories.
  const handleInfiniteScroll = useCallback(() => {
    if (totalCategories.current !== null) {
      if (
        totalCategories.current.total != null &&
        totalCategories.current.total > 0
      ) {
        dispatch(
          fetchAllArticleCategories({
            integrationId: integrationId,
            start: 0,
            limit: 15,
          })
        );
      }
    }
  }, [dispatch, integrationId, totalCategories.current.total]);

  // Handling the selection of a category.
  const handleSelectedCategory = ({ categoryId, categoryName }: any) => {
    if (categoryId !== 0 && categoryId !== null) {
      setSelectedCategory({ label: categoryName, value: categoryId });
    }
  };

  // Returning relevant properties and functions for the component.
  return {
    handleInfiniteScroll,
    fetchArticleCategoryAjaxStatus,
    categoryData,
    metaData,
    categoryIdList,
    handleSelectedCategory,
  };
}

export default useArticleSetting;
