/**
 * This file contains the custom hook for handling KB settings.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { updateActiveTheme } from "src/services/KnowledgeBase/Settings/Styles/updateActiveTheme.service";
import { useAppDispatch } from "src/store/store";
import useThemeBox from "../../../../../ThemeBox/useThemeBox";

/**
 * Custom hook for managing the preview box component for KB settings.
 */
function usePreviewBox(themeModalHide: () => void, id: number) {
  // State to manage the visibility of the preview box modal
  const [showModal, setShowModal] = useState(false);

  // Get the `getThemeData` function from the `useThemeBox` hook
  const { getThemeData } = useThemeBox();

  /**
   * Handler to show the preview box modal.
   */
  const onShow = (e: any) => {
    e.stopPropagation();
    setShowModal(true);
  };

  // Handler to hide the preview box modal
  const onHide = () => {
    setShowModal(false);
  };

  // Mutation hook for updating the active theme
  const update = useMutation({
    mutationFn: updateActiveTheme,
    onSuccess: () => {
      // Refetch theme data after a successful update
      getThemeData.refetch();
    },
  });

  // Get the integrationId from the route parameters
  const { integrationId } = useParams();

  /**
   * Handler to choose the theme and update the active theme.
   */
  const handleChooseTheme = () => {
    // Hide the theme modal
    themeModalHide();

    // If integrationId is available, trigger the theme update mutation
    if (integrationId) {
      update.mutate({
        integrationId: integrationId,
        themeId: id,
      });
    }
  };

  // Return the relevant state and functions
  return {
    showModal,
    onShow,
    onHide,
    handleChooseTheme,
  };
}

export default usePreviewBox;
