import { axiosJSON } from "src/globals/axiosEndPoints";
import APIResponse from "src/services/Settings/Teams/APIReponse";


export interface IUpdateAskForEmailDataParam{
  integrationId: number | string;
  whenAgentOffline?: {
   confirmationText: string;
   messageText: string
  },
  whenAgentNotResponding?: {
    canSendMessage?: boolean;
    sendMessageAfter?: number | string;
    messageText?: string;
    confirmationText?: string;
    canCustomerLeaveEmail?: boolean;
  },
  
}

interface updateAskForEmailResponse{
  status: boolean;
}

export async function updateAskForEmailData(params: IUpdateAskForEmailDataParam) {
  
  const { data: res } = await axiosJSON.post<APIResponse<updateAskForEmailResponse>>(
    "/api/chatSetting/forms/updateAskForEmail",
    params
  );

  if (res.error === true) {
    throw new Error(res.message as string);
  }

  return res.data as updateAskForEmailResponse;
}
