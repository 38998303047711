import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import {
  OrderInfoDetailsForEmailForwarding,
  OrderRowData,
} from "src/features/ReturnAutoWorkFlow/ReturnAutoWorkFlow.types";
import getOrderModalInfo, {
  GetOrderModalInfoParams,
  GetOrderModalInfoResponse,
} from "src/services/ReturnAutoWorkFlow/StaticModals/getOrderModalInfo.service";

interface IAmount {
  /** The currency code for the price (e.g., $, USD, EUR). */
  currencyCode: string;
  /** The amount of the price. */
  amount: number;
}
/**
 * Custom hook to fetch and format order information for the modal.
 *
 * This hook uses `useQuery` to fetch order data and formats it into a structured
 * object suitable for displaying order details in the modal.
 *
 * @param {GetOrderModalInfoParams} params - Parameters required to fetch the order information.
 *
 * @returns object - An object containing the formatted order details, loading state, and error state.
 */
const useOrderInfoModal = (params: GetOrderModalInfoParams) => {
  const { data, isLoading, isError } = useQuery<GetOrderModalInfoResponse>({
    queryKey: ["orderInfo", params],
    queryFn: () => getOrderModalInfo({ ...params }),
  });
  const ret: OrderInfoDetailsForEmailForwarding = {
    allOrderIds: [],
    allOrders: {},
    tableHeadingIds: [],
    tableHeadings: {},
    totalOrders: data?.totalOrders ?? 0,
  };
  if (data) {
    data?.tableHeadings.forEach((tableHeading) => {
      ret.tableHeadings[tableHeading.tableHeadingId] = tableHeading;
      ret.tableHeadingIds.push(tableHeading.tableHeadingId);
    });

    data.allOrders.forEach((order) => {
      // const orderRowData: OrderRowData = {};

      // order.rowData.map((row) => {
      //   orderRowData[row.tableHeadingId] = row;
      // });

      ret.allOrders[order.orderId] = {
        orderId: order.orderId,
        rowData: order.rowData,
      };

      ret.allOrderIds.push(order.orderId);
    });

    ret.totalOrders = data.totalOrders;
  }

  return {
    data: ret,
    isLoading,
    isError,
  };
};

export default useOrderInfoModal;
