import { axiosJSON } from "src/globals/axiosEndPoints";

export interface ReasonOptions {
  key: string;
  label: string;
}

export interface Step13ReturnReasonOptions {
  data: ReasonOptions[];
}

export async function fetchGetStep13ReturnReasonOptions() {
  const { data } = await axiosJSON.get(
    "/api/setting/automation/refund/step/13/getReturnReasonOptions"
  );

  if (data.err === true) {
    throw new Error("Some Error Occurred while fetching data");
  }

  return data.data;
}
