import {
  createAsyncThunk,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { AJAXSTATUS } from "src/globals/constants";
import { GetAllBrandData, OutBoundEmail } from "src/services/Settings/Brands/getAllBrands";
import { BrandAgent } from "src/services/Settings/Brands/getBrand";
import { BrandSignaturePlaceholder } from "src/services/Settings/Brands/getBrandSignaturePlaceholders";
import brandSettingsExtraReducers from "./brandSettings.extraReducers";
import brandSettingsReducers from "./brandSettings.reducers";
import { areAllUsersSelected, getBrandModifiedAssignToUsersIds, getBrandReassignableAgentIds, getBrandSelectedUserTicketCounts, isAgentIdSelected, isAllSelectedBrandAgentDisabled, isAllSelectedBrandAgentEnabled } from "./brandSettings.selectors";
import brandSettingsThunks from "./brandSettings.thunks";
import { BrandVariableValues } from "src/services/Settings/Brands/getBrandVariableValues";

export const fetchAllBrands = createAsyncThunk(
  "brandSettings/fetchAllBrands",
  brandSettingsThunks.fetchAllBrandThunk
);
export const addBrand = createAsyncThunk(
  "brandSettings/addBrand",
  brandSettingsThunks.addBrandThunk
);
export const updateBrand = createAsyncThunk(
  "brandSettings/updateBrand",
  brandSettingsThunks.updateBrandThunk
);
export const getBrand = createAsyncThunk(
  "brandSettings/getBrand",
  brandSettingsThunks.getBrandThunk
);
export const deleteBrand = createAsyncThunk(
  "brandSettings/deleteBrand",
  brandSettingsThunks.deleteBrandThunk
);

export const getBrandAgents = createAsyncThunk(
  "brandSettings/getBrandAgents",
  brandSettingsThunks.getBrandAgentsThunk
);

export const getAllBrandAgents = createAsyncThunk(
  "brandSettings/getAllBrandAgents",
  brandSettingsThunks.getAllBrandAgentsThunk
);
export const getAllOutboundEmails = createAsyncThunk(
  "brandSettings/getAllOutboundEmails",
  brandSettingsThunks.getAllOutboundEmailsThunk
);

export const getBrandSignaturePlaceholders = createAsyncThunk(
  "brandSettings/getBrandSignaturePlaceholders",
  brandSettingsThunks.fetchBrandPlaceholdersThunk
);

export const getBrandVariableValues = createAsyncThunk(
  "brandSettings/getBrandVariableValues",
  brandSettingsThunks.fetchBrandVariableValuesThunk
);


export const getAllBrandsInfiScroll = createAsyncThunk(
  "brandSettings/getAllBrandsInfiScroll",
  brandSettingsThunks.fetchAllBrandsInfiScrollThunk
);


export interface BrandFilters {
  searchValue: string;
}

export interface ModifiedBrandUser{
  id: number|string,
  enabled: boolean,
  ticketCount: number,
  assignTo: number|string|null;
}
export interface IBrandSettings extends GetAllBrandData {
  cachedData: { [pageNo: string | number]: Array<number | string> };
  activeBrandId: number|string,
  brandFetchLimit: number,
  filters: {
    searchValue: string,
  },
  users: { [id: number | string]: BrandAgent };
  userIds: Array<number | string>;
  userFetchLimit: number;
  userSearchValue: string;
  usersMeta: {
    count: number,
    total: number,
    userCurrentPage: number,
    totalPages: number,
  },
  brandAgents: { [id: number | string]: BrandAgent },
  brandAgentIds : Array<number | string>,
  brandAgentFetchLimit : number,
  brandAgentMeta: {
    count: number,
    total: number,
  },
  currentPage: number;
  totalPages: number;
  modifiedUsersList: {[id: number | string]: ModifiedBrandUser}
  outboundMails:  { [id: number | string]: OutBoundEmail },
  outboundMailsIds: Array<number | string>,
  outboundMailsMeta: {
    count: number,
    total: number
  },
  outBoundEmailFetchLimit: number;
  outBoundEmailSearchValue: string;
  bulkSelectedUserIds: Array<number|string>;
  brandDataAjaxStatus: AJAXSTATUS;
  addBrandAjaxStatus: AJAXSTATUS;
  updateBrandAjaxStatus: AJAXSTATUS,
  deleteBrandAjaxStatus: AJAXSTATUS;
  getBrandAjaxStatus: AJAXSTATUS;
  getBrandAgentAjaxStatus: AJAXSTATUS;
  getAllBrandAgentAjaxStatus: AJAXSTATUS;
  allOutboundEmailAjaxStatus: AJAXSTATUS;
  brandPlaceholderOptions: Array<BrandSignaturePlaceholder>,
  brandVariableValues: Array<BrandVariableValues>,
  childModalIsOpened: boolean;
  cachingPageNoLimit: number;
}

export const initialState: IBrandSettings = {
  brands: {},
  brandIds: [],
  cachedData: {},
  cachingPageNoLimit: 2,
  metaData: {
    count: 0,
    total: 0
  },
  activeBrandId: 0,
  brandFetchLimit: 10,
  filters: {
    searchValue: ""
  },
  users: {},
  userIds: [],
  usersMeta: {
    count: 0,
    total: 0,
    userCurrentPage: 0,
    totalPages: 0,
  },
  brandAgents: {},
  brandAgentIds: [],
  brandAgentMeta: {
    count: 0,
    total: 0,
  },
  brandAgentFetchLimit : 15,
  bulkSelectedUserIds: [],
  userSearchValue: "",
  userFetchLimit: 10,
  currentPage: 0,
  totalPages: 0,
  modifiedUsersList: {},
  outboundMails: {},
  outboundMailsIds: [],
  outboundMailsMeta: {
    count: 0,
    total: 0
  },
  outBoundEmailSearchValue: "",
  outBoundEmailFetchLimit: 8,
  brandDataAjaxStatus: "pending",
  addBrandAjaxStatus: "idle",
  updateBrandAjaxStatus: "idle",
  deleteBrandAjaxStatus: "idle",
  getBrandAjaxStatus: "idle",
  getBrandAgentAjaxStatus: "idle",
  getAllBrandAgentAjaxStatus: "idle",
  allOutboundEmailAjaxStatus: "idle",
  brandPlaceholderOptions: [],
  childModalIsOpened: false,
  brandVariableValues: [],
};

export const brandSettingsSlice = createSlice({
  name: "brandSettings",
  initialState,
  reducers: brandSettingsReducers,
  extraReducers: (builder) => {
    builder.addCase(
      fetchAllBrands.fulfilled,
      brandSettingsExtraReducers.fetchAllBrandFulfilled
    );
    builder.addCase(fetchAllBrands.rejected, (state) => {
      state.brandDataAjaxStatus = "rejected";
      pushTheToast({
        position: "top-right",
        type: "danger",
        text: "Something went wrong!"
      });
    });
    builder.addCase(fetchAllBrands.pending, (state) => {
      state.brandDataAjaxStatus = "pending";
    });

    builder.addCase(
      addBrand.fulfilled,
      brandSettingsExtraReducers.addBrandFulfilled
    );
    builder.addCase(addBrand.rejected, (state, action) => {
      state.addBrandAjaxStatus = "rejected";
      
      pushTheToast({
        position: "top-right",
        type: "danger",
        text: action.error.message ?? "Something went wrong"
      });
    });
    builder.addCase(addBrand.pending, (state) => {
      state.addBrandAjaxStatus = "pending";
    });

    builder.addCase(
      updateBrand.fulfilled,
      brandSettingsExtraReducers.updateBrandFulfilled
    );
    builder.addCase(updateBrand.rejected, (state) => {
      state.updateBrandAjaxStatus = "rejected";
      pushTheToast({
        position: "top-right",
        type: "danger",
        text: "Something went wrong!"
      });
    });
    builder.addCase(updateBrand.pending, (state) => {
      state.updateBrandAjaxStatus = "pending";
    });

    builder.addCase(
      deleteBrand.fulfilled,
      brandSettingsExtraReducers.deleteBrandFulfilled
    );
    builder.addCase(deleteBrand.rejected, (state, action) => {
      state.deleteBrandAjaxStatus = "rejected";
      
      pushTheToast({
        position: "top-right",
        type: "danger",
        text: action.error.message ?? "Something went wrong"
      });
    });
    builder.addCase(deleteBrand.pending, (state) => {
      state.deleteBrandAjaxStatus = "pending";
    });
    builder.addCase(
      getBrand.fulfilled,
      brandSettingsExtraReducers.getBrandFulfilled
    );
    builder.addCase(getBrand.rejected, (state) => {
      state.getBrandAjaxStatus = "rejected";
      pushTheToast({
        position: "top-right",
        type: "danger",
        text: "Something went wrong!"
      });
    });
    builder.addCase(getBrand.pending, (state) => {
      state.getBrandAjaxStatus = "pending";
    });
    builder.addCase(
      getBrandAgents.fulfilled,
      brandSettingsExtraReducers.getBrandAgentsFulfilled
    );
    builder.addCase(getBrandAgents.rejected, (state) => {
      state.getBrandAgentAjaxStatus = "rejected";
      pushTheToast({
        position: "top-right",
        type: "danger",
        text: "Something went wrong!"
      });
    });
    builder.addCase(getBrandAgents.pending, (state) => {
      state.getBrandAgentAjaxStatus = "pending";
    });
    builder.addCase(
      getAllBrandAgents.fulfilled,
      brandSettingsExtraReducers.getAllBrandAgentsFulfilled
    );
    builder.addCase(getAllBrandAgents.rejected, (state) => {
      state.getAllBrandAgentAjaxStatus = "rejected";
      pushTheToast({
        position: "top-right",
        type: "danger",
        text: "Something went wrong!"
      });
    });
    builder.addCase(getAllBrandAgents.pending, (state) => {
      state.getAllBrandAgentAjaxStatus = "pending";
    });
    builder.addCase(
      getAllOutboundEmails.fulfilled,
      brandSettingsExtraReducers.getAllOutboundEmailFulfilled
    );
    builder.addCase(getAllOutboundEmails.rejected, (state) => {
      state.allOutboundEmailAjaxStatus = "rejected";
      pushTheToast({
        position: "top-right",
        type: "danger",
        text: "Something went wrong!"
      });
    });
    builder.addCase(getAllOutboundEmails.pending, (state) => {
      state.allOutboundEmailAjaxStatus = "pending";
    });
    builder.addCase(
      getBrandSignaturePlaceholders.fulfilled,
      brandSettingsExtraReducers.fetchBrandSignaturePlaceholdersFulfilled
    );
    builder.addCase(
      getBrandVariableValues.fulfilled,
      brandSettingsExtraReducers.fetchBrandSignatureVariableFulfilled
    );
    builder.addCase(getAllBrandsInfiScroll.rejected, (state) => {
      state.brandDataAjaxStatus = "rejected";
      pushTheToast({
        position: "top-right",
        type: "danger",
        text: "Something went wrong!"
      });
    });
    builder.addCase(getAllBrandsInfiScroll.pending, (state) => {
      state.brandDataAjaxStatus = "pending";
    });
    builder.addCase(
      getAllBrandsInfiScroll.fulfilled,
      brandSettingsExtraReducers.fetchAllBrandInfiScrollFulfilled
    );
    
  }
});

export const { 
  resetBrandSettings, 
  resetBrandData, 
  setBrandSearchValue,
  resetBrandGeneralUserList,
  setBrandCurrentPage,
  setBrandUserCurrentPage,
  setBrandUserSearchValue,
  removeUserFromBulkSelect,
  selectAllUsersFromBulkSelect,
  removeAllUsersFromBulkSelect,
  setActiveBrandId,
  resetBrandAgentList,
  addUserToModifiedUsers,
  resetModifiedUsersList,
  selectUserForBulkSelect,
  setChildModal,
  setBrandOutboundEmailSearchValue,
  resetOutboundEmailList,
  setBrandCachedPage,
  setBrandData,
} =
  brandSettingsSlice.actions;

export const isAgentIdSelectedSelector = createSelector(
  [isAgentIdSelected],
  (isAgentIdSelected) => isAgentIdSelected
);

export const areAllUsersSelectedSelector = createSelector(
  [areAllUsersSelected],
  (areAllUsersSelected) => areAllUsersSelected
);

export const getBrandSelectedUserTicketCountsSelector = createSelector(
  [getBrandSelectedUserTicketCounts],
  (getBrandSelectedUserTicketCounts) => getBrandSelectedUserTicketCounts
);
export const isAllSelectedBrandAgentDisabledSelector = createSelector(
  [isAllSelectedBrandAgentDisabled],
  (isAllSelectedBrandAgentDisabled) => isAllSelectedBrandAgentDisabled
);
export const isAllSelectedBrandAgentEnabledSelector = createSelector(
  [isAllSelectedBrandAgentEnabled],
  (isAllSelectedBrandAgentEnabled) => isAllSelectedBrandAgentEnabled
);


export const getBrandDisabledAgentIdsSelector = createSelector(
  [getBrandReassignableAgentIds],
  (getBrandReassignableAgentIds) => getBrandReassignableAgentIds
);
export const getBrandModifiedAssignToUsersIdsSelector = createSelector(
  [getBrandModifiedAssignToUsersIds],
  (getBrandModifiedAssignToUsersIds) => getBrandModifiedAssignToUsersIds
);
export default brandSettingsSlice.reducer;
