import Loader from "src/components/Loader";
import { useReturnAutoWorkFlow } from "../../hooks/useReturnAutoWorkFlow";
import ConfiguredActiveStep from "./children/ConfiguredActiveStep/ConfiguredActiveStep";
import styles from "./StepsConfigured.module.scss";

function StepsConfigured() {
  const { returnAutoWorkFlow } = useReturnAutoWorkFlow();

  return (
    <div className={`col-12 col-md-4 p-2`}>
      <div className={` ${styles.rightSideSection}`}>
        <div className={`pt-2 pb-5 ${styles.scroll}`}>
          {returnAutoWorkFlow.loadingReturnAutomationWorkFlow === "pending" && (
            <div className={`${styles.loaderHeight}`}>
              <Loader className={`${styles.loader}`} />
            </div>
          )}
          {returnAutoWorkFlow.loadingReturnAutomationWorkFlow ===
            "rejected" && (
            <div className={`${styles.loaderHeight}`}>
              <div className="text-danger text-center">
                Error Loading Content...
              </div>
            </div>
          )}
          {returnAutoWorkFlow.loadingReturnAutomationWorkFlow ===
            "fulfilled" && (
            <div className={`mt-4  m-auto ${styles.previewWrapper}`}>
              <div className={`mt-3 mx-auto ${styles.teamHeading} py-2`}>
                <div className="d-flex justify-content-center">
                  <div>
                    <div className={`${styles.teamOnline}`}>
                      <span className={`${styles.teamText}`}>
                        Steps configured
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <ConfiguredActiveStep />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default StepsConfigured;
