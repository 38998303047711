import React, { useEffect, useMemo, useRef, useState } from "react";
import styles from "./ResultTemplates.module.scss";
import TemplateComp from "../../../TemplateComp/TemplateComp";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import {
  IGetTemplates,
  getTemplates,
} from "src/services/Templates/viewTemplates";
import InfiniteScroll from "src/components/InfiniteScroll";
import Loader from "src/components/Loader";
import { useGetTemplates } from "src/components/ViewTemplateV2/Children/CustomHook/useGetTemplates";

const ResultTemplates = ({
  setTemplates,
  selectedCategory,
  // templates,
  setSelectedTemplateData = (template: any) => {},
  onTemplateSelect = (template: string) => {},
}: {
  setTemplates: any;
  selectedCategory: any;
  templates: any;
  setSelectedTemplateData: any;
  onTemplateSelect: any;
}) => {
  const filters = useMemo(() => {
    return {
      start: 0,
      limit: 25,
      template_category_id: selectedCategory?.id,
    };
  }, [selectedCategory?.id]);

  const {
    status,
    templateIds,
    templates,
    hasNextPage,
    fetchNextPage,
    fetchStatus,
    isLoading,
  } = useGetTemplates(filters);

  const errorMessage = useMemo(() => {
    if (
      status === "error" ||
      (status !== "success" && fetchStatus !== "fetching") ||
      (status !== "loading" && templateIds.length === 0)
    ) {
      return (
        <span className={`ps-2 ${styles.noResultFound}`}>
          No template found
        </span>
      );
    }
  }, [status, fetchStatus, templateIds.length]);

  return (
    <div className={`p-2 ${styles.resWrapper}`} id="resCategory">
      <div className={`${styles.InfiniteScrollWrapper}`}>
        <p className={`mb-1 ${styles.templatePara}`}>
          {selectedCategory && selectedCategory.name
            ? selectedCategory.name
            : "Select Category"}
        </p>

        {selectedCategory.id &&
        isLoading === true &&
        templateIds.length === 0 ? (
          <>
            <Loader />
          </>
        ) : (
          <>
            <InfiniteScroll
              style={{ paddingTop: "20px", height: "51vh" }}
              loadMore={fetchNextPage}
              hasMore={hasNextPage}
              initialDataLoaded={true}
            >
              {errorMessage
                ? errorMessage
                : templateIds.map((templateId: any) => {
                    const template = templates[templateId];
                    return (
                      <>
                        <TemplateComp
                          template={template}
                          display={"d-none"}
                          setSelectedTemplateData={(template: any) => {
                            setSelectedTemplateData(template);
                          }}
                          onTemplateSelect={(template: string) => {
                            onTemplateSelect(template);
                          }}
                        />
                        {/* {template.previouslyUsedTicketId ? (
                          <>
                            {" "}
                            <div className="mb-1 ps-3">
                              <span className={`${styles.prevData}`}>
                                Previously Used In{" "}
                                <span className={`${styles.ticketNum}`}>
                                  Ticket{" "}
                                  {template.previouslyUsedTicketId
                                    ? template.previouslyUsedTicketId
                                    : "NA"}{" "}
                                </span>
                              </span>
                            </div>
                          </>
                        ) : (
                          <div className="mb-2"></div>
                        )} */}
                      </>
                    );
                  })}
            </InfiniteScroll>
          </>
        )}
      </div>
    </div>
  );
};

export default ResultTemplates;
