/**
 * This file is the service file used for making api request.
 * It contains the resetKnoweledgeBaseImages function service which is explained below.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants"; // this is used for testing purpose only for frontend.
import { ImageType } from "./replaceThemeImages.service";

interface resetThemeImagesPayload {
  integrationId: number | string;
  themeId: number | string;
  type: ImageType;
}

/**
 * For testing frontend with own api link if backend is not prepared.
 */
const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/resetKnoweledgeBaseImages`
  : "/api/chatSetting/resetThemeImages";

/**
 * This service is used to reset the KB theme.
 */
export const resetKnoweledgeBaseImages = async (
  params: resetThemeImagesPayload
) => {
  const { data: res } = await axiosJSON.post(apiEndPoint, params);
  return res.data;
};
