import { getAllLiveChatIntegrations, GetAllliveChatIntegrationsParam, LiveChatIntegrationList } from "src/services/LiveChat/Settings/getAllLiveChatIntegrations";
import { RootState } from "src/store/store";

// thunk that fetches all LiveChatIntegrationss
const fetchAllliveChatIntegrationsThunk = async(
  payload:undefined,
  {getState}: {getState: Function}
) =>{
  try{
    const currentState: RootState = getState();
    const queryData: GetAllliveChatIntegrationsParam = {
      start: currentState.liveChatIntegrations.liveChatIntegrationsIdList.length,
      limit: currentState.liveChatIntegrations.fetchLiveChatIntegrationsLimit
    }

    const LiveChatIntegrationsData: LiveChatIntegrationList = await getAllLiveChatIntegrations(queryData);
    console.log("---------------LiveChatIntegrationsSettingThunk ------------ ");
    console.log("Goals data ::: "+ JSON.stringify(LiveChatIntegrationsData));
    return {
      liveChatIntegrationsData: LiveChatIntegrationsData.integrations,
      liveChatIntegrationsIdList: LiveChatIntegrationsData.integrationIds,
      count: LiveChatIntegrationsData.metaData.count,
      totalCount: LiveChatIntegrationsData.metaData.total,
    }

  }catch(error){
    throw error;
  }
};


export default { fetchAllliveChatIntegrationsThunk };