import { Dropdown } from "react-bootstrap";
import { ConditionTypes } from "src/store/slices/initiateReturn/Helpers/returnExchange.helpers";
import { ReturnReason } from "src/store/slices/initiateReturn/step14Configuration.slice";
import styles from "./VariableSelect.module.scss";

// This function is used to extract an object from an event.
// It takes an event object as a parameter and returns an object with a "target" property.
// The "target" property contains a "value" property which is extracted from the event's target text.
export function extractObjectFromEvent(e: any) {
  const value = e.target.text;
  return {
    target: {
      value: value,
    },
  };
}

function VariableSelect(props: {
  id: string;
  condition: ConditionTypes | ReturnReason;
  variablesName: ReadonlyArray<string>;
  updateConditon: Function;
  pushCurrentHash: Function;
  selectedVariableName: string;
  showPlaceholder?: boolean;
  errorMessage?: string | null;
}) {
  return (
    <div className="d-flex flex-row">
      <Dropdown>
        <Dropdown.Toggle
          className={`d-flex justify-content-between ${styles.mainSelect} ${
            props.errorMessage ? "border border-danger" : ""
          }}`}
        >
          {/* Showing first value from array of variables names when selected variable is "" */}
          {props.selectedVariableName !== "" ? (
            props.selectedVariableName
          ) : props.showPlaceholder ? (
            <p className={`${styles.placeholder} m-0 p-0`}>Select </p>
          ) : (
            props.variablesName[0]
          )}
        </Dropdown.Toggle>

        <Dropdown.Menu className={`${styles.mainSelectMenu} mt-1`}>
          {props?.showPlaceholder === true ? (
            <Dropdown.Item
              key="blankChoice"
              hidden
              onClick={(e) => {
                const event = extractObjectFromEvent(e); // This function is used to extract an object from an event.
                props.updateConditon(props.id, event, props.condition);
                props.pushCurrentHash(props.id);
              }}
            >
              Select Options
            </Dropdown.Item>
          ) : null}
          {props.variablesName.map((vname: any, idx) => {
            if (vname instanceof Object) {
              return (
                <Dropdown.Item
                  key={vname.name}
                  value={vname.name}
                  onClick={(e: any) => {
                    const event = extractObjectFromEvent(e); // This function is used to extract an object from an event.
                    props.updateConditon(props.id, event, props.condition);
                    props.pushCurrentHash(props.id);
                  }}
                  className={`${styles.dropdownItem}`}
                >
                  {vname.name}
                </Dropdown.Item>
              );
            } else {
              return (
                <Dropdown.Item
                  key={vname}
                  value={vname}
                  onClick={(e: any) => {
                    const event = extractObjectFromEvent(e); // This function is used to extract an object from an event.
                    props.updateConditon(props.id, event, props.condition);
                    props.pushCurrentHash(props.id);
                  }}
                  className={`${styles.dropdownItem}`}
                >
                  {vname}
                </Dropdown.Item>
              );
            }
          })}
        </Dropdown.Menu>
      </Dropdown>
      <div className={`${styles.errorStyle} ps-2`}>
        {props.errorMessage ? props.errorMessage : null}
      </div>
    </div>
  );
}

export default VariableSelect;
