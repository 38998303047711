import styles  from "./LinkExpired.module.scss";

const LinkExpired = () => {
  return(
    <div className={`${styles.linkExpiredMain}`}>
    <section className={`${styles.linkExpiredPage}`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="sign-up-headings text-center">
              <h1 className={`${styles.linkExpiredText}`}>Link expired</h1>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  );
}

export default LinkExpired;