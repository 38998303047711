import AdditionalOptionList from "../AdditionalOptionList/AdditionalOptionList";

export const ATTACHEMENT_SUPPORTED_IMG_TYPES = [
  "png",
  "jpg",
  "jpeg",
  "bmp",
  "ico",
  "svg",
  "tiff",
  "webp",
];
export const ATTACHEMENT_SUPPORTED_VIDEO_TYPES = [
  "mp4",
  "mkv",
  "avi",
  "mpg",
  "mpeg",
  "webm",
];
export const ATTACHEMENT_SUPPORTED_AUDIO_TYPES = [
  "mp3",
  "wav",
  "mpa",
  "wma",
  "ogg",
  "midi",
  "mid",
];
export const ATTACHEMENT_SUPPORTED_COMPRESSION_TYPES = [
  "rar",
  "zip",
  "7z",
  "tar",
  "tar.gz",
];
export const ATTACHEMENT_SUPPORTED_DOC_TYPES = [
  "txt",
  "rtf",
  "pdf",
  "doc",
  "docx",
  "odt",
  "tex",
  "wpd",
  "xls",
  "xlsx",
  "ods",
  "xlsm",
  "csv",
  "ppt",
  "pptx",
  "odp",
  "pps",
  "xml",
  "log",
];
export const ATTACHEMENT_SUPPORTED_TYPES = [
  ...ATTACHEMENT_SUPPORTED_IMG_TYPES,
  ...ATTACHEMENT_SUPPORTED_VIDEO_TYPES,
  ...ATTACHEMENT_SUPPORTED_AUDIO_TYPES,
  ...ATTACHEMENT_SUPPORTED_COMPRESSION_TYPES,
  ...ATTACHEMENT_SUPPORTED_DOC_TYPES,
];

export const isFileAttachmentSupported = (type: string) => {
  return ATTACHEMENT_SUPPORTED_TYPES.includes(type.toLowerCase());
};

export const isFileImgSupported = (type: string) => {
  return ATTACHEMENT_SUPPORTED_IMG_TYPES.includes(type.toLowerCase());
};

function AttachmentPicker({
  additionalOptions,
  handleAddAttachmentClick,
}: any) {
  return additionalOptions && additionalOptions.length !== 0 ? (
    <AdditionalOptionList
      additionalOptions={[
        ...additionalOptions,
        {
          label: (
            <>
              Upload from your device <br />
              <span>Maximum file size 20MB</span>
            </>
          ),
          onClick: handleAddAttachmentClick,
          showLogo: true,
          hide: false,
        },
      ]}
    />
  ) : (
    <AdditionalOptionList
      additionalOptions={[
        {
          label: (
            <>
              Upload from your device <br />
              <span>Maximum file size 20MB</span>
            </>
          ),
          onClick: handleAddAttachmentClick,
          showLogo: true,
          hide: false,
        },
      ]}
    />
  );
}

export default AttachmentPicker;
