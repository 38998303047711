import { IGetAllOrders } from "./allOrders.slice";
import { initialState } from "./allOrders.slice";

const resetAllOrders = (state: IGetAllOrders) => {
  state = { ...initialState };
  return state;
};

// only resets some specific data
const resetAllOrdersSpecificData = (state: IGetAllOrders) => {
  state.orders = [];
  state.total = null;
  state.count = 0;
  state.currentPage = 1;
  state.totalPage = null;

  return state;
};

const setCurrentPage = (
  state: IGetAllOrders,
  { payload }: { payload: any }
) => {
  state.currentPage = payload.currentPage;
};

const setSearchTerm = (
  state: IGetAllOrders,
  { payload }: { payload: { serachTerm: string } }
) => {
  state.serachTerm = payload.serachTerm;
};

const setSortBy = (
  state: IGetAllOrders,
  { payload }: { payload: { sortBy: string } }
) => {
  state.sortBy = payload.sortBy;
};

export default {
  resetAllOrders,
  setCurrentPage,
  setSearchTerm,
  resetAllOrdersSpecificData,
  setSortBy
};
