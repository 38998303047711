import { axiosJSON } from "src/globals/axiosEndPoints";
interface SocialLogin {
  name: string;
  icon: string;
  route: string;
}
export interface InitialConfig {
  siteKey: string;
  reCaptchaEnabled: boolean;
  socialLogins?: Array<SocialLogin>;
}
interface GetInitialConfigParams {
  context?: "login" | "signup";
}
export async function getInitialConfig(params?: GetInitialConfigParams) {
  const { data } = await axiosJSON.get(`/api/getInitialConfig`, { params });
  return data.data as InitialConfig;
}
