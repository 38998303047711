import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  selectTicketCellData,
  updateTicketCellDataByKey
} from "src/store/slices/tickets/tickets.slice";
import { TableCellProps } from "..";
import log from "loglevel";
import DraftStatus from "src/components/DraftStatus";

function ShowDraftStatus({ ticketId, colKeys }: TableCellProps) {
  const dispatch = useAppDispatch();

  const ticketCellData: any = useAppSelector((state) =>
    selectTicketCellData(state, ticketId, colKeys)
  );

  const handleOnSelectStatus = (value: any) => {
    if (value === "pending") {
    } else if (value === "rejected") {
    } else {
      const payload = {
        ticketId,
        colKey: colKeys[0],
        cellData: {
          ...value,
          msgId: ticketCellData[1] ? ticketCellData[1].messageId : null
        }
      };
      // log.debug({ payload });
      dispatch(updateTicketCellDataByKey(payload));
    }
  };

  return (
    <DraftStatus
      ticketId={ticketId}
      currentStatus={ticketCellData[0]}
      onStatusChange={handleOnSelectStatus}
      messageId={ticketCellData[1]?.messageId}
    />
  );
}

export default ShowDraftStatus;
