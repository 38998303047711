import { useCallback } from "react";
import styles from "./ResumeHeader.module.scss";

/**
 * Resume Header Component used in First Login KB Preview
 */
const ResumeHeader = () => {
  /**
   * Handler for marking Kb setup complted and go to next step
   */
  const onClickHandler = useCallback(() => {
    // Go to the next step
    window.open(
      (process.env.REACT_APP_FIRST_LOGIN_ROUTE ?? "/") +
        "/configure/aiBotSetup",
      "_blank"
    );
  }, []);

  return (
    <div className={`${styles.resumeHeader}`}>
      {/* Message */}
      <span className={`${styles.resumeSpan}`}>Resume where you left off!</span>

      {/* Resume Button */}
      <button
        className={`ms-2 ${styles.resumeBtn} px-3`}
        onClick={onClickHandler}
      >
        Resume{" "}
        <span>
          <i className="fa-solid fa-arrow-right"></i>
        </span>
      </button>
    </div>
  );
};

export default ResumeHeader;
