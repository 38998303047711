import { useQuery } from "@tanstack/react-query";
import { useCallback, useMemo, useRef } from "react";
import getAllMentionsData from "src/services/TicketService/InternalChatBot/getAllMentions";
import { useAppSelector } from "src/store/store";

const useCustomMentions = (brandEmail?: string) => {
  const { brands } = useAppSelector(
    (state) => state.globals.brandSignatureData,
  );

  const brandId = useMemo(() => {
    const brand = brands?.find((val) => val.email === brandEmail);

    return brand ? brand.id + "" : null;
  }, [brandEmail, brands]);

  const { data, isLoading, error } = useQuery(
    ["mentions/getAllMentionsData", brandId],
    {
      queryFn: () => getAllMentionsData({ start: 0, limit: 1000, brandId }),
      staleTime: 60000,
      cacheTime: 60000,
    },
  );

  const currentState = useRef({
    data,
    isLoading,
    error,
  });

  currentState.current.data = data;
  currentState.current.isLoading = isLoading;
  currentState.current.error = error;

  const fetchAllMentions = useCallback(async () => {
    if (currentState.current.isLoading) return [];
    if (currentState.current.error) return [];
    return currentState.current.data;
  }, []);

  return {
    data,
    isLoading,
    error,
    fetchAllMentions,
  };
};

export default useCustomMentions;
