import { stat } from "fs";
import { EReturnAutomationQuestionKeys } from "src/enums/EReturnAutomationQuestionKeys";
import { IReturnIntegrationData } from "src/services/UiAutomation/ReturnExchange/getAllReturnIntegrations";
import {
  ActiveModal,
  AUTOMATION_STEP_CONFIG_STATUS,
  AUTOMATION_STEP_STATUS,
  IActiveSubModal,
  IAntiReturnStategiesOption,
  IIsConfigured,
  initialState,
  IResolutionOffersValue,
  IRestockingFee,
  IReturnReason,
  IStepQuestion,
  ReturnExchangeInterface,
  StepPreviewModalData,
} from "./retrunExchange.slice";
import { v4 as uuid } from "uuid";
import { AnyArray, WritableDraft } from "immer/dist/internal";

type ReturnExchangeInterfaceW = WritableDraft<ReturnExchangeInterface>;

// reset all steps data
const resetAllStepsData = (state: ReturnExchangeInterfaceW) => {
  return { ...initialState };
};

// to move to next step
const moveToNextStep = (
  state: ReturnExchangeInterfaceW,
  {
    payload,
  }: {
    payload: {
      moveFromStep: number | undefined;
      nextStepTransferedStatus?: boolean;
    };
  },
) => {
  let { moveFromStep } = payload;
  // currenty selected step
  // let selectedStepNumber = state.selectedStep;
  // step from which, move to next is clicked
  let selectedStepNumber = moveFromStep;

  switch (selectedStepNumber) {
    // if selected step is 9, i.e., select related order id
    case 9:
      // get the selected step data
      let stepData = state.step9.stepData;
      // check which step need to be shown next
      // if (
      //   // question 1 is selected as no and
      //   (stepData.stepQuestions[
      //     EReturnAutomationQuestionKeys.isReturnPolicySameForAllItem
      //   ].value as boolean) === false &&
      //   // question 2 is selected as no and
      //   (stepData.stepQuestions[
      //     EReturnAutomationQuestionKeys.isReturnPolicySameForAllOrders
      //   ].value as boolean) === false &&
      //   // question 4 is selected as yes
      //   (stepData.stepQuestions[
      //     EReturnAutomationQuestionKeys.canItemNotEligibleForReturn
      //   ].value as boolean) === true
      // ) {
      // question 1 and 2 is selected as no and question 4 is selected as yes
      // so, show step, 'select the items you want to return'
      // state.step10.showStep = true;
      // state.selectedStep = 10;
      // } else {
      // don't show step 'select the items you want to return'
      // state.step10.showStep = false;
      // instead show step 'select return type'

      if (payload.nextStepTransferedStatus) {
        // step 11 and others are not visible as it is already transfered
        // show go live step
        state.showStepGoLive = true;
      } else {
        state.step11.showStep = true;
        state.selectedStep = 11;
      }

      // }
      break;

    // if selected step is 10, i.e., select items you want to return
    // case 10:
    //   // goto step select return type
    //   state.step11.showStep = true;
    //   state.selectedStep = 11;
    //   break;

    // if selected step is 11, i.e., select return type
    case 11:
      if (payload.nextStepTransferedStatus) {
        // step 12 and others are not visible as it is already transfered
        // show go live step
        state.showStepGoLive = true;
      } else {
        // goto step select return reasons
        // show question 7
        state.step12.stepData.stepQuestions[
          EReturnAutomationQuestionKeys.selectReturnReason
        ].showQuestion = true;
        state.step12.showStep = true;
        state.selectedStep = 12;
      }

      break;

    // if selected step is 12, i.e., select return reasons
    case 12:
      // goto step follow up or return method
      // if question 7 a is selected, show follow up question
      // if (
      //   (state.step12.stepData.stepQuestions[
      //     EReturnAutomationQuestionKeys.selectReturnReason
      //   ].value as string) === "notRequired" &&
      //   state.step12.stepData.stepQuestions[
      //     EReturnAutomationQuestionKeys.isReturnReasonSameForAll
      //   ].value == null
      // ) {
      //   // show follow up questions
      //   state.step13.showStep = true;
      //   state.selectedStep = 13;
      // } else {
      // show step, 'return method

      if (payload.nextStepTransferedStatus) {
        // step 14 and others are not visible as it is already transfered
        // show go live step
        state.showStepGoLive = true;
      } else {
        //checking if
        if (
          state.step12.stepData.stepQuestions[
            EReturnAutomationQuestionKeys.selectReturnReason
          ]?.value === "notRequired"
        ) {
          state.step14.showStep = true;
          state.selectedStep = 14;
          state.step14.stepData.stepQuestions[
            EReturnAutomationQuestionKeys.returnMethodForMajorityOfOrders
          ].showQuestion = true;
        } else {
          state.step13.showStep = true;
          state.selectedStep = 13;
          state.step13.stepData.stepQuestions[
            EReturnAutomationQuestionKeys.antiReturnStrategies
          ].showQuestion = true;
        }
      }

      // }
      break;

    // if selected step is 13, i.e., anti refund strategies
    case 13:
      // show step, 'return method
      state.step14.showStep = true;
      state.selectedStep = 14;
      state.step14.stepData.stepQuestions[
        EReturnAutomationQuestionKeys.returnMethodForMajorityOfOrders
      ].showQuestion = true;
      break;

    // if selected step is 14, i.e., return method
    case 14:
      if (payload.nextStepTransferedStatus) {
        // step 15 and others are not visible as it is already transfered
        // show go live step
        state.showStepGoLive = true;
      } else {
        // show step, order summary
        state.step15.showStep = true;
        state.selectedStep = 15;
        state.step15.stepData.stepQuestions[
          EReturnAutomationQuestionKeys.isRestockingFeeChargedForMajorityOrders
        ].showQuestion = true;
      }

      break;

    // if selected step is 15, i.e., show go live
    case 15:
      // show step, go live
      state.showStepGoLive = true;

      break;
  }
}; // end of moveToNextStep

// update questions data
const updateQuestionStepNine = (
  state: ReturnExchangeInterfaceW,
  { payload }: { payload: { question: IStepQuestion } },
) => {
  let question: IStepQuestion = payload.question;
  if (question && question.key) {
    state.step9.stepData.stepQuestions[question.key] = question;
    if (
      question.showQuestion &&
      question.key ===
        EReturnAutomationQuestionKeys.returnNotEligibleResponseMessage
    ) {
      if (question.value === null || (question.value as string).trim() === "") {
        state.step9.stepData.stepQuestions[question.key].error =
          "Please fill this field";
      } else {
        state.step9.stepData.stepQuestions[question.key].error = null;
        // handle visibility of question
        handleQuestionsVisiblity(state, question);
      }
    }
    if (
      question.showQuestion &&
      question.key === EReturnAutomationQuestionKeys.returnWindow
    ) {
      if (
        question.value &&
        typeof question.value === "object" &&
        (question.value as any).days === 0
      ) {
        state.step9.stepData.stepQuestions[question.key].error =
          "Please fill this field";
      } else {
        question.error = null;
        // handle visibility of question
        handleQuestionsVisiblity(state, question);
      }
    }
  }
};

// update questions data for step 11
const updateQuestionStepEleven = (
  state: ReturnExchangeInterfaceW,
  { payload }: { payload: { question: IStepQuestion } },
) => {
  let question: IStepQuestion = payload.question;
  if (question && question.key) {
    state.step11.stepData.stepQuestions[question.key] = question;

    let resolutionOffers: any = question.value;

    if (
      resolutionOffers.exchangeWithOtherItems === null &&
      resolutionOffers.exchangeWithSameItems === null &&
      resolutionOffers.refundToOrigialPaymentMethod === null &&
      resolutionOffers.refundToStoreCredits === null &&
      resolutionOffers.resolutionOfferMessage?.value?.trim() === ""
    ) {
      question.error = "Please fill this field";
    } else {
      question.error = null;
    }

    // handle visibility of question
    handleQuestionsVisiblity(state, question);
  }
};

// update questions data for step 12
const updateQuestionStepTwelve = (
  state: ReturnExchangeInterfaceW,
  { payload }: { payload: { question: IStepQuestion } },
) => {
  let question: IStepQuestion = payload.question;
  if (question && question.key) {
    state.step12.stepData.stepQuestions[question.key] = question;
    // handle visibility of question
    handleQuestionsVisiblity(state, question);
  }
};

const updateQuestionStepThirteen = (
  state: ReturnExchangeInterfaceW,
  { payload }: { payload: { question: IStepQuestion } },
) => {
  let question: IStepQuestion = payload.question;
  if (question && question.key) {
    state.step13.stepData.stepQuestions[question.key] = question;
    // handle visibility of question
    handleQuestionsVisiblity(state, question);
  }
};

// update questions data for step 14
const updateQuestionStepFourteen = (
  state: ReturnExchangeInterfaceW,
  { payload }: { payload: { question: IStepQuestion } },
) => {
  let question: IStepQuestion = payload.question;
  if (question && question.key) {
    state.step14.stepData.stepQuestions[question.key] = question;
    // handle visibility of question
    handleQuestionsVisiblity(state, question);
  }
};

// update questions data for step 15
const updateQuestionStepFifteen = (
  state: ReturnExchangeInterfaceW,
  { payload }: { payload: { question: IStepQuestion } },
) => {
  let question: IStepQuestion = payload.question;
  if (question && question.key) {
    state.step15.stepData.stepQuestions[question.key] = question;
    // 20 Jan checking price and move to next question only if price is valid
    // const price = (state.step15.stepData.stepQuestions[EReturnAutomationQuestionKeys
    //   .usuallyChargedRestockingFee].value as IRestockingFee).price ?? ""
    // if(price !== "0" && price !== "00" && price !== ""){
    // handle visibility of question
    // handleQuestionsVisiblity(state, question);
    // }
    // Commented the above as it is not handling visibility for isRestockingFeeChargedForMajorityOrders
    handleQuestionsVisiblity(state, question);
  }
};

// function used to update step visibility directly
const handleStepVisibility = (
  state: ReturnExchangeInterfaceW,
  { payload }: { payload: { stepId: number; isVisible: boolean } },
) => {
  let { stepId, isVisible } = payload;

  switch (stepId) {
    case 9:
      state.step9.showStep = isVisible;
      break;
    case 10:
      state.step10.showStep = isVisible;
      break;
    case 11:
      state.step11.showStep = isVisible;
      break;
    case 12:
      state.step12.showStep = isVisible;
      break;
    case 13:
      state.step13.showStep = isVisible;
      break;
    case 14:
      state.step14.showStep = isVisible;
      break;
    case 15:
      state.step15.showStep = isVisible;
      break;
  }
};

// function used to update question visibility directly
const handleSingleQuestionVisibility = (
  state: ReturnExchangeInterfaceW,
  { payload }: { payload: { questionKey: string; stepId: number } },
) => {
  let { stepId, questionKey } = payload;

  switch (stepId) {
    case 9:
      state.step9.stepData.stepQuestions[questionKey].showQuestion = true;
      break;
    case 12:
      state.step12.stepData.stepQuestions[questionKey].showQuestion = true;
      break;
    case 13:
      state.step13.stepData.stepQuestions[questionKey].showQuestion = true;
      break;
  }
};

// helper function for updating visiblity of questions
const handleQuestionsVisiblity = (
  state: ReturnExchangeInterfaceW,
  question: IStepQuestion,
) => {
  let questionKey: string = question.key;

  switch (questionKey) {
    // question 1 is updated, prev. it was question 3
    case EReturnAutomationQuestionKeys.returnWindow:
      if (question.value) {
        // show question two
        state.step9.stepData.stepQuestions[
          EReturnAutomationQuestionKeys.returnNotEligibleResponseMessage
        ].showQuestion = true;
      }
      break;

    // question 2 is updated, new question added
    case EReturnAutomationQuestionKeys.returnNotEligibleResponseMessage:
      if (question.value) {
        // show question three
        state.step9.stepData.stepQuestions[
          EReturnAutomationQuestionKeys.isReturnPolicySameForAllOrders
        ].showQuestion = true;
      }

      break;

    // question 3 is updated
    case EReturnAutomationQuestionKeys.isReturnPolicySameForAllOrders:
      if (question.value === true) {
        // show question four if answered yes
        state.step9.stepData.stepQuestions[
          EReturnAutomationQuestionKeys.isReturnPolicySameForAllItem
        ].showQuestion = true;
        // if question 2 is yes, don't show 'select the items you want to return'
        state.step10.showStep = false;
      }

      break;

    // question 4 is updated
    case EReturnAutomationQuestionKeys.isReturnPolicySameForAllItem:
      if (question.value === true || question.value === false) {
        // show question two
        // state.step9.stepData.stepQuestions[
        //   EReturnAutomationQuestionKeys.isReturnPolicySameForAllOrders
        // ].showQuestion = true;
        // if question 1 is yes, don't show 'select the items you want to return'
        // if (question.value === true) {
        //   state.step10.showStep = false;
        // }
      }

      break;

    // // question 2 is updated
    // case EReturnAutomationQuestionKeys.isReturnPolicySameForAllOrders:
    //   if (question.value === true || question.value === false) {
    //     // show question three
    //     state.step9.stepData.stepQuestions[
    //       EReturnAutomationQuestionKeys.returnWindow
    //     ].showQuestion = true;

    //     // if question 2 is yes, don't show 'select the items you want to return'
    //     if (question.value === true) {
    //       state.step10.showStep = false;
    //     }
    //   }

    //   break;

    // // question 3 is updated
    // case EReturnAutomationQuestionKeys.returnWindow:
    //   if (question.value) {
    //     // show question four
    //     state.step9.stepData.stepQuestions[
    //       EReturnAutomationQuestionKeys.canItemNotEligibleForReturn
    //     ].showQuestion = true;
    //   }
    //   break;

    // question 4 is updated
    // case EReturnAutomationQuestionKeys.canItemNotEligibleForReturn:
    //   if (question.value === true || question.value === false) {
    //     // if config modal is added and configured
    //     // enable next step
    //     // enable step 3
    //     // state.step11.showStep = true; // move to next step by using move to next step btn
    //     state.step11.stepData.stepQuestions[
    //       EReturnAutomationQuestionKeys.resolutionOffer
    //     ].showQuestion = true;

    //     // if question 4 is no, don't show 'select the items you want to return'
    //     if (question.value === false) {
    //       state.step10.showStep = false;
    //     }
    //   }
    //   break;

    // question 5 is updated
    case EReturnAutomationQuestionKeys.resolutionOffer:
      if (question.value) {
        // update the resolution offer not available message textbox
        let resolutionOfferData: IResolutionOffersValue =
          question.value as IResolutionOffersValue;
        // check if any resolution offer is selected or not
        if (
          resolutionOfferData.exchangeWithOtherItems ||
          resolutionOfferData.exchangeWithSameItems ||
          resolutionOfferData.refundToOrigialPaymentMethod ||
          resolutionOfferData.refundToStoreCredits
        ) {
          // one or more options are selected, hide
          if (
            state.step11.stepData.stepQuestions[
              EReturnAutomationQuestionKeys.resolutionOffer
            ].value
          ) {
            state.step11.stepData.stepQuestions[
              EReturnAutomationQuestionKeys.resolutionOffer
            ].value = {
              ...(state.step11.stepData.stepQuestions[
                EReturnAutomationQuestionKeys.resolutionOffer
              ].value as IResolutionOffersValue),
              resolutionOfferMessage: {
                ...(
                  state.step11.stepData.stepQuestions[
                    EReturnAutomationQuestionKeys.resolutionOffer
                  ].value as IResolutionOffersValue
                ).resolutionOfferMessage,
                showQuestion: false,
              },
            };
          }
        } else {
          // no resolution options are selected, show the question
          if (
            state.step11.stepData.stepQuestions[
              EReturnAutomationQuestionKeys.resolutionOffer
            ].value
          ) {
            state.step11.stepData.stepQuestions[
              EReturnAutomationQuestionKeys.resolutionOffer
            ].value = {
              ...(state.step11.stepData.stepQuestions[
                EReturnAutomationQuestionKeys.resolutionOffer
              ].value as IResolutionOffersValue),
              resolutionOfferMessage: {
                ...(
                  state.step11.stepData.stepQuestions[
                    EReturnAutomationQuestionKeys.resolutionOffer
                  ].value as IResolutionOffersValue
                ).resolutionOfferMessage,
                showQuestion: true,
              },
            };
          }
        }
        // show next question
        state.step11.stepData.stepQuestions[
          EReturnAutomationQuestionKeys.isResolutionOfferedSameForAllOrders
        ].showQuestion = true;
      }
      break;

    // question 6 is updated
    case EReturnAutomationQuestionKeys.isResolutionOfferedSameForAllOrders:
      if (question.value) {
        // state.step12.showStep = true; // move to next step by using move to next step btn
        // show question 7
        state.step12.stepData.stepQuestions[
          EReturnAutomationQuestionKeys.selectReturnReason
        ].showQuestion = true;
      }
      break;

    // question 7 is updated
    case EReturnAutomationQuestionKeys.selectReturnReason:
      if (question.value as string) {
        // reset 8th question value
        // state.step12.stepData.stepQuestions[
        //   EReturnAutomationQuestionKeys.returnReasonList
        // ].value = null;

        //  show 8th question, only if 7b or c is selected
        if (
          question.value == "required" ||
          question.value == "requiredAndCustom"
        ) {
          state.step12.stepData.stepQuestions[
            EReturnAutomationQuestionKeys.returnReasonList
          ].showQuestion = true;
          // disable follow up question
          // state.step13.stepData.stepStatus = "disabled";
          // state.step13.showStep = false;
        } else {
          state.step12.stepData.stepQuestions[
            EReturnAutomationQuestionKeys.returnReasonList
          ].showQuestion = false;
          // enable follow up question
          // state.step13.stepData.stepStatus = "pending";
          // state.step13.showStep = true;

          // show step
          // state.step14.showStep = true;
          // show question
          // state.step14.stepData.stepQuestions[
          //   EReturnAutomationQuestionKeys.isReturnMethodSameForAllOrders
          // ].showQuestion = true;
          // state.step14.stepData.stepQuestions[
          //   EReturnAutomationQuestionKeys.returnMethodForMajorityOfOrders
          // ].showQuestion = true;

          // show question 9
          state.step12.stepData.stepQuestions[
            EReturnAutomationQuestionKeys.isReturnReasonSameForAll
          ].showQuestion = true;
        }
      }
      break;

    // question 8 is updated
    case EReturnAutomationQuestionKeys.selectReturnReason:
      break;

    // question 10 is updated
    case EReturnAutomationQuestionKeys.returnMethodForMajorityOfOrders:
      if (question.value as string) {
        // show step
        // state.step14.showStep = true;
        // show question 11,
        state.step14.stepData.stepQuestions[
          EReturnAutomationQuestionKeys.returnMethodDisplayMessage
        ].showQuestion = true;

        // update question 11 value based on question 10 selected answer
        if (question.value == "notRequired") {
          state.step14.stepData.stepQuestions[
            EReturnAutomationQuestionKeys.returnMethodDisplayMessage
          ].value =
            "As per our terms, we offer exchanges/refunds within {return_window} and the customer is not required to ship their order back. So, feel free to keep your order as a courtesy and we would be happy to process your request for you.";
        } else if (question.value == "returnRequired") {
          state.step14.stepData.stepQuestions[
            EReturnAutomationQuestionKeys.returnMethodDisplayMessage
          ].value =
            "As per our terms, we offer exchanges/refunds within {return_window} and the customer is required to ship their order back to us at their own cost. Please ship the order back to the following address and share the return tracking link below so that we can track your return.";
        } else {
          state.step14.stepData.stepQuestions[
            EReturnAutomationQuestionKeys.returnMethodDisplayMessage
          ].value =
            "As per our terms, we offer exchanges/refunds within {return_window} and we cover the cost of your return. Please download the following return label and ship order back using it.";
        }

        // show question 12,
        state.step14.stepData.stepQuestions[
          EReturnAutomationQuestionKeys.isReturnMethodSameForAllOrders
        ].showQuestion = true;
      }
      break;

    // question 10 is updated
    case EReturnAutomationQuestionKeys.antiReturnStrategies:
      if (question.value as IAntiReturnStategiesOption) {
        //  show question 11
        state.step13.stepData.stepQuestions[
          EReturnAutomationQuestionKeys.configureAntiReturnStrategies
        ].showQuestion = true;
      }
      break;

    // question 11 is updated
    case EReturnAutomationQuestionKeys.configureAntiReturnStrategies:
      if (question.value as IAntiReturnStategiesOption) {
        //  show question 12
        state.step13.stepData.stepQuestions[
          EReturnAutomationQuestionKeys.isReturnReasonOrFollowUpQuestionsSame
        ].showQuestion = true;
      }
      break;

    // question 13 is updated
    case EReturnAutomationQuestionKeys.isRestockingFeeChargedForMajorityOrders:
      if (question.value as string) {
        //  show question 14
        state.step15.stepData.stepQuestions[
          EReturnAutomationQuestionKeys.usuallyChargedRestockingFee
        ].showQuestion = true;
      } else {
        //  if question 13 value is no hide question 14,15 and 16
        state.step15.stepData.stepQuestions[
          EReturnAutomationQuestionKeys.usuallyChargedRestockingFee
        ].showQuestion = false;
        state.step15.stepData.stepQuestions[
          EReturnAutomationQuestionKeys.restockingFeeDisplayMessage
        ].showQuestion = false;
        state.step15.stepData.stepQuestions[
          EReturnAutomationQuestionKeys.isRestockingFeeSameForAll
        ].showQuestion = false;
        state.step15.stepData.stepQuestions[
          EReturnAutomationQuestionKeys.isRestockingFeeSameForAll
        ].value = null;
      }
      break;

    // question 14 is updated
    case EReturnAutomationQuestionKeys.usuallyChargedRestockingFee:
      if (question.value as string) {
        // checking price and move to next question only if price is valid
        const price =
          (
            state.step15.stepData.stepQuestions[
              EReturnAutomationQuestionKeys.usuallyChargedRestockingFee
            ].value as IRestockingFee
          ).price ?? "";
        if (price !== "0" && price !== "00" && price !== "") {
          // handle visibility of question
          //  show question 15
          state.step15.stepData.stepQuestions[
            EReturnAutomationQuestionKeys.restockingFeeDisplayMessage
          ].showQuestion = true;
        }
      }
      break;

    // question 15 is updated
    case EReturnAutomationQuestionKeys.restockingFeeDisplayMessage:
      if (question.value as string) {
        //  show question 16
        state.step15.stepData.stepQuestions[
          EReturnAutomationQuestionKeys.isRestockingFeeSameForAll
        ].showQuestion = true;
      }
      break;
  }
};

// update full automate/partially automate status in step 3
const setAutomateType = (
  state: ReturnExchangeInterfaceW,
  {
    payload,
  }: {
    payload: {
      automationType?: "fullyAutomate" | "partial";
      selectedBrandId?: number;
    };
  },
) => {
  if (payload.automationType !== undefined) {
    //checking if payload fully automate is set
    if (payload.automationType == "fullyAutomate") {
      //updating fullyAutoMate to true and resetting others
      state.step7.stepData.fullyAutoMate = true;
      state.step7.stepData.partiallyAutomate = false;
    } else {
      //updating partiallyAutomate to true and resetting others
      state.step7.stepData.partiallyAutomate = true;
      state.step7.stepData.fullyAutoMate = false;
    }
  }

  if (payload.selectedBrandId !== undefined) {
    state.step7.selectedBrandId = payload.selectedBrandId;
  }
};

//function to set step preview modal data, like its content and modal visibility
const setStepPreviewModalData = (
  state: ReturnExchangeInterfaceW,
  { payload }: { payload: StepPreviewModalData },
) => {
  state.stepConfiguredPreviewModal = payload;
};

const resetStepPreviewModalData = (state: ReturnExchangeInterfaceW) => {
  state.stepConfiguredPreviewModal = {
    showModal: false,
    isConfigured: false,
    title: "",
    subMessage: "",
    isTransfered: false,
    stepType: "select",
  };
};

//reducer function to reset integrations data, it is used in return integrations list
const returnIntegrationData = (state: ReturnExchangeInterfaceW) => {
  state.integrationIds = [];
  state.integrationMetaData = { total: 0, count: 0 };
  state.integrations = {};
};

//function update active integration id in redux slice
const setActiveIntegrationId = (
  state: ReturnExchangeInterfaceW,
  { payload }: { payload: { integrationId: number } },
) => {
  state.activeIntegrationId = payload.integrationId;
};

//function update active integration data in redux slice
const setActiveIntegrationData = (
  state: ReturnExchangeInterfaceW,
  { payload }: { payload: IReturnIntegrationData },
) => {
  state.activeIntegrationData = payload;
};

//function update selected stepId
const setSelectedStepId = (
  state: ReturnExchangeInterfaceW,
  { payload }: { payload: number },
) => {
  state.selectedStep = payload;
};

// used to discard the changes made
const discardTheStepChanges = (state: ReturnExchangeInterfaceW) => {
  // get the initial data
  const initialData = state.initialData;

  // data for step 9
  if (initialData.step9) {
    state.step9.stepData.stepQuestions = {
      ...initialData.step9.stepData.stepQuestions,
    };
  }

  // data for step 10
  if (initialData.step10) {
    state.step10.stepData.stepQuestions = {
      ...initialData.step10.stepData.stepQuestions,
    };
  }

  // data for step 11
  if (initialData.step11) {
    state.step11.stepData.stepQuestions = {
      ...initialData.step11.stepData.stepQuestions,
    };
  }

  // data for step 11
  if (initialData.step11) {
    state.step11.stepData.stepQuestions = {
      ...initialData.step11.stepData.stepQuestions,
    };
  }

  // data for step 12
  if (initialData.step12.stepData.stepQuestions) {
    state.step12.stepData.stepQuestions = {
      ...initialData.step12.stepData.stepQuestions,
    };
  }

  // data for step 13
  if (initialData.step13) {
    state.step13.stepData.stepQuestions = {
      ...initialData.step13.stepData.stepQuestions,
    };
  }

  // data for step 14
  if (initialData.step14) {
    state.step14.stepData.stepQuestions = {
      ...initialData.step14.stepData.stepQuestions,
    };
  }

  // data for step 15
  if (initialData.step15) {
    state.step15.stepData.stepQuestions = {
      ...initialData.step15.stepData.stepQuestions,
    };
  }
};

// function used to add the initialData
const addInitialData = (state: ReturnExchangeInterfaceW) => {
  let initialDataTemp = {
    // add data of step 9
    step9: {
      ...state.step9,
    },
    // add data of step 10
    step10: {
      ...state.step10,
    },
    // add data of step 11
    step11: {
      ...state.step11,
    },
    // add data of step 12
    step12: {
      ...state.step12,
    },
    // add data of step 13
    step13: {
      ...state.step13,
    },
    // add data of step 14
    step14: {
      ...state.step14,
    },
    // add data of step 15
    step15: {
      ...state.step15,
    },
  };

  // update the initial data fetched
  state.initialData = initialDataTemp;
};

//function which checks if all step apis loaded, the updates the global loader state to false if all completed
const updateReturnGlobalLoader = (state: ReturnExchangeInterfaceW) => {
  //checking if step 9 - 14 is loaded
  if (
    state.step9.fetchStepDataAjaxStatus === "fulfilled" &&
    state.step10.fetchStepDataAjaxStatus === "fulfilled" &&
    state.step11.fetchStepDataAjaxStatus === "fulfilled" &&
    state.step12.fetchStepDataAjaxStatus === "fulfilled" &&
    state.step13.fetchStepDataAjaxStatus === "fulfilled" &&
    state.step14.fetchStepDataAjaxStatus === "fulfilled"
  ) {
    state.isAllStepFetchedInitially = true;
    // save the intial data
    addInitialData(state);
  }
};

//reducer function to reset integrations data, it is used in return integrations list
const resetStepsData = (state: ReturnExchangeInterfaceW) => {
  state.step7 = initialState.step7;
  state.step9 = initialState.step9;
  state.step10 = initialState.step10;
  state.step11 = initialState.step11;
  state.step12 = initialState.step12;
  state.step13 = initialState.step13;
  state.step14 = initialState.step14;
  state.step15 = initialState.step15;
  state.isAllStepFetchedInitially = initialState.isAllStepFetchedInitially;
};

// Add a Reason
const addReturnReason = (
  state: ReturnExchangeInterfaceW,
  { payload }: { payload: { reason: string } },
) => {
  let returnReasonsList: any =
    state.step12.stepData.stepQuestions[
      EReturnAutomationQuestionKeys.returnReasonList
    ];
  if (returnReasonsList !== undefined) {
    if (
      state.step12.stepData.stepQuestions[
        EReturnAutomationQuestionKeys.returnReasonList
      ].value
    ) {
      (
        state.step12.stepData.stepQuestions[
          EReturnAutomationQuestionKeys.returnReasonList
        ].value as Array<IReturnReason>
      ).push({
        id: uuid(),
        new: true,
        value: payload.reason,
      });
    } else {
      state.step12.stepData.stepQuestions[
        EReturnAutomationQuestionKeys.returnReasonList
      ].value = [
        {
          id: uuid(),
          new: true,
          value: payload.reason,
        },
      ];
    }
    //07 Dec Commented this as one reason need to display by default
    // update visibility of question 9
    // handleSingleQuestionVisibility(state, {
    //   payload: {
    //     questionKey: EReturnAutomationQuestionKeys.isReturnReasonSameForAll,
    //     stepId: 12,
    //   },
    // });
  }
};

//07 Dec Added this to show Q9 only after the text in deafult reason box is changed
const updateQuestionTwelve = (state: ReturnExchangeInterfaceW) => {
  // update visibility of question 9
  handleSingleQuestionVisibility(state, {
    payload: {
      questionKey: EReturnAutomationQuestionKeys.isReturnReasonSameForAll,
      stepId: 12,
    },
  });
};

// to update return reason
const updateReturnReason = (
  state: ReturnExchangeInterfaceW,
  { payload }: { payload: { id: string; value: string } },
) => {
  const { id, value } = payload;

  let returnReasonsList: Array<IReturnReason> = state.step12.stepData
    .stepQuestions[EReturnAutomationQuestionKeys.returnReasonList]
    .value as Array<IReturnReason>;

  const reasonToUpdateIndex = returnReasonsList?.findIndex(
    (reason) => reason.id == id,
  );

  if (
    reasonToUpdateIndex !== -1 &&
    returnReasonsList &&
    returnReasonsList[reasonToUpdateIndex]
  ) {
    const reason = returnReasonsList[reasonToUpdateIndex];

    (
      state.step12.stepData.stepQuestions[
        EReturnAutomationQuestionKeys.returnReasonList
      ].value as Array<IReturnReason>
    )[reasonToUpdateIndex] = {
      ...reason,
      value: value,
      error: value.trim().length === 0 ? "error" : undefined,
    };
  }
};

const reOrderReturnReason = (
  state: ReturnExchangeInterfaceW,
  { payload }: { payload: { startIndex: number; endIndex: number } },
) => {
  const { startIndex, endIndex } = payload;

  let returnReasonsList: Array<IReturnReason> = state.step12.stepData
    .stepQuestions[EReturnAutomationQuestionKeys.returnReasonList]
    .value as Array<IReturnReason>;
  const [removed] = returnReasonsList.splice(startIndex, 1);
  (
    state.step12.stepData.stepQuestions[
      EReturnAutomationQuestionKeys.returnReasonList
    ].value as Array<IReturnReason>
  ).splice(endIndex, 0, removed);
};

// Delete Reason
const deleteReturnReason = (
  state: ReturnExchangeInterfaceW,
  { payload }: { payload: { id: string } },
) => {
  const { id } = payload;

  let returnReasonsList: Array<IReturnReason> = state.step12.stepData
    .stepQuestions[EReturnAutomationQuestionKeys.returnReasonList]
    .value as Array<IReturnReason>;
  state.step12.stepData.stepQuestions[
    EReturnAutomationQuestionKeys.returnReasonList
  ].value = returnReasonsList.filter((reason: any) => reason.id !== id) ?? [];
};

//used to update published in active integration data
const setBrandAndPublishedStatus = (
  state: ReturnExchangeInterfaceW,
  { payload }: { payload: { published: boolean; brand?: any } },
) => {
  state.activeIntegrationData.published = payload.published;
  if (payload.brand) {
    state.activeIntegrationData.brand = payload.brand;
  }
};

// function used to update step status
const updateStepStatus = (
  state: ReturnExchangeInterfaceW,
  { payload }: { payload: { stepId: number; status: AUTOMATION_STEP_STATUS } },
) => {
  let { stepId, status } = payload;
  switch (stepId) {
    case 9:
      state.step9.stepData.stepStatus = status;
      break;
    case 10:
      state.step10.stepData.stepStatus = status;
      break;
    case 11:
      state.step11.stepData.stepStatus = status;
      break;
    case 12:
      state.step12.stepData.stepStatus = status;
      break;
    case 13:
      state.step13.stepData.stepStatus = status;
      break;
    case 14:
      state.step14.stepData.stepStatus = status;
      break;
    case 15:
      state.step15.stepData.stepStatus = status;
      break;
  }
};

const updateStepConfigStatus = (
  state: ReturnExchangeInterfaceW,
  {
    payload,
  }: { payload: { stepId: number; status: AUTOMATION_STEP_CONFIG_STATUS } },
) => {
  let { stepId, status } = payload;
  switch (stepId) {
    case 9:
      state.step9.stepData.stepQuestions[
        EReturnAutomationQuestionKeys.isReturnPolicySameForAllOrders
      ].error = null;
      state.step9.stepData.configStatus = status;
      break;
    case 10:
      state.step10.stepData.stepQuestions[
        EReturnAutomationQuestionKeys.isReturnPolicySameForAllItem
      ].error = null;
      state.step10.stepData.configStatus = status;
      break;
    case 11:
      state.step11.stepData.stepQuestions[
        EReturnAutomationQuestionKeys.isResolutionOfferedSameForAllOrders
      ].error = null;
      state.step11.stepData.configStatus = status;
      break;
    case 12:
      state.step12.stepData.stepQuestions[
        EReturnAutomationQuestionKeys.isReturnReasonSameForAll
      ].error = null;
      state.step12.stepData.configStatus = status;
      break;
    case 13:
      state.step13.stepData.stepQuestions[
        EReturnAutomationQuestionKeys.isReturnReasonOrFollowUpQuestionsSame
      ].error = null;
      state.step13.stepData.configStatus = status;
      break;
    case 14:
      state.step14.stepData.stepQuestions[
        EReturnAutomationQuestionKeys.isReturnMethodSameForAllOrders
      ].error = null;
      state.step14.stepData.configStatus = status;
      break;
    case 15:
      state.step15.stepData.stepQuestions[
        EReturnAutomationQuestionKeys.isRestockingFeeSameForAll
      ].error = null;
      state.step15.stepData.configStatus = status;
      break;
  }
};

// Action to update isConfigured status for Question configureAntiReturnStrategies
const updateAntiRefundModalStatus = (
  state: ReturnExchangeInterfaceW,
  { payload }: { payload: { status: boolean } },
) => {
  let { status } = payload;
  (
    state.step13.stepData.stepQuestions[
      EReturnAutomationQuestionKeys.configureAntiReturnStrategies
    ].value as IIsConfigured
  ).isConfigured = status;
  state.step13.stepData.stepQuestions[
    EReturnAutomationQuestionKeys.configureAntiReturnStrategies
  ].error = null;
};

//used to update published in active integration data
const setShowPreviewer = (
  state: ReturnExchangeInterfaceW,
  { payload }: { payload: { showNotEligible: boolean } },
) => {
  state.showPreview.showNotEligible = payload.showNotEligible;
};

//used to update published in active integration data
const setGoLiveVisiblity = (
  state: ReturnExchangeInterfaceW,
  { payload }: { payload: { show: boolean } },
) => {
  state.showStepGoLive = payload.show;
};

function validateQuestion(
  question: IStepQuestion,
  configStatus: Record<string, "pending" | "completed">,
) {
  if (
    question.showQuestion &&
    (question.value === null || question.value === "" || question.value === 0)
  ) {
    question.error = "Please fill this field";
  } else {
    question.error = null;
  }

  if (
    question.showQuestion &&
    question.key === EReturnAutomationQuestionKeys.returnWindow
  ) {
    if (
      question.value &&
      typeof question.value === "object" &&
      (question.value as any).days === 0
    ) {
      question.error = "Please fill this field";
    } else {
      question.error = null;
    }
  } else if (question.showQuestion && Array.isArray(question.value)) {
    if (question.value.length === 0) {
      question.error = "Please fill this field";
    } else {
      question.error = null;
    }

    if (question.key === EReturnAutomationQuestionKeys.returnReasonList) {
      const isChildEmpty = (question.value as IReturnReason[]).some(
        (reason) => reason.value === null || reason.value.trim() === "",
      );
      if (isChildEmpty) {
        question.error = "Please fill this field";
      }
    }
  } else if (question.showQuestion && question.key in configStatus) {
    // For configure type Questions
    if (question?.value === true || question?.value === false) {
      if (
        question.value === false &&
        configStatus[question.key] === "pending"
      ) {
        question.error = "Please complete configuration";
      }
    } else {
      question.error = "Please fill this field";
    }
  } else if (
    question.showQuestion &&
    question.value &&
    question.key === EReturnAutomationQuestionKeys.resolutionOffer
  ) {
    let resolutionOffers: any = question.value;
    if (
      resolutionOffers.exchangeWithOtherItems === null &&
      resolutionOffers.exchangeWithSameItems === null &&
      resolutionOffers.refundToOrigialPaymentMethod === null &&
      resolutionOffers.refundToStoreCredits === null &&
      resolutionOffers.resolutionOfferMessage?.value?.trim() === ""
    ) {
      question.error = "Please fill this field";
    } else {
      question.error = null;
    }
  } else if (
    question.showQuestion &&
    question.value &&
    question.key === EReturnAutomationQuestionKeys.usuallyChargedRestockingFee
  ) {
    let values: any = question.value;
    if (!values.price || values.price == 0) {
      question.error = "Please fill this field";
    } else {
      question.error = null;
    }
  } else if (
    question.showQuestion &&
    question.key === EReturnAutomationQuestionKeys.antiReturnStrategies
  ) {
    const {
      offerAnExchange,
      offerSomeSuggestion,
      offerSuggestionExtendReturnDeadline,
      offerDiscountOnFutureOrder,
    } = question.value as IAntiReturnStategiesOption;
    if (
      !offerAnExchange &&
      !offerSomeSuggestion &&
      !offerSuggestionExtendReturnDeadline &&
      !offerDiscountOnFutureOrder
    ) {
      question.error = "Please select at least one strategy";
    } else {
      question.error = null;
    }
  } else if (
    question.showQuestion &&
    question.key === EReturnAutomationQuestionKeys.configureAntiReturnStrategies
  ) {
    const { isConfigured } = question.value as IIsConfigured;
    if (isConfigured === false) {
      question.error = "Please configure anti refund strategies";
    } else {
      question.error = null;
    }
  } else if (
    question.showQuestion &&
    question.value &&
    typeof question.value === "object"
  ) {
    const allChildValuesNull = Object.values(question.value).every(
      (childValue) => childValue === null || childValue === "",
    );
    if (allChildValuesNull) {
      question.error = "Please fill this field";
    } else {
      question.error = null;
    }
  }
}
const validateStepQuestions = (state: ReturnExchangeInterfaceW) => {
  const sliceData = {
    step9: state.step9,
    step10: state.step10,
    step11: state.step11,
    step12: state.step12,
    step13: state.step13,
    step14: state.step14,
    step15: state.step15,
  };
  const configStatus = {
    [EReturnAutomationQuestionKeys.isReturnPolicySameForAllOrders]:
      state.step9.stepData.configStatus,
    [EReturnAutomationQuestionKeys.isReturnPolicySameForAllItem]:
      state.step10.stepData.configStatus,
    [EReturnAutomationQuestionKeys.isResolutionOfferedSameForAllOrders]:
      state.step11.stepData.configStatus,
    [EReturnAutomationQuestionKeys.isReturnReasonSameForAll]:
      state.step12.stepData.configStatus,
    [EReturnAutomationQuestionKeys.isReturnReasonOrFollowUpQuestionsSame]:
      state.step13.stepData.configStatus,
    [EReturnAutomationQuestionKeys.isReturnMethodSameForAllOrders]:
      state.step14.stepData.configStatus,
    [EReturnAutomationQuestionKeys.isRestockingFeeSameForAll]:
      state.step15.stepData.configStatus,
  };

  // Loop through each step
  Object.values(sliceData).forEach((step) => {
    if (step.showStep) {
      // Loop through each step's questions
      Object.values(step.stepData.stepQuestions).forEach((question) => {
        validateQuestion(question, configStatus);
      });
    }
  });
};

//used to update published in active integration data
const setShowValidationErros = (
  state: ReturnExchangeInterfaceW,
  { payload }: { payload: { show: boolean } },
) => {
  state.showErrors = payload.show;
};

const updateReturnIntegrationData = (
  state: ReturnExchangeInterfaceW,
  {
    payload,
  }: {
    payload: {
      integrationId: number | string;
      integration: Partial<IReturnIntegrationData>;
    };
  },
) => {
  if (
    payload.integrationId &&
    state.integrationIds.includes(payload.integrationId)
  ) {
    state.integrations[payload.integrationId] = {
      ...state.integrations[payload.integrationId],
      ...payload.integration,
    };
  }
};

//used for return preview for finding opened modal and submodal
const setActiveModal = (
  state: ReturnExchangeInterfaceW,
  {
    payload,
  }: {
    payload: {
      activeModal: ActiveModal | null;
      activeSubModal: IActiveSubModal | null;
    };
  },
) => {
  state.activeModal = payload.activeModal;
  state.activeSubModal = payload.activeSubModal;
};

export default {
  resetAllStepsData,
  updateQuestionStepNine,
  updateQuestionStepEleven,
  updateQuestionStepTwelve,
  updateQuestionStepThirteen,
  moveToNextStep,
  setAutomateType,
  returnIntegrationData,
  setStepPreviewModalData,
  resetStepPreviewModalData,
  setActiveIntegrationId,
  setActiveIntegrationData,
  setSelectedStepId,
  updateReturnGlobalLoader,
  resetStepsData,
  updateQuestionStepFourteen,
  updateQuestionStepFifteen,
  addReturnReason,
  deleteReturnReason,
  reOrderReturnReason,
  updateReturnReason,
  updateQuestionTwelve,
  handleSingleQuestionVisibility,
  setBrandAndPublishedStatus,
  discardTheStepChanges,
  updateStepStatus,
  handleStepVisibility,
  updateStepConfigStatus,
  setShowPreviewer,
  setGoLiveVisiblity,
  addInitialData,
  validateStepQuestions,
  setShowValidationErros,
  updateReturnIntegrationData,
  updateAntiRefundModalStatus,
  setActiveModal,
};
