import CenterModal from "src/components/CenterModal/CenterModal";
import BaseButton from "../commons/BaseButton/BaseButton";
import styles from "./SendBulkMessage.module.scss";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/store/store";
import useResetBulkActions from "src/hooks/useResetBulkActions";
import ModalFooter from "./children/ModalFooter";
import Loader from "src/components/Loader";
import log from "loglevel";
import { v4 as uuid } from "uuid";
import AddSendMessageSuccess from "./children/AddSendMessageSuccess/AddSendMessageSuccess";
import {
  bulkSendMessage,
  resetBulkSendMessageState,
  setMessage,
} from "src/store/slices/ticketSendMessage/ticketSendMessage.slice";
import { QuillEditor } from "src/components/QuillEditor";
import ParseHTML from "src/components/ParseHTML";
import { replacePTagWithSpan } from "src/utils/utils";
import { TinyMCEEditor } from "src/components/TinyMCEContainer/Children/TinyMCEEditor";

function SendBulkMessage() {
  const [modalShow, setModalShow] = useState(false);
  const [inputFocused, setInputFocused] = useState(false);
  const { message, bulkSendMessageState, integrationFailureTickets } =
    useAppSelector((state) => state.ticketSendMessage);
  const dispatch = useAppDispatch();
  const resetBulkActions = useResetBulkActions();
  //  useEffect(() => () => resetBulkActions(), []);

  //storing the bulkSendMessageState in useRef to avoid stale state
  const bulkSendMessageStateRef = useRef(bulkSendMessageState);

  useEffect(() => {
    //updating useRef with state value, used to avoid stale state
    bulkSendMessageStateRef.current = bulkSendMessageState;
  }, [bulkSendMessageState]);

  const handleOnClick = () => {
    // log.debug("clicked bulk send message tickets button");
    setModalShow(true);
    dispatch(resetBulkSendMessageState());
  };

  const handleOnHideModal = () => {
    setModalShow(false);
    if (bulkSendMessageStateRef.current != "idle") {
      resetBulkActions(); //resetting bulk action filters, refetching the tickets, as new action has been performed
    }
  };

  const handleOnInputChange = (e: any) => {
    dispatch(setMessage(e));
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      dispatch(bulkSendMessage());
    }
  };

  const CustomTitleComp = () => {
    const onHandleClose = (e: any) => {
      setModalShow(false);
    };
    return (
      <div className="d-flex justify-content-between">
        <h5 className={`${styles["addTag_Title"]}`}>
          <span className={`me-2 ${styles.bulkRemarkIcon}`}>
            <i className="fas fa-envelope-square"></i>
          </span>
          Send Bulk message :
        </h5>
        <span
          className={`mx-1 ${styles.commomClose}`}
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={onHandleClose}
        >
          <span className={`${styles.close}`}>
            <i className="fa-solid fa-xmark"></i>
          </span>
        </span>
      </div>
    );
  };
  const ParseButtonTextHTML = ({ html }: { html: string }) => {
    const htmlRef: any = useRef(null);
    useEffect(() => {
      if (htmlRef.current) {
        htmlRef.current.innerHTML = html.replace(/<[^>]*script[^>]*>/g, "");
      }
    }, [html]);
    return <span ref={htmlRef}></span>;
  };
  const editorRef = useRef(null as any);
  return (
    <>
      <BaseButton
        FAIcon={"fas fa-envelope-square"}
        BtnText={
          bulkSendMessageState === "fulfilled" ? (
            <>
              <ParseButtonTextHTML html={replacePTagWithSpan(message)} />
            </>
          ) : (
            "Send Bulk Message"
          )
        }
        BtnAction={handleOnClick}
        Selected={modalShow}
        style={{ maxWidth: "180px" }}
        id="sendMsg"
      />
      <CenterModal
        show={modalShow}
        onHide={handleOnHideModal}
        customFooterComp={ModalFooter}
        id="sendMsgModal"
        customTitleComp={
          bulkSendMessageState === "fulfilled" ||
          (bulkSendMessageState === "rejected" && integrationFailureTickets)
            ? undefined
            : CustomTitleComp
        }
        dialogClassName={`${
          bulkSendMessageState === "fulfilled" ||
          (bulkSendMessageState === "rejected" && integrationFailureTickets)
            ? ""
            : styles.dialogClassName
        }`}
        contentClassName={`${
          bulkSendMessageState === "fulfilled" ||
          (bulkSendMessageState === "rejected" && integrationFailureTickets)
            ? ""
            : styles.contentClassName
        }`}
      >
        {bulkSendMessageState === "idle" && (
          <div
            className={`${styles.bulkRemarkMain} ${
              inputFocused ? `${styles.focused}` : ""
            } h-100`}
            id="sendMsgEditor"
          >
            <TinyMCEEditor
              editorRef={editorRef}
              uniqueID={uuid()}
              className={`${styles.bulkRamarkTextArea} ${
                styles.bulkRemarkSub
              } h-100 ${inputFocused ? `${styles.focused}` : ""}`}
              editorClassName={`h-100`}
              options={{
                min_height: 296,
                max_height: 296,
                placeholder: "Type your text here...",
              }}
              // placeholder={`Type here...`}
              showToolbar={false}
              onChange={handleOnInputChange}
              value={message}
              onFocus={() => setInputFocused(true)}
              onBlur={() => setInputFocused(false)}
            />
          </div>
        )}

        {bulkSendMessageState === "pending" && (
          <Loader style={{ marginTop: "-90px" }} />
        )}

        {(bulkSendMessageState === "fulfilled" ||
          bulkSendMessageState === "rejected") && (
          <AddSendMessageSuccess bulkSendMessageState={bulkSendMessageState} />
        )}
      </CenterModal>
    </>
  );
}

export default SendBulkMessage;
