/**
 * This file is the service file used for making api request.
 * It contains the getNotificationsService function service which is explained below.
 *
 * @author Yash Aditya
 */

import { axiosJSON } from "src/globals/axiosEndPoints";
import {
  AllNotificationsData,
  ReportNotification,
} from "src/routes/Report/Children/NotificationContainer/hooks/notifications/useNotifications";
import { CurrentStatusEndPoints } from "./reportTicketList.service";

/**
 * This service is created to fetch all the saved notifications for a perticular graph.
 */
const getNotificationsService = async (params: {
  context: CurrentStatusEndPoints;
}) => {
  const { data: res } = await axiosJSON.post(
    `/api/reports/getAllAddednotifications`,
    params
  );

  if (res.err || res.error) {
    throw res.msg ?? res.message ?? "Something went wrong!";
  }

  const ret: AllNotificationsData = {
    notifications: {},
    notificationIds: [],
    metaData: res.metaData,
  };
  res.data.forEach((value: ReportNotification) => {
    const id = `${value.id}`;
    ret.notificationIds.push(id);
    value.id = id;
    ret.notifications[id] = value;
  });

  return ret;
};

export default getNotificationsService;
