/**
 * This file defines a React component responsible for adding and update customQA.
 *
 * @author @Anubhav-busibud
 * @author @navjyot-busibud
 */
import styles from "./NewAnswers.module.scss";
import { Modal } from "react-bootstrap";
import CustomPublishModal from "./Children/CustomPublishModal/CustomPublishModal";
import Loader from "src/components/Loader";
import useNewAnswer from "./useNewAnswer";

const NewAnswers = () => {
  const {
    showQue,
    setShowQue,
    addCustomQue,
    setAddCustomQue,
    showModal,
    onShow,
    onHide,
    questions,
    setQuestions,
    answers,
    setAnswers,
    addQuestion,
    createQuestion,
    showValidation,
    setShowValidation,
    publishLoader,
    setPublishLoader,
    draftLoader,
    setDraftLoader,
    customQAId,
    setCustomQAId,
    updateQnA,
    getData,
    getDataisLoading,
    getDataHasError,
    profileId,
    subTabSection,
    handleQuestionChange,
    handleDeleteQuestion,
    handleAnswerChange,
    handlePublishUnpublishClick,
    handleSaveAsDraftButton,
    updateCustomQAData,
  } = useNewAnswer();

  return (
    <div className="mt-3">
      {getDataisLoading && subTabSection !== "newAnswer" ? (
        <div className="">
          <Loader />
        </div>
      ) : getDataHasError && subTabSection !== "newAnswer" ? (
        <div className="d-flex justify-content-center align-items-center h-100">
          Something went wrong...
        </div>
      ) : (
        <>
          {/* Add queestions */}
          <div className={`p-3 mb-2 ${styles.qaWrapper}`}>
            <div
              className="d-flex justify-content-between"
              onClick={(e) => {
                setShowQue(!showQue);
              }}
            >
              <div>
                <p className={`mb-0 ${styles.title}`}>Add questions</p>
                <span className={`d-block mb-2 ${styles.subTitle}`}>
                  Add questions that are generally asked by your customers
                </span>
              </div>
              <div>
                <span className={`${styles.toggleIcon}`}>
                  {showQue ? (
                    <i className="fa-solid fa-angle-up"></i>
                  ) : (
                    <i className="fa-solid fa-angle-down"></i>
                  )}
                </span>
              </div>
            </div>
            {showQue && (
              <>
                {/* Input Boxes */}
                <div className="mb-3">
                  {questions.map((question, index) => {
                    return (
                      <div
                        className=""
                        key={question.id}
                      >
                        <div
                          className="position-relative"
                          key={question.id}
                        >
                          <input
                            type="text"
                            className={`form-control mb-1 ${styles.inputBox} ${showValidation.question &&
                              question.isEmpty === true
                              ? "border border-danger"
                              : ""
                              }`}
                            id={`exampleFormControlInput${index}`}
                            onChange={(e) => handleQuestionChange(e, index)}
                            value={question.question}
                          />
                          {index > 0 && (
                            <span
                              className={`${styles.removeItem}`}
                              onClick={() => handleDeleteQuestion(index)}
                            >
                              <i className="fa-solid fa-xmark"></i>
                            </span>
                          )}
                        </div>
                        {showValidation.question &&
                          question.isEmpty === true ? (
                          <span className={`${styles.validations} `}>
                            Please enter above question
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })}

                  {/* Add more question buttons */}
                  <div
                    className={`d-flex align-items-center mt-2`}
                    onClick={addQuestion}
                    role="button"
                  >
                    <span className={`me-2 ${styles.addIcon}`}>+</span>
                    <span className={`${styles.addText}`}>
                      Add more variations for this question
                    </span>
                  </div>
                </div>
              </>
            )}
          </div>
          {/* Add customer answer  */}
          <div className={`p-3 mb-2 ${styles.qaWrapper}`}>
            <div
              className="d-flex justify-content-between"
              onClick={() => {
                setAddCustomQue(!addCustomQue);
              }}
            >
              <div>
                <p className={`mb-0 ${styles.title}`}>Add custom answer</p>
                <span className={`d-block mb-2 ${styles.subTitle}`}>
                  Add an answer that the bot will use to answer the question
                </span>
              </div>
              <div>
                <span className={`${styles.toggleIcon}`}>
                  {addCustomQue ? (
                    <i className="fa-solid fa-angle-up"></i>
                  ) : (
                    <i className="fa-solid fa-angle-down"></i>
                  )}
                </span>
              </div>
            </div>
            {addCustomQue && (
              <>
                <div className="mb-3">
                  <div className="position-relative">
                    {/* Added min-height, max-height and resize */}
                    <textarea
                      className={`form-control ${styles.textArea} ${showValidation.answer ? "border border-danger" : ""
                        }`}
                      id="exampleFormControlTextarea1"
                      rows={3}
                      value={answers}
                      onChange={handleAnswerChange}
                    ></textarea>
                    {showValidation.answer && (
                      <span className={`${styles.validations}`}>
                        Please enter answer here
                      </span>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
          {/* Go live */}
          <div className={`p-3 mb-2 ${styles.qaWrapper}`}>
            <p className={`mb-0 ${styles.goLive}`}>Go live</p>
            <span className={`d-block ${styles.publishText}`}>
              Publish your answer for the answer to work on cern
            </span>
            <div className="d-flex align-items-center mt-1 ">
              <button
                className={`px-2 me-1 ${styles.publishBtn}`}
                onClick={handlePublishUnpublishClick}
                disabled={createQuestion.isLoading || updateQnA.isLoading} //disable the button on ajax loading to prevent multiple ajax
              >
                <span className="pe-1">
                  {getData === undefined || getData?.status === "draft" ? (
                    <i className="fa-regular fa-eye"></i>
                  ) : (
                    <i className="fa-regular fa-eye-slash"></i>
                  )}
                </span>
                {getData === undefined
                  ? "Publish"
                  : getData?.status === "draft"
                    ? "Publish"
                    : "Unpublish"}
                {publishLoader && (
                  <span>
                    <span
                      className="spinner-border spinner-border-sm ms-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="sr-only">Loading...</span>
                  </span>
                )}
              </button>
              <Modal
                backdropClassName={`${styles.modalBack}`}
                show={showModal}
                onHide={onHide}
                dialogClassName={`${styles.modalDialog}`}
                contentClassName={`${styles.modalContent}`}
              >
                <CustomPublishModal
                  onHide={onHide}
                  customQAId={customQAId + ""}
                  status={getData?.status ? getData.status : "public"}
                  handleStatusUpdate={(id, values) => {
                    if (getData) {
                      updateCustomQAData(id, values);
                    }
                  }}
                />
              </Modal>
              <button
                className={`px-2 ${styles.publishBtn}`}
                onClick={handleSaveAsDraftButton}
                disabled={createQuestion.isLoading || updateQnA.isLoading} //disable the button on ajax loading to prevent multiple ajax
              >
                Save as draft
                {draftLoader && (
                  <span>
                    <span
                      className="spinner-border spinner-border-sm ms-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="sr-only">Loading...</span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default NewAnswers;
