import { useCallback, useEffect, useState } from "react";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { WEEK_LIST_SHORT } from "src/globals/constants";
import {
  getSnoozeDateOptions,
  SnoozeDateOptions
} from "src/services/Snooze/getSnoozeDateOptions";
import { getDateFromDate, getHoursFromDate } from "src/utils/dateLibrary";

function getStirngTime(currentDT: Date) {
  return `${
    WEEK_LIST_SHORT[currentDT.getDay()]
  } ${currentDT.getDate()} ${currentDT.toLocaleTimeString()}`;
}

export function useSnoozeOptions() {
  const [snoozeOptions, setSnoozeOptions] = useState([] as SnoozeDateOptions);
  const [optionsLoading, setOptionsLoading] = useState(true);
  const refreshSnoozeOptions = useCallback(() => {
    setOptionsLoading(true);
    getSnoozeDateOptions()
      .then((res) => {
        setSnoozeOptions(res);
        setOptionsLoading(false);
      })
      .catch((err) => {
        pushTheToast({
          type: "danger",
          text: "Something went wrong loading options!",
          position: "top-right"
        });
        setOptionsLoading(false);
      });
  }, []);
  useEffect(() => {
    const to = setTimeout(() => {
      const newOptions = snoozeOptions.map((data, index) => {
        let currentDT = new Date();
        switch (data.key) {
          case "1-day":
            currentDT = getDateFromDate(1);
            return {
              ...data,
              dateTimeString: getStirngTime(currentDT)
            };
          case "3-days":
            currentDT = getDateFromDate(3);
            return {
              ...data,
              dateTimeString: getStirngTime(currentDT)
            };
          case "7-days":
            currentDT = getDateFromDate(7);
            return {
              ...data,
              dateTimeString: getStirngTime(currentDT)
            };
          case "1-hour":
            currentDT = getHoursFromDate(1);
            return {
              ...data,
              dateTimeString: getStirngTime(currentDT)
            };
          case "3-hours":
            currentDT = getHoursFromDate(3);
            return {
              ...data,
              dateTimeString: getStirngTime(currentDT)
            };
          case "6-hours":
            currentDT = getHoursFromDate(6);
            return {
              ...data,
              dateTimeString: getStirngTime(currentDT)
            };
          case "till-customer-replies":
            return data;
          case "custom-datetime":
            return data;
          default:
            return data;
        }
      });
      setSnoozeOptions(newOptions);
    }, 30000);
    return () => {
      clearTimeout(to);
    };
  }, [snoozeOptions]);
  useEffect(() => {
    refreshSnoozeOptions();
  }, [false]);
  return { snoozeOptions, optionsLoading, refreshSnoozeOptions };
}
