/**
 * This file defines a custom React hook, `useCustomQa`, providing functions and state
 * related to the Custom Q&A section in the ChatBot interface. It includes navigation
 * functions for handling back clicks and creating new answers, as well as managing the
 * search term for filtering answers.
 *
 * @author @Anubhav-busibud
 */

import { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

function useCustomQa() {
  const navigate = useNavigate();
  const params = useParams();

  const [currentPage, setCurrentPage] = useState(1);
  const [metaData, setMetaData] = useState(null as any);

  /**
   * Navigates to the answer source page of the selected bot profile.
   */
  const handleBackClick = useCallback(() => {
    if (
      params.subTabSection === "newAnswer" ||
      Number.isInteger(Number(params.subTabSection))
    ) {
      navigate(`/bot/botProfiles/${params.profileId}/answerSource/customQa`);
    }
    if (params.subTab === "customQa" && !params.subTabSection) {
      navigate(`/bot/botProfiles`);
    }
  }, [navigate]);

  const handleNewAnswerClick = useCallback(() => {
    navigate(`newAnswer`);
  }, [navigate]);

  const [searchTerm, setSearchTerm] = useState("");

  return {
    handleBackClick,
    handleNewAnswerClick,
    searchTerm,
    setSearchTerm,
    params,
    currentPage,
    setCurrentPage,
    metaData,
    setMetaData,
  };
}

export default useCustomQa;
