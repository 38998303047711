import styles from "./CustomerEdit.module.scss";

import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";

import ProfileImage from "../ProfileImage/ProfileImage";
import Name from "../Name/Name";

import PrimaryEmail from "../PrimaryEmail/PrimaryEmail";
import OtherEmail from "../OtherEmail/OtherEmail";

import PhoneNumber from "../PhoneNumber/PhoneNumber";
import Instagram from "../Instagram/Instagram";

import Twitter from "../Twitter/Twitter";
import AddContact from "../AddContact/AddContact";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { setCustomerData } from "src/store/slices/editCustomerDetails/editCustomerDetails.slice";

import Facebook from "../Facebook/Facebook";
import editCustomerThunks from "src/store/slices/editCustomerDetails/editCustomerDetails.thunks";
import { Phone } from "src/store/slices/editCustomerDetails/editCustomerDetails.declarations";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";

import { InputFieldsContext } from "./useInputFields";
import { updateCustomerInfo } from "src/store/slices/liveChatSetting/chatWidExchMsg/chatWidExchMsg.slice";
import { actions } from "src/store/slices/liveChatSetting/chatWidExchCust/chatWidExchCust.slice";
import Whatsapp from "../Whatsapp/Whatsapp";
import { Spinner } from "react-bootstrap";
import { validateEmail } from "src/utils/utils";

interface Props {
  onHide: (e: any) => void;
}
const CustomerEdit = ({ onHide }: Props) => {
  const dispatch = useAppDispatch();
  const profileImageInputRef = useRef<HTMLInputElement>(null);

  const customerData = useAppSelector(
    (state) => state.shopifySidebar.customerData,
  );

  const [saveChange, setSaveChanges] = useState(true);
  const [saveLoader, setSaveLoader] = useState(false);

  useEffect(() => {
    dispatch(setCustomerData({ customer: customerData }));
    return () => {};
  }, [customerData]);

  function handleOnSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const fd = new FormData(e!.target as HTMLFormElement);
    const Name = fd.get("Name") as string;
    const Email = fd.get("Email") as string;
    const otherEmailsArray = fd.getAll("Other Emails") as string[];
    const phoneNumberArray = fd.getAll("Phone Number") as string[];
    const whatsappNumberArray = fd.getAll("Whatsapp") as string[]; // 22 Jan Adding Whatsapp field
    const countryCode = fd.getAll("countryCode");
    const instaArray = fd.getAll("Instagram") as string[];
    const facebookArray = fd.getAll("Facebook") as string[];

    const twitterArray = fd.getAll("Twitter") as string[];
    setSaveLoader(true);
    dispatch(
      editCustomerThunks.editCustomerAsyncAction({
        customer: {
          id: customerData?.id,
          name: Name,
          email:
            customerData.email === "" && Email.trim() === ""
              ? undefined
              : (Email as any),
          contacts: {
            otherEmails: otherEmailsArray,
            phone: phoneNumberArray.map((p, i) => {
              return { countryCode: countryCode[i], phoneNumber: p } as Phone;
            }),
            // 22 Jan Adding Whatsapp field
            whatsapp: whatsappNumberArray.map((p, i) => {
              return { countryCode: countryCode[i], phoneNumber: p } as Phone;
            }),
            instagram: instaArray,
            facebook: facebookArray,
            twitter: twitterArray,
          },
        },
      }),
      // This code block is executed after a successful response from an API call.
      // It updates the customer's name in the Redux store and dispatches actions to update the customer's name in the state.
    )
      .then((res: any) => {
        if (res.error || res.meta?.requestStatus === "rejected") {
          // 22 Jan If there is an error show danger toast and do not hide modal
          pushTheToast({
            type: "danger",
            text: "Something Went Wrong !",
            position: "top-right",
          });
        } else {
          // The "updateCustomerInfo" action is dispatched to update the customer's name in the Redux store.
          dispatch(
            updateCustomerInfo({
              customerId: customerData?.id,
              customerInfo: {
                name: Name,
              },
            }),
          );
          // The "updateCustomerDataByCustomerId" action is dispatched to update the customer's name in the state.
          dispatch(
            actions.updateCustomerDataByCustomerId({
              customerId: customerData?.id,
              customer: {
                name: Name,
              },
            }),
          );
          // 22 Jan If there is no error show success toast and hide modal
          pushTheToast({
            type: "success",
            text: "Customer Details Updated !",
            position: "top-right",
          });
          onHide({});
        }
      })
      .finally(() => {
        setSaveLoader(false);
      });
  }
  const { fields, areFieldsSame, areDuplicateFields, primaryEmail } =
    useContext(InputFieldsContext);

  const phoneNumbers = fields.get("phoneNumber");
  const whatsappNumbers = fields.get("whatsapp"); // 22 Jan Adding Whatsapp field

  const arePhoneNumbersAllValid = phoneNumbers
    ?.map((p) => p.isValid)
    .every((valid) => valid === true);

  // 22 Jan Checking Whatsapp numbers are valid
  const areWhatsappNumbersAllValid = whatsappNumbers
    ?.map((p) => p.isValid)
    .every((valid) => valid === true);

  const otherEmails = fields.get("otherEmails");

  const areOtherEmailAllValid = otherEmails
    ?.map((p) => p.isValid)
    .every((valid) => valid === true);

  const primaryEmailValid = useMemo(() => {
    if (customerData?.primaryEmailEditable) {
      return validateEmail(primaryEmail);
    }
    return true;
  }, [primaryEmail, customerData?.primaryEmailEditable]);
  const isSame = (value: any) => {
    setSaveChanges(value);
  };

  // Create a reference to the formDataRef div element using the useRef hook
  const formDataRef = useRef<HTMLDivElement>(null);

  // Use the useEffect hook to scroll to the bottom of the formDataRef div element when the fields dependency changes
  useEffect(() => {
    // Check if the formDataRef element exists
    if (formDataRef.current) {
      // Scroll to the bottom of the formDataRef element by setting scrollTop to scrollHeight
      formDataRef.current.scrollTop = formDataRef.current.scrollHeight;
    }
  }, [
    fields.get("otherEmails")?.length, // Add the otherEmails field length to the dependency array
    fields.get("phoneNumber")?.length, // Add the phoneNumber field length to the dependency array
    fields.get("whatsapp")?.length, // Add the whatsapp field length to the dependency array
    fields.get("instagram")?.length, // Add the instagram field length to the dependency array
    fields.get("facebook")?.length, // Add the facebook field length to the dependency array
    fields.get("twt")?.length, // Add the twt field length to the dependency array
  ]);

  /**
   * Memoized validation for save
   */
  const isSaveDisabled = useMemo(() => {
    return (
      saveLoader ||
      !primaryEmailValid ||
      areDuplicateFields.hasDuplicates ||
      (saveChange && areFieldsSame) ||
      !arePhoneNumbersAllValid ||
      !areOtherEmailAllValid ||
      !areWhatsappNumbersAllValid
    );
  }, [
    areDuplicateFields.hasDuplicates,
    saveLoader,
    saveChange,
    areFieldsSame,
    arePhoneNumbersAllValid,
    areOtherEmailAllValid,
    areWhatsappNumbersAllValid,
    primaryEmailValid,
  ]);

  return (
    <div>
      <div className="d-flex justify-content-between px-2">
        <p className={`${styles.editPara}`}>Edit Customer Details</p>
        <span
          className={`mx-1 ${styles.commomCloseBtn}`}
          onClick={onHide}
        >
          <i className="fa-solid fa-xmark"></i>
        </span>
      </div>

      <div>
        <div className="d-flex justify-content-between align-items-center px-2">
          <ProfileImage ref={profileImageInputRef} />
          <AddContact />
        </div>
        <div className="">
          <form
            id="editCustomerForm"
            onSubmit={(e) => {
              if (
                !arePhoneNumbersAllValid ||
                !areOtherEmailAllValid ||
                !areWhatsappNumbersAllValid
              ) {
                e.preventDefault();
              } else {
                handleOnSubmit(e);
              }
            }}
          >
            <div
              className={` ${styles.formData}`}
              ref={formDataRef}
            >
              <Name isSame={isSame} />
              <PrimaryEmail />
              <OtherEmail />
              <PhoneNumber />
              {/* 22 Jan Adding Whatsapp Field */}
              <Whatsapp />
              <Instagram />
              <Facebook />
              <Twitter />
            </div>
            <div>
              <button
                type="submit"
                className={`w-100 btn d-block mt-2 ${styles.saveBtn}  ${
                  saveLoader ? styles.loading : ""
                }`}
                disabled={isSaveDisabled}
              >
                <span>Save Changes</span>
                {saveLoader === true ? (
                  <Spinner
                    className="my-auto ms-2"
                    animation="border"
                    color="white"
                    size="sm"
                  />
                ) : null}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default CustomerEdit;
