import React, { useState } from "react";
import { useAppSelector } from "src/store/store";
import styles from "../CustomerEdit/CustomerEdit.module.scss";

function Name({ isSame }: any) {
  const { name } = useAppSelector((state) => state.shopifySidebar.customerData);

  return (
    <div className="mb-3">
      <label htmlFor="name" className={`form-label ${styles.labelName}`}>
        Name
      </label>
      <input
        type="text"
        data-lpignore="true"
        className={`form-control ${styles.formInput}`}
        id="name"
        aria-describedby="emailHelp"
        placeholder="Monica Geller"
        defaultValue={name}
        name="Name"
        onChange={(e) => {
          isSame(e.target.value.trim() === name);
        }}
      />
    </div>
  );
}

export default Name;
