import { axiosJSON } from "src/globals/axiosEndPoints";

export interface ListViewOption {
  key: string;
  value: string;
  idNName?: {
    id: string | number;
    name: string;
  }[];
}

export interface ListView {
  viewId: number;
  viewName: string;
  viewsTicketCount: number;
  options: ListViewOption[];
}

export interface ListViewResponse {
  usersViewData: { [viewId: number | string]: ListView };
  usersViewList: number[];
  usersViewTotal: number;
}

export async function getUsersViews(servicePayload: {
  start: number;
  limit: number;
}) {
  const { data } = await axiosJSON.post(`/api/views/listViews`, servicePayload);
  if (data.err === true) {
    throw new Error(data.msg as string);
  }
  const normalizedData = normalizeData(data);
  return normalizedData;
}

function normalizeData({
  data,
  metaData,
}: {
  data: { views: ListView[] };
  metaData: { count: number; totalCount: number };
}) {
  const usersViewData: ListViewResponse["usersViewData"] = {};
  const usersViewList: ListViewResponse["usersViewList"] = [];
  const usersViewTotal: ListViewResponse["usersViewTotal"] =
    metaData.totalCount;
  data.views.forEach((view) => {
    usersViewData[view.viewId] = view;
    usersViewList.push(view.viewId);
  });
  return {
    usersViewData,
    usersViewList,
    usersViewTotal,
  } as ListViewResponse;
}

export async function getUsersViewsById(servicePayload: { viewId: number }) {
  const { data } = await axiosJSON.post(
    `/api/views/getViewById`,
    servicePayload
  );
  if (data.err === true) {
    throw new Error(data.msg as string);
  }
  return data.data as ListView;
}
