import { axiosJSON } from "src/globals/axiosEndPoints";

export interface IGetSnooze {
  ticketId: number;
}

export interface ISnoozeData {
  id: number;
  ticketId: number;
  tillCustomerReplies: number;
  expireAt: string;
  expireAtInString: string;
  expireAtGmt: string;//getting the expireAtGmt from the backend response, used for showing the user based timezone datetime
  snoozedAt: string;
}

export async function getSnooze(params: IGetSnooze, signal?:any) {
  const { data: res } = await axiosJSON.post(
    `/api/ticket/snooze/get`,
    params,
    { signal }
    );
  if (res.error === true) {
    throw new Error(res.message as string);
  }
  return res.data as ISnoozeData;
}
