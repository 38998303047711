import React, { useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import styles from "./AssignedViewModel.module.scss";
import Error from "src/assets/images/error.png";
import User from "src/assets/images/user.png";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { upperCaseEachFirst } from "src/utils/utils";
import UserAvatar from "src/components/UserAvatar";
import AxiosImg from "src/components/AxiosImg";
import { useLocation, useNavigate } from "react-router-dom";
import { actions } from "src/store/slices/liveChatSetting/chatWidExchCust/chatWidExchCust.slice";

import { RouteNames } from "src/containers/SideBar/SideBar";

function AssignedViewModel() {
  const {
    chatAssignedNotification,
    selectedCustomerStatus,
    selectedCustomerChatId,
  } = useAppSelector((state) => state.chatWidExchCust);

  const location = useLocation();
  const liveChatRoute = `${RouteNames.liveChat}/chats`;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentState = useRef({
    location: location.pathname,
  });

  useEffect(() => {
    currentState.current = {
      location: location.pathname,
    };
  }, [location.pathname]);

  return (
    <>
      {/* <Modal
                className={`shadow-sm p-3 rounded h-auto`}
                //   show={showAssignModel}
                show={true}
                //   onHide={() => { setShowAssignModel(false) }}
                centered={true}
                enforceFocus={false}
            > */}

      <div className={`mx-3 mt-3 ${styles.secondSection}`}>
        <div className="m-auto  text-center">
          <div className="my-2">
            <div
              className={`${styles.errorIcon} d-flex align-items-center justify-content-center`}
            >
              <i className="fa-solid fa-exclamation"></i>
            </div>
          </div>
          <div className="my-2 mt-4">
            <span className={`${styles.NoReturnText} text-center`}>
              <strong className={`${styles.boldText}`}>
                {chatAssignedNotification.assigner.name ?? "NA"}
              </strong>{" "}
              has assigned this conversation with{" "}
              <strong className={`${styles.boldText}`}>
                {chatAssignedNotification.customer.name ?? "NA"}
              </strong>{" "}
              to you.
            </span>
          </div>
          <div className="my-2">
            <div className={`${styles.userBox} mx-auto`}>
              <div className={`d-flex `}>
                <div>
                  {/* img  */}
                  {chatAssignedNotification.customer.imageURL &&
                  chatAssignedNotification.customer.imageURL.length !== 0 ? (
                    <AxiosImg
                      url={chatAssignedNotification.customer.imageURL}
                      className={`${styles.profileIcon}`}
                      isDirectURL={chatAssignedNotification.customer?.isPublicAttachmentUrl}
                    />
                  ) : (
                    <div>
                      <UserAvatar
                        name={
                          chatAssignedNotification.customer.name &&
                          chatAssignedNotification.customer.name !== ""
                            ? chatAssignedNotification.customer.name
                            : "NA"
                        }
                        size={32}
                      />
                    </div>
                  )}
                </div>
                <div className={`${styles.infoWrap}`}>
                  <span className={`d-block w-100 text-truncate ${styles.nameText} ms-2`}>
                    {chatAssignedNotification.customer.name ?? "NA"}
                  </span>
                  <span className={`d-block w-100 text-truncate ${styles.emailText} ms-2`}>
                    {chatAssignedNotification.customer.email}
                  </span>
                </div>
              </div>
              {chatAssignedNotification?.preChatFrom &&
              chatAssignedNotification?.preChatFrom?.message &&
              chatAssignedNotification?.preChatFrom?.message.length !== 0 ? (
                <div className={`w-100 text-truncate ${styles.para}`}>
                  {chatAssignedNotification?.preChatFrom?.message}
                </div>
              ) : null}
            </div>
            <div
              className={`d-flex flex-column flex-md-row justify-content-center align-items-center my-3 `}
            >
              <button
                onClick={() => {
                  navigate(
                    `/live-chat/chats/${chatAssignedNotification.chatStatus}/${chatAssignedNotification.chatId}`
                  );
                  dispatch(actions.resetChatAssignedNotification());
                }}
                className={`border-0 me-md-4 mb-3 mb-md-0 ${styles.saveBtn}`}
              >
                Go to chat
              </button>
              <button
                onClick={() => {
                  if (
                    currentState.current.location.includes(liveChatRoute) &&
                    selectedCustomerStatus ===
                      chatAssignedNotification.chatStatus
                  ) {
                    dispatch(actions.resetChatAssignedNotification());
                  } else {
                    navigate(
                      `/live-chat/chats/${chatAssignedNotification.chatStatus}/all`
                    );
                    dispatch(actions.resetChatAssignedNotification());
                  }
                }}
                className={`bg-white mb-2 mb-md-0 ${styles.cancelBtn}`}
              >
                {`I will review in '${upperCaseEachFirst(
                  chatAssignedNotification.chatStatus
                )}' Section`}
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* </Modal> */}
    </>
  );
}

export default AssignedViewModel;
