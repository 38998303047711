import { OverlayTrigger, Tooltip } from "react-bootstrap";
import styles from "../CustomerEdit/CustomerEdit.module.scss";
import insta from "src/assets/images/insta-new.png";
import { useAppSelector } from "src/store/store";
import { InputFieldsContext } from "../CustomerEdit/useInputFields";
import { useContext, useEffect } from "react";
import AddMore from "../AddMore/AddMore";

function Instagram() {
  const instagram = useAppSelector(
    (state) => state.editcustomerdetails.customer.contacts?.instagram,
  );

  // let defaultValue;
  // if (instagram !== undefined) {
  //   if (instagram?.length > 0) {
  //     defaultValue = instagram[props.idx];
  //   } else {
  //     defaultValue = "";
  //   }
  // }

  const {
    addField,
    updateField,
    removeField,
    setFieldsOfKey,
    fields,
    areDuplicateFields,
  } = useContext(InputFieldsContext);

  useEffect(() => {
    setFieldsOfKey(instagram ?? [], "instagram");
  }, [instagram]);

  const instagramFields = fields.get("instagram");

  const isAddButtonEnabled = instagramFields?.length
    ? instagramFields[instagramFields.length - 1]?.isValid
      ? true
      : false
    : false;

  const { hasDuplicates, duplicateIds } =
    areDuplicateFields.duplicates["instagram"];

  return (
    <>
      {fields.get("instagram")?.map((field, idx) => {
        const hasError = hasDuplicates && duplicateIds.includes(field.id);
        return (
          <div
            className="d-flex flex-column"
            key={field.id}
          >
            {idx === 0 ? (
              <label
                htmlFor="instagram"
                className={`d-flex justify-content-between align-items-center form-label ${styles.labelName} m-0`}
              >
                <div className={`d-flex align-items-center`}>
                  <div
                    className={`d-flex justify-content-center align-items-center ${styles.msgBg}`}
                  >
                    <img
                      src={insta}
                      className={`${styles.instaImg}`}
                      alt="instagram"
                    />
                  </div>{" "}
                  <div>
                    <span className={`ps-1`}>Instagram</span>
                  </div>
                </div>
              </label>
            ) : (
              <></>
            )}

            <div
              className="d-flex mt-2 "
              style={{ width: "103.5%", overflowX: "hidden" }}
            >
              <input
                type="text"
                data-lpignore="true"
                className={`form-control ${styles.formInput} ${
                  hasError && "border-danger"
                }`}
                id="instagram"
                aria-describedby="emailHelp"
                placeholder="eg. jhon_doe"
                required
                name="Instagram"
                value={field.value as string}
                onChange={(e) => {
                  var regex = /^\s*$/;
                  updateField(
                    field,
                    {
                      ...field,
                      value: e.target.value,
                      isValid: !regex.test(e.target.value),
                    },
                    "instagram",
                  );
                }}
              />
              <div
                className={`${styles.customCrossPosition}`}
                onClick={() => {
                  // dispatch(
                  //   deleteContact({
                  //     id: id as string,
                  //     type: "instagram",
                  //     idx: props.idx,
                  //   })
                  // );
                  removeField(field, "instagram");
                }}
              >
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className={`${styles.toolTipCustom}`}>
                      Remove
                    </Tooltip>
                  }
                >
                  {({ ref, ...triggerHandler }) => (
                    <div
                      className={` `}
                      {...triggerHandler}
                      ref={ref}
                    >
                      <div
                        className={`d-flex justify-content-center align-items-center ${styles.clearText}`}
                      >
                        <i className="fa-solid fa-xmark"></i>
                      </div>
                    </div>
                  )}
                </OverlayTrigger>
              </div>
            </div>
            {hasError && (
              <span className={`${styles.validateSpan}`}>
                Duplicate Instagram ID not allowed
              </span>
            )}
          </div>
        );
      })}
      <span>
        <AddMore
          type="Account"
          isValid={isAddButtonEnabled}
          onClick={() => {
            if (!isAddButtonEnabled) {
              return;
            }
            addField({ value: "" }, "instagram");
          }}
          isVisible={(fields.get("instagram")?.length ?? 0) > 0}
        />
      </span>
    </>
  );
}

export default Instagram;
