/**
 * This file is the custom hook file.
 * It contains the useReportVisibility hook which is explained below.
 *
 * @author Ayush Gupta
 * @author Jayalakshmi
 */

import { useMemo } from "react";
import { useAppSelector } from "src/store/store";

/**
 * This hook is used to determine the visibility of different report sections based on ui_visibility
 */
const useReportVisibility = () => {
  // Fetching ui_visibility from the Redux store
  const ui_visibility = useAppSelector(
    (state) => state.globals.currentUserData?.ui_visibility
  );

  // Checking if any tickets report is present in ui_visibility
  const tickets = useMemo(
    () => ui_visibility?.some((item) => item.includes("tickets_")) ?? true,
    [ui_visibility]
  );

  // Checking if any live chat report is present in ui_visibility
  const liveChat = useMemo(
    () => ui_visibility?.some((item) => item.includes("liveChat_")) ?? true,
    [ui_visibility]
  );

  // Checking if any graphs are available in reports tab
  const reports = useMemo(() => tickets || liveChat, [tickets, liveChat]);

  /* TICKETS REPORTS */

  // Checking if any current status report is present
  const currentStatus = useMemo(
    () =>
      ui_visibility?.some((item) => item.includes("tickets_currentStatus")) ??
      true,
    [ui_visibility]
  );

  // Checking if any volume activity report is present
  const volumeActivity = useMemo(
    () =>
      ui_visibility?.some((item) => item.includes("tickets_volumeActivity")) ??
      true,
    [ui_visibility]
  );

  // Checking if any performance report is present
  const performance = useMemo(
    () =>
      ui_visibility?.some((item) => item.includes("tickets_performance")) ??
      true,
    [ui_visibility]
  );

  // Checking if any revenue report is present
  const revenue = useMemo(
    () =>
      ui_visibility?.some((item) => item.includes("tickets_revenue")) ?? true,
    [ui_visibility]
  );

  /* LIVE CHAT REPORTS */

  // Checking if any total chat report is present
  const totalChats = useMemo(
    () =>
      ui_visibility?.some((item) => item.includes("liveChat_totalChats")) ??
      true,
    [ui_visibility]
  );

  // Checking if any missed chat report is present
  const missed = useMemo(
    () =>
      ui_visibility?.some((item) => item.includes("liveChat_missedChats")) ??
      true,
    [ui_visibility]
  );

  // Checking if any chat satisfaction report is present
  const chatSatisfaction = useMemo(
    () =>
      ui_visibility?.some((item) =>
        item.includes("liveChat_chatSatisfaction")
      ) ?? true,
    [ui_visibility]
  );

  // Checking if any chat availability report is present
  const chatAvailability = useMemo(
    () =>
      ui_visibility?.some((item) =>
        item.includes("liveChat_chatAvailability")
      ) ?? true,
    [ui_visibility]
  );

  // Selecting initial route based on available graphs
  // Default route - current-status
  const selectedRoute = useMemo(() => {
    if (reports) {
      if (tickets) {
        if (currentStatus) return "/current-status";
        if (volumeActivity) return "/volume-activity";
        if (performance) return "/performance";
        if (revenue) return "/revenue";
      } else if (liveChat) {
        if (totalChats) return "/total-chats";
        if (missed) return "/missed-chats";
        if (chatSatisfaction) return "/chat-satisfaction";
        if (chatAvailability) return "/chat-availability";
      }
    }

    return "/current-status";
  }, [
    reports,
    tickets,
    currentStatus,
    volumeActivity,
    performance,
    revenue,
    liveChat,
    totalChats,
    missed,
    chatSatisfaction,
    chatAvailability,
  ]);

  // Memoized visibility object
  const reportVisibility = useMemo(() => {
    return {
      reports,
      tickets,
      liveChat,
      currentStatus,
      volumeActivity,
      performance,
      revenue,
      totalChats,
      missed,
      chatSatisfaction,
      chatAvailability,
      selectedRoute,
    };
  }, [
    chatAvailability,
    chatSatisfaction,
    currentStatus,
    liveChat,
    missed,
    performance,
    reports,
    revenue,
    selectedRoute,
    tickets,
    totalChats,
    volumeActivity,
  ]);

  return reportVisibility;
};

export default useReportVisibility;
