import { GetAllRoutingRules, getAllRoutingRules, GetAllRoutingRulesParams } from "src/services/LiveChat/Settings/routingRules/getAllRoutingRules";
import { RootState } from "src/store/store";

// thunk that fetches all routingRules
const fetchAllRoutingRulesThunk = async(
  payload:number|string,
  {getState}: {getState: Function}
) =>{
  try{
    const currentState: RootState = getState();
    const queryData: GetAllRoutingRulesParams = {
      integrationId: payload,
      start: currentState.routingRulesSetting.routingRuleIdList.length,
      limit: currentState.routingRulesSetting.fetchRoutingRulesLimit
    }
    const routingRuleData: GetAllRoutingRules = await getAllRoutingRules(queryData);
    console.log("---------------routingRuleSettingThunk ------------ ");
    console.log("RoutingRule data ::: "+ JSON.stringify(routingRuleData));
    return {
      routingRulesData: routingRuleData.rules,
      routingRuleIdList: routingRuleData.ruleIds,
      generalTeam: routingRuleData.generalTeam,
      count: routingRuleData.metaData.count,
      totalCount: routingRuleData.metaData.total
    }
  }catch(error){
    throw error;
  }
};


export default { fetchAllRoutingRulesThunk };