import ReactSelect from "react-select";
import styles from "../ReportInternalFilter.module.scss";
import { useCallback, useMemo } from "react";
import {
  reportInternalFiltersActions,
  useReportInternalFilters,
} from "src/routes/Report/hooks/reportFilters/useReportInternalFilters";

const useIntChatStatusFilter = () => {
  // Create a memoized array of all available chat statuses
  const allChatStatus = useMemo(() => {
    return [
      { id: "resolved", name: "Resolved" },
      { id: "unresolved", name: "Unresolved" },
    ];
  }, []);

  // Create a memoized array of options suitable for a selection component
  const options = useMemo(() => {
    return allChatStatus.map((data) => ({
      label: data.name,
      value: data,
    }));
  }, [allChatStatus]);

  // Use a custom hook to get internal filters and dispatch function
  const { internalFilters, dispatch } = useReportInternalFilters();

  // Create a memoized array of selected chat statuses based on internal filters
  const selectedChatStatus = useMemo(() => {
    if (internalFilters.addedFilters.chatStatus === "all") {
      return allChatStatus.map((chatStatus) => ({
        label: chatStatus.name,
        value: chatStatus,
      }));
    } else if (internalFilters.addedFilters.chatStatus === "resolved") {
      return [
        {
          label: allChatStatus[0].name,
          value: allChatStatus[0],
        },
      ];
    } else if (internalFilters.addedFilters.chatStatus === "unresolved") {
      return [
        {
          label: allChatStatus[1].name,
          value: allChatStatus[1],
        },
      ];
    } else {
      return [];
    }
  }, [internalFilters, allChatStatus]);

  // Define a callback function for when the selected chat statuses change
  const onChangeChatStatus = useCallback(
    (value: typeof selectedChatStatus) => {
      if (value.length === 2) {
        // If both statuses are selected, set the filter to "all"
        dispatch([
          reportInternalFiltersActions.setFilters,
          { chatStatus: "all" },
        ]);
      } else {
        // If only one status is selected, update the filter with the selected status
        dispatch([
          reportInternalFiltersActions.setFilters,
          { chatStatus: value.length ? value[0].value.id : undefined },
        ]);
      }
    },
    [selectedChatStatus]
  );

  // Return the necessary values for the component using this hook
  return {
    options,
    selectedChatStatus,
    onChangeChatStatus,
  };
};

function Agent() {
  const { options, selectedChatStatus, onChangeChatStatus } =
    useIntChatStatusFilter();

  return (
    <div className="px-2 pb-2">
      <label className={`${styles.labelText}`}>Chat Status</label>
      <ReactSelect
        isMulti
        options={options}
        value={selectedChatStatus}
        onChange={onChangeChatStatus as any}
        className={`${styles.text}`}
        menuShouldBlockScroll={true}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
            font: "normal normal normal 12px/18px Poppins",
            color: "#000000",
          }),
          multiValue: (base, { data }) => ({
            ...base,
            backgroundColor: '#F4F4F4', // Background color for selected values
            borderRadius: '6px',
            opacity: 1,
          }),
          multiValueLabel: (base) => ({
            ...base,
            font: "normal normal 500 12px/18px Poppins",
            letterSpacing: "0px",
            color: "#1F2223",
            paddingLeft:"12px",
          }),
          multiValueRemove: (base, { data }) => ({
            ...base,
            // color: '#000000', // Remove icon color for selected values
            fontSize: "6px",
            // ':hover': {
            //   backgroundColor: 'red', // Change the background color on hover if needed
            // },
          }),
        }}
      />
    </div>
  );
}

export default Agent;
