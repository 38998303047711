/**
 * This file defines a React component responsible for displaying available brands for bot profiles
 *
 * @author @navjyot-busibud
 * @author @yuvaraj-busibud
 * @author @ayush-busibud
 * @author @jayalakshmi-busibud
 *
 */
import { useInfiniteQuery } from "@tanstack/react-query";
import React, { useCallback, useEffect, useState } from "react";
import Select, {
  ControlProps,
  OptionProps,
  SingleValueProps,
  components,
} from "react-select";
import AxiosImg from "src/components/AxiosImg";
import UserAvatar from "src/components/UserAvatar";
import {
  GetAllBrandData,
  getLinkableBrands,
} from "src/services/UiAutomation/ReturnExchange/getLinkableBrands";
import styles from "./BrandDropdown.module.scss";

interface BrandOption {
  value: number;
  label: string;
  imageUrl?: string | null;
}

const isMulti = false;

const CustomOption: React.FC<
  OptionProps<BrandOption | null, typeof isMulti>
> = (props) => (
  <components.Option {...props}>
    <div className={`d-flex align-items-center cursor-pointer `}>
      {props.data?.imageUrl ? (
        <AxiosImg
          url={props.data.imageUrl}
          size={20}
          style={{ width: "20px", height: "20px" }}
          className={`rounded-circle`}
        />
      ) : (
        <UserAvatar
          name={props.label}
          size={20}
        />
      )}
      <span className={`ps-2 ${styles.brandName}`}>{props.label}</span>
    </div>
  </components.Option>
);

const CustomSingleValue: React.FC<
  SingleValueProps<BrandOption | null, typeof isMulti>
> = (props) => (
  <components.SingleValue {...props}>
    <div className={`d-flex align-items-center`}>
      {props.data?.imageUrl ? (
        <AxiosImg
          url={props.data.imageUrl}
          size={20}
          style={{ width: "20px", height: "20px" }}
          className={`rounded-circle`}
        />
      ) : (
        <UserAvatar
          name={props.data?.label ?? "NA"}
          size={20}
        />
      )}{" "}
      <span className={`ps-1 ${styles.selectBrand}`}>{props.data?.label}</span>
    </div>
  </components.SingleValue>
);

const CustomControl: React.FC<
  ControlProps<BrandOption | null, typeof isMulti>
> = ({ children, innerRef, innerProps, isFocused }) => {
  return (
    <div
      ref={innerRef}
      className={`custom-control-container ${
        isFocused ? styles.focusStyle : ""
      }`}
    >
      <div
        {...innerProps}
        className={`custom-control px-2 ${styles.brandDrop}`}
      >
        {children}
      </div>
    </div>
  );
};

const CustomDropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <i
        className={`fa-solid ${
          props.selectProps.menuIsOpen ? "fa-caret-up" : "fa-caret-down"
        }`}
      ></i>
    </components.DropdownIndicator>
  );
};

interface Props {
  selectedBrand: BrandOption | null;
  setSelectedBrand: (brand: BrandOption | null) => void;
  updateBrandChange?: (brandId: number) => void;
  className?: string;
  error?: string;
}

const BrandDropdown = ({
  selectedBrand,
  setSelectedBrand,
  updateBrandChange,
  className,
  error,
}: Props) => {
  const [brandOptions, setBrandOptions] = useState<BrandOption[]>([]);

  const payload = {
    limit: 15,
    start: 0,
  };

  const { data, isLoading, fetchNextPage, hasNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ["getLinkableBrands", payload],
      queryFn: ({ pageParam = payload }) => getLinkableBrands(pageParam),
      getNextPageParam: (lastPage: GetAllBrandData, allPages) => {
        const data = allPages.flatMap((data) => data.brandIds);

        if (data.length < lastPage.metaData.totalCount) {
          const nextPageParam = {
            ...payload,
            start: data.length,
          };
          return nextPageParam;
        }
        return null;
      },
    });

  // Callback to handle the change event of the dropdown
  const handleChange = useCallback(
    (selectedOption: BrandOption | null) => {
      setSelectedBrand(selectedOption);
      if (updateBrandChange && selectedOption) {
        updateBrandChange(selectedOption.value);
      }
    },
    [setSelectedBrand, updateBrandChange],
  );

  // Fetch the next page only when there is a hasNextPage and no ongoing fetch
  const fetchNextPageOnScroll = useCallback(() => {
    if (hasNextPage && !isFetching) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isFetching]);

  // Convert brandIds and brands data into options for the dropdown
  useEffect(() => {
    const brandData = data?.pages.flatMap((data) => data);

    if (brandData) {
      const options = brandData.reduce((acc: BrandOption[], brand) => {
        const { brands } = brand;

        if (brands) {
          const brandOptions = brand.brandIds.map(
            (brandId: string | number) => {
              const brand = brands[brandId];
              return {
                label: brand.name,
                value: Number(brand.id),
                imageUrl: brand.imageURL,
              } as BrandOption;
            },
          );

          return [...acc, ...brandOptions];
        }

        return acc;
      }, []);

      setBrandOptions(options);
    }
  }, [data]);

  return (
    <>
      <Select
        className={`w-100 cursor-pointer ${className ?? ""}`}
        classNamePrefix="dropdown"
        options={brandOptions}
        value={selectedBrand}
        onChange={handleChange}
        components={{
          Option: CustomOption,
          SingleValue: CustomSingleValue,
          // Menu: CustomMenu,
          Control: CustomControl,
          DropdownIndicator: CustomDropdownIndicator,
          IndicatorSeparator: null,
        }}
        placeholder="Select Brand"
        isSearchable={false}
        isMulti={isMulti}
        onMenuScrollToBottom={fetchNextPageOnScroll}
        isLoading={isLoading || isFetching}
      />
      {error && <span className="text-danger small">{error}</span>}
    </>
  );
};

export default BrandDropdown;
