import { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import styles from "./ArticleSideBar.module.scss";

function useArticleSideBar() {
  // States for ArticleSideBar
  const [showOverlay, setShowOveray] = useState(false);
  const [showSettingsSubSB, setShowSettingsSubSB] = useState<boolean>(true);

  // Function for toggling sidebar
  const handleToggleSubSideBar = () => {
    setShowSettingsSubSB((prevState) => !prevState);
  };

  // Function for getting toggle content
  const getToggleContent = () => {
    return (
      <div
        className={`${styles.subSideBarCloseBtnDiv} ${showSettingsSubSB === false ? styles.closeBtnToggle : ''
          }`}
        onClick={handleToggleSubSideBar}
      >
        <OverlayTrigger
          show={showOverlay}
          placement="top"
          overlay={
            <Tooltip className={`${styles.toolTipCustom}`}>
              <span className={`${styles.tooltipHead}`}>
                {showSettingsSubSB === true ? 'Hide sidebar' : 'Show sidebar'}
              </span>
            </Tooltip>
          }
        >
          {({ ref, ...triggerHandler }) => (
            <div
              {...triggerHandler}
              ref={ref}
              onMouseOver={() => {
                setShowOveray(true);
              }}
              onMouseOut={() => {
                setShowOveray(false);
              }}
            >
              <span className={`${styles.subSideBarCloseBtnToggleArrow} m-auto`}>
                {showSettingsSubSB === true ? (
                  <i className="fa-solid fa-chevron-left"></i>
                ) : (
                  <i className="fa-solid fa-chevron-right"></i>
                )}
              </span>
            </div>
          )}
        </OverlayTrigger>
      </div>
    );
  };


  return { showOverlay, showSettingsSubSB, handleToggleSubSideBar, getToggleContent };
}

export default useArticleSideBar;