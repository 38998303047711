import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { CallRecordingSlice } from "./callRecording.declarations";
import callRecordingExtraReducers from "./callRecording.extraReducers";
import { fetchCallerDataThunk } from "./callRecording.thunks";

export function builderFunction(
  builder: ActionReducerMapBuilder<CallRecordingSlice>
) {
  builder.addCase(
    fetchCallerDataThunk.fulfilled,
    callRecordingExtraReducers.callerDataFulfilled
  );
  builder.addCase(
    fetchCallerDataThunk.rejected,
    (state: CallRecordingSlice) => {
      state.callerDataAjaxStatus = "rejected";
    }
  );
  builder.addCase(fetchCallerDataThunk.pending, (state: CallRecordingSlice) => {
    state.callerDataAjaxStatus = "pending";
  });
}
