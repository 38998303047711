import styles from "../../AgentOffline.module.scss";
import dots from "src/assets/images/dots.png";
import textLogo from "src/assets/images/text.png";
import choiceImg from "src/assets/images/choiceList.png";
import dropdown from "src/assets/images/dropdown.png";
import multipleChoice from "src/assets/images/multipleChoice.png";
import info from "src/assets/images/thankYou.png";
import nextLine from "src/assets/images/nextLine.png";
import { Link } from "react-router-dom";
import MessageBox from "../../../../../AskMail/OfflineMode/Children/MessageBox";
import TicketForm from "../TicketForm/TicketForm";
import {
  setAgentOfflineAskForEmail,
  setAgentOfflineAutoReply,
  setAgentOfflineConfirmationText,
  setAgentOfflineMessageText,
  setShowOutsideBusinessForm,
  setShowTicketForm,
} from "src/store/slices/liveChatSetting/Forms/askForEmail/askForEmailSetting.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { useEffect } from "react";

interface Props {
  integrationId: string | number;
  // outsideBusinessHours: IAskForEmailWhenAgentOfflineForm,
  // setMessageText: (value:any)=>void;
  // setConfirmationText: (value:any)=>void;
  // setTicketFormConfirmationText: (value:any)=>void;
  // setAskForEmail: (value:any)=>void;
  // setAutoReply: (value:any)=>void;
  type: "withinBusinessHours" | "outsideBusinessHours";
}
const OutsideBusinessHours = ({
  integrationId,
  // outsideBusinessHours,
  // setMessageText,
  // setConfirmationText,
  // setAskForEmail,
  // setAutoReply,
  type = "outsideBusinessHours",
}: Props) => {
  const dispatch = useAppDispatch();

  const { askForEmail, autoReply, confirmationText, messageText } =
    useAppSelector((state) =>
      type == "withinBusinessHours"
        ? state.askForEmailSetting.askForEmailData.whenAgentOffline
            .withinBusinessHours
        : state.askForEmailSetting.askForEmailData.whenAgentOffline
            .outsideBusinessHours
    );

  const { messageTextErr, confirmationTextErr } = useAppSelector((state) =>
    type == "withinBusinessHours"
      ? state.askForEmailSetting.askForEmailData.whenAgentOffline
          .withinBusinessHours.validationErrors
      : state.askForEmailSetting.askForEmailData.whenAgentOffline
          .outsideBusinessHours.validationErrors
  );

  const { showOutsideBusinessHours, showTicketForm } = useAppSelector(
    (state) => state.askForEmailSetting
  );

  const showCurrentFormInWidget = () => {
    if (autoReply === false) {
      if (showTicketForm === false) {
        dispatch(setShowTicketForm({ value: true }));
      }
    } else {
      if (showTicketForm === true) {
        dispatch(setShowTicketForm({ value: false }));
      }
    }

    if (showOutsideBusinessHours === false) {
      dispatch(setShowOutsideBusinessForm({ value: true }));
    }
  };
  useEffect(() => {
    showCurrentFormInWidget();
  }, [autoReply]);
  return (
    <>
      <div>
        <span className={`${styles.offlineMode}`}>
          Decide what to send when agent(s) offline within business hours. You
          can add business hours in{" "}
          <Link
            to={`/live-chat/settings/${integrationId}/Availability`}
            className={`text-decoration-none ${styles.goto}`}
          >
            availability
          </Link>{" "}
          settings.
        </span>
        <div className={`mt-2`}>
          <div className={`d-flex mb-1`}>
            <div className="form-check">
              <input
                data-lpignore="true"
                className="form-check-input"
                type="radio"
                name="sendReply"
                id="sendReply"
                checked={autoReply}
                onChange={(e) => {
                  dispatch(setAgentOfflineAutoReply({ value: true, type }));
                  showCurrentFormInWidget();
                }}
              />
            </div>
            <label
              htmlFor="sendReply"
              className={`${styles.radioText} ${
                autoReply === true ? styles.activeText2 : ""
              }`}
            >
              Send Automated Reply
            </label>
          </div>
          <div className={`d-flex mb-1`}>
            <div className="form-check">
              <input
                data-lpignore="true"
                className="form-check-input"
                type="radio"
                name="sendTicket"
                id="sendTicket"
                checked={!autoReply}
                onChange={(e) => {
                  dispatch(setAgentOfflineAutoReply({ value: false, type }));
                  showCurrentFormInWidget();
                }}
              />
            </div>

            <label
              htmlFor="sendTicket"
              className={`form-label cursor-pointer ${styles.radioText} ${
                autoReply === false ? styles.activeText2 : ""
              }`}
            >
              Send Ticket Form
            </label>
          </div>
        </div>
        {autoReply === true && (
          <>
            <div className="">
              {/* ----Message box including sub component---- */}

              <MessageBox
                msgHead={`Message text`}
                messageText={messageText ?? ""}
                setMessageText={(value: string) => {
                  dispatch(setAgentOfflineMessageText({ value: value, type }));
                  showCurrentFormInWidget();
                }}
                showWarning={messageTextErr}
              />
              <div>
                <div className="form-check">
                  <input
                    className={`form-check-input ${styles.checkBoxInput}`}
                    type="checkbox"
                    id="checkBox1"
                    checked={askForEmail}
                    onChange={(e) => {
                      dispatch(
                        setAgentOfflineAskForEmail({
                          value: !askForEmail,
                          type,
                        })
                      );
                    }}
                  />

                  <label
                    className={`form-check-label ${styles.radioText}`}
                    htmlFor="checkBox1"
                  >
                    Ask for e-mail
                  </label>
                </div>
              </div>
              {askForEmail === true && (
                <div className="mt-2">
                  <MessageBox
                    msgHead={`Confirmation text`}
                    messageText={confirmationText ?? ""}
                    setMessageText={(value: string) => {
                      dispatch(
                        setAgentOfflineConfirmationText({ value: value, type })
                      );
                      showCurrentFormInWidget();
                    }}
                    showWarning={confirmationTextErr}
                  />
                </div>
              )}
            </div>
          </>
        )}
        {autoReply === false && <TicketForm type={type} />}
      </div>
    </>
  );
};

export default OutsideBusinessHours;
