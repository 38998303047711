import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";

export interface GetByIdParams {
  botProfileId: number | string;
  customQAId: number | string;
}

export interface GetByIdRes {
  customQAId: number;
  questions: string[];
  status: "draft" | "public";
  answer: string;
}

const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/customQa/getById`
  : "/api/botProfile/customQA/getById";

export async function getById(params: GetByIdParams) {
  const { data: res } = await axiosJSON.post(apiEndPoint, params);

  if (res.err) {
    throw new Error(res.message as string);
  }

  return res.data as GetByIdRes;
}
