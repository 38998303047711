import { GetConfigModalParams } from "src/features/ReturnAutoWorkFlow/children/ConfigModals/oldReturnModals.types";
import { axiosJSON } from "src/globals/axiosEndPoints";

interface ReasonQuestion {
  additionalOptions: Array<{ id: string | null; value: string }>;
  elementId: string;
  elementTypeId: string;
  elementValue: string;
}

export interface FollowUpReturnReason {
  id: string;
  reasonName: string;
  moreQuestions: ReasonQuestion[];
}

export interface GetAllFollowUpReturnReason {
  reasonIds: Array<string>;
  reasons: Record<string, FollowUpReturnReason>;
}

async function getAllReturnReasonListService(payload: GetConfigModalParams) {
  const { data: res } = await axiosJSON.post(
    "/api/setting/automation/refund/step/14/getAllReturnReasonList",
    payload,
  );

  if (res.err || res.error) {
    throw new Error(res.msg ?? res.message ?? "Some Error Occurred.");
  }

  const ret: GetAllFollowUpReturnReason = {
    reasonIds: [],
    reasons: {},
  };

  res.data.forEach((returnReason: FollowUpReturnReason) => {
    const id = returnReason.id;
    ret.reasonIds.push(returnReason.id);
    ret.reasons[id] = returnReason;
  });

  return ret;
}

export default getAllReturnReasonListService;
