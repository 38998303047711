import { useInfiniteQuery } from "@tanstack/react-query";
import {
  GetAllSchedulesData,
  GetAllSchedulesPayload,
  getAllSchedulesLiveChat,
} from "src/services/LiveChat/ScheduleFollowups/getAllSchedules";
interface Filters extends Omit<GetAllSchedulesPayload, "chatId"> {
  chatId?: string | number;
}

interface Props {
  payload: Filters;
}
export const useGetAllSchedules = ({ payload}: Props) => {
  const {
    status,
    error,
    data,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isError,
    fetchStatus,
  } = useInfiniteQuery({
    queryKey: ["LiveChat/fetchScheduleMessages", payload],
    queryFn: ({ pageParam = payload }) => getAllSchedulesLiveChat(pageParam),
    getNextPageParam: (
      prevPage: GetAllSchedulesData,
      allPages: GetAllSchedulesData[]
    ) => {
      const data = allPages.flatMap((page) => page.scheduleIds);
      if (data.length < prevPage.metaData.total) {
        return {
          ...payload,
          start: data.length,
        };
      }
      return null;
    },
    // enabled: payload.chatId !== undefined,
  });

  return {
    status,
    error,
    data,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isError,
    fetchStatus,
  };
};
