import { Route, Routes } from "react-router-dom";
import Profile from "./children/MainSetting/children/Profile/Profile";
import ChangePassword from "./children/MainSetting/children/ChangePassword/ChangePassword";
import Tags from "./children/MainSetting/children/Tags/Tags";
import User from "./children/MainSetting/children/User/User";
import Teams from "./children/MainSetting/children/Teams/Teams";
import TeamPage from "./children/MainSetting/children/Teams/components/TeamPage/TeamPage";
import { CurrentUser } from "src/services/Settings/Users/getCurrentUser";
import BrowseIntegration from "./children/MainSetting/children/BrowseIntegration/BrowseIntegration";
import EmailSignature from "./children/MainSetting/children/EmailSignature/EmailSignature";
import EmailIntegration from "./children/MainSetting/children/EmailIntegration";
import FbIntegration from "./children/MainSetting/children/BrowseIntegration/Children/FbIntegration/FbIntegration";
import BusinessHours from "./children/MainSetting/children/BusinessHours/BusinessHours";
import MyIntegration from "./children/MainSetting/children/MyIntegration/MyIntegration";
import ShopifyIntegration from "./children/MainSetting/children/BrowseIntegration/Children/ShopifyIntegration/ShopifyIntegration";
import Brands from "./children/MainSetting/children/Brands/Brands";
import ConnectEmailProcess from "./children/MainSetting/children/EmailIntegration/children/AddEmail/Children/AddModal/ConnectEmailProcess/ConnectEmailProcess";
import VerfiyEmailProcess from "./children/MainSetting/children/EmailIntegration/children/AddEmail/Children/AddModal/VerfiyEmailProcess/VerfiyEmailProcess";
import DomainIntegration from "./children/MainSetting/children/EmailIntegration/children/AddEmail/Children/AddModal/DomainIntegration/DomainIntegration";
import Complete from "./children/MainSetting/children/EmailIntegration/children/AddEmail/Children/AddModal/Complete/Complete";
import AgentNotification from "./children/MainSetting/children/AgentNotification/AgentNotification";
import WhatsappIntegration from "./children/MainSetting/children/BrowseIntegration/Children/WhatsappIntegration/WhatsappIntegration";
import Billing from "./children/MainSetting/children/Billing/Billing";
import SlackIntegration from "./children/MainSetting/children/BrowseIntegration/Children/SlackIntegration/SlackIntegration";
import AddNewSlack from "./children/MainSetting/children/BrowseIntegration/Children/SlackIntegration/Childern/AddNew/AddNewSlack";

const Setting = ({
  currentUserData,
}: {
  currentUserData: CurrentUser | null;
}) => (
  <>
    <Route
      path="settings/Profile"
      element={<Profile />}
    />

    {/* WhatsApp Integration Route */}
    {currentUserData?.ui_visibility &&
      currentUserData.ui_visibility.includes("integration_whatsapp_view") &&
      !(
        currentUserData?.disabledFeatures &&
        currentUserData.disabledFeatures.includes("whatsapp_integration")
      ) && (
        // Disable facebook route when it is included in disabledFeatures
        <Route
          path="settings/integrations/whatsapp"
          element={<WhatsappIntegration />}
        />
      )}
    {/* Slack Integration Route */}
    {currentUserData?.ui_visibility &&
      currentUserData.ui_visibility.includes("slack_integration_feature") && (
        // Disable slackIntegration route when it is included in disabledFeatures
        <>
          <Route
            path="settings/integrations/slack"
            element={<SlackIntegration />}
          />
          <Route
            path="settings/integrations/slack/addNew"
            element={<AddNewSlack />}
          />
        </>
      )}

    {currentUserData?.ui_visibility?.includes("integration_facebook_view") &&
      !(
        currentUserData?.disabledFeatures &&
        currentUserData.disabledFeatures.includes("facebook_integration")
      ) && (
        // Disable facebook route when it is included in disabledFeatures
        <Route
          path="settings/integrations/facebook"
          element={<FbIntegration />}
        />
      )}
    <Route
      path="settings/ChangePassword"
      element={<ChangePassword />}
    />
    {/* route for email signature */}
    <Route
      path="settings/EmailSignature"
      element={<EmailSignature />}
    />
    {currentUserData &&
      currentUserData.scopes &&
      currentUserData.scopes.includes("add_tags") && (
        <Route
          path="settings/Tags"
          element={<Tags />}
        />
      )}
    {currentUserData &&
      currentUserData.scopes &&
      currentUserData.scopes.includes("view_user") && (
        <Route
          path="settings/User"
          element={<User />}
        />
      )}
    {currentUserData &&
      currentUserData.scopes &&
      currentUserData.scopes.includes("add_team") && (
        <>
          <Route
            path="settings/Teams"
            element={<Teams />}
          />
          <Route
            path="settings/Teams/:teamId"
            element={<TeamPage />}
          />
        </>
      )}

    <Route
      path={`settings/integrations`}
      element={<MyIntegration />}
    />

    <Route
      path={`settings/browse-integration`}
      element={<BrowseIntegration />}
    />

    {currentUserData?.scopes?.includes("edit_report_notification_settings") ||
    currentUserData?.scopes?.includes("show_report_notification_settings") ? (
      <Route
        path={`settings/notification`}
        element={<AgentNotification />}
      />
    ) : (
      <></>
    )}

    <Route
      path={`settings/Brands`}
      element={<Brands />}
    />

    {/* Added this check to hide the email integration routes when it includes in disabled features */}
    {!(
      currentUserData?.disabledFeatures &&
      currentUserData.disabledFeatures.includes("email_integration")
    ) && (
      <>
        <Route
          path="settings/integrations/email"
          element={<EmailIntegration />}
        />
        <Route
          path={`settings/integrations/email/connectEmail`}
          element={<ConnectEmailProcess />}
        />

        <Route
          path={`settings/integrations/email/:integrationId/connectEmail`}
          element={<ConnectEmailProcess />}
        />

        <Route
          path={`settings/integrations/email/:integrationId/verifyEmail`}
          element={<VerfiyEmailProcess />}
        />

        <Route
          path={`settings/integrations/email/:integrationId/verifyDomain`}
          element={<DomainIntegration />}
        />

        <Route
          path={`settings/integrations/email/:integrationId/complete`}
          element={<Complete />}
        />
      </>
    )}
    {currentUserData?.ui_visibility?.includes("integration_shopify_view") && (
      <Route
        path={`settings/integrations/shopify`}
        element={<ShopifyIntegration />}
      />
    )}
    {/* {currentUserData &&
      currentUserData.scopes &&
      currentUserData.scopes.includes("availibility") && ( */}
    {/* <>
      <Route path="settings/availibility" element={<BusinessHours />} />
    </> */}
    {/* )} */}

    {currentUserData?.ui_visibility?.includes("billing_view") && (
      <Route
        path={`/settings/billing`}
        element={<Billing />}
      />
    )}
  </>
);
export default Setting;
