import { useCallback, useMemo } from "react";
import { useDispatchSectionContext } from "../../Hooks/useDispatchSectionContext";
import styles from "./DispatchRuleName.module.scss";

const DispatchRuleName = () => {
  const {
    updateState,
    state: { ruleName },
    showErrors,
    setShowErrors,
    automationType,
  } = useDispatchSectionContext();

  const handleChange = useCallback(
    (value: string) => {
      updateState({ ruleName: value });
      setShowErrors(false);
    },
    [setShowErrors, updateState],
  );

  const errorString = useMemo(() => {
    if (!showErrors) {
      return null;
    }

    if (ruleName === "" || ruleName.trim() === "") {
      return "Rule name cannot be empty";
    }

    return null;
  }, [ruleName, showErrors]);

  return (
    <div>
      <div className={`w-100 ${styles.ruleBox}`}>
        <div className={`${styles.selectBox}`}>
          <label
            htmlFor={`${automationType}_rulename`}
            className={`form-label ${styles.formLabel}`}
          >
            Add a dispatching rule name:
          </label>
          <div className={`${styles.inputBoxWrap} w-100`}>
            <input
              type="text"
              className={`form-control ${styles.inputBox} ${
                errorString ? styles.errBorder : ""
              }`}
              id={`${automationType}_rulename`}
              placeholder="Type here..."
              value={ruleName}
              onChange={(e) => {
                handleChange(e.target.value);
              }}
            />
          </div>
          {errorString ? (
            <div className={`${styles.errText} ms-2`}>{errorString}</div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default DispatchRuleName;
