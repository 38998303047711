import { useCallback, useState } from "react";
import { Spinner } from "react-bootstrap";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import contactUsService from "src/services/Billing/contactUs.service";
import styles from "./ContactSaleModal.module.scss";
import closeModal from "src/assets/images/close(3).png";
interface Props {
  onHide: () => void;
}

/**
 * Component for showing Contact us input and submit button
 */
const ContactUsUI = ({
  onHide,
  showSuccess,
}: {
  onHide: Props["onHide"];
  showSuccess: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);

  /**
   * Callback for handling contact us click
   */
  const clickHandler = useCallback(async () => {
    // Validate the message
    if (message === "") {
      setError(true);
      return;
    }

    try {
      // Initialize loader
      setIsLoading(true);

      // Call the service for sending message
      await contactUsService({ message });

      // Show the success modal UI
      showSuccess();
    } catch (err) {
      // Casting error as a Error object
      const er = err as Error;

      // Show a toast with relevent error message
      pushTheToast({
        position: "top-right",
        text: er.message,
        type: "danger",
      });
    } finally {
      // Hide the loader
      setIsLoading(false);
    }
  }, [message, showSuccess]);

  return (
    <div>
      {/* Header */}
      <div className="position-relative text-center">
        {/* Heading */}
        <h2 className={`mb-0 ${styles.heading}`}>Contact us</h2>

        {/* Info */}
        <span className={`d-inline-block ${styles.subHeading}`}>
          A member from our team will get in touch with you soon
        </span>

        {/* Close Modal */}
        <span
          className={`cursor-pointer ${styles.closeModal}`}
          onClick={onHide}
          id="closeModal"
        >
          <img
            src={closeModal}
            alt="closeModal"
            height={7}
            width={7}
          />
        </span>
      </div>

      {/* Inputs Box */}
      <div className="mt-4 pt-2 mb-3">
        {/* Label */}
        <label
          htmlFor="exampleFormControlTextarea1"
          className={`form-label ${styles.formLabel}`}
        >
          Let us know your requirements
        </label>

        {/* Text Area */}
        <textarea
          className={`form-control ${styles.textArea} ${
            error ? "border-danger" : ""
          }`}
          id="requirement"
          rows={3}
          placeholder="Type here ..."
          value={message}
          onChange={(e) => {
            // If we have error shown
            if (error) {
              // Hide the error
              setError(false);
            }

            // Update message variable with changed value
            setMessage(e.currentTarget.value);
          }}
        ></textarea>

        {/* Error Message */}
        {error && (
          <div className={`${styles.formError}`}>Please enter a message</div>
        )}

        {/* Submit Button */}
        <button
          className={`mt-3 ${styles.submitBtn}`}
          id="submitBtn"
          onClick={clickHandler}
          disabled={isLoading}
        >
          Submit
          {/* Loader */}
          {isLoading && (
            <Spinner
              size="sm"
              animation="border"
              className="mx-2"
            />
          )}
        </button>
      </div>
    </div>
  );
};

/**
 * Success Modal UI
 */
const SuccessUI = ({ onHide }: Props) => {
  return (
    <div className="position-relative py-5">
      {/* Hide Modal */}
      <span
        className={`cursor-pointer ${styles.closeModal}`}
        onClick={onHide}
        id="closeModal"
      >
        <img
          src={closeModal}
          alt="closeModal"
          height={7}
          width={7}
        />
      </span>

      {/* Message */}
      <p className={`text-center ${styles.successMsg}`}>
        Thank you for getting in touch. We will get back to you soon.
      </p>
    </div>
  );
};

const ContactSaleModal = ({ onHide }: Props) => {
  const [showSuccess, setShowSuccess] = useState(false);

  return (
    <div className={`${styles.contactSaleModal}`}>
      {showSuccess ? (
        <SuccessUI onHide={onHide} />
      ) : (
        <ContactUsUI
          onHide={onHide}
          showSuccess={() => setShowSuccess(true)}
        />
      )}
    </div>
  );
};

export default ContactSaleModal;
