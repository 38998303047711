import styles from "./SelfService.module.scss";
import { useParams } from "react-router-dom";
// import OrderTracking from "./Children/OrderTracking/OrderTracking";
// import OrderModification from "./Children/OrderModification/OrderModification";
// import ReturnFlow from "./Children/ReturnFlows/ReturnFlow";
import BugManagement from "./Children/BugManagement/BugManagement";

const SelfService = () => {
  const { serviceName } = useParams(); //get the URL parameters
  return (
    <div className={`w-100 ${styles.serviceWrapper}`}>
      {/* {serviceName === "orderTracking" && <OrderTracking />}
      {serviceName === "orderModification" && <OrderModification />}
      {serviceName === "return" && <ReturnFlow />} */}
      {serviceName === "bugManagement" && <BugManagement />}
    </div>
  );
};

export default SelfService;
