import { axiosJSON } from "src/globals/axiosEndPoints";
import {
  MessagesData,
  MessagesDataType,
} from "src/services/LiveChat/liveChatHistory/getLiveChatCustomerAllMessages.service";

export interface IGetLiveChatHistoryParams {
  /**
   * Customer Id to get customer data
   */
  customerId: string;
  /**
   * Limit for pagination
   */
  limit: number;
  filters?: {
    // default asc
    sortBy: "asc" | "desc";
  };
  /**
   * Id of starting item for pagination
   */
  startFromId: number | string | null;
  /**
   * Type of starting item for pagination
   */
  startFromIdType: MessagesDataType | null;
}

export interface IGetLiveChatHistoryResponse {
  messages: Record<string, MessagesData>;
  messageIds: string[];
  metaData: {
    /**
     * Id of live chat connected to customer if available
     */
    liveChatId?: string | null;

    // Frontend Only

    /**
     * Limit reached in current page or not
     */
    limitReached: boolean;

    /**
     * Status if customer is online or not
     */
    isCustomerOnline: boolean;
  };
}

const getCustomerAllMessagesV3 = async (payload: IGetLiveChatHistoryParams) => {
  const { data: res } = await axiosJSON.post(
    "/api/liveChat/chat/customerAllMessagesV3",
    payload,
  );

  if (res.err || res.error) {
    throw new Error(res.msg ?? res.message ?? "Something unexpected occcured!");
  }

  const ret: IGetLiveChatHistoryResponse = {
    messageIds: [],
    messages: {},
    metaData: {
      limitReached: false,
      liveChatId: res.metaData?.liveChatId,
      isCustomerOnline: res.metaData?.isCustomerOnline ?? false,
    },
  };

  res.data.forEach((msg: MessagesData) => {
    switch (msg.dataType) {
      case "ticket": {
        const id = `${msg.dataType}::${msg.ticketId}`;
        ret.messages[id] = msg;
        ret.messageIds.push(id);
        break;
      }
      case "chat": {
        const id = `${msg.dataType}::${msg.chatRequestId}`;
        ret.messages[id] = msg;
        ret.messageIds.push(id);
        break;
      }
      default: {
        const id = `${msg.dataType}::${msg.messageId}`;
        ret.messages[id] = msg;
        ret.messageIds.push(id);
        break;
      }
    }
  });

  if (ret.messageIds.length < payload.limit) {
    ret.metaData.limitReached = true;
  }

  return ret;
};

export default getCustomerAllMessagesV3;
