import SimpleImageSlider from "react-simple-image-slider";
import styles from "./AskPreview.module.scss";
import previewsilde1 from "src/assets/images/previewsilde1.png";
import previewsilde2 from "src/assets/images/previewsilde2.png";
import previewsilde3 from "src/assets/images/previewsilde3.png";
import previewsilde4 from "src/assets/images/previewslide4.png";
import { useReturnPreviewState } from "../../../../../../hooks/useReturnPreview";
import { useState } from "react";
import { Carousel } from "react-bootstrap";
const AskPreview = () => {
  // const images = [
  //   { url: previewsilde1 },
  //   { url: previewsilde2 },
  //   { url: previewsilde4 },
  // ];
  const [images, setImages] = useState([
    previewsilde1,
    previewsilde2,
    previewsilde4,
  ]);
  const { setShowAntiRefundPreview } = useReturnPreviewState();

  return (
    <div className="text-center">
      <div className={`${styles.previewWrapper}`}>
        <h3 className={`${styles.heading}`}>
          Instructions for how anti-refund works
        </h3>
        <p className={`${styles.subHeading}`}>
          Since you have selected multiple anti-refund strategies. The preview
          will begin at the first one .Click "Next" to see next stages.
        </p>
        <button
          className={`${styles.submitBtn}`}
          onClick={() => {
            setShowAntiRefundPreview(false);
          }}
        >
          I understand, show me the preview
        </button>
        <div className="w-100 d-flex justify-content-center">
          {/* <SimpleImageSlider
            width={300}
            height={340}
            images={images}
            showBullets={true}
            showNavs={false}
            autoPlay={true}
          /> */}
          <Carousel className={`${styles.couroselSlider} carouselWrapper`}>
            <Carousel.Item
              interval={800}
              className={styles.slideItem}
            >
              <img
                className="d-block w-100"
                src={previewsilde1}
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item
              interval={800}
              className={styles.slideItem}
            >
              <img
                className="d-block w-100"
                src={previewsilde2}
                alt="Second slide"
              />
            </Carousel.Item>
            <Carousel.Item
              interval={800}
              className={styles.slideItem}
            >
              <img
                className="d-block w-100"
                src={previewsilde4}
                alt="Third slide"
              />
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default AskPreview;
