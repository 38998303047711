import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { IUpdateChatSettingParams, updateChatSetting } from "src/services/LiveChat/Settings/updateChatSettingData";
import { fetchChatSettingData } from "src/store/slices/liveChatSetting/chatWidgetSetting/chatSetting/chatSetting.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import TimeOutComp from "./Children/TimeOutComp/TimeOutComp";
import styles from "./InactivityTimeOut.module.scss";
const InactivityTimeOut = () => {


  const { chatSettingData, chatSettingDataAjaxStatus } =
  useAppSelector((state) => state.liveChatSetting);

  const { integrationId } = useParams();
  const pageIntegrationId = integrationId ? decodeURIComponent(integrationId) : "";

  const [whenNoMessageCloseChat, setWhenNoMessageCloseChat] = useState<any>({enabled:false,value: 0});
  const [whenNoMessageMakeChatInactive, setWhenNoMessageMakeChatInactive] = useState<any>({enabled:false,value: 0});
  const [whenAgentNotResponding, setWhenAgentNotResponding] = useState<any>({enabled:false,value: 0});
  const [showSaveChange , setShowSaveChange] = useState(false);
  const [showSaveLoader , setShowSaveLoader] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(()=>{
    if(chatSettingData !== null){
      if(chatSettingData.inactivityTimeouts?.whenNoMessageCloseChat?.enabled !== whenNoMessageCloseChat.enabled 
        || chatSettingData.inactivityTimeouts?.whenNoMessageMakeChatInactive?.enabled !== whenNoMessageMakeChatInactive.enabled 
        || chatSettingData.inactivityTimeouts?.whenAgentNotResponding?.enabled !== whenAgentNotResponding.enabled 
        || chatSettingData.inactivityTimeouts?.whenNoMessageCloseChat?.value !==  whenNoMessageCloseChat.value
        || chatSettingData.inactivityTimeouts?.whenNoMessageMakeChatInactive?.value !==  whenNoMessageMakeChatInactive.value
        || chatSettingData.inactivityTimeouts?.whenAgentNotResponding?.value !==  whenAgentNotResponding.value){

          setShowSaveChange(true);
      }else{
        setShowSaveChange(false);
      }
    }
  },[whenNoMessageCloseChat,whenNoMessageMakeChatInactive,whenAgentNotResponding]);



  useEffect(() => {
    dispatch(fetchChatSettingData(pageIntegrationId));
  }, [false]);

  useEffect(()=>{
    if(chatSettingData !== null){

      if(chatSettingData.inactivityTimeouts?.whenNoMessageCloseChat){

        setWhenNoMessageCloseChat(chatSettingData.inactivityTimeouts.whenNoMessageCloseChat);
      }

      if(chatSettingData.inactivityTimeouts?.whenNoMessageMakeChatInactive){

        setWhenNoMessageMakeChatInactive(chatSettingData.inactivityTimeouts.whenNoMessageMakeChatInactive);
      }

      if(chatSettingData.inactivityTimeouts?.whenAgentNotResponding){

        setWhenAgentNotResponding(chatSettingData.inactivityTimeouts.whenAgentNotResponding);
      }
    }
  },[chatSettingData]);

  const handleSave = () =>{
    if(chatSettingData !== null && chatSettingDataAjaxStatus !== "pending"){

      let payload : IUpdateChatSettingParams = {
        whenNoMessageCloseChat : whenNoMessageCloseChat,
        whenNoMessageMakeChatInactive : whenNoMessageMakeChatInactive,
        whenAgentNotResponding : whenAgentNotResponding,
      };

      updateChatSetting({
        integrationId: pageIntegrationId,
        chatSetting:payload
      }).then((res)=>{
        pushTheToast({
          type: "success",
          text:"Changes Saved !",
          position: "top-right",
        });
        dispatch(fetchChatSettingData(pageIntegrationId));
        setShowSaveChange(false);
      }).catch((err)=>{
        pushTheToast({
          type: "danger",
          text: "Something went wrong!",
          position: "top-right",
        });
      });
    }
  };

  const handleCancel = () =>{
    if(chatSettingData !== null){

      if(chatSettingData.inactivityTimeouts?.whenNoMessageCloseChat){

        setWhenNoMessageCloseChat(chatSettingData.inactivityTimeouts.whenNoMessageCloseChat);
      }else{
        setWhenNoMessageCloseChat({enabled:false,value: 0});
      }

      if(chatSettingData.inactivityTimeouts?.whenNoMessageMakeChatInactive){

        setWhenNoMessageMakeChatInactive(chatSettingData.inactivityTimeouts.whenNoMessageMakeChatInactive);
      }else{
        setWhenNoMessageMakeChatInactive({enabled:false,value: 0});
        
      }

      if(chatSettingData.inactivityTimeouts?.whenAgentNotResponding){

        setWhenAgentNotResponding(chatSettingData.inactivityTimeouts.whenAgentNotResponding);
      }else{
        setWhenAgentNotResponding({enabled:false,value: 0});

      }
    }

    setShowSaveChange(false);
  };


  return (
    <>
      <div className={`${styles.inactiveMain}`}>
        <h2 className={`${styles.heading}`}>Inactivity timeouts</h2>
        <div>
          <TimeOutComp
            labelOne={`When the agent is not responding for`}
            labelTwo={`minutes, transfer the visitor to another agent.`}
            subLabel={`minutes, Applies only if the chat has just started. All following responses can be longer and won't result in a transfer.`}
            enabled={whenAgentNotResponding.enabled}
            count = {whenAgentNotResponding.value}
            setCount = {setWhenAgentNotResponding}
          />
          <TimeOutComp
            labelOne={`When there are no new messages in the chat for`}
            labelTwo={`minutes, make the chat inactive.`}
            subLabel={`Inactive chats are not included in agents' concurrent chats limit.`}
            enabled={whenNoMessageMakeChatInactive.enabled}
            count = {whenNoMessageMakeChatInactive.value}
            setCount = {setWhenNoMessageMakeChatInactive}
          />
          <TimeOutComp
            labelOne={`When there are no new messages in the chat for`}
            labelTwo={`minutes, close the chat.`}
            subLabel={``}
            enabled={whenNoMessageCloseChat.enabled}
            count = {whenNoMessageCloseChat.value}
            setCount = {setWhenNoMessageCloseChat}
          />
        </div>
        {showSaveChange && (

        <div className={`d-flex justify-content-start flex-column flex-md-row justify-content-md-end ${styles.actionBox}`}>
          <button className={`px-3 py-2 me-md-1 bg-white ${styles.cancelBtn}`} onClick={handleCancel}>Cancel</button>
          <button className={`px-3 py-2 mt-2 mt-md-0 ${styles.saveBtn}`} disabled={showSaveLoader === true} onClick={handleSave}>
            {showSaveLoader === true ? (
                <Spinner
                className="my-auto mx-1"
                animation="border"
                color="white"
                size="sm"
              />
              ): 
              "Save changes"
              }
            </button>
        </div>
        )}
      </div>
    </>
  );
};
export default InactivityTimeOut;
