import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";

const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/bugManagement/getBugPriorities`
  : "/api/selfService/bugManagement/getBugPrioritys";

export const getBugPriority = async () => {
  const { data: res } = await axiosJSON.get(apiEndPoint, {
    params: { cacheIt: true, cacheTime: 3600000 },
  });

  if (res.err || res.error) {
    throw res.msg ?? res.message ?? "Something went wrong!";
  }

  const ret = normalizeData(res);

  return ret;
};

export type AllPriorityNormalizedData = {
  allBugPriority: Record<string, { id: string; name: string; key: string }>;
  allBugPriorityIds: number[];
};

// convert incoming data to the required format
function normalizeData(response: any): AllPriorityNormalizedData {
  const statusesList = response.data;
  const normalizedData: AllPriorityNormalizedData = {
    allBugPriority: {},
    allBugPriorityIds: [],
  };
  statusesList.forEach((priority: any, index: number) => {
    normalizedData.allBugPriority[priority.priorityId] = {
      id: priority.priorityId,
      name: priority.priorityName,
      key: priority.priorityKey,
    };
    normalizedData.allBugPriorityIds.push(priority.priorityId);
  });
  return normalizedData;
}
