import { CustomerDetails } from "src/services/BugReport/getBugByCategory.service";
import styles from "./ProfileImgGroup.module.scss";
import AxiosImg from "src/components/AxiosImg";
import UserAvatar from "src/components/UserAvatar";
import GeneralTooltip from "src/components/GeneralTooltip/GeneralTooltip";

const RenderCustomerImg = ({
  customerData,
  translate,
}: {
  customerData?: CustomerDetails;
  translate: boolean;
}) => {
  return (
    <span className={`${translate ? styles.imgWrap : ""}`}>
      {customerData?.imageUrl ? (
        <AxiosImg
          className={`${styles.profileImg}`}
          url={customerData.imageUrl}
          size={22}
        />
      ) : customerData?.customerName ? (
        <UserAvatar
          className={`${styles.profileImg}`}
          name={customerData?.customerName ?? "N A"}
          size={22}
        />
      ) : (
        <></>
      )}
    </span>
  );
};

const ProfileImgGroup = ({
  customers,
  showReporter = true,
  companyName = [],
}: {
  customers: CustomerDetails[];
  showReporter?: boolean;
  companyName?: Array<string | null>;
}) => {
  const tooltipTitle = companyName.length
    ? customers
        .map(
          (customer, index) =>
            `${customer.customerName}(${companyName[index] || "NA"})`,
        )
        .join(", ")
    : customers.map((customer) => customer.customerName).join(", ");
  return (
    <div className="d-flex align-items-center">
      <GeneralTooltip
        title={tooltipTitle}
        className={`${styles.tooltip}`}
      >
        <div>
          {customers.slice(0, 3).map((customer, index) => (
            <RenderCustomerImg
              key={index}
              customerData={customer}
              translate={index > 0}
            />
          ))}
        </div>
      </GeneralTooltip>
      <span className={`ps-1 ${styles.reporters}`}>
        {customers.length > 3 ? `+ ${customers.length - 3}` : ""}
      </span>
      {showReporter && (
        <span className={`ps-2 me-2 ${styles.reporters}`}>Reporters</span>
      )}
    </div>
  );
};

export default ProfileImgGroup;
