/**
 * This file is the service file used for making api request.
 * It contains the updateAnalyticsAndSeo function service which is explained below.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants"; // this is used for testing purpose only for frontend.

export interface AnalyticsAndSeoPayload {
  integrationId: number | string;
  isGoogleAnalyticsEnabled?: boolean;
  isGoogleAnalyticsVersion4?: boolean;
  trackingId?: string;
  isVisibleinPublicSearch?: boolean;
  pageTitle?: string;
  metaDescription?: string;
  openGraphTitle?: string;
  openGraphDescription?: string;
}

/**
 * For testing frontend with own api link if backend is not prepared.
 */
const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/updateAnalyticsAndSeo`
  : "/api/chatSetting/updateAnalyticsAndSeo";

/**
 * This service is made to update the analytics and SEO data on the domain and access settings of knowledge base.
 */
export const updateAnalyticsAndSeo = async (
  payload: AnalyticsAndSeoPayload
) => {
  const { data: res } = await axiosJSON.post(apiEndPoint, payload);
  return res.data;
};
