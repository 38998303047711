import { useState } from "react";
import InstallTracker from "./Children/ConfigureTracker/Children/InstallTracker/InstallTracker";
import ConfigureTracker from "./Children/ConfigureTracker/ConfigureTracker";
import TrackerTable from "./Children/TrackerTable/TrackerTable";
import styles from "./SalesTracker.module.scss";
const SalesTracker = () => {
  const [AddSale, setAddSale] = useState(false);
  const showNewSales = () => {
    setAddSale(!AddSale);
  };
  return (
    <div className={`${styles.salesTracker}`}>
      {!AddSale && (
        <>
          <h2 className={`${styles.salesTrackerHead}`}>Sales Tracker</h2>
          <p className={`${styles.salesTrackerSub}`}>
            The Sales Tracker will allow you to measure how much revenue you get
            thanks to chat. It will keep track of all the transactions (and
            their values) that happen after chat.
          </p>
          <button className={`${styles.addSaleBtn}`} onClick={showNewSales}>
            <span>
              <i className="fa-solid fa-plus"></i>
            </span>{" "}
            Add new sales tracker
          </button>
          <div className="mt-4 overflow-auto">
            <TrackerTable />
          </div>
        </>
      )}
      {/* Show ConfigureTracker component when click on add new button */}
      {AddSale && (
        <>
          {/* <ConfigureTracker /> */}
          <InstallTracker />
        </>
      )}
    </div>
  );
};
export default SalesTracker;
