import styles from "./SelectBox.module.scss";
import { useCallback, useEffect, useRef, useState } from "react";
import SelectAsyncData from "src/components/ReactSelectUtilities/SecectAsyncData/SecectAsyncData";
import { Overlay } from "react-bootstrap";
import { readCustomerEmails } from "src/apis/addTicket/read/addTicket.read.api";
import {
  GetAllLinkableBrandData,
  getAllLinkableBrands,
} from "src/services/LiveChat/Settings/getAllLinkableBrands";
import { useFetchLinkableBrands } from "../CustomHook/useFetchLinkableBrands";
import { useNavigate } from "react-router-dom";

export interface SendingInfoLabel {
  imgURL?: null | string;
  name: string;
  isPublicAttachmentUrl?: boolean;
}
interface Props {
  name: string;
  addUser?: boolean;
  isDisabled?: boolean;
  isMulti?: boolean;
  currentValue?: string;
  getCustomerName?: (name: string) => void;
  getValue?: (value: string) => void;
  placeholder?: string;
  getIdValue?: (value: string) => void;
  LabelComponent?: (props: SendingInfoLabel) => JSX.Element;
  initialFetchDone?: () => void;
  integrationId: string | undefined;
  validationError?: boolean;
}

function SelectBox({
  name,
  isDisabled = false,
  integrationId,
  isMulti = false,
  currentValue = "",
  getValue = (value) => {},
  placeholder = "",
  LabelComponent,
  initialFetchDone = () => {},
  getCustomerName = (name) => {},
  getIdValue = (value) => {},
  validationError = false,
}: Props) {
  const formRef = useRef(null);
  const [email, setEmail] = useState(currentValue);

  const navigate = useNavigate();
  const { allBrands, loading, fetchData } = useFetchLinkableBrands();

  const fetchFrom = useCallback(
    async (searchTerm: string, loadOptions: any, additional: any) => {
      let data = allBrands;
      if (searchTerm.trim() === "") {
        data = await fetchData(parseInt(integrationId + ""));
      }

      searchTerm = searchTerm.trim().toLowerCase();
      const retValue: any = data
        .filter(
          (value) =>
            (value.name.search(searchTerm) !== -1 ||
              value.email.search(searchTerm) !== -1 ||
              searchTerm === "") &&
            value.email.trim() !== email
        )
        .map((values: any, key: number) => {
          return {
            label: LabelComponent ? (
              <LabelComponent
                imgURL={values.imageURL}
                name={values.name}
                isPublicAttachmentUrl={values.isPublicAttachmentUrl}
              />
            ) : (
              `${values.name} (${values.email})`
            ),
            value: values,
          };
        });

      retValue.push({
        label: (
          <div
            className={`${styles["add-ticket-user"]}`}
          >{`Add new brand`}</div>
        ),
        value: { id: 0, email: "", addBrand: true },
      });
      return {
        options: retValue,
        hasMore: false,
      };
    },
    [allBrands, integrationId, email]
  );

  //we are fetching brands on opening the menu, no need to fetch brands on initial load
  // useEffect(() => {
  //   if (integrationId) {
  //     fetchData(parseInt(integrationId)).then((res) => {
  //       initialFetchDone();
  //     });
  //   }
  // }, [integrationId]);

  useEffect(() => {
    setEmail(currentValue);
  }, [currentValue]);

  return (
    <>
      <input type="hidden" name={name} value={email} id="brandLink" />
      <div ref={formRef} className={`w-100`}>
        <SelectAsyncData
          isMulti={isMulti}
          styles={
            //checking if validation error is true if yes then showing red border
            validationError
              ? {
                  ...customStyles,
                  control: (provided: any, _: any) => ({
                    ...provided,
                    border: "1px solid #A70B0B",
                    borderRadius: "6px",
                  }),
                }
              : customStyles
          }
          currentValue={email}
          isDisabled={isDisabled}
          className={`w-100`}
          placeholder={placeholder}
          getOptionLabel={(option: any) => {
            if (LabelComponent && typeof option.label === "string") {
              const brandData = allBrands.filter(
                (brand) => option.label?.trim() == brand.email
              );
              let name = option.label;
              let imgUrl = null;
              let isPublicAttachmentUrl: boolean | undefined = false;

              if (brandData.length) {
                name = brandData[0].name;
                imgUrl = brandData[0].imageURL;
                isPublicAttachmentUrl = brandData[0].isPublicAttachmentUrl;
              }
              return (
                <LabelComponent
                  name={name}
                  imgURL={imgUrl}
                  isPublicAttachmentUrl={isPublicAttachmentUrl}
                />
              );
            }
            return option.label;
          }}
          fetchFunction={fetchFrom}
          onChange={(newValueArr: any) => {
            if (Array.isArray(newValueArr) && newValueArr[0]?.value?.addBrand) {
              navigate("/settings/Brands");
              return;
            }

            if (newValueArr.length) {
              const newValue = newValueArr[newValueArr.length - 1];

              setEmail(
                newValueArr.map((data: any) => data.value.email).join(",")
              );
              getIdValue(
                newValueArr.map((data: any) => data.value.id).join(",")
              );
              getCustomerName(
                newValueArr.map((data: any) => data.value.name).join(",")
              );
            } else {
              setEmail("");
              getIdValue("");
              getCustomerName("");
            }
          }}
          components={isDisabled ? { MultiValueRemove: () => null } : undefined}
        />
      </div>
    </>
  );
}

const customStyles = {
  control: (provided: any, _: any) => ({
    ...provided,
    background: "#f5f5f5 0% 0% no-repeat padding-box",
    borderColor: "#9e9e9e",
    minHeight: "48px",
    width: "100%",
    border: "1px solid transparent",
    borderRadius: "13px",
    color: "#000000",
    cursor: "pointer",
    paddingLeft: "5px",
    "&:active": {
      border: "1px solid #000000",
    },
  }),

  valueContainer: (provided: any, _: any) => ({
    ...provided,
    minHeight: "48px",
    padding: "0 6px",
  }),
  placeholder: (base: any) => ({
    ...base,
    font: "normal normal normal 14px/21px Poppins",
    letterSpacing: " 0px",
    color: "#6E6E6E",
  }),

  input: (provided: any, _: any) => ({
    ...provided,
    margin: "0px",
    font: "normal normal normal 14px/21px Poppins;",
  }),
  indicatorsContainer: (provided: any, _: any) => ({
    ...provided,
    minHeight: "48px",
    padding: "0px !important",
    marginRight: "6px",
  }),
  dropdownIndicator: (provided: any, _: any) => ({
    ...provided,
    padding: "0px !important",
  }),
  MenuPortal: (provided: any, _: any) => ({
    ...provided,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 16px 48px #00000029",
    borderRadius: "10px",
    border: "1px solid #fff",
    font: "normal normal normal 14px/21px Poppins",
    letterSpacing: "0px",
    color: "#000000",
  }),
  option: (provided: any, _: any) => ({
    ...provided,
    textAlign: "left",
    font: "normal normal normal 14px/21px Poppins",
    letterSpacing: "0px",
    color: "#000000",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    padding: "6px 12px",
    cursor: "pointer",
    "&:hover": {},
  }),
  clearIndicator: (provided: any, _: any) => ({
    ...provided,
    padding: "0px !important",
  }),
  indicatorSeparator: (provided: any, _: any) => ({
    ...provided,
    marginBottom: "4px",
    marginTop: "4px",
    width: "1px",
    boxSizing: "border-box",
    marginRight: "6px",
  }),

  multiValueLabel: (provided: any, _: any) => ({
    ...provided,
    padding: "0",
    textAlign: "left",
    font: "normal normal normal 14px/21px Poppins",
    letterSpacing: "0px",
    color: "#000000",
    opacity: "1",
  }),
  multiValue: (provided: any, _: any) => ({
    ...provided,
    paddingRight: "2px",
    background: "unset",
    font: "normal normal normal 14px/21px Poppins",
    letterSpacing: "0px",
    color: " #000000",
  }),
};

export default SelectBox;
