import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import objectHash from "object-hash";
import {
  GiveOfferType,
  Step13Config,
} from "src/services/UiAutomation/ReturnExchange/Configuration/Step13/step13GetConfig";
import { ReasonOptions } from "src/services/UiAutomation/ReturnExchange/Configuration/Step13/step13GetReturnReasonOptions";
import { ReturnTypes } from "src/services/UiAutomation/ReturnExchange/Configuration/Step13/step13ReturnTypeOptions";
import { ConditionType } from "src/services/UiAutomation/ReturnExchange/Configuration/Step9/step9GetConfig";
import {
  ConditionOptions,
  conditionType,
} from "src/services/UiAutomation/ReturnExchange/Configuration/Step9/step9GetOptionsConditions";
import { v4 as uuid } from "uuid";
import step13Builders from "./Builders/step13.builder";
import { RecursivePartial } from "./Helpers/returnExchange.helpers";

export const questionTypes = [
  "Short Answer",
  "Long Answer",
  "Multiple Choice",
  "Dropdown",
  "Image Upload",
  "Information",
  "None",
];

export type IQUestionType = (typeof questionTypes)[number];

interface OptionType {
  option: string;
  error: null;
}

interface FormElement {
  formElId: string;
  formElSequence: number;
  formElQuestionType: IQUestionType;
  formElQuestion: {
    question: string;
    error: null;
  };
  options?: {
    id: string;
    value: string;
  }[];
}

export let formElementInitialState = {
  formElQuestionType: questionTypes[questionTypes.length - 1],
  formElQuestion: {
    question: "",
    error: null,
  },
  options: [{ id: uuid(), value: "" }],
};

export const formOptions: { [key in IQUestionType]: any } = {
  "Multiple Choice": {
    options: Array<OptionType>,
  },
  Dropdown: { options: Array<OptionType> },
  None: {},
};

export const giveOfferVariableNames = [
  "Partial refund of",
  "Discount of",
  "Offer clearance product for free",
  "Other",
] as const;

export type OfferVariableName = (typeof giveOfferVariableNames)[number];

interface Forms {
  formId: string;
  formName: string | null | undefined;
  formSequence: number;
  conditions: ConditionType[];
  formElements: FormElement[];
  giveOffer: GiveOfferType;
}

export interface step13AskDetails {
  selectedFormId: string | null;
  form: Forms[];
  error: string | null;
  initialHash: string;
  currentHash: string;
  isChanged: boolean;
  intialStep13Config: Forms[] | null;

  conditionOptions: conditionType[];
  returnReasons: ReasonOptions[];
  returnTypes: ReturnTypes[];
  getOrderType: Step13Config;

  // Condition Related
  variableNames: any;
  operatorsNames: any;
  operatorSymbolMap: any;
  valueTypes: any;
  variableNameToValueType: any;
}

const firstFormId = uuid();

export const initialState: step13AskDetails = {
  selectedFormId: firstFormId,

  form: [
    {
      conditions: [],
      formId: firstFormId,
      formName: "Form 1",
      formSequence: 1,
      formElements: [
        {
          formElId: uuid(),
          formElSequence: 1,
          formElQuestionType: "",
          formElQuestion: {
            error: null,
            question: "",
          },
          options: [{ id: uuid(), value: "" }],
        },
      ],
      giveOffer: {
        name: "Partial refund of",
        offerType: "",
        offerDescription: "",
        offerSelectedItems: [""],
        offerSelectedTags: [""],
      },
    },
  ],
  error: null,
  initialHash: "",
  currentHash: "",
  isChanged: false,
  intialStep13Config: null,

  conditionOptions: [],
  getOrderType: {
    data: [],
  },
  variableNames: [],
  operatorsNames: {},
  operatorSymbolMap: {},
  valueTypes: {},
  variableNameToValueType: {},

  returnReasons: [],
  returnTypes: [],
};

export const step13AskDetailsSlice = createSlice({
  name: "step13AskDetails",
  initialState,
  reducers: {
    // Add Return Form
    addForm: (state: step13AskDetails) => {
      const newFormTypeId = uuid();

      state.selectedFormId = newFormTypeId;

      state.form.push({
        formId: newFormTypeId,
        formName: ` Form ${state.form.length + 1}`,
        formSequence: state.form.length + 1,
        conditions: [],
        formElements: [],
        giveOffer: {
          name: "Partial refund of",
          offerType: "",
          offerDescription: "",
          offerSelectedItems: [""],
          offerSelectedTags: [""],
        },
      });
    },

    // Update Return Form
    updateForm(
      state: step13AskDetails,
      params: PayloadAction<{
        formDetail: RecursivePartial<Forms>;
      }>,
    ) {
      const { formId, formName } = params.payload.formDetail;

      const selectedIdx = state.form.findIndex((frm) => frm.formId === formId);

      const selectedForm = state.form[selectedIdx];

      if (formName) selectedForm.formName = formName;
    },

    // Delete Return Form
    deleteForm: (state, { payload }: { payload: { id: string } }) => {
      const selectedFormTypeIdx = state.form.findIndex(
        (i) => i.formId === payload.id,
      );

      if (selectedFormTypeIdx !== -1 && payload.id === state.selectedFormId) {
        state.selectedFormId =
          state.form.at(selectedFormTypeIdx + 1)?.formId ??
          state.form.at(selectedFormTypeIdx - 1)?.formId ??
          null;
      }

      state.form = state.form
        .filter((form, idx) => {
          return form.formId !== payload.id;
        })
        .map((form, idx) => {
          form.formSequence = idx + 1;
          return form;
        });
    },

    pushInitialHash: (state) => {
      state.initialHash = objectHash(state.form);
      state.intialStep13Config = state.form;
    },

    validateChanges: (state) => {
      const { initialHash } = state;
      const newHash = objectHash(state.form);
      state.isChanged = !(newHash === initialHash);

      state.currentHash = newHash;
    },

    resetHashes: (state) => {
      state.initialHash = "";
      state.currentHash = "";
    },

    undoConfig: (
      state,
      params: PayloadAction<{ initialConfig: Forms[] | null }>,
    ) => {
      if (params.payload.initialConfig !== null)
        state.form = params.payload.initialConfig;
    },

    // Add Form Element
    addFormElement: (
      state: step13AskDetails,
      params: PayloadAction<{ formId: string; formEl: string }>,
    ) => {
      const selectedForm = state.form.find(
        (frm) => frm.formId === params.payload.formId,
      );

      if (selectedForm !== undefined) {
        selectedForm.formElements.push({
          formElId: uuid(),
          formElSequence: selectedForm.formElements.length + 1,
          ...formElementInitialState,
        });
      }
    },

    deleteFormElement: (
      state: step13AskDetails,
      params: PayloadAction<{ formId: string; formElId: string }>,
    ) => {
      const { formId, formElId } = params.payload;

      const selectedFormIdx = state.form.findIndex(
        (form) => form.formId === formId,
      );
      if (selectedFormIdx !== -1) {
        const selectedForms = state.form[selectedFormIdx];
        selectedForms.formElements = selectedForms.formElements.filter(
          (formEl) => formEl.formElId !== formElId,
        );
      }
    },

    // Update Conditon
    updateFormElement(
      state: step13AskDetails,
      params: PayloadAction<{
        formId: string;
        formElToUpdate: RecursivePartial<FormElement>;
      }>,
    ) {
      const { formId, formElToUpdate } = params.payload;

      const selectedFormIdx = state.form.findIndex(
        (form) => form.formId === formId,
      );

      if (formElToUpdate && selectedFormIdx !== -1) {
        const selectedForm = state.form[selectedFormIdx];

        const formElements = selectedForm.formElements;

        const formElIdx = formElements.findIndex(
          (frmEl) => frmEl.formElId === formElToUpdate.formElId,
        );

        if (formElIdx !== -1) {
          const prevFormEl = formElements[formElIdx];
          Object.assign(prevFormEl, formElToUpdate);
        }
      }
    },

    addFormElementOption: (
      state: step13AskDetails,
      params: PayloadAction<{
        formId: string;
        formElId: string;
      }>,
    ) => {
      const selectedForm = state.form.find(
        (frm) => frm.formId === params.payload.formId,
      );

      if (selectedForm !== undefined) {
        const formElements = selectedForm.formElements;

        const formElIdx = formElements.findIndex(
          (frmEl) => frmEl.formElId === params.payload.formElId,
        );

        if (formElIdx !== -1) {
          const prevFormEl = formElements[formElIdx];
          prevFormEl.options?.push({ id: uuid(), value: "" });
        }
      }
    },

    deleteFormElementOption: (
      state: step13AskDetails,
      params: PayloadAction<{
        formId: string;
        formElId: string;
        optionId: string;
      }>,
    ) => {
      const selectedForm = state.form.find(
        (frm) => frm.formId === params.payload.formId,
      );

      if (selectedForm !== undefined) {
        const formElements = selectedForm.formElements;

        const formElIdx = formElements.findIndex(
          (frmEl) => frmEl.formElId === params.payload.formElId,
        );

        if (formElIdx !== -1) {
          const prevFormEl = formElements[formElIdx];
          prevFormEl.options = prevFormEl.options?.filter(
            (option) => option.id !== params.payload.optionId,
          );
        }
      }
    },

    updateFormElementOption: (
      state: step13AskDetails,
      params: PayloadAction<{
        formId: string;
        formElId: string;
        option: { id: string; value: string };
      }>,
    ) => {
      const selectedForm = state.form.find(
        (frm) => frm.formId === params.payload.formId,
      );

      if (selectedForm !== undefined) {
        const formElements = selectedForm.formElements;

        const formElIdx = formElements.findIndex(
          (frmEl) => frmEl.formElId === params.payload.formElId,
        );

        if (formElIdx !== -1) {
          const prevFormEl = formElements[formElIdx];
          const prevOption = prevFormEl.options?.find(
            (opt) => opt.id === params.payload.option.id,
          );
          if (prevOption !== undefined) {
            Object.assign(prevOption, params.payload.option);
          }
        }
      }
    },

    // Set Selected Return Method
    setSelectedFormId(
      state: step13AskDetails,
      params: PayloadAction<{ formId: string }>,
    ) {
      state.selectedFormId = params.payload.formId;
    },

    getStep13Config(
      state: step13AskDetails,
      params: PayloadAction<Step13Config>,
    ) {},

    getStep13OptionConditons(
      state: step13AskDetails,
      params: PayloadAction<ConditionOptions>,
    ) {
      state.conditionOptions = params.payload.data;
      state.conditionOptions.forEach((option) => {
        state.variableNames.push({ name: option.name, id: option.id });
        state.operatorsNames[option.name] = option.operators.map((operator) => {
          state.operatorSymbolMap[operator.value] = operator.name;
          return operator.name;
        });
        state.valueTypes[option.valueType] = null;
        state.variableNameToValueType[option.name] = option.valueType;
      });
    },

    // getStep13ReturnReasonOptions(
    //   state: step13AskDetails,
    //   params: PayloadAction<ReturnReasonOptions>
    // ) {},

    getReturnTypeOptions() {},
  },
  extraReducers: step13Builders,
});

export default step13AskDetailsSlice.reducer;

export const {
  addForm,
  updateForm,
  deleteForm,
  addFormElement,
  updateFormElement,
  deleteFormElement,
  setSelectedFormId,
  updateFormElementOption,
  deleteFormElementOption,
  addFormElementOption,
  pushInitialHash,
  validateChanges,
  undoConfig,
  resetHashes,
  getStep13Config,
  getStep13OptionConditons,
  // getStep13ReturnReasonOptions,
  getReturnTypeOptions,
} = step13AskDetailsSlice.actions;
