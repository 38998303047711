import ViewTemplateButton from "src/components/ViewTemplateButtonV2";
import { useAppSelector } from "src/store/store";
import styles from "./Editor.module.scss";
import TinyMCEContainer from "src/components/TinyMCEContainer";
import { TinyMCEEditorContainerProps } from "src/interfaces/TinyMCEContainer/ITinyMCEContainer";

interface Props extends TinyMCEEditorContainerProps {
  showView?: boolean;
  buttons?: any;
  selectedFiles?: any;
  setSelectedFiles?: Function;
  isFileUploading?: any;
  setIsFileUploading?: Function;
}

function Editor({
  showView = false,
  className = "",
  editorClassName = "",
  buttons = <></>,
  selectedFiles = [],
  setSelectedFiles = () => {},
  isFileUploading,
  setIsFileUploading = () => {},
  ...props
}: Props) {
  const scopes = useAppSelector(
    (state) => state.globals.currentUserData?.scopes,
  );

  return (
    <div className={`${styles.mainBox} p-2 mx-0 my-2`}>
      <div className={`my-1`}></div>
      {showView && scopes && scopes.includes("view_templates") && (
        <div className={`d-flex justify-content-between`}>
          <div></div>
          <ViewTemplateButton
            selectedTemplate={(templateText: string) => {
              if (props.onChange) {
                props.onChange(templateText);
              }
            }}
          />
        </div>
      )}
      <TinyMCEContainer
        className={`${styles.editorBox}`}
        editorClassName={`${
          editorClassName === "" ? styles.editor : editorClassName
        }`}
        buttons={buttons}
        disableAttachment={false}
        selectedFiles={selectedFiles}
        setSelectedFiles={setSelectedFiles}
        isFileUploading={isFileUploading}
        setIsFileUploading={setIsFileUploading}
        {...props}
      />
    </div>
  );
}

export default Editor;
