/**
 * This file defines a React component responsible for rendering external sources article informations
 *
 * @author @yuvaraj-busibud
 * @author @navjyot-busibud
 */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Modal } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import SearchBar from "src/components/SearchBar/SearchBar";
import ArticleBrand from "../ImportModal/Children/ArticleBrand/ArticleBrand";
import ArticleComp from "../ImportModal/Children/ArticleComp/ArticleComp";
import TableHeader from "../ImportModal/Children/ArticleComp/Children/ArticleData/TableHeader/TableHeader";
import SearchData from "../ImportModal/Children/ArticleComp/Children/SearchData/SearchData";
import ImportModal from "../ImportModal/ImportModal";
import styles from "./ExternalActiveSource.module.scss";
import useDebounce from "src/hooks/useDebounce";
import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import {
  Articles,
  GetAllExternalParams,
  Source,
  getAllExternal,
} from "src/services/Bot/AnswerSources/getAllExternal.service";
import Loader from "src/components/Loader";
import InfiniteScroll from "src/components/InfiniteScrollBothSide";
import useExternalActiveSource from "./useExternalActiveSource";
import useSearchExternalArticles from "./useSearchExternalArticles";
import SearchedArticles from "./SearchedArticles/SearchedArticles";
import { useChannel } from "src/hooks/pusherHooks/useChannel";
import { useAppSelector } from "src/store/store";
import {
  importSourceProgress,
  unBindImportSourceProgress,
} from "src/pusherServices/botProflies/importProgress";
import DeleteSourceModal from "../DeleteSourceModal/DeleteSourceModal";
import PlanUpgradeOverlay from "src/components/PlanUpgrade/PlanUpgradeOverlay/PlanUpgradeOverlay";
import emptyBackPng from "src/assets/images/emptyBack.png";
interface Props {
  showSideBar: boolean;
}
function ExternalActiveSource({ showSideBar }: Props) {
  const {
    handleBackClick,
    getAllData,
    isLoading,
    onHide,
    onShow,
    showModal,
    isRefetching,
    fetchNextPage,
    hasNextPage,
    refetch,
    status,
    fetchStatus,
    updateSource,
  } = useExternalActiveSource();

  // Initialize state variables
  const [search, setSearch] = useState("");
  const [selectedSourceId, setSelectedSourceId] = useState<number>(0);
  const [selectedSourceName, setSelectedSourceName] = useState<string>("");
  const SEARCH_INPUT_ID = "external-search-input";
  const globalChannel = useAppSelector(
    (state) => state.globals.currentUserData?.websocket_channel_name,
  );

  const disabledFeatures = useAppSelector(
    (state) => state.globals.currentUserData?.disabledFeatures,
  );

  const queryClient = useQueryClient();
  // state to show/hide delete modal
  const [showDelete, setShowDelete] = useState(false);
  const [selectedDeleteSource, setSelectedDeleteSource] = useState<Source>();
  // Delete modal open function
  const onShowDeleteModal = (source: Source) => {
    setSelectedDeleteSource(source);
    setShowDelete(true);
    // console.log("show");
  };
  //Delete  modal close function
  const onHideDeleteModal = () => {
    setShowDelete(false);
    // console.log("hide");
  };

  // Use a WebSocket channel for real-time updates
  const { channel, unsubscribe } = useChannel({
    channelName: globalChannel + "",
  });

  // Debounce the search term to reduce unnecessary requests
  const debouncedSearchTerm = useDebounce(search, 500);
  // Reference to the search input element
  const searchInputRef = useRef({
    focused: false,
  });

  // Generate an error message if certain conditions are met
  const errorMessage = useMemo(() => {
    if (
      status === "error" ||
      (status !== "success" && fetchStatus !== "fetching") ||
      (status !== "loading" && getAllData?.length === 0)
    ) {
      return (
        <div
          className={`${styles.noResultFound} d-flex justify-content-center align-items-center h-100`}
        >
          <div className={styles.noResultFoundImage}>
            <img src={emptyBackPng} alt="no external article added" />
          </div>
          <div className={styles.noResultFoundText}>Import from external sources has not been added yet</div>
          <div><button
            className={`mt-2 mt-md-0 ${styles.importBtn}`}
            onClick={onShow}
            disabled={
              disabledFeatures?.includes("external_source") ?? false
            }
          >
            +Import
          </button></div>


        </div>
      );
    }
  }, [status, fetchStatus, getAllData?.length]);

  // Reset selected source when the search term changes
  useEffect(() => {
    if (debouncedSearchTerm !== "") {
    } else {
      setSelectedSourceId(0);
    }
  }, [debouncedSearchTerm]);

  // Callback function for handling source progress updates
  const handleSourceProgressCallback = useCallback((res: any) => {
    const sourceData = res.data;
    const sourceId = sourceData?.external_source_id;
    if (sourceId) {
      const status = sourceData?.status;
      const importPercentage = sourceData?.importPercentage;
      const pagesImported = sourceData?.pagesImported;
      // Update the source information
      updateSource(sourceId, {
        status: status,
        importPercentage: importPercentage,
        pagesImported: pagesImported,
        noOfPagesBeingImported: pagesImported,
        totalArticleCount: pagesImported,
      });
      queryClient.invalidateQueries([`getSourceArticles/${sourceId}`]);
    }
  }, []);

  // Set up WebSocket channel for importing source progress
  useEffect(() => {
    if (channel) {
      importSourceProgress(channel, handleSourceProgressCallback);
    }
    // Clean up the WebSocket channel when component unmounts
    return () => {
      if (channel) {
        unBindImportSourceProgress(channel, handleSourceProgressCallback);
      }
    };
  }, [channel]);

  // Handle search input changes
  const handleSearch = useCallback(
    (value: string, sourceId: number, sourceUrl: string) => {
      setSearch(value);
      setSelectedSourceName(sourceUrl);
      setSelectedSourceId(sourceId);
      if (!searchInputRef.current.focused) {
        const inputElement = document.getElementById(SEARCH_INPUT_ID);
        // Focus on the search input element
        if (inputElement) {
          inputElement.focus();
        }
        searchInputRef.current.focused = true;
      }
    },
    [],
  );

  return (
    <div className={`p-2 p-md-3 ${styles.mainWrapper}`}>
      <div className="d-block">
        <div className="d-flex flex-column flex-md-row justify-content-between align-items-md-center w-100 ">
          <div className="d-flex">
            <div
              className={`cursor-pointer ${styles.backArrow}`}
              onClick={handleBackClick}
              role="button"
            >
              <span>
                <i className="fa-solid fa-arrow-left"></i>
              </span>
            </div>
            <div className="ms-2">
              <h3 className={`mb-0 ${styles.heading}`}>External</h3>
              <span className={`d-block ${styles.subHeading}`}>
                Cern will use these sources to answer the questions
              </span>
            </div>
          </div>
          <div className="d-flex mt-2 mt-md-0 flex-column flex-md-row">
            {getAllData?.length !== 0 && <SearchBar
              id={SEARCH_INPUT_ID}
              className={`${styles.search} px-2 me-md-2`}
              inputClassName={`${styles.input}`}
              placeholder={`Search page `}
              value={search}
              onChange={(e: {
                target: { value: React.SetStateAction<string> };
              }) => {
                setSearch(e.target.value);
              }}
              onBlur={() => {
                searchInputRef.current.focused = false;
              }}
            />}
            <PlanUpgradeOverlay
              showOverlay={
                disabledFeatures?.includes("external_source") ?? false
              }
              message="Upgrade to import additional sources"
            >
              <button
                className={`mt-2 mt-md-0 ${styles.importBtn} ${getAllData?.length === 0 ? styles.displayNone : null}`}
                onClick={onShow}
                disabled={
                  disabledFeatures?.includes("external_source") ?? false
                }
              >
                +Import
              </button>
            </PlanUpgradeOverlay>
            <Modal
              backdropClassName={`${styles.modalBack}`}
              show={showModal}
              onHide={onHide}
              dialogClassName={`${styles.modalDialog}`}
              contentClassName={`${styles.modalContent}`}
            >
              <ImportModal
                onHide={onHide}
                refechSources={refetch}
                channel={channel} // Passing the WebSocket channel to the modal
              />
            </Modal>
          </div>
        </div>

        {debouncedSearchTerm === "" && search === "" ? (
          <div>
            {isLoading || isRefetching ? (
              <div>
                <Loader />
              </div>
            ) : errorMessage ? (
              errorMessage
            ) : (
              <div>
                <InfiniteScroll
                  loadMoreFromBottom={fetchNextPage}
                  hasMoreBottom={hasNextPage}
                  className={`h-100 overflow-auto p-1`}
                >
                  {getAllData?.length === 0 ? (
                    <div className="text-center mt-5">
                      <h4>No Article found</h4>
                    </div>
                  ) : (
                    getAllData?.map((source, idx) => {
                      if (!source) {
                        return null;
                      }
                      return (
                        <ArticleComp
                          key={source.id}
                          search={search}
                          setSearch={handleSearch}
                          source={source}
                          updateSource={updateSource}
                          handleDeleteSource={onShowDeleteModal}
                        />
                      );
                    })
                  )}
                </InfiniteScroll>
              </div>
            )}
          </div>
        ) : (
          <SearchedArticles
            searchTerm={debouncedSearchTerm}
            sourceId={selectedSourceId}
            sourceName={selectedSourceName}
          />
        )}
      </div>
      <Modal
        dialogClassName={`${styles.deleteModalDialog}`}
        contentClassName={`${styles.deleteModalContent}`}
        show={showDelete}
        onHide={onHideDeleteModal}
        backdropClassName={`${styles.deleteModalBack}`}
      >
        <DeleteSourceModal
          selectedSource={selectedDeleteSource}
          onHide={onHideDeleteModal}
          updateSource={updateSource}
        />
      </Modal>
    </div>
  );
}

export default ExternalActiveSource;
