import Select from "react-select";
import styles from "./VisitorPage.module.scss";

const options = [
  {
    value: 'contains',
    label: (
      <>
        <div className={`d-flex align-items-center`}>
          <span className={`ms-1 ${styles.selectSpan}`}>Contains</span>{" "}
        </div>
      </>
    ),
  },

  {
    value:'isExactly',
    label: (
      <>
        <div className={`d-flex align-items-center`}>
          {" "}
          <span className={`ms-1 ${styles.selectSpan}`}>Is exactly</span>{" "}
        </div>
      </>
    ),
  },
];
const VisitorPage = ({goalValue,setGoalValue, goalValueType, setGoalValueType}:any) => {

  const handleOnSelect = (e:any) =>{
    setGoalValueType(e.value);
  };

  return (
    <>
      <div className={`d-flex align-itemss-center my-3`}>
        <div className={`w-50 d-flex align-items-center`}>
          <span className={`${styles.urlAdrress}`}>
            …and this page URL address
          </span>
        </div>
        <div className={`w-50`}>
          <Select options={options} className={`${styles.selItem}`}
           value={options.filter(function(option) {
            return option.value === goalValueType;
          })}  onChange={handleOnSelect}/>
        </div>
      </div>
      <div>
        <input data-lpignore="true" 
          type="text"
          className={`form-control ${styles.goalInput}`}
          id="goalName"
          value={goalValue}
          onChange={(e)=>setGoalValue(e.target.value)}
          placeholder="Type in the URL address for the page"
        />
        <span className={`${styles.note}`}>
          Note: your LiveChat tracking code must be installed on the page for
          the Goal to work properly.
        </span>
      </div>
    </>
  );
};
export default VisitorPage;
