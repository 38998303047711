import React from "react";
import ThemeBox from "../ArticleSideBar/ThemeSelection/Children/ThemeBox/ThemeBox";
import CodeSetting from "./Children/CodeSetting/CodeSetting";
import styles from "./CustomCode.module.scss";
import useThemeBox from "../ArticleSideBar/ThemeSelection/Children/ThemeBox/useThemeBox";
import Loader from "src/components/Loader";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { useNavigate } from "react-router-dom";

const CustomCode = () => {
  const navigate = useNavigate();
  const { isError, isLoading } = useThemeBox();
  // If isLoading is true, it displays a loading spinner using the Loader component.
  if (isLoading) {
    return (
      <>
        <div className={`${styles.loaderWrapper}`}>
          <span>
            <Loader />
          </span>
        </div>
      </>
    );
  }
  // If an error occurs (isError is true), a toast message is pushed using the pushTheToast function from the ToastContainer module. The error message is displayed with the errorMsg class using the styles.errorMsg style.
  if (isError) {
    pushTheToast({
      position: "top-right",
      text: "Something went wrong",
      type: "danger",
    });
    // the component renders the main content of the Custom Code section.
    return (
      <>
        {" "}
        <div className={`p-3 w-100`}>
          <span className={`${styles.errorMsg}`}>Something Went Wrong</span>
        </div>
      </>
    );
  }

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className={`w-100 pt-4 px-3 ${styles.customeCodeWrapper}`}>
          <div className="mb-4 d-flex">
            <div>
              <div
                className={`d-flex justify-content-center align-items-center ${styles.backChat}`}
                onClick={() => navigate(-1)}
              >
                <i className="fa-solid fa-arrow-left"></i>
              </div>
            </div>
            <div>
              <h3 className={`mb-0 ${styles.heading} saufter_h3`}>
                Style Helpcenter page / Custom Code
              </h3>
              <p className={`${styles.subHeading} saufter_subHeading`}>
                Let customers style the help center right inside your chat
                widget instead of starting a conversation.
              </p>
            </div>
          </div>
          <div className={`d-block d-md-flex`}>
            <ThemeBox />
            <CodeSetting />
          </div>
        </div>
      )}
    </>
  );
};

export default CustomCode;
