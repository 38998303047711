import { useCallback } from "react";
import styles from "./Pagination.module.scss";

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  setCurrentPage: (currentPage: number) => void;
  showLR?: boolean;
  size?: number;
  colorCombo?: "pwg" | "bg";
  showLastTwoPages?: boolean;
}

function findPages(
  totalPages: number,
  currentPage: number,
  showLastTwoPages: boolean
) {
  const pagesArray: Array<{
    value: number;
    label: number | "...";
    start: number | null;
    end: number | null;
  }> = [];
  if (totalPages <= 7) {
    // If there are less than or equal to 7 pages
    for (let i = 1; i <= totalPages; i++) {
      pagesArray.push({
        value: i,
        label: i,
        start: null,
        end: null,
      });
    }
  } else if (currentPage < 6) {
    // If current page is in first five pages
    // Add first 5 pages and ...
    for (let i = 1; i < 7; i++) {
      pagesArray.push({
        value: i,
        label: i === 6 ? "..." : i,
        start: i === 6 ? i : null,
        end: i === 6 ? totalPages - 1 : null,
      });
    }
    // If we need to show two pages at end
    if (showLastTwoPages) {
      // Push the last second page
      pagesArray.push({
        value: totalPages - 1,
        label: totalPages - 1,
        start: null,
        end: null,
      });
    }
    // Add the last page
    pagesArray.push({
      value: totalPages,
      label: totalPages,
      start: null,
      end: null,
    });
  } else if (currentPage > totalPages - 5) {
    // If current page in in last 5
    // Add first page
    pagesArray.push({
      value: 1,
      label: 1,
      start: null,
      end: null,
    });
    // Add last 5 pages and ...
    for (let i = totalPages - 5; i <= totalPages; i++) {
      pagesArray.push({
        value: i,
        label: i === totalPages - 5 ? "..." : i,
        start: i === totalPages - 5 ? 2 : null,
        end: i === totalPages - 5 ? i : null,
      });
    }
  } else {
    // If current page is not in first five or last five
    // Add first page
    pagesArray.push({
      value: 1,
      label: 1,
      start: null,
      end: null,
    });
    // Add previous and next page from current page
    for (let i = currentPage - 2; i <= currentPage + 2; i++) {
      pagesArray.push({
        value: i,
        label: i === currentPage - 2 || i === currentPage + 2 ? "..." : i,
        start: i === currentPage - 2 ? 2 : i === currentPage + 2 ? i : null,
        end:
          i === currentPage - 2
            ? i
            : i === currentPage + 2
            ? totalPages - 1
            : null,
      });
    }
    // If we need to show last two pages
    if (showLastTwoPages) {
      // Show last second page
      pagesArray.push({
        value: totalPages - 1,
        label: totalPages - 1,
        start: null,
        end: null,
      });
    }
    // Push the last page
    pagesArray.push({
      value: totalPages,
      label: totalPages,
      start: null,
      end: null,
    });
  }
  return pagesArray;
}

function Pagination({
  currentPage,
  totalPages,
  setCurrentPage,
  showLR = true,
  size = 25,
  colorCombo = "pwg",
  showLastTwoPages = false,
}: PaginationProps) {
  const setPage = useCallback((e: any) => {
    if (!e.currentTarget.classList.contains(styles.disabled)) {
      const pageNumber = parseInt(e.currentTarget.getAttribute("page-number"));
      if (pageNumber) {
        setCurrentPage(pageNumber);
      } else {
        e.currentTarget.firstElementChild.classList.add(styles.show);
        e.currentTarget.firstElementChild.firstElementChild.focus();
      }
    }
  }, []);
  const setPageInput = useCallback((e: any) => {
    if (e.key === "Enter") {
      e.target.blur();
    }
  }, []);
  const setPageBlur = useCallback((e: any) => {
    const pageNumber = parseInt(e.currentTarget.value);
    if (pageNumber > 0 && pageNumber <= totalPages) {
      e.currentTarget.parentElement.classList.remove(styles.show);
      setCurrentPage(pageNumber);
    }
  }, []);
  return (
    <div className={`d-flex justify-content-end ${styles[colorCombo]}`}>
      <div className={`d-flex`}>
        {showLR && (
          <div
            className={`${styles.arrow} ${styles.left} ${
              currentPage < 2 || totalPages === 0 ? styles.disabled : ""
            } mx-1`}
            page-number={currentPage - 1}
            style={{ width: size + "px", height: size + "px" }}
            onClick={setPage}
          ></div>
        )}
        {findPages(totalPages, currentPage, showLastTwoPages).map(
          ({ value, label, start, end }, key) => {
            return (
              <div
                className={`${styles.circle} ${
                  currentPage === value && styles.active
                } mx-1`}
                key={key}
                page-number={label === "..." ? 0 : value}
                onClick={setPage}
                style={{ width: size + "px", height: size + "px" }}
              >
                {label === "..." && (
                  <div className={`${styles.pageInput}`}>
                    <input
                      data-lpignore="true"
                      type="number"
                      min={start ? start : 1}
                      max={end ? end : 1}
                      defaultValue={value}
                      onKeyUp={setPageInput}
                      onBlur={setPageBlur}
                      style={{ width: size + "px", height: size + "px" }}
                    />
                  </div>
                )}
                <span>{label}</span>
              </div>
            );
          }
        )}
        {showLR && (
          <div
            className={`${styles.arrow} ${styles.right} ${
              currentPage === totalPages || totalPages === 0
                ? styles.disabled
                : ""
            } mx-1`}
            style={{ width: size + "px", height: size + "px" }}
            page-number={currentPage + 1}
            onClick={setPage}
          ></div>
        )}
      </div>
    </div>
  );
}

export default Pagination;
