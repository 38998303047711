/**
 * This file is the custome hook file.
 * It contains useFiltersApplied custome hook to show filters.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { useQuery } from "@tanstack/react-query";
import React, { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  reportCurrentStatusActions,
  useReportFilters,
} from "src/routes/Report/hooks/reportFilters/useReportFilters";
import { getChannelList } from "src/services/InnerTicket/getChannelList";
import { fetchTicketsTagsApi } from "src/services/TicketService/fetchTicketsTags";
import { getAllStatuses } from "src/services/TicketService/getAllStatuses";
import { getAllCategoriesForReports } from "src/services/report/botReportsStaticData.service";
import { CurrentStatusEndPoints } from "src/services/report/reportTicketList.service";
import { TicketTags } from "src/store/slices/ticketTags/ticketTags.slice";
import { useAppSelector } from "src/store/store";
import useCustomAnswer from "../../Children/FilterWrapper/children/CustomAnswer/useCustomAnswer";

interface Props {
  endPoint: CurrentStatusEndPoints;
}

/**
 * This hook is created to find the applied filters and show them.
 * useQuery is used at different places to fetch the filter data.
 */
function useFiltersApplied({ endPoint }: Props) {
  const { profileId } = useParams();
  const { currentStatus, dispatch } = useReportFilters();
  const { data: allTicketStatus } = useQuery(
    ["useStatusFilter/getTicketStatusList"],
    {
      queryFn: getAllStatuses,
      staleTime: 600000,
      cacheTime: 600000,
    },
  );

  const { data: allChannels } = useQuery(["useChannelFilter/getChannelList"], {
    queryFn: async () => {
      return getChannelList({ context: "reports" });
    },
    staleTime: 600000,
    cacheTime: 600000,
    enabled: endPoint.startsWith("tickets"),
  });

  const { data: allTags } = useQuery(["useTagFilter/fetchTicketsTagsApi"], {
    queryFn: async () =>
      fetchTicketsTagsApi() as Promise<{
        allTags: TicketTags;
        allTagsIds: number[];
      }>,
    staleTime: 600000,
    cacheTime: 600000,
  });

  // 23 Apr 2024 Commenting this api call as related filter is hided for now
  // const { data: botCategories } = useQuery(
  //   ["botCategories/getAllCategoriesForReports", profileId],
  //   {
  //     queryFn: async () => {
  //       if (profileId) {
  //         return getAllCategoriesForReports({ botId: profileId });
  //       }
  //     },
  //     enabled: profileId ? true : false,
  //     staleTime: 600000,
  //     cacheTime: 600000,
  //   },
  // );

  const allBrands = useAppSelector(
    (state) => state.globals.brandSignatureData.brands,
  );
  const agentTypes = useMemo(() => {
    return [
      { id: "agent", name: "Agent" },
      { id: "lead_agents", name: "Lead Agents" },
      { id: "admin", name: "Admin" },
      { id: "support", name: "Support" },
    ];
  }, [false]);

  // 23 Apr 2024 Commenting this api call as related filter is hided for now
  // const {
  //   allCustomQA
  // } = useCustomAnswer();

  /**
   * Clearing any filter.
   */
  const clearAFilter = useCallback((key: keyof typeof currentStatus) => {
    dispatch([reportCurrentStatusActions.clearOneAppliedFilter, key]);
  }, []);

  /**
   * Finding applied filters by computing
   */
  const filtersApplied = useMemo(() => {
    const fa: Array<{ name: string; value: string; callback: () => void }> = [];
    if (currentStatus.selectedTicketStatus.length) {
      fa.push({
        name: "Ticket Status",
        value:
          allTicketStatus?.allTicketStatusesIds
            .filter((data) =>
              currentStatus.selectedTicketStatus.includes((data + "") as any),
            )
            .map((data) => allTicketStatus.allTicketStatuses[data].statusName)
            .join(", ") ?? "",
        callback: () => {
          clearAFilter("selectedTicketStatus");
        },
      });
    }
    if (currentStatus.selectedChannels.length) {
      fa.push({
        name: "Channels",
        value: Object.keys(allChannels ?? {})
          .filter((data) =>
            currentStatus.selectedChannels.includes((data + "") as any),
          )
          .map((data) => (allChannels ? allChannels[data].name : ""))
          .join(", "),
        callback: () => {
          clearAFilter("selectedChannels");
        },
      });
    }
    // Check if there are selectedChatStatus values in currentStatus
    if (currentStatus.selectedChatStatus.length) {
      // Construct an object representing the Chat Status filter and push it to the 'fa' array
      fa.push({
        name: "Chat Status",
        value:
          // If there are exactly 2 selected chat statuses, set 'value' to "all"
          currentStatus.selectedChatStatus.length === 2
            ? "all"
            : currentStatus.selectedChatStatus[0],
        // Provide a callback function to clear this specific filter
        callback: () => {
          clearAFilter("selectedChatStatus");
        },
      });
    }

    // Check if isSelectedAssigned in currentStatus is not null
    if (currentStatus.isSelectedAssigned !== null) {
      // Construct an object representing the Assignment filter and push it to the 'fa' array
      fa.push({
        name: "Assignment",
        value: currentStatus.isSelectedAssigned ? "assigned" : "unassigned",
        // Provide a callback function to clear this specific filter
        callback: () => {
          clearAFilter("isSelectedAssigned");
        },
      });
    }
    if (currentStatus.selectedAgentTypes.length) {
      fa.push({
        name: "Agents",
        value: agentTypes
          .filter((data) =>
            currentStatus.selectedAgentTypes.includes((data.id + "") as any),
          )
          .map((data) => data.name)
          .join(", "),
        callback: () => {
          clearAFilter("selectedAgentTypes");
        },
      });
    }
    if (currentStatus.selectedTags.length) {
      fa.push({
        name: "Tags",
        value:
          allTags?.allTagsIds
            .filter((data) =>
              currentStatus.selectedTags.includes((data + "") as any),
            )
            .map((data) => allTags.allTags[data].tagName)
            .join(", ") ?? "",
        callback: () => {
          clearAFilter("selectedTags");
        },
      });
    }
    if (currentStatus.selectedBrands.length) {
      fa.push({
        name: "Brands",
        value:
          allBrands
            ?.filter((data) =>
              currentStatus.selectedBrands.includes((data.id + "") as any),
            )
            .map((data) => data.name)
            .join(", ") ?? "",
        callback: () => {
          clearAFilter("selectedBrands");
        },
      });
    }
    // 23 Apr 2024 Commenting these as related filter is hided for now
    // if (currentStatus.selectedCategoriesBot.length) {
    //   fa.push({
    //     name: "All Categories",
    //     value:
    //       botCategories
    //         ?.filter((data) =>
    //           currentStatus.selectedCategoriesBot.includes(
    //             (data.categoryId + "") as any,
    //           ),
    //         )
    //         .map((data) => data.categoryName)
    //         .join(", ") ?? "",
    //     callback: () => {
    //       clearAFilter("selectedCategoriesBot");
    //     },
    //   });
    // }
    // if (currentStatus.selectedCustomQABot.length) {
    //   fa.push({
    //     name: "All Custom answers ",
    //     value:
    //       allCustomQA
    //         ?.filter((data) =>
    //           currentStatus.selectedCustomQABot.includes(
    //             (data.customQAId + "") as any
    //           )
    //         )
    //         .map((data) => data.customQAName)
    //         .join(", ") ?? "",
    //     callback: () => {
    //       clearAFilter("selectedCustomQABot");
    //     },
    //   });
    // }
    return fa;
  }, [
    currentStatus,
    allTicketStatus,
    allBrands,
    allChannels,
    allTags,
    agentTypes,
    // botCategories,
  ]);

  return { filtersApplied };
}

export default useFiltersApplied;
