import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";

export interface getRefundRequestListParam {
  integrationId: number | string;
}

const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/getRefundRequestList`
  : "/api/setting/automation/refund/step/12/getRefundRequestList";


export async function fetchGetRefundRequestList(
    params : getRefundRequestListParam
) {
  const {data} = await axiosJSON.get(
    apiEndPoint,
    {params}
  );

  if (data.error === true) {
    throw new Error("Some Error Occurred while fetching data");
  }

  return data.data;
}
