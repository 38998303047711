import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { returnIntegrationData } from "src/store/slices/initiateReturn/retrunExchange.slice";
import { fetchAllReturnIntegrations } from "src/store/slices/initiateReturn/returnExchange.thunk";
import { useAppDispatch, useAppSelector } from "src/store/store";

interface Props {
  fetchLimit: number;
}

//custom hook to fetch retun integrations
export const useFetchReturnIntegration = ({ fetchLimit }: Props) => {
  const [initialFetchLoading, setInitialFetchLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    integrationIds,
    integrations,
    integrationMetaData,
    fetchAllIntegrationsAjaxStatus,
  } = useAppSelector((state) => state.returnExchange);
  const dispatch = useAppDispatch();
  const currentState = useRef({
    initialFetchState: false,
  });

  useEffect(() => {
    if (!currentState.current.initialFetchState) {
      //resetting previous data
      dispatch(returnIntegrationData());
      //fetching integrations on load
      dispatch(fetchAllReturnIntegrations({ start: 0, limit: fetchLimit }));
      currentState.current.initialFetchState = true;
    }
  }, [dispatch, fetchLimit]);

  //function to reset fetched data
  const resetIntegrationData = useCallback(() => {
    dispatch(returnIntegrationData());
  }, [dispatch]);

  useEffect(() => {
    if (fetchAllIntegrationsAjaxStatus === "pending") {
      if (currentState.current.initialFetchState) {
        setInitialFetchLoading(true);
        currentState.current.initialFetchState = false;
      }
      setLoading(true);
    } else {
      setLoading(false);
      setInitialFetchLoading(false);
    }
  }, [fetchAllIntegrationsAjaxStatus]);

  //helper function to checks if there is more integrations needs to be fetched or not , used for infinite scroll
  const hasMoreIntegrations = useMemo(() => {
    return fetchAllIntegrationsAjaxStatus === "rejected"
      ? false
      : integrationMetaData.total === null
        ? true
        : Number(integrationIds.length) < Number(integrationMetaData.total);
  }, [fetchAllIntegrationsAjaxStatus, integrationMetaData, integrationIds]);

  const handleInfiniteScroll = useCallback(() => {
    if (fetchAllIntegrationsAjaxStatus !== "pending") {
      dispatch(
        fetchAllReturnIntegrations({
          start: integrationIds.length,
          limit: fetchLimit,
        }),
      );
    }
  }, [
    fetchAllIntegrationsAjaxStatus,
    dispatch,
    integrationIds.length,
    fetchLimit,
  ]);

  return {
    integrationIds,
    integrations,
    totalIntegrations: integrationMetaData.total,
    resetIntegrationData,
    loading,
    hasMoreIntegrations,
    handleInfiniteScroll,
    initialFetchLoading,
  };
};
