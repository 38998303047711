import { axiosJSON } from "src/globals/axiosEndPoints";
import {  ITicketChatFormDataV3 } from "src/store/slices/liveChatSetting/Forms/chatForm.declarations";
import { AskForEmailWhenAgentOfflineData } from "./updateAskForEmailDataV3";


interface GetAskForEmailDataParam{
  integrationId: string | number
}

interface AskForEmailTicketForm extends AskForEmailWhenAgentOfflineData{ 
  ticketChatFormData: ITicketChatFormDataV3;
}


export interface GetAskFormEmailData{
  formId: number | string;
  whenAgentOffline: {
    outsideBusinessHours: AskForEmailTicketForm;
    withinBusinessHours: AskForEmailTicketForm;
  };
  whenAgentNotResponding: {
    canSendMessage: boolean | null;
    sendMessageAfter: number | string | null;
    messageText: string | null;
    confirmationText: string | null;
    canCustomerLeaveEmail: boolean | null;
  };
  availability: {
    canCustomerStartChatAlways: boolean;
  };
}

export async function getAskForEmailData(params: GetAskForEmailDataParam) {
  const { data } = await axiosJSON.post(
    `/api/chatSetting/forms/getAskForEmail`,
    params
  );
  if (data.error === true) {
    throw new Error(data.message as string);
  }

  return data.data as GetAskFormEmailData;
}



