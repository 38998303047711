import Loader from "src/components/Loader";
import { useReturnAutoWorkFlow } from "../../hooks/useReturnAutoWorkFlow";
import { useMemo } from "react";
import StepDetails from "./children/StepDetails/StepDetails";
import styles from "./BaseSteps.module.scss";
import GoLiveStep from "./children/GoLiveStep/GoLiveStep";

/**
 * StepsHeading component.
 *
 * @returns Element for step heading.
 */
function StepsHeading({ showWorkflow }: { showWorkflow: boolean }) {
  return (
    <div>
      <span className={`${styles.subheading}`}>
        {
          "Please provide us with the following details to set up an automation system for returns. "
        }
        {showWorkflow && <span>See workflow</span>}
      </span>
    </div>
  );
}

/**
 * BaseSteps component.
 *
 * This component handles the rendering of the base questions within the return
 * auto workflow. It displays a loader during pending states, an error message
 * during rejected states, and the step details during fulfilled states.
 *
 * @returns The rendered component.
 */
function BaseSteps() {
  const { returnAutoWorkFlow } = useReturnAutoWorkFlow();
  const stepsSaved = useMemo(() => {
    return returnAutoWorkFlow.savedStepAndQuestionIds ?? [];
  }, [returnAutoWorkFlow]);

  return (
    <div className={`col-12 col-md-8 ${styles.leftSideSection} pb-5`}>
      {returnAutoWorkFlow.loadingReturnAutomationWorkFlow === "pending" && (
        <div className={`${styles.loaderHeight}`}>
          <Loader className={`${styles.loader}`} />
        </div>
      )}
      {returnAutoWorkFlow.loadingReturnAutomationWorkFlow === "rejected" && (
        <div className={`${styles.loaderHeight}`}>
          <div className="text-danger text-center">
            Error Loading Content...
          </div>
        </div>
      )}
      {returnAutoWorkFlow.loadingReturnAutomationWorkFlow === "fulfilled" && (
        <div className={`px-3 pt-3`}>
          <StepsHeading showWorkflow={false} />
          <div className={`d-flex mt-3 px-1 py-2`}>
            <div className={`col-md-12`}>
              {stepsSaved.map((step, index) => {
                return (
                  <StepDetails
                    key={step.stepId}
                    {...step}
                    stepNumber={index + 1}
                  />
                );
              })}
              {returnAutoWorkFlow.configured ? (
                <GoLiveStep stepNumber={stepsSaved.length + 1} />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default BaseSteps;
