import { useEffect, useMemo, useState } from "react";
import { Col, Row, Tab } from "react-bootstrap";
import SkeletonModalStructure from "src/components/AutomationComponents/SkeletonModalStructure/SkeletonModalStructure";
import Loader from "src/components/Loader";
import styles from "./ActionsModal.module.scss";
import {
  ActionGroup,
  IActionForModal,
} from "src/services/Automation/getAutomationById.service";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useActionsProvider } from "../../../hooks/useActionsState";
import ActionCondition from "./Components/ActionCondition/ActionCondition";
import useFetchActionOptions from "../../../hooks/useActionConditionOptions";
interface Props {
  handleCloseModal: () => void;
  conditionGroups: ActionGroup[];
  updateConditionGroup: (newGroup: ActionGroup) => void;
  setShowErrorModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ActionsModal = ({
  handleCloseModal,
  conditionGroups,
  updateConditionGroup,
  setShowErrorModal,
}: Props) => {
  const { type, integrationId } = useParams();
  const { actionOptions, actionOptionsStatus } = useFetchActionOptions(
    type ?? "",
    integrationId ?? "",
  );
  const { ActionsProvider, ...contexValue } = useActionsProvider();

  useMemo(() => {
    contexValue.updateState({
      actionOptions: actionOptions,
    });
  }, [actionOptions]);

  useMemo(() => {
    contexValue.updateState({
      conditionGroup: conditionGroups[0],
    });
  }, []);

  const addCondition = () => {
    if (contexValue.conditionGroup) {
      const newActions: IActionForModal[] = [
        ...contexValue.conditionGroup?.conditions,
      ];
      const actionUUID = uuidv4();
      if (newActions.length > 0) {
        newActions[newActions.length - 1].nextConditionUUID = actionUUID;
      }
      newActions.push({
        conditionUUID: actionUUID,
        conditonId: null,
        nextConditionUUID: null,
        fieldKey: null,
        value: null,
      });
      contexValue.updateState({
        conditionGroup: {
          ...contexValue.conditionGroup,
          conditions: newActions,
        },
      });
    }
  };

  const handleDiscard = () => {
    contexValue.resetState();
    handleCloseModal();
  };

  /**
   * Filter the actionOptions when changeStatus or assignToAgent is already added in conditions
   */
  const filteredActionOptions = useMemo(() => {
    // Extract fieldKeys that are already in conditions
    const existingConditionKeys = contexValue?.conditionGroup?.conditions.map(
      (condition) => condition.fieldKey,
    );

    // Filter out action options if their fieldKey is already in conditions
    const filteredOptions = actionOptions?.actionOptions.filter(
      (option) =>
        !(
          existingConditionKeys &&
          existingConditionKeys.includes(option.fieldKey) &&
          (option.fieldKey === "assignToAgent" ||
            option.fieldKey === "changeStatus")
        ),
    );
    return filteredOptions ?? [];
  }, [actionOptions, contexValue.conditionGroup]);

  return (
    <ActionsProvider value={contexValue}>
      <SkeletonModalStructure
        hasTwoSection={false}
        heading={`Action to be performed`}
        closeFunction={handleDiscard}
      >
        {actionOptionsStatus === "pending" ? (
          <div className="h-100 w-100 d-flex align-items-center justify-content-center">
            <Loader />
          </div>
        ) : actionOptionsStatus === "rejected" ? (
          <div className="h-100 w-100 d-flex align-items-center justify-content-center text-danger">
            Error loading content
          </div>
        ) : (
          <Tab.Container
            activeKey={""}
            onSelect={(eventKey) => {}}
          >
            <Row bsPrefix={`row`}>
              <Col>
                <div className={`${styles.contentStyles}`}>
                  {/* Actions */}
                  {contexValue.conditionGroup &&
                  contexValue.conditionGroup?.conditions?.length > 0 ? (
                    <div>
                      {contexValue.conditionGroup?.conditions?.map(
                        (actionCondition, actionIndex) => {
                          return (
                            <ActionCondition
                              key={actionCondition.conditionUUID}
                              condition={actionCondition}
                              conditionIndex={actionIndex}
                              filteredActionOptions={filteredActionOptions}
                            />
                          );
                        },
                      )}
                    </div>
                  ) : null}
                  <div
                    onClick={addCondition}
                    className="d-flex align-items-center mt-3 pb-2 cursor-pointer"
                  >
                    <div
                      className={`d-flex align-items-center justify-content-center me-2 me-md-0 mb-2 ${styles.addMore}`}
                    >
                      +
                    </div>
                    <div
                      className={`${styles.blueText} ms-2 `}
                      role="button"
                    >
                      Add Condition
                    </div>
                  </div>
                </div>
                {/* Footer Buttons */}
                <div
                  className={`p-3 d-flex justify-content-end ${styles.btnWrapper}`}
                >
                  <button
                    className={`${styles.cancelBtn}`}
                    onClick={handleDiscard}
                  >
                    Cancel
                  </button>
                  <button
                    className={`ms-3 ${styles.saveBtn}`}
                    onClick={() =>
                      contexValue.handleSave(
                        type ?? "",
                        updateConditionGroup,
                        setShowErrorModal,
                      )
                    }
                    id="actionsSaveBtn"
                    disabled={contexValue.saveStatus === "pending"}
                  >
                    {contexValue.saveStatus === "fulfilled"
                      ? "Saved!"
                      : contexValue.saveStatus === "pending"
                        ? "Saving..."
                        : "Save"}
                  </button>
                </div>
              </Col>
            </Row>
          </Tab.Container>
        )}
      </SkeletonModalStructure>
    </ActionsProvider>
  );
};

export default ActionsModal;
