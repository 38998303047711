import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import objectHash from "object-hash";
import {
  addConditionHandler,
  ConditionTypes,
  createNewObjectHash,
  deleteConditionHelper,
  deleteTabHelper,
  RecursivePartial,
  updateConditionHelper,
  validateChangeHandler,
  validateConditionsHelper,
} from "src/features/ReturnAutoWorkFlow/children/ConfigModals/store/returnModalsSliceHelpers";
import { AJAXSTATUS } from "src/globals/constants";
import { RestockingFeeTab } from "src/services/ReturnAutoWorkFlow/OldModals/restockingFee/getRestockingFee.service";
import { ReasonTypes } from "src/services/UiAutomation/ReturnExchange/Configuration/Step12/step12GetConfig";
import { ReturnReason } from "src/store/slices/initiateReturn/step14Configuration.slice";
import { RootState } from "src/store/store";
import { v4 as uuid } from "uuid";
import { ConfigConditionType } from "../../../oldReturnModals.types";
import restockingFeeBuilders from "./restockingFee.builder";

interface EnableDisableTypes {
  currentState: boolean;
  amount: number;
  // showInstruction: boolean;
}

export interface IRestockingFeeType {
  price: string;
  priceType: string;
}

interface RestockingFee {
  currentState: boolean;
  amount: number;
  priceType: string;
  error: string | null;
}

export interface AdditionalCharges {
  additionalChargeId: string;
  additionalChargeName: string | null | undefined;
  additionalChargeSequence: number;
  conditions: ConditionTypes[];
  error: string | null;
  itemError: string | null;
  selectReturnReason: ReturnReason[];
  selectReturnReasonError: string | null;
  isAddSelectReasonConditionEnabled: boolean;
  isSelectReasonEnabled: boolean;
  errorCount: number;
  isAddConditionEnabled: boolean;
  isAddOrderTypeConditionEnabled: boolean;
  restockingFees: RestockingFee;
  customShippingCharges: EnableDisableTypes;
  isValid: boolean;
  messageForCustomer: {
    value: string | undefined;
    error: string | null;
  };
}

// interface AllOrderAndItemTabType {
//   id: string;
//   error: string | undefined;
//   restockingFees: EnableDisableTypes;
//   customShippingCharges: EnableDisableTypes;
// }

export interface RestockingFeeState {
  selectedAdditionalChargeId: string | null;
  additionalCharges: AdditionalCharges[];
  // allOrderAndItemTab: AllOrderAndItemTabType;
  error: string;
  initialHashStaticTab: string;
  initialHashOtherTab: string;
  currentHashStaticTab: string;
  currentHashOtherTab: string;
  excludeKeysForHash: string[];
  isChanged: boolean;
  intialStep15Config: {
    additionalCharges: AdditionalCharges[] | null;
    // allOrderAndItem: AllOrderAndItemTabType | null;
  };
  returnReasonList: ReasonTypes[]; // Dec 20 Adding property to store default return reasons to check for validations based on moreQuestions
  conditionOptions: ConfigConditionType[];
  defaultOptions: ConfigConditionType[];
  itemOptions: ConfigConditionType[];
  selectReasonOptions: ConfigConditionType[];

  getItemType: RestockingFeeTab[];

  variableNames: any;
  operatorsNames: any;
  operatorSymbolMap: any;
  valueTypes: any;
  variableNameToValueType: any;
  fetchAjaxStatus: AJAXSTATUS;
  updateAjaxStatus: AJAXSTATUS;
}

const excludeKeys = [
  "error",
  "isAddConditionEnabled",
  "isAddOrderTypeConditionEnabled",
  "isValid",
  "errorCount",
  "itemConditionError",
  "additionalChargeSequence",
  "selectReturnReasonError",
];

const firstAdditionalChargeId = uuid();

export const initialState: RestockingFeeState = {
  selectedAdditionalChargeId: firstAdditionalChargeId,
  additionalCharges: [
    {
      additionalChargeId: firstAdditionalChargeId,
      additionalChargeName: "All orders and items",
      additionalChargeSequence: 1,
      conditions: [],
      error: null,
      itemError: null,
      selectReturnReasonError: null,
      isAddSelectReasonConditionEnabled: false,
      isSelectReasonEnabled: false,
      selectReturnReason: [],
      errorCount: 0,
      isAddConditionEnabled: true,
      isAddOrderTypeConditionEnabled: true,
      restockingFees: {
        amount: 0,
        currentState: false,
        priceType: "fixed",
        error: null,
      },
      customShippingCharges: { amount: 0, currentState: false },
      isValid: false,
      messageForCustomer: {
        value: "",
        error: null,
      },
    },
  ],
  // allOrderAndItemTab: {
  //   customShippingCharges: {
  //     amount: 0,
  //     currentState: false,
  //   },
  //   error: "",
  //   id: "1",
  //   restockingFees: {
  //     amount: 0,
  //     currentState: false,
  //   },
  // },
  error: "",

  initialHashStaticTab: "",
  initialHashOtherTab: "",
  currentHashStaticTab: "",
  currentHashOtherTab: "",
  excludeKeysForHash: excludeKeys,

  isChanged: false,
  intialStep15Config: {
    // allOrderAndItem: null,
    additionalCharges: null,
  },

  conditionOptions: [],
  defaultOptions: [],
  itemOptions: [],
  getItemType: [],
  selectReasonOptions: [],
  returnReasonList: [],

  variableNames: [],
  operatorsNames: {},
  operatorSymbolMap: {},
  valueTypes: {},
  variableNameToValueType: {},
  fetchAjaxStatus: "pending",
  updateAjaxStatus: "idle",
};

export const restockingFeeSlice = createSlice({
  name: "restockingFee",
  initialState,
  reducers: {
    // Add Method
    addAdditionalCharge: (
      state: RestockingFeeState,
      params: PayloadAction<{ isSelectReasonEnabled: boolean }>,
    ) => {
      const newAdditionalChargeId = uuid();
      const { isSelectReasonEnabled } = params.payload;
      state.selectedAdditionalChargeId = newAdditionalChargeId;

      state.additionalCharges.push({
        additionalChargeId: newAdditionalChargeId,
        additionalChargeName: `Restocking fee type ${
          state.additionalCharges.length + 1
        }`,
        conditions: [],
        selectReturnReasonError: null,
        isAddSelectReasonConditionEnabled: isSelectReasonEnabled,
        isSelectReasonEnabled: isSelectReasonEnabled,
        selectReturnReason: [],
        additionalChargeSequence: state.additionalCharges.length + 1,
        isValid: false,
        error: "",
        itemError: null,
        errorCount: 0,
        isAddConditionEnabled: false, //Making false as intitially no condition selected
        isAddOrderTypeConditionEnabled: false, //Making false as intitially no condition selected
        customShippingCharges: {
          amount: 0,
          currentState: false,
        },
        restockingFees: {
          amount: 0,
          currentState: false,
          priceType: "fixed",
          error: null,
        },
        messageForCustomer: {
          value: "",
          error: null,
        },
      });
    },

    // Update Method
    updateAdditionalCharge(
      state: RestockingFeeState,
      params: PayloadAction<{
        additionalChargeDetail: RecursivePartial<AdditionalCharges>;
      }>,
    ) {
      const { additionalChargeId, messageForCustomer, additionalChargeName } =
        params.payload.additionalChargeDetail;

      const selectedIdx = state.additionalCharges.findIndex(
        (adc) => adc.additionalChargeId === additionalChargeId,
      );

      const selectedMethod = state.additionalCharges[selectedIdx];

      selectedMethod.messageForCustomer.value =
        messageForCustomer?.value ?? selectedMethod.messageForCustomer.value;
      selectedMethod.messageForCustomer.error = !messageForCustomer?.value
        ? messageForCustomer?.error ?? selectedMethod.messageForCustomer.error
        : null;

      if (additionalChargeName)
        selectedMethod.additionalChargeName = additionalChargeName;
    },

    // Delete Method
    deleteAdditionalCharge: (
      state,
      { payload }: { payload: { id: string } },
    ) => {
      const { updatedTabs, newSelectedTabId } = deleteTabHelper({
        tabs: state.additionalCharges,
        tabId: payload.id,
        selectedTabId: state.selectedAdditionalChargeId,
        idKey: "additionalChargeId",
      });

      state.additionalCharges = updatedTabs;
      state.selectedAdditionalChargeId = newSelectedTabId;
    },

    pushInitialHash: (state) => {
      const hash = createNewObjectHash({
        excludeKeys: state.excludeKeysForHash,
        hashObject: state.additionalCharges,
      });

      if (hash) {
        state.initialHashOtherTab = hash;
        state.currentHashOtherTab = hash;
        state.intialStep15Config = {
          // allOrderAndItem: state.allOrderAndItemTab,
          additionalCharges: state.additionalCharges,
        };
      }
    },

    validateChanges: (state) => {
      const { initialHashOtherTab } = state;

      const { isChanged, newHash } = validateChangeHandler({
        stateObject: state.additionalCharges,
        excludeKeys: state.excludeKeysForHash,
        initialHash: initialHashOtherTab,
      });

      if (newHash) {
        state.isChanged = isChanged;
        state.currentHashOtherTab = newHash;
      }
    },

    resetHashes: (state) => {
      state.currentHashOtherTab = "";
      state.currentHashStaticTab = "";
    },

    undoConfig: (
      state,
      params: PayloadAction<{
        initialConfig: {
          additionalCharges: AdditionalCharges[] | null;
          // allOrderItems: AllOrderAndItemTabType | null;
        };
      }>,
    ) => {
      if (params.payload.initialConfig.additionalCharges !== null) {
        state.additionalCharges =
          params.payload.initialConfig.additionalCharges;

        // if (state.additionalCharges.length === 0) {
        //   state.selectedReturnTypeId = "1";
        // }
      }

      // if (params.payload.initialConfig.allOrderItems !== null) {
      //   state.allOrderAndItemTab = params.payload.initialConfig.allOrderItems;
      // }
    },

    // Validate Method
    validateAdditionalCharge(
      state: RestockingFeeState,
      params: PayloadAction<{
        additionalChargeId: string;
      }>,
    ) {
      const selectedMethodIdx = state.additionalCharges.findIndex(
        (additionalCharge) =>
          additionalCharge.additionalChargeId ===
          params.payload.additionalChargeId,
      );

      let selectedAdditionalCharge = state.additionalCharges[selectedMethodIdx];
      if (
        selectedAdditionalCharge.messageForCustomer.value !== undefined &&
        selectedAdditionalCharge.messageForCustomer.value.length <= 0
      ) {
        selectedAdditionalCharge.messageForCustomer.error =
          "*Please fill out this field";
      } else {
        selectedAdditionalCharge.messageForCustomer.error = null;
      }

      if (
        isNaN(selectedAdditionalCharge.restockingFees.amount) ||
        selectedAdditionalCharge.restockingFees.amount <= 0
      ) {
        selectedAdditionalCharge.restockingFees.error =
          "*Please fill out this field";
      } else {
        selectedAdditionalCharge.restockingFees.error = null;
      }

      const errors = [
        selectedAdditionalCharge.messageForCustomer.error,
        selectedAdditionalCharge.error,
        selectedAdditionalCharge.itemError,
        selectedAdditionalCharge.selectReturnReasonError,
        selectedAdditionalCharge.restockingFees.error,
      ];

      selectedAdditionalCharge.errorCount = errors.reduce(
        (p: number, c: any) => {
          if (c !== null) {
            return p + 1;
          }
          return p;
        },
        0,
      );

      if (state.additionalCharges == null) {
        state.error = "Add a Method  ";
      }
    },

    // Add A Condition
    addCondition: (
      state: RestockingFeeState,
      params: PayloadAction<{
        additionalChargeId: string;
        condition: string;
        isItemCondition?: boolean;
      }>,
    ) => {
      let selectedMethod = state.additionalCharges.find(
        (additionalCharge) =>
          additionalCharge.additionalChargeId ===
          params.payload.additionalChargeId,
      );

      const selectedTab = addConditionHandler({
        selectedTab: selectedMethod,
        conditionOptions: params.payload?.isItemCondition
          ? state.itemOptions
          : state.conditionOptions,
        payloadCondition: params.payload.condition,
        isItemCondition: params.payload?.isItemCondition,
      });

      selectedMethod = selectedTab;

      // if (selectedMethod !== undefined) {
      //   let operator: any = "";
      //   let variableValues = null;
      //   let variableName: any = "";

      //   if (params.payload?.isItemCondition === true) {
      //     let itemCondition = state.itemOptions[0];

      //     if (params.payload.condition !== "") {
      //       let addCondition = state.itemOptions.filter(
      //         (value) => value.name === params.payload.condition,
      //       );
      //       if (addCondition.length) {
      //         itemCondition = addCondition[0];
      //       }
      //     }

      //     variableName = itemCondition.name;
      //     operator = itemCondition.operators[0].name;
      //     variableValues = getConditionValueOrDefault(itemCondition.valueType);
      //   } else {
      //     let otherCondition = state.conditionOptions[0];

      //     if (params.payload.condition !== "") {
      //       let addCondition = state.conditionOptions.filter(
      //         (value) => value.name === params.payload.condition,
      //       );
      //       if (addCondition.length) {
      //         otherCondition = addCondition[0];
      //       }
      //     }

      //     variableName = otherCondition.name;
      //     operator = otherCondition.operators[0].name;
      //     variableValues = getConditionValueOrDefault(otherCondition.valueType);
      //   }
      //   selectedMethod.conditions.push({
      //     id: uuid(),
      //     operator: operator,
      //     ruleType: "AND",
      //     values: variableValues,
      //     variableName: variableName,
      //   });

      //   if (params.payload.isItemCondition === true) {
      //     // Update Select Item Condition
      //     selectedMethod.isAddConditionEnabled = false;
      //     if (selectedMethod.itemConditionError !== null) {
      //       selectedMethod.itemConditionError = null;
      //     }
      //   } else {
      //     // Check if the default value exists
      //     if (
      //       selectedMethod.conditions[selectedMethod.conditions.length - 1]
      //         .values["days"]?.currentValue > 0
      //     ) {
      //       // Add new condition enabled if default value exists
      //       selectedMethod.isAddOrderTypeConditionEnabled = true;
      //     } else {
      //       // Add new condition disabled if no default exists
      //       selectedMethod.isAddOrderTypeConditionEnabled = false;
      //     }
      //     // Remove error if applied
      //     if (selectedMethod.error !== null) {
      //       selectedMethod.error = null;
      //     }
      //   }
      // }
    },

    // Update Conditon
    updateCondition(
      state: RestockingFeeState,
      params: PayloadAction<{
        additionalChargeId: string;
        conditionToUpdate: RecursivePartial<ConditionTypes>;
        isItemCondition?: boolean;
      }>,
    ) {
      const { additionalChargeId, conditionToUpdate } = params.payload;

      const selectedMethodIdx = state.additionalCharges.findIndex(
        (additionalCharge) =>
          additionalCharge.additionalChargeId === additionalChargeId,
      );

      if (conditionToUpdate) {
        const selectedMethod = state.additionalCharges[selectedMethodIdx];

        const conditions = selectedMethod.conditions;

        const {
          updatedConditions,
          isAddOrderConditionEnabled,
          isAddItemConditionEnabled,
          isAddReasonConditionEnabled,
        } = updateConditionHelper({
          conditions,
          conditionToUpdate,
          returnReasonList: state.returnReasonList,
        });

        state.additionalCharges[selectedMethodIdx].conditions =
          updatedConditions;

        if (isAddOrderConditionEnabled !== null) {
          state.additionalCharges[
            selectedMethodIdx
          ].isAddOrderTypeConditionEnabled = isAddOrderConditionEnabled;
        }

        if (isAddItemConditionEnabled !== null) {
          state.additionalCharges[selectedMethodIdx].isAddConditionEnabled =
            isAddItemConditionEnabled;
        }

        if (isAddReasonConditionEnabled !== null) {
          state.additionalCharges[
            selectedMethodIdx
          ].isAddSelectReasonConditionEnabled = isAddReasonConditionEnabled;
        }
      }
    },
    addSelectReasonCondition: (
      state: RestockingFeeState,
      params: PayloadAction<{
        additionalChargeId: string;
      }>,
    ) => {
      const selectedMethod = state.additionalCharges.find(
        (additionalCharge) =>
          additionalCharge.additionalChargeId ===
          params.payload.additionalChargeId,
      );

      if (selectedMethod !== undefined) {
        let otherCondition = state.selectReasonOptions[0];

        let variableName: any = otherCondition.name;
        let operator = otherCondition.operators[0].name;

        selectedMethod.selectReturnReason.push({
          id: uuid(),
          operator: operator,
          ruleType: "AND",
          values: {
            return_reason: {
              reasonId: "",
              elementId: "",
              operator: "=",
              value: "",
            },
          },
          variableName: variableName,
        });

        selectedMethod.isAddSelectReasonConditionEnabled = false;

        if (selectedMethod.selectReturnReasonError !== null) {
          selectedMethod.selectReturnReasonError = null;
        }
      }
    },
    updateSelectReasonCondition(
      state: RestockingFeeState,
      params: PayloadAction<{
        additionalChargeId: string;
        conditionToUpdate: RecursivePartial<ReturnReason>;
      }>,
    ) {
      const { additionalChargeId, conditionToUpdate } = params.payload;

      const selectedMethodIdx = state.additionalCharges.findIndex(
        (additionalCharge) =>
          additionalCharge.additionalChargeId === additionalChargeId,
      );

      if (conditionToUpdate) {
        const selectedMethod = state.additionalCharges[selectedMethodIdx];

        const conditions = selectedMethod.selectReturnReason;
        const conditionIdx = conditions.findIndex(
          (selectReturnReason) =>
            selectReturnReason.id === conditionToUpdate.id,
        );
        if (conditionIdx !== -1) {
          const prevCondition = conditions[conditionIdx];
          Object.assign(prevCondition, conditionToUpdate);
          // prevCondition.values.return_reason.error = undefined;
          // Added to fix run time error i.e.,error is read only
          const updatedCondition = {
            ...prevCondition,
            values: {
              ...conditions[conditionIdx].values,
              return_reason: {
                ...conditions[conditionIdx].values.return_reason,
                error: undefined,
              },
            },
          };
          conditions[conditionIdx] = updatedCondition;
        }

        const lastCondition = conditions[conditionIdx];
        if (lastCondition?.values) {
          Object.entries<any>(lastCondition.values).forEach(([key, value]) => {
            let reason;
            // Get the reason based on id from default return reasons
            if (state.returnReasonList && state.returnReasonList.length > 0) {
              reason = (state.returnReasonList as ReasonTypes[]).find(
                (item) => item.id === value.reasonId,
              );
            }
            // Check if a reason is found
            if (reason) {
              // Always check for reasonId first
              if (!value.reasonId) {
                selectedMethod.isAddSelectReasonConditionEnabled = false;
              } else {
                // Check if there are more questions for the reason
                if (reason.moreQuestions && reason.moreQuestions.length > 0) {
                  const moreQuestion = reason.moreQuestions.find(
                    (question) => question.elementId === value.elementId,
                  );
                  if (moreQuestion) {
                    // If additionalOptions exist, check all properties
                    if (
                      moreQuestion.additionalOptions &&
                      moreQuestion.additionalOptions.length > 0
                    ) {
                      if (
                        !value.reasonId ||
                        !value.operator ||
                        !value.elementId ||
                        !value.value
                      ) {
                        selectedMethod.isAddSelectReasonConditionEnabled =
                          false;
                      } else {
                        selectedMethod.isAddSelectReasonConditionEnabled = true;
                      }
                    } else {
                      // If no additionalOptions, check for reasonId and elementId
                      if (!value.reasonId || !value.elementId) {
                        selectedMethod.isAddSelectReasonConditionEnabled =
                          false;
                      } else {
                        selectedMethod.isAddSelectReasonConditionEnabled = true;
                      }
                    }
                  } else {
                    selectedMethod.isAddSelectReasonConditionEnabled = false;
                  }
                }
              }
            } else {
              selectedMethod.isAddSelectReasonConditionEnabled = false;
            }
          });
        }
      }
    },
    // Delete Condition
    deleteSelectReasonCondition: (
      state: RestockingFeeState,
      params: PayloadAction<{
        additionalChargeId: string;
        conditionId: string;
      }>,
    ) => {
      const { additionalChargeId, conditionId } = params.payload;

      const selectedMethodIdx = state.additionalCharges.findIndex(
        (additionalCharge) =>
          additionalCharge.additionalChargeId === additionalChargeId,
      );
      if (selectedMethodIdx !== -1) {
        const selectedReturnMethods =
          state.additionalCharges[selectedMethodIdx];
        selectedReturnMethods.selectReturnReason =
          selectedReturnMethods.selectReturnReason.filter(
            (condition) => condition.id !== conditionId,
          );

        // if (selectedReturnMethods.selectReturnReason.length === 0) {
        selectedReturnMethods.isAddSelectReasonConditionEnabled = true;
        // }
      }
    },

    // Delete Condition
    deleteCondition: (
      state: RestockingFeeState,
      params: PayloadAction<{
        additionalChargeId: string;
        conditionId: string;
        isItemCondition?: boolean;
      }>,
    ) => {
      const { additionalChargeId, conditionId, isItemCondition } =
        params.payload;

      const selectedMethodIdx = state.additionalCharges.findIndex(
        (additionalCharge) =>
          additionalCharge.additionalChargeId === additionalChargeId,
      );
      if (selectedMethodIdx !== -1) {
        const selectedAdditionalCharges =
          state.additionalCharges[selectedMethodIdx];

        const {
          updatedConditions,
          isAddOrderConditionEnabled,
          isAddItemConditionEnabled,
        } = deleteConditionHelper({
          conditionId: conditionId,
          conditions: selectedAdditionalCharges.conditions,
          conditionType: isItemCondition ? "item" : "order",
        });

        state.additionalCharges[selectedMethodIdx].conditions =
          updatedConditions;

        if (isAddOrderConditionEnabled !== null) {
          state.additionalCharges[
            selectedMethodIdx
          ].isAddOrderTypeConditionEnabled = isAddOrderConditionEnabled;
        }

        if (isAddItemConditionEnabled !== null) {
          state.additionalCharges[selectedMethodIdx].isAddConditionEnabled =
            isAddItemConditionEnabled;
        }
      }
    },

    // Validate Condition
    validateCondition(
      state: RestockingFeeState,
      params: PayloadAction<{
        additionalChargeId: string;
      }>,
    ) {
      const selectedMethodIdx = state.additionalCharges.findIndex(
        (additionalCharge) =>
          additionalCharge.additionalChargeId ===
          params.payload.additionalChargeId,
      );

      const selectedMethod = state.additionalCharges[selectedMethodIdx];

      let itemConditionNames = Object.values(state.itemOptions).map(
        (option) => option.name,
      );
      if (selectedMethod.isSelectReasonEnabled) {
        if (
          selectedMethodIdx !== 0 &&
          selectedMethod.selectReturnReason.length <= 0
        ) {
          selectedMethod.selectReturnReasonError =
            "Atleast add one condition required";
        } else {
          selectedMethod.selectReturnReasonError = null;
        }
      }
      const conditions = selectedMethod.conditions;
      if (
        conditions.filter(
          (condition) => !itemConditionNames.includes(condition.variableName),
        ).length <= 0
      ) {
        selectedMethod.error = "Atleast add one condition required";
      }

      if (
        selectedMethodIdx !== 0 &&
        conditions.filter((condition) =>
          itemConditionNames.includes(condition.variableName),
        ).length <= 0
      ) {
        selectedMethod.itemError = "Atleast add one condition required";
      }

      // Validating conditions using helper
      const {
        updatedConditions,
        isAddOrderConditionEnabled,
        isAddItemConditionEnabled,
        isAddReasonConditionEnabled,
      } = validateConditionsHelper({
        selectedTab: selectedMethod,
        itemOptions: state.itemOptions,
        step: "step15",
        returnReasonList: state.returnReasonList,
      });

      state.additionalCharges[selectedMethodIdx].conditions = updatedConditions;
      if (isAddOrderConditionEnabled !== null) {
        state.additionalCharges[
          selectedMethodIdx
        ].isAddOrderTypeConditionEnabled = isAddOrderConditionEnabled;
      }

      if (isAddItemConditionEnabled !== null) {
        state.additionalCharges[selectedMethodIdx].isAddConditionEnabled =
          isAddItemConditionEnabled;
      }

      if (isAddReasonConditionEnabled !== null) {
        state.additionalCharges[
          selectedMethodIdx
        ].isAddSelectReasonConditionEnabled = isAddReasonConditionEnabled;
      }

      if (
        selectedMethod.messageForCustomer.value !== undefined &&
        selectedMethod.messageForCustomer.value.length <= 0
      ) {
        selectedMethod.messageForCustomer.error = "*Please fill out this field";
      }

      if (selectedMethod.restockingFees.amount <= 0) {
        selectedMethod.restockingFees.error = "*Please fill out this field";
      }

      const errors = [...conditions]
        .concat(
          selectedMethod.isSelectReasonEnabled
            ? [...selectedMethod.selectReturnReason]
            : [],
        )
        .flatMap((condition) => {
          return Object.entries(condition.values).flatMap(
            ([key, value]: [any, any]) => {
              if (value.error === undefined) return null;
              return value.error;
            },
          );
        })
        .concat([
          selectedMethod.error,
          selectedMethod.itemError,
          selectedMethod.selectReturnReasonError,
          selectedMethod.messageForCustomer.error,
          selectedMethod.restockingFees.error,
        ]);

      selectedMethod.errorCount = errors.reduce((p, c) => {
        if (c !== null && c !== undefined) {
          return p + 1;
        }
        return p;
      }, 0);

      if (selectedMethod.errorCount > 0) {
        selectedMethod.isValid = false;
      } else {
        selectedMethod.isValid = true;
      }
    },

    validateRestockingFee(state: RestockingFeeState) {
      state.additionalCharges.forEach((selectedMethod, idx) => {
        let itemConditionNames = Object.values(state.itemOptions).map(
          (option) => option.name,
        );
        if (selectedMethod.isSelectReasonEnabled) {
          if (idx !== 0 && selectedMethod.selectReturnReason.length <= 0) {
            selectedMethod.selectReturnReasonError =
              "Atleast add one condition required";
          }
        }

        const conditions = selectedMethod.conditions;
        if (
          conditions.filter(
            (condition) => !itemConditionNames.includes(condition.variableName),
          ).length <= 0
        ) {
          selectedMethod.error = "Atleast add one condition required";
        }

        if (
          idx !== 0 &&
          conditions.filter((condition) =>
            itemConditionNames.includes(condition.variableName),
          ).length <= 0
        ) {
          selectedMethod.itemError = "Atleast add one condition required";
        }

        const {
          updatedConditions,
          isAddOrderConditionEnabled,
          isAddItemConditionEnabled,
          isAddReasonConditionEnabled,
        } = validateConditionsHelper({
          selectedTab: selectedMethod,
          itemOptions: state.itemOptions,
          step: "step15",
          returnReasonList: state.returnReasonList,
        });

        state.additionalCharges[idx].conditions = updatedConditions;
        if (isAddOrderConditionEnabled !== null) {
          state.additionalCharges[idx].isAddOrderTypeConditionEnabled =
            isAddOrderConditionEnabled;
        }

        if (isAddItemConditionEnabled !== null) {
          state.additionalCharges[idx].isAddConditionEnabled =
            isAddItemConditionEnabled;
        }

        if (isAddReasonConditionEnabled !== null) {
          state.additionalCharges[idx].isAddSelectReasonConditionEnabled =
            isAddReasonConditionEnabled;
        }

        if (
          selectedMethod.messageForCustomer.value !== undefined &&
          selectedMethod.messageForCustomer.value.length <= 0
        ) {
          selectedMethod.messageForCustomer.error =
            "*Please fill out this field";
        }

        if (selectedMethod.restockingFees.amount <= 0) {
          selectedMethod.restockingFees.error = "*Please fill out this field";
        }

        const errors = [...conditions]
          .concat(
            selectedMethod.isSelectReasonEnabled
              ? [...selectedMethod.selectReturnReason]
              : [],
          )
          .flatMap((condition) => {
            return Object.entries(condition.values).flatMap(
              ([key, value]: [any, any]) => {
                if (value.error === undefined) return null;
                return value.error;
              },
            );
          })
          .concat([
            selectedMethod.error,
            selectedMethod.itemError,
            selectedMethod.selectReturnReasonError,
            selectedMethod.messageForCustomer.error,
            selectedMethod.restockingFees.error,
          ]);

        selectedMethod.errorCount = errors.reduce((p, c) => {
          if (c !== null && c !== undefined) {
            return p + 1;
          }
          return p;
        }, 0);

        if (selectedMethod.errorCount > 0) {
          selectedMethod.isValid = false;
        } else {
          selectedMethod.isValid = true;
        }
      });
    },

    // Set Selected Method
    setSelectedAdditionalChargeId(
      state: RestockingFeeState,
      params: PayloadAction<{ additionalChargeId: string }>,
    ) {
      state.selectedAdditionalChargeId = params.payload.additionalChargeId;
    },

    updateEnableDisableCurrentState(
      state,
      params: PayloadAction<{
        selectedId: string;
        type: string;
        currentState: boolean;
      }>,
    ) {
      // if (params.payload.selectedId === "1") {
      //   switch (params.payload.type) {
      //     case "Restocking":
      //       state.allOrderAndItemTab.restockingFees.currentState =
      //         params.payload.currentState;
      //       break;

      //     case "Shipping":
      //       state.allOrderAndItemTab.customShippingCharges.currentState =
      //         params.payload.currentState;
      //       break;

      //     default:
      //       break;
      //   }
      // } else {
      const selectedTabIdx = state.additionalCharges.findIndex(
        (adc) => adc.additionalChargeId === params.payload.selectedId,
      );

      switch (params.payload.type) {
        case "Restocking":
          state.additionalCharges[selectedTabIdx].restockingFees.currentState =
            params.payload.currentState;
          break;

        case "Shipping":
          state.additionalCharges[
            selectedTabIdx
          ].customShippingCharges.currentState = params.payload.currentState;
          break;

        default:
          break;
      }
      // }
    },

    updateFeesAmount(
      state,
      params: PayloadAction<{
        selectedId: string;
        type: string;
        amount: number;
        priceType?: string;
      }>,
    ) {
      // if (params.payload.selectedId === "1") {
      //   switch (params.payload.type) {
      //     case "Restocking":
      //       state.allOrderAndItemTab.restockingFees.amount =
      //         params.payload.amount;
      //       break;

      //     case "Shipping":
      //       state.allOrderAndItemTab.customShippingCharges.amount =
      //         params.payload.amount;
      //       break;

      //     default:
      //       break;
      //   }
      // } else {
      const selectedTabIdx = state.additionalCharges.findIndex(
        (adc) => adc.additionalChargeId === params.payload.selectedId,
      );
      if (selectedTabIdx !== -1) {
        switch (params.payload.type) {
          case "Restocking":
            state.additionalCharges[selectedTabIdx].restockingFees.amount =
              params.payload.amount;
            if (params.payload.priceType) {
              state.additionalCharges[selectedTabIdx].restockingFees.priceType =
                params.payload.priceType;
            }
            if (
              state.additionalCharges[selectedTabIdx].restockingFees.error !==
              null
            ) {
              state.additionalCharges[selectedTabIdx].restockingFees.error =
                null;
            }
            break;

          case "Shipping":
            state.additionalCharges[
              selectedTabIdx
            ].customShippingCharges.amount = params.payload.amount;
            break;

          default:
            break;
        }
      }
      // }
    },
    pushCurrentHashForSelectedTab: (
      state,
      params: PayloadAction<{ additionalChargeId: string | null }>,
    ) => {
      const selectedReturnTypeIdx = state.additionalCharges.findIndex(
        (rType) =>
          rType.additionalChargeId === params.payload.additionalChargeId,
      );

      state.currentHashOtherTab = objectHash(
        state.additionalCharges[selectedReturnTypeIdx],
      );
    },
    //Reset the step 15 configuration to the initial state
    resetStep15Config: (state: RestockingFeeState) => {
      return initialState;
    },
  },
  extraReducers: restockingFeeBuilders,
});

const restockingFeeErrorCount = (state: RootState) => {
  let errorCount = 0;
  state.restockingFee.additionalCharges.forEach((reason) => {
    if (reason.errorCount !== 0) {
      errorCount += 1;
    }
  });

  return errorCount;
};

export const restockingFeeErrorCountSelector = createSelector(
  [restockingFeeErrorCount],
  (restockingFeeErrorCount) => restockingFeeErrorCount,
);

export default restockingFeeSlice.reducer;

export const {
  addAdditionalCharge,
  updateAdditionalCharge,
  deleteAdditionalCharge,
  addCondition,
  updateCondition,
  deleteCondition,
  setSelectedAdditionalChargeId,
  validateAdditionalCharge,
  validateCondition,
  updateEnableDisableCurrentState,
  updateFeesAmount,
  pushInitialHash,
  validateChanges,
  undoConfig,
  resetHashes,
  pushCurrentHashForSelectedTab,
  validateRestockingFee,
  deleteSelectReasonCondition,
  addSelectReasonCondition,
  updateSelectReasonCondition,
  resetStep15Config,
} = restockingFeeSlice.actions;
