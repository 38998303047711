import liveChatImg from "src/assets/images/liveChatImg.png";
import mail from "src/assets/images/mail.png";
import telegram from "src/assets/images/telepgram.png";

import styles from "./SetupCard.module.scss";

interface Props {
  id: "emailSetup" | "botSetup" | "liveChatSetup";
  goto?: string;
}

/**
 * Card configuration data based on id
 */
const cardData = {
  emailSetup: {
    heading: "Setup E-mail forwarding to receive mails on helpdesk",
    img: mail,
    className: styles.mailImg,
    buttonText: "Configure",
  },
  botSetup: {
    heading: "Train AI bot Cern to reply to live chats",
    img: liveChatImg,
    className: styles.botImg,
    buttonText: "Train now !",
  },
  liveChatSetup: {
    heading: "Install live chat widget on your website",
    img: telegram,
    className: styles.liveChatImg,
    buttonText: "Install",
  },
};

/**
 * Component for Showing To-Do List Cards
 */
const SetupCard = ({ id, goto }: Props) => {
  return (
    <div
      className={`d-flex justify-content-between ${
        id === "liveChatSetup" ? "me-0" : "me-3"
      } ${styles.cardBox}`}
    >
      {/* Card Details */}
      <div className="w-50">
        {/* Heading */}
        <h3 className={`${styles.heading}`}>{cardData[id].heading}</h3>

        {/* Button */}
        <a
          className={`${styles.btn}`}
          href={`#${goto ? goto : id}`}
          id={`#${goto ? goto : id}`}
        >
          {cardData[id].buttonText}
        </a>
      </div>

      {/* Card Poster */}
      <div className="w-50 text-center">
        <img src={cardData[id].img} alt="" className={cardData[id].className} />
      </div>
    </div>
  );
};

export default SetupCard;
