import ReactSelect from "react-select";
import styles from "../ReportInternalFilter.module.scss";
import { useCallback, useMemo } from "react";
import {
  reportInternalFiltersActions,
  useReportInternalFilters,
} from "src/routes/Report/hooks/reportFilters/useReportInternalFilters";
import { useQuery } from "@tanstack/react-query";
import { getDraftStatusesOptions } from "src/services/TicketService/getDraftStatusesOptions";

const useIntDraftStatusFilter = () => {
  const { data: allDraftStatus } = useQuery(
    ["draftStatus/getDraftStatusesOptions"],
    {
      queryFn: getDraftStatusesOptions,
      staleTime: 600000,
      cacheTime: 600000,
    }
  );

  const options = useMemo(() => {
    return (
      allDraftStatus?.allDraftStatusesIds?.map((id) => {
        return {
          label: allDraftStatus.allDraftStatuses[id].name,
          value: allDraftStatus.allDraftStatuses[id],
        };
      }) ?? []
    );
  }, [allDraftStatus]);

  const { internalFilters, dispatch } = useReportInternalFilters();

  const selectedDraftStatus = useMemo(() => {
    return allDraftStatus?.allDraftStatusesIds.length
      ? (
          allDraftStatus.allDraftStatusesIds.filter((id) =>
            internalFilters.addedFilters.draftStatus?.includes(id + "")
          ) ?? []
        ).map((id) => {
          return {
            label: allDraftStatus.allDraftStatuses[id].name,
            value: allDraftStatus.allDraftStatuses[id],
          };
        })
      : [];
  }, [internalFilters, allDraftStatus]);

  const onChangeDraftStatus = useCallback(
    (value: typeof selectedDraftStatus) => {
      dispatch([
        reportInternalFiltersActions.setFilters,
        { draftStatus: value.map((data) => data.value.id + "") },
      ]);
    },
    [selectedDraftStatus]
  );
  return {
    options,
    selectedDraftStatus,
    onChangeDraftStatus,
  };
};

function DraftStatus() {
  const { options, selectedDraftStatus, onChangeDraftStatus } =
    useIntDraftStatusFilter();

  return (
    <div className="px-2 pb-2">
      <label className={`${styles.labelText}`}>Draft Status</label>
      <ReactSelect
        isMulti
        options={options}
        value={selectedDraftStatus}
        onChange={onChangeDraftStatus as any}
        className={`${styles.text}`}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
            font: "normal normal normal 12px/18px Poppins",
            color: "#000000",
          }),
          multiValue: (base, { data }) => ({
            ...base,
            backgroundColor: '#F4F4F4', // Background color for selected values
            borderRadius: '6px',
            opacity: 1,
          }),
          multiValueLabel: (base) => ({
            ...base,
            font: "normal normal 500 12px/18px Poppins",
            letterSpacing: "0px",
            color: "#1F2223",
            paddingLeft:"12px",
          }),
          multiValueRemove: (base, { data }) => ({
            ...base,
            // color: '#000000', // Remove icon color for selected values
            fontSize: "6px",
            // ':hover': {
            //   backgroundColor: 'red', // Change the background color on hover if needed
            // },
          }),
        }}
      />
    </div>
  );
}

export default DraftStatus;
