import { useMemo } from "react";
import { DispatchStepsType } from "../../DispatchStepsConfig";
import { useDispatchSectionContext } from "../../Hooks/useDispatchSectionContext";
import styles from "./DispatchRulesHeader.module.scss";

const Step = ({
  stepNumber,
  label,
  isActive,
  isPrevious,
  showSeperator,
  isCompleted,
}: {
  stepNumber: number;
  label: string;
  isActive: boolean;
  showSeperator: boolean;
  isPrevious: boolean;
  isCompleted: boolean;
}) => {
  return (
    <div className="d-flex align-items-center me-2">
      <div>
        <div
          className={`${styles.step} ${
            isCompleted || isActive || isPrevious ? styles.doneStep : ""
          } ${isPrevious && !isCompleted ? styles.skipped : ""}`}
        >
          <span>
            {isCompleted ? <i className="fa-solid fa-check"></i> : stepNumber}
          </span>
        </div>
      </div>

      <span
        className={`ps-1 ${styles.stepText} ${
          isCompleted || isActive ? styles.doneTask : ""
        }`}
      >
        {label}
      </span>
      {showSeperator && (
        <div
          className={`ms-2 ${styles.horizontalLine} ${
            isCompleted || isPrevious ? styles.doneLine : ""
          } ${isPrevious && !isCompleted ? styles.skipped : ""}`}
        ></div>
      )}
    </div>
  );
};

const DispatchRulesHeader = ({
  steps,
}: {
  steps: Array<DispatchStepsType>;
}) => {
  const { activeStep, completedSteps, state, showUserSegment } =
    useDispatchSectionContext();

  // Create a filtered list of steps by removing skipped steps
  const filteredSteps: Array<DispatchStepsType> = useMemo(() => {
    const newSteps: Array<DispatchStepsType> = [];
    let i = 0;

    while (i < steps.length) {
      const currentStep = steps[i];
      newSteps.push(currentStep);

      if (
        showUserSegment === false &&
        currentStep.skipUserSegmentNextIndex !== undefined
      ) {
        i = currentStep.skipUserSegmentNextIndex;
        continue;
      }

      if (
        state.isAiReadIssueDescriptionForDispatch === false &&
        currentStep.skipIssueDetailsNextIndex !== undefined
      ) {
        i = currentStep.skipIssueDetailsNextIndex;
        continue;
      }

      i++;
    }
    return newSteps;
  }, [steps, showUserSegment, state.isAiReadIssueDescriptionForDispatch]);

  return (
    <div
      className={`d-flex align-items-center mt-2 w-100 ${styles.timelineWrapper}`}
    >
      {filteredSteps.map((step, index) => {
        const idx = steps.findIndex((stp) => stp.key === step.key);

        if (idx === steps.length - 1 && idx !== activeStep) {
          return null;
        }

        return (
          <Step
            key={step.key}
            stepNumber={index + 1}
            label={step.name}
            showSeperator={
              index === steps.length - 2
                ? activeStep === steps.length - 1
                : index !== steps.length - 1
            }
            isPrevious={idx < activeStep}
            isActive={activeStep === idx}
            isCompleted={completedSteps.includes(step.key)}
          />
        );
      })}
    </div>
  );
};

export default DispatchRulesHeader;
