/**
 * This file is the custom hook and context file.
 * It contains useFreqSettingsCreate and useFreqSettings custom hooks.
 *
 * @author Yash Aditya
 */

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from "react";
import { AJAXSTATUS, ActionDispatch } from "src/globals/constants";
import {
  NotifYFreqTimeValues,
  NotificationFrequencyData,
  TimeValue,
} from "src/services/Settings/AgentNotifications/addOrUpdateSetting.service";
import { ResponseFrequencyTypes } from "src/services/Settings/AgentNotifications/getNotifyFreqType.service";
import { TimeZone } from "src/services/Settings/AgentNotifications/getTimeZones.service";
import useStaticSettingsData from "./useStaticSettingsData";

// Define the state shape for frequency settings
export interface FreqSettingsState extends ResponseFrequencyTypes {
  timeZones: Array<TimeZone>;
  notifySettingsData?: NotificationFrequencyData;
  notifySettingsOriginalData?: NotificationFrequencyData;
  notificationsEnabled: boolean;
  initialDataLoadingStatus: AJAXSTATUS;
}

// Initial state for frequency settings
const freqSettings: FreqSettingsState = {
  timeZones: [],
  notificationsEnabled: false,
  freqTypes: [],
  weekDays: [],
  months: [],
  initialDataLoadingStatus: "pending",
};

// Reducer function to handle state modifications
const reducerMethod = (
  state: FreqSettingsState,
  [action, payload]: [
    (value: FreqSettingsState, payload?: any) => void,
    undefined | any
  ]
) => {
  // Invoke the action to modify the state
  action(state, payload);
  // Return a new object to ensure immutability
  return { ...state };
};

// Create a context for frequency settings
const FreqSettingsContext = React.createContext([freqSettings, () => {}] as [
  FreqSettingsState,
  ActionDispatch<typeof freqSettingsActions>
]);

// Custom hook to create frequency settings with a reducer
export const useFreqSettingsCreate = () => {
  // Use the reducer to manage state
  const [state, dispatch] = useReducer(reducerMethod, {
    ...freqSettings,
  });
  const initialEnabledFalse = useRef(false);
  const notifyEnabled = useMemo(() => state.notificationsEnabled, [state]);

  // Memoize the modified dispatch function using useCallback
  const modifiedDispatch: ActionDispatch<typeof freqSettingsActions> =
    useCallback(
      (action, payload) => {
        dispatch([freqSettingsActions[action], payload]);
      },
      [dispatch]
    );

  // Fetch static settings data and status using the custom hook
  const {
    settingsStaticData,
    status,
    refetchNotifyFreqTypeStaticData,
    refetchNotifyEnabled,
  } = useStaticSettingsData();

  useEffect(() => {
    if (initialEnabledFalse.current) {
      if (notifyEnabled) {
        try {
          refetchNotifyFreqTypeStaticData();
          refetchNotifyEnabled();
        } catch {}
      }
    }
  }, [notifyEnabled, refetchNotifyFreqTypeStaticData, refetchNotifyEnabled]);

  // Use useMemo to conditionally dispatch actions based on the fetched data and status
  useMemo(() => {
    // Check if static settings data is available and status is 'fulfilled'
    if (settingsStaticData && status === "fulfilled") {
      // Dispatch action to set notification settings data
      modifiedDispatch("setNotifyStaticData", settingsStaticData);
      if (settingsStaticData.notifyEnabled === false) {
        initialEnabledFalse.current = true;
      } else {
        initialEnabledFalse.current = false;
      }
    } else if (initialEnabledFalse.current === false) {
      // Dispatch action to set global loading status
      modifiedDispatch("setGlobalLoading", status);
    }
  }, [settingsStaticData, status]);

  // Return the state, modified dispatch, and the context provider
  return [state, modifiedDispatch, FreqSettingsContext.Provider] as [
    FreqSettingsState,
    ActionDispatch<typeof freqSettingsActions>,
    typeof FreqSettingsContext.Provider
  ];
};

// Custom hook to consume the frequency settings context
export const useFreqSettings = () => {
  // Destructure the context values
  const [freqSettings, dispatch] = useContext(FreqSettingsContext);

  // Return the context values
  return { freqSettings, dispatch };
};

// Actions to modify the frequency settings state
const freqSettingsActions = {
  resetState: (state: FreqSettingsState) => {
    // Reset various properties of the state
    state.timeZones = [];
    delete state.notifySettingsData;
    delete state.notifySettingsOriginalData;
    state.notificationsEnabled = false;
    state.freqTypes = [];
    state.weekDays = [];
    state.months = [];
    state.initialDataLoadingStatus = "pending";
  },
  setNotificationEnabled: (state: FreqSettingsState, payload: boolean) => {
    // Set the notificationsEnabled property based on the payload
    state.notificationsEnabled = payload;
  },
  setGlobalLoading: (state: FreqSettingsState, loading: AJAXSTATUS) => {
    // Set the initialDataLoadingStatus property based on the payload
    state.initialDataLoadingStatus = loading;
  },
  // Set notify settings data in the state
  setNotifySettingsData: (
    state: FreqSettingsState,
    payload: NotificationFrequencyData
  ) => {
    if (payload.timeValues?.length) {
      if (!payload.timeValues[0].xNoOfTimes) {
        payload.timeValues[0].xNoOfTimes =
          payload.timeValues[0].times?.length ?? 1;
      }
    }
    state.notifySettingsData = payload;
    // Create a deep copy of the payload for original data
    state.notifySettingsOriginalData = JSON.parse(
      JSON.stringify(payload)
    ) as NotificationFrequencyData;
  },

  // Update frequency ID in notify settings data
  updateNotifySettingsFreqId: (
    state: FreqSettingsState,
    frequencyTypeId: NotificationFrequencyData["frequencyTypeId"]
  ) => {
    if (state.notifySettingsData) {
      if (frequencyTypeId) {
        // Find the frequency type based on the provided ID
        const freq = state.freqTypes.find(
          (v) => v.id + "" === frequencyTypeId + ""
        );
        if (freq) {
          // Update frequency type ID, type, and initialize timeValues if needed
          state.notifySettingsData.frequencyTypeId = frequencyTypeId;
          state.notifySettingsData.type = freq.type ?? "daily";
          if (!state.notifySettingsData.timeValues) {
            state.notifySettingsData.timeValues = [];
          }
        }
      }
    }
  },

  // Update frequency type in notify settings data
  updateNotifySettingsFreqType: (
    state: FreqSettingsState,
    type: NotificationFrequencyData["type"]
  ) => {
    if (state.notifySettingsData) {
      // Update the frequency type
      state.notifySettingsData.type = type;
    }
  },

  // Update time values in notify settings data
  updateNotifySettingsFreqTimeValues: (
    state: FreqSettingsState,
    payload: NotifYFreqTimeValues
  ) => {
    if (state.notifySettingsData?.timeValues?.length) {
      // Update time values with the provided payload
      state.notifySettingsData.timeValues[0] = {
        ...state.notifySettingsData.timeValues[0],
        ...payload,
      };
    } else if (state.notifySettingsData) {
      // Update time values with the provided payload
      state.notifySettingsData.timeValues = [
        {
          ...payload,
        },
      ];
    }
  },

  // Update time in notify settings data
  updateNotifySettingsFreqTimes: (
    state: FreqSettingsState,
    payload: {
      index: number;
      value: Partial<TimeValue>;
      syncTZ?: boolean;
    }
  ) => {
    if (state.notifySettingsData) {
      if (!state.notifySettingsData.timeValues) {
        state.notifySettingsData.timeValues = [];
      }
      if (!state.notifySettingsData.timeValues[0]) {
        state.notifySettingsData.timeValues[0] = {};
      }
      if (!state.notifySettingsData.timeValues[0].times) {
        state.notifySettingsData.timeValues[0].times = [];
      }
      // Update time values at the specified index with the provided payload
      state.notifySettingsData.timeValues[0].times[payload.index] = {
        ...(state.notifySettingsData.timeValues[0].times[payload.index] ?? {
          time: "",
          timeZoneId: "",
        }),
        ...payload.value,
      };

      // Synchronize time zone if specified
      if (payload.syncTZ && payload.value.timeZoneId) {
        for (let index = 0; index < 7; index++) {
          if (state.notifySettingsData.timeValues[0].times[index]) {
            // Update time zone for all days if a time value exists
            state.notifySettingsData.timeValues[0].times[index].timeZoneId =
              payload.value.timeZoneId;
          } else {
            // Initialize a new time value with the provided time zone
            state.notifySettingsData.timeValues[0].times[index] = {
              time: "",
              timeZoneId: payload.value.timeZoneId,
            };
          }
        }
      }
    }
  },
  setNotifyStaticData: (
    state: FreqSettingsState,
    {
      freqSettings,
      timeZones,
      notifyEnabled,
    }: {
      freqSettings?: ResponseFrequencyTypes;
      timeZones?: TimeZone[];
      notifyEnabled: boolean;
    }
  ) => {
    // Set the notifySettingsData property based on the payload
    if (timeZones?.length) {
      state.timeZones = timeZones;
    }
    state.notificationsEnabled = notifyEnabled;
    if (freqSettings) {
      state.freqTypes = freqSettings.freqTypes;
      state.weekDays = freqSettings.weekDays;
      state.months = freqSettings.months;
    }
    state.initialDataLoadingStatus = "fulfilled";
  },
};
