import { useCustomerSegments } from "../../hooks/useCustomerSegments";
import Loader from "src/components/Loader";
import ToggleSidebar from "src/components/ToggleSidebar/ToggleSidebar";
import SegmentTypeItem from "./children/SegmentTypeItem/SegmentTypeItem";
import styles from "./CSSideBar.module.scss";

const CSSideBar = () => {
  const { customerSegments } = useCustomerSegments();

  return (
    <ToggleSidebar>
      <div className={` ${styles.sidenav_content} overflow-auto`}>
        {/* Sidebar Items List */}
        <ul className={`${styles.sidebar_nav}`}>
          {/* Default Item */}
          <li className="mb-3">
            {/* Heading */}
            <h3 className={`${styles.heading}`}>Customer</h3>

            {/* Search Bar */}
            {/* <SearchBar
              className={`${styles.search} px-2`}
              inputClassName={`${styles.input}`}
              placeholder={`Search`}
              id="searchbar"
            /> */}
          </li>

          {/* Dynamic Content List */}
          {customerSegments.allSegmentsLoading === "pending" ? (
            <Loader />
          ) : customerSegments.allSegmentsLoading === "rejected" ? (
            <div className="text-danger">Error Loading Sidebar...</div>
          ) : (
            <div>
              {/* No items available */}
              {customerSegments.allSegmentTypes.length === 0 && (
                <span>No Item Found</span>
              )}

              {/* Sidebar items list */}
              {customerSegments.allSegmentTypes.map((id) => {
                const item = customerSegments.allSegments[id];
                if (!item) {
                  return null;
                }

                return (
                  <SegmentTypeItem
                    key={item.segmentType}
                    segmentTypeData={item}
                  />
                );
              })}
            </div>
          )}
        </ul>
      </div>
    </ToggleSidebar>
  );
};

export default CSSideBar;
