import { current, PayloadAction } from "@reduxjs/toolkit";
import {
  Customer,
  Customers,
} from "src/services/LiveChat/messageExchange/getAllCustomers";
import { RootState, useAppSelector } from "src/store/store";
import { setSearchValue } from "../../settings/user/userSettings.slice";
import {
  ChatWidExchCustSlice,
  initialState,
  LiveChatStatusTypes,
  StatusFilters,
} from "./chatWidExchCust.declarations";
import { BrandAndSignature } from "src/services/Settings/EmailSignature/getBrandSignature";
import { ILiveChatIntegratedBrand } from "src/services/LiveChat/messageExchange/getLiveChatIntegratedBrands";
import { ChatCountUpdatedData } from "src/pusherServices/livechat/chatCountUpdatedEvent";

const chatWidExchCustReducer = {
  resetCustomersData: (
    state: ChatWidExchCustSlice,
    action: PayloadAction<{ loading: boolean; initialized: boolean }>,
  ) => {
    const loading = action.payload.loading;
    const initialized = action.payload.initialized;

    state.customers = {};
    state.chatIdList = [];
    state.metaData = {
      unreadMentionsCount: state.metaData.unreadMentionsCount,
    };
    state.fetchCustAJAXStatus = "pending";
    state.fetchSingleCustomerAJAXStatus = "idle";
    state.selectedCustomerChatId = "";
    state.loading = loading;
    state.initialized = initialized;
  },
  setActiveChatId: (
    state: ChatWidExchCustSlice,
    action: PayloadAction<{ id: string | number }>,
  ) => {
    const { id } = action.payload;
    state.selectedCustomerChatId = id;
  },
  selectCustomerStatus: (
    state: ChatWidExchCustSlice,
    action: PayloadAction<{
      customerStatus: "live" | "missed" | "archived";
    }>,
  ) => {
    const { customerStatus } = action.payload;
    state.selectedCustomerStatus = customerStatus;
  },
  sortCustomerByDate: (state: ChatWidExchCustSlice) => {
    const filteredCustomer = Object.values(state.customers).sort(
      (cust1, cust2) => {
        let firstDate: any = new Date(cust2.lastMessageTime);
        let secondDate: any = new Date(cust1.lastMessageTime);
        return firstDate - secondDate;
      },
    );

    const chatIds = filteredCustomer.map((customer) => customer.chatId);

    state.chatIdList = chatIds;
  },

  setCustomerData: (
    state: ChatWidExchCustSlice,
    action: PayloadAction<{
      customer: Customer;
      checkForExistingCust?: boolean;
    }>,
  ) => {
    const { customer, checkForExistingCust } = action.payload;

    if (!state.chatIdList.includes(customer.chatId)) {
      if (!checkForExistingCust) {
        state.chatIdList.unshift(customer.chatId);
        state.customers[customer.chatId] = customer;
      }
    } else {
      state.customers[customer.chatId] = customer;
    }
  },

  setChatStatusByChatId: (
    state: ChatWidExchCustSlice,
    action: PayloadAction<{
      chatId: number | string;
      status: "live" | "missed" | "archived";
    }>,
  ) => {
    const { chatId, status } = action.payload;

    if (state.chatIdList.includes(chatId)) {
      state.customers[chatId].customerStatus = status;
    }
  },

  setChatDisconnectedStatus: (
    state: ChatWidExchCustSlice,
    action: PayloadAction<{
      chatId: number | string;
      connected: boolean;
      isCustomerOnline: boolean;
    }>,
  ) => {
    const { chatId, connected, isCustomerOnline } = action.payload;

    if (state.chatIdList.includes(chatId)) {
      state.customers[chatId].isConnected = connected;
      state.customers[chatId].isCustomerOnline = isCustomerOnline;
    }
  },

  setSearchValue: (
    state: ChatWidExchCustSlice,
    action: PayloadAction<{ searchValue: string }>,
  ) => {
    const { searchValue } = action.payload;

    state.filters.searchValue = searchValue;
  },

  setMissedMessageCount: (
    state: ChatWidExchCustSlice,
    action: PayloadAction<{ chatId: number | string; count: number }>,
  ) => {
    const { chatId, count } = action.payload;

    if (state.customers[chatId]) {
      state.customers[chatId].missedMessages = count;

      //JAN 05 below code is commented as chat id is not used anymore for counts, it is handled by making fetch request to for count
      // if (count === 0) {
      //   chatWidExchCustReducer.updateSidebar(state, {
      //     payload: {
      //       chatId: chatId,
      //       status: "live",
      //       isDelete: true,
      //     },
      //     type: "",
      //   });
      // }
    }
  },

  updateSidebar: (
    state: ChatWidExchCustSlice,
    action: PayloadAction<{
      chatId: number | string;
      status: "live" | "missed" | "archived";
      isDelete: boolean;
    }>,
  ) => {
    const { chatId: tempChatId, status, isDelete } = action.payload;

    if (isNaN(parseInt(tempChatId + ""))) {
      return;
    }

    const chatId = parseInt(tempChatId + "");
    //JAN 05 below code is commented as chat id is not used anymore for counts, it is handled by making fetch request to for count
    // if (isDelete) {
    //   const liveIndex = state.sidebar.liveChatIds.indexOf(chatId);

    //   if (liveIndex > -1) {
    //     state.sidebar.liveChatIds.splice(liveIndex, 1);
    //   }

    //   const missedIndex = state.sidebar.missedChatIds.indexOf(chatId);

    //   if (missedIndex > -1) {
    //     state.sidebar.missedChatIds.splice(missedIndex, 1);
    //   }
    // } else {
    //   if (status === "live") {
    //     if (!state.sidebar.liveChatIds.includes(chatId)) {
    //       state.sidebar.liveChatIds.push(chatId);
    //     }
    //   }

    //   if (status == "missed") {
    //     if (!state.sidebar.missedChatIds.includes(chatId)) {
    //       state.sidebar.missedChatIds.push(chatId);
    //     }
    //   }
    // }
  },

  removeCustomerFromChatList: (
    state: ChatWidExchCustSlice,
    action: PayloadAction<{
      chatId: number | string;
      selectNextChat?: boolean;
    }>,
  ) => {
    const { chatId } = action.payload;

    const index = state.chatIdList.indexOf(chatId);

    if (index > -1) {
      state.chatIdList.splice(index, 1);

      delete state.customers[chatId];
    }
  },

  removeChatsNotMatchingCurrentFilter: (state: ChatWidExchCustSlice) => {
    const customerStatus = state.selectedCustomerStatus;
    // Filter out chat IDs that do not match the selected customer status
    const matchingChatIds = state.chatIdList.filter((chatId) => {
      if (
        state.selectedCustomerChatId == chatId ||
        (state.customers[chatId] &&
          (!state.customers[chatId].customerStatus ||
            state.customers[chatId].customerStatus === customerStatus))
      ) {
        return true;
      }
      return false;
    });
    console.log(customerStatus, matchingChatIds);

    // Update the chatIdList to only include matching chat IDs
    state.chatIdList = matchingChatIds;

    // Remove customers that do not match the selected customer status
    Object.keys(state.customers).forEach((chatId) => {
      if (!matchingChatIds.map((chatId) => chatId + "").includes(chatId + "")) {
        delete state.customers[chatId];
      }
    });
  },

  resetInitializedStatus: (
    state: ChatWidExchCustSlice,
    action: PayloadAction<{ loading: boolean; initialized: boolean }>,
  ) => {
    const loading = action.payload.loading;
    const initialized = action.payload.initialized;

    state.loading = loading;
    state.initialized = initialized;
  },

  updateLiveChatRequestWaitingSinceData: (
    state: ChatWidExchCustSlice,
    action: PayloadAction<{ customers: Customers }>,
  ) => {
    // state.customers = action.payload.customers;

    let customers: Customers = action.payload.customers;
    state.chatIdList.forEach((chatId) => {
      if (customers[chatId] && state.customers[chatId]) {
        state.customers[chatId].customerWaitingForReplySince =
          customers[chatId].customerWaitingForReplySince;
        state.customers[chatId].isCustomerWaitingForReply =
          customers[chatId].isCustomerWaitingForReply;
      }
    });
  },

  updateCustomerDataOnChatEnd: (
    state: ChatWidExchCustSlice,
    action: PayloadAction<{ customerInfo: Customer }>,
  ) => {
    const { customerInfo } = action.payload;
    let chatId: string | number = parseInt(customerInfo.chatId + "");
    if (state.chatIdList.includes(chatId) && state.customers[chatId]) {
      state.customers[chatId] = customerInfo;
    }
  },
  setChatAssignedNotification: (
    state: ChatWidExchCustSlice,
    action: PayloadAction<{
      showModal: boolean;
      chatId: number | string;
      assigner: any;
      customer: any;
      chatStatus: LiveChatStatusTypes;
      preChatForm: any;
    }>,
  ) => {
    const { showModal, chatId, assigner, customer, chatStatus, preChatForm } =
      action.payload;
    state.chatAssignedNotification = {
      showModal: showModal,
      chatId: chatId,
      assigner: assigner,
      customer: customer,
      chatStatus: chatStatus,
      preChatFrom: preChatForm,
    };
  },
  resetChatAssignedNotification: (state: ChatWidExchCustSlice) => {
    state.chatAssignedNotification = {
      showModal: false,
      chatId: 0,
      assigner: {
        id: 0,
        name: "",
        imageURL: null,
      },
      customer: {
        id: 0,
        name: "",
        email: "",
        imageURL: null,
      },
      chatStatus: "live",
    };
  },

  resetCustomerData: (state: ChatWidExchCustSlice) => {
    state.customers = {};
    state.chatIdList = [];
    state.metaData = { unreadMentionsCount: 0 };
    state.fetchCustAJAXStatus = "pending";
  },

  selectedFilterIdApplied: (state: ChatWidExchCustSlice) => {
    state.idFilterApplied.selectedIds = state.selectedAgentIdsForFilter;
    state.idFilterApplied.selectedIdsInfo = [];
    state.selectedAgentIdsForFilter.forEach((selectedId) => {
      const agent = state.agents[selectedId];
      if (agent) {
        state.idFilterApplied.selectedIdsInfo.push({
          email: agent.email,
          id: agent.id,
          name: agent.name,
          imgUrl: agent.imageURL,
        });
      }
    });
  },

  idsAppliedToSelectedIdFilter: (state: ChatWidExchCustSlice) => {
    state.selectedAgentIdsForFilter = state.idFilterApplied.selectedIds;
    state.selectedBrandIdsForFilter = state.brandIdsApplied;
    state.selectedStatusForFilter = state.statusFilterApplied;
  },

  selectedBrandIdApplied: (state: ChatWidExchCustSlice) => {
    state.brandIdsApplied = state.selectedBrandIdsForFilter;
  },

  selectedStatusApplied: (state: ChatWidExchCustSlice) => {
    state.statusFilterApplied = state.selectedStatusForFilter;
  },

  //  Agent List Selected Filter Reducers
  selectedAgentForAssignToFilter: (
    state: ChatWidExchCustSlice,
    action: PayloadAction<number | string>,
  ) => {
    const agentId = action.payload;
    if (!state.selectedAgentIdsForFilter.includes(agentId)) {
      state.selectedAgentIdsForFilter.push(agentId);
    }
  },

  // Agent List Remove Selected Filter Reducers

  removeSelectedAgentForAssignToFilter: (
    state: ChatWidExchCustSlice,
    action: PayloadAction<number | string>,
  ) => {
    const removeAgentId = action.payload;
    state.selectedAgentIdsForFilter = state.selectedAgentIdsForFilter.filter(
      (agentId) => agentId !== removeAgentId,
    );
  },

  // Select All Agent List Selected Filter Reducers

  selectAllAgentForAssignToFilter: (state: ChatWidExchCustSlice) => {
    state.selectedAgentIdsForFilter = state.agentIds;
  },

  // Remove All Agent List Selected Filter Reducers

  removeAllAgentForAssignToFilter: (
    state: ChatWidExchCustSlice,
    action: PayloadAction<{ initialValue: Array<number | string> }>,
  ) => {
    const { initialValue } = action.payload;
    state.selectedAgentIdsForFilter = initialValue;
  },
  resetAssignToAgentListForFilters: (state: ChatWidExchCustSlice) => {
    state.agentIds = [];
    state.agents = {};
    state.agentMeta.count = 0;
    state.agentMeta.total = 0;
    return state;
  },
  setShowMentions: (
    state: ChatWidExchCustSlice,
    action: PayloadAction<boolean>,
  ) => {
    state.filters.showMentions = action.payload;
    return state;
  },

  allBrandsSelected: (state: ChatWidExchCustSlice) => {
    // empty the array
    state.selectedBrandIdsForFilter = [];
  },

  selectedBrandForFilter: (
    state: ChatWidExchCustSlice,
    action: PayloadAction<{ brandId: number | string }>,
  ) => {
    const brandId = action.payload.brandId;
    if (!state.selectedBrandIdsForFilter.includes(brandId)) {
      state.selectedBrandIdsForFilter.push(brandId);
    }
  },

  selectAllBrand: (
    state: ChatWidExchCustSlice,
    action: PayloadAction<{ brandList: ILiveChatIntegratedBrand[] | null }>,
  ) => {
    const brandList = action.payload.brandList;
    if (brandList) {
      state.selectedBrandIdsForFilter = brandList.map((brand) => brand.id);
    }
  },

  selectedMultipeBrandForFilter: (
    state: ChatWidExchCustSlice,
    action: PayloadAction<{ brandIds: Array<number | string> }>,
  ) => {
    const brandIds = action.payload.brandIds;
    brandIds.forEach((brandId) => {
      if (!state.selectedBrandIdsForFilter.includes(brandId)) {
        state.selectedBrandIdsForFilter.push(brandId);
      }
    });
  },

  deselectedBrandForFilter: (
    state: ChatWidExchCustSlice,
    action: PayloadAction<{ brandId: number | string }>,
  ) => {
    const brandId = action.payload.brandId;
    state.selectedBrandIdsForFilter = state.selectedBrandIdsForFilter.filter(
      (id) => id !== brandId,
    );
  },
  /**
   * Function to set selected status filters
   */
  selectedStatusForFilter: (
    state: ChatWidExchCustSlice,
    action: PayloadAction<{
      status: StatusFilters[];
    }>,
  ) => {
    const status = action.payload.status;
    // Check if all filters selected
    if (status.includes("all")) {
      state.selectedStatusForFilter = ["all"];
    } else if (status.length === 0) {
      //  Remove all selected filters if no status in payload is selected
      state.selectedStatusForFilter = [];
    } else {
      // Create a new set with all filters inside payload
      state.selectedStatusForFilter = Array.from(
        new Set([...state.selectedStatusForFilter, ...status]),
      );

      // Remove "all" from selectedStatusForFilter if it exists
      const index = state.selectedStatusForFilter.indexOf("all");
      if (index !== -1) {
        state.selectedStatusForFilter.splice(index, 1);
      }
    }
  },

  unCheckStatusFilter: (
    state: ChatWidExchCustSlice,
    action: PayloadAction<{ status: StatusFilters }>,
  ) => {
    const status = action.payload.status;
    state.selectedStatusForFilter = state.selectedStatusForFilter.filter(
      (statusFilter) => statusFilter !== status,
    );
  },
  updateDateForFilter: (
    state: ChatWidExchCustSlice,
    action: PayloadAction<{ startDate: Date | null; endDate: Date | null }>,
  ) => {
    const startDate = action.payload.startDate;
    const endDate = action.payload.endDate;
    state.filterStartDate = startDate;
    state.filterEndDate = endDate;
  },

  appliedFilterDateToSelectedDate: (state: ChatWidExchCustSlice) => {
    state.appliedFilterStartDate = state.filterStartDate;
    state.appliedFilterEndDate = state.filterEndDate;
  },

  resetDateFilter: (state: ChatWidExchCustSlice) => {
    state.filterStartDate = initialState.filterStartDate;
    state.filterEndDate = initialState.filterEndDate;
    state.appliedFilterStartDate = initialState.appliedFilterStartDate;
    state.appliedFilterEndDate = initialState.appliedFilterEndDate;
  },

  setAllStatusSelected: (
    state: ChatWidExchCustSlice,
    action: PayloadAction<{ isSelected: boolean }>,
  ) => {
    state.statusAllSelected = action.payload.isSelected;
  },

  resetAllFilters: (state: ChatWidExchCustSlice) => {
    state.selectedAgentIdsForFilter = initialState.selectedAgentIdsForFilter;
    state.idFilterApplied = initialState.idFilterApplied;

    state.selectedBrandIdsForFilter = initialState.selectedBrandIdsForFilter;
    state.brandIdsApplied = initialState.brandIdsApplied;
    state.selectedStatusForFilter = initialState.selectedStatusForFilter;
    state.statusFilterApplied = initialState.statusFilterApplied;

    state.statusAllSelected = initialState.statusAllSelected;
    state.filterStartDate = initialState.filterStartDate;
    state.filterEndDate = initialState.filterEndDate;
    state.appliedFilterStartDate = initialState.appliedFilterStartDate;
    state.appliedFilterEndDate = initialState.appliedFilterEndDate;
    state.filters.showMentions = false;
  },
  // Function to update customer data in the state
  updateCustomerData: (
    state: ChatWidExchCustSlice,
    action: PayloadAction<{
      chatId: number | string; // Chat ID of the customer to update
      customer: Partial<Customer>; // Partial customer data to update
    }>,
  ) => {
    // Destructure chatId and customer from the action payload
    const { chatId, customer } = action.payload;

    // Check if the chatId is present in the chatIdList of the state
    if (state.customers[chatId]) {
      // If the chatId is present, update the customer data for that chatId
      state.customers[chatId] = {
        ...state.customers[chatId], // Preserve existing customer data
        ...customer, // Merge in the updated customer data
      };
    }
  },

  updateCustomerDataByCustomerId: (
    state: ChatWidExchCustSlice,
    action: PayloadAction<{
      customerId: number | string; // Chat ID of the customer to update
      customer: Partial<Customer>; // Partial customer data to update
    }>,
  ) => {
    // Destructure chatId and customer from the action payload
    const { customerId, customer } = action.payload;

    state.chatIdList.forEach((chatId) => {
      if (
        state.customers[chatId] &&
        state.customers[chatId].customerId == customerId
      ) {
        state.customers[chatId] = {
          ...state.customers[chatId], // Preserve existing customer data
          ...customer, // Merge in the updated customer data
        };
      }
    });
  },

  resolveAndAddChatUpdatedCountToQueue: (
    state: ChatWidExchCustSlice,
    action: PayloadAction<{
      eventData: Array<ChatCountUpdatedData>;
      disabledBrandIds: Array<number | string>;
      resetQueue?: boolean;
    }>,
  ): ChatWidExchCustSlice => {
    const eventData = action.payload.eventData;
    const disabledBrandIds = action.payload.disabledBrandIds;

    // Map to store the latest event for each unique combination of chatId, action, and customerStatus
    const latestEventsMap: { [key: string]: ChatCountUpdatedData } = {};

    // Iterate through the event data to filter duplicates and keep the latest ones
    [...state.chatUpdatedCountQueue, ...eventData].forEach((event) => {
      // Construct a key using chatId, action, and customerStatus to identify unique events
      const key = `${event.chatId}_${event.action}_${event.customerStatus}`;

      if (disabledBrandIds?.includes(event.brandId)) {
        return;
      }

      // Check if an event with the same key already exists in the map
      if (latestEventsMap[key]) {
        // If the current event's timestamp is greater, replace the existing event with the current one
        if (event.timestamp > latestEventsMap[key].timestamp) {
          latestEventsMap[key] = event;
        }
      } else {
        // If no event with the same key exists, add the current event to the map
        latestEventsMap[key] = event;
      }
    });

    state.chatUpdatedCountQueue = Object.values(latestEventsMap).sort(
      (a, b) => a.timestamp - b.timestamp,
    );

    state.sidebar = updateSidebarCounts(
      { ...state.sidebarFromApi },
      state.chatUpdatedCountQueue,
      disabledBrandIds,
    );

    if (action.payload.resetQueue) {
      state.chatUpdatedCountQueue = [];
    }

    return state;
  },
};
export function updateSidebarCounts(
  sidebar: ChatWidExchCustSlice["sidebar"],
  eventData: Array<ChatCountUpdatedData>,
  disabledBrandIds: Array<number | string>,
): any {
  eventData.forEach((event) => {
    if (disabledBrandIds?.includes(event.brandId) || !sidebar?.timestamp) {
      return;
    }

    // Check if the event timestamp is greater than the sidebar timestamp
    if (sidebar.timestamp && event.timestamp <= sidebar.timestamp) {
      return; // Ignore the event if the timestamp is not greater
    }

    let generalStatus = event.customerStatus;

    switch (generalStatus) {
      case "live":
        if (event.action === "increment") {
          sidebar.liveChatIds++;
        } else if (event.action === "decrement") {
          sidebar.liveChatIds--;
          if (sidebar.liveChatIds < 0) {
            sidebar.liveChatIds = 0;
          }
        }
        break;
      case "missed":
        if (event.action === "increment") {
          sidebar.missedChatIds++;
        } else if (event.action === "decrement") {
          sidebar.missedChatIds--;
          if (sidebar.missedChatIds < 0) {
            sidebar.missedChatIds = 0;
          }
        }
        break;
      case "archived":
        if (event.action === "increment") {
          sidebar.archiveChatIds++;
        } else if (event.action === "decrement") {
          sidebar.archiveChatIds--;
          if (sidebar.archiveChatIds < 0) {
            sidebar.archiveChatIds = 0;
          }
        }
        break;
      default:
        return; // Ignore unknown generalStatus values
    }
  });

  return sidebar;
}
export default chatWidExchCustReducer;
