/**
 * This file is the react component file.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 * @author Navjyot
 */

import { useState } from "react";
import ReportTabs, {
  ReportTabNames,
  ReportTabsProps,
} from "../ReportTabs/ReportTabs";
import { useReportInternalFiltersCreate } from "src/routes/Report/hooks/reportFilters/useReportInternalFilters";
import { useNotificationsCreate } from "../NotificationContainer/hooks/notifications/useNotifications";
import { CurrentStatusEndPoints } from "src/services/report/reportTicketList.service";
import ReportTicketGraph from "../ReportTicketGraph/ReportTicketGraph";

// Interface for component props
export interface ReportTicketBodyProps {
  endPoint: CurrentStatusEndPoints;
  title: string;
  tooltip?: string;
  tabProps: Omit<ReportTabsProps, "activeTabName" | "setActiveTabname">;
}

// ReportTicketBody component definition
function ReportTicketBody({
  endPoint,
  title,
  tooltip,
  tabProps,
}: ReportTicketBodyProps) {
  // State for managing the active tab
  const [activeTabName, setActiveTabname] = useState(
    tabProps.showTabs === false
      ? ("heatmapView" as ReportTabNames)
      : ("chartView" as ReportTabNames)
  );

  // Custom hooks for managing internal filters and notifications
  const [state, dispatch, Provider] = useReportInternalFiltersCreate();
  const [stateN, dispatchN, ProviderN] = useNotificationsCreate(endPoint);

  // Render the component UI
  return (
    <Provider value={[state, dispatch]}>
      <ProviderN value={[stateN, dispatchN]}>
        {/* Total number of unresolved tickets  */}
        <ReportTabs
          activeTabName={activeTabName}
          setActiveTabname={setActiveTabname}
          {...tabProps}
          endPoint={endPoint}
        />
        {/* Ticket stats component */}
        <ReportTicketGraph
          activeTabName={activeTabName}
          setActiveTabname={setActiveTabname}
          endPoint={endPoint}
          title={title}
          tooltip={tooltip}
          tabProps={tabProps}
        />
      </ProviderN>
    </Provider>
  );
}

// Export the ReportTicketBody component
export default ReportTicketBody;
