import { useEffect, useRef, useState } from "react";
import AxiosImg from "src/components/AxiosImg";
import UserAvatar from "src/components/UserAvatar";
import { useAppSelector } from "src/store/store";
import styles from "../../../../LiveChat.module.scss";
import styles2 from "./ChatInfo.module.scss";
import userLocation from "src/assets/images/location.svg";
import { SOCIAL_CHANNELS } from "src/services/LiveChat/messageExchange/getAllCustomers";
function ChatInfo() {
  const selectedCustomer = useAppSelector(
    (state) => state.chatWidExhMsg.activeCustomerInfo,
  );

  const [customerName, setCustomerName] = useState("NA");
  const [imageURL, setImageURL] = useState<string | null>(null);
  const [email, setEmail] = useState("");
  const [whatsappNumber, setWhatsappNumber] = useState("NA");
  const [location, setLocation] = useState("unknown");
  // const [canSendNotification, setCanSendNotification] = useState(false);

  const [activeUser, setActiveUser] = useState(false);

  useEffect(() => {
    if (selectedCustomer) {
      setCustomerName(selectedCustomer.name);
      setImageURL(selectedCustomer.imageURL);
      setEmail(selectedCustomer.email);
      setActiveUser(selectedCustomer?.isCustomerOnline ?? false);
      setLocation(
        selectedCustomer?.customerMeta?.customerLocation?.countryName ??
          "unknown",
      ); //Adding location
    }
    // Adding Whatsapp number
    if (selectedCustomer?.chatChannelType) {
      if (
        SOCIAL_CHANNELS.includes(selectedCustomer?.chatChannelType) &&
        selectedCustomer?.chatChannelType != "messenger"
      ) {
        setWhatsappNumber(
          (selectedCustomer?.chatChannelToDetail &&
          "phoneNumber" in selectedCustomer?.chatChannelToDetail
            ? selectedCustomer?.chatChannelToDetail.phoneNumber
            : (selectedCustomer?.chatChannelToDetail as any)?.value) ?? "NA",
        );
      }
    }
  }, [selectedCustomer]);

  return (
    <div className={`${styles.chatInfo} w-50`}>
      <div className={`d-flex`}>
        {imageURL && imageURL.length !== 0 ? (
          <AxiosImg
            url={imageURL}
            className={`${styles.profileImage} rounded-circle`}
            isDirectURL={selectedCustomer?.isPublicAttachmentUrl}
          />
        ) : (
          <div className={`position-relative`}>
            <UserAvatar
              name={customerName}
              size={34}
            />
            <span
              className={`${styles2.activeDot} ${
                activeUser == true ? styles2.activeDot : styles2.inactiveDot
              }`}
            ></span>
          </div>
        )}
        <div className={`px-2`}>
          <p className={`${styles.liveChatName}`}>
            {customerName && customerName.length !== 0 ? customerName : "NA"}
          </p>
          <div>
            {/* Adding whatsapp number in case of whatsapp chat */}
            <div className="d-flex align-items-center mt-1">
              {selectedCustomer?.chatChannelType &&
              SOCIAL_CHANNELS.includes(selectedCustomer?.chatChannelType) &&
              selectedCustomer?.chatChannelType != "messenger" ? (
                <p className={`mb-0 ${styles2.whatsappText}`}>
                  {whatsappNumber && whatsappNumber.length !== 0
                    ? whatsappNumber
                    : "NA"}
                </p>
              ) : (
                <span className={`${styles2.whatsappText}`}>
                  {email && email.length !== 0 ? email : ""}
                </span>
              )}
              <p
                className={`mb-0 d-block text-truncate ps-3 ${styles2.location}`}
              >
                <span className={`pe-1 ${styles2.whatsappText}`}>
                  <img
                    src={userLocation}
                    alt=""
                    width={7}
                    height={10}
                  />
                </span>
                {/* Adding location */}
                {location && location.length !== 0 ? location : "unknown"}
              </p>
            </div>
          </div>
        </div>
        {/* <span className="ms-3">
          {canSendNotification === true ? 
            <i className="fa-solid fa-bell" role={"button"} style={{color: "#1F9004"}} onClick={(e)=>handleOnClickNotification(!canSendNotification)}></i>
            :
          <i className="fa-solid fa-bell-slash" role={"button"} style={{color: "#FF0000"}} onClick={(e)=>handleOnClickNotification(!canSendNotification)}></i>
        }
        </span> */}
      </div>
    </div>
  );
}

export default ChatInfo;
