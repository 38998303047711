import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCallerTypes, getCallRecording } from "src/services/CallRecording";
import {
  calculateTotalPages,
  pageSlice,
  paginationLogic,
} from "src/services/TicketService/pagination";
import { RootState } from "src/store/store";

const callerDataThunk = async (
  payload: undefined,
  { getState }: { getState: Function }
) => {
  try {
    const currentState: RootState = getState();
    const currentPage =
      currentState.callrecording.currentPage === 0
        ? 1
        : currentState.callrecording.currentPage;
    let allCallerTypes = currentState.callrecording.allCallerTypes;
    if (Object.keys(allCallerTypes).length === 0) {
      allCallerTypes = (await getCallerTypes()).allCallerTypes;
    }
    const { startPage, endPage, start, limit } = paginationLogic(
      currentPage,
      currentState.callrecording.rowsPerPage,
      currentState.callrecording.noOfPages,
      currentState.callrecording.cachingPageNoLimit
    );
    const { callerData, callerDataMeta, callerIdList } = await getCallRecording(
      {
        start,
        limit,
      }
    );
    const totalPages = calculateTotalPages(
      callerDataMeta.totalCount,
      currentState.callrecording.rowsPerPage
    );
    const cachedData: { [pageNo: string | number]: Array<number | string> } =
      {};
    let count = 0;
    for (let i = startPage; i <= endPage; i++) {
      cachedData[i] = pageSlice(
        callerIdList,
        currentState.ticket.ticketsPerPageLimit,
        count
      );
      count++;
    }
    return {
      callerData,
      callerDataMeta,
      cachedData,
      noOfPages: totalPages,
      currentPage,
      allCallerTypes,
    };
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export default { callerDataThunk };

export const fetchCallerDataThunk = createAsyncThunk(
  "callRecording/fetchCallerDataThunk",
  callerDataThunk
);
