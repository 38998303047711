import styles from "./LiveChatWidget.module.scss";
import { useEffect, useMemo, useState } from "react";
import SelecteShopfyStore from "./Children/ShopifyStore/SelecteShopfyStore";
import OtherWebsite from "./Children/OtherWebsite/OtherWebsite";
import RadioButtons from "./Children/RadioButtons/RadioButtons";
import { fetchWebIntegration } from "src/store/slices/liveChatSetting/websiteSettings/websiteSettings.slice";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { useFetchLinkableShopifyStores } from "./Children/OtherWebsite/Children/CustomHook/useFetchLinkableShopifyStores";
import Loader from "src/components/Loader";

const LiveChatWidget = () => {
  // State to keep track of the selected radio button value
  const [selectedRadio, setSelectedRadio] = useState("otherWebsite");
  // Retrieve the integration ID from the URL parameters
  const { integrationId } = useParams();
  // Custom hook to dispatch actions in Redux
  const dispatch = useAppDispatch();
  // Hook to programmatically navigate between routes
  const navigate = useNavigate();

  // Selecting specific parts of the state from the Redux store
  const { activeWebIntegration, activeWebIntAJAXStatus } = useAppSelector(
    (state) => state.websiteSettings,
  );

  // Custom hook to fetch Shopify stores based on the integration ID and selected radio button
  const { allStores, loading } = useFetchLinkableShopifyStores({
    integrationId,
    enabled: integrationId && selectedRadio === "shopify" ? true : false,
  });

  // Determine if the "Other Website" button should be disabled
  const disableOtherWebsiteButton = useMemo(() => {
    // Conditions to disable the button based on the integration type and other properties
    if (
      activeWebIntegration &&
      activeWebIntegration.live_chat_integration_type === "shopify" &&
      (activeWebIntegration.installed_successfully ||
        activeWebIntegration?.brand?.id)
    ) {
      return true;
    }
    return false;
  }, [
    // Dependencies for the useMemo hook to re-calculate the value
    activeWebIntegration?.live_chat_integration_type,
    activeWebIntegration?.embedded_script,
    activeWebIntegration?.installed_successfully,
    activeWebIntegration?.brand?.id,
  ]);

  // Determine if the "Shopify" button should be disabled
  const disableShopifyButton = useMemo(() => {
    // Conditions to disable the button based on the integration type and other properties
    if (
      activeWebIntegration &&
      activeWebIntegration.live_chat_integration_type == "otherWebsite" &&
      activeWebIntegration.embedded_script
    ) {
      return true;
    }
    return false;
  }, [
    // Dependencies for the useMemo hook to re-calculate the value
    activeWebIntegration?.live_chat_integration_type,
    activeWebIntegration?.embedded_script,
    activeWebIntegration?.installed_successfully,
  ]);

  // UseEffect hook to set the selected radio button based on the active web integration type
  useEffect(() => {
    // Check the type of live chat integration and set the radio button accordingly
    if (activeWebIntegration.live_chat_integration_type === "shopify") {
      setSelectedRadio("shopify");
    } else {
      setSelectedRadio("otherWebsite");
    }
    // Depend on the live_chat_integration_type to re-run this effect when it changes
  }, [activeWebIntegration?.live_chat_integration_type]);

  // UseEffect hook to fetch web integration details based on the integration ID
  useEffect(() => {
    // Ensure there is an integration ID before dispatching the action
    if (integrationId) {
      dispatch(
        fetchWebIntegration({
          integration_id: integrationId,
          // Callback function to navigate to the live chat settings page after fetching
          navigateCallBack: () => {
            navigate("/live-chat/settings");
          },
        }),
      );
    }
    // Depend on the integrationId to re-run this effect when it changes
  }, [integrationId]);

  // useMemo hook to determine if the integration is installed
  const isInstalled = useMemo(() => {
    // Check if there is an active web integration
    if (activeWebIntegration) {
      // For 'otherWebsite' type, check if it's installed successfully
      if (
        activeWebIntegration.live_chat_integration_type === "otherWebsite" &&
        activeWebIntegration.installed_successfully
      ) {
        return true;
      }
      // For 'shopify' type, check both installed_successfully and check_installation_success
      else if (
        activeWebIntegration.live_chat_integration_type === "shopify" &&
        activeWebIntegration.installed_successfully &&
        activeWebIntegration.check_installation_success
      ) {
        return true;
      }
    }
    // Return false if none of the conditions are met
    return false;
    // Depend on the specific properties of activeWebIntegration to re-calculate when they change
  }, [
    activeWebIntegration?.check_installation_success,
    activeWebIntegration.installed_successfully,
  ]);

  return (
    <div
      className={`${styles.chatWidgetMain}`}
      id="liveChatIntegration"
    >
      <div className={`d-flex`}>
        <h3 className={`${styles.chatWidgetHeader}`}>
          Install Helplama live chat widget on the website
          {/* ---installed button---  */}
          {isInstalled && (
            <button
              disabled={true}
              className={`${styles.installedBtn}`}
            >
              <span>
                <i className="fa-solid fa-check me-1"></i>
                Installed
              </span>
            </button>
          )}
        </h3>
      </div>
      <p className={`${styles.chatWidgetPara}`}>
        {`To see LiveChat on your website, you'll need to follow the instructions mentioned below:`}
      </p>
      {/* Radio Button's */}
      {/* showing loader until integration is fetched */}
      {!activeWebIntegration.integration_id &&
      activeWebIntAJAXStatus == "pending" ? (
        <>
          <div>
            <Loader />
          </div>
        </>
      ) : (
        <>
          <RadioButtons
            selectedRadio={selectedRadio}
            setSelectedRadio={setSelectedRadio}
            disableOtherWebsiteButton={disableOtherWebsiteButton}
            disableShopifyButton={disableShopifyButton}
          />
          {/* Conditional rendering based on selected radio button */}
          {selectedRadio === "shopify" ? (
            <>
              {/* Render Select Shopify Store component if "shopify" is selected */}
              <SelecteShopfyStore
                stores={allStores}
                storesLoading={loading}
              />
            </>
          ) : (
            <OtherWebsite />
          )}
        </>
      )}
    </div>
  );
};
export default LiveChatWidget;
