// React and Bootstrap imports
import { useCallback, useMemo, useState } from "react";
import { Dropdown } from "react-bootstrap";
import styles from "./Filters.module.scss";
import ActionButton from "src/components/ActionButton";
import ListFilter from "./ListFilter";

// Custom hook imports
import { useSegmentView } from "../../hooks/useSegmentView";
import { BetweenRange } from "src/services/CustomerSegments/createSegment.service";
import useFetchFilters from "../../hooks/useFetchFilters";
import { useCustomerSegments } from "src/routes/CustomerSegments/hooks/useCustomerSegments";
import { SegmentFilterOption } from "src/services/CustomerSegments/getSegmentFilters.service";
import Loader from "src/components/Loader";

/**
 * Filters component for managing segment view filters.
 * This component integrates with React Bootstrap components and custom hooks for state management.
 */
function Filters() {
  // State variables
  // Manages dropdown visibility
  const [toggleState, setToggleState] = useState<boolean>(false);
  // Controls error display
  const [showErrors, setShowErrors] = useState<boolean>(false);
  // Stores filter search value
  const [filterSearchValue, setFilterSearchValue] = useState("");

  // Custom hooks for segment and filter management
  // Fetches active segment details
  const { activeSegmentDetails } = useCustomerSegments();
  const { filters, filtersFetchStatus } = useFetchFilters({
    // Fetches filters based on segment details
    segmentType: activeSegmentDetails.activeSegmentType + "",
    segmentId: activeSegmentDetails.activeSegmentId + "",
  });

  // Manages segment view state
  const { segmentView, dispatch, fetchMoreSegmentView } = useSegmentView();

  /**
   * Determines if there are validation errors in selected filters.
   */
  const isError = useMemo(() => {
    let error = false;
    for (const filterKey in segmentView.selectedFilters) {
      const filter = segmentView.selectedFilters[filterKey];
      if (
        typeof filter.selectedValue === "object" &&
        filter.selectedValue &&
        !Array.isArray(filter.selectedValue) &&
        filter.selectedValue.showDateTimePicker
      ) {
        if (filter.selectedValue.isRange) {
          // Check if range filter is valid
          if (
            !(filter.valueDate as BetweenRange<string | null> | undefined)
              ?.start ||
            !(filter.valueDate as BetweenRange<string | null> | undefined)?.end
          ) {
            error = true;
          }
        } else if (!filter.valueDate) {
          error = true;
        }
      }
    }

    return error;
  }, [segmentView]);

  /**
   * Handles change in the search input for filters.
   * @param e - The change event object.
   */
  const handleSearchChange: React.ChangeEventHandler<HTMLInputElement> =
    useCallback((e) => {
      setFilterSearchValue(e.target.value);
    }, []);

  /**
   * Handles input change for specific filter.
   * @param e - The change event object.
   */
  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> =
    useCallback(
      (e) => {
        const filterKey = e.target.getAttribute("data-filter-key");
        if (filterKey) {
          // Dispatch action to update filter value
          dispatch("appendFilter", {
            filterKey,
            selectedValue: e.target.value,
          });
        }
      },
      [dispatch],
    );

  /**
   * Handles change in selection for a filter.
   * @param selectedValue - The selected filter value.
   * @param filterKey - The key of the filter.
   */
  const handleChange = useCallback(
    (
      selectedValue: SegmentFilterOption | SegmentFilterOption[] | null,
      filterKey: string,
    ) => {
      // Dispatch action to update filter selection
      dispatch("appendFilter", {
        filterKey,
        selectedValue,
      });
    },
    [dispatch],
  );

  /**
   * Handles change in date selection for a filter.
   * @param valueDate - The selected date value.
   * @param filterKey - The key of the filter.
   */
  const handleDateChange = useCallback(
    (
      valueDate: string | BetweenRange<string | null> | undefined,
      filterKey: string,
    ) => {
      // Dispatch action to update filter date value
      dispatch("appendFilter", {
        filterKey,
        valueDate,
      });
    },
    [dispatch],
  );

  /**
   * Handles cancellation of filter changes.
   */
  const handleCancel = useCallback(() => {
    setToggleState(false);
  }, []);

  /**
   * Fetches segment view data after applying filters.
   */
  const fetchAfterApplying = useCallback(() => {
    // Resets segment view and fetches updated data
    dispatch("setSegmentView", {
      segmentValues: {},
      segmentValueIds: [],
      hasMoreSegmentView: false,
    });

    // Need to put it in set time out because untill this function will stop execution the state will be stale.
    setTimeout(() => {
      fetchMoreSegmentView();
    }, 0);
  }, [dispatch, fetchMoreSegmentView]);

  /**
   * Handles applying selected filters.
   */
  const handleApply = useCallback(() => {
    if (filtersFetchStatus === "fulfilled") {
      if (isError) {
        setShowErrors(true);
      } else {
        setShowErrors(false);
        setToggleState(false);
        dispatch("applyFilters");
        fetchAfterApplying();
      }
    }
  }, [dispatch, isError, filtersFetchStatus, fetchAfterApplying]);

  /**
   * Handles clearing all filters.
   */
  const handleClearAll = useCallback(() => {
    if (
      filtersFetchStatus === "fulfilled" &&
      (Object.keys(segmentView.appliedFilters).length > 0 ||
        Object.keys(segmentView.selectedFilters).length > 0)
    ) {
      setShowErrors(false);
      setToggleState(false);
      dispatch("clearAllFilters");
      fetchAfterApplying();
    }
  }, [dispatch, filtersFetchStatus, segmentView, fetchAfterApplying]);

  return (
    <Dropdown
      onToggle={setToggleState}
      show={toggleState}
      align="start"
      id="ticketFilterSegmentBtn"
    >
      <Dropdown.Toggle
        as="div"
        bsPrefix={styles.caret}
      >
        <ActionButton
          className={`mx-md-1`}
          active={Object.keys(segmentView.appliedFilters).length ? true : false}
        >
          <div className="d-flex align-items-center justify-content-center">
            <span>
              <i className={`fa fa-filter me-2`} />
            </span>
            Filter
            <span>
              {Object.keys(segmentView.appliedFilters).length === 0 ? (
                <i
                  className={`fa-solid fa-caret-${
                    toggleState ? "up" : "down"
                  } ms-2`}
                />
              ) : (
                <span className={`ms-2 me-0 ${styles.badge}`}>
                  {segmentView.appliedFilters
                    ? Object.keys(segmentView.appliedFilters).length
                    : ""}
                </span>
              )}
            </span>
          </div>
        </ActionButton>
      </Dropdown.Toggle>
      <Dropdown.Menu
        className={styles.dropdownMenu}
        id="ticketFilterMenu"
      >
        <ul
          className={`list-unstyled mb-1 ${styles.coloumn_dropdown}`}
          aria-labelledby="table"
        >
          <>
            <li className={`${styles.table__li}`}>
              <div className="d-flex">
                <div
                  role="button"
                  className={`w-35 ${styles.restoreCols}`}
                  onClick={handleClearAll}
                >
                  Clear all filters
                </div>

                {isError && (
                  <div
                    className="text-danger ms-3"
                    style={{ fontSize: "smaller" }}
                  >
                    *Please fill all the details correctly
                  </div>
                )}
              </div>
            </li>
            <li>
              <div
                className={`w-100 d-flex my-1`}
                id="searchfilters"
              >
                <span className={`${styles.searchTicket2} w-0`}>
                  <i className={`fas fa-search`} />
                </span>
                <input
                  data-lpignore="true"
                  type="text"
                  value={filterSearchValue}
                  className={`${styles["col-search"]} w-100`}
                  placeholder="Search"
                  onChange={handleSearchChange}
                />
              </div>
            </li>
            <div className={`mt-3`}>
              {filtersFetchStatus === "rejected" ? (
                <div className="d-flex justify-content-center align-content-center align-items-center pt-4 text-danger">
                  Error Loading Content...
                </div>
              ) : filtersFetchStatus === "pending" ? (
                <div className="d-flex justify-content-center align-content-center align-items-center">
                  <Loader />
                </div>
              ) : (
                filters.map((filter) => {
                  if (
                    filter.name
                      .toLowerCase()
                      .includes(filterSearchValue.toLowerCase().trim())
                  ) {
                    return (
                      <div
                        key={filter.filterKey}
                        className="mt-2 mb-1"
                      >
                        <div className={`${styles.filterLabel}`}>
                          {filter.name}
                        </div>
                        {filter.type === "string" ||
                        filter.type === "number" ? (
                          <input
                            type={filter.type === "number" ? "number" : "text"}
                            value={
                              (segmentView.selectedFilters[filter.filterKey]
                                ?.selectedValue as string | undefined | null) ??
                              (filter.defaultValue as
                                | string
                                | undefined
                                | null) ??
                              ""
                            }
                            data-filter-key={filter.filterKey}
                            className={`w-100`}
                            placeholder={filter.placeholder ?? "Enter..."}
                            onChange={handleInputChange}
                          />
                        ) : filter.type === "select" ? (
                          <ListFilter
                            filter={filter}
                            handleChange={handleChange}
                            value={
                              (segmentView.selectedFilters[filter.filterKey]
                                ?.selectedValue as
                                | SegmentFilterOption
                                | SegmentFilterOption[]
                                | null
                                | undefined) ??
                              (filter.defaultValue as
                                | SegmentFilterOption
                                | SegmentFilterOption[]
                                | null
                                | undefined) ??
                              null
                            }
                            dateValue={
                              segmentView.selectedFilters[filter.filterKey]
                                ?.valueDate
                            }
                            handleDateChange={handleDateChange}
                            showErrors={isError && showErrors}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  }
                })
              )}
            </div>
          </>
        </ul>
        <div
          className={`d-flex justify-content-between pb-2 pt-3 ${styles.bottomContainer}`}
        >
          <div
            role="button"
            className={`px-3 py-1 ${styles.cancelBtn}`}
            id="filterCancelBtnSegment"
            onClick={handleCancel}
          >
            Cancel
          </div>

          <div
            role="button"
            className={`px-3 py-1 ${styles.applyBtn}`}
            id="filterApplyBtnSegment"
            onClick={handleApply}
          >
            Apply
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default Filters;
