import { axiosJSON } from "src/globals/axiosEndPoints";

export interface BrandDetail {
  id: string;
  brandName: string;
  brandEmail: string;
}

export interface GetBrandMetaData {
  count: number;
  totalCount: number;
}

export interface GetBrands {
  data: Array<BrandDetail>;
  metaData: GetBrandMetaData;
}

export interface GetBrandParams {
  start: number;
  limit: number;
}

export async function getBrands(params: GetBrandParams) {
  const { data } = await axiosJSON.post(
    `/api/setting/integration/getBrands`,
    params
  );
  if (data.err === true || data.error === true) {
    throw new Error(data.msg as string);
  }
  // const data: any = await getBrandsByPromise(params);
  return data as GetBrands;
}

// let getBrandsByPromise = function (params: GetBrandParams) {
//   return new Promise(function (resolve, reject) {
//     if (params) {
//       setTimeout(function () {
//         resolve({
//           data: [
//             {
//               id: 1,
//               brandName: "Helpdesk Brand",
//               brandEmail: "helpdeskbrand@helpdesk.com",
//             },
//             {
//               id: 2,
//               brandName: "Weissnat-Feest",
//               brandEmail: "choeger@farrell.net",
//             },
//             {
//               id: 3,
//               brandName: "Gibson-Beer",
//               brandEmail: "gibson.tony@schmidt.com",
//             },
//             {
//               id: 4,
//               brandName: "Kutch PLC",
//               brandEmail: "crona.ansley@daugherty.com",
//             },
//             {
//               id: 5,
//               brandName: "Gleichner, O'Keefe and Kuhlman",
//               brandEmail: "okuhic@cormier.net",
//             },
//             {
//               id: 7,
//               brandName: "Dummy Brand",
//               brandEmail: "dummybrand@testmailwarrior.ml",
//             },
//             {
//               id: 8,
//               brandName: "Test Brand",
//               brandEmail: "sreeragtest8@gmail.com",
//             },
//             {
//               id: 17,
//               brandName: "Custom Support Email Test",
//               brandEmail: "sreeragtest@example.tk",
//             },
//           ],
//           metaData: {
//             count: 8,
//             totalCount: 8,
//           },
//         });
//       }, 2000);
//     } else {
//       reject(new Error("Something is not right"));
//     }
//   });
// };
