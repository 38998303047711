import styles from "./TargetedMessages.module.scss";
import TargetLinks, { Links } from "./TargetLinks/TargetLinks";
import { useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import AddMessageModal from "../Children/AddMessageModal/AddMessageModal";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { fetchGetTargetedMessage } from "src/store/slices/targetedMessage/targetedMessage.thunks";
import { GetTargetedMessage } from "src/services/LiveChat/Settings/TargetedMessage/getTargetedMessage";
const TargetedMessages = () => {
  const { subSettingName } = useParams();
  const ShowComp = Links[subSettingName + ""]?.component;
  const [showModal, setShowModal] = useState(false);

  const onShow = useCallback(() => {
    setShowModal(true);
  }, []);
  const onHide = useCallback(() => {
    setShowModal(false);
  }, []);

  // const { getTargetedMessage } = useAppSelector((state) => {
  //   return {
  //     getTargetedMessage: state.targetedMessage.getTargetedMessage,
  //   };
  // });

  const dispatch = useAppDispatch();

  // useEffect(() => {
  //   dispatch(fetchGetTargetedMessage({ targetedMessageId: 2 }));
  // }, []);

  return (
    <>
      <div className={`${styles.targetWrapper}`}>
        <h1 className={`${styles.header}`}>Targeted Messages</h1>
        <div>
          <div className={`d-flex align-items-center`}>
            <span className={`me-2 ${styles.multi}`}>
              Multiple targeted messages
            </span>
            <div className="form-check form-switch me-2">
              <input className={`form-check-input`} type="checkbox" />
              <span className={`${styles.multi}`}>On</span>
            </div>
          </div>
          <p className={`${styles.subSpan}`}>
            Customers will see as many targeted messages as many conditions will
            be met during the visit.
          </p>
          <button className={`${styles.addNewBtn}`} onClick={onShow}>
            <span>
              <i className="fa-solid fa-plus"></i>{" "}
            </span>
            Add new targeted message
          </button>
          <Modal
            backdropClassName={`${styles.modalBack}`}
            show={showModal}
            onHide={onHide}
            dialogClassName={`${styles.modalDialog}`}
            contentClassName={`${styles.modalContent}`}
            //   centered={true}
            enforceFocus={false}
          >
            <AddMessageModal onHide={onHide} />
          </Modal>
          <div className={`mt-3`}>
            {/* Target link component  */}
            <TargetLinks />
          </div>
          {/* Show Greeting or Announcement component on switch */}
          <div className={`mt-3`}>{ShowComp && <ShowComp />}</div>
        </div>
      </div>
    </>
  );
};

export default TargetedMessages;
