import { useCallback, useEffect, useState } from "react";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { updateBotProfile } from "src/services/Bot/BotProfiles/updateBotProfile.service";
import { isHTMLEmpty, replaceSpanWithKeyB, trimPDIVBR } from "src/utils/utils";
import { IVariableB } from "../ConfigureEmailModal/Children/EditEmail/EditEmail";

/**
 * Custom Hook for managing Configure followup email Modal
 */
interface Props {
  profileId: number;
  data: {
    salutation: string;
    body: string;
  };
  handleEmailModalDataChange: (key: string, value: any) => void;
  variablesList?: {
    [x: string]: IVariableB;
  };
  onHide: () => void;
}
const useFollowupEmailModal = ({
  profileId,
  data,
  handleEmailModalDataChange,
  variablesList,
  onHide,
}: Props) => {
  // Save Modal States
  const [showSaveLoader, setShowSaveLoader] = useState(false);
  const [saveButtonText, setSaveButtonText] = useState<
    "Save" | "Saved!" | "Saving..."
  >("Save");

  const [editedData, setEditedData] = useState(
    data ?? {
      salutation: "",
      body: "",
    },
  );
  const [showError, setShowError] = useState(false);

  /**
   * Effect for initializing edited data
   */
  useEffect(() => {
    if (data) {
      setEditedData(data);
    }
  }, [data]);

  /**
   * Handler for saving modal
   */
  const handleSubmit = useCallback(async () => {
    // Check if salutation or body is empty
    // If yes , then show error and return
    if (
      isHTMLEmpty(trimPDIVBR(editedData?.body?.trim() ?? "")) ||
      isHTMLEmpty(trimPDIVBR(editedData?.salutation?.trim() ?? ""))
    ) {
      setShowError(true);
      return;
    }
    // If all feilds are filled, check if bot profile id is valid
    if (profileId) {
      try {
        // Format payload -replace spans with keys
        const modalData = {
          body: replaceSpanWithKeyB(
            editedData?.body ?? "",
            variablesList ?? {},
          ),
          salutation: replaceSpanWithKeyB(
            editedData?.salutation ?? "",
            variablesList ?? {},
          ),
        };

        //Show loader and set saving.. text
        setShowSaveLoader(true);
        setSaveButtonText("Saving...");
        //Api call
        await updateBotProfile({
          id: profileId,
          email_followup_configure_model: {
            body: modalData.body,
            salutation: modalData.salutation,
          },
        });

        setSaveButtonText("Saved!");
        pushTheToast({
          position: "top-right",
          text: "Saved Successfully !",
          type: "success",
        });
        handleEmailModalDataChange("emailFollowupConfigureModel", {
          ...editedData,
        });
        // change Updated Status to show tick mark once it is saved
        handleEmailModalDataChange("emailFollowupConfigureModelUpdated", true);

        onHide();
      } catch (e) {
        pushTheToast({
          position: "top-right",
          text: "Something went wrong !",
          type: "danger",
        });
        setSaveButtonText("Save");
        console.error(e);
      } finally {
        setShowSaveLoader(false);
      }
    }
  }, [
    editedData,
    handleEmailModalDataChange,
    onHide,
    profileId,
    variablesList,
  ]);

  const onChange = useCallback((key: "salutation" | "body", value: string) => {
    setEditedData((prevData) => {
      if (prevData) {
        return {
          ...prevData,
          [key]: value,
        };
      }
      return prevData;
    });
    setSaveButtonText("Save");
  }, []);

  return {
    handleSubmit,
    showSaveLoader,
    saveButtonText,
    onChange,
    showError,
    editedData,
  };
};

export default useFollowupEmailModal;
