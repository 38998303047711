import { axiosJSON } from "src/globals/axiosEndPoints";


export interface deleteArticleParams{
  articleId : string|number;
  integrationId : number|string;
}

export async function deleteArticle(params: deleteArticleParams) {
  const { data } = await axiosJSON.delete(
    `/api/chatSetting/article/delete`,
    {params}
  );
  if (data.error === true) {
    throw new Error(data.message as string);
  }
  return data.data;
}