import { useCallback, useEffect, useState } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import styles from "./NotificationBar.module.scss";

const EnableNotification = ({ setPermission }: any) => {
  const requestPermission = useCallback(() => {
    try {
      if (Notification) {
        Notification.requestPermission().then((res) => {
          setTimeout(() => {
            setPermission(Notification?.permission);
          }, 0);
        });
      }
    } catch {}
  }, []);
  return (
    <div className={`${styles.notificationBar}`}>
      <div className={`${styles.bar}`}>
        <span>Always get notified about new chats!</span>
        <button
          className={`btn ms-3 d-flex ${styles.btn}`}
          type="button"
          onClick={requestPermission}
        >
          <i className="fa-solid fa-bell m-auto"></i>
          <span className={`m-auto ms-2`}>Enable notifications</span>
        </button>
      </div>
      {/* <CloseButton className={`m-auto me-2`} /> */}
    </div>
  );
};
const UnblockNotificationModal = ({ show, setShow }: any) => {
  const onHide = useCallback(() => {
    setShow(false);
  }, []);
  return (
    <Modal
      show={show}
      onHide={onHide}
      dialogClassName={`${styles.dialog}`}
      contentClassName={`p-1 ${styles.content}`}
    >
      <div className="d-flex">
        <div className={`${styles.notificationModalHeading} ms-0`}>
          Notification settings
        </div>
        <CloseButton className="m-auto me-3" onClick={onHide} />
      </div>
      <div className={`${styles.notificationModalBody}`}>
        <div className={`${styles.note} d-flex`}>
          <div className="me-3">
            <i
              className={`${styles.warningIcon} fa-solid fa-circle-exclamation`}
            ></i>
          </div>
          <div>
            <div>
              Browser notifications are blocked. To start receiving
              notifications:
            </div>
            <div className="my-3">
              <div className="mb-3">
                <strong>On Chrome</strong> click the padlock{" "}
                <span>
                  <i className="fa-solid fa-lock"></i>
                </span>{" "}
                icon in the URL address bar and select <strong>Allow</strong>{" "}
                under the <strong>Notifications</strong> setting.
              </div>
              <div>
                <strong>On Safari</strong>{" "}
                <span>
                  choose <strong>Safari &gt; Preferences</strong>, click{" "}
                  <strong>Websites</strong>, then click{" "}
                  <strong>Notifications</strong>. Find {window.location.host} in
                  the list, and select <strong>Allow</strong>.
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* <button className={`btn ${styles.btn}`} type="button">
          <div>Check notifications tutorial</div>
        </button> */}
      </div>
    </Modal>
  );
};
const UnblockNotification = () => {
  const [show, setShow] = useState(false);
  return (
    <div className={`${styles.notificationBar} ${styles.unblock}`}>
      <div className={`${styles.bar}`}>
        {/* <i className={`fa-solid fa-bell-slash ${styles.bellIcon}`}></i> */}
        <span className={`ms-2 ${styles.textnotification}`}>
          {`Please enable browser notification so that you don't miss chats!`}
        </span>
        <button
          className={`btn ms-3 d-flex ${styles.btn}`}
          type="button"
          onClick={() => {
            setShow(true);
          }}
        >
          <span className={`m-auto`}>Unblock notifications</span>
        </button>
      </div>
      {/* <CloseButton className={`m-auto me-2`} /> */}
      <UnblockNotificationModal show={show} setShow={setShow} />
    </div>
  );
};

const NotificationBar = () => {
  const [show, setShow] = useState(
    (Notification?.permission || "none") as
      | "none"
      | "granted"
      | "denied"
      | "default"
  );

  return (
    <>
      {show === "default" && <EnableNotification setPermission={setShow} />}
      {show === "denied" && <UnblockNotification />}
    </>
  );
};
export default NotificationBar;
