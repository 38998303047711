import styles from "./EmailIntegration.module.scss";
import ShowTags from "./children/ShowTags/ShowTags";
import AddEmail from "./children/AddEmail/AddEmail";

function EmailIntegration() {
  return (
    <div className={`main__content`}>
      {/* <div className=" ">
        <div className="">
          <div className="top-btn d-flex justify-content-between flex-wrap">
            <div>
              <h3 className={`${styles.page_heading} mb-0`}>
                Email Integration
              </h3>
               <p className={`${styles.headling_tagline} m-0`}>
                Can create different tags edit , search &amp; delete
              </p> 
            </div>
          </div>
        </div>
      </div> */}
      <div className={`${styles.page_content} mt-3`}>
        <div className="">
          {/* <ShowTags /> */}
          <AddEmail />
        </div>
      </div>
    </div>
  );
}

export default EmailIntegration;
