/**
 * This is a react component file.
 *
 * @author Navjyot
 * @author Yash Aditya<yash@zinosi.com>
 */

import styles from "./ComingSoonBots.module.scss";
import RequestBox from "../Children/RequestBox/RequestBox";
import { ComingSoonFeatureTypes } from "src/services/ComingSoon/reqCmngSnFeature.service";

// ComingSoonBots component definition
const ComingSoonBots = ({
  activePath,
}: {
  activePath: ComingSoonFeatureTypes;
}) => {
  // Rendering the component structure
  return (
    <div className={`${styles.contentWrapper}`}>
      {/* Rendering the RequestBox component with the provided activePath */}
      <RequestBox activePath={activePath} />
    </div>
  );
};

export default ComingSoonBots;
