import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";

interface IFeatureLimit {
  name: string;
  count: number | string;
  maxCount: number | string;
}

export interface IBillingDetails {
  activePlan: {
    id: string | number;
    displayName: string; // Name of the Active Plan
    color: {
      background: string; // Background Color
      text: string; // Text Color
    };
    type: {
      name: "monthly" | "yearly"; //monthly or yearly
      displayName: string; //Anually or Monthly
      shortName: string; // yr or mo
    };
    price: string; // Price based on type
    appliedOffer?: {
      name: string;
      type: "percentage" | "fixed";
      price: string; // Removed Price in both cases fixed or percentage
    };
    planCycle?: {
      startDateGMT: string;
      endDateGMT: string;
    };
    isBillingComplete: boolean;
  };
  seats?: {
    totalCount: number;
    availableCount: number;
    utilizedCount: number;
    additionalSeats?: {
      perUnitPrice: string;
      dateTimeGMT?: string;
      quantity?: number;
    };
    lastPayment?: {
      price: string;
      paymentDateGMT: string;
    };
    nextPayment?: {
      price: string;
      paymentDateGMT: string;
    };
  };
  isFreePlan: boolean;
  featureLimit?: {
    limits: Array<IFeatureLimit>;
    isContactSales?: boolean;
    lastContactedAtGMT?: string;
  };
}

const url = IS_TEST_ACTIVE
  ? "http://localhost:30311/billing/getBillingDetails"
  : "/api/setting/billing/getDetails";

export async function getBillingDetails() {
  const { data: res } = await axiosJSON.post(url);
  if (res.err || res.error) {
    throw new Error(res.message ?? res.msg ?? "Something went wrong");
  }
  return res.data as IBillingDetails;
}
