import { GetAllGoalSetting, GetAllGoalSettingParams, getAllGoalSettings, getGoalTypeOptions } from "src/services/LiveChat/Settings/goals/getAllGoalSetting";
import { RootState } from "src/store/store";

// thunk that fetches all goalSettings
const fetchAllGoalSettingsThunk = async(
  payload:number|string,
  {getState}: {getState: Function}
) =>{
  try{
    const currentState: RootState = getState();
    const queryData: GetAllGoalSettingParams = {
      integrationId: payload,
      start: currentState.goalSetting.goalSettingIdList.length,
      limit: currentState.goalSetting.fetchGoalSettingsLimit
    
    }

    let goalTypeOptions = currentState.goalSetting.goalTypeOptions;
    if (goalTypeOptions.goalTypeOptionKeys.length === 0) {
      goalTypeOptions = await getGoalTypeOptions();
    }

    const goalSettingData: GetAllGoalSetting = await getAllGoalSettings(queryData);
    console.log("---------------goalSettingSettingThunk ------------ ");
    console.log("Goals data ::: "+ JSON.stringify(goalSettingData));
    return {
      goalSettingData: goalSettingData.goals,
      goalSettingIdList: goalSettingData.goalIds,
      count: goalSettingData.metaData.count,
      totalCount: goalSettingData.metaData.total,
      goalTypeOptions: goalTypeOptions
    }
  }catch(error){
    throw error;
  }
};


export default { fetchAllGoalSettingsThunk };