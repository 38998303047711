import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo, useState } from "react";
import {
  GetAllTemplatesData,
  searchTemplate,
} from "src/services/Templates/searchTemplates";
import {
  IGetCategory,
  getViewTemplateCategories,
  searchCategory,
} from "src/services/Templates/viewTemplateCategories";
import { IGetTemplateResponse } from "src/services/Templates/viewTemplates";
interface ITemplateCategory {
  id: number;
  name: string;
  subCategoryExists: boolean;
}
interface GetAllTemplateCategories {
  categoryIds: number[];
  categories: { [key: number]: ITemplateCategory };
  metaData: {
    total: number;
    count: number;
  };
}
interface IGetCategoryPayload extends IGetCategory {
  searchTerm?: string;
}
export const useGetTemplateCategories = (payload: IGetCategoryPayload) => {
  const fetchCategories = async (pageParam: IGetCategoryPayload) => {
    let data: any = null;

    if (pageParam.searchTerm && pageParam.searchTerm.trim() !== "") {
      data = await searchCategory(pageParam as any);
    } else {
      delete pageParam.searchTerm;
      data = await getViewTemplateCategories(pageParam);
    }

    const ret: GetAllTemplateCategories = {
      categoryIds: [],
      categories: {},
      metaData: {
        total: data.totalCount ?? 0,
        count: data.count ?? 0,
      },
    };

    data.categories.forEach((category: ITemplateCategory) => {
      const id = parseInt(category.id + "");
      ret.categories[id] = category;
      ret.categoryIds.push(id);
    });

    return ret;
  };
  const queryClient = useQueryClient();
  const [categoryAdded, setCategoryAdded] = useState(null as any);
  const {
    status,
    error,
    data,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isError,
    fetchStatus,
    refetch,
  } = useInfiniteQuery({
    queryKey: ["InnerTicket/getTemplateCategories", payload],
    queryFn: ({ pageParam = payload }) => fetchCategories(pageParam),
    getNextPageParam: (
      prevPage: GetAllTemplateCategories,
      allPages: GetAllTemplateCategories[]
    ) => {
      const data = allPages.flatMap((page) => page.categoryIds);
      if (data.length < prevPage.metaData.total) {
        return {
          ...payload,
          start: data.length,
        };
      }
      return null;
    },
  });

  const categories = useMemo(() => {
    let categoriesData: { [key: number | string]: ITemplateCategory } = {};
    data?.pages.forEach((page) => {
      categoriesData = { ...categoriesData, ...page.categories };
    });
    return categoriesData;
  }, [data, categoryAdded]);

  const categoryIds = useMemo(() => {
    const ids = data?.pages.flatMap((page) => page.categoryIds) ?? [];
    return Array.from(new Set([...ids]));
  }, [data, categoryAdded]);

  const addCategory = useCallback(
    (newCategory: ITemplateCategory) => {
      // Add the newly created category to the existing categories in query cache
      queryClient.setQueryData(
        ["InnerTicket/getTemplateCategories", payload],
        (oldData: any) => {
          const updatedData = { ...oldData };

          if (updatedData?.pages[0]) {
            setCategoryAdded(newCategory); //to trigger re-render
            // Add the new category to the existing categories data
            updatedData.pages[0].categories[newCategory.id] = newCategory;
            updatedData.pages[0].categoryIds.unshift(newCategory.id);
          }
          return updatedData;
        }
      );
    },
    [payload]
  );

  return {
    status,
    error,
    categories,
    categoryIds,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isError,
    fetchStatus,
    refetch,
    addCategory,
  };
};
