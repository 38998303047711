import { LiveChatHistoryConversationRes } from "src/services/LiveChat/liveChatHistory/getLiveChatHistoryConversation.service";
import { LiveChatHistoryChatsData } from "src/services/LiveChat/liveChatHistory/liveChatHistoryChats.service";
import { LiveChatHistoryMessagesData } from "src/services/LiveChat/liveChatHistory/liveChatHistoryMessages.service";
import { Customer } from "src/services/LiveChat/messageExchange/getAllCustomers";
import { LiveChatHistorySlice } from "./liveChatHistory.declarations";

export interface LiveChatHistoryChatsDataCustomer
  extends LiveChatHistoryChatsData {
  customer: Customer;
}

const fetchHistoryChatsFulfilled = (
  state: LiveChatHistorySlice,
  { payload }: { payload: LiveChatHistoryChatsDataCustomer }
) => {
  if (state.selectedChatId == payload.customer.chatId) {
    state.fetchHistoryChatsAJAXStatus = "fulfilled";
    state.historyChats.chatList = {
      ...state.historyChats.chatList,
      ...payload.chatList,
    };
    // state.historyChats.chatListIds = [
    //   ...state.historyChats.chatListIds,
    //   ...payload.chatListIds,
    // ];

    state.historyChats.chatListIds = Array.from(
      new Set([...state.historyChats.chatListIds, ...payload.chatListIds])
    );

    state.historyChats.metaData = payload.metaData;
    if (state.selectedInternalChatId == null) {
      state.selectedInternalChatId = state.historyChats.chatListIds[0];
    }
    state.customer = payload.customer;
  }

  return state;
};
const fetchHistoryChatsPending = (state: LiveChatHistorySlice) => {
  state.fetchHistoryChatsAJAXStatus = "pending";
  return state;
};
const fetchHistoryChatsRejected = (state: LiveChatHistorySlice) => {
  state.fetchHistoryChatsAJAXStatus = "rejected";
  return state;
};

const fetchHistoryMessagesFulfilled = (
  state: LiveChatHistorySlice,
  { payload }: { payload: LiveChatHistoryMessagesData }
) => {
  if (state.selectedInternalChatId == payload.customerData?.chatId) {
    state.fetchSelectedMessagesAJAXStatus = "fulfilled";
    state.selectedChatMessages.messageList = {
      ...state.selectedChatMessages.messageList,
      ...payload.messageList,
    };
    // state.selectedChatMessages.messageListIds = [
    //   ...state.selectedChatMessages.messageListIds,
    //   ...payload.messageListIds,
    // ];
    state.selectedChatMessages.messageListIds = Array.from(
      new Set([
        ...state.selectedChatMessages.messageListIds,
        ...payload.messageListIds,
      ])
    );

    state.selectedChatMessages.customerData = payload.customerData;
    state.selectedChatMessages.metaData = payload.metaData;
  }

  return state;
};
const fetchHistoryMessagesPending = (state: LiveChatHistorySlice) => {
  state.fetchSelectedMessagesAJAXStatus = "pending";
  return state;
};
const fetchHistoryMessagesRejected = (state: LiveChatHistorySlice) => {
  state.fetchSelectedMessagesAJAXStatus = "rejected";
  return state;
};

// Reducer for fetchLiveChatHistoryConversation API
const fetchLiveChatHistoryConversationFulfilled = (
  state: LiveChatHistorySlice,
  { payload }: { payload: LiveChatHistoryConversationRes }
) => {
  state.historyConversationAJAXStatus = "fulfilled";
  state.historyConversations = {
    metaData: payload.metaData,
    conversationData: {
      ...state.historyConversations.conversationData,
      ...payload.conversationData,
    },
    conversationDataIds: [
      ...state.historyConversations.conversationDataIds,
      ...payload.conversationDataIds,
    ],
  };
};

const fetchLiveChatHistoryConversationPending = (
  state: LiveChatHistorySlice
) => {
  state.historyConversationAJAXStatus = "pending";
  return state;
};

const fetchLiveChatHistoryConversationRejected = (
  state: LiveChatHistorySlice
) => {
  state.historyConversationAJAXStatus = "rejected";
  return state;
};

export default {
  fetchHistoryChatsFulfilled,
  fetchHistoryChatsPending,
  fetchHistoryChatsRejected,
  fetchHistoryMessagesFulfilled,
  fetchHistoryMessagesPending,
  fetchHistoryMessagesRejected,
  fetchLiveChatHistoryConversationFulfilled,
  fetchLiveChatHistoryConversationPending,
  fetchLiveChatHistoryConversationRejected,
};
