import styles from "./DraftModal.module.scss";
import { useEffect, useMemo, useRef, useState } from "react";
import DraftHeader, { AddDH } from "./children/DraftHeader";
import AddDraft from "./children/AddDraft";
import ViewDraft from "./children/ViewDraft";
import { Modal } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  fetchAlldraftMsg,
  resetDraftFlow,
  setIsDraftEditing,
  setTicketId,
} from "src/store/slices/draftFlow/draftFlow.slice";
import Loader from "../Loader";
import DraftSent from "./children/DraftSent";

interface Props {
  ticketId: number | string;
  escalateMessage?: string;
  escalateSelectedFiles?: any;
  onHide: () => void;
  onShow: () => void;
  show: boolean;
  maintainFlow?: boolean;
  clearEscalateMessge?: () => void;
  clearEscalateSelectedFiles?: ([]) => void;
  onAnyChange?: () => void;
}

const DraftModal = ({
  ticketId,
  escalateMessage,
  escalateSelectedFiles,
  onHide,
  onShow,
  show,
  maintainFlow = false,
  clearEscalateMessge = () => {},
  clearEscalateSelectedFiles = () => {},
  onAnyChange = () => {},
}: Props) => {
  const dispatch = useAppDispatch();
  const {
    addEditMsgStatus,
    getDraftMsgStatus,
    addEditRemarkStatus,
    canAddNewDraftMsg,
    draftMsg,
    unsentDraftMsg,
  } = useAppSelector((state) => state.draftFlow);
  const [add, setAdd] = useState(
    (canAddNewDraftMsg || maintainFlow ? "Add Draft" : "Draft") as AddDH
  );

  // Retrieve the 'scopes' value from the Redux store using the 'useAppSelector' hook.
  const scopes = useAppSelector(
    (state) => state.globals.currentUserData?.scopes
  );

  // Create a reference ('currentState') to store the current values of 'scopes' and 'ticketId'.
  const currentState = useRef({ scopes, ticketId });

  // Use 'useMemo' to update 'currentState.current' when 'scopes' or 'ticketId' changes.
  useMemo(() => {
    currentState.current = { scopes, ticketId };
  }, [scopes, ticketId]);

  //moved the states from child compontent to parent to avoid resetting the draft and remarks value on parent state updation
  // Initialize state variables 'draft', 'remark', 'selectedFilesDraft', and 'selectedFilesRemark'.
  const [draft, setDraft] = useState("");
  const [remark, setRemark] = useState("");
  const [selectedFilesDraft, setSelectedFilesDraft] = useState<any>([]);
  const [selectedFilesRemark, setSelectedFilesRemark] = useState<any>([]);

  const isDraftEditing = useAppSelector(
    (state) => state.draftFlow.isDraftEditing
  );

  useEffect(() => {
    if (canAddNewDraftMsg || maintainFlow) {
      setAdd((value) => {
        if (value === "Add Remark") {
          return value;
        } else {
          return "Add Draft";
        }
      });
    } else {
      setAdd("Draft");
    }
  }, [canAddNewDraftMsg, show, ticketId, maintainFlow]);

  useEffect(() => {
    if (show) {
      dispatch(resetDraftFlow());
      dispatch(setTicketId({ ticketId }));
      dispatch(fetchAlldraftMsg());
    } else {
    }
  }, [show, ticketId]);

  const handleOnHide = () => {
    if (isDraftEditing === true) {
      dispatch(setIsDraftEditing({ isDraftEditing: false }));
    }
    onHide();
  };

  //moved the use effect from child component to parent ..to avoid resetting the saved value
  useEffect(() => {
    // Check if 'escalateMessage' is defined and not empty.
    if (escalateMessage !== undefined && escalateMessage.trim() !== "") {
      // If 'escalateMessage' is not empty, set it as the 'draft'.
      setDraft(escalateMessage);

      // Check if the current user has the 'add_draft_remark' permission.
      if (
        currentState.current.scopes &&
        currentState.current.scopes.includes("add_draft_remark")
      ) {
        // If the user has permission, set the 'Add' action to "Add Remark".
        setAdd("Add Remark");
      }
    } else {
      // If 'escalateMessage' is empty or undefined.
      // Check if the current user has the 'add_draft_remark' permission.
      if (
        currentState.current.scopes &&
        currentState.current.scopes.includes("add_draft_remark")
      ) {
        // If the user has permission, set the 'Add' action to "Add Draft".
        setAdd("Add Draft");
      }
    }
  }, [escalateMessage]);

  // when escalateSelectedFiles are there, coming from inner ticket escalate
  useEffect(() => {
    if (
      escalateSelectedFiles !== undefined &&
      escalateSelectedFiles.length > 0
    ) {
      // set the selected files in draft modal, coming from inner ticket escalate
      setSelectedFilesDraft(escalateSelectedFiles);
    } else {
      setSelectedFilesDraft([]);//reset the draft attachments array if no attachments exists in message
    }
  }, [escalateSelectedFiles]);

  return (
    <Modal
      // backdropClassName={`${styles.modalBack}`}
      show={show}
      onHide={handleOnHide}
      dialogClassName={`${styles.modalDialog}`}
      contentClassName={`${styles.modalContent}`}
    >
      <div>
        {addEditMsgStatus === "pending" || getDraftMsgStatus === "pending" ? (
          <Loader className={styles.loader} />
        ) : addEditMsgStatus === "fulfilled" ? (
          <DraftSent onHide={handleOnHide} />
        ) : (
          // addEditMsgStatus === "rejected" ||
          //   getDraftMsgStatus === "rejected" ||
          //   addEditRemarkStatus === "rejected" ? (
          //   <div className={styles.rejected}>Something went wrong!</div>
          // ) :
          <>
            <DraftHeader add={add} onHide={handleOnHide} />
            {canAddNewDraftMsg || maintainFlow ? (
              <AddDraft
                add={add}
                setAdd={setAdd}
                escalateMessage={escalateMessage}
                escalateSelectedFiles={escalateSelectedFiles}
                clearEscalateSelectedFiles={clearEscalateSelectedFiles}
                ticketId={ticketId}
                clearEscalateMessge={clearEscalateMessge}
                onAnyChange={onAnyChange}
                draft={draft}
                remark={remark}
                selectedFilesDraft={selectedFilesDraft}
                selectedFilesRemark={selectedFilesRemark}
                setDraft={setDraft}
                setRemark={setRemark}
                setSelectedFilesDraft={setSelectedFilesDraft}
                setSelectedFilesRemark={setSelectedFilesRemark}
              />
            ) : (
              <ViewDraft ticketId={ticketId} onAnyChange={onAnyChange} />
            )}
          </>
        )}
      </div>
    </Modal>
  );
};

export default DraftModal;
