import React from "react";
import { NavLink, useParams } from "react-router-dom";
import ArticleFeatures from "src/routes/LiveChat/Children/ChatSetting/Children/KnowledgeBased/Children/KnowledgeBasedArticles/Children/ArticleFeatures/ArticleFeatures";
import SettingSideBar from "./Children/ArticleSideBar/SettingSideBar";
import ThemeSelection from "./Children/ArticleSideBar/ThemeSelection/ThemeSelection";
import Brand from "./Children/Brand/Brand";
import CustomCode from "./Children/CustomCode/CustomCode";
import AccessControl from "./Children/DomainSsl/Children/AccessControl/AccessControl";
import Analytics from "./Children/DomainSsl/Children/Analytics/Analytics";
import Domain from "./Children/DomainSsl/Children/Domain/Domain";
import Messaging from "./Children/Messaging/Messaging";
import styles from "./KnowledgeSetting.module.scss";
import Rating from "./Children/FeedbackSection/Children/Rating/Rating";
import Comments from "./Children/FeedbackSection/Children/Comments/Comments";
import ApproveFeedback from "./Children/FeedbackSection/Children/ApproveFeedback/ApproveFeedback";
import CustomKnowledgeBased from "src/routes/LiveChat/Children/ChatSetting/Children/KnowledgeBased/Children/CustomKnowledgeBased/CustomKnowledgeBased";

const KnowledgeSetting = () => {
  const { subSettings } = useParams();
  return (
    <div className={`w-100 ${styles.knwowledgeWrapper}`}>
      <SettingSideBar />

      {subSettings === "themeSelection" && <ThemeSelection />}
      {subSettings === "featuredArticles" && <ArticleFeatures />}
      {subSettings === "brand" && <Brand />}
      {subSettings === "messaging" && <Messaging />}
      {subSettings === "customCode" && <CustomCode />}
      {subSettings === "ratings" && <Rating />}
      {subSettings === "comments" && <Comments />}
      {subSettings === "statusFeedback" && <ApproveFeedback />}
      {subSettings === "domain" && <Domain />}
      {subSettings === "accessControl" && <AccessControl />}
      {subSettings === "analytics" && <Analytics />}
      {subSettings === "customKnowledgeBase" && <CustomKnowledgeBased />}
    </div>
  );
};

export default KnowledgeSetting;
