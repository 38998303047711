import {
  createAsyncThunk,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";
import { AJAXSTATUS } from "src/globals/constants";
import { fetchAllRoutingRulesFulfilled } from "./RoutingRulesSetting.extraReducers";
import RoutingRulesSettingReducers from "./RoutingRulesSetting.reducers";
import { getRoutingRuleDataFromId } from "./RoutingRulesSetting.selectors";
import RoutingRulesSettingThunk from "./RoutingRulesSetting.thunk";

export interface RoutingRuleCondition{
  conditionId: string| number;
  conditionValue: string;
  conditionConstraint: string;
}

export interface IRoutingRuleConditionList{
  location: Array<RoutingRuleCondition>;
  url: Array<RoutingRuleCondition>;
  domain: Array<RoutingRuleCondition>;
  [key: string]: Array<RoutingRuleCondition>;
}

// interface that define a single routing rule
export interface IRoutingRuleData{
  ruleId: number|string;
  ruleName: string;
  ruleAction: string;
  teamId: number|string;
  teamName: string;
  integrationId: number|string;
  conditions: IRoutingRuleConditionList
}


export type RoutingRulesSetting = {
  routingRuleData: {
    [ruleId: number | string ] : IRoutingRuleData
  };
  routingRuleIdList: Array<number|string>;
  fetchRoutingRulesAjaxStatus: AJAXSTATUS;
  fetchRoutingRulesLimit: number;
  // totalRoutingRulesCount : number
  generalTeam: {teamId: number|string, teamName: string};
  metaData:{
    count: number,
    total: number
  }
} 

// to fetch all routingRules
export const fetchAllRoutingRulesData = createAsyncThunk(
  "routingRuleSettings/fetchAllRoutingRules",
   RoutingRulesSettingThunk.fetchAllRoutingRulesThunk
);

// initial states for the slice
export const initialState: RoutingRulesSetting = {
  routingRuleData: {},
  routingRuleIdList: [],
  generalTeam: {teamId:0, teamName:""},
  fetchRoutingRulesAjaxStatus: "pending",
  fetchRoutingRulesLimit: 10,
  metaData: {
    count: 0,
    total: 0
  }
}

// create the slice
export const routingRuleSettingSlice = createSlice({
  name: "routingRule",
  initialState,
  reducers: RoutingRulesSettingReducers,
  extraReducers: (builder) => {
    // fetch all routingRules
    builder.addCase(fetchAllRoutingRulesData.fulfilled,
        fetchAllRoutingRulesFulfilled
      );
    builder.addCase(fetchAllRoutingRulesData.pending,
      (state) => {
        state.fetchRoutingRulesAjaxStatus = "pending";
      });
    builder.addCase(fetchAllRoutingRulesData.rejected,
      (state) => {
        state.fetchRoutingRulesAjaxStatus = "rejected";
      });

  }
});


// selector to get routing rule data from rule id
export const getRoutingRuleDataFromIdSelector = createSelector(
  [getRoutingRuleDataFromId],
  (routingRuleDataFromId) => routingRuleDataFromId
);


export const {
  resetRoutingRuleSettings,
  resetRoutingRulesData,
} = routingRuleSettingSlice.actions;


export default routingRuleSettingSlice.reducer;