import { IShopifySidebar } from "./shopifySidebar.slice";

const fetchShopifySidebarDataFulfilled = (
  state: IShopifySidebar,
  { payload }: { payload: any }
) => {
  state.shopifyDataStatus = "fulfilled";
  const orders: any = {};
  payload.data.orders.forEach((data: any) => {
    orders[data.orderId] = data;
  });
  state.shopifyData = {
    customer: payload.data.customer,
    orders: { ...state.shopifyData.orders, ...orders },
    orderIds: [
      ...state.shopifyData.orderIds,
      ...payload.data.orders.map((data: any) => data.orderId)
    ],
    totalOrders: payload.metaData.totalCount
  };
};
const fetchCustomerDataFulfilled = (
  state: IShopifySidebar,
  { payload }: { payload: any }
) => {
  state.customerDataStatus = "fulfilled";
  state.customerData = payload.data;
};

export default { fetchShopifySidebarDataFulfilled, fetchCustomerDataFulfilled };
