import { axiosJSON } from "src/globals/axiosEndPoints";
import { IArticleCategoryData } from "./getAllArticleCategory";


interface ArticleData {
    articleId : number|string,
    articleName : string,
    integrationId : number|string,
    articleStatus : "public"|"draft",
    createdAt : Date,
    createdAtGmt : Date
}


export interface GetArticleCategoriesParams {
  categoryId: string|number,
  integrationId: number|string,
}

export async function getArticleCategory(params: GetArticleCategoriesParams) {
  const { data: res }: any = await axiosJSON.post(
    "/api/chatSetting/article/getCategory",
    params
  );

  if (res.error === true) {
    throw new Error(res.message as string);
  }

//   console.log("getAllRoutingRules service ret:: ", JSON.stringify(ret));
  return res.data as IArticleCategoryData;
}
