import styles from "./SelectAllTicketCheckBox.module.scss";
import {
  selectMergeAllTicketsById,
  removeAllSelectedMergeTickets,
  areAllMergeTicketsSelectedSelector
} from "src/store/slices/innerTicket/innerTicket.slice";
import { useAppSelector, useAppDispatch } from "src/store/store";
import log from "loglevel";

function SelectAllTicketChekBox() {
  const areAllTicketsSelected = useAppSelector(
    areAllMergeTicketsSelectedSelector
  );
  const dispatch = useAppDispatch();
  const scopes = useAppSelector(
    (state) => state.globals.currentUserData?.scopes
  );

  const handleOnChangeCheckbox = (e: any) => {
    // log.debug("toggled ticket merge select all");
    if (areAllTicketsSelected) {
      dispatch(removeAllSelectedMergeTickets());
    } else {
      dispatch(selectMergeAllTicketsById());
    }
  };
  return (
    <>
      <div className="form-check" style={{ cursor: "pointer" }}>
        {scopes && scopes.includes("bulk_edit") && (
          <input
            data-lpignore="true"
            className="form-check-input"
            style={{
              width: " 20px",
              height: "20px",
              borderRadius: "6px",
              border: "1px solid #0B68BB",
              cursor: "pointer"
            }}
            type="checkbox"
            value=""
            id="flexCheckDefault"
            checked={areAllTicketsSelected}
            onChange={handleOnChangeCheckbox}
          />
        )}
        <span className={`${styles["thead"]}`}>All Tickets</span>
      </div>
      {/* <div className={`${styles["allTickets"]}`}>
        <div className={`row m-auto`}>
          <div className={`col-md-12`}>
            <span className={`${styles["thead"]}`}>All Tickets</span>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default SelectAllTicketChekBox;
