/**
 * This file is the custom hook file.
 * It contains useStaticSettingsData custom hook.
 *
 * @author Yash Aditya
 */

import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { AJAXSTATUS } from "src/globals/constants";
import { getNotifyEnableDisableService } from "src/services/Settings/AgentNotifications/getNotifyEnableDisable.service";
import { getNotifyFreqTypeService } from "src/services/Settings/AgentNotifications/getNotifyFreqType.service";
import { getTimeZonesService } from "src/services/Settings/AgentNotifications/getTimeZones.service";

/**
 * Fetches static data required for agent notification settings.
 * @returns A promise containing an array of static data.
 */
const getStaticData = async () => {
  return await Promise.all([getNotifyFreqTypeService(), getTimeZonesService()]);
};

/**
 * Custom hook to fetch and manage static settings data for agent notifications.
 * @returns An object containing static settings data and the current status.
 */
function useStaticSettingsData() {
  // Fetch notification enable/disable status based on the calculated setting ID and status
  const {
    data: notifyEnabled,
    status: notifyEnabledStatus,
    refetch: refetchNotifyEnabled,
  } = useQuery(["reports/settings/getNotifySettingService"], {
    queryFn: () => {
      return getNotifyEnableDisableService();
    },
    staleTime: 0,
    cacheTime: 0,
  });

  // Fetch static data for frequency types and time zones
  const {
    data: notifyFreqTypeStaticData,
    status: notifyFreqTypeStaticDataStatus,
    refetch: refetchNotifyFreqTypeStaticData,
  } = useQuery(["reports/settings/getStaticData", notifyEnabled], {
    queryFn: getStaticData,
    staleTime: 600000,
    cacheTime: 600000,
    enabled: notifyEnabled ? true : false,
  });

  // Calculate the overall status based on static data and notification enabled status
  const status: AJAXSTATUS = useMemo(() => {
    if (notifyEnabled && notifyEnabledStatus === "success") {
      if (notifyFreqTypeStaticDataStatus === "success") {
        return "fulfilled";
      } else if (notifyFreqTypeStaticDataStatus === "error") {
        return "rejected";
      } else {
        return "pending";
      }
    } else if (notifyEnabledStatus === "success") {
      return "fulfilled";
    } else if (notifyEnabledStatus === "error") {
      return "rejected";
    } else {
      return "pending";
    }
  }, [notifyFreqTypeStaticDataStatus, notifyEnabledStatus, notifyEnabled]);

  // Calculate the combined static settings data based on the final status and fetched data
  const settingsStaticData = useMemo(() => {
    if (status === "fulfilled") {
      return {
        freqSettings: (notifyFreqTypeStaticData ?? [])[0],
        timeZones: (notifyFreqTypeStaticData ?? [])[1],
        notifyEnabled: notifyEnabled ? true : false,
      };
    } else {
      return null;
    }
  }, [notifyFreqTypeStaticData, notifyEnabled, status]);

  // Return the final object containing static settings data and the current status
  return {
    settingsStaticData,
    status,
    refetchNotifyFreqTypeStaticData,
    refetchNotifyEnabled,
  };
}

export default useStaticSettingsData;
