if (!String.prototype.replaceAll) {
  String.prototype.replaceAll = function (
    search: string | RegExp,
    replacement: string | ((substring: string, ...args: any[]) => string)
  ): string {
    // If a regex pattern
    if (search instanceof RegExp) {
      return this.replace(search, replacement as string);
    }

    // If a string
    return this.replace(new RegExp(search, "g"), replacement as string);
  };
}

const DateNative = window.Date;
(window as any).Date = null;
class TheDate extends DateNative {
  constructor(...args: any[]) {
    const arg = args[0];
    if (
      typeof arg === "string" &&
      arg[4] === "-" &&
      arg[7] === "-" &&
      arg[10] === " " &&
      arg[13] === ":" &&
      arg[16] === ":"
    ) {
      args[0] = arg.replace(" ", "T");
    }
    super(...(args as []));
  }
}
(window as any).Date = TheDate;

if (window.location.hostname === "localhost") {
  const sharedData = new URLSearchParams(window.location.search);
  if (sharedData.get("logout")) {
    window.localStorage.removeItem("loginData");
  }
}

export default {};
