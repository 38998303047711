import styles from "./TicketLimiter.module.scss";

interface Props {
  isMoreData?: boolean;
}

/**
 * Component for showing 30 days Limit Message UI and blur effect
 */
const TicketLimiter = ({ isMoreData }: Props) => {
  return (
    <div id="limitTickets" className={`${styles.limitWrapper}`}>
      <div className={`${styles.limitBox}`}>
        {/* Go back UI */}
        {isMoreData ? (
          <div>
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17.428"
                height="15.785"
                viewBox="0 0 17.428 15.785"
              >
                <g
                  id="Group_2373"
                  data-name="Group 2373"
                  transform="translate(0.5 0.5)"
                >
                  <path
                    id="Icon_feather-filter"
                    data-name="Icon feather-filter"
                    d="M19.428,4.5H3l6.571,7.77v5.372l3.286,1.643V12.27Z"
                    transform="translate(-3 -4.5)"
                    fill="none"
                    stroke="#0b68bb"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                  />
                </g>
              </svg>
            </span>
            <p className={`${styles.moreFilter}`}>More Filters and Data</p>
            <span className={`mb-3 ${styles.subText}`}>
              Please go to the previous page to find tickets that came in within
              the last 30 days.
            </span>{" "}
          </div>
        ) : (
          ""
        )}

        {/* Use filters Message */}
        <div className={`mt-1 ${styles.limitMsg}`}>
          <p className={`mb-0 ${styles.limitText}`}>
            Looking for data beyond 30 days? Please use filters.{" "}
            <span className={`${styles.filterText}`}> Change Filters</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default TicketLimiter;
