import { MouseEventHandler, useCallback, useEffect, useState } from "react";
import styles from "./InVoiceTable.module.scss";
import InVoiceRow from "./Children/InVoiceRow/InVoiceRow";
import { useInvoiceList } from "../../hooks/useInvoiceList";
import InfiniteScroll from "src/components/InfiniteScroll";
import Loader from "src/components/Loader";
import { Spinner } from "react-bootstrap";

/**
 * Component for rendering Invoice Table
 */
const InVoiceTable = () => {
  // State for toggling invoice list
  const [show, setShow] = useState<boolean>(false);
  // State for showing load more button in infinite scroll
  const [showLoadMore, setShowLoadMore] = useState(false);

  const {
    invoices,
    invoicesIds,
    fetchNextPage,
    hasNextPage,
    errorMessage,
    isLoading,
    status,
  } = useInvoiceList();

  /**
   * Callback for toggling the List
   */
  const toggleList: MouseEventHandler<HTMLDivElement> = useCallback((e) => {
    e.stopPropagation();
    setShow((val) => !val);
  }, []);

  /**
   * Handler for fetching Next invoice Page
   * Also handles load more button state
   */
  const fetchNextPageHandler = useCallback(() => {
    fetchNextPage();
    setShowLoadMore(false);
  }, [fetchNextPage]);

  /**
   * Effect for changing load more button state
   */
  useEffect(() => {
    if (status === "success") {
      setShowLoadMore(true);
    }
  }, [status]);

  return (
    <div className={`w-100 ${styles.tableWrapper}`}>
      {/* Invoice List Header */}
      <div
        className={`d-flex align-items-center justify-content-between cursor-pointer ${styles.toggleWrapper}`}
        onClick={toggleList}
      >
        <h4 className={`${show ? styles.activeHead : styles.invoiceHead}`}>
          See Invoices
        </h4>
        <span className={`${show ? styles.activeArrow : styles.arrow}`}>
          {show ? (
            <span className={`${styles.arrows}`}>
              <i className="fw-bold fa-solid fa-chevron-up"></i>
            </span>
          ) : (
            <span className={`${styles.arrows}`}>
              <i className=" fw-bold fa-solid fa-chevron-down"></i>
            </span>
          )}
        </span>
      </div>
      {show && (
        <div className={`${styles.tableWrapper}`}>
          {/* Heading Row */}
          <div className={`mb-2 ${styles.tableHead}`}>
            <div className={`${styles.dateWrapper}`}>
              <span className={`${styles.listItem}`}>Date paid</span>
            </div>
            <div className={`${styles.amountWrapper}`}>
              <span className={`${styles.listItem}`}>Amount Paid</span>
            </div>
            <div className={`${styles.statusWrapper}`}>
              <span className={`${styles.listItem}`}>Status</span>
            </div>
            <div className={`${styles.invoiceWrapper}`}>
              <span className={`${styles.listItem}`}>View Invoice</span>
            </div>
            <div className={`${styles.downloadWrapper}`}>
              <span className={`${styles.listItem}`}>Download Invoice</span>
            </div>
            {/* More Option - Hidden for now */}
            {/* <div className={`${styles.optionWrapper}`}>
              <span className={`${styles.listItem}`}>More Options</span>
            </div> */}
          </div>

          {/* Invoice Rows */}
          <div>
            {isLoading && invoicesIds.length === 0 ? (
              <>
                <Loader />
              </>
            ) : (
              <InfiniteScroll
                loadMore={fetchNextPageHandler}
                hasMore={hasNextPage}
                initialDataLoaded={true}
                className={`${styles.invoiceList}`}
                loader={
                  <>
                    {showLoadMore ? (
                      // Rendering the load button conditionally.
                      <div
                        className={`${styles.loaderText}`}
                        onClick={fetchNextPageHandler}
                      >
                        <span>Load More</span>
                        <i className="ms-1 fa-solid fa-rotate-right"></i>
                      </div>
                    ) : (
                      // Rendering the load text conditionally.
                      <div className={`${styles.loadingText}`}>
                        <span>Loading...</span>
                        <div>
                          <Spinner
                            className={`ms-1 ${styles.spinner}`}
                            size="sm"
                            animation="border"
                          />
                        </div>
                      </div>
                    )}
                  </>
                }
              >
                {errorMessage ? (
                  <span
                    className={`d-flex align-items-center justify-content-center mt-3 ${styles.errorMessage}`}
                  >
                    {errorMessage}
                  </span>
                ) : (
                  invoicesIds.map((invoiceId) => {
                    const invoice = invoices[invoiceId];

                    return (
                      <InVoiceRow
                        key={invoiceId}
                        invoice={invoice}
                      />
                    );
                  })
                )}
              </InfiniteScroll>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default InVoiceTable;
