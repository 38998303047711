import { axiosJSON } from "src/globals/axiosEndPoints";

export interface GetChatSettingDataParams{
  integrationId:number|string;
  customization?:boolean;
  availability?:boolean;
  knowledgeBased?:boolean;
  chatSetting?:boolean;
}

export async function getChatSettingData(params: GetChatSettingDataParams) {
  const { data } = await axiosJSON.post(
    `/api/chatSetting/getChatSettingData`,
    params
  );
  if (data.error === true) {
    throw new Error(data.message as string);
  }
  return data.data;
}

export interface GetAvailableDayOptionsParam{
  integrationId:number|string;
}


export interface IAvailableDayOption{
  name: string;
  key: string;
}

export interface IAvailableDayOptionList {
  availableDayOptions : {[id: number | string] : IAvailableDayOption},
  availableDayOptionKeys : Array<number | string>,
}


export async function getAvailableDayOptions(params: GetAvailableDayOptionsParam) {
  const { data } = await axiosJSON.post(
    `/api/chatSetting/getAgentAvailableDayOptions`,
    params
  );
  if (data.error === true) {
    throw new Error(data.message as string);
  }

  const availableDayOptionKeys: any = [];
  const availableDayOptions: any = {};
  data.data.forEach((option: IAvailableDayOption ) => {
    availableDayOptions[option.key] = option;
    availableDayOptionKeys.push(option.key);
  });
  return { availableDayOptions, availableDayOptionKeys} as IAvailableDayOptionList;
}