import { Module } from "module";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import AddBulkRemark from "src/components/BulkActionComponents/AddBulkRemark/AddBulkRemark";
import AddTags from "src/components/BulkActionComponents/AddTags/AddTags";
import AssignTo from "src/components/BulkActionComponents/AssignTo/AssignTo";
import ChangeTicketStatus from "src/components/BulkActionComponents/ChangeTicketStatus/ChangeTicketStatus";
import SendBulkMessage from "src/components/BulkActionComponents/SendBulkMessage/SendBulkMessage";
import {
  areAllTicketsSelectedSelector,
  removeAllTickets,
  selectAllTickets,
  setBulkActionFilters,
} from "src/store/slices/tickets/tickets.slice";
import { getActiveListName } from "src/store/slices/ticketSidebar/ticketSidebar.selectors";
import { useAppDispatch, useAppSelector } from "src/store/store";
import Style from "../BulkActionSection/BulkAction.module.scss";
import BulkSelectModel from "./childern/BulkSelectModel";

let sideBarWidthG = "4.5rem";

export let setSideBarWidthExp = (width: string) => {
  sideBarWidthG = width;
};
const BulkActionSection = (props: any) => {
  const [sideBarWidth, setSideBarWidth] = useState(sideBarWidthG);
  const dispatch = useAppDispatch();
  const currentUserData = useAppSelector(
    (state) => state.globals.currentUserData
  );

  const { selectedAllTickets } = useAppSelector(
    (state) => state.ticket.bulkActionFilters
  );
  const areAllTicketsSelected = useAppSelector(areAllTicketsSelectedSelector);

  const { totalTicketsCount, currentView } = useAppSelector(
    (state) => state.ticket
  );

  //getting current view name, this can be "All Tickets", "My Ticket (open)"...
  const activeListName = useAppSelector(getActiveListName);

  useEffect(() => {
    setSideBarWidthExp = (width: string) => {
      sideBarWidthG = width;
      setSideBarWidth(width);
    };
  }, []);

  // send dispatch of clearing all ticket ids selected
  const handleOnClickCross = (e: any) => {
    e.preventDefault();
    dispatch(removeAllTickets());
    dispatch(setBulkActionFilters({ selectedAllTickets: false })); //resetting the select all filter on clicking of close button
  };

  //function to toggle select all filter
  const handleSelectAll = (selectAll: boolean) => {
    if (selectAll) {
      //select all has been opted
      dispatch(removeAllTickets()); //removing selectedIds and setting select all filter to true
      dispatch(setBulkActionFilters({ selectedAllTickets: true }));
    } else {
      //select all is false but first 10 is selected, so resetting the select all and selecting first 10 in the view
      dispatch(setBulkActionFilters({ selectedAllTickets: false }));
      dispatch(selectAllTickets());
    }
  };

  return (
    <>
      <div
        className={`${Style.actionSection}`}
        style={{ left: sideBarWidth }}
        id="bulkActionSection"
      >
        <div className={`${Style.actionBox}`} id="bulkActionBox">
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-wrap align-items-center">
              <p className={`mb-2 me-2 ${Style.bulkPara}`}>
                Bulk action {"("}
                {currentView === "customer-view" ? (
                  <span className="">
                    {selectedAllTickets === true
                      ? totalTicketsCount
                      : props.totalTicketsSelected}{" "}
                    {selectedAllTickets === true
                      ? totalTicketsCount <= 1
                        ? "customer selected"
                        : "customers selected"
                      : props.totalTicketsSelected <= 1
                      ? "customer selected"
                      : "customers selected"}
                  </span>
                ) : currentView === "response-view" ? (
                  <span className="">
                    {selectedAllTickets === true
                      ? totalTicketsCount
                      : props.totalTicketsSelected}{" "}
                    {selectedAllTickets === true
                      ? totalTicketsCount <= 1
                        ? "draft selected"
                        : "drafts selected"
                      : props.totalTicketsSelected <= 1
                      ? "draft selected"
                      : "drafts selected"}
                  </span>
                ) : (
                  <span className="">
                    {selectedAllTickets === true
                      ? totalTicketsCount
                      : props.totalTicketsSelected}{" "}
                    {selectedAllTickets === true
                      ? totalTicketsCount <= 1
                        ? "ticket selected"
                        : "tickets selected"
                      : props.totalTicketsSelected <= 1
                      ? "ticket selected"
                      : "tickets selected"}
                  </span>
                )}
                {")"}
              </p>
              <div className="d-flex justify-content-start flex-wrap mb-2">
                <div className={`me-2`}>
                  <span className="d-block d-md-flex aling-items-center">
                    <input
                      type="radio"
                      id="BA-select-tickets-all"
                      checked={selectedAllTickets}
                      className="ms-0 me-1 mx-md-1 cursor-pointer"
                      onChange={() => handleSelectAll(true)}
                    />
                    <label
                      htmlFor={`BA-select-tickets-all`}
                      className={` ${Style.labelText} ms-1`}
                    >
                      {currentView === "customer-view"
                        ? `Select all of the last tickets from Customers in "${activeListName}" view`
                        : currentView === "response-view"
                        ? `Select all ${totalTicketsCount} ${
                            totalTicketsCount <= 1 ? "draft" : "drafts"
                          } in "${activeListName}" view`
                        : `Select all ${totalTicketsCount} ${
                            totalTicketsCount <= 1 ? "ticket" : "tickets"
                          } in "${activeListName}" view`}
                    </label>
                  </span>
                </div>
                <div className="">
                  <span className="d-block d-md-flex aling-items-center">
                    <input
                      type="radio"
                      id="BA-select-tickets-selected"
                      checked={areAllTicketsSelected}
                      className="ms-0 me-1 mx-md-1 cursor-pointer"
                      onChange={() => handleSelectAll(false)}
                    />
                    <label
                      htmlFor={`BA-select-tickets-selected`}
                      className={` ${Style.labelText} ms-1`}
                    >
                      {currentView === "customer-view"
                        ? `
                Only last tickets of ${
                  totalTicketsCount > 10 ? 10 : totalTicketsCount
                } customers visible
                `
                        : currentView === "response-view"
                        ? `Only the ${
                            totalTicketsCount > 10 ? 10 : totalTicketsCount
                          } ${
                            totalTicketsCount <= 1 ? "draft" : "drafts"
                          } currently visible`
                        : `Only the ${
                            totalTicketsCount > 10 ? 10 : totalTicketsCount
                          } ${
                            totalTicketsCount <= 1 ? "ticket" : "tickets"
                          } currently visible`}
                    </label>
                  </span>
                </div>
              </div>
            </div>

            <span className={`${Style.actionClose} mt-0`} id="bulkActionClose">
              {" "}
              <i
                className="fa fa-solid fa-xmark"
                onClick={handleOnClickCross}
              />
            </span>
          </div>

          <div
            className={`${Style.actionBtnsContainer}`}
            id="bulkActionContainer"
          >
            {currentUserData &&
              currentUserData.scopes &&
              currentUserData.scopes.includes("reassign_ticket") && (
                <AssignTo />
              )}
            {currentUserData &&
              currentUserData.scopes &&
              currentUserData.scopes.includes("add_tags") && <AddTags />}
            {/* <AddBulkRemark /> */}
            {currentUserData &&
              currentUserData.scopes &&
              currentUserData.scopes.includes("change_status") && (
                <ChangeTicketStatus />
              )}
            {currentUserData &&
              currentUserData.scopes &&
              currentUserData.scopes.includes("send_bulk_message") && (
                <SendBulkMessage />
              )}
          </div>
        </div>
      </div>
    </>
  );
};
export default BulkActionSection;
