import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ITicketBtn } from "src/containers/SideBar/children/SubSB/SubSB";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import ticketSidebarReducers from "./ticketSidebar.reducers";
import ticketSidebarThunks from "./ticketSidebar.thunks";
import { createSelector } from "@reduxjs/toolkit";
import {
  getActiveListName,
  getActiveTicketPageCount,
  getActiveChannelId,
  getActiveDraftStatusId,
  getActiveTicketStatusId,
  getActiveListIndex,
  defaultDDFilters,
} from "./ticketSidebar.selectors";
import ticketSidebarExtraReducers from "./ticketSidebar.extraReducers";
import { getSelectedColIdIndexForAllViews } from "../tickets/tickets.selectors";

export interface UsersViewData {
  viewId: number;
  viewName: string;
  options: any;
  viewsTicketCount: number;
  ticketAppliedData?: string;
}

export interface ITicketSideBarState {
  ticketBtnData: Array<ITicketBtn>;
  selectedBtn: string;
  mainSideBarTotalTicketCount: number;
  usersViewData: { [viewId: number | string]: UsersViewData };
  usersViewList: Array<number>;
  usersViewListFlag: number;
  usersViewLimit: number;
  usersViewTotal: number;
  usersViewAjaxStatus: "pending" | "fulfilled" | "rejected";
  updateSidebarTicketsCountAjaxStatus: "pending" | "fulfilled" | "rejected";
  ajaxStatus: "pending" | "fulfilled" | "rejected";
  unreadMentionsCount: number;
  mentionsTicketAppliedData?: string;
  aiClosedTicketCount: number;
  aiOpenTicketCount: number;
  selectedAiBtn: "Open" | "Closed" | null;
}

export const initialState: ITicketSideBarState = {
  ticketBtnData: [],
  selectedBtn: "",
  mainSideBarTotalTicketCount: 0,
  usersViewData: {},
  usersViewList: [],
  usersViewListFlag: 0,
  usersViewLimit: 5,
  usersViewTotal: 0,
  usersViewAjaxStatus: "pending",
  updateSidebarTicketsCountAjaxStatus: "pending",
  ajaxStatus: "pending",
  unreadMentionsCount: 0,
  aiClosedTicketCount: 0,
  aiOpenTicketCount: 0,
  selectedAiBtn: null,
};

export const fetchSideBarData = createAsyncThunk(
  "ticketSidebar/fetchSideBarData",
  ticketSidebarThunks.fetchSideBarData
);
export const fetchAddedViewSideBarData = createAsyncThunk(
  "ticketSidebar/fetchAddedViewSideBarData",
  ticketSidebarThunks.fetchAddedViewSideBarDataThunk
);
export const fetchSideBarDataUpdate = createAsyncThunk(
  "ticketSidebar/fetchSideBarDataUpdate",
  ticketSidebarThunks.fetchSideBarData
);
export const fetchSideBarUsersViews = createAsyncThunk(
  "ticketSidebar/fetchSideBarUsersViews",
  ticketSidebarThunks.fetchSideBarUsersViews
);

/**
 * Thunk to refresh sidebar user views
 */
export const refreshSideBarUsersViews = createAsyncThunk(
  "ticketSidebar/refreshSideBarUsersViews",
  ticketSidebarThunks.refreshSideBarUsersViews
);

export const fetchMultipleSidebarData = createAsyncThunk(
  "ticketSidebar/fetchMultipleSidebarDataThunk",
  ticketSidebarThunks.fetchMultipleSidebarDataThunk
);

export const refetchSideBarData = createAsyncThunk(
  "ticketSidebar/refetchSideBarDataThunk",
  ticketSidebarThunks.refetchSideBarDataThunk
);

const ticketSidebarSlice = createSlice({
  name: "ticketSidebar",
  initialState: initialState,
  reducers: ticketSidebarReducers,
  extraReducers: (builder) => {
    builder.addCase(fetchSideBarData.pending, (state: ITicketSideBarState) => {
      state.ajaxStatus = "pending";
    });
    builder.addCase(
      fetchSideBarData.fulfilled,
      ticketSidebarExtraReducers.fetchSideBarDataFulfilled
    );
    builder.addCase(fetchSideBarData.rejected, (state: ITicketSideBarState) => {
      state.ajaxStatus = "rejected";
      try {
        if (!window.location.pathname.includes("login")) {
          pushTheToast({
            type: "danger",
            text: "Error fetching sidebar data!",
            position: "top-right",
          });
        }
      } catch {}
    });
    builder.addCase(
      refetchSideBarData.fulfilled,
      ticketSidebarExtraReducers.refetchSideBarDataFulfilled
    );
    builder.addCase(
      fetchSideBarUsersViews.pending,
      (state: ITicketSideBarState) => {
        state.usersViewAjaxStatus = "pending";
      }
    );
    builder.addCase(
      fetchSideBarUsersViews.fulfilled,
      ticketSidebarExtraReducers.fetchSideBarUsersViewsFulfilled
    );
    builder.addCase(
      fetchSideBarUsersViews.rejected,
      (state: ITicketSideBarState) => {
        state.usersViewAjaxStatus = "rejected";
        try {
          pushTheToast({
            type: "danger",
            text: "Error fetching sidebar users view!",
            position: "top-right",
          });
        } catch {}
      }
    );
    // Builder functions for Refreshing Sidebar User Views
    builder.addCase(
      refreshSideBarUsersViews.pending,
      (state: ITicketSideBarState) => {
        state.usersViewAjaxStatus = "pending";
      }
    );
    builder.addCase(
      refreshSideBarUsersViews.fulfilled,
      ticketSidebarExtraReducers.refreshSideBarUsersViewsFulfilled
    );
    builder.addCase(
      refreshSideBarUsersViews.rejected,
      (state: ITicketSideBarState) => {
        state.usersViewAjaxStatus = "rejected";
        try {
          pushTheToast({
            type: "danger",
            text: "Error fetching sidebar users view!",
            position: "top-right",
          });
        } catch {}
      }
    );

    builder.addCase(
      fetchSideBarDataUpdate.fulfilled,
      ticketSidebarExtraReducers.fetchSideBarDataFulfilled
    );

    builder.addCase(
      fetchMultipleSidebarData.fulfilled,
      ticketSidebarExtraReducers.fetchMultipleSidebarDataFulfilled
    );

    builder.addCase(
      fetchAddedViewSideBarData.fulfilled,
      ticketSidebarExtraReducers.fetchAddedViewSideBarDataFulfilled
    );
  },
});

export const {
  setDDSelectedIndex,
  selectDDIndex,
  saveTicketAppliedData,
  setSelectedBtn,
  setSelectedAiBtn,
  resetTicketSideBarAllData,
  setSidebarData,
  saveTicketAppliedViewCols,
  deleteUserView,
} = ticketSidebarSlice.actions;

export const defaultDDFiltersSelector = createSelector(
  [defaultDDFilters],
  (data) => data
);

export const ticketSidebarActiveSelector = createSelector(
  [
    getActiveListName,
    getActiveTicketPageCount,
    getActiveChannelId,
    getActiveDraftStatusId,
    getActiveTicketStatusId,
    getActiveListIndex,
    getSelectedColIdIndexForAllViews,
  ],
  (
    activeListName,
    activeTicketPageCount,
    activeChannelId,
    activeDraftStatusId,
    activeTicketStatusId,
    activeListIndex,
    selectedColIdIndexForAllViews
  ) => {
    return {
      activeListName,
      activeTicketPageCount,
      activeChannelId,
      activeDraftStatusId,
      activeTicketStatusId,
      activeListIndex,
      selectedColIdIndexForAllViews,
    };
  }
);

export const ticketSidebarActiveFilters = createSelector(
  [getActiveChannelId, getActiveDraftStatusId, getActiveTicketStatusId],
  (activeChannelId, activeDraftStatusId, activeTicketStatusId) => {
    return {
      activeChannelId,
      activeDraftStatusId,
      activeTicketStatusId,
    } as {
      activeChannelId: number[];
      activeDraftStatusId: number[];
      activeTicketStatusId: number[];
    };
  }
);
export default ticketSidebarSlice.reducer;
