import { axiosFormData } from "src/globals/axiosEndPoints";

export interface UpdateBrandProfilePayload {
  brandId: number|string;
  profileImage: File;
}

export async function updateBrandProfileImage(
  payload: UpdateBrandProfilePayload
) {
  const fData = new FormData();
  fData.append("brandId", payload.brandId+"");
  fData.append("profileImage", payload.profileImage);
  const { data: res } = await axiosFormData.post(
    "/api/brand/updateProfileImage",
    fData
  );

  if (res.error === true) {
    throw new Error("profileImageFailed");
  }

  return res.data;
}
