import { ICustomizationData, ICustomizationSetting, initialState } from "./customizationSettings.slice";

const resetCustomizationSettings = (state: ICustomizationSetting) => {
  state = { ...initialState };
  return state;
};

const setCustomizationData = (state: ICustomizationSetting, 
  { payload }: { payload:  ICustomizationData}
  ) => {
  state.customizationData = payload;
  return state;
};

export default { resetCustomizationSettings,setCustomizationData };
