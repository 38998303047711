import { Spinner } from "react-bootstrap";
import { Swiper as ISwiper } from "swiper";
import styles from "./NextStepButton.module.scss";

function NextStepButton(props: {
  isVisible: boolean;
  swiper: ISwiper | null;
  hide: boolean;
  handleAddAutomation: () => void;
  isLoading: boolean;
}) {
  return (
    <>
      {props.hide === true ? (
        <button
          className={`${styles.nextStepButton} `}
          disabled={props.isLoading}
          onClick={() => props.handleAddAutomation()}
        >
          <div className="">
            <span className={`${styles.goBackText}`}>
              {props.isLoading === true ? (
                <Spinner
                  className="my-auto mx-1"
                  animation="border"
                  color="white"
                  size="sm"
                />
              ) : (
                "Start Automating"
              )}
            </span>
          </div>
        </button>
      ) : (
        <button
          className={`${styles.nextStepButton} ${styles.small} px--md-3`}
          disabled={!props.isVisible}
          onClick={() => props.swiper?.slideNext()}
        >
          <div className="">
            <span className={`${styles.goBackText}`}>Next Step</span>
          </div>
        </button>
      )}
    </>
  );
}

export default NextStepButton;
