/**
 * This file defines a custom hook responsible for handling form elements,
 * handling CRUD operations form elements, implementing drag-and-drop update,
 * and managing the process of saving and discarding changes in the bot configuration form.
 *
 * @author @yuvaraj-busibud
 * @author @navjyot-busibud
 */
import {
  UseMutateFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { CreateBotProfileResponse } from "src/services/Bot/BotProfiles/createBotProfile.service";
import { BotProfile } from "src/services/Bot/BotProfiles/getAllBotProfiles.service";
import { UpdateBotProfileParams } from "src/services/Bot/BotProfiles/updateBotProfile.service";
import { ConfigureSteps } from "../../../useConfigureSteps";
interface Props {
  refetchGetBot: () => void;
  onSaveComplete: () => void;
  updateBotSettings: UseMutateFunction<
    CreateBotProfileResponse,
    unknown,
    UpdateBotProfileParams,
    unknown
  >;
  configureSteps: ConfigureSteps;
}
const useAdvancedBotConfig = ({
  onSaveComplete,
  refetchGetBot,
  updateBotSettings,
  configureSteps,
}: Props) => {
  const { state, dispatch } = configureSteps;

  const queryClient = useQueryClient();

  // State variables to manage UI states
  const [showError, setShowError] = useState(false);
  const [showSaved, setShowSaved] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  // State variables to manage advanced bot configuration settings
  const [isCustomTemperatureEnabled, setIsCustomTemperatureEnabled] =
    useState<
      BotProfile["configureAdvancedBotSettingsModel"]["isCustomTemperatureEnabled"]
    >(false);
  const [customTemperature, setCustomTemperature] =
    useState<
      BotProfile["configureAdvancedBotSettingsModel"]["customTemperature"]
    >(0);
  const [optimismLevelForBotLanguage, setOptimismLevelForBotLanguage] =
    useState<
      BotProfile["configureAdvancedBotSettingsModel"]["optimismLevelForBotLanguage"]
    >("neutral");
  const [energyLevelForBotResponse, setEnergyLevelForBotResponse] =
    useState<
      BotProfile["configureAdvancedBotSettingsModel"]["energyLevelForBotResponse"]
    >("balanced");
  const [isToneAdjustmentForBotResponse, setIsToneAdjustmentForBotResponse] =
    useState<
      BotProfile["configureAdvancedBotSettingsModel"]["isToneAdjustmentForBotResponse"]
    >(false);
  const [toneForBotResponse, setToneForBotResponse] =
    useState<
      BotProfile["configureAdvancedBotSettingsModel"]["toneForBotResponse"]
    >("casual");

  const [customToneInstructionEnabled, setCustomToneInstructionEnabled] =
    useState<
      BotProfile["configureAdvancedBotSettingsModel"]["customToneInstructionEnabled"]
    >(false);
  const [customToneInstructionMessage, setCustomToneInstructionMessage] =
    useState<
      BotProfile["configureAdvancedBotSettingsModel"]["customToneInstructionMessage"]
    >();
  const [
    contentRelatedInstructionEnabled,
    setContentRelatedInstructionEnabled,
  ] =
    useState<
      BotProfile["configureAdvancedBotSettingsModel"]["contentRelatedInstructionEnabled"]
    >(false);
  const [signOffInstruction, setSignOffInstruction] =
    useState<
      BotProfile["configureAdvancedBotSettingsModel"]["signOffInstruction"]
    >("");
  const [topicAvoidance, setTopicAvoidance] =
    useState<
      BotProfile["configureAdvancedBotSettingsModel"]["topicAvoidance"]
    >();
  const [personalizeMessage, setPersonalizeMessage] =
    useState<
      BotProfile["configureAdvancedBotSettingsModel"]["personalizeMessage"]
    >("");

  // Effect to initialize state variables from the bot profile in the state
  useEffect(() => {
    if (state.botProfile.configureAdvancedBotSettingsModel) {
      setIsCustomTemperatureEnabled(
        state.botProfile.configureAdvancedBotSettingsModel
          .isCustomTemperatureEnabled,
      );
      setCustomTemperature(
        state.botProfile.configureAdvancedBotSettingsModel.customTemperature,
      );
      setOptimismLevelForBotLanguage(
        state.botProfile.configureAdvancedBotSettingsModel
          .optimismLevelForBotLanguage,
      );
      setEnergyLevelForBotResponse(
        state.botProfile.configureAdvancedBotSettingsModel
          .energyLevelForBotResponse,
      );
      setIsToneAdjustmentForBotResponse(
        state.botProfile.configureAdvancedBotSettingsModel
          .isToneAdjustmentForBotResponse,
      );
      setToneForBotResponse(
        state.botProfile.configureAdvancedBotSettingsModel.toneForBotResponse,
      );
      setCustomToneInstructionEnabled(
        state.botProfile.configureAdvancedBotSettingsModel
          .customToneInstructionEnabled,
      );
      setCustomToneInstructionMessage(
        state.botProfile.configureAdvancedBotSettingsModel
          .customToneInstructionMessage,
      );
      setContentRelatedInstructionEnabled(
        state.botProfile.configureAdvancedBotSettingsModel
          .contentRelatedInstructionEnabled,
      );
      setSignOffInstruction(
        state.botProfile.configureAdvancedBotSettingsModel.signOffInstruction,
      );
      setTopicAvoidance(
        state.botProfile.configureAdvancedBotSettingsModel.topicAvoidance,
      );
      setPersonalizeMessage(
        state.botProfile.configureAdvancedBotSettingsModel.personalizeMessage,
      );
    }
  }, [state.botProfile.configureAdvancedBotSettingsModel]);

  // Callback to handle saving the advanced bot configuration settings
  const handleSave = useCallback(() => {
    // Validate required fields
    if (
      optimismLevelForBotLanguage === null ||
      energyLevelForBotResponse === null ||
      (isToneAdjustmentForBotResponse && toneForBotResponse === null) ||
      (customToneInstructionEnabled && !customToneInstructionMessage?.trim())
    ) {
      setShowError(true);
      return;
    }
    setShowError(false);
    setShowLoader(true);

    // Prepare payload for updating bot settings
    const settingPayload: UpdateBotProfileParams["configureAdvancedBotSettingsModel"] =
      {
        isCustomTemperatureEnabled: isCustomTemperatureEnabled,
        customTemperature: customTemperature,
        optimismLevelForBotLanguage: optimismLevelForBotLanguage,
        energyLevelForBotResponse: energyLevelForBotResponse,
        isToneAdjustmentForBotResponse: isToneAdjustmentForBotResponse,
        toneForBotResponse: toneForBotResponse,
        customToneInstructionEnabled: customToneInstructionEnabled,
        customToneInstructionMessage: customToneInstructionMessage,
        contentRelatedInstructionEnabled: contentRelatedInstructionEnabled,
        signOffInstruction: signOffInstruction,
        topicAvoidance: topicAvoidance,
        personalizeMessage: personalizeMessage,
      };

    // Updates bot settings via API call
    updateBotSettings(
      {
        id: state.botProfile.id,
        configureAdvancedBotSettingsModel: settingPayload,
      },
      {
        onSuccess: (data, variables, context) => {
          setShowLoader(false);

          // Update state with the new settings and mark as completed
          dispatch({
            type: "UPDATE_ANY_DETAILS",
            payload: {
              key: "configureAdvancedBotSettingsModel",
              value: {
                ...variables.configureAdvancedBotSettingsModel,
                isCompleted: true,
              },
            },
          });
          setShowSaved(true);
          onSaveComplete();

          // Refetch bot data if the update was successful
          if (data.statusCode && data.statusCode !== undefined) {
            refetchGetBot();
            dispatch({
              type: "UPDATE_STATUS",
              payload: "draft",
            });
          }
        },
        onError: () => {
          setShowError(true);
          setShowLoader(false);
        },
      },
    );
  }, [
    isCustomTemperatureEnabled,
    customTemperature,
    optimismLevelForBotLanguage,
    energyLevelForBotResponse,
    isToneAdjustmentForBotResponse,
    toneForBotResponse,
    customToneInstructionEnabled,
    customToneInstructionMessage,
    contentRelatedInstructionEnabled,
    signOffInstruction,
    topicAvoidance,
    personalizeMessage,
    updateBotSettings,
  ]);

  return {
    isCustomTemperatureEnabled,
    customTemperature,
    optimismLevelForBotLanguage,
    energyLevelForBotResponse,
    isToneAdjustmentForBotResponse,
    toneForBotResponse,
    setIsCustomTemperatureEnabled,
    setCustomTemperature,
    setOptimismLevelForBotLanguage,
    setEnergyLevelForBotResponse,
    setIsToneAdjustmentForBotResponse,
    setToneForBotResponse,
    handleSave,
    showError,
    setShowError,
    showLoader,
    showSaved,
    setShowSaved,
    customToneInstructionEnabled,
    setCustomToneInstructionEnabled,
    customToneInstructionMessage,
    setCustomToneInstructionMessage,
    contentRelatedInstructionEnabled,
    setContentRelatedInstructionEnabled,
    signOffInstruction,
    setSignOffInstruction,
    topicAvoidance,
    setTopicAvoidance,
    personalizeMessage,
    setPersonalizeMessage,
  };
};

export default useAdvancedBotConfig;
