import { useContext, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import styles from "../CustomerEdit/CustomerEdit.module.scss";
import flag1 from "src/assets/images/flag1.jpg";
import flag2 from "src/assets/images/flag2.jpg";
import flag3 from "src/assets/images/flag3.jpg";
import flag4 from "src/assets/images/flag4.jpg";
import whatsappImg from "src/assets/images/Whastapp.svg";
import AddMore from "../AddMore/AddMore";

import { useAppDispatch, useAppSelector } from "src/store/store";
import Select, {
  ActionMeta,
  components,
  GroupBase,
  InputProps,
  OptionProps,
  OptionsOrGroups,
  PropsValue,
  SingleValue,
  SingleValueProps,
} from "react-select";
import useInputFields, {
  InputFieldsContext,
} from "../CustomerEdit/useInputFields";
import {
  CountryItem,
  getCountryList,
} from "src/store/slices/globals/globals.slice";

import InttTelInput, {
  IntlTelInputParams,
} from "src/components/IntlTelInput/IntlTelInput";
import IntlTelInput from "src/components/IntlTelInput/IntlTelInput";

function Whatsapp(props: any) {
  const whatsapp = useAppSelector(
    (state) => state.editcustomerdetails.customer.contacts?.whatsapp,
  );

  // Removed the custom country list components as they are not being used anymore
  // const dispatch = useAppDispatch();

  // useEffect(() => {
  //   dispatch(getCountryList());
  // }, []);

  // const countryList = useAppSelector((state) => state.globals.countryList.data);

  // const { Option } = components;

  // const CustomOption = (props: any) => (
  //   <Option
  //     {...props}
  //     className="d-flex justify-content-evenly"
  //   >
  //     <div>{props.data.icon}</div>
  //     <span className="">{props.data.label}</span>
  //   </Option>
  // );

  // const CustomInput = (props: any) => {
  //   const selectedOption = props.selectProps.value;
  //   if (!props.isHidden) {
  //     return <>{selectedOption.icon}</>;
  //   }
  //   return <components.Input {...props} />;
  // };

  // const CustomSingleValue = (props: any) => {
  //   const selectedOption = props.selectProps.value;
  //   return (
  //     <components.SingleValue {...props}>
  //       {selectedOption.icon}
  //     </components.SingleValue>
  //   );
  // };

  // function getCountryCodeOption(
  //   field: any,
  // ):
  //   | PropsValue<{ value: string; label: JSX.Element; icon: JSX.Element }>
  //   | undefined {
  //   const countryItems = countryList?.filter((country: CountryItem) => {
  //     return country.dial_code === field.value.countryCode;
  //   });

  //   if (countryItems !== undefined && countryItems.length > 0) {
  //     return {
  //       value: countryItems[0].dial_code,
  //       label: <>{countryItems[0].code}</>,
  //       icon: (
  //         // <img
  //         //   src={`https://countryflagsapi.com/png/${countryItems[0].code}`}
  //         //   style={{ maxWidth: "20px", maxHeight: "20px" }}
  //         // />
  //         <span
  //           dangerouslySetInnerHTML={{
  //             __html: countryItems[0].flag_unicode
  //               .split(" ")
  //               .map((code) => code.substring(2))
  //               .map((code) => `&#x${code};`)
  //               .join(""),
  //           }}
  //         ></span>
  //         // <ReactCountryFlag countryCode={`${countryItems[0].code}`} />
  //         // <ReactCountryFlag countryCode="US" />
  //         // <p>{countryItems[0].flag_unicode}</p>
  //       ),
  //     };
  //   }
  // }

  const { updateField, setFieldsOfKey, fields, areDuplicateFields } =
    useContext(InputFieldsContext);

  useEffect(() => {
    setFieldsOfKey(whatsapp ?? [], "whatsapp");
  }, [whatsapp]);

  // const whatsappNumbers = fields.get("whatsapp");

  // const isAddButtonEnabled = whatsappNumbers?.length
  //   ? whatsappNumbers[whatsappNumbers.length - 1]?.isValid
  //     ? true
  //     : false
  //   : false;

  // Getting ids of duplicate numbers
  const { hasDuplicates, duplicateIds } =
    areDuplicateFields.duplicates["whatsapp"];

  return (
    <>
      {fields.get("whatsapp")?.map((field, idx) => {
        return (
          <>
            {idx === 0 ? (
              <label
                className={`d-flex justify-content-between align-items-center form-label ${styles.labelName} m-0`}
              >
                <div className={`d-flex align-items-center`}>
                  <div
                    className={`d-flex justify-content-center align-items-center ${styles.msgBg}`}
                  >
                    {/* <i
                      className={`fa-brands fa-whatsapp ${styles.whatsAppIcon}`}
                    ></i> */}
                    <img
                      src={whatsappImg}
                      alt=""
                      width={15}
                      height={15}
                    />
                  </div>{" "}
                  <div>
                    <span className={`ps-1`}>Whatsapp</span>
                  </div>
                </div>
              </label>
            ) : (
              <></>
            )}
            <div
              className="d-flex flex-column w-100"
              key={field.id}
            >
              <div className={`d-flex mt-2 w-100 `}>
                <IntlTelInput
                  phoneNumber={(field.value as any).phoneNumber}
                  countryCode={(field.value as any).countryCode}
                  disabled={true}
                  onChange={(params: IntlTelInputParams) => {
                    updateField(
                      field,
                      {
                        ...field,
                        value: {
                          countryCode: params.countryData.dialCode ?? "91",
                          phoneNumber: params.phoneNumber,
                        },
                        isValid: params.isValidNumber,
                      },
                      "whatsapp",
                    );
                  }}
                  isDuplicate={hasDuplicates && duplicateIds.includes(field.id)} //Adding isDuplicate to show red border if it is a duplicate
                >
                  {(params) => {
                    return (
                      <>
                        <small className={`${styles.errorMsg}`}>
                          {params.eventType !== "initial" && params.errorMessage
                            ? params.errorMessage
                            : hasDuplicates && duplicateIds.includes(field.id) //Adding error message if it is a duplicate number
                              ? `Duplicate whatsapp number not allowed`
                              : ""}
                        </small>
                      </>
                    );
                  }}
                </IntlTelInput>

                <input
                  data-lpignore="true"
                  name="countryCode"
                  hidden
                  value={(field.value as any).countryCode}
                ></input>
                <input
                  data-lpignore="true"
                  name="Whatsapp"
                  hidden
                  value={(field.value as any).phoneNumber}
                ></input>

                <div
                  className={`d-none ${styles.customCrossPosition} ${styles.setWidth}`}
                  onClick={() => {
                    // removeField(field, "whatsapp");
                  }}
                >
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip className={`${styles.toolTipCustom}`}>
                        Remove
                      </Tooltip>
                    }
                  >
                    {({ ref, ...triggerHandler }) => (
                      <div
                        {...triggerHandler}
                        ref={ref}
                      >
                        <div
                          className={`d-flex justify-content-center align-items-center ${styles.clearText}`}
                        >
                          <i className="fa-solid fa-xmark"></i>
                        </div>
                      </div>
                    )}
                  </OverlayTrigger>
                </div>
              </div>
            </div>
          </>
        );
      })}
      <span>
        {/* Jan 25 Commented it as we are not supporting multiple whatsapp numbers */}
        {/* <AddMore
          type="Number"
          isValid={isAddButtonEnabled}
          onClick={() => {
            if (!isAddButtonEnabled) {
              return;
            }
            addField(
              { value: { countryCode: "91", phoneNumber: "" } },
              "whatsapp"
            );
          }}
          isVisible={(fields.get("whatsapp")?.length ?? 0) > 0}
        /> */}
      </span>
    </>
  );
}

export default Whatsapp;
