export const MONTH_LIST = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const MONTH_LIST_SHORT = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const WEEK_LIST = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
export const WEEK_LIST_SHORT = [
  "Sun",
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
];

export const CREATED_DATE_OPTIONS_API_ROUTE = "/api/filters/createdDateOptions";
export const LAST_UPDATED_OPTIONS_API_ROUTE = "/api/filters/lastUpdatedOptions";
export const CLOSED_DATE_OPTIONS_API_ROUTE = "/api/filters/closedDateOptions";
export const CHANNEL_OPTIONS_API_ROUTE = "/api/filters/channelOptions";
export const BRAND_OPTIONS_API_ROUTE = "/api/filters/brandOptions";
export const AGENT_OPTIONS_API_ROUTE = "/api/filters/agentOptions";
export const TAGS_OPTIONS_API_ROUTE = "/api/filters/tagsOptions";
export const EMAILS_OPTIONS_API_ROUTE = "/api/filters/emailsOptions";
export const USER_TYPE_OPTIONS_API_ROUTE = "/api/filters/userTypeOptions";
export const LAST_MESSAGE_OPTIONS_API_ROUTE = "/api/filters/lastMessageOptions";
export const NOT_REPLIED_SINCE_OPTIONS_API_ROUTE =
  "/api/filters/notRepliedSinceOptions";
export const TICKET_STATUS_OPTIONS_API_ROUTE =
  "/api/filters/ticketStatusOptions";
export const DRAFT_STATUS_OPTIONS_API_ROUTE = "/api/filters/draftStatusOptions";
export const DRAFTED_BY_OPTIONS_API_ROUTE = "/api/filters/draftedByOptions";

export type AJAXSTATUS = "idle" | "pending" | "fulfilled" | "rejected";

export const listOfOperators = {
  within: ["is within", "is not within"],
  is: ["is", "is not"],
  include: ["include", "do not include"],
  areLessThan: ["are less than"],
  none: [],
  isThan: ["is", "is less than", "is more than"],
} as const;

export const IS_TEST_ACTIVE = process.env.REACT_APP_TEST_ACTIVE === "true";

export const POPPER_CONFIG = {
  modifiers: [
    {
      name: "computeStyles",
      options: {
        gpuAcceleration: false, // true by default
      },
    },
  ],
};

/**
 * ActionDispatch type is a generic type that defines a function for dispatching actions.
 * It takes a generic type TActions, which represents a collection of action functions.
 * These actions are expected to be functions that modify a state and may take a payload.
 *
 * The type specifies a function that takes two parameters:
 *   - 'action': a string literal representing the action to be dispatched.
 *   - 'payload': an optional parameter that can be the payload for the action.
 *
 * The type uses conditional types to infer and enforce the correct payload type for each action.
 * If an action in TActions is a function, it extracts the payload type using Parameters and maps it to the correct position.
 * If an action is not a function, it sets the payload type to 'never'.
 *
 * Example Usage:
 *   If TActions = { increment: (amount: number) => void; reset: () => void },
 *   ActionDispatch<TActions> would be:
 *   (action: "increment", payload: number) => void
 *   | (action: "reset", payload: never) => void
 */
export type ActionDispatch<TActions> = <T extends keyof TActions>(
  action: T,
  payload?: TActions[T] extends (...args: any) => any
    ? Parameters<TActions[T]>[1]
    : never,
) => void;

export type PromiseActionDispatch<TActions> = <T extends keyof TActions>(
  action: T,
  payload?: TActions[T] extends (...args: any) => any
    ? Parameters<TActions[T]>[1]
    : never,
) => Promise<void>;

/**
 * Enum for Free Plan Error status codes
 */
export enum FreePlanError {
  /** Access is restricted due to free plan limitations. */
  RESTRICTED = "free_plan_billing_restricted",

  /** Usage limit for the free plan has been reached. */
  LIMIT_REACHED = "free_plan_billing_reached",

  USER_LIMIT_REACHED = "user_max_limit_reached",
}

/**
 * Map for getting fallback error message
 */
export const FreePlanErrorMessage: { [T in FreePlanError]: string } = {
  [FreePlanError.RESTRICTED]:
    "Access is restricted on the free plan. Please consider upgrading plan.",
  [FreePlanError.LIMIT_REACHED]:
    "You've reached your limit on the free plan. Please consider upgrading plan.",
  [FreePlanError.USER_LIMIT_REACHED]:
    "You've reached limit on the number of users. Please consider adding more seats.",
};
