import { useCallback, useMemo, useState } from "react";
import { Dropdown } from "react-bootstrap";
import styles from "./FieldDropdown.module.scss";
import { ConditionOption as IConditionOption } from "src/services/Automation/AutomationTicketRouting/getConditionOptions.service";
import { useConditionGroupState } from "../../../../../hooks/useConditionGroupState";
import { Group } from "src/services/Automation/getAutomationById.service";

interface SelectedFieldType {
  fieldKey: string;
  fieldName?: string;
}

interface Props {
  options: Array<IConditionOption>;
  conditionIndex: number;
  groupIndex: number;
  prefix?: string;
}

const findSelectedComparisonTypeValue = ({
  selected,
  options,
}: {
  options: Array<IConditionOption>;
  selected: SelectedFieldType;
}): null | IConditionOption => {
  let option: null | IConditionOption = null;
  for (let i = 0; i < options.length; i++) {
    const value = options[i];
    if ((value as IConditionOption).fieldKey) {
      if ((value as IConditionOption).fieldKey === selected.fieldKey) {
        option = value as IConditionOption;
        break;
      }
    }
  }
  return option;
};

export const useSelectedField = ({
  options,
  conditionIndex,
  groupIndex,
}: Props) => {
  const { conditionGroup } = useConditionGroupState();
  const selected: SelectedFieldType | null = useMemo(() => {
    const ret = (conditionGroup?.conditions ?? [])[conditionIndex];
    if (ret?.fieldKey) {
      return {
        fieldKey: ret.fieldKey,
      };
    } else {
      return null;
    }
  }, [conditionGroup, conditionIndex, groupIndex]);

  const SelectedFieldType = useMemo(() => {
    return selected
      ? findSelectedComparisonTypeValue({ selected, options })
      : null;
  }, [options, selected]);

  return { SelectedFieldType };
};

interface PropsConditionType {
  option: IConditionOption;
  selected: IConditionOption | null;
  onChange: React.MouseEventHandler<HTMLElement>;
}
const ConditionType = ({ option, selected, onChange }: PropsConditionType) => {
  return (
    <div className="ps-2">
      {
        <span
          key={(option as IConditionOption).fieldKey}
          className={`me-1 ${styles.dropItem}`}
          onClick={onChange}
          {...((option as IConditionOption).fieldKey
            ? {
                "data-field-key": (option as IConditionOption).fieldKey,
              }
            : {})}
        >
          {(option as IConditionOption).fieldName}
        </span>
      }
    </div>
  );
};

const FieldDropdown = ({
  options,
  conditionIndex,
  groupIndex,
  prefix,
}: Props) => {
  const { conditionGroup, updateState, showErrors } = useConditionGroupState();
  const { SelectedFieldType } = useSelectedField({
    options,
    conditionIndex,
    groupIndex,
  });
  const [showMenu, setShowMenu] = useState(false);

  const handleChange: React.MouseEventHandler<HTMLElement> = useCallback(
    (e) => {
      const fieldKey = e.currentTarget.getAttribute("data-field-key");
      // const fieldDataType = e.currentTarget.getAttribute(
      //   "data-field-data-type",
      // ) as FieldDataTypes | null;

      if (fieldKey) {
        if (conditionGroup) {
          const newGroup: Group = { ...conditionGroup };
          if (newGroup) {
            // Check if the group and condition at the specified indexes exist
            if (newGroup && newGroup.conditions?.length) {
              if (newGroup.conditions[conditionIndex]) {
                // Merge the existing condition data with the new payload
                newGroup.conditions[conditionIndex] = {
                  ...newGroup.conditions[conditionIndex],
                  fieldKey: fieldKey,
                  value: null,
                  // fieldDataType: fieldDataType ? fieldDataType : undefined,
                };
              }
            }
            updateState({
              conditionGroup: newGroup,
              showErrors: false,
              saveStatus: "idle",
            });
          }
        }
        setShowMenu(false);
      }
    },
    [SelectedFieldType, conditionIndex, groupIndex, updateState, setShowMenu],
  );

  return (
    <div className={`me-md-2 mb-2 mb-md-0 ${styles.resWidth}`}>
      <Dropdown
        show={showMenu}
        onToggle={(show) => setShowMenu(show)}
        className={`${styles.resWidth}`}
      >
        <Dropdown.Toggle
          id="dropdown-basic"
          as="div"
          className={`${styles.dropHead}`}
        >
          <div>
            {prefix ? (
              <span className={`me-1 ${styles.prefixText}`}>{prefix}</span>
            ) : null}
            <span>
              {SelectedFieldType?.fieldKey
                ? SelectedFieldType.fieldName
                : "None"}
            </span>
          </div>
          <span className={`ps-2 ${styles.arrowDown}`}>
            {showMenu ? (
              <i className="fa-solid fa-caret-up"></i>
            ) : (
              <i className="fa-solid fa-caret-down"></i>
            )}
          </span>
        </Dropdown.Toggle>

        <Dropdown.Menu bsPrefix={`dropdown-menu ${styles.dropMenu}`}>
          {options.map((option) => {
            return (
              <ConditionType
                key={(option as IConditionOption).fieldKey}
                option={option}
                selected={SelectedFieldType}
                onChange={handleChange}
              />
            );
          })}
        </Dropdown.Menu>
      </Dropdown>

      {showErrors && !SelectedFieldType?.fieldKey && (
        <div className={styles.errText}>{"Please enter valid values!"}</div>
      )}
    </div>
  );
};
export default FieldDropdown;
