import { useState } from "react";
import InfiniteScroll from "src/components/InfiniteScroll";
import { fetchUnsentDraft } from "src/store/slices/draftFlow/draftFlow.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import DraftMsg from "../DraftMsg";
import styles from "./ListOtherDraft.module.scss";

function ListOtherDraft() {
  const [show, setShow] = useState(false);
  const dispatch = useAppDispatch();
  const unsentDraftMsg = useAppSelector(
    (state) => state.draftFlow.unsentDraftMsg
  );
  const unsentTotalCount = useAppSelector(
    (state) => state.draftFlow.unsentTotalCount
  );
  return (
    <div className={`d-inline-block`}>
      <span
        className={styles.unsentDraftText}
        onClick={(e: any) => {
          if (unsentDraftMsg.length > 0) {
            setShow(!show);
          }
        }}
      >
        +{unsentTotalCount} unsent drafts
      </span>
      {show && (
        <InfiniteScroll
          loadMore={() => {
            dispatch(fetchUnsentDraft());
          }}
          hasMore={unsentDraftMsg.length < unsentTotalCount}
          className={`${styles.othersBox} p-3`}
          initialDataLoaded={true}
        >
          <div className={`${styles.head} mx-0 my-2`}>Unsent Drafts</div>
          {unsentDraftMsg.map((value, index) => {
            return <DraftMsg key={index} canEdit={false} {...value} />;
          })}
        </InfiniteScroll>
      )}
    </div>
  );
}

export default ListOtherDraft;
