import { useState } from "react";
import successLogo from "src/assets/images/successLogo.png";
import { Modal } from "react-bootstrap";
import styles from "./SuccessModal.module.scss";

interface Props {
  brandName: string;
  setShowSuccessModal: (value: boolean) => void;
}

function SuccessModal({ brandName, setShowSuccessModal }: Props) {
  const [showModal, setShowModal] = useState(true);
  return (
    <Modal
      backdropClassName={`${styles.modalBack}`}
      show={showModal}
      onHide={() => {
        setShowModal(false);
        setShowSuccessModal(false);
      }}
      dialogClassName={`${styles.modalDialog}`}
      contentClassName={`${styles.modalContent}`}
    >
      <div className={`w-100 ${styles.deleteWrap}`}>
        <div className={`text-center `}>
          <img src={successLogo} alt="" className={`${styles.successLogo}`} />
          <p className={`mt-2 mb-1 ${styles.deleteText}`}>Deleted!</p>
          <span className={`pb-2 d-block ${styles.deleteSub}`}>
            "{brandName}" Brand successfully deleted
          </span>
          <div className="d-flex justify-content-center">
            <button
              className={`${styles.okBtn}`}
              onClick={() => {
                setShowModal(false);
                setShowSuccessModal(false);
              }}
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default SuccessModal;
