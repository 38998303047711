/**
 * This file defines a sidebar component for automation management and used for redirecting to automation related routes.
 * It is designed to be used within the BotSettings component.
 *
 * @author @navjyot-busibud
 * @author @yuvaraj-busibud
 */
import React from "react";
import { useNavigate } from "react-router-dom";
import orderIssue from "src/assets/images/WarrantyIssues.png";
import returnImg from "src/assets/images/auto2.png";
import orderTrack from "src/assets/images/auto3.png";
import botImg from "src/assets/images/botLogo.png";
import bugIcon from "src/assets/images/bugIcon.png";
import issueDispatchIcon from "src/assets/images/issueDispatchIcon.png";
import orderModifyImg from "src/assets/images/modification.png";
import orderImg from "src/assets/images/orderMonitor.png";
import { useAppSelector } from "src/store/store";
import {
  BotSettingsActivePath,
  BotSettingsActiveSubPath,
} from "../../BotSettings";
import { EAutomationType } from "../AutomationList/AutomationList";
import styles from "./AutomationSidebar.module.scss";
import ticketRoute from "src/assets/images/TicketRoute.svg";
interface Props {
  activePath?: BotSettingsActivePath;
  activeSubPath?: BotSettingsActiveSubPath;
  sidebarHover: boolean;
  setSidebarHover: React.Dispatch<React.SetStateAction<boolean>>;
}

const AutomationSidebar = ({
  activePath,
  activeSubPath,
  sidebarHover,
  setSidebarHover,
}: Props) => {
  const currentUserData = useAppSelector(
    (state) => state.globals.currentUserData,
  );
  const navigate = useNavigate();
  return (
    <>
      {/* Sidebar */}
      <div
        className={`d-flex flex-column  me-auto ms-0 ${styles.sidebar}`}
        onMouseEnter={() => {
          setSidebarHover(true);
        }}
        onMouseLeave={() => {
          setSidebarHover(false);
        }}
        id="sidebarWrapper"
      >
        {/* Bot */}
        {currentUserData?.ui_visibility &&
          currentUserData?.ui_visibility?.includes("chat_bot_view") && (
            <div
              className={`mb-2 ${styles.bot} ${
                activePath === "bot" ? styles.active : ""
              } ${
                sidebarHover
                  ? styles.initiateBox
                  : styles.initiateBoxOnSideBarHover
              }`}
              onClick={(e: any) => {
                navigate("/bot");
              }}
              id="bot"
            >
              <span className={`${styles.botImg}`}>
                <img
                  src={botImg}
                  alt=""
                />
              </span>
              <span
                className={`ps-2 ${
                  sidebarHover
                    ? styles.commonText
                    : styles.commonTextOnSideBarHover
                } `}
              >
                Bot
              </span>
            </div>
          )}
        {/* Bug management */}
        {currentUserData?.ui_visibility &&
          currentUserData?.ui_visibility?.includes("bug_management") && (
            <div
              className={`mb-2 ${styles.bug} ${
                activeSubPath === EAutomationType.BUG_MANAGEMENT
                  ? styles.active
                  : ""
              } ${
                sidebarHover
                  ? styles.initiateBox
                  : styles.initiateBoxOnSideBarHover
              }`}
              onClick={() => {
                navigate(`/automations/${EAutomationType.BUG_MANAGEMENT}`);
              }}
            >
              <span className={`${styles.botImg}`}>
                <img
                  src={bugIcon}
                  alt=""
                />
              </span>
              <span
                className={`ps-1 ${
                  sidebarHover
                    ? styles.commonText
                    : styles.commonTextOnSideBarHover
                } `}
              >
                Bug management
              </span>
            </div>
          )}

        {currentUserData?.ui_visibility &&
          currentUserData?.ui_visibility?.includes("issue_dispatch") && (
            <div
              className={`mb-2 ${styles.issueDispatch} ${
                activeSubPath === EAutomationType.ISSUE_DISPATCH
                  ? styles.active
                  : ""
              } ${
                sidebarHover
                  ? styles.initiateBox
                  : styles.initiateBoxOnSideBarHover
              }`}
              onClick={() => {
                navigate(`/automations/${EAutomationType.ISSUE_DISPATCH}`);
              }}
            >
              <span className={`${styles.botImg}`}>
                <img
                  src={issueDispatchIcon}
                  alt=""
                />
              </span>
              <span
                className={`ps-1 ${
                  sidebarHover
                    ? styles.commonText
                    : styles.commonTextOnSideBarHover
                } `}
              >
                Issue Dispatch
              </span>
            </div>
          )}

        {/** Checking if automation view is enabled */}
        {currentUserData?.ui_visibility &&
          currentUserData?.ui_visibility?.includes("automation_view") && (
            <>
              {/*1 June SHowing return based on ui visibility */}
              {/* Return Exchanges */}
              {currentUserData?.ui_visibility &&
                currentUserData?.ui_visibility?.includes(
                  "automation_return_exchange_view",
                ) && (
                  <div
                    key="returnExchange"
                    className={`mb-2 ${styles.return} ${
                      activeSubPath === "return" ? styles.active : ""
                    } ${
                      sidebarHover
                        ? styles.initiateBox
                        : styles.initiateBoxOnSideBarHover
                    }`}
                    onClick={(e: any) => {
                      navigate("/automations/return");
                    }}
                    id="returnExchange"
                  >
                    <span className={`${styles.botImg}`}>
                      <img
                        src={returnImg}
                        alt=""
                      />
                    </span>
                    <span
                      className={`ps-2 ${
                        sidebarHover
                          ? styles.commonText
                          : styles.commonTextOnSideBarHover
                      } `}
                    >
                      Returns/Exchanges
                    </span>
                  </div>
                )}
              {/* Order Tracking */}
              <div
                key="orderTracking"
                className={`mb-2 ${styles.tracking} ${
                  activePath === "tracking" ? styles.active : ""
                } ${
                  sidebarHover
                    ? styles.initiateBox
                    : styles.initiateBoxOnSideBarHover
                }`}
                onClick={(e: any) => {
                  navigate("/comingSoon/tracking");
                }}
                id="orderTracking"
              >
                <span className={`${styles.modifyImg}`}>
                  <img
                    src={orderTrack}
                    alt=""
                  />
                </span>
                <span
                  className={`ps-2 ${
                    sidebarHover
                      ? styles.commonText
                      : styles.commonTextOnSideBarHover
                  } `}
                >
                  Order tracking
                </span>
              </div>
              {/* Order modifications & cancellations */}
              <div
                key="orderModification"
                className={`mb-2 ${styles.modification} ${
                  activePath === "modification" ? styles.active : ""
                } ${
                  sidebarHover
                    ? styles.initiateBox
                    : styles.initiateBoxOnSideBarHover
                }`}
                onClick={(e: any) => {
                  navigate("/comingSoon/modification");
                }}
                id="orderModification"
              >
                <div className="d-flex">
                  <span className={`d-block ${styles.modifyImg}`}>
                    <img
                      src={orderModifyImg}
                      alt=""
                    />
                  </span>
                  <span
                    className={`ps-2 ${
                      sidebarHover
                        ? styles.commonText
                        : styles.commonTextOnSideBarHover
                    } `}
                  >
                    Order modifications & cancellations
                  </span>
                </div>
              </div>
              {/* Order Monitoring */}
              <div
                key="orderMonitoring"
                className={`mb-2 ${styles.monitoring} ${
                  activePath === "monitoring" ? styles.active : ""
                } ${
                  sidebarHover
                    ? styles.initiateBox
                    : styles.initiateBoxOnSideBarHover
                }`}
                onClick={(e: any) => {
                  navigate("/comingSoon/monitoring");
                }}
                id="orderMonitoring"
              >
                <span className={`${styles.orderMonitorImg}`}>
                  <img
                    src={orderImg}
                    alt=""
                  />
                </span>
                <span
                  className={`ps-2 ${
                    sidebarHover
                      ? styles.commonText
                      : styles.commonTextOnSideBarHover
                  } `}
                >
                  Order monitoring
                </span>
              </div>
              {/* Warranty Issues */}
              <div
                key="warrantyIssues"
                className={`mb-2 ${styles.warranty} ${
                  activePath === "warranty" ? styles.active : ""
                } ${
                  sidebarHover
                    ? styles.initiateBox
                    : styles.initiateBoxOnSideBarHover
                }`}
                onClick={(e: any) => {
                  navigate("/comingSoon/warranty");
                }}
                id="warrantyIssues"
              >
                <span className={`${styles.checkImg}`}>
                  <img
                    src={orderIssue}
                    alt=""
                  />
                </span>
                <span
                  className={`ps-2 ${
                    sidebarHover
                      ? styles.commonText
                      : styles.commonTextOnSideBarHover
                  } `}
                >
                  Warranty issues
                </span>
              </div>
              {/* Ticket routing  */}
              {currentUserData?.ui_visibility &&
                currentUserData?.ui_visibility?.includes(
                  "automation_ticket_routing",
                ) && (
                  <div
                    className={`mb-2 ${styles.ticketRoute} ${
                      activeSubPath === "ticketRouting" ? styles.active : ""
                    } ${
                      sidebarHover
                        ? styles.initiateBox
                        : styles.initiateBoxOnSideBarHover
                    }`}
                    onClick={(e: any) => {
                      navigate("/automations/ticketRouting");
                    }}
                  >
                    <span className={`${styles.botImg}`}>
                      <img
                        src={ticketRoute}
                        alt=""
                      />
                    </span>
                    <span
                      className={`ps-1 ${
                        sidebarHover
                          ? styles.commonText
                          : styles.commonTextOnSideBarHover
                      } `}
                    >
                      Ticket Routing
                    </span>
                  </div>
                )}
            </>
          )}
      </div>
    </>
  );
};

export default AutomationSidebar;
