import { axiosJSON } from "src/globals/axiosEndPoints";
import { BugData } from "./getBugByCategory.service";

const updateBugStatus = async (param: {
  bugId: number;
  bugStatusKey: string;
}) => {
  const { data: res } = await axiosJSON.post(
    `/api/selfService/bugManagement/changeBugStatusByAgent`,
    param,
  );

  if (res.err || res.error) {
    throw res.msg ?? res.message ?? "Something went wrong!";
  }

  let ret: BugData;

  ret = res.data;

  return ret;
};

export default updateBugStatus;
