import {
  CallerData,
  CallerTypes,
  CallRecordingSlice,
} from "./callRecording.declarations";

const callerDataFulfilled = (
  state: CallRecordingSlice,
  {
    payload,
  }: {
    payload: {
      callerData: {
        [callerId: string | number]: CallerData;
      };
      callerDataMeta: {
        current: number;
        totalCount: number;
      };
      cachedData: { [pageNo: string | number]: Array<number | string> };
      noOfPages: number;
      currentPage: number;
      allCallerTypes: { [id: string | number]: CallerTypes };
    };
  }
) => {
  state = {
    ...state,
    ...payload,
    callerDataAjaxStatus: "fulfilled",
    callerIdList: payload.cachedData[payload.currentPage],
  };
  return state;
};

export default { callerDataFulfilled };
