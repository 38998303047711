import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import styles from "./MultipleChoiceList.module.scss";
import { v4 as uuidv4 } from "uuid";

function MultipleChoiceList({ element, inputName }: any) {
  const [checkCount, setCheckCount] = useState(0);
  const [checkBoxList, setCheckBoxList] = useState<any>([]);

  useEffect(() => {
    if (element !== null && element !== undefined) {
      const optionsList = element.elementOptions?.map((choiceText: string) => {
        return {
          id: uuidv4(),
          label: choiceText,
          checked: false,
          isRequired: element.isRequired,
        };
      });

      setCheckBoxList(optionsList);
    }
  }, [element]);

  const handleOnChange = (e: any, checkBoxInput: any) => {
    if (element.isRequired) {
      let count = checkCount;
      if (e.target.checked) {
        setCheckCount(checkCount + 1);
        count += 1;
      } else {
        setCheckCount(checkCount - 1);
        count -= 1;
      }

      const optionsList = checkBoxList.map((checkBox: any) => {
        let input = { ...checkBox };
        if (input["id"] === checkBoxInput["id"]) {
          input["checked"] = e.target.checked;
        }
        if (count === 0) {
          input["isRequired"] = true;
          return input;
        } else {
          if (input.checked === false) {
            input["isRequired"] = false;
            return input;
          } else {
            return input;
          }
        }
      });

      setCheckBoxList(optionsList);
    }
  };
  return (
    <div className={`d-flex flex-column text-muted ${styles.fonts} mt-2`}>
      <div className="mb-2">
        {/* {element.elementValue} */}
        <div className={styles.inputLabel}>{element.elementValue}</div>
        {element.isRequired ? (
          <span className="text-danger align-top"> *</span>
        ) : null}{" "}
      </div>
      {checkBoxList.map((checkBoxInput: any) => {
        return (
          <Form.Check
            key={checkBoxInput.id}
            inline
            label={checkBoxInput.label}
            required={checkBoxInput.isRequired === true}
            name={inputName + "[]"}
            type="checkbox"
            checked={checkBoxInput.checked}
            className={styles.formCheck}
            onChange={(e) => handleOnChange(e, checkBoxInput)}
          />
        );
      })}
    </div>
  );
}

export default MultipleChoiceList;
