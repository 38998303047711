import React from "react";
import styles from "./NoTags.module.scss";
import noData from "src/assets/images/no-data.png";

function NoTags() {
  return (
    <div
      className={`${styles.no} ${styles.teams_yet} teams-yet d-flex justify-content-center align-items-center h-100 text-center mt-5`}
    >
      <div>
        <div className="images">
          <img src={noData} alt="no data" className="img-fluid" />
        </div>
        <div className={`${styles.no_teams} ${styles.no_data}`}>
          <h3 className="mb-0">No Data Available</h3>
          <h6>Looks like there are no tags yet. Go on create one!</h6>
        </div>
      </div>
    </div>
  );
}

export default NoTags;
