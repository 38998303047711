/**
 * useConfigureModal Hook
 *
 * Custom hook for handling CRUD operations related to audience configuration modal conditions.
 *
 * @author @yuvaraj-busibud
 */

/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useMemo, useCallback, useEffect } from "react";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { AudienceConfigCondition } from "src/store/slices/botProfiles/botProfiles.declarations";
import {
  addAudienceConfigCondition,
  resetAudienceConfigCondition,
} from "src/store/slices/botProfiles/botProfiles.slice";
import {
  updateConfigureAudienceCondition,
  fetchConfigureAudienceCondition,
} from "src/store/slices/botProfiles/botProfiles.thunks";
import { useAppDispatch, useAppSelector } from "src/store/store";
var objectHash = require("object-hash");

interface Props {
  profileId: number | null | undefined;
}

function useConfigureModal({ profileId }: Props) {
  const [showSaveLoader, setShowSaveLoader] = useState(false);
  const [saveButtonText, setSaveButtonText] = useState("Save");
  const [selectedOption, setSelectedOption] = useState(null);
  const [isChange, setIschange] = useState(false);

  const dispatch = useAppDispatch();
  const {
    audienceConfigConditions,
    audienceConfigConditionAJAX,
    initialConfigHash,
  } = useAppSelector((state) => state.botProfiles);

  const isLoading = useMemo(() => {
    return (
      audienceConfigConditionAJAX === "pending" &&
      audienceConfigConditions.length === 0
    );
  }, [audienceConfigConditionAJAX, audienceConfigConditions]);

  // Callback for adding a new audience configuration condition
  const handleAddCondition = useCallback(() => {
    dispatch(addAudienceConfigCondition());
  }, []);

  // Callback for handling the update audience configuration condition
  const handleSubmit = useCallback(() => {
    if (!profileId) {
      // If there is no profileId, exit the function
      return;
    }
    setShowSaveLoader(true);
    // Dispatch an action to update the configuration for audience conditions
    dispatch(
      updateConfigureAudienceCondition({ profileId: parseInt(profileId + "") })
    )
      .then((res: any) => {
        // Check if the request was rejected
        if (res?.meta?.requestStatus === "rejected") {
          // Display a danger toast for failed save
          pushTheToast({
            type: "danger",
            text: "Failed to save",
            position: "top-right",
          });
          // Reset the save button text
          setSaveButtonText("Save");
          return;
        }

        // Check if there is a payload and payload error
        if (res.payload && res.payload.error) {
          // Check if there are validation errors
          if (res.payload.validationErrors) {
            const conditions: AudienceConfigCondition[] =
              res.payload?.conditions;

            let errorText = "Please fill all the fields!";

            // Handle specific error scenarios based on conditions
            if (conditions && conditions.length === 1) {
              if (conditions[0]?.conditionKey === null) {
                errorText = "Atleast one condition required";
              } else {
                if (res.payload.errors[0]) {
                  errorText = res.payload.errors[0];
                } else {
                  errorText = conditions[0]?.error + "";
                }
              }
            } else if (conditions) {
              if (res.payload.errors[0]) {
                errorText = res.payload.errors[0];
              } else {
                const errorCondition = conditions.filter(
                  (condition) => condition.error
                );

                if (errorCondition) {
                  errorText = errorCondition[0].error + "";
                }
              }
            } else {
              errorText = "Atleast one condition required";
            }

            // Display a warning toast for validation errors
            pushTheToast({
              type: "warning",
              text: errorText,
              position: "top-right",
            });

            // Reset the save button text
            setSaveButtonText("Save");
          } else {
            // Display a danger toast for failed save
            pushTheToast({
              type: "danger",
              text: "Failed to save!",
              position: "top-right",
            });

            // Reset the save button text
            setSaveButtonText("Save");
          }
        } else {
          // Display a success toast for successful save
          pushTheToast({
            type: "success",
            text: "Successfully Saved!",
            position: "top-right",
          });

          // Update the save button text to indicate successful save
          setSaveButtonText("Saved!");
        }
      })
      .catch(() => {
        // Display a danger toast for failed save
        pushTheToast({
          type: "danger",
          text: "Failed to save!",
          position: "top-right",
        });

        // Reset the save button text
        setSaveButtonText("Save");
      })
      .finally(() => {
        // Hide the save loader after the update is complete
        setShowSaveLoader(false);
      });
  }, []);
  // Reset and fetch configure audience conditions when the profileId changes
  useEffect(() => {
    if (profileId) {
      dispatch(resetAudienceConfigCondition());
      dispatch(fetchConfigureAudienceCondition({ profileId }));
    }
  }, [profileId]);

  // Update save button state based on form changes
  useEffect(() => {
    if (showSaveLoader === false) {
      // Compare the hash of audienceConfigConditions with the initialConfigHash
      if (objectHash(audienceConfigConditions) != initialConfigHash) {
        setSaveButtonText("Save");
        setIschange(true);
      } else {
        setIschange(false);
      }
    }
  }, [showSaveLoader, audienceConfigConditions, initialConfigHash]);

  return {
    showSaveLoader,
    selectedOption,
    setSelectedOption,
    isLoading,
    audienceConfigConditions,
    handleAddCondition,
    handleSubmit,
    saveButtonText,
    isChange,
    setIschange,
  };
}

export default useConfigureModal;
