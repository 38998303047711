/**
 * This file is the service file used for making api request.
 * It contains the getAllArticleFeedback function service which is explained below.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants"; // this is used for testing purpose only for frontend.

export interface GetAllArticleFeedbackPayload {
  integrationId: number | string;
  limit: number | string;
  searchTerm?: string;
  start: number | string;
}

export interface GetAllArticleFeedback {
  id: number;
  feedbackCustomerName: string;
  feedbackCustomerEmail: string;
  articleName: string;
  articleId: number | string;
  articleUrl: string;
  ratingProvided: "happy" | "sad" | "neutral" | "yes" | "no";
  ratingStatus:
    | "approved"
    | "pending"
    | "rejected"
    | "Approved"
    | "Pending"
    | "Rejected";
  comment: {
    commentText: string;
    commentCreatedAt: string;
  };
  comment_action:
    | "approved"
    | "pending"
    | "rejected"
    | "Approved"
    | "Pending"
    | "Rejected";
}

export interface GetAllArticleFeedbackResponse {
  data: GetAllArticleFeedback[];
  metaData: {
    currentCount: number;
    totalCount: number;
  };
}

/**
 * For testing frontend with own api link if backend is not prepared.
 */
const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/getAllArticleFeedbacks`
  : "/api/chatSetting/getAllArticleFeedbacks";

/**
 * This is the service made to get all the feedbacks given on the articles published on the helpcenter page.
 */
export const getAllArticleFeedback = async (
  params: GetAllArticleFeedbackPayload
) => {
  const { data: res } = await axiosJSON.post(apiEndPoint, params);
  return res as GetAllArticleFeedbackResponse;
};
