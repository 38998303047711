import { useEffect, useRef, useState } from "react";
import styles from "./SuccessMsg.module.scss";
import SuccessImg from "src/assets/images/success.png";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { useNavigate } from "react-router-dom";
import { resetLiveChatTabQuickActionCreateTicket } from "src/store/slices/liveChatSetting/liveChatTabQuickActions/liveChatTabQuickActions.slice";
import { setTicketData } from "src/store/slices/liveChatSetting/chatWidExchMsg/chatWidExchMsg.slice";

const SuccessMsg = ({
  onHide,
  createdTicketId,
  resolveAndCreateTicket,
}: {
  onHide: any;
  createdTicketId: number | string;
  resolveAndCreateTicket: any;
}) => {
  const dispatch = useAppDispatch();
  // const { createdTicketId, createTicketFromLiveChatAjaxStatus } =
  //   useAppSelector((state) => state.liveChatTabQuickActions);

  // useEffect(() => {
  //   if (createTicketFromLiveChatAjaxStatus === "fulfilled") {
  //     dispatch(setTicketData({ ticketData: { id: createdTicketId } }));
  //   }
  // }, [createdTicketId]);

  const navigate = useNavigate();

  return (
    <div className="text-center">
      <img src={SuccessImg} className={`${styles.successImg}`} alt="success" />
      <p className={`mt-2 ${styles.donePara}`}>
        {resolveAndCreateTicket === true
          ? "The chat has been marked as resolved and a ticket has been created for this chat with ticket id : "
          : " A ticket has been created for this chat with ticket id :"}{" "}
        {createdTicketId}
      </p>
      <div className={`d-flex justify-content-center aling-items-center pt-2`}>
        <button
          className={`bg-white me-4 px-2 py-1 ${styles.viewTicket}`}
          onClick={(e: any) => {
            e.preventDefault();
            window.open(`/tickets/all/ticket-view/ticket/${createdTicketId}/`);
            onHide();
            // dispatch(resetLiveChatTabQuickActionCreateTicket());
          }}
        >
          View ticket
        </button>
        <button
          className={`px-2 py-1 ${styles.doneBtn}`}
          onClick={() => {
            onHide();
            // dispatch(resetLiveChatTabQuickActionCreateTicket());
          }}
        >
          Done
        </button>
      </div>
    </div>
  );
};

export default SuccessMsg;
