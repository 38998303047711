import { axiosJSON } from "src/globals/axiosEndPoints";

export interface Items {
  itemId: string;
  itemSubTotal: {
    amount: number;
    currencyCode: string;
  };
}

export interface CalculateRefundTotalPrice {
  items: [Items];

  summary: {
    subtotal: {
      amount: number;
      currencyCode: string;
    };
    itemCount: 1;
    discount: {
      amount: number;
      currencyCode: string;
    };
    tax: {
      amount: number;
      currencyCode: string;
    };
    shippingAmount: {
      amount: number;
      currencyCode: string;
    };
    refundTotalAmount: {
      amount: number;
      currencyCode: string;
    };
  };
}

export interface CalculateRefundTotalPriceParams {
  orderId: number;
  shippingAmount: number;
  refundItems: [
    {
      itemId: string;
      quantity: number;
      restock: boolean;
      locationId: string;
    }
  ];
}

export async function calculateRefundTotalPrice(
  params: CalculateRefundTotalPriceParams
) {
  const { data: res }: any = await axiosJSON.post(
    `/api/shopify/order/refund/calculate`,
    params
  );

  if (res.error === true) {
    throw new Error(res.message as string);
  }

  return res.data as CalculateRefundTotalPrice;
}
