import { axiosJSON } from "src/globals/axiosEndPoints";

export interface IProvince {
  provinceName: string | null;
  provinceCode: string | null;
}

export interface ICountry {
  countryName: string;
  countryCode: string;
  provinces: Array<IProvince>;
}
export interface IGetAllCountryList {
  countryList: Array<ICountry>;
  countryWithIdList: { [countryCode: string]: ICountry };
}

export async function getAllCountryList() {
  const { data: res }: any = await axiosJSON.post(
    `/api/shopify/getAllCountryList`
  );
  
  if (res.error === true) {
    throw new Error(res.message as string);
  }
  
  const ret: IGetAllCountryList = {
    countryList: res,
    countryWithIdList: {}
  };
  res.forEach((country: ICountry) => {
    ret.countryWithIdList[country.countryCode] = country;
  });

  return ret;
}
