import {
  createAsyncThunk,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { AJAXSTATUS } from "src/globals/constants";
import { IChatFormElementOptionList } from "src/services/LiveChat/Settings/preChatForm/getPreChatFormData";
import { ITicketChatFormData } from "../chatForm.declarations";
import ticketChatFormSettingExtraReducers from "./ticketChatFormSetting.extraReducers";
import ticketChatFormSettingReducers from "./ticketChatFormSetting.reducers";
import ticketChatFormSettingThunks from "./ticketChatFormSetting.thunks";

export const fetchTicketChatFormData = createAsyncThunk(
  "ticketChatFormSetting/fetchTicketChatFormData",
  ticketChatFormSettingThunks.fetchTicketChatFormDataThunk
);

export interface ITicketChatFormSetting{
  ticketChatFormData: ITicketChatFormData;
  formElementOptions: IChatFormElementOptionList;
  ticketChatFormDataAjaxStatus: AJAXSTATUS;
  updateTicketChatFormDataAjaxStatus: AJAXSTATUS;
}

export const initialState : ITicketChatFormSetting = {
  ticketChatFormData: {
    formId: 0,
    enabled: false,
    integrationId: 0,
    sendTicketFormMessageTo: "livechat",
    customEmail: "",
    elements: [],
    availability: {
      canCustomerStartChatAlways: false
    }
  },
  formElementOptions:{
    formElementOptions: {},
    formElementOptionIds: [],
  },
  ticketChatFormDataAjaxStatus: "pending",
  updateTicketChatFormDataAjaxStatus: "idle",
}

export const ticketChatFormSettingSlice = createSlice({
  name: "ticketChatFormSetting",
  initialState,
  reducers: ticketChatFormSettingReducers,
  extraReducers: (builder) => {
    builder.addCase(
      fetchTicketChatFormData.fulfilled,
      ticketChatFormSettingExtraReducers.fetchTicketChatFormDataFulfilled
    );
    builder.addCase(fetchTicketChatFormData.rejected, (state) => {
      state.ticketChatFormDataAjaxStatus = "rejected";
      pushTheToast({
        position: "top-right",
        type: "danger",
        text: "Something went wrong!"
      });
    });
    builder.addCase(fetchTicketChatFormData.pending, (state) => {
      state.ticketChatFormDataAjaxStatus = "pending";
    });
  }
});

export const { resetTicketChatFormSetting } = ticketChatFormSettingSlice.actions;

export default ticketChatFormSettingSlice.reducer;
