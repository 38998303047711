import { Customer } from "src/services/LiveChat/messageExchange/getAllCustomers";
import { ChatWidExchCustSlice } from "./chatWidExchCust.declarations";
import chatWidExchCustReducer from "./chatWidExchCust.reducers";

const fetchCustomerDataFulfilled = (
  state: ChatWidExchCustSlice,
  { payload }: any,
) => {
  state.chatIdList = Array.from(
    new Set([...state.chatIdList, ...payload.chatIdList]),
  );
  state.customers = { ...state.customers, ...payload.customers };

  state.metaData = payload.metaData;
  // if (payload.sidebar) {
  //   state.sidebar = payload.sidebar;
  // }
  state.fetchCustAJAXStatus = "fulfilled";

  if (payload.customerStatus && !state.initialized) {
    state.selectedCustomerStatus = payload.customerStatus;
  }

  state.loading = false;
  return state;
};

// const emptyCustomersList = (state: ChatWidExchCustSlice) => {
//   return state;
// };

const fetchCustomerDataByChatIdFulfilled = (
  state: ChatWidExchCustSlice,
  { payload }: any,
) => {
  if (payload.data.chatId) {
    //checking if chat status is matching with response chat status
    if (
      // payload.chatStatus === undefined ||
      // payload.chatStatus === state.selectedCustomerStatus
      payload.data.customerStatus === state.selectedCustomerStatus ||
      (payload.data.customerStatus === "queued" &&
        state.selectedCustomerStatus === "live")
    ) {
      //removing chat from list if it is resolved
      if (
        payload.data?.isResolved === true &&
        state.selectedCustomerStatus !== "archived"
      ) {
        chatWidExchCustReducer.removeCustomerFromChatList(state, {
          payload: {
            chatId: parseInt(payload.data.chatId + ""),
          },
          type: "",
        });
      } else {
        const customer = payload.data;
        // Check if customer's agent ID is included in selectedAgentIdsForFilter
        const isAgentFilterApplied = () => {
          if (
            state.idFilterApplied.selectedIds &&
            state.idFilterApplied.selectedIds.length !== 0
          ) {
            // Check if customer exists and has chatAssignedTo
            if (
              customer &&
              customer.chatAssignedTo &&
              customer.chatAssignedTo.length > 0
            ) {
              // Check if any chatAssignedTo ID is included in selectedAgentIdsForFilter
              return customer.chatAssignedTo.some((agent: any) =>
                state.idFilterApplied.selectedIds.includes(agent?.id ?? ""),
              );
            }
          } else {
            // If no filter is applied, consider it as applied
            return true;
          }
          return false; // Return false if customer's chatAssignedTo is not found in filter
        };

        // Check if customer's brand ID is included in brandIdsApplied
        const isBrandFilterApplied = () => {
          if (state.brandIdsApplied && state.brandIdsApplied.length !== 0) {
            // Check if customer exists and has a brand ID
            if (customer && customer.brand && customer.brand.id) {
              return state.brandIdsApplied.includes(customer.brand.id);
            }
          } else {
            return true;
          }
          return false; // Return false if customer's brand ID is not found in filter
        };

        // Check if customer's status is included in statusFilterApplied
        const isStatusFilterApplied = () => {
          if (
            state.statusFilterApplied &&
            state.statusFilterApplied.length !== 0
          ) {
            // Check if customer exists
            if (customer) {
              // Check if any of the applied status filters are satisfied
              return state.statusFilterApplied.some((filter) => {
                if (filter === "resolved") {
                  return customer.isResolved;
                } else if (filter === "resolved_and_archived") {
                  return (
                    customer.isResolved &&
                    customer.customerStatus === "achieved"
                  );
                } else if (filter === "unresolved_and_archived") {
                  return (
                    !customer.isResolved &&
                    customer.customerStatus === "achieved"
                  );
                } else if (filter === "unresolved_and_unarchived") {
                  return (
                    !customer.isResolved &&
                    customer.customerStatus !== "achieved"
                  );
                } else if (filter === "unresolved") {
                  return !customer.isResolved;
                } else if (filter === "all") {
                  return true; // Return true for "all" filter
                }
                return false; // Default return false
              });
            }
          }
          return true; // Return true if no filters are applied
        };

        // Check if customer's date falls within the selected date range
        const isDateFilterApplied = () => {
          if (state.appliedFilterEndDate && state.appliedFilterStartDate) {
            // Check if customer exists and lastMessageTime
            if (customer && customer?.lastMessageTime) {
              const userTimezone = payload.userTimezone;
              // Convert the date to the user's timezone
              const formattedDate = new Date(
                customer.lastMessageTime,
              ).toLocaleString("en-US", { timeZone: userTimezone });

              // Convert appliedFilterStartDate and appliedFilterEndDate to Date objects
              const startDate = new Date(state.appliedFilterStartDate);
              const endDate = new Date(state.appliedFilterEndDate);
              const lastMessageDate = new Date(formattedDate);
              // Compare dates
              return (
                state.appliedFilterStartDate !== null &&
                state.appliedFilterEndDate !== null &&
                lastMessageDate >= startDate &&
                lastMessageDate <= endDate
              );
            }
          } else {
            return true;
          }
          return false;
        };

        // Combine all filter checks
        const allFiltersApplied =
          isAgentFilterApplied() &&
          isBrandFilterApplied() &&
          isStatusFilterApplied() &&
          isDateFilterApplied() &&
          !state.filters.showMentions;

        //checking if live chat filter is applied, if applied not add new entry
        // const filterApplied =
        //   (state.idFilterApplied.selectedIds &&
        //     state.idFilterApplied.selectedIds.length !== 0) ||
        //   state.statusFilterApplied.length !== 0 ||
        //   state.appliedFilterEndDate ||
        //   state.appliedFilterEndDate ||
        //   state.brandIdsApplied.length !== 0 ||
        //   state.filters.showMentions;
        if (
          !state.chatIdList.includes(payload.data.chatId) &&
          state.filters.searchValue === "" &&
          allFiltersApplied &&
          !(
            state.chatIdList.length === 0 &&
            state.fetchCustAJAXStatus === "pending"
          )
        ) {
          state.chatIdList.unshift(payload.data.chatId);
        }

        state.customers[payload.data.chatId] = payload.data;
      }
    }

    // if (payload.metaData) {
    //   state.sidebar.missedChatIds = payload.metaData.sidebar.missedChatIds;
    //   state.sidebar.liveChatIds = payload.metaData.sidebar.liveChatIds;
    // }
  }

  state.loading = false;
  return state;
};

const fetchLiveChatSidebarFulfilled = (
  state: ChatWidExchCustSlice,
  { payload }: any,
) => {
  if (payload) {
    state.sidebar.missedChatIds = payload.missedChatIds;
    state.sidebar.liveChatIds = payload.liveChatIds;
    state.sidebar.archiveChatIds = payload.archiveChatIds;
    state.sidebar.timestamp = payload.timestamp;
    if (payload?.sidebarFromApi?.timestamp) {
      // Filter chatUpdatedCountQueue to remove entries with timestamps less than payload.timestamp
      state.chatUpdatedCountQueue = state.chatUpdatedCountQueue.filter(
        (entry) => entry.timestamp >= payload.sidebarFromApi.timestamp,
      );
    } else {
      state.chatUpdatedCountQueue = [];
    }
    if (payload.sidebarFromApi) {
      state.sidebarFromApi = payload.sidebarFromApi;
    }
  }

  return state;
};

function fetchAllAssignToAgentsListForFilterFulfilled(
  state: ChatWidExchCustSlice,
  { payload }: any,
) {
  const { agentIds, agents, metaData } = payload;

  state.agentListAJAXStatus = "fulfilled";
  state.agentIds = Array.from(new Set([...state.agentIds, ...agentIds]));

  state.agents = { ...state.agents, ...agents };
  state.agentMeta.count = metaData.count;
  state.agentMeta.total = metaData.total;

  return state;
}

const fetchLiveChatIntegratedBrandsFulfilled = (
  state: ChatWidExchCustSlice,
  { payload }: any,
) => {
  const { brands, brandIds, metaData } = payload;

  state.liveChatIntegratedBrands.brandIds = brandIds;
  state.liveChatIntegratedBrands.brands = brands;
  state.liveChatIntegratedBrands.metaData.count = metaData.count;
  state.liveChatIntegratedBrands.metaData.total = metaData.total;

  state.liveChatIntegratedBrands.fetchLiveChatIntegratedBrandAJAXStatus =
    "fulfilled";

  return state;
};

export default {
  fetchCustomerDataFulfilled,
  fetchCustomerDataByChatIdFulfilled,
  fetchLiveChatSidebarFulfilled,
  fetchAllAssignToAgentsListForFilterFulfilled,
  fetchLiveChatIntegratedBrandsFulfilled,
};
