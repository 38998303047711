import styles from "./DeleteTracker.module.scss";
const DeleteTracker = () => {
  return (
    <>
      <div className={`container`}>
        <div className={`row`}>
          <div className={`col-12`}>
            <div className="text-center">
              <p className={`${styles.delPara}`}>
                Are you sure you want to delete Same value?
              </p>
              <span className={`${styles.delSpan}`}>
                This sale tracker will be permanently deleted and all its
                statistics will be lost.
              </span>
              <div
                className={`d-flex flex-column justify-content-center align-items-center flex-md-row ${styles.actionBtns}`}
              >
                <button
                  className={` me-md-2 me-lg-4 bg-white ${styles.cancelBtn}`}
                >
                  Cancel
                </button>
                <button className={`mt-2 mt-md-0 ${styles.confirmBtn}`}>
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DeleteTracker;
