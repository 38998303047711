import styles from "./TrackerRow.module.scss";
const TrackerRow = () => {
  return (
    <>
      <div className={`${styles.tableRow}`}>
        <div className={`d-flex ${styles.widthSet1}`}>
          <div className="form-check form-switch me-2">
            <input className={`form-check-input`} type="checkbox" />
          </div>
        </div>
        <div className={`d-flex  ${styles.widthSet2}`}>
          <span className={`${styles.rowName}`}>Same value</span>
        </div>
        <div className={`d-flex ${styles.widthSet3}`}>
          <div className={`d-flex justify-content-between w-100`}>
            <div>
              <span className={`${styles.seeSpan}`}>Reports</span>
              <span className="mx-2">|</span>
              <span className={`${styles.seeSpan}`}>Archives</span>
            </div>
            <div>
              <div className="dropdown">
                <button
                  className={`dropdown-toggle ${styles.dropBtn}`}
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="fa-solid fa-ellipsis"></i>
                </button>
                <ul
                  className={`dropdown-menu ${styles.dropBox}`}
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <a className={`dropdown-item ${styles.details}`} href="#">
                      Details
                    </a>
                  </li>
                  <li>
                    <a className={`dropdown-item ${styles.delete}`} href="#">
                      Delete
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default TrackerRow;
