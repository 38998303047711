import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { fetchDeleteEmailIntegration } from "src/store/slices/emailIntegration/emailIntegration.thunks";
import { useAppDispatch, useAppSelector } from "src/store/store";
import style from "./DeleteIntegration.module.scss";
import { Spinner } from "react-bootstrap";
import { fetchUser } from "src/store/slices/settings/profile/profileSettings.slice";

function DeleteIntegration({ onHide }: any) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const emailIntegrationId = useAppSelector((state) => {
    return state.emailIntegration.selectedUser.emailIntegrationId;
  });

  const email = useAppSelector((state) => {
    return state.emailIntegration.selectedUser.emailEnteredByUser;
  });

  const [loader, setLoader] = useState(false);

  const clickHandler = useCallback(async () => {
    try {
      setLoader(true);
      await dispatch(fetchDeleteEmailIntegration({ emailIntegrationId }));
      dispatch(fetchUser());
      navigate("/settings/integrations/email");
    } catch (e) {
      const message =
        e instanceof Error ? e.message : "Something unexpected occurred!";
      pushTheToast({
        position: "top-right",
        text: message,
        type: "danger",
      });
    } finally {
      setLoader(false);
    }
  }, [dispatch, emailIntegrationId, navigate]);

  return (
    <div
      className={`d-flex-column justify-content-center text-center m-4 px-3 py-3`}
    >
      <div
        className={`d-flex justify-content-center ${style.deleteModalHeader}`}
      >
        Delete "{email}" Integration ?
      </div>
      <div className={`d-flex justify-content-center my-3 ${style.subText}`}>
        This will discard all the information related to this E-mail
      </div>

      <div className="d-flex justify-content-center pt-3">
        <div>
          <button
            className={`${style.verifyBtnEmpty} bg-white me-3`}
            onClick={() => onHide(true)}
          >
            Cancel
          </button>
        </div>
        <div>
          <button
            className={`${style.verifyBtn} ms-3 `}
            onClick={clickHandler}
            disabled={loader}
          >
            Confirm
            {loader && (
              <Spinner
                size="sm"
                animation="border"
                className="mx-2"
              />
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

export default DeleteIntegration;
