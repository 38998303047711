import styles from "./TransferTicket.module.scss";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  ITransferTicketApi,
  transferTicketApi,
} from "src/apis/transferTicket/insert/transferTicket.api";
import {
  globalLoaderProgress,
  showGlobalLoader,
} from "src/components/GlobalLoader/GlobalLoader";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { fetchMultipleSidebarData } from "src/store/slices/ticketSidebar/ticketSidebar.slice";
import { fetchTickets } from "src/store/slices/tickets/tickets.slice";
import { useAppDispatch } from "src/store/store";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "src/components/Loader";
import { readTicketLinkURLData } from "src/apis/transferTicket/read/transferTicket.read.api";
import SendingInfo from "src/components/SendingInfo";
import { QuillEditor } from "src/components/QuillEditor";
import { v4 as uuid } from "uuid";
import { validateEmail } from "src/utils/utils";
import TinyMCEContainer from "src/components/TinyMCEContainer";

function TransferTicket() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [showModel, setShowModel] = useState(false);
  const [showTheError, setShowTheError] = useState(false);
  const [ticketTitle, setTicketTitle] = useState("");
  const [ticketLink, setTicketLink] = useState("");
  const [ticketMsg, setTicketMsg] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [linkDataFetched, setLinkDataFetched] = useState("");
  const transferTicketModal: any = useRef(null);
  const linkRef = useRef("");
  const onHideModel = useCallback(() => {
    setShowModel(false);
  }, []);
  const validateInput = (ticketData: ITransferTicketApi) => {
    let showError = false;
    let errorText = "All the fields are required";
    if (ticketData.ticket_last_message.length === 0) {
      showError = true;
    } else if (ticketData.ticket_title.length === 0) {
      showError = true;
    } else if (ticketData.customer_email.length === 0) {
      showError = true;
    }

    if (showError) {
      setShowTheError(true);
      pushTheToast({
        type: "warning",
        text: errorText,
        position: "top-right",
      });
    }
    return showError;
  };
  const transferTicketSubmit = useCallback((e: any) => {
    e.preventDefault();
    const ticketData: ITransferTicketApi = {
      ticket_link: e.target.elements.ticket_link.value.trim(),
      ticket_last_message: e.target.elements.ticket_last_message.value.trim(),
      ticket_title: e.target.elements.ticket_title.value.trim(),
      customer_nicename: e.target.elements.customer_nicename.value.trim(),
      customer_email: e.target.elements.customer_email.value.trim(),
    };
    if (validateInput(ticketData)) {
      return;
    }
    showGlobalLoader("TransferTicket");
    transferTicketApi(ticketData, (progress: number) => {
      globalLoaderProgress(progress);
    })
      .then((res) => {
        if (!res.err) {
          // dispatch(fetchMultipleSidebarData());
          dispatch(fetchTickets());
          onHideModel();
          setTicketTitle("");
          setCustomerName("");
          setCustomerEmail("");
          setLinkDataFetched("");
          showGlobalLoader("None");
          navigate(
            `${location.pathname}/ticket/${res.data.id}/${window.location.search}`.replace(
              "//",
              "/"
            )
          );
        } else {
          showGlobalLoader("None");
          let displayableError = res?.displayableError === true ? true : false;
          if (displayableError) {
            pushTheToast({
              type: "danger",
              text: res.msg,
              position: "top-right",
            });
          } else {
            pushTheToast({
              type: "danger",
              text: "Error transferring ticket!",
              position: "top-right",
            });
          }
        }
      })
      .catch((err) => {
        showGlobalLoader("None");
        pushTheToast({
          type: "danger",
          text: err,
          position: "top-right",
        });
      });
  }, []);
  const getLinkData = useCallback((e: any) => {
    if (e.target.value.trim() === linkRef.current.trim()) {
    } else if (e.target.value.trim() !== "") {
      setLinkDataFetched("pending");
      setShowTheError(false);
      readTicketLinkURLData({ ticket_link: e.target.value })
        .then((res: any) => {
          if (res.data.isTicketExist) {
            setTicketTitle(res.data.ticketSubject);
            setCustomerName(res.data.customerName);
            setCustomerEmail(res.data.customerEmail);
            setLinkDataFetched("exists");
          } else {
            setTicketTitle("");
            setCustomerName("");
            setCustomerEmail("");
            setLinkDataFetched("notexist");
          }
        })
        .catch((err) => {
          setTicketTitle("");
          setCustomerName("");
          setCustomerEmail("");
          setLinkDataFetched("notexist");
        });
    } else {
      setTicketTitle("");
      setCustomerName("");
      setCustomerEmail("");
      setLinkDataFetched("");
    }
    linkRef.current = e.target.value.trim();
  }, []);

  return (
    <>
      <button
        className="transferTicket mb-2"
        onClick={() => {
          setShowModel(true);
        }}
        ref={transferTicketModal}
        id="transferTicketBtn"
      >
        <span className="me-1">
          <i className="fa-solid fa-download" />
        </span>
        Transfer Ticket
      </button>
      {/* transferTicket Modal */}
      <Modal
        className="transerTickModal"
        show={showModel}
        onHide={onHideModel}
        enforceFocus={false}
        dialogClassName={`${styles.dialogClass}`}
        contentClassName={`${styles.contentClass}`}
        backdropClassName={`${styles.backDropClass}`}
        id="transferTicketModal"
      >
        <ModalHeader id="transferTicketModal__header">
          <h5 className={`modal-title ${styles.transferHead}`}>
            <span className={`me-2 ${styles.transferIcon}`}>
              <i className="fa-solid fa-file-import" />
            </span>
            Transfer Ticket
          </h5>
          <div id="transferTicketModal__close">
            <span className={`${styles.btnClose}`} onClick={onHideModel}>
              <i className="fa-solid fa-xmark"></i>
            </span>
          </div>
        </ModalHeader>
        <ModalBody id="transferTicketModal__body">
          <form onSubmit={transferTicketSubmit}>
            <div className="mb-3 row" id="transferTicketModal__link">
              <label className={`col-sm-3 col-form-label ${styles.formLabel}`}>
                Enter ticket link:
              </label>
              <div className="col-sm-9">
                <input
                  type="text"
                  className={`form-control ${styles.override_border} ${styles.inputBox}`}
                  name="ticket_link"
                  required={true}
                  value={ticketLink}
                  onChange={(e) => {
                    setTicketLink(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                    }
                  }}
                  onKeyUp={(e: any) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      if (e.target.value.trim() !== "") {
                        e.target.blur();
                      }
                    }
                  }}
                  onBlur={getLinkData}
                />
              </div>
            </div>
            {linkDataFetched === "exists" || linkDataFetched === "notexist" ? (
              <>
                <div className="mb-3 row" id="transferTicketModal__msg">
                  <label
                    htmlFor="message"
                    className={`col-sm-3 col-form-label ${styles.formLabel}`}
                  >
                    Enter Customer's last message:
                  </label>
                  <div className="col-sm-9">
                    <TinyMCEContainer
                      className={`form-control p-0 ${styles.override_border} ${
                        showTheError && ticketMsg.trim() === ""
                          ? "redBorder"
                          : ""
                      }`}
                      autosavePrefix={`HELPDESK_TRANSFER_TICKET`}
                      // editorClassName={`${styles.ta}`}
                      options={{
                        max_height: 300,
                        min_height: 300,
                      }}
                      name="ticket_last_message"
                      value={ticketMsg}
                      onChange={(value) => {
                        setTicketMsg(value);
                      }}
                      showToolbar={false}
                      uniqueID={uuid()}
                    />
                  </div>
                </div>
                <div className="mb-3 row" id="transferTicketModal__subject">
                  <label
                    className={`col-sm-3 col-form-label ${styles.formLabel}`}
                  >
                    Enter Ticket Subject:
                  </label>
                  <div className="col-sm-9">
                    <input
                      data-lpignore="true"
                      type="text"
                      className={`form-control ${
                        showTheError && ticketTitle.trim() === ""
                          ? "redBorder"
                          : ""
                      }`}
                      name="ticket_title"
                      readOnly={linkDataFetched === "exists"}
                      value={ticketTitle}
                      onChange={(e: any) => {
                        setTicketTitle(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="mb-3 row" id="transferTicketModal__custName">
                  <label
                    className={`col-sm-3 col-form-label ${styles.formLabel}`}
                  >
                    Customer Name:
                  </label>
                  <div className="col-sm-9">
                    <input
                      data-lpignore="true"
                      type="text"
                      className="form-control"
                      name="customer_nicename"
                      required={true}
                      readOnly={linkDataFetched === "exists" || true}
                      value={customerName}
                      onChange={(e: any) => {
                        setCustomerName(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="mb-3 row" id="transferTicketModal__custEmail">
                  <label
                    className={`col-sm-3 col-form-label ${styles.formLabel}`}
                  >
                    Customer E-mail Address:
                  </label>
                  <div
                    className={`col-sm-9 ${
                      showTheError && !validateEmail(customerEmail)
                        ? styles.redBorder
                        : ""
                    }`}
                  >
                    <SendingInfo
                      name="customer_email"
                      addUser={true}
                      placeholder={"Enter customer email..."}
                      getCustomerName={setCustomerName}
                      getValue={setCustomerEmail}
                      currentValue={customerEmail}
                      isDisabled={linkDataFetched === "exists"}
                    />
                  </div>
                </div>
                <div
                  className="d-flex justify-content-end"
                  id="transferTicketModal__submit"
                >
                  <button className={`${styles.transferSubmit}`}>
                    Submit
                    <span className="ms-2">
                      <i className="fas fa-chevron-right" />
                    </span>
                  </button>
                </div>
              </>
            ) : linkDataFetched === "pending" ? (
              <div className={`d-flex flex-column`}>
                <Loader className={`m-auto ${styles.loading}`} />
                <span className={`${styles.loadingText}`}>
                  Fetching ticket details...
                </span>
              </div>
            ) : (
              ""
            )}
          </form>
        </ModalBody>
      </Modal>
    </>
  );
}
export default TransferTicket;
