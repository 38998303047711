import { useEffect, useMemo, useRef, useState } from "react";
import styles from "./RecentlyUsed.module.scss";
import {
  IGetRecentlyUsedTemplate,
  IGetTemplateResponse,
  getRecentlyUsedTemplates,
} from "src/services/Templates/getRecentlyUsedTemplates";
import { useAppSelector } from "src/store/store";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import ShowTemplate from "../../../SearchedTemplates/Children/ShowTemplate/ShowTemplate";
import InfiniteScroll from "src/components/InfiniteScroll";
import Loader from "src/components/Loader";
import { useGetRecentTemplates } from "../../../CustomHook/useGetRecentTemplates";

const RecentlyUsed = ({
  setSelectedTemplateData = (template: any) => {},
  onTemplateSelect = (template: string) => {},
}: {
  setSelectedTemplateData: any;
  onTemplateSelect: any;
}) => {
  const activeTicketId = useAppSelector((state) => {
    return state.innerTicket.activeTicketInfo.ticket_id;
  });
  // const [templates, setTemplates] = useState<IGetTemplateResponse[]>([]);
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const fetchResponseRef = useRef<any>({});
  const [fetchTemplateResponse, setFetchTemplateResponse] = useState<any>({
    start: null,
    count: null,
    totalCount: null,
  });

  const filters = useMemo(() => {
    return {
      start: 0,
      limit: 25,
      ticketId: activeTicketId,
    };
  }, [activeTicketId]);

  const {
    status,
    templateIds,
    templates,
    hasNextPage,
    fetchNextPage,
    fetchStatus,
    isLoading,
  } = useGetRecentTemplates(filters);

  const errorMessage = useMemo(() => {
    if (
      status === "error" ||
      (status !== "success" && fetchStatus !== "fetching") ||
      (status !== "loading" && templateIds.length === 0)
    ) {
      return (
        <span className={`ps-2 ${styles.noResultFound}`}>No result found</span>
      );
    }
  }, [status, fetchStatus, templateIds.length]);

  return (
    <>
      <div
        className={`p-2 ${styles.templateWrapper}`}
        style={{ height: "59vh" }} id="recentlyViewed"
      >
        <div className="h-100">
          {activeTicketId && isLoading === true && templateIds.length === 0 ? (
            <>
              <Loader />
            </>
          ) : (
            <>
              <InfiniteScroll
                style={{ height: "55vh", paddingTop: "20px" }}
                loadMore={fetchNextPage}
                hasMore={hasNextPage}
                initialDataLoaded={true}
              >
                {errorMessage
                  ? errorMessage
                  : templateIds.map((templateId) => {
                      const template = templates[templateId];
                      return (
                        <ShowTemplate
                          template={template}
                          display={"d-block"}
                          setSelectedTemplateData={(template: any) => {
                            setSelectedTemplateData(template);
                          }}
                          onTemplateSelect={(template: string) => {
                            onTemplateSelect(template);
                          }}
                        />
                      );
                    })}
              </InfiniteScroll>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default RecentlyUsed;
