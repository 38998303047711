import { axiosJSON } from "src/globals/axiosEndPoints";

export interface IDeleteSnooze {
  id: number;
}
const statusCodeMsg:any = {
  "snoozeNotFound": "Snooze Already Removed",
}
export async function deleteSnooze(params: IDeleteSnooze) {
  const { data: res } = await axiosJSON.delete(
    `/api/ticket/snooze/delete?id=${params.id}`
  );
  let defaultErrorMsg = "Something Went Wrong";
  if (res.error === true) {
    if(res?.statusCode){
      if(statusCodeMsg[res.statusCode] != undefined){
        defaultErrorMsg = statusCodeMsg[res.statusCode];
      }
      throw new Error(defaultErrorMsg);
    }
  }
  return true;
}
