import React, { useCallback, useState } from "react";
import { Dropdown } from "react-bootstrap";

import { setActiveFeaturedArticleData } from "src/store/slices/liveChatSetting/chatWidgetSetting/knowledgeBased/knowledgeBasedSetting.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { validateURL } from "src/utils/validations";
import { AddFeatureModalTypes } from "../../../AddFeaturesModal";
import styles from "./UrlAppear.module.scss";
interface Props {
  onHide: () => void;
  setShowComponent: (value: AddFeatureModalTypes) => void;
}
const UrlAppear = ({ onHide, setShowComponent }: Props) => {
  const { domainUrl, domainConstraint } = useAppSelector(
    (state) => state.knowledgeBasedSettings.activeFeaturedArticle
  );
  const dispatch = useAppDispatch();
  const [showDomainUrlError, setShowDomainUrlError] = useState("");
  const handleBack = useCallback(() => {
    setShowComponent("initial");
  }, []);

  const handleNext = useCallback(() => {
    if (domainUrl.trim().length !== 0 && validateURL(domainUrl.trim())) {
      setShowComponent("featuredArticle");
    } else {
      setShowDomainUrlError("Please enter valid url");
    }
  }, [domainUrl]);

  //helper function handles on change of domain constraint
  const handleOnChangeDomainConstraint = useCallback((value: string) => {
    if (value === "contains") {
      dispatch(setActiveFeaturedArticleData({ domainConstraint: value }));
    } else {
      dispatch(setActiveFeaturedArticleData({ domainConstraint: "isExactly" }));
    }
  }, []);

  const handleOnChangeDomainUrl = useCallback((e: any) => {
    dispatch(setActiveFeaturedArticleData({ domainUrl: e.target.value }));
  }, []);
  return (
    <div className="p-4">
      <div
        className={`d-flex justify-content-between  ${styles.modalHeaderBox} `}
      >
        <p className={`${styles.modalHead}`}>
          Please select which category you're adding the featured articles for?
        </p>
        {/* Hiding it as it is not there as per xd */}
        {/* <span className={`${styles.close}`} onClick={onHide}>
          <i className="fa-solid fa-xmark"></i>
        </span> */}
      </div>
      <div className={`p-2 ${styles.urlBox}`}>
        <div className="d-flex flex-wrap flex-md-nowrap align-items-center w-100">
          <span className={`pe-2 mb-2 mb-md-0 d-inline-block ${styles.url}`}>
            URL
          </span>
          <div
            className={`position-relative mb-2 mb-md-0 ${styles.containBox}`}
          >
            <select
              className={`form-select `}
              aria-label="Default select example"
              value={domainConstraint}
              defaultValue={"contains"}
              onChange={(e) => handleOnChangeDomainConstraint(e.target.value)}
            >
              <option selected value="contains">
                contains
              </option>
              <option value="isExactly">is exactly</option>
            </select>
            <span className={`px-1 ${styles.arrow}`}>
              <i className="fa-solid fa-caret-down"></i>
            </span>
          </div>
          <div className="w-100">
            <input
              className={`px-2 mb-2 mb-md-0 ${styles.mailBox}`}
              placeholder="https://acme.com/products"
              value={domainUrl}
              onChange={handleOnChangeDomainUrl}
            />
            <span className={`${styles.errorMsg}`}>{showDomainUrlError}</span>
          </div>
        </div>
      </div>
      <div className={`mt-4 ${styles.actionBtn}`}>
        <button
          className={`d-flex justify-content-center align-items-center ${styles.nextBtn}`}
          onClick={handleBack}
        >
          <span className={`ps-1 pe-2 ${styles.arrowLine}`}>
            <i className="fa-solid fa-chevron-left"></i>
          </span>
          <span> Back</span>
        </button>
        <button
          className={`d-flex justify-content-center align-items-center ${styles.nextBtn}`}
          onClick={handleNext}
        >
          <span> Next</span>
          <span className={`ps-2 pe-1 ${styles.arrowLine}`}>
            <i className="fa-solid fa-chevron-right"></i>
          </span>
        </button>
      </div>
    </div>
  );
};

export default UrlAppear;
