import { useCallback, useMemo, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import styles from "./NonValue.module.scss";
import stepDetailsStyles from "../../../../StepDetails/StepDetails.module.scss";

function NonValue({
  value,
  onChange,
  showErrors,
  heading,
  subHeading,
  placeholder = "Write your message...",
}: {
  value: string;
  onChange: (val: string) => void;
  showErrors: boolean;
  heading: string;
  subHeading: string;
  placeholder?: string;
}) {
  const isError = useMemo(() => {
    return !value.trim() && showErrors;
  }, [showErrors, value]);

  /**
   * Handles changes in the text input field.
   *
   * @param e - The change event from the input field.
   */
  const handleChange: React.ChangeEventHandler<HTMLTextAreaElement> =
    useCallback(
      (e) => {
        onChange(e.target.value);
      },
      [onChange, value],
    );

  return (
    <div
      className={`mt-3 pb-2 pt-2 ${stepDetailsStyles.query} ${
        styles.reasonMainBox
      } ${value.trim() ? stepDetailsStyles.activeLine : ""} ${
        isError ? stepDetailsStyles.errorLine : ""
      }`}
    >
      <p className={`mb-0`}>
        <span className={`mt-2 ${styles.reasonMainHeading}`}>{heading}</span>
      </p>
      <p className={`mb-0`}>
        <span className={`${styles.reasonSubHeading}`}>{subHeading}</span>
      </p>
      <div>
        <Form.Group
          className={`mb-2 mt-2 ${styles.textareaWrapper}`}
          controlId="exampleForm.ControlTextarea1"
        >
          <Form.Control
            as="textarea"
            style={{
              resize: "none",
            }}
            rows={4}
            placeholder={placeholder}
            className={`${isError ? "border-danger shadow-none" : ""}`}
            onChange={handleChange}
            value={value}
          />
        </Form.Group>
      </div>
      {isError && (
        <span className={`${styles.errorText}`}>Please fill this field</span>
      )}
    </div>
  );
}

export default NonValue;
