/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useRef } from "react";
import AxiosImg from "src/components/AxiosImg";
import InfiniteScroll from "src/components/InfiniteScroll";
import Loader from "src/components/Loader";
import SearchBar from "src/components/SearchBar/SearchBar";
import UserAvatar from "src/components/UserAvatar";
import {
  actions,
  areAllAgentSelectedForAssignToFilter,
  isAgentIdSelectedForAssignToFilterSelector,
} from "src/store/slices/liveChatSetting/chatWidExchCust/chatWidExchCust.slice";
import { fetchAllAssignToAgentsListForFiltering } from "src/store/slices/liveChatSetting/chatWidExchCust/chatWidExchCust.thunks";
import { useAppDispatch, useAppSelector } from "src/store/store";
import styles from "./AssignChatToAgentList.module.scss";
import { Spinner } from "react-bootstrap";
interface UserItemProp {
  user: any;
}

// Agent List Item Component
const AgentListItem = ({ user }: UserItemProp) => {
  const dispatch = useAppDispatch();

  const isAgentSelected = useAppSelector((state) =>
    isAgentIdSelectedForAssignToFilterSelector(state, user.id)
  );

  const handleOnChangeCheckbox = () => {
    if (isAgentSelected) {
      dispatch(actions.removeSelectedAgentForAssignToFilter(user.id));
    } else {
      dispatch(actions.selectedAgentForAssignToFilter(user.id));
    }
  };

  return (
    <div
      className={`mt-1 py-1 d-flex align-items-center ${
        isAgentSelected ? styles.addBoxSelected : styles.addBox
      }`}
      onClick={handleOnChangeCheckbox}
    >
      {/* <input
        checked={isAgentSelected}
        className="form-check-input"
        type={"checkbox"}
        onChange={() => {}}
      /> */}
      <div>
        <input
          checked={isAgentSelected}
          type={"checkbox"}
          onChange={() => {}}
          className={`form-check-input ${styles.customCheck}`}
        />
      </div>

      <span className={`ps-2 d-flex align-items-center`}>
        <span className="me-2">
          {user.imageURL && user.imageURL.length !== 0 ? (
            <AxiosImg
              url={user.imageURL}
              className={`${styles.dropdownItemProfileImg}`}
              isDirectURL={user.isPublicAttachmentUrl}
            />
          ) : (
            <UserAvatar name={user.name || "NA"} size={25} />
          )}
        </span>
        <div className={`${styles.infoWrapper}`}>
          <div className={`text-truncate ${styles.agentName}`}>{user.name}</div>
          <div className={`text-truncate ${styles.agentEmail}`}>
            {user?.email}
          </div>
        </div>
      </span>
    </div>
  );
};

const AssignChatToAgentList = ({
  targetRef,
  searchText,
  setSearchText,
  showSaveLoader,
  handleOnApply,
}: any) => {
  const { agentIds, agents, agentListAJAXStatus, agentMeta } = useAppSelector(
    (state) => state.chatWidExchCust
  );
  const dispatch = useAppDispatch();

  const areAllAgentSelected = useAppSelector(
    areAllAgentSelectedForAssignToFilter
  );

  const currentState = useRef({
    initialSearch: true,
    agentListAJAXStatus: agentListAJAXStatus,
    searchText: searchText,
  });

  useEffect(() => {
    currentState.current.agentListAJAXStatus = agentListAJAXStatus;
    currentState.current.searchText = searchText.trim();
  }, [agentListAJAXStatus, searchText]);

  useEffect(() => {
    dispatch(actions.resetAssignToAgentListForFilters());
    dispatch(
      fetchAllAssignToAgentsListForFiltering({
        searchTerm: currentState.current.searchText,
      })
    );
  }, []);

  /* Handle infinite scroll */
  const handleInfiniteScroll = useCallback(() => {
    if (currentState.current.agentListAJAXStatus === "fulfilled") {
      dispatch(
        fetchAllAssignToAgentsListForFiltering({
          searchTerm: currentState.current.searchText,
        })
      );
    }
  }, []);

  /* Handle search */
  const handleSearch = useCallback(() => {
    if (
      currentState.current.initialSearch === false &&
      currentState.current.agentListAJAXStatus === "fulfilled"
    ) {
      dispatch(actions.resetAssignToAgentListForFilters());
      dispatch(
        fetchAllAssignToAgentsListForFiltering({
          searchTerm: currentState.current.searchText,
        })
      );
    }
    currentState.current.initialSearch = false;
  }, []);

  // useEffect(() => {
  //   /**
  //    * close dropdown if clicked on outside of element
  //    */
  //   function handleClickOutside(event: any) {
  //     console.log("This one is triggered");
  //     if (targetRef.current && !targetRef.current?.contains(event.target)) {
  //       dispatch(resetAssignToAgentList());
  //       dispatch(
  //         actions.removeAllAgentForAssignToFilter({
  //           initialValue: selectedAgents,
  //         })
  //       );
  //     }
  //   }
  //   setTimeout(() => {
  //     // Bind the event listener
  //     document.addEventListener("click", handleClickOutside);
  //   }, 0);
  //   return () => {
  //     // Unbind the event listener on clean up
  //     document.removeEventListener("click", handleClickOutside);
  //   };
  // }, [targetRef]);

  const handleOnSelectAll = useCallback(() => {
    if (areAllAgentSelected) {
      dispatch(actions.removeAllAgentForAssignToFilter({ initialValue: [] }));
    } else {
      dispatch(actions.selectAllAgentForAssignToFilter());
    }
  }, [areAllAgentSelected]);

  // Has More calculated at each status change to trigger Infinite scroll
  const hasMore = useMemo(() => {
    return agentListAJAXStatus === "rejected"
      ? false
      : agentMeta.total === null || agentMeta.total === 0
      ? true
      : agentIds.length < agentMeta.total;
  }, [agentListAJAXStatus, agentMeta.total, agentIds.length]);

  return (
    <div className={`${styles.assignDropDown}`} style={{ height: "228px" }}>
      <div className={`${styles.searchMain}`}>
        <SearchBar
          value={searchText}
          className={`${styles.search}`}
          inputClassName={`${styles.input}`}
          placeholder={`Search assignee`}
          onChange={(e: any) => {
            setSearchText(e.target.value);
          }}
          onSearch={handleSearch}
        />
      </div>
      {agentIds.length !== 0 && (
        <div
          className={`mt-2 mb-1 d-flex align-items-center ${styles.selectAll}`}
        >
          <input
            id={"assignChatSelectAll"}
            checked={areAllAgentSelected}
            type={"checkbox"}
            onChange={handleOnSelectAll}
            className={`form-check-input ${styles.customCheck}`}
          />
          <label
            role="button"
            className={`mb-0 ${styles.selectAllText} ps-2`}
            htmlFor={"assignChatSelectAll"}
          >
            Select All
          </label>
        </div>
      )}

      <div className={`${styles.userListContainer} text-start`}>
        <InfiniteScroll
          className={`${styles.infScroll}`}
          loadMore={handleInfiniteScroll}
          initialDataLoaded={false}
          hideClassDisplayFlex={true}
          hasMore={hasMore}
          loader={
            agentListAJAXStatus === "pending" && agentIds.length === 0 ? (
              <Loader
                style={{
                  marginTop: "-90px",
                }}
              />
            ) : agentListAJAXStatus === "pending" ? (
              // Loading State
              <div className={`${styles.loadingText} mt-2`}>
                <span>Loading...</span>
                <div>
                  <Spinner
                    className={`ms-1 ${styles.spinner}`}
                    size="sm"
                    animation="border"
                  />
                </div>
              </div>
            ) : (
              // Load More Button
              <div
                className={`${styles.loaderText} mt-2`}
                onClick={handleInfiniteScroll}
              >
                <span>Load More</span>
                <i className="ms-1 fa-solid fa-rotate-right"></i>
              </div>
            )
          }
          errorMessage={
            agentIds.length === 0 &&
            (agentListAJAXStatus === "rejected" ||
              agentListAJAXStatus === "fulfilled") ? (
              <div
                className={`m-auto ${styles.noAgentFound} ${styles.loaderCenter} text-center`}
              >
                No Agent Found
              </div>
            ) : undefined
          }
        >
          {agentIds.map((agentId) => {
            const agent = agents[agentId];
            return <AgentListItem key={agent.id} user={agent} />;
          })}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default AssignChatToAgentList;
