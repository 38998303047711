import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { IUpdateTrackingParams } from "src/services/ShopifySidebar/SingleOrder/FulFillments/updateTracking";
import { updateTrackingThunk } from "src/store/slices/singleOrder/order.thunks";
import { useAppDispatch, useAppSelector } from "src/store/store";
import styles from "./EditTrackingModal.module.scss";

const EditTrackingModal = ({
  orderId,
  orderItemsInStatusGroup,
  showEditTrackingModal,
  setShowEditTrackingModal,
}: {
  orderId: number | undefined;
  orderItemsInStatusGroup: any;
  showEditTrackingModal: boolean;
  setShowEditTrackingModal: (showEditTrackingModal: boolean) => void;
}) => {
  const dispatch = useAppDispatch();
  const { updateTracking } = useAppSelector((state) => state.singleOrder);
  const [trackingNumberInput, setTrackingNumberInput] = useState<string>("");
  const [shippingCarrierInput, setShippingCarrierInput] = useState<string>("");
  const [notifyCustomer, setNotifyCustomer] = useState<boolean>(false);

  useEffect(() => {
    if (
      orderItemsInStatusGroup &&
      orderItemsInStatusGroup?.status &&
      orderItemsInStatusGroup?.status?.trackingInfo
    ) {
      if (orderItemsInStatusGroup?.status?.trackingInfo?.number) {
        setTrackingNumberInput(
          orderItemsInStatusGroup?.status?.trackingInfo?.number
        );
      }
      if (orderItemsInStatusGroup?.status?.trackingInfo?.company) {
        setShippingCarrierInput(
          orderItemsInStatusGroup?.status?.trackingInfo?.company
        );
      }
    }
  }, [orderItemsInStatusGroup]);

  const handleOnHideEditTrackingModal = () => {
    setShowEditTrackingModal(false);
  };

  // save tracking updates
  const handleSaveEditTracking = (e: any) => {
    if (updateTracking?.updateTrackingAjaxStatus !== "pending") {
      if (orderId && orderItemsInStatusGroup?.status?.fulfillmentId) {
        let updateTrackingParams: IUpdateTrackingParams = {
          orderId: orderId,
          fulfillmentId: orderItemsInStatusGroup.status.fulfillmentId,
          notifyCustomer: notifyCustomer,
          trackingNumber: trackingNumberInput.trim(),
          shippingCarrier: shippingCarrierInput.trim(),
        };
        dispatch(
          updateTrackingThunk({
            updateParams: updateTrackingParams,
            onSuccessCallBack: () => {handleOnHideEditTrackingModal()},
          })
        );
      } else {
        pushTheToast({
          type: "danger",
          text: "Something went wrong!",
          position: "top-right",
        });
      }
    }
  };

  return (
    <Modal
      show={showEditTrackingModal}
      onHide={handleOnHideEditTrackingModal}
      className={`shadow-sm p-3 mb-5  rounded ${styles.editTrackingModal}`}
      backdropClassName={styles.backdropEditTrackingModal}
      dialogClassName={styles.modalDialogEditTrackingModal}
      contentClassName={styles.modalContentEditTrackingModal}
      centered={true}
      enforceFocus={true}
    >
      <Modal.Header closeButton>
        <Modal.Title className={`${styles.editTrackingText} text-dark fs-6`}>
          Edit tracking
        </Modal.Title>
      </Modal.Header>

      <Modal.Body
        className={`d-flex flex-column justify-content-between ${styles.editTrackingText}`}
      >
        <div>
          <div>
            <form className={`${styles.editTrackingText}`}>
              <div className="form-group">
                <div className={`d-flex`}>
                  <div className={`d-flex flex-column me-2 w-50`}>
                    <label htmlFor="trackingNumInput" className="text-dark ">
                      Tracking Number{" "}
                    </label>

                    <input
                      type="text"
                      className="form-control"
                      aria-describedby="trackingNumInput"
                      value={trackingNumberInput}
                      onChange={(e) => setTrackingNumberInput(e.target.value)}
                    />
                  </div>
                  <div className={`d-flex flex-column ms-2 w-50`}>
                    <label
                      htmlFor="shippingCarrierInput"
                      className="text-dark "
                    >
                      Shipping Carrier{" "}
                    </label>

                    <input
                      type="text"
                      className="form-control"
                      aria-describedby="shippingCarrierInput"
                      value={shippingCarrierInput}
                      onChange={(e) => setShippingCarrierInput(e.target.value)}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-start mt-3 ">
                  <Form.Check
                    type="checkbox"
                    className="me-2 "
                    checked={notifyCustomer}
                    onClick={(e: any) => setNotifyCustomer(e.target.checked)}
                  />
                  <div className=" small ">
                    Send notification email to customer{" "}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div
          className={`d-flex  justify-content-end  w-100 ${styles.editTrackingText} `}
        >
          <div className="d-flex ">
            <div className="me-1 ">
              <Button
                variant="outline-secondary"
                className="text-dark"
                onClick={() => handleOnHideEditTrackingModal()}
              >
                Cancel
              </Button>
            </div>

            <div>
              <Button
                variant="outline-light border"
                className="text-white "
                style={{ backgroundColor: "#2b7554" }}
                onClick={(e: any) => {
                  handleSaveEditTracking(e);
                }}
              >
                {updateTracking?.updateTrackingAjaxStatus === "pending" ? (
                  <span>
                    <Spinner
                      className={`m-auto`}
                      animation="border"
                      size="sm"
                      color="white"
                    />
                    Saving
                  </span>
                ) : (
                  "Save"
                )}{" "}
              </Button>{" "}
            </div>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default EditTrackingModal;
