import { axiosJSON } from "src/globals/axiosEndPoints";

export interface WebhookInfo {
  label: string;
  key: string;
  enabled: boolean;
  hasPermission: boolean;
}
export interface FacebookPageInfo {
  id: number | string;
  name: string;
  category: string;
  integrated: boolean;
  imageUrl?: string | null;
  webhooks: Array<WebhookInfo>;
  facebookAccount?: {
    id: string;
    name: string;
    imageUrl?: string | null;
  };
  brand?: {
    id: string;
    name: string;
    email: string;
    imageUrl?: string | null;
  };
}

export interface GetFacebookPageWebhooksPayload {
  pageId: string | number;
}
export async function getFacebookPageWebhooks(
  payload: GetFacebookPageWebhooksPayload,
) {
  const { data: res } = await axiosJSON.post(
    `/api/setting/integration/facebook/getPageWebhooks`,
    payload,
  );
  if (res.err) {
    throw "Something went wrong!";
  }

  return res.data as FacebookPageInfo;
}
