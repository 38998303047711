import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import log from "loglevel";
import { RootState } from "src/store/store";
import { AjaxError } from "src/types/AjaxError";
import TicketService from "src/services/TicketService/TicketService";
import UserService from "src/services/UserService";
import { getSelectedUser } from "./ticketUsers.selectors";
import { AssignTicketsToPayload } from "src/services/TicketService/assignTicketsTo";
import { getTicketListFilterQuery } from "../tickets/tickets.selectors";
import { setBulkUpdatedTicketCount } from "../tickets/tickets.slice";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";

export type User = {
  id: number;
  email: string;
  name: string;
  imageUrl?: string;
  status?: string;
  isPublicAttachmentUrl?: boolean;
};

type TicketUsersSlice = {
  userSelected: number;
  allUsers: User[];
  bulkAssignTicketsState: "idle" | "pending" | "fulfilled" | "rejected";
  fetchAllUsersState: "idle" | "pending" | "fulfilled" | "rejected";
};

const initialState: TicketUsersSlice = {
  userSelected: -1,
  allUsers: [],
  bulkAssignTicketsState: "idle",
  fetchAllUsersState: "idle",
};

export const bulkAssignTickets = createAsyncThunk(
  "ticket/bulkAssignTickets",
  async (payload: undefined, { getState, dispatch }: { getState: Function, dispatch: Function }) => {
    try {
      const currentState: RootState = getState();
      const servicePayload: AssignTicketsToPayload = {
        userId: currentState.ticketUsers.userSelected,
        ticketId: currentState.ticket.bulkSelectedTicketsIds,
      };
       //checking if all tickets selected in bulk action
       if(currentState.ticket.bulkActionFilters.selectedAllTickets === true){

        //deleting the ticketIds list from payload 
        delete servicePayload['ticketId'];

        //setting allTickets flag to true
        servicePayload["allTickets"] = true;

        const ticketsQuery = getTicketListFilterQuery(currentState);
        //adding ticket filters to payload
        servicePayload["filters"] = ticketsQuery['filters'];

        //adding flag in payload if it is a customer-view or not
        servicePayload["isCustomerView"] = currentState.ticket.currentView === "customer-view";
      }

      const bulkAssignTicketsResponse = await TicketService.assignTicketsTo(
        servicePayload
      );

      //checking if response has updatedTicket count if yes then update the count in redux to show on modal else set to 0
      if(bulkAssignTicketsResponse?.metaData?.updatedCount){

        dispatch(setBulkUpdatedTicketCount({count: bulkAssignTicketsResponse?.metaData?.updatedCount}))
      }else{
        dispatch(setBulkUpdatedTicketCount({count: 0}))

      }

      return bulkAssignTicketsResponse;
    } catch (error) {
      let ajaxError: AjaxError = {
        message: (error as Error).message,
      };
      return ajaxError;
    }
  }
);

export const fetchAllUsers = createAsyncThunk(
  "ticket/fetchAllUsers",
  async () => {
    try {
      const servicePayload = { start: 0, limit: 1000 };
      const fetchAllUsersResponse = await UserService.fetchAllUsers(
        servicePayload
      );
      return fetchAllUsersResponse;
    } catch (error) {
      let ajaxError: AjaxError = {
        message: (error as Error).message,
      };
      return ajaxError;
    }
  }
);

export const ticketUsersSlice = createSlice({
  name: "ticketTags",
  initialState,
  reducers: {
    selectUser: (state, action: PayloadAction<number>) => {
      const userId = action.payload;
      state.userSelected = userId;
      // log.debug(`selected user id: ${userId}`);
    },
    resetSelectedUser: (state) => {
      state.userSelected = -1;
    },
    resetBulkAssignTickets: (state) => {
      state.userSelected = -1;
      state.bulkAssignTicketsState = "idle";
      state.fetchAllUsersState = "idle";
      state.allUsers = [];
    },
  },
  extraReducers: (builder) => {
    /* 
    Reducers for bulk action for assigning tickets user
    */
    builder.addCase(bulkAssignTickets.fulfilled, (state, { payload }) => {
      state.bulkAssignTicketsState = "fulfilled";
      if(payload?.message){
        pushTheToast({
          type: "danger",
          text: payload.message,
          position: "top-right",
        });
      }
    });
    builder.addCase(bulkAssignTickets.rejected, (state) => {
      state.bulkAssignTicketsState = "rejected";
    });
    builder.addCase(bulkAssignTickets.pending, (state) => {
      state.bulkAssignTicketsState = "pending";
    });

    /* 
    Reducers for bulk action for fetching all users (agents)
    */
    builder.addCase(fetchAllUsers.fulfilled, (state, { payload }) => {
      state.allUsers = payload;
      state.fetchAllUsersState = "fulfilled";
    });
    builder.addCase(fetchAllUsers.rejected, (state) => {
      state.fetchAllUsersState = "rejected";
    });
    builder.addCase(fetchAllUsers.pending, (state) => {
      state.fetchAllUsersState = "pending";
    });
  },
});

export const { selectUser, resetSelectedUser, resetBulkAssignTickets } =
  ticketUsersSlice.actions;

export const getSelectedUserSelector = createSelector(
  [getSelectedUser],
  (getSelectedUser) => getSelectedUser
);

export default ticketUsersSlice.reducer;
