import styles from "./TimeLine.module.scss";

// to show a time line, pass each step text inside a array, current activeStep required to show the current Step, for the bottom text if you required any margin so pass it inside a array
interface TimeLineProps {
  stepText: Array<string>;
  activeStep: number;
  textMargin?: Array<string>;
}

const TimeLine = ({ stepText, activeStep, textMargin }: TimeLineProps) => {
  return (
    <div className="d-flex align-items-center justify-content-center mt-4">
      {stepText.map((step, index) => (
        <div
          className="d-flex flex-column"
          key={index}
        >
          <div className="d-flex align-items-center">
            <div>
              <span
                className={`${styles.stepCount} ${
                  index + 1 === activeStep ? styles.activeStepCount : ""
                }`}
              >
                {index + 1}
              </span>
            </div>
            {index + 1 < stepText.length && (
              <div className="d-flex align-items-center">
                <div className={`${styles.verticalLine}`}></div>
                <div className={`${styles.verticalLine}`}></div>
                <div className={`${styles.verticalLine}`}></div>
                <div className={`${styles.verticalLine}`}></div>
                <div className={`${styles.verticalLine}`}></div>
                <div className={`${styles.verticalLine}`}></div>
              </div>
            )}
          </div>
          <div>
            <span
              className={`${styles.stepText} ${
                textMargin?.length && textMargin[index]
              } ${index + 1 === activeStep ? styles.blackText : ""}`}
            >
              {step}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TimeLine;
