import { getHelpCenterPageData } from "src/services/LiveChat/Settings/getHelpCenterData";
import { IUpdateHelpCenterPageDataParams, updateHelpCenterPageData } from "src/services/LiveChat/Settings/updateHelpCenterData";
import { RootState } from "src/store/store";

const fetchHelpCenterDataThunk = async (
  payload: number|string,
  { getState, dispatch }: { getState: Function; dispatch: Function }
) => {
  const currentState: RootState = getState();

  const helpCenterData = await getHelpCenterPageData({
    integrationId: payload
  });
  return helpCenterData;
};

interface UpdateHelpCenterDataParams extends IUpdateHelpCenterPageDataParams{
  callback?: ()=> void;
}

export default { fetchHelpCenterDataThunk };
