import styles from "./ResponseRemarkModal.module.scss";
interface Props {onHide : (e:any) => void};
const ResponseRemarkModal = ({onHide} : Props) => {
  return (
    <div>
      <div className={`${styles.sendBulk_Header}`}>
        <h5 className={`${styles.sendBulk_Title}`}>
          <span className={`me-2 ${styles.sendBulkHeadIcon}`}>
            <i className="fas fa-pencil-alt"></i>
          </span>
          Add Remark:
        </h5>
        <span className={`mx-1 ${styles.commomCloseBtn}`} onClick={onHide}>
          <i className="fa-solid fa-xmark"></i>
        </span>
      </div>
      <div>
        <span className={`me-1 ${styles.bulkRemarkHead}`}>
          <span className="me-1">
            <i className="fas fa-user"></i>
          </span>
          <span>SK, 31/1/2022 ,7:10 am</span>
        </span>
        <p className={`${styles.bulkRemarkSub}`}>
          Last updated <span>5 min</span> ago
        </p>
      </div>
      <div className="mb-1">
        <textarea
          className={`form-control ${styles.sendBulkTextArea}`}
          rows={12}
          placeholder="Type here..."
          style={{ resize: "none" }}
        ></textarea>
      </div>
      <div className={`${styles.sendBulk_Footer}`}>
        <span className={`${styles.smile}`}>
          <i className="fas fa-smile"></i>
        </span>
        <span className={`${styles.paperClip}`}>
          <i className="fas fa-paperclip"></i>
        </span>
        <span className={`${styles.bold}`}>B</span>
        <span className={`${styles.italic}`}>I</span>
        <span className={`${styles.smile}`}>
          <i className="fas fa-image"></i>
        </span>
      </div>
    </div>
  );
};
export default ResponseRemarkModal;
