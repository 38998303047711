import { axiosJSON } from "src/globals/axiosEndPoints";
import log from "loglevel";
import { TicketsQuery } from "./getTicketsV2";
import { FreePlanError, FreePlanErrorMessage } from "src/globals/constants";

export type SendBulkMessagePayload = {
  ticketIds?: string[];
  message: string;
  allTickets?: boolean;
  filters?: TicketsQuery["filters"];
  isCustomerView?: boolean;
};

export async function sendBulkMessage(servicePayload: SendBulkMessagePayload) {
  const { data } = await axiosJSON.post(
    `/api/ticket/sendBulkMessage`,
    servicePayload,
  );
  if (data.err === true) {
    if (data.statusCode) {
      // If the status code is there, throw a specific error
      throw {
        statusCode: data.statusCode,
        message: data.msg ?? data.message,
        failedTickets: data?.failedTickets,
        failedTicketCount: data?.failedTicketCount,
      };
    } else {
      throw new Error(data.msg as string);
    }
  }
  const normalizedData = normalizeData(data);
  // log.debug('send bulk message raw response:', data);
  // log.debug('send bulk message normalized response:', normalizedData);
  return normalizedData;
}

// convert incoming data to the required format
function normalizeData(response: any) {
  const normalizedData = response;
  return normalizedData;
}
