import { useMemo, useState } from "react";
import style from "./BaseButton.module.scss";

type Props = {
  FAIcon: string;
  BtnText: string;
  BtnAction: Function;
  Selected: Boolean;
};

const BaseButton = ({
  FAIcon,
  BtnText,
  BtnAction,
  Selected,
  ...props
}: any) => {
  const [buttonState, setButtonState] = useState(false);

  const handleOnClick = (e: any) => {
    e.preventDefault();
    setButtonState(!buttonState);
    BtnAction();
  };

  const getClassName = useMemo(() => {
    const baseClassName = `btn me-2 d-inline-block text-truncate align-middle `;
    if (Selected) {
      return baseClassName + `${style.actionBtnOn}`;
    } else {
      return baseClassName + `${style.actionBtnOff}`;
    }
  }, [Selected]);

  return (
    <div>
      <button
        onClick={handleOnClick}
        type="button"
        className={getClassName}
        {...props}
      >
        <span className="me-2">
          <i className={`${FAIcon}`} />
        </span>
        {BtnText}
      </button>
    </div>
  );
};

export default BaseButton;
