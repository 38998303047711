/**
 * This file defines a React component responsible for rendering single row of external sources article data
 *
 * @author @yuvaraj-busibud
 * @author @navjyot-busibud
 * @author @Anubhav-busibud
 */
import React from "react";
import styles from "./SearchData.module.scss";
import { Articles } from "src/services/Bot/AnswerSources/getAllExternal.service";
import { highlightSearchedText } from "src/routes/TicketList/children/OuterTickets/Chidren/SearchedTickets/SearchedTickets";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ParseHTML from "src/components/ParseHTML";
import { Link } from "react-router-dom";
import BotIndexingStatus, { IBotIndexingStatus } from "src/components/BotIndexingStatus/BotIndexingStatus";

interface Props {
  article: Articles;
  handleSelectArticle: (
    articleId: number | string,
    isSelected: boolean,
  ) => void;
  isAllArticleSelected: boolean;
  searchTerm: string;
  sourceId: number | string;
}

const SearchData = ({
  article,
  handleSelectArticle,
  isAllArticleSelected,
  searchTerm,
  sourceId
}: Props) => {
  const [changedBotStatus, setchangedBotStatus] = React.useState<IBotIndexingStatus>(article.botTrainingStatus);
  return (
    <div>
      <div className={`px-2 py-2 mb-2 ${styles.tableHead}`}>
        <div className={`d-flex align-items-center mb-1`}>
          <div className={`${styles.article}`}>
            <div className="form-check ms-1">
              <div>
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={article.isSelected || isAllArticleSelected}
                  onChange={(e) => {
                    handleSelectArticle(
                      article.articleId,
                      !(article.isSelected || isAllArticleSelected),
                    );
                  }}
                />
              </div>
              <OverlayTrigger
                placement={"top"}
                overlay={
                  <Tooltip className={`${styles.toolTipCustom}`}>
                    <div className={`${styles.tooltipBody}`}>
                      <ParseHTML
                        // html={replaceImgTagWithFileName({
                        //   html: ticketCellData[2]?.text ?? "",
                        //   attachments:
                        //     ticketCellData[2]?.attachments ?? [],
                        // })}
                        html={article.articleTitle}
                      />
                    </div>
                  </Tooltip>
                }
              >
                <label
                  className={`form-check-label pe-2 ${styles.articleName}`}
                  htmlFor="order"
                >
                  {article.articleTitle}
                </label>
              </OverlayTrigger>
            </div>
          </div>
          <div className={`${styles.articleUrl} pe-2`}>
            <OverlayTrigger
              placement={"top"}
              overlay={
                <Tooltip className={`${styles.toolTipCustom}`}>
                  <div className={`${styles.tooltipBody}`}>
                    <ParseHTML
                      // html={replaceImgTagWithFileName({
                      //   html: ticketCellData[2]?.text ?? "",
                      //   attachments:
                      //     ticketCellData[2]?.attachments ?? [],
                      // })}
                      html={article.articleUrl}
                    />
                  </div>
                </Tooltip>
              }
            >
              <Link
                className={`${styles.tablePara}`}
                to={article.articleUrl}
                target="_blank"
              >
                {article.articleUrl}
              </Link>
            </OverlayTrigger>
          </div>
          <div className={`${styles.mainSource} pe-2`}>
            <span className={`${styles.articleName}`}>
              {article.articleMainSource}
            </span>
          </div>
          <div className={`${styles.appearance} pe-2`}>
            <span className={`${styles.articleName} ${styles.appearCount}`}>
              {article.noOfTimesAppeared}
            </span>
          </div>
          <BotIndexingStatus
            botStatus={article.botTrainingStatus}
            failedText={"Training failed"}
            loadingText={"This may take few minutes..."}
            syncedText={"page synced"}
            percentageLoaded={80}
            sourceId={sourceId}
            sourceType={'External'}
            id={article.articleId}
            setchangedBotStatus={setchangedBotStatus}
          />
        </div>
        <p className={`mb-0 ${styles.desc}`}>
          {highlightSearchedText(article.articleDescription, searchTerm)}
        </p>
      </div>
    </div>
  );
};

export default SearchData;
