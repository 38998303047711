import { useEffect, useState } from "react";
import Loader from "src/components/Loader";
import {
  fetchAllOrders,
  setCurrentPage,
} from "src/store/slices/allOrders/allOrders.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import styles from "./AllOrderSubRow.module.scss";
import RowOrderItem from "./RowOrderItem/RowOrderItem";
import noData from "src/assets/images/no-data.png";

const AllOrderSubRow = (props: {
  isCheckAll: boolean;
  setIsCheckAll: Function;
  setShowModal: any;
}) => {
  const { customer, orders, currentPage, totalPage, fetchAllOrdersAjaxStatus } =
    useAppSelector((state) => state.allOrders);

  const dispatch = useAppDispatch();

  // useEffect(() => {
  //   dispatch(fetchAllOrders());
  // }, []);

  const fetchPrevPage = (e: any) => {
    if (fetchAllOrdersAjaxStatus === "fulfilled" && currentPage > 1) {
      // set current page
      dispatch(setCurrentPage({ currentPage: currentPage - 1 }));
      dispatch(fetchAllOrders());
    }
  };

  const fetchNextPage = (e: any) => {
    if (
      fetchAllOrdersAjaxStatus === "fulfilled" &&
      totalPage &&
      currentPage < totalPage
    ) {
      // set current page
      dispatch(setCurrentPage({ currentPage: currentPage + 1 }));
      dispatch(fetchAllOrders());
    }
  };

  function renderData(orders: any) {
    return (
      <div className={``}>
        <div className={` ${styles.TableRow}`}>
          {fetchAllOrdersAjaxStatus === "fulfilled" && orders.length > 0 ? (
            <>
              {orders.map((order: any) => {
                return (
                  <RowOrderItem
                    order={order}
                    setShowModal={props.setShowModal}
                    isCheckAll={props.isCheckAll}
                    setIsCheckAll={props.setIsCheckAll}
                  />
                );
              })}
            </>
          ) : (
            <>
              {fetchAllOrdersAjaxStatus === "pending" && <Loader />}
              {(fetchAllOrdersAjaxStatus === "fulfilled" ||
                fetchAllOrdersAjaxStatus === "rejected") &&
              orders.length === 0 ? (
                <div
                  className={`${styles.no} ${styles.noOrdersDiv} d-flex justify-content-center align-items-center h-100 text-center mt-4`}
                >
                  <div>
                    <div className="images">
                      <img src={noData} alt="no data" className="img-fluid" />
                    </div>
                    <div className={`${styles.noOrdersText} ${styles.no_data}`}>
                      <h3>No orders found</h3>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </>
          )}
        </div>

        <div className={`text-center my-2`}>
          <span
            className={`${styles.leftArrow} ${
              fetchAllOrdersAjaxStatus === "fulfilled" && currentPage > 1
                ? ""
                : styles.arrowDisabled
            }`}
            onClick={(e: any) => fetchPrevPage(e)}
          >
            <i className="fa-solid fa-angle-left"></i>
          </span>
          <span
            className={`${styles.rightArrow} ${
              fetchAllOrdersAjaxStatus === "fulfilled" &&
              totalPage &&
              currentPage < totalPage
                ? ""
                : styles.arrowDisabled
            }`}
            onClick={(e: any) => fetchNextPage(e)}
          >
            <i className="fa-solid fa-angle-right"></i>
          </span>
        </div>
      </div>
    );
  }

  try {
    return (
      <div className={`${styles.mainDiv}`}>{orders && renderData(orders)}</div>
    );
  } catch (error) {
    console.log(error);

    return <p>Some error</p>;
  }
};
export default AllOrderSubRow;
