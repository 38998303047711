import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  ExtendedTemplateContext,
  useTemplateDispatch,
  useTemplates,
} from "../MainSection/TemplateContext";

type Props = {
  selectedTemplateId: string | null;
  setSelectedTemplateId: (value: string | null) => void;
};

function useDraftSidebar({ selectedTemplateId, setSelectedTemplateId }: Props) {
  const templates = useTemplates();
  const dispatch = useTemplateDispatch();
  const { templateIds, totalCount } = useContext(ExtendedTemplateContext);

  const [activeStyles, setActiveStyles] = useState(true);

  useEffect(() => {
    setActiveStyles(true);
  }, [selectedTemplateId]);

  const newTemplateNames = useMemo(() => {
    return (
      Array.from(
        new Set([
          ...templateIds,
          ...Object.keys(templates).map((id) =>
            id === "new" ? "new" : parseInt(id)
          ),
        ])
      ).map((templateId) => {
        const templateSteps = templates[templateId];
        if (!templateSteps) {
          return undefined;
        }
        const name =
          templateSteps.length > 0 ? templateSteps[0].templateHeading : "";
        return {
          id: templateId + "",
          name,
        };
      }) as { id: string; name: string }[]
    )
      .filter((item) => item !== undefined)
      .sort((a, b) => {
        if (a.id === "new" && b.id !== "new") {
          return -1; // a comes first
        } else if (a.id !== "new" && b.id === "new") {
          return 1; // b comes first
        } else {
          return 0; // no change in order
        }
      });
  }, [templateIds, templates]);

  function addNewTemplate() {
    setSelectedTemplateId("new");
    dispatch({
      type: "Create_New_Template",
      payload: {
        templateId: "new",
        value: "new",
        stepId: "new",
        templateName: "New Template " + (parseInt((totalCount ?? 0) + "") + 1),
      },
    });
  }

  const memoizedAddNewTemplate = useCallback(addNewTemplate, [
    setSelectedTemplateId,
    dispatch,
    totalCount,
  ]);

  function selecteTemplate(id: string) {
    setActiveStyles(true);
    setSelectedTemplateId(id);
  }

  const memoizedSelecteTemplate = useCallback(selecteTemplate, [
    setSelectedTemplateId,
    setActiveStyles,
  ]);

  return {
    templates,
    activeStyles,
    setActiveStyles,
    newTemplateNames,
    addNewTemplate: memoizedAddNewTemplate,
    selecteTemplate: memoizedSelecteTemplate,
  };
}

export default useDraftSidebar;
