/**
 * This file defines a React component representing the ChatBot interface.
 * It includes tabs for navigating between "User setting" and "Answer Source" for a specific bot profile.
 *
 * @author @navjyot-busibud
 * @author @Anubhav-busibud
 * @author @yuvaraj-busibud
 */

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from "react";
import styles from "./ChatBot.module.scss";
import UserSetting from "./Children/UserSetting/UserSetting";
import ActiveSource from "./Children/ActiveSource/ActiveSource";
import Analytics from "./Children/Analytics/Analytics";
import { useNavigate, useParams } from "react-router-dom";
import settingImg from "src/assets/images/botSetting.png";
import blackbook from "src/assets/images/bookOpenBlack.png"
import book from "src/assets/images/bookOpen.png"
import { useAppSelector } from "src/store/store";

// type Props = {
//   handleBackClick: () => void;
//   selectedBotId: string | null | number;
// };

const ChatBot = () => {
  const navigate = useNavigate();
  const { profileId, tab } = useParams();
  const [currentTab,setCurrentTab]=useState("");

  const ui_visibility = useAppSelector(
    (state) => state.globals.currentUserData?.ui_visibility,
  );

  // 20 Apr 2024 Checking if atleast one bot report is included in ui_visibility
  const showAnalytics = useMemo(() => {
    return (
      ui_visibility?.includes("bot_percentageCernFoundUseful") ||
      ui_visibility?.includes("bot_volumeOfChartsByCern") ||
      ui_visibility?.includes("bot_chartsCernDidNotFindAnswersByTime") ||
      ui_visibility?.includes("bot_mostUsedArticlesByCernToAnswerQuestions") ||
      ui_visibility?.includes("bot_mostUsedCustomAnsByCernToAnswerQuestions")
    );
  }, [ui_visibility]);

  return (
    <div className="w-100">
      <div
        className={`d-flex flex-column flex-md-row align-items-md-center ms-md-4 ${styles.btnWrapper}`}
      >
        <button
          className={`px-3 mb-2 mb-md-0 ${styles.tabsBtn} ${tab === "settings" && styles.active
            }`}
          onClick={() => {
            navigate(`/bot/botProfiles/${profileId}/settings`);
            setCurrentTab("settings")
          }}
          id="settings"
        >
          <span className="pe-1">
            <img
              src={settingImg}
              alt=""
              className={`${styles.settingImg}`}
            />
          </span>
          Settings
        </button>
        <button
          className={`px-3 mb-2 mb-md-0 ${styles.tabsBtn} ${tab === "answerSource" && styles.active
            }`}
          onClick={() => {
            navigate(`/bot/botProfiles/${profileId}/answerSource/external`);
            setCurrentTab("external")
          }}
          id="answerSource"
        >
          <span className="pe-1">
            <img
              src={currentTab==="external"?blackbook:book}
              alt=""
              className={`${styles.book}`}
            />
          </span>
          Answer Source
        </button>
        {/* No ui for this so hide for now 29.07.2023  */}
        <button
          className={`px-3 ${styles.tabsBtn} ${tab === "analytics" && styles.active
            } ${!showAnalytics && "d-none"}`} // 20 Apr 2024 Adding this check to show Analytics only when atleast one bot report is available in ui_visibility
          onClick={() => {
            navigate(`/bot/botProfiles/${profileId}/analytics`);
            setCurrentTab("analytics")
          }}
        >
          <span className="pe-1">
            <i className="fa-solid fa-chart-line"></i>
          </span>
          Analytics
        </button>
      </div>
      <div className={`w-100 ${styles.contentWrapper}`}>
        {tab === "settings" && (
          <>
            <UserSetting /> 
          </>
        )}
        {tab === "answerSource" && (
          <>
            <ActiveSource /> 
          </>
        )}
        {tab === "analytics" && (
          <>
            <Analytics />
          </>
        )}
      </div>
    </div>
  );
};

export default ChatBot;
