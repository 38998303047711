import { useCallback, useEffect, useRef, useState } from "react";
import ReactSelect from "react-select";
import { GetLinkableAccountsResponse } from "src/services/Integrations/WhatsappIntegration.service";
import { useFetchLinkableAccounts } from "../CustomHook/useFetchLinkableAccounts";
import styles from "./WhatsappBrandsSelectBox.module.scss";

interface Props {
  addUser?: boolean;
  isDisabled?: boolean;
  currentValue?: GetLinkableAccountsResponse;
  placeholder?: string;
  initialFetchDone?: () => void;
  integrationId: string | undefined;
  getValue?: (value: GetLinkableAccountsResponse | null) => void;
  showValidation: boolean;
  additionalLinkableAccount?: GetLinkableAccountsResponse;
}

/**
 * Component to render Dropdown for Whatsapp Accounts Linked
 */
function WhatsappBrandsSelectBox({
  isDisabled = false,
  integrationId,
  currentValue = {
    id: "",
    phone_numbers: [],
  },
  placeholder,
  initialFetchDone,
  getValue,
  showValidation = false,
  additionalLinkableAccount,
}: Props) {
  const formRef = useRef(null);
  const [selectedPhoneNumber, setSelectedPhoneNumber] =
    useState<GetLinkableAccountsResponse | null>(null);

  const { linkableAccounts, isLoading } = useFetchLinkableAccounts({
    integrationId,
    initialFetchDone,
    selectedPhoneNumber,
    additionalLinkableAccount,
  });

  /**
   * Effect for initializing with a current value
   */
  useEffect(() => {
    if (currentValue && currentValue.id) {
      setSelectedPhoneNumber(currentValue);
    } else {
      setSelectedPhoneNumber(null);
    }
  }, [currentValue]);

  /**
   * On Change handler for updating dropdown values
   */
  const onChangeHandler = useCallback(
    (newValueArr: GetLinkableAccountsResponse | null) => {
      if (!newValueArr) {
        setSelectedPhoneNumber(newValueArr);
      }
      if (getValue) {
        getValue(newValueArr);
      }
    },
    [getValue],
  );

  /**
   * Callback handler for building options label string
   */
  const getOptionLabel = useCallback(
    (option: GetLinkableAccountsResponse) =>
      option.id ? `${option.id} (${option.phone_numbers.join(",")})` : "",
    [],
  );

  return (
    <div
      ref={formRef}
      className={`w-100`}
    >
      <ReactSelect
        isClearable={true}
        styles={customStyles}
        value={selectedPhoneNumber}
        getOptionLabel={getOptionLabel}
        isLoading={isLoading}
        placeholder={placeholder}
        options={linkableAccounts}
        onChange={onChangeHandler}
        className={`w-100 ${
          showValidation ? "border border-danger rounded" : ""
        }`}
        isDisabled={isDisabled}
      />
      {showValidation && (
        <span className={`${styles.validation}`}>please select an account</span>
      )}
    </div>
  );
}

const customStyles = {
  control: (provided: any, _: any) => ({
    ...provided,
    background: "#f5f5f5 0% 0% no-repeat padding-box",
    borderColor: "#9e9e9e",
    minHeight: "48px",
    width: "100%",
    border: "1px solid transparent",
    borderRadius: "8px",
    color: "#000000",
    cursor: "pointer",
    "&:active": {
      border: "1px solid #000000",
    },
  }),

  valueContainer: (provided: any, _: any) => ({
    ...provided,
    minHeight: "48px",
    padding: "0 6px",
  }),
  placeholder: (base: any) => ({
    ...base,
    font: "normal normal normal 14px/21px Poppins",
    letterSpacing: " 0px",
    color: "#6E6E6E",
  }),

  input: (provided: any, _: any) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorsContainer: (provided: any, _: any) => ({
    ...provided,
    minHeight: "48px",
    padding: "0px !important",
    marginRight: "6px",
  }),
  dropdownIndicator: (provided: any, _: any) => ({
    ...provided,
    padding: "0px !important",
  }),
  MenuPortal: (provided: any, _: any) => ({
    ...provided,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 16px 48px #00000029",
    borderRadius: "10px",
    border: "1px solid #fff",
    font: "normal normal normal 14px/21px Poppins",
    letterSpacing: "0px",
    color: "#000000",
  }),
  option: (provided: any, _: any) => ({
    ...provided,
    textAlign: "left",
    font: "normal normal normal 14px/21px Poppins",
    letterSpacing: "0px",
    color: "#000000",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    padding: "6px 12px",
    cursor: "pointer",
    "&:hover": {},
  }),
  clearIndicator: (provided: any, _: any) => ({
    ...provided,
    padding: "0px !important",
  }),
  indicatorSeparator: (provided: any, _: any) => ({
    ...provided,
    marginBottom: "4px",
    marginTop: "4px",
    width: "1px",
    boxSizing: "border-box",
    marginRight: "6px",
  }),

  multiValueLabel: (provided: any, _: any) => ({
    ...provided,
    padding: "0",
    textAlign: "left",
    font: "normal normal normal 14px/21px Poppins",
    letterSpacing: "0px",
    color: "#000000",
    opacity: "1",
  }),
  multiValue: (provided: any, _: any) => ({
    ...provided,
    paddingRight: "2px",
    background: "unset",
    font: "normal normal normal 14px/21px Poppins",
    letterSpacing: "0px",
    color: " #000000",
  }),
};

export default WhatsappBrandsSelectBox;
