import { useCallback, useEffect, useState } from "react";
import styles from "./ChooseArticleType.module.scss";
import { useAppDispatch, useAppSelector } from "src/store/store";

import { setActiveFeaturedArticleData } from "src/store/slices/liveChatSetting/chatWidgetSetting/knowledgeBased/knowledgeBasedSetting.slice";
import { AddFeatureModalTypes } from "../AddFeaturesModal/AddFeaturesModal";
import cross from "src/assets/images/featuredArticledCross.png";
import arrow from "src/assets/images/rightarrow.png";

interface Props {
  onHide: () => void;
  setShowComponent: (value: AddFeatureModalTypes) => void;
}
const ChooseArticleType = ({ onHide, setShowComponent }: Props) => {
  const { type, isDefault } = useAppSelector(
    (state) => state.knowledgeBasedSettings.activeFeaturedArticle
  );
  const { defaultExists } = useAppSelector(
    (state) => state.knowledgeBasedSettings.featuredArticleMeta
  );

  const dispatch = useAppDispatch();

  const handleNext = useCallback(() => {
    if (type === "custom") {
      setShowComponent("domainUrl");
    } else {
      setShowComponent("featuredArticle");
    }
  }, [type]);

  //helper function to update active featured article type
  const handleOnTypeChange = useCallback((value: "custom" | "default") => {
    dispatch(setActiveFeaturedArticleData({ type: value }));
  }, []);

  return (
    <div className="p-4 d-block">
      <div
        className={`d-flex justify-content-between  ${styles.modalHeaderBox} `}
      >
        <p className={`${styles.modalHead}`}>
          Please select which category you're adding the featured articles for?
        </p>
        <span className={`${styles.close}`} onClick={onHide}>
          {/* <i className="fa-solid fa-xmark"></i> */}
          <img src={cross} alt=""></img>
        </span>
      </div>
      <div>
        <div
          className={`${styles.choseBox} ${
            defaultExists && isDefault === false ? styles.disabled : ""
          } mb-3`}
        >
          <div className={`d-flex mb-2`}>
            <div className="form-check">
              <input
                className="form-check-input cursor-pointer"
                type="radio"
                name="targetedMessageType"
                id="default"
                value="Greeting"
                checked={type === "default"}
                disabled={defaultExists && isDefault === false}
                onChange={() => handleOnTypeChange("default")}
              />
            </div>
            <div>
              <label
                htmlFor="default"
                className={`mb-1 cursor-pointer ${styles.radioHead}`}
              >
                Default URL (All Pages)
              <p className={`mb-0 ${styles.choseBoxPara}`}>
                Featured articles will appear on all pages of your website.
              </p>
              </label>
            </div>
          </div>
        </div>
        <div className={`${styles.choseBox}`}>
          <div className={`d-flex mb-2`}>
            <div className="form-check cursor-pointer">
              <input
                className="form-check-input"
                type="radio"
                name="targetedMessageType"
                id="custom"
                value="Announcement"
                checked={type === "custom"}
                onChange={() => handleOnTypeChange("custom")}
              />
            </div>
            <div>
              <label
                htmlFor="custom"
                className={`mb-1 cursor-pointer ${styles.radioHead}`}
              >
                Specific URL
              <p className={`${styles.choseBoxPara} mb-0`}>
                Featured articles will appear on exactly or URL which contains
                the pages with the URL that you provide
              </p>
              </label>
            </div>
          </div>
        </div>
        <div className={`mt-4 ${styles.actionBtn}`}>
          <button
            className={`d-flex align-items-center justify-content-center ${styles.nextBtn}`}
            onClick={handleNext}
          >
            <span>Next</span>
            <span className={` d-flex align-items-center justify-content-center ${styles.arrow}`}>
              {/* <i className="fa-solid fa-chevron-right"></i> */}
              <img src={arrow} className={`${styles.nextArrow}`} alt=""/>
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChooseArticleType;
