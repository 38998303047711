import { useRef, useState } from "react";
import ModelBackImg from "src/assets/images/ModelBackImg.png";
import { Controller, Swiper as ISwiper, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import GoBackButton from "../GoBackButton/GoBackButton";
import NextStepButton from "../NextStepButton/NextStepButton";
import Step1 from "../Step1/Step1";
import Step2 from "../Step2/Step2";
import Step3 from "../Step3/Step3";
import cross from "src/assets/images/featuredArticledCross.png";
import styles from "./StepSilder.module.scss";

function StepsSlider({ onHide }: { onHide: () => void }) {
  const [controlledSwiper, setControlledSwiper] = useState<ISwiper | null>(
    null,
  );

  const [disableBackBtn, setDisableBackBtn] = useState(false);

  const [hideNextBtn, setHideNextBtn] = useState(false);

  const stepsRef = useRef(null);

  return (
    <div className={`px-1 px-md-4 pt-4`}>
      <img
        onClick={onHide}
        src={cross}
        alt="cross"
        className={`${styles.closeIcon}`}
      />
      <div
        className={`mt-3 d-flex justify-content-between align-items-center ${styles.stepsHeader}  `}
      >
        <GoBackButton
          disabled={disableBackBtn}
          swiper={controlledSwiper}
        />
        <div className={`px-1`}>
          <span className={`${styles.stepBox}`}>
            Step
            <span
              ref={stepsRef}
              className={`${styles.stepsCount} ms-2 align-middle`}
            ></span>
          </span>
        </div>
        <NextStepButton
          isVisible={true}
          swiper={controlledSwiper}
          hide={hideNextBtn}
        />
      </div>
      <Swiper
        modules={[Controller, Pagination]}
        onSwiper={setControlledSwiper}
        slidesPerView={1}
        pagination={{
          el: stepsRef.current,
          type: "custom",
          renderCustom(swiper, current, total) {
            return `0${current}/0${total}`;
          },
        }}
        allowTouchMove={false}
      >
        {/* Step1 */}
        <SwiperSlide>
          <Step1 setBackBtn={setDisableBackBtn} />
        </SwiperSlide>

        {/* Step2 */}
        <SwiperSlide>
          <Step2 />
        </SwiperSlide>

        {/* Step3 */}
        <SwiperSlide>
          <Step3 setHideBtn={setHideNextBtn} />
        </SwiperSlide>
      </Swiper>
      <img
        src={ModelBackImg}
        alt=""
      />
    </div>
  );
}

export default StepsSlider;
