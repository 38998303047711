import Loader from "src/components/Loader";
import styles from "./AccessControl.module.scss";
import useAccessControl from "./useAccessControl";
import passShow from "src/assets/images/passShow.png";
import passHide from "src/assets/images/passHide.png";
import arrowRight from "src/assets/images/arrowRight.png";
import { useNavigate } from "react-router-dom";

const AccessControl = () => {
  const navigate = useNavigate();
  // Variable declaration from useAccessControl hook
  const {
    dropdown,
    handleIpAddressChange,
    handleIpWhiteListingChange,
    handlePassRestrictionChange,
    handlePasswordChange,
    handleSaveChanges,
    ipAddress,
    ipWhiteListing,
    passRestriction,
    password,
    setDropDown,
    updateDomaiAndSslAccessControl,
    isLoading,
    showPassword,
    toggleShowPassword,
    showValidation,
    IpValidationError,
  } = useAccessControl();

  return (
    <div className={`w-100 pt-4 px-3 ${styles.accessControlWrapper}`}>
      <div className="mb-4 d-flex flex-column flex-md-row align-items-start">
        {/* header section */}
        <div className="d-flex justify-content-between">
          <div>
            <div
              className={`d-flex justify-content-center align-items-center ${styles.backChat}`}
              onClick={() => navigate(-1)}
            >
              <i className="fa-solid fa-arrow-left"></i>
            </div>
          </div>
          <div>
            <h3 className={`mb-0 ${styles.heading} saufter_h3`}>
              Domain and Access / Access Control
            </h3>
            <p className={`mb-0 ${styles.subHeading} saufter_subHeading`}>
              Let customers style the help center right inside your chat widget
              instead of starting a conversation.
            </p>
          </div>
        </div>

        {/* save changes button */}
        <div className="d-flex mt-2 mt-md-0 ms-auto">
          {/* Delete Button */}
          <div
            className={`${styles.delBox} d-none`}
            aria-disabled={true}
          >
            <i className="fa-solid fa-trash"></i>
          </div>
          <button
            className={`ms-1 px-3 d-flex align-items-center justify-content-center ${styles.saveBtn}`}
            onClick={handleSaveChanges}
            disabled={updateDomaiAndSslAccessControl.isLoading}
          >
            {updateDomaiAndSslAccessControl.isLoading
              ? "Saving..."
              : "Save Changes"}
          </button>
        </div>
      </div>
      {isLoading ? (
        <>
          <div className={`${styles.loaderWrapper}`}>
            <Loader />
          </div>
        </>
      ) : (
        <>
          <p className={`mb-3 ${styles.head} saufter_h4`}>
            Access control settings
          </p>
          <div
            className={`d-flex justify-content-between align-items-center px-2 saufter_medium_h4 ${
              styles.dropBtn
            } ${dropdown && styles.borderSet}`}
            onClick={() => setDropDown(!dropdown)}
          >
            <span>Access Control</span>
            <span className={`${styles.arrow}`}>
              {dropdown ? (
                <img
                  src={arrowRight}
                  alt=""
                  className={`${styles.arrowDown}`}
                />
              ) : (
                <img
                  src={arrowRight}
                  alt=""
                />
              )}
            </span>
          </div>

          {/* Dropdown Body */}
          {dropdown ? (
            <div className={`p-2 p-md-3 mb-2 ${styles.dropDown}`}>
              <div className="mb-3">
                <p className={`mb-0 ${styles.accessName} saufter_h5`}>
                  Password Restriction
                </p>
                {/* Password Restriction Checkbox and Input Box */}
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexCheckDefault"
                    checked={passRestriction}
                    onChange={handlePassRestrictionChange}
                    required
                  />
                  <label
                    className={`form-check-label ${styles.formLabel} saufter_h6`}
                    htmlFor="flexCheckDefault"
                  >
                    Limit access to your Knowledge base with a password.
                  </label>

                  <div className={`position-relative ${styles.urlBox}`}>
                    <input
                      type={showPassword ? "text" : "password"}
                      className={`${styles.urlInput} px-2 mt-2 ${
                        showValidation.password && styles.validationBorder
                      } ${!passRestriction && styles.disableInput}`}
                      placeholder="Enter password"
                      disabled={!passRestriction}
                      value={password}
                      onChange={handlePasswordChange}
                    />
                    <img
                      src={showPassword ? passShow : passHide}
                      alt="hide"
                      className={`${
                        showPassword ? styles.passShow : styles.passHide
                      }`}
                      onClick={toggleShowPassword}
                    />
                  </div>
                  {showValidation.password && (
                    <span className={`${styles.validationError}`}>
                      Please enter a password
                    </span>
                  )}
                </div>
              </div>

              {/* IP Whitelisting Checkbox and Input Box */}
              <div className="d-none">
                <p className={`mb-0 ${styles.accessName} saufter_h5`}>
                  IP Whitelisting
                </p>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault2"
                    checked={ipWhiteListing}
                    onChange={handleIpWhiteListingChange}
                  />
                  <label
                    className={`form-check-label ${styles.formLabel} saufter_h6`}
                    htmlFor="flexCheckDefault2"
                  >
                    Limit access to your Knowledge base with certain trusted IP
                    addresses.
                  </label>

                  <div className={`position-relative ${styles.urlBox}`}>
                    <div className="mb-2">
                      <textarea
                        className={`form-control mt-2 ${styles.textArea} ${
                          (showValidation.ipAddress || IpValidationError) &&
                          styles.validationBorder
                        }`}
                        id="exampleFormControlTextarea1"
                        rows={4}
                        disabled={!ipWhiteListing}
                        value={ipAddress}
                        onChange={handleIpAddressChange}
                      ></textarea>
                      {(showValidation.ipAddress || IpValidationError) && (
                        <span className={`${styles.validationError}`}>
                          {IpValidationError
                            ? "Addresses are not in valid format"
                            : "Please enter IP addresses"}
                        </span>
                      )}
                    </div>

                    {/* Instruction */}
                    <div>
                      <span>
                        <p className={`mb-0 ${styles.instruction}`}>
                          {" "}
                          Add one or multiple IP addresses, each on a separate
                          line. IPs can be added in several ways:{" "}
                        </p>
                        <span className={`d-block ${styles.instruction}`}>
                          {" "}
                          - As a single address: 54.243.104.66 or
                          2001:0db8:0000:0042:0000:8a2e:0370:7334{" "}
                        </span>
                        <span className={`d-block ${styles.instruction}`}>
                          {" "}
                          - As a range: 54.243.104.0-54.243.104.99{" "}
                        </span>
                        <span className={`d-block ${styles.instruction}`}>
                          {" "}
                          - As a CIDR notation: 54.243.104.0/8 or
                          2620:0:2d0:200::7/32{" "}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
};

export default AccessControl;
