import styles from "./FinalSetup.module.scss";
import caution from "src/assets/images/caution.png";
import Select from "react-select";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SetupTable from "./Children/SetupTable/SetupTable";
import Gmail from "../AddEmail/Children/AddModal/DomainIntegration/children/OutgoingMailOption/Gmail/Gmail";

const options = [
  { value: "1", label: "2048(Default)" },
  { value: "2", label: "1024" },
  { value: "3", label: "1025" },
  { value: "4", label: "1026" },
];

const FinalSetup = ({
  isGmail,
  isIncommingSetting = false,
}: {
  isGmail: boolean;
  isIncommingSetting?: boolean;
}) => {
  return (
    <>
      {isGmail ? (
        <Gmail isIncommingSetting={isIncommingSetting} />
      ) : (
        <div className={`pt-4 px-3 ms-lg-3 me-0 me-lg-3`}>
          <div className={`${styles.emailForward} d-none`}>
            {/* 
            <div className={`py-2 ${styles.emailHead} d-none`}>
              <span className={`py-1 px-3 d-inline-block ${styles.emailSpan}`}>
                {isGmail
                  ? `As you are integrating a Gmail email address, you will need to link your Google Account with Saufter so that we can send emails to your customers.`
                  : `Finally, let's Setup SPF & DKIM`}
              </span>
            </div> 
            */}
            <div className={`py-3`}>
              <div>
                <div>
                  <span className={`ps-3 ${styles.selSpan}`}>
                    Select DKIM key size
                  </span>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip className={`${styles.toolTipCustom}`}>
                        DKIM refers to the key length of characters. The longer
                        the key length, the more challenging it is for hackers
                        to break the DKIM key.
                      </Tooltip>
                    }
                  >
                    {({ ref, ...triggerHandler }) => (
                      <span
                        {...triggerHandler}
                        ref={ref}
                        className={`${styles.orderStatus}`}
                      >
                        <img
                          className={`ms-1 cursor-pointer ${styles.caution}`}
                          src={caution}
                          alt=""
                        />
                      </span>
                    )}
                  </OverlayTrigger>
                </div>
                <div className={`my-2 px-3`}>
                  <Select
                    styles={{
                      singleValue: (baseStyles, _state) => ({
                        ...baseStyles,
                        font: "normal normal normal 14px/21px Poppins",
                      }),
                    }}
                    isDisabled
                    options={options}
                    value={options[0]}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={`my-2 py-2 px-2 ${styles.redBorder} d-none`}>
            <p className={`mb-0 p-1 ${styles.cautionText}`}>
              You can still send emails from this address but you may be more
              susceptible to deliverability issues. Please verify your domain to
              ensure the best possible performance.
            </p>
          </div>
          <div>
            <p className={`${styles.leadPara}`}>
              To enable DKIM signing for your domain, please add the information
              below to your DNS records via your DNS registrar. Note that
              verification of these settings may take up to 72 hours after
              submission.
            </p>
          </div>
          <SetupTable />
        </div>
      )}
    </>
  );
};
export default FinalSetup;
