import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "../CustomerEdit/CustomerEdit.module.scss";
import UserAvatar from "src/components/UserAvatar";
import edit from "src/assets/images/editCustomer.svg";

import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  editCustomerProfile,
  removeCustomerProfile,
} from "src/store/slices/editCustomerDetails/editCustomerDetails.slice";
import editCustomerDetails from "src/store/slices/editCustomerDetails/editCustomerDetails.thunks";
import AxiosImg from "src/components/AxiosImg";
import { Overlay } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import { selectFile } from "src/utils/utils";
import { ATTACHEMENT_SUPPORTED_IMG_TYPES } from "src/components/QuillEditorContainer/children/AttachmentPicker/AttachmentPicker";
import { uploadAttachment } from "src/services/Attachment/uploadAttachment";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";

const ProfileImage = React.forwardRef<HTMLInputElement>(
  (props, imageInputForwardRef) => {
    const profileImg = useAppSelector((state) => {
      return state.editcustomerdetails.customer.customerProfileImageUrl;
    });
    const isPublicAttachmentUrl = useAppSelector((state) => {
      return state.editcustomerdetails.customer.isPublicAttachmentUrl;
    });
    const [showOverlay, setShowOverlay] = useState(false);
    const overlayTarget = useRef(null);

    const id = useAppSelector((state) => {
      return state.editcustomerdetails.customer.id;
    });

    const dispatch = useAppDispatch();

    const resetInputRef = useRef<HTMLInputElement>(null);

    function runRemoveDispatch() {
      setShowOverlay(false);
      dispatch(
        editCustomerDetails.removeCustomerProfileAsyncAction({
          customerId: id as string,
          customerProfileImageUrl: "",
        })
      );
    }

    const { name } = useAppSelector(
      (state) => state.shopifySidebar.customerData
    );

    const onClickWindow = useCallback((e: any) => {
      if (!e.target.closest(".ignoreClick")) {
        setShowOverlay(false);
      }
    }, []);
    useEffect(() => {
      window.addEventListener("click", onClickWindow);
      return () => {
        window.removeEventListener("click", onClickWindow);
      };
    }, [onClickWindow]);

    function handleAddAttachmentClick(
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) {
      setShowOverlay(false);
      //moved the file upload logic to thunk, file object cannot be serialized
      dispatch(
        editCustomerDetails.editCustomerProfileAsyncAction({
          customerId: id as string,
        })
      );
    }

    return (
      <div className={`${styles.image} pt-2`}>
        {profileImg ? (
          <AxiosImg url={profileImg} className="rounded-circle" isDirectURL={isPublicAttachmentUrl}/>
        ) : (
          <UserAvatar name={name || "NA"} size={45} />
        )}

        <div className={`d-flex justify-content-center ${styles.editInfo}`}>
          <div
            className={`d-flex justify-content-center align-items-center ${styles.customInfo}`}
          >
            <div
              className={`d-flex ignoreClick justify-content-center align-items-center ${styles.customInfo}`}
              onClick={() => {
                setShowOverlay((show) => !show);
              }}
              ref={overlayTarget}
            >
              <img src={edit} alt="" className={`${styles.editLogo}`} />
            </div>
            <Overlay
              target={overlayTarget.current}
              show={showOverlay}
              placement={`bottom-start`}
            >
              {({ placement, arrowProps, show: _show, popper, ...props }) => (
                <ul
                  className={`pe-1 ignoreClick dropdown-menu ${styles.customerDropDown} ${styles.customerDropDown1}`}
                  {...props}
                >
                  <li className={`d-flex`}>
                    <div className={`${styles.hoverBar}`}></div>

                    <button
                      className={`dropdown-item ${styles.photoText}`}
                      onClick={(e) => handleAddAttachmentClick(e)}
                    >
                      Change Photo
                    </button>
                  </li>

                  <li className={`d-flex`}>
                    <div className={`${styles.hoverBar}`}></div>
                    <button
                      className={`dropdown-item ${styles.photoText}`}
                      onClick={(e) => {
                        runRemoveDispatch();
                      }}
                    >
                      Remove Photo
                    </button>
                  </li>
                </ul>
              )}
            </Overlay>
          </div>
        </div>
      </div>
    );
  }
);

export default ProfileImage;
