import Lottie from "lottie-light-react";
import celebration from "src/assets/images/celebration.json";
import styles from "./DispatchRuleSuccess.module.scss";

export interface DispatchRuleSuccessProps {
  onHide: () => void;
}

const DispatchRuleSuccess = ({ onHide }: DispatchRuleSuccessProps) => {
  return (
    <div className={`w-100 ${styles.ruleBox}`}>
      <div className={`text-center ${styles.successWrapper}`}>
        <Lottie
          loop={false}
          className={`${styles.celebrationAnimation}`}
          animationData={celebration}
        ></Lottie>
        <span className={`mx-auto mb-3 ${styles.successIcon}`}>
          <i className="fa-solid fa-check"></i>
        </span>
        <p className={` ${styles.successHead}`}>
          Dispatching rules setup completed!
        </p>

        <div className="">
          <button
            className={`${styles.ruleBtn}`}
            id="bug_rule_Btn"
            onClick={onHide}
          >
            Go to all rules
          </button>
        </div>
      </div>
    </div>
  );
};

export default DispatchRuleSuccess;
