import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";
import { IBotIndexingStatus } from "src/components/BotIndexingStatus/BotIndexingStatus";
export interface getAllQuestionAnswerParams {
  botProfileId: number | string;
  start?: number;
  limit?: number;
  searchTerm?: string;
}
export interface QuestionAns {
  // questionId: number;
  customQAId: number;
  question: string;
  status: "draft" | "public";
  numberOfTimesAppeared: number;
  // isSelected: boolean;
  botStatus: IBotIndexingStatus;
  botTrainingStatus: "loading" | "failed" | "synced";
}
export interface getAllQuestionAnswerRes {
  data: QuestionAns[];
  metaData: {
    count: number;
    total: number;
  };
}

const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/customQa/getAllQuestionAnswer`
  : "/api/botProfile/customQA/getAll";

export async function getAllQuestionAnswer(params: getAllQuestionAnswerParams) {
  const { data: res } = await axiosJSON.post(apiEndPoint, params);

  if (res.err) {
    throw new Error(res.message as string);
  }

  return {
    ...res,
    metaData: {
      total: res?.metaData?.totalCount ?? 0,
      count: res?.metaData?.currentCount,
    },
  } as getAllQuestionAnswerRes;
}
