import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import {
  ConditionTypes,
  createNewObjectHash,
  getConditionValueOrDefault,
} from "src/store/slices/initiateReturn/Helpers/returnExchange.helpers";
import { v4 as uuid } from "uuid";
import {
  fetchPostStep14Config,
  fetchStep14GetConfig,
} from "../Thunks/returnExchangeModal.thunk";
import { step14Config } from "../step14Configuration.slice";

const step14Builders = (builder: ActionReducerMapBuilder<step14Config>) => {
  builder.addCase(fetchPostStep14Config.pending, (state: step14Config) => {
    state.updateAjaxStatus = "pending";
  });
  builder.addCase(
    fetchPostStep14Config.fulfilled,
    (state: step14Config, action) => {
      state.updateAjaxStatus = "fulfilled";
      updateConfig(state, action.payload);
    },
  );
  builder.addCase(fetchPostStep14Config.rejected, (state: step14Config) => {
    state.updateAjaxStatus = "rejected";
    pushTheToast({
      type: "danger",
      text: "failed to update!",
      position: "top-right",
    });
  });
  builder.addCase(
    fetchStep14GetConfig.pending,
    (state: step14Config, { payload }) => {
      state.fetchAjaxStatus = "pending";
    },
  );
  builder.addCase(
    fetchStep14GetConfig.rejected,
    (state: step14Config, { payload }) => {
      state.fetchAjaxStatus = "rejected";
      pushTheToast({
        type: "danger",
        text: "failed to fetch configuration!",
        position: "top-right",
      });
    },
  );
  builder.addCase(
    fetchStep14GetConfig.fulfilled,
    (state: step14Config, action) => {
      state.fetchAjaxStatus = "fulfilled";

      updateConfig(state, action.payload);
    },
  );
};
const updateConfig = (state: step14Config, payload: any) => {
  const {
    step14ConditionOptionsRes,
    step14ConfigRes,
    returnReasons,
    returnReasonList,
  } = payload;

  if (step14ConditionOptionsRes) {
    state.conditionOptions = step14ConditionOptionsRes.conditions;
    state.itemOptions = step14ConditionOptionsRes.itemConditions;
    state.defaultOptions = step14ConditionOptionsRes.defaultConditions;
    // Adding default return reasons for a state variable to check for validations based on moreQuestions
    if (returnReasonList) {
      state.returnReasonList = Object.values(returnReasonList);
    }
    state.selectReasonOptions =
      step14ConditionOptionsRes.returnReasonConditions;

    [
      ...state.conditionOptions,
      ...state.itemOptions,
      ...state.defaultOptions,
      ...state.selectReasonOptions,
    ].forEach((option) => {
      if (!state.variableNames.some((item: any) => item.id === option.id)) {
        state.variableNames.push({ name: option.name, id: option.id });
      }

      state.operatorsNames[option.name] = option.operators.map((operator) => {
        state.operatorSymbolMap[operator.value] = operator.name;
        return operator.name;
      });
      state.valueTypes[option.valueType] = null;
      state.variableNameToValueType[option.name] = option.valueType;
    });
  }

  if (step14ConfigRes) {
    const step14Config = step14ConfigRes;

    if (step14Config.length > 0) {
      state.selectedReturnMethodId = step14Config[0].configTabId.toString();
      state.returnMethods = step14Config.map((tab: any, idx: any) => {
        return {
          returnMethodName: tab.configTabName,
          returnMethodId: tab.configTabId.toString(),
          returnMethodType: {
            value: tab?.returnMethod?.returnType ?? "notRequired",
            message: tab?.returnMethod?.displayMessage ?? "",
            error: null,
          },
          selectReturnReasonError: null,
          isAddSelectReasonConditionEnabled: returnReasons,
          returnReasons: returnReasons,
          returnReasonList: returnReasonList
            ? Object.values(returnReasonList)
            : [], //Assigning api response to redux state
          error: null,
          isAddConditionEnabled: true,
          errorCount: 0,
          isValid: false,
          shipWithAnyCarrier: {
            currentState: true,
            instructions: "",
            showInstruction: false,
          },
          shipWithPrepaidLabels: {
            currentState: true,
            instructions: "",
            showInstruction: false,
          },
          // itemSequence: tab.itemSequence,
          conditions: tab.conditions.map((condition: any) => {
            const selectedVariable: any = state.variableNames.find(
              (variable: any) => variable.id == condition.conditionTypeId,
            );

            if (selectedVariable) {
              const variableName = selectedVariable.name;
              const operatorSymbol = condition.operator;
              const operator = state.operatorSymbolMap[operatorSymbol];
              const valueType = state.variableNameToValueType[variableName];
              const values = getConditionValueOrDefault(
                valueType,
                condition.conditionValue,
              );

              return {
                id: condition.id.toString(),
                variableName,
                operator,
                values,
              } as ConditionTypes;
            }
            return {} as ConditionTypes;
          }),
          selectReturnReason: tab.selectReturnReasons.map((condition: any) => {
            const selectedVariable: any = state.variableNames.find(
              (variable: any) => variable.id == condition.conditionTypeId,
            );

            if (selectedVariable) {
              const variableName = selectedVariable.name;
              const operatorSymbol = condition.operator;
              const operator = state.operatorSymbolMap[operatorSymbol];
              const values = getConditionValueOrDefault(
                "return_reason",
                condition.conditionValue,
              );

              return {
                id: condition.id.toString(),
                variableName,
                operator,
                values,
              } as ConditionTypes;
            }
            return {} as ConditionTypes;
          }),
        };
      });
    } else {
      if (step14ConditionOptionsRes.defaultConditions) {
        let conditions = step14ConditionOptionsRes.defaultConditions.map(
          (condition: any) => {
            const selectedVariable: any = state.variableNames.find(
              (variable: any) => variable.id == condition.id,
            );
            if (selectedVariable) {
              const variableName = selectedVariable.name;
              const operatorSymbol = condition.operators[0].value;
              const operator = state.operatorSymbolMap[operatorSymbol];
              const valueType = state.variableNameToValueType[variableName];
              const values = getConditionValueOrDefault(valueType, null);

              return {
                id: uuid(),
                variableName,
                operator,
                values,
              } as ConditionTypes;
            }
          },
        );

        state.returnMethods[0].conditions = conditions;
        state.returnMethods[0].returnMethodName = "All orders and items";
      }
    }
  }

  const hash = createNewObjectHash({
    excludeKeys: state.excludeKeysForHash,
    hashObject: state.returnMethods,
  });

  if (hash) {
    state.initialHash = hash;
    state.currentHash = hash;
  }
};
export default step14Builders;
