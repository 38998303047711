import { IBotIndexingStatus } from "src/components/BotIndexingStatus/BotIndexingStatus";
import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";


export interface SelectArticlesParams {
  botProfileId: number;
  selectedArticleIds: number[];
  unselectedArticleIds: number[];
  allArticles: boolean;
  // search?: string;
  botTrainingStatus:IBotIndexingStatus;

}

export interface SelectArticlesResponse {
  msg: String;
  trainingStatuses: {
    articleId: number ;
    botTrainingStatus: IBotIndexingStatus;
  }[];
}

const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/botProfile/kbArticle/select`
  : "/api/botProfile/articles/select";

export async function selectArticles(params: SelectArticlesParams) {
  const { data: res } = await axiosJSON.post(apiEndPoint, params);

  if (res.error) {
    throw new Error(res.message as string);
  }

  return res.data as SelectArticlesResponse;
}
