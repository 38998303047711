import { axiosJSON } from "src/globals/axiosEndPoints";

export async function integrateShopify(shop_name: string) {
  try {
    const res = (
      await axiosJSON.post(`/api/integration/shopify`, { shop_name })
    ).data;
    if (res.err && res.code === "already_exists") {
      throw "already_exists";
    } if (res.err && res.code === "current_tenant_shopify_integrated_already") {
      throw "current_tenant_shopify_integrated_already";
    }if (res.err && res.code === "invalid_store_name") {
      throw "invalid_store_name";
    }else if (res.err) {
      throw "Something went wrong!";
    }
    return res;
  } catch (e) {
    if (e == "already_exists") {
      throw "This store is already integrated!";
    } else if (e === "current_tenant_shopify_integrated_already") {
      throw "An existing Shopify integration has already integrated!";
    } else if (e === "invalid_store_name") {
      throw "Please enter a valid store url!";
    }
    throw "Something went wrong!";
  }
}
