import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";
import { Phone } from "src/store/slices/editCustomerDetails/editCustomerDetails.declarations";
import { AutomationAPIPayload } from "../createAutomation.service";

export enum DispatchChannels {
  Email = "1",
  Whatsapp = "2",
  Facebook = "3",
  Instagram = "4",
  InstagramDM = "5",
  SMS = "6",
}

export interface AvailableDispatchChannel {
  channelId: string;
  channelName: string;
}

export interface UserAgent {
  teamId?: string;
  id: string;
  email: string;
  phone: Phone | null;
  name: string;
  imageUrl: string | null;
  roleId: string;
  roleName: string;
  availableChannels: AvailableDispatchChannel[];
}

interface Payload {
  integrationId: string;
  start: number;
  limit: number;
}

export const getAllAgentsService = async ({
  payload,
  type,
}: AutomationAPIPayload<Payload>) => {
  const apiEndPoint = IS_TEST_ACTIVE
    ? `${process.env.REACT_APP_TEST_SITE_URL}/bugManagement/getUsersData`
    : `/api/automation/${type}/getUsersData`;

  const { data: res } = await axiosJSON.post(apiEndPoint, payload);

  if (res.err || res.error) {
    throw res.msg || res.message || "Cannot get the users data";
  }

  const ret: UserAgent[] = res.data.map((data: UserAgent) => {
    let availableChannels: AvailableDispatchChannel[] = [];
    if (data.email) {
      availableChannels.push({
        channelId: DispatchChannels.Email,
        channelName: "Email",
      });
    }

    if (data.phone) {
      availableChannels.push({
        channelId: DispatchChannels.SMS,
        channelName: "SMS",
      });
    }

    return {
      ...data,
      id: data.id + "",
      availableChannels,
    };
  });

  return ret as UserAgent[];
};
