import { axiosJSON } from "src/globals/axiosEndPoints";

export interface addBrandPayload {
  brandName?: string;
  brandEmail?: string;
  isActive?: string;
  brandSignature?: string;
  disabledUserIds?: string;
  disabledUser?: Array<{
    userId: number | string;
    assignTo: number | string;
  }>;
}

export async function addBrand(payload: addBrandPayload) {
  const { data: res } = await axiosJSON.post("/api/brand/addBrand", payload);

  let errorMessage = "Something went wrong";

  if (res.err === true) {
    if (res.statusCode) {
      // If the status code is there, throw a specific error
      throw {
        statusCode: res.statusCode,
        message: res.msg ?? res.message,
      };
    } else {
      if (res?.metaData?.statusCode && res.metaData.statusCode.length !== 0) {
        errorMessage = res.metaData.statusCode;
      }

      throw new Error(errorMessage);
    }
  }

  return res.data;
}

export interface CreateBrandPayload {
  brandName?: string;
  brandEmail?: string;
}

export async function createBrand(payload: CreateBrandPayload) {
  const { data: res } = await axiosJSON.post("/api/brand/createBrand", payload);

  let errorMessage = "Something went wrong";

  if (res.err === true) {
    if (res?.metaData?.statusCode && res.metaData.statusCode.length !== 0) {
      errorMessage = res.metaData.statusCode;
    }

    throw new Error(errorMessage);
  }

  return res.data;
}
