import { axiosJSON } from "src/globals/axiosEndPoints";
import { BrandData } from "./getAllBrands";

export interface BrandVariableValues {
  key: string,
  value: string
}

export async function getBrandVariableValues() {

  const { data: res } = await axiosJSON.get("/api/brand/getBrandVariableValues");

  if (res.err) {
    throw new Error(res.msg);
  }

  return res.data as Array<BrandVariableValues>;
}
