/**
 * This is a service file.
 *
 * @author Yash Aditya<yash@zinosi.com>
 */

import { axiosJSON } from "src/globals/axiosEndPoints";
import { ComingSoonFeatureTypes } from "./reqCmngSnFeature.service";

// Defining an asynchronous function getCmngSnRequestedService with a parameter 'params'
async function getCmngSnRequestedService() {
  // Destructuring assignment to extract 'data' from the response of the POST request
  const { data: res } = await axiosJSON.post("/api/comingSoon/getRequested");

  // Checking for errors in the response
  if (res.err || res.error) {
    // Throwing an error with a fallback message if 'msg' or 'message' is not present in the response
    throw res.msg ?? res.message ?? "Something went wrong!";
  }

  // Casting 'isRequested' property of the response to boolean and returning it
  return res.requestedFeatures as Array<ComingSoonFeatureTypes>;
}

// Exporting the getCmngSnRequestedService function as the default export of the module
export default getCmngSnRequestedService;
