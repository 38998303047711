import InnerChatShowDoc from "./children/InnerChatShowDoc/InnerChatShowDoc";
import InnerChatShowImg from "./children/InnerChatShowImg/InnerChatShowImg";
import InnerChatShowPdf from "./children/InnerChatShowPdf/InnerChatShowPdf";
import InnerChatShowVid from "./children/InnerChatShowVid/InnerChatShowVid";
import InnerChatShowZip from "./children/InnerChatShowZip/InnerChatShowZip";
import { v4 as uuidv4 } from "uuid";
import styles from "./InnerChatAttachments.module.scss";

const InnerChatAttachments = ({ messageData, setModalShow }: any) => {
  return (
    <div className={`${styles.showAttachmentsDiv}`}>
      {messageData.attachments &&
        messageData.attachments.map((attachment: any, index: any) => {
          if (
            attachment.attachmentType === "image/png" ||
            attachment.attachmentType === "image/jpg" ||
            attachment.attachmentType === "image/jpeg"
          ) {
            return (
              <InnerChatShowImg
                key={uuidv4()}
                attachment={attachment}
                messageData={messageData}
                setModalShow={setModalShow}
              />
            );
          } else if (attachment.attachmentType === "application/pdf") {
            return (
              <InnerChatShowPdf
                key={uuidv4()}
                attachment={attachment}
                messageData={messageData}
              />
            );
          } else if (attachment.attachmentType === "application/zip") {
            return (
              <InnerChatShowZip
                key={uuidv4()}
                attachment={attachment}
                messageData={messageData}
              />
            );
          } else {
            // all other docs
            return (
              <InnerChatShowDoc
                key={uuidv4()}
                attachment={attachment}
                messageData={messageData}
              />
            );
          }
        })}
    </div>
  );
};

export default InnerChatAttachments;
