import { axiosJSON } from "src/globals/axiosEndPoints";
import APIResponse from "./APIReponse";

interface IRoutingRuleConditionList{
  location: Array<RoutingRuleCondition>;
  url: Array<RoutingRuleCondition>;
  domain: Array<RoutingRuleCondition>;
  [key: string]: Array<RoutingRuleCondition>;
}

interface RoutingRuleCondition{
  conditionId: string| number;
  conditionValue: string;
  conditionConstraint: string;
}

export interface addRoutingRuleParams {
  ruleName: string;
  ruleAction: string;
  teamId?: number|string;
  conditions: IRoutingRuleConditionList
  integrationId: number|string;
}



export async function addRoutingRules(params: addRoutingRuleParams) {
  const { data } = await axiosJSON.post(
    `/api/chatSetting/routingRule/create`,
    params
  );
  if (data.error === true) {
    throw new Error(data.message as string);
  }
  return data.data;
}