import { useEffect, useState } from "react";
import { fetchAllGoalSettingData, getGoalSettingDataFromIdSelector, resetGoalSettingsData } from "src/store/slices/liveChatSetting/Goals/GoalSetting.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import CustomVariable from "./Children/CustomVariable/CustomVariable";
import LiveChatApi from "./Children/LiveChatApi/LiveChatApi";
import VisitorPage from "./Children/VisitorPage/VisitorPage";
import styles from "./NewGoal.module.scss";
import {v4 as uuidV4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { addGoalSetting, addGoalSettingParams } from "src/services/LiveChat/Settings/goals/addGoalSetting";

import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { updateGoalSetting, updateGoalSettingParams } from "src/services/LiveChat/Settings/goals/updateGoalSetting";
import { Modal } from "react-bootstrap";
import DeleteGoal from "../GoalTable/Children/GoalRow/DeleteGoal/DeleteGoal";

export const GoalTypeCompList :{
  [key: string]: { name: string; component: any }
  } = {
  specificPage: { name: "specificPage", component:VisitorPage },
  customVariable: { name: "customVariable", component:CustomVariable},
  apiRequestIsSent: { name: "apiRequestIsSent", component:LiveChatApi}
};

const NewGoal = ({goalId, integrationId, goalTypeOptions}:any) => {

  const [goalName, setGoalName] = useState("");
  const [goalType, setGoalType] = useState("apiRequestIsSent");
  const [goalValueType, setGoalValueType] = useState("");
  const [goalValue, setGoalValue] = useState("");
  const [showModal , setShowModal] = useState(false);

  const goalData = useAppSelector((state) =>
    getGoalSettingDataFromIdSelector(state, goalId)
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(()=>{

    if(goalId !== 'new' && goalData !== null){
      setGoalName(goalData.goalName);
      setGoalType(goalData.goalType);
      setGoalValueType(goalData.goalValueType);
      setGoalValue(goalData.goalValue);
    }


  },[goalId, goalTypeOptions]);
  const handleSelect = (e: any) => {
    const selectedGoal = e.target.value;
    setGoalType(selectedGoal);
    setGoalValue("");
    setGoalValueType("");
  };
  
  const handleCancel = (e: any) => {
    // dispatch(fetchAllTeams());
    navigate("/live-chat/settings/"+integrationId+"/goals");
  };

  const handlePayloadValidation = ()=>{
    if(goalName === "" || goalType===""){
      return false;
    }
    if(goalType !== "apiRequestIsSent"){
      if(goalValue === "" || goalValueType === ""){
        return false;
      }
    }
    return true;
  }
  const handleSubmit = () => {
    if(goalData !== null || goalId === "new"){

      // setShowSaveChanges(false);
      if(!handlePayloadValidation()){
        pushTheToast({
          type: "warning",
          text: "Please fill all the required fields!",
          position: "top-right"
        });
        return;
      }
      
      if(goalId === "new"){
        let payload:addGoalSettingParams = {
          goalName: goalName,
          goalType: goalType,
          goalValue: goalValue,
          goalValueType: goalValueType,
          integrationId: integrationId
        }

        addGoalSetting(payload).then((res)=>{

          dispatch(resetGoalSettingsData());
          dispatch(fetchAllGoalSettingData(integrationId));
          navigate('/live-chat/settings/'+integrationId+'/goals')
          setTimeout(() => {
            pushTheToast({
              type: "success",
              text: "Goal successfully added!",
              position: "top-right"
            });
          }, 0);
        }).catch((err)=>{
          pushTheToast({
            type: "danger",
            text: "Something went wrong!",
            position: "top-right"
          });
        // setShowSaveChanges(true);
        })
      }else{
        let payload:updateGoalSettingParams = {
          goalId: goalId,
          goalName: goalName,
          goalType: goalType,
          goalValue: goalValue,
          goalValueType: goalValueType,
          integrationId: integrationId
        }


        updateGoalSetting(payload).then((res)=>{

          dispatch(resetGoalSettingsData());
          dispatch(fetchAllGoalSettingData(integrationId));
          navigate('/live-chat/settings/'+integrationId+'/goals');
          setTimeout(() => {
            pushTheToast({
              type: "success",
              text: "goal successfully updated!",
              position: "top-right"
            });
          }, 0);
        }).catch((err)=>{
          pushTheToast({
            type: "danger",
            text: "Something went wrong!",
            position: "top-right"
          });
          // setShowSaveChanges(true);
        });
      }
    }
    
  }

  const showDiscardModal = () => {
    setShowModal(true);
  }
  const onHide = () =>{
    setShowModal(false);
  }

  const ShowComp = goalType === null || goalType === "" ? null : GoalTypeCompList[goalType + ""]?.component;
  
  return (
    <div>
      <p className={`${styles.goalSpan}`}>
        Name your goal and choose the result you want to reach: for example, a
        customer landing on a checkout page after a chat.
      </p>
      <div className={`container-fluid px-0 mx-0`}>
        <div className={`row`}>
          <div className={`col-12 col-md-6`}>
            <div className="mb-3">
              <label
                htmlFor="goalName"
                className={`form-label ${styles.labelName}`}
              >
                Goal Name
              </label>
              <input data-lpignore="true" 
                type="text"
                className={`form-control ${styles.goalInput}`}
                id="goalName"
                value={goalName}
                onChange={(e)=>setGoalName(e.target.value)}
                placeholder="Type in the name for the Goal"
              />
            </div>
            <div>
              <div>
                <label
                  htmlFor="goalName"
                  className={`form-label ${styles.labelName}`}
                >
                  Mark this Goals as achieved when:
                </label>
              </div>
              {/* <div className={`w-100 dropdown`}>
                <a
                  className={`btn dropdown-toggle ${styles.page}`}
                  href="#"
                  role="button"
                  id="dropdownMenuLink"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  visitor lands on a specific page...
                </a>

                <ul
                  className={`dropdown-menu ${styles.dropBox}`}
                  aria-labelledby="dropdownMenuLink"
                >
                  <li>
                    <a className="dropdown-item" href="#">
                      Action
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Another action
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Something else here
                    </a>
                  </li>
                </ul>
              </div> */}
              <div>
                <select className={`w-100 ${styles.page}`} onChange={handleSelect} value={goalType}>
                  {
                    goalTypeOptions && Object.keys(goalTypeOptions.goalTypeOptions).map((key:string, index:number)=>{
                        return <option key={uuidV4()} value={goalTypeOptions.goalTypeOptions[key].key} className={`${styles.ellipsis}`}>
                          {goalTypeOptions.goalTypeOptions[key].name}
                        </option>
                    })
                  }
                
                  {/* <option value={`var`} className={`${styles.ellipsis}`}>
                    Custom variable in LiveChat code has met the specific value
                  </option>
                  <option value={`api`} className={`${styles.ellipsis}`}>
                    LiveChat API request is sent
                  </option> */}
                </select>
              </div>
              <Modal
                backdropClassName={`${styles.modalBack}`}
                show={showModal}
                onHide={onHide}
                dialogClassName={`${styles.modalDialog}`}
                contentClassName={`${styles.modalContent}`}
                // centered={true}
                enforceFocus={false}
              >
                <DeleteGoal showDeleteText={false} handleConfirm={handleCancel} onHide={onHide} />
              </Modal>
              { ShowComp && <ShowComp goalValue={goalValue} goalValueType={goalValueType} setGoalValueType={setGoalValueType} setGoalValue={setGoalValue}/>}
              {/* {goalValueType === "specificPage" ? <VisitorPage /> : ""}
              {goalValueType === "customVariable" ? <CustomVariable /> : ""}
              {goalValueType === "apiRequestIsSent" ? <LiveChatApi /> : ""} */}
              {goalId === "new" && (

                <div className={`d-flex flex-column flex-md-row align-items-center pb-2`} >
                <button className={`${styles.addRuleBtn}`} onClick={handleSubmit}>Add a Goal</button>
                <span className={`px-1 ${styles.or}`}> or</span>
                <button className={`ms-md-1 bg-white ${styles.discardBtn}`} onClick={showDiscardModal}>Cancel</button>
              </div>
                )}
            </div>
          </div>
        </div>
        {
          goalId !== "new" && (
            <div className={`d-flex justify-content-end ${styles.actionDiv}`}>
              <button className={`bg-white ${styles.discardBtn}`} onClick={showDiscardModal}>Cancel</button>
              <button className={`${styles.addRuleBtn}`} onClick={handleSubmit}>Save changes</button>
            </div>
          )
        }
      </div>
    </div>
  );
};
export default NewGoal;
