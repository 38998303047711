import { axiosJSON } from "src/globals/axiosEndPoints";
import { BrandDetail } from "src/services/EmailIntegration/getBrands";

interface SlackBrandsData {
  data: BrandDetail[];
  metaData: {
    hasMore: boolean;
  };
}

const getLinkableBrandSlackService = async (payload: {
  start: number;
  limit: number;
}) => {
  const { data: res } = await axiosJSON.post(
    "/api/setting/integration/slack/getLinkableBrands",
    payload,
  );

  if (res.err || res.error) {
    throw new Error(res.msg ?? res.message ?? "Something went wrong!");
  }

  const ret: SlackBrandsData = {
    data: res.data,
    metaData: {
      hasMore: true,
    },
  };

  if (ret.data.length < payload.limit) {
    ret.metaData.hasMore = false;
  }

  return res.data as SlackBrandsData;
};

export default getLinkableBrandSlackService;
