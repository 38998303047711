


import styles from "./MainSection.module.scss";
import Step from "./Children/Step/Step";
import useMainSection from "./useMainSection";
import { useCallback, useMemo } from "react";
import { Form } from "react-bootstrap";
import { useTemplateDispatch } from "./TemplateContext";

type Props = {
  selectedTemplateId: string | null;
  onHide: () => void;
  scheduleMessageFor: "innerTicket" | "liveChat";
  setSelectedTemplateId: (value: string | null) => void;
  ticketId?: number | string;
  scheduleWith?: string | null;
};

const MainSection = ({
  setSelectedTemplateId,
  selectedTemplateId,
  onHide,
  scheduleMessageFor,
  ticketId,
  scheduleWith,
}: Props) => {
  const {
    buttonText,
    template,
    selectedTemplateStep,
    createTemplateClick,
    updateTemplateClick,
    setButtonText,
    resetApiState,
    scheduleMessageOnly,
    bigButtonText,
    setScheduleMessageOnlyFlag,
    setScheduleMessageFlag,
    onlyScheduleBtnText,
    isUpdateLoading,
    showValidationErrors,
  } = useMainSection({
    setSelectedTemplateId,
    selectedTemplateId,
    scheduleMessageFor,
    ticketId,
    onHide,
    scheduleWith,
  });

  const dispatch = useTemplateDispatch();

  const firstStep = useMemo(() => {
    if(selectedTemplateStep && selectedTemplateStep[0]){
      return selectedTemplateStep[0];
    }
  }, [selectedTemplateStep]);

  const updateHeading = useCallback(
    (value: string) => {
      dispatch({
        type: "Update_TemplateHeading",
        payload: {
          templateId: selectedTemplateId,
          stepId: firstStep?.stepId,
          value: value,
        },
      });
    },
    [selectedTemplateId, firstStep?.stepId]
  );


  const handleRemoveStep = useCallback(
    (stepId: number|string) => {
      console.log("remove step", stepId)
      dispatch({
        type: "Remove_Step",
        payload: {
          templateId: selectedTemplateId,
          stepId: stepId,
        },
      });
    },
    [selectedTemplateId]
  );
  

  if (!template && !selectedTemplateId) {
    return null;
  }

  if (!selectedTemplateId) {
    return null;
  }

  if (!selectedTemplateStep) {
    return null;
  }

  return (
    <>
      <div className={`${styles.sectionWrapper}`}>
        <div className={`${styles.stepsSection}`}>
          {firstStep ? (
            <div>
              <div className={`${styles.stepsWrapper}`}>
                <Form>
                  <Form.Group
                    className="mb-1"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label className={`${styles.formLabel}`}>
                      Template Heading
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      value={firstStep.templateHeading}
                      onChange={(e) => updateHeading(e.target.value)}
                      className={`${styles.inputText} ${showValidationErrors && firstStep.templateHeading?.trim() === "" ? "border border-danger": ""}`}
                    />
                  </Form.Group>
                </Form>
              </div>
            </div>
          ) : null}
          {selectedTemplateStep.map((step: any, index: number) => {
            return (
              <Step
                key={step.stepId}
                step={step}
                index={index}
                firstStep={index === 0}
                lastStep={selectedTemplateStep.length === index + 1}
                selectedTemplateId={selectedTemplateId}
                setButtonText={setButtonText}
                resetApiState={resetApiState}
                scheduleMessageFor={scheduleMessageFor}
                showValidationErrors={showValidationErrors}
                handleRemoveStep={handleRemoveStep}
              />
            );
          })}
        </div>
        {/* Buttons */}
        <div className={`d-flex flex-column bottom-0`}>
          <div
            className={`d-flex flex-column flex-md-row justify-content-end mt-3 mb-2 `}
          >
            <button
              className={`d-flex align-items-center justify-content-center me-0 me-md-2 mb-2 mb-md-0 ${styles.updateBtn}`}
              disabled={isUpdateLoading}
              onClick={() => {
                setScheduleMessageOnlyFlag(false);
                if (selectedTemplateId === "new") {
                  createTemplateClick(false);
                } else {
                  updateTemplateClick(false);
                }
              }}
            >
              {buttonText}
            </button>
            <button
              className={`d-flex align-items-center justify-content-center  ${styles.updateBtn}`}
              disabled={isUpdateLoading}
              onClick={() => {
                if (selectedTemplateId === "new") {
                  createTemplateClick(true);
                  setScheduleMessageOnlyFlag(true);
                } else {
                  scheduleMessageOnly();
                }
              }}
            >
              {onlyScheduleBtnText}
            </button>
          </div>
          <div className={`d-flex  justify-content-end`}>
            <button
              className={`d-flex align-items-center justify-content-center  ${styles.updateBtn} `}
              disabled={isUpdateLoading}
              onClick={() => {
                setScheduleMessageOnlyFlag(false);
                if (selectedTemplateId === "new") {
                  setScheduleMessageFlag(true);
                  createTemplateClick(true);
                } else {
                  updateTemplateClick(true);
                }
              }}
            >
              {bigButtonText}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainSection;
