import Lottie from "lottie-light-react";
import botImg from "src/assets/images/botImg.json";
import styles from "./loginHeader.module.scss";
/**
 * Header component for displaying the Saufter.io logo.
 */
const Header = () => {
  return (
    <div
      className="row"
      id="header-section"
    >
      <div className="col-12 mt-4">
        {/* Logo */}
        <div
          className={`d-flex align-items-center mb-4 mt-4 ${styles.headerWrapper}`}
        >
          {/* <img
            src="https://zenpreprod.saufter.io/static/media/saufter.d3e21a516ea4e307c930.gif"
            alt=""
            className="saufterImg"
          /> */}
          <div className={`${styles.saufterImg}`}>
            <Lottie animationData={botImg}></Lottie>
          </div>
          <span className={`${styles.saufterLogo} ps-2`}> Saufter.io </span>
        </div>
      </div>
    </div>
  );
};

export default Header;
