import React from "react";
import styles from "./Analytics.module.scss";
import ChatNotFound from "src/components/ChatNotFound/ChatNotFound";
import traffic_Empty from "src/assets/images/traffic_Empty.png";
import ReportDatePicker from "src/routes/Report/Children/ReportDatePicker/ReportDatePicker";
import {
  reportCurrentStatusActions,
  useReportFiltersCreate,
} from "src/routes/Report/hooks/reportFilters/useReportFilters";
import FilterWrapper from "src/routes/Report/Children/FilterWrapper/FilterWrapper";
import KBAnalytics from "src/routes/Report/Children/KBAnalytics/KBAnalytics";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";

const Analytics = () => {
  const navigate = useNavigate();
  const [state, dispatch, Provider] = useReportFiltersCreate(); // Getting the state and dispatch from the custom hook
  const { integrationId } = useParams(); // Getting the integrationId from the url
  const pageIntegrationId: string = integrationId // Decoding the integrationId
    ? decodeURIComponent(integrationId)
    : "";
  return (
    // Providing the state and dispatch to the child components
    <Provider value={[state, dispatch]}>
      <div className={`${styles.analyticsWrapper}`}>
        <div className="d-flex align-items-center">
          {/* Back Button  */}
          <div
            onClick={() => navigate(-1)}
            className={`d-flex justify-content-center align-items-center ${styles.backChat}`}
          >
            <i className="fa-solid fa-arrow-left"></i>
          </div>
          <h3 className={`ms-2 mb-0 ${styles.heading}`}>
            Knowledge base reports
          </h3>
        </div>

        <div className="d-flex flex-column flex-md-row justify-content-between align-items-md-center">
          <div>
            <FilterWrapper scope="knowledgebase" /> {/* FilterWrapper Component */}
          </div>
          <div>
            <ReportDatePicker // ReportDatePicker Component
              startDate={state.startDate}
              endDate={state.endDate}
              onChange={([startDate, endDate]) => {
                dispatch([reportCurrentStatusActions.setStartDate, startDate]);
                dispatch([reportCurrentStatusActions.setEndDate, endDate]);
              }}
            />
          </div>
        </div>
        <div>
          {/* Analytics Main Component */}
          <KBAnalytics />
        </div>
      </div>
    </Provider>
  );
};

export default Analytics;

// Chat Not Found

<>
  <div
    className={`d-flex justify-content-center align-items-center w-100 ${styles.analytics}`}
  >
    <ChatNotFound
      text={"Coming Soon..."}
      subText={"All the analytics will appeare here..."}
      img={traffic_Empty}
      className={`${styles.NoChatFound}`}
    />
  </div>
</>;
