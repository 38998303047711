/**
 * This file defines a React component representing bot chats in live chat sidebar
 *
 * @author @navjyot-busibud
 * @author @Anubhav-busibud
 */
import UserAvatar from "src/components/UserAvatar";
import styles from "./ChatBotHandle.module.scss";
import botRely from "src/assets/images/botReply.png";
import Lottie from "lottie-light-react";
import botImg from "src/assets/images/botImg.json";
import { Customer } from "src/services/LiveChat/messageExchange/getAllCustomers";
import AxiosImg from "src/components/AxiosImg";
import { BiMessageDetail } from "react-icons/bi";
import { getTimeFromNow } from "src/utils/dateLibrary";
import { useAppSelector } from "src/store/store";
import { useParams } from "react-router-dom";
interface Props {
  customer: Customer;
}
const ChatBotHandle = ({ customer }: Props) => {
  const userTimezone = useAppSelector(
    (state) => state.globals.currentUserData?.userTimezone,
  );

  const { chatId } = useParams();

  return (
    <div
      className={`mb-2 ${styles.botWrapper} ${
        chatId == customer.chatId ? styles.botWrapperActive : ""
      } `}
    >
      <div
        className={`px-2 ${styles.header}  ${
          chatId === customer.chatId ? styles.activeHeader : ""
        }`}
      >
        <p className={`mb-0 ${styles.para}`}>
          {/* 02 Jan Conditionally show text when chat is disconnected */}
          {customer.isConnected === true ? (
            <span>Chat being handled by </span>
          ) : (
            <span>Chat was handled by </span>
          )}
          <span className={`${styles.bothandle}`}>
            {customer.botDetails?.name}
          </span>
        </p>
      </div>
      <div className={`${styles.botBody} px-2 py-1 d-flex`}>
        <div className={`d-flex ${styles.leftSection}`}>
          <div>
            {customer.imageURL && customer.imageURL.length !== 0 ? (
              <AxiosImg
                url={customer.imageURL}
                className={`${styles.profileIcon}`}
              />
            ) : (
              <div>
                <UserAvatar
                  name={
                    customer.name && customer.name !== "" ? customer.name : "NA"
                  }
                  size={20}
                />
              </div>
            )}
          </div>
          <div className="ps-1">
            <p className={`mb-0 text-truncate ${styles.botName}`}>
              {customer.name && customer.name !== "" ? customer.name : "NA"}
            </p>
            <span className={`${styles.botStatus} text-truncate`}>
              {customer.lastMessageBy?.type === "bot" ? (
                <>
                  <img
                    src={botRely}
                    alt=""
                    className="me-1"
                    width={11}
                    height={10}
                  />
                  {customer.lastMessageBy.name} replied
                </>
              ) : customer.lastMessageBy?.type === "customer" ? (
                <>
                  <BiMessageDetail className="me-1" />
                  {customer.lastMessageBy?.name} messaged
                </>
              ) : (
                "Agent Replied"
              )}
            </span>
          </div>
        </div>
        <div
          className={`d-flex flex-column align-items-center ${styles.rightSection}`}
        >
          <span className={`${styles.timeSpan}`}>
            {getTimeFromNow({
              date: new Date(customer.lastMessageTime),
              timeZone: userTimezone,
            })}
          </span>
          <span className={`${styles.lottieImg}`}>
            {/* Default Bot Image */}
            <Lottie animationData={botImg}></Lottie>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ChatBotHandle;
