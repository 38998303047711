import { axiosJSON } from "src/globals/axiosEndPoints";

export interface SlackIntegration {
  integrationId: string;
  brand: {
    id: string;
    name: string;
    email: string;
    imageUrl: string | null;
  } | null;
  channel: {
    id: string;
    name: string;
    imageUrl?: string | null;
  };
}

interface ISlackIntegrationData {
  integrationIds: string[];
  integrations: Record<string, SlackIntegration>;
  metaData: {
    hasMore: boolean;
  };
}

const getAllSlackIntegrationService = async (payload: {
  start: number;
  limit: number;
}) => {
  const { data: res } = await axiosJSON.post(
    "/api/setting/integration/slack/getAllSlackIntegrations",
    payload,
  );

  if (res.err || res.error) {
    throw new Error(res.msg ?? res.message ?? "Something went wrong!");
  }

  const ret: ISlackIntegrationData = {
    integrationIds: [],
    integrations: {},
    metaData: {
      hasMore: true,
    },
  };

  res.data.forEach((integration: SlackIntegration) => {
    const id = integration.integrationId + "";
    ret.integrations[id] = integration;
    ret.integrationIds.push(id);
  });

  if (ret.integrationIds.length < payload.limit) {
    ret.metaData.hasMore = false;
  }

  return ret;
};

export default getAllSlackIntegrationService;
