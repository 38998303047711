import TableComponents from "src/components/TableComponents";

export type Column = {
  id: number;
  keys: string[];
  name: string;
  // cellComponent?: any; //commenting cellComponent and headerComponent from Column interface, components has been separated from Column interface
  // headerComponent?: any;
  show?: boolean; // if true then it will be checked in the more columns
  hideInMoreCols?: boolean; // if true then it will be hidden in the more columns
  onlyRequiredIfTableType?: { [key: string]: Array<string> };
};

export type ColumnComponents = {
  cellComponent: any;
  headerComponent: any;
};

// the order of the column in this column list matters
export const allColumns: Column[] = [
  {
    id: 1,
    // keys: ["ticket_subject", "ticket_excerpt"],
    keys: [
      "ticket_subject",
      "last_message",
      "last_message_formatted",
      "is_read",
      "last_mention_message",
      "unread_mention_count",
      "is_ai_handled",
      "is_ai_drafted",
    ],
    name: "All Tickets",
    hideInMoreCols: true,
    onlyRequiredIfTableType: {
      mentions: ["last_mention_message", "unread_mention_count"],
    },
  },
  {
    id: 2,
    keys: ["ticket_channel"],
    name: "Channel",
  },
  {
    id: 3,
    keys: ["ticket_customer_name"],
    name: "Customer Name",
  },
  {
    id: 4,
    keys: ["ticket_tags"],
    name: "Tags",
  },
  {
    id: 5,
    keys: ["not_replied_since"],
    name: "Not-replied since",
  },

  {
    id: 6,
    keys: [
      "drafted_message",
      "message_draft_status",
      "message_previous_draft_status",
    ],
    name: "All Drafts",
    hideInMoreCols: true,
  },
  {
    id: 7,
    keys: ["last_message_received"],
    name: "Last Message received",
  },

  {
    id: 8,
    keys: ["ticket_drafted_by"],
    name: "Drafted By",
    hideInMoreCols: true, //hided the draft by from outer ticket view
  },
  {
    // this id col is mandatory and its keys and name should not be changed
    id: 9,
    keys: ["ticket_id"],
    name: "Ticket id",
  },
  {
    id: 10,
    keys: ["ticket_subject"],
    name: "Subject",
  },
  {
    id: 11,
    keys: ["ticket_excerpt"],
    name: "Ticket Excerpt",
  },
  {
    id: 12,
    keys: ["ticket_assigned_to"],
    name: "Assigned to",
  },
  {
    id: 13,
    keys: ["last_message_date"],
    name: "Last Message at",
  },
  {
    id: 14,
    keys: ["ticket_created_date"],
    name: "Created",
  },
  {
    id: 15,
    keys: ["ticket_modified_date"],
    name: "Last Updated",
  },
  {
    id: 16,
    keys: ["ticket_status"],
    name: "Ticket Status",
  },
  {
    id: 17,
    keys: ["number_of_tickets"],
    name: "No of Tickets",
  },
  // {
  //   id: 18,
  //   keys: ["ticket_assignee_team"],
  //   name: "Assignee Team",
  //   cellComponent: TableComponents.JustShowString,
  //   headerComponent: TableComponents.SimpleHeader
  // },
  // {
  //   id: 19,
  //   keys: ["ticket_integration"],
  //   name: "Integration",
  //   cellComponent: TableComponents.JustShowString,
  //   headerComponent: TableComponents.SimpleHeader
  // },
  // {
  //   id: 20,
  //   keys: ["pre_draft_details"],
  //   name: "Pre-draft Details",
  //   cellComponent: TableComponents.PreDraftDetails,
  //   headerComponent: TableComponents.SimpleHeader
  // },
  // {
  //   id: 21,
  //   keys: ["escalated_draft_details"],
  //   name: "Escalated draft details",
  //   cellComponent: TableComponents.EscalatedDraftDetails,
  //   headerComponent: TableComponents.SimpleHeader
  // },
  // {
  //   id: 22,
  //   keys: ["ticket_remark"],
  //   name: "Remarks",
  //   cellComponent: TableComponents.EscalatedDraftDetails,
  //   headerComponent: TableComponents.SimpleHeader,
  // },
  {
    id: 23,
    keys: ["message_draft_status", "drafted_message"],
    name: "Draft Status",
    hideInMoreCols: true, //hided the draft status from outer ticket view
  },
  {
    id: 24,
    keys: ["ticket_customer_name"],
    name: "All Customer",
    hideInMoreCols: true,
  },
  {
    id: 25,
    keys: ["customer_open_tickets_count"],
    name: "Open Tickets",
    hideInMoreCols: true,
  },
  {
    id: 26,
    keys: ["ticket_brand"],
    name: "Brand",
  },
];

export const allColumnComponents: { [id: number]: ColumnComponents } = {
  1: {
    cellComponent: TableComponents.TicketTitleExcerpt,
    headerComponent: TableComponents.AllTicketsHeader,
  },
  2: {
    cellComponent: TableComponents.TicketChannel,
    headerComponent: TableComponents.SimpleHeader,
  },
  3: {
    cellComponent: TableComponents.TicketCustomer,
    headerComponent: TableComponents.SimpleHeader,
  },
  4: {
    cellComponent: TableComponents.TicketTags,
    headerComponent: TableComponents.SimpleHeader,
  },
  5: {
    cellComponent: TableComponents.TicketNotRepliedSince,
    headerComponent: TableComponents.SimpleHeader,
  },

  6: {
    cellComponent: TableComponents.ResponseTitleExcerpt,
    headerComponent: TableComponents.AllDraftHeader,
  },
  7: {
    cellComponent: TableComponents.LastMessageReceived,
    headerComponent: TableComponents.SimpleHeader,
  },

  8: {
    cellComponent: TableComponents.JustShowString,
    headerComponent: TableComponents.SimpleHeader,
  },
  9: {
    cellComponent: TableComponents.ResponseTicketID,
    headerComponent: TableComponents.SimpleHeader,
  },
  10: {
    cellComponent: TableComponents.JustShowString,
    headerComponent: TableComponents.SimpleHeader,
  },
  11: {
    cellComponent: TableComponents.JustShowString,
    headerComponent: TableComponents.SimpleHeader,
  },
  12: {
    cellComponent: TableComponents.AssignedTo,
    headerComponent: TableComponents.SimpleHeader,
  },
  13: {
    cellComponent: TableComponents.TicketNotRepliedSince,
    headerComponent: TableComponents.SimpleHeader,
  },
  14: {
    cellComponent: TableComponents.TicketNotRepliedSince,
    headerComponent: TableComponents.SimpleHeader,
  },
  15: {
    cellComponent: TableComponents.TicketNotRepliedSince,
    headerComponent: TableComponents.SimpleHeader,
  },
  16: {
    cellComponent: TableComponents.ResponseTicketStatus,
    headerComponent: TableComponents.SimpleHeader,
  },
  17: {
    cellComponent: TableComponents.JustShowString,
    headerComponent: TableComponents.SimpleHeader,
  },
  //18: {
  //   cellComponent: TableComponents.JustShowString,
  //   headerComponent: TableComponents.SimpleHeader
  // },
  //19: {
  //   cellComponent: TableComponents.JustShowString,
  //   headerComponent: TableComponents.SimpleHeader
  // },
  //20: {
  //   cellComponent: TableComponents.PreDraftDetails,
  //   headerComponent: TableComponents.SimpleHeader
  // },
  //21: {
  //   cellComponent: TableComponents.EscalatedDraftDetails,
  //   headerComponent: TableComponents.SimpleHeader
  // },
  //22: {
  //   cellComponent: TableComponents.EscalatedDraftDetails,
  //   headerComponent: TableComponents.SimpleHeader,
  // },
  23: {
    cellComponent: TableComponents.ShowDraftStatus,
    headerComponent: TableComponents.SimpleHeader,
  },
  24: {
    cellComponent: TableComponents.AllCustomerRow,
    headerComponent: TableComponents.AllCustomerHeader,
  },
  25: {
    cellComponent: TableComponents.OpenTickets,
    headerComponent: TableComponents.SimpleHeader,
  },
  26: {
    cellComponent: TableComponents.TicketBrand,
    headerComponent: TableComponents.SimpleHeader,
  },
};

export type TableView = {
  viewName: string;
  routeString: string;
  tabIcon: string;
  visibilityKeyName: string;
  tabIconColourInactive: string;
  tabIconColourActive: string;
  defaultColumnsList: number[];
};

const allTicketViews: TableView[] = [
  {
    viewName: "Ticket View",
    routeString: "ticket-view",
    visibilityKeyName: "ticket_list_ticket_view",
    tabIcon: "fa-solid fa-ticket",
    tabIconColourInactive: "black",
    tabIconColourActive: "#f6e4c6",
    defaultColumnsList: [
      1, // ticket_subject_excerpt
      26, // ticket_brand
      2, // ticket_channel
      3, // ticket_customer_name
      4, // ticket_tags
      // 5, // not_replied_since
      14, //ticket_created_date //making ticket created date and last message data as default column
      13, //last_message_date
    ],
  },
  {
    viewName: "Response View",
    routeString: "response-view",
    visibilityKeyName: "ticket_list_response_view",
    tabIcon: "fas fa-reply",
    tabIconColourInactive: "black",
    tabIconColourActive: "lightgreen",
    defaultColumnsList: [
      6, // ticket_subject_excerpt
      7, // last_message_received
      2, // ticket_channel
      23, // message_draft_status
      16, // ticket_status
      9, // ticket_id
      5, // not_replied_since
      // 20, // pre_draft_details
      // 21, // escalated_draft_details
      // 22, // ticket_remark: not working
      14, //ticket_created_date //making ticket created date and last message data as default column
      13, //last_message_date
    ],
  },
  {
    viewName: "Customer View",
    routeString: "customer-view",
    visibilityKeyName: "ticket_list_customer_view",
    tabIcon: "fas fa-user-friends",
    tabIconColourInactive: "black",
    tabIconColourActive: "#7ED5D3",
    defaultColumnsList: [
      24, //All customer
      // 6, // ticket_subject_excerpt
      7, // last_message_received
      2, // ticket_channel
      25, //open tickets
      13, //last message at
    ],
  },
];

export const getKeysForColumnIds = (ids: Array<number>) => {
  return allColumns
    .filter((col) => ids.includes(col.id))
    .map((col) => col.keys.join(","))
    .join(",")
    .split(",");
};

export default allTicketViews;
