/**
 * This file is the service file used for making api request.
 * It contains the createKnowledgeBase function service which is explained below.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants"; // this is used for testing purpose only for frontend.

export interface CreateKnowledgeBasePayload {
  brandId: string | number;
}

export interface CreateKnowledgeBaseRes {
  knowledgeBaseId: number | string;
}

/**
 * For testing frontend with own api link if backend is not prepared.
 */
const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/createKnowledgeBase`
  : "/api/knowledgeBase/create";

/**
 * This service is used to create the knowledge base.
 */
export const createKnowledgeBase = async (
  params: CreateKnowledgeBasePayload
) => {
  const { data: res } = await axiosJSON.post(apiEndPoint, params);
  return res.data as CreateKnowledgeBaseRes;
};
