import { GetConfigModalParams } from "src/features/ReturnAutoWorkFlow/children/ConfigModals/oldReturnModals.types";
import { axiosJSON } from "src/globals/axiosEndPoints";
import { RestockingFeeTab } from "./getRestockingFee.service";

interface Payload extends GetConfigModalParams {
  configTabs: RestockingFeeTab[];
}

async function updateRestockingFeeService(payload: Payload) {
  const { data: res } = await axiosJSON.post(
    "/api/setting/automation/refund/step/15/configuration",
    payload,
  );

  if (res.err || res.error) {
    throw new Error(res.msg ?? res.message ?? "Some Error Occurred.");
  }
}

export default updateRestockingFeeService;
