/**
 * This file defines a react component responsible for rendering external source table
 * also handles selecting articles, reindexing, and deleting source
 *
 * @author @yuvaraj-busibud
 * @author @navjyot-busibud
 */
import {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styles from "./ArticleComp.module.scss";
import dots from "src/assets/images/sixDots.png";
import folder from "src/assets/images/botstatus/folder.png"
import importInProgress from "src/assets/images/importInProgress.gif";
import SearchBar from "src/components/SearchBar/SearchBar";
import ArticleData from "./Children/ArticleData/ArticleData";
import Pagination from "src/routes/TicketList/children/Pagination/Pagination";
import TableHeader from "./Children/ArticleData/TableHeader/TableHeader";
import materialsync from "../../../../../../../../../../../assets/images/botstatus/materialsync.png"
import metrocross from "../../../../../../../../../../../assets/images/botstatus/metrocross.png"

import {
  Source,
  getAllExternal,
} from "src/services/Bot/AnswerSources/getAllExternal.service";
import {
  calculateTotalPages,
  paginationLogic,
} from "src/services/TicketService/pagination";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import useDebounce from "src/hooks/useDebounce";
import { getSourceArticles } from "src/services/Bot/AnswerSources/getSourceArticles.service";
import useSourceArticles from "./useSourceArticles";
import { selectSourceArticle } from "src/services/Bot/AnswerSources/selectSourceArticles.service";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import Loader from "src/components/Loader";
import {
  Button,
  Overlay,
  OverlayTrigger,
  Popover,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import { FreePlanError, FreePlanErrorMessage } from "src/globals/constants";

interface Props {
  source: Source;
  setSearch: (value: string, sourceId: number, sourceName: string) => void;
  search: string;
  updateSource: (id: number, values: Partial<Source>) => void;
  handleDeleteSource: (source: Source) => void;
}

const ArticleComp = ({
  source,
  search,
  setSearch,
  updateSource,
  handleDeleteSource,
}: Props) => {
  const {
    showModal,
    onShow,
    onHide,
    articles,
    isLoading,
    debouncedSearchTerm,
    setCurrentPage,
    currentPage,
    totalPages,
    setShowArticles,
    showArticles,
    updateArticleValue,
    fetchStatus,
    status,
    unSelectAllArticle,
    selectedArticleIds,
    handleRefreshImport,
    handleReindexSource,
    handleResumeImport,
    handleStopImport,
    isReindexingLoading,
    isStopOrResumeImportLoading,
    isAllArticleSelected,
    setIsAllArticleSelected,
    updateArticlebotTrainingStatus

  } = useSourceArticles({
    sourceId: source.id,
    totalArticleCount: source.totalArticleCount,
    updateSource: updateSource,
    source: source,
  });

  const selectArticleMutation = useMutation({
    mutationFn: selectSourceArticle,
    onSuccess: (data, variables, context) => {
      updateArticlebotTrainingStatus(data);
      console.log("after select", articles);
    },
    onError: (error: any) => {
      // console.log("Error: failed to updated column");
      if (
        typeof error == "object" &&
        (error.statusCode === FreePlanError.LIMIT_REACHED ||
          error.statusCode === FreePlanError.RESTRICTED)
      ) {
        const statusCode =
          error.statusCode as keyof typeof FreePlanErrorMessage;
        // If the free plan limit is exceeded, show free plan error
        pushTheToast({
          text: error.message ?? FreePlanErrorMessage[statusCode],
          type: "danger",
          position: "top-right",
        });
      } else {
        pushTheToast({
          type: "danger",
          text: "Failed to select article!",
          position: "top-right",
        });
      }
    },
  });

  const handleSelectArticle = useCallback(
    (articleId: number | string, isSelected: boolean) => {
      selectArticleMutation.mutate({
        isSelected: isSelected,
        articleId: articleId,
        sourceId: source.id,
      });
      if (isAllArticleSelected && !isSelected) {
        setIsAllArticleSelected(false);
      }
      updateArticleValue(parseInt(articleId + ""), { isSelected: isSelected });
    },
    [source.id, isAllArticleSelected],
  );

  const handleSelectAllArticle = useCallback(
    (isSelected: boolean) => {
      if (articles?.length) {
        selectArticleMutation.mutate({
          isAllSelected: isSelected,
          sourceId: source.id,
        });
        unSelectAllArticle(isSelected);
        setIsAllArticleSelected(isSelected);
      }
    },
    [articles?.length, selectArticleMutation, setIsAllArticleSelected, source.id, unSelectAllArticle],
  );


  const errorMessage = useMemo(() => {
    if (
      status === "error" ||
      (status !== "success" && fetchStatus !== "fetching") ||
      (status !== "loading" && articles?.length === 0)
    ) {
      return (
        <span className={`ps-2 mt-3 ${styles.noResultFound}`}>
          No Article Found
        </span>
      );
    }
  }, [status, fetchStatus, articles?.length]);

  const refreshPopover = (
    <Popover id="popover-basic">
      <Popover.Body bsPrefix={`popver ${styles.popBody}`}>
        <span className={`${styles.popText}`}>
          {source.status === "stopped" ? "Resume" : "Refresh"}
        </span>
      </Popover.Body>
    </Popover>
  );
  const deletePopover = (
    <Popover id="popover-basic">
      <Popover.Body bsPrefix={`popver ${styles.popBody}`}>
        <span className={`${styles.popText}`}>Delete</span>
      </Popover.Body>
    </Popover>
  );
  const stopPopover = (
    <Popover id="popover-basic">
      <Popover.Body bsPrefix={`popver ${styles.popBody}`}>
        <span className={`${styles.popText}`}>Stop import</span>
      </Popover.Body>
    </Popover>
  );
  const reindexPopover = (
    <Popover id="popover-basic">
      <Popover.Body bsPrefix={`popver ${styles.popBody}`}>
        <span className={`${styles.popText}`}>Re-index</span>
      </Popover.Body>
    </Popover>
  );

  return (
    <div className={`p-3 w-100 ${styles.compWrapper} my-2 mx-0`}>
      <div
        className="d-flex flex-column flex-md-row justify-content-between "
        onClick={(e) => {
          setShowArticles(!showArticles);
        }}
        role="button"
      >
        <div className={`d-flex align-items-center ${styles.leftSection} `}>
          <img
            src={dots}
            alt=""
            className={`me-2 ${styles.dots}`}
          />
          <div className={`px-1 ${styles.folder}`}>
            <span>
              <img src={folder} alt="folder icon" />
            </span>
          </div>
          <span className={`px-2 ${styles.arrow}`}>
            <i
              className={`fa-solid fa-chevron-${showArticles ? "down" : "right"
                }`}
            ></i>
          </span>
          <span className={`${styles.mail}`}>{source.sourceUrl}</span>
        </div>
        <div
          className={`d-flex flex-column flex-md-row align-items-md-center justify-content-end  ${styles.rightSection}`}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {source.status === "deleting" ? (
            <div
              className={`d-flex mb-2 md-mb-0 flex-md-row  align-items-center me-2 ${styles.progressWrapper}`}
            >
              {/* <ProgressBar now={40} /> */}
              <img
                src={importInProgress}
                alt=""
                className={`${styles.importInProgress}`}
              />
              <span className={`ps-2 ${styles.importText}`}>
                Deleting source ...
              </span>
            </div>
          ) : source.status !== "stopped" && source.status !== "completed" ? (
            <div
              className={`d-flex mb-2 md-mb-0 flex-md-row  align-items-center me-2 ${styles.progressWrapper}`}
            >
              {/* <ProgressBar now={40} /> */}
              <img
                src={importInProgress}
                alt=""
                className={`${styles.importInProgress}`}
              />
              <OverlayTrigger
                trigger="hover"
                placement="top"
                overlay={deletePopover}
              >
                <span
                  className={`ms-2  ${styles.deleteSource}`}
                  onClick={() => handleDeleteSource(source)}
                >
                  <i className="fa-solid fa-trash"></i>
                </span>
              </OverlayTrigger>
              {!isStopOrResumeImportLoading ? (
                <OverlayTrigger
                  trigger="hover"
                  placement="top"
                  overlay={stopPopover}
                >
                  <span
                    className={`ms-2 ${styles.deleteSource}`}
                    onClick={() => {
                      if (!isStopOrResumeImportLoading) {
                        handleStopImport();
                      }
                    }}
                  >
                    {isStopOrResumeImportLoading ? (
                      <Spinner
                        style={{ height: 10, width: 10 }}
                        variant="secondary"
                        animation="border"
                        size="sm"
                      />
                    ) : (
                      <img src={metrocross} alt="refresh" height="8px" width="8px" />
                    )}
                  </span>
                </OverlayTrigger>
              ) : null}

              <span className={`ps-2 ${styles.importText}`}>
                {isStopOrResumeImportLoading
                  ? "Stopping import ..."
                  : source.noOfPagesBeingImported === 0
                    ? `${source.status !== "importing"
                      ? "Syncing..."
                      : `Training completed for ${source.noOfPagesBeingImported} of ${source.totalArticleCount} pages`
                    }`
                    : `${source.noOfPagesBeingImported} pages are being ${source.status !== "importing" ? "synced" : "imported"
                    }...`}
              </span>
            </div>
          ) : source.status === "completed" || source.status === "stopped" ? (
            <div
              className={`d-flex mb-2 md-mb-0 flex-md-row  align-items-center me-2 ${styles.progressWrapper}`}
            >
              <OverlayTrigger
                trigger="hover"
                placement="top"
                overlay={deletePopover}
              >
                <span
                  className={`ms-2  ${styles.deleteSource}`}
                  onClick={() => handleDeleteSource(source)}
                >
                  <i className="fa-solid fa-trash"></i>
                </span>
              </OverlayTrigger>
              {!isStopOrResumeImportLoading ? (
                <OverlayTrigger
                  trigger="hover"
                  placement="top"
                  overlay={refreshPopover}
                >
                  <span
                    className={`ms-2 ${styles.deleteSource}`}
                    onClick={() => {
                      if (!isStopOrResumeImportLoading) {
                        source.status === "stopped"
                          ? handleResumeImport()
                          : handleRefreshImport();
                      }
                    }}
                  >
                    {isStopOrResumeImportLoading ? (
                      <Spinner
                        style={{ height: 10, width: 10 }}
                        variant="secondary"
                        animation="border"
                        size="sm"
                      />
                    ) : source.status === "stopped" ? (
                      <i className="fa-solid fa-rotate-right"></i>
                    ) : (
                      <img src={materialsync} alt="cross" height="14px" width="10px" />
                    )}
                  </span>
                </OverlayTrigger>
              ) : null}

              <div className={`d-flex align-items-start me-5`}>
                {isStopOrResumeImportLoading ? (
                  <span className={`ps-2 ${styles.importText}`}>
                    Resuming import ...
                  </span>
                ) : source.status === "stopped" ? (
                  <span className={`ps-2 ${styles.importText}`}>import</span>
                ) : (
                  <>
                    <div style={{ marginLeft: "11px" }}>
                      <span className={`${styles.checkMsg}`}>
                        <i className="fa-solid fa-check"></i>
                      </span>
                    </div>
                    <span className={`ps-2 ${styles.importText}`}>
                      {source.pagesImported} pages imported
                    </span>
                  </>
                )}
              </div>
              {/* <OverlayTrigger
                trigger="hover"
                placement="top"
                overlay={reindexPopover}
              >
                <span
                  className={`ms-2 ${styles.deleteSource}`}
                  onClick={() => {
                    if (!isReindexingLoading) {
                      handleReindexSource();
                    }
                  }}
                >
                  {isReindexingLoading ? (
                    <Spinner
                      style={{ height: 10, width: 10 }}
                      variant="secondary"
                      animation="border"
                      size="sm"
                    />
                  ) : (
                    <i className="fa-solid fa-arrows-rotate"></i>
                  )}
                </span>
              </OverlayTrigger> */}
            </div>
          ) : null}

          <SearchBar
            className={`${styles.search} px-2`}
            inputClassName={`${styles.input}`}
            placeholder={`Search page`}
            onChange={(e: { target: { value: string } }) => {
              setSearch(e.target.value, source.id, source.sourceUrl);
            }}
            value={search}
            onClick={(e: { stopPropagation: () => void }) => {
              e.stopPropagation();
            }}
          />
        </div>
      </div>
      {/* Table */}
      {showArticles && (
        <div className="mt-3">
          <TableHeader
            isAllArticleSelected={isAllArticleSelected}
            setSelectAll={handleSelectAllArticle}
          />
          {errorMessage ? (
            errorMessage
          ) : isLoading && !articles?.length ? (
            <Loader className={`${styles.articleLoader}`} />
          ) : (
            <div>
              {articles?.map((article) => {
                return (
                  <>
                    <ArticleData
                      key={article.articleId}
                      article={article}
                      handleSelectArticle={handleSelectArticle}
                      isAllArticleSelected={isAllArticleSelected}
                      sourceId={source.id}
                      updateArticleValue={updateArticleValue}
                      updateArticlebotTrainingStatus={updateArticlebotTrainingStatus}
                    />
                  </>
                );
              })}
              <div
                className="pt-2"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {source.totalArticleCount > 1 && (
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    setCurrentPage={(selectedPage) => {
                      setCurrentPage(selectedPage);
                    }}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ArticleComp;
