/**
 * This file is the React component file.
 *
 * @author Yash Aditya
 */

// Importing necessary dependencies and components
import { useQuery } from "@tanstack/react-query";
import { Dropdown, Spinner } from "react-bootstrap";
import getSendingFormatService, {
  SendingFormatData,
} from "src/services/report/getSendingFormat.service";
import {
  ReportNotification,
  reportNotificationsActions,
  useNotifications,
} from "../../../../hooks/notifications/useNotifications";
import styles from "./SendingFormat.module.scss";
import { useCallback, useEffect, useRef, useState } from "react";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import updateNotificationService from "src/services/report/updateNotification.service";
import useNotifyPermission from "../../../../hooks/notifications/useNotifyPermission";

// Defining the Props interface
interface Props {
  notificationData: ReportNotification;
}

// Custom hook to handle sending format-related actions
const useSendingFormat = ({ notificationData }: Props) => {
  // Querying for sending formats data using react-query
  const { data: allSendingFormat, status } = useQuery(
    ["report/getSendingFormatService"],
    {
      queryFn: getSendingFormatService,
      staleTime: 600000,
      cacheTime: 600000,
    }
  );

  // State to manage loading state for sending format changes
  const [formatLoading, setFormatLoading] = useState(false);

  // Getting notifications context and dispatch function from the custom hook
  const { reportNotifications, dispatch } = useNotifications();

  // Callback to handle changing the sending format
  const changeSendingFormat = useCallback(
    (e: any) => {
      setFormatLoading(true);
      const params = {
        id: notificationData.id,
        sendingFormatId: e.currentTarget.getAttribute("data-sending-format-id"),
      };
      if (reportNotifications.context) {
        updateNotificationService({
          context: reportNotifications.context,
          ...params,
        })
          .then((res) => {
            // Dispatching the changed notification data
            dispatch([
              reportNotificationsActions.changeNotificationData,
              params,
            ]);
          })
          .catch((err) => {
            // Displaying an error toast if changing sending format fails
            pushTheToast({
              type: "danger",
              position: "top-right",
              text: "Error changing sending format",
            });
          })
          .finally(() => {
            setFormatLoading(false);
          });
      }
    },
    [notificationData, reportNotifications.context]
  );

  // Returning necessary values and functions for the SendingFormat component
  return { allSendingFormat, changeSendingFormat, formatLoading };
};

// Interface for TheSendingFormat component props
interface TheSendingFormatProps extends Props {
  allSendingFormat?: SendingFormatData;
  changeSendingFormat: (e: any) => void;
  formatLoading: boolean;
  disabled?: boolean;
}

// Dropdown menu component for selecting sending format
export const TheSendingFormat = ({
  notificationData,
  allSendingFormat,
  changeSendingFormat,
  formatLoading,
  disabled = false,
}: TheSendingFormatProps) => {
  // State to manage the visibility of the dropdown menu
  const [show, setShow] = useState(false);
  // Reference for the dropdown menu target
  const menuTarget = useRef(null as any);

  // Callback to close the dropdown menu after selecting a sending format
  const changeSendingFormatAndClose = useCallback(
    (e: any) => {
      setShow(false);
      changeSendingFormat(e);
    },
    [changeSendingFormat]
  );

  // Callback to toggle the visibility of the dropdown menu
  const toggleMenu = useCallback(() => {
    if (!disabled) {
      setShow((prev) => !prev);
    }
  }, [setShow, disabled]);

  // Effect to close the dropdown menu when clicking outside of it
  useEffect(() => {
    const windowClick = (e: any) => {
      if (!e.target.closest(".disable-click-area-window")) {
        setShow(false);
      }
    };
    window.addEventListener("click", windowClick);

    return () => {
      window.removeEventListener("click", windowClick);
    };
  }, [setShow]);

  // Rendering the SendingFormat component
  return (
    <Dropdown show={show}>
      <Dropdown.Toggle
        as="div"
        disabled={formatLoading}
        bsPrefix={`${styles.dropdownBtn} disable-click-area-window`}
        onClick={toggleMenu}
        style={{ cursor: disabled ? "not-allowed" : "pointer" }}
      >
        {/* Displaying the selected sending format or a default message */}
        <span className={`d-block text-truncate ${styles.buttonText}`}>
          {allSendingFormat?.sendingFormats[notificationData.sendingFormatId]
            ?.name ?? "Select Sending Format"}
        </span>
        {/* Displaying a loading spinner during sending format changes */}
        <span className="ms-1">
          {formatLoading ? (
            <Spinner animation="border" size="sm" />
          ) : (
            <i className="fa-solid fa-caret-down"></i>
          )}
        </span>
      </Dropdown.Toggle>

      {/* Dropdown menu for selecting sending format */}
      <Dropdown.Menu
        ref={menuTarget}
        bsPrefix={`dropdown-menu p-2 ${styles.dropMenu} disable-click-area-window`}
      >
        <div>
          {/* Displaying the list of available sending formats */}
          {allSendingFormat?.sendingFormatIds.map((id) => {
            return (
              <span
                data-sending-format-id={id}
                key={id}
                className={`${styles.items}`}
                onClick={changeSendingFormatAndClose}
              >
                {allSendingFormat.sendingFormats[id].name}
              </span>
            );
          })}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

// Main SendingFormat component
const SendingFormat = ({ notificationData }: Props) => {
  // Using the custom hook to get sending format data and related functions
  const { allSendingFormat, changeSendingFormat, formatLoading } =
    useSendingFormat({ notificationData });
  const notifyPermission = useNotifyPermission();

  // Rendering TheSendingFormat component with obtained data and functions
  return (
    <TheSendingFormat
      changeSendingFormat={changeSendingFormat}
      formatLoading={formatLoading}
      notificationData={notificationData}
      allSendingFormat={allSendingFormat}
      disabled={notifyPermission !== "edit"}
    />
  );
};

// Exporting the SendingFormat component
export default SendingFormat;
