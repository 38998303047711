import { AJAXSTATUS } from "src/globals/constants";

export interface AgentInfo {
  id: null | string | number;
  name: null | string;
  profilePic: string | null;
  isPublicAttachmentUrl?: boolean;
}
export interface BrandInfo {
  id: null | string | number;
  name: null | string;
  brandPic: string | null;
}

export interface CallerTypes {
  id: number | string;
  name: string;
}

export interface CallerData {
  callerId: string | number;
  caller: string; //Will be {(isd|std)Code + phoneNumber}
  callType: CallerTypes;
  agent: AgentInfo;
  brand: BrandInfo;
  callReceivedDate: string;
  callReceivedDateUTC: string;
  recordedVoiceURL: string;
}

export interface CallRecordingSlice {
  allCallerTypes: { [id: string | number]: CallerTypes };
  callerData: { [callerId: string | number]: CallerData };
  callerIdList: Array<number | string>;
  callerDataAjaxStatus: AJAXSTATUS;
  callerDataMeta: {
    current: number;
    totalCount: number;
  };
  cachedData: { [pageNo: string | number]: Array<number | string> };
  noOfPages: number;
  currentPage: number;
  rowsPerPage: number;
  limitOfCallerData: number;
  cachingPageNoLimit: number;
}

export const initialState: CallRecordingSlice = {
  allCallerTypes: {},
  callerData: {},
  callerIdList: [],
  callerDataAjaxStatus: "pending",
  callerDataMeta: {
    current: 0,
    totalCount: 0,
  },
  noOfPages: 0,
  currentPage: 0,
  rowsPerPage: 10,
  limitOfCallerData: 50,
  cachedData: {},
  cachingPageNoLimit: 2,
};
