import { initialState, RoutingRulesSetting } from "./RoutingRulesSetting.slice";

// reset routingRule settings
const resetRoutingRuleSettings = (state: RoutingRulesSetting) => {
  state = {...initialState};
  return state;
};

// reset routingRules data
const resetRoutingRulesData = (state: RoutingRulesSetting) => {
  state.routingRuleData = {};
  state.routingRuleIdList = [];
  state.fetchRoutingRulesAjaxStatus = "pending";
  state.metaData.count =0;
  state.metaData.total =0;
};


export default { 
  resetRoutingRuleSettings,
  resetRoutingRulesData, 
};