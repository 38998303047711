import {useMemo} from "react";
import Loader from "src/components/Loader";

function useCategoryLoader(showLoader: boolean, className?: string) {
  return useMemo(() => {
    if (showLoader) {
      return (
        <div className={`d-flex flex-column m-auto ${className}`}>
          <Loader />
        </div>
      );
    } else {
      return undefined;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showLoader]);
}

export default useCategoryLoader;
