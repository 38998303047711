import { Modal } from "react-bootstrap";
import styles from "../../ConfigureSteps.module.scss";
import ConfigureModal from "../../../ConfigureModal/ConfigureModal";
import Lottie from "lottie-light-react";
import ripple from "src/assets/images/ripple.json";
import TextBox from "../TextBox/TextBox";
import FormModal from "../../../FormModal/FormModal";
import {
  CheckboxWrapper,
  QuestionWrapper,
  RadioWrapper,
} from "../Wrappers/Wrappers";
import useLiveChatSettings from "./useLiveChatSettings";
import { ConfigureSteps } from "../../useConfigureSteps";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { UseMutateFunction } from "@tanstack/react-query";
import { CreateBotProfileResponse } from "src/services/Bot/BotProfiles/createBotProfile.service";
import { UpdateBotProfileParams } from "src/services/Bot/BotProfiles/updateBotProfile.service";
import { useMemo } from "react";

interface Props {
  refetchGetBot: () => void;
  configureSteps: ConfigureSteps;
  updateBotSettings: UseMutateFunction<
    CreateBotProfileResponse,
    unknown,
    UpdateBotProfileParams,
    unknown
  >;
}

/**
 * Component for showing Live Chat Settings in Bot profile Setup
 */
const LiveChatSettings = ({
  configureSteps,
  updateBotSettings,
  refetchGetBot,
}: Props) => {
  const { showStep, onHideShow } = useLiveChatSettings(configureSteps);

  const { state, dispatch } = configureSteps;

  const showingEmail = useMemo(
    () => (state.botProfile.channels?.includes("5") ? true : false),
    [state],
  );

  return (
    <div
      className="d-flex position-relative mb-2"
      id={`live_${state.botProfile.id}`}
    >
      {/* Active Step Line */}
      <div className={`d-flex flex-column align-items-center me-2`}>
        <div>
          <span
            className={`${
              configureSteps.state.botProfile.visibleQuestionKeys?.includes(
                "need_user_details",
              )
                ? styles.doneCount
                : styles.count
            }`}
          >
            {configureSteps.state.botProfile.visibleQuestionKeys?.includes(
              "need_user_details",
            ) ? (
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
            ) : (
              <span>{showingEmail ? "3" : "2"}</span>
            )}
          </span>
        </div>

        <div className={`${styles.statusBar} ${styles.statusBarOne}`}></div>
      </div>

      {/* Step Box */}
      <div className={`p-3 w-100 ${styles.stepBox}`}>
        {/* Header and collapse */}
        <div
          className="cursor-pointer"
          onClick={onHideShow}
        >
          <h6 className={`mb-0 ${styles.stepHead}`}>Live Chat</h6>
          <span className={`${styles.stepSpan}`}>
            Live chat specific bot settings.
          </span>
        </div>
        {showStep && (
          <>
            {/* Question 11 */}
            {configureSteps.state.botProfile.visibleQuestionKeys?.includes(
              "answer_content",
            ) && (
              <>
                <QuestionWrapper
                  question={`Q${
                    showingEmail ? "10" : "5"
                  }. How cern will introduce himself in the first message?`}
                >
                  <div className="w-100">
                    <TextBox
                      value={state.botProfile.introductionMessage}
                      dispatch={configureSteps.dispatch}
                    />
                  </div>
                </QuestionWrapper>

                {!configureSteps.state.botProfile.visibleQuestionKeys?.includes(
                  "introduction_message",
                ) && (
                  <div className="d-flex align-items-center">
                    <button
                      className={`${styles.moveBtn}`}
                      onClick={() => {
                        if (state.botProfile.introductionMessage?.length > 0) {
                          configureSteps.handleMoveToNextStep("introMessage");
                        }
                      }}
                    >
                      <span style={{ width: "33px", height: "33px" }}>
                        <Lottie animationData={ripple}></Lottie>
                      </span>
                      <span>Move to next question</span>
                    </button>
                  </div>
                )}
              </>
            )}

            {/* Question 12 */}
            {configureSteps.state.botProfile.visibleQuestionKeys?.includes(
              "introduction_message",
            ) && (
              <>
                <QuestionWrapper
                  question={`Q${
                    showingEmail ? "11" : "6"
                  }. Who can connect to cern?`}
                >
                  {/* Users who do not have orders  */}
                  <CheckboxWrapper
                    checked={state.botProfile.eligibleUsers?.includes("1")}
                    label="Authenticated/Logged in users who do not have an order"
                    onChange={() => {
                      if (state.botProfile.eligibleUsers?.includes("1")) {
                        if (state.botProfile.eligibleUsers?.length === 1) {
                          pushTheToast({
                            text: "You can not remove all audience",
                            position: "top-right",
                            type: "warning",
                          });
                          return;
                        }
                        dispatch({
                          type: "UPDATE_ELIGIBLE_USERS",
                          payload: state.botProfile.eligibleUsers?.filter(
                            (item: string) => item !== "1",
                          ),
                        });
                        updateBotSettings(
                          {
                            id: state.botProfile.id,
                            eligible_users:
                              state.botProfile.eligibleUsers?.filter(
                                (item: string) => item !== "1",
                              ),
                          },
                          {
                            onSuccess: (data) => {
                              if (
                                data.statusCode &&
                                data.statusCode !== undefined
                              ) {
                                refetchGetBot();
                                dispatch({
                                  type: "UPDATE_STATUS",
                                  payload: "draft",
                                });
                              }
                            },
                          },
                        );
                      } else {
                        dispatch({
                          type: "UPDATE_ELIGIBLE_USERS",
                          payload: [...state.botProfile.eligibleUsers, "1"],
                        });
                        updateBotSettings(
                          {
                            id: state.botProfile.id,
                            eligible_users: [
                              ...state.botProfile.eligibleUsers,
                              "1",
                            ],
                          },
                          {
                            onSuccess: (data) => {
                              if (
                                data.statusCode &&
                                data.statusCode !== undefined
                              ) {
                                refetchGetBot();
                                dispatch({
                                  type: "UPDATE_STATUS",
                                  payload: "draft",
                                });
                              }
                            },
                          },
                        );
                      }
                    }}
                  />
                  {/* Users who have orders  */}
                  <CheckboxWrapper
                    checked={state.botProfile.eligibleUsers?.includes("2")}
                    label="Authenticated/Logged in users who have an order"
                    onChange={() => {
                      if (state.botProfile.eligibleUsers?.includes("2")) {
                        if (state.botProfile.eligibleUsers?.length === 1) {
                          pushTheToast({
                            text: "You can not remove all audience",
                            position: "top-right",
                            type: "warning",
                          });
                          return;
                        }
                        dispatch({
                          type: "UPDATE_ELIGIBLE_USERS",
                          payload: state.botProfile.eligibleUsers?.filter(
                            (item: string) => item !== "2",
                          ),
                        });
                        updateBotSettings(
                          {
                            id: state.botProfile.id,
                            eligible_users:
                              state.botProfile.eligibleUsers?.filter(
                                (item: string) => item !== "2",
                              ),
                          },
                          {
                            onSuccess: (data) => {
                              if (
                                data.statusCode &&
                                data.statusCode !== undefined
                              ) {
                                refetchGetBot();
                                dispatch({
                                  type: "UPDATE_STATUS",
                                  payload: "draft",
                                });
                              }
                            },
                          },
                        );
                      } else {
                        dispatch({
                          type: "UPDATE_ELIGIBLE_USERS",
                          payload: [...state.botProfile.eligibleUsers, "2"],
                        });
                        updateBotSettings(
                          {
                            id: state.botProfile.id,
                            eligible_users: [
                              ...state.botProfile.eligibleUsers,
                              "2",
                            ],
                          },
                          {
                            onSuccess: (data) => {
                              if (
                                data.statusCode &&
                                data.statusCode !== undefined
                              ) {
                                refetchGetBot();
                                dispatch({
                                  type: "UPDATE_STATUS",
                                  payload: "draft",
                                });
                              }
                            },
                          },
                        );
                      }
                    }}
                  />
                  {/* Users Not Logged In */}
                  <CheckboxWrapper
                    checked={state.botProfile.eligibleUsers?.includes("3")}
                    label="Users not logged in"
                    onChange={(e) => {
                      if (state.botProfile.eligibleUsers?.includes("3")) {
                        if (state.botProfile.eligibleUsers?.length === 1) {
                          pushTheToast({
                            text: "You can not remove all audience",
                            position: "top-right",
                            type: "warning",
                          });
                          return;
                        }
                        dispatch({
                          type: "UPDATE_ELIGIBLE_USERS",
                          payload: state.botProfile.eligibleUsers?.filter(
                            (item: string) => item !== "3",
                          ),
                        });
                        updateBotSettings(
                          {
                            id: state.botProfile.id,
                            eligible_users:
                              state.botProfile.eligibleUsers?.filter(
                                (item: string) => item !== "3",
                              ),
                          },
                          {
                            onSuccess: (data) => {
                              if (
                                data.statusCode &&
                                data.statusCode !== undefined
                              ) {
                                refetchGetBot();
                                dispatch({
                                  type: "UPDATE_STATUS",
                                  payload: "draft",
                                });
                              }
                            },
                          },
                        );
                      } else {
                        dispatch({
                          type: "UPDATE_ELIGIBLE_USERS",
                          payload: [...state.botProfile.eligibleUsers, "3"],
                        });
                        updateBotSettings(
                          {
                            id: state.botProfile.id,
                            eligible_users: [
                              ...state.botProfile.eligibleUsers,
                              "3",
                            ],
                          },
                          {
                            onSuccess: (data) => {
                              if (
                                data.statusCode &&
                                data.statusCode !== undefined
                              ) {
                                refetchGetBot();
                                dispatch({
                                  type: "UPDATE_STATUS",
                                  payload: "draft",
                                });
                              }
                            },
                          },
                        );
                      }
                    }}
                  />
                </QuestionWrapper>

                {/* Buttons */}
                <div className="d-flex align-items-center">
                  <button
                    className={`me-2 ${styles.configureBtn}`}
                    onClick={configureSteps.onShow}
                    id="configureAudienceBtn"
                  >
                    + Configure more audience
                  </button>
                  <Modal
                    backdropClassName={`${styles.modalBack}`}
                    show={configureSteps.showModal}
                    onHide={configureSteps.onHide}
                    dialogClassName={`${styles.modalDialog}`}
                    contentClassName={`${styles.modalContent}`}
                  >
                    <ConfigureModal
                      profileId={configureSteps.id}
                      onHide={configureSteps.onHide}
                    />
                  </Modal>
                  {!configureSteps.state.botProfile.visibleQuestionKeys?.includes(
                    "eligible_users",
                  ) && (
                    <button
                      className={`${styles.moveBtn}`}
                      onClick={() => {
                        if (state.botProfile.eligibleUsers.length > 0) {
                          configureSteps.handleMoveToNextStep("audience");
                        }
                      }}
                      id="moveNextBtn"
                    >
                      <span style={{ width: "33px", height: "33px" }}>
                        <Lottie animationData={ripple}></Lottie>
                      </span>
                      <span>Move to next question</span>
                    </button>
                  )}
                </div>
              </>
            )}

            {configureSteps.state.botProfile.visibleQuestionKeys?.includes(
              "eligible_users",
            ) && (
              <>
                {/* Question 13 */}
                <QuestionWrapper
                  question={`Q${
                    showingEmail ? "12" : "7"
                  }. How many times cern will answer the questions?`}
                >
                  <RadioWrapper
                    name="botAnswerStrategy"
                    checked={state.botProfile.botAnswerStrategy === "1"}
                    label="Answer a single time and always transfer the ticket to an agent if
        the customer responds."
                    onChange={() => {
                      dispatch({
                        type: "UPDATE_BOT_ANSWER_STRATEGY",
                        payload: "1",
                      });
                      updateBotSettings(
                        {
                          id: state.botProfile.id,
                          bot_answer_strategy: "1",
                        },
                        {
                          onSuccess: (data) => {
                            if (
                              data.statusCode &&
                              data.statusCode !== undefined
                            ) {
                              refetchGetBot();
                              dispatch({
                                type: "UPDATE_STATUS",
                                payload: "draft",
                              });
                            }
                          },
                        },
                      );
                    }}
                  />
                  <RadioWrapper
                    name="botAnswerStrategy"
                    checked={state.botProfile.botAnswerStrategy === "2"}
                    label="Answer multiple times and only transfer to an agent if the
        customer chooses the 'Talk to a human' option."
                    value="2"
                    onChange={() => {
                      dispatch({
                        type: "UPDATE_BOT_ANSWER_STRATEGY",
                        payload: "2",
                      });
                      updateBotSettings(
                        {
                          id: state.botProfile.id,
                          bot_answer_strategy: "2",
                        },
                        {
                          onSuccess: (data) => {
                            if (
                              data.statusCode &&
                              data.statusCode !== undefined
                            ) {
                              refetchGetBot();
                              dispatch({
                                type: "UPDATE_STATUS",
                                payload: "draft",
                              });
                            }
                          },
                        },
                      );
                    }}
                  />
                  <RadioWrapper
                    name="botAnswerStrategy"
                    checked={state.botProfile.botAnswerStrategy === "3"}
                    value="3"
                    label="Answer multiple times and do not show the option to 'Talk to a
        human'."
                    onChange={() => {
                      dispatch({
                        type: "UPDATE_BOT_ANSWER_STRATEGY",
                        payload: "3",
                      });
                      updateBotSettings(
                        {
                          id: state.botProfile.id,
                          bot_answer_strategy: "3",
                        },
                        {
                          onSuccess: (data) => {
                            if (
                              data.statusCode &&
                              data.statusCode !== undefined
                            ) {
                              refetchGetBot();
                              dispatch({
                                type: "UPDATE_STATUS",
                                payload: "draft",
                              });
                            }
                          },
                        },
                      );
                    }}
                  />
                </QuestionWrapper>

                {!configureSteps.state.botProfile.visibleQuestionKeys?.includes(
                  "bot_answer_strategy",
                ) && (
                  <div className="d-flex align-items-center">
                    <button
                      className={`${styles.moveBtn}`}
                      onClick={() => {
                        if (state.botProfile.botAnswerStrategy) {
                          configureSteps.handleMoveToNextStep(
                            "answerOnceOrMultiple",
                          );
                        }
                      }}
                    >
                      <span style={{ width: "33px", height: "33px" }}>
                        <Lottie animationData={ripple}></Lottie>
                      </span>
                      <span>Move to next question</span>
                    </button>
                  </div>
                )}
              </>
            )}

            {configureSteps.state.botProfile.visibleQuestionKeys?.includes(
              "bot_answer_strategy",
            ) && (
              <>
                {/* Question 14 */}
                <QuestionWrapper
                  question={`Q${
                    showingEmail ? "13" : "8"
                  }. Are there additional details that you would like to collect
      from the user before handing over to an agent?`}
                >
                  <RadioWrapper
                    name="additional-details"
                    checked={state.botProfile.needUserDetails === true}
                    label="Yes"
                    value="true"
                    onChange={(e) => {
                      dispatch({
                        type: "UPDATE_NEED_USER_DETAILS",
                        payload: true,
                      });
                      updateBotSettings(
                        {
                          id: state.botProfile.id,
                          need_user_details: true,
                        },
                        {
                          onSuccess: (data) => {
                            if (
                              data.statusCode &&
                              data.statusCode !== undefined
                            ) {
                              refetchGetBot();
                              dispatch({
                                type: "UPDATE_STATUS",
                                payload: "draft",
                              });
                            }
                          },
                        },
                      );
                    }}
                  />
                  <RadioWrapper
                    name="additional-details"
                    checked={state.botProfile.needUserDetails === false}
                    label="No"
                    value="false"
                    onChange={(e) => {
                      dispatch({
                        type: "UPDATE_NEED_USER_DETAILS",
                        payload: false,
                      });
                      updateBotSettings(
                        {
                          id: state.botProfile.id,
                          need_user_details: false,
                        },
                        {
                          onSuccess: (data) => {
                            if (
                              data.statusCode &&
                              data.statusCode !== undefined
                            ) {
                              refetchGetBot();
                              dispatch({
                                type: "UPDATE_STATUS",
                                payload: "draft",
                              });
                            }
                          },
                        },
                      );
                    }}
                  />
                </QuestionWrapper>
                <div className="d-flex align-items-start">
                  {state.botProfile.needUserDetails === true && (
                    <div>
                      <button
                        className={`me-2 ${styles.configureBtn}`}
                        onClick={configureSteps.modalShow}
                      >
                        + Configure form
                      </button>
                      <Modal
                        backdropClassName={`${styles.modalBack}`}
                        show={configureSteps.showformModal}
                        onHide={configureSteps.modalHide}
                        dialogClassName={`${styles.modalDialog}`}
                        contentClassName={`${styles.modalContent}`}
                      >
                        <FormModal
                          onHide={configureSteps.modalHide}
                          onSaveComplete={() => {
                            dispatch({
                              type: "UPDATE_CONFIGURE_FORM_STATUS",
                              payload: "completed",
                            });
                            configureSteps.modalHide();
                          }}
                          profileId={configureSteps.id}
                        />
                      </Modal>
                      {state.botProfile.configureFormStatus === "pending" ? (
                        <span className={`${styles.pendingText}`}>
                          <i className="fa-solid fa-circle-exclamation me-1"></i>
                          Pending
                        </span>
                      ) : null}
                    </div>
                  )}

                  {!configureSteps.state.botProfile.visibleQuestionKeys?.includes(
                    "need_user_details",
                  ) ? (
                    <button
                      className={`${styles.moveBtn}`}
                      onClick={() => {
                        if (state.botProfile.needUserDetails !== null) {
                          configureSteps.handleMoveToNextStep(
                            "handoverToHuman",
                          );
                        } else {
                          pushTheToast({
                            text: "Please select atleast one answer strategy",
                            position: "top-right",
                            type: "warning",
                          });
                        }
                      }}
                    >
                      <span style={{ width: "33px", height: "33px" }}>
                        <Lottie animationData={ripple}></Lottie>
                      </span>
                      <span>Move to next question</span>
                    </button>
                  ) : null}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default LiveChatSettings;
