/**
 * This file defines a React component responsible for rendering form elements,
 * handling CRUD operations form elements, implementing drag-and-drop functionality,
 * and managing the process of saving and discarding changes in the bot configuration form.
 *
 * @author @yash-busibud
 * @author @ayush-busibud
 * @author @navjyot-busibud
 */
import Loader from "src/components/Loader";
import EditEmail, { IVariableB } from "./Children/EditEmail/EditEmail";
import EmailPreview from "./Children/EmailPreview/EmailPreview";

import useConfigureEmailModal from "./useConfigureEmailModal";

import styles from "./ConfigureEmailModal.module.scss";
import { Spinner } from "react-bootstrap";
import useUserSettings from "../../../../../UserSetting/useUserSettings";
interface Props {
  onHide: () => void;
  profileId: number;
  data: {
    salutation: string;
    introduction: string;
    questionRegardingResolution: string;
  };
  handleEmailModalDataChange: (key: string, value: any) => void;
  variablesList?: {
    [x: string]: IVariableB;
  };
  variableIdsList?: string[];
  companyName: string;
}

/**
 * Component for showing Configure Email Modal
 */
const ConfigureEmailModal = ({
  onHide,
  profileId,
  data,
  handleEmailModalDataChange,
  variablesList,
  variableIdsList,
  companyName,
}: Props) => {
  const {
    handleSubmit,
    saveButtonText,
    showSaveLoader,
    onChange,
    showError,
    editedData,
  } = useConfigureEmailModal({
    profileId,
    data,
    handleEmailModalDataChange,
    variablesList,
    onHide,
  });

  return (
    <div className={`${styles.modalWrapper}`}>
      {/* Header */}
      <div className="d-flex align-items-center pt-3">
        {/* Cross Button */}
        <div>
          <div
            className={`${styles.closeModal}`}
            onClick={onHide}
          >
            <span className="cursor-pointer">
              <i className="fa-solid fa-xmark"></i>
            </span>
          </div>
        </div>

        {/* Heading */}
        <h3 className={`mb-0 ms-2 ${styles.heading}`}>
          Configure how the email with Cern's response will look like.
        </h3>
      </div>
      {/* Content */}
      <div className={`${styles.mainContent}`}>
        <div
          className={`d-flex flex-column flex-md-row ${styles.contentWrapper}`}
          style={{ height: "calc(100vh - 200px)" }}
        >
          {/* Left Section */}
          <div className={`p-4 ${styles.leftSection}`}>
            <h4 className={`${styles.header}`}>Edit email</h4>
            <div className={`${styles.container}`}>
              {/* Salutation */}
              <EditEmail
                heading="Salutation:"
                placeholder="Please enter the salutation bot will use"
                initialValue={data?.salutation ?? ""}
                onChange={(val: string) => onChange("salutation", val)}
                showErrors={showError}
                variablesList={variablesList}
                variableIdsList={variableIdsList}
              />
              {/* Introduction */}
              <EditEmail
                heading="Introduction:"
                placeholder="Please enter the introduction bot will use"
                initialValue={data?.introduction ?? ""}
                onChange={(val: string) => onChange("introduction", val)}
                showErrors={showError}
                variablesList={variablesList}
                variableIdsList={variableIdsList}
              />
              {/* Question regarding resolution */}
              <EditEmail
                heading="Question regarding resolution:"
                placeholder="Please enter the question to use for resolution"
                initialValue={data?.questionRegardingResolution ?? ""}
                onChange={(val: string) =>
                  onChange("questionRegardingResolution", val)
                }
                hideVariables
                showErrors={showError}
                variablesList={variablesList}
                variableIdsList={variableIdsList}
              />
            </div>
          </div>

          {/* Right Section - Email Preview*/}
          <div className={`${styles.rightSection} text-center`}>
            <EmailPreview
              model="email"
              companyName={companyName}
              data={editedData}
              variableList={variablesList}
            />
          </div>
        </div>
      </div>

      {/* Footer with Save and Discard buttons */}
      <div
        className={`d-flex justify-content-end align-items-center px-3 ${styles.btnWrapper}`}
      >
        {/* Discard Button */}
        <button
          className={`me-2 ${styles.discardBtn}`}
          onClick={onHide}
        >
          Discard changes
        </button>

        {/* Save Button */}
        <button
          className={`${styles.saveBtn}`}
          disabled={showSaveLoader || saveButtonText === "Saved!"}
          onClick={handleSubmit}
        >
          {showSaveLoader && (
            <Spinner
              className="my-auto mx-1"
              animation="border"
              color="white"
              size="sm"
            />
          )}
          <span>{saveButtonText}</span>
        </button>
      </div>
    </div>
  );
};

export default ConfigureEmailModal;
