import {
  createAsyncThunk,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { AJAXSTATUS } from "src/globals/constants";
import { IChatFormElementOptionList } from "src/services/LiveChat/Settings/preChatForm/getPreChatFormData";
import { IPostChatFormData } from "../chatForm.declarations";
import postChatFormSettingExtraReducers from "./postChatFormSetting.extraReducers";
import postChatFormSettingReducers from "./postChatFormSetting.reducers";
import postChatFormSettingThunks from "./postChatFormSetting.thunks";

export const fetchPostChatFormData = createAsyncThunk(
  "postChatFormSetting/fetchPostChatFormData",
  postChatFormSettingThunks.fetchPostChatFormDataThunk
);

export interface IPostChatFormSetting{
  postChatFormData: IPostChatFormData;
  formElementOptions: IChatFormElementOptionList;
  postChatFormDataAjaxStatus: AJAXSTATUS;
  updatePostChatFormDataAjaxStatus: AJAXSTATUS;
}

export const initialState : IPostChatFormSetting = {
  postChatFormData: {
    formId: 0,
    enabled: false,
    integrationId: 0,
    elements: [],
  },
  formElementOptions:{
    formElementOptions: {},
    formElementOptionIds: [],
  },
  postChatFormDataAjaxStatus: "pending",
  updatePostChatFormDataAjaxStatus: "idle",
}

export const postChatFormSettingSlice = createSlice({
  name: "postChatFormSetting",
  initialState,
  reducers: postChatFormSettingReducers,
  extraReducers: (builder) => {
    builder.addCase(
      fetchPostChatFormData.fulfilled,
      postChatFormSettingExtraReducers.fetchPostChatFormDataFulfilled
    );
    builder.addCase(fetchPostChatFormData.rejected, (state) => {
      state.postChatFormDataAjaxStatus = "rejected";
      pushTheToast({
        position: "top-right",
        type: "danger",
        text: "Something went wrong!"
      });
    });
    builder.addCase(fetchPostChatFormData.pending, (state) => {
      state.postChatFormDataAjaxStatus = "pending";
    });
  }
});

export const { resetPostChatFormSetting } = postChatFormSettingSlice.actions;

export default postChatFormSettingSlice.reducer;
