import { useCallback, useMemo } from "react";
import { useAutomationContext } from "src/routes/BotSettings/Children/Automation/Hooks/useAutomationContext";
import { EAutomationType } from "src/routes/BotSettings/Children/AutomationList/AutomationList";
import {
  IAction,
  ISimpleListOption,
  QuestionType,
  QuestionVisibility,
} from "src/services/Automation/getAutomationById.service";
import ConfigurationAction from "../ConfigurationActions/ConfigurationAction";

const USER_SEGMENT_BUG_QUESTION_KEY = "allowedBugReportUserSegments";
const USER_SEGMENT_ISSUE_DISPATCH_QUESTION_KEY =
  "allowedIssueDispatchUserSegments";

interface Props {
  questionKey: string;
  actionIdx: number;
  data: IAction;
  isBrandRequired?: boolean;
}

const QuestionActions = ({
  data,
  actionIdx,
  questionKey,
  isBrandRequired,
}: Props) => {
  const { formData, updateQuestions, refetchAutomation, type } =
    useAutomationContext();

  const updateActionStatus = useCallback(
    (status: IAction["status"]) => {
      const question = formData.questionsData[questionKey];
      // Check if we have actions array
      const actionsArray = question.action;

      if (!actionsArray) {
        return;
      }

      // Check if the status is not same and needs to update
      if (actionsArray[actionIdx].status === status) {
        return;
      }

      const actions: IAction[] =
        question.action?.map((a, idx) =>
          idx === actionIdx ? { ...a, status } : a,
        ) ?? [];

      const isAnswered =
        status === "pending"
          ? false
          : question.questionType === QuestionType.ConfigOnly &&
              status === "completed"
            ? true
            : question.isAnswered;

      updateQuestions({
        ...question,
        isAnswered,
        action: actions,
      });
    },
    [actionIdx, formData.questionsData, questionKey, updateQuestions],
  );

  const disableRefetch = useMemo(() => {
    const questionKey =
      type === EAutomationType.BUG_MANAGEMENT
        ? USER_SEGMENT_BUG_QUESTION_KEY
        : type === EAutomationType.ISSUE_DISPATCH
          ? USER_SEGMENT_ISSUE_DISPATCH_QUESTION_KEY
          : "";

    const question = formData.questionsData[questionKey];

    return (
      data.key === "dispatchRules" &&
      question &&
      // Question is needed
      question.visibility !== QuestionVisibility.RelativeHidden &&
      // However, there is no user segment data to fetch yet then disable refetch
      (question.questionValue as string[]).length === 0 &&
      (question.listOfOptions as ISimpleListOption[]).length === 0
    );
  }, [data.key, formData.questionsData, type]);

  switch (data.type) {
    case "configuration":
      return (
        <ConfigurationAction
          data={data}
          isQuestionDisabled={
            isBrandRequired === true && formData.brand === null
          }
          updateActionStatus={updateActionStatus}
          integrationId={formData.integrationId}
          automationType={type ?? EAutomationType.BUG_MANAGEMENT}
          refetchAutomation={refetchAutomation}
          disableRefetch={disableRefetch}
        />
      );
    default:
      return null;
  }
};

export default QuestionActions;
