import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { logoutUser } from "src/hooks/auth/useLogin";
import { useAppDispatch, useAppSelector } from "src/store/store";
import AxiosImg from "../AxiosImg";
import UserAvatar from "../UserAvatar";
import styles from "./LogoutDiv.module.scss";
import useChangeChatAcceptStatus from "src/hooks/useChangeChatAcceptStatus";
import {
  setCurrentUserData,
  updateCurrentUserData,
} from "src/store/slices/globals/globals.slice";
import { getCurrentUser } from "src/services/Settings/Users/getCurrentUser";
/**
 * Function to determine the CSS class for a user status based on the provided status string.
 * @param status - The user status ("away", "offline", or undefined for other statuses).
 * @returns The CSS class associated with the user status.
 */
const getUserStatusClass = (status: string | undefined): string => {
  // Check if the status is "away" and return the associated CSS class
  if (status === "away") {
    return styles.away;
  }
  // Check if the status is "offline" and return the associated CSS class
  else if (status === "offline") {
    return styles.offline;
  }
  // If the status is neither "away" nor "offline", return an empty string
  return "";
};

function LogoutDiv() {
  // Retrieve the current user data from the Redux store using the useAppSelector hook
  const currentUserData = useAppSelector(
    (state) => state.globals.currentUserData
  );

  // Initialize loading state using the useState hook
  const [loading, setLoading] = useState(false);

  // Destructure values from the useChangeChatAcceptStatus custom hook
  const { isLoading, updateChatAcceptStatusMutation } =
    useChangeChatAcceptStatus({
      userId: currentUserData?.userId, // Pass the userId from the currentUserData to the custom hook
    });

  // Retrieve the dispatch function from the Redux store using the useAppDispatch hook
  const dispatch = useAppDispatch();

  if (currentUserData) {
    return (
      <div
        className={`d-flex flex-column justify-content-between ${styles.body}`}
        id="logout__section"
      >
        <div>
          <div className={`p-2 d-flex ${styles.profile}`}>
            <div className={`d-flex my-auto mx-0`}>
              {currentUserData.profileImageUrl ? (
                <AxiosImg
                  url={currentUserData.profileImageUrl}
                  className={`${styles.profileImg}`}
                  isDirectURL={currentUserData.isPublicAttachmentUrl}
                />
              ) : (
                <UserAvatar
                  size={35}
                  name={
                    `${currentUserData.firstName} ${currentUserData.lastName}`.trim()
                      ? `${currentUserData.firstName} ${currentUserData.lastName}`.trim()
                      : currentUserData.email.trim()
                  }
                  className={`${styles.profileImg}`}
                />
              )}
              <div className={`w-0`}>
                <i
                  className={`fa-solid fa-circle ${styles.profileDot} ${
                    getUserStatusClass(currentUserData.agentStatus?.status) //dynamically render class for agent status
                  }`}
                ></i>
              </div>
            </div>
            <div className={`d-flex flex-column mx-2 my-auto py-1`}>
              <span className={`${styles.name}`}>
                {`${currentUserData.firstName} ${currentUserData.lastName}`.trim()}
              </span>
              <span className={`${styles.status}`}>
                {currentUserData.agentStatus?.displayName}
              </span>
            </div>
          </div>
          <div className={`p-2 mt-2 ${styles.chatAcceptStatus}`}>
            {/* Accept Chats button */}
            <div className="d-flex align-items-center justify-content-between">
              <div className="ms-2">
                <label
                  className={`form-check-label ${styles.enableChat}`}
                  htmlFor={"user_chat_accept_status"}
                >
                  Accept Chats
                </label>
              </div>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  name="user_chat_accept_status"
                  checked={currentUserData.chatAcceptStatus ?? false}
                  disabled={isLoading}
                  onChange={() => {
                    const status = !currentUserData.chatAcceptStatus;
                    //optimistic update the user data in the state
                    dispatch(
                      updateCurrentUserData({
                        chatAcceptStatus: status,
                        agentStatus: {
                          displayName: status
                            ? "Not Accepting Chats"
                            : "Available",
                          status: status ? "online" : "offline",
                        },
                      })
                    );
                    // Initiate the mutation using the mutate function from updateChatAcceptStatusMutation
                    updateChatAcceptStatusMutation.mutate(
                      {
                        userId: currentUserData.userId,
                        chatAcceptStatus: !currentUserData.chatAcceptStatus, // Toggle the chat acceptance status
                      },
                      {
                        // Define an onError callback to handle errors during the mutation
                        onError: (error, variables, options) => {
                          const status = !variables.chatAcceptStatus;
                          // Dispatch an action to update the current user data in the Redux store with the previous chatAcceptStatus
                          dispatch(
                            updateCurrentUserData({
                              chatAcceptStatus: !variables.chatAcceptStatus,
                              agentStatus: {
                                displayName: status
                                  ? "Not Accepting Chats"
                                  : "Available",
                                status: status ? "online" : "offline",
                              },
                            })
                          );
                        },
                        onSuccess: () => {
                          // Call the getCurrentUser function to retrieve user data asynchronously
                          getCurrentUser()
                            // Once the user data is successfully retrieved, execute the following callback function
                            .then((res) => {
                              // Dispatch an action to set the current user data in the Redux store
                              dispatch(setCurrentUserData(res));
                            });
                        },
                      }
                    );

                    // Dispatch an action to update the current user data in the Redux store with the toggled chatAcceptStatus
                    dispatch(
                      updateCurrentUserData({
                        chatAcceptStatus: !currentUserData.chatAcceptStatus,
                      })
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div className={`px-2 my-1`} id="logout_section__accset">
            <NavLink
              to={`/settings/Profile`}
              className={`d-flex p-2 ${styles.links}`}
            >
              <i className={`fa-solid fa-gear`}></i>
              <span className={`mx-1`}>{`Account & Settings`}</span>
            </NavLink>
            <a
              href={`https://saufter.io/`}
              target={`_blank`}
              className={`d-flex p-2 ${styles.links}`}
            >
              <svg
                viewBox="0 0 24 24"
                fill="5E5E5E"
                id="logout_section__helpCenter"
              >
                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm7.46 7.12-2.78 1.15c-.51-1.36-1.58-2.44-2.95-2.94l1.15-2.78c2.1.8 3.77 2.47 4.58 4.57zM12 15c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zM9.13 4.54l1.17 2.78c-1.38.5-2.47 1.59-2.98 2.97L4.54 9.13c.81-2.11 2.48-3.78 4.59-4.59zM4.54 14.87l2.78-1.15c.51 1.38 1.59 2.46 2.97 2.96l-1.17 2.78c-2.1-.81-3.77-2.48-4.58-4.59zm10.34 4.59-1.15-2.78c1.37-.51 2.45-1.59 2.95-2.97l2.78 1.17c-.81 2.1-2.48 3.77-4.58 4.58z"></path>
              </svg>
              <span className={`mx-1`}>{`Help Center`}</span>
            </a>
          </div>
        </div>
        <div
          className={`p-3 ${styles.signoutTop}`}
          id="logout_section__logOutBtn"
        >
          <div
            className={`d-inline-flex ${styles.signout}`}
            onClick={() => {
              setLoading(true);
              logoutUser(() => {
                setLoading(false);
              });
            }}
          >
            {loading ? (
              <Spinner size="sm" animation="border" color="5e5e5e" />
            ) : (
              <i className={`fa-solid fa-arrow-right-from-bracket`}></i>
            )}
            <span className={`mx-1`}>Sign Out</span>
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}

export default LogoutDiv;
