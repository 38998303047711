import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { IReturnReason } from "src/features/ReturnAutoWorkFlow/hooks/useGetAutomationData";
import { ReturnReasonOrderAndItemsTab } from "src/services/ReturnAutoWorkFlow/OldModals/returnReasonOrderAndItems/getReturnReasonOrderAndItems.service";
import { ReturnReasonOrderAndItemsConditions } from "src/services/ReturnAutoWorkFlow/OldModals/returnReasonOrderAndItems/getReturnReasonOrderAndItemsConditions.service";
import { FormElementOption } from "src/services/ReturnAutoWorkFlow/OldModals/returnReasonOrderAndItems/getReturnReasonOrderAndItemsFormElementOptions.service";
import {
  ConditionTypes,
  createNewObjectHash,
  getConditionValueOrDefault,
} from "src/features/ReturnAutoWorkFlow/children/ConfigModals/store/returnModalsSliceHelpers";
import { v4 as uuid } from "uuid";
import { ReturnReasonOrderAndItemsState } from "./returnReasonOrderAndItems.slice";
import {
  fetchReturnReasonOrderAndItems,
  updateReturnReasonOrderAndItems,
} from "./returnReasonOrderAndItems.thunk";

interface Payload {
  conditionOptionsRes?: ReturnReasonOrderAndItemsConditions;
  configRes: ReturnReasonOrderAndItemsTab[];
  defaultConditionOrderDate?: ConditionTypes;
  defaultConditionReasonsList?: IReturnReason[];
  formElementOptions?: FormElementOption[];
}

const returnReasonOrderAndItemsBuilders = (
  builder: ActionReducerMapBuilder<ReturnReasonOrderAndItemsState>,
) => {
  builder.addCase(
    fetchReturnReasonOrderAndItems.fulfilled,
    (state: ReturnReasonOrderAndItemsState, { payload }) => {
      state.fetchAjaxStatus = "fulfilled";
      updateConfig(state, payload);
    },
  );

  // Case Pending
  builder.addCase(
    fetchReturnReasonOrderAndItems.pending,
    (state: ReturnReasonOrderAndItemsState, { payload }) => {
      state.fetchAjaxStatus = "pending";
    },
  );

  // Case Rejected
  builder.addCase(
    fetchReturnReasonOrderAndItems.rejected,
    (state: ReturnReasonOrderAndItemsState, { payload }) => {
      state.fetchAjaxStatus = "rejected";
      pushTheToast({
        type: "danger",
        text: "Failed to fetch configuration!",
        position: "top-right",
      });
    },
  );

  // Builder Cases for updateReturnReasonOrderAndItems

  // Case Pending
  builder.addCase(
    updateReturnReasonOrderAndItems.pending,
    (state: ReturnReasonOrderAndItemsState) => {
      state.updateAjaxStatus = "pending";
    },
  );

  // Case Fulfilled
  builder.addCase(
    updateReturnReasonOrderAndItems.fulfilled,
    (state: ReturnReasonOrderAndItemsState, { payload }) => {
      state.updateAjaxStatus = "fulfilled";
      updateConfig(state, payload);
    },
  );

  // Case Rejected
  builder.addCase(
    updateReturnReasonOrderAndItems.rejected,
    (state: ReturnReasonOrderAndItemsState) => {
      state.updateAjaxStatus = "rejected";
      pushTheToast({
        type: "danger",
        text: "Failed to update Configuration!",
        position: "top-right",
      });
    },
  );
};

const updateConfig = (
  state: ReturnReasonOrderAndItemsState,
  payload: Payload,
) => {
  const {
    configRes,
    conditionOptionsRes,
    defaultConditionReasonsList,
    formElementOptions,
  } = payload;

  if (conditionOptionsRes) {
    state.conditionOptions = conditionOptionsRes.conditions;
    state.itemOptions = conditionOptionsRes.itemConditions;
    state.defaultOptions = conditionOptionsRes.defaultConditions;
    [
      ...state.conditionOptions,
      ...state.itemOptions,
      ...state.defaultOptions,
    ].forEach((option) => {
      if (!state.variableNames.some((item: any) => item.id === option.id)) {
        state.variableNames.push({ name: option.name, id: option.id });
      }

      state.operatorsNames[option.name] = option.operators.map((operator) => {
        state.operatorSymbolMap[operator.value] = operator.name;
        return operator.name;
      });
      state.valueTypes[option.valueType] = null;
      state.variableNameToValueType[option.name] = option.valueType;
    });
  }

  if (configRes) {
    // Check if we have any configuration returned from the server or not.
    if (configRes.length > 0) {
      state.selectedReasonTypeId = configRes[0].configTabId.toString();
      state.reasonTypes = configRes.map((tab) => {
        return {
          reasonTypeName: tab.configTabName,
          reasonTypeId: tab.configTabId.toString(),
          applicableForAllItems: tab.applicableForAllItems,
          applicableForAllOrders: tab.applicableForAllOrders,
          error: null,
          reasonError: null,
          itemError: null,
          explainWhyTheyReturnError: null,
          isAddConditionEnabled: true,
          isAddItemConditionEnabled: true,
          isAddReasonEnabled: true,
          errorCount: 0,
          isValid: false,
          explainWhyTheyReturn: tab.explainWhyTheyReturn ?? null,
          conditions: tab.conditions.map((condition: any) => {
            const selectedVariable: any = state.variableNames.find(
              (variable: any) => variable.id == condition.conditionTypeId,
            );

            if (selectedVariable) {
              const variableName = selectedVariable.name;
              const operatorSymbol = condition.operator;
              const operator = state.operatorSymbolMap[operatorSymbol];
              const valueType = state.variableNameToValueType[variableName];
              const values = getConditionValueOrDefault(
                valueType,
                condition.conditionValue,
              );

              return {
                id: condition.id.toString(),
                variableName,
                operator,
                values,
              } as ConditionTypes;
            }
            return {} as ConditionTypes;
          }),
          reasons: tab.reasons.map((reason: any) => {
            return {
              id: reason.id.toString(),
              reason: {
                value: reason.value,
                moreQuestions: reason.moreQuestions ?? [],
                isQuestionValid: false,
              },
              error: null,
            };
          }),
        };
      });
    } else {
      // Set the default conditions
      if (conditionOptionsRes?.defaultConditions) {
        const conditions = conditionOptionsRes.defaultConditions
          .map((condition) => {
            const selectedVariable = state.variableNames.find(
              (variable: any) => variable.id == condition.id,
            );

            if (selectedVariable) {
              const variableName = selectedVariable.name;
              const operatorSymbol = condition.operators[0].value;
              const operator = state.operatorSymbolMap[operatorSymbol];
              const valueType = state.variableNameToValueType[variableName];
              const values = getConditionValueOrDefault(valueType, null);

              // const values = productIdToDetails(Conditionvalues);

              return {
                id: uuid(),
                variableName,
                operator,
                values,
              } as ConditionTypes;
            }
            return null;
          })
          .filter(Boolean) as ConditionTypes[];

        state.reasonTypes[0].conditions = conditions;
        state.reasonTypes[0].reasonTypeName = "All orders and items"; //Addingg same name as other modals for initial tab
      }

      // Set default reasons from Q8
      if (defaultConditionReasonsList) {
        let reasons = defaultConditionReasonsList.map((reason: any) => {
          return {
            id: reason.id.toString(),
            reason: {
              value: reason.value,
              moreQuestions: reason.moreQuestions ?? [],
              isQuestionValid: false,
            },
            error: null,
            isNew: true,
          };
        });
        state.reasonTypes[0].reasons = reasons;
      }

      //Dec 12 Adding empty default return reason initially
      if (
        (defaultConditionReasonsList &&
          defaultConditionReasonsList.length === 0) ||
        !defaultConditionReasonsList
      ) {
        let reasons = [
          {
            id: uuid(),
            reason: {
              value: "",
              moreQuestions: [],
              isQuestionValid: false,
            },
            error: null,
            isNew: true,
          },
        ];
        state.reasonTypes[0].reasons = reasons;
      }
    }

    if (configRes) {
      state.getItemType = configRes;
    }
  }

  if (formElementOptions) {
    state.formElementOptions = formElementOptions;
  }

  const hash = createNewObjectHash({
    excludeKeys: state.excludeKeysForHash,
    hashObject: state.reasonTypes,
  });

  if (hash) {
    state.initialHashOtherTab = hash;
    state.currentHashOtherTab = hash;
  }
};

export default returnReasonOrderAndItemsBuilders;
