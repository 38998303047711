import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";

/**
 * Interface representing the parameters required for deleting an image for product.
 */
export interface DeleteImageParams {
  integrationId: string;
  productId: string;
}

/**
 * Deletes an image associated with a specific product.
 *
 * This function sends a DELETE request to the server to remove the image based on the provided parameters.
 *
 * @param {DeleteImageParams} params - The parameters for the image deletion, including relevant identifiers like integration ID and product ID.
 * @returns {Promise<any>} A promise that resolves to the response data from the server.
 * @throws {Error} Throws an error if the API response contains an error or if an error occurs during the request.
 */
async function deleteImage(params: DeleteImageParams): Promise<any> {
  const apiEndPoint = IS_TEST_ACTIVE
    ? `${process.env.REACT_APP_TEST_SITE_URL}/deleteImage`
    : `/api/setting/automation/refund/product/deleteImage`;
  const { data: res } = await axiosJSON.delete(apiEndPoint, { params });

  if (res.err || res.error) {
    throw new Error(res.msg ?? res.message ?? "Some Error Occurred.");
  }

  return res.data;
}

export default deleteImage;
