import styles from "./ToggleCustomerSB.module.scss";
import UserAvatar from "src/components/UserAvatar";
import logo from "src/assets/images/missed.png";
import hourGlass from "src/assets/images/hourglassWhite.png";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  selectAllCustomers,
  selectCustomersByStatus,
} from "src/store/slices/liveChatSetting/chatWidExchCust/chatWidExchCust.selectors";
import {
  actions,
  sortChatByMessageDateSelector,
} from "src/store/slices/liveChatSetting/chatWidExchCust/chatWidExchCust.slice";
import { useEffect, useMemo, useRef, useState } from "react";
import InfiniteScroll from "src/components/InfiniteScroll";
import { Spinner } from "react-bootstrap";
import { v4 as uuid } from "uuid";
import { RouteNames } from "src/containers/SideBar/SideBar";
import { useNavigate, useParams } from "react-router-dom";
import { fetchCustomerData } from "src/store/slices/liveChatSetting/chatWidExchCust/chatWidExchCust.thunks";
import AxiosImg from "src/components/AxiosImg";
import { setActiveCustomerInfo } from "src/store/slices/liveChatSetting/chatWidExchMsg/chatWidExchMsg.slice";

export const getChatIdRouteLink = ({ chatId, chatStatus }: any) => {
  return `${RouteNames.liveChat}/chats/${chatStatus}/${chatId}/chattingTab/conversation`;
};

const ToggleCustomerSB = ({
  searchText,
  setSearchText,
}: {
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const dispatch = useAppDispatch();
  const { chatId: currentChatId, chatStatus } = useParams();

  const navigate = useNavigate();

  // Initing load more button and setting it true
  const [showLoadMore, setShowLoadMore] = useState(true);

  const {
    chatIdList: originalChatIdList,
    customers,
    fetchCustAJAXStatus,
    metaData,
    filters,
    fetchCustomerLimit,
  } = useAppSelector((state) => state.chatWidExchCust);

  // Get a sorted list of chat IDs using a selector
  const sortedChatIdList = useAppSelector(sortChatByMessageDateSelector);

  const rootRefSidebar = useRef(null as any);

  // Use useMemo to conditionally update chatIdList based on searchValue
  const chatIdList = useMemo(() => {
    // Check if the searchValue in filters is empty
    if (filters.searchValue.trim() === "") {
      // If it's empty, use the sorted chat ID list
      return sortedChatIdList;
    } else {
      // If there is a search value, use the original chat ID list
      return originalChatIdList;
    }
  }, [sortedChatIdList, originalChatIdList, filters.searchValue]);

  const currentState = useRef({
    chatIdList: chatIdList,
    customers: customers,
    fetchCustAJAXStatus: fetchCustAJAXStatus,
    metaData: metaData,
  });

  useEffect(() => {
    currentState.current = {
      chatIdList: chatIdList,
      customers: customers,
      fetchCustAJAXStatus: fetchCustAJAXStatus,
      metaData: metaData,
    };
  }, [chatIdList, customers, fetchCustAJAXStatus, metaData]);

  const handleInfiniteScroll = () => {
    if (
      currentState.current !== null &&
      currentState.current.fetchCustAJAXStatus === "fulfilled"
    ) {
      if (currentState.current.metaData.limitReached ? false : true) {
        // Setting load more button show false
        setShowLoadMore(false);
        dispatch(
          fetchCustomerData({ start: currentState.current.chatIdList.length })
        );
      }
    }
  };

  // Created has more memo to do optimize it and reuse it
  const hasMore = useMemo(() => {
    return fetchCustAJAXStatus === "rejected"
      ? false
      : metaData.limitReached
        ? false
        : true;
  }, [fetchCustAJAXStatus, metaData]);

  // Created show loader memo to do optimize it and reuse it
  const showLoader = useMemo(() => {
    return fetchCustAJAXStatus === "pending" && chatIdList.length === 0;
  }, [fetchCustAJAXStatus, chatIdList]);

  // Effect to show the load more button again if loading done.
  useEffect(() => {
    if (fetchCustAJAXStatus === "fulfilled") {
      setShowLoadMore(true);
    }
  }, [fetchCustAJAXStatus]);

  return (
    <div className={`${styles.toggleMain}`}>
      <InfiniteScroll
        key={"liveChatToggleSB"}
        initialDataLoaded={true}
        loadMore={handleInfiniteScroll}
        loader={
          showLoader ? (
            <div className={`${styles.loading} m-2`}>
              <Spinner
                animation="border"
                variant="primary"
                size="sm"
                className={`d-block mb-2`}
              />
            </div>
          ) : showLoadMore ? (
            // Rendering the load button conditionally.
            <div
              className={`${styles.loaderText}`}
              onClick={handleInfiniteScroll}
              title="Load More"
            >
              <i className="ms-1 fa-solid fa-rotate-right"></i>
            </div>
          ) : (
            // Rendering the load text conditionally.
            <div className={`${styles.loadingText}`} title="Loading...">
              <div>
                <Spinner
                  className={`ms-1 ${styles.spinner}`}
                  size="sm"
                  animation="border"
                />
              </div>
            </div>
          )
        }
        className={`pt-2 ${styles.contentSB}`}
        // Hiding the flex class in infinite scroll when data is prepent to make it smooth. It is kept there to show the loader in middle
        hideClassDisplayFlex={showLoader === false}
        hasMore={hasMore}
      >
        {/* If not loading then only load data. */}
        {showLoader === false && (
          // Showing the data with a height to make sure the data should always scroll if window height is larger.
          <div
            className="d-flex flex-column justify-content-top"
            style={{
              minHeight:
                chatIdList.length >= parseInt(fetchCustomerLimit + "")
                  ? "100vh"
                  : "auto",
              height: "auto",
            }}
          >
            {chatIdList &&
              chatIdList.map((chatId) => {
                const customer = customers[chatId];
                return (
                  <div
                    className={`m-1 ${styles.userIcon} ${
                      currentChatId == chatId ? styles.active : ""
                    } d-flex justify-content-center`}
                    key={"toggle_sb_" + customer.chatId}
                  >
                    <div
                      role={"button"}
                      className={`w-100 ${styles.picHeight}`}
                      onClick={() => {
                        if (currentChatId != customer.chatId) {
                          dispatch(
                            actions.setActiveChatId({ id: customer.chatId })
                          );
                          dispatch(
                            setActiveCustomerInfo({ customerInfo: customer })
                          );
                          navigate(
                            window.location.pathname.replace(
                              `chats/${chatStatus}/${currentChatId}`,
                              `chats/${chatStatus}/${customer.chatId}`
                            )
                          );
                        }
                      }}
                    >
                      {customer.imageURL && customer.imageURL.length !== 0 ? (
                        <AxiosImg
                          url={customer.imageURL}
                          className={`${styles.profileIcon}`}
                          isDirectURL={customer.isPublicAttachmentUrl}
                        />
                      ) : (
                        <UserAvatar
                          name={
                            customer.name && customer.name !== ""
                              ? customer.name
                              : "NA"
                          }
                          size={32}
                        />
                      )}
                      <span
                        className={`position-relative w-0 h-0 mt-0 mb-auto `}
                      >
                        {(customer.missedMessages ?? 0) > 0 && (
                          <>
                            {customer.customerStatus == "missed" ? (
                              <span className={`${styles.missedSpan} px-1`}>
                                <img
                                  className={`${styles.missLogo} me-1`}
                                  src={logo}
                                />
                                {customer.missedMessages}
                              </span>
                            ) : (
                              <span className={`${styles.quantity}`}>
                                {customer.missedMessages}
                              </span>
                            )}
                          </>
                        )}
                      </span>
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </InfiniteScroll>
      {/* <div className={`d-flex justify-content-center`}>
        <div>
          <UserAvatar name={"NA"} size={32} />
         <span className={`${styles.missedSpan}`}>
           <img className={`${styles.missLogo}`} src={logo}  />
           2
         </span>
        </div>
      </div>
      <div className={`d-flex justify-content-center`}>
        <div>
          <UserAvatar name={"NA"} size={32} />
         <span className={`${styles.waiting}`}>
         <img className={`${styles.hourGlass}`} src={hourGlass} />
         </span> 
        </div>
      </div> */}
    </div>
  );
};
export default ToggleCustomerSB;
