import styles from "./TakeOverModal.module.scss";
import preview from "src/assets/images/previewChat.png";

const TakeOvermodal = ({
  selectionHandler,
  handleClose,
}: {
  selectionHandler: (isPreChatForm: boolean) => void;
  handleClose: () => void;
}) => {
  return (
    <div className={`text-center ${styles.takeOverWrapper}`}>
      <div>
        <span
          className={`d-flex justify-content-end h-0 ${styles.closeBtn}`}
          onClick={() => handleClose()}
        >
          <svg
            id="Component_163_1"
            data-name="Component 163 – 1"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
          >
            <circle
              id="Ellipse_844"
              data-name="Ellipse 844"
              cx="10"
              cy="10"
              r="10"
              fill="#707070"
              opacity="0"
            />
            <path
              id="Icon_ionic-ios-close"
              data-name="Icon ionic-ios-close"
              d="M16.466,15.476l2.992-2.992a.7.7,0,0,0-.991-.991l-2.992,2.992-2.992-2.992a.7.7,0,1,0-.991.991l2.992,2.992-2.992,2.992a.7.7,0,1,0,.991.991l2.992-2.992,2.992,2.992a.7.7,0,1,0,.991-.991Z"
              transform="translate(-5.559 -5.562)"
              fill="#707070"
            />
          </svg>
        </span>
      </div>
      <h2 className={`text-center ${styles.heading}`}>Pre-chat form preview</h2>

      <img
        src={preview}
        alt=""
        className={`${styles.previewImg}`}
      />

      <p className={`${styles.info}`}>
        The customer has not yet filled the pre-chat form with the following
        details. Would you like the customer fill it out before the customer is
        connected to you or would you like to be connected while the customer
        remains anonymous in the system?
      </p>

      <div className="d-flex justify-content-center align-items-center">
        <button
          className={`${styles.noFillBtn}`}
          id="noFillBtn"
          onClick={() => selectionHandler(false)}
        >
          No need for them to fill the form
        </button>

        <button
          className={`${styles.fillBtn}`}
          id="fillBtn"
          onClick={() => selectionHandler(true)}
        >
          Please request them to fill the form
        </button>
      </div>
    </div>
  );
};

export default TakeOvermodal;
