import styles from "./UserRow.module.scss";
import user from "src/assets/images/user.png";
import {
  AddMemberToTeamParams,
  addMemberToTeam,
} from "src/services/Settings/Teams/addMemberToTeam";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  fetchMembersInTeam,
  resetMembersInTeamData,
} from "src/store/slices/teamSettings/teamSettings.slice";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import UserAvatar from "src/components/UserAvatar";
import AxiosImg from "src/components/AxiosImg";

function UserRow(props: any) {
  // console.log("User Row Team data:: ", JSON.stringify(props));
  const dispatch = useAppDispatch();
  const { activeTeam } = useAppSelector((state) => state.teamSettings);

  const handleAddUserToTeam = (e: any) => {
    e.preventDefault();
    // hide the add members modal
    props.setShowAddMemberModal(false);
    //Todo: show the adding toast loader
    addMemberToTeam({
      userId: props.userData.userId,
      teamId: activeTeam.teamId,
    })
      .then((res) => {
        // refetch the members in team data
        dispatch(resetMembersInTeamData());
        dispatch(fetchMembersInTeam(activeTeam.teamId));
        //Todo: show the member added toast
        setTimeout(() => {
          pushTheToast({
            type: "success",
            text: "Team member added successfully!",
            position: "top-right",
          });
        }, 0);
      })
      .catch((err) => {
        // show the error toast
        pushTheToast({
          type: "danger",
          text: "Failed to add team member!",
          position: "top-right",
        });
      });
  };
  return (
    <div
      className={`d-flex align-items-center mb-1 mx-2 px-2 ${styles.userDiv}`}
      onClick={(e) => handleAddUserToTeam(e)}
    >
      <div className={`${styles.dropImg}`}>
        <div className={`${styles.modal_status_image}`}>
          {props.userData.profileImageUrl === null ? (
            <UserAvatar
              className={`rounded-circle img-fluid ${styles.userImg}`}
              name={`${
                `${props.userData.firstName} ${props.userData.lastName}`.trim()
                  ? `${props.userData.firstName} ${props.userData.lastName}`
                  : props.userData.email
              }`}
              size={20}
            />
          ) : (
            <AxiosImg
              className={`rounded-circle img-fluid ${styles.userImg} `}
              url={props.userData.profileImageUrl}
              isDirectURL={props.userData.isPublicAttachmentUrl}
            />
          )}
          {/* <img
          src={props.userData.profileImageUrl == null ? user: props.userData.profileImageUrl}
          alt="status"
          className="rounded-circle img-fluid"
        /> */}
        </div>
      </div>

      <div className={`ms-2 ${styles.dropMail}`}>
        {/* {`${props.userData.firstName} ${props.userData.lastName}`} */}
        {`${
          `${props.userData.firstName} ${props.userData.lastName}`.trim()
            ? `${props.userData.firstName} ${props.userData.lastName}`
            : props.userData.email
        }`}
      </div>
    </div>
  );
}

export default UserRow;
