// Importing axios and other necessary modules
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { v4 as uuid } from "uuid";
import { logoutUser } from "src/hooks/auth/useLogin";
import { logoutKBPreview } from "src/routes/KnowledgeBaseUpdated/Children/TabSection/KnowledgeSetting/Children/ArticleSideBar/ThemeSelection/Children/ThemeSetting/Children/PreviewSection/Children/PassProtect/usePassProtect";
import { store } from "src/store/store";
import { updateCurrentUserDataDisabledFeatures } from "src/store/slices/globals/globals.slice";

// Defining the structure of a cache entry
interface CacheEntry {
  key: string;
  timestamp: number;
  status: "creating" | "created" | "error";
  data: any;
  cacheTime: number;
}

// Map to store API cache entries
const apiCache: Map<string, CacheEntry> = new Map();

/**
 * Generates a unique cache key based on URL and payload.
 * @param url The URL of the request.
 * @param payload The request payload.
 * @returns The cache key.
 */
export function getCacheKey(url: string, payload: any): string {
  return `${url}:${JSON.stringify(payload ?? {})}`;
}

/**
 * Retrieves data from the cache if available.
 * @param url The URL of the request.
 * @param payload The request payload.
 * @returns The cached data if available, otherwise null.
 */
function getDataFromCache(url: string, payload: any) {
  const key = getCacheKey(url, payload);
  const entry = apiCache.get(key);
  if (entry) {
    const currentTime = new Date().getTime();
    if (entry.status === "created") {
      if (currentTime - entry.timestamp <= entry.cacheTime) {
        // Cache entry is valid, return it
        return entry;
      } else {
        // Cache entry has expired, delete it from cache
        apiCache.delete(key);
        return null;
      }
    } else {
      // Cache entry is still being created or encountered an error
      return entry;
    }
  } else {
    // Cache entry does not exist
    return null;
  }
}

/**
 * Sets data to the cache.
 * @param url The URL of the request.
 * @param payload The request payload.
 * @param data The response data.
 * @param status The status of the cache entry.
 * @param cacheTime The cache expiration time in milliseconds.
 */
function setDataToCache(
  url: string,
  payload: any,
  data: any,
  status: CacheEntry["status"],
  cacheTime: number, // in milliseconds
): void {
  const key = getCacheKey(url, payload);
  apiCache.set(key, {
    key,
    timestamp: new Date().getTime(),
    data,
    status,
    cacheTime,
  });
}

/**
 * Clears cache entries that match the given URL and have a status of "created" or "error".
 * @param url The URL to match against cache keys.
 */
export function clearCacheByUrl(url: string): void {
  const keysToDelete: string[] = [];

  // Iterate over each cache entry
  apiCache.forEach((entry, key) => {
    // Check if the key starts with the given URL and the status is either "created" or "error"
    if (
      key.startsWith(url) &&
      (entry.status === "created" || entry.status === "error")
    ) {
      keysToDelete.push(key); // Add the key to the list of keys to delete
    }
  });

  // Delete each key from the cache
  keysToDelete.forEach((key) => apiCache.delete(key));
}

/**
 * Deletes a specific cache entry based on the URL and payload.
 * @param url The URL of the request.
 * @param payload The request payload.
 */
export function deleteDataFromCache(url: string, payload: any): void {
  const key = getCacheKey(url, payload); // Generate the cache key
  const cacheEntry = apiCache.get(key); // Retrieve the cache entry

  // Check if the cache entry exists and its status is either "created" or "error"
  if (
    cacheEntry &&
    (cacheEntry.status === "created" || cacheEntry.status === "error")
  ) {
    apiCache.delete(key); // Delete the cache entry
  }
}
// Store intervals for asynchronous requests
const intervals: { [key: string]: any } = {};

/**
 * Waits for the response to be available in the cache.
 * @param url The URL of the request.
 * @param data The request data.
 * @returns A promise that resolves when the response is available in the cache.
 */
const waitForResponse = (url: string, data: any) => {
  const key = uuid();
  return new Promise((resolve: (value: CacheEntry) => void, reject) => {
    intervals[key] = setInterval(() => {
      const newCachedData = getDataFromCache(url, data);
      if (newCachedData?.status === "created") {
        clearInterval(intervals[key]);
        resolve(newCachedData);
      } else if (newCachedData?.status === "error") {
        clearInterval(intervals[key]);
        reject(newCachedData.data);
      }
    }, 500);
  });
};

// Creating axios instance with auth and JSON content type
export const axiosJSON = axios.create({
  baseURL: process.env.REACT_APP_SITE_URL,
  headers: {
    "content-type": "application/json",
  },
});

/**
 * Overridden post method for the axiosJSON instance with caching functionality.
 * @param url The URL to which the request is made.
 * @param data The data to be sent as the request body.
 * @param config The request configuration.
 * @returns A promise that resolves to the response data or rejects with an error.
 */
const postFun = axiosJSON.post;
axiosJSON.post = async <T = any, R = AxiosResponse<T, any>, D = any>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig<D> | undefined,
): Promise<R> => {
  // Extract cache time from request data or default to 0
  const cacheTime: number = data?.cacheTime ? data.cacheTime : 0;

  // Check if caching is requested for this request
  if (data?.cacheIt) {
    // Remove cache-related properties from request data
    delete data.cacheIt;
    delete data.cacheTime;

    // Check if data is available in cache
    const cachedData = getDataFromCache(url, data);
    if (cachedData !== null) {
      // If data is already available in cache and is created, return it
      if (cachedData.status === "created") {
        return cachedData.data;
      } else {
        // If data is being created, wait for response
        const newData = await waitForResponse(url, data);
        return newData.data;
      }
    } else {
      // If data is not in cache, mark it as creating and proceed with request
      setDataToCache(url, data, null, "creating", cacheTime);
    }
  }

  // If caching is not requested, proceed with the original post request
  return new Promise(
    async (resolve: (value: R | PromiseLike<R>) => void, reject) => {
      try {
        if (data) {
          delete data.cacheIt;
          delete data.cacheTime;
        }

        // Perform the original post request
        const value = await postFun(url, data, config);
        const req = value.config;
        let reqData = undefined;
        if (req.data) {
          reqData =
            typeof req.data === "string" ? JSON.parse(req.data) : req.data;
        }

        // Update cache if response is successful
        const cachedData = getDataFromCache(req.url!, reqData);
        if (cachedData !== null && req.url) {
          setDataToCache(req.url, reqData, value, "created", cacheTime);
        }
        resolve(value as any);
      } catch (err: any) {
        // Handle errors and update cache if applicable
        const req = err.config;
        let reqData: any = undefined;
        if (req.data) {
          reqData =
            typeof req.data === "string" ? JSON.parse(req.data) : req.data;
        }
        const cachedData = getDataFromCache(req.url!, reqData);
        if (cachedData !== null && req.url) {
          setDataToCache(req.url, reqData, err, "error", cacheTime);
          setTimeout(() => {
            deleteDataFromCache(req.url, reqData);
          }, 5000);
        }
        reject(err);
      }
    },
  );
};

/**
 * Overridden get method for the axiosJSON instance with caching functionality.
 * @param url The URL to which the request is made.
 * @param config The request configuration.
 * @returns A promise that resolves to the response data or rejects with an error.
 */
const getFun = axiosJSON.get;
axiosJSON.get = async <T = any, R = AxiosResponse<T, any>, D = any>(
  url: string,
  config?: AxiosRequestConfig<D> | undefined,
): Promise<R> => {
  // Extract cache time from request configuration or default to 0
  const cacheTime: number = config?.params?.cacheTime
    ? config.params.cacheTime
    : 0;

  // Check if caching is requested for this request
  if (config?.params?.cacheIt) {
    // Remove cache-related properties from request configuration
    delete config.params.cacheIt;
    delete config.params.cacheTime;

    // Check if data is available in cache
    const cachedData = getDataFromCache(url, config.params);
    if (cachedData !== null) {
      // If data is already available in cache and is created, return it
      if (cachedData.status === "created") {
        return cachedData.data;
      } else {
        // If data is being created, wait for response
        const newData = await waitForResponse(url, config.params);
        return newData.data;
      }
    } else {
      // If data is not in cache, mark it as creating and proceed with request
      setDataToCache(url, config.params, null, "creating", cacheTime);
    }
  }

  // If caching is not requested, proceed with the original get request
  return new Promise(
    async (resolve: (value: R | PromiseLike<R>) => void, reject) => {
      try {
        if (config?.params) {
          delete config.params.cacheIt;
          delete config.params.cacheTime;
        }

        // Perform the original get request
        const value = await getFun(url, config);
        const req = value.config;
        let reqData = undefined;
        if (req.params) {
          reqData =
            typeof req.params === "string"
              ? (() => {
                  try {
                    return JSON.parse(req.params);
                  } catch {
                    return req.params;
                  }
                })()
              : req.params;
        }

        // Update cache if response is successful
        const cachedData = getDataFromCache(req.url!, reqData);
        if (cachedData !== null && req.url) {
          setDataToCache(req.url, reqData, value, "created", cacheTime);
        }
        resolve(value as any);
      } catch (err: any) {
        // Handle errors and update cache if applicable
        const req = err.config;
        let reqData: any = undefined;
        if (req.params) {
          reqData =
            typeof req.params === "string"
              ? JSON.parse(req.params)
              : req.params;
        }
        const cachedData = getDataFromCache(req.url!, reqData);
        if (cachedData !== null && req.url) {
          setDataToCache(req.url, reqData, err, "error", cacheTime);
          setTimeout(() => {
            deleteDataFromCache(req.url, reqData);
          }, 5000);
        }
        reject(err);
      }
    },
  );
};

// Creating axios instance with auth and form-data content type
export const axiosFormData = axios.create({
  baseURL: process.env.REACT_APP_SITE_URL,
  headers: {
    "content-type": "multipart/form-data",
  },
});

// Creating axios instance with auth for KB preview
export const axiosKbPreview = axios.create({
  baseURL: process.env.REACT_APP_SITE_URL,
  headers: {
    "content-type": "application/json",
  },
});

// Function to preprocess successful response
function preprocessResponse(response: AxiosResponse) {
  if (
    response.data &&
    typeof response.data === "object" &&
    response.data.errorType === "usage_limit_exceeded" &&
    response.data.disabledFeatures
  ) {
    // Check if errorType property exists and is not empty
    const disabledFeatures = response.data.disabledFeatures || [];
    if (disabledFeatures.length > 0) {
      // Dispatch an action to update the store with disabledFeatures
      store.dispatch(
        updateCurrentUserDataDisabledFeatures({
          disabledFeatures: disabledFeatures,
        }),
      );
    }
  }
}

// Function to preprocess error response
function preprocessError(error: any) {
  const response = error?.response;
  if (
    response &&
    response.data &&
    typeof response.data === "object" &&
    response.data.errorType === "usage_limit_exceeded"
  ) {
    // Check if errorType property exists and is not empty
    const disabledFeatures = response.data.disabledFeatures || [];
    if (disabledFeatures.length > 0) {
      // Dispatch an action to update the store with disabledFeatures
      store.dispatch(
        updateCurrentUserDataDisabledFeatures({
          disabledFeatures: disabledFeatures,
        }),
      );
    }
  }
}

axiosJSON.interceptors.request.use(
  (req) => {
    return req;
  },
  (err) => {
    return Promise.reject(err);
  },
);

// Using middleware to logout the user on 403 error
axiosJSON.interceptors.response.use(
  (res) => {
    try {
      preprocessResponse(res);
    } catch (err: any) {}
    return res;
  },
  (err) => {
    if (err?.response?.status === 403) {
      logoutUser(() => {}, false);
    }
    try {
      preprocessError(err);
    } catch (err: any) {}
    return Promise.reject(err);
  },
);

// Using middleware to logout the user on 403 error
axiosFormData.interceptors.response.use(
  (res) => {
    try {
      preprocessResponse(res);
    } catch (err: any) {}
    return res;
  },
  (err) => {
    if (err?.response?.status === 403) {
      logoutUser(() => {}, false);
    }
    try {
      preprocessError(err);
    } catch (err: any) {}
    return Promise.reject(err);
  },
);

/**
 * Adding updated identifier using middleware in each request.
 */
axiosKbPreview.interceptors.request.use((req) => {
  const modifiedReq = {
    ...req,
    data: {
      ...req.data,
      knowledgeBaseSessionIdentifier:
        localStorage.getItem("knowledgeBaseSessionIdentifier") ?? undefined,
    },
  };
  return modifiedReq;
});

/**
 * Geting updated identifier using middleware in each response.
 */
axiosKbPreview.interceptors.response.use(
  (res) => {
    const availableSessionIdentifier = localStorage.getItem(
      "knowledgeBaseSessionIdentifier",
    );
    if (
      res.data.knowledgeBaseSessionIdentifier &&
      availableSessionIdentifier !== res.data.knowledgeBaseSessionIdentifier
    ) {
      localStorage.setItem(
        "knowledgeBaseSessionIdentifier",
        res.data.knowledgeBaseSessionIdentifier,
      );
    }
    return res;
  },
  // Using middleware to logout the user on 403 error
  (err) => {
    if (err?.response?.status === 403) {
      logoutKBPreview(() => {});
    }
    return Promise.reject(err);
  },
);

/*
axiosFormData.interceptors.request.use(
  (req) => {
    if (req.method?.toLowerCase() === "get") {
      if (req.params) {
        // req.params.idddd = 2;
      } else {
        // req.params = { idddd: 3 };
      }
    } else {
      if (req.data) {
        // req.data.append("idddddd", 1);
      } else {
        req.data = new FormData();
        // req.data.append("idddddd", 1);
      }
    }
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);
 */

/**
 * Used to set the auth data
 */
export function setAxiosAuth({ name, value }: { name: string; value: string }) {
  axiosJSON.defaults.headers.common[name] = value;
  axiosFormData.defaults.headers.common[name] = value;
}

/**
 * Used to set the auth data
 */
export function setKBAxiosAuth({
  name,
  value,
}: {
  name: string;
  value: string;
}) {
  axiosKbPreview.defaults.headers.common[name] = value;
}

// Track ongoing AJAX requests
export let allOngoingAxiosRequests = 0;

// Define your global interceptors
const requestInterceptor = (config: any) => {
  // Increment allOngoingAxiosRequests counter when a request is made
  allOngoingAxiosRequests++;
  // Do something globally before the request is sent
  return config;
};

const responseInterceptor = (response: any) => {
  // Decrement allOngoingAxiosRequests counter when a response is received
  allOngoingAxiosRequests--;
  // Do something globally with the response data
  return response;
};

const errorInterceptor = (error: any) => {
  // Decrement allOngoingAxiosRequests counter when a response is received with an error
  allOngoingAxiosRequests--;
  return Promise.reject(error);
};

// Attach the interceptors to your axios instances

// For axiosJSON instance
axiosJSON.interceptors.request.use(requestInterceptor);
axiosJSON.interceptors.response.use(responseInterceptor, errorInterceptor);

// For axiosFormData instance
axiosFormData.interceptors.request.use(requestInterceptor);
axiosFormData.interceptors.response.use(responseInterceptor, errorInterceptor);

// For axiosKbPreview instance
axiosKbPreview.interceptors.request.use(requestInterceptor);
axiosKbPreview.interceptors.response.use(responseInterceptor, errorInterceptor);
