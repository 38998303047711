import { addEditDraftMsg } from "src/services/DraftFlow/addEditDraft";
import { addEditRemark } from "src/services/DraftFlow/addEditRemark";
import { deleteDraftRemark } from "src/services/DraftFlow/deleteRemark";
import {
  getDraftMessagesApi,
  getDraftMsgHistoryApi,
  getDraftMsgRemarksApi,
  getDraftUnsentMessagesApi,
} from "src/services/DraftFlow/getDraftMessages";
import { RootState } from "src/store/store";
import { fetchAlldraftMsg } from "./draftFlow.slice";

async function fetchAllDraftMsgThunk(
  payload: undefined,
  { getState }: { getState: Function }
) {
  const currentState: RootState = getState();
  const msgData = await getDraftMessagesApi({
    ticketId: parseInt(currentState.draftFlow.ticketID + ""),
  });
  if (msgData.draftMsg === null) {
    return { msgData };
  } else {
    const { draftMsgHistory } = await getDraftMsgHistoryApi({
      msgId: msgData.draftMsg.messageId,
      historyNum: msgData.draftMsg.draftMsgHistoryCount,
      threshold: currentState.draftFlow.historyThreshold,
    });
    let remarks = null;
    if (
      currentState.globals.currentUserData?.scopes?.includes(
        "view_draft_remark"
      )
    ) {
      remarks = await getDraftMsgRemarksApi({
        msgId: msgData.draftMsg.messageId,
        start: 0,
        limit: currentState.draftFlow.remarkLimit,
        sort: "desc",
      });
    }
    const unsent = await getDraftUnsentMessagesApi({
      ticketId: parseInt(currentState.draftFlow.ticketID + ""),
      start: 0,
      limit: currentState.draftFlow.unsentLimit,
    });
    return { msgData, draftMsgHistory, remarks, unsent };
  }
}

async function fetchDraftMsgHistoryThunk(
  {
    msgId,
    historyNum,
    callback,
  }: { msgId: number; historyNum: number; callback?: () => void },
  { getState }: { getState: Function }
) {
  const currentState: RootState = getState();
  const { draftMsgHistory } = await getDraftMsgHistoryApi({
    msgId,
    historyNum,
    threshold: currentState.draftFlow.historyThreshold,
  });
  if (callback) {
    setTimeout(() => {
      callback();
    }, 0);
  }
  return { draftMsgHistory };
}

async function fetchUnsentDraftThunk(
  payload: undefined,
  { getState }: { getState: Function }
) {
  const currentState: RootState = getState();
  const unsent = await getDraftUnsentMessagesApi({
    ticketId: parseInt(currentState.draftFlow.ticketID + ""),
    start: currentState.draftFlow.unsentDraftMsg.length,
    limit: currentState.draftFlow.unsentLimit,
  });
  return unsent;
}

async function fetchRemarkDataThunk(
  { msgId }: { msgId: number },
  { getState }: { getState: Function }
) {
  const currentState: RootState = getState();
  const remarks = await getDraftMsgRemarksApi({
    msgId,
    start: currentState.draftFlow.draftRemarkIds.length,
    limit: currentState.draftFlow.remarkLimit,
    sort: "desc",
  });
  return remarks;
}

async function addEditDraftMsgDataThunk(
  {
    msgId,
    msgContent,
    remark,
    fetchAll = false,
    draftAttachmentBatchNumbers = [],
    remarkAttachmentBatchNumbers = [],
    callback = () => {},
  }: {
    msgId: number | null;
    msgContent: string;
    remark?: string;
    fetchAll?: boolean;
    draftAttachmentBatchNumbers?: Array<string>;
    remarkAttachmentBatchNumbers?: Array<string>;
    callback?: () => void;
  },
  { getState, dispatch }: { getState: Function; dispatch: Function }
) {
  const currentState: RootState = getState();
  let messageId = msgId;
  if (
    msgId === null &&
    currentState.draftFlow.draftMsg?.messageId !== undefined &&
    currentState.draftFlow.draftMsg.messageId !== null &&
    currentState.draftFlow.canAddNewDraftMsg === false
  ) {
    messageId = currentState.draftFlow.draftMsg.messageId;
  }
  let draftPayload: any = {
    msgId: messageId,
    ticketId: parseInt(currentState.draftFlow.ticketID + ""),
    msgContent,
    attachmentBatchNumber: draftAttachmentBatchNumbers
  };

  if(draftPayload.attachmentBatchNumber.length === 0){
    delete  draftPayload.attachmentBatchNumber;
  }
  const data = await addEditDraftMsg(draftPayload);

  if (msgId === null && remark !== undefined) {
    let remarkPayload: any = {
      msgId: data.messageId,
      remarkId: null,
      remark,
      attachmentBatchNumber: remarkAttachmentBatchNumbers
    };
  
    if(remarkPayload.attachmentBatchNumber.length === 0){
      delete  remarkPayload.attachmentBatchNumber;
    }
    await addEditRemark(remarkPayload);
  }
  if (fetchAll) {
    dispatch(fetchAlldraftMsg());
  }
  setTimeout(() => {
    try {
      callback();
    } catch {}
  }, 0);
  return data;
}

async function addEditRemarkDataThunk(
  {
    remarkId,
    remark,
    fetchAll = false,
    attachmentBatchNumber = [],
    callback = () => {},
  }: {
    remarkId: number | null;
    remark: string;
    fetchAll?: boolean;
    attachmentBatchNumber?: Array<string>;
    callback?: () => void;
  },
  { getState, dispatch }: { getState: Function; dispatch: Function }
) {
  const currentState: RootState = getState();
  if (
    currentState.draftFlow.draftMsg !== null &&
    currentState.draftFlow.draftMsg.messageId
  ) {
    
    let remarkPayload: any = {
      msgId: currentState.draftFlow.draftMsg.messageId,
      remarkId,
      remark,
      attachmentBatchNumber: attachmentBatchNumber
    }

    if(remarkPayload.attachmentBatchNumber.length === 0){
      delete  remarkPayload.attachmentBatchNumber;
    }

    const remarkData = await addEditRemark(remarkPayload);
    if (fetchAll) {
      dispatch(fetchAlldraftMsg());
    }
    setTimeout(() => {
      callback();
    }, 0);
    return remarkData;
  } else {
    throw "Can not add remark without messageId";
  }
}

async function deleteDraftRemarkDataThunk(
  {
    msgId,
    remarkId,
    fetchAll = false,
  }: { msgId: number; remarkId: number; fetchAll?: boolean },
  { getState, dispatch }: { getState: Function; dispatch: Function }
) {
  const ret = await deleteDraftRemark({
    msgId,
    remarkId,
  });
  if (fetchAll) {
    dispatch(fetchAlldraftMsg());
  }
  return ret;
}

export default {
  fetchAllDraftMsgThunk,
  fetchDraftMsgHistoryThunk,
  addEditDraftMsgDataThunk,
  addEditRemarkDataThunk,
  deleteDraftRemarkDataThunk,
  fetchUnsentDraftThunk,
  fetchRemarkDataThunk,
};
