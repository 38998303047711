import { useAppSelector } from "src/store/store";
import ReportTicketBody from "../../../ReportTicketBody/ReportTicketBody";

function VolumeActivityReports() {
  const ui_visibility = useAppSelector(
    (state) => state.globals.currentUserData?.ui_visibility,
  );

  return (
    <div>
      {ui_visibility?.includes("tickets_volumeActivity_ticketsCreated") && (
        <ReportTicketBody
          endPoint={"tickets/volumeActivity/ticketsCreated"}
          title={"Tickets created by time"}
          tooltip={
            "This graph displays the number of tickets created over time, providing insights into the ticket creation patterns for the specified period."
          }
          tabProps={{
            showComparison: false,
            showDailyPart: true,
            showListView: true,
          }}
        />
      )}
      {ui_visibility?.includes(
        "tickets_volumeActivity_averageTicketsGraph",
      ) && (
        <ReportTicketBody
          endPoint={"tickets/volumeActivity/averageTicketsGraph"}
          title={"Average Number Of Tickets Created Per Day"}
          tooltip={
            "This graph represents the average number of tickets created each day."
          }
          tabProps={{
            showComparison: false,
            showDailyPart: true,
            showListView: true,
          }}
        />
      )}
      {ui_visibility?.includes("tickets_volumeActivity_ticketsClosed") && (
        <ReportTicketBody
          endPoint={"tickets/volumeActivity/ticketsClosed"}
          title={"Tickets closed by time"}
          tooltip={
            "This graph shows the number of tickets closed over time, offering insights into ticket closure patterns for the specified period."
          }
          tabProps={{
            showComparison: false,
            showDailyPart: true,
            showListView: true,
          }}
        />
      )}
      {ui_visibility?.includes("tickets_volumeActivity_backlogPercentage") && (
        <ReportTicketBody
          endPoint={"tickets/volumeActivity/backlogPercentage"}
          title={"Backlog Percentage"}
          tooltip={
            "The percentage of tickets created during a specific period that remained unanswered or unresolved within 24 hours."
          }
          tabProps={{
            showComparison: false,
            showDailyPart: true,
            showListView: true,
          }}
        />
      )}
      {ui_visibility?.includes("tickets_volumeActivity_responseSent") && (
        <ReportTicketBody
          endPoint={"tickets/volumeActivity/responseSent"}
          title={"Responses sent by time"}
          tooltip={
            "This graph depicts the number of responses over time, providing insights into response patterns for the given period. It takes into account multiple responses sent within a single ticket, offering a comprehensive view of the overall response activity."
          }
          tabProps={{
            showComparison: false,
            showDailyPart: true,
            showListView: true,
          }}
        />
      )}
    </div>
  );
}

export default VolumeActivityReports;
