import {
  fetchCustomerData,
  fetchShopifySidebarData,
  resetShopifySidebar,
  setCustomerId,
} from "src/store/slices/shopifySidebar/shopifySidebar.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import styles from "./ListCustomer.module.scss";
import { useEffect, useRef } from "react";

const ListCustomer = ({ customerData, setShowWhat }: any) => {
  const dispatch = useAppDispatch();

  //Get ui visibility from store
  const ui_visibility = useAppSelector(
    (state) => state.globals.currentUserData?.ui_visibility,
  );
  const currentState = useRef({
    ui_visibility,
  });
  //Update when ui_visibility changes
  useEffect(() => {
    currentState.current = {
      ui_visibility,
    };
  }, [ui_visibility]);

  const handleOnClickCustomer = (e: any) => {
    e.preventDefault();
    dispatch(resetShopifySidebar());
    // when a customer item is clicked, show sidebar data for that customer
    setShowWhat("customerSidebar");
    // sets the customer id for shopify in customer sidebar
    dispatch(setCustomerId({ id: customerData.id })); // id of selected customer
    // fetch the selected customer data
    dispatch(
      fetchCustomerData({
        includeTimeline: true,
      }),
    );
    // fetching shopify data for customer sidebar only if it is in ui visibility
    if (
      currentState.current.ui_visibility?.includes("integration_shopify_view")
    ) {
      dispatch(fetchShopifySidebarData());
    }
  };

  return (
    <div
      className={`d-flex flex-row w-100 ${styles.mainDiv}`}
      onClick={(e: any) => handleOnClickCustomer(e)}
    >
      <div
        className={`dflex flex-cloumn `}
        style={{ minWidth: "92%" }}
      >
        <div className={`dflex flex-row ${styles.customerName}`}>
          {customerData.name ?? "NA"}
        </div>{" "}
        <div className={`dflex flex-row  ${styles.customerEmail}`}>
          {customerData.email ?? "NA"}
        </div>
      </div>
      <div
        className={`dflex flex-cloumn m-auto`}
        style={{ minWidth: "8%" }}
      >
        <span className={`${styles.icon}`}>
          <i className={`fas fa-chevron-right `}></i>
        </span>
      </div>
    </div>
  );
};

export default ListCustomer;
