import {
  BaseConfigModalTabType,
  GetConfigModalParams,
} from "src/features/ReturnAutoWorkFlow/children/ConfigModals/oldReturnModals.types";
import { axiosJSON } from "src/globals/axiosEndPoints";

export interface ReturnTypeBasedOnOrderOrItemsTab
  extends BaseConfigModalTabType {
  notEligibleResponseMessage: string;
  returnTypes: {
    name: string;
    key: string;
    enabled: boolean;
  }[];
}

async function getReturnOrderOrItemsService(params: GetConfigModalParams) {
  const { data: res } = await axiosJSON.get(
    "/api/setting/automation/refund/step/11/configuration",
    { params },
  );

  if (res.err || res.error) {
    throw new Error(res.msg ?? res.message ?? "Some Error Occurred.");
  }

  return res.data as ReturnTypeBasedOnOrderOrItemsTab[];
}

export default getReturnOrderOrItemsService;
