import styles from "./ConditionBox.module.scss";
import { ReactNode, SetStateAction, useState } from "react";
import Select, { components } from "react-select";
import { v4 as uuidv4 } from "uuid";
import { CloseButton, Dropdown } from "react-bootstrap";
import { useAppSelector } from "src/store/store";
const ConditionBox = (props: {
  onDelete: (arg0: any) => void;
  condition: any;
  isFirstElement: boolean;
}) => {
  function handleTrashButton() {
    props.onDelete(props.condition);
  }

  let [count, setCount] = useState(0);

  const conditionType = useAppSelector((state) => {
    return state.targetedMessage.conditionType;
  });

  const options = [
    {
      value: 1,
      label: "Current page URL",
      icon: <i className="fa-solid fa-earth-asia"></i>,
    },
    {
      value: 2,
      label: "Customer’s location",
      icon: <i className="fa-solid fa-location-dot"></i>,
    },
    {
      value: 3,
      label: "Customer’s browsing time",
      icon: <i className="fa-solid fa-clock"></i>,
    },
    {
      value: 4,
      label: "Number of viewed pages",
      icon: <i className="fa-solid fa-earth-asia"></i>,
    },
    {
      value: 5,
      label: "Refering website address",
      icon: <i className="fa-solid fa-earth-asia"></i>,
    },
    {
      value: 6,
      label: "Pages visited in exact order",
      icon: <i className="fa-solid fa-earth-asia"></i>,
    },
    {
      value: 7,
      label: "Any pages from session",
      icon: <i className="fa-solid fa-earth-asia"></i>,
    },
    {
      value: 8,
      label: "Custom variable",
      icon: <i className="fa-solid fa-star"></i>,
    },
  ];

  const Option = (props: any) => (
    <components.Option {...props}>
      {props.data.icon} &nbsp;
      {props.data.label}
    </components.Option>
  );

  const optionListNew = [
    { value: "Contains", label: "Contains" },
    { value: "Does not contain", label: "Does not contain" },
    { value: "Is exactly", label: "Is exactly" },
    { value: "Is not", label: "Is not" },
  ];
  const optionList3 = [
    { value: "Page URL contains", label: "Page URL contains" },
    { value: "Page URL is not", label: "Page URL is not" },
    { value: "Page URL is exactly", label: "Page URL is exactly" },
  ];
  const optionList4 = [
    { value: "contains", label: "contains" },
    { value: "is exactly", label: "is exactly" },
    { value: "is not", label: "is not" },
    { value: "is greater than", label: "is greater than" },
    { value: "is greater or equal", label: "is greater or equal" },
    { value: "does not contain", label: "does not contain" },
    { value: "is lower than", label: "is lower than" },
    { value: "is lower or equal", label: "is lower or equal" },
  ];

  const SingleValue = ({ children, ...props }: any) => (
    <components.SingleValue {...props}>{children}</components.SingleValue>
  );

  let [selectedOption, setSelectedOption] = useState(1);

  let [nextPageCount, setNextPageCount] = useState<any[]>([]);

  const handleClick = () => {
    setNextPageCount((nextPageCount) => [...nextPageCount, uuidv4()]);
  };

  return (
    <>
      {!props.isFirstElement ? (
        <span className={`my-2 text-center d-block`}>{conditionType}</span>
      ) : (
        ""
      )}
      <div className={`${styles.conditionBox} `} id={props.condition.id}>
        <div className="d-flex justify-content-between w-100">
          {/* Content */}

          {selectedOption == 8 ? (
            <div className="d-flex flex-column  w-100">
              {/* Select Button */}
              <div className="d-flex flex-column ">
                <Select
                  options={options}
                  defaultValue={options[8]}
                  onChange={(e) => {
                    setSelectedOption(e?.value ?? -1);
                  }}
                  className={`${styles.selectItem2}`}
                  styles={{
                    singleValue: (base) => ({
                      ...base,
                      display: "flex",
                      alignItems: "center",
                    }),
                  }}
                  components={{
                    Option,
                    SingleValue,
                  }}
                  menuPlacement="auto"
                />

                <div className="d-flex mt-3  ">
                  <input
                    type="text"
                    placeholder="Enter variable name"
                    className={`${styles.inputBox} w-50 `}
                  />

                  <Select
                    options={optionList4}
                    defaultValue={optionList4}
                    className={" w-50 ms-4"}
                  />
                  <input
                    type="text"
                    placeholder="Enter variable value"
                    className={`${styles.inputBox}  w-50 ms-4`}
                  />
                </div>
              </div>
            </div>
          ) : selectedOption == 6 ? (
            <>
              <div className="d-flex flex-column  w-100">
                {/* Select Button */}
                <div className="d-flex ">
                  <Select
                    options={options}
                    defaultValue={options[5]}
                    onChange={(e) => {
                      setSelectedOption(e?.value ?? -1);
                    }}
                    className={`${styles.selectItem2}`}
                    styles={{
                      singleValue: (base) => ({
                        ...base,
                        display: "flex",
                        alignItems: "center",
                      }),
                    }}
                    components={{
                      Option,
                      SingleValue,
                    }}
                  />
                </div>

                {/* Condition Based Item */}

                <div className="d-flex mt-3  w-75 small">
                  <div className="small text-muted pt-2">1.</div>
                  <Select
                    options={optionList3}
                    defaultValue={optionList3}
                    className={"w-50 ms-4"}
                  />
                  <input
                    type="text"
                    placeholder="Enter a string"
                    className={`${styles.inputBox} ms-3 w-25`}
                  />
                </div>
                {nextPageCount.map((val, idx) => {
                  return (
                    <div key={val}>
                      <div className="d-flex mt-3  w-75 small">
                        <div className="small text-muted pt-2">{idx + 2}</div>
                        <Select
                          options={optionList3}
                          defaultValue={optionList3}
                          className={"w-50 ms-4"}
                        />
                        <input
                          type="text"
                          placeholder="Enter a string"
                          className={`${styles.inputBox} ms-3 w-25`}
                        />
                        <CloseButton
                          className="small pt-4 ms-2"
                          onClick={() => {
                            setNextPageCount((nextPageCount) => [
                              ...nextPageCount.slice(0, idx),
                              ...nextPageCount.slice(idx + 1),
                            ]);
                          }}
                        />
                      </div>
                    </div>
                  );
                })}

                <div
                  className={`mt-3 d-flex`}
                  role="button"
                  onClick={handleClick}
                >
                  <div>
                    <div className={`d-block ${styles.plusBox} `}>
                      {" "}
                      <i className="fa-solid fa-plus"></i>
                    </div>
                  </div>
                  <div className={`${styles.addSpan} mt-1`}> Next Page </div>
                </div>
              </div>
            </>
          ) : (
            <div className="d-flex   w-100">
              {/* Select Button */}
              <div className="d-flex ">
                <Select
                  options={options}
                  defaultValue={options[0]}
                  onChange={(e) => {
                    setSelectedOption(e?.value ?? -1);
                  }}
                  className={`${styles.selectItem2}`}
                  styles={{
                    singleValue: (base) => ({
                      ...base,
                      display: "flex",
                      alignItems: "center",
                    }),
                  }}
                  components={{
                    Option,
                    SingleValue,
                  }}
                />
              </div>

              {/* Condition Based Item */}
              <div className={`${styles.selectItem3} d-flex   w-100  ms-3`}>
                {selectedOption == 1 ? (
                  <>
                    <Select
                      options={optionListNew}
                      defaultValue={optionListNew}
                      className={"w-50"}
                    />

                    <input
                      type="text"
                      placeholder="Enter a string"
                      className={`${styles.inputBox} ms-3 w-50`}
                    />
                  </>
                ) : (
                  ""
                )}

                {selectedOption == 2 ? (
                  <>
                    <input
                      type="text"
                      placeholder="Enter country or city name"
                      className={`${styles.inputBox} w-100 `}
                    />
                  </>
                ) : (
                  ""
                )}

                {selectedOption == 3 ? (
                  <div className=" d-flex  w-100 ">
                    <span className="pt-2">is more than:</span>
                    <span className="ms-2">
                      <div className={`d-flex ${styles.timeSet}`}>
                        <div className={`${styles.timeSet}`}>
                          <span>{count}</span>
                        </div>
                        <div
                          className={`d-flex flex-column ${styles.arrowBox}`}
                        >
                          <i
                            className={`${styles.upArrow} fa-solid fa-sort-up`}
                            onClick={(e) => {
                              setCount(++count);
                            }}
                          ></i>
                          <i
                            className={`${styles.downArrow} fa-solid fa-caret-down`}
                            onClick={(e) => {
                              setCount(--count);
                            }}
                          ></i>
                        </div>
                      </div>
                    </span>
                    <span className="pt-2 ms-2">seconds</span>
                  </div>
                ) : (
                  ""
                )}

                {selectedOption == 4 ? (
                  <div className=" d-flex  w-100 ">
                    <span className="pt-2">is at least </span>
                    <span className="ms-2">
                      <div className={`d-flex ${styles.timeSet}`}>
                        <div className={`${styles.timeSet}`}>
                          <span>{count}</span>
                        </div>
                        <div
                          className={`d-flex flex-column ${styles.arrowBox}`}
                        >
                          <i
                            className={`${styles.upArrow} fa-solid fa-sort-up`}
                            onClick={(e) => {
                              setCount(++count);
                            }}
                          ></i>
                          <i
                            className={`${styles.downArrow} fa-solid fa-caret-down`}
                            onClick={(e) => {
                              setCount(--count);
                            }}
                          ></i>
                        </div>
                      </div>
                    </span>
                    <span className="pt-2 ms-2">seconds</span>
                  </div>
                ) : (
                  ""
                )}

                {selectedOption == 5 ? (
                  <>
                    <Select
                      options={optionListNew}
                      defaultValue={optionListNew}
                      className={"w-50"}
                    />

                    <input
                      type="text"
                      placeholder="Enter a string"
                      className={`${styles.inputBox} ms-3 w-50`}
                    />
                  </>
                ) : (
                  ""
                )}

                {selectedOption == 7 ? (
                  <>
                    <Select
                      options={optionListNew}
                      defaultValue={optionListNew}
                      className={"w-50"}
                    />

                    <input
                      type="text"
                      placeholder="Enter a string"
                      className={`${styles.inputBox} ms-3 w-50`}
                    />
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          )}

          {/* Trash Button */}
          <div onClick={handleTrashButton}>
            <span className={`ms-2 ${styles.trash} mt-2`}>
              <i className="fa-solid fa-trash"></i>
            </span>
          </div>
        </div>

        {/* For customer page url  */}

        {/* For current location */}
        {/* <>
           <input
              type="text"
              placeholder="Enter country or city name"
              className={`${styles.countryBox}`}
            />
           </> */}
        {/* For browsing time */}
        {/* <>
              <div className={`d-flex align-items-center ms-2`}>
                <span>is more than:</span>
                <span className="mx-1">
                  <div className={`d-flex ${styles.timeSet}`}>
                    <div className={`${styles.timeSet}`}>
                      <span>{count}</span>
                    </div>
                    <div className={`d-flex flex-column ${styles.arrowBox}`}>
                      <span
                        className={`${styles.upArrow}`}
                        onClick={handleIncr}
                      >
                        <i className="fa-solid fa-sort-up"></i>
                      </span>
                      <span
                        className={`${styles.downArrow}`}
                        onClick={handleDec}
                      >
                        <i className="fa-solid fa-caret-down"></i>
                      </span>
                    </div>
                  </div>
                </span>
                <span>seconds</span>
              </div>
            </> */}

        {/* For Number of viewed pages */}
        {/* <>
              <div className={`d-flex align-items-center ms-2`}>
                <span>is at least </span>
                <span className="mx-1">
                  <div className={`d-flex ${styles.timeSet}`}>
                    <div className={`${styles.timeSet}`}>
                      <span>{count}</span>
                    </div>
                    <div className={`d-flex flex-column ${styles.arrowBox}`}>
                      <span
                        className={`${styles.upArrow}`}
                        onClick={handleIncr}
                      >
                        <i className="fa-solid fa-sort-up"></i>
                      </span>
                      <span
                        className={`${styles.downArrow}`}
                        onClick={handleDec}
                      >
                        <i className="fa-solid fa-caret-down"></i>
                      </span>
                    </div>
                  </div>
                </span>
                <span>seconds</span>
              </div>
            </> */}
        {/* Pages visited in exact order */}
        {/* <>
             <Select
              options={optionList3}
              className={`${styles.selectItem3}`}
            />
            <input
              type="text"
              placeholder="Enter a string"
              className={`${styles.inputBox}`}
            />
            </> */}

        {/* Custom variable */}
        {/* <>
              <input
                type="text"
                placeholder="Enter variable name"
                className={`ms-3 ${styles.inputBox}`}
              />
              <Select
                options={optionList4}
                className={`${styles.selectItem3}`}
              />
              <input
                type="text"
                placeholder="Enter variable value"
                className={`${styles.inputBox}`}
              />
            </> */}
      </div>
    </>
  );
};
export default ConditionBox;
