import styles from "./NoMembers.module.scss";
import noMembersInTeamImg from "src/assets/images/noMembersInTeam.png"
function NoMembers(props: any){
  return(
    <div 
    className={`d-flex justify-content-center align-items-center text-center`}
    >
      <div>
        <div>
          <img src={noMembersInTeamImg} className={`img-fluid ${styles.noMembersImg}`} />
        </div>
        <div className={`${styles.noMembersText}`}>
          <p>Seems like there are no members yet.</p>
        </div>
      </div>
    </div>
  )
}

export default NoMembers;