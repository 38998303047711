import { axiosJSON } from "src/globals/axiosEndPoints";
import { ICustomerData } from "src/store/slices/shopifySidebar/shopifySidebar.slice";

export interface EditCustomer {
  isUpdated: boolean;
}

export async function editCustomer(params: Omit<ICustomerData, "channels">) {
  const { data }: any = await axiosJSON.post(`/api/customer/update`, params);

  if (data.errors) {
    throw new Error(data.errors.join(""));
  }

  if (data.error === true) {
    throw new Error(data.message as string);
  }

  return data.data as EditCustomer;
}
