import React, { useCallback, useMemo, useRef, useState } from "react";
import styles from "./AgentFilter.module.scss";
import { Dropdown } from "react-bootstrap";
import {
  useReportFilters,
  reportCurrentStatusActions,
} from "src/routes/Report/hooks/reportFilters/useReportFilters";
import { v4 as uuid } from "uuid";

const useAgentTypeFilter = () => {
  const agentTypes = useMemo(() => {
    return [
      { id: "agent", name: "Agent" },
      { id: "lead_agents", name: "Lead Agents" },
      { id: "admin", name: "Admin" },
      { id: "support", name: "Support" },
    ];
  }, [false]);
  const allAgentTypeId = useMemo(() => {
    return agentTypes.map((data) => data.id);
  }, [agentTypes]);

  const { currentStatus, dispatch } = useReportFilters();

  const selectedAgentTypes = useMemo(() => {
    return currentStatus.selectedAgentTypes;
  }, [currentStatus]);

  const selectAllAgentTypes = useCallback(
    (e: any) => {
      if (e.target.checked) {
        dispatch([
          reportCurrentStatusActions.setFilters,
          {
            selectedAgentTypes: [...allAgentTypeId],
          },
        ]);
      } else {
        dispatch([
          reportCurrentStatusActions.setFilters,
          { selectedAgentTypes: [] },
        ]);
      }
    },
    [allAgentTypeId]
  );
  const clearAllAgentTypes = useCallback(() => {
    dispatch([
      reportCurrentStatusActions.setFilters,
      { selectedAgentTypes: [] },
    ]);
  }, []);
  const checkUncheckAgentTypes = useCallback(
    (e: any) => {
      if (e.target.checked) {
        dispatch([
          reportCurrentStatusActions.setFilters,
          { selectedAgentTypes: [...selectedAgentTypes, e.target.value] },
        ]);
      } else {
        dispatch([
          reportCurrentStatusActions.setFilters,
          {
            selectedAgentTypes: selectedAgentTypes.filter(
              (id) => id !== e.target.value
            ),
          },
        ]);
      }
    },
    [selectedAgentTypes]
  );
  return {
    agentTypes,
    selectedAgentTypes,
    selectAllAgentTypes,
    clearAllAgentTypes,
    checkUncheckAgentTypes,
  };
};
interface Props {
  isSegement?: boolean;
  disabled?: boolean;
}
const AgentFilter = ({ isSegement, disabled }: Props) => {
  const {
    agentTypes,
    selectedAgentTypes,
    selectAllAgentTypes,
    clearAllAgentTypes,
    checkUncheckAgentTypes,
  } = useAgentTypeFilter();
  const [ddOpen, setDdOpen] = useState(false);
  const dropdownArrowSpanRef = useRef<any>();
  const uuidRef = useRef(uuid());

  return (
    <div className={`me-2 mb-2 mb-lg-0`}>
      <Dropdown onToggle={setDdOpen}>
        <Dropdown.Toggle
          as="div"
          bsPrefix={`dropdown-toggle cursor-pointer border-0 px-2 py-1 ${
            isSegement === true ? styles.segementBtn : styles.filterButton
          } ${disabled && styles.disable}`}
        >
          Agents
          {selectedAgentTypes.length ? (
            <span className={`mx-1 ${styles.filterCount}`}>
              {/* added condition to show 0 before count if count is less than 10 */}
              {selectedAgentTypes.length < 10
                ? `0${selectedAgentTypes.length}`
                : selectedAgentTypes.length}
            </span>
          ) : (
            ""
          )}
          <span className="ps-1" ref={dropdownArrowSpanRef}>
            {ddOpen ? (
              <i className={`fa-solid fa-caret-up`}></i>
            ) : (
              <i className={`fa-solid fa-caret-down`}></i>
            )}
          </span>
        </Dropdown.Toggle>

        <Dropdown.Menu bsPrefix={`dropdown-menu border-0 ${styles.filterMenu}`}>
          <span className={`${styles.dropItem}`}>
            <div
              className={`${styles.itemWrapper} ${
                selectedAgentTypes.length === agentTypes.length &&
                styles.selectedText
              }`}
            >
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={"all"}
                  id={uuidRef.current + "report-filter-agent-types-all-select"}
                  checked={selectedAgentTypes.length === agentTypes.length}
                  onChange={selectAllAgentTypes}
                />
                <label
                  className={`form-check-label ${styles.checktext}`}
                  htmlFor={
                    uuidRef.current + "report-filter-agent-types-all-select"
                  }
                >
                  All Agents
                </label>
              </div>
            </div>
          </span>
          {agentTypes.map((data) => {
            return (
              <span key={data.id} className={`${styles.dropItem}`}>
                <div
                  className={`${styles.itemWrapper} ${
                    selectedAgentTypes.includes(data.id as any) &&
                    styles.selectedOne
                  }`}
                >
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={data.id}
                      id={
                        uuidRef.current + "reports-filter-agent-type" + data.id
                      }
                      checked={selectedAgentTypes.includes(data.id as any)}
                      onChange={checkUncheckAgentTypes}
                    />
                    <label
                      className={`form-check-label ${styles.checktext}`}
                      htmlFor={
                        uuidRef.current + "reports-filter-agent-type" + data.id
                      }
                    >
                      {data.name}
                    </label>
                  </div>
                </div>
              </span>
            );
          })}
          <span className={`${styles.clearSpan}`} onClick={clearAllAgentTypes}>
            Clear all
          </span>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default AgentFilter;
