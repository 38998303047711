import { IReadCustomerEmails } from "src/apis/addTicket/read/addTicket.read.api";
import { axiosJSON } from "src/globals/axiosEndPoints";
import { LiveMsgType } from "../messageExchange/sendMessage";

export interface WhatsappData {
  start: number;
  limit: number;
  search: string;
  type: "whatsapp_to" | "whatsapp_from";
}

export async function getWhatsappData(payload: WhatsappData) {
  try {
    const response = await axiosJSON.post(`/whatsapp`, payload);
    return response.data;
  } catch (error) {
    throw "Something went wrong!";
  }
}

export interface InstagramData {
  start: number;
  limit: number;
  search: string;
  type: "instagram_to" | "instagram_from";
}

export async function getInstagramData(payload: InstagramData) {
  try {
    const response = await axiosJSON.post(`/instagram`, payload);
    return response.data;
  } catch (error) {
    throw "Something went wrong!";
  }
}

export interface MessengerData {
  start: number;
  limit: number;
  search: string;
  type: "messenger_to" | "messenger_from";
}

export async function getMessengerData(payload: MessengerData) {
  try {
    const response = await axiosJSON.post(`/messenger`, payload);
    return response.data;
  } catch (error) {
    throw "Something went wrong!";
  }
}

export type ChannelType =
  | "email"
  | "messenger"
  | "instagram"
  | "whatsapp"
  | "sms";

export interface StartNewChatPayload {
  chat_id: string | number;
  message: string;
  messageType: LiveMsgType;
  channelType: ChannelType;
  attachmentBatchNumber?: Array<string>; // for saving the batch id of attachment
  uuid: string; //unique id for message, used for try again feature in send message
  attachments?: Array<any>;
  toValue: string | string[] | undefined;
}

export async function startNewChat(payload: StartNewChatPayload) {
  try {
    const response = await axiosJSON.post(`/startNewChat`, payload);
    return response.data;
  } catch (error) {
    throw "Something went wrong!";
  }
}

export interface WhatsappToPayload {
  searchTerm: string;
  start: number;
  limit: number;
  customerId: number | string;
  channelType: string;
}
export interface PhoneNumberData {
  countryCode: number;
  phoneNumber: string;
}

export interface WhatsappToResponse {
  err: boolean;
  msg: string;
  data: PhoneNumberData[];
  metaData: {
    totalCount: number;
    currentCount: number;
  };
}

export async function getWhatsappToData(payload: WhatsappToPayload) {
  try {
    const response = await axiosJSON.post(`/api/customer/toDetails`, payload);
    return response.data as WhatsappToResponse;
  } catch (error) {
    throw "Something went wrong!";
  }
}

export interface WhatsappFromPayload {
  start: number;
  limit: number;
  channelType: string;
  searchTerm: string;
}
export interface PhoneNumberData {
  countryCode: number;
  phoneNumber: string;
}

export async function getWhatsappFromData(payload: WhatsappFromPayload) {
  try {
    const response = await axiosJSON.post(
      `/api/liveChat/chat/fromDetails`,
      payload
    );
    return response.data as WhatsappToResponse;
  } catch (error) {
    throw "Something went wrong!";
  }
}

export interface StartChatPayload {
  message: string;
  // messageType: LiveMsgType;
  attachmentBatchNumber?: Array<string>; // for saving the batch id of attachment
  uuid: string; //unique id for message, used for try again feature in send message
  // attachments?: Array<any>;
  to: string;
  from: string;
  channelType: ChannelType;
}

export interface StartChatResponse {
  err: boolean;
  msg: string;
  data: { chatId: string | number };
}

export async function startChat(payload: StartChatPayload) {
  try {
    const response = await axiosJSON.post(
      `/api/liveChat/agent/startNewChat`,
      payload
    );
    return response.data as StartChatResponse;
  } catch (error) {
    throw "Something went wrong!";
  }
}
