import { axiosJSON } from "src/globals/axiosEndPoints";
import APIResponse from "./APIReponse";
import { Phone } from "src/store/slices/editCustomerDetails/editCustomerDetails.declarations";

export interface GetAllUserParams {
  searchTerm?: string;
  start: number;
  limit: number;
}

interface Team {
  teamId: number | string;
  teamName: string;
}

export interface User {
  userId: number;
  firstName: string;
  lastName: string;
  email: string;
  userRole: string | number | null;
  phone: Phone | string | null;
  isOwner: null | boolean;
  userStatus: null | string;
  profileImageId: string | number | null;
  profileImageUrl: string | null;
  teams: Array<Team> | null;
  isPublicAttachmentUrl?: boolean;
  hasLoggedInSessions?: boolean;
  isDeactivated?: boolean;
}

export interface GetAllUser {
  users: { [userId: number | string]: User };
  userIds: Array<number | string>;
  metaData: {
    count: number;
    total: number;
  };
}

export async function getAllUser(params: GetAllUserParams) {
  if (
    params.searchTerm === undefined ? false : params.searchTerm.trim() === ""
  ) {
    delete params.searchTerm;
  }
  const { data: res }: any = await axiosJSON.post<APIResponse<GetAllUser>>(
    "/api/setting/user/getAll",
    params,
  );

  if (res.error === true) {
    throw new Error(res.message as string);
  }
  const ret: GetAllUser = {
    users: {},
    userIds: [],
    metaData: {
      count: 0,
      total: 0,
    },
  };
  ret.metaData.count = res.count;
  ret.metaData.total = res.total;
  res.users.forEach((user: User) => {
    ret.users[user.userId] = user;
    ret.userIds.push(user.userId);
  });
  return ret;
}
