import { RouteNames } from "src/containers/SideBar/SideBar";
import { ESideBar } from "src/store/slices/tickets/tickets.slice";

export enum ETableTypes {
  all = "all",
  my = "my",
}
export enum ETableTypeIndex {
  all = 0,
  my,
}
export enum ETableTypeNames {
  all = ESideBar.allTickets,
  my = ESideBar.myTickets,
}

export enum ESideBarFilterIndex {
  activeChannelId = 0,
  activeTicketStatusId,
  activeDraftStatusId,
}

export const ETicketViewTypes: any = {
  ticketView: "ticket-view",
  responseView: "response-view",
};

export function mainSBLink({
  search,
  pathName,
}: {
  search: string;
  pathName: string;
}) {
  let link = "";
  if (
    pathName.indexOf(RouteNames.tickets) === 0 &&
    !pathName.includes("/ticket/")
  ) {
    link = pathName + search;
  } else if (pathName.indexOf(RouteNames.tickets) === 0) {
    link = pathName.slice(0, pathName.indexOf("/ticket/"));
  } else {
    link = `${RouteNames.tickets}/${ETableTypes.all}/${ETicketViewTypes.ticketView}`;
  }
  return link;
}

export function homeLink() {
  const link = `${RouteNames.tickets}/${ETableTypes.all}/${ETicketViewTypes.ticketView}`;
  return link;
}

export function viewIdLink(viewId: number) {
  return `${RouteNames.tickets}/viewId::${viewId}/${ETicketViewTypes.ticketView}`;
}

export function subSBLink({
  tableType,
  search,
  pathName,
}: {
  tableType: string;
  search: string;
  pathName: string;
}) {
  let viewType = ETicketViewTypes.ticketView;
  for (const x in ETicketViewTypes) {
    if (pathName.includes(ETicketViewTypes[x])) {
      viewType = ETicketViewTypes[x];
      break;
    }
  }
  // return `${RouteNames.tickets}/${tableType}/${viewType}/${search}`;

  // console.log("subSBLink search:: ", search);
  // while changing table type in button, if tableType is not the current one, remove search params
  // if(pathName.includes(`/${tableType}/`)){
  //   return `${RouteNames.tickets}/${tableType}/${viewType}/${search}`;
  // }else{
  //   return `${RouteNames.tickets}/${tableType}/${viewType}`;
  // }

  // if there is an search query related to current view add it with url
  return `${RouteNames.tickets}/${tableType}/${viewType}/${search}`;
}

interface SBFLinkParams {
  sidebarFilters: any;
  selected: number;
  key: number;
  defaultDDSelectors: any;
}
export enum SidebalActiveFiltersMapping {
  activeChannelId = 0,
  activeTicketStatusId,
  activeDraftStatusId,
}

export function sidebarFiltersLink({
  sidebarFilters,
  selected,
  key,
  defaultDDSelectors,
}: SBFLinkParams) {
  if (sidebarFilters == null) {
    sidebarFilters = defaultDDSelectors ?? {
      activeChannelId: 0,
      activeTicketStatusId: 0,
      activeDraftStatusId: 0,
    };
  }
  // console.log(selected);
  sidebarFilters[SidebalActiveFiltersMapping[selected]] = key;
  return sidebarFilters;
}

export function getTicketIdRouteLink({
  ticketId,
  tableType,
  viewType,
}: {
  ticketId: string;
  tableType: string;
  viewType: string;
}) {
  return `${RouteNames.tickets}/${tableType}/${viewType}/ticket/${ticketId}`;
}
