import styles from "./TicketChannel.module.scss";
import log from "loglevel";
import { getChannelFAIcon } from "src/utils/utils";
import { useAppSelector } from "src/store/store";
import { selectTicketCellData } from "src/store/slices/tickets/tickets.slice";
import { TableCellProps } from "..";

function TicketChannel({ ticketId, colKeys }: TableCellProps) {
  const ticketCellData: any = useAppSelector((state) =>
    selectTicketCellData(state, ticketId, colKeys)
  );
  
  return (
    <span className="d-flex flex-column justify-content-center">
      <div className={`${styles["subject"]} `}>
        <span className={`${styles["tbody"]}`}>
          <span className={`me-1 px-4 fb`}>
            <i
              className={`fa-brands override  ${getChannelFAIcon(
                ticketCellData[0]
              )}`}
            />
          </span>
        </span>
      </div>
    </span>
  );
}

export default TicketChannel;
