import { OverlayTrigger, Tooltip } from "react-bootstrap";
import styles from "../CustomerEdit/CustomerEdit.module.scss";
import envelop from "src/assets/images/envelop.png";
import { useAppSelector } from "src/store/store";
import { InputFieldsContext } from "../CustomerEdit/useInputFields";
import AddMore from "../AddMore/AddMore";
import { useContext, useEffect } from "react";
import { validateEmail } from "src/utils/utils";
import IsFirstRender from "./IsFirstRender";

function OtherEmail(props: any) {
  const otherEmails = useAppSelector(
    (state) => state.editcustomerdetails.customer.contacts?.otherEmails,
  );

  const {
    addField,
    updateField,
    removeField,
    setFieldsOfKey,
    fields,
    areDuplicateFields,
  } = useContext(InputFieldsContext);

  useEffect(() => {
    setFieldsOfKey(otherEmails ?? [], "otherEmails");
  }, [otherEmails]);

  const otherEmailsFields = fields.get("otherEmails");

  const isAddButtonEnabled = otherEmailsFields?.length
    ? otherEmailsFields[otherEmailsFields.length - 1]?.isValid
      ? true
      : false
    : false;

  const { hasDuplicates, duplicateIds } =
    areDuplicateFields.duplicates["otherEmails"];

  return (
    <>
      {fields.get("otherEmails")?.map((field, idx) => {
        const hasError = hasDuplicates && duplicateIds.includes(field.id);
        return (
          <div className="d-flex flex-column">
            {idx === 0 ? (
              <label
                htmlFor="email"
                className={`d-flex justify-content-between align-items-center form-label ${styles.labelName} m-0`}
              >
                <div className={`d-flex align-items-center`}>
                  <div
                    className={`d-flex justify-content-center align-items-center ${styles.msgBg}`}
                  >
                    <img
                      src={envelop}
                      className={`${styles.envelop}`}
                      alt="email"
                    />
                  </div>{" "}
                  <div>
                    <span className={`ps-1`}>Other E-mail</span>
                  </div>
                </div>
              </label>
            ) : (
              <></>
            )}

            <div
              className="d-flex mt-2 "
              style={{ width: "103.5%", overflowX: "hidden" }}
            >
              <div className="d-flex flex-column w-100">
                <IsFirstRender>
                  {(IsFirstRender) => {
                    return (
                      <>
                        <input
                          type="email"
                          data-lpignore="true"
                          className={`form-control ${styles.formInput} ${
                            (!IsFirstRender && !field.isValid) || hasError
                              ? "border border-danger"
                              : ""
                          }  `}
                          id="email"
                          aria-describedby="emailHelp"
                          placeholder="abcd@gmail.com"
                          required
                          name="Other Emails"
                          value={field.value}
                          onChange={(e) => {
                            updateField(
                              field,
                              {
                                ...field,
                                value: e.target.value,
                                isValid: validateEmail(e.target.value),
                              },
                              "otherEmails",
                            );
                          }}
                        />
                      </>
                    );
                  }}
                </IsFirstRender>
                <IsFirstRender>
                  {(isFirstRender) => {
                    return (
                      <>
                        {!isFirstRender && !field.isValid && (
                          <small className={`${styles.errorMsg}`}>
                            Please enter a valid email address
                          </small>
                        )}
                      </>
                    );
                  }}
                </IsFirstRender>
              </div>
              <div
                className={`${styles.customCrossPosition}`}
                onClick={() => {
                  removeField(field, "otherEmails");
                }}
              >
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className={`${styles.toolTipCustom}`}>
                      Remove
                    </Tooltip>
                  }
                >
                  {({ ref, ...triggerHandler }) => (
                    <div
                      className={` `}
                      {...triggerHandler}
                      ref={ref}
                    >
                      <div
                        className={`d-flex justify-content-center align-items-center ${styles.clearText}`}
                      >
                        <i className="fa-solid fa-xmark"></i>
                      </div>
                    </div>
                  )}
                </OverlayTrigger>
              </div>
            </div>
            {hasError && (
              <span className={`${styles.validateSpan}`}>
                Duplicate Emails not allowed
              </span>
            )}
          </div>
        );
      })}

      <span>
        <AddMore
          type="Email"
          isValid={isAddButtonEnabled}
          onClick={() => {
            if (!isAddButtonEnabled) {
              return;
            }
            addField({ value: "" }, "otherEmails");
          }}
          isVisible={(fields.get("otherEmails")?.length ?? 0) > 0}
        />
      </span>
      {/* <div className="d-flex flex-column">
        {props.firstIdx ? (
          <label
            htmlFor="name"
            className={`d-flex justify-content-between align-items-center form-label ${styles.labelName} m-0`}
          >
            <div className={`d-flex align-items-center`}>
              <div
                className={`d-flex justify-content-center align-items-center me-1 ${styles.msgBg}`}
              >
                <img src={envelop} className={`${styles.envelop}`} />
              </div>{" "}
              <div>
                <span>Other E-mail</span>
              </div>
            </div>
          </label>
        ) : (
          <></>
        )}

        <div
          className="d-flex mt-2 "
          style={{ width: "103.5%", overflowX: "hidden" }}
        >
          <input
            type="email"
            data-lpignore="true"
            className={`form-control ${styles.formInput} `}
            id="name"
            aria-describedby="emailHelp"
            placeholder="abcd@gmail.com"
            required
            name="Other Emails"
          />
          <div
            className={`${styles.customCrossPosition}`}
            onClick={() => {
              // dispatch(
              //   deleteContact({
              //     id: custId as string,
              //     type: "otherEmails",
              //     idx: props.idx,
              //   })
              // );
              props.getIdForDeletion(props.item.id);
            }}
          >
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip className={`${styles.toolTipCustom}`}>Remove</Tooltip>
              }
            >
              {({ ref, ...triggerHandler }) => (
                <div className={` `} {...triggerHandler} ref={ref}>
                  <div
                    className={`d-flex justify-content-center align-items-center ${styles.clearText}`}
                  >
                    <i className="fa-solid fa-xmark"></i>
                  </div>
                </div>
              )}
            </OverlayTrigger>
          </div>
        </div>
      </div> */}

      {/* <span className={`${styles.validateSpan}`}>
    Please enter a valid email address
  </span> */}
    </>
  );
}

export default OtherEmail;
