import { axiosJSON } from "src/globals/axiosEndPoints";

export interface IGetTemplates {
  template_category_id: number;
  start: number;
  limit: number;
}

export interface IGetTemplateResponse {
  id: number;
  template_body: string;
  template_category_id: number;
}

export async function getTemplates(apiPayload: IGetTemplates) {
  const { data } = await axiosJSON.post(
    "/api/template/getTemplates",
    apiPayload
  );

  // console.log(data);

  //   if (data.error === true) {
  //     throw new Error("Some Error Occurred while fetching categories");
  //   }

  return data;
}
