import { useMemo } from 'react';

const useCategoryError = (showLoader: boolean) => {
  const errorMessage = useMemo(() => {
    if (showLoader) {
      return <div className={`p-2 mx-auto text-center`}>No Result Found</div>;
    }
    return undefined;
  }, [showLoader]);

  return errorMessage;
};

export default useCategoryError;