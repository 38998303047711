import React, { useEffect, useRef } from "react";

const IsFirstRender: React.FC<{
  children: (isFirstRender: boolean) => JSX.Element;
}> = (props) => {
  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    }
  }, []);
  return props.children(isFirstRender.current);
};

export default IsFirstRender;
