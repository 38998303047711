import { axiosJSON } from "src/globals/axiosEndPoints";
import { FieldValueInstance } from "./getConditionOptions.service";
import { IS_TEST_ACTIVE } from "src/globals/constants";

/**
 * Interface for the parameters to be passed to the getConditionSelectDDService function.
 */
export interface ConditionSelectDDParams {
  type: string;
  fieldKey: string;
  /**
   * Format of string - "key(1)::key(2)::key(n)" - If n=0 then undefined
   */
  key?: string;
  start: number;
  limit: number;
}

export interface ConditionSelectDDRes {
  data: Array<FieldValueInstance>;
  metaData?: { isCompleted: boolean };
}

/**
 * Fetches condition select dropdown data for a given automation type and parameters.
 *
 * @param params - ConditionSelectDDParams
 * @returns An array of FieldValueInstance.
 * @throws Will throw an error if the response contains an error message.
 */
async function getConditionSelectDDService({
  type,
  ...params
}: ConditionSelectDDParams) {
  const apiEndPoint = IS_TEST_ACTIVE
    ? `${process.env.REACT_APP_TEST_SITE_URL}/${type}/getConditionSelectDD`
    : `/api/automation/${type}/getConditionSelectDD`;

  const { data: res } = await axiosJSON.post(apiEndPoint, params);

  if (res.error || res.err) {
    throw new Error(res.msg ?? res.message ?? "Something went wrong!");
  }

  const ret: ConditionSelectDDRes = {
    data: res.data,
    metaData: res.metaData,
  };

  return ret;
}

export default getConditionSelectDDService;
