import { axiosJSON } from "src/globals/axiosEndPoints";

export interface InstallShopifyWidgetParam {
  integrationId: number | string;
}

export const installShopifyWidgetService = async (
  params: InstallShopifyWidgetParam,
) => {
  // Make a POST request to the API endpoint to install the Shopify widget
  const { data: res } = await axiosJSON.post(
    `/api/chatSetting/widget/installShopifyWidget`,
    params,
  );

  // Check if there's an error response from the API
  if (res.err) {
    // Throw an error with the message from the API response, or a default message if not available
    throw new Error(res.msg ?? res.message ?? "Something went wrong");
  }

  // Return the data containing URL and installation status
  return res.data as { url?: string; installed?: boolean };
};
