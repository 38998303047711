import {
  createAsyncThunk,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { AJAXSTATUS } from "src/globals/constants";
import helpCenterSettingExtraReducers from "./helpCenterSetting.extraReducers";
import helpCenterSettingReducers from "./helpCenterSetting.reducers";
import helpCenterSettingThunks from "./helpCenterSetting.thunks";

export const fetchHelpCenterData = createAsyncThunk(
  "HelpCenterSettings/fetchHelpCenterData",
  helpCenterSettingThunks.fetchHelpCenterDataThunk
);

export interface HelpcenterAttachment{
  attachmentId: number|string;
  attachmentBatchNumber: string;
  attachmentName: string;
  attachmentSize: number| string;
  attachmentType: string;
  attachmentUrl: string;
}
export interface IHelpCenterData {
  helpdeskUrl: {domainName: string, extension: string};
  companyName: string;
  headlineText: string;
  logoAttachment : HelpcenterAttachment | null;
  backgroundAttachment: HelpcenterAttachment | null;
  faviconAttachment : HelpcenterAttachment | null;
  themeColor: string;
  googleAnalyticsTrackingId: string;
  searchEngineIndexing: boolean;
  isPublished : boolean;
}

export interface IHelpCenterSetting {
  helpCenterData : IHelpCenterData,
  helpCenterDataAjaxStatus: AJAXSTATUS,
  updateHelpCenterDataAjaxStatus: AJAXSTATUS,
}

export const initialState : IHelpCenterSetting = {
  
  helpCenterData: {
    helpdeskUrl : {domainName: "", extension: ""},
    companyName : "",
    headlineText : "",
    logoAttachment : null,
    backgroundAttachment : null,
    faviconAttachment : null,
    themeColor : "",
    googleAnalyticsTrackingId : "",
    searchEngineIndexing : false,
    isPublished : false
  },
  helpCenterDataAjaxStatus: "pending",
  updateHelpCenterDataAjaxStatus: "idle",
}

export const helpCenterSettingSlice = createSlice({
  name: "helpCenterSetting",
  initialState,
  reducers: helpCenterSettingReducers,
  extraReducers: (builder) => {
    builder.addCase(
      fetchHelpCenterData.fulfilled,
      helpCenterSettingExtraReducers.fetchHelpCenterDataFulfilled
    );
    builder.addCase(fetchHelpCenterData.rejected, (state) => {
      state.helpCenterDataAjaxStatus = "rejected";
      pushTheToast({
        position: "top-right",
        type: "danger",
        text: "Something went wrong!"
      });
    });
    builder.addCase(fetchHelpCenterData.pending, (state) => {
      state.helpCenterDataAjaxStatus = "pending";
    });

  }
});

export const { resetHelpCenterSetting } = helpCenterSettingSlice.actions;

export default helpCenterSettingSlice.reducer;
