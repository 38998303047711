import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import innerTicketReducers from "./innerTicket.reducers";
import { InnerTicketType } from "./innerTicket.slice";

const fetchInnerTicketsFulfilled = (
  state: InnerTicketType,
  { payload }: any,
) => {
  const {
    ticketStatusList,
    ticketData,
    ticketList,
    ticketListTotal,
    ticketDataById,
    customerId,
  }: any = payload as any;

  state.filters.customerId = customerId;
  if (state.filters.ticketId === 0) {
    state.filters.ticketId = parseInt(state.active.ticketId + "");
  }
  state.ticketData = {
    ...ticketDataById,
    ...state.ticketData,
    ...ticketData,
  };
  const ticketId = parseInt(state.active.ticketId + "");
  //for removing duplicate ticket ids in left sidebar ticket list .. added the Set class
  state.ticketList = Array.from(new Set([...state.ticketList, ...ticketList]));
  if (
    !state.ticketList.includes(ticketId) &&
    Object.keys(ticketDataById).length > 0
  ) {
    state.ticketList.unshift(ticketId);
    state.ticketListFlag = -1;
  }
  if (ticketData[ticketList[0]]) {
    state.customerData.name = ticketData[ticketList[0]].ticket_customer_name;
  }
  state.ticketListTotal = ticketListTotal;
  state.ticketStatusList = ticketStatusList;
  state.ticketDataAjaxStatus = "fulfilled";
};
const fetchAllInnerTicketsFulfilled = (
  state: InnerTicketType,
  { payload }: any,
) => {
  const { ticketData, ticketList, ticketListTotal }: any = payload as any;
  state.ticketData = {
    ...state.ticketData,
    ...ticketData,
  };
  //for removing duplicate ticket ids in left sidebar ticket list .. added the Set class
  state.ticketList = Array.from(new Set([...state.ticketList, ...ticketList]));
  state.ticketListTotal = ticketListTotal;
  state.ticketDataAjaxStatus = "fulfilled";
};

function fetchInnerTicketMessagesFulfilled(
  state: InnerTicketType,
  { payload }: any,
) {
  if (state.active.ticketId == payload.ticketInfo.ticket_id) {
    state.activeTicketInfo = payload.ticketInfo;
    innerTicketReducers.setInnerTicketStatus(state, {
      payload: {
        status: {
          id: payload.ticketInfo.ticket_status.ticketStatusId,
          statusName: payload.ticketInfo.ticket_status.ticketStatusName,
        },
        ticketId: payload.ticketInfo.ticket_id,
      },
    });
    state.messageDataAjaxStatus = "fulfilled";
    state.messageData = { ...state.messageData, ...payload.messageData };
    state.messageList = [...state.messageList, ...payload.messageList];
    state.messageMetaData = payload.metaData;
    state.hasMoreMessage = payload.hasMoreMessage;
    state.channelList = payload.channelList;
  }

  // if the ticket is in ticket sidebar
  if (state.ticketData[payload.ticketInfo.ticket_id]) {
    state.ticketData[payload.ticketInfo.ticket_id].ticket_subject =
      payload.ticketInfo.ticket_subject;
  }
}

function sendMessagesFulfilled(state: InnerTicketType, { payload }: any) {
  state.sendMessageAjaxStatus = "fulfilled";
  const uuid = payload.message.messageUuid + "";
  const index = state.messageList.indexOf(uuid);
  // console.log(index, uuid, payload);
  if (index !== -1) {
    // Replace the UUID with messageId
    state.messageList[index] = payload.message.messageId;
    delete state.messageData[uuid];
    state.messageData[payload.message.messageId] = payload.message;
  }
  // state.messageData[payload.message.messageId] = payload.message;
  // console.log(payload.message);
  // state.messageList.unshift(payload.message.messageId);
  let statusId = 0;
  for (const x in state.ticketStatusList) {
    if (state.ticketStatusList[x].statusName === payload.status) {
      statusId = state.ticketStatusList[x].id;
      break;
    }
  }
  innerTicketReducers.setInnerTicketStatus(state, {
    payload: {
      status: { id: statusId, statusName: payload.status },
      ticketId: payload.ticketId,
    },
  });

  let messageType = payload.message.messageType;

  if (messageType == "Message") {
    //  message sent
    pushTheToast({
      type: "success",
      text: "Message Sent!",
      position: "top-right",
    });
  }

  if (messageType == "Note") {
    //  internal note added
    pushTheToast({
      type: "success",
      text: "Internal Note Added!",
      position: "top-right",
    });
  }
}

// fulfilled case for fetching all mergable tickets
function fetchAllMergableTicketsFulfilled(
  state: InnerTicketType,
  { payload }: any,
) {
  const {
    // customerId,
    ticketData,
    ticketList,
    ticketListTotal,
  }: any = payload as any;
  // console.log("fetchAllMergableTicketsFulfilled::: " + JSON.stringify(payload));
  state.ticketMerge.ticketData = {
    ...state.ticketMerge.ticketData,
    ...ticketData,
  };
  //to prevent duplicate ticketids using Set class
  state.ticketMerge.ticketList = Array.from(
    new Set([...state.ticketMerge.ticketList, ...ticketList]),
  );
  state.ticketMerge.ticketListTotal = ticketListTotal;
  state.ticketMerge.mergableTicketFetchAjaxStatus = "fulfilled";
}

function editDraftMsgFulfilled(state: InnerTicketType, { payload }: any) {
  state.messageData[payload.messageId] = payload;
}

function fetchMessagesUpdatesFulfilled(
  state: InnerTicketType,
  { payload }: any,
) {
  if (state.active.ticketId == payload.ticketInfo.ticket_id) {
    state.activeTicketInfo = payload.ticketInfo;
    innerTicketReducers.setInnerTicketStatus(state, {
      payload: {
        status: {
          id: payload.ticketInfo.ticket_status.ticketStatusId,
          statusName: payload.ticketInfo.ticket_status.ticketStatusName,
        },
        ticketId: payload.ticketInfo.ticket_id,
      },
    });
    payload.messageList.forEach((msgId: any) => {
      if (
        !state.messageList.includes(msgId) ||
        !state.messageList.includes(
          payload.messageData[msgId]?.messageUuid + "",
        )
      ) {
        state.messageList.unshift(msgId);
      }

      const index = state.messageList.indexOf(
        payload.messageData[msgId]?.messageUuid + "",
      );
      if (index !== -1) {
        // Replace the UUID with messageId
        state.messageList[index] = msgId;
        delete state.messageData[payload.messageData[msgId]?.messageUuid + ""];
      }
      state.messageData[msgId] = payload.messageData[msgId];
    });
    state.messageMetaData = payload.metaData;
  }
  // if the ticket is in ticket sidebar
  if (state.ticketData[payload.ticketInfo.ticket_id]) {
    state.ticketData[payload.ticketInfo.ticket_id].ticket_subject =
      payload.ticketInfo.ticket_subject;
  }
}

function fetchAllTicketsIdListFulfilled(
  state: InnerTicketType,
  { payload }: any,
) {
  state.allTicketsNav.allTicketsIdList = payload;
  state.allTicketsNav.allTicketsIdAjaxStatus = "fulfilled";
}

function searchCustomersFulfilled(state: InnerTicketType, { payload }: any) {
  state.searchCustomersData.customersData = {
    ...state.searchCustomersData.customersData,
    ...payload.customersData,
  };
  state.searchCustomersData.customersIdList = [
    ...state.searchCustomersData.customersIdList,
    ...payload.customersIdList,
  ];
  state.searchCustomersData.metaData = {
    count: payload.metaData.count,
    totalCount: payload.metaData.totalCount,
  };

  state.searchCustomersData.searchCustomersAjaxStatus = "fulfilled";
}

function fetchMessageByIdsFulfilled(state: InnerTicketType, { payload }: any) {
  state.messageData = { ...state.messageData, ...payload };
  return state;
}

function fetchSnoozeDataFulfilled(state: InnerTicketType, { payload }: any) {
  if (state.active.ticketId == payload.ticketId) {
    state.activeSnooze.snoozeData = {
      id: payload.id,
      ticketId: payload.ticketId,
      tillCustomerReplies: payload.tillCustomerReplies,
      expireAt: payload.expireAt,
      expireAtInString: payload.expireAtInString,
      snoozedAt: payload.snoozedAt,
      expireAtGmt: payload.expireAtGmt,
    };
    state.activeSnooze.snoozeDataAjaxStatus = "fulfilled";
  }

  return state;
}

const fetchInnerTicketUpdatesFulfilled = (
  state: InnerTicketType,
  { payload }: any,
) => {
  const {
    ticketData,
    ticketList,
    ticketListTotal,
    customerId,
    canAddNew,
  }: any = payload as any;

  if (
    state.filters.customerId == customerId &&
    state.filters.ticketStatus == payload.ticketStatus
  ) {
    ticketList.forEach((ticketId: any) => {
      const parsedTicketId = parseInt(ticketId + "");
      if (canAddNew === true) {
        if (!state.ticketList.includes(parsedTicketId)) {
          state.ticketList.unshift(parsedTicketId);
        }
      }
      state.ticketData[parsedTicketId] = ticketData[parsedTicketId];
    });
    if (canAddNew === true) {
      state.ticketListTotal = ticketListTotal;
    }
  } else {
    // console.log("response ignored", ticketList);
  }
};

export default {
  fetchInnerTicketsFulfilled,
  fetchAllInnerTicketsFulfilled,
  fetchInnerTicketMessagesFulfilled,
  sendMessagesFulfilled,
  fetchAllMergableTicketsFulfilled,
  editDraftMsgFulfilled,
  fetchMessagesUpdatesFulfilled,
  fetchAllTicketsIdListFulfilled,
  searchCustomersFulfilled,
  fetchMessageByIdsFulfilled,
  fetchSnoozeDataFulfilled,
  fetchInnerTicketUpdatesFulfilled,
};
