import { useEffect, useMemo, useState } from "react";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { HomePageData, getHomePageData } from "src/services/Home";
import getTodoListService, {
  SetupDetails,
} from "src/services/Home/getTodoList.service";
import { useAppSelector } from "src/store/store";

/**
 * Custom hook to Manage home page data fetching and states
 */
const useHomePage = () => {
  // States for managing Home page
  const [homePageData, setHomePageData] = useState(null as null | HomePageData);
  const [setupData, setSetupData] = useState<null | SetupDetails>(null);
  const [loading, setLoading] = useState(true);

  // Current user data from global slice in redux store
  const currentUserData = useAppSelector(
    (state) => state.globals.currentUserData,
  );

  // Memoized boolean to determine whether to show the social media section based on request counts
  const showSocialMediaBlock = useMemo(() => {
    // Checking if Instagram or Messenger request counts are defined in the home page data
    if (
      homePageData?.socialMediaRequestCount?.instagram !== undefined ||
      homePageData?.socialMediaRequestCount?.messenger !== undefined
    ) {
      // If either Instagram or Messenger request count is defined, return true to show the section
      return true;
    }
    // If neither Instagram nor Messenger request count is defined, return false   to hide the section
    return false;
  }, [
    // Dependency array to track changes in Instagram and Messenger request counts
    homePageData?.socialMediaRequestCount?.instagram, // Dependency for Instagram request count
    homePageData?.socialMediaRequestCount?.messenger, // Dependency for Messenger request count
  ]);

  /**
   * Effect for initializing home page data
   */
  useEffect(() => {
    /**
     * Asynchronous function for initializing home page data
     */
    const fetchHomePageData = async () => {
      try {
        // Fetch data
        const res = await getHomePageData();

        // Set the home page state
        setHomePageData(res);
      } catch (err) {
        // If we have an error and we are not on login page
        if (!window.location.pathname.includes("login")) {
          // Show warning toast
          pushTheToast({
            type: "danger",
            text: "Something went wrong!",
            position: "top-right",
          });
        }
      }
    };

    /**
     * Asynchronous function for initializing first login to-do list setup data
     */
    const fetchToDoListSetupData = async () => {
      try {
        // Fetch data
        const res = await getTodoListService();

        // Set the Setup data for first login to-do list
        setSetupData(res);
      } catch (err) {
        console.error(err);
      }
    };

    // Initialize Loader
    setLoading(true);

    // Fetch all data
    Promise.all([fetchHomePageData(), fetchToDoListSetupData()])
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  }, []);

  return {
    homePageData,
    currentUserData,
    setupData,
    loading,
    showSocialMediaBlock,
  };
};

export default useHomePage;
