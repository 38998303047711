/* eslint-disable no-throw-literal */
import { axiosJSON } from "src/globals/axiosEndPoints";
import { Message } from "./getMessages";

export type LiveMsgType = "message" | "note" | "forward";

export interface SendLiveMessagePayload {
  chat_id: string | number;
  message: string;
  messageType: LiveMsgType;
  attachmentBatchNumber?: Array<string>; // for saving the batch id of attachment
  uuid: string; //unique id for message, used for try again feature in send message
  channel?: string;
  to?: string;
}

export async function sendLiveMessages(payload: SendLiveMessagePayload) {
  if (payload.attachmentBatchNumber?.length === 0) {
    delete payload.attachmentBatchNumber;
  }

  const { data } = await axiosJSON.post(
    `/api/liveChat/agent/sendMessage`,
    payload,
  );
  // Check if the 'err' property in the response data is true
  if (data.err === true) {
    // Check specific cases for errors
    if (data.statusCode) {
      if (data.statusCode === "attachmentSizeLimitExceeded") {
        // If the attachment size limit is exceeded, throw a specific error
        throw {
          statusCode: "attachmentSizeLimitExceeded",
          message: "Attachment file size exceeded",
        };
      } else if (data.statusCode === "integrationFailure") {
        throw {
          statusCode: "integrationFailure",
          message:
            data.msg ??
            data.message ??
            "Failed to send message. Integration is not complete",
          failureMetaData: data.failureMetaData,
        };
      } else if (data.statusCode) {
        throw {
          statusCode: data.statusCode,
          message: data.msg ?? data.message,
        };
      }
    } else {
      // Otherwise, throw an error with the message from 'data.msg'
      throw new Error(data.msg as string);
    }
  }
  return normalizeMessage(data.data, payload);
}

function normalizeMessage(data: any, payload: SendLiveMessagePayload) {
  return {
    messageId: data.messageId,
    messageText: data.message,
    sentBy: data.sentBy,
    sendTime: data.messageCreatedAt,
    sendTimeGmt: data.messageCreatedAtGmt,
    isEvent: false,
    sentByType: data.sentByType,
    senderType: data.senderType,
    isInternalNote: payload.messageType == "note",
    messageType: payload.messageType,
    messageUuid: payload.uuid,
    messageStatus: "fulfilled",
    attachments: data.attachments,
    to: data.to,
    from: data.from,
    messageChannel: data.messageChannel,
  } as Message;
}
