import { useAppSelector } from "src/store/store";
import ReportTicketBody from "../../../ReportTicketBody/ReportTicketBody";

const TotalChatReports = () => {
  const ui_visibility = useAppSelector(
    (state) => state.globals.currentUserData?.ui_visibility,
  );

  return (
    <div className="mb-2">
      {ui_visibility?.includes("liveChat_totalChats_totalChatReports") && (
        <ReportTicketBody
          endPoint={"liveChat/totalChats/totalChatReports"}
          title={"Total chats"}
          // tooltip={"Unresolved = Pending or Open Tickets"}
          tabProps={{
            showComparison: false,
            showDailyPart: true,
            showListView: true,
          }}
        />
      )}
      {ui_visibility?.includes(
        "liveChat_totalChats_totalChatHeatmapReports",
      ) && (
        <ReportTicketBody
          endPoint={"liveChat/totalChats/totalChatHeatmapReports"}
          title={"Total chats heatmap"}
          // tooltip={"Unresolved = Pending or Open Tickets"}
          tabProps={{
            showComparison: false,
            showDailyPart: false,
            showListView: false,
            showTabs: false,
          }}
        />
      )}
    </div>
  );
};

export default TotalChatReports;
