/**
 * This is the component file.
 * It contains the ShowAgents component function and a useShowAgents custom hook to manage it.
 *
 * @author Yash Aditya
 */

import { OverlayTrigger, Tooltip } from "react-bootstrap";
import UserAvatar from "src/components/UserAvatar";
import styles from "../../EditNotification.module.scss";
import { useMemo } from "react";
import { NotifyUsers } from "../../../../hooks/notifications/useNotifications";
import { useQuery } from "@tanstack/react-query";
import getNotificationSettingsDataService from "src/services/report/getNotificationSettingsData.service";
import AxiosImg from "src/components/AxiosImg";

// Custom hook for handling the "Sent To Agents" functionality
const useShowAgents = () => {
  // Fetching notification settings data
  const { data: notificationSettingsData, status } = useQuery(
    ["reportNotification/getNotificationSettingsDataService"],
    {
      queryFn: getNotificationSettingsDataService,
      // Jul 26 Making these as 0
      // because it is not refetching when the data updated on agent notifications
      staleTime: 0,
      cacheTime: 0,
    },
  );

  // Computing the sentTo ids with the all user data.
  const usersData = useMemo(() => {
    const users: {
      [userId: string]: NotifyUsers;
    } = {};

    const userIds: Array<string> = [];

    notificationSettingsData?.sendTo?.forEach((user) => {
      users[user.id] = user;
      userIds.push(user.id);
    });
    return { users, userIds };
  }, [notificationSettingsData]);

  return { status, usersData };
};

/**
 * This component is used to show the agents who are assigned to get the notifications.
 */
export const ShowAgents = () => {
  const { status, usersData } = useShowAgents();
  const userIdsToShow = usersData?.userIds?.slice(0, 3) ?? [];
  const hasMoreUsers = (usersData?.userIds?.length ?? 0) > 3;
  const avatarStyle = {
    width: "25px",
    height: "25px",
    borderRadius: "100%",
  };
  return (
    <div className={`text-truncate ${styles.contentWrapper}`}>
      <span className={`pe-1 ${styles.userSvg}`}>
        {/* User icon */}
        <i className="fa-solid fa-user"></i>
      </span>
      <span className={`pe-1 ${styles.sentMsg}`}>Sent to</span>

      {/* User avatars with tooltips */}
      {userIdsToShow.map((id) => {
        const user = usersData.users[id];
        const img = user?.imageUrl?.trim() ? (
          <AxiosImg
            url={user.imageUrl}
            style={avatarStyle}
          />
        ) : (
          <UserAvatar
            name={user.name ?? user.email}
            size={100}
            style={avatarStyle}
          />
        );

        return (
          <OverlayTrigger
            key={id}
            placement="top"
            overlay={
              <Tooltip className={`${styles.toolTipCustom}`}>
                <span className="mx-1">{img}</span>
                <span className="mx-1">{user.name ?? user.email}</span>
              </Tooltip>
            }
          >
            {({ ref, ...triggerHandler }) => (
              <span
                className="pe-2"
                ref={ref}
                {...triggerHandler}
              >
                {img}
              </span>
            )}
          </OverlayTrigger>
        );
      })}

      {/* Show ellipsis if there are more than 3 users */}
      {hasMoreUsers && <span className="ms-2 pe-2">...</span>}
    </div>
  );
};
