import { useCallback, useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import emailSetupImg from "src/assets/images/emailSetup.png";
import liveChatSetupImg from "src/assets/images/saufterLivechatSetup.png";

import TimelineUi from "src/routes/Home/ToDoList/ListStep/Children/TimelineUi/TimelineUi";
import EmailToDo from "./Children/EmailToDo/EmailToDo";
import LiveChatToDo from "./Children/LiveChatToDo/LiveChatToDo";

import styles from "./ListStep.module.scss";
import { useAppDispatch } from "src/store/store";
import { createNewWebIntegration } from "src/store/slices/liveChatSetting/websiteSettings/websiteSettings.slice";
import useToDoList from "../hooks/useToDoList";

/**
 * Setup step details Data object
 */
const cardData = {
  emailSetup: {
    heading: "Complete E-mail forwarding setup",
    subHeading:
      "You can connect your email to saufter.io by setting up email forwarding. Follow the steps below to get started.",
    img: emailSetupImg,
  },
  liveChatSetup: {
    heading: "Setup Saufter.io Livechat",
    subHeading:
      "To see LiveChat on your website, you'll need to follow the instructions mentioned below:",
    img: liveChatSetupImg,
  },
};

export interface IListStep {
  number: number;
  title: string;
  done: boolean;
}

interface Props {
  id: "emailSetup" | "liveChatSetup";
  steps: IListStep[];
  activeStep: number;
  integrationId?: string;
  botProfileId?: string;
}

/**
 * Component for showing setup step in to-do list
 */
const ListStep = ({
  activeStep,
  id,
  steps,
  integrationId,
  botProfileId,
}: Props) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { initializeLiveChatSetup } = useToDoList();

  // Handlers for configure to-do steps modal
  const hideModalHandler = useCallback(() => {
    setShowModal(false);
  }, []);

  const showModalHandler = useCallback(() => {
    // Check its last step
    if (activeStep === 5) {
      // Check if we have bot profile id
      if (botProfileId) {
        // Open Bot Profile page
        navigate(`/bot/botProfiles/${botProfileId}/settings`);
      } else {
        // Open Bot Settings page
        navigate(`/bot`);
      }
      return;
    }

    // If we have live chat setup and integration id is not there
    if (id === "liveChatSetup" && !integrationId) {
      // Create new integration
      dispatch(createNewWebIntegration()).then(({ payload }) => {
        const res = payload as {
          integration_id: string | number;
        };
        // Update live chat setup with new integration id
        initializeLiveChatSetup(res.integration_id + "");
        // Show the modal
        setShowModal(true);
      });
      return;
    }

    setShowModal(true);
  }, [
    activeStep,
    botProfileId,
    dispatch,
    id,
    initializeLiveChatSetup,
    integrationId,
    navigate,
  ]);

  return (
    <div
      className={`mb-4 ${styles.emailSetup}`}
      id={id}
    >
      {/* Step Info */}
      <h2 className="mb-1 saufter_h3"> {cardData[id].heading} </h2>
      <p className="saufter_subHeading">{cardData[id].subHeading}</p>
      <div className="d-flex flex-column flex-md-row justify-content-between">
        {/* Steps */}
        <div className="mt-3">
          <div>
            {steps.map((step, index) => (
              <TimelineUi
                key={`${id}_${index}`}
                stepNumber={step.number}
                title={step.title}
                done={step.done}
                active={activeStep === step.number}
                showModal={showModalHandler}
                configureId={`${id}_${index}`}
                type={id}
                finalStepNumber={steps.length}
              />
            ))}
          </div>
        </div>

        {/* Step Image */}
        <img
          src={cardData[id].img}
          alt=""
          className={`${styles.emailImg}`}
        />
      </div>

      {/* Configure Modal */}
      <Modal
        backdropClassName={`${styles.modalBack}`}
        show={showModal}
        onHide={hideModalHandler}
        dialogClassName={`${styles.modalDialog}`}
        contentClassName={`${styles.modalContent}`}
        centered
      >
        {id === "emailSetup" ? (
          <EmailToDo
            hideModal={hideModalHandler}
            stepNumber={integrationId ? activeStep : 0}
            integrationId={integrationId}
          />
        ) : (
          <LiveChatToDo
            hideModal={hideModalHandler}
            stepNumber={activeStep}
            integrationId={integrationId}
          />
        )}
      </Modal>
    </div>
  );
};

export default ListStep;
