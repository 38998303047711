/**
 * This file defines a custom hook responsible for adding and update customQA info.
 *
 * @author @Anubhav-busibud
 */
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { SetStateAction, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { createCustomQa } from "src/services/Bot/CustomQA/createCustomQa.service";
import { QuestionAns } from "src/services/Bot/CustomQA/getAllQuestionAnswer.service";
import { GetByIdRes, getById } from "src/services/Bot/CustomQA/getById.service";
import { updateCustomQA } from "src/services/Bot/CustomQA/updateCustomQA.service";
import { v4 as uuidv4 } from "uuid";

function useNewAnswer() {
  const [showQue, setShowQue] = useState(true);
  const [addCustomQue, setAddCustomQue] = useState(true);
  const [showModal, setModal] = useState(false);
  const { profileId, subTabSection } = useParams();
  const [customQAId, setCustomQAId] = useState<string | number>("newAnswer");

  // Function to show the modal
  const onShow = useCallback(() => {
    setModal(true);
  }, []);

  // Function to hide the modal
  const onHide = useCallback(() => {
    setModal(false);
  }, []);

  // Query to fetch data by ID
  const {
    data: getData,
    isLoading: getDataisLoading,
    isError: getDataHasError,
  } = useQuery({
    queryKey: ["getQAbyId", customQAId + ""],
    queryFn: () =>
      getById({
        botProfileId: profileId || "",
        customQAId: Number(customQAId),
      }),
    enabled: customQAId && customQAId !== "newAnswer" ? true : false,
  });

  // Effect to set customQAId when subTabSection changes
  useEffect(() => {
    if (subTabSection) {
      setCustomQAId(subTabSection);
    }
  }, [subTabSection]);

  // State for questions and answers
  const [questions, setQuestions] = useState([
    {
      question: "",
      id: uuidv4(),
      isEmpty: true,
    },
  ]);
  const [answers, setAnswers] = useState("");

  // Effect to populate questions and answers when data is fetched
  useEffect(() => {
    if (getData && getData.questions && getData.answer) {
      setQuestions(
        getData?.questions.map((question) => {
          return {
            question: question,
            id: uuidv4(),
            isEmpty: false,
          };
        }),
      );
      setAnswers(getData?.answer);
    }
  }, [getData]);

  // Function to add a new question
  const addQuestion = useCallback(() => {
    setShowValidation((prevValidation) => ({
      ...prevValidation,
      question: false,
    }));
    setQuestions((prevQuestions) => [
      ...prevQuestions,
      {
        question: "",
        id: uuidv4(),
        isEmpty: true,
      },
    ]);
  }, []);

  // Mutation to create a new question
  const createQuestion = useMutation({
    mutationKey: ["customQA"],
    mutationFn: createCustomQa,
  });

  // State for validation errors
  const [showValidation, setShowValidation] = useState({
    question: false,
    answer: false,
  });

  // Loading states for publish and draft
  const [publishLoader, setPublishLoader] = useState(false);
  const [draftLoader, setDraftLoader] = useState(false);

  // Query client for cache management
  const queryClient = useQueryClient();

  const navigate = useNavigate();

  // Mutation to update QnA data
  const updateQnA = useMutation({
    mutationFn: updateCustomQA,
    onSuccess(data, variables, context) {
      // navigate(`/bot/botProfiles/${profileId}/answerSource/customQa`);
    },
  });

  // Function to handle question change
  const handleQuestionChange = useCallback(
    (e: { target: { value: string } }, index: number) => {
      setQuestions((prevQuestions) => {
        const newQuestions = [...prevQuestions];
        newQuestions[index].question = e.target.value;
        newQuestions[index].isEmpty = e.target.value.trim() === "";
        return newQuestions;
      });
    },
    [],
  );

  // Function to handle question deletion
  const handleDeleteQuestion = useCallback((index: number) => {
    setQuestions((prevQuestions) => {
      const newQuestions = [...prevQuestions];
      newQuestions.splice(index, 1);
      return newQuestions;
    });
  }, []);

  // Function to handle answer change
  const handleAnswerChange = useCallback((e: { target: { value: string } }) => {
    setShowValidation((prevValidation) => ({
      ...prevValidation,
      answer: !e.target.value?.trim() ? true : false,
    }));
    setAnswers(e.target.value);
  }, []);

  // Function to update custom QA data in the query cache
  const updateCustomQAData = useCallback(
    (id: number | string, values: Partial<QuestionAns>) => {
      queryClient.setQueryData<GetByIdRes>(
        ["getQAbyId", id + ""],
        (queryData) => {
          if (queryData?.customQAId == id) {
            return {
              ...queryData,
              ...values,
            };
          } else if (Object.values(values).length > 3) {
            return {
              customQAId: parseInt(id + ""),
              ...queryData,
              ...values,
            } as GetByIdRes;
          }
          return queryData;
        },
      );
    },
    [],
  );

  const validatePayload = useCallback(
    // This function validates an answer and an array of questions.
    (answer: string, questionArr: Array<any>) => {
      // Check if there are questions in the array.
      if (questionArr.length > 0) {
        // Check if there are any questions with empty or only whitespace characters.
        if (
          questionArr.filter((question) => !question.question.trim()).length
        ) {
          // If there are empty questions, set the 'question' validation flag to true.
          setShowValidation((prevValidation) => ({
            ...prevValidation,
            question: true,
          }));
          // Return false to indicate validation failure.
          return false;
        } else {
          // If all questions are valid, set the 'question' validation flag to false.
          setShowValidation((prevValidation) => ({
            ...prevValidation,
            question: false,
          }));
        }
      }
      // Check if the answer is empty or only contains whitespace characters.
      if (answer.trim() === "") {
        // If the answer is empty, set the 'answer' validation flag to true.
        setShowValidation((prevValidation) => ({
          ...prevValidation,
          answer: true,
        }));
        // Return false to indicate validation failure.
        return false;
      }
      // If both questions and answers are valid, return true to indicate successful validation.
      return true;
    },
    [],
  );

  const handlePublishUnpublishClick = useCallback(() => {
    // validates an answer and an array of questions.
    if (!validatePayload(answers, questions)) {
      return;
    }

    setPublishLoader(true);
    if (getData === undefined) {
      createQuestion.mutate(
        {
          questions: questions.map((question) => question.question),
          answer: answers,
          botProfileId: profileId as string,
          status: "public",
        },
        {
          onSuccess(data, variables, context) {
            setPublishLoader(false);
            updateCustomQAData(data.customQAId, {
              customQAId: parseInt(data.customQAId + ""),
              numberOfTimesAppeared: 0,
              question: variables.questions as any,
              status: variables.status,
            });
            setCustomQAId(data.customQAId);
            onShow();
          },
          onError(error, variables, context) {
            setPublishLoader(false);
            pushTheToast({
              position: "top-right",
              text: "Failed to save",
              type: "danger",
            });
          },
        },
      );
    } else {
      updateQnA.mutate(
        {
          botProfileId: profileId || "",
          customQAId: getData.customQAId,
          status: getData.status === "draft" ? "public" : "draft",
          questions: questions.map((question) => question.question),
          answer: answers,
        },
        {
          onSuccess(data, variables, context) {
            setPublishLoader(false);
            // if (getData.status === "draft") {
            onShow();
            // }
          },
          onError(error, variables, context) {
            setPublishLoader(false);
            pushTheToast({
              position: "top-right",
              text: "Failed to update",
              type: "danger",
            });
          },
        },
      );
    }
  }, [questions, answers, getData, profileId, createQuestion, updateQnA]);

  const handleSaveAsDraftButton = useCallback(() => {
    // validates an answer and an array of questions.
    if (!validatePayload(answers, questions)) {
      return;
    }
    setDraftLoader(true);
    if (getData === undefined) {
      createQuestion.mutate(
        {
          questions: questions.map((question) => question.question),
          answer: answers,
          botProfileId: profileId as string,
          status: "draft",
        },
        {
          onSuccess: (data, variables, context) => {
            setDraftLoader(false);
            updateCustomQAData(data.customQAId, {
              customQAId: parseInt(data.customQAId + ""),
              numberOfTimesAppeared: 0,
              question: variables.questions as any,
              status: variables.status,
            });
            setCustomQAId(data.customQAId);
            pushTheToast({
              position: "top-right",
              text: "Saved as draft",
              type: "success",
            });
          },
          onError: () => {
            setDraftLoader(false);
            pushTheToast({
              position: "top-right",
              text: "Failed to save",
              type: "danger",
            });
          },
        },
      );
    } else {
      updateQnA.mutate(
        {
          botProfileId: profileId || "",
          customQAId: getData.customQAId,
          status: "draft",
          questions: questions.map((question) => question.question),
          answer: answers,
        },
        {
          onSuccess(data, variables, context) {
            setDraftLoader(false);
            pushTheToast({
              position: "top-right",
              text: "Updated successfully",
              type: "success",
            });
          },
          onError(error, variables, context) {
            setDraftLoader(false);
            pushTheToast({
              position: "top-right",
              text: "Failed to save",
              type: "danger",
            });
          },
        },
      );
    }
  }, [questions, answers, getData, profileId, createQuestion, updateQnA]);

  return {
    showQue,
    setShowQue,
    addCustomQue,
    setAddCustomQue,
    showModal,
    onShow,
    onHide,
    questions,
    setQuestions,
    answers,
    setAnswers,
    addQuestion,
    createQuestion,
    showValidation,
    setShowValidation,
    publishLoader,
    setPublishLoader,
    draftLoader,
    setDraftLoader,
    customQAId,
    setCustomQAId,
    queryClient,
    updateQnA,
    getData,
    getDataisLoading,
    getDataHasError,
    profileId,
    subTabSection,
    handleQuestionChange,
    handleDeleteQuestion,
    handleAnswerChange,
    handlePublishUnpublishClick,
    handleSaveAsDraftButton,
    updateCustomQAData,
  };
}

export default useNewAnswer;
