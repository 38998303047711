import { axiosJSON } from "src/globals/axiosEndPoints";
import log from "loglevel";

export async function fetchAllUsers(
  payload: { start: number; limit: number },
  getOnlyActiveUsers = false
) {
  const { data } = await axiosJSON.post(`/api/ticket/getUsers`, payload);
  if (data.err === true) {
    throw new Error(data.msg as string);
  }
  const normalizedData = normalizeData(data.data, getOnlyActiveUsers);

  return normalizedData;
}

// convert incoming data to the required format
function normalizeData(response: any, getOnlyActiveUsers = false): any {
  if (getOnlyActiveUsers) {
    response = response.filter((data: any) => data.status === "active");
  }
  return response;
}
