import { axiosJSON } from "src/globals/axiosEndPoints";

export interface RemoveCustomerProfile {
  customerId: string;
}

export interface RemoveCustomerProfileParams {
  customerId: string;
}

export async function removeCustomerProfile(
  params: RemoveCustomerProfileParams
) {
  const { data }: any = await axiosJSON.delete(
    `/api/customer/deleteProfileImage`,
    { params }
  );

  if (data.errors) {
    throw new Error(data.errors.join(""));
  }

  if (data.error === true) {
    throw new Error(data.message as string);
  }

  return data.data as RemoveCustomerProfile;
}
