/**
 * This file is the React component file.
 *
 * @author Yash Aditya
 */

// Importing styles from the SCSS module
import styles from "./NotificationTable.module.scss";

// Importing necessary dependencies and components
import {
  ReportNotification,
  reportNotificationsActions,
  useNotifications,
} from "../../../../hooks/notifications/useNotifications";
import SendingFormat from "../SendingFormat/SendingFormat";
import { getTimeFromNow } from "src/utils/dateLibrary";
import { useCallback, useMemo, useState } from "react";
import { Spinner } from "react-bootstrap";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import deleteNotificationService from "src/services/report/deleteNotification.service";
import updateNotificationService from "src/services/report/updateNotification.service";
import NotificationModal from "../../../NotificationModal/NotificationModal";
import useNotifyPermission from "../../../../hooks/notifications/useNotifyPermission";

// Defining the Props interface
interface Props {
  notificationData: ReportNotification;
}

// Custom hook to handle actions related to the NotificationTable
const useNotificationTable = ({ notificationData }: Props) => {
  // Getting notifications context and dispatch function from the custom hook
  const { reportNotifications, dispatch } = useNotifications();

  // State to manage loading state for delete and toggle actions
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [toggleLoading, setToggleLoading] = useState(false);

  // State to manage opening and closing of preview modal
  const [showModal, setShowModal] = useState(false);

  // Callback to show the edit notification view
  const showEditNotification = useCallback(() => {
    dispatch([reportNotificationsActions.setActiveView, "edit"]);
  }, [dispatch]);

  // Callback to delete a notification
  const deleteNotification = useCallback(() => {
    if (!deleteLoading) {
      setDeleteLoading(true);
      const params = {
        id: notificationData.id,
      };
      if (reportNotifications.context) {
        // Need to add count as dispatch might be executed 2 times ib strict mode so doing decreament in dispatch will misbehave
        const count =
          (reportNotifications?.notificationsData?.metaData?.totalCount ?? 1) -
          1;
        deleteNotificationService({
          context: reportNotifications.context,
          ...params,
        })
          .then((res) => {
            dispatch([
              reportNotificationsActions.deleteNotificationData,
              {
                notificationId: params.id,
                count: count < 1 ? 0 : count,
              },
            ]);
          })
          .catch((err) => {
            // Displaying an error toast if deleting the notification fails
            pushTheToast({
              type: "danger",
              position: "top-right",
              text: "Error deleting notification",
            });
          })
          .finally(() => {
            setDeleteLoading(false);
          });
      }
    }
  }, [
    notificationData,
    reportNotifications.context,
    deleteLoading,
    setDeleteLoading,
  ]);

  // Callback to toggle notification enable/disable state
  const toggleNotification = useCallback(() => {
    setToggleLoading(true);
    const params = {
      id: notificationData.id,
      notificationEnabled: !notificationData.notificationEnabled,
    };
    if (reportNotifications.context) {
      updateNotificationService({
        context: reportNotifications.context,
        ...params,
      })
        .then((res) => {
          dispatch([reportNotificationsActions.changeNotificationData, params]);
        })
        .catch((err) => {
          // Displaying an error toast if toggling fails
          pushTheToast({
            type: "danger",
            position: "top-right",
            text: "Error enabling or disabling",
          });
        })
        .finally(() => {
          setToggleLoading(false);
        });
    }
  }, [notificationData, reportNotifications.context]);

  // Callback to edit a notification
  const editNotification = useCallback(() => {
    // Setting the editing notification data and showing the edit view
    dispatch([
      reportNotificationsActions.setEditingNotificationData,
      notificationData,
    ]);
    showEditNotification();
  }, [showEditNotification, notificationData]);

  // Preview Modal Handler
  const toggleModal = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal]);

  // Returning the necessary values and functions for the NotificationTable
  return {
    deleteLoading,
    toggleLoading,
    showModal,
    deleteNotification,
    toggleNotification,
    editNotification,
    toggleModal,
  };
};

// Functional component for rendering a single notification in a table
const NotificationTable = ({ notificationData }: Props) => {
  // Extracting values and functions from the custom hook
  const {
    deleteLoading,
    toggleLoading,
    showModal,
    deleteNotification,
    toggleNotification,
    editNotification,
    toggleModal,
  } = useNotificationTable({ notificationData });
  const notifyPermission = useNotifyPermission();

  // Rendering the notification table
  return (
    <div
      className={`d-flex align-items-center py-2 px-3 mb-2 ${styles.tableWrapper}`}
    >
      <div className={`${styles.ticketWrapper}`}>
        <div className={`mb-1 ${styles.ticketStatus}`}>
          {/* Displaying the report name and creation time */}
          <span>{notificationData.reportName}</span>
          <span className={`ps-1 ${styles.time}`}>
            Created{" "}
            {notificationData?.createdTimeUTC
              ? getTimeFromNow({
                  date: new Date(notificationData.createdTimeUTC),
                })
              : ""}
          </span>
        </div>
        <div className="mb-0">
          {/* Displaying the sending format and a preview link */}
          <SendingFormat notificationData={notificationData} />
          <span
            onClick={toggleModal}
            className={`ps-2 ${styles.previewText} cursor-pointer`}
          >
            Preview alert
          </span>
          {/* Preview Modal */}
          <NotificationModal
            onHide={toggleModal}
            showModal={showModal}
            notificationData={notificationData}
          />
        </div>
      </div>
      <div className={`${styles.editWrapper}`}>
        {/* Edit button with click handler */}
        {notifyPermission === "edit" && (
          <span
            onClick={editNotification}
            className="cursor-pointer"
          >
            Edit
          </span>
        )}
      </div>
      <div className={`${styles.deleteWrapper}`}>
        {/* Delete button with loading spinner during delete action */}
        {notifyPermission === "edit" && (
          <span
            onClick={deleteNotification}
            style={{ cursor: deleteLoading ? "default" : "pointer" }}
          >
            {deleteLoading ? (
              <Spinner
                animation="border"
                size="sm"
              />
            ) : (
              ""
            )}{" "}
            Delete
          </span>
        )}
      </div>
      <div
        className={`d-flex align-items-center justify-content-end ${styles.actionWrapper}`}
      >
        {/* Notify label and toggle switch for enabling/disabling notifications */}
        <span className={`${styles.notify}`}>Notify</span>
        {toggleLoading ? (
          <div className="mx-2">
            <Spinner
              animation="border"
              size="sm"
            />
          </div>
        ) : (
          ""
        )}
        <div className="form-check form-switch mx-2">
          <input
            className={`form-check-input ${styles.notCheck}`}
            style={{
              cursor: notifyPermission === "edit" ? "pointer" : "not-allowed",
              pointerEvents: "auto",
            }}
            type="checkbox"
            role="switch"
            id={`flexSwitchCheckDefault-reports-notification-${notificationData.id}`}
            checked={notificationData.notificationEnabled}
            onChange={toggleNotification}
            disabled={toggleLoading || notifyPermission !== "edit"}
          />
          <label
            className={`form-check-label ${styles.toggleText}`}
            style={{
              cursor: notifyPermission === "edit" ? "pointer" : "not-allowed",
            }}
            htmlFor={`flexSwitchCheckDefault-reports-notification-${notificationData.id}`}
          >
            {notificationData.notificationEnabled ? "On" : "Off"}
          </label>
        </div>
      </div>
    </div>
  );
};

// Exporting the NotificationTable component
export default NotificationTable;
