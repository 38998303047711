import { useState } from "react";
import { TINY_MCE_ATTACHEMENT_SUPPORTED_IMG_TYPES, TINY_MCE_ATTACHEMENT_SUPPORTED_VIDEO_TYPES, TinyMCEEditorContainerProps } from "src/interfaces/TinyMCEContainer/ITinyMCEContainer";
import styles from "./CustomMediaPicker.module.scss";

interface Props{
    selectFileFromLocal: Function
    allowedEmbbedTypes: TinyMCEEditorContainerProps["allowedEmbbedTypes"]
}
const CustomMediaPicker = ({ selectFileFromLocal, allowedEmbbedTypes }:Props) => {

  const [show, setShow]= useState(true);

  const handleOnClick = (type: "image" | "video") => {

    if(type === "image"){
      const SUPPORTED_TYPES = TINY_MCE_ATTACHEMENT_SUPPORTED_IMG_TYPES.slice(); // Create a copy of the original supported types array

        if(allowedEmbbedTypes?.includes("gif")){
          SUPPORTED_TYPES.push("gif");
        }
        selectFileFromLocal(SUPPORTED_TYPES, true);
        // setShow(false);
    }else{
        selectFileFromLocal(TINY_MCE_ATTACHEMENT_SUPPORTED_VIDEO_TYPES, true);
        // setShow(false);
    }
  }

  return (
    <>
    <div className={`${styles.leftContent} ${styles.fitBox}`}>
      <div className={`mt-3 d-flex`}>
        <div className={`me-2 ${styles.addBox}`} onClick={()=>setShow(!show) }> + </div>
        {
          show && 
          <>
          <div className={`me-2 ${styles.addBox}`} onClick={(e)=>handleOnClick("image")}>
          <span>
            <i className="fa-solid fa-image"></i>
          </span>
        </div>
        <div className={`${styles.addBox}`} onClick={(e)=>handleOnClick("video")}>
          <span>
          <i className="fa-solid fa-video"></i>
          </span>
        </div>
        </>
        }
       
      </div>
    </div>
    </>
  );
};
export default CustomMediaPicker;
