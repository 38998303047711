/**
 * This is the component file.
 * It contains the ShowFreqData component function and a useShowFreqData custom hook to manage it.
 *
 * @author Yash Aditya
 */

import styles from "../NotFrequency/NotFrequency.module.scss";
import { useQuery } from "@tanstack/react-query";
import getNotificationSettingsDataService from "src/services/report/getNotificationSettingsData.service";
import { useMemo } from "react";

// This is used to show the threshold that how many times the notification will be sent.
const getStringForNumbers = (len: number) => {
  if (len === 0) {
    return "None";
  } else if (len === 1) {
    return "Once";
  } else if (len === 2) {
    return "Twice";
  } else if (len === 3) {
    return "Thrice";
  } else {
    return len + " Times";
  }
};

// Custom hook for handling the ShowFreqData component
export const useShowFreqData = () => {
  // Fetching notification settings data
  const { data: notificationSettingsData, status } = useQuery(
    ["reportNotification/getNotificationSettingsDataService"],
    {
      queryFn: getNotificationSettingsDataService,
      // Jul 26 Making these as 0
      // because it is not refetching when the data updated on agent notifications
      staleTime: 0,
      cacheTime: 0,
    },
  );

  /**
   * This memoized function the timeString for the frequency label modal.
   */
  const timeString = useMemo(() => {
    return notificationSettingsData?.sendingFrequency?.smallFreqLabel
      ? notificationSettingsData.sendingFrequency.smallFreqLabel
      : "Loading...";
  }, [notificationSettingsData]);

  // This will return the capitalized frequency of notification.
  const freq = useMemo(() => {
    return notificationSettingsData?.sendingFrequency?.type
      ? notificationSettingsData.sendingFrequency.type
          .slice(0, 1)
          .toUpperCase() +
          notificationSettingsData.sendingFrequency.type.slice(1)
      : "";
  }, [notificationSettingsData]);

  return { status, notificationSettingsData, timeString, freq };
};

/**
 * This component is used to show the applied frequency of the notifications.
 */
const ShowFreqData = () => {
  const { status, notificationSettingsData, freq } = useShowFreqData();

  return (
    <>
      <p
        className={`mb-1 ${styles.sendNote}`}
      >{`Send notifications: ${freq}`}</p>
      {(notificationSettingsData?.sendingFrequency?.timeValues ?? []).map(
        // Map over each time value in the array
        (timeVal, index) => {
          return (
            <div
              key={index}
              className={`mb-2`}
            >
              {/* Display the number of times for this specific time value */}
              <span
                className={`d-flex align-items-center ps-2 pe-3 mb-2 ${styles.frequency}`}
              >
                {getStringForNumbers(
                  timeVal?.times?.length ?? timeVal?.xNoOfTimes ?? 0,
                )}
              </span>
              {notificationSettingsData?.sendingFrequency?.type ===
                "weekly" && (
                <span
                  className={`d-flex align-items-center mb-2 px-2 ${styles.days}`}
                >
                  On {timeVal.weekDayLabel}
                </span>
              )}
              {notificationSettingsData?.sendingFrequency?.type ===
                "monthly" && (
                <span
                  className={`d-flex align-items-center mb-2 px-2 ${styles.days}`}
                >
                  On {timeVal.monthLabel}
                </span>
              )}
              {notificationSettingsData?.sendingFrequency?.type ===
                "yearly" && (
                <span
                  className={`d-flex align-items-center mb-2 px-2 ${styles.days}`}
                >
                  On {timeVal.date} of {timeVal.monthLabel}
                </span>
              )}
              {(timeVal?.times ?? []).map((time, index) => {
                return (
                  <div
                    className="d-flex align-items-center mb-1"
                    key={index}
                  >
                    {/* Display the time with a clock icon */}
                    <p
                      className={`mb-0 d-flex align-items-center ${styles.days} px-2`}
                    >
                      <span className="">
                        <i className="fa-regular fa-clock"></i>
                      </span>
                      <span className="px-2">{time?.time}</span>
                      <span>
                        {/* Display a chevron-down icon */}
                        <i className="fa-solid fa-chevron-down"></i>
                      </span>
                    </p>
                    {/* Display the time zone in uppercase */}
                    <span
                      className={`ms-2 px-2 d-flex align-items-center ${styles.timeZone}`}
                    >
                      {time?.timeZoneLabel}
                    </span>
                  </div>
                );
              })}
            </div>
          );
        },
      )}
    </>
  );
};

export default ShowFreqData;
