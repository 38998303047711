import { RouteNames } from "src/containers/SideBar/SideBar";

export function liveChatIdLink({
    pathName,
  }: {
    pathName: string;
  }) {
    let link = "";
    if (
      pathName.indexOf(RouteNames.liveChat) === 0 &&
      pathName.includes("/chats/")
    ) {
      link = pathName ;
    } else {
      link = `${RouteNames.liveChat}/chats`;
    }
  
    return link;
  }