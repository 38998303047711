import { SlackIntegration } from "src/services/Integrations/Slack/getAllSlackIntegrations.service";
import styles from "./SlackIntegratedBrand.module.scss";
import slack from "src/assets/images/slack.png";
import deleteSlackIntegrationService from "src/services/Integrations/Slack/deleteSlackIntegration.service";
import { useMutation } from "@tanstack/react-query";
import { Spinner } from "react-bootstrap";
import AxiosImg from "src/components/AxiosImg";
import UserAvatar from "src/components/UserAvatar";
import React, { SetStateAction } from "react";

const SlackIntegratedBrand = ({
  brand,
  setSlackIntegrationData,
}: {
  brand: SlackIntegration;
  setSlackIntegrationData: React.Dispatch<SetStateAction<any>>;
}) => {
  // api call to delete integration
  const { mutate, isLoading } = useMutation({
    mutationFn: deleteSlackIntegrationService,
    onSuccess: () => {
      setSlackIntegrationData((prevState: any) =>
        prevState.filter(
          (slack: any) => brand.integrationId !== slack.integrationId,
        ),
      );
    },
  });
  return (
    <div className={`pb-1 ${styles.brandMain}`}>
      <div className={styles.brandChannelContent}>
        <div className={styles.brandNameContainer}>
          <span
            className={`me-2 ${styles.chooseBoxHead} ${styles.lightTextColor}`}
          >
            Brand:
          </span>
          <span>
            {brand.brand?.imageUrl ? (
              <AxiosImg
                url={brand.brand.imageUrl}
                className={`${styles.profileIcon}`}
              />
            ) : (
              <UserAvatar
                name={brand.brand?.name || "NA"}
                size={27}
              />
            )}
          </span>
          <span className={`me-2 ${styles.chooseBoxHead2}`}>
            {brand.brand?.name}
          </span>
        </div>
        <div className={`ms-4 ${styles.channelNameContainer}`}>
          <span
            className={`me-2 ${styles.chooseBoxHead} ${styles.lightTextColor}`}
          >
            Channel:
          </span>
          <span>
            {brand.channel.imageUrl ? (
              <AxiosImg
                url={brand.channel.imageUrl}
                className={`${styles.profileIcon}`}
              />
            ) : (
              <UserAvatar
                name={brand.channel.name || "NA"}
                size={20}
              />
            )}
          </span>
          <span className={`me-2 ${styles.chooseBoxHead2}`}>
            {brand.channel.name}
          </span>
        </div>
      </div>
      <div className={""}>
        <div className="me-1">
          <button
            className={`${styles.deleteIntegrationBtn} px-2 d-flex justify-content-center align-items-center ms-3`}
            onClick={() => mutate({ integrationId: brand.integrationId })}
          >
            <span className="me-2">
              <i className="fa-solid fa-trash"></i>
            </span>
            <span>Delete integration</span>
            {isLoading && <Spinner size="sm" />}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SlackIntegratedBrand;
