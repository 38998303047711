import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction
} from "@reduxjs/toolkit";
import log from "loglevel";
import { RootState } from "src/store/store";
import { AjaxError } from "src/types/AjaxError"
import TicketService from "src/services/TicketService/TicketService";
import UserService from "src/services/UserService";
import { getSelectedUser } from "./ticketRemarks.selectors"
import { AddTicketRemarksPayload } from "src/services/TicketService/addTicketRemarks";
import { getBulkSelectedTicketIds, getTicketListFilterQuery } from "src/store/slices/tickets/tickets.selectors"

type TicketRemarksSlice = {
  remark: string,
  bulkAddRemarksState: "idle" | "pending" | "fulfilled" | "rejected";
};

const initialState: TicketRemarksSlice = {
  remark: "",
  bulkAddRemarksState: "idle",
};

export const bulkAddRemarks = createAsyncThunk(
  "ticket/bulkAddRemarks",
  async (payload: undefined, { getState }: { getState: Function }) => {
    try {
      const currentState: RootState = getState();
      const servicePayload: AddTicketRemarksPayload = {
        ticketIds: getBulkSelectedTicketIds(currentState),
        remark: currentState.ticketRemarks.remark,
      }
      //checking if all tickets selected in bulk action
      if(currentState.ticket.bulkActionFilters.selectedAllTickets === true){

          //deleting the ticketIds list from payload 
          delete servicePayload['ticketIds'];
  
          //setting allTickets flag to true
          servicePayload["allTickets"] = true;
  
          const ticketsQuery = getTicketListFilterQuery(currentState);
          //adding ticket filters to payload
          servicePayload["filters"] = ticketsQuery['filters'];
        }
  
      const bulkAddRemarksResponse = await TicketService.addTicketRemarks(servicePayload);
      return bulkAddRemarksResponse;
    } catch (error) {
      let ajaxError: AjaxError = {
        message: (error as Error).message
      };
      return ajaxError;
    }
  }
);

export const ticketRemarksSlice = createSlice({
  name: "ticketTags",
  initialState,
  reducers: {
    setRemark: (state, action: PayloadAction<string>) => {
      const remark = action.payload;
      state.remark = remark;
    },
    resetBulkAddRemarksState: (state) => {
      state.bulkAddRemarksState = "idle"
    },
    resetbulkAddRemarks: (state) => {
      state.remark = ""
      state.bulkAddRemarksState = "idle"
    }
  },
  extraReducers: (builder) => {
    /* 
    Reducers for bulk action for assigning tickets user
    */
    builder.addCase(bulkAddRemarks.fulfilled, (state, { payload }) => {
      state.bulkAddRemarksState = "fulfilled";
    });
    builder.addCase(bulkAddRemarks.rejected, (state) => {
      state.bulkAddRemarksState = "rejected";
    });
    builder.addCase(bulkAddRemarks.pending, (state) => {
      state.bulkAddRemarksState = "pending";
    });
  }
});

export const { setRemark, resetBulkAddRemarksState, resetbulkAddRemarks
} = ticketRemarksSlice.actions;

// export const getSelectedUserSelector = createSelector(
//   [getSelectedUser], getSelectedUser => (getSelectedUser)
// );

export default ticketRemarksSlice.reducer;
