import { axiosJSON } from "src/globals/axiosEndPoints";

export interface GetAllProductsParams {
  searchTerm?: string;
  limit: number;
  start: number;
}

interface Variants {
  variantId: number;
  variantName: string;
  variantPrice: number;
  variantStock: number;
  variantSku: string;
  imageUrl: URL;
}

export interface ProductDetail {
  productId: number;
  productName: string;
  productPrice: {
    //changed price type as per backend
    amount: string;
    currencyCode: string;
  };
  productStock: number;
  productSku: string;
  imageUrl: string;
  variants: Variants[];
}

export async function getAllShopifyProducts(payload: GetAllProductsParams) {
  const { data } = await axiosJSON.post("/api/shopify/product/getAll", payload);

  if (data.error === true) {
    throw new Error("Some Error Occurred while fetching data");
  }

  return {
    data: data.data,
    metaData: {
      total: data?.total ?? 0,
      count: data?.count ?? 0,
    },
  };
}
