/* eslint-disable no-lone-blocks */
/* eslint-disable react-hooks/exhaustive-deps */
import styles from "./MultipleFilter.module.scss";
import { Dropdown } from "react-bootstrap";
import AssignChatFilter, {
  renderAssingedAgentInfo,
} from "../AssignChatTo/AssignChatFilter";
import SearchBar from "src/components/SearchBar/SearchBar";
import filter from "src/assets/images/filterImg.png";
import { actions } from "src/store/slices/liveChatSetting/chatWidExchCust/chatWidExchCust.slice";
import useMultipleFilter from "./useMultipleFilter";
import AxiosImg from "src/components/AxiosImg";
import UserAvatar from "src/components/UserAvatar";
import { useAppSelector } from "src/store/store";
import Loader from "src/components/Loader";
import { fetchLiveChatIntegratedBrandsThunk } from "src/store/slices/liveChatSetting/chatWidExchCust/chatWidExchCust.thunks";
import FilterDatePicker from "./Children/FilterDatePicker/FilterDatePicker";
import { useEffect, useState } from "react";
import { StatusFilters } from "src/store/slices/liveChatSetting/chatWidExchCust/chatWidExchCust.declarations";

interface Props {
  showDropdown: boolean;
  setShowDropdown: React.Dispatch<React.SetStateAction<boolean>>;
}

const MultipleFilter = ({ setShowDropdown, showDropdown }: Props) => {
  const {
    dispatch,
    appliedBrandIds,
    appliedStartDate,
    appliedEndDate,
    brandList,
    fetchLiveChatIntegratedBrandAJAXStatus,
    idFilterApplied,
    statusApplied,
    startDate,
    endDate,
    resolvedChecked,
    searchQuery,
    selectedBrandIds,
    searchStatusQuery,
    selectAllChecked,
    selectAllStatus,
    setSearchQuery,
    setSelectAllChecked,
    showAsigneeList,
    showBrandList,
    setSearchStatusQuery,
    setSelectedBrandIds,
    setStartDate,
    setEndDate,
    unresolvedChecked,
    handleCancel,
    handleOnApply,
    handleSelectAll,
    handleResolved,
    handleUnresolved,
    handleCancelForStatus,
    handleApplyForStatus,
    handleMouseEnter,
    handleMouseLeave,
    handleMouseEnterForBrand,
    handleMouseLeaveForBrand,
    filteredOptions,
    concatinateAgentNamesInitials,
    allStatusFilterSelected,
    dropdownHandeler,
    formattedStartDate,
    formattedEndDate,
    selectAllBrand,
    concatinateAgentNames,
    handleMouseEnterForStatus,
    handleMouseLeaveForStatus,
    showStatus,
    handleMouseEnterForDate,
    handleMouseLeaveForDate,
    showDate,
    clearAllFitlerInside,
    clearAllFilterOutside,
    showValidation,
    setShowValidation,
    clearAllAppliedBrands,
    clearAllAppliedTags,
    resolvedAndArchivedChecked,
    unresolvedAndArchivedChecked,
    unresolvedAndUnarchivedChecked,
    handleResolvedAndArchived,
    handleUnresolvedAndArchived,
    handleUnresolvedAndUnarchived,
    statusDisplayNames,
  } = useMultipleFilter({
    setShowDropdown,
    showDropdown,
  });

  const selectedBrandIdsForFilter = useAppSelector(
    (state) => state.chatWidExchCust.selectedBrandIdsForFilter
  );

  const selectedBrands = selectedBrandIdsForFilter.map((id: any) =>
    brandList?.find((brand: any) => brand.id === id)
  );

  const { selectedStatusForFilter, statusAllSelected } = useAppSelector(
    (state) => state.chatWidExchCust
  );

  const [enableStartDate, setEnableStartDate] = useState(false);
  const [enableEndDate, setEnableEndDate] = useState(false);

  const changeDates = (date: any) => {
    if (enableStartDate) {
      setStartDate(date);
    }

    if (enableEndDate) {
      setEndDate(date);
    }
  };

  const selectedAgentForFilterInfo = useAppSelector((state) =>
    state.chatWidExchCust.selectedAgentIdsForFilter.flatMap((selectedId) => {
      const agent = state.chatWidExchCust.agents[selectedId];
      const agentInfo = [];
      if (agent) {
        agentInfo.push({
          email: agent.email,
          id: agent.id,
          name: agent.name,
          imgUrl: agent.imageURL,
        });
      }
      return agentInfo;
    })
  );

  useEffect(() => {
    if (formattedEndDate !== "Date") {
      setEnableEndDate(false);
    }
    // Adding this to fix start date selecting not working after secting second time
    if (formattedStartDate !== "Date") {
      setEnableStartDate(false);
    }
  }, [formattedEndDate,formattedStartDate]);
  return (
    <div className={`w-100 ${styles.filterWrapper}`}>
      {/* Dropdown  */}
      <Dropdown bsPrefix="dropdown w-100" show={showDropdown}>
        <Dropdown.Toggle
          as="div"
          id="dropdown-basic"
          bsPrefix={`dropdown-toggle ${styles.dropBtn}`}
          onClick={dropdownHandeler}
        >
          <p className="mb-0">
            <span className={`${styles.filter}`}>Filter</span>
            <span className={`ps-2 ${styles.filter}`}>
              <img src={filter} alt="filter" />
            </span>
          </p>
          {/* Outside Clear Button */}
          {(idFilterApplied.selectedIds &&
            idFilterApplied.selectedIds.length > 0) ||
          (allStatusFilterSelected ? true : false) ||
          statusApplied.includes("resolved") ||
          statusApplied.includes("unresolved") ||
          statusApplied.includes("resolved_and_archived") ||
          statusApplied.includes("unresolved_and_archived") ||
          statusApplied.includes("unresolved_and_unarchived") ||
          appliedStartDate !== null ||
          appliedEndDate !== null ||
          appliedBrandIds.length > 0 ? (
            <span
              className={`me-3 d-block ${styles.clearAll}`}
              onClick={(e) => {
                e.stopPropagation();
                clearAllFilterOutside();
                handleOnApply();
              }}
            >
              <span>
                <i className="fa-solid fa-xmark"></i>
              </span>{" "}
              Clear all
            </span>
          ) : null}
        </Dropdown.Toggle>

        <Dropdown.Menu bsPrefix={`dropdown-menu ${styles.dropMenu}`}>
          {/* Filter Section */}
          <div className={`${styles.fitlerSection} h-auto`}>
            <div className="d-flex" style={{ height: "267px" }}>
              {/* Assignee Chat Filter  */}
              <div style={{ height: "200px" }}>
                <AssignChatFilter />
              </div>

              {/* Date Picker  */}
              <div
                className=" pt-2 w-100 ms-2 d-flex justify-content-center flex-column align-items-center"
                // style={{ height: "240px" }}
              >
                <div className={`d-flex align-items-center ${styles.brands}`}>
                  <span>
                    <i className="fa-regular fa-calendar"></i>
                  </span>
                  <div className={`px-2 ${styles.dateWrap}`}>
                    <span
                      className={`px-2 ${styles.startDate} ${
                        styles.startSelected
                      } ${enableStartDate ? "border border-primary" : ""} ${
                        showValidation.startDataValidation &&
                        "border border-danger"
                      }`}
                      role="button"
                      onClick={() => {
                        setEnableStartDate(!enableStartDate);
                        setEnableEndDate(false);
                        setShowValidation({
                          startDataValidation: false,
                        });
                      }}
                    >
                      {formattedStartDate}
                    </span>
                    <span className={`px-2 ${styles.between}`}> - </span>
                    <span
                      className={`px-2 ${styles.endDate}  ${
                        styles.endSelected
                      } ${
                        showValidation.endDateValidation &&
                        "border border-danger"
                      } ${enableEndDate ? "border border-primary" : ""}`}
                      role="button"
                      onClick={() => {
                        setEnableEndDate(!enableEndDate);
                        setEnableStartDate(false);
                        setShowValidation({
                          endDateValidation: false,
                        });
                      }}
                    >
                      {formattedEndDate}
                    </span>
                  </div>

                  {formattedStartDate === "Date" &&
                  formattedEndDate === "Date" ? null : (
                    <span
                      role="button"
                      className={`${styles.clearDateButton}`}
                      onClick={() => {
                        setStartDate(null);
                        setEndDate(null);
                        setShowValidation({
                          endDateValidation: false,
                          startDataValidation: false,
                        });
                      }}
                    >
                      <i className="fa-solid fa-circle-xmark"></i>
                    </span>
                  )}
                </div>
                <div className={`customReactDatePicker mt-2`}>
                  <FilterDatePicker
                    startDate={startDate}
                    endDate={endDate}
                    onChange={changeDates}
                    startDateEnabled={enableStartDate}
                    endDateEnabled={enableEndDate}
                  />
                </div>
              </div>
            </div>

            <div className="d-flex pt-2 justify-content-between">
              {/* Filter By Brands */}
              <div
                className="align-items-center flex-column"
                style={{ width: "270px" }}
              >
                <span className={`d-flex align-items-center ${styles.brands}`}>
                  <div className="d-flex justify-content-between w-100">
                    <span className={`my-auto ${styles.assignedAgentsName}`}>
                      {selectedBrands && selectedBrands.length > 0
                        ? concatinateAgentNames(selectedBrands)
                        : "Brands"}
                    </span>
                    {selectedBrands && selectedBrands.length > 0 && (
                      <>
                        {/* show this clear all button when filter is applied  */}
                        <span
                          className={`d-block ${styles.clearAll} ${styles.flexShrink}`}
                          onClick={clearAllAppliedBrands}
                          role="button"
                        >
                          <span className="pe-1">
                            <i className="fa-solid fa-xmark"></i>
                          </span>
                          Clear all
                        </span>
                      </>
                    )}
                  </div>
                </span>
                <div className={`${styles.menuWrapper} `}>
                  <SearchBar
                    className={`${styles.search} ps-2 pe-1`}
                    inputClassName={`${styles.input}`}
                    placeholder={`Search brands`}
                    value={searchQuery}
                    onChange={(event: {
                      target: { value: React.SetStateAction<string> };
                    }) => setSearchQuery(event.target.value)}
                    onSearch={(searchTerm: any) => {
                      dispatch(
                        fetchLiveChatIntegratedBrandsThunk({
                          searchTerm: searchTerm,
                        })
                      );
                    }}
                  />
                  {fetchLiveChatIntegratedBrandAJAXStatus === "pending" ? (
                    <Loader />
                  ) : (
                    <>
                      <div className={`${styles.showItems}`}>
                        <div className={`${styles.selectAll}`}>
                          <div className={`form-check`}>
                            <input
                              className={`form-check-input ${styles.customCheck}`}
                              role="button"
                              type="checkbox"
                              value=""
                              id="selectAll"
                              checked={selectAllChecked}
                              onClick={(e) => selectAllBrand(e)}
                            />
                            <label
                              className={`form-check-label ps-2 ${styles.filterLabel}`}
                              htmlFor="selectAll"
                            >
                              Select All
                            </label>
                          </div>
                        </div>
                        {brandList &&
                          brandList
                            // .filter((brand) =>
                            //   brand.name
                            //     .toLowerCase()
                            //     .includes(searchQuery.toLowerCase())
                            // )
                            .map((brand, index) => (
                              <div
                                className={`${styles.formCheck} ${
                                  selectAllChecked ||
                                  selectedBrandIds.includes(brand.id)
                                    ? styles.brandSelected
                                    : ""
                                }`}
                                key={index}
                              >
                                <div className={`form-check w-100`}>
                                  <input
                                    className={`form-check-input ${styles.customCheck} `}
                                    type="checkbox"
                                    value=""
                                    id={`brand-${index}`}
                                    checked={
                                      selectAllChecked ||
                                      selectedBrandIds.includes(brand.id)
                                    }
                                    onClick={() => {
                                      const brandIndex =
                                        selectedBrandIds.indexOf(brand.id);
                                      if (brandIndex === -1) {
                                        setSelectedBrandIds([
                                          ...selectedBrandIds,
                                          brand.id,
                                        ]);
                                        dispatch(
                                          actions.selectedBrandForFilter({
                                            brandId: brand.id,
                                          })
                                        );
                                        if (
                                          selectedBrandIds.length ===
                                          brandList.length - 1
                                        ) {
                                          setSelectAllChecked(true);
                                        }
                                      } else {
                                        const newSelectedBrandIds = [
                                          ...selectedBrandIds,
                                        ];
                                        newSelectedBrandIds.splice(
                                          brandIndex,
                                          1
                                        );
                                        setSelectedBrandIds(
                                          newSelectedBrandIds
                                        );
                                        dispatch(
                                          actions.deselectedBrandForFilter({
                                            brandId: brand.id,
                                          })
                                        );
                                        dispatch(
                                          actions.selectedMultipeBrandForFilter(
                                            {
                                              brandIds: newSelectedBrandIds,
                                            }
                                          )
                                        );
                                      }

                                      if (
                                        selectAllChecked &&
                                        selectedBrandIds.length ===
                                          brandList.length
                                      ) {
                                        // If all checkboxes are currently selected and the clicked checkbox
                                        // is being deselected, also deselect the "select all" checkbox
                                        setSelectAllChecked(false);
                                      } else if (
                                        !selectAllChecked &&
                                        selectedBrandIds.length ===
                                          brandList.length
                                      ) {
                                        // If all checkboxes are now selected, select the "select all" checkbox
                                        setSelectAllChecked(true);
                                      }
                                    }}
                                  />
                                  <label
                                    className={`form-check-label text-truncate ps-2 ${styles.filterLabel}`}
                                    htmlFor={`brand-${index}`}
                                  >
                                    {brand.name}
                                  </label>
                                </div>
                              </div>
                            ))}
                      </div>
                    </>
                  )}
                </div>
              </div>

              {/* Filter By Status */}
              <div className="d-flex align-items-center flex-column ms-2">
                <span className={`d-flex align-items-center ${styles.brands}`}>
                  <div className="d-flex justify-content-between w-100">
                    <div
                      className={`text-truncate ${styles.statusDisplayName}`}
                    >
                      {statusAllSelected
                        ? "All Selected"
                        : selectedStatusForFilter.length > 0
                        ? selectedStatusForFilter
                            .map((status) => statusDisplayNames[status])
                            .join(", ")
                        : "Status"}
                    </div>
                    {selectedStatusForFilter.includes("resolved") ||
                    selectedStatusForFilter.includes("unresolved") ||
                    selectedStatusForFilter.includes("resolved_and_archived") ||
                    selectedStatusForFilter.includes(
                      "unresolved_and_archived"
                    ) ||
                    selectedStatusForFilter.includes(
                      "unresolved_and_unarchived"
                    ) ||
                    (statusAllSelected &&
                      selectedStatusForFilter.includes("all")) ? (
                      <>
                        {/* show this clear all button when filter is applied  */}
                        <span
                          className={`d-block ${styles.clearAll}`}
                          onClick={clearAllAppliedTags}
                          role="button"
                        >
                          <span className="pe-1">
                            <i className="fa-solid fa-xmark"></i>
                          </span>
                          Clear all
                        </span>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </span>
                <div className={`${styles.menuWrapper} w-100`}>
                  <SearchBar
                    className={`${styles.search} ps-2`}
                    inputClassName={`${styles.input}`}
                    placeholder={`Search Status`}
                    value={searchStatusQuery}
                    onChange={(e: {
                      target: { value: React.SetStateAction<string> };
                    }) => setSearchStatusQuery(e.target.value)}
                  />
                  <div className={`${styles.showItems}`}>
                    <div className={`${styles.selectAll}`}>
                      <div className={`form-check`}>
                        <div>
                          <input
                            className={`form-check-input ${styles.customCheck}`}
                            role="button"
                            type="checkbox"
                            value=""
                            id="selectAll2"
                            checked={
                              selectAllStatus ||
                              selectedStatusForFilter.includes("all")
                            }
                            onChange={handleSelectAll}
                          />
                        </div>

                        <label
                          className={`form-check-label text-truncate ps-2 ${styles.filterLabel}`}
                          htmlFor="selectAll2"
                        >
                          Select All
                        </label>
                      </div>
                    </div>
                    {filteredOptions.map((option, index) => (
                      <div
                        key={index}
                        className={`${styles.formCheck} ${
                          (resolvedChecked && option === "Resolved") ||
                          (unresolvedChecked && option === "Unresolved") ||
                          (resolvedAndArchivedChecked &&
                            option === "Resolved and Archived") ||
                          (unresolvedAndArchivedChecked &&
                            option === "Unresolved and Archived") ||
                          (unresolvedAndUnarchivedChecked &&
                            option === "Unresolved and Unarchived")
                            ? styles.statusSelected
                            : ""
                        }`}
                      >
                        <div className={`form-check`}>
                          <input
                            key={index}
                            className={`form-check-input ${styles.customCheck}`}
                            type="checkbox"
                            id={`option-${index}`}
                            checked={
                              option === "Resolved" &&
                              (resolvedChecked ||
                                selectedStatusForFilter.includes("resolved"))
                                ? true
                                : option === "Unresolved" &&
                                  (unresolvedChecked ||
                                    selectedStatusForFilter.includes(
                                      "unresolved"
                                    ))
                                ? true
                                : option === "Resolved and Archived" &&
                                  (resolvedAndArchivedChecked ||
                                    selectedStatusForFilter.includes(
                                      "resolved_and_archived"
                                    ))
                                ? true
                                : option === "Unresolved and Archived" &&
                                  (unresolvedAndArchivedChecked ||
                                    selectedStatusForFilter.includes(
                                      "unresolved_and_archived"
                                    ))
                                ? true
                                : option === "Unresolved and Unarchived" &&
                                  (unresolvedAndUnarchivedChecked ||
                                    selectedStatusForFilter.includes(
                                      "unresolved_and_unarchived"
                                    ))
                                ? true
                                : selectedStatusForFilter.includes("all")
                                ? true
                                : false
                            }
                            onChange={
                              option === "Resolved"
                                ? handleResolved
                                : option === "Unresolved"
                                ? handleUnresolved
                                : option === "Resolved and Archived"
                                ? handleResolvedAndArchived
                                : option === "Unresolved and Archived"
                                ? handleUnresolvedAndArchived
                                : option === "Unresolved and Unarchived"
                                ? handleUnresolvedAndUnarchived
                                : handleUnresolved
                            }
                          />
                          <label
                            className={`form-check-label ps-2 ${styles.filterLabel}`}
                            htmlFor={`option-${index}`}
                          >
                            {option}
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Buttons Section*/}
          <div
            className={`d-flex justify-content-between align-items-center mt-1`}
          >
            <button
              className={`${styles.cancelBtn}`}
              onClick={() => {
                setShowDropdown(!showDropdown);
                handleCancel();
                dispatch(actions.idsAppliedToSelectedIdFilter());
                handleCancelForStatus();
                setStartDate(appliedStartDate);
                setEndDate(appliedEndDate);
                setShowValidation({
                  endDateValidation: false,
                  startDataValidation: false,
                });
              }}
            >
              Cancel
            </button>
            <div className="d-flex align-items-center">
              {/* Clear All Inside Button */}
              {selectedAgentForFilterInfo.length > 0 ||
              formattedStartDate !== "Date" ||
              formattedEndDate !== "Date" ||
              selectedBrands.length > 0 ||
              statusAllSelected ||
              selectedStatusForFilter.includes("resolved") ||
              selectedStatusForFilter.includes("unresolved") ||
              selectedStatusForFilter.includes("resolved_and_archived") ||
              selectedStatusForFilter.includes("unresolved_and_archived") ||
              selectedStatusForFilter.includes("unresolved_and_unarchived") ? (
                <span
                  className={`me-3 ${styles.clearAll}`}
                  onClick={() => {
                    clearAllFitlerInside();
                    setShowValidation({
                      endDateValidation: false,
                      startDataValidation: false,
                    });
                  }}
                >
                  <span className="pe-1">
                    <i className="fa-solid fa-xmark"></i>
                  </span>
                  Clear all
                </span>
              ) : null}
              <button
                className={`${styles.applyBtn}`}
                onClick={() => {
                  if (
                    (formattedEndDate === "Date" &&
                      formattedStartDate !== "Date") ||
                    (formattedEndDate !== "Date" &&
                      formattedStartDate === "Date")
                  ) {
                    if (formattedEndDate === "Date") {
                      setShowValidation({
                        endDateValidation: true,
                        startDataValidation: false,
                      });
                      return;
                    }
                    if (formattedStartDate === "Date") {
                      setShowValidation({
                        endDateValidation: false,
                        startDataValidation: true,
                      });
                      return;
                    }
                    return;
                  } else {
                    setShowValidation({
                      endDateValidation: false,
                      startDataValidation: false,
                    });
                    handleOnApply();
                    setShowDropdown(!showDropdown);
                    handleApplyForStatus();
                  }
                }}
              >
                Apply
              </button>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>

      {/* Filtered Items List  */}
      {!showDropdown &&
      ((idFilterApplied.selectedIds &&
        idFilterApplied.selectedIds.length > 0) ||
        (allStatusFilterSelected ? true : false) ||
        statusApplied.includes("resolved") ||
        statusApplied.includes("unresolved") ||
        statusApplied.includes("resolved_and_archived") ||
        statusApplied.includes("unresolved_and_archived") ||
        statusApplied.includes("unresolved_and_unarchived") ||
        appliedStartDate !== null ||
        appliedEndDate !== null ||
        appliedBrandIds.length > 0) ? (
        <p
          className={`mb-0 ${styles.filteredItem} d-flex justify-content-start`}
        >
          {/* chat assignee */}
          {idFilterApplied.selectedIds &&
          idFilterApplied.selectedIds.length > 0 ? (
            <div
              className={`${
                !showAsigneeList
                  ? "text-truncate ps-1"
                  : styles.onHoverListForChat
              } ${!showAsigneeList ? styles.assignee : ""} ${
                !showAsigneeList ? styles.assignWrap : ""
              }`}
              role="button"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <>
                <div className="d-flex position-relative">
                  {idFilterApplied.selectedIdsInfo &&
                  idFilterApplied.selectedIdsInfo.length !== 0 ? (
                    // If any agent is selected
                    <div>
                      {/* If Mouse Entered */}
                      {showAsigneeList ? (
                        <div className={`${styles.selectedList} overflow-auto`}>
                          {/* List Items */}
                          <ul className={`${styles.noBullet}`}>
                            {idFilterApplied.selectedIdsInfo.map((agent) => (
                              <div className="w-100">
                                <li
                                  key={agent.id}
                                  className="d-inline-block text-truncate"
                                  // style={{ maxWidth: "80px" }}
                                >
                                  <span
                                    className={`position-relative ${styles.chatImg} me-1`}
                                  >
                                    {agent.imgUrl &&
                                    agent.imgUrl.length !== 0 ? (
                                      <AxiosImg
                                        url={agent.imgUrl}
                                        className={`${styles.profileImg}`}
                                        isDirectURL={
                                          agent?.isPublicAttachmentUrl
                                        }
                                      />
                                    ) : (
                                      <UserAvatar
                                        name={
                                          agent.name && agent.name !== ""
                                            ? agent.name
                                            : "NA"
                                        }
                                        size={18}
                                        className={`img-fluid`}
                                      />
                                    )}
                                  </span>
                                  <span>{agent.name}</span>
                                </li>
                              </div>
                            ))}
                          </ul>
                        </div>
                      ) : (
                        // If Mouse Left
                        <div className="d-flex position-relative">
                          {renderAssingedAgentInfo(
                            idFilterApplied.selectedIdsInfo
                          )}
                        </div>
                      )}
                    </div>
                  ) : (
                    // If no agent is selected
                    <span
                      className={`position-relative ${styles.chatFilter} ${styles.assignee} `}
                    >
                      Assignee
                    </span>
                  )}
                </div>

                {/* If Mouse Left */}
                {!showAsigneeList && (
                  <span className={`ms-1 me-2 ${styles.assignedAgentsName}`}>
                    {idFilterApplied.selectedIdsInfo &&
                    idFilterApplied.selectedIdsInfo.length
                      ? idFilterApplied.selectedIdsInfo.length <= 2
                        ? concatinateAgentNamesInitials(
                            idFilterApplied.selectedIdsInfo
                          )
                        : idFilterApplied.selectedIdsInfo
                            .map((agent) =>
                              concatinateAgentNamesInitials([agent])
                            )
                            .slice(0, 2)
                            .join(", ") + "..."
                      : concatinateAgentNamesInitials(
                          idFilterApplied.selectedIdsInfo
                        )}
                  </span>
                )}
              </>
            </div>
          ) : (
            ""
          )}

          {/* date */}
          {appliedStartDate !== null ? (
            <span
              className={` ${
                !showDate
                  ? "text-truncate d-inline-block ps-1 "
                  : styles.onHoverListForDate
              }${!showDate ? styles.assignee : ""} ${
                !showDate ? styles.brandWrap : ""
              } position-relative`}
              role="button"
              onMouseEnter={handleMouseEnterForDate}
              onMouseLeave={handleMouseLeaveForDate}
            >
              {showDate ? (
                <div
                  className={`${styles.showDateList} d-inline-block text-truncate overflow-auto`}
                >
                  <ul className={`${styles.noBullet}`}>
                    <li>
                      {appliedStartDate && appliedEndDate ? (
                        <>
                          {appliedStartDate.toLocaleDateString("en-GB", {
                            day: "numeric",
                            month: "numeric",
                          })}{" "}
                          -{" "}
                          {appliedEndDate.toLocaleDateString("en-GB", {
                            day: "numeric",
                            month: "numeric",
                          })}
                        </>
                      ) : null}
                    </li>
                  </ul>
                </div>
              ) : appliedStartDate && appliedEndDate ? (
                `${appliedStartDate.toLocaleDateString("en-GB", {
                  day: "numeric",
                  month: "numeric",
                })} - ${appliedEndDate.toLocaleDateString("en-GB", {
                  day: "numeric",
                  month: "numeric",
                })} `
              ) : (
                "Date"
              )}
            </span>
          ) : (
            ""
          )}

          {/* brands */}
          {appliedBrandIds.length !== 0 ? (
            <div
              className={`${
                !showBrandList
                  ? "text-truncate ps-1 "
                  : styles.onHoverListForBrands
              } ${!showBrandList ? styles.assignee : ""} ${
                !showBrandList ? styles.brandWrap : ""
              } position-relative`}
              role="button"
              onMouseEnter={handleMouseEnterForBrand}
              onMouseLeave={handleMouseLeaveForBrand}
            >
              <div className="d-flex">
                {showBrandList ? (
                  <div
                    className={`${styles.showBrandList} text-truncate overflow-auto`}
                    // style={{ maxWidth: "50px" }}
                  >
                    <ul className={`${styles.noBullet}`}>
                      {appliedBrandIds.map((id) => (
                        <li
                          key={id}
                          className="text-truncate w-100"
                          // style={{ maxWidth: "50px" }}
                        >
                          {appliedBrandIds.length !== 0 && brandList
                            ? brandList
                                .filter((brand: any) =>
                                  appliedBrandIds.includes(brand.id)
                                )
                                .map((brand: any) => {
                                  if (brand.id === id) {
                                    return brand.name;
                                  }
                                })
                            : "Brand"}
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : appliedBrandIds.length !== 0 && brandList ? (
                  appliedBrandIds
                    .map((id: any) =>
                      brandList.find((brand: any) => brand.id === id)
                    )
                    .map((brand: any) => brand.name.substring(0, 2))
                    .slice(0, 2)
                    .join(", ") + (appliedBrandIds.length > 2 ? "..." : "")
                ) : (
                  "Brand"
                )}
              </div>
            </div>
          ) : (
            ""
          )}

          {/* status */}
          {!showDropdown &&
          ((statusApplied.length > 0 && allStatusFilterSelected !== false) ||
            statusApplied.includes("resolved") ||
            statusApplied.includes("unresolved") ||
            statusApplied.includes("resolved_and_archived") ||
            statusApplied.includes("unresolved_and_archived") ||
            statusApplied.includes("unresolved_and_unarchived")) ? (
            <div
              className={`${styles.assignee} ${
                showStatus ? styles.resolved2 : styles.resolved
              } ${
                showStatus ? styles.showStatusAll : "text-truncate ps-1"
              } position-relative`}
              role="button"
              onMouseEnter={handleMouseEnterForStatus}
              onMouseLeave={handleMouseLeaveForStatus}
            >
              <div
                className={`d-flex`}
                // style={{ maxWidth: "40px" }}
              >
                {showStatus ? (
                  <div
                    className={`${styles.showStatusList} d-flex overflow-auto`}
                    // style={{ maxWidth: "50px" }}
                  >
                    <ul className={`${styles.noBullet} `}>
                      {statusApplied.includes("all")
                        ? Object.keys(statusDisplayNames)
                            .filter((status) => status !== "all")
                            .map((status) => (
                              <li
                                key={status}
                                className="text-truncate w-100 px-1"
                                // style={{ maxWidth: "50px" }}
                              >
                                {statusDisplayNames[status as StatusFilters]}
                              </li>
                            ))
                        : statusApplied.map((status: StatusFilters) => (
                            <li
                              key={status}
                              className="text-truncate w-100 px-1"
                              // style={{ maxWidth: "50px" }}
                            >
                              {statusDisplayNames[status]}
                            </li>
                          ))}
                    </ul>
                  </div>
                ) : (
                  <div>
                    {statusApplied.includes("all")
                      ? Object.keys(statusDisplayNames)
                          .filter((status) => status !== "all")
                          .map((status) => (
                            <span
                              key={status}
                              className={`text-truncate pt-2 d-inline-block ${styles.status}`}
                              style={{ width: "50px" }}
                            >
                              {statusDisplayNames[status as StatusFilters]}
                            </span>
                          ))
                      : statusApplied.map((status: StatusFilters) => (
                          <span
                            key={status}
                            className={`text-truncate pt-2 d-inline-block ${styles.status}`}
                            style={{ width: "50px" }}
                          >
                            {statusDisplayNames[status]}
                          </span>
                        ))}
                  </div>
                )}
              </div>
            </div>
          ) : (
            ""
          )}
        </p>
      ) : null}
    </div>
  );
};

export default MultipleFilter;
