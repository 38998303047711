/**
 * This file defines a custom hook responsible for CRUD of condition in Audience configure form
 *
 * @author @yuvaraj-busibud
 */
import React, { useCallback, useMemo } from "react";
import { AudienceConfigCondition } from "src/store/slices/botProfiles/botProfiles.declarations";
import {
  removeAudienceConfigCondition,
  updateAudienceConfigCondition,
} from "src/store/slices/botProfiles/botProfiles.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";

interface Props {
  condition: AudienceConfigCondition;
  setIschange: any;
}
interface SelectedOption {
  value: string | number;
  label: string;
}

export const useCondition = ({ condition, setIschange }: Props) => {
  // Redux dispatch hook
  const dispatch = useAppDispatch();

  // Selecting audienceConfigOptions from the Redux store using useSelector
  const { audienceConfigOptions } = useAppSelector(
    (state) => state.botProfiles
  );

  // Memoizing the conditionOptions for better performance
  const conditionOptions = useMemo(() => {
    return audienceConfigOptions.map((option) => {
      return { label: option.conditionName, value: option.conditionKey };
    });
  }, [audienceConfigOptions]);

  // Memoizing selectedCondition, selectedOperator, and operatorOptions
  const { selectedCondition, selectedOperator, operatorOptions } =
    useMemo(() => {
      const conditionOption = audienceConfigOptions.find(
        (option) => option.conditionKey === condition.conditionKey
      );

      if (conditionOption) {
        let operator = conditionOption.operators.find(
          (operator) => operator.key === condition.operator
        );
        return {
          selectedCondition: {
            label: conditionOption.conditionName,
            value: conditionOption.conditionKey,
            inputType: conditionOption.inputType,
          },
          operatorOptions: conditionOption.operators.map((operator) => {
            return {
              label: operator.name,
              value: operator.key,
            };
          }),
          selectedOperator: operator
            ? {
                label: operator.name,
                value: operator.key,
              }
            : undefined,
        };
      }

      return {
        operatorOptions: [] as Array<any>,
      };
    }, [condition, audienceConfigOptions]);

  // Memoizing conditionValue for better performance
  const conditionValue = useMemo(() => {
    return condition.values;
  }, [condition.values]);

  // Handler to update the selected condition type
  const handleUpdateConditionType = useCallback(
    (selectedCondition: SelectedOption) => {
      const conditionOption = audienceConfigOptions.find(
        (option) => option.conditionKey === selectedCondition.value
      );

      if (conditionOption) {
        // Dispatching an action to update the audience configuration condition
        dispatch(
          updateAudienceConfigCondition({
            conditionId: condition.id,
            properties: {
              conditionKey: conditionOption.conditionKey + "",
              operator: conditionOption.operators[0].key,
              values: null,
            },
          })
        );
      }
    },
    [audienceConfigOptions, condition.id]
  );

  // Handler to update the selected operator
  const handleUpdateOperator = useCallback(
    (selectedOperator: SelectedOption) => {
      // Dispatching an action to update the audience configuration condition
      dispatch(
        updateAudienceConfigCondition({
          conditionId: condition.id,
          properties: {
            operator: selectedOperator.value + "",
          },
        })
      );
    },
    [condition.id]
  );

  // Handler to update the condition value
  const handleUpdateValue = useCallback(
    (value: string | Array<string | number> | null) => {
      // Dispatching an action to update the audience configuration condition
      dispatch(
        updateAudienceConfigCondition({
          conditionId: condition.id,
          properties: {
            values: value,
            error: undefined,
          },
        })
      );
      // Setting isChange to true to indicate a change
      setIschange(true);
    },
    [condition.id]
  );

  // Handler to delete the condition
  const handleDeleteCondition = useCallback(() => {
    // Dispatching an action to remove the audience configuration condition
    dispatch(removeAudienceConfigCondition(condition.id));
    // Setting isChange to true to indicate a change
    setIschange(true);
  }, [condition.id]);

  // Returning the state and handler functions
  return {
    conditionOptions,
    selectedCondition,
    selectedOperator,
    operatorOptions,
    handleUpdateConditionType,
    handleUpdateOperator,
    handleUpdateValue,
    handleDeleteCondition,
    conditionValue,
  };
};
