import ReactSelect from "react-select";
import styles from "../ReportInternalFilter.module.scss";
import { useCallback, useMemo } from "react";
import {
  reportInternalFiltersActions,
  useReportInternalFilters,
} from "src/routes/Report/hooks/reportFilters/useReportInternalFilters";
import { useQuery } from "@tanstack/react-query";
import { fetchAllUsers } from "src/services/UserService/fetchAllUsers";

const useIntAssignedToFilter = () => {
  const { data: allUsers } = useQuery(["userData/getAllUsers"], {
    queryFn: async () => {
      const users: Array<{
        id: string;
        email: string;
        imageUrl?: string | null;
        name?: string | null;
        status: string;
      }> = await fetchAllUsers({ start: 0, limit: 1000 }, true);
      return users;
    },
    staleTime: 600000,
    cacheTime: 600000,
  });

  const options = useMemo(() => {
    return (
      allUsers?.map((data) => {
        return {
          label: data.name ?? data.email,
          value: data,
        };
      }) ?? []
    );
  }, [allUsers]);

  const { internalFilters, dispatch } = useReportInternalFilters();

  const selectedUsers = useMemo(() => {
    return allUsers?.length
      ? (
          allUsers.filter((data) =>
            internalFilters.addedFilters.assignedTo?.includes(data.id + "")
          ) ?? []
        ).map((user) => {
          return {
            label: user.name ?? user.email,
            value: user,
          };
        })
      : [];
  }, [internalFilters, allUsers]);

  const onChangeUsers = useCallback(
    (value: typeof selectedUsers) => {
      dispatch([
        reportInternalFiltersActions.setFilters,
        { assignedTo: value.map((data) => data.value.id + "") },
      ]);
    },
    [selectedUsers]
  );
  return {
    options,
    selectedUsers,
    onChangeUsers,
  };
};

function AssignedTo() {
  const { options, selectedUsers, onChangeUsers } = useIntAssignedToFilter();

  return (
    <div className="px-2 pb-2">
      <label className={`${styles.labelText}`}>Assigned to</label>
      <ReactSelect
        isMulti
        options={options}
        value={selectedUsers}
        onChange={onChangeUsers as any}
        className={`${styles.text}`}
        menuShouldBlockScroll={true}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
            font: "normal normal normal 12px/18px Poppins",
            color: "#000000",
          }),
          multiValue: (base, { data }) => ({
            ...base,
            backgroundColor: '#F4F4F4', // Background color for selected values
            borderRadius: '6px',
            opacity: 1,
          }),
          multiValueLabel: (base) => ({
            ...base,
            font: "normal normal 500 12px/18px Poppins",
            letterSpacing: "0px",
            color: "#1F2223",
            paddingLeft:"12px",
          }),
          multiValueRemove: (base, { data }) => ({
            ...base,
            // color: '#000000', // Remove icon color for selected values
            fontSize: "6px",
            // ':hover': {
            //   backgroundColor: 'red', // Change the background color on hover if needed
            // },
          }),
        }}
      />
    </div>
  );
}

export default AssignedTo;
