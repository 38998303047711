import React, { useCallback, useEffect, useState } from "react";
import {
  reportNotificationsActions,
  useNotifications,
} from "../../../hooks/notifications/useNotifications";
import getNotificationsService from "src/services/report/getNotifications.service";
import { useCreateNewNotify } from "src/routes/Report/Children/CreateNotifyBtn/CreateNotifyBtn";
import { ReportTabNames } from "src/routes/Report/Children/ReportTabs/ReportTabs";

interface Props {
  activeTabname: ReportTabNames;
  setActiveTabname: React.Dispatch<React.SetStateAction<ReportTabNames>>;
}
function useNotificationBox({ activeTabname, setActiveTabname }: Props) {
  const { reportNotifications, dispatch } = useNotifications();
  const createNewNotify = useCreateNewNotify({
    activeTabname,
    setActiveTabname,
  });

  const [isLoading, setIsloading] = useState(true);

  const [isError, setIsError] = useState(false);

  const showEditNotification = useCallback(() => {
    createNewNotify();
    dispatch([reportNotificationsActions.setActiveView, "edit"]);
  }, [dispatch, createNewNotify]);

  useEffect(() => {
    setIsloading(true);
    setIsError(false);
    if (reportNotifications.context) {
      getNotificationsService({ context: reportNotifications.context })
        .then((res) => {
          dispatch([reportNotificationsActions.setAllNotifications, res]);
        })
        .catch((err) => {
          setIsError(true);
        })
        .finally(() => {
          setIsloading(false);
        });
    }
  }, [reportNotifications.context]);

  return { reportNotifications, isLoading, showEditNotification, isError };
}

export default useNotificationBox;
