import { axiosJSON, clearCacheByUrl } from "src/globals/axiosEndPoints";
import { Phone } from "src/store/slices/editCustomerDetails/editCustomerDetails.declarations";
import { StatusFilters } from "src/store/slices/liveChatSetting/chatWidExchCust/chatWidExchCust.declarations";
import {
  ChannelType,
  PhoneNumberData,
} from "../startNewChat/startNewChat.services";
import { EChannel } from "src/enums/EChannel";
export const SOCIAL_CHANNELS = ["whatsapp", "messenger", "instagram"];

export interface ITicketData {
  id: string | number;
  createdDateTimeGMT?: string;
}
export interface LiveCustomerMeta {
  customerLocation?: {
    countryName?: null | string;
    countryCode?: null | string;
  };
  chatStartedUrl?: null | string;
  currentChatUrl?: null | string;
}
export interface CustomerAttribute {
  name: string;
  value: string;
}

export interface IChatChannelDetail {
  id: string;
  value: string;
}
export interface Customer {
  customerId: string;
  chatId: string | number;
  name: string;
  email: string;
  otherEmails?: string[]; // Optional property for additional email addresses
  phone?: Phone[]; // Optional property for phone numbers with associated details
  instagram?: string[]; // Optional property for Instagram usernames
  facebook?: string[]; // Optional property for Facebook usernames
  twitter?: string[]; // Optional property for Twitter usernames
  subject: string;
  imageURL: string | null;
  isPublicAttachmentUrl?: boolean;
  lastMessage: string;
  lastMentionMessage?: string;
  lastMessageTime: string;
  isRead: boolean;
  missedMessages: number | null;
  receivedMessages: number | null;
  channelName: string;
  customerStatus: "live" | "missed" | "archived";
  lastMessageId: number | string;
  isConnected: boolean;
  canReply: boolean;
  chatAssignedTo?: Array<{
    id: number | string;
    name: string;
    imgUrl: string | null;
  }>;
  mentionedBy?: {
    id: number | string;
    name: string;
    email: string;
    imgUrl: string | null;
  };
  unreadMentionCount?: number;
  isCustomerWaitingForReply: boolean;
  customerWaitingForReplySince: string | null;
  // assignedAgentId?: Array<string | number>; //removed from backend
  brand?: null | {
    id: string | number;
    name: string;
    email: string;
    imgURL?: string | null;
    isPublicAttachmentUrl?: boolean;
  };
  chatStatus?: "queued" | "chat_in_progress_ai" | "";
  queuedDatetimeGmt?: string | null;
  ticketData?: ITicketData | null;
  isResolved?: boolean;
  historyCount?: number;
  isTicketCreated?: boolean;
  customerMeta?: LiveCustomerMeta;
  isCustomerOnline?: boolean;
  isWhatsappChat?: boolean;
  mobileNumber?: string;
  scheduledCount?: number;
  integrationId?: number | string;
  attributes?: Array<CustomerAttribute>;
  lastMessageBy?: {
    //used in bot chat new design, to show "agent replied" or "cern replied", etc
    id: number | string;
    name: string;
    imgUrl: string | null;
    type: "bot" | "agent" | "customer";
  };
  botDetails?: {
    //used in bot chat new design, to show bot name
    id: number | string;
    name: string;
    imgUrl: string | null;
    botProfileName: string | null;
    isDeleted: boolean;
  };
  isAnonymousCustomer?: boolean; //flag to identify if customer is anonymous
  chatChannelType?: EChannel;
  chatChannelToDetail?: PhoneNumberData | IChatChannelDetail;
  chatChannelFromDetail?: PhoneNumberData | IChatChannelDetail;
  replyOptions?: Array<EChannel>;
}

export interface Customers {
  [chatId: string | number]: Customer;
}

export interface CustomerReturnType {
  customers: Customers;
  chatIdList: Array<number | string>;
  metaData: {
    unreadMentionsCount: number;
    searchTotalCount?: number;
    limitReached?: boolean;
  };
  customerStatus?: "live" | "missed" | "archived";
}

export interface GetAllCustomers {
  status?: Array<"live" | "missed" | "archived" | "queued">;
  start?: number | string;
  limit?: number | string;
  searchTerm?: string;
  initialFetch?: boolean;
  filters?: {
    onlyAssignedChats?: boolean | undefined;
    assignedAgentsId?: Array<string | number> | undefined;
    showMentions?: boolean;
    // allBrands?: boolean | undefined;
    brandIds?: Array<string | number> | undefined;
    // allStatus?: boolean | undefined;
    chatResolvedStatus?: Array<StatusFilters> | undefined;
    startDate?: string | null;
    endDate?: string | null;
  };
}

export async function getQueuedChats(payload: {
  start: number;
  limit: number;
}) {
  const { data, status } = await axiosJSON.post(
    `/api/liveChat/agent/getQueuedChats`,
    payload,
  );

  if (data.error === true) {
    throw new Error(data.message as string);
  }

  const customers: Customers = {};

  data.data?.map((customer: Customer) => {
    const chatId = parseInt(customer.chatId + "");
    customers[chatId] = customer;
  });

  return customers;
}

export async function getAllCustomers(payload: GetAllCustomers, signal?: any) {
  if (
    payload.searchTerm === undefined ? false : payload.searchTerm.trim() === ""
  ) {
    delete payload.searchTerm;
  }

  if (payload?.initialFetch == false) {
    delete payload.initialFetch;
  }

  const { data, status } = await axiosJSON.post(
    `/api/liveChat/agent/getAllLiveChatCustomers`,
    { ...payload, cacheIt: true, cacheTime: 30000 },
    { signal },
  );

  if (data.error === true) {
    throw new Error(data.message as string);
  }
  const normalizedData = normalizeData(
    data.data,
    data.metaData,
    parseInt(payload.limit + ""),
  );
  // console.log(normalizeData);
  return normalizedData;
}

export function clearGetAllCustomersCache() {
  clearCacheByUrl("/api/liveChat/agent/getAllLiveChatCustomers");
}

function normalizeData(customerData: any, meta: any, limit: number) {
  const customers: Customers = {};
  const chatIdList: Array<number | string> = [];

  customerData.map((customer: Customer) => {
    const chatId = parseInt(customer.chatId + "");
    chatIdList.push(chatId);
    customers[chatId] = customer;
  });

  const metaData: CustomerReturnType["metaData"] = {
    searchTotalCount: meta.searchTotalCount,
    unreadMentionsCount: meta.unreadMentionsCount,
  };

  // Checking if payload limit is there then checking if limit is reached.
  if (!isNaN(limit)) {
    if (chatIdList.length < limit) {
      metaData.limitReached = true;
    }
  }
  const customerStatus = meta?.customerStatus;

  return {
    customers,
    chatIdList,
    metaData,
    customerStatus,
  } as CustomerReturnType;
}
