import { createSlice } from "@reduxjs/toolkit";
import {
  ConditionTypes,
  IVariableName,
  ruleTypes,
} from "src/features/ReturnAutoWorkFlow/children/ConfigModals/store/returnModalsSliceHelpers";
import { ARSResponse } from "src/features/ReturnAutoWorkFlow/hooks/useGetAutomationData";
import { AJAXSTATUS } from "src/globals/constants";
import { conditionType } from "src/services/UiAutomation/ReturnExchange/Configuration/Step9/step9GetOptionsConditions";
import { v4 as uuidv4 } from "uuid";
import { AntiRefundFollowUpQuestion } from "../../../oldReturnModals.types";
import { AntiRefundStrategiesReason } from "../AntiRefundStrategiesSlice/antiRefundStrategies.slice";
import antiRefundStrategiesOrdersBuilders from "./antiRefundStrategiesOrders.builder";
import antiRefundOrderTypesConfigReducers from "./antiRefundStrategiesOrders.reducers";

export interface IItem {
  productId: number | string;
  productName: string;
  productSku: string | null;
  imageUrl: string | null;
}

export interface IFollowUpQuestion {
  elementId: string;
  elementTypeId: string | number;
  elementValue: string;
  additionalOptions: { id: string; value: string }[];
  selectedValue: string[] | string;
  // selectedId: string[] | string;
}

export interface IRefundScenario {
  id: string;
  items: IItem[];
  followUpQuestions: IFollowUpQuestion[];
  enabled: boolean;
}

export interface IConditionTypes {
  id: string;
  ruleType: (typeof ruleTypes)[number];
  variableName: IVariableName;
  values: any;
  operator: string | undefined;
}

export interface IAntiRefundOrderType {
  orderTypeId: string | number;
  orderTypeName: string;
  // scenarios: IRefundScenario[];
  conditions: ConditionTypes[];
  // additionalInformation?: {
  //   enabled?: boolean;
  // };
  refundRequests: {
    reasons: AntiRefundStrategiesReason[];
  };
  errorCount: number;
  error: string | null;
  isAddConditionEnabled: boolean;
  isValid: boolean;
  applyToAllOrders: boolean; //Added as per new design
}

export interface IAntiRefundOrderTypeData {
  selectedOrderTypeId: string | number | null;
  antiRefundOrderTypes: Array<IAntiRefundOrderType>;
  selectedTabIntialHash: string | null;
  selectedTabCurrentHash: string | null;
  refundRequestFollowUpQuestions: AntiRefundFollowUpQuestion[];
  refundRequestReasons: AntiRefundStrategiesReason[];
  conditionOptions: conditionType[];
  defaultOptions: conditionType[];
  // itemOptions: conditionType[];
  operatorsNames: any;
  operatorSymbolMap: any;
  variableNames: any;
  valueTypes: any;
  variableNameToValueType: any;
}
export interface AntiRefundStrategiesOrdersState {
  antiRefundOrderTypesData: IAntiRefundOrderTypeData;
  error: string | null;
  initialHash: string;
  currentHash: string;
  fetchAjaxStatus: AJAXSTATUS;
  updateAjaxStatus: AJAXSTATUS;
  followUpQuestionsAjaxStatus: AJAXSTATUS;
  isChanged: boolean;
  excludeKeysForHash: string[];
  arsResponse: ARSResponse | null; // Used for return preview
}
const excludeKeys = [
  "error",
  "isAddConditionEnabled",
  "isValid",
  "errorCount",
  "itemConditionError",
  "isNewScenario",
];
const firstOrderTypeId = uuidv4();

export const initialState: AntiRefundStrategiesOrdersState = {
  isChanged: false,
  antiRefundOrderTypesData: {
    refundRequestFollowUpQuestions: [],
    refundRequestReasons: [],
    selectedOrderTypeId: firstOrderTypeId,
    antiRefundOrderTypes: [
      {
        orderTypeId: firstOrderTypeId,
        orderTypeName: "Order Type 1",
        errorCount: 0,
        error: null,
        isAddConditionEnabled: true,
        isValid: false,
        // scenarios: [],
        conditions: [],
        // additionalInformation: {},
        refundRequests: {
          reasons: [],
        },
        applyToAllOrders: true, //By default enabled all orders toggle button
      },
    ],
    selectedTabIntialHash: "",
    selectedTabCurrentHash: "",
    operatorsNames: {},
    variableNames: [],
    valueTypes: {},
    operatorSymbolMap: {},
    variableNameToValueType: {},
    conditionOptions: [],
    defaultOptions: [],
    // itemOptions: [],
  },
  excludeKeysForHash: excludeKeys,
  error: null,
  initialHash: "",
  currentHash: "",
  fetchAjaxStatus: "pending",
  updateAjaxStatus: "idle",
  followUpQuestionsAjaxStatus: "idle",
  arsResponse: null,
};

export const antiRefundStrategiesOrdersSlice = createSlice({
  name: "antiRefundStrategiesOrders",
  initialState,
  reducers: antiRefundOrderTypesConfigReducers,
  extraReducers: antiRefundStrategiesOrdersBuilders,
});

export const {
  setSelectedOrderTypeId,
  updateOrderType,
  pushCurrentHashForSelectedTab,
  validateChanges,
  addOrderType,
  // validateOrderType,
  validateCondition,
  addCondition,
  deleteCondition,
  updateCondition,
  deselectAllFollowUpQuestions,
  removeFollowUpQuestions,
  selectAllFollowUpQuestions,
  toggleAntiRefundMethodStep13,
  updateAdditionalInfo,
  updateGiveOffer,
  updateFollowUpQuestionSelectedValue,
  updateFollowUpQuestions,
  updateItemsAndTagsAllSelected,
  updateOfferExchange,
  updateRefundReasons,
  updateScenarioItems,
  updateScenarioTags,
  validateAntiRefundOrders,
  resetConfig,
  deleteOrderTypeTab,
  // setStep13OptionConditons,
  pushInitialHash,
  updateAdditionalInfoConfigOrderTypes,
  updateFollowUpQuestionsConfigOrderTypes,
  toggleAllOrder,
  updateArsResponse,
} = antiRefundStrategiesOrdersSlice.actions;

export default antiRefundStrategiesOrdersSlice.reducer;
