import { useCallback, useMemo, useState } from "react";
import { Dropdown } from "react-bootstrap";
import {
  CompareData,
  CompareDetail,
  CompareTypes,
  FieldDataTypes,
} from "src/services/CustomerSegments/getConditionOptions.service";
import styles from "../../UpdateSegmentDetails.module.scss";
import { useUpdateSegmentDetails } from "../../hooks/useUpdateSegmentDetails";

interface SelectedComparisonType {
  compareType: CompareTypes;
  compareKey?: string;
}

interface Props {
  options: Array<CompareDetail | CompareData>;
  conditionIndex: number;
  groupIndex: number;
}

const findSelectedComparisonTypeValue = ({
  selected,
  options,
}: {
  options: Array<CompareDetail | CompareData>;
  selected: SelectedComparisonType;
}): null | CompareDetail => {
  let option: null | CompareDetail = null;
  for (let i = 0; i < options.length; i++) {
    const value = options[i];
    if ((value as CompareDetail).compareType) {
      if (
        (value as CompareDetail).compareType === selected.compareType &&
        (selected.compareKey === undefined ||
          selected.compareKey === (value as CompareDetail).compareKey)
      ) {
        option = value as CompareDetail;
        break;
      }
    }
  }
  if (option) {
    return option;
  }
  for (let i = 0; i < options.length; i++) {
    const value = options[i];
    if ((value as CompareData).compareDetails) {
      option = findSelectedComparisonTypeValue({
        options: (value as CompareData).compareDetails,
        selected,
      });
      if (option) {
        break;
      }
    }
  }
  return option;
};

export const useSelectedComparisionType = ({
  options,
  conditionIndex,
  groupIndex,
}: Props) => {
  const { updateSegmentDetails, dispatch } = useUpdateSegmentDetails();
  const selected: SelectedComparisonType | null = useMemo(() => {
    const ret = ((updateSegmentDetails.segmentDetails.groupConditions ?? [])[
      groupIndex
    ]?.conditions ?? [])[conditionIndex];
    if (ret.compareType) {
      return {
        compareType: ret.compareType,
        compareKey: ret.compareKey,
      };
    } else {
      return null;
    }
  }, [updateSegmentDetails, conditionIndex, groupIndex]);

  const selectedComparisionType = useMemo(() => {
    return selected
      ? findSelectedComparisonTypeValue({ selected, options })
      : null;
  }, [options, selected]);

  return { selectedComparisionType };
};

interface PropsCompareType {
  option: CompareData | CompareDetail;
  selected: CompareDetail | null;
  onChange: React.MouseEventHandler<HTMLElement>;
}
const ComparisonType = ({ option, selected, onChange }: PropsCompareType) => {
  return (
    <div className="ps-2">
      {(option as CompareData).compareDetails ? (
        <div>
          <p className={`ps-0 mb-1 ${styles.dropTitle}`}>
            {(option as CompareData).groupName}
          </p>
          {(option as CompareData).compareDetails.map((optionH) => {
            return (
              <ComparisonType
                key={
                  (optionH as CompareData).compareDetails
                    ? (optionH as CompareData).groupName
                    : (optionH as CompareDetail).compareType
                }
                option={optionH}
                selected={selected}
                onChange={onChange}
              />
            );
          })}
        </div>
      ) : (option as CompareDetail).compareType ? (
        <span
          key={(option as CompareDetail).compareType}
          className={`ps-0 ${styles.dropItem}`}
          onClick={onChange}
          {...((option as CompareDetail).compareKey
            ? { "data-compare-key": (option as CompareDetail).compareKey }
            : {})}
          data-compare-type={(option as CompareDetail).compareType}
          data-field-data-type={(option as CompareDetail).fieldDataType}
        >
          {(option as CompareDetail).compareName}
        </span>
      ) : (
        ""
      )}
    </div>
  );
};

const ComparisonTypes = ({ options, conditionIndex, groupIndex }: Props) => {
  const { updateSegmentDetails, dispatch } = useUpdateSegmentDetails();
  const { selectedComparisionType } = useSelectedComparisionType({
    options,
    conditionIndex,
    groupIndex,
  });
  const [showMenu, setShowMenu] = useState(false);

  const handleChange: React.MouseEventHandler<HTMLElement> = useCallback(
    (e) => {
      const compareKey = e.currentTarget.getAttribute("data-compare-key");
      const compareType = e.currentTarget.getAttribute(
        "data-compare-type",
      ) as CompareTypes | null;
      const fieldDataType = e.currentTarget.getAttribute(
        "data-field-data-type",
      ) as FieldDataTypes | null;

      if (compareType) {
        dispatch("updateSegmentCondition", {
          conditionIndex,
          groupIndex,
          data: {
            compareKey: compareKey ? compareKey : undefined,
            compareType,
            fieldDataType: fieldDataType ? fieldDataType : undefined,
          },
        });
        setShowMenu(false);
      }
    },
    [
      selectedComparisionType,
      conditionIndex,
      groupIndex,
      dispatch,
      setShowMenu,
    ],
  );

  return (
    <div className={`me-md-2 ms-md-2 mb-2 mb-md-0 ${styles.resWidth}`}>
      <Dropdown
        show={showMenu}
        onToggle={(show) => setShowMenu(show)}
        className={`${styles.resWidth}`}
      >
        <Dropdown.Toggle
          id="dropdown-basic"
          as="div"
          className={`ms-md-2 ${styles.dropHead} ${
            selectedComparisionType?.compareName == null && styles.intialtext
          }`}
        >
          {selectedComparisionType?.compareName
            ? selectedComparisionType.compareName
            : "None"}

          <span className={`ps-2 ${styles.arrowDown}`}>
            <i
              className={`fa-solid fa-chevron-${showMenu ? "up" : "down"}`}
            ></i>
          </span>
        </Dropdown.Toggle>

        <Dropdown.Menu bsPrefix={`dropdown-menu ${styles.dropMenu}`}>
          {options.map((option) => {
            return (
              <ComparisonType
                key={
                  (option as CompareData).compareDetails
                    ? (option as CompareData).groupName
                    : (option as CompareDetail).compareType
                }
                option={option}
                selected={selectedComparisionType}
                onChange={handleChange}
              />
            );
          })}
        </Dropdown.Menu>
      </Dropdown>

      {updateSegmentDetails.showErrors &&
        !selectedComparisionType?.compareName && (
          <div className={styles.errText}>{"Please enter valid values!"}</div>
        )}
    </div>
  );
};
export default ComparisonTypes;
