import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import {
  areAllTicketsSelected,
  totalTicketsSelected,
  isTicketIdSelected,
  getTicketCellDataSelector,
  isColumnSelected,
  getSelectedColIdIndexForAllViews,
  updatedTicketsCount,
} from "./tickets.selectors";
import ticketsReducers from "./tickets.reducers";
import ticketsExtraReducers from "./tickets.extraReducers";
import ticketsThunk from "./tickets.thunk";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { Column } from "src/hooks/useTicketViews/ticketViewsConfig";
import { AJAXSTATUS } from "src/globals/constants";

export enum ESideBar {
  allTickets = "All Tickets",
  myTickets = "My Tickets",
  channels = "Channels",
  ticketStatus = "Ticket Status",
  draftStatus = "Draft Status",
  tickets = "Tickets",
}

export type CurrentColumnList = CurrentColumn[];

export type CurrentColumn = {
  key: string;
  name: string;
  show: boolean;
  checked: boolean;
};

export interface ITableFilters {
  tableType: string;
  table?: { [key: string | number]: any };
}

export interface RoutesColumn {
  [key: string]: Column[];
}

export type TicketSliceState = {
  allTickets: any;
  allTicketsIds: string[];
  currentView: string;
  totalTicketsCount: number;
  ticketsPerPageLimit: number;
  currentPage: number;
  totalPages: number;
  ticketsLoadingState: "pending" | "fulfilled" | "rejected";
  cachedTickets: {
    [page: number]: string[];
  };
  bulkSelectedTicketsIds: string[];
  bulkAddTicketTagsState: "pending" | "fulfilled" | "rejected";
  moreColsSearchValue: string;
  allColumnList: Column[];
  defaultColumnList: RoutesColumn;
  currColumnList: RoutesColumn;
  filterSearchString: string;
  ticketListUpdateAjaxStatus: AJAXSTATUS;
  moreColAjaxStatus: { [columnId: number]: AJAXSTATUS };
  //state for storing bulk action filters
  bulkActionFilters: {
    selectedAllTickets: boolean; //used for selecting all the tickets
    showSelectAllTicketModal: boolean; //used to show/hide select all ticket modal
  };
  bulkUpdatedTicketCounts: number; //to show updated ticket count in sucess modal of bulk action
};

export const initialState: TicketSliceState = {
  allTickets: {},
  allTicketsIds: [],
  currentView: "",
  totalTicketsCount: 0,
  ticketsPerPageLimit: 10,
  currentPage: 0,
  totalPages: 0,
  ticketsLoadingState: "pending",
  cachedTickets: {},
  bulkSelectedTicketsIds: [],
  bulkAddTicketTagsState: "pending",
  bulkActionFilters: {
    selectedAllTickets: false, //by default select all tickets is set to false
    showSelectAllTicketModal: false,
  },
  bulkUpdatedTicketCounts: 0,
  moreColsSearchValue: "",
  allColumnList: [],
  defaultColumnList: {},
  currColumnList: {},
  filterSearchString: "",
  ticketListUpdateAjaxStatus: "idle",
  moreColAjaxStatus: {},
};

export const fetchTickets = createAsyncThunk(
  "ticket/fetchAllTickets",
  ticketsThunk.fetchTicketsThunk,
);
export const fetchTicketUpdates = createAsyncThunk(
  "ticket/fetchTicketUpdates",
  ticketsThunk.fetchTicketsThunk,
);
export const fetchTicketsbyIds = createAsyncThunk(
  "ticket/fetchTicketsByIds",
  ticketsThunk.fetchTicketsByIdsThunk,
);
export const fetchMoreCol = createAsyncThunk(
  "ticket/fetchMoreColThunk",
  ticketsThunk.fetchMoreColThunk,
);

export const ticketSlice = createSlice({
  name: "ticket",
  initialState,
  reducers: ticketsReducers,
  extraReducers: (builder) => {
    /* 
    Reducers for fetching list of tickets based on input payload query
    */
    builder.addCase(
      fetchTicketUpdates.fulfilled,
      ticketsExtraReducers.fetchTicketsFulfilled,
    );
    builder.addCase(fetchTicketUpdates.pending, (state) => {
      state.ticketListUpdateAjaxStatus = "pending";
    });
    builder.addCase(fetchTicketUpdates.rejected, (state) => {
      state.ticketListUpdateAjaxStatus = "rejected";
    });
    builder.addCase(
      fetchTickets.fulfilled,
      ticketsExtraReducers.fetchTicketsFulfilled,
    );
    builder.addCase(fetchTickets.rejected, (state, { payload }) => {
      if (!payload) {
        state.ticketsLoadingState = "rejected";
        pushTheToast({
          type: "danger",
          text: "Failed to fetch tickets",
          position: "top-right",
        });
      }
    });
    builder.addCase(fetchTickets.pending, (state) => {
      state.ticketsLoadingState = "pending";
    });
    builder.addCase(
      fetchTicketsbyIds.fulfilled,
      ticketsExtraReducers.fetchTicketsByIdsFulfilled,
    );
    builder.addCase(
      fetchMoreCol.fulfilled,
      ticketsExtraReducers.fetchMoreColFulfilled,
    );
    builder.addCase(fetchMoreCol.pending, (state, action: any) => {
      state.moreColAjaxStatus[action.meta.arg.columnId] = "pending";
    });
    builder.addCase(fetchMoreCol.rejected, (state, { payload }: any) => {
      state.moreColAjaxStatus[payload.columnId] = "rejected";
    });
  },
});

export const {
  resetTickets,
  setCurrentPage,
  resetPagination,
  resetCachedTickets,
  selectTicketById,
  removeTicketById,
  selectAllTickets,
  removeAllTickets,
  resetMoreColumns,
  resetAllMoreColumns,
  setMoreColsSearchValue,
  setDefaultColumnList,
  setAllColumnList,
  setCurrColumnList,
  checkUncheckColumn,
  reorderCurrColumnList,
  updateTicketCellDataByKey,
  setFilterSearchString,
  setCurrentView,
  resetCurrentView,
  checkUncheckColumnIds,
  checkAllViewSelectedCols,
  resetBulkActionFilters,
  setBulkActionFilters,
  setBulkUpdatedTicketCount,
} = ticketSlice.actions;

export const isTicketIdSelectedSelector = createSelector(
  [isTicketIdSelected],
  (isTicketSelected) => isTicketSelected,
);

export const getSelectedColIdIndexForAllViewsSelector = createSelector(
  [getSelectedColIdIndexForAllViews],
  (getSelectedColIdIndexForAllViews) => getSelectedColIdIndexForAllViews,
);

export const areAllTicketsSelectedSelector = createSelector(
  [areAllTicketsSelected],
  (areAllTicketsSelected) => areAllTicketsSelected,
);

export const totalTicketsSelectedSeletector = createSelector(
  [totalTicketsSelected],
  (totalTicketsSelected) => totalTicketsSelected,
);

/**
 * selector to get updated tickets count for bulk action, which returns how many tickets has been updated successfully
 * these values set by "setBulkUpdatedTicketCount" reducer function which is called in required thunks|slice
 */
export const updatedTicketsCountSeletector = createSelector(
  [updatedTicketsCount],
  (updatedTicketsCount) => updatedTicketsCount,
);

const getAllColList = (state: any) => state.ticket.allColumnList;

const getAllTickets = (state: any) => state.ticket.allTickets;

export const selectTicketCellData = createSelector(
  getAllTickets,
  (_: any, ticketId: any) => ticketId,
  (_: any, __: any, colKeys: any) => colKeys,
  getTicketCellDataSelector,
);

const selectCurrColumnList = (state: any) => state.ticket.currColumnList;

export const isColumnSelectedSelector = createSelector(
  selectCurrColumnList,
  (_: any, viewType: {} | undefined) => viewType,
  (_: any, __: any, colKeys: {} | undefined) => colKeys,
  isColumnSelected,
);

export default ticketSlice.reducer;
