import { useEffect, useRef, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import {
  ActionMeta,
  GroupBase,
  MultiValue,
  OptionsOrGroups,
  SingleValue,
  components,
} from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import styles from "./CustomizeSelect.module.scss";

const selectStyles = {
  control: (provided: any) => ({
    ...provided,
    minWidth: 250,
    margin: "8px 0px",
    backgroundColor: "white",
    borderColor: "#707070",
    "&:hover": {
      borderColor: "#707070",
      boxShadow: "unset !important",
    },
  }),
  container: () => ({
    borderRadius: "8px",
    color: "black",
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: "500",
    padding: "0 17px",
    cursor: "pointer",
  }),
  menu: () => ({ boxShadow: "none", border: "none" }),
  option: (provided: any, state: { isFocused: any }) => ({
    ...provided,
    color: "black",
    backgroundColor: state.isFocused ? "lightGrey" : null,
  }),
};

interface Item {
  value: string;
  label: string;
  price: {
    amount: string;
    currencyCode: string;
  };
  imgSrc: string;
}

const Option = (props: any) => {
  return (
    <div>
      <components.Option {...props}>
        <div className="d-flex align-items-center gap-3">
          <div>
            <input
              type="checkbox"
              checked={props.isSelected}
              onChange={() => null}
              className="form-check-input"
              style={{
                width: "20px",
                height: "20px",
                borderRadius: "6px",
                margin: 0,
              }}
            />
          </div>
          {props.data.imgSrc === null ? (
            <></>
          ) : (
            <img
              alt=""
              src={props.data.imgSrc}
              style={{
                maxWidth: "35px",
                maxHeight: "32px",
                objectFit: "cover",
                alignSelf: "center",
                borderRadius: "5px",
              }}
            ></img>
          )}

          <div className="d-flex flex-column w-100 ">
            {/* Dec 25 changed rendering as price type should match type from backend */}
            {props.label !== null && (
              <label className={`${styles.optionCss}`}>{props.label}</label>
            )}
            {props.data.price !== null && (
              <>
                {props.data.price?.amount && props.data.price?.currencyCode && (
                  <>
                    <label className={`${styles.price}`}>
                      {props.data.price.amount} {props.data.price.currencyCode}
                    </label>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </components.Option>
    </div>
  );
};

export function usePrevious<T>(state: T): T | undefined {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = state;
  });

  return ref.current;
}

function CustomizeSelect(props: {
  id: string;
  condition: any;
  updateCondition: Function;
  error: string;
  allOptions: OptionsOrGroups<any, GroupBase<any>>;
  intialSelectedOptions: Array<number | string>;
  loadOptions: (
    searchTerm: string,
    loadOptions: any,
    additional: any,
  ) => Promise<{
    options: any;
    hasMore: boolean;
    additional: {
      page: any;
    };
  }>;
  showValidation?: boolean;
}) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [selectedOptions, setSelectedOptions] = useState<
    Array<number | string>
  >(props.intialSelectedOptions);

  const prevSelectedOptions = usePrevious(selectedOptions);
  const [isOptionsInitial, setIsOptionsInitial] = useState(true);

  useEffect(() => {
    if (isOptionsInitial && selectedOptions.length == 0) {
      return;
    }

    if (prevSelectedOptions?.length !== selectedOptions.length) {
      props.updateCondition(selectedOptions.filter((option) => option));
    }
    setIsOptionsInitial(false);
  }, [selectedOptions]);

  const isMulti = true;

  const onSelectChange = (
    newValues: SingleValue<any> | MultiValue<any>,
    actionMeta: ActionMeta<any>,
  ) => {
    let newSelectedOptions =
      (Array.isArray(newValues) ? [...newValues] : newValues) ?? [];

    let option: any = null;

    if (actionMeta.action === "remove-value") {
      option = actionMeta.removedValue;
    } else {
      option = actionMeta.option;
    }

    //If  For All Selected
    if (
      isMulti &&
      (newSelectedOptions as MultiValue<Item>).length &&
      (option as Item).value === "*"
    ) {
      if (actionMeta.action === "select-option") {
        setSelectedOptions(allOptions.map((option) => option.id));
      } else if (actionMeta.action === "deselect-option") {
        setSelectedOptions([]);
      }
    } else {
      if (!isMulti) {
        // Single value
        setSelectedOptions((newSelectedOptions && newSelectedOptions) || null);
      } else {
        // Multi value
        if (
          actionMeta.action === "remove-value" ||
          actionMeta.action === "deselect-option"
        ) {
          const allSelectedOptions = selectedOptions.filter(
            (optionId: any) => optionId != option?.id,
          );
          setSelectedOptions(allSelectedOptions);
        } else {
          if (option?.id) {
            const allSelectedOptions = Array.from(
              new Set([...selectedOptions, option?.id]),
            );
            setSelectedOptions(allSelectedOptions);
          }
        }
      }
    }
  };

  const allOptions = [
    { label: "Select all", value: "*", imgSrc: null },
    ...props.allOptions,
  ];

  let dropdownMessage = "Select items";
  if (selectedOptions.length === allOptions.length) {
    dropdownMessage = `all items selected`;
  } else if (selectedOptions.length >= 1) {
    dropdownMessage = `${selectedOptions.length} items selected`;
  }

  const [showPopover, setShowPopover] = useState(true);

  useEffect(() => {
    if (props.error) {
      setShowPopover(true);
    } else {
      setShowPopover(false);
    }
  }, [props.error]);

  const popover =
    props.error && props.error.length > 0 ? (
      <Popover
        id="popover-basic"
        className="rounded-3"
      >
        <Popover.Body className="d-flex flex-row">
          <div className="border px-2 bg-warning text-white me-1 rounded-3 ">
            !
          </div>
          <div className="mt-1">{props.error}</div>
        </Popover.Body>
      </Popover>
    ) : (
      <></>
    );

  return (
    <OverlayTrigger
      show={showPopover}
      placement="bottom"
      overlay={popover}
      rootClose={true}
      rootCloseEvent="mousedown"
      onToggle={(isShown) => {
        // Set the state variable based on the popover visibility
        if (!menuOpen) {
          setShowPopover(isShown);
        }
      }}
    >
      <div
        className={`ms-lg-2 ${styles.thirdSelect}`}
        onClick={() => setShowPopover(false)}
        style={{
          maxWidth: "20rem",
          minWidth: "16rem",
        }}
      >
        <Dropdown
          onToggle={(nextShow) => setMenuOpen(nextShow)}
          className={`${
            props.showValidation && "border border-danger rounded"
          }`}
        >
          <Dropdown.Toggle
            variant=""
            id="dropdown-basic"
            bsPrefix={`d-flex align-items-center justify-content-between ${
              styles.selectDropdown
            }  ${props.error ? "border border-danger" : ""}`}
          >
            <span
              className={
                selectedOptions.length !== allOptions.length &&
                selectedOptions.length === 0
                  ? styles.placeholder
                  : ""
              }
            >
              {" "}
              {dropdownMessage}{" "}
            </span>{" "}
            {/* Dec 15 changing dropdown icon as per xd */}
            <span
              className={`ps-2 pe-2 ${
                menuOpen ? styles.upArrow : styles.downArrow
              }`}
            >
              {" "}
              <i className="fa fa-caret-down"></i>
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu bsPrefix={`dropdown-menu ${styles.menuBox}`}>
            <AsyncPaginate
              autoFocus
              backspaceRemovesValue={false}
              components={{
                IndicatorSeparator: null,
                DropdownIndicator: null,
                Option,
              }} // Dec 15 remove dropdown icon as it is not in xd
              controlShouldRenderValue={false}
              hideSelectedOptions={false}
              isClearable={false}
              inputValue={searchInput}
              onInputChange={(inputValue, actionMeta) => {
                if (
                  actionMeta.action !== "input-blur" &&
                  actionMeta.action !== "menu-close" &&
                  actionMeta.action !== "set-value"
                ) {
                  setSearchInput(inputValue);
                }
              }}
              menuIsOpen={menuOpen}
              isMulti
              onChange={(
                newValue: MultiValue<any>,
                actionMeta: ActionMeta<any>,
              ) => {
                onSelectChange(newValue, actionMeta);
              }}
              placeholder="Search..."
              styles={selectStyles}
              tabSelectsValue={false}
              value={selectedOptions}
              loadOptions={props.loadOptions}
              isOptionSelected={(option: any, selectValue) => {
                if (selectedOptions.includes(option.id)) {
                  return true;
                }
                return false;
              }}
            />
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </OverlayTrigger>
  );
}

export default CustomizeSelect;
