/**
 * This is a react component file.
 *
 * @author Navjyot
 * @author Yash Aditya<yash@zinosi.com>
 */

import { useCallback, useMemo, useState } from "react";
import styles from "./RequestBox.module.scss";
import returnImg from "src/assets/images/auto2.png";
import orderImg from "src/assets/images/orderMonitor.png";
import warranty from "src/assets/images/WarrantyIssues.png";
import modification from "src/assets/images/modification.png";
import orderTrack from "src/assets/images/auto3.png";
import reqCmngSnFeatureService, {
  ComingSoonFeatureTypes,
} from "src/services/ComingSoon/reqCmngSnFeature.service";
import { useQuery } from "@tanstack/react-query";
import getCmngSnRequestedService from "src/services/ComingSoon/getCmngSnFeature.service";
import Loader from "src/components/Loader";
import { Modal, Spinner } from "react-bootstrap";
import RequestModal, { RequestModalTypes } from "./RequestModal/RequestModal";
import FeatureBox from "./RequestModal/FeatureBox/FeatureBox";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";

// Define Props interface
interface Props {
  activePath: ComingSoonFeatureTypes;
}

// Main component definition
const RequestBox = ({ activePath }: Props) => {
  // State to render modal.
  const [showModal, setShowModal] = useState(null as RequestModalTypes);

  // Query to check if the feature has been requested
  const {
    data: requestedFeaturesArray,
    status,
    refetch,
  } = useQuery(["getCmngSnRequestedService", activePath], {
    queryFn: getCmngSnRequestedService,
    staleTime: 0,
    cacheTime: 0,
  });

  const requestedFeatures = useMemo(
    () => requestedFeaturesArray ?? [],
    [requestedFeaturesArray]
  );

  // Memoize image source based on activePath
  const requestImg = useMemo(() => {
    return activePath === "return"
      ? returnImg
      : activePath === "monitoring"
      ? orderImg
      : activePath === "tracking"
      ? orderTrack
      : activePath === "warranty"
      ? warranty
      : activePath === "modification"
      ? modification
      : "";
  }, [activePath]);

  // Memoize style based on activePath
  const style = useMemo(() => {
    return activePath === "return"
      ? { width: "33px", height: "33px" }
      : activePath === "monitoring"
      ? { width: "44px", height: "44px" }
      : activePath === "tracking"
      ? { width: "45px", height: "45px" }
      : activePath === "warranty"
      ? { width: "44px", height: "44px" }
      : { width: "44px", height: "44px" };
  }, [activePath]);

  // Callback function to request early access
  const requestAccess = useCallback(
    (selectedItems: Array<ComingSoonFeatureTypes>) => {
      setShowModal("loading");
      reqCmngSnFeatureService({ context: selectedItems })
        .then(() => {
          refetch();
          setShowModal("requested");
        })
        .catch((err) => {
          console.error(err);
          pushTheToast({
            type: "danger",
            text: "Failed to request early access!",
            position: "top-right",
          });
          setShowModal("makeRequest");
        });
    },
    [refetch]
  );

  // Function to show the request access modal.
  const handleRequestAccess = useCallback(() => {
    setShowModal("makeRequest");
  }, []);

  // Function to close the modal
  const handleModalClose = useCallback(() => {
    if (showModal !== "loading") {
      setShowModal(null);
    }
  }, [showModal]);

  // Render based on query status and request status
  if (status === "error") {
    return (
      <div className="d-flex text-center text-danger justify-content-center align-items-center align-middle">
        Error loading content...
      </div>
    );
  }

  if (status === "loading") {
    return (
      <div className="d-flex text-center text-danger justify-content-center align-items-center align-middle">
        <Loader />
      </div>
    );
  }

  // Render the main component with early access information

  return (
    <>
      <div className={`${styles.requestBox} text-center`}>
        <img
          src={requestImg}
          alt=""
          className={`mx-auto ${styles.requestImg}`}
          style={style}
        />
        <h3 className={`${styles.heading}`}>
          {!requestedFeatures.includes(activePath)
            ? `This feature is in beta. Sign up for early access right now!`
            : `You have already requested early access to this feature.`}
        </h3>
        {!requestedFeatures.includes(activePath) && (
          <>
            <span
              className={`${styles.subHeading}`}
            >{`Request early access now and we will add you to our priority list.`}</span>
            <button
              className={`mx-auto ${styles.requestBtn} cursor-pointer`}
              onClick={handleRequestAccess}
            >
              <span className="pe-1">
                <i className="fa-regular fa-clock"></i>
              </span>
              Request early access
            </button>
          </>
        )}
      </div>
      <Modal
        backdropClassName={`${styles.modalBack}`}
        show={showModal !== null}
        onHide={handleModalClose}
        dialogClassName={`${styles.modalDialog}`}
        contentClassName={`${styles.modalContent}`}
        enforceFocus={false}
      >
        <div className="d-flex">
          <RequestModal
            onHide={handleModalClose}
            requestedFeatures={requestedFeatures}
            showModal={showModal}
            requestAccess={requestAccess}
          />
        </div>
      </Modal>
    </>
  );
};

export default RequestBox;
