import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { Modal } from "react-bootstrap";

import VerifyingTheConnection from "../AddEmail/Children/AddModal/VerifyingTheConnection/VerifyingTheConnection";
import CouldntVerifyModal from "../AddEmail/Children/AddModal/CouldntVerifyModal/CouldntVerifyModal";
import HasBeenVerified from "../AddEmail/Children/AddModal/HasBeenVerified/HasBeenVerified";

import { fetchVerifyEmailForwarding } from "src/store/slices/emailIntegration/emailIntegration.thunks";
import { actions } from "src/store/slices/emailIntegration/emailIntegration.slices";

import copy from "src/assets/images/copyMail.png";
import styles from "./EmailForwarding.module.scss";

const EmailForwarding = ({
  isEmailToDo = false,
  hideElements,
}: {
  isEmailToDo?: boolean;
  hideElements?: (val: boolean) => void;
}) => {
  const [show, setShow] = useState(false);

  const toggleSetup = () => {
    setShow(!show);
  };

  const dispatch = useAppDispatch();

  const [copySEmail, setCopied] = useState(false);

  const supportEmail = useAppSelector((state) => {
    return state.emailIntegration.selectedUser.defaultEmail;
  });

  const emailEnteredByUser = useAppSelector((state) => {
    return state.emailIntegration.selectedUser.emailEnteredByUser;
  });

  const isEmailVerified = useAppSelector((state) => {
    return state.emailIntegration.selectedUser.isEmailVerified;
  });

  const [verifyEmailShowModal, setIsVerifyingEmailShowModal] = useState(false);
  const [couldntVerifyShowModal, SetCouldntVerifyShowModal] = useState(false);
  const [emailHasBeenVerified, setEmailHasBeenVerified] = useState(false);

  const verifyEmailLoading = useAppSelector(
    (state) => state.emailIntegration.verifyEmailLoading,
  );

  useEffect(() => {
    setIsVerifyingEmailShowModal(verifyEmailLoading === "pending");
  }, [verifyEmailLoading]);

  useEffect(() => {
    if (verifyEmailLoading === "fulfilled") {
      SetCouldntVerifyShowModal(false);
      setIsVerifyingEmailShowModal(false);
      setEmailHasBeenVerified(true);
      dispatch(actions.setverifyEmailLoadingIdle());
      if (hideElements) {
        hideElements(true);
      }
    } else if (verifyEmailLoading === "rejected") {
      setEmailHasBeenVerified(false);
      setIsVerifyingEmailShowModal(false);
      SetCouldntVerifyShowModal(true);
      if (hideElements) {
        hideElements(true);
      }
      dispatch(actions.setverifyEmailLoadingIdle());
    } else if (verifyEmailLoading === "pending") {
      setEmailHasBeenVerified(false);
      SetCouldntVerifyShowModal(false);
      setIsVerifyingEmailShowModal(true);
      if (hideElements) {
        hideElements(true);
      }
    }
  }, [dispatch, hideElements, verifyEmailLoading]);

  const copyToClipBoard = useCallback(async (copyMe: string) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 5000);
    } catch (err) {}
  }, []);

  const selectedEmailId = useAppSelector((state) => {
    return state.emailIntegration.selectedUser.emailIntegrationId;
  });

  const channelName = useAppSelector((state) => {
    return state.emailIntegration.selectedUser.emailIntegrationChannel;
  });

  const [emailVerification, setEmailVerification] =
    useState<boolean>(isEmailVerified);

  const successCallback = useCallback(() => {
    setEmailHasBeenVerified(false);
    dispatch(actions.emailVerified());
    setEmailVerification(true);
    if (hideElements) {
      hideElements(false);
    }
  }, [dispatch, hideElements]);

  const failedCallback = useCallback(() => {
    SetCouldntVerifyShowModal(false);
    setEmailVerification(true);
    if (hideElements) {
      hideElements(false);
    }
  }, [hideElements]);

  if (isEmailToDo && verifyEmailShowModal) {
    return (
      <div className={`my-5 py-5`}>
        <VerifyingTheConnection
          text="connection"
          subText="We are sending a test email to your support address to verify if the emails are being received in Saufter. It can take about a minute."
        />
      </div>
    );
  }

  if (isEmailToDo && couldntVerifyShowModal) {
    return (
      <div className={`my-5 py-5`}>
        <CouldntVerifyModal
          onHide={failedCallback}
          showVerifyModal={setIsVerifyingEmailShowModal}
          verifyText="your email"
          setupName="email forwarding"
        />
      </div>
    );
  }

  if (isEmailToDo && emailHasBeenVerified) {
    return (
      <div className={`my-5 py-5`}>
        <HasBeenVerified
          onHide={successCallback}
          text={"Email"}
          hideCloseButton={true}
          subText={
            <div className="text-muted">
              All mails from{" "}
              <span className="text-dark">
                {" "}
                <b>{emailEnteredByUser}</b>{" "}
              </span>{" "}
              will now be forwarded to
              <span className="text-dark">
                {" "}
                <b>{supportEmail} </b>{" "}
              </span>
            </div>
          }
        />
      </div>
    );
  }

  return (
    <>
      <div
        className={`ms-2 pt-4 ms-lg-2 me-0 me-lg-4`}
        style={{ width: isEmailToDo ? "100%" : "90%" }}
      >
        <div className={`${styles.emailForward}`}>
          <div className={`py-2 ${styles.emailHead}`}>
            <span className={`py-1 px-2 `}>Let's setup Email Forwarding </span>
          </div>
          <div className={`pt-2`}>
            <div>
              <div className="mb-1">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label px-3"
                >
                  <span className={`${styles.adreess}`}>
                    Forward your email to the address below:
                  </span>
                </label>
                <div className={`ps-3 me-3 position-relative`}>
                  <input
                    type="email"
                    className={`form-control ${styles.mailInput} `}
                    id="emailInput"
                    aria-describedby="emailHelp"
                    placeholder="support@emails.helplama.com"
                    value={supportEmail}
                    disabled={isEmailVerified}
                  />
                  <div
                    className={`${styles.copyMain}`}
                    onClick={() => copyToClipBoard(supportEmail)}
                    id="copyEmail"
                  >
                    {copySEmail ? (
                      <button className={`py-1 ${styles.copiedBtn}`}>
                        <img
                          src={copy}
                          alt=""
                        />{" "}
                        Copied !
                      </button>
                    ) : (
                      <button className={`py-1 ${styles.copyBtn}`}>
                        <img
                          src={copy}
                          alt=""
                        />{" "}
                        Copy Email
                      </button>
                    )}
                  </div>
                </div>
                <div
                  className={`mt-3 pb-3 d-flex flex-column flex-md-row align-items-center ${styles.emailHead}`}
                >
                  <button
                    className={`px-3 mx-3 py-1 px-2 ${styles.verifyBtn}`}
                    disabled={isEmailVerified}
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(
                        fetchVerifyEmailForwarding({
                          forwardingParams: {
                            emailIntegrationId: selectedEmailId,
                          },
                          eventParams: {
                            channelName: channelName,
                          },
                        }),
                      );
                    }}
                    id="verifyEmailForwading"
                  >
                    Verify Email Forwarding
                  </button>
                  {emailVerification && (
                    <div className="ms-1 mt-2 mt-md-0 d-flex align-items-center">
                      {isEmailVerified ? (
                        <div className={`${styles.successCheck}`}>
                          <span>
                            <i className="fa-solid fa-check"></i>
                          </span>
                        </div>
                      ) : (
                        <div className={`${styles.failedCheck}`}>
                          <span>
                            <i className="fa-solid fa-close"></i>
                          </span>
                        </div>
                      )}
                      <span className={`ms-2 ${styles.statusText}`}>
                        {isEmailVerified
                          ? "Verified !"
                          : "Verification failed !"}
                      </span>
                    </div>
                  )}
                </div>
                <div className={`px-3 py-2  ${styles.setupBox}`}>
                  <div
                    className={`cursor-pointer d-flex justify-content-between`}
                    onClick={toggleSetup}
                    id="toggleSetup"
                  >
                    <span className={`pe-2 ${styles.setupSpan}`}>
                      How To Setup and Verify Email Forwarding?
                    </span>
                    <span className={`cursor-pointer ${styles.setupSpan}`}>
                      <i
                        className={
                          show
                            ? "fa-solid fa-chevron-up"
                            : "fa-solid fa-chevron-down"
                        }
                      ></i>
                      {/* <i className="fa-solid fa-chevron-up"></i> */}
                    </span>
                  </div>
                </div>

                {show && (
                  <div className={`px-3 py-2`}>
                    <div className={`d-flex`}>
                      <span
                        className={`mb-1 ${styles.stepSpan} ${styles.stepDiv}`}
                      >
                        Step 1 :
                      </span>
                      <span
                        className={` mb-1 ${styles.stepSpan}  ${styles.msgDiv}`}
                      >
                        Copy your default Helplama email that is provided above
                        .
                      </span>
                    </div>
                    <div className={`d-flex`}>
                      <span className={` ${styles.stepSpan} ${styles.stepDiv}`}>
                        Step 2 :
                      </span>
                      <span
                        className={`mb-1 ${styles.stepSpan} ${styles.msgDiv}`}
                      >
                        Go to your email provider page . Look for the option to
                        add forwarding E-mails. (Most providers will have
                        instructions on how to perform email forwarding in their
                        help center.)
                      </span>
                    </div>
                    <div className={`d-flex`}>
                      <span
                        className={`mb-1 ${styles.stepSpan} ${styles.stepDiv}`}
                      >
                        Step 3 :
                      </span>
                      <span
                        className={`mb-1 ${styles.stepSpan}  ${styles.msgDiv}`}
                      >
                        Paste the copied Helplama email at your e-mails
                        provider's E-mail forwarding option . Don't Forget to
                        save these changes.
                      </span>
                    </div>
                    <div className={`d-flex`}>
                      <span
                        className={`mb-1 ${styles.stepSpan} ${styles.stepDiv}`}
                      >
                        Step 4 :
                      </span>
                      <span
                        className={`mb-1 ${styles.stepSpan}  ${styles.msgDiv}`}
                      >
                        After completing the process in your email provider
                        page. Navigate back to the Helplama tab.
                      </span>
                    </div>
                    <div className={`d-flex`}>
                      <span
                        className={`mb-1 ${styles.stepSpan} ${styles.stepDiv}`}
                      >
                        Step 5 :
                      </span>
                      <span
                        className={`mb-1 ${styles.stepSpan}  ${styles.msgDiv}`}
                      >
                        Click Verify email forwarding button, this might take a
                        minute to verify.
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {!isEmailToDo && (
          <Modal
            backdropClassName={`${styles.modalBack}`}
            show={verifyEmailShowModal}
            dialogClassName={`${styles.modalDialog}`}
            contentClassName={`${styles.modalContent}`}
            centered={true}
            enforceFocus={false}
          >
            <VerifyingTheConnection
              text="connection"
              subText="We are sending a test email to your support address to verify if the emails are being received in Saufter. It can take about a minute."
            />
          </Modal>
        )}

        {!isEmailToDo && (
          <Modal
            backdropClassName={`${styles.modalBack}`}
            show={couldntVerifyShowModal}
            dialogClassName={`${styles.modalDialog}`}
            contentClassName={`${styles.modalContent}`}
            centered={true}
            enforceFocus={false}
            onHide={failedCallback}
          >
            <CouldntVerifyModal
              onHide={failedCallback}
              showVerifyModal={setIsVerifyingEmailShowModal}
              verifyText="your email"
              setupName="email forwarding"
            />
          </Modal>
        )}

        {!isEmailToDo && (
          <Modal
            backdropClassName={`${styles.modalBack}`}
            show={emailHasBeenVerified}
            dialogClassName={`${styles.modalDialog}`}
            contentClassName={`${styles.modalContent}`}
            centered={true}
            enforceFocus={false}
            onHide={successCallback}
          >
            <HasBeenVerified
              onHide={successCallback}
              text={"Email"}
              subText={
                <div className="text-muted">
                  All mails from{" "}
                  <span className="text-dark">
                    {" "}
                    <b>{emailEnteredByUser}</b>{" "}
                  </span>{" "}
                  will now be forwarded to
                  <span className="text-dark">
                    {" "}
                    <b>{supportEmail} </b>{" "}
                  </span>
                </div>
              }
            />
          </Modal>
        )}
      </div>
    </>
  );
};
export default EmailForwarding;
