import { Channel } from "pusher-js";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RouteNames } from "src/containers/SideBar/SideBar";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import {
  getAgentChatEndedEvent,
  getChatEndedEvent,
} from "src/pusherServices/livechat/chatEndedEvent";
import { getNewlyConnectedCustomerEvent } from "src/pusherServices/livechat/customerConnectedEvent";
import { getLiveMessageRecEvent } from "src/pusherServices/livechat/messageEvents";
import {
  Customer,
  Customers,
  clearGetAllCustomersCache,
  getAllCustomers,
} from "src/services/LiveChat/messageExchange/getAllCustomers";
import { Message } from "src/services/LiveChat/messageExchange/getMessages";
import { actions } from "src/store/slices/liveChatSetting/chatWidExchCust/chatWidExchCust.slice";
import {
  fetchAgentsInfoByIds,
  fetchCustomerDataByChatId,
  fetchLiveChatSidebar,
  markMessageAsSeen,
} from "src/store/slices/liveChatSetting/chatWidExchCust/chatWidExchCust.thunks";
import {
  addNewMessageChatWidExchMsg,
  markMessagesSeen,
  setActiveCustomerInfo,
  updateCustomerInfo,
} from "src/store/slices/liveChatSetting/chatWidExchMsg/chatWidExchMsg.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { getMessageReadEvent } from "src/pusherServices/livechat/messageReadEvent";
import { convertHTMLToText, pushNotification } from "src/utils/utils";
import { getLiveChatRequestWaitingForReplySinceEvent } from "src/pusherServices/livechat/liveChatWaitingForReplySinceEvent";
import { getLiveChatUpdatedEvent } from "src/pusherServices/livechat/chatUpdatedEvent";
import { agentAssignedChatEvent } from "src/pusherServices/livechat/agentAssignedChatEvent";
import { agentRemovedFromAssignedChatEvent } from "src/pusherServices/livechat/agentRemovedFromAssignedChatEvent";
import { fetchLiveChatMessgeUpdates } from "src/store/slices/liveChatSetting/chatWidExchMsg/chatWidExchMsg.thunks";
import { GET_TAB_FOCUSED } from "src/globals/globalFunctions";
import { chatOnlineStatusUpdatedEvent } from "src/pusherServices/livechat/chatOnlineStatusEvent";
import { useQueuedChatRecurringNotification } from "./useQueuedChatNotification";
import { AJAXSTATUS } from "src/globals/constants";
import {
  ChatCountUpdatedData,
  ChatCountUpdatedEventCB,
  chatCountUpdatedEvent,
} from "src/pusherServices/livechat/chatCountUpdatedEvent";
import { setUnreadChatIdsForTitle } from "src/store/slices/globals/globals.slice";
import { removeCustomerByChatIdCache } from "src/services/LiveChat/messageExchange/getCustomerByChatId";

interface IWaitingForReplyDataItem {
  chatId: string | number;
  customerId: string | number;
  isCustomerWaitingForReply: boolean;
  customerWaitingForReplySince: string | null;
}

const getFormTitle = (formType: string | undefined) => {
  if (formType === "preChat") {
    return "Prechat form";
  } else if (formType === "askForEmail") {
    return "Email form";
  } else if (formType === "ticketForm") {
    return "Ticket form";
  }

  return "Prechat form";
};

export const useLiveChatMessageBinder = () => {
  const [privatePusherChannel, setPrivatePusherChannel] = useState(null as any);
  const [globalPusherChannel, setGlobalPusherChannel] = useState(null as any);
  const navigate = useNavigate();

  const {
    selectedCustomerChatId,
    chatIdList,
    customers,
    selectedCustomerStatus,
    sidebar,
  } = useAppSelector((state) => state.chatWidExchCust);
  const {
    activeCustomerInfo,
    messageIdList,
    metaData: msgMetaData,
  } = useAppSelector((state) => state.chatWidExhMsg);
  const { currentUserData, unreadChatIds } = useAppSelector(
    (state) => state.globals,
  );
  const [queuedChats, setQueuedChats] = useState<{
    [key: string | number]: Customer;
  }>({});
  const { refetch, removeChatFromNotifications } =
    useQueuedChatRecurringNotification();
  const location = useLocation();

  const currentState = useRef({
    selectedCustomerChatId: selectedCustomerChatId,
    location: location.pathname,
    chatIdList: chatIdList,
    customers: customers,
    activeCustomerInfo: activeCustomerInfo,
    selectedCustomerStatus: selectedCustomerStatus,
    currentUserData: currentUserData,
    unreadChatIds: unreadChatIds,
    messageIdList: messageIdList,
    msgMetaData: msgMetaData,
  });

  const liveChatSideBarAjaxStatusRef = useRef<AJAXSTATUS>("idle");

  const liveChatRoute = `${RouteNames.liveChat}/chats`;

  const dispatch = useAppDispatch();

  useEffect(() => {
    currentState.current = {
      selectedCustomerChatId: selectedCustomerChatId,
      location: location.pathname,
      chatIdList: chatIdList,
      customers: customers,
      activeCustomerInfo: activeCustomerInfo,
      selectedCustomerStatus: selectedCustomerStatus,
      currentUserData: currentUserData,
      unreadChatIds: unreadChatIds,
      messageIdList: messageIdList,
      msgMetaData: msgMetaData,
    };
  }, [
    selectedCustomerChatId,
    location.pathname,
    chatIdList,
    customers,
    activeCustomerInfo,
    selectedCustomerStatus,
    currentUserData,
    unreadChatIds,
    messageIdList,
    msgMetaData,
  ]);

  useEffect(() => {
    if (sidebar?.liveChatIds !== undefined && sidebar?.liveChatIds !== null) {
      dispatch(
        setUnreadChatIdsForTitle({
          chatIds: sidebar.liveChatIds,
        }),
      );
    }
  }, [sidebar?.liveChatIds]);

  // Defining a function named updateLiveChatSidebar using React's useCallback hook
  const updateLiveChatSidebar = useCallback(() => {
    // Checking if the current Ajax status is not "pending"
    if (liveChatSideBarAjaxStatusRef.current !== "pending") {
      // Setting the Ajax status to "pending" to indicate that a request is in progress
      liveChatSideBarAjaxStatusRef.current = "pending";

      // Dispatching an action to fetch live chat sidebar data
      // dispatch(fetchLiveChatSidebar()).finally(() => {
      //   // Setting the Ajax status to "fulfilled" after the request is complete, regardless of success or failure
      //   liveChatSideBarAjaxStatusRef.current = "fulfilled";
      // });
    }
  }, []); // Empty dependency array indicates that this callback has no dependencies

  const liveMsgCallback = (res: any) => {
    if (res) {
      //added condition to check for chat id or session id as required input
      if (res?.chat_id || res?.session_id) {
        let chatId = res?.chat_id;
        let brandId = res?.brandId;
        const notification = res?.notification;
        let notificationEnabled = true;
        if (notification?.enabled === false) {
          notificationEnabled = false;
        }

        if (
          brandId &&
          currentState.current.currentUserData?.disabledBrandIds &&
          currentState.current.currentUserData?.disabledBrandIds.includes(
            parseInt(brandId + ""),
          )
        ) {
          //ignore the event, this brand is disabled
          return;
        }

        if (
          currentState.current.chatIdList.includes(chatId + "") ||
          currentState.current.selectedCustomerChatId == chatId
        ) {
          removeCustomerByChatIdCache(chatId);
        }

        // if (!currentState.current.unreadChatIds?.includes(res?.chat_id)) {
        // updateLiveChatSidebar();
        // }
        //getting the sessionid from event response
        const sessionId = res?.session_id ?? null;
        //getting the flag for the session event identification
        const isSessionEvent = res?.is_session_event ?? false;

        if (res.data.messageId) {
          const messageData: Message = {
            messageId: res.data.messageId,
            messageText: res.data.message,
            sentBy: {
              id: res?.data?.sentBy?.id,
              name: res?.data?.sentBy?.name,
              email: res?.data?.sentBy?.email,
              imageURL: res?.data?.sentBy?.imageURL,
            },
            sendTime: res.data.messageCreatedAt,
            sendTimeGmt: res.data.messageCreatedAtGmt,
            isEvent: false,
            sentByType: res.data.sentByType,
            senderType: res.data.senderType,
            isInternalNote:
              res.data.isInternalNote && res.data.isInternalNote == true
                ? true
                : false,
            messageType: res.data?.messageType,
            formDetails: res.data?.formDetails,
            eventDetails: res.data?.eventDetails,
            messageSeenStatus: res.data?.messageSeenStatus, // added for message seen status in message object
          };

          if (messageData.eventDetails?.type === "event_chat_resolved") {
            updateLiveChatSidebar();
          }

          //checking if current url is live chat url or not
          if (
            currentState.current?.location &&
            currentState.current.location.includes(liveChatRoute)
          ) {
            let fetchUpdateForSession = false;
            //checking if event is session event
            if (isSessionEvent === true && sessionId) {
              //parsing the current chat session id from channel name string
              let splitedArray =
                currentState.current.activeCustomerInfo?.channelName.split(
                  "session-",
                );
              const currentChatSessionId =
                splitedArray && splitedArray.length !== 0
                  ? splitedArray[1]
                  : null;
              //checking if session id matches current chat session id
              if (currentChatSessionId == sessionId) {
                //session id matches , so fetch the message updates
                fetchUpdateForSession = true;
                chatId = currentState.current.selectedCustomerChatId;
              }
            }

            if (
              fetchUpdateForSession === true ||
              chatId == currentState.current?.selectedCustomerChatId
            ) {
              // dispatch(
              //   addNewMessageChatWidExchMsg({ messageData: messageData })
              // );

              if (notificationEnabled) {
                //if message already exists ignore
                if (
                  (currentState.current.messageIdList.includes(
                    messageData.messageUuid + "",
                  ) ||
                    currentState.current.messageIdList.includes(
                      messageData.messageId,
                    )) &&
                  messageData.senderType?.toLowerCase() === "agent"
                ) {
                  return;
                }
                const callbackNotify = () => {
                  if (
                    (messageData.messageType?.toLowerCase() === "message" ||
                      messageData?.messageType === "chatForm") &&
                    // !GET_TAB_FOCUSED() &&
                    messageData.senderType.toLowerCase() !== "agent"
                  ) {
                    pushNotification({
                      title:
                        "New Message From " + messageData.sentBy.name + "!",
                      options: {
                        body: convertHTMLToText(messageData.messageText),
                        tag: `NewMessage::${chatId}::${messageData.messageId}`,
                      },
                      soundType: "NewMessageSound",
                      callback: () => {
                        navigate(
                          window.location.pathname.replace(
                            window.location.pathname,
                            liveChatRoute +
                              `/${
                                res?.chatStatus ?? "live"
                              }/${chatId}/chattingTab/conversation`,
                          ),
                        );
                      },
                    });
                  }
                };
                // Only fetch when all data loaded in bottom
                if (currentState.current.msgMetaData.limitReachedNew === true) {
                  dispatch(
                    fetchLiveChatMessgeUpdates({
                      chatStatus: res?.chatStatus ?? "live",
                      callback: callbackNotify,
                    }),
                  );
                } else {
                  // Otherwise call the notification.
                  callbackNotify();
                }
              } else {
                //if message already exists ignore
                if (
                  (currentState.current.messageIdList.includes(
                    messageData.messageUuid + "",
                  ) ||
                    currentState.current.messageIdList.includes(
                      messageData.messageId,
                    )) &&
                  messageData.senderType?.toLowerCase() === "agent"
                ) {
                  return;
                }
                // Only fetch when all data loaded in bottom
                if (currentState.current.msgMetaData.limitReachedNew === true) {
                  // Added chat status
                  dispatch(
                    fetchLiveChatMessgeUpdates({
                      chatStatus: res?.chatStatus ?? "live",
                    }),
                  );
                } else {
                  //otherwise fetch the customer data to update active customer
                  if (chatId) {
                    dispatch(
                      fetchCustomerDataByChatId({
                        payload: chatId,
                      }),
                    );
                  }
                }
              }

              //27 Dec commented this as it is executing on create ticket,and this already handled on other place
              // Check if the received message has a different chatStatus than the currently selected customer status
              // if (
              //   res?.chatStatus !== currentState.current.selectedCustomerStatus
              // ) {
              //   console.log("new message archived")
              //   let currentChatId = res?.chat_id;
              //   let currentChatStatus =
              //     currentState.current.selectedCustomerStatus;
              //   let customer;
              //   if (currentChatId) {
              //     let previousChatId: number | null = null;
              //     //Get the customer details with new message chatId
              //     chatIdList.map((chatId) => {
              //       customer = customers[chatId];
              //     });
              //     //Remove the chatId from the list of current chatids in sidebar
              //     const chatIds = currentState.current.chatIdList.filter(
              //       (chatIds, index) => {
              //         if (currentChatId != chatIds) {
              //           return true;
              //         } else {
              //           previousChatId =
              //             index === 0
              //               ? null
              //               : parseInt(
              //                   currentState.current.chatIdList[index - 1] + ""
              //                 );
              //           return false;
              //         }
              //       }
              //     );
              //     //Remove the customer from sidebar
              //     dispatch(
              //       actions.removeCustomerFromChatList({
              //         chatId: parseInt(currentChatId + ""),
              //       })
              //     );
              //     //Get the chatId which needs to be active after removing current chatId
              //     const redirectChatId =
              //       previousChatId && previousChatId !== null
              //         ? previousChatId
              //         : chatIds[0];
              //     // Set the active chat ID and customer info
              //     dispatch(actions.setActiveChatId({ id: redirectChatId }));
              //     if (customer) {
              //       dispatch(setActiveCustomerInfo({ customerInfo: customer }));
              //     }
              //     // Update the URL and navigate to the appropriate chat
              //     navigate(
              //       window.location.pathname.replace(
              //         `chats/${currentChatStatus}/${currentChatId}`,
              //         `chats/${currentChatStatus}/${redirectChatId}`
              //       )
              //     );
              //   }
              // }
            } else {
              //This will execute when new message is not from the active chat

              //check for the new message status and selected customer status and it's not ticket created event
              if (
                res?.chatStatus !==
                  currentState.current.selectedCustomerStatus &&
                !messageData.isEvent
              ) {
                //remove the chat from list in sidebar
                dispatch(
                  actions.removeCustomerFromChatList({
                    chatId: parseInt(res?.chat_id + ""),
                  }),
                );

                // Clear the cache for all customers api call to refetch on changing tabs
                clearGetAllCustomersCache();
                if (
                  res?.chat_id === currentState.current.selectedCustomerChatId
                ) {
                  removeCustomerByChatIdCache(res?.chat_id + "");
                }
              }
              if (
                messageData?.senderType &&
                messageData.senderType.toLowerCase() === "agent"
              ) {
                dispatch(
                  fetchCustomerDataByChatId({
                    payload: chatId,
                    chatStatus: "live",
                  }),
                );
              } else if (
                messageData.messageType === undefined ||
                messageData.messageType?.toLowerCase() === "message"
              ) {
                if (notificationEnabled) {
                  dispatch(
                    fetchCustomerDataByChatId({
                      chatStatus: "live",
                      payload: chatId,
                      callback: () => {
                        pushNotification({
                          title:
                            "New Message From " + messageData.sentBy.name + "!",
                          options: {
                            body: convertHTMLToText(messageData.messageText),
                            tag: `NewMessage::${chatId}::${messageData.messageId}`,
                          },
                          soundType: "NewMessageSound",
                          callback: () => {
                            navigate(
                              window.location.pathname.replace(
                                window.location.pathname,
                                liveChatRoute +
                                  `/${
                                    res?.chatStatus ?? "live"
                                  }/${chatId}/chattingTab/conversation`,
                              ),
                            );
                          },
                        });
                      },
                    }),
                  );
                } else {
                  dispatch(
                    fetchCustomerDataByChatId({
                      chatStatus: "live",
                      payload: chatId,
                    }),
                  );
                }
              } else if (
                messageData?.messageType === "chatForm" &&
                notificationEnabled
              ) {
                const messageText =
                  getFormTitle(messageData?.formDetails?.formType) +
                  " has been submitted";

                pushNotification({
                  title: "New Message From " + messageData.sentBy.name + "!",
                  options: {
                    body: messageText,
                    tag: `NewMessage::${chatId}::${messageData.messageId}`,
                  },
                  soundType: "NewMessageSound",
                  callback: () => {
                    navigate(
                      window.location.pathname.replace(
                        window.location.pathname,
                        liveChatRoute +
                          `/${
                            res?.chatStatus ?? "live"
                          }/${chatId}/chattingTab/conversation`,
                      ),
                    );
                  },
                });
              }
            }

            if (
              messageData?.senderType &&
              messageData.senderType.toLowerCase() !== "agent" &&
              (messageData.messageType === undefined ||
                messageData.messageType?.toLowerCase() === "message")
            ) {
              updateLiveChatSidebar();
              // dispatch(
              //   actions.updateSidebar({
              //     chatId: chatId,
              //     status: "live",
              //     isDelete: false,
              //   })
              // );
            }
          } else {
            if (
              notificationEnabled &&
              messageData?.senderType?.toLowerCase() !== "agent" &&
              (messageData.messageType === undefined ||
                messageData.messageType?.toLowerCase() === "message" ||
                messageData?.messageType === "chatForm")
            ) {
              const messageText =
                messageData?.messageType === "chatForm"
                  ? getFormTitle(messageData?.formDetails?.formType) +
                    " has been submitted"
                  : convertHTMLToText(messageData.messageText);

              pushNotification({
                title: "New Message From " + messageData.sentBy.name + "!",
                options: {
                  body: messageText,
                  tag: `NewMessage::${chatId}::${messageData.messageId}`,
                },
                soundType: "NewMessageSound",
                callback: () => {
                  navigate(
                    window.location.pathname.replace(
                      window.location.pathname,
                      liveChatRoute +
                        `/${
                          res?.chatStatus ?? "live"
                        }/${chatId}/chattingTab/conversation`,
                    ),
                  );
                },
              });
            }
          }
        }
      }
    }
  };

  const NewCustomerCallback = (res: any) => {
    if (res?.data?.chatRequestId) {
      const notification = res?.notification;
      let notificationEnabled = true;
      if (notification?.enabled === false) {
        notificationEnabled = false;
      }
      const chatId = res.data.chatRequestId;
      const customer: Customer = {
        customerId: res.data.customerId,
        chatId: chatId,
        name: res.data.customerName,
        email: res.data.customerEmail,
        subject: res.data.subject,
        imageURL: res.data.imageURL,
        lastMessage: res.data.lastMessage,
        lastMessageTime: res.data.lastMessageTime,
        isRead: res.data.isRead,
        missedMessages: res.data.missedMessages ?? 0,
        receivedMessages: res.data.receivedMessages ?? 0,
        channelName: res.data.customerChannelName,
        customerStatus: res.data.customerStatus,
        lastMessageId: res.data.lastMessageId,
        isConnected: res.data.isConnected,
        canReply: res.data.canReply,
        isCustomerWaitingForReply: res.data.isCustomerWaitingForReply,
        customerWaitingForReplySince: res.data.customerWaitingForReplySince,
        chatStatus: res.data?.chatStatus,
        queuedDatetimeGmt: res.data?.queuedDatetimeGmt,
        brand: res.data?.brand,
        isResolved: res?.data?.isResolved,
        isTicketCreated: res?.data?.isTicketCreated,
        ticketData: res?.data?.ticketData,
      };

      if (customer.chatStatus !== "queued") {
        //chat is connected so remove the chat from notification
        removeChatFromNotifications(chatId);
      }
      refetch();
      updateLiveChatSidebar();

      const brandId = res.data.brand?.id;
      if (
        brandId &&
        currentState.current.currentUserData?.disabledBrandIds &&
        currentState.current.currentUserData?.disabledBrandIds.includes(
          parseInt(brandId + ""),
        )
      ) {
        //ignore the event, this brand is disabled
        return;
      }

      // Check if the chatId is either in the chatIdList or matches the selectedCustomerChatId
      if (
        currentState.current.chatIdList.includes(chatId) ||
        currentState.current.selectedCustomerChatId == chatId
      ) {
        // Clear the cache for all customers
        clearGetAllCustomersCache();
        // Remove the specific customer from the cache by chatId
        removeCustomerByChatIdCache(chatId);
      }
      //checking if current url is live chat url or not
      const assignedAgentIds =
        res.data?.assignedAgents && res.data?.assignedAgents.length !== 0
          ? res.data?.assignedAgents?.map((agentId: any) => {
              return parseInt(agentId + "");
            })
          : [];

      if (
        assignedAgentIds &&
        assignedAgentIds.includes(
          parseInt(currentState.current.currentUserData?.userId + ""),
        )
      ) {
        customer["canReply"] = true;
      } else {
        customer["canReply"] = false;
      }

      //checking if current url is live chat url or not
      if (
        currentState.current.location &&
        currentState.current.location.includes(liveChatRoute)
      ) {
        if (currentState.current.selectedCustomerStatus == "live") {
          dispatch(
            fetchCustomerDataByChatId({ payload: chatId, chatStatus: "live" }),
          );
        }

        if (customer.missedMessages != 0) {
          updateLiveChatSidebar();
          // dispatch(
          //   actions.updateSidebar({
          //     chatId: chatId,
          //     status: "live",
          //     isDelete: false,
          //   })
          // );
        }
      }

      const toastText =
        customer.chatStatus === "queued"
          ? customer.name + " has been Queued!"
          : customer.name + " has been Connected!";

      //checking if chat accepted by customer, then only pushing chat connected notification
      if (
        notificationEnabled &&
        (res.data?.chatAcceptedBy === undefined ||
          res.data.chatAcceptedBy === "customer")
      ) {
        pushNotification({
          title: toastText,
          options: {
            body: convertHTMLToText(customer.lastMessage),
            tag: `NewChat::${chatId}`,
          },
          soundType: "NewChatSound",
          callback: () => {
            navigate(
              window.location.pathname.replace(
                window.location.pathname,
                liveChatRoute + `/live/${chatId}/chattingTab/conversation`,
              ),
            );
          },
        });
      }
    }
  };

  const ChatEndedEventCallback = (res: any) => {
    if (res?.data?.chatDetails.chatRequestId) {
      //chat is ended, remove chat from notification
      removeChatFromNotifications(res?.data?.chatDetails.chatRequestId);
      refetch();
    }

    updateLiveChatSidebar();

    if (
      currentState.current.location &&
      currentState.current.location.includes(liveChatRoute)
    ) {
      let toastText = "This chat has been disconnected";

      if (res?.msg) {
        toastText = res.msg;
      }

      if (res?.data?.chatDetails.chatRequestId) {
        const chatId = res.data.chatDetails.chatRequestId;

        // Check if the chatId is either in the chatIdList or matches the selectedCustomerChatId
        if (
          currentState.current.chatIdList.includes(chatId) ||
          currentState.current.selectedCustomerChatId == chatId
        ) {
          // Clear the cache for all customers
          clearGetAllCustomersCache();
          // Remove the specific customer from the cache by chatId
          removeCustomerByChatIdCache(chatId);
        }

        dispatch(
          actions.setChatDisconnectedStatus({
            chatId: chatId,
            connected: res?.data.isConnected ?? false,
            isCustomerOnline: res?.data.isCustomerOnline ?? false,
          }),
        );

        if (
          res?.data?.chatDetails.chatRequestStatus &&
          res.data.chatDetails.chatRequestStatus == "missed"
        ) {
          // dispatch(
          //   actions.updateSidebar({
          //     chatId: chatId,
          //     status: "missed",
          //     isDelete: false,
          //   })
          // );
          dispatch(
            actions.setChatStatusByChatId({ chatId: chatId, status: "missed" }),
          );

          if (currentState.current.selectedCustomerStatus != "missed") {
            dispatch(actions.removeCustomerFromChatList({ chatId: chatId }));
          }
        }

        if (
          res?.data?.chatDetails.chatRequestStatus &&
          res.data.chatDetails.chatRequestStatus == "archived"
        ) {
          dispatch(
            actions.setChatStatusByChatId({
              chatId: chatId,
              status: "archived",
            }),
          );

          if (currentState.current.selectedCustomerStatus != "archived") {
            dispatch(actions.removeCustomerFromChatList({ chatId: chatId }));
          }
        }

        const customerInfo = currentState.current.customers[chatId] ?? null;

        if (customerInfo) {
          let customer: Customer = {
            ...customerInfo,
            isConnected: false,
          };

          if (
            res?.data?.chatDetails.chatRequestStatus &&
            res.data.chatDetails.chatRequestStatus == "missed"
          ) {
            customer.customerStatus = "missed";
          }

          if (
            res?.data?.chatDetails.chatRequestStatus &&
            res.data.chatDetails.chatRequestStatus == "archived"
          ) {
            customer.customerStatus = "archived";
          }

          // if (
          //   res?.data?.chatDetails.isCustomerWaitingForReply
          // ) {
          customer.isCustomerWaitingForReply =
            res?.data?.chatDetails.isCustomerWaitingForReply;
          // }

          // if (
          //   res?.data?.chatDetails.customerWaitingForReplySince
          // ) {
          customer.customerWaitingForReplySince =
            res?.data?.chatDetails.customerWaitingForReplySince;
          // }

          customer.isConnected = res?.data.isCustomerOnline ?? false;
          customer.chatStatus = "";

          // update in customers data list also
          dispatch(
            actions.updateCustomerDataOnChatEnd({
              customerInfo: customer,
            }),
          );

          if (
            currentState.current.activeCustomerInfo?.chatId == customer.chatId
          ) {
            dispatch(setActiveCustomerInfo({ customerInfo: customer }));
            pushTheToast({
              type: "info",
              text: toastText,
              position: "top-right",
            });
          }

          // pushTheToast({
          //     type: "danger",
          //     text: customer.name+" has been disconnected",
          //     position: "top-right",
          // });
        }
      }
    }
  };

  const ChatMessageReadEventCallback = (res: any) => {
    if (
      currentState.current.location &&
      currentState.current.location.includes(liveChatRoute)
    ) {
      if (res?.data?.chatRequestId) {
        const chatId = res.data.chatRequestId;
        const lastMessageId = res.data.lastMessageId; // Getting last message id from event response
        const status = res.data.status; // Getting status from event response
        const senderType = res.data.senderType; // Getting sender type from event response
        const seenBy = res.data.seenBy; // Getting sender type from event response
        updateLiveChatSidebar();

        if (
          res.data.missedMessageCount !== null &&
          res.data.missedMessageCount !== undefined &&
          !isNaN(Number(res.data.missedMessageCount))
        ) {
          const missedMessageCount = parseInt(res.data.missedMessageCount + "");
          dispatch(
            actions.setMissedMessageCount({
              chatId: chatId,
              count: missedMessageCount,
            }),
          );
        }
        // Check if the chatId is either in the chatIdList or matches the selectedCustomerChatId
        if (
          currentState.current.chatIdList.includes(chatId) ||
          currentState.current.selectedCustomerChatId == chatId
        ) {
          // Clear the cache for all customers
          clearGetAllCustomersCache();
          // Remove the specific customer from the cache by chatId
          removeCustomerByChatIdCache(chatId);
        }
        // Checking if current chat is selected chat and then mark messages as seen
        if (currentState.current.selectedCustomerChatId == chatId) {
          dispatch(
            markMessagesSeen({
              lastMessageId: lastMessageId ?? "",
              seenStatus: status ?? false,
              senderType: senderType,
              seenBy: seenBy,
            }),
          );
        }
      }
    }
  };

  //  callback for live chat request waiting for reply since event
  const liveChatRequestWaitingForReplySinceEventCallBack = (res: any) => {
    // console.log(
    //   "liveChatRequestWaitingForReplySinceEventCallBack res.data:: ",
    //   res.data
    // );
    let waitingForReplyData: any = res.data.waitingForReplySinceData;

    // check if there is any data in chat request sidebar
    if (
      waitingForReplyData &&
      currentState.current.chatIdList &&
      currentState.current.chatIdList.length > 0
    ) {
      let customersTemp: Customers = { ...currentState.current.customers };
      let chatIdListTemp: Array<number | string> = [];

      // waiting for reply data mapped by chat id
      let waitingForReplyDataMappedByChatId: {
        [chatId: string | number]: IWaitingForReplyDataItem;
      } = {};

      waitingForReplyData.map(
        (waitingForReplyItem: IWaitingForReplyDataItem) => {
          const chatId = parseInt(waitingForReplyItem.chatId + "");
          waitingForReplyDataMappedByChatId[chatId] = waitingForReplyItem;
        },
      );

      currentState.current.chatIdList.forEach((chatId) => {
        let customerData: Customer = { ...customersTemp[chatId] };
        // console.log("customersTemp :: ", customersTemp);
        // console.log("customerData :: ", customerData);
        // console.log("chatId :: ", chatId);
        // check is there any data related to this in event response
        if (waitingForReplyDataMappedByChatId[chatId]) {
          // if there, update it
          customerData.customerWaitingForReplySince =
            waitingForReplyDataMappedByChatId[
              chatId
            ].customerWaitingForReplySince;
          customerData.isCustomerWaitingForReply =
            waitingForReplyDataMappedByChatId[chatId].isCustomerWaitingForReply;
        } else {
          // if not there, set the default
          customerData.customerWaitingForReplySince = null;
          customerData.isCustomerWaitingForReply = false;
        }

        // update it
        customersTemp[chatId] = customerData;
      });

      // dispatch the action to update the data
      dispatch(
        actions.updateLiveChatRequestWaitingSinceData({
          customers: customersTemp,
        }),
      );
    }
  };

  const ChatUpdatedCallback = (res: any) => {
    if (res?.data?.chatRequestId) {
      const chatId = parseInt(res.data.chatRequestId + "");
      const canAddNew =
        res?.data?.canAddNew && res?.data?.canAddNew === true ? true : false;
      const brandId = res.data.brandId;
      if (
        brandId &&
        currentState.current.currentUserData?.disabledBrandIds &&
        currentState.current.currentUserData?.disabledBrandIds.includes(
          parseInt(brandId + ""),
        )
      ) {
        //ignore the event, this brand is disabled
        return;
      }

      // Check if the chatId is either in the chatIdList or matches the selectedCustomerChatId
      if (
        currentState.current.chatIdList.includes(chatId) ||
        currentState.current.selectedCustomerChatId == chatId
      ) {
        // Clear the cache for all customers
        clearGetAllCustomersCache();
        // Remove the specific customer from the cache by chatId
        removeCustomerByChatIdCache(chatId);
      }

      if (res?.data.updatedData && res.data.updatedData?.customerStatus) {
        if (res.data.updatedData?.customerStatus !== "queued") {
          removeChatFromNotifications(res.chatId);
        }
      }

      //checking if event chatId existsin in current sidebar chats or selected chat
      if (
        res?.data.updatedData &&
        Object.keys(res?.data.updatedData).length > 0 &&
        (currentState.current.chatIdList.includes(chatId) ||
          currentState.current.selectedCustomerChatId == chatId)
      ) {
        // Validate if updatedData is Partial<Customer>
        const updatedData: Partial<Customer> = res.data.updatedData;

        if (updatedData?.customerStatus !== null) {
          // Dispatch action to update customer data
          dispatch(
            actions.updateCustomerData({
              chatId: res?.data?.chatRequestId,
              customer: updatedData,
            }),
          );
          dispatch(actions.removeChatsNotMatchingCurrentFilter());
        }

        // Check if the active customer info needs to be updated
        if (
          currentState.current.activeCustomerInfo?.chatId ==
            res?.data?.chatRequestId &&
          updatedData?.customerStatus !== null
        ) {
          dispatch(
            updateCustomerInfo({
              customerId:
                currentState.current.activeCustomerInfo?.customerId + "",
              customerInfo: updatedData,
            }),
          );
        }
      } else if (
        currentState.current.chatIdList.includes(chatId) ||
        currentState.current.selectedCustomerChatId == chatId
      ) {
        dispatch(fetchCustomerDataByChatId({ payload: chatId }));
      } else {
        //checking if chat should added at top
        if (canAddNew) {
          dispatch(fetchCustomerDataByChatId({ payload: chatId }));
        }
      }
    }
  };

  const ChatAssingedCallBack = (res: any) => {
    if (res?.data.chatId) {
      const chatId = parseInt(res?.data?.chatId + "");
      const customer = res?.data.customer;
      const assigner = res?.data.assigner;
      const preChatForm = res?.data.preChatForm;
      const assigneeIds = res?.data.assigneeIds?.map(
        (assigneeId: number | string) => parseInt(assigneeId + ""),
      );

      const brandId = res.data.brandId;
      if (
        brandId &&
        currentState.current.currentUserData?.disabledBrandIds &&
        currentState.current.currentUserData?.disabledBrandIds.includes(
          parseInt(brandId + ""),
        )
      ) {
        //ignore the event, this brand is disabled
        return;
      }

      if (
        currentState.current.chatIdList.includes(chatId) ||
        currentState.current.selectedCustomerChatId == chatId
      ) {
        clearGetAllCustomersCache();
        removeCustomerByChatIdCache(chatId);
      }

      if (
        currentState.current.location &&
        currentState.current.location.includes(liveChatRoute)
      ) {
        if (currentState.current.chatIdList.includes(chatId)) {
          dispatch(fetchCustomerDataByChatId({ payload: chatId }));
        }
      }

      //checking if chat is assigned to current agent
      if (
        assigneeIds.includes(
          parseInt(currentState.current.currentUserData?.userId + ""),
        ) &&
        assigner.id != currentState.current.currentUserData?.userId
      ) {
        dispatch(
          actions.setChatAssignedNotification({
            chatId: res?.data.chatId,
            showModal: true,
            customer,
            assigner,
            chatStatus: res?.data.chatStatus,
            preChatForm,
          }),
        );

        const notificationTitle = ` ${
          assigner.name ?? "NA"
        } has assigned you a chat!`;

        const notificationMessageBody = `${
          assigner.name ?? "NA"
        } has assigned this conversation with ${customer.name ?? "NA"} to you`;

        pushNotification({
          title: notificationTitle,
          options: {
            body: notificationMessageBody,
            tag: `NewChat::${chatId}`,
          },
          soundType: "NewChatSound",
          callback: () => {
            navigate(
              window.location.pathname.replace(
                window.location.pathname,
                liveChatRoute +
                  `/${
                    res?.data?.chatStatus ?? "live"
                  }/${chatId}/chattingTab/conversation`,
              ),
            );
          },
        });
      }
    }
  };

  const AgentRemovedFromAssignedChatCallBack = (res: any) => {
    if (
      currentState.current.location &&
      currentState.current.location.includes(liveChatRoute)
    ) {
      if (res?.data.chatId) {
        const chatId = parseInt(res?.data.chatId + "");
        const removedAgentIds = res?.data.removedAgentIds?.map(
          (agentId: number | string) => parseInt(agentId + ""),
        );
        if (
          currentState.current.chatIdList.includes(chatId) ||
          currentState.current.selectedCustomerChatId == chatId
        ) {
          clearGetAllCustomersCache();
          removeCustomerByChatIdCache(chatId);
        }
        //checking if active customer info chatId and event chat id is same
        if (currentState.current.selectedCustomerChatId == chatId) {
          if (currentState.current.activeCustomerInfo) {
            const activeCustomer: Customer = {
              ...currentState.current.activeCustomerInfo,
            };

            const assignedAgents =
              activeCustomer?.chatAssignedTo &&
              activeCustomer?.chatAssignedTo.length
                ? activeCustomer?.chatAssignedTo.filter((agentInfo) => {
                    if (removedAgentIds.includes(parseInt(agentInfo.id + ""))) {
                      return false;
                    }

                    return true;
                  })
                : [];

            activeCustomer["chatAssignedTo"] = assignedAgents;

            dispatch(setActiveCustomerInfo({ customerInfo: activeCustomer }));
          }
        }

        //checking for the sidebar chat and updating
        if (currentState.current.chatIdList.includes(chatId)) {
          const customer = {
            ...currentState.current.customers[chatId],
          };

          const assignedAgents =
            customer?.chatAssignedTo && customer?.chatAssignedTo.length
              ? customer?.chatAssignedTo.filter((agentInfo) => {
                  if (removedAgentIds.includes(parseInt(agentInfo.id + ""))) {
                    return false;
                  }
                  return true;
                })
              : [];

          customer["chatAssignedTo"] = assignedAgents;

          dispatch(
            actions.setCustomerData({
              customer: customer,
              checkForExistingCust: true,
            }),
          );
          dispatch(fetchCustomerDataByChatId({ payload: chatId }));
        }
      }
    }
  };

  //method handles chat online/offline status updation
  const ChatOnlineStatusUpdatedCallback = (res: any) => {
    if (res?.data?.chatIds) {
      //getting the chatIds from event response
      const chatIds = res.data?.chatIds?.map((chatId: string | number) =>
        parseInt(chatId + ""),
      );

      //getting the online status from event response
      const isOnline = res.data?.online == true ? true : false;

      //looping through each chatId and checking if chatId exists in current chat ids list
      chatIds.map((chatId: number) => {
        //checking if chat id exists in current chat list
        if (currentState.current.customers[chatId] !== undefined) {
          //chat id exists

          //getting chat object and updating it values
          const customer: Customer = {
            ...currentState.current.customers[chatId],
            isCustomerOnline: isOnline,
          };

          //update the status
          dispatch(
            actions.setCustomerData({
              customer: customer,
              checkForExistingCust: true,
            }),
          );
        }

        //checking if active chat id and current chat id is equal
        if (
          currentState.current.activeCustomerInfo?.chatId &&
          currentState.current.activeCustomerInfo?.chatId == chatId
        ) {
          const activeCustomer: Customer = {
            ...currentState.current.activeCustomerInfo,
            isCustomerOnline: isOnline,
          };
          //updating active chat info
          dispatch(setActiveCustomerInfo({ customerInfo: activeCustomer }));
        }
      });
    }
  };

  const ChatCountUpdatedCallback = useCallback(
    (res: ChatCountUpdatedData[]) => {
      if (Array.isArray(res)) {
        dispatch(
          actions.resolveAndAddChatUpdatedCountToQueue({
            disabledBrandIds:
              currentState.current.currentUserData?.disabledBrandIds ?? [],
            eventData: res,
          }),
        );
      }
    },
    [],
  );

  useEffect(() => {
    if (globalPusherChannel) {
      //   console.log("bind events");
      globalPusherChannel.unbind("pusher:subscription_succeeded");

      globalPusherChannel.bind("pusher:subscription_succeeded", () => {
        // console.log("binded global events");
        //binding events
        getLiveMessageRecEvent(globalPusherChannel, liveMsgCallback);

        getNewlyConnectedCustomerEvent(
          globalPusherChannel,
          NewCustomerCallback,
        );

        getChatEndedEvent(globalPusherChannel, ChatEndedEventCallback);

        getAgentChatEndedEvent(globalPusherChannel, ChatEndedEventCallback);

        getMessageReadEvent(globalPusherChannel, ChatMessageReadEventCallback);

        getLiveChatRequestWaitingForReplySinceEvent(
          globalPusherChannel,
          liveChatRequestWaitingForReplySinceEventCallBack,
        );

        getLiveChatUpdatedEvent(globalPusherChannel, ChatUpdatedCallback);

        agentAssignedChatEvent(globalPusherChannel, ChatAssingedCallBack);

        agentRemovedFromAssignedChatEvent(
          globalPusherChannel,
          AgentRemovedFromAssignedChatCallBack,
        );

        //binding chat online status update event on global channel
        chatOnlineStatusUpdatedEvent(
          globalPusherChannel,
          ChatOnlineStatusUpdatedCallback,
        );

        chatCountUpdatedEvent(globalPusherChannel, ChatCountUpdatedCallback);
      });
    }
  }, [globalPusherChannel]);

  useEffect(() => {
    if (privatePusherChannel) {
      //   console.log("bind events");
      privatePusherChannel.unbind("pusher:subscription_succeeded");

      privatePusherChannel.bind("pusher:subscription_succeeded", () => {
        // console.log("binded private events");
        //binding events
        // getLiveMessageRecEvent(privatePusherChannel, liveMsgCallback);
        // getNewlyConnectedCustomerEvent(privatePusherChannel, NewCustomerCallback);
        // getChatEndedEvent(privatePusherChannel, ChatEndedEventCallback);
        // getAgentChatEndedEvent(privatePusherChannel, ChatEndedEventCallback);
      });
    }
  }, [privatePusherChannel]);

  const bindAllEvents = (
    channel: Channel | null | undefined,
    isGlobal: boolean = false,
  ) => {
    if (channel) {
      if (isGlobal) {
        setGlobalPusherChannel(channel);
      } else {
        setPrivatePusherChannel(channel);
      }
    }
  };

  return { bindAllEvents };
};
