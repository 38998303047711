import { axiosJSON } from "src/globals/axiosEndPoints";

const downloadFile = async (url: string, throwError = false) => {
  try {
    const { data: res } = await axiosJSON.get(url, {
      responseType: "blob",
    });

    if (res.err || res.error) {
      throw res.msg ?? res.message ?? "Something unexpected occured!";
    }

    const blob = await res;
    const blobUrl = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = blobUrl;

    const filename = "recorded_file";
    a.download = filename;

    // Append the anchor to the body to ensure it is part of the document
    document.body.appendChild(a);

    // Trigger the click event on the anchor
    a.click();

    // Clean up and remove the anchor from the document
    document.body.removeChild(a);

    // Revoke the blob URL to free up memory
    URL.revokeObjectURL(blobUrl);
  } catch (err: any) {
    if (throwError) {
      throw err;
    }
    console.log("Downloading Failed:", err.message || err);
  }
};

export default downloadFile;
