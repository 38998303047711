import { useCallback, useMemo, useState } from "react";
import styles from "./UploadNewCsvModal.module.scss";
import ConfigureUpload from "./ConfigureUpload/ConfigureUpload";
import UploadCsv from "./UploadCsv/UploadCsv";
import MapAttribute from "./MapAttribute/MapAttribute";
import ImportData from "./ImportData/ImportData";
import SuccessMsg from "./SuccessMsg/SuccessMsg";
import SkeletonModalStructure from "src/components/AutomationComponents/SkeletonModalStructure/SkeletonModalStructure";
import {
  useUploadNewCsvModalContext,
  useUploadNewCsvModalProvider,
} from "./Hooks/useUploadNewCsvModalContext";
import { useParams } from "react-router-dom";
import {
  EUploadSteps,
  stepsList,
} from "src/features/ReturnAutoWorkFlow/ReturnAutoWorkFlow.types";
import UploadNewCsvHeader from "./UploadNewCsvHeader/UploadNewCsvHeader";
import Loader from "src/components/Loader";
import { Spinner } from "react-bootstrap";
import markCSVUploadCompletedService from "src/services/ReturnAutoWorkFlow/ConfigModals/markCSVUploadCompleted.service";
import { useReturnAutoWorkFlow } from "src/features/ReturnAutoWorkFlow/hooks/useReturnAutoWorkFlow";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import csvUpdateQuestions from "src/services/ReturnAutoWorkFlow/ConfigModals/csvUpdateQuestions.service";
import updateMappedAttributes from "src/services/ReturnAutoWorkFlow/ConfigModals/updateMappedAttributes.service";

const UploadCsvSteps = () => {
  const { returnAutoWorkFlow } = useReturnAutoWorkFlow();
  const integrationId = useMemo(
    () => returnAutoWorkFlow.integrationId + "",
    [returnAutoWorkFlow],
  );

  const [nextBtnDisabled, setNextBtnDisabled] = useState(false);
  const {
    state,
    setCompletedSteps,
    setShowErrors,
    updateState,
    validateStep,
    isLoading,
  } = useUploadNewCsvModalContext();

  const attachmentId = useMemo(
    () => state.uploadCSV?.attachmentId + "",
    [state],
  );
  const configQuestions = useMemo(() => {
    return state.configureUpload.configureCSVQuestions.map((question) => {
      return {
        questionId: question.questionId,
        questionValue: question.questionValue ?? "",
      };
    });
  }, [state.configureUpload]);

  const updatedTableHeadingIds = useMemo(() => {
    return state.mapAttributes?.updatedTableHeadingIds ?? [];
  }, [state]);

  const activeStep =
    stepsList.find((step) => step.key == state.activeStep) ?? stepsList[0];

  const [btnloading, setBtnLoading] = useState(false);

  const handleNavigation = useCallback(
    async (isNext: boolean) => {
      if (!activeStep) {
        return;
      }

      // Check validity if moving to the next step
      if (isNext) {
        let isValid = validateStep({
          stepType: activeStep?.key,
          state: state,
        });

        // Need to call the API to complete the step, if it is `import`.
        if (isValid) {
          switch (activeStep.key) {
            case EUploadSteps.Import: {
              setBtnLoading(true);
              try {
                await markCSVUploadCompletedService({
                  integrationId,
                  attachmentId,
                });
              } catch (error) {
                // On error need to make it invalid to prevent next step transition.
                isValid = false;
                console.error(error);
                pushTheToast({
                  type: "danger",
                  text: "Couldn't update! Please check your network and try again!",
                  position: "top-right",
                });
              }
              setBtnLoading(false);
              break;
            }
            case EUploadSteps.ConfigureUpload: {
              setBtnLoading(true);
              try {
                csvUpdateQuestions({
                  integrationId: integrationId ?? "",
                  questions: configQuestions,
                });
              } catch (error) {
                console.error(error);
                pushTheToast({
                  position: "top-right",
                  text:
                    typeof error === "string"
                      ? error
                      : "Something went wrong while updating!",
                  type: "danger",
                });
              }
              setBtnLoading(false);
              break;
            }
            case EUploadSteps.MapAttributes: {
              setBtnLoading(true);
              try {
                updateMappedAttributes({
                  headingIds: updatedTableHeadingIds,
                  attachmentId: state?.uploadCSV?.attachmentId ?? "",
                  integrationId: integrationId,
                }).then((res) => {
                  if (state.mapAttributes)
                    updateState({
                      mapAttributes: {
                        ...state.mapAttributes,
                        mapAttributeErrors: res?.mapAttributeErrors ?? {},
                      },
                    });
                });
              } catch (error) {
                console.error(error);
                pushTheToast({
                  position: "top-right",
                  text:
                    typeof error === "string"
                      ? error
                      : "Something went wrong while updating!",
                  type: "danger",
                });
              }
              setBtnLoading(false);
              break;
            }
          }
        }
        // Update completed steps
        setCompletedSteps((prev) => {
          const hasKey = prev.includes(activeStep.key);
          const updatedSteps = isValid
            ? hasKey
              ? prev
              : [...prev, activeStep.key]
            : hasKey
              ? prev.filter((val) => val !== activeStep.key)
              : prev;

          return updatedSteps;
        });

        // Update errors if not valid
        if (!isValid) {
          setShowErrors(true);
          return;
        }
      }

      // Update active step
      const newIndex = isNext
        ? activeStep.index + 1 < stepsList.length
          ? activeStep.index + 1
          : activeStep.index
        : activeStep.index - 1 >= 0
          ? activeStep.index - 1
          : activeStep.index;
      setNextBtnDisabled(false);
      updateState({
        activeStep: stepsList[newIndex]?.key ?? activeStep.key,
      });
    },
    [activeStep, stepsList, integrationId, attachmentId, validateStep],
  );

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      {/* Header - steps list */}
      <UploadNewCsvHeader
        steps={stepsList}
        activeStep={activeStep}
      />
      {/* Navigation Buttons */}
      {activeStep.index !== stepsList.length - 1 ? (
        <div className="d-flex justify-content-center mt-4 mb-3">
          <button
            className={`me-2 ${styles.prevBtn}`}
            id="modal_prev_btn"
            disabled={activeStep.index === 0}
            onClick={() => handleNavigation(false)}
          >
            <span className="pe-1">
              <i className="fa-solid fa-arrow-left"></i>
            </span>
            <span>Previous</span>
          </button>
          {activeStep.index < stepsList.length - 1 && (
            <button
              className={`${styles.prevBtn}`}
              id="modal_next_btn"
              onClick={() => handleNavigation(true)}
              disabled={nextBtnDisabled}
            >
              <span>Next</span>
              <span className="ps-1">
                {btnloading ? (
                  <Spinner size="sm" />
                ) : (
                  <i className="fa-solid fa-arrow-right"></i>
                )}
              </span>
            </button>
          )}
        </div>
      ) : null}
      <div className={`p-4 ${styles.contentWrapper}`}>
        {activeStep?.key === EUploadSteps.ConfigureUpload ? (
          <ConfigureUpload />
        ) : activeStep?.key === EUploadSteps.UploadCSV ? (
          <UploadCsv setNextBtnDisabled={setNextBtnDisabled} />
        ) : activeStep?.key === EUploadSteps.MapAttributes ? (
          <MapAttribute />
        ) : activeStep?.key === EUploadSteps.Import ? (
          <ImportData setNextBtnDisabled={setNextBtnDisabled} />
        ) : activeStep?.key === EUploadSteps.Completed ? (
          <SuccessMsg />
        ) : null}
      </div>
    </div>
  );
};

const UploadNewCsvModal = ({ onHide }: { onHide: () => void }) => {
  const { integrationId } = useParams();
  const { UploadNewCsvProvider, ...contextValues } =
    useUploadNewCsvModalProvider(integrationId ?? "");
  return (
    <UploadNewCsvProvider value={contextValues}>
      <SkeletonModalStructure
        hasTwoSection={false}
        heading="Upload csv file of your products to automate the returns process"
        closeFunction={onHide}
      >
        <UploadCsvSteps />
      </SkeletonModalStructure>
    </UploadNewCsvProvider>
  );
};

export default UploadNewCsvModal;
