import { useCallback, useMemo } from "react";
import {
  BetweenRange,
  ConditionI,
} from "src/services/CustomerSegments/createSegment.service";
import {
  CompareData,
  CompareDetail,
  FieldValueInstance,
} from "src/services/CustomerSegments/getConditionOptions.service";
import { useUpdateSegmentDetails } from "../../hooks/useUpdateSegmentDetails";
import { useSelectedComparisionType } from "../ComparisonTypes/ComparisonTypes";
import DateTimeSelector from "./children/DateTimeSelector";
import DateTimeSelectorRange from "./children/DateTimeSelectorRange";
import MultiSelectStringInput from "./children/MultiSelectStringInput";
import NumberInput from "./children/NumberInput";
import NumberInputRange from "./children/NumberInputRange";
import SelectInput from "./children/SelectInput";
import StringInput from "./children/StringInput";
import SearchableDropdownInput from "./children/SearchableDropdown";

interface Props {
  compareDetails: Array<CompareDetail | CompareData>;
  fieldValues?: Array<FieldValueInstance>;
  fieldKey: string;
  isMulti?: boolean;
  isSearchable?: boolean;
  placeholder?: string | null;
  conditionIndex: number;
  groupIndex: number;
}

const ValueBox = ({
  compareDetails,
  fieldValues = [],
  placeholder,
  fieldKey,
  isMulti = false,
  isSearchable = false,
  conditionIndex,
  groupIndex,
}: Props) => {
  const { updateSegmentDetails, dispatch } = useUpdateSegmentDetails();
  const { selectedComparisionType } = useSelectedComparisionType({
    options: compareDetails,
    conditionIndex,
    groupIndex,
  });

  const selectedvalue = useMemo(() => {
    const value = (updateSegmentDetails.segmentDetails.groupConditions ?? [])[
      groupIndex
    ]?.conditions[conditionIndex];
    if (value !== undefined) {
      return value;
    } else {
      return null;
    }
  }, [updateSegmentDetails, groupIndex, conditionIndex]);

  const handleValueUpdate = useCallback(
    function (v: ConditionI["value"], labels?: Record<string, string[]>) {
      if (selectedComparisionType) {
        dispatch("updateSegmentCondition", {
          conditionIndex,
          groupIndex,
          data: {
            value: v,
            valuesOfSelectKeys: labels,
            fieldDataType: selectedComparisionType.fieldDataType,
            maxLimit: selectedComparisionType.max,
            minLimit: selectedComparisionType.min,
          },
        });
      }
    },
    [dispatch, conditionIndex, groupIndex, selectedComparisionType],
  );

  const handleSpecificTimeUpdate = useCallback(
    (isSpecificTime: boolean) => {
      if (selectedComparisionType) {
        dispatch("updateSegmentCondition", {
          conditionIndex,
          groupIndex,
          data: { isSpecificTime },
        });
      }
    },
    [conditionIndex, dispatch, groupIndex, selectedComparisionType],
  );

  const handlePushIndex = useCallback(
    function (index: number) {
      dispatch("pushValueIndexSegmentCondition", {
        conditionIndex,
        groupIndex,
        index,
      });
    },
    [dispatch, conditionIndex, groupIndex],
  );
  const handlePopIndex = useCallback(
    function (index: number) {
      dispatch("popValueIndexSegmentCondition", {
        conditionIndex,
        groupIndex,
        index,
      });
    },
    [dispatch, conditionIndex, groupIndex],
  );

  if (selectedComparisionType) {
    if (selectedComparisionType.fieldDataType === "string") {
      if (isMulti) {
        const value = selectedvalue?.value as Array<string> | null | undefined;

        if (isSearchable) {
          return (
            <SearchableDropdownInput
              onChange={handleValueUpdate}
              placeholder={placeholder ?? "Enter value..."}
              fieldKey={fieldKey}
              value={value}
              error={
                updateSegmentDetails.showErrors
                  ? "This field is required"
                  : undefined
              }
            />
          );
        }

        return (
          <MultiSelectStringInput
            onChange={handleValueUpdate}
            placeholder={placeholder ?? "Enter value..."}
            isEmailError={false}
            value={value}
            error={
              updateSegmentDetails.showErrors
                ? "This field is required"
                : undefined
            }
          />
        );
      }

      return (
        <StringInput
          value={(selectedvalue?.value as string | null | undefined) ?? ""}
          onChange={handleValueUpdate}
          placeholder={placeholder ?? "Enter value..."}
          suffix={selectedComparisionType.suffix}
          error={
            updateSegmentDetails.showErrors
              ? "This field is required"
              : undefined
          }
        />
      );
    } else if (selectedComparisionType.fieldDataType === "number") {
      return (
        <NumberInput
          value={(selectedvalue?.value as string | undefined) ?? ""}
          onChange={handleValueUpdate}
          placeholder={"0"}
          max={selectedComparisionType.max}
          min={selectedComparisionType.min ?? 0}
          suffix={selectedComparisionType.suffix}
          isError={updateSegmentDetails.showErrors}
        />
      );
    } else if (selectedComparisionType.fieldDataType === "numberRange") {
      return (
        <NumberInputRange
          value={{
            start:
              (selectedvalue?.value as BetweenRange<string> | undefined)
                ?.start ?? "",
            end:
              (selectedvalue?.value as BetweenRange<string> | undefined)?.end ??
              "",
          }}
          onChange={handleValueUpdate}
          placeholder={"0"}
          max={selectedComparisionType.max}
          min={selectedComparisionType.min ?? 0}
          suffix={selectedComparisionType.suffix}
          isError={updateSegmentDetails.showErrors}
        />
      );
    } else if (
      selectedComparisionType.fieldDataType === "dateTimeRange" ||
      selectedComparisionType.fieldDataType === "dateRange" ||
      selectedComparisionType.fieldDataType === "dateOrDateTimeRange"
    ) {
      return (
        <DateTimeSelectorRange
          value={{
            start:
              (selectedvalue?.value as BetweenRange<string> | undefined)
                ?.start ?? "",
            end:
              (selectedvalue?.value as BetweenRange<string> | undefined)?.end ??
              "",
          }}
          onChange={handleValueUpdate}
          dateType={selectedComparisionType.fieldDataType}
          suffix={selectedComparisionType.suffix}
          error={
            updateSegmentDetails.showErrors
              ? "This field is required"
              : undefined
          }
        />
      );
    } else if (
      selectedComparisionType.fieldDataType === "dateTime" ||
      selectedComparisionType.fieldDataType === "date" ||
      selectedComparisionType.fieldDataType === "dateOrDateTime"
    ) {
      return (
        <DateTimeSelector
          value={(selectedvalue?.value as string | undefined) ?? ""}
          onChange={handleValueUpdate}
          dateType={selectedComparisionType.fieldDataType}
          suffix={selectedComparisionType.suffix}
          onSpecificTimeChange={handleSpecificTimeUpdate}
          isSpecificTime={selectedvalue?.isSpecificTime}
          error={
            updateSegmentDetails.showErrors
              ? "This field is required"
              : undefined
          }
        />
      );
    } else if (selectedComparisionType.fieldDataType === "select") {
      return (
        <SelectInput
          fieldValues={fieldValues}
          fieldKey={fieldKey}
          value={
            (selectedvalue?.value as string | Array<string> | undefined) ?? null
          }
          onChange={handleValueUpdate}
          valuesOfSelectKeys={selectedvalue?.valuesOfSelectKeys}
          placeholder={placeholder ?? "Select Values..."}
          suffix={selectedComparisionType.suffix}
          isMulti={isMulti}
          error={
            updateSegmentDetails.showErrors
              ? "This field is required"
              : undefined
          }
          handlePush={handlePushIndex}
          handlePop={handlePopIndex}
        />
      );
    }
  }
  return null;
};

export default ValueBox;
