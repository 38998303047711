import { createContext, useCallback, useContext, useState } from "react";
import {
  EmailSetupDetails,
  ILiveChatSetupDetails,
  SetupDetails,
} from "src/services/Home/getTodoList.service";

/**
 * Interface representing the context for ToDoList.
 */
interface ToDoContext {
  /**
   * Holds setup data or null if not available.
   */
  setupData: SetupDetails | null;
  /**
   * Function to set setup data.
   */
  setSetupData: React.Dispatch<React.SetStateAction<SetupDetails | null>>;
}

// Initial state for ToDoContext.
const initialState: ToDoContext = {
  setupData: null,
  setSetupData: () => {},
};

// Create a context with initial state.
const ToDoListContext = createContext<ToDoContext>(initialState);

/**
 * Hook to create ToDoList context.
 * @param initialData Initial setup data.
 * @returns Object containing setup data, setter function, and provider.
 */
export const useCreateToDoListContext = () => {
  const [setupData, setSetupData] = useState<SetupDetails | null>(null);

  return {
    setupData,
    setSetupData,
    Provider: ToDoListContext.Provider,
  };
};

/**
 * Hook to use ToDoList context.
 * @returns Object containing setup data and functions to update email and live chat steps.
 */
const useToDoList = () => {
  const { setupData, setSetupData } = useContext(ToDoListContext);

  /**
   * Update email setup step.
   * @param emailDetails Partial details of email setup.
   */
  const updateEmailStep = useCallback(
    (emailDetails: Partial<EmailSetupDetails>, isCompleted?: boolean) => {
      setSetupData((prev) => {
        if (prev && prev.emailSetup && prev.emailSetup.setupData) {
          return {
            ...prev,
            emailSetup: {
              ...prev.emailSetup,
              setupData: { ...prev.emailSetup.setupData, ...emailDetails },
              isCompleted: isCompleted
                ? isCompleted
                : prev.emailSetup.isCompleted,
            },
          };
        }

        return null;
      });
    },
    [setSetupData],
  );

  /**
   * Update live chat setup step.
   * @param liveChatDetails Partial details of live chat setup.
   */
  const updateLiveChatStep = useCallback(
    (
      liveChatDetails: Partial<ILiveChatSetupDetails>,
      isCompleted?: boolean,
    ) => {
      setSetupData((prev) => {
        if (prev && prev.liveChatSetup && prev.liveChatSetup.setupData) {
          return {
            ...prev,
            liveChatSetup: {
              ...prev.liveChatSetup,
              setupData: {
                ...prev.liveChatSetup.setupData,
                ...liveChatDetails,
              },
              isCompleted: isCompleted
                ? isCompleted
                : prev.liveChatSetup.isCompleted,
            },
          };
        }

        return null;
      });
    },
    [setSetupData],
  );

  /**
   * Handler for initializing live chat setup data when creating new integration
   */
  const initializeLiveChatSetup = useCallback(
    (integrationId: string) => {
      setSetupData((prev) => {
        if (prev && prev.liveChatSetup) {
          return {
            ...prev,
            liveChatSetup: {
              ...prev.liveChatSetup,
              setupData: {
                integration_id: integrationId,
                store_url: "",
                embedded_script: "",
                live_chat_enabled: false,
                installed_successfully: false,
                integration_web_socket_channel_name: "",
                brand: {
                  id: -1,
                  name: "",
                  email: "",
                  signature: "",
                  enabled: false,
                  isDefault: false,
                  isActive: false,
                  isVerified: false,
                  imageURL: null,
                },
                website_name: "",
                domain_branding_name: "",
              },
            },
          };
        }
        return null;
      });
    },
    [setSetupData],
  );

  /**
   * Handler for initializing live chat setup data when creating new integration
   */
  const initializeEmailSetup = useCallback(
    (integrationId: string) => {
      setSetupData((prev) => {
        if (prev && prev.emailSetup) {
          return {
            ...prev,
            emailSetup: {
              ...prev.emailSetup,
              setupData: {
                brandId: "",
                brandName: "",
                brandOutboundEmail: "",
                domainAuthData: [],
                emailIntegrationChannel: "",
                emailIntegrationId: integrationId,
                emailsForwardedTo: "",
                firstEmailSent: false,
                isDomainAddedForAuth: false,
                isDomainVerified: false,
                isEmailAdded: true,
                isEmailVerified: false,
                mailType: {
                  id: "",
                  typeName: "",
                },
                verificationDone: false,
              },
            },
          };
        }
        return null;
      });
    },
    [setSetupData],
  );

  return {
    setupData,
    updateEmailStep,
    updateLiveChatStep,
    initializeLiveChatSetup,
    initializeEmailSetup,
  };
};

export default useToDoList;
