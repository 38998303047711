import React, { useCallback } from "react";
import styles from "./AddShortNotes.module.scss";
import hii from "src/assets/images/hifi.gif";
import toggle from "src/assets/images/chat/blackToggle.png";
import { AddFeatureModalTypes } from "../../../AddFeaturesModal";

import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  addArticleIdToFeatured,
  fetchAllFeaturedArticles,
  resetFeaturedArticlesData,
} from "src/store/slices/liveChatSetting/chatWidgetSetting/knowledgeBased/knowledgeBasedSetting.slice";
import useFeaturedArticles from "../../../../../../CustomHooks/useFeaturedArticles";
import { useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
interface Props {
  onHide: Function;
  setShowComponent: (value: AddFeatureModalTypes) => void;
}
const AddShortNotes = ({ onHide, setShowComponent }: Props) => {
  const { type, featuredArticles } = useAppSelector(
    (state) => state.knowledgeBasedSettings.activeFeaturedArticle
  );
  const dispatch = useAppDispatch();

  const { integrationId } = useParams();

  const { submitLoading, handleFeaturedArticleSubmit } = useFeaturedArticles({
    integrationId: integrationId,
  });

  const handleBack = useCallback(() => {
      setShowComponent("featuredArticle"); //09 fix back button click,when click on back it should show featuredarticle page
  }, []);

  const handleSuccessCallback = useCallback(() => {
    setShowComponent("success");
    dispatch(resetFeaturedArticlesData());
    if (integrationId) {
      dispatch(fetchAllFeaturedArticles({ integrationId: integrationId }));
    }
  }, []);

  const handleSubmit = useCallback(() => {
    handleFeaturedArticleSubmit(handleSuccessCallback);
  }, []);

  const handleOnChangeShortTitle = useCallback(
    (featuredArticle: any, e: any) => {
      dispatch(
        addArticleIdToFeatured({
          ...featuredArticle,
          shortTitle: e.target.value,
        })
      );
    },
    []
  );

  const getArticleText = useCallback(
    (featuredArticles: any, indexNum: number) => {
      const featuredArticle = featuredArticles.find(
        (elem: any, index: number) => index === indexNum
      );

      if (featuredArticle?.shortTitle) {
        return featuredArticle.shortTitle;
      }

      return featuredArticle?.articleName;
    },
    []
  );

  function ToggleIconClosedURL(color: string) {
    const data = `<svg xmlns="http://www.w3.org/2000/svg" width="55" height="55"><defs><filter id="prefix__a" x="0" y="0" width="55" height="55" filterUnits="userSpaceOnUse"><feOffset dy="3"/><feGaussianBlur stdDeviation="3" result="blur"/><feFlood flood-opacity=".161"/><feComposite operator="in" in2="blur"/><feComposite in="SourceGraphic"/></filter></defs><g data-name="Group 3646"><g filter="url(#prefix__a)"><path data-name="Rectangle 1486" d="M27.5 6A18.5 18.5 0 0146 24.5 18.5 18.5 0 0127.5 43H9V24.5A18.5 18.5 0 0127.5 6z" fill="${color}"/></g><path data-name="Icon feather-message-square" d="M35.325 28.884a1.814 1.814 0 01-1.813 1.816H22.628L19 34.325V19.814A1.814 1.814 0 0120.814 18h12.7a1.814 1.814 0 011.814 1.814z" fill="#fff"/></g></svg>`;
    return `data:image/svg+xml;base64,${window.btoa(data)}`;
  }
  
  return (
    <div className="d-flex flex-wrap">
      <div className={`p-4 ${styles.shortWrapper}`}>
        <h4 className={`${styles.heading}`}>Add short titles</h4>
        <span className={`${styles.subHeading}`}>
          Add short titles of characters 200 characters only. Which will appear
          in preview
        </span>
        <div className="mt-3">
          {Object.values(featuredArticles).map((featuredArticle) => {
            return (
              <div className="mb-3" key={featuredArticle.articleId}>
                <label
                  htmlFor="text"
                  className={`form-label ${styles.formLabel}`}
                >
                  {featuredArticle.articleName}
                </label>
                <div className={`d-flex align-items-center ${styles.formBox}`}>
                  <input
                    type="email"
                    className={`form-control text-truncate ${styles.formInput}`}
                    id="text"
                    maxLength={200}
                    value={featuredArticle?.shortTitle ?? ""}
                    placeholder="Type short heading eg. Get exciting offers !"
                    onChange={(e) =>
                      handleOnChangeShortTitle(featuredArticle, e)
                    }
                  />
                  <span className={`${styles.textLimit}`}>
                    {featuredArticle?.shortTitle?.length ?? 0}/200
                  </span>
                  <span className="ps-2">
                    <i className="fa-regular fa-face-smile"></i>
                  </span>
                </div>
              </div>
            );
          })}

          {/*<div className="mb-3">
            <label htmlFor="text" className={`form-label ${styles.formLabel}`}>
              #3 tips to maintain your acme shoes
            </label>
            <div className={`d-flex align-items-center ${styles.formBox}`}>
              <input
                type="email"
                className={`form-control text-truncate ${styles.formInput}`}
                id="text"
                placeholder="Type short heading eg. Get exciting offers !"
              />
              <span className={`${styles.textLimit}`}>0/200</span>
              <span className="ps-2">
                <i className="fa-regular fa-face-smile"></i>
              </span>
            </div>
          </div>
          <div className="mb-3">
            <label htmlFor="text" className={`form-label ${styles.formLabel}`}>
              What are the costs of shipping to NYC ?
            </label>
            <div className={`d-flex align-items-center ${styles.formBox}`}>
              <input
                type="email"
                className={`form-control text-truncate ${styles.formInput}`}
                id="text"
                placeholder="Type short heading eg. Get exciting offers !"
              />
              <span className={`${styles.textLimit}`}>0/200</span>
              <span className="ps-2">
                <i className="fa-regular fa-face-smile"></i>
              </span>
            </div>
          </div>
          <div className="mb-3">
            <label htmlFor="text" className={`form-label ${styles.formLabel}`}>
              How can we claim a warranty in the order ?
            </label>
            <div className={`d-flex align-items-center ${styles.formBox}`}>
              <input
                type="email"
                className={`form-control text-truncate ${styles.formInput}`}
                id="text"
                placeholder="Type short heading eg. Get exciting offers !"
              />
              <span className={`${styles.textLimit}`}>0/200</span>
              <span className="ps-2">
                <i className="fa-regular fa-face-smile"></i>
              </span>
            </div>
          </div> */}
        </div>
        <div className={`mt-3 ${styles.actionBtn}`}>
          <button
            className={`d-flex justify-content-center align-items-center ${styles.backBtn}`}
            onClick={handleBack}
          >
            <span className={`ps-1 pe-2 ${styles.arrow}`}>
              <i className="fa-solid fa-chevron-left"></i>
            </span>
            <span> Back </span>
          </button>
          <button
            className={`d-flex justify-content-center align-items-center ${styles.nextBtn}`}
            disabled={submitLoading}
            onClick={handleSubmit}
          >
            {submitLoading === true ? (
              <Spinner
                className="my-auto mx-1"
                animation="border"
                color="white"
                size="sm"
              />
            ) : (
              <>
                <span> Publish</span>
                <span className={`ps-2 pe-1 ${styles.arrow}`}>
                  <i className="fa-solid fa-chevron-right"></i>
                </span>
              </>
            )}
          </button>
        </div>
      </div>
      <div className={`p-2 p-md-2 p-lg-4 ${styles.widgetWrapper}`}>
        <div className="">
          <div className={`pe-2 pt-32 mb-2 ms-auto ${styles.questBox}`}>
            <img src={hii} alt="" className={`${styles.hifi}`} />
            <span>Have Questions?</span>
          </div>
          <div className={`py-2 w-100 my-1 ${styles.questions}`}>
            <span className={`text-truncate d-inline-block ${styles.queText}`}>
              {getArticleText(Object.values(featuredArticles), 0)}
            </span>
            <span className={`${styles.arrowRight}`}>
              <i className="fa-solid fa-arrow-right"></i>
            </span>
          </div>
          {Object.values(featuredArticles).length >= 2 ? (
            <>
              <div className={`py-2 w-100 my-1 ${styles.questions}`}>
                <span
                  className={`text-truncate d-inline-block ${styles.queText}`}
                >
                  {getArticleText(Object.values(featuredArticles), 1)}
                </span>
                <span className={`${styles.arrowRight}`}>
                  <i className="fa-solid fa-arrow-right"></i>
                </span>
              </div>
              <div
                className={`${
                  Object.values(featuredArticles).length >= 4 ? "d-flex" : ""
                }`}
              >
                {Object.values(featuredArticles).length >= 3 ? (
                  <div
                    className={`py-2 ${
                      Object.values(featuredArticles).length >= 4
                        ? styles.questionWidth
                        : "w-100"
                    } my-1 ${styles.questions}`}
                  >
                    <span
                      className={`text-truncate d-inline-block ${styles.queText}`}
                    >
                      {getArticleText(Object.values(featuredArticles), 2)}
                    </span>
                    <span className={`${styles.arrowRight}`}>
                      <i className="fa-solid fa-arrow-right"></i>
                    </span>
                  </div>
                ) : null}
                {Object.values(featuredArticles).length >= 4 ? (
                  <div
                    className={`py-2 w-50  my-1 ${styles.questionPaddingRight} ${styles.questions}`}
                  >
                    <span
                      className={`text-truncate d-inline-block ${styles.queText}`}
                    >
                      {getArticleText(Object.values(featuredArticles), 3)}
                    </span>
                    <span className={`${styles.arrowRight}`}>
                      <i className="fa-solid fa-arrow-right"></i>
                    </span>
                  </div>
                ) : null}
              </div>
            </>
          ) : null}

          <div className="d-flex justify-content-end mt-3">
            {/* <img src={toggle} alt="" /> */}
            <img
            src={
               ToggleIconClosedURL(
                     "#000000"
                  )
            }
            className={`img-fluid cursor-pointer toggle-btn black-toggle ${styles.rotateImg}`}
          />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddShortNotes;
