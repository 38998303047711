import { GetConfigModalParams } from "src/features/ReturnAutoWorkFlow/children/ConfigModals/oldReturnModals.types";
import { axiosJSON } from "src/globals/axiosEndPoints";

interface MetaData {
  count: number;
  hasNextPage: boolean;
}

export interface AllProductTags {
  data: string[];
  metaData: MetaData;
}

export interface GetAllProductTagsPayload extends GetConfigModalParams {
  limit: number;
}

export async function getAllProductTags(payload: GetAllProductTagsPayload) {
  const { data: res } = await axiosJSON.post(
    "/api/setting/automation/refund/product/tags/getAll",
    payload,
  );

  if (res.err || res.error) {
    throw new Error(res.msg ?? res.message ?? "Some Error Occurred.");
  }

  const ret: AllProductTags = {
    data: res.data,
    metaData: res.metaData ?? { count: 0, hasNextPage: false },
  };

  return ret;
}
