import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  fetchTickets,
  removeAllTickets,
  resetBulkActionFilters,
  resetCachedTickets,
} from "src/store/slices/tickets/tickets.slice";
import { resetAddBulkTags } from "src/store/slices/ticketTags/ticketTags.slice";
import { resetBulkAssignTickets } from "src/store/slices/ticketUsers/ticketUsers.slice";
import { resetbulkAddRemarks } from "src/store/slices/ticketRemarks/ticketRemarks.slice";
import { resetBulkTicketsStatuses } from "src/store/slices/ticketStatus/ticketStatus.slice";
import { resetbulkSendMessage } from "src/store/slices/ticketSendMessage/ticketSendMessage.slice";
import { fetchMultipleSidebarData } from "src/store/slices/ticketSidebar/ticketSidebar.slice";
interface Props {
  tableType?: string;
}
function useResetBulkActions(props?: Props) {
  const dispatch = useAppDispatch();
  const { bulkSelectedTicketsIds } = useAppSelector((state) => state.ticket);
  const { bulkAddStatusState } = useAppSelector((state) => state.ticketStatus);
  const { bulkAssignTicketsState } = useAppSelector(
    (state) => state.ticketUsers,
  );
  const { bulkAddTicketTagsState } = useAppSelector(
    (state) => state.ticketTags,
  );
  const { bulkAddRemarksState } = useAppSelector(
    (state) => state.ticketRemarks,
  );
  const { bulkSendMessageState } = useAppSelector(
    (state) => state.ticketSendMessage,
  );

  const resetBulkActions = () => {
    // if there is bulk status change or bulk asignee change, refetch the tickets also
    if (
      bulkSelectedTicketsIds &&
      bulkSelectedTicketsIds.length > 0 &&
      (bulkAddStatusState !== "idle" ||
        bulkAssignTicketsState !== "idle" ||
        bulkAddTicketTagsState !== "idle" ||
        bulkAddRemarksState !== "idle" ||
        bulkSendMessageState !== "idle")
    ) {
      dispatch(resetCachedTickets());
      dispatch(fetchTickets());
      if (props?.tableType && props.tableType.trim() !== "") {
        dispatch(fetchMultipleSidebarData({ views: [props.tableType] }));
      }
    }
    dispatch(removeAllTickets());
    dispatch(resetAddBulkTags());
    dispatch(resetBulkAssignTickets());
    dispatch(resetbulkAddRemarks());
    dispatch(resetBulkTicketsStatuses());
    dispatch(resetbulkSendMessage());
    dispatch(resetBulkActionFilters()); //dispatching reset filter reducer function to reset the bulk action filter state
  };

  return resetBulkActions;
}

export default useResetBulkActions;
