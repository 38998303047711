import { Modal } from "react-bootstrap";
import styles from "./EmailSendSuccess.module.scss";
import successImg from "src/assets/images/success.png";

const EmailSendSuccess = ({
  showModal,
  setShowModal,
  emailF,
  setEmailF,
}: any) => {
  const handleOnHide = () => {
    setShowModal("");
    setEmailF("");
  };

  const handleOk = (e: any) => {
    handleOnHide();
  };

  return (
    <Modal
      show={showModal === "forgotEmailSendSuccess"}
      onHide={handleOnHide}
      dialogClassName={`${styles.modalDialog}`}
      contentClassName={`${styles.modalContent}`}
      id="forgotEmailSendSuccess"
    >
      <div className={`${styles.resetLink}`}>
        {/* <div> */}
        {/* <div className={`${styles.modalDialogInner}`}> */}
        <div className={`${styles.modalContentInner}`}>
          <div className={`${styles.modalHeader}`}></div>
          <div
            className={`${styles.modalBody}`}
            id="forgotEmailSendSuccess__body"
          >
            <div className={`${styles.emailMsg}`}>
              <div className={`${styles.verifiedIcon}`}>
                <img
                  className={`img-fluid ${styles.successImg}`}
                  src={successImg}
                />
              </div>
              <h5 className={`${styles.sentMsgTxt}`}>
                We sent a link to your email {emailF} to reset your password
              </h5>
            </div>
          </div>
          <div className={`${styles.horizontalSeparator}`}></div>
          <div
            className={`${styles.modalFooter}`}
            onClick={(e) => {
              handleOk(e);
            }}
            id="forgotEmailSendSuccess__okBtn"
          >
            <p className={`m-2 ${styles.sentBtnOk}`}>Ok</p>
          </div>
        </div>
        {/* </div> */}
        {/* </div> */}
      </div>
    </Modal>
  );
};

export default EmailSendSuccess;
