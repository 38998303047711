import deleteIcon from "src/assets/images/delete.png";
import dots from "src/assets/images/sixDots.png";
import { IBugCategoriesQuestion } from "src/services/Automation/getAutomationById.service";
import styles from "./CategoryQuestion.module.scss";
import useCategoryQuestion from "./useCategoryQuestion";

interface QuestionsProps {
  questionData: IBugCategoriesQuestion;
  stepId: string;
}

interface CategoryItemProps {
  category: IBugCategoriesQuestion["questionValue"][number];
  idx: number;
  error: string | null;
  isQuestionDisabled: boolean;
  onDelete: (idx: number) => void;
  onNameChange: (idx: number, name: string) => void;
  onDescriptionChange: (idx: number, description: string) => void;
}

const CategoryItem = ({
  category,
  idx,
  error,
  isQuestionDisabled,
  onDelete,
  onNameChange,
  onDescriptionChange,
}: CategoryItemProps) => {
  return (
    <div
      key={idx}
      className={`p-3 ${styles.categoryBox} mb-2`}
    >
      <div className={`d-flex`}>
        <img
          src={dots}
          alt=""
          height={18}
          className="mt-3"
        />
        <div className="ms-2">
          <div className="mb-3">
            <input
              type="text"
              className={`form-control ${styles.categoryInput} ${
                error ? styles.errBorder : ""
              }`}
              disabled={isQuestionDisabled}
              id={`category_name_${idx}`}
              onChange={(e) => onNameChange(idx, e.target.value)}
              value={category.name}
              placeholder="Enter category name"
            />
            {/* Conditional rendering of error message */}
            {error && <div className={`pt-1 ${styles.errorText}`}>{error}</div>}
          </div>
          <div className="">
            <textarea
              className={`form-control ${styles.textBox}`}
              id={`category_description_${idx}`}
              value={category.description ?? ""}
              disabled={isQuestionDisabled}
              rows={2}
              onChange={(e) => onDescriptionChange(idx, e.target.value)}
              placeholder="Description"
            ></textarea>
          </div>
        </div>
        {idx !== 0 && (
          <span
            className={`ps-2 ${isQuestionDisabled ? "opacity-50" : ""}`}
            onClick={() => {
              if (isQuestionDisabled) {
                return;
              }

              onDelete(idx);
            }}
          >
            <img
              src={deleteIcon}
              alt="delete"
              width={14}
              height={19}
              className="cursor-pointer"
            />
          </span>
        )}
      </div>
    </div>
  );
};

const CategoryQuestion = ({ stepId, questionData }: QuestionsProps) => {
  const {
    addMoreCategories,
    deleteCategory,
    errors,
    isQuestionDisabled,
    handleDescriptionChange,
    handleNameChange,
  } = useCategoryQuestion({
    stepId,
    questionData,
  });

  return (
    <div>
      {questionData.questionValue.map((category, idx) => {
        return (
          <CategoryItem
            category={category}
            idx={idx}
            key={idx}
            isQuestionDisabled={isQuestionDisabled}
            error={errors[idx]}
            onDelete={deleteCategory}
            onDescriptionChange={handleDescriptionChange}
            onNameChange={handleNameChange}
          />
        );
      })}

      <div
        className={`cursor-pointer d-flex pt-2 ${
          isQuestionDisabled ? "opacity-50" : ""
        }`}
        onClick={() => {
          if (isQuestionDisabled) {
            return;
          }
          addMoreCategories();
        }}
      >
        <div className="dropdown">
          <div
            className={`me-2 d-flex align-items-center ${styles.addMoreBtn}`}
          >
            <span className="">
              {" "}
              <i className="fa-solid fa-plus"></i>
            </span>
          </div>
        </div>
        <div className="">
          <span className={`${styles.addMoreText}`}>Add more categories</span>
        </div>
      </div>
    </div>
  );
};

export default CategoryQuestion;
