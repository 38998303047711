import { useCallback, useEffect, useRef, useState } from "react";
import Select, {
  ActionMeta,
  components,
  GroupBase,
  MenuListProps,
  MultiValue,
  OptionProps,
  OptionsOrGroups,
  SingleValue,
} from "react-select";
import Dropdown from "react-bootstrap/Dropdown";
import Container from "react-bootstrap/Container";
import styles from "./SelectBox.module.scss";
import dummy from "src/assets/images/chat/user.png";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { AsyncPaginate } from "react-select-async-paginate";
import {
  GetAllBrandData,
  searchBrands,
} from "src/services/Settings/Brands/getAllBrands";

const selectStyles = {
  control: (provided: any) => ({
    ...provided,
    minWidth: 200,
    margin: 8,
    backgroundColor: "white",
    cursor: "pointer",
    
  }),
  container: () => ({
    borderRadius: "8px",
    color: "black",
    fontSize: "14px",
    lineHeight: "21px",
    fontWeight: "500",
    padding:"20px 10px",
    boxShadow: "0px 3px 15px #00000029", 
    border:"none",
  }),
  menu: () => ({ boxShadow: "none" , border:"none" }),
  option: (provided: any, state: { isFocused: any }) => ({
    ...provided,
    color: "black",
    borderRadius: "6px",
    backgroundColor: state.isFocused ? "#F5F5F5" : null,
  }),
};

interface Item {
  value: string;
  label: string;
  price: string;
  imgSrc: string;
}

const Option = (props: any) => {
  return (
    <div>
      <components.Option {...props}>
        <div className="d-flex gap-3">
          <input
            type="checkbox"
            checked={props.isSelected}
            className={`${styles.inputBox}`}
            onChange={() => null}
          />
          {/* {props.data.imgSrc === null ? (
            <></>
          ) : (
            <img
              alt=""
              src={props.data.imgSrc}
              style={{
                maxWidth: "32px",
                maxHeight: "32px",
                objectFit: "cover",
                alignSelf: "center",
                borderRadius: "5px",
              }}
            ></img>
          )} */}

          <div className="d-flex flex-column w-100 ">
            <label className={`${styles.labelName}`}>{props.label}</label>
            <label className={`${styles.labelMail}`}>{props.data.price}</label>
          </div>
        </div>
      </components.Option>
    </div>
  );
};

const colorOptions: OptionsOrGroups<Item, GroupBase<Item>> = [
  // {
  //   value: "Acme1",
  //   label: "Acme",
  //   price: "support@acme.com",
  //   imgSrc: dummy,
  // },
  // {
  //   value: "Acme2",
  //   label: "Acme",
  //   price: "support@acme.com",
  //   imgSrc: dummy,
  // },
  // {
  //   value: "Acme3",
  //   label: "Acme",
  //   price: "support@acme.com",
  //   imgSrc: dummy,
  // },
  // {
  //   value: "Acme4",
  //   label: "Acme",
  //   price: "support@acme.com",
  //   imgSrc: dummy,
  // },
  // {
  //   value: "Acme5",
  //   label: "Acme",
  //   price: "support@acme.com",
  //   imgSrc: dummy,
  // },
];

// const allOptions = [
//   { label: "Select all", value: "*", imgSrc: null },
//   ...colorOptions,
// ];

function SelectBox({
  // allOptions,
  updateSelectedOption,
  intialSelectedOptions,
  showWarning = false,
}: {
  // allOptions:any,
  updateSelectedOption: Function;
  intialSelectedOptions: any;
  showWarning?: boolean;
}) {
  const initialFetchOption = useRef(false);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const currentOptions = useRef();
  const [selectedOptions, setSelectedOptions] = useState<
    OptionsOrGroups<any, GroupBase<any>>
  >(intialSelectedOptions);

  const [isOptionsInitial, setIsOptionsInitial] = useState(true);

  const isMulti = true;
  const dispatch = useAppDispatch();
  const [ddOpen, setDdOpen] = useState(false);
  const dropdownArrowSpanRef = useRef<any>();
  const dropdownRef = useRef<any>();
  useEffect(() => {
    if (!initialFetchOption.current) {
      fetchBrands("", undefined, undefined);
      initialFetchOption.current = true;
    }
  }, []);
  useEffect(() => {
    if (isOptionsInitial && selectedOptions.length == 0) {
      return;
    }

    updateSelectedOption(selectedOptions);

    setIsOptionsInitial(false);
  }, [selectedOptions]);

  const onSelectChange = (
    newValues: SingleValue<any> | MultiValue<any>,
    actionMeta: ActionMeta<any>
  ) => {
    let newSelectedOptions =
      (Array.isArray(newValues) ? [...newValues] : newValues) ?? [];

    let option;

    if (actionMeta.action === "remove-value") {
      option = actionMeta.removedValue;
    } else {
      option = actionMeta.option;
    }

    //If  For All Selected
    if (
      isMulti &&
      (newSelectedOptions as MultiValue<Item>).length &&
      (option as Item).value === "*"
    ) {
      if (actionMeta.action === "select-option") {
        const allSelectedOptions = newSelectedOptions.filter(
          (option: any) => option.value !== "*"
        );
        setSelectedOptions(allSelectedOptions);
      } else if (actionMeta.action === "deselect-option") {
        setSelectedOptions([]);
      }
    } else {
      if (!isMulti) {
        // Single value
        setSelectedOptions((newSelectedOptions && newSelectedOptions) || null);
      } else {
        // Multi value
        const allSelectedOptions = newSelectedOptions.filter(
          (option: any) => option.value !== "*"
        );

        setSelectedOptions(allSelectedOptions);
      }
    }
  };

  let dropdownMessage = "--Select--";
  if (selectedOptions.length === 1 && selectedOptions[0].value !== "*") {
    dropdownMessage = `${
      selectedOptions[0]?.label.length === 0
        ? selectedOptions[0]?.value
        : selectedOptions[0]?.label
    }`;
  } else if (selectedOptions.length === totalItemCount) {
    dropdownMessage = `All Email selected`;
  } else if (selectedOptions.length > 1) {
    dropdownMessage = `${selectedOptions.length} Email Selected`;
  }

  const [showPopover, setShowPopover] = useState(true);

  const fetchBrands = useCallback(
    async (searchTerm: string, loadOptions: any, additional: any) => {
      searchTerm = searchTerm.trim();
      const limit = 25;

      const { brands, metaData }: GetAllBrandData = await searchBrands({
        searchTerm: searchTerm ?? "",
        start: additional ? additional.page : 0,
        limit,
        onlyVerified: true,
      });
      if (searchTerm === "") {
        setTotalItemCount(metaData.total);
      }
      const retValue: any = Object.values(brands).map((brand, key) => {
        return {
          id: brand.id,
          label: brand.name,
          price: brand.email,
          value: brand.email,
        };
      });
      // console.log(data.map((values, key) => values.email));
      return {
        options: retValue,
        hasMore: metaData.count === limit,
        additional: {
          page: additional ? additional.page + metaData.count : metaData.count,
        },
      };
    },
    []
  );

  return (
    <div className={`${styles.thirdSelect}`}>
      <Dropdown onToggle={setDdOpen}>
        <Dropdown.Toggle
          variant=""
          id="dropdown-basic"
          as="div"
          bsPrefix={`d-flex align-items-center justify-content-between px-2 ${
            showWarning ? "redBorder rounded" : ""
          } ${styles.selectDropdown}`}
        >
          <span className={`${styles.msgText}`}> {dropdownMessage} </span>{" "}
          <span
            ref={dropdownArrowSpanRef}
            className={`ps-2 ${styles.downArrow}`}
          >
            {" "}
            {ddOpen ? (
              <i
                className={`fa-solid fa-caret-up
                        `}
              ></i>
            ) : (
              <i
                className={`fa-solid fa-caret-down
                          `}
              ></i>
            )}
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu bsPrefix={`dropdown-menu border-0 ${styles.menuBox}`}>
          <AsyncPaginate
            autoFocus
            backspaceRemovesValue={false}
            components={{ IndicatorSeparator: null, Option }}
            controlShouldRenderValue={false}
            hideSelectedOptions={false}
            isClearable={false}
            inputValue={searchInput}
            onInputChange={(inputValue, actionMeta) => {
              if (
                actionMeta.action !== "input-blur" &&
                actionMeta.action !== "menu-close" &&
                actionMeta.action !== "set-value"
              ) {
                setSearchInput(inputValue);
              }
            }}
            loadOptions={async (searchTerm, loadOptions, additional) => {
              const data = await fetchBrands(
                searchTerm,
                loadOptions,
                additional
              );
              currentOptions.current = data.options;
              return data;
            }}
            menuIsOpen
            isMulti
            onChange={onSelectChange}
            // options={allOptions}
            placeholder="Search..."
            styles={selectStyles}
            tabSelectsValue={false}
            value={selectedOptions}
            debounceTimeout={300}
          />
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

export default SelectBox;
