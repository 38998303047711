/**
 * This file is the component file.
 *
 * @author Yash Aditya
 */

import { useCallback, useMemo, useState } from "react";
import styles from "./TimeSelect.module.scss";
import { Dropdown } from "react-bootstrap";
import TimePicker from "src/components/TimePicker/TimePicker";

// Define the properties that TimeSelect component expects
export interface TimeSelectProps {
  value: null | string; // Format - HH:mm:ss
  onChange: (value: null | string) => void;
  disabled?: boolean;
}

// TimeSelect component definition
function TimeSelect({ value, onChange, disabled = false }: TimeSelectProps) {
  // Used for time dropdown toggle state.
  const [toggle, setToggle] = useState(false);

  // Memoize the startDate based on the provided value
  const getSelectedTime = useCallback(() => {
    if (value) {
      // Create a new Date object with the provided value
      return new Date(new Date().toISOString().split("T")[0] + "T" + value);
    } else {
      // If the value is null, set startDate to null
      return null;
    }
  }, [value]);

  const [selectedTime, setSelectedTime] = useState(getSelectedTime());

  // Updating time on updating value from parent component.
  useMemo(() => {
    setSelectedTime(getSelectedTime());
  }, [getSelectedTime, value]);

  // To open and close the dropdown dynamically.
  const handleOnToggle = useCallback(
    (open: boolean) => {
      if (!disabled) {
        setToggle(open);
        if (open === false) {
          setSelectedTime(getSelectedTime());
        }
      }
    },
    [getSelectedTime, value, disabled],
  );

  // To handle the close of time dropdown.
  const handleClose = useCallback(() => {
    setToggle(false);
    setSelectedTime(getSelectedTime());
  }, [getSelectedTime, value]);

  // To handle the save of time dropdown.
  const handleSave = useCallback(() => {
    setToggle(false);
    if (selectedTime) {
      // Extract hours, minutes, and seconds from the selected date
      const hh = selectedTime.getHours();
      const mm = selectedTime.getMinutes();
      const ss = selectedTime.getSeconds();
      // Format the time string and call the onChange function
      onChange(
        `${hh < 10 ? `0${hh}` : hh}:${mm < 10 ? `0${mm}` : mm}:${
          ss < 10 ? `0${ss}` : ss
        }`,
      );
    } else {
      // If date is null, call onChange with null
      onChange(null);
    }
  }, [onChange, selectedTime]);

  return (
    <Dropdown
      show={toggle}
      onToggle={handleOnToggle}
      className="ps-2 ps-md-0 mb-2 mb-md-0"
    >
      <Dropdown.Toggle
        as="div"
        bsPrefix={`px-2 d-flex align-items-center ms-md-2 ${
          styles.selectTime
        } ${disabled ? styles.disabled : ""} position-relative`}
      >
        <i className={`fa-regular fa-clock mx-0 ${styles.clockPos}`}></i>
        <span className="ms-2 me-auto">{value}</span>
        <i
          className={`fa-solid me-0 ms-auto fa-chevron-${
            toggle ? "up" : "down"
          } ${styles.clockPos}`}
        ></i>
      </Dropdown.Toggle>
      <Dropdown.Menu
        as="div"
        className={`p-2 ${styles.dropdown}`}
      >
        <div className={`${styles.heading}`}>Set time</div>
        <div className={`${styles.setTimes} p-2`}>
          {toggle && (
            <TimePicker
              selectedTime={selectedTime}
              onChangeTime={setSelectedTime}
            />
          )}
        </div>
        <div className="d-flex justify-content-between">
          <div
            className={`${styles.cancel}`}
            onClick={handleClose}
          >
            Cancel
          </div>
          <div
            className={`${styles.save}`}
            onClick={handleSave}
          >
            Save
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}

// Export the TimeSelect component
export default TimeSelect;
