import styles from "./AutomationTab.module.scss";

function AutomationTab(props: { children: React.ReactNode; idx: number }) {
  return (
    <div
      className={`d-flex ${styles.editWrapper} ${
        props.idx === 0 ? "pt-1  px-2" : null
      }`}
    >
      {props.children}
    </div>
  );
}

export default AutomationTab;
