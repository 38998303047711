import { axiosJSON } from "src/globals/axiosEndPoints";

export interface IUpdatePrevTicketTemplate {
  templateId: number | string;
  ticketId: number | string;
}

export async function updatePreviouslyUsedTicketInTemplate(
  apiPayload: IUpdatePrevTicketTemplate
) {
  // console.log(`apiPayload:: ${apiPayload}`);
  const { data } = await axiosJSON.post(
    "/api/template/updatePreviouslyUsedTicketInTemplate",
    apiPayload
  );

  // console.log(data);

  if (data.error === true) {
    throw new Error(
      "Some Error Occurred while updating template used in ticket"
    );
  }

  return data;
}
