import styles from "./ReturnType.module.scss";
import refresh from "src/assets/images/chat/refreshButton.png";
import TypeCard from "./Children/TypeCard/TypeCard";
import dummy1 from "src/assets/images/order1.png";
import dummy2 from "src/assets/images/order2.png";
const ReturnType = () => {
  return (
    <>
      <div>
        <div className={`d-flex align-items-center`}>
          <div>
            <img
              src={refresh}
              alt="refresh"
              className={`${styles.refresh}`}
            />{" "}
          </div>
          <span className={`ms-1 ${styles.heading}`}>
            Initiate Return/Exchange{" "}
          </span>
        </div>
        <div className={`py-1`}>
          <span className={`d-block ${styles.selItem}`}>
            Select how do you want to return /exchange these items :
          </span>
        </div>

        <div>
          <TypeCard
            orderTitle={"Acme T-shirt"}
            orderImg={dummy1}
            orderData1={"$ 789 x 1"}
            orderData2={"SKU : 0101"}
            showComment={""}
          />
          {/* <TypeCard orderTitle={"Acme T-shirt"} orderImg={dummy1} orderData1={"$ 789 x 1"} orderData2={"SKU : 0101"} showComment={"d-none"} />
          <TypeCard orderTitle={""} orderImg={dummy2} orderData1={"$ 30 x 1"} orderData2={"SKU : 0101"} showComment={"d-flex"} /> */}
        </div>
      </div>
    </>
  );
};
export default ReturnType;
