import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";
import { Group } from "../getAutomationById.service";

export interface UpdateConditionGroupParams extends Partial<Group> {
  type: string;
  integrationId: string;
}

async function updateConditionGroup({
  type,
  ...params
}: UpdateConditionGroupParams) {
  const apiEndPoint = IS_TEST_ACTIVE
    ? `${process.env.REACT_APP_TEST_SITE_URL}/${type}/updateConditionGroup`
    : `/api/automation/${type}/updateConditionGroup`;
  const { data: res } = await axiosJSON.post(apiEndPoint, params);

  if (res.error || res.err) {
    throw new Error(res.msg ?? res.message ?? "Something went wrong!");
  }

  const ret: Group = {
    ...res.data,
    type,
  };

  return ret;
}

export default updateConditionGroup;
