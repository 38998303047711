import styles from "./CategoryItem.module.scss";

interface ITemplateCategory {
  id: number;
  name: string;
  subCategoryExists: boolean;
}

const CategoryItem = ({
  category,
  selectedCategoryHandler,
  selectedCategory,
}: {
  category: ITemplateCategory;
  selectedCategoryHandler: any;
  selectedCategory: any;
}) => {
  return (
    <div
      className={`ms-2 mb-2 ${styles.categoryBtn} ${
        selectedCategory && selectedCategory.id == category.id
          ? styles.activeCategory
          : ""
      }`}
      onClick={() => {
        if (selectedCategory.id != category.id) {
          selectedCategoryHandler(category);
        }
      }}
      id={`template_${category.id}`}
    >
      <div
        className={`cursor-pointer ${styles.bar} ${
          selectedCategory && selectedCategory.id == category.id
            ? styles.activeBar
            : ""
        }`}
      ></div>
      <span className="w-100 text-truncate">{category.name}</span>
    </div>
  );
};

export default CategoryItem;
