import { useAppDispatch, useAppSelector } from "src/store/store";
import useResetBulkActions from "src/hooks/useResetBulkActions";
import BaseFooterButtonLight from "src/components/BulkActionComponents/commons/BaseFooterButtonLight";
import BaseFooterButtonSolid from "src/components/BulkActionComponents/commons/BaseFooterButtonSolid";
import { bulkSendMessage } from "src/store/slices/ticketSendMessage/ticketSendMessage.slice";

const ModalFooter = ({
  setModalShow,
}: {
  setModalShow: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const resetBulkActions = useResetBulkActions();
  const { message, bulkSendMessageState, integrationFailureTickets } =
    useAppSelector((state) => state.ticketSendMessage);
  const dispatch = useAppDispatch();

  const handleOnClickAddBulkRemark = (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault();
    dispatch(bulkSendMessage());
  };

  const handleOnClickExit = (e: any) => {
    e.preventDefault();
    resetBulkActions();
  };

  const handleOnClickAddMore = (e: any) => {
    e.preventDefault();
    setModalShow(false);
    resetBulkActions(); //resetting bulk action filters, in new select all filter implementation we can't let user add more cause select all works based on filters
  };

  return (
    <>
      {bulkSendMessageState === "fulfilled" ||
      (bulkSendMessageState === "rejected" && integrationFailureTickets) ? (
        <div
          className={`w-100 d-flex justify-content-between`}
          id="sendMessageExitBtn"
        >
          <BaseFooterButtonLight onClick={handleOnClickExit}>
            Exit Bulk actions
          </BaseFooterButtonLight>

          <BaseFooterButtonSolid
            onClick={handleOnClickAddMore}
            id="sendMessageAddMoreBtn"
          >
            Add more
          </BaseFooterButtonSolid>
        </div>
      ) : (
        <BaseFooterButtonSolid
          onClick={handleOnClickAddBulkRemark}
          style={message.trim() === "" ? { opacity: "50%" } : undefined}
          disabled={message.trim() === "" ? true : false}
          id="sendMessageBtn"
        >
          Send Message
        </BaseFooterButtonSolid>
      )}
    </>
  );
};

export default ModalFooter;
