/**
 * This file is the custome hook file.
 * It contains the useAllCategory hook which is explained below.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

/* eslint-disable react-hooks/exhaustive-deps */
import { useInfiniteQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  reportCurrentStatusActions,
  useReportFilters,
} from "src/routes/Report/hooks/reportFilters/useReportFilters";
import {
  Category,
  GetAllCategoryV2Params,
  getAllCategoryV2,
} from "src/services/KnowledgeBase/Analytics/getAllCategoryV2.service";

/**
 * This hook is used in AllCategory component to handle the component logic.
 */
function useAllCategory() {
  const [ddOpen, setDdOpen] = useState(false);
  const dropdownArrowSpanRef = useRef<any>();
  const endPoint = window.location.pathname;

  const { integrationId } = useParams();
  const pageIntegrationId = integrationId
    ? decodeURIComponent(integrationId)
    : "";

  const payload: GetAllCategoryV2Params = useMemo(() => {
    return {
      start: 0,
      limit: 15,
      integrationId: pageIntegrationId,
    };
  }, [pageIntegrationId]);

  /**
   * useInfiniteQuery return the fetched data for infinite scroll.
   */
  const {
    data,
    isLoading,
    isError,
    hasNextPage,
    fetchNextPage,
    refetch,
    isRefetching,
    isFetchingNextPage,
    status,
    fetchStatus,
  } = useInfiniteQuery({
    queryKey: ["GetAllCategoryV2", payload],
    /**
     * Here we are using getAllCategoryV2 to get the all category data based on the pageParam parameters applied.
     */
    queryFn: ({ pageParam = payload }) => getAllCategoryV2(pageParam),
    /**
     * This is the callback function used when the infinite query is trigered to fetch the next page of infinite scroll.
     */
    getNextPageParam: (lastPage: any, allPages) => {
      const data = allPages.flatMap((data: any) => data.data);
      if (data.length < lastPage.metaData?.total) {
        const nextPageParam = {
          ...payload,
          start: data.length,
        };
        return nextPageParam;
      }
      return null;
    },
    enabled: ((payload.integrationId ?? "") + "").trim() ? true : false,
  });

  const [allCategories, setAllCategories] = useState<Category[] | undefined>();

  useEffect(() => {
    if (data) {
      /**
       * Page returns the array of array so flat mapping them to get the data in required format.
       */
      const allCateogories = data?.pages.flatMap(
        (data: any) => data.data
      ) as any as Category[];
      /**
       * Then setting up the data in allCategories state
       */
      setAllCategories(allCateogories);
    }
  }, [data]);

  /**
   * Extracting all the categories ids in an array.
   */
  const categoryIds = useMemo(() => {
    return allCategories?.map((cat) => cat.categoryId.toString());
  }, [allCategories]);

  const { currentStatus, dispatch } = useReportFilters();

  const selectedCategories = useMemo(() => {
    return currentStatus.selectedCategories;
  }, [currentStatus]);

  /**
   * Function is used to select all the categories in the top level filters.
   */
  const selectAllCategories = useCallback(
    (e: any) => {
      if (e.target.checked) {
        dispatch([
          reportCurrentStatusActions.setFilters,
          {
            selectedCategories: categoryIds,
          },
        ]);
      } else {
        dispatch([
          reportCurrentStatusActions.setFilters,
          { selectedCategories: [] },
        ]);
      }
    },
    [categoryIds]
  );

  /**
   * Function is used to clear all the categories from the top level filters.
   */
  const clearAllCategories = useCallback(() => {
    dispatch([
      reportCurrentStatusActions.setFilters,
      { selectedCategories: [] },
    ]);
  }, []);

  /**
   * Function is used to check or uncheck all the categories in the top level filters.
   */
  const checkUncheckCategories = useCallback(
    (e: any) => {
      if (e.target.checked) {
        dispatch([
          reportCurrentStatusActions.setFilters,
          { selectedCategories: [...selectedCategories, e.target.value] },
        ]);
      } else {
        dispatch([
          reportCurrentStatusActions.setFilters,
          {
            selectedCategories: selectedCategories.filter(
              (id: any) => id !== e.target.value
            ),
          },
        ]);
      }
    },
    [selectedCategories]
  );

  return {
    ddOpen,
    setDdOpen,
    dropdownArrowSpanRef,
    isLoading,
    isError,
    hasNextPage,
    fetchNextPage,
    refetch,
    isRefetching,
    isFetchingNextPage,
    status,
    fetchStatus,
    selectAllCategories,
    clearAllCategories,
    checkUncheckCategories,
    allCategories,
    selectedCategories,
    categoryIds,
  };
}

export default useAllCategory;
