import axios from "axios";
import { axiosJSON } from "src/globals/axiosEndPoints";

interface DeleteGoalSetting {
  goalId : number|string;
}

export interface DeleteGoalSettingParams {
  goalId : number|string;
  integrationId: number | string;
}



export async function deleteGoalSetting (params : DeleteGoalSettingParams) {

    const { data:res } = await axiosJSON.delete(
        "/api/chatSetting/goals/delete",{
        params
      }
    );

    if(res.error === true )
    {
      throw new Error(res.message as string)
    }

    return res.data as DeleteGoalSetting 
  } 
