import { createAsyncThunk } from "@reduxjs/toolkit";
import getAllReturnReasonListService from "src/services/ReturnAutoWorkFlow/OldModals/General/getAllReturnReasonList.service";
import getRestockingFeeService, {
  RestockingFeeTab,
} from "src/services/ReturnAutoWorkFlow/OldModals/restockingFee/getRestockingFee.service";
import getRestockingFeeConditionsService from "src/services/ReturnAutoWorkFlow/OldModals/restockingFee/getRestockingFeeConditions.service";
import updateRestockingFeeService from "src/services/ReturnAutoWorkFlow/OldModals/restockingFee/updateRestockingFee.service";
import { getValueFromConditionType } from "src/features/ReturnAutoWorkFlow/children/ConfigModals/store/returnModalsSliceHelpers";
import { RootState } from "src/store/store";
import { SelectedConditionType } from "../../../oldReturnModals.types";
import { ReturnModalsThunkPayload } from "../../returnModalsStore";
import {
  IRestockingFeeType,
  validateRestockingFee,
} from "./restockingFee.slice";

interface UpdateRestockingFee extends ReturnModalsThunkPayload {
  integrationId: string;
  isSelectReasonEnabled: boolean;
}

export const updateRestockingFeeModal = createAsyncThunk(
  "updateRestockingFeeModal",
  async (payload: UpdateRestockingFee, { dispatch, getState }) => {
    const state = getState() as RootState;

    const additionalCharges = state.restockingFee.additionalCharges;

    const integrationId = payload.integrationId;
    const configTabs = additionalCharges.map((additionalCharge) => {
      const operatorSymbolMap = state.restockingFee.operatorSymbolMap;

      return {
        configTabId: Number(additionalCharge.additionalChargeId),
        configTabName: additionalCharge.additionalChargeName,
        restockingFee: {
          price: additionalCharge.restockingFees.amount + "",
          priceType: additionalCharge.restockingFees.priceType,
        },
        restockingFeeMessage: additionalCharge.messageForCustomer.value,
        conditions: additionalCharge.conditions.map((condition) => {
          const selectedVariable = state.restockingFee.variableNames.find(
            (variable: any) => variable.name === condition.variableName,
          );

          if (selectedVariable) {
            return {
              conditionId: Number(condition.id),
              conditionTypeId: selectedVariable.id,

              conditionValue: getValueFromConditionType(condition.values),
              operator: Object.keys(operatorSymbolMap).find((key) => {
                if (
                  (condition.operator === "are less than" ||
                    condition.operator === "is less than") &&
                  key === "<"
                ) {
                  return true;
                }
                return operatorSymbolMap[key] === condition.operator;
              }),
            } as SelectedConditionType;
          } else {
            return {
              conditionId: Number(condition.id),
              conditionTypeId: -1,
            };
          }
        }),
        selectReturnReasons: additionalCharge.selectReturnReason?.map(
          (condition) => {
            const selectedVariable = state.restockingFee.variableNames.find(
              (variable: any) => variable.name === condition.variableName,
            );

            if (selectedVariable) {
              return {
                conditionId: Number(condition.id),
                conditionTypeId: selectedVariable.id,

                conditionValue: getValueFromConditionType(condition.values),
                operator: Object.keys(operatorSymbolMap).find((key) => {
                  if (
                    (condition.operator === "are less than" ||
                      condition.operator === "is less than") &&
                    key === "<"
                  ) {
                    return true;
                  }
                  return operatorSymbolMap[key] === condition.operator;
                }),
              } as SelectedConditionType;
            } else {
              return {
                conditionId: Number(condition.id),
                conditionTypeId: -1,
              };
            }
          },
        ),
      } as RestockingFeeTab;
    });
    await updateRestockingFeeService({ integrationId, configTabs });
    setTimeout(() => {
      if (payload.callback) {
        payload.callback();
      }
    }, 0);

    // Refetch data for tabs

    const configRes = await getRestockingFeeService({ integrationId }); // async action

    return {
      configRes,
      isSelectReasonEnabled: payload.isSelectReasonEnabled,
    };
  },
);

interface FetchRestockingFeePayload {
  integrationId: string;
  isSelectReasonEnabled: boolean;
  restockingFee: IRestockingFeeType;
}

export const fetchRestockingFeeModal = createAsyncThunk(
  "fetchRestockingFeeModal",
  async (payload: FetchRestockingFeePayload) => {
    const integrationId = payload.integrationId;

    const [configRes, conditionRes, returnReasons] = await Promise.all([
      getRestockingFeeService({ integrationId }),
      getRestockingFeeConditionsService({ integrationId }),
      getAllReturnReasonListService({ integrationId }), // Adding to get default return reasons for a state variable to check for validations based on moreQuestions
    ]);

    return {
      conditionRes,
      configRes,
      isSelectReasonEnabled: payload.isSelectReasonEnabled,
      restockFee: payload.restockingFee,
      returnReasonList: returnReasons.reasons ?? [],
    };
  },
);

export const validateRestockingFeeConditions = createAsyncThunk(
  "validateRestockingFeeConditions",
  async (_: void, { getState, dispatch }) => {
    await dispatch(validateRestockingFee());

    const state = getState() as RootState;

    let errorCount = 0;
    state.restockingFee.additionalCharges.forEach((data) => {
      if (data.errorCount !== 0) {
        errorCount += 1;
      }
    });

    return errorCount;
  },
);
