import { useEffect } from "react";
import { TinyMCEEditorProps } from "src/interfaces/TinyMCEContainer/ITinyMCEContainer";

import { Editor as TinyMceEditor } from "tinymce";
interface Props {
  autoCompleteMenuConfigs: TinyMCEEditorProps["autoCompleteMenuConfigs"];
  editorRef: React.MutableRefObject<TinyMceEditor | null>;
  styleClassName?: string;
}

function useAutoCompleteHandler({
  autoCompleteMenuConfigs,
  editorRef,
  styleClassName,
}: Props) {
  useEffect(() => {
    const editor = editorRef.current;
    if (editor) {
      const onAction = (autocompleteApi: any, rng: Range, value: string) => {
        editorRef.current?.selection.setRng(rng);
        console.log(value);
        editorRef.current?.insertContent(value);
        autocompleteApi.hide();
      };

      autoCompleteMenuConfigs?.forEach((config) => {
        editor.ui.registry.addAutocompleter(`specialchars_${config.prefix}`, {
          trigger: config.prefix,
          minChars: 1,
          columns: 1,
          highlightOn: ["char_name"],
          onAction: onAction,
          fetch: async (
            pattern: string,
            maxResults: number,
            fetchOptions: Record<string, any>
          ) => {
            const chars = await config.fetchHandler(
              pattern,
              maxResults,
              fetchOptions
            );

            const results = chars.map((char) => ({
              type: "cardmenuitem",
              value: char.value,
              label: char.label,
              items: [
                {
                  type: "cardcontainer",
                  direction: "vertical",
                  items: [
                    {
                      type: "cardtext",
                      text: char.label,
                      name: "char_name",
                      classes: char.classes,
                    },
                  ],
                },
              ],
            }));

            setTimeout(() => {
              if (styleClassName) {
                const collectionElement = document.querySelector(
                  ".tox-autocompleter .tox-collection"
                );
                if (collectionElement) {
                  collectionElement.classList.add(styleClassName);
                }
              }
            }, 0);

            return results as any;
          },
        });
      });
    }
  }, [editorRef.current, autoCompleteMenuConfigs, styleClassName]);

  return null;
}

export default useAutoCompleteHandler;
