import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./SubArticleFolder.module.scss";
import drag from "src/assets/images/drag.png";
import dots from "src/assets/images/subFolder.png";
import { getTimeFromNow } from "src/utils/dateLibrary";
import { useAppSelector } from "src/store/store";
import { useNavigate, useParams } from "react-router-dom";
import { Dropdown, Overlay } from "react-bootstrap";
import { DraggableProvided } from "react-beautiful-dnd";
interface Props {
  article: any;
  handleArticleDelete: any;
  handleArticleDuplicate: any;
  categoryId: number | string;
  provided: DraggableProvided;
}
const SubArticleFolder = ({
  article,
  handleArticleDelete,
  handleArticleDuplicate,
  categoryId,
  provided,
}: Props) => {
  const userTimezone = useAppSelector(
    (state) => state.globals.currentUserData?.userTimezone
  );
  const [showOL, setShowOL] = useState(false);
  const overlayTarget = useRef(null as null | HTMLDivElement);

  const { integrationId } = useParams();
  const pageIntegrationId = integrationId
    ? decodeURIComponent(integrationId)
    : "";

  const navigate = useNavigate();
  const handleEditArticle = (articleId: number | string) => {
    navigate(`/knowledge-base/${pageIntegrationId}/articles/${articleId}`);
  };

  const duplicateAjaxStatus = useAppSelector(
    (state) => state.knowledgeBasedSettings.fetchDuplicateArticleAjaxStatus
  );

  const onToggleOL = useCallback(() => {
    setShowOL((show) => !show);
  }, []);

  useEffect(() => {
    const onClickWindow = (event: Event) => {
      if (
        !(
          event.target === overlayTarget.current ||
          overlayTarget.current?.contains(event.target as any)
        )
      ) {
        setShowOL(false);
      }
    };
    window.addEventListener("click", onClickWindow);
    return () => {
      window.removeEventListener("click", onClickWindow);
    };
  }, [false]);

  return (
    <div>
      <span className={`${styles.artricleWrapper}`}>
        <div
          className={`d-flex align-items-center justify-content-between px-3 py-2 py-md-2 ${styles.knowledgeBox2}`}
        >
          <div
            className={`d-flex align-items-center ${styles.knowledgeName}`}
            onClick={(e) => handleEditArticle(article?.articleId)}
          >
            <div className={`${styles.drag}`}>
              <span
                className={`${styles.threeDots}`}
                {...provided.dragHandleProps}
              >
                <img src={dots} alt="dots" className={`${styles.dotImg}`} />
              </span>
            </div>
            <div>
              <span className={`${styles.steps} saufter_subHeading`}>
                {article?.articleName}
              </span>
            </div>
          </div>

          <div className={`${styles.statusBox}`}>
            <span className={`${styles.status}`}>
              <span
                className={`${
                  article?.articleStatus === "public"
                    ? styles.publicStataus
                    : styles.draftStatus
                }`}
              >
                {article?.articleStatus === "public" ? "Public" : "Draft"}
              </span>
            </span>
          </div>
          <div className={`${styles.timeSet}`}>
            <span className={`${styles.timeDate}`}>{`${getTimeFromNow({
              date: new Date(
                new Date(article?.createdAtGmt + "Z").toLocaleString("en-US", {
                  timeZone: userTimezone,
                })
              ),
              timeZone: userTimezone,
              secondString: {
                secondText: "sec",
                secondsText: "sec",
              },
              minuteString: {
                minuteText: "minute",
                minutesText: "minutes",
              },
              hourString: {
                hourText: "hour",
                hoursText: "hours",
              },
            })}`}</span>
          </div>
          <div>
            <div
              ref={overlayTarget}
              className={`cursor-pointer my-1 my-md-0 d-flex justify-content-center align-items-center ${styles.moreOption}`}
              onClick={onToggleOL}
            >
              <span>
                <i className="fa-solid fa-ellipsis"></i>
              </span>
            </div>

            <Overlay
              target={overlayTarget.current}
              placement="bottom-end"
              show={showOL}
            >
              <div className={`dropdown-menu ${styles.addNewBox}`}>
                <li>
                  <span
                    className={`${styles.innerDropDown} cursor-pointer`}
                    onClick={(e) => {
                      if (duplicateAjaxStatus === "pending") return;
                      handleArticleDuplicate(article.articleId);
                    }}
                  >
                    <span className="me-1">
                      <i className="fa-solid fa-clone"></i>
                    </span>
                    Duplicate
                  </span>
                </li>
                <li>
                  <span
                    className={`${styles.innerDropDown} cursor-pointer`}
                    onClick={(e) =>
                      handleArticleDelete(article?.articleId, categoryId)
                    }
                  >
                    <span className={`me-1 ${styles.delete}`}>
                      <i className="fa-solid fa-trash"></i>
                    </span>
                    <span className={`${styles.delete}`}>Delete article</span>
                  </span>
                </li>
              </div>
            </Overlay>
          </div>
        </div>
      </span>
    </div>
  );
};

export default SubArticleFolder;
