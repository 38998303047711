/**
 * This file is the custome hook file.
 * It contains the useReportTicketList hook which is explained below.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { useQuery } from "@tanstack/react-query";
import { useCallback, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  reportCurrentStatusActions,
  useReportFilters,
} from "src/routes/Report/hooks/reportFilters/useReportFilters";
import {
  ReportDateType,
  ReportSidebarFilters,
  reportInternalFiltersActions,
  useReportInternalFilters,
} from "src/routes/Report/hooks/reportFilters/useReportInternalFilters";
import { getChannelList } from "src/services/InnerTicket/getChannelList";
import reportTicketListService, {
  CurrentStatusEndPoints,
  ReportTicketListParams,
  allMsgClusters,
  allTimeClusters,
} from "src/services/report/reportTicketList.service";
import { useAppSelector } from "src/store/store";

interface Props {
  endPoint: CurrentStatusEndPoints;
  reportDateType?: ReportDateType;
}

/**
 * This hook is created to maintain and use the applied sidebar filters for fetching the list view.
 */
const useSideBarFilters = ({ endPoint, reportDateType }: Props) => {
  const { dispatch } = useReportFilters();
  const { dispatch: dispatchIF } = useReportInternalFilters();

  /**
   * Fetching Channel filters
   */
  const { data: allChannels, isFetched: isChannelFetched } = useQuery(
    ["useChannelFilter/getChannelList"],
    {
      queryFn: async () => {
        return getChannelList({ context: "reports" });
      },
      staleTime: 600000,
      cacheTime: 600000,
      enabled: endPoint.startsWith("tickets"),
    }
  );

  // State for selected Sidebar filters
  const [selectedSBFilterIds, setSelectedSBFilterIds] = useState(
    [] as Array<string>
  );
  // State for fetched categories
  const [fetchedCat, setFetchedCat] = useState(
    null as null | { [id: string]: { id: string; name: string } }
  );

  const allSidebarFilters = useMemo(() => {
    // If Categories are already present return
    if (fetchedCat) {
      return fetchedCat;
    }
    let asbf: { [id: string]: { id: string; name: string } } = {};
    // If channel data is present and endpoint uses channel filter
    if (
      allChannels &&
      endPoint.startsWith("tickets") &&
      endPoint !== "tickets/currentStatus/unresolvedTicketsPendingLongest" &&
      endPoint !== "tickets/currentStatus/ticketsMostBackForth" &&
      !endPoint.includes("performance")
    ) {
      // Set channel filters
      for (const key in allChannels) {
        asbf[key] = { id: key, name: allChannels[key].name };
      }
    }
    return asbf;
  }, [allChannels, endPoint, fetchedCat]);

  const allSidebarFilterIds = useMemo(() => {
    return Object.keys(allSidebarFilters ?? {});
  }, [allSidebarFilters]);

  const allPreFetchingDone = useMemo(() => {
    // If endpoint doesn't use channel filter
    if (
      endPoint === "tickets/currentStatus/unresolvedTicketsPendingLongest" ||
      endPoint === "tickets/currentStatus/ticketsMostBackForth" ||
      endPoint.includes("performance") ||
      endPoint.includes("bot") || //For bot reports
      endPoint.includes("totalChatReports") ||
      endPoint.includes("missedChatsReports") ||
      endPoint.includes("chatSatisfactionReports") ||
      endPoint.includes("chatAvailabilityReports")
    ) {
      return true;
    } else {
      return isChannelFetched;
    }
  }, [isChannelFetched, endPoint]);

  /**
   * Computing the required sidebar filters as per the endpoints.
   */
  const sideBarFilters = useMemo(() => {
    const f: ReportSidebarFilters | null = {};
    // If we have any selected Sibebar filters calculate filters
    if (selectedSBFilterIds.length) {
      if (
        endPoint === "tickets/currentStatus/unresolvedTicketsPendingLongest" ||
        endPoint.includes("missedChatsReports") ||
        endPoint.includes("totalChatReports") ||
        endPoint.includes("chatSatisfactionReports") ||
        endPoint.includes("chatAvailabilityReports") ||
        endPoint.includes("volumeActivity") ||
        endPoint.includes("performance")
      ) {
        f.timeClusters = selectedSBFilterIds;
      } else if (endPoint === "tickets/currentStatus/ticketsMostBackForth") {
        f.msgClusters = selectedSBFilterIds;
      } else if (
        !endPoint.startsWith("knowledgeBase") &&
        !endPoint.startsWith("bot")
      ) {
        f.channels = selectedSBFilterIds;
      } else if (
        endPoint.startsWith("knowledgeBase") &&
        endPoint.includes("newArticlesAddedByTime")
      ) {
        f.timeRange = selectedSBFilterIds;
      } else if (
        endPoint.startsWith("knowledgeBase") &&
        endPoint.includes("authorsMostArticles")
      ) {
        f.agentIds = selectedSBFilterIds;
      } else if (endPoint.startsWith("bot")) {
        // Adding side bar filters for bot reports
        f.timeRange = selectedSBFilterIds;
      }
    }
    if (Object.keys(f).length === 0) {
      dispatchIF([reportInternalFiltersActions.setSidebarFilters, null]);
      return null;
    } else {
      dispatchIF([reportInternalFiltersActions.setSidebarFilters, f]);
      return f;
    }
  }, [selectedSBFilterIds, endPoint]);

  /**
   * Selecting the sidebar filters in list view.
   */
  const selectAllSBFilters = useCallback(
    (e: any) => {
      if (e.target.checked) {
        setSelectedSBFilterIds(allSidebarFilterIds);
      } else {
        setSelectedSBFilterIds([]);
      }
      dispatch([reportCurrentStatusActions.resetListViewPage, endPoint]);
    },
    [allSidebarFilterIds]
  );

  /**
   * Clearing the sidebar filters in list view.
   */
  const clearAllSBFilters = useCallback(() => {
    setSelectedSBFilterIds([]);
    dispatch([reportCurrentStatusActions.resetListViewPage, endPoint]);
  }, []);

  /**
   * Check uncheck the sidebar filters in list view.
   */
  const checkUncheckSBFilters = useCallback(
    (e: any) => {
      if (e.target.checked) {
        setSelectedSBFilterIds((prev) => [
          ...prev.filter((id) => allSidebarFilterIds.includes(id + "")),
          e.target.value,
        ]);
      } else {
        setSelectedSBFilterIds((prev) =>
          prev.filter(
            (id) =>
              id !== e.target.value && allSidebarFilterIds.includes(id + "")
          )
        );
      }
      dispatch([reportCurrentStatusActions.resetListViewPage, endPoint]);
    },
    [allSidebarFilterIds]
  );

  return {
    allSidebarFilters,
    allSidebarFilterIds,
    selectedSBFilterIds,
    allPreFetchingDone,
    sideBarFilters,
    selectAllSBFilters,
    clearAllSBFilters,
    checkUncheckSBFilters,
    setFetchedCatData: setFetchedCat,
  };
};

/**
 * This hook is used to fetch the ticke list in report.
 */
function useReportTicketList({ endPoint, reportDateType }: Props) {
  const { filtersForAPI, currentPage, dispatch } = useReportFilters(endPoint);
  const {
    allSidebarFilters,
    allSidebarFilterIds,
    selectedSBFilterIds,
    allPreFetchingDone,
    sideBarFilters,
    selectAllSBFilters,
    clearAllSBFilters,
    checkUncheckSBFilters,
    setFetchedCatData,
  } = useSideBarFilters({ endPoint });

  const { internalFilters } = useReportInternalFilters();

  const { integrationId, profileId } = useParams();

  const pageIntegrationId = integrationId
    ? decodeURIComponent(integrationId)
    : "";

  const currentState = useRef({ limit: 15 });

  const [totalTickets, setTotalTickets] = useState(0);

  const setCurrentPage = useCallback(
    (value: number) => {
      dispatch([
        reportCurrentStatusActions.setListViewPage,
        { key: endPoint, value },
      ]);
    },
    [dispatch, endPoint]
  );

  /**
   * Total pages available
   */
  const totalPages = useMemo(() => {
    return Math.ceil(totalTickets / currentState.current.limit);
  }, [totalTickets]);

  /**
   * Starting index for API call
   */
  const start = useMemo(() => {
    return (
      currentState.current.limit * currentPage - currentState.current.limit
    );
  }, [currentPage]);
  
  // Fetching disabledFeatures from the Redux store
  const disabledFeatures = useAppSelector(
    (state) => state.globals.currentUserData?.disabledFeatures
  );

  const {
    data: reportTicketList,
    status,
    isPreviousData,
  } = useQuery(
    [
      `useReportTicketList/${endPoint}`,
      filtersForAPI,
      allPreFetchingDone,
      sideBarFilters,
      internalFilters.filters,
      start,
      endPoint,
      pageIntegrationId,
      profileId,
      internalFilters.reportDateType, //Added to fetch listview when reportDateType changes
    ],
    {
      /**
       * Fetching the list view based on endpoints and params using the reportTicketListService.
       */
      queryFn: async () => {
        const params: ReportTicketListParams = {
          viewType: "listView",
          start,
          limit: currentState.current.limit,
        };
        // Set global filter if available
        if (filtersForAPI) {
          params.filters = filtersForAPI;
        }
        // Set sidebar filters if available
        if (sideBarFilters) {
          params.internalFilters = sideBarFilters;
        }
        // Set internal filters is available
        if (Object.keys(internalFilters.filters).length) {
          params.internalFilters = {
            ...(params.internalFilters ?? {}),
            ...internalFilters.filters,
          };
        }
        // Set Date type if available
        if (reportDateType !== undefined) {
          params.reportDateType = reportDateType;
        }
        // Set integration id for knowledge base reports
        if (endPoint.startsWith("knowledgeBase")) {
          params.integrationId = pageIntegrationId;
        }
        if (endPoint.startsWith("bot/") && profileId) {
          params.botProfileId = profileId;
        }
        return reportTicketListService(params, endPoint);
      },
      cacheTime: 0,
      staleTime: 0,
      keepPreviousData: true,
      // Only fetch if preFetching is done
      enabled: !(disabledFeatures && disabledFeatures.includes("reports")) && (allPreFetchingDone || endPoint.startsWith("knowledgeBase")),
    }
  );

  /**
   * Setting sidebar categories based on the data returned from the list view api calling.
   */
  useMemo(() => {
    if (reportTicketList?.categories) {
      setFetchedCatData(reportTicketList.categories);
    } else {
      setFetchedCatData(null);
    }
    setTotalTickets(reportTicketList?.metaData.totalCount ?? 0);
  }, [reportTicketList]);

  /**
   * Opening the ticket or article in new tab on clicking on the item in the list view.
   */
  const handleTicketClick = useCallback((e: any) => {
    if (endPoint.startsWith("knowledgeBase")) {
      window.open(
        `${
          window.location.origin
        }/knowledge-base/${pageIntegrationId}/articles/${e.currentTarget.getAttribute(
          "data-ticket-id"
        )}`
      );
    } else if (
      endPoint.startsWith("bot") ||
      endPoint.includes("missedChatsReports") ||
      endPoint.includes("totalChatReports") ||
      endPoint.includes("chatSatisfactionReports") 
    ) {
      // Open chat in a new window
      window.open(
        `${
          window.location.origin
        }/live-chat/chats/${e.currentTarget.getAttribute(
          "data-chat-status"
        )}/${e.currentTarget.getAttribute("data-chat-id")}`
      );
    } else if (
      endPoint.includes("chatAvailabilityReports")
    ) {
      // For chat availability there is no redirection
      return;
    } else {
      window.open(
        `${
          window.location.origin
        }/tickets/all/ticket-view/ticket/${e.currentTarget.getAttribute(
          "data-ticket-id"
        )}/`
      );
    }
  }, []);

  return {
    reportTicketList,
    status,
    allSidebarFilters,
    allSidebarFilterIds,
    selectedSBFilterIds,
    selectAllSBFilters,
    clearAllSBFilters,
    checkUncheckSBFilters,
    currentPage,
    setCurrentPage,
    totalPages,
    handleTicketClick,
    isPreviousData,
    pageIntegrationId,
  };
}

export default useReportTicketList;
