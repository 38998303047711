/**
 * This file is the service file.
 *
 * @author Jayalakshmi
 * @author Yash Aditya
 */

import { axiosJSON } from "src/globals/axiosEndPoints";

// Async function to fetch users to be notified from the server
export const getUsersToBeNotified = async () => {
  // Send a GET request to the specified API endpoint
  const { data: res } = await axiosJSON.post(
    `/api/reports/notificationSettings/getUsersToBeNotified`
  );

  // Check if there's an error in the response
  if (res.err || res.error) {
    // If there is an error, throw an exception with the error message
    throw res.msg;
  }

  // Map the selected user ids to strings and return as an array of strings
  return res.selectedUserIds.map((id: any) => id + "") as Array<string>;
};

export default getUsersToBeNotified;
