import UserAvatar from "src/components/UserAvatar";
import styles from "./TableRow.module.scss";
import mediaImg from "src/assets/images/media.png";
import { Modal } from "react-bootstrap";
import { useCallback, useEffect, useState } from "react";
import BugReportModal from "./Children/BugReportModal/BugReportModal";
import DropDownStaus from "../DropDownStatus/DropDownStatus";
import {
  BugData,
  CustomerDetails,
} from "src/services/BugReport/getBugByCategory.service";
import { capitalizeFirstLetter } from "../helper/helperFunction";
import AxiosImg from "src/components/AxiosImg";
import ParseHTML from "src/components/ParseHTML";
import ProfileImgGroup from "../ProfileImgGroup/ProfileImgGroup";
import GeneralTooltip from "src/components/GeneralTooltip/GeneralTooltip";
import CompanyProfileImg from "./Children/CompanyProfileImg/CompanyProfileImg";

export interface BugTableData {
  bugData: BugData;
  categoryName: string;
  selectedBugId: Set<number>;
  setSelectedBugId: React.Dispatch<React.SetStateAction<Set<number>>>;
  setSelectedBugsData: React.Dispatch<React.SetStateAction<BugData[]>>;
}

const RenderCustomerImg = ({
  customerData,
  translate,
}: {
  customerData?: CustomerDetails;
  translate?: boolean;
}) => {
  return (
    <span className={`${translate ? styles.imgWrap : ""}`}>
      {customerData?.imageUrl ? (
        <AxiosImg
          className={`${styles.profileImg}`}
          url={customerData?.imageUrl}
          size={21}
        />
      ) : customerData?.customerName ? (
        <UserAvatar
          className={`${styles.profileImg}`}
          name={customerData?.customerName ?? "N A"}
          size={21}
        />
      ) : (
        <></>
      )}
    </span>
  );
};

const TableRow = ({
  bugData,
  categoryName,
  selectedBugId,
  setSelectedBugId,
  setSelectedBugsData,
}: BugTableData) => {
  const [showModal, setShowModal] = useState(false);
  const [isSelected, setIsSelected] = useState(
    selectedBugId.has(bugData.bugId),
  );
  const [bugStatus, setBugStatus] = useState(bugData.bugStatusKey);

  // select bug function for merge
  const handleCheckBoxSelection = () => {
    setSelectedBugId((prevSelectedBugs) => {
      const newSelectedBugs = new Set(prevSelectedBugs);
      if (isSelected) {
        newSelectedBugs.delete(bugData.bugId);
      } else {
        newSelectedBugs.add(bugData.bugId);
      }
      return newSelectedBugs;
    });
  };

  // if bug id in the set map than select it
  useEffect(() => {
    setIsSelected(selectedBugId.has(bugData.bugId));
    setSelectedBugsData((prevState) => {
      const bugIds = new Set(prevState.map((bug) => bug.bugId));
      if (selectedBugId.has(bugData.bugId) && !bugIds.has(bugData.bugId)) {
        return [...prevState, { ...bugData, categoryName }];
      } else {
        return prevState.filter((bug) => selectedBugId.has(bug.bugId));
      }
    });
  }, [selectedBugId, bugData.bugId, categoryName]);

  // update the bug status on the both modal and table
  const updatedStatus = useCallback(
    (newStatus: string, isResolved: boolean = false) => {
      if (newStatus === "resolved" && !isResolved) {
        setShowModal(true);
        return;
      } else {
        setBugStatus(newStatus);
        bugData.bugStatusKey = newStatus;
      }
      if (isResolved) {
        setBugStatus(newStatus);
      }
    },
    [],
  );

  return (
    <div className={`d-flex align-items-center mb-2 ${styles.tableHeader}`}>
      <div className={`${styles.tableContentReport}`}>
        <div className="form-check d-flex align-items-center">
          <div>
            <input
              className={`form-check-input ${styles.checkBox}`}
              type="checkbox"
              value={bugData.bugId}
              checked={isSelected}
              id={`flexCheckDefault-${bugData.bugId}`}
              onChange={handleCheckBoxSelection}
            />
          </div>

          {/* report column */}
          <div className={`ms-3 me-2 text-truncate`}>
            {/* <img src="" alt="" /> */}
            {bugData.customerDetails.length === 1 ? (
              <div>
                <RenderCustomerImg
                  key={bugData.customerDetails[0].customerId}
                  customerData={bugData.customerDetails[0]}
                />
                <div className={`ps-1 d-inline-flex ${styles.userName}`}>
                  {bugData.customerDetails[0].customerName ?? ""}
                </div>
              </div>
            ) : (
              <ProfileImgGroup
                customers={bugData.customerDetails}
                companyName={bugData.companyName}
                showReporter={false}
              />
            )}
          </div>
        </div>
      </div>
      {/* company column */}
      <div className={`${styles.tableContent}`}>
        <CompanyProfileImg companyName={bugData.companyName} />
      </div>

      {/* priority column */}
      <div className={`${styles.tableContentPriority} pe-2`}>
        <div
          className={`${styles.priorityText} 
        ${
          bugData.priorityKey === "normal"
            ? styles.priorityBlue
            : bugData.priorityKey === "high"
              ? styles.priorityGreen
              : styles.priorityRed
        }
        `}
        >
          {bugData.priorityKey === "normal" ||
          bugData.priorityKey === "high" ? (
            <span>Priority: </span>
          ) : (
            ""
          )}
          <span
            className={`${
              bugData.priorityKey === "needThisFixedRightAway"
                ? ""
                : styles.textCapital
            }`}
          >
            {bugData.priorityKey === "needThisFixedRightAway"
              ? "I need this fixed right away"
              : bugData.priorityKey}
          </span>
        </div>
      </div>

      {/* description column */}
      <div className={`${styles.tableContentDesc} pe-2`}>
        <div
          className={`d-flex align-items-center ${styles.descriptionContainer}`}
        >
          <ParseHTML
            className={`${styles.descriptionText} text-truncate`}
            html={bugData.bugDescription}
          />
        </div>
      </div>

      {/* media column */}
      <div className={`${styles.tableContent}`}>
        <div>
          <img
            src={mediaImg}
            alt="media"
            className={`${styles.mediaImg}`}
          />
          <span className={`ps-1 ${styles.moreItem}`}>
            {bugData.bugAttachmentDetails.length > 1
              ? "+" + bugData.bugAttachmentDetails.length
              : ""}
          </span>
        </div>
      </div>

      {/* category column */}
      <div
        className={`${styles.tableContent} ${styles.toolTipView} text-truncate`}
      >
        {" "}
        <span>{capitalizeFirstLetter(categoryName)}</span>
      </div>

      {/* status column */}
      <div className={`${styles.tableContentStatus}`}>
        <DropDownStaus
          status={bugStatus}
          id={bugData.bugId}
          updatedStatus={updatedStatus}
        />
      </div>

      {/* resolve btn */}
      <div className={`${styles.tableContent}`}>
        <button
          className={`px-4 ${styles.resolveBtn}`}
          onClick={() => setShowModal(true)}
          id="bug_resolve_btn"
        >
          {bugData.bugStatusKey === "resolved" ? "View details" : "Resolve"}
          <span className="ps-1">
            <i className="fa-solid fa-angle-right"></i>
          </span>{" "}
        </button>
      </div>
      <Modal
        backdropClassName={`${styles.modalBack}`}
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName={`${styles.modalDialog}`}
        contentClassName={`${styles.modalContent}`}
        centered={true}
        enforceFocus={false}
      >
        <BugReportModal
          onHide={() => setShowModal(false)}
          id={bugData.bugId}
          categoryName={categoryName}
          tableBugStatusUpdate={updatedStatus}
          bugData={bugData}
        />
      </Modal>
    </div>
  );
};

export default TableRow;
