import { useInfiniteQuery } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { IAutomationListParams } from "src/routes/BotSettings/Children/AutomationList/AutomationList";
import {
  IListQuestion,
  IMultiSelectListQuestion,
} from "src/services/Automation/getAutomationById.service";
import {
  SelectDDParams,
  getQuestionSelectDDService,
} from "src/services/Automation/getQuestionSelectDD.service";
import { ListQuestionOptionType } from "./ListQuestion";

interface QuestionsProps {
  questionData: IMultiSelectListQuestion | IListQuestion;
  stepId: string;
}

const INITIAL_PAYLOAD = {
  start: 0,
  limit: 20,
};

const useQuestionSelectDD = ({ questionData, stepId }: QuestionsProps) => {
  const { type } = useParams<IAutomationListParams>();

  const payload: SelectDDParams = useMemo(
    () => ({
      questionKey: questionData.questionKey,
      stepId,
      limit: INITIAL_PAYLOAD.limit,
      start: INITIAL_PAYLOAD.start,
    }),
    [questionData.questionKey, stepId],
  );

  // Infinite query hook to fetch pages
  const { data, isLoading, fetchNextPage, hasNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ["getQuestionSelectDDService", type, payload],
      queryFn: ({ pageParam = { type, payload: payload } }) =>
        getQuestionSelectDDService(pageParam),
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.length < payload.limit) {
          return null;
        }

        const data = allPages.flatMap((page) => page);
        const nextPageParam: SelectDDParams = {
          questionKey: questionData.questionKey,
          stepId,
          limit: payload.limit,
          start: data.length,
        };

        return { type, payload: nextPageParam };
      },
      enabled: questionData.isApiRequired === true,
    });

  /**
   * Fetch next page handler
   */
  const fetchNextPageHandler = useCallback(() => {
    // If we have next page and currently not fetching fetch the next page
    if (questionData.isApiRequired === true && hasNextPage && !isFetching) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isFetching, questionData.isApiRequired]);

  const { options, optionsMap } = useMemo(() => {
    const options: ListQuestionOptionType[] = [];
    const optionsMap: Record<string, ListQuestionOptionType> = {};

    if (data) {
      const pages = data.pages.flatMap((page) => page) ?? [];

      pages.forEach((op) => {
        const option = {
          value: op.id,
          label: op.value,
        };
        options.push(option);
        optionsMap[op.id] = option;
      });
    }

    return { options, optionsMap };
  }, [data]);

  const showLoader = useMemo(() => {
    if (!questionData.isApiRequired) {
      return false;
    }

    // Show loader if loading or fetching data
    return isLoading || isFetching;
  }, [isFetching, isLoading, questionData.isApiRequired]);

  return { options, optionsMap, showLoader, fetchNextPageHandler };
};

export default useQuestionSelectDD;
