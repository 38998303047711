import { axiosJSON } from "src/globals/axiosEndPoints";

export interface IResolveChatParams{
    chatRequestId: string | number;
}

export async function resolveChat(
  payload: IResolveChatParams
) {
  const { data } = await axiosJSON.post(
    `/api/liveChat/agent/resolveChat`,
    payload
  );

  if (data.err === true) {
    throw new Error(data.msg as string);
  }
  return data;
}
