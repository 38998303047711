import React from "react";
import styles from "./HistoryMessageOut.module.scss";
import ParseHTML from "src/components/ParseHTML";
import InnerChatAttachments from "src/components/InnerChatAttachments/InnerChatAttachments";
import AxiosImg from "src/components/AxiosImg";
import UserAvatar from "src/components/UserAvatar";
import { Message } from "src/services/LiveChat/messageExchange/getMessages";
import botImg from "src/assets/images/botImg.png";

export const HistoryMessageOut = ({
  messageText,
  sentBy,
  sendTimeGmt,
  attachments,
  sendTime,
  messageId,
  messageType,
  senderType,
}: // sendingStatus = false,
// tryAgainStatus = false,
Message) => {
  return (
    <>
      <div className={`d-flex justify-content-end mb-2 ${styles.msgOut} `}>
        <div>
          <div className={`${styles.msgText}`}>
            <ParseHTML
              html={messageText}
              // replaceNewLineWithBr={true}
            />

            {attachments && attachments.length > 0 && (
              <div className={`mt-2`}>
                <InnerChatAttachments
                  messageData={{
                    attachments: attachments,
                    senderName: sentBy.name,
                    messageDateTime: sendTime,
                    messageId: messageId,
                  }}
                />
              </div>
            )}
          </div>
        </div>
        {/* checking if senderType or messageType is from bot, then rendering bot image */}
        {senderType === "Bot" || messageType === "botMessage" ? (
          <img
            src={botImg}
            className={`rounded-circle ${styles.profileImage}`}
          />
        ) : sentBy?.imageURL && sentBy?.imageURL.length !== 0 ? (
          <AxiosImg
            url={sentBy?.imageURL}
            className={`rounded-circle ${styles.profileImage}`}
            isDirectURL={sentBy?.isPublicAttachmentUrl}
          />
        ) : (
          <UserAvatar
            name={sentBy?.name ?? "NA"}
            size={60}
            className={`rounded-circle ${styles.profileImage} mb-1`}
          />
        )}
      </div>
    </>
  );
};
