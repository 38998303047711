import {createSelector, createSlice} from "@reduxjs/toolkit";
import {builderFunction} from "./chatWidExchCust.builder";
import {initialState} from "./chatWidExchCust.declarations";
import chatWidExchCustReducer from "./chatWidExchCust.reducers";
import {
  areAllAgentSelectedForFilter,
  isAgentIdSelectedForFilter,
  sortChatByMessageDate,
} from "./chatWidExchCust.selectors";

export const chatWidExchCustSlice = createSlice({
  name: "chatWidExchCust",
  initialState,
  reducers: chatWidExchCustReducer,
  extraReducers: builderFunction,
});

export const isAgentIdSelectedForAssignToFilterSelector = createSelector(
  [isAgentIdSelectedForFilter],
  (isAgentIdSelectedForFilter) => isAgentIdSelectedForFilter
);

export const areAllAgentSelectedForAssignToFilter = createSelector(
  [areAllAgentSelectedForFilter],
  (areAllAgentSelectedForFilter) => areAllAgentSelectedForFilter
);

export const actions = chatWidExchCustSlice.actions;



// Create a selector that uses the sortChatByMessageDate function to sort chat IDs
export const sortChatByMessageDateSelector = createSelector(
  [sortChatByMessageDate],  // Pass the sortChatByMessageDate function as a selector input
  (sortChatByMessageDate) => sortChatByMessageDate  // Return the result of sortChatByMessageDate
);
export default chatWidExchCustSlice.reducer;
