import React from "react";
import ThemeBox from "../ArticleSideBar/ThemeSelection/Children/ThemeBox/ThemeBox";
import MessageSetting from "./Children/BrandSetting/MessageSetting";
import styles from "./Messaging.module.scss";
import useThemeBox from "../ArticleSideBar/ThemeSelection/Children/ThemeBox/useThemeBox";
import Loader from "src/components/Loader";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { useNavigate } from "react-router-dom";

const Messaging = () => {
  const navigate = useNavigate();
  // The useThemeBox hook is used to check if there is an error or if the data is still loading.
  const { isError, isLoading } = useThemeBox();

  // If the data is still loading, a Loader component is displayed.
  if (isLoading) {
    return (
      <>
        <div className={`${styles.loaderWrapper}`}>
          <span>
            <Loader />
          </span>
        </div>
      </>
    );
  }
  // If there is an error, a toast notification is pushed, and an error message is displayed.
  if (isError) {
    pushTheToast({
      position: "top-right",
      text: "Something went wrong",
      type: "danger",
    });

    return (
      <>
        {" "}
        <div className={`p-2  w-100`}>
          <span className={`${styles.errorMsg}`}>Something Went Wrong</span>
        </div>
      </>
    );
  }
  // the main content of the Messaging section is rendered, which includes a heading and a subheading, followed by the ThemeBox and MessageSetting components.
  return (
    <div className={`w-100 pt-4 px-3 ${styles.msgWrapper}`}>
      <div className="mb-4 d-flex">
        <div>
          <div
            className={`d-flex justify-content-center align-items-center ${styles.backChat}`}
            onClick={() => navigate(-1)}
          >
            <i className="fa-solid fa-arrow-left"></i>
          </div>
        </div>
        <div>
          <h3 className={`mb-0 ${styles.heading} saufter_h3`}>
            Style Helpcenter page / Messaging
          </h3>
          <p className={`${styles.subHeading} saufter_subHeading`}>
            Let customers style the help center right inside your chat widget
            instead of starting a conversation.
          </p>
        </div>
      </div>
      <div className={`d-block d-md-flex`}>
        <ThemeBox />
        <MessageSetting />
      </div>
    </div>
  );
};

export default Messaging;
