import { axiosJSON } from "src/globals/axiosEndPoints";

export interface AddImapParams {
  emailIntegrationId: number;
  imapUsername: string;
  imapPassword: string;
  imapHost?: string;
  imapPort?: string;
  imapUseSSL?: boolean;
}

/**
 * Service for adding imap details
 */
const addImapDetailsService = async (params: AddImapParams) => {
  const { data: res } = await axiosJSON.post(
    "/api/setting/integration/addIncomingDetails",
    params,
  );

  if (res.err === true || res.error === true) {
    if (res.statusCode === "auth_failed") {
      return false;
    }
    throw new Error(res.msg ?? res.message ?? "Some error occured");
  }
  return true;
};

export default addImapDetailsService;
