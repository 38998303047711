import { axiosJSON } from "src/globals/axiosEndPoints";

const updateBug = async (param: {
  id: number | undefined;
  bugStatusKey?: string | undefined;
  priorityKey?: string | null | undefined;
  categoryId?: number | undefined;
  description?: string | undefined;
  attachmentBatchNumber?: string[];
}) => {
  const { data: res } = await axiosJSON.put(
    `/api/selfService/bugManagement/updateBug`,
    param,
  );

  if (res.err || res.error) {
    throw res.msg ?? res.message ?? "Something went wrong!";
  }

  return res.data;
};

export default updateBug;
