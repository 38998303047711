/**
 * This file is the service file used for making api request.
 * It contains the getAllKnowledgeBase function service which is explained below.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants"; // this is used for testing purpose only for frontend.

export interface GetAllKnowledgeBasePayload {
  start: number;
  limit: number;
}

export interface KnowledgeBase {
  knowledgeBaseId: string | number;
  brandDetails: {
    id: string | number;
    name: string;
    imageURL: string | null;
  };
  integrationId: string | number;
  knowledgeBaseEnableStatus: boolean;
}

export interface GetAllKnowledgeBaseRes {
  data: KnowledgeBase[];
  metaData: {
    totalCount: number;
    currentCount: number;
    addNewEnabled: boolean;
  };
}

/**
 * For testing frontend with own api link if backend is not prepared.
 */
const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/getAllKnowledgeBase`
  : "/api/knowledgeBase/getAll";

/**
 * This service is used to get all the KB data.
 */
export const getAllKnowledgeBase = async (
  params: GetAllKnowledgeBasePayload
) => {
  const { data: res } = await axiosJSON.post(apiEndPoint, params);
  return res as GetAllKnowledgeBaseRes;
};
