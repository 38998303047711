import { axiosJSON } from "src/globals/axiosEndPoints";

export interface CancelOrderReasonOption {
  data: Array<{
    name: string;
    id: string;
  }>;
}

export async function cancelOrderReasonOption() {
  const res: any = await axiosJSON.post(
    `/api/shopify/order/orderCancelReasonOptions`
  );

  if (res.error === true) {
    throw new Error(res.message as string);
  }

  return res.data as CancelOrderReasonOption;
}
