import styles from "./LastMessageReceived.module.scss";
import { useAppSelector } from "src/store/store";
import { selectTicketCellData } from "src/store/slices/tickets/tickets.slice";
import { TableCellProps } from "..";
import { removeHtmlTags } from "src/utils/utils";

function LastMessageReceived({ ticketId, colKeys }: TableCellProps) {
  const ticketCellData: any = useAppSelector((state) =>
    selectTicketCellData(state, ticketId, colKeys)
  );

  return (
    <div className={`${styles.LastMessageHead}`}>
      {ticketCellData[0] ? (
        <>
          {/* last message heading */}
          {/* <span className={`${styles.msgHead}`}>{ticketCellData[0]}</span> */}
          <span className={`${styles.msgHead}`}>
            {removeHtmlTags(ticketCellData[0])}
          </span>
          {/* last message body*/}
          {/* <span className={`${styles.msgSub}`}>
            Hi team,I realized that th...
          </span> */}
        </>
      ) : (
        <>
          {/* <span className={`${styles.msgHead}`}></span> */}
          <span className={`${styles.msgHead}`}>
            {/* Issue with received Ord... */}
            NA
          </span>
          {/* <span className={`${styles.msgSub}`}>
            Hi team,I realized that th...
          </span> */}
        </>
      )}
    </div>
  );
}

export default LastMessageReceived;
