/**
 * This file contains the custom hook for handling KB settings.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getRatingsAndComments } from "src/services/KnowledgeBase/Settings/Feedback/getRatingsAndComments.service";
import { updateRatingsAndComments } from "src/services/KnowledgeBase/Settings/Feedback/updateRatingsAndComments.service";

/**
 * Custom hook for managing the Rating component in KB settings.
 */
function useRating() {
  // States and variables
  const { integrationId } = useParams();
  const pageIntegrationId = integrationId
    ? decodeURIComponent(integrationId)
    : "";

  //  Query for getting the ratings and comments
  const getRatingsAndCommentData = useQuery({
    queryKey: ["getRatingsAndComments"],
    queryFn: () =>
      getRatingsAndComments({
        integrationId: pageIntegrationId,
      }),
  });

  // Mutation Function for updating the ratings and comments
  const updateRatingAndComments = useMutation({
    mutationFn: updateRatingsAndComments,
    // onSuccess: () => {
    //   getRatingsAndCommentData.refetch();
    // },
  });

  //   States for the rating and comments
  const [allowUsers, setAllowUsers] = useState(
    getRatingsAndCommentData.data?.userAllowedToRate ?? false
  );

  const [showCount, setShowCount] = useState<boolean>(
    getRatingsAndCommentData.data?.ratingShowHelpCount ?? false
  );
  const [selectedRadio, setSelectedRadio] = useState<string | undefined>(
    getRatingsAndCommentData.data?.feedbackRatingType === "emojis"
      ? "flexRadioDefault1"
      : "flexRadioDefault2"
  );

  // State to manage whether rating approval is required
  const [reqApproval, setReqApproval] = useState<boolean>(
    // Initialize with the value from the fetched data, defaulting to false if not present
    getRatingsAndCommentData.data?.ratingApprovalRequired ?? false
  );

  // State to manage the selected rating approval type
  const [selectedRatingApproval, setSelectedRatingApproval] = useState<string>(
    // Initialize based on the fetched data, defaulting to "unApproved" if not "approved"
    getRatingsAndCommentData.data?.ratingApprovalType === "approved"
      ? "approved"
      : "unApproved"
  );

  // Set the states for various rating and comments properties when the data is fetched or changed
  useEffect(() => {
    // Check if data is available
    if (getRatingsAndCommentData?.data) {
      // Set various states based on the fetched data
      setAllowUsers(getRatingsAndCommentData.data.userAllowedToRate);
      setShowCount(getRatingsAndCommentData.data.ratingShowHelpCount);
      setSelectedRadio(
        // Set the selected radio based on the feedbackRatingType in the fetched data
        getRatingsAndCommentData.data.feedbackRatingType === "emojis"
          ? "flexRadioDefault1"
          : "flexRadioDefault2"
      );

      // Update the reqApproval state based on the fetched data, defaulting to true if not present
      setReqApproval(
        getRatingsAndCommentData.data.ratingApprovalRequired ?? true
      );

      // Update the selectedRatingApproval state based on the fetched data
      if (getRatingsAndCommentData.data?.ratingApprovalType === "approved") {
        setSelectedRatingApproval("approved");
      } else {
        setSelectedRatingApproval("unApproved");
      }
    }
  }, [getRatingsAndCommentData.data]);

  return {
    allowUsers,
    setAllowUsers,
    showCount,
    setShowCount,
    selectedRadio,
    setSelectedRadio,
    data: getRatingsAndCommentData.data,
    isError: getRatingsAndCommentData.isError,
    updateRatingAndComments,
    isPreviousData: getRatingsAndCommentData.isPreviousData,
    getRatingsAndCommentData,
    reqApproval,
    selectedRatingApproval,
    setReqApproval,
    setSelectedRatingApproval,
  };
}

export default useRating;
