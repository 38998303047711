/**
 * This file defines a custom hook responsible for deleting external sources
 *
 * @author @yuvaraj-busibud
 */
import {
  InfiniteData,
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { deleteExternalSource } from "src/services/Bot/AnswerSources/deleteExternalSource";
import {
  GetAllExternalParams,
  GetAllExternalResponse,
  Source,
} from "src/services/Bot/AnswerSources/getAllExternal.service";

interface Props {
  id: number | string | undefined;
  updateSource?: (id: number, source: Partial<Source>) => void;
}
function useDeleteExternalSouce({ id, updateSource }: Props) {
  const queryClient = useQueryClient();
  const { profileId } = useParams();
  const [isDeleteInProgress, setIsDeleteInProgress] = useState(false);

  const payload = useMemo(() => {
    let payload: GetAllExternalParams = {
      botProfileId: profileId + "",
      start: 0,
      limit: 25,
    };

    return payload;
  }, [profileId]);

  // Mutation for updating the order
  const deleteExternalSourceMutation = useMutation({
    mutationFn: deleteExternalSource,
    mutationKey: ["deleteExternalSource", id],
    onSuccess: (data, variables, context) => {
      //checking if status is deleted then directly removing entry from list
      if (data.status === "deleted") {
        removeSourceFromList(variables.sourceId);
        setIsDeleteInProgress(false);
      } else {
        //updating the delete status to react-query state
        if (updateSource) {
          updateSource(parseInt(variables.sourceId + ""), {
            status: data.status,
          });
        }
        setIsDeleteInProgress(true);
      }
    },
    onError: () => {
      pushTheToast({
        type: "danger",
        text: "Failed to delete source",
        position: "top-right",
      });
    },
  });

  //function to call ajax for deleting bot profile
  const handleDeleteProfile = useCallback(() => {
    if (id) {
      deleteExternalSourceMutation.mutate({ sourceId: id });
    }
  }, [id]);

  const removeSourceFromList = useCallback(
    (sourceId: number | string) => {
      // Get the current data from the cache
      const externalSources = queryClient.getQueryData<
        InfiniteData<GetAllExternalResponse>
      >(["getAllExternal", payload]);
      if (externalSources) {
        // Find the updated schedule in the list and remove it
        const updatedExternalSources = externalSources.pages.map((page) => {
          const newPages = page.data.filter((source) => source.id != sourceId);
          return {
            ...page,
            data: newPages,
            metaData: {
              total: page.metaData.total - 1,
              count: newPages.length,
            },
          };
        });
        // Update the cache with the new data
        queryClient.setQueryData<InfiniteData<GetAllExternalResponse>>(
          ["getAllExternal", payload],
          {
            pages: updatedExternalSources,
            pageParams: externalSources.pageParams,
          }
        );
      }
    },
    [payload]
  );

  const isLoading = deleteExternalSourceMutation.isLoading;
  const isSuccess = deleteExternalSourceMutation.isSuccess;
  const isError = deleteExternalSourceMutation.isError;

  return {
    handleDeleteProfile,
    removeSourceFromList,
    isLoading,
    isSuccess,
    isError,
    isDeleteInProgress,
  };
}

export default useDeleteExternalSouce;
