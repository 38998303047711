import GeneralTooltip from "src/components/GeneralTooltip/GeneralTooltip";
import styles from "./CompanyProfileImg.module.scss";
import { CustomerDetails } from "src/services/BugReport/getBugByCategory.service";
import AxiosImg from "src/components/AxiosImg";
import UserAvatar from "src/components/UserAvatar";

const RenderCustomerImg = ({
  customerData,
  translate,
}: {
  customerData?: CustomerDetails;
  translate?: boolean;
}) => {
  return (
    <span className={`${translate ? styles.imgWrap : ""}`}>
      {customerData?.imageUrl ? (
        <AxiosImg
          className={`${styles.profileImg}`}
          url={customerData?.imageUrl}
          size={21}
        />
      ) : customerData?.customerName ? (
        <UserAvatar
          className={`${styles.profileImg}`}
          name={customerData?.customerName ?? "N A"}
          size={21}
        />
      ) : (
        <></>
      )}
    </span>
  );
};

const CompanyProfileImg = ({
  companyName = [],
}: {
  companyName: Array<string | null>;
}) => {
  return (
    <div>
      {companyName.length === 1 ? (
        <div className={`me-2 ${styles.overflowHid} d-flex align-items-center`}>
          <RenderCustomerImg
            customerData={{
              customerName: companyName[0] ?? "NA",
              customerEmail: "",
              customerId: 0,
              imageUrl: "",
              isPublicAttachment: false,
            }}
          />
          <span
            className={`ps-1 text-truncate d-inline-flex ${styles.userName}`}
          >
            {companyName[0] ?? "NA"}
          </span>
        </div>
      ) : (
        <div className="d-flex align-items-center">
          <GeneralTooltip
            title={companyName
              .map((company) => company ?? "NA")
              .join(", ")}
            className={`${styles.tooltip}`}
          >
            <div>
              {companyName.slice(0, 3).map((value, index) => (
                <RenderCustomerImg
                  key={index}
                  customerData={{
                    customerName: value ?? "NA",
                    customerEmail: "",
                    customerId: 0,
                    imageUrl: "",
                    isPublicAttachment: false,
                  }}
                  translate={index > 0}
                />
              ))}
            </div>
          </GeneralTooltip>
          {companyName.length > 3 && (
            <span className={`${styles.profileNo} ms-1`}>
              + {companyName.length - 3}
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default CompanyProfileImg;
