import { TicketSliceState } from "./tickets.slice";
import log from "loglevel";
import { current } from "@reduxjs/toolkit";

function fetchTicketsFulfilled(state: TicketSliceState, { payload }: any) {
  const {
    allTickets,
    allTicketsIds,
    cachedTickets,
    totalTicketsCount,
    totalPages,
    currentPage,
  } = payload as any;
  state.allTickets = allTickets;
  state.allTicketsIds = allTicketsIds;
  state.totalTicketsCount = totalTicketsCount;
  state.totalPages = totalPages;
  state.cachedTickets = cachedTickets;
  state.currentPage = currentPage; //updating the current page here for better state management

  if (state.currentPage === 0 && allTicketsIds && allTicketsIds.length) {
    state.currentPage = 1;
  }
  if (allTicketsIds == undefined || allTicketsIds.length === 0) {
    // console.log("HIIII", allTicketsIds, cachedTickets);
    const usablePages = Object.entries(cachedTickets)
      .filter(([key, value]: any, index) => value.length !== 0)
      .map(([key]: any) => parseInt("" + key));
    if (usablePages.length > 0) {
      const currentPageTemp = usablePages[usablePages.length - 1];
      state.currentPage = currentPageTemp;
      state.allTicketsIds = cachedTickets[currentPageTemp];
    } else {
      state.currentPage = 0;
      state.allTicketsIds = [];
    }
  }

  if (state.currentPage > state.totalPages) {
    state.currentPage = state.totalPages;
  }

  // log.info("updated state after fetching tickets:", current(state));
  state.ticketsLoadingState = "fulfilled";
  state.ticketListUpdateAjaxStatus = "fulfilled";
  state.moreColAjaxStatus = { 9: "fulfilled" };
  state.currColumnList[state.currentView].forEach((col) => {
    if (col.show) {
      state.moreColAjaxStatus[col.id] = "fulfilled";
    }
  });
  return state;
}

function fetchTicketsByIdsFulfilled(state: TicketSliceState, { payload }: any) {
  for (const x in payload) {
    state.allTickets[x] = { ...(state.allTickets[x] ?? {}), ...payload[x] };
  }
  return state;
}

const fetchMoreColFulfilled = (state: TicketSliceState, { payload }: any) => {
  for (const x in payload.data) {
    state.allTickets[x] = {
      ...(state.allTickets[x] ?? {}),
      ...payload.data[x],
    };
  }
  state.moreColAjaxStatus[payload.columnId] = "fulfilled";
  return state;
};

export default {
  fetchTicketsFulfilled,
  fetchTicketsByIdsFulfilled,
  fetchMoreColFulfilled,
};
