import { StringOptions } from "sass";
import { axiosJSON } from "src/globals/axiosEndPoints";

export interface IGetLiveChatThreadParams {
  chatRequestId: string | number;
}

export interface IGetLiveChatThreadRes {
  threadText: string;
}

export async function getLiveChatThread(payload: IGetLiveChatThreadParams) {
  const { data } = await axiosJSON.post(
    `/api/liveChat/agent/getLiveChatThread`,
    payload
  );

  // const data = JSON.parse(
  //   `
  //   {
  //       "err": false,
  //       "data": {
  //           "threadText": "<div dir=\\"ltr\\" class=\\"gmail_attr\\"><blockquote style=\\"margin:0px 0px 0px 0.8ex;border-left:1px solid rgb(204,204,204);padding-left:1ex\\"><p><b>Chat transcript:</b></p><p><b>Chat started: 2023-01-11 14:19:34</b></p><p class='customer-message'>Maninder : Hellow</p><p class='customer-message'>Maninder : Hi Sir</p><p class='agent-message'>Helpdesk : Hello</p><p class='agent-message'>Helpdesk : Hello Ticket created</p><p class='agent-message'>Helpdesk : hey man</p></blockquote></div>"
  //       }
  //   }
  //   `
  // );
  
  if (data.err === true) {
    throw new Error(data.msg as string);
  }
  return data.data as IGetLiveChatThreadRes;
}
