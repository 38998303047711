import Lottie from "lottie-light-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Dropdown } from "react-bootstrap";
import ReactSelect, {
  components,
  GroupBase,
  MultiValue,
  OptionProps,
  StylesConfig,
} from "react-select";
import downArrow from "src/assets/images/downArrow.png";
import ripple from "src/assets/images/ripple.json";
import { useAutomationContext } from "src/routes/BotSettings/Children/Automation/Hooks/useAutomationContext";
import {
  IssueDescriptionDetail,
  ListOption,
} from "src/services/Automation/DispatchRulesConfigModal/getDispatchRuleConfigById.service";
import { useDispatchSectionContext } from "../../Hooks/useDispatchSectionContext";
import KnowledgeBaseDisabledPopover from "../DispatchIssueDetails/KnowledgeBaseDisabledPopover/KnowledgeBaseDisabledPopover";
import useSuggestArticles from "../DispatchIssueDetails/SuggestArticles/useSuggestArticles";
import styles from "./DispatchIssueResponses.module.scss";

interface OptionType {
  label: string;
  value: string;
}

const IS_MULTI = true;

const defaultStyles: StylesConfig<
  OptionType,
  typeof IS_MULTI,
  GroupBase<OptionType>
> = {
  control: (provided) => ({
    ...provided,
    backgroundColor: "#FCFCFC",
    color: "#000",
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "18px",
    minHeight: "37px",
    borderRadius: "4px",
    "&:hover": {
      borderColor: "#666666",
      borderRadius: "4px",
    },
    border: "1px solid #666666",
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "#F5F5F5",
    color: "#000",
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "18px",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: "#000",
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "18px",
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: "#707070",
    ":hover": {
      color: "#707070",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "#FFF" : "#000",
    backgroundColor: "#FFF",
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "18px",
    cursor: "pointer",
    ":hover": {
      backgroundColor: "#f1f1f1",
    },
  }),
  menu: (provided) => ({
    ...provided,
    padding: "0px",
    height: "155px",
    border: "none",
    boxShadow: "none",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
  }),
};

type Steps =
  | "isSuggestArticles"
  | "selectKBArticles"
  | "configureReply"
  | "finalize";

const getStepFromIssueDescription = (
  issueDescription: IssueDescriptionDetail,
): Steps => {
  if (issueDescription.isSuggestArticles === undefined) {
    return "isSuggestArticles";
  }

  if (issueDescription.articlesDetails === undefined) {
    return "selectKBArticles";
  }

  if (issueDescription.articleConfiguredResponse === undefined) {
    return "configureReply";
  }

  return "finalize";
};

const DropdownContent = ({
  showDivider,
  index,
  updateHandler,
  issueDescription: desc,
  disableKnowledgeBase,
}: {
  showDivider: boolean;
  issueDescription: IssueDescriptionDetail;
  updateHandler: (
    descriptionDetail: IssueDescriptionDetail,
    index: number,
  ) => void;
  index: number;
  disableKnowledgeBase: boolean;
}) => {
  const [issueDescription, setIssueDescription] =
    useState<IssueDescriptionDetail>({
      ...desc,
    });

  const [stepStage, setStepStage] = useState<Steps>(() =>
    getStepFromIssueDescription({
      ...desc,
    }),
  );

  const {
    state: { integrationId },
    automationType,
  } = useDispatchSectionContext();

  const { options, optionsMap, fetchNextPageHandler, showLoader } =
    useSuggestArticles(integrationId, automationType);

  const newOptions = useMemo(() => {
    return [
      {
        value: "selectAll",
        label: "Select all",
      },
      ...options,
    ];
  }, [options]);

  const OptionComponent: React.ComponentType<
    OptionProps<OptionType, typeof IS_MULTI, GroupBase<OptionType>>
  > = useMemo(
    () => (props) => {
      const isSelectAll = props.data.value === "selectAll";

      // Check if all options (except "Select all") are selected
      const allSelected =
        options.length > 0 &&
        options.every(
          (option) =>
            issueDescription?.articlesDetails?.some(
              (article) => article.id === option.value,
            ),
        );

      // Determine if the current option is checked (individual or "Select all")
      const isChecked = isSelectAll
        ? allSelected // "Select all" should be checked if all options are selected
        : issueDescription?.articlesDetails?.some(
            (article) => article.id === props.data.value,
          );

      return (
        <components.Option {...props}>
          <div className="d-flex align-items-center justify-content-start">
            <input
              type="checkbox"
              checked={isChecked}
              onChange={(e) => {
                // handleSelectArticles(e.target.checked);
              }}
              className={`${styles.selectCheckBox} flex-shrink-0`}
            />{" "}
            <label className={`ms-2 ${styles.selectLabel}`}>
              {props.label}
            </label>
          </div>
        </components.Option>
      );
    },
    [issueDescription?.articlesDetails, options],
  );

  const [accordion, setAccordion] = useState<boolean>(false);
  const [isSaved, setIsSaved] = useState<boolean>(false);

  /**
   * Side Effect for initializing finalized step
   */
  useEffect(() => {
    if (
      issueDescription.isSuggestArticles === undefined ||
      issueDescription.articlesDetails === undefined ||
      issueDescription.articleConfiguredResponse === undefined
    ) {
      return;
    }

    if (
      issueDescription.isSuggestArticles === true &&
      issueDescription.articlesDetails.length !== 0 &&
      issueDescription.articleConfiguredResponse.length !== 0
    ) {
      setStepStage("finalize");
    }
  }, [
    issueDescription.articleConfiguredResponse,
    issueDescription.articlesDetails,
    issueDescription.isSuggestArticles,
  ]);

  /**
   * Validated state
   */
  const isStepValidated: boolean = useMemo(() => {
    if (
      stepStage === "isSuggestArticles" &&
      issueDescription.isSuggestArticles === undefined
    ) {
      return false;
    }

    if (
      stepStage === "selectKBArticles" &&
      (issueDescription.articlesDetails === undefined ||
        issueDescription.articlesDetails.length === 0)
    ) {
      return false;
    }

    if (
      stepStage === "configureReply" &&
      (issueDescription.articleConfiguredResponse === undefined ||
        issueDescription.articleConfiguredResponse.length === 0)
    ) {
      return false;
    }

    if (
      stepStage === "finalize" &&
      (issueDescription.isSuggestArticles === undefined ||
        (issueDescription.isSuggestArticles === true &&
          (issueDescription.articlesDetails?.length === 0 ||
            issueDescription.articleConfiguredResponse?.length === 0)))
    ) {
      return false;
    }

    return true;
  }, [
    issueDescription.articleConfiguredResponse,
    issueDescription.articlesDetails,
    issueDescription.isSuggestArticles,
    stepStage,
  ]);

  const handleIsSuggestArticles = useCallback((checked: boolean) => {
    setIssueDescription((prev) => {
      return { ...prev, isSuggestArticles: checked };
    });

    if (checked === false) {
      setStepStage("finalize");
    } else {
      // console.log(issueDescription);
      // const step = getStepFromIssueDescription(issueDescription);
      // console.log(step);
      // setStepStage(step);
      setStepStage("isSuggestArticles");
    }

    setIsSaved(false);
  }, []);

  const handleIsConfigureReply = useCallback((checked: boolean) => {
    setIssueDescription((prev) => {
      return { ...prev, isCernReplyToCustomerResponse: checked };
    });

    if (checked === false) {
      setStepStage("finalize");
    } else {
      // setStepStage(getStepFromIssueDescription(issueDescription));
      setStepStage("configureReply");
    }

    setIsSaved(false);
  }, []);

  const handleSelectArticles = useCallback(
    (val: MultiValue<OptionType>) => {
      // Check if "Select all" is selected
      const isSelectAllSelected = val.some(
        (option) => option.value === "selectAll",
      );
      // If "Select all" is chosen, select or deselect all options
      if (isSelectAllSelected) {
        // If all options are already selected, uncheck all
        if (val.length === options.length + 1) {
          // +1 accounts for "Select all"
          setIssueDescription((prev) => ({
            ...prev,
            articlesDetails: [],
          }));
        } else {
          // Otherwise, select all options
          const allOptions = options.map((option) => ({
            id: option.value,
            name: option.label,
          }));
          setIssueDescription((prev) => ({
            ...prev,
            articlesDetails: allOptions,
          }));
        }
      } else {
        // Handle selection of individual options (when "Select all" is not clicked)
        setIssueDescription((prev) => ({
          ...prev,
          articlesDetails: val.map(
            (val) => ({ id: val.value, name: val.label }) as ListOption,
          ),
        }));
      }

      // Set save state to false
      setIsSaved(false);
    },
    [options],
  );

  const handleRemoveSelectedArticle = useCallback((val: OptionType) => {
    setIssueDescription((prev) => ({
      ...prev,
      articlesDetails: prev?.articlesDetails?.filter(
        (article) => article.id !== val.value,
      ),
    }));
  }, []);

  const handleArticleConfigureResponse: React.ChangeEventHandler<HTMLTextAreaElement> =
    useCallback((event) => {
      const text = event.target.value;
      setIssueDescription((prev) => {
        return { ...prev, articleConfiguredResponse: text };
      });
      setIsSaved(false);
    }, []);

  const handleNext = useCallback(() => {
    if (stepStage === "finalize" && isStepValidated) {
      // Save the current state
      updateHandler({ ...issueDescription }, index);
      setIsSaved(true);
    } else {
      // Move to Next
      setStepStage((prev) => {
        if (prev === "isSuggestArticles") {
          return "selectKBArticles";
        }

        if (prev === "selectKBArticles") {
          return "configureReply";
        }

        return prev;
      });
    }
  }, [index, isStepValidated, issueDescription, stepStage, updateHandler]);

  const selectedValue = useMemo(() => {
    return (
      issueDescription.articlesDetails
        ?.map((article) => optionsMap[article.id] || null)
        .filter(Boolean) ?? []
    );
  }, [issueDescription, optionsMap]);
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div>
      <button
        className={`d-flex justify-content-between align-items-center cursor-pointer p-3 ${styles.accordWrap}`}
        onClick={() => setAccordion((prev) => !prev)}
        aria-expanded={accordion}
        aria-controls={`accordion-content-${index}`}
      >
        <span className={`pe-1 ${styles.accordionHead}`}>
          Issue {index + 1}: {issueDescription.description}
        </span>
        <span>
          <img
            src={downArrow}
            alt={accordion ? "Collapse" : "Expand"}
            width={12}
            className={`${accordion && styles.arrowDrop}`}
          />
        </span>
      </button>
      {accordion ? (
        <div className={`px-3 pb-3 ${styles.accordionBody}`}>
          {/* Is Suggest Articles Radio Buttons */}
          <div className={`mb-3 ${styles.boxWrapper}`}>
            <h4 className={`mb-1 ${styles.radioHead}`}>
              Would you like to suggest knowledge base articles in the response
              to the customer for this issue?
            </h4>
            <div className="form-check mb-0">
              <input
                className={`form-check-input ${styles.radioCheck}`}
                type="radio"
                name={`${index}_kb_articles`}
                id={`${index}_kb_article_yes`}
                checked={issueDescription.isSuggestArticles === true}
                disabled={disableKnowledgeBase}
                onChange={() => handleIsSuggestArticles(true)}
              />
              <label
                className={`form-check-label ${styles.formLabel}`}
                htmlFor={`${index}_kb_article_yes`}
              >
                Yes
                {disableKnowledgeBase && <KnowledgeBaseDisabledPopover />}
              </label>
            </div>
            <div className="form-check mb-0">
              <input
                className={`form-check-input ${styles.radioCheck}`}
                type="radio"
                name={`${index}_kb_articles`}
                id={`${index}_kb_article_no`}
                checked={issueDescription.isSuggestArticles === false}
                onChange={() => handleIsSuggestArticles(false)}
              />
              <label
                className={`form-check-label ${styles.formLabel}`}
                htmlFor={`${index}_kb_article_no`}
              >
                No
              </label>
            </div>
          </div>

          {/* Knowledge base articles selection */}
          {stepStage !== "isSuggestArticles" &&
            issueDescription.isSuggestArticles === true && (
              <div className={`mb-3 ${styles.boxWrapper}`}>
                <h4 className={`mb-1 ${styles.radioHead}`}>
                  Please select knowlege-base articles for individual issue
                  descriptions?
                </h4>
                <div>
                  <label
                    htmlFor="exampleFormControlTextarea1"
                    className={`mb-0 form-label ${styles.desc}`}
                  >
                    Issue Description:
                  </label>
                  <p className={`mb-0 ${styles.descSub}`}>
                    {issueDescription.description}
                  </p>
                  <div className="mt-2 d-flex flex-column">
                    <label
                      htmlFor=""
                      className={`mb-0 form-label ${styles.artLabel}`}
                    >
                      Articles
                    </label>

                    <Dropdown
                      onToggle={(nextShow) => setMenuOpen(nextShow)}
                      drop="down"
                    >
                      <Dropdown.Toggle
                        variant=""
                        id="dropdown-basic"
                        bsPrefix={`d-flex align-items-center justify-content-between ${styles.selectDropdown} flex-wrap`}
                      >
                        <div className={`${styles.selectBox} d-flex`}>
                          {selectedValue?.length > 0 ? (
                            <div className="d-flex">
                              {selectedValue.map((selected) => {
                                return (
                                  <div
                                    className={`${styles.valueBox} ms-1 p-1 d-flex align-items-center justify-content-between`}
                                  >
                                    <span>{selected.label}</span>
                                    <span
                                      onClick={() =>
                                        handleRemoveSelectedArticle(selected)
                                      }
                                    >
                                      <i
                                        className={`fa-solid fa-close ${styles.close} ms-2 me-1`}
                                      ></i>
                                    </span>
                                  </div>
                                );
                              })}
                            </div>
                          ) : (
                            <div className={`${styles.selectText}`}>
                              Select articles
                            </div>
                          )}
                        </div>
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        bsPrefix={`dropdown-menu ${styles.menuBox} p-2`}
                      >
                        <ReactSelect
                          menuIsOpen={menuOpen}
                          autoFocus
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          backspaceRemovesValue={false}
                          options={newOptions}
                          className="border-danger"
                          placeholder="Search articles"
                          components={{
                            Option: OptionComponent,
                            IndicatorSeparator: null,
                            DropdownIndicator: null,
                          }}
                          controlShouldRenderValue={false}
                          isClearable={false}
                          isMulti={IS_MULTI}
                          value={selectedValue}
                          onChange={handleSelectArticles}
                          styles={defaultStyles}
                          isLoading={showLoader}
                          onMenuScrollToBottom={fetchNextPageHandler}
                        />
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            )}

          {/* Configure article Response */}
          {stepStage !== "isSuggestArticles" &&
            stepStage !== "selectKBArticles" &&
            issueDescription.isSuggestArticles === true && (
              <div className={`mb-3 ${styles.boxWrapper}`}>
                <h4 className={`mb-1 ${styles.radioHead}`}>
                  Is there an automated response that you would like to be sent
                  out by Cern AI, to any customers reporting such an issue?
                </h4>
                <textarea
                  className={`form-control ${styles.textBox}`}
                  id={`${index}_kb_articles_configured_response`}
                  rows={3}
                  value={issueDescription.articleConfiguredResponse}
                  onChange={handleArticleConfigureResponse}
                ></textarea>
              </div>
            )}

          {/* Is Configure Reply Radio buttons */}
          <div className={`mb-3 ${styles.boxWrapper} d-none`}>
            <h4 className={`mb-1 ${styles.radioHead}`}>
              In case the customer might respond to this communication from you
              in the same thread, would you like to configure a reply to their
              response?
            </h4>
            <div className="form-check mb-0">
              <input
                className={`form-check-input ${styles.radioCheck}`}
                type="radio"
                name={`${index}_configure_reply`}
                id={`${index}_configure_reply_yes`}
                checked={
                  issueDescription.isCernReplyToCustomerResponse === true
                }
                onChange={() => handleIsConfigureReply(true)}
              />
              <label
                className={`form-check-label ${styles.formLabel}`}
                htmlFor={`${index}_configure_reply_yes`}
              >
                Yes
              </label>
            </div>
            <div className="form-check mb-0">
              <input
                className={`form-check-input ${styles.radioCheck}`}
                type="radio"
                name={`${index}_configure_reply`}
                id={`${index}_configure_reply_no`}
                checked={
                  issueDescription.isCernReplyToCustomerResponse === false
                }
                onChange={() => handleIsConfigureReply(false)}
              />
              <label
                className={`form-check-label ${styles.formLabel}`}
                htmlFor={`${index}_configure_reply_no`}
              >
                No
              </label>
            </div>
          </div>

          {/* COnfigure Bot Response and Reply */}
          <div className={`mb-3 ${styles.boxWrapper} d-none`}>
            <h4 className={`mb-1 ${styles.radioHead}`}>
              Please configure your response below:
            </h4>
            <div className="row mb-2">
              <div className="col-4">
                <h4 className={`mb-1 ${styles.radioHead}`}>Customer reply:</h4>
                <textarea
                  className={`form-control ${styles.resBox}`}
                  id="exampleFormControlTextarea1"
                  rows={3}
                ></textarea>
              </div>
              <div className="col-8">
                <h4 className={`mb-1 ${styles.radioHead}`}>
                  Please configure your response below:
                </h4>
                <textarea
                  className={`form-control ${styles.resBox}`}
                  id="exampleFormControlTextarea1"
                  rows={3}
                ></textarea>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-4">
                <h4 className={`mb-1 ${styles.radioHead}`}>
                  New customer response combination:
                </h4>
                <textarea
                  className={`form-control ${styles.resBox}`}
                  id="exampleFormControlTextarea1"
                  rows={3}
                ></textarea>
              </div>
              <div className="col-8">
                <div className="d-flex justify-content-between align-items-center">
                  <h4 className={`mb-1 ${styles.radioHead}`}>
                    New response combination:
                  </h4>
                  <span className={`${styles.deleteText}`}>Delete</span>
                </div>

                <textarea
                  className={`form-control ${styles.resBox}`}
                  id="exampleFormControlTextarea1"
                  rows={3}
                ></textarea>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className={`${styles.addIcon}`}>
                <span>+</span>
              </div>
              <span className={`ms-2 ${styles.addText}`}>
                Add new response combination
              </span>
            </div>
          </div>

          {/* Buttons */}
          <button
            className={`mt-3  ${styles.moveBtn}`}
            onClick={handleNext}
            disabled={!isStepValidated || isSaved}
          >
            <span style={{ width: "33px", height: "33px" }}>
              <Lottie animationData={ripple}></Lottie>
            </span>
            <span>
              {stepStage === "finalize"
                ? "Finalize response settings"
                : "Move to next step"}
            </span>
          </button>

          {/* Saved status */}
          {isSaved && (
            <div className="d-flex align-items-center py-2">
              <div className={`${styles.checkedBox}`}>
                <span>
                  <i className="fa-solid fa-check"></i>
                </span>
              </div>
              <span className={`ms-2 ${styles.checkText}`}>
                Response settings finalized
              </span>
            </div>
          )}
        </div>
      ) : (
        ""
      )}
      {showDivider && <div className={`${styles.devider}`}></div>}
    </div>
  );
};

const DispatchIssueResponses = () => {
  const {
    state: { issueDescription },
    updateState,
    setShowErrors,
  } = useDispatchSectionContext();
  const { formData } = useAutomationContext();

  const updateHandler = useCallback(
    (descriptionDetail: IssueDescriptionDetail, index: number) => {
      const newDescription = [...issueDescription];
      newDescription[index] = descriptionDetail;
      updateState({ issueDescription: newDescription });
      setShowErrors(false);
    },
    [issueDescription, setShowErrors, updateState],
  );

  return (
    <div className={`${styles.dispatchWrapper}`}>
      <h4 className={`${styles.dispatchHeader}`}>
        Configure customer response:
      </h4>
      <p className={`mb-2 ${styles.accordHeading}`}>Customer Issues:</p>
      <div className={`${styles.toggleWrapper}`}>
        {issueDescription.map((desc, idx) => {
          return (
            <DropdownContent
              key={idx}
              updateHandler={updateHandler}
              showDivider={idx !== issueDescription.length - 1}
              issueDescription={desc}
              index={idx}
              disableKnowledgeBase={formData.hasKnowledgeBaseArticles === false}
            />
          );
        })}
      </div>
    </div>
  );
};

export default DispatchIssueResponses;
