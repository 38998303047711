import { useCallback, useRef, useState } from "react";
import { Modal, OverlayTrigger, Popover, Spinner } from "react-bootstrap";
import useClickAway from "src/hooks/useClickAway";
import { useAppDispatch } from "src/store/store";
import useChatPagination from "./useChatPagination";
import {
  setSendCernMsgId,
  setShowCernThread,
} from "src/store/slices/innerTicket/innerTicket.slice";
import AxiosImg from "src/components/AxiosImg";
import InfiniteScroll from "src/components/InfiniteScrollBothSide";
import UserAvatar from "src/components/UserAvatar";
import BotUser from "./Children/BotUser/BotUser";
import BotView, { BotViewTicketData } from "./Children/BotView/BotView";
import ExternalChat from "./Children/ExternalChat/ExternalChat";
import styles from "./InternalBotChat.module.scss";
import saufterLogo from "src/assets/images/saufter.gif";

const LiveChatIcon = () => {
  return (
    <div className="text-center mb-5">
      <OverlayTrigger
        placement="left"
        overlay={
          <Popover
            id="popover-basic"
            bsPrefix={`popover ${styles.popoverWrapper}`}
          >
            <Popover.Body
              as="div"
              bsPrefix={`popover-body ${styles.botView} p-2`}
            >
              Live Chat
            </Popover.Body>
          </Popover>
        }
        trigger={["hover", "focus"]}
        delay={200}
      >
        <svg
          width={22}
          height={25}
          viewBox="0 0 22 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20 6.02706V18.0471C20 19.1471 19.1 20.0471 18 20.0471H4L0 24.0471V6.04706C0 4.94706 0.9 4.04706 2 4.04706H16.1C16.04 4.36706 16 4.70706 16 5.04706C16 5.38706 16.04 5.72706 16.1 6.04706H2V18.0471H18V6.94706C18.74 6.79706 19.42 6.46706 20 6.02706Z"
            fill="#656565"
          />
          <circle
            cx="17.7435"
            cy="4.98515"
            r="4.18858"
            fill="#FF8499"
          />
        </svg>
      </OverlayTrigger>
    </div>
  );
};

const CernAI = () => {
  const dispatch = useAppDispatch();

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className={`${styles.separator}`}></div>
        <span className={`${styles.sidebarText}`}>Cern AI</span>
        <div className={`${styles.separator}`}></div>
      </div>
      <div className="d-flex justify-content-center mb-3">
        <img
          src={saufterLogo}
          alt="logo"
          className={`${styles.logo} cursor-pointer`}
          onClick={() => {
            dispatch(setSendCernMsgId({ msgId: null }));
            dispatch(setShowCernThread({ show: true }));
          }}
        />
      </div>
    </>
  );
};

const AddChatOverlay = ({
  onShowExternal,
  onShowInternal,
  setInternalChat,
}: {
  onShowExternal: () => void;
  onShowInternal: () => void;
  setInternalChat: (value: boolean) => void;
}) => {
  const [showPopover, setShowPopover] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  useClickAway(ref, () => setShowPopover(false));

  return (
    <OverlayTrigger
      placement="left"
      trigger={"click"}
      show={showPopover}
      onToggle={(nextShow) => setShowPopover(nextShow)}
      overlay={
        <Popover
          id="popover-basic"
          bsPrefix={`popover ${styles.AddWrapper}`}
        >
          <Popover.Body
            as="div"
            ref={ref}
            bsPrefix={`popover-body ${styles.showPopover}`}
          >
            {/* Show ExternalChat Component */}
            <div
              className={`${styles.externalOptions}`}
              onClick={() => {
                onShowExternal();
                setShowPopover(false);
              }}
            >
              <span className="px-4">External communication</span>
            </div>

            {/* Show BotView Component  */}
            <div
              className={`${styles.internalOptions}`}
              onClick={() => {
                onShowInternal();
                setShowPopover(false);
                setInternalChat(true);
              }}
            >
              <span className="px-4">Internal communication</span>
            </div>
          </Popover.Body>
        </Popover>
      }
    >
      <div className="d-flex justify-content-center mb-3 cursor-pointer">
        <div className={`${styles.addIcon}`}>
          <span>
            <i className="fa-solid fa-plus"></i>
          </span>
        </div>
      </div>
    </OverlayTrigger>
  );
};

const MyTicket = ({
  ticketData,
  onShowInternal,
  setInternalChat,
}: {
  ticketData: BotViewTicketData;
  onShowInternal: () => void;
  setInternalChat: (value: boolean) => void;
}) => {
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className={`${styles.separator}`}></div>
        <span className={`${styles.sidebarText}`}>This ticket</span>
        <div className={`${styles.separator}`}></div>
      </div>
      <div
        className={`w-100 d-flex justify-content-center mb-3 cursor-pointer ${styles.thisTicket}`}
        onClick={() => {
          onShowInternal();
          setInternalChat(false);
        }}
      >
        {ticketData.ticket_customer_details?.imgURL ? (
          <AxiosImg
            url={ticketData.ticket_customer_details.imgURL}
            className={styles.userName}
          />
        ) : (
          <UserAvatar
            name={
              ticketData?.ticket_customer_details?.name ??
              ticketData?.ticket_customer_name ??
              "NA"
            }
            size={32}
          />
        )}
      </div>
    </div>
  );
};

const ChatList = ({
  context,
  linkedTicketId,
  setShowChat,
  setTicketData,
  setInternalChat,
  callbackRefs,
}: {
  context: "internal" | "external" | "linked";
  linkedTicketId?: string | number;
  setShowChat: React.Dispatch<React.SetStateAction<boolean>>;
  setTicketData: React.Dispatch<React.SetStateAction<BotViewTicketData | null>>;
  setInternalChat: React.Dispatch<React.SetStateAction<boolean>>;
  callbackRefs?: React.MutableRefObject<{
    addTicket: (ticketData: BotViewTicketData) => void;
  }>;
}) => {
  const externalRef = useRef<HTMLDivElement>(null);
  const externalNoteRef = useRef<HTMLDivElement>(null);

  const { chats, changePage, hasNextPage, isLoading, totalChats } =
    useChatPagination({
      tableType: context === "internal" ? "mentions" : "all",
      linkedTicketId,
      callbackRefs,
    });

  const handleScroll = useCallback(
    (
      sourceDiv: React.RefObject<HTMLDivElement>,
      targetDiv: React.RefObject<HTMLDivElement>,
    ) => {
      if (sourceDiv.current && targetDiv.current) {
        targetDiv.current.scrollTop = sourceDiv.current.scrollTop / 5;
      }
    },
    [],
  );

  const handleBtnClickScroll = useCallback(
    (ref: React.RefObject<HTMLDivElement>, scrollDown: boolean) => {
      if (ref.current && scrollDown) {
        ref.current.scrollTop += ref.current.clientHeight + 6.5;
      } else if (ref.current) {
        ref.current.scrollTop -= ref.current.clientHeight + 6.5;
      }
    },
    [],
  );

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className={`${styles.separator}`}></div>
        <span className={`${styles.sidebarText}`}>
          {context === "external"
            ? "External chats"
            : context === "internal"
              ? "Other internal"
              : "Linked tickets"}
        </span>
        <div className={`${styles.separator}`}></div>
      </div>
      <InfiniteScroll
        initialDataLoaded={true}
        hasMoreBottom={hasNextPage || isLoading}
        loadMoreFromBottom={changePage}
        className={`${styles.chatContainer} scrollbar-hide`}
        root={externalRef}
        onScroll={
          context === "external"
            ? () => handleScroll(externalRef, externalNoteRef)
            : undefined
        }
        infiniteLoaderBottom={
          <div
            className={`mb-2`}
            onClick={changePage}
          >
            <Spinner
              size="sm"
              animation="border"
            />
          </div>
        }
      >
        <div
          className={`ms-1`}
          onScroll={
            context === "external"
              ? () => handleScroll(externalRef, externalNoteRef)
              : undefined
          }
        >
          {chats.map((ticket, index) => (
            <BotUser
              customer={ticket.ticket_customer_details}
              ticketId={ticket.ticket_id}
              setTicketData={() => setTicketData(ticket)}
              socialIcon={ticket.ticket_channel}
              lastMsg={ticket.last_message}
              lastMsgDate={ticket.last_message_date}
              missedChatCount={ticket.missedChatCount}
              activeChatCount={ticket.activeChatCount}
              altText={ticket.last_message}
              setShowChat={setShowChat}
              internalChat={false}
              setInternalChat={setInternalChat}
              key={index}
            />
          ))}
        </div>
      </InfiniteScroll>

      {chats.length > 3 && (
        <div
          className={`d-flex justify-content-center align-items-center ${styles.navigation}`}
        >
          <span
            className={`${styles.arrow} cursor-pointer text-end`}
            onClick={() => handleBtnClickScroll(externalRef, false)}
          >
            <i className="fa-solid fa-angle-left"></i>
          </span>
          <span className={`mx-1 ${styles.navigationSeparator}`}></span>
          <span
            className={`${styles.arrow} cursor-pointer`}
            onClick={() => handleBtnClickScroll(externalRef, true)}
          >
            <i className="fa-solid fa-angle-right"></i>
          </span>
        </div>
      )}
      {context === "external" && (
        <>
          {totalChats && totalChats > 100 ? (
            <div className={styles.botMoreText}>
              <span className={`py-1 ${styles.moreText}`}>100+ more</span>
            </div>
          ) : (
            <div
              ref={externalNoteRef}
              className={styles.botMoreText}
            >
              {chats.map((chat, index) => {
                if (index % 2 === 0)
                  return (
                    <span
                      className={`py-1 ${styles.moreText}`}
                      key={index}
                    >
                      {totalChats ? totalChats - index : ""} more
                    </span>
                  );
                return "";
              })}
            </div>
          )}
        </>
      )}
    </>
  );
};

const InternalBotChat = ({
  initialticketData,
}: {
  initialticketData?: BotViewTicketData;
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showChat, setShowChat] = useState<boolean>(false);
  const [ticketData, setTicketData] = useState<BotViewTicketData | null>(null);
  const [internalChat, setInternalChat] = useState(false);
  const callbackRefs = useRef({
    addTicket: (ticketData: BotViewTicketData) => {},
  });

  const handleAddTicketData = useCallback((ticketData: BotViewTicketData) => {
    setTicketData(ticketData);
    callbackRefs.current.addTicket(ticketData);
  }, []);

  return (
    <div className="position-relative">
      <div className={`px-2 pt-3 ${styles.botWrapper}`}>
        {/* Live Chat SVG */}
        <LiveChatIcon />
        {/* Cern AI */}
        <CernAI />
        {/* Linked tickets */}
        {initialticketData?.ticket_id ? (
          <ChatList
            context={"linked"}
            linkedTicketId={initialticketData?.ticket_id}
            setShowChat={setShowChat}
            setTicketData={setTicketData}
            setInternalChat={setInternalChat}
            callbackRefs={callbackRefs}
          />
        ) : (
          ""
        )}
        {/* Add chat overlay */}
        {initialticketData ? (
          <AddChatOverlay
            onShowExternal={() => setShowModal(true)}
            onShowInternal={() => {
              setTicketData(initialticketData);
              setShowChat(true);
            }}
            setInternalChat={(value: boolean) => setInternalChat(value)}
          />
        ) : (
          ""
        )}
        {/* External */}
        <ChatList
          context={"external"}
          setShowChat={setShowChat}
          setTicketData={setTicketData}
          setInternalChat={setInternalChat}
        />
        <div className="d-flex justify-content-center mb-3">
          <div
            className={`${styles.addIcon}`}
            onClick={() => setShowModal(true)}
          >
            <span>
              <i className="fa-solid fa-plus"></i>
            </span>
          </div>
        </div>
        {initialticketData?.ticket_id ? (
          <Modal
            show={showModal}
            onHide={() => setShowModal(false)}
            contentClassName={`${styles.modalContent}`}
            dialogClassName={`${styles.dialogClass}`}
            backdropClassName={`${styles.backDropClass}`}
            id="externalModal"
            enforceFocus={false}
            centered={true}
            backdrop="static" // Prevents clicking outside the modal to close it
          >
            <ExternalChat
              onHide={() => setShowModal(false)}
              fromEmail={initialticketData?.ticket_from ?? ""}
              setTicketData={handleAddTicketData}
              setShowChat={setShowChat}
              linkedTicketId={initialticketData?.ticket_id}
            />
          </Modal>
        ) : (
          ""
        )}
        {/* Internal */}
        <ChatList
          context={"internal"}
          setShowChat={setShowChat}
          setTicketData={setTicketData}
          setInternalChat={setInternalChat}
        />
      </div>
      {showChat === true && (
        <BotView
          onHide={() => {
            setShowChat(false);
          }}
          ticketData={ticketData}
          internalChat={internalChat}
        />
      )}
    </div>
  );
};

export default InternalBotChat;
