import {
  reportInternalFiltersActions,
  useReportInternalFilters,
} from "src/routes/Report/hooks/reportFilters/useReportInternalFilters";
import styles from "./DailyPart.module.scss";
import { useCallback, useEffect } from "react";

const DailyPart = ({ disabled }: { disabled: boolean }) => {
  const { internalFilters, dispatch } = useReportInternalFilters();

  const changeFilters = useCallback((e: any) => {
    dispatch([
      reportInternalFiltersActions.setReportDateType,
      e.currentTarget.getAttribute("data-value"),
    ]);
  }, []);

  return (
    <div className={`d-flex align-items-center px-2 ${styles.timeWrapper}`}>
      <span
        className={`px-2 ${
          internalFilters.reportDateType === "daily"
            ? styles.timeSpanActive
            : styles.timeSpan
        } ${disabled && styles.disable}`}
        data-value="daily"
        onClick={changeFilters}
      >
        Daily
      </span>
      <span
        className={`ms-2 px-2 ${
          internalFilters.reportDateType === "weekly"
            ? styles.timeSpanActive
            : styles.timeSpan
        } ${disabled && styles.disable}`}
        data-value="weekly"
        onClick={changeFilters}
      >
        Weekly
      </span>
      <span
        className={`ms-2 px-2 ${
          internalFilters.reportDateType === "monthly"
            ? styles.timeSpanActive
            : styles.timeSpan
        } ${disabled && styles.disable}`}
        data-value="monthly"
        onClick={changeFilters}
      >
        Monthly
      </span>
    </div>
  );
};

export default DailyPart;
