import { useState, useEffect } from "react";
import TicketService from "src/services/TicketService/TicketService";
import log from "loglevel";
import { DraftStatusesData } from "src/services/TicketService/getDraftStatusesOptions";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { ChangeDraftStatusPayload } from "src/services/TicketService/changeDraftStatus";

function useChangeDraftStatus() {
  const [loading, setLoading] = useState(false);
  const [loadingError, setLoadingError] = useState<null | string>(null);

  const changeDraftStatus = (
    draftStatusId: any,
    ticketId: any,
    msgId: any,
    callback?: any
  ) => {
    setLoading(true);
    const sendAjax = async () => {
      try {
        const payload: ChangeDraftStatusPayload = {
          ticketId,
          draftStatusId,
          msgId
        };
        const data = await TicketService.changeDraftStatus(payload);
        setLoading(false);
        if (callback) {
          callback(true);
        }
        // log.debug("draft status changed:", data);
      } catch (error) {
        const errorMessage = (error as Error).message;
        setLoadingError(errorMessage);
        setLoading(false);
        log.error("error changing draft status, error:", errorMessage);
        pushTheToast({
          type: "danger",
          text: "Error Changing Draft Status!",
          position: "top-right"
        });
        if (callback) {
          callback(false);
        }
      }
    };
    sendAjax();
  };

  return { loading, loadingError, changeDraftStatus };
}

export default useChangeDraftStatus;
