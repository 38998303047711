import React from "react";
import styles from "./SuccessMsg.module.scss";
import successImg from "src/assets/images/successMsg.gif";
import cross from "src/assets/images/featuredArticledCross.png";
interface Props {
  onHide: () => void;
}
const SuccessMsg = ({ onHide }: Props) => {
  return (
    <div className={`p-4 ${styles.modal}`}>
      <div className="d-flex justify-content-end">
        <span className={`cursor-pointer ${styles.close}`} onClick={onHide}>
          {/* <i className="fa-solid fa-xmark"></i> */}
          <img src={cross} alt=""></img>
        </span>
      </div>
      <div className={`text-center py-5 ${styles.modalContent}`}>
        <div className={`mx-auto ${styles.checkBox}`}>
          <span>
            <img src={successImg} alt="" className={`${styles.successMsg}`} />
          </span>
        </div>
        <p className={`text-center mt-4 pt-2 ${styles.successtext}`}>
          Featured articles enabled !
        </p>
      </div>
    </div>
  );
};

export default SuccessMsg;
