import {
  ConfigConditionType,
  GetConfigModalParams,
} from "src/features/ReturnAutoWorkFlow/children/ConfigModals/oldReturnModals.types";
import { axiosJSON } from "src/globals/axiosEndPoints";

export interface AntiRefundStrategiesOrdersConditions {
  conditions: ConfigConditionType[];
  defaultConditions: ConfigConditionType[];
}

async function getAntiRefundStrategiesOrdersConditionsService(
  params: GetConfigModalParams,
) {
  const { data: res } = await axiosJSON.get(
    "/api/setting/automation/refund/step/13/conditionOptions",
    { params },
  );

  if (res.err || res.error) {
    throw new Error(res.msg ?? res.message ?? "Some Error Occurred.");
  }

  return res.data as AntiRefundStrategiesOrdersConditions;
}

export default getAntiRefundStrategiesOrdersConditionsService;
