import { useEffect, useMemo, useState } from "react";

function useErrorMessage(isError: boolean, className="") {
  return useMemo(() => {
    if (isError) {
      return (
        <div className={`${className} m-auto mt-2 text-center`}>
          <p className={` mt-2 mb-0`}>No Schedule Message Found</p>
        </div>
      );
    } else {
      return undefined;
    }
  }, [isError]);
}

export default useErrorMessage;
