import styles from "./OrderSummary.module.scss";
import returnImg from "src/assets/images/ReturnImg.png";
import orderImg from "src/assets/images/order1.png";
import orderImg2 from "src/assets/images/order2.png";
import TimeLine from "../Timeline/Timeline";
import {
  IAmount,
  IOrder,
  useReturnPreviewState,
} from "../../../../../../hooks/useReturnPreview";
import ButtonWrapper from "../ButtonWrapper/ButtonWrapper";
import WidgetFooter from "../WidgetFooter/WidgetFooter";
import bag from "src/assets/images/chat/bag.png";
import { dummyProducts } from "../SearchProducts/SearchProducts";
import { Modal } from "react-bootstrap";
import { useState } from "react";
import { IRestockingFee } from "src/store/slices/initiateReturn/retrunExchange.slice";
import useGetAutomationData from "src/features/ReturnAutoWorkFlow/hooks/useGetAutomationData";
import { getCurrencySymbol } from "src/utils/utils";

const OrderSummary = () => {
  const { selectedOrder, orders, updateState, ...contextValues } =
    useReturnPreviewState();
  const { getRestockingFeeData } = useGetAutomationData();
  const { restockingFee, currency } = getRestockingFeeData();
  const [showModal, setShowModal] = useState(false);
  const items = Object.values(selectedOrder?.addedItems ?? {});
  const calculateTotalForAddedItems = () => {
    if (
      !selectedOrder ||
      !selectedOrder.addedItems ||
      !selectedOrder.itemsInOrder
    ) {
      return { currencyCode: "", amount: 0 };
    }

    let totalAmount = 0;
    let currencyCode = "";

    // Iterate over each added item
    for (const addedItemId in selectedOrder.addedItems) {
      const addedItem = selectedOrder.addedItems[addedItemId];

      // Find the corresponding item in the order
      const orderItem = selectedOrder.itemsInOrder.find(
        (item) => item.id === addedItem.itemId,
      );

      if (orderItem) {
        // Assume all items have the same currency code
        currencyCode = orderItem.unitPrice.currencyCode;
        // Calculate the amount for this added item
        const itemTotal = orderItem.unitPrice.amount * addedItem.quantity;
        totalAmount += itemTotal;
      }
    }

    return { currencyCode, amount: totalAmount };
  };
  const calculateTotalExchangeAmount = () => {
    let totalAmount = 0;
    let currencyCode = "$";

    items?.forEach((item) => {
      item.exchangeItemIds?.forEach((exchangeId) => {
        const exchangeItem = dummyProducts.find(
          (product) => product.id === exchangeId,
        );
        if (exchangeItem) {
          // Assume all items have the same currency code
          currencyCode = exchangeItem.price.currencyCode;
          totalAmount += exchangeItem.price.amount;
        }
      });
    });

    return { currencyCode, amount: totalAmount };
  };
  const calculateTotalRefundAmount = () => {
    if (
      !selectedOrder ||
      !selectedOrder.addedItems ||
      !selectedOrder.itemsInOrder
    ) {
      return { currencyCode: "", amount: 0 };
    }

    let totalAmount = 0;
    let currencyCode = "";

    // Iterate over each added item
    for (const addedItemId in selectedOrder.addedItems) {
      const addedItem = selectedOrder.addedItems[addedItemId];

      // Find the corresponding item in the order
      const orderItem = selectedOrder.itemsInOrder.find(
        (item) => item.id === addedItem.itemId,
      );

      if (orderItem) {
        // Assume all items have the same currency code
        currencyCode = orderItem.unitPrice.currencyCode;
        // Calculate the amount for this added item
        const itemTotal = orderItem.unitPrice.amount * addedItem.quantity;
        totalAmount += itemTotal;
      }
    }

    return { currencyCode, amount: totalAmount };
  };

  const returnableItemsAmount = calculateTotalForAddedItems();
  const totalExchangeAmount = calculateTotalExchangeAmount();
  const totalRefundAmount = calculateTotalRefundAmount();
  const finalAmount =
    totalExchangeAmount.amount -
    totalRefundAmount.amount +
    (restockingFee?.priceType === "percentageOfOrderValue"
      ? (parseInt(restockingFee.price ?? "0") / 100) *
        returnableItemsAmount.amount // calculate percentage
      : parseInt(restockingFee.price ?? "0")); // fallback to normal price if not percentage
  const handleNext = () => {
    if (selectedOrder)
      updateState({
        ...contextValues,
        selectedOrder: {
          ...selectedOrder,
          activeStep: "orderSummary",
        },
      });
  };
  const handleBack = () => {
    if (selectedOrder)
      updateState({
        ...contextValues,
        selectedOrder: {
          ...selectedOrder,
          activeStep: "returnMethod",
        },
      });
  };
  const totalExchangeItems = items.reduce((acc, item) => {
    return acc + (item.exchangeItemIds?.length || 0);
  }, 0);

  return (
    <div>
      <div className={`${styles.widgetBody}`}>
        <div>
          <TimeLine activeStep={selectedOrder?.activeStep ?? "selectOrder"} />
          <div className="mt-3">
            <div className="d-flex align-items-center mb-3 pb-1">
              <img
                src={returnImg}
                alt=""
              />
              <span className={`ps-1 ${styles.heading}`}>
                Select item to exchange
              </span>
            </div>
            <div className={`${styles.orderBox}`}>
              <div className="pb-2 d-flex align-items-center ">
                <div>
                  <img
                    src={bag}
                    className={`${styles.bag}`}
                    alt=""
                  />
                </div>
                <div className="ps-1">
                  <span className={`${styles.itemSpan}`}>
                    Item(s) you will return ({items.length}) :
                  </span>
                </div>
              </div>
              {items?.map((item) => {
                const itemData = selectedOrder?.itemsInOrder?.find(
                  (value) => value.id + "" === item.itemId + "",
                );
                return (
                  <div className={`mb-2`}>
                    <span className={`${styles.radioLabel}`}></span>
                    <div className={`d-flex mb-3`}>
                      <div className={`${styles.cart}`}>
                        <img
                          src={
                            itemData?.name == "Acme T-Shirt"
                              ? orderImg
                              : orderImg2
                          }
                          alt="order"
                          className={`${styles.orderImg}`}
                        />
                      </div>
                      <div className={`ms-2`}>
                        <span className={`${styles.orderDesc}`}>
                          {itemData?.unitPrice?.currencyCode}
                          {itemData?.unitPrice?.amount} x {itemData?.quantity}
                        </span>
                        <span className={`${styles.orderDesc}`}>
                          SKU : {itemData?.sku}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className={`${styles.orderBox} mt-3`}>
              <div className="pb-2 d-flex align-items-center ">
                <div>
                  <img
                    src={bag}
                    className={`${styles.bag}`}
                    alt=""
                  />
                </div>
                <div className="ps-1">
                  <span className={`${styles.itemSpan}`}>
                    Item(s) you will receive ({totalExchangeItems}) :
                  </span>
                </div>
              </div>
              {items.flatMap(
                (item) =>
                  item.exchangeItemIds?.map((exchangeId) => {
                    const exchangeItem = dummyProducts.find(
                      (product) => product.id === exchangeId,
                    );
                    return (
                      <div
                        key={exchangeId}
                        className="mb-2"
                      >
                        <div className={`mt-2 ${styles.labelBox}`}>
                          <div className="d-flex">
                            <div className={styles.cart}>
                              <img
                                src={orderImg}
                                alt="order"
                                width={37}
                                height={37}
                              />
                            </div>
                            <div className="ms-2 w-100">
                              <div className="d-flex justify-content-between align-items-lg-end">
                                <div>
                                  <span className={styles.labelName}>
                                    {exchangeItem?.name}
                                  </span>
                                  <span className={styles.labelDesc}>
                                    SKU: {exchangeItem?.sku}
                                  </span>
                                  <span className={styles.labelValue}>
                                    {exchangeItem?.price?.currencyCode}
                                    {exchangeItem?.price?.amount}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }) || [],
              )}
            </div>
            <div className={`${styles.orderBox} mt-3`}>
              <div className={`${styles.separation}`}></div>
              <div className={`pt-3 ${styles.orderSummary}`}>
                <div
                  className={`mb-2 ${styles.amount} d-flex justify-content-between`}
                >
                  <span>Total Amount</span>
                  <span>${finalAmount}</span>
                </div>
                <div
                  className={`mb-2 ${styles.billText} d-flex justify-content-between`}
                >
                  <span>Amount to be paid</span>
                  <span>
                    {totalExchangeAmount.currencyCode}
                    {totalExchangeAmount.amount}
                  </span>
                </div>
                <div
                  className={`mb-2 ${styles.billText} d-flex justify-content-between`}
                >
                  <span>To be refunded</span>
                  <span>
                    {returnableItemsAmount?.currencyCode}
                    {returnableItemsAmount?.amount}
                  </span>
                </div>
              </div>
              <div
                className={`${styles.seeHow} cursor-pointer d-flex`}
                onClick={() => setShowModal(true)}
              >
                <span
                  className={`${styles.caution} d-flex align-items-center justify-content-between`}
                >
                  <i className="fa-solid fa-circle-info"></i>
                </span>
                <div className={`ms-1 ${styles.seeHow}`}>
                  see how it is calculated
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ButtonWrapper
        onBackClick={handleBack}
        onNextClick={handleNext}
      />
      <WidgetFooter />
      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
        contentClassName={`${styles.modalContent}`}
        centered={true}
        enforceFocus={false}
        backdropClassName={`${styles.backDrop}`}
        style={{
          zIndex: 19999,
        }}
      >
        <OrderSummaryModal
          returnableItemsAmount={returnableItemsAmount}
          totalExchangeAmount={totalExchangeAmount}
          restockingFee={restockingFee}
          finalAmount={finalAmount}
          currency={currency}
        />
      </Modal>
    </div>
  );
};

export default OrderSummary;

const OrderSummaryModal = ({
  returnableItemsAmount,
  totalExchangeAmount,
  finalAmount,
  restockingFee,
  currency,
}: {
  returnableItemsAmount: IAmount;
  totalExchangeAmount: IAmount;
  finalAmount: number;
  currency: string;
  restockingFee: IRestockingFee;
}) => {
  return (
    <div>
      <div className="d-flex">
        <span
          className={`${styles.caution} d-flex align-items-center justify-content-between`}
        >
          <i className="fa-solid fa-circle-info"></i>
        </span>
        <div className={`ms-1 ${styles.seeHow}`}>see how it is calculated</div>
      </div>
      <div className={`pt-3 ${styles.orderSummary}`}>
        <div
          className={`mb-2 ${styles.billText} d-flex justify-content-between`}
        >
          <span>Total Paid On Original Order</span>
          <span>
            {returnableItemsAmount?.currencyCode}
            {returnableItemsAmount?.amount}
          </span>
        </div>
        <div
          className={`mb-2 ${styles.billText} d-flex justify-content-between`}
        >
          <span>Total Value Removed From Order</span>
          <span>
            {returnableItemsAmount?.currencyCode ?? "$"}
            {returnableItemsAmount?.amount ?? "0"}
          </span>
        </div>
        <div
          className={`mb-2 ${styles.billText} d-flex justify-content-between`}
        >
          <span>Total Value Added To Order</span>
          <span>
            {totalExchangeAmount?.currencyCode ?? "$"}
            {totalExchangeAmount?.amount ?? "0"}
          </span>
        </div>
        <div
          className={`mb-2 ${styles.billText} d-flex justify-content-between`}
        >
          <span>Restocking Fee</span>
          <span>
            {restockingFee?.priceType === "fixedAmount" &&
              getCurrencySymbol(currency)}
            {restockingFee?.price ?? 0}
            {restockingFee?.priceType === "percentageOfOrderValue" && `%`}
          </span>
        </div>
        <div className={`${styles.separation} my-2`}></div>
        <div className={`mb-2 ${styles.amount} d-flex justify-content-between`}>
          <span>Total Amount</span>
          <span> ${finalAmount}</span>
        </div>
      </div>
    </div>
  );
};
