/**
 * This file is the service file used for making api request.
 * It contains the getNotifySettingService function service which is explained below.
 *
 * @author Yash Aditya
 */

// Import statements for types
import { axiosJSON } from "src/globals/axiosEndPoints";
import { NotificationFrequencyData } from "./addOrUpdateSetting.service";

// Function to add or update notification settings
export const getNotifySettingService = async () => {
  const { data: res } = await axiosJSON.post(
    "/api/reports/notificationSettings/getSetting"
  );

  if (res.err) {
    throw res.msg;
  }

  return res.data as NotificationFrequencyData;
};
