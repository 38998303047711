import styles from "./AddConditionButton.module.scss";

function AddConditionButton(props: {
  isAddBtnEnabled: boolean;
  AddCondition: Function;
  id: string;
  btnText?: string;
}) {
  return (
    <div
      className="d-flex align-items-center mt-3 ms-md-2"
      onClick={() => {
        if (props.isAddBtnEnabled) {
          props.AddCondition(props.id);
        }
      }}
    >
      {/* Jul 24 Adding codium pr suggestions */}
      <div
        className={`d-flex align-items-center justify-content-center ${
          props.isAddBtnEnabled
            ? `cursor-pointer ${styles.addMore}`
            : styles.disableBtn
        }`}
      >
        +
      </div>

      <div
        className={`${styles.blueText} ms-2 mb-0`}
        role="button"
      >
        {props.btnText && props.btnText.trim().length > 0
          ? props.btnText
          : "Add condition"}
      </div>
    </div>
  );
}

export default AddConditionButton;
