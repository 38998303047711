import { MONTH_LIST } from "src/globals/constants";
import { showIth } from "./utils";

export function generateCurrentPSTforDB() {
  //it will return PST
  let date: string | Date = new Date();
  date = new Date(date).toLocaleString("en-US", {
    timeZone: "America/Los_Angeles",
  });
  date = new Date(date);
  let hh: any = date.getHours(),
    min: any = date.getMinutes(),
    dd: any = date.getDate(),
    mm: any = date.getMonth() + 1,
    yy: any = date.getFullYear(),
    ss: any = date.getSeconds();

  if (hh < 10) {
    hh = "0" + hh;
  } /* if(hh < 10) */
  if (min < 10) {
    min = "0" + min;
  } /* if(min < 10) */
  if (ss < 10) {
    ss = "0" + ss;
  } /* if(ss < 10) */
  if (dd < 10) {
    dd = "0" + dd;
  } /* if(dd < 10) */
  if (mm < 10) {
    mm = "0" + mm;
  } /* if(mm < 10) */
  hh += "";
  min += "";
  dd += "";
  mm += "";
  yy += "";
  ss += "";

  return `${yy}-${mm}-${dd} ${hh}:${min}:${ss}`;
}
export const generatePST = (date: any) => {
  //it will return PST
  if (date == "") {
    return "";
  }
  if (typeof date === "string") {
    date += " ";
  }
  date = new Date(date).toLocaleString("en-US", {
    timeZone: "America/Los_Angeles",
  });
  date = new Date(date);
  let hh = date.getHours(),
    min = date.getMinutes(),
    dd = date.getDate(),
    mm = date.getMonth() + 1,
    yy = date.getFullYear(),
    ss = date.getSeconds();

  if (hh < 10) hh = "0" + hh;
  if (min < 10) min = "0" + min;
  if (ss < 10) ss = "0" + ss;
  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;
  hh += "";
  min += "";
  dd += "";
  mm += "";
  yy += "";
  ss += "";
  return `${yy}-${mm}-${dd} ${hh}:${min}:${ss}`;
};

export const generateLocal = (date = new Date()) => {
  date = new Date(date);
  let hh: number | string = date.getHours(),
    min: number | string = date.getMinutes(),
    dd: number | string = date.getDate(),
    mm: number | string = date.getMonth() + 1,
    yy: number | string = date.getFullYear(),
    ss: number | string = date.getSeconds();
  if (hh < 10) hh = "0" + hh;
  if (min < 10) min = "0" + min;
  if (ss < 10) ss = "0" + ss;
  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;
  return `${yy}-${mm}-${dd}T${hh}:${min}:${ss}`;
};

export const generate24TimeOnly = (date: any) => {
  if (date == "") {
    return "";
  }
  if (typeof date === "string") {
    date += " ";
  }
  date = new Date(date);
  let hh = date.getHours(),
    min = date.getMinutes(),
    ss = date.getSeconds();

  if (hh < 10) hh = "0" + hh;
  if (min < 10) min = "0" + min;
  if (ss < 10) ss = "0" + ss;
  return `${hh}:${min}:${ss}`;
};

export const generateDateOnly = (date: any, format?: string) => {
  if (date == "") {
    return "";
  }
  if (typeof date === "string") {
    date += " ";
  }
  date = new Date(date);
  let dd = date.getDate(),
    mm = date.getMonth() + 1,
    yy = date.getFullYear();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;
  if (format === "d-m-y") {
    return `${dd}-${mm}-${yy}`;
  } else if (format === "m-d-y") {
    return `${mm}-${dd}-${yy}`;
  } else {
    return `${yy}-${mm}-${dd}`;
  }
};

export const generateTimeOnly = (date: any, ampm?: any, showSec?: any) => {
  if (date == "") {
    return "";
  }
  if (typeof date === "string") {
    date += " ";
  }
  date = new Date(date);
  let hh = date.getHours(),
    min = date.getMinutes(),
    ss = date.getSeconds(),
    ampmText = "";
  if (ampm) {
    if (hh < 12) {
      ampmText = " AM";
    } else {
      ampmText = " PM";
      if (hh > 12) {
        hh = hh - 12;
      }
    }
  }
  if (hh < 10) hh = "0" + hh;
  if (min < 10) min = "0" + min;
  if (ss < 10) ss = "0" + ss;
  if (showSec) {
    return `${hh}:${min}:${ss}${ampmText}`;
  } else {
    return `${hh}:${min}${ampmText}`;
  }
};

export const generateStartDate = (date = new Date(), days = 0) => {
  date.setDate(date.getDate() - days);
  let hh: any = date.getHours(),
    min: any = date.getMinutes(),
    dd: any = date.getDate(),
    mm: any = date.getMonth() + 1,
    yy: any = date.getFullYear(),
    ss: any = date.getSeconds();

  if (hh < 10) hh = "0" + hh;
  if (min < 10) min = "0" + min;
  if (ss < 10) ss = "0" + ss;
  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;
  hh += "";
  min += "";
  dd += "";
  mm += "";
  yy += "";
  ss += "";
  return `${yy}-${mm}-${dd}T00:00:00`;
};
export const generateEndDate = (date = new Date()) => {
  let hh: any = date.getHours(),
    min: any = date.getMinutes(),
    dd: any = date.getDate(),
    mm: any = date.getMonth() + 1,
    yy: any = date.getFullYear(),
    ss: any = date.getSeconds();

  if (hh < 10) hh = "0" + hh;
  if (min < 10) min = "0" + min;
  if (ss < 10) ss = "0" + ss;
  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;
  hh += "";
  min += "";
  dd += "";
  mm += "";
  yy += "";
  ss += "";
  return `${yy}-${mm}-${dd}T23:59:59`;
};

export function generateFromDate(noOfDays: number, date: any) {
  date = new Date(date) || new Date();
  date.setDate(date.getDate() - noOfDays);
  let hh = date.getHours(),
    min = date.getMinutes(),
    dd = date.getDate(),
    mm = date.getMonth() + 1,
    yy = date.getFullYear(),
    ss = date.getSeconds();

  if (hh < 10) hh = "0" + hh;
  if (min < 10) min = "0" + min;
  if (ss < 10) ss = "0" + ss;
  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;
  hh += "";
  min += "";
  dd += "";
  mm += "";
  yy += "";
  ss += "";
  return `${yy}-${mm}-${dd} ${hh}:${min}:${ss}`;
}
export const dateDifference = (date1: any, date2: any) => {
  if (typeof date1 === "string") {
    date1 += " ";
  }
  if (typeof date2 === "string") {
    date2 += " ";
  }
  date1 = new Date(date1);
  date2 = new Date(date2);
  if (date1.getTime() < date2.getTime()) {
    let t = date1;
    date1 = date2;
    date2 = t;
  }
  let r = parseInt(
    (date1.getTime() - date2.getTime()) / (1000 * 60 * 60 * 24) + "",
  );
  if (isNaN(r)) {
    return 0;
  } else {
    return r;
  }
};

export function getDateTimeWithTimeZoneOffset(
  timezone?: string,
  date = new Date(),
) {
  let ret = generateLocal(date);
  if (timezone === undefined) {
    return ret + "Z";
  }
  date = new Date(date);
  let dateDestination = new Date(
    new Date(date).toLocaleString("en-US", { timeZone: timezone }),
  );
  let dateUTC = new Date(new Date(date).toISOString().slice(0, 23));

  let offset = dateDestination.getTime() - dateUTC.getTime();
  if (offset < 0) {
    ret += "-";
    offset = offset + -offset * 2;
  } else {
    ret += "+";
  }
  let offsetMinutes = Math.round(Math.round(offset / 1000) / 60);
  let hour: number | string = parseInt(offsetMinutes / 60 + "");
  if (!isFinite(hour)) {
    hour = "00";
  } else {
    if (hour < 10) hour = "0" + hour;
  }
  let minutes: number | string = offsetMinutes % 60;
  if (!isFinite(minutes)) {
    minutes = "00";
  } else {
    if (minutes < 10) minutes = "0" + minutes;
  }
  return `${ret}${hour}${minutes}`;
}

export function getTimeFromNow({
  date,
  need,
  showJustNow = true,
  timeZone,
  showAgo = true,
  secondString = {
    secondText: "sec",
    secondsText: "sec",
  },
  minuteString = {
    minuteText: "m",
    minutesText: "m",
  },
  hourString = {
    hourText: "h",
    hoursText: "h",
  },
}: {
  date: Date;
  need?: "both" | "date" | "time";
  showJustNow?: boolean;
  timeZone?: string;
  showAgo?: boolean;
  secondString?: {
    secondText: string;
    secondsText: string;
  };
  minuteString?: {
    minuteText: string;
    minutesText: string;
  };
  hourString?: {
    hourText: string;
    hoursText: string;
  };
}) {
  //Assuming date as per timezone
  const ago = showAgo ? " ago" : "";
  date = new Date(date);
  const current = new Date(
    timeZone
      ? new Date().toLocaleString("en-US", { timeZone })
      : new Date().toISOString().slice(0, 23),
  );
  const diff = Math.abs(
    Math.floor((current.getTime() - date.getTime()) / 1000),
  );

  if (diff < 60) {
    if (showJustNow) {
      return "Just Now";
    } else {
      return (
        diff +
        `${diff <= 1 ? secondString.secondText : secondString.secondsText}` +
        `${ago}`
      );
    }
  } else if (diff / 60 / 60 < 1) {
    return (
      Math.floor(diff / 60) +
      `${
        minuteString.minuteText.length > 2 ||
        minuteString.minutesText.length > 2
          ? " "
          : ""
      }${
        Math.floor(diff / 60) <= 1
          ? minuteString.minuteText
          : minuteString.minutesText
      }` +
      ` ${ago}`
    );
  } else if (diff / 60 / 60 < 24) {
    return (
      Math.floor(diff / 60 / 60) +
      `${
        hourString.hourText.length > 2 || hourString.hoursText.length > 2
          ? " "
          : ""
      }${
        Math.floor(diff / 60 / 60) <= 1
          ? hourString.hourText
          : hourString.hoursText
      } ` +
      (Math.floor(diff / 60) % 60) +
      `${
        minuteString.minuteText.length > 2 ||
        minuteString.minutesText.length > 2
          ? " "
          : ""
      }${
        Math.floor(diff / 60) % 60 <= 1
          ? minuteString.minuteText
          : minuteString.minutesText
      }` +
      ` ${ago}`
    );
  } else if (diff / 60 / 60 / 24 < 7) {
    const days = Math.floor(diff / 60 / 60 / 24);
    const hours = Math.floor((diff % (60 * 60 * 24)) / 3600);
    return `${days}d ${hours}h${ago}`;
  } else {
    if (need === "both") {
      return date.toLocaleString();
    } else if (need === "time") {
      return date.toLocaleTimeString();
    } else {
      return date.toLocaleDateString();
    }
  }
}

/**
 * Method to get date in form 28th August 2018, 08:28 AM
 *
 */
export function getMonthNameDateAmPmTime(
  dateTimeStamp: any,
  monthNameInShort: boolean = false,
) {
  let dateNTime = dateTimeStamp.split(" ");
  let seperatedDate = dateNTime[0].split("-");
  let seperatedTime = dateNTime[1].split(":");
  // make the date string
  let month =
    monthNameInShort === true
      ? getMonthNameInShort(seperatedDate[1])
      : getMonthName(seperatedDate[1]);
  let date =
    seperatedDate[2] +
    getDateNthSuffix(parseInt(seperatedDate[2])) +
    " " +
    month +
    " " +
    seperatedDate[0];

  let h = parseInt(seperatedTime[0]);
  let m = parseInt(seperatedTime[1]);
  let s = parseInt(seperatedTime[2]);
  let time = "";
  let hs,
    ms,
    amOrPm = "";
  if (h < 12) {
    amOrPm = " AM";
  } else {
    amOrPm = " PM";
    if (h > 12) h = h - 12;
  }
  if (h < 10) hs = "0" + h;
  else hs = h;
  if (m < 10) ms = "0" + m;
  else ms = m;
  time = hs + ":" + ms + amOrPm;
  return date + ", " + time;
}

export function getMonthName(month: any) {
  let months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return months[parseInt(month) - 1];
}

export function getMonthNameInShort(month: any) {
  let months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  return months[parseInt(month) - 1];
}

export function getDateNthSuffix(date: any) {
  if (date > 3 && date < 21) return "th";
  switch (date % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}

export function getDateFromDate(noOfDays: number, date: Date = new Date()) {
  date = new Date(date);
  return new Date(date.setDate(date.getDate() + noOfDays));
}

export function getHoursFromDate(noOfHours: number, date: Date = new Date()) {
  date = new Date(date);
  return new Date(date.setHours(date.getHours() + noOfHours));
}

// function to get current timezone name of client/browser
export function getClientSystemTimeZoneName() {
  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return timezone;
}

// function that checks a date time passed is in past
export function checkExpireTimeIsInPastAndDiff(
  expireDateTime: string,
  timeZone: string | undefined,
) {
  const current = new Date(new Date().toLocaleString("en-US", { timeZone }));
  const expireAtDate = new Date(
    new Date(expireDateTime).toLocaleString("en-US", { timeZone }),
  );
  let diff = Math.round((expireAtDate.getTime() - current.getTime()) / 1000);
  if (diff < 0) {
    // already expired, or expire time is in past
    return `0 sec`;
  }
  if (diff < 60) {
    return diff + `sec`;
  } else if (diff / 60 / 60 < 1) {
    return Math.round(diff / 60) + `m`;
  } else if (Math.round(diff / 60 / 60) < 24) {
    return (
      Math.round(diff / 60 / 60) +
      "h " +
      Math.round(Math.round(diff / 60) % 60) +
      `m`
    );
  } else {
    return (
      Math.round(diff / 60 / 60 / 24) +
      "d " +
      Math.round(Math.round(diff / 60 / 60) % 24) +
      `h`
    );
  }
}

export const convertGmtToUserTimezone = (
  datatimeGmt: string,
  userTimezone?: string,
  dateOnly = false,
) => {
  const date = new Date(datatimeGmt + "Z");
  if (dateOnly) {
    const convertedDateTime = date.toLocaleDateString("en-US", {
      timeZone: userTimezone,
    });

    return convertedDateTime;
  } else {
    const convertedDateTime = date.toLocaleString("en-US", {
      timeZone: userTimezone,
    });

    return convertedDateTime;
  }
};

export const getTodayRelativeTime = ({
  time,
  timeZone,
}: {
  time: Date;
  timeZone?: string;
}) => {
  if (timeZone) {
    return generateTimeOnly(time.toLocaleString("en-US", { timeZone }), true);
  } else {
    return generateTimeOnly(time, true);
  }
};

export const getDayFromTime = ({
  time,
  timeZone,
}: {
  time: Date;
  timeZone?: string;
}) => {
  let currentTime = new Date();
  if (timeZone) {
    time = new Date(time.toLocaleString("en-US", { timeZone }));
    currentTime = new Date(currentTime.toLocaleString("en-US", { timeZone }));
  }
  if (
    currentTime.getDate() === time.getDate() &&
    currentTime.getMonth() === time.getMonth() &&
    currentTime.getFullYear() === time.getFullYear()
  ) {
    return "Today";
  } else if (
    (currentTime.getDate() - time.getDate() === 1 ||
      currentTime.getDate() - time.getDate() === -30 ||
      currentTime.getDate() - time.getDate() === -29 ||
      currentTime.getDate() - time.getDate() === -28 ||
      currentTime.getDate() - time.getDate() === -27) &&
    currentTime.getMonth() === time.getMonth() &&
    currentTime.getFullYear() === time.getFullYear()
  ) {
    return "Yesterday";
  } else {
    return `${time.getDate()}${showIth(time.getDate())} ${
      MONTH_LIST[time.getMonth()]
    }${
      time.getFullYear() !== currentTime.getFullYear()
        ? `, ${time.getFullYear()}`
        : ""
    }`;
  }
};

export const isDaySame = (time1: Date | null, time2: Date | null) => {
  // console.log(time1,time2);
  if (time1 === null || time2 === null) {
    return false;
  }
  return (
    time1.getDate() === time2.getDate() &&
    time1.getMonth() === time2.getMonth() &&
    time1.getFullYear() === time2.getFullYear()
  );
};

export const formatDateTimeString = (datetime: string) => {
  let date: any = new Date(datetime);
  let hh = date.getHours(),
    min = date.getMinutes(),
    dd = date.getDate(),
    mm = date.getMonth() + 1,
    yy = date.getFullYear(),
    ss = date.getSeconds();

  if (hh < 10) hh = "0" + hh;
  if (min < 10) min = "0" + min;
  if (ss < 10) ss = "0" + ss;
  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;
  hh += "";
  min += "";
  dd += "";
  mm += "";
  yy += "";
  ss += "";
  return `${yy}-${mm}-${dd} ${hh}:${min}:${ss}`;
};

/**
 * Method to get date in form Mon, 08:28 AM
 *
 */
export function getDayAndTime(datetime = new Date()) {
  var day = getDayName(datetime.getDay() + 1, true);
  return (
    day +
    ", " +
    datetime.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    })
  );
}

export function getDayName(day: any, short = false) {
  let days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  let daysInShort = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  if (short === true) {
    return daysInShort[parseInt(day) - 1];
  } else {
    return days[parseInt(day) - 1];
  }
}

export function isDateStringValid(datetime: string) {
  const date: any = new Date(datetime);

  if (date !== "Invalid Date" && !isNaN(date)) {
    return true;
  } else {
    return false;
  }
}

function getTimezoneOffset(atTime: Date, timeZone: string | undefined) {
  const localizedTime = new Date(atTime.toLocaleString("en-US", { timeZone }));
  const utcTime = new Date(atTime.toLocaleString("en-US", { timeZone: "UTC" }));
  return Math.round(
    (localizedTime.getTime() - utcTime.getTime()) / (60 * 1000),
  );
}

export function convertUserTimeZoneToAnother(
  datetimeString: string,
  fromTimeZone: string | undefined,
  toTimeZone: string,
) {
  const date = new Date(datetimeString + "Z");

  // Calculate the total offset difference
  let offsetDiff =
    getTimezoneOffset(date, toTimeZone) - getTimezoneOffset(date, fromTimeZone);

  // Adjust the date by the offset difference
  const targetTime = date.getTime() + offsetDiff * 60 * 1000;
  const convertedDate = new Date(targetTime);

  // Return the converted date in ISO string format
  return convertedDate.toISOString();
}
export function formatUTCDate(date: Date) {
  const year = String(date.getUTCFullYear());
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const day = String(date.getUTCDate()).padStart(2, "0");
  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");
  const seconds = String(date.getUTCSeconds()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  return formattedDate;
}

export function isMySQLDateStringValid(
  datetime: string,
  format: "date" | "dateTime" | "dateOrDateTime" = "dateTime",
): boolean {
  // Regular expression for MySQL datetime format with time
  const mysqlDateTimeRegex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;

  // Regular expression for MySQL date format without time
  const mysqlDateRegex = /^\d{4}-\d{2}-\d{2}$/;

  // Validate according to the format parameter
  switch (format) {
    case "date":
      return mysqlDateRegex.test(datetime);
    case "dateTime":
      return mysqlDateTimeRegex.test(datetime);
    case "dateOrDateTime":
      return mysqlDateRegex.test(datetime) || mysqlDateTimeRegex.test(datetime);
    default:
      return false;
  }
}

/**
 * Returns a formatted string in form "1/12/2024 ,1:30 am"
 *
 * @param dateGmt Date in Gmt format
 * @param userTimezone Current timezone
 * @returns The formatted string or undefined in case invalid date
 */
export function formatMDYTimeStringDate(
  dateGmt: string,
  userTimezone?: string,
) {
  // Convert the string to a Date object and append "Z" to indicate UTC
  const date = new Date(dateGmt + "Z");

  // Check if the Date object is valid
  if (isNaN(date.getTime())) {
    console.error("Invalid date provided:", dateGmt);
    return;
  }

  // Extract the date parts
  const day = date.getUTCDate();
  const month = date.getUTCMonth() + 1; // getUTCMonth is zero-based
  const year = date.getUTCFullYear();

  const timeFormatter = new Intl.DateTimeFormat("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    timeZone: userTimezone,
  });

  const timeParts = timeFormatter.formatToParts(date);

  // Construct the time string (format like "1:30 AM")
  const timeString = `${timeParts.find((p) => p.type === "hour")
    ?.value}:${timeParts.find((p) => p.type === "minute")?.value} ${timeParts
    .find((p) => p.type === "dayPeriod")
    ?.value.toLowerCase()}`;

  // Construct the final formatted string
  return `${month}/${day}/${year}, ${timeString}`;
}
