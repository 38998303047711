// Import necessary modules and assets
import { useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { useAppSelector } from "src/store/store";

import deleteIcon from "src/assets/images/deleteInt.png";
import doneIcon from "src/assets/images/doneProcess.png";
// import successImg from "src/assets/images/successMsg.png";

import DeleteIntegration from "../DeleteIntegration/DeleteIntegration";
import styles from "./EmailProcess.module.scss";

interface StepProps {
  stepNumber: number;
  title: string;
  done: boolean;
  active: boolean;
}

/**
 * Step component for rendering email integration step
 */
const Step = ({ stepNumber, title, done, active }: StepProps) => (
  <div className={`d-flex align-items-center`}>
    {/* Step Details */}
    <div className={`d-flex align-items-center`}>
      {/* Step Status */}
      {done ? (
        <img
          src={active ? doneIcon : doneIcon}
          alt=""
          className={`${styles.doneImg}`}
        />
      ) : (
        <div>
          {/* Step Number */}
          <span
            className={`d-flex justify-content-center align-items-center ${
              styles.step2
            } ${active ? styles.active : ""}`}
          >
            {stepNumber}
          </span>
        </div>
      )}
      {/* Step Title */}
      <span
        className={`ps-2 ${styles.stepTitle} ${
          active ? styles.activeTitle : ""
        }`}
      >
        {title}
      </span>
    </div>
    {/* Dash between steps */}
    {stepNumber !== 4 && (
      <div
        className={`mx-3 ${styles.borderSpace} ${done && styles.activeBorder}`}
      ></div>
    )}
  </div>
);

interface Props {
  step: number;
  delete: boolean;
}

/**
 * Email Process Component to render email integration steps
 */
const EmailProcess = (props: Props) => {
  const [deleteIntegration, setDeleteIntegration] = useState(false);

  // TypeName is Others for all email integrations
  // Leaving this state for future use cases

  const { isEmailVerified, isDomainVerified, isEmailAdded } = useAppSelector(
    (state) => state.emailIntegration.selectedUser
  );

  // Delete Integration modal
  const onHide = () => setDeleteIntegration(false);
  const onShow = () => setDeleteIntegration(true);

  /**
   * Memoized Step data
   */
  const steps = useMemo(
    () => [
      { number: 1, title: "Add E-mail", done: isEmailAdded },
      { number: 2, title: "Incoming mail settings", done: isEmailVerified },
      { number: 3, title: "Outgoing mail settings", done: isDomainVerified },
      {
        number: 4,
        title:
          isEmailVerified && isDomainVerified
            ? "Authentication complete !"
            : "Authentication",
        done: isEmailVerified && isDomainVerified,
      },
    ],
    [isDomainVerified, isEmailAdded, isEmailVerified]
  );

  return (
    <>
      {/* Header */}
      <div
        className={`d-flex justify-content-between align-items-center py-2 py-md-3 px-2 px-md-3 ms-lg-2 me-0 me-lg-4 mt-2 ${styles.processMain}`}
      >
        {/* Steps */}
        <div className="d-flex align-items-center">
          {steps.map((step, index) => (
            <Step
              key={index}
              stepNumber={step.number}
              title={step.title}
              done={step.done}
              active={props.step === step.number}
            />
          ))}
        </div>

        {/* Delete Button */}
        {props.delete && (
          <div className={`ms-2`}>
            <img
              src={deleteIcon}
              alt=""
              className={`cursor-pointer ${styles.delIcon}`}
              onClick={onShow}
            />
          </div>
        )}
      </div>

      {/* Delete Modal */}
      <Modal
        backdropClassName={`${styles.modalBack}`}
        show={deleteIntegration}
        onHide={onHide}
        dialogClassName={`${styles.modalDialog}`}
        contentClassName={`${styles.modalContent}`}
        centered={true}
        enforceFocus={false}
      >
        <DeleteIntegration onHide={onHide} />
      </Modal>
    </>
  );
};

export default EmailProcess;
