/**
 * This file is the service file.
 *
 * @author Jayalakshmi
 * @author Yash Aditya
 */

import { axiosJSON } from "src/globals/axiosEndPoints";

// Define the structure of a User
interface User {
  id: string;
  email: string;
  imageUrl?: string | null | undefined;
  name?: string | null | undefined;
  role: "leadAgent" | "admin",
  disabled: boolean;
}

// Define the structure of a Team
interface Team {
  id: string;
  name: string;
  imageUrl?: string;
  userIds: Array<string>;
}

// Define the structure of the response from the server when fetching users and teams
interface GetUsersAndTeamsResponse {
  agentIdsForAppliedBrands: Array<string>;
  users: { [userId: string]: User };
  userIds: Array<string>;
  teams: { [teamId: string]: Team };
  teamIds: Array<string>;
}

// Async function to fetch users and teams from the server
export const getUsersAndTeams = async () => {
  // Send a GET request to the specified API endpoint
  const { data: res } = await axiosJSON.post(
    `/api/reports/notificationSettings/getUsersAndTeams`
  );

  // Check if there's an error in the response
  if (res.err || res.error) {
    // If there is an error, throw an exception with the error message
    throw res.msg;
  }

  // Initialize the response structure
  const ret: GetUsersAndTeamsResponse = {
    agentIdsForAppliedBrands: res.data.agentIdsForAppliedBrands,
    users: {},
    userIds: [],
    teams: {},
    teamIds: [],
  };

  // Process the list of users from the response
  res.data.users.forEach((user: User) => {
    // Convert user id to string and add it to the response structure
    user.id = user.id + "";
    ret.users[user.id] = user;
    ret.userIds.push(user.id);
  });

  // Process the list of teams from the response
  res.data.teams.forEach((team: Team) => {
    // Convert team id to string and add it to the response structure
    team.id = team.id + "";
    ret.teams[team.id] = team;
    ret.teamIds.push(team.id);
  });

  // Return the processed response
  return ret;
};

export default getUsersAndTeams;
