import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";

export interface operators {
  name: string;
  key: string;
}

export interface conditionType {
  conditionKey: number|string;
  conditionName: string;
  inputType: string;
  operators: operators[];
}

export interface ConditionOptions {
  data: conditionType[];
}


const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/botProfile/getConditionOptions`
  : "/api/botProfile/options/audienceConfig";

export async function getConditionOptions() {
  const { data: res } = await axiosJSON.get(apiEndPoint);

  if (res.err) {
    throw res.msg;
  }

  return res.data as conditionType[];
}
