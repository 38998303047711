/**
 * This file contains the custom hook for handling KB settings.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { useMutation, useQueries } from "@tanstack/react-query";
import { SetStateAction, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import useDebounce from "src/hooks/useDebounce";
import {
  createArticleFeedback,
  createArticleFeedbackResponse,
} from "src/services/KnowledgeBase/Settings/Preview/createArticleFeedback.service";
import { getArticleById } from "src/services/KnowledgeBase/Settings/Preview/getArticle.service";
import { getRatingsAndComments } from "src/services/KnowledgeBase/Settings/Preview/getRatingsAndComments.service";
import { getThemeDetails } from "src/services/KnowledgeBase/Settings/Preview/getThemeDetails.service";

type Props = {
  integrationIdentifier: string;
};

/**
 * This hook is used to maintain the data and states of ArticleInfo component.
 */
function useArticleInfo({ integrationIdentifier }: Props) {
  const [detailDesc, setDetailDesc] = useState(false); // for detail description
  const { id, helpCenterPageUri } = useParams(); // for getting article id from url
  const [articleData, ratingData] = useQueries({
    // for getting article data and rating data
    queries: [
      {
        queryKey: ["getArticle"],
        queryFn: () =>
          getArticleById({
            articleId: id ? id : "",
            integrationIdentifier: integrationIdentifier,
          }),
        onError: (data: any) => {
          // if (data?.response.data.tokenExpire) {
          //   setToken("");
          //   navigate(`/helpcenterpage/${helpCenterPageUri}`,{
          //     state: { tokenExpired: true },
          //   });
          //   return;
          // }
          // if (isProtected) {
          //   navigate(`/helpcenterpage/${helpCenterPageUri}`);
          // }
        },
      },
      {
        // for getting rating data
        queryKey: ["getRatingAndComment"],
        queryFn: () =>
          getRatingsAndComments({
            integrationIdentifier: integrationIdentifier,
            articleId: id ? id : "",
          }),
        onError: (data: any) => {
          // if (data?.response.data.tokenExpire) {
          //   setToken("");
          //   navigate(`/helpcenterpage/${helpCenterPageUri}`,{
          //     state: { tokenExpired: true },
          //   });
          //   return;
          // }
          // if (isProtected) {
          //   navigate(`/helpcenterpage/${helpCenterPageUri}`);
          // }
        },
      },
    ],
  });

  useEffect(() => {
    // for checking if user has already rated or commented
    if (ratingData && ratingData.data?.data.alreadyRating) {
      setRatingText("Thank you for leaving a rating !");
      setRatingMade(true);
    }
    if (ratingData && ratingData.data?.data.alreadyComment) {
      setDisableComments(true);
    }
  }, [ratingData]);

  const [themeData] = useQueries({
    // for getting theme data
    queries: [
      {
        queryKey: ["getThemeDetails", integrationIdentifier],
        queryFn: () =>
          getThemeDetails({
            integrationIdentifier,
          }),
        onError: (data: any) => {
          // if (data?.response.data.tokenExpire) {
          //   setToken("");
          //   navigate(`/helpcenterpage/${helpCenterPageUri}`,{
          //     state: { tokenExpired: true },
          //   });
          //   return;
          // }
          // if (isProtected) {
          //   navigate(`/helpcenterpage/${helpCenterPageUri}`);
          // }
        },
      },
    ],
  });

  const giveFeedback = useMutation({
    // for giving feedback
    mutationFn: createArticleFeedback,
  });

  const [submitButtonLoader, setSubmitButtonLoader] = useState(false); // for submit button loader

  const [ratingText, setRatingText] = useState(""); // for rating text
  const [loader, setLoader] = useState(false); // for loader
  const [ratingMade, setRatingMade] = useState(false); // for checking if rating is made
  const [comment, setComment] = useState(""); // for comment
  const [disableComments, setDisableComments] = useState(false); // for disabling comments

  function updateRating(rating: "happy" | "sad" | "neutral" | "yes" | "no") {
    // for updating rating
    setLoader(true);
    giveFeedback.mutate(
      // for giving feedback
      {
        integrationIdentifier: integrationIdentifier,
        rating: rating,
        articleId: id ? id : "",
      },
      {
        onSuccess: (data) => {
          // for success
          setLoader(false);
          setRatingText("Thank you for leaving a rating !");
          setRatingMade(true);
          ratingData.refetch();
        },
        onError: (data: any) => {
          // for error
          setLoader(false);
          // if (data?.response.data.tokenExpire) {
          //   setToken("");
          //   navigate(`/helpcenterpage/${helpCenterPageUri}`,{
          //     state: { tokenExpired: true },
          //   });
          //   return;
          // }
          setRatingText("Something went wrong !");
        },
      }
    );
  }

  function submitComment() {
    // for submitting comment
    if (comment === "") {
      pushTheToast({
        position: "top-right",
        text: "Please enter comment !",
        type: "warning",
      });
      return;
    }
    setSubmitButtonLoader(true);
    giveFeedback.mutate(
      // for giving feedback
      {
        articleId: id ? id : "",
        integrationIdentifier: integrationIdentifier,
        comment: comment,
      },
      {
        onSuccess: () => {
          // for success
          setSubmitButtonLoader(false);
          pushTheToast({
            position: "top-right",
            text: "Comment Added Successfully",
            type: "success",
          });
          setDisableComments(true);
          setComment("");
          ratingData.refetch();
        },
        onError: (data: any) => {
          // for error
          setSubmitButtonLoader(false);
          // if (data?.response.data.tokenExpire) {
          //   setToken("");
          //   navigate(`/helpcenterpage/${helpCenterPageUri}`,{
          //     state: { tokenExpired: true },
          //   });
          //   return;
          // }
          pushTheToast({
            // for error
            position: "top-right",
            text: "Something went wrong !",
            type: "danger",
          });
        },
      }
    );
  }

  const [searchTerm, setSearchTerm] = useState(""); // for search term

  const navigate = useNavigate(); // for navigation
  const debouncedSearchTerm = useDebounce(searchTerm, 1000); // for debouncing search term

  function handleSearch(event: React.ChangeEvent<HTMLInputElement>) {
    // for handling search
    setSearchTerm(event.target.value);
  }
  const debounceSearchRef = useRef(debouncedSearchTerm); // for debouncing search term
  useEffect(() => {
    if (debounceSearchRef.current !== debouncedSearchTerm) {
      debounceSearchRef.current = debouncedSearchTerm;
      navigate(
        {
          search: `?search=${debouncedSearchTerm}`,
          pathname: `/helpcenterpage/${helpCenterPageUri}/`,
        },
        {
          replace: true,
        }
      );
    }
  }, [debouncedSearchTerm]);

  return {
    detailDesc,
    setDetailDesc,
    articleData: articleData.data,
    ratingData: ratingData.data,
    themeData: themeData.data,
    updateRating,
    ratingText,
    loader,
    ratingMade,
    comment,
    setComment,
    submitComment,
    disableComments,
    handleSearch,
    helpCenterPageUri,
    navigate,
    searchTerm,
    giveFeedback,
    articleIsLoading: articleData.isLoading,
    articleHasError: articleData.isError,
    ratingIsLoading: ratingData.isLoading,
    ratingHasError: ratingData.isError,
    themeDataIsLoading: themeData.isLoading,
    giveFeedbackIsLoading: giveFeedback.isLoading,
    submitButtonLoader,
  };
}

export default useArticleInfo;
