import React, { useMemo } from "react";
import styles from "./CustomizePreview.module.scss";
import helpLogo from "src/assets/images/helpCenter.png";
import { useQuery } from "@tanstack/react-query";
import { Theme } from "src/services/KnowledgeBase/Settings/Styles/getAllThemes.service";
import themeDefaultBackground from "src/assets/images/themeDefaultBackground.png";

interface Props {
  onHide: (e: any) => void;
  themeData: Theme;
}

const CustomizePreview = ({ onHide, themeData }: Props) => {
  const parentStyle = {
    fontFamily: themeData.font ?? "Roboto",
  };

  const categoryStyle = {
    color: themeData.brandColor ? themeData.brandColor : "#707070",
  };

  const backgroundImage = useMemo(() => {
    if (themeData?.backgroundImage) {
      return themeData.backgroundImage;
    } else if (themeData?.themeType === "night_in_the_oscars") {
      return themeDefaultBackground;
    }
    return "";
  }, [themeData?.backgroundImage, themeData?.themeType]);


  return (
    <div>
      <div className="d-flex justify-content-end align-items-center h-0">
        <span className={`cursor-pointer ${styles.closeBtn}`} onClick={onHide}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <g id="MDI_close" data-name="MDI / close" opacity="0.6">
              <g
                id="Boundary"
                stroke="rgba(0,0,0,0)"
                stroke-width="1"
                opacity="0"
              >
                <rect width="24" height="24" stroke="none" />
                <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
              </g>
              <path
                id="Path_close"
                data-name="Path / close"
                d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
              />
            </g>
          </svg>
        </span>
      </div>
      <div className={`${styles.previewBox}`}>
        <div
          className={`d-flex ${styles.headerBox}`}
          style={{
            backgroundColor: `${
              themeData?.themeType === "night_in_the_oscars"
                ? "#393939"
                : "#ffffff"
            }`,
          }}
        >
        <div className={`px-3 d-flex align-items-center ${styles.dotsBox}`}>
            <div className={`${styles.dots}`}></div>
            <div className={`${styles.dots} ${styles.red}`}></div>
            <div className={`${styles.dots} ${styles.yellow}`}></div>
        </div>
        </div>
        <div
          className={`text-center d-flex flex-column justify-content-center align-items-center ${styles.previewHeader}`}
          style={{
            background: themeData.backgroundColor,
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
        
          <p className="mb-1 d-flex align-items-center">
            <img src={helpLogo} alt="logo" className={`${styles.helpLogo}`} />
            <span className={`${styles.acme}`} style={parentStyle}>
              {themeData.storeName}
            </span>
          </p>
          <h4 className={`mb-0 ${styles.helpText} pt-2`} style={parentStyle}>
            {themeData.heading}
          </h4>

          <span className={`${styles.previewName}`} style={parentStyle}>
            {themeData.subheading}
          </span>
          <div className="w-100">
            <div
              className={`d-flex align-items-center ps-4 ${styles.searchBox}`}
            >
              <span className={`${styles.searchText} ms-4`} style={parentStyle}>
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32.375"
                  height="32.643"
                  viewBox="0 0 32.375 32.643"
                >
                  <path
                    id="Icon_material-search"
                    data-name="Icon material-search"
                    d="M27.639,25.03H26.176l-.518-.5a12.006,12.006,0,1,0-9.126,4.237,11.921,11.921,0,0,0,7.83-2.93l.5.523V27.83l9.255,9.313,2.758-2.781Zm-11.107,0a8.4,8.4,0,1,1,8.33-8.4A8.353,8.353,0,0,1,16.532,25.03Z"
                    transform="translate(-4.5 -4.5)"
                    fill="#afafaf"
                  />
                </svg>
              </span>
              <span className={`${styles.searchText}`}>
                {themeData.placeholder}
              </span>
            </div>
          </div>
        </div>

        <div
          style={{
            backgroundColor: `${
              themeData?.themeType === "night_in_the_oscars"
                ? "#000000"
                : "#ffffff"
            }`,
          }}
        >
          <div className={`${styles.categoryBox}`}>
            <p
              className={`${styles.categoryText}`}
              style={{ ...parentStyle, ...categoryStyle }}
            >
              Categories
            </p>
            {themeData?.themeType === "night_in_the_oscars" ? (
              <>
                <div className="row">
                  <div className="col-12 col-md-6 pe-2">
                    <div
                      className={`${styles.category} ${styles.categoryNight}`}
                    ></div>
                  </div>
                  <div className="col-12 col-md-6 ps-2">
                    <div
                      className={`${styles.category}  ${styles.categoryNight}`}
                    ></div>
                  </div>
                  <div className="col-12 col-md-6 pe-2">
                    <div
                      className={`${styles.category}  ${styles.categoryNight}`}
                    ></div>
                  </div>
                  <div className="col-12 col-md-6 ps-2">
                    <div
                      className={`${styles.category}  ${styles.categoryNight}`}
                    ></div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className={`${styles.category}`}></div>
                <div className={`${styles.category}`}></div>

                <div className={`${styles.category}`}></div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomizePreview;
