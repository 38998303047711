import { Accordion } from "react-bootstrap";
import { NavLink, useParams } from "react-router-dom";
import styles from "./ArticleSideBar.module.scss";
import useArticleSideBar from "./useArticleSideBar";
import { Brand } from "src/services/KnowledgeBase/getKnowledgeBaseBrands.service";
import AxiosImg from "src/components/AxiosImg";
import UserAvatar from "src/components/UserAvatar";

type Props = {
  showSubFolder: boolean;
  brandDetails: Brand | undefined;
  brandDetailsError: boolean;
  brandDetailsLoading: boolean;
};
const ArticleSideBar = ({
  showSubFolder,
  brandDetails,
  brandDetailsError,
  brandDetailsLoading,
}: Props) => {
  const { integrationId } = useParams();
  const pageIntegrationId: string = integrationId
    ? decodeURIComponent(integrationId)
    : "";
  const { getToggleContent, showSettingsSubSB } = useArticleSideBar();
  return (
    <div
      className={`position-relative ${styles.sidebarWrapper} ${
        showSettingsSubSB === true ? styles.active : ""
      }`}
    >
      {/* showSettingSubSb is true then show sidebar for articles  */}
      {showSettingsSubSB === true && (
        <>
          <div>
            {/* <NavLink
              to={`/knowledge-base`}
              className={`mt-2 d-flex justify-content-center align-items-center ${styles.backChat}`}
            >
              <i className="fa-solid fa-arrow-left"></i>
            </NavLink> */}
          </div>
          {brandDetailsLoading ? (
            <div
              className="spinner-border text-secondary spinner-border-sm"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : brandDetailsError ? (
            <div>Error</div>
          ) : brandDetails ? (
            <div
              className={`mt-2 ${styles.sideBarHead} saufter_medium_h4 d-flex align-items-center`}
            >
              <span className={`d-flex align-items-center justify-content-center ${styles.brandImg}`}>
                {brandDetails.imageURL && brandDetails.imageURL.length !== 0 ? (
                  <AxiosImg
                    url={brandDetails.imageURL ?? "NA"}
                    className={`rounded-circle me-2 ${styles.authorImg}`}
                  />
                ) : (
                  <UserAvatar
                    name={brandDetails.name ?? "NA"}
                    size={25}
                    className={`me-2`}
                  />
                )}
              </span>
              <span className=" d-flex align-items-center justify-content-center">{brandDetails?.name}</span>
            </div>
          ) : (
            ""
          )}

          <h4 className={`mt-2 ${styles.kbHead} saufter_medium_h4`}>
            Knowledge Base
          </h4>

          {/* Article and Categories Accordion */}
          <Accordion defaultActiveKey="0">
            <Accordion.Item className={`${styles.accordItem}`} eventKey="0">
              <Accordion.Header
                className={`${styles.toggleHead} ${styles.accordionOpen} saufter_h4`}
              >
                Articles & Categories
              </Accordion.Header>
              <Accordion.Body className={`${styles.accordBody}`}>
                <div className="d-flex ps-1">
                  <NavLink
                    to={`#`}
                    className={`${styles.webBtn} ${styles.webBtnActive}`}
                  >
                    <div className={`${styles.bar}`}></div>
                    Create new articles & categories
                  </NavLink>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </>
      )}

      {getToggleContent()}
    </div>
  );
};

export default ArticleSideBar;
