import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "src/components/Loader";
import { fetchHelpCenterData, resetHelpCenterSetting } from "src/store/slices/liveChatSetting/chatWidgetSetting/helpCenterSetting/helpCenterSetting.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import HelpCenterPage from "./children/HelpCenterPage/HelpCenterPage";
import HelpCenterSetting from "./children/HelpCenterSetting/HelpCenterSetting";
import LeftCreateBox from "./children/LeftCreateBox/LeftCreateBox";
import { UploadAttachmentRes } from "src/services/Attachment/uploadAttachment";
import { axiosFormData } from "src/globals/axiosEndPoints";
import { IUpdateHelpCenterPageDataParams, updateHelpCenterPageData } from "src/services/LiveChat/Settings/updateHelpCenterData";
import styles from "./HelpCenterStyle.module.scss";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";

export interface AttachmentData{
  attachmentUrl: string, 
  attachmentName: string, 
  attachmentId: number|string, 
  attachmentType: string,
  isUploaded: boolean, 
  attachmentFile: any,
  isAxiosLink: boolean,
  isFileExist: boolean,
  isDeleted: boolean,
}
const HelpCenterStyle = () => {
  
  const { integrationId } = useParams();
  const pageIntegrationId = integrationId ? decodeURIComponent(integrationId) : "";

  const { helpCenterData, helpCenterDataAjaxStatus, updateHelpCenterDataAjaxStatus } = useAppSelector((state) => state.helpCenterSettings);
  const dispatch = useAppDispatch();
  const [helpdeskUrl, setHelpdeskUrl] = useState({domainName:"", extension:""});
  const [companyName, setCompanyName] = useState("");
  const [headlineText, setHeadlineText] = useState("");
  const [themeColor, setThemeColor] = useState("");
  const [googleAnalyticsTrackingId, setGoogleAnalyticsTrackingId] = useState("");
  const [searchEngineIndexing, setSearchEngineIndexing] = useState(false);
  const [isPublished, setIsPublished] = useState(false);

  const [logoAttachmentData, setLogoAttachmentData] = useState<AttachmentData>();
  const [backgroundAttachmentData, setBackgroundAttachmentData] = useState<AttachmentData>();
  const [faviconAttachmentData, setFaviconAttachmentData] = useState<AttachmentData>();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchHelpCenterData(pageIntegrationId));
  }, [false]);

  useEffect(()=>{
    if(helpCenterData !== null || helpCenterData !== undefined){
      setHelpdeskUrl(helpCenterData.helpdeskUrl);
      setCompanyName(helpCenterData.companyName);
      setHeadlineText(helpCenterData.headlineText);
      setThemeColor(helpCenterData.themeColor);
      setGoogleAnalyticsTrackingId(helpCenterData.googleAnalyticsTrackingId);
      setSearchEngineIndexing(helpCenterData.searchEngineIndexing);
      setIsPublished(helpCenterData.isPublished);

      const attachmentDataObj = {
        attachmentUrl: "",
        attachmentName: "",
        attachmentId: 0,
        attachmentType: "",
        isUploaded: true,
        attachmentFile: null,
        isAxiosLink: true,
        isFileExist: true,
        isDeleted: false
      };
      const attachments:any = {logo:helpCenterData.logoAttachment, background:helpCenterData.backgroundAttachment,favicon:helpCenterData.faviconAttachment};
      Object.keys(attachments).map((key:string)=>{

        if(attachments[key] !== null && attachments[key] !== undefined && Object.keys(attachments[key]).length !== 0){

          const attachment = attachments[key];

          let attachmentObj = {
            ...attachmentDataObj
          };
          attachmentObj['attachmentId'] = attachment.attachmentId;
          attachmentObj['attachmentName'] = attachment.attachmentName;
          attachmentObj['attachmentUrl'] = attachment.attachmentUrl;
          
          setAttachmentData(key, attachmentObj);
        }
      })
    }
    // console.log(helpCenterData);
  },[helpCenterData])

   //   helpdeskUrl
// companyName
// headlineText
// themeColor
// googleAnalyticsTrackingId
// searchEngineIndexing
// isPublished

const setAttachmentData = (type:string, attachmentData:AttachmentData) =>{
  if(type === 'logo'){
    setLogoAttachmentData(attachmentData);
  }else if(type === 'background'){
    setBackgroundAttachmentData(attachmentData);
  }else{
    setFaviconAttachmentData(attachmentData);
  }
}

const handleSubmit = () =>{

  if(helpCenterData !== null && helpCenterDataAjaxStatus !== "pending"){

    let payload: IUpdateHelpCenterPageDataParams = {
      integrationId: pageIntegrationId,
      helpdeskUrl: helpdeskUrl.domainName,
      companyName: companyName,
      headlineText: headlineText,
      themeColor: themeColor,
      googleAnalyticsTrackingId: googleAnalyticsTrackingId,
      searchEngineIndexing: searchEngineIndexing,
      isPublished: isPublished,
    };
    //checking if attachment was uploaded
    if(
      (logoAttachmentData?.isFileExist === true && logoAttachmentData?.isUploaded === false) ||
        (backgroundAttachmentData?.isFileExist === true && backgroundAttachmentData?.isUploaded === false) ||
        (faviconAttachmentData?.isFileExist === true && faviconAttachmentData?.isUploaded === false)
    ){
      //uploading files
      const uploadableAttachments = [logoAttachmentData,backgroundAttachmentData,faviconAttachmentData].filter((attachmentData)=>(attachmentData?.isFileExist===true && attachmentData.isUploaded===false && attachmentData.isDeleted === false));

      const ids = Promise.all( 
        
        uploadableAttachments.map(async (attachmentData)=>{
          let attachment:any = null;
          await uploadAttachment(attachmentData?.attachmentFile)
          .then((uploadRes)=>{
            if(uploadRes.attachments[0].status === true){
              attachmentData = {
                attachmentId: uploadRes.attachments[0].attachmentId,
                attachmentName: uploadRes.attachments[0].attachmentName,
                attachmentUrl: URL.createObjectURL(attachmentData?.attachmentFile),
                isUploaded: true,
                attachmentFile: attachmentData?.attachmentFile,
                isAxiosLink: false,
                isFileExist: true,
                attachmentType: attachmentData?.attachmentType ?? "",
                isDeleted: false,
              };
              attachment=attachmentData
            }
          }).catch((err)=>{
            console.log("error when uploading attachment: "+JSON.stringify(err));
          });
        return attachment;
      })
      ).then((res)=>{

        //adding deleted attachment to the payload
        if(logoAttachmentData?.isDeleted === true){
          payload['logoAttachmentId'] = null;
        }
        if(backgroundAttachmentData?.isDeleted === true){
          payload['backgroundAttachmentId'] = null;
        }
        if(faviconAttachmentData?.isDeleted === true){
          payload['faviconAttachmentId'] = null;
        }

        const logoAttachment = res.filter((key:any)=>key.attachmentType === 'logo');
        const backgroundAttachment = res.filter((key:any)=>key.attachmentType === 'background');
        const faviconAttachment = res.filter((key:any)=>key.attachmentType === 'favicon');

        if(logoAttachment.length !== 0 && logoAttachment[0]?.attachmentId  !== 0){
          payload['logoAttachmentId'] = logoAttachment[0].attachmentId;
        }
        if(backgroundAttachment.length !== 0  && backgroundAttachment[0]?.attachmentId  !== 0){
          payload['backgroundAttachmentId'] = backgroundAttachment[0].attachmentId;
        }
        if(faviconAttachment.length !== 0  && faviconAttachment[0]?.attachmentId  !== 0){
          payload['faviconAttachmentId'] = faviconAttachment[0].attachmentId;
        }
        
        updateHelpCenterPageData(payload).
          then((res)=>{
            pushTheToast({
              type: "success",
              text: "updated successfully!",
              position: "top-right"
          });
          dispatch(resetHelpCenterSetting());
          dispatch(fetchHelpCenterData(pageIntegrationId));
        }).catch((err)=>{
          pushTheToast({
            type: "danger",
            text: "something went wrong!",
            position: "top-right"
          });
        });

      }).catch((err)=>{
        console.log("error on uploading files"+ JSON.stringify(err));
      });

    }else{

      //adding deleted attachment to the payload
      if(logoAttachmentData?.isDeleted === true){
        payload['logoAttachmentId'] = null;
      }
      if(backgroundAttachmentData?.isDeleted === true){
        payload['backgroundAttachmentId'] = null;
      }
      if(faviconAttachmentData?.isDeleted === true){
        payload['faviconAttachmentId'] = null;
      }

      updateHelpCenterPageData(payload).
      then((res)=>{
        pushTheToast({
          type: "success",
          text: "updated successfully!",
          position: "top-right"
      });
      dispatch(resetHelpCenterSetting());
      dispatch(fetchHelpCenterData(pageIntegrationId));
    }).catch((err)=>{
      pushTheToast({
        type: "danger",
        text: "something went wrong!",
        position: "top-right"
      });
    });
    }
    
  }

}

const uploadAttachment =async (file:File)=>{
  
  const fData = new FormData();
  fData.append("attachment[]", file);

  const { data: res } = await axiosFormData.post("/api/attachment/add", fData);

  // console.log("Uploaded attachments:: Res:: ", JSON.stringify(res));
  if (res === false) {
    throw new Error(res.message as string);
  }

  let ret: UploadAttachmentRes = {
    attachments: [],
  };

  ret.attachments = res;

  return ret;
};

  return (
    <div className={`${styles.createArticle}`}>
      <div className={`d-flex justify-content-between align-items-center flex-wrap`}>
        <div className={`d-flex flex-column flex-md-row ${styles.leftContent}`}>
          <div className={`${styles.backArrow}`}>
            <span onClick={(e)=>{navigate("/live-chat/settings/"+pageIntegrationId+"/Knowledge");}}>
              <i className="fa-solid fa-arrow-left" role={'button'}></i>{" "}
            </span>
          </div>
          <div className={`${styles.heading}`}>
            <span>Knowledge Base &gt; Style Helpcenter Page</span>
          </div>
        </div>
      
        <div className={`d-flex align-items-center mt-2 mt-md-0 ${styles.rightContent}`}>
          <span className={`${styles.moreOption}`}>
            {" "}
            <i className="fa-solid fa-ellipsis"></i>{" "}
          </span>
          {/* <div className={`${styles.delBox}`}>
            <i className="fa-solid fa-trash"></i>
          </div> */}
          <button className={`${styles.saveBtn} ms-3`} onClick={handleSubmit}> + Save Changes</button>
        </div>
      </div>
      {helpCenterDataAjaxStatus === "pending" ? (
        <Loader />
         ) : (
      <div className={`mt-4 d-flex flex-wrap flex-md-nowrap `}>
        
       {/* <LeftCreateBox /> */}
       {/* <AddArticle /> */}
       <HelpCenterPage
        companyName={companyName}
        headlineText={headlineText}
        integrationId={pageIntegrationId}
        logoImgUrl={logoAttachmentData?.attachmentUrl}
        isAxiosLink={logoAttachmentData?.isAxiosLink}
        themeColour={themeColor}
          />
        <div className={`${styles.rightContent}`}>
        {/* <ArticleSetting /> */}
       {helpCenterData  && <HelpCenterSetting helpCenterData={{
          helpdeskUrl: helpdeskUrl,
          companyName: companyName,
          headlineText: headlineText,
          themeColor: themeColor,
          googleAnalyticsTrackingId: googleAnalyticsTrackingId,
          searchEngineIndexing: searchEngineIndexing,
          isPublished: isPublished,
          logoAttachmentData: logoAttachmentData,
          backgroundAttachmentData: backgroundAttachmentData,
          faviconAttachmentData: faviconAttachmentData,
        }}
        setHelpCenterData={{
          setHelpdeskUrl: setHelpdeskUrl,
          setCompanyName: setCompanyName,
          setHeadlineText: setHeadlineText,
          setThemeColor: setThemeColor,
          setGoogleAnalyticsTrackingId: setGoogleAnalyticsTrackingId,
          setSearchEngineIndexing: setSearchEngineIndexing,
          setIsPublished: setIsPublished,
          setLogoAttachmentData: setLogoAttachmentData,
          setBackgroundAttachmentData: setBackgroundAttachmentData,
          setFaviconAttachmentData: setFaviconAttachmentData,
        }}
        integrationId={pageIntegrationId}
        />
      }
        </div>
      </div>
          )}
    </div>
  );
};
export default HelpCenterStyle;
