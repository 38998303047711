import { useCallback, useEffect, useRef, useState } from "react";
import { Button, Form, Overlay } from "react-bootstrap";
import { useParams } from "react-router-dom";
import {
  fetchInnerTicketMessages,
  resetInnerTicketMessage,
  sendMessages,
  setActiveTicketInfoFromTicketData,
  setInnerTicketActive,
} from "src/store/slices/innerTicket/innerTicket.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import styles from "./CannedSendMessage.module.scss";
import sendLogo from "src/assets/images/send.png";
import CannedSendingAs, { ESendTypes } from "../CannedSendingAs";
import SendingDetails from "src/routes/Ticket/children/MainChat/children/SendMessage/children/SendingDetails";
import QuillEditorContainer from "src/components/QuillEditorContainer";
import DraftModal from "src/components/DraftModal";
import OpenClose from "src/routes/Ticket/children/MainChat/children/SendMessage/children/OpenClose";
import { v4 as uuid } from "uuid";
import TinyMCEContainer from "src/components/TinyMCEContainer";

function CannedSendMessage({
  message,
  setMessage,
  textareaBorder,
  handleChange,
}: any) {
  const dispatch = useAppDispatch();
  const [css, updatedCss] = useState(false);
  // const sendingDetailsRef: any = useRef({});
  // const target = useRef(null);
  // const overlayRef = useRef(null);
  const formRef: any = useRef(null);
  // const [showOverlay, setShowOverlay] = useState(false);
  // const { tableType, ticketId, viewType }: any = useParams();
  // const [showDraftModal, setShowDraftModal] = useState(false);
  // const canAddDraft = useAppSelector(
  //   (state) => state.innerTicket.activeTicketInfo.can_add_new_draft
  // );
  // const sendStatus = useAppSelector(
  //   (state) => state.innerTicket.sendMessageAjaxStatus
  // );
  // const ticketStatusList = useAppSelector(
  //   (state) => state.innerTicket.ticketStatusList
  // );
  // // const [message, setMessage]: any = useState("");
  // const [sendType, setSendType] = useState(1);
  // const clearData = useCallback(() => {
  //   try {
  //     sendingDetailsRef.current.clearData();
  //   } catch {}
  //   setMessage("");
  // }, []);
  // const sendMessage = useCallback(
  //   (e: any, status = "Open") => {
  //     e.preventDefault();
  //     if (sendStatus !== "pending") {
  //       dispatch(
  //         sendMessages({
  //           ticketId,
  //           to: formRef.current.elements.to
  //             ? formRef.current.elements.to.value.trim()
  //             : "",
  //           cc: formRef.current.elements.cc
  //             ? formRef.current.elements.cc.value.trim()
  //             : "",
  //           bcc: formRef.current.elements.bcc
  //             ? formRef.current.elements.bcc.value.trim()
  //             : "",
  //           message: formRef.current.elements.message
  //             ? formRef.current.elements.message.value.trim()
  //             : "",
  //           messageType: ESendTypes[sendType] as any,
  //           sendAs: status,
  //         })
  //       );
  //       setTimeout(() => {
  //         clearData();
  //       }, 0);
  //     }
  //   },
  //   [ticketId, sendStatus, sendType]
  // );

  // const onShowDraftModal = useCallback(() => {
  //   setShowDraftModal(true);
  // }, []);
  // const onHideDraftModal = useCallback(() => {
  //   setShowDraftModal(false);
  //   setMessage("");
  //   dispatch(resetInnerTicketMessage());
  //   dispatch(setActiveTicketInfoFromTicketData({ ticketId }));
  //   dispatch(fetchInnerTicketMessages());
  // }, []);

  // function selectedTemplateHandler(template: any) {
  //   console.log("hahah " + template);
  // }

  return (
    <div className={`mb-2 mx-auto w-100`}>
      <div
        className={`${styles.sendMessage} ${styles[textareaBorder]}`}
        id="cannedMessage"
      >
        <Form ref={formRef}>
          <TinyMCEContainer
            name="message"
            className={`${styles.message}`}
            // editorClassName={`${styles.editor}`}
            value={message}
            onChange={(value) => {
              if (handleChange) {
                handleChange();
              }
              setMessage(value);
            }}
            options={{
              placeholder:
                // sendType === ESendTypes.Note
                // ? `Use "#" to recall related templates`
                "Response text*",
              min_height: 135,
              max_height: 135,
            }}
            uniqueID={uuid()}
          />
        </Form>
      </div>
    </div>
  );
}

export default CannedSendMessage;
