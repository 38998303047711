/**
 * This file is the React component file for rendering a dropdown menu with a list of categories.
 * It provides the functionality to select and filter categories.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 * @author Navjyot
 */

import React, { useRef } from "react";
import styles from "./AllCategory.module.scss";
import { Dropdown } from "react-bootstrap";
import useAllCategory from "./useAllCategory";
import InfiniteScroll from "src/components/InfiniteScrollBothSide";
import { v4 as uuid } from "uuid";

// Define the interface for the component's props
interface Props {
  isSegement?: boolean;
  disabled?: boolean;
}

// React component for rendering a dropdown menu with a list of categories
const AllCategory = ({ isSegement, disabled }: Props) => {
  // Destructuring values from the custom hook
  const {
    setDdOpen,
    ddOpen,
    dropdownArrowSpanRef,
    allCategories,
    checkUncheckCategories,
    clearAllCategories,
    selectAllCategories,
    fetchNextPage,
    hasNextPage,
    selectedCategories,
    categoryIds,
  } = useAllCategory();

  // Generate a unique identifier for the component
  const uuidRef = useRef(uuid());

  return (
    <div>
      {/* Dropdown component from react-bootstrap */}
      <Dropdown onToggle={setDdOpen}>
        {/* Dropdown toggle button */}
        <Dropdown.Toggle
          as="div"
          bsPrefix={`dropdown-toggle cursor-pointer border-0 px-2 py-1 me-2  mb-2 mb-lg-0 ${
            styles.filterButton
          } ${ddOpen && styles.openFilter}
          ${disabled && styles.disable} `}
        >
          All category
          {/* Display selected category count if any */}
          {selectedCategories?.length ? (
            <>
              <span className={`mx-1 ${styles.filterCount}`}>
                {/* added condition to show 0 before count if count is less than 10 */}
                {selectedCategories.length < 10
                  ? `0${selectedCategories.length}`
                  : selectedCategories.length}
              </span>{" "}
            </>
          ) : (
            ""
          )}
          {/* Display dropdown arrow */}
          <span className="ps-1" ref={dropdownArrowSpanRef}>
            {ddOpen ? (
              <i className={`fa-solid fa-caret-up`}></i>
            ) : (
              <i className={`fa-solid fa-caret-down`}></i>
            )}
          </span>
        </Dropdown.Toggle>

        {/* Dropdown menu */}
        <Dropdown.Menu bsPrefix={`dropdown-menu border-0 ${styles.filterMenu}`}>
          <div className={`${styles.dropMenu}`}>
            {/* Select all item */}
            <span className={`${styles.dropItem}`}>
              <div
                className={`${styles.itemWrapper} ${
                  selectedCategories.length === allCategories?.length &&
                  styles.selectedText
                } `}
              >
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value="all"
                    checked={
                      selectedCategories.length === allCategories?.length
                    }
                    onChange={selectAllCategories}
                    id={uuidRef.current + "report-filter-status-all-category"}
                  />
                  <label
                    className={`form-check-label ${styles.checktext}`}
                    htmlFor={
                      uuidRef.current + "report-filter-status-all-category"
                    }
                  >
                    Select all
                  </label>
                </div>
              </div>
            </span>

            {/* List of categories with infinite scroll */}
            <span className={`${styles.dropItem}`}>
              <div style={{ maxHeight: "20vh" }}>
                {/* InfiniteScroll component for efficient scrolling */}
                <InfiniteScroll
                  loadMoreFromBottom={fetchNextPage}
                  hasMoreBottom={hasNextPage}
                  className={`h-100 overflow-auto`}
                >
                  {categoryIds?.map((id: any) => {
                    return (
                      <>
                        <div
                          className={`${styles.itemWrapper} ${
                            selectedCategories.includes(id) && styles.selectOne
                          }`}
                          key={id}
                        >
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={selectedCategories.includes(id)}
                              onChange={checkUncheckCategories}
                              value={id}
                              id={uuidRef.current + "category-filter" + id}
                            />
                            <label
                              className={`form-check-label ${styles.checktext}`}
                              htmlFor={uuidRef.current + "category-filter" + id}
                            >
                              {/* Display category name */}
                              {
                                allCategories?.find(
                                  (cat) => cat.categoryId === Number(id)
                                )?.categoryName
                              }
                            </label>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </InfiniteScroll>
              </div>
            </span>
          </div>
          {/* Clear all categories button */}
          <span className={`${styles.clearSpan}`} onClick={clearAllCategories}>
            Clear all
          </span>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default AllCategory;
