import { axiosJSON } from "src/globals/axiosEndPoints";

export const getBugPriority = async () => {
  const { data: res } = await axiosJSON.get(
    `/api/selfService/bugManagement/getBugPrioritys`,
    {
      params: { cacheIt: true, cacheTime: 3600000 },
    },
  );

  if (res.err || res.error) {
    throw res.msg ?? res.message ?? "Something went wrong!";
  }

  const ret = normalizeData(res);

  return ret;
};

export type AllPriorityNormalizedData = {
  allBugPriority: Record<string, { id: string; name: string, key: string }>;
  allBugPriorityIds: number[];
};

// convert incoming data to the required format
function normalizeData(response: any): AllPriorityNormalizedData {
  const statusesList = response.data;
  const normalizedData: AllPriorityNormalizedData = {
    allBugPriority: {},
    allBugPriorityIds: [],
  };
  statusesList.forEach((priority: any, index: number) => {
    normalizedData.allBugPriority[`${index + 1}`] = {
      id: index + 1 + "",
      name: priority.priorityName,
      key: priority.priorityKey
    };
    normalizedData.allBugPriorityIds.push(index + 1);
  });
  return normalizedData;
}
