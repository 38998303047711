/**
 * This file is the service file used for making api request.
 * It contains the getHelpCenterPage function service which is explained below.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { axiosKbPreview } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants"; // this is used for testing purpose only for frontend.

// export interface helpCenterPageResponse {
//   integrationIdentifier: string;
//   passwordRestrictionStatus: boolean;
// }

export interface helpCenterPagePayload {
  helpCenterPageUri: string;
  isFirstLogin: boolean;
}
// Added reposnse format to check error is true or not and show error message
export interface helpCenterPageResponse {
  error: boolean;
  data: {
    integrationIdentifier: string;
    passwordRestrictionStatus: boolean;
  };
  status: string;
  statusMsg: string;
}
/**
 * For testing frontend with own api link if backend is not prepared.
 */
const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/getHelpCenterPage/`
  : "/api/knowledgebase/helpCenterPage/check/";

/**
 * This service is used to get the helpcenter page.
 */
export const getHelpCenterPage = async (params: helpCenterPagePayload) => {
  const { data: res } = await axiosKbPreview.post(
    `${apiEndPoint}${params.helpCenterPageUri}`,
    { isFirstLogin: params.isFirstLogin },
  );
  return res as helpCenterPageResponse;
};
