import { useContext, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import IntlTelInput, {
  IntlTelInputParams,
} from "src/components/IntlTelInput/IntlTelInput";
import { useAppSelector } from "src/store/store";
import AddMore from "../AddMore/AddMore";
import styles from "../CustomerEdit/CustomerEdit.module.scss";
import { InputFieldsContext } from "../CustomerEdit/useInputFields";

function PhoneNumber(props: any) {
  const phoneNumber = useAppSelector(
    (state) => state.editcustomerdetails.customer.contacts?.phone,
  );

  // Removed the custom country list components as they are not being used anymore
  // const dispatch = useAppDispatch();

  // useEffect(() => {
  //   dispatch(getCountryList());
  // }, []);

  // const countryList = useAppSelector((state) => state.globals.countryList.data);

  // const { Option } = components;

  // const CustomOption = (props: any) => (
  //   <Option
  //     {...props}
  //     className="d-flex justify-content-evenly"
  //   >
  //     <div>{props.data.icon}</div>
  //     <span className="">{props.data.label}</span>
  //   </Option>
  // );

  // const CustomInput = (props: any) => {
  //   const selectedOption = props.selectProps.value;
  //   if (!props.isHidden) {
  //     return <>{selectedOption.icon}</>;
  //   }
  //   return <components.Input {...props} />;
  // };

  // const CustomSingleValue = (props: any) => {
  //   const selectedOption = props.selectProps.value;
  //   return (
  //     <components.SingleValue {...props}>
  //       {selectedOption.icon}
  //     </components.SingleValue>
  //   );
  // };

  // function getCountryCodeOption(
  //   field: any,
  // ):
  //   | PropsValue<{ value: string; label: JSX.Element; icon: JSX.Element }>
  //   | undefined {
  //   const countryItems = countryList?.filter((country: CountryItem) => {
  //     return country.dial_code === field.value.countryCode;
  //   });

  //   if (countryItems !== undefined && countryItems.length > 0) {
  //     return {
  //       value: countryItems[0].dial_code,
  //       label: <>{countryItems[0].code}</>,
  //       icon: (
  //         // <img
  //         //   src={`https://countryflagsapi.com/png/${countryItems[0].code}`}
  //         //   style={{ maxWidth: "20px", maxHeight: "20px" }}
  //         // />
  //         <span
  //           dangerouslySetInnerHTML={{
  //             __html: countryItems[0].flag_unicode
  //               .split(" ")
  //               .map((code) => code.substring(2))
  //               .map((code) => `&#x${code};`)
  //               .join(""),
  //           }}
  //         ></span>
  //         // <ReactCountryFlag countryCode={`${countryItems[0].code}`} />
  //         // <ReactCountryFlag countryCode="US" />
  //         // <p>{countryItems[0].flag_unicode}</p>
  //       ),
  //     };
  //   }
  // }

  const {
    addField,
    updateField,
    removeField,
    setFieldsOfKey,
    fields,
    areDuplicateFields,
  } = useContext(InputFieldsContext);

  useEffect(() => {
    setFieldsOfKey(phoneNumber ?? [], "phoneNumber");
  }, [phoneNumber]);

  const phoneNumbers = fields.get("phoneNumber");

  const isAddButtonEnabled = phoneNumbers?.length
    ? phoneNumbers[phoneNumbers.length - 1]?.isValid
      ? true
      : false
    : false;

  // Getting ids of duplicate numbers
  const { hasDuplicates, duplicateIds } =
    areDuplicateFields.duplicates["phoneNumber"];

  return (
    <>
      {fields.get("phoneNumber")?.map((field, idx) => {
        return (
          <>
            {idx === 0 ? (
              <label
                className={`d-flex justify-content-between align-items-center form-label ${styles.labelName} m-0`}
              >
                <div className={`d-flex align-items-center`}>
                  <div
                    className={`d-flex justify-content-center align-items-center ${styles.msgBg}`}
                  >
                    <i className={`fa-solid fa-phone ${styles.callIcon}`}></i>
                  </div>{" "}
                  <div>
                    <span className={`ps-1`}>Phone Number</span>
                  </div>
                </div>
              </label>
            ) : (
              <></>
            )}
            <div
              className="d-flex flex-column w-100"
              key={field.id}
            >
              <div className={`d-flex mt-2 w-100 `}>
                <IntlTelInput
                  phoneNumber={(field.value as any).phoneNumber}
                  countryCode={(field.value as any).countryCode}
                  onChange={(params: IntlTelInputParams) => {
                    updateField(
                      field,
                      {
                        ...field,
                        value: {
                          countryCode: params.countryData.dialCode ?? "91",
                          phoneNumber: params.phoneNumber,
                        },
                        isValid: params.isValidNumber,
                      },
                      "phoneNumber",
                    );
                  }}
                  isDuplicate={hasDuplicates && duplicateIds.includes(field.id)} //Adding isDuplicate to show red border if it is a duplicate
                >
                  {(params) => {
                    return (
                      <>
                        <small className={`${styles.errorMsg}`}>
                          {params.eventType !== "initial" && params.errorMessage
                            ? params.errorMessage
                            : hasDuplicates && duplicateIds.includes(field.id) //Adding error message if it is a duplicate number
                              ? `Duplicate phone number not allowed`
                              : ""}
                        </small>
                      </>
                    );
                  }}
                </IntlTelInput>

                <input
                  data-lpignore="true"
                  name="countryCode"
                  hidden
                  value={(field.value as any).countryCode}
                ></input>
                <input
                  data-lpignore="true"
                  name="Phone Number"
                  hidden
                  value={(field.value as any).phoneNumber}
                ></input>

                <div
                  className={`${styles.customCrossPosition} ${styles.setWidth}`}
                  onClick={() => {
                    removeField(field, "phoneNumber");
                  }}
                >
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip className={`${styles.toolTipCustom}`}>
                        Remove
                      </Tooltip>
                    }
                  >
                    {({ ref, ...triggerHandler }) => (
                      <div
                        {...triggerHandler}
                        ref={ref}
                      >
                        <div
                          className={`d-flex justify-content-center align-items-center ${styles.clearText}`}
                        >
                          <i className="fa-solid fa-xmark"></i>
                        </div>
                      </div>
                    )}
                  </OverlayTrigger>
                </div>
              </div>
            </div>
          </>
        );
        // Removed the custom country list components as they are not being used anymore
        // return (
        //   <div
        //     className="d-flex flex-column"
        //     key={field.id}
        //   >
        //     {idx === 0 ? (
        //       <label
        //         htmlFor="name"
        //         className={`d-flex justify-content-between align-items-center form-label ${styles.labelName} m-0`}
        //       >
        //         <div className={`d-flex align-items-center`}>
        //           <div
        //             className={`d-flex justify-content-center align-items-center me-1 ${styles.msgBg}`}
        //           >
        //             <i className={`fa-solid fa-phone ${styles.callIcon}`}></i>
        //           </div>{" "}
        //           <div>
        //             <span>Phone Number</span>
        //           </div>
        //         </div>
        //       </label>
        //     ) : (
        //       <></>
        //     )}
        //     <div className={`d-flex align-items-center`}>
        //       <div className={`mt-2 me-2 me-lg-2 ${styles.countryWrapper}`}>
        //         <Select
        //           options={
        //             countryList?.map((country: any) => {
        //               return {
        //                 value: country.dial_code,
        //                 label: country.code,
        //                 icon: (
        //                   // <img
        //                   //   src={`https://countryflagsapi.com/png/${country.code}`}
        //                   //   style={{ maxWidth: "20px", maxHeight: "20px" }}
        //                   // />
        //                   // <ReactCountryFlag countryCode={`${country.code}`} />
        //                   // <p>{country.dial_code}</p>
        //                   <span
        //                     dangerouslySetInnerHTML={{
        //                       __html: country.flag_unicode
        //                         .split(" ")
        //                         .map((code: string) => code.substring(2))
        //                         .map((code: any) => `&#x${code};`)
        //                         .join(""),
        //                     }}
        //                   ></span>
        //                 ),
        //               };
        //             }) ??
        //             ([] as {
        //               value: string;
        //               label: JSX.Element;
        //               icon: JSX.Element;
        //             }[])
        //           }
        //           styles={{
        //             container: (bs) => {
        //               bs.minWidth = "10%";
        //               bs.zIndex = "1";
        //               return bs;
        //             },
        //           }}
        //           value={getCountryCodeOption(field)}
        //           className={`${styles.countryCode}`}
        //           components={{
        //             Option: CustomOption,
        //             Input: CustomInput,
        //             SingleValue: CustomSingleValue,
        //           }}
        //           isSearchable={false}
        //           defaultValue={{
        //             value: countryList?.[0]?.dial_code ?? "",
        //             label: <>{countryList?.[0]?.code}</> ?? <></>,
        //             icon: (
        //               // <img
        //               //   src={`https://countryflagsapi.com/png/${countryList?.[0]?.code}`}
        //               //   style={{ maxWidth: "20px", maxHeight: "20px" }}
        //               // />
        //               <span
        //                 dangerouslySetInnerHTML={{
        //                   __html:
        //                     countryList?.[0]?.flag_unicode
        //                       ?.split(" ")
        //                       ?.map((code: string) => code.substring(2))
        //                       ?.map((code: any) => `&#x${code};`)
        //                       ?.join("") ?? "",
        //                 }}
        //               ></span>
        //             ) ?? <></>,
        //           }}
        //           onChange={(
        //             newValue: SingleValue<{
        //               value: string;
        //               label: JSX.Element;
        //               icon: JSX.Element;
        //             }>,
        //             actionMeta: ActionMeta<{
        //               value: string;
        //               label: JSX.Element;
        //               icon: JSX.Element;
        //             }>,
        //           ) => {
        //             return updateField(
        //               field,
        //               {
        //                 ...field,
        //                 value: { countryCode: newValue?.value ?? "+91" },
        //               },
        //               "phoneNumber",
        //             );
        //           }}
        //         />
        //       </div>
        //       <div className={`d-flex mt-2 ${styles.phoneWrapper}`}>
        //         <input
        //           data-lpignore="true"
        //           name="countryCode"
        //           hidden
        //           value={(field.value as any).countryCode}
        //         ></input>
        //         <input
        //           data-lpignore="true"
        //           style={{}}
        //           type="number"
        //           className={`form-control ${styles.formInput} ${styles.numberSet}`}
        //           id="phoneNumber"
        //           aria-describedby="emailHelp"
        //           placeholder="Enter Phone Number"
        //           required
        //           name="Phone Number"
        //           value={(field.value as any).phoneNumber}
        //           onChange={(e) => {
        //             updateField(
        //               field,
        //               {
        //                 ...field,
        //                 value: { phoneNumber: e.target.value },
        //               },
        //               "phoneNumber",
        //             );
        //           }}
        //         />
        //         <div
        //           className={`${styles.customCrossPosition} ${styles.setWidth}`}
        //           onClick={() => {
        //             // dispatch(
        //             //   deleteContact({
        //             //     id: id as string,
        //             //     type: "phone",
        //             //     idx: props.idx,
        //             //   })
        //             // );
        //             removeField(field, "phoneNumber");
        //           }}
        //         >
        //           <OverlayTrigger
        //             placement="top"
        //             overlay={
        //               <Tooltip className={`${styles.toolTipCustom}`}>
        //                 Remove
        //               </Tooltip>
        //             }
        //           >
        //             {({ ref, ...triggerHandler }) => (
        //               <div
        //                 className={` `}
        //                 {...triggerHandler}
        //                 ref={ref}
        //               >
        //                 <div
        //                   className={`d-flex justify-content-center align-items-center ${styles.clearText}`}
        //                 >
        //                   <i className="fa-solid fa-xmark"></i>
        //                 </div>
        //               </div>
        //             )}
        //           </OverlayTrigger>
        //         </div>
        //       </div>
        //     </div>

        //     {/* <div className="dropdown position-relative"></div> */}
        //   </div>
        // );
      })}
      <span>
        <AddMore
          type="Number"
          isValid={isAddButtonEnabled}
          onClick={() => {
            if (!isAddButtonEnabled) {
              return;
            }
            addField(
              { value: { countryCode: "91", phoneNumber: "" } },
              "phoneNumber",
            );
          }}
          isVisible={(fields.get("phoneNumber")?.length ?? 0) > 0}
        />
      </span>
    </>
  );
}

export default PhoneNumber;
