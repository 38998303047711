import { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import ReturnDiscardModal from "src/components/AutomationComponents/ReturnDiscardModal/ReturnDiscardModal";
import styles from "./DiscardAndSave.module.scss";

function DiscardAndSave({
  onSave,
  saveFailed,
  showLoading,
  onDiscard,
  showError = false,
}: {
  onSave: () => void;
  showLoading?: boolean;
  onDiscard: () => void;
  saveFailed?: boolean;
  showError?: boolean;
}) {
  const [showModal, setModalShow] = useState(false);
  const handleClose = () => setModalShow(false);

  return (
    <div
      className={`d-flex justify-content-end flex-column align-items-end py-3 mt-0 mt-md-0 ${styles.actionBox}`}
    >
      <div>
        <button
          className={`px-3 py-2 ${styles.backBtn} me-2`}
          onClick={() => setModalShow(true)}
        >
          Discard Changes
        </button>

        <Modal
          className={`shadow-sm p-3 rounded`}
          show={showModal}
          onHide={handleClose}
          dialogClassName={`${styles.modalDialog2}`}
          contentClassName={`${styles.modalContent2}`}
          backdropClassName={`${styles.modalBackDrop}`}
          enforceFocus={false}
          keyboard={false}
        >
          <ReturnDiscardModal
            heading={`Do you want to discard the changes`}
            subText={`This will discard all the changes made`}
            onDiscard={onDiscard}
            onHide={handleClose}
          />
        </Modal>
        <button
          disabled={showLoading || showError}
          className={`px-3 py-2 me-4 ${styles.saveBtn}`}
          onClick={onSave}
        >
          {showLoading === true ? (
            <Spinner
              className={`m-auto`}
              animation="border"
              size="sm"
              color="grey"
            />
          ) : (
            <span className="">
              {saveFailed && saveFailed === true ? "Saving failed" : "Save"}
            </span>
          )}
        </button>
      </div>
      <div>
        {saveFailed && saveFailed === true && (
          <div className={`${styles.errorText} mt-1 me-4`}>
            Saving failed. Please review the errors
          </div>
        )}
      </div>
    </div>
  );
}

export default DiscardAndSave;
