import React from "react";
import ThemeBox from "./Children/ThemeBox/ThemeBox";
import ThemeSetting from "./Children/ThemeSetting/ThemeSetting";
import styles from "./ThemeSelection.module.scss";
import { useNavigate } from "react-router-dom";

const ThemeSelection = () => {
  const navigate = useNavigate();
  return (
    // This component is used to display the theme selection options for the helpcenter page. // It includes the ThemeBox component to show the available themes and the ThemeSetting component to allow users to customize the selected theme.
    <div className={`w-100 pt-4 px-2 px-md-3 ${styles.themeWrapper}`}>
      <div className="mb-4 d-flex">
        <div
          style={{
            zIndex: 5,
            position: "relative",
          }}
        >
          <div
            className={`d-flex justify-content-center align-items-center ${styles.backChat}`}
            onClick={() => navigate(-1)}
          >
            <i className="fa-solid fa-arrow-left"></i>
          </div>
        </div>
        <div>
          <h3 className={`mb-0 ${styles.heading} saufter_h3`}>
            Style Helpcenter page / Theme Selection
          </h3>
          <p className={`${styles.subHeading} saufter_subHeading`}>
            Let customers style the help center right inside your chat widget
            instead of starting a conversation.
          </p>
        </div>
      </div>
      <div
        className={`d-block d-md-flex  ${styles.selectionWrapper} justify-content-between`}
      >
        <ThemeBox />
        <ThemeSetting />
      </div>
    </div>
  );
};

export default ThemeSelection;
