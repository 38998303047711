import { forwardRef, useContext, useRef, useState, useMemo } from "react";
import ActionButton from "src/components/ActionButton";
import {
  getTotalActiveFiltersAppliedCountSelector,
  getTotalFiltersAppliedCountSelector,
} from "src/store/slices/ticketFilters/ticketFilters.slice";
import { useAppSelector } from "src/store/store";
import { FilterToggleStateContext } from "../../Filter";
import styles from "./FilterToggle.module.scss";
import log from "loglevel";

const FilterToggle = ({ children, onClick }: any, ref: any) => {
  const [toggle, setToggle] = useState(false);
  const [btnState, setBtnState] = useState("idle");
  const { toggleState } = useContext(FilterToggleStateContext);
  // const totalFiltersAppliedCount = useAppSelector(
  //   getTotalFiltersAppliedCountSelector
  // );
  const totalActiveFiltersAppliedCount = useAppSelector(
    getTotalActiveFiltersAppliedCountSelector
  );
  const btnRef = useRef(ref);

  const handleOnClick = (e: any) => {
    e.preventDefault();
    onClick(e);
    setToggle(!toggle);
  };

  return (
    <ActionButton
      ref={ref}
      active={totalActiveFiltersAppliedCount !== 0}
      onClick={handleOnClick}
      className={`mx-md-1`}
    >
      <span>
        <i className={`fa fa-filter me-2`} />
      </span>
      Filter
      <span>
        {totalActiveFiltersAppliedCount === 0 ? (
          <i
            className={`fa-solid fa-caret-${toggleState ? "up" : "down"} ms-2`}
          />
        ) : (
          <span className={`ms-2 me-0 ${styles.badge}`}>
            {totalActiveFiltersAppliedCount}
          </span>
        )}
      </span>
      {children}
    </ActionButton>
  );
};

export default forwardRef(FilterToggle);
