import {axiosJSON} from "src/globals/axiosEndPoints";

export interface IMergeTickets{
  mergedIntoTicket: number | string,
  mergedTicketIds: Array<number | string>,
  // note?: string | null,
  // mergedTicketsNote?: string | null,
}

export async function mergeTickets(
  servicePayload: IMergeTickets,
  uploadProgress?: (progress: number) => void
  ){
  // if(servicePayload.note == null){
  //   delete servicePayload.note;
  // }
  // if(servicePayload.mergedTicketsNote == null){
  //   delete servicePayload.mergedTicketsNote;
  // }
  const { data } = await axiosJSON.post(
    `/api/ticket/mergeTickets`,
    servicePayload,
    {
      onUploadProgress: (progressEvent: any) => {
        if (uploadProgress) {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          uploadProgress(progress);
        }
      }
    }
  );

  if(data.err === true){
    throw new Error(data.msg as string)
  }
  return data;
}