import styles from "./ShowOtherFiles.module.scss";
import ImgPdf from "src/assets/images/attachmentTypePdf.png";
import ImgZip from "src/assets/images/attachmentTypeZip.png";
import ImgOtherDoc from "src/assets/images/attachmentTypeOtherDocs.png";

import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import {
  deleteAttachment,
  DeleteAttachmentParam,
} from "src/services/Attachment/deleteAttachment";
import {
  STATUS_UPLOAD,
  TinyMCEUploadFile,
} from "src/interfaces/TinyMCEContainer/ITinyMCEContainer";
import { getTypeFromFileName } from "src/utils/utils";

interface Props {
  file: TinyMCEUploadFile;
  onDeleteFile: Function;
}
function ShowOtherFiles({ file, onDeleteFile }: Props) {
  const [isUploading, setIsUploading] = useState<boolean>(true);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  useEffect(() => {
    if (file.status === STATUS_UPLOAD.success) {
      // file is uploaded
      setIsUploading(false);
    }
  }, [file.status]);

  const getFileType = (file: TinyMCEUploadFile) => {
    let fileType = "";
    if (file.file && file.file.type) {
      fileType = file.file.type;
    } else if (file && file.attachmentType) {
      fileType = file.attachmentType;
    }
    return fileType;
  };

  const openFileInNewTab = () => {
    if (file.attachmentURL) { // Checks if the file has an attachment URL
      window.open(file.attachmentURL, "_blank"); // Opens the file in a new tab using the attachment URL
    } else {
      if (!file.file) { // Checks if the file is not present
        return; // Returns if the file is not present
      }
      const url = URL.createObjectURL(file.file); // Creates a URL for the file
      window.open(url, "_blank"); // Opens the file in a new tab using the URL
    }
  };

  return (
    <>
      <div
        className={`d-flex flex-row ${styles.showOtherFilesDiv}`}
        onClick={openFileInNewTab}  // Opens the file in a new tab
        role="button" // Setting role as button
      >
        <div
          className={`d-flex flex-column justify-content-center p-2  ${styles.fileTypeIconDiv}`}
        >
          <img
            className={`${styles.fileTypeIcon}`}
            src={
              getFileType(file).includes("application/x-zip-compressed")
                ? ImgZip
                : getFileType(file).includes("application/pdf")
                ? ImgPdf
                : ImgOtherDoc
            }
          />
        </div>
        <div className={`d-flex flex-column p-1`}>
          <div className={`d-flex flex-row ${styles.fileNameTxt}`}>
            {file.file?.name}
          </div>
          <div className={`d-flex flex-row ${styles.fileFormatTxt}`}>
            {getFileType(file).includes("application/x-zip-compressed")
              ? "ZIP"
              : getFileType(file).includes("application/pdf")
              ? "PDF"
              : getTypeFromFileName(file.file?.name + "").toUpperCase()}
          </div>
        </div>
        {/* </div> */}
        <span
          className={`${styles.closeNProgress} ${
            isUploading === true && isHovered === false
              ? styles.progressBadgeBg
              : styles.CloseBadgeBg
          }`}
          onMouseOver={() => {
            setIsHovered(true);
          }}
          onMouseLeave={() => {
            setIsHovered(false);
          }}
          onClick={
            // isUploading === false ? (e) => removeAttachment(e) : undefined
            (e) => {
              e.stopPropagation();  // Preventing event bubbling
              onDeleteFile(file); 
            }
          }
        >
          {isUploading === false || isHovered === true ? (
            <i className={`fa-solid fa-times ${styles.clossIcon}`}></i>
          ) : (
            <Spinner
              className={`my-auto mx-1 ${styles.spinner}`}
              animation="border"
              // variant="primary"
              // style={{width:"12px", height:"12px"}}
              // style={{color: "#0B68BB", width:"12px", height:"12px"}}
              // size="sm"
            />
          )}
        </span>
      </div>
    </>
  );
}

export default ShowOtherFiles;
