import { axiosJSON } from "src/globals/axiosEndPoints";


interface ArticleData {
    articleId : number|string,
    articleName : string,
    integrationId : number|string,
    articleStatus : "public"|"draft",
    createdAt : Date,
    createdAtGmt : Date
}


export interface IArticleCategoryData{
    categoryId : number|string,
    categoryName : string,
    integrationId : number| string,
    articles : Array<ArticleData>
}

export interface getAllArticleCategory {
  categories: { [categoryId: string | number]: IArticleCategoryData };
  categoryIds: Array<number | string>;
  metaData: {
    count: number;
    total: number;
  };
}

export interface GetAllArticleCategoriesParams {
  searchTerm?: string,
  integrationId: number|string,
  start: number;
  limit: number;
}

export async function getAllArticleCategory(params: GetAllArticleCategoriesParams) {
  const { data: res }: any = await axiosJSON.post(
    "/api/chatSetting/article/getAllCategory",
    params
  );

  if (res.error === true) {
    throw new Error(res.message as string);
  }

  const ret: getAllArticleCategory = {
    categories: {},
    categoryIds: [],
    metaData: {
      count: 0,
      total: 0
    }
  };
  ret.metaData.count = res.count;
  ret.metaData.total = res.total;
  res.data.forEach((category: IArticleCategoryData) => {
    ret.categories[category.categoryId] = category;
    ret.categoryIds.push(category.categoryId);
  });
//   console.log("getAllRoutingRules service ret:: ", JSON.stringify(ret));
  return ret;
}
