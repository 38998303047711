import { axiosJSON } from "src/globals/axiosEndPoints";

/**
 * Represents a segment with a name and a key.
 */
export interface Segment {
  name: string; // New (0-1 weeks) etc
  /**
   * To show in tooltip.
   */
  description: string;
  /**
   * segmentId for default or custom segment.
   */
  segmentId: string;
}

/**
 * Represents a group of segments with an optional default key.
 */
export interface SegmentGroup {
  name: string; // All, Lifecycle etc
  /**
   * segmentId sent in group only if segments array is not available for that group.
   * If both segments array and key are not sent, it means it is a custom segment group.
   */
  segmentId?: string;
  /**
   * Segments Array
   */
  segments?: Array<Segment>;
}

/**
 * Represents all segment types with their groups, name, and a unique segment type identifier.
 */
export interface IAllSegmentType {
  segmentGroups: Array<SegmentGroup>;
  name: string; // Company, People etc
  segmentType: string; // Must be unique for all segment types
  defaultSegmentIds: Array<string>;
}

/**
 * Represents the normalized structure of all segment types data.
 */
export interface IAllSegmentTypesData {
  allSegments: Record<string, IAllSegmentType>;
  allSegmentTypes: string[];
}

/**
 * Fetches the segment types from the server.
 *
 * @returns A promise that resolves to the normalized segment types data.
 * @throws Will throw an error if the server response contains an error.
 */
const getSegmentTypesService = async (): Promise<IAllSegmentTypesData> => {
  // `/api/segment/getSidebarData`
  const { data: res } = await axiosJSON.get(`/api/segment/getSegmentTypes`);

  if (res.error || res.err) {
    throw new Error(res.msg ?? res.message ?? "Something Unexpected Occurred!");
  }

  return normalizeData(res.data as IAllSegmentType[]);
};

/**
 * Normalizes the segment types data to a structured format.
 *
 * @param data - The array of segment types data.
 * @returns The normalized segment types data.
 */
const normalizeData = (data: IAllSegmentType[]): IAllSegmentTypesData => {
  const ret: IAllSegmentTypesData = {
    allSegments: {},
    allSegmentTypes: [],
  };

  // Iterate over each value in the data array
  for (const value of data) {
    // Convert the segmentType to a string and assign it back to the object
    value.segmentType = value.segmentType + "";

    // Add the value to the allSegments object, using the segmentType as the key
    ret.allSegments[value.segmentType] = value;

    // Add the segmentType to the allSegmentTypes array
    ret.allSegmentTypes.push(value.segmentType);

    // Initialize the defaultSegmentIds array for the current value
    value.defaultSegmentIds = [];

    // Iterate over each segmentGroup in the current value's segmentGroups array
    for (let i = 0; i < value.segmentGroups.length; i++) {
      // Get the current segmentGroup
      const segmentGroup = value.segmentGroups[i];

      // If the segmentGroup has a segmentId, convert it to a string and add it to defaultSegmentIds
      if (segmentGroup.segmentId) {
        segmentGroup.segmentId = segmentGroup.segmentId + "";
        value.defaultSegmentIds.push(segmentGroup.segmentId);
      }

      // If the segmentGroup has a segments array and it is not empty, iterate over its elements
      if (segmentGroup.segments?.length) {
        for (let j = 0; j < segmentGroup.segments.length; j++) {
          // Get the current segment
          const segment = segmentGroup.segments[j];

          // If the segment has a segmentId, convert it to a string and add it to defaultSegmentIds
          if (segment.segmentId) {
            segment.segmentId = segment.segmentId + "";
            value.defaultSegmentIds.push(segment.segmentId);
          }
        }
      }
    }
  }

  return ret;
};

export default getSegmentTypesService;
