import { axiosJSON } from "src/globals/axiosEndPoints";

export interface MarkLiveChatMessageAsSeenParams{
  chatId: number|string;
  lastMessageId: number|string;
}

export async function markLiveChatMessageAsSeen(payload: MarkLiveChatMessageAsSeenParams) {
  const { data, status } = await axiosJSON.post(
    `/api/liveChat/agent/markMessageAsSeen`,
    payload
  );
  
  if (data.error === true) {
    throw new Error(data.message as string);
  }
// console.log(normalizeData);
  return data.data;
}


