import { useState, createContext } from "react";
import { Dropdown } from "react-bootstrap";
import { useAppSelector } from "src/store/store";
import MoreColsMenu from "./children/MoreColsMenu";
import MoreColsToggle from "./children/MoreColsToggle";
import styles from "./MoreCols.module.scss";

export const MoreColsToggleStateContext = createContext<any>({});

function MoreCols() {
  const ticketsLoadingState = useAppSelector(
    (state) => state.ticket.ticketsLoadingState
  );
  const [toggleState, setToggleState] = useState(false);

  const handleOnToggle = (isOpen: boolean) => {
    setToggleState(isOpen);
  };

  return (
    <>
      <MoreColsToggleStateContext.Provider
        value={toggleState && ticketsLoadingState !== "pending"}
      >
        <Dropdown onToggle={handleOnToggle} id="moreColsBtn">
          <Dropdown.Toggle
            as={MoreColsToggle}
            id="dropdown-custom-components"
          />
          <Dropdown.Menu as={MoreColsMenu} className={styles.dropdownMenu} />
        </Dropdown>
      </MoreColsToggleStateContext.Provider>
    </>
  );
}

export default MoreCols;
