import {useMemo} from "react";
import {Spinner} from "react-bootstrap";

function useArticleLoader(showLoader: boolean, className?: string) {
  return useMemo(() => {
    if (showLoader) {
      return (
        <div className={`${className} m-auto `}>
          <Spinner
            animation="border"
            variant="primary"
            size="sm"
            className={`d-block m-auto mb-2`}
          />
          <span>Loading articles...</span>
        </div>
      );
    } else {
      return (
        <div>
          <Spinner animation="border" variant="secondary" />
        </div>
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showLoader]);
}

export default useArticleLoader;
