import styles from "./ToastModals.module.scss";
import done from "src/assets/images/done2.gif";
import successImg from "src/assets/images/successMsg.png";
interface Props {
  onHide: () => void;
  callAgain: () => void;
  isLiveChatSetup?: boolean;
}

function Installation({ onHide, callAgain, isLiveChatSetup = false }: Props) {
  return (
    <div className="px-4">
      <div className={`${styles.topIcon} mt-5 mb-4 mx-auto`}>
        <img
          src={isLiveChatSetup ? successImg : done}
          className={`${styles.done}`}
          alt="success"
        />
      </div>
      <div className={`${styles.title} mx-auto`}>
        {`Sucessfully Installed!`}
      </div>
      <div className={`${styles.subtitle} mx-auto my-2`}>
        {`Saufter Live Chat Widget is successfully installed and working on your website.`}
      </div>
      <div className="d-flex justify-content-center mb-4 px-2">
        <button
          className={`px-4 py-1 ${styles.checkBtn}`}
          onClick={onHide}
          id="successBtn"
        >
          Ok
        </button>
      </div>
    </div>
  );
}

export default Installation;
