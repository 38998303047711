import {createAsyncThunk, createSelector, createSlice} from "@reduxjs/toolkit";
import {pushTheToast} from "src/containers/ToastContainer/ToastContainer";
import {AJAXSTATUS} from "src/globals/constants";
import {getAllCannedResponse} from "src/services/LiveChat/Settings/cannedResponses/getAllCannedResponse";
import cannedResponseExtraReducers from "./cannedResponse.extraReducers";
import cannedResponseReducers from "./cannedResponse.reducers";
import cannedResponseThunks from "./cannedResponse.thunks";

interface CannedResponseFilters {
  searchValue: string;
}

export interface ICannedResponses extends getAllCannedResponse {
  cachedCannedResponse: {
    [page: number]: string[];
  };
  cannedResponseLimit: number;
  filters: CannedResponseFilters;
  cannedResponseAjaxStatus: AJAXSTATUS;
  addCannedResponseAjaxStatus: AJAXSTATUS;
  deleteCannedResponseAjaxStatus: AJAXSTATUS;

  cannedResponsePerPageLimit: number;
  cannedResponseCurrentPage: number;
  cannedResponseTotalPages: number;
}

// to fetch all the cannedResponse
export const fetchAllCannedResponse = createAsyncThunk(
  "cannedResponse/fetchAllCannedResponse",
  cannedResponseThunks.fetchAllCannedResponseThunk
);
export const addCannedResponse = createAsyncThunk(
  "cannedResponse/addCannedResponse",
  cannedResponseThunks.addCannedResponseThunk
);
export const editCannedResponse = createAsyncThunk(
  "cannedResponse/editCannedResponse",
  cannedResponseThunks.editCannedResponseThunk
);
export const deleteCannedResponse = createAsyncThunk(
  "cannedResponse/editCannedResponse",
  cannedResponseThunks.deleteCannedResponseThunk
);

export const fetchAllCannedResponseInfiScroll = createAsyncThunk(
  "cannedResponse/fetchAllCannedResponseInfiScroll",
  cannedResponseThunks.fetchAllCannedResponseInfiScrollThunk
);

export const initialState: ICannedResponses = {
  cannedResponses: {},
  cannedResponseIdList: [],
  metaData: {
    count: 0,
    total: null,
  },
  cachedCannedResponse: {
  },
  cannedResponseLimit: 25,
  filters: {
    searchValue: "",
  },
  cannedResponseAjaxStatus: "pending",
  addCannedResponseAjaxStatus: "idle",
  deleteCannedResponseAjaxStatus: "idle",

  cannedResponseCurrentPage: 1,
  cannedResponseTotalPages: 0,
  cannedResponsePerPageLimit: 4,
};

export const cannedResponseSlice = createSlice({
  name: "cannedResponses",
  initialState,
  reducers: cannedResponseReducers,
  extraReducers: (builder) => {
    builder.addCase(
      fetchAllCannedResponse.fulfilled,
      cannedResponseExtraReducers.fetchAllCannedResponseFulfilled
    );
    builder.addCase(fetchAllCannedResponse.pending, (state) => {
      state.cannedResponseAjaxStatus = "pending";
    });
    builder.addCase(fetchAllCannedResponse.rejected, (state) => {
      state.cannedResponseAjaxStatus = "rejected";
      pushTheToast({
        position: "top-right",
        type: "danger",
        text: "Something went wrong!",
      });
    });
    builder.addCase(
      fetchAllCannedResponseInfiScroll.fulfilled,
      cannedResponseExtraReducers.fetchAllCannedResponseInfiScrollFulfilled
    );
    builder.addCase(fetchAllCannedResponseInfiScroll.pending, (state) => {
      state.cannedResponseAjaxStatus = "pending";
    });
    builder.addCase(fetchAllCannedResponseInfiScroll.rejected, (state) => {
      state.cannedResponseAjaxStatus = "rejected";
      pushTheToast({
        position: "top-right",
        type: "danger",
        text: "Something went wrong!",
      });
    });
    builder.addCase(
      addCannedResponse.fulfilled,
      cannedResponseExtraReducers.addCannedResponseFulfilled
    );
    
    builder.addCase(addCannedResponse.rejected, (state) => {
      state.addCannedResponseAjaxStatus = "rejected";
      pushTheToast({
        position: "top-right",
        type: "danger",
        text: "Something went wrong!",
      });
    });
    builder.addCase(addCannedResponse.pending, (state) => {
      state.addCannedResponseAjaxStatus = "pending";
    });
  },
});

export const {
  resetCannedResponse,
  setCannedResponseSearchValue,
  resetCannedResponseSearchValue,
  setCurrentPage,
} = cannedResponseSlice.actions;

export default cannedResponseSlice.reducer;
