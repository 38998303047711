import React, { useContext, useMemo, useReducer } from "react";
import { GlobalFilters } from "./useReportFilters";

export interface SegmentFilter {
  name: string;
  filters: GlobalFilters;
}

interface ReportSegmentFiltersState {
  allSegmentFilters: Array<SegmentFilter>;
}

const reportSegmentFilters: ReportSegmentFiltersState = {
  allSegmentFilters: [],
};

const reducerMethod = (
  state: ReportSegmentFiltersState,
  [action, payload]: [
    (value: ReportSegmentFiltersState, payload?: any) => void,
    undefined | any
  ]
) => {
  action(state, payload);
  return { ...state };
};

const ReportSegmentFiltersContext = React.createContext([
  JSON.parse(JSON.stringify(reportSegmentFilters)) as ReportSegmentFiltersState,
  () => {},
] as [
  ReportSegmentFiltersState,
  React.Dispatch<
    [(value: ReportSegmentFiltersState, payload?: any) => void, undefined | any]
  >
]);

export const useReportSegmentFiltersCreate = () => {
  const [state, dispatch] = useReducer(
    reducerMethod,
    JSON.parse(JSON.stringify(reportSegmentFilters))
  );
  return [state, dispatch, ReportSegmentFiltersContext.Provider] as [
    ReportSegmentFiltersState,
    typeof dispatch,
    typeof ReportSegmentFiltersContext.Provider
  ];
};

export const useReportSegmentFilters = () => {
  const [segmentFilters, dispatch] = useContext(ReportSegmentFiltersContext);

  return { segmentFilters, dispatch };
};

export const reportSegmentFiltersActions = {
  resetState: (state: ReportSegmentFiltersState) => {
    state.allSegmentFilters = [];
  },
  appendSegmentFilter: (
    state: ReportSegmentFiltersState,
    payload: SegmentFilter
  ) => {
    if (
      !state.allSegmentFilters
        .map((data) => data.name.toLowerCase().trim())
        .includes(payload.name.toLowerCase())
    ) {
      state.allSegmentFilters = [...state.allSegmentFilters, payload];
    }
  },
  clearOneAppliedFilter: (
    state: ReportSegmentFiltersState,
    payload: { key: keyof GlobalFilters; index: number }
  ) => {
    if (state.allSegmentFilters[payload.index]?.filters) {
      delete state.allSegmentFilters[payload.index].filters[payload.key];
      if (
        Object.keys(state.allSegmentFilters[payload.index].filters).length === 0
      ) {
        state.allSegmentFilters = state.allSegmentFilters.filter(
          (data, index) => index !== payload.index
        );
      }
    }
  },
};
