import { useQuery } from "@tanstack/react-query";
import styles from "./NotificationModal.module.scss";
import { Modal, Dropdown } from "react-bootstrap";
import UserAvatar from "src/components/UserAvatar";
import getNotificationPreviewService from "src/services/report/getNotificationPreview.service";
import { ReportNotification } from "../../hooks/notifications/useNotifications";
import Loader from "src/components/Loader";
import AxiosImg from "src/components/AxiosImg";
import { CurrentStatusEndPoints } from "src/services/report/reportTicketList.service";
import closeModal from "src/assets/images/closePreviewHtml.png";
interface HookProp {
  notificationData: ReportNotification;
  showModal: boolean;
  context?: CurrentStatusEndPoints;
}

/**
 * Custom hook for Getting Notification preview
 */
const useNotificationPreview = ({
  notificationData,
  showModal,
  context,
}: HookProp) => {
  const { data: notificationPreview, status } = useQuery(
    ["report/getNotificationPreview", notificationData, showModal, context],
    {
      queryFn: () =>
        getNotificationPreviewService({
          id: notificationData.id,
          context: notificationData.id === "new" ? context : undefined,
        }),
      staleTime: 600000,
      cacheTime: 600000,
      enabled: showModal,
    },
  );

  return { status, notificationPreview };
};

interface Props extends HookProp {
  showModal: boolean;
  onHide: () => void;
}

// Functional component for Notification Modal
const NotificationModal = ({
  showModal,
  onHide,
  notificationData,
  context,
}: Props) => {
  // Custom hook for Notifications Preview data handling
  const { status, notificationPreview } = useNotificationPreview({
    notificationData: notificationData,
    showModal,
    context,
  });

  return (
    <Modal
      backdropClassName={`${styles.modalBack}`}
      show={showModal}
      onHide={onHide}
      dialogClassName={`${styles.modalDialog}`}
      contentClassName={`${styles.modalContent}`}
    >
      <>
        {/* Close Modal */}
        <div className="d-flex flex-row-reverse pt-3 px-3">
          <div
            className={`${styles.closeModal}`}
            onClick={onHide}
          >
            <span className="cursor-pointer">
              <img
                src={closeModal}
                alt=""
                className={`${styles.closeModal}`}
              />
            </span>
          </div>
        </div>
        {/* Main Wrapper */}
        <div className={`${styles.wrapper} d-flex flex-column`}>
          {/* Bar Div */}
          <div className={`d-flex align-items-center ps-4 ${styles.appBar}`}>
            <div className="d-flex align-items-center">
              <div className={`${styles.notifyDots}`}></div>
              <div className={`${styles.notifyDots}`}></div>
              <div className={`${styles.notifyDots}`}></div>
            </div>
          </div>
          {/* Content Div */}
          {status === "loading" ? (
            <div className="my-5">
              <Loader />
            </div>
          ) : status === "error" ? (
            <div className="h-100 d-flex align-items-center justify-content-center">
              <span className="text-danger">Error Loading Preview...</span>
            </div>
          ) : (
            <div>
              {/* User Information */}
              <div className={`d-flex ${styles.userInfoContainer}`}>
                {/* User Profile Image */}
                <div className={styles.userImg}>
                  {notificationPreview?.imageUrl &&
                  notificationPreview.imageUrl.trim().length !== 0 ? (
                    <AxiosImg
                      className={`${styles.profileImg}`}
                      url={notificationPreview.imageUrl}
                    />
                  ) : (
                    <UserAvatar
                      name={notificationPreview?.name ?? "NA"}
                      size={30}
                    />
                  )}
                </div>
                {/* User Info */}
                <div className={`${styles.userInfo}`}>
                  <div className="">
                    <p className="mb-1">
                      <span className={styles.userName}>
                        {notificationPreview?.name}
                      </span>
                      <span>{` <${notificationPreview?.email}>`}</span>
                    </p>
                    <p className="mb-0 d-flex align-items-center">
                      <span className="d-block">to me</span>
                      <span className="px-1">
                        <Dropdown>
                          <Dropdown.Toggle
                            id="dropdown-basic"
                            as="span"
                            className={`cursor-pointer ${styles.dropBtn}`}
                          ></Dropdown.Toggle>
                        </Dropdown>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              {/* IFrame for rendering html */}
              <div className={`${styles.IFrameContainer}`}>
                <iframe
                  title="Preview"
                  // src="http://localhost:3031/notification-preview.html"
                  srcDoc={notificationPreview?.content}
                  width="100%"
                  height="100%"
                  loading="lazy"
                ></iframe>
              </div>
            </div>
          )}
        </div>
      </>
    </Modal>
  );
};

// Exporting the Notification Modal component
export default NotificationModal;
