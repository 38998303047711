import { axiosJSON } from "src/globals/axiosEndPoints";
import { KeyExpiresAt } from "./createSnooze";

export type SnoozeDateOptions = Array<{
  name: string;
  key: KeyExpiresAt;
  dateTimeString: string;
  showDateTimePicker: boolean;
}>;

const opt: SnoozeDateOptions = [
  {
    name: "Till Customer Replies",
    key: "till-customer-replies",
    dateTimeString: "",
    showDateTimePicker: false
  },
  {
    name: "1 Hour",
    key: "1-hour",
    dateTimeString: "Thu 12 @ 12:44 PM",
    showDateTimePicker: false
  },
  {
    name: "3 Hours",
    key: "3-hours",
    dateTimeString: "Thu 12 @ 02:44 PM",
    showDateTimePicker: false
  },
  {
    name: "6 Hours",
    key: "6-hours",
    dateTimeString: "Thu 12 @ 05:44 PM",
    showDateTimePicker: false
  },
  {
    name: "1 Day",
    key: "1-day",
    dateTimeString: "Fri 13 @ 11:44 AM",
    showDateTimePicker: false
  },
  {
    name: "3 Days",
    key: "3-days",
    dateTimeString: "Sun 15 @ 11:44 AM",
    showDateTimePicker: false
  },
  {
    name: "7 Days",
    key: "7-days",
    dateTimeString: "Thu 19 @ 11:44 AM",
    showDateTimePicker: false
  },
  {
    name: "Custom date & time",
    key: "custom-datetime",
    dateTimeString: "",
    showDateTimePicker: true
  }
];

export async function getSnoozeDateOptions() {
  const { data: res } = await axiosJSON.post(
    `/api/ticket/snooze/snoozeDateOptions`
  );
  if (res.error === true) {
    throw new Error(res.message as string);
  }
  return res as SnoozeDateOptions;
}
