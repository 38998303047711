/**
 * This file defines a React component responsible for rendering form elements,
 * handling CRUD operations form elements, implementing drag-and-drop functionality,
 * and managing the process of saving and discarding changes in the bot followup email form.
 *
 * @author @yash-busibud
 * @author @navjyot-busibud
 */
import styles from "./FollowUpEmailModal.module.scss";
import EditEmail, {
  IVariableB,
} from "../ConfigureEmailModal/Children/EditEmail/EditEmail";
import EmailPreview from "../ConfigureEmailModal/Children/EmailPreview/EmailPreview";
import useFollowupEmailModal from "./useFollowupEmailModal";
import Loader from "src/components/Loader";
import { Spinner } from "react-bootstrap";
import useUserSettings from "../../../../../UserSetting/useUserSettings";
interface Props {
  onHide: () => void;
  profileId: number;
  data: {
    salutation: string;
    body: string;
  };
  handleEmailModalDataChange: (key: string, value: any) => void;
  variablesList?: {
    [x: string]: IVariableB;
  };
  variableIdsList?: string[];
  companyName: string;
}

const FollowUpEmailModal = ({
  onHide,
  profileId,
  data,
  handleEmailModalDataChange,
  variablesList,
  variableIdsList,
  companyName,
}: Props) => {
  const {
    handleSubmit,
    saveButtonText,
    showSaveLoader,
    onChange,
    showError,
    editedData,
  } = useFollowupEmailModal({
    profileId,
    data,
    handleEmailModalDataChange,
    variablesList,
    onHide,
  });

  return (
    <div className={`${styles.modalWrapper}`}>
      <div className="d-flex flex-shrink-0 align-items-center pt-3">
        <div>
          <div
            className={`${styles.closeModal}`}
            onClick={onHide}
          >
            <span className="cursor-pointer">
              <i className="fa-solid fa-xmark"></i>
            </span>
          </div>
        </div>
        <h3 className={`mb-0 ms-2 ${styles.heading}`}>
          Configure followup e-mail
        </h3>
      </div>
      {/* Content */}
      <div className={`${styles.mainContent}`}>
        <div
          className={`d-flex flex-column flex-md-row ${styles.contentWrapper}`}
        >
          {/* Left Section */}
          <div className={`p-4 ${styles.leftSection}`}>
            <h4 className={`${styles.header}`}>Edit email</h4>
            {/* Salutation */}
            <EditEmail
              heading="Salutation:"
              placeholder="Please enter the salutation bot will use"
              initialValue={data?.salutation ?? ""}
              onChange={(val) => onChange("salutation", val)}
              showErrors={showError}
              variablesList={variablesList}
              variableIdsList={variableIdsList}
            />
            {/* Body */}
            <EditEmail
              heading="Body:"
              placeholder="Please enter the body bot will use"
              initialValue={data?.body ?? ""}
              onChange={(val) => onChange("body", val)}
              showErrors={showError}
              variablesList={variablesList}
              variableIdsList={variableIdsList}
            />
          </div>
          {/* Right Section - Email Preview */}
          <div className={`${styles.rightSection} text-center`}>
            <EmailPreview
              model="followup"
              companyName={companyName}
              data={editedData}
              variableList={variablesList}
            />
          </div>
        </div>
      </div>
      <div
        className={`d-flex justify-content-end align-items-center px-3 ${styles.btnWrapper}`}
      >
        {/* Discard button */}
        <button
          className={`me-2 ${styles.discardBtn}`}
          onClick={onHide}
        >
          Discard changes
        </button>
        {/* Save Button */}
        <button
          className={`${styles.saveBtn}`}
          disabled={showSaveLoader || saveButtonText === "Saved!"}
          onClick={handleSubmit}
        >
          {showSaveLoader && (
            <Spinner
              className="my-auto mx-1"
              animation="border"
              color="white"
              size="sm"
            />
          )}
          <span>{saveButtonText}</span>
        </button>
      </div>
    </div>
  );
};

export default FollowUpEmailModal;
