import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";
import { AudienceConfigCondition } from "src/store/slices/botProfiles/botProfiles.declarations";

export interface operators {
  name: string;
  value: string;
}

export interface conditionType {
  conditionKey: number | string;
  conditionName: string;
  inputType: string;
  operators: operators[];
}

export interface GetConfigureAudiencePayload {
  id: number | string;
}

const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/botProfile/getConfigureAudience`
  : "/api/botProfile/getConfigureAudience";

export async function getConfigureAudience(
  payload: GetConfigureAudiencePayload
) {
  const { data: res } = await axiosJSON.post(apiEndPoint, payload);

  if (res.err) {
    throw res.msg;
  }

  return parseConfigData(res.data?.audienceConfig);
}

const parseConfigData = (data: Array<any>) => {
  console.log(data)
  return data.map((condition) => {
    return {
      id: condition.id,
      ruleType: "AND",
      conditionKey: condition.field,
      values: condition.value,
      operator: condition.compareType,
      isNewlyAdded: false,
      selectedTags: condition.selectedTags
    } as AudienceConfigCondition;
  });
};
