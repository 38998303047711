import { createAsyncThunk } from "@reduxjs/toolkit";
import { EReturnAutomationQuestionKeys } from "src/enums/EReturnAutomationQuestionKeys";
import {
  bulkStepUpdate,
  IReturnAutomationBulkStepUpdateParams,
  IStepData,
} from "src/services/UiAutomation/ReturnExchange/bulkStepUpdate";
import { fetchGetStep10Config } from "src/services/UiAutomation/ReturnExchange/Configuration/Step10/step10GetConfig";
import { fetchGetStep10ConditionsOptions } from "src/services/UiAutomation/ReturnExchange/Configuration/Step10/step10GetOptionConditions";
import { fetchStep10ConfigPost } from "src/services/UiAutomation/ReturnExchange/Configuration/Step10/step10PostConfig";
import { fetchGetStep11Config } from "src/services/UiAutomation/ReturnExchange/Configuration/Step11/step11GetConfig";
import { fetchStep11ConditionOptions } from "src/services/UiAutomation/ReturnExchange/Configuration/Step11/step11GetOptionConditions";
import { fetchStep11ConfigPost } from "src/services/UiAutomation/ReturnExchange/Configuration/Step11/step11PostConfig";
import { fetchGetStep12Config } from "src/services/UiAutomation/ReturnExchange/Configuration/Step12/step12GetConfig";
import { fetchStep12ConditionOptions } from "src/services/UiAutomation/ReturnExchange/Configuration/Step12/step12GetOptionConditions";
import { fetchStep12ConfigPost } from "src/services/UiAutomation/ReturnExchange/Configuration/Step12/step12PostConfig";
import { fetchGetStep13Config } from "src/services/UiAutomation/ReturnExchange/Configuration/Step13/step13GetConfig";
import { fetchGetStep13FormElementOptions } from "src/services/UiAutomation/ReturnExchange/Configuration/Step13/step13GetFormElementOptions";
import { fetchGetStep13ConditionsOptions } from "src/services/UiAutomation/ReturnExchange/Configuration/Step13/step13GetOptionsConditions";
import { fetchGetStep13ReturnReasonOptions } from "src/services/UiAutomation/ReturnExchange/Configuration/Step13/step13GetReturnReasonOptions";
import { fetchGetStep13ReturnTypeOptions } from "src/services/UiAutomation/ReturnExchange/Configuration/Step13/step13ReturnTypeOptions";
import { fetchGetStep14Config } from "src/services/UiAutomation/ReturnExchange/Configuration/Step14/step14GetConfig";
import { fetchGetStep14ConditionsOptions } from "src/services/UiAutomation/ReturnExchange/Configuration/Step14/step14GetOptionsConditions";
import { fetchGetStep15Config } from "src/services/UiAutomation/ReturnExchange/Configuration/Step15/step15GetConfig";
import { fetchGetStep15ConditionsOptions } from "src/services/UiAutomation/ReturnExchange/Configuration/Step15/step15GetOptionsConditions";
import {
  ConditionType,
  fetchGetStep9Config,
  Step9TabType,
  TabType,
} from "src/services/UiAutomation/ReturnExchange/Configuration/Step9/step9GetConfig";
import { fetchGetStep9ConditionsOptions } from "src/services/UiAutomation/ReturnExchange/Configuration/Step9/step9GetOptionsConditions";
import { fetchStep9ConfigPost } from "src/services/UiAutomation/ReturnExchange/Configuration/Step9/step9PostConfig";
import { RootState } from "src/store/store";
import {
  IResolutionOffersValue,
  IRestockingFee,
  IReturnReason,
} from "../retrunExchange.slice";
// import {
//   getStep12Config,
//   getStep12OptionConditons,
// } from "../step12Config.slice";
import { fetchRefundRequestFollowUpQuestions } from "src/services/UiAutomation/ReturnExchange/Configuration/AntiRefund/getFollowUpQuestion";
import { fetchGetStep12FormElementOptions } from "src/services/UiAutomation/ReturnExchange/Configuration/Step12/step12GetFormElementOptions";
import { fetchStep13ConfigPost } from "src/services/UiAutomation/ReturnExchange/Configuration/Step13/step13PostConfig";
import { getAllReturnReasonList } from "src/services/UiAutomation/ReturnExchange/Configuration/Step14/getAllReturnReasonList";
import { fetchStep14ConfigPost } from "src/services/UiAutomation/ReturnExchange/Configuration/Step14/step14PostConfig";
import { fetchStep15ConfigPost } from "src/services/UiAutomation/ReturnExchange/Configuration/Step15/step15PostConfig";
import { validateStep13 } from "../antiRefundOrderTypesConfig.slice";
import { validateStep10 } from "../step10Configuration.slices";
import { validateStep11 } from "../step11Config.slice";
import { validateStep12 } from "../step12Config.slice";
import { getStep13Config } from "../step13AskDetails.slice";
import { validateStep14 } from "../step14Configuration.slice";
import { validateStep15 } from "../step15Configuration.slice";
import { validateStep9 } from "../step9Configuration.slice";
import { updatePreviewDetails } from "../Helpers/returnPreview.helpers";
import { StepName } from "src/routes/Setting/children/MainSetting/children/SetRulesNew/childerns/childern/AddAutomations/WidgetPreviewSection/hooks/useReturnPreview";
import useGetAutomationData from "src/routes/Setting/children/MainSetting/children/SetRulesNew/childerns/childern/AddAutomations/WidgetPreviewSection/hooks/useGetAutomationData";
import {
  fetchGetAllProductTags,
  fetchGetAllShopifyProducts,
} from "../returnExchange.thunk";

//  Step 9
export const fetchStep9GetConfig = createAsyncThunk(
  "fetchStep9GetConfig",
  async (_: void, { getState }) => {
    //getting the redux state to get the active integration id of current segment
    const currentState = getState() as RootState;
    const integrationId = currentState.returnExchange.activeIntegrationId;

    const step9ConditionOptionsRes = await fetchGetStep9ConditionsOptions({
      integrationId,
    }); // async action

    const step9ConfigRes = await fetchGetStep9Config({ integrationId }); // async action

    const question1 =
      currentState.returnExchange.step9.stepData.stepQuestions[
        EReturnAutomationQuestionKeys.returnWindow
      ];
    const defaultReturnWindow = {
      id: "",
      ruleType: "AND",
      variableName: "Order Date",
      values: question1.value,
      operator: "within",
    };
    return { step9ConfigRes, step9ConditionOptionsRes, defaultReturnWindow };
  },
);

export function getValueFromConditionType(values: any) {
  let conditionValue: any = null;
  const keys = Object.keys(values);
  keys.forEach((key) => {
    switch (key) {
      case "codes": {
        conditionValue = values[key].currentValue;
        break;
      }

      case "countryName": {
        conditionValue = values[key].currentValue;
        break;
      }
      case "day_type": {
        if (conditionValue === null) {
          conditionValue = {};
        }
        conditionValue["day_type"] = values[key].currentValue;
        break;
      }
      case "days": {
        if (conditionValue === null) {
          conditionValue = {};
        }
        conditionValue["days"] = values[key].currentValue;
        break;
      }
      case "numbers": {
        conditionValue = values[key].currentValue;
        break;
      }
      case "tags": {
        conditionValue = values[key].currentValue;
        break;
      }
      case "amount": {
        if (conditionValue === null) {
          conditionValue = {};
        }
        conditionValue["value"] = values[key].currentValue;
        break;
      }
      case "currency": {
        if (conditionValue === null) {
          conditionValue = {};
        }
        conditionValue["currencyCode"] = values[key].currentValue;

        break;
      }
      case "items": {
        conditionValue = values[key].currentValue.map((value: any) => {
          if (value.id === undefined) {
            return value.toString();
          } else {
            return value.id.toString();
          }
        });

        break;
      }
      case "return_reason": {
        if (conditionValue === null) {
          conditionValue = {};
        }
        conditionValue["elementId"] = values[key].elementId;
        conditionValue["operator"] = values[key].operator;
        conditionValue["reasonId"] = values[key].reasonId;
        conditionValue["value"] = values[key].value;

        break;
      }
    }
  });

  return conditionValue;
}
interface fetchPostStepConfigPayload {
  callback?: Function;
}
export const fetchPostStep9Config = createAsyncThunk(
  "fetchPostStep9Config",
  async (
    payload: fetchPostStepConfigPayload | undefined = { callback: () => {} },
    { getState },
  ) => {
    const state = getState() as RootState;

    const integrationId = state.returnExchange.activeIntegrationId;
    const orderTypes = state.step9Configuration.step9Configuration.orderTypes;

    const tabType = orderTypes.map((orderType) => {
      const operatorSymbolMap =
        state.step9Configuration.step9Configuration.operatorSymbolMap;
      const conditionType =
        Object.entries(
          state.step9Configuration.step9Configuration.variableNames,
        ).find(
          ([key, variable]: any) =>
            variable === orderType.returnWindow?.variableName,
        ) ?? "";
      return {
        configTabId: Number(orderType.orderTypeId),
        configTabName: orderType.orderTypeName,
        notEligibleResponseMessage: orderType.messageForCustomer.value,
        conditions: orderType.conditions.map((condition) => {
          const conditionType =
            Object.entries(
              state.step9Configuration.step9Configuration.variableNames,
            ).find(
              ([key, variable]: any) => variable === condition.variableName,
            ) ?? "";

          return {
            conditionId: Number(condition.id),
            conditionTypeId:
              conditionType !== "" ? conditionType[0] : conditionType,
            conditionValue: getValueFromConditionType(condition.values),
            operator: Object.keys(operatorSymbolMap).find((key) => {
              if (
                (condition.operator === "are less than" ||
                  condition.operator === "is less than") &&
                key === "<"
              ) {
                return true;
              }
              return operatorSymbolMap[key] === condition.operator;
            }),
          } as ConditionType & { conditionId: number };
        }),
        returnWindow: {
          conditionId: 0,
          conditionTypeId: conditionType !== "" ? conditionType[0] : "",
          conditionValue: getValueFromConditionType(
            orderType.returnWindow?.values,
          ),
          operator: Object.keys(operatorSymbolMap).find((key) => {
            if (
              (orderType.returnWindow?.operator === "are less than" ||
                orderType.returnWindow?.operator === "is less than") &&
              key === "<"
            ) {
              return true;
            }
            return operatorSymbolMap[key] === orderType.returnWindow?.operator;
          }),
        } as ConditionType & { conditionId: number },
      } as Step9TabType;
    });

    await fetchStep9ConfigPost({ integrationId, tabType });

    setTimeout(() => {
      if (payload.callback) {
        payload.callback();
      }
    }, 0);

    // Refetch the data

    const step9ConfigRes = await fetchGetStep9Config({ integrationId }); // async action

    const question1 =
      state.returnExchange.step9.stepData.stepQuestions[
        EReturnAutomationQuestionKeys.returnWindow
      ];
    const defaultReturnWindow = {
      id: "",
      ruleType: "AND",
      variableName: "Order Date",
      values: question1.value,
      operator: "within",
    };
    return { step9ConfigRes, defaultReturnWindow };
  },
);

//  Step 10

export const fetchStep10GetConfig = createAsyncThunk(
  "fetchStep10GetConfig",
  async (payload, { dispatch, getState }) => {
    //getting the redux state to get the active integration id of current segment
    const currentState = getState() as RootState;
    const integrationId = currentState.returnExchange.activeIntegrationId;
    const step10ConditionOptionsRes = await fetchGetStep10ConditionsOptions({
      integrationId,
    }); // async action
    // if (step10ConditionOptionsRes) {
    //   dispatch(fetchGetAllShopifyProducts({ limit: 10, start: 0 }));
    // }
    const step10ConfigRes = await fetchGetStep10Config({ integrationId }); // async action

    const question1 =
      currentState.returnExchange.step9.stepData.stepQuestions[
        EReturnAutomationQuestionKeys.returnWindow
      ];
    const defaultConditionOrderDate = {
      id: "",
      ruleType: "AND",
      variableName: "Order Date",
      values: question1.value,
      operator: "within",
    };

    return {
      step10ConditionOptionsRes,
      step10ConfigRes,
      defaultConditionOrderDate,
    };
  },
);

export const fetchPostStep10Config = createAsyncThunk(
  "fetchPostStep10Config",
  async (
    payload: fetchPostStepConfigPayload | undefined = { callback: () => {} },
    { getState },
  ) => {
    const state = getState() as RootState;

    const integrationId = state.returnExchange.activeIntegrationId;
    const itemTypes = state.step10Configuration.itemTypes;

    const tabType = itemTypes.map((itemType) => {
      const operatorSymbolMap = state.step10Configuration.operatorSymbolMap;

      return {
        configTabId: Number(itemType.itemTypeId),
        configTabName: itemType.itemTypeName,
        notEligibleResponseMessage: itemType.messageForCustomer.value,
        conditions: itemType.conditions.map((condition) => {
          const selectedVariable = state.step10Configuration.variableNames.find(
            (variable: any) => variable.name === condition.variableName,
          );

          if (selectedVariable) {
            return {
              conditionId: Number(condition.id),
              conditionTypeId: selectedVariable.id,

              conditionValue: getValueFromConditionType(condition.values),
              operator: Object.keys(operatorSymbolMap).find((key) => {
                if (
                  (condition.operator === "are less than" ||
                    condition.operator === "is less than") &&
                  key === "<"
                ) {
                  return true;
                }
                return operatorSymbolMap[key] === condition.operator;
              }),
            } as ConditionType & { conditionId: number };
          } else {
            return {
              conditionId: Number(condition.id),
              conditionTypeId: -1,
            };
          }
        }),
      } as TabType;
    });

    await fetchStep10ConfigPost({ integrationId, tabType });
    setTimeout(() => {
      if (payload.callback) {
        payload.callback();
      }
    }, 0);

    // Refetch the data

    const step10ConfigRes = await fetchGetStep10Config({ integrationId }); // async action

    const question1 =
      state.returnExchange.step9.stepData.stepQuestions[
        EReturnAutomationQuestionKeys.returnWindow
      ];
    const defaultConditionOrderDate = {
      id: "",
      ruleType: "AND",
      variableName: "Order Date",
      values: question1.value,
      operator: "within",
    };

    return {
      step10ConfigRes,
      defaultConditionOrderDate,
    };
  },
);

//  Step 11
export const fetchStep11GetConfig = createAsyncThunk(
  "fetchStep11GetConfig",
  async (payload, { dispatch, getState }) => {
    //getting the redux state to get the active integration id of current segment
    const currentState = getState() as RootState;
    const integrationId = currentState.returnExchange.activeIntegrationId;

    const step11ConditionOptionsRes = await fetchStep11ConditionOptions({
      integrationId,
    }); // async action
    // if (step11ConditionOptionsRes) {
    //   dispatch(fetchGetAllShopifyProducts({ limit: 10, start: 0 }));
    // }
    const step11ConfigRes = await fetchGetStep11Config({ integrationId }); // async action

    const question5 =
      currentState.returnExchange.step11.stepData.stepQuestions[
        EReturnAutomationQuestionKeys.resolutionOffer
      ];

    return {
      step11ConditionOptionsRes,
      step11ConfigRes,
      defaultResolutionOfferValue: question5.value,
    };
  },
);

export const fetchPostStep11Config = createAsyncThunk(
  "fetchPostStep11Config",
  async (
    payload: fetchPostStepConfigPayload | undefined = { callback: () => {} },
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;

    const returnTypes = state.step11Config.returnTypes;

    const integrationId = state.returnExchange.activeIntegrationId;
    const tabType: any = returnTypes.map((returnTypes) => {
      const operatorSymbolMap = state.step11Config.operatorSymbolMap;

      return {
        configTabId: Number(returnTypes.returnTypeId),
        configTabName: returnTypes.returnTypeName,
        notEligibleResponseMessage: returnTypes.messageForCustomer.value,
        conditions: returnTypes.conditions.map(
          (condition: {
            variableName: any;
            id: any;
            values: any;
            operator: any;
          }) => {
            const selectedVariable = state.step11Config.variableNames.find(
              (variable: any) => variable.name === condition.variableName,
            );

            if (selectedVariable) {
              return {
                conditionId: Number(condition.id),
                conditionTypeId: selectedVariable.id,

                conditionValue: getValueFromConditionType(condition.values),
                operator: Object.keys(operatorSymbolMap).find((key) => {
                  if (
                    (condition.operator === "are less than" ||
                      condition.operator === "is less than") &&
                    key === "<"
                  ) {
                    return true;
                  }
                  return operatorSymbolMap[key] === condition.operator;
                }),
              } as ConditionType & { conditionId: number };
            } else {
              return {
                conditionId: Number(condition.id),
                conditionTypeId: -1,
              };
            }
          },
        ),
        returnTypes: {
          exchangeWithSameItems: returnTypes.ExchangeWithSameItem,
          exchangeWithOtherItems: returnTypes.ExchangeWithOtherItem,
          refundToStoreCredit: returnTypes.RefundtoStoreCredit,
          refundToOriginalPaymentMethod: returnTypes.RefundToOriginalPayment,
        },
      };
    });

    await fetchStep11ConfigPost({ integrationId, tabType });
    setTimeout(() => {
      if (payload.callback) {
        payload.callback();
      }
    }, 0);

    // Refetch data

    const step11ConfigRes = await fetchGetStep11Config({ integrationId }); // async action

    const question5 =
      state.returnExchange.step11.stepData.stepQuestions[
        EReturnAutomationQuestionKeys.resolutionOffer
      ];

    return {
      step11ConfigRes,
      defaultResolutionOfferValue: question5.value,
    };
  },
);

// Step 12
export const fetchStep12GetConfig = createAsyncThunk(
  "fetchStep12GetConfig",
  async (payload, { dispatch, getState }) => {
    //getting the redux state to get the active integration id of current segment
    const currentState = getState() as RootState;
    const integrationId = currentState.returnExchange.activeIntegrationId;

    const question1 =
      currentState.returnExchange.step9.stepData.stepQuestions[
        EReturnAutomationQuestionKeys.returnWindow
      ];

    const reasonList = currentState.returnExchange.step12.stepData
      .stepQuestions[EReturnAutomationQuestionKeys.returnReasonList]
      .value as IReturnReason[];

    const defaultConditionOrderDate = {
      id: "",
      ruleType: "AND",
      variableName: "Order Date",
      values: question1.value,
      operator: "within",
    };

    // const step12ConditionOptionsRes = await fetchStep12ConditionOptions({
    //   integrationId,
    // }); // async action

    // if (step12ConditionOptionsRes) {
    //   dispatch(fetchGetAllShopifyProducts({ limit: 10, start: 0 }));
    // }

    // const step12ConfigRes = await fetchGetStep12Config({ integrationId }); // async action

    // const formElementOptions = await fetchGetStep12FormElementOptions({
    //   integrationId,
    // });

    const [step12ConditionOptionsRes, step12ConfigRes, formElementOptions] =
      await Promise.all([
        fetchStep12ConditionOptions({ integrationId }),
        fetchGetStep12Config({ integrationId }),
        fetchGetStep12FormElementOptions({ integrationId }),
      ]);

    return {
      step12ConditionOptionsRes,
      step12ConfigRes,
      defaultConditionOrderDate,
      defaultConditionReasonsList: reasonList,
      formElementOptions,
    };
  },
);

export const fetchPostStep12Config = createAsyncThunk(
  "fetchPostStep12Config",
  async (
    payload: fetchPostStepConfigPayload | undefined = { callback: () => {} },
    { getState },
  ) => {
    const state = getState() as RootState;

    const integrationId = state.returnExchange.activeIntegrationId;
    const reasonTypes = state.step12Config.reasonTypes;

    const tabType: any = reasonTypes.map((reasonType) => {
      const operatorSymbolMap = state.step12Config.operatorSymbolMap;
      let reasonsPayload: any = [];
      // 03 Jan If explainWhyTheyReturn id "requiredAndCustom" or "required",then only include reasons in payload
      if (
        reasonType.explainWhyTheyReturn === "requiredAndCustom" ||
        reasonType.explainWhyTheyReturn === "required"
      ) {
        reasonsPayload = reasonType.reasons.map((reason) => {
          const newReason = {
            id: reason.isNew ? null : reason.id,
            value: reason.reason.value,
            moreQuestions: reason.reason.moreQuestions.map((question) => {
              const newQuestion = {
                elementTypeId: question.elementTypeId,
                elementId: question.isNewElement ? null : question.elementId,
                additionalOptions: question.additionalOptions.map((option) => {
                  if (option.new === true) {
                    return {
                      id: null,
                      value: option.value,
                    };
                  } else {
                    return {
                      id: option.id,
                      value: option.value,
                    };
                  }
                }),
                elementValue: question.elementValue,
              };
              return newQuestion;
            }),
          };

          return {
            ...newReason,
            moreQuestions:
              reasonType.explainWhyTheyReturn === "requiredAndCustom"
                ? newReason.moreQuestions
                : [], // 03 Jan If explainWhyTheyReturn id "requiredAndCustom",then only include morequestions in payload
          };
        });
      }

      // const reasonsPayload = reasonType.reasons.map((reason) => {
      //   const newReason = {
      //     id: reason.isNew ? null : reason.id, //Add null if it is a new reason otherwise send reason id
      //     value: reason.reason.value,
      //     moreQuestions: reason.reason.moreQuestions.map((question) => {
      //       const newQuestion = {
      //         elementTypeId: question.elementTypeId,
      //         elementId:  question.isNewElement ? null : question.elementId, //Add null if it is a new element otherwise send element id
      //         additionalOptions: question.additionalOptions.map((option) => {
      //           // Remove "new" and "option" properties from the condition
      //           if (option.new === true) {
      //             return {
      //               id: null,
      //               value: option.value, // Change "option" to "value"
      //             };
      //           } else {
      //             return {
      //               id: option.id,
      //               value: option.value, // Change "option" to "value"
      //             };
      //           }
      //         }),
      //         elementValue: question.elementValue,
      //       };
      //       return newQuestion;
      //     }),
      //   };

      //   return {
      //     ...newReason,
      //     moreQuestions: newReason.moreQuestions, // Change "reason.reason.moreQuestions" to "newReason.moreQuestions"
      //   };
      // });

      return {
        configTabId: Number(reasonType.reasonTypeId),
        configTabName: reasonType.reasonTypeName,

        conditions: reasonType.conditions.map(
          (condition: {
            variableName: any;
            id: any;
            values: any;
            operator: any;
          }) => {
            const selectedVariable = state.step12Config.variableNames.find(
              (variable: any) => variable.name === condition.variableName,
            );

            if (selectedVariable) {
              return {
                conditionId: Number(condition.id),
                conditionTypeId: selectedVariable.id,

                conditionValue: getValueFromConditionType(condition.values),
                operator: Object.keys(operatorSymbolMap).find((key) => {
                  if (
                    (condition.operator === "are less than" ||
                      condition.operator === "is less than") &&
                    key === "<"
                  ) {
                    return true;
                  }
                  return operatorSymbolMap[key] === condition.operator;
                }),
              } as ConditionType & { conditionId: number };
            } else {
              return {
                conditionId: Number(condition.id),
                conditionTypeId: -1,
              };
            }
          },
        ),
        reasons: reasonsPayload,
        explainWhyTheyReturn: reasonType.explainWhyTheyReturn ?? null, //03 Jan Adding "explainWhyTheyReturn" property as needed as per new xd
      };
    });
    await fetchStep12ConfigPost({ integrationId, tabType });
    setTimeout(() => {
      if (payload.callback) {
        payload.callback();
      }
    }, 0);

    // Refetch the data after updating it
    const step12ConfigRes = await fetchGetStep12Config({ integrationId });

    return {
      step12ConfigRes,
    };
  },
);

// Step 13, old one, ask details
export const fetchStep13GetConfig = createAsyncThunk(
  "fetchStep13GetConfig",
  async (payload, { dispatch, getState }) => {
    //getting the redux state to get the active integration id of current segment
    const currentState = getState() as RootState;
    const integrationId = currentState.returnExchange.activeIntegrationId;

    const ConditionResponse = await fetchGetStep13ConditionsOptions({
      integrationId,
    }); // async action
    const returnReason = await fetchGetStep13ReturnReasonOptions();
    const returnTypes = await fetchGetStep13ReturnTypeOptions();
    const formElementOptions = await fetchGetStep13FormElementOptions();

    if (ConditionResponse) {
      // dispatch(setStep13OptionConditons({ data: ConditionResponse })); // sync action
    }

    const res = await fetchGetStep13Config({ integrationId }); // async action
    if (res) {
      dispatch(getStep13Config({ data: res })); // sync action
    }
    return {
      returnTypes,
      returnReason,
      formElementOptions,
    };
  },
);

// for new step 13, anti refund strategies
export const fetchStep13GetConfigThunk = createAsyncThunk(
  "fetchStep13GetConfigThunk",
  async (payload, { dispatch, getState }) => {
    //getting the redux state to get the active integration id of current segment
    const currentState = getState() as RootState;
    // const integrationId = currentState.returnExchange.activeIntegrationId;
    const integrationId = currentState.returnExchange
      .activeIntegrationId as number;

    // const conditionResponse = await fetchGetStep13ConditionsOptions({
    //   integrationId,
    // }); // async action

    // console.log(conditionResponse);

    // if (ConditionResponse) {
    //   dispatch(setStep13OptionConditons({ data: ConditionResponse })); // sync action
    // }

    // const configRes = await fetchGetStep13Config({ integrationId }); // async action

    // const getRefundRequestList = await getAllReturnReasonList({
    //   integrationId,
    // }); //async action

    // if (res) {
    //   dispatch(getStep13Config({ data: res })); // sync action
    // }
    try {
      const [conditionResponse, configRes, getRefundRequestList] =
        await Promise.all([
          await fetchGetStep13ConditionsOptions({ integrationId }),
          await fetchGetStep13Config({ integrationId }),
          await getAllReturnReasonList({ integrationId }),
        ]);
      // Getting all tags and products to assign incase of ["*"]
      let allTags: any = currentState.returnExchange.getAllProductTags.data;
      let allItems: any = currentState.returnExchange.getAllShopifyProducts;
      if (allTags?.length == 0) {
        const productTags: any = await dispatch(
          fetchGetAllProductTags({ limit: 1000 }),
        );
        allTags = productTags?.payload?.data ?? [];
      }
      if (allItems?.length == 0) {
        const allShopifyProducts: any = await dispatch(
          fetchGetAllShopifyProducts({
            limit: 1000,
            searchTerm: "",
            start: 0,
          }),
        );
        allItems = allShopifyProducts.payload?.data ?? [];
      }

      return {
        conditionResponse,
        configRes,
        getRefundRequestList,
        allItems: allItems,
        allTags: allTags,
      };
    } catch (error) {
      console.error("Something Went Wrong");
      throw error;
    }
  },
);

export const fetchPostStep13Config = createAsyncThunk(
  "fetchPostStep13Config",
  async (
    payload: fetchPostStepConfigPayload | undefined = { callback: () => {} },
    { getState },
  ) => {
    const state = getState() as RootState;

    const integrationId = state.returnExchange.activeIntegrationId;
    const orderTypes =
      state.antiRefundOrderTypesConfig.antiRefundOrderTypesData
        .antiRefundOrderTypes;

    const tabType: any = orderTypes.map((orderType) => {
      const operatorSymbolMap =
        state.antiRefundOrderTypesConfig.antiRefundOrderTypesData
          .operatorSymbolMap;
      const reasonsPayload = orderType.refundRequests.reasons.map((reason) => {
        const newReason = {
          ...reason,
          // moreQuestions: reason.moreQuestions.map((question) => {
          //   const newQuestion = {
          //     elementTypeId: question.elementTypeId,
          //     additionalOptions: question.additionalOptions.map((option) => {
          //       console.log("option", option);
          //       // Remove "new" and "option" properties from the condition
          //       if (option.new === true) {
          //         return {
          //           id: null,
          //           value: option.option, // Change "option" to "value"
          //         };
          //       } else {
          //         return {
          //           id: option.id,
          //           value: option.option, // Change "option" to "value"
          //         };
          //       }
          //     }),
          //     elementValue: question.elementValue,
          //   };
          //   return newQuestion;
          // }),
        };

        return {
          ...newReason,
          // moreQuestions: newReason.moreQuestions, // Change "reason.reason.moreQuestions" to "newReason.moreQuestions"
        };
      });
      // Map and update the reasonsPayload
      const updatedReasonsPayload = reasonsPayload.map((reason) => {
        return {
          id: reason.id,
          scenarios: reason.scenarios.map((scenario) => {
            return {
              scenarioId: scenario.isNewScenario ? undefined : scenario.id,
              tags:
                scenario.itemsAndTagAllSelected === true
                  ? ["*"]
                  : (scenario.tags as string[]).filter(
                      (v) => v !== null && v !== undefined,
                    ),
              itemIds:
                scenario.itemsAndTagAllSelected === true
                  ? ["*"]
                  : scenario.items
                      .filter((item) => item.id !== undefined)
                      .map((item) => item.id?.toString() || ""), //adding items to send in payload
              enabled: scenario.enabled ?? false,
              additionalInformation: {
                enabled: scenario.additionalInformation?.enabled ?? false,
                message: scenario.additionalInformation?.message ?? "",
                extendReturnPeriod: {
                  enabled:
                    scenario.additionalInformation?.extendReturnPeriod
                      .enabled ?? false,
                  days:
                    scenario.additionalInformation?.extendReturnPeriod.days ??
                    0,
                },
              },
              giveOffer: {
                enabled: scenario.giveOffer?.enabled ?? false,
                message: scenario.giveOffer?.message ?? "",
                offerSelectedItems:
                  (scenario.giveOffer?.offerSelectedItems as number[]) ?? [],
                offerSelectedTags: scenario.giveOffer
                  ?.offerSelectedTags as string[],
                offerType: (scenario.giveOffer?.offerType as string) ?? "other",
                offerValue: (scenario.giveOffer?.offerValue as number) ?? 0,
                offerUnit: scenario.giveOffer?.offerUnit ?? "None",
                name: scenario.giveOffer?.name ?? "",
              },
              offerExchange: {
                enabled: scenario.offerExchange?.enabled ?? false,
                message: scenario.offerExchange?.message ?? "",
                exchangeWithOtherItems:
                  scenario.offerExchange?.exchangeWithOtherItems ?? false,
                exchangeWithSameItems:
                  scenario.offerExchange?.exchangeWithSameItems ?? false,
              },
              followUpQuestions:
                scenario.followUpQuestions.length > 0
                  ? scenario.followUpQuestions.map(
                      (question: {
                        selectedValue: string | any[];
                        additionalOptions: string | any[];
                        elementId: any;
                      }) => {
                        if (
                          question.selectedValue.length ===
                          question.additionalOptions.length
                        ) {
                          return {
                            elementId: question.elementId,
                            value: ["*"],
                            // selectedId: ["*"],
                          };
                        } else {
                          return {
                            elementId: question.elementId,
                            value: question.selectedValue,
                          };
                        }
                      },
                    )
                  : [],
            };
          }),
        };
      });

      return {
        configTabId: Number(orderType.orderTypeId),
        configTabName: orderType.orderTypeName,
        // applyToAllOrders: orderType.applyToAllOrders ?? true,
        conditions: orderType.conditions.map(
          (condition: {
            variableName: any;
            id: any;
            values: any;
            operator: any;
          }) => {
            const selectedVariable =
              state.antiRefundOrderTypesConfig.antiRefundOrderTypesData.variableNames.find(
                (variable: any) => variable.name === condition.variableName,
              );

            if (selectedVariable) {
              return {
                conditionId: Number(condition.id),
                conditionTypeId: selectedVariable.id,

                conditionValue: getValueFromConditionType(condition.values),
                operator: Object.keys(operatorSymbolMap).find((key) => {
                  if (
                    (condition.operator === "are less than" ||
                      condition.operator === "is less than") &&
                    key === "<"
                  ) {
                    return true;
                  }
                  return operatorSymbolMap[key] === condition.operator;
                }),
              } as ConditionType & { conditionId: number };
            } else {
              return {
                conditionId: Number(condition.id),
                conditionTypeId: -1,
              };
            }
          },
        ),
        refundRequests: updatedReasonsPayload.length
          ? updatedReasonsPayload
          : {},
      };
    });
    await fetchStep13ConfigPost({ integrationId, tabType });
    setTimeout(() => {
      if (payload.callback) {
        payload.callback();
      }
    }, 0);
    // Refetch data

    const configRes = await fetchGetStep13Config({ integrationId });
    // Getting all tags and products to assign incase of ["*"]
    let allTags: any = state.returnExchange.getAllProductTags.data;
    let allItems: any = state.returnExchange.getAllShopifyProducts;
    if (allTags?.length == 0) {
      const productTags: any = await fetchGetAllProductTags({ limit: 1000 }),
        allTags = productTags?.payload?.data ?? [];
    }
    if (allItems?.length == 0) {
      const allShopifyProducts: any = await fetchGetAllShopifyProducts({
          limit: 1000,
          searchTerm: "",
          start: 0,
        }),
        allItems = allShopifyProducts.payload?.data ?? [];
    }

    return {
      configRes,
      allTags,
      allItems,
    };
  },
);

// Step 14

export const fetchStep14GetConfig = createAsyncThunk(
  "fetchStep14GetConfig",
  async (payload, { dispatch, getState }) => {
    //getting the redux state to get the active integration id of current segment
    const currentState = getState() as RootState;
    const integrationId = currentState.returnExchange
      .activeIntegrationId as number;

    // const step14ConditionOptionsRes = await fetchGetStep14ConditionsOptions({
    //   integrationId,
    // }); // async action

    // const step14ConfigRes = await fetchGetStep14Config({ integrationId }); // async action

    const [step14ConditionOptionsRes, step14ConfigRes, returnReasonList] =
      await Promise.all([
        fetchGetStep14ConditionsOptions({ integrationId }),
        fetchGetStep14Config({ integrationId }),
        getAllReturnReasonList({ integrationId }), // Adding to get default return reasons for a state variable to check for validations based on moreQuestions
      ]);

    // Fetch the returnReasons answer from Q7 and send to builder
    const returnReasons =
      currentState.returnExchange.step12.stepData.stepQuestions[
        EReturnAutomationQuestionKeys.returnReasonList
      ].showQuestion === true;

    return {
      step14ConditionOptionsRes,
      step14ConfigRes,
      returnReasons,
      returnReasonList: returnReasonList.reasons ?? [],
    };
  },
);

export const fetchPostStep14Config = createAsyncThunk(
  "fetchPostStep14Config",
  async (
    payload: fetchPostStepConfigPayload | undefined = { callback: () => {} },
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;

    const returnMethods = state.step14Config.returnMethods;

    const integrationId = state.returnExchange.activeIntegrationId;
    const tabType: any = returnMethods.map((returnMethod) => {
      const operatorSymbolMap = state.step14Config.operatorSymbolMap;

      return {
        configTabId: Number(returnMethod.returnMethodId),
        configTabName: returnMethod.returnMethodName,

        conditions: returnMethod.conditions.map(
          (condition: {
            variableName: any;
            id: any;
            values: any;
            operator: any;
          }) => {
            const selectedVariable = state.step14Config.variableNames.find(
              (variable: any) => variable.name === condition.variableName,
            );

            if (selectedVariable) {
              return {
                conditionId: Number(condition.id),
                conditionTypeId: selectedVariable.id,

                conditionValue: getValueFromConditionType(condition.values),
                operator: Object.keys(operatorSymbolMap).find((key) => {
                  if (
                    (condition.operator === "are less than" ||
                      condition.operator === "is less than") &&
                    key === "<"
                  ) {
                    return true;
                  }
                  return operatorSymbolMap[key] === condition.operator;
                }),
              } as ConditionType & { conditionId: number };
            } else {
              return {
                conditionId: Number(condition.id),
                conditionTypeId: -1,
              };
            }
          },
        ),
        selectReturnReasons: returnMethod.selectReturnReason?.map(
          (condition) => {
            const selectedVariable = state.step14Config.variableNames.find(
              (variable: any) => variable.name === condition.variableName,
            );

            if (selectedVariable) {
              return {
                conditionId: Number(condition.id),
                conditionTypeId: selectedVariable.id,

                conditionValue: getValueFromConditionType(condition.values),
                operator: Object.keys(operatorSymbolMap).find((key) => {
                  if (
                    (condition.operator === "are less than" ||
                      condition.operator === "is less than") &&
                    key === "<"
                  ) {
                    return true;
                  }
                  return operatorSymbolMap[key] === condition.operator;
                }),
              } as ConditionType & { conditionId: number };
            } else {
              return {
                conditionId: Number(condition.id),
                conditionTypeId: -1,
              };
            }
          },
        ),
        returnMethod: {
          returnType: returnMethod.returnMethodType.value,
          displayMessage: returnMethod.returnMethodType.message,
        },
      };
    });
    await fetchStep14ConfigPost({ integrationId, tabType });
    setTimeout(() => {
      if (payload.callback) {
        payload.callback();
      }
    }, 0);
    // Refetch data for tabs

    const step14ConfigRes = await fetchGetStep14Config({ integrationId }); // async action
    // Getting the answer from Ques 7 and sending to the builder
    const returnReasons =
      state.returnExchange.step12.stepData.stepQuestions[
        EReturnAutomationQuestionKeys.returnReasonList
      ].showQuestion === true;

    return {
      step14ConfigRes,
      returnReasons,
    };
  },
);

// Step 15

export const fetchStep15GetConfig = createAsyncThunk(
  "fetchStep15GetConfig",
  async (payload, { dispatch, getState }) => {
    //getting the redux state to get the active integration id of current segment
    const currentState = getState() as RootState;
    const integrationId = currentState.returnExchange
      .activeIntegrationId as number;

    // const step15ConditionOptionsRes = await fetchGetStep15ConditionsOptions({
    //   integrationId,
    // }); // async action

    // const step15ConfigRes = await fetchGetStep15Config({ integrationId }); // async action

    const [step15ConfigRes, step15ConditionOptionsRes, returnReasons] =
      await Promise.all([
        fetchGetStep15Config({ integrationId }),
        fetchGetStep15ConditionsOptions({ integrationId }),
        getAllReturnReasonList({ integrationId }), // Adding to get default return reasons for a state variable to check for validations based on moreQuestions
      ]);

    // Getting the answer from Ques 7 and sending to the builder
    const isSelectReasonEnabled =
      currentState.returnExchange.step12.stepData.stepQuestions[
        EReturnAutomationQuestionKeys.returnReasonList
      ].showQuestion === true;

    const restockFee = currentState.returnExchange.step15.stepData
      .stepQuestions[EReturnAutomationQuestionKeys.usuallyChargedRestockingFee]
      .value as IRestockingFee;

    return {
      step15ConditionOptionsRes,
      step15ConfigRes,
      isSelectReasonEnabled,
      restockFee,
      returnReasonList: returnReasons.reasons ?? [],
    };
  },
);

export const fetchPostStep15Config = createAsyncThunk(
  "fetchPostStep15Config",
  async (
    payload: fetchPostStepConfigPayload | undefined = { callback: () => {} },
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;

    const additionalCharges = state.step15Configuration.additionalCharges;

    const integrationId = state.returnExchange.activeIntegrationId;
    const tabType: any = additionalCharges.map((additionalCharge) => {
      const operatorSymbolMap = state.step15Configuration.operatorSymbolMap;

      return {
        configTabId: Number(additionalCharge.additionalChargeId),
        configTabName: additionalCharge.additionalChargeName,
        restockingFee: {
          price: additionalCharge.restockingFees.amount,
          priceType: additionalCharge.restockingFees.priceType,
        },
        restockingFeeMessage: additionalCharge.messageForCustomer.value,
        conditions: additionalCharge.conditions.map(
          (condition: {
            variableName: any;
            id: any;
            values: any;
            operator: any;
          }) => {
            const selectedVariable =
              state.step15Configuration.variableNames.find(
                (variable: any) => variable.name === condition.variableName,
              );

            if (selectedVariable) {
              return {
                conditionId: Number(condition.id),
                conditionTypeId: selectedVariable.id,

                conditionValue: getValueFromConditionType(condition.values),
                operator: Object.keys(operatorSymbolMap).find((key) => {
                  if (
                    (condition.operator === "are less than" ||
                      condition.operator === "is less than") &&
                    key === "<"
                  ) {
                    return true;
                  }
                  return operatorSymbolMap[key] === condition.operator;
                }),
              } as ConditionType & { conditionId: number };
            } else {
              return {
                conditionId: Number(condition.id),
                conditionTypeId: -1,
              };
            }
          },
        ),
        selectReturnReasons: additionalCharge.selectReturnReason?.map(
          (condition) => {
            const selectedVariable =
              state.step15Configuration.variableNames.find(
                (variable: any) => variable.name === condition.variableName,
              );

            if (selectedVariable) {
              return {
                conditionId: Number(condition.id),
                conditionTypeId: selectedVariable.id,

                conditionValue: getValueFromConditionType(condition.values),
                operator: Object.keys(operatorSymbolMap).find((key) => {
                  if (
                    (condition.operator === "are less than" ||
                      condition.operator === "is less than") &&
                    key === "<"
                  ) {
                    return true;
                  }
                  return operatorSymbolMap[key] === condition.operator;
                }),
              } as ConditionType & { conditionId: number };
            } else {
              return {
                conditionId: Number(condition.id),
                conditionTypeId: -1,
              };
            }
          },
        ),
      };
    });
    await fetchStep15ConfigPost({ integrationId, tabType });
    setTimeout(() => {
      if (payload.callback) {
        payload.callback();
      }
    }, 0);

    // Refetch data for tabs

    const step15ConfigRes = await fetchGetStep15Config({ integrationId }); // async action
    // Getting the answer from Ques 7 and sending to the builder
    const isSelectReasonEnabled =
      state.returnExchange.step12.stepData.stepQuestions[
        EReturnAutomationQuestionKeys.returnReasonList
      ].showQuestion === true;

    return {
      step15ConfigRes,
      isSelectReasonEnabled,
    };
  },
);

// thunk to update
export const updateReturnAutomationBulkStepData = createAsyncThunk(
  "updateReturnAutomationBulkStepData",
  async (
    // payload: any | undefined = { onUpdateComplete: () => {} },
    _: void,
    { dispatch, getState },
  ) => {
    // get the state
    const state: RootState = getState() as RootState;
    const returnExchangeState = state.returnExchange;
    // construct the params
    let params: IReturnAutomationBulkStepUpdateParams = {
      integrationId: returnExchangeState.activeIntegrationId as number,
      step_data: [],
    };

    // to store the step data
    let stepDataTemp: IStepData = {
      step_id: -1,
      stepQuestions: {},
    };

    // step 9 data
    let stepNine = returnExchangeState.step9;
    // if step 9 is visible
    if (stepNine.showStep === true && stepNine.stepData.stepQuestions) {
      stepDataTemp = {
        step_id: 9,
        stepQuestions: {},
      };

      // iterate through the questions answered

      Object.keys(stepNine.stepData.stepQuestions).forEach((questionKey) => {
        // Ignore the question if added as this is hidden
        if (
          questionKey ===
          EReturnAutomationQuestionKeys.isReturnPolicySameForAllOrders
        ) {
          return;
        }

        // get the question
        let question = stepNine.stepData.stepQuestions[questionKey];
        // check the question is visible and answered
        if (question.showQuestion === true) {
          stepDataTemp.stepQuestions[question.key] = question.value;
        }
      });

      if (stepDataTemp.stepQuestions) {
        params.step_data.push(stepDataTemp);
      }
    } // end of step 9

    // step 10 data
    // let stepTen = returnExchangeState.step10;
    // // if step 10 is visible
    // if (stepTen.showStep === true && stepTen.stepData.stepQuestions) {
    //   stepDataTemp = {
    //     step_id: 10,
    //     stepQuestions: {},
    //   };

    //   // iterate through the questions answered
    //   Object.keys(stepTen.stepData.stepQuestions).forEach((questionKey) => {
    //     // get the question
    //     let question = stepTen.stepData.stepQuestions[questionKey];
    //     // check the question is visible and answered
    //     if (question.showQuestion === true) {
    //       stepDataTemp.stepQuestions[question.key] = question.value;
    //     }
    //   });
    //   if (stepDataTemp.stepQuestions) {
    //     params.step_data.push(stepDataTemp);
    //   }
    // } // end of step 10

    // step 11 data
    let stepEleven = returnExchangeState.step11;
    // if step 11 is visible
    if (stepEleven.showStep === true && stepEleven.stepData.stepQuestions) {
      stepDataTemp = {
        step_id: 11,
        stepQuestions: {},
      };

      // iterate through the questions answered
      Object.keys(stepEleven.stepData.stepQuestions).forEach((questionKey) => {
        // Ignore the question if added as this is hidden
        if (
          questionKey ===
          EReturnAutomationQuestionKeys.isResolutionOfferedSameForAllOrders
        ) {
          return;
        }

        // get the question
        let question = stepEleven.stepData.stepQuestions[questionKey];
        // check the question is visible and answered
        if (question.showQuestion === true) {
          if (question.key === EReturnAutomationQuestionKeys.resolutionOffer) {
            let resolutionOfferVal: IResolutionOffersValue =
              question.value as IResolutionOffersValue;
            stepDataTemp.stepQuestions[question.key] = {
              exchangeWithOtherItems: resolutionOfferVal.exchangeWithOtherItems,
              exchangeWithSameItems: resolutionOfferVal.exchangeWithSameItems,
              refundToOrigialPaymentMethod:
                resolutionOfferVal.refundToOrigialPaymentMethod,
              refundToStoreCredits: resolutionOfferVal.refundToStoreCredits,

              resolutionOfferMessage:
                resolutionOfferVal.resolutionOfferMessage.value,
            };
          } else {
            stepDataTemp.stepQuestions[question.key] = question.value;
          }
        }
      });
      if (stepDataTemp.stepQuestions) {
        params.step_data.push(stepDataTemp);
      }
    } // end of step 11

    // step 12 data
    let stepTwelve = returnExchangeState.step12;
    // if step 12 is visible
    if (stepTwelve.showStep === true && stepTwelve.stepData.stepQuestions) {
      stepDataTemp = {
        step_id: 12,
        stepQuestions: {},
      };

      // iterate through the questions answered
      Object.keys(stepTwelve.stepData.stepQuestions).forEach((questionKey) => {
        // get the question
        let question = stepTwelve.stepData.stepQuestions[questionKey];
        // check the question is visible and answered
        if (question.showQuestion === true) {
          if (question.key === EReturnAutomationQuestionKeys.returnReasonList) {
            stepDataTemp.stepQuestions[question.key] = (
              question.value as Array<IReturnReason>
            ).map((reason) => {
              if (reason.new === true) {
                return {
                  id: null,
                  value: reason.value,
                };
              } else {
                return {
                  id: reason.id,
                  value: reason.value,
                };
              }
            });
          } else {
            stepDataTemp.stepQuestions[question.key] = question.value;
          }
        }
      });

      if (stepDataTemp.stepQuestions) {
        params.step_data.push(stepDataTemp);
      }
    } // end of step 12

    // step 13 data
    let stepThirteen = returnExchangeState.step13;
    // if step 13 is visible
    if (stepThirteen.showStep === true && stepThirteen.stepData.stepQuestions) {
      stepDataTemp = {
        step_id: 13,
        stepQuestions: {},
      };

      // iterate through the questions answered
      Object.keys(stepThirteen.stepData.stepQuestions).forEach(
        (questionKey) => {
          // get the question
          let question = stepThirteen.stepData.stepQuestions[questionKey];
          // check the question is visible and answered
          if (
            question.showQuestion === true &&
            questionKey !==
              EReturnAutomationQuestionKeys.configureAntiReturnStrategies
          ) {
            stepDataTemp.stepQuestions[question.key] = question.value;
          }
        },
      );
      if (stepDataTemp.stepQuestions) {
        params.step_data.push(stepDataTemp);
      }
    } // end of step 13

    // step 14 data
    let stepFourteen = returnExchangeState.step14;
    // if step 14 is visible
    if (stepFourteen.showStep === true && stepFourteen.stepData.stepQuestions) {
      stepDataTemp = {
        step_id: 14,
        stepQuestions: {},
      };

      // iterate through the questions answered
      Object.keys(stepFourteen.stepData.stepQuestions).forEach(
        (questionKey) => {
          // Ignore the question if added as this is hidden
          if (
            questionKey ===
            EReturnAutomationQuestionKeys.isReturnMethodSameForAllOrders
          ) {
            return;
          }
          // get the question
          let question = stepFourteen.stepData.stepQuestions[questionKey];
          // check the question is visible and answered
          if (question.showQuestion === true) {
            stepDataTemp.stepQuestions[question.key] = question.value;
          }
        },
      );
      if (stepDataTemp.stepQuestions) {
        params.step_data.push(stepDataTemp);
      }
    } // end of step 14

    // step 15 data
    let stepFifteen = returnExchangeState.step15;
    if (stepFifteen.showStep === true && stepFifteen.stepData.stepQuestions) {
      stepDataTemp = {
        step_id: 15,
        stepQuestions: {},
      };

      // iterate through the questions answered
      Object.keys(stepFifteen.stepData.stepQuestions).forEach((questionKey) => {
        // Ignore the question if added as this is hidden
        if (
          questionKey ===
          EReturnAutomationQuestionKeys.isRestockingFeeSameForAll
        ) {
          return;
        }

        // get the question
        let question = stepFifteen.stepData.stepQuestions[questionKey];
        // check the question is visible and answered
        if (question.showQuestion === true) {
          stepDataTemp.stepQuestions[question.key] = question.value;
        }
      });
      if (stepDataTemp.stepQuestions) {
        params.step_data.push(stepDataTemp);
      }
    } // end of step 15

    const res = await bulkStepUpdate(params /*, payload.onUpdateComplete*/);

    return res;
  },
);

// New thunk to update specific question
export const updateReturnAutomationSingleQuestion = createAsyncThunk(
  "updateReturnAutomationSingleQuestion",
  async (
    {
      questionKey,
      questionValue,
      stepId,
      context,
      updateContext, // Related to return preview
    }: {
      questionKey: string;
      questionValue: any;
      stepId: number;
      context?: "reason";
      updateContext?: ({
        selectedOrderId,
        activeStep,
      }: {
        selectedOrderId: string;
        activeStep: StepName;
      }) => void;
    },
    { getState },
  ) => {
    // get the state
    const state: RootState = getState() as RootState;
    const returnExchangeState = state.returnExchange;

    // construct the params
    let params: IReturnAutomationBulkStepUpdateParams = {
      integrationId: returnExchangeState.activeIntegrationId as number,
      step_data: [],
    };
    const isRestockingFee =
      (returnExchangeState.step15.stepData.stepQuestions[
        EReturnAutomationQuestionKeys.isRestockingFeeChargedForMajorityOrders
      ].value as boolean) ?? false;
    const question7Answer =
      returnExchangeState.step12.stepData.stepQuestions[
        EReturnAutomationQuestionKeys.selectReturnReason
      ].value;
    const isReturnMethodAvailable =
      returnExchangeState.step14.stepData.stepQuestions[
        EReturnAutomationQuestionKeys.returnMethodForMajorityOfOrders
      ].showQuestion ?? false;
    // Related to return preview
    if (updateContext) {
      const { selectedOrderId, activeStep } = updatePreviewDetails({
        questionKey,
        isRestockingFee,
        showReturnReasons:
          question7Answer != null && question7Answer != "notRequired",
        isReturnMethodAvailable,
      });
      updateContext({
        selectedOrderId: selectedOrderId,
        activeStep: activeStep,
      });
    }
    // If a step containing the question is found
    if (stepId) {
      if (context === "reason") {
        let updatedReturnReasonsList = (
          questionValue as Array<IReturnReason>
        ).map((reason) => {
          if (reason.new === true) {
            return {
              id: null,
              value: reason.value,
            };
          } else {
            return {
              id: reason.id,
              value: reason.value,
            };
          }
        });
        questionValue = updatedReturnReasonsList;
      }
      // construct the payload for the updated question
      let stepDataTemp: IStepData = {
        step_id: stepId,
        stepQuestions: {
          [questionKey]: questionValue,
        },
      };

      params.step_data.push(stepDataTemp);

      // Make the API call
      const res = await bulkStepUpdate(params);

      return res;
    }
  },
);

//validation thunks
export const validateStep9Conditions = createAsyncThunk(
  "validateStep9Conditions",
  async (_: void, { getState, dispatch }) => {
    await dispatch(validateStep9());

    const state = getState() as RootState;

    let errorCount = 0;
    state.step9Configuration.step9Configuration.orderTypes.forEach(
      (orderType) => {
        if (orderType.errorCount !== 0) {
          errorCount += 1;
        }
      },
    );

    return errorCount;
  },
);

export const validateStep10Conditions = createAsyncThunk(
  "validateStep10Conditions",
  async (_: void, { getState, dispatch }) => {
    await dispatch(validateStep10());

    const state = getState() as RootState;

    let errorCount = 0;
    state.step10Configuration.itemTypes.forEach((item) => {
      if (item.errorCount !== 0) {
        errorCount += 1;
      }
    });

    return errorCount;
  },
);

export const validateStep11Conditions = createAsyncThunk(
  "validateStep11Conditions",
  async (_: void, { getState, dispatch }) => {
    await dispatch(validateStep11());

    const state = getState() as RootState;

    let errorCount = 0;
    state.step11Config.returnTypes.forEach((data) => {
      if (data.errorCount !== 0) {
        errorCount += 1;
      }
    });

    return errorCount;
  },
);

export const validateStep12Conditions = createAsyncThunk(
  "validateStep12Conditions",
  async (_: void, { getState, dispatch }) => {
    await dispatch(validateStep12());

    const state = getState() as RootState;

    let errorCount = 0;
    state.step12Config.reasonTypes.forEach((item) => {
      if (item.errorCount !== 0) {
        errorCount += 1;
      }
    });

    return errorCount;
  },
);

//validation thunks
export const validateStep13Conditions = createAsyncThunk(
  "validateStep13Conditions",
  async (_: void, { getState, dispatch }) => {
    await dispatch(validateStep13());

    const state = getState() as RootState;

    let errorCount = 0;
    state.antiRefundOrderTypesConfig.antiRefundOrderTypesData.antiRefundOrderTypes.forEach(
      (orderType) => {
        if (orderType.errorCount !== 0) {
          errorCount += 1;
        }
      },
    );

    return errorCount;
  },
);

export const validateStep14Conditions = createAsyncThunk(
  "validateStep14Conditions",
  async (_: void, { getState, dispatch }) => {
    await dispatch(validateStep14());

    const state = getState() as RootState;

    let errorCount = 0;
    state.step14Config.returnMethods.forEach((data) => {
      if (data.errorCount !== 0) {
        errorCount += 1;
      }
    });

    return errorCount;
  },
);

export const validateStep15Conditions = createAsyncThunk(
  "validateStep15Conditions",
  async (_: void, { getState, dispatch }) => {
    await dispatch(validateStep15());

    const state = getState() as RootState;

    let errorCount = 0;
    state.step15Configuration.additionalCharges.forEach((data) => {
      if (data.errorCount !== 0) {
        errorCount += 1;
      }
    });

    return errorCount;
  },
);

export const fetchRefundRequestListStep13 = createAsyncThunk(
  "fetchRefundRequestListStep13",
  async (payload, { dispatch, getState }) => {
    const currentState = getState() as RootState;
    const integrationId = currentState.returnExchange
      .activeIntegrationId as number;
    const getRefundRequestList = await getAllReturnReasonList({
      integrationId,
    });

    if (getRefundRequestList) {
      return getRefundRequestList;
    }
  },
);

export const fetchGetRefundRequestFollowUpQuestionsStep13 = createAsyncThunk(
  "fetchGetRefundRequestFollowUpQuestionsStep13",
  async (payload: { id: string }, { dispatch, getState }) => {
    const currentState = getState() as RootState;
    const integrationId = currentState.returnExchange.activeIntegrationId;
    const getRefundRequestFollowUpQuestions =
      await fetchRefundRequestFollowUpQuestions({
        integrationId,
        id: payload.id,
      });

    if (getRefundRequestFollowUpQuestions) {
      return getRefundRequestFollowUpQuestions;
    }
  },
);
