import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styles from "./CreateTicket.module.scss";
import Select from "react-select";
import { ICreateTicketFromChatParams } from "src/services/LiveChat/createTicket/createTicketFromChat.service";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  createLiveChatTicketThunk,
  fetchLiveChatThreadThunk,
} from "src/store/slices/liveChatSetting/liveChatTabQuickActions/liveChatTabQuickActions.thunks";
import ReactSelect from "react-select";
import {
  ICustomerData,
  readCompanyEmails,
} from "src/apis/addTicket/read/addTicket.read.api";
import { v4 as uuid } from "uuid";
import LiveSendingAs, { ESendTypes } from "../../../LiveSendingAs";
import LiveChatSendingDetails from "./LiveChatSendingDetails/LiveChatSendingDetails";
import { Dropdown, Spinner } from "react-bootstrap";
import { isHTMLEmpty, trimPDIVBR, validateEmail } from "src/utils/utils";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { useNavigate, useParams } from "react-router-dom";
import { actions } from "src/store/slices/liveChatSetting/chatWidExchCust/chatWidExchCust.slice";
import { Customer } from "src/services/LiveChat/messageExchange/getAllCustomers";
import { fetchCustomerData } from "src/store/slices/liveChatSetting/chatWidExchCust/chatWidExchCust.thunks";
import {
  setIsResolved,
  setTicketData,
} from "src/store/slices/liveChatSetting/chatWidExchMsg/chatWidExchMsg.slice";
import TinyMCEContainer from "src/components/TinyMCEContainer";
import closeModal from "src/assets/images/close(3).png";
import { BsExclamationDiamondFill } from "react-icons/bs";
import IntegrationFailureText from "src/components/IntegrationFailureModal/children/IntegrationFailureText/IntegrationFailureText";
import { IntegrationFailureMetaData } from "src/types/IntegrationFailure";
import useIntegrationFailure from "src/hooks/useIntegrationFailure";
import IntegrationFailureModal from "src/components/IntegrationFailureModal/IntegrationFailureModal";
import alertIcon from "src/assets/images/alert.svg";
// const options = [
//   { value: "chocolate", label: "Chocolate" },
//   { value: "strawberry", label: "Strawberry" },
//   { value: "vanilla", label: "Vanilla" },
// ];
// Props interface for the CreateTicket component
interface Props {
  onHide: (e: any) => void; // Callback to hide the component
  heading: string; // Heading text for the component
  subHeading: string; // Subheading text for the component
  submitBtn: string; // Text for the submit button
  resolve: boolean; // Boolean indicating whether the ticket is resolved
  chatId: number | string; // Chat ID for the ticket
  selectedCustomerEmail: string; // Email of the selected customer
  isAnonymouseUser?: boolean; // Flag indicating if the user is anonymous (optional)
  onSuccessCallback: (createdTicketId: number | string) => void; // Callback for successful ticket creation
}

// CreateTicket component definition
const CreateTicket = ({
  onHide, // Callback to hide the component
  heading, // Heading text for the component
  subHeading, // Subheading text for the component
  submitBtn, // Text for the submit button
  resolve, // Boolean indicating whether the ticket is resolved
  chatId, // Chat ID for the ticket
  selectedCustomerEmail, // Email of the selected customer
  isAnonymouseUser, // Flag indicating if the user is anonymous (optional)
  onSuccessCallback, // Callback for successful ticket creation
}: Props) => {
  const dispatch = useAppDispatch(); // Redux dispatch hook

  const {
    customers,
    chatIdList,
    selectedCustomerStatus,
    selectedCustomerChatId,
    fetchCustAJAXStatus,
  } = useAppSelector((state) => state.chatWidExchCust);

  const { chatStatus } = useParams();
  const navigate = useNavigate();

  const {
    threadText,
    createTicketFromLiveChatAjaxStatus,
    integrationFailure: integrationFailureAPIData,
  } = useAppSelector((state) => state.liveChatTabQuickActions);

  const [sendType, setSendType] = useState<ESendTypes>(ESendTypes.Message);
  const [showReplyType, setShowReplyType] = useState<boolean>(true);
  const [showThread, setShowThread] = useState(false);

  const [ticketSubject, setTicketSubject] = useState<string>("");
  const [anonymousName, setAnonymousName] = useState<string>("");
  const [anonymousEmail, setAnonymousEmail] = useState<string>("");
  const [fromEmailList, setFromEmailList]: any = useState([]);
  const [fromEmail, setFromEmail] = useState(null as any);
  const [ticketDescription, setTicketDescription] = useState<string>("");
  const [sendEmail, setSendEmail] = useState<boolean>(false);
  const [cc, setCc]: any = useState("");
  const [bcc, setBcc]: any = useState("");
  const [showError, setShowError] = useState(false);
  // const isToastActive = useRef(false);
  const [integrationErrorState, setIntegrationErrorState] = useState({
    showIntegrationError: false,
    showSeeWhy: false,
  });
  const [showCannotSendModal, setShowCannotSendModal] = useState(false);
  const [isDropdownDataFetching, setIsDropdownDataFetching] =
    useState<boolean>(false);

  const [dropValue, setDropValue] = React.useState<string>("");
  const currentState = useRef({
    customers,
    chatIdList,
    selectedCustomerStatus,
    selectedCustomerChatId,
    fetchCustAJAXStatus,
  });

  useEffect(() => {
    currentState.current.customers = customers;
    currentState.current.chatIdList = chatIdList;
    currentState.current.selectedCustomerStatus = selectedCustomerStatus;
    currentState.current.selectedCustomerChatId = selectedCustomerChatId;
    currentState.current.fetchCustAJAXStatus = fetchCustAJAXStatus;
  }, [customers, selectedCustomerStatus, chatIdList, selectedCustomerChatId]);

  const { getIntegrationFailureData } = useIntegrationFailure();

  /**
   * Integration failure data
   */
  const { integrationFailure, isIntegrationFailure } = useMemo(() => {
    return getIntegrationFailureData({
      fromEmail: fromEmail?.email,
      integrationFailureAPIData: integrationFailureAPIData,
    });
  }, [fromEmail?.email, integrationFailureAPIData]);
  const handleCannotSend: React.MouseEventHandler<HTMLButtonElement> =
    useCallback((e) => {
      e.preventDefault();
      e.stopPropagation();
      setShowCannotSendModal(true);
    }, []);

  const createTicketSubmit = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      if (isIntegrationFailure && integrationFailure) {
        if (sendEmail) {
          setIntegrationErrorState((data) => {
            return {
              ...data,
              showSeeWhy: true,
            };
          });
        }
        setIntegrationErrorState((data) => {
          return {
            ...data,
            showIntegrationError: true,
          };
        });
        return;
      }
      const ticketPayload: ICreateTicketFromChatParams = {
        chatRequestId: chatId,
        ticketSubject: ticketSubject.trim(),
        ticketBrand: fromEmail?.email,
        ticketDescription: ticketDescription, //optional
        sendEmail: sendEmail, //optional
        threadText: threadText, // optional
        internalNote: ticketDescription, // optional
        cc: [],
        bcc: [],
        resolve: resolve,
      };

      //Dec 27 Add customer name and email incase of anonymous user
      if (isAnonymouseUser) {
        ticketPayload.customerName = anonymousName.trim(); // optional
        ticketPayload.customerEmail = anonymousEmail; // optional
      }

      if (cc && cc != "") {
        ticketPayload.cc = cc?.split(",");
      }

      if (bcc && bcc != "") {
        ticketPayload.bcc = bcc?.split(",");
      }

      if (sendType == ESendTypes.Note) {
        delete ticketPayload.ticketDescription;
        delete ticketPayload.threadText;
      }

      if (sendType == ESendTypes.Message) {
        delete ticketPayload.internalNote;
      }

      if (sendEmail == false) {
        delete ticketPayload?.internalNote;
        delete ticketPayload?.ticketDescription;
        delete ticketPayload?.threadText;
        delete ticketPayload?.cc;
        delete ticketPayload?.bcc;
      }

      if (ticketPayload?.bcc?.length == 0) {
        delete ticketPayload.bcc;
      }

      if (ticketPayload?.cc?.length == 0) {
        delete ticketPayload.cc;
      }

      let isValid = true;
      let validationText: Array<string> = [];

      // validation
      if (ticketPayload?.ticketSubject?.length === 0) {
        isValid = false;
        validationText.push("Please enter subject!");
      }
      if (!validateEmail(ticketPayload.ticketBrand)) {
        isValid = false;
        validationText.push("Please select from e-mail!");
      }
      //Dec 27 check if customer is anonymous user
      if (isAnonymouseUser) {
        // validation for customer name
        if (ticketPayload?.customerName?.trim().length === 0) {
          isValid = false;
          validationText.push("Please enter customer name!");
        }
        // validation for customer email
        if (!validateEmail(ticketPayload.customerEmail!)) {
          isValid = false;
          validationText.push("Please enter a valid customer e-mail!");
        }
      }

      // if send mail
      if (sendEmail == true) {
        if (sendType == ESendTypes.Note) {
          if (
            ticketPayload?.internalNote?.length === 0 ||
            isHTMLEmpty(
              trimPDIVBR(
                ticketPayload?.internalNote ? ticketPayload?.internalNote : "",
              ),
            )
          ) {
            isValid = false;
            validationText.push("Please add note!");
          }
        } else {
          if (
            ticketPayload?.ticketDescription?.length === 0 ||
            ticketPayload?.threadText?.length === 0 ||
            isHTMLEmpty(
              trimPDIVBR(
                ticketPayload?.ticketDescription
                  ? ticketPayload?.ticketDescription
                  : "",
              ),
            )
          ) {
            isValid = false;
            validationText.push("Please add description!");
          }
          // vaidate cc and bcc
          if (ticketPayload?.cc?.length) {
            ticketPayload?.cc?.forEach((mailId) => {
              if (!validateEmail(mailId)) {
                isValid = false;
              }
            });
          }

          if (ticketPayload?.bcc?.length) {
            ticketPayload?.bcc?.forEach((mailId) => {
              if (!validateEmail(mailId)) {
                isValid = false;
              }
            });
          }
        }
      }

      if (!isValid) {
        if (validationText.length) {
          // Check if there is no existing toast
          // Dec 30 Commented as the showing inline error message instead of teast message
          // if (!isToastActive.current) {
          //   // Display the warning toast
          //   pushTheToast({
          //     type: "warning",
          //     text: validationText[0],
          //     position: "top-right",
          //   });

          //   // Set isToastActive to true
          //   isToastActive.current = true;

          //   // Set a timeout to reset isToastActive after 5000ms
          //   setTimeout(() => {
          //     isToastActive.current = false;
          //   }, 5000);
          // }
          setShowError(true);
          return;
        }
      }
      dispatch(
        createLiveChatTicketThunk({
          ...ticketPayload,
          callback: ticketCreatedCallback,
        }),
      );
    },
    [
      sendType,
      ticketSubject,
      fromEmail,
      sendEmail,
      ticketDescription,
      cc,
      bcc,
      anonymousName,
      anonymousEmail,
      isAnonymouseUser,
    ],
  );
  const ticketCreatedCallback = useCallback(
    (ticketData: any) => {
      setTimeout(() => {
        onSuccessCallback(ticketData.ticketId);
      }, 0);
      if (resolve == true) {
        pushTheToast({
          text: "Chat is resolved and ticket has been created",
          position: "top-right",
          type: "success",
        });
        // dispatch(resetChatWidExchMsg());
        // dispatch(
        //   actions.resetCustomersData({ loading: false, initialized: true })
        // );
        // dispatch(fetchCustomerData({ start: 0 }));
        // navigate(`/live-chat/chats/archived/${ticketData.chatId}`);

        //removing resolved chat
        //checking if there is any other chat exists in same tab if yes then will delete the resolved chat from current list ..
        //if there will be no chat left after removing resolved chat ..then redirect user to archived
        if (
          currentState.current.selectedCustomerStatus !== "archived" &&
          currentState.current.chatIdList.length === 1
        ) {
          navigate(`/live-chat/chats/archived/${ticketData.chatId}`);
          //removing chat from currect chat list
        } else if (currentState.current.selectedCustomerStatus !== "archived") {
          if (currentState.current.selectedCustomerChatId) {
            let previousChatId: number | null = null;

            const chatIds = currentState.current.chatIdList.filter(
              (chatId, index) => {
                if (currentState.current.selectedCustomerChatId != chatId) {
                  return true;
                } else {
                  previousChatId =
                    index === 0
                      ? null
                      : parseInt(
                          currentState.current.chatIdList[index - 1] + "",
                        );
                  return false;
                }
              },
            );

            dispatch(
              actions.removeCustomerFromChatList({
                chatId: parseInt(ticketData.chatId),
              }),
            );

            const redirectChatId =
              previousChatId && previousChatId !== null
                ? previousChatId
                : chatIds[0];

            if (
              chatStatus &&
              currentState.current.fetchCustAJAXStatus !== "pending"
            ) {
              dispatch(fetchCustomerData({ start: 0, initialFetch: false }));
            }

            navigate(
              chatStatus && chatStatus !== undefined
                ? window.location.pathname.replace(
                    `chats/${chatStatus}/${currentState.current.selectedCustomerChatId}`,
                    `chats/${chatStatus}/${redirectChatId}`,
                  )
                : `/live-chat/chats/${currentState.current.selectedCustomerStatus}/${redirectChatId}`,
            );
          }
        } else {
          //handle updation of archived chat
          const customer = currentState.current?.customers[ticketData.chatId];
          if (customer) {
            dispatch(
              actions.setCustomerData({
                customer: {
                  ...customer,
                  isTicketCreated: true,
                  isResolved: true,
                  ticketData: { id: ticketData.ticketId },
                },
                checkForExistingCust: true,
              }),
            );
          }

          if (currentState.current.selectedCustomerChatId == chatId) {
            dispatch(setIsResolved({ isResolved: true }));
            dispatch(
              setTicketData({ ticketData: { id: ticketData.ticketId } }),
            );
          }
        }
      } else {
        const customer = currentState.current?.customers[ticketData.chatId];
        if (customer) {
          dispatch(
            actions.setCustomerData({
              customer: {
                ...customer,
                isTicketCreated: true,
                ticketData: { id: ticketData.ticketId },
              },
              checkForExistingCust: true,
            }),
          );
        }
        if (currentState.current.selectedCustomerChatId == chatId) {
          dispatch(setTicketData({ ticketData: { id: ticketData.ticketId } }));
        }
        pushTheToast({
          text: "A ticket has been created",
          position: "top-right",
          type: "success",
        });
      }
    },
    [resolve],
  );
  useEffect(() => {
    dispatch(
      fetchLiveChatThreadThunk({
        params: { chatRequestId: chatId },
        callBack: () => setShowThread(true),
      }),
    );
    fetchDropdownOptions();
  }, []);

  // to fetch dropdown options
  const fetchDropdownOptions = () => {
    setIsDropdownDataFetching(true);
    const limit = 1000;
    readCompanyEmails({
      searchTerm: "",
      start: 0,
      limit,
      onlyVerified: true,
    })
      .then(({ data, metaData }: ICustomerData) => {
        setFromEmailList(data);
        setIsDropdownDataFetching(false);
      })
      .catch((err) => {
        setIsDropdownDataFetching(false);
      });
  };

  const handleSendMessageType = (type: ESendTypes) => {
    // console.log("handleSendMessageType type:: ", type);
    if (showReplyType) {
      setSendType(type);
    } else {
      setSendType(ESendTypes.Note);
    }
  };

  return (
    <div>
      <div className={`d-flex justify-content-between align-items-center`}>
        <span className={`${styles.modalHead}`}>{heading} </span>
        <span
          className="cursor-pointer"
          onClick={onHide}
        >
          {/* <i className="fa-solid fa-xmark"></i> */}
          <img
            src={closeModal}
            alt=""
            className={`${styles.closeModal}`}
          />
        </span>
      </div>
      <span className={`${styles.ticketInfo}`}>{subHeading}</span>
      <div className="mb-3 mt-2">
        <label
          htmlFor="exampleFormControlInput1"
          className={`form-label ${styles.formLabel}`}
        >
          Ticket subject:
        </label>
        <input
          type="text"
          className={`form-control ${styles.formControl} ${
            showError && !ticketSubject.trim() ? "redBorder" : ""
          }`}
          id="exampleFormControlInput1"
          placeholder="Untitled live chat ticket"
          value={ticketSubject}
          onChange={(e: any) => {
            setTicketSubject(e.target.value);
          }}
        />
        {/* Dec 30 Show error message when ticket subject is empty */}
        {showError && !ticketSubject.trim() && (
          <small className={`${styles.errorMsg}`}>Please enter subject!</small>
        )}
      </div>
      <div className="mb-2">
        <label
          htmlFor="exampleFormControlInput1"
          className={`form-label ${styles.formLabel}`}
        >
          From e-mail :
        </label>
        <div>
          <Dropdown
            as={"div"}
            bsPrefix={`${
              showError && (fromEmail?.email == null || fromEmail?.email == "")
                ? "redBorder rounded"
                : ""
            } dropdown w-100 text-start`}
          >
            <Dropdown.Toggle
              as={"div"}
              bsPrefix={`w-100 text-start ${styles.dropBtn} p-2`}
              childBsPrefix=""
              type="button"
            >
              {fromEmail?.email == null || fromEmail?.email == ""
                ? "Select e-mail"
                : fromEmail?.email}
            </Dropdown.Toggle>
            <Dropdown.Menu
              bsPrefix={`dropdown-menu ${styles.dropMenu}`}
              as={"ul"}
            >
              <li>
                <span className={`ps-3 ${styles.selSpan}`}>
                  Select e-mail :
                </span>
              </li>
              {fromEmailList.map((emailData: any) => {
                return (
                  <li key={uuid()}>
                    <Dropdown.Item
                      as={"a"}
                      bsPrefix={`dropdown-item cursor-pointer ${styles.dropItem}`}
                      onClick={() => setFromEmail(emailData)}
                    >
                      <p className={`mb-0 ${styles.tName}`}>{emailData.name}</p>
                      <span className={`${styles.tMail}`}>
                        {emailData.email}
                      </span>
                    </Dropdown.Item>
                  </li>
                );
              })}
            </Dropdown.Menu>
            {/* <ReactSelect
              className={`w-100  text-start ${styles.dropBtn} py-2`}
              placeholder="Select E-mail"
              options={fromEmailList.map((emailData: any) => ({
                label: emailData.email,
                value: emailData,
              }))}
              // onMenuOpen={fetchDropdownOptions}
              value={fromEmail}
              onChange={(value) => {
                setFromEmail(value);
              }}
              isLoading={isDropdownDataFetching}
            /> */}
          </Dropdown>
          {/* Dec 30 Show error message when from email is not valid */}
          {showError &&
            (fromEmail?.email == null || fromEmail?.email == "") && (
              <>
                <small className={`${styles.errorMsg}`}>
                  Please select from e-mail!
                </small>
              </>
            )}
          {integrationErrorState?.showIntegrationError &&
            isIntegrationFailure && (
              <div className="">
                <p className={`mb-0 mt-1 d-flex align-items-center`}>
                  <span className={`${styles.cText}`}>
                    Cannot create ticket
                  </span>
                  <IntegrationFailureText
                    integrationFailure={integrationFailure}
                  />
                </p>
              </div>
            )}
        </div>
      </div>
      {isAnonymouseUser && (
        // Render the following content only if isAnonymouseUser is true
        <>
          <div className="mb-3">
            <label
              htmlFor="anonymouseName"
              className={`form-label ${styles.formLabel}`}
            >
              Customer name :
            </label>
            <input
              type="text"
              className={`form-control ${styles.formControl} ${
                showError && !anonymousName.trim() ? "redBorder" : ""
              }`}
              id="anonymouseName"
              placeholder="Enter name of the chat visitor"
              // value={ticketSubject}
              onChange={(e: any) => {
                setAnonymousName(e.target.value);
              }}
            />
            {/* Dec 30 Show error message when customer name is empty */}
            {showError && !anonymousName.trim() && (
              <small className={`${styles.errorMsg}`}>
                Please enter customer name!
              </small>
            )}
          </div>
          <div className="mb-3">
            <label
              htmlFor="anonymouseEmail"
              className={`form-label ${styles.formLabel}`}
            >
              Customer e-mail address :
            </label>
            <input
              type="email"
              className={`form-control ${styles.formControl} ${
                showError && !validateEmail(anonymousEmail.trim())
                  ? "redBorder"
                  : ""
              }`}
              id="anonymouseEmail"
              placeholder="Enter e-mail of the chat visitor"
              onChange={(e: any) => {
                setAnonymousEmail(e.target.value);
              }}
            />
            {/* Dec 30 Show error message when customer email is not valid */}
            {showError && !validateEmail(anonymousEmail.trim()) && (
              <small className={`${styles.errorMsg}`}>
                Please enter a valid customer e-mail!
              </small>
            )}
          </div>
        </>
      )}

      <div className="form-check py-2 mb-2">
        <input
          className="form-check-input"
          type="checkbox"
          value=""
          id="flexCheckDefault"
          checked={sendEmail}
          onChange={(e: any) => {
            setSendEmail(e.target.checked);
          }}
        />
        <label
          className={`${styles.ticketInfo}`}
          htmlFor="flexCheckDefault"
        >
          Send e-mail while creating
        </label>
      </div>

      {/* show send email section */}
      {sendEmail === true ? (
        <>
          <div className={`p-2 mb-2 ${styles.sendMessage}`}>
            <LiveChatSendingDetails
              selectedCustomerEmail={selectedCustomerEmail}
              selectedfromEmail={fromEmail}
              cc={cc}
              setCc={setCc}
              bcc={bcc}
              setBcc={setBcc}
              isIntegrationFailure={
                integrationErrorState?.showSeeWhy && isIntegrationFailure
              }
            />
            <LiveSendingAs
              showReply={showReplyType}
              sendType={sendType}
              setSendType={handleSendMessageType}
              selectedTemplate={(templateText: string) => {
                setTicketDescription(templateText);
              }}
            />
            <TinyMCEContainer
              name="message"
              className={`${styles.message} ${
                sendType === ESendTypes.Note ? styles.active : ""
              }`}
              editorClassName={`${
                ""
                //  styles.editor
              } ${
                showError && isHTMLEmpty(trimPDIVBR(ticketDescription.trim()))
                  ? "redBorder rounded"
                  : ""
              } max-content-height important`}
              value={ticketDescription}
              onChange={(value) => {
                setTicketDescription(value);
              }}
              options={{
                placeholder:
                  sendType === ESendTypes.Note
                    ? `Use "#" to recall related templates`
                    : "Enter your message here",
                max_height: 180,
                min_height: 100,
              }}
              uniqueID={uuid()}
              threadData={
                threadText && threadText != "" && sendType !== ESendTypes.Note
                  ? {
                      threadText: threadText,
                    }
                  : undefined
              }
              showThread={showThread}
              setShowThread={setShowThread}
            />
            {/* Dec 30 Show error message when send type is note and it is empty */}
            {showError &&
              sendType == ESendTypes.Note &&
              isHTMLEmpty(trimPDIVBR(ticketDescription.trim())) && (
                <small className={`${styles.errorMsg}`}>Please add note!</small>
              )}
            {/* Dec 30 Show error message when send type is message and it is empty */}
            {showError &&
              sendType == ESendTypes.Message &&
              isHTMLEmpty(trimPDIVBR(ticketDescription.trim())) && (
                <small className={`${styles.errorMsg}`}>
                  Please add description!
                </small>
              )}
          </div>
        </>
      ) : (
        <></>
      )}

      <div className="d-flex align-items-lg-center flex-column flex-md-row">
        <button
          className={`px-2 py-1 ${styles.submitBtn} d-inline-block ${
            sendEmail === true &&
            integrationErrorState?.showSeeWhy &&
            isIntegrationFailure
              ? styles.disbledBtn
              : ""
          }`}
          onClick={createTicketSubmit}
          disabled={
            integrationErrorState?.showIntegrationError &&
            isIntegrationFailure &&
            integrationErrorState?.showSeeWhy
          }
        >
          {createTicketFromLiveChatAjaxStatus === "pending" ? (
            <Spinner
              className="my-auto mx-1"
              animation="border"
              color="white"
              size="sm"
            />
          ) : (
            submitBtn
          )}
        </button>
        {sendEmail === true &&
          isIntegrationFailure &&
          integrationErrorState?.showSeeWhy && (
            <div
              className={`d-flex align-items-center ms-lg-1 ${styles.integrationFail}`}
            >
              <span className="me-1">
                <img
                  src={alertIcon}
                  alt="alert"
                  width={12}
                  height={12}
                />
              </span>
              <span>Cannot create a ticket</span>
              <button onClick={handleCannotSend}>See why?</button>
            </div>
          )}
      </div>
      {/* Integration Failure Modal  */}
      {sendEmail === true &&
        isIntegrationFailure &&
        integrationErrorState?.showSeeWhy &&
        integrationFailure && (
          <IntegrationFailureModal
            show={showCannotSendModal}
            onHide={() => setShowCannotSendModal(false)}
            data={integrationFailure}
          />
        )}
    </div>
  );
};

export default CreateTicket;
