import { getAskForEmailData } from "src/services/LiveChat/Settings/askForEmail/getAskForEmailData";
import { getFormElementOptions } from "src/services/LiveChat/Settings/preChatForm/getPreChatFormData";
import { RootState } from "src/store/store";

const fetchaskForEmailDataThunk = async (
  payload: number|string,
  { getState, dispatch }: { getState: Function; dispatch: Function }
) => {
  const currentState: RootState = getState();

  let formElementOptions = currentState.ticketChatFormSetting.formElementOptions;
  if (formElementOptions.formElementOptionIds.length === 0) {
    formElementOptions = await getFormElementOptions({formType:"ticketForm"});
  }
  
  const askForEmailData = await getAskForEmailData({
    integrationId: payload,
  });

  return {askForEmailData ,formElementOptions };
};

export default { fetchaskForEmailDataThunk};
