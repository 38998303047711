import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import UserAvatar from "src/components/UserAvatar";
import styles from "./CommentModal.module.scss";
import { GetAllArticleFeedback } from "src/services/KnowledgeBase/Settings/Feedback/getAllArticleFeedback.service";
import { GetStatus } from "../FeedbackTable/FeedbackTable";
import { UseMutationResult } from "@tanstack/react-query";
import {
  UpdateArticleFeedbackPayload,
  UpdateArticleFeedbackResponse,
} from "src/services/KnowledgeBase/Settings/Feedback/updateArticleFeedback.service";
import { useParams } from "react-router-dom";
import { useCommentModal } from "./useCommentModal";

type Props = {
  feedbackData: GetAllArticleFeedback;
  onHide: () => void;
  updateStatus: UseMutationResult<
    UpdateArticleFeedbackResponse,
    unknown,
    UpdateArticleFeedbackPayload,
    unknown
  >;
  commentStatus: string;
  setCommentStatus: React.Dispatch<
    React.SetStateAction<
      "pending" | "rejected" | "approved" | "Pending" | "Rejected" | "Approved"
    >
  >;
};

const CommentModal = ({
  feedbackData,
  onHide,
  updateStatus,
  commentStatus,
  setCommentStatus,
}: Props) => {

  const { pageIntegrationId } = useCommentModal();

  return (
    <>
      {/* Modal Header  */}
      <div className="d-flex justify-content-between">
        <p className={`mt-2 ${styles.heading}`}>Comment</p>
        <span className={`cursor-pointer ${styles.closeBtn}`} onClick={onHide}>
          <i className="fa-solid fa-xmark"></i>
        </span>
      </div>

      {/* Modal Body  */}
      {/* Name , Email And Dropdwon For Status  */}
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          <div>
            <UserAvatar size={34} name={feedbackData.feedbackCustomerName} />
          </div>
          <div className="ms-2">
            <div>
              <p className={`mb-0 ${styles.userName}`}>
                {feedbackData.feedbackCustomerName}{" "}
              </p>
              <span className={`d-block ${styles.userEmail}`}>
                {feedbackData.feedbackCustomerEmail}
              </span>
            </div>
          </div>
        </div>
        <div>
          <Dropdown>
            <Dropdown.Toggle
              as={"div"}
              id="dropdown-basic"
              bsPrefix={`px-2 py-1 cursor-pointer dropdown-toggle ${
                commentStatus === "approved" || commentStatus === "Approved"
                  ? styles.customBtn
                  : commentStatus === "rejected" || commentStatus === "Rejected"
                  ? styles.RejectBtn
                  : styles.PendingBtn
              }`}
            >
              <span className="pe-2">{GetStatus(commentStatus)}</span>
            </Dropdown.Toggle>

            <Dropdown.Menu
              bsPrefix={`dropdown-menu p-2 ${styles.dropdownMenu}`}
            >
              <p className={`mb-0 pb-1 ${styles.dropHead}`}>
                Mark Rating status as
              </p>
              <div className={`${styles.hr}`}></div>
              <div className="mt-3">
                <li className={`d-flex justify-content-start ${styles.mainLi}`}>
                  <div className={`${styles.bar}`}></div>
                  <Dropdown.Item
                    className={`${styles.changeStatusbox}`}
                    onClick={() => {
                      setCommentStatus("approved");
                      updateStatus.mutate({
                        articleFeedbackId: feedbackData.id,
                        commentAction: 1,
                        integrationId: Number(pageIntegrationId),
                      });
                    }}
                  >
                    <span className={`${styles.darkColor}`}>Approved</span>
                  </Dropdown.Item>
                </li>
                <li className={`d-flex justify-content-start ${styles.mainLi}`}>
                  <div className={`${styles.bar}`}></div>
                  <Dropdown.Item
                    className={`${styles.changeStatusbox}`}
                    onClick={() => {
                      setCommentStatus("pending");
                      updateStatus.mutate({
                        articleFeedbackId: feedbackData.id,
                        commentAction: 2,
                        integrationId: Number(pageIntegrationId),
                      });
                    }}
                  >
                    <span className={`${styles.darkColor}`}>Pending</span>
                  </Dropdown.Item>
                </li>
                <li className={`d-flex justify-content-start ${styles.mainLi}`}>
                  <div className={`${styles.bar}`}></div>
                  <Dropdown.Item
                    className={`${styles.changeStatusbox}`}
                    onClick={() => {
                      setCommentStatus("rejected");
                      updateStatus.mutate({
                        articleFeedbackId: feedbackData.id,
                        commentAction: 3,
                        integrationId: Number(pageIntegrationId),
                      });
                    }}
                  >
                    <span className={`${styles.darkColor}`}>Rejected</span>
                  </Dropdown.Item>
                </li>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      {/* Comment */}
      <div className={`mt-2 ${styles.commentBox}`}>
        <span className={`p-2 ${styles.commentMsg}`}>
          {feedbackData.comment.commentText}
        </span>
      </div>

      {/* Time  */}
      <p className="text-end">
        {" "}
        <span className={`${styles.timeShow}`}>
          <span className={`${styles.time}`}>
            <i className="fa-solid fa-clock"></i>
          </span>{" "}
          {new Date(feedbackData.comment.commentCreatedAt).toLocaleString(
            "en-US",
            {
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            }
          )}
        </span>
      </p>
    </>
  );
};

export default CommentModal;
