import React from "react";
import styles from "./NotificationBox.module.scss";
import caution from "src/assets/images/caution.png";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import NotificationTable from "./Children/NotificationTable/NotificationTable";
import useNotificationBox from "./hooks/useNotificationBox";
import { ReportTabNames } from "../../../ReportTabs/ReportTabs";
import useNotifyPermission from "../../hooks/notifications/useNotifyPermission";
import Loader from "src/components/Loader";

interface Props {
  activeTabname: ReportTabNames;
  setActiveTabname: React.Dispatch<React.SetStateAction<ReportTabNames>>;
}
const NotificationBox = ({ activeTabname, setActiveTabname }: Props) => {
  const { reportNotifications, isLoading, showEditNotification, isError } =
    useNotificationBox({ activeTabname, setActiveTabname });
  const notifyPermission = useNotifyPermission();

  /* Display error message if api is rejected */
  if (isError) {
    return (
      <div className="d-flex align-items-center justify-content-center w-100 mt-3 mb-3">
        <div className="text-danger text-center">Error loading content...</div>
      </div>
    );
  }

  /* Display loader if data is still pending */
  if (isLoading) {
    return (
      <div className="d-flex align-items-center justify-content-center w-100">
        <Loader className="pt-0 mt-0" imgClassName="pt-0 mt-0" />
      </div>
    );
  }

  return (
    <div>
      <div className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items-md-center">
        <h3 className={`mb-2 mb-md-0 ${styles.chartHead} me-2`}>
          Total number of unresolved tickets
        </h3>
        {notifyPermission === "edit" && (
          <span className={`${styles.alert}`} onClick={showEditNotification}>
            + New alert
          </span>
        )}
      </div>
      <div>
        <p className={`mt-2 ${styles.allNotification}`}>
          {`All notifications (${reportNotifications.notificationsData.metaData.totalCount})`}
        </p>
        <div className={`${styles.tableMain}`}>
          {reportNotifications.notificationsData.notificationIds.map((id) => {
            return (
              <NotificationTable
                key={id}
                notificationData={
                  reportNotifications.notificationsData.notifications[id]
                }
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default NotificationBox;
