/**
 * This file is the service file used for making api request.
 * It contains the getAllArticle function service which is explained below.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { axiosKbPreview } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants"; // this is used for testing purpose only for frontend.

export interface ArticleAuthorDetails {
  id: number;
  name: string;
  imageURL: string;
}

export interface ArticleDetails {
  articleId: number;
  articleName: string;
  articleDescription: string;
  searchKeyword: null | string;
  createdAtGmt: string;
  updatedAtGmt: string;
  articleAuthorDetails: ArticleAuthorDetails | null;
  attachments: [];
}

export interface getAllArticleResponse {
  data: ArticleDetails[];
  metaData: {
    currentCount: number;
    totalCount: number;
  };
  tokenExpire: boolean;
}

export interface getAllArticlePayload {
  integrationIdentifier: string;
  start: number | string;
  limit: number | string;
  searchTerm?: string;
  token?: string;
  categoryId?: string;
}

/**
 * For testing frontend with own api link if backend is not prepared.
 */
const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/`
  : "/api/knowledgebase/helpCenterPage/";

/**
 * This service is to get all the articles.
 */
export const getAllArticle = async (params: getAllArticlePayload) => {
  const { data: res } = await axiosKbPreview.post(
    `${apiEndPoint}${params.integrationIdentifier}${
      params.categoryId ? `/getAllArticles` : `/getFeaturedArticles`
    }`,
    {
      start: params.start,
      limit: params.limit,
      searchTerm: params.searchTerm,
      categoryId: params.categoryId ? params.categoryId : undefined,
    }
  );

  if (res.err) {
    return {
      err: res.err,
      tokenExpire: res.tokenExpire,
    };
  }

  return res as getAllArticleResponse;
};
