import { useCallback, useEffect, useId, useState } from "react";
import styles from "./MergeModal.module.scss";
import closeModal from "src/assets/images/close(3).png";
import SuccessMergeModal from "../SuccessMergeModal/SuccessMergeModal";
import { Dropdown, Modal, Spinner } from "react-bootstrap";
import useBugPriorityList from "../../../FilterSection/Priority/useBugPriorityList";
import useBugCategoryList from "../../../FilterSection/BugCategory/useBugCategoryList";
import InfiniteScroll from "src/components/InfiniteScroll";
import Loader from "src/components/Loader";
import { capitalizeFirstLetter } from "../../../helper/helperFunction";
import IssueDescription from "../../../TableRow/Children/BugReportModal/IssueDescription/IssueDescription";
import mergeBug, {
  MergeBugReport,
} from "src/services/BugReport/mergeBug.service";
import { useMutation } from "@tanstack/react-query";
import ProfileImgGroup from "../../../ProfileImgGroup/ProfileImgGroup";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { BugData } from "src/services/BugReport/getBugByCategory.service";
interface Props {
  onHide: () => void;
  selectedBugsData: BugData[];
  setSelectedBugId: React.Dispatch<React.SetStateAction<Set<number>>>;
}
interface BugCategory {
  id?: number;
  name: string | undefined;
}

const MergeModal = ({ onHide, selectedBugsData, setSelectedBugId: selectedIds }: Props) => {
  const [selectedBugId, setSelectedBugId] = useState<Set<number>>(
    new Set(selectedBugsData.map((bug) => bug.bugId)),
  );
  const [isMerge, setIsMerge] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<BugCategory>({
    name: "",
  });
  const [selectedPriority, setSelectedPriority] = useState<string>("");
  const [mergeDoneData, setMergeDoneData] = useState<MergeBugReport>();
  const [show, setShow] = useState(false);
  const [showLoadMore, setShowLoadMore] = useState(false);
  const [ddOpen, setDdOpen] = useState(false);
  const componentId = useId();

  useEffect(() => {
    if (selectedBugsData.length > 0) {
      const oldestBug = selectedBugsData.reduce((oldest, current) => {
        const oldestDate = new Date(oldest.bugCreatedAtGmt);
        const currentDate = new Date(current.bugCreatedAtGmt);
        return currentDate < oldestDate ? current : oldest;
      });
      const priorities = selectedBugsData.map((bug) => bug.priorityKey);
      if (priorities.includes("needThisFixedRightAway")) {
        setSelectedPriority("needThisFixedRightAway");
      } else if (priorities.includes("high")) {
        setSelectedPriority("high");
      } else setSelectedPriority("normal");

      setSelectedCategory({ name: oldestBug.categoryName });
    } else {
      setSelectedPriority("");
      setSelectedCategory({ name: "" });
    }
  }, [selectedBugsData]);

  const { allBugPriority } = useBugPriorityList();
  const {
    bugCategories,
    isError,
    isLoading,
    nextPageLoad,
    hasNextPage,
    bugCategoriesIds,
    fetchStatus,
  } = useBugCategoryList();

  const fetchNextPageHandler = useCallback(() => {
    nextPageLoad();
    setShowLoadMore(false);
  }, [nextPageLoad]);

  /**
   * Effect for changing load more button state
   */
  useEffect(() => {
    if (fetchStatus === "idle") {
      setShowLoadMore(true);
    }
  }, [fetchStatus]);

  const {
    mutate,
    isError: mergingError,
    isLoading: mergingLoading,
  } = useMutation({
    mutationFn: mergeBug,
    onSuccess: (data) => {
      setMergeDoneData(data);
      setIsMerge(true);
    },
  });

  const handleSubmitMerge = useCallback(() => {
    if (selectedBugId.size < 2) {
      pushTheToast({
        type: "warning",
        text: "Selecte atleast 2 bugs to merge!",
        position: "top-right",
      });
      return;
    }
    //find selected bugs and get their ids
    const selectedBugBrand = selectedBugsData
      .filter((bug) => selectedBugId.has(bug.bugId))
      .map((bug) => bug.brandId);

    // Check if all bug's brandId is the same
    if (new Set(selectedBugBrand).size !== 1){
      setShow(true);
      return;
    }

    let payload: {
      bugIds: number[];
      categoryId?: number;
      priorityKey: string;
    } = { bugIds: [...selectedBugId], priorityKey: selectedPriority };
    if (selectedCategory.id) {
      payload.categoryId = selectedCategory.id;
    }
    mutate(payload);
  }, [selectedBugId, selectedCategory, selectedPriority]);

  // const showCategories = Object.values(bugAllCategory?.allBugCategory ?? {});
  const showPriority = Object.values(allBugPriority?.allBugPriority ?? {});

  return !isMerge ? (
    <div className={`${styles.mergeModalWrapper}`}>
      <div className="d-flex justify-content-between align-items-center">
        {/* invisible for merge modal  */}
        <div className="">
          <h4 className={`mb-2 ${styles.modalHead}`}>
            Merge {selectedBugId.size} bug reports?
          </h4>
          <span className={`${styles.modalSubHead}`}>
            {" "}
            The following bugs reports will be merged.
          </span>
        </div>
        <img
          src={closeModal}
          alt=""
          height={12}
          width={12}
          onClick={onHide}
          className="cursor-pointer"
        />
      </div>
      <div className={`${styles.mergeModalContainer}`}>
        <div className="d-flex align-items-center flex-wrap mb-4 pb-2">
          <ProfileImgGroup
            customers={selectedBugsData.flatMap((data) => data.customerDetails)}
            companyName={selectedBugsData.flatMap((data) => data.companyName)}
          />
          {/* <BugCategory /> */}
          <Dropdown
            show={ddOpen}
            onToggle={setDdOpen}
          >
            {/* Dropdown toggle button */}
            <Dropdown.Toggle
              as="div"
              bsPrefix={`dropdown-toggle cursor-pointer border-0 px-2 py-1 me-2  mb-2 mb-lg-0 ${styles.filterButton}`}
            >
              Category: {selectedCategory.name}
              {/* Display dropdown arrow */}
            </Dropdown.Toggle>

            {/* Dropdown menu */}
            <Dropdown.Menu
              bsPrefix={`dropdown-menu border-0 ${styles.filterMenu}`}
            >
              <div className={`${styles.dropMenu}`}>
                {/* List of filters */}
                <div className={`${styles.itemWrapper}`}>
                  <div>
                    {fetchStatus === "fetching" &&
                    bugCategoriesIds.length === 0 ? (
                      <div>
                        <Loader />
                      </div>
                    ) : (
                      <InfiniteScroll
                        loadMore={
                          fetchStatus === "idle"
                            ? fetchNextPageHandler
                            : () => {}
                        }
                        hasMore={hasNextPage}
                        initialDataLoaded={true}
                        className={`${styles.filterList}`}
                        loader={
                          <div>
                            {showLoadMore ? (
                              // Rendering the load button conditionally.
                              <div
                                className={`${styles.loaderText}`}
                                onClick={fetchNextPageHandler}
                              >
                                <span>Load More</span>
                                <i className="ms-1 fa-solid fa-rotate-right"></i>
                              </div>
                            ) : (
                              // Rendering the load text conditionally.
                              <div
                                className={`${styles.loadingText} d-flex align-items-center`}
                              >
                                <span>Loading...</span>
                                <div>
                                  <Spinner
                                    className={`ms-1 ${styles.spinner}`}
                                    size="sm"
                                    animation="border"
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        }
                      >
                        {isError ? (
                          <span
                            className={`d-flex align-items-center justify-content-center mt-3 ${styles.errorMessage}`}
                          >
                            Something went worng...
                          </span>
                        ) : (
                          bugCategoriesIds.map((categoryId: any) => {
                            return (
                              <div
                                className={`${styles.specificItem}`}
                                key={categoryId}
                                onClick={() => {
                                  setSelectedCategory({
                                    id: categoryId,
                                    name: bugCategories[categoryId].name
                                      ? capitalizeFirstLetter(
                                          bugCategories[categoryId].name,
                                        )
                                      : "",
                                  });
                                  setDdOpen(false);
                                }}
                              >
                                <div
                                  className={``}
                                  key={categoryId}
                                >
                                  <label
                                    className={`cursor-pointer form-check-label w-100 pe-2 ${styles.checktext}`}
                                    id={
                                      componentId + "selectFilter" + categoryId
                                    }
                                    htmlFor={
                                      componentId + "selectFilter" + categoryId
                                    }
                                  >
                                    {/* Display user information */}
                                    <div className="d-flex justify-content-between align-items-center">
                                      <div className="w-100 text-truncate">
                                        <span className="ps-2">
                                          {capitalizeFirstLetter(
                                            bugCategories[categoryId].name,
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  </label>
                                </div>
                              </div>
                            );
                          })
                        )}
                      </InfiniteScroll>
                    )}
                  </div>
                </div>
              </div>
            </Dropdown.Menu>
          </Dropdown>
          {/* <Priority /> */}
          <Dropdown className="ms-1">
            <Dropdown.Toggle
              variant=""
              id="dropdown-basic"
              className={`d-flex align-items-center ${styles.dropdownBorder}
              ${
                selectedPriority === "normal"
                  ? styles.priorityBlue
                  : selectedPriority === "high"
                    ? styles.priorityGreen
                    : styles.priorityRed
              }
              `}
            >
              <div>Priority:</div>
              <span className={`px-1 ${styles.submitText}`}>
                {selectedPriority === "needThisFixedRightAway"
                  ? "I need this fixed right away"
                  : capitalizeFirstLetter(selectedPriority)}
              </span>
            </Dropdown.Toggle>

            <Dropdown.Menu className={`${styles.dropdownBox}`}>
              {showPriority.map((priority) => (
                <Dropdown.Item
                  className={`d-flex align-items-center ${styles.dropdownItem}`}
                  onClick={() => setSelectedPriority(priority.key)}
                  key={priority.id}
                >
                  {priority.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className={`p-1 ${styles.issueWrapper}`}>
          {selectedBugsData.map((bug) => (
            <IssueDescription
              key={bug.bugId}
              isUserShow={true}
              bugData={bug}
              isSelectedForMerge={selectedBugId.has(bug.bugId)}
              setSelectedBugId={setSelectedBugId}
            />
          ))}
        </div>
        <div className="d-flex justify-content-end pt-2 mt-1">
          <button
            className={`${styles.mergeBtn}`}
            onClick={handleSubmitMerge}
          >
            Merge bugs
            {mergingLoading && (
              <Spinner
                className="ms-1"
                size="sm"
              />
            )}
          </button>
        </div>
      </div>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        centered={true}
      >
        <div className="p-5 d-flex flex-column align-items-center">
          <span className={styles.notMergeText}>
            Not all bugs you have selected are from the same brand. Only bugs
            from the same brand can be merged.
          </span>
          <button
            className={`${styles.notMergeBtn} mt-2`}
            onClick={() => setShow(false)}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  ) : (
    <SuccessMergeModal
      mergeData={mergeDoneData}
      onHide={onHide}
      setSelectedBugId={selectedIds}
    />
  );
};

export default MergeModal;
