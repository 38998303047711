import { axiosJSON } from "src/globals/axiosEndPoints";

export interface LiveChatHistoryMessagesPayoad {
  chatRequestId: number | string;
  start?: number;
  limit?: number;
}

export interface HistoryCustomerData {
  chatId: number | string;
  customerId: number | string;
  name?: string;
  email: string;
  subject?: string;
  imageURL?: null | string;
  lastMessage?: string;
  lastMessageTime?: string;
  isRead: boolean;
  missedMessages: number;
  receivedMessages: number;
  channelName: string;
  customerStatus?: "live" | "missed" | "archived";
  lastMessageId: number;
}

export interface HistoryMessageSentBy {
  id: number | string;
  name?: string;
  email: string;
  imageURL?: null | string;
}

export interface HistoryMessageData {
  messageId: number | string;
  messageText: string;
  sentBy: HistoryMessageSentBy;
  sendTime: string;
  sendTimeGmt: string;
  isEvent?: null | any;
  sentByType?: "in" | "out";
  senderType?: "Agent" | "Customer";
  isInternalNote?: null | any;
}

export interface LiveChatHistoryMessagesData {
  customerData: null | HistoryCustomerData;
  messageList: { [messageId: number | string]: HistoryMessageData };
  messageListIds: Array<number | string>;
  metaData: {
    count: number;
    totalCount: number;
  };
}

const getLiveChatHistoryMessages = async (
  payload: LiveChatHistoryMessagesPayoad
) => {
  const { data: res } = await axiosJSON.post(
    "/api/liveChat/chat/history",
    payload
  );
  if (res.err) {
    throw res.msg;
  }
  const ret: LiveChatHistoryMessagesData = {
    customerData: res.data.customerData,
    messageList: {},
    messageListIds: [],
    metaData: res.metaData,
  };
  for (let i = 0; i < res.data.messageData.length; i++) {
    const message = res.data.messageData[i];
    ret.messageList[message.messageId] = message;
    ret.messageListIds.push(message.messageId);
  }
  return ret;
};

export default getLiveChatHistoryMessages;
