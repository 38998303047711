import { useCallback, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { useReturnAutoWorkFlow } from "../../hooks/useReturnAutoWorkFlow";
import OrderInfoModal from "./OrderInfoModal/OrderInfoModal";
import NoEditErrorModal from "./NoEditErrorModal/NoEditErrorModal";
import orderInfoModalStyles from "./OrderInfoModal/OrderInfoModal.module.scss";
import noEditErrorModalStyles from "./NoEditErrorModal/NoEditErrorModal.module.scss";
import moveToNextModalStyles from "./MoveNextModal/MoveNextModal.module.scss";
import MoveNextModal from "./MoveNextModal/MoveNextModal";

function StaticModals() {
  const { returnAutoWorkFlow, dispatch } = useReturnAutoWorkFlow();
  const activeStaticModal = useMemo(
    () => returnAutoWorkFlow.activeStaticModal,
    [returnAutoWorkFlow],
  );

  /**
   * Boolean for showing/hiding the modal
   */
  const showModal = useMemo(
    () => activeStaticModal !== null,
    [activeStaticModal],
  );

  const hideModal = useCallback(() => {
    dispatch("setState", {
      activeStaticModal: null,
      activeStaticModalStepId: null,
      activeStaticModalQuestionId: null,
    });
  }, [dispatch]);

  /**
   * Object that gets styles based on active modal
   */
  const styles = useMemo(() => {
    if (activeStaticModal === "emailForwardingOrderInfo") {
      return orderInfoModalStyles;
    } else if (
      activeStaticModal === "noEditErrorModal" ||
      activeStaticModal === "noEditErrorQuestionModal"
    ) {
      return noEditErrorModalStyles;
    } else if (activeStaticModal === "moveToNextModal") {
      return moveToNextModalStyles;
    } else {
      return {};
    }
  }, [activeStaticModal]);

  /**
   * Active Modal component based on event type
   */
  const ModalComponent = useMemo(() => {
    switch (activeStaticModal) {
      case "emailForwardingOrderInfo":
        return <OrderInfoModal onHide={hideModal} />;
      case "noEditErrorModal":
        return (
          <NoEditErrorModal
            context="configModal"
            onHide={hideModal}
          />
        );
      case "noEditErrorQuestionModal":
        return (
          <NoEditErrorModal
            context="question"
            onHide={hideModal}
          />
        );
      case "moveToNextModal":
        return <MoveNextModal onHide={hideModal} />;
      default:
        return <div></div>;
    }
  }, [hideModal, activeStaticModal]);

  return (
    <Modal
      show={showModal}
      onHide={hideModal}
      /**
       * Adding the default stylings and then custom modal styling based on configuration key
       */
      dialogClassName={`${styles.modalDialog}`}
      contentClassName={`${styles.modalContent}`}
      backdropClassName={`${styles.modalBackdrop}`}
      centered={true}
      enforceFocus={false} // Needed for TinyMCE Editor
      backdrop={
        activeStaticModal === "emailForwardingOrderInfo" ? "static" : undefined
      } // Stops closing the modal by clicking backdrop
    >
      {ModalComponent}
    </Modal>
  );
}

export default StaticModals;
