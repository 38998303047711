/**
 * This file is the service file used for making api request.
 * It contains the updateDomainSSLAndAccesControl function service which is explained below.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants"; // this is used for testing purpose only for frontend.

export interface DoaminSSLAndDomainAccessPayload {
  integrationId: number | string;
  customDomain?: boolean; // If a user wants custom domain we save it and the helpcenter may show on that domwin.
  customDomainUrl?: string; // may beused to load the helpcenter on the custom URL of the tenant domain.
  sslCertificate?: "freeSSL" | "customSSL";
  passwordRestriction?: boolean; // password restriction checkbox. If enabled then the helpcenter will require password to view the articles.
  password?: string; // the password to open the helpcenter.
  isIpWhitelist?: boolean; // IP whitelist check boc to ristrict or avail access.
  ipWhitelistList?: string; // list of IP in a specific format expolained in the component.
}

/**
 * For testing frontend with own api link if backend is not prepared.
 */
const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/updateDomainSSLAndAccesControl`
  : "/api/chatSetting/updateDomainSSLAndAccesControl";

/**
 * This service is created to update the domain ad ssl and access control data.
 */
export const updateDomainSSLAndAccesControl = async (
  params: DoaminSSLAndDomainAccessPayload
) => {
  const { data: res } = await axiosJSON.post(apiEndPoint, params);
  return res.data as DoaminSSLAndDomainAccessPayload;
};
