/**
 * This file defines a React component represent custom styled dropdown for Audience configure modal
 *
 * @author @yuvaraj-busibud
 */
import React, { useRef, useState } from "react";
import styles from "./CustomSelectDropdown.module.scss";
import Select, {
  components,
  ControlProps,
  MenuProps,
  OptionProps,
  SingleValueProps,
} from "react-select";

interface Option {
  value: string | number;
  label: string;
}

const CustomOption: React.FC<OptionProps<Option>> = (props) => (
  <components.Option {...props}>
    <div className={`${styles.items}`}>
      <span>{props.data?.label}</span>
    </div>
  </components.Option>
);

const CustomSingleValue: React.FC<SingleValueProps<Option>> = (props) => (
  <components.SingleValue {...props}>
    <div className={`${styles.items}`}>
      <span>{props.data?.label}</span>
    </div>
  </components.SingleValue>
);

const CustomControl: React.FC<ControlProps<Option>> = ({
  children,
  innerRef,
  innerProps,
  isFocused,
  className,
}) => {
  return (
    <div
      ref={innerRef}
      className={`custom-control-container w-100 ${
        isFocused ? styles.focusStyle : ""
      }`}
    >
      <div {...innerProps} className={className}>
        {children}
      </div>
    </div>
  );
};

const CustomDropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <i
        className={`fa-solid ${
          props.selectProps.menuIsOpen ? "fa-caret-up" : "fa-caret-down"
        }`}
      ></i>
    </components.DropdownIndicator>
  );
};

const customStyles = {
  // Other styles for the React Select can be defined here

  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#f5f5f5" : "#fff", // Change the hover color here
    "&:hover": {
      backgroundColor: "#f5f5f5", // Change the hover color here as well
    },
    padding: "10px",
  }),
  menuList: (provided: any) => ({
    ...provided,
    margin: "0 5px", // Add 5px margin on the left and right sides of the menu
  }),
};

interface Props {
  options: Option[];
  selectedOption: Option[] | Option | undefined;
  setSelectedOption: (value: Option[] | Option) => void;
  menuClassName?: string;
  hasMore?: boolean;
  loadMore?: () => void;
  isLoading?: boolean;
  placeholder?: string;
  isSearchable?: boolean;
  isMultiSelect?: boolean;
  error?: string | null;
  handleSearch?: (value: string) => void;
}
const CustomSelectDropdown = ({
  options,
  selectedOption,
  setSelectedOption,
  menuClassName = "",
  hasMore = false,
  loadMore = () => {},
  isLoading = false,
  placeholder = "Select a category",
  isSearchable = false,
  isMultiSelect = false,
  error,
  handleSearch = () => {},
}: Props) => {
  // const options = [
  //   { value: "email", label: "E-mail" },
  //   { value: "name", label: "Name" },
  //   { value: "phone", label: "Phone" },
  //   { value: "country", label: "Country" },
  //   { value: "tag", label: "Tag" },
  //   { value: "browserLanguage", label: "Browser language" },
  //   { value: "referralUrl", label: "Referral URL" },
  //   { value: "currentPageUrl", label: "Current page URL" },
  // ];

  // const [selectedOption, setSelectedOption] = useState<Option[]>();

  const handleChange = (selectedOption: any) => {
    setSelectedOption(selectedOption);
  };

  return (
    <Select
      value={selectedOption}
      onChange={handleChange}
      options={options}
      className={`${styles.text}`}
      placeholder={placeholder}
      components={{
        Option: CustomOption,
        SingleValue: CustomSingleValue,
        // Menu: CustomMenu,
        Control: (props) => (
          <CustomControl
            {...props}
            className={`custom-control ${menuClassName} ${
              error ? styles.borderError : ""
            }`}
          />
        ), // Pass the className directly to CustomControl
        DropdownIndicator: CustomDropdownIndicator,
        IndicatorSeparator: null,
      }}
      styles={customStyles}
      isSearchable={isSearchable}
      isMulti={isMultiSelect}
      isLoading={isLoading}
      onMenuScrollToBottom={loadMore}
      onInputChange={(inputValue) => handleSearch(inputValue)}
    />
  );
};

export default CustomSelectDropdown;
