import React, { useState } from "react";
import Loader from "src/components/Loader";
import styles from "./SaveAsModal.module.scss";
interface Props {
  viewName: string;
  onChange: (e: any) => void;
  onHide: (e: any) => void;
  addViewLoading: boolean;
  onSubmitHandler: (e: any) => void;
  showViewNameError: string;
}
const SaveAsModal = ({
  addViewLoading,
  onHide,
  onChange,
  viewName,
  onSubmitHandler,
  showViewNameError,
}: Props) => {
  return (
    <div>
      <div className={`w-100 text-center position-relative`}>
        <span className={`d-inline-block mx-auto mb-4 ${styles.saveView}`}>
          Save View as{" "}
        </span>
        <span
          className={`cursor-pointer ${styles.closeModal}`}
          onClick={onHide}
        >
          <i className="fa-solid fa-xmark"></i>
        </span>
      </div>
      {addViewLoading === true ? (
        <Loader />
      ) : (
        <form onSubmit={onSubmitHandler}>
          <div className="mb-4 mt-2 px-1">
            <label htmlFor="VName" className={`form-label ${styles.formLabel}`}>
              View Name
            </label>
            <input
              type="text"
              className={`form-control ${styles.inputBox} ${
                showViewNameError.length ? styles.reqInputBorder : ""
              }`}
              id="VName"
              value={viewName}
              onChange={onChange}
              placeholder="Enter view name"
              required={true}
            />
            <span className={`${styles.labelCaution}`}>
              {showViewNameError}
            </span>
          </div>
          <div className={`d-flex align-items-center justify-content-end px-1`}>
            <button
              type="submit"
              className={`${styles.saveBtn}`}
              disabled={viewName.length < 1}
            >
              Save
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default SaveAsModal;
