import UserAvatar from "src/components/UserAvatar";
import styles from "./TicketCustomer.module.scss";
import { useAppSelector } from "src/store/store";
import { selectTicketCellData } from "src/store/slices/tickets/tickets.slice";
import { TableCellProps } from "..";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const dummyImageUrl = "https://picsum.photos/200/300?random=2";

// function TicketCustomer({ ticket }: { ticket: Ticket }) {
function TicketCustomer({ ticketId, colKeys }: TableCellProps) {
  const ticketCellData: any = useAppSelector((state) =>
    selectTicketCellData(state, ticketId, colKeys)
  );
  return (
    <>
      <OverlayTrigger
        placement={"top"}
        overlay={<Tooltip>{ticketCellData[0]}</Tooltip>}
      >
        <div className={`${styles["subject"]}`}>
          <span className={`${styles["tbody"]}`}>
            <span className={`me-2 ${styles.userImg}`}>
              <UserAvatar name={ticketCellData[0] || "NA"} size={30} />
            </span>
            <span className={`${styles["custName"]} text-break`}>
              {ticketCellData[0] ?? "NA"}
            </span>
          </span>
        </div>
      </OverlayTrigger>
    </>
  );
}

export default TicketCustomer;
