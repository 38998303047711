import { CallRecordingSlice, initialState } from "./callRecording.declarations";

const resetCallRecording = () => {
  return initialState;
};
const setCatchedPage = (
  state: CallRecordingSlice,
  { payload }: { payload: { pageNo: number } }
) => {
  state.currentPage = payload.pageNo;
  if (state.cachedData[payload.pageNo]) {
    state.callerIdList = state.cachedData[payload.pageNo];
  }
};

export default { resetCallRecording, setCatchedPage };
