import ParseHTML from "src/components/ParseHTML";
import { ESendTypes } from "../SendMessage/children/SendingAs";
import styles from "./TicketMsg.module.scss";
import InnerChatAttachments from "src/components/InnerChatAttachments/InnerChatAttachments";
import { showAttachment } from "src/services/Attachment/showAttachment";
import { useEffect, useRef, useState } from "react";
import CreateTemplate from "src/components/Template/children/CreateTemplate/CreateTemplate";
import { CloseButton, Dropdown, Modal } from "react-bootstrap";
import { removeImagesNSeeMoreFromMessage } from "src/utils/utils";
import { useAppSelector } from "src/store/store";
import { highlightSearchedText } from "src/routes/TicketList/children/OuterTickets/Chidren/SearchedTickets/SearchedTickets";
import CreateTemp from "src/components/ViewTemplateV2/Children/CreateTemp/CreateTemp";
import MsgSent from "src/components/ShowReadUnread/Children/MsgSent/MsgSent";

const MoreDD = ({
  parentRef,
  messageData,
  rightAlign,
  handleCreatedTemplateCreatedAndSelectedFromMsg,
}: any) => {
  const [showDD, setShowDD] = useState(false);
  const scopes = useAppSelector(
    (state) => state.globals.currentUserData?.scopes,
  );
  const permission = scopes?.includes("edit_templates");
  const [showCreateTemplateFromMsgModal, setShowCreateTemplateFromMsgModal] =
    useState<boolean>(false);

  const handleCreateTemplateFromMsg = (e: any) => {
    setShowCreateTemplateFromMsgModal(true);
  };
  const onHideCreateTemplateFromMsgModal = () => {
    setShowCreateTemplateFromMsgModal(false);
  };

  useEffect(() => {
    if (parentRef.current) {
      parentRef.current.addEventListener("mouseenter", (e: any) => {
        setShowDD(true);
      });
      parentRef.current.addEventListener("mouseleave", (e: any) => {
        setShowDD(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [false]);

  return (
    <>
      <div
        className={`mx-2`}
        style={{ width: "24px", height: "24px", minWidth: "24px" }}
      >
        {rightAlign === true && showDD && permission && (
          <Dropdown bsPrefix={`${styles.dropdownlist}`}>
            <Dropdown.Toggle
              as="div"
              bsPrefix={`${styles.dropDownArrow}`}
            >
              <i className="fa-solid fa-ellipsis-vertical"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu bsPrefix={`dropdown-menu ${styles.showDropDown}`}>
              <Dropdown.Item
                bsPrefix={`dropdown-item ${styles.customDropdownItem}`}
                onClick={(e) => handleCreateTemplateFromMsg(e)}
              >
                <span>
                  <i className="fa-solid fa-bookmark me-1"></i>
                </span>
                Save message to templates
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>
      {/* show create template modal */}
      {showCreateTemplateFromMsgModal === true ? (
        <>
          <Modal
            backdropClassName={`${styles.modalBack}`}
            show={showCreateTemplateFromMsgModal}
            onHide={() => onHideCreateTemplateFromMsgModal()}
            dialogClassName={`${styles.modalDialog}`}
            contentClassName={`${styles.modalContent} ${styles.height}`}
            centered={true}
            enforceFocus={false}
          >
            <div className={`h-0 w-100 d-flex justify-content-end`}>
              <CloseButton
                className={`m-2 mt-3 me-3 p-0`}
                style={{ height: "14px", width: "14px", zIndex: 1080 }}
                onClick={onHideCreateTemplateFromMsgModal}
              />
            </div>
            <div className={`${styles.createTemplateMain}`}>
              {/* <CreateTemplate
                // createTemplateText={messageData.message}
                createTemplateText={removeImagesNSeeMoreFromMessage(
                  messageData.message
                )}
                onTemplateSelect={(templateText: string) => {
                  handleCreatedTemplateCreatedAndSelectedFromMsg(templateText);
                  onHideCreateTemplateFromMsgModal();
                }}
                onViewChange={() => {
                  onHideCreateTemplateFromMsgModal();
                }}
              /> */}

              <CreateTemp
                createTemplateText={removeImagesNSeeMoreFromMessage(
                  messageData.message,
                )}
                currentView={"create"}
                // setCurrentView={setCurrentView}
                hideBack={true}
              />
            </div>
          </Modal>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

const TicketMsg = ({
  messageData,
  rightAlign,
  handleCreatedTemplateCreatedAndSelectedFromMsg,
}: {
  messageData: any;
  rightAlign: any;
  handleCreatedTemplateCreatedAndSelectedFromMsg: any;
}) => {
  const parentRef = useRef(null);
  const searchTerm = useAppSelector(
    (state) => state.ticketGlobalSearch.searchText,
  );
  return (
    <>
      {/* <div className="d-flex flex-column" style={{width: "fit-content", marginLeft: rightAlign === true ? "auto" : undefined,  marginRight: rightAlign === true ? undefined : "auto"}}> */}
      <div
        className={`d-flex justify-content-end ${
          rightAlign === true ? "" : `flex-row-reverse`
        }`}
        ref={parentRef}
      >
        <MoreDD
          parentRef={parentRef}
          messageData={messageData}
          rightAlign={rightAlign}
          handleCreatedTemplateCreatedAndSelectedFromMsg={
            handleCreatedTemplateCreatedAndSelectedFromMsg
          }
        />
        <div
          className={`${
            (messageData.messageType as any) === ESendTypes[2]
              ? styles.noteDataDiv
              : styles.messageDataDiv
          } ${rightAlign ? styles.right : ""}`}
        >
          <ParseHTML
            className={`${styles.msgText} ${rightAlign ? styles.right : ""} ${
              (messageData.messageType as any) === ESendTypes[2]
                ? styles.note
                : ""
            }`}
            html={
              messageData
                ? (highlightSearchedText(
                    messageData.message,
                    searchTerm,
                    true,
                  ) as string)
                : ""
            }
          />
          {messageData &&
            messageData.attachments &&
            messageData.attachments.length > 0 && (
              <InnerChatAttachments messageData={messageData} />
            )}
        </div>
      </div>{" "}
      {/* {messageData?.messageInfo && ( */}
      {/* <div className="mx-2">
          <MsgSent status={messageData.messageInfo} />
        </div> */}
      {/* )} */}
      {/* </div> */}
    </>
  );
};
export default TicketMsg;
