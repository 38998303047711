import {
  createAsyncThunk,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";

import teamSettingsReducers from "./teamSettings.reducers";
import teamSettingsThunk from "./teamSettings.thunk";
import { fetchAllTeamsFulfilled, fetchAllTeamMembersFulfilled } from "./teamSettings.extraReducers";
import { getActiveTeamData, getTeamDataFromId } from "./teamSettings.selectors";

// interface that define a single team
export interface ITeamData{
  teamId: number,
  teamName: string,
  users: number
}


export type TeamsType = {
  // teamData: {
  //   [teamId: number | string ] : ITeamData
  // };
  teamData: any;
  teamIdList: any;
  fetchTeamsAjaxStatus: "pending" | "fulfilled" | "rejected";
  fetchTeamsLimit: number,
  // totalTeamsCount : number
  metaData:{
    count: number,
    total: number
  },

  usersInTeam: any,
  usersInTeamIdList: any,
  teamMateSearchText: string,
  usersInTeamCount: number,
  usersInTeamTotalCount: number,
  fetchTeamMemberLimit: number,
  activeTeam: ITeamData,
  fetchUsersInTeamAjaxStatus: "pending" | "fulfilled" | "rejected";

  allUsers: any,
  allUsersIdList: any,
  allUsersCount: number,
  allUsersTotalCount: number,
  fetchAllUsersLimit: number,
  addMemberSearchText: string,
  fetchAllUsersAjaxStatus: "pending" | "fulfilled" | "rejected";
} 

// to fetch all teams
export const fetchAllTeams = createAsyncThunk(
  "teamSettings/fetchAllTeams",
  teamSettingsThunk.fetchAllTeamsThunk
);

// to fetch all members in a team
export const fetchMembersInTeam = createAsyncThunk(
  "teamSettings/fetchMembersInTeam",
  teamSettingsThunk.fetchMembersInTeamThunk
)

// initial states for the slice
export const initialState: TeamsType = {
  teamData: {},
  teamIdList: [],
  fetchTeamsAjaxStatus: "pending",
  fetchTeamsLimit: 10,
  // totalTeamsCount: 0
  metaData: {
    count: 0,
    total: 0
  },

  usersInTeam: {},
  usersInTeamIdList: [],
  teamMateSearchText: "",
  fetchTeamMemberLimit: 10,
  usersInTeamCount: 0,
  usersInTeamTotalCount: 0,
  activeTeam: {
    teamId: 0,
    teamName: "",
    users: 0
  },
  fetchUsersInTeamAjaxStatus: "pending",

  allUsers: {},
  allUsersIdList: [],
  addMemberSearchText: "",
  allUsersCount: 0,
  allUsersTotalCount: 0,
  fetchAllUsersLimit: 10,
  fetchAllUsersAjaxStatus: "pending"
}

// create the slice
export const teamSettingsSlice = createSlice({
  name: "teamSlice",
  initialState,
  reducers: teamSettingsReducers,
  extraReducers: (builder) => {
    // fetch all teams
    builder.addCase(fetchAllTeams.fulfilled,
        fetchAllTeamsFulfilled
      );
    builder.addCase(fetchAllTeams.pending,
      (state) => {
        state.fetchTeamsAjaxStatus = "pending";
      });
    builder.addCase(fetchAllTeams.rejected,
      (state) => {
        state.fetchTeamsAjaxStatus = "rejected";
      });

    // fetch all the team members
    builder.addCase(fetchMembersInTeam.fulfilled,
        fetchAllTeamMembersFulfilled
      );
    builder.addCase(fetchMembersInTeam.pending,
      (state) => {
        state.fetchUsersInTeamAjaxStatus = "pending";
      });
    builder.addCase(fetchMembersInTeam.rejected,
      (state) => {
        state.fetchUsersInTeamAjaxStatus = "rejected";
      });
  }
});

export const {
  resetTeamSettings,
  resetTeamsData,
  resetMembersInTeamData,
  resetAllUsersData,
  setActiveTeamData,
  resetActiveTeamData,
  setTeamMateSearchValue,
  resetTeamMateSearchValue
} = teamSettingsSlice.actions;

// selector to get team data from team id
export const getTeamDataFromIdSelector = createSelector(
  [getTeamDataFromId],
  (teamDataFromId) => teamDataFromId
);

// selector to get active team data
export const getActiveTeamDataSelector = createSelector(
  [getActiveTeamData],
  (activeTeamData) => activeTeamData
);

export default teamSettingsSlice.reducer;