import { axiosJSON } from "src/globals/axiosEndPoints";

/**
 * Service for saving/editing card details in billing
 */
const processCardService = async () => {
  const { data: res } = await axiosJSON.post(
    `/api/setting/billing/processCard`
  );

  if (res.err || res.error || !res.data?.url?.trim()) {
    throw res.msg ?? res.message ?? "Something went wrong!";
  }

  // Redirect to Save Card Page
  if (res.status_code === "user_redirect") {
    window.location.replace(res.data.url);
  }
};

export default processCardService;
