import React from "react";
import styles from "./CreateTempHeader.module.scss";

const CreateTempHeader = ({
  currentView,
  setCurrentView,
  hideBack = false,
}: {
  currentView: any;
  setCurrentView: any;
  hideBack?: boolean;
}) => {
  return (
    <div>
      <div
        className={`d-flex justify-content-between align-items-center mb-2 mb-md-0`}
      >
        <p className={`${styles.chooseTemplate}`}>Create New Template</p>
        {hideBack ? (
          <div></div>
        ) : (
          <button
            className={`${styles.viewTemplateBtn}`}
            onClick={() => {
              setCurrentView("view");
            }}
          >
            Back
          </button>
        )}
      </div>
    </div>
  );
};

export default CreateTempHeader;
