import { axiosJSON } from "src/globals/axiosEndPoints";
import APIResponse from "./APIReponse";
import { User } from "./getAllUser";
interface ISocialAccount {
  id: null | string;
  email: null | string;
  avatar: null | string;
  name: null | string;
}
export interface ILinkedAccount {
  name: string;
  icon: string;
  route: string;
  isPublicLink: boolean;
  account: null | ISocialAccount;
}

export async function getLinkedSocialAccounts() {
  const { data: res } = await axiosJSON.get("/api/setting/user/getLinkedSocialAccounts");

  if (res.err === true) {
    throw new Error(res.msg as string);
  }

  return res.data as Array<ILinkedAccount>;
}
