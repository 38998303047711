/**
 * This file is the service file used for making api request.
 * It contains the addNotificationService function service which is explained below.
 *
 * @author Yash Aditya
 */

import { axiosJSON } from "src/globals/axiosEndPoints";
import { CurrentStatusEndPoints } from "./reportTicketList.service";
import {
  ReportInternalFilters,
  ReportSidebarFilters,
} from "src/routes/Report/hooks/reportFilters/useReportInternalFilters";
import { FilterInParam } from "src/routes/Report/hooks/reportFilters/useReportFilters";

export interface ReportNotificationParams {
  context: CurrentStatusEndPoints;
  /**
   * Title or name of the report we are going to send.
   */
  reportName: string;
  globalFiltersApplied?: FilterInParam;
  internalFiltersApplied?: ReportInternalFilters;
  sidebarFiltersApplied?: ReportSidebarFilters;
  sendingFormatId: string;
}

/**
 * This service is created to add a new notification.
 */
const addNotificationService = async (param: ReportNotificationParams) => {
  const { data: res } = await axiosJSON.post(
    `/api/reports/addNotification`,
    param
  );

  if (res.err || res.error) {
    throw res.msg ?? res.message ?? "Something went wrong!";
  }

  return true;
};

export default addNotificationService;
