import {
  createAsyncThunk,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";
import { AJAXSTATUS } from "src/globals/constants";
import { LiveChatIntegration, LiveChatIntegrationList } from "src/services/LiveChat/Settings/getAllLiveChatIntegrations";
import { fetchAllLiveChatIntegrationsFulfilled } from "./LiveChatIntegrations.extraReducers";
import LiveChatIntegrationsReducers from "./LiveChatIntegrations.reducers";
import LiveChatIntegrationsThunk from "./LiveChatIntegrations.thunk";

// to fetch all liveChatIntegrationss
export const fetchAllLiveChatIntegrationsData = createAsyncThunk(
  "liveChatIntegrations/fetchAllLiveChatIntegrations",
   LiveChatIntegrationsThunk.fetchAllliveChatIntegrationsThunk
);

export interface LiveChatIntegrationsData{
  liveChatIntegrationsData: { [integrationId: string | number]: LiveChatIntegration };
  liveChatIntegrationsIdList: Array<number | string>;
  metaData: {
    count: number;
    total: number;
  };

  fetchLiveChatIntegrationsAjaxStatus: AJAXSTATUS;
  fetchLiveChatIntegrationsLimit: number;
}
// initial states for the slice
export const initialState: LiveChatIntegrationsData  = {
  liveChatIntegrationsData: {},
  liveChatIntegrationsIdList: [],
  fetchLiveChatIntegrationsAjaxStatus: "pending",
  fetchLiveChatIntegrationsLimit: 10,
  metaData: {
    count: 0,
    total: 0
  },
}

// create the slice
export const liveChatIntegrationsSlice = createSlice({
  name: "liveChatIntegrations",
  initialState,
  reducers: LiveChatIntegrationsReducers,
  extraReducers: (builder) => {
    // fetch all liveChatIntegrationss
    builder.addCase(fetchAllLiveChatIntegrationsData.fulfilled,
        fetchAllLiveChatIntegrationsFulfilled
      );
    builder.addCase(fetchAllLiveChatIntegrationsData.pending,
      (state) => {
        state.fetchLiveChatIntegrationsAjaxStatus = "pending";
      });
    builder.addCase(fetchAllLiveChatIntegrationsData.rejected,
      (state) => {
        state.fetchLiveChatIntegrationsAjaxStatus = "rejected";
      });
  }
});


export const {
  resetLiveChatIntegrationsData,
} = liveChatIntegrationsSlice.actions;


export default liveChatIntegrationsSlice.reducer;