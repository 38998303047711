import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";

export interface DeleteScheduleSequecePayload {
  ticketId?: number;
  chatId?: number;
}

const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/schedule/deleteSequence`
  : "/api/liveChat/agent/message/schedule/deleteSequence";

export const deleteScheduleSequence = async (params: DeleteScheduleSequecePayload) => {
  const { data: res } = await axiosJSON.delete(apiEndPoint, { params });

  if (res.err) {
    throw res.msg;
  }

  return res;
};
