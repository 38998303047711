import BaseSteps from "./children/BaseSteps/BaseSteps";
import ConfigModals from "./children/ConfigModals/ConfigModals";
import HeadingSection from "./children/HeadingSection/HeadingSection";
import StaticModals from "./children/StaticModals/StaticModals";
import StepsConfigured from "./children/StepsConfigured/StepsConfigured";
import { useReturnAutoWorkFlowCreate } from "./hooks/useReturnAutoWorkFlow";
import styles from "./ReturnAutoWorkFlow.module.scss";
import { useReturnPreviewProvider } from "./hooks/useReturnPreview";
import { WidgetPreviewSection } from "./children/WidgetPreviewSection/WidgetPreviewSection";

/**
 * ReturnAutoWorkFlow component.
 *
 * This component sets up the context and provides a workflow for returning
 * auto configurations. It uses the `useReturnAutoWorkFlowCreate` hook to
 * manage the state and dispatch for the workflow.
 *
 * @returns The rendered component.
 */
function ReturnAutoWorkFlow() {
  const [state, dispatch, Provider] = useReturnAutoWorkFlowCreate();
  const { ReturnPreviewProvider, ...contexValue } =
    useReturnPreviewProvider(state);

  return (
    <Provider value={[state, dispatch]}>
      <ReturnPreviewProvider value={contexValue}>
        <div className={`w-100 ${styles.contentWrapper}`}>
          {state.loadingStepsAndQuestionSaving === "pending" && (
            <div className={`${styles.loadingBlocker}`}></div>
          )}
          <HeadingSection />
          <div className={`d-flex ms-1 flex-wrap ${styles.mainDiv}`}>
            <BaseSteps />
            <StepsConfigured />
          </div>
          <ConfigModals />
          <StaticModals />
        </div>
        <WidgetPreviewSection />
      </ReturnPreviewProvider>
    </Provider>
  );
}

export default ReturnAutoWorkFlow;
