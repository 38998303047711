import UserAvatar from "src/components/UserAvatar";
import styles from "./TicketBrand.module.scss";
import { useAppSelector } from "src/store/store";
import { selectTicketCellData } from "src/store/slices/tickets/tickets.slice";
import { TableCellProps } from "..";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import AxiosImg from "src/components/AxiosImg";

function TicketBrand({ ticketId, colKeys }: TableCellProps) {
  const ticketCellData: any = useAppSelector((state) =>
    selectTicketCellData(state, ticketId, colKeys)
  );
  return (
    <>
      <OverlayTrigger
        placement={"top"}
        overlay={
          ticketCellData[0]?.name ? (
            <Tooltip>{ticketCellData[0].name}</Tooltip>
          ) : (
            <></>
          )
        }
      >
        <div className={`${styles["subject"]}`}>
          <span className={`${styles["tbody"]}`}>
            <span className="me-2">
              {ticketCellData[0]?.imgURL ? (
                <AxiosImg
                  className={`${styles.brandImg}`}
                  url={ticketCellData[0].imgURL}
                  isDirectURL={ticketCellData[0]?.isPublicAttachmentUrl}
                />
              ) : (
                <UserAvatar name={ticketCellData[0]?.name || "NA"} size={30} />
              )}
            </span>
            <span className={`${styles.brandName}`}>
              {ticketCellData[0]?.name ?? "NA"}
            </span>
          </span>
        </div>
      </OverlayTrigger>
    </>
  );
}

export default TicketBrand;
