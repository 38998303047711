/* eslint-disable no-throw-literal */
import { axiosJSON } from "src/globals/axiosEndPoints";

export interface ISendMessageService {
  ticketId: number | string;
  to?: string;
  cc?: string;
  bcc?: string;
  from?: string;
  message: string;
  messageType: "Message" | "Note" | "Forward" | "NoteBotAiQuery";
  parentMessageId?: number;
  sendAs: string;
  attachmentBatchNumber?: Array<string>; // for saving the batch id of attachment
  message_thread?: string;
  content_ids?: string;
  uuid: string;
  channel?: string;
}

export async function sendMessageService(messageData: ISendMessageService) {
  const ccArr = (messageData.cc + "")
    .split(",")
    .filter((value) => value !== "undefined");
  const bccArr = (messageData.bcc + "")
    .split(",")
    .filter((value) => value !== "undefined" && !ccArr.includes(value));
  messageData.cc = ccArr.join(",");
  messageData.bcc = bccArr.join(",");
  if (messageData.cc?.trim() === "" || messageData.messageType === "Note") {
    delete messageData.cc;
  } /* else if (messageData.cc ? !validateEmail(messageData.cc) : false) {
    throw "Cc e-mail not valid!";
  } */
  if (messageData.bcc?.trim() === "" || messageData.messageType === "Note") {
    delete messageData.bcc;
  } /* else if (messageData.bcc ? !validateEmail(messageData.bcc) : false) {
    throw "Bcc e-mail not valid!";
  } */
  if (messageData.to?.trim() === "" || messageData.messageType === "Note") {
    delete messageData.to;
  }

  if (messageData.from?.trim() === "" || messageData.messageType === "Note") {
    delete messageData.from;
  }
  /*if (messageData.message.length === 0) {
    throw "Description empty!";
  }

  if(messageData.attachmentBatchNumber?.length === 0){
    delete messageData.attachmentBatchNumber;
  }*/

  if (
    messageData.message.length === 0 &&
    messageData.attachmentBatchNumber?.length === 0
  ) {
    throw "Description or attachment is required!";
  }

  if (messageData.attachmentBatchNumber?.length === 0) {
    delete messageData.attachmentBatchNumber;
  }

  try {
    const { data } = await axiosJSON.post(
      `/api/message/sendMessage`,
      messageData,
    );

    // Check if there is an error in the response data
    if (data.err) {
      // Check specific cases for errors
      if (data.statusCode) {
        // If the attachment size limit is exceeded, throw a specific error
        if (data.statusCode === "attachmentSizeLimitExceeded") {
          throw {
            statusCode: "attachmentSizeLimitExceeded",
            message: "Attachment file size exceeded",
          };
        } else if (data.statusCode === "integrationFailure") {
          throw {
            statusCode: "integrationFailure",
            message: data.msg ?? data.message,
            failureMetaData: data.failureMetaData,
          };
        } else if (data.statusCode) {
          throw {
            statusCode: data.statusCode,
            message: data.msg ?? data.message,
          };
        }
      } else {
        // Otherwise, throw a general error
        throw new Error("Something went wrong!");
      }
    }

    // Return the data if no errors occurred
    return data;
  } catch (e: any) {
    // Catch and handle errors during the request
    if (typeof e === "object") {
      throw e;
    }
    // Log the error to the console
    console.error(e);

    // Rethrow a general error message
    throw new Error("Something went wrong!");
  }
}

export interface ChangeCustomerProps {
  customerEmail?: string;
  ticketId: string;
  cc?: Array<string>;
  from?: string;
}

export async function changeCustomer(params: ChangeCustomerProps) {
  const { data: res } = await axiosJSON.post(
    `/api/ticket/changeCustomer`,
    params,
  );
  if (res.err) {
    throw res.message;
  }
  return res.message;
}
