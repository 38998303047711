import { createContext, useCallback, useContext, useState } from "react";
import useGetAutomationData, {
  Condition,
  IReturnType,
} from "./useGetAutomationData";
import { ConditionTypes } from "src/store/slices/initiateReturn/Helpers/returnExchange.helpers";

// Define the IAmount interface
export interface IAmount {
  amount: number; // Order amount value
  currencyCode: string; // Order amount currency code
}

// Define the IOrderItem interface
export interface IOrderItem {
  id: string; // Id of the item
  name: string; // Name of the item
  sku: string; // SKU of the item
  quantity: number; // Quantity of the item
  unitPrice: IAmount; // Price of one unit of the item
  itemImageUrl?: string | null; // Image URL of the item (optional)
  returnExchangeApplicable: boolean; // Indicates if return/exchange is applicable
}

export type ElementType = 87 | 74 | 77 | 76;
export const ElementTypes: Record<ElementType, string> = {
  87: "shortAnswer",
  74: "longAnswer",
  77: "multipleChoice",
  76: "dropdown",
};

interface ReasonQuestion {
  additionalOptions: any[];
  elementId: string;
  elementTypeId: string;
  elementValue: string;
}

// Define the IOrder interface
export interface IOrder {
  orderId: string; // Id of the order
  orderName: string; // Name of the order
  orderDateUTC: string; // Date of the order in UTC
  orderTotal: IAmount; // Total amount of the order
  itemsInOrderCount: number; // Count of items in the order
  itemsInOrder: IOrderItem[]; // Array of items in the order
  returnExchangeApplicable?: boolean; // Indicates if return/exchange is applicable (optional)
  conditions: Condition[];
  selectedAction?: "return" | null;
  activeStep?: StepName;
  prevStepForConfig?: StepName; // Will track the step to show when click on back in configure next steps page
  addedItems?: Record<string, IAddedItem>;
  modalType?: ActiveModal;
  activeItemIdForExchange?: string;
}

export type StepName =
  | "selectOrder"
  | "selectAction"
  | "addReturnItems"
  | "notReturnable" // For order
  | "notReturnableItems" // For items
  | "returnType"
  | "reason"
  | "moreDetails"
  | "returnInstruction"
  | "returnMethod"
  | "orderSummary"
  | "reviewRestockingFee"
  | "configureNextSteps"
  | "arsInformation"
  | "arsOffers"
  | "arsReturnTypes"
  | "arsExchange"
  | "antiRefundStagePreview";

export interface PayloadFormDataValue {
  key: string;
  value: string | string[];
}
export interface IActiveSubModal {
  name: "moreQuestions";
  reasonId?: string;
}
export type ActiveModal =
  | "nonEligibleOrder"
  | "nonEligibleItems"
  | "returnTypes"
  | "returnReasons"
  | "antiRefund"
  | "antiRefundMultipleTabs"
  | "returnMethod"
  | "orderSummary";
export interface IAddedItem {
  itemId: string;
  quantity: number;
  indexes: number[];
  isReturnExchangeApplicable: boolean;
  selectedReturnType?: string;
  addedReasonId?: string;
  followUpAnswers?: PayloadFormDataValue[];
  dontReturnOrExchangeInfo?: boolean;
  dontReturnOrExchangeOffers?: boolean;
  dontReturnOrExchange?: boolean;
  newReturnType?: string; // Used incase of ars offer return type
  exchangeItemIds?: string[]; // Used incase of ars offer return type
}
export interface DispatchState {
  orders: IOrder[];
  selectedOrder: IOrder | null;
  updateState: (data: Partial<DispatchState>) => void;
  updateContext: ({
    selectedOrderId,
    activeStep,
  }: {
    selectedOrderId: string;
    activeStep: StepName;
  }) => void;
  clearModalData: ({
    activeModal,
  }: {
    activeModal: ActiveModal | null;
  }) => void;
  showPreview: boolean;
  showAntiRefundPreview: boolean;
  setShowPreview: (show: boolean) => void;
  setShowAntiRefundPreview: (show: boolean) => void;
  updateSelectedOrder: () => void;
  selectedOrderId: string; // Will be updated when questions changes
  activeStep: StepName; // Will be updated when questions changes
}

export const DummyOrder = {
  orderId: "SampleOrderWithinReturnWindow",
  orderDateUTC: "2024-06-30T14:30:00Z",
  orderTotal: {
    amount: 820,
    currencyCode: "$",
  },
  itemsInOrderCount: 4,
  itemsInOrder: [
    {
      id: "ITEM001",
      name: "Acme T-Shirt",
      sku: "0101",
      quantity: 2,
      unitPrice: {
        amount: 789,
        currencyCode: "$",
      },
      itemImageUrl: "/assets/images/order1.png",
      returnExchangeApplicable: true,
    },
    {
      id: "ITEM002",
      name: "Acme Shoes",
      sku: "0101",
      quantity: 2,
      unitPrice: {
        amount: 30,
        currencyCode: "$",
      },
      itemImageUrl: "src/assets/images/order2.png",
      returnExchangeApplicable: true,
    },
  ],
  returnExchangeApplicable: false,
};

const initialState: DispatchState = {
  updateState: () => {},
  updateContext: () => {},
  orders: [
    {
      ...DummyOrder,
      orderId: "SampleOrderWithinReturnWindow",
      orderName: "Sample Order 1",
      conditions: [],
      returnExchangeApplicable: true,
    },
    {
      ...DummyOrder,
      orderId: "SampleOrderOutSideReturnWindow",
      orderName: "Sample Order 2",
      conditions: [],
    },
  ],
  selectedOrder: null,
  showPreview: false,
  showAntiRefundPreview: false,
  setShowPreview: () => {},
  setShowAntiRefundPreview: () => {},
  selectedOrderId: "SampleOrderWithinReturnWindow",
  activeStep: "selectOrder",
  updateSelectedOrder: () => {},
  clearModalData: () => {},
};

const ReturnPreviewContext = createContext(initialState);

export const useReturnPreviewState = () => {
  const context = useContext(ReturnPreviewContext);

  if (!context) {
    throw new Error(
      "useReturnPreviewState must be used within a ReturnPreviewProvider",
    );
  }

  return context;
};

export const useReturnPreviewProvider = () => {
  const [state, setState] = useState({
    ...initialState,
  });
  const {
    arsResponse,
    antiRefundSelectedOrderId,
    activeModal,
    activeSubModal,
    createAddedItem,
    getAllReturnTypes,
    getNonEligibleOrders,
    getNonEligibleItemOrders,
    getReturnTypeOrders,
    getReturnReasonsOrders,
    getReturnMethodOrders,
    applyReturnWindowToDefaultOrders,
    applyAllItemsEligible,
    applyAllItemsNotEligible,
    addNonEligibleItemsOrder,
    getOrderSummaryOrders,
  } = useGetAutomationData();

  const updateState = useCallback((data: Partial<DispatchState>) => {
    setState((currentData: DispatchState) => {
      return {
        ...currentData,
        ...data,
      };
    });
  }, []);

  const setShowPreview = useCallback((show: boolean) => {
    setState((currentData: DispatchState) => {
      return {
        ...currentData,
        showPreview: show,
      };
    });
  }, []);

  const setShowAntiRefundPreview = useCallback((show: boolean) => {
    setState((currentData: DispatchState) => {
      return {
        ...currentData,
        showAntiRefundPreview: show,
      };
    });
  }, []);

  const updateContext = useCallback(
    ({
      selectedOrderId,
      activeStep,
    }: {
      selectedOrderId: string;
      activeStep: StepName;
    }) => {
      updateState({
        selectedOrderId: selectedOrderId,
        activeStep: activeStep,
      });
    },
    [state],
  );

  const clearModalData = useCallback(
    ({ activeModal }: { activeModal: ActiveModal | null }) => {
      if (activeModal) {
        //If active modal exists then need to find related orders and remove them
        const filteredOrders = state.orders.filter(
          (order) => order.modalType !== activeModal,
        );
        updateState({
          ...state,
          orders: filteredOrders,
        });
      }
    },
    [state, updateState],
  );

  /**
   * Function to bootstrap selected order
   * This will set the selectedOrder and active page and fill the previous pages answers
   */
  const updateSelectedOrder = useCallback(() => {
    // Get all return types
    const { allReturnTypes } = getAllReturnTypes();
    const addedItems: Record<string, IAddedItem> = {};

    // Function to update state with eligible items
    const updateEligibleItems = (updatedOrders: IOrder[]) => {
      const { updatedOrders: updatedOrdersEligibleItems } =
        applyAllItemsEligible(updatedOrders);
      updateState({ orders: updatedOrdersEligibleItems });
    };

    // Function to update state with non-eligible items
    const updateNotEligibleItems = (updatedOrders: IOrder[]) => {
      const { updatedOrders: updatedOrdersEligibleItems } =
        applyAllItemsNotEligible(updatedOrders);
      updateState({ orders: updatedOrdersEligibleItems });
    };

    // Function to handle order updates based on conditions
    const handleOrderUpdate = (
      getOrdersFunction: () => {
        updatedOrders: IOrder[];
        updatedSelectedOrder: IOrder | undefined;
      },
      addItemsConditon: boolean | null,
    ) => {
      const { updatedOrders, updatedSelectedOrder } = getOrdersFunction();
      if (addItemsConditon === true) {
        updateEligibleItems(updatedOrders);
      } else if (addItemsConditon === false) {
        updateNotEligibleItems(updatedOrders);
      } else {
        updateState({ orders: updatedOrders });
      }
      if (updatedSelectedOrder) {
        updateState({ selectedOrder: updatedSelectedOrder });
      }
    };

    // Function to update get selectedorder with activeStep based on the offers selected
    const getAntiRefundOrder = (orderId?: string) => {
      const updatedSelectedOrder = state.orders.find(
        (order) => order.orderId == orderId,
      );
      let activeStep: StepName = "configureNextSteps";
      let prevStep: StepName = "reason";
      if (
        updatedSelectedOrder &&
        activeSubModal &&
        activeSubModal.name === "arsStages"
      ) {
        // Determine active step based on ARS response scenario
        if (arsResponse?.isInformationAvailable ?? false) {
          activeStep = prevStep = "arsInformation";
        } else if (arsResponse?.isOffersAvailable ?? false) {
          activeStep = prevStep = "arsOffers";
        } else if (arsResponse?.isExchangeAvailable ?? false) {
          if (
            arsResponse?.returnTypes &&
            arsResponse?.returnTypes?.length > 0
          ) {
            activeStep = prevStep = "arsReturnTypes";
          } else {
            activeStep = prevStep = "arsExchange";
          }
        }
        updatedSelectedOrder.activeStep = activeStep;
        updatedSelectedOrder.prevStepForConfig = prevStep;
        const stagesLength = [
          arsResponse?.isInformationAvailable ?? false,
          arsResponse?.isOffersAvailable ?? false,
          arsResponse?.isExchangeAvailable ?? false,
        ].filter(Boolean).length;
        if (stagesLength > 1) {
          setShowAntiRefundPreview(true);
        }
        return {
          updatedSelectedOrder: updatedSelectedOrder,
        };
      }
      return {
        updatedSelectedOrder,
      };
    };

    // Check if a modal is active and handle accordingly
    if (activeModal) {
      switch (activeModal) {
        case "nonEligibleOrder":
          handleOrderUpdate(getNonEligibleOrders, null);
          break;
        case "nonEligibleItems":
          handleOrderUpdate(getNonEligibleItemOrders, false);
          break;
        case "returnTypes":
          handleOrderUpdate(getReturnTypeOrders, true);
          break;
        case "returnReasons":
          handleOrderUpdate(getReturnReasonsOrders, true);
          break;
        case "returnMethod":
          handleOrderUpdate(getReturnMethodOrders, true);
          break;
        case "orderSummary":
          handleOrderUpdate(getOrderSummaryOrders, true);
          break;
        case "antiRefundMultipleTabs": {
          const { updatedSelectedOrder } = getAntiRefundOrder(
            antiRefundSelectedOrderId?.toString(),
          );
          if (updatedSelectedOrder) {
            updateState({
              selectedOrder: {
                ...updatedSelectedOrder,
              },
            });
          }
          break;
        }
        case "antiRefund": {
          const { updatedSelectedOrder } = getAntiRefundOrder("antiRefund");
          if (updatedSelectedOrder) {
            updateState({
              selectedOrder: {
                ...updatedSelectedOrder,
              },
            });
          }
          break;
        }
        default:
          break;
      }
    } else if (state.selectedOrderId) {
      // Handle case when there is a selected order ID
      const updatedOrder = state.orders.find(
        (order) => order.orderId === state.selectedOrderId,
      );
      const { updatedOrders } = applyReturnWindowToDefaultOrders();

      updateState({ orders: updatedOrders });

      if (updatedOrder) {
        updatedOrder.activeStep = state.activeStep;
        const updateOrderItems = (addReturnType: boolean) => {
          updatedOrder.itemsInOrder.forEach((item) => {
            addedItems[item.id] = createAddedItem({
              itemId: item.id,
              quantity: item.quantity,
              addReturnType,
              returnTypes: addReturnType ? allReturnTypes : undefined,
            });
          });
          updatedOrder.addedItems = addedItems;
        };

        // Handle state based on the active step
        switch (state.activeStep) {
          case "selectAction":
            updatedOrder.selectedAction = "return";
            break;
          case "addReturnItems":
            updateOrderItems(false);
            updateEligibleItems(state.orders);
            // updateNotEligibleItems(state.orders);
            break;
          case "returnType":
            updateOrderItems(false);
            const { updatedNonEligibleOrders: updatedReturnTypeOrders } =
              addNonEligibleItemsOrder(state.orders);
            updateState({ orders: updatedReturnTypeOrders });
            updateEligibleItems(state.orders);
            break;
          case "returnMethod":
          case "reviewRestockingFee":
          case "orderSummary":
          case "reason":
            updateOrderItems(true);
            const { updatedNonEligibleOrders: updatedReasonOrders } =
              addNonEligibleItemsOrder(state.orders);
            updateState({ orders: updatedReasonOrders });
            updateEligibleItems(state.orders);
            break;
          case "configureNextSteps":
            updatedOrder.activeStep = "configureNextSteps";
            updatedOrder.prevStepForConfig = "reason";
            break;
        }
        updateState({ selectedOrder: updatedOrder });
      }
    }
  }, [state]);

  return {
    ...state,
    updateState,
    updateContext,
    setShowPreview,
    updateSelectedOrder,
    clearModalData,
    setShowAntiRefundPreview,
    ReturnPreviewProvider: ReturnPreviewContext.Provider,
  };
};
