import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import objectHash from "object-hash";
import { AJAXSTATUS } from "src/globals/constants";
import { Step9Config } from "src/services/UiAutomation/ReturnExchange/Configuration/Step9/step9GetConfig";
import { conditionType } from "src/services/UiAutomation/ReturnExchange/Configuration/Step9/step9GetOptionsConditions";
import {
  addConditionHandler,
  ConditionTypes,
  createNewObjectHash,
  deleteConditionHelper,
  deleteTabHelper,
  RecursivePartial,
  updateConditionHelper,
  validateChangeHandler,
  validateConditionsHelper,
} from "src/store/slices/initiateReturn/Helpers/returnExchange.helpers";
import { v4 as uuid } from "uuid";
import step9Builders from "./Builders/step9.builder";
import { step9ConfigErrorCount } from "./returnExchange.selectors";

export interface OrderTypeDetails {
  orderTypeId: string;
  orderTypeName: string | null | undefined;
  // orderSequence: number;
  conditions: ConditionTypes[];
  messageForCustomer: {
    value: string | undefined;
    error: string | null;
  };
  returnWindow: ConditionTypes | null;
  error: string | null;
  returnWindowError: string | null;
  isAddConditionEnabled: boolean;
  errorCount: number;
  tabHash: string;
  isValid: boolean;
}

export interface Step9Type {
  selectedOrderTypeId: string | null;
  orderTypes: OrderTypeDetails[];
  error: string | null;
  selectedTabIntialHash: string | null;
  selectedTabCurrentHash: string | null;
  tabIsChanged: boolean;
  conditionOptions: conditionType[];
  returnWindowOptions: conditionType[];
  defaultOptions: conditionType[];
  getOrderType: Step9Config;

  variableNames: any;
  operatorsNames: any;
  operatorSymbolMap: any;
  valueTypes: any;
  variableNameToValueType: any;
}

export interface step9Configuration {
  step9Configuration: Step9Type;
  initialHash: string;
  currentHash: string;
  isChanged: boolean;
  excludeKeysForHash: string[];
  intialStep9Config: Step9Type | null;
  fetchAjaxStatus: AJAXSTATUS;
  updateAjaxStatus: AJAXSTATUS;
}

const firstOrderId = uuid();
const excludeKeys = [
  "returnWindowError",
  "isAddConditionEnabled",
  "errorCount",
  "tabHash",
  "isValid",
];
export const initialState: step9Configuration = {
  step9Configuration: {
    selectedOrderTypeId: firstOrderId,
    orderTypes: [
      {
        orderTypeId: firstOrderId,
        orderTypeName: "Order Type 1",
        // orderSequence: 1,
        conditions: [],
        messageForCustomer: { value: "", error: null },
        error: null,
        returnWindowError: null,
        isAddConditionEnabled: true,
        errorCount: 0,
        isValid: false,
        tabHash: "",
        returnWindow: null,
      },
    ],
    error: null,
    selectedTabIntialHash: "",
    selectedTabCurrentHash: "",
    tabIsChanged: false,
    conditionOptions: [],
    returnWindowOptions: [],
    defaultOptions: [],
    getOrderType: {
      data: [],
    },
    variableNames: {},
    operatorsNames: {},
    operatorSymbolMap: {},
    valueTypes: {},
    variableNameToValueType: {},
  },
  initialHash: "",
  currentHash: "",
  excludeKeysForHash: excludeKeys,
  isChanged: false,
  intialStep9Config: null,
  fetchAjaxStatus: "pending",
  updateAjaxStatus: "idle",
};

export const step9ConfigurationSlice = createSlice({
  name: "step9Configuration",
  initialState,
  reducers: {
    // Add Tab
    addOrderType: (state: step9Configuration) => {
      const newOrderTypeId = uuid();
      console.log(state.step9Configuration.selectedOrderTypeId, "before");

      state.step9Configuration.selectedOrderTypeId = newOrderTypeId;
      const selectedOrderTypeId = state.step9Configuration.selectedOrderTypeId;

      state.step9Configuration.orderTypes.push({
        orderTypeId: newOrderTypeId,
        orderTypeName: `Order Type ${
          state.step9Configuration.orderTypes.length + 1
        }`,
        conditions: [],
        returnWindow: {
          id: "",
          ruleType: "AND",
          variableName: "None",
          values: "",
          operator: "",
        },
        // orderSequence: state.step9Configuration.orderTypes.length + 1,
        messageForCustomer: { value: "", error: null },
        error: null,
        returnWindowError: null,
        isAddConditionEnabled: true,
        errorCount: 0,
        tabHash: "",
        isValid: false,
      });
      console.log(selectedOrderTypeId, "selectedOrderTypeId");
      // validateCondition({ orderTypeId: selectedOrderTypeId });
    },

    // Update Tab name
    updateOrderType(
      state: step9Configuration,
      params: PayloadAction<{
        orderTypeDetail: RecursivePartial<OrderTypeDetails>;
      }>,
    ) {
      const { orderTypeId, messageForCustomer, orderTypeName } =
        params.payload.orderTypeDetail;

      const selectedIdx = state.step9Configuration.orderTypes.findIndex(
        (order) => order.orderTypeId === orderTypeId,
      );
      const selectedOrder = state.step9Configuration.orderTypes[selectedIdx];

      selectedOrder.messageForCustomer.value =
        messageForCustomer?.value ?? selectedOrder.messageForCustomer.value;
      selectedOrder.messageForCustomer.error =
        messageForCustomer?.error ?? selectedOrder.messageForCustomer.error;

      if (orderTypeName) selectedOrder.orderTypeName = orderTypeName;
    },

    // Delete Tab
    deleteOrderType: (state, { payload }: { payload: { id: string } }) => {
      const { updatedTabs, newSelectedTabId } = deleteTabHelper({
        tabs: state.step9Configuration.orderTypes,
        tabId: payload.id,
        selectedTabId: state.step9Configuration.selectedOrderTypeId,
        idKey: "orderTypeId",
      });

      state.step9Configuration.orderTypes = updatedTabs;
      state.step9Configuration.selectedOrderTypeId = newSelectedTabId;
    },

    pushInitialHash: (state) => {
      const hash = createNewObjectHash({
        excludeKeys: state.excludeKeysForHash,
        hashObject: state.step9Configuration.orderTypes,
      });

      if (hash) {
        state.initialHash = hash;
        state.currentHash = hash;
      }
    },

    pushCurrentHashForSelectedTab: (
      state,
      params: PayloadAction<{ orderTypeId: string | null }>,
    ) => {
      const selectedOrderIdx = state.step9Configuration.orderTypes.findIndex(
        (orderType) => orderType.orderTypeId === params.payload.orderTypeId,
      );

      state.step9Configuration.selectedTabCurrentHash = objectHash(
        state.step9Configuration.orderTypes[selectedOrderIdx],
      );
    },

    validateChanges: (state) => {
      const { initialHash, excludeKeysForHash } = state;

      const { isChanged, newHash } = validateChangeHandler({
        stateObject: state.step9Configuration.orderTypes,
        excludeKeys: excludeKeysForHash,
        initialHash,
      });

      if (newHash) {
        state.isChanged = isChanged;
        state.currentHash = newHash;
      }
    },

    resetHashes: (state) => {
      state.initialHash = "";
      state.currentHash = "";
    },

    undoConfig: (
      state,
      params: PayloadAction<{ initialConfig: Step9Type | null }>,
    ) => {
      if (params.payload.initialConfig !== null)
        state.step9Configuration = params.payload.initialConfig;
    },

    // Add Condition
    addCondition: (
      state,
      params: PayloadAction<{ orderTypeId: string; condition: string }>,
    ) => {
      let selectedOrder = state.step9Configuration.orderTypes.find(
        (orderType) => orderType.orderTypeId === params.payload.orderTypeId,
      );

      const selectedTab = addConditionHandler({
        selectedTab: selectedOrder,
        conditionOptions: state.step9Configuration.conditionOptions,
        payloadCondition: params.payload.condition,
        isItemCondition: false,
      });

      selectedOrder = selectedTab;

      // if (selectedOrder !== undefined) {
      //   let condition: any = state.step9Configuration.conditionOptions[0];

      //   if (params.payload.condition !== "") {
      //     let addCondition = state.step9Configuration.conditionOptions.filter(
      //       (value) => value.name === params.payload.condition,
      //     );
      //     if (addCondition) {
      //       condition = addCondition[0];
      //     }
      //   }
      //   selectedOrder.conditions.push({
      //     id: uuid(),
      //     operator: state.step9Configuration.operatorsNames[condition.name][0],
      //     ruleType: "AND",
      //     values: getConditionValueOrDefault(
      //       state.step9Configuration.variableNameToValueType[condition.name],
      //     ),
      //     variableName: condition.name,
      //   });
      //   selectedOrder.isAddConditionEnabled = false;
      //   if (selectedOrder.error !== undefined) {
      //     selectedOrder.error = null;
      //     // selectedOrder.errorCount = selectedOrder.errorCount - 1;
      //   }
      // }
    },

    //Validate Tab
    validateOrderType(
      state: step9Configuration,
      params: PayloadAction<{
        orderTypeId: string | null;
      }>,
    ) {
      if (params.payload.orderTypeId) {
        const selectedOrderIdx = state.step9Configuration.orderTypes.findIndex(
          (orderType) => orderType.orderTypeId === params.payload.orderTypeId,
        );

        if (selectedOrderIdx !== -1) {
          const messageForCustomer =
            state.step9Configuration.orderTypes[selectedOrderIdx]
              .messageForCustomer;

          if (messageForCustomer.value === "") {
            messageForCustomer.error = "*Please fill out this field";
          } else {
            messageForCustomer.error = null;
          }
        }
      }
    },

    // Validate
    validateCondition(
      state: step9Configuration,
      params: PayloadAction<{
        orderTypeId: string;
      }>,
    ) {
      const selectedOrderIdx = state.step9Configuration.orderTypes.findIndex(
        (orderType) => orderType.orderTypeId === params.payload.orderTypeId,
      );

      const selectedOrder =
        state.step9Configuration.orderTypes[selectedOrderIdx];
      //checking return window error
      if (
        !selectedOrder.returnWindow?.variableName ||
        selectedOrder.returnWindow.variableName === "None"
      ) {
        selectedOrder.returnWindowError = "*Please fill out this field";
      } else {
        selectedOrder.returnWindowError = null;
      }
      const conditions = selectedOrder.conditions;
      if (conditions.length <= 0) {
        selectedOrder.error = "Atleast add one condition required";
      }

      // Validating conditions using helper
      const { updatedConditions, isAddOrderConditionEnabled } =
        validateConditionsHelper({
          selectedTab: selectedOrder,
          step: "step9",
        });

      state.step9Configuration.orderTypes[selectedOrderIdx].conditions =
        updatedConditions;
      if (isAddOrderConditionEnabled != null) {
        state.step9Configuration.orderTypes[
          selectedOrderIdx
        ].isAddConditionEnabled = isAddOrderConditionEnabled;
      }

      if (
        selectedOrder.messageForCustomer.value !== undefined &&
        selectedOrder.messageForCustomer.value.length <= 0
      ) {
        selectedOrder.messageForCustomer.error = "*Please fill out this field";
      }

      const errors = conditions
        .flatMap((condition) => {
          return Object.entries(condition.values).flatMap(
            ([key, value]: [any, any]) => {
              if (value.error === undefined) return null;
              return value.error;
            },
          );
        })
        .concat([
          selectedOrder.messageForCustomer.error,
          selectedOrder.error,
          selectedOrder.returnWindowError,
        ]);

      selectedOrder.errorCount = errors.reduce((p, c) => {
        if (c !== null) {
          return p + 1;
        }
        return p;
      }, 0);

      if (selectedOrder.errorCount > 0) {
        selectedOrder.isValid = false;
      } else {
        selectedOrder.isValid = true;
      }
    },

    //validate all tabs
    validateStep9(state: step9Configuration) {
      // let totalErrorCount = 0;
      state.step9Configuration.orderTypes.forEach(
        (selectedOrder, selectedOrderIdx) => {
          //checking return window error
          if (
            !selectedOrder.returnWindow?.variableName ||
            selectedOrder.returnWindow.variableName === "None"
          ) {
            selectedOrder.returnWindowError = "*Please fill out this field";
          } else {
            selectedOrder.returnWindowError = null;
          }
          const conditions = selectedOrder.conditions;
          if (conditions.length <= 0) {
            selectedOrder.error = "Atleast add one condition required";
          }

          // Validating conditions using helper
          const { updatedConditions, isAddOrderConditionEnabled } =
            validateConditionsHelper({
              selectedTab: selectedOrder,
              step: "step9",
            });

          state.step9Configuration.orderTypes[selectedOrderIdx].conditions =
            updatedConditions;
          if (isAddOrderConditionEnabled != null) {
            state.step9Configuration.orderTypes[
              selectedOrderIdx
            ].isAddConditionEnabled = isAddOrderConditionEnabled;
          }

          if (
            selectedOrder.messageForCustomer.value !== undefined &&
            selectedOrder.messageForCustomer.value.length <= 0
          ) {
            selectedOrder.messageForCustomer.error =
              "*Please fill out this field";
          }

          const errors = conditions
            .flatMap((condition) => {
              return Object.entries(condition.values).flatMap(
                ([key, value]: [any, any]) => {
                  if (value.error === undefined) return null;
                  return value.error;
                },
              );
            })
            .concat([
              selectedOrder.messageForCustomer.error,
              selectedOrder.error,
              selectedOrder.returnWindowError,
            ]);

          selectedOrder.errorCount = errors.reduce((p, c) => {
            if (c !== null) {
              return p + 1;
            }
            return p;
          }, 0);

          if (selectedOrder.errorCount > 0) {
            selectedOrder.isValid = false;
          } else {
            selectedOrder.isValid = true;
          }

          // totalErrorCount += selectedOrder.errorCount;
        },
      );

      setTimeout(() => {
        // params.payload.callback(totalErrorCount);
      }, 0);
    },

    // Update Conditon
    updateCondition(
      state,
      params: PayloadAction<{
        orderTypeId: string;
        conditionToUpdate: RecursivePartial<ConditionTypes>;
      }>,
    ) {
      const { orderTypeId, conditionToUpdate } = params.payload;

      const selectedOrderIdx = state.step9Configuration.orderTypes.findIndex(
        (orderType) => orderType.orderTypeId === orderTypeId,
      );

      if (selectedOrderIdx !== -1 && conditionToUpdate) {
        const { updatedConditions, isAddOrderConditionEnabled } =
          updateConditionHelper({
            conditions:
              state.step9Configuration.orderTypes[selectedOrderIdx].conditions,
            conditionToUpdate,
          });

        state.step9Configuration.orderTypes[selectedOrderIdx].conditions =
          updatedConditions;

        if (isAddOrderConditionEnabled !== null) {
          state.step9Configuration.orderTypes[
            selectedOrderIdx
          ].isAddConditionEnabled = isAddOrderConditionEnabled;
        }
      }
    },

    // DeleteCondition
    deleteCondition: (
      state: step9Configuration,
      params: PayloadAction<{ orderTypeId: string; conditionId: string }>,
    ) => {
      const { orderTypeId, conditionId } = params.payload;

      const selectedOrderIdx = state.step9Configuration.orderTypes.findIndex(
        (orderType) => orderType.orderTypeId === orderTypeId,
      );

      const selectedOrder =
        state.step9Configuration.orderTypes[selectedOrderIdx];

      if (selectedOrderIdx !== -1) {
        const { updatedConditions, isAddOrderConditionEnabled } =
          deleteConditionHelper({
            conditionId: conditionId,
            conditions: selectedOrder.conditions,
            conditionType: "order",
          });

        state.step9Configuration.orderTypes[selectedOrderIdx].conditions =
          updatedConditions;
        if (isAddOrderConditionEnabled !== null) {
          state.step9Configuration.orderTypes[
            selectedOrderIdx
          ].isAddConditionEnabled = isAddOrderConditionEnabled;
        }
      }
    },

    // set active tab
    setSelectedOrderTypeId(
      state: step9Configuration,
      params: PayloadAction<{ orderTypeId: string }>,
    ) {
      state.step9Configuration.selectedOrderTypeId = params.payload.orderTypeId;
    },

    // Update Conditon
    updateReturnWindowCondition(
      state: step9Configuration,
      params: PayloadAction<{
        orderTypeId: string;
        conditionToUpdate: RecursivePartial<ConditionTypes>;
      }>,
    ) {
      const { orderTypeId, conditionToUpdate } = params.payload;

      const selectedOrderIdx = state.step9Configuration.orderTypes.findIndex(
        (orderType) => orderType.orderTypeId === orderTypeId,
      );

      if (conditionToUpdate) {
        const selectedOrder =
          state.step9Configuration.orderTypes[selectedOrderIdx];

        const conditions = selectedOrder.returnWindow;

        const prevCondition = conditions ?? {};
        Object.assign(prevCondition, conditionToUpdate);
        // Object.entries(prevCondition.values).forEach((v: any) => {
        //   Object.entries<any>(v).forEach(([key, value]) => {
        //     if (value.error !== undefined) {
        //       value.error = undefined;
        //     }
        //   });
        // });
      }
    },

    resetConfig(state: step9Configuration) {
      state = initialState;
    },

    // return initial state
    resetStep9Config: (state: step9Configuration) => {
      return initialState;
    },
  },
  extraReducers: step9Builders,
});

export default step9ConfigurationSlice.reducer;

export const {
  addOrderType,
  addCondition,
  deleteOrderType,
  deleteCondition,
  updateCondition,
  updateOrderType,
  validateCondition,
  pushInitialHash,
  validateChanges,
  resetHashes,
  undoConfig,
  validateOrderType,
  setSelectedOrderTypeId,
  pushCurrentHashForSelectedTab,
  updateReturnWindowCondition,
  resetConfig,
  validateStep9,
  resetStep9Config,
} = step9ConfigurationSlice.actions;

export const step9ConfigErrorCountSelector = createSelector(
  [step9ConfigErrorCount],
  (step9ConfigErrorCount) => step9ConfigErrorCount,
);
