import { NavLink } from "react-router-dom";
import styles from "./TabSection.module.scss";
import Articles from "./Articles/Articles";
import useTabSection from "./useTabSection";
import book from "src/assets/images/articles.png";
import analytics from "src/assets/images/analytics.png";
import settingIcon from "src/assets/images/settingIcon.png";
import { useAppSelector } from "src/store/store";

// Tab names
export const TabNamesLC: {
  [key: string]: { name: string; component: any };
} = {
  analytics: { name: "analytics", component: null },
  settings: { name: "settings", component: null },
  article: { name: "articles", component: Articles },
};

function TabSection() {
  const { pageIntegrationId, tabName, pageBrandId } = useTabSection();
  const ui_visibility = useAppSelector(
    (state) => state.globals.currentUserData?.ui_visibility
  );

  return (
    <div className={`d-flex flex-column flex-md-row ${styles.btnWrapper}`}>
      {/* Navlink for Articles */}
      <NavLink
        to={`/knowledge-base/${pageIntegrationId}/articles`}
        className={`btn ${styles.liveChatBtn} ${
          tabName === TabNamesLC.article.name ? styles.active : ""
        }`}
      >
        <p className="d-flex align-items-center mb-0 justify-content-center">
          <span className="pe-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="17"
              viewBox="0 0 17 17"
              className={`${styles.tabIcon}`}
            >
              <g
                id="MDI_book-open-page-variant-outline"
                data-name="MDI / book-open-page-variant-outline"
                transform="translate(0)"
              >
                <g
                  id="Boundary"
                  transform="translate(0)"
                  stroke="rgba(0,0,0,0)"
                  stroke-width="1"
                  opacity="0"
                >
                  <rect width="17" height="17" stroke="none" />
                  <rect x="0.5" y="0.5" width="16" height="16" fill="none" />
                </g>
                <path
                  id="Path_book-open-page-variant-outline"
                  data-name="Path / book-open-page-variant-outline"
                  d="M13.455,1,10,4.46v7.611l3.46-3.114V1m1.384,2.768v9.341a8.041,8.041,0,0,0-2.422-.346A8.335,8.335,0,0,0,8.611,13.8V4.46A6.435,6.435,0,0,0,4.806,3.422,6.435,6.435,0,0,0,1,4.46V14.6a.372.372,0,0,0,.346.346c.069,0,.1-.035.173-.035a8.445,8.445,0,0,1,3.287-.761,6.435,6.435,0,0,1,3.806,1.038,8.335,8.335,0,0,1,3.806-1.038,7.043,7.043,0,0,1,3.287.727.312.312,0,0,0,.173.035.372.372,0,0,0,.346-.346V4.46a4.719,4.719,0,0,0-1.384-.692M7.227,13.047a9.921,9.921,0,0,0-2.422-.284,9.97,9.97,0,0,0-2.422.346V5.242a6.186,6.186,0,0,1,2.422-.436,6.186,6.186,0,0,1,2.422.436Z"
                  transform="translate(-0.308 0.384)"
                />
              </g>
            </svg>
          </span>
          <span>Articles</span>
        </p>
      </NavLink>
      {/* Navlink for Analytics */}
      {ui_visibility?.some((item) => item.startsWith("knowledgeBase_")) && (
        <NavLink
          to={`/knowledge-base/${pageIntegrationId}/analytics`}
          className={`btn ${styles.liveChatBtn} ${
            tabName === TabNamesLC.analytics.name ? styles.active : ""
          }`}
        >
          <p className="d-flex align-items-center mb-0 justify-content-center">
            <span className="pe-1">
              <svg
                id="Layer_2"
                data-name="Layer 2"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                className={`${styles.tabIcon}`}
              >
                <g id="invisible_box" data-name="invisible box">
                  <rect
                    id="Rectangle_1642"
                    data-name="Rectangle 1642"
                    width="18"
                    height="18"
                    fill="none"
                  />
                </g>
                <g
                  id="icons_Q2"
                  data-name="icons Q2"
                  transform="translate(1.5 1.5)"
                >
                  <path
                    id="Path_411"
                    data-name="Path 411"
                    d="M4,18.25H4a.75.75,0,0,1,.75-.75h13.5a.75.75,0,0,1,.75.75h0a.75.75,0,0,1-.75.75H4.75A.75.75,0,0,1,4,18.25ZM7.75,10A.75.75,0,0,0,7,9.25H7a.75.75,0,0,0-.75.75v6h1.5Zm9-5.25A.75.75,0,0,0,16,4h0a.75.75,0,0,0-.75.75V16h1.5Zm-6,3A.75.75,0,0,0,10,7h0a.75.75,0,0,0-.75.75V16h1.5Zm3,3.375a.75.75,0,0,0-.75-.75h0a.75.75,0,0,0-.75.75V16h1.5Z"
                    transform="translate(-4 -4)"
                    fill="#6d6d6d"
                  />
                </g>
              </svg>
            </span>
            <span> Analytics</span>
          </p>
        </NavLink>
      )}

      {/* Navlink for Settings */}
      <NavLink
        to={`/knowledge-base/${pageIntegrationId}/settings/themeSelection`}
        className={`btn ${styles.liveChatBtn}  ${
          tabName === TabNamesLC.settings.name ? styles.active : ""
        }`}
      >
        <p className="d-flex align-items-center mb-0 justify-content-center">
          <span className="pe-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              className={`${styles.tabIcon}`}
            >
              <g
                id="Icon_feather-settings"
                data-name="Icon feather-settings"
                transform="translate(-1 -1)"
              >
                <path
                  id="Path_416"
                  data-name="Path 416"
                  d="M17.591,15.545A2.045,2.045,0,1,1,15.545,13.5,2.045,2.045,0,0,1,17.591,15.545Z"
                  transform="translate(-6.545 -6.545)"
                  fill="none"
                  stroke="#4e4e4e"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1"
                />
                <path
                  id="Path_417"
                  data-name="Path 417"
                  d="M14.045,11.045a1.125,1.125,0,0,0,.225,1.241l.041.041a1.364,1.364,0,1,1-1.93,1.93l-.041-.041a1.134,1.134,0,0,0-1.923.8v.116a1.364,1.364,0,1,1-2.727,0v-.061a1.125,1.125,0,0,0-.736-1.03,1.125,1.125,0,0,0-1.241.225l-.041.041a1.364,1.364,0,1,1-1.93-1.93l.041-.041a1.134,1.134,0,0,0-.8-1.923H2.864a1.364,1.364,0,1,1,0-2.727h.061a1.125,1.125,0,0,0,1.03-.736A1.125,1.125,0,0,0,3.73,5.714l-.041-.041a1.364,1.364,0,1,1,1.93-1.93l.041.041A1.125,1.125,0,0,0,6.9,4.009h.055a1.125,1.125,0,0,0,.682-1.03V2.864a1.364,1.364,0,1,1,2.727,0v.061a1.134,1.134,0,0,0,1.923.8l.041-.041a1.364,1.364,0,1,1,1.93,1.93l-.041.041A1.125,1.125,0,0,0,13.991,6.9v.055a1.125,1.125,0,0,0,1.03.682h.116a1.364,1.364,0,1,1,0,2.727h-.061a1.125,1.125,0,0,0-1.03.682Z"
                  fill="none"
                  stroke="#4e4e4e"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1"
                />
              </g>
            </svg>
          </span>
          <span> Settings</span>
        </p>
      </NavLink>
    </div>
  );
}

export default TabSection;
