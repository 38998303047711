import { axiosJSON } from "src/globals/axiosEndPoints";
import { IReturnIntegrationData } from "./getAllReturnIntegrations";

export interface IGetReturnIntegrationByIdParam {
  integrationId: number;
}

export async function getReturnIntegrationById(
  payload: IGetReturnIntegrationByIdParam
) {
  const { data } = await axiosJSON.post(
    "/api/setting/automation/refund/getById",
    payload
  );

  if (data.error === true) {
    throw new Error("Some Error Occurred while fetching data");
  }

  return data.data as IReturnIntegrationData;
}
