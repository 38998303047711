import React from "react";
import styles from "./Tags.module.scss";
import { useCallback, useState } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import AddTag from "./children/AddTag/AddTag";
import NoTags from "./children/NoTags/NoTags";
import ShowTags from "./children/ShowTags/ShowTags";
import { useAppSelector } from "src/store/store";

function Tags() {
  const scopes = useAppSelector(
    (state) => state.globals.currentUserData?.scopes
  );
  return (
    <div className={`main__content`}>
      <div className=" ">
        <div className="">
          <div className="top-btn d-flex justify-content-between flex-wrap">
            <div>
              <h3 className={`${styles.page_heading} mb-0`}>Tags</h3>
              <p className={`mt-2 ${styles.headling_tagline} m-0`}>
                Can create different tags edit , search &amp; delete
              </p>
            </div>
            {/* Show the add tag component */}
            {scopes && scopes.includes("add_tags") && <AddTag />}
          </div>
        </div>
      </div>
      <div className={`${styles.page_content} mt-2 mt-md-3 mt-lg-5 px-1 px-md-2 px-lg-5`}>
        <div className="">
          {/* <div className="col-12">
            <form className={`${styles.users}`}>
              <div className="mb-3">
                <div className={`${styles.search} d-flex`}>
                  <i className={`${styles.fontAwsomeIcon} fa fa-search `} />
                  <input data-lpignore="true" 
                    type="text"
                    className={`${styles.form_control}`}
                    placeholder="Search"
                  />
                </div>
              </div>
            </form>
          </div> */}
          {/*-----TAG TABLE--*/}
          <ShowTags />
        </div>
        <hr className={`${styles.horizonLine}`}></hr>
        {/* No Tags */}
        {/* <NoTags/> */}
      </div>
    </div>
  );
}

export default Tags;
