import React, { useState } from "react";
import styles from "./Report.module.scss";

import "react-datepicker/dist/react-datepicker.css";
import CurrentStatus from "./Children/CurrentStatus/CurrentStatus";
import VolumeActivity from "./Children/VolumeActivity/VolumeActivity";
import Performance from "./Children/Performance/Performance";
import RevenueReport from "./Children/RevenueReport/RevenueReport";
import TotalChats from "./Children/TotalChats/TotalChats";
import MissedChats from "./Children/MissedChats/MissedChats";
import { useParams } from "react-router-dom";
import ChatSatisfaction from "./Children/ChatSatisfaction/ChatSatisfaction";
import ChatAvailability from "./Children/ChatAvailability/ChatAvailability";
const Report = () => {
  const { viewType } = useParams(); //get the URL parameters

  return (
    <div className={`p-3 ${styles.reportWrapper}`}>
      {/* For current status  */}
      {viewType === "current-status" && <CurrentStatus />}

      {/* For Volume and activity  */}
      {viewType === "volume-activity" && <VolumeActivity />}

      {/* For performance  */}
      {viewType === "performance" && <Performance />}

      {/* For revenue  */}
      {viewType === "revenue" && <RevenueReport />}

      {/* For Total chats  */}
      {viewType === "total-chats" && <TotalChats />}

      {/* For missed chats  */}
      {viewType === "missed-chats" && <MissedChats />}

      {viewType === "chat-satisfaction" && <ChatSatisfaction />}
      {viewType === "chat-availability" && <ChatAvailability />}
    </div>
  );
};

export default Report;
