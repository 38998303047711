import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { AJAXSTATUS } from "src/globals/constants";
import { AskForEmailWhenAgentOfflineData } from "src/services/LiveChat/Settings/askForEmail/updateAskForEmailDataV3";
import { IChatFormElementOptionList } from "src/services/LiveChat/Settings/preChatForm/getPreChatFormData";
import { ITicketChatFormDataV3 } from "../chatForm.declarations";
import askForEmailSettingExtraReducers from "./askForEmailSetting.extraReducers";
import askForEmailSettingReducers from "./askForEmailSetting.reducers";
import askForEmailSettingThunks from "./askForEmailSetting.thunks";

export const fetchAskForEmailData = createAsyncThunk(
  "askForEmailSetting/fetchAskForEmailData",
  askForEmailSettingThunks.fetchaskForEmailDataThunk
);

export interface IAskForEmailWhenAgentOfflineForm
  extends AskForEmailWhenAgentOfflineData {
  ticketChatFormData: ITicketChatFormDataV3;
  ticketChatFormDataAjaxStatus: AJAXSTATUS;
  updateTicketChatFormDataAjaxStatus: AJAXSTATUS;
  validationErrors: {
    messageTextErr: boolean,
    confirmationTextErr: boolean,
    brandEmailsErr: boolean,
    customEmailsErr: boolean,
    ticketConfirmationTextErr: boolean,
  }
}

export interface IAskForEmailData {
  formId: number | string;
  whenAgentOffline: {
    outsideBusinessHours: IAskForEmailWhenAgentOfflineForm;
    withinBusinessHours: IAskForEmailWhenAgentOfflineForm;
    formElementOptions: IChatFormElementOptionList;
  };

  WhenAgentOfflineWithinBusinessHourInitialHash: string,
  WhenAgentOfflineOusideBusinessHourInitialHash: string,
  whenAgentNotResponding: {
    canSendMessage: boolean | null;
    sendMessageAfter: number | string | null;
    messageText: string | null;
    confirmationText: string | null;
    canCustomerLeaveEmail: boolean | null;
  };
  availability: {
    canCustomerStartChatAlways: boolean;
  };
}

export interface IAskForEmailSetting {
  askForEmailData: IAskForEmailData;
  askForEmailDataAjaxStatus: AJAXSTATUS;
  updateAskForEmailDataAjaxStatus: AJAXSTATUS;
  showOutsideBusinessHours: boolean;
  showTicketForm: boolean;
}

export const initialState: IAskForEmailSetting = {
  askForEmailData: {
    formId: 0,
    whenAgentOffline: {
      formElementOptions: {
        formElementOptions: {},
        formElementOptionIds: [],
      },
      outsideBusinessHours: {
        messageText: "",
        confirmationText: "",
        ticketFormConfirmationText: "",
        askForEmail: false,
        autoReply: false,
        ticketFormId: 0,
        ticketChatFormData: {
          formId: 0,
          integrationId: 0,
          brandEmails: { enabled: false, email: [] },
          customEmails: { enabled: false, email: "" },
          elements: [],
          availability: {
            canCustomerStartChatAlways: false,
          },
        },
        validationErrors: {
          messageTextErr: false,
          confirmationTextErr: false,
          brandEmailsErr: false,
          customEmailsErr: false,
          ticketConfirmationTextErr: false,
        },
        ticketChatFormDataAjaxStatus: "idle",
        updateTicketChatFormDataAjaxStatus: "idle",
      },
      withinBusinessHours: {
        messageText: "",
        confirmationText: "",
        ticketFormConfirmationText: "",
        askForEmail: false,
        autoReply: false,
        ticketFormId: 0,
        ticketChatFormData: {
          formId: 0,
          integrationId: 0,
          brandEmails: { enabled: false, email: [] },
          customEmails: { enabled: false, email: "" },
          elements: [],
          availability: {
            canCustomerStartChatAlways: false,
          },
        },
        validationErrors: {
          messageTextErr: false,
          confirmationTextErr: false,
          brandEmailsErr: false,
          customEmailsErr: false,
          ticketConfirmationTextErr: false,
        },
        ticketChatFormDataAjaxStatus: "idle",
        updateTicketChatFormDataAjaxStatus: "idle",
      },
    },
    WhenAgentOfflineWithinBusinessHourInitialHash: "",
    WhenAgentOfflineOusideBusinessHourInitialHash: "",
    whenAgentNotResponding: {
      canSendMessage: false,
      sendMessageAfter: "",
      messageText: "",
      confirmationText: "",
      canCustomerLeaveEmail: false,
    },
    availability: {
      canCustomerStartChatAlways: false,
    },
  },
  showOutsideBusinessHours: false,
  showTicketForm: false,
  askForEmailDataAjaxStatus: "pending",
  updateAskForEmailDataAjaxStatus: "idle",
};

export const askForEmailSettingSlice = createSlice({
  name: "askForEmailSetting",
  initialState,
  reducers: askForEmailSettingReducers,
  extraReducers: (builder) => {
    builder.addCase(
      fetchAskForEmailData.fulfilled,
      askForEmailSettingExtraReducers.fetchAskForEmailDataFulfilled
    );
    builder.addCase(fetchAskForEmailData.rejected, (state) => {
      state.askForEmailDataAjaxStatus = "rejected";
      pushTheToast({
        position: "top-right",
        type: "danger",
        text: "Something went wrong!",
      });
    });
    builder.addCase(fetchAskForEmailData.pending, (state) => {
      state.askForEmailDataAjaxStatus = "pending";
    });
  },
});

export const {
  resetAskForEmailSetting,
  AddFormNewElement,
  removeFormElement,
  updateFormElement,
  setBrandEmailData,
  setCustomEmailData,
  setTicketFormConfirmationTextData,
  setFormElements,
  setAgentOfflineMessageText,
  setAgentOfflineConfirmationText,
  setAgentOfflineAskForEmail,
  setAgentOfflineAutoReply,
  setShowOutsideBusinessForm,
  setShowTicketForm,
  setAgentNotAvailableFormValidationErrors,
} = askForEmailSettingSlice.actions;

export default askForEmailSettingSlice.reducer;
