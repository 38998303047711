import styles from "./FoundNoTickets.module.scss";
import emptyBackPng from "src/assets/images/emptyBack.png";

function FoundNoTickets({
  text = "No Ticket Found",
  img = emptyBackPng,
  className = "",
}: {
  text?: string;
  img?: string;
  className?: string;
}) {
  return (
    <div className={`${styles.nothingFound} ${className}`}>
      <img
        src={img}
        className={`d-flex justify-content-center img-fuild m-auto mb-2 ${styles.noDataImg}`}
      />
      <div>{text}</div>
    </div>
  );
}

export default FoundNoTickets;
