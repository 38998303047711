import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AJAXSTATUS } from "src/globals/constants";
import { ReturnReasonOrderAndItemsTab } from "src/services/ReturnAutoWorkFlow/OldModals/returnReasonOrderAndItems/getReturnReasonOrderAndItems.service";
import { FormElementOption } from "src/services/ReturnAutoWorkFlow/OldModals/returnReasonOrderAndItems/getReturnReasonOrderAndItemsFormElementOptions.service";
import {
  addConditionHandler,
  ConditionTypes,
  createNewObjectHash,
  deleteConditionHelper,
  deleteTabHelper,
  RecursivePartial,
  updateConditionHelper,
  validateChangeHandler,
  validateConditionsHelper,
} from "src/features/ReturnAutoWorkFlow/children/ConfigModals/store/returnModalsSliceHelpers";
import { v4 as uuid } from "uuid";
import { ConfigConditionType } from "../../../oldReturnModals.types";
import returnReasonOrderAndItemsBuilders from "./returnReasonOrderAndItems.builder";

export interface ReasonFollowUpQuestion {
  additionalOptions: {
    id: string;
    value: string;
    isNew?: boolean;
  }[];
  elementId: string;
  elementTypeId: string;
  elementValue: string;
  error?: string | null;
  isNewElement?: boolean; //Added variable to send element id as null if it is new element
}

export interface FollowUpReturnReason {
  id: string;
  reason: {
    error?: string | null;
    value: string;
    moreQuestions: ReasonFollowUpQuestion[];
    isQuestionValid?: boolean;
  };
  error?: string | null;
  isNew?: boolean; //Added variable to send reason id as null if it is new reason
}

interface ReasonTypeDetails {
  reasonTypeId: string;
  reasonTypeName: string | null | undefined;
  // reasonSequence: number;
  conditions: ConditionTypes[];
  reasons: FollowUpReturnReason[];
  itemError: string | null;
  reasonError: string | null;
  errorCount: number;
  error: string | null;
  isAddConditionEnabled: boolean;
  isAddItemConditionEnabled: boolean;
  applicableForAllOrders: boolean;
  applicableForAllItems: boolean;
  isAddReasonEnabled: boolean;
  isValid: boolean;
  explainWhyTheyReturn: "notRequired" | "required" | "requiredAndCustom" | null; //Adding to select options as per xd
  explainWhyTheyReturnError: string | null; //Adding to show error when explainWhyTheyReturn is not selected
}

// interface AllOrderAndItemTabType {
//   id: string;
//   error: string | undefined;
//   reasons: ReasonTypes[];
//   errorCount: number;
// }

export interface ReturnReasonOrderAndItemsState {
  selectedReasonTypeId: string | null;
  reasonTypes: ReasonTypeDetails[];
  // allOrderAndItemTab: AllOrderAndItemTabType;
  error: string | null;
  initialHashStaticTab: string;
  initialHashOtherTab: string;
  currentHashStaticTab: string;
  currentHashOtherTab: string;
  excludeKeysForHash: string[];
  isChanged: boolean;
  intialStep12Config: {
    returnTypes: ReasonTypeDetails[] | null;
    // allOrderAndItem: AllOrderAndItemTabType | null;
  };

  conditionOptions: ConfigConditionType[];
  defaultOptions: ConfigConditionType[];
  itemOptions: ConfigConditionType[];
  getItemType: ReturnReasonOrderAndItemsTab[];

  formElementOptions: FormElementOption[];

  variableNames: any;
  operatorsNames: any;
  operatorSymbolMap: any;
  valueTypes: any;
  variableNameToValueType: any;

  fetchAjaxStatus: AJAXSTATUS;
  updateAjaxStatus: AJAXSTATUS;
  moreQuestionsModal: ReasonFollowUpQuestion[];
  moreQuestionsModalReasonId?: string;
  moreQuestionsModalReasonTypeId?: string;
}

const excludeKeys = [
  "itemError",
  "reasonError",
  "error",
  "errorCount",
  "isAddConditionEnabled",
  "isAddItemConditionEnabled",
  "isAddReasonEnabled",
  "isValid",
  "isQuestionValid", //Added to exclude while calculating hash
  "isNew",
  "isNewElement",
  "explainWhyTheyReturnError",
];

const firstReasonTypeId = uuid();

export const initialState: ReturnReasonOrderAndItemsState = {
  selectedReasonTypeId: firstReasonTypeId,
  reasonTypes: [
    {
      reasonTypeId: firstReasonTypeId,
      reasonTypeName: "Return Reason 1",
      conditions: [],
      reasons: [
        {
          id: uuid(),
          reason: {
            value: "",
            moreQuestions: [],
            isQuestionValid: false,
          },
          error: null,
        },
      ],
      errorCount: 0,
      error: null,
      isAddConditionEnabled: true,
      isAddItemConditionEnabled: true,

      // Enable these by default
      applicableForAllOrders: true,
      applicableForAllItems: true,

      isAddReasonEnabled: true,
      isValid: false,
      itemError: null,
      reasonError: null,
      explainWhyTheyReturn: null,
      explainWhyTheyReturnError: null,
    },
  ],
  error: "",
  initialHashStaticTab: "",
  initialHashOtherTab: "",
  currentHashStaticTab: "",
  currentHashOtherTab: "",
  excludeKeysForHash: excludeKeys,
  isChanged: false,
  intialStep12Config: {
    returnTypes: null,
  },

  conditionOptions: [],
  itemOptions: [],
  defaultOptions: [],
  getItemType: [],

  formElementOptions: [],

  variableNames: [],
  operatorsNames: {},
  operatorSymbolMap: {},
  valueTypes: {},
  variableNameToValueType: {},
  fetchAjaxStatus: "pending",
  updateAjaxStatus: "idle",
  moreQuestionsModal: [],
};

export const returnReasonOrderAndItemsSlice = createSlice({
  name: "returnReasonOrderAndItems",
  initialState,
  reducers: {
    // Add Reason Type
    addReasonType: (state: ReturnReasonOrderAndItemsState) => {
      const newReasonTypeId = uuid();

      state.selectedReasonTypeId = newReasonTypeId;

      state.reasonTypes.push({
        reasonTypeId: newReasonTypeId,
        reasonTypeName: `Return Reason ${state.reasonTypes.length + 1}`,
        conditions: [],
        reasons: [
          {
            //Adding default reason on adding new tab
            id: uuid(),
            reason: {
              value: "",
              moreQuestions: [],
              isQuestionValid: false,
            },
            error: null,
            isNew: true,
          },
        ],
        // reasonSequence: state.reasonTypes.length + 1,
        errorCount: 0,
        error: "",
        itemError: null,
        reasonError: null,
        isAddConditionEnabled: false, //Making false as intitially no condition selected
        isAddItemConditionEnabled: false, //Making false as intitially no condition selected
        applicableForAllOrders: true,
        applicableForAllItems: true,
        isAddReasonEnabled: false,
        isValid: false,
        explainWhyTheyReturn: null, //Adding default option as per xd
        explainWhyTheyReturnError: null,
      });
    },

    toggleAllOrder: (
      state: ReturnReasonOrderAndItemsState,
      { payload }: { payload: { reasonTypeId: string; value: boolean } },
    ) => {
      const selectedIdx = state.reasonTypes.findIndex(
        (reason) => reason.reasonTypeId === payload.reasonTypeId,
      );

      if (selectedIdx !== -1) {
        const selectedOrder = state.reasonTypes[selectedIdx];

        selectedOrder.applicableForAllOrders = payload.value;
        // if (payload.value === true) {
        //   // If toggle is enabled, add a default condition to conditions
        //   const condition = state.defaultOptions[0];
        //   selectedOrder.conditions = [
        //     {
        //       id: uuid(),
        //       operator: state.operatorsNames[condition.name][0],
        //       ruleType: "AND",
        //       values: getConditionValueOrDefault(
        //         state.variableNameToValueType[condition.name],
        //       ),
        //       variableName: condition.name as ConditionTypes["variableName"],
        //     },
        //   ];
        // } else {
        //   // If toggle is disabled, add an order condition
        //   const condition = state.conditionOptions[0];
        //   selectedOrder.conditions = [
        //     {
        //       id: uuid(),
        //       operator: state.operatorsNames[condition.name][0],
        //       ruleType: "AND",
        //       values: getConditionValueOrDefault(
        //         state.variableNameToValueType[condition.name],
        //       ),
        //       variableName: condition.name as ConditionTypes["variableName"],
        //     },
        //   ];
        //   selectedOrder.isAddConditionEnabled = false;
        //   if (selectedOrder.error !== undefined) {
        //     selectedOrder.error = null;
        //   }
        // }
      }
    },

    toggleAllItem: (
      state: ReturnReasonOrderAndItemsState,
      { payload }: { payload: { reasonTypeId: string; value: boolean } },
    ) => {
      const selectedIdx = state.reasonTypes.findIndex(
        (reason) => reason.reasonTypeId === payload.reasonTypeId,
      );

      if (selectedIdx !== -1) {
        const selectedOrder = state.reasonTypes[selectedIdx];

        selectedOrder.applicableForAllItems = payload.value;
        // if (payload.value === true) {
        //   // If toggle is enabled, add a default condition to conditions
        //   const condition = state.defaultOptions[0];
        //   selectedOrder.conditions = [
        //     {
        //       id: uuid(),
        //       operator: state.operatorsNames[condition.name][0],
        //       ruleType: "AND",
        //       values: getConditionValueOrDefault(
        //         state.variableNameToValueType[condition.name],
        //       ),
        //       variableName: condition.name as ConditionTypes["variableName"],
        //     },
        //   ];
        // } else {
        //   // If toggle is disabled, add an order condition
        //   const condition = state.itemOptions[0];
        //   selectedOrder.conditions = [
        //     {
        //       id: uuid(),
        //       operator: state.operatorsNames[condition.name][0],
        //       ruleType: "AND",
        //       values: getConditionValueOrDefault(
        //         state.variableNameToValueType[condition.name],
        //       ),
        //       variableName: condition.name as ConditionTypes["variableName"],
        //     },
        //   ];
        //   selectedOrder.isAddConditionEnabled = false;
        //   if (selectedOrder.error !== undefined) {
        //     selectedOrder.error = null;
        //   }
        // }
      }
    },

    // Update Reason Type
    updateReasonType(
      state: ReturnReasonOrderAndItemsState,
      params: PayloadAction<{
        reasonTypeDetail: RecursivePartial<ReasonTypeDetails>;
      }>,
    ) {
      const { reasonTypeId, reasonTypeName } = params.payload.reasonTypeDetail;

      const selectedIdx = state.reasonTypes.findIndex(
        (ret) => ret.reasonTypeId === reasonTypeId,
      );

      const selectedReason = state.reasonTypes[selectedIdx];

      if (reasonTypeName) selectedReason.reasonTypeName = reasonTypeName;
    },

    pushCurrentHashForSelectedTab: (
      state,
      params: PayloadAction<{ reasonTypeId: string | null }>,
    ) => {},

    // Delete Reason Type
    deleteReasonType: (state, { payload }: { payload: { id: string } }) => {
      const { updatedTabs, newSelectedTabId } = deleteTabHelper({
        tabs: state.reasonTypes,
        tabId: payload.id,
        selectedTabId: state.selectedReasonTypeId,
        idKey: "reasonTypeId",
      });

      state.reasonTypes = updatedTabs;
      state.selectedReasonTypeId = newSelectedTabId;
    },

    // Validate Reason Type
    validateReasonType(
      state: ReturnReasonOrderAndItemsState,
      params: PayloadAction<{
        reasonTypeId: string | null;
      }>,
    ) {
      const selectedReasonIdx = state.reasonTypes.findIndex(
        (reasonType) => reasonType.reasonTypeId === params.payload.reasonTypeId,
      );
      const reasonError = !state.reasonTypes.some((res) =>
        res.reasons.some((reason) => reason.reason.value.length === 0),
      );

      if (state.reasonTypes[selectedReasonIdx].explainWhyTheyReturn === null) {
        state.reasonTypes[selectedReasonIdx].explainWhyTheyReturnError =
          "Please fill out this field";
      } else {
        state.reasonTypes[selectedReasonIdx].explainWhyTheyReturnError = null;
      }

      if (reasonError) {
        // 03 Jan checking for explainWhyTheyReturn before checking reasons,as they are not considered when the value is "notRequired" as per new xd
        if (
          state.reasonTypes[selectedReasonIdx].explainWhyTheyReturn !==
          "notRequired"
        ) {
          state.reasonTypes.forEach((res) => {
            res.reasons.forEach((re) => {
              if (re.reason.value.length === 0) {
                re.error = "Please fill the field";
              }
            });
          });
        }
      }
      if (state.reasonTypes == null) {
        state.error = "Add a Reason Type ";
      }
    },

    pushInitialHash: (state) => {
      const hash = createNewObjectHash({
        excludeKeys: state.excludeKeysForHash,
        hashObject: state.reasonTypes,
      });

      if (hash) {
        state.initialHashOtherTab = hash;
        state.currentHashOtherTab = hash;
        state.intialStep12Config = {
          // allOrderAndItem: state.allOrderAndItemTab,
          returnTypes: state.reasonTypes,
        };
      }
    },

    validateChanges: (state) => {
      const { initialHashOtherTab } = state;

      const { isChanged, newHash } = validateChangeHandler({
        stateObject: state.reasonTypes,
        excludeKeys: state.excludeKeysForHash,
        initialHash: initialHashOtherTab,
      });

      if (newHash) {
        state.isChanged = isChanged;
        state.currentHashOtherTab = newHash;
      }
    },

    validateReturnReasonOrderAndItems(state) {
      const currentState = state;

      let itemConditionNames = Object.values(currentState.itemOptions).map(
        (item) => item.name,
      );

      currentState.reasonTypes.forEach((selectedItem, selectedItemIdx) => {
        const conditions = selectedItem.conditions;
        const reasons = selectedItem.reasons;

        if (
          selectedItemIdx !== 0 && //Dec 12 Ignore all orders tab for validation
          conditions.filter(
            (condition) => !itemConditionNames.includes(condition.variableName),
          ).length <= 0
        ) {
          selectedItem.error = "Atleast add one Condition";
          if (selectedItem.applicableForAllOrders) {
            selectedItem.error = null;
          }
        }

        if (
          selectedItemIdx !== 0 && //Dec 12 Ignore all orders tab for validation
          conditions.filter((condition) =>
            itemConditionNames.includes(condition.variableName),
          ).length <= 0
        ) {
          selectedItem.itemError = "Atleast add one condition required";
          if (selectedItem.applicableForAllItems) {
            selectedItem.itemError = null;
          }
        }
        // 03 Jan checking for explainWhyTheyReturn before checking reasons,as they are not considered when the value is "notRequired" as per new xd
        if (selectedItem.explainWhyTheyReturn !== "notRequired") {
          if (reasons.length <= 0) {
            selectedItem.reasonError = "Atleast add one reason";
          }
          const reasonCounts: Record<string, number> = {};

          // First, count occurrences of each reason
          reasons.forEach((re) => {
            const reasonValue = re.reason.value.trim();
            if (reasonValue.length > 0) {
              reasonCounts[reasonValue] = (reasonCounts[reasonValue] || 0) + 1;
            }
          });

          // Then, check each reason for errors
          reasons.forEach((re) => {
            const reasonValue = re.reason.value.trim();

            // Check if the reason field is empty
            if (reasonValue.length === 0) {
              re.error = "Please fill the field";
              selectedItem.reasonError = "Please fill the field";
              selectedItem.isAddReasonEnabled = false;
            }
            // Check for duplicate reasons
            else if (reasonCounts[reasonValue] > 1) {
              re.error = "Please enter unique reasons";
              selectedItem.reasonError = "Please enter unique reasons";
              selectedItem.isAddReasonEnabled = false;
            }
            // Clear the error if the reason is valid and not duplicated
            else {
              re.error = null;
              selectedItem.reasonError = null;
            }
          });
        } else {
          // Need to invalidated the previously added validations.
          selectedItem.reasonError = null;
        }
        // Check for explainWhyTheyReturn validation
        state.reasonTypes.forEach((res) => {
          if (res.explainWhyTheyReturn === null) {
            res.explainWhyTheyReturnError = "Please fill out this field";
          } else {
            res.explainWhyTheyReturnError = null;
          }
        });

        const {
          updatedConditions,
          isAddOrderConditionEnabled,
          isAddItemConditionEnabled,
        } = validateConditionsHelper({
          selectedTab: selectedItem,
          step: "step12",
          applicableForAllItems: selectedItem.applicableForAllItems,
          applicableForAllOrders: selectedItem.applicableForAllOrders,
        });

        state.reasonTypes[selectedItemIdx].conditions = updatedConditions;
        if (isAddOrderConditionEnabled !== null) {
          state.reasonTypes[selectedItemIdx].isAddConditionEnabled =
            isAddOrderConditionEnabled;
        }

        if (isAddItemConditionEnabled !== null) {
          state.reasonTypes[selectedItemIdx].isAddItemConditionEnabled =
            isAddItemConditionEnabled;
        }
        const errors = conditions
          .flatMap((condition) => {
            return Object.entries(condition.values).flatMap(
              ([key, value]: [any, any]) => {
                if (value.error === undefined) return null;
                return value.error;
              },
            );
          })
          .concat([
            selectedItem.error,
            selectedItem.itemError,
            selectedItem.reasonError,
            selectedItem.explainWhyTheyReturnError,
          ])
          .concat(
            // 03 Jan checking for explainWhyTheyReturn before checking reasons,as they are not considered when the value is "notRequired" as per new xd
            selectedItem.explainWhyTheyReturn !== "notRequired"
              ? reasons.map((reason) => {
                  //Added this check to show error if any reason text is empty
                  if (reason.error !== null) {
                    return "error";
                  }
                  if (
                    reason.error === null ||
                    reason.reason.isQuestionValid === true ||
                    reason.reason.moreQuestions.length === 0
                  ) {
                    return null;
                  }
                  return "error";
                })
              : null,
          );
        selectedItem.errorCount = errors.reduce((p, c) => {
          if (c !== null && c !== undefined) {
            return p + 1;
          }
          return p;
        }, 0);

        if (selectedItem.errorCount > 0) {
          selectedItem.isValid = false;
        } else {
          selectedItem.isValid = true;
        }
      });
    },

    resetHashes: (state) => {
      state.currentHashOtherTab = "";
      state.currentHashStaticTab = "";
    },

    undoConfig: (
      state,
      params: PayloadAction<{
        initialConfig: {
          returnTypes: ReasonTypeDetails[] | null;
        };
      }>,
    ) => {
      if (params.payload.initialConfig.returnTypes !== null) {
        state.reasonTypes = params.payload.initialConfig.returnTypes;
      }
    },

    addCondition: (
      state: ReturnReasonOrderAndItemsState,
      params: PayloadAction<{
        reasonTypeId: string;
        condition: string;
        isItemCondition?: boolean;
      }>,
    ) => {
      let selectedItem = state.reasonTypes.find(
        (resType) => resType.reasonTypeId === params.payload.reasonTypeId,
      );

      const selectedTab = addConditionHandler({
        selectedTab: selectedItem,
        conditionOptions: params.payload?.isItemCondition
          ? state.itemOptions
          : state.conditionOptions,
        payloadCondition: params.payload.condition,
        isItemCondition: params.payload?.isItemCondition,
      });

      selectedItem = selectedTab;

      // if (selectedItem !== undefined) {
      //   let operator: any = "";
      //   let variableValues = null;
      //   let variableName: any = "";

      //   if (params.payload?.isItemCondition === true) {
      //     let itemCondition = state.itemOptions[0];

      //     if (params.payload.condition !== "") {
      //       let addCondition = state.itemOptions.filter(
      //         (value) => value.name === params.payload.condition,
      //       );
      //       if (addCondition.length) {
      //         itemCondition = addCondition[0];
      //       }
      //     }

      //     variableName = itemCondition.name;
      //     operator = itemCondition.operators[0].name;
      //     variableValues = getConditionValueOrDefault(itemCondition.valueType);
      //   } else {
      //     let otherCondition = state.conditionOptions[0];

      //     if (params.payload.condition !== "") {
      //       let addCondition = state.conditionOptions.filter(
      //         (value) => value.name === params.payload.condition,
      //       );
      //       if (addCondition.length) {
      //         otherCondition = addCondition[0];
      //       }
      //     }

      //     variableName = otherCondition.name;
      //     operator = otherCondition.operators[0].name;
      //     variableValues = getConditionValueOrDefault(otherCondition.valueType);
      //   }
      //   selectedItem.conditions.push({
      //     id: uuid(),
      //     operator: operator,
      //     ruleType: "AND",
      //     values: variableValues,
      //     variableName: variableName,
      //   });

      //   if (params.payload.isItemCondition === true) {
      //     selectedItem.isAddItemConditionEnabled = false;
      //     if (selectedItem.itemError !== null) {
      //       selectedItem.itemError = null;
      //     }
      //   } else {
      //     // Check if the default value exists
      //     if (
      //       selectedItem.conditions[selectedItem.conditions.length - 1].values[
      //         "days"
      //       ]?.currentValue > 0
      //     ) {
      //       // Add new condition enabled if default value exists
      //       selectedItem.isAddConditionEnabled = true;
      //     } else {
      //       // Add new condition disabled if no default exists
      //       selectedItem.isAddConditionEnabled = false;
      //     }
      //     // Remove error if applied
      //     if (selectedItem.error !== null) {
      //       selectedItem.error = null;
      //     }
      //   }
      // }
    },

    updateCondition(
      state: ReturnReasonOrderAndItemsState,
      params: PayloadAction<{
        reasonTypeId: string;
        conditionToUpdate: RecursivePartial<ConditionTypes>;
      }>,
    ) {
      const { reasonTypeId, conditionToUpdate } = params.payload;

      const selectedReasonIdx = state.reasonTypes.findIndex(
        (reasonType) => reasonType.reasonTypeId === reasonTypeId,
      );

      if (selectedReasonIdx !== -1 && conditionToUpdate) {
        const selectedReason = state.reasonTypes[selectedReasonIdx];

        const conditions = selectedReason.conditions;

        const {
          updatedConditions,
          isAddOrderConditionEnabled,
          isAddItemConditionEnabled,
        } = updateConditionHelper({
          conditions,
          conditionToUpdate,
        });

        state.reasonTypes[selectedReasonIdx].conditions = updatedConditions;

        if (isAddOrderConditionEnabled !== null) {
          state.reasonTypes[selectedReasonIdx].isAddConditionEnabled =
            isAddOrderConditionEnabled;
        }

        if (isAddItemConditionEnabled !== null) {
          state.reasonTypes[selectedReasonIdx].isAddItemConditionEnabled =
            isAddItemConditionEnabled;
        }
      }
    },

    // Delete Condition
    deleteCondition: (
      state: ReturnReasonOrderAndItemsState,
      params: PayloadAction<{
        reasonTypeId: string;
        conditionId: string;
        isItemCondition?: boolean;
      }>,
    ) => {
      const { reasonTypeId, conditionId, isItemCondition } = params.payload;

      const selectedReasonIdx = state.reasonTypes.findIndex(
        (reasonType) => reasonType.reasonTypeId === reasonTypeId,
      );
      if (selectedReasonIdx !== -1) {
        const selectedReasonType = state.reasonTypes[selectedReasonIdx];
        const {
          updatedConditions,
          isAddOrderConditionEnabled,
          isAddItemConditionEnabled,
        } = deleteConditionHelper({
          conditionId: conditionId,
          conditions: selectedReasonType.conditions,
          conditionType: isItemCondition ? "item" : "order",
        });

        state.reasonTypes[selectedReasonIdx].conditions = updatedConditions;
        if (isAddOrderConditionEnabled !== null) {
          state.reasonTypes[selectedReasonIdx].isAddConditionEnabled =
            isAddOrderConditionEnabled;
        }
        if (isAddItemConditionEnabled !== null) {
          state.reasonTypes[selectedReasonIdx].isAddItemConditionEnabled =
            isAddItemConditionEnabled;
        }
      }
    },

    // Validate Condition
    validateCondition(
      state: ReturnReasonOrderAndItemsState,
      params: PayloadAction<{
        reasonTypeId: string;
      }>,
    ) {
      const selectedReasonIdx = state.reasonTypes.findIndex(
        (reasonType) => reasonType.reasonTypeId === params.payload.reasonTypeId,
      );

      const selectedReason = state.reasonTypes[selectedReasonIdx];

      let itemConditionNames = Object.values(state.itemOptions).map(
        (option) => option.name,
      );

      const conditions = selectedReason?.conditions;

      if (
        selectedReasonIdx !== 0 && //Dec 12 Ignore all orders tab for validation
        conditions.filter(
          (condition) => !itemConditionNames.includes(condition.variableName),
        ).length <= 0
      ) {
        selectedReason.error = "Atleast add one condition required";
        if (selectedReason.applicableForAllOrders) {
          selectedReason.error = null;
        }
      }
      if (
        selectedReasonIdx !== 0 && //Dec 12 Ignore all orders tab for validation
        conditions.filter((condition) =>
          itemConditionNames.includes(condition.variableName),
        ).length <= 0 &&
        !selectedReason.applicableForAllItems
      ) {
        selectedReason.itemError = "Atleast add one condition required";
        if (selectedReason.applicableForAllItems) {
          selectedReason.itemError = null;
        }
      }
      // Check for explainWhyTheyReturn validation
      state.reasonTypes.forEach((res) => {
        if (res.explainWhyTheyReturn === null) {
          res.explainWhyTheyReturnError = "Please fill out this field";
        } else {
          res.explainWhyTheyReturnError = null;
        }
      });
      // 03 Jan checking for explainWhyTheyReturn before checking reasons,as they are not considered when the value is "notRequired" as per new xd
      if (
        selectedReason &&
        selectedReason.explainWhyTheyReturn !== "notRequired"
      ) {
        if (selectedReason.reasons.length <= 0) {
          selectedReason.reasonError = "Atleast add one reason";
        }
      }

      // Validating conditions using helper
      const {
        updatedConditions,
        isAddOrderConditionEnabled,
        isAddItemConditionEnabled,
      } = validateConditionsHelper({
        selectedTab: selectedReason,
        step: "step12",
        applicableForAllItems: selectedReason.applicableForAllItems,
        applicableForAllOrders: selectedReason.applicableForAllOrders,
      });

      state.reasonTypes[selectedReasonIdx].conditions = updatedConditions;
      if (isAddOrderConditionEnabled !== null) {
        state.reasonTypes[selectedReasonIdx].isAddConditionEnabled =
          isAddOrderConditionEnabled;
      }

      if (isAddItemConditionEnabled !== null) {
        state.reasonTypes[selectedReasonIdx].isAddItemConditionEnabled =
          isAddItemConditionEnabled;
      }

      const errors = conditions
        .flatMap(
          (condition: {
            values: { [s: string]: unknown } | ArrayLike<unknown>;
          }) => {
            return Object.entries(condition.values).flatMap(
              ([key, value]: [any, any]) => {
                if (value.error === undefined) return null;
                return value.error;
              },
            );
          },
        )
        .concat([
          selectedReason.error,
          selectedReason.itemError,
          selectedReason.reasonError,
          selectedReason.explainWhyTheyReturnError,
        ]);

      selectedReason.errorCount = errors.reduce((p: number, c: null) => {
        if (c !== null) {
          return p + 1;
        }
        return p;
      }, 0);

      if (selectedReason.errorCount > 0) {
        selectedReason.isValid = false;
      } else {
        selectedReason.isValid = true;
      }
    },

    // Delete Reason
    deleteReason: (
      state: ReturnReasonOrderAndItemsState,
      params: PayloadAction<{ reasonTypeId: string; id: string }>,
    ) => {
      const { reasonTypeId, id } = params.payload;

      const selectedReasonTypeIdx = state.reasonTypes.findIndex(
        (reasonType) => reasonType.reasonTypeId === reasonTypeId,
      );

      if (selectedReasonTypeIdx !== -1) {
        const selectedReasonTypes = state.reasonTypes[selectedReasonTypeIdx];
        selectedReasonTypes.reasons = selectedReasonTypes.reasons.filter(
          (reason) => reason.id !== id,
        );
      }
    },

    // Update Reason
    updateReason(
      state: ReturnReasonOrderAndItemsState,
      params: PayloadAction<{
        reasonTypeId: string;
        reasonToUpdate: FollowUpReturnReason;
      }>,
    ) {
      const { reasonTypeId, reasonToUpdate } = params.payload;

      // if (reasonTypeId === "1") {
      //   if (reasonToUpdate) {
      //     const reasons = state.allOrderAndItemTab.reasons;
      //     const reasonIdx = reasons.findIndex(
      //       (reason) => reason.id === reasonToUpdate.id
      //     );

      //     if (reasonIdx !== -1) {
      //       reasons[reasonIdx].reason = params.payload.reasonToUpdate.reason;
      //     }
      //   }
      // }

      const selectedReasonTypeIdx = state.reasonTypes.findIndex(
        (reasonType) => reasonType.reasonTypeId === reasonTypeId,
      );

      if (reasonToUpdate.id !== undefined) {
        if (selectedReasonTypeIdx !== -1) {
          const selectedReasonType = state.reasonTypes[selectedReasonTypeIdx];

          const reasons = selectedReasonType.reasons;
          const reasonIdx = reasons.findIndex(
            (reason) => reason.id === reasonToUpdate.id,
          );

          if (reasonIdx !== -1) {
            reasons[reasonIdx].reason = params.payload.reasonToUpdate.reason;
          }
        }
      }
    },

    // Set Selected Reason Type
    setSelectedReasonTypeId(
      state: ReturnReasonOrderAndItemsState,
      params: PayloadAction<{ reasonTypeId: string }>,
    ) {
      state.selectedReasonTypeId = params.payload.reasonTypeId;
    },

    reorder(
      state,
      params: PayloadAction<{
        startIndex: number;
        endIndex: number;
        reasonTypeId: string;
      }>,
    ) {
      const { startIndex, endIndex, reasonTypeId } = params.payload;

      const selectedReasonTypeIdx = state.reasonTypes.findIndex(
        (reasonType) => reasonType.reasonTypeId === reasonTypeId,
      );

      if (selectedReasonTypeIdx !== -1) {
        const selectedReasonType = state.reasonTypes[selectedReasonTypeIdx];

        const [removed] = selectedReasonType.reasons.splice(startIndex, 1);
        selectedReasonType.reasons.splice(endIndex, 0, removed);
      }
    },

    // Reducers for updating Reason question answer
    updateReasonOption: (
      state,
      action: PayloadAction<{
        reasonTypeId: string;
        value: ReasonTypeDetails["explainWhyTheyReturn"];
      }>,
    ) => {
      const reasonType = state.reasonTypes.find(
        (reasonType) => reasonType.reasonTypeId === action.payload.reasonTypeId,
      );
      if (reasonType) {
        reasonType.explainWhyTheyReturn = action.payload.value;
        // update explainWhyTheyReturnError,when explainWhyTheyReturnError is selected
        if (reasonType.explainWhyTheyReturnError !== null) {
          reasonType.explainWhyTheyReturnError = null;
        }
      }
    },

    addReason: (state, action: PayloadAction<{ reasonTypeId: string }>) => {
      const reasonType = state.reasonTypes.find(
        (reasonType) => reasonType.reasonTypeId === action.payload.reasonTypeId,
      );
      if (reasonType) {
        reasonType.reasons.push({
          id: uuid(),
          error: null,
          reason: {
            value: "",
            moreQuestions: [],
            isQuestionValid: false,
          },
          isNew: true, //set isNew to true to send reason id as null if it is new reason
        });
        if (reasonType.reasonError !== null) {
          reasonType.reasonError = null;
        }
        reasonType.isAddReasonEnabled = false;
      }
    },
    removeReason: (
      state,
      action: PayloadAction<{ reasonTypeId: string; reasonId: string }>,
    ) => {
      const reasonType = state.reasonTypes.find(
        (reasonType) => reasonType.reasonTypeId === action.payload.reasonTypeId,
      );
      if (reasonType) {
        reasonType.reasons = reasonType.reasons.filter(
          (reason) => reason.id !== action.payload.reasonId,
        );
        if (reasonType.reasons.length === 0) {
          reasonType.reasonError = "Atleast add one reason";
        }
        reasonType.isAddReasonEnabled = true;
      }
    },
    updateReasonValue: (
      state,
      action: PayloadAction<{
        reasonTypeId: string;
        reasonId: string;
        value: string;
      }>,
    ) => {
      const reasonType = state.reasonTypes.find(
        (reasonType) => reasonType.reasonTypeId === action.payload.reasonTypeId,
      );
      if (reasonType) {
        const reason = reasonType.reasons.find(
          (reason) => reason.id === action.payload.reasonId,
        );
        if (reason) {
          reason.reason.value = action.payload.value;
          // 16 Sep 2024 Making this change as backend needs reasonId as null when they are updated
          if (!reason.isNew) {
            reason.isNew = true;
          }
        }
        const error = reasonType.reasons.some(
          (reason) => reason.reason.value.length === 0,
        );
        if (error) {
          reasonType.isAddReasonEnabled = false;
        } else {
          reasonType.reasonError = null;
          reasonType.isAddReasonEnabled = true;
        }
      }
    },
    /**
     * Adding new question.
     */
    addMoreQuestion: (state) => {
      state.moreQuestionsModal.push({
        additionalOptions: [],
        elementId: uuid(),
        elementTypeId: state.formElementOptions[0].elementId.toString(),
        elementValue: "",
        isNewElement: true,
      });
    },
    reorderMoreQuestion: (
      state,
      action: PayloadAction<{
        startIndex: number;
        endIndex: number;
      }>,
    ) => {
      const { startIndex, endIndex } = action.payload;

      const [removed] = state.moreQuestionsModal.splice(startIndex, 1);
      state.moreQuestionsModal.splice(endIndex, 0, removed);
    },
    /**
     * Removing a question from moreQuestionsModal using moreQuestionId
     */
    removeMoreQuestion: (
      state,
      action: PayloadAction<{
        moreQuestionId: string;
      }>,
    ) => {
      const { moreQuestionId } = action.payload;
      state.moreQuestionsModal = state.moreQuestionsModal.filter(
        (element) => moreQuestionId !== element.elementId,
      );
    },
    /**
     * Updating a question value in moreQuestionsModal using questionId
     */
    updateMoreQuestionValue: (
      state,
      action: PayloadAction<{
        questionId: string;
        value: string;
      }>,
    ) => {
      const question = state.moreQuestionsModal.find(
        (question) => question.elementId === action.payload.questionId,
      );
      if (question) {
        question.elementValue = action.payload.value;
      }
    },
    /**
     * Updating a question type in moreQuestionsModal using questionId
     */
    updateMoreQuestionType: (
      state,
      action: PayloadAction<{
        questionId: string;
        type: string;
        isAdditionalOptions?: boolean;
      }>,
    ) => {
      const question = state.moreQuestionsModal.find(
        (question) => question.elementId === action.payload.questionId,
      );
      if (question) {
        question.elementTypeId = action.payload.type;
        //11 Dec added to make additionalOptions as empty when multiple choice or dropdown changed to short or long answer
        if (!action.payload.isAdditionalOptions) {
          question.additionalOptions = [];
        }
      }
    },
    /**
     * Adding a question option in moreQuestionsModal using questionId
     */
    addMoreQuestionOption: (
      state,
      action: PayloadAction<{
        questionId: string;
        option: string;
      }>,
    ) => {
      const question = state.moreQuestionsModal.find(
        (question) => question.elementId === action.payload.questionId,
      );
      if (question) {
        question.additionalOptions.push({
          id: uuid(),
          value: action.payload.option,
          isNew: true,
        });
      }
    },
    /**
     * Removing a question option in moreQuestionsModal using questionId and option index
     */
    removeMoreQuestionOption: (
      state,
      action: PayloadAction<{
        questionId: string;
        optionIndex: number;
      }>,
    ) => {
      const question = state.moreQuestionsModal.find(
        (question) => question.elementId === action.payload.questionId,
      );
      if (question) {
        question.additionalOptions.splice(action.payload.optionIndex, 1);
      }
    },
    /**
     * Updating a question option in moreQuestionsModal using questionId and option index
     */
    updateMoreQuestionOption: (
      state,
      action: PayloadAction<{
        questionId: string;
        optionIndex: number;
        option: string;
      }>,
    ) => {
      const question = state.moreQuestionsModal.find(
        (question) => question.elementId === action.payload.questionId,
      );
      if (question) {
        question.additionalOptions[action.payload.optionIndex].value =
          action.payload.option;
      }
    },
    /**
     * Need to save the data of active more question in moreQuestionsModal state before rendering the questions to edit on modal.
     */
    setMoreQuestionModal: (
      state,
      action: PayloadAction<{
        moreQuestionsModal: ReasonFollowUpQuestion[];
        moreQuestionsModalReasonId: string;
        moreQuestionsModalReasonTypeId: string;
      }>,
    ) => {
      // Hence checking if the data is already saved or not.
      if (
        state.moreQuestionsModalReasonId !==
          action.payload.moreQuestionsModalReasonId ||
        state.moreQuestionsModalReasonTypeId !==
          action.payload.moreQuestionsModalReasonTypeId
      ) {
        state.moreQuestionsModal = [...action.payload.moreQuestionsModal];
        state.moreQuestionsModalReasonId =
          action.payload.moreQuestionsModalReasonId;
        state.moreQuestionsModalReasonTypeId =
          action.payload.moreQuestionsModalReasonTypeId;
      }
    },
    resetMoreQuestionModal: (state) => {
      state.moreQuestionsModal = [];
      delete state.moreQuestionsModalReasonId;
      delete state.moreQuestionsModalReasonTypeId;
    },
    /**
     * Used to save the modal data to reason more questions on clicking save button.
     */
    saveMoreQuestion: (
      state,
      action: PayloadAction<{ reasonId: string; reasonTypeId: string }>,
    ) => {
      const { reasonId, reasonTypeId } = action.payload;

      const reasonType = state.reasonTypes.find(
        (reasonType) => reasonType.reasonTypeId === reasonTypeId,
      );

      if (reasonType) {
        const reason = reasonType.reasons.find(
          (reason) => reason.id === reasonId,
        );

        if (reason) {
          reason.reason.moreQuestions = [...state.moreQuestionsModal];
        }
      }
    },
    validateMoreQuestion: (
      state,
      action: PayloadAction<{ reasonTypeId: string }>,
    ) => {
      const reasonType = state.reasonTypes.find(
        (reasonType) => reasonType.reasonTypeId === action.payload.reasonTypeId,
      );
      if (reasonType) {
        const reasons = reasonType.reasons;
        const formElementOptions = state.formElementOptions;

        const multipleChoiceTypeId = formElementOptions.find(
          (option) => option.elementLabel === "Multiple Choice",
        )?.elementId;

        const dropdownTypeId = formElementOptions.find(
          (option) => option.elementLabel === "Dropdown",
        )?.elementId;

        reasons.forEach((reason) => {
          // Check if reason has value or not

          if (reason.reason.value.length <= 0) {
            reason.error = "Please fill this field";
          } else {
            reason.error = null;
          }

          // Validate all more questions
          const moreQuestions = reason.reason.moreQuestions;

          if (moreQuestions.length <= 0) {
            // No questions added
            reason.reason.isQuestionValid = false;
          } else {
            // Validate all questions
            moreQuestions.forEach((question) => {
              if (
                // Check if question is filled
                !question.elementId ||
                !question.elementTypeId ||
                !question.elementValue
              ) {
                reason.reason.isQuestionValid = false;
              } else if (
                // Check if additionalOptions is present when multiple choice or dropdown is selected
                (question.elementTypeId === multipleChoiceTypeId?.toString() ||
                  question.elementTypeId === dropdownTypeId?.toString()) &&
                question.additionalOptions.length <= 0
              ) {
                reason.reason.isQuestionValid = false;
              } else {
                reason.reason.isQuestionValid = true;
              }
            });
          }
        });
      }
    },
    //Reset the step 12 configuration to the initial state
    resetStep12Config: (state: ReturnReasonOrderAndItemsState) => {
      return initialState;
    },
  },
  extraReducers: returnReasonOrderAndItemsBuilders,
});

export default returnReasonOrderAndItemsSlice.reducer;

export const {
  addReasonType,
  updateReasonType,
  deleteReasonType,
  validateReasonType,
  addCondition,
  updateCondition,
  deleteCondition,
  validateCondition,
  setSelectedReasonTypeId,
  updateReasonOption,
  addReason,
  deleteReason,
  updateReason,
  reorder,
  pushInitialHash,
  validateChanges,
  undoConfig,
  resetHashes,
  pushCurrentHashForSelectedTab,
  removeReason,
  updateReasonValue,
  addMoreQuestion,
  removeMoreQuestion,
  updateMoreQuestionValue,
  updateMoreQuestionType,
  addMoreQuestionOption,
  removeMoreQuestionOption,
  updateMoreQuestionOption,
  reorderMoreQuestion,
  setMoreQuestionModal,
  resetMoreQuestionModal,
  saveMoreQuestion,
  validateMoreQuestion,
  validateReturnReasonOrderAndItems,
  resetStep12Config,
  toggleAllOrder,
  toggleAllItem,
} = returnReasonOrderAndItemsSlice.actions;
