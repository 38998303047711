import styles from "./RequestModal.module.scss";
import saufterImg from "src/assets/images/saufter.gif";
import saufterText from "src/assets/images/saufterText.svg";
import closeModal from "src/assets/images/closeModal.png";
import caution from "src/assets/images/caution.png";
import loaderImg from "src/assets/images/darkLoader.gif";
import FeatureBox from "./FeatureBox/FeatureBox";
import { useCallback, useMemo, useState } from "react";
import { ComingSoonFeatureTypes } from "src/services/ComingSoon/reqCmngSnFeature.service";
import Lottie from "lottie-light-react";
import celebrate from "src/assets/images/celebrationNew.json";
import { Spinner } from "react-bootstrap";

// Define types for the different states of the modal
export type RequestModalTypes = null | "makeRequest" | "requested" | "loading";

// Props interface for the RequestModal component
interface Props {
  onHide: () => void;
  requestedFeatures: Array<ComingSoonFeatureTypes>;
  showModal: RequestModalTypes;
  requestAccess: (selectedItems: Array<ComingSoonFeatureTypes>) => void;
}

/**
 * RequestModal Component
 */
const RequestModal = ({
  onHide,
  requestedFeatures,
  showModal,
  requestAccess,
}: Props) => {
  // Local state to manage requested features
  const [requestedFeaturesLocal, setRequestedFeaturesLocal] =
    useState(requestedFeatures);

  // Memoize the requested features local state
  useMemo(() => {
    setRequestedFeaturesLocal(requestedFeatures);
  }, [requestedFeatures]);

  // Handler for feature checkbox change
  const handleFeatchureCheck: React.ChangeEventHandler<HTMLInputElement> =
    useCallback((e) => {
      const value = e.target.value as ComingSoonFeatureTypes;
      if (value) {
        setRequestedFeaturesLocal((prev) => {
          if (e.target.checked) {
            if (prev.includes(value)) {
              return prev;
            } else {
              return [...prev, value];
            }
          } else {
            return prev.filter((v) => v !== value);
          }
        });
      }
    }, []);

  // Handler for requesting access
  const handleRequestAccess = useCallback(() => {
    requestAccess(requestedFeaturesLocal);
  }, [requestAccess, requestedFeaturesLocal]);

  return (
    <div className={`py-4 px-5 ${styles.requestModal}`}>
      <div className="d-flex justify-content-between align-items-center">
        <span className="d-flex flex-nowrap">
        <img src={saufterImg} alt="" height={30} />
        <img style={{paddingLeft: "4px"}} src={saufterText} alt="" height={30} />
        </span>
        {showModal !== "loading" ? (
          <img
            src={closeModal}
            alt=""
            height={18}
            className="cursor-pointer"
            onClick={onHide}
          />
        ) : (
          <div></div>
        )}
      </div>
      {
      // showModal === "loading" ? (
      //   <div className="d-flex text-center text-danger justify-content-center align-items-center align-middle pt-5">
      //     <img src={loaderImg} className="w-100" />
      //   </div>
      // ) : 
      showModal === "makeRequest" || showModal === "loading" ? (
        <>
          <div className="mt-5">
            <h3 className={`${styles.heading}`}>
              Request early access to our innovative developments with
              Saufter.io
            </h3>
            <div>
              <img src={caution} alt="" height={14} width={14} />
              <span className={`ps-1 ${styles.cautionSpan}`}>
                Don't worry you will not be charged for requesting early access.
              </span>
            </div>

            <div className="mt-4 pt-2">
              <p className={`mb-2 ${styles.requestText}`}>
                Request early access to following features :
              </p>
              <div className="d-flex flex-wrap">
                <FeatureBox
                  label="Returns & exchanges"
                  onChange={handleFeatchureCheck}
                  checked={requestedFeaturesLocal.includes("return")}
                  disabled={requestedFeatures.includes("return")}
                  value={"return"}
                />
                <FeatureBox
                  label="Order tracking"
                  onChange={handleFeatchureCheck}
                  checked={requestedFeaturesLocal.includes("tracking")}
                  disabled={requestedFeatures.includes("tracking")}
                  value={"tracking"}
                />
                <FeatureBox
                  label="Warranty claims"
                  onChange={handleFeatchureCheck}
                  checked={requestedFeaturesLocal.includes("warranty")}
                  disabled={requestedFeatures.includes("warranty")}
                  value={"warranty"}
                />
                <FeatureBox
                  label="24/7 order monitoring"
                  onChange={handleFeatchureCheck}
                  checked={requestedFeaturesLocal.includes("monitoring")}
                  disabled={requestedFeatures.includes("monitoring")}
                  value={"monitoring"}
                />
                <FeatureBox
                  label="Order modification"
                  onChange={handleFeatchureCheck}
                  checked={requestedFeaturesLocal.includes("modification")}
                  disabled={requestedFeatures.includes("modification")}
                  value={"modification"}
                />
                <FeatureBox
                  label="Actionable insights"
                  onChange={handleFeatchureCheck}
                  checked={requestedFeaturesLocal.includes(
                    "actionable_insights"
                  )}
                  disabled={requestedFeatures.includes("actionable_insights")}
                  value={"actionable_insights"}
                />
              </div>
              <button
                className={`${styles.requestBtn} cursor-pointer d-flex align-items-center`}
                onClick={handleRequestAccess}
                disabled={showModal === "loading"}
              >
                <span className="pe-2">
                  <i className="fa-regular fa-clock"></i>
                </span>
                <span> Request early access</span>
                {showModal === "loading" ?
                  <Spinner
                  className="my-auto ms-2"
                  animation="border"
                  color="white"
                  size="sm"
                /> : null
                }
              </button>
            </div>
          </div>
        </>
      ) : showModal === "requested" ? (
        <>
          <div className={`mt-5 ${styles.sucessWrapper}`}>
            <h4 className={`mb-4 ${styles.successHead}`}>
              Successfully requested!
            </h4>
            <p className={`mb-3 pt-2 ${styles.goodText}`}>
              Woohoo , you're good to go !
            </p>
            <h6 className={`mb-3 ${styles.whatNext}`}>WHAT'S NEXT ? </h6>
            <span className={`d-inline-block ${styles.notifyText}`}>
              You will receive a notification from us as soon as it is your turn
              to access the joy!
            </span>
            <div className="mt-3 mb-3 d-flex align-items-center">
              <img src={caution} alt="" height={20} width={20} />
              <span className={`ps-1 ${styles.cautionSpan}`}>
                Don't worry you will not be charged for requesting early access.
              </span>
            </div>
            <div>
              <span className={`${styles.celebrateImg}`}>
                <Lottie animationData={celebrate}></Lottie>
              </span>

              <button
                className={`mt-5 ${styles.requestBtn} cursor-pointer d-flex align-items-center`}
                onClick={onHide}
              >
                Ok
              </button>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default RequestModal;
