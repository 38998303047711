import SuggestionTabs from "./Children/SuggestionTabs/SuggestionTabs";
import TableData from "./Children/TableData/TableData";
import styles from "./SuggestionTable.module.scss";
const SuggestionTable = () => {
  return (
    <div className="mt-4">
      <SuggestionTabs />
      <div className={`${styles.tableWrapper}`}>
        <div
          className={`mt-3 d-flex align-items-center justify-content-between px-3 ${styles.tableHead}`}
        >
          <div className={`${styles.article}`}>
            <span className={`${styles.tablePara}`}>Status</span>
          </div>
          <div className={`px-2 ${styles.article}`}>
            <span className={`${styles.tablePara}`}>Agent Response</span>
          </div>

          <div className={`${styles.article}`}>
            <span className={`px-2 ${styles.tablePara}`}>
              Customer Question
            </span>
          </div>
          <div className={` px-3 ${styles.article}`}>
            <span className={`${styles.tablePara}`}>Ticket ID</span>
          </div>
          <div className={`${styles.article}`}>
            <span className={`${styles.tablePara}`}>Suggestion date</span>
          </div>
          <div className={`${styles.article}`}>
            <span className={`${styles.tablePara}`}>Suggested by</span>
          </div>
        </div>
        <TableData />
      </div>
    </div>
  );
};

export default SuggestionTable;
