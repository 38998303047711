import { axiosJSON } from "src/globals/axiosEndPoints";
import { AutomationAPIPayload } from "../createAutomation.service";

export interface ICernReplyCustomerResponse {
  reply: string;
  response: string;
}

export interface IssueDescription {
  /**
   * Comes from backend. Needed in case of edit dispatch rule only
   */
  descriptionId?: string;
  /**
   * Description text
   */
  description: string;
  /**
   * Used for suggest articles in the current issue description
   * Only for Issue Dispatch right now
   */
  isSuggestArticles?: boolean;
  /**
   * Present if isSuggestArticles is true
   */
  articlesIds?: string[];
  /**
   * Present if isSuggestArticles is true
   */
  articleConfiguredResponse?: string;
  /**
   * Present if isSuggestArticles is true
   */
  isCernReplyToCustomerResponse?: boolean;
  /**
   * Present if isCernReplyToCustomerResponse is true
   */
  cernReplyToCustomerResponse?: ICernReplyCustomerResponse[];
  /**
   * Present for all issues
   */
  categoryIds?: string[];
}

export interface DispatchRule {
  id?: string;
  teamId?: string;
  agentIds: string[];
  channels: string[];
}

export interface DispatchRuleContactDetails {
  // Can be single agent
  // Can be single agent with team
  teamId: string;
  agentId: string[]; // []
  channels: string[];
  email?: string;
  whatsappPhone?: {
    countryCode: number;
    number: string;
  };
  facebookHandles?: string[];
  instagramHandles?: string[];
}

export interface CreateDispatchRuleConfigParams {
  integrationId: string;
  ruleName: string;
  userSegmentIds?: string[];
  /**
   * Only used for Bug Management Ids
   */
  bugCategoryIds?: string[];
  /**
   * Only used for Bug Management Ids
   */
  priorityIds?: string[];
  isAiReadIssueDescriptionForDispatch: boolean | null;
  /**
   * Used for overall suggest articles in the issue description
   * Only for Bug Management right now
   */
  isSuggestArticles?: boolean;
  /**
   * This will be empty if isAiReadIssueDescriptionForDispatch is false | null
   */
  issueDescription: IssueDescription[];
  dispatchToDetails: Array<DispatchRule>;
}

// interface Params {
//   integrationId: string;
//   ruleName: string;
// }

interface Response {
  configurationId?: string;
  status?: "duplicate_dispatched_conditions";
  message?: string;
}

export const createDispatchRuleConfigService = async ({
  payload,
  type,
}: AutomationAPIPayload<CreateDispatchRuleConfigParams>) => {
  const { data: res } = await axiosJSON.post(
    `/api/automation/${type}/createDispatchRuleConfig`,
    payload,
  );

  if (res.err || res.error) {
    if (res.status === "duplicate_dispatched_conditions") {
      return {
        status: res.status,
        message:
          res.msg ??
          res.message ??
          "The selected dispatching rules conditions are already in use. Please select different dispatched conditions",
      } as Response;
    }

    throw new Error(
      res.msg || res.message || "Cannot create the dispatch rule config",
    );
  }

  return res.data as Response;
};
