import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import draftFlowExtraReducers from "./draftFlow.extraReducers";
import draftFlowReducers from "./draftFlow.reducers";
import { getIsDraftEditing } from "./draftFlow.selectors";
import draftFlowThunks from "./draftFlow.thunks";

export const fetchAlldraftMsg = createAsyncThunk(
  "draftFlow/fetchAlldraftMsg",
  draftFlowThunks.fetchAllDraftMsgThunk
);
export const fetchDraftMsgHistory = createAsyncThunk(
  "draftFlow/fetchDraftMsgHistory",
  draftFlowThunks.fetchDraftMsgHistoryThunk
);
export const fetchUnsentDraft = createAsyncThunk(
  "draftFlow/fetchUnsentDraft",
  draftFlowThunks.fetchUnsentDraftThunk
);
export const fetchRemarkData = createAsyncThunk(
  "draftFlow/fetchRemarkData",
  draftFlowThunks.fetchRemarkDataThunk
);
export const addEditDraftMsgData = createAsyncThunk(
  "draftFlow/addEditDraftMsgData",
  draftFlowThunks.addEditDraftMsgDataThunk
);
export const addEditRemarkData = createAsyncThunk(
  "draftFlow/addEditRemarkData",
  draftFlowThunks.addEditRemarkDataThunk
);
export const deleteDraftRemarkData = createAsyncThunk(
  "draftFlow/deleteDraftRemarkData",
  draftFlowThunks.deleteDraftRemarkDataThunk
);

export interface IDraftMsgRemark {
  agentId: number;
  remarkContent: string;
  isEdited: boolean;
  remarkDate: string;
  remarkDateGmt: string;
  remarkId: number | string;
  agentName: string;
  agentImgUrl: string;
  isPublicAttachmentUrl?: boolean;
  attachments?: any;
}

export interface IDraftMsgHistory {
  agentId: number | null;
  agentName: string;
  agentImgUrl: string;
  isPublicAttachmentUrl?: boolean;
  draftMsgStatusId: number | null;
  draftMsgStatusName: string;
  messageDate: string;
  messageDateGmt: string;
  messageContent: string;
}

export interface DraftStatusCycle {
  draftMsgStatusId: number;
  draftMsgStatusName: string;
  draftMsgStatusDate: string;
  draftMsgStatusDateGmt: string;
}

export interface IDraftMsg extends IDraftMsgHistory {
  messageId: number | null;
  draftMsgHistoryCount: number;
  attachments?: any;
}

export interface IDraftMsgHistoryObj {
  [pageNo: string | number]: IDraftMsgHistory;
}

export interface IDraftFlow {
  draftMsg: IDraftMsg | null;
  draftStatusCycle: Array<DraftStatusCycle>;
  draftRemarks: { [id: string | number]: IDraftMsgRemark };
  draftRemarkIds: Array<number | string>;
  draftMsgHistory: IDraftMsgHistoryObj;
  unsentDraftMsg: Array<IDraftMsg>;
  canAddNewDraftMsg: boolean;
  ticketID: number | string;
  addEditMsgStatus: "idle" | "pending" | "fulfilled" | "rejected";
  addEditRemarkStatus: "idle" | "pending" | "fulfilled" | "rejected";
  getDraftMsgStatus: "idle" | "pending" | "fulfilled" | "rejected";
  historyStatus: "idle" | "pending" | "fulfilled" | "rejected";
  remarkStatus: "idle" | "pending" | "fulfilled" | "rejected";
  unsentStatus: "idle" | "pending" | "fulfilled" | "rejected";
  historyThreshold: number;
  unsentLimit: number;
  remarkLimit: number;
  unsentTotalCount: number;
  remarkTotalCount: number;
  isDraftEditing: boolean; // a draft message is being edited or not
}

export const initialState: IDraftFlow = {
  draftMsg: null,
  draftRemarks: {},
  draftRemarkIds: [],
  draftStatusCycle: [],
  draftMsgHistory: {},
  unsentDraftMsg: [],
  canAddNewDraftMsg: true,
  ticketID: 0,
  addEditMsgStatus: "idle",
  addEditRemarkStatus: "idle",
  getDraftMsgStatus: "pending",
  historyStatus: "idle",
  remarkStatus: "idle",
  unsentStatus: "idle",
  historyThreshold: 15,
  unsentLimit: 15,
  remarkLimit: 5,
  unsentTotalCount: 0,
  remarkTotalCount: 0,
  isDraftEditing: false,
};

export const draftFlowSlice = createSlice({
  name: "draftFlow",
  initialState,
  reducers: draftFlowReducers,
  extraReducers: (builder) => {
    builder.addCase(
      fetchAlldraftMsg.fulfilled,
      draftFlowExtraReducers.fetchAlldraftMsgFulfilled
    );
    builder.addCase(fetchAlldraftMsg.rejected, (state) => {
      state.getDraftMsgStatus = "rejected";
    });
    builder.addCase(fetchAlldraftMsg.pending, (state) => {
      state.getDraftMsgStatus = "pending";
    });

    builder.addCase(
      addEditDraftMsgData.fulfilled,
      draftFlowExtraReducers.addEditDraftMsgDataFulfilled
    );
    builder.addCase(addEditDraftMsgData.rejected, (state) => {
      state.addEditMsgStatus = "rejected";
    });
    builder.addCase(addEditDraftMsgData.pending, (state) => {
      state.addEditMsgStatus = "pending";
    });

    builder.addCase(
      deleteDraftRemarkData.fulfilled,
      draftFlowExtraReducers.deleteDraftRemarkDataFulfilled
    );
    builder.addCase(deleteDraftRemarkData.rejected, (state) => {
      state.addEditRemarkStatus = "rejected";
    });
    builder.addCase(deleteDraftRemarkData.pending, (state) => {
      state.addEditRemarkStatus = "pending";
    });

    builder.addCase(
      addEditRemarkData.fulfilled,
      draftFlowExtraReducers.addEditRemarkDataFulfilled
    );
    builder.addCase(addEditRemarkData.rejected, (state) => {
      state.addEditRemarkStatus = "rejected";
    });
    builder.addCase(addEditRemarkData.pending, (state) => {
      state.addEditRemarkStatus = "pending";
    });

    builder.addCase(
      fetchDraftMsgHistory.fulfilled,
      draftFlowExtraReducers.fetchDraftMsgHistoryFulfilled
    );
    builder.addCase(fetchDraftMsgHistory.rejected, (state) => {
      state.historyStatus = "rejected";
    });
    builder.addCase(fetchDraftMsgHistory.pending, (state) => {
      state.historyStatus = "pending";
    });

    builder.addCase(
      fetchUnsentDraft.fulfilled,
      draftFlowExtraReducers.fetchUnsentDraftFulfilled
    );
    builder.addCase(fetchUnsentDraft.rejected, (state) => {
      state.unsentStatus = "rejected";
    });
    builder.addCase(fetchUnsentDraft.pending, (state) => {
      state.unsentStatus = "pending";
    });

    builder.addCase(
      fetchRemarkData.fulfilled,
      draftFlowExtraReducers.fetchRemarkDataFulfilled
    );
    builder.addCase(fetchRemarkData.rejected, (state) => {
      state.remarkStatus = "rejected";
    });
    builder.addCase(fetchRemarkData.pending, (state) => {
      state.remarkStatus = "pending";
    });
  },
});

export const {
  resetDraftFlow,
  setTicketId,
  setDraftMsg,
  setDraftFlow,
  setIsDraftEditing,
} = draftFlowSlice.actions;

// selector to get is draft editing or not
export const isDraftEditingSelector = createSelector(
  [getIsDraftEditing],
  (isDraftEditing) => getIsDraftEditing
);

export default draftFlowSlice.reducer;
