import { axiosJSON } from "src/globals/axiosEndPoints";
import log from 'loglevel';
import { removeWhiteSpaces } from "src/utils/validations";

export type CreateTagPayload = {
  name: string
}

export async function createTagApi(servicePayload: CreateTagPayload) {
  servicePayload.name = removeWhiteSpaces(servicePayload.name);

  const { data } = await axiosJSON.post(`/api/tags/addTag`, servicePayload);
  if (data.err === true) {
    throw new Error(data.msg as string)
  }
  const normalizedData = normalizeData(data)
  // log.debug('create ticket tags raw response:', data);
  // log.debug('create ticket tags normalized response:', normalizedData);
  return normalizedData;
}


// convert incoming data to the required format
function normalizeData(response: any) {

  const normalizedData = response
  return normalizedData
}