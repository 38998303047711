import { axiosJSON } from "src/globals/axiosEndPoints";

export interface UpdateOrder {
  // data: {
    orderId: number;
    tags?: Array<string>;
    shippingAddress?: {
      firstName: string;
      lastName: string;
      company: string;
      address1: string;
      address2: string;
      city: string;
      country: string;
      countryCode: string;
      province: string;
      provinceCode: string;
      phone: string;
      zip: string;
    };
  // };
  error: boolean;
  message: string;
}

export interface UpdateOrderParams {
  orderId: number;
  // contact information
  email?: string;
  isUpdateCustomer?: boolean;
  // customer note
  note?: string; 
  tags?: Array<string>;
  shippingAddress?: {
    firstName: string;
    lastName: string;
    company: string;
    address1: string;
    address2: string;
    city: string;
    country: string;
    countryCode: string;
    province: string;
    provinceCode: string;
    phone: string;
    zip: string;
  };
}

export async function updateOrder(params: UpdateOrderParams) {
  const { data: res }: any = await axiosJSON.post(
    `/api/shopify/order/update`,
    params
  );

  if (res.error === true) {
    throw new Error(res.message as string);
  }

  return res.data as UpdateOrder;
}
