import styles from "./MessageSetting.module.scss";
import Select from "react-select";
import useMessageSetting from "./useMessageSetting";
import arrowRight from "src/assets/images/arrowRight.png";
const MessageSetting = () => {
  const {
    dropdown,
    setDropDown,
    styleTheme,
    setStyleTheme,
    font,
    language,
    setLanguage,
    customize,
    setCustomize,
    handleHeadlineChanges,
    handleSubHeadlineChanges,
    handleSearchInputChanges,
    handleZeroArticleChanges,
    handleOneArticleChanges,
    handleArticleCountChanges,
    handleNoTitleArticleTextChanges,
    selectedOption,
    handleChange,
    isLoading,
    selectedLanguage,
    languageOptions,
    handleLanguageChange,
    resetButtonClicked,
    handleResetDefaultButton,
    headline,
    subHeading,
    placeholderText,
    fontOptions,
    zeroArticle,
    oneArticle,
    articleCount,
    noTitleArticleText,
    resetLanguageDefault,
    textStatus,
    handleCustomTextDefaultReset,
  } = useMessageSetting();
  return (
    <div className={`p-3 ${styles.settingWrapper}`}>
      <p className={`${styles.heading} saufter_h4`}>Message settings</p>
      <div
        className={`d-flex justify-content-between align-items-center px-2 saufter_medium_h4 ${
          styles.dropBtn
        } ${dropdown && styles.borderSet}`}
        onClick={() => {
          setDropDown(!dropdown);
          setStyleTheme(false);
          setLanguage(false);
        }}
      >
        {/* Wording Section  */}
        <span>Wording</span>
        <span className={`${styles.arrow}`}>
          {dropdown ? (
            <img
              src={arrowRight}
              alt=""
              className={`${styles.arrowDown}`}
            />
          ) : (
            <img
              src={arrowRight}
              alt=""
            />
          )}
        </span>
      </div>
      {dropdown ? (
        <div className={`p-2 p-md-3 mb-2 ${styles.dropDown}`}>
          <div className="mb-2">
            {/* Home Page Headling  */}
            <p className={`mb-1 ${styles.headline} saufter_h5`}>
              HOME PAGE HEADLINE
            </p>
            <span className={`mb-2 ${styles.subHeadline} saufter_h6`}>
              This is the headline shown on the homepage of your knowledge base.
            </span>
            <input
              type="text"
              placeholder="What are you looking for?"
              className={`${styles.searchInput}`}
              onChange={handleHeadlineChanges}
              value={headline}
            />
            <span className={`d-flex justify-content-end ${styles.saveText}`}>
              {textStatus.headline}
            </span>
          </div>
          <div className="mb-2">
            <p className={`mb-1 ${styles.headline} saufter_h5`}>
              HOME PAGE SUBHEADING
            </p>
            <span className={`mb-2 ${styles.subHeadline} saufter_h6`}>
              This is the sub heading shown on the homepage of your knowledge
              base.
            </span>
            <input
              type="text"
              placeholder="Get help straight from our team…"
              className={`${styles.searchInput}`}
              onChange={handleSubHeadlineChanges}
              value={subHeading}
            />
            <span className={`d-flex justify-content-end ${styles.saveText}`}>
              {textStatus.subheading}
            </span>
          </div>
          <div className="">
            <p className={`mb-1 ${styles.headline} saufter_h5`}>
              SEARCH INPUT TEXT
            </p>
            <span className={`mb-2 ${styles.subHeadline} saufter_h6`}>
              This is the text shown inside your search box before someone types
              in a search term.
            </span>
            <input
              type="text"
              placeholder="Search for answers.."
              className={`${styles.searchInput}`}
              onChange={handleSearchInputChanges}
              value={placeholderText}
            />
            <span className={`d-flex justify-content-end ${styles.saveText}`}>
              {textStatus.placeholder}
            </span>
          </div>

          {/* <div className="mb-2 w-100 d-flex flex-row-reverse mt-2">
            <button className="btn btn-light" onClick={handelSaveChanges}>
              <small>
                {textChanged
                  ? "Save Changes"
                  : updateKBSettings.isLoading
                  ? "Updating..."
                  : "Saved"}
              </small>
            </button>
          </div> */}
        </div>
      ) : (
        ""
      )}

      {/* Font Section */}
      <div
        className={`d-flex justify-content-between align-items-center px-2 mt-2 saufter_medium_h4 ${
          styles.dropBtn
        } ${styleTheme && styles.borderSet}`}
        onClick={() => {
          setStyleTheme(!styleTheme);
          setDropDown(false);
          setLanguage(false);
        }}
      >
        <span>Font</span>
        <span className={`${styles.arrow}`}>
          {styleTheme ? (
            <img
              src={arrowRight}
              alt=""
              className={`${styles.arrowDown}`}
            />
          ) : (
            <img
              src={arrowRight}
              alt=""
            />
          )}
        </span>
      </div>
      {styleTheme ? (
        <div className={`p-2 p-md-3  ${styles.fontDropdown}`}>
          <p className={`mb-1 ${styles.chooseFont} saufter_h6`}>
            Choose from a selection of fonts to match your website’s tone &
            style.
          </p>
          <div>
            <span className={`${styles.fonts} saufter_h5`}>MAIN FONT</span>
            {/* <div
              className={`d-flex justify-content-between align-items-center px-2 mb-2 ${styles.dropBtn}`}
              onClick={() => setFont(!font)}
            >
              <span>Roboto</span>
              <span className={`${styles.arrow}`}>
                {font ? (
                  <i className="fa-solid fa-chevron-down"></i>
                ) : (
                  <i className="fa-solid fa-chevron-right"></i>
                )}
              </span>
            </div> */}
            {
              <>
                <div className="position-relative">
                  <Select
                    closeMenuOnSelect={true}
                    value={selectedOption}
                    onChange={handleChange}
                    options={fontOptions}
                    className={`${styles.text}`}
                    placeholder="Select a font"
                    isSearchable={false}
                    components={{ IndicatorSeparator: () => null }}
                  />
                </div>
              </>
            }
          </div>
        </div>
      ) : (
        ""
      )}

      {/* Language And Text */}
      <div
        className={`d-flex justify-content-between align-items-center px-2 mt-2 saufter_medium_h4 ${
          styles.dropBtn
        }  ${language && styles.borderSet}`}
        onClick={() => {
          setLanguage(!language);
          setStyleTheme(false);
          setDropDown(false);
        }}
      >
        <span>Language & Text</span>
        <span className={`${styles.arrow}`}>
          {language ? (
            <img
              src={arrowRight}
              alt=""
              className={`${styles.arrowDown}`}
            />
          ) : (
            <img
              src={arrowRight}
              alt=""
            />
          )}
        </span>
      </div>
      {language ? (
        <div className={`p-2 p-md-3 mb-2 ${styles.fontDropdown2}`}>
          <p className={`mb-0 ${styles.languageHead} saufter_h5`}>
            Default Language
          </p>
          <span
            className={`d-inline-block ${styles.languagesubHeading} saufter_h6`}
          >
            Set the language of your knowledge base.
          </span>
          {/* <div
            className={`d-flex justify-content-between align-items-center px-2 mb-2 ${styles.dropBtn}`}
            onClick={() => setSelectLang(!selectLang)}
          >
            <span>English</span>
            <span className={`${styles.arrow}`}>
              {font ? (
                <i className="fa-solid fa-chevron-down"></i>
              ) : (
                <i className="fa-solid fa-chevron-right"></i>
              )}
            </span>
          </div>
          <span className={`d-flex justify-content-end h-0 ${styles.saveText}`}>
    
            updating.....
          </span> */}
          {
            <>
              <div className="position-relative">
                <Select
                  closeMenuOnSelect={true}
                  value={selectedLanguage}
                  onChange={handleLanguageChange}
                  options={languageOptions}
                  className={`${styles.text}`}
                  placeholder="Choose Language"
                  isSearchable={false}
                  isLoading={isLoading}
                  components={{ IndicatorSeparator: () => null }}
                />
              </div>
            </>
          }
          <div className="mt-3">
            <p className={`mb-0 ${styles.languageHead} saufter_h5`}>
              Text Defaults
            </p>
            <span
              className={`d-inline-block ${styles.languagesubHeading} saufter_h6`}
            >
              You can edit the common strings that appear in your knowledge base
              here. This is great if you want to localize your content to a
              language of your choice, or change the wording in any of our
              defaults.
            </span>
            {/* <button
              className={`px-1 mt-2 mb-3 w-100 ${styles.resetLanguage}`}
              onClick={handleResetDefaultButton}
              disabled={
                resetLanguageDefault.isLoading ||
                selectedLanguage.value === "English"
              }
            >
              {resetButtonClicked && resetLanguageDefault.isSuccess
                ? "Reset!"!
                : resetLanguageDefault.isLoading
                ? "Reseting..."
                : "Reset to language defaults"}
            </button> */}
          </div>

          <div
            className={`d-flex justify-content-between align-items-center px-2 saufter_h5 ${
              styles.dropBtn
            } ${customize && styles.borderSet}`}
            onClick={() => setCustomize(!customize)}
          >
            <span className="pe-1">Customise the text defaults</span>
            <span className={`${styles.arrow}`}>
              {customize ? (
                <img
                  src={arrowRight}
                  alt=""
                  className={`${styles.arrowDown}`}
                />
              ) : (
                <img
                  src={arrowRight}
                  alt=""
                />
              )}
            </span>
          </div>
          {customize ? (
            <div className={`p-2 p-md-3 mb-2 ${styles.dropDownNew}`}>
              <div className="mb-2">
                <label
                  htmlFor=""
                  className={`${styles.labelName} saufter_h5`}
                >
                  0 articles
                </label>
                <div className="position-relative">
                  <input
                    type="text"
                    className={`${styles.articleInput}`}
                    placeholder="0 articles"
                    onChange={handleZeroArticleChanges}
                    value={zeroArticle}
                  />
                  <span
                    className={`${styles.resetPosition}`}
                    role="button"
                    onClick={() => {
                      handleCustomTextDefaultReset("zeroArticle");
                    }}
                  >
                    Reset
                  </span>
                </div>
                <span
                  className={`d-flex justify-content-end ${styles.saveText}`}
                >
                  {textStatus.zeroArticle}
                </span>
              </div>
              <div className="mb-2">
                <label
                  htmlFor=""
                  className={`${styles.labelName} saufter_h5`}
                >
                  1 articles
                </label>
                <div className="position-relative">
                  <input
                    type="text"
                    className={`${styles.articleInput}`}
                    placeholder="1 articles"
                    onChange={handleOneArticleChanges}
                    value={oneArticle}
                  />
                  <span
                    className={`${styles.resetPosition}`}
                    role="button"
                    onClick={() => {
                      handleCustomTextDefaultReset("oneArticle");
                    }}
                  >
                    Reset
                  </span>
                </div>
                <span
                  className={`d-flex justify-content-end ${styles.saveText}`}
                >
                  {textStatus.oneArticle}
                </span>
              </div>
              <div className="mb-2">
                <label
                  htmlFor=""
                  className={`${styles.labelName} saufter_h5`}
                >
                  % &#123;count&#125; articles
                </label>
                <div className="position-relative">
                  <input
                    type="text"
                    className={`${styles.articleInput}`}
                    placeholder="%{count} articles"
                    onChange={handleArticleCountChanges}
                    value={articleCount}
                  />
                  <span
                    className={`${styles.resetPosition}`}
                    role="button"
                    onClick={() => {
                      handleCustomTextDefaultReset("articleCount");
                    }}
                  >
                    Reset
                  </span>
                </div>
                <span
                  className={`d-flex justify-content-end ${styles.saveText}`}
                >
                  {textStatus.articleCount}
                </span>
              </div>
              <div className="mb-2">
                <label
                  htmlFor=""
                  className={`${styles.labelName} saufter_h5`}
                >
                  This article has no title
                </label>
                <div className="position-relative">
                  <input
                    type="text"
                    className={`${styles.articleInput}`}
                    placeholder="This article has no title"
                    onChange={handleNoTitleArticleTextChanges}
                    value={noTitleArticleText}
                  />
                  <span
                    className={`${styles.resetPosition}`}
                    role="button"
                    onClick={() => {
                      handleCustomTextDefaultReset("noTitleArticleText");
                    }}
                  >
                    Reset
                  </span>
                </div>
                <span
                  className={`d-flex justify-content-end ${styles.saveText}`}
                >
                  {textStatus.noTitleArticleText}
                </span>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default MessageSetting;
