import styles from "./TagItem.module.scss";

const TagItem = ({ tag, onClick }: any) => {
  const handleOnClick = (e: any) => {
    e.preventDefault();
    onClick(tag);
  };
  return (
    <div
      className={`btn w-100 ${styles["addTagItem"]} ${styles["tagItem"]}`}
      onClick={handleOnClick}
      id={`addTag_${tag.tagName.toLowerCase().replace(" ", "_")}`}
    >
      {tag.tagName}
    </div>
  );
};

export default TagItem;
