import { useCallback } from "react";
import DateTimeSelector from "./DateTimeSelector";
import {
  BetweenRange,
  FieldDataDateTypes,
} from "src/services/Automation/AutomationTicketRouting/getConditionOptions.service";

interface Props {
  value: BetweenRange<string>;
  onChange: (value: BetweenRange<string>) => void;
  placeholder?: string;
  dateType: FieldDataDateTypes;
  error?: string;
  suffix?: string;
}
const DateTimeSelectorRange = ({
  value,
  onChange,
  placeholder = "",
  dateType,
  error,
  suffix,
}: Props) => {
  const handleStartChange = useCallback(
    (dt: string) => {
      onChange({ ...value, start: dt });
    },
    [value, onChange],
  );
  const handleEndChange = useCallback(
    (dt: string) => {
      onChange({ ...value, end: dt });
    },
    [value, onChange],
  );

  return (
    <div>
      <DateTimeSelector
        value={value.start}
        onChange={handleStartChange}
        placeholder={placeholder}
        dateType={dateType}
        error={error}
        suffix={suffix}
      />
      <span className="mx-2">-</span>
      <DateTimeSelector
        value={value.end}
        onChange={handleEndChange}
        placeholder={placeholder}
        dateType={dateType}
        error={error}
        suffix={suffix}
      />
    </div>
  );
};

export default DateTimeSelectorRange;
