import { IAskForEmailSetting } from "./askForEmailSetting.slice";
import {v4 as uuidv4} from "uuid";
import { GetAskFormEmailData } from "src/services/LiveChat/Settings/askForEmail/getAskForEmailData";
import { IChatFormElementOptionList } from "src/services/LiveChat/Settings/preChatForm/getPreChatFormData";
var objectHash = require("object-hash");

const fetchAskForEmailDataFulfilled = (
  state: IAskForEmailSetting,
  { payload }: {payload: { askForEmailData: GetAskFormEmailData , formElementOptions: IChatFormElementOptionList }}
) => {
  
  // state = {
  //   ...state,
  //   askForEmailData: {...payload.askForEmailData},
  // };
  state.askForEmailData.availability = payload.askForEmailData.availability;
  
  state.askForEmailData.formId = payload.askForEmailData.formId;

  //when agent not response section data
  state.askForEmailData.whenAgentNotResponding = {
    ...state.askForEmailData.whenAgentNotResponding,
    ...payload.askForEmailData.whenAgentNotResponding
  };

  //form element options list for ticket form in agent not available section
  state.askForEmailData.whenAgentOffline.formElementOptions = {
    ...state.askForEmailData.whenAgentOffline.formElementOptions,
    ...payload.formElementOptions
  };
  
  state.askForEmailData.whenAgentOffline.withinBusinessHours = {
    ...state.askForEmailData.whenAgentOffline.withinBusinessHours,
    ...payload.askForEmailData.whenAgentOffline.withinBusinessHours
  };

  state.askForEmailData.whenAgentOffline.outsideBusinessHours = {
    ...state.askForEmailData.whenAgentOffline.outsideBusinessHours,
    ...payload.askForEmailData.whenAgentOffline.outsideBusinessHours
  };

  const formElementList = state.askForEmailData.whenAgentOffline.outsideBusinessHours.ticketChatFormData.elements.map((element: any) => {
    let key = uuidv4();
    return {
      ...element,
      uniqueId: key,
      isNewlyAdded: false,
    };
  });

  state.askForEmailData.whenAgentOffline.outsideBusinessHours.ticketChatFormData.elements = formElementList;

  const ticketFormElementList = state.askForEmailData.whenAgentOffline.withinBusinessHours.ticketChatFormData.elements.map((element: any) => {
    let key = uuidv4();
    return {
      ...element,
      uniqueId: key,
      isNewlyAdded: false,
    };
  });

  state.askForEmailData.whenAgentOffline.withinBusinessHours.ticketChatFormData.elements = ticketFormElementList;

  if(!state.askForEmailData.whenAgentOffline.withinBusinessHours.ticketChatFormData.brandEmails){
    state.askForEmailData.whenAgentOffline.withinBusinessHours.ticketChatFormData.brandEmails = {enabled: false, email: []};
  }
  if(!state.askForEmailData.whenAgentOffline.outsideBusinessHours.ticketChatFormData.brandEmails){
    state.askForEmailData.whenAgentOffline.outsideBusinessHours.ticketChatFormData.brandEmails = {enabled: false, email: []};
  }

  state.askForEmailDataAjaxStatus = "fulfilled";

  const withinBusinessHoursJson:any = {
    ...state.askForEmailData.whenAgentOffline.withinBusinessHours
  };
  const outsideBusinessHoursJson:any = {
    ...state.askForEmailData.whenAgentOffline.outsideBusinessHours
  };

  delete withinBusinessHoursJson["validationErrors"];
  delete outsideBusinessHoursJson["validationErrors"];

  state.askForEmailData.WhenAgentOfflineWithinBusinessHourInitialHash = objectHash(withinBusinessHoursJson);
  state.askForEmailData.WhenAgentOfflineOusideBusinessHourInitialHash = objectHash(outsideBusinessHoursJson);
  return state;
};


export default { fetchAskForEmailDataFulfilled };
