import "intl-tel-input/build/css/intlTelInput.css";
import "intl-tel-input/build/js/utils";
import styles from "./IntlTelInput.module.scss";
import intlTelInput, {
  CountryData,
  IntlTelInputGlobals,
  Options,
  Plugin,
} from "intl-tel-input";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

type IntlTelInputEvents = "numberchange" | "countrychange" | "initial";

export interface IntlTelInputParams {
  eventType: IntlTelInputEvents;
  isValidNumber: boolean;
  phoneNumber: string;
  countryData: CountryData;
  errorMessage: string;
}

interface IntlTelInputProps {
  onChange?: (params: IntlTelInputParams) => void;
  phoneNumber?: string;
  countryCode?: string;
  className?: string;
  initialValidate?: boolean;
  showError?: boolean;
  children(params: IntlTelInputParams): React.ReactNode;
  isDuplicate?: boolean;
  disabled?: boolean;
}

function IntlTelInput({
  disabled = false,
  children,
  className,
  countryCode,
  initialValidate = false,
  showError = true,
  isDuplicate,
  onChange,
  phoneNumber,
}: IntlTelInputProps) {
  const telInputRef = useRef<HTMLInputElement | null>(null);
  const iti = useRef<Plugin | null>(null);
  const isFirstRender = useRef(true);
  const [params, setParams] = useState<IntlTelInputParams>({
    eventType: "initial",
    isValidNumber: false,
    phoneNumber: "",
    countryData: { name: "", iso2: "", dialCode: "" },
    errorMessage: "",
  });

  const onCountryChange = useCallback(
    function _onCountryChange(e: any) {
      const params: IntlTelInputParams = {
        eventType: "countrychange",
        isValidNumber: iti.current?.isValidNumber() ?? false,
        phoneNumber: iti.current?.getNumber() ?? "",
        countryData:
          iti.current?.getSelectedCountryData() ?? ({} as CountryData),
        errorMessage:
          getValidationErrorMessage(
            iti.current?.getValidationError() ?? -1,
            iti.current?.isValidNumber() ?? false,
          ) ?? "",
      };
      if (!isFirstRender.current) {
        setParams(params);
      } else {
        isFirstRender.current = false;
      }
      if (onChange) {
        onChange(params);
      }
    },
    [onChange],
  );

  const onNumberChange = useCallback(
    function _onNumberChange(e: any) {
      const params: IntlTelInputParams = {
        eventType: iti.current?.getNumber() === "" ? "initial" : "numberchange", // when the number is empty, it's the initial event
        isValidNumber: iti.current?.isValidNumber() ?? false,
        phoneNumber: iti.current?.getNumber() ?? "",
        countryData:
          iti.current?.getSelectedCountryData() ?? ({} as CountryData),
        errorMessage:
          getValidationErrorMessage(
            iti.current?.getValidationError() ?? -1,
            iti.current?.isValidNumber() ?? false,
          ) ?? "",
      };

      if (!isFirstRender.current) {
        setParams(params);
      } else {
        isFirstRender.current = false;
      }

      if (onChange) {
        onChange(params);
      }
    },
    [onChange],
  );

  const validateInitialNumber = useCallback(() => {
    if (phoneNumber) {
      const isValidNumber = iti.current?.isValidNumber() ?? false;
      const errorMessage =
        getValidationErrorMessage(
          iti.current?.getValidationError() ?? -1,
          isValidNumber,
        ) ?? "";

      const newParams: IntlTelInputParams = {
        eventType: "initial",
        isValidNumber,
        phoneNumber: phoneNumber,
        countryData:
          iti.current?.getSelectedCountryData() ?? ({} as CountryData),
        errorMessage,
      };

      if (!isFirstRender.current) {
        setParams(newParams);
      } else {
        isFirstRender.current = false;
      }
    }
  }, [phoneNumber]);

  useLayoutEffect(() => {
    // console.log("phonernumber effect");
    if (phoneNumber) {
      iti.current?.setNumber(phoneNumber);

      if (initialValidate) {
        validateInitialNumber();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumber]);

  useLayoutEffect(() => {
    // console.log("countrycode effect");
    if (countryCode) {
      const iso2 = getCountryCodeFromISO2(countryCode);
      if (iso2) {
        iti.current?.setCountry(iso2);
      }
    }
  }, [countryCode]);

  useEffect(() => {
    // console.log("mount effect");
    const options: Options = {
      utilsScript:
        "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.12/js/utils.js",
      formatOnDisplay: true,
    };
    const ref = telInputRef.current;

    ref?.addEventListener("countrychange", onCountryChange);

    if (ref) {
      iti.current = intlTelInput(ref, options);
    }

    if (phoneNumber) {
      iti.current?.setNumber(phoneNumber);
    }

    if (countryCode) {
      const iso2 = getCountryCodeFromISO2(countryCode);
      if (iso2) {
        iti.current?.setCountry(iso2);
      }
    }

    return () => {
      ref?.removeEventListener("countrychange", onCountryChange);
    };
  }, []);

  return (
    <div className="w-100">
      <input
        type="tel"
        ref={telInputRef}
        onChange={onNumberChange}
        required
        className={`form-control ${className ? className : styles.formInput} ${
          // Add a red border if the phone number is invalid and has been entered
          showError &&
          (!params.isValidNumber || isDuplicate) &&
          params.phoneNumber !== "" &&
          !isFirstRender.current &&
          "border border-danger"
        }`}
        disabled={disabled}
      />
      {children && children(params)}
    </div>
  );
}

export function getCountryCodeFromISO2(dialCode: string): string | undefined {
  // @ts-ignore
  const itiGlobals: IntlTelInputGlobals = intlTelInputGlobals;
  const countryData = itiGlobals
    .getCountryData()
    .find((country: CountryData) => country.dialCode === dialCode);
  if (countryData) {
    return countryData.iso2;
  }
}
function getValidationErrorMessage(
  errorCode: number,
  isValidNumber: boolean,
): string | undefined {
  switch (errorCode) {
    case intlTelInputUtils.validationError.INVALID_COUNTRY_CODE:
      return "Choose a valid country code";

    case intlTelInputUtils.validationError.TOO_LONG:
      return "Phone number is too long";

    case intlTelInputUtils.validationError.TOO_SHORT:
      return "Phone number is too short";

    // @ts-ignore
    case intlTelInputUtils.validationError.INVALID_LENGTH:
      return "Please enter a valid phone number";

    default: {
      if (!isValidNumber) {
        return "Please enter a valid phone number";
      }
    }
  }
}

export default IntlTelInput;
