import { axiosFormData, axiosJSON } from "src/globals/axiosEndPoints";

export interface AddCustomerProfile {
  customerId: string;
  profileImageId: string;
  profileImageUrl: string;
}

export interface AddCustomerProfileParams {
  profileImage: File;
  customerId: string;
}

export async function addCustomerProfile(params: AddCustomerProfileParams) {
  const fd = new FormData();
  fd.append("profileImage", params.profileImage);
  fd.append("customerId", params.customerId);
  const { data }: any = await axiosFormData.post(
    `/api/customer/updateProfileImage`,
    fd
  );

  if (data.errors) {
    throw new Error(data.errors.join(""));
  }

  if (data.error === true) {
    throw new Error(data.message as string);
  }

  return data.data as AddCustomerProfile;
}
