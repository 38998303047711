import { ArticleData } from "src/services/LiveChat/Settings/knowledgeBased/articleCategory/getAllArticles";
import {
  IKnowledgeBasedSetting,
  initialState,
} from "./knowledgeBasedSetting.slice";

const resetKnowledgeBasedSetting = (state: IKnowledgeBasedSetting) => {
  state = { ...initialState };
  return state;
};

//reducer function to reset the existing featured article data
const resetFeaturedArticlesData = (state: IKnowledgeBasedSetting) => {
  state.featuredArticleMeta = initialState.featuredArticleMeta;
  state.featuredArticlesIds = initialState.featuredArticlesIds;
  state.featuredArticles = initialState.featuredArticles;
  state.fetchFeaturedArticlesAjaxStatus =
    initialState.fetchFeaturedArticlesAjaxStatus;
  return state;
};

// reducer for set new order of categories ids when drag and drop
const setNewOrderOfCategories = (
  state: IKnowledgeBasedSetting,
  {
    payload,
  }: {
    payload: {
      categoryIdList: Array<number | string>;
    };
  }
) => {
  state.categoryIdList = payload.categoryIdList;
  return state;
};

const setNewOrderOfArticles = (
  state: IKnowledgeBasedSetting,
  {
    payload,
  }: {
    payload: {
      categoryId: string;
      articeIdList: Array<number | string>;
    };
  }
  ) => {
    state.categoryData[payload.categoryId].articlesIds = payload.articeIdList;
    return state;
  };

//reducer function to reset the existing categories data
const resetArticleCategoriesData = (state: IKnowledgeBasedSetting) => {
  state.categoryData = initialState.categoryData;
  state.categoryIdList = initialState.categoryIdList;
  state.categoryMetaData = initialState.categoryMetaData;
  state.fetchCategoryAjaxStatus = initialState.fetchCategoryAjaxStatus;
  return state;
};

//reducer function to update active featured article data
const setActiveFeaturedArticleData = (
  state: IKnowledgeBasedSetting,
  {
    payload,
  }: {
    payload: {
      id?: number | string;
      integrationId?: number | string;
      type?: "default" | "custom";
      domainUrl?: string;
      domainConstraint?: "contains" | "isExactly";
      featuredArticles?: any;
      isDefault?: boolean;
    };
  }
) => {
  if (payload.id !== undefined) {
    state.activeFeaturedArticle.id = payload.id;
  }
  if (payload.integrationId !== undefined) {
    state.activeFeaturedArticle.integrationId = payload.integrationId;
  }
  if (payload.type !== undefined) {
    state.activeFeaturedArticle.type = payload.type;
  }
  if (payload.domainUrl !== undefined) {
    state.activeFeaturedArticle.domainUrl = payload.domainUrl;
  }
  if (payload.domainConstraint !== undefined) {
    state.activeFeaturedArticle.domainConstraint =
      payload.domainConstraint == "isExactly" ? "isExactly" : "contains";
  }
  if (payload.featuredArticles !== undefined) {
    state.activeFeaturedArticle.featuredArticles = payload.featuredArticles;
  }
  if (payload.isDefault !== undefined) {
    state.activeFeaturedArticle.isDefault = payload.isDefault;
  }
  return state;
};

//reducer function to reset active featured article data
const resetActiveFeaturedArticleData = (state: IKnowledgeBasedSetting) => {
  state.activeFeaturedArticle = initialState.activeFeaturedArticle;
  return state;
};

const addArticleIdToFeatured = (
  state: IKnowledgeBasedSetting,
  {
    payload,
  }: {
    payload: {
      articleId: number | string;
      shortTitle: string;
      articleName: string;
    };
  }
) => {
  if (
    state.activeFeaturedArticle.featuredArticles[payload.articleId] ===
    undefined
  ) {
    state.activeFeaturedArticle.featuredArticles[payload.articleId] = payload;
  } else {
    state.activeFeaturedArticle.featuredArticles[payload.articleId].shortTitle =
      payload.shortTitle;
    state.activeFeaturedArticle.featuredArticles[
      payload.articleId
    ].articleName = payload.articleName;
  }

  return state;
};
const removeArticleIdRemoveFeatured = (
  state: IKnowledgeBasedSetting,
  {
    payload,
  }: {
    payload: {
      articleId: number | string;
    };
  }
) => {
  if (
    state.activeFeaturedArticle.featuredArticles[payload.articleId] !==
    undefined
  ) {
    const activeFeaturedArticle = {
      ...state.activeFeaturedArticle.featuredArticles,
    };
    delete activeFeaturedArticle[payload.articleId];
    state.activeFeaturedArticle.featuredArticles = activeFeaturedArticle;
  }
  return state;
};

// Need to update article into particular category
const addDuplicateArticle = (
  state: IKnowledgeBasedSetting,
  {
    payload,
  }: {
    payload: {
      articleId: number | string;
      articleName: string;
      categoryId: string;
      createdAt: string;
      createdAtGmt: string;
      integrationId: number;
      articleStatus: string;
    };
  }
) => {
  state.categoryData[payload.categoryId].articles[payload.articleId] = {
    articleId: payload.articleId,
    articleName: payload.articleName,
    articleStatus: payload.articleStatus as "draft" | "public",
    createdAt: new Date(payload.createdAt),
    createdAtGmt: new Date(payload.createdAtGmt),
    integrationId: payload.integrationId,
  };
};

const deleteArticleFromList = (
  state: IKnowledgeBasedSetting,
  {
    payload,
  }: {
    payload: {
      articleId: number | string;
      categoryId: string;
    };
  }
) => {
  const { articleId, categoryId } = payload;
  const { articles } = state.categoryData[categoryId];
  delete articles[articleId];
  // Delete the articleId from the articlesIds array
  const articleIndex = state.categoryData[categoryId].articlesIds.indexOf(
    articleId
  );
  state.categoryData[categoryId].articlesIds.splice(articleIndex, 1);
};

const updateCategoryName = (
  state: IKnowledgeBasedSetting,
  {
    payload,
  }: {
    payload: {
      categoryId: string;
      categoryName: string;
    };
  }
) => {
  const { categoryId, categoryName } = payload;
  state.categoryData[categoryId].categoryName = categoryName;
};

export default {
  resetKnowledgeBasedSetting,
  resetFeaturedArticlesData,
  resetArticleCategoriesData,
  setActiveFeaturedArticleData,
  resetActiveFeaturedArticleData,
  addArticleIdToFeatured,
  removeArticleIdRemoveFeatured,
  addDuplicateArticle,
  deleteArticleFromList,
  updateCategoryName,
  setNewOrderOfCategories,
  setNewOrderOfArticles,
};
