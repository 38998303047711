import React, { useCallback, useState, useRef, useEffect, useMemo } from "react";
import styles from "./TeamPage.module.scss";
import { useNavigate, useParams } from "react-router-dom";
// import user from "src/assets/images/user.png";
import { Modal, Overlay } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  fetchAllTeams,
  fetchMembersInTeam,
  getTeamDataFromIdSelector,
  resetActiveTeamData,
  resetMembersInTeamData,
  resetTeamMateSearchValue,
  resetTeamsData,
  setActiveTeamData,
  setTeamMateSearchValue,
} from "src/store/slices/teamSettings/teamSettings.slice";
import {
  updateTeam,
  UpdateTeamParams,
} from "src/services/Settings/Teams/updateTeam";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";

import {
  DeleteTeamParams,
  deletTeam,
} from "src/services/Settings/Teams/deleteTeam";
import UsersInTeam from "./children/UsersInTeam/UsersInTeam";
import AddMembers from "./children/AddMembers/AddMembers";
import { v4 as uuidv4 } from "uuid";
import useDebounce from "src/hooks/useDebounce";
import { setSearchValue } from "src/store/slices/settings/user/userSettings.slice";
import DeleteModal from "./children/DeleteModal/DeleteModal";

function TeamPage() {
  const dispatch = useAppDispatch();
  // get the team id
  const { teamId }: any = useParams();
  const pageTeamId = teamId ? decodeURIComponent(teamId) : "";


  const teamDataState = useAppSelector((state) =>
    getTeamDataFromIdSelector(state, pageTeamId)
  );

  //Fetch the team data when reaload page
  useMemo(() => {
    if(!teamDataState){
      dispatch(fetchAllTeams());
    }
  },[]);

  // Added this state to update the team data when it is saved 
  const [teamData,setTeamData] = useState(teamDataState)
  const [teamName, setTeamName] = useState<string>("");
  const [showModal, setShowModal] = useState(false);
  const [isValidTeamName, setIsValidTeamName] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [disableDelete, setDisableDelete] = useState(false);

  const { addUserAjaxStatus } = useAppSelector((state) => state.userSettings);
  /*for team members */
  const {
    usersInTeam,
    usersInTeamIdList,
    fetchUsersInTeamAjaxStatus,
    usersInTeamCount,
    usersInTeamTotalCount,
  } = useAppSelector((state) => state.teamSettings);

  const onShow = useCallback(() => {
    setShowModal(true);
  }, []);
  const onHide = useCallback(() => {
    setShowModal(false);
  }, []);

  // const [show, setShow] = useState(false);
  // const target = useRef(null);

  let navigate = useNavigate();

  useMemo(() => {
    if (teamDataState) {
      setTeamData(teamDataState)
      setTeamName(teamDataState.teamName);
      dispatch(resetActiveTeamData());
      dispatch(setActiveTeamData(teamDataState));
    }
  }, [teamDataState]);

  // fetch the members of team
  /*useEffect(() => {
    dispatch(fetchMembersInTeam(parseInt(pageTeamId)));
  }, [])*/

  const handleBackBtn = (e: any) => {
    dispatch(resetActiveTeamData());
    dispatch(setSearchValue({ searchValue: "" }));
    dispatch(resetTeamMateSearchValue());
    dispatch(resetMembersInTeamData());
    dispatch(resetTeamsData());
    // dispatch(fetchAllTeams());
    navigate("/settings/Teams");
  };

  // function that handle team name input change
  const handleTeamNameChange = (e: any) => {
    let teamInputValue = e.target.value;
    setTeamName(teamInputValue);
    if (teamInputValue.trim().length > 0) {
      setIsValidTeamName(true);
      setDisableSubmit(false);
    } else {
      setIsValidTeamName(false);
      setDisableSubmit(true);
    }
  };

  // function to handle team update submit
  const handleTeamUpdateSubmit = (e: any) => {
    // disable the submit btn
    if (teamData) {
      setDisableSubmit(true);
      let updateData: UpdateTeamParams = {
        teamId: teamData.teamId,
        teamName: teamName.trim(),
      };
      updateTeam(updateData)
        .then((res) => {
          console.log(res,"res")
          dispatch(resetActiveTeamData());
          dispatch(fetchAllTeams());
          dispatch(setActiveTeamData(teamData));
          // Set team data after save
          setTeamData((team: any) => ({
            ...team,
            teamId: updateData.teamId,
            teamName: updateData.teamName,
          }));
          setTimeout(() => {
          pushTheToast({
              type: "success",
              text: "Team updated successfully!",
              position: "top-right",
            });
          }, 0);
        })
        .catch((err) => {
          pushTheToast({
            type: "danger",
            text: "Team update failed!",
            position: "top-right",
          });
          setDisableSubmit(false);
        });
    }
  };

  // function that handle delete of team
  const handleDelete = (e: any) => {
    if (teamData) {
      setDisableDelete(true);
      let deletePayload: DeleteTeamParams = {
        teamId: teamData.teamId,
      };
      // delete the team
      deletTeam(deletePayload)
        .then((res) => {
          dispatch(resetActiveTeamData());
          dispatch(fetchAllTeams());
          navigate("/settings/Teams");
          setTimeout(() => {
            pushTheToast({
              type: "success",
              text: "Team deleted successfully!",
              position: "top-right",
            });
          }, 0);
        })
        .catch((err) => {
          pushTheToast({
            type: "danger",
            text: "Team deletion failed!",
            position: "top-right",
          });
          setDisableDelete(false);
        });
    }
  };

  const [searchTerm, setSearchTerm] = useState<string>("");
  const debouncedSearchTerm = useDebounce(searchTerm, 2000);
  const inputSearchRef: any = useRef(null);
  useEffect(
    () => {
      handleSearchTeamMate();
      if (inputSearchRef.current) {
        inputSearchRef.current.blur();
      }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  );

  const handleSearchTeamMate = () => {
    dispatch(resetMembersInTeamData());
    dispatch(setTeamMateSearchValue({ searchValue: searchTerm }));
    dispatch(fetchMembersInTeam(parseInt(pageTeamId)));
  };

  /*useEffect(() => {
    if(addUserAjaxStatus === "fulfilled"){
      setTimeout(() => {
        pushTheToast({
          type: "success",
          text: "Team member added successfully!",
          position: "top-right"
        });
      }, 0);
    }

    if(addUserAjaxStatus === "rejected"){
      
    }
  },[addUserAjaxStatus])*/

  return (
    <div className={`main__content`}>
      <div className="d-flex justify-content-between align-items-baseline flex-wrap">
        <div className="d-flex flex-wrap">
          <div className="edit-icons mb-2 mb-md-0">
            <button
              style={{ border: "none", background: "none" }}
              onClick={(e) => {
                handleBackBtn(e);
              }}
            >
              <svg
                style={{ transform: "rotateZ(180deg)" }}
                width="29"
                height="29"
                viewBox="0 0 29 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.625 6.04191V22.9586C3.625 23.5995 3.87961 24.2142 4.33283 24.6674C4.78604 25.1206 5.40073 25.3752 6.04167 25.3752H22.9583C23.5993 25.3752 24.214 25.1206 24.6672 24.6674C25.1204 24.2142 25.375 23.5995 25.375 22.9586V6.04191C25.375 5.40097 25.1204 4.78628 24.6672 4.33307C24.214 3.87986 23.5993 3.62524 22.9583 3.62524H6.04167C5.40073 3.62524 4.78604 3.87986 4.33283 4.33307C3.87961 4.78628 3.625 5.40097 3.625 6.04191ZM8.45833 13.2919H14.5V8.45858L20.5417 14.5002L14.5 20.5419V15.7086H8.45833V13.2919Z"
                  stroke="black"
                  strokeWidth="2"
                />
                <path
                  d="M22.9584 14.5L14.7876 7.25V13.2917H7.78406V15.7083H14.7876V21.75L22.9584 14.5Z"
                  fill="black"
                />
              </svg>
            </button>
          </div>

          <div>
            <h2 className={`${styles.page_heading} ms-1 ms-md-3`}>
              {teamData?.teamName}
            </h2>
          </div>
        </div>

        <div className="">
          <div className="add-user add-team pe-5 mt-2 mt-md-0">
            <button
              className={`${styles.cancelButton} ${styles.pinkColor} me-3 m-1  `}
              data-bs-dismiss="modal"
              disabled={disableDelete == true ? true : false}
              onClick={(e) => onShow()}
            >
              {" "}
              Delete{" "}
            </button>
            <button
              type="submit"
              className={`${styles.pink_btn_submit} m-1 `}
              disabled={disableSubmit == true ? true : false}
              onClick={(e) => handleTeamUpdateSubmit(e)}
            >
              Save Changes
            </button>
          </div>
        </div>
      </div>

      <div
        className={`d-flex flex-column mt-5 px-0 px-md-5 ${styles.teamsett}`}
      >
        <div>
          <h6 className={`${styles.pageHead}`}>Team settings</h6>
        </div>
        <div className="mt-3">
          <div className="">
            <label
              htmlFor="exampleInputPassword1"
              className={`${styles.formLabel} form-label`}
            >
              {" "}
              Team Name
            </label>
            <div className="input-box d-flex align-items-center">
              <input
                data-lpignore="true"
                type="text"
                placeholder="General"
                value={teamName}
                className={`form-control mt-1 ${styles.formInput}`}
                id="teamName"
                name="inputTeamName"
                onChange={handleTeamNameChange}
              />
            </div>
          </div>

          <div className="mt-3">
            <label
              htmlFor="exampleInputPassword1"
              className={`${styles.formLabel} form-label text-dark `}
            >
              {" "}
              Assignement Method
            </label>
            <div className="input-box d-flex align-items-center">
              <input
                data-lpignore="true"
                type="text"
                placeholder="Manual"
                className={`form-control mt-1 ${styles.formInput}`}
                id="teamName"
              />
            </div>
          </div>
        </div>
      </div>

      <div className={`mt-5 px-0 px-md-5 ${styles.teamsett}`}>
        <div>
          <h6 className={`${styles.teamMate} `}>Teammates</h6>
        </div>
        <form className={`${styles.users}`}>
          <div className={`${styles.search} d-flex mt-3`}>
            <div>
              <i className={`${styles.fontAwsomeIcon} fa fa-search `} />{" "}
            </div>
            <input
              data-lpignore="true"
              type="text"
              className={`${styles.form_control}`}
              placeholder="Search Users"
              ref={inputSearchRef}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </form>
      </div>

      <div
        className={`d-flex justify-content-between mt-2 px-0 px-md-5 flex-wrap ${styles.teamsett}`}
      >
        <div>
          <p className={`${styles.showing} pt-2 px-2 px-md-0`}>
            Showing{" "}
            <span>
              {usersInTeamIdList.length > 0 ? 1 : 0}-{usersInTeamIdList.length}
            </span>{" "}
            of <span>{usersInTeamTotalCount}</span>
          </p>
        </div>
        <AddMembers />
      </div>
      <Modal
        show={showModal}
        onHide={onHide}
        dialogClassName={`${styles.deleteDialog}`}
        contentClassName={`${styles.deleteContent}`}
        centered={false}
        enforceFocus={false}
      >
        <DeleteModal
          confirmText="Are you sure you want to delete this team?"
          handleCancel={onHide}
          handleSubmit={handleDelete}
          isLoading={disableDelete}
        />
      </Modal>

      {/* Show the team members */}
      <UsersInTeam teamId={parseInt(pageTeamId)} />
    </div>
  );
}

export default TeamPage;
