// Component imports
import Step1 from "./Children/Step1/Step1";
import Step2 from "./Children/Step2/Step2";
import Step3 from "./Children/Step3/Step3";
import Step4 from "./Children/Step4/Step4";
import styles from "./StepSilder.module.scss";
import { useCreateAutomation } from "../../../../Hooks/useAutomationManager";
import { CreateAutomationParams } from "src/services/Automation/createAutomation.service";
import cross from "src/assets/images/featuredArticledCross.png";
import { useCallback, useRef, useState } from "react";
import ModelBackImg from "src/assets/images/ModelBackImg.png";
import { Controller, Swiper as ISwiper, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import GoBackButton from "./Children/GoBackButton/GoBackButton";
import NextStepButton from "./Children/NextStepButton/NextStepButton";

/**
 * Component for new Automation setup Steps with slider
 */
function StepsSlider({ onHide }: { onHide: () => void }) {
  const [controlledSwiper, setControlledSwiper] = useState<ISwiper | null>(
    null,
  );
  const { addNewAutomation, isLoading } = useCreateAutomation();
  const [showError, setShowError] = useState(false);

  const [disableBackBtn, setDisableBackBtn] = useState(false);

  const [hideNextBtn, setHideNextBtn] = useState(false);

  const stepsRef = useRef(null);

  const [stepsData, setStepsData] = useState<CreateAutomationParams>({
    brandId: -1,
    step_data: {
      selected_auto_type: "fully_automate",
    },
  });
  const handleAddAutomation = useCallback(() => {
    if (stepsData.brandId == -1) {
      setShowError(true);
    } else {
      setShowError(false);
      addNewAutomation({ ...stepsData });
    }
  }, [addNewAutomation, stepsData]);

  return (
    <div className={`px-1 px-md-4 pt-4`}>
      <img
        onClick={onHide}
        src={cross}
        alt="cross"
        className={`${styles.closeIcon}`}
      />
      <div
        className={`mt-3 d-flex justify-content-between align-items-center ${styles.stepsHeader}  `}
      >
        <GoBackButton
          disabled={disableBackBtn}
          swiper={controlledSwiper}
        />
        <div className={`px-1`}>
          <span className={`${styles.stepBox}`}>
            Step
            <span
              ref={stepsRef}
              className={`${styles.stepsCount} ms-2 align-middle`}
            ></span>
          </span>
        </div>
        <NextStepButton
          isVisible={true}
          swiper={controlledSwiper}
          hide={hideNextBtn}
          handleAddAutomation={handleAddAutomation}
          isLoading={isLoading}
        />
      </div>
      <Swiper
        modules={[Controller, Pagination]}
        onSwiper={setControlledSwiper}
        slidesPerView={1}
        pagination={{
          el: stepsRef.current,
          type: "custom",
          renderCustom(swiper, current, total) {
            return `0${current}/0${total}`;
          },
        }}
        allowTouchMove={false}
        style={{
          height: "257px",
        }}
      >
        {/* Step1 */}
        <SwiperSlide>
          <Step1 setBackBtn={setDisableBackBtn} />
        </SwiperSlide>

        {/* Step2 */}
        <SwiperSlide>
          <Step2 />
        </SwiperSlide>

        {/* Step3 */}
        <SwiperSlide>
          <Step4
            setShowError={setShowError}
            setStepsData={setStepsData}
            showError={showError}
            setHideBtn={setHideNextBtn}
          />
        </SwiperSlide>
      </Swiper>
      <img
        src={ModelBackImg}
        alt=""
      />
    </div>
  );
}
export default StepsSlider;
