import { axiosJSON } from "src/globals/axiosEndPoints";

interface IChannel {
  id: number;
  name: string;
  compare: string;
  isValueRequired: boolean;
}

export interface IChannelList {
  [id: number | string]: IChannel;
}

export async function getChannelList(params: { context?: "reports" } = {}) {
  const { data } = await axiosJSON.get(`/api/filters/channelOptions`, {
    params,
  });
  if (data.err === true) {
    throw new Error(data.msg as string);
  }
  const channelList: IChannelList = {};
  data.data.forEach((channel: IChannel) => {
    channelList[channel.id] = channel;
  });
  return channelList;
}
