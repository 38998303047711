import { useState } from "react";
import styles from "./AddVariable.module.scss";
import useAddVariable from "src/hooks/automation/useAddVariable";
import useGetVariables from "src/hooks/automation/useGetVariables";
import { IVariable } from "src/services/UiAutomation/ReturnExchange/Configuration/Variables/getAllVariables";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";

interface Props {
  onHide: () => void;
  handleInsert?: (placeholder: IVariable) => void;
}

const AddVariable = ({ onHide, handleInsert }: Props) => {
  const [name, setName] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const { AddVariable } = useAddVariable();
  const { refetch } = useGetVariables();

  const onSubmit = async () => {
    // Check if both name and message exist.
    if (name && message) {
      try {
        const result = await AddVariable(name, message);
        if (result.err === false) {
          await refetch();
          pushTheToast({
            position: "top-right",
            text: "Variable Added Successfully",
            type: "success",
          });
          onHide();
        } else {
          pushTheToast({
            position: "top-right",
            text: "Variable already exists",
            type: "danger",
          });
        }
      } catch (error) {
        console.error("Failed to add variable:", error);
      }
    } else {
      pushTheToast({
        position: "top-right",
        text: "Please fill required details",
        type: "warning",
      });
    }
  };
  // Submit and insert into Message Section
  const onSubmitAndInsert = async () => {
    if (name && message) {
      const result = await AddVariable(name, message);
      if (result.err === false) {
        await refetch();
        pushTheToast({
          position: "top-right",
          text: "Variable Added",
          type: "success",
        });
        onHide();
        handleInsert?.(result.data);
      } else {
        pushTheToast({
          position: "top-right",
          text: "Variable already exists",
          type: "danger",
        });
      }
    } else {
      pushTheToast({
        position: "top-right",
        text: "Please fill required details",
        type: "warning",
      });
    }
  };

  return (
    <div className={`position-relative py-3 ${styles.contentWrapper}`}>
      <div className={`d-flex justify-content-between px-3`}>
        <div className={`d-flex align-items-center`}>
          <span
            className={`d-flex justify-content-center align-items-center cursor-pointer ${styles.backArrow}`}
            onClick={onHide}
          >
            <i className="fa-solid fa-arrow-left"></i>
          </span>
          <span className={`ps-3 ${styles.addVariable}`}>Add Variables</span>
        </div>
        <div>
          <span
            className={`d-flex justify-content-center align-items-center cursor-pointer ${styles.backArrow}`}
            onClick={onHide}
          >
            <i className="fa-solid fa-xmark"></i>
          </span>
        </div>
      </div>
      <div className={`mt-3 px-3`}>
        <div>
          <div className="mb-3">
            <label
              htmlFor="variableName"
              className={`form-label ${styles.formName}`}
            >
              Name:
            </label>
            <input
              type="text"
              className={`form-control ${styles.formInput}`}
              id="variableName"
              placeholder="name@example.com"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>
          <div className="mb-3">
            <label
              htmlFor="variableText"
              className={`form-label ${styles.formName}`}
            >
              Message for the customer:
            </label>
            <textarea
              className={`form-control ${styles.formInput}`}
              id="variableText"
              rows={4}
              placeholder="write your message..."
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
            ></textarea>
          </div>
        </div>
      </div>
      <div
        className={`d-flex justify-content-end py-2 pe-3 w-100 ${styles.actionBox}`}
      >
        <button
          className={`btn me-lg-2 px-2 py-2 ${styles.saveBtn}`}
          onClick={onSubmit}
        >
          Save
        </button>
        <button
          className={`btn px-2 py-2 ${styles.insertBtn}`}
          onClick={onSubmitAndInsert}
        >
          Save & Insert
        </button>
      </div>
    </div>
  );
};

export default AddVariable;
