import { useQuery } from "@tanstack/react-query";
import { AJAXSTATUS } from "src/globals/constants";
import getSegmentFiltersService from "src/services/CustomerSegments/getSegmentFilters.service";

function useFetchFilters({
  segmentType,
  segmentId,
}: {
  segmentType: string;
  segmentId: string;
}) {
  const { data: filters, status } = useQuery(
    ["useFetchFilters", segmentType, segmentId],
    {
      queryFn: async () => {
        return segmentType
          ? await getSegmentFiltersService({
              segmentType,
              segmentId,
            })
          : undefined;
      },
      cacheTime: 3600000,
      staleTime: 3600000,
      networkMode: "always",
    },
  );

  return {
    filters: filters ?? [],
    filtersFetchStatus:
      status === "success"
        ? "fulfilled"
        : status === "loading"
          ? "pending"
          : ("rejected" as AJAXSTATUS),
  };
}

export default useFetchFilters;
