import {
  createAsyncThunk,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { AJAXSTATUS } from "src/globals/constants";
import { IAvailableDayOptionList } from "src/services/LiveChat/Settings/getChatSettingData";
import availabilitySettingExtraReducers from "./availabilitySetting.extraReducers";
import availabilitySettingReducers from "./availabilitySetting.reducers";
import availabilitySettingThunks from "./availabilitySetting.thunks";

export const fetchAvailabilityData = createAsyncThunk(
  "availabilitySetting/fetchAvailabilityData",
  availabilitySettingThunks.fetchAvailabilityDataThunk
);

export const updateAvailabilityData = createAsyncThunk(
  "availabilitySetting/updateAvailabilityData",
  availabilitySettingThunks.updateAvailabilityDataThunk
);

export interface IAgentAvailableAtData {
  id: string|number;
  availableDay : string;
  availableAtFrom : string;
  availableAtTo : string;
  timezone: string;
}

export interface IAvailabilityData {
  agentAvailableAt : Array<IAgentAvailableAtData>;
  canCustomerStartChatAlways: boolean;
  canCustomerViewChatHistory: boolean;
}

export interface IAvailabilitySetting {
  availabilityData : IAvailabilityData,
  availableDayOptions : IAvailableDayOptionList,
  availabilityDataAjaxStatus: AJAXSTATUS,
  updateAvailabilityDataAjaxStatus: AJAXSTATUS,

}

export const initialState : IAvailabilitySetting = {
  availabilityData: {
  agentAvailableAt : [],
  canCustomerStartChatAlways: false,
  canCustomerViewChatHistory: false,
  },
  availableDayOptions : {
    availableDayOptionKeys: [],
    availableDayOptions: {}
  },
  availabilityDataAjaxStatus: "pending",
  updateAvailabilityDataAjaxStatus: "idle",
}

export const availabilitySettingSlice = createSlice({
  name: "availabilitySetting",
  initialState,
  reducers: availabilitySettingReducers,
  extraReducers: (builder) => {
    builder.addCase(
      fetchAvailabilityData.fulfilled,
      availabilitySettingExtraReducers.fetchAvailabilityDataFulfilled
    );
    builder.addCase(fetchAvailabilityData.rejected, (state) => {
      state.availabilityDataAjaxStatus = "rejected";
      pushTheToast({
        position: "top-right",
        type: "danger",
        text: "Something went wrong!"
      });
    });
    builder.addCase(fetchAvailabilityData.pending, (state) => {
      state.availabilityDataAjaxStatus = "pending";
    });

    builder.addCase(
      updateAvailabilityData.fulfilled,
      availabilitySettingExtraReducers.updateAvailabilityDataFulfilled
    );
    builder.addCase(updateAvailabilityData.rejected, (state) => {
      state.updateAvailabilityDataAjaxStatus = "rejected";
      pushTheToast({
        position: "top-right",
        type: "danger",
        text: "Something went wrong!"
      });
    });
    builder.addCase(updateAvailabilityData.pending, (state) => {
      state.updateAvailabilityDataAjaxStatus = "pending";
    });
  }
});

export const { resetAvailabilitySetting } = availabilitySettingSlice.actions;

export default availabilitySettingSlice.reducer;
