import styles from "./InstallTracker.module.scss";
import back from "src/assets/images/backArrow2.png";
import copy from "src/assets/images/copy.png";
import TrackerDetails from "./Children/TrackerDetails/TrackerDetails";
import ActionComp from "./Children/ActionComp/ActionComp";
import { useState } from "react";
const InstallTracker = () => {
  const [copyText, setCopyText] = useState(false);

  let body = "</body> ";
  let code = `<!-- Start of the Sales tracker code (place after LiveChat tracking code) --> 
  <script type="text/javascript"> 
  var LC_API = LC_API || {on_after_load: function(){}}; 
  (function(cb){ 
    LC_API.on_after_load = function(){ 
        cb(); try { 
        var custom_variables = [ {name: '__order_id', value: 12345}, {name: '__order_price', value: 5k} ]; LC_API.trigger_sales_tracker('UrhprfQIqMFsGuQhkll5YrwBJtSDxIqs', custom_variables); } catch (error) { if (window.console){ console.log('LiveChat sales tracker error:', error); } } }; })(LC_API.on_after_load); </script> <!-- End of the Sales tracker code -->`;
  const copyCode = () => {
    // copy the code inside the code variable
    navigator.clipboard.writeText(code);
    // for change of text from copy to copied need to setCopyText to true
    setCopyText(true);
    //console.log(code);
  };
  return (
    <>
      <div className={`${styles.installMain}`}>
        <div className={`d-flex align-items-md-center`}>
          <img
            className={`cursor-pointer ${styles.backImg}`}
            src={back}
            alt="backArrow"
          />
          <h2 className={`${styles.heading}`}>
            Step 2 of 2: Install Sales Tracker code on your website
          </h2>
        </div>
        <div className={`${styles.codeBox}`}>
          <p>
            Copy this code and paste it before the closing{" "}
            <span className={`${styles.bodyTag}`}>{body}</span>
            tag on the page{" "}
            <span className={`${styles.boldSpan}`}>
              you want the conversion to happen.
            </span>{" "}
            Make sure the LiveChat{" "}
            <span className={`${styles.boldSpan}`}>
              tracking code comes right before
            </span>{" "}
            the Sales tracker code.
          </p>
          <div className={`${styles.innerCodeBox}`}>
            <span className={`${styles.code}`}>{code}</span>
            <div className={`d-flex justify-content-end`}>
              <button
                className={`px-2 ${styles.copyBtn} ${
                  copyText ? styles.copiedText : ""
                }`}
                onClick={copyCode}
              >
                <span>
                  {/* <img className={`${styles.copyImg}`} src={copy} alt="" /> */}
                  <i className="fa-regular fa-copy"></i>
                </span>{" "}
                {copyText ? "Copied !" : "Copy Code"}
              </button>
            </div>
          </div>
        </div>
        <div className={`mt-3`}>
          <p className={`${styles.trackHead}`}>Tracker Details</p>
          <TrackerDetails />
        </div>
        <div>
          <ActionComp />
        </div>
      </div>
    </>
  );
};
export default InstallTracker;
