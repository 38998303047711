/**
 * This file is the service file used for making api request.
 * It contains the getTimeZonesService function service which is explained below.
 *
 * @author Yash Aditya
 */

// Import statements for types
import { axiosJSON } from "src/globals/axiosEndPoints";

// Type for time zones
export interface TimeZone {
  id: string;
  label: string;
}

// Function to get time zones
export const getTimeZonesService = async () => {
  const { data: res } = await axiosJSON.post(
    "/api/reports/notificationSettings/getTimeZones"
  );

  if (res.err || res.error) {
    throw res.msg || res.message;
  }

  return res.data as Array<TimeZone>;
};
