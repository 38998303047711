import {
  CSVTableVarientImage,
  ImportedCSVTable,
} from "src/features/ReturnAutoWorkFlow/ReturnAutoWorkFlow.types";
import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";

/**
 * Parameters for fetching all the products based on csv uploaded file.
 */
export interface GetAllImportedProductsParams {
  integrationId: string;
  /** The starting index for the orders to be fetched, used for pagination. */
  start: number;
  /** The maximum number of orders to be fetched in a single request, used for pagination. */
  limit: number;
  attachmentId?: string; //(optional) to get specific import products
}

/**
 * Represents a table heading in the orders table.
 */
export interface TableHeading {
  /**
   * The unique identifier for the table heading.
   * This ID will be used in rows to render corresponding data.
   */
  tableHeadingId: string;
  /** The display name of the table heading. */
  tableHeadingName: string;
  /**
   * This is needed for the map attributes feature.
   * Here after changing the heading with anutoer one and on submit API the changedTableHeadingId will be mapped to tableHeadingId.
   */
  changedTableHeadingId?: null | string;
}

/**
 * The data for the corresponding row in the orders table.
 * - `type` can be `null`, `"string"`, `"number"`, or `"price" or `"date"`.
 * - `value` can be `null`, a `string`, a `number`, or an `IAmount`.
 */
export interface VarientRow {
  /**
   * Here the `tableHeadingId` will be from the `tableHeadings`.
   * null can be possible in the case if image is not uploaded yet.
   */
  // tableHeadingId: string;
  /**
   * `varientImage` will have and specific data type as `CSVTableVarientImage` in `value`.
   * null or undefined will be there for string.
   */
  type?: null | "varientImage";
  value: null | CSVTableVarientImage | string;
}
/**
 * Represents an individual order.
 */
export interface IVarient {
  /** The unique identifier for the varient. */
  varientId: string;
  rowData: VarientRow[];
}

/**
 * The response structure for the `getAllImportedProducts` function.
 */
export interface GetAllImportedProductsResponse {
  /** The name of the imported file. */
  fileName: string;
  /** The total number of varients in the table. */
  totalVarients: number;
  /** The total number of products in the table. */
  totalProducts: number;
  /** The total SKU */
  totalSKU: number;
  /**
   * The array of table headings to be displayed in the products table.
   */
  tableHeadings: TableHeading[];
  /**
   * The list of all the variants in the table.
   */
  varients: IVarient[];
}

/**
 * Fetches all products data for the Uploaded CSV modal.
 *
 * This function used to retrieve all products data for uploaded CSV modal.
 *
 * @param {GetAllImportedProductsParams} params - The parameters to send with the request.
 * @returns {Promise<ImportedCSVTable>} A promise that resolves to the response data containing all products.
 * @throws {Error} Throws an error if the API response contains an error or if an error occurs during the request.
 */
async function getAllImportedProducts(
  params: GetAllImportedProductsParams,
): Promise<ImportedCSVTable> {
  const apiEndPoint = IS_TEST_ACTIVE
    ? `${process.env.REACT_APP_TEST_SITE_URL}/getAllImportedProducts`
    : `/api/setting/automation/refund/product/getAll`;

  const { data: res } = await axiosJSON.post(apiEndPoint, params);

  if (res.err || res.error) {
    throw new Error(res.msg ?? res.message ?? "Some Error Occurred.");
  }

  const normalizedData = normalizeData(
    res.data as GetAllImportedProductsResponse,
  );
  return normalizedData as ImportedCSVTable;
}
const normalizeData = (data: GetAllImportedProductsResponse) => {
  const ret: ImportedCSVTable = {
    fileName: "",
    totalProducts: 0,
    totalVarients: data?.totalVarients ?? 0,
    totalSKU: 0,
    tableHeadings: {},
    tableHeadingIds: [],
    varients: {},
    varientIds: [],
  };
  if (data) {
    data?.tableHeadings.forEach((tableHeading) => {
      ret.tableHeadings[tableHeading.tableHeadingId] = tableHeading;
      ret.tableHeadingIds.push(tableHeading.tableHeadingId);
    });

    data.varients.forEach((varient) => {
      const rowData: Record<
        string,
        {
          type?: null | "varientImage";
          value: null | CSVTableVarientImage | string;
        }
      > = {};

      ret.varients[varient.varientId] = {
        varientId: varient.varientId,
        rowData: varient.rowData,
      };

      ret.varientIds.push(varient.varientId);
    });

    ret.totalProducts = data.totalProducts;
    ret.totalVarients = data.totalVarients;
    ret.totalSKU = data.totalSKU;
    ret.fileName = data.fileName;
  }
  return ret;
};

export default getAllImportedProducts;
