import styles from "./CustomVariable.module.scss";
const CustomVariable = ({goalValue,setGoalValue, goalValueType, setGoalValueType}:any) => {
  return (
    <>
      <div className={`${styles.customMain}`}>
        <div className="mb-3 row">
          <label
            htmlFor="inputPassword"
            className={`col-sm-5 col-form-label ${styles.labelName}`}
          >
            Custom variable named:
          </label>
          <div className="col-sm-7">
            <input data-lpignore="true" 
              type="text"
              value={goalValueType}
              onChange={(e)=>setGoalValueType(e.target.value)}
              className={`form-control ${styles.goalInput}`}
              id="inputPassword"
              placeholder="Type in the variable name"
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputPassword"
            className={`col-sm-5 col-form-label ${styles.labelName}`}
          >
            Has the value:
          </label>
          <div className="col-sm-7">
            <input data-lpignore="true" 
              type="text"
              value={goalValue}
              onChange={(e)=>setGoalValue(e.target.value)}
              className={`form-control ${styles.goalInput}`}
              id="inputPassword"
              placeholder="Type in the variable name"
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default CustomVariable;
