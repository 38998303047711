import { axiosJSON } from "src/globals/axiosEndPoints";
import { IFulfillmentStatus } from "./createFulfillment";

export interface HoldFulfillment {
  error: boolean;
  message: string;
  data: {
    id: string;
    fulfillmentStatus: IFulfillmentStatus
    // status: string;
    // requestStatus: string;
  };
}

export interface HoldFulfillmentParams {
  orderId: number;
  fulfillmentOrderId: string; // shopify fulfillmentOrder id
  reasonId: string | number; //this enum value is given in FufillmentHoldReason endpoint
  notifyMerchant: boolean;
  note: string;
}

export async function holdFulfillment(params: HoldFulfillmentParams) {
  const { data: res }: any = await axiosJSON.post(
    `/api/shopify/order/fulfillment/hold`,
    params
  );
  if (res.error === true) {
    throw new Error(res.message as string);
  }

  return res.data as HoldFulfillment;
}
