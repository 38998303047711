import { AJAXSTATUS } from "src/globals/constants";
import { LiveChatHistoryConversationRes } from "src/services/LiveChat/liveChatHistory/getLiveChatHistoryConversation.service";
import { LiveChatHistoryChatsData } from "src/services/LiveChat/liveChatHistory/liveChatHistoryChats.service";
import { LiveChatHistoryMessagesData } from "src/services/LiveChat/liveChatHistory/liveChatHistoryMessages.service";
import { Customer } from "src/services/LiveChat/messageExchange/getAllCustomers";

export interface LiveChatHistorySlice {
  customer: null | Customer;
  selectedCustomerId: null | string | number;
  selectedChatId: null | string | number;
  selectedInternalChatId: null | string | number;
  fetchHistoryChatsAJAXStatus: AJAXSTATUS;
  historyChatsLimit: number;
  historyChats: LiveChatHistoryChatsData;
  fetchSelectedMessagesAJAXStatus: AJAXSTATUS;
  selectedChatMessagesLimit: number;
  selectedChatMessages: LiveChatHistoryMessagesData;
  historyConversations: LiveChatHistoryConversationRes;
  historyConversationAJAXStatus: AJAXSTATUS;
}

export const initialState: LiveChatHistorySlice = {
  customer: null,
  selectedCustomerId: null,
  selectedChatId: null,
  selectedInternalChatId: null,
  fetchHistoryChatsAJAXStatus: "idle",
  historyChatsLimit: 50,
  historyChats: {
    chatList: {},
    chatListIds: [],
    metaData: {
      count: 0,
      totalCount: 0,
    },
  },
  fetchSelectedMessagesAJAXStatus: "idle",
  selectedChatMessagesLimit: 50,
  selectedChatMessages: {
    customerData: null,
    messageList: {},
    messageListIds: [],
    metaData: {
      count: 0,
      totalCount: 0,
    },
  },
  historyConversationAJAXStatus: `idle`,
  historyConversations: {
    conversationData: {},
    metaData: {
      limitReached: false,
      currentCount: 0,
    },
    conversationDataIds: [],
  },
};
