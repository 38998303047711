import {
  BaseConfigModalTabType,
  SelectedConditionType,
  GetConfigModalParams,
} from "src/features/ReturnAutoWorkFlow/children/ConfigModals/oldReturnModals.types";
import { axiosJSON } from "src/globals/axiosEndPoints";

export interface NonEligibleOrdersTab extends BaseConfigModalTabType {
  notEligibleResponseMessage: string;
  returnWindow: SelectedConditionType;
}

async function getNonEligibleOrdersService(params: GetConfigModalParams) {
  const { data: res } = await axiosJSON.get(
    "/api/setting/automation/refund/step/9/configuration",
    { params },
  );

  if (res.err || res.error) {
    throw new Error(res.msg ?? res.message ?? "Some Error Occurred.");
  }

  return res.data as NonEligibleOrdersTab[];
}

export default getNonEligibleOrdersService;
