import { useEffect, useCallback, useRef } from "react";
import { NavLink, useParams } from "react-router-dom";
import InfiniteScroll from "src/components/InfiniteScroll";
import Loader from "src/components/Loader";
import { resetTicketGlobalSearchData } from "src/store/slices/ticketGlobalSearch/ticketGlobalSearch.slice";
import { fetchAllSearchTickets } from "src/store/slices/ticketGlobalSearch/ticketGlobalSearch.thunks";
import { useAppDispatch, useAppSelector } from "src/store/store";
import SearchedTickets from "./Chidren/SearchedTickets/SearchedTickets";
import styles from "./OuterTickets.module.scss";
import emptyBackPng from "src/assets/images/emptyBack.png";
import { getChannelFAIcon } from "src/utils/utils";

const OuterTickets = () => {
  const dispatch = useAppDispatch();
  const { tableType, viewType }: any = useParams();
  const searchText = useAppSelector((state) =>
    state.ticketGlobalSearch.searchText.trim()
  );
  const { ticketData, ticketList, ticketDataAjaxStatus, ticketListTotal } =
    useAppSelector((state) => state.ticketGlobalSearch);
  const currentState = useRef({
    ticketDataAjaxStatus,
    ticketListTotal,
    searchText,
    tableType,
  });

  useEffect(() => {
    currentState.current = {
      ticketDataAjaxStatus,
      ticketListTotal,
      searchText,
      tableType,
    };
  }, [ticketDataAjaxStatus, ticketListTotal, searchText, tableType]);

  const handleInfiniteScroll = useCallback(() => {
    if (
      currentState.current.ticketListTotal !== null &&
      currentState.current.ticketListTotal > 0
    ) {
      dispatch(fetchAllSearchTickets());
    }
  }, [ticketListTotal]);

  useEffect(() => {
    if (searchText.trim()) {
      dispatch(resetTicketGlobalSearchData());
      dispatch(fetchAllSearchTickets());
    }
  }, [searchText]);

  return (
    <>
      <div id="searcheWrapper">
        <h3 className={`${styles.searchHead}`}>Search Results</h3>
        <InfiniteScroll
          className={`${styles.searchDiv} pb-2`}
          loadMore={handleInfiniteScroll}
          loader={
            ticketDataAjaxStatus === "pending" && ticketList.length === 0 ? (
              <div className={`d-flex flex-column m-auto ${styles.loading}`}>
                <Loader />
                <span className="text-center">Searching...</span>
              </div>
            ) : undefined
          }
          initialDataLoaded={true}
          errorMessage={
            ticketListTotal === 0 || ticketDataAjaxStatus === "rejected" ? (
              <div className={`${styles.nothingFound} m-auto`}>
                <img src={emptyBackPng} className={`d-block m-auto mb-2`} />
                <span>No Ticket Found</span>
              </div>
            ) : undefined
          }
          hasMore={
            ticketDataAjaxStatus === "rejected"
              ? false
              : ticketListTotal === null
              ? true
              : ticketList.length < ticketListTotal
          }
        >
          {ticketList.map((ticketId) => (
            <NavLink
              key={ticketId}
              className={`${styles.hoverAnnimation} my-1`}
              to={`${window.location.pathname.replace(
                `/${viewType}`,
                `/${viewType}/ticket/${ticketId}/`
              )}${window.location.search}`.replace("//", "/")}
              id={`searchedRes__${ticketId}`}
            >
              <SearchedTickets
                ticketNum={ticketId}
                ticketHead={ticketData[ticketId].ticket_subject}
                ticketDesc={ticketData[ticketId].last_message}
                socialMedia={getChannelFAIcon(
                  ticketData[ticketId].ticket_channel
                )}
                name={ticketData[ticketId].ticket_customer_name}
                email={ticketData[ticketId].ticket_to}
                time={ticketData[ticketId].last_message_date}
                brand={ticketData[ticketId].ticket_brand}
              />
            </NavLink>
          ))}
        </InfiniteScroll>
      </div>
    </>
  );
};
export default OuterTickets;
