import { useInfiniteQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { IGetRecentlyUsedTemplate, getRecentlyUsedTemplates } from "src/services/Templates/getRecentlyUsedTemplates";
import { IGetTemplateResponse } from "src/services/Templates/searchTemplates";

interface GetAllTemplatesData {
  templateIds: number[];
  templates: { [key: number]: IGetTemplateResponse };
  metaData: {
    total: number;
    count: number;
  };
}
export const useGetRecentTemplates = (payload: IGetRecentlyUsedTemplate) => {
  const fetchTemplates = async (pageParam: IGetRecentlyUsedTemplate) => {
    const data = await getRecentlyUsedTemplates(pageParam);

    const ret: GetAllTemplatesData = {
      templateIds: [],
      templates: {},
      metaData: {
        total: data.totalCount ?? 0,
        count: data.count ?? 0,
      },
    };

    data.data.forEach((template: IGetTemplateResponse) => {
      const id = parseInt(template.id + "");
      ret.templates[id] = template;
      ret.templateIds.push(id);
    });

    return ret;
  };

  const {
    status,
    error,
    data,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isError,
    fetchStatus,
    refetch,
  } = useInfiniteQuery({
    queryKey: ["InnerTicket/getRecentTemplates", payload],
    queryFn: ({ pageParam = payload }) => fetchTemplates(pageParam),
    getNextPageParam: (
      prevPage: GetAllTemplatesData,
      allPages: GetAllTemplatesData[]
    ) => {
      const data = allPages.flatMap((page) => page.templateIds);
      if (data.length < prevPage.metaData.total) {
        return {
          ...payload,
          start: data.length,
        };
      }
      return null;
    },
    enabled: payload.ticketId ? true : false
  });

  const templates = useMemo(() => {
    let categoriesData: { [key: number | string]: IGetTemplateResponse } = {};
    data?.pages.forEach((page) => {
      categoriesData = { ...categoriesData, ...page.templates };
    });
    return categoriesData;
  }, [data]);

  const templateIds = useMemo(() => {
    const ids = data?.pages.flatMap((page) => page.templateIds) ?? [];
    return Array.from(new Set([...ids]));
  }, [data]);

  return {
    status,
    error,
    templates,
    templateIds,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isError,
    fetchStatus,
    refetch,
  };
};
