import React, {
  SetStateAction,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  changeTheCustomer,
  fetchMessagesUpdates,
  isCurrentTicketMergedWithDifferentSelector,
  sendMessages,
} from "src/store/slices/innerTicket/innerTicket.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import SendMessage from ".";
import { ChangeCustomerProps } from "src/services/SendMessageService";
import { IntegrationFailureMetaData } from "src/types/IntegrationFailure";
import { ReplyOption } from "./children/SendingAsV2";
import { EChannel } from "src/enums/EChannel";

function SendMessageW({
  createdAndSelectedTemplateFromMsg,
  setCreatedAndSelectedTemplateFromMsg,
  ticketId,
}: {
  createdAndSelectedTemplateFromMsg?: any;
  setCreatedAndSelectedTemplateFromMsg?: Function;
  ticketId?: string;
}) {
  const dispatch = useAppDispatch();
  const [sendType, setSendType] = useState(1);
  const [replyOption, setReplyOption] = useState<ReplyOption>(
    ReplyOption.Reply,
  );

  const { brands } = useAppSelector(
    (state) => state.globals.brandSignatureData,
  );

  const {
    activeTicketInfo,
    ticketStatusList,
    activeSnooze,
    sendMessageAjaxStatus: sendStatus,
    integrationFailure: integrationFailureAPIData,
    messageMetaData,
    isSlackIntegrated,
  } = useAppSelector((state) => state.innerTicket);

  const {
    ticket_channel,
    message_thread,
    ticket_from,
    ticket_to,
    ticket_cc,
    failureMetadata: failureFBData,
    forward_message_thread,
  } = activeTicketInfo;

  const isCurrentTicketMergedWithDifferent = useAppSelector(
    isCurrentTicketMergedWithDifferentSelector,
  );

  const [messageChannel, setMessageChannel] = useState<EChannel>(
    typeof ticket_channel === "string"
      ? ((ticket_channel + "".toLowerCase()) as any)
      : EChannel.email,
  );

  const [to, setTo] = useState<string>(ticket_to);

  const currentReplyOptionRef = useRef(replyOption);
  // const ticket_bcc = useAppSelector(
  //   (state) =>
  //     state.innerTicket.activeTicketInfo.ticket_bcc as undefined | null | string
  // );

  // Search Term from the global search
  const textToHighlight = useAppSelector(
    (state) => state.ticketGlobalSearch.searchText,
  );

  const allowedNoteChannels = useMemo(() => {
    const noteOptions = [...(messageMetaData?.noteOptions ?? [])];

    if (!noteOptions.includes(EChannel.slack)) {
      noteOptions.push(EChannel.slack);
    }

    return noteOptions.filter((v) => {
      if (v === EChannel.slack) {
        if (isSlackIntegrated) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    });
  }, [messageMetaData?.noteOptions, isSlackIntegrated]);

  const sendMessagesCB = useCallback((payload: any, callback?: () => void) => {
    dispatch(sendMessages(payload));
    if (callback) {
      callback();
    }
  }, []);

  const fetchMessagesUpdatesCB = useCallback(() => {
    dispatch(fetchMessagesUpdates());
  }, []);

  const changeTheCustomerCB = useCallback(
    (payload: ChangeCustomerProps) => {
      if (
        currentReplyOptionRef.current === ReplyOption.Forward ||
        payload.customerEmail === "" ||
        messageMetaData.presistChangeCustomer === false
      ) {
        if (payload.customerEmail !== undefined) {
          setTo(payload.customerEmail);
          return;
        }
      } else {
        if (payload.customerEmail !== undefined) {
          setTo(payload.customerEmail);
        }
      }
      dispatch(changeTheCustomer(payload));
    },
    [replyOption, messageMetaData?.presistChangeCustomer],
  );

  /**
   * Integration failure data
   */
  const integrationFailure: IntegrationFailureMetaData | null = useMemo(() => {
    // If failure data is from an API error, then return that first.
    if (integrationFailureAPIData) {
      return integrationFailureAPIData;
    }

    // Check if we have facebook messager ticket
    if (
      typeof ticket_channel === "string" &&
      (ticket_channel.toLowerCase() === "messenger" ||
        ticket_channel.toLowerCase() === "instagram")
    ) {
      // Check if we have failure data for facebook
      if (failureFBData) {
        return failureFBData;
      }
    } else if (brands) {
      // Get the selected brand
      const brandData = brands.find((brand) => brand.email === ticket_from);

      // Check if we have brand data
      if (brandData) {
        // Check if it is an email integration error
        if (!brandData.isEmailActive) {
          // Return failure metadata response for email integration

          return {
            type: "email",
            integrationId: brandData.emailIntegrationId,
            brand: {
              id: brandData.id,
              email: brandData.email,
              name: brandData.name,
            },
          } as IntegrationFailureMetaData;

          // Check if we have brand integration error
        } else if (!brandData.isActive || !brandData.isVerified) {
          // Return failure metadata response for brand integration

          return {
            type: "brand",
            integrationId: brandData.emailIntegrationId,
            brand: {
              id: brandData.id,
              email: brandData.email,
              name: brandData.name,
            },
          } as IntegrationFailureMetaData;
        }
      }
    }

    // If none return null
    return null;
  }, [
    ticket_channel,
    failureFBData,
    ticket_from,
    brands,
    integrationFailureAPIData,
  ]);

  return (
    <SendMessage
      createdAndSelectedTemplateFromMsg={createdAndSelectedTemplateFromMsg}
      setCreatedAndSelectedTemplateFromMsg={
        setCreatedAndSelectedTemplateFromMsg
      }
      ticketId={ticketId}
      ticket_channel={ticket_channel}
      message_thread={
        replyOption == ReplyOption.Forward
          ? forward_message_thread
          : message_thread
      }
      isCurrentTicketMergedWithDifferent={isCurrentTicketMergedWithDifferent}
      ticket_from={ticket_from}
      ticketStatusList={ticketStatusList}
      activeSnooze={activeSnooze}
      ticket_to={to}
      sendMessages={sendMessagesCB}
      fetchMessagesUpdates={fetchMessagesUpdatesCB}
      changeTheCustomer={changeTheCustomerCB}
      sendType={sendType}
      setSendType={setSendType}
      textToHighlight={textToHighlight}
      sendStatus={sendStatus}
      ticket_cc={ticket_cc ?? ""}
      autosavePrefix={`HELPDESK_INNER_TICKET_${ticketId}`}
      // ticket_bcc={ticket_bcc ?? ""}
      integrationFailure={integrationFailure}
      replyOption={replyOption}
      setReplyOption={(option) => {
        currentReplyOptionRef.current = option;
        setReplyOption(option);
        if (option === ReplyOption.Forward) {
          changeTheCustomerCB({
            ticketId: ticketId + "",
            customerEmail: ticket_to ?? "",
          });
        } else {
          changeTheCustomerCB({
            ticketId: ticketId + "",
            customerEmail: ticket_to,
          });
        }
      }}
      messageChannel={messageChannel}
      setMessageChannel={setMessageChannel}
      allowedChannels={messageMetaData?.replyOptions ?? [EChannel.email]}
      allowedNoteChannels={allowedNoteChannels}
    />
  );
}

export default SendMessageW;
