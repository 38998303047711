import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";
import { ScheduleData } from "./getAllSchedules";

export interface UpdateSchedulePayload {
  id: number;
  rescheduleTo?: string; //UTC datetime string
  condition?:
    | "noNewOrderPlacedAfterScheduling"
    | "noNewResponseOrMessageReceivedFromTheCustomer"
    | "none";
  message?: string;
  attachmentBatchNumber?: string[];
  removedAttachmentIds?: Array<number|string>;
}

const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/schedule/update`
  : "/api/liveChat/agent/message/schedule/update";

export const updateScheduleLiveChat = async (payload: UpdateSchedulePayload) => {
  const {data: res} = await axiosJSON.post(apiEndPoint, payload);

  if (res.err) {
    throw res.msg;
  }

  return res.data as ScheduleData;
};
