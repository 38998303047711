import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { builderFunction } from "./callRecording.builder";
import { initialState } from "./callRecording.declarations";
import callRecordingReducers from "./callRecording.reducers";

export const callRecordingSlice = createSlice({
  name: "callRecording",
  initialState,
  reducers: callRecordingReducers,
  extraReducers: builderFunction,
});

export const { resetCallRecording, setCatchedPage } =
  callRecordingSlice.actions;

export default callRecordingSlice.reducer;
