import { axiosJSON } from "src/globals/axiosEndPoints";

interface ChannelIntegrated {
  channelName: string;
  isIntegrated: boolean;
}

export interface IsChannelIntegratedParam {
  channels: string[];
  brandId: string;
}

const isIntegratedChannelService = async (
  payload: IsChannelIntegratedParam,
) => {
  const { data: res } = await axiosJSON.post(
    "/api/setting/integration/isIntegratedChannels",
    payload,
  );

  if (res.err || res.error) {
    throw new Error(res.msg ?? res.message ?? "Something unexpected occcured!");
  }

  return res.data as ChannelIntegrated[];
};

export default isIntegratedChannelService;
