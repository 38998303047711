import styles from "./ReasonCard.module.scss";
import caution from "src/assets/images/caution.png";
import { ReasonTypes } from "src/store/slices/initiateReturn/step12Config.slice";
interface Props {
  orderTitle: string;
  orderImg: string;
  orderData1: string;
  orderData2: string;
  addComment: string;
  reasons?: ReasonTypes[] | string[];
}
const ReasonCard = ({
  orderTitle,
  orderImg,
  orderData1,
  orderData2,
  addComment,
  reasons,
}: Props) => {
  console.log(reasons);
  return (
    <>
      <div className="pb-2">
        <div>
          <span className={`d-block px-1 my-1 py-1 ${styles.errormsg}`}>
            *Please select a reason for return/exchange
          </span>
        </div>
        <div>
          <div className={`px-3 py-2 mt-2 ${styles.cardBox}`}>
            <div>
              <div>
                <span className={`pe-1 ${styles.orderName}`}>
                  {orderTitle}{" "}
                </span>
                <span className={`d-inline-block ${styles.moreInfo}`}>
                  {addComment}
                </span>
              </div>

              <div className="d-flex mb-3">
                <img
                  src={orderImg}
                  className={`${styles.orderImg}`}
                />
                <div className="ms-1">
                  <span className={`d-block ${styles.orderData}`}>
                    {orderData1}
                  </span>

                  <span className={`d-block ${styles.orderData}`}>
                    {orderData2}
                  </span>
                </div>
              </div>
            </div>
            <div>
              <p className={`mb-1 ${styles.reason}`}>Reasons:</p>

              {reasons &&
                reasons.map((res, idx) => {
                  return (
                    <div className="form-check">
                      <input
                        className={`form-check-input ${styles.radioCheck}`}
                        type="radio"
                        name="item"
                        id={`Item ${idx}`}
                      />
                      <label
                        className={`form-check-label ${styles.labelItem}`}
                        htmlFor={`Item ${idx}`}
                      >
                        {res instanceof Object ? res.reason.value : res}
                      </label>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ReasonCard;
