import { useCallback, useState } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { useAppSelector } from "src/store/store";
import AllOrders from "../AllOrders/AllOrders";
import styles from "./AllOrdersButton.module.scss";

function AllOrdersButton() {
  const [showModal, setShowModal] = useState(false);
  const shopifyData = useAppSelector(
    (state) => state.shopifySidebar.shopifyData
  );
  const onShow = useCallback(() => {
    setShowModal(true);
  }, []);
  const onHide = useCallback(() => {
    setShowModal(false);
  }, []);

  return (
    <>
      <button
        className={`${styles.allOrdersButton} d-flex my-1 p-2 w-100`}
        onClick={onShow}
      >
        <i className={`fa-solid fa-cube my-auto mx-0`}></i>
        <span className={`${styles.allOrderSpan}`}>All Orders</span>
        <span className={`my-auto mx-0`}>
          {shopifyData?.customer?.totalOrdersCount}
        </span>
      </button>
      <Modal
        backdropClassName={`${styles.modalBack}`}
        show={showModal}
        onHide={onHide}
        dialogClassName={`${styles.modalDialog}`}
        contentClassName={`${styles.modalContent}`}
        enforceFocus={false}
      >
        <AllOrders onHide={onHide} />
      </Modal>
    </>
  );
}

export default AllOrdersButton;
