import { Button, OverlayTrigger, Popover, Row } from "react-bootstrap";
import { UIButtonProps } from "src/store/slices/targetedMessage/targetedMessage.slice";
import CardWidgetButton from "./Children/DelBox/CardWidgetButton";
import styles from "./TargetWidget.module.scss";

export default function Triggers({
  buttons,
  changeButtonProps,
  removeButton,
  addButton,
  selectedBtn,
  setSelectedBtnId,
}: {
  buttons: UIButtonProps[];
  changeButtonProps: any;
  selectedBtn: any;
  removeButton: any;
  addButton: any;
  setSelectedBtnId: any;
}) {
  function onOverlayExited(el: any, btn: any) {
    console.log(`exited trigger ${btn.order}`);
  }

  return (
    <div>
      {buttons.map((btn: UIButtonProps) => {
        let selectedBtnStyleClass: string;
        switch (btn.buttonStyles) {
          case "primary":
            selectedBtnStyleClass = styles.primaryButton;
            break;
          case "danger":
            selectedBtnStyleClass = styles.dangerButton;
            break;
          default:
            selectedBtnStyleClass = "";
            break;
        }
        return (
          <Row key={btn.id} className={`${styles.row}`}>
            <OverlayTrigger
              onExited={(el) => onOverlayExited(el, btn)}
              trigger="click"
              placement="right"
              rootClose={true}
              overlay={
                <Popover id={btn.id} className={`${styles.buttonPopover}`}>
                  {/* <Popover.Header as="h3">
                    Showing {btn.id} Popover
                  </Popover.Header> */}
                  <Popover.Body className={`${styles.popoverBody}`}>
                    <CardWidgetButton
                      id={btn.id}
                      selectedBtn={selectedBtn}
                      changeButtonProps={changeButtonProps}
                      btn={btn}
                    />
                  </Popover.Body>
                </Popover>
              }
            >
              <button
                onClick={(ev) => setSelectedBtnId(btn.id)}
                className={`${styles.checkBtn} ${selectedBtnStyleClass} mt-1 mb-1 `}
              >
                <div
                  className={`btn-group  ${styles.delBox}`}
                  onClick={(ev) => removeButton(ev, btn.id)}
                >
                  <Button className={`${styles.trashBtn} `}>
                    <div className={`${styles.trash} mb-1`}>
                      <i className="fa-solid fa-trash "></i>
                    </div>
                  </Button>{" "}
                </div>
                {btn.text}
              </button>
            </OverlayTrigger>
          </Row>
        );
      })}
      <button className={`${styles.addBtn}`} onClick={(ev) => addButton(ev)}>
        <span>+ Add Button</span>
      </button>
    </div>
  );
}
