import { PayloadAction } from "@reduxjs/toolkit";
import objectHash from "object-hash";
import { RefundScenarioState } from "src/routes/Setting/children/MainSetting/children/SetRulesNew/childerns/childern/AddAutomations/AddAutomationStep4/Children/AntiRefundModal/Children/RefundBox/Scenario/ScenarioReducer";
import { ConditionOptions } from "src/services/UiAutomation/ReturnExchange/Configuration/Step9/step9GetOptionsConditions";
import {
  RecursivePartial,
  addConditionHandler,
  createNewObjectHash,
  deleteConditionHelper,
  deleteTabHelper,
  getConditionValueOrDefault,
  updateConditionHelper,
  validateChangeHandler,
  validateConditionsHelper,
} from "src/store/slices/initiateReturn/Helpers/returnExchange.helpers";
import { v4 as uuid } from "uuid";
import { RefundScenario } from "./antiRefundConfig.slice";
import {
  IAntiRefundOrderType,
  IAntiRefundOrderTypesConfig,
  IConditionTypes,
  initialState,
} from "./antiRefundOrderTypesConfig.slice";
import { ARSResponse } from "src/routes/Setting/children/MainSetting/children/SetRulesNew/childerns/childern/AddAutomations/WidgetPreviewSection/hooks/useGetAutomationData";

const setSelectedOrderTypeId = (
  state: IAntiRefundOrderTypesConfig,
  { payload }: { payload: { orderTypeId: string } },
) => {
  state.antiRefundOrderTypesData.selectedOrderTypeId = payload.orderTypeId;
};

// This dispatch function is responsible to update state when clicked on Apply to all orders toglle button
const toggleAllOrder = (
  state: IAntiRefundOrderTypesConfig,
  { payload }: { payload: { orderTypeId: string; value: boolean } },
) => {
  const selectedIdx =
    state.antiRefundOrderTypesData.antiRefundOrderTypes.findIndex(
      (order) => order.orderTypeId === payload.orderTypeId,
    );
  const selectedOrder =
    state.antiRefundOrderTypesData.antiRefundOrderTypes[selectedIdx];

  selectedOrder.applyToAllOrders = payload.value;
  if (payload.value === true) {
    // If toggle is enabled, add a default condition to conditions
    let condition: any = state.antiRefundOrderTypesData.defaultOptions[0];
    selectedOrder.conditions = [
      {
        id: uuid(),
        operator:
          state.antiRefundOrderTypesData.operatorsNames[condition.name][0],
        ruleType: "AND",
        values: getConditionValueOrDefault(
          state.antiRefundOrderTypesData.variableNameToValueType[
            condition.name
          ],
        ),
        variableName: condition.name,
      },
    ];
  } else {
    // If toggle is disabled, add an order condition
    let condition: any = state.antiRefundOrderTypesData.conditionOptions[0];
    selectedOrder.conditions = [
      {
        id: uuid(),
        operator:
          state.antiRefundOrderTypesData.operatorsNames[condition.name][0],
        ruleType: "AND",
        values: getConditionValueOrDefault(
          state.antiRefundOrderTypesData.variableNameToValueType[
            condition.name
          ],
        ),
        variableName: condition.name,
      },
    ];
    selectedOrder.isAddConditionEnabled = false;
    if (selectedOrder.error !== undefined) {
      selectedOrder.error = null;
      // selectedOrder.errorCount = selectedOrder.errorCount - 1;
    }
  }
};

const updateOrderType = (
  state: IAntiRefundOrderTypesConfig,

  {
    payload,
  }: { payload: { orderTypeDetail: RecursivePartial<IAntiRefundOrderType> } },
) => {
  const { orderTypeId, orderTypeName } = payload.orderTypeDetail;

  const selectedIdx =
    state.antiRefundOrderTypesData.antiRefundOrderTypes.findIndex(
      (order) => order.orderTypeId === orderTypeId,
    );
  const selectedOrder =
    state.antiRefundOrderTypesData.antiRefundOrderTypes[selectedIdx];

  if (orderTypeName) selectedOrder.orderTypeName = orderTypeName;
};

const pushCurrentHashForSelectedTab = (
  state: IAntiRefundOrderTypesConfig,
  { payload }: { payload: { orderTypeId: string | null } },
) => {
  const selectedOrderIdx =
    state.antiRefundOrderTypesData.antiRefundOrderTypes.findIndex(
      (orderType) => orderType.orderTypeId === payload.orderTypeId,
    );

  state.antiRefundOrderTypesData.selectedTabCurrentHash = objectHash(
    state.antiRefundOrderTypesData.antiRefundOrderTypes[selectedOrderIdx],
  );
};

const validateChanges = (state: IAntiRefundOrderTypesConfig) => {
  const { initialHash } = state;
  const { isChanged, newHash } = validateChangeHandler({
    stateObject: state.antiRefundOrderTypesData.antiRefundOrderTypes,
    excludeKeys: state.excludeKeysForHash,
    initialHash,
  });
  if (newHash) {
    state.isChanged = isChanged;
    state.currentHash = newHash;
  }
};

// Add Order Type
const addOrderType = (state: IAntiRefundOrderTypesConfig) => {
  const newOrderTypeId = uuid();

  state.antiRefundOrderTypesData.selectedOrderTypeId = newOrderTypeId;
  let condition: any = state.antiRefundOrderTypesData.defaultOptions[0];
  state.antiRefundOrderTypesData.antiRefundOrderTypes.push({
    orderTypeId: newOrderTypeId,
    orderTypeName: `Order Type ${
      state.antiRefundOrderTypesData.antiRefundOrderTypes.length + 1
    }`,
    conditions: [
      {
        id: uuid(),
        operator:
          state.antiRefundOrderTypesData.operatorsNames[condition.name][0],
        ruleType: "AND",
        values: getConditionValueOrDefault(
          state.antiRefundOrderTypesData.variableNameToValueType[
            condition.name
          ],
        ),
        variableName: condition.name,
      } as IConditionTypes,
    ],
    refundRequests: {
      //assign the default refund requests
      reasons: state.antiRefundOrderTypesData.refundRequestReasons,
    },
    errorCount: 0,
    isValid: false,
    error: null,
    isAddConditionEnabled: true,
    applyToAllOrders: true,
  });
};

//Validate OrderType
// This is empty action commented it
// const validateOrderType = (
//   state: IAntiRefundOrderTypesConfig,
//   { payload }: { payload: { orderTypeId: string | number | null } },
// ) => {
//   // Empty function
// };

const validateCondition = (
  state: IAntiRefundOrderTypesConfig,
  { payload }: { payload: { orderTypeId: string | null } },
) => {
  const selectedOrderIdx =
    state.antiRefundOrderTypesData.antiRefundOrderTypes.findIndex(
      (orderType) => orderType.orderTypeId === payload.orderTypeId,
    );

  const selectedOrder =
    state.antiRefundOrderTypesData.antiRefundOrderTypes[selectedOrderIdx];
  // Validating conditions using helper
  const { updatedConditions, isAddOrderConditionEnabled } =
    validateConditionsHelper({
      selectedTab: selectedOrder,
      step: "step10",
      conditionOptions: state.antiRefundOrderTypesData.conditionOptions,
    });

  state.antiRefundOrderTypesData.antiRefundOrderTypes[
    selectedOrderIdx
  ].conditions = updatedConditions;
  if (isAddOrderConditionEnabled != null) {
    state.antiRefundOrderTypesData.antiRefundOrderTypes[
      selectedOrderIdx
    ].isAddConditionEnabled = isAddOrderConditionEnabled;
  }
  const conditions =
    state.antiRefundOrderTypesData.antiRefundOrderTypes[selectedOrderIdx]
      .conditions;

  const errors = conditions
    .flatMap((condition) => {
      return Object.entries(condition.values).flatMap(
        ([key, value]: [any, any]) => {
          if (value.error === undefined) return null;
          return value.error;
        },
      );
    })
    .concat([selectedOrder.error]);

  selectedOrder.errorCount = errors.reduce((p, c) => {
    if (c !== null) {
      return p + 1;
    }
    return p;
  }, 0);

  if (selectedOrder.errorCount > 0) {
    selectedOrder.isValid = false;
  } else {
    selectedOrder.isValid = true;
  }
};

const addCondition = (
  state: IAntiRefundOrderTypesConfig,
  { payload }: { payload: { orderTypeId: string | null; condition: string } },
) => {
  let selectedOrder = state.antiRefundOrderTypesData.antiRefundOrderTypes.find(
    (orderType) => orderType.orderTypeId === payload.orderTypeId,
  );

  if (selectedOrder !== undefined) {
    const selectedTab = addConditionHandler({
      selectedTab: selectedOrder,
      conditionOptions: state.antiRefundOrderTypesData.conditionOptions,
      payloadCondition: payload.condition,
      isItemCondition: false,
    });

    selectedOrder = selectedTab;

    if (selectedOrder?.error !== undefined) {
      selectedOrder.error = null;
      // selectedOrder.errorCount = selectedOrder.errorCount - 1;
    }
  }
};

const deleteCondition = (
  state: IAntiRefundOrderTypesConfig,
  { payload }: { payload: { orderTypeId: string; conditionId: string } },
) => {
  const { orderTypeId, conditionId } = payload;

  const selectedOrderIdx =
    state.antiRefundOrderTypesData.antiRefundOrderTypes.findIndex(
      (orderType) => orderType.orderTypeId === orderTypeId,
    );

  if (selectedOrderIdx !== -1) {
    const selectedOrderTypes =
      state.antiRefundOrderTypesData.antiRefundOrderTypes[selectedOrderIdx];
    const { updatedConditions, isAddOrderConditionEnabled } =
      deleteConditionHelper({
        conditionId: conditionId,
        conditions: selectedOrderTypes.conditions,
        conditionType: "order",
      });

    state.antiRefundOrderTypesData.antiRefundOrderTypes[
      selectedOrderIdx
    ].conditions = updatedConditions;
    if (isAddOrderConditionEnabled !== null) {
      state.antiRefundOrderTypesData.antiRefundOrderTypes[
        selectedOrderIdx
      ].isAddConditionEnabled = isAddOrderConditionEnabled;
    }

    if (selectedOrderTypes.conditions.length === 0) {
      selectedOrderTypes.isAddConditionEnabled = true;
    }
  }
};

// Update Conditon
const updateCondition = (
  state: IAntiRefundOrderTypesConfig,
  {
    payload,
  }: {
    payload: {
      orderTypeId: string;
      conditionToUpdate: RecursivePartial<IConditionTypes>;
    };
  },
) => {
  const { orderTypeId, conditionToUpdate } = payload;

  const selectedOrderIdx =
    state.antiRefundOrderTypesData.antiRefundOrderTypes.findIndex(
      (orderType) => orderType.orderTypeId === orderTypeId,
    );

  if (selectedOrderIdx !== -1 && conditionToUpdate) {
    const { updatedConditions, isAddOrderConditionEnabled } =
      updateConditionHelper({
        conditions:
          state.antiRefundOrderTypesData.antiRefundOrderTypes[selectedOrderIdx]
            .conditions,
        conditionToUpdate,
      });

    state.antiRefundOrderTypesData.antiRefundOrderTypes[
      selectedOrderIdx
    ].conditions = updatedConditions;

    if (isAddOrderConditionEnabled !== null) {
      state.antiRefundOrderTypesData.antiRefundOrderTypes[
        selectedOrderIdx
      ].isAddConditionEnabled = isAddOrderConditionEnabled;
    }
  }
};

//Update the enable value for a specific reason in Q12 modal
const toggleAntiRefundMethodStep13 = (
  state: IAntiRefundOrderTypesConfig,
  action: PayloadAction<{
    orderTypeId: string | number;
    id: number;
    enabled: boolean;
    scenarioId: string | number;
  }>,
) => {
  const { orderTypeId, id, enabled, scenarioId } = action.payload;
  const orderTypeIndex =
    state.antiRefundOrderTypesData.antiRefundOrderTypes.findIndex(
      (orderType) => orderType.orderTypeId === orderTypeId,
    );

  const reasonIdx = state.antiRefundOrderTypesData.antiRefundOrderTypes[
    orderTypeIndex
  ].refundRequests.reasons.findIndex((reason) => reason.id === id);

  const reason =
    state.antiRefundOrderTypesData.antiRefundOrderTypes[orderTypeIndex]
      .refundRequests.reasons[reasonIdx];
  if (reason) {
    const scenarioIdx = reason.scenarios.findIndex(
      (scenario) => scenario.id === scenarioId,
    );
    if (scenarioIdx > -1) {
      reason.scenarios[scenarioIdx].enabled = enabled;
    }
  }
};

const updateScenarioItems = (
  state: IAntiRefundOrderTypesConfig,
  action: PayloadAction<{
    orderTypeId: string;
    reasonId: string | number;
    scenarioId: string;
    items: {
      id?: number;
      value: string;
      label: string;
      price?: {
        //Changing price format as per xd
        amount: string;
        currencyCode: string;
      };
      imgSrc?: string;
      type: "tag" | "item";
    }[];
  }>,
) => {
  const { orderTypeId, reasonId, scenarioId, items } = action.payload;
  const orderTypeIndex =
    state.antiRefundOrderTypesData.antiRefundOrderTypes.findIndex(
      (orderType) => orderType.orderTypeId === orderTypeId,
    );
  const reasonIdx = state.antiRefundOrderTypesData.antiRefundOrderTypes[
    orderTypeIndex
  ].refundRequests.reasons.findIndex((reason) => reason.id === reasonId);
  const scenarioIdx = state.antiRefundOrderTypesData.antiRefundOrderTypes[
    orderTypeIndex
  ].refundRequests.reasons[reasonIdx].scenarios.findIndex(
    (scenario) => scenario.id === scenarioId,
  );
  state.antiRefundOrderTypesData.antiRefundOrderTypes[
    orderTypeIndex
  ].refundRequests.reasons[reasonIdx].scenarios[scenarioIdx].items = items.map(
    (item) => ({
      id: item.id,
      imgSrc: item.imgSrc,
      label: item.label,
      type: "item",
      value: item.value,
      price: {
        amount: item.price?.amount,
        currencyCode: item.price?.currencyCode,
      },
    }),
  );
};

const updateScenarioTags = (
  state: IAntiRefundOrderTypesConfig,
  action: PayloadAction<{
    orderTypeId: string;
    reasonId: string | number;
    scenarioId: string;
    tags: string[];
  }>,
) => {
  const { orderTypeId, reasonId, scenarioId, tags } = action.payload;
  const orderTypeIndex =
    state.antiRefundOrderTypesData.antiRefundOrderTypes.findIndex(
      (orderType) => orderType.orderTypeId === orderTypeId,
    );
  const reasonIdx = state.antiRefundOrderTypesData.antiRefundOrderTypes[
    orderTypeIndex
  ].refundRequests.reasons.findIndex((reason) => reason.id === reasonId);
  const scenarioIdx = state.antiRefundOrderTypesData.antiRefundOrderTypes[
    orderTypeIndex
  ].refundRequests.reasons[reasonIdx].scenarios.findIndex(
    (scenario) => scenario.id === scenarioId,
  );
  state.antiRefundOrderTypesData.antiRefundOrderTypes[
    orderTypeIndex
  ].refundRequests.reasons[reasonIdx].scenarios[scenarioIdx].tags = tags;
};

const updateItemsAndTagsAllSelected = (
  state: IAntiRefundOrderTypesConfig,
  action: PayloadAction<{
    orderTypeId: string;
    reasonId: string | number;
    scenarioId: string;
    allSelected: boolean;
  }>,
) => {
  const { orderTypeId, reasonId, scenarioId, allSelected } = action.payload;
  const orderTypeIndex =
    state.antiRefundOrderTypesData.antiRefundOrderTypes.findIndex(
      (orderType) => orderType.orderTypeId === orderTypeId,
    );
  const reasonIdx = state.antiRefundOrderTypesData.antiRefundOrderTypes[
    orderTypeIndex
  ].refundRequests.reasons.findIndex((reason) => reason.id === reasonId);
  const scenarioIdx = state.antiRefundOrderTypesData.antiRefundOrderTypes[
    orderTypeIndex
  ].refundRequests.reasons[reasonIdx].scenarios.findIndex(
    (scenario) => scenario.id === scenarioId,
  );
  state.antiRefundOrderTypesData.antiRefundOrderTypes[
    orderTypeIndex
  ].refundRequests.reasons[reasonIdx].scenarios[
    scenarioIdx
  ].itemsAndTagAllSelected = allSelected;
};

const updateFollowUpQuestions = (
  state: IAntiRefundOrderTypesConfig,
  action: PayloadAction<{
    orderTypeId: string;
    reasonId: string | number;
    scenarioId: string;
    elementId: string;
    elementTypeId: string;
  }>,
) => {
  const { orderTypeId, reasonId, scenarioId, elementId, elementTypeId } =
    action.payload;
  const orderTypeIndex =
    state.antiRefundOrderTypesData.antiRefundOrderTypes.findIndex(
      (orderType) => orderType.orderTypeId === orderTypeId,
    );
  const reasonIdx = state.antiRefundOrderTypesData.antiRefundOrderTypes[
    orderTypeIndex
  ].refundRequests.reasons.findIndex((reason) => reason.id === reasonId);
  const scenarioIdx = state.antiRefundOrderTypesData.antiRefundOrderTypes[
    orderTypeIndex
  ].refundRequests.reasons[reasonIdx].scenarios.findIndex(
    (scenario) => scenario.id === scenarioId,
  );
  const element =
    state.antiRefundOrderTypesData.refundRequestFollowUpQuestions.filter(
      (element) =>
        element.elementId === elementId &&
        element.elementTypeId === elementTypeId,
    )[0];

  const elementIdx = state.antiRefundOrderTypesData.antiRefundOrderTypes[
    orderTypeIndex
  ].refundRequests.reasons[reasonIdx].scenarios[
    scenarioIdx
  ].followUpQuestions.findIndex(
    (element) =>
      element.elementId === elementId &&
      element.elementTypeId === elementTypeId,
  );

  if (elementIdx === -1) {
    state.antiRefundOrderTypesData.antiRefundOrderTypes[
      orderTypeIndex
    ].refundRequests.reasons[reasonIdx].scenarios[
      scenarioIdx
    ].followUpQuestions.push({
      elementId: elementId,
      elementTypeId: elementTypeId,
      elementValue: element.elementValue,
      additionalOptions: element.additionalOptions,
      selectedValue: element.additionalOptions[0].id,
      // selectedId: element.additionalOptions[0].id,
    });
  }
};

const removeFollowUpQuestions = (
  state: IAntiRefundOrderTypesConfig,
  action: PayloadAction<{
    orderTypeId: string;
    reasonId: string | number;
    scenarioId: string;
    elementId: string;
    elementTypeId: string;
  }>,
) => {
  const { orderTypeId, reasonId, scenarioId, elementId, elementTypeId } =
    action.payload;
  const orderTypeIndex =
    state.antiRefundOrderTypesData.antiRefundOrderTypes.findIndex(
      (orderType) => orderType.orderTypeId === orderTypeId,
    );
  const reasonIdx = state.antiRefundOrderTypesData.antiRefundOrderTypes[
    orderTypeIndex
  ].refundRequests.reasons.findIndex((reason) => reason.id === reasonId);
  const scenarioIdx = state.antiRefundOrderTypesData.antiRefundOrderTypes[
    orderTypeIndex
  ].refundRequests.reasons[reasonIdx].scenarios.findIndex(
    (scenario) => scenario.id === scenarioId,
  );
  const elementIdx = state.antiRefundOrderTypesData.antiRefundOrderTypes[
    orderTypeIndex
  ].refundRequests.reasons[reasonIdx].scenarios[
    scenarioIdx
  ].followUpQuestions.findIndex(
    (element) =>
      element.elementId === elementId &&
      element.elementTypeId === elementTypeId,
  );

  if (elementIdx > -1) {
    state.antiRefundOrderTypesData.antiRefundOrderTypes[
      orderTypeIndex
    ].refundRequests.reasons[reasonIdx].scenarios[
      scenarioIdx
    ].followUpQuestions.splice(elementIdx, 1);
  }
};

const selectAllFollowUpQuestions = (
  state: IAntiRefundOrderTypesConfig,
  action: PayloadAction<{
    orderTypeId: string;
    reasonId: string | number;
    scenarioId: string;
  }>,
) => {
  const { orderTypeId, reasonId, scenarioId } = action.payload;
  const orderTypeIndex =
    state.antiRefundOrderTypesData.antiRefundOrderTypes.findIndex(
      (orderType) => orderType.orderTypeId === orderTypeId,
    );
  const reasonIdx = state.antiRefundOrderTypesData.antiRefundOrderTypes[
    orderTypeIndex
  ].refundRequests.reasons.findIndex((reason) => reason.id === reasonId);
  const scenarioIdx = state.antiRefundOrderTypesData.antiRefundOrderTypes[
    orderTypeIndex
  ].refundRequests.reasons[reasonIdx].scenarios.findIndex(
    (scenario) => scenario.id === scenarioId,
  );

  state.antiRefundOrderTypesData.antiRefundOrderTypes[
    orderTypeIndex
  ].refundRequests.reasons[reasonIdx].scenarios[scenarioIdx].followUpQuestions =
    state.antiRefundOrderTypesData.refundRequestFollowUpQuestions.map(
      (element) => {
        return {
          elementId: element.elementId,
          elementTypeId: element.elementTypeId,
          elementValue: element.elementValue,
          additionalOptions: element.additionalOptions,
          selectedValue: element.additionalOptions[0].id,
          // selectedId: element.additionalOptions[0].id,
        };
      },
    );
};

const deselectAllFollowUpQuestions = (
  state: IAntiRefundOrderTypesConfig,
  action: PayloadAction<{
    orderTypeId: string;
    reasonId: string | number;
    scenarioId: string;
  }>,
) => {
  const { orderTypeId, reasonId, scenarioId } = action.payload;
  const orderTypeIndex =
    state.antiRefundOrderTypesData.antiRefundOrderTypes.findIndex(
      (orderType) => orderType.orderTypeId === orderTypeId,
    );
  const reasonIdx = state.antiRefundOrderTypesData.antiRefundOrderTypes[
    orderTypeIndex
  ].refundRequests.reasons.findIndex((reason) => reason.id === reasonId);
  const scenarioIdx = state.antiRefundOrderTypesData.antiRefundOrderTypes[
    orderTypeIndex
  ].refundRequests.reasons[reasonIdx].scenarios.findIndex(
    (scenario) => scenario.id === scenarioId,
  );

  state.antiRefundOrderTypesData.antiRefundOrderTypes[
    orderTypeIndex
  ].refundRequests.reasons[reasonIdx].scenarios[scenarioIdx].followUpQuestions =
    [];
};

const updateFollowUpQuestionSelectedValue = (
  state: IAntiRefundOrderTypesConfig,
  action: PayloadAction<{
    orderTypeId: string;
    reasonId: string | number;
    scenarioId: string;
    elementId: string;
    elementTypeId: string;
    selectedValue: string;
  }>,
) => {
  const {
    orderTypeId,
    reasonId,
    scenarioId,
    elementId,
    elementTypeId,
    selectedValue,
  } = action.payload;
  const orderTypeIndex =
    state.antiRefundOrderTypesData.antiRefundOrderTypes.findIndex(
      (orderType) => orderType.orderTypeId === orderTypeId,
    );
  const reasonIdx = state.antiRefundOrderTypesData.antiRefundOrderTypes[
    orderTypeIndex
  ].refundRequests.reasons.findIndex((reason) => reason.id === reasonId);
  const scenarioIdx = state.antiRefundOrderTypesData.antiRefundOrderTypes[
    orderTypeIndex
  ].refundRequests.reasons[reasonIdx].scenarios.findIndex(
    (scenario) => scenario.id === scenarioId,
  );
  const elementIdx = state.antiRefundOrderTypesData.antiRefundOrderTypes[
    orderTypeIndex
  ].refundRequests.reasons[reasonIdx].scenarios[
    scenarioIdx
  ].followUpQuestions.findIndex(
    (element) =>
      element.elementId === elementId &&
      element.elementTypeId === elementTypeId,
  );

  if (elementIdx !== -1) {
    if (selectedValue === "*") {
      state.antiRefundOrderTypesData.antiRefundOrderTypes[
        orderTypeIndex
      ].refundRequests.reasons[reasonIdx].scenarios[
        scenarioIdx
      ].followUpQuestions[elementIdx].selectedValue =
        state.antiRefundOrderTypesData.antiRefundOrderTypes[
          orderTypeIndex
        ].refundRequests.reasons[reasonIdx].scenarios[
          scenarioIdx
        ].followUpQuestions[elementIdx].additionalOptions.map(
          (option) => option.value,
        );
    } else {
      state.antiRefundOrderTypesData.antiRefundOrderTypes[
        orderTypeIndex
      ].refundRequests.reasons[reasonIdx].scenarios[
        scenarioIdx
      ].followUpQuestions[elementIdx].selectedValue = selectedValue;
    }
  }
};
const updateAdditionalInfo = (
  state: IAntiRefundOrderTypesConfig,
  action: PayloadAction<{
    orderTypeId: string;
    reasonId: string | number;
    scenarioId: string;
    additionalInfo: {
      enabled?: boolean;
      message?: string;
      extendReturnPeriod?: {
        enabled: boolean;
        days: number;
      };
    };
  }>,
) => {
  const { orderTypeId, reasonId, scenarioId, additionalInfo } = action.payload;
  const orderTypeIndex =
    state.antiRefundOrderTypesData.antiRefundOrderTypes.findIndex(
      (orderType) => orderType.orderTypeId === orderTypeId,
    );
  const reasonIdx = state.antiRefundOrderTypesData.antiRefundOrderTypes[
    orderTypeIndex
  ].refundRequests.reasons.findIndex((reason) => reason.id === reasonId);
  const scenarioIdx = state.antiRefundOrderTypesData.antiRefundOrderTypes[
    orderTypeIndex
  ].refundRequests.reasons[reasonIdx].scenarios.findIndex(
    (scenario) => scenario.id === scenarioId,
  );

  const refundScenario =
    state.antiRefundOrderTypesData.antiRefundOrderTypes[orderTypeIndex]
      .refundRequests.reasons[reasonIdx].scenarios[scenarioIdx];

  if (refundScenario) {
    if (refundScenario.additionalInformation === undefined) {
      refundScenario.additionalInformation = {
        enabled: false,
        message: "",
        extendReturnPeriod: {
          enabled: false,
          days: 0,
        },
      };
    }

    if (refundScenario && refundScenario.additionalInformation) {
      if (additionalInfo.enabled !== undefined) {
        refundScenario.additionalInformation.enabled = additionalInfo.enabled;
      }
      if (additionalInfo.message !== undefined) {
        refundScenario.additionalInformation.message = additionalInfo.message;
      }
      if (additionalInfo.extendReturnPeriod !== undefined) {
        refundScenario.additionalInformation.extendReturnPeriod.enabled =
          additionalInfo.extendReturnPeriod.enabled;
        refundScenario.additionalInformation.extendReturnPeriod.days =
          additionalInfo.extendReturnPeriod.days;
      }
    }
  }
};

const updateGiveOffer = (
  state: IAntiRefundOrderTypesConfig,
  action: PayloadAction<{
    orderTypeId: string;
    reasonId: string | number;
    scenarioId: string;
    giveOffer: {
      offerType?:
        | "partial_refund_of"
        | "discount_of"
        | "offer_clearance_product_for_free"
        | "other"
        | null;
      offerSelectedItems?: string[];
      offerSelectedTags?: string[];
      enabled?: boolean;
      message?: string;
      value?: string;
      type?: string;
    };
  }>,
) => {
  const { orderTypeId, reasonId, scenarioId, giveOffer } = action.payload;
  const orderTypeIndex =
    state.antiRefundOrderTypesData.antiRefundOrderTypes.findIndex(
      (orderType) => orderType.orderTypeId === orderTypeId,
    );

  const reasonIdx = state.antiRefundOrderTypesData.antiRefundOrderTypes[
    orderTypeIndex
  ].refundRequests.reasons.findIndex((reason) => reason.id === reasonId);

  const scenarioIdx = state.antiRefundOrderTypesData.antiRefundOrderTypes[
    orderTypeIndex
  ].refundRequests.reasons[reasonIdx].scenarios.findIndex(
    (scenario) => scenario.id === scenarioId,
  );

  const refundScenario =
    state.antiRefundOrderTypesData.antiRefundOrderTypes[orderTypeIndex]
      .refundRequests.reasons[reasonIdx].scenarios[scenarioIdx];

  if (refundScenario) {
    if (refundScenario.giveOffer === undefined) {
      refundScenario.giveOffer = {
        enabled: false,
        message: "",
        offerSelectedItems: [],
        offerSelectedTags: [],
        offerType: null,
        offerUnit: "percentage",
      };
    }

    if (refundScenario && refundScenario.giveOffer) {
      if (giveOffer?.enabled !== undefined) {
        refundScenario.giveOffer.enabled = giveOffer.enabled;
      }
      if (giveOffer?.message !== undefined) {
        refundScenario.giveOffer.message = giveOffer.message;
      }
      if (giveOffer?.offerSelectedItems !== undefined) {
        refundScenario.giveOffer.offerSelectedItems =
          giveOffer.offerSelectedItems;
      }
      if (giveOffer?.offerSelectedTags !== undefined) {
        refundScenario.giveOffer.offerSelectedTags =
          giveOffer.offerSelectedTags;
      }
      if (giveOffer?.offerType !== undefined) {
        refundScenario.giveOffer.offerType = giveOffer.offerType;
      }
    }
  }
};

const updateOfferExchange = (
  state: IAntiRefundOrderTypesConfig,
  action: PayloadAction<{
    orderTypeId: string;
    reasonId: string | number;
    scenarioId: string;
    offerExchange: {
      enabled?: boolean;
      message?: string;
      exchangeWithSameItems?: boolean;
      exchangeWithOtherItems?: boolean;
    };
  }>,
) => {
  const { orderTypeId, reasonId, scenarioId, offerExchange } = action.payload;

  const orderTypeIndex =
    state.antiRefundOrderTypesData.antiRefundOrderTypes.findIndex(
      (orderType) => orderType.orderTypeId === orderTypeId,
    );

  const reasonIdx = state.antiRefundOrderTypesData.antiRefundOrderTypes[
    orderTypeIndex
  ].refundRequests.reasons.findIndex((reason) => reason.id === reasonId);

  const scenarioIdx = state.antiRefundOrderTypesData.antiRefundOrderTypes[
    orderTypeIndex
  ].refundRequests.reasons[reasonIdx].scenarios.findIndex(
    (scenario) => scenario.id === scenarioId,
  );

  const refundScenario =
    state.antiRefundOrderTypesData.antiRefundOrderTypes[orderTypeIndex]
      .refundRequests.reasons[reasonIdx].scenarios[scenarioIdx];

  if (refundScenario) {
    if (refundScenario.offerExchange === undefined) {
      refundScenario.offerExchange = {
        enabled: false,
        message: "",
        exchangeWithSameItems: false,
        exchangeWithOtherItems: false,
      };
    }

    if (refundScenario && refundScenario.offerExchange) {
      if (offerExchange.enabled !== undefined) {
        refundScenario.offerExchange.enabled = offerExchange.enabled;
      }
      if (offerExchange.message !== undefined) {
        refundScenario.offerExchange.message = offerExchange.message;
      }
      if (offerExchange.exchangeWithSameItems !== undefined) {
        refundScenario.offerExchange.exchangeWithSameItems =
          offerExchange.exchangeWithSameItems;
      }
      if (offerExchange.exchangeWithOtherItems !== undefined) {
        refundScenario.offerExchange.exchangeWithOtherItems =
          offerExchange.exchangeWithOtherItems;
      }
    }
  }
};

const updateRefundReasons = (
  state: IAntiRefundOrderTypesConfig,
  action: PayloadAction<{
    orderTypeId: string;
    id: number;
    scenarios: RefundScenario[];
  }>,
) => {
  const { orderTypeId, id, scenarios } = action.payload;
  const orderTypeIndex =
    state.antiRefundOrderTypesData.antiRefundOrderTypes.findIndex(
      (orderType) => orderType.orderTypeId === orderTypeId,
    );
  const reasonIdx = state.antiRefundOrderTypesData.antiRefundOrderTypes[
    orderTypeIndex
  ].refundRequests.reasons.findIndex((reason) => reason.id === id);

  const reason =
    state.antiRefundOrderTypesData.antiRefundOrderTypes[orderTypeIndex]
      .refundRequests.reasons[reasonIdx];
  if (reason) {
    if (scenarios) {
      reason.scenarios = scenarios;
    }
  }
};

const validateStep13 = (state: IAntiRefundOrderTypesConfig) => {
  // let totalErrorCount = 0;
  state.antiRefundOrderTypesData.antiRefundOrderTypes.forEach(
    (selectedOrder) => {
      const conditions = selectedOrder.conditions;
      if (conditions.length <= 0) {
        selectedOrder.error = "Atleast add one condition required";
        // When applyToAllOrders is true remove validation error if any
        if (selectedOrder.applyToAllOrders === true) {
          selectedOrder.error = null;
        }
      }

      conditions.forEach((condition, idx) => {
        Object.entries<any>(condition.values).forEach(([key, value]) => {
          switch (key) {
            case "days": {
              if (value.currentValue === 0) {
                value.error = "Please enter the value for the number of days.";
              }
              // When applyToAllOrders is true remove validation error if any
              if (selectedOrder.applyToAllOrders === true) {
                value.error = undefined;
              }
              break;
            }
            case "tags": {
              if (value.currentValue.length === 0) {
                value.error = "The order tag field cannot be empty.";
              }
              // When applyToAllOrders is true remove validation error if any
              if (selectedOrder.applyToAllOrders === true) {
                value.error = undefined;
              }
              break;
            }

            case "codes": {
              if (value.currentValue.length === 0) {
                value.error = "Please fill out this field";
              }
              // When applyToAllOrders is true remove validation error if any
              if (selectedOrder.applyToAllOrders === true) {
                value.error = undefined;
              }
              break;
            }

            case "numbers": {
              if (value.currentValue === 0) {
                value.error = "This field cannot be empty.";
              } else if (value.currentValue < 0) {
                value.error = "Value must be non-negative.";
              }
              // When applyToAllOrders is true remove validation error if any
              if (selectedOrder.applyToAllOrders === true) {
                value.error = undefined;
              }
              break;
            }
            case "countryName": {
              if (value.currentValue.length === 0) {
                value.error = "The order country field cannot be empty.";
              }
              // When applyToAllOrders is true remove validation error if any
              if (selectedOrder.applyToAllOrders === true) {
                value.error = undefined;
              }
              break;
            }
            case "amount": {
              if (condition.operator === "is between") {
                if (
                  // Check if both inputs are empty
                  (value.currentValue.minValue === undefined &&
                    value.currentValue.maxValue === undefined) ||
                  (value.currentValue.minValue === "" &&
                    value.currentValue.maxValue === "")
                ) {
                  value.error = "Both input fields are required.";
                  break;
                }
                // Check if any field is empty
                else if (
                  value.currentValue.minValue === undefined ||
                  value.currentValue.maxValue === undefined ||
                  value.currentValue.maxValue === "" ||
                  value.currentValue.minValue === ""
                ) {
                  value.error = "This field cannot be empty.";
                  break;
                } else if (
                  //Check they are valid non-negative numbers or not
                  isNaN(value.currentValue.minValue) ||
                  isNaN(value.currentValue.maxValue) ||
                  parseFloat(value.currentValue.minValue) < 0 ||
                  parseFloat(value.currentValue.maxValue) < 0
                ) {
                  value.error =
                    "Enter numeric values only and value must be non-negative.";
                  break;
                } else if (
                  // Check if min value is greater than max value or both are equal
                  parseFloat(value.currentValue.minValue) >=
                  parseFloat(value.currentValue.maxValue)
                ) {
                  value.error =
                    "The first value needs to be smaller than the second value, and they cannot be the same.";
                  break;
                } else {
                  //Check if decimal value is upto 2 decimal places or not
                  const decimalPattern = /^\d+(\.\d{1,2})?$/;
                  if (
                    !decimalPattern.test(value.currentValue.minValue) ||
                    !decimalPattern.test(value.currentValue.maxValue)
                  ) {
                    value.error =
                      "Decimal values are allowed up to two decimal places.";
                    break;
                  }
                }
              } else {
                const decimalPattern = /^\d+(\.\d{1,2})?$/;
                //Check if the operator is not between and value is 0 or not filled yet
                if (value.currentValue?.toString()?.length === 0) {
                  value.error = "This field cannot be empty.";
                  break;
                } else if (value.currentValue === 0) {
                  value.error = "This field cannot be zero.";
                  break;
                } else if (
                  ////Check they are valid non-negative numbers or not
                  isNaN(value.currentValue)
                ) {
                  value.error = "Enter numeric value only.";
                  break;
                } else if (
                  ////Check they are valid non-negative numbers or not
                  parseFloat(value.currentValue) < 0
                ) {
                  value.error = "Value must be non-negative.";
                  break;
                } //Check if decimal value is upto 2 decimal places or not
                else if (!decimalPattern.test(value.currentValue)) {
                  value.error =
                    "Decimal value is allowed up to two decimal places.";
                  break;
                }
              }
              break;
            }
            case "none": {
              value.error = "*Please select an option";
              // When applyToAllOrders is true remove validation error if any
              if (selectedOrder.applyToAllOrders === true) {
                value.error = undefined;
              }
              break;
            }

            default:
              break;
          }
          if (conditions.length - 1 === idx && value.error !== undefined) {
            selectedOrder.isAddConditionEnabled = false;
          }

          if (conditions.length - 1 === idx && value.error !== undefined) {
            selectedOrder.isAddConditionEnabled = false;
          }
        });
      });

      const errors = conditions
        .flatMap((condition) => {
          return Object.entries(condition.values).flatMap(
            ([key, value]: [any, any]) => {
              if (value.error === undefined) return null;
              return value.error;
            },
          );
        })
        .concat([selectedOrder.error]);

      selectedOrder.errorCount = errors.reduce((p, c) => {
        if (c !== null) {
          return p + 1;
        }
        return p;
      }, 0);

      if (selectedOrder.errorCount > 0) {
        selectedOrder.isValid = false;
      } else {
        selectedOrder.isValid = true;
      }

      // totalErrorCount += selectedOrder.errorCount;
    },
  );
};

//Reset the resetAntiRefundOrderTypes configuration to the initial state
const resetConfig = (state: IAntiRefundOrderTypesConfig) => {
  state = initialState;
};

const deleteOrderTypeTab = (
  state: IAntiRefundOrderTypesConfig,
  { payload }: { payload: { id: string } },
) => {
  if (state.antiRefundOrderTypesData.selectedOrderTypeId) {
    const { updatedTabs, newSelectedTabId } = deleteTabHelper({
      tabs: state.antiRefundOrderTypesData.antiRefundOrderTypes,
      tabId: payload.id,
      selectedTabId:
        state.antiRefundOrderTypesData.selectedOrderTypeId.toString(),
      idKey: "orderTypeId",
    });

    state.antiRefundOrderTypesData.antiRefundOrderTypes = updatedTabs;
    state.antiRefundOrderTypesData.selectedOrderTypeId = newSelectedTabId;
  }
};

const setStep13OptionConditons = (
  state: IAntiRefundOrderTypesConfig,
  params: PayloadAction<ConditionOptions>,
) => {
  state.antiRefundOrderTypesData.conditionOptions = params.payload.data;
  state.antiRefundOrderTypesData.conditionOptions.forEach((option) => {
    state.antiRefundOrderTypesData.variableNames.push({
      name: option.name,
      id: option.id,
    });
    state.antiRefundOrderTypesData.operatorsNames[option.name] =
      option.operators.map((operator) => {
        state.antiRefundOrderTypesData.operatorSymbolMap[operator.value] =
          operator.name;
        return operator.name;
      });
    state.antiRefundOrderTypesData.valueTypes[option.valueType] = null;
    state.antiRefundOrderTypesData.variableNameToValueType[option.name] =
      option.valueType;
  });
};
//Update redux state followup questions with the local state on saving followup questions
const updateFollowUpQuestionsConfigOrderTypes = (
  state: IAntiRefundOrderTypesConfig,
  params: PayloadAction<{
    orderTypeId: string | number;
    reasonId: string | number;
    scenarioId: string;
    localState: RefundScenarioState;
  }>,
) => {
  const { orderTypeId, reasonId, scenarioId, localState } = params.payload;

  // Find the index of the selected 'orderType' based on 'orderTypeId'.
  const orderTypeIndex =
    state.antiRefundOrderTypesData.antiRefundOrderTypes.findIndex(
      (orderType) => orderType.orderTypeId === orderTypeId,
    );

  // Find the index of the selected 'reason' within the 'refundRequests' of the 'orderType'.
  const reasonIdx = state.antiRefundOrderTypesData.antiRefundOrderTypes[
    orderTypeIndex
  ].refundRequests.reasons.findIndex((reason) => reason.id === reasonId);

  // Find the index of the selected 'scenario' within the 'reason'.
  const scenarioIdx = state.antiRefundOrderTypesData.antiRefundOrderTypes[
    orderTypeIndex
  ].refundRequests.reasons[reasonIdx].scenarios.findIndex(
    (scenario) => scenario.id === scenarioId,
  );

  // Get the 'refundScenario' based on the calculated indices.
  const refundScenario =
    state.antiRefundOrderTypesData.antiRefundOrderTypes[orderTypeIndex]
      .refundRequests.reasons[reasonIdx].scenarios[scenarioIdx];

  // Check if a 'refundScenario' exists
  if (refundScenario) {
    refundScenario.items = localState.items;
    refundScenario.tags = localState.tags;
    refundScenario.followUpQuestions = localState.followUpQuestions;
    refundScenario.itemsAndTagAllSelected = localState.itemsAndTagAllSelected;
  }
};

//Update redux state additional info with the local state on saving additional info
const updateAdditionalInfoConfigOrderTypes = (
  state: IAntiRefundOrderTypesConfig,
  params: PayloadAction<{
    orderTypeId: string | number;
    reasonId: string | number;
    scenarioId: string;
    localState: RefundScenarioState;
  }>,
) => {
  const { orderTypeId, reasonId, scenarioId, localState } = params.payload;

  // Find the index of the selected 'orderType' based on 'orderTypeId'.
  const orderTypeIndex =
    state.antiRefundOrderTypesData.antiRefundOrderTypes.findIndex(
      (orderType) => orderType.orderTypeId === orderTypeId,
    );

  // Find the index of the selected 'reason' within the 'refundRequests' of the 'orderType'.
  const reasonIdx = state.antiRefundOrderTypesData.antiRefundOrderTypes[
    orderTypeIndex
  ].refundRequests.reasons.findIndex((reason) => reason.id === reasonId);

  // Find the index of the selected 'scenario' within the 'reason'.
  const scenarioIdx = state.antiRefundOrderTypesData.antiRefundOrderTypes[
    orderTypeIndex
  ].refundRequests.reasons[reasonIdx].scenarios.findIndex(
    (scenario) => scenario.id === scenarioId,
  );

  // Get the 'refundScenario' based on the indices.
  const refundScenario =
    state.antiRefundOrderTypesData.antiRefundOrderTypes[orderTypeIndex]
      .refundRequests.reasons[reasonIdx].scenarios[scenarioIdx];
  // Check if a 'refundScenario' exists
  if (refundScenario) {
    refundScenario.additionalInformation = localState.additionalInformation;
    refundScenario.giveOffer = localState.giveOffer;
    refundScenario.offerExchange = localState.offerExchange;
  }
};

const pushInitialHash = (state: IAntiRefundOrderTypesConfig) => {
  const hash = createNewObjectHash({
    excludeKeys: state.excludeKeysForHash,
    hashObject: state.antiRefundOrderTypesData.antiRefundOrderTypes,
  });

  if (hash) {
    state.currentHash = hash;
    state.initialHash = hash;
  }
  state.currentHash = state.initialHash;
};

const updateArsResponse = (
  state: IAntiRefundOrderTypesConfig,
  { payload }: { payload: { arsResponse: ARSResponse | null } },
) => {
  state.arsResponse = payload.arsResponse;
};

export default {
  setSelectedOrderTypeId,
  updateOrderType,
  pushCurrentHashForSelectedTab,
  validateChanges,
  addOrderType,
  // validateOrderType,
  validateCondition,
  addCondition,
  deleteCondition,
  updateCondition,
  deselectAllFollowUpQuestions,
  removeFollowUpQuestions,
  selectAllFollowUpQuestions,
  toggleAntiRefundMethodStep13,
  updateAdditionalInfo,
  updateGiveOffer,
  updateFollowUpQuestionSelectedValue,
  updateFollowUpQuestions,
  updateItemsAndTagsAllSelected,
  updateOfferExchange,
  updateRefundReasons,
  updateScenarioItems,
  updateScenarioTags,
  validateStep13,
  resetConfig,
  deleteOrderTypeTab,
  setStep13OptionConditons,
  pushInitialHash,
  updateAdditionalInfoConfigOrderTypes,
  updateFollowUpQuestionsConfigOrderTypes,
  toggleAllOrder,
  updateArsResponse,
};
