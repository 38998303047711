import {
  BaseConfigModalTabType,
  GetConfigModalParams,
  SelectedConditionType,
} from "src/features/ReturnAutoWorkFlow/children/ConfigModals/oldReturnModals.types";
import { ReturnMethodType } from "src/features/ReturnAutoWorkFlow/children/ConfigModals/store/slices/ReturnMethodSlice/returnMethod.slice";
import { axiosJSON } from "src/globals/axiosEndPoints";

export interface ReturnMethodTab extends BaseConfigModalTabType {
  selectReturnReasons: SelectedConditionType[];
  returnMethod: {
    returnType: ReturnMethodType;
    displayMessage: string;
  };
}

async function getReturnMethodService(params: GetConfigModalParams) {
  const { data: res } = await axiosJSON.get(
    "/api/setting/automation/refund/step/14/configuration",
    { params },
  );

  if (res.err || res.error) {
    throw new Error(res.msg ?? res.message ?? "Some Error Occurred.");
  }

  return res.data as ReturnMethodTab[];
}

export default getReturnMethodService;
