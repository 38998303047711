import { axiosJSON } from "src/globals/axiosEndPoints";
import { IFulfillmentStatus } from "./createFulfillment";

export interface ReleaseFulfillment {
  data: {
    orderId: number;
    fulfillmentOrderId: string;
    fulfillmentStatus: IFulfillmentStatus;
    // fulfillmentOrder: {
    //   id: string;
    //   status: string;
    //   requestStatus: string;
    // };
  };
  error: boolean;
  message: "";
}

export interface ReleaseFulfillmentParams {
  orderId: number;
  fulfillmentOrderId: string;// shopify fulfillmentOrder Id
}

export async function releaseFulfillment(params: ReleaseFulfillmentParams) {
  const { data: res }: any = await axiosJSON.post(
    `/api/shopify/order/fulfillment/release`,
    params
  );
  if (res.error === true) {
    throw new Error(res.message as string);
  }

  return res.data as ReleaseFulfillment;
}
