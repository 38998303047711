import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import DraftModal from "src/components/DraftModal";
import DraftMsgView from "src/components/DraftModal/children/DraftMsg/DraftMsgView";
import {
  editDraftMsg,
  fetchInnerTicketMessages,
  fetchMessageByIds,
  resetInnerTicketMessage,
  setActiveTicketInfoFromTicketData,
} from "src/store/slices/innerTicket/innerTicket.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";

function DraftMsgComp({
  messageData,
  messageId,
}: {
  messageData: any;
  messageId: number;
}) {
  const { ticketId } = useParams();
  const [show, setShow] = useState(false);
  const [selectedFilesEditDraft, setSelectedFilesEditDraft] = useState<any>([]);
  const [isFileUploadingEditDraft, setIsFileUploadingEditDraft] =
    useState<boolean>(false);
  const dispatch = useAppDispatch();
  const currentState = useRef({ ticketId, messageId });
  useEffect(() => {
    currentState.current = { ticketId, messageId };
  }, [ticketId, messageId]);
  const onShow = () => {
    setShow(true);
  };
  const scopes = useAppSelector(
    (state) => state.globals.currentUserData?.scopes
  );
  const onHide = () => {
    setShow(false);
    // dispatch(resetInnerTicketMessage());
    // dispatch(setActiveTicketInfoFromTicketData({ ticketId }));
    // dispatch(fetchInnerTicketMessages());
  };
  const handelOnEdit = (value: string, callback: () => void) => {
    // // console.log("DraftMsgComp selectedFilesEditDraft::: ", JSON.stringify(selectedFilesEditDraft));
    dispatch(
      editDraftMsg({
        msgId: currentState.current.messageId,
        msgContent: value,
        attachmentBatchNumber: selectedFilesEditDraft
          ? selectedFilesEditDraft.map(function (value: any, index: any) {
              return value.batchNumber || value.attachmentBatchNumber;
            })
          : [],
        callback,
      })
    );
    // setSelectedFilesEditDraft([]);
  };
  const chatIconAction = (e: any) => {
    onShow();
  };
  return (
    <div className={`px-4`}>
      <DraftMsgView
        statusName={messageData.draftStatusData.draftStatusName}
        agentImgURL={messageData.senderImgURL}
        agentName={messageData.senderName}
        messageDate={messageData.messageDateTime}
        canEdit={scopes && scopes.includes("add_draft") ? true : false}
        editDraftMsg={handelOnEdit}
        messageContent={messageData.message}
        showChatIcon={true}
        chatIconAction={chatIconAction}
        showRemarkCountIcon={true}
        remarkCount={
          messageData.draftRemarkCount ? messageData.draftRemarkCount : 0
        }
        selectedFilesEditDraft={selectedFilesEditDraft}
        setSelectedFilesEditDraft={setSelectedFilesEditDraft}
        isFileUploadingEditDraft={isFileUploadingEditDraft}
        setIsFileUploadingEditDraft={setIsFileUploadingEditDraft}
        messageData={messageData}
        attachments={messageData.attachments}
        isFromInnerTicket={true}
      />
      <DraftModal
        ticketId={parseInt(ticketId + "")}
        onHide={onHide}
        onShow={onShow}
        show={show}
        onAnyChange={() => {
          dispatch(
            fetchMessageByIds({
              ticketId: parseInt(currentState.current.ticketId + ""),
              msgIds: [currentState.current.messageId],
            })
          );
        }}
      />
    </div>
  );
}

export default DraftMsgComp;
