import React, { useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import styles from "./EnterStoreNameModal.module.scss";
import integrationLogo from "src/assets/images/integrationLogo.png";
import helplamaLogo from "src/assets/images/hLogo.png";
import saufterLogo from "src/assets/images/saufter.gif";
import shopifyLogo from "src/assets/images/browseShop.png";
import { integrateShopify } from "src/apis/shopify/integrate";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";

const EnterStoreNameModal = ({
  showModal,
  setShowModal,
}: {
  showModal: boolean;
  setShowModal: Function;
}) => {
  const [storeUrl, setStoreUrl] = useState<string>("");

  const [loading, setLoading] = useState(false);

  const handleConnectStore = (e: any) => {
    setLoading(true);
    if (storeUrl.trim().length !== 0) {
      integrateShopify(storeUrl)
        .then((res) => {
          setLoading(false);
          window.location.replace(res.data.url as any);
        })
        .catch((err) => {
          setLoading(false);
          pushTheToast({
            type: "danger",
            text: err,
            position: "top-right",
          });
        });
    }
  };

  const handleOnHide = () => {
    setShowModal(false);
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={handleOnHide}
        backdropClassName={`${styles.modalBack}`}
        dialogClassName={`${styles.modalDialog}`}
        contentClassName={`${styles.modalContent}`}
        centered={true}
        enforceFocus={false}
      >
        <div className={styles.mainDiv}>
          <div className={`d-flex justify-content-center ${styles.iconsDiv}`}>
            <div className={`d-flex flex-row w-100 justify-content-between`}>
              <div className={`d-flex flex-column`} style={{ width: "35%" }}>
                <div className={`d-flex flex-row justify-content-center`}>
                  <img className={`${styles.logo}`} src={saufterLogo} />
                </div>
                <div className={`d-flex flex-row justify-content-center`}>
                  <span className={`${styles.storeText}`}>Saufter</span>
                </div>
              </div>

              <div
                className={`d-flex flex-column justify-content-center`}
                style={{ width: "30%" }}
              >
                <div className={`text-center `}>
                  <img
                    className={`${styles.integrateIcon}`}
                    src={integrationLogo}
                  />
                </div>
              </div>

              <div className={`d-flex flex-column`} style={{ width: "35%" }}>
                <div className={`d-flex flex-row justify-content-center`}>
                  <img className={`${styles.logo}`} src={shopifyLogo} />
                </div>
                <div className={`d-flex flex-row justify-content-center`}>
                  <span className={`${styles.storeText}`}>Shopify</span>
                </div>
              </div>
            </div>
          </div>
          <p className={styles.enterYourStore}>Enter Your Store</p>
          <input data-lpignore="true" 
            type={"text"}
            className={styles.storeUrlInput}
            placeholder="my-store.myshopify.com"
            value={storeUrl}
            onChange={(e) => setStoreUrl(e.target.value)}
          />
          <div className={`${styles.connectBtnDiv} justify-content-center`}>
            <button
              className={styles.connectStoreBtn}
              onClick={(e) => handleConnectStore(e)}
              disabled={storeUrl.trim().length === 0}
            >
              {loading && (
                <Spinner
                  className={`mx-2 my-auto`}
                  animation="border"
                  size="sm"
                  variant="white"
                />
              )}
              <span className={`m-auto`}>Connect store</span>
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EnterStoreNameModal;
