import { useCallback, useEffect, useMemo, useRef } from "react";

export const deleteDraft = (autosavePrefix: string) => {};

interface DraftProps {
  autosavePrefix?: string;
  value: string;
  onChange: (value: string) => void;
}

const useSaveDraft = ({ autosavePrefix, value, onChange }: DraftProps) => {
  const currentState = useRef({ autosavePrefix });
  const init = useRef(false);
  useMemo(() => {
    currentState.current.autosavePrefix = autosavePrefix;
  }, [autosavePrefix]);

  useEffect(() => {
    if (currentState.current.autosavePrefix) {
      if (init.current) {
        const to = setTimeout(() => {
          if (value.trim()) {
            window.localStorage.setItem(
              `${currentState.current.autosavePrefix}draft`,
              value,
            );
          } else {
            window.localStorage.removeItem(
              `${currentState.current.autosavePrefix}draft`,
            );
          }
        }, 1000);
        return () => {
          clearTimeout(to);
        };
      } else if (value.trim() === "") {
        const v = window.localStorage.getItem(
          `${currentState.current.autosavePrefix}draft`,
        );
        if (v?.trim()) {
          onChange(v);
        }
        init.current = true;
      }
    }
  }, [value]);
};

export default useSaveDraft;
