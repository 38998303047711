import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";

export interface retrySourceArticles {
  sourceId: number | string;
  articleId: number | string;
}

const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/external/reTrySourceArticles`
  : "/api/botProfile/external/reTrySourceArticles";

export async function reTrySourceArticles(params: retrySourceArticles) {
  const { data: res } = await axiosJSON.post(apiEndPoint, params);

  if (res.error) {
    throw new Error(res.message as string);
  }

  return res.data;
}
