import styles from "./SendingAsV2.module.scss";
import ViewTemplateButton from "src/components/ViewTemplateButtonV2";
import { useAppSelector } from "src/store/store";
import { useEffect, useState } from "react";
import DropdownGroup from "src/routes/LiveChat/Children/MainChat/Children/LiveSendingAs/Children/DropdownGroup";
import AddNoteIcon from "src/assets/images/note-add.png";
import AddNoteActiveIcon from "src/assets/images/note-add-active.png";
import ViewTemplateButtonV2 from "src/components/ViewTemplateButtonV2";
import { EChannel } from "src/enums/EChannel";
import { Dropdown } from "react-bootstrap";
import slack from "src/assets/images/slack.png";
import slackAlert from "src/assets/images/SlackAlert.png";
import saufter from "src/assets/images/saufter.png";
import { useDispatch } from "react-redux";
import { setShowEnableSlack } from "src/store/slices/innerTicket/innerTicket.slice";
export enum ESendTypes {
  Message = 1,
  Note,
}

export enum ReplyOption {
  Reply = "Reply",
  Forward = "Forward",
}

interface SendingAsV2Props {
  ticketId: string;
  sendType: ESendTypes;
  setSendType: (type: ESendTypes) => void;
  replyOption: ReplyOption;
  setReplyOption: (option: ReplyOption) => void;
  selectedTemplate?: (templateText: string) => void;
  showES?: boolean;
  showingWhichModal?: string;
  setShowingWhichModal?: (whichModal: string) => void;
  channelName: EChannel;
  setChannelName: (value: EChannel) => void;
  allowedChannels: EChannel[];
  allowedNoteChannels: EChannel[];
  internalChat?: boolean;
}

function SendingAsV2({
  ticketId,
  sendType,
  setSendType,
  replyOption,
  setReplyOption,
  selectedTemplate = (templateText) => {},
  showES = true,
  showingWhichModal = "",
  setShowingWhichModal = (whichModal) => {},
  channelName = EChannel.email,
  setChannelName = (value) => {},
  allowedChannels = [EChannel.email],
  allowedNoteChannels = [],
  internalChat,
}: SendingAsV2Props) {
  const scopes = useAppSelector(
    (state) => state.globals.currentUserData?.scopes,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      scopes &&
      (scopes.includes("send_message") || scopes.includes("add_draft")) &&
      showES
    ) {
      setSendType(sendType);
    } else if (scopes && scopes.includes("add_internal_note")) {
      setSendType(ESendTypes.Note);
    }
  }, [false]);
  useEffect(() => {
    internalChat
      ? setSendType(ESendTypes.Note)
      : setSendType(ESendTypes.Message);
  }, [internalChat]);
  const [noteIcon, setNoteIcon] = useState(false);
  return (
    <div
      className={`d-flex ${styles.sendingAs} justify-content-between my-1 flex-wrap`}
    >
      <div className={`my-auto d-flex`}>
        {scopes &&
          (scopes.includes("send_message") || scopes.includes("add_draft")) &&
          showES &&
          (ESendTypes.Message !== sendType ? (
            <div
              className={`px-2 ${styles.share} ${
                ""
                // sendType === ESendTypes.NOTE ? styles.active : ""
              }`}
              onClick={(e) => {
                setSendType(ESendTypes.Message);
                setChannelName(EChannel.email);
                // if slack enable button visible so hide it
                if (!allowedNoteChannels.includes(EChannel.slack)) {
                  dispatch(setShowEnableSlack({ show: false, ticketId }));
                }
              }}
            >
              <i
                className={`fa-sharp fa-solid fa-share fa-flip-horizontal fa-xs ${styles.active}`}
              ></i>
              <span className={`ms-1 ${styles.active}`}>Send Response</span>
            </div>
          ) : (
            <div className="d-flex align-items-center">
              <DropdownGroup
                replyOption={replyOption}
                setReplyOption={(option) => {
                  setReplyOption(option);
                  if (option == ReplyOption.Forward) {
                    setChannelName(EChannel.email);
                  }
                }}
                showReply={true}
                channel={channelName}
                setChannel={setChannelName}
                allowedChannels={allowedChannels}
                disableChannel={replyOption == ReplyOption.Forward}
              />
            </div>
          ))}
      </div>

      <div className="d-flex justify-content-end align-items-center">
        <div className={`d-flex justify-content-center align-items-center`}>
          {scopes && scopes.includes("view_templates") && (
            <span className={`${styles.templetesBtn}`}>
              {" "}
              <ViewTemplateButtonV2
                selectedTemplate={(templateText: string) => {
                  selectedTemplate(templateText);
                }}
                showingWhichModal={showingWhichModal}
                setShowingWhichModal={setShowingWhichModal}
              />
            </span>
          )}
        </div>
        {scopes && scopes.includes("add_internal_note") && (
          <div
            className={`px-2 ${styles.note}`}
            onClick={(e) => {
              setSendType(ESendTypes.Note);
            }}
            onMouseEnter={() => {
              setNoteIcon(true);
            }}
            onMouseLeave={() => {
              setNoteIcon(false);
            }}
          >
            <div
              className={`${
                sendType !== ESendTypes.Message ? styles.active : ""
              } px-1`}
              onClick={(e) => {
                setSendType(ESendTypes.Note);
              }}
            >
              <span
                className={`${styles.noteText} ${
                  sendType !== ESendTypes.Message ? styles.activeText : ""
                }`}
              >
                +{" "}
              </span>
              {/* Conditionally display active note icon */}
              <img
                src={
                  sendType !== ESendTypes.Message || noteIcon === true
                    ? AddNoteActiveIcon
                    : AddNoteIcon
                }
                className={`${styles.noteIcon}`}
                alt="note"
              />
              <span
                className={`${styles.noteText} ${
                  sendType !== ESendTypes.Message ? styles.activeText : ""
                }`}
              >
                {" "}
                Internal note
              </span>
            </div>
            <div>
              {sendType !== ESendTypes.Message && (
                <Dropdown>
                  <Dropdown.Toggle
                    id="reply-dropdown"
                    as="div"
                    bsPrefix={`dropdown-toggle px-2 ms-1 ${styles.slackNote} ${
                      styles.cursorPointer
                    } ${
                      !allowedNoteChannels.includes(EChannel.slack) &&
                      channelName === EChannel.slack &&
                      styles.borderRed
                    }`}
                  >
                    <div>
                      {!allowedNoteChannels.includes(EChannel.slack) &&
                      channelName === EChannel.slack ? (
                        <img
                          src={slackAlert}
                          alt="slack"
                          width={15}
                          height={15}
                        />
                      ) : (
                        ""
                      )}
                      <img
                        src={
                          channelName.toLocaleLowerCase() === EChannel.email
                            ? saufter
                            : slack
                        }
                        alt={
                          channelName.toLocaleLowerCase() === EChannel.email
                            ? "saufter"
                            : "slack"
                        }
                        width={12}
                        height={12}
                      />
                      <span className={`ps-1 ${styles.slackText}`}>
                        {channelName.toLocaleLowerCase() === EChannel.email
                          ? "Saufter note"
                          : "Slack"}
                      </span>
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu
                    className={`dropdown-menu ${styles.slackMenu}`}
                  >
                    <div style={{ pointerEvents: "auto" }}>
                      <Dropdown.Item
                        eventKey={ESendTypes.Note}
                        as={"div"}
                        bsPrefix={`dropdown-item d-flex align-items-center mb-1 ${styles.dropItem}`}
                        onClick={(e) => {
                          if (!allowedNoteChannels.includes(EChannel.slack)) {
                            dispatch(
                              setShowEnableSlack({ show: true, ticketId }),
                            );
                          }
                          setSendType(ESendTypes.Note);
                          setChannelName(EChannel.slack);
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <img
                            src={slack}
                            alt="slack"
                            width={12}
                            height={12}
                          />
                          <span className={`ps-1`}>Slack</span>
                          {!allowedNoteChannels.includes(EChannel.slack) && (
                            <div>
                              <img
                                src={slackAlert}
                                alt="slack"
                                width={13}
                                height={13}
                                className="mx-1"
                              />
                              <span className={styles.slcakAlertText}>
                                Not Integrated
                              </span>
                            </div>
                          )}
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey={ESendTypes.Note}
                        as={"div"}
                        bsPrefix={`dropdown-item d-flex align-items-center mb-1 ${styles.dropItem} `}
                        onClick={(e) => {
                          setSendType(ESendTypes.Note);
                          setChannelName(EChannel.email);
                          // if slack enable button visible so hide it
                          if (!allowedNoteChannels.includes(EChannel.slack)) {
                            dispatch(
                              setShowEnableSlack({ show: false, ticketId }),
                            );
                          }
                        }}
                      >
                        <div>
                          <img
                            src={saufter}
                            alt="saufter"
                            width={12}
                            height={12}
                          />
                          <span className={`ps-1`}>Saufter note</span>
                        </div>
                      </Dropdown.Item>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SendingAsV2;
