import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "./WhatsappIntegration.module.scss";
import backArrow from "src/assets/images/backArrow.png";
import deleteImg from "src/assets/images/delete.png";
import { useQuery } from "@tanstack/react-query";
import {
  IntegrateWhatsappParam,
  deleteWhatsAppAccountService,
  getWhatsappAccounts,
  integrateWhatsapp,
  isWhatsappIntegrated,
} from "src/services/Integrations/WhatsappIntegration.service";
import Loader from "src/components/Loader";
import {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { integrateFacebook } from "src/services/Integrations/FacebookIntegration";
import { Spinner } from "react-bootstrap";
import WhatsappRow from "./children/WhatsappRow/WhatsappRow";
import DeleteModal from "./children/DisconnectIntegration/DeleteModal";

function WhatsappIntegration() {
  const navigate = useNavigate();
  const [isWhatsappInstalled, setIsWhatsappInstalled] = useState(false);
  const [addNewNumberLoader, setAddNewNumberLoader] = useState(false);

  const successFlagPushed = useRef(false);

  const integration = useCallback((params?: IntegrateWhatsappParam) => {
    setAddNewNumberLoader(true);
    integrateWhatsapp(params)
      .then((res) => {
        window.location.replace(res);
      })
      .catch((err) => {
        pushTheToast({
          type: "danger",
          text: err.message,
          position: "top-right",
        });
      })
      .finally(() => {
        setAddNewNumberLoader(false);
      });
  }, []);

  const {
    data,
    isLoading: whatsappAccountsLoading,
    isError: whatsappAccountsError,
    refetch,
  } = useQuery({
    queryKey: ["getWhatsappAccounts"],
    queryFn: getWhatsappAccounts,
  });

  const whatsappAccounts = useMemo(() => {
    if (data?.accounts) {
      setIsWhatsappInstalled(true);
    } else {
      setIsWhatsappInstalled(false);
    }
    return data?.accounts;
  }, [data]);

  const [params, setParams] = useSearchParams();

  useEffect(() => {
    // Get specific query parameters by name
    const status = params.get("status");

    if (
      successFlagPushed.current === false &&
      whatsappAccounts &&
      status &&
      status === "success"
    ) {
      pushTheToast({
        type: "success",
        text: "Whatsapp integrated successfully!",
        position: "top-right",
      });
      successFlagPushed.current = true;
      // Remove the query params for success status
      params.delete("status");
      setParams(params);
    }
  }, [whatsappAccounts, params]);

  const heading = (
    <>
      <div className="d-flex justify-content-between">
        <h3 className={`${styles.chooseBoxHead} mt-0`}>
          Your Linked Whatsapp Numbers.
        </h3>
      </div>
      <div className={`mb-1`}>
        <span className={`${styles.chooseBoxSpan}`}>
          {whatsappAccounts?.length && whatsappAccounts.length > 0
            ? `${whatsappAccounts.length} numbers are linked.`
            : "You have no numbers linked at the moment."}
        </span>
      </div>
    </>
  );

  return (
    <div className={`${styles.fbMain}`}>
      <div className={``}>
        <div className={`d-flex justify-content-between align-items-center`}>
          <div className={`d-flex`}>
            <div
              className="edit-icons"
              style={{ marginTop: "-4px" }}
            >
              <button
                style={{
                  border: "none",
                  background: "none",
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  navigate("/settings/browse-integration");
                }}
              >
                <img
                  src={backArrow}
                  alt="backArrow"
                />
              </button>
            </div>
            <div className={`${styles.fbMainDiv}`}>
              <h1 className={`${styles.fb}`}>Whatsapp</h1>
            </div>
          </div>
        </div>
        <div className={` ${styles.chooseBoxMain}`}>
          {whatsappAccountsLoading ? (
            <Loader />
          ) : !isWhatsappInstalled || !whatsappAccounts?.length ? (
            <>
              <div className={`${styles.chooseBox} py-2`}>
                <div className={`${styles.headerBottomBorder}`}>
                  {heading}
                  <button
                    className={`${styles.fbBtn} mt-4 px-2 d-flex justify-content-center align-items-center mx-3 mb-2`}
                    onClick={() =>
                      integration({ integration_id: data?.integrationId })
                    }
                  >
                    <i className="fa-brands fa-facebook me-2"></i>
                    <span>Log in With Facebook</span>
                  </button>
                </div>
              </div>
            </>
          ) : (
            <div className={`d-block mt-2`}>
              <div className={`${styles.chooseBox} py-2`}>
                <div className={`${styles.headerBottomBorder}`}>
                  {heading}
                  {whatsappAccountsError ? (
                    <div>Something went wrong</div>
                  ) : (
                    <div className={`${styles.resTable}`}>
                      {whatsappAccounts.map((account) => {
                        return (
                          <div key={account.id}>
                            <WhatsappRow account={account} />
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
              <p className={`${styles.addInt}`}>
                <span
                  className={`cursor-pointer`}
                  onClick={() => integration()}
                >
                  + Add new number
                </span>
                {/* loader for add new number button */}
                {addNewNumberLoader === true && (
                  <Spinner
                    className="my-auto ms-2"
                    animation="border"
                    size="sm"
                  />
                )}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default WhatsappIntegration;
