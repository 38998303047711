import { Spinner } from "react-bootstrap";
import { AJAXSTATUS } from "src/globals/constants";
import styles from "./DeactivateBrand.module.scss";
interface Props{
  handleCancel: Function,
  handleSubmit: Function,
  updateBrandAjaxStatus: AJAXSTATUS
}
const DeactivateBrand = ( {handleCancel, handleSubmit, updateBrandAjaxStatus}: Props) => {

  return (
    <>
      <div className={`container`}>
        <div className={`row`}>
          <div className={`col-12`}>
            <div className={`px-4 pt-4 pb-3 text-center`}>
              <h3 className={`${styles.modalHead}`}>
              Are you sure you want to deactivate this brand?
              </h3>
              <span className={`d-inline-block py-2 ${styles.modalSub}`}>
              You will be able to activate the brand again, if you want.
              </span>
              <div
                className={` pt-4 pb-3 d-flex justify-content-center align-items-center`}
              >
                <button
                  className={`bg-transparent px-4 py-2 me-3 me-md-4 ${styles.cancelBtn}`}
                  onClick={()=>handleCancel()}
                >
                  Cancel
                </button>
                <button className={`border-0 px-4 py-2 ${styles.confirmBtn}`} onClick={()=>handleSubmit()} disabled={updateBrandAjaxStatus === "pending"}>
                {updateBrandAjaxStatus === "pending" ? (
                  <Spinner
                    className="my-auto mx-1"
                    animation="border"
                    color="white"
                    size="sm"
                  />
                ): "Confirm"}
                  
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DeactivateBrand;
