/**
 * This file is the custom hook and context file.
 * It contains useCustomerSegmentsCreate and useCustomerSegments custom hooks.
 *
 * @author Yash Aditya
 * @module CustomerSegments
 * @fileoverview Custom hooks and context for managing customer segments state.
 */

import React, {
  useCallback,
  useContext,
  useMemo,
  useReducer,
  useRef,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AJAXSTATUS, ActionDispatch } from "src/globals/constants";
import getCustomSegmentsService, {
  AllCustomSegments,
} from "src/services/CustomerSegments/getCustomSegments.service";
import {
  IAllSegmentTypesData,
  Segment,
} from "src/services/CustomerSegments/getSegmentTypes.service";
import customerSegmentsUtils from "../utils/customerSegments.utils";
import useFetchSegments from "./useFetchSegments";

export type SegmentTypes = "companySegment" | "userSegment";

export interface SegmentDataForUpdateI {
  segmentId?: string;
  segmentType: string;
  action: "create" | "update" | "duplicate";
}

/**
 * Interface representing the global state for customer segments.
 *
 * @interface CustomerSegmentsState
 * @extends IAllSegmentTypesData
 */
export interface CustomerSegmentsState extends IAllSegmentTypesData {
  // Status of all segments loading
  allSegmentsLoading: AJAXSTATUS;
  customSegments: Record<
    // segmentType
    string,
    AllCustomSegments & {
      // Status of custom segments loading
      customSegmentsLoading: AJAXSTATUS;
      hasNextPage: boolean;
    }
  >;
  // Currently active segment type
  activeSegmentType: string | null;
  // Currently active segment ID
  activeSegmentId: string | null;
  segmentDataForUpdate: SegmentDataForUpdateI | null;
}

/**
 * Initial state for the customer segments.
 */
const customerSegments: CustomerSegmentsState = {
  // All segments data
  allSegments: {},
  // All segment types
  allSegmentTypes: [],
  // Initial loading status for all segments
  allSegmentsLoading: "pending",
  // No active segment type initially
  activeSegmentType: null,
  // No active segment ID initially
  activeSegmentId: null,
  customSegments: {},
  segmentDataForUpdate: null,
};

/**
 * Reducer function to handle state modifications.
 * @function
 * @param {CustomerSegmentsState} state - The current state.
 * @param {[function, any]} actionPayload - Array containing the action and payload.
 * @returns {CustomerSegmentsState} The new state after applying the action.
 */
const reducerMethod = (
  state: CustomerSegmentsState,
  [action, payload]: [
    (value: CustomerSegmentsState, payload?: any) => void,
    undefined | any,
  ],
): CustomerSegmentsState => {
  // Execute the action function with current state and payload
  action(state, payload);
  // Return a new state object to ensure immutability
  return { ...state };
};

/**
 * Context for customer segments.
 */
const CustomerSegmentsContext = React.createContext([
  customerSegments,
  () => {},
] as [CustomerSegmentsState, ActionDispatch<typeof CustomerSegmentsActions>]);

/**
 * Custom hook to create context with a reducer.
 */
export const useCustomerSegmentsCreate = () => {
  // Get segment type and ID from route parameters
  const { segmentType, segmentId } = useParams();
  // Get navigation function
  const navigate = useNavigate();
  // Fetch all segments data and status
  const { allSegmentsData, allSegmentsStatus } = useFetchSegments();

  // Use the reducer to manage state
  const [state, dispatch] = useReducer(reducerMethod, {
    // Initialize state with default values
    ...customerSegments,
    // Set initial loading status for all segments
    allSegmentsLoading: allSegmentsStatus,
    // Set active segment type if available
    activeSegmentType: segmentType ? segmentType : null,
    // Set active segment ID if available
    activeSegmentId: segmentId ? segmentId : null,
  });

  // Create a ref to store current segment ID and type and state
  const currentStateRef = useRef({ segmentId, segmentType, state });

  // Need to keep the updated state in useRef as it is being used in async useMemo below
  // To update the fetched data we need this and we can not put this in the useMemo dependency to resolve infinite renders
  useMemo(() => {
    currentStateRef.current.state = state;
  }, [state]);

  // Memoize the modified dispatch function using useCallback
  const modifiedDispatch: ActionDispatch<typeof CustomerSegmentsActions> =
    useCallback(
      (action, payload) => {
        // Wrap dispatch with action and payload
        dispatch([CustomerSegmentsActions[action], payload]);
      },
      [dispatch],
    );

  useMemo(() => {
    // Update ref with current segment ID
    currentStateRef.current.segmentId = segmentId;
    // Update ref with current segment type
    currentStateRef.current.segmentType = segmentType;

    const value: Partial<CustomerSegmentsState> = {
      // Set active segment type
      activeSegmentType: segmentType ? segmentType : null,
      // Set active segment ID
      activeSegmentId: segmentId ? segmentId : null,
    };
    // Dispatch setSegments action with updated values
    modifiedDispatch("setSegments", value);
  }, [segmentId, segmentType]);

  useMemo(async () => {
    // Destructure current segment ID and type from ref
    const { segmentId, segmentType, state } = currentStateRef.current;

    const value: Partial<CustomerSegmentsState> = {
      // Set loading status for all segments
      allSegmentsLoading: allSegmentsStatus,
      // Set active segment type
      activeSegmentType: segmentType ? segmentType : null,
      // Set active segment ID
      activeSegmentId: segmentId ? segmentId : null,
      customSegments: state.customSegments,
    };

    if (allSegmentsData && value.customSegments) {
      // Set all segments data
      value.allSegments = allSegmentsData.allSegments;
      // Set all segment types
      value.allSegmentTypes = allSegmentsData.allSegmentTypes;
      if (
        !(
          value.activeSegmentType &&
          allSegmentsData.allSegmentTypes.includes(value.activeSegmentType)
        )
      ) {
        // Set default active segment type if current type is not valid
        value.activeSegmentType = allSegmentsData.allSegmentTypes[0] ?? null;
      }
      if (value.activeSegmentType && !value.activeSegmentId) {
        const defaultSegmentIds =
          allSegmentsData.allSegments[value.activeSegmentType]
            ?.defaultSegmentIds;
        if (defaultSegmentIds?.length) {
          // Set default active segment ID if current ID is not available
          value.activeSegmentId = defaultSegmentIds[0];
        }
      }

      // Setting up custom segments for each segment type
      for (let i = 0; i < allSegmentsData.allSegmentTypes.length; i++) {
        const segmentType = allSegmentsData.allSegmentTypes[i];
        // Checking if segment is defined already
        if (value.customSegments[segmentType] === undefined) {
          value.customSegments[segmentType] = {
            allCustomSegmentIds: [],
            allCustomSegments: {},
            customSegmentsLoading: "idle",
            hasNextPage: true,
          };
        }
      }
    }
    if (
      value.allSegments &&
      value.activeSegmentType &&
      value.activeSegmentId &&
      value.customSegments
    ) {
      // Retrieve the defaultSegmentIds for the active segment type, or an empty array if not found
      const defaultSegmentIds =
        value.allSegments[value.activeSegmentType]?.defaultSegmentIds ?? [];

      // Check if the active segment ID is not included in the defaultSegmentIds
      // Also Check if the active segment ID is not included in the custom segments
      if (
        !defaultSegmentIds.includes(value.activeSegmentId) &&
        !value.customSegments[value.activeSegmentType].allCustomSegments[
          value.activeSegmentId
        ]
      ) {
        try {
          // Fetch custom segments using the getCustomSegmentsService function
          const customSegmentsData = await getCustomSegmentsService({
            segmentType: value.activeSegmentType,
            segmentIds: [value.activeSegmentId],
          });

          // Check if the first custom segment key matches the active segment ID
          if (
            customSegmentsData.allCustomSegmentIds[0] ===
              value.activeSegmentId &&
            value.customSegments[value.activeSegmentType]
          ) {
            // Set custom segments data in the state
            value.customSegments[value.activeSegmentType].allCustomSegments =
              customSegmentsData.allCustomSegments;

            // Set custom segment keys in the state
            value.customSegments[value.activeSegmentType].allCustomSegmentIds =
              customSegmentsData.allCustomSegmentIds;
          } else {
            // Reset active segment ID if the custom segment ID does not match
            value.activeSegmentId = null;
          }
        } catch (error) {
          console.error(error);
        }
      }
    }

    // Dispatch setSegments action with updated values
    modifiedDispatch("setSegments", value);
    if (
      value.activeSegmentType &&
      value.activeSegmentId &&
      (segmentType !== value.activeSegmentType ||
        segmentId !== value.activeSegmentId)
    ) {
      // Navigate to the new segment type and ID if changed
      navigate(
        customerSegmentsUtils.getNavigation(
          value.activeSegmentType,
          value.activeSegmentId,
        ),
      );
    }
  }, [modifiedDispatch, navigate, allSegmentsData, allSegmentsStatus]);

  // Return the state, modified dispatch, and context provider
  return [state, modifiedDispatch, CustomerSegmentsContext.Provider] as [
    CustomerSegmentsState,
    ActionDispatch<typeof CustomerSegmentsActions>,
    typeof CustomerSegmentsContext.Provider,
  ];
};

/**
 * Custom hook to consume the customer segments context.
 */
export const useCustomerSegments = () => {
  // Destructure context values
  const [customerSegments, dispatch] = useContext(CustomerSegmentsContext);

  const segmentDataForUpdate = useMemo(
    () => customerSegments.segmentDataForUpdate,
    [customerSegments],
  );

  const activeSegmentDetails = useMemo(() => {
    const segmentTypeName =
      customerSegments.allSegments[customerSegments.activeSegmentType + ""]
        ?.name ?? "";
    let segmentName = "";
    let segmentDescription = "";

    let isCustomSegment = false;
    const customSegmentValue = (customerSegments.customSegments[
      customerSegments.activeSegmentType + ""
    ]?.allCustomSegments ?? {})[customerSegments.activeSegmentId + ""];

    if (customSegmentValue) {
      segmentName = customSegmentValue.name;
      segmentDescription = customSegmentValue.description;
      isCustomSegment = true;
    }

    if (isCustomSegment) {
      return {
        segmentTypeName,
        segmentName,
        segmentDescription,
        activeSegmentId: customerSegments.activeSegmentId,
        activeSegmentType:
          customerSegments.activeSegmentType as SegmentTypes | null,
        isCustomSegment,
      };
    }

    const segmentGroups =
      customerSegments.allSegments[customerSegments.activeSegmentType + ""]
        ?.segmentGroups ?? [];
    for (let i = 0; i < segmentGroups.length; i++) {
      const group = segmentGroups[i];
      if (group.segmentId === customerSegments.activeSegmentId) {
        segmentName = group.name;
        break;
      } else if (group.segments?.length) {
        for (let j = 0; j < group.segments.length; j++) {
          const segment = group.segments[j];
          if (segment.segmentId === customerSegments.activeSegmentId) {
            segmentName = segment.name;
            segmentDescription = segment.description;
            break;
          }
        }
      }
    }
    return {
      segmentTypeName,
      segmentName,
      segmentDescription,
      activeSegmentId: customerSegments.activeSegmentId,
      activeSegmentType:
        customerSegments.activeSegmentType as SegmentTypes | null,
      isCustomSegment,
      allSegmentsLoading: customerSegments.allSegmentsLoading,
    };
  }, [customerSegments]);

  /**
   * Hides the update segment details UI by dispatching a remove action.
   */
  const onHideUpdateSegmentModal = useCallback(() => {
    dispatch("removeUpdateSegmentDetails");
  }, [dispatch]);

  // Return context values
  return {
    customerSegments,
    dispatch,
    segmentDataForUpdate,
    activeSegmentDetails,
    onHideUpdateSegmentModal,
  };
};

/**
 * Actions to modify the customer segments state.
 */
const CustomerSegmentsActions = {
  /**
   * Resets the customer segments state.
   * @function
   * @param {CustomerSegmentsState} state - The current state.
   */
  resetState: (state: CustomerSegmentsState) => {
    // Reset all segments data
    state.allSegments = {};
    // Reset all segment types
    state.allSegmentTypes = [];
    // Reset all custom segments data
    state.customSegments = {};
    // Reset loading status for all segments
    state.allSegmentsLoading = "pending";
    // Reset active segment type
    state.activeSegmentType = null;
    // Reset active segment ID
    state.activeSegmentId = null;
    // Reset update segment details
    state.segmentDataForUpdate = null;
  },
  /**
   * Appends custom segments to the state.
   * @function
   * @param state - The current state.
   * @param payload - The custom segments to append.
   */
  appendCustomSegments: (
    state: CustomerSegmentsState,
    payload: {
      customSegments?: AllCustomSegments;
      loading?: AJAXSTATUS;
      segmentType: string;
      hasNextPage?: boolean;
    },
  ) => {
    if (payload.customSegments && state.customSegments[payload.segmentType]) {
      // Check and filter if id is already in the list.
      payload.customSegments.allCustomSegmentIds =
        payload.customSegments.allCustomSegmentIds.filter(
          (v) =>
            !state.customSegments[payload.segmentType].allCustomSegments[v],
        );
      // Append new custom segment keys to the existing ones
      state.customSegments[payload.segmentType].allCustomSegmentIds = [
        ...state.customSegments[payload.segmentType].allCustomSegmentIds,
        ...payload.customSegments.allCustomSegmentIds,
      ];
      // Append new custom segments to the existing ones
      state.customSegments[payload.segmentType].allCustomSegments = {
        ...state.customSegments[payload.segmentType].allCustomSegments,
        ...payload.customSegments.allCustomSegments,
      };
    }
    if (payload.loading && state.customSegments[payload.segmentType]) {
      // Set loading status for custom segments
      state.customSegments[payload.segmentType].customSegmentsLoading =
        payload.loading;
    }
    if (
      payload.hasNextPage !== undefined &&
      state.customSegments[payload.segmentType]
    ) {
      // Set has next page status for custom segments
      state.customSegments[payload.segmentType].hasNextPage =
        payload.hasNextPage;
    }
  },
  /**
   * Unshift the Custom Segments segments state.
   *
   * @function
   * @param state - The current state.
   * @param payload - The state to set.
   */
  unshiftCustomSegment: (
    state: CustomerSegmentsState,
    payload: { segment: Segment; segmentType: string },
  ) => {
    // Adding segment data in object.
    state.customSegments[payload.segmentType].allCustomSegments[
      payload.segment.segmentId
    ] = payload.segment;

    // Checking if segment id is already included.
    if (
      !state.customSegments[payload.segmentType].allCustomSegmentIds.includes(
        payload.segment.segmentId,
      )
    ) {
      // unshifting the segment id in the array to render it.
      state.customSegments[payload.segmentType].allCustomSegmentIds.unshift(
        payload.segment.segmentId,
      );
    }
  },
  /**
   * Sets the customer segments state.
   * @function
   * @param {CustomerSegmentsState} state - The current state.
   * @param {Partial<CustomerSegmentsState>} payload - The state to set.
   */
  setSegments: (
    state: CustomerSegmentsState,
    payload: Partial<CustomerSegmentsState>,
  ) => {
    if (payload.allSegments !== undefined) {
      // Set all segments data
      state.allSegments = payload.allSegments;
    }
    if (payload.allSegmentTypes !== undefined) {
      // Set all segment types
      state.allSegmentTypes = payload.allSegmentTypes;
    }
    if (payload.customSegments !== undefined) {
      // Set all custom segments data
      state.customSegments = payload.customSegments;
    }
    if (payload.allSegmentsLoading !== undefined) {
      // Set loading status for all segments
      state.allSegmentsLoading = payload.allSegmentsLoading;
    }
    if (payload.activeSegmentType !== undefined) {
      // Set active segment type
      state.activeSegmentType = payload.activeSegmentType;
    }
    if (payload.activeSegmentId !== undefined) {
      // Set active segment ID
      state.activeSegmentId = payload.activeSegmentId;
    }
    if (payload.segmentDataForUpdate !== undefined) {
      // Set update segment details
      state.segmentDataForUpdate = payload.segmentDataForUpdate;
    }
  },
  /**
   * Sets the update segment details.
   * @function
   * @param state - The current state.
   * @param payload - The part of segmentDataForUpdate to set.
   */
  setUpdateSegmentDetails: (
    state: CustomerSegmentsState,
    payload: SegmentDataForUpdateI,
  ) => {
    state.segmentDataForUpdate = payload;
  },
  /**
   * Updates the update segment id.
   * @function
   * @param state - The current state.
   * @param payload - The part of payload to set.
   */
  updateUpdateSegmentDetails: (
    state: CustomerSegmentsState,
    payload: Partial<SegmentDataForUpdateI>,
  ) => {
    if (state.segmentDataForUpdate) {
      state.segmentDataForUpdate = {
        ...state.segmentDataForUpdate,
        ...payload,
      };
    }
  },
  /**
   * Removes the update segment details and makes it null.
   * @function
   * @param state - The current state.
   */
  removeUpdateSegmentDetails: (state: CustomerSegmentsState) => {
    state.segmentDataForUpdate = null;
  },
};
