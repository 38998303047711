import React from "react";
import styles from "./AgentSuggestion.module.scss";
import SearchBar from "src/components/SearchBar/SearchBar";
import SuggestionTable from "./Children/SuggestionTable/SuggestionTable";
const AgentSuggestion = () => {
  return (
    <div className={`w-100 p-2 p-md-3 ${styles.mainWrapper}`}>
      <div className="d-flex flex-column flex-md-row justify-content-between align-items-md-center w-100 ">
        <div className="d-flex">
          <div className={`cursor-pointer ${styles.backArrow}`} role="button">
            <span>
              <i className="fa-solid fa-arrow-left"></i>
            </span>
          </div>
          <div className="ms-2">
            <h3 className={`mb-0 ${styles.heading}`}>Agent suggestions</h3>
            <span className={`d-block ${styles.subHeading}`}>
              Agent can suggest answers for the questions. Approved answers will
              appear to customers with relevant query
            </span>
          </div>
        </div>
        <div className="d-flex mt-2 mt-md-0 flex-column flex-md-row">
          <SearchBar
            id=""
            className={`${styles.search} px-2 me-md-2`}
            inputClassName={`${styles.input}`}
            placeholder={`Search page`}
          />
        </div>
      </div>
      <div>
        <SuggestionTable />
      </div>
    </div>
  );
};

export default AgentSuggestion;
