import { PayloadAction } from "@reduxjs/toolkit";
import { initialState, RoutesColumn, TicketSliceState } from "./tickets.slice";
import log from "loglevel";
import { Column } from "src/hooks/useTicketViews/ticketViewsConfig";
import { arrayMoveIndex } from "src/utils/utils";

const setCurrentPage = (
  state: TicketSliceState,
  { payload }: { payload: number },
) => {
  state.currentPage = payload;
  return state;
};
const resetTickets = (state: TicketSliceState) => {
  state = initialState;
  return state;
};
const resetCachedTickets = (state: TicketSliceState) => {
  state.cachedTickets = {};
  return state;
};

const resetPagination = (state: TicketSliceState) => {
  state.currentPage = 0;
  state.totalTicketsCount = 0;
  state.totalPages = 0;
  return state;
};

const selectTicketById = (
  state: TicketSliceState,
  action: PayloadAction<string>,
) => {
  const ticketId = action.payload;
  state.bulkSelectedTicketsIds.push(ticketId);
  // log.debug(`selected ticket id: ${ticketId}`);
};
const removeTicketById = (
  state: TicketSliceState,
  action: PayloadAction<string>,
) => {
  const removeTicketId = action.payload;
  state.bulkSelectedTicketsIds = state.bulkSelectedTicketsIds.filter(
    (ticketId) => ticketId !== removeTicketId,
  );
  // log.debug(`removed ticket id: ${removeTicketId}`);
};
const selectAllTickets = (state: TicketSliceState) => {
  state.bulkSelectedTicketsIds = state.allTicketsIds;
  // log.debug(`selected all ${state.bulkSelectedTicketsIds.length} tickets`);
};
const removeAllTickets = (state: TicketSliceState) => {
  state.bulkSelectedTicketsIds = [];
  // log.debug(`removed all ${state.bulkSelectedTicketsIds.length} tickets`);
};

const resetMoreColumns = (state: TicketSliceState, { payload }: any) => {
  state.currColumnList[payload] = state.defaultColumnList[payload];
};
const resetAllMoreColumns = (state: TicketSliceState) => {
  state.currColumnList["ticket-view"] = state.defaultColumnList["ticket-view"];
  state.currColumnList["response-view"] =
    state.defaultColumnList["response-view"];
  state.currColumnList["customer-view"] =
    state.defaultColumnList["customer-view"];
};

const setMoreColsSearchValue = (state: TicketSliceState, { payload }: any) => {
  state.moreColsSearchValue = payload;
};

const setDefaultColumnList = (
  state: TicketSliceState,
  { payload }: { payload: RoutesColumn },
) => {
  state.defaultColumnList = payload;
};

const setAllColumnList = (
  state: TicketSliceState,
  { payload }: { payload: Column[] },
) => {
  state.allColumnList = payload;
};

const setCurrColumnList = (
  state: TicketSliceState,
  { payload }: { payload: RoutesColumn },
) => {
  let alreadyFilledColumnsList = { ...state.currColumnList };
  if (Object.keys(alreadyFilledColumnsList).length !== 0) {
    // if there is already some data, that may contain previously selected to show in more columns
    state.currColumnList = alreadyFilledColumnsList;
  } else {
    // there is no data in state.currColumnList, so set the values
    state.currColumnList = payload;
  }
};

const reorderCurrColumnList = (state: TicketSliceState, { payload }: any) => {
  const { viewType, srcI, desI } = payload;
  const colList = [...state.currColumnList[viewType]];
  const firstItem: any = colList.shift();
  colList.splice(desI, 0, colList.splice(srcI, 1)[0]);
  colList.unshift(firstItem);
  state.currColumnList[viewType] = colList;
};

const checkUncheckColumn = (state: TicketSliceState, { payload }: any) => {
  const { colId, colInd, viewType } = payload;
  const currColIndex = state.currColumnList[viewType].findIndex(
    (currCol) => currCol.id === colId,
  );
  // log.debug({ currColIndex });
  if (currColIndex !== -1) {
    const currColCheckboxState =
      state.currColumnList[viewType][currColIndex].show;
    state.currColumnList[viewType][currColIndex].show = !currColCheckboxState;
  }
  if (colInd != undefined) {
    const colAtInd = { ...state.currColumnList[viewType][currColIndex] };
    state.currColumnList[viewType].splice(currColIndex, 1);
    state.currColumnList[viewType].splice(colInd, 0, colAtInd);
  }
};

const checkUncheckColumnIds = (state: TicketSliceState, { payload }: any) => {
  const { ticketColumns, viewType } = payload;
  let arr = [...state.currColumnList[viewType]];
  ticketColumns.forEach((col: any, i: number) => {
    if ((col + "").includes("::")) {
      const c = (col + "").split("::");
      const ind1 = parseInt(c[1]);
      const ind = arr.findIndex((val) => val.id == parseInt(c[0]));
      arr = arrayMoveIndex({ arr, oldIndex: ind, newIndex: ind1 });
      ticketColumns[i] = parseInt(c[0]);
    } else {
      ticketColumns[i] = parseInt(col);
    }
  });
  arr.forEach((col, i) => {
    if (!col.hideInMoreCols) {
      if (ticketColumns.includes(col.id)) {
        arr[i].show = true;
      } else {
        arr[i].show = false;
      }
    }
  });
  state.currColumnList[viewType] = arr;
};
const checkAllViewSelectedCols = (
  state: TicketSliceState,
  { payload }: any,
) => {
  for (const viewType in payload) {
    checkUncheckColumnIds(state, {
      payload: { ticketColumns: payload[viewType], viewType: viewType },
    });
  }
};
const updateTicketCellDataByKey = (
  state: TicketSliceState,
  action: PayloadAction<{ ticketId: string; colKey: string; cellData: any }>,
) => {
  const { ticketId, colKey, cellData } = action.payload;
  state.allTickets[ticketId][colKey] = cellData;
};

const setFilterSearchString = (
  state: TicketSliceState,
  action: PayloadAction<string>,
) => {
  state.filterSearchString = action.payload;
};

const setCurrentView = (
  state: TicketSliceState,
  { payload }: { payload: string },
) => {
  state.currentView = payload;

  return state;
};

const resetCurrentView = (state: TicketSliceState) => {
  state.currentView = "";
  return state;
};

//reducer function to reset bulk action filter
const resetBulkActionFilters = (state: TicketSliceState) => {
  state.bulkActionFilters = {
    selectedAllTickets: false, //resetting the selectedAllTickets state to false
    showSelectAllTicketModal: false, //closing the "select all ticket modal"
  };

  return state;
};

//reducer function to set bulk action filter
const setBulkActionFilters = (
  state: TicketSliceState,
  {
    payload,
  }: {
    payload: {
      selectedAllTickets?: boolean;
      showSelectAllTicketModal?: boolean;
    };
  },
) => {
  //updating bulk action filter with param value
  if (payload.selectedAllTickets !== undefined) {
    state.bulkActionFilters.selectedAllTickets = payload.selectedAllTickets;
  }

  if (payload.showSelectAllTicketModal !== undefined) {
    state.bulkActionFilters.showSelectAllTicketModal =
      payload.showSelectAllTicketModal;
  }

  return state;
};

const setBulkUpdatedTicketCount = (
  state: TicketSliceState,
  { payload }: { payload: { count: number } },
) => {
  state.bulkUpdatedTicketCounts = payload.count;
  return state;
};

export default {
  setCurrentPage,
  resetTickets,
  resetPagination,
  resetCachedTickets,
  selectTicketById,
  removeTicketById,
  selectAllTickets,
  removeAllTickets,
  resetMoreColumns,
  resetAllMoreColumns,
  setMoreColsSearchValue,
  setDefaultColumnList,
  setAllColumnList,
  setCurrColumnList,
  checkUncheckColumn,
  reorderCurrColumnList,
  updateTicketCellDataByKey,
  setFilterSearchString,
  setCurrentView,
  resetCurrentView,
  checkUncheckColumnIds,
  checkAllViewSelectedCols,
  resetBulkActionFilters,
  setBulkActionFilters,
  setBulkUpdatedTicketCount,
};
