import React, { useState } from "react";
import styles from "./EnableDisableType.module.scss";
interface Props {
  leadName: string;
  onChange: Function;
  value: boolean;
}
const EnableDisableType = ({ leadName, onChange, value }: Props) => {
  const [enable, setEnable] = useState(value); // changed
  return (
    <>
      <div
        className={`d-flex flex-column flex-lg-row flex-lg-start justify-content-start justify-content-lg-between align-items-start  align-items-lg-center ${styles.enableWrapper} py-3 px-3 mb-3`}
      >
        <div>
          <p className={`mb-0 ${styles.lead}`}>{leadName} </p>
        </div>
        <div>
          <div className="form-check form-switch  mt-2 mt-lg-0">
            <input
              className={`form-check-input ${styles.switch}`}
              type="checkbox"
              role="switch"
              id="flexSwitchCheckChecked"
              onClick={() => {
                setEnable(!enable);
                onChange(!enable); // changed
              }}
              checked={value}
              onChange={() => {}}
            />
            <label
              className={`form-check-label ${styles.toggleText}`}
              htmlFor="flexSwitchCheckChecked"
            >
              {enable ? "Disable" : "Enable"}
            </label>
          </div>
        </div>
      </div>
    </>
  );
};
export default EnableDisableType;
