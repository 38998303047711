import { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { GetOrderViewModel } from "src/services/ShopifySidebar/SingleOrder/BasicOrder/getOrderViewModel";
import { useAppSelector } from "src/store/store";
import EditCustomerNote from "./EditCustomerNote/EditCustomerNote";
import styles from "./Notes.module.scss";

function Notes() {
  const {
    loading,
    error,
    getOrderViewModel: getOrder,
    updateOrder,
  } = useAppSelector((state) => state.singleOrder);

  const [showEditNoteModal, setShowEditNoteModal] = useState<boolean>(false);

  const handleOnShowEditNote = () => {
    setShowEditNoteModal(true);
  };

  const handleOnHideEditNote = () => {
    setShowEditNoteModal(false);
  };
  function renderData(order: GetOrderViewModel) {
    return (
      <>
        <div className={`${styles.Notes}  p-3 mb-3 d-flex flex-column  ms-3 `}>
          <div className="d-flex flex-row justify-content-between">
            <p className={`mb-0 ${styles.lead}`}>Notes</p>

            {/* <div
              className="link-primary"
              role="button"
              onClick={() => {
                // we don't need edit now
                // handleOnShowEditNote();
              }}
            >
              Edit
            </div> */}
          </div>

          <div className={`mt-4 ${styles.lead}`}>
            {updateOrder &&
            updateOrder?.customerOrderNotesUpdateAjaxStatus === "pending" ? (
              <div className={`m-auto text-center ${styles.spinnerDiv}`}>
                <Spinner
                  className={`m-auto text-center`}
                  animation="border"
                  color="white"
                  size="sm"
                />
              </div>
            ) : (
              <p>
                {order?.customerNotes && order?.customerNotes?.length > 0
                  ? order?.customerNotes
                  : "No notes from customer"}
              </p>
            )}
          </div>
        </div>
        {showEditNoteModal === true ? (
          <>
            <Modal
              show={showEditNoteModal}
              onHide={handleOnHideEditNote}
              className={`shadow-sm p-3 mb-5  rounded ${styles.editNoteModal}`}
              backdropClassName={styles.backdropEditNoteModal}
              dialogClassName={styles.modalDialogEditNoteModal}
              contentClassName={styles.modalContentEditNoteModal}
              centered={true}
              enforceFocus={true}
            >
              <EditCustomerNote onHide={handleOnHideEditNote} />
            </Modal>
          </>
        ) : (
          <></>
        )}
      </>
    );
  }

  return <div>{!loading && getOrder && renderData(getOrder)}</div>;
}

export default Notes;
