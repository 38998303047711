import TrackerRow from "./Children/TrackerRow/TrackerRow";
import styles from "./TrackerTable.module.scss";
const TrackerTable = () => {
  return (
    <>
      <div className={`${styles.tableHead}`}>
        <div className={`d-flex ${styles.widthSet1}`}>
          <span className={`${styles.headerName}`}>
            ACTIVE
            <span className={`ms-2 ${styles.downArrow}`}>
              <i className="fa-solid fa-sort-down"></i>
            </span>
          </span>
        </div>
        <div className={`d-flex  ${styles.widthSet2}`}>
          <span className={`${styles.headerName}`}>
            NAME
            <span className={`ms-2 ${styles.downArrow}`}>
              <i className="fa-solid fa-sort-down"></i>
            </span>
          </span>
        </div>
        <div className={`d-flex justify-content-center ${styles.widthSet3}`}>
          <span className={`${styles.headerName}`}>SEE IN</span>
        </div>
      </div>
      <div>
        <TrackerRow />
      </div>
    </>
  );
};
export default TrackerTable;
