/**
 * This file is the component file.
 *
 * @author Yash Aditya
 */

import NotificationTime, {
  NotificationTimeNormal,
} from "./children/NotificationTime/NotificationTime";
import styles from "../../AgentNotification.module.scss";
import XTimes from "./children/XTimes/XTimes";
import NormalSelect from "./children/NormalSelect/NormalSelect";
import { useFreqSettings } from "../../hooks/useFreqSettings";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getNotifySettingService } from "src/services/Settings/AgentNotifications/getNotifySetting.service";
import { AJAXSTATUS } from "src/globals/constants";
import Loader from "src/components/Loader";
import {
  NotificationFrequencyData,
  addOrUpdateSettingService,
} from "src/services/Settings/AgentNotifications/addOrUpdateSetting.service";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { deepCheckEqualObject } from "src/utils/utils";
import useNotifySetPermission from "../../hooks/useNotifySetPermission";
import { useQuery } from "@tanstack/react-query";

// Custom hook for managing frequency settings
const useSetFrequency = () => {
  // Destructuring values from the hook
  const { freqSettings, dispatch } = useFreqSettings();
  const [freqStatus, setFreqStatus] = useState("pending" as AJAXSTATUS);
  const [freqDataNotFilled, setFreqDataNotFilled] = useState(false);

  const isEdited = useMemo(() => {
    return !deepCheckEqualObject(
      freqSettings.notifySettingsData ?? {},
      freqSettings.notifySettingsOriginalData ?? {},
    );
  }, [freqSettings]);

  // const fetchNotifySet = useCallback(() => {
  //   setFreqStatus("pending");
  //   getNotifySettingService()
  //     .then((res) => {
  //       setFreqStatus("fulfilled");
  //       dispatch("setNotifySettingsData", res);
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //       setFreqStatus("rejected");
  //     });
  // }, [setFreqStatus, dispatch]);

  //Call api only on component load instead of everytime opened the dropdown
  const { data: notifySettings, status: notifySettingsStatus } = useQuery(
    ["getNotifySettings"],
    {
      queryFn: getNotifySettingService,
      staleTime: 600000,
      cacheTime: 600000,
      // Handle error during data retrieval
      onError: (err) => {
        console.error(err);
        setFreqStatus("rejected");
      },
    },
  );

  useMemo(() => {
    if (notifySettingsStatus === "loading") {
      setFreqStatus("pending");
    } else if (notifySettingsStatus === "success") {
      setFreqStatus("fulfilled");
    } else if (notifySettingsStatus === "error") {
      setFreqStatus("rejected");
    }
  }, [notifySettingsStatus]);

  /**
   * Update store data with query
   */
  useEffect(() => {
    // Check if we have the query api data
    if (notifySettings) {
      // Dispatch the notify settings data update action
      dispatch("setNotifySettingsData", notifySettings);
      setFreqStatus("fulfilled");
    }
  }, [dispatch, notifySettings]);

  // Memoizing selected notification frequency settings
  const selectedSettings = useMemo(() => {
    if (freqSettings.notifySettingsData) {
      return { ...freqSettings.notifySettingsData };
    } else {
      return null;
    }
  }, [freqSettings]);

  // Memoizing the selected frequency type
  const selectedFreqType = useMemo(() => {
    const ft = freqSettings.freqTypes.find(
      (value) => value.id + "" === selectedSettings?.frequencyTypeId + "",
    );
    if (ft) {
      return { ...ft };
    } else {
      return null;
    }
  }, [freqSettings, selectedSettings]);

  // Memoizing frequency type options for dropdown
  const freqTypeOptions = useMemo(() => {
    return freqSettings.freqTypes.map((value) => {
      return {
        id: value.id + "",
        name: value.label,
      };
    });
  }, [freqSettings]);

  // Memoizing the selected frequency type value for dropdown
  const freqTypeValue = useMemo(() => {
    return selectedSettings ? [selectedSettings.frequencyTypeId + ""] : [];
  }, [selectedSettings]);

  // Handling frequency type change
  const handleFreqTypeChange = useCallback(
    (id: Array<string>) => {
      // Extract the first element from the array, which represents the frequency type ID
      const freqTypeId = id[0];
      if (freqTypeId) {
        // If a valid frequency type ID exists, dispatch an action to update notify settings
        dispatch("updateNotifySettingsFreqId", freqTypeId);
      }
    },
    [dispatch],
  );

  // Handle X times a day change
  const handleXTimesADayChange = useCallback(
    (value: number) => {
      // Dispatch an action to update notify settings with the new value for X times a day
      dispatch("updateNotifySettingsFreqTimeValues", { xNoOfTimes: value });
    },
    [dispatch],
  );

  // Handle week day change
  const handleWeekDayChange = useCallback(
    (value: Array<string>) => {
      // Dispatch an action to update notify settings with the selected week day
      dispatch("updateNotifySettingsFreqTimeValues", { weekDayKey: value[0] });
    },
    [dispatch],
  );

  // Handle date change
  const handleDateChange = useCallback(
    (value: Array<string>) => {
      // Dispatch an action to update notify settings with the selected date
      dispatch("updateNotifySettingsFreqTimeValues", { date: value[0] });
    },
    [dispatch],
  );

  // Handle month change
  const handleMonthChange = useCallback(
    (value: Array<string>) => {
      // Dispatch an action to update notify settings with the selected month
      dispatch("updateNotifySettingsFreqTimeValues", { monthKey: value[0] });
    },
    [dispatch],
  );

  // Handle save action
  const handleSave = useCallback(() => {
    if (selectedSettings) {
      let notFilled = false;

      // Create an object to store notification frequency data based on selected settings
      const params: NotificationFrequencyData = {
        frequencyTypeId: selectedSettings.frequencyTypeId,
        type: selectedSettings.type,
        timeValues:
          selectedFreqType?.handledByBackend !== true
            ? selectedSettings.timeValues?.map((value) => {
                // Initialize an empty object for each time value
                let retData: typeof value = {};

                // Check if time zone ID exists, and add it to the data
                if (value.timeZoneId) {
                  retData.timeZoneId = value.timeZoneId;
                }

                // Check frequency type to determine additional properties
                if (
                  (value.xNoOfTimes ?? 1) > 7 &&
                  (selectedFreqType?.fixedNoOfTimes ?? 0) === 0
                ) {
                  // If the frequency is more than 7 times a day, add xNoOfTimes to the data
                  retData.xNoOfTimes = value.xNoOfTimes;
                } else if (value.times?.length) {
                  const sliceLength = selectedFreqType?.fixedNoOfTimes
                    ? selectedFreqType.fixedNoOfTimes
                    : value.xNoOfTimes ?? 1;
                  // If there are specific times defined, add times to the data
                  retData.times = value.times.slice(0, sliceLength);
                  if (retData.times.length < sliceLength) {
                    notFilled = true;
                  }
                  retData.times.forEach((t) => {
                    if (!(t && t.time && t.timeZoneId)) {
                      notFilled = true;
                    }
                  });
                } else {
                  // If required fields are not filled, set notFilled flag to true
                  notFilled = true;
                }

                // Handle specific properties based on the frequency type
                if (selectedSettings.type === "weekly") {
                  if (value.weekDayKey) {
                    // If weekDayKey exists, add it to the data
                    retData.weekDayKey = value.weekDayKey;
                  } else {
                    // If required fields are not filled, set notFilled flag to true
                    notFilled = true;
                  }
                } else if (selectedSettings.type === "monthly") {
                  if (value.date) {
                    // If date exists, add it to the data
                    retData.date = value.date;
                  } else {
                    // If required fields are not filled, set notFilled flag to true
                    notFilled = true;
                  }
                } else if (selectedSettings.type === "yearly") {
                  if (value.date && value.monthKey) {
                    // If date and monthKey exist, add them to the data
                    retData.date = value.date;
                    retData.monthKey = value.monthKey;
                  } else {
                    // If required fields are not filled, set notFilled flag to true
                    notFilled = true;
                  }
                }

                // Return the constructed data for the current time value
                return retData;
              })
            : [],
      };

      // Checking time values are present or not if not handled by backend
      if (
        params.timeValues?.length === 0 &&
        selectedFreqType?.handledByBackend !== true
      ) {
        notFilled = true;
      }

      if (notFilled) {
        setFreqDataNotFilled(true);
        // Showing warning toast
        pushTheToast({
          type: "warning",
          text: "Please select all settings!",
          position: "top-right",
        });
      } else {
        setFreqDataNotFilled(false);
        // Set frequency status to "pending" to indicate saving is in progress
        setFreqStatus("pending");

        // Call the addOrUpdateSettingService with the parameters
        addOrUpdateSettingService(params)
          .then((res) => {
            // Dispatch an action to update the notify settings data in the state
            dispatch("setNotifySettingsData", params);

            // Set frequency status to "fulfilled" to indicate successful save
            setFreqStatus("fulfilled");
          })
          .catch((err) => {
            // Log any errors that occur during the save process
            console.error(err);

            // Set frequency status to "fulfilled" as we want to show the edited data
            setFreqStatus("fulfilled");

            // Showing error toast
            pushTheToast({
              type: "danger",
              text: "Error in saving settings!",
              position: "top-right",
            });
          });
      }
    }
  }, [dispatch, selectedSettings, selectedFreqType]);

  // Commented as api is calling each time dropdown is opened
  // useEffect(() => {
  //   fetchNotifySet();
  // }, [false]);

  // Returning the memoized values
  return {
    selectedSettings,
    selectedFreqType,
    freqSettings,
    freqTypeOptions,
    freqTypeValue,
    freqStatus,
    freqDataNotFilled,
    isEdited,
    handleFreqTypeChange,
    // fetchNotifySet,
    handleXTimesADayChange,
    handleWeekDayChange,
    handleDateChange,
    handleMonthChange,
    handleSave,
  };
};

// Main component for setting frequency
function SetFrequency() {
  // Destructuring values from the custom hook
  const {
    selectedFreqType,
    selectedSettings,
    freqSettings,
    freqTypeOptions,
    freqTypeValue,
    freqStatus,
    isEdited,
    handleFreqTypeChange,
    handleXTimesADayChange,
    handleWeekDayChange,
    handleDateChange,
    handleMonthChange,
    handleSave,
  } = useSetFrequency();
  const notifyPermission = useNotifySetPermission();

  /* Display error message if data is rejected */
  if (freqStatus === "rejected") {
    return (
      <div className="d-flex align-items-center justify-content-center w-100">
        <div className="text-danger text-center">Error loading content...</div>
      </div>
    );
  }

  /* Display loader if data is still pending */
  if (freqStatus === "pending") {
    return (
      <div className="d-flex align-items-center justify-content-center w-100">
        <Loader
          className="pt-0 mt-0"
          imgClassName="pt-0 mt-0"
        />
      </div>
    );
  }

  // JSX for rendering the component
  return (
    <div className="px-2 pt-2 pb-3">
      <div
        className={`py-2 d-flex flex-column flex-md-row align-items-md-center `}
      >
        {/* Select setting type dropdown */}
        <NormalSelect
          options={freqTypeOptions}
          value={freqTypeValue}
          onChange={handleFreqTypeChange}
          isMulti={false}
          disabled={notifyPermission !== "edit"}
        />
        {selectedFreqType?.handledByBackend !== true && (
          <>
            <>
              {selectedSettings?.type === "weekly" && (
                <>
                  {/* Select weekdays dropdown */}
                  <NormalSelect
                    options={freqSettings.weekDays.map((value) => {
                      return {
                        id: value.key + "",
                        name: value.label,
                      };
                    })}
                    value={(selectedSettings.timeValues ?? [])
                      .filter((v) => v.weekDayKey)
                      .map((value) => {
                        return value.weekDayKey + "";
                      })}
                    onChange={handleWeekDayChange}
                    isMulti={false}
                    disabled={notifyPermission !== "edit"}
                  />
                </>
              )}
              {selectedSettings?.type === "yearly" && (
                <>
                  {/* Select weekdays dropdown */}
                  <NormalSelect
                    options={freqSettings.months.map((value) => {
                      return {
                        id: value.key + "",
                        name: value.label,
                      };
                    })}
                    value={(selectedSettings.timeValues ?? [])
                      .filter((v) => v.monthKey)
                      .map((value) => {
                        return value.monthKey + "";
                      })}
                    onChange={handleMonthChange}
                    isMulti={false}
                    disabled={notifyPermission !== "edit"}
                  />
                </>
              )}
              {(selectedSettings?.type === "monthly" ||
                selectedSettings?.type === "yearly") && (
                <>
                  {/* Select weekdays dropdown */}
                  <NormalSelect
                    options={Array.from(
                      { length: 31 },
                      (_, index) => index + 1,
                    ).map((value) => {
                      return {
                        id: value + "",
                        name:
                          value +
                          (value > 27 ? " OR (Last Date of Month)" : ""),
                      };
                    })}
                    value={(selectedSettings.timeValues ?? [])
                      .filter((v) => v.date)
                      .map((value) => {
                        return value.date + "";
                      })}
                    onChange={handleDateChange}
                    isMulti={false}
                    disabled={notifyPermission !== "edit"}
                  />
                </>
              )}
            </>
            {/* Additional components based on selected frequency type */}
            {(selectedFreqType?.fixedNoOfTimes ?? 0) === 0 && (
              <XTimes
                value={(selectedSettings?.timeValues ?? [])[0]?.xNoOfTimes ?? 1}
                onChange={handleXTimesADayChange}
                disabled={notifyPermission !== "edit"}
              />
            )}
            {selectedFreqType?.fixedNoOfTimes === 1 && (
              <NotificationTimeNormal
                times={
                  ((selectedSettings?.timeValues ?? [])[0]?.times ?? [])[0]
                }
                serialNumber={0}
                disabled={notifyPermission !== "edit"}
              />
            )}
          </>
        )}
      </div>
      {selectedFreqType?.handledByBackend !== true && (
        <>
          {/* Rendering based on the selected frequency type */}
          {((selectedFreqType?.fixedNoOfTimes ?? 0) > 1 ||
            ((selectedFreqType?.fixedNoOfTimes ?? 0) === 0 &&
              ((selectedSettings?.timeValues ?? [])[0]?.xNoOfTimes ?? 1) <
                8)) && (
            <div className={`p-2 mt-2 ${styles.reportWrap}`}>
              <p className={`${styles.setHead}`}>Set timings</p>
              <div className="d-flex flex-column flex-md-row align-items-md-center flex-wrap">
                {/* Mapping through time values and rendering NotificationTime component */}
                {Array.from(
                  {
                    length: selectedFreqType?.fixedNoOfTimes
                      ? selectedFreqType.fixedNoOfTimes
                      : (selectedSettings?.timeValues ?? [])[0]?.xNoOfTimes ??
                        1,
                  },
                  (_, index) => index + 1,
                ).map((v, index) => {
                  const value = (selectedSettings?.timeValues ?? [])[0]?.times;
                  return (
                    <div key={index}>
                      <NotificationTime
                        times={value ? value[index] : undefined}
                        serialNumber={index}
                        disabled={notifyPermission !== "edit"}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </>
      )}
      {isEdited && notifyPermission === "edit" && (
        <div className={`d-flex mt-2 justify-content-start`}>
          <button
            className={`${styles.saveFreqBtn}`}
            onClick={handleSave}
          >
            Save
          </button>
        </div>
      )}
    </div>
  );
}

export default SetFrequency;
