import { getLocalLoginCustomerInfo } from "src/services/LocalStorage/loginData";
import { fetchTimeZoneList, ITimeZoneListParams, ITimeZoneListRes } from "src/services/Settings/Timezone/fetchTimeZoneList";
import { deleteProfileImageUser } from "src/services/Settings/Users/deleteProfileImageUser";
import { CurrentUser, getCurrentUser } from "src/services/Settings/Users/getCurrentUser";
import { getUser } from "src/services/Settings/Users/getUser";
import { getUserRoles } from "src/services/Settings/Users/getUserRoles";
import { updateProfileImageUser } from "src/services/Settings/Users/updateProfileImageUser";
import {
  updateUser,
  UpdateUserParams
} from "src/services/Settings/Users/updateUser";
import { RootState } from "src/store/store";

async function fetchUserThunk(
  payload: undefined,
  { getState }: { getState: Function }
) {
  const currentState: RootState = getState();
  let roles = currentState.profileSettings.userRoles;
  if (roles.rolesIds.length === 0) {
    roles = await getUserRoles();
  }
  const user = await getCurrentUser();
  return { user, roles };
}

interface UUParams extends UpdateUserParams {
  callback?: (user: CurrentUser) => void;
}

async function updateUserThunk(
  { callback, ...payload }: UUParams,
  { getState }: { getState: Function }
) {
  const update = await updateUser(payload);
  const user = await getCurrentUser();
  if (callback) {
    setTimeout(() => {
      callback(user);
    }, 0);
  }
  return user;
}

async function updateUserProfilePicThunk(
  payload: undefined,
  { getState }: { getState: Function }
) {
  const currentState: RootState = getState();
  if (currentState.profileSettings.userData === null) {
    throw "User id not available!";
  }
  const imageData = await updateProfileImageUser({
    userId: currentState.profileSettings.userData.userId
  });
  return imageData;
}

async function deleteUserProfilePicThunk(
  payload: undefined,
  { getState }: { getState: Function }
) {
  const currentState: RootState = getState();
  if (currentState.profileSettings.userData === null) {
    throw "User id not available!";
  }
  const imageData = await deleteProfileImageUser({
    userId: currentState.profileSettings.userData.userId
  });
  return imageData;
}

async function fetchTimeZoneListDataThunk(
  payload: undefined,
  {getState}: {getState: Function}
){
  const currentState: RootState = getState();
  let params: ITimeZoneListParams = {
    searchTerm: currentState.profileSettings.timeZoneListSearchTerm,
    // start: currentState.profileSettings.timezoneListData.data.length,
    start: 0,
    // limit: currentState.profileSettings.fetchTimeZoneListLimit,
    limit: 800,
  }

  const timeZoneListData: ITimeZoneListRes = await fetchTimeZoneList(params);

  return timeZoneListData;
}

export default {
  fetchUserThunk,
  updateUserThunk,
  updateUserProfilePicThunk,
  deleteUserProfilePicThunk,
  fetchTimeZoneListDataThunk
};
