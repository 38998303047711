import React, { useEffect, useMemo, useRef, useState } from "react";
import styles from "./TemplateCategory.module.scss";
import SearchBar from "src/components/SearchBar/SearchBar";
import { NavLink } from "react-router-dom";
import {
  IGetCategory,
  getViewTemplateCategories,
  searchCategory,
} from "src/services/Templates/viewTemplateCategories";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import InfiniteScroll from "src/components/InfiniteScroll";
import CategoryItem from "./CategoryItem/CategoryItem";
import Loader from "src/components/Loader";
import { useSearchTemplates } from "src/components/ViewTemplateV2/Children/CustomHook/useSearchTemplates";
import { useGetTemplateCategories } from "src/components/ViewTemplateV2/Children/CustomHook/useGetTemplateCategories";

const TemplateCategory = ({
  setTemplates,
  selectedCategory,
  setSelectedCategory,
}: {
  setTemplates: any;
  selectedCategory: any;
  setSelectedCategory: any;
}) => {
  const [searchText, setSearchText] = useState<string>("");
  const [searchInput, setSearchInput] = useState<string>("");

  const filters = useMemo(() => {
    return {
      start: 0,
      limit: 25,
      searchTerm: searchText,
    };
  }, [searchText]);

  const {
    status,
    categoryIds,
    categories,
    hasNextPage,
    fetchNextPage,
    fetchStatus,
    isLoading,
  } = useGetTemplateCategories(filters);

  interface ITemplateCategory {
    id: number;
    name: string;
    subCategoryExists: boolean;
  }

  function selectedCategoryHandler(category: ITemplateCategory) {
    if (category.id === undefined) {
      return;
    }
    setTemplates([]);
    // setShowLoader(true);
    setSelectedCategory(category);
  }

  return (
    <div className={`pe-3 ${styles.sidebarWrapper}`} id="templateCategory">
      <p className={`mb-0 mt-1 ${styles.templateHead}`}>Template Category:</p>
      <SearchBar
        className={`w-100 ${styles.search} px-1 mt-1`}
        inputClassName={` ${styles.input}`}
        placeholder={`Search category`}
        value={searchInput}
        onChange={(e: any) => {
          // setSearchDone(false);
          setSearchInput(e.target.value);
        }}
        onSearch={(value: string) => setSearchText(value)}
        id="templateSearch"
      />
      <div className="mt-3">
        {isLoading === true && categoryIds.length === 0 ? (
          <>
            <Loader />
          </>
        ) : (
          <>
            <InfiniteScroll
              style={{ maxHeight: "46vh", overflowX: "hidden" }}
              loadMore={fetchNextPage}
              hasMore={hasNextPage}
              initialDataLoaded={true}
            >
              {categoryIds.map((categoryId) => {
                const category = categories[categoryId];
                return (
                  <CategoryItem
                    category={category}
                    selectedCategoryHandler={selectedCategoryHandler}
                    selectedCategory={selectedCategory}
                  />
                );
              })}
            </InfiniteScroll>
          </>
        )}
      </div>
    </div>
  );
};

export default TemplateCategory;
