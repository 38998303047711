import {
  initialState,
  LiveChatHistorySlice,
} from "./liveChatHistory.declarations";

const liveChatHistoryReducer = {
  resetLiveChatHistory: () => {
    return { ...initialState };
  },
  resetLiveChatHistoryMessages: (state: LiveChatHistorySlice) => {
    state.selectedChatMessages = initialState.selectedChatMessages;
    state.fetchSelectedMessagesAJAXStatus = "idle";
  },
  setSelectedCustomerId: (
    state: LiveChatHistorySlice,
    { payload }: { payload: number | string }
  ) => {
    state.selectedCustomerId = payload;
  },
  setSelectedChatId: (
    state: LiveChatHistorySlice,
    { payload }: { payload: number | string }
  ) => {
    state.selectedChatId = payload;
  },
  setSelectedInternalChatId: (
    state: LiveChatHistorySlice,
    { payload }: { payload: number | string }
  ) => {
    state.selectedInternalChatId = payload;
  },
};

export default liveChatHistoryReducer;
