import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { channelSubscriber } from "src/globals/pusher";
import {
  getLiveMessageRecEvent,
  LiveMessageRecEventCB,
} from "src/pusherServices/livechat/messageEvents";
import {
  GetAssignToAgentParam,
  getAssignToAgents,
} from "src/services/LiveChat/messageExchange/getAssignToAgents";
import {
  getAllMessages,
  GetLiveMessagePayload,
  Message,
} from "src/services/LiveChat/messageExchange/getMessages";
import {
  SendLiveMessagePayload,
  sendLiveMessages,
} from "src/services/LiveChat/messageExchange/sendMessage";
import { RootState } from "src/store/store";
import { AjaxError } from "src/types/AjaxError";
import { actions } from "../chatWidExchCust/chatWidExchCust.slice";
import {
  appendMessageBeforeSending,
  updateTryAgainMessage,
} from "src/store/slices/liveChatSetting/chatWidExchMsg/chatWidExchMsg.slice";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { formatDateTimeString } from "src/utils/dateLibrary";
import { FreePlanError, FreePlanErrorMessage } from "src/globals/constants";

export interface FetchMsgDataP {
  chatRequestId: string | number;
  chatStatus: "missed" | "live" | "archived";
  scrollDirection?: "old" | "new";
  liveMsgCallback?: LiveMessageRecEventCB;
  callback?: () => void;
  getActiveCustomer?: boolean;
}

let msgController: any = [];

const getMsgController = () => msgController;

const fetchMsgDataThunk = async (
  {
    liveMsgCallback,
    callback,
    getActiveCustomer = false,
    ...payload
  }: FetchMsgDataP,
  {
    getState,
    dispatch,
    rejectWithValue,
  }: { getState: Function; dispatch: Function; rejectWithValue: any },
) => {
  let errRej = false;

  msgController.map((c: any) => {
    c.abort();
  });

  const controller = new AbortController();
  msgController.push(controller);

  try {
    const currentState: RootState = getState();

    const messageIdList = currentState.chatWidExhMsg.messageIdList;
    // Finding last message id based on scroll direction.
    const lastMessageId =
      payload.scrollDirection === "old"
        ? messageIdList[messageIdList.length - 1]
        : payload.scrollDirection === "new"
          ? messageIdList[0]
          : undefined;

    const messagePayload: GetLiveMessagePayload = {
      lastMessageId,
      limit: currentState.chatWidExhMsg.fetchMessageLimit,
      chatRequestId: payload.chatRequestId,
      scrollDirection: payload.scrollDirection,
      tabContext: payload.chatStatus,
      sortBy: "desc",
      getActiveCustomer:
        getActiveCustomer ||
        currentState.chatWidExhMsg.activeCustomerInfo === null,
    };

    // Mentions are not applied when search is there
    if (
      currentState.chatWidExchCust.filters.showMentions &&
      currentState.chatWidExhMsg.messageIdList.length === 0 &&
      currentState.chatWidExchCust.filters.searchValue.trim() === ""
    ) {
      messagePayload.filters = {
        limitToUnreadMention: true,
      };
    }

    const data = await getAllMessages(messagePayload, controller.signal);

    // Need to make it true if limitToUnreadMention flag is not true for first fetch.
    if (
      payload.scrollDirection == undefined &&
      messagePayload.filters?.limitToUnreadMention !== true
    ) {
      data.metaData.limitReachedNew = true;
    }

    if (window.location.pathname.split("/")[4] === "all") {
      errRej = true;
      throw "Canceled";
    }

    if (data.activeCustomerInfo) {
      dispatch(
        actions.setCustomerData({
          customer: data.activeCustomerInfo,
          checkForExistingCust: true,
        }),
      );
      //   const msgCh = channelSubscriber(data.customerInfo.channelName);
      //   if (msgCh && liveMsgCallback) {
      //     getLiveMessageRecEvent(msgCh, liveMsgCallback);
      //   }
    }
    setTimeout(() => {
      if (callback) {
        callback();
      }
    }, 0);
    return { data, scrollDirection: payload.scrollDirection };
  } catch (error) {
    if (axios.isCancel(error) || errRej) {
      return rejectWithValue(true);
    } else {
      let ajaxError: AjaxError = {
        message: (error as Error).message,
      };
      // log.warn(ajaxError);
      throw ajaxError;
    }
  } finally {
    msgController = getMsgController().filter((c: any) => c !== controller);
  }
};

export interface SendLiveMsgP extends SendLiveMessagePayload {
  callback?: () => void;
  tryAgain?: boolean;
  attachments?: Array<any>;
}

const sendLiveMsgThunk = async (
  { callback, tryAgain, ...payload }: SendLiveMsgP,
  {
    dispatch,
    getState,
    rejectWithValue,
  }: { dispatch: Function; getState: Function; rejectWithValue: any },
) => {
  const currentState: RootState = getState();
  // Create a new Date object representing the current date and time in user timezone
  let currentDateTime = new Date().toLocaleString("en-US", {
    timeZone: currentState.globals.currentUserData?.userTimezone,
    hour12: false,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
  // Format the obtained date and time string using a custom function
  currentDateTime = formatDateTimeString(currentDateTime);
  //  This is for appending the message to the list before sending it to the server
  const newMessageToAppend: Message = {
    messageId: payload.uuid,
    messageText: payload.message,
    sentBy: {
      id: currentState.globals.currentUserData?.userId,
      name: currentState.globals.currentUserData?.firstName,
      email: currentState.globals.currentUserData?.email,
      imageURL: currentState.globals.currentUserData?.profileImageUrl,
    },
    sendTime: new Date().toString(),
    sendTimeGmt: new Date().toString(),
    isEvent: false,
    sentByType: "out",
    senderType: "Agent",
    isInternalNote: payload.messageType === "note",
    messageType: payload.messageType,
    messageUuid: payload.uuid,
    messageStatus: "pending",
    attachments: payload.attachments,
    messageSeenStatus: false, // this is for the message seen status of the agent
    messageChannel: payload.channel,
    to: {
      name: "",
      email: payload.to ?? "",
    },
  };

  // Checking try again to avoid appending the message again
  if (!tryAgain) {
    dispatch(appendMessageBeforeSending(newMessageToAppend));
  } else {
    //updating the try again message status to pending
    dispatch(
      updateTryAgainMessage({
        messageUuid: payload.uuid,
        message: { messageStatus: "pending" },
      }),
    );
  }

  delete payload.attachments;

  const currentMessageBrand = {
    id: currentState.chatWidExhMsg.activeCustomerInfo?.brand?.id ?? "",
    email: currentState.chatWidExhMsg.activeCustomerInfo?.brand?.email ?? "",
    name: currentState.chatWidExhMsg.activeCustomerInfo?.brand?.name ?? "",
  };

  try {
    const data = await sendLiveMessages(payload);

    // Dispatch an action to update chat lastMessageTime with the current date and time
    dispatch(
      actions.updateCustomerData({
        chatId: payload.chat_id,
        customer: {
          lastMessage: data.messageText,
          lastMessageId: data.messageId,
          lastMessageTime: currentDateTime,
        },
      }),
    );

    if (data.attachments === undefined) {
      data.attachments = newMessageToAppend.attachments;
    }

    return data;
  } catch (e: any) {
    //showing toast if attachmentSizeLimitExceeded error
    if (typeof e == "object") {
      if (e.statusCode === "attachmentSizeLimitExceeded") {
        pushTheToast({
          text: "Attachment file size exceeded!",
          type: "danger",
          position: "top-right",
        });
      } else if (
        e.statusCode === FreePlanError.LIMIT_REACHED ||
        e.statusCode === FreePlanError.RESTRICTED
      ) {
        const statusCode = e.statusCode as keyof typeof FreePlanErrorMessage;
        // If the free plan limit is exceeded, show limit reached error
        pushTheToast({
          text: e.message ?? FreePlanErrorMessage[statusCode],
          type: "danger",
          position: "top-right",
        });
        return rejectWithValue(e.statusCode);
      } else if (e.statusCode === "integrationFailure") {
        // If we have brand data in response
        if (e.failureMetaData.brand) {
          e.failureMetaData.brand = {
            id: e.failureMetaData.brand.id ?? currentMessageBrand.id,
            email: currentMessageBrand.email,
            name: e.failureMetaData.brand.name ?? currentMessageBrand.name,
          };
        } else {
          // If we don't have brand data in response get it out of message state
          e.failureMetaData.brand = currentMessageBrand;
        }
        return rejectWithValue(e);
      }
    }
    throw e;
  }
};

export interface IFetchLiveChatMessgeUpdates {
  chatStatus: "missed" | "live" | "archived";
  callback?: () => void;
}

const fetchLiveChatMessgeUpdatesThunk = async (
  payload: IFetchLiveChatMessgeUpdates,
  { getState, dispatch }: { getState: Function; dispatch: Function },
) => {
  const currentState: RootState = getState();

  const chatId = parseInt(
    currentState.chatWidExchCust.selectedCustomerChatId + "",
  );
  const messageIdList = currentState.chatWidExhMsg.messageIdList;
  const lastMessageId = messageIdList[0]; // For updates it will always fetch new data so selecting 1st message id.

  try {
    const { messages, messageIdList, metaData, activeCustomerInfo } =
      await getAllMessages({
        chatRequestId: chatId,
        lastMessageId,
        sortBy: "desc",
        limit: 5,
        tabContext: payload.chatStatus,
        scrollDirection: "new", // For updates it will always be new.
      });
    // It will always return all new data.
    metaData.limitReachedNew = true;

    if (activeCustomerInfo) {
      dispatch(
        actions.setCustomerData({
          customer: activeCustomerInfo,
          checkForExistingCust: true,
        }),
      );
    }
    setTimeout(() => {
      if (payload?.callback) {
        payload.callback();
      }
    }, 0);
    return {
      messages,
      messageIdList,
      metaData,
      activeCustomerInfo,
      chatId,
    };
  } catch (error) {
    throw error;
  }
};
export interface GetAssignToAgentP {
  searchTerm?: string;
  callback?: () => {};
}
async function getAssignToAgentsListThunk(
  { callback, ...payload }: GetAssignToAgentP,
  { getState, dispatch }: { getState: Function; dispatch: Function },
) {
  const currentState: RootState = getState();

  let agentListPayload: GetAssignToAgentParam = {
    chatId: currentState.chatWidExchCust.selectedCustomerChatId,
    start: currentState.chatWidExhMsg.agentIds.length,
    limit: currentState.chatWidExhMsg.agentFetchLimit,
  };

  if (payload.searchTerm && payload.searchTerm.trim().length !== 0) {
    agentListPayload.searchTerm = payload.searchTerm;
  }
  const data = await getAssignToAgents(agentListPayload);

  setTimeout(() => {
    if (callback) {
      callback();
    }
  }, 0);

  return data;
}

export default {
  fetchMsgDataThunk,
  sendLiveMsgThunk,
  fetchLiveChatMessgeUpdatesThunk,
};

export const fetchMsgData = createAsyncThunk(
  "chatWidExchMsg/fetchMsgData",
  fetchMsgDataThunk,
);
export const sendLiveMsg = createAsyncThunk(
  "chatWidExchMsg/sendLiveMsg",
  sendLiveMsgThunk,
);

export const fetchLiveChatMessgeUpdates = createAsyncThunk(
  "chatWidExchMsg/fetchLiveChatMessgeUpdates",
  fetchLiveChatMessgeUpdatesThunk,
);

export const fetchAllAssignToAgentsList = createAsyncThunk(
  "chatWidExchMsg/fetchAllAssignToAgentsList",
  getAssignToAgentsListThunk,
);
