import { useEffect, useMemo, useRef, useState } from "react";
import styles from "./EditEmail.module.scss";
import TinyMCEContainer from "src/components/TinyMCEContainer";
import { Dropdown } from "react-bootstrap";
import SearchBar from "src/components/SearchBar/SearchBar";
import {
  isHTMLEmpty,
  removeBracesFromVariableKey,
  replaceKeyWithSpanB,
  trimPDIVBR,
} from "src/utils/utils";
import { v4 as uuid } from "uuid";

export interface IVariableB {
  id: string;
  variableName: string;
  example: string;
  variableKey: string;
}

interface Props {
  heading: string;
  placeholder: string;
  hideVariables?: boolean;
  initialValue: string;
  onChange: (value: string) => void;
  variablesList?: {
    [x: string]: IVariableB;
  };
  variableIdsList?: string[];
  showErrors: boolean;
}

function isEqual(objA: any, objB: any) {
  return JSON.stringify(objA) === JSON.stringify(objB);
}

/**
 * Edit Email Component for showing Tiny MCE Editor and Variables
 */
const EditEmail = ({
  heading,
  placeholder,
  initialValue,
  onChange,
  hideVariables = false,
  variablesList: initialVariablesList = {},
  variableIdsList: initialVariableIdsList = [],
  showErrors,
}: Props) => {
  const [value, setValue] = useState(initialValue);
  const ref = useRef(null as any);

  const [variableIdsList, setVariableIdsList] = useState(
    initialVariableIdsList,
  );
  const [searchText, setSearchText] = useState<string>("");

  const prevVariablesList = useRef<typeof initialVariablesList>({});

  useEffect(() => {
    if (!isEqual(prevVariablesList.current, initialVariablesList)) {
      // Initialize the data by replacing Variable Key if any present with proper styling
      const valueUpdated = replaceKeyWithSpanB(
        value,
        initialVariablesList ?? {},
        "background: #dff0ff;border-radius: 5px;color: #0b68bb;padding:1px;",
      );
      setValue(valueUpdated);
      prevVariablesList.current = initialVariablesList ?? {};
    }
  }, [initialVariablesList]);

  /**
   * Change handler for setting values
   */
  const onChangeHandler = (val: string) => {
    setValue(val);
    onChange(val);
  };

  const [showDropdown, setShowDropdown] = useState(false);

  const filterVariables = (searchValue: string) => {
    const filteredIds = Object.keys(initialVariablesList || {}).filter(
      (id) =>
        initialVariablesList[id]?.variableName
          .toLowerCase()
          .includes(searchValue?.toLowerCase()),
    );
    setVariableIdsList(filteredIds);
  };

  const handleSearchChange = (value: string) => {
    if (value && value?.trim().length > 0) {
      filterVariables(value);
    } else {
      // If the search value is empty, display all variables
      setVariableIdsList(Object.keys(initialVariablesList || {}));
    }
  };

  const setDropdown = () => {
    // Added this to clear search and show all varibles once dropdown closed
    if (showDropdown) {
      setSearchText("");
      handleSearchChange("");
    }
    setShowDropdown(!showDropdown);
  };

  /**
   * Example Variable for showing example
   */
  const exampleVariable = useMemo(() => {
    if (initialVariableIdsList.length === 0) return "";
    const variableKey = removeBracesFromVariableKey(
      initialVariablesList[initialVariableIdsList[0]]?.variableKey ?? "",
    );
    return `${variableKey} Eg: ${
      initialVariablesList[initialVariableIdsList[0]].example
    }`;
  }, [initialVariablesList, initialVariableIdsList]);

  return (
    <div className={`p-2 mb-4 ${styles.editMailWrapper}`}>
      <p className={`${styles.heading}`}>{heading} </p>
      <TinyMCEContainer
        className={`border ${
          showErrors
            ? isHTMLEmpty(trimPDIVBR(value?.trim()))
              ? "border-danger shadow-none"
              : ""
            : ""
        }`}
        value={value}
        onChange={onChangeHandler}
        // Only used to remove the left margin from toolbar
        showCustomFilePicker={true}
        uniqueID={uuid()}
        // Hide all controls that are outside of editor
        hideAllControls={true}
        options={{
          placeholder,
        }}
        toolbar="emoticons bold italic underline link bullist"
        getMentionRef={(data) => {
          if (ref) {
            ref.current = data;
          }
        }}
      />
      {showErrors && isHTMLEmpty(trimPDIVBR(value.trim())) && (
        <span className={`${styles.errorText}`}>Please fill this field</span>
      )}
      {!hideVariables && (
        <div>
          <div className={`${styles.variableHeading}`}>
            Available variables:
          </div>
          <div className={`d-flex align-items-center flex-wrap`}>
            {initialVariablesList &&
              ((variableIdsList && variableIdsList.length !== 0) ||
                (initialVariablesList &&
                  initialVariableIdsList?.length !== 0)) && (
                <div
                  className={`me-2 p-2 mb-2 ${styles.variableText}`}
                  role="button"
                  onClick={(e) => {
                    e.preventDefault();
                    if (variableIdsList && variableIdsList.length !== 0) {
                      addVariableOnClick(
                        initialVariablesList[variableIdsList[0]],
                        ref,
                        "background: #dff0ff;border-radius: 5px;color: #0b68bb;padding:4px;",
                      );
                    } else if (initialVariableIdsList?.length !== 0) {
                      //Added this to avoid when search text is made empty it is not showing example and dropdown moving out of place
                      addVariableOnClick(
                        initialVariablesList[initialVariableIdsList[0]],
                        ref,
                        "background: #dff0ff;border-radius: 5px;color: #0b68bb;padding:4px;",
                      );
                    }
                  }}
                >
                  {" "}
                  <code> {exampleVariable}</code>{" "}
                </div>
              )}{" "}
            <Dropdown
              drop="up"
              show={showDropdown}
              onToggle={setDropdown}
            >
              <Dropdown.Toggle
                className={`dropdown-toggle ${styles.showBtn}`}
                as={"div"}
                variant="success"
                onClick={setDropdown}
              >
                <span className={`cursor-pointer ${styles.seeAll}`}>
                  {" "}
                  See all
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu
                bsPrefix={`dropdown-menu pt-0 border-0 ${styles.variBox}`}
              >
                <div>
                  <div
                    className={`px-2 d-flex justify-content-between align-items-center py-2 ${styles.headerCol}`}
                  >
                    <span className={`${styles.leadName}`}>All variables</span>
                    <div className={`d-flex align-items-center`}>
                      <button
                        className={`btn btn-primary d-none ${styles.addBtn}`}
                        onClick={() => {}}
                      >
                        + Add
                      </button>
                      <span
                        className={`cursor-pointer ps-2  ${styles.closeDrop}`}
                        onClick={setDropdown}
                      >
                        {" "}
                        <i className="fa-solid fa-xmark"></i>
                      </span>
                    </div>
                  </div>
                  <div className={`py-2 ${styles.variHeight}`}>
                    {initialVariablesList &&
                    variableIdsList &&
                    variableIdsList?.length !== 0
                      ? variableIdsList?.map((id, idx) => {
                          const variable = initialVariablesList
                            ? initialVariablesList[id]
                            : null;

                          if (!variable) {
                            return "";
                          }

                          return (
                            <li
                              key={idx}
                              className="m-1"
                            >
                              <span
                                className={`dropdown-item ${styles.dropDown}`}
                                onClick={() => {
                                  addVariableOnClick(
                                    variable,
                                    ref,
                                    "background: #dff0ff;border-radius: 5px;color: #0b68bb;padding:4px;",
                                  );
                                  setDropdown();
                                }}
                              >
                                <p className={`mb-0 ${styles.varibleHead}`}>
                                  {variable?.variableName}
                                </p>
                                <span className={`${styles.varibleContent}`}>
                                  {" "}
                                  Eg: {variable?.example}
                                </span>
                              </span>
                            </li>
                          );
                        })
                      : ""}
                  </div>
                  <div className={`mx-1`}>
                    <SearchBar
                      className={`${styles.search} form-control px-2`}
                      inputClassName={`${styles.input}`}
                      placeholder={`Search`}
                      onChange={(e: any) => {
                        setSearchText(e.target.value);
                      }}
                      onSearch={handleSearchChange}
                      value={searchText}
                      isSearchIcon={false}
                    />
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      )}
    </div>
  );
};

const addVariableOnClick = (
  variable: IVariableB,
  ref: React.MutableRefObject<any>,
  inlineStyles?: string,
) => {
  // Check if we have a new mention
  if (ref.current.addMentionRef?.current?.addMention) {
    // Adds a span with the new mention
    const variableKey = removeBracesFromVariableKey(variable.variableKey);
    const msg = `${variableKey} Eg: ${variable.example}`;
    ref.current.addMentionRef.current.addMention({
      id: variable.id, // Used to identify the variable
      name: msg, // Used to render the text inside the span
      inlineStyles: inlineStyles
        ? inlineStyles
        : "display: inline-block;color: #0B68BB;",
    });
  }
};

export default EditEmail;
