import { IMessageData } from "src/store/slices/innerTicket/innerTicket.slice";
import styles from "./ShowEventMsg.module.scss";
import TicketEventMsg from "./TicketEvent/TicketEventMsg";

interface IShowEventMsg {
  messageData: IMessageData;
}

const ShowEventMsg = ({ messageData }: IShowEventMsg) => {
  return (
    <div className={`${styles.eventMainDiv}`}>
      <TicketEventMsg messageData={messageData} />
    </div>
  );
};

export default ShowEventMsg;
