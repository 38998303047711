import { useQuery } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import getAllSlackIntegrationService from "src/services/Integrations/Slack/getAllSlackIntegrations.service";
import getLinkableBrandSlackService from "src/services/Integrations/Slack/getLinkableBrandsSlack.service";

const initialPayload = {
  start: 0,
  limit: 50,
};

function useGetAllLinkableBrnad() {
  const [payload, setPayload] = useState({
    ...initialPayload,
  });
  // const [integratedSlack, setIntegratedSlack] = useState<any[]>([]);

  const { data, isError, isLoading, status } = useQuery(
    ["slackIntegration/getLinkableBrandSlackService"],
    {
      queryFn: () => getLinkableBrandSlackService(payload),
      staleTime: 60000,
      cacheTime: 60000,
    },
  );

  //Calculate the currentPage and totalPages for pagination
  const currentPage = Math.floor(payload.start! / payload.limit!) + 1;

  // Change the current page and trigger a refetch
  const changePage = useCallback(() => {
    setPayload({
      ...payload,
      start: payload.start + payload.limit!,
    });
  }, [payload]);

  return {
    linkableBrands: data as any,
    currentPage,
    changePage,
    // hasNextPage: data?.metaData.hasMore,
    isLoading,
    isError,
  };
}

export default useGetAllLinkableBrnad;
