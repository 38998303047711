import React, { useEffect, useState } from "react";
import styles from "./PreviewBox.module.scss";
import preview from "src/assets/images/theme-preview.png";
import Preview2Crop from "src/assets/images/Preview2Crop.jpg";
import { Modal } from "react-bootstrap";
import CustomizePreview from "./CustomizePreview/CustomizePreview";
import { updatePageStylingProperty } from "src/store/slices/knowledgeBase/knowledgeBase.slice";
import { Theme } from "src/services/KnowledgeBase/Settings/Styles/getAllThemes.service";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useThemeBox from "../../../../../ThemeBox/useThemeBox";
import { updateActiveTheme } from "src/services/KnowledgeBase/Settings/Styles/updateActiveTheme.service";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "src/store/store";
import usePreviewBox from "./usePreviewBox";
import previewImg from "src/assets/images/previewInTab.png";

interface Props {
  themeModalHide: () => void;
  themeData: Theme;
}

const PreviewBox = ({ themeModalHide, themeData }: Props) => {
  const { onShow, onHide, handleChooseTheme, showModal } = usePreviewBox(
    themeModalHide,
    themeData.id
  );

  return (
    <div className="">
      <div className={`${styles.imgWrapper}`}>
        <img
          src={
            themeData.themeType === "alice_in_wonderland"
              ? preview
              : themeData.themeType === "night_in_the_oscars"
              ? Preview2Crop
              : null
          }
          alt=""
          className={`img-fluid w-100 ${styles.setHeight}`}
        />
      </div>

      <div
        className={`pt-2 text-center p-2`}
        style={{ borderTop: "1px solid #D5D5D5" }}
      >
        <p className={`mb-0 ${styles.previewMain} saufter_p`}>
          {themeData.name}{" "}
        </p>
        <span className={`d-inline-block ${styles.previewText}`}>
          {themeData.desc}
        </span>
        <div
          className={`mt-3 mb-2 d-flex justify-content-between ${styles.actionBox}`}
        >
          <button
            className={`d-flex justify-content-center align-items-center ps-2 pe-3 ${styles.previewBtn}`}
            onClick={onShow}
          >
            Preview
            <span>
              <img src={previewImg} alt="" className={`${styles.previewImg}`} />
            </span>
          </button>
          <Modal
            // backdropClassName={`${styles.modalBack}`}
            show={showModal}
            onHide={onHide}
            dialogClassName={`${styles.modalDialog}`}
            contentClassName={`${styles.modalContent}`}
            centered={true}
          >
            <CustomizePreview onHide={onHide} themeData={themeData} />
          </Modal>
          <button
            className={`d-flex justify-content-center align-items-center ps-2 pe-3 ${styles.chooseBtn}`}
            onClick={handleChooseTheme}
          >
            Choose this theme
          </button>
        </div>
      </div>
    </div>
  );
};

export default PreviewBox;
