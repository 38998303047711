import styles from "./inputBox.module.scss";
import Select from "react-select";
import { RoutingRuleCondition } from "src/store/slices/liveChatSetting/RoutingRules/RoutingRulesSetting.slice";

interface Props {
    conditionType: string;
    condition: any;
    placeholder: string;
    handleConditionDelete: any;
    handleConditionOnChange: any;
  }
const InputBox = ({condition,conditionType,placeholder,handleConditionDelete,handleConditionOnChange}:Props) => {

    const options = [
        {
          value:"contains",
          label: (
            <>
              <div className={`d-flex align-items-center`}>
                <span className={`ms-1 ${styles.selectSpan}`}>Contains</span>{" "}
              </div>
            </>
          ),
        },
        {
          value: "isExactly",
          label: (
            <>  
              <div className={`d-flex align-items-center`}>
                {" "}
                <span className={`ms-1 ${styles.selectSpan}`}>Is exactly</span>{" "}
              </div>
            </>
          ),
        },
      ];
    return (
        <div className={`my-2 d-flex flex-wrap flex-sm-nowrap justify-content-between align-items-center`}>
        <div className={`mb-1 mb-md-0 ${styles.selectBox}`}>
          <Select 
            options={options} 
            className={`${styles.selItem}`} 
            value={options.filter(function(option) {
              return option.value === condition.conditionConstraint;
            })}
            onChange={(e)=>handleConditionOnChange(condition,'select',e?.value,conditionType)}
            />
        </div>
        <div className={`${styles.inputBox}`}>
          <input
            className={`${styles.urlInput}`}
            type="text"
            value={condition.conditionValue}
            placeholder={placeholder}
            onChange={(e)=>handleConditionOnChange(condition,'text',e.target.value,conditionType)}
            // onChange={handleConditionChange(condition.conditionId)}
          />
        </div>
        <span className={`ms-1 ms-md-2 cursor-pointer`}>
          <i className="fa-solid fa-xmark" onClick={(e)=>handleConditionDelete(condition,conditionType)}></i>
        </span>
      </div>
    )
}

export default InputBox;