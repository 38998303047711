import { axiosJSON } from "src/globals/axiosEndPoints";

interface IAssignToAgent {
  id: number | string;
  name: string;
  email: string;
  imageURL: string|null
}
export interface GetAssignToAgentParam {
  chatId?: string|number;
  searchTerm?: string;
  start?: number;
  limit?: number;
}

export interface AssignToAgentList {
  agents: { [agent: number | string]: IAssignToAgent };
  agentIds: Array<number | string>;
  metaData: {
    count: number;
    total: number;
  };
}

export async function getAssignToAgents(payload: GetAssignToAgentParam) {
  const { data: res } = await axiosJSON.post(
    "/api/liveChat/agent/getAssignToAgentList",
    payload
  );

  if (res.err) {
    throw new Error(res.msg);
  }

  const ret: AssignToAgentList = {
    agents: {},
    agentIds: [],
    metaData: {
      count: 0,
      total: 0,
    },
  };
  ret.metaData.count = res.metaData.count ?? 0;
  ret.metaData.total = res.metaData.total ?? 0;

  res.data.forEach((agent: IAssignToAgent) => {
    ret.agents[agent.id] = agent;
    ret.agentIds.push(agent.id);
  });

  return ret;
}
