import FinalChat from "../../Customization/Children/FinalChat/FinalChat";
import styles from "./PreChatForm.module.scss";
import { Accordion, Dropdown, Spinner } from "react-bootstrap";
import PreChatComp from "./Children/PreChatComp/PreChatComp";
import { useEffect, useRef, useState } from "react";
import textLogo from "src/assets/images/text.png";
import choiceImg from "src/assets/images/choiceList.png";
import dropdown from "src/assets/images/dropdown.png";
import multipleChoice from "src/assets/images/multipleChoice.png";
import disable from "src/assets/images/disable.png";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  fetchPreChatFormData,
  resetPreChatFormSetting,
} from "src/store/slices/liveChatSetting/Forms/preChatForm/preChatFormSetting.slice";
import { v4 as uuidv4 } from "uuid";
import ElementText from "../FormElements/Text/ElementText";
import ElementChoiceList from "../FormElements/ChoiceList/ElementChoiceList";
import ElementMultiChoiceList from "../FormElements/MultiChoiceList/ElementMultiChoiceList";
import ElementDropDown from "../FormElements/DropDown/ElementDropDown";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import {
  IUpdatePreChatFormDataParam,
  updatePreChatFormData,
} from "src/services/LiveChat/Settings/preChatForm/updatePreChatFormData";
import Loader from "src/components/Loader";
import { useParams } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const PreChatForm = () => {
  const {
    preChatFormData,
    formElementOptions,
    preChatFormDataAjaxStatus,
    updatePreChatFormDataAjaxStatus,
  } = useAppSelector((state) => state.preChatFormSetting);
  const dispatch = useAppDispatch();

  const { integrationId } = useParams();
  const pageIntegrationId = integrationId
    ? decodeURIComponent(integrationId)
    : "";
  const [formElements, setFormElements] = useState<any>([]);

  const [elementOptions, setElementOptions] = useState<any>([]);
  const [enabled, setEnabled] = useState(true); //02 Dec 22 // making the prechat enabled by default and hiding the enabled button
  const [showSaveChanges, setShowSaveChanges] = useState(false);
  const [showSaveLoader, setShowSaveLoader] = useState(false);

  const handleInput = () => {
    setEnabled(!enabled);
  };
  const prevElementState = useRef<any>();

  const elementTypesList: {
    [key: string]: { component: any };
  } = {
    //
    information: { component: ElementText },
    email: { component: ElementText },
    text: { component: ElementText },
    longText: { component: ElementText },
    choiceList: { component: ElementChoiceList },
    multipleChoiceList: { component: ElementMultiChoiceList },
    dropDown: { component: ElementDropDown },
  };

  useEffect(() => {
    dispatch(fetchPreChatFormData(pageIntegrationId));
  }, [false]);

  useEffect(() => {
    if (preChatFormData !== null) {
      if (
        preChatFormData.enabled === enabled &&
        JSON.stringify(prevElementState.current) ===
          JSON.stringify(formElements)
      ) {
        setShowSaveChanges(false);
      } else {
        setShowSaveChanges(true);
      }
    }
  }, [formElements, enabled]);

  useEffect(() => {
    if (preChatFormData !== null) {
      setEnabled(preChatFormData.enabled);

      let formElementList = preChatFormData.elements.map((element: any) => {
        let key = uuidv4();
        return {
          ...element,
          uniqueId: key,
          isNewlyAdded: false,
        };
      });

      setFormElements(formElementList);
      prevElementState.current = formElementList;
    }
  }, [preChatFormData]);

  useEffect(() => {
    if (formElementOptions !== null && formElements !== null) {
      const options = formElementOptions.formElementOptionIds.map(
        (optionId, index) => {
          let elementOption = formElementOptions.formElementOptions[optionId];
          let option = {
            ...elementOption,
            isAlreadyExists: false,
          };
          let element = formElements.filter(
            (element: any) => optionId === element.elementId
          );
          if (element.length === 0 || elementOption.canHaveMultiple === true) {
            option.isAlreadyExists = false;
          } else {
            option.isAlreadyExists = true;
          }

          return option;
        }
      );
      setElementOptions(options);
    }
  }, [formElements, formElementOptions]);

  const handleElementChange = (element: any) => {
    if (formElements === null) {
      return;
    }
    const stateFormElements = formElements.map((formElement: any) => {
      if (element.uniqueId === formElement.uniqueId) {
        return element;
      } else {
        return formElement;
      }
    });
    setFormElements(stateFormElements);
  };

  const handleNewElement = (optionId: any) => {
    if (optionId !== null) {
      let elementOption = elementOptions.filter(
        (element: any) => optionId === element.elementId
      );

      if (elementOption.length === 0) {
        return;
      }

      elementOption = elementOption[0];

      if (
        elementOption.canHaveMultiple === false &&
        elementOption.isAlreadyExists === true
      ) {
        return;
      }

      if (
        ["choiceList", "multipleChoiceList", "dropDown"].includes(
          elementOption.elementType
        )
      ) {
        let newElement = {
          elementId: elementOption.elementId,
          elementType: elementOption.elementType,
          elementKey: elementOption.elmentType + "_" + uuidv4(),
          elementLabel: elementOption.elementLabel,
          elementValue: "Question",
          elementOptions: ["First Answer", "Second Answer"],
          isRequired: false,
          isDefault: false,
          isNewlyAdded: true,
          uniqueId: uuidv4(),
        };

        const stateFormElements = [...formElements, newElement];
        setFormElements(stateFormElements);
      } else {
        let newElement = {
          elementId: elementOption.elementId,
          elementType: elementOption.elementType,
          elementKey: elementOption.elementType + "_" + uuidv4(),
          elementLabel: elementOption.elementLabel,
          elementValue: "Question",
          elementOptions: [],
          isRequired: false,
          isDefault: false,
          isNewlyAdded: true,
          uniqueId: uuidv4(),
        };
        const stateFormElements = [...formElements, newElement];
        setFormElements(stateFormElements);
      }
    }
  };

  const handleElementDelete = (element: any) => {
    if (formElements === null) {
      return;
    }
    const stateFormElements = formElements.filter(
      (formElement: any) => element.uniqueId !== formElement.uniqueId
    );

    setFormElements(stateFormElements);
  };

  const handleCancelButton = () => {
    if (formElements === null && preChatFormData !== null) {
      return;
    }

    // setEnabled(preChatFormData.enabled); //02 Dec 22 // commenting this line ...it will not be disabled cause of hiding enabled button

    let formElementList = preChatFormData.elements.map((element: any) => {
      let key = uuidv4();
      return {
        ...element,
        uniqueId: key,
      };
    });

    setFormElements(formElementList);
    prevElementState.current = formElementList;
    setShowSaveChanges(false);
  };

  const handleSubmit = () => {
    if (preChatFormDataAjaxStatus !== "pending" && formElements !== null) {
      if (formElements.length === 0) {
        pushTheToast({
          type: "danger",
          text: "Atleast one element is required",
          position: "top-right",
        });
      }

      let payload: IUpdatePreChatFormDataParam = {
        integrationId: pageIntegrationId,
      };
      if (enabled !== preChatFormData.enabled) {
        payload.enabled = enabled;
      }

      const emptyValueElements = formElements.filter(
        (element: any) => element.elementValue.length === 0
      );

      if (emptyValueElements.length > 0) {
        pushTheToast({
          type: "warning",
          text: "Please fill all the fields",
          position: "top-right",
        });
        return;
      }
      setShowSaveLoader(true);

      let payloadElements = formElements.map((element: any) => {
        return {
          elementId: element.elementId,
          elementKey: element.elementKey,
          elementValue: element.elementValue,
          elementOptions: element.elementOptions,
          isRequired: element.isRequired,
          isNewlyAdded: element.isNewlyAdded,
        };
      });

      payload.elements = payloadElements;

      updatePreChatFormData(payload)
        .then((res) => {
          pushTheToast({
            type: "success",
            text: "Successfully changed",
            position: "top-right",
          });
          setShowSaveChanges(false);
          setShowSaveLoader(false);

          dispatch(resetPreChatFormSetting());
          dispatch(fetchPreChatFormData(pageIntegrationId));
        })
        .catch((err) => {
          pushTheToast({
            type: "danger",
            text: "Something went wrong!",
            position: "top-right",
          });
          setShowSaveLoader(false);
        });
      // setShowSaveLoader(false);
    }
  };
  return (
    <div>
      <div className={`d-flex flex-wrap`}>
        {/* Custom Left */}
        <div className={`${styles.customLeft}`}>
          <div className={`${styles.fixPost}`}>
            <div>
              <h2 className={`${styles.preChatHead}`}>Pre-Chat Form</h2>
              <span className={`${styles.preChatSub}`}>
                {`Pre-Chat Form allows you to gather visitor's information before the chat starts.`}
              </span>
              <p className={`mt-2 d-flex align-items-center`}>
                {/* 02 Dec 22 // Hiding the enabled prechat button and it will be default enabled  */}
                {/* preChatFormDataAjaxStatus !== "pending" && (
                  <>
                  <span className={`${styles.enablePre}`}>
                    Enable Pre-Chat Form:
                  </span>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      onChange={handleInput}
                      checked={enabled}
                    />
                    <label
                      className={`ms-1 form-check-label ${styles.toggleSpan}`}
                      htmlFor="flexSwitchCheckDefault"
                    >
                      {enabled ? "on" : "off"}
                    </label>
                  </div>
                  </>
                )*/}
              </p>
            </div>
            {preChatFormDataAjaxStatus === "pending" ? (
              <Loader />
            ) : (
              preChatFormData !== null && (
                <>
                  <div>
                    {enabled === true ? (
                      <DragDropContext
                        onDragEnd={(param: any) => {
                          const srcI = param.source.index;
                          const desI = param.destination?.index;
                          if (desI !== undefined && desI !== null) {
                            const updatedList = [...formElements];
                            updatedList.splice(
                              desI,
                              0,
                              updatedList.splice(srcI, 1)[0]
                            );
                            setFormElements(updatedList);
                          }
                        }}
                      >
                        <Droppable droppableId="droppable-1">
                          {(provided: any, _: any) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                              className={`${styles.overIssue}`}
                            >
                              {formElements.map((element: any, i: number) => {
                                //
                                const ShowComp =
                                  elementTypesList[element.elementType]
                                    .component;
                                const hideDelete = formElements.length === 1;
                                return (
                                  <Draggable
                                    key={element.uniqueId}
                                    draggableId={
                                      "draggable-" + element.uniqueId
                                    }
                                    index={i}
                                  >
                                    {(provided: any, snapshot: any) => (
                                      <ShowComp
                                        customProvided={provided}
                                        element={element}
                                        handleElementDelete={
                                          handleElementDelete
                                        }
                                        handleElementChange={
                                          handleElementChange
                                        }
                                        hideDelete={hideDelete}
                                      />
                                    )}
                                  </Draggable>
                                );
                              })}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    ) : undefined}
                  </div>
                  {/* Add element dropdown  */}
                  {enabled && (
                    <>
                      <div className={`d-flex pt-2`}>
                        <Dropdown>
                          <Dropdown.Toggle
                            as="div"
                            id="dropdown-basic"
                            bsPrefix={`cursor-pointer  me-2 d-flex align-items-center dropdown-toggle ${styles.addBox}`}
                          >
                            <span className={``}>
                              {" "}
                              <i className="fa-solid fa-plus"></i>
                            </span>
                          </Dropdown.Toggle>

                          <Dropdown.Menu
                            bsPrefix={`dropdown-menu ${styles.dropBox}`}
                          >
                            {elementOptions.map((option: any) => {
                              return (
                                <li>
                                  <span
                                    onClick={(e) => {
                                      handleNewElement(option.elementId);
                                    }}
                                    className={
                                      option.isAlreadyExists === true
                                        ? `dropdown-item ${styles.dropItem}`
                                        : `dropdown-item ${styles.dropItem2}`
                                    }
                                  >
                                    <img
                                      src={textLogo}
                                      className={`${styles.textLogo}`}
                                      alt=""
                                    />
                                    {option.elementLabel}
                                  </span>
                                </li>
                              );
                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                        <div className="d-flex align-items-center">
                          <span className={`${styles.addElement}`}>
                            Add Element
                          </span>
                        </div>
                      </div>
                      {/* Show when click on question  */}
                    </>
                  )}
                </>
              )
            )}
          </div>

          {showSaveChanges && (
            <div className={`flex-column flex-md-row ${styles.actionBtn}`}>
              <button
                className={`me-2 ${styles.cancelBtn}`}
                onClick={handleCancelButton}
              >
                Cancel
              </button>
              <button
                className={`mt-2 mt-md-0 ${styles.saveBtn}`}
                disabled={showSaveLoader === true}
                onClick={handleSubmit}
              >
                {showSaveLoader === true ? (
                  <Spinner
                    className="my-auto mx-1"
                    animation="border"
                    color="white"
                    size="sm"
                  />
                ) : (
                  "Save changes"
                )}
              </button>{" "}
            </div>
          )}
        </div>

        {/* Custom Right */}
        <div
          className={`d-block d-md-flex justify-content-md-center align-items-md-center ${styles.customRight}`}
        >
          {enabled ? (
            <FinalChat
              formData={{
                elements: formElements,
                submitButtonText: "Start Chat",
              }}
              show={{ chatForm: true }}
            />
          ) : (
            <div
              className={`d-flex justify-content-center align-items-center text-center ${styles.disableChat}`}
            >
              <img className={`mb-2`} src={disable} alt="disable" />
              <p className={`${styles.disableSpan}`}>
                {" "}
                "Pre-Chat Form is disabled."
              </p>
              <span className={`${styles.disableSpan}`}>
                {`The chat will start automatically, but your agents won‘t know visitors’ names, e-mails etc.`}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default PreChatForm;
