/**
 * This file defines a custom hook responsible for handling on change of brands dropdown in create bot profile modal
 * 
 * @author @Anubhav-busibud
 */
import { useCallback, useState } from "react";
import { BrandOption } from "./BrandModal";

function useBrandModal() {
  const [selectedBrand, setSelectedBrand] = useState<BrandOption>();
  const [error, setError] = useState("");

  // Callback to handle the change event of the dropdown
  const handleChange = useCallback((selectedOption: BrandOption) => {
    setSelectedBrand(selectedOption);
    if(selectedOption){
      setError("");
    }
  }, []);

  const [showSetup, setShowSetup] = useState(false);

  return {
    selectedBrand,
    handleChange,
    showSetup,
    setShowSetup,
    error,
    setError,
  };
}

export default useBrandModal;
