import {
  ConfigureCSVQuestion,
  ConfigureCSVUploadDetails,
  EConfigureCsvActiveStep,
} from "src/features/ReturnAutoWorkFlow/ReturnAutoWorkFlow.types";
import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";
import {
  GetAllImportedProductsResponse,
  IVarient,
} from "./getAllImportedProducts.service";
import { UploadCsvResponse, normalizeUploadErrors } from "./uploadCsv.service";

export interface GetCSVUploadDetailsParams {
  /** The unique identifier for the integration */
  integrationId: string;
}
export interface ErrorDescription {
  description: string;
  info: string;
}
/**
 * The data for the corresponding row in the errors table.
 * - `type` can be `null`, `"string"`, `"description"` - for the field which have description.
 * - `value` can be `null`, a `string`, or an `ErrorDescription`.
 */
export interface ErrorRow {
  tableHeadingId: string;
  type: null | "string" | "description";
  value: null | string | ErrorDescription;
}
/**
 * Represents an individual error row.
 */
export interface IError {
  /** The unique identifier for the order. */
  errorId: string;
  rowData: ErrorRow[];
}
interface TableHeading {
  /**
   * The `tableHeadingId` associated with the heading will be used in rows to render them.
   */
  tableHeadingId: string;
  /** The name of the heading. */
  tableHeadingName: string;
}
/**
 * Represents a collection of errors found in an uploaded CSV file.
 */
export interface UploadedCSVErrors {
  attachmentId: string;
  /**
   * Reperesents Heading for the errors
   * Example - "Column Mismatch", "Your impot has 6 errors"
   */
  heading?: null | string;
  /**
   * Subtitle or additional text for the error summary.
   * Example - "The columns in the uploaded CSV file do not match..."
   * Will not present incase of errors table
   */
  subText?: string;
  /**
   * The array of table headings to be displayed in the errors table.
   */
  tableHeadings?: TableHeading[];
  /**
   * The list of all errors based on the uploaded csv.
   */
  errors?: IError[];
  /**
   * A link to download the CSV file with errors.
   */
  downloadErrorCSVLink?: string;
  /**
   * The total number of errors found in the CSV file.
   */
  totalErrorCount?: number;
}
export interface ConfigureCSVTableMappedAttributes {
  /**
   * The total number of rows, which is the same as the total number of variants.
   */
  totalVarients: number;
  /**
   * Frontend use only - The total pages calculated using the total variants count.
   */
  totalPages: number;
  /**
   * Frontend use only - The active page among all the pages of the table.
   */
  activePage: number;
  /**
   * The array of table headings to be displayed in the products table.
   */
  tableHeadings: TableHeading[];
  /**
   * The list of all the variants in the table.
   */
  varients: IVarient[];
}
export interface GetCSVUploadDetailsResponse {
  /**
   * The current active step in the upload process.
   * Can be one of the following: "configureUpload", "uploadCSV", "mapAttributes", "import", or "completed".
   */
  activeStep:
    | "configureUpload"
    | "uploadCSV"
    | "mapAttributes"
    | "import"
    | "completed";
  /**
   * Required details for the configureUpload step.
   */
  configureUpload: {
    /**
     * An array of questions for configuring the CSV upload.
     */
    configureCSVQuestions: Array<ConfigureCSVQuestion>;
  };

  /**
   * Optional details for the uploadCSV step.
   */
  uploadCSV?: null | {
    /**
     * A link to a sample CSV template.
     */
    sampleCSVTemplateLink: string;
    /**
     * An optional link to the uploaded file.
     * Can be null.
     */
    uploadedFileLink?: null | string;
    /**
     * Uploaded file name.
     */
    uploadedFileName?: null | string;
    /**
     * Optional errors found during the upload.
     * Can be null.
     */
    uploadErrors?: null | UploadedCSVErrors;
    /**
     * An optional file uploaded by the user.
     * Required for the uploadCSV page but used only on the frontend.
     * Can be null.
     */
    uploadedFile?: null | File;

    attachmentId?: null | string;
  };
  /**
   * For mapping the attributes, we can change the headimg of the columns among all the processed headings.
   * It can be null or undefined initially.
   */
  mapAttributes?: null | ConfigureCSVTableMappedAttributes;

  /**
   * For the import step of the CSV upload process.
   * It can be null or undefined initially.
   */
  importedCSV?: null | GetAllImportedProductsResponse;
}
async function getConfigureCSVUploadDetails(
  params: GetCSVUploadDetailsParams,
): Promise<any> {
  const apiEndPoint = IS_TEST_ACTIVE
    ? `${process.env.REACT_APP_TEST_SITE_URL}/csv/getConfigureCSVUploadDetails`
    : `/api/setting/automation/refund/product/csv/getConfigureCSVUploadDetails`;

  const { data: res } = await axiosJSON.get(apiEndPoint, { params });

  if (res.err || res.error) {
    throw new Error(res.msg ?? res.message ?? "Some Error Occurred.");
  }
  const normalizedData = normalizeData(res.data as GetCSVUploadDetailsResponse);
  return normalizedData as ConfigureCSVUploadDetails;
}

function normalizeData(
  data: GetCSVUploadDetailsResponse,
): ConfigureCSVUploadDetails {
  const { activeStep, configureUpload, uploadCSV, mapAttributes, importedCSV } =
    data;
  const normalizedData: ConfigureCSVUploadDetails = {
    activeStep: activeStep as EConfigureCsvActiveStep,
    configureUpload: {
      configureCSVQuestions: configureUpload.configureCSVQuestions,
    },
    uploadCSV: uploadCSV
      ? {
          sampleCSVTemplateLink: uploadCSV.sampleCSVTemplateLink,
          uploadedFileLink: uploadCSV.uploadedFileLink || null,
          uploadedFileName: uploadCSV.uploadedFileName || null,
          attachmentId: uploadCSV.attachmentId || null,
          uploadErrors: uploadCSV.uploadErrors
            ? normalizeUploadErrors(uploadCSV.uploadErrors as UploadCsvResponse)
            : null,
        }
      : null,
    mapAttributes: null,
    importedCSV: null,
  };
  return normalizedData;
}

export default getConfigureCSVUploadDetails;
