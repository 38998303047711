import styles from "./Timeline.module.scss";

// Define StepConfig interface
interface StepConfig {
  steps: number[];
  activeStep: number;
  showBorderAfterLast: boolean;
  showBorderBeforeStart: boolean;
}

// Step configurations
const stepsConfig: Record<string, StepConfig> = {
  default: {
    steps: [1, 2, 3, 4, 5, 6, 7],
    activeStep: 0,
    showBorderBeforeStart: false,
    showBorderAfterLast: false,
  },
  selectOrder: {
    steps: [1, 2, 3],
    activeStep: 1,
    showBorderBeforeStart: false,
    showBorderAfterLast: false,
  },
  selectAction: {
    steps: [1, 2, 3],
    activeStep: 2,
    showBorderBeforeStart: false,
    showBorderAfterLast: false,
  },
  notReturnable: {
    steps: [1, 2, 3],
    activeStep: 3,
    showBorderBeforeStart: false,
    showBorderAfterLast: false,
  },
  addReturnItems: {
    steps: [1, 2, 3, 4, 5],
    activeStep: 3,
    showBorderBeforeStart: false,
    showBorderAfterLast: false,
  },
  notReturnableItems: {
    steps: [1, 2, 3, 4, 5],
    activeStep: 3,
    showBorderBeforeStart: false,
    showBorderAfterLast: false,
  },
  returnType: {
    steps: [1, 2, 3, 4, 5],
    activeStep: 4,
    showBorderBeforeStart: false,
    showBorderAfterLast: true,
  },
  reason: {
    steps: [1, 2, 3, 4, 5],
    activeStep: 5,
    showBorderBeforeStart: false,
    showBorderAfterLast: false,
  },
  moreDetails: {
    steps: [2, 3, 4, 5, 6],
    activeStep: 6,
    showBorderBeforeStart: false,
    showBorderAfterLast: false,
  },
  arsInformation: {
    steps: [3, 4, 5, 6, 7],
    activeStep: 7,
    showBorderBeforeStart: false,
    showBorderAfterLast: false,
  },
  arsOffers: {
    steps: [3, 4, 5, 6, 7],
    activeStep: 7,
    showBorderBeforeStart: true,
    showBorderAfterLast: true,
  },
  arsReturnTypes: {
    steps: [5, 6, 7, 8, 9],
    activeStep: 9,
    showBorderBeforeStart: true,
    showBorderAfterLast: true,
  },
  arsExchange: {
    steps: [5, 6, 7, 8, 9],
    activeStep: 9,
    showBorderBeforeStart: true,
    showBorderAfterLast: true,
  },
  returnMethod: {
    steps: [7, 8, 9, 10, 11],
    activeStep: 11,
    showBorderBeforeStart: false,
    showBorderAfterLast: false,
  },
  reviewRestockingFee: {
    steps: [8, 9, 10, 11, 12],
    activeStep: 12,
    showBorderBeforeStart: true,
    showBorderAfterLast: false,
  },
  orderSummary: {
    steps: [8, 9, 10, 11, 12],
    activeStep: 12,
    showBorderBeforeStart: true,
    showBorderAfterLast: false,
  },
};

// Function to get the label for a specific step type
const getStepLabel = (type: string, step: number): string => {
  const labels: Record<string, string[]> = {
    selectOrder: ["Select Order", "", ""],
    selectAction: ["Select Order", "Select Action", ""],
    addReturnItems: [
      "Select Order",
      "Select Action",
      "Add return items",
      "Select return Type",
      "Add reasons",
    ],
    notReturnableItems: [
      "Select Order",
      "Select Action",
      "Add return items",
      "Select return Type",
      "Add reasons",
    ],
    notReturnable: ["Select Order", "Select Action", "Order not eligible"],
    returnType: [
      "Select Order",
      "Select Action",
      "Add return items",
      "Select return Type",
      "Add reasons",
    ],
    reason: [
      "Select Order",
      "Select Action",
      "Add return items",
      "Select return Type",
      "Add reasons",
    ],
    moreDetails: [
      "Select Action",
      "Add return items",
      "Select return Type",
      "Add reasons",
      "More details",
    ],
    arsInformation: [
      "Add return items",
      "Select return Type",
      "Add reasons",
      "More details",
      "Information",
    ],
    arsOffers: [
      "Add return items",
      "Select return Type",
      "Add reasons",
      "More details",
      "Offers",
    ],
    arsReturnTypes: [
      "Add reasons",
      "More details",
      "Information",
      "Offers",
      "Exchanges",
    ],
    arsExchange: [
      "Add reasons",
      "More details",
      "Information",
      "Offers",
      "Exchanges",
    ],
    returnMethod: [
      "Information",
      "Offers",
      "Exchanges",
      "Confirmation",
      "Return Method",
    ],
    reviewRestockingFee: [
      "Offers",
      "Exchanges",
      "Confirmation",
      "Return Method",
      "Order Summary",
    ],
    orderSummary: [
      "Offers",
      "Exchanges",
      "Confirmation",
      "Return Method",
      "Order Summary",
    ],
  };
  return labels[type]?.[step] || "";
};

// Function to get the step data
const getStepData = (type: string) => {
  const config = stepsConfig[type || "default"];
  return config?.steps?.map((step) => ({
    number: step,
    active: step === config?.activeStep,
    showBorderAfterLast: config?.showBorderAfterLast ? true : false,
    showBorderBeforeStart: config?.showBorderBeforeStart ? true : false,
    label: getStepLabel(type, step - config?.steps[0]),
  }));
};

// Define TimeLineProps interface
interface TimeLineProps {
  activeStep: string;
}

// TimeLine component
const TimeLine = ({ activeStep }: TimeLineProps) => {
  const stepData = getStepData(activeStep);

  return (
    <div className="d-flex align-items-center justify-content-center mt-4">
      {stepData &&
        stepData?.map((step, index) => (
          <div key={index}>
            <div className="d-flex align-items-center">
              {/* Adding dotted line before start of steps if index is 0 */}
              {index === 0 && step.showBorderBeforeStart && (
                <div className={`${styles.dashBorder} ${styles.clip}`}></div>
              )}

              <span
                className={`d-flex justify-content-center align-items-center ${
                  styles.step
                } ${step.active ? styles.active : ""}`}
              >
                {step.number}
              </span>

              {/* Adding dotted line after end of steps if index is last or in the middle of steps */}
              {(index < stepData.length - 1 || step.showBorderAfterLast) && (
                <div
                  className={`${styles.dashBorder} ${
                    index === stepData.length - 1 && step.showBorderAfterLast
                      ? styles.clip
                      : ""
                  }`}
                ></div>
              )}
            </div>

            <div>
              {/* Step label rendering with active and inactive styles */}
              <span
                className={`${styles.selSpan} ${
                  step.active ? styles.active : ""
                } ${
                  step.showBorderBeforeStart && index === 0
                    ? styles.clipMargin
                    : ""
                }`}
              >
                {step.label}
              </span>
            </div>
          </div>
        ))}
    </div>
  );
};

export default TimeLine;
