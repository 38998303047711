import { axiosJSON } from "src/globals/axiosEndPoints";
import { IMessageData } from "src/store/slices/innerTicket/innerTicket.slice";

const sendEscalateMsg = async (payload: {
  messageId: number | string;
  message: string;
  attachmentBatchNumber?: string[];
}) => {
  const { data: res } = await axiosJSON.post(
    `/api/message/sendEscalateMsg`,
    payload,
  );

  if (res.err || res.error) {
    throw res.msg ?? res.message ?? "Something went wrong!";
  }

  return res.data as IMessageData;
};

export default sendEscalateMsg;
