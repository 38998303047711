import { axiosJSON } from "src/globals/axiosEndPoints";

export interface ILiveChatIntegratedBrand {
  id: number | string;
  name: string;
  email: string;
}
export interface IGetLiveChatIntegratedBrandsParams {
  searchTerm?: string;
  start?: number;
  limit?: number;
}

export interface ILiveChatIntegratedBrandList {
  // brands: { [brand: number | string]: ILiveChatIntegratedBrand };
  brands: Array<ILiveChatIntegratedBrand>;
  brandIds: Array<number | string>;
  metaData: {
    count: number;
    total: number;
  };
}

export async function getLiveChatIntegratedBrands(
  payload: IGetLiveChatIntegratedBrandsParams
) {
  if (!payload.searchTerm) {
    delete payload.searchTerm;
  }

  const { data: res } = await axiosJSON.post(
    "/api/liveChat/agent/getLiveChatIntegratedBrandList",
    payload
  );

  if (res.err) {
    throw new Error(res.msg);
  }

  const ret: ILiveChatIntegratedBrandList = {
    // brands: {},
    brands: [],
    brandIds: [],
    metaData: {
      count: 0,
      total: 0,
    },
  };
  ret.metaData.count = res.metaData.count ?? 0;
  ret.metaData.total = res.metaData.total ?? 0;

  ret.brands = res.data;

  res.data.forEach((brand: ILiveChatIntegratedBrand) => {
    // ret.brands[brand.id] = brand;
    ret.brandIds.push(brand.id);
  });

  return ret;
}
