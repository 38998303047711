import { useInfiniteQuery } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { EAutomationType } from "src/routes/BotSettings/Children/AutomationList/AutomationList";
import {
  DispatchRuleArticlesPayload,
  getAllArticlesService,
} from "src/services/Automation/DispatchRulesConfigModal/getAllArticles.service";
import { AutomationAPIPayload } from "src/services/Automation/createAutomation.service";

const INITIAL_PAYLOAD = {
  start: 0,
  limit: 20,
};

interface OptionType {
  value: string;
  label: string;
}

const useSuggestArticles = (
  integrationId: string,
  automationType: EAutomationType,
) => {
  const payload: AutomationAPIPayload<DispatchRuleArticlesPayload> =
    useMemo(() => {
      return {
        payload: {
          ...INITIAL_PAYLOAD,
          integrationId,
        },
        type: automationType,
      };
    }, [automationType, integrationId]);

  // Infinite query hook to fetch pages
  const { data, isLoading, fetchNextPage, hasNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ["getAllArticles", payload],
      queryFn: ({ pageParam = payload }) => getAllArticlesService(pageParam),
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.metaData.limitReached) {
          return null;
        }

        const data = allPages.flatMap((page) => page);
        const nextPageParam: AutomationAPIPayload<DispatchRuleArticlesPayload> =
          {
            payload: {
              limit: INITIAL_PAYLOAD.limit,
              start: data.length,
              integrationId,
            },
            type: automationType,
          };
        return nextPageParam;
      },
    });

  /**
   * Fetch next page handler
   */
  const fetchNextPageHandler = useCallback(() => {
    // If we have next page and currently not fetching fetch the next page
    if (hasNextPage && !isFetching) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isFetching]);

  const { options, optionsMap } = useMemo(() => {
    const options: OptionType[] = [];
    const optionsMap: Record<string, OptionType> = {};

    if (data) {
      const pages = data.pages.flatMap((page) => page.articles) ?? [];

      pages.forEach((op) => {
        const id = op.articleId + "";
        const option = {
          value: op.articleId + "",
          label: op.articleName,
        };
        options.push(option);
        optionsMap[id] = option;
      });
    }

    return { options, optionsMap };
  }, [data]);

  const showLoader = useMemo(() => {
    // Show loader if loading or fetching data
    return isLoading || isFetching;
  }, [isFetching, isLoading]);

  return { options, optionsMap, showLoader, fetchNextPageHandler };
};

export default useSuggestArticles;
