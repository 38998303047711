import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  conditionType,
  getConditionOptions,
} from "src/services/Bot/BotProfiles/getConditionOptions.service";
import { getConfigureAudience } from "src/services/Bot/BotProfiles/getConfigureAudience.service";
import { updateBotProfile } from "src/services/Bot/BotProfiles/updateBotProfile.service";
import { getCallerTypes, getCallRecording } from "src/services/CallRecording";
import {
  calculateTotalPages,
  pageSlice,
  paginationLogic,
} from "src/services/TicketService/pagination";
import { RootState } from "src/store/store";
import { validateURL } from "src/utils/validations";

const fetchConfigureAudienceConditionThunk = async (
  payload: { profileId: number },
  { getState }: { getState: Function }
) => {
  const currentState: RootState = getState();

  let conditionOptions: conditionType[] =
    currentState.botProfiles.audienceConfigOptions;
  if (currentState.botProfiles.audienceConfigOptions.length === 0) {
    conditionOptions = await getConditionOptions();
  }

  const data = await getConfigureAudience({ id: payload.profileId });

  return { conditionOptions: conditionOptions, conditions: data };
};

export const updateConfigureAudienceConditionThunk = async (
  payload: { profileId: number },
  { getState, dispatch }: { dispatch: Function; getState: Function }
) => {
  const RootState: RootState = getState();
  const audienceConfigConditions =
    RootState.botProfiles.audienceConfigConditions;
  const audienceConfigOptions = RootState.botProfiles.audienceConfigOptions;
  const validationErrors: Array<string> = [];

  let conditions: Array<any> = [];

  if (audienceConfigConditions.length === 0) {
    validationErrors.push("Atleast one condition required");
  }
  audienceConfigConditions.forEach((item, index) => {
    let condition = {
      ...item,
      error: "",
    };

    if (condition.conditionKey === null) {
      condition.error = "*Please select category";
      validationErrors.push("Please select category");
    } else {
      if (condition.conditionKey === "refferalPageUrl" || condition.conditionKey === "currentPageUrl") {
        console.log(
          condition.values &&
            Array.isArray(condition.values) &&
            (condition.operator === "is" || condition.operator === "isNot")
        );
        if (
          condition.values &&
          Array.isArray(condition.values) &&
          (condition.operator === "is" || condition.operator === "isNot")
        ) {
          condition.values.forEach((value) => {
            if (!validateURL(value + "")) {
              const conditionOption = audienceConfigOptions.find(
                (option) => condition.conditionKey === option.conditionKey
              );
              if (conditionOption) {
                condition.error = `*Please enter valid url`;
                validationErrors.push(
                  `Please enter valid url in ${conditionOption.conditionName.toLowerCase()}`
                );
              } else {
                condition.error = `*Please enter valid url`;
                validationErrors.push(
                  `Please enter valid url in ${condition.conditionKey}`
                );
              }
            }
          });
        }
      }
    }

    if (!condition.values || condition.values.length === 0) {
      const conditionOption = audienceConfigOptions.find(
        (option) => condition.conditionKey === option.conditionKey
      );

      if (conditionOption) {
        condition.error = `*Please fill out this field`;
        validationErrors.push(
          `Please fill out ${conditionOption.conditionName.toLowerCase()} field`
        );
      } else {
        if (condition.conditionKey) {
          condition.error = `*Please fill out this field`;
          validationErrors.push(
            `Please fill out ${condition.conditionKey.toLowerCase()} field`
          );
        } else {
          condition.error = `*Please select category`;
          validationErrors.push(`Please select category`);
        }
      }
    }

    conditions.push(condition);
  });

  if (Object.keys(validationErrors).length === 0) {
    // No validation errors
    try {
      await updateBotProfile({
        id: payload.profileId,
        audience_config: audienceConfigConditions.map((condition) => {
          return {
            id: condition.isNewlyAdded ? null : condition.id,
            value: condition.values,
            field: condition.conditionKey,
            compareType: condition.operator,
          };
        }),
      });

      await dispatch(
        fetchConfigureAudienceCondition({
          profileId: payload.profileId,
        })
      );

      return {
        error: false,
        validationErrors: false,
        conditions,
        errors: validationErrors,
      };
    } catch (error) {
      return {
        error: true,
        validationErrors: false,
        conditions,
        errors: validationErrors,
      };
    }
  } else {
    return {
      error: true,
      validationErrors: true,
      conditions,
      errors: validationErrors,
    };
  }
};
export default {
  fetchConfigureAudienceConditionThunk,
  updateConfigureAudienceConditionThunk,
};

export const fetchConfigureAudienceCondition = createAsyncThunk(
  "botProfiles/fetchConfigureAudienceCondition",
  fetchConfigureAudienceConditionThunk
);
export const updateConfigureAudienceCondition = createAsyncThunk(
  "botProfiles/updateConfigureAudienceCondition",
  updateConfigureAudienceConditionThunk
);
