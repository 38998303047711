import styles from "./ChatSetting.module.scss";
import NewRule from "./Children/ChatSettingSection/Children/SetupRoute/Children/NewRule/NewRule";
import LeftSideBarSection, {
  SideBarLinks,
  SubSettingLinks,
} from "./Children/LeftSideBarSection";
import { useParams } from "react-router-dom";
import SetCondition from "./Children/Engagement/Children/Greetings/Children/SetCondition/SetCondition";
import ChatSettingHome from "./Children/ChatSettingHome/ChatSettingHome";
import NewTargetedMessage from "./Children/Engagement/TargetedMessages/NewTargetedMessage/NewTargetedMessage";

const GetComponent = () => {
  const { settingName, subSettingName, grandSubSettingName, integrationId } =
    useParams();

  if (settingName === undefined) {
    return {
      ShowComp: ChatSettingHome,
      showSidebar: false,
    };
  }

  const SettingObj = SideBarLinks[settingName + ""];
  let showSidebar = SettingObj.showSideBar === false ? false : true;

  if (subSettingName !== undefined && subSettingName !== "") {
    if (
      SettingObj?.hasSubSetting !== undefined &&
      SettingObj?.hasSubSetting !== false
    ) {
      const SubSettingObj =
        SettingObj?.subSettingKey === null ||
          SettingObj?.subSettingKey === undefined
          ? SubSettingLinks[subSettingName + ""]
          : SubSettingLinks[SettingObj?.subSettingKey];

      showSidebar = SubSettingObj?.showSidebar === false ? false : true;

      return {
        ShowComp: SubSettingObj?.component,
        showSidebar: showSidebar,
      };
    }
  }

  return {
    ShowComp: SettingObj?.component,
    showSidebar: showSidebar,
  };
};
const ChatSetting = () => {
  const { ShowComp, showSidebar } = GetComponent();

  return (
    <>
      {showSidebar === true ? (
        <div className={`${styles.settingMain}`}>
          {/* <div className={``}> */}
          <div className={`${styles.leftSideBar}`}>
            <LeftSideBarSection />
            {/* ---live chat toggle--- */}
            {/* <div className={`d-flex justify-content-between`}>
          <p>Live Chat</p>
          <div className="form-check form-switch">
            <input
              data-lpignore="true"
              className="form-check-input"
              type="checkbox"
              id="mySwitch"
              name="darkmode"
              value="yes"
            />
          </div>
        </div> */}
          </div>
          <div className={`w-100 ${styles.mainContent}`}>
            {ShowComp && <ShowComp />}
            {/* ---LivechatWdiget component--- */}

            {/* <LiveChat /> */}

            {/* pre chat forms */}
            {/* <PreChatForm /> */}

            {/* ---canned response--- */}
            {/* <CannedResponse /> */}

            {/* ---chat assignment--- */}
            {/* <ChatAssignment /> */}

            {/* ---setup routing rules component--- */}
            {/* <SetupRoute />      */}

            {/* ---forward chat component---*/}
            {/* <ForwardChat /> */}

            {/* ---file share component--- */}
            {/* <FileShare /> */}

            {/* ---Goals component--- */}
            {/* <ChatGoal /> */}
            {/* ---sales tracker--- */}
            {/* <SalesTracker /> */}
          </div>
          {/* </div> */}
          {/* <ChatSettingHome /> */}
        </div>
      ) : (
        <>
          <div className={`w-100 `}>
            {ShowComp && <ShowComp />}
            {/* <KnowledgeBasedArticles />  */}
            {/* <CreateArticle /> */}
            {/* ----New Rule--- */}
            {/* <NewRule /> */}
            {/* <CustomKnowledgeBased />  */}
            {/* <SetCondition /> */}
          </div>
        </>
      )}
    </>
  );
};
export default ChatSetting;
