import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchAllGoalSettingData, resetGoalSettingsData } from "src/store/slices/liveChatSetting/Goals/GoalSetting.slice";
import { getRoutingRuleDataFromIdSelector } from "src/store/slices/liveChatSetting/RoutingRules/RoutingRulesSetting.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import styles from "./ChatGoals.module.scss";
import EditGoal from "./Children/EditGoal/EditGoal";
import GoalTable from "./Children/GoalTable/GoalTable";
import GoalTracker from "./Children/GoalTable/GoalTable";

import NewGoal from "./Children/NewGoal/NewGoal";
const ChatGoals = () => {
  const [newGoal, setNewGoal] = useState(false);

  const { subSettingName }: any = useParams();
  const goalId = subSettingName ? decodeURIComponent(subSettingName) : "";
  const navigate = useNavigate();

  const { integrationId } = useParams();
  const goalIntegrationId = integrationId ? decodeURIComponent(integrationId) : "";

 
  const dispatch = useAppDispatch();
  const {goalSettingData, goalSettingIdList, metaData,goalTypeOptions, fetchGoalSettingAjaxStatus} = useAppSelector((state) => state.goalSetting);
  const currentState = useRef({
    goalSettingData, 
    goalSettingIdList,
    metaData,
    goalTypeOptions,
    fetchGoalSettingAjaxStatus
  });

  const totalRules: any = useRef(metaData.total);
  useEffect(() => {
    totalRules.current = metaData.total;
  }, [metaData.total]);

  useEffect(() => {

      dispatch(resetGoalSettingsData());
      dispatch(fetchAllGoalSettingData(goalIntegrationId));
      
  }, []);

  const handleInfiniteScroll = useCallback(() => {
    if (totalRules.current !== null) {
      if (totalRules.current > 0) {
        dispatch(fetchAllGoalSettingData(goalIntegrationId));
      }
    }
  }, [metaData.total]);

 
  useEffect(()=>{

    if(goalId !== "" && goalId !== undefined){
      let goalData = goalSettingData[goalId] ?? null;
      if(goalId === "new" || goalData !== null){
        setNewGoal(true);
      }
    }else{
      setNewGoal(false);
    }

  },[goalId])

  const handleAddNew = () => {
    navigate('/live-chat/settings/'+goalIntegrationId+'/goals/new');
  };
  
  return (
    <div className={`${styles.Goals}`}>
      <h1 className={`${styles.GoalsHead}`}>Goals</h1>
      {!newGoal && (
        <>
          <p className={`${styles.GoalsSpan}`}>
            Goals are the true measure of a how the chat is performing. You can
            set up any beneficial conditions you want to reach, e.g. a sale or a
            sign up, and automatically tie them to chats. Whenever that goal is
            reached during a conversation, LiveChat will let you know
          </p>
          <button className={`${styles.addNewBtn}`} onClick={handleAddNew}>
            <span>
              <i className="fa-solid fa-plus"></i>
            </span>{" "}
            Add new goal
          </button>
          <div className="mt-4">
            { goalSettingData && Object.keys(goalSettingData).length !== 0 && (

            <GoalTable
            integrationId={goalIntegrationId}
            handleInfiniteScroll={handleInfiniteScroll}
            fetchGoalSettingAjaxStatus={fetchGoalSettingAjaxStatus}
            goalSettingData={goalSettingData}
            metaData={metaData}
            goalSettingIdList={goalSettingIdList}  
            />
            )}

          </div>
        </>
      )}

      {newGoal && (
        <>
          <NewGoal goalId={goalId} integrationId={goalIntegrationId} goalTypeOptions={goalTypeOptions}/>
          {/* <EditGoal /> */}
        </>
      )}
    </div>
  );
};
export default ChatGoals;
