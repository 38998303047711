import { Channel } from "pusher-js";

export type LiveChatTrafficDataUpdateCallback = (res: any) => void;

export const getLiveChatTrafficDataUpdationEvent = (
  channel: Channel,
  callback: LiveChatTrafficDataUpdateCallback
) => {
  channel.bind("live_chat_traffic_data_updation", callback);
};

export const getLiveChatTrafficDataUpdationEventUnbind = (
  channel: Channel,
) => {
  channel.unbind("live_chat_traffic_data_updation");
};