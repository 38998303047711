import { WebsiteSettingsData } from "./websiteSettings.slice";

const fetchallWebIntegrationsFulfilled = (
  state: WebsiteSettingsData,
  { payload }: any
) => {
  state.allWebIntegrations = payload;
  const filtered = payload.filter((res: any) => !res.url?.trim());
  if (filtered.length > 0) {
    state.createdIntId = filtered[0].integration_id;
  } else {
    state.createdIntId = null;
  }
  state.allWebIntAJAXStatus = "fulfilled";
};

const fetchWebIntegrationFulfilled = (
  state: WebsiteSettingsData,
  { payload }: any
) => {
  state.activeWebIntegration = payload;
  state.activeWebIntAJAXStatus = "fulfilled";
};

const createNewWebIntegrationFulfilled = (
  state: WebsiteSettingsData,
  { payload }: any
) => {
  state.createdIntId = payload.integration_id;
  state.createNewWebIntAJAXStatus = "fulfilled";
};
const updateTheWebIntegrationFulfilled = (
  state: WebsiteSettingsData,
  { payload }: any
) => {
  //commented below two lines as it is not needed anymore, update thunk itself refetching the data and updating the slice value
  // state.activeWebIntegration.store_url = payload.url;
  // state.activeWebIntegration.live_chat_enabled = payload.live_chat_enabled;
  state.updateNewWebIntAJAXStatus = "fulfilled";
};

const installShopifyWidgetFulfilled = (
  state: WebsiteSettingsData,
  { payload }: any
) => {
  //commented below two lines as it is not needed anymore, update thunk itself refetching the data and updating the slice value
  // state.activeWebIntegration.store_url = payload.url;
  // state.activeWebIntegration.live_chat_enabled = payload.live_chat_enabled;
  state.activeWebIntegration.installed_successfully = true;
};

export default {
  fetchallWebIntegrationsFulfilled,
  fetchWebIntegrationFulfilled,
  createNewWebIntegrationFulfilled,
  updateTheWebIntegrationFulfilled,
  installShopifyWidgetFulfilled,
};
