import styles from "./TableRow.module.scss";
import clock from "src/assets/images/clock.png";
import { OrderData } from "src/features/ReturnAutoWorkFlow/ReturnAutoWorkFlow.types";
import { IAmount } from "src/features/ReturnAutoWorkFlow/hooks/useReturnPreview";
import { useAppSelector } from "src/store/store";
import { formatMDYTimeStringDate } from "src/utils/dateLibrary";
interface TableHeaderProps {
  orderData: OrderData | null;
  searchText: string;
}

const TableHeader = ({ orderData, searchText }: TableHeaderProps) => {
  const userTimezone = useAppSelector(
    (state) => state.globals.currentUserData?.userTimezone,
  );
  function escapeRegex(string: string) {
    return string.replace(/[/\-\\^$*+?.()|[\]{}]/g, "\\$&");
  }

  const highlightText = (text: string, searchText: string) => {
    if (
      searchText === undefined ||
      searchText === null ||
      searchText?.trim() === ""
    ) {
      return text;
    } else {
      // escaping regex characters
      const escapedHighlight = escapeRegex(searchText);
      // Split on highlight term and include term into parts, ignore case
      const parts = text.split(new RegExp(`(${escapedHighlight})`, "gi"));
      // Return the highlighted text as JSX
      return (
        <>
          {parts.map((part, i) => {
            if (part.toLowerCase() === searchText.toLowerCase()) {
              return (
                <span
                  key={i}
                  className={styles.highlight}
                >
                  {part}
                </span>
              );
            } else {
              return part;
            }
          })}{" "}
        </>
      );
    }
  };

  return (
    <div>
      <div
        className={`d-flex align-items-center px-2 mb-2 ${styles.tableHead}`}
      >
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
          />
        </div>
        {orderData?.rowData?.map((rowData) => {
          let valueToRender;

          if (rowData?.type === "price") {
            const price = rowData?.value as IAmount;
            valueToRender = `${price.currencyCode}${price.amount}`;
          } else if (rowData?.type === "date") {
            if (userTimezone) {
              const dateInUserTimezone = formatMDYTimeStringDate(
                rowData?.value as string,
                userTimezone,
              );
              const formattedDateStr = dateInUserTimezone?.replace(
                /\b(am|pm)\b/g,
                (match) => match.toUpperCase(),
              );
              valueToRender = formattedDateStr?.toString() ?? "";
            } else {
              valueToRender = rowData.value?.toString() ?? "";
            }
          } else {
            valueToRender = rowData?.value?.toString() ?? "";
          }

          const highlightedValue = highlightText(valueToRender, searchText);

          return (
            <div className={`text-truncate ${styles.value}`}>
              <span className={`${styles.tablePara}`}>
                {rowData?.type === "date" ? (
                  <span className="me-1">
                    <img
                      src={clock}
                      alt="clock"
                      width={12}
                      height={12}
                    />
                  </span>
                ) : null}
                {highlightedValue ?? ""}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TableHeader;
