import { axiosJSON } from "src/globals/axiosEndPoints";

export interface step9to15ParamsPost {
  step_data: {
    fully_automate_current_step?: boolean; // not needed in v2
    transfer_to_agent?: boolean;
    confirmationMessage?: string
  };
  integrationId: number;
}

export async function fetchPostStep9(payload: step9to15ParamsPost) {
  const { data } = await axiosJSON.post(
    "/api/setting/automation/refund/step/9",
    payload
  );

  // const data = await postStep9;
  if (data.error === true) {
    throw new Error("Some Error Occurred while fetching data");
  }

  return data;
}

// let postStep9 = function (params: step9to15ParamsPost) {
//   return new Promise(function (resolve, reject) {
//     if (params) {
//       setTimeout(function () {
//         resolve({});
//       }, 2000);
//     } else {
//       reject(new Error("Something is not right!"));
//     }
//   });
// };
