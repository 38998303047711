/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import SearchBar from "src/components/SearchBar/SearchBar";
import Filter from "./children/Filter";
import MoreCols from "./children/MoreCols";
import styles from "./FilterSection.module.scss";

function SearchBarTicketsFilter() {
  const [searchValue, setSearchValue] = useState("");
  return (
    <>
      <SearchBar
        className={`${styles.tableSearchInput}`}
        inputClassName={`w-100`}
        placeholder="Search by customer name, E-mail and any other keyword"
        onSearch={(value: any) => {}}
        onChange={(e: any) => {
          setSearchValue(e.target.value);
        }}
        value={searchValue}
      />
    </>
  );
}

function FilterSection() {
  return (
    <div className={`row justify-content-start`} id="ticketsFilters">
      {/* <div className={`col-12 col-md-6 `}>
        <SearchBarTicketsFilter />
      </div> */}
      <div
        className={`col-12 d-flex justify-content-start ${styles.filterDiv}`}
      >
        <MoreCols />
        <Filter />
      </div>
    </div>
  );
}

export default FilterSection;
