import { axiosJSON } from "src/globals/axiosEndPoints";
import log from 'loglevel';
import { TicketsQuery } from "./getTicketsV2";

export type ChangeBulkTicketStatusPayload = {
  ticketStatusId: number,
  ticketIds?: string[],
  allTickets?: boolean; //if all the ticket select this value should be set to true
  filters?: TicketsQuery["filters"]; 
  isCustomerView?: boolean; //flag if it is a customer view, needed this cause in customer we will be selecting all the last tickets of the customer instead of all!
}

export async function changeBulkTicketStatus(servicePayload: ChangeBulkTicketStatusPayload) {
  const { data } = await axiosJSON.post(`/api/ticket/changeBulkTicketStatus`, servicePayload);
  if (data.err === true) {
    throw new Error(data.msg as string)
  }
  const normalizedData = normalizeData(data)
  // log.debug('change ticket status raw response:', data);
  // log.debug('change ticket status normalized response:', normalizedData);
  return normalizedData;
}


// convert incoming data to the required format
function normalizeData(response: any) {

  const normalizedData = response
  return normalizedData
}