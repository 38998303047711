/**
 * This file is the service file used for making api request.
 * It contains the getNotifyEnableDisableService function service which is explained below.
 *
 * @author Yash Aditya
 */

// Import statements for types
import { axiosJSON } from "src/globals/axiosEndPoints";

// Function to update the enable or disable notifications status
export const getNotifyEnableDisableService = async () => {
  const { data: res } = await axiosJSON.post(
    "/api/reports/notificationSettings/getEnableOrDisableNotifications"
  );

  if (res.err) {
    throw res.msg;
  }

  return res.enabled as boolean;
};
