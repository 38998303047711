import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";
import { AutomationAPIPayload } from "../createAutomation.service";

interface Payload {
  name: string;
  description: string;
}

export const createCategoryService = async ({
  payload,
  type,
}: AutomationAPIPayload<Payload>) => {
  const apiEndPoint = IS_TEST_ACTIVE
    ? `${process.env.REACT_APP_TEST_SITE_URL}/bugManagement/createCategory`
    : `/api/automation/${type}/issueCategory/add`;

  const { data: res } = await axiosJSON.post(apiEndPoint, payload);

  if (res.err || res.error) {
    throw res.msg || res.message || "Cannot create category";
  }

  return (res.data.issueCategoryId + "") as string;
};
