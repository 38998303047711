import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { showGlobalLoader } from "src/components/GlobalLoader/GlobalLoader";
import MergeModal from "src/components/MergeModal/MergeModal";
import useTicketViews from "src/hooks/useTicketViews";
import {
  resetInnerTicket,
  setLastSearchURL,
  setShowCernThread,
} from "src/store/slices/innerTicket/innerTicket.slice";
import { fetchAllMsgDraftStatuses } from "src/store/slices/ticketMsgDraftStatus/ticketMsgDraftStatus.slice";
import { setSelectedBtn } from "src/store/slices/ticketSidebar/ticketSidebar.slice";
import { fetchAllStatuses } from "src/store/slices/ticketStatus/ticketStatus.slice";
import {
  setAllColumnList,
  setCurrColumnList,
  setDefaultColumnList,
} from "src/store/slices/tickets/tickets.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import styles from "./Ticket.module.scss";
import CustomerSB from "./children/CustomerSB/CustomerSB";
import GlobalSearch from "./children/GlobalSearch/GlobalSearch";
import MainChat from "./children/MainChat/MainChat";
import CernThread from "./children/MainChat/children/CernThread/CernThread";
import InternalBotChat from "./children/MainChat/children/InternalBotChat/InternalBotChat";
import PathHeader from "./children/PathHeader/PathHeader";
import SetupToast from "./children/SetupToast/SetupToast";
import TicketSB from "./children/TicketSB/TicketSB";

let initialized = 0;

function Ticket() {
  const dispatch = useAppDispatch();
  // const location = useLocation();
  const { searchText, tableType, viewType }: any = useParams();
  const { showCernThread, sendCernMsgId, activeTicketInfo } = useAppSelector(
    (state) => state.innerTicket,
  );

  // Get Search params and check if it is first login
  const [searchParams] = useSearchParams();
  const isFirstLogin = useRef(searchParams.get("isFirstLogin") === "true");
  const location = useLocation();
  const sidebarAjaxStatus = useAppSelector(
    (state) => state.ticketSideBar.ajaxStatus,
  );

  const routerSearchText = searchText ? decodeURIComponent(searchText) : "";
  const scopes = useAppSelector(
    (state) => state.globals.currentUserData?.scopes,
  );
  const { allColumnList, defaultColumnsList } = useTicketViews();

  // to handle merge ticket modal
  const [showMergeModal, setShowMergeModal] = useState(false);
  const [init, setInit] = useState(false);
  const onMergeShow = () => {
    setShowMergeModal(true);
  };
  const onMergeHide = () => {
    setShowMergeModal(false);
  };

  if (initialized === 0) {
    dispatch(resetInnerTicket());
    initialized++;
  }
  useMemo(() => {
    dispatch(setSelectedBtn({ tableType }));
  }, [dispatch, tableType]);
  useEffect(() => {
    dispatch(setAllColumnList(allColumnList));
    dispatch(setDefaultColumnList(defaultColumnsList));
    dispatch(setCurrColumnList(defaultColumnsList));
    setTimeout(() => {
      showGlobalLoader("TicketDetails");
    }, 0);
    dispatch(fetchAllMsgDraftStatuses());
    dispatch(fetchAllStatuses());
    dispatch(
      setLastSearchURL({
        searchUrl: "",
        ticketRoute: "",
        searchUrlIsApplied: false,
      }),
    );
    return () => {
      initialized = 0;
    };
  }, [false]);

  useEffect(() => {
    dispatch(setShowCernThread({ show: false }));
  }, [location]);

  useEffect(() => {
    if (sidebarAjaxStatus === "fulfilled") {
      initialized++;
    }
    if (initialized > 1) {
      setInit(true);
    }
  }, [tableType, viewType, sidebarAjaxStatus]);

  if (init) {
    return (
      <div className={`d-flex ${styles.ticketWrapper}`}>
        <div
          className={`${isFirstLogin.current ? "pb-3" : "p-3"} ${
            styles.singleTicket
          }`}
        >
          {/* Setup Toast for First Login */}
          {isFirstLogin.current && <SetupToast />}
          <PathHeader />
          <div className="px-3 pt-2">
            <div className={`${styles.main}`}>
              {routerSearchText === "" ? (
                <>
                  {/* <TicketHeader /> */}
                  <div className={`d-flex ${styles.innerTicket}`}>
                    <TicketSB minimizeSB={showCernThread} />
                    <MainChat setShowMerge={setShowMergeModal} />
                    {showCernThread ? (
                      <CernThread
                        messageId={sendCernMsgId}
                        ticket_from={activeTicketInfo.ticket_from}
                      />
                    ) : (
                      <CustomerSB />
                    )}
                  </div>
                  {showMergeModal === true &&
                  scopes &&
                  scopes.includes("merge_tickets") ? (
                    <MergeModal
                      onShow={onMergeShow}
                      onHide={onMergeHide}
                      showModal={showMergeModal}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <GlobalSearch searchText={routerSearchText} />
              )}
            </div>
          </div>
        </div>
        <InternalBotChat initialticketData={activeTicketInfo} />
      </div>
    );
  } else {
    return <></>;
  }
}

export default Ticket;
