import { useState, useMemo } from "react";
import { Modal, Dropdown } from "react-bootstrap";
import SearchBar from "src/components/SearchBar/SearchBar";
import AddVariable from "./AddVariable/AddVariable";
import styles from "./Variables.module.scss";
import useGetVariables from "src/hooks/automation/useGetVariables";
import useSearchVariables from "src/hooks/automation/useSearchVariable";
import { IVariable } from "src/services/UiAutomation/ReturnExchange/Configuration/Variables/getAllVariables";
import Loader from "src/components/Loader";

// Props for the Variables component
interface Props {
  numberOfItems?: number;
  handleAddVariableOnClick?: (placeholder: IVariable) => void;
}

// The main Variables component
function Variables({ numberOfItems, handleAddVariableOnClick }: Props) {
  const [searchText, setSearchText] = useState<string>("");
  const [debouncedTerm, setDebouncedTerm] = useState<string>("");

  // Custom hook for getting all Variables
  const { variables, variablesIds } = useGetVariables();

  // Custom hook to get searched variable list
  const {
    isLoading,
    variables: dropdownVariables,
    variablesIds: dropdownVariableIds,
  } = useSearchVariables({
    searchTerm: debouncedTerm,
  });

  // Change the dropdown list only if the searched term or the incoming variables change
  const variablesList = useMemo(
    () => (debouncedTerm ? dropdownVariables : variables),
    [debouncedTerm, dropdownVariables, variables]
  );
  const variableIdsList = useMemo(
    () => (debouncedTerm ? dropdownVariableIds : variablesIds),
    [debouncedTerm, dropdownVariableIds, variablesIds]
  );

  // Function gets debounced search text as a parameter and sets it to state
  const handleSearch = (debouncedSearchText: any) => {
    setDebouncedTerm(debouncedSearchText);
  };

  // State for showing and hiding Modal
  const [showModal, setShowModal] = useState(false);
  const onShow = (e: any) => {
    e.stopPropagation();
    setShowModal(true);
  };
  const onHide = () => {
    setShowModal(false);
  };

  // State for showing and hiding the dropdown
  const [showDropdown, setShowDropdown] = useState(false);
  const setDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <div className={`pt-2`}>
      <h4 className={`${styles.lineText}`}>Available variables:</h4>
      <div className={`d-flex align-items-center flex-wrap`}>
        {variablesIds && variables
          ? variablesIds.map((id, idx) => {
              const { variableValue, variableKey } = variables[id];
              return idx < (numberOfItems ?? 1) ? (
                <div
                  className={`me-2 p-2 mb-2 ${styles.variableText}`}
                  role="button"
                  key={idx}
                  onClick={(e) => {
                    e.preventDefault();
                    if (handleAddVariableOnClick) {
                      handleAddVariableOnClick(variables[id]);
                    }
                  }}
                >
                  {" "}
                  <code>
                    {" "}
                    {variableKey} Eg: {variableValue}
                  </code>{" "}
                </div>
              ) : (
                ""
              );
            })
          : ""}{" "}
        <Dropdown drop="up" show={showDropdown} onToggle={setDropdown}>
          <Dropdown.Toggle
            className={`dropdown-toggle ${styles.showBtn}`}
            as={"div"}
            variant="success"
            onClick={setDropdown}
          >
            <span className={`cursor-pointer ${styles.seeAll}`}> See all</span>
          </Dropdown.Toggle>
          <Dropdown.Menu bsPrefix={`dropdown-menu pt-0 ${styles.variBox}`}>
            <div>
              <div
                className={`px-2 d-flex justify-content-between py-2 ${styles.headerCol}`}
              >
                <span className={`${styles.leadName}`}>All variables</span>
                <div className={`d-flex align-items-center`}>
                  <button
                    className={`btn btn-primary ${styles.addBtn}`}
                    onClick={onShow}
                  >
                    + Add
                  </button>
                  <Modal
                    show={showModal}
                    onHide={onHide}
                    dialogClassName={`${styles.modalDialog}`}
                    contentClassName={`${styles.modalContent}`}
                    centered={true}
                  >
                    <AddVariable
                      onHide={onHide}
                      handleInsert={handleAddVariableOnClick}
                    />
                  </Modal>
                  <span
                    className={`cursor-pointer ps-2  ${styles.closeDrop}`}
                    onClick={setDropdown}
                  >
                    {" "}
                    <i className="fa-solid fa-xmark"></i>
                  </span>
                </div>
              </div>
              <div className={`py-2 ${styles.variHeight}`}>
                {variableIdsList && variablesList ? (
                  variableIdsList.map((id, idx) => {
                    const { variableName, variableValue } = variablesList[id];
                    return (
                      <li key={idx}>
                        <span
                          className="dropdown-item"
                          onClick={(e) => {
                            if (handleAddVariableOnClick) {
                              handleAddVariableOnClick(variablesList[id]);
                            }
                            setDropdown();
                          }}
                        >
                          <p className={`mb-0 ${styles.varibleHead}`}>
                            {variableName}
                          </p>
                          <span className={`${styles.varibleContent}`}>
                            {" "}
                            Eg: {variableValue}
                          </span>
                        </span>
                      </li>
                    );
                  })
                ) : isLoading ? (
                  <Loader />
                ) : (
                  ""
                )}
              </div>
              <div className={`mx-1`}>
                <SearchBar
                  className={`${styles.search} form-control px-2`}
                  inputClassName={`${styles.input}`}
                  placeholder={`Search`}
                  onChange={(e: any) => {
                    setSearchText(e.target.value);
                  }}
                  onSearch={handleSearch}
                  value={searchText}
                />
              </div>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
}

// Function to update variables to Tiny MCE Message Section
export const addVariableOnClick = (
  variable: IVariable,
  ref: React.MutableRefObject<any>,
  inlineStyles?: string
) => {
  // Check if we have a new mention
  if (ref.current.addMentionRef.current.addMention) {
    // Adds a span with the new mention
    ref.current.addMentionRef.current.addMention({
      id: variable.id, // Used to identify the variable
      name: variable.variableKey, // Used to identify the text that is used inside the span
      inlineStyles: inlineStyles
        ? inlineStyles
        : "display: inline-block;color: #0B68BB;",
    });
  }
};

export default Variables;
