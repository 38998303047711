/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import {
  IUpdateKnowledgeBasedParams,
  updateChatSetting,
} from "src/services/LiveChat/Settings/updateChatSettingData";
import { fetchKnowledgeBasedData } from "src/store/slices/liveChatSetting/chatWidgetSetting/knowledgeBased/knowledgeBasedSetting.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";

function useCustomKnowledgeBased() {
  // States And Variables
  const { integrationId } = useParams(); // integrationId is the id of the page
  const pageIntegrationId = integrationId
    ? decodeURIComponent(integrationId)
    : "";

  // Fetching Knowledge Based Data
  const { knowledgeBasedData } = useAppSelector(
    (state) => state.knowledgeBasedSettings
  );

  const dispatch = useAppDispatch();
  const [chatWidgetTitle, setChatWidgetTitle] = useState(""); // Title of the chat widget
  const [chatWidgetPlaceholder, setChatWidgetPlaceholder] = useState(""); // Placeholder of the chat widget
  const [showSaveChanges, setShowSaveChanges] = useState(false); // Show save changes button

  const navigate = useNavigate();

  // Fetching Knowledge Based Data on page load
  useEffect(() => {
    dispatch(fetchKnowledgeBasedData(pageIntegrationId));
  }, []);

  // Setting the values of the states from the fetched data
  useEffect(() => {
    if (knowledgeBasedData !== null) {
      setChatWidgetTitle(knowledgeBasedData.chatWidgetTitle);
      setChatWidgetPlaceholder(knowledgeBasedData.chatWidgetPlaceholder);
    }
  }, [knowledgeBasedData]);

  // Checking if the data is changed or not to show the save changes button
  useEffect(() => {
    if (knowledgeBasedData !== null) {
      if (
        chatWidgetTitle === knowledgeBasedData.chatWidgetTitle &&
        chatWidgetPlaceholder === knowledgeBasedData.chatWidgetPlaceholder
      ) {
        setShowSaveChanges(false);
      } else {
        setShowSaveChanges(true);
      }
    }
  }, [chatWidgetTitle, chatWidgetPlaceholder]);

  // Handling the edit button
  const handleEdit = useCallback(() => {
    let payload: IUpdateKnowledgeBasedParams = {
      chatWidgetTitle: chatWidgetTitle,
      chatWidgetPlaceholder: chatWidgetPlaceholder,
    };

    // Calling the updateChatSetting function to update the data
    updateChatSetting({
      integrationId: pageIntegrationId,
      knowledgeBased: payload,
    })
      // If the data is updated successfully then show the success toast and fetch the data again
      .then((res) => {
        pushTheToast({
          type: "success",
          text: "Successfully changed",
          position: "top-right",
        });
        dispatch(fetchKnowledgeBasedData(pageIntegrationId));
        setShowSaveChanges(false);
      })
      .catch((err) => {
        pushTheToast({
          type: "danger",
          text: "Something went wrong!",
          position: "top-right",
        });
      });
  }, [chatWidgetTitle, chatWidgetPlaceholder, pageIntegrationId]);

  //  Handling the cancel button
  const handleCancel = useCallback(() => {
    navigate("/live-chat/settings/" + pageIntegrationId + "/Knowledge");
  }, [navigate, pageIntegrationId]);

  return {
    chatWidgetTitle,
    setChatWidgetTitle,
    chatWidgetPlaceholder,
    setChatWidgetPlaceholder,
    showSaveChanges,
    handleEdit,
    handleCancel,
  };
}

export default useCustomKnowledgeBased;
