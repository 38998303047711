import { useCallback, useEffect, useRef, useState } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { AddTicketTagsPayload } from "src/services/TicketService/addTicketTags";
import TicketService from "src/services/TicketService/TicketService";
import AddTagsDropDown, { AddTagProps, OneTag } from "../AddTagsDropDown";
import styles from "./AddTagsButton.module.scss";
import TagItemDisplay from "./children/TagItemDisplay/TagItemDisplay";
import Popover from "react-bootstrap/Popover";
import addTag from "src/assets/images/AddTags.svg";
interface AddTagsButtonProps extends AddTagProps {
  canChange: boolean;
}

function AddTagsButton({
  ticketIds,
  activeTags,
  setActiveTags,
  canChange,
}: AddTagsButtonProps) {
  const [showDD, setShowDD] = useState(false);
  const [showTT, setShowTT] = useState(false);
  const DDRef: any = useRef(null);
  const DDRefBtn: any = useRef(null);

  const handleOnClickRemoveTagItem = (tag: OneTag) => {
    setShowDD(false);
    const servicePayload: AddTicketTagsPayload = {
      ticketIds: ticketIds.split(","),
      tags: activeTags
        .filter((tag2) => tag2.id !== tag.id)
        .map((tag2) => tag2.id),
    };
    TicketService.addTicketTagsApi(servicePayload)
      .then((res) => {
        setActiveTags(activeTags.filter((tag2) => tag2.id !== tag.id));
      })
      .catch((err) => {});
  };
  const onClickWindow = useCallback((e: any) => {
    if (DDRef.current && DDRefBtn.current) {
      if (
        !DDRef.current.contains(e.target) &&
        DDRefBtn.current != e.target &&
        !DDRefBtn.current.contains(e.target)
      ) {
        setShowDD(false);
      }
    }
    if (
      e.target.closest("#tooltip-bootom-end-add-tag") === null &&
      e.target.closest(`.${styles.moreTags}`) === null
    ) {
      setShowTT(false);
    }
  }, []);
  useEffect(() => {
    window.addEventListener("click", onClickWindow);
    return () => {
      window.removeEventListener("click", onClickWindow);
    };
  }, [onClickWindow]);

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body bsPrefix={`popover-body ${styles.popOver}`}>
        <span className={`py-0 ${styles.hoverTag}`}> Tags</span>
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      {activeTags.length === 0 && canChange && (
        <div
          className={`d-flex align-items-center ms-4 me-2 ${styles.addTagBtn}`}
          onClick={(e) => {
            setShowDD(!showDD);
          }}
          ref={DDRefBtn}
        >
          <OverlayTrigger
            trigger={["hover", "focus"]}
            placement="right"
            overlay={popover}
          >
            <div>
              <img
                src={addTag}
                alt="addTag"
              />
            </div>
          </OverlayTrigger>
        </div>
      )}

      {/* Selected tags list */}
      {activeTags.length > 0 && (
        <div className={`d-flex`}>
          <span className={`m-auto d-flex ${styles.activeTagList}`}>
            {activeTags.slice(0, 2).map((tag, index) => (
              <TagItemDisplay
                tag={tag}
                key={index}
                onClickRemove={handleOnClickRemoveTagItem}
              />
            ))}
          </span>
          {activeTags.length > 2 && (
            <OverlayTrigger
              placement={"bottom"}
              show={showTT}
              overlay={
                <Tooltip
                  id={`tooltip-bootom-end-add-tag`}
                  className={`${styles.tooltip}`}
                  onMouseEnter={() => setShowTT(true)}
                  onMouseLeave={() => setShowTT(false)}
                >
                  {activeTags.slice(2).map((tag, index) => (
                    <TagItemDisplay
                      tag={tag}
                      key={index}
                      onClickRemove={handleOnClickRemoveTagItem}
                    />
                  ))}
                </Tooltip>
              }
            >
              <span
                className={`my-auto mx-1 px-2 d-flex align-items-center ${styles.moreTags}`}
                onMouseEnter={() => setShowTT(true)}
                onMouseLeave={() => setShowTT(false)}
              >
                <span>...{activeTags.length - 2}</span>
                <span className="ps-1"> +</span>
              </span>
            </OverlayTrigger>
          )}
          {canChange && (
            <OverlayTrigger
              trigger={["hover", "focus"]}
              placement="right"
              overlay={popover}
            >
              <span
                className={`fas solid fa-plus d-inline-block m-auto ${styles.addTagsPlusIcon}`}
                ref={DDRefBtn}
                onClick={(e) => {
                  setShowDD(!showDD);
                }}
              ></span>
            </OverlayTrigger>
          )}
        </div>
      )}
      <div
        ref={DDRef}
        className="h-0 w-0"
      >
        {showDD && canChange && (
          <AddTagsDropDown
            ticketIds={ticketIds}
            activeTags={activeTags}
            setActiveTags={(activeTags) => {
              setActiveTags(activeTags);
              setShowDD(false);
            }}
          />
        )}
      </div>
    </>
  );
}

export default AddTagsButton;
