import { axiosJSON } from "src/globals/axiosEndPoints";

export interface BrandData {
  isVerified: boolean;
  id: number | string;
  name: string;
  email: string;
  signature: string;
  enabled: boolean;
  isDefault: boolean;
  isActive: boolean;
  imageURL: null | string;
  isPublicAttachmentUrl?: boolean;
  disabledUserIds: Array<string | number>;
  hasPermission: boolean;
  emailIntegrationId?: number | null;
}

export interface GetAllBrandData {
  brands: { [brand: number | string]: BrandData };
  brandIds: Array<number | string>;
  metaData: {
    count: number;
    total: number;
  };
}

export interface GetAllBrandParam {
  limit?: number;
  start?: number;
  type?: "all" | "agent";
  signatureType: "filtered" | "unfiltered";
}

export async function getAllBrands(params: GetAllBrandParam) {
  const { data: res } = await axiosJSON.get("/api/brand/getBrands", { params });

  if (res.err === true) {
    throw new Error(res.msg as string);
  }

  const ret: GetAllBrandData = {
    brands: {},
    brandIds: [],
    metaData: {
      count: 0,
      total: 0,
    },
  };
  ret.metaData.count = res.metaData.count;
  ret.metaData.total = res.metaData.total;
  res.data.forEach((brand: BrandData) => {
    ret.brands[brand.id] = brand;
    ret.brandIds.push(brand.id);
  });

  return ret;
}

export interface SearchBrandPayload {
  limit?: number;
  start?: number;
  searchTerm: string;
  type?: "all" | "agent";
  signatureType?: "filtered" | "unfiltered",
  onlyVerified?: boolean
}

export async function searchBrands(payload: SearchBrandPayload) {
  const { data: res } = await axiosJSON.post(
    "/api/brand/searchBrands",
    payload
  );

  if (res.err === true) {
    throw new Error(res.msg as string);
  }

  const ret: GetAllBrandData = {
    brands: {},
    brandIds: [],
    metaData: {
      count: 0,
      total: 0,
    },
  };
  ret.metaData.count = res.metaData.count;
  ret.metaData.total = res.metaData.totalCount;
  res.data.forEach((brand: BrandData) => {
    ret.brands[brand.id] = brand;
    ret.brandIds.push(brand.id);
  });

  return ret;
}

export interface OutBoundEmail {
  id: number;
  emailName: string;
  emailAddress: string;
  isEmailVerified: boolean;
  isDomainVerified: boolean;
}

export interface AllOutboundEmails {
  outboundEmails: { [key: number | string]: OutBoundEmail };
  outboundEmailsIds: Array<number | string>;
  metaData: {
    total: number;
    count: number;
  };
}

export interface GetAllOutboundEmailParam {
  searchTerm?: string;
  start: number;
  limit: number;
}

export async function getAllOutboundEmails(payload: GetAllOutboundEmailParam) {
  const { data } = await axiosJSON.post(
    `/api/setting/integration/getAllEmailIntegrations`,
    payload
  );
  if (data.err === true || data.error === true) {
    throw new Error(data.msg as string);
  }

  const ret: AllOutboundEmails = {
    outboundEmails: {},
    outboundEmailsIds: [],
    metaData: {
      total: 0,
      count: 0,
    },
  };
  ret.metaData.count = data.metaData.count;
  ret.metaData.total = data.metaData.total;
  data.data.forEach((outboundEmail: OutBoundEmail) => {
    ret.outboundEmails[outboundEmail.id] = outboundEmail;
    ret.outboundEmailsIds.push(outboundEmail.id);
  });

  return ret;
}
