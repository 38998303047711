import { MessagesData } from "src/services/LiveChat/liveChatHistory/getLiveChatCustomerAllMessages.service";
import ChatCreated, {
  LiveChatHistoryCustomerDataUI,
} from "../ChatCreated/ChatCreated";
import ViewMsgCommon from "../ViewMsgCommon/ViewMsgCommon";
import TicketCreated from "../TicketCreated/TicketCreated";
import TicketEventMsg from "../TicketEventMsg/TicketEventMsg";
import CernAIMsg from "src/routes/Ticket/children/MainChat/children/CernAIMsg/CernAIMsg";

function RenderInnerMsg({
  scrollToRef,
  messageData,
  customerData,
}: {
  messageData: MessagesData;
  scrollToRef?: React.MutableRefObject<any>;
  customerData?: LiveChatHistoryCustomerDataUI;
}) {
  return (
    <div
      ref={scrollToRef}
      className={`pt-1`}
    >
      {messageData.dataType === "ticket" ? (
        <TicketCreated {...messageData} />
      ) : messageData.dataType === "chat" ? (
        <ChatCreated
          {...messageData}
          customerData={customerData}
        />
      ) : messageData.dataType === "Event" ? (
        <TicketEventMsg
          message={messageData.message}
          time={messageData.messageDateTimeUTC}
        />
      ) : messageData.dataType === "NoteBotAiQuery" ? (
        <CernAIMsg
          messageData={messageData}
          hideCernThreadCount={true}
        />
      ) : (
        <ViewMsgCommon {...messageData} />
      )}
    </div>
  );
}

export default RenderInnerMsg;
