import loadingGif from "src/assets/images/loading.gif";
import styles from "./Loader.module.scss";
import darkLoader from "src/assets/images/darkLoader.gif";

function Loader({ className, imgClassName, ...props }: any) {
  return (
    <div className={`mb-auto ${styles.loading} ${className}`} {...props}>
      <img src={loadingGif} className={`img-fluid ${imgClassName}`} />
    </div>
  );
}

/**
 * DarkLoader component
 * used in the kb helpcenter preview
 */
export function DarkLoader({ className, imgClassName, ...props }: any) {
  return (
    <div className={`mb-auto ${styles.darkLoading} ${className}`} {...props}>
      <img src={darkLoader} className={`img-fluid ${imgClassName}`} />
    </div>
  );
}

export default Loader;
