import React, { useState } from "react";
import styles from "../../LiveChatWidget.module.scss";
import shoppify from "src/assets/images/shopify.png";
import browser from "src/assets/images/internet.png";
import { useAppSelector } from "src/store/store";

function RadioButtons({
  selectedRadio,
  setSelectedRadio,
  disableShopifyButton,
  disableOtherWebsiteButton,
}: {
  selectedRadio: string;
  setSelectedRadio: Function;
  disableShopifyButton: boolean;
  disableOtherWebsiteButton: boolean;
}) {
  const ui_visibility = useAppSelector(
    (state) => state.globals.currentUserData?.ui_visibility
  );
  return (
    <div className={`d-flex flex-wrap  mb-3`}>
      {/* Radio for Shopify Store */}
      {/* 01 Dec 22  Commenting the shopify store button as it is not implemented right now*/}
      {ui_visibility?.includes("integration_shopify_view") && (
        <div
          className={`${styles.selectOption} me-2 me-md-3 mb-2 mb-lg-0 ${
            selectedRadio === "Shopify Store" ? styles.activeDiv : ""
          } ${
            disableShopifyButton === true ? styles.disabledSelectOption : ""
          }`}
        >
          <div
            className={`form-check d-flex align-items-center ${
              disableShopifyButton ? "" : "cursor-pointer"
            }`}
            onClick={() => {
              if (!disableShopifyButton) {
                setSelectedRadio("shopify");
              }
            }}
          >
            <div>
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                value="shopify"
                checked={selectedRadio === "shopify"}
                onChange={(e) => {
                  setSelectedRadio(e.target.value);
                }}
                disabled={disableShopifyButton}
              />
            </div>
            <div>
              <img src={shoppify} alt="shopify" className="me-1" />
            </div>
            <div>
              <span className={`${styles.selectOptionPara}`}>
                Shopify Store
              </span>
            </div>
          </div>
        </div>
      )}

      {/* Radio for Other Website  */}
      <div
        className={`${styles.selectOption} ${
          selectedRadio === "otherWebsite" ? styles.activeDiv : ""
        } ${
          disableOtherWebsiteButton === true ? styles.disabledSelectOption : ""
        }`}
      >
        <div
          className={`form-check d-flex align-items-center ${
            disableOtherWebsiteButton ? "" : "cursor-pointer"
          }`}
          onClick={() => {
            if (!disableOtherWebsiteButton) {
              setSelectedRadio("otherWebsite");
            }
          }}
        >
          <div>
            <input
              className="form-check-input"
              type="radio"
              name="flexRadioDefault"
              value="otherWebsite"
              checked={selectedRadio === "otherWebsite"}
              onChange={(e) => {
                setSelectedRadio(e.target.value);
              }}
              disabled={disableOtherWebsiteButton}
            />
          </div>
          <div>
            <img src={browser} alt="shoppify" className="me-1" />
          </div>
          <div>
            <span className={`${styles.selectOptionPara}`}>Other Website</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RadioButtons;
