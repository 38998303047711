import { useCallback, useEffect, useRef } from "react";
import { Spinner } from "react-bootstrap";
import AxiosImg from "src/components/AxiosImg";
import InfiniteScroll from "src/components/InfiniteScroll";
import Loader from "src/components/Loader";
import SearchBar from "src/components/SearchBar/SearchBar";
import UserAvatar from "src/components/UserAvatar";
import {
  areAllAgentSelectedForAssignToSelector,
  isAgentIdSelectedForAssignToSelector,
  removeAgentFormAssignToSelect,
  removeAllAgentForAssignToSelect,
  resetAssignToAgentList,
  selectAgentForAssignToSelect,
  selectAllAgentForAssignToSelect,
} from "src/store/slices/liveChatSetting/chatWidExchMsg/chatWidExchMsg.slice";
import { fetchAllAssignToAgentsList } from "src/store/slices/liveChatSetting/chatWidExchMsg/chatWidExchMsg.thunks";
import { useAppDispatch, useAppSelector } from "src/store/store";
// import styles from "../../AssignChatTo.module.scss";
import styles from "./AssignChatToAgentList.module.scss";
interface UserItemProp {
  user: any;
}

const AgentListItem = ({ user }: UserItemProp) => {
  const dispatch = useAppDispatch();

  const isAgentSelected = useAppSelector((state) =>
    isAgentIdSelectedForAssignToSelector(state, user.id)
  );

  const handleOnChangeCheckbox = () => {
    if (isAgentSelected) {
      dispatch(removeAgentFormAssignToSelect(user.id));
    } else {
      dispatch(selectAgentForAssignToSelect(user.id));
    }
  };

  return (
    <div
      className={`mt-1 py-1 d-flex align-items-center ${
        isAgentSelected ? styles.addBoxSelected : styles.addBox
      }`}
      onClick={handleOnChangeCheckbox}
    >
      <div>
        <input
          checked={isAgentSelected}
          type={"checkbox"}
          className={`form-check-input ${styles.agentCheck}`}
          onChange={() => {}}
        />
      </div>
      <span className="ps-2 d-flex align-items-center">
        <span className={`me-2 ${styles.profileIcon}`}>
          {user.imageURL && user.imageURL.length !== 0 ? (
            <AxiosImg
              url={user.imageURL}
              className={`${styles.dropdownItemProfileImg}`}
              isDirectURL={user.isPublicAttachmentUrl}
            />
          ) : (
            <UserAvatar name={user.name || "NA"} size={25} />
          )}
        </span>
        <div className="">
          <div className={`text-truncate ${styles.agentName}`}>{user.name}</div>
          <div className={`text-truncate ${styles.agentEmail}`}>
            {user?.email}
          </div>
        </div>
      </span>
    </div>
  );
};

const AssignChatToAgentList = ({
  targetRef,
  searchText,
  setSearchText,
  showSaveLoader,
  setShowSaveLoader,
  handleCancel,
  handleOnApply,
  setShowAgentModal,
  cernAssigned,
}: any) => {
  const {
    agentIds,
    agents,
    assignToAgentListAJAXStatus,
    agentMeta,
    selectedAgentIds,
  } = useAppSelector((state) => state.chatWidExhMsg);
  const dispatch = useAppDispatch();

  const areAllAgentSelected = useAppSelector(
    areAllAgentSelectedForAssignToSelector
  );

  useEffect(() => {
    dispatch(resetAssignToAgentList());
    dispatch(fetchAllAssignToAgentsList({}));
  }, []);

  const currentState = useRef({
    initialSearch: true,
    assignToAgentListAJAXStatus: assignToAgentListAJAXStatus,
    searchText: searchText,
  });

  useEffect(() => {
    currentState.current.assignToAgentListAJAXStatus =
      assignToAgentListAJAXStatus;
    currentState.current.searchText = searchText;
  }, [assignToAgentListAJAXStatus, searchText]);

  /* Handle infinite scroll */
  const handleInfiniteScroll = useCallback(() => {
    if (currentState.current.assignToAgentListAJAXStatus === "fulfilled") {
      dispatch(
        fetchAllAssignToAgentsList({
          searchTerm: currentState.current.searchText,
        })
      );
    }
  }, []);

  /* Handle search */
  const handleSearch = useCallback(() => {
    if (
      currentState.current.initialSearch === false &&
      currentState.current.assignToAgentListAJAXStatus === "fulfilled"
    ) {
      dispatch(resetAssignToAgentList());
      dispatch(
        fetchAllAssignToAgentsList({
          searchTerm: currentState.current.searchText,
        })
      );
    }
    currentState.current.initialSearch = false;
  }, []);
  useEffect(() => {
    /**
     * close dropdown if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (selectedAgentIds.length === 0 && cernAssigned === false) {
        setShowAgentModal(true);
      } else {
        if (targetRef.current && !targetRef.current?.contains(event.target)) {
          handleCancel();
        }
      }
    }
    setTimeout(() => {
      // Bind the event listener
      document.addEventListener("click", handleClickOutside);
    }, 0);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("click", handleClickOutside);
    };
  }, [targetRef, selectedAgentIds]);

  const handleOnSelectAll = useCallback(() => {
    if (areAllAgentSelected) {
      dispatch(removeAllAgentForAssignToSelect({ initialValue: [] }));
    } else {
      dispatch(selectAllAgentForAssignToSelect());
    }
  }, [areAllAgentSelected]);

  return (
    <div className={`${styles.assignDropDown}`}>
      <div className={`${styles.searchMain}`}>
        <SearchBar
          value={searchText}
          className={`${styles.search}`}
          inputClassName={`${styles.input}`}
          placeholder={`Search assignee`}
          onChange={(e: any) => {
            setSearchText(e.target.value);
          }}
          onSearch={handleSearch}
        />
      </div>
      {agentIds.length !== 0 && (
        <div className="ms-3 mt-2 mb-1 d-flex align-items-center">
          <div>
            <input
              id={"assignChatSelectAll"}
              checked={areAllAgentSelected}
              className={`form-check-input ${styles.agentCheck}`}
              type={"checkbox"}
              onChange={handleOnSelectAll}
            />
          </div>

          <label
            role="button"
            className={`${styles.selectAllText} ps-2`}
            htmlFor={"assignChatSelectAll"}
          >
            Select All
          </label>
        </div>
      )}

      <div className={`${styles.userListContainer} text-start`}>
        <InfiniteScroll
          className={`${styles.infScroll}`}
          loadMore={handleInfiniteScroll}
          initialDataLoaded={false}
          hideClassDisplayFlex={true}
          hasMore={
            assignToAgentListAJAXStatus === "rejected"
              ? false
              : agentMeta.total === null || agentMeta.total === 0
              ? true
              : agentIds.length < agentMeta.total
          }
          loader={
            assignToAgentListAJAXStatus === "pending" &&
            agentIds.length === 0 ? (
              <Loader
                style={{
                  marginTop: "-90px",
                }}
              />
            ) : undefined
          }
          errorMessage={
            agentIds.length === 0 &&
            (assignToAgentListAJAXStatus === "rejected" ||
              assignToAgentListAJAXStatus === "fulfilled") ? (
              <div
                className={`m-auto ${styles.noAgentFound} ${styles.loaderCenter} text-center`}
              >
                No Agent Found
              </div>
            ) : undefined
          }
        >
          {agentIds.map((agentId) => {
            const agent = agents[agentId];
            return <AgentListItem key={agent.id} user={agent} />;
          })}
        </InfiniteScroll>
      </div>
      {agentIds.length !== 0 ? (
        <div
          className={`d-flex justify-content-between pb-2 pt-3 ${styles.bottomContainer}`}
        >
          {/* <div
            role="button"
            className={`px-3 py-1 ${styles.cancelBtn}`}
            onClick={handleCancel}
          >
            Cancel
          </div> */}
          <button
            className={`px-3 py-1 ${styles.cancelBtn}`}
            onClick={handleCancel}
          >
            Cancel
          </button>

          {/* <div
            role="button"
            className={`px-3 py-1 ${styles.applyBtn}`}
            onClick={(e: any) => {
              if (!showSaveLoader) {
                handleOnApply();
              }
            }}
          >
            {showSaveLoader === true ? (
              <Spinner
                className="my-auto mx-1"
                animation="border"
                color="white"
                size="sm"
              />
            ) : (
              "Apply"
            )}
          </div> */}
          <button
            className={`px-3 py-1 ${styles.applyBtn}`}
            onClick={(e: any) => {
              if (!showSaveLoader) {
                handleOnApply();
              }
            }}
          >
            {showSaveLoader === true ? (
              <Spinner
                className="my-auto mx-1"
                animation="border"
                color="white"
                size="sm"
              />
            ) : (
              "Apply"
            )}
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default AssignChatToAgentList;
