import { axiosJSON } from "src/globals/axiosEndPoints";

interface FormElementOption {
  elementId: number;
  elementLabel: string;
  elementType: string;
}

export interface Step13GetFormElementOptions {
  data: FormElementOption[];
}

export async function fetchGetStep13FormElementOptions() {
  const { data } = await axiosJSON.get(
    "/api/setting/automation/refund/step/13/getFormElementOptions"
  );

  if (data.err === true) {
    throw new Error("Some Error Occurred while fetching data");
  }

  return data.data;
}
