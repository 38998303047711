import React from "react";
import styles from "./TemplateForm.module.scss";
import { Form } from "react-bootstrap";
import QuillEditorContainer from "src/components/QuillEditorContainer";
import TinyMCEContainer from "src/components/TinyMCEContainer";
import { v4 as uuid } from "uuid";

const TemplateForm = ({
  selectedCategory,
  templateBody,
  setTemplateBody,
  templateHeading,
  setTemplateHeading,
  selectedCategoryError,
  templateHeadingError,
  templateBodyError,
}: {
  selectedCategory: any;
  templateBody: any;
  setTemplateBody: any;
  templateHeading: any;
  setTemplateHeading: any;
  selectedCategoryError: boolean;
  templateHeadingError: boolean;
  templateBodyError: boolean;
}) => {
  return (
    <>
      <div className={`${styles.formWrapper}`}>
        <div>
          <Form.Group className="" controlId="exampleForm.ControlInput1">
            <Form.Label className={`${styles.formLabel}`}>
              Template Heading:
            </Form.Label>
            <Form.Control
              type="text"
              className={`${styles.inputBox} ${
                templateHeadingError
                  ? styles.inputErrBorder
                  : styles.inputBorderNone
              }`}
              placeholder="Type here"
              value={templateHeading}
              onChange={(e) => {
                setTemplateHeading(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group
            className="mb-3 mt-2"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Label className={`${styles.formLabel}`}>Template:</Form.Label>
            <div
              className={`p-3 ${styles.quillBox} ${
                templateBodyError
                  ? styles.quillBoxErrorBorder
                  : styles.quillBoxBorder
              }`}
            >
              <div>
                <TinyMCEContainer
                  value={templateBody}
                  onChange={(value) => {
                    setTemplateBody(value);
                  }}
                  options={{
                    placeholder: "Type your text here...",
                    min_height: 315,
                  }}
                  uniqueID={uuid()}
                />
              </div>
            </div>
          </Form.Group>
        </div>
      </div>
    </>
  );
};

export default TemplateForm;
