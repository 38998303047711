import { axiosJSON } from "src/globals/axiosEndPoints";

interface IDraftMsg {
  msgId?: number | null;
  ticketId: number;
  msgContent: string;
}

export async function addEditDraftMsg(servicePayload: IDraftMsg) {
  if (servicePayload.msgId == null) {
    delete servicePayload.msgId;
  }
  const { data } = await axiosJSON.post(
    `/api/ticket/editOrAddDraftMsg`,
    servicePayload
  );
  if (data.err === true) {
    throw new Error(data.msg as string);
  }
  return data.data;
}
