import { useContext } from "react";
import { ModalContext, ModalProvider } from "../../../../Modal/ModalContext";
import OrderDetailsModal from "../../../../Order/children/OrderDetailsModal";
import AllOrdersContext from "../../../AllOrdersContext";
import styles from "../AllOrderSubRow.module.scss";

const RowOrderItemContext = ({
  order,
  setShowModal,
}: {
  order: any;
  setShowModal: Function;
}) => {
  const modalContext = useContext(ModalContext);
  const allOrdersContext = useContext(AllOrdersContext);

  function onCheckboxChanged(ev: React.ChangeEvent<HTMLInputElement>) {
    const { checked } = ev.target;
    const id = ev.target.getAttribute("data-id");
    if (id !== null) {
      allOrdersContext.upsertOrderChecked({ id: Number.parseInt(id), checked });
    }
  }

  return (
    <div
      className={`d-flex py-2 ${styles.TableRowMap}`}
      onClick={(e: any) => {
        if (!e.target.classList.contains("ignoreClick")) {
          modalContext.handleModal(
            <OrderDetailsModal orderId={order?.orderId} />
          );
        }
      }}
    >
      <div className={`${styles.TableOrders}`}>
        <div className="form-check">
          <input
            className="form-check-input ignoreClick cursor-pointer"
            type="checkbox"
            data-id={order.orderId}
            checked={
              allOrdersContext?.getOrderCheckedById(order.orderId)?.checked ??
              false
            }
            onChange={onCheckboxChanged}
            style={{
              border: "1px solid #707070",
              borderRadius: "4px",
              width: "15px",
              height: "15px",
            }}
          />
          <span className={`ms-2 ${styles.tableHeading}`}>
            {order?.orderName}
          </span>
        </div>
      </div>
      <div className={`${styles.TableDate}`}>
        <span className={`${styles.tableHeading}`}>{order?.createdAt}</span>
      </div>
      <div className={`${styles.TableCustomer}`}>
        <span className={`${styles.tableHeading}`}>{order?.customerName} </span>
      </div>
      <div className={`${styles.TableTotal}`}>
        <span className={`${styles.tableHeading}`}>
          {order?.orderTotal?.amount}
        </span>
      </div>
      <div className={`${styles.TablePayment}`}>
        <span
          className={`${styles.tableHeading} ${styles.paymentStatus} px-3 py-1`}
        >
          <div className={`${styles.round}`}></div>
          <span className="ms-1">{order?.paymentStatus?.name}</span>
        </span>
      </div>
      <div className={`${styles.TableFullfillment}`}>
        <span className={`${styles.tableHeading} ${styles.fullFill} px-3 py-1`}>
          <span className={`${styles.circleDot}`}>
            <i className="fa-solid fa-circle-dot"></i>
          </span>
          <span className="ms-1">{order?.fulfillmentStatus?.name}</span>
        </span>
      </div>
      <div className={`${styles.TableItem}`}>
        <span className={`${styles.tableHeading}`}>{order?.items} item </span>
      </div>
      <div className={`${styles.TableMethod}`}>
        <span className={`${styles.tableHeading}`}>
          {order?.deliveryMethod}
        </span>
      </div>
      <div className={`${styles.TableTags}`}>
        {order.tags.length === 0 || order.tags === undefined ? (
          ""
        ) : (
          <span className={`${styles.tableHeading} ${styles.Tags}`}>
            {order?.tags}
          </span>
        )}
      </div>
    </div>
  );
};

function RowOrderItem({
  order,
  setShowModal,
  isCheckAll,
  setIsCheckAll,
}: {
  order: any;
  setShowModal: Function;
  isCheckAll: boolean;
  setIsCheckAll: Function;
}) {
  return (
    <ModalProvider>
      <RowOrderItemContext order={order} setShowModal={setShowModal} />
    </ModalProvider>
  );
}
export default RowOrderItem;
