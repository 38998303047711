export function dateFormate( data: string, isMonthShort = false ) {
  const formatter = new Intl.DateTimeFormat( "default", { month: "short" } );
  const timeAndDate = new Date( data );
  const hours = timeAndDate.getHours();
  const minutes = timeAndDate.getMinutes();
  const date = timeAndDate.getDate();
  const month: any = isMonthShort
    ? formatter.format( timeAndDate )
    : timeAndDate.getMonth();
  const year = timeAndDate.getFullYear();
  return isMonthShort
    ? `${date} ${month} ${year}, ${hours < 13 ? hours ?? 12 : hours % 12}:${( minutes < 10 ? "0" : "" ) + minutes
    } ${hours < 12 ? "AM" : "PM"}`
    : `${date}/${month + 1}/${String( year ).slice( 2, 4 )}, ${hours < 13 ? hours ?? 12 : hours % 12
    }:${( minutes < 10 ? "0" : "" ) + minutes} ${hours < 12 ? "AM" : "PM"}`;
}

export function dateFormateWithFullMonth(data: string) {
  const formatter = new Intl.DateTimeFormat("default", { month: "long" });
  const timeAndDate = new Date(data);
  const hours = timeAndDate.getHours();
  const minutes = timeAndDate.getMinutes();
  const date = timeAndDate.getDate();
  const month: any = formatter.format(timeAndDate);
  const year = timeAndDate.getFullYear();
  return `${date} th ${month} ${year}, ${hours < 13 ? hours ?? 12 : hours % 12}:${
        (minutes < 10 ? "0" : "") + minutes
      } ${hours < 12 ? "AM" : "PM"}`;
}
