import { axiosJSON } from "src/globals/axiosEndPoints";

export type ASSIGN_CHAT_RES_STATUS_CODE =
  | ""
  | "invalid_chat_request_id" // for this err => true
  | "invalid_user" // for this err => true
  | "limit_reached" // for this err => false
  | "chat_assigned" // for this err => false
  | "chat_not_assigned"; // for this err => false

export interface IAssignChatParam {
  chatRequestId: string | number;
  requirePreChatForm?: boolean;
}

export interface IAssignChatRes {
  statusCode: ASSIGN_CHAT_RES_STATUS_CODE;
  msg: string;
}

export async function assignChat(params: IAssignChatParam) {
  const { data: res } = await axiosJSON.post(
    `/api/liveChat/traffic/assignChat`,
    params,
  );

  if (res.err) {
    throw res.msg;
  }

  const ret: IAssignChatRes = {
    statusCode: res.status_code,
    msg: res.msg,
  };

  return ret;
}
