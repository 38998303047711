import React from "react";
import { BsArrowLeft } from "react-icons/bs";
import styles from "./GoBackButton.module.scss";
import { Swiper as ISwiper } from "swiper";

function GoBackButton(props: { disabled: boolean; swiper: ISwiper | null }) {
  return (
    <>
      <button
        className={`${styles.goBackButton} ${
          props.disabled ? styles.hidden : ""
        } btn d-flex justify-content-between px-md-3 py-1`}
        disabled={props.disabled}
        onClick={() => props.swiper?.slidePrev()}
      >
        <div className={`${styles.backArrow}`}>
          <BsArrowLeft />
        </div>
        <div className="ms-1">
          <span className={`${styles.goBackText}`}>Go back</span>
        </div>
      </button>
    </>
  );
}

export default GoBackButton;
