import { useCallback, useEffect, useRef, useState } from "react";
import { Col, Modal, Nav, Row, Tab } from "react-bootstrap";
import AddConditionButton from "src/components/AutomationComponents/ContentSection/AddConditionButton";
import Condition from "src/components/AutomationComponents/ContentSection/Condition";
import ContentHeading from "src/components/AutomationComponents/ContentSection/ContentHeading";
import MessageSection from "src/components/AutomationComponents/ContentSection/MessageSection";
import OperatorSelect from "src/components/AutomationComponents/ContentSection/OperatorSelect";
import Values from "src/components/AutomationComponents/ContentSection/Values";
import Variables, {
  addVariableOnClick,
} from "src/components/AutomationComponents/ContentSection/VariableSection";
import VariableSelect from "src/components/AutomationComponents/ContentSection/VariableSelect";
import DiscardAndSave from "src/components/AutomationComponents/DiscardAndSave";
import SkeletonModalStructure from "src/components/AutomationComponents/SkeletonModalStructure/SkeletonModalStructure";
import AddMore from "src/components/AutomationComponents/TabSection/AddMore";
import AutomationTab from "src/components/AutomationComponents/TabSection/AutomationTab";
import CloseButtonForTab from "src/components/AutomationComponents/TabSection/CloseButtonForTab";
import ConditionGroup from "src/components/AutomationComponents/TabSection/ConditionGroup";
import TabError from "src/components/AutomationComponents/TabSection/TabError";
import TabName from "src/components/AutomationComponents/TabSection/TabName";
import Loader from "src/components/Loader";
import DiscardModal from "src/components/AutomationComponents/DiscardModal/DiscardModal";
import { v4 as uuid } from "uuid";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  setSelectedOrderTypeId,
  validateChanges,
  undoConfig,
  deleteOrderType,
} from "../../store/slices/NonEligibleOrdersSlice/nonEligibleOrders.slice";
import styles from "./NonEligibleOrders.module.scss";
import useNonEligibleOrders from "./useNonEligibleOrders";
import { fetchNonEligibleOrdersThunk } from "../../store/slices/NonEligibleOrdersSlice/nonEligibleOrders.thunk";
import { useReturnAutoWorkFlow } from "src/features/ReturnAutoWorkFlow/hooks/useReturnAutoWorkFlow";

const NonEligibleOrdersComponent = ({
  onSave,
}: {
  onSave: (isSaved: boolean) => void;
}) => {
  const dispatch = useAppDispatch();
  const insertPlaceholderRef = useRef(null as any);
  const { returnAutoWorkFlow } = useReturnAutoWorkFlow();

  const {
    dispAddCondition,
    dispAddTab,
    dispDeleteCondition,
    dispPostConfig,
    dispPushCurrentHashForSelectedTab,
    dispUpdateConditionForValues,
    dispUpdateConditionForVariables,
    dispUpdateConditonForOperator,
    dispUpdateOrderType,
    dispValidateCondition,
    dispValidateOrderType,
    orderTypes,
    fetchAjaxStatus,
    updateAjaxStatus,
    initialHash,
    currentHash,
    selectedOrderTypeId,
    isAddBtnEnabled,
    defaultOptions,
    conditionOptions,
    operators,
    returnWindowOptions,
    dispUpdateReturnWindowConditionForVariables,
    dispUpdateReturnWindowConditonForOperator,
    disUpdateReturnWindowCondition,
  } = useNonEligibleOrders({ onSave });

  if (fetchAjaxStatus === "pending") {
    return (
      <div className={`${styles.loaderHeight}`}>
        <Loader />
      </div>
    );
  }

  return (
    <div>
      <Tab.Container
        id="left-tabs-example"
        activeKey={selectedOrderTypeId ?? ""}
        onSelect={(eventKey) => {
          if (selectedOrderTypeId !== eventKey) {
            dispatch(setSelectedOrderTypeId({ orderTypeId: eventKey ?? "" }));
          }
        }}
      >
        <Row>
          <Col>
            <Nav
              variant="tabs"
              className={`d-flex align-items-center border-0 ${styles.tabSectionWrapper} `}
            >
              {orderTypes.map((order, idx) => {
                return (
                  <Nav.Item
                    role="button"
                    key={"tabItem_" + order.orderTypeId}
                  >
                    <Nav.Link
                      eventKey={order.orderTypeId}
                      className={`py-1 px-3  text-dark shadow-sm  ${styles.tabStyles}`}
                      style={{
                        background:
                          selectedOrderTypeId !== null
                            ? selectedOrderTypeId === order.orderTypeId
                              ? order.errorCount > 0
                                ? "#FFDCDC 0% 0% no-repeat padding-box"
                                : "#FFFFFF 0% 0% no-repeat padding-box "
                              : order.errorCount > 0
                                ? "#FFDCDC 0% 0% no-repeat padding-box"
                                : "#f5f5f5 0% 0% no-repeat padding-box"
                            : "",
                      }}
                    >
                      <AutomationTab idx={idx}>
                        <TabName
                          id={order.orderTypeId}
                          name={order.orderTypeName}
                          updateType={dispUpdateOrderType}
                          pushCurrentHash={dispPushCurrentHashForSelectedTab}
                        />

                        <TabError errorCount={order.errorCount} />

                        <div className="ms-1">
                          <CloseButtonForTab
                            index={idx}
                            name={order.orderTypeName}
                            hasStaticTab={false}
                            onDelete={() => {
                              dispatch(
                                deleteOrderType({ id: order.orderTypeId }),
                              );
                            }}
                          />
                        </div>
                      </AutomationTab>
                    </Nav.Link>
                  </Nav.Item>
                );
              })}

              <AddMore
                AddTab={dispAddTab}
                text="Add more custom order type"
                selectedTabId={selectedOrderTypeId}
                validateTabType={dispValidateOrderType}
              />
            </Nav>

            <Tab.Content
              className={`${styles.contentStyles} pt-4  px-4 ${
                initialHash &&
                currentHash &&
                initialHash === currentHash &&
                styles.noDiscardAndSave
              }`}
            >
              {orderTypes.map((order, index) => {
                const allSelectedConditionNames: string[] =
                  order.conditions.map((condition) => condition.variableName);

                const filteredConditions = conditionOptions.filter(
                  (condition) => !allSelectedConditionNames.includes(condition),
                );

                return (
                  <Tab.Pane
                    key={"tab_" + order.orderTypeId}
                    eventKey={order.orderTypeId}
                    unmountOnExit={true}
                    onEnter={() => {
                      dispValidateCondition(order.orderTypeId);
                    }}
                    onEntered={() => {
                      dispValidateCondition(order.orderTypeId);
                    }}
                    onExited={() => {
                      dispValidateCondition(order.orderTypeId);
                    }}
                  >
                    {/* Conditions Section  */}
                    <ContentHeading text="NON-Eligiblity order Conditions could be " />
                    {/* Conditions Section  */}
                    {index === 0 ? (
                      <ConditionGroup
                        key={order.orderTypeId + "default_condition"}
                        title=""
                        initialShow={true}
                        disableHide={true}
                      >
                        <ConditionGroup
                          key={order.orderTypeId + "default_condition_1"}
                          title=""
                          initialShow={true}
                          disableHide={true}
                        >
                          <VariableSelect
                            key={order.orderTypeId + "main_condition"}
                            id={order.orderTypeId}
                            condition={{} as any}
                            variablesName={defaultOptions}
                            pushCurrentHash={dispPushCurrentHashForSelectedTab}
                            updateConditon={() => {}}
                            selectedVariableName={""}
                            showPlaceholder={false}
                          />
                        </ConditionGroup>
                        <span className={`${styles.subText}`}>
                          To create specific order type for specific kinds of
                          orders,{" "}
                          <button
                            className={`${styles.clickLink}`}
                            onClick={dispAddTab}
                          >
                            click here
                          </button>
                        </span>
                      </ConditionGroup>
                    ) : (
                      <ConditionGroup
                        key={order.orderTypeId + "other_condition_1"}
                        title=""
                        initialShow={true}
                        disableHide={true}
                        errorMessage={order.error}
                      >
                        {order.conditions.length === 0 ? (
                          <ConditionGroup
                            key={order.orderTypeId + "other_condition"}
                            title=""
                            initialShow={true}
                            disableHide={true}
                          >
                            <VariableSelect
                              id={order.orderTypeId}
                              condition={{} as any}
                              variablesName={filteredConditions}
                              pushCurrentHash={
                                dispPushCurrentHashForSelectedTab
                              }
                              updateConditon={dispAddCondition}
                              selectedVariableName={""}
                              showPlaceholder={true}
                              errorMessage={order.error}
                            />
                          </ConditionGroup>
                        ) : null}

                        {order.conditions.map((condition, idx) => {
                          return (
                            <Condition
                              key={"condition_" + condition.id}
                              id={order.orderTypeId}
                              condition={condition}
                              deleteCondition={dispDeleteCondition}
                              idx={idx}
                              validateCondition={dispValidateCondition}
                            >
                              <VariableSelect
                                id={order.orderTypeId}
                                condition={condition}
                                variablesName={filteredConditions}
                                pushCurrentHash={
                                  dispPushCurrentHashForSelectedTab
                                }
                                updateConditon={dispUpdateConditionForVariables}
                                selectedVariableName={condition.variableName}
                              />
                              <OperatorSelect
                                condition={condition}
                                id={order.orderTypeId}
                                pushCurrentHash={
                                  dispPushCurrentHashForSelectedTab
                                }
                                updateConditon={dispUpdateConditonForOperator}
                                operatorNames={
                                  operators[condition.variableName]
                                }
                                selectedOperator={condition.operator}
                              />

                              <Values
                                id={order.orderTypeId}
                                condition={condition}
                                updateCondition={dispUpdateConditionForValues}
                                pushCurrentHash={
                                  dispPushCurrentHashForSelectedTab
                                }
                                useNewComponent={true}
                              />
                            </Condition>
                          );
                        })}
                        <AddConditionButton
                          AddCondition={dispAddCondition}
                          id={order.orderTypeId}
                          isAddBtnEnabled={isAddBtnEnabled}
                        />
                      </ConditionGroup>
                    )}

                    <ConditionGroup
                      title="Select Return Window"
                      errorMessage={order.returnWindowError}
                    >
                      {order.returnWindow ? (
                        <Condition
                          key={uuid()}
                          idx={0}
                          id={order.orderTypeId}
                          condition={order.returnWindow}
                          deleteCondition={dispDeleteCondition}
                          validateCondition={dispValidateCondition}
                          hideDelete={true}
                        >
                          <VariableSelect
                            id={order.orderTypeId}
                            condition={order.returnWindow}
                            variablesName={returnWindowOptions}
                            pushCurrentHash={dispPushCurrentHashForSelectedTab}
                            updateConditon={
                              dispUpdateReturnWindowConditionForVariables
                            }
                            selectedVariableName={
                              order.returnWindow.variableName
                            }
                            showPlaceholder={true}
                            errorMessage={order.returnWindowError}
                          />
                          {order.returnWindow.variableName !== "None" ? (
                            <>
                              <OperatorSelect
                                condition={order.returnWindow}
                                id={order.orderTypeId}
                                pushCurrentHash={
                                  dispPushCurrentHashForSelectedTab
                                }
                                updateConditon={
                                  dispUpdateReturnWindowConditonForOperator
                                }
                                operatorNames={
                                  operators[order.returnWindow.variableName] ??
                                  []
                                }
                                selectedOperator={order.returnWindow.operator}
                              />

                              <Values
                                id={order.orderTypeId}
                                condition={order.returnWindow}
                                updateCondition={disUpdateReturnWindowCondition}
                                pushCurrentHash={
                                  dispPushCurrentHashForSelectedTab
                                }
                                useNewComponent={true}
                              />
                            </>
                          ) : null}
                        </Condition>
                      ) : (
                        <></>
                      )}
                    </ConditionGroup>
                    <ConditionGroup
                      title="Reason to be communicated to the customer"
                      errorMessage={order.messageForCustomer.error}
                    >
                      <MessageSection
                        updateOrder={dispUpdateOrderType}
                        message={{
                          value: order.messageForCustomer?.value ?? "",
                          error: order.messageForCustomer?.error,
                        }}
                        pushCurrentHash={dispPushCurrentHashForSelectedTab}
                        validateTabType={dispValidateOrderType}
                        id={order.orderTypeId}
                        text=""
                        hideError={false}
                        insertPlaceholderRef={insertPlaceholderRef}
                      />
                      {/* Variable Section  */}
                      <Variables
                        numberOfItems={3}
                        handleAddVariableOnClick={(value) => {
                          addVariableOnClick(value, insertPlaceholderRef);
                        }}
                      />
                    </ConditionGroup>
                  </Tab.Pane>
                );
              })}
            </Tab.Content>
          </Col>
        </Row>

        <DiscardAndSave
          onSave={dispPostConfig}
          showLoading={updateAjaxStatus === "pending"}
          onDiscard={() => {
            if (!returnAutoWorkFlow.integrationId) {
              return;
            }

            dispatch(
              fetchNonEligibleOrdersThunk({
                integrationId: returnAutoWorkFlow.integrationId,
                // TODO -
                selectedReturnWindow: "",
              }),
            );
          }}
        />
      </Tab.Container>
    </div>
  );
};

const NonEligibleOrders = ({
  onHide,
  onSave = () => {},
}: {
  onHide: () => void;
  onSave?: () => void;
}) => {
  const dispatch = useAppDispatch();
  const { isChanged, intialStep9Config } = useAppSelector(
    (state) => state.nonEligibleOrders,
  );

  const [isValidating, setIsValidating] = useState(false);
  const [showDiscardModel, setShowDiscardModal] = useState(false);

  useEffect(() => {
    if (isValidating) {
      if (isChanged) {
        setShowDiscardModal(true);
      } else {
        onHide();
      }
      setIsValidating(false);
    }
  }, [isChanged, isValidating, onHide]);

  const closeButtonFunctionality = useCallback(() => {
    dispatch(validateChanges());
    setIsValidating(true);
  }, [dispatch]);

  return (
    <div>
      {" "}
      <SkeletonModalStructure
        hasTwoSection={false}
        heading="Add Orders That Are Not Eligible"
        closeFunction={closeButtonFunctionality}
      >
        <NonEligibleOrdersComponent onSave={onSave} />
      </SkeletonModalStructure>
      <Modal
        className={`shadow-sm p-3 rounded`}
        show={showDiscardModel}
        onHide={() => setShowDiscardModal(false)}
        dialogClassName={`${styles.modalDialog2}`}
        contentClassName={`${styles.modalContent2}`}
        backdropClassName={`${styles.modalBackDrop}`}
        enforceFocus={false}
        keyboard={false}
      >
        <DiscardModal
          setShow={setShowDiscardModal}
          closeMainModel={onHide}
          onConfirm={() => {
            dispatch(undoConfig({ initialConfig: intialStep9Config }));
          }}
        />
      </Modal>
    </div>
  );
};

export default NonEligibleOrders;
