// Import necessary modules and assets
import { useMemo } from "react";
import doneIcon from "src/assets/images/doneProcess.png";
// import successImg from "src/assets/images/successMsg.png";

import styles from "./LiveChatProcess.module.scss";

interface StepProps {
  stepNumber: number;
  title: string;
  done: boolean;
  active: boolean;
}

/**
 * Step component for rendering email integration step
 */
const Step = ({ stepNumber, title, done, active }: StepProps) => (
  <div className={`d-flex align-items-center`}>
    {/* Step Details */}
    <div className={`d-flex align-items-center`}>
      {/* Step Status */}
      {done ? (
        <img
          src={active ? doneIcon : doneIcon}
          alt=""
          className={`${styles.doneImg}`}
        />
      ) : (
        <div>
          {/* Step Number */}
          <span
            className={`d-flex justify-content-center align-items-center ${
              styles.step2
            } ${active ? styles.active : ""}`}
          >
            {stepNumber}
          </span>
        </div>
      )}
      {/* Step Title */}
      <span
        className={`ps-2 ${styles.stepTitle} ${
          active ? styles.activeTitle : ""
        }`}
      >
        {title}
      </span>
    </div>
    {/* Dash between steps */}
    {stepNumber !== 4 && (
      <div
        className={`mx-3 ${styles.borderSpace} ${done && styles.activeBorder}`}
      ></div>
    )}
  </div>
);

interface Props {
  step: number;
  isStep1Done: boolean;
  isStep4Done: boolean;
}

/**
 * Email Process Component to render email integration steps
 */
const LiveChatProcess = (props: Props) => {
  /**
   * Memoized Step data
   */
  const steps = useMemo(
    () => [
      { number: 1, title: "Store details", done: props.isStep1Done },
      {
        number: 2,
        title: "Copy code and embed to website",
        done: props.isStep4Done,
      },
      {
        number: 3,
        title: "Check installation on the store",
        done: props.isStep4Done,
      },
      {
        number: 4,
        title: "Complete!",
        done: props.isStep4Done,
      },
    ],
    [props.isStep4Done, props.isStep1Done],
  );

  return (
    <>
      {/* Header */}
      <div
        className={`d-flex justify-content-between align-items-center py-2 py-md-3 px-2 px-md-3 me-0 me-lg-4 mt-2 ${styles.processMain}`}
        id="liveChatProcess"
      >
        {/* Steps */}
        <div className="d-flex align-items-center">
          {steps.map((step, index) => (
            <Step
              key={index}
              stepNumber={step.number}
              title={step.title}
              done={step.done}
              active={props.step === step.number}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default LiveChatProcess;
