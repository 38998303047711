import { Dropdown, Modal } from "react-bootstrap";
import styles from "./BugMgmtMsg.module.scss";
import clockIcon from "src/assets/images/clockIcon.png";
import { useRef, useState } from "react";
import MediaPreview from "./MediaPreview/MediaPreview";
import { BugAttachmentDetails } from "src/services/BugReport/getBugByCategory.service";
import { capitalizeFirstLetter } from "src/routes/SelfService/Children/BugManagement/Children/helper/helperFunction";
import { dateFormate } from "src/routes/SelfService/Children/BugManagement/Children/TableRow/Children/BugReportModal/Children/dateFormate";
import AxiosImg from "src/components/AxiosImg";
import UserAvatar from "src/components/UserAvatar";
import { Link } from "react-router-dom";
import { getTimeFromNow } from "src/utils/dateLibrary";
import { DropDownMenuData } from "../SendMsgCommon/SendMsgCommon";
import { useAppSelector } from "src/store/store";
import useUTCToUserTime from "src/hooks/dateHooks/useUTCToUserTime";

function formatTimeDifference(pastDate: Date, resolvedDate?: Date): string {
  const now = resolvedDate ? resolvedDate : new Date();
  const durationInMinutes = Math.abs(
    (now.getTime() - pastDate.getTime()) / (1000 * 60),
  );

  if (durationInMinutes < 60) {
    return `${Math.ceil(durationInMinutes)} minute${
      durationInMinutes < 2 ? "" : "s"
    }`;
  }

  const hours = durationInMinutes / 60;
  if (hours < 24) {
    return `${Math.floor(hours)} hour${hours < 2 ? "" : "s"}`;
  }

  const days = hours / 24;
  if (days < 31) {
    return `${Math.floor(days)} day${days < 2 ? "" : "s"}`;
  }

  const months = days / 30;
  return `${Math.floor(months)} month${months < 2 ? "" : "s"}`;
}

const BugMgmtMsg = ({ messageData }: { messageData: any }) => {
  const [show, setShow] = useState(false);
  const [dropShow, setDropShow] = useState(false);
  const dropdownRef = useRef<any>();
  const dropdownArrowSpanRef = useRef<any>();
  const userTimezone = useAppSelector(
    (state) => state.globals.currentUserData?.userTimezone,
  );
  const createDate = useUTCToUserTime({
    timeInUTC: messageData.bugReportDetails.bugCreatedAtGmt,
  });

  return (
    <div className={`w-100 d-flex ${styles.msgMargin} ${styles.left}`}>
      <div className={styles.profileMargin}>
        {messageData.senderImgURL ? (
          <AxiosImg
            url={messageData.senderImgURL}
            className={`${styles.profileIcon}`}
            isDirectURL={messageData?.isPublicAttachmentUrl}
          />
        ) : (
          <UserAvatar
            className={`${styles.profileIcon}`}
            name={messageData.senderName || "NA"}
            size={35}
          />
        )}
      </div>
      <div className="w-100 mx-3">
        <div className={`${styles.workflowBox} mb-2`}>
          Bug management workflow{" "}
          <i
            className="fa-solid fa-code-pull-request ms-2"
            aria-hidden="true"
          ></i>
        </div>
        <div className={`${styles.outerContainer}`}>
          <div className={`${styles.innerContainer}`}>
            <span className={styles.text500}>Issue description:</span>
            <div className={styles.textGrayDark}>{messageData.message}</div>
            <span
              role="button"
              className={`${styles.textBlue} ${styles.text500}`}
              onClick={() => setShow(true)}
            >
              {messageData.attachments.length} media file
              {messageData.attachments.length > 1 && "s"}
            </span>
            <div className={`d-flex align-items-center ${styles.gridGap} mb-2`}>
              <span className={styles.text600}>Status:</span>
              <div
                className={`${styles.dot} 
                ${
                  messageData.bugReportDetails.bugStatusKey == "submitted"
                    ? styles.greyDot
                    : messageData.bugReportDetails.bugStatusKey === "resolved"
                      ? styles.greenDot
                      : styles.yellowDot
                }`}
              ></div>
              <span>
                {capitalizeFirstLetter(
                  messageData.bugReportDetails.bugStatusKey,
                )}
              </span>
              <div className="d-flex align-items-center">
                <img
                  src={clockIcon}
                  alt=""
                  width={12}
                  height={12}
                />
                <div className={`${styles.textGrayLight}`}>
                  {dateFormate(createDate)}
                </div>
              </div>
              <div className={`${styles.text600} ms-2`}>
                Issue{" "}
                {messageData.bugReportDetails.bugStatusKey === "resolved"
                  ? "resolved in "
                  : "pending since "}
                {messageData.bugReportDetails?.bugResolveDetails?.resolveDateGmt
                  ? formatTimeDifference(
                      new Date(messageData.bugReportDetails.bugCreatedAtGmt),
                      new Date(
                        messageData.bugReportDetails.bugResolveDetails.resolveDateGmt,
                      ),
                    )
                  : formatTimeDifference(
                      new Date(messageData.bugReportDetails.bugCreatedAtGmt + "z"),
                    )}
                .
              </div>
            </div>
            <div className="d-flex">
              <div
                className={`${styles.priority} ${
                  messageData.bugReportDetails.priorityKey === "normal"
                    ? styles.priorityBlue
                    : messageData.bugReportDetails.priorityKey === "high"
                      ? styles.priorityGreen
                      : styles.priorityRed
                }`}
              >
                Priority:{" "}
                {messageData.bugReportDetails.priorityKey ===
                "needThisFixedRightAway"
                  ? "I need this fixed right away"
                  : capitalizeFirstLetter(
                      messageData.bugReportDetails.priorityKey,
                    )}
              </div>
              <div className={`${styles.category} ms-1`}>
                Category:{" "}
                {messageData.bugReportDetails.categoryDetails.categoryName}
              </div>
            </div>
          </div>
          <div className="w-100 text-center my-1 pb-1">
            <Link
              to={`../selfService/bugManagement?bugId=${messageData.bugReportDetails.bugId}&categoryName=${messageData.bugReportDetails.categoryDetails.categoryName}`}
              target="_blank"
              className={`${styles.textBlue} ${styles.text500}`}
            >
              See workflow
            </Link>
          </div>
        </div>
        <span
          className={`${styles.created} d-flex justify-content-end`}
          style={{ float: "left" }}
        >
          <div
            className={`dropdown d-flex justify-content-start`}
            ref={dropdownRef}
          >
            <div>
              <Dropdown onToggle={setDropShow}>
                <Dropdown.Toggle
                  id="dropdown-basic"
                  as="div"
                  ref={dropdownRef}
                  bsPrefix={`dropdown-toggle cursor-pointer ${styles.sendDrop} ${styles.dropdownToggle}`}
                >
                  {`${getTimeFromNow({
                    date: new Date(messageData.messageDateTime),
                    timeZone: userTimezone,
                  })}${`, Via: ${messageData.from}`}`}
                  <span ref={dropdownArrowSpanRef}>
                    {" "}
                    {dropShow ? (
                      <i className={`fa-solid fa-caret-up`}></i>
                    ) : (
                      <i className={`fa-solid fa-caret-down`}></i>
                    )}
                  </span>
                </Dropdown.Toggle>
                <DropDownMenuData
                  messageData={messageData}
                  labelBy={"dropdownMenuButton2"}
                />
              </Dropdown>
            </div>
          </div>
        </span>
      </div>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        contentClassName={styles.modalClass}
        centered={true}
      >
        <MediaPreview
          media={messageData.attachments}
          senderName={messageData.senderName}
          senderImgUrl={messageData.senderImgURL}
          onHide={() => setShow(false)}
        />
      </Modal>
    </div>
  );
};

export default BugMgmtMsg;
