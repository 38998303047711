/**
 * This file is the service file used for making api request.
 * It contains the getAllThemes function service which is explained below.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants"; // this is used for testing purpose only for frontend.

export interface ThemesPayload {
  integrationId: number | string;
  start: number | string;
  limit: number | string;
}

export interface Theme {
  id: number;
  name: string;
  desc: string;
  storeName: string;
  heading: string;
  subheading: string;
  placeholder: string;
  logoImage: string | null;
  backgroundImage: string | null;
  isActive: boolean;
  backgroundColor: string;
  font: string;
  brandColor: string;
  themeType: "alice_in_wonderland" | "night_in_the_oscars";
}

export interface ThemeData {
  themes: Theme[];
}

export interface ThemesRes {
  data: ThemeData;
  metaData: {
    currentCount: number;
    totalCount: number;
  };
}

/**
 * For testing frontend with own api link if backend is not prepared.
 */
const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/getThemes`
  : "/api/chatSetting/getAllThemes";

/**
 * This service is used to get all the themes in the KB settings.
 */
export const getAllThemes = async (params: ThemesPayload) => {
  const { data: res } = await axiosJSON.post(apiEndPoint, params);
  return res as ThemesRes;
};
