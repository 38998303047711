import styles from "./ResetPassword.module.scss";
import { useCallback, useEffect, useRef, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { resetPassword } from "src/services/Auth/resetPassword";
import { passwordValidate } from "src/utils/utils";
import { useNavigate, useParams } from "react-router-dom";
import pass from "src/assets/images/6.png";
import showPassImg from "src/assets/images/7.png";

const validatePasswordConstraints = (password: string, constraint: RegExp) => {
  if (password.length === 0) {
    return false;
  }
  if (constraint.test(password)) {
    return true;
  } else {
    return false;
  }
};

export const PasswordMustContain = ({ password, showCross, ...props }: any) => {
  return (
    <div
      {...props}
      className={`left-reset text-right d-flex flex-column align-items-start align-items-md-center ${props.className}`}
    >
      <h2 className={`mb-3 ${styles.passwordText}`}>Password must contain:</h2>
      <ul className={`${styles.resetConditions} px-0`}>
        <li
          className={`${styles.newSteps} ${
            password !== undefined && password.trim().length >= 6
              ? `${styles.tickArrow}`
              : showCross
              ? styles.crossCheck
              : ""
          }`}
        >
          At least 6 Characters
        </li>
        <li
          className={`${styles.newSteps} ${
            password !== undefined &&
            validatePasswordConstraints(password, /(?=.*[A-Z])/)
              ? `${styles.tickArrow}`
              : showCross
              ? styles.crossCheck
              : ""
          }`}
        >
          At least 1 upper case letter(A-Z)
        </li>
        <li
          className={`${styles.newSteps} ${
            password !== undefined &&
            validatePasswordConstraints(password, /(?=.*[a-z])/)
              ? `${styles.tickArrow}`
              : showCross
              ? styles.crossCheck
              : ""
          }`}
        >
          At least 1 lower case letter(a-z)
        </li>
        <li
          className={`${styles.newSteps} ${
            password !== undefined &&
            validatePasswordConstraints(password, /(?=.*\d)/)
              ? `${styles.tickArrow}`
              : showCross
              ? styles.crossCheck
              : ""
          }`}
        >
          At least 1 Number(1-9)
        </li>
        <li
          className={`${styles.newSteps} ${
            password !== undefined &&
            validatePasswordConstraints(password, /(?=.*?[^\w\s])/)
              ? `${styles.tickArrow}`
              : showCross
              ? styles.crossCheck
              : ""
          }`}
        >
          At least 1 Special Charecter(@, #, ...)
        </li>
      </ul>
    </div>
  );
};

const ResetPassword = () => {
  const { token }: any = useParams<string>();
  const tokenDataRef: any = useRef({ token: "" });
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [newPass, setNewPass] = useState("");
  const [reNewPass, setReNewPass] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [showPass, setShowPass] = useState(false);
  const [showCpass, setShowCpass] = useState(false);
  const [showCross, setShowCross] = useState(false);
  const [focusedEle, setFocusedEle] = useState("pass" as "pass" | "re-pass");

  const onShow = useCallback(() => {
    setShowModal(true);
  }, []);
  const onHide = useCallback(() => {
    setShowModal(false);
  }, []);

  useEffect(() => {
    if (token === "") {
      setTimeout(() => {
        pushTheToast({
          type: "danger",
          text: "Invalid Reset Link!",
          position: "top-right",
        });
      }, 0);
    } else {
      tokenDataRef.current.token = token;
    }
  }, [token]);

  const submitResetPassword = (e: any) => {
    e.preventDefault();
    if (reNewPass === newPass && passwordValidate(newPass)) {
      setLoading(true);
      resetPassword({
        token: tokenDataRef.current.token,
        password: newPass.trim(),
      })
        .then(() => {
          setLoading(false);
          setShowModal(true);
        })
        .catch(() => {
          setLoading(false);
          pushTheToast({
            type: "danger",
            text: "Failed to reset password!",
            position: "top-right",
          });
        });
    } else {
      setShowCross(true);
      pushTheToast({
        type: "warning",
        text: "Please check passwords!",
        position: "top-right",
      });
    }
  };

  const handleResetCancelAndOk = (e: any) => {
    setNewPass("");
    setReNewPass("");
    setShowModal(false);
    e.preventDefault();
    navigate("/login");
  };

  return (
    <div className={`${styles.resetMain}`}>
      <section className={`${styles.resetPage}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="sign-up-headings text-center">
                <h1 className={`${styles.resetText}`}>Reset Your Password</h1>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-12 col-12">
              <PasswordMustContain
                password={focusedEle === "pass" ? newPass : reNewPass}
                showCross={showCross}
                className={`${styles.leftDiv}`}
              />
            </div>

            <div className="col-md-6 col-sm-12 col-12">
              <div className={`${styles.resetFields}`}>
                <form className="mt-5" id="Login" name="login-form" action="">
                  <div className="row">
                    <div className={`${styles.inputFormGroup}`}>
                      <div
                        className={`mb-4 col-md-12 col-lg-12 effect-field ${styles.effectField}`}
                      >
                        <input
                          type={showPass ? "text" : "password"}
                          className={`form-control ${styles.inputField} ${
                            showCross && !passwordValidate(newPass)
                              ? styles.notValid
                              : ""
                          }`}
                          name="password"
                          placeholder="Enter Password"
                          value={newPass}
                          onChange={(e) => setNewPass(e.target.value)}
                          required
                          onFocus={() => {
                            setFocusedEle("pass");
                          }}
                        />
                        {newPass && (
                          <div className={`h-0 d-flex justify-content-end`}>
                            <img
                              src={showPass ? `${showPassImg}` : `${pass}`}
                              alt="tick"
                              className={`${styles.passImg}`}
                              onClick={(e) => {
                                setShowPass(!showPass);
                              }}
                            />
                          </div>
                        )}
                        <div className="hover_content"></div>
                      </div>
                      <div
                        className={`mb-4 col-md-12 col-lg-12 effect-field ${styles.effectField}`}
                      >
                        <input
                          type={showCpass ? "text" : "password"}
                          className={`form-control ${styles.inputField} ${
                            showCross &&
                            (newPass !== reNewPass ||
                              !passwordValidate(reNewPass)) &&
                            reNewPass.trim() !== ""
                              ? styles.notValid
                              : ""
                          }`}
                          name="password_match"
                          placeholder="Re-enter new password"
                          required
                          value={reNewPass}
                          onChange={(e) => setReNewPass(e.target.value)}
                          onFocus={() => {
                            setFocusedEle("re-pass");
                          }}
                        />
                        {reNewPass && (
                          <div className={`h-0 d-flex justify-content-end`}>
                            <img
                              src={showCpass ? `${showPassImg}` : `${pass}`}
                              alt="tick"
                              className={`${styles.passImg}`}
                              onClick={(e) => {
                                setShowCpass(!showCpass);
                              }}
                            />
                          </div>
                        )}
                        {showCross &&
                          newPass !== reNewPass &&
                          reNewPass.trim() !== "" && (
                            <>
                              <div className={`${styles.notMatched}`}>
                                The password does not match
                              </div>
                            </>
                          )}
                        <div className="hover_content"></div>
                      </div>
                      <div
                        className={`mb-4 col-md-12 col-lg-12 ${styles.effectField}`}
                      >
                        <button
                          type="button"
                          className={`btn ${styles.signUpButton}`}
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModa2"
                          onClick={(e) => submitResetPassword(e)}
                        >
                          {loading && (
                            <Spinner
                              className={`mx-1 my-auto`}
                              animation="border"
                              color="white"
                              size="sm"
                            />
                          )}
                          <span>Change Password</span>
                        </button>

                        {/* Success modal for the reset password done successfully */}
                        <Modal
                          backdropClassName={`${styles.modalBack}`}
                          show={showModal}
                          onHide={onHide}
                          dialogClassName={`${styles.modalDialog}`}
                          contentClassName={`${styles.modalContent}`}
                          centered={true}
                          enforceFocus={false}
                        >
                          <p className={`${styles.changePassText}`}>
                            Your password has been changed!
                          </p>
                          <button
                            className={`btn mt-3 ${styles.send}`}
                            onClick={(e) => {
                              handleResetCancelAndOk(e);
                            }}
                          >
                            Okay
                          </button>
                        </Modal>
                      </div>
                    </div>
                    <p className={`text-center ${styles.cancelBtn} mt-2`}>
                      <span
                        onClick={(e) => {
                          handleResetCancelAndOk(e);
                        }}
                        className={`${styles.cancelSpan}`}
                      >
                        Cancel
                      </span>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default ResetPassword;
