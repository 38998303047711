import { useContext } from "react";
import styles from "./OrderDetailsAction.module.scss";
import { ModalContext } from ".././../../Modal/ModalContext";
import CancelOrderModal from "./CancelOrderModal";
import { useAppDispatch, useAppSelector } from "src/store/store";
import RefundModal from "./RefundModal";
import { resetRefundModalData } from "src/store/slices/singleOrder/order.slice";
import { Dropdown } from "react-bootstrap";
import DuplicateOrder from "./children/DuplicateOrder/DuplicateOrder";

function OrderDetailsAction() {
  const dispatch = useAppDispatch();
  const { getOrderViewModel } = useAppSelector((state) => state.singleOrder);
  const modalContext = useContext(ModalContext);

  return (
    <div
      className={`d-flex flex-row flex-wrap mb-4 mt-4 ms-2 justify-content-between ${styles.orderDetailAction}`}
    >
      {getOrderViewModel && (
        <>
          <div className="d-flex flex-row flex-wrap">
            <div className={`me-3`}>
              <span className={`${styles.leftBox}`}>
                <i
                  className="fa-solid fa-arrow-left"
                  role="button"
                  onClick={() =>
                    modalContext.handleModal?.call(null, undefined)
                  }
                ></i>
              </span>
            </div>

            <div className={`my-1 my-lg-0`}>
              <div>
                <span className={`${styles.OrderDeTailsTicket} col `}>
                  {getOrderViewModel?.orderName}
                </span>
                <span
                  className={`${styles.tableHeading} ${styles.paymentStatus}`}
                >
                  {" "}
                  <div className={`${styles.round}`}></div>
                  {/* Paid{" "} */}
                  {getOrderViewModel?.paymentStatus?.name}
                </span>
                <span className={`${styles.tableHeading} ${styles.fullFill}`}>
                  <span className={`${styles.circleDot}`}>
                    <i className="fa-solid fa-circle-dot"></i>
                  </span>{" "}
                  {getOrderViewModel?.fulfillmentStatus?.name}
                </span>
              </div>

              <div>
                {getOrderViewModel.createdDate ? (
                  <span className={`${styles.lead}`}>
                    {new Date(getOrderViewModel.createdDate).toLocaleDateString(
                      "en-us",
                      {
                        weekday: "long",
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      }
                    )}
                  </span>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <div className="d-flex flex-row flex-wrap">
            <div
              className={` ${styles.RefundAndEditAction} d-flex align-items-center flex-row mt-2 me-2 `}
            >
              {/* Nov 24 hided order actions as it is not fully completed */}
              {/* <div
                className={`${styles.lead}  mx-2 `}
                role="button"
                onClick={() => {
                  dispatch(resetRefundModalData());
                  modalContext.handleModal(<RefundModal />);
                }}
              >
                Refund
              </div>

              <div className={`${styles.lead} ms-3 `} role="button">
                Edit
              </div>

              <div className={`${styles.lead} ms-3 `} role="button">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    bsPrefix={`dropdown-toggle px-1 py-1 ${styles.dropBtn}`}
                    as="div"
                  >
                    More Actions
                  </Dropdown.Toggle>

                  <Dropdown.Menu bsPrefix={`dropdown-menu ${styles.dropMenu}`}>
                    <li className={`dropdown-item ${styles.menuList}`}>
                      <span className="me-2">
                        <i className="fa-regular fa-copy"></i>
                      </span>
                      <span
                        onClick={() => {
                          // we don't need this now
                          modalContext.handleModal(<DuplicateOrder />);
                        }}
                      >
                        Duplicate
                      </span>
                    </li>
                    <li className={`dropdown-item ${styles.menuList}`}>
                      <span className="me-2">
                        <i className="fa-solid fa-xmark"></i>
                      </span>
                      <span
                        onClick={() => {
                          // we don't need this now
                          modalContext.handleModal(<CancelOrderModal />);
                        }}
                      >
                        Cancel Order
                      </span>
                    </li>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div
                className={`${styles.fwdbwdarrow} d-flex align-items-center flex-row ms-3`}
              >
                <div className="ms-2">
                  <span className={`${styles.lead} `} role="button">
                    {" "}
                    <i className="fa-solid fa-chevron-left"></i>{" "}
                  </span>
                </div>

                <div>
                  <span className={`d-block ${styles.verticalLine} vr mx-2`}></span>
                </div>

                <div className="me-2">
                  <span className={`${styles.lead}`} role="button">
                    <i className="fa-solid fa-chevron-right text-dark"></i>{" "}
                  </span>
                </div>
              </div> */}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
export default OrderDetailsAction;
