import { RootState } from "src/store/store";
import { isObjOrStrEmpty } from "src/utils/utils";

export const addViewValidationSelector = (addViewFormData: any) => {
  if (addViewFormData.viewName.trim().length === 0) {
    return false;
  }

  let fieldsFilledCount = 1; // one for the viewName
  if (!isObjOrStrEmpty(addViewFormData.brand)) fieldsFilledCount++;
  if (!isObjOrStrEmpty(addViewFormData.channel)) fieldsFilledCount++;
  if (!isObjOrStrEmpty(addViewFormData.tags)) fieldsFilledCount++;
  if (areDateOk(addViewFormData.createdOn, addViewFormData.createdOnValue))
    fieldsFilledCount++;
  if (!isObjOrStrEmpty(addViewFormData.assignedTo)) fieldsFilledCount++;
  if (areDateOk(addViewFormData.closedOn, addViewFormData.closedOnValue))
    fieldsFilledCount++;
  if (!isObjOrStrEmpty(addViewFormData.draftStatus)) fieldsFilledCount++;
  if (!isObjOrStrEmpty(addViewFormData.ticketStatus)) fieldsFilledCount++;

  if (fieldsFilledCount >= 2) {
    return true;
  }
  return false;
};

// return true if dates are validated, else false
function areDateOk(selectedOption: any, selectedValue: any) {
  if (isObjOrStrEmpty(selectedOption)) {
    return false;
  }

  const dateNotRequired = ["after", "before", "between"];

  // if an option is selected which does not required datepickers data
  if (!dateNotRequired.includes(selectedOption.compare)) {
    return true;
  }

  if (selectedOption.compare === "between") {
    if (Object.values(selectedValue).every((e) => e !== null)) {
      return true;
    } else {
      return false;
    }
  }

  if (selectedValue.startDate !== null && selectedValue.startTime !== null) {
    // console.log("is true");
    return true;
  }

  return false;
}
