import useSegmentFiltersApplied from "../../hooks/reportFilters/useSegmentFiltersApplied";
import styles from "./SegmentFiltersApplied.module.scss";

const SegmentFiltersApplied = () => {
  const { segmentFiltersApplied } = useSegmentFiltersApplied();

  return (
    <>
      {segmentFiltersApplied.map((data, index) => {
        return (
          <div key={data.name} className={``}>
            <span className={`ps-1 pe-1 ${styles.viewFilters}`}>
              {data.name}:
            </span>
            {data.filters.map((value) => {
              return (
                <div
                  key={value.name}
                  className={`d-inline-flex px-2 py-1 mb-1 me-2 ${styles.filterBox}`}
                >
                  <span className={`${styles.statusTruncate} my-auto`}>
                    {value.name} - {value.value}
                  </span>
                  <i
                    className="cursor-pointer ms-2 my-auto fa-solid fa-xmark"
                    onClick={value.callback}
                  ></i>
                </div>
              );
            })}
          </div>
        );
      })}
    </>
  );
};

export default SegmentFiltersApplied;
