/**
 * This file defines a custom hook responsible for getting and updating external sources
 *
 * @author @yuvaraj-busibud
 */
import {
  InfiniteData,
  useInfiniteQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useDebounce from "src/hooks/useDebounce";
import {
  GetAllExternalParams,
  Source,
  getAllExternal,
  GetAllExternalResponse,
} from "src/services/Bot/AnswerSources/getAllExternal.service";
import { setBackButtonRedirectPath } from "src/store/slices/botProfiles/botProfiles.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";

function useExternalActiveSource() {
  const [showModal, setShowModal] = useState(false);
  const [showSideBar, setShowSidebar] = useState(false);
  const onShow = (e: any) => {
    e.stopPropagation();
    setShowModal(true);
  };
  const onHide = () => {
    setShowModal(false);
  };
  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const backRedirectPath = useAppSelector(
    (state) => state.botProfiles.backRedirectPath
  );
  const dispatch = useAppDispatch();

  //function handles redirect for back button
  const handleBackClick = useCallback(() => {
    if (backRedirectPath.trim()) {
      dispatch(
        setBackButtonRedirectPath({
          path: "",
        })
      );
      navigate(backRedirectPath);
    } else {
      navigate("/bot/botProfiles");
    }
  }, [backRedirectPath]);

  //getting bot profile id from url
  const { profileId } = useParams();

  const payload = useMemo(() => {
    let payload: GetAllExternalParams = {
      botProfileId: profileId + "",
      start: 0,
      limit: 25,
    };

    return payload;
  }, [profileId]);

  const {
    data,
    isLoading,
    hasNextPage,
    fetchNextPage,
    refetch,
    isRefetching,
    isFetchingNextPage,
    status,
    fetchStatus,
  } = useInfiniteQuery({
    queryKey: ["getAllExternal", payload],
    queryFn: ({ pageParam = payload }) => getAllExternal(pageParam),
    getNextPageParam: (lastPage, allPages) => {
      const data = allPages.flatMap((data) => data.data);
      if (data.length < lastPage.metaData?.total) {
        const nextPageParam = {
          ...payload,
          start: data.length,
        };
        return nextPageParam;
      }
      return null;
    },
  });

  const getAllData = data?.pages?.flatMap((data) => data.data);

  const removeSourceFromList = useCallback(
    (sourceId: number | string) => {
      // Get the current data from the cache
      const externalSources = queryClient.getQueryData<
        InfiniteData<GetAllExternalResponse>
      >(["getAllExternal", payload]);
      if (externalSources) {
        // Find the updated schedule in the list and remove it
        const updatedExternalSources = externalSources.pages.map((page) => {
          const newPages = page.data.filter((source) => source.id != sourceId);
          return {
            ...page,
            data: newPages,
            metaData: {
              total: page.metaData.total - 1,
              count: newPages.length,
            },
          };
        });
        // Update the cache with the new data
        queryClient.setQueryData<InfiniteData<GetAllExternalResponse>>(
          ["getAllExternal", payload],
          {
            pages: updatedExternalSources,
            pageParams: externalSources.pageParams,
          }
        );
      }
    },
    [payload]
  );

  const updateSource = useCallback(
    (id: number, values: Partial<Source>) => {
      // Find the source in the cached data
      const cachedData: InfiniteData<GetAllExternalResponse> | undefined =
        queryClient.getQueryData(["getAllExternal", payload]);

      if (values.status === "deleted") {
        removeSourceFromList(id);
        return;
      }

      if (cachedData) {
        // Map over the pages and update the source with the matching id
        const updatedPages = cachedData.pages.map((page) => {
          const updatedPageData = page.data.map((source) => {
            if (source.id === id) {
              return {
                ...source,
                ...values,
              };
            }
            return source;
          });

          return {
            ...page,
            data: updatedPageData,
          };
        });

        // Update the query data with the updated pages
        queryClient.setQueryData(["getAllExternal", payload], {
          pages: updatedPages,
          // Add other properties as needed
        });
      }
    },
    [data, payload]
  );

  return {
    handleBackClick,
    showModal,
    onShow,
    onHide,
    getAllData,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isRefetching,
    refetch,
    fetchStatus,
    status,
    updateSource,
  };
}

export default useExternalActiveSource;
