import React, { useMemo, useState } from "react";
import styles from "./RatingSection.module.scss";
import helpLogo from "src/assets/images/helpCenter.png";
import useRating from "../../useRating";
import useThemeBox from "../../../../../ArticleSideBar/ThemeSelection/Children/ThemeBox/useThemeBox";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import AxiosImg from "src/components/AxiosImg";
import saufterLogo from "src/assets/images/saufter-logo.svg";
import themeDefaultBackground from "src/assets/images/themeDefaultBackground.png";

type Props = {
  allowUsers: boolean;
  showCount: boolean;
  selectedRadio: string | undefined;
  handleClickOnEmoji: () => void;
  emojiClick: boolean;
};

const RatingSection = ({
  allowUsers,
  showCount,
  selectedRadio,
  handleClickOnEmoji,
  emojiClick,
}: Props) => {
  // Changed the html and css in this file to dynamic data and styles from backend

  //Getting data to show theme data dynamically (05 Dec 2023)
  const {
    data,
    parentStyle,
    categoryStyle,
    isLoading,
    updateKBSettings,
    isError,
    isFetching,
  } = useThemeBox();

  // Define a memoized function to get the store name
  const storeName = useMemo(() => {
    // Check if 'data' exists and has a non-null 'storeName' property
    if (data && data?.storeName) {
      // If available, return the 'storeName' property
      return data?.storeName;
    }

    // If 'data' or 'storeName' is not available or is null, return the default store name "Help Center"
    return "Help Center";
  }, [data]); // Re-run the function when the 'data' object changes

  const backgroundImage = useMemo(() => {
    if (data?.backgroundImage) {
      return data?.backgroundImage;
    } else if (data?.themeType === "night_in_the_oscars") {
      return themeDefaultBackground;
    }
    return "";
  }, [data?.backgroundImage, data?.themeType]);

  if (isError) {
    pushTheToast({
      position: "top-right",
      text: "Something went wrong",
      type: "danger",
    });

    return (
      <>
        <div className={`p-2 ${styles.themeWrapper}`}>
          <span className={`${styles.errorMsg}`}>Something Went Wrong</span>
        </div>
      </>
    );
  }

  return (
    <div
      className={`p-2 p-md-3 p-lg-4 ${styles.themeWrapper}`}
      style={{
        filter:
          isLoading || updateKBSettings.isLoading || isFetching
            ? "blur(3px)"
            : "",
        position: "relative",
      }}
    >
      {isLoading || updateKBSettings.isLoading || isFetching ? (
        <div
          style={{
            position: "absolute",
            zIndex: 1,
            top: "50%",
            left: "50%",
          }}
        >
          <div className="spinner-border text-secondary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : null}

      <div
        className={`pt-3 ${styles.previewBox}`}
        style={{
          backgroundColor: `${
            data?.themeType === "night_in_the_oscars" ? "#393939" : "#ffffff"
          }`,
        }}
      >
        <div className="px-3 d-flex align-items-center">
          <div className={`${styles.dots}`}></div>
          <div className={`${styles.dots} ${styles.red}`}></div>
          <div className={`${styles.dots} ${styles.yellow}`}></div>
        </div>
        <div
          className={`mt-2 text-center d-flex flex-column justify-content-center align-items-center ${styles.previewHeader}`}
          style={{
            background: data?.backgroundColor ?? "#0b68bb",
            backgroundImage: `url(${backgroundImage})`, //added default background image
            backgroundSize: "cover",
          }}
        >
          <p className="mb-1">
            {data?.logoImage ? (
              <AxiosImg
                url={data?.logoImage}
                className={`${styles.helpLogo}`}
              />
            ) : (
              <img src={helpLogo} alt="" className={`${styles.helpLogo}`} />
            )}
            <span className={`ms-2 ${styles.acme}`} style={parentStyle}>
              {storeName}
            </span>
          </p>
          <h4 className={`mb-1 ${styles.helpText}`} style={parentStyle}>
            {data?.heading}
          </h4>
          <p className={`${styles.previewName}`} style={parentStyle}>
            {data?.subheading}
          </p>
        </div>
        <div>
          <div className={`d-flex align-items-center ps-4 ${styles.searchBox}`}>
            <span className={`${styles.searchText}`} style={parentStyle}>
              {" "}
              <i className="fa-solid fa-magnifying-glass"></i>{" "}
            </span>
            <span className={`ps-2 ${styles.searchText}`}>
              {data?.placeholder}
            </span>
          </div>
        </div>

        {/* If Emoji input is clicked then show the below  */}
        {emojiClick ? (
          <div className={`text-center px-2 mb-4 ${styles.emojiSection}`}>
            <p
              className={`${styles.articleMain} ${
                data?.themeType === "night_in_the_oscars" ? "text-white" : ""
              }`}
            >
              Thank you for leaving a rating !
            </p>{" "}
          </div>
        ) : (
          // Else show the below
          <div className={`text-center px-2 mb-4 ${styles.emojiSection}`}>
            {/* If Allow User Enabled  then only show the below */}
            {allowUsers && (
              <>
                <p
                  className={`${styles.articleMain} saufter_h5 ${
                    data?.themeType === "night_in_the_oscars"
                      ? "text-white"
                      : ""
                  }`}
                >
                  Did you find this article helpful?
                </p>

                {/* Div For Emoji */}
                {selectedRadio === "flexRadioDefault1" ? (
                  <div
                    className={`d-flex align-items-center justify-content-center`}
                    onClick={handleClickOnEmoji}
                    role="button"
                  >
                    <div
                      className={`d-flex flex-column align-items-center ${styles.emojiWrap}`}
                    >
                      <p className="mb-0">😄</p>
                      <span
                        className={`${styles.emojiText} saufter_h6 ${
                          data?.themeType === "night_in_the_oscars"
                            ? "text-white"
                            : ""
                        }`}
                      >
                        Happy
                      </span>
                    </div>
                    <div
                      className={`d-flex flex-column align-items-center  ${styles.emojiWrap}`}
                    >
                      <p className="mb-0">🙂</p>
                      <span
                        className={`${styles.emojiText} saufter_h6 ${
                          data?.themeType === "night_in_the_oscars"
                            ? "text-white"
                            : ""
                        }`}
                      >
                        Neutral
                      </span>
                    </div>
                    <div
                      className={`d-flex flex-column align-items-center  ${styles.emojiWrap}`}
                    >
                      <p className="mb-0">😞</p>
                      <span
                        className={`${styles.emojiText} saufter_h6 ${
                          data?.themeType === "night_in_the_oscars"
                            ? "text-white"
                            : ""
                        }`}
                      >
                        Sad
                      </span>
                    </div>
                  </div>
                ) : (
                  <div
                    className={`d-flex align-items-center justify-content-center`}
                    onClick={handleClickOnEmoji}
                    role="button"
                  >
                    <div
                      className={`d-flex flex-column align-items-center ${styles.emojiWrapThumb}`}
                    >
                      <p className="mb-0">👍</p>
                      <span
                        className={`${styles.emojiText} saufter_h6 ${
                          data?.themeType === "night_in_the_oscars"
                            ? "text-white"
                            : ""
                        }`}
                      >
                        Yes
                      </span>
                    </div>
                    <div
                      className={`d-flex flex-column align-items-center  ${styles.emojiWrapThumb}`}
                    >
                      <p className="mb-0">👎</p>
                      <span
                        className={`${styles.emojiText} saufter_h6 ${
                          data?.themeType === "night_in_the_oscars"
                            ? "text-white"
                            : ""
                        }`}
                      >
                        No
                      </span>
                    </div>
                  </div>
                )}
              </>
            )}

            {/* SHow Count is Enabled  */}
            {showCount && (
              <span
                className={`${styles.helpCount} saufter_h5 ${
                  data?.themeType === "night_in_the_oscars" ? "text-white" : ""
                }`}
              >
                0 out of 0 people found this article helpful so far
              </span>
            )}
          </div>
        )}
        <div className={`text-center mt-4 ${styles.footer}`}>
          {data?.helplamaBranding && (
            <p
              className={`mb-1 ${styles.brand} d-flex align-items-center justify-content-center`}
            >
              <span
                className={`${
                  data?.themeType === "night_in_the_oscars"
                    ? styles.nightThemeFooter
                    : styles.alicethemeFooter
                }`}
              >
                Powered by
              </span>
              {/* Dec 6 hided the footer logo and showing the saufter logo */}
              <div
                className={`mx-1 ${
                  data?.themeType === "night_in_the_oscars" &&
                  styles.nightThemeFooterImg
                }`}
              >
                <img
                  src={saufterLogo}
                  alt=""
                  className={`${styles.helpLogo}`}
                />
              </div>
              {/* {data?.footerLogo ? (
                <AxiosImg
                  url={data?.footerLogo}
                  className={`${styles.helpLogo}`}
                />
              ) : (
                <img src={helpLogo} alt="" className={`${styles.helpLogo}`} />
              )} */}
              {/*rendering the "Powered by" text from backend */}
              <span
                className={`${styles.invertCss} ${
                  data?.themeType === "night_in_the_oscars"
                    ? styles.nightThemeFooterLogo
                    : styles.aliceThemeFooterLogo
                }`}
              >
                {data?.helplamaBrandingText ?? "Powered by Saufter"}
              </span>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default RatingSection;
