import { useNavigate, useParams } from "react-router-dom";
import { RESET } from "src/store/slices/targetedMessage/targetedMessage.slice";
import { useAppDispatch } from "src/store/store";
import styles from "./DiscardModal.module.scss";

interface Props {
  // Props for hide the modal
  onHide: string;
}
const DiscardModal = ({ onHide }: any) => {
  //Function  to hide the modal
  const handleClose = (e: any) => {
    e.preventDefault();
    onHide(e);
  };
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  function handleClick() {
    onHide();
    dispatch(RESET({}));
    navigate(`/live-chat/settings/${params.integrationId}/targetMessage`);
  }
  const params = useParams();
  return (
    <>
      <div className={`${styles.modalMain}`}>
        <h3>Do you want to proceed without saving changes ?</h3>
        <span>This will discard all the changes that you have made</span>
        <div className={`${styles.actionDiv}`}>
          <button className={`${styles.cancelBtn}`} onClick={handleClose}>
            Cancel
          </button>
          <button className={`${styles.confirmBtn}`} onClick={handleClick}>
            Confirm
          </button>
        </div>
      </div>
    </>
  );
};
export default DiscardModal;
