import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import ticketSidebarSlice from "./slices/ticketSidebar/ticketSidebar.slice";
import ticketTagsSlice from "./slices/ticketTags/ticketTags.slice";
import ticketReducer from "src/store/slices/tickets/tickets.slice";
import ticketUsersSlice from "./slices/ticketUsers/ticketUsers.slice";
import ticketRemarksSlice from "./slices/ticketRemarks/ticketRemarks.slice";
import ticketStatusSlice from "./slices/ticketStatus/ticketStatus.slice";
import ticketSendMessageSlice from "./slices/ticketSendMessage/ticketSendMessage.slice";
import ticketFiltersSlice from "./slices/ticketFilters/ticketFilters.slice";
import innerTicketSlice from "./slices/innerTicket/innerTicket.slice";
import ticketMsgDraftStatusSlice from "./slices/ticketMsgDraftStatus/ticketMsgDraftStatus.slice";
import ticketViewsSlice from "./slices/ticketViews/ticketViews.slice";
import draftFlowSlice from "./slices/draftFlow/draftFlow.slice";
import shopifySidebarSlice from "./slices/shopifySidebar/shopifySidebar.slice";
import teamSettingsSlice from "./slices/teamSettings/teamSettings.slice";
import profileSettingsSlice from "./slices/settings/profile/profileSettings.slice";
import userSettingsSlice from "./slices/settings/user/userSettings.slice";
import globalsSlice from "./slices/globals/globals.slice";
import tagsSettingsSlice from "./slices/settings/tags/tagsSettings.slice";
import singleOrderSlice from "./slices/singleOrder/order.slice";
import callRecordingSlice from "./slices/callRecording/callRecording.slice";
import step9ConfigurationSlice from "./slices/initiateReturn/step9Configuration.slice";
import step10ConfigurationSlice from "./slices/initiateReturn/step10Configuration.slices";
import step11ConfigSlice from "./slices/initiateReturn/step11Config.slice";
import step12ConfigSlice from "./slices/initiateReturn/step12Config.slice";
import step14ConfigSlice from "./slices/initiateReturn/step14Configuration.slice";
import step15ConfigurationSlice from "./slices/initiateReturn/step15Configuration.slice";
// import step13AskDetailsSlice from "./slices/initiateReturn/step13AskDetails.slice";
import cannedResponseSlice from "./slices/liveChatSetting/cannedResponse/cannedResponse.slice";
import availabilitySettingSlice from "./slices/liveChatSetting/chatWidgetSetting/availability/availabilitySetting.slice";
import knowledgeBasedSettingSlice from "./slices/liveChatSetting/chatWidgetSetting/knowledgeBased/knowledgeBasedSetting.slice";
import customizationSettingSlice from "./slices/liveChatSetting/chatWidgetSetting/customization/customizationSettings.slice";
import helpCenterSettingSlice from "./slices/liveChatSetting/chatWidgetSetting/helpCenterSetting/helpCenterSetting.slice";
import preChatFormSettingSlice from "./slices/liveChatSetting/Forms/preChatForm/preChatFormSetting.slice";
import askForEmailSettingSlice from "./slices/liveChatSetting/Forms/askForEmail/askForEmailSetting.slice";
import targetedMessageSlice from "./slices/targetedMessage/targetedMessage.slice";
import postChatFormSettingSlice from "./slices/liveChatSetting/Forms/postChatForm/postChatFormSetting.slice";
import ticketChatFormSettingSlice from "./slices/liveChatSetting/Forms/ticketChatForm/ticketChatFormSetting.slice";
import chatSettingSlice from "./slices/liveChatSetting/chatWidgetSetting/chatSetting/chatSetting.slice";
import RoutingRulesSettingSlice from "./slices/liveChatSetting/RoutingRules/RoutingRulesSetting.slice";
import GoalSettingSlice from "./slices/liveChatSetting/Goals/GoalSetting.slice";
import articleCategorySlice from "./slices/liveChatSetting/chatWidgetSetting/articleCategory/articleCategory.slice";
import articleSettingSlice from "./slices/liveChatSetting/chatWidgetSetting/articleSetting/articleSetting.slice";
import LiveChatIntegrationsSlice from "./slices/liveChatSetting/LiveChatIntegrations/LiveChatIntegrations.slice";
import websiteSettingsSlice from "./slices/liveChatSetting/websiteSettings/websiteSettings.slice";
import chatWidExtCustSlice from "./slices/liveChatSetting/chatWidExchCust/chatWidExchCust.slice";
import chatWidExchMsgSlice from "./slices/liveChatSetting/chatWidExchMsg/chatWidExchMsg.slice";
import liveChatHistorySlice from "./slices/liveChatSetting/liveChatHistory/liveChatHistory.slice";
import liveChatTrafficSlice from "./slices/liveChatTraffic/liveChatTraffic.slice";
import brandSettingsSlice from "./slices/settings/brands/brandSettings.slice";
import editCustomerDetailSlice from "./slices/editCustomerDetails/editCustomerDetails.slice";
import emailIntegrationSlice from "./slices/emailIntegration/emailIntegration.slices";
import ticketGlobalSearchSlice from "./slices/ticketGlobalSearch/ticketGlobalSearch.slice";
import returnExchangeSlice from "./slices/initiateReturn/retrunExchange.slice";
import liveChatTabQuickActionsSlice from "./slices/liveChatSetting/liveChatTabQuickActions/liveChatTabQuickActions.slice";
import allOrdersSlice from "./slices/allOrders/allOrders.slice";
import antiRefundConfigSlice from "./slices/initiateReturn/antiRefundConfig.slice";
import antiRefundOrderTypesConfigSlice from "./slices/initiateReturn/antiRefundOrderTypesConfig.slice";
import knowledgeBaseSlice from "./slices/knowledgeBase/knowledgeBase.slice";
import botProfilesSlice from "./slices/botProfiles/botProfiles.slice";
import returnModalSlices from "src/features/ReturnAutoWorkFlow/children/ConfigModals/store/returnModalsStore";

export const store = configureStore({
  reducer: {
    ticket: ticketReducer,
    ticketSideBar: ticketSidebarSlice,
    ticketTags: ticketTagsSlice,
    ticketUsers: ticketUsersSlice,
    ticketRemarks: ticketRemarksSlice,
    ticketStatus: ticketStatusSlice,
    ticketSendMessage: ticketSendMessageSlice,
    ticketFilter: ticketFiltersSlice,
    innerTicket: innerTicketSlice,
    ticketMsgDraftStatus: ticketMsgDraftStatusSlice,
    ticketViews: ticketViewsSlice,
    draftFlow: draftFlowSlice,
    shopifySidebar: shopifySidebarSlice,
    teamSettings: teamSettingsSlice,
    profileSettings: profileSettingsSlice,
    userSettings: userSettingsSlice,
    tagsSettings: tagsSettingsSlice,
    globals: globalsSlice,
    allOrders: allOrdersSlice,
    singleOrder: singleOrderSlice,
    callrecording: callRecordingSlice,
    step9Configuration: step9ConfigurationSlice,
    step10Configuration: step10ConfigurationSlice,
    step11Config: step11ConfigSlice,
    step12Config: step12ConfigSlice,
    step14Config: step14ConfigSlice,
    step15Configuration: step15ConfigurationSlice,
    // step13AskDetails: step13AskDetailsSlice,
    targetedMessage: targetedMessageSlice,
    customizationSettings: customizationSettingSlice,
    availabilitySettings: availabilitySettingSlice,
    knowledgeBasedSettings: knowledgeBasedSettingSlice,
    helpCenterSettings: helpCenterSettingSlice,
    cannedResponses: cannedResponseSlice,
    preChatFormSetting: preChatFormSettingSlice,
    askForEmailSetting: askForEmailSettingSlice,
    postChatFormSetting: postChatFormSettingSlice,
    liveChatSetting: chatSettingSlice,
    ticketChatFormSetting: ticketChatFormSettingSlice,
    routingRulesSetting: RoutingRulesSettingSlice,
    goalSetting: GoalSettingSlice,
    articleCategory: articleCategorySlice,
    articleSetting: articleSettingSlice,
    liveChatIntegrations: LiveChatIntegrationsSlice,
    websiteSettings: websiteSettingsSlice,
    chatWidExchCust: chatWidExtCustSlice,
    chatWidExhMsg: chatWidExchMsgSlice,
    liveChatHistory: liveChatHistorySlice,
    liveChatTraffic: liveChatTrafficSlice,
    brandSettings: brandSettingsSlice,
    editcustomerdetails: editCustomerDetailSlice,
    emailIntegration: emailIntegrationSlice,
    ticketGlobalSearch: ticketGlobalSearchSlice,
    returnExchange: returnExchangeSlice,
    antiRefundConfig: antiRefundConfigSlice,
    antiRefundOrderTypesConfig: antiRefundOrderTypesConfigSlice,
    liveChatTabQuickActions: liveChatTabQuickActionsSlice,
    knowledgeBase: knowledgeBaseSlice,
    botProfiles: botProfilesSlice,
    ...returnModalSlices,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
