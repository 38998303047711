import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Filters,
  initialFilters,
  resetFilters,
  setCurrentViewFilters,
  setFiltersFromURL,
  TicketFilterCol,
} from "src/store/slices/ticketFilters/ticketFilters.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { isObjOrStrEmpty } from "src/utils/utils";
import {
  resetCachedTickets,
  resetPagination,
} from "src/store/slices/tickets/tickets.slice";
import { useParams } from "react-router-dom";

interface IFilterValue {
  id: number;
  name: string;
  compare: string;
  isValueRequired: any;
  viewFilter: boolean;
}
export const useMapSidebarFilters = () => {
  const { ticketBtnData, selectedBtn } = useAppSelector(
    (state) => state.ticketSideBar
  );
  const { activeFilters } = useAppSelector((state) => state.ticketFilter);
  const { tableType }: any = useParams(); //to get view id

  const viewId = useMemo(() => {
    const viewId = tableType.includes("viewId")
      ? parseInt(tableType.split("::")[1])
      : null;
    return viewId;
  }, [tableType]);
  const dispatch = useAppDispatch();

  const currentViewFilters = useAppSelector(
    (state) => state.ticketFilter.currentViewFilters
  );

  const otherFiltersRef = useRef({ activeFilters });

  useEffect(() => {
    otherFiltersRef.current.activeFilters = activeFilters;
  }, [activeFilters]);
  // useEffect(() => {
  //   if (viewId) {
  //     dispatch(getCurrentViewData(viewId));
  //   }
  // }, [viewId]);
  const mergeFilters = useCallback(
    (activeFilters: Array<any>, mergeFilters: Array<any>) => {
      if (!isObjOrStrEmpty(activeFilters)) {
        let filters: any = activeFilters.map((activeFilter) => {
          return {
            ...activeFilter,
            viewFilter: true,
          };
        });

        mergeFilters.map((otherFilter: any) => {
          //if all selected no need to add it to the filter array
          if (otherFilter.id == 0 || otherFilter?.viewFilter === true) {
            return;
          }

          let isExists = filters.filter(
            (filter: any) => filter.id == otherFilter.id
          );

          if (!isExists.length) {
            filters.push(otherFilter);
          }
        });

        return filters;
      } else {
        return mergeFilters.filter((filter) => !filter?.viewFilter);
      }
    },
    []
  );
  const mergeViewFilterAndOtherFilters = useCallback(
    (activeFilters: Filters, otherFilters: Filters) => {
      const tempActiveFilters: Filters = { ...activeFilters };
      const {
        filterByCreatedDate,
        filterByClosedDate,
        filterByCreatedDateValue,
        filterByClosedDateValue,
        filterByChannels,
        filterByBrands,
        filterByAgents,
        filterByTags,
        filterByEmails,
        filterByAssignees,
        filterByUserType,
        filterByLastMessage,
        filterByNotRepliedSince,
        filterByNotRepliedSinceValue,
        filterByTicketStatus,
        filterByDraftStatus,
        filterByDraftedBy,
      }: any = otherFilters;

      if (isObjOrStrEmpty(activeFilters.filterByCreatedDate)) {
        tempActiveFilters.filterByCreatedDate = filterByCreatedDate;
        tempActiveFilters.filterByCreatedDateValue = filterByCreatedDateValue;
      }

      if (isObjOrStrEmpty(activeFilters.filterByClosedDate)) {
        tempActiveFilters.filterByClosedDate = filterByClosedDate;
        tempActiveFilters.filterByClosedDateValue = filterByClosedDateValue;
      }

      if (isObjOrStrEmpty(activeFilters.filterByNotRepliedSince)) {
        tempActiveFilters.filterByNotRepliedSince = filterByNotRepliedSince;
        tempActiveFilters.filterByNotRepliedSinceValue =
          filterByNotRepliedSinceValue;
      }

      if (!isObjOrStrEmpty(filterByChannels)) {
        tempActiveFilters.filterByChannels = mergeFilters(
          tempActiveFilters.filterByChannels,
          filterByChannels
        );
      }
      if (!isObjOrStrEmpty(filterByBrands)) {
        tempActiveFilters.filterByBrands = mergeFilters(
          tempActiveFilters.filterByBrands,
          filterByBrands
        );
      }
      if (!isObjOrStrEmpty(filterByAgents)) {
        tempActiveFilters.filterByAgents = mergeFilters(
          tempActiveFilters.filterByAgents,
          filterByAgents
        );
      }
      if (!isObjOrStrEmpty(filterByTags)) {
        tempActiveFilters.filterByTags = mergeFilters(
          tempActiveFilters.filterByTags,
          filterByTags
        );
      }
      if (isObjOrStrEmpty(activeFilters.filterByEmails)) {
        tempActiveFilters.filterByEmails = filterByEmails;
      }

      if (!isObjOrStrEmpty(filterByUserType)) {
        tempActiveFilters.filterByUserType = mergeFilters(
          tempActiveFilters.filterByUserType,
          filterByUserType
        );
      }
      if (!isObjOrStrEmpty(filterByLastMessage)) {
        tempActiveFilters.filterByLastMessage = mergeFilters(
          tempActiveFilters.filterByLastMessage,
          filterByLastMessage
        );
      }

      if (!isObjOrStrEmpty(filterByDraftStatus)) {
        tempActiveFilters.filterByDraftStatus = mergeFilters(
          tempActiveFilters.filterByDraftStatus,
          filterByDraftStatus
        );
      }
      if (!isObjOrStrEmpty(filterByDraftedBy)) {
        tempActiveFilters.filterByDraftedBy = mergeFilters(
          tempActiveFilters.filterByDraftedBy,
          filterByDraftedBy
        );
      }

      if (!isObjOrStrEmpty(filterByTicketStatus)) {
        tempActiveFilters.filterByTicketStatus = mergeFilters(
          tempActiveFilters.filterByTicketStatus,
          filterByTicketStatus
        );
      }

      return tempActiveFilters;
    },
    [mergeFilters]
  );

  useEffect(() => {
    if (viewId) {
      // let allFilters =currentViewFilters;
      // dispatch(setFiltersFromURL(allFilters));
    } else if (ticketBtnData.length) {
      const data = ticketBtnData[0];

      let activeChannelFilters: IFilterValue[] = [];
      let activeDraftStatusFilters: IFilterValue[] = [];
      let activeTicketStatusFilters: IFilterValue[] = [];

      //iterating over each sidebar button data and getting the applied filters
      data.ticketBtnList.forEach((list) => {
        //checking if selected button is "My" or "all" ticket
        if (list.keyName === selectedBtn) {
          //getting the selected options in active channels dropdown
          let activeChannelIds = list.ddOuterList[0]?.ddSelectedIndex;
          //iterating over selected ids
          activeChannelIds?.forEach((selectedId) => {
            //initial object of filter value
            let filter: IFilterValue = {
              id: selectedId,
              name: list.ddOuterList[0].ddList[selectedId].name,
              compare: "=",
              isValueRequired: true,
              viewFilter: true,
            };

            if (selectedId != 0) {
              activeChannelFilters.push(filter);
            }
          });
        }

        if (list.keyName === selectedBtn) {
          let activeDraftStatusIds = list.ddOuterList[2]?.ddSelectedIndex ?? [];
          activeDraftStatusIds?.forEach((selectedId) => {
            //initial object of filter value
            let filter: IFilterValue = {
              id: selectedId,
              name: list.ddOuterList[2].ddList[selectedId].name,
              compare: "=",
              isValueRequired: true,
              viewFilter: true,
            };

            //checking if "all" selected, if yes then not adding it to filters
            if (selectedId != 0) {
              activeDraftStatusFilters.push(filter);
            }
          });
        }

        if (list.keyName === selectedBtn) {
          let activeTicketStatusIds = list.ddOuterList[1].ddSelectedIndex;

          activeTicketStatusIds?.forEach((selectedId) => {
            //initial object of filter value
            let filter: IFilterValue = {
              id: selectedId,
              name: list.ddOuterList[1].ddList[selectedId].name,
              compare: "=",
              isValueRequired: true,
              viewFilter: true,
            };

            //checking if "all" selected, if yes then not adding it to filters
            if (selectedId != 0) {
              activeTicketStatusFilters.push(filter);
            }
          });
        }
      });
      let allFilters: any = {
        ...initialFilters,
        filterByTicketStatus: activeTicketStatusFilters,
        filterByDraftStatus: activeDraftStatusFilters,
        filterByChannels: activeChannelFilters,
      };

      //updating the view filters in current view filter slice
      dispatch(setCurrentViewFilters(allFilters));
      // let mergedFilters = mergeViewFilterAndOtherFilters(
      //   allFilters,
      //   otherFiltersRef.current.activeFilters
      // );

      // dispatch(setFiltersFromURL(mergedFilters));
    }
  }, [
    viewId,
    JSON.stringify(ticketBtnData),
    JSON.stringify(selectedBtn),
    mergeViewFilterAndOtherFilters,
  ]);

  //callback function handler restore to default filter
  const restoreToDefaultFilters = useCallback((e: any) => {
    dispatch(
      resetFilters({
        removeFilterByLastUpdated: false,
        resetActiveFilters: true,
      })
    );
    dispatch(resetPagination());
    dispatch(resetCachedTickets());
  }, []);

  return {
    restoreToDefaultFilters,
  };
};
