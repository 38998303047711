import { axiosJSON } from "src/globals/axiosEndPoints";

const emailIntWithGmailService = async (params: {
  emailIntegrationId: string;
  incomming?: boolean;
}) => {
  const { data: res } = await axiosJSON.post(
    `/api/setting/integration/domainGoogleVerification`,
    params,
  );

  if (res.err || res.error) {
    throw new Error(res.msg ?? res.message ?? "Something Went Wrong!");
  }

  return res.data.url as string;
};

export default emailIntWithGmailService;
