import styles from "./ShopifyIntegration.module.scss";
import backArrow from "src/assets/images/backArrow.png";
import ShopifyStore from "./Children/ShopifyStore/ShopifyStore";
import AboutIntegShopify from "./Children/AboutIntegShopify/AboutIntegShopify";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import EnterStoreNameModal from "./Children/EnterStoreNameModal/EnterStoreNameModal";
import {
  getAllShopifyIntegrations,
  IShopifyIntegrationItem,
} from "src/services/Integrations/getAllShopifyIntegrations";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import Loader from "src/components/Loader";
import SettingToast from "src/routes/Setting/children/SettingToast/SettingToast";

const ShopifyIntegration = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showToast, setShowToast] = useState("" as "" | "success");
  const [showWhat, setShowWhat] = useState<string>(
    "storesPage" as "storesPage" | "aboutPage" | ""
  );
  const [currentStoreData, setCurrentStoreData] =
    useState<IShopifyIntegrationItem>();
  const [params, setParams] = useSearchParams();

  const [loading, setLoading] = useState<boolean>(true);
  const [loadingText, setLoadingText] = useState<string>("");
  const [storeIntegrationIds, setStoreIntegrationIds] = useState(
    [] as Array<number>
  );

  const [storeIntegrationData, setStoreIntegrationData] = useState(
    {} as { [id: number]: IShopifyIntegrationItem }
  );

  const pollingTimeoutRef = useRef(null as any);

  // check integration is success or not
  useEffect(() => {
    let success = params.get("success");
    if (success !== "" && success?.trim() === "true") {
      params.delete("success");
      setParams(params);
      //removed the existing logic for showing the success msg
      // Show success toast notification, with custom component
      pushTheToast({
        type: "success",
        text: SettingToast,
        customCompProps: {
          text: (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ margin: "-7px" }}
            >
              <span>
                <i className={`fa-solid fa-circle-check me-1`}></i>
              </span>
              <span>Integration successfully added!</span>
            </div>
          ),
        },
        isTextComponent: true,
        position: "top-center",
      });
    } else {
    }
  }, [params]);

  const fetchAllShopifyIntegrations = () => {
    setLoading(true);
    getAllShopifyIntegrations()
      .then((res) => {
        setStoreIntegrationData(res.integrationsList);
        setStoreIntegrationIds(res.integrationIdsList);
        if (res?.metaData?.integrationInProcess) {
          setLoadingText(res?.metaData?.loadingText ?? "");
          clearTimeout(pollingTimeoutRef.current);
          pollingTimeoutRef.current = setTimeout(() => {
            fetchAllShopifyIntegrations();
          }, 15000);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        pushTheToast({
          type: "danger",
          text: err.message,
          position: "top-right",
        });
        setLoading(false);
      });
  };

  // fetch the shopify stores that are integrated
  useEffect(() => {
    fetchAllShopifyIntegrations();
  }, [false]);

  // show modal to enter store name
  const showStoreNameModal = () => {
    setShowModal(true);
  };

  return (
    <>
      {/* Show the integrated stores page */}
      {showWhat === "storesPage" && (
        <div className={`${styles.fbMain}`}>
          {/* ---Home page for shopify integration--- */}
          <div className={``}>
            <div
              className={`d-flex justify-content-between align-items-center`}
            >
              <div className={`d-flex`}>
                <div>
                  <img
                    className={`${styles.leftArrow}`}
                    src={backArrow}
                    onClick={(e) => {
                      navigate("/settings/browse-integration");
                    }}
                  />
                </div>
                <div className={`${styles.fbMainDiv}`}>
                  <h1 className={`${styles.fb}`}>Shopify</h1>
                </div>
              </div>

              {/* -----Show this div when Integration successfully added!----- */}

              {/* commented the success notification div, this div already passed as custom component in the pushTheToast function call */}
              {/* {showToast === "success" && (
                <div className={`${styles.successMsg}`}>
                  <span>
                    <i className={`fa-solid fa-circle-check me-1`}></i>
                  </span>
                  <span>Integration successfully added!</span>
                </div>
              )} */}

              <div>
                {/* ---show button when add shopify--- */}
                {loading === false && storeIntegrationIds.length === 0 && (
                  <button
                    className={`${styles.addBtn} d-none`}
                    onClick={(e) => showStoreNameModal()}
                  >
                    + Add Shopify
                  </button>
                )}
              </div>
            </div>

            {/* Choose an account  */}
            <div className={`${styles.chooseBoxMain}`}>
              <h1 className={`${styles.shopifyHead}`}>
                Shopify is an e-commerce platform used by 500,000+ stores.
              </h1>

              {/* Show loader */}
              {/* {loading === true && <Loader />} */}
              {loading === true && (
                <div className="text-center">
                  <Loader />
                  {loadingText}
                </div>
              )}

              {loading === false && (
                <>
                  <div className={`${styles.chooseBox}`}>
                    <h3 className={`pb-1 ${styles.chooseBoxHead}`}>
                      Your Shopify stores
                    </h3>
                    <div>
                      {/* ----Show this div when there is no integration---- */}
                      {storeIntegrationIds.length === 0 && (
                        <>
                          <div>
                            <span className={`${styles.chooseBoxSpan}`}>
                              You have no integration at the moment.
                            </span>
                          </div>
                        </>
                      )}

                      {/* ----Show this div when there is an integration---- */}
                      {storeIntegrationIds.length > 0 && (
                        <>
                          {storeIntegrationIds.map((integrationId) => {
                            return (
                              <ShopifyStore
                                storeData={storeIntegrationData[integrationId]}
                                setShowWhat={setShowWhat}
                                setCurrentStoreData={setCurrentStoreData}
                              />
                            );
                          })}
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}

              {/* Show add shopify button in bottom */}
              {loading === false && storeIntegrationIds.length === 0 && (
                <>
                  <div className={`d-none`}>
                    {/* ---show button when add shopify--- */}
                    <p className={`${styles.addInt}`}>
                      <span onClick={(e) => showStoreNameModal()}>
                        + Add Shopify{" "}
                      </span>
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}

      {/* Show modal to enter store name */}
      {showModal === true && (
        <EnterStoreNameModal
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}

      {/* Show the about store page */}
      {showWhat === "aboutPage" && (
        <AboutIntegShopify
          showWhat={showWhat}
          setShowWhat={setShowWhat}
          fetchAllShopifyIntegrations={fetchAllShopifyIntegrations}
          currentStoreData={currentStoreData}
        />
      )}
    </>
  );
};
export default ShopifyIntegration;
