import { useMutation } from "@tanstack/react-query";
import { addVariable } from "src/services/UiAutomation/ReturnExchange/Configuration/Variables/addVariable";

const useAddVariable = () => {
  const mutation = useMutation({
    mutationFn: addVariable,
    onSuccess: () => {
      console.log("Success");
    },
    onError: () => {
      console.log("Error");
    },
  });

  const AddVariable = async (name: string, value: string) => {
    let result;
    try {
      result = await mutation.mutateAsync({ name, value });
    } catch (err) {
      result = err;
    }
    return result;
  };

  return { AddVariable };
};

export default useAddVariable;
