import { useCallback, useMemo, useState } from "react";
import Select, { components, GroupBase, StylesConfig } from "react-select";
import { useAutomationContext } from "src/routes/BotSettings/Children/Automation/Hooks/useAutomationContext";
import {
  IListQuestion,
  IMultiSelectListQuestion,
  Question,
  QuestionType,
} from "src/services/Automation/getAutomationById.service";
import useQuestionSelectDD from "./useQuestionSelectDD";
import styles from "./ListQuestion.module.scss";
import { EAutomationType } from "src/routes/BotSettings/Children/AutomationList/AutomationList";

interface QuestionsProps {
  questionData: IMultiSelectListQuestion | IListQuestion;
  stepId: string;
}

export interface ListQuestionOptionType {
  value: string;
  label: string;
}

const defaultStyles: StylesConfig<
  ListQuestionOptionType,
  boolean,
  GroupBase<ListQuestionOptionType>
> = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "#FCFCFC",
    color: "#000",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "21px",
    minHeight: "32px",
    borderRadius: "2px",
    "&:hover": {
      borderColor: "#BBBBBB",
    },
    border: state.isFocused ? "1px solid #ECECEC" : "1px solid #ECECEC",
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "#E9E9E9",
    color: "#000",
    fontSize: "14px",
    fontWeight: "normal",
    lineHeight: "21px",
    height: "24px",
    margin: "0px",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    padding: "0px 5px",
    marginRight: "3px",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#6c757d",
    fontSize: "14px",
  }),
  input: (provided) => ({
    ...provided,
    margin: "0px",
    padding: "0 3px",
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "0 6px",
    font: "normal normal normal 12px/18px Poppins",
    letterSpacing: "0px",
  }),
  clearIndicator: (provided, state) => ({
    ...provided,
    padding: "0px 2px",
    cursor: "pointer",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: "0px 2px",
    cursor: "pointer",
    color: "#000", // Customize arrow button hover color
    "&:hover": {
      color: "#000", // Customize arrow button hover color
    },
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    padding: "0px 2px",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: "#000",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "21px",
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: "#707070",
    ":hover": {
      color: "#707070",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "#FFF" : "#000",
    backgroundColor: state.isSelected ? "#007bff" : "#FFF",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "16px",
    borderRadius: "4px",
    cursor: "pointer",
    ":hover": {
      backgroundColor: "rgba(112, 112, 112, 0.1)",
    },
  }),
  menu: (provided) => ({
    ...provided,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 16px #00000029",
    borderRadius: "5px",
    maxWidth: "231px",
    padding: "10px 5px",
  }),
};

/**
 * Checks if the question is a valid list question
 * @param question Automation Question
 * @returns boolean representing the validity
 */
const isListQuestion = (question: Question): question is IListQuestion => {
  return question.questionType === QuestionType.List;
};

/**
 * Checks if the question is a valid multi list question
 * @param question Automation Question
 * @returns boolean representing the validity
 */
const isMultiListQuestion = (
  question: Question,
): question is IMultiSelectListQuestion => {
  return question.questionType === QuestionType.MultiList;
};

const ListQuestion = ({ questionData, stepId }: QuestionsProps) => {
  const { updateQuestions, saveQuestionsData, formData, type } =
    useAutomationContext();

  const isQuestionUserSegment = useMemo(() => {
    return questionData.questionKey.toLowerCase().includes("usersegments");
  }, [questionData.questionKey]);

  const {
    fetchNextPageHandler,
    showLoader,
    options: apiOptions,
    optionsMap: apiOptionsMap,
  } = useQuestionSelectDD({ questionData, stepId });
  const [error, setError] = useState<string | null>(null);
  const { options, optionsMap } = useMemo(() => {
    const options: ListQuestionOptionType[] = [];
    const optionsMap: Record<string, ListQuestionOptionType> = {};

    questionData.listOfOptions?.forEach((op) => {
      const option = {
        value: op.id,
        label: op.value,
      };
      options.push(option);
      optionsMap[op.id] = option;
    });

    // If API options are required, add them and remove duplicates
    if (
      questionData.isApiRequired === true &&
      apiOptions.length !== 0 &&
      apiOptionsMap
    ) {
      const allOptions = [...options, ...apiOptions];
      const uniqueOptionsMap = new Map<string, ListQuestionOptionType>();

      allOptions.forEach((option) => {
        uniqueOptionsMap.set(option.value, option);
      });

      return {
        options: Array.from(uniqueOptionsMap.values()),
        optionsMap: { ...optionsMap, ...apiOptionsMap },
      };
    }

    return { options, optionsMap };
  }, [
    apiOptions,
    apiOptionsMap,
    questionData.isApiRequired,
    questionData.listOfOptions,
  ]);
  // Add state to manage menu open/close
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleMultiOptionChange = useCallback(
    (selectedOptions: ListQuestionOptionType[]) => {
      if (isMultiListQuestion(questionData)) {
        if (
          typeof questionData.maximumLimit === "number" &&
          selectedOptions.length > questionData.maximumLimit
        ) {
          if (isQuestionUserSegment) {
            setError(
              "* You can only select upto 3 user segments. Please remove a user segment if you wish to enter a different one.",
            );
          } else {
            setError(
              "Maximum selection limit reached. Please remove an option before adding a new one.",
            );
          }
          return;
        } else {
          setError(null);
        }

        const newValues = selectedOptions.map((option) => option.value);

        const isAnswered = newValues.length !== 0;

        updateQuestions({
          ...questionData,
          questionValue: newValues,
          isAnswered,
        });

        if (isAnswered) {
          saveQuestionsData({
            stepId,
            stepQuestions: {
              [questionData.questionKey]: newValues,
            },
          });
        }
      }
    },
    [
      isQuestionUserSegment,
      questionData,
      saveQuestionsData,
      stepId,
      updateQuestions,
    ],
  );

  const handleOptionChange = useCallback(
    (optionKey: ListQuestionOptionType | null) => {
      if (isListQuestion(questionData)) {
        updateQuestions({
          ...questionData,
          questionValue: optionKey?.value ?? "",
          isAnswered: optionKey !== null,
        });

        if (optionKey !== null && optionKey?.value) {
          saveQuestionsData({
            stepId,
            stepQuestions: {
              [questionData.questionKey]: optionKey.value,
            },
          });
        }
      }
    },
    [questionData, saveQuestionsData, stepId, updateQuestions],
  );

  const selectedValue = useMemo(() => {
    return Array.isArray(questionData.questionValue)
      ? questionData.questionValue
          .map((id) => optionsMap[id] || null)
          .filter(Boolean)
      : optionsMap[questionData.questionValue] || null;
  }, [questionData.questionValue, optionsMap]);
  // Modify DropdownIndicator to use isMenuOpen state
  const DropdownIndicator = ({ ...props }: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <span className={`pe-2 ${styles.dropdonArrow}`}>
          {/* Toggle icon based on isMenuOpen */}
          <i className={`fa-solid fa-caret-${isMenuOpen ? "up" : "down"}`}></i>
        </span>
      </components.DropdownIndicator>
    );
  };
  return (
    <div className="ms-lg-2 mx-0 px-0 mb-lg-0 d-flex flex-column w-100">
      <Select
        className={`${error ? styles.errorBorder : ""}`}
        styles={defaultStyles}
        value={selectedValue}
        isDisabled={questionData.isBrandRequired && formData.brand === null}
        options={options}
        placeholder={questionData.questionPlaceholder}
        onChange={(value) => {
          if (Array.isArray(value)) {
            handleMultiOptionChange(value as ListQuestionOptionType[]);
          } else {
            handleOptionChange(value as ListQuestionOptionType);
          }
        }}
        isSearchable={true}
        onMenuOpen={() => setIsMenuOpen(true)} // Set menu open state
        onMenuClose={() => setIsMenuOpen(false)} // Set menu close state
        onMenuScrollToBottom={fetchNextPageHandler}
        isLoading={showLoader}
        isMulti={Array.isArray(questionData.questionValue)}
        components={{
          IndicatorSeparator: null,
          DropdownIndicator,
        }}
      />
      {error && <div className={`${styles.errorText}`}>{error}</div>}
      {type === EAutomationType.BUG_MANAGEMENT && isQuestionUserSegment && (
        <a
          className={`pt-1 ${styles.createSegmentLink}`}
          href="/customer-segments"
          target="_blank"
        >
          Create new user segment
        </a>
      )}
    </div>
  );
};

export default ListQuestion;
