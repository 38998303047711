import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import ItemsAndTagsSelect from "src/components/AutomationComponents/IndividualComponents/ItemAndTagsSelect/ItemsAndTagsSelect";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { useReturnAutoWorkFlow } from "src/features/ReturnAutoWorkFlow/hooks/useReturnAutoWorkFlow";
import getProductsService, {
  ReturnProduct,
} from "src/services/ReturnAutoWorkFlow/OldModals/General/getProducts.service";
import { getAllProductTags } from "src/services/ReturnAutoWorkFlow/OldModals/General/getProductTags.service";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  updateFollowUpQuestionsConfigOrderTypes,
  validateChanges,
} from "../../../../store/slices/AntiRefundStrategiesOrdersSlice/antiRefundStrategiesOrders.slice";
import { getRefundRequestFollowUpQuestionsOrdersThunk } from "../../../../store/slices/AntiRefundStrategiesOrdersSlice/antiRefundStrategiesOrders.thunk";
import {
  RefundScenario,
  updateFollowUpQuestionsConfig,
} from "../../../../store/slices/AntiRefundStrategiesSlice/antiRefundStrategies.slice";
import { fetchRefundRequestFollowUpQuestionsThunk } from "../../../../store/slices/AntiRefundStrategiesSlice/antiRefundStrategies.thunk";
import {
  RefundScenarioState,
  ScenarioDispatchContext,
  ScenarioStateContext,
} from "../Scenario/ScenarioReducer";
import styles from "./AddStrategy.module.scss";

type Props = {
  onHide: () => void;
  refundReasonId: string | number;
  returnReason: string;
  scenario: RefundScenario;
  question: string;
};

type ItemAndTagSelectOptionType = {
  id?: number;
  value: string;
  label: string;
  price?: {
    //Changing format as per backend
    amount: string;
    currencyCode: string;
  };
  imgSrc?: string | null;
  type: "tag" | "item";
};

export enum FormElementTypes {
  TEXT = "87",
  LONG_TEXT = "74",
  MULTIPLE_CHOICE = "77",
  DROPDOWN = "76",
}

const limit = 25;

const SpecificElementModal = ({
  onHide,
  refundReasonId,
  returnReason,
  scenario,
  question,
}: Props) => {
  const dispatch = useAppDispatch();
  const { returnAutoWorkFlow } = useReturnAutoWorkFlow();

  // Get the reducer local state
  const localState: RefundScenarioState = useContext(ScenarioStateContext);

  //Get the reducer dispatch function
  const localDispatch = useContext(ScenarioDispatchContext);

  const [fetchedDataLength, setFetchedDataLength] = useState(0);

  const [errorMessage, setErrorMessage] = useState("");

  const [error, setError] = useState(false);

  const [itemError, setItemError] = useState(false);

  const [allOptions, setAllOptions] = useState<any>([]);

  const handleLoadOptions = useCallback(
    async (searchTerm: string, loadOptions: any, additional: any) => {
      if (!returnAutoWorkFlow.integrationId) {
        return {
          options: [],
          hasMore: false,
          additional: {
            page: 0,
          },
        };
      }

      searchTerm = searchTerm.trim();

      let productTagOptions: any = []; // Declare an empty array for product tags
      let options: any = []; // Declare an empty array for options

      if (!additional?.page) {
        try {
          setAllOptions([]);
          // Fetch product tags only on the first page
          const productTags = await getAllProductTags({
            integrationId: returnAutoWorkFlow.integrationId,
            limit,
          });

          productTagOptions = productTags.data?.map(
            (tag: string, idx: number) => {
              return {
                label: tag,
                value: tag,
                type: "tag",
              } as ItemAndTagSelectOptionType;
            },
          );

          const newOptions = [
            { label: "Select all items and tags", value: "*", imgSrc: null },
            { label: "Tags", value: "Tags", imgSrc: null, isDisabled: true },
          ];

          options.push(...newOptions);
        } catch (err) {
          return {
            options: [],
            hasMore: false,
            additional: {
              page: 0,
            },
          };
        }
      }

      const filteredProductTagOptions = productTagOptions.filter(
        (tagOption: { label: string }) =>
          tagOption.label.toLowerCase().includes(searchTerm.toLowerCase()),
      );

      try {
        const allShopifyProducts = await getProductsService({
          integrationId: returnAutoWorkFlow.integrationId,
          limit: limit,
          searchTerm,
          start: additional?.page ?? 0,
        });

        const shopifyProductOptions = allShopifyProducts.data?.map(
          (product: ReturnProduct) => {
            return {
              id: product.productId,
              imgSrc: product.imageUrl,
              label: product.productName,
              price: {
                //Changing format as per backend
                amount: product?.productPrice?.amount,
                currencyCode: product?.productPrice?.currencyCode,
              },
              value: product.productId.toString(),
              type: "item",
            } as ItemAndTagSelectOptionType;
          },
        );

        options = options.concat(
          filteredProductTagOptions,
          shopifyProductOptions,
        );

        const index = filteredProductTagOptions.length;
        if (!additional?.page) {
          options.splice(index + 2, 0, {
            label: "Items",
            value: "Items",
            imgSrc: null,
            isDisabled: true,
          });
        }

        const meta = allShopifyProducts.metaData;

        setFetchedDataLength(fetchedDataLength + shopifyProductOptions.length);

        setAllOptions((op: any) => {
          return [...op, ...options];
        });

        return {
          options: options,
          hasMore: fetchedDataLength < meta.total,
          additional: {
            page: additional ? additional.page + meta.count : meta.count,
          },
        };
      } catch (err) {
        return {
          options: [],
          hasMore: false,
          additional: {
            page: 0,
          },
        };
      }
    },
    [fetchedDataLength, returnAutoWorkFlow.integrationId],
  );

  const items = useMemo(() => {
    return typeof localState.items !== "string"
      ? localState.items.map((item) => ({ ...item }))
      : [];
  }, [localState.items]);

  const tags = useMemo(() => {
    return typeof localState.tags !== "string"
      ? localState.tags.map((tag) => ({
          label: tag,
          value: tag,
          type: "tag",
        }))
      : [];
  }, [localState.tags]);

  const initialSelecteOptions = useMemo(
    () => [
      { label: "Tags", value: "Tags", imgSrc: null, isDisabled: true },
      ...items,
      {
        label: "Items",
        value: "Items",
        imgSrc: null,
        isDisabled: true,
      },
      ...tags,
    ],
    [items, tags],
  );

  // Follow Up Questions
  useEffect(() => {
    if (!returnAutoWorkFlow.integrationId) {
      return;
    }

    if (question === "question11") {
      // If the question is "question11," fetch refund request follow-up questions.
      dispatch(
        fetchRefundRequestFollowUpQuestionsThunk({
          id: refundReasonId.toString(),
          integrationId: returnAutoWorkFlow.integrationId,
        }),
      );
    }
    if (question === "question12") {
      // If the question is "question12," fetch specific follow-up questions (Step 13).
      dispatch(
        getRefundRequestFollowUpQuestionsOrdersThunk({
          id: refundReasonId.toString(),
          integrationId: returnAutoWorkFlow.integrationId,
        }),
      );
    }

    // Update the local state with the 'scenario' information.
    localDispatch({
      type: "UPDATE_LOCAL_STATE",
      payload: {
        scenario: scenario,
      },
    });
  }, [returnAutoWorkFlow.integrationId]);

  const followUpQuestionQ11 = useAppSelector(
    (state) =>
      state.antiRefundStrategies.refundRequests.refundRequestFollowUpQuestions,
  );
  const followUpQuestionQ12 = useAppSelector(
    (state) =>
      state.antiRefundStrategiesOrders.antiRefundOrderTypesData
        .refundRequestFollowUpQuestions,
  );

  const { followUpQuestion, allFollowUpQuestions } = useMemo(() => {
    let followUpQuestion;
    if (question === "question11") {
      followUpQuestion = followUpQuestionQ11;
    }
    if (question === "question12") {
      followUpQuestion = followUpQuestionQ12;
    }
    const allFollowUpQuestions = followUpQuestion?.map((question) => {
      return {
        id: question.elementId,
        typeId: question.elementTypeId,
        label: question.elementValue,
        value: question.elementValue,
        checked: false,
      } as any;
    });

    // For adding select all option
    allFollowUpQuestions?.unshift({
      id: 0,
      label: "Select all questions",
      typeId: 0,
      value: "*",
    });

    return { followUpQuestion, allFollowUpQuestions };
  }, [followUpQuestionQ11, followUpQuestionQ12, question]);

  const orderTypeId = useAppSelector(
    (state) =>
      state.antiRefundStrategiesOrders.antiRefundOrderTypesData
        .selectedOrderTypeId,
  );

  const selectedFollowUpQuestions = useMemo(() => {
    return localState?.followUpQuestions.map((question) => {
      // Check if question.additionalOptions is iterable
      const additionalOptions = Array.isArray(question.additionalOptions)
        ? [{ value: "Select All", id: "0" }, ...question.additionalOptions]
        : [{ value: "Select All", id: "0" }];

      return {
        id: question.elementId,
        typeId: question.elementTypeId,
        label: question.elementValue,
        value: question.elementValue,
        additionalOptions: additionalOptions,
        selectedValue: question.selectedValue,
        // selectedId: question.selectedId,
      };
    });
  }, [localState?.followUpQuestions]);

  //Function execute when "save" button is clicked
  const handleSave = useCallback(() => {
    let shouldDispatch = true;
    selectedFollowUpQuestions &&
      selectedFollowUpQuestions.some((question) => {
        if (
          question.selectedValue === "" ||
          question.selectedValue.length === 0
        ) {
          pushTheToast({
            text: "Please complete all required fields",
            type: "danger",
            position: "top-right",
          });
          setError(true);
          shouldDispatch = false; // Set the flag to false
          return true; // Exit the loop by returning true
        }
        return false;
      });
    // checking if no item or tag is selected,then show error
    if (
      localState.items.length === 0 &&
      localState.tags.length === 0 &&
      localState.itemsAndTagAllSelected === false
    ) {
      setItemError(true);
      shouldDispatch = false; // Set the flag to false
    }

    if (shouldDispatch) {
      if (question === "question11") {
        dispatch(
          updateFollowUpQuestionsConfig({
            reasonId: refundReasonId as number,
            scenarioId: scenario.id,
            localState: localState,
          }),
        );
      }
      if (question === "question12") {
        dispatch(
          updateFollowUpQuestionsConfigOrderTypes({
            orderTypeId: orderTypeId ?? "",
            reasonId: refundReasonId as number,
            scenarioId: scenario.id,
            localState: localState,
          }),
        );
        dispatch(validateChanges());
      }
      onHide();
    }
  }, [
    dispatch,
    localState,
    onHide,
    orderTypeId,
    question,
    refundReasonId,
    scenario.id,
    selectedFollowUpQuestions,
  ]);

  //Function execute when "cancel" button is clicked
  const handleCancel = useCallback(() => {
    localDispatch({
      type: "CLEAR_LOCAL_STATE_FOLLOW_UP_QUESTIONS",
    });
    onHide();
  }, [localDispatch, onHide]);

  const handleFollowUpQuestionChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, selectedOption: any) => {
      if (selectedOption.value === "*" && e.target.checked) {
        localDispatch({
          type: "SELECT_ALL_FOLLOW_UP_QUESTIONS",
        });
      } else if (selectedOption.value === "*" && !e.target.checked) {
        localDispatch({
          type: "DESELECT_ALL_FOLLOW_UP_QUESTIONS",
        });
      } else if (e.target.checked && selectedOption.value !== "*") {
        localDispatch({
          type: "UPDATE_FOLLOW_UP_QUESTIONS",
          payload: {
            elementId: selectedOption.id,
            elementTypeId: selectedOption.typeId,
            reasonId: refundReasonId,
            scenarioId: scenario.id,
          },
        });
      } else if (!e.target.checked && selectedOption.value !== "*") {
        localDispatch({
          type: "REMOVE_FOLLOW_UP_QUESTIONS",
          payload: {
            elementId: selectedOption.id,
            elementTypeId: selectedOption.typeId,
            reasonId: refundReasonId,
            scenarioId: scenario.id,
          },
        });
      }
    },
    [localDispatch, refundReasonId, scenario.id],
  );

  const handleFollowUpQuestionSelectedValueChange = useCallback(
    ({
      selectedOption,
      elementId,
      elementTypeId,
      checked,
      updatedText,
    }: {
      selectedOption?: {
        value: string;
        id: string;
      };
      checked?: boolean;
      elementId: string;
      elementTypeId: string;
      updatedText?: string;
    }) => {
      const selectedValues = localState?.followUpQuestions.find((question) => {
        return (
          question.elementId === elementId &&
          question.elementTypeId === elementTypeId
        );
      })?.selectedValue;

      if (
        elementTypeId + "" === FormElementTypes.TEXT ||
        elementTypeId + "" === FormElementTypes.LONG_TEXT
      ) {
        if (updatedText !== undefined) {
          localDispatch({
            type: "UPDATE_FOLLOW_UP_QUESTIONS_SELECTED_VALUE",
            payload: {
              elementId: elementId,
              elementTypeId: elementTypeId,
              selectedValue: updatedText,
            },
          });
        }

        return;
      }

      if (selectedOption?.value === "Select All" && checked === true) {
        localDispatch({
          type: "UPDATE_FOLLOW_UP_QUESTIONS_SELECTED_VALUE",
          payload: {
            elementId: elementId,
            elementTypeId: elementTypeId,
            selectedValue: "*",
          },
        });
      } else if (selectedOption?.value === "Select All" && checked === false) {
        localDispatch({
          type: "UPDATE_FOLLOW_UP_QUESTIONS_SELECTED_VALUE",
          payload: {
            elementId: elementId,
            elementTypeId: elementTypeId,
            selectedValue: [],
          },
        });
      } else if (
        checked === true &&
        selectedOption?.value !== "Select All" &&
        selectedOption?.id
      ) {
        const updatedSelectedValues =
          Array.isArray(selectedValues) &&
          selectedValues.includes(selectedOption.id)
            ? selectedValues
            : [...(selectedValues || []), selectedOption.id];

        localDispatch({
          type: "UPDATE_FOLLOW_UP_QUESTIONS_SELECTED_VALUE",
          payload: {
            elementId: elementId,
            elementTypeId: elementTypeId,
            selectedValue: updatedSelectedValues,
          },
        });
      } else if (
        checked === false &&
        selectedOption?.value !== "Select All" &&
        selectedOption?.id
      ) {
        const updatedSelectedValues =
          Array.isArray(selectedValues) &&
          selectedValues.includes(selectedOption.id)
            ? selectedValues.filter((value) => value !== selectedOption.id)
            : selectedValues ?? [];

        localDispatch({
          type: "UPDATE_FOLLOW_UP_QUESTIONS_SELECTED_VALUE",
          payload: {
            elementId: elementId,
            elementTypeId: elementTypeId,
            selectedValue: updatedSelectedValues,
          },
        });
      }
    },
    [localDispatch, localState?.followUpQuestions],
  );

  return (
    <div>
      <div
        className={`d-flex align-items-start w-100 ${styles.contentWrapper}`}
      >
        {/* Back Button */}
        <div>
          <span
            className={`pe-3 d-block ${styles.leftArrow}`}
            role="button"
            onClick={onHide}
          >
            <i className="fa-solid fa-angle-left"></i>
          </span>
        </div>

        <div className="w-100 mt-1">
          <h3 className={`d-flex mb-0 align-items-center ${styles.heading}`}>
            <span className="pe-3 d-block">
              Select specific items and orders
            </span>
          </h3>
          <div>
            <p className={`${styles.subHeading}`}>
              Return/exchange reason: {returnReason}
            </p>
          </div>

          {/* Items And Tags Select */}
          <div className=" mb-3">
            <Form.Label className={`mb-0 ${styles.formLabel}`}>
              Choose specific items and tags for anti- refund strategy to work:
            </Form.Label>
            <div className={`d-flex align-items-center`}>
              <div className={`${styles.customizeSelect}`}>
                <ItemsAndTagsSelect
                  id={refundReasonId}
                  scenario={scenario}
                  allOptions={allOptions}
                  intialSelectedOptions={initialSelecteOptions}
                  loadOptions={handleLoadOptions}
                  itemError={itemError} //Added to show error only after clicked on save
                  setItemError={setItemError}
                  items={localState.items}
                  itemsAndTagAllSelected={localState.itemsAndTagAllSelected}
                  tags={localState.tags}
                  onUpdateAllItemsAndTagsSelected={(selected) => {
                    localDispatch({
                      type: "ITEMS_AND_TAGS_ALL_SELECTED",
                      payload: {
                        itemsAndTagAllSelected: selected,
                      },
                    });
                  }}
                  onUpdateItems={(items) => {
                    localDispatch({
                      type: "UPDATE_SCENARIO_ITEMS",
                      payload: { items },
                    });
                  }}
                  onUpdateTags={(tags) => {
                    localDispatch({
                      type: "UPDATE_SCENARIO_TAGS",
                      payload: { tags },
                    });
                  }}
                />
              </div>
            </div>
          </div>

          {/* Follow Up Question Select */}
          <div className=" mb-3">
            <Form.Label className={`mb-0 ${styles.formLabel}`}>
              Choose specific follow-up questions and their answers for anti
              refund strategy to work:
            </Form.Label>
            {/* Question Dropdown */}
            <div className={`d-flex align-items-center`}>
              <Dropdown>
                <Dropdown.Toggle
                  as="div"
                  id="dropdown-basic"
                  bsPrefix={`dropdown-toggle ${styles.dropBtn} ${
                    errorMessage !== undefined && errorMessage.length > 0
                      ? " border border-danger "
                      : " "
                  } `}
                  role="button"
                >
                  <span className={`text-truncate ${styles.toggleName}`}>
                    {selectedFollowUpQuestions?.length + 1 ===
                    allFollowUpQuestions?.length
                      ? "All Follow Up Questions Selected"
                      : selectedFollowUpQuestions?.length === 0
                        ? "All follow-up questions"
                        : `${selectedFollowUpQuestions?.length} follow-up questions selected`}
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu bsPrefix={`dropdown-menu ${styles.dropMenu}`}>
                  {allFollowUpQuestions?.map((option, idx) => {
                    return (
                      <div className={`${styles.formcheckWrap} mb-1`}>
                        <div className={`form-check`}>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={option.value}
                            id={`followUpQuestion-${option.id}`}
                            role="button"
                            onChange={(e) => {
                              setErrorMessage("");
                              setError(false);
                              handleFollowUpQuestionChange(e, option);
                            }}
                            checked={
                              option.value === "*"
                                ? followUpQuestion?.length ===
                                  selectedFollowUpQuestions?.length!
                                  ? true
                                  : false
                                : selectedFollowUpQuestions?.some(
                                    (selectedOption) => {
                                      return selectedOption.id === option.id;
                                    },
                                  )
                            }
                          />
                          <label
                            className={`form-check-label ${styles.label}`}
                            htmlFor={`followUpQuestion-${option.id}`}
                          >
                            {idx === 0
                              ? option.label
                              : `Follow up question #${idx}`}
                            <span className={`${styles.moreDetails}`}>
                              {idx === 0 ? "" : option.value}
                            </span>
                          </label>
                        </div>
                      </div>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            {errorMessage !== undefined && errorMessage.length > 0 && (
              <div className={`${styles.error}`}>{errorMessage} </div>
            )}

            {/* Selected Question Section */}
            <div className="mt-4">
              {selectedFollowUpQuestions?.length! > 0 && (
                <>
                  <p className={`mb-1 ${styles.selectPara}`}>
                    Selected questions
                  </p>
                  {selectedFollowUpQuestions?.map((option, idx) => {
                    return (
                      <div className="mb-3">
                        <div className={`p-2 mb-1 ${styles.selectedBox}`}>
                          <p className={`mb-0 ${styles.followHead}`}>
                            Follow up question #{idx + 1}
                          </p>
                          <span
                            className={`d-inline-block ${styles.followSpan}`}
                          >
                            {option.label}
                          </span>
                        </div>
                        {option.typeId + "" === FormElementTypes.TEXT && (
                          <div>
                            <input
                              type="text"
                              value={option.selectedValue}
                              className={`${styles.inputBox} w-100`}
                              placeholder="Please enter the short answer"
                              onChange={(e) => {
                                setError(false);
                                handleFollowUpQuestionSelectedValueChange({
                                  elementId: option.id,
                                  elementTypeId: option.typeId.toString(),
                                  updatedText: e.target.value,
                                });
                              }}
                              id={`${option.id}_${option.typeId}`}
                            />
                            {error &&
                              option.selectedValue !== undefined &&
                              option.selectedValue.length <= 0 && (
                                <div className={`${styles.error}`}>
                                  {" *Please fill out this field"}{" "}
                                </div>
                              )}
                          </div>
                        )}

                        {option.typeId + "" === FormElementTypes.LONG_TEXT && (
                          <div>
                            <textarea
                              name={option.label}
                              className={`${styles.textareaBox} w-100`}
                              value={option.selectedValue}
                              placeholder="Please enter the long answer"
                              onChange={(e) => {
                                setError(false);
                                handleFollowUpQuestionSelectedValueChange({
                                  elementId: option.id,
                                  elementTypeId: option.typeId.toString(),
                                  updatedText: e.target.value,
                                });
                              }}
                              id={`${option.id}_${option.typeId}`}
                            ></textarea>
                            {error &&
                              option.selectedValue !== undefined &&
                              option.selectedValue.length <= 0 && (
                                <div className={`${styles.error}`}>
                                  {" *Please fill out this field"}{" "}
                                </div>
                              )}
                          </div>
                        )}

                        {(option.typeId + "" === FormElementTypes.DROPDOWN ||
                          option.typeId + "" ===
                            FormElementTypes.MULTIPLE_CHOICE) && (
                          <Dropdown bsPrefix="W-100">
                            <Dropdown.Toggle
                              as="div"
                              id="dropdown-basic"
                              bsPrefix={`dropdown-toggle ${styles.dropBtn}`}
                              role="button"
                            >
                              <span
                                className={`text-truncate ${styles.selName}`}
                              >
                                {option.selectedValue &&
                                option.additionalOptions
                                  ? option.selectedValue.length === 0
                                    ? "Select Answers"
                                    : option.selectedValue.length + 1 ===
                                        option.additionalOptions.length
                                      ? "All Answers Selected"
                                      : `${option.selectedValue.length} Answers Selected`
                                  : "Select Answers"}
                              </span>
                            </Dropdown.Toggle>
                            {error &&
                              option.selectedValue !== undefined &&
                              option.selectedValue.length <= 0 && (
                                <div className={`${styles.error}`}>
                                  {" *Please fill out this field"}{" "}
                                </div>
                              )}

                            <Dropdown.Menu
                              bsPrefix={`dropdown-menu ${styles.dropMenu2}`}
                            >
                              {option.additionalOptions.map((adOption, idx) => {
                                return (
                                  <div
                                    className={`${styles.formcheckWraper} mb-1`}
                                  >
                                    <div className={`form-check`}>
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value={adOption.value}
                                        onChange={(e) => {
                                          setError(false);
                                          handleFollowUpQuestionSelectedValueChange(
                                            {
                                              checked: e.target.checked,
                                              selectedOption: adOption,
                                              elementId: option.id,
                                              elementTypeId:
                                                option.typeId.toString(),
                                            },
                                          );
                                        }}
                                        checked={
                                          (option.additionalOptions &&
                                            option.selectedValue &&
                                            adOption.value &&
                                            (adOption.value === "Select All"
                                              ? option.additionalOptions
                                                  .length -
                                                  1 ===
                                                (option.selectedValue || [])
                                                  .length
                                              : Array.isArray(
                                                    option.selectedValue,
                                                  ) &&
                                                  Array.isArray(
                                                    option.selectedValue,
                                                  )
                                                ? option.selectedValue.some(
                                                    (selectedOption) =>
                                                      selectedOption ===
                                                      adOption.id,
                                                  )
                                                : option.selectedValue ===
                                                  adOption.id)) as
                                            | boolean
                                            | undefined
                                        }
                                        id={`followUpQuestionAdOption-${adOption.id}`}
                                      />
                                      <label
                                        className={`form-check-label ${styles.label}`}
                                        htmlFor={`followUpQuestionAdOption-${adOption.id}`}
                                      >
                                        {adOption.value}
                                      </label>
                                    </div>
                                  </div>
                                );
                              })}
                            </Dropdown.Menu>
                          </Dropdown>
                        )}
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Buttons */}
      <div className={`d-flex justify-content-end ${styles.btnWrapper}`}>
        <button
          className={`${styles.cancelBtn}`}
          onClick={handleCancel}
        >
          Cancel
        </button>
        <button
          className={`ms-3 ${styles.saveBtn}`}
          onClick={handleSave}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default SpecificElementModal;
