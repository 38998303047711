import { CloseButton } from "react-bootstrap";

import OrderDetails from "../../../OrderDetails/OrderDetails";
import styles from "./OrderDetailsModal.module.scss";

function OrderDetailsModal(props: {
  isOpen?: boolean;

  orderId: string | number;
}) {
  return (
    <>
      <OrderDetails orderId={props.orderId} />
    </>
  );
}

export default OrderDetailsModal;
