import { useCallback, useMemo, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import styles from "./TextArea.module.scss";

function TextArea({
  value,
  onChange,
  showErrors,
  placeholder = "Write your message...",
}: {
  value: string;
  onChange: (val: string) => void;
  showErrors: boolean;
  placeholder?: string;
}) {
  const isError = useMemo(() => {
    return !value.trim() && showErrors;
  }, [showErrors, value]);

  /**
   * Handles changes in the text input field.
   *
   * @param e - The change event from the input field.
   */
  const handleChange: React.ChangeEventHandler<HTMLTextAreaElement> =
    useCallback(
      (e) => {
        onChange(e.target.value);
      },
      [onChange],
    );

  return (
    <div>
      <Form.Group
        className={`mb-3 mt-2 ${styles.textareaWrapper}`}
        controlId="exampleForm.ControlTextarea1"
      >
        <Form.Control
          as="textarea"
          style={{
            resize: "none",
            font: "normal normal normal 12px/19px Poppins",
          }}
          rows={3}
          className={`${isError ? "border-danger shadow-none" : ""}`}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
        />
        {isError && (
          <span className={`${styles.errorText}`}>Please fill this field</span>
        )}
      </Form.Group>
    </div>
  );
}

export default TextArea;
