import axios from "axios";
import { axiosJSON } from "src/globals/axiosEndPoints";
import APIResponse from "./APIReponse";

interface DeleteRoutingRule {
  ruleId : number|string;
}

export interface DeleteRoutingRuleParams {
  ruleId : number|string;
}



export async function deleteRoutingRule (params : DeleteRoutingRuleParams) {

    const { data:res } = await axiosJSON.delete<APIResponse<DeleteRoutingRule>>(
        "/api/chatSetting/routingRule/delete",{
        params
      }
    );

    if(res.error === true )
    {
      throw new Error(res.message as string)
    }

    return res.data as DeleteRoutingRule 
  } 
