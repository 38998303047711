import { Dropdown, Modal } from "react-bootstrap";
import styles from "./TableData.module.scss";
import { useRef, useState } from "react";
import UserAvatar from "src/components/UserAvatar";
import SuggestionModal from "../SuggestionModal/SuggestionModal";
const TableData = () => {
  const [ddOpen, setDdOpen] = useState(false);
  const dropdownArrowSpanRef = useRef<any>();
  const [resModal, setResModal] = useState(false);
  const onShow = () => {
    setResModal(true);
  };
  const onHide = () => {
    setResModal(false);
  };
  return (
    <div>
      <div
        className={`mt-3 d-flex align-items-center justify-content-between px-3 py-2 ${styles.tableData}`}
      >
        <div className={`${styles.article}`}>
          <span className={`${styles.tablePara}`}>
            <Dropdown onToggle={setDdOpen}>
              <Dropdown.Toggle
                id="dropdown-basic"
                as="div"
                bsPrefix={`dropdown-toggle  px-3 ${styles.dropBtn} ${styles.rejectBtn} ${styles.pendingBtn} `}
              >
                <span> Published </span>
                <span className="ps-1">
                  {ddOpen ? (
                    <>
                      {" "}
                      <i className="fa-solid fa-caret-up"></i>
                    </>
                  ) : (
                    <>
                      {" "}
                      <i className="fa-solid fa-caret-down"></i>
                    </>
                  )}
                </span>
              </Dropdown.Toggle>

              <Dropdown.Menu bsPrefix={`dropdown-menu ${styles.dropMenu}`}>
                <Dropdown.Item
                  href="#/action-1"
                  as="div"
                  bsPrefix={`dropdown-item ${styles.dropItem}`}
                >
                  Published
                </Dropdown.Item>
                <Dropdown.Item
                  href="#/action-1"
                  as="div"
                  bsPrefix={`dropdown-item ${styles.dropItem}`}
                >
                  Reject
                </Dropdown.Item>
                <Dropdown.Item
                  href="#/action-2"
                  as="div"
                  bsPrefix={`dropdown-item ${styles.dropItem}`}
                >
                  Pending Approval
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </span>
        </div>
        <div className={` px-2 ${styles.article}`}>
          <div
            className={`p-2 ${styles.publishBox} ${styles.rejectBox} ${styles.pendingBox} `}
            onClick={onShow}
          >
            <span className="d-block">
              {" "}
              <UserAvatar name="NA" size={20} />{" "}
            </span>
            <span className={`${styles.resText}`}>
              Hi Alex, Thanks for understanding. We will be back with an update
              very soon. Feel free to reach out to me lkhdhkkj ...
            </span>
            <div className={`pt-1 ${styles.dateTime}`}>
              <span className="me-1">
                <i className="fas fa-clock"></i>
              </span>
              <span>09/22/2023, 06:51 PM </span>
            </div>
          </div>
          <Modal
            backdropClassName={`${styles.modalBack}`}
            show={resModal}
            onHide={onHide}
            dialogClassName={`${styles.modalDialog}`}
            contentClassName={`${styles.modalContent}`}
            centered={false}
            enforceFocus={false}
          >
            <SuggestionModal onHide={onHide} />
          </Modal>
        </div>

        <div className={`${styles.questionWrap}`}>
          <span className={`px-2 ${styles.questionText}`}>
            The division which is classified on the basis which is classified on
            the basis of physical features is known as physical division...
          </span>
        </div>
        <div className={`px-3 ${styles.article}`}>
          <span className={`${styles.id}`}>10938</span>
        </div>
        <div className={`${styles.article}`}>
          <span className={`${styles.suggestDate}`}>
            <div>
              <span className="me-1">
                <i className="fas fa-clock"></i>
              </span>
              <span>09/22/2023, 06:51 PM </span>
            </div>
          </span>
        </div>
        <div className={`${styles.article}`}>
          <div>
            <UserAvatar name="NA" size={20} />
            <span className={`ps-1 ${styles.name}`}>Sam</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableData;
