import { axiosJSON } from "src/globals/axiosEndPoints";
import { IFulfillmentStatus } from "./createFulfillment";

export interface FulfillmentItem {
  itemId: string;
  variantId: string;
  itemName: string;
  quantity: number;
  sku: string;
  unitPrice: {
    amount: number;
    currencyCode: string;
  };
  imageUrl: string;
  fulfillmentStatus: string;
  locationId: string;
  locationName: string;
  // weight: number;
  // weightUnit: string;
}

export interface GetFulFillmentOrder {
  orderId: number;
  orderName: string;
  customer: {
    customerId: number;
    customerName: string;
    customerEmail: string;
    customerPhone: string;
  };
  fulfillmentStatus: IFulfillmentStatus;
  orderItems: {
    items: [FulfillmentItem];
    status: {
      fulfillmentStatus: IFulfillmentStatus;
      name: string;
      fulfillmentId: string | null;
      trackingInfo: null;
      supportedActions: null;
      fulfillmentOrderId: string;
      locationId: string;
      locationName: string;
      itemCount: number;
    };
  };
  // fulfillmentDetails: {
  //   orderName: string;
  //   customerName: string;
  //   fulfillmentStatus: string;
  //   fulfillFrom: string;
  // };
  // fulfillmentItems: [FulfillmentItem];
  shipping: {
    address: {
      id: string;
      name: string;
      firstName: string;
      lastName: string;
      address1: string;
      address2: null;
      city: string;
      phone: string;
      company: null;
      province: string;
      provinceCode: null;
      country: string;
      countryCodeV2: string;
      zip: string;
    };
    shippingTitle: null;
    shippingAmount: {
      amount: number;
      currencyCode: string;
    };
  };
  totalCount: number;
  count: number;
}

export interface GetFulFillmentOrderParams {
  orderId: number;
  fulfillmentOrderId: string;
  start: number;
  limit: number;
}

export async function getFulFillmentOrder(params: GetFulFillmentOrderParams) {
  const { data: res }: any = await axiosJSON.post(
    `/api/shopify/order/fulfillment/get`,
    params
  );

  if (res.error === true) {
    throw new Error(res.message as string);
  }

  return res.data as GetFulFillmentOrder;
}
