import styles from "./Reasons.module.scss";
import refresh from "src/assets/images/chat/refreshButton.png";
import dummy1 from "src/assets/images/order1.png";
import dummy2 from "src/assets/images/order2.png";
import ReasonCard from "./Children/ReasonCard/ReasonCard";
import { useAppSelector } from "src/store/store";
import { ReasonTypes } from "src/store/slices/initiateReturn/step12Config.slice";
const Reasons = () => {
  const selectedReasonType = useAppSelector((state) => {
    // if (state.step12Config.selectedReasonTypeId === "1") {
    //   return state.step12Config.allOrderAndItemTab;
    // }
    return state.step12Config.reasonTypes.find(
      (res) => res.reasonTypeId === state.step12Config.selectedReasonTypeId,
    );
  });

  return (
    <>
      <div>
        <div className={`d-flex align-items-center`}>
          <div>
            <img
              src={refresh}
              alt="refresh"
              className={`${styles.refresh}`}
            />{" "}
          </div>
          <span className={`ms-1 ${styles.heading}`}>
            Initiate Return/Exchange{" "}
          </span>
        </div>
        <div>
          <span className={`d-block py-2 ${styles.selItem}`}>
            Please tell us why do you want to return/exchange these items :
          </span>
        </div>

        <div>
          <ReasonCard
            orderTitle={"Acme T-shirt"}
            orderImg={dummy1}
            orderData1={"$ 789 x 1"}
            orderData2={"SKU : 0101"}
            addComment={"*Exchange with same item"}
            reasons={[
              "Fitting Issues",
              "Quality Issues",
              "Did not like the item",
              "Received different item",
            ]}
          />
          <ReasonCard
            orderTitle={"Acme T-shirt"}
            orderImg={dummy1}
            orderData1={"$ 789 x 1"}
            orderData2={"SKU : 0101"}
            addComment={"*Exchange with other item"}
          />
          <ReasonCard
            orderTitle={"Acme T-shirt"}
            orderImg={dummy2}
            orderData1={"$ 789 x 1"}
            orderData2={"SKU : 0101"}
            addComment={"*Refund to original payment"}
          />
        </div>
      </div>
    </>
  );
};
export default Reasons;
