/**
 * This file is the service file used for making api request.
 * It contains the updateRatingsAndComments function service which is explained below.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants"; // this is used for testing purpose only for frontend.

export interface RatingsAndCommentsPayload {
  integrationId: number | string;
  ratingShowHelpCount?: boolean;
  userAllowedToRate?: boolean;
  feedbackRatingType?: "emojis" | "thumbsUpAndDown";
  commentsEnabled?: boolean;
  commentApprovalRequired?: boolean;
  commentsType?: "approved" | "unApproved";
  ratingApprovalRequired?: boolean; //approval required for rating flag
  ratingApprovalType?: "approved" | "unApproved"; //default approval value for rating submittion
}

/**
 * For testing frontend with own api link if backend is not prepared.
 */
const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/updateRatingsAndComments`
  : "/api/chatSetting/updateRatingsAndComments";

/**
 * This service is created to update the rating and comments meta data in the knowledge base settings.
 */
export const updateRatingsAndComments = async (
  params: RatingsAndCommentsPayload
) => {
  const { data: res } = await axiosJSON.post(apiEndPoint, params);
  return res.data;
};
