import { useQuery } from "@tanstack/react-query";
import {
  IGetLinkableShopifyStores,
  getLinkableShopifyStores,
} from "src/services/LiveChat/Settings/getLinkableShopifyStores";

/**
 * Custom hook to fetch linkable Shopify stores.
 */
export const useFetchLinkableShopifyStores = ({
  integrationId,
  enabled,
}: {
  integrationId?: string | number;
  enabled: boolean;
}) => {
  /**
   * Function to fetch data.
   * @param integrationId
   */
  const fetchData = async (integrationId: string | number) => {
    try {
      const data: IGetLinkableShopifyStores = await getLinkableShopifyStores({
        integrationId: parseInt(integrationId + ""),
      });
      return Object.values(data.stores);
    } catch (error) {
      throw new Error((error as Error).message);
    }
  };

  //react-Query to fetch data.
  const {
    isLoading,
    data: allStores,
    error,
  } = useQuery(
    ["linkableShopifyStores", integrationId],
    () => fetchData(integrationId + ""),
    {
      enabled: enabled, //flag to only fetch data if enabled
    },
  );

  return { loading: isLoading, allStores, error };
};
