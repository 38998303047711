/**
 * This file is the service file used for making api request.
 * It contains the replaceOrRevertOpenGraphImage function service which is explained below.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants"; // this is used for testing purpose only for frontend.

export interface ReplaceOrRevertOpenGraphImagePayload {
  integrationId: number | string;
  type: "replace" | "revert";
  replaceImg?: File;
}

export interface ReplaceOrRevertOpenGraphImageResponse {
  success: boolean;
  data: {
    openGraphImage: string | null;
  };
}

/**
 * For testing frontend with own api link if backend is not prepared.
 */
const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/updateThemeStyles`
  : "/api/chatSetting/replaceOrRevertOpenGraphImage";

/**
 * This is the service used to reverting the images in the open graph theme settings.
 */
export const replaceOrRevertOpenGraphImage = async (
  params: ReplaceOrRevertOpenGraphImagePayload
) => {
  const { data: res } = await axiosJSON.post(apiEndPoint, params, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return res;
};
