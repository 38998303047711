import { axiosJSON } from "src/globals/axiosEndPoints";
import { channelSubscriber, channelUnsubscriber } from "src/globals/pusher";
import {
  EmailForwardingDetails,
  EmailForwardingErrors,
} from "src/features/ReturnAutoWorkFlow/ReturnAutoWorkFlow.types";

/**
 * Email forwarding details to update and the details needed for verification.
 */
interface EmailForwardingDetailsWithVerification
  extends EmailForwardingDetails {
  /**
   * Used for showing error modal.
   */
  verificationError?: null | EmailForwardingErrors;
  /**
   * Pusher channel used to verify email.
   */
  verificationChannel?: null | string;
}

/**
 * Parameters required to create or verify email forwarding.
 *
 * @property {string} integrationId - The ID of the integration.
 * @property {string} email - The email address to be forwarded.
 */
interface CreateOrVerifyEmailForwardingParams {
  integrationId: string;
  email: string;
}

/**
 * Creates or verifies email forwarding for a given email address.
 *
 * @param params - The parameters needed to create or verify the email forwarding.
 * @returns A promise that resolves to the email forwarding details.
 * @throws Will throw an error if the response contains an error or if some other error occurs.
 */
async function createOrVerifyEmailForwarding(
  params: CreateOrVerifyEmailForwardingParams,
) {
  const { data: res } = await axiosJSON.post(
    "/api/setting/automation/refund/emailForwarding/createOrVerifyEmail",
    params,
  );

  if (res.err || res.error) {
    throw new Error(res.msg ?? res.message ?? "Some Error Occurred.");
  }

  const ret = res.data as Partial<EmailForwardingDetailsWithVerification>;

  return ret;
}

/**
 * Subscribes to a Pusher channel to listen for email verification events.
 *
 * @param options - Options for the callback.
 * @param options.channelName - The name of the Pusher channel to subscribe to.
 * @param options.params - The parameters for creating or verifying email forwarding.
 * @returns A promise that resolves to the email forwarding details once the verification event is received.
 */
const fetchVerifyEmailCallback = ({
  channelName,
  params,
}: {
  channelName?: null | string;
  params: CreateOrVerifyEmailForwardingParams;
}) => {
  return new Promise(
    (
      resolve: (value: Partial<EmailForwardingDetailsWithVerification>) => void,
      reject,
    ) => {
      if (channelName?.trim()) {
        let timeout: any = null;
        const channel = channelSubscriber(channelName);
        if (channel) {
          channel.bind(
            "return_email_forwarding_verification",
            ({ email, integrationId }: CreateOrVerifyEmailForwardingParams) => {
              if (
                params.email === email &&
                params.integrationId === integrationId
              ) {
                clearTimeout(timeout);
                channelUnsubscriber(channelName);
                resolve({ activeEmailVerified: true, verificationError: null });
              }
            },
          );
        }
        timeout = setTimeout(() => {
          channelUnsubscriber(channelName);
          createOrVerifyEmailForwarding(params)
            .then((value) => {
              resolve({
                ...value,
                activeEmailVerified: value.activeEmailVerified ? true : false,
              });
            })
            .catch((err) => {
              reject(err);
            });
        }, 30000);
      } else {
        setTimeout(() => {
          createOrVerifyEmailForwarding(params)
            .then((value) => {
              resolve({
                ...value,
                activeEmailVerified: value.activeEmailVerified ? true : false,
              });
            })
            .catch((err) => {
              reject(err);
            });
        }, 30000);
      }
    },
  );
};

/**
 * Creates or verifies email forwarding and listens for the verification event.
 *
 * @param params - The parameters needed to create or verify the email forwarding.
 * @returns A promise that resolves to the email forwarding details once the verification process is complete.
 */
async function createOrVerifyEmailForwardingService(
  params: CreateOrVerifyEmailForwardingParams,
): Promise<Partial<EmailForwardingDetailsWithVerification>> {
  try {
    const res = await createOrVerifyEmailForwarding(params);
    return await fetchVerifyEmailCallback({
      channelName: res.verificationChannel,
      params,
    });
  } catch (error) {
    console.error(error);
    return {
      activeEmailVerified: false,
      verificationError: {
        heading: "Error verifying connection",
        subHeading: "Please check your network or try again after some time.",
      },
    };
  }
}

export default createOrVerifyEmailForwardingService;
