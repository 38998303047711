import { axiosFormData } from "src/globals/axiosEndPoints";
import { selectFile } from "src/utils/utils";

export interface UpdateProfileImageUserParams {
  userId: number;
}

export interface UpdateProfileImageUser {
  userId: number;
  profileImageId: number;
  profileImageUrl: string;
}

export async function updateProfileImageUser(
  params: UpdateProfileImageUserParams
) {
  const fData = new FormData();
  fData.append("userId", params.userId + "");
  fData.append("profileImage", (await selectFile(["jpeg", "jpg", "png"]))[0]);
  const { data: res } = await axiosFormData.post(
    "/api/setting/user/updateProfileImage",
    fData
  );

  if (res.error === true) {
    throw new Error(res.message as string);
  }

  return res.data as UpdateProfileImageUser;
}
