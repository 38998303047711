import { axiosJSON } from "src/globals/axiosEndPoints";
import { ConditionType, StepConfigParams } from "../Step9/step9GetConfig";

interface MoreQuestion {
  elementTypeId: string;
  additionalOptions: {
    id: string;
    value: string;
  }[];
  elementValue: string;
  elementId: string;
}

export interface ReasonTypes {
  id: string;
  value: string;
  moreQuestions: MoreQuestion[];
}
export interface Step12TabType {
  configTabId: number;
  configTabName: string;
  conditions: ConditionType[];
  reasons: ReasonTypes[];
}

export interface Step12Config {
  data: Step12TabType[];
}

export async function fetchGetStep12Config(params: StepConfigParams) {
  const { data } = await axiosJSON.get(
    "/api/setting/automation/refund/step/12/configuration",
    { params },
  );

  if (data.err === true) {
    throw new Error("Some Error Occurred while fetching data");
  }

  return data.data;
}
