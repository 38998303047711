import { useInfiniteQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useState } from "react";
import { AJAXSTATUS } from "src/globals/constants";
import getCustomerAllMessagesV3, {
  IGetLiveChatHistoryParams,
  IGetLiveChatHistoryResponse,
} from "src/services/TicketService/InternalChatBot/getLiveChatHistoryMessageById.service";

const limit = 30;
const sortBy = "desc";

const useLiveChatHistory = (customerId: string) => {
  const [historyData, setHistoryData] =
    useState<IGetLiveChatHistoryResponse | null>(null);
  const INITIAL_PAYLOAD: IGetLiveChatHistoryParams = useMemo(
    () => ({
      customerId: customerId,
      limit,
      filters: { sortBy },
      startFromId: null,
      startFromIdType: null,
    }),
    [customerId],
  );

  const historyMessages = useMemo(
    () => historyData?.messages ?? {},
    [historyData?.messages],
  );

  const historyMessageIds = useMemo(
    () =>
      sortBy === "desc" && historyData
        ? [...historyData.messageIds].reverse()
        : historyData?.messageIds ?? [],
    [historyData],
  );

  const { isError, data, hasNextPage, fetchNextPage, isLoading, isSuccess } =
    useInfiniteQuery({
      queryKey: ["getCustomerAllMessagesV3", INITIAL_PAYLOAD],
      queryFn: ({ pageParam = INITIAL_PAYLOAD }) =>
        getCustomerAllMessagesV3(pageParam),
      getNextPageParam: (
        prevPage: IGetLiveChatHistoryResponse,
        _allPages: IGetLiveChatHistoryResponse[],
      ) => {
        // Return next page params if we have more pages
        if (prevPage.metaData.limitReached) {
          return null;
        }

        const lastMessageId =
          prevPage.messageIds[prevPage.messageIds.length - 1];
        const lastMessageIdParts = lastMessageId?.split("::");
        const startFromId = lastMessageIdParts ? lastMessageIdParts[1] : null;
        const startFromIdType = lastMessageIdParts
          ? lastMessageIdParts[0]
          : null;

        return {
          customerId: INITIAL_PAYLOAD.customerId,
          limit: INITIAL_PAYLOAD.limit,
          filters: INITIAL_PAYLOAD.filters,
          startFromId,
          startFromIdType,
        } as IGetLiveChatHistoryParams;
      },
    });

  useEffect(() => {
    if (data) {
      setHistoryData(() => {
        let historyMessagesData: IGetLiveChatHistoryResponse["messages"] = {};
        data.pages.forEach((page) => {
          historyMessagesData = { ...historyMessagesData, ...page.messages };
        });

        return {
          messageIds: Array.from(
            new Set([...(data.pages.flatMap((page) => page.messageIds) ?? [])]),
          ),
          messages: historyMessagesData,
          metaData: data.pages[data?.pages.length - 1].metaData,
        };
      });
    }
  }, [data]);

  const { customerLiveChatId, limitReached, isCustomerOnline } = useMemo(() => {
    return {
      customerLiveChatId: historyData?.metaData.liveChatId,
      limitReached: historyData?.metaData.limitReached ?? false,
      isCustomerOnline: historyData?.metaData.isCustomerOnline ?? false,
    };
  }, [
    historyData?.metaData.isCustomerOnline,
    historyData?.metaData.limitReached,
    historyData?.metaData.liveChatId,
  ]);

  const fetchStatus: AJAXSTATUS = useMemo(() => {
    return isLoading
      ? "pending"
      : isError
        ? "rejected"
        : isSuccess
          ? "fulfilled"
          : "idle";
  }, [isError, isLoading, isSuccess]);

  const fetchLastMessages = useCallback(
    async (callback?: () => void) => {
      const payload: IGetLiveChatHistoryParams = {
        ...INITIAL_PAYLOAD,
        limit: 5,
      };

      try {
        const response = await getCustomerAllMessagesV3(payload);

        if (response) {
          setHistoryData((prevData) => {
            let updatedMessages = {
              ...prevData?.messages,
              ...response.messages,
            };
            let updatedMessageIds = Array.from(
              new Set([
                ...response.messageIds,
                ...(prevData?.messageIds ?? []),
              ]),
            );

            return {
              ...prevData,
              messages: updatedMessages,
              messageIds: updatedMessageIds,
              metaData: response.metaData,
            };
          });

          if (callback) callback();
        }
      } catch (error) {
        console.error("Error fetching last messages:", error);
      }
    },
    [INITIAL_PAYLOAD],
  );

  return {
    historyMessages,
    historyMessageIds,
    customerLiveChatId,
    isCustomerOnline,
    fetchNextPage,
    hasNextPage,
    limitReached,
    fetchStatus,
    fetchLastMessages,
  };
};

export default useLiveChatHistory;
