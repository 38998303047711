import { useState } from "react";
import { useParams } from "react-router-dom";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import {
  addCannedResponse,
  AddCannedResponseParams,
} from "src/services/LiveChat/Settings/cannedResponses/addCannedResponse";
import {
  fetchAllCannedResponse,
  resetCannedResponse,
} from "src/store/slices/liveChatSetting/cannedResponse/cannedResponse.slice";
import { useAppDispatch } from "src/store/store";
import CannedSendMessage from "../CannedSendMessage";
import styles from "./CannedResponseForm.module.scss";

const CannedResponseForm = () => {
  const [shortcutText, setShortcutText] = useState<string>("");
  const [title, setTitle] = useState<string>("");

  const { integrationId } = useParams();
  const pageIntegrationId = integrationId
    ? decodeURIComponent(integrationId)
    : "";

  const [message, setMessage]: any = useState("");
  const dispatch = useAppDispatch();

  /* Function to handle cannedResponse shorcutText change */
  const hanldeShortcutTextChange = (e: any) => {
    const sanitizedValue = e.target.value.replace(/\s/g, ''); // Remove spaces
    setShortcutText(sanitizedValue);
    setvalidationBorder(false);
  };

  // Function to handle cannedResponse Title Change
  const handleTitleChange = (e: any) => {
    setTitle(e.target.value);
    setvalidationBorder(false);
  }

  const [validationBorder, setvalidationBorder] = useState<boolean>(false);
  const [responseAdding, setResponseAdding] = useState<boolean>(false);

  /* Function to handle add cannedResponse submit*/
  const handleAddCannedResponse = (e: any) => {
    setResponseAdding(true);
    e.preventDefault();
    const addCannedResponseParams: AddCannedResponseParams = {
      shortcut: shortcutText,
      messageText: message,
      integrationId: pageIntegrationId,
      title: title
    };
    if (shortcutText !== "" && message !== "" && title !== "") {
      setvalidationBorder(false);

      addCannedResponse(addCannedResponseParams)
        .then((res) => {
          pushTheToast({
            type: "success",
            text: "Responses Added Successfully!",
            position: "top-right",
          });
          setShortcutText("");
          setMessage("");
          setTitle("");
          dispatch(resetCannedResponse());
          dispatch(fetchAllCannedResponse(pageIntegrationId));
          setResponseAdding(false);
        })
        .catch((e) => {
          setResponseAdding(false);
          pushTheToast({
            type: "danger",
            text: e?.message ?? "something went wrong",
            position: "top-right",
          });
        });
    } else {
      setResponseAdding(false);
      // pushTheToast({
      //   type: "warning",
      //   text: "both shortcut and message fields is required!",
      //   position: "top-right",
      // });

      // Popover Conditioning for Message Validation
      if (message === "") {
        setvalidationBorder(true);
      }
      else {
        setvalidationBorder(false);
      }
    }
  };

  const handleMessageChangeForValidation = () => {
    if (message.length <= 0) {
      setvalidationBorder(false);
    }
  }

  if (validationBorder) {
    const element = document.getElementById('cannedMessage');
    element?.classList.add("border-danger");
  }
  else {
    const element = document.getElementById('cannedMessage');
    element?.classList.remove("border-danger");
  }

  return (
    <form onSubmit={handleAddCannedResponse}>
      <input
        data-lpignore="true"
        className={`mb-3 ${styles.inputText}`}
        type={"text"}
        placeholder="Response title*"
        value={title}
        onChange={handleTitleChange}
        id="validationDefault01"
        required
      />
      <input
        data-lpignore="true"
        className={`mb-3 ${styles.inputText}`}
        type={"text"}
        placeholder="Create shortcut"
        onChange={hanldeShortcutTextChange}
        value={shortcutText}
        id="validationDefault02"
        required
      />

      <CannedSendMessage message={message} setMessage={setMessage} handleChange={handleMessageChangeForValidation} />

      <button
        className={`mt-1 ${styles.addResponse} ${responseAdding ? styles.disabled : ""}`}
        disabled={responseAdding}
        type="submit"
      >
        {responseAdding ? `Adding Response ...` : `Add Response`}
      </button>
    </form>
  );
};
export default CannedResponseForm;
