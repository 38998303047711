import { GetAllArticleCategoriesParams, getAllArticleCategory } from "src/services/LiveChat/Settings/knowledgeBased/articleCategory/getAllArticleCategory";
import { RootState } from "src/store/store";

// thunk that fetches all articleCategories
const fetchAllArticleCategoryThunk = async(
  payload:number|string,
  {getState}: {getState: Function}
) =>{
  try{
    const currentState: RootState = getState();
    const queryData: GetAllArticleCategoriesParams = {
      integrationId: payload,
      start: currentState.articleCategory.categoryIdList.length,
      limit: currentState.articleCategory.fetchArticleCategoryLimit,
      searchTerm: currentState.articleCategory.filters.searchValue,
    
    }

    // check search term is empty or not
    if((queryData.searchTerm?.length === 0)){
      delete queryData.searchTerm;
    }

    const articleCategoryData: getAllArticleCategory = await getAllArticleCategory(queryData);
    console.log("---------------articleCategorySettingThunk ------------ ");
    console.log("categories data ::: "+ JSON.stringify(articleCategoryData));
    return {
      categoryData: articleCategoryData.categories,
      categoryIdList: articleCategoryData.categoryIds,
      count: articleCategoryData.metaData.count,
      totalCount: articleCategoryData.metaData.total,
    }
  }catch(error){
    throw error;
  }
};


export default { fetchAllArticleCategoryThunk };