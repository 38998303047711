import { useParams } from "react-router-dom";

import backBtn from "src/assets/images/backArrow.png";
import Loader from "src/components/Loader";
import EmailForwarding from "../../../../EmailForwarding/EmailForwarding";
import EmailProcess from "../EmailProcess/EmailProcess";
import IncomingMailSetting from "./Children/IncomingMailSetting/IncomingMailSetting";

import styles from "./VerfiyEmailProcess.module.scss";
import EmailAddress from "../DomainIntegration/children/EmailAddress/EmailAddress";
import useVerifyEmailProcess from "./useVerifyEmailProcess";
import DomainVerification from "../../../../DomainVerification/DomainVerification";

function VerfiyEmailProcess() {
  const { integrationId } = useParams();

  const { loader, navigate, selectedType, selectedUser, setSelectedType } =
    useVerifyEmailProcess(integrationId);

  return (
    <>
      {/* Loader */}
      {loader === "pending" ? (
        <div className={`d-flex flex-column m-auto`}>
          <Loader />
        </div>
      ) : (
        <div className={`d-flex ${styles.contentWidth}`}>
          <div
            className={`${styles.backWidth} mt-3 ps-1 `}
            role="button"
            onClick={() => {
              navigate("/settings/integrations/email");
            }}
          >
            <img
              src={backBtn}
              className={`${styles.backBtn}`}
              alt="back"
            />
          </div>
          <div className={`${styles.connectWidth}`}>
            <EmailProcess
              step={2}
              delete={true}
            />
            <IncomingMailSetting
              selectedType={selectedType}
              preferred={
                selectedUser.incomingMailSettings?.preferredReceiveType ??
                "currentHost"
              }
              onSelect={setSelectedType}
            />
            {selectedType === "currentHost" ? (
              <EmailForwarding />
            ) : selectedType === "IMAP" ? (
              <EmailAddress
                type="incoming"
                isStepDone={selectedUser.isEmailVerified}
              />
            ) : selectedType === "gmail" ? (
              <DomainVerification
                isGmail={true}
                isIncommingSetting={true}
              />
            ) : (
              <></>
            )}
            <div
              className={`ms-2 mt-3 d-flex justify-content-between align-items-center ${styles.pageWrap}`}
            >
              <button
                className={`px-md-3 ${styles.prevBtn}`}
                onClick={() =>
                  navigate(
                    `/settings/integrations/email/${integrationId}/connectEmail`,
                  )
                }
              >
                <span className="pe-1">
                  <i className="fa-solid fa-arrow-left"></i>
                </span>
                Previous
              </button>
              <button
                className={`px-md-3 ${styles.nextBtn}`}
                disabled={!selectedUser.isEmailVerified}
                onClick={() =>
                  navigate(
                    `/settings/integrations/email/${integrationId}/verifyDomain`,
                  )
                }
              >
                Next{" "}
                <span className="ps-1">
                  <i className="fa-solid fa-arrow-right"></i>
                </span>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default VerfiyEmailProcess;
