import { axiosJSON } from "src/globals/axiosEndPoints";
import APIResponse from "./APIReponse";

interface AddMemberToTeam {
    userId : number;
}

export interface AddMemberToTeamParams {
    teamId : number;
    userId: number;
}



export async function addMemberToTeam (params : AddMemberToTeamParams) {
  

    const { data : res }: any = await axiosJSON.post<APIResponse<AddMemberToTeam>>(
      "/api/setting/team/addMemberToTeam",
        params
    );

    if(res.error === true )
    {
      throw new Error(res.message as string)
    }

    return res.data as AddMemberToTeam 
  } 
