import React from "react";
import styles from "./ChooseTemplate.module.scss";

export default function ChooseTemplate({
  currentView,
  setCurrentView,
}: {
  currentView: any;
  setCurrentView: any;
}) {
  // function to handle button click
  const handleBtnClick = () => {
    if (currentView === "view") {
      setCurrentView("create");
    } else {
      setCurrentView("view");
    }
  };

  return (
    <div>
      <div
        className={`d-flex justify-content-between align-items-center mb-2 mb-md-0`}
      >
        <p className={`${styles.chooseTemplate}`}>
          {currentView === "view" ? "Choose Templates" : "Create New Template"}
        </p>
        <button
          onClick={handleBtnClick}
          className={`${styles.viewTemplateBtn}`}
          id="templateBtn"
        >
          {currentView === "view" ? "Create Template" : "Back"}
        </button>
      </div>
    </div>
  );
}
