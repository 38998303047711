import FilterWrapper from "../FilterWrapper/FilterWrapper";
import CurrentStatusReports from "./children/CurrentStatusReports/CurrentStatusReports";
import styles from "./CurrentStatus.module.scss";
import {
  reportCurrentStatusActions,
  useReportFiltersCreate,
} from "../../hooks/reportFilters/useReportFilters";
import ReportDatePicker from "../ReportDatePicker/ReportDatePicker";
import ReportBar from "src/components/svg/ReportBar";

const CurrentStatus = () => {
  const [state, dispatch, Provider] = useReportFiltersCreate({
    globalDateApplicable: false,
  });

  return (
    <Provider value={[state, dispatch]}>
      <div>
        <div
          className={`d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center `}
        >
          <div className={`d-flex align-items-center`}>
            <span>
              <ReportBar />
            </span>
            <span className={`ms-2 ${styles.heading}`}>Current Status</span>
          </div>
          {/* <ReportDatePicker
            startDate={state.startDate}
            endDate={state.endDate}
            onChange={([startDate, endDate]) => {
              dispatch([reportCurrentStatusActions.setStartDate, startDate]);
              dispatch([reportCurrentStatusActions.setEndDate, endDate]);
            }}
          /> */}
        </div>
        <div>
          <FilterWrapper />
          <CurrentStatusReports />
        </div>
      </div>
    </Provider>
  );
};

export default CurrentStatus;
