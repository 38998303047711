/**
 * This file is the component file.
 *
 * @author Yash Aditya
 */

import Select, { components } from "react-select";
import styles from "./NormalSelect.module.scss";
import { useMemo, useState } from "react";

// Define the structure of the values in the options array
interface Value {
  id: string;
  name: string;
}

// Define the expected properties for the NormalSelect component
export interface NormalSelectProps {
  options: Array<Value>;
  value: Array<Value["id"]>;
  onChange: (value: Array<Value["id"]>) => void;
  isMulti: boolean;
  disabled?: boolean;
}

// NormalSelect component definition
function NormalSelect({
  options,
  value,
  onChange,
  isMulti,
  disabled = false,
}: NormalSelectProps) {
  /**
   * Created values array based on value and options.
   */
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const values = useMemo(() => {
    const optionsJSON: { [id: string]: Value } = {};
    options.forEach((v) => {
      optionsJSON[v.id] = v;
    });
    return value.map((v) => {
      return optionsJSON[v];
    });
  }, [options, value]);
  const customDropdownIndicator = (props: any) => (
    <components.DropdownIndicator {...props}>
      <i
        className={`fa-solid fa-chevron-${isDropdownOpen ? "up" : "down"}`}
        style={{ color: "#707070", fontSize: "12px" }}
      ></i>
    </components.DropdownIndicator>
  );
  return (
    <div className={`${styles.selectWrap}`}>
      {/* React Select component */}
      <Select
        isDisabled={disabled}
        // Set the options for the select
        options={options}
        // Define how to display the label for each option
        getOptionLabel={(value) => value.name}
        // Set initial value
        value={isMulti ? values : values[0] ?? null}
        getOptionValue={(value) => value.name}
        // Handle change in selection
        onChange={(v: any) => {
          // Check if it's a multi-select or single-select
          if (isMulti) {
            // For multi-select, map the selected values to an array of ids or default to an empty array
            onChange(v?.map((val: any) => val.id) ?? []);
          } else {
            // For single-select, handle the selected value or default to an empty array
            if (v) {
              onChange([v.id]);
            } else {
              onChange([]);
            }
          }
        }}
        // Customize the styles of the select component
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            color: "black",
            marginLeft: "8px",
            fontSize: "12px",
            fontWeight: "500",
            minHeight: "24px", // Set the minimum height for the header
            maxHeight: "24px",
            cursor: "pointer",
          }),
          // ... (previous styles)
          valueContainer: (baseStyles) => ({
            ...baseStyles,
            padding: "0px 6px", // Customize the padding for the value indicator
          }),
          menu: (baseStyles) => ({
            ...baseStyles,
            backgroundColor: "#fff", // Customize the background color
            fontSize: "12px",
            padding: "0 5px",
            zIndex: 9999, // Set a high zIndex to make the menu appear above other components
          }),
          singleValue: (baseStyles) => ({
            ...baseStyles,
            fontWeight: "500", // Customize the font weight for the selected item in the header
          }),

          option: (baseStyles, state) => ({
            ...baseStyles,
            color: state.isSelected ? "#000" : "#000", // Text color based on selection
            backgroundColor: state.isSelected ? "#FFDEDE" : "white", // Background color based on selection
            fontSize: "12px",
            marginBottom: "3px",
            // Custom styles for option on hover
            "&:hover": {
              backgroundColor: "#FFDEDE",
              color: "black",
              cursor: "pointer",
            },
          }),
          dropdownIndicator: (baseStyles) => ({
            ...baseStyles,
            padding: "0 8px", // Customize the padding of the dropdown indicator
          }),
          input: (baseStyles) => ({
            ...baseStyles,
            margin: "0px 4px", // Customize the margin for the input
            paddingTop: "0px",
          }),
        }}
        // Specify if it's a multi-select or single-select
        isMulti={isMulti}
        menuPortalTarget={document.body}
        components={{
          DropdownIndicator: customDropdownIndicator,
        }}
        onMenuOpen={() => setIsDropdownOpen(true)}
        onMenuClose={() => setIsDropdownOpen(false)}
      />
    </div>
  );
}

// Export the NormalSelect component
export default NormalSelect;
