import { IArticleSetting, initialState } from "./articleSetting.slice";

// reset article settings
const resetArticleSetting = (state: IArticleSetting) => {
  state = {...initialState};
  return state;
};


export default { 
  resetArticleSetting,
};