import { useEffect, useMemo, useReducer, useRef, useState } from "react";
import styles from "./AddTags.module.scss";
import log from "loglevel";
import CenterModal from "src/components/CenterModal/CenterModal";
import BaseButton from "../commons/BaseButton/BaseButton";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  createTag,
  fetchAllTicketsTags,
  getAllTagsSelector,
  getSelectedTagsIdsSelector,
  getUnselectedTagsIdsSelector,
  removeTagById,
  resetBulkAddTicketTagsState,
  selectTagById,
} from "src/store/slices/ticketTags/ticketTags.slice";
import AddTagsSuccess from "./children/AddTagsSuccess/AddTagsSuccess";
import TagItemDisplay from "./children/TagItemDisplay/TagItemDisplay";
import TagItem from "./children/TagItem/TagItem";
import DisplayAddedTags from "./children/DisplayAddedTags/DisplayAddedTags";
import useResetBulkActions from "src/hooks/useResetBulkActions";
import ModalFooter from "./children/ModalFooter";
import AddTagsBtn from "./children/AddTagsBtn";
import noTagFiller from "src/assets/images/noTagFiller.png";
import AddMoreBtn from "./children/AddMoreBtn";
import { removeWhiteSpaces } from "src/utils/validations";
import Loader from "src/components/Loader";

function AddTags() {
  const [menuInputValue, setMenuInputValue] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [modalState, localDispatch] = useReducer(reducer, initialModalState);
  const resetBulkActions = useResetBulkActions();
  const selectedTagsIds = useAppSelector(getSelectedTagsIdsSelector);
  const { allTagsIds, allTags } = useAppSelector(getAllTagsSelector);
  const unselectedTicketIds = useAppSelector(getUnselectedTagsIdsSelector);
  const bulkAddTicketTagsState = useAppSelector(
    (state) => state.ticketTags.bulkAddTicketTagsState
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (bulkAddTicketTagsState === "fulfilled") {
      localDispatch({ type: "ADD_TAGS_SUCCESS" }); //modalState.showModalFooter turn off
    }
  }, [bulkAddTicketTagsState]);

  //  useEffect(() => () => resetBulkActions(), []);
  const bulkAddTicketTagsStateRef = useRef(bulkAddTicketTagsState);
  useEffect(() => {
    bulkAddTicketTagsStateRef.current = bulkAddTicketTagsState;
  }, [bulkAddTicketTagsState]);
  const handleOnClick = () => {
    // log.debug("clicked bulk add tags button");
    setModalShow(true);
    dispatch(fetchAllTicketsTags());
  };

  const handleOnHideModal = () => {
    setModalShow(false);
    if (bulkAddTicketTagsStateRef.current !== "idle") {
      resetBulkActions(); //resetting bulk action filters, refetching the tickets, as new action has been performed
    }
  };

  const handleOnInputMenuValue = (e: any) => {
    setMenuInputValue(e.target.value);
  };

  // const handleOnClick

  const handleOnClickAddTagItem = (tag: any) => {
    // log.debug("tag selected:", tag);
    dispatch(selectTagById(tag.id));
    localDispatch({
      type: "ON_CLICK_ADD_TAG_MENU_BTN",
      payload: { noTagSelected: false },
    });
  };

  const handleOnClickRemoveTagItem = (tag: any) => {
    // console.log("tag removed:", tag);
    dispatch(removeTagById(tag.id));
    if (selectedTagsIds.length === 1) {
      localDispatch({
        type: "EXPAND_ADD_TAG_MENU",
      });
    }
  };

  const handleOnClickCreateTag = (e: any) => {
    e.preventDefault();

    if (
      removeWhiteSpaces(menuInputValue) !== "" &&
      !Object.values(allTags)
        .map((data) => data.tagName.toLowerCase().trim())
        .includes(removeWhiteSpaces(menuInputValue).toLowerCase())
    ) {
      dispatch(createTag(menuInputValue));
    }
  };

  const handleOnClickPlusIcon = (e: any) => {
    e.preventDefault();
    setMenuInputValue("");
    localDispatch({
      type: "EXPAND_ADD_TAG_MENU",
    });
  };

  const handleOnClickBottomPlusIcon = (e: any) => {
    e.preventDefault();
    setModalShow(true);
    setMenuInputValue("");
    localDispatch({
      type: "EXPAND_ADD_TAG_MENU",
    });
    dispatch(resetBulkAddTicketTagsState());
  };

  // filters the tags based on the input field string
  const getFilteredTagsIds = useMemo(
    () =>
      unselectedTicketIds.filter((tagId) =>
        allTags[tagId].tagName.includes(removeWhiteSpaces(menuInputValue))
      ),
    [menuInputValue, unselectedTicketIds]
  );

  // returns true if the input field does not have atleast one exact matchin in the tags list
  const showCreateTagOption = useMemo(() => {
    if (removeWhiteSpaces(menuInputValue) === "") {
      return false;
    }
    const hasExactMatch =
      allTagsIds.filter(
        (id: any) =>
          allTags[id].tagName.toLowerCase().trim() ===
          removeWhiteSpaces(menuInputValue).toLowerCase()
      ).length !== 0;
    return !hasExactMatch;
  }, [menuInputValue, unselectedTicketIds]);

  const showTagIsAlreadyAdded = useMemo(() => {
    const tagIsAlreadyAdded =
      selectedTagsIds.filter(
        (id) =>
          allTags[id].tagName.toLowerCase().trim() ===
          removeWhiteSpaces(menuInputValue).toLowerCase()
      ).length !== 0;
    return tagIsAlreadyAdded;
  }, [menuInputValue, selectedTagsIds]);

  return (
    <>
      {bulkAddTicketTagsState === "fulfilled" ? (
        <DisplayAddedTags onClick={handleOnClickBottomPlusIcon} />
      ) : (
        <BaseButton
          FAIcon={"fas fa-tags"}
          BtnText={"Add Tags"}
          BtnAction={handleOnClick}
          Selected={modalShow}
          id="addTagsBtn"
        />
      )}
      <CenterModal
        show={modalShow}
        onHide={handleOnHideModal}
        id="addTagsModal"
        customTitleComp={
          bulkAddTicketTagsState === "fulfilled" ? undefined : CustomTitleComp
        }
        customFooterComp={
          selectedTagsIds.length !== 0 && bulkAddTicketTagsState !== "pending"
            ? ModalFooter
            : undefined
        }
        // customFooterComp={ModalFooter}
      >
        {modalState.showAddTagsDiv && bulkAddTicketTagsState === "idle" && (
          <div className={`d-block w-100 text-center`} id="addTagModalBody">
            <img className={`${styles.noTagFiller}`} src={noTagFiller} />
            <div className={`${styles.noTagsText}`}>0 Tags to display</div>
            <div className={`d-flex justify-content-center mt-3`}>
              <AddTagsBtn
                onClick={handleOnClickPlusIcon}
                // style={{ background: "blue" }}
                id="addTag"
              />
            </div>
          </div>
        )}

        {/* Selected tags list */}
        {/* {selectedTagsIds.length !== 0 && */}
        {selectedTagsIds.length !== 0 &&
          bulkAddTicketTagsState !== "pending" &&
          bulkAddTicketTagsState !== "fulfilled" && (
            <div className={`${styles.fixHeight}`}>
              <span className={`d-flex align-items-center flex-wrap`}>
                {selectedTagsIds.map((tagId) => (
                  <TagItemDisplay
                    tag={allTags[tagId]}
                    key={tagId}
                    onClickRemove={handleOnClickRemoveTagItem}
                  />
                ))}
                {/* <AddMoreBtn onClick={handleOnClickPlusIcon} /> */}
                <span
                  className={`cursor-pointer`}
                  onClick={handleOnClickPlusIcon}
                >
                  +
                </span>
              </span>
            </div>
          )}

        {/* {false && ( */}
        {bulkAddTicketTagsState !== "pending" &&
          modalState.isAddTagsMenuOpen && (
            <div className={`${styles["addTagBox"]}`} id="addTagBox">
              <input
                data-lpignore="true"
                type="text"
                className={`${styles["addInput"]}`}
                placeholder="Enter tag name"
                value={menuInputValue}
                onChange={handleOnInputMenuValue}
              />
              <div className={`${styles["addTagDropdownMain"]} mt-1`}>
                {showCreateTagOption && (
                  <div
                    className={`btn w-100 ${styles["addTagDropdownBtn"]}`}
                    onClick={handleOnClickCreateTag}
                  >
                    <div style={{ color: "116BBB", fontWeight: "bold" }}>
                      <i className={`fas fa-plus me-1`} /> Create tag "
                      {removeWhiteSpaces(menuInputValue)}"
                    </div>
                  </div>
                )}
                {getFilteredTagsIds.map((tagId) => (
                  <TagItem
                    key={tagId}
                    tag={allTags[tagId]}
                    onClick={handleOnClickAddTagItem}
                  />
                ))}
                {showTagIsAlreadyAdded && (
                  <div className={`${styles.tagAlreadyAdded}`}>
                    A tag with name “
                    <span className={`${styles.tagAlreadyAddedValue}`}>
                      {removeWhiteSpaces(menuInputValue)}
                    </span>
                    ” is already added
                  </div>
                )}
              </div>
            </div>
          )}

        {bulkAddTicketTagsState === "pending" && (
          <Loader style={{ marginTop: "-90px" }} />
        )}

        {/* Add tag success div */}
        {bulkAddTicketTagsState === "fulfilled" && <AddTagsSuccess />}
      </CenterModal>
    </>
  );
}

const CustomTitleComp = () => (
  <h5 className={`${styles["addTag_Title"]}`}>Add tags</h5>
);

const getAddTagsClass = (isAddTagsMenuOpen: boolean) => {
  if (isAddTagsMenuOpen === false) {
    return `btn ${styles.addTagBtn1} me-2`;
  }
  if (isAddTagsMenuOpen === true) {
    return `btn btn-primary ${styles.addTagBtn2} me-2`;
  }
};

const initialModalState = {
  showAddTagsDiv: true,
  isAddTagsMenuOpen: false,
  showTagsList: false,
  disableAddTagsBtn: true,
  // showModalFooter: false,
};

const reducer = (state: any, { type, payload }: any) => {
  switch (type) {
    case "HIDE_ADD_TAG_MENU":
      return {
        ...state,
        showAddTagsDiv: true,
        isAddTagsMenuOpen: false,
        showTagsList: false,
        disableAddTagsBtn: true,
      };
    case "EXPAND_ADD_TAG_MENU":
      return {
        ...state,
        showAddTagsDiv: false,
        isAddTagsMenuOpen: true,
        showTagsList: true,
        disableAddTagsBtn: true,
      };
    case "ON_CLICK_ADD_TAG_MENU_BTN":
      const isMenuOpen = state.isAddTagsMenuOpen;
      const noTagSelected = payload.noTagSelected;
      if (isMenuOpen && noTagSelected) {
        return {
          ...state,
          isAddTagsMenuOpen: false,
          showTagsList: false,
          disableAddTagsBtn: true,
        };
      } else if (isMenuOpen && !noTagSelected) {
        return {
          ...state,
          showAddTagsDiv: false,
          isAddTagsMenuOpen: false,
          showTagsList: true,
          disableAddTagsBtn: false,
        };
      } else if (!isMenuOpen) {
        return {
          ...state,
          isAddTagsMenuOpen: true,
          showTagsList: false,
          disableAddTagsBtn: true,
        };
      }
      return state;
    case "SELECTED_TAGS_LIST":
      return {
        ...state,
        showAddTagsDiv: false,
        isAddTagsMenuOpen: false,
        showTagsList: true,
        disableAddTagsBtn: false,
      };
    case "ADD_TAGS_SUCCESS":
      return {
        ...state,
        showTagsList: false,
      };
    default:
      return state;
  }
};

export default AddTags;
