import React from "react";
import styles from "./KnowledgeBaseUpdated.module.scss";
import TabSection from "./Children/TabSection";
import Articles from "./Children/TabSection/Articles/Articles";
import KnowledgeSetting from "./Children/TabSection/KnowledgeSetting/KnowledgeSetting";
import { Route, Routes } from "react-router-dom";
import Analytics from "./Children/TabSection/Analytics/Analytics";

const KnowledgeBaseUpdated = () => {
  return (
    <div className="py-3 px-3 w-100">
      <TabSection />
      <div className={`${styles.knowledgeBaseWrapper}`}>
        <Routes>
          <Route path={`/articles`} element={<Articles />} />
          <Route path={`/articles/:articleId`} element={<Articles />} />
          <Route path={`/settings`} element={<KnowledgeSetting />} />
          <Route
            path={`/settings/:subSettings`}
            element={<KnowledgeSetting />}
          />
          <Route
            path={`/settings/:subSettings/:subSubSettings`}
            element={<KnowledgeSetting />}
          />
          <Route path={`/analytics`} element={<Analytics />} />
        </Routes>
      </div>
    </div>
  );
};

export default KnowledgeBaseUpdated;
