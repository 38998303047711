import { Spinner } from "react-bootstrap";
import styles from "./ToastModals.module.scss";
import logo from "src/assets/images/hLogo.png";
import saufterLogo from "src/assets/images/saufter-logo.svg";

const showSaufterLogo = window.location.hostname.includes(
  process.env.REACT_APP_LOGO_HOST_NAME + "",
);

interface Props {
  onHide: () => void;
  callAgain: () => void;
}

function InstallationLoading({ onHide, callAgain }: Props) {
  return (
    <div className="px-4">
      <div className="h-0 m-auto">
        <img
          src={showSaufterLogo ? saufterLogo : logo} //render saufter logo for saufter domain
          className={`${styles.loaderBG} position-relative m-auto d-block`}
          style={{
            padding: showSaufterLogo ? "2px" : undefined, //added padding for saufter logo to avoid loader overlapping
          }}
        />
      </div>
      <Spinner
        animation="border"
        className={`${styles.loader} d-block mx-auto my-5`}
      />
      <div className={`${styles.title} mx-auto`}>
        {`Verifying Live Chat Widget Installation`}
      </div>
      <div className={`${styles.subtitle} mx-auto my-2`}>
        {`Hold Tight! We're checking installation on your website this may take upto 30 seconds.`}
      </div>
    </div>
  );
}

export default InstallationLoading;
