/**
 * This file contains the custome hook for component.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { getAnalyticsAndSeo } from "src/services/KnowledgeBase/Settings/DomainAndAccess/getAnalyticsAndSeo.service";
import { replaceOrRevertOpenGraphImage } from "src/services/KnowledgeBase/Settings/DomainAndAccess/replaceOrRevertOpenGraphImage.service";
import { updateAnalyticsAndSeo } from "src/services/KnowledgeBase/Settings/DomainAndAccess/updateAnalyticsAndSeo.service";
import { selectFile } from "src/utils/utils";

/**
 * This hook is used for the analytical data and filters management in KB.
 */
function useAnalytics() {
  // Variable declaration from useAnalytics hook

  // State declaration for dropdown
  const [dropdown, setDropDown] = useState(true);
  const [seo, setSeo] = useState(false); // State declaration for seo
  const [graph, setGraph] = useState(false); // State declaration for graph

  const { integrationId } = useParams();
  const pageIntegrationId = integrationId
    ? decodeURIComponent(integrationId)
    : "";

  // Query for fetching analytics and seo data
  const getAnalyticsAndSeoData = useQuery({
    queryFn: () =>
      getAnalyticsAndSeo({
        integrationId: pageIntegrationId,
      }),
    queryKey: ["getAnalyticsAndSeo"],
  });

  // Getting data and loading state from getAnalyticsAndSeoData query
  const { data, isLoading } = getAnalyticsAndSeoData;

  const queryClient = useQueryClient();

  // Mutation for updating analytics and seo data
  const updateAnalytics = useMutation({
    mutationFn: updateAnalyticsAndSeo,
    onSuccess: () => {
      queryClient.invalidateQueries(["getAnalyticsAndSeo"]);
    },
  });

  // State declaration for analytics and seo data
  const [isAnalyticsEnabled, setIsAnalyticsEnabled] = useState(
    data?.isGoogleAnalyticsEnabled ?? false
  );

  // State declaration for analytics version
  const [isVersion4, setIsVersion4] = useState(
    data?.isGoogleAnalyticsVersion4 === true
      ? "flexRadioDefault1"
      : "flexRadioDefault2"
  );

  const [trackingId, setTrackingId] = useState(data?.trackingId ?? ""); // State declaration for tracking id

  const [showKBPublicSearch, setShowKBPublicSearch] = useState(
    // State declaration for show kb public search
    data?.isVisibleinPublicSearch ?? false
  );
  const [pageTitle, setPageTitle] = useState(data?.pageTitle ?? ""); // State declaration for page title
  const [metaDescription, setMetaDescription] = useState(
    // State declaration for meta description
    data?.metaDescription ?? ""
  );

  const [openGraphTitle, setOpenGraphTitle] = useState(
    // State declaration for open graph title
    data?.openGraphTitle ?? ""
  );
  const [openGraphDescription, setOpenGraphDescription] = useState(
    // State declaration for open graph description
    data?.openGraphDescription ?? ""
  );

  const handleIsAnalyticsEnabled = useCallback((e: any) => {
    // Function for handling analytics enabled
    setIsAnalyticsEnabled(e.target.checked);
  }, []);

  const handleChangeIsVersion4 = useCallback(() => {
    // Function for handling analytics version
    setIsVersion4("flexRadioDefault1");
  }, []);

  const handleChangeOldVersion = useCallback(() => {
    // Function for handling old version
    setIsVersion4("flexRadioDefault2");
  }, []);

  const handleChangeTrackingId = useCallback((e: any) => {
    // Function for handling tracking id
    setTrackingId(e.target.value);
  }, []);

  const handleShowKBPublicSearch = useCallback((e: any) => {
    // Function for handling show kb public search
    setShowKBPublicSearch(e.target.checked);
  }, []);

  const handleChangePageTitle = useCallback((e: any) => {
    // Function for handling page title
    setPageTitle(e.target.value);
  }, []);

  const handleChangeMetaDescription = useCallback((e: any) => {
    // Function for handling meta description
    setMetaDescription(e.target.value);
  }, []);

  const handleChangeOpenGraphTitle = useCallback((e: any) => {
    // Function for handling open graph title
    setOpenGraphTitle(e.target.value);
  }, []);

  const handleChangeOpenGraphDescription = useCallback((e: any) => {
    // Function for handling open graph description
    setOpenGraphDescription(e.target.value);
  }, []);

  const handleSaveChanges = useCallback(() => {
    // Function for handling save changes
    // If tracking id is empty then show warning toast
    if (!trackingId) {
      pushTheToast({
        position: "top-right",
        text: "Tracking ID is required",
        type: "warning",
      });
      return;
    } else if (pageTitle === "" || metaDescription === "") {
      pushTheToast({
        position: "top-right",
        text: "Please Fill All Seo Settings Fields",
        type: "warning",
      });
    } else if (openGraphTitle === "" || openGraphDescription === "") {
      pushTheToast({
        position: "top-right",
        text: "Please Fill Open Graph Data Fields",
        type: "warning",
      });
    } else {
      // If all fields are filled then update analytics and seo data
      updateAnalytics.mutate({
        integrationId: pageIntegrationId,
        isGoogleAnalyticsEnabled: isAnalyticsEnabled,
        isGoogleAnalyticsVersion4:
          isVersion4 === "flexRadioDefault1" ? true : false,
        trackingId: trackingId,
        isVisibleinPublicSearch: showKBPublicSearch,
        pageTitle: pageTitle,
        metaDescription: metaDescription,
        openGraphTitle: openGraphTitle,
        openGraphDescription: openGraphDescription,
      });
    }
  }, [
    trackingId,
    pageTitle,
    metaDescription,
    openGraphTitle,
    openGraphDescription,
    isAnalyticsEnabled,
    isVersion4,
    showKBPublicSearch,
    updateAnalytics,
    pageIntegrationId,
  ]);

  const [openGraphImage, setOpenGraphImage] = useState(
    data?.openGraphImage ?? null
  ); // State declaration for open graph image

  // Mutation for replacing or reverting open graph image
  const replaceOrRevertOpenGraphImageData = useMutation({
    mutationFn: replaceOrRevertOpenGraphImage,
    mutationKey: ["replaceOrRevertOpenGraphImage"],
    onSuccess: (data) => {
      data.data.openGraphImage
        ? setOpenGraphImage(data.data.openGraphImage)
        : setOpenGraphImage(null);
    },
  });

  // Function for handling replace image
  const handleReplaceImage = async () => {
    // Try catch block for handling error
    try {
      let image = (await selectFile(["jpeg", "jpg", "png"]))[0]; // Select image
      replaceOrRevertOpenGraphImageData.mutate({
        // Replace image data mutation
        type: "replace",
        replaceImg: image,
        integrationId: pageIntegrationId,
      });
    } catch (error) {
      // If error then show warning toast
      pushTheToast({
        text: (error as any).msg,
        position: "top-right",
        type: "warning",
      });
    }
  };

  // Function for handling revert image
  const handleRevertImage = useCallback(() => {
    // Revert image data mutation
    replaceOrRevertOpenGraphImageData.mutate({
      integrationId: pageIntegrationId,
      type: "revert",
    });
  }, [replaceOrRevertOpenGraphImageData, pageIntegrationId]);

  // Use effect for setting analytics and seo data in state variables when data is fetched or changed
  useEffect(() => {
    if (data) {
      setIsAnalyticsEnabled(data?.isGoogleAnalyticsEnabled ?? false);
      setIsVersion4(
        data?.isGoogleAnalyticsVersion4 === true
          ? "flexRadioDefault1"
          : "flexRadioDefault2"
      );
      setTrackingId(data?.trackingId ?? "");
      setShowKBPublicSearch(data?.isVisibleinPublicSearch ?? false);
      setPageTitle(data?.pageTitle ?? "");
      setMetaDescription(data?.metaDescription ?? "");
      setOpenGraphTitle(data?.openGraphTitle ?? "");
      setOpenGraphDescription(data?.openGraphDescription ?? "");
    }
  }, [data]);

  return {
    dropdown,
    setDropDown,
    seo,
    setSeo,
    graph,
    setGraph,
    pageIntegrationId,
    isLoading,
    isAnalyticsEnabled,
    handleIsAnalyticsEnabled,
    isVersion4,
    handleChangeIsVersion4,
    handleChangeOldVersion,
    trackingId,
    handleChangeTrackingId,
    showKBPublicSearch,
    handleShowKBPublicSearch,
    pageTitle,
    handleChangePageTitle,
    metaDescription,
    handleChangeMetaDescription,
    openGraphTitle,
    handleChangeOpenGraphTitle,
    openGraphDescription,
    handleChangeOpenGraphDescription,
    handleSaveChanges,
    handleReplaceImage,
    handleRevertImage,
    updateAnalytics,
    data,
    replaceOrRevertOpenGraphImageData,
    openGraphImage,
  };
}

export default useAnalytics;
