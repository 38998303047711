import React from "react";
import styles from "./AllTemplates.module.scss";
import TemplateCategory from "./Children/TemplateCategory/TemplateCategory";
import ResultTemplates from "./Children/ResulTemplates/ResultTemplates";

const AllTemplates = ({
  setTemplates,
  selectedCategory,
  setSelectedCategory,
  templates,
  setSelectedTemplateData = (template: any) => {},
  onTemplateSelect = (template: string) => {},
}: {
  setTemplates: any;
  selectedCategory: any;
  setSelectedCategory: any;
  templates: any;
  setSelectedTemplateData: any;
  onTemplateSelect: any;
}) => {
  return (
    <div className="d-flex flex-column flex-md-row">
      <TemplateCategory
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        setTemplates={setTemplates}
      />
      <ResultTemplates
        selectedCategory={selectedCategory}
        setTemplates={setTemplates}
        templates={templates}
        setSelectedTemplateData={(template: any) => {
          setSelectedTemplateData(template);
        }}
        onTemplateSelect={(template: string) => {
          onTemplateSelect(template);
        }}
      />
    </div>
  );
};

export default AllTemplates;
