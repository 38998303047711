import { axiosJSON } from "src/globals/axiosEndPoints";

export interface IReadCustomerEmails {
  searchTerm: string;
  start: number;
  limit: number;
  onlyVerified?: boolean;
}
export interface ICustomerEmails {
  id: number;
  name: string;
  email: string;
}
export interface ICustomerData {
  data: Array<ICustomerEmails>;
  metaData: {
    count: number;
    totalCount: number;
  };
}
export async function readCustomerEmails(payload: IReadCustomerEmails) {
  try {
    const { data } = await axiosJSON.post(`/api/customer/search`, payload);
    return data;
  } catch (e) {
    throw "Something went wrong!";
  }
}

export async function readCompanyEmails(payload: IReadCustomerEmails) {
  try {
    const { data } = await axiosJSON.post(`/api/brand/searchBrands`, payload);
    return data;
  } catch (e) {
    throw "Something went wrong!";
  }
}
