import { axiosJSON } from "src/globals/axiosEndPoints";
import { BugData } from "./getBugByCategory.service";

interface Params {
  categoryId: number;
  start: number;
  limit: number;
  bugStatusKeys?: string[];
  priorityKeys?: string[];
}

interface CustomerDetails {
  customerId: number;
  customerName: string;
  customerEmail: string;
  imageUrl: string;
  isPublicAttachment: boolean;
}

interface BugAttachmentDetails {
  attachmentId: number;
  attachmentBatchNumber: string;
  attachmentName: string;
  attachmentSize: number;
  attachmentType: string;
  attachmentUrl: string;
  isPublicAttachmentUrl: boolean;
}

export interface BugReport {
  bugId: number;
  bugDescription: string;
  bugCreatedAtGmt: string;
  ticketId: number | null;
  bugStatusKey: string;
  priorityKey: string;
  bugReportedUrl: string | null;
  customerDetails: CustomerDetails;
  bugAttachmentDetails: BugAttachmentDetails[];
}

interface MetaData {
  totalCount: number;
  currentCount: number;
}

interface ApiResponse {
  err: boolean;
  msg: string;
  data: BugData[];
  metaData: MetaData;
}


const getBugListByCategoryId = async (param: Params) => {
  const { data: res } = await axiosJSON.post(
    `/api/selfService/bugManagement/getBugsDataByCategoryId`,
    param,
  );

  if (res.err || res.error) {
    throw res.msg ?? res.message ?? "Something went wrong!";
  }

  let ret: ApiResponse;

  ret = res;

  return ret;
};

export default getBugListByCategoryId;
