import { axiosFormData, axiosJSON } from "src/globals/axiosEndPoints";
import APIResponse from "../Settings/Teams/APIReponse";

interface DeleteAttachmentRes {
  status : number | string;
}

export interface DeleteAttachmentParam{
  attachmentId: number;
}


/*export async function deleteAttachment(
  params: DeleteAttachmentParam
) {
  // console.log("Delete attachment:: Params:: ", JSON.stringify(params));

  const { data: res } = await axiosJSON.delete<APIResponse <DeleteAttachmentRes>>(
    "/api/attachment/delete",
    params
  );

  // console.log("Delete attachment:: Res:: ", JSON.stringify(res));
  if (res === false) {
    throw new Error(res.message as string);
  }

  return res ;
}*/


export async function deleteAttachment(params: DeleteAttachmentParam) {
  const { data } = await axiosJSON.delete(
    `/api/attachment/delete`,
    {params}
  );
  // console.log("Delete attachment:: res:: ", JSON.stringify(data));
  if (data !== 1) {
    throw new Error("Failed to delete");
  }
  return data;
}

