import styles from "./ImportData.module.scss";
import { useEffect, useMemo, useState } from "react";
import ProductsTable from "../../UploadedCsvModal/ProductsTable/ProductsTable";
import { useParams } from "react-router-dom";
import Loader from "src/components/Loader";
import getImportProducts, {
  GetImportProductsParams,
} from "src/services/ReturnAutoWorkFlow/ConfigModals/getImportProducts.service";
import { ImportedCSVTable } from "src/features/ReturnAutoWorkFlow/ReturnAutoWorkFlow.types";
import { useQuery } from "@tanstack/react-query";
import { useUploadNewCsvModalContext } from "../Hooks/useUploadNewCsvModalContext";
import { useUploadedCSVModal } from "../../UploadedCsvModal/UploadedCsvModal";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";

/**
 * Custom hook to handle the data for importing products data.
 *
 * This hook uses the `useQuery` hook from React Query to fetch and manage the state of product variant data.
 * It transforms the raw data into a structured format suitable for use in the modal, including processing
 * table headings and variant information.
 *
 * @param {GetImportProductsParams} params - The parameters used to fetch product variant data.
 * @returns object - An object containing the formatted products details, loading state, and error state.
 */
const useImportCsvData = (params: GetImportProductsParams) => {
  const { data, isLoading, isError } = useQuery<ImportedCSVTable>({
    queryKey: ["getImportProducts", params],
    queryFn: () => getImportProducts({ ...params }),
  });
  return {
    data: data,
    isLoading,
    isError,
  };
};

const ImportData = ({
  setNextBtnDisabled,
}: {
  setNextBtnDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const { integrationId } = useParams();
  const { state } = useUploadNewCsvModalContext();
  const limit = 10;
  /**
   * Starting index for API call
   */
  const start = useMemo(() => {
    return limit * currentPage - limit;
  }, [currentPage]);

  const params: GetImportProductsParams = {
    integrationId: integrationId ?? "",
    start: start,
    limit: limit,
    attachmentId: state.uploadCSV?.attachmentId ?? "",
  };

  // Calling this api once to start the import
  const {
    data: importData,
    isError: importError,
    isLoading: isImportLoading,
  } = useImportCsvData({
    attachmentId: state.uploadCSV?.attachmentId ?? "",
    integrationId: integrationId ?? "",
    start: 0,
    limit: limit,
  });

  const { isError, isLoading, data } = useUploadedCSVModal({
    ...params,
    isImportLoading,
  });

  useEffect(() => {
    if (importError) {
      setNextBtnDisabled(true);
      pushTheToast({
        position: "top-right",
        text: "Something went wrong while importing!",
        type: "danger",
      });
    } else if (importData) {
      setNextBtnDisabled(false);
    }
  }, [importError, importData]);
  /**
   * Total pages available
   */
  const totalPages = useMemo(() => {
    return data?.totalVarients ? Math.ceil(data?.totalVarients / limit) : 0;
  }, [data?.totalVarients]);

  return (
    <div>
      {isLoading || isImportLoading ? (
        <div className={`mt-3 ${styles.contentWrapper}`}>
          <Loader />
        </div>
      ) : isError ? (
        <div className={`mt-3 ${styles.contentWrapper}`}>
          <div className="d-flex align-items-center justify-content-center text-danger">
            {"Something went wrong!"}
          </div>
        </div>
      ) : data ? (
        <div className={`mt-3 ${styles.contentWrapper}`}>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h4 className={`${styles.heading}`}>Imported file</h4>
              <p className={`mb-0 ${styles.subHeading}`}>
                <span className={`${styles.fileName}`}>File name- </span>
                <span className={`${styles.fileDesc}`}>
                  {data?.fileName ?? ""}
                </span>
              </p>
            </div>
            <div className="d-flex">
              <div className="text-center me-4">
                <p className={`mb-1 ${styles.prodValue}`}>
                  {(data?.totalProducts ?? 0) > 9
                    ? data?.totalProducts ?? 0
                    : `0${data?.totalProducts}`}
                </p>
                <span className={`${styles.prod}`}>Products</span>
              </div>
              <div className="text-center">
                <p className={`mb-1 ${styles.prodValue}`}>
                  {data?.totalSKU ?? 0}
                </p>
                <span className={`${styles.prod}`}>Total SKU</span>
              </div>
            </div>
          </div>
          <div className={`mt-3 ${styles.table}`}>
            <ProductsTable
              data={data}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={totalPages}
              integrationId={integrationId ?? ""}
              viewContext="newUploads"
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ImportData;
