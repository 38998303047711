import styles from "./AgentRow.module.scss";
import dummy1 from "src/assets/images/chat/1.png";
import dummy2 from "src/assets/images/chat/2.png";
import dummy3 from "src/assets/images/chat/3.png";
import { useEffect, useState } from "react";
import { BrandAgent } from "src/services/Settings/Brands/getBrand";
import UserAvatar from "src/components/UserAvatar";
import AxiosImg from "src/components/AxiosImg";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import DisableAgentModal from "./Children/DisableAgentModal/DisableAgentModal";
import { useAppDispatch, useAppSelector } from "src/store/store";

import {
  addUserToModifiedUsers,
  getBrandModifiedAssignToUsersIdsSelector,
  isAgentIdSelectedSelector,
  removeUserFromBulkSelect,
  selectUserForBulkSelect,
  setChildModal,
} from "src/store/slices/settings/brands/brandSettings.slice";
interface Props {
  user: BrandAgent;
}
const AgentRow = ({ user }: Props) => {
  const { activeBrandId } = useAppSelector((state) => state.brandSettings);
  const scopes = useAppSelector(
    (state) => state.globals.currentUserData?.scopes,
  );
  const [showModal, setShowModal] = useState(false);

  const dispatch = useAppDispatch();

  const isAgentSelected = useAppSelector((state) =>
    isAgentIdSelectedSelector(state, user.id),
  );
  const modifiedAssignToUserIds = useAppSelector(
    getBrandModifiedAssignToUsersIdsSelector,
  );

  const handleOnClick = (value: boolean) => {
    //if value is true, then no need to show modal
    if (value) {
      handleSubmit(null, true);
    } else {
      if (user.ticketCount && parseInt(user.ticketCount + "") > 0) {
        setShowModal(true);
      } else handleSubmit(null, value);
    }
  };

  const handleSubmit = (
    selectedAgent: BrandAgent | null,
    enabled: boolean = false,
  ) => {
    let updatedAgent: any = {};

    updatedAgent[user.id] = {
      id: user.id,
      enabled: enabled,
      ticketCount:
        user.ticketCount && user.ticketCount !== 0
          ? parseInt(user.ticketCount + "")
          : 0,
      assignTo:
        selectedAgent && selectedAgent.id != 0 ? selectedAgent.id : null,
    };

    dispatch(addUserToModifiedUsers(updatedAgent));
    setShowModal(false);
    dispatch(setChildModal(false));
  };

  const handleOnChangeCheckbox = () => {
    if (isAgentSelected) {
      dispatch(removeUserFromBulkSelect(user.id));
    } else {
      dispatch(selectUserForBulkSelect(user.id));
    }
  };

  const handleOnClickRow = (e: any) => {
    if (user.roleName === "Admin") {
      return;
    }
    if (scopes && scopes.includes("settings_can_edit_email_signature")) {
      let ele = e.target;
      let clickable = true;
      try {
        while (true) {
          if (ele === e.currentTarget) {
            break;
          } else if (ele.classList.contains("ignoreClick")) {
            clickable = false;
            break;
          } else {
            ele = ele.parentElement;
          }
        }
      } catch {
        clickable = false;
      }
      if (clickable) {
        handleOnChangeCheckbox();
      }
    }
  };

  useEffect(() => {
    dispatch(setChildModal(showModal));
  }, [showModal]);

  const customStyles = {
    display: isAgentSelected ? "block" : "none",
  };
  const style = {
    background: isAgentSelected
      ? " #f8f8f8 0% 0% no-repeat padding-box"
      : "transparent",
    borderRadius: isAgentSelected ? "10px" : " ",
    cursor: "pointer",
  };
  return (
    <>
      <div>
        <div
          className={`py-2 ps-2 ps-4 mt-4 d-flex align-items-center position-relative ${styles.tableHeader}`}
          style={style}
          onClick={(event) => handleOnClickRow(event)}
        >
          <div
            className={`${styles.showBar}`}
            style={customStyles}
          ></div>
          <div className={`form-check me-2 ${styles.chkform}`}>
            <input
              className={`form-check-input ${styles.checkboxtick}`}
              type="checkbox"
              value=""
              checked={isAgentSelected}
              disabled={
                user.roleName === "Admin" ||
                ((scopes &&
                  scopes.includes("settings_can_edit_email_signature")) === true
                  ? false
                  : true)
              }
              onChange={handleOnChangeCheckbox}
            />
          </div>
          <div className={`${styles.list}`}>
            <div className={`d-flex align-items-center`}>
              <div>
                {user.profileImageUrl && user.profileImageUrl.trim() !== "" ? (
                  <AxiosImg
                    url={user.profileImageUrl}
                    className={`rounded-circle ${styles.dummyImg}`}
                    isDirectURL={user.isPublicAttachmentUrl}
                  />
                ) : (
                  <UserAvatar
                    name={user.name ?? "NA"}
                    size={32}
                  />
                )}
              </div>
              <div className={`ms-2`}>
                <h6 className={`mb-0 ${styles.agentName}`}>
                  {user.name ?? "NA"}
                </h6>
                <span className={`d-inline-block ${styles.agentEmail}`}>
                  {user.email}
                </span>
              </div>
            </div>
          </div>
          <div className={`${styles.role} text-center`}>
            <span className={`${styles.roleText}`}>{user.roleName} </span>
          </div>
          <div className={`${styles.permit} ignoreClick`}>
            <div className={`d-flex justify-content-center`}>
              <div className="form-check form-switch">
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip className={` ${styles.toolTipCustom}`}>
                      {/* Oct 28 updated the enable/disable text to Enabled/Disabled */}
                      {user.enabled ? "Enabled" : "Disabled"}
                    </Tooltip>
                  }
                >
                  {({ ref, ...triggerHandler }) => (
                    <div
                      className="form-check form-switch"
                      {...triggerHandler}
                      ref={ref}
                      // onClick={showDeactivateModal}
                    >
                      <input
                        className={`me-1 form-check-input ${styles.switchBox}`}
                        type="checkbox"
                        role="switch"
                        id={"user_enable_switch_user_" + user.id}
                        checked={user.enabled}
                        onChange={(e) => handleOnClick(!user.enabled)}
                        disabled={
                          user.roleName === "Admin" ||
                          modifiedAssignToUserIds.includes(user.id) ||
                          ((scopes &&
                            scopes.includes(
                              "settings_can_edit_email_signature",
                            )) === true
                            ? false
                            : true)
                        }
                      />
                    </div>
                  )}
                </OverlayTrigger>
              </div>
              <label
                htmlFor={"user_enable_switch_user_" + user.id}
                className={`${styles.permitText}`}
              >
                {" "}
                {/* Oct 28 updated the enable/disable text to Enabled/Disabled */}
                {user.enabled ? "Enabled" : "Disabled"}
              </label>
            </div>

            {/* Admin Access Message */}
            {user.roleName === "Admin" && (
              <span className={`text-danger ${styles.adminAccess}`}>
                Admin's access cannot be disabled.
              </span>
            )}
          </div>
        </div>
      </div>
      <Modal
        backdropClassName={`${styles.modalBack} d-none`}
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName={`${styles.modalDialog}`}
        contentClassName={`${styles.modalContent}`}
        centered={true}
        enforceFocus={false}
        style={{ zIndex: 1056 }}
      >
        <DisableAgentModal
          brandUser={user}
          isSingleUser={true}
          brandId={activeBrandId}
          deleteAjaxStatus={"fulfilled"}
          handleSubmit={handleSubmit}
          handleCancel={() => setShowModal(false)}
        />
      </Modal>
    </>
  );
};
export default AgentRow;
