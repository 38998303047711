import { axiosJSON } from "src/globals/axiosEndPoints";


export interface updateGoalSettingParams{
  goalId: number | string;
  integrationId: number | string;
  goalName ?: string,
  isActive ? : boolean,
  goalValueType? : string;
  goalValue ?: string,
  goalType ?: string,
}


export async function updateGoalSetting(params: updateGoalSettingParams) {
  const { data } = await axiosJSON.post(
    `/api/chatSetting/goals/update`,
    params
  );
  if (data.error === true) {
    throw new Error(data.message as string);
  }
  return data.data;
}