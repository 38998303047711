import { useMemo } from "react";
import { Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";

function useShowLoader({
  styles,
  errorInCustFetchOrLoading,
  fetchMsgAJAXStatus,
  messageIdList,
}: any) {
  const { chatId } = useParams();
  return useMemo(() => {
    return (fetchMsgAJAXStatus === "pending" || chatId === "all") &&
      messageIdList.length === 0 ? (
      <div
        className={`d-flex flex-column justify-content-center align-items-center ${
          styles.loading
        } ${errorInCustFetchOrLoading ? styles.loadingChatsInConv : ""} h-100`}
      >
        <Spinner
          animation="border"
          variant="primary"
          size="sm"
          className={`d-block mb-2`}
        />
        <span>Loading Messages...</span>
      </div>
    ) : undefined;
  }, [
    fetchMsgAJAXStatus,
    chatId,
    messageIdList,
    errorInCustFetchOrLoading,
    styles,
  ]);
}

export default useShowLoader;
