import React from "react";
import styles from "./SearchedTemplates.module.scss";
import ShowTemplate from "./Children/ShowTemplate/ShowTemplate";
import { v4 as uuidv4 } from "uuid";
import InfiniteScroll from "src/components/InfiniteScrollBothSide";

const SearchedTemplates = ({
  templates,
  searchText,
  setSelectedTemplateData = (template: any) => {},
  onTemplateSelect = (template: string) => {},
  hasMore,
  loadMore,
  errorMessage,
}: {
  templates: any;
  searchText: string;
  setSelectedTemplateData: any;
  onTemplateSelect: any;
  hasMore?: boolean;
  loadMore: () => void;
  errorMessage?: JSX.Element;
}) => {
  return (
    <div className="mt-3 pb-4" style={{ height: "60vh" }}>
      <h3 className={`pb-1 ${styles.searchHead}`}>
        Showing results for {`"${searchText}"`}
      </h3>
      <InfiniteScroll
        className={`overflow-auto h-100 py-2`}
        loadMoreFromBottom={loadMore}
        hasMoreBottom={hasMore}
      >
        {errorMessage
          ? errorMessage
          : templates.map((template: any) => {
              return (
                <div className={`${styles.templateMain}`} key={uuidv4()}>
                  <ShowTemplate
                    template={template}
                    searchText={searchText}
                    display={"d-block"}
                    setSelectedTemplateData={(template: any) => {
                      setSelectedTemplateData(template);
                    }}
                    onTemplateSelect={(template: string) => {
                      onTemplateSelect(template);
                    }}
                  />
                </div>
              );
            })}
      </InfiniteScroll>
    </div>
  );
};

export default SearchedTemplates;
