/**
 * LanguageDropdown Component
 *
 * This component represents a dropdown for selecting language options in audience configuration
 *
 * @author @yuvaraj-busibud
 */

import React, { useEffect, useMemo, useRef, useState } from "react";
import styles from "./LanguageDropdown.module.scss";
import CustomSelectDropdown from "../../../CustomSelectDropdown/CustomSelectDropdown";
import { getBrowserLanguageList } from "src/services/Bot/BotProfiles/getBrowserLanguageList";
import { useQuery } from "@tanstack/react-query";

interface Options {
  value: string | number;
  label: string;
}
interface Props {
  value: string | null | Array<string | number>;
  handleChange: (value: Array<string | number>) => void;
  error?: string | null;
}

const LanguageDropdown = ({ value, handleChange, error }: Props) => {
  //query to fetch queued chats
  const {
    isLoading,
    error: fetchError,
    data,
    refetch,
  } = useQuery(
    ["botProfiles/getBrowserLanguageList"],
    async () => {
      const response = await getBrowserLanguageList();
      return response;
    },
    {
      staleTime: 600000,
      cacheTime: 600000,
    }
  );

  const languageOptions = useMemo(() => {
    if (data) {
      return data;
    }
    return [];
  }, [data]);

  const [selectedLanguage, setSelectedLanguage] = useState<Options[]>();

  useEffect(() => {
    if (value && Array.isArray(value) && languageOptions) {
      const selectedOptions = value
        .map((value) => {
          return languageOptions.find((option) => option.value == value);
        })
        .filter((item) => item !== undefined && item !== null);

      setSelectedLanguage(selectedOptions as Options[]);
    }
  }, [value, languageOptions]);

  return (
    <div className="mb-1">
      <CustomSelectDropdown
        selectedOption={selectedLanguage}
        setSelectedOption={(value) => {
          if (Array.isArray(value)) {
            handleChange(value.map((item) => item.value));
          } else {
            handleChange([value.value]);
          }
        }}
        options={languageOptions}
        menuClassName={`${styles.containDrop}`}
        placeholder="select language"
        isMultiSelect={true}
        isSearchable={true}
        isLoading={isLoading}
        error={selectedLanguage?.length ? undefined : error}
      />
      {error ? (
        <span className={`${styles.errorText} text-danger`}>{error}</span>
      ) : null}
    </div>
  );
};

export default LanguageDropdown;
