import { createAsyncThunk } from "@reduxjs/toolkit";
import { getValueFromConditionType } from "src/features/ReturnAutoWorkFlow/children/ConfigModals/store/returnModalsSliceHelpers";
import getNonEligibleItemsService, {
  NonEligibleItemsTab,
} from "src/services/ReturnAutoWorkFlow/OldModals/nonEligibleItems/getNonEligibleItems.service";
import getNonEligibleItemsConditionsService from "src/services/ReturnAutoWorkFlow/OldModals/nonEligibleItems/getNonEligibleItemsConditions.service";
import { updateNonEligibleItemsService } from "src/services/ReturnAutoWorkFlow/OldModals/nonEligibleItems/updateNonEligibleItems.service";
import { IStepQuestion } from "src/store/slices/initiateReturn/retrunExchange.slice";
import { RootState } from "src/store/store";
import { SelectedConditionType } from "../../../oldReturnModals.types";
import { ReturnModalsThunkPayload } from "../../returnModalsStore";
import { validateNonEligibleItems } from "./nonEligibleItems.slice";

export const fetchNonEligibleItemsThunk = createAsyncThunk(
  "fetchNonEligibleItems",
  async (payload: {
    integrationId: string;
    selectedReturnWindow: IStepQuestion["value"];
  }) => {
    const integrationId = payload.integrationId;

    const [conditionRes, configRes] = await Promise.all([
      getNonEligibleItemsConditionsService({ integrationId }),
      getNonEligibleItemsService({ integrationId }),
    ]);

    const defaultConditionOrderDate = {
      id: "",
      ruleType: "AND",
      variableName: "Order Date",
      values: payload.selectedReturnWindow,
      operator: "within",
    };

    return {
      conditionRes,
      configRes,
      defaultConditionOrderDate,
    };
  },
);

interface UpdateNonEligibleItemsPayload extends ReturnModalsThunkPayload {
  integrationId: string;
  selectedReturnWindow: IStepQuestion["value"];
}

export const updateNonEligibleItemsThunk = createAsyncThunk(
  "updateNonEligibleItems",
  async (payload: UpdateNonEligibleItemsPayload, { getState }) => {
    const state = getState() as RootState;

    const integrationId = payload.integrationId;
    const itemTypes = state.nonEligibleItems.itemTypes;

    const configTabs = itemTypes.map((itemType) => {
      const operatorSymbolMap = state.nonEligibleItems.operatorSymbolMap;

      return {
        configTabId: Number(itemType.itemTypeId),
        configTabName: itemType.itemTypeName,
        notEligibleResponseMessage: itemType.messageForCustomer.value,
        conditions: itemType.conditions.map((condition) => {
          const selectedVariable = state.nonEligibleItems.variableNames.find(
            (variable: any) => variable.name === condition.variableName,
          );

          if (selectedVariable) {
            return {
              conditionId: Number(condition.id),
              conditionTypeId: selectedVariable.id,

              conditionValue: getValueFromConditionType(condition.values),
              operator: Object.keys(operatorSymbolMap).find((key) => {
                if (
                  (condition.operator === "are less than" ||
                    condition.operator === "is less than") &&
                  key === "<"
                ) {
                  return true;
                }
                return operatorSymbolMap[key] === condition.operator;
              }),
            } as SelectedConditionType;
          } else {
            return {
              conditionId: Number(condition.id),
              conditionTypeId: -1,
            };
          }
        }),
      } as NonEligibleItemsTab;
    });

    await updateNonEligibleItemsService({ integrationId, configTabs });
    setTimeout(() => {
      if (payload.callback) {
        payload.callback();
      }
    }, 0);

    const configRes = await getNonEligibleItemsService({ integrationId }); // async action

    const defaultConditionOrderDate = {
      id: "",
      ruleType: "AND",
      variableName: "Order Date",
      values: payload.selectedReturnWindow,
      operator: "within",
    };

    return {
      configRes,
      defaultConditionOrderDate,
    };
  },
);

export const validateNonEligibleItemsThunk = createAsyncThunk(
  "validateNonEligibleItems",
  async (_: void, { getState, dispatch }) => {
    await dispatch(validateNonEligibleItems());

    const state = getState() as RootState;

    let errorCount = 0;
    state.nonEligibleItems.itemTypes.forEach((item) => {
      if (item.errorCount !== 0) {
        errorCount += 1;
      }
    });

    return errorCount;
  },
);
