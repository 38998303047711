import { useCallback, useMemo, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { MdDelete } from "react-icons/md";
import dots from "src/assets/images/dots.svg";
import DiscardAndSave from "src/components/AutomationComponents/DiscardAndSave";
import SkeletonModalStructure from "src/components/AutomationComponents/SkeletonModalStructure/SkeletonModalStructure";
import ConditionGroup from "src/components/AutomationComponents/TabSection/ConditionGroup";
import Loader from "src/components/Loader";
import { useReturnAutoWorkFlow } from "src/features/ReturnAutoWorkFlow/hooks/useReturnAutoWorkFlow";
import { v4 as uuid } from "uuid";
import AskQuestion from "../../OldModals/ReturnReasonOrderAndItems/children/AskQuestion/AskQuestion";
import { ReasonFollowUpQuestion } from "../../store/slices/ReturnReasonOrderAndItems/returnReasonOrderAndItems.slice";
import styles from "./FollowUpQuestionModal.module.scss";
import useFollowUpQuesModal, {
  handleValidateFollowUpQues,
} from "./useFollowUpQuesModal";

const FollowUpQuestionModal = ({
  onHide,
  onSave,
}: {
  onHide: () => void;
  onSave: () => void;
}) => {
  const {
    returnAutoWorkFlow: { integrationId },
  } = useReturnAutoWorkFlow();

  const {
    allReasons,
    allReasonIds,
    isError,
    isLoading,
    formElements,
    isSaving,
    updateQuestions,
    isSavingError,
    setAllFollowUpData,
    moreQuestions,
    setMoreQuestions,
  } = useFollowUpQuesModal(integrationId ?? "");

  const [showErrors, setShowErrors] = useState(false);

  const isAddButtonDisabled = useMemo(() => {
    return allReasonIds.some((id) => allReasons[id].reason.value.trim() === "");
  }, [allReasonIds, allReasons]);

  const onSubmit = useCallback(() => {
    if (showErrors) {
      return;
    }

    const payload = handleValidateFollowUpQues({ allReasons, allReasonIds });
    if (payload === null) {
      setShowErrors(true);
      return;
    }

    updateQuestions(payload);
    onSave();
  }, [allReasonIds, allReasons, onSave, showErrors, updateQuestions]);

  const reorderReasonsList = useCallback(
    (sourceIndex: number, destinationIndex: number) => {
      setAllFollowUpData((prev) => {
        const newReasonIds = [...prev.allReasonIds];
        const [removed] = newReasonIds.splice(sourceIndex, 1);
        newReasonIds.splice(destinationIndex, 0, removed);

        return {
          ...prev,
          allReasonIds: newReasonIds,
        };
      });
      setShowErrors(false);
    },
    [setAllFollowUpData],
  );

  const handleDeleteReason = useCallback(
    (index: number) => {
      setAllFollowUpData((prev) => {
        const newReasonIds = [...prev.allReasonIds];
        const newReasons = { ...prev.allReasons };

        const removedReasonId = newReasonIds.splice(index, 1);
        delete newReasons[removedReasonId[0]];

        return {
          ...prev,
          allReasonIds: newReasonIds,
          allReasons: newReasons,
        };
      });
      setShowErrors(false);
    },
    [setAllFollowUpData],
  );

  const updateReasonValue = useCallback(
    (index: number, val: string) => {
      setAllFollowUpData((prev) => {
        const reasonId = prev.allReasonIds[index];
        const updatedReasons = {
          ...prev.allReasons,
          [reasonId]: {
            ...prev.allReasons[reasonId],
            isNew: true,
            reason: {
              ...prev.allReasons[reasonId].reason,
              value: val,
            },
          },
        };

        return {
          ...prev,
          allReasons: updatedReasons,
        };
      });
      setShowErrors(false);
    },
    [setAllFollowUpData],
  );

  const handleAddReason = useCallback(() => {
    if (isAddButtonDisabled) {
      return;
    }

    const newId = uuid();
    const newReason = {
      id: newId,
      reason: {
        value: "",
        moreQuestions: [],
      },
      isNew: true,
    };

    setAllFollowUpData((prev) => {
      return {
        ...prev,
        allReasons: { ...prev.allReasons, [newId]: newReason },
        allReasonIds: [...prev.allReasonIds, newId],
      };
    });
    setShowErrors(false);
  }, [isAddButtonDisabled, setAllFollowUpData]);

  const saveMoreQuestionHandler = useCallback(
    (reasonId: string) => {
      setAllFollowUpData((prev) => {
        const updatedReasons = {
          ...prev.allReasons,
          [reasonId]: {
            ...prev.allReasons[reasonId],
            reason: {
              ...prev.allReasons[reasonId].reason,
              moreQuestions: moreQuestions,
            },
          },
        };

        return {
          ...prev,
          allReasons: updatedReasons,
        };
      });
      setShowErrors(false);
    },
    [moreQuestions, setAllFollowUpData],
  );

  const addMoreQuestionHandler = useCallback(() => {
    const newQuestion: ReasonFollowUpQuestion = {
      additionalOptions: [],
      elementId: uuid(),
      elementTypeId: formElements[0]?.elementId?.toString() ?? "",
      elementValue: "",
      isNewElement: true,
    };

    setMoreQuestions((prev) => [...prev, newQuestion]);
  }, [formElements]);

  const addMoreQuestionOptionHandler = useCallback(
    (option: string, questionId: string) => {
      setMoreQuestions((prev) =>
        prev.map((question) =>
          question.elementId === questionId
            ? {
                ...question,
                additionalOptions: [
                  ...(question.additionalOptions || []),
                  { id: uuid(), value: option, isNew: true },
                ],
              }
            : question,
        ),
      );
    },
    [],
  );

  const removeMoreQuestionHandler = useCallback((questionId: string) => {
    setMoreQuestions((prev) =>
      prev.filter((question) => question.elementId !== questionId),
    );
  }, []);

  const removeMoreQuestionOptionHandler = useCallback(
    (questionId: string, optionIndex: number) => {
      setMoreQuestions((prev) =>
        prev.map((question) =>
          question.elementId === questionId
            ? {
                ...question,
                additionalOptions: question.additionalOptions.filter(
                  (_, index) => index !== optionIndex,
                ),
              }
            : question,
        ),
      );
    },
    [],
  );

  const reorderMoreQuestionHandler = useCallback(
    (sourceIndex: number, destinationIndex: number) => {
      setMoreQuestions((prev) => {
        const updatedQuestions = [...prev];
        const [movedQuestion] = updatedQuestions.splice(sourceIndex, 1);
        updatedQuestions.splice(destinationIndex, 0, movedQuestion);
        return updatedQuestions;
      });
    },
    [],
  );

  const updateMoreQuestionOptionHandler = useCallback(
    (newValue: string, questionId: string, optionIndex: number) => {
      setMoreQuestions((prev) =>
        prev.map((question) =>
          question.elementId === questionId
            ? {
                ...question,
                additionalOptions: question.additionalOptions.map(
                  (option, index) =>
                    index === optionIndex
                      ? { ...option, value: newValue }
                      : option,
                ),
              }
            : question,
        ),
      );
    },
    [],
  );

  const updateMoreQuestionTypeHandler = useCallback(
    (
      questionId: string,
      type: string,
      isAdditionalOptions: boolean | undefined,
    ) => {
      setMoreQuestions((prev) =>
        prev.map((question) =>
          question.elementId === questionId
            ? {
                ...question,
                elementTypeId: type,
                additionalOptions: !isAdditionalOptions
                  ? []
                  : question.additionalOptions,
              }
            : question,
        ),
      );
    },
    [],
  );

  const updateMoreQuestionValueHandler = useCallback(
    (questionId: string, newValue: string) => {
      setMoreQuestions((prev) =>
        prev.map((question) =>
          question.elementId === questionId
            ? { ...question, elementValue: newValue }
            : question,
        ),
      );
    },
    [],
  );

  // Calculate duplicates and map to their index positions
  const duplicates = useMemo(() => {
    const valueCounts: Record<string, number> = {};
    const duplicateIndices: Record<number, boolean> = {};
    // Count occurrences of each value
    allReasonIds.forEach((id, index) => {
      const trimmedValue = allReasons[id].reason.value.trim();
      if (trimmedValue) {
        valueCounts[trimmedValue] = (valueCounts[trimmedValue] || 0) + 1;
      }
    });
    // Mark indices of duplicated items
    allReasonIds.forEach((id, index) => {
      if (valueCounts[allReasons[id].reason.value.trim()] > 1) {
        duplicateIndices[index] = true;
      }
    });

    return duplicateIndices;
  }, [allReasons, allReasonIds]);

  return (
    <div>
      <SkeletonModalStructure
        hasTwoSection={false}
        heading="Add follow-up questions to the list of return reasons."
        closeFunction={onHide}
      >
        {/* <div className={`${styles.mainContainer}`}> */}
        {isLoading && allReasonIds.length === 0 ? (
          <div className={`${styles.subContainer}`}>
            <Loader />
          </div>
        ) : isError ? (
          <div className={`${styles.subContainer} text-danger`}>
            Error Loading Content!
          </div>
        ) : (
          <div
            className={`shadow ${styles.contentStyles} pt-4 px-4 ${styles.noDiscardAndSave}`}
          >
            <ConditionGroup
              title="Add Return Reasons To Be Displayed To The Customer:"
              errorMessage={""}
              disableHide={true}
              initialShow={true}
            >
              <DragDropContext
                onDragEnd={(result) => {
                  if (!result.destination) {
                    return;
                  }
                  reorderReasonsList(
                    result.source.index,
                    result.destination.index,
                  );
                }}
              >
                <div className="mt-2">
                  <Droppable droppableId="droppable">
                    {(provided) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {allReasonIds.map((reasonId, idx) => {
                          const reason = allReasons[reasonId];

                          if (!reason) {
                            return null;
                          }

                          return (
                            <Draggable
                              key={reason.id}
                              draggableId={reason.id}
                              index={idx}
                            >
                              {(provided, snapshot) => (
                                <div
                                  className="d-flex align-items-center mb-2"
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div
                                    className={`px-3 py-3 mb-2 d-flex justify-content-between align-items-center w-100 ${styles.stringListBox}`}
                                  >
                                    <img
                                      src={dots}
                                      className={`pe-2`}
                                      alt=""
                                    />
                                    <div className="w-100 d-flex flex-column align-items-start">
                                      <ListInput
                                        showErrors={showErrors}
                                        value={reason.reason.value}
                                        onChange={(value) =>
                                          updateReasonValue(idx, value)
                                        }
                                        isDuplicate={duplicates[idx] == true}
                                      />
                                      {showErrors && duplicates[idx] == true ? (
                                        <div className={`${styles.errorText}`}>
                                          Please enter a unique reason
                                        </div>
                                      ) : null}
                                    </div>
                                    <MdDelete
                                      style={{
                                        color: "#707070",
                                        fontSize: "21px",
                                      }}
                                      className="ms-2"
                                      role="button"
                                      onClick={() => handleDeleteReason(idx)}
                                    />
                                  </div>
                                  <AskQuestion
                                    reason={reason}
                                    reasonTypeId={""}
                                    isFollowUpModal={true}
                                    followUpQuestions={moreQuestions}
                                    pushCurrentHash={() => {}}
                                    setMoreQuestionModalHandler={(ques) => {
                                      setMoreQuestions(ques);
                                    }}
                                    formElementOptions={formElements}
                                    addMoreQuestionHandler={
                                      addMoreQuestionHandler
                                    }
                                    addMoreQuestionOptionHandler={
                                      addMoreQuestionOptionHandler
                                    }
                                    removeMoreQuestionHandler={
                                      removeMoreQuestionHandler
                                    }
                                    removeMoreQuestionOptionHandler={
                                      removeMoreQuestionOptionHandler
                                    }
                                    reorderMoreQuestionHandler={
                                      reorderMoreQuestionHandler
                                    }
                                    saveMoreQuestionHandler={
                                      saveMoreQuestionHandler
                                    }
                                    updateMoreQuestionOptionHandler={
                                      updateMoreQuestionOptionHandler
                                    }
                                    updateMoreQuestionTypeHandler={
                                      updateMoreQuestionTypeHandler
                                    }
                                    updateMoreQuestionValueHandler={
                                      updateMoreQuestionValueHandler
                                    }
                                  />
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>

                  {/* Add Condition Button  */}
                  <div
                    className={`d-flex align-items-center pt-2 ${
                      isAddButtonDisabled ? styles.disable : ""
                    }`}
                    role="button"
                    onClick={handleAddReason}
                  >
                    <div className={`${styles.addBtn}`}>
                      <span>+</span>
                    </div>
                    <span className={`ps-2 ${styles.addText}`}>
                      Add more reason
                    </span>
                  </div>
                </div>
              </DragDropContext>
            </ConditionGroup>
          </div>
        )}
        <DiscardAndSave
          saveFailed={isSavingError}
          showError={showErrors}
          showLoading={isSaving}
          onDiscard={onHide}
          onSave={onSubmit}
        />
        {/* </div> */}
      </SkeletonModalStructure>
    </div>
  );
};

const ListInput = ({
  value,
  onChange,
  showErrors,
  isDuplicate,
  placeholder = "Enter value...",
}: {
  value: string;
  onChange: (val: string) => void;
  showErrors: boolean;
  isDuplicate: boolean;
  placeholder?: string;
}) => {
  const isError = useMemo(() => {
    return (!value.trim() || isDuplicate) && showErrors;
  }, [showErrors, value, isDuplicate]);

  return (
    <input
      type="text"
      placeholder={placeholder}
      className={`px-2 py-1 ${styles.stringListInput} ${
        isError ? "border-danger" : ""
      }`}
      value={value}
      onChange={(e) => {
        onChange(e.target.value);
      }}
    />
  );
};

export default FollowUpQuestionModal;
