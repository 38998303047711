import { axiosJSON } from "src/globals/axiosEndPoints";

export interface IDeleteReturnIntegrationParam {
  integrationId: number|string;
}
interface IDeleteReturnIntegrationResponse{
  err: boolean,
  msg: string
}
export async function deleteReturnIntegration(
  params: IDeleteReturnIntegrationParam
) {
  const { data } = await axiosJSON.delete(
    "/api/setting/automation/refund/delete",
    {params}
  );

  if (data.err === true) {
    throw new Error("Some Error Occurred while fetching data");
  }

  return data as IDeleteReturnIntegrationResponse;
}