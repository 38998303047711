import styles from "./PolicyMessage.module.scss";
import returnImg from "src/assets/images/ReturnImg.png";
import info from "src/assets/images/Info.svg";
import Lottie from "lottie-light-react";
import policyImg from "src/assets/images/PolicyImg.json";
import { useReturnPreviewState } from "../../../../../../hooks/useReturnPreview";
import TimeLine from "../Timeline/Timeline";
import ButtonWrapper from "../ButtonWrapper/ButtonWrapper";
import WidgetFooter from "../WidgetFooter/WidgetFooter";
import ParseHTML from "src/components/ParseHTML";
import useGetAutomationData from "src/features/ReturnAutoWorkFlow/hooks/useGetAutomationData";

const PolicyMessage = () => {
  const { selectedOrder, updateState, ...contextValues } =
    useReturnPreviewState();
  const { getReturnWindowData } = useGetAutomationData();
  const { day_type, message, returnWindow } = getReturnWindowData();
  const handleBack = () => {
    if (selectedOrder) {
      updateState({
        ...contextValues,
        selectedOrder: {
          ...selectedOrder,
          activeStep: "selectAction",
        },
      });
    }
  };

  return (
    <div>
      <div className={`${styles.widgetBody}`}>
        <TimeLine activeStep={selectedOrder?.activeStep ?? "selectOrder"} />
        <div className="mt-2 d-flex align-items-center">
          <img
            src={returnImg}
            alt=""
          />
          <span className={`ps-1 ${styles.heading}`}>
            Order Not Under Return/Exchange Policy
          </span>
        </div>
        <div className="text-center mt-5">
          <img
            src={info}
            alt=""
            height={30}
            width={30}
          />
          <h4 className={`mt-2 pt-1 mb-4 pb-3 ${styles.subHeading} d-none`}>
            We're sorry this order is already shipped we cannot initiate return
            or exchange for this order .
          </h4>
          {/* for atboard-17 ui  */}
          <h4 className={`mt-2 pt-1 mb-4 pb-3 ${styles.subHeading}`}>
            <ParseHTML html={message ?? ""} />
          </h4>
          <button
            className={`${styles.backBtn}`}
            id="goBackBtn"
            onClick={handleBack}
          >
            Go back
          </button>
          <span className={`mt-4 d-block ${styles.celebrateImg}`}>
            <Lottie animationData={policyImg}></Lottie>
          </span>
        </div>
      </div>
      <ButtonWrapper
        onBackClick={handleBack}
        onNextClick={() => {}}
      />
      <WidgetFooter />
    </div>
  );
};

export default PolicyMessage;
