import { axiosJSON } from "src/globals/axiosEndPoints";

export interface GetAllDiscountCodesParams {
  limit: number;
  startCursor?: string;
}

interface DiscountCodeData {
  title: string;
  code: string;
}

interface MetaData {
  hasNextPage: boolean;
  endCursor: string;
}

export interface GetAllDiscountCodes {
  data: DiscountCodeData[];
  metaData: MetaData;
}

export async function getAllDiscountCodes(payload: GetAllDiscountCodesParams) {
  const { data } = await axiosJSON.post(
    "/api/shopify/getAllDiscountCodes",
    payload
  );

  if (data.error === true) {
    throw new Error("Some Error Occurred while fetching data");
  }

  return { data: data.data, metaData: data.metaData };
}
