import {
  WhatsappAccount,
  deleteWhatsAppAccountService,
  subscribeWhatsappAccount,
} from "src/services/Integrations/WhatsappIntegration.service";
import styles from "./WhatsappRow.module.scss";
import whatsapp from "src/assets/images/whatsapp.png";
import { Spinner } from "react-bootstrap";
import proceed from "src/assets/images/proceed.png";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useCallback, useState } from "react";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import PhoneRegisterModal from "./children/PhoneRegisterModal/PhoneRegisterModal";
import DeleteModal from "../DisconnectIntegration/DeleteModal";

interface Props {
  account: WhatsappAccount;
}

function WhatsappRow({ account }: Props) {
  const subscribeWhatsapp = useMutation({
    mutationFn: subscribeWhatsappAccount,
  });

  const [enable, setEnable] = React.useState(account.integrated);
  const [registered, setRegistered] = React.useState(account.registered);
  const [showRegisterModal, setShowRegisterModal] = React.useState(false);
  const [error, setError] = React.useState("");
  const queryClient = useQueryClient();
  const [showModal, setShowModal] = useState(false);
  const [showDeleteLoading, setShowDeleteLoading] = useState(false);

  const handleShowModal = useCallback(() => {
    setShowModal(true);
  }, [setShowModal]);

  const deleteWhatsappAccount = useCallback(() => {
    setShowModal(false);
    setShowDeleteLoading(true);
    deleteWhatsAppAccountService({ integrationId: parseInt(account.id + "") })
      .then(() => {
        pushTheToast({
          type: "success",
          text: "Whatsapp Number deleted successfully!",
          position: "top-right",
        });
        // Update the query data directly
        queryClient.setQueryData<{
          accounts: Array<WhatsappAccount>;
          integrationId: number | string;
        }>(["getWhatsappAccounts"], (oldData) => {
          if (!oldData) {
            return oldData;
          }

          return {
            ...oldData,
            accounts: oldData.accounts.filter((business) => {
              if (business.id == account.id) {
                return false;
              }
              return true;
            }),
          };
        });
        setShowDeleteLoading(false);
      })
      .catch((err) => {
        pushTheToast({
          type: "danger",
          text: err.message,
          position: "top-right",
        });
        setShowDeleteLoading(false);
      });
  }, [queryClient, account.id]);

  const toggleIntegration = (pin?: string | number) => {
    if (!registered) {
      if (!pin || (pin + "").length === 0) {
        setError("PIN cannot be empty");
        return;
      } else if ((pin + "").length !== 6) {
        setError("PIN must be 6 digits");
        return;
      }
    }
    setEnable(!enable);

    subscribeWhatsapp.mutate(
      {
        integration_id: account.id,
        subscribe: !enable,
        pin: pin,
      },
      {
        onError: (err, variables) => {
          pushTheToast({
            type: "danger",
            text: `Failed to ${enable ? "disconnect" : "connect"} account`,
            position: "top-right",
          });
          setEnable(!variables.subscribe);
        },
        onSuccess: (res) => {
          setRegistered(true);
          setShowRegisterModal(false);
          pushTheToast({
            type: "success",
            text: `${enable ? "Disconnected" : "Connected"} successfully`,
            position: "top-right",
          });
          // Update the query data directly
          queryClient.setQueryData<{
            accounts: Array<WhatsappAccount>;
            integrationId: number | string;
          }>(["getWhatsappAccounts"], (oldData) => {
            if (!oldData) {
              return oldData;
            }

            return {
              ...oldData,
              accounts: oldData.accounts.map((business) => {
                if (business.id == account.id) {
                  business.registered = true;
                }
                return business;
              }),
            };
          });
        },
      },
    );
  };

  return (
    <div
      className={`d-flex justify-content-between align-items-center ${styles.rowDiv}`}
    >
      <div className={`d-flex align-items-center`}>
        <img
          className={`${styles.fbLogo}`}
          src={whatsapp}
        />
        <span className={`ps-3 ${styles.fbName}`}>
          {account.phoneNumbers.map((number) => {
            return `${number.number} (${number.name}) ` + ``;
          })}
        </span>
      </div>
      <div className={`d-flex align-items-center`}>
        <span className={`d-block me-3 ${styles.pageStatus}`}>
          {enable ? "Disconnect Account" : "Connect Account"}
        </span>
        {subscribeWhatsapp.isLoading && (
          <Spinner
            className="mx-1"
            animation="border"
            size="sm"
          />
        )}
        <div className="form-check form-switch me-3">
          <input
            data-lpignore="true"
            className={`form-check-input ${styles.formCheck}`}
            type="checkbox"
            checked={enable}
            onClick={
              registered
                ? () => toggleIntegration()
                : () => setShowRegisterModal(true)
            }
            disabled={subscribeWhatsapp.isLoading}
          />
        </div>
        {/* <img
          className={`${styles.proceedImg}`}
          src={proceed}
          onClick={() => {}}
        /> */}
        <div className="me-3 d-flex mt-0">
          <button
            className={`${styles.delBtn}`}
            onClick={handleShowModal}
          >
            <span className={`my-auto`}>Delete Integration</span>
            <span className={`me-1`}>
              {" "}
              {showDeleteLoading ? (
                <Spinner size="sm" />
              ) : (
                <i className="fa-solid fa-trash"></i>
              )}
            </span>
          </button>
        </div>
      </div>
      <PhoneRegisterModal
        show={showRegisterModal}
        handleClose={() => setShowRegisterModal(false)}
        error={error}
        handleRegister={toggleIntegration}
        isLoading={subscribeWhatsapp.isLoading}
      />
      <DeleteModal
        showModal={showModal}
        setShowModal={setShowModal}
        handleDelete={deleteWhatsappAccount}
        warningText={`This operation will delete ${account.phoneNumbers.map(
          (number) => {
            return `${number.number} (${number.name}) ` + ``;
          },
        )} number.`}
      />
    </div>
  );
}

export default WhatsappRow;
