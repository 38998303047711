import React, { useRef, useState } from "react";
import styles from "./LongTextBox.module.scss";


function LongTextBox({element, inputName}:any) {

  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const textInput = useRef<any>();
  const handleChange = (e: { target: { value: any } }) => {

    let value = e.target.value;
    // console.log(element.elementValue);
    if (value.length === 0) {
      setShowPlaceholder(true);
    }else{
      setShowPlaceholder(false);
    }
  };

  return (
    <div className="position-relative">
        <div className="form-group name-group" 
            >
         {showPlaceholder === true && (

          <div className={`${styles.placeholder}`} onClick={()=>{textInput.current.focus()}}>
            <label className={styles.inputLabel}>{element.elementValue}</label>
            {element.isRequired ? (<span className={`${styles.star} me-1 align-top`}>*</span>) : null}
          </div>
         )}
         <input
            type="text"
            ref={textInput}
            name={inputName}
            required={element.isRequired === true}
            // placeholder= {element.elementValue}
            className={` border-0 ${styles.textBox} ms-1 `}
            onChange={handleChange}
            onBlur={(e)=> {
              if (e.target.value.length === 0){
                setShowPlaceholder(true);
              }else{
                setShowPlaceholder(false)
              }}}
          />        
        </div>
      {/* {element.isRequired ? (
        <>
          {astric ? (
            <span
              className="text-danger position-absolute"
              style={{ transform: "translateX(0px)" }}
            >
              *
            </span>
          ) : null}

          <input
            type="text"
            name={inputName}
            required={element.isRequired === true}
            placeholder={element.elementValue}
            className={` border-0 ${styles.textBoxOutline} ms-1 ps-1`}
            onChange={handleChange}
          />
        </>
      ) : (
        <input
          type="text"
          name={inputName}
          required={element.isRequired === true}
          placeholder={element.elementValue}
          className={` border-0 ${styles.textBoxOutline} ms-1 `}
          onChange={handleChange}
        />
      )}*/}
      <span>
        <hr />
      </span> 
    </div>
  );
}

export default LongTextBox;
