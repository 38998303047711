import { Dropdown, Form } from "react-bootstrap";
import styles from "./SuggestionModal.module.scss";
import { useRef, useState } from "react";
import TinyMCEContainer from "src/components/TinyMCEContainer";
import UserAvatar from "src/components/UserAvatar";
interface Props {
  onHide: () => void;
}
const SuggestionModal = ({ onHide }: Props) => {
  const [ddOpen, setDdOpen] = useState(false);
  const dropdownArrowSpanRef = useRef<any>();
  const formRef: any = useRef(null);

  return (
    <div className={`${styles.modalWrapper}`}>
      <div className="d-flex justify-content-between align-items-center">
        <p className={`mb-0 ${styles.heading}`}>Agent suggestion</p>
        <span className={`${styles.closeModal}`} onClick={onHide}>
          <i className="fa-solid fa-xmark"></i>
        </span>
      </div>
      <div className={`${styles.modalContentWrap}`}>
      <div className="d-flex align-items-center mt-3 mb-3">
        <span className={`pe-1 ${styles.status}`}>Status :</span>
        <Dropdown onToggle={setDdOpen}>
          <Dropdown.Toggle
            id="dropdown-basic"
            as="div"
            bsPrefix={`dropdown-toggle  px-3 ${styles.dropBtn} ${styles.rejectBtn} ${styles.pendingBtn} `}
          >
            <span> Published </span>
            <span className="ps-1">
              {ddOpen ? (
                <>
                  {" "}
                  <i className="fa-solid fa-caret-up"></i>
                </>
              ) : (
                <>
                  {" "}
                  <i className="fa-solid fa-caret-down"></i>
                </>
              )}
            </span>
          </Dropdown.Toggle>

          <Dropdown.Menu bsPrefix={`dropdown-menu ${styles.dropMenu}`}>
            <Dropdown.Item
              href="#/action-1"
              as="div"
              bsPrefix={`dropdown-item ${styles.dropItem}`}
            >
              Published
            </Dropdown.Item>
            <Dropdown.Item
              href="#/action-1"
              as="div"
              bsPrefix={`dropdown-item ${styles.dropItem}`}
            >
              Reject
            </Dropdown.Item>
            <Dropdown.Item
              href="#/action-2"
              as="div"
              bsPrefix={`dropdown-item ${styles.dropItem}`}
            >
              Pending Approval
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className={`${styles.questionWrap} mb-3`}>
        <div className="d-flex">
          <p className={`${styles.questionText}`}>Q.</p>
          <div className="ms-1">
            <p className={`${styles.questionText}`}>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged.
            </p>
            <button className={`px-2 ${styles.editBtn}`}>
              <span>
                <i className="fa-regular fa-pen-to-square"></i>{" "}
              </span>
              <span className="ps-1">Edit question</span>
            </button>
            <div className="d-flex justify-content-between align-items-end d-none">
              <div className={`mb-2 w-75`}>
                <div
                  className={`${styles.sendMessage} ${styles.textareaBorder}`}
                  id="cannedMessage"
                >
                  <Form ref={formRef}>
                    <TinyMCEContainer
                      name="message"
                      className={`${styles.message}`}
                      // editorClassName={`${styles.editor}`}
                      value=""
                      onChange={() => {}}
                      options={{
                        placeholder:
                          // sendType === ESendTypes.Note
                          // ? `Use "#" to recall related templates`
                          "",
                        min_height: 100,
                        max_height: 100,
                      }}
                      uniqueID=""
                    />
                  </Form>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <button className={`me-2 ${styles.cancelBtn}`}>Cancel</button>
                <button className={`${styles.submitBtn}`}>Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`${styles.statusBox}`}>
        <div className="d-flex">
          <div>
            <UserAvatar name="NA" size={35} />
          </div>

          <div className="ms-2">
            <div className="d-flex align-items-center">
              <p className={`mb-0 ${styles.userText}`}>
                Carla Jones added answer suggestion
              </p>
              <div className={`ps-2 ${styles.time}`}>
                <span className="me-1">
                  <i className="fas fa-clock"></i>
                </span>
                <span>09/22/2023, 06:51 PM </span>
              </div>
            </div>

            <p>
              <span className={`${styles.status}`}>Status</span>
              <span className={`ps-2 ${styles.publishText}`}>Published</span>
              <span className={`ps-4 ${styles.msg}`}>
                <i className="fa-regular fa-message"></i>
                <span className={`ps-1 ${styles.msgCount}`}>1</span>
              </span>
            </p>
          </div>
        </div>
        <div>
          <p className={`${styles.questionText}`}>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged.
          </p>
          <p className={`${styles.questionText}`}>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged.
          </p>
          <p className={`${styles.questionText}`}>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged.
          </p>
          <button className={`px-2 ${styles.editDrafBtn}`}>
            <span>
              <i className="fa-regular fa-pen-to-square"></i>{" "}
            </span>
            <span className="ps-1">Edit draft</span>
          </button>
          <div className="d-flex justify-content-between align-items-end d-none">
            <div className={`mb-2 w-75`}>
              <div
                className={`${styles.sendMessage} ${styles.textareaBorder}`}
                id="cannedMessage"
              >
                <Form ref={formRef}>
                  <TinyMCEContainer
                    name="message"
                    className={`${styles.message}`}
                    // editorClassName={`${styles.editor}`}
                    value=""
                    onChange={() => {}}
                    options={{
                      placeholder:
                        // sendType === ESendTypes.Note
                        // ? `Use "#" to recall related templates`
                        "",
                      min_height: 100,
                      max_height: 100,
                    }}
                    uniqueID=""
                  />
                </Form>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <button className={`me-2 px-2 ${styles.cancelBtn}`}>Cancel</button>
              <button className={`px-2 ${styles.submitBtn}`}>Save</button>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default SuggestionModal;
