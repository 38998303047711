import styles from "./TrafficTable.module.scss";
import wait from "src/assets/images/hourglass.png";
import alert from "src/assets/images/exclamationMark.png";
import { NavLink } from "react-router-dom";
import { TabNamesLC } from "../TabSection";
import { useCallback, useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "src/store/store";
import TrafficItem from "./children/TrafficItem";
import InfiniteScroll from "src/components/InfiniteScroll";
import { Spinner } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import Loader from "src/components/Loader";
import {
  fetchLiveChatTrafficData,
  resetLiveChatTrafficData,
  resetLiveChatTrafficDataExceptTabCount,
  setTrafficFilterType,
  TRAFFIC_FILTER_TYPE,
} from "src/store/slices/liveChatTraffic/liveChatTraffic.slice";
import traffic_Empty from "src/assets/images/traffic_Empty.png";
import ChatNotFound from "src/components/ChatNotFound/ChatNotFound";

export const TrafficFilterTabNames: {
  [key: string]: { name: string };
} = {
  allCustomers: { name: "allCustomers" },
  chatting: { name: "chatting" },
  waitingForReply: { name: "waitingForReply" },
  queued: { name: "queued" },
  invited: { name: "invited" },
  browsing: { name: "browsing" },
};

const TrafficTable = () => {
  const tabName: any = window.location.pathname.split("/")[3];
  const dispatch = useAppDispatch();

  const {
    trafficItemList,
    trafficItemIdsList,
    fetchTrafficDataAjaxStatus,
    assignChatAjaxStatus,
    assignChatStatusCode,
    trafficTypeTabCount,
    metaData,
  } = useAppSelector((state) => state.liveChatTraffic);

  const currentState = useRef({
    trafficItemList,
    trafficItemIdsList,
    fetchTrafficDataAjaxStatus,
    metaData,
  });

  useEffect(() => {
    currentState.current = {
      trafficItemList,
      trafficItemIdsList,
      fetchTrafficDataAjaxStatus,
      metaData,
    };
  }, [
    trafficItemList,
    trafficItemIdsList,
    fetchTrafficDataAjaxStatus,
    metaData,
  ]);

  useEffect(() => {
    let currentTab: any = tabName;
    if (tabName === "waitingForReply") {
      currentTab = "waiting_for_reply";
    } else if (tabName === "allCustomers") {
      currentTab = "all";
    }
    dispatch(resetLiveChatTrafficDataExceptTabCount());
    // dispatch(resetLiveChatTrafficData());
    dispatch(setTrafficFilterType({ filterType: currentTab }));
    dispatch(fetchLiveChatTrafficData());
  }, [tabName]);

  const handleInfiniteScroll = useCallback(() => {
    if (
      currentState.current.metaData.total !== null &&
      currentState.current.fetchTrafficDataAjaxStatus === "fulfilled"
    ) {
      if (currentState.current.metaData.total > 0) {
        dispatch(fetchLiveChatTrafficData());
      }
    }
  }, [metaData.total]);

  return (
    <div className={`${styles.trafficMain}`}>
      <div className={`d-flex justify-content-between ${styles.filterMain}`}>
        <div>
          <NavLink
            to={`/live-chat/${TabNamesLC.traffic.name}/${TrafficFilterTabNames.allCustomers.name}`}
          >
            <span
              className={`${styles.trafficFilter} ${
                tabName === TrafficFilterTabNames.allCustomers.name
                  ? styles.activeFilter
                  : ""
              } `}
            >
              {" "}
              All customers(<span>{trafficTypeTabCount.all}</span>)
            </span>
          </NavLink>

          <NavLink
            to={`/live-chat/${TabNamesLC.traffic.name}/${TrafficFilterTabNames.chatting.name}`}
          >
            <span
              className={`${styles.trafficFilter} ${
                tabName === TrafficFilterTabNames.chatting.name
                  ? styles.activeFilter
                  : ""
              } `}
            >
              Chatting(<span>{trafficTypeTabCount.chatting}</span>)
            </span>
          </NavLink>

          <NavLink
            to={`/live-chat/${TabNamesLC.traffic.name}/${TrafficFilterTabNames.waitingForReply.name}`}
          >
            <span
              className={`${styles.trafficFilter} ${
                tabName === TrafficFilterTabNames.waitingForReply.name
                  ? styles.activeFilter
                  : ""
              }`}
            >
              Waiting for reply(
              <span>{trafficTypeTabCount.waitingForReply}</span>)
            </span>
          </NavLink>

          <NavLink
            to={`/live-chat/${TabNamesLC.traffic.name}/${TrafficFilterTabNames.queued.name}`}
          >
            <span
              className={`${styles.trafficFilter} ${
                tabName === TrafficFilterTabNames.queued.name
                  ? styles.activeFilter
                  : ""
              }`}
            >
              Queued(<span>{trafficTypeTabCount.queued}</span>)
            </span>
          </NavLink>

          {/* <NavLink
            to={`/live-chat/${TabNamesLC.traffic.name}/${TrafficFilterTabNames.invited.name}`}
          >
            <span
              className={`${styles.trafficFilter} ${
                tabName === TrafficFilterTabNames.invited.name
                  ? styles.activeFilter
                  : ""
              }`}
            >
              Invited(<span>0</span>)
            </span>
          </NavLink> */}

          {/* <NavLink
            to={`/live-chat/${TabNamesLC.traffic.name}/${TrafficFilterTabNames.browsing.name}`}
          >
            <span
              className={`${styles.trafficFilter} ${
                tabName === TrafficFilterTabNames.browsing.name
                  ? styles.activeFilter
                  : ""
              }`}
            >
              Browsing(<span>0</span>)
            </span>
          </NavLink> */}
        </div>
        <div>
          <span className={`${styles.moreOption}`}>
            <i className="fa-solid fa-ellipsis-vertical"></i>
          </span>
        </div>
      </div>
      <div className="px-2 pt-3 w-100 overflow-auto">
        <div className={`${styles.headerMain}`}>
          <div className={`ps-3 ${styles.widthSet}`}>
            <span className={`${styles.headerName}`}>Name</span>
          </div>
          <div className={`${styles.widthSet}`}>
            <span className={`${styles.headerName}`}>E-mail</span>
          </div>
          <div className={`${styles.widthSet}`}>
            <span className={`${styles.headerName}`}>Brand Name</span>
          </div>
          <div className={`${styles.widthSet} ${styles.mobWidth}`}>
            <span className={`${styles.headerName}`}>Actions</span>
          </div>
          <div className={`${styles.widthSet}`}>
            <span className={`${styles.headerName}`}>Activity</span>
          </div>
          <div className={`${styles.widthSet}`}>
            <span className={`${styles.headerName}`}>Chatting with</span>
          </div>
        </div>

        {assignChatAjaxStatus === "pending" ||
        (trafficItemIdsList.length === 0 &&
          fetchTrafficDataAjaxStatus === "pending") ? (
          <>
            <div className="d-flex justify-content-center w-100">
              <Loader />
            </div>
          </>
        ) : (fetchTrafficDataAjaxStatus === "fulfilled" ||
            fetchTrafficDataAjaxStatus === "rejected") &&
          trafficItemIdsList.length === 0 ? (
          <div className="d-flex justify-content-center w-100">
            <ChatNotFound
              text={"No Traffic Found"}
              subText={
                "All the traffic and their activity on your website appeare here"
              }
              img={traffic_Empty}
              className={`${styles.NoChatFound}`}
            />
          </div>
        ) : (
          <>
            <InfiniteScroll
              style={{ height: "calc(100vh - 200px)" }}
              className={`${styles.tableWrap}`}
              loadMore={handleInfiniteScroll}
              loader={
                fetchTrafficDataAjaxStatus === "pending" &&
                trafficItemIdsList.length === 0 ? (
                  <div className={`${styles.loading} m-auto`}>
                    <Spinner
                      animation="border"
                      variant="primary"
                      size="sm"
                      className={`d-block m-auto mb-2`}
                    />
                    <span>Loading...</span>
                  </div>
                ) : undefined
              }
              initialDataLoaded={true}
              hasMore={
                fetchTrafficDataAjaxStatus === "rejected"
                  ? false
                  : metaData.total === null
                  ? true
                  : trafficItemIdsList.length < metaData.total
              }
            >
              <div className="">
                {trafficItemIdsList.map((id, index) => {
                  return (
                    <TrafficItem
                      key={uuidv4()}
                      itemData={trafficItemList[id]}
                    />
                  );
                })}
              </div>
            </InfiniteScroll>
          </>
        )}
      </div>
    </div>
  );
};

export default TrafficTable;
