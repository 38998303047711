import styles from "./InnerChatShowImg.module.scss";
import { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import UserNActionHeader from "../UserNAction/UserNActionHeader";
import AxiosImg from "src/components/AxiosImg";
import DownloadFile from "src/components/DownloadFile";

const InnerChatShowImg = ({ attachment, messageData, setModalShow }: any) => {
  const [maximizeImg, setMaximizeImg] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const handleMinimizeImage = () => {
    setMaximizeImg(false);
    if (setModalShow) {
      setModalShow(false);
    }
  };
  const handleMaximizeImage = () => {
    setMaximizeImg(true);
    if (setModalShow) {
      setModalShow(true);
    }
  };
  return (
    <>
      <div className={`position-relative ${styles.mainDiv}`}>
        {/* <div className={`d-flex flex-row ${styles.cardDiv}`}>
          <AxiosImg
            isLoaded={imageLoaded}
            setIsLoaded={setImageLoaded}
            url={attachment.attachmentUrl}
            className={`${styles.image}`}
            isDirectURL={attachment?.isPublicAttachmentUrl}
          />
        </div> */}
        {/* Download part */}
        <div
          className={`${styles.downloadDiv}`}
          onClick={handleMaximizeImage}
        >
          <DownloadFile
            url={attachment.attachmentUrl}
            fileName={attachment.attachmentName}
            className={`btn p-0 m-3 ${styles.downloadBtn}`}
            loading={loading}
            setLoading={setLoading}
            stopPropagation={true}
          >
            <div className={` text-center ${styles.fileDownload}`}>
              {loading ? (
                <Spinner
                  className={`m-auto`}
                  animation="border"
                  size="sm"
                  color="grey"
                />
              ) : (
                <i
                  className={`fas fa-cloud-download-alt p-1 ${styles.downloadIcon}`}
                ></i>
              )}
            </div>
          </DownloadFile>
        </div>
        <p className={`mt-1 ${styles.filename}`}>{attachment.attachmentName}</p>
      </div>

      <Modal
        show={maximizeImg}
        onHide={handleMinimizeImage}
        dialogClassName={`${styles.modalDialog}`}
        contentClassName={`position-relative ${styles.modalContent}`}
      >
        <div className={`w-100 h-100 text-center`}>
          {/* Here checking if image is not loaded then block rendering and load only on model load. otherwise load it so that it will not be rendered again and again.*/}
          {maximizeImg || imageLoaded ? (
            <AxiosImg
              isLoaded={imageLoaded}
              setIsLoaded={setImageLoaded}
              url={attachment.attachmentUrl}
              className={`${styles.imgInModal}`}
              isDirectURL={attachment?.isPublicAttachmentUrl}
            />
          ) : (
            <></>
          )}
        </div>
        <UserNActionHeader
          attachment={attachment}
          messageData={messageData}
          className={styles.modalHover}
        />
      </Modal>
    </>
  );
};

export default InnerChatShowImg;
