import React, {  useMemo, useCallback } from "react";
import { bugManagementCurrentStatusActions, useBugManagementFilters } from "../../../Hooks/useBugManagementFilter";
import FilterSelect from "../FilterSelect/FilterSelect";
import useBugCategoryList from "./useBugCategoryList";
import useBugPriorityList from "../Priority/useBugPriorityList";
import useBugStatusList from "../StatusFilter/useBugStatusList";

interface Props {
  addFilters: (
    selectedStatus: Array<string | undefined>,
    selectedPriority: Array<string | undefined>,
    selectedCategory: Array<string>,
  ) => void;
}

const BugCategory = ({ addFilters }: Props) => {
  const {
    isError,
    nextPageLoad,
    bugCategories,
    bugCategoriesIds,
    hasNextPage,
    fetchStatus,
  } = useBugCategoryList();

  // get dynamic bug priority and status from the backend database
  const { allBugPriority } = useBugPriorityList();
  const { allBugStatus } = useBugStatusList();

  // get current selected filter ids
  const { currentStatus, dispatch } = useBugManagementFilters();

  const selectedStatus = useMemo(() => {
    return currentStatus.status.map((id) => allBugStatus?.allBugStatus[id].key);
  }, [currentStatus.status]);

  const selectedPriority = useMemo(() => {
    return currentStatus.priority.map(
      (id) => allBugPriority?.allBugPriority[id].key,
    );
  }, [currentStatus.priority]);

  // fucntion to add selected value to useContenxt
  const selectedCategory = useMemo(() => {
    return currentStatus.bugCategory;
  }, [currentStatus]);

  const checkUncheckCategory = useCallback(
    (e: any) => {
      if (e.target.checked) {
        dispatch([
          bugManagementCurrentStatusActions.setFilters,
          { bugCategory: [...selectedCategory, e.target.value] },
        ]);
      } else {
        dispatch([
          bugManagementCurrentStatusActions.setFilters,
          {
            bugCategory: selectedCategory.filter((id) => id !== e.target.value),
          },
        ]);
      }
    },
    [selectedCategory],
  );

  return (
    <FilterSelect
      filterName={"Bug category"}
      allFilters={bugCategories}
      allFilterIds={bugCategoriesIds}
      selectedFilters={selectedCategory}
      checkUncheckFilters={checkUncheckCategory}
      applyFilters={() =>
        addFilters(selectedStatus, selectedPriority, currentStatus.bugCategory)
      }
      cancelFilters={() => {}}
      nextPageLoad={nextPageLoad}
      isError={isError}
      hasNextPage={hasNextPage}
      fetchStatus={fetchStatus}
    />
  );
};

export default BugCategory;
