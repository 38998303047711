import { axiosJSON } from "src/globals/axiosEndPoints";

export async function getDraftMessagesApi(servicePayload: {
  ticketId: number;
}) {
  const { data } = await axiosJSON.post(
    `/api/ticket/getAllDraftMsgs`,
    servicePayload
  );
  if (data.err === true) {
    throw new Error(data.msg as string);
  }
  return {
    ...data.data,
    draftMsg:
      Object.keys(data.data.draftMsg).length > 0 ? data.data.draftMsg : null,
  };
}

export async function getDraftUnsentMessagesApi(servicePayload: {
  ticketId: number;
  start: number;
  limit: number;
}) {
  const { data } = await axiosJSON.post(
    `/api/ticket/getUnsentDraftMsgs`,
    servicePayload
  );
  if (data.err === true) {
    throw new Error(data.msg as string);
  }
  return {
    unsentDraftMsg: data.data.unsentDraftMsgs,
    metaData: data.metaData,
  };
}

export async function getDraftMsgRemarksApi(servicePayload: {
  msgId: number;
  start: number;
  limit: number;
  sort: "desc" | "asc";
}) {
  const { data } = await axiosJSON.post(
    `/api/ticket/getDraftMsgRemarks`,
    servicePayload
  );
  if (data.err === true) {
    throw new Error(data.msg as string);
  }
  const draftRemarks: any = {};
  const draftRemarkIds: any = [];
  data.data.draftMsgRemarks.forEach((remark: any) => {
    draftRemarks[remark.remarkId] = remark;
    draftRemarkIds.push(remark.remarkId);
  });
  return {
    draftRemarks,
    draftRemarkIds,
    metaData: data.metaData,
  };
}

export async function getDraftMsgHistoryApi(servicePayload: {
  msgId: number;
  historyNum: number;
  threshold: number;
}) {
  const { data } = await axiosJSON.post(
    `/api/ticket/getDraftMsgHistory`,
    servicePayload
  );
  if (data.err === true) {
    throw new Error(data.msg as string);
  }
  return {
    draftMsgHistory: data.data.draftMsgHistory,
    metaData: data.metaData,
  };
}
