import AxiosImg from "src/components/AxiosImg";
import styles from "./UserListItem.module.scss";
import UserAvatar from "src/components/UserAvatar";

interface Props {
  user: any;
  userSelected: any;
  onSelect: any;
}

const UserListItem = ({ user, userSelected, onSelect }: Props) => {
  const handleOnClick = (e: any) => {
    onSelect(user);
  };
  return (
    <div
      className={`py-1 mt-1 ${
        userSelected === user.id ? styles.addBoxSelected : styles.addBox
      }`}
      onClick={handleOnClick}
    >
      <span>
        <span className={`me-2 ${styles.userImg}`}>
          {user?.imageUrl && user.imageUrl.trim().length !== 0 ? (
            <AxiosImg
              className={`${styles.profileImg}`}
              url={user.imageUrl}
              isDirectURL={user?.isPublicAttachmentUrl}
            />
          ) : (
            <UserAvatar name={user.name || "NA"} size={21} />
          )}
        </span>
        <span className={`${styles.assignName}`}>{user.name}</span>
      </span>
    </div>
  );
};

export default UserListItem;
