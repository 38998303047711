import ParseHTML from "src/components/ParseHTML";
import styles from "./OrderTimelineMap.module.scss";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
function OrderTimelineMap({
  timeline: [title, time],
}: {
  timeline: Array<string>;
}) {
  return (
    <>
      <li className="d-flex flex-row mt-4 justify-content-between">
        <div className="fst-normal text-truncate w-75">
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip >
                <div className={` ${styles.toolTipCustom}`}>
                  <ParseHTML html={title}></ParseHTML>
                </div>
              </Tooltip>
            }
          >
            {({ ref, ...triggerHandler }) => (
              <p
                {...triggerHandler}
                ref={ref}
                className={`cursor-pointer ${styles.orderDetailText}`}
              >
                <ParseHTML html={title}></ParseHTML>
              </p>
            )}
          </OverlayTrigger>
        </div>
        <div>
          {new Date(time).toLocaleDateString("en-us", {
            weekday: "long",
            year: "numeric",
            month: "short",
            day: "numeric",
          })}
        </div>
      </li>
    </>
  );
}

export default OrderTimelineMap;
