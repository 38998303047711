import { axiosJSON } from "src/globals/axiosEndPoints";

export interface step7Params {
  step_data: {
    selected_auto_type:
      | "fully_automate"
      | "partially_automate"
      | "no_automation";
  };
  integrationId: number;
}

export async function fetchPostStep7(payload: step7Params) {
  const { data } = await axiosJSON.post(
    "/api/setting/automation/refund/step/7",
    payload
  );

  // const data = await postStep7;
  if (data.error === true) {
    throw new Error("Some Error Occurred while fetching data");
  }

  return data;
}

// let postStep7 = function (params: step7Params) {
//   return new Promise(function (resolve, reject) {
//     if (params) {
//       setTimeout(function () {
//         resolve({});
//       }, 2000);
//     } else {
//       reject(new Error("Something is not right!"));
//     }
//   });
// };
