import { useCallback, useState, useRef } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { forgotPassword } from "src/services/Auth/forgotPassword";
import { validateEmail } from "src/utils/utils";
import styles from "./EnterEmail.module.scss";

const EnterEmail = ({ showModal, setShowModal, emailF, setEmailF }: any) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleOnHide = () => {
    setShowModal("");
  };

  const handleSendClick = (e: any) => {
    e.preventDefault();
    setLoading(true);
    if (validateEmail(emailF.trim())) {
      forgotPassword({
        email: emailF.trim(),
      })
        .then((res: any) => {
          setShowModal("forgotEmailSendSuccess");
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          pushTheToast({
            type: "danger",
            text: err.message,
            position: "top-right",
          });
        });
    } else {
      setLoading(false);
      pushTheToast({
        type: "danger",
        text: "Not a valid email!",
        position: "top-right",
      });
    }
  };

  return (
    <Modal
      show={showModal === "modalEnterRegEmail"}
      onHide={handleOnHide}
      dialogClassName={`${styles.modalDialog}`}
      contentClassName={`${styles.modalContent}`}
      centered
      id="forgot_modal"
    >
      <div className={`${styles.forgotPassword}`}>
        <div>
          <div className={`${styles.modalDialogInner}`}>
            <div
              className={`${styles.modalContentInner}`}
              id="forgot_modal__container"
            >
              <div
                className={`${styles.modalHeader}`}
                id="forgot_modal__header"
              >
                <h5 className={`${styles.modalTitle}`}>Forgot Password?</h5>
              </div>
              <div
                className={`${styles.modalBodyInner}`}
                id="forgot_modal__emailId"
              >
                <label className={`${styles.mailLabel}`}>
                  Please enter your registered email address
                </label>
                <input
                  type="email"
                  data-lpignore="true"
                  className={`form-control ${styles.inputEmail}`}
                  value={emailF}
                  id="email"
                  placeholder="Email address"
                  name="email"
                  onChange={(e) => {
                    setEmailF(e.target.value);
                  }}
                />
              </div>
              <div className={`${styles.horizontalSeparator}`}></div>

              <div
                className={`${styles.modalFooterInner}`}
                onClick={(e) => {
                  handleSendClick(e);
                }}
                id="forgot_modal__sendBtn"
              >
                <p className={`text-center my-2 ${styles.sendBtn}`}>
                  {loading && (
                    <Spinner
                      className={`mx-1 my-auto`}
                      animation="border"
                      color="white"
                      size="sm"
                    />
                  )}
                  <span>Send</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EnterEmail;
