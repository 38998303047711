import { useAppDispatch, useAppSelector } from "src/store/store";
import styles from "./FilterList.module.scss";
import { v4 as uuidv4 } from "uuid";
import { useState, useMemo, useContext, useRef, useEffect } from "react";
import {
  filtersValidatedSelector,
  getTotalActiveFiltersAppliedCountSelector,
  getTotalFiltersAppliedCountSelector,
  resetFilters,
  resetOnlyOtherFilters,
  selectFilterSearchValue,
  setActiveFiltersToFilters,
  setFiltersToActiveFilters,
  setSearchFilterColsDataValue,
  validateFilters,
} from "src/store/slices/ticketFilters/ticketFilters.slice";
import FilterColDropdown from "../FilterColDropdown";
import {
  resetCachedTickets,
  resetPagination,
} from "src/store/slices/tickets/tickets.slice";
import { FilterToggleStateContext } from "../../Filter";

function FilterList({ containerTarget }: any) {
  const dispatch = useAppDispatch();
  const [showFilterError, setShowFilterError] = useState(false);
  const { toggleState, setToggleState } = useContext(FilterToggleStateContext);
  const filterCount = useAppSelector(getTotalFiltersAppliedCountSelector);
  const activeFilterCount = useAppSelector(
    getTotalActiveFiltersAppliedCountSelector
  );
  const errors = useAppSelector((state) => state.ticketFilter.filterErrors);

  const searchFilterColsDataValue = useAppSelector(selectFilterSearchValue);

  const filtersColData = useAppSelector(
    (state) => state.ticketFilter.filtersColData
  );

  const filtersValidated = useAppSelector(filtersValidatedSelector);

  const dateTimeInputRefs: any = {
    ticket_date_gmt: useRef<HTMLDivElement>(null),
    ticket_closed_date_gmt: useRef<HTMLDivElement>(null),
    not_replied_since: useRef<HTMLDivElement>(null),
    ticket_modified_gmt: useRef<HTMLDivElement>(null),
  };

  const handleOnClickCancel = (e: any) => {
    e.preventDefault();
    dispatch(setActiveFiltersToFilters());
    setToggleState(!toggleState);
  };

  const handleOnClickApply = () => {
    // e.preventDefault();
    // log.debug("Applying local filters...");
    dispatch(validateFilters());
    if (filtersValidated === true) {
      setShowFilterError(false);
      dispatch(setFiltersToActiveFilters());
    } else {
      setShowFilterError(true);
    }
  };

  const handleSearchInputChange = (e: any) => {
    // e.preventDefault();
    dispatch(setSearchFilterColsDataValue(e.target.value.trim()));
  };

  const dropdownRef = useRef(null as any);

  const getSearchFilteredCols = useMemo(() => {
    let filteredCols = filtersColData;
    if (searchFilterColsDataValue.length !== 0 && filtersColData.length !== 0) {
      filteredCols = filtersColData.filter((cols) =>
        cols.name
          .toLowerCase()
          .includes(searchFilterColsDataValue.trim().toLowerCase())
      );
    }
    if (filteredCols.length !== 0) {
      return filteredCols.map((col) =>
        col.key ? (
          <FilterColDropdown
            key={uuidv4()}
            filterCol={col}
            containerTarget={containerTarget}
            colRef={dateTimeInputRefs[col.key]}
          />
        ) : (
          <></>
        )
      );
    }
    return <></>;
  }, [searchFilterColsDataValue, filtersColData, containerTarget]);

  const handleOnClickClearAll = () => {
    if (activeFilterCount) {
      dispatch(resetOnlyOtherFilters());
      dispatch(resetPagination());
      dispatch(resetCachedTickets());
    } else if (filterCount) {
      dispatch(
        resetFilters({
          removeFilterByLastUpdated: false,
          resetActiveFilters: false,
        })
      );
    }
  };

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      if (
        errors["filterByCreatedDateValue"] !== undefined &&
        errors["filterByCreatedDateValue"].showError === true
      ) {
        dateTimeInputRefs.ticket_date_gmt.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      } else if (
        errors["filterByClosedDateValue"] !== undefined &&
        errors["filterByClosedDateValue"].showError === true
      ) {
        dateTimeInputRefs.ticket_closed_date_gmt.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      } else if (
        errors["filterByNotRepliedSinceValue"] !== undefined &&
        errors["filterByNotRepliedSinceValue"].showError === true
      ) {
        dateTimeInputRefs.not_replied_since.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      } else if (
        errors["filterByLastUpdatedValue"] !== undefined &&
        errors["filterByLastUpdatedValue"].showError === true
      ) {
        dateTimeInputRefs.ticket_modified_gmt.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  }, [errors]);

  return (
    <>
      <ul
        className={`list-unstyled mb-0 ${styles.coloumn_dropdown}`}
        aria-labelledby="table"
        ref={dropdownRef}
      >
        <>
          <li className={`${styles.table__li}`}>
            <div className="d-flex">
              <div
                role="button"
                className={`w-35 ${styles.restoreCols}`}
                onClick={handleOnClickClearAll}
              >
                Clear all filters
              </div>
              {showFilterError === true && (
                <div
                  className="text-danger ms-3"
                  style={{ fontSize: "smaller" }}
                >
                  *Please fill all the details correctly
                </div>
              )}
            </div>
          </li>
          <li>
            <div className={`w-100 d-flex my-1`} id="searchfilters">
              <span className={`${styles.searchTicket2} w-0`}>
                <i className={`fas fa-search`} />
              </span>
              <input
                data-lpignore="true"
                type="text"
                className={`${styles["col-search"]} w-100`}
                placeholder="Search"
                value={searchFilterColsDataValue}
                onChange={handleSearchInputChange}
              />
            </div>
          </li>
          {getSearchFilteredCols}
        </>
      </ul>
      <div
        className={`d-flex justify-content-between pb-2 pt-3 ${styles.bottomContainer}`}
      >
        <div
          role="button"
          className={`px-3 py-1 ${styles.cancelBtn}`}
          onClick={handleOnClickCancel}
          id="filterCancelBtn"
        >
          Cancel
        </div>

        <div
          role="button"
          className={`px-3 py-1 ${styles.applyBtn}`}
          onClick={handleOnClickApply}
          id="filterApplyBtn"
        >
          Apply
        </div>
      </div>
    </>
  );
}

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const FilterHeading = ({ headingText }: any) => (
  <div className={`mt-3`}>
    <label>{headingText}</label>
  </div>
);

export default FilterList;
