import styles from "./BaseFooterButtonLight.module.scss";

function BaseFooterButtonLight(props: any) {
  return (
    <button className={`px-3 ${styles.addexit}`} {...props}>
      {props.children}
    </button>
  );
}

export default BaseFooterButtonLight;
