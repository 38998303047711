import styles from "./SnoozeModal.module.scss";
import { Modal } from "react-bootstrap";
import { useCallback, useEffect, useState } from "react";
import SelectModal from "./children/SelectModal";
import { createSnooze, KeyExpiresAt } from "src/services/Snooze/createSnooze";
import { getSnooze, ISnoozeData } from "src/services/Snooze/getSnooze";
import Snoozed from "./children/Snoozed";
import Loader from "../Loader";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { deleteSnooze } from "src/services/Snooze/deleteSnooze";
import { updateSnooze } from "src/services/Snooze/updateSnooze";
import { useSnoozeOptions } from "src/hooks/snoozeModal/useSnoozeOptions";
import {
  checkExpireTimeIsInPastAndDiff,
  getDateTimeWithTimeZoneOffset,
  getTimeFromNow,
} from "src/utils/dateLibrary";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  fetchMessagesUpdates,
  fetchSnooze,
  resetSnoozeData,
} from "src/store/slices/innerTicket/innerTicket.slice";

export interface SnoozeProps {
  show: boolean;
  onShow: () => void;
  onHide: () => void;
  ticketId: number | string;
  snoozeData: ISnoozeData | null;
  setSnoozeData: (snoozeData: ISnoozeData | null) => void;
  handelOnPending: (e: any) => void;
}

function SnoozeModal({
  show,
  onShow,
  onHide,
  ticketId,
  snoozeData,
  setSnoozeData,
  handelOnPending,
}: SnoozeProps) {
  const dispatch = useAppDispatch();
  const [currentSection, setCurrentSection] = useState(
    "select" as "select" | "snooze" | "loading",
  );
  const { snoozeOptions, optionsLoading, refreshSnoozeOptions } =
    useSnoozeOptions();

  const userTimezone = useAppSelector(
    (state) => state.globals.currentUserData?.userTimezone,
  );

  const activeSnooze = useAppSelector(
    (state) => state.innerTicket.activeSnooze,
  );

  const [reOpenStr, setReOpenStr] = useState(null as string | null);
  // const getTheSnoozeData = useCallback(
  //   (callback?: () => void) => {
  //     getSnooze({
  //       ticketId: parseInt(ticketId + ""),
  //     })
  //       .then((res) => {
  //         setSnoozeData(res);
  //         if (callback) {
  //           callback();
  //         }
  //       })
  //       .catch((err) => {
  //         setSnoozeData(null);
  //         if (callback) {
  //           callback();
  //         }
  //       });
  //   },
  //   [ticketId]
  // );

  useEffect(() => {
    if (activeSnooze.snoozeDataAjaxStatus === "fulfilled") {
      if (
        activeSnooze.snoozeData.id != 0 &&
        activeSnooze.snoozeData.ticketId != 0
      ) {
        setSnoozeData(activeSnooze.snoozeData);
      } else {
        setSnoozeData(null);
      }
    } else {
      setSnoozeData(null);
    }
  }, [activeSnooze]);

  const onSnooze = useCallback(
    (key: KeyExpiresAt, dateTime?: string) => {
      setCurrentSection("loading");
      if (snoozeData === null) {
        createSnooze({
          ticketId: parseInt(ticketId + ""),
          expireAt: key,
          expireAtValue: dateTime,
        })
          .then((res) => {
            dispatch(resetSnoozeData());
            dispatch(
              fetchSnooze({
                callback: () => {
                  setCurrentSection("snooze");
                },
              }),
            );
          })
          .catch((err) => {
            pushTheToast({
              type: "danger",
              text: err.message,
              position: "top-right",
            });
            setCurrentSection("select");
          });
      } else {
        updateSnooze({
          // ticketId: parseInt(ticketId + ""),
          id: snoozeData.id,
          expireAt: key,
          expireAtValue: dateTime,
        })
          .then((res) => {
            dispatch(resetSnoozeData());
            dispatch(
              fetchSnooze({
                callback: () => {
                  setCurrentSection("snooze");
                },
              }),
            );
          })
          .catch((err) => {
            pushTheToast({
              type: "danger",
              text: err.message,
              position: "top-right",
            });
            setCurrentSection("select");
          });
      }
    },
    [ticketId, show, snoozeData],
  );
  const onRemoveSnooze = useCallback(() => {
    if (snoozeData !== null) {
      setCurrentSection("loading");
      deleteSnooze({ id: snoozeData.id })
        .then((res) => {
          setSnoozeData(null);
          dispatch(resetSnoozeData());
          setCurrentSection("select");
        })
        .catch((err) => {
          setCurrentSection("select");
          pushTheToast({
            type: "danger",
            text: err.message,
            position: "top-right",
          });
        });
    }
  }, [snoozeData]);

  useEffect(() => {
    dispatch(resetSnoozeData());
    dispatch(fetchSnooze({}));
  }, [ticketId]);

  useEffect(() => {
    setCurrentSection("select");
  }, [show]);

  useEffect(() => {
    let ts: any = null;
    let remainingTimeToReopen: any = null;
    if (snoozeData !== null) {
      if (snoozeData.tillCustomerReplies === 1) {
        setReOpenStr("Reopens when customer will reply");
      } else if (snoozeData.expireAtGmt) {
        //using gmt expire at time to check for checking the expire time difference
        remainingTimeToReopen = checkExpireTimeIsInPastAndDiff(
          snoozeData.expireAtGmt + "Z",
          userTimezone,
        );
        setReOpenStr(`Reopens in ${remainingTimeToReopen}`);
        /*setReOpenStr(
          `Reopens in ${getTimeFromNow({
            date: new Date(snoozeData.expireAt),
            need: "date",
            showJustNow: false,
            // timeZone: tenantTimezone,
            timeZone: userTimezone,
            showAgo: false
          })}`
        );*/
        ts = setInterval(() => {
          //using gmt expire at time to check for checking the expire time difference
          remainingTimeToReopen = checkExpireTimeIsInPastAndDiff(
            snoozeData.expireAtGmt + "Z",
            userTimezone,
          );

          setReOpenStr(`Reopens in ${remainingTimeToReopen}`);
          // if snooze expired
          if (remainingTimeToReopen === `0 sec`) {
            clearInterval(ts);
            dispatch(fetchMessagesUpdates());
          }
          /*setReOpenStr(
            `Reopens in ${getTimeFromNow({
              date: new Date(snoozeData.expireAt),
              need: "date",
              showJustNow: false,
              // timeZone: tenantTimezone,
              timeZone: userTimezone,
              showAgo: false
            })}`
          );*/
        }, 30000);
      }
    } else {
      // setReOpenStr(null);
    }
    return () => {
      clearInterval(ts);
    };
  }, [snoozeData, userTimezone]);

  return (
    <div>
      <Modal
        backdropClassName={`${styles.modalBack}`}
        show={show}
        onHide={onHide}
        dialogClassName={`${styles.modalDialog}`}
        contentClassName={`${styles.modalContent}`}
        centered={true}
        enforceFocus={false}
      >
        {currentSection === "loading" || optionsLoading ? (
          <div className="pb-5">
            <Loader />
          </div>
        ) : currentSection === "select" ? (
          <SelectModal
            onHide={onHide}
            onRemoveSnooze={onRemoveSnooze}
            onSnooze={onSnooze}
            snoozeData={snoozeData}
            snoozeOptions={snoozeOptions}
            reOpenStr={reOpenStr}
            userTimezone={userTimezone}
          />
        ) : currentSection === "snooze" && reOpenStr !== null ? (
          <Snoozed
            onHide={onHide}
            reOpenStr={reOpenStr}
            handelOnPending={handelOnPending}
          />
        ) : (
          <div>Something went wrong!</div>
        )}
      </Modal>
    </div>
  );
}

export default SnoozeModal;
