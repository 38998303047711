import { axiosJSON } from "src/globals/axiosEndPoints";
import { Segment } from "./getSegmentTypes.service";

/**
 * Parameters required to fetch custom segments.
 */
interface GetCustomSegmentParams {
  segmentType: string;
  segmentIds?: Array<string>;
  start?: number;
  limit?: number;
}

/**
 * Represents the normalized structure of all custom segments.
 */
export interface AllCustomSegments {
  allCustomSegments: Record<string, Segment>;
  allCustomSegmentIds: string[];
}

/**
 * Fetches the custom segments from the server.
 *
 * @param params - The parameters including segment type, start index, and limit.
 * @returns A promise that resolves to the normalized custom segments data.
 * @throws Will throw an error if the server response contains an error.
 */
const getCustomSegmentsService = async ({
  segmentType,
  ...params
}: GetCustomSegmentParams): Promise<AllCustomSegments> => {
  // `/api/segment/${payload.segmentType}/getUserSidebarData`
  const { data: res } = await axiosJSON.post(
    `/api/segment/${segmentType}/getCustomSegments`,
    params,
  );

  if (res.error || res.err) {
    throw new Error(res.msg ?? res.message ?? "Something Unexpected Occurred!");
  }

  return normalizeData(res.data as Array<Segment>);
};

/**
 * Normalizes the custom segments data to a structured format.
 *
 * @param data - The array of custom segments.
 * @returns The normalized custom segments data.
 */
const normalizeData = (data: Array<Segment>): AllCustomSegments => {
  const ret: AllCustomSegments = {
    allCustomSegments: {},
    allCustomSegmentIds: [],
  };

  for (const value of data) {
    value.segmentId = value.segmentId + "";
    ret.allCustomSegments[value.segmentId] = value;
    ret.allCustomSegmentIds.push(value.segmentId);
  }

  return ret;
};

export default getCustomSegmentsService;
