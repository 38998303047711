import { axiosJSON } from "src/globals/axiosEndPoints";
import {
  conditionType,
  GetOptionsConditionsParam,
} from "../Step9/step9GetOptionsConditions";

export async function fetchGetStep13ConditionsOptions(
  params: GetOptionsConditionsParam,
) {
  const { data } = await axiosJSON.get(
    "/api/setting/automation/refund/step/13/conditionOptions",
    { params },
  );

  if (data.err === true) {
    throw new Error("Some Error Occurred while fetching data");
  }
  // console.log("fetchGetStep13ConditionsOptions :: data.data ");
  // console.log(data.data);

  return {
    conditions: (data.data?.conditions ?? []) as conditionType[],
    // itemConditions: data.data?.itemConditions,
    defaultConditions: (data.data?.defaultConditions ?? []) as conditionType[],
  };
}
