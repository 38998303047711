import { axiosJSON } from "src/globals/axiosEndPoints";

interface OrderTotal {
  amount: number;
  currencyCode: string;
}

interface Order {
  orderId: number;
  orderName: string;
  createdAt: Date;
  customerName: string;
  orderTotal: OrderTotal;
  paymentStatus: string;
  fulfillmentStatus: string;
  items: number;
  deliveryMethod: null;
  tags: [];
}

interface Customer {
  name: string;
  email: string;
  phone: string;
}

export interface GetAllOrder {
  customer: Customer;
  orders: Array<Order>;
  total: number;
  count: number;
  error: boolean;
}

export interface GetAllOrderParams {
  customerId: number;
  filters:{
    sortBy: string;
    searchTerm?: string;
  }
  
  // isDesc: boolean;
  start: number;
  limit: number;
}

export async function getAllOrders(params: GetAllOrderParams) {
  const { data: res }: any =  await axiosJSON.post(
    `/api/shopify/order/getAll`,
    params
  );
  console.log("getAllOrders.ts res:: "+ JSON.stringify(res))
  if (res.error === true) {
    throw new Error(res.message as string);
  }

  return res.data as GetAllOrder;
}
