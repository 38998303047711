import { axiosJSON } from "src/globals/axiosEndPoints";
import { ILoginData } from "src/hooks/auth/useLogin";

// Defining the interface for the payload sent to the loginRedirectService function
interface Payload {
  token: string;
}
interface ILoginRedirect extends ILoginData {
  actionType: "login" | "signup" | "link_account";
}

// Async function to handle the login redirection service
async function loginRedirectService(payload: Payload) {
  // Making a POST request to the "/api/loginRedirect" endpoint using axiosJSON instance
  const { data: res } = await axiosJSON.post(`/api/loginRedirect`, payload);

  // Checking the status in the response
  if (res.status === "0" || res.err) {
    // If the status is "0", indicating an error, throw an error with the message from the response
    throw new Error((res.message || res.msg) as string);
  }

  // If the status is not "0", return the data from the response as ILoginData
  return res.data as ILoginRedirect;
}

export default loginRedirectService;
