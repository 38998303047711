import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";
import { IChatFormElement } from "src/store/slices/liveChatSetting/Forms/chatForm.declarations";

const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/botProfile/getConfigureForm`
  : "/api/botProfile/getConfigureForm";

interface GetConfigureFormData {
  elements: IChatFormElement[];
}

interface GetConfigureFormPayload {
  id: number;
}
export async function getConfigureForm(payload: GetConfigureFormPayload) {
  const { data: res } = await axiosJSON.post(apiEndPoint, payload);

  if (res.err) {
    throw res.msg;
  }

  return res.data as GetConfigureFormData;
}
