import React, { useCallback, useMemo, useState } from "react";
import TableHeader from "../../TableHeader/TableHeader";
import TableRow from "../../TableRow/TableRow";
import styles from "./BugCategoriesHalfView.module.scss";
import { BugData } from "src/services/BugReport/getBugByCategory.service";

export interface BugCategoryParam {
  bugsData: Array<BugData>;
  categoryName: string;
  selectedBugId: Set<number>;
  setSelectedBugId: React.Dispatch<React.SetStateAction<Set<number>>>;
  setSelectedBugsData: React.Dispatch<React.SetStateAction<BugData[]>>;
}

const BugCategoriesHalfView = ({
  bugsData,
  categoryName,
  selectedBugId,
  setSelectedBugId,
  setSelectedBugsData,
}: BugCategoryParam) => {
  const showBugs = useMemo(
    () => ((bugsData?.length ?? 0) < 3 ? bugsData : bugsData?.slice(0, 3)),
    [bugsData],
  );

  return (
    <div
      className={`w-100 overflow-auto ${
        showBugs.length > 0 ? styles.halfViewWrapper : ""
      }`}
    >
      <TableHeader
        bugList={bugsData}
        setSelectedBug={setSelectedBugId}
      />
      {showBugs?.length ? (
        showBugs?.map((bug: BugData) => (
          <TableRow
            key={bug.bugId}
            bugData={bug}
            categoryName={categoryName}
            selectedBugId={selectedBugId}
            setSelectedBugId={setSelectedBugId}
            setSelectedBugsData={setSelectedBugsData}
          />
        ))
      ) : (
        <p className={`my-2 ${styles.noBug}`}>No bugs in this category</p>
      )}
    </div>
  );
};

export default BugCategoriesHalfView;
