import styles from "./ElementText.module.scss";
import dots from "src/assets/images/dots.png";
import nextLine from "src/assets/images/nextLine.png";
import { useState } from "react";

interface Props {
  element: any;
  handleElementChange: any;
  handleElementDelete: any;
  hideDelete: boolean;
  customProvided: any;
  initialShowInput?: boolean;
}
const ElementText = ({
  element,
  handleElementChange,
  handleElementDelete,
  hideDelete,
  customProvided,
  initialShowInput = false, //flag to show element by default
}: Props) => {
  const [showForm, setShowForm] = useState(initialShowInput);
  const showPreForm = () => {
    setShowForm(!showForm);
  };

  const handleChange = (eventName: string, event: any) => {
    if (element !== null) {
      if (event.target.value !== null) {
        let obj = {
          ...element,
        };
        if (eventName === "required") {
          obj.isRequired = !obj.isRequired;
        } else {
          obj.elementValue = event.target.value;
        }
        handleElementChange(obj);
      }
    }
  };
  return (
    <div
      ref={customProvided.innerRef}
      {...customProvided.draggableProps}
      style={{ ...customProvided.draggableProps.style, position: "static" }}
    >
      <div className={`py-2 px-1 px-md-2 ${styles.formBox}`}>
        <div className={`d-flex justify-content-between`}>
          <div className={`d-flex aling-items-center`}>
            <div {...customProvided.dragHandleProps}>
              <img src={dots} alt="dots" />
            </div>
            <div>
              <span className={`ms-2 ${styles.commonSpan}`}>
                {element.elementLabel}
              </span>
            </div>
          </div>
          <div className={`d-flex flex-wrap align-items-center`}>
            {element.elementType !== "information" && (
              <div className="form-check mx-1">
                <input
                  className={`form-check-input`}
                  type="checkbox"
                  disabled={element.isDefault}
                  checked={element.isRequired}
                  onChange={(e) => handleChange("required", e)}
                />
                <label
                  className={`form-check-label ${styles.requiredField}`}
                  htmlFor="flexCheckDefault"
                >
                  Required
                </label>
              </div>
            )}
            <div className={`mx-2 ${styles.delElemTop}`}>
              {element.isDefault !== true && hideDelete === false ? (
                <span
                  className={`${styles.delElem} ${styles.delForm}`}
                  onClick={(e) => handleElementDelete(element)}
                >
                  <i className={`fa-solid fa-trash`}></i>
                </span>
              ) : (
                <></>
              )}
            </div>
            <span onClick={showPreForm}>
              {showForm ? (
                <i className={`fa-solid fa-angle-up ${styles.downArrow}`}></i>
              ) : (
                <i className={`fa-solid fa-angle-down ${styles.downArrow}`}></i>
              )}
            </span>
          </div>
        </div>
        {showForm && (
          <div className={`mt-2`}>
            <div className="mb-2">
              <label className={`form-label ${styles.commonSpan}`}>Label</label>
              <input
                type="text"
                onChange={(e) => handleChange("element", e)}
                className={`form-control ${styles.formInput}`}
                aria-describedby="emailHelp"
                value={element.elementValue}
              />
              {element.elementValue.trim() === "" && (
                <span className={styles.dangerText}>Field cannot be empty</span>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ElementText;
