import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Button,
  Form,
  Modal,
  Overlay,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import styles from "./SendMessage.module.scss";
import { useAppSelector } from "src/store/store";
import sendImg from "src/assets/images/sendIcon.svg";
import { ESendTypes } from "./children/SendingAs";
import SendingDetails from "./children/SendingDetails";
import DraftModal from "src/components/DraftModal";
import OpenClose from "./children/OpenClose";
import { v4 as uuid } from "uuid";
import {
  findAndReplaceCID,
  isHTMLEmpty,
  isSigEdited,
  isSigEqToText,
  removeSignatureFromText,
  trimPDIVBR,
} from "src/utils/utils";
import MaximizeMinimizeEditor from "./children/MaximizeMinimizeEditor/MaximizeMinimizeEditor";
import {
  ChangeCustomerProps,
  ISendMessageService,
} from "src/services/SendMessageService";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { useBrandSignature } from "src/hooks/brandSignature/useBrandSignature";
import { TicketStatus } from "src/services/TicketService/getAllStatuses";
import { AJAXSTATUS } from "src/globals/constants";
import TinyMCEContainer from "src/components/TinyMCEContainer";
import PlanUpgrade from "src/components/PlanUpgrade/PlanUpgrade";
import { TemplateProvider } from "src/components/ScheduleModal/Children/MainSection/TemplateContext";
import ScheduleModal from "src/components/ScheduleModal/ScheduleModal";
import MessageSchedule from "src/components/MessageSchedule/MessageSchedule";
import styledCss from "./ScheduleModal.module.scss";
import IntegrationFailureModal from "src/components/IntegrationFailureModal/IntegrationFailureModal";
import { IntegrationFailureMetaData } from "src/types/IntegrationFailure";
import alertIcon from "src/assets/images/alert.svg";
import SendingAsV2, { ReplyOption } from "./children/SendingAsV2";
import { EChannel } from "src/enums/EChannel";
import { Editor as TinyMceEditor } from "tinymce";
import MentionBox from "./children/MentionBox/MentionBox";
import useCustomMentions from "src/hooks/TinyMCEContainer/useCustomMentions";
import { Link } from "react-router-dom";

function SendMessage({
  createdAndSelectedTemplateFromMsg,
  setCreatedAndSelectedTemplateFromMsg,
  ticketId,
  allowDraft = true,
  TicketSelectorComponent,
  ticket_channel,
  message_thread,
  isCurrentTicketMergedWithDifferent = false,
  ticket_from,
  ticket_to,
  ticketStatusList,
  activeSnooze,
  sendMessages,
  fetchMessagesUpdates = () => {},
  changeTheCustomer,
  sendType,
  setSendType,
  textToHighlight,
  sendStatus,
  ticket_cc,
  ticket_bcc,
  isToDisabled,
  isFromDisabled,
  autosavePrefix,
  integrationFailure = null,
  replyOption,
  setReplyOption,
  messageChannel,
  setMessageChannel,
  allowedChannels,
  smallWindowChat = false,
  allowedNoteChannels = [],
  internalChat = false,
}: {
  createdAndSelectedTemplateFromMsg?: any;
  setCreatedAndSelectedTemplateFromMsg?: Function;
  ticketId?: string;
  allowDraft?: boolean;
  TicketSelectorComponent?: any;
  ticket_channel: any;
  message_thread: any;
  isCurrentTicketMergedWithDifferent?: boolean;
  ticket_from: any;
  ticket_to: any;
  ticketStatusList: {
    [key: string | number]: TicketStatus;
  };
  activeSnooze?: any;
  sendMessages: (payload: ISendMessageService, callback?: () => void) => void;
  fetchMessagesUpdates?: () => void;
  changeTheCustomer: (payload: ChangeCustomerProps) => void;
  sendType: number;
  setSendType: React.Dispatch<React.SetStateAction<number>>;
  textToHighlight?: string;
  sendStatus: AJAXSTATUS;
  ticket_cc?: string;
  ticket_bcc?: string;
  isToDisabled?: boolean;
  isFromDisabled?: boolean;
  autosavePrefix?: string;
  integrationFailure?: IntegrationFailureMetaData | null;
  replyOption: ReplyOption;
  setReplyOption: (option: ReplyOption) => void;
  messageChannel: EChannel;
  setMessageChannel: React.Dispatch<React.SetStateAction<EChannel>>;
  allowedChannels: Array<EChannel>;
  smallWindowChat?: boolean;
  allowedNoteChannels?: Array<EChannel>;
  internalChat?: boolean;
}) {
  //Used to clear the to, from, cc, bcc
  const sendingDetailsRef: any = useRef({});
  const [canDeleteInline, setCanDeleteInline] = useState(true);

  const scopes = useAppSelector(
    (state) => state.globals.currentUserData?.scopes,
  );
  const ui_visibility = useAppSelector(
    (state) => state.globals.currentUserData?.ui_visibility,
  );

  const scheduleMessageVisible = useMemo(() => {
    return ui_visibility?.includes("inner_ticket_schedule_message");
  }, [ui_visibility]);

  const disabledFeatures = useAppSelector(
    (state) => state.globals.currentUserData?.disabledFeatures,
  );
  const slackNotIntegrateData = useAppSelector(
    (state) => state.innerTicket?.showEnableSlack,
  );

  const slackNotIntegrate = useMemo(() => {
    return (slackNotIntegrateData ?? {})[ticketId + ""] ? true : false;
  }, [slackNotIntegrateData, ticketId]);

  const isSendEmailDisabled = useMemo(() => {
    return disabledFeatures?.includes("send_email") ?? false;
  }, [disabledFeatures]);

  /**
   * Calculate if integration is failed
   */
  const isIntegrationFailed = useMemo(() => {
    // Check if integration failure has happened
    if (!integrationFailure) {
      return false;
    }

    // Check if we have failed brand email and active ticket selected from is same as failed brand email
    if (
      integrationFailure.brand?.email &&
      integrationFailure.brand.email !== ticket_from
    ) {
      return false;
    }

    return true;
  }, [integrationFailure, ticket_from]);

  useEffect(() => {
    if (ticket_channel !== "Email") {
      // If the active customer's chat channel type is email, allow inline deletion
      setCanDeleteInline(false);
    } else {
      setCanDeleteInline(true);
    }
  }, [ticket_channel]);
  const editorRef = useRef<TinyMceEditor | null>(null);
  const target: any = useRef(null);
  const overlayRef = useRef(null);
  const formRef: any = useRef(null);
  const [sendThread, setSendThread] = useState(true);
  const [showWarning, setShowWarning] = useState(false);
  const [showThread, setShowThread] = useState(false);
  const [showMaximizedModal, setShowMaximizedModal] = useState(false);
  const [showingWhichModal, setShowingWhichModal] = useState<string>(
    "" as "template" | "escalateDraft",
  );
  const sendMessageMainDivRef: any = useRef(null);
  const sendMessageDivRef: any = useRef(null);
  let resizeObserver: any = useRef(null);

  const [showOverlay, setShowOverlay] = useState(false);
  const [showDraftModal, setShowDraftModal] = useState(false);

  const [sigEdited, setSigEdited] = useState(false);
  const [sendAs, setSendAs] = useState("Open");
  const [message, setMessage] = useState("");

  // the signature isfetched based on the brand email (ticket_from)
  const { signatureText } = useBrandSignature(ticket_from ?? "");
  // stores the selected files
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);
  const [currentAttachmentBatchNumber, setCurrentAttachmentBatchNumber] =
    useState<string>("");
  const { fetchAllMentions } = useCustomMentions(ticket_from);
  //created memo to check weather signature can be shown or not
  const canShowSignatureText = useMemo(() => {
    return (
      ESendTypes.Message === sendType &&
      (messageChannel + "").toLowerCase() === "email"
    );
  }, [sendType, messageChannel]);

  const currentState = useRef({
    scopes,
    ticketId,
    sendType,
    signatureText,
    canShowSignatureText,
    autosavePrefix,
  });
  useMemo(() => {
    // keeping somestates updated to avoid the stale state problem
    currentState.current = {
      scopes,
      ticketId,
      sendType,
      signatureText,
      canShowSignatureText,
      autosavePrefix,
    };
  }, [
    scopes,
    ticketId,
    sendType,
    signatureText,
    canShowSignatureText,
    autosavePrefix,
  ]);

  // when a template is selected after creating new template from message
  useEffect(() => {
    if (
      createdAndSelectedTemplateFromMsg &&
      createdAndSelectedTemplateFromMsg !== "" &&
      setCreatedAndSelectedTemplateFromMsg
    ) {
      // setting into  message if template is created
      setMessage(createdAndSelectedTemplateFromMsg);
      setCreatedAndSelectedTemplateFromMsg("");
    }
  }, [createdAndSelectedTemplateFromMsg]);

  useEffect(() => {
    setShowWarning(false);
    setShowThread(false);
    // reset attachment data when ticket is changed
    // setSelectedFiles([]); // clear the attachments selected
    // setCurrentAttachmentBatchNumber(""); // clear the batch number
  }, [ticketId + ""]);

  const clearData = useCallback(() => {
    try {
      setSelectedFiles([]); // clear the attachments selected
      setCurrentAttachmentBatchNumber(""); // clear the batch number after sending message
      sendingDetailsRef.current.clearData();
    } catch {}
    setMessage(
      currentState.current.canShowSignatureText
        ? currentState.current.signatureText
          ? currentState.current.signatureText
          : ""
        : "",
    );
  }, []);
  const sendMessage = useCallback(
    (e: any) => {
      e.preventDefault();
      if (
        ticketId &&
        sendStatus !== "pending" &&
        isCurrentTicketMergedWithDifferent === false
      ) {
        if (
          selectedFiles.length > 0 ||
          (formRef.current.elements.message.value.trim().length > 0 &&
            isSigEqToText({
              text: formRef.current.elements.message.value,
              signature: currentState.current.signatureText,
            }))
        ) {
          // also close the modal
          if (showMaximizedModal === true) {
            setShowMaximizedModal(false);
          }
          const messageUuid = uuid();
          const payload: ISendMessageService & { attachments?: Array<any> } = {
            ticketId,
            to: formRef.current.elements.to
              ? formRef.current.elements.to.value.trim()
              : "",
            from: formRef.current.elements.from
              ? formRef.current.elements.from.value.trim()
              : "",
            cc: formRef.current.elements.cc
              ? formRef.current.elements.cc.value.trim()
              : "",
            bcc: formRef.current.elements.bcc
              ? formRef.current.elements.bcc.value.trim()
              : "",
            message: formRef.current.elements.message
              ? trimPDIVBR(
                  findAndReplaceCID(
                    formRef.current.elements.message.value.trim(),
                  ),
                )
              : "",
            messageType:
              sendType != ESendTypes.Note && replyOption === ReplyOption.Forward
                ? "Forward"
                : message.includes("data-cern-mention-instruction-type") // if message have cern instruction then send Note
                  ? "NoteBotAiQuery"
                  : (ESendTypes[sendType] as any),
            sendAs,
            attachmentBatchNumber: selectedFiles
              ? selectedFiles.map(function (value: any, index: any) {
                  return value.batchNumber;
                })
              : [],
            attachments: selectedFiles
              ? selectedFiles.map((attachment: any, index: number) => {
                  return {
                    attachmentId: attachment.attachmentId,
                    attachmentName: attachment.attachmentName,
                    attachmentBatchNumber: attachment.batchNumber,
                    attachmentType:
                      attachment.file?.type || attachment.attachmentType,
                    attachmentUrl: attachment.attachmentURL,
                    embedded: attachment.embedded,
                    contentId: attachment.contentId,
                  };
                })
              : [],
            // selectedFiles[0] && selectedFiles[0].batchNumber
            //   ? selectedFiles[0].batchNumber.trim()
            //   : "",
            uuid: messageUuid,
            channel: messageChannel,
          };
          if (
            sendThread &&
            message_thread?.threadText &&
            (messageChannel + "").toLowerCase() === "email" &&
            sendType !== ESendTypes.Note
          ) {
            payload.message_thread = message_thread.threadText;
            if (message_thread.content_ids) {
              payload.content_ids = message_thread.content_ids;
            }
          }
          if (
            sendType === ESendTypes.Message &&
            (messageChannel + "").toLowerCase() === "email"
          ) {
            //added check for "from"|"to" email field required only if channel is email (facebook and instgram will not have email)
            if (payload.to?.trim() && payload.from?.trim()) {
              sendMessages(payload, clearData);
            } else {
              setShowWarning(true);
              pushTheToast({
                text: `Please enter ${
                  !payload.to?.trim() ? "to" : "from"
                } email!`,
                type: "danger",
                position: "top-right",
              });
            }
          } else {
            sendMessages(payload, clearData);
          }
        } else {
          setShowWarning(true);
          pushTheToast({
            text: "Please add text or attachment!",
            type: "warning",
            position: "top-right",
          });
        }
      }
    },
    [
      ticketId,
      sendStatus,
      sendType,
      sendAs,
      selectedFiles,
      showMaximizedModal,
      message_thread,
      sendThread,
      isCurrentTicketMergedWithDifferent,
      message,
      messageChannel,
      replyOption,
    ],
  );

  useEffect(() => {
    if (showWarning) {
      const timeout = setTimeout(() => {
        setShowWarning(false);
      }, 5000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [showWarning]);

  const onShowDraftModal = useCallback(() => {
    // if draft modal is showing from inner ticket send message modal, close the send message modal
    /*if(showMaximizedModal === true){
      setShowMaximizedModal(false);
    }*/
    // setShowingWhichModal("escalateDraft");
    setShowDraftModal(true);
  }, []);
  const onHideDraftModal = useCallback(() => {
    setShowDraftModal(false);
  }, []);

  const onClickWindow = useCallback((e: any) => {
    if (e.target !== target.current && !target.current?.contains(e.target)) {
      setShowOverlay(false);
    }
  }, []);

  const [showCannotSendModal, setShowCannotSendModal] = useState(false);

  const handleCannotSend: React.MouseEventHandler<HTMLButtonElement> =
    useCallback((e) => {
      e.preventDefault();
      e.stopPropagation();
      setShowCannotSendModal(true);
    }, []);

  useEffect(() => {
    window.addEventListener("click", onClickWindow);
    return () => {
      window.removeEventListener("click", onClickWindow);
    };
  }, [onClickWindow]);

  useEffect(() => {
    if (
      showMaximizedModal === false &&
      sendMessageDivRef.current &&
      resizeObserver.current === null
    ) {
      resizeObserver.current = new ResizeObserver((entries) => {
        const { width, height } =
          sendMessageDivRef.current.getBoundingClientRect();
        if (height > 518 && showMaximizedModal === false) {
          sendMessageDivRef.current.classList.add(styles.addOverFlowYAuto);
        } else {
          if (
            sendMessageDivRef.current.classList.contains(
              styles.addOverFlowYAuto,
            )
          ) {
            sendMessageDivRef.current.classList.remove(styles.addOverFlowYAuto);
          }
        }
      });

      resizeObserver.current.observe(sendMessageDivRef.current);
    } else {
      if (resizeObserver.current !== null) {
        resizeObserver.current.disconnect();
        // resizeObserver.current.unobserve(sendMessageDivRef.current);
        resizeObserver.current = null;
      }
    }

    return () => {
      if (resizeObserver.current !== null) {
        resizeObserver.current.disconnect();
        resizeObserver.current = null;
      }
    };
  }, [showMaximizedModal]);

  //for snooze send as default button "pending"
  useEffect(() => {
    if (activeSnooze) {
      if (
        activeSnooze.snoozeDataAjaxStatus === "fulfilled" &&
        activeSnooze.snoozeData.id !== 0 &&
        activeSnooze.snoozeData.ticketId !== 0
      ) {
        setSendAs("Pending");
      } else if (sendAs === "Pending") {
        setSendAs("Open");
      }
    }
  }, [activeSnooze, ticketId]);

  // Get copied draft message and id from redux store
  const { copiedAiDraft, aiDraftMsgId } = useAppSelector(
    (state) => state.innerTicket,
  );

  // Effect to paste copied ai draft message when message is empty
  useEffect(() => {
    // Check if ai draft is not empty
    if (copiedAiDraft && copiedAiDraft.trim().length > 0) {
      // Check if message is empty , set the message with draft
      if (isHTMLEmpty(trimPDIVBR(message.trim()))) {
        setMessage(copiedAiDraft);
      }
    }
    // Clearing copied message after 3 seconds
    // setTimeout(function () {
    //   dispatch(setCopiedAiDraft({
    //     message: ""
    //   }));
    // }, 3000);
  }, [copiedAiDraft, aiDraftMsgId]);

  // send message part
  const [scheduleWith, setScheduleWith] = useState<string | null>(null);
  const [automatedFolloupModal, setAutomatedFolloupModal] = useState(false);

  const { activeCustomerInfo } = useAppSelector((state) => state.chatWidExhMsg);

  const onShow = (e: any, value: string | null) => {
    e.stopPropagation();
    setScheduleWith(value);
    setAutomatedFolloupModal(true);
    setSchedulePopoverState(false);
  };
  const onHide = () => {
    setAutomatedFolloupModal(false);
  };

  const scheduleMessageCount = useAppSelector((state) => {
    return state.innerTicket.activeTicketInfo.ticket_schedule_message_count;
  });

  const [schedulePopoverState, setSchedulePopoverState] = useState(false);

  // For message schedule modal
  const [showModal, setShowModal] = useState(false);

  const onShowScheduleMsgModal = (e: any) => {
    setSchedulePopoverState(false);
    setShowModal(true);
  };
  const onHideScheduleMsgModal = () => {
    setShowModal(false);
  };

  const ref = useRef({ sendType, messageChannel });

  useEffect(() => {
    ref.current = {
      sendType,
      messageChannel,
    };
  }, [messageChannel, sendType]);

  const handleRemoveImages = useCallback(() => {
    // Social tickets do not support embedded attachments, so when the user switches channel, it will delete the inline images and keep attachments in list
    const editorWindow = editorRef.current?.getBody();
    const images = editorWindow?.querySelectorAll("img");
    images?.forEach((img) => img.remove());
  }, []);

  const setChannelName = useCallback(
    (channel: EChannel) => {
      // Update the message channel state with the selected channel
      setMessageChannel(channel);
      // If the selected channel is not email, disable inline deletion and remove images
      if (channel !== EChannel.email) {
        setCanDeleteInline(false);
        handleRemoveImages();
      } else {
        // If the selected channel is email, enable inline deletion
        setCanDeleteInline(true);
      }
    },
    [setMessageChannel, setCanDeleteInline, handleRemoveImages],
  );

  const popover = (
    <Popover
      id="popover-basic"
      bsPrefix={`popover ${styles.popWrapper}`}
    >
      <Popover.Body className={`${styles.popoverBody}`}>
        <div>
          {scheduleMessageCount && scheduleMessageCount > 0 ? (
            <div
              className={`${styles.scheduleList}`}
              onClick={(e) => onShowScheduleMsgModal(e)}
            >
              Edit scheduled message
            </div>
          ) : (
            <>
              <div
                className={`${styles.scheduleList}`}
                onClick={(e) => onShow(e, null)}
              >
                Schedule
              </div>
              <div
                className={`${styles.scheduleList}`}
                onClick={(e) => onShow(e, "close")}
              >
                Schedule and mark closed
              </div>
              <div
                className={`${styles.scheduleList}`}
                onClick={(e) => onShow(e, "pending")}
              >
                Schedule and mark pending
              </div>
              <div
                className={`${styles.scheduleList}`}
                onClick={(e) => onShow(e, "open")}
              >
                Schedule and mark open
              </div>
            </>
          )}
        </div>
      </Popover.Body>
    </Popover>
  );

  const mentionDelimiter = useMemo(() => {
    if (sendType === ESendTypes.Message) {
      return undefined;
    }
    return {
      delimiters: {
        "@": {
          fetchOptions: async () => {
            const data = await fetchAllMentions();
            // if (data && ref.current.messageChannel !== EChannel.slack) {
            //   return data.filter(
            //     (mention) => mention.mentionCategoryDetails.key !== "slack",
            //   );
            // } else if (data && ref.current.messageChannel === EChannel.slack){
            //   return data.filter(
            //     (mention) => mention.mentionCategoryDetails.key === "slack",
            //   );
            // }
            return data;
          },
          renderComponent: (props: any) => (
            <MentionBox
              {...props}
              setMessageChannel={setChannelName}
            />
          ),
        },
      },
    };
  }, [sendType, ticket_from, fetchAllMentions]);

  useEffect(() => {
    if (!scheduleMessageCount) {
      setShowModal(false);
    }
  }, [scheduleMessageCount]);

  const getSendMessageDiv = (
    <div
      className={`${
        smallWindowChat ? "" : "mx-1 p-2"
      } pb-0 d-flex justify-content-between align-items-center flex-column `}
    >
      {scheduleMessageVisible &&
      scheduleMessageCount !== undefined &&
      scheduleMessageCount > 0 ? (
        <div
          className={`d-flex flex-row-reverse align-items-center ${styles.showDisconnected} w-100`}
        >
          <Modal
            backdropClassName={`${styledCss.modalBack}`}
            className={`${styles.modalClass}`}
            show={showModal}
            onHide={onHideScheduleMsgModal}
            dialogClassName={`${styledCss.modalDialog}`}
            contentClassName={`${styledCss.modalContent}`}
            animation={true}
            enforceFocus={false}
          >
            <MessageSchedule
              ticketId={ticketId}
              onHide={onHideScheduleMsgModal}
              scheduleMessageFor="innerTicket"
            />
          </Modal>

          <div>
            <span
              className={`ps-2 cursor-pointer ${styledCss.messageCount}`}
              onClick={onShowScheduleMsgModal}
            >
              {`${scheduleMessageCount} Messages Scheduled`}
            </span>
          </div>
        </div>
      ) : null}

      <div
        className={`${smallWindowChat ? "" : "p-2"} ${
          showMaximizedModal === true
            ? styles.sendMessageInModal
            : styles.sendMessage
        }`}
        ref={sendMessageDivRef}
      >
        <Form
          ref={formRef}
          onSubmit={sendMessage}
          className="d-flex flex-column"
        >
          <input
            type="email"
            style={{
              height: 0,
              border: "none",
              margin: 0,
              width: 0,
              opacity: 0,
              padding: 0,
            }}
          />
          {TicketSelectorComponent && TicketSelectorComponent}
          <div className={`d-flex justify-content-between w-100 mb-1`}>
            <div
              className={`me-2`}
              style={{ minWidth: "96%" }}
            >
              {sendType === ESendTypes.Message &&
                scopes &&
                scopes.includes("send_message") &&
                messageChannel?.toLowerCase() === "email" && (
                  <SendingDetails
                    ticketId={ticketId}
                    src={sendingDetailsRef}
                    isInModal={showMaximizedModal}
                    ticket_to={ticket_to}
                    ticket_from={ticket_from}
                    ticket_cc={ticket_cc}
                    ticket_bcc={ticket_bcc}
                    changeTheCustomer={changeTheCustomer}
                    textToHighlight={textToHighlight}
                    isToDisabled={isToDisabled}
                    isFromDisabled={isFromDisabled}
                    isIntegrationFailed={isIntegrationFailed}
                    integrationFailType={integrationFailure?.type}
                    smallWindowChat={smallWindowChat}
                  />
                )}
            </div>
            <div
              className={`me-2`}
              style={{ minWidth: "4%" }}
            >
              <MaximizeMinimizeEditor
                showMaximizedModal={showMaximizedModal}
                setShowMaximizedModal={setShowMaximizedModal}
                className={`my-auto mx-2 ${styles.maximizeMinimizeMainDiv}`}
              />
            </div>
          </div>
          <SendingAsV2
            ticketId={ticketId + ""}
            sendType={sendType}
            setSendType={setSendType}
            selectedTemplate={(templateText: string) => {
              setMessage((msg) => templateText + msg);
            }}
            showingWhichModal={showingWhichModal}
            setShowingWhichModal={setShowingWhichModal}
            channelName={messageChannel ?? EChannel.email}
            replyOption={replyOption}
            setReplyOption={setReplyOption}
            allowedChannels={
              replyOption === ReplyOption.Forward
                ? [EChannel.email]
                : allowedChannels
            }
            allowedNoteChannels={allowedNoteChannels}
            internalChat={internalChat}
            setChannelName={setChannelName}
          />
          <div className={`position-relative`}>
            {slackNotIntegrate && (
              <div
                className={`${styles.slackNotIntegrate} d-flex justify-content-center align-items-center`}
              >
                <Link
                  to={`../settings/browse-integration`}
                  target="_blank"
                  className={`${styles.enableBtn}`}
                >
                  Enable slack integration
                </Link>
              </div>
            )}
            <TinyMCEContainer
              name="message"
              tinymceEditorRef={editorRef}
              canDeleteInline={canDeleteInline}
              // scrollHeightClassName={`${
              //   showMaximizedModal === true ? "" : styles.scrollHeightClassName
              // }`} // don't need to change height on scroll in modal
              className={`${
                showMaximizedModal === true
                  ? styles.messageInModal
                  : styles.message
              } ${sendType !== ESendTypes.Message ? styles.active : ""}`}
              editorClassName={`${showWarning ? "redBorder rounded" : ""} ${
                showMaximizedModal === true
                  ? `${styles.editorInModal} max-content-height important`
                  : `${styles.editor} max-content-height important`
              }`}
              autosavePrefix={autosavePrefix}
              value={message}
              signatureText={canShowSignatureText ? signatureText : undefined}
              setSigEdited={setSigEdited}
              sigEdited={sigEdited}
              mentionsEnabled={false} //sendType !== ESendTypes.Message
              customAutoCompleteOptions={mentionDelimiter}
              onChange={(value) => {
                setMessage(value);
                // Added this to update active text to check for copy and paste of ai draft message
                // May 06 2024 Commeting this line as this causing Tinymce not render inline attachment, this need to be replaced
                // dispatch(
                //   setActiveMessageText({
                //     message: value,
                //   }),
                // );
              }}
              threadData={
                (messageChannel + "").toLowerCase() === "email" &&
                sendType === ESendTypes.Message
                  ? message_thread
                  : undefined
              }
              sendThread={sendThread}
              setSendThread={setSendThread}
              showThread={showThread}
              setShowThread={setShowThread}
              disableFormatingsAndEmbedding={
                (messageChannel + "").toLowerCase() !== "email"
              }
              options={{
                placeholder:
                  sendType === ESendTypes.Note
                    ? "Use @ to mention your team members"
                    : "Enter your message here",
                max_height: showMaximizedModal === true ? 400 : 250,
                min_height: showMaximizedModal === true ? 400 : 110,
                // readOnly: (isCurrentTicketMergedWithDifferent === true)
              }}
              // enable the attachment picker
              disableAttachment={
                false || isCurrentTicketMergedWithDifferent === true
              }
              disableEmbedding={(messageChannel + "").toLowerCase() !== "email"} //Added to disable attachments for non email channels
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              isFileUploading={isFileUploading}
              setIsFileUploading={setIsFileUploading}
              currentAttachmentBatchNumber={currentAttachmentBatchNumber}
              setCurrentAttachmentBatchNumber={setCurrentAttachmentBatchNumber}
              uniqueID={uuid()}
              buttonContainerClass={`${styles.sendMessageBtn} ${
                isIntegrationFailed &&
                sendType !== ESendTypes.Note &&
                smallWindowChat &&
                styles.btnOverFlow
              }`}
              buttons={
                <>
                  {isSendEmailDisabled && sendType !== ESendTypes.Note ? (
                    <PlanUpgrade
                      upgradeButtonText="Upgrade to send"
                      showOnlyButton={true}
                    />
                  ) : (
                    <div
                      className={`d-flex ${
                        isIntegrationFailed &&
                        sendType !== ESendTypes.Note &&
                        smallWindowChat &&
                        styles.btnFlexColumn
                      }`}
                    >
                      {isIntegrationFailed && sendType !== ESendTypes.Note && (
                        <div
                          className={`d-flex align-items-center ${styles.integrationFail}`}
                        >
                          <span className="me-1">
                            <img
                              src={alertIcon}
                              alt="alert"
                              width={12}
                              height={12}
                            />
                          </span>
                          <span>Cannot send</span>
                          <button
                            onClick={handleCannotSend}
                            id="integrationFailureBtn"
                          >
                            See why?
                          </button>
                        </div>
                      )}

                      <div className={`d-flex`}>
                        {scheduleMessageVisible &&
                          ticketId !== undefined &&
                          ticketId !== "" && (
                            <OverlayTrigger
                              trigger="click"
                              placement="top-end"
                              overlay={popover}
                              onToggle={(nextShow) => {
                                setSchedulePopoverState(nextShow);
                              }}
                              // onEntering={() => setSchedulePopoverState(true)}
                              // onExiting={() => setSchedulePopoverState(false)}
                              show={schedulePopoverState}
                              rootClose={true}
                            >
                              <Button
                                className={`btn d-flex  my-auto mx-1 ${styles.sendBtn} ${styles["translate-up"]}`}
                                role="Bu"
                              >
                                <span className={`my-auto`}>
                                  <i className="fa-regular fa-clock"></i>
                                </span>
                                <span
                                  className={`mx-2 my-auto ${styles.line}`}
                                ></span>
                                <span>
                                  <i
                                    className={`fa-solid fa-chevron-${
                                      schedulePopoverState ? "up" : "down"
                                    }`}
                                  ></i>
                                </span>
                              </Button>
                            </OverlayTrigger>
                          )}

                        {sendType !== ESendTypes.Note && allowDraft && (
                          <button
                            className={`d-none btn d-flex my-auto mx-1 ${styles.sendBtn} ${styles["translate-up"]}`}
                            disabled={
                              message.trim().length === 0 ||
                              !isSigEqToText({
                                text: message,
                                signature: signatureText,
                              }) ||
                              isFileUploading ||
                              isCurrentTicketMergedWithDifferent === true
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              onShowDraftModal();
                            }}
                          >
                            <span className={`my-auto`}>Escalate</span>
                          </button>
                        )}
                        {((scopes && scopes.includes("send_message")) ||
                          sendType === ESendTypes.Note) && (
                          <>
                            <Button
                              type="submit"
                              className={`d-flex my-auto mx-1 ${styles.sendBtn}`}
                              disabled={
                                (isIntegrationFailed &&
                                  sendType !== ESendTypes.Note) ||
                                isFileUploading ||
                                isCurrentTicketMergedWithDifferent === true ||
                                ticketId == undefined
                              }
                            >
                              <span className={`my-auto`}>
                                {sendType === ESendTypes.Note
                                  ? "Add Note"
                                  : "Send"}{" "}
                                {smallWindowChat ? "" : `As ${sendAs}`}
                              </span>
                              <span
                                className={`${
                                  smallWindowChat ? "" : "mx-2"
                                } my-auto`}
                              ></span>
                              <img
                                src={sendImg}
                                className={`my-auto`}
                              />
                            </Button>

                            {smallWindowChat ? (
                              ""
                            ) : (
                              <OpenClose
                                ref={target}
                                onClick={(e: any) => {
                                  if (
                                    isIntegrationFailed &&
                                    sendType !== ESendTypes.Note
                                  ) {
                                    return;
                                  }
                                  setShowOverlay(!showOverlay);
                                }}
                                show={showOverlay}
                                className={`my-auto mx-2 ${styles.openC} ${
                                  showOverlay ? styles.active : ""
                                } ${
                                  isIntegrationFailed &&
                                  sendType !== ESendTypes.Note
                                    ? styles.disabledDropdownBtn
                                    : ""
                                }`}
                              />
                            )}

                            <Overlay
                              target={target}
                              show={showOverlay}
                              ref={overlayRef}
                              placement="top-end"
                            >
                              {({
                                placement,
                                arrowProps,
                                show: _show,
                                popper,
                                ...props
                              }) => (
                                <div
                                  {...props}
                                  className={`p-2 ${styles.overlay} ${
                                    showMaximizedModal === true
                                      ? styles.sendUsPopupInModal
                                      : ""
                                  }`}
                                >
                                  {Object.entries(ticketStatusList).map(
                                    ([index, data], key) => {
                                      return (
                                        <div
                                          key={key}
                                          className={`btn d-flex px-1 py-0 ${
                                            styles.item
                                          } ${
                                            sendAs === data.statusName
                                              ? styles.active
                                              : ""
                                          }`}
                                          onClick={(e) => {
                                            setSendAs(data.statusName);
                                          }}
                                        >
                                          <span
                                            className={`${styles.hoverBar}`}
                                          ></span>
                                          <span>
                                            {sendType === ESendTypes.Note
                                              ? "Add Note"
                                              : "Send"}{" "}
                                            As {data.statusName}
                                          </span>
                                        </div>
                                      );
                                    },
                                  )}
                                </div>
                              )}
                            </Overlay>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </>
              }
            />
          </div>
        </Form>
      </div>
    </div>
  );

  return (
    <div
      className={`mb-2 ${smallWindowChat ? "" : "px-2 mx-auto"} w-100 ${
        styles.sendMessageMain
      } send-div-main`}
      ref={sendMessageMainDivRef}
    >
      {showMaximizedModal === true ? (
        <Modal
          show={true}
          onHide={() => setShowMaximizedModal(false)}
          backdropClassName={`${styles.modalBackDrop}`}
          dialogClassName={`${styles.modalDialog}`}
          className={`${styles.modalBack}`}
          contentClassName={`${styles.modalContent} ${
            showingWhichModal === "template" ||
            showingWhichModal === "escalateDraft" ||
            showDraftModal === true
              ? styles.hideVisibility
              : ""
          }`}
          enforceFocus={false}
        >
          {getSendMessageDiv}
        </Modal>
      ) : (
        getSendMessageDiv
      )}
      {ticketId && allowDraft && (
        <DraftModal
          escalateMessage={removeSignatureFromText(message)}
          clearEscalateMessge={() => {
            setMessage(
              currentState.current.canShowSignatureText &&
                currentState.current.signatureText
                ? currentState.current.signatureText
                : "",
            );
          }}
          escalateSelectedFiles={selectedFiles}
          clearEscalateSelectedFiles={setSelectedFiles}
          show={showDraftModal}
          onShow={onShowDraftModal}
          onHide={onHideDraftModal}
          ticketId={ticketId}
          maintainFlow={true}
          onAnyChange={fetchMessagesUpdates}
        />
      )}

      <Modal
        // backdropClassName={`${styles.modalBack}`}
        show={automatedFolloupModal}
        className={`${styles.modalClass}`}
        onHide={onHide}
        dialogClassName={`${styles.modalScheduleDialog}`}
        contentClassName={`${styles.modalScheduleContent}`}
        enforceFocus={false}
      >
        <TemplateProvider scheduleMessageFor="innerTicket">
          <ScheduleModal
            ticketId={ticketId}
            onHide={onHide}
            scheduleMessageFor="innerTicket"
            scheduleWith={scheduleWith}
          />
        </TemplateProvider>
      </Modal>

      {/* Integration Failure Modal  */}
      {integrationFailure && (
        <IntegrationFailureModal
          show={showCannotSendModal}
          onHide={() => setShowCannotSendModal(false)}
          data={integrationFailure}
        />
      )}
    </div>
  );
}

export default SendMessage;
