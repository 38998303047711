import { Column } from "src/hooks/useTicketViews/ticketViewsConfig";
import { ITicketJSON, Tickets } from "src/services/TicketService/getTicketsV2";
import { RootState } from "src/store/store";
import { arraysAreEqual, isObjOrStrEmpty } from "src/utils/utils";
import log from "loglevel";
import { prepareGetSidebarTicketsCountQuery } from "src/services/SideBar/getTicketsCountInSideBar";
import {
  getActiveChannelId,
  getActiveDraftStatusId,
  getActiveListIndex,
  getActiveTicketStatusId,
} from "../ticketSidebar/ticketSidebar.selectors";
import {
  getClosedDateValue,
  getCreatedDateValue,
  getLastUpdatedDateValue,
  getNotRepliedSinceDateValue,
} from "../ticketFilters/ticketFilters.selectors";

export function getBulkSelectedTicketIds(state: RootState) {
  return state.ticket.bulkSelectedTicketsIds;
}

export function isTicketIdSelected(state: RootState, ticketId: string) {
  return (
    state.ticket.bulkActionFilters.selectedAllTickets ||
    state.ticket.bulkSelectedTicketsIds.includes(ticketId)
  );
}

export function areAllTicketsSelected(state: RootState): boolean {
  if (!isObjOrStrEmpty(state.ticket.allTicketsIds)) {
    const allTicketIds = state.ticket.allTicketsIds;
    const allSelectedTicketIds = state.ticket.bulkSelectedTicketsIds;

    // return true if there exists some tickets and each of them are seleted
    return (
      allTicketIds.length !== 0 &&
      allTicketIds.every((t) => allSelectedTicketIds.includes(t))
    );
  } else {
    return false;
  }
}
export const getAllCachedTicketIds = (state: RootState) => {
  const ticketIds: Array<string> = [];
  for (const x in state.ticket.cachedTickets) {
    if (state.ticket.cachedTickets[x].length) {
      ticketIds.push(...state.ticket.cachedTickets[x]);
    }
  }
  return ticketIds;
};

export function getTicketCellDataSelector(
  tickets: any,
  ticketId: string,
  colKeys: string[]
) {
  const data: Array<Column> = [];
  colKeys.forEach((colKey) => {
    data.push(tickets[ticketId][colKey]);
  });
  return data;
}

export const isColumnSelected = (
  currColumnList: any,
  viewType: any,
  colKeys: any
): any => {
  const colList = currColumnList[viewType];
  // log.debug({ colList, viewType });
  for (let i = 0; i < colList.length; i++) {
    const col = colList[i];
    if (arraysAreEqual(col.keys, colKeys) === true) {
      // log.debug({ 1: col.keys, 2: colKeys });
      return true;
    }
  }
  return false;
};

export const getSelectedColIdIndexForAllViews = (state: RootState) => {
  try {
    return {
      "ticket-view": state.ticket.currColumnList["ticket-view"]
        .map((col, index) => (col.show ? `${col.id}::${index}` : ""))
        .filter((data) => data !== ""),
      "response-view": state.ticket.currColumnList["response-view"]
        .map((col, index) => (col.show ? `${col.id}::${index}` : ""))
        .filter((data) => data !== ""),
      "customer-view": state.ticket.currColumnList["customer-view"]
        .map((col, index) => (col.show ? `${col.id}::${index}` : ""))
        .filter((data) => data !== ""),
    } as { [key: string]: Array<string> };
  } catch {
    return null;
  }
};

export function totalTicketsSelected(state: RootState) {
  return state.ticket.bulkSelectedTicketsIds.length;
}

/**
 * selector to get updated tickets count for bulk action, which returns how many tickets has been updated successfully
 * these values set by "setBulkUpdatedTicketCount" reducer function which is called in required thunks|slice
 */
export function updatedTicketsCount(state: RootState) {
  return state.ticket.bulkUpdatedTicketCounts;
}

/**
 * function to prepare ticket filters query for bulk action api's, which is used to send in payload when selectAll option is selected in bulk action
 * Note: created this function from fetchUpdatedSidebarTicketsCount thunk and kept it separate and generic to use this function on all ticket bulk action apis with one line
 * @param state
 */
export function getTicketListFilterQuery(state: RootState) {
  const activeListIndex: string = getActiveListIndex(state);
  const activeChannelId: number[] = getActiveChannelId(state);
  const activeTicketStatusId: number[] = getActiveTicketStatusId(state);
  const activeDraftStatusId: number[] = getActiveDraftStatusId(state);

  const createdDateValue = getCreatedDateValue(state);
  const closedDateValue = getClosedDateValue(state);
  const notRepliedSinceDateValue = getNotRepliedSinceDateValue(state);
  const lastUpdatedDateValue = getLastUpdatedDateValue(state);
  const filterSearchString = state.ticket.filterSearchString;
  const filterOptions = {
    activeChannelId,
    activeDraftStatusId,
    activeTicketStatusId,
    createdDateValue,
    closedDateValue,
    notRepliedSinceDateValue,
    filterSearchString,
    lastUpdatedDateValue,
    ...state.ticketFilter.activeFilters,
  };

  const ticketsQuery: any = prepareGetSidebarTicketsCountQuery({
    tableType: activeListIndex,
    filterOptions,
  });

  return ticketsQuery;
}
