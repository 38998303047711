import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { AJAXSTATUS } from "src/globals/constants";
import { IArticleCategoryDataV2 } from "src/services/LiveChat/Settings/knowledgeBased/articleCategory/getAllArticleCategoryV2";
import { IFeaturedArticlesData } from "src/services/LiveChat/Settings/knowledgeBased/featuredArticles/getAllFeaturedArticles";
import knowledgeBasedSettingExtraReducers from "./knowledgeBasedSetting.extraReducers";
import knowledgeBasedSettingReducers from "./knowledgeBasedSetting.reducers";
import { isArticleSelected } from "./knowledgeBasedSetting.selectors";
import knowledgeBasedSettingThunks from "./knowledgeBasedSetting.thunks";

export const fetchKnowledgeBasedData = createAsyncThunk(
  "knowledgeBasedSetting/fetchKnowledgeBasedData",
  knowledgeBasedSettingThunks.fetchKnowledgeBasedDataThunk
);

export const updateKnowledgeBasedData = createAsyncThunk(
  "knowledgeBasedSetting/updateKnowledgeBasedData",
  knowledgeBasedSettingThunks.updateKnowledgeBasedDataThunk
);
export const fetchAllFeaturedArticles = createAsyncThunk(
  "knowledgeBasedSetting/fetchAllFeaturedArticles",
  knowledgeBasedSettingThunks.fetchAllFeaturedArticlesThunk
);
export const addFeaturedArticle = createAsyncThunk(
  "knowledgeBasedSetting/addFeaturedArticle",
  knowledgeBasedSettingThunks.addFeaturedArticleThunk
);
export const updateFeaturedArticle = createAsyncThunk(
  "knowledgeBasedSetting/updateFeaturedArticle",
  knowledgeBasedSettingThunks.updateFeaturedArticleThunk
);

export const fetchAllArticles = createAsyncThunk(
  "knowledgeBasedSetting/fetchAllArticles",
  knowledgeBasedSettingThunks.fetchAllArticlesThunk
);
export const fetchAllArticleCategories = createAsyncThunk(
  "knowledgeBasedSetting/fetchAllArticleCategories",
  knowledgeBasedSettingThunks.fetchAllArticleCategoriesThunk
);

export const fetchDuplicateArticle = createAsyncThunk(
  "knowledgeBasedSetting/duplicateArticle",
  knowledgeBasedSettingThunks.duplicateArticleThunk
);

export interface IKnowledgeBasedData {
  enabled: boolean;
  chatWidgetTitle: string;
  chatWidgetPlaceholder: string;
  featuredArticlesMinimizedView: boolean;
}

export interface IKnowledgeBasedSetting {
  knowledgeBasedData: IKnowledgeBasedData;
  knowledgeBasedDataAjaxStatus: AJAXSTATUS;
  updateKnowledgeBasedDataAjaxStatus: AJAXSTATUS;

  //featured articles objects
  featuredArticles: { [key: number | string]: IFeaturedArticlesData }; //contains featured article data
  featuredArticlesIds: Array<number | string>; //ids of fetched featured articles
  featuredArticleMeta: { total: number; count: number; defaultExists: boolean }; //contains fetched article count for pagination
  fetchFeaturedArticlesAjaxStatus: AJAXSTATUS; //ajax status of get all featured article
  updateFeaturedArticlesAjaxStatus: AJAXSTATUS; //ajax status update featured article
  addFeaturedArticlesAjaxStatus: AJAXSTATUS; //ajax status create featured article
  categoryData: { [categoryId: string | number]: IArticleCategoryDataV2 };
  categoryIdList: Array<number | string>;
  categoryMetaData: {
    count: number;
    total: number | null;
  };
  fetchCategoryAjaxStatus: AJAXSTATUS;
  activeFeaturedArticle: IFeaturedArticlesData; //for the add/edit featured article modal
  featuredArticleSelectLimit: number;

  fetchDuplicateArticleAjaxStatus: AJAXSTATUS;
}

export const initialState: IKnowledgeBasedSetting = {
  knowledgeBasedData: {
    enabled: false,
    chatWidgetTitle: "",
    chatWidgetPlaceholder: "",
    featuredArticlesMinimizedView: false,
  },
  knowledgeBasedDataAjaxStatus: "pending",
  updateKnowledgeBasedDataAjaxStatus: "idle",

  //featured articles
  featuredArticlesIds: [],
  featuredArticles: {},
  featuredArticleMeta: { total: 0, count: 0, defaultExists: false },
  fetchFeaturedArticlesAjaxStatus: "pending",
  updateFeaturedArticlesAjaxStatus: "idle",
  addFeaturedArticlesAjaxStatus: "idle",
  categoryData: {},
  categoryIdList: [],
  fetchCategoryAjaxStatus: "pending",
  featuredArticleSelectLimit: 4,
  categoryMetaData: {
    count: 0,
    total: 0,
  },
  activeFeaturedArticle: {
    //for the add/edit featured article modal
    id: 0,
    integrationId: 0,
    type: "custom",
    domainUrl: "",
    domainConstraint: "contains",
    featuredArticles: {},
    isDefault: false,
  },

  fetchDuplicateArticleAjaxStatus: "idle",
};

export const knowledgeBasedSettingSlice = createSlice({
  name: "knowledgeBasedSetting",
  initialState,
  reducers: knowledgeBasedSettingReducers,
  extraReducers: (builder) => {
    builder.addCase(
      fetchKnowledgeBasedData.fulfilled,
      knowledgeBasedSettingExtraReducers.fetchKnowledgeBaseDataFulfilled
    );
    builder.addCase(fetchKnowledgeBasedData.rejected, (state) => {
      state.knowledgeBasedDataAjaxStatus = "rejected";
      pushTheToast({
        position: "top-right",
        type: "danger",
        text: "Something went wrong!",
      });
    });
    //featured articles builder case
    //get all featured articles
    builder.addCase(fetchAllFeaturedArticles.pending, (state) => {
      state.fetchFeaturedArticlesAjaxStatus = "pending";
    });
    builder.addCase(
      fetchAllFeaturedArticles.fulfilled,
      knowledgeBasedSettingExtraReducers.fetchAllFeaturedArticlesFulfilled
    );
    builder.addCase(fetchAllFeaturedArticles.rejected, (state) => {
      state.fetchFeaturedArticlesAjaxStatus = "rejected";
      pushTheToast({
        position: "top-right",
        type: "danger",
        text: "Something went wrong!",
      });
    });
    builder.addCase(addFeaturedArticle.pending, (state) => {
      state.addFeaturedArticlesAjaxStatus = "pending";
    });
    builder.addCase(addFeaturedArticle.fulfilled, (state) => {
      state.addFeaturedArticlesAjaxStatus = "fulfilled";
    });
    builder.addCase(addFeaturedArticle.rejected, (state) => {
      state.addFeaturedArticlesAjaxStatus = "rejected";
      pushTheToast({
        position: "top-right",
        type: "danger",
        text: "Failed to add, domain might be already exist!",
      });
    });
    builder.addCase(updateFeaturedArticle.pending, (state) => {
      state.updateFeaturedArticlesAjaxStatus = "pending";
    });
    builder.addCase(
      updateFeaturedArticle.fulfilled,
      knowledgeBasedSettingExtraReducers.updateFeaturedArticlesFulfilled
    );
    builder.addCase(updateFeaturedArticle.rejected, (state) => {
      state.updateFeaturedArticlesAjaxStatus = "rejected";
      pushTheToast({
        position: "top-right",
        type: "danger",
        text: "Failed to update, domain might be already exist!",
      });
    });

    builder.addCase(fetchAllArticleCategories.pending, (state) => {
      state.fetchCategoryAjaxStatus = "pending";
    });
    builder.addCase(
      fetchAllArticleCategories.fulfilled,
      knowledgeBasedSettingExtraReducers.fetchAllArticleCategoriesFulfilled
    );
    builder.addCase(fetchAllArticleCategories.rejected, (state) => {
      state.fetchCategoryAjaxStatus = "rejected";
      pushTheToast({
        position: "top-right",
        type: "danger",
        text: "Failed to fetch categories!",
      });
    });

    builder.addCase(
      fetchAllArticles.fulfilled,
      knowledgeBasedSettingExtraReducers.fetchAllArticlesFulfilled
    );

    builder.addCase(
      fetchDuplicateArticle.fulfilled,
      knowledgeBasedSettingExtraReducers.fetchDuplicateArticleFulfilled
    );

    builder.addCase(fetchDuplicateArticle.rejected, (state) => {
      state.fetchDuplicateArticleAjaxStatus = "rejected";
      pushTheToast({
        type: "danger",
        text: "Something went wrong!",
        position: "top-right",
      });
    });

    builder.addCase(fetchDuplicateArticle.pending, (state) => {
      state.fetchDuplicateArticleAjaxStatus = "pending";
    });
  },
});

export const isArticleSelectedSelector = createSelector(
  [isArticleSelected],
  (isArticleSelected) => isArticleSelected
);

export const {
  resetKnowledgeBasedSetting,
  resetFeaturedArticlesData,
  resetArticleCategoriesData,
  setActiveFeaturedArticleData,
  resetActiveFeaturedArticleData,
  addArticleIdToFeatured,
  removeArticleIdRemoveFeatured,
  addDuplicateArticle,
  deleteArticleFromList,
  updateCategoryName,
  setNewOrderOfCategories,
  setNewOrderOfArticles,
} = knowledgeBasedSettingSlice.actions;

export default knowledgeBasedSettingSlice.reducer;
