/**
 * This file is the service file used for making api request.
 * It contains the createArticleFeedback function service which is explained below.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { axiosKbPreview } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants"; // this is used for testing purpose only for frontend.

export interface createArticleFeedbackResponse {
  articleFeedbackId?: number | string;
}

export interface createArticleFeedbackPayload {
  articleId: number | string;
  rating?: "happy" | "sad" | "neutral" | "yes" | "no";
  comment?: string;
  integrationIdentifier: string;
}

/**
 * For testing frontend with own api link if backend is not prepared.
 */
const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/`
  : "/api/knowledgebase/helpCenterPage/";

/**
 * This service is made to add the feedback on the article by the customers on the helpcenter page.
 */
export const createArticleFeedback = async (
  params: createArticleFeedbackPayload
) => {
  const { data: res } = await axiosKbPreview.post(
    `${apiEndPoint}${params.integrationIdentifier}/createArticleFeedback`,
    {
      articleId: params.articleId,
      rating: params.rating,
      comment: params.comment,
    }
  );

  if (res.err) {
    return {
      err: res.err,
      tokenExpire: res.tokenExpire,
    };
  }

  return res.data as createArticleFeedbackResponse;
};
