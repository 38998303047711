import { axiosJSON } from "src/globals/axiosEndPoints";
interface IGetLiveChatSidebarParam {
  count?: boolean;
}
export async function getLiveChatSidebar(params?: IGetLiveChatSidebarParam) {
  const { data, status } = await axiosJSON.get(
    `/api/liveChat/agent/getLiveChatSidebar`,
    { params }
  );

  if (data.err === true) {
    throw new Error(data.msg as string);
  }
  // console.log(normalizeData);
  return data.data;
}
