/**
 * This file defines a custom hook responsible for fetching bot settings and updating bot name and brand of bot setting.
 * 
 * @author @Anubhav-busibud
 * @author @navjyot-busibud
 * @author @yuvaraj-busibud
 */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BrandOption } from "../../../BrandModal/BrandModal";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getBotProfile } from "src/services/Bot/BotProfiles/getBotProfile.service";
import { useAppDispatch, useAppSelector } from "src/store/store";
import useDebounce from "src/hooks/useDebounce";
import { updateBotProfile } from "src/services/Bot/BotProfiles/updateBotProfile.service";
import { getAllBrandsInfiScroll } from "src/store/slices/settings/brands/brandSettings.slice";

function useUserSettings() {
  const { profileId: selectedBotId } = useParams();
  const [selectedBrand, setSelectedBrand] = useState<BrandOption>();
  const [status, setStatus] = useState( '' );

  const navigate = useNavigate();

  // Callback to handle back click 
  const handleBackClick = useCallback( () => {
    navigate( "/bot/botProfiles" );
  }, [] );

  //Fetch the botProfile data and incase of new profile return null
  const { data, isLoading, isFetching, refetch } = useQuery( {
    queryKey: ["getBotProfile", selectedBotId],
    queryFn: () => {
      if ( selectedBotId === "new" ) {
        return null;
      }
      return getBotProfile( {
        id: parseInt( selectedBotId + "" ),
      } );
    },
  } );

  const [name, setName] = useState( "" );

  const debouncedName = useDebounce( name, 500 );

  // Create a ref variable to store the previous value of 'debouncedName'
  const prevName = useRef<string | undefined>( undefined );

  const dispatch = useAppDispatch();

  // Triggers whenever 'debouncedName' changes.
  useEffect( () => {
    // Check if 'data?.name' and 'prevName.current' is defined,
    // and 'debouncedName' is different from the previous value.
    if (
      data?.name !== undefined &&
      prevName.current !== undefined &&
      debouncedName !== prevName.current
    ) {
      // update bot settings with the debounced name
      updateBotSettings( {
        id: parseInt( selectedBotId + "" ),
        name: debouncedName,
      } );
    }
    prevName.current = debouncedName;
  }, [debouncedName] );

  useEffect( () => {
    // Check if brandData exists in the data object
    if (data?.brandData) {
      // If brandData exists, set the selectedBrand state with the following properties
      setSelectedBrand({
        // Convert the id property to a Number and assign it to the value property
        value: Number(data.brandData.id),
        // Assign the name property to the label property
        label: data.brandData?.name,
        // Assign the imgURL property to the imageUrl property
        imageUrl: data.brandData?.imgURL,
      });
    }
    //Update the prevName and name of bot 
    if ( data?.name !== undefined ) {
      setName( data.name === "Untitled cern" ? "" : data.name );
      prevName.current = data.name;
    }
  }, [data?.brandId, data?.brandData, data?.name] );

  // Mutation for updating bot settings
  const updateBot = useMutation( {
    mutationKey: ["updateBotSettings"],
    mutationFn: updateBotProfile,
    // onSuccess function with parameters data, variables, and context
    onSuccess: (data, variables, context) => {
      // Extract the updatedStatus from the variables, if available
      const updatedStatus = variables?.status;
      // Check if the data contains a specific status code indicating a bot with similar config is already published
      if (
        data.statusCode === "bot_with_similar_config_already_published" ||
        data["status-code"] === "bot_with_similar_config_already_published"
      ) {
        // Handle the case where a bot with similar configuration is already published (currently empty)
        // You may add specific actions or code here if needed.
      } else if (updatedStatus) {
        // If there's an updatedStatus in the variables, set the status to the updated value
        setStatus(updatedStatus);
      }
    },
  } );
  // Create funtion to call mutation to update bot settings
  const updateBotSettings = updateBot.mutate;

  //Callback to update the bot settings when the brand is changed
  const updateBrandChange = useCallback(
    ( brandId: string ) => {
      updateBotSettings( {
        id: parseInt( selectedBotId + "" ),
        brand_id: brandId,
      } );
    },
    [selectedBotId, updateBotSettings]
  );

  // Fetch initial data on component mount
  useEffect( () => {
    dispatch( getAllBrandsInfiScroll( { start: 0 } ) );
  }, [] );

  return {
    isLoading,
    isFetching,
    handleBackClick,
    selectedBrand,
    updateBrandChange,
    name,
    setName,
    updateBot,
    refetch,
    updateBotSettings,
    data,
    setSelectedBrand,
    status
  };
}

export default useUserSettings;
