import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";

export interface getRefundRequestFollowUpQuestionsParams {
  integrationId: number | string;
  id: string ,
}

export interface AdditionalOptions {
  id : string,
  value : string
}

export interface RefundRequestFollowUpQuestions {
  elementId: string;
  elementTypeId: string | number;
  elementValue: string;
  additionalOptions: AdditionalOptions[];
  selectedValue: string | string [];
}

export interface getRefundRequestFollowUpQuestions {
  data : RefundRequestFollowUpQuestions[]
}


const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/getRefundRequestFollowUpQuestions`
  : "/api/setting/automation/refund/step/12/getRefundRequestFollowUpQuestions";


export async function fetchRefundRequestFollowUpQuestions(
    params :getRefundRequestFollowUpQuestionsParams
) {
  const {data} = await axiosJSON.get(
    apiEndPoint,
    {params}
  );

  if (data.error === true) {
    throw new Error("Some Error Occurred while fetching data");
  }

  return data.data;
}
