/**
 * This file is the custome hook file.
 * It contains useNotificationFiltersApplied custome hook.
 *
 * @author Yash Aditya
 */

import { useCallback, useMemo } from "react";
import {
  reportNotificationsActions,
  useNotifications,
} from "./useNotifications";
import { useAppSelector } from "src/store/store";
import { useQuery } from "@tanstack/react-query";
import { fetchTicketsTagsApi } from "src/services/TicketService/fetchTicketsTags";
import { getAllStatuses } from "src/services/TicketService/getAllStatuses";
import { getDraftStatusesOptions } from "src/services/TicketService/getDraftStatusesOptions";
import { fetchAllUsers } from "src/services/UserService/fetchAllUsers";
import { TicketTags } from "src/store/slices/ticketTags/ticketTags.slice";
import { getChannelList } from "src/services/InnerTicket/getChannelList";

// Custom hook for managing applied notification filters
function useNotificationFiltersApplied() {
  // Get notification context and dispatch function from useNotifications hook
  const { reportNotifications, dispatch } = useNotifications();

  const globalFiltersData = useMemo(() => {
    return {
      ...(reportNotifications.editingNotificationData?.globalFiltersApplied ??
        {}),
    };
  }, [reportNotifications]);

  const internalFiltersData = useMemo(() => {
    return {
      ...(reportNotifications.editingNotificationData?.internalFiltersApplied ??
        {}),
    };
  }, [reportNotifications]);

  const sidebarFiltersData = useMemo(() => {
    return {
      ...(reportNotifications.editingNotificationData?.sidebarFiltersApplied ??
        {}),
    };
  }, [reportNotifications]);

  const sidebarCategories = useMemo(() => {
    return {
      ...(reportNotifications.editingNotificationData?.sidebarCategories ?? {}),
    };
  }, [reportNotifications]);

  // Query for fetching all ticket statuses
  const { data: allTicketStatus } = useQuery(
    ["useStatusFilter/getTicketStatusList"],
    {
      queryFn: getAllStatuses,
      staleTime: 600000,
      cacheTime: 600000,
    }
  );

  // Query for fetching all users
  const { data: allUsers } = useQuery(["userData/getAllUsers"], {
    queryFn: async () => {
      const users: Array<{
        id: string;
        email: string;
        imageUrl?: string | null;
        name?: string | null;
        status: string;
      }> = await fetchAllUsers({ start: 0, limit: 1000 }, true);
      return users;
    },
    staleTime: 600000,
    cacheTime: 600000,
  });

  // Query for fetching all draft statuses
  const { data: allDraftStatus } = useQuery(
    ["draftStatus/getDraftStatusesOptions"],
    {
      queryFn: getDraftStatusesOptions,
      staleTime: 600000,
      cacheTime: 600000,
    }
  );

  // Query for fetching all tags
  const { data: allTags } = useQuery(["useTagFilter/fetchTicketsTagsApi"], {
    queryFn: async () =>
      fetchTicketsTagsApi() as Promise<{
        allTags: TicketTags;
        allTagsIds: number[];
      }>,
    staleTime: 600000,
    cacheTime: 600000,
  });

  // Get all brands from global store
  const allBrands = useAppSelector(
    (state) => state.globals.brandSignatureData.brands
  );

  // Query for fetching all channels
  const { data: allChannels } = useQuery(["useChannelFilter/getChannelList"], {
    queryFn: async () => {
      return getChannelList({ context: "reports" });
    },
    staleTime: 600000,
    cacheTime: 600000,
  });

  // Define agent types
  const agentTypes = useMemo(() => {
    return [
      { id: "agent", name: "Agent" },
      { id: "lead_agents", name: "Lead Agents" },
      { id: "admin", name: "Admin" },
      { id: "support", name: "Support" },
    ];
  }, [false]);

  // Callback function for deleting a filter
  const deleteFilter = useCallback(
    (e: any) => {
      const btn = e.currentTarget as HTMLButtonElement;
      const filterKey = btn.getAttribute("data-filter-key");
      const subFilterKey = btn.getAttribute("data-sub-filter-key");
      const filterId = btn.getAttribute("data-filter-id");

      if (filterKey && subFilterKey && filterId) {
        dispatch([
          reportNotificationsActions.deleteEachAppliedFilter,
          {
            filterKey,
            subFilterKey,
            filterId,
          },
        ]);
      } else {
        console.error("Filter delete metada attributes not found.");
      }
    },
    [dispatch]
  );

  // Memoized object representing globally applied filters
  const globalFiltersApplied = useMemo(() => {
    const fa: Array<{
      name: string;
      key: string;
      filters: Array<{
        id: string | number;
        value: string;
      }>;
    }> = [];
    // Logic for applying global filters

    // Apply filter for Ticket Status
    if (globalFiltersData?.ticketStatus?.length) {
      fa.push({
        name: "Ticket Status",
        key: "ticketStatus",
        filters: (allTicketStatus?.allTicketStatusesIds ?? [])
          .filter((data) =>
            globalFiltersData?.ticketStatus?.includes((data + "") as any)
          )
          .map((data) => ({
            id: allTicketStatus?.allTicketStatuses[data].id + "",
            value: allTicketStatus?.allTicketStatuses[data].statusName ?? "",
          })),
      });
    }

    // Apply filter for channels
    if (globalFiltersData?.channels?.length) {
      fa.push({
        name: "Channels",
        key: "channels",
        filters: Object.keys(allChannels ?? {})
          .filter((data) =>
            globalFiltersData?.channels?.includes((data + "") as any)
          )
          .map((data) => ({
            id: allChannels ? allChannels[data].id : "",
            value: allChannels ? allChannels[data].name : "",
          })),
      });
    }

    // Apply filter for agent types
    if (globalFiltersData?.agentTypes?.length) {
      fa.push({
        name: "Agents",
        key: "agentTypes",
        filters: agentTypes
          .filter((data) =>
            globalFiltersData?.agentTypes?.includes((data.id + "") as any)
          )
          .map((data) => ({ id: data.id, value: data.name })),
      });
    }

    // Apply filter for tags
    if (globalFiltersData?.tags?.length) {
      fa.push({
        name: "Ticket Tags",
        key: "tags",
        filters: (allTags?.allTagsIds ?? [])
          .filter((data) =>
            globalFiltersData?.tags?.includes((data + "") as any)
          )
          .map((data) => ({
            id: allTags?.allTags[data].id + "",
            value: allTags?.allTags[data].tagName ?? "",
          })),
      });
    }

    // Apply filter for brands
    if (globalFiltersData?.brands?.length) {
      fa.push({
        name: "Brands",
        key: "brands",
        filters: (allBrands ?? [])
          .filter((data) =>
            globalFiltersData?.brands?.includes((data.id + "") as any)
          )
          .map((data) => ({ id: data.id, value: data.name })),
      });
    }
    return fa;
  }, [
    globalFiltersData,
    allTicketStatus,
    allBrands,
    allChannels,
    allTags,
    agentTypes,
  ]);

  // Memoized object representing internally applied filters
  const internalFiltersApplied = useMemo(() => {
    const fa: Array<{
      name: string;
      key: string;
      filters: Array<{
        id: string | number;
        value: string;
      }>;
    }> = [];
    // Logic for applying internal filters

    // Apply filter for brands
    if (internalFiltersData?.brands?.length) {
      fa.push({
        name: "Brands",
        key: "brands",
        filters: (allBrands ?? [])
          .filter((data) =>
            internalFiltersData?.brands?.includes((data.id + "") as any)
          )
          .map((data) => ({ id: data.id, value: data.name })),
      });
    }

    // Apply filter for Ticket Status
    if (internalFiltersData?.ticketStatus?.length) {
      fa.push({
        name: "Ticket Status",
        key: "ticketStatus",
        filters: (allTicketStatus?.allTicketStatusesIds ?? [])
          .filter((data) =>
            internalFiltersData?.ticketStatus?.includes((data + "") as any)
          )
          .map((data) => ({
            id: allTicketStatus?.allTicketStatuses[data].id + "",
            value: allTicketStatus?.allTicketStatuses[data].statusName ?? "",
          })),
      });
    }

    // Apply filter for Assigned to
    if (internalFiltersData?.assignedTo?.length) {
      fa.push({
        name: "Assigned To",
        key: "assignedTo",
        filters: (allUsers ?? [])
          ?.filter((data) =>
            internalFiltersData?.assignedTo?.includes((data.id + "") as any)
          )
          .map((data) => ({ id: data.id, value: data.name ?? data.email })),
      });
    }

    // Apply filter for tags
    if (internalFiltersData?.tags?.length) {
      fa.push({
        name: "Ticket Tags",
        key: "tags",
        filters: (allTags?.allTagsIds ?? [])
          .filter((data) =>
            internalFiltersData?.tags?.includes((data + "") as any)
          )
          .map((data) => ({
            id: allTags?.allTags[data].id + "",
            value: allTags?.allTags[data].tagName ?? "",
          })),
      });
    }

    // Apply filter for drafted by
    if (internalFiltersData?.draftedBy?.length) {
      fa.push({
        name: "Drafted By",
        key: "draftedBy",
        filters: (allUsers ?? [])
          ?.filter((data) =>
            internalFiltersData?.draftedBy?.includes((data.id + "") as any)
          )
          .map((data) => ({ id: data.id, value: data.name ?? data.email })),
      });
    }

    // Apply filter for draft status
    if (internalFiltersData?.draftStatus?.length) {
      fa.push({
        name: "Draft Status",
        key: "draftStatus",
        filters: (allDraftStatus?.allDraftStatusesIds ?? [])
          .filter((id) =>
            internalFiltersData?.draftStatus?.includes((id + "") as any)
          )
          .map((id) => ({
            id: id + "",
            value: allDraftStatus?.allDraftStatuses[id].name ?? "",
          })),
      });
    }

    return fa;
  }, [
    internalFiltersData,
    allBrands,
    allTicketStatus,
    allUsers,
    allTags,
    allDraftStatus,
  ]);

  // Memoized object representing sidebar applied filters
  const sidebarFiltersApplied = useMemo(() => {
    const fa: Array<{
      name: string;
      key: string;
      filters: Array<{
        id: string | number;
        value: string;
      }>;
    }> = [];

    // Logic for applying sidebar filters

    // Checking if the categories are available or not.
    const isCategory = Object.values(sidebarCategories ?? {}).length
      ? true
      : false;

    // Apply filter for channels
    if (sidebarFiltersData?.channels?.length) {
      fa.push({
        name: "Channels",
        key: "channels",
        filters: Object.keys(allChannels ?? {})
          .filter((data) =>
            sidebarFiltersData?.channels?.includes((data + "") as any)
          )
          .map((data) => ({
            id: allChannels ? allChannels[data].id : "",
            value: allChannels ? allChannels[data].name : "",
          })),
      });
    }

    // Apply filter for message clusters
    if (sidebarFiltersData?.msgClusters?.length && isCategory) {
      fa.push({
        name: "Clusters",
        key: "msgClusters",
        filters: Object.values(sidebarCategories ?? {})
          .filter((data) =>
            sidebarFiltersData?.msgClusters?.includes((data.id + "") as any)
          )
          .map((data) => ({
            id: data.id,
            value: data.name,
          })),
      });
    }

    // Apply filter for timeClusters
    if (sidebarFiltersData?.timeClusters?.length && isCategory) {
      fa.push({
        name: "Time Clusters",
        key: "timeClusters",
        filters: Object.values(sidebarCategories ?? {})
          .filter((data) =>
            sidebarFiltersData?.timeClusters?.includes((data.id + "") as any)
          )
          .map((data) => ({
            id: data.id,
            value: data.name,
          })),
      });
    }

    return fa;
  }, [sidebarFiltersData, allChannels, sidebarCategories]);

  // Return the applied filters objects
  return {
    globalFiltersApplied,
    internalFiltersApplied,
    sidebarFiltersApplied,
    deleteFilter,
  };
}

// Export the custom hook
export default useNotificationFiltersApplied;
