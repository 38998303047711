import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";
import { Attachment } from "./getAllTemplates";
import { TinyMCEUploadFile } from "src/interfaces/TinyMCEContainer/ITinyMCEContainer";
interface Agent {
  id: number;
  name: string;
  imgUrl: string | null;
  email: string;
}

export interface ScheduleData {
  id: number;
  condition:
    | "noNewOrderPlacedAfterScheduling"
    | "noNewResponseOrMessageReceivedFromTheCustomer"
    | "none";
  chatId: number;
  message: string;
  agent: Agent;
  scheduleFollowUpType: "days" | "weeks" | "months" | "dateAndTime";
  scheduleFollowUpValue: number | string;
  scheduledToUTC: string;
  attachments: TinyMCEUploadFile[] | null;
  templateId: number;
}

export interface GetAllSchedulesData {
  schedules: { [key: number | string]: ScheduleData };
  scheduleIds: Array<number | string>;
  metaData: {
    total: number;
    count: number;
  };
}

export interface GetAllSchedulesPayload {
  chatId?: number;
  ticketId ? : number | string;
  start: number;
  limit: number;
}

console.log("IS_TEST_ACTIVE", IS_TEST_ACTIVE);

const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/schedule/getAll`
  : "/api/liveChat/agent/message/schedule/getAll";

export const getAllSchedulesLiveChat = async (payload: GetAllSchedulesPayload) => {
  const { data: res } = await axiosJSON.post(apiEndPoint, payload);

  if (res.err) {
    throw res.msg;
  }

  const ret: GetAllSchedulesData = {
    scheduleIds: [],
    schedules: {},
    metaData: {
      total: res.metaData?.total,
      count: res.metaData?.count,
    },
  };

  res.data.forEach((schedule: ScheduleData) => {
    const id = parseInt(schedule.id + "");
    ret.schedules[id] = schedule;
    ret.scheduleIds.push(id);
  });

  return ret;
};
