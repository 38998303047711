import { axiosJSON } from "src/globals/axiosEndPoints";
import { BrandAgent } from "./getBrand";

export interface GetBrandAgentPayload {
  brandId: number | string | null;
  withTicketCounts?: boolean;
  searchTerm?: string;
  start?: number;
  limit?: number;
  isExcludeUserAdmin?: boolean;
}

export interface BrandAllAgents {
  users: { [brand: number | string]: BrandAgent };
  userIds: Array<number | string>;
  metaData: {
    count: number;
    total: number;
  };
}

export async function getBrandAgents(payload: GetBrandAgentPayload) {
  const { data: res } = await axiosJSON.post(
    "/api/brand/getBrandAgents",
    payload
  );

  if (res.err) {
    throw new Error(res.msg);
  }

  const ret: BrandAllAgents = {
    users: {},
    userIds: [],
    metaData: {
      count: 0,
      total: 0,
    },
  };
  ret.metaData.count = res.metaData.count ?? 0;
  ret.metaData.total = res.metaData.total ?? 0;

  res.data.forEach((user: BrandAgent) => {
    ret.users[user.id] = user;
    ret.userIds.push(user.id);
  });

  return ret;
}
