import { GetConfigModalParams } from "src/features/ReturnAutoWorkFlow/children/ConfigModals/oldReturnModals.types";
import { axiosJSON } from "src/globals/axiosEndPoints";

interface MetaData {
  count: number;
  hasNextPage: boolean;
}

export interface AllOrderTags {
  data: string[];
  metaData: MetaData;
}

export interface GetOrderTagsParams extends GetConfigModalParams {
  limit: number;
}

async function getOrderTagsService(payload: GetOrderTagsParams) {
  const { data: res } = await axiosJSON.post(
    "/api/setting/automation/refund/order/tags/getAll",
    payload,
  );

  if (res.err || res.error) {
    throw new Error(res.msg ?? res.message ?? "Some Error Occurred.");
  }

  const ret: AllOrderTags = { data: res.data, metaData: res.metaData };

  return ret;
}

export default getOrderTagsService;
