import styles from "./SignUp.module.scss";
import helpLogo from "src/assets/images/helplama.png";
import saufterLogo from "src/assets/images/saufter-full.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { signupUser } from "src/services/Auth/signup";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { Spinner } from "react-bootstrap";
import {
  base64UrlDecode,
  passwordValidate,
  validateEmail,
} from "src/utils/utils";
import { PasswordMustContain } from "../ResetPassword";
import validMail from "src/assets/images/5.png";
import pass from "src/assets/images/6.png";
import showPassImg from "src/assets/images/7.png";
import {
  InitialConfig,
  getInitialConfig,
} from "src/services/Auth/getInitialConfig";
import Loader from "src/components/Loader";

const logo = window.location.hostname.includes(
  process.env.REACT_APP_LOGO_HOST_NAME + ""
)
  ? saufterLogo
  : helpLogo;

const SignUp = () => {
  const { payload } = useParams();
  const payloadData = useRef({ email: "", token: "" });
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPass, setShowPass] = useState(false);
  const [showCpass, setShowCpass] = useState(false);
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [showCross, setShowCross] = useState(false);
  const [focusedEle, setFocusedEle] = useState("pass" as "pass" | "re-pass");
  const navigate = useNavigate();
  const handelSignUpSubmit = useCallback((e: any) => {
    e.preventDefault();
    if (payloadData.current.token === "") {
      setShowCross(true);
      pushTheToast({
        type: "danger",
        text: "Invalid Token!",
        position: "top-right",
      });
    } else if (
      e.target.elements.password.value === e.target.elements.password2.value &&
      passwordValidate(e.target.elements.password.value)
    ) {
      setLoading(true);
      signupUser({
        name: e.target.elements.name.value.trim(),
        password: e.target.elements.password.value,
        token: payloadData.current.token,
      })
        .then((res) => {
          setLoading(false);
          navigate("/login");
          setTimeout(() => {
            pushTheToast({
              type: "success",
              text: "Signed up successfully!",
              position: "top-right",
            });
          }, 0);
        })
        .catch((err) => {
          setLoading(false);
          pushTheToast({
            type: "danger",
            text: "User might already exists!",
            position: "top-right",
          });
        });
    } else {
      setShowCross(true);
      pushTheToast({
        type: "warning",
        text: "Please check passwords!",
        position: "top-right",
      });
    }
  }, []);

  useMemo(() => {
    try {
      const data = JSON.parse(base64UrlDecode(payload + ""));
      payloadData.current = {
        email: data.email,
        token: data.token,
      };
    } catch (e) {
      // navigate("/login");
      console.error(e);
      setTimeout(() => {
        pushTheToast({
          type: "danger",
          text: "Invalid Sign Up URL!",
          position: "top-right",
        });
      }, 0);
    }
  }, [payload]);

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<InitialConfig>();

  useEffect(() => {
    const fetchInitialConfig = async () => {
      try {
        // fetching the site key and enabled flag from initial config api
        const response = await getInitialConfig({ context: "signup" });
        setData(response);
      } catch (error) {
        console.error("Error fetching initial config:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchInitialConfig();
  }, []);

  if (isLoading) {
    return (
      <div className="w-100 h-100">
        <Loader />
      </div>
    );
  }

  return (
    <div className={`${styles.SignUpMain}`}>
      <section className={`${styles.signPages}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="logo-helplama d-flex justify-content-center">
                <img className={`${styles.helplamaLogo}`} src={logo} />
              </div>
              <div className="sign-up-headings text-center">
                <h1 className={`${styles.signUp}`}>Sign Up</h1>
                <p className={`${styles.subHeading}`}>
                  Please fill all the required fields
                </p>
              </div>
            </div>
          </div>
          <form
            className={`${styles.signInForm} mt-4`}
            onSubmit={handelSignUpSubmit}
          >
            <div className="row">
              <div className="col-md-6 col-sm-12 col-12">
                <PasswordMustContain
                  password={focusedEle === "pass" ? password : confirmPassword}
                  showCross={showCross}
                  className={`mt-3`}
                />
              </div>
              <div className="col-md-6 col-sm-12 col-12">
                <div className="input-form-group">
                  <div className="mb-4 col-md-12 col-lg-12 mt-2 effect-field">
                    <input
                      type="text"
                      className={`form-control ${styles.signInControl}`}
                      placeholder="Display Name"
                      name="name"
                      required
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <div className="hover_content"></div>
                  </div>
                  <div className="mb-4 mt-3 col-lg-12 col-md-12 effect-field">
                    <input
                      type="email"
                      className={`form-control ${styles.signInControl}`}
                      placeholder="Email Address"
                      name="email"
                      required
                      defaultValue={payloadData.current.email}
                      readOnly={true}
                    />
                    {/* show only mail id is valid  */}
                    {validateEmail(payloadData.current.email) && (
                      <div className={`h-0 d-flex justify-content-end`}>
                        <img
                          src={validMail}
                          alt="tick"
                          className={`${styles.mailImg}`}
                        />
                      </div>
                    )}
                    <div className="hover_content"></div>
                  </div>
                  {/* <div className="mb-4 col-md-12 col-lg-12 effect-field">
                  <input
                    type="tel"
                    className={`form-control ${styles.signInControl}`}
                    id="Phone"
                    placeholder="Phone"
                  />
                  <div className="hover_content"></div>
                </div> */}
                  <div
                    className={`mb-4 col-md-12 col-lg-12 effect-field ${styles.setHeight}`}
                  >
                    <input
                      type={showPass ? "text" : "password"}
                      className={`form-control ${styles.signInControl} ${
                        showCross && !passwordValidate(password)
                          ? styles.notValid
                          : ""
                      }`}
                      name="password"
                      placeholder="Create Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      onFocus={() => {
                        setFocusedEle("pass");
                      }}
                    />
                    {password && (
                      <div className={`h-0 d-flex justify-content-end`}>
                        <img
                          // src={pass}
                          src={showPass ? `${showPassImg}` : `${pass}`}
                          alt="tick"
                          className={`${styles.passImg}`}
                          onClick={(e) => {
                            setShowPass(!showPass);
                          }}
                        />
                        {/* <img
                        src={showPass}
                        alt="tick"
                        className={`${styles.passImg}`}
                      /> */}
                      </div>
                    )}
                    <div className="hover_content"></div>
                  </div>
                  <div
                    className={`mb-4 col-md-12 col-lg-12 effect-field ${styles.setHeight}`}
                  >
                    <input
                      type={showCpass ? "text" : "password"}
                      className={`form-control ${styles.signInControl} ${
                        showCross &&
                        (password !== confirmPassword ||
                          !passwordValidate(confirmPassword)) &&
                        confirmPassword.trim() !== ""
                          ? styles.notValid
                          : ""
                      }`}
                      name="password2"
                      placeholder="Repeat Password"
                      required
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      onFocus={() => {
                        setFocusedEle("re-pass");
                      }}
                    />
                    {confirmPassword && (
                      <div className={`h-0 d-flex justify-content-end`}>
                        <img
                          src={showCpass ? `${showPassImg}` : `${pass}`}
                          alt="tick"
                          className={`${styles.passImg}`}
                          onClick={(e) => {
                            setShowCpass(!showCpass);
                          }}
                        />
                        {/* <img
                        src={showPass}
                        alt="tick"
                        className={`${styles.passImg}`}
                      /> */}
                      </div>
                    )}
                    {showCross &&
                      password !== confirmPassword &&
                      confirmPassword.trim() !== "" && (
                        <>
                          <div className={`${styles.notMatched}`}>
                            The password does not match
                          </div>
                        </>
                      )}
                    <div className="hover_content"></div>
                  </div>
                  {/* <div className={`col-md-12 ${styles.formCheck} text-center`}>
                  <label className={`${styles.logic_input}`}>
                    Accept Terms & Conditions
                    <input type="checkbox" className={`${styles.checkBox}`} />
                    <span className={`${styles.checkMark}`}></span>
                  </label>
                </div> */}
                </div>
                <div className="bottom-links text-center pb-2">
                  <p className={`${styles.accountLink} mt-3`}>
                    Already a member?{" "}
                    <Link className={`${styles.linkCss}`} to="/login">
                      <span className={`${styles.loginSpan}`}> Log In</span>
                    </Link>{" "}
                  </p>
                </div>
                <div className="d-flex justify-content-center">
                  <button
                    type="submit"
                    className={`btn btn-primary d-flex justify-content-center ${styles.signInBtn} max-content-width`}
                  >
                    {loading && (
                      <Spinner
                        className={`mx-1 my-auto`}
                        animation="border"
                        color="white"
                      />
                    )}
                    <span className={`m-auto`}>Sign Up</span>
                  </button>
                </div>
                {/* Social login buttons */}
                <div className="d-flex flex-column flex-md-row justify-content-center align-items-center mt-1 pt-1">
                  {data?.socialLogins?.map((socialLogin) => {
                    return (
                      <a
                        href={`${process.env.REACT_APP_SITE_URL ?? ""}${
                          socialLogin.route
                        }?token=${payloadData.current.token}`}
                        className={`${styles.socialBtn} m-1 mb-2 mb-md-0`}
                      >
                        <img src={socialLogin.icon} alt="" className="me-2" />
                        <span>{socialLogin.name}</span>
                      </a>
                    );
                  })}
                </div>
              </div>
            </div>
          </form>
          {/* <div className="bottom-links text-center pb-2">
            <p className={`${styles.accountLink}`}>
              <Link className={`${styles.linkCss}`} to="/login">
                {" "}
                Already have an account{" "}
              </Link>{" "}
              &nbsp; | &nbsp; Terms and Conditions
            </p>
          </div> */}
        </div>
      </section>
    </div>
  );
};
export default SignUp;
