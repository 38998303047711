import styles from "./DraftStatus.module.scss";
import { useAppSelector } from "src/store/store";
import Dropdown from "react-bootstrap/esm/Dropdown";
import { useState, Fragment } from "react";
import log from "loglevel";
import useChangeDraftStatus from "src/hooks/useChangeDraftStatus";
import { Spinner } from "react-bootstrap";

export interface DraftStatusProps {
  ticketId: string | number;
  onStatusChange: (value: any) => void;
  className?: string;
  currentStatus: { draftStatusValue: string; draftStatusId: number };
  messageId?: number | string | null;
}

function DraftStatus({
  ticketId,
  onStatusChange,
  className = "",
  currentStatus,
  messageId = null,
}: DraftStatusProps) {
  const scopes = useAppSelector(
    (state) => state.globals.currentUserData?.scopes
  );
  const [toggleState, setToggleState] = useState<boolean>(false);
  const { changeDraftStatus } = useChangeDraftStatus();
  const allDraftStatusesIds = useAppSelector(
    (state) => state.ticketMsgDraftStatus.allDraftStatusesIds
  );
  const allDraftStatuses = useAppSelector(
    (state) => state.ticketMsgDraftStatus.allDraftStatuses
  );
  const [loading, setLoading] = useState(false);

  const handleOnSelectStatus = (id: any) => {
    if (onStatusChange) {
      onStatusChange("pending");
      setLoading(true);
    }
    const payload = {
      draftStatusId: allDraftStatuses[id].id,
      draftStatusValue: allDraftStatuses[id].name,
    };
    // log.debug({ payload });
    if (messageId == null) {
      onStatusChange("rejected");
      setLoading(false);
    } else {
      changeDraftStatus(
        allDraftStatuses[id].id,
        ticketId,
        messageId,
        (success: any) => {
          setLoading(false);
          if (success) {
            onStatusChange(payload);
          } else {
            onStatusChange("rejected");
          }
        }
      );
    }
  };

  return (
    <>
      <div
        className={`ignoreClick ${styles.draftStatusMain} ${className}`}
        // onClick={(e: any) => e.stopPropagation()}
      >
        {currentStatus ? (
          <Dropdown
            onToggle={setToggleState}
            onSelect={handleOnSelectStatus}
            align="end"
          >
            <Dropdown.Toggle
              id={`${getToggleBtnStyleId(
                currentStatus.draftStatusValue.toLowerCase()
              )}`}
              disabled={
                currentStatus.draftStatusValue.toLowerCase() ===
                "pending to draft"
                  ? true
                  : scopes && scopes.includes("change_draft_status")
                  ? false
                  : true
              }
            >
              <span className={`d-flex`}>
                <span className={`my-auto mx-1`}>
                  {currentStatus.draftStatusValue}
                </span>
                {loading ? (
                  <Spinner
                    className={`mx-1 my-auto`}
                    variant="secondary"
                    animation="border"
                    size="sm"
                  />
                ) : (
                  <i
                    className={`my-auto mx-1 fa-solid fa-caret-${
                      toggleState ? "up" : "down"
                    } ms-2`}
                  />
                )}
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu
              style={{ border: "none", backgroundColor: "transparent" }}
            >
              <div className={`${styles.overlay}`}>
                <div className={`${styles.grey} mx-2 py-2 mb-2`}>
                  Mark ticket status as
                </div>
                {allDraftStatusesIds.map((id) => {
                  if (
                    currentStatus.draftStatusId === id ||
                    allDraftStatuses[id].name.toLowerCase() ===
                      "pending to draft" ||
                    (allDraftStatuses[id].name.toLowerCase() === "void" &&
                      scopes &&
                      !scopes.includes("can_handle_draft_void"))
                  ) {
                    return <Fragment key={id}></Fragment>;
                  }
                  return (
                    <div className={`px-2`}>
                      <Dropdown.Item
                        as="button"
                        eventKey={id}
                        key={id}
                        className={`d-flex ${styles.dropDown_bg}`}
                      >
                        <div className={`${styles.hoverBar}`}> </div>
                        <span className={`${styles.dropDownText}`}>
                          {allDraftStatuses[id].name}
                        </span>
                      </Dropdown.Item>
                    </div>
                  );
                })}
              </div>
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

/* 
Pending to draft
Drafted
Edited
Draft Again
approved(Don't send)
approved
sent

 */

const getToggleBtnStyleId = (draftStatus: string) => {
  switch (draftStatus.toLowerCase()) {
    case "pending to draft":
      return styles.dropdownTogglePendingDraft;
    case "drafted":
      return styles.dropdownToggleDrafted;
    case "edited":
      return styles.dropdownToggleEdited;
    case "draft again":
      return styles.dropdownToggleDraftAgain;
    case "approved(don't send)":
      return styles.dropdownToggleApprDontSent;
    case "approved":
      return styles.dropdownToggleAppr;
    case "sent":
      return styles.dropdownToggleSent;
    default:
      return styles.dropdownTogglePendingDraft;
  }
};

export default DraftStatus;
