import { useState } from "react";
import styles from "./ConfigureGroup.module.scss";
import pen from "src/assets/images/PenBlue.png";
import pendingImg from "src/assets/images/pendingIcon.png";
import { Modal } from "react-bootstrap";
import ConfigurationModal from "../ConfigurationModal/ConfigurationModal";
import deelteIcon from "src/assets/images/delete.png";
import { Group } from "src/services/Automation/getAutomationById.service";

/**
 * Interface for Configuration Group props
 */
interface Props {
  conditionGroup: Group;
  showDelete: boolean;
  handleDelete: () => void;
  groupIndex: number;
  updateConditionGroup: (newGroup: Group) => void;
  setShowErrorModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * Configuration modal button in automation
 */
const ConfigureGroup = ({
  conditionGroup,
  groupIndex,
  showDelete,
  handleDelete,
  updateConditionGroup,
  setShowErrorModal,
}: Props) => {
  const [isActive, setIsActive] = useState(conditionGroup.status === "pending");
  const [isModalVisible, setModalVisible] = useState(false);

  return (
    <div>
      <div
        className={`d-flex align-items-center justify-content-between ${styles.configureBtnWrapper}`}
      >
        {/* Configuration Button */}
        <div
          d-flex
          flex-column
        >
          <div className="d-flex align-items-center me-2">
            <button
              className={`${styles.nextStepButton} btn px-3 ${
                !isActive && styles.disabled
              }`}
              onClick={() => setModalVisible(true)}
            >
              <div>
                <p className="mb-0">
                  <span className={`d-block ${styles.configBtn}`}>
                    {conditionGroup.status === "completed" && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                      >
                        <defs>
                          <filter
                            id="Path_3051"
                            x="0"
                            y="0"
                            width="22"
                            height="22"
                            filterUnits="userSpaceOnUse"
                          >
                            <feOffset dy="3" />
                            <feGaussianBlur
                              stdDeviation="6.5"
                              result="blur"
                            />
                            <feFlood flood-color="#c1c1c1" />
                            <feComposite
                              operator="in"
                              in2="blur"
                            />
                            <feComposite in="SourceGraphic" />
                          </filter>
                        </defs>
                        <g
                          id="Group_5944"
                          data-name="Group 5944"
                        >
                          <g filter="url(#Path_3051)">
                            <g
                              id="Path_3051-2"
                              data-name="Path 3051"
                              fill="none"
                            >
                              <path
                                d="M11,0A11,11,0,1,1,0,11,11,11,0,0,1,11,0Z"
                                stroke="none"
                              />
                              <path
                                d="M 11 2 C 6.037380218505859 2 2 6.037380218505859 2 11 C 2 15.96261978149414 6.037380218505859 20 11 20 C 15.96261978149414 20 20 15.96261978149414 20 11 C 20 6.037380218505859 15.96261978149414 2 11 2 M 11 0 C 17.07513046264648 0 22 4.924869537353516 22 11 C 22 17.07513046264648 17.07513046264648 22 11 22 C 4.924869537353516 22 0 17.07513046264648 0 11 C 0 4.924869537353516 4.924869537353516 0 11 0 Z"
                                stroke="none"
                                fill="#fff"
                              />
                            </g>
                          </g>
                          <path
                            id="Icon_material-done"
                            data-name="Icon material-done"
                            d="M8.437,14.716l-2.5-2.5-.834.834,3.337,3.337,7.15-7.15L14.752,8.4Z"
                            fill="#fff"
                            stroke="#fff"
                            stroke-width="1"
                          />
                        </g>
                      </svg>
                    )}
                    <span className="ms-2">{`Configure group ${
                      groupIndex + 1
                    }`}</span>
                  </span>
                </p>
              </div>
            </button>
          </div>
          {/* Edit Configuration */}
          {conditionGroup.status === "completed" && (
            <div
              className={`mt-2 ${styles.editText}`}
              role="button"
              onClick={() => setIsActive(true)}
            >
              <img
                src={pen}
                alt=""
              />
              <span className="ps-1">Edit configuration</span>
            </div>
          )}
          {/* Pending configuration */}
          {conditionGroup.status === "pending" && (
            <div className="d-flex align-items-center pt-2">
              <img
                src={pendingImg}
                alt=""
                className={`${styles.pendingImg}`}
              />
              <span className={`ps-1 ${styles.pendingText}`}>Pending</span>
            </div>
          )}
        </div>
        {/* Modal */}
        <Modal
          show={isModalVisible}
          onHide={() => setModalVisible(false)}
          contentClassName={`${styles.modalContent}`}
          dialogClassName={`${styles.dialogClass}`}
          centered={false}
          enforceFocus={false}
          backdropClassName={styles.backDropClass}
        >
          <ConfigurationModal
            handleCloseModal={() => setModalVisible(false)}
            conditionGroup={conditionGroup}
            groupIndex={groupIndex}
            updateConditionGroup={updateConditionGroup}
            setShowErrorModal={setShowErrorModal}
          />
        </Modal>
        {showDelete && (
          <div onClick={handleDelete}>
            <img
              src={deelteIcon}
              alt="deleteIcon"
              height={18}
              className="cursor-pointer"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ConfigureGroup;
