import AxiosImg from "src/components/AxiosImg";
import UserAvatar from "src/components/UserAvatar";
import { useAppSelector } from "src/store/store";
import styles from "../../../../HistoryInnerView.module.scss";

type Props = {
  imageURL?: string | null;
  name: string;
  email: string;
  isPublicAttachmentUrl?: boolean;

};

function ActiveCustomer({ email, imageURL, name , isPublicAttachmentUrl}: Props) {
  return (
    <div className="mt-2 px-4 d-flex mb-3">
      <div className={`${styles.imgWrapper}`}>
        {imageURL ? (
          <AxiosImg url={imageURL} className={`rounded-circle`} isDirectURL={isPublicAttachmentUrl} />
        ) : (
          <UserAvatar name={name ?? "NA"} size={32} />
        )}
      </div>
      <div className={`${styles.userInfo}`}>
        <h6 className={`mb-0 ${styles.userName}`}>{name}</h6>
        <div className={`w-100`}>
          <p className={`mb-0 text-truncate ${styles.userEmail}`}>{email}</p>
        </div>
      </div>
    </div>
  );
}

export default ActiveCustomer;
