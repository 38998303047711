import styles from "./DisplayAddedTags.module.scss";
import { useAppSelector } from "src/store/store";
import {
  getAllTagsSelector,
  getSelectedTagsIdsSelector,
} from "src/store/slices/ticketTags/ticketTags.slice";

const DisplayAddedTags = (props: any) => {
  const selectedTagsIds = useAppSelector(getSelectedTagsIdsSelector);
  const { allTags } = useAppSelector(getAllTagsSelector);

  return (
    <div className={`me-2 ${styles.fixWidth}`}>
      {selectedTagsIds.map((tagId) => (
        <span
          key={tagId}
          className={`${styles.addTagEmailBottom} user-select-none`}
        >
          {allTags[tagId].tagName}
        </span>
      ))}
      <i
        className={`fas solid fa-plus ${styles.addTagsPlusIcon}`}
        onClick={props.onClick}
      />
    </div>
  );
};

export default DisplayAddedTags;
