import { initialState, ITagsSettings } from "./tagsSettings.slice";

const resetTagsSettings = (state: ITagsSettings) => {
  state = { ...initialState };
  return state;
};

const setTagsSearchValue = (
  state: ITagsSettings,
  { payload }: { payload: { searchValue: string } }
) => {
  state.filters.searchValue = payload.searchValue;
};

const resetTagsSearchValue = (state: ITagsSettings) => {
  state.filters.searchValue = "";
}

export default { resetTagsSettings, setTagsSearchValue, resetTagsSearchValue };
