import { useMemo, useState } from "react";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import cautionImg from "src/assets/images/caution.png";
import cols from "src/assets/images/more-column.png";
import ActionButton from "src/components/ActionButton";
import { SegmentTypes } from "src/routes/CustomerSegments/hooks/useCustomerSegments";
import { useSegmentView } from "../../hooks/useSegmentView";
import styles from "./MoreCols.module.scss";

const getHeadingName = (segmentType: SegmentTypes | null): string => {
  switch (segmentType) {
    case "companySegment":
      return `Display Company attributes`;
    case "userSegment":
      return `Display User attributes`;
    default:
      return `Display Table attributes`;
  }
};

/**
 * Renders a dropdown menu with a list of columns.
 *
 * @returns The rendered dropdown menu.
 */
function MoreCols() {
  const { activeSegmentDetails, segmentView, dispatch } = useSegmentView();

  const [toggleState, setToggleState] = useState(false);

  const heading = useMemo(() => {
    return getHeadingName(activeSegmentDetails.activeSegmentType);
  }, [activeSegmentDetails]);

  return (
    <Dropdown
      show={toggleState}
      onToggle={setToggleState}
      id="moreColsBtnCompany"
    >
      <Dropdown.Toggle
        as="div"
        id="dropdown-custom-components"
        bsPrefix={styles.caret}
      >
        <ActionButton className={`me-1`}>
          <span className="me-1">
            <img
              src={cols}
              alt=""
              className={styles.moreColsImg}
            />
          </span>
          More Columns
          <span>
            <i
              className={`fa-solid fa-caret-${
                toggleState ? "up" : "down"
              } ms-2`}
            />
          </span>
        </ActionButton>
      </Dropdown.Toggle>
      <Dropdown.Menu className={styles.dropdownMenu}>
        <ul
          className={`list-unstyled mb-0 ${styles.columnDropdown}`}
          aria-labelledby="table"
        >
          <div className={`ms-2 mt-1 ${styles.moreColsHead}`}>{heading}</div>
          {segmentView.allColumnKeys.map((key) => {
            const col = segmentView.allColumns[key];
            return (
              <div
                key={key}
                className="ms-2 mt-1"
              >
                {/* Column Check box */}
                <input
                  data-lpignore="true"
                  type="checkbox"
                  className={`align-middle form-check-input ${styles.checkBox}`}
                  onChange={(e) => {}}
                  onClick={(e) => {
                    dispatch("setColumnSelect", {
                      key,
                      value: !col.selected,
                    });
                  }}
                  checked={col.required ? true : col.selected ?? false}
                  disabled={col.required}
                  id={`segment_column_${key}_checkbox`}
                />

                {/* Column Name Label */}
                <label
                  htmlFor={`segment_column_${key}_checkbox`}
                  className={`ms-2 user-select-none ${styles.colItems}`}
                >
                  {col.name}
                </label>

                {/* Column Description Overlay */}
                {col.description ? (
                  <OverlayTrigger
                    overlay={
                      <Tooltip>
                        <span className={`${styles.overlayText}`}>
                          {col.description}
                        </span>
                      </Tooltip>
                    }
                    trigger={["hover", "focus"]}
                    placement="top"
                  >
                    <span className="mx-1">
                      <img
                        src={cautionImg}
                        alt="moreInfo"
                        width={12}
                      />
                    </span>
                  </OverlayTrigger>
                ) : null}
              </div>
            );
          })}
        </ul>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default MoreCols;
