import styles from "./ToastModals.module.scss";

interface Props {
  onHide: () => void;
  callAgain: () => void;
}

function Installation({ onHide, callAgain }: Props) {
  return (
    <div className="px-4">
      <div className={`${styles.topIcon} mt-5 mb-4 mx-auto`}>
        <i className={`material-icons ${styles.errorI}`}>&#xe001;</i>
      </div>
      <div className={`${styles.title} mx-auto`}>
        {`Sorry, We couldn't detect installation!`}
      </div>
      <div className={`${styles.subtitle} mx-auto my-2`}>
        {`Please put the code snippet in your web page body then keep your website opened in the new tab, then comeback here and check again!`}
      </div>
      <div className="d-flex justify-content-between mb-4 px-2">
        <button className={`px-3 py-1 ${styles.cancelBtn}`} onClick={onHide}>
          Cancel
        </button>
        <button className={`px-3 py-1 ${styles.checkBtn}`} onClick={callAgain}>
          Check Again
        </button>
      </div>
    </div>
  );
}

export default Installation;
