import { useCallback, useEffect, useMemo, useState } from "react";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import {
  getConditionValueOrDefault,
  IVariableName,
} from "src/features/ReturnAutoWorkFlow/children/ConfigModals/store/returnModalsSliceHelpers";
import { useReturnAutoWorkFlow } from "src/features/ReturnAutoWorkFlow/hooks/useReturnAutoWorkFlow";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  addCondition,
  addOrderType,
  deleteCondition,
  deleteOrderTypeTab,
  IAntiRefundOrderType,
  IConditionTypes,
  pushCurrentHashForSelectedTab,
  resetConfig,
  setSelectedOrderTypeId,
  toggleAllOrder,
  updateCondition,
  updateOrderType,
} from "../../../store/slices/AntiRefundStrategiesOrdersSlice/antiRefundStrategiesOrders.slice";
import {
  fetchAntiRefundStrategiesOrdersThunk,
  updateAntiRefundStrategiesOrdersThunk,
  validateAntiRefundOrdersThunk,
} from "../../../store/slices/AntiRefundStrategiesOrdersSlice/antiRefundStrategiesOrders.thunk";
import {
  useFetchReturnProducts,
  useFetchReturnProductTags,
} from "src/features/ReturnAutoWorkFlow/hooks/useFetchReturnConditions";

function useReturnOrder({ onSave }: { onSave: (isSaved: boolean) => void }) {
  const dispatch = useAppDispatch();
  const [saveFailed, setSaveFailed] = useState(false);
  const { returnAutoWorkFlow } = useReturnAutoWorkFlow();
  const { loadingStatus, fetchProducts, products } = useFetchReturnProducts();
  const {
    fetchProductTags,
    loadingStatus: loadingStatusTags,
    productTags,
  } = useFetchReturnProductTags();

  const {
    fetchAjaxStatus,
    antiRefundOrderTypesData: {
      selectedOrderTypeId,
      antiRefundOrderTypes,
      operatorsNames: operators,
      variableNameToValueType: valuesTypes,
      conditionOptions: conditionOptionsRaw,
      defaultOptions: defaultOptionsRaw,
    },
    updateAjaxStatus,
    initialHash,
    currentHash,
  } = useAppSelector((state) => state.antiRefundStrategiesOrders);

  // Get the selected order from store based on the 'selectedOrderTypeId'.
  const selectedOrder = useMemo(() => {
    const order = antiRefundOrderTypes.find(
      (order) => order.orderTypeId === selectedOrderTypeId,
    );

    return order;
  }, [antiRefundOrderTypes, selectedOrderTypeId]);

  // Find the order type with a matching ID
  const selectedOrderTypeIndex = useAppSelector((state) =>
    state.antiRefundStrategiesOrders.antiRefundOrderTypesData.antiRefundOrderTypes.findIndex(
      (orderType) => orderType.orderTypeId === selectedOrderTypeId,
    ),
  );

  // Get the 'reasons' data from store based on the selected order type and its index.
  const reasons = useAppSelector(
    (state) =>
      state.antiRefundStrategiesOrders.antiRefundOrderTypesData
        .antiRefundOrderTypes[selectedOrderTypeIndex].refundRequests.reasons,
  );

  useEffect(() => {
    if (!returnAutoWorkFlow.integrationId) {
      return;
    }
    // Reset the configuration to its initial state
    dispatch(resetConfig());
    // Fetch configuration data for Step 13
    dispatch(
      fetchAntiRefundStrategiesOrdersThunk({
        integrationId: returnAutoWorkFlow.integrationId,
      }),
    );
  }, [dispatch, returnAutoWorkFlow.integrationId]);

  useEffect(() => {
    if (loadingStatusTags === "idle" && productTags.length === 0) {
      fetchProductTags();
    }

    if (loadingStatus === "idle" && products.length === 0) {
      fetchProducts();
    }
  }, [
    fetchProductTags,
    fetchProducts,
    loadingStatus,
    loadingStatusTags,
    productTags.length,
    products.length,
  ]);

  const isAddBtnEnabled = useMemo(
    () => selectedOrder?.isAddConditionEnabled ?? false,
    [selectedOrder?.isAddConditionEnabled],
  );

  const conditionOptions = useMemo(
    () => Object.values(conditionOptionsRaw).map((option) => option.name),
    [conditionOptionsRaw],
  );

  const defaultOptions = useMemo(
    () => Object.values(defaultOptionsRaw).map((option) => option.name),
    [defaultOptionsRaw],
  );

  //function to update tab name
  const dispUpdateOrderType = useCallback(
    (id: string, updateObj: any) => {
      dispatch(
        updateOrderType({
          orderTypeDetail: {
            ...antiRefundOrderTypes,
            orderTypeId: id,
            ...updateObj,
          },
        }),
      );
      setSaveFailed(false);
    },
    [antiRefundOrderTypes, dispatch],
  );

  //function to update the current tab hash string, which is used to identify if the data is changed or not
  const dispPushCurrentHashForSelectedTab = useCallback(
    (id: string) => {
      dispatch(
        pushCurrentHashForSelectedTab({
          orderTypeId: id,
        }),
      );
      setSaveFailed(false);
    },
    [dispatch],
  );

  //function to add new tab
  const dispAddTab = useCallback(() => {
    dispatch(addOrderType());
    setSaveFailed(false);
  }, [dispatch]);

  //function to validate condition of passed tab id
  const dispValidateCondition = useCallback(() => {
    setSaveFailed(false);
  }, []);

  //function to add new condition for the passed tab id
  const dispAddCondition = useCallback(
    (id: string, e?: { target: { value: string } }) => {
      dispatch(
        addCondition({
          orderTypeId: id,
          condition: e ? e.target.value : "",
        }),
      );
      setSaveFailed(false);
    },
    [dispatch],
  );

  //function to delete a condition by its id
  const dispDeleteCondition = useCallback(
    (conId: string, id: string) => {
      dispatch(
        deleteCondition({
          conditionId: conId,
          orderTypeId: id,
        }),
      );
      setSaveFailed(false);
    },
    [dispatch],
  );

  //function to update conditionType of a condition
  const dispUpdateConditionForVariables = useCallback(
    (
      id: string,
      e: { target: { value: string } },
      condition: IConditionTypes,
    ) => {
      dispatch(
        updateCondition({
          orderTypeId: id,
          conditionToUpdate: {
            ...condition,
            variableName: e.target.value as IVariableName,
            operator: operators[e.target.value as IVariableName][0],
            values: getConditionValueOrDefault(
              valuesTypes[e.target.value as IVariableName],
            ),
          },
        }),
      );
      setSaveFailed(false);
    },
    [dispatch, operators, valuesTypes],
  );

  //function to update operator of a condition
  const dispUpdateConditonForOperator = useCallback(
    (
      id: string,
      e: { target: { value: string } },
      condition: IConditionTypes,
    ) => {
      dispatch(
        updateCondition({
          orderTypeId: id,
          conditionToUpdate: {
            ...condition,
            operator: e.target.value,
          },
        }),
      );
      setSaveFailed(false);
    },
    [dispatch],
  );

  //function to update condition value by its value type
  const dispUpdateConditionForValues = useCallback(
    (id: string, type: string, value: any, condition: IConditionTypes) => {
      switch (type) {
        case "day_type":
          dispatch(
            updateCondition({
              orderTypeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  day_type: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;
        case "days":
          dispatch(
            updateCondition({
              orderTypeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  days: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;
        case "tags":
          dispatch(
            updateCondition({
              orderTypeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  tags: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;
        case "codes":
          dispatch(
            updateCondition({
              orderTypeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  codes: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;
        case "numbers":
          dispatch(
            updateCondition({
              orderTypeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  numbers: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;

        case "countryName":
          dispatch(
            updateCondition({
              orderTypeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  countryName: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;
        case "amount":
          dispatch(
            updateCondition({
              orderTypeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  amount: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;
        case "currency":
          dispatch(
            updateCondition({
              orderTypeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  currency: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;

        case "items":
          dispatch(
            updateCondition({
              orderTypeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  items: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;
        default:
          break;
      }
      setSaveFailed(false);
    },
    [dispatch],
  );

  //function to save/submit the configuration modal data
  const dispPostConfig = useCallback(async () => {
    if (!returnAutoWorkFlow.integrationId) {
      return;
    }

    const res = await dispatch(validateAntiRefundOrdersThunk());
    if (res.payload === 0) {
      dispatch(
        updateAntiRefundStrategiesOrdersThunk({
          callback: () => {
            onSave(true);
          },
          integrationId: returnAutoWorkFlow.integrationId,
        }),
      );
    } else {
      setSaveFailed(true);
      pushTheToast({
        type: "warning",
        text: "Please fill the required fields!",
        position: "top-right",
      });
    }
  }, [dispatch, onSave, returnAutoWorkFlow.integrationId]);

  const onDiscard = useCallback(() => {
    if (!returnAutoWorkFlow.integrationId) {
      return;
    }

    dispatch(
      fetchAntiRefundStrategiesOrdersThunk({
        integrationId: returnAutoWorkFlow.integrationId,
      }),
    );
  }, [dispatch, returnAutoWorkFlow.integrationId]);

  const onToggleAllOrder = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, order: IAntiRefundOrderType) => {
      dispatch(
        toggleAllOrder({
          orderTypeId: order.orderTypeId.toString(),
          value: e.currentTarget.checked,
        }),
      );
      dispPushCurrentHashForSelectedTab(order.orderTypeId.toString());
    },
    [dispPushCurrentHashForSelectedTab, dispatch],
  );

  const onDeleteTab = useCallback(
    (id: string) => {
      dispatch(deleteOrderTypeTab({ id }));
    },
    [dispatch],
  );

  const onSelectTab = useCallback(
    (eventKey: string | null) => {
      if (selectedOrderTypeId !== eventKey) {
        dispatch(setSelectedOrderTypeId({ orderTypeId: eventKey ?? "" }));
      }
    },
    [dispatch, selectedOrderTypeId],
  );

  return {
    reasons,
    operators,
    antiRefundOrderTypes,
    selectedOrderTypeId,
    fetchAjaxStatus,
    saveFailed,
    isAddBtnEnabled,
    conditionOptions,
    defaultOptions,
    updateAjaxStatus,
    initialHash,
    currentHash,
    dispAddCondition,
    dispAddTab,
    dispDeleteCondition,
    dispPostConfig,
    dispPushCurrentHashForSelectedTab,
    dispUpdateConditionForValues,
    dispUpdateConditionForVariables,
    dispUpdateConditonForOperator,
    dispUpdateOrderType,
    dispValidateCondition,
    onToggleAllOrder,
    onDiscard,
    onDeleteTab,
    onSelectTab,
  };
}
export default useReturnOrder;
