import styles from "./ContentHeading.module.scss";

function ContentHeading(props: { text: string; className?: string }) {
  return (
    <div
      className={`mb-2 ${styles.lineText} ${
        props.className && props.className
      } `}
    >
      {props.text} <span className={`${styles.punctuation}`}>:</span>
    </div>
  );
}

export default ContentHeading;
