import React, { useCallback, useMemo, useRef, useState } from "react";
import styles from "./AllBrand.module.scss";
import { Dropdown } from "react-bootstrap";
import {
  useReportFilters,
  reportCurrentStatusActions,
} from "src/routes/Report/hooks/reportFilters/useReportFilters";
import { useAppSelector } from "src/store/store";
import { v4 as uuid } from "uuid";

const useBrandFilter = () => {
  const allBrands = useAppSelector(
    (state) => state.globals.brandSignatureData.brands
  );
  const allBrandId = useMemo(() => {
    return allBrands?.map((data) => data.id + "") ?? [];
  }, [allBrands]);

  const { currentStatus, dispatch } = useReportFilters();

  const selectedBrands = useMemo(() => {
    return currentStatus.selectedBrands;
  }, [currentStatus]);

  const selectAllBrands = useCallback(
    (e: any) => {
      if (e.target.checked) {
        dispatch([
          reportCurrentStatusActions.setFilters,
          {
            selectedBrands: [...allBrandId],
          },
        ]);
      } else {
        dispatch([
          reportCurrentStatusActions.setFilters,
          { selectedBrands: [] },
        ]);
      }
    },
    [allBrandId]
  );
  const clearAllBrands = useCallback(() => {
    dispatch([reportCurrentStatusActions.setFilters, { selectedBrands: [] }]);
  }, []);
  const checkUncheckBrands = useCallback(
    (e: any) => {
      if (e.target.checked) {
        dispatch([
          reportCurrentStatusActions.setFilters,
          { selectedBrands: [...selectedBrands, e.target.value] },
        ]);
      } else {
        dispatch([
          reportCurrentStatusActions.setFilters,
          {
            selectedBrands: selectedBrands.filter(
              (id) => id !== e.target.value
            ),
          },
        ]);
      }
    },
    [selectedBrands]
  );
  return {
    allBrands,
    selectedBrands,
    selectAllBrands,
    clearAllBrands,
    checkUncheckBrands,
  };
};
interface Props {
  isSegement?: boolean;
  disabled?: boolean;
}
const AllBrandFilter = ({ isSegement, disabled }: Props) => {
  const {
    allBrands,
    selectedBrands,
    selectAllBrands,
    clearAllBrands,
    checkUncheckBrands,
  } = useBrandFilter();
  const [ddOpen, setDdOpen] = useState(false);
  const dropdownArrowSpanRef = useRef<any>();
  const uuidRef = useRef(uuid());

  return (
    <div>
      <div className={`me-2  mb-2 mb-lg-0`}>
        <Dropdown onToggle={setDdOpen}>
          <Dropdown.Toggle
            as="div"
            bsPrefix={`dropdown-toggle cursor-pointer border-0 px-2 py-1 ${
              isSegement === true ? styles.segementBtn : styles.filterButton
            } ${disabled && styles.disable}`}
          >
            All Brands
            {selectedBrands.length ? (
              <span className={`mx-1 ${styles.filterCount}`}>
                {/* added condition to show 0 before count if count is less than 10 */}
                {selectedBrands.length < 10
                  ? `0${selectedBrands.length}`
                  : selectedBrands.length}
              </span>
            ) : (
              ""
            )}
            <span className="ps-1" ref={dropdownArrowSpanRef}>
              {ddOpen ? (
                <i className={`fa-solid fa-caret-up`}></i>
              ) : (
                <i className={`fa-solid fa-caret-down`}></i>
              )}
            </span>
          </Dropdown.Toggle>

          <Dropdown.Menu
            bsPrefix={`dropdown-menu border-0 ${styles.filterMenu}`}
          >
            <div className={`${styles.brandsWrap}`}>
              <span className={`${styles.dropItem}`}>
                <div
                  className={`${styles.itemWrapper} ${
                    selectedBrands.length === allBrands?.length &&
                    styles.selectedText
                  }`}
                >
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={"all"}
                      id={uuidRef.current + "report-filter-brand-all-select"}
                      checked={selectedBrands.length === allBrands?.length}
                      onChange={selectAllBrands}
                    />
                    <label
                      className={`form-check-label ${styles.checktext}`}
                      htmlFor={
                        uuidRef.current + "report-filter-brand-all-select"
                      }
                    >
                      Select All
                    </label>
                  </div>
                </div>
              </span>

              {allBrands?.map((data, index) => {
                return (
                  <span key={data.id} className={`${styles.dropItem}`}>
                    <div
                      className={`${styles.itemWrapper} ${
                        selectedBrands.includes(data.id + "") &&
                        styles.selectedOne
                      }`}
                    >
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={data.id}
                          id={uuidRef.current + "report-filter-brand" + data.id}
                          checked={selectedBrands.includes(data.id + "")}
                          onChange={checkUncheckBrands}
                        />
                        <label
                          className={`form-check-label ${styles.checktext}`}
                          htmlFor={
                            uuidRef.current + "report-filter-brand" + data.id
                          }
                        >
                          {data.name ?? ""}
                        </label>
                      </div>
                    </div>
                  </span>
                );
              })}
            </div>
            <span className={`${styles.clearSpan}`} onClick={clearAllBrands}>
              Clear all
            </span>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default AllBrandFilter;
