/**
 * This file contains the custom hook for handling KB settings.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getActiveThemeDetails } from "src/services/KnowledgeBase/Settings/Styles/getActiveThemeDetails.service";
import { updateThemeStyles } from "src/services/KnowledgeBase/Settings/Styles/updateThemeStyles.service";
import { useAppSelector } from "src/store/store";

/**
 * Thios hook ise used to manage the theme component.
 */
function useThemeBox(): any {
  // Getting the wording from the store
  const { headline, placeholderText, subheading } = useAppSelector(
    (state: { knowledgeBase: { pageStyling: { wording: any } } }) =>
      state.knowledgeBase.pageStyling.wording
  );

  const { integrationId } = useParams();
  const pageIntegrationId = integrationId
    ? decodeURIComponent(integrationId)
    : "";

  // Getting the theme data from the api
  const getThemeData = useQuery({
    queryKey: ["getActiveThemeDetails", pageIntegrationId],
    queryFn: () =>
      getActiveThemeDetails({
        integrationId: pageIntegrationId,
      }),
  });

  // Setting the font style
  const [fontStyle, setFontStyle] = useState(
    getThemeData.data?.data.font ?? "Roboto"
  );

  // Use effect to set the font style
  useEffect(() => {
    setFontStyle(getThemeData.data?.data.font);
  }, [getThemeData]);

  // Function to handle the font change
  const handleFontChange = (event: {
    target: { value: React.SetStateAction<any> };
  }) => {
    if (event.target.value) {
      setFontStyle(event.target.value);
    }
  };

  // Setting the parent style
  const parentStyle = {
    fontFamily: fontStyle ?? "Roboto",
  };

  // Setting the category style
  const categoryStyle = {
    color: getThemeData.data?.data.brandColor
      ? getThemeData.data?.data.brandColor
      : "#707070",
  };

  // Query client
  const queryClient = useQueryClient();

  // Mutation to update the theme styles
  const updateKBSettings = useMutation({
    mutationFn: updateThemeStyles,
    mutationKey: ["updateThemeStyles"],
    onSuccess: () => {
      queryClient.invalidateQueries([
        "getActiveThemeDetails",
        pageIntegrationId,
      ]);
      // getThemeData.refetch();
    },
  });

  return {
    fontStyle,
    handleFontChange,
    parentStyle,
    headline,
    placeholderText,
    subheading,
    getThemeData,
    data: getThemeData.data?.data,
    isLoading: getThemeData.isLoading,
    error: getThemeData.error,
    isError: getThemeData.isError,
    failureReason: getThemeData.failureReason,
    updateKBSettings,
    categoryStyle,
    isFetching: getThemeData.isFetching,
  };
}

export default useThemeBox;
