import React from "react";
import styles from "./DropDown.module.scss";
import {v4 as uuid} from "uuid";


function DropDown({element, inputName}:any) {
  return (
    <div className={`${styles.dropDown} text-muted mt-2`}>
      {" "}
      <div>
        {/* {element.elementValue}{" "} */}
        <div className={styles.inputLabel}>{element.elementValue}</div>
        {element.isRequired ? <span className="text-danger align-top"> *</span> : null}{" "}
      </div>
      <div className={`${styles.select_width} rounded`}>
        <select
          className={` form-control  border text-muted ${styles.fonts} mt-1`}
          name={inputName}
          required={element.isRequired === true}
          placeholder={"--choose--"}
        >
          {
            element.elementOptions.map((optionText:string)=>{

              return <option key={uuid()} value={optionText}>{optionText}</option>
            })
          }
        </select>
        <div className={``}>
          <i className={` ${styles.select_arrow} fa-solid fa-angle-down`}></i>
        </div>
      </div>
    </div>
  );
}

export default DropDown;
