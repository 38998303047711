import { axiosJSON } from "src/globals/axiosEndPoints";


export interface deleteArticleCategoryParams{
  categoryId : string|number;
  integrationId : number|string;
}

export async function deleteArticleCategory(params: deleteArticleCategoryParams) {
  const { data } = await axiosJSON.delete(
    `/api/chatSetting/article/deleteCategory`,
    {params}
  );
  if (data.error === true) {
    throw new Error(data.message as string);
  }
  return data.data;
}