import { useCallback, useMemo, useRef } from "react";
import { useGetAllSchedules } from "./useGetAllSchedules";
import { useParams } from "react-router-dom";
import {
  GetAllSchedulesData,
  ScheduleData,
} from "src/services/LiveChat/ScheduleFollowups/getAllSchedules";
import useErrorMessage from "../ErrorMessage/errorMessage";
import Loader from "src/components/Loader";
import { InfiniteData, useQueryClient } from "@tanstack/react-query";
import {
  UpdateSchedulePayload,
  updateScheduleLiveChat,
} from "src/services/LiveChat/ScheduleFollowups/updateSchedule";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { fetchCustomerDataByChatId } from "src/store/slices/liveChatSetting/chatWidExchCust/chatWidExchCust.thunks";
import { useAppDispatch } from "src/store/store";
import { setActiveScheduleMessageCount } from "src/store/slices/innerTicket/innerTicket.slice";
import { deleteScheduleLiveChat } from "src/services/LiveChat/ScheduleFollowups/deleteSchedule";
import {
  DeleteScheduleSequecePayload,
  deleteScheduleSequence,
} from "src/services/LiveChat/ScheduleFollowups/deleteScheduleSequence";
import { setActiveChatScheduledMessageCount } from "src/store/slices/liveChatSetting/chatWidExchMsg/chatWidExchMsg.slice";
import { removeCustomerByChatIdCache } from "src/services/LiveChat/messageExchange/getCustomerByChatId";

export const useScheduleMessages = (
  scheduleMessageFor: "liveChat" | "innerTicket",
  ticketId: number | string | undefined,
) => {
  const { chatId } = useParams();

  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const filters = useMemo(() => {
    if (scheduleMessageFor === "innerTicket") {
      return {
        ticketId: ticketId,
        start: 0,
        limit: 25,
      };
    } else {
      return {
        chatId: chatId,
        start: 0,
        limit: 25,
      };
    }
  }, [chatId, scheduleMessageFor, ticketId]);

  const { status, data, hasNextPage, fetchNextPage, fetchStatus } =
    useGetAllSchedules({
      payload: filters,
    });

  const currentState = useRef({ total: 0 });

  const { schedules, totalCount } = useMemo(() => {
    let scheduleData: { [key: number | string]: ScheduleData } = {};
    data?.pages.forEach((page) => {
      scheduleData = { ...scheduleData, ...page.schedules };
    });

    // Get the total value from the last fetched page
    const totalPages = data?.pages || [];
    const lastPage = totalPages[totalPages.length - 1];
    const total = lastPage?.metaData?.total ?? 0;
    currentState.current.total = total;
    return { schedules: scheduleData, totalCount: total };
  }, [data]);

  const scheduleIds = useMemo(() => {
    const ids = data?.pages.flatMap((page) => page.scheduleIds) ?? [];
    return Array.from(new Set([...ids]));
  }, [data]);

  const errorMessage = useErrorMessage(
    status === "error" ||
      (status !== "success" && fetchStatus !== "fetching") ||
      (status !== "loading" && scheduleIds.length === 0),
  );

  const loader =
    status === "loading" && fetchStatus === "fetching" ? (
      <div className="m-auto w-100">
        <Loader />
      </div>
    ) : undefined;

  const updateScheduleData = useCallback(
    async (payload: UpdateSchedulePayload) => {
      try {
        // let updatedSchedule: ScheduleData;

        // if (scheduleMessageFor === "liveChat") {
        //   updatedSchedule = await updateScheduleLiveChat(payload);
        // }
        // else {
        //   updatedSchedule = await updateScheduleInnerTicket(payload);
        // }

        const updatedSchedule = await updateScheduleLiveChat(payload);

        pushTheToast({
          type: "success",
          text: "Schedule message updated successfully!",
          position: "top-right",
        });

        // Get the current data from the cache
        const schedules = queryClient.getQueryData<
          InfiniteData<GetAllSchedulesData>
        >(["LiveChat/fetchScheduleMessages", filters]);

        if (schedules) {
          // Find the updated schedule in the list and replace it
          const updatedSchedules = schedules.pages.map((schedulePage) => {
            const schedule = schedulePage.schedules[payload.id];
            if (schedule) {
              return {
                ...schedulePage,
                schedules: {
                  ...schedulePage.schedules,
                  [payload.id]: updatedSchedule,
                },
              };
            }
            return schedulePage;
          });

          // Update the cache with the new data
          queryClient.setQueryData<InfiniteData<GetAllSchedulesData>>(
            ["LiveChat/fetchScheduleMessages", filters],
            {
              pages: updatedSchedules,
              pageParams: schedules.pageParams,
            },
          );
        }
      } catch (error: any) {
        pushTheToast({
          type: "danger",
          text: "Failed to update schedule message!",
          position: "top-right",
        });
        throw error.message;
      }
    },
    [scheduleMessageFor, queryClient],
  );

  const deleteScheduleMessage = useCallback(
    async (id: number) => {
      try {
        let deleteStatus = await deleteScheduleLiveChat({ id: id });

        pushTheToast({
          type: "success",
          text: "Schedule message deleted successfully!",
          position: "top-right",
        });
        if (scheduleMessageFor === "innerTicket") {
          dispatch(
            setActiveScheduleMessageCount({
              count:
                currentState.current.total < 1
                  ? 0
                  : currentState.current.total - 1,
            }),
          );
        } else {
          dispatch(
            setActiveChatScheduledMessageCount({
              chatId: parseInt(chatId + ""),
              count:
                currentState.current.total < 1
                  ? 0
                  : currentState.current.total - 1,
            }),
          );
          removeCustomerByChatIdCache(chatId + "");
          dispatch(fetchCustomerDataByChatId({ payload: chatId + "" }));
        }
        // Get the current data from the cache
        const schedules = queryClient.getQueryData<
          InfiniteData<GetAllSchedulesData>
        >(["LiveChat/fetchScheduleMessages", filters]);

        if (schedules) {
          // Find the updated schedule in the list and remove it
          const updatedSchedules = schedules.pages.map((schedulePage) => {
            if (schedulePage.scheduleIds.includes(id)) {
              const scheduleIds = schedulePage.scheduleIds.filter(
                (scheduleId) => scheduleId !== id,
              );

              return {
                ...schedulePage,
                scheduleIds,
                metaData: {
                  total: schedulePage.metaData.total - 1,
                  count: scheduleIds.length,
                },
              };
            }

            return schedulePage;
          });

          // Update the cache with the new data
          queryClient.setQueryData<InfiniteData<GetAllSchedulesData>>(
            ["LiveChat/fetchScheduleMessages", filters],
            {
              pages: updatedSchedules,
              pageParams: schedules.pageParams,
            },
          );
        }
      } catch (error: any) {
        pushTheToast({
          type: "danger",
          text: "Failed to delete schedule message!",
          position: "top-right",
        });
        throw error.message;
      }
    },
    [scheduleMessageFor, queryClient],
  );

  const deleteScheduleMessageSequence = useCallback(async () => {
    try {
      let payload: DeleteScheduleSequecePayload = {};

      if (scheduleMessageFor === "innerTicket") {
        payload.ticketId = parseInt(ticketId + "");
      } else {
        payload.chatId = parseInt(chatId + "");
      }

      let deleteStatus = await deleteScheduleSequence(payload);

      pushTheToast({
        type: "success",
        text: "Deleted schedule sequence successfully!",
        position: "top-right",
      });
      if (scheduleMessageFor === "innerTicket") {
        dispatch(
          setActiveScheduleMessageCount({
            count: 0,
          }),
        );
      } else {
        dispatch(
          setActiveChatScheduledMessageCount({
            chatId: parseInt(chatId + ""),
            count: 0,
          }),
        );
        removeCustomerByChatIdCache(chatId + "");
        dispatch(fetchCustomerDataByChatId({ payload: chatId + "" }));
      }
      // Get the current data from the cache
      const schedules = queryClient.getQueryData<
        InfiniteData<GetAllSchedulesData>
      >(["LiveChat/fetchScheduleMessages", filters]);

      if (schedules) {
        queryClient.resetQueries(["LiveChat/fetchScheduleMessages", filters]);
      }
    } catch (error: any) {
      pushTheToast({
        type: "danger",
        text: "Failed to delete schedule sequence!",
        position: "top-right",
      });
      throw error.message;
    }
  }, [scheduleMessageFor, queryClient, chatId, ticketId]);

  return {
    handleInfiniteScroll: fetchNextPage,
    hasNextPage,
    status,
    schedules,
    scheduleIds,
    errorMessage,
    loader,
    updateScheduleData,
    deleteScheduleMessage,
    deleteScheduleMessageSequence,
  };
};
