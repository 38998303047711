import React, { useEffect, useState } from "react";
import { Accordion, Modal } from "react-bootstrap";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import styles from "./SettingSideBar.module.scss";
import useThemeBox from "./ThemeSelection/Children/ThemeBox/useThemeBox";
import useArticles from "../../../Articles/useArticles";
import AxiosImg from "src/components/AxiosImg";
import UserAvatar from "src/components/UserAvatar";
const SettingSideBar = () => {
  const [showSettingsSubSB, setShowSettingsSubSB] = useState<boolean>(true);
  const getToggleContent = () => {
    return (
      <div
        className={`${styles.subSideBarCloseBtnDiv} ${
          showSettingsSubSB === false ? styles.closeBtnToggle : ""
        }`}
        onClick={() => handleToggleSubSideBar()}
      >
        <span className={`${styles.subSideBarCloseBtnToggleArrow} m-auto`}>
          {showSettingsSubSB === true ? (
            <i className="fa-solid fa-chevron-left"></i>
          ) : (
            <i className="fa-solid fa-chevron-right"></i>
          )}
        </span>
      </div>
    );
  };

  // Function for toggling the sidebar
  const handleToggleSubSideBar = () => {
    if (showSettingsSubSB === true) {
      setShowSettingsSubSB(false);
    } else {
      setShowSettingsSubSB(true);
    }
  };

  const { integrationId, subSettings } = useParams();
  const pageIntegrationId: string = integrationId
    ? decodeURIComponent(integrationId)
    : "";

  const navigate = useNavigate();

  // State for active accordion
  const [activeAccordionKey, setActiveAccordionKey] = useState<string | null>(
    subSettings === "themeSelection" ||
      subSettings === "brand" ||
      subSettings === "messaging" ||
      subSettings === "customCode"
      ? "style_help_center_page"
      : subSettings === "ratings" ||
        subSettings === "comments" ||
        subSettings === "statusFeedback"
      ? "feedback"
      : "domain_and_access"
  );

  // Chaning the active accordion key on subsettings change
  useEffect(() => {
    if (
      subSettings === "themeSelection" ||
      subSettings === "brand" ||
      subSettings === "messaging" ||
      subSettings === "customCode"
    ) {
      setActiveAccordionKey("style_help_center_page");
    } else if (
      subSettings === "ratings" ||
      subSettings === "comments" ||
      subSettings === "statusFeedback"
    ) {
      setActiveAccordionKey("feedback");
    } else if (
      subSettings === "analytics" ||
      subSettings === "domain" ||
      subSettings === "accessControl"
    ) {
      setActiveAccordionKey("domain_and_access");
    } else {
      setActiveAccordionKey("");
    }
  }, [subSettings]);

  const { data, isLoading } = useThemeBox();

  // Random uri for preview
  const randomUri = data?.helpCenterPageUri;

  const { brandDetails, brandDetailsError, brandDetailsLoading } =
    useArticles();

  return (
    <div
      className={`position-relative ${styles.sidebarWrapper} ${
        showSettingsSubSB === true ? styles.active : ""
      }`}
    >
      {showSettingsSubSB === true && (
        <>
          {brandDetailsLoading ? (
            <div
              className="spinner-border text-secondary spinner-border-sm"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : brandDetailsError ? (
            <div>Error</div>
          ) : brandDetails ? (
            <div
              className={`mt-2 ${styles.brandDetail} saufter_medium_h4 d-flex justify-content-start align-items-center`}
            >
              <span className={`d-flex align-items-center justify-content-center ${styles.brandImg}`}>
                {brandDetails.imageURL && brandDetails.imageURL.length !== 0 ? (
                  <AxiosImg
                    url={brandDetails.imageURL ?? "NA"}
                    className={`rounded-circle me-2 ${styles.authorImg}`}
                  />
                ) : (
                  <UserAvatar
                    name={brandDetails.name ?? "NA"}
                    size={25}
                    className={`me-2`}
                  />
                )}
              </span>
              <span>{brandDetails?.name}</span>
            </div>
          ) : (
            ""
          )}
          <h4
            className={`mt-1 mb-3 ps-1 ${styles.sideBarHead} saufter_medium_h4`}
          >
            Knowledge Base
          </h4>

          {/* Preview */}
          <span
            className={`d-inline-block mb-3  p-1 ${styles.preview} ${
              isLoading ? styles.previeDisabled : ""
            }`}
            onClick={() => {
              if (isLoading) {
                return;
              } else {
                window.open(`/helpcenterpage/${randomUri}`);
              }
            }}
          >
            Preview
            <span>
              {/* <i className="ps-2 cursor-pointer fa-solid fa-arrow-up-right-from-square"></i> */}
              <i className="fa-solid fa-up-right-from-square"></i>
            </span>
          </span>
          <Accordion defaultActiveKey={activeAccordionKey}>
            {/* Style Helpcenter Page */}
            <Accordion.Item
              className={`${styles.accordItem}`}
              eventKey="style_help_center_page"
            >
              <Accordion.Header
                className={`mb-2 ps-1 ${styles.toggleHead} ${
                  activeAccordionKey === "style_help_center_page"
                    ? styles.activeBtn
                    : ""
                } `}
                onClick={() => {
                  navigate(
                    `/knowledge-base/${pageIntegrationId}/settings/themeSelection`
                  );
                }}
              >
                Style Helpcenter Page
              </Accordion.Header>
              <Accordion.Body className={`${styles.accordBody}`}>
                <div className="d-flex mb-2">
                  <NavLink
                    to={`/knowledge-base/${pageIntegrationId}/settings/themeSelection`}
                    className={`${styles.webBtn} ${
                      subSettings === "themeSelection"
                        ? styles.webBtnActive
                        : ""
                    }`}
                  >
                    <div
                      className={`${
                        subSettings === "themeSelection" ? styles.bar : ""
                      }`}
                    ></div>
                    Theme Selection
                  </NavLink>
                </div>
                <div className="d-flex mb-2">
                  <NavLink
                    to={`/knowledge-base/${pageIntegrationId}/settings/brand`}
                    className={`${styles.webBtn} ${
                      subSettings === "brand" ? styles.webBtnActive : ""
                    }`}
                  >
                    <div
                      className={`${subSettings === "brand" ? styles.bar : ""}`}
                    ></div>
                    <span> Brand</span>
                  </NavLink>
                </div>
                <div className="d-flex mb-2">
                  <NavLink
                    to={`/knowledge-base/${pageIntegrationId}/settings/messaging`}
                    className={`${styles.webBtn} ${
                      subSettings === "messaging" ? styles.webBtnActive : ""
                    }`}
                  >
                    <div
                      className={`${
                        subSettings === "messaging" ? styles.bar : ""
                      }`}
                    ></div>
                    Messaging
                  </NavLink>
                </div>
                <div className="d-flex">
                  <NavLink
                    to={`/knowledge-base/${pageIntegrationId}/settings/customCode`}
                    className={`${styles.webBtn} ${
                      subSettings === "customCode" ? styles.webBtnActive : ""
                    }`}
                  >
                    <div
                      className={`${
                        subSettings === "customCode" ? styles.bar : ""
                      }`}
                    ></div>
                    Custom code
                  </NavLink>
                </div>
              </Accordion.Body>
            </Accordion.Item>

            {/* Feedback */}
            <Accordion.Item
              className={`${styles.accordItem}`}
              eventKey="feedback"
            >
              <Accordion.Header
                className={`mb-2  ps-1 ${styles.toggleHead} ${
                  activeAccordionKey === "feedback" ? styles.activeBtn : ""
                }`}
                onClick={() => {
                  navigate(
                    `/knowledge-base/${pageIntegrationId}/settings/ratings`
                  );
                }}
              >
                Feedback
              </Accordion.Header>
              <Accordion.Body className={`${styles.accordBody}`}>
                <div className="d-flex mb-2">
                  <NavLink
                    to={`/knowledge-base/${pageIntegrationId}/settings/ratings`}
                    className={`${styles.webBtn} ${
                      subSettings === "ratings" ? styles.webBtnActive : ""
                    }`}
                  >
                    <div
                      className={`${
                        subSettings === "ratings" ? styles.bar : ""
                      }`}
                    ></div>
                    Ratings
                  </NavLink>
                </div>
                <div className="d-flex mb-2">
                  <NavLink
                    to={`/knowledge-base/${pageIntegrationId}/settings/comments`}
                    className={`${styles.webBtn} ${
                      subSettings === "comments" ? styles.webBtnActive : ""
                    }`}
                  >
                    <div
                      className={`${
                        subSettings === "comments" ? styles.bar : ""
                      }`}
                    ></div>
                    Comments and Questions
                  </NavLink>
                </div>
                <div className="d-flex mb-2">
                  <NavLink
                    to={`/knowledge-base/${pageIntegrationId}/settings/statusFeedback`}
                    className={`${styles.webBtn} ${
                      subSettings === "statusFeedback"
                        ? styles.webBtnActive
                        : ""
                    }`}
                  >
                    <div
                      className={`${
                        subSettings === "statusFeedback" ? styles.bar : ""
                      }`}
                    ></div>
                    Approve/Reject Feedback
                  </NavLink>
                </div>
              </Accordion.Body>
            </Accordion.Item>

            {/* Domain and Acess */}
            <Accordion.Item
              className={`${styles.accordItem}`}
              eventKey="domain_and_access"
            >
              <Accordion.Header
                className={`mb-2 ps-1 ${styles.toggleHead} ${
                  activeAccordionKey === "domain_and_access"
                    ? styles.activeBtn
                    : ""
                }`}
                onClick={() => {
                  navigate(
                    `/knowledge-base/${pageIntegrationId}/settings/accessControl`
                  );
                }}
              >
                Domain and Access
              </Accordion.Header>
              <Accordion.Body className={`${styles.accordBody}`}>
                <div className="d-flex mb-2 d-none">
                  <NavLink
                    to={`/knowledge-base/${pageIntegrationId}/settings/domain`}
                    className={`${styles.webBtn} ${
                      subSettings === "domain" ? styles.webBtnActive : ""
                    }`}
                  >
                    <div
                      className={`${
                        subSettings === "domain" ? styles.bar : ""
                      }`}
                    ></div>
                    Domain & SSL
                  </NavLink>
                </div>
                <div className="d-flex mb-2">
                  <NavLink
                    to={`/knowledge-base/${pageIntegrationId}/settings/accessControl`}
                    className={`${styles.webBtn} ${
                      subSettings === "accessControl" ? styles.webBtnActive : ""
                    }`}
                  >
                    <div
                      className={`${
                        subSettings === "accessControl" ? styles.bar : ""
                      }`}
                    ></div>
                    Access Control
                  </NavLink>
                </div>
                <div className="d-flex mb-2 d-none">
                  <NavLink
                    to={`/knowledge-base/${pageIntegrationId}/settings/analytics`}
                    className={`${styles.webBtn} ${
                      subSettings === "analytics" ? styles.webBtnActive : ""
                    }`}
                  >
                    <div
                      className={`${
                        subSettings === "analytics" ? styles.bar : ""
                      }`}
                    ></div>
                    Analytics & SEO
                  </NavLink>
                </div>
              </Accordion.Body>
            </Accordion.Item>

            {/* Featured Articles */}
            <div className="d-flex w-100 mb-2">
              <NavLink
                to={`/knowledge-base/${pageIntegrationId}/settings/featuredArticles`}
                className={`w-100 ps-1 py-1 position-relative ${
                  styles.featureHead
                } ${
                  subSettings === "featuredArticles"
                    ? styles.navHeadSelected
                    : ""
                } d-flex align-items-center`}
              >
                <div
                  className={`${
                    subSettings === "featuredArticles" ? styles.featureBar : ""
                  }`}
                ></div>
                Featured articles
              </NavLink>
            </div>

            {/* Customize Knowledge Base */}
            <div className="d-flex">
              <NavLink
                to={`/knowledge-base/${pageIntegrationId}/settings/customKnowledgeBase`}
                className={`ps-1 py-1 ${styles.navHead} ${
                  subSettings === "customKnowledgeBase"
                    ? styles.widgetHeadSelected
                    : ""
                } d-flex`}
              >
                Customise KnowledgeBase in the chat widget
              </NavLink>
            </div>
          </Accordion>
        </>
      )}
      {getToggleContent()}
    </div>
  );
};

export default SettingSideBar;
