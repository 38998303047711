import {axiosJSON} from "src/globals/axiosEndPoints";
import {IS_TEST_ACTIVE} from "src/globals/constants";
import { TinyMCEUploadFile } from "src/interfaces/TinyMCEContainer/ITinyMCEContainer";

export interface GetAllTemplatesPayload {
  start: number;
  limit: number;
  templateType :"live_chat_template" | "inner_ticket_template"
}

export interface Attachment {
  attachmentBatchNumber: string;
  attachmentId: number | string;
  attachmentName: string;
  attachmentSize: number;
  attachmentType: string;
  attachmentUrl: string;
  contentId: null | string;
  embedded: boolean;
}

export interface TemplateStep {
  attachments: TinyMCEUploadFile[];
  removedAttachmentIds?: Array<number|string>
  scheduleFollowUpType: "days" | "weeks" | "months" | "dateAndTime";
  scheduleFollowUpValue: number | string;
  sendMessageCondition:
    | "noNewOrderPlacedAfterScheduling"
    | "noNewResponseOrMessageReceivedFromTheCustomer"
    | "none";
  stepId: number | string;
  templateHeading?: string;
  templateMessage: string;
}

export interface GetAllTemplatesResponse {
  data: {
    templateId: number | string;
    templateSteps: TemplateStep[];
  }[];
  metaData: {
    currentCount: number;
    totalCount: number;
  };
}

const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/template/getAll`
  : "/api/liveChat/agent/message/template/getAll";

export const getAllTemplatesLiveChat = async (params: GetAllTemplatesPayload) => {

  const response = await axiosJSON.post(apiEndPoint, params);
  return response.data;
};
