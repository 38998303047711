import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import SendingInfo from "src/components/SendingInfo";
import OpenClose from "src/routes/Ticket/children/MainChat/children/SendMessage/children/OpenClose";
import { changeTheCustomer } from "src/store/slices/innerTicket/innerTicket.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import styles from "./SendMailDetails.module.scss";
import { BsExclamationDiamondFill } from "react-icons/bs";

function SendMailDetails({
  src,
  isInModal,
  isIntegrationFailed = false,
  integrationFailType,
  to,
  setTo,
  from,
  disableToInput,
}: {
  src: any;
  isInModal: any;
  isIntegrationFailed?: boolean;
  integrationFailType?: string;
  to: string;
  setTo: (value: string) => void;
  from: string;
  disableToInput?: boolean;
}) {
  const [showSending, setShowSending] = useState(
    isInModal === true ? true : false,
  );
  const sendingDetailsRef = useRef(null as any);
  const [cc, setCc]: any = useState("");
  const [bcc, setBcc]: any = useState("");

  const clearData = useCallback(() => {
    setCc("");
    setBcc("");
    setShowSending(false);
  }, []);

  useEffect(() => {
    sendingDetailsRef.current.scrollTop = 0;
  }, [showSending]);
  return (
    <div
      className={`d-flex ${styles.sendingDetails} ${
        showSending ? styles.active : ""
      } justify-content-between`}
    >
      <div
        className={`${styles.sendingBlock}`}
        ref={sendingDetailsRef}
      >
        <div className="d-flex mb-1">
          <input
            data-lpignore="true"
            type="email"
            style={{
              width: 0,
              height: 0,
              position: "absolute",
              opacity: 0,
              border: "none",
            }}
          />
          <label>To:</label>
          <SendingInfo
            name="to"
            isDisabled={disableToInput}
            currentValue={to}
            getValue={(value) => {
              if (value && value !== to) {
                setTo(value);
              } else if (value === "") {
                // Update the 'to' field value if it is different from the current value or if it is an empty string
                setTo("");
              }
            }}
            addUserZIndex={203}
            addUser={true}
          />
        </div>
        <div className="d-flex mb-1 position-relative">
          <label>From:</label>
          <SendingInfo
            name="from"
            isDisabled={true}
            currentValue={from}
            isError={isIntegrationFailed && integrationFailType !== undefined}
          />
          {isIntegrationFailed && integrationFailType !== undefined && (
            <p className={`${styles.integrateText}`}>
              <span className={`${styles.exclamation}`}>
                <BsExclamationDiamondFill />
              </span>
              {integrationFailType === "brand"
                ? " Brand unavailable"
                : " Integration not available"}
            </p>
          )}
        </div>
        {/* <div className="d-flex mb-1">
          <label>Bcc:</label>
          <SendingInfo
            name="bcc"
            currentValue={bcc}
            getValue={(value) => {
              setBcc(value);
            }}
            addUser={true}
            isMulti={true}
          />
        </div>
        <div className="d-flex mb-1">
          <label>Cc:</label>
          <SendingInfo
            name="cc"
            currentValue={cc}
            getValue={(value) => {
              setCc(value);
            }}
            addUser={true}
            isMulti={true}
          />
        </div> */}
      </div>
      <OpenClose
        onClick={(e: any) => {
          setShowSending(!showSending);
        }}
        show={showSending}
      />
    </div>
  );
}

export default SendMailDetails;
