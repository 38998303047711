/**
 * This file is the service file used for making api request.
 * It contains the signIn function service which is explained below.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { axiosKbPreview } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants"; // this is used for testing purpose only for frontend.

export interface signInResponse {
  token?: string;
  expiresIn?: number;
}

export interface signInPayload {
  password: string;
  integrationIdentifier: string;
}

/**
 * For testing frontend with own api link if backend is not prepared.
 */
const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/`
  : "/api/knowledgebase/helpCenterPage/";

/**
 * This service is used to sign in the user at helpcenter.
 */
export const signIn = async (params: signInPayload) => {
  const { data: res } = await axiosKbPreview.post(
    `${apiEndPoint}${params.integrationIdentifier}/signIn`,
    {
      password: params.password,
    }
  );

  if (res.err) {
    throw Error(res.msg as string);
  }
  return res.data as signInResponse;
};
