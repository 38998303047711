import styles from "./RuleTableRow.module.scss";
import sixDots from "src/assets/images/sixDots.png";
import UserAvatar from "src/components/UserAvatar";
import exclamation from "src/assets/images/exclamationMark.png";
import genLogo from "src/assets/images/General.png";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import Select from "react-select";
import addRule from "src/assets/images/AddedRule.png";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidV4 } from "uuid";
import {
  duplicateRoutingRule,
  DuplicateRoutingRuleParams,
} from "src/services/LiveChat/Settings/routingRules/duplicateRoutingRules";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";

import {
  fetchAllRoutingRulesData,
  resetRoutingRulesData,
} from "src/store/slices/liveChatSetting/RoutingRules/RoutingRulesSetting.slice";
import {
  deleteRoutingRule,
  DeleteRoutingRuleParams,
} from "src/services/LiveChat/Settings/routingRules/deleteRoutingRules";
import {
  moveRoutingRuleOrder,
  MoveRoutingRuleOrderParams,
} from "src/services/LiveChat/Settings/routingRules/moveRoutingRuleOrder";
import { useAppDispatch } from "src/store/store";
const RuleTableRow = ({ ruleData, index, canMoveTop, canMoveBottom }: any) => {
  const { integrationId } = useParams();
  const pageIntegrationId = integrationId
    ? decodeURIComponent(integrationId)
    : "";

  const TooltipText: {
    [key: string]: { value: string };
  } = {
    url: { value: "Customer visits URL that:" },
    domain: { value: "Customer visits Domain that:" },
    location: { value: "Customer visits location that:" },
    contains: { value: "Contains" },
    isExactly: { value: "Is Exactly" },
  };

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleDuplicateRule = () => {
    if (ruleData !== null) {
      let payload: DuplicateRoutingRuleParams = {
        ruleId: ruleData.ruleId,
      };

      duplicateRoutingRule(payload)
        .then((res) => {
          dispatch(resetRoutingRulesData());
          dispatch(fetchAllRoutingRulesData(pageIntegrationId));
          setTimeout(() => {
            pushTheToast({
              type: "success",
              text: "duplicated successfully!",
              position: "top-right",
            });
          }, 0);
        })
        .catch((err) => {
          pushTheToast({
            type: "danger",
            text: "something went wrong!",
            position: "top-right",
          });
        });
    }
  };

  const handleDeleteRule = () => {
    if (ruleData !== null) {
      let payload: DeleteRoutingRuleParams = {
        ruleId: ruleData.ruleId,
      };

      deleteRoutingRule(payload)
        .then((res) => {
          dispatch(resetRoutingRulesData());
          dispatch(fetchAllRoutingRulesData(pageIntegrationId));
          setTimeout(() => {
            pushTheToast({
              type: "success",
              text: "deleted successfully!",
              position: "top-right",
            });
          }, 0);
        })
        .catch((err) => {
          pushTheToast({
            type: "danger",
            text: "something went wrong!",
            position: "top-right",
          });
        });
    }
  };

  const handleMoveRuleOrder = (type: any) => {
    if (ruleData !== null) {
      if (type == "top" && canMoveTop === false) {
        return;
      }
      if (type == "bottom" && canMoveBottom === false) {
        return;
      }

      let payload: MoveRoutingRuleOrderParams = {
        ruleId: ruleData.ruleId,
        moveTo: type,
      };

      moveRoutingRuleOrder(payload)
        .then((res) => {
          // console.log(res);
          dispatch(resetRoutingRulesData());
          dispatch(fetchAllRoutingRulesData(pageIntegrationId));
          setTimeout(() => {
            pushTheToast({
              type: "success",
              text: "moved successfully!",
              position: "top-right",
            });
          }, 0);
        })
        .catch((err) => {
          pushTheToast({
            type: "danger",
            text: "something went wrong!",
            position: "top-right",
          });
        });
    }
  };

  return (
    <>
      <div className={`${styles.tableRow}`}>
        <div className={`${styles.widthSet1}`}>
          <span className={`${styles.priority}`}>
            <span className={`me-3`}>
              <img className={`${styles.sixDot}`} src={sixDots} alt="dots" />
            </span>
            <span className={`${styles.num}`}>{index + 1}.</span>
          </span>
        </div>
        <div className={`${styles.widthSet2}`}>
          <span className={`${styles.headName}`}>{ruleData.ruleName}</span>
        </div>
        <div className={`${styles.widthSet3}`}>
          <span>
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip className={` ${styles.toolTipCustom}`}>
                  {Object.keys(ruleData.conditions).map((key: string) => {
                    return (
                      <>
                        <span key={uuidV4()}>{TooltipText[key].value}</span>
                        {ruleData.conditions[key].map((condition: any) => {
                          return (
                            <p key={uuidV4()}>
                              {TooltipText[condition.conditionConstraint]
                                .value +
                                " " +
                                condition.conditionValue}
                            </p>
                          );
                        })}
                      </>
                    );
                  })}
                  {/* <p>Contains https://.co.com</p>
                  <span>Customer visits URL that</span>
                  <p>Contains https://.co.com</p> */}
                </Tooltip>
              }
            >
              {({ ref, ...triggerHandler }) => (
                <div {...triggerHandler} ref={ref}>
                  <img
                    className={`${styles.exclame}`}
                    src={exclamation}
                    alt="exclamation"
                  />
                </div>
              )}
            </OverlayTrigger>
          </span>
          <span className={`${styles.headName}`}> URL</span>
        </div>
        <div className={`${styles.widthSet4}`}>
          <div>
            {ruleData.ruleAction === "routeToGroup" ? (
              <>
                <span className={`${styles.routeTo}`}> Route to</span>
                <span className="mx-1">
                  {" "}
                  <UserAvatar name={ruleData.teamName} size={25} />{" "}
                </span>
                <span className={`${styles.routeName}`}>{ruleData.teamName}</span>
              </>
            ) : (
              <span>Turn Chat Off</span>
            )}
          </div>
          {/* <div className="dropdown">
            <button
              className={`dropdown-toggle ${styles.dropBtn}`}
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="fa-solid fa-ellipsis"></i>
            </button>
            <ul
              className={`dropdown-menu ${styles.dropBox}`}
              aria-labelledby="dropdownMenuButton1"
            >
              <li>
                <a
                  className={`dropdown-item ${styles.edit}`}
                  href="#"
                  onClick={(e) =>
                    navigate(
                      "/live-chat/settings/" +
                        pageIntegrationId +
                        "/routeRule/" +
                        ruleData.ruleId
                    )
                  }
                >
                  Edit
                </a>
              </li>
              <li>
                <a
                  className={`dropdown-item ${styles.edit}`}
                  href="#"
                  onClick={handleDuplicateRule}
                >
                  Duplicate
                </a>
              </li>
              <li>
                <a
                  className={
                    canMoveTop === true
                      ? `dropdown-item ${styles.edit}`
                      : `dropdown-item ${styles.move}`
                  }
                  href="#"
                  onClick={(e) => handleMoveRuleOrder("top")}
                >
                  Move to top
                </a>
              </li>
              <li>
                <a
                  className={
                    canMoveBottom === true
                      ? `dropdown-item ${styles.edit}`
                      : `dropdown-item ${styles.move}`
                  }
                  href="#"
                  onClick={(e) => handleMoveRuleOrder("bottom")}
                >
                  Move to bottom
                </a>
              </li>
              <li>
                <a
                  className={`dropdown-item ${styles.delete}`}
                  href="#"
                  onClick={handleDeleteRule}
                >
                  Delete
                </a>
              </li>
              <li>
                <a
                  className={`dropdown-item ${styles.edit}`}
                  href="#"
                  onClick={(e) =>
                    navigate(
                      "/live-chat/settings/" +
                        pageIntegrationId +
                        "/routeRule/" +
                        ruleData.ruleId
                    )
                  }
                >
                  Edit
                </a>
              </li>
              <li>
                <a
                  className={`dropdown-item ${styles.edit}`}
                  href="#"
                  onClick={handleDuplicateRule}
                >
                  Duplicate
                </a>
              </li>
              <li>
                <a
                  className={
                    canMoveTop === true
                      ? `dropdown-item ${styles.edit}`
                      : `dropdown-item ${styles.move}`
                  }
                  href="#"
                  onClick={(e) => handleMoveRuleOrder("top")}
                >
                  Move to top
                </a>
              </li>
              <li>
                <a
                  className={
                    canMoveBottom === true
                      ? `dropdown-item ${styles.edit}`
                      : `dropdown-item ${styles.move}`
                  }
                  href="#"
                  onClick={(e) => handleMoveRuleOrder("bottom")}
                >
                  Move to bottom
                </a>
              </li>
              <li>
                <a
                  className={`dropdown-item ${styles.delete}`}
                  href="#"
                  onClick={handleDeleteRule}
                >
                  Delete
                </a>
              </li>
            </ul>
          </div> */}
          <Dropdown bsPrefix={`dropdown cursor-pointer`}>
            <Dropdown.Toggle
              as="div"
              bsPrefix={`dropdown-toggle ${styles.dropBtn}`}
            >
              <span>
                {" "}
                <i className="fa-solid fa-ellipsis"></i>{" "}
              </span>
            </Dropdown.Toggle>

            <Dropdown.Menu bsPrefix={`dropdown-menu ${styles.dropBox}`}>
              <li>
                <span
                  className={`${styles.edit}`}
                  onClick={(e) =>
                    navigate(
                      "/live-chat/settings/" +
                        pageIntegrationId +
                        "/routeRule/" +
                        ruleData.ruleId
                    )
                  }
                >
                  Edit
                </span>
              </li>
              <li>
                <span
                  className={`${styles.edit}`}
                  onClick={handleDuplicateRule}
                >
                  Duplicate
                </span>
              </li>
              <li>
                <span
                  className={
                    canMoveTop === true ? `${styles.edit}` : `${styles.move}`
                  }
                  onClick={(e) => handleMoveRuleOrder("top")}
                >
                  Move to top
                </span>
              </li>
              <li>
                <span
                  className={
                    canMoveBottom === true ? `${styles.edit}` : `${styles.move}`
                  }
                  onClick={(e) => handleMoveRuleOrder("bottom")}
                >
                  Move to bottom
                </span>
              </li>
              <li>
                <span className={`${styles.delete}`} onClick={handleDeleteRule}>
                  Delete
                </span>
              </li>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </>
  );
};
export default RuleTableRow;
