import { Navigate, useParams } from "react-router-dom";
import { useAppSelector } from "src/store/store";
import {
  EAutomationType,
  IAutomationParams,
} from "../AutomationList/AutomationList";
import AutomationTabSettings from "./AutomationTabSettings/AutomationTabSettings";
import TicketRouteSetting from "./TicketRoute/TicketRouteSetting";

/**
 * Component for showing automation steps and configuration
 */
const Automation = () => {
  const { type, integrationId } = useParams<IAutomationParams>();

  const currentUserData = useAppSelector(
    (state) => state.globals.currentUserData,
  );

  if (!currentUserData || type === undefined || integrationId === undefined) {
    return <Navigate to={"/"} />;
  }

  return (
    <div>
      {/* {category === EAutomationType.RETURN && <AddAutomation />} */}
      {/* {category === EAutomationType.ORDER_MODIFICATION && <ModifyOrder />} */}
      {/* {category === EAutomationType.TRACK_ORDER && <TrackOrder />} */}
      {/* {category === EAutomationType.WARRANTY_ISSUES && <WarrantyIssues />} */}
      {/* {type === EAutomationType.SUBSCRIPTION_CANCELLATION && (
        <SubscriptionCancellation />
      )} */}
      {type === EAutomationType.BUG_MANAGEMENT &&
        currentUserData?.ui_visibility &&
        currentUserData?.ui_visibility?.includes("bug_management") && (
          <AutomationTabSettings />
        )}

      {type === EAutomationType.ISSUE_DISPATCH &&
        currentUserData?.ui_visibility &&
        currentUserData?.ui_visibility?.includes("issue_dispatch") && (
          <AutomationTabSettings />
        )}
      {type === EAutomationType.TICKET_ROUTING &&
        currentUserData?.ui_visibility &&
        currentUserData?.ui_visibility?.includes(
          "automation_ticket_routing",
        ) && <TicketRouteSetting />}
    </div>
  );
};

export default Automation;
