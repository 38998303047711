import { axiosJSON } from "src/globals/axiosEndPoints";
import { CurrentStatusEndPoints } from "./reportTicketList.service";

export interface NotificationPreviewParams {
  /**
   * Notification ID
   */
  id: string;
  context?: CurrentStatusEndPoints;
}

export interface NotificationPreview {
  content: string;
  name: string;
  email: string;
  imageUrl?: string;
}

/**
 * This is a service to get the notifications preview based on id
 */
const getNotificationPreviewService = async (
  params: NotificationPreviewParams
) => {
  const { data: res } = await axiosJSON.post(`/api/reports/getPreview`, params);

  if (res.err || res.error) {
    throw res.msg ?? res.message ?? "Something went wrong!";
  }

  const ret: NotificationPreview = res.data;

  return ret;
};

export default getNotificationPreviewService;
