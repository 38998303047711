import { axiosJSON } from "src/globals/axiosEndPoints";
import APIResponse from "./APIReponse";
import { User } from "./getAllUser";

export interface GetUserParams {
  userId: number;
}

export async function getUser(params: GetUserParams) {
  const { data: res } = await axiosJSON.post<APIResponse<User>>(
    "/api/setting/user/get",
    params
  );

  if (res.error === true) {
    throw new Error(res.message as string);
  }

  return res.data as User;
}
