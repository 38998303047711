import styles from "./Customization.module.scss";
import { Accordion, Modal, Spinner } from "react-bootstrap";
import brush from "src/assets/images/brush.png";
import msg from "src/assets/images/comment.png";
import FinalChat from "./Children/FinalChat/FinalChat";
import Form from "react-bootstrap/Form";
import { Link, NavLink, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  fetchCustomizationData,
  resetCustomizationSettings,
} from "src/store/slices/liveChatSetting/chatWidgetSetting/customization/customizationSettings.slice";
import {
  IUpdateCustomizationParams,
  updateChatSetting,
} from "src/services/LiveChat/Settings/updateChatSettingData";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import CustomColorPicker from "../KnowledgeBased/Children/CreateArticle/children/HelpCenterSetting/children/ColorPicker/CustomColorPicker";
import Loader from "src/components/Loader";
import {
  convertGmtToUserTimezone,
  getDayAndTime,
  isDateStringValid,
} from "src/utils/dateLibrary";
const Customization = () => {
  const { integrationId } = useParams();
  const pageIntegrationId = integrationId
    ? decodeURIComponent(integrationId)
    : "";

  const { customizationData, languagesList, customizationDataAjaxStatus } =
    useAppSelector((state) => state.customizationSettings);
  const userTimezone = useAppSelector(
    (state) => state.globals.currentUserData?.userTimezone
  );
  const dispatch = useAppDispatch();

  const [topbarHeadingText, setTopbarHeadingText] = useState("");
  const [topbarSubHeadingText, setTopbarSubHeadingText] = useState("");
  const [statusTextWhenTeamOnline, setStatusTextWhenTeamOnline] = useState("");
  const [statusTextWhenTeamOffline, SetStatusTextWhenTeamOffline] =
    useState("");
  const [autoreplayText, setAutoreplyText] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState(null as any);
  const [selectedWidgetPosition, setSelectedWidgetPosition] = useState(
    null as any
  );
  const [selectedMinimizedWindowColour, setSelectedMinimizedWindowColor] =
    useState({ label: "", value: "" });
  const [selectedLiveChatThemeColor, setSelectedLiveChatThemeColor] = useState({
    label: "",
    value: "",
  });
  const [showOnlineStatusText, setShowOnlineStatusText] = useState(true);
  const [showAutoReplyText, setShowAutoReplyText] = useState(false);

  const [offlineTextFocus, setOfflineTextFocus] = useState(false);
  const [onlineTextFocus, setOnlineTextFocus] = useState(false);

  const [showSaveLoader, setShowSaveLoader] = useState(false);
  const [showSaveChanges, setShowSaveChanges] = useState(false);

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    dispatch(fetchCustomizationData(pageIntegrationId));
  }, [false]);

  useEffect(() => {
    if (customizationData !== null) {
      setTopbarHeadingText(customizationData.topbarHeading);
      setTopbarSubHeadingText(customizationData.topbarSubHeading);
      setStatusTextWhenTeamOnline(
        customizationData.statusTextWhenTeamOnline.value
      );
      SetStatusTextWhenTeamOffline(
        customizationData.statusTextWhenTeamOffline.value
      );
      setAutoreplyText(customizationData.autoreplayText);

      setSelectedLanguage(customizationData.languageId);

      setSelectedWidgetPosition(customizationData.appearances.widgetPosition);

      setSelectedMinimizedWindowColor({
        label: customizationData.appearances.appearanceWindowColor.color,
        value: customizationData.appearances.appearanceWindowColor.value,
      });

      setSelectedLiveChatThemeColor({
        label: customizationData.appearances.liveChatThemeColor.color,
        value: customizationData.appearances.liveChatThemeColor.value,
      });
    }
  }, [customizationData]);

  useEffect(() => {
    if (customizationData !== null && customizationData !== undefined) {
      if (
        customizationData.languageId !== selectedLanguage ||
        customizationData.topbarHeading !== topbarHeadingText ||
        customizationData.topbarSubHeading !== topbarSubHeadingText ||
        customizationData.statusTextWhenTeamOffline.value !==
          statusTextWhenTeamOffline ||
        customizationData.statusTextWhenTeamOnline.value !==
          statusTextWhenTeamOnline ||
        customizationData.autoreplayText !== autoreplayText ||
        customizationData.appearances.appearanceWindowColor.value !==
          selectedMinimizedWindowColour.value ||
        customizationData.appearances.liveChatThemeColor.value !==
          selectedLiveChatThemeColor.value ||
        customizationData.appearances.widgetPosition !== selectedWidgetPosition
      ) {
        setShowSaveChanges(true);
      } else {
        setShowSaveChanges(false);
      }
    }
  }, [
    selectedLanguage,
    topbarHeadingText,
    topbarSubHeadingText,
    statusTextWhenTeamOffline,
    statusTextWhenTeamOnline,
    autoreplayText,
    selectedWidgetPosition,
    selectedMinimizedWindowColour,
    selectedLiveChatThemeColor,
  ]);

  const handleLanguageChange = (event: any) => {
    clearAutoReplyView();
    setSelectedLanguage(event.target.value);
  };

  const handleWidgetPositionChange = (event: any) => {
    setSelectedWidgetPosition(event.target.value);
  };

  const handleMinimizedWindowColorChange = (color: string, value: string) => {
    clearAutoReplyView();
    setSelectedMinimizedWindowColor({
      value: value,
      label: color,
    });
  };
  const handleLiveChatThemeColorChange = (color: string, value: string) => {
    clearAutoReplyView();
    setSelectedLiveChatThemeColor({
      value: value,
      label: color,
    });
  };

  const clearAutoReplyView = () => {
    setShowAutoReplyText(false);
  };

  const onHide = () => {
    setShowModal(false);
  };

  const handleSubmit = () => {
    if (
      customizationData !== null &&
      customizationDataAjaxStatus !== "pending"
    ) {
      let payload: IUpdateCustomizationParams = {
        appearances: {
          appearanceWindowColor: {
            color: selectedMinimizedWindowColour.label,
            value: selectedMinimizedWindowColour.value,
          },
          liveChatThemeColor: {
            color: selectedLiveChatThemeColor.label,
            value: selectedLiveChatThemeColor.value,
          },
          widgetPosition: selectedWidgetPosition,
        },
      };

      if (customizationData.languageId !== selectedLanguage.value) {
        payload["languageId"] = selectedLanguage.value;
      }

      if (customizationData.topbarHeading !== topbarHeadingText) {
        payload["topbarHeading"] = topbarHeadingText;
      }

      if (customizationData.topbarSubHeading !== topbarSubHeadingText) {
        payload["topbarSubHeading"] = topbarSubHeadingText;
      }

      if (
        customizationData.statusTextWhenTeamOffline.value !==
        statusTextWhenTeamOffline
      ) {
        payload["statusTextWhenTeamOffline"] = statusTextWhenTeamOffline;
      }

      if (
        customizationData.statusTextWhenTeamOnline.value !==
        statusTextWhenTeamOnline
      ) {
        payload["statusTextWhenTeamOnline"] = statusTextWhenTeamOnline;
      }

      if (customizationData.autoreplayText !== autoreplayText) {
        payload["autoreplayText"] = autoreplayText;
      }

      setShowSaveLoader(true);

      updateChatSetting({
        integrationId: pageIntegrationId,
        customization: payload,
      })
        .then((res) => {
          pushTheToast({
            type: "success",
            text: "Changes Saved !",
            position: "top-right",
          });
          dispatch(resetCustomizationSettings());
          dispatch(fetchCustomizationData(pageIntegrationId));
          setShowSaveLoader(false);
        })
        .catch((err) => {
          pushTheToast({
            type: "danger",
            text: "Something went wrong!",
            position: "top-right",
          });
          setShowSaveLoader(false);
        });
    }
  };

  const getAvailabilityDate = useCallback(
    (datetimeGMT: string) => {
      if (!isDateStringValid(datetimeGMT)) {
        return datetimeGMT;
      }

      const convertDateTime = convertGmtToUserTimezone(
        datetimeGMT,
        userTimezone
      );
      const datetime = new Date(convertDateTime);
      return getDayAndTime(datetime);
    },
    [userTimezone]
  );

  const handleCancel = () => {
    if (customizationData !== null) {
      setTopbarHeadingText(customizationData.topbarHeading);
      setTopbarSubHeadingText(customizationData.topbarSubHeading);
      setStatusTextWhenTeamOnline(
        customizationData.statusTextWhenTeamOnline.value
      );
      SetStatusTextWhenTeamOffline(
        customizationData.statusTextWhenTeamOffline.value
      );
      setAutoreplyText(customizationData.autoreplayText);

      setSelectedLanguage(customizationData.languageId);

      setSelectedWidgetPosition(customizationData.appearances.widgetPosition);

      setSelectedMinimizedWindowColor({
        label: customizationData.appearances.appearanceWindowColor.color,
        value: customizationData.appearances.appearanceWindowColor.value,
      });

      setSelectedLiveChatThemeColor({
        label: customizationData.appearances.liveChatThemeColor.color,
        value: customizationData.appearances.liveChatThemeColor.value,
      });

      pushTheToast({
        type: "success",
        text: "Changes Discarded !",
        position: "top-right",
      });
      onHide();
      setShowSaveChanges(false);
    }
  };

  return (
    <div className={`${styles.Customization}`}>
      <div className={`d-md-flex`}>
        {/* left side UI */}
        <div className={`${styles.customLeft}`}>
          <h3 className={`${styles.heading}`}>Customization</h3>
          <span className={`${styles.subHeading}`}>
            Decide how the chat widget on your website will look, behave and
            what information it will offer.
          </span>
          {customizationDataAjaxStatus === "pending" ? (
            <Loader />
          ) : (
            <>
              <div className="mb-2 mt-3">
                <Accordion defaultActiveKey="1">
                  <Accordion.Item
                    eventKey="0"
                    className={`${styles.accordItem}`}
                  >
                    <Accordion.Header className={`${styles.toggleHead}`}>
                      <img
                        className={`${styles.brushImg}`}
                        src={brush}
                        alt="brush"
                      />
                      <span className={`${styles.installPara} ms-2`}>
                        Appearance
                      </span>
                    </Accordion.Header>
                    <Accordion.Body className={`${styles.accordBody}`}>
                      <div className="mb-3">
                        <div className={`${styles.windowCol}`}>
                          <p>Minimized Window Colour</p>
                        </div>
                        <div className={`flex-wrap ${styles.colorSelect}`}>
                          <input
                            type="radio"
                            name="radio"
                            value="#FF5B5B"
                            checked={
                              selectedMinimizedWindowColour.label ==
                              "bittersweet"
                            }
                            onChange={(e) =>
                              handleMinimizedWindowColorChange(
                                "bittersweet",
                                e.target.value
                              )
                            }
                          />
                          <input
                            data-lpignore="true"
                            className={`${styles.blackCol}`}
                            type="radio"
                            name="radio"
                            value="#000000"
                            checked={
                              selectedMinimizedWindowColour.label == "black"
                            }
                            onChange={(e) =>
                              handleMinimizedWindowColorChange(
                                "black",
                                e.target.value
                              )
                            }
                          />
                          <input
                            data-lpignore="true"
                            className={`${styles.blueCol}`}
                            type="radio"
                            name="radio"
                            value="#0B68BB"
                            checked={
                              selectedMinimizedWindowColour.label == "blue"
                            }
                            onChange={(e) =>
                              handleMinimizedWindowColorChange(
                                "blue",
                                e.target.value
                              )
                            }
                          />
                          <input
                            data-lpignore="true"
                            className={`${styles.skyblueCol}`}
                            type="radio"
                            name="radio"
                            value="#1492E6"
                            checked={
                              selectedMinimizedWindowColour.label == "skyblue"
                            }
                            onChange={(e) =>
                              handleMinimizedWindowColorChange(
                                "skyblue",
                                e.target.value
                              )
                            }
                          />
                          <input
                            data-lpignore="true"
                            className={`${styles.maroonCol}`}
                            type="radio"
                            name="radio"
                            value="#710F43"
                            checked={
                              selectedMinimizedWindowColour.label == "maroon"
                            }
                            onChange={(e) =>
                              handleMinimizedWindowColorChange(
                                "maroon",
                                e.target.value
                              )
                            }
                          />
                          <input
                            data-lpignore="true"
                            className={`${styles.multiCol}`}
                            type="radio"
                            name="radio"
                            value="custom"
                            checked={false}
                            onClick={(e) =>
                              handleMinimizedWindowColorChange(
                                "custom",
                                selectedMinimizedWindowColour.value
                              )
                            }
                          />
                          {selectedMinimizedWindowColour.label === "custom" ? (
                            <CustomColorPicker
                              color={selectedMinimizedWindowColour.value}
                              onChange={(e: any) =>
                                handleMinimizedWindowColorChange(
                                  "custom",
                                  e.hex
                                )
                              }
                              showColorBlocks={false}
                            />
                          ) : null}
                        </div>
                      </div>
                      <div className={`mb-3`}>
                        <div className={`${styles.windowCol}`}>
                          <p>Live Chat theme Colour</p>
                        </div>
                        <div className={`flex-wrap ${styles.colorSelect}`}>
                          <input
                            type="radio"
                            name="radio1"
                            value="#FF5B5B"
                            checked={
                              selectedLiveChatThemeColor.label == "bittersweet"
                            }
                            onChange={(e) =>
                              handleLiveChatThemeColorChange(
                                "bittersweet",
                                e.target.value
                              )
                            }
                          />
                          <input
                            data-lpignore="true"
                            className={`${styles.blackCol}`}
                            type="radio"
                            name="radio1"
                            value="#000000"
                            checked={
                              selectedLiveChatThemeColor.label == "black"
                            }
                            onChange={(e) =>
                              handleLiveChatThemeColorChange(
                                "black",
                                e.target.value
                              )
                            }
                          />
                          <input
                            data-lpignore="true"
                            className={`${styles.blueCol}`}
                            type="radio"
                            name="radio1"
                            value="#0B68BB"
                            checked={selectedLiveChatThemeColor.label == "blue"}
                            onChange={(e) =>
                              handleLiveChatThemeColorChange(
                                "blue",
                                e.target.value
                              )
                            }
                          />
                          <input
                            data-lpignore="true"
                            className={`${styles.skyblueCol}`}
                            type="radio"
                            name="radio1"
                            value="#1492E6"
                            checked={
                              selectedLiveChatThemeColor.label == "skyblue"
                            }
                            onChange={(e) =>
                              handleLiveChatThemeColorChange(
                                "skyblue",
                                e.target.value
                              )
                            }
                          />
                          <input
                            data-lpignore="true"
                            className={`${styles.maroonCol}`}
                            type="radio"
                            name="radio1"
                            value="#710F43"
                            checked={
                              selectedLiveChatThemeColor.label == "maroon"
                            }
                            onChange={(e) =>
                              handleLiveChatThemeColorChange(
                                "maroon",
                                e.target.value
                              )
                            }
                          />
                          <input
                            data-lpignore="true"
                            className={`${styles.multiCol}`}
                            type="radio"
                            name="radio"
                            value="custom"
                            checked={false}
                            onClick={(e) =>
                              handleLiveChatThemeColorChange(
                                "custom",
                                selectedLiveChatThemeColor.value
                              )
                            }
                          />
                          {selectedLiveChatThemeColor.label === "custom" ? (
                            <CustomColorPicker
                              color={selectedLiveChatThemeColor.value}
                              onChange={(e: any) =>
                                handleLiveChatThemeColorChange("custom", e.hex)
                              }
                              showColorBlocks={false}
                            />
                          ) : null}
                        </div>
                      </div>
                      <div className="mb-3">
                        <div className={`${styles.windowCol}`}>
                          <p>Widget Position</p>
                          <div className="d-flex align-items-center">
                            <span className="me-2">Align to: </span>
                            <div className="me-3">
                              <Form.Select
                                aria-label="Default select example"
                                bsPrefix={`${styles.selectDay} ${styles.selectDay2}`}
                                value={
                                  selectedWidgetPosition != null
                                    ? selectedWidgetPosition
                                    : "left"
                                }
                                onChange={handleWidgetPositionChange}
                              >
                                <option value="right">Right</option>
                                <option value="left">Left</option>
                              </Form.Select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
              <div>
                <Accordion defaultActiveKey="1">
                  <Accordion.Item
                    eventKey="0"
                    className={`${styles.accordItem}`}
                  >
                    <Accordion.Header className={`${styles.toggleHead}`}>
                      <img
                        className={`${styles.brushImg}`}
                        src={msg}
                        alt="msg"
                      />
                      <span className={`${styles.installPara} ms-2`}>
                        Customise Greetings/Phrases
                      </span>
                    </Accordion.Header>
                    <Accordion.Body
                      className={`${styles.accordBody} ${styles.customBody}`}
                    >
                      <div className="mb-3">
                        <p className={`${styles.windowCol} mb-2`}>
                          Select Language
                        </p>

                        <Form.Select
                          aria-label="Default select example"
                          bsPrefix={`${styles.selectDay} ${styles.selectDay2}`}
                          value={
                            selectedLanguage != null ? selectedLanguage : ""
                          }
                          onChange={handleLanguageChange}
                        >
                          {Object.values(languagesList.languages).map(
                            (option) => (
                              <option value={option.id}>{option.name}</option>
                            )
                          )}
                        </Form.Select>
                      </div>
                      <div>
                        <p className={`${styles.windowCol}`}>
                          Welcome your customers
                        </p>
                        <div className={`mb-3`}>
                          <p className={`${styles.customPara} mb-1`}>
                            Top bar heading
                          </p>

                          <input
                            className={`px-2 ${styles.inputBox}`}
                            type={"text"}
                            value={topbarHeadingText}
                            onChange={(e) => {
                              setTopbarHeadingText(e.target.value);
                              clearAutoReplyView();
                            }}
                            placeholder={`Hello !`}
                          />
                        </div>
                        <div>
                          <p className={`${styles.customPara} mb-1`}>
                            Top bar sub-heading
                          </p>

                          <input
                            className={`px-2 ${styles.inputBox}`}
                            type={"text"}
                            value={topbarSubHeadingText}
                            onChange={(e) => {
                              setTopbarSubHeadingText(e.target.value);
                              clearAutoReplyView();
                            }}
                            placeholder={`Welcome to Acme support. How can we help you?`}
                          />
                        </div>
                      </div>
                      <div className="mt-3">
                        <p className={`${styles.windowCol} mb-2`}>
                          When team online
                        </p>
                        <p className={`${styles.customPara} mb-1`}>
                          Visitors can directly send chats when the team is
                          online. You can set the
                          <NavLink
                            to={`/live-chat/settings/${integrationId}/Availability?preview=offlineTeam`}
                            className="mx-1"
                          >
                            availability
                          </NavLink>
                          hours
                        </p>
                        <div>
                          <div
                            className={`px-2 ${styles.inputBox} ${
                              onlineTextFocus === true ? `${styles.focus}` : ""
                            } d-flex align-items-center`}
                          >
                            <span className={`${styles.readonlySpan}`}>
                              {customizationData.statusTextWhenTeamOnline
                                .readonly ?? "We generally reply in "}
                            </span>
                            <input
                              className={`${styles.editableInput}`}
                              type={"text"}
                              onChange={(e) => {
                                setStatusTextWhenTeamOnline(e.target.value);
                                clearAutoReplyView();
                              }}
                              onClick={(e) => {
                                setShowOnlineStatusText(true);
                                clearAutoReplyView();
                              }}
                              value={statusTextWhenTeamOnline}
                              placeholder={`few minutes`}
                              onFocus={(e) => {
                                setOnlineTextFocus(true);
                                clearAutoReplyView();
                              }}
                              onBlur={(e) => setOnlineTextFocus(false)}
                              readOnly={true}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="mt-3">
                        <p className={`${styles.windowCol} mb-2`}>
                          When team is not available for chat
                        </p>
                        <p className={`${styles.customPara} mb-1`}>
                          Visitors can directly send chats when the team is
                          offline. You can set the
                          <NavLink
                            to={`/live-chat/settings/${integrationId}/Availability?preview=offlineTeam`}
                            className="mx-1"
                          >
                            availability
                          </NavLink>
                          hours
                        </p>
                        <div className="mb-3">
                          <div
                            className={`px-2 ${styles.inputBox} ${
                              onlineTextFocus === true ? `${styles.focus}` : ""
                            } d-flex align-items-center`}
                          >
                            <span className={`${styles.readonlySpan}`}>
                              {customizationData.statusTextWhenTeamOffline
                                .readonly ?? "We'll be back at"}
                            </span>
                            <input
                              className={`${styles.editableInput}`}
                              type={"text"}
                              value={getAvailabilityDate(
                                customizationData.statusTextWhenTeamOffline
                                  .value
                              )}
                              readOnly={true}
                              placeholder={`Mon, 10:41`}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mb-1 d-none">
                        <p className={`${styles.previewHead} mb-2`}>
                          Automatic reply to chat when the store is offline or
                          fill{" "}
                          <NavLink
                            to={`/live-chat/settings/${integrationId}/preChat`}
                            className="mx-1"
                          >
                            Ticket
                          </NavLink>
                          form
                        </p>
                        <div className={`py-2 px-3 ${styles.previewChat}`}>
                          <p className={`${styles.previewMsg}`}>
                            Thanks ! We'll be back online in 3 hours . Someone
                            from our team will reach out to here in this chat as
                            well as via E-mail .
                          </p>
                          <p className="mb-0">
                            <span className={`d-block ${styles.replyAt}`}>
                              {" "}
                              We generally reply at :{" "}
                            </span>
                            <span
                              className={`d-block mb-0 ${styles.replyTime}`}
                            >
                              Monday - Saturday , 9:00 am - 10 : pm
                            </span>
                          </p>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
              {showSaveChanges && (
                <div className={`${styles.btnSave}`}>
                  <button
                    type="button"
                    className={`${styles.cancelBtn}`}
                    onClick={(e) => setShowModal(true)}
                  >
                    Cancel
                  </button>
                  <button
                    className={`mt-2 mt-md-0 ${styles.saveBtn}`}
                    type="submit"
                    disabled={showSaveLoader === true}
                    onClick={handleSubmit}
                  >
                    {showSaveLoader === true ? (
                      <Spinner
                        className="my-auto mx-1"
                        animation="border"
                        color="white"
                        size="sm"
                      />
                    ) : (
                      "Save changes"
                    )}
                  </button>
                </div>
              )}
            </>
          )}
          <Modal
            backdropClassName={`${styles.modalBack}`}
            show={showModal}
            onHide={onHide}
            dialogClassName={`${styles.modalDialog}`}
            contentClassName={`${styles.modalContent}`}
            //   centered={true}
            enforceFocus={false}
          >
            <>
              <div className="text-center">
                <p className={`${styles.delPara}`}>
                  Are you sure you want to cancel the changes you have made?
                </p>
                <span className={`${styles.delSpan}`}></span>
                <div className={`${styles.actionBtns}`}>
                  <button
                    className={`${styles.cancelModalBtn}`}
                    onClick={onHide}
                  >
                    No
                  </button>
                  <button
                    className={`mt-2 mt-md-0 ${styles.confirmBtn}`}
                    onClick={handleCancel}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </>
          </Modal>
        </div>
        <div className={`${styles.customRight}`}>
          <FinalChat
            customizationData={{
              topbarHeadingText: topbarHeadingText,
              topbarSubHeadingText: topbarSubHeadingText,
              statusTextWhenTeamOnline: {
                readonly: customizationData.statusTextWhenTeamOnline.readonly,
                value: statusTextWhenTeamOnline,
              },
              statusTextWhenTeamOffline: {
                readonly: customizationData.statusTextWhenTeamOffline.readonly,
                value: getAvailabilityDate(
                  customizationData.statusTextWhenTeamOffline.value
                ),
              },
              autoreplayText: autoreplayText,
              showOnlineStatusText: showOnlineStatusText,
              minimizedWindowColour: selectedMinimizedWindowColour.value,
              liveChatThemeColor: selectedLiveChatThemeColor.value,
              showAutoReplyText: showAutoReplyText,
              selectedWidgetPosition:
                selectedWidgetPosition != null
                  ? selectedWidgetPosition
                  : "left",
            }}
            show={{ homePage: showAutoReplyText !== true, askForEmail: false }}
          />
        </div>
      </div>
    </div>
  );
};
export default Customization;
