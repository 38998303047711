import { axiosJSON } from "src/globals/axiosEndPoints";
import { IMessageData } from "src/store/slices/innerTicket/innerTicket.slice";

interface MetaData {
  totalReplies: number;
  lastReplyDateGmt: string;
}

interface ApiResponse {
  err: boolean;
  data: IMessageData[];
  metaData?: MetaData;
  msg: string;
}

const getBotAiThreadmsg = async (payload: {
  parentMessageId: number | undefined | null;
  ticketId: number;
  start: number;
  limit: number;
}) => {
  const { data: res } = await axiosJSON.post(`/api/message/getBotAiThreadMessages`, payload);

  if (res.err || res.error) {
    throw res.msg ?? res.message ?? "Something went wrong!";
  }

  return res as ApiResponse;
};

export default getBotAiThreadmsg;
