import { useCallback, useMemo, useState } from "react";
import { AJAXSTATUS } from "src/globals/constants";
import { FieldValueInstance } from "src/services/Automation/AutomationTicketRouting/getConditionOptions.service";
import getConditionSelectDDService, {
  ConditionSelectDDParams,
} from "src/services/Automation/AutomationTicketRouting/getConditionSelectDD.service";

const CONDITIONDD_LIMIT = 100;

/**
 * Custom hook to fetch condition dropdown data based on the provided parameters.
 *
 * This hook manages the state and logic required to fetch condition dropdown options
 * from the `getConditionSelectDDService`. It maintains the fetched data, the request
 * status, and whether there are more options to load.
 *
 * @param params - The parameters for fetching condition dropdown data.
 * @param params.type - The type of the automation.
 * @param params.fieldKey - The key of the field for which options are to be fetched.
 * @param params.key - The key identifying the condition.
 * @param params.doNotFetchFromApi - The key to return options as it is without calling api.
 * @returns An object containing the fetched condition dropdown options, request status, and more.
 * conditionOpitionDD - The fetched condition dropdown options.
 * conditionOpitionDDStatus - The status of the request, which can be one of "fulfilled", "pending", or "rejected".
 * hasMore - Boolean indicating whether there are more options to load.
 * fetchConditionDD - Function to fetch the condition dropdown options.
 *
 * @example
 * const { conditionOpitionDD, conditionOpitionDDStatus, hasMore, fetchConditionDD } = useFetchConditionDD({
 *   type: 'type',
 *   fieldKey: 'fieldKey',
 *   key: 'key',
 * });
 *
 * @see {@link getConditionSelectDDService} for the service used to fetch condition dropdown options.
 */
function useFetchConditionDD({
  type,
  fieldKey,
  key,
  fieldValuesFetched,
  doNotFetchFromApi,
}: Omit<ConditionSelectDDParams, "start" | "limit"> & {
  fieldValuesFetched: Array<FieldValueInstance>;
  doNotFetchFromApi?: boolean;
}) {
  const [conditionOpitionDD, setConditionOpitionDD] = useState<
    Array<FieldValueInstance>
  >([...fieldValuesFetched]);

  const [conditionOpitionDDStatus, setConditionOpitionDDStatus] =
    useState<AJAXSTATUS>("fulfilled");
  const [hasMore, setHasMore] = useState(false);

  useMemo(() => {
    setConditionOpitionDD([...fieldValuesFetched]);
    setConditionOpitionDDStatus("fulfilled");
    setHasMore(false);
  }, [type, fieldKey, key, fieldValuesFetched]);

  const fetchConditionDD = useCallback(() => {
    if (conditionOpitionDDStatus === "fulfilled" && !doNotFetchFromApi) {
      setConditionOpitionDDStatus("pending");
      getConditionSelectDDService({
        type,
        fieldKey,
        key,
        start: conditionOpitionDD.length ?? 0,
        limit: CONDITIONDD_LIMIT,
      }).then((res) => {
        setConditionOpitionDDStatus("fulfilled");
        setConditionOpitionDD((prev) => {
          return [...prev, ...res.data];
        });
        if (
          res.metaData?.isCompleted ||
          res.data.length !== CONDITIONDD_LIMIT
        ) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
      });
    }
  }, [conditionOpitionDD, conditionOpitionDDStatus, type, fieldKey, key]);

  return {
    conditionOpitionDD,
    conditionOpitionDDStatus,
    hasMore,
    fetchConditionDD,
  };
}

export default useFetchConditionDD;
