import { PayloadAction } from "@reduxjs/toolkit";
import { AJAXSTATUS } from "src/globals/constants";
import { ISnoozeData } from "src/services/Snooze/getSnooze";
import { TicketStatus } from "src/services/TicketService/getAllStatuses";
import {
  IMessageData,
  initialState,
  InnerTicketFilterType,
  InnerTicketType,
} from "./innerTicket.slice";

const resetInnerTicket = (state: InnerTicketType) => {
  state = {
    ...initialState,
    lastGlobalSearchUrl: state.lastGlobalSearchUrl, //search url reset done manually
    lastActiveTableType: state.lastActiveTableType,
  };
  return state;
};

const resetInnerTicketData = (state: InnerTicketType) => {
  state.ticketData = {};
  state.ticketList = [];
  state.ticketListTotal = null;
  return state;
};

const resetInnerTicketMessage = (state: InnerTicketType) => {
  state.messageData = {};
  state.messageList = [];
  state.hasMoreMessage = true;
  return state;
};

const setInnerTicketFilters = (
  state: InnerTicketType,
  { payload }: { payload: object },
) => {
  state.filters = { ...state.filters, ...payload };
};
const setGlobalData = (
  state: InnerTicketType,
  { payload }: { payload: { searchText: string; tableType: "string" } },
) => {
  state.globalSearch = payload;
};

const setInnerTicketActive = (
  state: InnerTicketType,
  { payload }: { payload: any },
) => {
  state.active = { ...state.active, ...payload };
};
const setActiveTicketInfoFromTicketData = (
  state: InnerTicketType,
  { payload }: { payload: any },
) => {
  state.activeTicketInfo = state.ticketData[payload.ticketId]
    ? state.ticketData[payload.ticketId]
    : {};
};
/**
 * This will set copied ai draft in redux store
 *
 * @param state
 * @param payload contains message which is copied
 */
const setCopiedAiDraft = (
  state: InnerTicketType,
  { payload }: { payload: { message: string; id: string } },
) => {
  state.copiedAiDraft = payload.message;
  state.aiDraftMsgId = payload.id;
};

const setInnerTicketUserID = (
  state: InnerTicketType,
  { payload }: { payload: { assignedTo: any; ticketId: number | string } },
) => {
  state.activeTicketInfo.ticket_assigned_to = payload.assignedTo;
  if (state.ticketData[payload.ticketId]) {
    state.ticketData[payload.ticketId].ticket_assigned_to = payload.assignedTo;
  }
};
const setInnerTicketStatus = (
  state: InnerTicketType,
  { payload }: { payload: { status: TicketStatus; ticketId: number | string } },
) => {
  state.activeTicketInfo.ticket_status = {
    ticketStatusId: payload.status.id,
    ticketStatusName: payload.status.statusName,
  };
  if (state.ticketData[payload.ticketId]) {
    state.ticketData[payload.ticketId].ticket_status = {
      ticketStatusId: payload.status.id,
      ticketStatusName: payload.status.statusName,
    };
  }
};

const setSearchTextTicketSb = (
  state: InnerTicketType,
  { payload }: { payload: { searchText: string } },
) => {
  state.filters.searchText = payload.searchText;
};
const setInnerTicketActiveTags = (
  state: InnerTicketType,
  { payload }: { payload: { tags: any; ticketId: number | string } },
) => {
  state.activeTicketInfo.ticket_tags = payload.tags;
  if (state.ticketData[payload.ticketId]) {
    state.ticketData[payload.ticketId].ticket_tags = payload.tags;
  }
};

// to select a ticket by id
// const selectTicketById = (
//   state: InnerTicketType,
//   {payload}: {payload: {ticketId: number | string}}
// ) => {
//   // console.log("Ticket id selected to be merged: "+payload);
//   state.ticketMerge.allSelectedTicketIds.push(payload.ticketId);
//   // console.log("Id of merge ticket selected: "+ payload);
// };

// to select a ticket by id
const selectMergeTicketById = (
  state: InnerTicketType,
  action: PayloadAction<string>,
) => {
  const ticketId = action.payload;
  // console.log("Ticket id selected to be merged: " + ticketId);
  state.ticketMerge.allSelectedTicketIds.push(ticketId);
  // console.log("Id of merge ticket selected: " + ticketId);
};

// to remove selected ticket by id
const removeSelectedMergeTicketById = (
  state: InnerTicketType,
  action: PayloadAction<string>,
) => {
  const ticketIdToBeRemoved = action.payload;
  // console.log("Selected ticket id to be removed: " + ticketIdToBeRemoved);
  state.ticketMerge.allSelectedTicketIds =
    state.ticketMerge.allSelectedTicketIds.filter(
      (ticketId) => ticketId !== ticketIdToBeRemoved,
    );
};

// to select all tickets to merge
const selectMergeAllTicketsById = (state: InnerTicketType) => {
  state.ticketMerge.allSelectedTicketIds = state.ticketMerge.ticketList;
};

// remove all selected tickets
const removeAllSelectedMergeTickets = (state: InnerTicketType) => {
  state.ticketMerge.allSelectedTicketIds = [];
};

// reset ticketMerge
const resetTicketMerge = (state: InnerTicketType) => {
  state.ticketMerge = initialState.ticketMerge;
};

// reset all tickets nav
const resetAllTicketsNav = (state: InnerTicketType) => {
  state.allTicketsNav = initialState.allTicketsNav;
};

// check and set whether there is a prev ticket
const setHasPrevTicket = (
  state: InnerTicketType,
  { payload }: { payload: { ticketId: number } },
) => {
  let index = state.allTicketsNav.allTicketsIdList.indexOf(payload.ticketId);
  if (index !== -1) {
    if (index - 1 >= 0) {
      state.allTicketsNav.hasPrev = true;
      state.allTicketsNav.prevTicketId =
        state.allTicketsNav.allTicketsIdList[index - 1];
    } else {
      state.allTicketsNav.hasPrev = false;
      state.allTicketsNav.prevTicketId = null;
    }
  } else {
    state.allTicketsNav.hasPrev = false;
    state.allTicketsNav.prevTicketId = null;
  }
};

// check and set whether there is a next ticket
const setHasNextTicket = (
  state: InnerTicketType,
  { payload }: { payload: { ticketId: number } },
) => {
  let index = state.allTicketsNav.allTicketsIdList.indexOf(payload.ticketId);
  if (index !== -1) {
    if (index + 1 < state.allTicketsNav.allTicketsIdList.length) {
      state.allTicketsNav.hasNext = true;
      state.allTicketsNav.nextTicketId =
        state.allTicketsNav.allTicketsIdList[index + 1];
    } else {
      state.allTicketsNav.hasNext = false;
      state.allTicketsNav.nextTicketId = null;
    }
  } else {
    state.allTicketsNav.hasNext = false;
    state.allTicketsNav.nextTicketId = null;
  }
};

// reset all customers search
const resetSearchAllCustomers = (state: InnerTicketType) => {
  state.searchCustomersData = initialState.searchCustomersData;
};

const setSearchAllCustomersSearchText = (
  state: InnerTicketType,
  { payload }: { payload: { searchText: string } },
) => {
  state.searchCustomersData.searchTerm = payload.searchText;
};

const resetActiveMessageThread = (state: InnerTicketType) => {
  state.activeTicketInfo.message_thread = undefined;
};

const resetSnoozeData = (
  state: InnerTicketType,
  { payload }: { payload: { ajaxStatus: AJAXSTATUS } | undefined },
) => {
  state.activeSnooze.snoozeData = initialState.activeSnooze.snoozeData;
  if (payload?.ajaxStatus) {
    state.activeSnooze.snoozeDataAjaxStatus = payload.ajaxStatus;
  } else {
    state.activeSnooze.snoozeDataAjaxStatus =
      initialState.activeSnooze.snoozeDataAjaxStatus;
  }
};

// to set ticket subject in inner ticket and ticket data
const setTicketSubject = (
  state: InnerTicketType,
  { payload }: { payload: { updatedSubject: string; ticketId: number } },
) => {
  if (
    state.activeTicketInfo.ticket_id &&
    state.activeTicketInfo.ticket_id === payload.ticketId
  ) {
    state.activeTicketInfo.ticket_subject = payload.updatedSubject;
  }
  if (state.ticketData[payload.ticketId]) {
    state.ticketData[payload.ticketId].ticket_subject = payload.updatedSubject;
  }
};

const setActiveCustomerName = (
  state: InnerTicketType,
  { payload }: { payload: { customerName: string } },
) => {
  state.activeTicketInfo.ticket_customer_name = payload.customerName;
};

const updateSideBarTicketsCustomerName = (
  state: InnerTicketType,
  { payload }: { payload: { customerId: number; name: string } },
) => {
  state.ticketList.forEach((ticketId) => {
    if (state.ticketData[ticketId]) {
      if (
        state.ticketData[ticketId].ticket_customer_name &&
        state.ticketData[ticketId]?.customer_id == payload.customerId
      ) {
        state.ticketData[ticketId].ticket_customer_name = payload.name;
      }
    }
  });
};

const setSnoozeData = (
  state: InnerTicketType,
  { payload }: { payload: { ticketId: number; snoozeData: ISnoozeData } },
) => {
  if (state.active.ticketId == payload.ticketId) {
    state.activeSnooze.snoozeData = payload.snoozeData;
    state.activeSnooze.snoozeDataAjaxStatus = "fulfilled";
  }
};

const removeTicketFromSidebar = (
  state: InnerTicketType,
  {
    payload,
  }: { payload: { ticketIds: number[]; checkActiveStatus?: boolean } },
) => {
  const ticketIds: number[] = [];

  if (state.filters.ticketStatus !== 0) {
    payload.ticketIds.forEach((ticketId) => {
      if (payload.checkActiveStatus === true) {
        if (
          state.ticketData[ticketId] &&
          state.ticketData.ticket_status?.ticketStatusId !=
            state.filters.ticketStatus
        ) {
          ticketIds.push(ticketId);
        }
      } else {
        if (state.ticketList.includes(ticketId)) {
          ticketIds.push(ticketId);
        }
      }
    });
  }

  if (ticketIds.length) {
    state.ticketList = state.ticketList.filter((id) => !ticketIds.includes(id));
    let tickets = { ...state.ticketData };
    ticketIds.forEach((ticketId) => {
      if (tickets[ticketId]) {
        delete tickets[ticketId];
      }
    });
    state.ticketData = tickets;
    state.ticketListTotal =
      (state.ticketListTotal ?? 0) - ticketIds.length <= 0
        ? 0
        : (state.ticketListTotal ?? 0) - ticketIds.length;
  }
};

const filterInnerTicketsByActiveFilter = (state: InnerTicketType) => {
  const ticketIds: number[] = [];
  if (state.filters.ticketStatus !== 0) {
    Object.values(state.ticketData).forEach((ticket) => {
      if (ticket.ticket_status?.ticketStatusId != state.filters.ticketStatus) {
        ticketIds.push(parseInt(ticket.ticket_id));
      }
    });
  }

  if (ticketIds.length) {
    state.ticketList = state.ticketList.filter((id) => !ticketIds.includes(id));
    let tickets = { ...state.ticketData };
    ticketIds.forEach((ticketId) => {
      if (tickets[ticketId]) {
        delete tickets[ticketId];
      }
    });
    state.ticketData = tickets;
    state.ticketListTotal =
      (state.ticketListTotal ?? 0) - ticketIds.length <= 0
        ? 0
        : (state.ticketListTotal ?? 0) - ticketIds.length;
  }
};

const setSidebarTicketStatus = (
  state: InnerTicketType,
  { payload }: { payload: { status: TicketStatus; ticketId: number | string } },
) => {
  if (state.ticketData[payload.ticketId]) {
    state.ticketData[payload.ticketId].ticket_status = {
      ticketStatusId: payload.status.id,
      ticketStatusName: payload.status.statusName,
    };
  }
};

const setActiveScheduleMessageCount = (
  state: InnerTicketType,
  { payload }: { payload: { count: number } },
) => {
  state.activeTicketInfo.ticket_schedule_message_count = payload.count;
};

const setLastSearchURL = (
  state: InnerTicketType,
  {
    payload,
  }: {
    payload: {
      searchUrl?: string;
      ticketRoute?: string;
      searchUrlIsApplied?: boolean;
    };
  },
) => {
  if (payload.searchUrl !== undefined) {
    state.lastGlobalSearchUrl.searchRoute = payload.searchUrl;
  }
  if (payload.ticketRoute !== undefined) {
    state.lastGlobalSearchUrl.ticketRoute = payload.ticketRoute;
  }
  if (payload.searchUrlIsApplied !== undefined) {
    state.lastGlobalSearchUrl.searchUrlIsApplied = payload.searchUrlIsApplied;
  }
};

const setLastActiveTableType = (
  state: InnerTicketType,
  { payload }: { payload: { lastActiveTableType: string | null | undefined } },
) => {
  state.lastActiveTableType = payload.lastActiveTableType;
};

const addMessageForTryAgain = (
  state: InnerTicketType,
  { payload }: { payload: IMessageData },
) => {
  if (payload.messageUuid) {
    state.messageList.unshift(payload.messageUuid + "");
    state.messageData[payload.messageUuid + ""] = payload;
  }
};

const addMessage = (
  state: InnerTicketType,
  { payload }: { payload: IMessageData },
) => {
  if (payload.messageId) {
    const msgId = payload.messageId + "";
    if (
      !state.messageList.includes(msgId) ||
      !state.messageList.includes(payload.messageUuid + "")
    ) {
      state.messageList.unshift(msgId);
    }

    const index = state.messageList.indexOf(payload.messageUuid + "");
    if (index !== -1) {
      // Replace the UUID with messageId
      state.messageList[index] = msgId;
      delete state.messageData[payload.messageUuid + ""];
    }
    state.messageData[msgId] = payload;
  }
};

const updateTryAgainMessageStatus = (
  state: InnerTicketType,
  { payload }: { payload: { uuid: string; messageStatus: AJAXSTATUS } },
) => {
  if (payload.uuid && state.messageList.includes(payload.uuid)) {
    state.messageData[payload.uuid].messageStatus = payload.messageStatus;
  }
};

/**
 * Clear integration failure data before trying again
 */
const clearIntegrationFailure = (
  state: InnerTicketType,
  { payload }: { payload: { uuid?: string } },
) => {
  if (payload.uuid && state.messageList.includes(payload.uuid)) {
    if (state.messageData[payload.uuid].integrationFailure) {
      state.messageData[payload.uuid].integrationFailure = undefined;
    }
  }
};

const setShowOtherTicketMessages = (
  state: InnerTicketType,
  { payload }: { payload: { show: boolean; type: "message" | "note" } },
) => {
  if (payload.type === "note") {
    state.showOtherTicketNotes = payload.show;
  } else {
    state.showOtherTicketMsg = payload.show;
  }
};

const setShowCernThread = (
  state: InnerTicketType,
  { payload }: { payload: { show: boolean } },
) => {
  state.showCernThread = payload.show;
};

const setSendCernMsgId = (
  state: InnerTicketType,
  { payload }: { payload: { msgId: number | null } },
) => {
  state.sendCernMsgId = payload.msgId;
};

const setRefetchCernThread = (
  state: InnerTicketType,
  { payload }: { payload: { refetch: boolean } },
) => {
  state.refetchCernThread = payload.refetch;
};

const setCernMessageError = (
  state: InnerTicketType,
  { payload }: { payload: { msgId: number | undefined } },
) => {
  if (payload.msgId) {
    state.cernErrorMessageId = payload.msgId;
  }
};

const setCernRepliesCount = (
  state: InnerTicketType,
  { payload }: { payload: { msgId: number; count: number; date: string } },
) => {
  const threadData = state.messageData[payload.msgId]?.cernThreadData;
  if (threadData) {
    threadData.totalReplies = payload.count;
    threadData.lastReplyDateGmt = payload.date;
  }
};

const setShowEnableSlack = (
  state: InnerTicketType,
  { payload }: { payload: { show: boolean; ticketId: string } },
) => {
  if (state.showEnableSlack) {
    state.showEnableSlack[payload.ticketId] = payload.show;
  } else {
    state.showEnableSlack = { [payload.ticketId]: payload.show };
  }
};

const updateMessageInfo = (
  state: InnerTicketType,
  {
    payload,
  }: {
    payload: { id: string | number; messageInfo: IMessageData["messageInfo"] };
  },
) => {
  if (payload.id && state.messageList.includes(payload.id)) {
    state.messageData[payload.id].messageInfo = payload.messageInfo;
  }
};

/**
 * Reducer for updating the feedback data from pusher event
 */
const updateCustomerFeedback = (
  state: InnerTicketType,
  {
    payload,
  }: {
    payload: {
      id: string | number;
      customerFeedback: IMessageData["customerFeedback"];
      botInfo: IMessageData["botInfo"];
    };
  },
) => {
  // Check if message is in the list
  if (payload.id && state.messageList.includes(payload.id)) {
    // Assign customer feedback if it is updated
    if (payload.customerFeedback) {
      state.messageData[payload.id].customerFeedback = payload.customerFeedback;
    }

    // Assign bot info if it is updated
    if (payload.botInfo) {
      state.messageData[payload.id].botInfo = payload.botInfo;
    }
  }
};

export default {
  resetInnerTicket,
  setInnerTicketFilters,
  resetInnerTicketData,
  resetInnerTicketMessage,
  setInnerTicketActive,
  setInnerTicketUserID,
  setActiveTicketInfoFromTicketData,
  setSearchTextTicketSb,
  setInnerTicketActiveTags,
  setGlobalData,
  selectMergeTicketById,
  removeSelectedMergeTicketById,
  selectMergeAllTicketsById,
  removeAllSelectedMergeTickets,
  resetTicketMerge,
  setInnerTicketStatus,
  resetAllTicketsNav, // for ticket navigation through left right arrow btns in inner ticket
  setHasNextTicket,
  setHasPrevTicket,
  // for customers search in inner ticket
  resetSearchAllCustomers,
  setSearchAllCustomersSearchText,
  resetActiveMessageThread,
  setTicketSubject,
  resetSnoozeData,
  setActiveCustomerName,
  updateSideBarTicketsCustomerName,
  setSnoozeData,
  removeTicketFromSidebar,
  filterInnerTicketsByActiveFilter,
  setSidebarTicketStatus,
  setActiveScheduleMessageCount,
  setLastSearchURL,
  setLastActiveTableType,
  addMessageForTryAgain,
  updateTryAgainMessageStatus,
  setShowOtherTicketMessages,
  setShowCernThread,
  setSendCernMsgId,
  setRefetchCernThread,
  setCernMessageError,
  setCernRepliesCount,
  setShowEnableSlack,
  addMessage,
  updateMessageInfo,
  clearIntegrationFailure, // For integration failure for try again
  updateCustomerFeedback,
  setCopiedAiDraft,
};
