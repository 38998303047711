/**
 * This file is the service file used for making api request.
 * It contains the getActiveThemeDetails function service which is explained below.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants"; // this is used for testing purpose only for frontend.

export interface GetActiveThemePayload {
  integrationId: number | string;
}

export interface GetActiveThemeRes {
  themeId: number;
  name: string;
  desc: string;
  heading: string;
  subheading: string;
  helplamaBranding: boolean;
  backgroundColor: string;
  brandColor: string;
  placeholder: string;
  storeName: string;
  logoImage: string | null;
  backgroundImage: string | null;
  favicon: string | null;
  footerLogo: string | null;
  font: string;
  language: string;
  zeroArticle: string;
  oneArticle: string;
  articleCount: number;
  noTitleArticleText: string;
  customCodeHead: string;
  customCodeBody: string;
  helpCenterPageUri: string;
  themeType: "alice_in_wonderland" | "night_in_the_oscars";
  helplamaBrandingText?: string; //property which is used to render "powered by" text in preview
}

/**
 * For testing frontend with own api link if backend is not prepared.
 */
const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/getKnowledgeBaseStyles`
  : "/api/chatSetting/getActiveThemeDetails";

/**
 * This service is used to get the active theme details in the KB settings.
 */
export const getActiveThemeDetails = async (params: GetActiveThemePayload) => {
  const { data: res } = await axiosJSON.post(apiEndPoint, params);
  return res;
};
