/**
 * This file defines a custom hook responsible for searching external source articles
 *
 * @author @yuvaraj-busibud
 */
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useDebounce from "src/hooks/useDebounce";
import { Articles } from "src/services/Bot/AnswerSources/getAllExternal.service";
import { Article } from "src/services/Bot/AnswerSources/getAllKbArticles.service";
import {
  GetSourceArticlesParams,
  GetSourceArticlesResponse,
  getSourceArticles,
} from "src/services/Bot/AnswerSources/getSourceArticles.service";
import {
  SearchSourceArticlesParams,
  searchAllSourceArticles,
} from "src/services/Bot/AnswerSources/searchSourceArticles.service";
import {
  calculateTotalPages,
  pageSlice,
  paginationLogic,
} from "src/services/TicketService/pagination";
interface Props {
  sourceId?: number;
  search: string;
}
function useSearchExternalArticles({ sourceId, search }: Props) {
  const [currentPage, setCurrentPage] = useState(1);

  const queryClient = useQueryClient();

  const lastSearchTermRef = useRef("");
  const lastSearchSourceId = useRef(0);

  const { profileId } = useParams();

  const payload = useMemo(() => {
    let temp: any = {
      pageNo: currentPage,
      searchTerm: search,
      profileId: profileId,
    };

    if (sourceId) {
      temp.sourceId = sourceId;
    }
    return temp;
  }, [profileId, sourceId, currentPage, search]);

  const totalPagesRef = useRef(0);

  const currentState = useRef({
    payload: payload,
    currentPage: currentPage,
  });

  useMemo(() => {
    currentState.current.payload = payload;
    currentState.current.currentPage = currentPage;
  }, [payload, search, currentPage]);

  const cachedDataRef = useRef<{
    data: { [key: number]: Articles[] };
    metaData: { count: number; total: number };
  }>({ data: {}, metaData: { count: 0, total: 0 } });

  const fetchDataForPage = useCallback(
    async (payload: any) => {
      const perAjaxFetchLimit = 10;
      const pageNo = payload.pageNo;
      if (
        lastSearchTermRef.current !== (payload.searchTerm ?? "") ||
        lastSearchSourceId.current !== payload.sourceId
      ) {
        //clearing the cache if search applied
        cachedDataRef.current = { data: {}, metaData: { count: 0, total: 0 } };
        lastSearchTermRef.current = payload.searchTerm ?? "";
        lastSearchSourceId.current = payload.sourceId;
      }
      const cachedData = cachedDataRef.current.data;

      if (cachedData[pageNo]) {
        // If data for the requested page is already cached, return it.
        return {
          data: cachedData[pageNo],
          metaData: cachedDataRef.current.metaData,
        } as GetSourceArticlesResponse;
      } else {
        const { startPage, endPage, start, limit } = paginationLogic(
          pageNo,
          perAjaxFetchLimit,
          cachedDataRef.current.metaData.total
        );

        let sourceArticles: GetSourceArticlesResponse = {
          data: [],
          metaData: { count: 0, total: 0 },
        };
        if (payload.sourceId) {
          const sourceArticlePayload: GetSourceArticlesParams = {
            start,
            limit,
            sourceId: payload.sourceId,
          };

          if (payload.searchTerm?.trim()) {
            sourceArticlePayload.searchTerm = payload.searchTerm;
          }

          sourceArticles = await getSourceArticles(sourceArticlePayload);
        } else {
          const sourceArticlePayload: SearchSourceArticlesParams = {
            start,
            limit,
            searchTerm: payload.searchTerm,
            botProfileId: payload.profileId + "",
          };

          sourceArticles = await searchAllSourceArticles(sourceArticlePayload);
        }

        // If data is not in the cache, fetch it and store it in the cache.
        const totalPages = calculateTotalPages(
          sourceArticles.metaData.total,
          perAjaxFetchLimit
        );
        totalPagesRef.current = totalPages;

        let count = 0;
        for (let i = startPage; i <= endPage; i++) {
          cachedData[i] = pageSlice(
            sourceArticles.data,
            perAjaxFetchLimit,
            count
          );
          count++;
        }
        cachedDataRef.current.metaData = sourceArticles.metaData;
        return {
          data: cachedData[pageNo],
          metaData: cachedDataRef.current.metaData,
        } as GetSourceArticlesResponse;
      }
    },
    [profileId]
  );

  //react query hook to fetch source articles
  const { data, isLoading, status, fetchStatus } = useQuery({
    queryKey: ["searchSourceArticles", payload],
    queryFn: () => {
      const dataForPage = fetchDataForPage(payload);
      return dataForPage;
    },
    enabled: search !== "",
  });

  //function to update article value in react query
  const updateArticleValue = useCallback(
    (articleId: number, values: Partial<Article>) => {
      //checking if the passed value exists in the object, if yes then proceed
      const updatedData = cachedDataRef.current.data[currentPage]?.map(
        (article) =>
          article.articleId === articleId ? { ...article, ...values } : article
      );

      if (updatedData) {
        cachedDataRef.current.data[currentPage] = updatedData;
      }

      //getting the query data from react-query cache
      let queryData: GetSourceArticlesResponse | undefined =
        queryClient.getQueryData([
          "searchSourceArticles",
          currentState.current.payload,
        ]);

      //update the cache
      if (queryData) {
        const articlesData = queryData?.data.map((article) =>
          article.articleId === articleId ? { ...article, ...values } : article
        );

        // 2. Update the data in the cache using queryClient.setQueryData
        queryClient.setQueryData(
          ["searchSourceArticles", currentState.current.payload],
          {
            ...queryData,
            data: articlesData,
          }
        );
      }
    },
    [data, payload, currentPage]
  );

  //function to update unselect all articles in the react query
  const unSelectAllArticle = useCallback(() => {
    const updatedData = Object.values(cachedDataRef.current.data).forEach(
      (articles) => articles.map((article) => (article.isSelected = false))
    );

    let queryData: GetSourceArticlesResponse | undefined =
      queryClient.getQueryData([
        "searchSourceArticles",
        currentState.current.payload,
      ]);

    if (queryData) {
      const articlesData = queryData?.data.map((article) => {
        article.isSelected = false;
        return article;
      });

      // 2. Update the data in the cache using queryClient.setQueryData
      queryClient.setQueryData(
        ["searchSourceArticles", currentState.current.payload],
        {
          ...queryData,
          data: articlesData,
        }
      );
    }
  }, [data, payload, currentPage]);

  return {
    articles: data?.data,
    isLoading,
    currentPage,
    setCurrentPage,
    totalPages: totalPagesRef.current,
    updateArticleValue,
    fetchStatus,
    status,
    unSelectAllArticle,
    totalCount: data?.metaData.total,
  };
}

export default useSearchExternalArticles;
