import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";
import {
  ActionGroup,
  Group,
  IActionForModal,
} from "../getAutomationById.service";

export interface UpdateActionsParams extends ActionGroup {
  type: string;
  integrationId: string;
}

async function updateActions({ type, ...params }: UpdateActionsParams) {
  const apiEndPoint = IS_TEST_ACTIVE
    ? `${process.env.REACT_APP_TEST_SITE_URL}/${type}/updateActions`
    : `/api/automation/${type}/updateActions`;
  const { data: res } = await axiosJSON.post(apiEndPoint, params);

  if (res.error || res.err) {
    throw new Error(res.msg ?? res.message ?? "Something went wrong!");
  }

  return res.data;
}

export default updateActions;
