import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { IUpdateChatSettingParams, updateChatSetting } from "src/services/LiveChat/Settings/updateChatSettingData";
import { fetchChatSettingData } from "src/store/slices/liveChatSetting/chatWidgetSetting/chatSetting/chatSetting.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import styles from "./FileShare.module.scss";
const FileShare = () => {

  const { integrationId } = useParams();
  const pageIntegrationId = integrationId ? decodeURIComponent(integrationId) : "";

  const { chatSettingData, chatSettingDataAjaxStatus } =
  useAppSelector((state) => state.liveChatSetting);
  const [showSaveChange , setShowSaveChange] = useState(false);

  const [enabledForVisitors, setEnabledForVisitors] = useState<boolean>(false);
  const [enabledForAgents, setEnabledForAgents] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(()=>{
    if(chatSettingData !== null && (chatSettingData.fileSharingForVisitors !== enabledForVisitors || chatSettingData.fileSharingForAgents !== enabledForAgents)){
      setShowSaveChange(true);
    }else{
      setShowSaveChange(false);
    }
  },[enabledForVisitors,enabledForAgents]);



  useEffect(() => {
    dispatch(fetchChatSettingData(pageIntegrationId));
  }, [false]);

  useEffect(()=>{
    if(chatSettingData !== null){
      setEnabledForAgents(chatSettingData.fileSharingForAgents);
      setEnabledForVisitors(chatSettingData.fileSharingForVisitors);
    }
  },[chatSettingData]);

  const handleSave = () =>{
    if(chatSettingData !== null && chatSettingDataAjaxStatus !== "pending"){

      let payload : IUpdateChatSettingParams = {
        fileSharingForAgents: enabledForAgents,
        fileSharingForVisitors: enabledForVisitors,
      }

      updateChatSetting({
        integrationId: pageIntegrationId,
        chatSetting:payload
      }).then((res)=>{
        pushTheToast({
          type: "success",
          text:"Successfully changed",
          position: "top-right",
        });
        dispatch(fetchChatSettingData(pageIntegrationId));
        setShowSaveChange(false);
      }).catch((err)=>{
        pushTheToast({
          type: "danger",
          text: "Something went wrong!",
          position: "top-right",
        });
      });
    }
  };

  const handleDiscard = () =>{
    if(chatSettingData !== null){
      setEnabledForAgents(chatSettingData.fileSharingForAgents);
      setEnabledForVisitors(chatSettingData.fileSharingForVisitors);
    }

    setShowSaveChange(false);
  };

  return (
    <div className={`${styles.fileShare}`}>
      <h1 className={`${styles.fileHeading}`}>File Sharing</h1>
      <h2 className={`${styles.fileShareHead}`}>How file sharing works</h2>
      <span className={`${styles.fileShareSub}`}>
        You can use LiveChat to share and receive files. When a file is shared,
        a link appears in the chat window and in the Archives. Anyone with the
        link can use it to download the file.
      </span>
      <div className="mt-3">
        <h2 className={`${styles.fileShareHead}`}>Enable File sharing </h2>
        <div className="form-check">
          <input
            className="form-check-input"
            data-lpignore="true"
            type="checkbox"
            value=""
            id="flexCheckDefault"
            checked={enabledForVisitors}
            onClick={(e)=>setEnabledForVisitors(!enabledForVisitors)}
          />
          <label
            className={`form-check-label ${styles.filLabel}`}
            htmlFor="flexCheckDefault"
          >
            For Visitors
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            data-lpignore="true"
            type="checkbox"
            value=""
            id="flexCheckDefault"
            checked={enabledForAgents}
            onClick={(e)=>setEnabledForAgents(!enabledForAgents)}
          />
          <label
            className={`form-check-label ${styles.filLabel}`}
            htmlFor="flexCheckDefault"
          >
            For Agent
          </label>
        </div>
        <button className={`${styles.addRuleBtn} mt-3`} disabled={showSaveChange === false} onClick={handleSave}>Save changes</button> {showSaveChange && ( <button className={`bg-white ${styles.discardBtn} mt-3`} onClick={handleDiscard}>Discard</button>)}
      </div>
    </div>
  );
};
export default FileShare;
