import styles from "../CustomerEdit/CustomerEdit.module.scss";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import twitter from "src/assets/images/twitter.png";
import { useAppSelector } from "src/store/store";
import { InputFieldsContext } from "../CustomerEdit/useInputFields";
import { useContext, useEffect } from "react";
import AddMore from "../AddMore/AddMore";

function Twitter() {
  const twt = useAppSelector(
    (state) => state.editcustomerdetails.customer.contacts?.twitter,
  );

  // let defaultValue;
  // if (twt !== undefined) {
  //   if (twt?.length > 0) {
  //     defaultValue = twt[props.idx];
  //   } else {
  //     defaultValue = "";
  //   }
  // }

  const {
    addField,
    updateField,
    removeField,
    setFieldsOfKey,
    fields,
    areDuplicateFields,
  } = useContext(InputFieldsContext);

  useEffect(() => {
    setFieldsOfKey(twt ?? [], "twt");
  }, [twt]);

  const twitterFields = fields.get("twt");

  const isAddButtonEnabled = twitterFields?.length
    ? twitterFields[twitterFields.length - 1]?.isValid
      ? true
      : false
    : false;

  const { hasDuplicates, duplicateIds } = areDuplicateFields.duplicates["twt"];

  return (
    <>
      {fields.get("twt")?.map((field, idx) => {
        const hasError = hasDuplicates && duplicateIds.includes(field.id);
        return (
          <div
            className="d-flex flex-column"
            key={field.id}
          >
            {idx === 0 ? (
              <label
                htmlFor="twt"
                className={`d-flex justify-content-between align-items-center form-label ${styles.labelName} m-0`}
              >
                <div className={`d-flex align-items-center`}>
                  <div
                    className={`d-flex justify-content-center align-items-center ${styles.msgBg}`}
                  >
                    <img
                      src={twitter}
                      className={`${styles.twit}`}
                      alt="twitter"
                    />
                  </div>{" "}
                  <div>
                    <span className={`ps-1`}>Twitter</span>
                  </div>
                </div>
              </label>
            ) : (
              <></>
            )}

            <div
              className="d-flex mt-2 "
              style={{ width: "103.5%", overflowX: "hidden" }}
            >
              <input
                data-lpignore="true"
                type="text"
                className={`form-control ${styles.formInput} ${
                  hasError && "border-danger"
                }`}
                id="twt"
                aria-describedby="emailHelp"
                placeholder="eg. @username"
                required
                name="Twitter"
                value={field.value as string}
                onChange={(e) => {
                  var regex = /^\s*$/;
                  updateField(
                    field,
                    {
                      ...field,
                      value: e.target.value,
                      isValid: !regex.test(e.target.value),
                    },
                    "twt",
                  );
                }}
              />
              <div
                className={`${styles.customCrossPosition}`}
                onClick={() => {
                  // dispatch(
                  //   deleteContact({
                  //     id: id as string,
                  //     type: "twitter",
                  //     idx: props.idx,
                  //   })
                  // );
                  removeField(field, "twt");
                }}
              >
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className={`${styles.toolTipCustom}`}>
                      Remove
                    </Tooltip>
                  }
                >
                  {({ ref, ...triggerHandler }) => (
                    <div
                      className={` `}
                      {...triggerHandler}
                      ref={ref}
                    >
                      <div
                        className={`d-flex justify-content-center align-items-center ${styles.clearText}`}
                      >
                        <i className="fa-solid fa-xmark"></i>
                      </div>
                    </div>
                  )}
                </OverlayTrigger>
              </div>
            </div>
            {hasError && (
              <span className={`${styles.validateSpan}`}>
                Duplicate Twitter ID not allowed
              </span>
            )}
          </div>
        );
      })}
      <span>
        <AddMore
          type="Account"
          isValid={isAddButtonEnabled}
          onClick={() => {
            if (!isAddButtonEnabled) {
              return;
            }
            addField({ value: "" }, "twt");
          }}
          isVisible={(fields.get("twt")?.length ?? 0) > 0}
        />
      </span>
    </>
  );
}

export default Twitter;

{
  /* <div className="mb-3">
  <label
    htmlFor="name"
    className={`d-flex justify-content-between align-items-center form-label ${styles.labelName}`}
  >
    <div className={`d-flex align-items-center`}>
      <div
        className={`d-flex justify-content-center align-items-center me-1 ${styles.msgBg}`}
      >
        <img src={twitter} className={`${styles.twit}`} />
      </div>{" "}
      <div>
        {" "}
        <span>Twitter</span>
      </div>
    </div>
     <div className={`d-flex justify-content-center align-items-center ${styles.clearText}`}>
                    <i className="fa-solid fa-xmark"></i>
                  </div> 
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip className={`${styles.toolTipCustom}`}>Remove</Tooltip>}
    >
      {({ ref, ...triggerHandler }) => (
        <div className={` ms-0 me-0`} {...triggerHandler} ref={ref}>
          <div
            className={`d-flex justify-content-center align-items-center ${styles.clearText}`}
          >
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>
      )}
    </OverlayTrigger>
  </label>
  <input
    data-lpignore="true"
    type="url"
    className={`form-control ${styles.formInput}`}
    id="name"
    aria-describedby="emailHelp"
    placeholder="eg. @username"
    required
  />
</div>; 

      */
}
