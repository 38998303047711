import { Channel } from "pusher-js";

export type IntegrationUpdateEvent = (res: { integrationId: string }) => void;

export const getIntegrationUpdated = (
  channel: Channel,
  callback: IntegrationUpdateEvent,
) => {
  channel.bind("integration_updated", callback, {
    pauseOnInActiveTab: true,
  });
};

export const getIntegrationUpdatedUnbind = (channel: Channel) => {
  channel.unbind("integration_updated");
};
