import styles from "./AllOrderMoreColoumn.module.scss";
import { Dropdown, Button } from "react-bootstrap";
const AllOrderMoreColoumn = () => {
  return (
    <div
      className={`d-flex justify-content-between align-items-center ${styles.AllOrderMain}`}
    >
      <div>
        <span className={`${styles.Orders}`}>Orders</span>
      </div>
      {/* <div className={`d-flex ${styles.ExportMAain}`}>
        <div>
          <span className={`${styles.Export}`}>Export</span>
        </div>
        <div className={``}>
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic" className={`${styles.moreActionDrop}`}>
              More actions
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="#/action-1" className={`${styles.AllrderItems}`} >Action</Dropdown.Item>
              <Dropdown.Item href="#/action-2"  className={`${styles.AllrderItems}`}>Another action</Dropdown.Item>
              <Dropdown.Item href="#/action-3"  className={`${styles.AllrderItems}`}>Something else</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div>
          <Button variant="success" className={`${styles.CreateOrder}`}>Create Order</Button>{" "}
        </div>
      </div> */}
    </div>
  );
};
export default AllOrderMoreColoumn;
