import { useNavigate } from "react-router-dom";
import styles from "./SetupToast.module.scss";
import { useCallback } from "react";

const SetupToast = () => {
  const navigate = useNavigate();

  const navigationHandler = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <div className={`px-2 mb-4 ${styles.toastWrap}`}>
      <div className="d-flex align-items-center">
        <h3 className={`me-2 ${styles.toastHead}`}>
          Complete E-mail forwarding to saufter.io to receive replies
        </h3>
        <button
          className={`${styles.completeBtn} ${styles.setupBtn}`}
          onClick={navigationHandler}
        >
          Complete{" "}
          <span className="ps-1">
            <i className="fa-solid fa-arrow-right"></i>
          </span>
        </button>
      </div>

      <div className="d-flex align-items-center ms-5 ps-4">
        <div className={`${styles.verticalBar}`}></div>
        <h3 className={`ms-3 me-2 ${styles.toastHead}`}>
          Install live chat on your wesbsite
        </h3>
        <button
          className={`${styles.setupBtn} ${styles.installBtn}`}
          onClick={navigationHandler}
        >
          Install
          <span className="ps-1">
            <i className="fa-solid fa-arrow-right"></i>
          </span>
        </button>
      </div>
    </div>
  );
};

export default SetupToast;
