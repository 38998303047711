import { WebhookInfo } from "src/services/Integrations/getFacebookPageWebhooks";
import styles from "./CheckConditions.module.scss";
interface Props {
  webhookInfo: WebhookInfo;
  updateWebhook: (key: string, updatedWebhook: Partial<WebhookInfo>) => void;
  showErrorBorder: boolean;
}
const CheckConditions = ({
  webhookInfo,
  updateWebhook,
  showErrorBorder,
}: Props) => {
  return (
    <>
      <div className={`d-flex align-items-center mb-2`}>
        <div className="form-check form-switch me-3">
          <input
            data-lpignore="true"
            className={`form-check-input ${styles.formCheck} ${
              webhookInfo.hasPermission &&
              showErrorBorder &&
              !webhookInfo.enabled
                ? "border-danger"
                : ""
            }`}
            type="checkbox"
            checked={webhookInfo.enabled}
            disabled={!webhookInfo.hasPermission}
            onChange={(e) => {
              if (webhookInfo.hasPermission === true) {
                updateWebhook(webhookInfo.key, {
                  enabled: !webhookInfo.enabled,
                });
              }
            }}
          />
        </div>
        <div>
          <span className={`${styles.checkName}`}>{webhookInfo.label}</span>
        </div>
      </div>
    </>
  );
};
export default CheckConditions;
