import Lottie from "lottie-light-react";
import styles from "./SuccessMsg.module.scss";
import celebration from "src/assets/images/celebration.json";
import { GetImportProductsParams } from "src/services/ReturnAutoWorkFlow/ConfigModals/getImportProducts.service";
import { useParams } from "react-router-dom";
import { useUploadNewCsvModalContext } from "../Hooks/useUploadNewCsvModalContext";
import { useUploadedCSVModal } from "../../UploadedCsvModal/UploadedCsvModal";
import Loader from "src/components/Loader";

const SuccessMsg = () => {
  const { integrationId } = useParams();
  const { state } = useUploadNewCsvModalContext();
  const params: GetImportProductsParams = {
    integrationId: integrationId ?? "",
    start: 0,
    limit: 10,
    attachmentId: state.uploadCSV?.attachmentId ?? "",
  };

  const { isError, isLoading, data } = useUploadedCSVModal(params);

  return (
    <div>
      {isLoading ? (
        <div>
          <Loader />
        </div>
      ) : isError ? (
        <div className={`mt-3 ${styles.contentWrapper}`}>
          <div className="d-flex align-items-center justify-content-center text-danger">
            {"Something went wrong!"}
          </div>
        </div>
      ) : data ? (
        <div className={`text-center mt-5 ${styles.successWrapper}`}>
          <Lottie
            loop={false}
            className={`${styles.celebrationAnimation}`}
            animationData={celebration}
          ></Lottie>
          <span className={`mx-auto mb-3 ${styles.successIcon}`}>
            <i className="fa-solid fa-check"></i>
          </span>
          <p className={`mb-2 ${styles.successHead}`}>
            CSV file uploaded successful
          </p>
          <span className={`d-block ${styles.successSpan}`}>
            {state.uploadCSV?.uploadedFileName ?? ""}
          </span>
          <span className={`${styles.successDesc}`}>
            Total of {data?.totalVarients ?? 0} products and{" "}
            {data?.totalVarients ?? 0} SKU were imported
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default SuccessMsg;
