import { useCallback, useState } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import ReturnDiscardModal from "src/components/AutomationComponents/ReturnDiscardModal/ReturnDiscardModal";
import styles from "./CloseButton.module.scss";

function CloseButtonForTab({
  hasStaticTab,
  index,
  name,
  onDelete,
}: {
  name: string | null | undefined;
  index: number;
  hasStaticTab: boolean;
  onDelete: () => void;
}) {
  const [showModal, setModalShow] = useState(false);
  const handleClose = useCallback(() => setModalShow(false), []);

  return (
    <div
      className="ms-3 ms-md-0"
      role="button"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Modal
        className={`shadow-sm p-3 rounded`}
        show={showModal}
        onHide={handleClose}
        dialogClassName={`${styles.modalDialog2}`}
        contentClassName={`${styles.modalContent2}`}
        backdropClassName={`${styles.modalBackDrop}`}
        enforceFocus={false}
        keyboard={false}
      >
        <ReturnDiscardModal
          heading={`Do you want to delete ${name}?`}
          subText={`This will discard all the changes made in ${name}`}
          onDiscard={onDelete}
          onHide={handleClose}
        />
      </Modal>

      {index === 0 && !hasStaticTab ? null : (
        <CloseButton
          className={`${styles.closeButton} `}
          onClick={(e) => {
            setModalShow(true);
          }}
        />
        // <span
        //   className={`${styles.closeModal}`}
        //   onClick={(e) => {
        //     setModalShow(true);
        //   }}
        // >
      //   <i className="fa-solid fa-xmark"></i>
        // </span>
      )}
    </div>
  );
}

export default CloseButtonForTab;
