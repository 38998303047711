import makeAnimated from "react-select/animated";
import {
  TicketFilterCol,
  setFilterByAgents,
  setFilterByCreatedDate,
  setFilterByCreatedStartDate,
  setFilterByCreatedStartTime,
  setFilterByCreatedEndDate,
  setFilterByCreatedEndTime,
  setFilterByClosedDate,
  setFilterByClosedStartDate,
  setFilterByClosedStartTime,
  setFilterByClosedEndDate,
  setFilterByClosedEndTime,
  setFilterByChannels,
  setFilterByBrands,
  setFilterByTags,
  setFilterByEmails,
  setFilterByUserType,
  setFilterByLastMessage,
  setFilterByNotRepliedSince,
  setFilterByTicketStatus,
  setFilterByDraftStatus,
  setFilterByDraftedBy,
  setFilterByNotRepliedSinceDate,
  setFilterByNotRepliedSinceTime,
  setFilterByLastUpdated,
  setFilterByLastUpdatedEndTime,
  setFilterByLastUpdatedEndDate,
  setFilterByLastUpdatedStartDate,
  setFilterByLastUpdatedStartTime,
} from "src/store/slices/ticketFilters/ticketFilters.slice";
import log from "loglevel";
import { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/store/store";
import DatePicker from "react-datepicker";
import styles from "./FilterColDropdown.module.scss";
import { isObjOrStrEmpty } from "src/utils/utils";
import AsyncDropdown from "src/components/AsyncDropdown";
import {
  AGENT_OPTIONS_API_ROUTE,
  BRAND_OPTIONS_API_ROUTE,
  CHANNEL_OPTIONS_API_ROUTE,
  CLOSED_DATE_OPTIONS_API_ROUTE,
  CREATED_DATE_OPTIONS_API_ROUTE,
  LAST_UPDATED_OPTIONS_API_ROUTE,
  DRAFTED_BY_OPTIONS_API_ROUTE,
  DRAFT_STATUS_OPTIONS_API_ROUTE,
  EMAILS_OPTIONS_API_ROUTE,
  LAST_MESSAGE_OPTIONS_API_ROUTE,
  NOT_REPLIED_SINCE_OPTIONS_API_ROUTE,
  TAGS_OPTIONS_API_ROUTE,
  TICKET_STATUS_OPTIONS_API_ROUTE,
  USER_TYPE_OPTIONS_API_ROUTE,
} from "src/globals/constants";

function ColDropdown({
  filterCol,
  containerTarget,
  colRef,
}: {
  filterCol: TicketFilterCol;
  colRef?: React.MutableRefObject<any>;
  containerTarget?: any;
}) {
  const {
    filterByAgents,
    filterByCreatedDate,
    filterByCreatedDateValue,
    filterByClosedDate,
    filterByClosedDateValue,
    filterByChannels,
    filterByBrands,
    filterByTags,
    filterByEmails,
    filterByUserType,
    filterByLastMessage,
    filterByNotRepliedSince,
    filterByNotRepliedSinceValue,
    filterByTicketStatus,
    filterByDraftStatus,
    filterByDraftedBy,
    filterByLastUpdated,
    filterByLastUpdatedValue,
  } = useAppSelector((state) => state.ticketFilter.filters);
  const errors = useAppSelector((state) => state.ticketFilter.filterErrors);
  const dispatch = useAppDispatch();

  switch (filterCol.key) {
    case "ticket_date_gmt":
      return (
        <>
          <AsyncDropdown
            getOptionValue={(option: any) => option.id || option.compare}
            getOptionLabel={(option: any) => option.name}
            styles={customStyles}
            loadUrl={CREATED_DATE_OPTIONS_API_ROUTE}
            isMulti={filterCol.isMultiSelect}
            value={filterByCreatedDate}
            isDisabled={filterByCreatedDate?.viewFilter}
            onChange={(newValue: any) =>
              dispatch(setFilterByCreatedDate(newValue))
            }
            containerTarget={containerTarget}
          />
          {!isObjOrStrEmpty(filterByCreatedDate) &&
            filterByCreatedDate.isValueRequired === true && (
              <div className="mt-3">
                <GetFilterDatePicker
                  showError={errors["filterByCreatedDateValue"]?.showError}
                  dateRef={colRef}
                  readOnly={filterByCreatedDate?.viewFilter === true}
                  selectedDate={
                    filterByCreatedDateValue.startDate
                      ? new Date(filterByCreatedDateValue.startDate)
                      : null
                  }
                  selectedTime={
                    filterByCreatedDateValue.startTime
                      ? new Date(filterByCreatedDateValue.startTime)
                      : null
                  }
                  onChangeDate={(date: Date) =>
                    dispatch(setFilterByCreatedStartDate(date.toISOString()))
                  }
                  onChangeTime={(date: Date) =>
                    dispatch(setFilterByCreatedStartTime(date.toISOString()))
                  }
                />
              </div>
            )}

          {filterByCreatedDateValue.selectsRange === true && (
            <div className="mt-1">
              <div className="d-flex justify-content-center align-items-center p-0 mb-1">
                To
              </div>
              <GetFilterDatePicker
                showError={errors["filterByCreatedDateValue"]?.showError}
                readOnly={filterByCreatedDate?.viewFilter === true}
                selectedDate={
                  filterByCreatedDateValue.endDate
                    ? new Date(filterByCreatedDateValue.endDate)
                    : null
                }
                selectedTime={
                  filterByCreatedDateValue.endTime
                    ? new Date(filterByCreatedDateValue.endTime)
                    : null
                }
                onChangeDate={(date: Date) =>
                  dispatch(setFilterByCreatedEndDate(date.toISOString()))
                }
                onChangeTime={(date: Date) =>
                  dispatch(setFilterByCreatedEndTime(date.toISOString()))
                }
              />
            </div>
          )}
        </>
      );
    case "ticket_modified_gmt":
      return (
        <>
          <AsyncDropdown
            getOptionValue={(option: any) => option.id || option.compare}
            getOptionLabel={(option: any) => option.name}
            styles={customStyles}
            loadUrl={LAST_UPDATED_OPTIONS_API_ROUTE}
            isMulti={filterCol.isMultiSelect}
            value={filterByLastUpdated}
            isDisabled={filterByLastUpdated?.viewFilter}
            onChange={(newValue: any) =>
              dispatch(setFilterByLastUpdated(newValue))
            }
            containerTarget={containerTarget}
          />
          {!isObjOrStrEmpty(filterByLastUpdated) &&
            filterByLastUpdated.isValueRequired === true && (
              <div className="mt-3">
                <GetFilterDatePicker
                  showError={errors["filterByLastUpdatedValue"]?.showError}
                  dateRef={colRef}
                  readOnly={filterByLastUpdated?.viewFilter === true}
                  selectedDate={
                    filterByLastUpdatedValue.startDate
                      ? new Date(filterByLastUpdatedValue.startDate)
                      : null
                  }
                  selectedTime={
                    filterByLastUpdatedValue.startTime
                      ? new Date(filterByLastUpdatedValue.startTime)
                      : null
                  }
                  onChangeDate={(date: Date) =>
                    dispatch(
                      setFilterByLastUpdatedStartDate(date.toISOString())
                    )
                  }
                  onChangeTime={(date: Date) =>
                    dispatch(
                      setFilterByLastUpdatedStartTime(date.toISOString())
                    )
                  }
                />
              </div>
            )}

          {filterByLastUpdatedValue.selectsRange === true && (
            <div className="mt-1">
              <div className="d-flex justify-content-center align-items-center p-0 mb-1">
                To
              </div>
              <GetFilterDatePicker
                showError={errors["filterByLastUpdatedValue"]?.showError}
                readOnly={filterByCreatedDate?.viewFilter === true}
                selectedDate={
                  filterByLastUpdatedValue.endDate
                    ? new Date(filterByLastUpdatedValue.endDate)
                    : null
                }
                selectedTime={
                  filterByLastUpdatedValue.endTime
                    ? new Date(filterByLastUpdatedValue.endTime)
                    : null
                }
                onChangeDate={(date: Date) =>
                  dispatch(setFilterByLastUpdatedEndDate(date.toISOString()))
                }
                onChangeTime={(date: Date) =>
                  dispatch(setFilterByLastUpdatedEndTime(date.toISOString()))
                }
              />
            </div>
          )}
        </>
      );
    case "ticket_closed_date_gmt":
      return (
        <>
          <AsyncDropdown
            getOptionValue={(option: any) => option.id || option.compare}
            getOptionLabel={(option: any) => option.name}
            styles={customStyles}
            loadUrl={CLOSED_DATE_OPTIONS_API_ROUTE}
            isMulti={filterCol.isMultiSelect}
            value={filterByClosedDate}
            isDisabled={filterByClosedDate?.viewFilter}
            onChange={(newValue: any) =>
              dispatch(setFilterByClosedDate(newValue))
            }
            containerTarget={containerTarget}
          />
          {!isObjOrStrEmpty(filterByClosedDate) &&
            filterByClosedDate.isValueRequired === true && (
              <div className="mt-3">
                <GetFilterDatePicker
                  showError={errors["filterByClosedDateValue"]?.showError}
                  dateRef={colRef}
                  readOnly={filterByClosedDate?.viewFilter === true}
                  selectedDate={
                    filterByClosedDateValue.startDate
                      ? new Date(filterByClosedDateValue.startDate)
                      : null
                  }
                  selectedTime={
                    filterByClosedDateValue.startTime
                      ? new Date(filterByClosedDateValue.startTime)
                      : null
                  }
                  onChangeDate={(date: Date) =>
                    dispatch(setFilterByClosedStartDate(date.toISOString()))
                  }
                  onChangeTime={(date: Date) =>
                    dispatch(setFilterByClosedStartTime(date.toISOString()))
                  }
                />
              </div>
            )}

          {filterByClosedDateValue.selectsRange === true && (
            <div className="mt-1">
              <div className="d-flex justify-content-center align-items-center p-0 mb-1">
                To
              </div>
              <GetFilterDatePicker
                showError={errors["filterByClosedDateValue"]?.showError}
                readOnly={filterByClosedDate?.viewFilter === true}
                selectedDate={
                  filterByClosedDateValue.endDate
                    ? new Date(filterByClosedDateValue.endDate)
                    : null
                }
                selectedTime={
                  filterByClosedDateValue.endTime
                    ? new Date(filterByClosedDateValue.endTime)
                    : null
                }
                onChangeDate={(date: Date) =>
                  dispatch(setFilterByClosedEndDate(date.toISOString()))
                }
                onChangeTime={(date: Date) =>
                  dispatch(setFilterByClosedEndTime(date.toISOString()))
                }
              />
            </div>
          )}
        </>
      );
    case "ticket_channel":
      return (
        <AsyncDropdown
          getOptionValue={(option: any) => option.id || option.compare}
          getOptionLabel={(option: any) => option.name}
          styles={customStyles}
          loadUrl={CHANNEL_OPTIONS_API_ROUTE}
          isMulti={filterCol.isMultiSelect}
          value={filterByChannels}
          onChange={(newValue: any) => dispatch(setFilterByChannels(newValue))}
          containerTarget={containerTarget}
        />
      );
    case "ticket_brand_id":
      return (
        <AsyncDropdown
          getOptionValue={(option: any) => option.id || option.compare}
          getOptionLabel={(option: any) => option.name}
          styles={customStyles}
          loadUrl={BRAND_OPTIONS_API_ROUTE}
          isMulti={filterCol.isMultiSelect}
          value={filterByBrands}
          onChange={(newValue: any) => dispatch(setFilterByBrands(newValue))}
          containerTarget={containerTarget}
        />
      );
    case "ticket_agent":
      return (
        <AsyncDropdown
          getOptionValue={(option: any) => option.id || option.compare}
          getOptionLabel={(option: any) => option.name}
          styles={customStyles}
          loadUrl={AGENT_OPTIONS_API_ROUTE}
          isMulti={filterCol.isMultiSelect}
          value={filterByAgents}
          onChange={(newValue: any) => dispatch(setFilterByAgents(newValue))}
          containerTarget={containerTarget}
        />
      );
    case "tags":
      return (
        <AsyncDropdown
          getOptionValue={(option: any) => option.id || option.compare}
          getOptionLabel={(option: any) => option.name}
          styles={customStyles}
          loadUrl={TAGS_OPTIONS_API_ROUTE}
          isMulti={filterCol.isMultiSelect}
          value={filterByTags}
          onChange={(newValue: any) => dispatch(setFilterByTags(newValue))}
          containerTarget={containerTarget}
        />
      );
    case "filter_email":
      return (
        <AsyncDropdown
          getOptionValue={(option: any) => option.id || option.compare}
          getOptionLabel={(option: any) => option.name}
          styles={customStyles}
          loadUrl={EMAILS_OPTIONS_API_ROUTE}
          isMulti={filterCol.isMultiSelect}
          value={filterByEmails}
          onChange={(newValue: any) => dispatch(setFilterByEmails(newValue))}
          containerTarget={containerTarget}
        />
      );
    case "user_type":
      return (
        <AsyncDropdown
          getOptionValue={(option: any) => option.id || option.compare}
          getOptionLabel={(option: any) => option.name}
          styles={customStyles}
          loadUrl={USER_TYPE_OPTIONS_API_ROUTE}
          isMulti={filterCol.isMultiSelect}
          value={filterByUserType}
          onChange={(newValue: any) => dispatch(setFilterByUserType(newValue))}
          containerTarget={containerTarget}
        />
      );
    case "last_message":
      return (
        <AsyncDropdown
          getOptionValue={(option: any) => option.id || option.compare}
          getOptionLabel={(option: any) => option.name}
          styles={customStyles}
          loadUrl={LAST_MESSAGE_OPTIONS_API_ROUTE}
          isMulti={filterCol.isMultiSelect}
          value={filterByLastMessage}
          onChange={(newValue: any) =>
            dispatch(setFilterByLastMessage(newValue))
          }
          containerTarget={containerTarget}
        />
      );
    case "not_replied_since":
      return (
        <>
          <AsyncDropdown
            getOptionValue={(option: any) => option.id || option.compare}
            getOptionLabel={(option: any) => option.name}
            styles={customStyles}
            loadUrl={NOT_REPLIED_SINCE_OPTIONS_API_ROUTE}
            isMulti={filterCol.isMultiSelect}
            value={filterByNotRepliedSince}
            onChange={(newValue: any) =>
              dispatch(setFilterByNotRepliedSince(newValue))
            }
            containerTarget={containerTarget}
          />
          {!isObjOrStrEmpty(filterByNotRepliedSince) &&
            filterByNotRepliedSince.isValueRequired === true && (
              <div className="mt-3">
                <GetFilterDatePicker
                  showError={errors["filterByNotRepliedSinceValue"]?.showError}
                  dateRef={colRef}
                  selectedDate={
                    filterByNotRepliedSinceValue.startDate
                      ? new Date(filterByNotRepliedSinceValue.startDate)
                      : null
                  }
                  selectedTime={
                    filterByNotRepliedSinceValue.startTime
                      ? new Date(filterByNotRepliedSinceValue.startTime)
                      : null
                  }
                  onChangeDate={(date: Date) =>
                    dispatch(setFilterByNotRepliedSinceDate(date.toISOString()))
                  }
                  onChangeTime={(date: Date) =>
                    dispatch(setFilterByNotRepliedSinceTime(date.toISOString()))
                  }
                />
              </div>
            )}
        </>
      );
    case "ticket_status_id":
      return (
        <AsyncDropdown
          getOptionValue={(option: any) => option.id || option.compare}
          getOptionLabel={(option: any) => option.name}
          styles={customStyles}
          loadUrl={TICKET_STATUS_OPTIONS_API_ROUTE}
          isMulti={filterCol.isMultiSelect}
          value={filterByTicketStatus}
          onChange={(newValue: any) =>
            dispatch(setFilterByTicketStatus(newValue))
          }
          containerTarget={containerTarget}
        />
      );
    case "ticket_draft_status":
      return (
        <AsyncDropdown
          getOptionValue={(option: any) => option.id || option.compare}
          getOptionLabel={(option: any) => option.name}
          styles={customStyles}
          loadUrl={DRAFT_STATUS_OPTIONS_API_ROUTE}
          isMulti={filterCol.isMultiSelect}
          value={filterByDraftStatus}
          onChange={(newValue: any) =>
            dispatch(setFilterByDraftStatus(newValue))
          }
          containerTarget={containerTarget}
        />
      );
    case "drafted_by":
      return (
        <AsyncDropdown
          getOptionValue={(option: any) => option.id || option.compare}
          getOptionLabel={(option: any) => option.name}
          styles={customStyles}
          loadUrl={DRAFTED_BY_OPTIONS_API_ROUTE}
          isMulti={filterCol.isMultiSelect}
          value={filterByDraftedBy}
          onChange={(newValue: any) => dispatch(setFilterByDraftedBy(newValue))}
          containerTarget={containerTarget}
        />
      );
    default:
      return <></>;
  }
}

function FilterColDropdown({
  filterCol,
  colRef,
  containerTarget,
}: {
  filterCol: TicketFilterCol;
  colRef?: React.MutableRefObject<any>;
  containerTarget?: any;
}) {
  return (
    <>
      <div
        className={`mt-3`}
        id={`filtersItem_${filterCol.name.toLowerCase().replace(" ", "_")}`}
      >
        <label className={`${styles.filterLabel}`}>{filterCol.name}</label>
      </div>
      <ColDropdown
        filterCol={filterCol}
        containerTarget={containerTarget}
        colRef={colRef}
      />
    </>
  );
}

const CustomDatePicker = forwardRef(
  ({ value, onClick, faIcon, error }: any, ref: any) => (
    <div
      className={`d-flex justify-content-center align-items-center p-0 ${
        styles.dateDiv
      } ${error ? "border-danger" : ""}`}
      onClick={onClick}
      ref={ref}
    >
      {/* <i className="fa-regular fa-calendar-check m-auto mx-1" /> */}
      <i className={`fa-regular ${faIcon} m-auto mx-1`} />
      {value}
    </div>
  )
);

export const GetFilterDatePicker = ({
  selectedDate,
  selectedTime,
  onChangeDate,
  onChangeTime,
  readOnly = false,
  dateRef,
  showError = false,
}: {
  selectedDate: Date | null;
  selectedTime: Date | null;
  onChangeDate: (date: Date) => void;
  onChangeTime: (date: Date) => void;
  readOnly?: boolean; //used this flag to disable editing of view filter
  dateRef?: React.MutableRefObject<any>;
  showError?: boolean;
}) => {
  return (
    <>
      <div className={`d-flex`} ref={dateRef}>
        <DatePicker
          selected={selectedDate}
          onChange={onChangeDate}
          dateFormat="MMM d, yyyy"
          closeOnScroll={true}
          readOnly={readOnly}
          customInput={
            <CustomDatePicker
              faIcon={"fa-calendar-check"}
              error={
                showError && selectedDate == null
                  ? "please fill this field"
                  : null
              }
            />
          }
          wrapperClassName={`${styles.wrapperDatepicker}`}
        />
        <div className="mx-1"></div>
        <DatePicker
          selected={selectedTime}
          onChange={onChangeTime}
          showTimeSelect
          showTimeSelectOnly
          dateFormat="h:mm aa"
          closeOnScroll={true}
          readOnly={readOnly}
          customInput={
            <CustomDatePicker
              faIcon={"fa-clock"}
              error={
                showError && selectedTime == null
                  ? "please fill this field"
                  : null
              }
            />
          }
          wrapperClassName={styles.wrapperDatepicker}
        />
      </div>
      {/* {showError && (selectedDate == null || selectedTime === null) ? <span className="text-danger" style={{fontSize: "smaller"}}>* please fill the required date and time</span>: null} */}
    </>
  );
};

export const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    background: "fff",
    borderColor: "9e9e9e",
    minHeight: "24px",
    width: "100%",
    border: "1px solid 616D75",
    borderRadius: "4px",
  }),
  option: (provided: any, _: any) => ({
    ...provided,
    textAlign: "left",
    font: "normal normal 500 12px/18px Poppins",
    letterSpacing: "0px",
    color: "000000",
    background: "FFFFFF 0% 0% no-repeat padding-box",
    padding: "6px 12px",
    "&:hover": {
      background: "fff5f6 0% 0% no-repeat padding-box",
    },
  }),
  valueContainer: (provided: any, _: any) => ({
    ...provided,
    minHeight: "24px",
    padding: "0 6px",
  }),

  input: (provided: any, _: any) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorsContainer: (provided: any, _: any) => ({
    ...provided,
    minHeight: "24px",
    padding: "0px !important",
  }),
  dropdownIndicator: (provided: any, _: any) => ({
    ...provided,
    padding: "0px !important",
  }),
  clearIndicator: (provided: any, _: any) => ({
    ...provided,
    padding: "0px !important",
  }),
  multiValueLabel: (provided: any, _: any) => ({
    ...provided,
    padding: "0",
    textAlign: "left",
    font: "normal normal normal 12px/18px Poppins",
    letterSpacing: "0px",
    color: "707070",
    opacity: "1",
  }),
  multiValue: (provided: any, _: any) => ({
    ...provided,
    paddingRight: "2px",
  }),
};

export default FilterColDropdown;
