/**
 * This file is the react component file.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 * @author Navjyot
 */

import styles from "./ReportTicketGraph.module.scss";
import caution from "src/assets/images/caution.png";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ReportTicketList from "../ReportTicketList/ReportTicketList";
import FiltersApplied from "../FiltersApplied/FiltersApplied";
import NotificationContainer from "../NotificationContainer/NotificationContainer";
import SegmentFiltersApplied from "../SegmentFiltersApplied/SegmentFiltersApplied";
import ArticleDurationList from "src/routes/Report/Children/ArticleDurationList/ArticleDurationList";
import Graph, { GraphProps } from "./children/Graph/Graph";
import CreateNotifyBtn from "../CreateNotifyBtn/CreateNotifyBtn";
import useNotifyPermission from "../NotificationContainer/hooks/notifications/useNotifyPermission";
import PlanUpgrade from "src/components/PlanUpgrade/PlanUpgrade";
import { useAppSelector } from "src/store/store";
import ChatHandleWrapper from "../ReportTicketList/ChatHandleWrapper/ChatHandleWrapper";
import { useReportInternalFilters } from "../../hooks/reportFilters/useReportInternalFilters";

// ReportTicketGraph component definition
const ReportTicketGraph = ({
  activeTabName,
  setActiveTabname,
  endPoint,
  title,
  tooltip,
  tabProps,
  ...props
}: GraphProps) => {
  const notifyPermission = useNotifyPermission();

  // Fetching disabledFeatures from the Redux store
  const disabledFeatures = useAppSelector(
    (state) => state.globals.currentUserData?.disabledFeatures,
  );
  const { internalFilters } = useReportInternalFilters();

  // Render the component UI
  return (
    <div className={`${styles.ticketStatShow} position-relative py-3 px-4`}>
      {activeTabName !== "notify" ? (
        <div>
          <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center">
            <h3 className={`mb-2 ps-2 mb-md-0 ${styles.chartHead} me-2`}>
              {title}
            </h3>
            {tooltip ? (
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>{tooltip}</Tooltip>}
              >
                {({ ref, ...triggerHandler }) => (
                  <span
                    {...triggerHandler}
                    className="d-inline-flex align-items-center"
                  >
                    <img
                      src={caution}
                      alt=""
                      ref={ref}
                      className={`cursor-pointer ${styles.caution}`}
                    />
                  </span>
                )}
              </OverlayTrigger>
            ) : (
              <></>
            )}
          </div>

          <div className="d-flex flex-column flex-md-row align-items-start mb-1">
            <div
              className=""
              style={{
                width:
                  activeTabName === "listView" &&
                  tabProps.showNotifyTab &&
                  notifyPermission === "edit"
                    ? "80%"
                    : "",
              }}
            >
              <FiltersApplied endPoint={endPoint} />
              {tabProps.showComparison ? <SegmentFiltersApplied /> : <></>}
            </div>
            {/* Create notification */}
            {activeTabName === "listView" &&
              tabProps.showNotifyTab &&
              notifyPermission === "edit" && (
                <div className={`mt-3 ${styles.createBtnWrap}`}>
                  <CreateNotifyBtn
                    activeTabname={activeTabName}
                    setActiveTabname={setActiveTabname}
                  />
                </div>
              )}
          </div>

          {activeTabName === "chartView" || activeTabName === "heatmapView" ? (
            <>
              {disabledFeatures && disabledFeatures?.includes("reports") ? (
                <div style={{ minHeight: "400px" }}>
                  <div className={`${styles.upgradeBox}`}>
                    <PlanUpgrade
                      upgradeText="Upgrade to view"
                      subText="To see the insights, upgrade to paid plan"
                    />
                  </div>
                </div>
              ) : (
                // Highcharts graph component
                <Graph
                  activeTabName={activeTabName}
                  endPoint={endPoint}
                  title={title}
                  tooltip={tooltip}
                  tabProps={tabProps}
                  setActiveTabname={setActiveTabname}
                  {...props}
                />
              )}
            </>
          ) : tabProps.showListView ? (
            <>
              {disabledFeatures && disabledFeatures?.includes("reports") ? (
                <div style={{ minHeight: "400px" }}>
                  <div className={`${styles.upgradeBox}`}>
                    <PlanUpgrade
                      upgradeText="Upgrade to view"
                      subText="To see the insights, upgrade to paid plan"
                    />
                  </div>
                </div>
              ) : (
                // ReportTicketList or ArticleDurationList component based on endpoint
                <div>
                  {endPoint.startsWith("tickets") &&
                    !(
                      endPoint.includes("volumeActivity") ||
                      endPoint.includes("revenueGeneratedByTime") ||
                      endPoint.includes("ticketsFullResolutionOverTime") ||
                      endPoint.includes("ticketsFirstReplyOverTime")
                    ) && <ReportTicketList endPoint={endPoint} />}
                  {endPoint.includes("volumeActivity") && (
                    <ReportTicketList
                      endPoint={endPoint}
                      reportDateType={
                        tabProps.showDailyPart
                          ? internalFilters.reportDateType
                          : undefined
                      }
                    />
                  )}
                  {(endPoint.includes("ticketsFullResolutionOverTime") ||
                    endPoint.includes("ticketsFirstReplyOverTime")) && (
                    <ReportTicketList
                      endPoint={endPoint}
                      reportDateType={
                        tabProps.showDailyPart
                          ? internalFilters.reportDateType
                          : undefined
                      }
                    />
                  )}
                  {endPoint.includes("revenueGeneratedByTime") && (
                    <ChatHandleWrapper
                      endPoint={endPoint}
                      reportDateType={
                        tabProps.showDailyPart
                          ? internalFilters.reportDateType
                          : undefined
                      }
                    />
                  )}
                  {endPoint.startsWith("bot") &&
                    (endPoint.includes("percentageCernFoundUseful") ||
                    endPoint.includes("volumeOfChartsByCern") ||
                    endPoint.includes("chartsCernDidNotFindAnswersByTime") ? (
                      <ChatHandleWrapper
                        endPoint={endPoint}
                        reportDateType={
                          tabProps.showDailyPart
                            ? internalFilters.reportDateType
                            : undefined
                        }
                      />
                    ) : (
                      <ArticleDurationList endPoint={endPoint} />
                    ))}
                  {(endPoint.includes("chatSatisfactionReports") ||
                    endPoint.includes("chatAvailabilityReports")) && (
                    <ChatHandleWrapper
                      endPoint={endPoint}
                      reportDateType={
                        tabProps.showDailyPart
                          ? internalFilters.reportDateType
                          : undefined
                      }
                    />
                  )}
                  {/* Conditiong Based on the Enpoint */}
                  {!endPoint.includes("newArticlesAddedByTime") &&
                    !endPoint.includes("authorsMostArticles") &&
                    !endPoint.includes("highestRatedArticles") &&
                    !endPoint.includes("lowestRatedArticles") &&
                    endPoint.startsWith("knowledgeBase") && (
                      <ArticleDurationList endPoint={endPoint} />
                    )}

                  {/* Conditiong Based on the Enpoint */}
                  {(endPoint.includes("highestRatedArticles") ||
                    endPoint.includes("lowestRatedArticles")) &&
                    endPoint.startsWith("knowledgeBase") && (
                      <ArticleDurationList
                        endPoint={endPoint}
                        reportDateType={tabProps.reportDateType ?? undefined}
                      />
                    )}
                  {(endPoint.includes("totalChatReports") ||
                    endPoint.includes("missedChatsReports")) && (
                    <ReportTicketList
                      endPoint={endPoint}
                      reportDateType={
                        tabProps.showDailyPart
                          ? internalFilters.reportDateType
                          : undefined
                      }
                    />
                  )}
                </div>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      ) : tabProps.showNotifyTab && notifyPermission !== "none" ? (
        // NotificationContainer component for "notify" tab
        <>
          {disabledFeatures && disabledFeatures?.includes("reports") ? (
            <div style={{ minHeight: "400px" }}>
              <div className={`${styles.upgradeBox}`}>
                <PlanUpgrade
                  upgradeText="Upgrade to view"
                  subText="To see the insights, upgrade to paid plan"
                />
              </div>
            </div>
          ) : (
            <>
              {/* Componenet for edit notification  */}
              <NotificationContainer
                activeTabname={activeTabName}
                setActiveTabname={setActiveTabname}
              />
            </>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

// Export the ReportTicketGraph component
export default ReportTicketGraph;
