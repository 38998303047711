import { axiosJSON } from "src/globals/axiosEndPoints";

export interface CustomerDetails {
  customerId: number;
  customerName: string;
  customerEmail: string;
  imageUrl: string | null;
  isPublicAttachment: boolean;
}

export interface BugAttachmentDetails {
  attachmentId: number;
  attachmentBatchNumber: string;
  attachmentName: string;
  attachmentSize: number;
  attachmentType: string;
  attachmentUrl: string;
  isPublicAttachmentUrl: boolean;
}

interface BugResolveInfo {
  bugResolveByType: string;
  resolveDateGmt: string;
  agentName?: string;
  agentImageUrl?: string | null;
}

export interface BugData {
  bugId: number;
  ticketId: number[];
  brandId: number;
  bugDescription: string;
  bugCreatedAtGmt: string;
  bugStatusKey: string;
  priorityKey: string;
  bugReportedUrl: Array<string | null>;
  customerDetails: CustomerDetails[];
  bugResolveDetails?: BugResolveInfo;
  bugAttachmentDetails: BugAttachmentDetails[];
  categoryName?: string;
  companyName: Array<string | null>;
}

interface resBugData {
  bugsData: Array<BugData>;
  bugsTotalCount: number;
  categoryId: number;
  categoryName: string;
}

export interface resBugMeta {
  err: boolean;
  data: resBugData[];
  msg: string;
  metaData: {
    totalCount: number;
  };
}

const getBugByCategory = async (param: {
  start: number;
  limit: number;
  categoryId?: Array<number>;
}) => {
  const { data: res } = await axiosJSON.post(
    `/api/selfService/bugManagement/getCategoriesWithBugsData`,
    param,
  );

  if (res.err || res.error) {
    throw res.msg ?? res.message ?? "Something went wrong!";
  }

  return res as resBugMeta;
};

export default getBugByCategory;
