import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import getCernInstruction from "src/services/TicketService/InternalChatBot/getCernInstruction";
import { useAppSelector } from "src/store/store";

const useCernInstructionPagination = () => {
  const limit = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const [instructions, setInstructions] = useState<
    Array<{ name: string; type: number }> | undefined
  >([]);
  const brandId = useAppSelector(state => state.innerTicket.activeTicketInfo.ticket_brand.id)
  const { data } = useQuery(["cernThread/getCernInstruction", brandId], {
    queryFn: () => getCernInstruction(brandId),
    staleTime: 60000,
    cacheTime: 60000,
  });

  const cernInstruction = useMemo(() => {
    const output = data?.flatMap((category) =>
      category.instructionDetails.map((detail) => {
        return {
          name: detail.name,
          type: detail.type
        };
      }),
    );
    if (output?.length) {
      const newData = [...output];
      setInstructions(newData.splice(0, limit));
    }
    return output;
  }, [data?.length, currentPage]);

  const changePage = (page: number) => {
    setCurrentPage(page);
    if (cernInstruction?.length) {
      const newData = [...cernInstruction];
      setInstructions(newData.splice(limit * (page - 1), limit * page));
    }
  };

  useEffect(() => {
    if (cernInstruction?.length) {
      const newData = [...cernInstruction];
      setInstructions(
        newData.splice(limit * (currentPage - 1), limit * currentPage),
      );
    }
  }, [currentPage]);

  return {
    cernInstruction: instructions,
    totalPage: cernInstruction?.length
      ? Math.ceil(cernInstruction.length / limit)
      : 0,
    currentPage,
    changePage,
  };
};

export default useCernInstructionPagination;