import React, { useCallback, useMemo, useRef, useState } from "react";
import styles from "./StatusFilterSection.module.scss";
import { Dropdown } from "react-bootstrap";
import { useQuery } from "@tanstack/react-query";
import { getAllStatuses } from "src/services/TicketService/getAllStatuses";
import {
  reportCurrentStatusActions,
  useReportFilters,
} from "src/routes/Report/hooks/reportFilters/useReportFilters";
import { v4 as uuid } from "uuid";

const useStatusFilter = () => {
  const { data: allTicketStatus } = useQuery(
    ["useStatusFilter/getTicketStatusList"],
    {
      queryFn: getAllStatuses,
      staleTime: 600000,
      cacheTime: 600000,
    }
  );
  const allStatusId = useMemo(() => {
    return allTicketStatus?.allTicketStatusesIds.map((id) => id + "");
  }, [allTicketStatus]);

  const { currentStatus, dispatch } = useReportFilters();

  const selectedStatus = useMemo(() => {
    return currentStatus.selectedTicketStatus;
  }, [currentStatus]);

  const selectAllStatus = useCallback(
    (e: any) => {
      if (e.target.checked) {
        dispatch([
          reportCurrentStatusActions.setFilters,
          {
            selectedTicketStatus: [...(allStatusId ?? [])],
          },
        ]);
      } else {
        dispatch([
          reportCurrentStatusActions.setFilters,
          { selectedTicketStatus: [] },
        ]);
      }
    },
    [allStatusId]
  );
  const clearAllStatus = useCallback(() => {
    dispatch([
      reportCurrentStatusActions.setFilters,
      { selectedTicketStatus: [] },
    ]);
  }, []);
  const checkUncheckStatus = useCallback(
    (e: any) => {
      if (e.target.checked) {
        dispatch([
          reportCurrentStatusActions.setFilters,
          { selectedTicketStatus: [...selectedStatus, e.target.value] },
        ]);
      } else {
        dispatch([
          reportCurrentStatusActions.setFilters,
          {
            selectedTicketStatus: selectedStatus.filter(
              (id) => id !== e.target.value
            ),
          },
        ]);
      }
    },
    [selectedStatus]
  );
  return {
    allTicketStatus,
    allStatusId,
    selectedStatus,
    selectAllStatus,
    clearAllStatus,
    checkUncheckStatus,
  };
};
interface Props {
  isSegement?: boolean;
  disabled?: boolean;
}
const StatusFilterSection = ({ isSegement, disabled }: Props) => {
  const {
    allTicketStatus,
    allStatusId,
    selectedStatus,
    selectAllStatus,
    clearAllStatus,
    checkUncheckStatus,
  } = useStatusFilter();
  const [ddOpen, setDdOpen] = useState(false);
  const dropdownArrowSpanRef = useRef<any>();
  const uuidRef = useRef(uuid());

  return (
    <div>
      <Dropdown onToggle={setDdOpen}>
        <Dropdown.Toggle
          as="div"
          bsPrefix={`dropdown-toggle cursor-pointer border-0 px-2 py-1 me-2  mb-2 mb-lg-0 ${
            isSegement === true ? styles.segementBtn : styles.filterButton
          } ${disabled && styles.disable}`}
        >
          Ticket status
          {selectedStatus.length ? (
            <span className={`mx-1 ${styles.filterCount}`}>
              {/* added condition to show 0 before count if count is less than 10 */}
              {selectedStatus.length < 10
                ? `0${selectedStatus.length}`
                : selectedStatus.length}
            </span>
          ) : (
            ""
          )}
          <span className="ps-1" ref={dropdownArrowSpanRef}>
            {ddOpen ? (
              <i className={`fa-solid fa-caret-up`}></i>
            ) : (
              <i className={`fa-solid fa-caret-down`}></i>
            )}
          </span>
        </Dropdown.Toggle>

        <Dropdown.Menu bsPrefix={`dropdown-menu border-0 ${styles.filterMenu}`}>
          <span className={`${styles.dropItem}`}>
            <div
              className={`${styles.itemWrapper} ${
                selectedStatus.length ===
                  allTicketStatus?.allTicketStatusesIds.length &&
                styles.selectedText
              }`}
            >
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value="all"
                  id={uuidRef.current + "status-flexCheckDefault"}
                  checked={
                    selectedStatus.length ===
                    allTicketStatus?.allTicketStatusesIds.length
                  }
                  onChange={selectAllStatus}
                />
                <label
                  className={`form-check-label ${styles.checktext}`}
                  htmlFor={uuidRef.current + "status-flexCheckDefault"}
                >
                  Select all
                </label>
              </div>
            </div>
          </span>
          {allStatusId?.map((id) => {
            return (
              <span key={id} className={`${styles.dropItem}`}>
                <div
                  className={`${styles.itemWrapper} ${
                    selectedStatus.includes(id) && styles.selectedOne
                  }`}
                >
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={id}
                      id={uuidRef.current + "status-check" + id}
                      checked={selectedStatus.includes(id)}
                      onChange={checkUncheckStatus}
                    />
                    <label
                      className={`form-check-label ${styles.checktext}`}
                      htmlFor={uuidRef.current + "status-check" + id}
                    >
                      {allTicketStatus?.allTicketStatuses[id].statusName ?? ""}
                    </label>
                  </div>
                </div>
              </span>
            );
          })}
          <span className={`${styles.clearSpan}`} onClick={clearAllStatus}>
            Clear all
          </span>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default StatusFilterSection;
