import { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import SkeletonModalStructure from "src/components/AutomationComponents/SkeletonModalStructure/SkeletonModalStructure";
import DiscardModal from "src/components/AutomationComponents/DiscardModal/DiscardModal";
import { validateChanges } from "src/store/slices/initiateReturn/step14Configuration.slice";
import ReturnOrder from "./ReturnOrder/ReturnOrder";
import styles from "./AntiRefundStrategiesOrders.module.scss";
import { useAppDispatch, useAppSelector } from "src/store/store";

const AntiRefundStrategiesOrders = ({
  onHide,
  onSave = () => {},
}: {
  onHide: () => void;
  onSave?: () => void;
}) => {
  const dispatch = useAppDispatch();
  const isChanged = useAppSelector(
    (state) => state.antiRefundStrategiesOrders.isChanged,
  );
  const [isValidating, setIsValidating] = useState(false);
  const [showDiscardModel, setShowDiscardModal] = useState(false);

  useEffect(() => {
    if (isValidating) {
      if (isChanged) {
        setShowDiscardModal(true);
      } else {
        onHide();
      }
      setIsValidating(false);
    }
  }, [isChanged, isValidating, onHide]);

  const closeButtonFunctionality = useCallback(() => {
    dispatch(validateChanges());
    setIsValidating(true);
  }, [dispatch]);

  const handleClose = () => setShowDiscardModal(false);

  return (
    <div>
      <SkeletonModalStructure
        hasTwoSection={false}
        heading="Configure Anti-Return Strategies For Various Order Types"
        closeFunction={closeButtonFunctionality}
      >
        <ReturnOrder onSave={onSave} />
      </SkeletonModalStructure>
      <Modal
        className={`shadow-sm p-3 rounded`}
        show={showDiscardModel}
        onHide={handleClose}
        dialogClassName={`${styles.modalDialog2}`}
        contentClassName={`${styles.modalContent2}`}
        backdropClassName={`${styles.modalBackDrop}`}
        enforceFocus={false}
        keyboard={false}
      >
        <DiscardModal
          setShow={setShowDiscardModal}
          closeMainModel={onHide}
          onConfirm={() => {}}
        />
      </Modal>
    </div>
  );
};

export default AntiRefundStrategiesOrders;
