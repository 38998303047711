import { IChatSetting } from "./chatSetting.slice";

const fetchChatSettingDataFulfilled = (
  state: IChatSetting,
  { payload }: {payload: any}
) => {
  state = {
    ...state,
    chatSettingData: {
      chatAssignment: payload.chatSetting.chatAssignment,
      transcriptForwarding: payload.chatSetting.transcriptForwarding,
      fileSharingForVisitors: payload.chatSetting.fileSharingForVisitors,
      fileSharingForAgents: payload.chatSetting.fileSharingForAgents,
      inactivityTimeouts: payload.chatSetting.inactivityTimeouts
    },
    chatSettingDataAjaxStatus: "fulfilled",
  };
  // console.log(payload);
  return state;
};


export default { fetchChatSettingDataFulfilled };
