import { Channel } from "pusher-js";
import { Message } from "src/services/LiveChat/messageExchange/getMessages";

export type LiveMessageRecEventCB = (res: any) => void;

export const getLiveMessageRecEvent = (
  channel: Channel,
  callback: LiveMessageRecEventCB
) => {
  channel.bind("new_message", callback);
};

export const getLiveMessageRecEventUnbind = (
  channel: Channel,
) => {
  channel.unbind("new_message");
};