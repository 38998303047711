import { axiosJSON } from "src/globals/axiosEndPoints";
import { ConditionType, StepConfigParams } from "../Step9/step9GetConfig";
import { RefundReason } from "src/store/slices/initiateReturn/antiRefundConfig.slice";

export interface FormElementType {
  elementId: number;
  elementValue: string;
  elementTypeId: string;
  additionalOptions: string[];
}

export interface GiveOfferType {
  name: string;
  offerType: string;
  offerDescription: string;
  offerSelectedItems: string[];
  offerSelectedTags: string[];
}

export interface Step13TabType {
  configTabId: number;
  configTabName: string;
  conditions: ConditionType[];
  // formElements: FormElementType[];
  // giveOffer: GiveOfferType;

  refundRequests: {
    reasons: RefundReason[];
  };
}

export interface Step13Config {
  data: Step13TabType[];
}

export async function fetchGetStep13Config(params: StepConfigParams) {
  const { data } = await axiosJSON.get(
    "/api/setting/automation/refund/step/13/configuration",
    { params }
  );

  if (data.err === true) {
    throw new Error("Some Error Occurred while fetching data");
  }

  return data.data;
}
