import { createAsyncThunk } from "@reduxjs/toolkit";
import getAntiRefundStrategiesService from "src/services/ReturnAutoWorkFlow/OldModals/antiRefundStrategies/getAntiRefundStrategies.service";
import getRefundRequestFollowUpQuestionsService from "src/services/ReturnAutoWorkFlow/OldModals/antiRefundStrategies/getRefundRequestFollowUpQuestions.service";
import updateAntiRefundStrategiesService, {
  UpdateAntiRefundStrategiesPayload,
} from "src/services/ReturnAutoWorkFlow/OldModals/antiRefundStrategies/updateAntiRefundStrategies.service";
import updateRefundRequestSelectedItemsService from "src/services/ReturnAutoWorkFlow/OldModals/antiRefundStrategies/updateRefundRequestSelectedItems.service";
import { RootState } from "src/store/store";

interface AntiRefundStrategiesPayload {
  integrationId: string;
}

interface UpdatePayload {
  reasonId: string;
  scenarioId: string | null;
  integrationId: string;
}

export const fetchAntiRefundStrategies = createAsyncThunk(
  "fetchAntiRefundStrategies",
  async (payload: AntiRefundStrategiesPayload) => {
    const integrationId = payload.integrationId;
    const res = getAntiRefundStrategiesService({
      integrationId,
    });

    return res;
  },
);

export const fetchRefundRequestFollowUpQuestionsThunk = createAsyncThunk(
  "fetchRefundRequestFollowUpQuestionsThunk",
  async (payload: { integrationId: string; id: string }) => {
    const res = getRefundRequestFollowUpQuestionsService({
      integrationId: payload.integrationId,
      id: payload.id,
    });

    return res;
  },
);

// NOT BEING USED AT THE MOMENT
export const updateRefundRequestSelectedItem = createAsyncThunk(
  "updateRefundRequestSelectedItem",
  async (payload: UpdatePayload, { getState }) => {
    const currentState = getState() as RootState;
    const integrationId = payload.integrationId;
    const reasonIndex =
      currentState.antiRefundStrategies.refundRequests.reasons.findIndex(
        (reason) => reason.id === payload.reasonId,
      );
    const scenarioIndex =
      currentState.antiRefundStrategies.refundRequests.reasons[
        reasonIndex
      ].scenarios.findIndex((scenario) => scenario.id === payload.scenarioId);
    const selectedScenario =
      currentState.antiRefundStrategies.refundRequests.reasons[reasonIndex]
        .scenarios[scenarioIndex];

    await updateRefundRequestSelectedItemsService({
      id: payload.reasonId,
      integrationId,
      scenarioId: payload.scenarioId,
      itemIds: selectedScenario.itemsAndTagAllSelected
        ? ["*"]
        : Array.isArray(selectedScenario.items)
          ? selectedScenario.items.map((item) => item.id as number)
          : [],
      tags: selectedScenario.itemsAndTagAllSelected
        ? ["*"]
        : Array.isArray(selectedScenario.tags)
          ? selectedScenario.tags.map((tag) => tag)
          : [],
      enabled: selectedScenario.enabled,
      additionalInformation: selectedScenario.additionalInformation,
      giveOffer: selectedScenario.giveOffer?.enabled
        ? ({
            ...selectedScenario.giveOffer,
            name: "",
          } as any)
        : undefined,
      offerExchange: selectedScenario.offerExchange,
      followUpQuestions: selectedScenario.followUpQuestions.map((question) => {
        if (
          question.selectedValue.length === question.additionalOptions.length
        ) {
          return {
            ...question,
            selectedValue: ["*"],
            // selectedId: ["*"],
          };
        } else {
          return question;
        }
      }),
    });
  },
);

export const updateAntiRefundStrategies = createAsyncThunk(
  "updateAntiRefundStrategies",
  async (payload: { integrationId: string }, { getState }) => {
    const currentState = getState() as RootState;
    const integrationId = payload.integrationId;

    const param: UpdateAntiRefundStrategiesPayload = {
      integrationId,
      refundRequests:
        currentState.antiRefundStrategies.refundRequests.reasons.map(
          (reason) => {
            return {
              id: reason.id,
              scenarios: reason.scenarios.map((scenario) => {
                return {
                  // If Scenario is new then don't send scenarioId
                  scenarioId: scenario.isNewScenario ? undefined : scenario.id,
                  // Need to filter it because somehow the non selected tags are stored as null.
                  tags:
                    scenario.itemsAndTagAllSelected === true
                      ? ["*"]
                      : (scenario.tags as string[]).filter(
                          (v) => v !== null && v !== undefined,
                        ),
                  itemIds:
                    scenario.itemsAndTagAllSelected === true
                      ? ["*"]
                      : scenario.items
                          .filter((item) => item.id !== undefined)
                          .map((item) => item.id?.toString() || ""),
                  enabled: (scenario.enabled as boolean) ?? false,
                  additionalInformation: {
                    enabled: scenario.additionalInformation?.enabled ?? false,
                    message: scenario.additionalInformation?.message ?? "",
                    extendReturnPeriod: {
                      enabled:
                        scenario.additionalInformation?.extendReturnPeriod
                          ?.enabled ?? false,
                      days:
                        scenario.additionalInformation?.extendReturnPeriod
                          ?.days ?? 0,
                    },
                  },
                  giveOffer: {
                    enabled: scenario.giveOffer?.enabled ?? false,
                    message: scenario.giveOffer?.message ?? "",
                    offerSelectedItems:
                      (scenario.giveOffer?.offerSelectedItems as number[]) ??
                      [],
                    offerSelectedTags: scenario.giveOffer
                      ?.offerSelectedTags as string[],
                    offerType:
                      (scenario.giveOffer?.offerType as string) ?? "other",
                    offerValue: (scenario.giveOffer?.offerValue as number) ?? 0,
                    offerUnit: scenario.giveOffer?.offerUnit ?? "None",
                    name: scenario.giveOffer?.name ?? "",
                  },
                  offerExchange: {
                    enabled: scenario.offerExchange?.enabled ?? false,
                    message: scenario.offerExchange?.message ?? "",
                    exchangeWithOtherItems:
                      scenario.offerExchange?.exchangeWithOtherItems ?? false,
                    exchangeWithSameItems:
                      scenario.offerExchange?.exchangeWithSameItems ?? false,
                  },
                  followUpQuestions:
                    scenario.followUpQuestions.length > 0
                      ? scenario.followUpQuestions.map((question) => {
                          if (
                            question.selectedValue.length ===
                            question.additionalOptions.length
                          ) {
                            return {
                              elementId: question.elementId,
                              value: ["*"],
                              // selectedId: ["*"],
                            };
                          } else {
                            return {
                              elementId: question.elementId,
                              value: question.selectedValue,
                            };
                          }
                        })
                      : [],
                };
              }),
            };
          },
        ),
    };

    await updateAntiRefundStrategiesService(param);

    // Refetch updated data
    const getRefundRequestList = await getAntiRefundStrategiesService({
      integrationId,
    });

    return getRefundRequestList;
  },
);
