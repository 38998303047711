import { useAppSelector } from "src/store/store";
import styles from "./CurrentHost.module.scss";
import { useState } from "react";
import copy from "src/assets/images/copyMail.png";

const CurrentHost = () => {
  const [copySEmail, setCopied] = useState(false);

  const supportEmail = useAppSelector((state) => {
    return state.emailIntegration.selectedUser.outgoingMailSettings.outgoingEmailAddress ?? "";
  });
  
  const copyToClipBoard = async (copyMe: string) => {
    try {
      await navigator.clipboard.writeText(copyMe);
    } catch (err) {}
  };
  return (
    <>
      <div className={`ms-2 mt-5 ${styles.container}`}>
        {/* Heading */}
        <div className={`${styles.topContainer}`}>
          <p>Outgoing Email details</p>
        </div>
        <div className={styles.bottomContainer}>
          <form>
            <div className="mb-1">
              <label htmlFor="exampleInputEmail1" className="form-label">
                <div className={`${styles.head} ps-3`}>
                  Outgoing Email address
                </div>
                <div className={`${styles.adreess} ps-3`}>
                  Emails will be sent out via this address below
                </div>
              </label>
              <div className={`ps-3 me-3 position-relative`}>
                <input
                  type="email"
                  className={`form-control ${styles.mailInput} ${styles.disabled}`}
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="support@emails.helplama.com"
                  value={supportEmail}
                />
                <div
                  className={`${styles.copyMain}`}
                  onClick={(e) => {
                    e.preventDefault();
                    copyToClipBoard(supportEmail);
                    setCopied(true);
                    setTimeout(() => {
                      setCopied(false);
                    }, 5000);
                  }}
                >
                  {copySEmail ? (
                    <button className={`py-1 ${styles.copiedBtn}`}>
                      <img src={copy} alt="" /> Copied !
                    </button>
                  ) : (
                    <button className={`py-1 ${styles.copyBtn}`}>
                      <img src={copy} alt="" /> Copy Email
                    </button>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CurrentHost;
