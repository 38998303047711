import { axiosJSON } from "src/globals/axiosEndPoints";
import { validateEmail } from "src/utils/utils";

interface IForgotPassword {
  email: string;
}

const statusCodeAndMessage:any = {
  "EMAIL_REQUIRED": "Email is required!",
  "EMAIL_NOT_VALID": "Email is not valid!",
  "USER_NOT_FOUND": "Email does not exists!",
  "USER_NOT_ACTIVE": "User is not active!",
  "EMAIL_FAILED": "Failed To Send Email!",
  "EMAIL_SENT": "Email Sent Successfully!",
}

export async function forgotPassword(servicePayload: IForgotPassword) {
  if (!validateEmail(servicePayload.email)) {
    throw new Error("Email not valid!");
  }
  const { data } = await axiosJSON.post(`/api/forgotPassword`, servicePayload);
  if (data.status == false) {
    if(data.statusCode && statusCodeAndMessage[data.statusCode.trim().toUpperCase()] != undefined){
      throw new Error(statusCodeAndMessage[data.statusCode.trim().toUpperCase()]);
    }else{
      
      throw new Error("Something went wrong!")
    }
  }
  // contains status, error, message
  return data;
}
