import ListOtherDraft from "../ListOtherDraft";
import styles from "./DraftHeader.module.scss";

export type AddDH = "Add Draft" | "Add Remark" | "Draft" | "Remarks Thread";

interface Props {
  add: AddDH;
  onHide?: (e: any) => void;
  className?: string;
}

function DraftHeader({ add, onHide, className = "" }: Props) {
  return (
    <>
      <div className={`d-flex justify-content-between ${className}`}>
        <div>
          <span className={`${styles.Draft}`}>{add}</span>
          {add.toLowerCase().includes("draft") ? <ListOtherDraft /> : "💬"}
        </div>
        <div>
          {onHide !== undefined ? (
            <span className={`${styles.close}`} onClick={onHide}>
              <i className="fa-solid fa-xmark"></i>
            </span>
          ) : (
            <></>
          )}
        </div>
      </div>
      {add === "Add Remark" && (
        <div className={`${styles.sub} py-1`}>
          Make sure you summarize your case and explain your exact confusion
          with the matter
        </div>
      )}
    </>
  );
}

export default DraftHeader;
