import { useCallback, useEffect, useRef, useState } from "react";
import { Overlay, Spinner } from "react-bootstrap";
import UserAvatar from "src/components/UserAvatar";
import { AJAXSTATUS } from "src/globals/constants";
import { BrandAgent } from "src/services/Settings/Brands/getBrand";
import {
  getBrandAgents,
  getBrandSelectedUserTicketCountsSelector,
  resetBrandAgentList,
} from "src/store/slices/settings/brands/brandSettings.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { ReassignToDropDown } from "../ReassignToDropDown";
import { SelectedAgentDropDown } from "../SelectedAgentDropDown";
import styles from "./DisableAgentModal.module.scss";
import AxiosImg from "src/components/AxiosImg";
interface Props {
  handleCancel: Function;
  handleSubmit: (selectedAgent: BrandAgent) => void;
  deleteAjaxStatus: AJAXSTATUS;
  brandId: number | string;
  brandUser?: BrandAgent;
  isSingleUser?: boolean;
}

const DisableAgentModal = ({
  brandUser,
  isSingleUser,
  brandId,
  handleCancel,
  handleSubmit,
  deleteAjaxStatus,
}: Props) => {
  const defaultSelected: BrandAgent = {
    id: 0,
    name: "Unassigned",
    roleId: 0,
    roleName: "",
    email: "",
    enabled: true,
    profileImageUrl: null,
  };

  const {
    brandAgentIds,
    brandAgents,
    getBrandAgentAjaxStatus,
    brandAgentMeta,
  } = useAppSelector((state) => state.brandSettings);
  const brandSelectedUserTicketCounts = useAppSelector(
    getBrandSelectedUserTicketCountsSelector
  );

  const [selectedAgent, setSelectedAgent] = useState(defaultSelected);
  const [showAgentModal, setShowAgentModal] = useState(false);
  const dispatch = useAppDispatch();

  const [showOverlay, setShowOverlay] = useState(false);

  const target = useRef(null);

  useEffect(() => {
    if (brandId) {
      dispatch(resetBrandAgentList());
      dispatch(getBrandAgents({ brandId: brandId }));
    }
  }, [brandId]);

  const currentState = useRef({
    getBrandAgentAjaxStatus: getBrandAgentAjaxStatus,
  });

  useEffect(() => {
    currentState.current = {
      getBrandAgentAjaxStatus: getBrandAgentAjaxStatus,
    };
  }, [getBrandAgentAjaxStatus]);

  /* Handle infinite scroll */
  const handleInfiniteScroll = useCallback(() => {
    if (currentState.current.getBrandAgentAjaxStatus === "fulfilled") {
      dispatch(getBrandAgents({ brandId: brandId }));
    }
  }, [brandId]);

  function getUsersList() {
    let defaultUser: any = {};
    defaultUser[defaultSelected.id] = defaultSelected;

    return { ...defaultUser, ...brandAgents };
  }

  function getUserIds() {
    return [defaultSelected.id, ...brandAgentIds];
  }
  return (
    <>
      <div className={`container`}>
        <div className={`row`}>
          <div className={`col-12`}>
            <div className={`px-4 pt-4 pb-3 text-center`}>
              <h3 className={`${styles.modalHead}`}>
                {isSingleUser === true && brandUser
                  ? "This agent have already assigned tickets with this brand"
                  : "These agents have already assigned tickets with this brand"}
              </h3>
              <span className={`d-inline-block py-2 ${styles.modalSub}`}>
                If you disabled these agent(s), the current ticets will be
                unassigned.
              </span>
              <div
                className={`${styles.ticketCountSpan} mb-2 d-flex justify-content-center`}
              >
                {isSingleUser === true && brandUser ? (
                  <>
                    {`(${brandUser.name ?? "This user"} has ${
                      brandUser.ticketCount ?? 0
                    } ${
                      (parseInt(brandUser.ticketCount+"") ?? 0) > 1 ? "tickets" : "ticket"
                    } with this brand)`}
                  </>
                ) : (
                  <>
                    {`(Among the selected agents, ${brandSelectedUserTicketCounts} tickets are from this brand)`}

                    <div
                      onMouseOver={() => setShowOverlay(true)}
                      onMouseLeave={() => setShowOverlay(false)}
                    >
                      <i ref={target} className="material-icons mx-1 my-auto">
                        &#xe88f;
                      </i>
                      <Overlay
                        target={target}
                        show={showOverlay}
                        placement="bottom"
                      >
                        {({
                          placement,
                          arrowProps,
                          show: _show,
                          popper,
                          ...props
                        }) => (
                          <div
                            {...props}
                            style={{
                              ...props.style,
                            }}
                            className={`${styles.overlay}`}
                          >
                            <SelectedAgentDropDown />
                          </div>
                        )}
                      </Overlay>
                    </div>
                  </>
                )}
              </div>
              <div className={`d-flex justify-content-center`}>
                <div>
                  <span className={`d-inline-block mb-2 ${styles.modalSub}`}>
                    Reassign to :
                  </span>
                  <button
                    className={`btn ${styles.ProfileMain} d-flex`}
                    type="button"
                    onClick={(e) => {
                      setShowAgentModal(!showAgentModal);
                    }}
                  >
                    {selectedAgent?.profileImageUrl &&
                    selectedAgent?.profileImageUrl.trim().length !== 0 ? (
                      <AxiosImg
                        className={`${styles.profileIcon}`}
                        url={selectedAgent.profileImageUrl}
                        isDirectURL={selectedAgent.isPublicAttachmentUrl}
                      />
                    ) : (
                      <UserAvatar
                        name={selectedAgent.name}
                        size={20}
                        className={`m-auto`}
                      />
                    )}
                    <span className={`my-auto mx-2`}>{selectedAgent.name}</span>
                    <i
                      className={`fa-solid d-block m-auto fa-caret-${
                        showAgentModal ? "up" : "down"
                      }`}
                    ></i>
                  </button>
                  <ReassignToDropDown
                    defaultUserIds={
                      brandUser && isSingleUser === true ? [brandUser.id] : []
                    }
                    metaData={brandAgentMeta}
                    show={showAgentModal}
                    ajaxStatus={getBrandAgentAjaxStatus}
                    users={getUsersList()}
                    userIds={getUserIds()}
                    selectedUser={selectedAgent}
                    handleInfiniteScroll={handleInfiniteScroll}
                    onSelect={(selectedUser) => {
                      setShowAgentModal(false);
                      setSelectedAgent(selectedUser);
                    }}
                  />
                </div>
              </div>

              <div
                className={` pt-4 pb-3 d-flex justify-content-center align-items-center`}
              >
                <button
                  className={`bg-transparent px-4 py-2 me-3 me-md-4 ${styles.cancelBtn}`}
                  onClick={() => handleCancel()}
                >
                  Cancel
                </button>
                <button
                  className={`border-0 px-4 py-2 ${styles.confirmBtn}`}
                  onClick={() => handleSubmit(selectedAgent)}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DisableAgentModal;
