import { IMessageData } from "src/store/slices/innerTicket/innerTicket.slice";
import styles from "./AnswerSourceModal.module.scss";
import closeModalBtn from "src/assets/images/closeModal.png";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import ParseHTML from "../ParseHTML";

interface Props {
  onHide: () => void;
  messageData: IMessageData;
}

const AnswerSourceModal = ({ onHide, messageData }: Props) => {
  const navigate = useNavigate();

  const openBotProfile = useCallback(() => {
    if (messageData.answerSource?.botProfileId) {
      navigate(
        `/bot/botProfiles/${messageData.answerSource.botProfileId}/answerSource/customQa`,
      );
    }
  }, [messageData.answerSource?.botProfileId, navigate]);

  return (
    <>
      <div className={`${styles.modalWrapper}`}>
        <div className="d-flex justify-content-between align-items-start">
          <div>
            <p className="mb-0 saufter_h3">Answer Source: Custom QA</p>
            <span className="d-block saufter_subHeading">
              One of the admins created a custom QA for AI cern to use in
              answering customer queries.
            </span>
          </div>
          <span
            id="closeBtn"
            onClick={() => onHide()}
            style={{ cursor: "pointer" }}
            aria-label="Close"
          >
            <img
              src={closeModalBtn}
              alt="close"
              width={7}
              height={7}
            />
          </span>
        </div>
        <div className="mt-3 pt-2">
          <h3 className={`saufter_h4 ${styles.answerHead}`}>
            Answer
            {messageData.answerSource?.botProfileId && (
              <span
                className={`ps-2 ${styles.link}`}
                onClick={openBotProfile}
              >
                Edit this answer for AI cern training
              </span>
            )}
          </h3>
          <p className={`pb-1 ${styles.lead}`}>
            <ParseHTML html={messageData.message} />
          </p>
        </div>
        <div>
          <h3 className={`saufter_h4 mb-3 ${styles.answerHead}`}>
            Questions
            {messageData.answerSource?.botProfileId && (
              <span
                className={`ps-2 ${styles.link}`}
                onClick={openBotProfile}
              >
                Edit questions for AI cern training?
              </span>
            )}
          </h3>
          <div>
            {messageData.answerSource?.questions &&
              messageData.answerSource.questions.map((question, idx) => (
                <span
                  className={`${styles.applyBtn} d-inline-block text-truncate`}
                  key={`${question}_btn_${idx}`}
                  id={`${question}_btn_${idx}`}
                >
                  {question}
                </span>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default AnswerSourceModal;
