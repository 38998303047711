import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";
import { Articles } from "./getSourceArticles.service";

export interface GetSourceArticlesResponse {
  data: Articles[];
  metaData: {
    count: number;
    total: number;
  };
}

export interface SearchSourceArticlesParams {
  searchTerm: string;
  start?: number;
  limit?: number;
  botProfileId: number | string;
}

const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/botProfile/external/getAll`
  : "/api/botProfile/external/searchAllSourceArticles";

export async function searchAllSourceArticles(
  params: SearchSourceArticlesParams
) {
  const { data: res } = await axiosJSON.post(apiEndPoint, params);

  if (res.err) {
    throw new Error(res.msg as string);
  }

  return res as GetSourceArticlesResponse;
}
