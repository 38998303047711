import { axiosJSON } from "src/globals/axiosEndPoints";
import { ConditionType, StepConfigParams } from "../Step9/step9GetConfig";

interface ReturnTypes {
  name: string;
  key: string;
  enabled: boolean;
}

export interface Step11TabType {
  configTabId: number;
  configTabName: string;
  conditions: ConditionType[];
  notEligibleResponseMessage: string;
  returnTypes: ReturnTypes;
}

export interface Step11Config {
  data: Step11TabType[];
}

export async function fetchGetStep11Config(params: StepConfigParams) {
  const { data } = await axiosJSON.get(
    "/api/setting/automation/refund/step/11/configuration",
    { params }
  );

  if (data.err === true) {
    throw new Error("Some Error Occurred while fetching data");
  }

  return data.data;
}
