import styles from "./TicketViewTableRow.module.scss";
import styles2 from "src/routes/TicketList/children/TicketView/children/TicketViewTableHeader/TicketViewTableHeader.module.css";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Column,
  allColumnComponents,
} from "src/hooks/useTicketViews/ticketViewsConfig";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  removeTicketById,
  selectTicketById,
  totalTicketsSelectedSeletector,
  isTicketIdSelectedSelector,
  selectAllTickets,
  setBulkActionFilters,
} from "src/store/slices/tickets/tickets.slice";
import { Fragment, useMemo } from "react";
import { Spinner } from "react-bootstrap";
import BlurredUI from "./BlurredUI/BlurredUI";
import TicketLimiter from "../TicketLimiter/TicketLimiter";

function BulkTick({ ticketId }: any) {
  const dispatch = useAppDispatch();
  const scopes = useAppSelector(
    (state) => state.globals.currentUserData?.scopes,
  );
  const selectedAllTickets = useAppSelector(
    (state) => state.ticket.bulkActionFilters.selectedAllTickets,
  );
  const isTicketSelected = useAppSelector((state) =>
    isTicketIdSelectedSelector(state, ticketId),
  );
  const handleOnChangeCheckbox = (e: any) => {
    if (selectedAllTickets) {
      dispatch(setBulkActionFilters({ selectedAllTickets: false }));
      dispatch(selectAllTickets());
    }
    if (isTicketSelected) {
      dispatch(removeTicketById(ticketId));
    } else {
      dispatch(selectTicketById(ticketId));
    }
  };
  return (
    <div className={styles.table_row}>
      {scopes && scopes.includes("bulk_edit") && (
        <input
          className={`form-check-input ignoreClick ${styles.checkbox_row}`}
          data-lpignore="true"
          type="checkbox"
          checked={isTicketSelected}
          onChange={handleOnChangeCheckbox}
        />
      )}
    </div>
  );
}

function TicketViewTableRow({
  showBlurredSection,
}: {
  showBlurredSection: boolean;
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const { viewType } = useParams();
  const allTicketsIds = useAppSelector((state) => state.ticket.allTicketsIds);
  const { ticketsPerPageLimit } = useAppSelector((state) => state.ticket);
  const currColumnList = useAppSelector((state) => state.ticket.currColumnList);
  const totalTicketsSelected = useAppSelector(totalTicketsSelectedSeletector);
  const moreColAjaxStatus = useAppSelector(
    (state) => state.ticket.moreColAjaxStatus,
  );

  /**
   * Blurred rows array to get the amount of rows to render
   */
  const blurredRows = useMemo(() => {
    // Calculate number of items to add
    const itemsToAdd = ticketsPerPageLimit - allTicketsIds.length;

    // Don't show blurred section if not correct view or
    // If items to add is greater than 50% of tickets on the page i.e.,
    // (there are 6 or more tickets on page out of 10)
    if (!showBlurredSection || itemsToAdd >= ticketsPerPageLimit * 0.5) {
      return [];
    }

    // Create an array of items
    const blurredArray = [];

    for (let i = 0; i < itemsToAdd; i++) {
      blurredArray.push(1);
    }

    // Return the array
    return blurredArray;
  }, [showBlurredSection, allTicketsIds.length, ticketsPerPageLimit]);

  const handleOnClickTicket = (e: any, ticketId: any) => {
    e.preventDefault();
    let ele = e.target;
    let clickable = true;
    if (e.ctrlKey) {
      window.open(
        `${location.pathname}/ticket/${ticketId}/`.replace("//", "/") +
          window.location.search,
      );
      return;
    }
    try {
      while (true) {
        if (ele === e.currentTarget) {
          break;
        } else if (ele.classList.contains("ignoreClick")) {
          clickable = false;
          break;
        } else {
          ele = ele.parentElement;
        }
      }
    } catch {
      clickable = false;
    }
    if (clickable && totalTicketsSelected === 0) {
      navigate(
        `${location.pathname}/ticket/${ticketId}/`.replace("//", "/") +
          window.location.search,
      );
    }
  };

  const getCellComp = (col: Column, ticketId: any) => {
    let CellComp: any = allColumnComponents[col.id]?.cellComponent;
    return (
      <div
        key={col.id}
        className={`${styles.cellRoot}`}
      >
        {moreColAjaxStatus[col.id] === "pending" ? (
          <div
            className={`${
              styles2[
                col.name.toLowerCase().replace(" ", "_").replace(" ", "_")
              ]
            }`}
            style={
              styles2[
                col.name.toLowerCase().replace(" ", "_").replace(" ", "_")
              ]
                ? { margin: "0 10px" }
                : { minWidth: "150px", margin: "0 10px" }
            }
          >
            <Spinner
              animation="border"
              variant="primary"
              size="sm"
              className="m-auto ms-5"
            />
          </div>
        ) : (
          <CellComp
            ticketId={ticketId}
            colKeys={col.keys}
            colId={col.id}
          />
        )}
      </div>
    );
  };

  const getRowComp = (ticketId: any) => {
    if (viewType === undefined) return <></>;
    return currColumnList[viewType].map((col) => {
      if (col.show === true) {
        return getCellComp(col, ticketId);
      } else {
        return (
          <div
            key={col.id}
            style={{ display: "none" }}
          />
        );
      }
    });
  };

  const getTableDataComp = allTicketsIds.map((ticketId: any) => {
    return (
      <Fragment key={ticketId}>
        <BulkTick ticketId={ticketId} />
        <Link
          to={`${location.pathname}/ticket/${ticketId}/${window.location.search}`.replace(
            "//",
            "/",
          )}
          className={`d-block`}
          onClick={(e) => e.preventDefault()}
        >
          <div
            className={`d-flex ${styles["table_body"]} py-2`}
            onClick={(e: any) => handleOnClickTicket(e, ticketId)}
          >
            <div className={`${styles.hoverBar}  ${styles[viewType + ""]} `} />
            {getRowComp(ticketId)}
          </div>
        </Link>
      </Fragment>
    );
  });

  return (
    <>
      {getTableDataComp}
      {viewType && blurredRows.length > 0 && (
        <div
          className={`position-relative mt-2 ${
            blurredRows.length === 1 ? "mb-4" : ""
          }`}
        >
          {/* Blurred Ticket Rows */}
          {blurredRows.map((key) => (
            <BlurredUI
              key={key}
              columns={currColumnList[viewType]}
            />
          ))}

          {/* Limiter UI to apply the blur effect */}
          <TicketLimiter isMoreData={false} />
        </div>
      )}
    </>
  );
}

export default TicketViewTableRow;
