import { useCallback, useMemo } from "react";
import { Dropdown } from "react-bootstrap";
import CustomizeSelect from "src/components/AutomationComponents/IndividualComponents/CustomizeSelect/CustomizeSelect";
import ReturnProductsSelect from "src/components/AutomationComponents/IndividualComponents/ReturnProductsSelect/ReturnProductsSelect";
import SelectReturnReason from "src/components/AutomationComponents/IndividualComponents/SelectReturnReason/SelectReturnReason";
import Counter from "src/components/AutomationComponents/Other/Components/Counter";
import CountryDropdown from "src/components/AutomationComponents/Other/Components/CountryDrodown/CountryDropdown";
import DiscountCodes from "src/components/AutomationComponents/Other/Components/DiscountCodes";
import DiscountCodesNew from "src/components/AutomationComponents/Other/Components/DiscountCodesNew";
import NumberInput from "src/components/AutomationComponents/Other/Components/NumberInput";
import NumberInputRange from "src/components/AutomationComponents/Other/Components/NumberInputRange";
import Tags from "src/components/AutomationComponents/Other/Components/Tags";
import TagsNew from "src/components/AutomationComponents/Other/Components/TagsNew";
import { ConditionTypes } from "src/store/slices/initiateReturn/Helpers/returnExchange.helpers";
import { fetchGetAllShopifyProducts } from "src/store/slices/initiateReturn/returnExchange.thunk";
import { ReturnReason } from "src/store/slices/initiateReturn/step14Configuration.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import styles from "./Values.module.scss";

export interface SelectOptionType {
  id: number;
  value: string;
  label: string;
  price: {
    //Changing format as per backend
    amount: string;
    currencyCode: string;
  };
  imgSrc: string;
}

const ItemsShopify = ({ props, value }: { props: any; value: any }) => {
  const dispatch = useAppDispatch();

  const allShopifyProducts = useAppSelector(
    (state) => state.returnExchange.getAllShopifyProducts,
  );

  const allOptions = useMemo(() => {
    return allShopifyProducts.map((product) => {
      return {
        id: product.productId,
        imgSrc: product.imageUrl,
        label: product.productName,
        price: product.productPrice,
        value: product.productName,
      } as SelectOptionType;
    });
  }, [allShopifyProducts]);

  const initialOptions = useMemo(
    () =>
      props.condition.values?.items?.currentValue?.map((id: any) =>
        Number(id),
      ) ?? [],
    [props.condition.values?.items?.currentValue],
  );

  const handleLoadOptions = useCallback(
    async (searchTerm: string, loadOptions: any, additional: any) => {
      const limit = 25;
      searchTerm = searchTerm.trim();
      let data: any = { payload: { data: [], meta: { total: 0, count: 0 } } };
      data = await dispatch(
        fetchGetAllShopifyProducts({
          limit: limit,
          searchTerm,
          start: additional?.page ?? 0,
        }),
      );

      if (data.requestStatus === "rejected" || data.error) {
        return {
          options: [],
          hasMore: false,
          additional: {
            page: 0,
          },
        };
      }

      const options = data.payload?.data?.map((product: any) => {
        return {
          id: product.productId,
          imgSrc: product.imageUrl,
          label: product.productName,
          price: product.productPrice,
          value: product.productName,
        } as SelectOptionType;
      });

      const meta = data.payload?.meta;

      return {
        options: options,
        hasMore:
          meta?.total > parseInt(additional?.page ?? 0 + "") + options.length,
        additional: {
          page: additional ? additional.page + meta.count : meta.count,
        },
      };
    },
    [dispatch],
  );

  return (
    <CustomizeSelect
      id={props.id}
      error={value.error}
      condition={props.condition}
      updateCondition={(value: any) => {
        props.updateCondition(
          props.id,
          "items",
          value.map((id: number) => id + ""),
          props.condition,
        );
      }}
      allOptions={allOptions}
      intialSelectedOptions={initialOptions}
      loadOptions={handleLoadOptions}
    />
  );
};

const Items = ({ props, value }: { props: any; value: any }) => {
  const initialOptions = useMemo(
    () =>
      props.condition.values?.items?.currentValue?.map((id: any) =>
        Number(id),
      ) ?? [],
    [props.condition.values?.items?.currentValue],
  );

  return (
    <ReturnProductsSelect
      error={value.error}
      updateCondition={(value) => {
        props.updateCondition(
          props.id,
          "items",
          value.map((id) => id + ""),
          props.condition,
        );
      }}
      initialOptions={initialOptions}
    />
  );
};

function Values(props: {
  id: string;
  condition: ConditionTypes | ReturnReason;
  updateCondition: Function;
  pushCurrentHash: Function;
  useNewComponent?: boolean;
}) {
  return (
    <>
      {Object.entries<any>(props.condition.values || {}).map(([key, value]) => {
        switch (key) {
          case "days": {
            return (
              <Counter
                count={value.currentValue}
                type={"days"}
                error={value.error}
                onChange={(value: string | number) => {
                  props.updateCondition(
                    props.id,
                    "days",
                    value,
                    props.condition,
                  );

                  props.pushCurrentHash(props.id);
                }}
              />
            );
          }

          case "tags": {
            if (props.useNewComponent) {
              return (
                <TagsNew
                  tags={value.currentValue}
                  error={value.error}
                  isItemTag={
                    props.condition.variableName === "Item" ||
                    props.condition.variableName === "Tags"
                  }
                  onChange={(value: string[] | number[] | string) => {
                    props.updateCondition(
                      props.id,
                      "tags",
                      value,
                      props.condition,
                    );
                    props.pushCurrentHash(props.id);
                  }}
                />
              );
            }
            return (
              <Tags
                tags={value.currentValue}
                error={value.error}
                isItemTag={
                  props.condition.variableName === "Item" ||
                  props.condition.variableName === "Tags"
                }
                onChange={(value: string[] | number[] | string) => {
                  props.updateCondition(
                    props.id,
                    "tags",
                    value,
                    props.condition,
                  );
                  props.pushCurrentHash(props.id);
                }}
              />
            );
          }
          case "codes": {
            if (props.useNewComponent) {
              return (
                <DiscountCodesNew
                  codes={value.currentValue}
                  error={value.error}
                  onChange={(value: string[] | number[] | string) => {
                    props.updateCondition(
                      props.id,
                      "codes",
                      value,
                      props.condition,
                    );
                    props.pushCurrentHash(props.id);
                  }}
                />
              );
            }

            return (
              <DiscountCodes
                codes={value.currentValue}
                error={value.error}
                onChange={(value: string[] | number[] | string) => {
                  props.updateCondition(
                    props.id,
                    "codes",
                    value,
                    props.condition,
                  );
                  props.pushCurrentHash(props.id);
                }}
              />
            );
          }
          case "numbers": {
            return (
              <Counter
                count={value.currentValue}
                type={"numbers"}
                error={value.error}
                onChange={(value: string | number) => {
                  props.updateCondition(
                    props.id,
                    "numbers",
                    value,
                    props.condition,
                  );
                  props.pushCurrentHash(props.id);
                }}
                variableName={props.condition.variableName}
              />
            );
          }

          case "countryName": {
            return (
              // <TextInput
              //   placeholder="enter country"
              //   value={value}
              //   onChange={(value: string) => {
              //     props.updateCondition(
              //       props.id,
              //       "countryName",
              //       value,
              //       props.condition
              //     );
              //     props.pushCurrentHash(props.id);
              //   }}
              // />

              // Dec 30 Adding country dropdown to search and add multiple dropdowns as said by designers
              <CountryDropdown
                value={value.currentValue}
                handleChange={(value: any) => {
                  props.updateCondition(
                    props.id,
                    "countryName",
                    value,
                    props.condition,
                  );
                  props.pushCurrentHash(props.id);
                }}
                error={value.error}
              />
            );
          }

          case "items": {
            if (props.useNewComponent) {
              return (
                <Items
                  props={props}
                  value={value}
                />
              );
            }

            return (
              <ItemsShopify
                props={props}
                value={value}
              />
            );
          }

          case "amount": {
            return (
              <>
                {props.condition.operator === "is between" ? (
                  <NumberInputRange
                    placeholder="00"
                    value={value}
                    operator={props.condition.operator}
                    onChange={(value: any) => {
                      props.updateCondition(
                        props.id,
                        "amount",
                        value,
                        props.condition,
                      );
                      props.pushCurrentHash(props.id);
                    }}
                  />
                ) : (
                  <NumberInput
                    placeholder="00"
                    value={value}
                    onChange={(value: number) => {
                      props.updateCondition(
                        props.id,
                        "amount",
                        value,
                        props.condition,
                      );
                      props.pushCurrentHash(props.id);
                    }}
                  />
                )}
              </>
            );
          }

          case "currency": {
            return (
              <>
                {/* I have replaced the Form Select with Bootstrap Toggle because in Form Select we are using option tag and option is not customizable */}
                <Dropdown>
                  <Dropdown.Toggle
                    className={`ms-lg-2 mt-2 mt-lg-0 ${styles.currencyBox}`}
                  >
                    {value.currentValue !== "" ? value.currentValue : "INR"}{" "}
                    {/* Showing first value for default when selected variable is "" */}
                  </Dropdown.Toggle>
                  {/* Rendering Options  */}
                  <Dropdown.Menu className={`${styles.mainSelectMenu} mt-1`}>
                    <Dropdown.Item
                      value="USD"
                      onClick={(e: any) => {
                        props.updateCondition(
                          props.id,
                          "currency",
                          e.target.text,
                          props.condition,
                        ); // This function is used to update the condition.
                        props.pushCurrentHash(props.id); // This function is used to push the current hash.
                      }}
                      className={`${styles.daysddItem}`}
                    >
                      {`USD`}
                    </Dropdown.Item>
                    <Dropdown.Item
                      value="INR"
                      onClick={(e: any) => {
                        props.updateCondition(
                          props.id,
                          "currency",
                          e.target.text,
                          props.condition,
                        ); // This function is used to update the condition.
                        props.pushCurrentHash(props.id); // This function is used to push the current hash.
                      }}
                      className={`${styles.daysddItem}`}
                    >{`INR`}</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </>
            );
          }

          case "day_type": {
            // Define a variable called "mapper" and immediately invoke an anonymous function
            const mapper = (() => {
              // Use a switch statement to determine the value of "mapper" based on the value of "value.currentValue"
              switch (value.currentValue) {
                case "business_days":
                case "business days":
                  // If the value is "business_days" or "business days", set "mapper" to "business days"
                  return "business days";
                case "days":
                  // If the value is "days", set "mapper" to "days"
                  return "days";
                default:
                  // For any other value, set "mapper" to an empty string
                  return "";
              }
            })();
            return (
              <Dropdown>
                {/* I have replaced the Form Select with Bootstrap Toggle because in Form Select we are using option tag and option is not customizable */}
                <Dropdown.Toggle
                  className={`ms-lg-2 mt-2 mt-lg-0 ${styles.currencyBox} me-2`}
                >
                  {/* showing value from mapper when current value is not empty "" */}
                  {value.currentValue !== "" ? mapper : "days"}
                </Dropdown.Toggle>
                {/* Rendering Options  */}
                <Dropdown.Menu className={`${styles.mainSelectMenu} mt-1`}>
                  <Dropdown.Item
                    value="business_days"
                    onClick={(e: any) => {
                      props.updateCondition(
                        props.id,
                        "day_type",
                        e.target.text,
                        props.condition,
                      ); // This function is used to update the condition.
                      props.pushCurrentHash(props.id); // This function is used to push the current hash.
                    }}
                    className={`${styles.daysddItem}`}
                  >
                    {`business days`}
                  </Dropdown.Item>
                  <Dropdown.Item
                    value="days"
                    onClick={(e: any) => {
                      props.updateCondition(
                        props.id,
                        "day_type",
                        e.target.text,
                        props.condition,
                      ); // This function is used to update the condition.
                      props.pushCurrentHash(props.id); // This function is used to push the current hash.
                    }}
                    className={`${styles.daysddItem}`}
                  >{`days`}</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            );
          }
          case "return_reason": {
            return (
              <SelectReturnReason
                return_reasons={value.reasonId}
                error={value.error}
                onChange={(reasonId: string, value: string) => {
                  props.updateCondition(
                    props.id,
                    {
                      ...props.condition.values.return_reason,
                      reasonId,
                      value,
                    },
                    props.condition,
                  );
                  props.pushCurrentHash(props.id);
                }}
              />
            );
          }

          default:
            return null;
        }
      })}
    </>
  );
}

export default Values;
