import { OverlayTrigger } from "react-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";
import { Placement } from "react-bootstrap/esm/types";

const GeneralTooltip = ({
  title,
  children,
  className = "",
  placement = "top",
}: {
  title: string;
  children: React.ReactElement;
  className?: string;
  placement?: Placement;
}) => {
  return (
    <OverlayTrigger
      trigger={["hover", "focus"]}
      placement={placement}
      overlay={<Tooltip className={className}>{title}</Tooltip>}
    >
      {children}
    </OverlayTrigger>
  );
};

export default GeneralTooltip;
