import { axiosJSON } from "src/globals/axiosEndPoints";

export interface updateBrandPayload {
  id: string | number,
  brandName?: string,
  brandEmail?: string,
  isActive?: boolean,
  brandSignature?: string,
  disabledUser?: Array<{
    userId: number|string,
    enabled: boolean,
    assignTo: number|string
  }>,
  deleteProfile?: boolean;
}

export async function updateBrand(payload: updateBrandPayload) {

  const { data: res } = await axiosJSON.post("/api/brand/updateBrand", payload);

  let errorMessage = "Something went wrong";

  if (res.err === true) {

    if(res?.metaData?.statusCode && res.metaData.statusCode.length !== 0){
      errorMessage = res.metaData.statusCode;
    }

    throw new Error(errorMessage);
  }

  return res.data;
}
