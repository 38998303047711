import UserAvatar from "src/components/UserAvatar";
import { BrandAgent } from "src/services/Settings/Brands/getBrand";
import { useAppSelector } from "src/store/store";
import styles from "./SelectedAgentDropDown.module.scss";
import AxiosImg from "src/components/AxiosImg";


const UserListItem = ({ user }: {user: BrandAgent}) => {

  return (
    <div
     className={`${styles.tableHeader} m-2 align-items-center`}
    >
      <span className={`${styles.agentName}`}>
        <span className="me-2">
        { user?.profileImageUrl && user?.profileImageUrl.trim().length !== 0 ?
            <AxiosImg
            className={`${styles.profileIcon}`}
            url={user.profileImageUrl}
            isDirectURL={user.isPublicAttachmentUrl}
          />
          :
          <UserAvatar name={user.name || "NA"} size={25} />
        }
        </span>
        <span className={`${styles.assignName}`}>{user.name}</span>
      </span>
      <span className={`${styles.ticketCount} ms-4`}>{user.ticketCount ?? 0}</span>

    </div>
  );
};

export function SelectedAgentDropDown() {

  const { bulkSelectedUserIds, users } = useAppSelector((state)=>state.brandSettings);
  return (
    <div className={`mt-2`}>
    <div className={`${styles.tableHeader} m-2`}>
      <span className={`${styles.agentNameCol} ms-2`} >Agents</span>
      <span >Tickets</span>
    </div>
    {bulkSelectedUserIds.map((userId) => {
        const user = users[userId];
        if(user.ticketCount && parseInt(user.ticketCount+"") > 0){
          return (
            <UserListItem
            key={user.id}
            user={user}
            />
            );
          }else{
            return null;
          }
      })}
    </div>
  );
}
