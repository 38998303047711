import {
  ErrorDescription,
  UploadedCSVErrors,
} from "src/features/ReturnAutoWorkFlow/ReturnAutoWorkFlow.types";
import styles from "./ErrorsTable.module.scss";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import errorImg2 from "src/assets/images/bxs-error-circle.svg";
import DownloadFile from "src/components/DownloadFile";
import { useState } from "react";
import Pagination from "src/routes/TicketList/children/Pagination/Pagination";
import { v4 as uuid } from "uuid";
import Loader from "src/components/Loader";

const ErrorsTable = ({
  uploadErrors,
  isLoading,
  currentPage,
  totalPages,
  setCurrentPage,
}: {
  uploadErrors: UploadedCSVErrors;
  isLoading: boolean;
  currentPage: number;
  totalPages: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const [errorCsvDownloding, setErrorCsvDownloading] = useState(false);
  if (isLoading) {
    return <Loader imgClassName={"pt-0"} />;
  }
  return (
    <div>
      <div>
        <div
          className={`d-flex align-items-center px-2 mb-2 mx-auto ${styles.tableHead}`}
        >
          {uploadErrors?.tableHeadingIds?.map((tableHeadingId) => {
            const heading = uploadErrors?.tableHeadings
              ? uploadErrors?.tableHeadings[tableHeadingId]
              : null;
            return (
              <div className={`text-truncate ${styles.element}`}>
                <span className={`${styles.tablePara}`}>
                  {heading?.tableHeadingName ?? ""}
                </span>
              </div>
            );
          })}
        </div>
        {uploadErrors?.errorIds?.map((errorId, index) => {
          const error = uploadErrors?.errors
            ? uploadErrors?.errors[errorId]
            : null;
          return (
            <div
              key={uuid()}
              className={`d-flex align-items-center px-2 mb-2 mx-auto ${
                styles.tableRow
              } ${index % 2 === 0 ? styles.evenRow : styles.oddRow}`}
            >
              {uploadErrors?.tableHeadingIds?.map((tableHeadingId) => {
                const row = error?.rowData[tableHeadingId];
                return (
                  <div className={`text-truncate ${styles.element}`}>
                    {row?.type === "string" ? (
                      <span className={`${styles.tablePara}`}>
                        {(row?.value as string) ?? ""}
                      </span>
                    ) : row?.type === "description" ? (
                      <span className={`${styles.tablePara}`}>
                        {(row.value as ErrorDescription)?.description ?? ""}
                        <OverlayTrigger
                          trigger="click"
                          placement="top"
                          overlay={
                            <Tooltip className={` ${styles.toolTipCustom}`}>
                              <span>
                                {(row.value as ErrorDescription)?.info ?? ""}
                              </span>
                            </Tooltip>
                          }
                        >
                          <img
                            src={errorImg2}
                            alt=""
                            className="ms-1"
                          />
                        </OverlayTrigger>
                      </span>
                    ) : null}
                  </div>
                );
              })}
            </div>
          );
        })}
        <div
          className={`d-flex align-items-center px-2 mb-2 mx-auto justify-content-end ${styles.tableRow}`}
        >
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
      <div
        className="d-flex justify-content-end mt-4 mx-auto"
        style={{ maxWidth: "780px" }}
      >
        <DownloadFile
          fileName="Saufter_sample_template-Sheet1.csv"
          url={uploadErrors?.downloadErrorCSVLink ?? ""}
          className={`${styles.prevBtn}`}
          id="download_error_csv"
          loading={errorCsvDownloding}
          setLoading={setErrorCsvDownloading}
        >
          {errorCsvDownloding ? (
            <Spinner
              size="sm"
              variant="secondary"
            />
          ) : (
            <span>Download error csv</span>
          )}
        </DownloadFile>
      </div>
    </div>
  );
};

export default ErrorsTable;
