import { axiosJSON } from "src/globals/axiosEndPoints";
import { TabType } from "./step9GetConfig";
export interface GetConfigurationPayload {
  integrationId: number | string;
  tabType: TabType[];
}
export async function fetchStep9ConfigPost(payload: GetConfigurationPayload) {
  console.log(payload);
  const { data } = await axiosJSON.post(
    "/api/setting/automation/refund/step/9/configuration",
    { configTabs: payload.tabType, integrationId: payload.integrationId }
  );

  // const data = await postStep9;
  if (data.err === true) {
    throw new Error("Some Error Occurred while fetching data");
  }

  return data;
}

// let postStep9 = function (params: Step9Config) {
//   return new Promise(function (resolve, reject) {
//     if (params) {
//       setTimeout(function () {
//         resolve({});
//       }, 2000);
//     } else {
//       reject(new Error("Something is not right!"));
//     }
//   });
// };
