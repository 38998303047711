import { axiosJSON } from "src/globals/axiosEndPoints";

export interface PaymentTermOption {
  paymetTermOptions: Array<{
    id: string;
    name: string;
    showDateTimePicker: boolean;
    type: string;
    dueInDays: number;
  }>;
}

export interface PaymentTermOptionParams {}

export async function paymentTermOption(params: PaymentTermOptionParams) {
  const { data: res }: any = await axiosJSON.post(
    `/api/shopify/order/getPaymentTermsOptions`,
    params
  );

  if (res.error === true) {
    throw new Error(res.message as string);
  }

  return res.data as PaymentTermOption;
}
