import styles from "./AddReturnItems.module.scss";
import returnImg from "src/assets/images/ReturnImg.png";
import orderImg from "src/assets/images/order1.png";
import orderImg2 from "src/assets/images/order2.png";
import TimeLine from "../../Widgetpreview/Children/Timeline/Timeline";
import {
  StepName,
  useReturnPreviewState,
} from "../../../../../hooks/useReturnPreview";
import ButtonWrapper from "../../Widgetpreview/Children/ButtonWrapper/ButtonWrapper";
import WidgetFooter from "../../Widgetpreview/Children/WidgetFooter/WidgetFooter";
import { useState } from "react";
import useGetAutomationData from "src/features/ReturnAutoWorkFlow/hooks/useGetAutomationData";

const AddReturnItems = () => {
  let itemCount = 1;
  const [showError, setShowError] = useState(false);

  const { selectedOrder, updateState, ...contextValues } =
    useReturnPreviewState();

  const { getAllReturnTypes, isReturnTypesAvailable } = useGetAutomationData();

  const { allReturnTypes } = getAllReturnTypes();

  const handleBack = () => {
    if (selectedOrder) {
      updateState({
        ...contextValues,
        selectedOrder: {
          ...selectedOrder,
          activeStep: "selectAction",
        },
      });
    }
  };
  const handleNext = () => {
    if (
      !selectedOrder?.addedItems ||
      Object.keys(selectedOrder.addedItems).length < 1
    ) {
      setShowError(true);
      return;
    }

    // Check if any item is not returnable
    const hasNonReturnableItems = Object.values(selectedOrder?.addedItems).some(
      (item) => !item.isReturnExchangeApplicable,
    );
    if (hasNonReturnableItems) {
      updateState({
        ...contextValues,
        selectedOrder: {
          ...selectedOrder,
          activeStep: "notReturnableItems",
        },
      });
    } else {
      let nextPage: StepName = "configureNextSteps";
      let prevStep: StepName = "addReturnItems";
      if (
        isReturnTypesAvailable &&
        allReturnTypes &&
        allReturnTypes?.length > 0
      ) {
        nextPage = "returnType";
      }
      updateState({
        ...contextValues,
        selectedOrder: {
          ...selectedOrder,
          activeStep: nextPage,
          prevStepForConfig: prevStep,
        },
      });
    }
  };

  const handleCheckUncheck = (
    itemId: string,
    index: number,
    checked: boolean,
  ) => {
    if (selectedOrder?.orderId) {
      setShowError(false);
      const currentAddedItems = selectedOrder.addedItems || {};

      // Update the addedItems based on checked state and index
      let updatedItems = { ...currentAddedItems };

      if (checked) {
        updatedItems[itemId] = updatedItems[itemId]
          ? {
              ...updatedItems[itemId],
              quantity: updatedItems[itemId].quantity + 1,
              indexes: [...updatedItems[itemId].indexes, index],
            }
          : {
              itemId,
              quantity: 1,
              isReturnExchangeApplicable: true,
              indexes: [index],
            };
      } else {
        if (updatedItems[itemId]) {
          if (updatedItems[itemId].quantity > 1) {
            updatedItems[itemId] = {
              ...updatedItems[itemId],
              quantity: updatedItems[itemId].quantity - 1,
              indexes: updatedItems[itemId].indexes.filter((i) => i !== index),
            };
          } else {
            const { [itemId]: _, ...rest } = updatedItems;
            updatedItems = rest;
          }
        }
      }

      updateState({
        ...contextValues,
        selectedOrder: {
          ...selectedOrder,
          addedItems: updatedItems,
        },
      });
    }
  };

  return (
    <div>
      <div className={`${styles.widgetBody}`}>
        <TimeLine activeStep={selectedOrder?.activeStep ?? "selectOrder"} />
        <div className="mt-2 d-flex align-items-center">
          <img
            src={returnImg}
            alt=""
          />
          <span className={`ps-1 ${styles.heading}`}>Add Return Item(S)</span>
        </div>
        {showError && (
          <span className={`p-1 mb-1 mt-2 ${styles.errorMsg}`}>
            * Please select at least 1 item you want to return/Exchange
          </span>
        )}
        <div className="mt-3 pt-1">
          <p className={`${styles.boxHeader}`}>
            Select Items you wish to Return /Exchange :
          </p>
          {selectedOrder &&
            selectedOrder.itemsInOrder.map((item, itemIndex) => {
              return (
                <div
                  className={`${styles.orderBox} mb-3`}
                  key={item.id}
                >
                  <div className="d-flex pt-1 mb-4">
                    <div className={styles.cart}>
                      <img
                        src={item.name == "Acme T-Shirt" ? orderImg : orderImg2}
                        alt="order"
                        className={styles.orderImg}
                      />
                    </div>
                    <div className="ms-2">
                      <span className={styles.orderName}>
                        {item.unitPrice.currencyCode} {item.unitPrice.amount} x{" "}
                        {item.quantity}
                      </span>
                      <span className={styles.orderDesc}>SKU : {item.sku}</span>
                    </div>
                  </div>

                  {[...Array(item.quantity)].map((_, i) => {
                    const isChecked =
                      (selectedOrder?.addedItems &&
                        selectedOrder?.addedItems[item.id]?.indexes?.includes(
                          i,
                        )) ||
                      false;

                    return (
                      <div
                        className="d-flex justify-content-between mb-2"
                        key={`${item.id}-${i}`}
                      >
                        <div className="form-check">
                          <input
                            className={`form-check-input ${styles.checkBox} ${
                              showError ? "border-danger" : ""
                            }`}
                            type="checkbox"
                            value=""
                            id={`return${item.id}-${i}`}
                            onChange={(e) =>
                              handleCheckUncheck(item.id, i, e.target.checked)
                            }
                            checked={isChecked}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`return${item.id}-${i}`}
                          >
                            <span className={`d-block ${styles.labelHead}`}>
                              Return {item.name}
                            </span>
                            <span className={styles.labelSub}>
                              {item.unitPrice.currencyCode}{" "}
                              {item.unitPrice.amount} x 1
                            </span>
                          </label>
                        </div>
                        <div>
                          <span className={styles.itemText}>
                            Sample item {itemCount++}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
        </div>
      </div>
      <ButtonWrapper
        onBackClick={handleBack}
        onNextClick={handleNext}
      />
      <WidgetFooter />
    </div>
  );
};

export default AddReturnItems;
