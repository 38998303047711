import styles from "./ThemeSetting.module.scss";
import helpLogo from "src/assets/images/helpCenter.png";
import { Modal } from "react-bootstrap";
import ResetModal from "./Children/ThemeModal/ResetModal/ResetModal";
import ThemeModal from "./Children/ThemeModal/ThemeModal";
import useThemeSetting from "./useThemeSetting";
import useThemeBox from "../ThemeBox/useThemeBox";
import preview from "src/assets/images/theme-preview.png";
import preview2 from "src/assets/images/preview2.png";
import arrowRight from "src/assets/images/arrowRight.png";

const ThemeSetting = () => {
  const {
    dropdown,
    setDropDown,
    onShow,
    showModal,
    resetModal,
    onHide,
    hideResetModal,
    showResetModal,
  } = useThemeSetting();

  const { getThemeData } = useThemeBox();

  return (
    <div className={`p-3 ${styles.settingWrapper}`}>
      <p className={`${styles.heading} saufter_h4 `}>Theme Selection</p>
      <div
        className={`d-flex justify-content-between align-items-center px-2 saufter_medium_h4 ${
          styles.dropBtn
        } ${dropdown && styles.borderSet}`}
        onClick={() => setDropDown(!dropdown)}
      >
        <span>Theme Selection</span>
        <span className={`${styles.arrow}`}>
          {dropdown ? (
            <img src={arrowRight} alt="" className={`${styles.arrowDown}`} />
          ) : (
            <img src={arrowRight} alt="" />
          )}
        </span>
      </div>
      {dropdown ? (
        <div className={`p-3 ${styles.dropDown}`}>
          <p className={`${styles.previewHead}`}>
            {getThemeData?.data?.data?.themeType === "alice_in_wonderland"
              ? "ALICE IN WONDERLAND"
              : getThemeData?.data?.data?.themeType === "night_in_the_oscars"
              ? "NIGHT IN THE OSCARS"
              : ""}
          </p>
          {/* <div className={`${styles.previewBox}`}>
            <div
              className={`mt-1 text-center d-flex flex-column justify-content-center align-items-center ${styles.previewHeader}`}
            >
              <p className="mb-0">
                <img
                  src={helpLogo}
                  alt="logo"
                  className={`${styles.helpLogo}`}
                />
                <span className={`ms-2 ${styles.acme}`}>
                  Acme Store Help Center
                </span>
              </p>
              <h4 className={`${styles.helpText}`}>Hi, How can we help ?</h4>
            </div>
            <div>
              <div
                className={`d-flex align-items-center ps-4 ${styles.searchBox}`}
              >
                <span className={`${styles.searchText}`}>
                  {" "}
                  <i className="fa-solid fa-magnifying-glass"></i>{" "}
                </span>
                <span className={`ps-2 ${styles.searchText}`}>
                  Search for answers
                </span>
              </div>
            </div>
            <div className={`mt-3 ${styles.categoryBox}`}>
              <p className={`mb-1 ${styles.categoryText}`}>Categories</p>
              <div className={`${styles.category}`}></div>
              <div className={`${styles.category}`}></div>

              <div className={`${styles.category}`}></div>
            </div>
          </div> */}

          <img
            src={
              getThemeData?.data?.data?.themeType === "alice_in_wonderland"
                ? preview
                : getThemeData?.data?.data?.themeType === "night_in_the_oscars"
                ? preview2
                : null
            }
            alt=""
            className={`img-fluid w-100 ${styles.setHeight}`}
          />

          <div className={`mt-3 w-100 ${styles.actionBox}`}>
            <button
              className={`d-flex justify-content-center align-items-center me-2 ${styles.changeBtn}`}
              onClick={onShow}
            >
              Change theme
            </button>
            {/* Modal for Themes Listing */}
            <Modal
              // backdropClassName={`${styles.modalBack}`}
              show={showModal}
              onHide={onHide}
              dialogClassName={`${styles.modalDialog}`}
              contentClassName={`${styles.modalContent}`}
              centered={true}
            >
              <ThemeModal
                onHide={onHide}
                themeType={getThemeData?.data?.data?.themeType}
              />
            </Modal>
            <button
              className={`d-flex justify-content-center align-items-center ${styles.resetBtn}`}
              onClick={showResetModal}
            >
              Reset theme
            </button>
            <Modal
              // backdropClassName={`${styles.modalBack}`}
              show={resetModal}
              onHide={hideResetModal}
              dialogClassName={`${styles.modalRestDialog}`}
              contentClassName={`${styles.modalRestContent}`}
              backdropClassName={`${styles.backdropColor}`}
            >
              <ResetModal hideResetModal={hideResetModal} />
            </Modal>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ThemeSetting;
