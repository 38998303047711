import { createSelector } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { RootState, useAppSelector } from "src/store/store";
import AllOrdersContext, { OrderCheckedState } from "./AllOrdersContext";
function getAllOrders(state: RootState) {
  return state.allOrders.orders;
}

const transformAllOrders = createSelector([getAllOrders], (allOrders) => {
  return allOrders.map((o) => {
    return { id: o.orderId, checked: false };
  });
});

function AllOrdersContextProvider(props: any) {
  const allOrdersCheckedState = useAppSelector(transformAllOrders);
  const [ordersChecked, setOrdersChecked] = useState<
    { id: number; checked: boolean }[]
  >(allOrdersCheckedState);

  useEffect(
    () => setOrdersChecked(allOrdersCheckedState),
    [allOrdersCheckedState]
  );

  function upsertOrderChecked(orderToUpdate: OrderCheckedState) {
    const foundOrderIdx = ordersChecked.findIndex(
      (o) => o.id === orderToUpdate.id
    );
    if (foundOrderIdx !== -1) {
      const order = ordersChecked[foundOrderIdx];
      order.checked = orderToUpdate.checked;
      ordersChecked[foundOrderIdx] = order;
      setOrdersChecked([...ordersChecked]);
    } else {
      setOrdersChecked([...ordersChecked, orderToUpdate]);
    }
  }

  function getOrderCheckedById(id: number) {
    return ordersChecked.find((o) => o.id === id);
  }

  function areAllOrdersChecked() {
    if (ordersChecked.length === 0) return false;
    for (let i = 0; i < ordersChecked.length; i++) {
      if (!ordersChecked[i].checked) return false;
    }
    return true;
  }

  function toggleCheckedOrders(checkedAll: boolean) {
    setOrdersChecked((oc) => {
      for (let i = 0; i < oc.length; i++) {
        oc[i].checked = checkedAll;
      }
      return [...oc];
    });
  }

  function resetState() {
    setOrdersChecked([]);
  }

  useEffect(() => {
    return resetState;
  }, []);

  return (
    <AllOrdersContext.Provider
      value={{
        ordersChecked,
        upsertOrderChecked,
        getOrderCheckedById,
        areAllOrdersChecked,
        toggleCheckedOrders,
        resetState,
      }}
    >
      {props.children}
    </AllOrdersContext.Provider>
  );
}

export default AllOrdersContextProvider;
