import { useAppSelector } from "src/store/store";
import { getDayFromTime } from "src/utils/dateLibrary";
import styles from "./ShowTopTimeBar.module.scss";

interface Props {
  className?: string;
  dateClassName?: string;
  dateTimeUTC: string;
  isHistory?: boolean;
  [key: string]: any;
}

function ShowTopTimeBar({
  className = "",
  dateClassName = "",
  dateTimeUTC,
  isHistory = false,
  ...props
}: Props) {
  const userTimezone = useAppSelector(
    (state) => state.globals.currentUserData?.userTimezone
  );

  return (
    <div className={`d-flex justify-content-between ${className}`}>
      <div
        className={`my-auto h-0 ${styles.border} ${
          isHistory ? styles.shortBorder : ""
        }`}
      ></div>
      <div className={`${styles.time} ${dateClassName}`} {...props}>
        {dateTimeUTC && userTimezone
          ? getDayFromTime({
              time: new Date(dateTimeUTC + "Z"),
              timeZone: userTimezone,
            })
          : ""}
      </div>
      <div
        className={`my-auto h-0 ${styles.border} ${
          isHistory ? styles.shortBorder : ""
        }`}
      ></div>
    </div>
  );
}

export default ShowTopTimeBar;
