import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";
import { ExternalSourceImportStatus } from "./importExternalSource.service";

export interface DeleteExternalSourceParam {
  sourceId: number | string;
}
export interface DeleteExternalSourceResponse {
  status: ExternalSourceImportStatus;
}
const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/botProfile/external/deleteSource`
  : "/api/botProfile/external/deleteSource";

export async function deleteExternalSource(params: DeleteExternalSourceParam) {
  const { data: res } = await axiosJSON.delete(apiEndPoint, { params });

  if (res.err) {
    throw new Error(res.msg as string);
  }

  return res.data as DeleteExternalSourceResponse;
}
