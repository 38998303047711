import { useNavigate, useParams } from "react-router-dom";
import { Links } from "../../../../../../TargetedMessages/TargetLinks/TargetLinks";
import Announcement from "../../../../../Announcement/Announcement";
import Greetings from "../../../../Greetings";
import styles from "./SaveGreetModal.module.scss";

const SaveGreetModal = (props: { heading: string; Hide: Function }) => {
  const navigate = useNavigate();
  const params = useParams();

  return (
    <>
      <div className={`${styles.modalMain}`}>
        <div
          className={`d-flex justify-content-between align-items-center ${styles.modalHeaderBox}`}
        >
          {props.heading === "Announcement" ? (
            <p className={`${styles.modalHead}`}>
              Name your announcement so you can easily identify it later
            </p>
          ) : (
            <p className={`${styles.modalHead}`}>
              Name your greeting so you can easily identify it later
            </p>
          )}

          <span
            className={`${styles.close}`}
            onClick={() => {
              props.Hide();
            }}
          >
            <i className="fa-solid fa-xmark"></i>
          </span>
        </div>
        <div>
          <input className={`${styles.inputBox}`} type="text" placeholder="" />
          {props.heading === "Announcement" ? (
            ""
          ) : (
            <div className="d-flex">
              <span className={`${styles.setSpan}`}>Set Greeting Live</span>
              <div className="form-check form-switch ms-2">
                <input className={`form-check-input`} type="checkbox" />
              </div>
            </div>
          )}

          <div className={`${styles.actionBox}`}>
            <button
              className={`${styles.backBtn}`}
              onClick={() => {
                props.Hide();
              }}
            >
              Cancel
            </button>
            <button
              className={`${styles.saveBtn}`}
              onClick={() => {
                {
                  props.heading === "Greeting"
                    ? navigate(
                        `/live-chat/settings/${params.integrationId}/targetMessage/${Links.greeting.name}`
                      )
                    : navigate(
                        `/live-chat/settings/${params.integrationId}/targetMessage/${Links.announcement.name}`
                      );
                }
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default SaveGreetModal;
