import { useState } from "react";
import NotificationBox from "./children/NotificationBox/NotificationBox";
import EditNotification from "./children/EditNotification/EditNotification";
import { useNotifications } from "./hooks/notifications/useNotifications";
import { ReportTabNames } from "../ReportTabs/ReportTabs";
import useNotifyPermission from "./hooks/notifications/useNotifyPermission";

interface Props {
  activeTabname: ReportTabNames;
  setActiveTabname: React.Dispatch<React.SetStateAction<ReportTabNames>>;
}

function NotificationContainer({ activeTabname, setActiveTabname }: Props) {
  const { reportNotifications } = useNotifications();
  const notifyPermission = useNotifyPermission();

  return (
    <div>
      {reportNotifications.activeView === "edit" &&
      notifyPermission === "edit" ? (
        <EditNotification />
      ) : (
        <NotificationBox
          activeTabname={activeTabname}
          setActiveTabname={setActiveTabname}
        />
      )}
    </div>
  );
}

export default NotificationContainer;
