import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";
import { ExternalSourceImportStatus } from "./importExternalSource.service";
import { IBotIndexingStatus } from "src/components/BotIndexingStatus/BotIndexingStatus";

export interface Articles {
  botTrainingStatus: IBotIndexingStatus;
  articleId: number;
  articleUrl: string;
  articleTitle: string;
  articleMainSource: string;
  noOfTimesAppeared: number;
  articleDescription: string;
  isSelected: boolean;
  sourceId?: number;
}
// {
//   id: 1,
//   sourceUrl: "https://example.com/source1",
//   noOfPagesBeingImported: 10,
//   totalArticleCount: 0,
// }
export interface Source {
  id: number;
  sourceUrl: string;
  noOfPagesBeingImported: number;
  status: ExternalSourceImportStatus;
  importPercentage: number;
  pagesImported?: number;
  totalArticleCount: number;
  isAllArticleSelected: boolean;
}

export interface GetAllExternalResponse {
  data: Source[];
  metaData: {
    count: number;
    total: number;
  };
}

export interface GetAllExternalParams {
  searchTerm?: string;
  botProfileId: number | string;
  start?: number;
  limit?: number;
  // type?: "all" | "single";
  sourceId? : number | string;
}

const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/botProfile/external/getAll`
  : "/api/botProfile/external/getAllSources";

export async function getAllExternal(params: GetAllExternalParams) {
  const { data: res } = await axiosJSON.post(apiEndPoint, params);
  console.log(res.data);

  if (res.err) {
    throw new Error(res.message as string);
  }

  return res as GetAllExternalResponse;
}
