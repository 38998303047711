import styles from "./SuccessMergeModal.module.scss";
import closeModal from "src/assets/images/close(3).png";
import IssueDescription from "../../../TableRow/Children/BugReportModal/IssueDescription/IssueDescription";
import copyIcon from "src/assets/images/copyIcon.png";
import { MergeBugReport } from "src/services/BugReport/mergeBug.service";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useRef, useState } from "react";
import BugReportModal from "../../../TableRow/Children/BugReportModal/BugReportModal";

interface Props {
  onHide: () => void;
  mergeData: MergeBugReport | undefined;
  setSelectedBugId: React.Dispatch<React.SetStateAction<Set<number>>>;
}

const SuccessMergeModal = ({ onHide, mergeData, setSelectedBugId }: Props) => {
  const [showModel, setShowModel] = useState(false);
  const queryClient = useQueryClient();

  const hanndleShowModel = useCallback(
    (newStatus?: string) => {
      if (newStatus === "resolved") {
        setShowModel((prev) => {
          if (!prev) return true;
          onHide();
          setSelectedBugId(new Set());
          return false;
        });
        queryClient.clear();
      } else if (!newStatus) {
        setShowModel((prev) => !prev);
        onHide();
        setSelectedBugId(new Set());
      }
    },
    [showModel],
  );
  // ticket id coppy function
  const handleCopy = (copyMe: string) => {
    window.navigator.clipboard.writeText(copyMe);
  };

  if (showModel)
    return (
      <BugReportModal
        onHide={hanndleShowModel}
        id={mergeData?.bugId}
        tableBugStatusUpdate={() => {}}
        categoryName={mergeData?.categoryDetails.categoryName ?? ""}
        bugData={{
          bugId: mergeData?.bugId ?? 0,
          brandId: 0,
          bugDescription: mergeData?.bugDescription ?? "",
          bugAttachmentDetails: mergeData?.bugAttachmentsDetails ?? [],
          bugCreatedAtGmt: mergeData?.createdAtGmt ?? "",
          bugStatusKey: mergeData?.bugStatusKey ?? "",
          priorityKey: mergeData?.priorityKey ?? "",
          customerDetails: mergeData?.customerDetails ?? [],
          ticketId: mergeData?.ticketId ?? [],
          bugReportedUrl: mergeData?.bugReportedUrl ?? [],
          companyName: mergeData?.companiesName ?? [],
        }}
      />
    );

  return (
    <div className={`${styles.mergeModalWrapper}`}>
      <div className="d-flex justify-content-end align-items-center">
        <img
          src={closeModal}
          alt=""
          height={12}
          width={12}
          onClick={() => {
            queryClient.clear();
            setSelectedBugId(new Set());
            onHide();
          }}
          className="cursor-pointer"
        />
      </div>
      <div className={`${styles.successContainer}`}>
        <div className="text-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="45.875"
            height="45.875"
            viewBox="0 0 45.875 45.875"
          >
            <path
              id="Icon_awesome-check-circle"
              data-name="Icon awesome-check-circle"
              d="M46.438,23.5A22.938,22.938,0,1,1,23.5.563,22.937,22.937,0,0,1,46.438,23.5ZM20.847,35.645,37.865,18.627a1.48,1.48,0,0,0,0-2.093l-2.093-2.093a1.48,1.48,0,0,0-2.093,0L19.8,28.32l-6.48-6.48a1.48,1.48,0,0,0-2.093,0L9.135,23.933a1.48,1.48,0,0,0,0,2.093l9.619,9.619a1.48,1.48,0,0,0,2.093,0Z"
              transform="translate(-0.563 -0.563)"
              fill="#56cf22"
            />
          </svg>
          <p className={`mt-3 pt-1 text-center ${styles.successHead}`}>
            Merge completed!
          </p>
        </div>
        <div className="d-flex flex-wrap align-items-center pt-5 mb-2">
          <span className={`${styles.linkedText} me-3`}>Linked tickets:</span>
          {mergeData?.ticketId.map((ticket) => (
            <div
              className={`mb-1 me-1 ${styles.tivketId}`}
              key={ticket}
            >
              <span className={`pe-1`}>
                Ticket ID :{" "}
                <a
                  href={`${window.location.origin}/tickets/all/ticket-view/ticket/${ticket}/`}
                  target="_blank"
                  className={`cursor-pointer`}
                >
                  {ticket}
                </a>
              </span>
              <img
                src={copyIcon}
                className={`cursor-pointer`}
                alt=""
                width={10}
                onClick={() =>
                  handleCopy(
                    `${window.location.origin}/tickets/all/ticket-view/ticket/${ticket}/`,
                  )
                }
              />
            </div>
          ))}
        </div>
        <div>
          <IssueDescription
            isUserShow={false}
            mergeData={mergeData}
            mergeModal={true}
            hanndleShowModel={hanndleShowModel}
          />
        </div>
      </div>
    </div>
  );
};

export default SuccessMergeModal;
