import { useCallback, useMemo } from "react";
import styles from "../../../UpdateSegmentDetails.module.scss";

interface Props {
  value: string;
  onChange: (value: string) => void;
  placeholder: string;
  error?: string;
  suffix?: string;
}
const StringInput = ({
  value,
  onChange,
  placeholder,
  error = "",
  suffix = "",
}: Props) => {
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      onChange(e.target.value);
    },
    [onChange],
  );

  const isError = useMemo(() => error && !value.trim(), [error, value]);

  return (
    <div className={``}>
      <div className={`ms-md-2`}>
        <input
          type="text"
          value={value}
          className={`${styles.textBox} ${isError ? styles.errBorder : ""}`}
          placeholder={placeholder}
          onChange={handleChange}
        />
        {suffix ? (
          <div
            className={`${styles.daysAgoText} ms-2 d-flex align-items-center`}
          >
            {suffix}
          </div>
        ) : (
          ""
        )}
      </div>
      {isError ? <div className={`${styles.errText} ms-2`}>{error}</div> : ""}
    </div>
  );
};

export default StringInput;
