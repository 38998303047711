import axios from "axios";
import {
  getTicketSideBarApi,
  ITicketsSB,
} from "src/apis/sideBar/read/getTicketSideBar.api";
import { ITicketBtn } from "src/containers/SideBar/children/SubSB/SubSB";
import {
  createParamMSD,
  getMultipleSidebarData,
  getMultipleSidebarDataMultiAjax,
} from "src/services/SideBar/getMultipleSidebarData";
import {
  getUsersViews,
  getUsersViewsById,
  ListView,
  ListViewResponse,
} from "src/services/SideBar/getSubSB";
import {
  getTicketsCountInSideBar,
  ISidebarTicketsCountParams,
  ISidebarTicketsCountRes,
  prepareGetSidebarTicketsCountQuery,
} from "src/services/SideBar/getTicketsCountInSideBar";
import { RootState } from "src/store/store";
import { AjaxError } from "src/types/AjaxError";
import {
  getClosedDateValue,
  getCreatedDateValue,
  getNotRepliedSinceDateValue,
} from "../ticketFilters/ticketFilters.selectors";
import { ESideBar } from "../tickets/tickets.slice";
import {
  getActiveChannelId,
  getActiveDraftStatusId,
  getActiveListIndex,
  getActiveTicketStatusId,
} from "./ticketSidebar.selectors";
import { fetchMultipleSidebarData } from "./ticketSidebar.slice";

async function fetchSideBarData(
  payload: undefined,
  { getState, dispatch }: { getState: Function; dispatch: Function }
) {
  try {
    const ticketBtnData: Array<ITicketBtn> =
      getState().ticketSideBar.ticketBtnData;
    const res: Array<ITicketsSB> = await getTicketSideBarApi();
    const ticketBtnDataTemp: Array<ITicketBtn> = res.map((data, key) => {
      return {
        header: data.name,
        margin: key === 0 ? 5 : 4,
        paginate: false,
        ticketBtnList: data.content.map((ticketDivs, key1) => {
          return {
            ticketBtnName: ticketDivs.name,
            ticketBtnCount: 0,
            keyName: ticketDivs.name === ESideBar.allTickets ? "all" : "my",
            ddOuterList: ticketDivs.content.map((dropDowns, key2) => {
              let keyTemp = 0;
              let dropdownMenuAllCount = 0;
              for (const x in dropDowns.content) {
                if (
                  dropDowns.content[x].name === "Open" &&
                  dropDowns.name === "Ticket Status"
                ) {
                  keyTemp = parseInt(x);
                }
                dropdownMenuAllCount += dropDowns.content[x].count;
              }
              // console.log("dropDowns.content:: ", dropDowns.content);
              // console.log("dropdownMenuAllCount:: ", dropdownMenuAllCount);
              return {
                ddName: dropDowns.name,
                ddList: {
                  // 0: { name: "All", count: ticketDivs.count },
                  0: { name: "All", count: dropdownMenuAllCount },
                  ...dropDowns.content,
                },
                ddSelectedIndex: ticketBtnData[key]
                  ? ticketBtnData[key].ticketBtnList[key1]
                    ? ticketBtnData[key].ticketBtnList[key1].ddOuterList[key2]
                      ? ticketBtnData[key].ticketBtnList[key1].ddOuterList[key2]
                          .ddSelectedIndex
                      : [keyTemp]
                    : [keyTemp]
                  : [keyTemp],
              };
            }),
          };
        }),
      };
    });
    const selectedBtn = getState().ticketSideBar.selectedBtn;
    let viewById: ListView | null = null,
      userViews: ListViewResponse | null = null;
    try {
      if (selectedBtn.includes("viewId")) {
        viewById = await getUsersViewsById({
          viewId: parseInt(selectedBtn.split("::")[1]),
        });
      }
      userViews = await getUsersViews({
        start: 0,
        limit: getState().ticketSideBar.usersViewLimit,
      });
    } catch {}
    // refetch the sidebar ticket count with global filters applied
    setTimeout(() => {
      const [
        pathIndex,
        ticketPathStart,
        ticketPathId,
        ticketPathView,
        ticketPathSubStart,
        ticketPathSubId,
      ] = window.location.pathname.split("/");
      if (ticketPathStart !== "tickets" || ticketPathSubStart === "ticket") {
        dispatch(
          fetchMultipleSidebarData({
            views: [ticketPathSubStart === "ticket" ? ticketPathId : "all"],
          })
        );
      }
    }, 0);
    return {
      ticketBtnData: ticketBtnDataTemp,
      userViews,
      viewById,
    };
  } catch (e) {
    throw e;
  }
}

async function fetchSideBarUsersViews(
  payload: undefined,
  { getState, dispatch }: { getState: Function; dispatch: Function }
) {
  const currentState: RootState = getState();
  const usersData = await getUsersViews({
    start:
      currentState.ticketSideBar.usersViewList.length +
      currentState.ticketSideBar.usersViewListFlag,
    limit: currentState.ticketSideBar.usersViewLimit,
  });
  return usersData;
}

/**
 * Async Thunk Function to get refresh user views
 */
async function refreshSideBarUsersViews(
  payload: undefined,
  { getState, dispatch }: { getState: Function; dispatch: Function }
) {
  const currentState: RootState = getState();
  // Get user views data
  const usersData = await getUsersViews({
    start: 0,
    limit: currentState.ticketSideBar.usersViewLimit,
  });
  return usersData;
}

let contMSD: { [key: string]: any } = [];
const getContMSD = () => contMSD;

interface IFetchMultipleSideBarPayload {
  //all - fetch all ticket, my - fetch my ticket sidebar, allViews - fetch all views, string "view::1" - viewId which needs to be fetched
  views: Array<"all" | "my" | "mentions" | "allViews" | string>;
}
// function to fetch updated tickets count in sidebar based on filters applied for all views
async function fetchMultipleSidebarDataThunk(
  payload: IFetchMultipleSideBarPayload = {
    views: [
      "all",
      "my",
      "mentions",
      "allViews",
      "ai_handled_open",
      "ai_handled_closed",
    ],
  },
  { getState, rejectWithValue }: { getState: Function; rejectWithValue: any }
) {
  if (payload.views.length == 0) {
    payload.views = [
      "all",
      "my",
      "mentions",
      "allViews",
      "ai_handled_open",
      "ai_handled_closed",
    ];
  }

  payload.views.forEach((key) => {
    if (contMSD[key] !== undefined) {
      contMSD[key].abort();
    }
  });
  if (payload.views.includes("allViews")) {
    Object.entries(contMSD).forEach(([key, value]) => {
      if (key !== "all" && key !== "my" && key !== "mentions") {
        contMSD[key].abort();
      }
    });
  }
  // const controller = new AbortController();
  // contMSD.push(controller);

  const currentState: RootState = getState();
  const param: { [key: string]: { signal: any; param: any } } = {};

  //checking if payload contains allTickets value, if yes adding it to the param
  if (payload.views.includes("all")) {
    let controller = new AbortController();
    contMSD["all"] = controller;
    param["all"] = {
      signal: controller.signal,
      param: createParamMSD({
        ticketAppliedData:
          currentState.ticketSideBar.ticketBtnData[0].ticketBtnList[0]
            .ticketAppliedData,
        activeListIndex: "all",
        sidebarFiltersApplied: {
          activeSidebarChannelId:
            currentState.ticketSideBar.ticketBtnData[0].ticketBtnList[0]
              .ddOuterList[0].ddSelectedIndex,
          activeSidebarTicketStatusId:
            currentState.ticketSideBar.ticketBtnData[0].ticketBtnList[0]
              .ddOuterList[1].ddSelectedIndex,
          activeSidebarDraftStatusId:
            currentState.ticketSideBar.ticketBtnData[0].ticketBtnList[0]
              .ddOuterList[2]?.ddSelectedIndex ?? [],
        },
      }),
    };
  }
  //checking if payload contains myTickets value, if yes adding it to the param
  if (payload.views.includes("my")) {
    let controller = new AbortController();
    contMSD["my"] = controller;
    param["my"] = {
      signal: controller.signal,
      param: createParamMSD({
        ticketAppliedData:
          currentState.ticketSideBar.ticketBtnData[0].ticketBtnList[1]
            .ticketAppliedData,
        activeListIndex: "my",
        sidebarFiltersApplied: {
          activeSidebarChannelId:
            currentState.ticketSideBar.ticketBtnData[0].ticketBtnList[1]
              .ddOuterList[0].ddSelectedIndex,
          activeSidebarTicketStatusId:
            currentState.ticketSideBar.ticketBtnData[0].ticketBtnList[1]
              .ddOuterList[1].ddSelectedIndex,
          activeSidebarDraftStatusId:
            currentState.ticketSideBar.ticketBtnData[0].ticketBtnList[1]
              .ddOuterList[2]?.ddSelectedIndex ?? [],
        },
      }),
    };
  }
  //checking if payload contains mentions value, if yes adding it to the param
  if (payload.views.includes("mentions")) {
    let controller = new AbortController();
    contMSD["mentions"] = controller;
    param["mentions"] = {
      signal: controller.signal,
      param: createParamMSD({
        ticketAppliedData: currentState.ticketSideBar.mentionsTicketAppliedData,
        activeListIndex: "mentions",
        sidebarFiltersApplied: undefined,
      }),
    };
  }

  //checking if payload contains allViews value, if yes adding all the viewIds to param
  if (payload.views.includes("allViews")) {
    Object.values(currentState.ticketSideBar.usersViewData).forEach((data) => {
      let controller = new AbortController();
      contMSD[`viewId::${data.viewId}`] = controller;

      param[`viewId::${data.viewId}`] = {
        signal: controller.signal,
        param: createParamMSD({
          ticketAppliedData: data.ticketAppliedData,
          activeListIndex: `viewId::${data.viewId}`,
          sidebarFiltersApplied: undefined,
        }),
      };
    });
  }
  //checking if view ids given in payload, filter the viewIds
  let viewIds = payload.views.filter((value) => {
    let v = value.trim();
    return v.includes("viewId");
  });

  //checking viewIds exists by checking viewIds length and also checking if allViews is exists in payload, if allViews given then ignoring the viewIds param
  if (viewIds.length && !payload.views.includes("allViews")) {
    //using the Set class for filtering duplicate viewId
    Array.from(new Set(viewIds)).forEach((viewId) => {
      let controller = new AbortController();
      contMSD[viewId] = controller;
      param[viewId] = {
        signal: controller.signal,
        param: createParamMSD({
          ticketAppliedData: undefined,
          activeListIndex: viewId,
          sidebarFiltersApplied: undefined,
        }),
      };
    });
  }
  let openId, closedId;
  getState().ticketSideBar?.ticketBtnData?.forEach((section: ITicketBtn) => {
    section?.ticketBtnList?.forEach((btn) => {
      btn?.ddOuterList?.forEach((dd) => {
        Object.values(dd?.ddList).forEach((status) => {
          if (status?.name === "Open") {
            openId = status.id;
          } else if (status?.name === "Closed") {
            closedId = status.id;
          }
        });
      });
    });
  });

  if (payload.views.includes("ai_handled_open")) {
    let controller = new AbortController();
    contMSD["ai_handled_open"] = controller;
    param[`ai_handled_open`] = {
      signal: controller.signal,
      param: createParamMSD({
        ticketAppliedData: undefined,
        activeListIndex: "ai_handled",
        sidebarFiltersApplied: {
          activeSidebarTicketStatusId: [openId],
        },
      }),
    };
  }
  if (payload.views.includes("ai_handled_closed")) {
    let controller = new AbortController();
    contMSD["ai_handled_closed"] = controller;
    param[`ai_handled_closed`] = {
      signal: controller.signal,
      param: createParamMSD({
        ticketAppliedData: undefined,
        activeListIndex: "ai_handled",
        sidebarFiltersApplied: {
          activeSidebarTicketStatusId: [closedId],
        },
      }),
    };
  }
  try {
    const sidebarTicketsCount = await getMultipleSidebarDataMultiAjax(param);
    return sidebarTicketsCount;
  } catch (error) {
    if (axios.isCancel(error)) {
      return rejectWithValue(true);
    } else {
      let ajaxError: AjaxError = {
        message: (error as Error).message,
      };
      throw ajaxError;
    }
  } finally {
    let controllers = getContMSD();
    Object.entries(param).forEach(([key, value]) => {
      if (controllers[key] !== undefined) {
        delete controllers[key];
      }
    });
    contMSD = controllers;
  }
}

async function fetchAddedViewSideBarDataThunk(
  payload: number | string,
  { getState, dispatch }: { getState: Function; dispatch: Function }
) {
  const viewData = await getUsersViewsById({
    viewId: parseInt(payload + ""),
  });
  return viewData;
}

async function refetchSideBarDataThunk(
  payload: undefined,
  { getState, dispatch }: { getState: Function; dispatch: Function }
) {
  try {
    const res: Array<ITicketsSB> = await getTicketSideBarApi(true);
    const ticketBtnData: Array<ITicketBtn> =
      getState().ticketSideBar.ticketBtnData;
    const ticketBtnDataTemp: Array<ITicketBtn> = res.map((data, key) => {
      const currentTicketBtnList = ticketBtnData[key];
      return {
        header: data.name,
        margin: key === 0 ? 5 : 4,
        paginate: false,
        ticketBtnList: data.content.map((ticketDivs, key1) => {
          const currentButton = currentTicketBtnList?.ticketBtnList.find(
            (button) =>
              button.keyName ===
              (ticketDivs.name === ESideBar.allTickets ? "all" : "my")
          );
          return {
            ticketBtnName: ticketDivs.name,
            ticketBtnCount: currentButton?.ticketBtnCount ?? 0,
            keyName: ticketDivs.name === ESideBar.allTickets ? "all" : "my",
            ddOuterList: ticketDivs.content.map((dropDowns, key2) => {
              const currentDropdown = currentButton?.ddOuterList.find(
                (currentDropdown) => currentDropdown.ddName === dropDowns.name
              );
              let keyTemp = 0;
              let dropdownMenuAllCount = currentDropdown
                ? (currentDropdown.ddList ?? [])[0]?.count ?? 0
                : 0;
              // console.log("dropDowns.content:: ", dropDowns.content);
              // console.log("dropdownMenuAllCount:: ", dropdownMenuAllCount);
              Object.keys(dropDowns.content).map((dropdownId: any) => {
                if (currentDropdown?.ddList[dropdownId])
                  dropDowns.content[dropdownId].count =
                    currentDropdown.ddList[dropdownId].count;
              });
              return {
                ddName: dropDowns.name,
                ddList: {
                  0: { name: "All", count: dropdownMenuAllCount },
                  ...dropDowns.content,
                },
                ddSelectedIndex: ticketBtnData[key]
                  ? ticketBtnData[key].ticketBtnList[key1]
                    ? ticketBtnData[key].ticketBtnList[key1].ddOuterList[key2]
                      ? ticketBtnData[key].ticketBtnList[key1].ddOuterList[key2]
                          .ddSelectedIndex
                      : [keyTemp]
                    : [keyTemp]
                  : [keyTemp],
              };
            }),
          };
        }),
      };
    });
    // const selectedBtn = getState().ticketSideBar.selectedBtn;
    // let viewById: ListView | null = null,
    // userViews: ListViewResponse | null = null;
    // try {
    //   if (selectedBtn.includes("viewId")) {
    //     viewById = await getUsersViewsById({
    //       viewId: parseInt(selectedBtn.split("::")[1]),
    //     });
    //   }
    //   userViews = await getUsersViews({
    //     start: 0,
    //     limit: getState().ticketSideBar.usersViewLimit,
    //   });
    // } catch {}
    // refetch the sidebar ticket count with global filters applied
    // setTimeout(() => {
    //   const [
    //     pathIndex,
    //     ticketPathStart,
    //     ticketPathId,
    //     ticketPathView,
    //     ticketPathSubStart,
    //     ticketPathSubId,
    //   ] = window.location.pathname.split("/");
    //   if (ticketPathStart !== "tickets" || ticketPathSubStart === "ticket") {
    //     dispatch(
    //       fetchMultipleSidebarData({
    //         views: [ticketPathSubStart === "ticket" ? ticketPathId : "all"],
    //       })
    //     );
    //   }
    // }, 0);
    return {
      ticketBtnData: ticketBtnDataTemp,
      // userViews,
      // viewById,
    };
  } catch (e) {
    throw e;
  }
}

export default {
  fetchSideBarData,
  fetchSideBarUsersViews,
  refreshSideBarUsersViews,
  fetchMultipleSidebarDataThunk,
  fetchAddedViewSideBarDataThunk,
  refetchSideBarDataThunk,
};
