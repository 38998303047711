import { useEffect, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import styles from "./ComponentStyles.module.scss";

function NumberInput(props: any) {
  const [showPopover, setShowPopover] = useState(true);

  useEffect(() => {
    if (props.value.error) {
      setShowPopover(true);
    } else {
      setShowPopover(false);
    }
  }, [props.value.error]);

  const popover =
    props.value.error && props.value.error.length > 0 ? (
      <Popover
        id="popover-basic"
        className="rounded-3"
      >
        <Popover.Body className="d-flex flex-row">
          <div className="border px-2 bg-warning text-white me-1 rounded-3 ">
            !
          </div>
          <div className="mt-1">{props.value.error}</div>
        </Popover.Body>
      </Popover>
    ) : (
      <></>
    );
  return (
    <>
      <OverlayTrigger
        show={showPopover}
        placement="bottom"
        overlay={popover}
        rootClose={true}
        rootCloseEvent="mousedown"
        onToggle={(isShown) => {
          // Set the state variable based on the popover visibility
          setShowPopover(isShown);
        }}
      >
        <div
          className={`d-flex flex-column ms-lg-2`}
          style={{
            maxWidth: "15%",
          }}
        >
          <input
            type="number"
            style={{
              border: "1px solid #707070",
              outline: "none",
            }}
            className={`w-100 px-2 ${styles.searchTagBoxStyling} ${
              props.value.error ? styles.borderDanger : ""
            } h-100 `}
            placeholder={props.placeholder}
            value={props.value.currentValue}
            onChange={(e) => props.onChange(e.target.value)}
          />
          <span className={styles.errorTextTop}>
            <span className={`max-content-width ${styles.errorText}`}>
              {props.value.error &&
                props.value.error.length > 0 &&
                props.value.error}
            </span>
          </span>
        </div>
      </OverlayTrigger>
    </>
  );
}

export default NumberInput;
