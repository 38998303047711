import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import styles from "../../LiveChatWidget.module.scss";
import shoppify from "src/assets/images/shopify.png";
import logo from "src/assets/images/hLogo.png";
import InstallButton from "../Buttons/InstallButton/InstallButton";
import DeleteButton from "../Buttons/DeleteButton/DeleteButton";
import LiveChatEnableToggle from "../LiveChatEnableToggle/LiveChatEnableToggle";
import SelectBox from "../OtherWebsite/Children/SelectBox";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { LabelComponent } from "../OtherWebsite/OtherWebsite";
import { Modal, Spinner } from "react-bootstrap";
import { useWidgetIntegrationUtility } from "../OtherWebsite/Children/CustomHook/useWidgetIntegrationUtility";
import { FetchUpdateWebIntParams } from "src/store/slices/liveChatSetting/websiteSettings/websiteSettings.thunks";
import {
  installShopifyWidget,
  updateTheWebIntegration,
} from "src/store/slices/liveChatSetting/websiteSettings/websiteSettings.slice";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { IShopifyStore } from "src/services/LiveChat/Settings/getLinkableShopifyStores";
import ToastModals from "../ToastModals/ToastModals";
import { InstallShopifyWidgetParam } from "src/services/LiveChat/Settings/website/installShopifyWidgetService";
interface Props {
  stores?: IShopifyStore[];
  storesLoading?: boolean;
}
function SelecteShopfyStore({ stores, storesLoading }: Props) {
  // Extracting `integrationId` from the URL parameters using React Router's `useParams` hook.
  const { integrationId } = useParams();

  // Using a custom hook `useAppSelector` to access Redux state, specifically the `websiteSettings` slice.
  const {
    activeWebIntAJAXStatus, // Status of the AJAX call for the active web integration.
    updateNewWebIntAJAXStatus, // Status of the AJAX call for updating a web integration.
    activeWebIntegration, // The currently active web integration details.
    lcInstallationStatus, // Status of the live chat installation.
    sendWidgetScriptEmailAJAXStatus, // Status of the AJAX call for sending the widget script via email.
  } = useAppSelector((state) => state.websiteSettings);

  // State to store the selected brand's email, initialized with the email from the active web integration or an empty string.
  const [choosedBrandEmail, setChoosedBrandEmail] = useState(
    activeWebIntegration.brand?.email
      ? activeWebIntegration.brand.email + ""
      : "",
  );

  // State to store the selected store, initialized as null. The store object includes a value and a label.
  const [selectedStore, setSelectedStore] = useState<{
    value: string | number;
    label: JSX.Element;
  } | null>(null);

  // Custom hook to dispatch actions to the Redux store.
  const dispatch = useAppDispatch();

  // Custom hook `useWidgetIntegrationUtility` provides utility functions and state for widget integration.
  // It is initialized with the `integrationId` obtained from the URL parameters.
  const {
    checkInstallation, // Function to check the installation status.
    deleteLoading, // Boolean state indicating if the delete operation is in progress.
    handleDelete, // Function to handle the deletion of an integration.
    setDeleteLoading, // Function to set the `deleteLoading` state.
    setShowDeleteModal, // Function to show/hide the delete confirmation modal.
    showDeleteModal, // Boolean state indicating if the delete confirmation modal is visible.
    installLoading, // Boolean state indicating if the install operation is in progress.
    setInstallLoading, // Function to set the `installLoading` state.
    showModal, // Boolean state indicating if any modal is visible.
    setShowModal, // Function to show/hide modals.
  } = useWidgetIntegrationUtility({
    integrationId: integrationId,
  });

  // useMemo hook to transform stores data into a format suitable for the Select component
  const options = useMemo(() => {
    return stores?.map((store) => ({
      value: store.storeUrl, // The value to be returned when an option is selected
      label: (
        // JSX for rendering the option label with an image and text
        <>
          <div className={`d-flex align-items-center`}>
            <img
              src={shoppify}
              alt=""
            />
            <span className={`ms-1 ${styles.milkshakes}`}>
              {store.displayStoreUrl}
            </span>
          </div>
        </>
      ),
    }));
  }, [stores]); // Dependency array to recompute if stores change

  // State to track if live chat is enabled
  const [enabled, setEnabled] = useState(
    activeWebIntegration.live_chat_enabled ?? false,
  );

  // State to control the visibility of the installation loader
  const [installLoader, setInstallLoader] = useState(false);

  // State to manage validation states for web URL and brand choice
  const [showValidation, setShowValidation] = useState<{
    webURL?: boolean;
    choosedBrand?: boolean;
  }>({
    webURL: false,
    choosedBrand: false,
  });

  // State to store the chosen brand ID
  const [choosedBrandId, setChoosedBrandId] = useState(
    activeWebIntegration.brand?.id ? activeWebIntegration.brand.id + "" : "",
  );

  // useRef to keep track of the current state without causing re-renders
  const currentState = useRef({
    activeWebIntegration,
    integrationId,
    brandsFetched: false,
  });

  // useMemo hook to determine if the current selections have been updated
  const isUpdated = useMemo(() => {
    return (
      activeWebIntegration?.store_url === selectedStore?.value &&
      activeWebIntegration?.brand?.id == choosedBrandId //&&
      // Both of these will be updated via toggle itself and hence are not a dependency for save button
    );
  }, [
    activeWebIntegration?.store_url,
    activeWebIntegration?.brand,
    selectedStore,
    choosedBrandId,
  ]);

  // useCallback hook for handling data update logic
  const updateData = useCallback(() => {
    if (integrationId && selectedStore && choosedBrandId) {
      // Constructing payload for updating web integration
      const payload: FetchUpdateWebIntParams = {
        integration_id: integrationId,
        live_chat_integration_type: "shopify",
      };
      // Conditionally adding URL to payload if it has changed
      if (
        selectedStore?.value &&
        activeWebIntegration.store_url !== selectedStore?.value
      ) {
        payload.url = selectedStore.value + "";
      }
      // Conditionally adding brand ID to payload if it has changed
      if (activeWebIntegration.brand?.id != choosedBrandId) {
        payload.brand_id = choosedBrandId;
      }
      // Resetting validation states
      setShowValidation({
        webURL: false,
        choosedBrand: false,
      });

      // Dispatching the update action
      dispatch(updateTheWebIntegration(payload));
    } else if (!selectedStore) {
      // Handling validation for invalid website URL
      setShowValidation({
        webURL: true,
        choosedBrand: false,
      });
    } else if (!choosedBrandId) {
      // Handling validation for unselected brand
      setShowValidation({
        webURL: false,
        choosedBrand: true,
      });
    } else {
      // Handling case where all fields are empty
      pushTheToast({
        type: "warning",
        text: "Please add valid entries!",
        position: "top-right",
      });
    }
  }, [integrationId, enabled, selectedStore, choosedBrandId]);

  const handleInstall = useCallback(() => {
    if (integrationId && selectedStore && choosedBrandId) {
      const payload: InstallShopifyWidgetParam = {
        integrationId: integrationId,
      };
      setShowValidation({
        webURL: false,
        choosedBrand: false,
      });
      setInstallLoader(true);
      dispatch(installShopifyWidget(payload))
        .then((res) => {
          if ((res?.payload as any)?.data?.url) {
            window.open((res?.payload as any)?.data?.url, "_blank");
          }
        })
        .finally(() => {
          setInstallLoader(false);
        });
    } else if (!selectedStore) {
      // if website url is invalid
      setShowValidation({
        webURL: true,
        choosedBrand: false,
      });
    } else if (!choosedBrandId) {
      // if brand is not selected
      setShowValidation({
        webURL: false,
        choosedBrand: true,
      });
    } else {
      // if all fields are empty
      pushTheToast({
        type: "warning",
        text: "Please add valid entries!",
        position: "top-right",
      });
    }
  }, [integrationId, selectedStore, enabled, choosedBrandId]);

  useEffect(() => {
    if (activeWebIntegration.store_url) {
      setSelectedStore({
        value: activeWebIntegration.store_url + "",
        label: (
          <>
            <div className={`d-flex align-items-center`}>
              {" "}
              <img
                src={shoppify}
                alt=""
              />
              <span className={`ms-1 ${styles.milkshakes}`}>
                {activeWebIntegration.displayStoreUrl}
              </span>{" "}
            </div>
          </>
        ),
      });
    }
  }, [activeWebIntegration.store_url]);

  useEffect(() => {
    currentState.current = {
      activeWebIntegration,
      integrationId,
      brandsFetched: currentState.current.brandsFetched,
    };
  }, [activeWebIntegration, integrationId]);

  useEffect(() => {
    if (
      activeWebIntegration.check_installation_success ||
      lcInstallationStatus !== "pending"
    ) {
      setInstallLoading(false);
    }
  }, [activeWebIntegration.check_installation_success, lcInstallationStatus]);

  useEffect(() => {
    if (activeWebIntegration.installed_successfully === false) {
      setShowModal("");
    }
  }, [activeWebIntegration.installed_successfully]);

  return (
    <>
      <div>
        <div className="mb-3">
          {/* <Form.Select
          aria-label="Default select example"
          bsPrefix={`${styles.selectDay}`}
        >
          <option value="1">
             <img src={shoppify} alt="" />
              <span className="ms-1">milkshakes.myshopify.com</span> 
            
          </option>
          <option value="2">waffles.myshopify.com</option>
          <option value="3">pancakes.myshopify.com</option>
        </Form.Select> */}
          <div className="d-flex justify-content-between flex-wrap">
            <div>
              <p className={`${styles.chosePara}`}>
                {" "}
                Choose a store you want to install helplama live chat widget
              </p>
            </div>
            <div>
              <LiveChatEnableToggle
                liveChatType="shopify"
                disabled={
                  !(
                    currentState.current.activeWebIntegration
                      .installed_successfully &&
                    currentState.current.activeWebIntegration
                      .check_installation_success
                  )
                }
                enabled={enabled}
                integrationId={integrationId}
                type="live_chat_enabled"
                onChange={(value: boolean) => setEnabled(value)}
              />
            </div>
          </div>
          <Select
            isDisabled={
              isUpdated ||
              currentState.current.activeWebIntegration
                .check_installation_success === true ||
              currentState.current.activeWebIntegration
                .installed_successfully === true
            }
            value={selectedStore}
            options={options}
            className={`${styles.selItem}`}
            onChange={(value) => {
              setSelectedStore(value);
            }}
            placeholder={"Select Shopify Store"}
            isLoading={storesLoading}
            styles={
              //checking if validation error is true if yes then showing red border
              showValidation.webURL
                ? {
                    ...customStyles,
                    control: (provided: any, _: any) => ({
                      ...provided,
                      border: "1px solid #A70B0B",
                      borderRadius: "6px",
                    }),
                  }
                : customStyles
            }
          />
          <span
            className={`${
              showValidation.webURL ? styles.validationText : "d-none"
            }`}
          >
            Please select shopify store!
          </span>
        </div>
        <div>
          <p className={`${styles.integrate}`}>
            Make sure you have integrated your shopify store to our helpdesk .
            If you shopify store doesn't appear here.
            <span>
              <Link
                to="/settings/integrations/shopify"
                className={`${styles.link}`}
              >
                Integrate Shopify
              </Link>
            </span>
          </p>
        </div>
        <div className={`mb-3 `}>
          <p className={`${styles.chosePara}`}>Choose a brand</p>
          <SelectBox
            name="from"
            integrationId={integrationId}
            currentValue={choosedBrandEmail}
            getIdValue={(value) => {
              setChoosedBrandId(value);
              setShowValidation({
                choosedBrand: false,
              });
            }}
            isDisabled={
              isUpdated ||
              currentState.current.activeWebIntegration
                .check_installation_success === true ||
              currentState.current.activeWebIntegration
                .installed_successfully === true
            }
            getValue={(value) => {
              setChoosedBrandEmail(value);
            }}
            isMulti={false}
            LabelComponent={LabelComponent}
            placeholder={"Select Brand"}
            initialFetchDone={() => {
              // currentState.current.brandsFetched = true;
              // setChoosedBrandEmail(
              //   currentState.current.activeWebIntegration.brand?.email
              //     ? currentState.current.activeWebIntegration.brand.email + ""
              //     : "",
              // );
            }}
            validationError={showValidation.choosedBrand}
          />
          <span
            className={`${
              showValidation.choosedBrand ? styles.validationText : "d-none"
            }`}
          >
            Please select a brand!
          </span>
          <div>
            {(activeWebIntegration.embedded_script !== null) === true ? (
              <></>
            ) : (
              <p className={`mt-2 ${styles.integrate}`}>
                Helplama live chat widget will be linked to the selected brand.
                If you want to create a brand click here.
                <Link
                  target="_blank"
                  to="/settings/Brands"
                  className={`ms-1 ${styles.link}`}
                >
                  <span>
                    Manage Brand
                    <span>
                      <i className="fa-solid fa-up-right-from-square ms-1"></i>
                    </span>
                  </span>
                </Link>
              </p>
            )}
          </div>
        </div>
        <div className={`d-flex justify-content-end mt-2`}>
          {activeWebIntegration.store_url?.trim() ? (
            <DeleteButton onClick={(e: any) => setShowDeleteModal(true)} />
          ) : (
            <></>
          )}
          <button
            className={`${styles.checkInstallation} d-flex mt-md-0`}
            disabled={
              (activeWebIntegration.installed_successfully &&
                activeWebIntegration.check_installation_success) ||
              activeWebIntAJAXStatus === "pending" ||
              updateNewWebIntAJAXStatus === "pending" ||
              installLoader
            }
            onClick={() => {
              if (!isUpdated && !activeWebIntegration.installed_successfully) {
                updateData();
              } else if (
                isUpdated &&
                !activeWebIntegration.installed_successfully
              ) {
                handleInstall();
              } else if (
                !(
                  activeWebIntegration.installed_successfully &&
                  activeWebIntegration.check_installation_success
                )
              ) {
                checkInstallation();
                if (integrationId && activeWebIntegration.store_url) {
                  window.open(activeWebIntegration.store_url, "_blank");
                }
              }
            }}
          >
            {(installLoader || updateNewWebIntAJAXStatus === "pending") && (
              <Spinner
                animation="border"
                size="sm"
                className="my-auto mx-1"
              />
            )}
            <span className="d-inline-block my-auto mx-1">
              {!isUpdated && !activeWebIntegration.installed_successfully
                ? "Save"
                : isUpdated && !activeWebIntegration.installed_successfully
                ? "Install"
                : activeWebIntegration.installed_successfully &&
                  activeWebIntegration.check_installation_success
                ? "Installed"
                : "Check Installation"}
            </span>
          </button>
        </div>
      </div>
      <ToastModals
        showModal={showModal}
        setShowModal={setShowModal as any}
        loading={installLoading}
        success={!!activeWebIntegration.check_installation_success}
        callAgain={checkInstallation}
      />
      <Modal
        backdropClassName={`${styles.modalBack}`}
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        dialogClassName={`${styles.modalDialog}`}
        contentClassName={`${styles.modalContent}`}
        // centered={true}
        enforceFocus={false}
      >
        <div className={`container`}>
          <div className={`row`}>
            <div className={`col-12`}>
              <div className="text-center">
                <p className={`${styles.delPara}`}>
                  Are you sure you want to delete this integration?
                </p>
                <span className={`${styles.delSpan}`}>
                  All associated views and rules will be deleted
                </span>
                <div
                  className={`mt-2 pt-2 d-flex justify-content-center align-items-center flex-column flex-md-row`}
                >
                  <button
                    className={`me-md-4 ${styles.cancelBtn}`}
                    onClick={() => setShowDeleteModal(false)}
                  >
                    Cancel
                  </button>

                  <button
                    className={`mt-2 mt-md-0 ${styles.confirmBtn}`}
                    onClick={handleDelete}
                    disabled={deleteLoading}
                  >
                    {deleteLoading === true ? (
                      <Spinner
                        className="my-auto mx-1"
                        animation="border"
                        color="white"
                        size="sm"
                      />
                    ) : (
                      "Confirm"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
const customStyles = {
  control: (provided: any, _: any) => ({
    ...provided,
    background: "#f5f5f5 0% 0% no-repeat padding-box",
    borderColor: "#9e9e9e",
    minHeight: "48px",
    width: "100%",
    border: "1px solid transparent",
    borderRadius: "13px",
    color: "#000000",
    cursor: "pointer",
    paddingLeft: "5px",
    "&:active": {
      border: "1px solid #000000",
    },
  }),

  valueContainer: (provided: any, _: any) => ({
    ...provided,
    minHeight: "48px",
    padding: "0 6px",
  }),
  placeholder: (base: any) => ({
    ...base,
    font: "normal normal normal 14px/21px Poppins",
    letterSpacing: " 0px",
    color: "#6E6E6E",
  }),

  input: (provided: any, _: any) => ({
    ...provided,
    margin: "0px",
    font: "normal normal normal 14px/21px Poppins;",
  }),
  indicatorsContainer: (provided: any, _: any) => ({
    ...provided,
    minHeight: "48px",
    padding: "0px !important",
    marginRight: "6px",
  }),
  dropdownIndicator: (provided: any, _: any) => ({
    ...provided,
    padding: "0px !important",
  }),
  MenuPortal: (provided: any, _: any) => ({
    ...provided,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 16px 48px #00000029",
    borderRadius: "10px",
    border: "1px solid #fff",
    font: "normal normal normal 14px/21px Poppins",
    letterSpacing: "0px",
    color: "#000000",
  }),
  option: (provided: any, _: any) => ({
    ...provided,
    textAlign: "left",
    font: "normal normal normal 14px/21px Poppins",
    letterSpacing: "0px",
    color: "#000000",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    padding: "6px 12px",
    cursor: "pointer",
    "&:hover": {},
  }),
  clearIndicator: (provided: any, _: any) => ({
    ...provided,
    padding: "0px !important",
  }),
  indicatorSeparator: (provided: any, _: any) => ({
    ...provided,
    marginBottom: "4px",
    marginTop: "4px",
    width: "1px",
    boxSizing: "border-box",
    marginRight: "6px",
  }),

  multiValueLabel: (provided: any, _: any) => ({
    ...provided,
    padding: "0",
    textAlign: "left",
    font: "normal normal normal 14px/21px Poppins",
    letterSpacing: "0px",
    color: "#000000",
    opacity: "1",
  }),
  multiValue: (provided: any, _: any) => ({
    ...provided,
    paddingRight: "2px",
    background: "unset",
    font: "normal normal normal 14px/21px Poppins",
    letterSpacing: "0px",
    color: " #000000",
  }),
};
export default SelecteShopfyStore;
