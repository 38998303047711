import { axiosJSON } from "src/globals/axiosEndPoints";

export interface LiveChatHistoryChatsPayoad {
  customerId: number | string;
  start?: number;
  limit?: number;
}

export interface HistoryCustomerChat {
  chatRequestId: number;
  customerName?: string;
  customerEmail: string;
  customerQuery?: string;
  requestedAt?: string;
  lastMessage?: string;
  lastMessageAt?: string;
  ticketData?: any;
}

export interface LiveChatHistoryChatsData {
  chatList: { [chatRequestId: number | string]: HistoryCustomerChat };
  chatListIds: Array<number | string>;
  metaData: {
    count: number;
    totalCount: number;
  };
}

const getLiveChatHistoryChats = async (payload: LiveChatHistoryChatsPayoad) => {
  const { data: res } = await axiosJSON.post(
    "/api/liveChat/chat/history",
    payload
  );
  if (res.err) {
    throw res.msg;
  }
  const ret: LiveChatHistoryChatsData = {
    chatList: {},
    chatListIds: [],
    metaData: res.metaData,
  };
  for (let i = 0; i < res.data.length; i++) {
    const chat = res.data[i];
    ret.chatList[chat.chatRequestId] = chat;
    ret.chatListIds.push(chat.chatRequestId);
  }
  return ret;
};

export default getLiveChatHistoryChats;
