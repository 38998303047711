import React, { useState } from "react";
import { useAppSelector } from "src/store/store";
import AddTagModal from "../AddTag/AddTagModal/AddTagModal";
import DeleteTagModal from "./DeleteTagModal/DeleteTagModal";
import styles from "./TagRow.module.scss";

function TagRow({ tagData }: any) {
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDeleteTagModal, setShowDeleteTagModal] = useState<boolean>(false);
  const scopes = useAppSelector(
    (state) => state.globals.currentUserData?.scopes
  );
  const handleEditTag = (e: any) => {
    setShowEditModal(!showEditModal);
  };

  const handleDeleteTag = (e: any) => {
    setShowDeleteTagModal(!showDeleteTagModal);
  };

  return (
    <div className="d-flex  justify-content-between mt-3 w-100">
      <div className={`${styles.tagName}`}>
        {tagData.tagName.trim() ? tagData.tagName : "NA"}
      </div>
      <div className={`${styles.tagDesc}`}>
        {tagData.tagDescription.trim() ? tagData.tagDescription : "NA"}
      </div>
      <div className={`d-flex align-items-center ${styles.tagAction}`}>
        {scopes && scopes.includes("add_tags") && (
          <>
            <i
              className="fa-solid fa-pen me-3 "
              role="button"
              onClick={(e) => {
                handleEditTag(e);
              }}
            ></i>
            {/* <i
              className="fa-solid fa-trash pe-3"
              role="button"
              onClick={(e) => {
                handleDeleteTag(e);
              }}
            ></i> */}
            <div className={`${styles.listDelete}`}>
            
              <i
                className="material-icons m-auto"
                role="button"
                onClick={(e) => {
                  handleDeleteTag(e);
                }}
              >
                &#xe872;
              </i>
           
            </div>

            {showEditModal === true ? (
              <AddTagModal
                showModal={showEditModal}
                setShowModal={setShowEditModal}
                editTagData={tagData}
              />
            ) : undefined}

            {showDeleteTagModal === true ? (
              <DeleteTagModal
                showDeleteTagModal={showDeleteTagModal}
                setShowDeleteTagModal={setShowDeleteTagModal}
                tagData={tagData}
              />
            ) : undefined}
          </>
        )}
      </div>
    </div>
  );
}

export default TagRow;
