import { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./ChatPage.module.scss";
import copy from "copy-to-clipboard";

const ChatPage = () => {
  const [copyText, setCopyText] = useState("https://direct.lc.chat/14041023/");
  const [changeText, setChangeText] = useState(false);
  const handleCopyText = (e: any) => {
    setCopyText(e.target.value);
  };
  const copyToClipboard = () => {
    copy(copyText);
    // console.log(copyText);
    setChangeText(true);
  };
  return (
    <div className={` p-2 p-lg-4 ${styles.chatPage}`}>
      <h2 className={`${styles.chatPageHead}`}>Chat Page</h2>
      <p className={`${styles.chatPageSub}`}>
        Share your chat page link so customers can easily contact your business
        wherever they are.{" "}
        <span className={`${styles.link}`}>
          {" "}
          <Link to="/">Test it out</Link>
        </span>
      </p>
      <p className={`${styles.weblink}`}>www.oberlo.in</p>
      <div className={`${styles.linkDiv}`}>
        <input
          data-lpignore="true"
          className={`${styles.inputLink}`}
          type={`text`}
          value={copyText}
          onChange={handleCopyText}
        />
        <button className={`${styles.copyBtn}`} onClick={copyToClipboard}>
          {changeText ? "Copied !" : "Copy Link"}
        </button>
      </div>
    </div>
  );
};
export default ChatPage;
