import { useCallback } from "react";
import { NavLink } from "react-router-dom";
import { SelectButtonDataType } from "src/features/ReturnAutoWorkFlow/ReturnAutoWorkFlow.types";
import styles from "./SelectButtons.module.scss";
import shopifyImg from "src/assets/images/browseShop.png";
import bigCommerce from "src/assets/images/bigCommerce.png";
import wooCommerce from "src/assets/images/wooCommerce.png";
import magento from "src/assets/images/magento.png";

/**
 * A React component for rendering a selectable button.
 *
 * @param option - The data for the button including its value and disabled status.
 * @param isSelected - A boolean indicating whether the button is selected.
 * @param onChange - A function to handle the button click event.
 */
function SelectButton({
  option,
  isSelected,
  onChange,
}: {
  option: SelectButtonDataType;
  isSelected: boolean;
  onChange: React.MouseEventHandler<HTMLElement>;
}) {
  return (
    <button
      data-value={option.value}
      data-selected={isSelected}
      onClick={onChange}
      disabled={option.disabled}
      className={`px-2 me-2 mb-2 ${styles.sourceBtn}`}
    >
      {option.buttonName !== "automateWithoutInt" && (
        <img
          src={
            option.buttonName === "wooCommerce"
              ? wooCommerce
              : option.buttonName === "bigCommerce"
                ? bigCommerce
                : option.buttonName === "magento"
                  ? magento
                  : shopifyImg
          }
          alt=""
          height={21}
          className="pe-1"
        />
      )}
      <span className={`${styles.sourceName}`}>{option.label}</span>
    </button>
  );
}

/**
 * A React component for rendering a group of selectable buttons.
 *
 * @param options - An array of options for the buttons.
 * @param value - The currently selected value(s).
 * @param onChange - A function to handle the change in selection.
 * @param isMulti - A boolean indicating whether multiple selections are allowed.
 */
function SelectButtons({
  options,
  value,
  onChange,
  isMulti = false,
  showLink,
}: {
  options: Array<SelectButtonDataType>;
  value: null | string | Array<string>;
  onChange: (value: string | Array<string>) => void;
  isMulti?: boolean;
  showLink: boolean;
}) {
  /**
   * Checks if a given value is selected.
   *
   * @param v - The value to check.
   * @returns A boolean indicating if the value is selected.
   */
  const isSelected = useCallback(
    (v: string) => {
      return isMulti
        ? ((value as Array<string>) ?? [])
            .map((val) => val + "")
            .includes(v + "")
        : value
          ? value + "" === v + ""
          : false;
    },
    [value, isMulti],
  );

  /**
   * Handles the button click event and updates the selection state.
   *
   * @param e - The mouse event from the button click.
   */
  const handleOnChange: React.MouseEventHandler<HTMLElement> = useCallback(
    (e) => {
      const ele = e.currentTarget as HTMLElement;
      const thisValue = ele?.getAttribute("data-value");
      if (isMulti) {
        const valueStr = ((value as Array<string>) ?? []).map(
          (val) => val + "",
        );
        if (thisValue) {
          if (valueStr.includes(thisValue)) {
            onChange(valueStr.filter((v) => v !== thisValue));
          } else {
            onChange([...valueStr, thisValue]);
          }
        }
      } else {
        if (thisValue && value !== thisValue) {
          onChange(thisValue);
        }
      }
    },
    [value, isMulti, onChange],
  );

  return (
    <div>
      <div className="d-flex flex-wrap me-5 pe-4">
        {options.map((option) => {
          return (
            <SelectButton
              key={option.value}
              option={option}
              isSelected={isSelected(option.value)}
              onChange={handleOnChange}
            />
          );
        })}
      </div>
      {showLink && (
        <div>
          <span className={`${styles.configureText}`}>
            {
              "To use any of the above integrations which are not available, please configure them "
            }
            <a
              href="/settings/browse-integration"
              className={`${styles.link}`}
              target="_blank"
            >
              here
            </a>
            {"."}
          </span>
        </div>
      )}
    </div>
  );
}

export default SelectButtons;
