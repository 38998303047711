import { createAsyncThunk } from "@reduxjs/toolkit";
import getNonEligibleOrdersService, {
  NonEligibleOrdersTab,
} from "src/services/ReturnAutoWorkFlow/OldModals/nonEligibleOrders/getNonEligibleOrders.service";
import getNonEligibleOrdersConditionsService from "src/services/ReturnAutoWorkFlow/OldModals/nonEligibleOrders/getNonEligibleOrdersConditions.service";
import updateNonEligibleOrdersService from "src/services/ReturnAutoWorkFlow/OldModals/nonEligibleOrders/updateNonEligibleOrders.service";
import { IStepQuestion } from "src/store/slices/initiateReturn/retrunExchange.slice";
import { getValueFromConditionType } from "src/features/ReturnAutoWorkFlow/children/ConfigModals/store/returnModalsSliceHelpers";
import { RootState } from "src/store/store";
import { SelectedConditionType } from "../../../oldReturnModals.types";
import { ReturnModalsThunkPayload } from "../../returnModalsStore";
import { validateNonEligibleOrder } from "./nonEligibleOrders.slice";

export const fetchNonEligibleOrdersThunk = createAsyncThunk(
  "fetchNonEligibleOrders",
  async (payload: {
    integrationId: string;
    selectedReturnWindow: IStepQuestion["value"];
  }) => {
    const integrationId = payload.integrationId;

    const [conditionOptionRes, configRes] = await Promise.all([
      getNonEligibleOrdersConditionsService({ integrationId }),
      getNonEligibleOrdersService({ integrationId }),
    ]);

    const defaultReturnWindow = {
      id: "",
      ruleType: "AND",
      variableName: "Order Date",
      values: payload.selectedReturnWindow,
      operator: "within",
    };

    return { configRes, conditionOptionRes, defaultReturnWindow };
  },
);

interface UpdateNonEligibleOrdersPayload extends ReturnModalsThunkPayload {
  integrationId: string;
  selectedReturnWindow: IStepQuestion["value"];
}

export const updateNonEligibleOrdersThunk = createAsyncThunk(
  "updateNonEligibleOrders",
  async (payload: UpdateNonEligibleOrdersPayload, { getState }) => {
    const state = getState() as RootState;

    const integrationId = payload.integrationId;
    const orderTypes = state.nonEligibleOrders.step9Configuration.orderTypes;

    const configTabs = orderTypes.map((orderType) => {
      const operatorSymbolMap =
        state.nonEligibleOrders.step9Configuration.operatorSymbolMap;
      const conditionType =
        Object.entries(
          state.nonEligibleOrders.step9Configuration.variableNames,
        ).find(
          ([key, variable]: any) =>
            variable === orderType.returnWindow?.variableName,
        ) ?? "";
      return {
        configTabId: Number(orderType.orderTypeId),
        configTabName: orderType.orderTypeName,
        notEligibleResponseMessage: orderType.messageForCustomer.value,
        conditions: orderType.conditions.map((condition) => {
          const conditionType =
            Object.entries(
              state.nonEligibleOrders.step9Configuration.variableNames,
            ).find(
              ([key, variable]: any) => variable === condition.variableName,
            ) ?? "";

          return {
            conditionId: Number(condition.id),
            conditionTypeId:
              conditionType !== "" ? conditionType[0] : conditionType,
            conditionValue: getValueFromConditionType(condition.values),
            operator: Object.keys(operatorSymbolMap).find((key) => {
              if (
                (condition.operator === "are less than" ||
                  condition.operator === "is less than") &&
                key === "<"
              ) {
                return true;
              }
              return operatorSymbolMap[key] === condition.operator;
            }),
          } as SelectedConditionType;
        }),
        returnWindow: {
          conditionId: 0,
          conditionTypeId: conditionType !== "" ? conditionType[0] : "",
          conditionValue: getValueFromConditionType(
            orderType.returnWindow?.values,
          ),
          operator: Object.keys(operatorSymbolMap).find((key) => {
            if (
              (orderType.returnWindow?.operator === "are less than" ||
                orderType.returnWindow?.operator === "is less than") &&
              key === "<"
            ) {
              return true;
            }
            return operatorSymbolMap[key] === orderType.returnWindow?.operator;
          }),
        } as SelectedConditionType,
      } as NonEligibleOrdersTab;
    });

    await updateNonEligibleOrdersService({ integrationId, configTabs });

    setTimeout(() => {
      if (payload.callback) {
        payload.callback();
      }
    }, 0);

    // Refetch the data
    const configRes = await getNonEligibleOrdersService({ integrationId }); // async action

    const defaultReturnWindow = {
      id: "",
      ruleType: "AND",
      variableName: "Order Date",
      values: payload.selectedReturnWindow,
      operator: "within",
    };

    return { configRes, defaultReturnWindow };
  },
);

export const validateNonEligibleOrders = createAsyncThunk(
  "validateNonEligibleOrders",
  async (_: void, { getState, dispatch }) => {
    await dispatch(validateNonEligibleOrder());

    const state = getState() as RootState;

    let errorCount = 0;
    state.nonEligibleOrders.step9Configuration.orderTypes.forEach(
      (orderType) => {
        if (orderType.errorCount !== 0) {
          errorCount += 1;
        }
      },
    );

    return errorCount;
  },
);
