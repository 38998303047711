import {
  AudienceConfigCondition,
  botProfilesSlice,
  initialState,
} from "./botProfiles.declarations";
import { v4 as uuid } from "uuid";

const resetBotProfiles = () => {
  return initialState;
};

const addAudienceConfigCondition = (state: botProfilesSlice) => {
  let newCondition: AudienceConfigCondition = {
    id: uuid(),
    ruleType: "AND",
    conditionKey: null,
    values: null,
    operator: undefined,
    isNewlyAdded: true,
  };
  // Adding the new condition to the existing list of conditions
  state.audienceConfigConditions.push(newCondition);
  return state;
};

const removeAudienceConfigCondition = (
  state: botProfilesSlice,
  action: { type: string; payload: string }
) => {
  // Removing the specified condition from the list of conditions
  const updatedConditions = state.audienceConfigConditions.filter(
    (cond) => cond.id !== action.payload
  );

  let newCondition: AudienceConfigCondition = {
    id: uuid(),
    ruleType: "AND",
    conditionKey: null,
    values: null,
    operator: undefined,
    isNewlyAdded: true,
  };

  if (updatedConditions.length === 0) {
    updatedConditions.push(newCondition);
  }
  state.audienceConfigConditions = updatedConditions;
  return state;
};

const updateAudienceConfigCondition = (
  state: botProfilesSlice,
  action: {
    type: string;
    payload: {
      conditionId: string;
      properties: Partial<AudienceConfigCondition>;
    };
  }
) => {
  // Updating the specified properties of the condition in the list of conditions
  const updatedConditions = state.audienceConfigConditions.map((cond) => {
    if (cond.id === action.payload.conditionId) {
      console.log(cond);
      return {
        ...cond,
        ...action.payload.properties,
      };
    }
    return cond;
  });

  state.audienceConfigConditions = updatedConditions;

  return state;
};

const resetAudienceConfigCondition = (state: botProfilesSlice) => {
  state.audienceConfigConditions = [];
  return state;
};

const setBackButtonRedirectPath = (
  state: botProfilesSlice,
  action: {
    type: string;
    payload: {
      path: string;
    };
  }
) => {
  state.backRedirectPath = action.payload.path;
};
export default {
  resetBotProfiles,
  addAudienceConfigCondition,
  updateAudienceConfigCondition,
  removeAudienceConfigCondition,
  resetAudienceConfigCondition,
  setBackButtonRedirectPath,
};
