import React, { useCallback, useState } from "react";
// import { Modal } from "react-bootstrap";
// import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
// import { addTag, AddTagParams } from "src/services/Settings/Tags/addTag";
// import { fetchAllTheTags, resetTagsSettings } from "src/store/slices/settings/tags/tagsSettings.slice";
// import { useAppDispatch } from "src/store/store";
import styles from "./AddTag.module.scss";
import AddTagModal from "./AddTagModal/AddTagModal";

function AddTag() {
  // const dispatch = useAppDispatch();
  const [showModal, setShowModal] = useState(false);
  // const [tagName, setTagName] = useState<string>("");
  // const [tagDescription, setTagDescription] = useState<string>("");

  const onShow = useCallback(() => {
    setShowModal(true);
  }, []);
  // const onHide = useCallback(() => {
  //   setTagName("");
  //   setTagDescription("");
  //   setShowModal(false);
  // }, []);

  /* Function to handle tag name change */
  // const hanldeTagNameChange = (e: any) => {
  //   e.preventDefault();
  //   setTagName(e.target.value);
  // }

  /* Function to handle tag description change */
  // const hanldeTagDescChange = (e: any) => {
  //   e.preventDefault();
  //   setTagDescription(e.target.value);
  // }

  /* Function to handle add tag submit*/
  // const hanldeAddTagSubmit = (e: any) => {
  //   e.preventDefault();
  //   if(tagName.length > 0){
  //     const addTagParams: AddTagParams = {
  //       name: tagName.trim(),
  //       description: tagDescription.trim()
  //     };
  //     addTag(
  //       addTagParams
  //     ).then((res) => {
  //       dispatch(resetTagsSettings());
  //       dispatch(fetchAllTheTags())
  //       onHide();
  //       pushTheToast({
  //         type: "success",
  //         text: "Tag added successfully!",
  //         position: "top-right"
  //       });
  //     }).catch((err) => {
  //       pushTheToast({
  //         type: "danger",
  //         text: "Failed to add tag!",
  //         position: "top-right"
  //       });
  //     });
  //   }else{
  //     pushTheToast({
  //       type: "warning",
  //       text: "Tag name is required!",
  //       position: "top-right"
  //     });
  //   }
  // }

  return (
    <div className={`${styles.add_user} pe-5 mt-2 mt-md-0 `}>
      <button
        type="submit"
        className={`pink-btn-submit d-flex justify-content-center align-items-center ${styles.btn} ${styles.pink_btn_submit}`}
        onClick={onShow}
      >
        Add a Tag
      </button>
      {/* <Modal
        show={showModal}
        onHide={onHide}
        centered={true}
        contentClassName={`${styles.modalContent}`}
      >
        <div className="">
          <div>
            <div>
              <div
                className={`${styles.header} d-flex  justify-content-between mx-4 mt-4 mb-2`}
              >
                <h5 className="m-2">Add a Tag</h5>
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close "
                  className={`${styles.closeButton} btn-close ms-2 me-3 mt-2`}
                  onClick={onHide}
                >
                  {" "}
                </button>
              </div>
            </div>
          </div>

          <div>
            <div className="mt-4">
              <div className="col-12">
                <form className="team-form">
                  <div className="mx-4 my-2">
                    <label
                      htmlFor="exampleInputPassword1"
                      className={`${styles.formLabel} form-label fw-bold  ms-2`}
                    >
                      {" "}
                      Tag Name
                    </label>
                    <div className="input-box d-flex align-items-center">
                      <input data-lpignore="true" 
                        type="text"
                        placeholder="Name"
                        value={tagName}
                        onChange={(e) => hanldeTagNameChange(e)}
                        className="form-control ms-2"
                        id="teamName"
                      />
                    </div>
                  </div>

                  <div className="mx-4 mt-3">
                    <label
                      htmlFor="exampleInputPassword1"
                      className={`${styles.formLabel} form-label fw-bold ms-2`}
                    >
                      {" "}
                      Description (Optional)
                    </label>
                    <div className="input-box d-flex align-items-center">
                      <input data-lpignore="true" 
                        type="text"
                        placeholder="Description"
                        className="form-control ms-2"
                        id="teamName"
                        value={tagDescription}
                        onChange={(e) => hanldeTagDescChange(e)}
                      />
                    </div>
                  </div>

                  <div className="d-flex flex-row-reverse mt-4 mb-3 me-3 p-2 ">
                    <div className="add-user add-team ">
                      <button
                        type="submit"
                        className={`btn ${styles.pink_btn_submit}  `}
                        onClick={(e)=> hanldeAddTagSubmit(e)}
                      >
                        Add Tag
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal> */}
      <AddTagModal showModal={showModal} setShowModal={setShowModal} editTagData={null}/>
    </div>
  );
}

export default AddTag;
