import { axiosJSON } from "src/globals/axiosEndPoints";
import { DomainAuthData } from "./getEmailIntegrationById";

export interface VerifyDomainEmailAuth {
  isDomainVerified: boolean;
  domainAuthData: Array<DomainAuthData>;
}

export interface VerifyDomainEmailAuthParams {
  emailIntegrationId: number;
}

export async function verifyDomainEmailAuthById(
  params: VerifyDomainEmailAuthParams
) {
  const { data } = await axiosJSON.post(
    `/api/setting/integration/verifyEmailDomainAuthentication`,
    params
  );

  if (data.err === true || data.error === true) {
    throw new Error(data.msg as string);
  }

  return data.data as VerifyDomainEmailAuth;
}
