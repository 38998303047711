import { useCallback, useState } from "react";
import RatingSection from "./Children/RatingSection/RatingSection";
import RatingSetting from "./Children/RatingSetting/RatingSetting";
import styles from "./Rating.module.scss";
import useRating from "./useRating";
import { useNavigate } from "react-router-dom";
const Rating = () => {
  const navigate = useNavigate();
  const {
    allowUsers,
    isError,
    selectedRadio,
    setAllowUsers,
    setSelectedRadio,
    setShowCount,
    showCount,
    reqApproval,
    setReqApproval,
    selectedRatingApproval,
    setSelectedRatingApproval,
  } = useRating();

  // State for emoji click
  const [emojiClick, setEmojiClick] = useState(false);

  // Handling the click on emoji
  const handleClickOnEmoji = useCallback(() => {
    setEmojiClick(true);
  }, [setEmojiClick]);

  if (isError)
    return (
      <>
        {" "}
        <div className={`p-2  w-100`}>
          <span className={`${styles.errorMsg}`}>Something Went Wrong</span>
        </div>
      </>
    );

  return (
    <div className={`w-100 pt-4 px-3 ${styles.ratingsWrapper}`}>
      <div className="mb-4 d-flex">
        <div>
          <div
            className={`d-flex justify-content-center align-items-center ${styles.backChat}`}
            onClick={() => navigate(-1)}
          >
            <i className="fa-solid fa-arrow-left"></i>
          </div>
        </div>
        <div>
          <h3 className={`mb-0 ${styles.heading} saufter_h3`}>
            Feedbacks / Ratings
          </h3>
          <p className={`${styles.subHeading} saufter_subHeading`}>
            Let customers style the help center right inside your chat widget
            instead of starting a conversation.
          </p>
        </div>
      </div>
      <div className={`d-block d-md-flex`}>
        {/* Rating Setting Section - Left Side  */}
        <RatingSetting
          allowUsers={allowUsers}
          setAllowUsers={setAllowUsers}
          setShowCount={setShowCount}
          showCount={showCount}
          selectedRadio={selectedRadio}
          setSelectedRadio={setSelectedRadio}
          setEmojiClick={setEmojiClick}
          reqApproval={reqApproval}
          setReqApproval={setReqApproval}
          selectedRatingApproval={selectedRatingApproval}
          setSelectedRatingApproval={setSelectedRatingApproval}
        />

        {/* Rating Section - Right Side  */}
        <RatingSection
          allowUsers={allowUsers}
          showCount={showCount}
          selectedRadio={selectedRadio}
          handleClickOnEmoji={handleClickOnEmoji}
          emojiClick={emojiClick}
        />
      </div>
    </div>
  );
};

export default Rating;
