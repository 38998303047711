import { axiosJSON } from "src/globals/axiosEndPoints";

export interface IFulfillmentStatus {
  id: number;
  name: string;
  groupId: number;
}

export interface CreateFulfillment {
  orderId: number;
  status: boolean;
  fulfillmentId: string;
  fulfillmentStatus: IFulfillmentStatus;
}

export interface CreateFulfillmentParams {
  orderId: number;
  fulfillmentOrderId: string;
  fulfillItems: Array<{
    itemId: string;
    quantity: number;
  }>;
  notifyCustomer: boolean;
  shippingAddress: {
    // optional
    address1: string;
    address2: string;
    city: string;
    countryCode: string;
    provinceCode: string;
    zip: string;
  };
  trackingInfo?: {
    // optional
    trackingNumber?: string;
    shippingCarrier?: string;
    trackingUrl?: string;
  };
}

export async function createFulfillment(params: CreateFulfillmentParams) {
  // tracking info is optional, to delete them if not set
  if (
    params?.trackingInfo?.trackingNumber?.length === 0 &&
    params?.trackingInfo?.shippingCarrier?.length === 0
  ) {
    delete params.trackingInfo;
  }
  const { data: res }: any = await axiosJSON.post(
    `/api/shopify/order/fulfillment/create`,
    params
  );

  if (res.error === true) {
    throw new Error(res.message as string);
  }

  return res.data as CreateFulfillment;
}
