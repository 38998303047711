import UserAvatar from "src/components/UserAvatar";
import styles from "./CernAIMsg.module.scss";
import saufterLogo from "src/assets/images/saufter.gif";
import cautionImg from "src/assets/images/errorImg.png";
import { useCallback, useState } from "react";
import ParseHTML from "src/components/ParseHTML";
import AxiosImg from "src/components/AxiosImg";
import { Dropdown } from "react-bootstrap";
import { getTimeFromNow } from "src/utils/dateLibrary";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { DropDownMenuData } from "../SendMsgCommon/SendMsgCommon";
import { useDispatch } from "react-redux";
import {
  clearIntegrationFailure,
  sendMessages,
  setSendCernMsgId,
  setShowCernThread,
} from "src/store/slices/innerTicket/innerTicket.slice";
import InnerChatAttachments from "src/components/InnerChatAttachments/InnerChatAttachments";
import tryAgainIcon from "src/assets/images/tryAgainIcon.png";

function formatDate(date: Date): string {
  const now = new Date();
  const inputDate = new Date(date);

  const isToday = now.toDateString() === inputDate.toDateString();
  const isYesterday =
    new Date(now.setDate(now.getDate() - 1)).toDateString() ===
    inputDate.toDateString();

  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  if (isToday) {
    return `today at ${inputDate.toLocaleTimeString("en-US", timeOptions)}`;
  } else if (isYesterday) {
    return `yesterday at ${inputDate.toLocaleTimeString("en-US", timeOptions)}`;
  } else {
    const dateOptions: Intl.DateTimeFormatOptions = {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    };
    return `${inputDate.toLocaleDateString(
      "en-US",
      dateOptions,
    )} at ${inputDate.toLocaleTimeString("en-US", timeOptions)}`;
  }
}

const CernAIMsg = ({
  messageData,
  hideCernThreadCount = false,
}: {
  messageData: any;
  hideCernThreadCount?: boolean;
}) => {
  const [error, setError] = useState(false);
  const [dropShow, setDropShow] = useState(false);
  const dispatch = useAppDispatch();
  const userTimezone = useAppSelector(
    (state) => state.globals.currentUserData?.userTimezone,
  );
  const cernErrorMessageId = useAppSelector(
    (state) => state.innerTicket?.cernErrorMessageId,
  );
  // This is the function that is called when the user clicks on the "Try Again" button
  const handleSendTryAgainMessage = useCallback(() => {
    if (messageData?.tryAgainPayload) {
      // dispatch(messageSendTryAgainPressed());
      const unsentMessages = JSON.parse(
        localStorage.getItem("unsentMessages") || "[]",
      );
      const unsentMessageIndex = unsentMessages.findIndex(
        (msg: any) => msg.messageId === messageData.messageId,
      );
      if (unsentMessageIndex !== -1) {
        unsentMessages.splice(unsentMessageIndex, 1);
        localStorage.setItem("unsentMessages", JSON.stringify(unsentMessages));
      }
      // clearing previous integration failure before trying again
      dispatch(
        clearIntegrationFailure({
          uuid: messageData.messageUuid,
        }),
      );
      dispatch(
        sendMessages({
          ...messageData?.tryAgainPayload,
          message: messageData.message,
          isTryAgain: true,
        }),
      );
    }
  }, [messageData?.tryAgainPayload]);

  // This is the function that is called when the message fails to send due to a network error
  const handleSendFailedMessage = useCallback(() => {
    if (messageData) {
      const unsentMessagesString = localStorage.getItem("unsentMessages");
      const unsentMessages = unsentMessagesString
        ? JSON.parse(unsentMessagesString)
        : [];

      // Check if the array already contains a message with the same messageId
      const existingMessageIndex = unsentMessages.findIndex(
        (msg: any) => msg.messageId === messageData.messageId,
      );
      if (existingMessageIndex === -1) {
        // If the array does not contain a message with the same messageId, push the new message into the array
        unsentMessages.push(messageData);
      } else {
        // If an existing message is found, check if integrationData has changed
        const existingMessage = unsentMessages[existingMessageIndex];
        const existingIntegrationData = existingMessage?.integrationFailure;
        const newIntegrationData = messageData?.integrationFailure;
        if (
          JSON.stringify(existingIntegrationData) !==
          JSON.stringify(newIntegrationData)
        ) {
          // If integrationData has changed, update the existing message
          unsentMessages[existingMessageIndex] = messageData;
          // Save the updated array to localStorage
          localStorage.setItem(
            "unsentMessages",
            JSON.stringify(unsentMessages),
          );
        }
      }
    }
  }, [messageData]);

  const tryAgainRendering = () => {
    handleSendFailedMessage();
    return (
      <div className={`d-flex justify-content-end mt-1`}>
        <span className={`${styles.failedText}`}>Failed....</span>
        <button
          id="tryAgain"
          className={` border-0 bg-white d-flex align-items-center ${styles.tryText}`}
          onClick={() => {
            handleSendTryAgainMessage();
          }}
        >
          <img
            src={tryAgainIcon}
            alt=""
            className={`me-1 ${styles.tryAgainIcon}`}
          />
          <span> Try again</span>
        </button>
      </div>
    );
  };

  return (
    <div className={`${styles.slackWrapper}`}>
      <div>
        <div
          className={`d-flex align-items-center mb-2 ${
            (cernErrorMessageId === messageData.messageId ||
              messageData?.cernThreadData?.isCernInstructionError) &&
            styles.addPos
          }`}
        >
          <div className={`${styles.logo}`}>
            <img
              src={saufterLogo}
              alt="saufter logo"
            />
          </div>
          <span className={`ms-2 ${styles.cern}`}>Cern AI</span>
        </div>

        <div className={`d-flex`}>
          {(cernErrorMessageId === messageData.messageId ||
            messageData?.cernThreadData?.isCernInstructionError) && (
            <div>
              <img
                src={cautionImg}
                alt="error img"
                className={`${styles.erroMsg}`}
              />
            </div>
          )}
          <div>
            <div
              className={`${styles.msgBox} ${
                (cernErrorMessageId === messageData.messageId ||
                  messageData?.cernThreadData?.isCernInstructionError) &&
                styles.errorBorder
              }`}
            >
              <div className="d-flex align-items-center mb-1">
                <span className={`${styles.chatText}`}>Chat</span>
                <span className={`mx-1 ${styles.separator}`}></span>
                <span className={`${styles.actionText}`}>Actions</span>
              </div>
              <div className={`${styles.msgText}`}>
                <p className=",b-0">
                  <ParseHTML html={messageData.message} />
                </p>
              </div>
              {messageData.attachments?.length ? (
                <div className="mt-2">
                  <InnerChatAttachments
                    messageData={{ attachments: messageData.attachments }}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="d-flex justify-content-between align-items-center mt-1">
              {messageData.messageStatus === "pending" ? (
                <span
                  className={`d-flex justify-content-end mt-1 ${styles.sending}`}
                >
                  Sending...
                </span>
              ) : messageData.messageStatus === "rejected" ? (
                tryAgainRendering()
              ) : (
                <div className="w-100 d-flex justify-content-between">
                  <div className="d-none">
                    <span className={`${styles.replyText}`}>
                      Reply to thread
                    </span>
                  </div>
                  <div className="d-flex align-items-center me-3">
                    <span>
                      {messageData.senderImgURL ? (
                        <AxiosImg
                          url={messageData.senderImgURL}
                          className={`${styles.profileSmallIcon}`}
                          isDirectURL={messageData?.isPublicAttachmentUrl}
                        />
                      ) : (
                        <UserAvatar
                          name={messageData.senderName || "NA"}
                          size={22}
                        />
                      )}
                    </span>
                    <div className={`mx-2 ${styles.logo}`}>
                      <img
                        src={saufterLogo}
                        alt="saufter logo"
                      />
                    </div>
                    {!hideCernThreadCount && (
                      <button
                        className={`${styles.replyText} cursor-pointer`}
                        onClick={() => {
                          dispatch(
                            setSendCernMsgId({ msgId: messageData.messageId }),
                          );
                          dispatch(setShowCernThread({ show: true }));
                        }}
                      >
                        {messageData?.cernThreadData?.totalReplies} Reply to
                        thread
                      </button>
                    )}
                    {messageData?.cernThreadData?.lastReplyDateGmt && (
                      <span className={`ps-2 ${styles.lastReply}`}>
                        Last reply{" "}
                        {formatDate(
                          new Date(
                            messageData?.cernThreadData?.lastReplyDateGmt + "z",
                          ),
                        )}
                      </span>
                    )}
                  </div>
                  <div>
                    <Dropdown onToggle={setDropShow}>
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        as="div"
                        bsPrefix={`dropdown-toggle cursor-pointer ${styles.sendDrop} ${styles.dropdownToggle}`}
                      >
                        {`${getTimeFromNow({
                          date: new Date(
                            messageData.messageDateTime ??
                              messageData.messageDateTimeUTC,
                          ),
                          timeZone: userTimezone,
                        })}`}
                        <span>
                          {" "}
                          {dropShow ? (
                            <i className={`fa-solid fa-caret-up`}></i>
                          ) : (
                            <i className={`fa-solid fa-caret-down`}></i>
                          )}
                        </span>
                      </Dropdown.Toggle>
                      <DropDownMenuData
                        messageData={messageData}
                        labelBy={"dropdownMenuButton2"}
                      />
                    </Dropdown>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="ms-3">
            {messageData.senderImgURL ? (
              <AxiosImg
                url={messageData.senderImgURL}
                className={`${styles.profileIcon}`}
                isDirectURL={messageData?.isPublicAttachmentUrl}
              />
            ) : (
              <UserAvatar
                name={messageData.senderName || "NA"}
                size={33}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CernAIMsg;
