import { forwardRef } from "react";
import styles from "./MaximizeMinimizeEditor.module.scss";

const MaximizeMinimizeEditor = forwardRef(function (
  {
    onClick = (e) => {},
    className = "",
    showMaximizedModal,
    setShowMaximizedModal,
    ...props
  }: {
    onClick?: (e: any) => void;
    className?: string;
    showMaximizedModal: any;
    setShowMaximizedModal: any;
    [key: string]: any;
  },
  ref: any
) {
  return (
    <div
      className={`mx-0 mt-0 ${styles.maximizeMinimize} ${className} d-flex flex-column justify-content-center`}
      onClick={(e) => setShowMaximizedModal(!showMaximizedModal)}
      {...props}
      ref={ref}
    >
      <i
        className={`fa-solid ${
          showMaximizedModal === true
            ? `fa-minus fa-2xs`
            : `fa-up-right-and-down-left-from-center fa-2xs`
        } ${styles.icon}`}
      ></i>
    </div>
  );
});

export default MaximizeMinimizeEditor;
