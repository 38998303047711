import { useCallback, useMemo, useState } from "react";
import {
  reportNotificationsActions,
  useNotifications,
} from "../../../../hooks/notifications/useNotifications";
import styles from "./AddNotification.module.scss";
interface Props {
  hide: () => void;
}
const useAddNotification = ({ hide }: Props) => {
  const { reportNotifications, dispatch } = useNotifications();
  const reportName = useMemo(() => {
    return reportNotifications.editingNotificationData?.reportName ?? "";
  }, [reportNotifications]);
  const [name, setName] = useState(reportName);
  const [err, setErr] = useState(false);

  const setReportName = useCallback(
    (e: any) => {
      if (name.trim()) {
        dispatch([
          reportNotificationsActions.setEditingNotificationData,
          { reportName: name.trim() },
        ]);
        hide();
      } else {
        setErr(true);
      }
    },
    [name, hide]
  );

  const setNameValue = useCallback((e: any) => {
    if (e.target.value.trim()) {
      setErr(false);
    }
    setName(e.target.value);
  }, []);

  return { name, setReportName, setNameValue, err };
};

const AddNotification = ({ hide }: Props) => {
  const { name, setReportName, setNameValue, err } = useAddNotification({
    hide,
  });

  return (
    <div className="p-3">
      <h4 className={`${styles.heading}`}>Add Notification / Report Name</h4>
      <div>
        <input
          type="text"
          placeholder="Eg . Unresolved Tickets in Channels"
          className={`px-2 w-100 mt-1 ${styles.inputBox} ${
            err ? styles.err : ""
          }`}
          value={name}
          onChange={setNameValue}
        />
        <div className={`d-flex justify-content-end mt-3 mb-2`}>
          <button className={`me-2 ${styles.cancelBtn}`} onClick={hide}>
            Cancel
          </button>
          <button className={`${styles.addBtn}`} onClick={setReportName}>
            Add
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddNotification;
