import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { botProfilesSlice } from "./botProfiles.declarations";
import botProfilesExtraReducers from "./botProfiles.extraReducers";
import { fetchConfigureAudienceCondition, updateConfigureAudienceCondition } from "./botProfiles.thunks";

export function builderFunction(
  builder: ActionReducerMapBuilder<botProfilesSlice>
) {
  builder.addCase(
    fetchConfigureAudienceCondition.fulfilled,
    botProfilesExtraReducers.fetchConfigureAudienceConditionFulfilled
  );
  builder.addCase(
    fetchConfigureAudienceCondition.rejected,
    (state: botProfilesSlice) => {
      state.audienceConfigConditionAJAX = "rejected";
    }
  );
  builder.addCase(
    fetchConfigureAudienceCondition.pending,
    (state: botProfilesSlice) => {
      state.audienceConfigConditionAJAX = "pending";
    }
  );
  builder.addCase(
    updateConfigureAudienceCondition.fulfilled,
    botProfilesExtraReducers.updateConfigureAudienceConditionFulfilled
  );
}
