import { useAutomationContext } from "src/routes/BotSettings/Children/Automation/Hooks/useAutomationContext";
import Lottie from "lottie-light-react";
import liveDot from "src/assets/images/liveDot.json";
import styles from "./ConfiguredStep.module.scss";
import { Modal, Spinner } from "react-bootstrap";
import { useCallback, useState } from "react";
import { updateAutomationService } from "src/services/Automation/updateAutomation.service";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import PublishAutomation from "src/routes/BotSettings/Children/Automation/AutomationTabSettings/Children/ConfigureSteps/Children/Components/PublishAutomation/PublishAutomation";

interface Props {
  index: number;
  title: string;
  isActive: boolean;
  isComplete: boolean;
  isGoLiveStep?: boolean;
}

/**
 * Configure Step Component for showing step status
 */
const ConfiguredStep = ({
  index,
  title,
  isActive,
  isComplete,
  isGoLiveStep,
}: Props) => {
  const { formData, updateFormData, type, isSaving } = useAutomationContext();
  const [loading, setLoading] = useState(false);
  const [showUnPublishModal, setShowUnpublishModal] = useState(false);

  const handleUnpublish = useCallback(async () => {
    if (!formData.showGoLive || !type) {
      console.log("Some Error Occured");
      return;
    }
    if (!formData.published) {
      setShowUnpublishModal(true);
    } else {
      setLoading(true);
      const payload = {
        integrationId: formData.integrationId,
        publish: !formData.published,
      };
      try {
        await updateAutomationService({
          type: type,
          payload: payload,
        });

        updateFormData({ published: payload.publish });
      } catch (err) {
        pushTheToast({
          position: "top-right",
          type: "danger",
          text:
            typeof err === "string"
              ? err
              : payload.publish
                ? "Could not publish automation"
                : "Could not unpublish automation",
        });
      } finally {
        setLoading(false);
      }
    }
  }, [
    formData.integrationId,
    formData.published,
    formData.showGoLive,
    type,
    updateFormData,
  ]);

  return (
    <div className={`${styles.steps} ${isActive ? styles.active : ""}`}>
      <div
        className={`d-flex align-items-start py-1 px-2 w-100 ${
          isGoLiveStep ? "mt-2" : ""
        }`}
      >
        {/* Step Number */}
        <div className={`pe-3`}>
          <div
            className={`${styles.number} ${
              isActive || isComplete ? styles.active : ""
            }  mt-1 d-flex justify-content-center align-items-center`}
          >
            {isComplete ? (
              <span>
                <i className="fa-solid fa-check" />
              </span>
            ) : (
              <span>{index + 1}</span>
            )}
          </div>
        </div>
        {/* Content */}
        <div>
          {/* Title */}
          <span
            className={`${styles.stepHeading} ${
              isComplete ? styles.completed : ""
            } ${isActive ? styles.active : ""} mb-0`}
          >
            {title}
          </span>
          {isGoLiveStep ? (
            <div className="mb-2">
              <div className={`${styles.subText}`}>
                Ticket Routing automation flow is live for{" "}
                {formData?.brand?.name}
              </div>
              <div className="d-flex align-items-center">
                <button
                  className={`mt-2 ${styles.moveBtn}`}
                  id="automation_goLive_btn"
                  disabled={isSaving}
                  onClick={() => handleUnpublish()}
                >
                  <span className="pe-1">
                    <i className="fa-regular fa-eye"></i>
                  </span>
                  <span> {formData.published ? "Unpublish" : "Go live"} </span>
                  {loading && (
                    <Spinner
                      className={`mx-1 my-auto`}
                      animation="border"
                      color="white"
                      size="sm"
                    />
                  )}
                </button>

                {formData.published ? (
                  <div className="ms-3 mt-2 d-flex align-items-center">
                    <span
                      style={{
                        height: 20,
                        width: 20,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Lottie animationData={liveDot}></Lottie>
                    </span>
                    <div className="ps-1">
                      <span className={`${styles.liveText}`}>Live</span>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <Modal
        backdropClassName={`${styles.modalBrandBack}`}
        show={showUnPublishModal}
        onHide={() => setShowUnpublishModal(false)}
        dialogClassName={`${styles.modalBrandDialog}`}
        contentClassName={`${styles.modalBrandContent}`}
        centered={true}
      >
        <PublishAutomation onHide={() => setShowUnpublishModal(false)} />
      </Modal>
    </div>
  );
};

export default ConfiguredStep;
