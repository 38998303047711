/**
 * This file is the React component file for rendering applied filters in a report.
 * It displays both standard filters and additional internal filters with corresponding values.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 * @author Navjyot
 */

import caution from "src/assets/images/caution.png";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import useFiltersApplied from "../../hooks/reportFilters/useFiltersApplied";
import styles from "./FiltersApplied.module.scss";
import useIntFiltersApplied from "../../hooks/reportFilters/useIntFiltersApplied";
import { CurrentStatusEndPoints } from "src/services/report/reportTicketList.service";
import { useCallback, useEffect, useMemo } from "react";

// Define the interface for the component's props
interface Props {
  endPoint: CurrentStatusEndPoints;
  showInternalFilters?: boolean; //added new prop to hide internal filters
  isGlobalFilter?: boolean; //prop defining if the filter is global (used in live chat reports)
}

// React component for rendering applied filters in a report
const FiltersApplied = ({
  endPoint,
  showInternalFilters = true,
  isGlobalFilter = false,
}: Props) => {
  // Custom hooks to retrieve applied filters data
  const { filtersApplied } = useFiltersApplied({ endPoint });

  const internalFilterFuncton = showInternalFilters
    ? useIntFiltersApplied
    : () => ({ intFiltersApplied: [] });
  const { intFiltersApplied } = internalFilterFuncton();

  return (
    <div className="mt-3">
      {/* Display standard filters if any */}
      {filtersApplied.length ? (
        <>
          {/* Filter icon */}
          <span className={`${styles.filterImg}`}>
            <i className="fa-solid fa-filter"></i>
          </span>
          {/* Label for applied filters */}
          <span className={`ps-1 ${styles.filters}`}>
            {"Filters applied: "}
          </span>
          {/* Display each applied filter with caution icon for additional information */}
          {filtersApplied.map((data) => {
            return (
              <span
                key={data.name}
                className={`${styles.ticketStatus} px-3 py-1 d-inline-flex mx-1`}
              >
                {/* Display filter name and value */}
                <span className={`${styles.statusTruncate} my-auto me-1`}>
                  {/*Capitalize the first letter of data.value for better readability  */}
                  <span>
                    {isGlobalFilter
                      ? ""
                      : `${
                          data.name.charAt(0).toUpperCase() +
                          data.name.toLowerCase().slice(1)
                        } - `}{" "}
                  </span>
                  {data.value.charAt(0).toUpperCase() + data.value.slice(1)}
                </span>
                {/* Display caution icon with additional information on hover */}
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip>
                      <div className={`${styles.overlayBox}`}>
                        {/* Capitalize the first letter of data.value for better readability in the tooltip */}
                        {data.name.charAt(0).toUpperCase() +
                          data.name.toLowerCase().slice(1)}{" "}
                        -{" "}
                        {data.value.charAt(0).toUpperCase() +
                          data.value.slice(1)}
                      </div>
                    </Tooltip>
                  }
                >
                  {({ ...triggerHandler }) => (
                    <img
                      {...triggerHandler}
                      src={caution}
                      alt=""
                      className={`cursor-pointer ${styles.cautionImg} my-auto`}
                    />
                  )}
                </OverlayTrigger>
              </span>
            );
          })}
        </>
      ) : (
        ""
      )}

      {/* Display internal filters if any */}
      {intFiltersApplied.length ? (
        <>
          {/* Label for other filters */}
          <span className={`${styles.filters}`}>Other Filters:</span>
          {/* Display each internal filter with value and a close icon for removal */}
          {intFiltersApplied.map((data) => {
            return (
              <span
                key={data.name}
                className={`${styles.dateFilter} px-2 py-1 mb-1 d-inline-flex mx-1 my-auto`}
              >
                {/* Display internal filter value with tooltip on hover */}
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip>
                      {/* Capitalize the first letter of data.value for better readability in the tooltip */}
                      {data.name.charAt(0).toUpperCase() +
                        data.name.toLowerCase().slice(1)}
                      :{" "}
                      {data.value.charAt(0).toUpperCase() + data.value.slice(1)}
                    </Tooltip>
                  }
                >
                  {({ ...triggerHandler }) => (
                    <span
                      {...triggerHandler}
                      className={`${styles.otherFilterValues} text-truncate`}
                    >
                      {/* Capitalize the first letter of data.value for better readability in the tooltip */}
                      {data.name.charAt(0).toUpperCase() +
                        data.name.toLowerCase().slice(1)}
                      :{" "}
                      {data.value.charAt(0).toUpperCase() + data.value.slice(1)}
                    </span>
                  )}
                </OverlayTrigger>
                {/* Display close icon for removing the internal filter */}
                <span className="mx-1 my-auto">
                  <i
                    onClick={data.callback}
                    className="cursor-pointer fa-solid fa-xmark"
                  ></i>
                </span>
              </span>
            );
          })}
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default FiltersApplied;
