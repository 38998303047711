/**
 * This file is the React component file for a date range picker.
 * It utilizes the react-datepicker library to allow users to select a date range.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 * @author Navjyot
 */

import { useCallback, useEffect, useRef, useState } from "react";
import DatePicker, { CalendarContainer } from "react-datepicker";
import "./ReportDatePicker.scss";
import arrow from "src/assets/images/downArrow.png";
import { useAppSelector } from "src/store/store";

// React component for the date range picker
function ReportDatePicker({
  startDate,
  endDate,
  onChange,
}: {
  startDate: null | Date;
  endDate: null | Date;
  onChange: (value: [Date | null, Date | null]) => void;
}) {
  // Fetching disabledFeatures from the Redux store
  const disabledFeatures = useAppSelector(
    (state) => state.globals.currentUserData?.disabledFeatures
  );
  const [start, setStart] = useState(startDate);
  const [end, setEnd] = useState(endDate);
  const [open, setOpen] = useState(false);

  // Handle apply button click
  const handleApply = useCallback(() => {
    // Create a new Date object with the value of 'start' if it exists, otherwise use the current date
    const newDate = start ? new Date(start) : new Date();
    // If 'start' exists, add 30 days to the 'newDate' object
    if (start) {
      newDate.setDate(newDate.getDate() + 30);
    }
    // Call the 'onChange' function with an array containing 'start' and 'end' (if 'end' exists) or 'newDate'
    onChange([start, end ?? newDate]);

    setOpen(false);
  }, [start, end, onChange]);

  // Reset the date range
  const resetRange = useCallback(() => {
    setStart(startDate);
    setEnd(endDate);
  }, [startDate, endDate]);

  // Handle click outside the date picker
  const outsideClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (arrowRef.current?.contains(event.target as Node)) return; // If the click is on the arrow, do nothing
      setOpen(false);
      resetRange();
    },
    [resetRange]
  );

  useEffect(() => {
    resetRange();
  }, [resetRange]);

  const arrowRef = useRef<HTMLDivElement>(null); // Reference to the arrow element

  const [selectedRange, setSelectedRange] = useState(""); // State to store the selected range

  function isDateWithinRange(
    date: Date,
    start: Date | null,
    end: Date | null
  ): boolean {
    if (start && end) {
      return date >= start && date <= end;
    }
    return false;
  }

  const isSameDay = (date1: Date, date2: Date | null): boolean => {
    return (
      date2 !== null &&
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  };

  // Handle click on a range change
  const handleRangeClick = (range: string) => {
    let startDate: Date | null = null; // Start date of the range
    let endDate: Date | null = null; // End date of the range

    const today = new Date(); // Today's date
    today.setHours(0, 0, 0, 0); // Set the time to 00:00:00:00

    switch (
      range // Set the start and end dates based on the range selected
    ) {
      case "Today": // If the range is 'Today', set the start and end dates to today
        startDate = today;
        endDate = today;
        onChange([startDate, endDate]); // Call the 'onChange' function with the start and end dates
        break;
      case "Yesterday": // If the range is 'Yesterday', set the start and end dates to yesterday
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1); // Set the date to yesterday
        startDate = yesterday;
        endDate = yesterday;
        onChange([startDate, endDate]); // Call the 'onChange' function with the start and end dates
        break;
      case "Last 7 days": // If the range is 'Last 7 days', set the start and end dates to the last 7 days
        const last7Days = new Date(today);
        last7Days.setDate(last7Days.getDate() - 6); // Set the date to 6 days ago
        startDate = last7Days;
        endDate = today;
        onChange([startDate, endDate]); // Call the 'onChange' function with the start and end dates
        break;
      case "Last 30 days": // If the range is 'Last 30 days', set the start and end dates to the last 30 days
        const last30Days = new Date(today);
        last30Days.setDate(last30Days.getDate() - 29); // Set the date to 29 days ago
        startDate = last30Days;
        endDate = today;
        onChange([startDate, endDate]); // Call the 'onChange' function with the start and end dates
        break;
      case "This month": // If the range is 'This month', set the start and end dates to the current month
        const firstDayOfMonth = new Date(
          today.getFullYear(),
          today.getMonth(),
          1
        ); // Set the date to the first day of the current month
        startDate = firstDayOfMonth;
        endDate = today;
        onChange([startDate, endDate]); // Call the 'onChange' function with the start and end dates
        break;
      case "Last month": // If the range is 'Last month', set the start and end dates to the last month
        const firstDayOfLastMonth = new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          1
        ); // Set the date to the first day of the last month
        const lastDayOfLastMonth = new Date(
          today.getFullYear(),
          today.getMonth(),
          0
        ); // Set the date to the last day of the last month
        startDate = firstDayOfLastMonth;
        endDate = lastDayOfLastMonth;
        onChange([startDate, endDate]); // Call the 'onChange' function with the start and end dates
        break;
      case "Custom Range": // If the range is 'Custom Range', set the start and end dates to the current start and end dates
        startDate = start; // Set the start date to the current start date
        endDate = end; // Set the end date to the current end date
        break;
      default:
        break;
    }
    setSelectedRange(range); // Set the selected range
    setStart(startDate); // Set the start date
    setEnd(endDate); // Set the end date
  };

  const datePickerRef = useRef<HTMLDivElement>(null); // Reference to the date picker element
  const menuRef = useRef<HTMLDivElement>(null); // Reference to the menu element

  useEffect(() => {
    // Scroll to the date picker element
    datePickerRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
    });

    // Scroll to the menu element
    menuRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
    });
  }, [selectedRange]); // Scroll to the date picker element when the selected range changes

  // Custom calendar container  to show the date range options
  const calendarContainer: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
    className,
    children,
  }) => {
    return (
      // Custom calendar container
      <CalendarContainer
        className={`d-flex flex-column containerDiv ${
          selectedRange === "Custom Range" ? `customRange` : ``
        } border-0`}
      >
        {/* First Row - Menu and Date Picker */}
        <div
          className={`d-flex justify-content-between ${
            selectedRange === "Custom Range" ? "my-2 py-2 pt-1" : ""
          } ${selectedRange === "Custom Range" ? "firstRow" : ""}`}
        >
          {/* Menu */}
          <div
            className={`d-flex flex-column justify-content-between  ${
              selectedRange === "Custom Range" ? "py-3 pe-1 MenuDiv" : ""
            } `}
            ref={menuRef}
          >
            {/* Today */}
            <span
              onClick={() => handleRangeClick("Today")}
              role="button"
              className={`
                ${
                  selectedRange === "Today" ? "item selectedItem" : "item"
                } w-100 
              `}
            >
              TODAY
            </span>
            {/* Yesterday */}
            <span
              onClick={() => handleRangeClick("Yesterday")}
              role="button"
              className={
                selectedRange === "Yesterday" ? "item selectedItem" : "item"
              }
            >
              Yesterday
            </span>
            {/* Last 7 days */}
            <span
              onClick={() => handleRangeClick("Last 7 days")}
              role="button"
              className={
                selectedRange === "Last 7 days" ? "item selectedItem" : "item"
              }
            >
              Last 7 days
            </span>
            {/* Last 30 days */}
            <span
              onClick={() => handleRangeClick("Last 30 days")}
              role="button"
              className={`
                ${
                  selectedRange === "Last 30 days"
                    ? "item selectedItem"
                    : "item"
                } w-100 
              `}
            >
              Last 30 days
            </span>
            {/* This month */}
            <span
              onClick={() => handleRangeClick("This month")}
              role="button"
              className={
                selectedRange === "This month" ? "item selectedItem" : "item"
              }
            >
              This month
            </span>
            {/* Last month */}
            <span
              onClick={() => handleRangeClick("Last month")}
              role="button"
              className={
                selectedRange === "Last month" ? "item selectedItem" : "item"
              }
            >
              Last month
            </span>
            {/* Custom Range */}
            <span
              onClick={(e) => {
                setOpen(true);
                handleRangeClick("Custom Range");
              }}
              role="button"
              className={
                selectedRange === "Custom Range" ? "item selectedItem" : "item"
              }
            >
              Custom Range
            </span>
          </div>
          {/* Custom date picker */}
          {selectedRange === "Custom Range" && (
            <div
              className={`d-flex flex-column ${className} ms-2`}
              ref={datePickerRef} // Set the reference to the date picker element
            >
              <div className="">{children}</div>
            </div>
          )}
        </div>
        {/* Second Row - Buttons */}
        <div className=" px-1 ">
          {/* Apply Button */}
          {selectedRange === "Custom Range" && (
            <div className={`d-flex justify-content-end mb-2`}>
              <button
                className={`btn cancelButton me-1`}
                onClick={() => {
                  setOpen(false);
                  resetRange();
                }}
              >
                Cancel
              </button>
              <button
                className={`btn applyBTN_CURRENT_STATUS`}
                onClick={handleApply}
              >
                Apply
              </button>
            </div>
          )}
        </div>
      </CalendarContainer>
    );
  };

  return (
    <div
      className={`d-flex align-items-center p-2 ${
        open ? `opened` : ``
      } rangePicker_CURRENT_STATUS customStyles ${(disabledFeatures && disabledFeatures.includes("reports")) ? `disable` : ""}`}  //Addded customStyles to apply styles only for reports date picker
    >
      <div className="">
        <span className="pe-1">
          <i className="fa-regular fa-calendar"></i>
        </span>
      </div>
      <DatePicker
        selected={start}
        open={open}
        selectsRange
        showMonthDropdown
        showYearDropdown
        startDate={start}
        endDate={end}
        onChange={([start, end]) => {
          setStart(start);
          setEnd(end);
        }}
        onInputClick={() => {
          setOpen(!open);
          setSelectedRange("");
        }}
        onClickOutside={(e) => {
          // Handle click outside the date picker
          outsideClick(e);
        }}
        dateFormat={"MMMM d, yyyy"}
        monthsShown={2}
        dropdownMode="select"
        useShortMonthInDropdown
        className={`datePickerStyles`}
        calendarContainer={calendarContainer}
        calendarClassName={`bg-white w-100 calendarDatePicker`}
        renderCustomHeader={({
          monthDate,
          customHeaderCount,
          decreaseMonth,
          increaseMonth,
        }) => {
          return (
            <div className="bg-white">
              <button
                aria-label="Previous Month"
                className={
                  "react-datepicker__navigation react-datepicker__navigation--previous"
                }
                style={customHeaderCount === 1 ? { visibility: "hidden" } : {}}
                onClick={decreaseMonth}
              >
                <span
                  className={
                    "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous arrowColor"
                  }
                >
                  {"<"}
                </span>
              </button>
              <span className="react-datepicker__current-month">
                {monthDate.toLocaleString("en-US", {
                  month: "long",
                  year: "numeric",
                })}
              </span>
              <button
                aria-label="Next Month"
                className={
                  "react-datepicker__navigation react-datepicker__navigation--next"
                }
                style={customHeaderCount === 0 ? { visibility: "hidden" } : {}}
                onClick={increaseMonth}
              >
                <span
                  className={
                    "react-datepicker__navigation-icon react-datepicker__navigation-icon--next arrowColor"
                  }
                >
                  {">"}
                </span>
              </button>
            </div>
          );
        }}
        dayClassName={(date) => {
          const isSelected = isDateWithinRange(date, start, end);
          const isStart = isSameDay(date, start);
          const isEnd = isSameDay(date, end);

          if (isSelected) {
            if (isStart) {
              return "selectedDate firstDayLastDay";
            } else if (isEnd) {
              return "selectedDate firstDayLastDay";
            } else {
              return "selectedDate";
            }
          }

          return "";
        }}
        onSelect={(date) => {
          return console.log(date, "date on select");
        }}
        customInput={
          <div
            role="button"
            className="pe-1"
            onClick={(e) => {
              // Handle click on the arrow
              e.stopPropagation();
              setOpen(!open);
            }}
          >
            {start?.toLocaleDateString("en-US", {
              day: "numeric",
              month: "long",
              year: "numeric",
            })}{" "}
            -{" "}
            {end?.toLocaleDateString("en-US", {
              day: "numeric",
              month: "long",
              year: "numeric",
            })}
          </div>
        }
      ></DatePicker>
      {/* Added this to show arrow as per xd */}
      <div
        className="ms-1"
        role="button"
        ref={arrowRef} // Set the reference to the arrow element
        onClick={(e) => {
          // Handle click on the arrow
          e.stopPropagation();
          setOpen(!open);
        }}
      >
        <span className={`arrow`}>
          {open ? (
            <i className="fa fa-angle-up"></i>
          ) : (
            <i className="fa fa-angle-down"></i>
          )}
        </span>
      </div>
    </div>
  );
}

export default ReportDatePicker;
