import { useCallback, useEffect, useState } from "react";
import {
  addReturnIntegration,
  removeReturnIntegration,
} from "src/store/slices/initiateReturn/returnExchange.thunk";
import { useAppDispatch, useAppSelector } from "src/store/store";

interface Props {
  automateType?: "fully_automate" | "partially_automate";
  brandId?: number;
  successCallback?: (integrationId: number) => void;
}

//custom hook to create new retun integration
export const useCreateReturnIntegration = ({
  automateType,
  brandId,
  successCallback,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const { addIntegrationAjaxStatus } = useAppSelector(
    (state) => state.returnExchange,
  );
  const dispatch = useAppDispatch();

  const addNewIntegration = useCallback(() => {
    if (automateType && brandId) {
      //dispatching thunk to create new integration
      dispatch(
        addReturnIntegration({
          step_data: { selected_auto_type: automateType },
          brandId: brandId,
          callback: successCallback,
        }),
      );
    }
  }, [automateType, brandId, dispatch, successCallback]);

  useEffect(() => {
    //setting the loading state to true if request status is pending
    if (addIntegrationAjaxStatus === "pending") {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [addIntegrationAjaxStatus]);

  useEffect(() => {
    //setting the loading state to true if request status is pending
    if (addIntegrationAjaxStatus === "pending") {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [addIntegrationAjaxStatus]);

  const deleteIntegration = useCallback(
    async (
      integrationId: number,
      handleDeleteIntegrationCallback: () => void,
    ) => {
      //deleting integration
      dispatch(
        removeReturnIntegration({
          integrationId: integrationId,
          callback: handleDeleteIntegrationCallback,
        }),
      );
    },
    [dispatch],
  );

  return {
    addNewIntegration,
    deleteIntegration,
    loading,
  };
};
