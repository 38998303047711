import { ITextBoxQuestion } from "src/services/Automation/getAutomationById.service";
import styles from "./InputBox.module.scss";
import { useAutomationContext } from "../../../../Hooks/useAutomationContext";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  EAutomationType,
  IAutomationListParams,
} from "src/routes/BotSettings/Children/AutomationList/AutomationList";
interface QuestionsProps {
  questionData: ITextBoxQuestion;
  stepId: string;
  setShowErrorModal?: React.Dispatch<React.SetStateAction<boolean>>;
}
const InputBox = ({
  stepId,
  questionData,
  setShowErrorModal,
}: QuestionsProps) => {
  const { updateQuestions, saveQuestionsData, formData } =
    useAutomationContext();
  const { type } = useParams<IAutomationListParams>();

  const onChangeHandler = useCallback(
    (value: string) => {
      if (type == EAutomationType.TICKET_ROUTING && formData?.published) {
        if (setShowErrorModal) setShowErrorModal(true);
        return;
      }
      updateQuestions({
        ...questionData,
        questionValue: value,
        isAnswered: value !== "",
      });

      if (value !== "") {
        saveQuestionsData({
          stepId,
          stepQuestions: {
            [questionData.questionKey]: value,
          },
        });
      }
    },
    [questionData, saveQuestionsData, stepId, updateQuestions],
  );

  return (
    <>
      <div className="position-relative mb-2">
        <div
          className={`pb-2 ${styles.query} ${true ? styles.activeLine : ""}
                  ${false ? styles.errorLine : ""}`}
          data-question-key={1}
        >
          <input
            type="text"
            placeholder={`${questionData.questionPlaceholder ?? ""}`}
            className={`${styles.inputBox}`}
            value={questionData.questionValue ?? ""}
            onChange={(e) => onChangeHandler(e.target.value)}
          />
        </div>
      </div>
    </>
  );
};

export default InputBox;
