import React, { useCallback, useMemo, useRef, useState } from "react";
import styles from "./AllTags.module.scss";
import { Dropdown } from "react-bootstrap";
import { useQuery } from "@tanstack/react-query";
import {
  useReportFilters,
  reportCurrentStatusActions,
} from "src/routes/Report/hooks/reportFilters/useReportFilters";
import { fetchTicketsTagsApi } from "src/services/TicketService/fetchTicketsTags";
import { TicketTags } from "src/store/slices/ticketTags/ticketTags.slice";
import { v4 as uuid } from "uuid";

const useTagFilter = () => {
  const { data: allTags } = useQuery(["useTagFilter/fetchTicketsTagsApi"], {
    queryFn: async () =>
      fetchTicketsTagsApi() as Promise<{
        allTags: TicketTags;
        allTagsIds: number[];
      }>,
    staleTime: 600000,
    cacheTime: 600000,
  });
  const allTagId = useMemo(() => {
    return allTags?.allTagsIds.map((id) => id + "") ?? [];
  }, [allTags]);

  const { currentStatus, dispatch } = useReportFilters();

  const selectedTags = useMemo(() => {
    return currentStatus.selectedTags;
  }, [currentStatus]);

  const selectAllTags = useCallback(
    (e: any) => {
      if (e.target.checked) {
        dispatch([
          reportCurrentStatusActions.setFilters,
          {
            selectedTags: [...allTagId],
          },
        ]);
      } else {
        dispatch([reportCurrentStatusActions.setFilters, { selectedTags: [] }]);
      }
    },
    [allTagId]
  );
  const clearAllTags = useCallback(() => {
    dispatch([reportCurrentStatusActions.setFilters, { selectedTags: [] }]);
  }, []);
  const checkUncheckTags = useCallback(
    (e: any) => {
      if (e.target.checked) {
        dispatch([
          reportCurrentStatusActions.setFilters,
          { selectedTags: [...selectedTags, e.target.value] },
        ]);
      } else {
        dispatch([
          reportCurrentStatusActions.setFilters,
          {
            selectedTags: selectedTags.filter((id) => id !== e.target.value),
          },
        ]);
      }
    },
    [selectedTags]
  );
  return {
    allTags,
    allTagId,
    selectedTags,
    selectAllTags,
    clearAllTags,
    checkUncheckTags,
  };
};
interface Props {
  isSegement?: boolean;
  disabled?: boolean;
}
const AllTags = ({ isSegement, disabled }: Props) => {
  const {
    allTags,
    allTagId,
    selectedTags,
    selectAllTags,
    clearAllTags,
    checkUncheckTags,
  } = useTagFilter();
  const [ddOpen, setDdOpen] = useState(false);
  const dropdownArrowSpanRef = useRef<any>();
  const uuidRef = useRef(uuid());

  return (
    <div>
      <Dropdown onToggle={setDdOpen}>
        <Dropdown.Toggle
          as="div"
          bsPrefix={`dropdown-toggle cursor-pointer border-0 px-2 py-1 me-2  mb-2 mb-lg-0 ${
            isSegement === true ? styles.segementBtn : styles.filterButton
          }  ${disabled && styles.disable}`}
        >
          All Tags
          {selectedTags.length ? (
            <span className={`mx-1 ${styles.filterCount}`}>
              {/* added condition to show 0 before count if count is less than 10 */}
              {selectedTags.length < 10
                ? `0${selectedTags.length}`
                : selectedTags.length}
            </span>
          ) : (
            ""
          )}
          <span className="ps-1" ref={dropdownArrowSpanRef}>
            {ddOpen ? (
              <i className={`fa-solid fa-caret-up`}></i>
            ) : (
              <i className={`fa-solid fa-caret-down`}></i>
            )}
          </span>
        </Dropdown.Toggle>

        <Dropdown.Menu bsPrefix={`dropdown-menu border-0 ${styles.filterMenu}`}>
          <div className={`${styles.brandsWrap}`}>
            <span className={`${styles.dropItem}`}>
              <div
                className={`${styles.itemWrapper} ${
                  selectedTags.length === allTagId.length && styles.selectedText
                }`}
              >
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={"all"}
                    id={uuidRef.current + "report-filter-tag-all-select"}
                    checked={selectedTags.length === allTagId.length}
                    onChange={selectAllTags}
                  />
                  <label
                    className={`form-check-label ${styles.checktext}`}
                    htmlFor={uuidRef.current + "report-filter-tag-all-select"}
                  >
                    Select All
                  </label>
                </div>
              </div>
            </span>
            {allTagId.map((id) => {
              return (
                <span key={id} className={`${styles.dropItem}`}>
                  <div
                    className={`${styles.itemWrapper} ${
                      selectedTags.includes(id) && styles.selectedOne
                    } `}
                  >
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={id}
                        id={uuidRef.current + "report-tag-filter" + id}
                        checked={selectedTags.includes(id)}
                        onChange={checkUncheckTags}
                      />
                      <label
                        className={`form-check-label ${styles.checktext}`}
                        htmlFor={uuidRef.current + "report-tag-filter" + id}
                      >
                        {allTags?.allTags[id].tagName ?? ""}
                      </label>
                    </div>
                  </div>
                </span>
              );
            })}
          </div>
          <span className={`${styles.clearSpan}`} onClick={clearAllTags}>
            Clear all
          </span>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default AllTags;
