import UserAvatar from "src/components/UserAvatar";
import styles from "./SearchedTickets.module.scss";
import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import { useAppSelector } from "src/store/store";
import ReactDOMServer from "react-dom/server";
import { useCallback, useMemo, useState } from "react";
import AxiosImg from "src/components/AxiosImg";

function escapeRegex(string: string) {
  return string.replace(/[/\-\\^$*+?.()|[\]{}]/g, "\\$&");
}

export function highlightSearchedText(
  text: string | React.ReactNode,
  highlight: string,
  textIsHtml?: boolean,
  className?: string
): string | JSX.Element | undefined | React.ReactNode {
  // Check if the input text is HTML code
  if (
    highlight === undefined ||
    highlight === null ||
    highlight?.trim() === ""
  ) {
    return text;
  } else {
    if (text === undefined) {
      return undefined;
    } else if (textIsHtml) {
      // Create a dummy element to render the HTML code
      const el = document.createElement("div");
      if (typeof text === "string") {
        el.innerHTML = text;
      }

      // Get all the text nodes inside the element
      const textNodes = [];
      const walk = document.createTreeWalker(el, NodeFilter.SHOW_TEXT, null);
      while (walk.nextNode()) {
        textNodes.push(walk.currentNode);
      }
      textNodes.forEach((node) => {
        const textContent = node.textContent;
        if (textContent) {
          const parts = textContent.split(new RegExp(`(${highlight})`, "gi"));
          const highlightedNode = document.createDocumentFragment();
          parts.forEach((part, i) => {
            if (part.toLowerCase() === highlight.toLowerCase()) {
              const highlightNode = document.createElement("span");
              highlightNode.style.backgroundColor = "#f1ff97";
              if (className && !highlightNode.classList.contains(className)) {
                highlightNode.classList.add(className);
              }
              const textNode = document.createTextNode(part);
              highlightNode.appendChild(textNode);
              highlightedNode.appendChild(highlightNode);
            } else {
              const textNode = document.createTextNode(part);
              highlightedNode.appendChild(textNode);
            }
          });
          node.parentNode?.replaceChild(highlightedNode, node);
        }
      });
      // Return the highlighted HTML code string
      return el.innerHTML;
    } else if (typeof text === "string") {
      // escaping regex characters
      const escapedHighlight = escapeRegex(highlight);
      // Split on highlight term and include term into parts, ignore case
      const parts = text.split(new RegExp(`(${escapedHighlight})`, "gi"));
      // Return the highlighted text as JSX
      return (
        <>
          {parts.map((part, i) => {
            if (part.toLowerCase() === highlight.toLowerCase()) {
              return (
                <span
                  key={i}
                  className={className}
                  style={{ backgroundColor: "#f1ff97" }}
                >
                  {part}
                </span>
              );
            } else {
              return part;
            }
          })}{" "}
        </>
      );
    } else {
      if (text !== undefined && text !== null) {
        const textElement = <>{text}</>;
        const textString = ReactDOMServer.renderToString(textElement);
        return highlightSearchedText(textString, highlight, true);
      }
    }
  }
}

export const TicketDescription = ({
  ticketDesc,
  searchTerm,
}: {
  ticketDesc: string;
  searchTerm: string;
}) => {
  const [show, setShow] = useState(false);
  const onShow = useCallback(() => {
    setShow(true);
  }, []);
  const onHide = useCallback(() => {
    setShow(false);
  }, []);
  const descPopover = useMemo(
    () => (
      <Popover
        bsPrefix={`popover ${styles.hoverDiv}`}
        onMouseEnter={onShow}
        onMouseLeave={onHide}
      >
        <Popover.Body bsPrefix={`popover-body ${styles.body}`}>
          {highlightSearchedText(ticketDesc, searchTerm)}
        </Popover.Body>
      </Popover>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ticketDesc, searchTerm]
  );

  return (
    <>
      <OverlayTrigger
        show={show && ticketDesc.length > 150}
        placement="auto"
        overlay={descPopover}
      >
        <p
          className={`mb-0 ${styles.description}`}
          onMouseEnter={onShow}
          onMouseLeave={onHide}
        >
          {highlightSearchedText(ticketDesc, searchTerm)}
        </p>
      </OverlayTrigger>
    </>
  );
};

interface Props {
  ticketNum: number;
  ticketHead: string;
  ticketDesc: string;
  socialMedia: string;
  name: string;
  email: string;
  time: string;
  imageURL?: string | null;
  isPublicAttachmentUrl?: boolean;
  brand: any;
}
const SearchedTickets = ({
  ticketNum,
  ticketHead,
  ticketDesc,
  socialMedia,
  name,
  email,
  time,
  imageURL,
  isPublicAttachmentUrl,
  brand,
}: Props) => {
  // Search Term from the global search

  const searchTerm = useAppSelector(
    (state) => state.ticketGlobalSearch.searchText
  );

  return (
    <div className="px-3 py-3 mb-2">
      <div className={`d-flex align-items-start mb-1 ${styles.ticketMain}`}>
        <div className={`${styles.ticketNumber}`}>
          <h4 className={`${styles.commonClass} text-truncate`}>
            {highlightSearchedText(ticketNum.toLocaleString(), searchTerm)}
          </h4>
        </div>
        <div className={`${styles.nameDesc}`}>
          <div className={`${styles.descWrapper}`}>
            <h4 className={`mb-0 ${styles.commonClass}`}>
              {highlightSearchedText(ticketHead, searchTerm)}
            </h4>
            {/* <span className={`${styles.desc}`}>{highlightSearchedText(ticketDesc, searchTerm)}</span> */}
          </div>
        </div>
        <div className={`${styles.socialMedia}`}>
          <span className={`${styles.messenger}`}>
            <i className={socialMedia}></i>
          </span>
        </div>
        <div className={`d-flex align-items-start ${styles.user}`}>
          {imageURL && imageURL.trim() !== "" ? (
            <AxiosImg className={`${styles.profileIcon}`} url={imageURL} isDirectURL={isPublicAttachmentUrl} />
          ) : (
            <UserAvatar className={`mx-0 `} size={20} name={name} />
          )}

          <h4
            className={`mb-0 ms-2 text-break ${styles.commonClass}`}
          >
            {highlightSearchedText(name, searchTerm)}
          </h4>
        </div>
        <div className={`ms-2 ${styles.userEmail}`}>
          <span className={`${styles.mail}`}>
            {highlightSearchedText(email, searchTerm)}
          </span>
        </div>
        {/* Added user brand as per the client requirement  */}
        <div className={`ms-2 d-flex align-items-center  ${styles.userBrand}`}>
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip className={`${styles.mail}`}>{brand?.name}</Tooltip>
            }
          >
            {({ ref, ...triggerHandler }) => (
              <div className={` ms-0 me-0`} {...triggerHandler} ref={ref}>
                <div
                  className={`d-flex justify-content-center align-items-center ${styles.clearText}`}
                >
                  {brand?.imgURL ? (
                    <AxiosImg
                      className={`${styles.brandImg} flex-shrink-0`}
                      url={brand?.imgURL}
                      isDirectURL={brand?.isPublicAttachmentUrl}
                    />
                  ) : (
                    <UserAvatar name={brand?.name || "NA"} size={20} />
                  )}
                  <span className={`ps-1 ${styles.brand}`}>{brand?.name}</span>
                </div>
              </div>
            )}
          </OverlayTrigger>
        </div>
        <div className={`${styles.timeSpan}`}>
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip className={`${styles.mail}`}>
                Created on {new Date(time).toLocaleString()}
              </Tooltip>
            }
          >
            {({ ref, ...triggerHandler }) => (
              <div className={` ms-0 me-0`} {...triggerHandler} ref={ref}>
                <div
                  className={`d-flex justify-content-center align-items-center ${styles.clearText}`}
                >
                  <span className={`${styles.mail}`}>
                    <span className="me-1">
                      <i className="fas fa-clock"></i>
                    </span>
                    <span>{new Date(time).toLocaleString()}</span>
                  </span>
                </div>
              </div>
            )}
          </OverlayTrigger>
        </div>
        {/* <div className={`${styles.timeSpan}`}>
        <span className={`${styles.mail}`}>
          <span className="me-1">
            <i className="fas fa-clock"></i>
          </span>
          <span>{time}</span>
        </span>
      </div> */}
        <div className={`${styles.goTo}`}>
          <span className={`${styles.goAhead}`}>
            <i className="fa-solid fa-angle-right"></i>
          </span>
        </div>
      </div>
      <TicketDescription ticketDesc={ticketDesc} searchTerm={searchTerm} />
    </div>
  );
};

export default SearchedTickets;
