import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { ChatWidExchMsgSlice } from "./chatWidExchMsg.declarations";
import chatWidExchMsgExtraReducers from "./chatWidExchMsg.extraReducers";
import {
  fetchAllAssignToAgentsList,
  fetchLiveChatMessgeUpdates,
  fetchMsgData,
  sendLiveMsg,
} from "./chatWidExchMsg.thunks";

export function builderFunction(
  builder: ActionReducerMapBuilder<ChatWidExchMsgSlice>,
) {
  builder.addCase(
    fetchMsgData.fulfilled,
    chatWidExchMsgExtraReducers.fetchMsgDataFulfilled,
  );
  builder.addCase(
    fetchMsgData.rejected,
    (state: ChatWidExchMsgSlice, { payload }) => {
      if (!payload) {
        pushTheToast({
          type: "danger",
          text: "Failed to get messages",
          position: "top-right",
        });
        state.fetchMsgAJAXStatus = "rejected";
      }
    },
  );
  builder.addCase(fetchMsgData.pending, (state: ChatWidExchMsgSlice) => {
    state.fetchMsgAJAXStatus = "pending";
  });

  builder.addCase(
    sendLiveMsg.fulfilled,
    chatWidExchMsgExtraReducers.sendLiveMsgFulfilled,
  );

  builder.addCase(
    sendLiveMsg.rejected,
    (state: ChatWidExchMsgSlice, action) => {
      if (action.meta.arg.uuid) {
        state.messages[action.meta.arg.uuid].messageStatus = "rejected";
      }
      state.sendMsgAJAXStatus = "rejected";

      const payload = action.payload as any;

      if (payload?.statusCode === "integrationFailure") {
        state.integrationFailure = payload.failureMetaData;
        // Store integration failure data for message for try again
        state.messages[action.meta.arg.uuid].integrationFailure =
          payload.failureMetaData;
      }
    },
  );

  builder.addCase(sendLiveMsg.pending, (state: ChatWidExchMsgSlice, action) => {
    if (action.meta.arg.uuid && state.messages[action.meta.arg.uuid]) {
      state.messages[action.meta.arg.uuid].messageStatus = "pending";
    }
    state.sendMsgAJAXStatus = "pending";
  });

  builder.addCase(
    fetchLiveChatMessgeUpdates.fulfilled,
    chatWidExchMsgExtraReducers.fetchLiveChatMessgeUpdatesFulfilled,
  );

  builder.addCase(
    fetchAllAssignToAgentsList.rejected,
    (state: ChatWidExchMsgSlice) => {
      state.assignToAgentListAJAXStatus = "rejected";
    },
  );
  builder.addCase(
    fetchAllAssignToAgentsList.pending,
    (state: ChatWidExchMsgSlice) => {
      state.assignToAgentListAJAXStatus = "pending";
    },
  );

  builder.addCase(
    fetchAllAssignToAgentsList.fulfilled,
    chatWidExchMsgExtraReducers.fetchAssignToAgentsFulfilled,
  );
}
