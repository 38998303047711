import axios from "axios";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";

import { searchCustomers } from "src/services/Customer/searchCustomers";
import { addEditDraftMsg } from "src/services/DraftFlow/addEditDraft";
import { getAllTicketsIdList } from "src/services/InnerTicket/getAllTicketsIdList";
import { getChannelList } from "src/services/InnerTicket/getChannelList";
import {
  getMessagesApi,
  MessageQuery,
} from "src/services/InnerTicket/getMessages";
import {
  IMergeTickets,
  mergeTickets,
} from "src/services/InnerTicket/MergeTickets/mergeTickets";
import {
  changeCustomer,
  ChangeCustomerProps,
  ISendMessageService,
  sendMessageService,
} from "src/services/SendMessageService";
import { getSnooze } from "src/services/Snooze/getSnooze";
import { getAllStatuses } from "src/services/TicketService/getAllStatuses";
import {
  getTicketsV2,
  TicketsQuery,
} from "src/services/TicketService/getTicketsV2";
import TicketService from "src/services/TicketService/TicketService";
import { RootState } from "src/store/store";
import { AjaxError } from "src/types/AjaxError";
import {
  getCreatedDateValue,
  getClosedDateValue,
  getNotRepliedSinceDateValue,
  getLastUpdatedDateValue,
} from "../ticketFilters/ticketFilters.selectors";
import {
  getActiveListIndex,
  getActiveChannelId,
  getActiveTicketStatusId,
  getActiveDraftStatusId,
} from "../ticketSidebar/ticketSidebar.selectors";
import {
  addMessageForTryAgain,
  fetchMessagesUpdates,
  IMessageData,
  setSendCernMsgId,
  setShowCernThread,
  updateTryAgainMessageStatus,
} from "./innerTicket.slice";
import { formatDateTimeString } from "src/utils/dateLibrary";
import { FreePlanError, FreePlanErrorMessage } from "src/globals/constants";
import { AsyncThunkPayloadCreator } from "@reduxjs/toolkit";
import { IntegrationFailureMetaData } from "src/types/IntegrationFailure";
import isIntegratedChannelService, {
  IsChannelIntegratedParam,
} from "src/services/TicketService/InternalChatBot/isIntegratedChannels.service";

let cont: any = []; //controller list
let snoozeControllers: any = []; //snooze controller list

const getCont = () => cont;
const getSnoozeControllers = () => cont;

const fetchTicketsThunk = async (
  payload: undefined,
  { getState }: { getState: Function },
) => {
  try {
    const currentState: RootState = getState();
    const activeListIndex: string = getActiveListIndex(currentState);
    const start =
      currentState.innerTicket.ticketList.length +
        currentState.innerTicket.ticketListFlag <
      0
        ? 0
        : currentState.innerTicket.ticketList.length +
          currentState.innerTicket.ticketListFlag;
    // Build column data for tickets
    const queryData: TicketsQuery = {
      columns: [
        "ticket_subject",
        // "ticket_excerpt",
        "ticket_channel",
        "ticket_customer_name",
        "ticket_tags",
        "ticket_brand",
        "not_replied_since",
        // "last_message_received",
        // "ticket_draft_status",
        // "ticket_drafted_by",
        "ticket_id",
        "ticket_assigned_to",
        "last_message_date",
        "ticket_created_date",
        // "ticket_modified_date",
        "ticket_status",
        // "number_of_tickets",
        // "ticket_assignee_team",
        // "ticket_integration",
        "customer_id",
        // "ticket_to",
        // "last_mention_message",
        // "mentioned_by",
        // "unread_mention_count",
        "is_ai_handled",
        "is_ai_drafted",
        "ticket_customer_details",
      ],
      filters: {
        tableType: activeListIndex === "mentions" ? "mentions" : "all",
      },
      start,
      limit: currentState.innerTicket.ticketDataLimit,
    };

    if (activeListIndex === "mentions") {
      queryData.columns.push(
        ...["last_mention_message", "mentioned_by", "unread_mention_count"],
      );
    }
    // if (currentState.innerTicket.filters.tableType.includes("viewId")) {
    //   queryData.filters.viewId = parseInt(
    //     currentState.innerTicket.filters.tableType.split("::")[1]
    //   );
    // }
    let customerId = currentState.innerTicket.filters.customerId;
    let ticketDataById: object = {};
    // If no customerId available
    if (customerId === 0) {
      // Add Option for ticket ID
      queryData.filters.options = [
        {
          key: "id",
          compare: "=",
          value: parseInt(currentState.innerTicket.active.ticketId + ""),
        },
      ];
      // Check if view is customer-View
      if (currentState.innerTicket.filters.currentView === "customer-view") {
        queryData.filters.isInnerTicket = true;
      }
      // Get Ticket data with active ticket id
      ticketDataById = (
        await getTicketsV2(
          queryData,
          true,
          currentState.innerTicket.filters.currentView,
        )
      ).tickets;
      // If data returned get the customer Id from ticket response
      if (Object.values(ticketDataById).length > 0) {
        customerId = Object.values(ticketDataById)[0].customer_id;
      }
    }
    let ticketStatusList = currentState.innerTicket.ticketStatusList;
    // If ticket status data is not available then fetch Statuses
    if (Object.keys(currentState.innerTicket.ticketStatusList).length === 0) {
      const { allTicketStatuses } = await getAllStatuses();
      ticketStatusList = allTicketStatuses;
    }
    // Apply the customer if filter to get all tickets for the selected customer
    queryData.filters.options = [
      {
        key: "customer_id",
        compare: "=",
        value: customerId,
      },
    ];
    // If ticket status is not all then add the ticket status
    if (currentState.innerTicket.filters.ticketStatus !== 0) {
      queryData.filters.options.push({
        key: "ticket_status_id",
        compare: "=",
        value: currentState.innerTicket.filters.ticketStatus,
      });
    }
    // Add search query if available
    if (currentState.innerTicket.filters.searchText !== "") {
      queryData.filters.searchOptions = {
        value: currentState.innerTicket.filters.searchText,
        keys: [
          "ticket_id",
          "ticket_title",
          // "customer_nicename",
          "all_email",
          // "customer_email",
          "company_email",
          // "ticket_description",
          "tags",
        ],
      };
    }
    // If customer view enable flag
    if (currentState.innerTicket.filters.currentView === "customer-view") {
      queryData.filters.isInnerTicket = true;
    }
    // const activeListIndex: string = getActiveListIndex(currentState);
    // const activeChannelId: number = getActiveChannelId(currentState);
    // const activeTicketStatusId: number = getActiveTicketStatusId(currentState);
    // const activeDraftStatusId: number = getActiveDraftStatusId(currentState);
    // const createdDateValue = getCreatedDateValue(currentState);
    // const closedDateValue = getClosedDateValue(currentState);
    // const notRepliedSinceDateValue = getNotRepliedSinceDateValue(currentState);
    // const filterSearchString = currentState.ticket.filterSearchString;

    // const filterOptions = {
    //   activeChannelId,
    //   activeDraftStatusId,
    //   createdDateValue,
    //   closedDateValue,
    //   notRepliedSinceDateValue,
    //   ...currentState.ticketFilter.activeFilters,
    // };
    // const fOptions: any = TicketService.prepareInnerTicketQuery({
    //   filterOptions,
    //   appliedFilters: queryData.filters.options,
    // });
    // queryData.filters.options = fOptions;
    // Get all tickets data for selected customer
    const { tickets, ticketMeta } = await getTicketsV2(
      queryData,
      true,
      currentState.innerTicket.filters.currentView,
    );
    return {
      ticketDataById,
      customerId,
      ticketStatusList,
      ticketData: tickets,
      ticketList: ticketMeta.ticketIds.map((data) => parseInt(data)),
      ticketListTotal: ticketMeta.totalCount,
    };
  } catch (error) {
    throw error;
  }
};

//for global search
const fetchAllTicketsThunk = async (
  payload: undefined,
  { getState }: { getState: Function },
) => {
  try {
    const currentState: RootState = getState();

    const activeListIndex: string = getActiveListIndex(currentState);
    const queryData: TicketsQuery = {
      columns: [
        "ticket_subject",
        "ticket_excerpt",
        "ticket_channel",
        "ticket_customer_name",
        "ticket_tags",
        "ticket_brand",
        "last_message",
        "not_replied_since",
        // "last_message_received",
        // "ticket_draft_status",
        // "ticket_drafted_by",
        "ticket_id",
        "ticket_assigned_to",
        "last_message_date",
        "ticket_created_date",
        // "ticket_modified_date",
        "ticket_status",
        // "number_of_tickets",
        // "ticket_assignee_team",
        // "ticket_integration",
        "customer_id",
        "ticket_to",
        // "last_mention_message",
        // "mentioned_by",
        // "unread_mention_count",
        "last_message",
        "is_ai_handled",
        "is_ai_drafted",
      ],
      filters: {
        tableType: activeListIndex === "mentions" ? "mentions" : "all",
        searchOptions: {
          value: currentState.innerTicket.globalSearch.searchText,
          keys: ["*"],
        },
      },
      start: currentState.innerTicket.ticketList.length,
      limit: currentState.innerTicket.ticketDataLimit,
    };

    if (activeListIndex === "mentions") {
      queryData.columns.push(
        ...["last_mention_message", "mentioned_by", "unread_mention_count"],
      );
    }
    // if (currentState.innerTicket.filters.tableType.includes("viewId")) {
    //   queryData.filters.viewId = parseInt(
    //     currentState.innerTicket.filters.tableType.split("::")[1]
    //   );
    // }
    if (currentState.innerTicket.filters.currentView === "customer-view") {
      queryData.filters.isInnerTicket = true;
    }
    const { tickets, ticketMeta } = await getTicketsV2(
      queryData,
      true,
      currentState.innerTicket.filters.currentView,
    );
    return {
      ticketData: tickets,
      ticketList: ticketMeta.ticketIds.map((data) => parseInt(data)),
      ticketListTotal: ticketMeta.totalCount,
    };
  } catch (error) {
    throw error;
  }
};

// for merge modal
const fetchMergableTicketsThunk = async (
  payload: undefined,
  { getState }: { getState: Function },
) => {
  try {
    const currentState: RootState = getState();
    const activeListIndex: string = getActiveListIndex(currentState);
    const queryData: TicketsQuery = {
      columns: [
        "ticket_subject",
        "ticket_excerpt",
        "ticket_channel",
        "ticket_customer_name",
        "ticket_tags",
        "ticket_brand",
        "not_replied_since",
        // "last_message_received",
        // "ticket_draft_status",
        // "ticket_drafted_by",
        "ticket_id",
        "ticket_assigned_to",
        "last_message_date",
        "ticket_created_date",
        // "ticket_modified_date",
        "ticket_status",
        // "number_of_tickets",
        // "ticket_assignee_team",
        // "ticket_integration",
        "customer_id",
        "ticket_to",
        // "last_mention_message",
        // "mentioned_by",
        // "unread_mention_count",
      ],
      filters: {
        tableType: activeListIndex === "mentions" ? "mentions" : "all",
      },
      start: currentState.innerTicket.ticketMerge.ticketList.length,
      limit: currentState.innerTicket.ticketMerge.ticketDataLimit,
    };

    if (activeListIndex === "mentions") {
      queryData.columns.push(
        ...["last_mention_message", "mentioned_by", "unread_mention_count"],
      );
    }
    // if (currentState.innerTicket.filters.tableType.includes("viewId")) {
    //   queryData.filters.viewId = parseInt(
    //     currentState.innerTicket.filters.tableType.split("::")[1]
    //   );
    // }
    let customerId = currentState.innerTicket.filters.customerId;
    let ticketDataById: object = {};
    let currentTicketId = parseInt(
      currentState.innerTicket.active.ticketId + "",
    );
    if (customerId === 0) {
      queryData.filters.options = [
        {
          key: "id",
          compare: "=",
          value: currentTicketId,
        },
      ];
      if (currentState.innerTicket.filters.currentView === "customer-view") {
        queryData.filters.isInnerTicket = true;
      }
      ticketDataById = (
        await getTicketsV2(
          queryData,
          true,
          currentState.innerTicket.filters.currentView,
        )
      ).tickets;
      if (Object.values(ticketDataById).length > 0) {
        customerId = Object.values(ticketDataById)[0].customer_id;
      }
    }
    queryData.filters.options = [
      {
        key: "customer_id",
        compare: "=",
        value: customerId,
      },
    ];
    // get the ticket that can be merged, means not including cuurrent ticket and tickets that are already merged
    let notIncludingId = "" + currentTicketId;
    if (currentState.innerTicket.activeTicketInfo.merged_tickets != null) {
      // there are some already merged tickets, only fetch the tickets other than that
      notIncludingId +=
        "," + currentState.innerTicket.activeTicketInfo.merged_tickets;
    }
    // // console.log("innerTicketThunk::: notIncludingIds::: "+currentState.innerTicket.activeTicketInfo.merged_tickets);
    // // console.log("innerTicketThunk::: notIncludingIds::: "+notIncludingId);
    queryData.filters.options.push({
      key: "id",
      compare: "!=",
      value: notIncludingId,
    });

    // we need to show only unclosed tickets in merge modal
    // commented on 12/5/22, now we can merge tickets with all statuses
    /*queryData.filters.options.push({
      key: "ticket_status_id",
      compare: "!=",
      value: "5"
    });*/

    if (currentState.innerTicket.filters.currentView === "customer-view") {
      queryData.filters.isInnerTicket = true;
    }
    const { tickets, ticketMeta } = await getTicketsV2(
      queryData,
      true,
      currentState.innerTicket.filters.currentView,
    );
    return {
      customerId,
      ticketData: tickets,
      ticketList: ticketMeta.ticketIds.map((data) => parseInt(data)),
      ticketListTotal: ticketMeta.totalCount,
    };
  } catch (error) {
    throw error;
  }
};

const fetchMessagesThunk = async (
  payload: undefined | { callback: () => void },
  { getState, rejectWithValue }: { getState: Function; rejectWithValue: any },
) => {
  cont.forEach((c: any) => {
    c.abort();
  });
  const controller = new AbortController();
  cont.push(controller);

  try {
    const currentState: RootState = getState();
    let channelList = currentState.innerTicket.channelList;
    if (Object.keys(channelList).length === 0) {
      channelList = await getChannelList();
    }
    // Get the last message ID from the message list
    let lastMessageId =
      currentState.innerTicket.messageList[
        currentState.innerTicket.messageList.length - 1
      ];
    // Initialize lastMessageType as "message"
    let lastMessageType: MessageQuery["lastMessageType"] = "message";
    // Check if the last message ID includes "chat::" and update lastMessageId and lastMessageType accordingly
    if (lastMessageId && (lastMessageId + "").includes("chat::")) {
      lastMessageId = (lastMessageId + "").split("::")[1];
      lastMessageType = "chat";
    }
    const activeListIndex: string = getActiveListIndex(currentState);
    const queryData: MessageQuery = {
      ticketId: parseInt(currentState.innerTicket.active.ticketId + ""),
      sortByTime: "descending",
      // Use lastMessageId and lastMessageType instead of start
      lastMessageId: lastMessageId,
      lastMessageType: lastMessageType,
      limit: currentState.innerTicket.messageDataLimit,
      ticketColumns: [
        "ticket_subject",
        "ticket_channel",
        "ticket_customer_name",
        "not_replied_since",
        "ticket_brand",
        "ticket_id",
        "ticket_assigned_to",
        "last_message_date",
        "ticket_created_date",
        "ticket_status",
        "customer_id",
        "ticket_to",
        "ticket_from",
        "ticket_bcc",
        "ticket_cc",
        "ticket_description",
        "ticket_type",
        "ticket_created_by",
        "ticket_tags",
        "can_add_new_draft",
        "merged_tickets", // added for merging ticket modals
        "ticket_attachments", // to get attachments of ticket, attachments added while creating tickets
        "merged_parent_tickets", //to get the list of ticket id's to which the current ticket is merged into
        "ticket_schedule_message_count", // to get the number of scheduled messages in a ticket
        // "unread_mention_message_id",
        // "last_mention_message",
        // "mentioned_by",
        // "unread_mention_count",
        "is_ai_handled",
        // "is_ai_drafted",
        "ticket_customer_details",
      ],
      includeCustomerChats: true,
    };

    if (activeListIndex === "mentions") {
      queryData.ticketColumns.push(
        ...([
          "unread_mention_message_id",
          "last_mention_message",
          "mentioned_by",
          "unread_mention_count",
        ] as any),
      );
    }
    // Update condition to check for lastMessageId instead of start
    if (activeListIndex === "mentions" && !queryData.lastMessageId) {
      queryData.filters = { limitToUnreadMention: true };
    }
    const { ticketInfo, messageData, messageList, metaData } =
      await getMessagesApi(queryData, controller.signal);
    setTimeout(() => {
      if (payload?.callback) {
        payload.callback();
      }
    }, 0);
    return {
      ticketInfo,
      messageData,
      messageList,
      hasMoreMessage:
        messageList.length < currentState.innerTicket.messageDataLimit
          ? false
          : true,
      channelList,
      metaData,
    };
  } catch (error) {
    // console.log(error);
    if (axios.isCancel(error)) {
      return rejectWithValue(false);
    } else {
      // let ajaxError: AjaxError = {
      //   message: (error as Error).message,
      // };
      // // log.warn(ajaxError);
      // throw ajaxError;
      return rejectWithValue((error as Error).cause);
    }
  } finally {
    cont = getCont().filter((c: any) => c !== controller);
  }
};

interface fetchMessageUpdate {
  messageAfterId?: number;
}
const fetchMessagesUpdatesThunk = async (
  payload: undefined | { callback?: () => void; messageAfterId?: number },
  { getState }: { getState: Function },
) => {
  try {
    const currentState: RootState = getState();
    const queryData: MessageQuery = {
      ticketId: parseInt(currentState.innerTicket.active.ticketId + ""),
      sortByTime: "descending",
      start: 0,
      limit: 5,
      ticketColumns: [
        "ticket_subject",
        "ticket_channel",
        "ticket_customer_name",
        "not_replied_since",
        "ticket_brand",
        "ticket_id",
        "ticket_assigned_to",
        "last_message_date",
        "ticket_created_date",
        "ticket_status",
        "customer_id",
        "ticket_to",
        "ticket_from",
        "ticket_bcc",
        "ticket_cc",
        "ticket_description",
        "ticket_type",
        "ticket_created_by",
        "ticket_tags",
        "can_add_new_draft",
        "merged_tickets", // added for merging ticket modals
        "ticket_attachments", // to get attachments of ticket, attachments added while creating tickets
        "is_ai_handled",
        // "is_ai_drafted",
        "ticket_schedule_message_count", // to get the number of scheduled messages in a ticket
        "ticket_customer_details",
      ],
      includeCustomerChats: true,
    };
    if (payload?.messageAfterId) {
      queryData["messageAfterId"] = payload.messageAfterId;
    }
    const { ticketInfo, messageData, messageList, metaData } =
      await getMessagesApi(queryData);
    setTimeout(() => {
      if (payload?.callback) {
        payload?.callback();
      }
    }, 0);
    return {
      ticketInfo,
      messageData,
      messageList,
      metaData,
    };
  } catch (error) {
    // console.log(error);
    throw error;
  }
};

export interface SendMessageThunkI extends ISendMessageService {
  callback?: () => void;
  isTryAgain?: boolean;
  attachments?: Array<any>;
}

const sendMessageThunk = async (
  {
    callback = () => {},
    isTryAgain,
    attachments,
    ...payload
  }: SendMessageThunkI,
  {
    getState,
    dispatch,
    rejectWithValue,
  }: { getState: Function; dispatch: Function; rejectWithValue: any },
) => {
  try {
    const currentState: RootState = getState();
    if (!isTryAgain) {
      const activeTicketChannel =
        currentState.innerTicket.activeTicketInfo?.ticket_channel;
      let channelId = 0;
      if (activeTicketChannel) {
        const channel = Object.values(
          currentState.innerTicket.channelList,
        ).find((channel) => channel.name === activeTicketChannel);
        if (channel) {
          channelId = channel.id;
        }
      }
      let currentDateTime = new Date().toLocaleString("en-US", {
        timeZone: currentState.globals.currentUserData?.userTimezone,
        hour12: false,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });

      currentDateTime = formatDateTimeString(currentDateTime);
      const newMessage: IMessageData = {
        messageId: payload.uuid,
        ticketId: parseInt(payload.ticketId + ""),
        messageDateTime: new Date().toString(),
        // messageDateTimeGmt: string;
        isDraftMessage: false,
        subject: currentState.innerTicket.activeTicketInfo.ticket_subject,
        channelId: channelId,
        message: payload.message,
        to: payload.to ?? "",
        from: payload.from ?? "",
        cc: payload.cc ?? "",
        bcc: payload.bcc ?? "",
        sentBy: "agent",
        messageType: payload.messageType,
        senderName: currentState.globals.currentUserData?.firstName ?? "NA",
        senderImgURL:
          currentState.globals.currentUserData?.profileImageUrl ?? null,
        attachments: attachments,
        messageUuid: payload.uuid,
        messageStatus: "pending",
        tryAgainPayload: {
          ...payload,
          message: "",
        },
      };
      dispatch(addMessageForTryAgain(newMessage));
    } else {
      dispatch(
        updateTryAgainMessageStatus({
          uuid: payload.uuid,
          messageStatus: "pending",
        }),
      );
    }
    const message = await sendMessageService(payload);

    if (message.messageType === "NoteBotAiQuery") {
      dispatch(setShowCernThread({ show: true }));
      dispatch(setSendCernMsgId({ msgId: message.messageId }));
    }
    dispatch(fetchMessagesUpdates({ callback }));
    setTimeout(() => {
      callback();
    }, 0);
    return { message, status: payload.sendAs, ticketId: payload.ticketId };
  } catch (e: any) {
    // console.log(e);
    //showing toast if attachmentSizeLimitExceeded error
    if (typeof e == "object") {
      if (e.statusCode === "attachmentSizeLimitExceeded") {
        pushTheToast({
          text: "Attachment file size exceeded!",
          type: "danger",
          position: "top-right",
        });
        return rejectWithValue(e.statusCode);
      } else if (
        e.statusCode === FreePlanError.LIMIT_REACHED ||
        e.statusCode === FreePlanError.RESTRICTED
      ) {
        const statusCode = e.statusCode as keyof typeof FreePlanErrorMessage;
        // If the free plan limit is exceeded, show limit reached error
        pushTheToast({
          text: e.message ?? FreePlanErrorMessage[statusCode],
          type: "danger",
          position: "top-right",
        });
        return rejectWithValue(statusCode);
      } else if (e.statusCode === "integrationFailure") {
        return rejectWithValue(e);
      }
    }
    throw e;
  }
};

// thunk used to merge tickets
const mergeTicketThunk = async (
  payload: IMergeTickets,
  { getState, dispatch }: { getState: Function; dispatch: Function },
) => {
  const currentState: RootState = getState();
  // console.log("Merge Tickets payload:: " + JSON.stringify(payload));
  // get the response by calling service
  let res: any = await mergeTickets(payload);
  return res;
};

async function editDraftMsgThunk(
  {
    msgId,
    msgContent,
    attachmentBatchNumber,
    callback,
  }: {
    msgId: number;
    msgContent: string;
    attachmentBatchNumber?: Array<string>;
    callback?: () => void;
  },
  { getState, dispatch }: { getState: Function; dispatch: Function },
) {
  try {
    const currentState: RootState = getState();
    const ticketId = parseInt(currentState.innerTicket.active.ticketId + "");

    let editDraftPayload: any = {
      msgId: msgId,
      ticketId: ticketId,
      msgContent,
      attachmentBatchNumber: attachmentBatchNumber,
    };

    if (editDraftPayload.attachmentBatchNumber.length === 0) {
      delete editDraftPayload.attachmentBatchNumber;
    }

    const data = await addEditDraftMsg(editDraftPayload);
    if (callback) {
      setTimeout(() => {
        callback();
      }, 0);
    }
    const msg = await getMessagesApi({
      ticketId,
      sortByTime: "ascending",
      limit: 1,
      ticketColumns: [],
      messageIds: [msgId],
      includeCustomerChats: true,
    });
    return msg.messageData[msgId];
  } catch (err) {
    pushTheToast({
      type: "danger",
      text: "Error editing draft!",
      position: "top-right",
    });
    if (callback) {
      setTimeout(() => {
        callback();
      }, 0);
    }
    throw err;
  }
}

async function fetchMessageByIdsThunk({
  ticketId,
  msgIds,
}: {
  ticketId: number;
  msgIds: Array<number>;
}) {
  const msg = await getMessagesApi({
    ticketId,
    sortByTime: "ascending",
    limit: 1,
    ticketColumns: [],
    messageIds: msgIds,
    includeCustomerChats: true,
  });
  return msg.messageData;
}

// thunk to fetch all ticket id's list thunk
async function fetchAllTicketsIdListThunk(
  payload: undefined,
  { getState }: { getState: Function },
) {
  // console.log("fetching ticket IDs")
  // let res: any = await getAllTicketsIdList();
  const currentState = getState() as RootState;
  const activeListIndex: string = getActiveListIndex(currentState);
  const activeChannelId: number[] = getActiveChannelId(currentState);
  const activeTicketStatusId: number[] = getActiveTicketStatusId(currentState);
  const activeDraftStatusId: number[] = getActiveDraftStatusId(currentState);
  const createdDateValue = getCreatedDateValue(currentState);
  const closedDateValue = getClosedDateValue(currentState);
  const lastUpdatedValue = getLastUpdatedDateValue(currentState);
  const notRepliedSinceDateValue = getNotRepliedSinceDateValue(currentState);
  const filterSearchString = currentState.ticket.filterSearchString;

  const filterOptions = {
    activeChannelId,
    activeDraftStatusId,
    activeTicketStatusId,
    createdDateValue,
    closedDateValue,
    notRepliedSinceDateValue,
    filterSearchString,
    lastUpdatedValue,
    ...currentState.ticketFilter.activeFilters,
  };

  const queryData: any = TicketService.prepareGetTicketsQuery({
    start: 0,
    limit: 10,
    allColumnList: [],
    tableType: currentState.innerTicket.lastActiveTableType || activeListIndex,
    filterOptions,
  });
  delete queryData.columns;
  delete queryData.start;
  queryData.ticketId =
    currentState.innerTicket.filters.ticketId ||
    currentState.innerTicket.active.ticketId;
  queryData.currentView = currentState.innerTicket.filters.currentView;

  const data = await getAllTicketsIdList(queryData);
  return data.map((val) => parseInt(val + ""));
}
async function changeTheCustomerThunk(
  payload: ChangeCustomerProps,
  { getState }: { getState: Function },
) {
  try {
    const res: any = await changeCustomer(payload);
    return payload;
  } catch (e: any) {
    setTimeout(() => {
      pushTheToast({ type: "danger", text: e.message, position: "top-right" });
    }, 0);
  }
}

// thunk to search customers
async function searchCustomersThunk(
  payload: undefined,
  { getState }: { getState: Function },
) {
  let currentState: RootState = getState();
  let res: any = await searchCustomers({
    searchTerm: currentState.innerTicket.searchCustomersData.searchTerm,
    start: currentState.innerTicket.searchCustomersData.customersIdList.length,
    limit: currentState.innerTicket.searchCustomersData.fetchCustomersDataLimit,
  });

  let ret: any = {
    customersData: res.customersData,
    customersIdList: res.customersIdList,
    metaData: res.metaData,
  };

  return ret;
}

// thunk for get snooze
async function getSnoozeThunk(
  { callback }: { callback?: () => void },
  { getState, rejectWithValue }: { getState: Function; rejectWithValue: any },
) {
  snoozeControllers.forEach((c: any) => {
    c.abort();
  });
  const snoozeController = new AbortController();
  snoozeControllers.push(snoozeController);

  try {
    const currentState: RootState = getState();
    const ticketId = parseInt(currentState.innerTicket.active.ticketId + "");

    const ret: any = await getSnooze(
      {
        ticketId: ticketId,
      },
      snoozeController.signal,
    );

    if (callback) {
      setTimeout(() => {
        callback();
      }, 0);
    }

    return ret;
  } catch (error) {
    if (axios.isCancel(error)) {
      return rejectWithValue(true);
    } else {
      let ajaxError: AjaxError = {
        message: (error as Error).message,
      };
      // log.warn(ajaxError);
      throw ajaxError;
    }
  } finally {
    snoozeControllers = getSnoozeControllers().filter(
      (c: any) => c !== snoozeController,
    );
  }
}

interface innerTicketUpdatesPayload {
  limit?: number;
  ticketIds?: Array<number>;
  callback?: () => void;
}

const fetchInnerTicketUpdatesThunk = async (
  payload: innerTicketUpdatesPayload,
  { getState }: { getState: Function },
) => {
  try {
    const currentState: RootState = getState();
    const activeListIndex: string = getActiveListIndex(currentState);
    const start = 0;
    const limit = payload?.limit ?? 5;
    const queryData: TicketsQuery = {
      columns: [
        "ticket_subject",
        // "ticket_excerpt",
        "ticket_channel",
        "ticket_customer_name",
        "ticket_tags",
        "ticket_brand",
        "not_replied_since",
        // "last_message_received",
        // "ticket_draft_status",
        // "ticket_drafted_by",
        "ticket_id",
        "ticket_assigned_to",
        "last_message_date",
        "ticket_created_date",
        // "ticket_modified_date",
        "ticket_status",
        // "number_of_tickets",
        // "ticket_assignee_team",
        // "ticket_integration",
        "customer_id",
        // "ticket_to",
        // "last_mention_message",
        // "mentioned_by",
        // "unread_mention_count",
      ],
      filters: {
        tableType: activeListIndex === "mentions" ? "mentions" : "all",
      },
      start,
      limit,
    };
    if (payload?.ticketIds?.length !== 0) {
      queryData["ticketIds"] = payload.ticketIds;
    }
    // if (currentState.innerTicket.filters.tableType.includes("viewId")) {
    //   queryData.filters.viewId = parseInt(
    //     currentState.innerTicket.filters.tableType.split("::")[1]
    //   );
    // }
    let customerId = currentState.innerTicket.filters.customerId;

    queryData.filters.options = [
      {
        key: "customer_id",
        compare: "=",
        value: customerId,
      },
    ];

    const ticketStatus = currentState.innerTicket.filters.ticketStatus;

    if (currentState.innerTicket.filters.ticketStatus !== 0) {
      queryData.filters.options.push({
        key: "ticket_status_id",
        compare: "=",
        value: ticketStatus,
      });
    }
    if (currentState.innerTicket.filters.searchText !== "") {
      queryData.filters.searchOptions = {
        value: currentState.innerTicket.filters.searchText,
        keys: [
          "ticket_id",
          "ticket_title",
          // "customer_nicename",
          "all_email",
          // "customer_email",
          "company_email",
          // "ticket_description",
          "tags",
        ],
      };
    }
    if (currentState.innerTicket.filters.currentView === "customer-view") {
      queryData.filters.isInnerTicket = true;
    }
    // const activeListIndex: string = getActiveListIndex(currentState);
    // const activeChannelId: number = getActiveChannelId(currentState);
    // const activeTicketStatusId: number = getActiveTicketStatusId(currentState);
    // const activeDraftStatusId: number = getActiveDraftStatusId(currentState);
    // const createdDateValue = getCreatedDateValue(currentState);
    // const closedDateValue = getClosedDateValue(currentState);
    // const notRepliedSinceDateValue = getNotRepliedSinceDateValue(currentState);
    // const filterSearchString = currentState.ticket.filterSearchString;

    // const filterOptions = {
    //   activeChannelId,
    //   activeDraftStatusId,
    //   createdDateValue,
    //   closedDateValue,
    //   notRepliedSinceDateValue,
    //   ...currentState.ticketFilter.activeFilters,
    // };
    // const fOptions: any = TicketService.prepareInnerTicketQuery({
    //   filterOptions,
    //   appliedFilters: queryData.filters.options,
    // });
    // queryData.filters.options = fOptions;
    const { tickets, ticketMeta } = await getTicketsV2(
      queryData,
      true,
      currentState.innerTicket.filters.currentView,
    );

    setTimeout(() => {
      if (payload.callback) {
        payload.callback();
      }
    }, 0);

    return {
      customerId,
      ticketData: tickets,
      ticketList: ticketMeta.ticketIds.map((data) => parseInt(data)),
      ticketListTotal: ticketMeta.totalCount,
      ticketStatus,
      canAddNew:
        payload?.ticketIds && payload.ticketIds.length !== 0 ? false : true,
    };
  } catch (error) {
    throw error;
  }
};

const fetchIsChannelIntegratedThunk = async (
  payload: IsChannelIntegratedParam,
  { getState }: { getState: Function },
) => {
  try {
    const data = await isIntegratedChannelService(payload);
    return data;
  } catch (error) {
    throw error;
  }
};

const innerTicketThunk = {
  fetchTicketsThunk,
  fetchAllTicketsThunk,
  fetchMessagesThunk,
  sendMessageThunk,
  mergeTicketThunk,
  fetchMergableTicketsThunk, // to get all the tickets for current customer without active ticket in inner ticket and already merged tickets
  editDraftMsgThunk,
  fetchMessagesUpdatesThunk,
  fetchAllTicketsIdListThunk,
  searchCustomersThunk,
  changeTheCustomerThunk,
  fetchMessageByIdsThunk,
  getSnoozeThunk,
  fetchInnerTicketUpdatesThunk,
  fetchIsChannelIntegratedThunk,
};

export default innerTicketThunk;
