/**
 * This file contains the custom hook for handling KB settings.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { getDomainSSLAndAccesControl } from "src/services/KnowledgeBase/Settings/DomainAndAccess/getDomainSSLAndAccesControl.service";
import { updateDomainSSLAndAccesControl } from "src/services/KnowledgeBase/Settings/DomainAndAccess/updateDomainSSLAndAccesControl.service";
import { validateURL } from "src/utils/validations";

/**
 * Custom hook for managing the Domain component in KB settings.
 */
function useDomain() {
  // States and variables
  const { integrationId } = useParams();
  const pageIntegrationId = integrationId
    ? decodeURIComponent(integrationId)
    : "";

  const [showValidation, setShowValidation] = useState<boolean>(false);
  const [IpValidationError, setIpValidationError] = useState<boolean>(false);

  // Query Function to get domain and access control data
  const getDomainAndAccessControl = useQuery({
    queryKey: ["getDomainSSLAndAccesControl"],
    queryFn: () =>
      getDomainSSLAndAccesControl({
        integrationId: pageIntegrationId,
      }),
    onSuccess: (data) => {
      setIpValidationError(false);
    },
  });

  // Mutaton function to domain and access control data
  const updateDomaiAndSslAccessControl = useMutation({
    mutationFn: updateDomainSSLAndAccesControl,
    // onSuccess: () => {
    //   getDomainAndAccessControl.refetch();
    // },
    onError: (error: any) => {
      if (
        error.response.data.errors[0] === "ipWhitelistList not valid format"
      ) {
        setIpValidationError(true);
      }
    },
  });

  const [dropdown, setDropDown] = useState(true);

  const [ownDomain, setOwnDomain] = useState<boolean>(
    getDomainAndAccessControl.data?.customDomain ?? false
  );

  const [domainUrlLoader, setDomainUrlLoader] = useState<boolean>(false);

  // Function to handle own domain change
  const handleOwnDomainChange = (e: any) => {
    setOwnDomain(e.target.checked);
    // Updating domain and access control
    if (validateURL(domainiUrl) && e.target.checked) {
      updateDomaiAndSslAccessControl.mutate({
        integrationId: pageIntegrationId,
        customDomain: e.target.checked,
      });
    } else if (!e.target.checked) {
      updateDomaiAndSslAccessControl.mutate({
        integrationId: pageIntegrationId,
        customDomain: e.target.checked,
      });
    }
  };

  // State
  const [selectedRadio, setSelectedRadio] = useState(
    getDomainAndAccessControl.data?.sslCertificate === "freeSSL"
      ? "flexRadioDefault1"
      : "flexRadioDefault2"
  );

  // State
  const [domainiUrl, setDomainUrl] = useState(
    getDomainAndAccessControl.data?.customDomainUrl ?? ""
  );

  //  Function to handle radio button change
  const handleChangeflexRadioDefault1 = (e: any) => {
    setSelectedRadio("flexRadioDefault1");
    // Updating domain and access control data
    updateDomaiAndSslAccessControl.mutate({
      integrationId: pageIntegrationId,
      sslCertificate: "freeSSL",
    });
  };

  // Function to handle radio button change
  const handleChangeflexRadioDefault2 = (e: any) => {
    setSelectedRadio("flexRadioDefault2");
    // Updating domain and access control data
    updateDomaiAndSslAccessControl.mutate({
      sslCertificate: "customSSL",
      integrationId: pageIntegrationId,
    });
  };

  // Function to handle domain url change
  const handleDomainUrlChange = (e: any) => {
    setDomainUrl(e.target.value);
  };

  // Function to handle change url
  const handleChangeUrl = (e: any) => {
    setDomainUrlLoader(true);
    e.preventDefault();
    // If domain url is empty then show toast
    if (!validateURL(domainiUrl)) {
      setShowValidation(true);
      setDomainUrlLoader(false);
      return;
    }
    setShowValidation(false);
    // Updating domain and access control data
    updateDomaiAndSslAccessControl.mutate(
      {
        customDomainUrl: domainiUrl,
        customDomain: true,
        integrationId: pageIntegrationId,
      },
      {
        onSuccess: () => {
          setDomainUrlLoader(false);
          pushTheToast({
            position: "top-right",
            text: "Domain URL updated successfully",
            type: "success",
          });
        },
      }
    );
  };

  // Use effect to set own domain, selected radio and domain url when data is fetched
  useEffect(() => {
    if (getDomainAndAccessControl.data) {
      setOwnDomain(getDomainAndAccessControl.data.customDomain);
      setSelectedRadio(
        getDomainAndAccessControl.data.sslCertificate === "freeSSL"
          ? "flexRadioDefault1"
          : "flexRadioDefault2"
      );
      setDomainUrl(getDomainAndAccessControl.data.customDomainUrl);
    }
  }, [getDomainAndAccessControl.data]);

  return {
    dropdown,
    setDropDown,
    ownDomain,
    handleOwnDomainChange,
    selectedRadio,
    setSelectedRadio,
    handleChangeflexRadioDefault1,
    handleChangeflexRadioDefault2,
    domainiUrl,
    handleDomainUrlChange,
    isLoading: getDomainAndAccessControl.isLoading,
    data: getDomainAndAccessControl.data,
    handleChangeUrl,
    updateDomaiAndSslAccessControl,
    integrationId: pageIntegrationId,
    isRefetching: getDomainAndAccessControl.isRefetching,
    refetch: getDomainAndAccessControl.refetch,
    domainUrlLoader,
    showValidation,
    IpValidationError,
    setIpValidationError,
  };
}

export default useDomain;
