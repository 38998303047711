import { axiosJSON } from "src/globals/axiosEndPoints";

export interface IGetRecentlyUsedTemplate {
  start: number;
  limit: number;
  ticketId: number | string;
}

export interface ITemplate {
  id: number;
  template_body: string;
  template_category_id: number;
  templateHeading: string;
  categoryName: string;
  previouslyUsedTicketId: number | string | null;
}

export interface IGetTemplateResponse {
  error: string;
  data: Array<ITemplate>;
  count: number;
  totalCount: number;
}

export async function getRecentlyUsedTemplates(
  apiPayload: IGetRecentlyUsedTemplate
) {
  const { data } = await axiosJSON.post(
    "/api/template/getRecentlyUsedTemplates",
    apiPayload
  );

  // console.log(data);

  if (data.error === true) {
    throw new Error(
      "Some Error Occurred while fetching recently used templates"
    );
  }

  return data;
}
