/**
 * This file is the service file used for making api request.
 * It contains the getBrandDetailsByIntegrationId function service which is explained below.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants"; // this is used for testing purpose only for frontend.
import { Brand } from "./getKnowledgeBaseBrands.service";

export interface GetBrandDetailsByIntegrationIdPayload {
  integrationId: string | number;
}

/**
 * For testing frontend with own api link if backend is not prepared.
 */
const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/getBrandDetailsByIntegrationId`
  : "/api/chatSetting/getIntegrationBrandDetails";

/**
 * This service is used to get the brand detail using the live chat integration id.
 */
export const getBrandDetailsByIntegrationId = async (
  params: GetBrandDetailsByIntegrationIdPayload
) => {
  const { data: res } = await axiosJSON.post(apiEndPoint, params);
  return res.data as Brand;
};
