import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { ChatWidExchCustSlice } from "./chatWidExchCust.declarations";
import chatWidExchCustExtraReducers from "./chatWidExchCust.extraReducers";
import {
  fetchAllAssignToAgentsListForFiltering,
  fetchCustomerData,
  fetchCustomerDataByChatId,
  fetchLiveChatIntegratedBrandsThunk,
  fetchLiveChatSidebar,
  markMessageAsSeen,
} from "./chatWidExchCust.thunks";

export function builderFunction(
  builder: ActionReducerMapBuilder<ChatWidExchCustSlice>
) {
  builder.addCase(
    fetchCustomerData.fulfilled,
    chatWidExchCustExtraReducers.fetchCustomerDataFulfilled
  );
  builder.addCase(
    fetchCustomerData.rejected,
    (state: ChatWidExchCustSlice, { payload }) => {
      if (!payload) {
        state.loading = false;
        state.fetchCustAJAXStatus = "rejected";
      }
    }
  );
  builder.addCase(fetchCustomerData.pending, (state: ChatWidExchCustSlice) => {
    state.fetchCustAJAXStatus = "pending";
  });

  //single customer
  builder.addCase(
    fetchCustomerDataByChatId.fulfilled,
    chatWidExchCustExtraReducers.fetchCustomerDataByChatIdFulfilled
  );
  builder.addCase(
    fetchCustomerDataByChatId.rejected,
    (state: ChatWidExchCustSlice) => {
      state.fetchSingleCustomerAJAXStatus = "rejected";
    }
  );
  builder.addCase(
    fetchCustomerDataByChatId.pending,
    (state: ChatWidExchCustSlice) => {
      state.fetchSingleCustomerAJAXStatus = "pending";
    }
  );

  //live chat sidebar
  builder.addCase(
    fetchLiveChatSidebar.fulfilled,
    chatWidExchCustExtraReducers.fetchLiveChatSidebarFulfilled
  );

  builder.addCase(
    fetchAllAssignToAgentsListForFiltering.rejected,
    (state: ChatWidExchCustSlice) => {
      state.agentListAJAXStatus = "rejected";
    }
  );
  builder.addCase(
    fetchAllAssignToAgentsListForFiltering.pending,
    (state: ChatWidExchCustSlice) => {
      state.agentListAJAXStatus = "pending";
    }
  );

  builder.addCase(
    fetchAllAssignToAgentsListForFiltering.fulfilled,
    chatWidExchCustExtraReducers.fetchAllAssignToAgentsListForFilterFulfilled
  );

  builder.addCase(
    fetchLiveChatIntegratedBrandsThunk.fulfilled,
    chatWidExchCustExtraReducers.fetchLiveChatIntegratedBrandsFulfilled
  );

  builder.addCase(
    fetchLiveChatIntegratedBrandsThunk.rejected,
    (state: ChatWidExchCustSlice) => {
      state.liveChatIntegratedBrands.fetchLiveChatIntegratedBrandAJAXStatus =
        "rejected";
    }
  );
  builder.addCase(
    fetchLiveChatIntegratedBrandsThunk.pending,
    (state: ChatWidExchCustSlice) => {
      state.liveChatIntegratedBrands.fetchLiveChatIntegratedBrandAJAXStatus =
        "pending";
    }
  );
}
