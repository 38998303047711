import { Spinner } from "react-bootstrap";
import styles from "./NewCategory.module.scss";
interface Props {
  categoryName: string;
  categoryId: number | string;
  setCategoryName: any;
  handleSubmit: any;
  isUpdate: boolean;
  onHide: (e: any) => void;
  showLoader: boolean
  showValidation?: boolean;
  setShowValidation?: (e: boolean) => void;
}
const NewCategory = ({
  categoryName,
  categoryId,
  setCategoryName,
  handleSubmit,
  isUpdate,
  onHide,
  showLoader,
  showValidation,
  setShowValidation,
}: Props) => {
  return (
    <div className={`container`}>
      <div className={`row`}>
        <div className={`col-12`}>
          <div className={`d-flex justify-content-between mb-2`}>
            <h1 className={`${styles.NewCategoryHead}`}>
              Give category a name
            </h1>
            <span className={`${styles.hideModal}`} onClick={onHide}>
              <i className="fa-solid fa-xmark"></i>
            </span>
          </div>
          <div>
            <input
              className={`${styles.addCategory} ${showValidation ? styles.validationBorder : ""}`}
              type={"text"}
              value={categoryName}
              placeholder={`Type here...`}
              onChange={(e) => {
                if (showValidation && setShowValidation) {
                  setShowValidation(false)
                }
                setCategoryName(e.target.value)
              }}
            />
            {showValidation && <span className={`${styles.validationError}`}>Please Enter a Category Name</span>}
            <div className={`d-flex justify-content-end mt-3`}>
              <button className={`${styles.cancelBtn}`} onClick={onHide}>
                Cancel
              </button>
              <button
                className={`${styles.addBtn}`}
                onClick={(e) => handleSubmit(isUpdate, categoryId)}
              >
                {showLoader ? (
                  <Spinner
                    className="my-auto mx-1"
                    animation="border"
                    color="white"
                    size="sm"
                  />
                ) : (
                  isUpdate ? "Save" : "Add"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewCategory;
