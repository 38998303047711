import { axiosJSON } from "src/globals/axiosEndPoints";
import { validateEmail } from "src/utils/utils";

export interface ITransferTicketApi {
  ticket_link: string;
  ticket_last_message: string;
  ticket_title: string;
  customer_email: string;
  customer_nicename: string;
}
export async function transferTicketApi(
  ticketData: ITransferTicketApi,
  uploadProgress?: (progress: number) => void
) {
  if (!validateEmail(ticketData.customer_email)) {
    throw "Customer e-mail not valid!";
  }
  try {
    const { data } = await axiosJSON.post(
      `/api/ticket/transferTicket`,
      ticketData,
      {
        onUploadProgress: (progressEvent: any) => {
          if (uploadProgress) {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            uploadProgress(progress);
          }
        }
      }
    );
    return data;
  } catch (e) {
    throw "Something went wrong!";
  }
}
