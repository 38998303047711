import FilterWrapper from "../FilterWrapper/FilterWrapper";
import ReportDatePicker from "../ReportDatePicker/ReportDatePicker";
import {
  reportCurrentStatusActions,
  useReportFiltersCreate,
} from "../../hooks/reportFilters/useReportFilters";
import VolumeActivityReports from "./Children/VolumeActivityReports/VolumeActivityReports";
import styles from "./VolumeActivity.module.scss";
import ReportBar from "src/components/svg/ReportBar";

const VolumeActivity = () => {
  const [state, dispatch, Provider] = useReportFiltersCreate();

  return (
    <Provider value={[state, dispatch]}>
      <div
        className={`d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center `}
      >
        <div className={`d-flex align-items-center`}>
          <span>
            <ReportBar />
          </span>
          <span className={`ms-2 ${styles.heading}`}>Volume and Activity</span>
        </div>
        <ReportDatePicker
          startDate={state.startDate}
          endDate={state.endDate}
          onChange={([startDate, endDate]) => {
            dispatch([reportCurrentStatusActions.setStartDate, startDate]);
            dispatch([reportCurrentStatusActions.setEndDate, endDate]);
          }}
        />
      </div>
      <div>
        <FilterWrapper />
        <h3 className={`mt-3 mb-0 ${styles.heading3}`}>Volume activity</h3>
        <VolumeActivityReports />
      </div>
    </Provider>
  );
};

export default VolumeActivity;
