import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";

export interface LiveChatHistoryConversationPayload {
  customerId: string | number;
  filters: {
    sortBy: "newestFirst" | "oldestFirst" | "ticketsFirst" | "chatsFirst";
  };
  start: number;
  limit: number;
}

interface StatusRes {
  id: number | string;
  name: string;
}

export interface Conversation {
  id: string;
  conversationType: "ticket" | "chat";
  chatRequestId?: number | string;
  ticketId?: number | string;
  lastMessageExcerpt: string;
  lastMessageSentTimeUTC: string;
  createdTimeUTC: string;
  ticketStatus?: StatusRes;
  chatStatus?: StatusRes;
}

export interface ConversationData {
  [id: string | number]: Conversation;
}

export interface LiveChatHistoryConversationRes {
  conversationData: ConversationData;
  conversationDataIds: string[];
  metaData: {
    currentCount: number;
    limitReached: boolean;
  };
}

const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/getLiveChatHC`
  : "/api/liveChat/chat/getLiveChatHistoryConversationV2";

export const getLiveChatHistoryConversation = async (
  payload: LiveChatHistoryConversationPayload,
) => {
  const { data: res } = await axiosJSON.post(apiEndPoint, payload);
  const ret: LiveChatHistoryConversationRes = {
    conversationData: {},
    metaData: {
      currentCount: res.metaData.currentCount,
      limitReached: false,
    },
    conversationDataIds: [],
  };
  res.data.forEach((conversation: Conversation) => {
    let idPrefix = "";
    let id: string | number = "";
    if (conversation.conversationType === "ticket") {
      idPrefix = "ticket";
      id = conversation.ticketId || "";
    } else {
      idPrefix = "chat";
      id = conversation.chatRequestId || "";
    }
    const mappedId = `${idPrefix}::${id}`;
    conversation.id = mappedId;
    ret.conversationData[mappedId] = conversation;
    ret.conversationDataIds.push(mappedId);
  });

  if (ret.conversationDataIds.length < payload.limit) {
    ret.metaData.limitReached = true;
  }
  console.log(ret);
  return ret;
};
