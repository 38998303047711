import { useState } from "react";
import Select from "react-select";
import styles from "./TicketCurrentStatusV2.module.scss";
import useChangeTicketStatus from "src/hooks/useChangeTicketStatus";
import { TicketStatus } from "src/services/TicketService/getAllStatuses";

interface Props {
  className?: string;
  currentStatus: TicketStatus;
  onChange: (status: TicketStatus) => void;
  ticketId: string;
  DDClassName?: string;
  canChange: boolean;
}

function TicketCurrentStatusDropDown({
  className = "",
  currentStatus,
  onChange,
  ticketId,
  DDClassName = "",
  canChange,
}: Props) {
  const { changeStatus, loading } = useChangeTicketStatus({
    ticketId: ticketId,
    currentStatusName: currentStatus.statusName,
    onChange,
  });

  const statusOptions = [
    { value: "Open", label: "Open", backGround: "#1492E6", color: "#1492E6" },
    {
      value: "Pending",
      label: "Pending",
      backGround: "#e16411",
      color: "#FF8499",
    },
    {
      value: "Closed",
      label: "Closed",
      backGround: "#589a38",
      color: "#95BF47",
    },
  ];

  const [show, setShow] = useState(false);

  const handleDropdownChange = (selectedOption: any) => {
    changeStatus(selectedOption.value);
  };

  const customStyles = {
    control: (styles: any) => ({
      ...styles,
      background: "#f5f5f5",
      width: "100px",
      minHeight: "32px",
      borderRadius: "7px",
      display: "flex",
      alignItems: "center",
      border: "none",
      marginRight: "2px",
      font: "normal normal normal 12px/18px Poppins",
      cursor: "pointer",
    }),
    menu: (provided: any) => ({
      ...provided,
      zIndex: 9999,
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      borderRadius: "5px",
      font: "normal normal normal 12px/18px Poppins",
      padding: "8px",
      border: "none",
    }),
    option: (styles: any, { data, isFocused }: any) => ({
      ...styles,
      display: "flex",
      alignItems: "center",
      borderRadius: "10px",
      color: isFocused ? "#fff" : data.color,
      backgroundColor: isFocused ? "#1492E6" : undefined,
      cursor: "pointer",
    }),
    singleValue: (styles: any, { data }: any) => ({
      ...styles,
      display: "flex",
      alignItems: "center",
      borderRadius: "10px",
      color: data.color,
    }),
    dropdownIndicator: (styles: any) => ({
      ...styles,
      padding: "0",
      cursor: "pointer",
      color: "#000",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    // Add custom styles for valueContainer here
    valueContainer: (provided: any) => ({
      ...provided,
      padding: "0 8px", // Custom padding
      display: "flex",
      alignItems: "center",
      height: "32px", // Adjust the height if needed
      fontSize: "12px", // Custom font size
      fontFamily: "Poppins, sans-serif", // Font family
      fontWeight: "normal", // Font weight
      lineHeight: "18px", // Adjust line height
    }),
  };

  const DropdownIndicator = () => (
    <i
      className={`me-2 fa-solid d-block m-auto fa-caret-${
        show ? "up" : "down"
      }`}
    ></i>
  );

  const formatSingleValue = ({ data }: any) => (
    <div className="d-flex align-items-center">
      <div
        style={{
          width: "7px",
          height: "7px",
          borderRadius: "50%",
          backgroundColor: data.color,
          marginRight: "8px",
        }}
      ></div>
      <span style={{ color: data.color }}>{data.label}</span>
    </div>
  );

  return (
    <>
      <div className={`d-flex align-items-center ${className}`}>
        {canChange ? (
          <div className={`ms-1`}>
            <Select
              className={`${styles.dropdown}`}
              defaultValue={statusOptions.find(
                (option) => option.value === currentStatus.statusName,
              )}
              options={statusOptions}
              onChange={handleDropdownChange}
              isDisabled={loading !== ""}
              styles={customStyles}
              components={{ DropdownIndicator, SingleValue: formatSingleValue }} // Custom SingleValue component
              onMenuOpen={() => setShow(true)}
              onMenuClose={() => setShow(false)}
              isSearchable={false}
            />
          </div>
        ) : null}
      </div>
    </>
  );
}

export default TicketCurrentStatusDropDown;
