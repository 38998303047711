import { useEffect, useMemo, useState } from "react";
import { getCountryListV2 } from "src/store/slices/globals/globals.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import styles from "./CountryDropdown.module.scss";
import CustomSelectDropdown from "./children/CustomSelectDropdown";

interface Props {
  value: string[]; // Array of selected country codes
  handleChange: (value: string[]) => void; // Callback function to handle changes
  error?: string; // Optional error message
}

// Define the structure of country options
interface Options {
  label: string;
  value: string;
}

const CountryDropdown = ({ value, handleChange, error }: Props) => {
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);

  const countryList = useAppSelector((state) => state.globals.countryList);

  // Effect to fetch country list
  useEffect(() => {
    if (!countryList.loading && !countryList.data?.length) {
      setLoading(true);
      dispatch(getCountryListV2()).finally(() => setLoading(false));
    }
  }, [countryList, dispatch]);

  // Memoized country options derived from countryList data
  const countryOptions = useMemo(() => {
    if (countryList.data) {
      return countryList.data.map((option) => ({
        label: option.name,
        value: option.alpha2Code ?? option.code,
      }));
    }

    return [];
  }, [countryList.data]);

  const [selectedCountry, setSelectedCountry] = useState<Options[]>();

  // Effect to update selectedCountry when value or countryOptions change
  useEffect(() => {
    if (value && Array.isArray(value) && countryOptions) {
      const selectedOptions = value
        .map((value) => countryOptions.find((option) => option.value === value))
        .filter((item) => item !== undefined && item !== null);

      setSelectedCountry(selectedOptions as Options[]);
    }
  }, [value, countryOptions]);

  return (
    <div className="ms-2">
      <CustomSelectDropdown
        selectedOption={selectedCountry}
        setSelectedOption={(value: any) => {
          if (Array.isArray(value)) {
            handleChange(value.map((item) => item.value));
          } else {
            handleChange([value.value]);
          }
        }}
        options={countryOptions}
        menuClassName={`${styles.containDrop} me-2 ${
          error && error.length && styles.errorBorder
        }`}
        placeholder="enter country"
        isMultiSelect={true}
        isSearchable={true}
        isLoading={loading}
        error={selectedCountry?.length ? undefined : error}
      />
    </div>
  );
};

export default CountryDropdown;
