import UserAvatar from "src/components/UserAvatar";
import styles from "./TicketData.module.scss";
import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import { getChannelFAIcon } from "src/utils/utils";
import { useNavigate, useParams } from "react-router-dom";
import SelectTicketCheckBox from "src/components/MergeModal/children/SelectTicketCheckBox/SelectTicketCheckBox";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  isMergedTicketIdSelectedSelector,
  removeSelectedMergeTicketById,
  selectMergeTicketById,
  setLastSearchURL,
} from "src/store/slices/innerTicket/innerTicket.slice";
import AxiosImg from "src/components/AxiosImg";
import {
  TicketDescription,
  highlightSearchedText,
} from "src/routes/TicketList/children/OuterTickets/Chidren/SearchedTickets/SearchedTickets";

interface ITicketData {
  ticketData: any;
  showArrow?: boolean;
  showCheckBox?: boolean;
  hideNCancelMerge?: () => void;
  enableOnClickOpenTicket?: boolean;
}
export default function TicketData({
  ticketData,
  showArrow = true,
  showCheckBox = false,
  hideNCancelMerge = undefined,
  enableOnClickOpenTicket = true,
}: ITicketData) {
  const { tableType, viewType, searchText, ticketId: id }: any = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const scopes = useAppSelector(
    (state) => state.globals.currentUserData?.scopes
  );
  const isTicketSelected = useAppSelector((state) =>
    isMergedTicketIdSelectedSelector(state, ticketData.ticket_id)
  );

  const handleOnClick = (e: any, ticketId: any) => {
    e.stopPropagation();
    if (!e.target.classList.contains("ignoreClick")) {
      /*if(hideNCancelMerge != undefined){
        hideNCancelMerge();
      }*/
      // if can open the ticket on clicking the row
      if (enableOnClickOpenTicket === true) {
        if (searchText) {
          // Get the current path and query
          const currentPath = window.location.pathname;
          const currentQuery = window.location.search;

          // Combine the path and query
          const currentUrl = `${currentPath}${currentQuery}`;
          dispatch(
            setLastSearchURL({
              searchUrl: currentUrl,
              ticketRoute: `/tickets/${tableType}/${viewType}/ticket/${id}/${window.location.search}`,
            })
          );
        }

        navigate(
          `/tickets/${tableType  === "mentions" ? "all" : tableType}/${viewType}/ticket/${ticketId}/${window.location.search}`
        );
      } else {
        if (scopes && scopes.includes("bulk_edit") && showCheckBox === true) {
          // if onclick ticket row open the ticket is disabled and there is check box, check or uncheck the checkbox
          if (isTicketSelected === true) {
            dispatch(removeSelectedMergeTicketById(ticketId));
          } else {
            dispatch(selectMergeTicketById(ticketId));
          }
        }
      }
    }
  };

  return (
    <>
      <div className={` px-3 py-3 ${styles.tableWrapper}`}>
        <div
          className={`d-flex justify-content-between ${styles.ticketData}`}
          onClick={(e) => {
            handleOnClick(e, ticketData.ticket_id);
          }}
        >
          {showCheckBox == false ? (
            <div className={`${styles.ticketNumber}`}>
              <span className={`${styles.ticketId}`}>
                {highlightSearchedText(
                  ticketData.ticket_id.toLocaleString(),
                  searchText
                )}
              </span>
            </div>
          ) : (
            <SelectTicketCheckBox ticketId={ticketData.ticket_id} />
          )}

          <div className={`pe-1 ${styles.nameDesc}`}>
            <div className={`${styles.tickets}`}>
              <span className={`${styles.ticketsHead}`}>
                {highlightSearchedText(ticketData.ticket_subject, searchText)}
              </span>
              <div className={`${styles.ticketsSub}`}>
                {/* {ticketData.ticket_excerpt} */}
              </div>
            </div>
          </div>
          <div className={`${styles.socialMedia}`}>
            <div className={`${styles.channel}`}>
              <span>
                <i
                  className={`fa-brands ${getChannelFAIcon(
                    ticketData.ticket_channel
                  )} override`}
                ></i>
              </span>
            </div>
          </div>
          <div className={`${styles.user}`}>
            <div className={`${styles.customer} d-flex align-items-start`}>
              <UserAvatar
                name={ticketData.ticket_customer_name || "NA"}
                size={30}
              />
              <span className={`ms-2 text-break px-2 ${styles.custName}`}>
                {highlightSearchedText(
                  ticketData.ticket_customer_name,
                  searchText
                )}
              </span>
            </div>
          </div>
          <div className={`${styles.userEmail}`}>
            <div className={`${styles.mailId}`}>
              <span className={`${styles.mailIdSpan}`}>
                {highlightSearchedText(ticketData?.ticket_to, searchText)}
              </span>
            </div>
          </div>
          {/* Added user brand as per the client requirement  */}
          <div className={`ms-2 ${styles.brandData}`}>
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip className={`${styles.mail}`}>
                  {" "}
                  {ticketData.ticket_brand.name}
                </Tooltip>
              }
            >
              {({ ref, ...triggerHandler }) => (
                <div className={` ms-0 me-0`} {...triggerHandler} ref={ref}>
                  <div
                    className={`d-flex align-items-center justify-content-center ${styles.clearText}`}
                  >
                    {ticketData.ticket_brand?.imgURL ? (
                      <div>
                        <AxiosImg
                          className={`${styles.brandImg}`}
                          url={ticketData.ticket_brand?.imgURL}
                        />
                      </div>
                    ) : (
                      <div>
                        <UserAvatar
                          name={ticketData.ticket_brand?.name || "NA"}
                          size={20}
                        />
                      </div>
                    )}
                    <span className={`ps-1 ${styles.brand}`}>
                      {ticketData.ticket_brand.name}
                    </span>
                  </div>
                </div>
              )}
            </OverlayTrigger>
          </div>
          <div className={`${styles.timeSpan}`}>
            <div className={`${styles.date}`}>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip className={`${styles.toolTipCustom}`}>
                    Created on{" "}
                    {ticketData.ticket_created_date
                      ? new Date(
                          ticketData.ticket_created_date
                        ).toLocaleString()
                      : "NA"}
                  </Tooltip>
                }
              >
                {({ ref, ...triggerHandler }) => (
                  <span
                    {...triggerHandler}
                    ref={ref}
                    className={`${styles.size} mx-3`}
                  >
                    <span className={`${styles.timeLog}`}>
                      <span className="me-1">
                        <i className="fas fa-clock"></i>
                      </span>
                      <span>
                        {new Date(
                          ticketData.ticket_created_date
                        ).toLocaleString()}
                      </span>
                    </span>
                  </span>
                )}
              </OverlayTrigger>
            </div>
          </div>
          <div>
            {/* arrow is shown only in few cases */}
            {showArrow == true ? (
              <div className={`${styles.goTo}`}>
                <span className={`${styles.rightArrow}`}>
                  <i className="fa-solid fa-angle-right"></i>
                </span>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <TicketDescription
          ticketDesc={ticketData.last_message}
          searchTerm={searchText}
        />
      </div>
    </>
  );
}
