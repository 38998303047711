import { useCallback, useMemo } from "react";
import { ConfigureSteps } from "../../useConfigureSteps";

const useLiveChatSettings = (configureSteps: ConfigureSteps) => {
  const showStep = useMemo(
    () => configureSteps.currentActiveStep === 3,
    [configureSteps]
  );

  const onHideShow = useCallback(() => {
    configureSteps.setCurrentActiveStep(
      configureSteps.currentActiveStep === 3 ? 0 : 3
    );
  }, [configureSteps]);

  return { showStep, onHideShow };
};

export default useLiveChatSettings;
