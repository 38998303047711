import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { step13AskDetails } from "../step13AskDetails.slice";
import { fetchStep13GetConfig } from "../Thunks/returnExchangeModal.thunk";
import { IAntiRefundOrderTypesConfig } from "../antiRefundOrderTypesConfig.slice";

const step13Builders = (
  builder: ActionReducerMapBuilder<step13AskDetails>
) => {
  builder.addCase(fetchStep13GetConfig.fulfilled, (state, { payload }) => {
    const { returnTypes } = payload;
    if (returnTypes) {
      state.returnTypes = returnTypes;
    }
  });
};

export default step13Builders;
