import { axiosFormData } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";

/**
 * Interface representing the parameters required for uploading an image.
 */
export interface UploadImageParams {
  integrationId: string;
  file: File; //The image file to be uploaded. Accepted types: jpeg, png, jpg, gif, svg.
  productId: string;
  abortControllerSignal?: any;
}
export interface UploadImageResponse {
  attachmentId: string;
  attachmentUrl: string;
}

/**
 * Uploads an image to the backend for a specific product.
 *
 * This function sends a POST request with the image file and associated product and integration IDs
 * using FormData to handle file uploads.
 *
 * @param {UploadImageParams} params - The parameters for the image upload, including the integration ID, product ID, and image file.
 * @returns {Promise<UploadImageResponse>} A promise that resolves to the response data from the server.
 * @throws {Error} Throws an error if the API response contains an error or if an error occurs during the request.
 */
async function uploadImage(
  params: UploadImageParams,
  onUploadProgress: (progressEvent: any) => void,
): Promise<UploadImageResponse> {
  const fData = new FormData();
  fData.append("integrationId", params.integrationId);
  fData.append("file", params.file);
  fData.append("productId", params.productId);

  const apiEndPoint = IS_TEST_ACTIVE
    ? `${process.env.REACT_APP_TEST_SITE_URL}/uploadImage`
    : `/api/setting/automation/refund/product/uploadImage`;
  const { data: res } = await axiosFormData.post(apiEndPoint, fData, {
    signal: params.abortControllerSignal,
    onUploadProgress: onUploadProgress,
  });

  if (res.err || res.error) {
    throw new Error(res.msg ?? res.message ?? "Some Error Occurred.");
  }

  return res.data as UploadImageResponse;
}

export default uploadImage;
