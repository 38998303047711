import { axiosJSON } from "src/globals/axiosEndPoints";
import { SegmentDetails } from "./createSegment.service";

/**
 * Parameters required to fetch a custom segment by its ID.
 */
export interface GetSegmentByIdPayload {
  segmentId: string;
  segmentType: string;
}

/**
 * Fetches the details of a custom segment by its ID from the server.
 *
 * @param params - The parameters including segment ID and segment type.
 * @returns A promise that resolves to the custom segment details.
 * @throws Will throw an error if the server response contains an error.
 */
async function getSegmentByIdService({
  segmentType,
  ...params
}: GetSegmentByIdPayload): Promise<SegmentDetails> {
  // `/api/segment/${params.segmentType}/getById`;
  const { data: res } = await axiosJSON.post(
    `/api/segment/${segmentType}/getSegmentById`,
    params,
  );

  if (res.error || res.err) {
    throw new Error(res.msg ?? res.message ?? "Something went wrong!");
  }

  return { segmentType, ...res.data } as SegmentDetails;
}

export default getSegmentByIdService;
