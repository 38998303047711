import styles from "./AddMessageModal.module.scss";
import greet from "src/assets/images/greeting.png";
import announce from "src/assets/images/announceImg.png";

import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { fetchCreateTargetedMessage } from "src/store/slices/targetedMessage/targetedMessage.thunks";
import { CreateTargetedMessage } from "src/services/LiveChat/Settings/TargetedMessage/createTargetedMessage";
import { RESET } from "src/store/slices/targetedMessage/targetedMessage.slice";

//Props to hide the modal
interface Props {
  onHide: string;
}
const AddMessageModal = ({ onHide }: any) => {
  //Function  to hide the modal
  const handleClose = (e: any) => {
    e.preventDefault();
    onHide(e);
  };

  const { integrationId } = useParams();
  const pageIntegrationId: string = integrationId
    ? decodeURIComponent(integrationId)
    : "";

  const navigate = useNavigate();

  const [radioSelected, setRadioSelected] = useState(false);
  var [selectedRadio, setSelectedRadio] = useState("");

  function handleChange(event: { target: { value: any } }) {
    setRadioSelected(true);
    setSelectedRadio(event.target.value);
  }
  const dispatch = useAppDispatch();

  function handleClick() {
    onHide();
    dispatch(RESET({}));
    if (selectedRadio == "Greeting") {
      navigate(`/live-chat/settings/${pageIntegrationId}/newGreeting`);
    } else {
      navigate(`/live-chat/settings/${pageIntegrationId}/newAnnouncement`);
    }
  }

  // const { createTargetedMessage } = useAppSelector((state) => {
  //   return {
  //     createTargetedMessage: state.targetedMessage.createTargetedMessage,
  //   };
  // });

  // console.log(createTargetedMessage);

  return (
    <>
      <div className={`${styles.modalMain}`}>
        <div
          className={`d-flex justify-content-between align-items-center ${styles.modalHeaderBox} `}
        >
          <p className={`${styles.modalHead}`}>
            What would you like to create?
          </p>
          <span className={`${styles.close}`} onClick={handleClose}>
            <i className="fa-solid fa-xmark"></i>
          </span>
        </div>
        <div>
          <span className={`${styles.selectSpan}`}>
            Select type of Targeted message you want to create :
          </span>
          <div className={`${styles.choseBox} mb-3`}>
            <div className={`d-flex align-items-center mb-2`}>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="targetedMessageType"
                  id="flexRadioDefault2"
                  value="Greeting"
                  onChange={handleChange}
                />
              </div>
              <h5 className={`${styles.greeting}`}>
                <img className={`${styles.checkImg}`} src={greet} alt="greet" />
                Greeting
              </h5>
            </div>
            <div>
              <p className={`${styles.choseBoxPara}`}>
                A recurring and automated message that welcomes and supports
                customers on your website.
              </p>
            </div>
          </div>
          <div className={`${styles.choseBox}`}>
            <div className={`d-flex align-items-center mb-2`}>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="targetedMessageType"
                  id="flexRadioDefault1"
                  value="Announcement"
                  onChange={handleChange}
                />
              </div>
              <h5 className={`${styles.greeting}`}>
                <img
                  className={`${styles.checkImg}`}
                  src={announce}
                  alt="announce"
                />
                Announcement
              </h5>
            </div>
            <div>
              <p className={`${styles.choseBoxPara}`}>
                When your team is not available, customers can fill out the
                ticket form
              </p>
            </div>
          </div>
          <div className={`${styles.actionBtn}`}>
            <button className={`${styles.backBtn}`} onClick={handleClose}>
              Back
            </button>

            {radioSelected ? (
              <button className={`${styles.nextBtn}`} onClick={handleClick}>
                Next
              </button>
            ) : (
              <button className={`${styles.nextBtnDisabled}`} disabled>
                Next
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddMessageModal;
