import { useMemo } from "react";
import {
  ActionGroup,
  Group,
  ICheckListOption,
} from "src/services/Automation/getAutomationById.service";
import { useAutomationContext } from "src/routes/BotSettings/Children/Automation/Hooks/useAutomationContext";
import AssignBox from "../AssignBox";
import { useParams } from "react-router-dom";

const AutomationSummary = ({
  updateActiveStep,
}: {
  updateActiveStep: (val: number) => void;
}) => {
  const { formData } = useAutomationContext();
  const { integrationId } = useParams();
  const triggerQuestion = Object.values(formData.questionsData).find(
    (question) => question.questionKey === "triggers",
  );
  const allowedActionsQuestion = Object.values(formData.questionsData).find(
    (question) => question.questionKey === "triggeredBy",
  );
  const actionsQuestion = Object.values(formData.questionsData).find(
    (question) => question.questionKey === "actions",
  );
  const conditionGroupQuestion = Object.values(formData.questionsData).find(
    (question) => question.questionKey === "conditionGroups",
  );
  const selectedTriggerOption = useMemo(() => {
    return (triggerQuestion?.listOfOptions as ICheckListOption[])?.find(
      (option: ICheckListOption) =>
        option.optionKey == triggerQuestion?.questionValue,
    );
  }, [formData.questionsData, triggerQuestion?.questionValue]);

  const allowedActions = useMemo(() => {
    return (allowedActionsQuestion?.listOfOptions as ICheckListOption[])
      ?.filter((option: ICheckListOption) =>
        (allowedActionsQuestion?.questionValue as string[]).includes(
          option.optionKey,
        ),
      )
      .map((option) => option.optionLabel);
  }, [formData.questionsData, allowedActionsQuestion?.questionValue]);

  // Function to set respective active step for triggers | actions | condition groups
  const edit = (type: "actions" | "conditionGroups" | "triggeredBy") => {
    const getStepIndex = (key: string) => {
      return formData.stepIds
        .map((id, stepIndex) => {
          return formData.stepsData[id].questionKeys.includes(key)
            ? stepIndex
            : null;
        })
        .filter((stepIndex) => stepIndex !== null)[0]; // Get the first valid stepIndex
    };

    let stepIndex: number | null = null;
    stepIndex = getStepIndex(type);

    if (stepIndex !== null && stepIndex !== undefined) {
      updateActiveStep(stepIndex);
    }
  };

  return (
    <div>
      <AssignBox
        selectedTrigger={selectedTriggerOption?.optionLabel ?? ""}
        allowedActions={allowedActions ?? []}
        conditionGroups={
          (conditionGroupQuestion?.conditionGroups as Group[]) ?? []
        }
        actionGroups={(actionsQuestion?.conditionGroups as ActionGroup[]) ?? []}
        integrationId={integrationId ?? ""}
        showEdit={true}
        edit={edit}
      />
    </div>
  );
};

export default AutomationSummary;
