import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Modal, Spinner } from "react-bootstrap";
import { flushSync } from "react-dom";

import { NavLink, useNavigate, useParams } from "react-router-dom";
import InfiniteScroll from "src/components/InfiniteScrollBothSide";
import { useChannel } from "src/hooks/pusherHooks/useChannel";
import { getLiveMessageRecEvent } from "src/pusherServices/livechat/messageEvents";
import SendingAs, {
  ESendTypes,
} from "src/routes/Ticket/children/MainChat/children/SendMessage/children/SendingAs";
import SendMsgCommon from "src/routes/Ticket/children/MainChat/children/SendMsgCommon/SendMsgCommon";
import {
  Message,
  Messages,
} from "src/services/LiveChat/messageExchange/getMessages";
import { selectedCustomerSelector } from "src/store/slices/liveChatSetting/chatWidExchCust/chatWidExchCust.selectors";
import { actions } from "src/store/slices/liveChatSetting/chatWidExchCust/chatWidExchCust.slice";
import {
  fetchCustomerDataByChatId,
  fetchLiveChatSidebar,
  markMessageAsSeen,
} from "src/store/slices/liveChatSetting/chatWidExchCust/chatWidExchCust.thunks";
import {
  addNewMessageChatWidExchMsg,
  markMessagesSeen, // Importing reducer for marking messages as seen
  resetChatWidExchMsg,
  setActiveCustomerInfo,
  appendMessageBeforeSending,
} from "src/store/slices/liveChatSetting/chatWidExchMsg/chatWidExchMsg.slice";
import {
  fetchLiveChatMessgeUpdates,
  fetchMsgData,
} from "src/store/slices/liveChatSetting/chatWidExchMsg/chatWidExchMsg.thunks";
import { useAppDispatch, useAppSelector } from "src/store/store";
import styles from "../../../../LiveChat.module.scss";
import { getChatIdRouteLink } from "../../../ToggleCustomerSB/ToggleCustomerSB";
import LiveSendMessage from "../LiveSendMessage";
import MessageIn from "../MessageIn/MessageIn";
import MessageOut from "../MessageOut/MessageOut";
import { v4 as uuid } from "uuid";
import MessageInternalNote from "../MessageInternalNote/MessageInternalNote";
import MessageNoti from "../MessageNoti";
import PreChatForm from "../PreChatBox/PreChatBox";
import PreChatBox from "../PreChatBox/PreChatBox";
import PickBox from "./PickBox/PickBox";
import { setAssignChatAjaxStatus } from "src/store/slices/liveChatTraffic/liveChatTraffic.slice";
import {
  assignChat,
  IAssignChatRes,
} from "src/services/LiveChat/traffic/assignChatFromTraffic.service";
import { ELiveChatAssignChatStatusCode } from "src/enums/ELiveChatAssignChatStatusCode";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import {
  Customer,
  SOCIAL_CHANNELS,
} from "src/services/LiveChat/messageExchange/getAllCustomers";
import {
  assignMissedChat,
  IAssignMissedChatRes,
} from "src/services/LiveChat/messageExchange/assignMissedChat";
import { ELiveChatAssignMissedChatStatusCode } from "src/enums/ELiveChatAssignMissedChatStatusCode";
import MissedChatPickBox from "./MissedChatPickBox/MissedChatPickBox";
import renderAllMessages from "./hooks/renderAllMessages";
import styledCss from "./Conversation.module.scss";
import useShowLoader from "./hooks/useShowLoader";
import useShowError from "./hooks/useShowError";
import WhatsappChatPickBox from "./WhatsappChatPickBox/WhatsappChatPickBox";
import MessageSchedule from "src/components/MessageSchedule/MessageSchedule";
import BotHandOverBox from "./BotHandOverBox/BotHandOverBox";
import BotChatPickBox from "./BotChatPickBox/BotChatPickBox";
import Lottie from "lottie-light-react";
import botImg from "src/assets/images/botImg.json";
import TakeOvermodal from "src/components/TakeOverModal/TakeOvermodal";

// Created hook to check only if api is failed
export const useCheckJustErrorInConversation = () => {
  const { messageIdList, fetchMsgAJAXStatus } = useAppSelector(
    (state) => state.chatWidExhMsg,
  );
  const { fetchCustAJAXStatus, chatIdList } = useAppSelector(
    (state) => state.chatWidExchCust,
  );

  const { chatId } = useParams();
  const paramChatId = chatId ? decodeURIComponent(chatId) : "";
  return useMemo(() => {
    return (
      fetchMsgAJAXStatus === "rejected" || fetchCustAJAXStatus === "rejected"
    );
  }, [
    fetchMsgAJAXStatus,
    messageIdList,
    paramChatId,
    fetchCustAJAXStatus,
    chatIdList,
  ]);
};

// Created hook to check if api is failed or data is empty
export const useCheckErrorInConversation = () => {
  const { messageIdList, fetchMsgAJAXStatus } = useAppSelector(
    (state) => state.chatWidExhMsg,
  );
  const { fetchCustAJAXStatus, chatIdList, filters } = useAppSelector(
    (state) => state.chatWidExchCust,
  );

  const { chatId } = useParams();
  const paramChatId = chatId ? decodeURIComponent(chatId) : "";
  return useMemo(() => {
    return (
      fetchMsgAJAXStatus === "rejected" ||
      fetchCustAJAXStatus === "rejected" ||
      (fetchMsgAJAXStatus === "fulfilled" && messageIdList.length === 0) ||
      (paramChatId == "all" &&
        fetchCustAJAXStatus === "fulfilled" &&
        fetchMsgAJAXStatus !== "pending" &&
        fetchMsgAJAXStatus !== "idle" &&
        messageIdList.length === 0) ||
      (fetchCustAJAXStatus === "fulfilled" &&
        chatIdList.length === 0 &&
        !filters.searchValue.trim())
    );
  }, [
    fetchMsgAJAXStatus,
    messageIdList,
    paramChatId,
    fetchCustAJAXStatus,
    chatIdList,
    filters,
  ]);
};

export interface EventChatIds {
  [chatId: number | string]: number | string;
}

function ChatSection() {
  // Defining the flags to show loading or load more on load buttons.
  const [showLoadMoreTop, setShowLoadMoreTop] = useState(true);
  const [showLoadMoreBottom, setShowLoadMoreBottom] = useState(true);

  const {
    customers,
    chatIdList,
    fetchCustAJAXStatus,
    metaData: chatMetaData,
    filters,
  } = useAppSelector((state) => state.chatWidExchCust);

  const currentUserData = useAppSelector(
    (state) => state.globals.currentUserData,
  );

  const scheduleMessageVisible = useMemo(() => {
    return currentUserData?.ui_visibility?.includes(
      "live_chat_schedule_message",
    );
  }, [currentUserData?.ui_visibility]);

  const {
    messageIdList,
    messages,
    activeCustomerInfo,
    metaData,
    fetchMsgAJAXStatus,
    fetchMessageLimit,
  } = useAppSelector((state) => state.chatWidExhMsg);

  const errorInCustFetchOrLoading = useMemo(() => {
    return (
      (fetchCustAJAXStatus !== "fulfilled" &&
        chatIdList.length === 0 &&
        messageIdList.length === 0) ||
      (chatMetaData.limitReached == undefined &&
        !activeCustomerInfo &&
        messageIdList.length === 0) ||
      activeCustomerInfo == null
    );
  }, [
    fetchCustAJAXStatus,
    chatIdList,
    chatMetaData.limitReached,
    activeCustomerInfo,
    messageIdList.length,
  ]);
  const [showAssignMissedChatLoader, setShowAssignMissedChatLoader] =
    useState(false);
  const { assignChatAjaxStatus, assignChatStatusCode } = useAppSelector(
    (state) => state.liveChatTraffic,
  );
  const navigate = useNavigate();

  const scrollToRef = useRef(null);
  const [scrolledId, setScrolledId] = useState(null as null | string | number);
  const scrollToBottom = useRef(false);
  const { chatId, chatStatus } = useParams();
  //commenting this state as it not used anymore (it was used to show the assigned agent info above send message)
  // const [assignedAgentInfo, setAssignedAgentInfo] = useState<any>({
  //   id: 0,
  //   name: "",
  //   imgURL: null,
  // });
  useMemo(() => {
    setScrolledId(null);
  }, [chatId]);
  const [canReply, setCanReply] = useState(true);
  const paramChatId = chatId ? decodeURIComponent(chatId) : "";
  const paramChatStatus = chatStatus ? decodeURIComponent(chatStatus) : "";

  const errorInConversation = useCheckErrorInConversation();
  const justErrorInConversation = useCheckJustErrorInConversation();

  const selectedChatIdRef = useRef<any>(0);
  const currentState = useRef({
    metaData: metaData,
    fetchMsgAJAXStatus: fetchMsgAJAXStatus,
    activeCustomerInfo: activeCustomerInfo,
    currentUserData: currentUserData,
    messageIdList: messageIdList,
    fetchMessageLimit,
    filters,
    chatId: paramChatId,
    activeChatStatus: paramChatStatus,
    // messages: messages
    customers,
  });

  useMemo(() => {
    currentState.current.metaData = metaData;
    currentState.current.fetchMsgAJAXStatus = fetchMsgAJAXStatus;
    currentState.current.activeCustomerInfo = activeCustomerInfo;
    currentState.current.currentUserData = currentUserData;
    currentState.current.messageIdList = messageIdList;
    currentState.current.fetchMessageLimit = fetchMessageLimit;
    currentState.current.filters = filters;
    currentState.current.chatId = paramChatId;
    currentState.current.activeChatStatus = paramChatStatus;
    currentState.current.customers = customers;

    // currentState.current.messages = messages;
    if (activeCustomerInfo) {
      setCanReply(activeCustomerInfo.canReply);

      // if (activeCustomerInfo.chatAssignedTo?.id) {
      //   setAssignedAgentInfo(activeCustomerInfo.chatAssignedTo);
      // }
    }
  }, [
    metaData,
    fetchMsgAJAXStatus,
    activeCustomerInfo,
    currentUserData,
    messageIdList,
    fetchMessageLimit,
    filters,
    paramChatId,
    paramChatStatus,
    customers,
  ]);

  const previousSelectedCustomerStatus = useRef<any>(null);
  const lastMessageIntersectRef = useRef<any>(null);
  const lastReadMessageId = useRef<number>();

  const dispatch = useAppDispatch();

  const showLoaderEle = useShowLoader({
    styles,
    errorInCustFetchOrLoading,
    fetchMsgAJAXStatus,
    messageIdList,
  });

  const showErrorEle = useShowError({
    errorInConversation,
    errorInCustFetchOrLoading,
    justErrorInConversation,
    styles,
  });

  const messageIdListReversed = useMemo(() => {
    const reversedId = [...messageIdList].reverse();
    if (scrollToBottom.current === true) {
      scrollToBottom.current = false;
    } else {
      setScrolledId((prev) => {
        if (prev === null) {
          if (
            currentState.current.filters?.showMentions &&
            metaData?.unreadMentionMessageId
          ) {
            return metaData.unreadMentionMessageId + "";
          } else {
            return reversedId[reversedId.length - 1] ?? null;
          }
        } else {
          return (
            reversedId[
              reversedId.length - currentState.current.fetchMessageLimit
            ] ?? null
          );
        }
      });
    }
    return reversedId;
  }, [messageIdList, metaData]);
  const updateSidebarCount = () => {
    if (
      currentState.current.activeCustomerInfo?.canReply == true &&
      currentState.current.activeCustomerInfo?.chatStatus !== "queued"
    ) {
      // dispatch(
      //   actions.updateSidebar({
      //     chatId: paramChatId,
      //     status: "live",
      //     isDelete: true,
      //   })
      // );
      dispatch(fetchLiveChatSidebar());

      //updating missed message count to 0
      dispatch(
        actions.setMissedMessageCount({ chatId: paramChatId ?? "", count: 0 }),
      );

      const activeCustomer: Customer = {
        ...currentState.current.activeCustomerInfo,
        missedMessages: 0,
      };
      dispatch(setActiveCustomerInfo({ customerInfo: activeCustomer }));
    }
  };

  const globals = useAppSelector((state) => state.globals);

  useEffect(() => {
    if (paramChatId && paramChatId !== "" && paramChatId !== "all") {
      // setAssignedAgentInfo(null);
      dispatch(resetChatWidExchMsg());
      const activeCustomer =
        currentState.current.customers[paramChatId] ?? null;
      dispatch(setActiveCustomerInfo({ customerInfo: activeCustomer }));
      dispatch(actions.setActiveChatId({ id: parseInt(paramChatId) }));
      dispatch(fetchCustomerDataByChatId({ payload: paramChatId }));
      dispatch(
        fetchMsgData({
          chatRequestId: paramChatId,
          chatStatus: paramChatStatus as "missed" | "live" | "archived",
        }),
      );
      selectedChatIdRef.current = paramChatId;
    }
  }, [paramChatId]);

  // When scrolling from top.
  const handleInfiniteScrollTop = useCallback(() => {
    if (
      currentState.current !== null &&
      currentState.current.fetchMsgAJAXStatus === "fulfilled" &&
      selectedChatIdRef.current
    ) {
      if (currentState.current.metaData.limitReachedOld ? false : true) {
        // Setting load more button show false
        setShowLoadMoreTop(false);
        dispatch(
          fetchMsgData({
            chatRequestId: selectedChatIdRef.current,
            chatStatus: paramChatStatus as "missed" | "live" | "archived",
            scrollDirection: "old",
          }),
        );
      }
    }
  }, [paramChatStatus]);

  // When scrolling from bottom.
  const handleInfiniteScrollBottom = useCallback(() => {
    if (
      currentState.current !== null &&
      currentState.current.fetchMsgAJAXStatus === "fulfilled" &&
      selectedChatIdRef.current
    ) {
      if (currentState.current.metaData.limitReachedNew ? false : true) {
        // Setting load more button show false
        setShowLoadMoreBottom(false);
        dispatch(
          fetchMsgData({
            chatRequestId: selectedChatIdRef.current,
            chatStatus: paramChatStatus as "missed" | "live" | "archived",
            scrollDirection: "new",
          }),
        );
      }
    }
  }, [paramChatStatus]);

  const sendMessageAjaxStatus = useAppSelector(
    (state) => state.chatWidExhMsg.sendMsgAJAXStatus,
  );

  useEffect(() => {
    //for message has been read
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting && entry.isIntersecting === true) {
          const lastMessageId = currentState.current.messageIdList[0];
          if (lastMessageId) {
            // const message = currentState.current.messages[lastMessageId];

            //checking if last message is already seen
            if (
              currentState.current.activeCustomerInfo?.chatAssignedTo &&
              currentState.current.activeCustomerInfo?.chatAssignedTo
                ?.length !== 0 &&
              currentState.current.activeCustomerInfo?.missedMessages != 0 &&
              currentState.current.activeCustomerInfo?.chatStatus !==
                "queued" &&
              currentState.current.activeCustomerInfo?.canReply === true
            ) {
              //checking if last message Id is already seen
              if (lastReadMessageId.current != lastMessageId) {
                lastReadMessageId.current = parseInt(lastMessageId + "");
                // Send a message to the server that the user has seen the message.
                dispatch(
                  markMessageAsSeen({
                    lastMessageId: lastMessageId,
                    chatId: currentState.current.activeCustomerInfo.chatId,
                  }),
                ).then((res) => {
                  const status = res.payload; // Getting the status of the message from the response
                  const senderType = messages[lastMessageId].senderType; // Getting the sender type of the message
                  // Dispatching the action to mark the messages as seen
                  // dispatch(
                  //   markMessagesSeen({
                  //     lastMessageId: lastMessageId,
                  //     seenStatus: status,
                  //     senderType: senderType,
                  //   })
                  // );
                });

                updateSidebarCount();
              }
            }
          }
          return;
        }
      },
      {
        root: null,
        threshold: 0.1,
      },
    );
    if (lastMessageIntersectRef.current) {
      observer.observe(lastMessageIntersectRef.current);
    }

    return () => {
      if (lastMessageIntersectRef.current) {
        observer.unobserve(lastMessageIntersectRef.current);
      }
    };
  }, [messageIdList]);

  const handleChatAssignment = (assignChatId: number | string) => {
    dispatch(setAssignChatAjaxStatus({ ajaxStatus: "pending" }));
    assignChat({ chatRequestId: assignChatId })
      .then((res: IAssignChatRes) => {
        dispatch(setAssignChatAjaxStatus({ ajaxStatus: "fulfilled" }));
        if (res.statusCode === ELiveChatAssignChatStatusCode.CHAT_ASSIGNED) {
          pushTheToast({
            position: "top-right",
            type: "success",
            text: "Chat assigned.",
          });
          const customer = currentState.current.activeCustomerInfo;
          if (customer) {
            let activeCustomer: Customer = {
              ...customer,
            };
            activeCustomer["isConnected"] = true;
            activeCustomer["canReply"] = true;
            activeCustomer["chatAssignedTo"] = [
              {
                id: currentState.current.currentUserData?.userId ?? 0,
                name:
                  currentState.current.currentUserData?.firstName +
                  " " +
                  currentState.current.currentUserData?.lastName,
                imgUrl:
                  currentState.current.currentUserData?.profileImageUrl ?? null,
              },
            ];
            activeCustomer["chatStatus"] = "";
            dispatch(setActiveCustomerInfo({ customerInfo: activeCustomer }));
            if (currentState.current.messageIdList[0]) {
              dispatch(
                markMessageAsSeen({
                  lastMessageId: currentState.current.messageIdList[0],
                  chatId: customer.chatId,
                }),
              );
              updateSidebarCount();
            }
          }
        } else if (
          res.statusCode === ELiveChatAssignChatStatusCode.CHAT_NOT_ASSIGNED
        ) {
          pushTheToast({
            position: "top-right",
            type: "warning",
            text: "Failed to assign chat.",
          });
        } else if (
          res.statusCode ===
          ELiveChatAssignChatStatusCode.INVALID_CHAT_REQUEST_ID
        ) {
        } else if (
          res.statusCode === ELiveChatAssignChatStatusCode.INVALID_USER
        ) {
        } else if (
          res.statusCode === ELiveChatAssignChatStatusCode.LIMIT_REACHED
        ) {
          pushTheToast({
            position: "top-right",
            type: "warning",
            text: "Chat limit reached.",
          });
        }
      })
      .catch((err) => {
        dispatch(setAssignChatAjaxStatus({ ajaxStatus: "rejected" }));
        pushTheToast({
          position: "top-right",
          type: "danger",
          text: err,
        });
      });
  };

  const handleMissedChatAssignment = (assignChatId: number | string) => {
    // dispatch(setAssignChatAjaxStatus({ ajaxStatus: "pending" }));
    setShowAssignMissedChatLoader(true);
    assignMissedChat({ chatRequestId: assignChatId })
      .then((res: IAssignMissedChatRes) => {
        // dispatch(setAssignChatAjaxStatus({ ajaxStatus: "fulfilled" }));
        setShowAssignMissedChatLoader(false);
        dispatch(fetchLiveChatSidebar());

        if (
          res.statusCode === ELiveChatAssignMissedChatStatusCode.CHAT_ASSIGNED
        ) {
          pushTheToast({
            position: "top-right",
            type: "success",
            text: "Chat assigned.",
          });
          const customer = currentState.current.activeCustomerInfo;
          if (customer) {
            let activeCustomer: Customer = {
              ...customer,
            };
            activeCustomer["isConnected"] = true;
            activeCustomer["canReply"] = true;
            activeCustomer["chatAssignedTo"] = [
              {
                id: currentState.current.currentUserData?.userId ?? 0,
                name:
                  currentState.current.currentUserData?.firstName +
                  " " +
                  currentState.current.currentUserData?.lastName,
                imgUrl:
                  currentState.current.currentUserData?.profileImageUrl ?? null,
              },
            ];
            activeCustomer["chatStatus"] = "";
            dispatch(setActiveCustomerInfo({ customerInfo: activeCustomer }));
            if (currentState.current.messageIdList[0]) {
              dispatch(
                markMessageAsSeen({
                  lastMessageId: currentState.current.messageIdList[0],
                  chatId: customer.chatId,
                }),
              );
            }
          }
        } else if (
          res.statusCode ===
          ELiveChatAssignMissedChatStatusCode.CHAT_NOT_ASSIGNED
        ) {
          pushTheToast({
            position: "top-right",
            type: "warning",
            text: "Failed to assign chat.",
          });
        } else if (
          res.statusCode ===
          ELiveChatAssignMissedChatStatusCode.INVALID_CHAT_REQUEST_ID
        ) {
        } else if (
          res.statusCode === ELiveChatAssignMissedChatStatusCode.INVALID_USER
        ) {
        } else if (
          res.statusCode ===
          ELiveChatAssignMissedChatStatusCode.ALREADY_ASSIGNED
        ) {
          pushTheToast({
            position: "top-right",
            type: "warning",
            text: "Chat is already assigned to someone.",
          });
        }
      })
      .catch((err) => {
        setShowAssignMissedChatLoader(false);
        pushTheToast({
          position: "top-right",
          type: "danger",
          text: err,
        });
      });
  };
  // For message schedule modal
  const [showModal, setShowModal] = useState(false);
  const onShow = (e: any) => {
    setShowModal(true);
  };
  const onHide = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (!activeCustomerInfo?.scheduledCount) {
      setShowModal(false);
    }
  }, [activeCustomerInfo?.scheduledCount]);

  const handleBotChatAssignment = useCallback(
    (assignChatId: number | string, isPreChatForm?: boolean) => {
      setShowAssignMissedChatLoader(true);
      assignChat({
        chatRequestId: assignChatId,
        requirePreChatForm: isPreChatForm,
      })
        .then((res: IAssignChatRes) => {
          if (res.statusCode === ELiveChatAssignChatStatusCode.CHAT_ASSIGNED) {
            pushTheToast({
              position: "top-right",
              type: "success",
              text: "Chat assigned.",
            });
            const customer = currentState.current.activeCustomerInfo;
            if (customer) {
              let activeCustomer: Customer = {
                ...customer,
              };
              activeCustomer["isConnected"] = true;
              activeCustomer["canReply"] = true;
              activeCustomer["chatAssignedTo"] = [
                {
                  id: currentState.current.currentUserData?.userId ?? 0,
                  name:
                    currentState.current.currentUserData?.firstName +
                    " " +
                    currentState.current.currentUserData?.lastName,
                  imgUrl:
                    currentState.current.currentUserData?.profileImageUrl ??
                    null,
                },
              ];
              activeCustomer["chatStatus"] = "";
              dispatch(setActiveCustomerInfo({ customerInfo: activeCustomer }));
              currentState.current.activeCustomerInfo = customer;

              if (currentState.current.messageIdList[0]) {
                dispatch(
                  markMessageAsSeen({
                    lastMessageId: currentState.current.messageIdList[0],
                    chatId: customer.chatId,
                  }),
                );
                setTimeout(() => {
                  updateSidebarCount();
                }, 0);
              }
            }
          } else if (
            res.statusCode === ELiveChatAssignChatStatusCode.CHAT_NOT_ASSIGNED
          ) {
            pushTheToast({
              position: "top-right",
              type: "warning",
              text: "Failed to assign chat.",
            });
          } else if (
            res.statusCode ===
            ELiveChatAssignChatStatusCode.INVALID_CHAT_REQUEST_ID
          ) {
          } else if (
            res.statusCode === ELiveChatAssignChatStatusCode.INVALID_USER
          ) {
          } else if (
            res.statusCode === ELiveChatAssignChatStatusCode.LIMIT_REACHED
          ) {
            pushTheToast({
              position: "top-right",
              type: "warning",
              text: "Chat limit reached.",
            });
          }
        })
        .catch((err) => {
          pushTheToast({
            position: "top-right",
            type: "danger",
            text: err,
          });
        })
        .finally(() => {
          setShowAssignMissedChatLoader(false);
        });
    },
    [],
  );

  //flag to show "waiting for user to fill form loader" in conversation section
  const waitingForFromComplete = useAppSelector(
    (state) => state.chatWidExhMsg.metaData.waitingForFromComplete,
  );

  useEffect(() => {
    const unsentMessage = JSON.parse(
      localStorage.getItem("unsentMessagesLiveChat") || "[]",
    );

    if (unsentMessage.length > 0) {
      unsentMessage.forEach((msg: any) => {
        if (msg.chat_id == paramChatId) {
          const messageToAppend: Message = {
            messageId: msg.uuid,
            messageText: msg.message,
            sentBy: {
              id: globals.currentUserData?.userId,
              name: globals.currentUserData?.firstName,
              email: globals.currentUserData?.email,
              imageURL: globals.currentUserData?.profileImageUrl,
            },
            sendTime: new Date().toString(),
            sendTimeGmt: new Date().toString(),
            isEvent: false,
            sentByType: "out",
            senderType: "Agent",
            isInternalNote: msg.messageType === "note",
            messageType: msg.messageType,
            messageUuid: msg.uuid,
            messageStatus: "rejected",
            attachments: msg.attachments,
            messageSeenStatus: false, // this is for the message seen status of the agent
          };
          dispatch(appendMessageBeforeSending(messageToAppend));
        }
      });
    }
  }, [paramChatId]);

  // useEffect hook to navigate to the appropriate chat URL when active customer info changes
  useEffect(() => {
    // Check if activeCustomerInfo.customerStatus is defined
    if (activeCustomerInfo?.customerStatus !== undefined) {
      // Navigate to the appropriate chat URL based on activeCustomerInfo.chatId
      if (
        activeCustomerInfo.chatId &&
        currentState.current.chatId == activeCustomerInfo.chatId &&
        currentState.current.activeChatStatus !==
          activeCustomerInfo.customerStatus
      ) {
        // currentState.current.activeCustomerInfo
        navigate(
          `/live-chat/chats/${activeCustomerInfo.customerStatus}/${activeCustomerInfo.chatId}`,
        );
      }
    }
  }, [
    activeCustomerInfo?.chatStatus === "queued",
    activeCustomerInfo?.customerStatus,
    activeCustomerInfo?.chatId,
  ]);

  // Created has more top memo to do optimize it and reuse it
  const hasMoreTop = useMemo(() => {
    return fetchMsgAJAXStatus === "rejected"
      ? false
      : messageIdList.length === 0
        ? false
        : metaData.limitReachedOld
          ? false
          : true;
  }, [fetchMsgAJAXStatus, messageIdList, metaData]);

  // Created has more bottom memo to do optimize it and reuse it
  const hasMoreBottom = useMemo(() => {
    return fetchMsgAJAXStatus === "rejected"
      ? false
      : messageIdList.length === 0
        ? false
        : metaData.limitReachedNew
          ? false
          : true;
  }, [fetchMsgAJAXStatus, messageIdList, metaData]);

  // Effect to show the load more button again if loading done.
  useEffect(() => {
    if (fetchMsgAJAXStatus === "fulfilled") {
      setShowLoadMoreTop(true);
      setShowLoadMoreBottom(true);
    }
  }, [fetchMsgAJAXStatus]);

  const [isTakeOverModalVisible, setIsTakeOverModalVisible] = useState(false);

  /**
   * Handler for calling take over chat and hiding overlay wrapper
   */
  const clickHandler = useCallback(
    (isPreChatForm: boolean) => {
      if (activeCustomerInfo?.chatId) {
        handleBotChatAssignment(activeCustomerInfo.chatId, isPreChatForm);
        setIsTakeOverModalVisible(false);
      }
    },
    [activeCustomerInfo?.chatId, handleBotChatAssignment],
  );

  useEffect(() => {
    setIsTakeOverModalVisible(false);
  }, [paramChatId]);

  return (
    <div
      className={`${styles.mainChat} ${
        errorInCustFetchOrLoading ? styles.loading : ""
      } position-relative`}
    >
      {/* Take Over Wrapper */}
      {isTakeOverModalVisible && (
        <div className={`${styles.modalWrapper}`}>
          <TakeOvermodal
            selectionHandler={clickHandler}
            handleClose={() => setIsTakeOverModalVisible(false)}
          />
        </div>
      )}

      {/* Main Chat List */}
      <InfiniteScroll
        className={`mt-0 mb-auto mx-auto mt-auto w-100 ${styles.chatSec} ${
          activeCustomerInfo?.isConnected == false
            ? `${styles.hideSendMessage}`
            : ""
        } ${showErrorEle || showLoaderEle ? "d-flex flex-column" : ""}`}
        loadMoreFromTop={handleInfiniteScrollTop}
        hasMoreTop={hasMoreTop}
        loadMoreFromBottom={handleInfiniteScrollBottom}
        hasMoreBottom={hasMoreBottom}
        scrollToChildRef={scrollToRef}
        scrollToDependency={[scrolledId]}
        infiniteLoader={
          <div className={`${styles.loadingText}`}>
            <span>Loading...</span>
            <div>
              <Spinner
                className={`ms-1 ${styles.spinner}`}
                size="sm"
                animation="border"
              />
            </div>
          </div>
        }
        infiniteLoaderTop={
          showLoadMoreTop ? (
            // Rendering the load button conditionally.
            <div
              className={`${styles.loaderText} mb-2`}
              onClick={handleInfiniteScrollTop}
            >
              <span>Load More</span>
              <i className="ms-1 fa-solid fa-rotate-right"></i>
            </div>
          ) : undefined
        }
        infiniteLoaderBottom={
          showLoadMoreBottom ? (
            // Rendering the load button conditionally.
            <div
              className={`${styles.loaderText}`}
              onClick={handleInfiniteScrollBottom}
            >
              <span>Load More</span>
              <i className="ms-1 fa-solid fa-rotate-right"></i>
            </div>
          ) : undefined
        }
      >
        {showErrorEle ? (
          showErrorEle
        ) : showLoaderEle ? (
          showLoaderEle
        ) : (
          // Showing the data with a height to make sure the data should always scroll if window height is larger.
          <div
            className={`d-flex flex-column ${styles.chatWrapper}`}
            style={{ minHeight: "calc(100vh - 200px)", height: "auto" }}
          >
            {renderAllMessages(
              messageIdListReversed,
              messages,
              false,
              scrolledId,
              scrollToRef,
            )}
            <div ref={lastMessageIntersectRef}></div>
            {messageIdList.length > 0 || paramChatId !== "all"
              ? messageIdList.length > 0 &&
                fetchMsgAJAXStatus !== "pending" &&
                metaData.limitReachedNew === true && (
                  <div className={`pb-3 mb-0 mt-auto`}>
                    {/* checking if waiting for user to complete form flag is enabled, if yes show the loader */}
                    {waitingForFromComplete && (
                      <div className="d-flex flex-column justify-content-center">
                        <span
                          className={`${styles.lottieImg} d-flex justify-content-center`}
                        >
                          {/* Default Bot Image */}
                          <Lottie animationData={botImg}></Lottie>
                        </span>
                        <span
                          className={`${styles.botLoaderMessage}  d-flex justify-content-center`}
                        >
                          Waiting for visitor to complete form...
                        </span>
                      </div>
                    )}

                    {activeCustomerInfo?.isConnected !== true ||
                    activeCustomerInfo?.isCustomerOnline !== true ||
                    (activeCustomerInfo.scheduledCount &&
                      activeCustomerInfo?.scheduledCount > 0) ? (
                      <div
                        className={`mx-1 p-2 pb-0 d-flex justify-content-between align-items-center ${styles.showDisconnected}`}
                      >
                        {activeCustomerInfo?.isConnected !== true ||
                        activeCustomerInfo?.isCustomerOnline !== true ? (
                          <div className="d-flex align-items-center">
                            <i className="material-icons mx-1 my-auto">
                              &#xe88f;
                            </i>
                            <span>
                              {`The chat is disconnected.${
                                !activeCustomerInfo?.isAnonymousCustomer
                                  ? " Any responses will be sent via e-mail."
                                  : ""
                              }`}
                            </span>
                          </div>
                        ) : null}

                        <Modal
                          backdropClassName={`${styledCss.modalBack}`}
                          show={showModal}
                          onHide={onHide}
                          dialogClassName={`${styledCss.modalDialog}`}
                          contentClassName={`${styledCss.modalContent}`}
                          animation={true}
                          enforceFocus={false}
                        >
                          <MessageSchedule
                            onHide={onHide}
                            scheduleMessageFor="liveChat"
                          />
                        </Modal>
                        {scheduleMessageVisible &&
                        activeCustomerInfo?.scheduledCount &&
                        activeCustomerInfo.scheduledCount !== 0 ? (
                          <div>
                            <span
                              className={`ps-2 cursor-pointer ${styledCss.messageCount}`}
                              onClick={onShow}
                            >
                              {`${activeCustomerInfo.scheduledCount} Messages Scheduled`}
                            </span>
                          </div>
                        ) : null}
                      </div>
                    ) : null}

                    <div className={`${styles.sendMessageContainer}`}>
                      {/* checking if chat is connected to bot then showing take over chat in the send message input overlay */}
                      {activeCustomerInfo?.chatStatus ===
                      "chat_in_progress_ai" ? (
                        <BotChatPickBox
                          isConnected={activeCustomerInfo?.isConnected} // 02 Jan added to change text when bot chat disconnected
                          onClick={() =>
                            SOCIAL_CHANNELS.includes(
                              activeCustomerInfo.chatChannelType + "",
                            ) //for social channel chats, disabling the prechat form request modal
                              ? clickHandler(false)
                              : setIsTakeOverModalVisible(true)
                          }
                          showLoader={showAssignMissedChatLoader}
                          botName={
                            activeCustomerInfo?.botDetails?.name ?? "Cern"
                          }
                        />
                      ) : activeCustomerInfo?.chatStatus === "queued" &&
                        activeCustomerInfo?.customerStatus === "live" ? (
                        <PickBox
                          chatId={activeCustomerInfo?.chatId}
                          customerName={activeCustomerInfo?.name}
                          waitingSinceDateTime={
                            activeCustomerInfo.queuedDatetimeGmt
                          }
                          onClick={handleChatAssignment}
                          ajaxStatus={assignChatAjaxStatus}
                        />
                      ) : (activeCustomerInfo?.customerStatus === "missed" ||
                          activeCustomerInfo?.customerStatus === "archived") &&
                        (activeCustomerInfo?.chatAssignedTo === null ||
                          activeCustomerInfo?.chatAssignedTo === undefined ||
                          activeCustomerInfo?.chatAssignedTo.length === 0) ? (
                        <MissedChatPickBox
                          chatId={activeCustomerInfo?.chatId}
                          onClick={handleMissedChatAssignment}
                          showLoader={showAssignMissedChatLoader}
                        />
                      ) : activeCustomerInfo?.customerStatus === "archived" &&
                        activeCustomerInfo?.isWhatsappChat ? (
                        <WhatsappChatPickBox />
                      ) : null}

                      {/* <OfflinePopup /> */}
                      <LiveSendMessage
                        canReply={canReply}
                        updateSidebarCount={updateSidebarCount}
                        isConnected={activeCustomerInfo?.isConnected ?? false}
                        showScheduleMsgModal={onShow}
                        scheduleMessageCount={
                          activeCustomerInfo?.scheduledCount
                        }
                      />
                    </div>
                  </div>
                )
              : null}
          </div>
        )}
      </InfiniteScroll>
    </div>
  );
}

export default ChatSection;
