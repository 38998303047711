import { createSlice } from "@reduxjs/toolkit";
import { builderFunction } from "./ticketGlobalSearch.builder";
import { initialState } from "./ticketGlobalSearch.declarations";
import ticketGlobalSearchReducers from "./ticketGlobalSearch.reducers";

export const ticketGlobalSearchSlice = createSlice({
  name: "ticketGlobalSearch",
  initialState,
  reducers: ticketGlobalSearchReducers,
  extraReducers: builderFunction,
});

export const {
  resetTicketGlobalSearch,
  setSearchData,
  resetTicketGlobalSearchData,
} = ticketGlobalSearchSlice.actions;

export default ticketGlobalSearchSlice.reducer;
