/**
 * This file is the service file used for making api request.
 * It contains the getThemeDetails function service which is explained below.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { axiosKbPreview } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants"; // this is used for testing purpose only for frontend.

export interface getThemeDetailsData {
  themeId: number;
  themeType: "alice_in_wonderland" | "night_in_the_oscars";
  name: string;
  desc: string;
  heading: string;
  subheading: string;
  helplamaBranding: boolean;
  helplamaBrandingText?: string;//property responsible for showing "powered by" text in preview section footer 
  backgroundColor: string;
  brandColor: string;
  placeholder: string;
  storeName: string;
  logoImage: null | string;
  backgroundImage: null | string;
  favicon: null | string;
  footerLogo: null | string;
  font: string;
  language: string;
  zeroArticle: string;
  oneArticle: string;
  articleCount: number;
  noTitleArticleText: string;
  customCodeHead: string;
  customCodeBody: string;
}

export interface getThemeDetailsResponse {
  data: getThemeDetailsData;
  err: string;
  tokenExpire: boolean;
}

export interface getThemeDetailsPayload {
  integrationIdentifier: string;
}

/**
 * For testing frontend with own api link if backend is not prepared.
 */
const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/`
  : "/api/knowledgebase/helpCenterPage/";

/**
 * This service is used to get the theme details.
 */
export const getThemeDetails = async (params: getThemeDetailsPayload) => {
  const { data: res } = await axiosKbPreview.post(
    `${apiEndPoint}${params.integrationIdentifier}/getThemeDetails`,
    {}
  );

  return res as getThemeDetailsResponse;
};
