import RecordTableRow from "../RecordTableRow/RecordTableRow";
import styles from "../../CallRecording.module.scss";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { useEffect, useRef } from "react";
import { fetchCallerDataThunk } from "src/store/slices/callRecording/callRecording.thunks";
import { setCatchedPage } from "src/store/slices/callRecording/callRecording.slice";
import { v4 as uuid } from "uuid";
import Pagination from "src/routes/TicketList/children/Pagination/Pagination";
import Loader from "src/components/Loader";
import FoundNoTickets from "src/components/FoundNoTickets";

function RecordMain() {
  const dispatch = useAppDispatch();
  const {
    callerDataAjaxStatus,
    currentPage,
    cachedData,
    callerData,
    callerIdList,
    noOfPages,
  } = useAppSelector((state) => state.callrecording);
  const currentState = useRef({
    callerDataAjaxStatus,
    currentPage,
    cachedData,
  });
  useEffect(() => {
    currentState.current = { callerDataAjaxStatus, currentPage, cachedData };
  }, [callerDataAjaxStatus, currentPage, cachedData]);

  useEffect(() => {
    dispatch(fetchCallerDataThunk());
  }, [false]);
  const changePage = (pageNo: number) => {
    if (currentState.current.cachedData[pageNo]) {
      dispatch(setCatchedPage({ pageNo }));
    } else {
      dispatch(setCatchedPage({ pageNo }));
      dispatch(fetchCallerDataThunk());
    }
  };

  return (
    <div className={`${styles.tableMain}`}>
      {callerDataAjaxStatus === "pending" ? (
        <Loader />
      ) : callerDataAjaxStatus === "fulfilled" ? (
        <>
          <div className={`${styles.tableHeader}`}>
            <div className={`${styles.headerDiv}`}>
              <span className={`${styles.headerCell}`}>Caller</span>
            </div>
            <div className={`${styles.headerDiv}`}>
              <span className={`${styles.headerCell}`}>Call Type</span>
            </div>
            <div className={`${styles.headerDiv}`}>
              <span className={`${styles.headerCell}`}>Agent</span>
            </div>
            <div className={`${styles.headerDiv}`}>
              <span className={`${styles.headerCell}`}>Brand</span>
            </div>
            <div className={`${styles.headerDiv}`}>
              <span className={`${styles.headerCell}`}>Call Recieved Date</span>
            </div>
            <div className={`${styles.headerDiv}`}>
              <span className={`${styles.headerCell}`}>Recording</span>
            </div>
          </div>
          <div>
            {callerIdList.map((id, key) => {
              const caller = callerData[id];
              return <RecordTableRow key={uuid()} {...caller} />;
            })}
          </div>
          <Pagination
            currentPage={currentPage}
            totalPages={noOfPages}
            setCurrentPage={changePage}
          />
        </>
      ) : (
        // <FoundNoTickets text="Waiting to update new calls. It might take up to 48 hours before they are updated." />
        <FoundNoTickets text="Found No Data!" />
      )}
    </div>
  );
}

export default RecordMain;
