import ReactSelect, {
  MultiValueProps,
  OptionProps,
  components,
} from "react-select";
import styles from "../ReportInternalFilter.module.scss";
import { useCallback, useMemo } from "react";
import {
  reportInternalFiltersActions,
  useReportInternalFilters,
} from "src/routes/Report/hooks/reportFilters/useReportInternalFilters";
import disLike from "src/assets/images/chat/chatRatingdislike.png";
import like from "src/assets/images/chat/chatRatinglike.png";

// Custom hook for managing the "Chat Ratings" filter
const useChatRatingsFilter = () => {
  // Create a memoized array of all available chat statuses
  const allChatRatings = useMemo(() => {
    return [
      { id: "1", name: "Like" },
      { id: "0", name: "Dislike" },
    ];
  }, []);

  // Create a memoized array of options suitable for a selection component
  const options = useMemo(() => {
    return allChatRatings.map((data) => ({
      label: data.name,
      value: data.id,
    }));
  }, [allChatRatings]);

  // Accessing internal filters and dispatch function
  const { internalFilters, dispatch } = useReportInternalFilters();

  // Memoizing selected ratings based on internal filters
  const selectedRatings = useMemo(() => {
    return allChatRatings?.length
      ? (
        allChatRatings?.filter((rating) =>
            internalFilters.addedFilters.chatRatings?.includes(rating.id + "")
          ) ?? []
        ).map((rating) => {
          return {
            label: rating.name,
            value: rating.id,
          };
        })
      : [];
  }, [internalFilters, allChatRatings]);

  // The onChangeChatRating function updates the internal filters state with the selected ratings.
  const onChangeChatRating = useCallback(
    (value: typeof selectedRatings) => {
      // Dispatching an action to update the internal filters
      dispatch([
        reportInternalFiltersActions.setFilters,
        { chatRatings: value.map((data) => data.value + "") },
      ]);
    },
    [selectedRatings]
  );

  // Chat rating options, selected ratings, and the callback
  return {
    options,
    selectedRatings,
    onChangeChatRating,
  };
};

// React component for the "Ratings" filter
function ChatRatings() {
  // Using the custom hook to get options, selected ratings, and the callback
  const { options, selectedRatings, onChangeChatRating } =
    useChatRatingsFilter();

  const CustomOption: React.FC<OptionProps<any>> = (props) => (
    <components.Option {...props}>
      <div className={`d-flex align-items-center cursor-pointer `}>
        <img
          src={props.data.label == "Like" ? like : disLike}
          style={{ width: "17px", height: "17px" }}
          alt="like"
        />
        {/* <span className={`ps-2 ${styles.brandName}`}>{props.label}</span> */}
      </div>
    </components.Option>
  );

  const CustomMultiValue: React.FC<MultiValueProps<any>> = (props) => (
    <components.MultiValue {...props}>
      <div className={`d-flex align-items-center justify-content-center`}>
        <img
          src={props.data.label == "Like" ? like : disLike}
          style={{ width: "17px", height: "17px" }}
          alt="like"
        />
        {/* <span className={`ps-1 ${styles.selectBrand}`}>
          {props.data?.label}
        </span> */}
      </div>
    </components.MultiValue>
  );

  // Rendering the component UI
  return (
    <div className="px-2 pb-2">
      <label className={`${styles.labelText}`}>Chat Rating</label>
      {/* ReactSelect component is rendered with the appropriate props, including the options, selected values, and onChange event handler. */}
      <ReactSelect
        isMulti
        options={options}
        value={selectedRatings}
        onChange={onChangeChatRating as any}
        className={`${styles.text}`}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
            font: "normal normal normal 12px/18px Poppins",
            color: "#000000",
          }),
          multiValue: (base, { data }) => ({
            ...base,
            backgroundColor: "#F4F4F4", // Background color for selected values
            borderRadius: "6px",
            opacity: 1,
          }),
          multiValueLabel: (base) => ({
            ...base,
            font: "normal normal 500 12px/18px Poppins",
            letterSpacing: "0px",
            color: "#1F2223",
            paddingLeft: "12px",
          }),
          multiValueRemove: (base, { data }) => ({
            ...base,
            // color: '#000000', // Remove icon color for selected values
            fontSize: "6px",
            // ':hover': {
            //   backgroundColor: 'red', // Change the background color on hover if needed
            // },
          }),
        }}
        components={{
          Option: CustomOption,
          MultiValue: CustomMultiValue,
        }}
      />
    </div>
  );
}

export default ChatRatings;
