import { EReturnAutomationQuestionKeys } from "src/enums/EReturnAutomationQuestionKeys";
import useGetAutomationData from "src/routes/Setting/children/MainSetting/children/SetRulesNew/childerns/childern/AddAutomations/WidgetPreviewSection/hooks/useGetAutomationData";
import { StepName } from "src/routes/Setting/children/MainSetting/children/SetRulesNew/childerns/childern/AddAutomations/WidgetPreviewSection/hooks/useReturnPreview";

export const updatePreviewDetails = ({
  questionKey,
  isRestockingFee,
  showReturnReasons,
  isReturnMethodAvailable,
}: {
  questionKey: string;
  isRestockingFee?: boolean;
  showReturnReasons?: boolean;
  isReturnMethodAvailable?: boolean;
}) => {
  let selectedOrderId = "SampleOrderWithinReturnWindow";
  let activeStep: StepName = "selectOrder";
  switch (questionKey) {
    case EReturnAutomationQuestionKeys.returnWindow:
      selectedOrderId = "SampleOrderOutSideReturnWindow";
      activeStep = "selectAction";
      break;
    case EReturnAutomationQuestionKeys.returnNotEligibleResponseMessage:
      selectedOrderId = "SampleOrderOutSideReturnWindow";
      activeStep = "notReturnable";
      break;
    case EReturnAutomationQuestionKeys.isReturnPolicySameForAllItem:
      activeStep = "addReturnItems";
      break;
    case EReturnAutomationQuestionKeys.resolutionOffer:
      activeStep = "returnType";
      break;
    case EReturnAutomationQuestionKeys.selectReturnReason:
      if (showReturnReasons) {
        activeStep = "reason";
      } else {
        activeStep = "returnType";
      }
      // else if (isReturnMethodAvailable) {
      // activeStep = "returnMethod";
      // } else {
      //   activeStep = "configureNextSteps";
      // }
      break;
    case EReturnAutomationQuestionKeys.returnReasonList:
    case EReturnAutomationQuestionKeys.isReturnReasonSameForAll:
      activeStep = "reason";
      break;
    case EReturnAutomationQuestionKeys.antiReturnStrategies:
    case EReturnAutomationQuestionKeys.configureAntiReturnStrategies:
    case EReturnAutomationQuestionKeys.isReturnReasonOrFollowUpQuestionsSame:
      activeStep = "configureNextSteps";
      break;
    case EReturnAutomationQuestionKeys.returnMethodForMajorityOfOrders:
    case EReturnAutomationQuestionKeys.returnMethodDisplayMessage:
    case EReturnAutomationQuestionKeys.isReturnMethodSameForAllOrders:
      activeStep = "returnMethod";
      break;
    case EReturnAutomationQuestionKeys.isRestockingFeeChargedForMajorityOrders:
      if (isRestockingFee) {
        activeStep = "reviewRestockingFee";
      } else {
        activeStep = "orderSummary";
      }
      break;
    case EReturnAutomationQuestionKeys.usuallyChargedRestockingFee:
    case EReturnAutomationQuestionKeys.restockingFeeDisplayMessage:
    case EReturnAutomationQuestionKeys.isRestockingFeeSameForAll:
      activeStep = "reviewRestockingFee";
      break;
    default:
      break;
  }
  return {
    selectedOrderId,
    activeStep,
  };
};
