import { axiosJSON } from "src/globals/axiosEndPoints";

export interface IGetAllReturnIntegrationsParam {
  start?: number;
  limit?: number;
}

export interface IReturnIntegrationData {
  integrationId: number | string; 
  RequestCategory: string; //category type string
  configured: boolean; //flag whether all the steps completed
  brand: { //brand of the integration, it can be null if itegration is not completed
    id: number;
    name: string;
    email: string;
    imageURL: string|null;
  } | null;
  published: boolean; //flag whether automation is live or not live
}

export interface GetAllReturnIntegrations {
  integrations: { [key: string | number]: IReturnIntegrationData };
  integrationIds: Array<number | string>;
  metaData: {
    total: number | string;
    count: number | string;
  };
}

export async function getAllReturnIntegrations(
  payload: IGetAllReturnIntegrationsParam
) {
  const { data } = await axiosJSON.post(
    "/api/setting/automation/refund/getAll",
    payload
  );

  if (data.error === true) {
    throw new Error("Some Error Occurred while fetching data");
  }

  return normalizeData(data.data, data.metaData );
}


function normalizeData(data: any, meta: any) {
  const integrations: { [key: string | number]: IReturnIntegrationData } = {};
  const integrationIds: Array<number | string> = [];

  data.map((integration: IReturnIntegrationData) => {
    const integrationId = parseInt(integration.integrationId + "");
    integrationIds.push(integrationId);
    integrations[integrationId] = integration;
  });

  const metaData = { count: meta.count, total: meta.total };

  return {
    integrations,
    integrationIds,
    metaData,
  } as GetAllReturnIntegrations;
}
