import styles from "./AutomationTabSettings.module.scss";
import settingImg from "src/assets/images/botSetting.png";
import book from "src/assets/images/bookOpen.png";
import { useState } from "react";
import SettingSection from "./Children/SettingSection/SettingSection";

const AutomationTabSettings = () => {
  const [tab, setTab] = useState("settings");

  return (
    <div className="w-100">
      <div
        className={`d-flex flex-column flex-md-row align-items-md-center ms-md-4 ${styles.btnWrapper}`}
      >
        <button
          className={`px-3 mb-2 mb-md-0 ${styles.tabsBtn} ${
            tab === "settings" && styles.active
          }`}
          onClick={() => setTab("settings")}
        >
          <span className="pe-1">
            <img
              src={settingImg}
              alt=""
              className={`${styles.settingImg}`}
            />
          </span>
          Settings
        </button>
        <button
          className={`px-3 d-none mb-2 mb-md-0 ${styles.tabsBtn} ${
            tab === "answerSource" && styles.active
          }`}
          onClick={() => setTab("answerSource")}
        >
          <span className="pe-1">
            <img
              src={book}
              alt=""
              className={`${styles.book}`}
            />
          </span>
          Answer Source
        </button>

        <button
          className={`px-3 d-none ${styles.tabsBtn} ${
            tab === "analytics" && styles.active
          }`}
          onClick={() => setTab("analytics")}
        >
          <span className="pe-1">
            <i className="fa-solid fa-chart-line"></i>
          </span>
          Analytics
        </button>
      </div>
      <div className={`w-100 ${styles.contentWrapper} p-3`}>
        {tab === "settings" && <SettingSection />}
        {tab === "answerSource" && (
          <div className="d-none">Active Source Component</div>
        )}
        {tab === "analytics" && (
          <div className="d-none">Analytics Component</div>
        )}
      </div>
    </div>
  );
};

export default AutomationTabSettings;
