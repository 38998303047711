import { useEffect, useMemo, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import dots from "src/assets/images/dots.svg";
import {
  FollowUpReturnReason,
  removeReason,
  updateReasonValue,
  validateMoreQuestion,
} from "../../../../store/slices/ReturnReasonOrderAndItems/returnReasonOrderAndItems.slice";
import styles from "./DraggableInput.module.scss";
import { useAppDispatch } from "src/store/store";

function DraggableInput({
  res,
  resTypeId,
  pushCurrentHash,
}: {
  res: FollowUpReturnReason;
  resTypeId: string;
  pushCurrentHash?: (id: string) => void;
}) {
  const dispatch = useAppDispatch();
  const [showPopover, setShowPopover] = useState(true);

  useEffect(() => {
    if (res.error) {
      setShowPopover(true);
    } else {
      setShowPopover(false);
    }
  }, [res.error]);

  const popover = useMemo(
    () =>
      res.error && res.error.length > 0 ? (
        <Popover
          id="popover-basic"
          className="rounded-3"
        >
          <Popover.Body className="d-flex flex-row">
            <div className="border px-2 bg-warning text-white me-1 rounded-3 ">
              !
            </div>
            <div className="mt-1">{res.error}</div>
          </Popover.Body>
        </Popover>
      ) : (
        <></>
      ),
    [res.error],
  );

  return (
    <div
      className={`px-3 py-2 mb-2 d-flex justify-content-between align-items-center ${styles.createBox}`}
    >
      <img
        src={dots}
        className={`pe-2`}
        alt=""
      />
      <OverlayTrigger
        show={showPopover}
        placement="bottom"
        overlay={popover}
        rootClose={true}
        rootCloseEvent="mousedown"
        // defaultShow={showPopover} // Set the defaultShow property based on the state variable
        onToggle={(isShown) => {
          // Set the state variable based on the popover visibility
          setShowPopover(isShown);
        }}
      >
        <input
          type="text"
          placeholder="Enter reason"
          className={`px-2 py-1 ${styles.reasonInput} ${
            res.error && `border border-danger`
          }`}
          value={res.reason.value}
          onChange={(e) => {
            // setResValue(e.target.value);
            dispatch(
              updateReasonValue({
                reasonTypeId: resTypeId,
                value: e.target.value,
                reasonId: res.id,
              }),
            );
            dispatch(
              validateMoreQuestion({
                reasonTypeId: resTypeId,
              }),
            );
            // Added optional prop pushCurrentHash and call it when the reason text change to show save button
            if (pushCurrentHash) {
              pushCurrentHash(resTypeId);
            }
          }}
        />
      </OverlayTrigger>
      <MdDelete
        style={{ color: "#707070" }}
        className="ms-2"
        role="button"
        onClick={() => {
          dispatch(
            removeReason({
              reasonId: res.id,
              reasonTypeId: resTypeId,
            }),
          );
          // Added optional prop pushCurrentHash and call it when the reason text change to show save button
          if (pushCurrentHash) {
            pushCurrentHash(resTypeId);
          }
        }}
      />
    </div>
  );
}

export default DraggableInput;
