import { useCallback, useMemo } from "react";
import { Option } from "../CheckOptions/CheckOptions";

/**
 * RadioOptions component handles a list of options with checkboxes.
 *
 * @param options - List of options to render.
 * @param value - Selected value.
 * @param onChange - Callback function when the selected value change.
 */
function RadioOptions({
  options,
  value,
  onChange,
  showErrors,
}: {
  options: Array<{ label: string; value: string }>;
  value: string;
  onChange: (value: string) => void;
  showErrors: boolean;
}) {
  const isChecked = useCallback(
    (v: string) => {
      return value + "" === v + "";
    },
    [value],
  );

  const handleOnChange: React.ChangeEventHandler<HTMLInputElement> =
    useCallback(
      (e) => {
        const ele = e.currentTarget as HTMLElement;
        const thisValue = ele?.getAttribute("data-value");
        if (thisValue && value !== thisValue) {
          onChange(thisValue);
        }
      },
      [value, onChange],
    );

  return (
    <>
      {options.map((option) => {
        return (
          <Option
            key={option.value}
            {...option}
            checked={isChecked(option.value)}
            onChange={handleOnChange}
            type="radio"
            showErrors={showErrors}
          />
        );
      })}
    </>
  );
}

export default RadioOptions;
