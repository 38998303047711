import { axiosJSON } from "src/globals/axiosEndPoints";
import { ConditionType, StepConfigParams } from "../Step9/step9GetConfig";

interface AdditionCharges {
  name: string;
  key: string;
  enabled: boolean;
  value: string;
}

export interface Step15TabType {
  configTabId: number;
  configTabName: string;
  conditions: ConditionType[];
  additionalCharges: [AdditionCharges, AdditionCharges];
}

export interface Step15Config {
  data: Step15TabType[];
}

export async function fetchGetStep15Config(params: StepConfigParams) {
  const { data } = await axiosJSON.get(
    "/api/setting/automation/refund/step/15/configuration",
    { params }
  );

  if (data.err === true) {
    throw new Error("Some Error Occurred while fetching data");
  }

  return data.data;
}
