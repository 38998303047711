import { axiosJSON } from "src/globals/axiosEndPoints";

export interface IUpdateReturnIntegrationParams {
  integrationId: string;
  publish: boolean;
  brandId?: string;
}

async function publishReturnIntegrationService(
  payload: IUpdateReturnIntegrationParams,
) {
  const { data: res } = await axiosJSON.post(
    `/api/setting/automation/refund/publish`,
    payload,
  );

  if (res.err || res.error) {
    throw new Error(
      res.msg ?? res.message ?? "Some error occurred while updating",
    );
  }

  return true;
}

export default publishReturnIntegrationService;
