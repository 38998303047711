/**
 * This file contains the custom hook for handling KB settings.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useState } from "react";
import { flushSync } from "react-dom";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { setKBAxiosAuth } from "src/globals/axiosEndPoints";
import { getHelpCenterPage } from "src/services/KnowledgeBase/Settings/Preview/helpCenterPage.service";
import { signIn } from "src/services/KnowledgeBase/Settings/Preview/signIn.service";

export interface Props {
  helpCenterPageUri?: string;
  isFirstLogin: string | null;
}
export let logoutKBPreview: (
  callback: () => void,
  callAxios?: boolean,
) => void = () => {};

export const KB_AUTH_TOKEN_HEADER_NAME = "Authorization";

let lastLoginToken = localStorage.getItem("token");

/**
 * This hook is used to handle the state and data in PassProtect component.
 */
function usePassProtect({ helpCenterPageUri, isFirstLogin }: Props) {
  // State to manage password visibility
  const [showPass, setShowPass] = useState(false);
  // State to store the password input
  const [passWord, setPassword] = useState("");
  // State to control the password validation message display
  const [showValidation, setShowValidation] = useState(false);
  // State to track if the page is protected by a password
  const [isProtected, setIsProtected] = useState(false);
  // State to store the authentication token
  const [token, setToken] = useState<string | null>(lastLoginToken);
  // State to track the initialization status
  const [initialized, setInitialized] = useState(false);

  //Added to show status message when knowledgebase is deleted and preview page is accessing
  const [statusMsg, setStatusMsg] = useState("");

  // const [kbData, setkbData] = useState("");

  // Query for retrieving help center page data
  const { data, isLoading, isError, error, refetch } = useQuery({
    queryKey: ["getHelpCenterPage", helpCenterPageUri, isFirstLogin],
    queryFn: () =>
      getHelpCenterPage({
        helpCenterPageUri: helpCenterPageUri + "",
        isFirstLogin: isFirstLogin === "true",
      }),
    // Callback when the query is successful
    onSuccess: (response) => {
      // Show status message when error is true
      if (response.error === true) {
        if (response.status && response.statusMsg) {
          setStatusMsg(response.statusMsg);
        }
      }
      setInitialized(true);
    },
    onError: () => {
      setInitialized(true);
    },
  });
  // Update isProtected when passwordRestrictionStatus changes
  useEffect(() => {
    if (data?.data?.passwordRestrictionStatus) {
      setIsProtected(data?.data?.passwordRestrictionStatus);
    } else {
      setIsProtected(false);
    }
  }, [data?.data?.passwordRestrictionStatus]);

  // Mutation for user sign-in
  const callSignIn = useMutation({
    mutationFn: signIn,
  });

  // Navigation function
  const navigate = useNavigate();

  // Get the current location
  const location = useLocation();

  // Get the current hostname
  const hostname = window.location.hostname;

  // Create a memoized integrationIdentifier value
  const integrationIdentifier = useMemo(
    () => data?.data?.integrationIdentifier ?? "",
    [data?.data?.integrationIdentifier],
  );

  // Function to handle user sign-in
  function handleSignIn(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    if (passWord === "") {
      return;
    } else {
      e.preventDefault();
      callSignIn.mutate(
        {
          password: passWord,
          integrationIdentifier: integrationIdentifier,
        },
        {
          // Callback when sign-in is successful
          onSuccess: (data) => {
            if (data.token) {
              setToken(data.token);
              localStorage.setItem("token", data.token);
            } else {
              setShowValidation(true);
            }
            // navigate(`/helpcenterpage/${helpCenterPageUri}`);
          },
          // Callback when sign-in fails
          onError: (error) => {
            setShowValidation(true);
          },
        },
      );
    }
  }

  // Function to log out the user's session
  const logoutSession = useCallback(
    (callback: () => void) => {
      localStorage.removeItem("token");
      setToken(null);
      if (initialized) {
        refetch();
        setInitialized(false);
      }
    },
    [initialized],
  );

  // Function to handle changes in local storage (e.g., token updates)
  const storageEvent = useCallback(() => {
    let loginDataTemp: any = window.localStorage.getItem("token");
    if (loginDataTemp) {
      if (lastLoginToken !== loginDataTemp) {
        flushSync(() => {
          setToken(loginDataTemp);
        });
      }
    } else {
      setToken(null);
    }
    lastLoginToken = loginDataTemp;
  }, []);

  // Add an event listener for changes in local storage
  useEffect(() => {
    window.addEventListener("storage", storageEvent);
    return () => {
      window.removeEventListener("storage", storageEvent);
    };
  }, [storageEvent]);

  // Make the logout function globally available
  useEffect(() => {
    logoutKBPreview = logoutSession;
    return () => {
      logoutKBPreview = (callback: () => void) => {};
    };
  }, [logoutSession]);

  useEffect(() => {
    if (lastLoginToken) {
      // Set authentication token for Axios requests
      setKBAxiosAuth({
        name: KB_AUTH_TOKEN_HEADER_NAME,
        value: `${token}`,
      });
      // navigate(`/helpcenterpage/${helpCenterPageUri}`);
    } else {
      // navigate(`/helpcenterpage/${helpCenterPageUri}`);
    }
  }, [false]);

  // Store the token in local storage when it changes
  useMemo(() => {
    if (token === null) {
      window.localStorage.removeItem("token");
    } else {
      window.localStorage.setItem("token", token);
      // Set authentication token for Axios requests
      setKBAxiosAuth({
        name: KB_AUTH_TOKEN_HEADER_NAME,
        value: `${token}`,
      });
    }
  }, [token]);

  return {
    showPass,
    setShowPass,
    passWord,
    setPassword,
    showValidation,
    setShowValidation,
    handleSignIn,
    hostname,
    callSignIn,
    location,
    isProtected,
    setIsProtected,
    initialized,
    token,
    isError,
    error,
    integrationIdentifier,
    statusMsg,
  };
}

export default usePassProtect;
