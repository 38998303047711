import { useCallback, useEffect, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import Select from "react-select";
import { fetchGetAllDiscountCodes } from "src/store/slices/initiateReturn/returnExchange.thunk";
import { useAppDispatch, useAppSelector } from "src/store/store";
import styles from "./ComponentStyles.module.scss";

function DiscountCodes(props: any) {
  const dispatch = useAppDispatch();

  const discountCodes = useAppSelector((state) =>
    state.returnExchange.getAllDiscountCodes.data.map((code) => {
      return { value: code.code, label: code.title };
    }),
  );
  var arr = [];
  for (var i = 0; i < props.codes.length; i++) {
    arr.push({
      value: props.codes[i],
      label: props.codes[i],
    });
  }

  const { getAllDiscountCodes, getAllDiscountCodesAjax } = useAppSelector(
    (state) => state.returnExchange,
  );

  const handleLoadOptions = useCallback(
    (e: any) => {
      const limit = 15;
      if (
        getAllDiscountCodesAjax !== "pending" &&
        getAllDiscountCodes.metaData.hasNextPage === true
      ) {
        dispatch(
          fetchGetAllDiscountCodes({
            limit,
            startCursor: getAllDiscountCodes.metaData.endCursor,
          }),
        );
      }
    },
    [
      getAllDiscountCodesAjax,
      getAllDiscountCodes.metaData.hasNextPage,
      getAllDiscountCodes.metaData.endCursor,
      dispatch,
    ],
  );
  const [menuOpen, setMenuOpen] = useState(false);

  const [showPopover, setShowPopover] = useState(true);
  useEffect(() => {
    if (props.error) {
      setShowPopover(true);
    } else {
      setShowPopover(false);
    }
  }, [props.error]);
  const popover =
    props.error && props.error.length > 0 ? (
      <Popover
        id="popover-basic"
        className="rounded-3"
      >
        <Popover.Body className="d-flex flex-row">
          <div className="border px-2 bg-warning text-white me-1 rounded-3 ">
            !
          </div>
          <div className="mt-1">{props.error}</div>
        </Popover.Body>
      </Popover>
    ) : (
      <></>
    );
  return (
    <>
      <OverlayTrigger
        show={showPopover}
        placement="bottom"
        overlay={popover}
        rootClose={true}
        onToggle={(show) => {
          if (!menuOpen) {
            setShowPopover(show);
          }
        }}
        // onToggle={(show) => {
        //   setShowPopover(show);
        // }}
        // onExit={(e: any) => {
        //   setShowPopover(false);
        // }}
        rootCloseEvent="mousedown"
      >
        <div
          className={`ms-lg-3 ${styles.thirdSelect}`}
          style={{
            maxWidth: "20rem",
            minWidth: "16rem",
          }}
          onClick={() => {
            setShowPopover(false);
          }}
        >
          <Select
            defaultValue={arr.map((tag) => tag)}
            value={arr.map((tag) => tag)}
            isMulti
            name="codes"
            styles={customStyles}
            options={discountCodes}
            className={`basic-multi-select ${styles.customCss} ${
              props.error ? styles.borderDanger : ""
            }`}
            classNamePrefix="select"
            isClearable={false}
            placeholder="search codes"
            onChange={(e) => props.onChange(e.map((s) => s.value))}
            isLoading={getAllDiscountCodesAjax === "pending"}
            onMenuScrollToBottom={handleLoadOptions}
            onMenuClose={() => {
              setMenuOpen(false);
            }}
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator: () => null,
            }}
            onMenuOpen={() => {
              setMenuOpen(true);
              if (
                getAllDiscountCodesAjax !== "pending" &&
                discountCodes.length === 0
              ) {
                dispatch(
                  fetchGetAllDiscountCodes({
                    limit: 15,
                  }),
                );
              }
            }}
          />
          <span className={styles.errorText}>
            {props.error && props.error.length > 0 && props.error}
          </span>
        </div>
      </OverlayTrigger>
    </>
  );
}
const customStyles = {
  control: (provided: any, _: any) => ({
    ...provided,
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: "#707070",
    fontSize: "14px !important", // Placeholder font size
  }),
  dropdownIndicator: () => ({
    display: "none", // Hide the dropdown arrow
  }),
  indicatorSeparator: () => ({
    display: "none", // Hide the separator line
  }),
};
export default DiscountCodes;
