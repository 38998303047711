import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { TicketGlobalSearchSlice } from "./ticketGlobalSearch.declarations";
import ticketGlobalSearchExtraReducers from "./ticketGlobalSearch.extraReducers";
import { fetchAllSearchTickets } from "./ticketGlobalSearch.thunks";

export function builderFunction(
  builder: ActionReducerMapBuilder<TicketGlobalSearchSlice>
) {
  builder.addCase(
    fetchAllSearchTickets.fulfilled,
    ticketGlobalSearchExtraReducers.fetchAllSearchTicketsFulfilled
  );
  builder.addCase(
    fetchAllSearchTickets.rejected,
    (state: TicketGlobalSearchSlice) => {
      state.ticketDataAjaxStatus = "rejected";
    }
  );
  builder.addCase(
    fetchAllSearchTickets.pending,
    (state: TicketGlobalSearchSlice) => {
      state.ticketDataAjaxStatus = "pending";
    }
  );
}
