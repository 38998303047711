import { useCallback, useState } from "react";
import { Modal } from "react-bootstrap";
import styles from "./ActionComp.module.scss";
import DeleteTracker from "./Children/DeleteTracker/DeleteTracker";
const ActionComp = () => {
  const [showModal, setShowModal] = useState(false);

  const onShow = useCallback(() => {
    setShowModal(true);
  }, []);
  const onHide = useCallback(() => {
    setShowModal(false);
  }, []);
  return (
    <>
      <div
        className={`d-flex flex-wrap justify-content-between align-items-center ${styles.actionBox}`}
      >
        <div className={`${styles.backOpt}`}>
          <span className="me-1">
            <i className="fa-solid fa-angle-left"></i>
          </span>
          <span>Back to the list</span>
        </div>
        <div>
          <button className={`mt-2 mt-md-0 ${styles.delOpt}`} onClick={onShow}>
            <span className="me-1">
              <i className="fa-solid fa-trash"></i>
            </span>
            <span>Delete this Sales Tracker</span>
          </button>
        </div>
        <Modal
          backdropClassName={`${styles.modalBack}`}
          show={showModal}
          onHide={onHide}
          dialogClassName={`${styles.modalDialog}`}
          contentClassName={`${styles.modalContent}`}
        //   centered={true}
          enforceFocus={false}
        >
         <DeleteTracker />
        </Modal>
      </div>
    </>
  );
};
export default ActionComp;
