function ReportBar() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="41.061"
      height="41.061"
      viewBox="0 0 41.061 41.061"
    >
      <defs>
        <filter
          id="Path_112"
          x={0}
          y={0}
          width="30"
          height="30"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy={3} />
          <feGaussianBlur stdDeviation={3} result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="Layer_2" data-name="Layer 2" transform="translate(6.694 3.694)">
        <g id="invisible_box" data-name="invisible box">
          <rect
            id="Rectangle_405"
            data-name="Rectangle 405"
            width={28}
            height={28}
            fill="none"
          />
        </g>
        <g
          id="icons_Q2"
          data-name="icons Q2"
          transform="translate(2.306 2.306)"
        >
          <g transform="matrix(1, 0, 0, 1, -9, -6)" filter="url(#Path_112)">
            <path
              id="Path_112-2"
              data-name="Path 112"
              d="M4,25.908H4a1.153,1.153,0,0,1,1.153-1.153H25.908a1.153,1.153,0,0,1,1.153,1.153h0a1.153,1.153,0,0,1-1.153,1.153H5.153A1.153,1.153,0,0,1,4,25.908ZM9.765,13.225a1.153,1.153,0,0,0-1.153-1.153h0a1.153,1.153,0,0,0-1.153,1.153v9.225H9.765ZM23.6,5.153A1.153,1.153,0,0,0,22.449,4h0A1.153,1.153,0,0,0,21.3,5.153v17.3H23.6ZM14.378,9.765a1.153,1.153,0,0,0-1.153-1.153h0a1.153,1.153,0,0,0-1.153,1.153V22.449h2.306Zm4.612,5.189A1.153,1.153,0,0,0,17.837,13.8h0a1.153,1.153,0,0,0-1.153,1.153v7.495H18.99Z"
              transform="translate(5 2)"
              fill="#000000"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ReportBar;
