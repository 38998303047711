import { Channel } from "pusher-js";
import { IMessageData } from "src/store/slices/innerTicket/innerTicket.slice";

/**
 * Email Bot Feedback Updated Event Response Interface
 *
 * This Event is called when customer selects a response in email
 */
export interface IBotEmailUpdatedResponse {
  messageId: string | number;
  ticketId: string | number;
  customerFeedback: IMessageData["customerFeedback"];
  botInfo: IMessageData["botInfo"];
}

/**
 * Binder for email bot feedback updated event in inner ticket view
 */
export const getEmailBotFeedbackUpdatedEvent = (
  channel: Channel,
  callback: (res: IBotEmailUpdatedResponse) => void
) => {
  channel.bind("ticket_event_email_bot_feedback_updated", callback);
};
