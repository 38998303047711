import { axiosJSON } from "src/globals/axiosEndPoints";

interface Events {
  description: string;
  location: null;
  createdAt: Date;
}

interface TrackingDetails {
  name: string;
  key: string;
  createdAt: Date;
  events: [Events];
}

export interface IShippedBy {
  url: string;
  company: string;
  number: string;
}

export interface GetTrackingData {
  orderId: number;
  orderName: string;
  shippedBy: null | IShippedBy;
  trackingDetails: Array<TrackingDetails>;
}

export interface GetTrackingDataParams {
  orderId: string | number;
}

export async function getTrackingData(params: GetTrackingDataParams) {
  const { data: res } = await axiosJSON.post(
    `/api/shopify/order/getTrackingData`,
    params
  );
  // const data = {
  //   orderId: 1,
  //   orderName: "#1001",
  //   shippedBy: null,
  //   trackingDetails: [
  //     {
  //       name: "Order Placed",
  //       key: "orderPlaced",
  //       createdAt: "2022-09-16 01:47:53",
  //       events: [
  //         {
  //           description: "Your order no.#1001 Has been placed.",
  //           location: "",
  //           createdAt: "2022-09-16 01:47:53",
  //         },
  //       ],
  //     },
  //     {
  //       name: "Order Fulfilled",
  //       key: "orderFulfilled",
  //       createdAt: "2022-09-16 01:47:53",
  //       events: [
  //         {
  //           description: "Your order no.#1001 Has been placed.",
  //           location: "",
  //           createdAt: "2022-09-16 01:47:53",
  //         },
  //       ],
  //     },
  //     {
  //       name: "Order Shipped",
  //       key: "orderShipped",
  //       createdAt: "2022-09-16 01:47:53",
  //       events: [
  //         {
  //           description: "Your order no.#1001 Has been placed.",
  //           location: "",
  //           createdAt: "2022-09-16 01:47:53",
  //         },
  //       ],
  //     },
  //     {
  //       name: "Order Shipped",
  //       key: "orderShipped",
  //       createdAt: "2022-09-16 01:47:53",
  //       events: [
  //         {
  //           description: "Your order no.#1001 Has been placed.",
  //           location: "",
  //           createdAt: "2022-09-16 01:47:53",
  //         },
  //       ],
  //     },
  //     {
  //       name: "Order Delivered",
  //       key: "orderDelivered",
  //       createdAt: "2022-09-16 01:47:53",
  //       events: [
  //         {
  //           description: "Your order no.#1001 Has been placed.",
  //           location: "",
  //           createdAt: "2022-09-16 01:47:53",
  //         },
  //       ],
  //     },
  //     {
  //       name: "Order Returned",
  //       key: "orderReturn",
  //       createdAt: "2022-09-16 01:47:53",
  //       events: [
  //         {
  //           description: "Your order no.#1001 Has been placed.",
  //           location: "",
  //           createdAt: "2022-09-16 01:47:53",
  //         },
  //       ],
  //     },
  //   ],
  // };

  if (res.error === true) {
    throw new Error(res.message as string);
  }

  return res.data as unknown as GetTrackingData;
}
