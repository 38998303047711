import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";
import { ExternalSourceImportStatus } from "./importExternalSource.service";

export interface UpdateSourceImportStatusParam {
  sourceId: number;
  action: "stop" | "resume" | "refresh" | "reindex";
}

export interface UpdateSourceImportStatusResponse {
  sourceId: number;
  status: ExternalSourceImportStatus;
  numberOfPages: number;
}

const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/botProfile/kbArticle/select`
  : "/api/botProfile/external/updateSourceImportStatus";

export async function updateSourceImportStatus(
  params: UpdateSourceImportStatusParam
) {
  const { data: res } = await axiosJSON.post(apiEndPoint, params);

  if (res.err) {
    if (res["displayable-error"]) {
      let error:any = new Error(res["displayable-error"] as string);
      error.displayableError = res["displayable-error"];
      throw error;
    } else {
      throw new Error(res.msg as string);
    }
  }

  return res.data as UpdateSourceImportStatusResponse;
}
