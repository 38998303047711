import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import UserModal from "src/routes/Setting/children/MainSetting/children/User/UserModal/UserModal";
import { useAppSelector } from "src/store/store";
import styles from "./CreateNAddUser.module.scss";

function CreateNAddUser(props: any){

  const [showAddUserModal, setShowAddUserModal] = useState<boolean>(true);
  const { addUserAjaxStatus } =  useAppSelector((state) => state.userSettings);

  /*To show the add user modal */
  // const handleShowAddUserModal = (e: any) => {
    // e.preventDefault();
    // add add members main modal
    // props.setShowAddMemberModal(false);
    // setShowAddUserModal(true);
  // }

  const handleHideAddUserModal = () => {
    props.handleHideCreateNewUserModal();
    setShowAddUserModal(false);
  }

  useEffect(() => {
    if(addUserAjaxStatus === "pending"){
      handleHideAddUserModal();
    }
  },[addUserAjaxStatus])


  /*To create a new user and add to team */
  // const handleCreateNAddMember = (e: any) => {

  // }

  return(
      <Modal
          className={`shadow-sm p-3 rounded`}
          show={showAddUserModal}
          onHide={handleHideAddUserModal}
          centered={true}
          contentClassName={`${styles.modalContent}`}
          enforceFocus={true}
        >
        <UserModal OnHide={handleHideAddUserModal} editUserId={null} />
      </Modal>
  );
}

export default CreateNAddUser;