import { useCallback, useEffect, useRef, useState } from "react";
import TicketService from "src/services/TicketService/TicketService";
import UserService from "src/services/UserService";
import Loader from "../Loader";
import styles from "./AssignToDropDown.module.scss";
import UserListItem from "./children/UserListItem";

export type User = {
  id: number;
  email: string;
  name: string;
  status?: string;
  imageUrl?: string;
  isPublicAttachmentUrl?: boolean;
};

type TicketUsers = {
  userSelected: number;
  allUsers: { [userId: number]: User };
  ajaxStatus: "pending" | "fulfilled" | "rejected";
  selectStatus: "idle" | "pending" | "fulfilled" | "rejected";
};

interface Props {
  selectedUser: number;
  onSelect: (selectedUser: User) => void;
  show: boolean;
  ticketId: Array<number>;
}

export function AssignToDropDown({
  selectedUser,
  onSelect,
  show,
  ticketId
}: Props) {
  const [userState, setUsers]: [TicketUsers, Function] = useState({
    userSelected: selectedUser,
    allUsers: {},
    ajaxStatus: "pending",
    selectStatus: "idle"
  });
  const users: any = useRef(userState);
  const onSelectHandel = useCallback(
    (user: User) => {
      const strTicketIds = ticketId.map(String);
      const servicePayload: { userId: number; ticketId: string[] } = {
        userId: user.id,
        ticketId: strTicketIds
      };
      setUsers({ ...users.current, selectStatus: "pending" });
      TicketService.assignTicketsTo(servicePayload)
        .then((res) => {
          setUsers({
            ...users.current,
            userSelected: user.id,
            selectStatus: "fulfilled"
          });
          onSelect(user);
        })
        .catch((err) => {
          setUsers({ ...users.current, selectStatus: "rejected" });
        });
    },
    [ticketId]
  );
  const fetchAllUsersData = useCallback((callback?: any) => {
    setUsers({
      userSelected: users.current.userSelected,
      allUsers: {},
      ajaxStatus: "pending",
      selectStatus: "idle"
    });
    const servicePayload = { start: 0, limit: 1000 };
    UserService.fetchAllUsers(servicePayload)
      .then((allUsersData) => {
        const auJSON: any = {};
        allUsersData.forEach((user: any) => {
          auJSON[user.id] = user;
        });
        setUsers({
          userSelected: users.current.userSelected,
          allUsers: auJSON,
          ajaxStatus: "fulfilled",
          selectStatus: "idle"
        });
        if (callback) {
          callback(auJSON[users.current.userSelected]);
        }
      })
      .catch(() => {
        setUsers({
          userSelected: users.current.userSelected,
          allUsers: {},
          ajaxStatus: "rejected",
          selectStatus: "idle"
        });
      });
  }, []);
  useEffect(() => {
    users.current = { ...userState };
  }, [userState]);
  // useEffect(() => {
  //   onSelect(userState.allUsers[userState.userSelected]);
  // }, [userState.userSelected]);
  useEffect(() => {
    setUsers({ ...users.current, userSelected: selectedUser });
  }, [selectedUser]);
  useEffect(() => {
    setUsers({ ...users.current, userSelected: selectedUser });
    fetchAllUsersData();
  }, [false]);
  useEffect(() => {
    if (show) {
      fetchAllUsersData();
    }
  }, [show]);
  // console.log(show);
  return (
    <div className={`${styles.relative}`}>
      {show && (
        <div className={`${styles.body}`}>
          <h5 className={`${styles["addTag_Title"]}`}>Assign to</h5>
          {(userState.ajaxStatus === "pending" ||
            userState.selectStatus === "pending") && (
            <Loader
              style={{
                marginTop: "-90px"
              }}
            />
          )}
          {userState.ajaxStatus === "fulfilled" && (
            <div
              className={`${styles.userListContainer} ${
                userState.selectStatus === "pending" ? "d-none" : ""
              }`}
            >
              {Object.values(userState.allUsers).map((user: User) => {
                if ((user.status as any) == "active")
                  return (
                    <UserListItem
                      key={user.id}
                      user={user}
                      userSelected={userState.userSelected}
                      onSelect={onSelectHandel}
                    />
                  );
              })}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
