import OrderDetails from "src/routes/Ticket/children/CustomerSB/children/OrderDetails/OrderDetails";
import AutoOrder from "./Children/AutoOrder/AutoOrder";

import LoginHome from "./Children/LoginHome/LoginHome";
import OtpComp from "./Children/OtpComp/OtpComp";
import styles from "./IssuesOrder.module.scss";
const IssuesOrder = (props: { type: string }) => {
  const text = (props.type === "returnInstruction") 
                ? "Initiate return order"
                : "Issues/ Questions with existing order"
  const icon = props.type === "returnInstruction" 
                ? ( <i className="fa-solid fa-house"></i>) 
                : ( <i className="fa-solid fa-angle-left"></i>)
  const style = props.type === "returnInstruction" 
                ? ""
                : `${styles.backArow}`
    return (
    <>
      <div
        className={`${styles.carWrapper}
      `}
      >
        <div className="container-fluid">
          <div className="row">
            <div className={`col-12`}>
              <div className="d-block">
                <div
                  className={`cursor-pointer d-flex position-sticky bg-white top-0 pt-2 pb-2`}
                >
                  <div
                    className={`d-flex justify-content-center align-items-center me-2 ${style}`}
                  >
                    <span>
                      {icon}
                    </span>{" "}
                  </div>
                  <div className="w-100">
                    <span className={`${styles.header}`}>
                      {text}
                    </span>
                  </div>
                </div>
                {/* Components for login and otp - For now it is d-none  */}
                <div className={`d-none mt-3 pt-3 pb-2 px-2 ${styles.logBox}`}>
                  {/* <LoginHome /> */}
                  {/* <OtpComp /> */}
                </div>
                <div>
                  <AutoOrder type={props.type} />
                </div>
              </div>
              {/* HTML for 43/62 of xd. Search Product or SKU  */}
            </div>
          </div>
        </div>
        <div>{/* <ExchangeItem /> */}</div>
        {/* Show this div for pagination of showing results of exchange items */}
        <div
          className={`d-none d-flex justify-content-between px-3 py-1 bg-white ${styles.pagination}`}
        >
          <div
            className={`d-flex justify-content-center align-items-center cursor-pointer ${styles.arrowBox}`}
          >
            <span>
              <i className="fa-solid fa-angle-left"></i>
            </span>
          </div>
          <div
            className={`d-flex justify-content-center align-items-center cursor-pointer ${styles.arrowBox}`}
          >
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>{" "}
          </div>
        </div>
        {/* Show this div for regret msg  */}
        {/* <div className={`w-100`}>
          <img
            src={regret}
            alt="regret"
            className={`w-100 ${styles.regretImg}`}
          />
        </div> */}
        {/* Show this div for ReturnPolicy component at bottom  */}
        {/* <div className={`${styles.setOpacity}`}>
          <img src={regret} alt="" className={`w-100`} />
        </div>
        <div
          className={`mt-1 d-flex flex-column flex-lg-row  justify-content-between position-relative px-4 pb-2 ${styles.btnGroup}`}
        >
          <button className={`py-1 px-3 mb-1 mb-lg-0 ${styles.nextBtn}`}>
            Go back
          </button>
          <button className={`border-0 py-1 px-3 text-white ${styles.nextBtn}`}>
            Proceed with other items
          </button>
        </div> */}
        {/* <div
          className={`d-flex justify-content-center align-items-center  py-1 ${styles.footer}`}
        >
          <img src={logo} alt="logo" className={`${styles.logo}`} />
          <span className={`ps-1 ${styles.powered}`}>Powered by Helplama</span>
        </div> */}
      </div>
    </>
  );
};
export default IssuesOrder;
