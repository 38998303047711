import React, { useRef, useState } from "react";
import styles from "./AddFeaturesModal.module.scss";
import FeaturedArticle from "./Children/FeaturedArticle/FeaturedArticle";
import AddShortNotes from "./Children/FeaturedArticle/AddShortNotes/AddShortNotes";
import SuccessMsg from "./Children/FeaturedArticle/SuccessMsg/SuccessMsg";
import ChooseArticleType from "../ChooseArticleType/ChooseArticleType";
import UrlAppear from "./Children/FeaturedArticle/UrlAppear/UrlAppear";
interface Props {
  onHide: () => void;
}
export type AddFeatureModalTypes =
  | "initial"
  | "domainUrl"
  | "featuredArticle"
  | "shortTitle"
  | "success";

const AddFeaturesModal = ({ onHide }: Props) => {
  const [showComponent, setShowComponent] =
    useState<AddFeatureModalTypes>("initial");

  const getComponent = () => {
    if (showComponent === "initial") {
      return (
        <>
          {/* Initial Modal */}
          <ChooseArticleType
            onHide={onHide}
            setShowComponent={setShowComponent}
          />
        </>
      );
    } else if (showComponent === "domainUrl") {
      return (
        <>
          {/* for select specific url  */}
          <UrlAppear onHide={onHide} setShowComponent={setShowComponent} />
        </>
      );
    } else if (showComponent === "featuredArticle") {
      return (
        <>
          {/* for Featured articles  */}
          <FeaturedArticle
            onHide={onHide}
            setShowComponent={setShowComponent}
          />
        </>
      );
    } else if (showComponent === "shortTitle") {
      return (
        <>
          {/* for add short notes  */}
          <AddShortNotes onHide={onHide} setShowComponent={setShowComponent} />
        </>
      );
    } else if (showComponent === "success") {
      return (
        <>
          {/* for success component  */}
          <SuccessMsg onHide={onHide} />
        </>
      );
    }
  };

  return <div className={`${styles.modalMain}`}>{getComponent()}</div>;
};

export default AddFeaturesModal;
