import { useAppSelector } from "src/store/store";
import styles from "./BotProfileInfo.module.scss";
import AxiosImg from "src/components/AxiosImg";
import {
  OverlayTrigger,
  Popover,
  Tooltip,
  TooltipProps,
} from "react-bootstrap";
import { RefAttributes } from "react";
import { Link, useNavigate } from "react-router-dom";

function BotProfileInfo() {
  const botProfileInfo = useAppSelector(
    (state) => state.chatWidExhMsg.activeCustomerInfo?.botDetails,
  );

  const navigate = useNavigate();

  const botProfileLogo = botProfileInfo?.imgUrl;
  const botPofileId = botProfileInfo?.id;

  let botProfileName: string = botProfileInfo?.botProfileName ?? ""; // Assuming botProfileInfo is the variable containing the name

  if (botPofileId === undefined || botProfileName.length === 0) {
    return <></>;
  }

  if (botProfileName.length > 26) {
    // Check if the 26th character is a space
    if (botProfileName.charAt(25) === " ") {
      botProfileName = botProfileName.slice(0, 25) + "...";
    } else {
      botProfileName = botProfileName.slice(0, 26) + "...";
    }
  }

  const popover = (
    props: JSX.IntrinsicAttributes &
      TooltipProps &
      RefAttributes<HTMLDivElement>,
  ) => (
    <Tooltip
      id="button-tooltip"
      {...props}
    >
      <div className={`p-1 w-100`}>
        Bot Profile : {botProfileInfo?.botProfileName}
      </div>
    </Tooltip>
  );

  return (
    <>
      <div className={`mb-2 ${styles.generalInfo}`}>
        <div className={`${styles.infoMain}`}>
          <div className={`${styles.state} d-flex mb-2`}>
            <span className={`me-2`}>
              <i className="fa-solid fa-robot"></i>
            </span>
            <div>
              <div className={`${styles.infoName}`}>Bot Profile</div>
              {botProfileInfo?.isDeleted ? (
                <div className={`${styles.infoEmail} ${styles.breakWord}`}>
                  {botProfileName}
                </div>
              ) : (
                <Link
                  to={`/bot/botProfiles/${botProfileInfo?.id}/settings`}
                  target="_blank"
                >
                  <div className={`${styles.infoEmail} ${styles.breakWord}`}>
                    {botProfileName}
                  </div>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BotProfileInfo;
