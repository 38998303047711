/**
 * This file is the component file.
 *
 * @author Yash Aditya
 */

import styles from "./NotificationTime.module.scss";
import TimeSelect from "../TimeSelect/TimeSelect";
import NormalSelect from "../NormalSelect/NormalSelect";
import { showIth } from "src/utils/utils";
import { useFreqSettings } from "../../../../hooks/useFreqSettings";
import { useCallback } from "react";

// Define the expected properties for the NotificationTime component
interface Props {
  serialNumber: number;
  times?: {
    time?: string;
    timeZoneId?: string;
  };
  disabled?: boolean;
}

// NotificationTime component definition
export const NotificationTimeNormal = ({
  serialNumber,
  times,
  disabled = false,
}: Props) => {
  const { freqSettings, dispatch } = useFreqSettings();

  // Handle time change
  const onChangeTime = useCallback(
    (time: string | null) => {
      // Check if a valid time is provided
      if (time) {
        // Dispatch an action to update notify settings with the new time value
        dispatch("updateNotifySettingsFreqTimes", {
          index: serialNumber,
          value: { time },
        });
      }
    },
    [dispatch, serialNumber],
  );

  // Handle time zone change
  const onChangeTimeZone = useCallback(
    (timeZoneIds: Array<string>) => {
      // Check if a valid time zone ID is provided
      if (timeZoneIds[0]) {
        // Dispatch an action to update notify settings with the new time zone value
        dispatch("updateNotifySettingsFreqTimes", {
          index: serialNumber,
          value: { timeZoneId: timeZoneIds[0] },
          syncTZ: true,
        });
      }
    },
    [dispatch, serialNumber],
  );

  return (
    <>
      {/* TimeSelect component */}
      <TimeSelect
        value={times?.time ?? null} // Initial value for TimeSelect
        onChange={onChangeTime}
        disabled={disabled}
      />
      {/* NormalSelect component */}
      <NormalSelect
        options={freqSettings.timeZones.map((value) => ({
          id: value.id,
          name: value.label,
        }))} // Initial options for NormalSelect
        value={times?.timeZoneId ? [times.timeZoneId] : []}
        onChange={onChangeTimeZone}
        isMulti={false} // Specify if NormalSelect allows multiple selections
        disabled={disabled}
      />
    </>
  );
};

// NotificationTime component definition
const NotificationTime = ({ serialNumber, times, disabled }: Props) => {
  return (
    <div className="mb-3">
      {/* Display notification header with serial number */}
      <p className={`${styles.notificationHead} ps-2 ps-md-0 ms-md-2`}>
        {serialNumber + 1 + showIth(serialNumber + 1)} notification
      </p>
      {/* Display a row with TimeSelect and NormalSelect components */}
      <div className="d-flex flex-column flex-md-row align-items-md-center">
        <NotificationTimeNormal
          serialNumber={serialNumber}
          times={times}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

// Export the NotificationTime component
export default NotificationTime;
