import { AJAXSTATUS } from "src/globals/constants";
import { MessageReturnType } from "src/services/LiveChat/messageExchange/getMessages";
import { IntegrationFailureMetaData } from "src/types/IntegrationFailure";

export interface ILiveChatAgent {
  id: number | string;
  name: string;
  email: string;
  imgUrl?: string;
  isPublicAttachmentUrl?: boolean;
}
export interface ChatWidExchMsgSlice extends MessageReturnType {
  fetchMsgAJAXStatus: AJAXSTATUS;
  sendMsgAJAXStatus: AJAXSTATUS;
  fetchMessageLimit: number;
  agents: { [id: number | string]: ILiveChatAgent };
  agentIds: Array<number | string>;
  agentMeta: {
    count: number;
    total: number;
  };
  selectedAgentIds: Array<number | string>;
  agentFetchLimit: number;
  assignToAgentListAJAXStatus: AJAXSTATUS;
  integrationFailure: IntegrationFailureMetaData | null;
}

export const initialState: ChatWidExchMsgSlice = {
  messages: {},
  messageIdList: [],
  metaData: { waitingForFromComplete: false }, //by default hided the bot form loader
  activeCustomerInfo: null,
  fetchMessageLimit: 25,
  fetchMsgAJAXStatus: "idle",
  sendMsgAJAXStatus: "idle",
  //for assign to agent dropdown
  agents: {},
  agentIds: [],
  agentMeta: {
    count: 0,
    total: 0,
  },
  selectedAgentIds: [],
  agentFetchLimit: 15,
  assignToAgentListAJAXStatus: "idle",
  integrationFailure: null,
};
