import { useState, useRef } from "react";
import { Overlay } from "react-bootstrap";
import { BlockPicker, CirclePicker, CompactPicker, CustomPicker ,GithubPicker,HuePicker,InjectedColorProps, TwitterPicker} from "react-color";
import { EditableInput, Hue, Saturation } from "react-color/lib/components/common";
import { ExportedColorProps } from "react-color/lib/components/common/ColorWrap";
import styles from "./CustomColorPicker.module.scss";

export const CustomColorPicker = ( {showColorBlocks=true, ...Props} ) => {

  const [showColourPalatte, setShowColourPalatte] = useState(false);
  const inlineStyles = {
    pointer: {
      width: '10px',
      height: '10px',
      borderRadius: '50%',
      transform: 'translate(5px, 0px)',
      border: '1px solid #fff',
    },
    saturationPointer: {
      width: '8px',
      height: '8px',
      borderRadius: '50%',
      border: '1px solid #fff',
    },
  }

  const CustomSaturationPointer = () => {
    return (
      <div style={ inlineStyles.saturationPointer } />
    )
  }
  
  const CustomPointer = () => {
    return (
      <div style={ inlineStyles.pointer } />
    )
  }

  const HueStyles = {
    default: {
      hue:{
        borderRadius: 10
      }
    },
  };
  
  const twitterStyle= {
    default: {
        input: {
            display: "none"
        },
        hash: {
            display: "none"
        },
        card: {
            padding: "0px",
            border: 'none',
            boxShadow: 'none',
        },
        swatch:{
          height: "24px",
          width: "24px",
          margin: "4px 2px 0 2px",
        },
        body:{
          padding: "0",
        }
    }
  };
  return (
    <div>
        <div className={`d-flex ${styles.colorBox} rounded`}>
          <div className="w-100" style={{position:"relative"}}>
          <EditableInput
            style={{input: {paddingLeft:"4px",fontSize: 12,height:"28px",width:"100%",borderRadius:4, border:"1px solid #707070"}}}
            value={Props.hex}
            onChange={(e)=>Props.onChange?.(e)}
          /> 
          </div>
          <div className={`${styles.miniColorBox} ms-1`} style={{backgroundColor:Props.hex}} ></div>
          <span className="mx-1" role={"button"} onClick={(e)=>setShowColourPalatte(!showColourPalatte)} >
          <i className="fa-solid fa-caret-down"></i>
          </span>
        </div>
        {showColourPalatte && (

        <div style={{position:"relative"}}>
        <div className={`${styles.modalContainer} rounded`}>
          <div className="d-flex w-100" style={{height: 90}}>
          <div className={`${styles.saturationPicker} w-100`}>
            <Saturation 
            {...Props}
            styles={HueStyles}
            pointer={CustomSaturationPointer as any}
            onChange={(e)=>Props.onChange?.(e)} />
          </div>
          <div className={`${styles.huePicker} ms-1`} >
            <Hue
              {...Props}
              styles={HueStyles}
              onChange={(e)=>Props.onChange?.(e)}
              direction="vertical"
              pointer={CustomPointer as any}
              />
          </div>
          </div>
          {showColorBlocks === true ? (

            <div className="twitter-picker">
          <TwitterPicker width="100%" triangle="hide" styles={twitterStyle} onChange={(e)=>Props.onChange?.(e)} colors={['#EF5E5D', '#A697C4','#F8A33B','#107A86','#217EF7','#FA6838','#27226C','#2EBCD4']} />
          </div>
            ): null}
          </div>
        </div>
        )}

    </div>
  );
};

export default CustomPicker(CustomColorPicker);