/* eslint-disable no-lone-blocks */
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllDiscountCodes,
  GetAllDiscountCodesParams,
} from "src/services/UiAutomation/ReturnExchange/Configuration/General/getAllDiscountCodes";
import {
  getAllOrderTags,
  GetAllOrderTagsParams,
} from "src/services/UiAutomation/ReturnExchange/Configuration/General/getAllOrderTags";
import {
  getAllProductTags,
  GetAllProductTagsParams,
} from "src/services/UiAutomation/ReturnExchange/Configuration/General/getAllProductTags";
import {
  GetAllProductsParams,
  getAllShopifyProducts,
} from "src/services/UiAutomation/ReturnExchange/Configuration/General/getAllShopifyProducts";
import {
  createReturnIntegration,
  ICreateReturnIntegrationParams,
} from "src/services/UiAutomation/ReturnExchange/createReturnIntegration";
import {
  deleteReturnIntegration,
  IDeleteReturnIntegrationParam,
} from "src/services/UiAutomation/ReturnExchange/deleteReturnIntegration";
import {
  getAllReturnIntegrations,
  IGetAllReturnIntegrationsParam,
} from "src/services/UiAutomation/ReturnExchange/getAllReturnIntegrations";
import {
  getReturnIntegrationById,
  IGetReturnIntegrationByIdParam,
} from "src/services/UiAutomation/ReturnExchange/getReturnIntegrationById";
import { fetchGetStep10 } from "src/services/UiAutomation/ReturnExchange/step10/getStep10";
import { fetchPostStep10 } from "src/services/UiAutomation/ReturnExchange/step10/postStep10";
import { fetchGetStep11 } from "src/services/UiAutomation/ReturnExchange/step11/getStep11";
import { fetchPostStep11 } from "src/services/UiAutomation/ReturnExchange/step11/postStep11";
import { fetchGetStep12 } from "src/services/UiAutomation/ReturnExchange/step12/getStep12";
import { fetchPostStep12 } from "src/services/UiAutomation/ReturnExchange/step12/postStep12";
import { fetchGetStep13 } from "src/services/UiAutomation/ReturnExchange/step13/getStep13";
import { fetchPostStep13 } from "src/services/UiAutomation/ReturnExchange/step13/postStep13";
import { fetchGetStep14 } from "src/services/UiAutomation/ReturnExchange/step14/getStep14";
import { fetchPostStep14 } from "src/services/UiAutomation/ReturnExchange/step14/postStep14";
import { fetchGetStep15 } from "src/services/UiAutomation/ReturnExchange/step15/getStep15";
import { fetchPostStep15 } from "src/services/UiAutomation/ReturnExchange/step15/postStep15";
import {
  step7,
  fetchGetStep7,
} from "src/services/UiAutomation/ReturnExchange/step7/getStep7";
import {
  step7Params,
  fetchPostStep7,
} from "src/services/UiAutomation/ReturnExchange/step7/postStep7";
import {
  fetchGetStep9,
  IGetStep9to15Params,
  step9to15ParamsGet,
} from "src/services/UiAutomation/ReturnExchange/step9/getStep9";
import {
  fetchPostStep9,
  step9to15ParamsPost,
} from "src/services/UiAutomation/ReturnExchange/step9/postStep9";

import // getAllDiscountCodesReducer,
// getAllOrderTagsReducer,
// getAllProductTagsReducer,
// getAllShopifyProuctReducer,
"./retrunExchange.slice";
import {
  getAllReturnReasonList,
  GetAllReturnReasonListParams,
} from "src/services/UiAutomation/ReturnExchange/Configuration/Step14/getAllReturnReasonList";
import { stepDetails, validateStepQuestions } from "./retrunExchange.slice";
import { RootState } from "src/store/store";
import {
  EReturnAutomationHiddenKeys,
  EReturnAutomationQuestionKeys,
} from "src/enums/EReturnAutomationQuestionKeys";

// Step7
export const fetchStep7 = createAsyncThunk(
  "fetchStep7",
  async (payload: IGetStep9to15Params, { dispatch }) => {
    const step7: step7 = await fetchGetStep7(payload); // async action
    return step7;
  },
);

export const fetchChangeAutomationRequest = createAsyncThunk(
  "fetchChangeAutomationRequest",
  async (payload: step7Params, { dispatch }) => {
    const res = await fetchPostStep7(payload); // async action
    return res;
  },
);

// Step 9

export const fetchStep9 = createAsyncThunk(
  "fetchStep9",
  async (payload: IGetStep9to15Params, { dispatch }) => {
    const res: step9to15ParamsGet = await fetchGetStep9(payload); // async action
    return res;
  },
);

export const fetchStep9Post = createAsyncThunk(
  "fetchStep9Post",
  async (payload: step9to15ParamsPost, { dispatch }) => {
    const res = await fetchPostStep9(payload); // async action
    return payload.step_data;
  },
);

// Step 10
export const fetchStep10 = createAsyncThunk(
  "fetchStep10",
  async (payload: IGetStep9to15Params, { dispatch }) => {
    const res: step9to15ParamsGet = await fetchGetStep10(payload); // async action
    return res;
  },
);

export const fetchStep10Post = createAsyncThunk(
  "fetchStep10Post",
  async (payload: step9to15ParamsPost, { dispatch }) => {
    const res = await fetchPostStep10(payload); // async action
    return payload.step_data;
  },
);

// Step 11

export const fetchStep11 = createAsyncThunk(
  "fetchStep11",
  async (payload: IGetStep9to15Params, { dispatch }) => {
    const res: step9to15ParamsGet = await fetchGetStep11(payload); // async action
    return res;
  },
);

export const fetchStep11Post = createAsyncThunk(
  "fetchStep11Post",
  async (payload: step9to15ParamsPost, { dispatch }) => {
    const res = await fetchPostStep11(payload); // async action
    return payload.step_data;
  },
);

// Step 12

export const fetchStep12 = createAsyncThunk(
  "fetchStep12",
  async (payload: IGetStep9to15Params, { dispatch }) => {
    const res: step9to15ParamsGet = await fetchGetStep12(payload); // async action
    return res;
  },
);

export const fetchStep12Post = createAsyncThunk(
  "fetchStep12Post",
  async (payload: step9to15ParamsPost, { dispatch }) => {
    const res = await fetchPostStep12(payload); // async action
    return payload.step_data;
  },
);

// Step 13

export const fetchStep13 = createAsyncThunk(
  "fetchStep13",
  async (payload: IGetStep9to15Params, { dispatch }) => {
    const res: step9to15ParamsGet = await fetchGetStep13(payload); // async action
    return res;
  },
);

export const fetchStep13Post = createAsyncThunk(
  "fetchStep13Post",
  async (payload: step9to15ParamsPost, { dispatch }) => {
    const res = await fetchPostStep13(payload); // async action
    return payload.step_data;
  },
);

// Step 14

export const fetchStep14 = createAsyncThunk(
  "fetchStep14",
  async (payload: IGetStep9to15Params, { dispatch }) => {
    const res: step9to15ParamsGet = await fetchGetStep14(payload); // async action
    return res;
  },
);

export const fetchStep14Post = createAsyncThunk(
  "fetchStep14Post",
  async (payload: step9to15ParamsPost, { dispatch }) => {
    const res = await fetchPostStep14(payload); // async action
    return payload.step_data;
  },
);

// Step 15

export const fetchStep15 = createAsyncThunk(
  "fetchStep15",
  async (payload: IGetStep9to15Params, { dispatch }) => {
    const res: step9to15ParamsGet = await fetchGetStep15(payload); // async action
    return res;
  },
);

export const fetchStep15Post = createAsyncThunk(
  "fetchStep15Post",
  async (payload: step9to15ParamsPost, { dispatch }) => {
    const res = await fetchPostStep15(payload); // async action
    return payload.step_data;
  },
);

export const fetchGetAllOrderTags = createAsyncThunk(
  "fetchGetAllOrderTags",
  async (payload: GetAllOrderTagsParams, { dispatch }) => {
    {
      const res = await getAllOrderTags(payload);
      // if (res) {
      //   dispatch(getAllOrderTagsReducer({ orderTags: res }));
      // }
      return res;
    }
  },
);

export const fetchGetAllShopifyProducts = createAsyncThunk(
  "fetchGetAllShopifyProducts",
  async (payload: GetAllProductsParams, { dispatch }) => {
    {
      const res = await getAllShopifyProducts(payload);

      // if (res) {
      //   dispatch(getAllShopifyProuctReducer({ products: res.data }));
      // }
      return { data: res.data, meta: res.metaData };
    }
  },
);

export const fetchGetAllProductTags = createAsyncThunk(
  "fetchGetAllProductTags",
  async (payload: GetAllProductTagsParams, { dispatch }) => {
    {
      const res = await getAllProductTags(payload);

      // if (res) {
      //   dispatch(getAllProductTagsReducer({ productTags: res }));
      // }
      return res;
    }
  },
);

export const fetchGetAllDiscountCodes = createAsyncThunk(
  "fetchGetAllDiscountCodes",
  async (payload: GetAllDiscountCodesParams, { dispatch }) => {
    {
      const res = await getAllDiscountCodes(payload);

      // if (res) {
      //   dispatch(getAllDiscountCodesReducer({ discountCodes: res }));
      // }
      return res;
    }
  },
);
//thunk to fetch all return integrations
export const fetchAllReturnIntegrations = createAsyncThunk(
  "fetchAllReturnIntegrations",
  async (payload: IGetAllReturnIntegrationsParam, { dispatch }) => {
    {
      const res = await getAllReturnIntegrations(payload);
      return res;
    }
  },
);

interface IAddReturnIntegrationPayload extends ICreateReturnIntegrationParams {
  callback?: Function;
}
//thunk to create new return integration
export const addReturnIntegration = createAsyncThunk(
  "addReturnIntegration",
  async (payload: IAddReturnIntegrationPayload, { dispatch }) => {
    {
      const res = await createReturnIntegration({
        step_data: payload.step_data,
        brandId: payload.brandId,
      });

      setTimeout(() => {
        if (payload?.callback) {
          payload.callback(res?.integrationId);
        }
      }, 0);
      return res;
    }
  },
);

interface IDeleteReturnIntegrationPayload
  extends IDeleteReturnIntegrationParam {
  callback?: Function;
}
//thunk to delete return integration
export const removeReturnIntegration = createAsyncThunk(
  "deleteReturnIntegration",
  async (payload: IDeleteReturnIntegrationPayload, { dispatch }) => {
    {
      const res = await deleteReturnIntegration({
        integrationId: payload.integrationId,
      });

      setTimeout(() => {
        if (payload?.callback) {
          payload.callback();
        }
      }, 0);

      return payload.integrationId;
    }
  },
);
//thunk to fetch return integration by id
export const fetchReturnIntegrationById = createAsyncThunk(
  "fetchReturnIntegrationById",
  async (payload: IGetReturnIntegrationByIdParam, { dispatch }) => {
    {
      const res = await getReturnIntegrationById(payload);
      return res;
    }
  },
);

export const fetchAllReturnReasonList = createAsyncThunk(
  "fetchAllReturnReasonList",
  async (payload: GetAllReturnReasonListParams, { dispatch }) => {
    {
      const res = await getAllReturnReasonList(payload);
      return res;
    }
  },
);

//validation thunks
export const validateAllQuestions = createAsyncThunk(
  "validateAllQuestions",
  async (payload: { stepId?: number }, { getState, dispatch }) => {
    await dispatch(validateStepQuestions());

    const state = getState() as RootState;
    const { stepId } = payload;
    let errorQuestionKeysWithStepId: { stepId: number; questionKey: string }[] =
      [];

    const sliceData: { [key: number]: stepDetails } = {
      9: state.returnExchange.step9,
      10: state.returnExchange.step10,
      11: state.returnExchange.step11,
      12: state.returnExchange.step12,
      14: state.returnExchange.step14,
      15: state.returnExchange.step15,
    };

    // Include step 13 conditionally
    if (
      state.returnExchange.step12.stepData.stepQuestions[
        EReturnAutomationQuestionKeys.selectReturnReason
      ].value !== "notRequired"
    ) {
      sliceData[13] = state.returnExchange.step13;
    }

    // Determine the steps to validate
    Object.entries(sliceData).forEach(([stepIdString, step]) => {
      const currentStepId = Number(stepIdString);

      // Check if the stepId is either undefined (validate all) or less than or equal to the given stepId
      if ((stepId === undefined || currentStepId <= stepId) && step.showStep) {
        Object.values(step.stepData.stepQuestions).forEach((question) => {
          if (
            question.showQuestion &&
            question.error &&
            !EReturnAutomationHiddenKeys.includes(question.key)
          ) {
            errorQuestionKeysWithStepId.push({
              stepId: currentStepId,
              questionKey: question.key,
            });
          }
        });
      }
    });

    return errorQuestionKeysWithStepId;
  },
);
