import { WebhookQuestionDetails } from "src/features/ReturnAutoWorkFlow/ReturnAutoWorkFlow.types";
import styles from "./VerifyProcessModal.module.scss";

const VerificationStepStatus = ({
  check,
  error,
  stepName,
  description,
}: {
  check?: "success" | "error";
  error?: string;
  stepName: string;
  description: string;
}) => {
  return (
    <div className="mb-2">
      <div className="d-flex align-items-center">
        {check === "success" ? (
          <span className={`me-1 ${styles.checkBox}`}>
            <i className="fa-solid fa-check"></i>
          </span>
        ) : check === "error" ? (
          <span className={`me-1 ${styles.uncheckBox}`}>
            <i className="fa-solid fa-xmark"></i>
          </span>
        ) : (
          <div className={`me-1 ${styles.normalDot}`}></div>
        )}
        <span className={`${styles.stepText}`}>{stepName}</span>
      </div>
      <p className={`${styles.description}`}>{description}</p>
      {error?.trim() ? (
        <span className={`d-block ${styles.errorMsg}`}>{error}</span>
      ) : (
        ""
      )}
    </div>
  );
};

const VerifyProcessModal = ({
  orderStatus,
  verify,
  back,
}: {
  orderStatus: WebhookQuestionDetails["orderStatus"];
  verify: () => void;
  back: () => void;
}) => {
  return (
    <div>
      <h3 className={`${styles.heading}`}>Verification Process</h3>
      <p className={`${styles.subHeading}`}>
        After setting up the webhook, you'll need to verify that it's correctly
        configured by completing two steps:
      </p>
      <div className={`${styles.greyBox}`}>
        <VerificationStepStatus
          check={
            orderStatus === "created" || orderStatus === "fulfilled"
              ? "success"
              : orderStatus === "notCreated"
                ? "error"
                : undefined
          }
          stepName={"Step 1: Add a new order"}
          description={`Create a new order in your platform (you can create a test order if necessary). Once the order is added, Saufter will verify if the order confirmation event has been triggered.`}
        />
        <VerificationStepStatus
          check={
            orderStatus === "fulfilled"
              ? "success"
              : orderStatus === "notCreated" || orderStatus === "created"
                ? "error"
                : undefined
          }
          stepName={"Step 2: Mark the order as fulfilled"}
          description={`Create a new order in your platform (you can create a test order if necessary). Once the order is added, Saufter will verify if the order confirmation event has been triggered.`}
          error={
            orderStatus === "notCreated"
              ? "Step 1: Order not created"
              : orderStatus === "created"
                ? "Step 2: Order not fulfilled"
                : undefined
          }
        />
      </div>
      <div className="mt-3 d-flex justify-content-center">
        <button
          className={`px-1 ${styles.backBtn}`}
          onClick={back}
        >
          Back
        </button>
        <button
          className={`${styles.nextBtn}`}
          onClick={verify}
        >
          Verify webhook setup
        </button>
      </div>
    </div>
  );
};

export default VerifyProcessModal;
