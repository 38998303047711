import { useParams } from "react-router-dom";
import styles from "./GreetingTable.module.scss";
interface Props {
  img?: string;
  imgText: string;
  display: number;
  chat: number;
  conversation: string;
}
const GreetingTable = ({
  img,
  imgText,
  display,
  chat,
  conversation,
}: Props) => {
  const params = useParams();
  // console.log(params);

  return (
    <>
      <div className={`${styles.tableHeader}`}>
        <div className={`${styles.widthSet1}`}>
          <span className={`${styles.headerName}`}>
            <img className={`${styles.tableImg}`} src={img} alt="" />
            <span className={`ms-2 ${styles.imgText}`}>{imgText}</span>
          </span>
        </div>

        {params.subSettingName === "announcement" ? (
          <div className="d-flex ">
            <div className={`${styles.greenDot} `}></div>
            <div className={` ${styles.imgText}`}>Running Till 30/06/22</div>
          </div>
        ) : (
          <div className={`${styles.widthSet2}`}>
            <span className={`${styles.headerName}`}>
              <div className="form-check form-switch mb-0">
                <input
                  className={`form-check-input ${styles.formCheck}`}
                  type="checkbox"
                />
              </div>
            </span>
          </div>
        )}
        {params.subSettingName === "announcement" ? (
          ""
        ) : (
          <>
            <div className={`${styles.widthSet3}`}>
              <span className={`${styles.headerName}`}>{display} </span>
            </div>
            <div className={`${styles.widthSet4}`}>
              <span className={`${styles.headerName}`}>{chat} </span>
            </div>
            <div className={`${styles.widthSet5}`}>
              <span className={`${styles.headerName}`}>{conversation} </span>
            </div>
          </>
        )}

        <div className={`${styles.widthSet6}`}>
          {params.subSettingName === "announcement" ? (
            0
          ) : (
            <div>
              <span className={`${styles.headerName}`}>Reports</span>
            </div>
          )}

          <div className="dropdown">
            <button
              className={`dropdown-toggle ${styles.dropBtn}`}
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="fa-solid fa-ellipsis"></i>
            </button>
            <ul
              className={`dropdown-menu ${styles.dropBox}`}
              aria-labelledby="dropdownMenuButton1"
            >
              <li>
                <a className={`dropdown-item ${styles.details}`} href="#">
                  Edit
                </a>
              </li>
              <li>
                <a className={`dropdown-item ${styles.details}`} href="#">
                  Duplicate
                </a>
              </li>
              <li>
                <a className={`dropdown-item ${styles.delete}`} href="#">
                  Delete
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
export default GreetingTable;
