import { useAppSelector } from "src/store/store";
import { totalTicketsSelectedSeletector, updatedTicketsCountSeletector } from "src/store/slices/tickets/tickets.slice";
import BaseSuccessModalBody from "src/components/BulkActionComponents/commons/BaseSuccessModalBody/BaseSuccessModalBody";

const AssignToSuccess = () => {
  const totalTicketsSelected = useAppSelector(totalTicketsSelectedSeletector);
  const updatedTicketsCount = useAppSelector(updatedTicketsCountSeletector);

  return (
    <BaseSuccessModalBody>
      Tags added for {updatedTicketsCount} ticket(s)!
    </BaseSuccessModalBody>
  );
};

export default AssignToSuccess;
