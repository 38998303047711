import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/store/store";

import { IncomingRecieveType } from "src/services/EmailIntegration/getEmailIntegrationById";
import { actions } from "src/store/slices/emailIntegration/emailIntegration.slices";
import { fetchGetEmailIntegrationById } from "src/store/slices/emailIntegration/emailIntegration.thunks";

const useVerifyEmailProcess = (
  integrationId?: string,
  navigateCallback?: () => void
) => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { emailLoading: loader, selectedUser } = useAppSelector(
    (state) => state.emailIntegration
  );

  // Added to display a loader based on the API status when switching to another integration,
  // ensuring to display correct details
  useEffect(() => {
    // Dispatch an action to fetch email integration by ID
    dispatch(
      fetchGetEmailIntegrationById({
        emailIntegrationId: Number(integrationId),
        // Callback function executed after fetching the integration
        callback: (status) => {
          // Callback for redirecting user
          if (status === undefined) {
            if (navigateCallback) {
              navigateCallback();
            } else {
              navigate(`/settings/integrations/email/connectEmail`);
            }
          }
        },
      })
    );
    // Cleanup function: set verifyEmailLoading state to idle when the component is unmounted
    return () => {
      dispatch(actions.setverifyEmailLoadingIdle());
    };
  }, [dispatch, integrationId, navigate, navigateCallback]);

  const [selectedType, setSelectedType] = useState<IncomingRecieveType>(
    selectedUser.incomingMailSettings?.receiveType ?? "currentHost"
  );

  useEffect(() => {
    setSelectedType(
      selectedUser.incomingMailSettings?.receiveType ?? "currentHost"
    );
  }, [selectedUser.incomingMailSettings?.receiveType]);

  return {
    selectedType,
    navigate,
    loader,
    selectedUser,
    setSelectedType,
  };
};

export default useVerifyEmailProcess;
