import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";
import { AutomationAPIPayload } from "../createAutomation.service";

export interface DispatchRuleCategory {
  id: string;
  name: string;
  description: string;
}

export interface GetAllIssueCategoryParam {
  start: number;
  limit: number;
}

interface Response {
  categories: Record<string, DispatchRuleCategory>;
  categoryIds: string[];
  metaData: {
    hasMore: boolean;
  };
}

export const getAllIssueCategoryService = async ({
  payload,
  type,
}: AutomationAPIPayload<GetAllIssueCategoryParam>) => {
  const apiEndPoint = IS_TEST_ACTIVE
    ? `${process.env.REACT_APP_TEST_SITE_URL}/bugManagement/getAllCategory`
    : `/api/automation/${type}/issueCategory/getAll`;

  const { data: res } = await axiosJSON.post(apiEndPoint, payload);

  if (res.err || res.error) {
    throw res.msg || res.message || "Cannot get categories";
  }

  const ret: Response = {
    categories: {},
    categoryIds: [],
    metaData: {
      hasMore: true,
    },
  };

  res.data.forEach((val: DispatchRuleCategory) => {
    const id = val.id + "";
    ret.categoryIds.push(id);
    ret.categories[id] = { ...val, id };
  });

  if (ret.categoryIds.length < payload.limit) {
    ret.metaData.hasMore = false;
  }

  return ret;
};
