import { axiosJSON } from "src/globals/axiosEndPoints";

interface Payload {
  planId: string;
  seats: number | string;
}

interface Response {
  status_code:
    | "user_redirect"
    | "upgrade_success"
    | "upgrade_cancel"
    | "upgrade_failed";
  data?: {
    url?: string;
  };
}

/**
 * Service for initiating upgrade plan
 */
const processUpgradePlanService = async (payload: Payload) => {
  const { data: res } = await axiosJSON.post(
    `/api/setting/billing/processUpgradePlan`,
    payload,
  );

  if (res.err || res.error) {
    throw res.msg ?? res.message ?? "Something went wrong!";
  }

  // Response Object
  const ret = {
    status_code: res.status_code,
    data: res?.data,
  } as Response;

  // Redirect to Payment Page
  if (ret.status_code === "user_redirect") {
    // Check if we have page url
    if (ret.data?.url) {
      window.location.replace(ret.data.url);
    } else {
      throw Error;
    }
  }

  // If we have payment status for automated payment
  if (
    ret.status_code === "upgrade_success" ||
    ret.status_code === "upgrade_failed" ||
    ret.status_code === "upgrade_cancel"
  ) {
    return ret.status_code;
  }
  return null;
};

export default processUpgradePlanService;
