import backBtn from "src/assets/images/backArrow.png";
import Loader from "src/components/Loader";

import EmailProcess from "../EmailProcess/EmailProcess";
import styles from "./DomainIntegration.module.scss";
import useDomainIntegration from "./useDomainIntegration";
import OutgoingMailSetting from "./children/OutgoingMailSetting/OutgoingMailSetting";
import DomainVerification from "../../../../DomainVerification/DomainVerification";
import CurrentHost from "./children/OutgoingMailOption/CurrentHostOption/CurrentHost";
import EmailAddress from "./children/EmailAddress/EmailAddress";
import { useParams } from "react-router-dom";
import { useMemo } from "react";

/**
 * Component for rendering domain integration
 */
function DomainIntegration() {
  const { integrationId } = useParams();

  const {
    loader,
    checkingAuth,
    navigate,
    disableNextButton,
    handleSelect,
    selectedType,
    selectedUser,
  } = useDomainIntegration(integrationId);

  const isDomainVerifiedForSMTP = useMemo(() => {
    if (selectedUser.outgoingMailSettings?.integrations) {
      return selectedUser.outgoingMailSettings.integrations["SMTP"]?.verified;
    }

    return selectedUser.isDomainVerified;
  }, [
    selectedUser.isDomainVerified,
    selectedUser.outgoingMailSettings.integrations,
  ]);
  return (
    <div
      className={`d-flex-column justify-content-center ${styles.contentWidth}`}
    >
      {loader === "pending" ? (
        <Loader />
      ) : (
        <>
          <div className="d-flex">
            <div
              className={`${styles.backWidth} mt-3 ps-1 `}
              role="button"
              onClick={() => {
                navigate("/settings/integrations/email");
              }}
            >
              <img
                src={backBtn}
                className={`${styles.backBtn}`}
                alt="back"
              />
            </div>
            <div className={`${styles.connectWidth}`}>
              <EmailProcess
                step={3}
                delete={true}
              />
              <OutgoingMailSetting
                selectedType={selectedType}
                preferred={
                  selectedUser.outgoingMailSettings?.preferredReceiveType ??
                  "currentHostWithDNS"
                }
                onSelect={handleSelect}
              />
              {checkingAuth ? (
                <Loader />
              ) : (
                <>
                  {selectedType &&
                  (selectedType === "currentHostWithDNS" ||
                    selectedType === "gmail") ? (
                    <DomainVerification isGmail={selectedType === "gmail"} />
                  ) : selectedType === "SMTP" ? (
                    <EmailAddress
                      type="outgoing"
                      isStepDone={!!isDomainVerifiedForSMTP}
                    />
                  ) : selectedType === "currentHost" ? (
                    <CurrentHost />
                  ) : (
                    <></>
                  )}
                </>
              )}
              <div
                className={`ms-2 mt-3 d-flex justify-content-between align-items-center ${styles.pageWrap}`}
              >
                <button
                  className={`py-2 px-md-3 ${styles.prevBtn}`}
                  onClick={() =>
                    navigate(
                      `/settings/integrations/email/${integrationId}/verifyEmail`,
                    )
                  }
                >
                  <span className="pe-1">
                    <i className="fa-solid fa-arrow-left"></i>
                  </span>
                  Previous
                </button>
                <button
                  className={`py-2 px-md-3 ${styles.nextBtn}`}
                  disabled={disableNextButton}
                  onClick={() =>
                    navigate(
                      `/settings/integrations/email/${integrationId}/complete`,
                    )
                  }
                >
                  Next{" "}
                  <span className="ps-1">
                    <i className="fa-solid fa-arrow-right"></i>
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div
            className={`${styles.connectWidth}`}
            style={{ marginLeft: "30px" }}
          ></div>
        </>
      )}
    </div>
  );
}

export default DomainIntegration;
