import styles from './SetupComplete.module.scss';
const SetupComplete = () => {
  return (
    <div className={`w-100 text-center ${styles.modalWrapper}`}>
      <div className={`mx-auto mb-3 ${styles.sucessCheck}`}>
        <span>
          <i className="fa-solid fa-check"></i>
        </span>
      </div>
      <p className={`mb-0 ${styles.modalHead}`}>Setup complete !</p>
    </div>
  );
};

export default SetupComplete;