/**
 * This file defines a React component responsible for showing publish/unpublish modal in customQA.
 *
 * @author @Anubhav-busibud
 * @author @navjyot-busibud
 */
import { useMutation } from "@tanstack/react-query";
import styles from "./CustomPublishModal.module.scss";
import { updateCustomQA } from "src/services/Bot/CustomQA/updateCustomQA.service";
import { useNavigate, useParams } from "react-router-dom";
import { QuestionAns } from "src/services/Bot/CustomQA/getAllQuestionAnswer.service";
import { useEffect, useState } from "react";
interface Props {
  onHide: () => void;
  customQAId: string;
  status: "public" | "draft";
  handleStatusUpdate: (
    customQAId: number | string,
    values: Partial<QuestionAns>,
  ) => void;
}
const CustomPublishModal = ({
  onHide,
  customQAId,
  status,
  handleStatusUpdate,
}: Props) => {
  const [publishStatus, setPublishStatus] = useState(status);
  //mutation to update publish status of custom qa
  const updateQnA = useMutation({
    mutationFn: updateCustomQA,
    onSuccess: (data, variables, context) => {
      //updating the react-query with updated value
      handleStatusUpdate(variables.customQAId, {
        status: variables.status === "public" ? "public" : "draft",
      });
      if (variables.status)
        setPublishStatus(variables.status === "public" ? "public" : "draft");

    },
  });
  const { profileId } = useParams();

  useEffect(() => {
    setPublishStatus(status);
  }, [status]);
  const navigate = useNavigate();
  return (
    <div className={`text-center ${styles.modalWrapper}`}>
      <div className={`mx-auto ${styles.successImg}`}>
        <i className="fa-solid fa-check"></i>
      </div>
      <span className={`${styles.publishText}`}>
        {publishStatus === "public"
          ? `Custom answer published!`
          : `Custom answer unpublished!`}
      </span>
      <div className={`d-flex justify-content-center ${styles.btnWrapper}`}>
        <button
          className={`px-3 ${styles.unPublish}`}
          onClick={() => {
            updateQnA.mutate({
              botProfileId: profileId || "",
              customQAId: customQAId || "",
              status: publishStatus === "public" ? "draft" : "public",
            });
          }}
        >
          <span className="pe-1">
            <i className="fa-regular fa-eye"></i>
          </span>
          {publishStatus === "public" ? "Unpublish" : "Publish"}
          {updateQnA.isLoading ? (
            <span>
              <span
                className="spinner-border spinner-border-sm ms-2"
                role="status"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Loading...</span>
            </span>
          ) : null}
        </button>
        <button
          className={`px-3 ${styles.okBtn}`}
          onClick={() => {
            navigate(`/bot/botProfiles/${profileId}/answerSource/customQa`);
            onHide();
            // alert("Auto training started");
          }}
        >
          Okay
        </button>
      </div>
    </div>
  );
};

export default CustomPublishModal;
