import { axiosJSON } from "src/globals/axiosEndPoints";
import {
  FormElementType,
  GiveOfferType,
  Step13TabType,
} from "./step13GetConfig";
import { ConditionType } from "../Step9/step9GetConfig";
import {
  FollowUpQuestions,
  RefundReason,
} from "src/store/slices/initiateReturn/antiRefundConfig.slice";
import { GetConfigurationPayload } from "../Step9/step9PostConfig";

interface step13PostParams {
  configTabId: number;
  configTabName: string;
  conditions: ConditionType[];
  formElements: FormElementType[];
  // giveOffer: GiveOfferType;

  // For Refund Request
  refundRequests: RefundReason[];
}

export async function fetchStep13ConfigPost(payload: GetConfigurationPayload) {
  const { data } = await axiosJSON.post(
    "/api/setting/automation/refund/step/13/configuration",
    { configTabs: payload.tabType, integrationId: payload.integrationId }
  );

  if (data.err === true) {
    throw new Error("Some Error Occurred while fetching data");
  }

  return data;
}
