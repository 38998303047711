/* eslint-disable react-hooks/exhaustive-deps */
import styles from "./PreviewSection.module.scss";
import helpLogo from "src/assets/images/helpCenter.png";
import bulb from "src/assets/images/bulb.png";
import dummy from "src/assets/images/dummy1.png";
import Loader from "src/components/Loader";
import usePreviewSection from "./usePreviewSection";
import { Fragment, useEffect, useMemo } from "react";
import InfiniteScroll from "src/components/InfiniteScrollBothSide";
import Footer from "./Children/Footer/Footer";
import FA_NightInTheOscars from "./Children/UpdatedArticleBox/UpdatedArticleBox";
import FA_AliceInWonderland from "./Children/FeatureBox/FeatureBox";
import AliceInWonderlandCategory from "./Children/AliceInWonderlandCategory/AliceInWonderlandCategory";
import CategoryNight from "./Children/CategoryNight/CategoryNight";
import CategoryDetails from "./Children/CategoryDetails/CategoryDetails";
import AliceCategoryDetails from "./Children/AliceCategoryDetails/AliceCategoryDetails";
import CustomCode from "./Children/CustomCode/CustomCode";
import { DarkLoader } from "src/components/Loader/Loader";
import themeDefaultBackground from "src/assets/images/themeDefaultBackground.png";

type Props = {
  integrationIdentifier: string;
};

const PreviewSection = ({ integrationIdentifier }: Props) => {
  const {
    articles,
    themeData,
    helpCenterPageUri,
    navigate,
    articleIsLoading,
    articleHasError,
    fetchNextPage,
    hasNextPage,
    isRefetching,
    searchTerm,
    setSearchTerm,
    categories,
    categoryIsLoading,
    categoryHasError: categoryIsError,
    categoryHasNextPage,
    fetchNextCategoryPage,
    isCategoryRefetching,
    themeDataIsLoading,
    themeDataHasError,
    isTokenExpired,
    debouncedSearchTerm,
  } = usePreviewSection({
    integrationIdentifier,
  });

  // Use the useMemo hook to calculate the value of showSearchLoader
  const showSearchLoader = useMemo(() => {
    // Check if either the category or article is currently being refetched
    // If either is refetching, show the loader
    if (
      (isCategoryRefetching && isRefetching) ||
      (articleIsLoading && categoryIsLoading)
    ) {
      return true;
    }

    // If neither category nor article is refetching, do not show the loader
    return false;
  }, [isRefetching, isCategoryRefetching, articleIsLoading, categoryIsLoading]);

  const backgroundImage = useMemo(() => {
    if (themeData?.data?.backgroundImage) {
      return themeData?.data?.backgroundImage;
    } else if (themeData?.data?.themeType === "night_in_the_oscars") {
      return themeDefaultBackground;
    }
    return "";
  }, [themeData?.data?.backgroundImage, themeData?.data?.themeType]);

  // Use the useMemo hook to calculate the value of noDataFoundError
  const noDataFoundError = useMemo(() => {
    // Check if both the category and articles are empty
    // If both is empty, show no data found
    if (!articles?.length && !categories?.length) {
      return true;
    }
    // If either category or article exists, do not show the error
    return false;
  }, [articles?.length, categories?.length]);

  if (themeDataIsLoading || articleIsLoading || categoryIsLoading) {
    return (
      <div className={`d-flex justify-content-center w-100 p-3 mb-3 `}>
        {themeData?.data?.themeType === "alice_in_wonderland" ? ( // loader for alice in wonderland
          <Loader />
        ) : (
          <>
            {/* loader for night theme  */}
            <div className={`${styles.loaderCss}`}>
              <DarkLoader />{" "}
            </div>
          </>
        )}
      </div>
    );
  }

  if (themeDataHasError || articleHasError) {
    // if (isTokenExpired) {
    //   setToken("");
    //   navigate(`/helpcenterpage/${helpCenterPageUri}`, {
    //     state: { tokenExpired: true },
    //   });
    // }
    return <div className={`p-3 ${styles.errorMsg}`}>Something went wrong</div>;
  }

  return (
    <>
      <CustomCode // custom code which will be added in head and body
        customCodeBody={themeData?.data.customCodeBody ?? ""}
        customCodeHead={themeData?.data.customCodeHead ?? ""}
      />
      <div
        style={{
          fontFamily: themeData?.data?.font ?? "Roboto", // font family
        }}
      >
        {/* commented beacuse it should not be there */}
        {/* <div
          className={`d-flex ${styles.headerBox}`}
          style={{
            backgroundColor: `${
              themeData?.data.themeType === "night_in_the_oscars"
                ? "#393939"
                : "#ffffff"
            }`,
          }}
        >
          <div className="px-3 d-flex align-items-center">
            <div className={`${styles.dots}`}></div>
            <div className={`${styles.dots} ${styles.red}`}></div>
            <div className={`${styles.dots} ${styles.yellow}`}></div>
          </div>
        </div> */}
        {/* Active Theme Configruation  */}
        <div
          className={`${styles.headerSection} position-relative`}
          style={{
            backgroundColor: `${themeData?.data?.backgroundColor}`, // background color
            backgroundImage: `url(${backgroundImage})`, //added default background image
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            padding: "30px 10px 0px 10px",
          }}
        >
          <div>
            <p className="text-center mb-1 d-flex align-items-center justify-content-center">
              <img
                src={themeData?.data?.logoImage ?? helpLogo} // logo image
                alt="logo"
                className={`${styles.helpLogo}`}
              />
              {/* Store Name */}
              <span
                className={`${styles.acme}`}
                style={{
                  fontFamily: themeData?.data?.font ?? "Roboto", // font family
                }}
              >
                {themeData?.data?.storeName}
              </span>
            </p>
            {/* Preview Heading */}
            <h3
              className={`text-center ${styles.helpText}`}
              style={{
                fontFamily: themeData?.data?.font ?? "Roboto", // font family
              }}
            >
              {themeData?.data?.heading}
            </h3>
            {/* Sub Heading */}
            <p
              className={`text-center ${styles.subHeading}`}
              style={{
                fontFamily: themeData?.data?.font ?? "Roboto", // font family
              }}
            >
              {themeData?.data?.subheading}
            </p>
          </div>

          {/* Search Box */}
          <div className={`d-flex align-items-center ${styles.searchBox}`}>
            <div className="position-relative w-100 text-center">
              <span className={`${styles.search}`}>
                <i className="fa-solid fa-magnifying-glass"></i>
              </span>
              <input
                type="text"
                placeholder={`${themeData?.data?.placeholder}`}
                className={`${styles.inputBox}`}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{
                  fontFamily: themeData?.data?.font ?? "Roboto", // font family
                }}
              />
            </div>
          </div>
        </div>

        {/* Articles & Categories */}
        <div className="">
          {/* Show Loader for search or initial loader if ajax status is in pending still */}
          {showSearchLoader ? (
            themeData?.data?.themeType === "alice_in_wonderland" ? ( // loader for alice in wonderland
              <Loader />
            ) : (
              <>
                {/* loader for night theme  */}
                <div
                  style={{
                    color: `${
                      themeData?.data?.themeType === "night_in_the_oscars"
                        ? "#ffffff"
                        : "#000000"
                    }`,
                    fontFamily: themeData?.data?.font ?? "Roboto", // font family
                    backgroundColor: `${
                      themeData?.data?.themeType === "night_in_the_oscars"
                        ? "#000000"
                        : "#ffffff"
                    }`,
                    paddingTop: "47px",
                  }}
                >
                  <div
                    style={{
                      height: "calc(100vh - 270px)",
                    }}
                    className={`${styles.loaderCss}`}
                  >
                    <DarkLoader />{" "}
                  </div>
                </div>
              </>
            )
          ) : /* No Data found Error section */
          noDataFoundError ? (
            <div
              style={{
                color: `${
                  themeData?.data?.themeType === "night_in_the_oscars"
                    ? "#ffffff"
                    : "#000000"
                }`,
                fontFamily: themeData?.data?.font ?? "Roboto", // font family
                backgroundColor: `${
                  themeData?.data?.themeType === "night_in_the_oscars"
                    ? "#000000"
                    : "#ffffff"
                }`,
                paddingTop: "47px",
              }}
            >
              <div
                style={{
                  height: "calc(100vh - 270px)",
                }}
                className={`${styles.nodataFound}`}
              >
                {debouncedSearchTerm && debouncedSearchTerm.trim() !== ""
                  ? "No Article Found"
                  : "No Data Found"}
              </div>
              <div
                style={{
                  backgroundColor: `${
                    themeData?.data?.themeType === "night_in_the_oscars"
                      ? "#000000"
                      : "#ffffff"
                  }`,
                  position: "absolute",
                  bottom: 0,
                  width: "100%",
                }}
              >
                <Footer
                  show={themeData?.data?.helplamaBranding ? true : false}
                  footerLogo={themeData?.data.footerLogo ?? ""}
                  font={themeData?.data.font}
                  helplamaBrandingText={themeData?.data?.helplamaBrandingText} //passing "helplamaBrandingText" prop to render powered by text dynamically
                  nightTheme={
                    themeData?.data?.themeType === "night_in_the_oscars"
                      ? true
                      : false
                  }
                />
              </div>
            </div>
          ) : (
            //render the articles section on ajax loading complete
            <div
              style={{
                backgroundColor: `${
                  themeData?.data?.themeType === "night_in_the_oscars"
                    ? "#000000"
                    : "#ffffff"
                }`,
                paddingTop: "47px",
              }}
            >
              {/* Infitite Scroll for Categoies  */}
              <InfiniteScroll
                hasMoreBottom={categoryHasNextPage}
                loadMoreFromBottom={fetchNextCategoryPage}
                className={` overflow-auto px-1 py-1 w-100 `}
                style={{ height: "calc(100vh - 270px)" }}
              >
                <div className={`px-2 px-lg-5 pb-3 ${styles.articleWrap}`}>
                  {/* Articles */}
                  {articles?.length && articles.length > 0 ? (
                    <div className="">
                      <div
                        className={`text-center w-100`}
                        style={{
                          marginTop: "30px",
                        }}
                      >
                        <h4
                          className={`${styles.featuredArticles} `}
                          style={{
                            color: `${
                              themeData?.data?.themeType ===
                              "night_in_the_oscars"
                                ? "#ffffff"
                                : "#000000"
                            }`,
                            fontFamily: themeData?.data?.font ?? "Roboto", // font family
                          }}
                        >
                          Featured Articles
                        </h4>
                      </div>
                      {isRefetching || articleIsLoading ? (
                        <div
                          className={`d-flex justify-content-center w-100 p-3 mb-3 `}
                        >
                          {themeData?.data?.themeType ===
                          "alice_in_wonderland" ? ( // loader for alice in wonderland
                            <Loader />
                          ) : (
                            <>
                              {/* loader for night theme  */}
                              <div className={`${styles.loaderCss}`}>
                                <DarkLoader />{" "}
                              </div>
                            </>
                          )}
                        </div>
                      ) : (
                        // <InfiniteScroll
                        //    loadMoreFromBottom={fetchNextPage}
                        //   className={`overflow-hidden px-1 py-1 w-100`}
                        //   style={{ height: "100%" }}
                        // >  hasMoreBottom={false}
                        <div className="w-100">
                          {articles?.length !== 0 &&
                          themeData?.data?.themeType ===
                            "night_in_the_oscars" ? ( // night theme
                            <>
                              {/* Logic for showing articles in pair and showing the article count */}
                              {articles
                                ?.reduce((acc, curr, idx1) => {
                                  if (idx1 % 2 === 0) {
                                    acc.push([curr]);
                                  } else {
                                    acc[acc.length - 1].push(curr);
                                  }
                                  return acc;
                                }, [])
                                .map((pair: any[], pairIdx: number) => {
                                  return (
                                    <div
                                      className="w-100 d-flex flex-wrap justify-content-center"
                                      key={pairIdx}
                                    >
                                      {pair.map(
                                        (article: any, articleIdx: number) => {
                                          const articleIndex =
                                            pairIdx * 2 + articleIdx + 1;
                                          return (
                                            <Fragment key={article.articleId}>
                                              <div
                                                className={`m-3 ms-0 mb-2 ${styles.articleBox}`}
                                              >
                                                <FA_NightInTheOscars // Night Theme article component
                                                  article={article}
                                                  articleIdx={articleIndex}
                                                  helpCenterPageUri={
                                                    helpCenterPageUri
                                                  }
                                                  brandColor={
                                                    themeData?.data.brandColor
                                                  }
                                                  font={themeData?.data.font}
                                                />
                                              </div>
                                              {pair.length % 2 !== 0 &&
                                              pair.length === articleIdx + 1 ? (
                                                <div
                                                  className={`m-3 ms-0 mb-2 ${styles.articleBox}`}
                                                ></div>
                                              ) : (
                                                <> </>
                                              )}
                                            </Fragment>
                                          );
                                        },
                                      )}
                                    </div>
                                  );
                                })}
                            </>
                          ) : articles?.length !== 0 &&
                            themeData?.data.themeType ===
                              "alice_in_wonderland" ? ( // alice in wonderland theme
                            <div className="d-flex flex-column align-items-center justify-content-center">
                              {articles?.map(
                                (article: any, articleIdx: number) => {
                                  return (
                                    <FA_AliceInWonderland // alice in wonderland article component
                                      article={article}
                                      articleIdx={articleIdx}
                                      helpCenterPageUri={helpCenterPageUri}
                                      brandColor={themeData?.data.brandColor}
                                      font={themeData?.data.font}
                                    />
                                  );
                                },
                              )}
                            </div>
                          ) : (
                            // for no article found it should be blank
                            <>
                              <div
                                className={`${styles.nodataFound}`}
                                style={{
                                  color: `${
                                    themeData?.data?.themeType ===
                                    "night_in_the_oscars"
                                      ? "#ffffff"
                                      : "#000000"
                                  }`,
                                  fontFamily: themeData?.data?.font ?? "Roboto", // font family
                                }}
                              >
                                No featured article found
                              </div>
                            </>
                          )}
                        </div>
                        // </InfiniteScroll>
                      )}
                    </div>
                  ) : null}

                  {/* Categories */}
                  {categories?.length && categories?.length !== 0 ? (
                    <div className="">
                      <div className={`text-center w-100`}>
                        <h4
                          className={`${styles.featuredArticles} pt-5 mt-1`}
                          style={{
                            color: `${
                              themeData?.data?.themeType ===
                              "night_in_the_oscars"
                                ? "#ffffff"
                                : "#000000"
                            }`,
                            fontFamily: themeData?.data?.font ?? "Roboto", // font family
                          }}
                        >
                          Categories
                        </h4>
                      </div>
                      {isCategoryRefetching || categoryIsLoading ? (
                        <div
                          className={`d-flex justify-content-center w-100 p-3 mb-3 `}
                        >
                          {themeData?.data?.themeType ===
                          "alice_in_wonderland" ? ( // loader for alice in wonderland
                            <Loader />
                          ) : (
                            // loader for night theme
                            <>
                              <div className={`${styles.loaderCss}`}>
                                <DarkLoader />{" "}
                              </div>
                            </>
                          )}
                        </div>
                      ) : (
                        // <InfiniteScroll
                        //   hasMoreBottom={categoryHasNextPage}
                        //   loadMoreFromBottom={fetchNextCategoryPage}
                        //   className={` overflow-auto px-1 py-1 w-100 `}
                        //   style={{ maxHeight: "50vh" }}
                        // >
                        <div>
                          {categories?.length !== 0 &&
                          themeData?.data?.themeType ===
                            "night_in_the_oscars" ? ( // night theme
                            categories
                              ?.reduce((acc, curr, idx1) => {
                                // Logic for showing categories in pair and showing the category count
                                if (idx1 % 2 === 0) {
                                  acc.push([curr]);
                                } else {
                                  acc[acc.length - 1].push(curr);
                                }
                                return acc;
                              }, [])
                              .map((pair: any[], pairIdx: number) => {
                                return (
                                  <div
                                    className="w-100 d-flex flex-wrap justify-content-center"
                                    key={pairIdx}
                                  >
                                    {pair.map(
                                      (category: any, categoryIdx: number) => {
                                        const categoryIndex =
                                          pairIdx * 2 + categoryIdx + 1;
                                        return (
                                          <Fragment key={category.categoryId}>
                                            <div
                                              className={`m-3 ms-0 mb-2 ${styles.articleBox}`}
                                            >
                                              <CategoryNight // Night Theme category component
                                                category={category}
                                                helpCenterPageUri={
                                                  helpCenterPageUri
                                                }
                                                font={themeData?.data.font}
                                              />
                                            </div>
                                            {pair.length % 2 !== 0 &&
                                            pair.length === categoryIdx + 1 ? (
                                              <div
                                                className={`m-3 ms-0 mb-2 ${styles.articleBox}`}
                                              ></div>
                                            ) : (
                                              <> </>
                                            )}
                                          </Fragment>
                                        );
                                      },
                                    )}
                                  </div>
                                );
                              })
                          ) : categories?.length !== 0 &&
                            themeData?.data.themeType ===
                              "alice_in_wonderland" ? ( // alice in wonderland theme
                            categories?.map((category: any) => {
                              return (
                                <div className="d-flex justify-content-center">
                                  <AliceInWonderlandCategory // alice in wonderland category component
                                    category={category}
                                    helpCenterPageUri={helpCenterPageUri}
                                    font={themeData?.data.font}
                                  />
                                </div>
                              );
                            })
                          ) : (
                            <div
                              className={`${styles.nodataFound}`}
                              style={{
                                color: `${
                                  themeData?.data?.themeType ===
                                  "night_in_the_oscars"
                                    ? "#ffffff"
                                    : "#000000"
                                }`,
                                fontFamily: themeData?.data?.font ?? "Roboto", // font family
                              }}
                            >
                              No Category Found
                            </div>
                          )}
                        </div>
                        // </InfiniteScroll>
                      )}
                    </div>
                  ) : null}
                  {/* Footer */}
                  <div
                    style={{
                      backgroundColor: `${
                        themeData?.data?.themeType === "night_in_the_oscars"
                          ? "#000000"
                          : "#ffffff"
                      }`,
                      position: "absolute", // to see footer at the bottom of the page
                      bottom: 0,
                      width: "100%",
                      left: 0,
                      right: 0,
                    }}
                  >
                    <Footer
                      show={themeData?.data?.helplamaBranding ? true : false}
                      footerLogo={themeData?.data.footerLogo ?? ""}
                      font={themeData?.data.font}
                      helplamaBrandingText={
                        themeData?.data?.helplamaBrandingText
                      } //passing "helplamaBrandingText" prop to render powered by text dynamically
                      nightTheme={
                        themeData?.data?.themeType === "night_in_the_oscars"
                          ? true
                          : false
                      }
                    />
                  </div>
                </div>
              </InfiniteScroll>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PreviewSection;
