import { getChatSettingData } from "src/services/LiveChat/Settings/getChatSettingData";
import {
  duplicateArticle,
  duplicateArticleParams,
} from "src/services/LiveChat/Settings/knowledgeBased/article/duplicateArticle";
import {
  GetAllArticleCategoriesParamsV2,
  getAllArticleCategoryV2,
} from "src/services/LiveChat/Settings/knowledgeBased/articleCategory/getAllArticleCategoryV2";
import {
  GetAllArticleParamsV2,
  getAllArticles,
} from "src/services/LiveChat/Settings/knowledgeBased/articleCategory/getAllArticles";
import {
  addFeaturedArticle,
  ICreateFeaturedArticleParam,
} from "src/services/LiveChat/Settings/knowledgeBased/featuredArticles/addFeaturedArticle";
import {
  getAllFeaturedArticles,
  IGetAllFeaturedArticleParams,
} from "src/services/LiveChat/Settings/knowledgeBased/featuredArticles/getAllFeaturedArticles";
import {
  IUpdateFeaturedArticleParam,
  updateFeaturedArticle,
} from "src/services/LiveChat/Settings/knowledgeBased/featuredArticles/updateArticle";
import {
  IUpdateKnowledgeBasedParams,
  updateChatSetting,
} from "src/services/LiveChat/Settings/updateChatSettingData";
import { RootState } from "src/store/store";
import { setCustomizationData } from "../customization/customizationSettings.slice";

const fetchKnowledgeBasedDataThunk = async (
  payload: string | number,
  { getState, dispatch }: { getState: Function; dispatch: Function }
) => {
  const currentState: RootState = getState();

  const knowledgeBasedData = await getChatSettingData({
    integrationId: payload,
    knowledgeBased: true,
    customization: true,
  });
  //getting the customization data to show the styling in the knowledgebase widget preview
  if(knowledgeBasedData?.customization){
    dispatch(setCustomizationData(knowledgeBasedData.customization));
  }
  return knowledgeBasedData;
};

interface updateKnowledgeBasedDataParams extends IUpdateKnowledgeBasedParams {
  integrationId: number | string;
  callback?: () => void;
}

async function updateKnowledgeBasedDataThunk(
  { callback, integrationId, ...payload }: updateKnowledgeBasedDataParams,
  { getState }: { getState: Function }
) {
  const currentState: RootState = getState();
  if (currentState.knowledgeBasedSettings.knowledgeBasedData === null) {
    throw "something went wrong!";
  }
  const knowledgeBasedData = await updateChatSetting({
    integrationId: integrationId,
    knowledgeBased: payload,
  });
  if (callback) {
    setTimeout(() => {
      callback();
    }, 0);
  }

  return knowledgeBasedData;
}

//thunk to fetch all featured articles
async function fetchAllFeaturedArticlesThunk(
  payload: IGetAllFeaturedArticleParams,
  { getState }: { getState: Function }
) {
  const data = await getAllFeaturedArticles(payload);

  return data;
}

interface AddFeaturedArticlePayload {
  integrationId: number | string;
  callback?: () => void;
}
//thunk to fetch all featured articles
async function addFeaturedArticleThunk(
  { callback, integrationId }: AddFeaturedArticlePayload,
  { getState }: { getState: Function }
) {
  const currentState: RootState = getState();

  const featuredArticles = Object.values(
    currentState.knowledgeBasedSettings.activeFeaturedArticle.featuredArticles
  ).map((featuredArticle) => {
    return {
      articleId: featuredArticle.articleId,
      shortTitle: featuredArticle?.shortTitle ?? "",
    };
  });
  const payload: ICreateFeaturedArticleParam = {
    integrationId,
    type: currentState.knowledgeBasedSettings.activeFeaturedArticle.type,
    featuredArticles,
    domainConstraint:
      currentState.knowledgeBasedSettings.activeFeaturedArticle
        .domainConstraint,
    domainUrl:
      currentState.knowledgeBasedSettings.activeFeaturedArticle.domainUrl,
  };

  if (payload.type === "default") {
    delete payload.domainConstraint;
    delete payload.domainUrl;
  }

  const res = await addFeaturedArticle(payload);

  setTimeout(() => {
    if (callback) {
      callback();
    }
  });
  return res;
}

interface UpdateFeaturedArticlePayload {
  integrationId: number | string;
  callback?: () => void;
}
//thunk to fetch all featured articles
async function updateFeaturedArticleThunk(
  { callback, integrationId }: UpdateFeaturedArticlePayload,
  { getState }: { getState: Function }
) {
  const currentState: RootState = getState();

  const featuredArticles = Object.values(
    currentState.knowledgeBasedSettings.activeFeaturedArticle.featuredArticles
  ).map((featuredArticle) => {
    return {
      articleId: featuredArticle.articleId,
      shortTitle: featuredArticle?.shortTitle ?? "",
    };
  });
  const payload: IUpdateFeaturedArticleParam = {
    featuredArticleId:
      currentState.knowledgeBasedSettings.activeFeaturedArticle.id,
    integrationId,
    type: currentState.knowledgeBasedSettings.activeFeaturedArticle.type,
    featuredArticles,
    domainConstraint:
      currentState.knowledgeBasedSettings.activeFeaturedArticle
        .domainConstraint,
    domainUrl:
      currentState.knowledgeBasedSettings.activeFeaturedArticle.domainUrl,
  };
  if (payload.type === "default") {
    delete payload.domainConstraint;
    delete payload.domainUrl;
  }
  const res = await updateFeaturedArticle(payload);

  setTimeout(() => {
    if (callback) {
      callback();
    }
  });

  return res;
}

//thunk to fetch all article category
async function fetchAllArticleCategoriesThunk(
  payload: GetAllArticleCategoriesParamsV2,
  { getState }: { getState: Function }
) {
  const data = await getAllArticleCategoryV2(payload);

  return data;
}

//thunk to fetch all articles
async function fetchAllArticlesThunk(
  payload: GetAllArticleParamsV2,
  { getState }: { getState: Function }
) {
  const data = await getAllArticles(payload);

  return { categoryId: payload.categoryId, ...data };
}

async function duplicateArticleThunk(
  payload: duplicateArticleParams,
  { getState }: { getState: Function }
) {
  const data = await duplicateArticle({
    ...payload,
  });

  return data;
}

export default {
  fetchKnowledgeBasedDataThunk,
  updateKnowledgeBasedDataThunk,
  fetchAllFeaturedArticlesThunk,
  addFeaturedArticleThunk,
  updateFeaturedArticleThunk,
  fetchAllArticleCategoriesThunk,
  fetchAllArticlesThunk,
  duplicateArticleThunk,
};
