import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { deleteWebIntegration } from "src/services/LiveChat/Settings/website/deleteWebIntegration";
import {
  checkLCInstallationT,
  sendWidgetScriptEmail,
} from "src/store/slices/liveChatSetting/websiteSettings/websiteSettings.slice";
import { useAppDispatch } from "src/store/store";
import { isEmailsValid } from "src/utils/utils";
import { ModalTypes } from "../../../ToastModals/ToastModals";

interface Props {
  integrationId?: string;
}

/**
 * Custom hook for widget integration utility.
 */
export const useWidgetIntegrationUtility = ({ integrationId }: Props) => {
  // State variables
  const [deleteLoading, setDeleteLoading] = useState(false); // Flag for loading state during deletion
  const [showDeleteModal, setShowDeleteModal] = useState(false); // Flag for showing delete confirmation modal
  const [installLoading, setInstallLoading] = useState(false); // Flag for loading state during installation
  const [showModal, setShowModal] = useState("" as ModalTypes); // State for showing different modals

  // Redux dispatch and navigation hook
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Function to check installation of the widget
  const checkInstallation = useCallback(() => {
    if (integrationId) {
      setInstallLoading(true); // Set loading state to true during installation check
      setShowModal("Installation"); // Show modal indicating installation process
      // Dispatch action to check widget installation status
      dispatch(checkLCInstallationT());
    }
  }, [integrationId]);

  // Function to handle deletion of the widget integration
  const handleDelete = useCallback(() => {
    if (integrationId) {
      setDeleteLoading(true); // Set loading state to true during deletion process
      // Call API to delete the widget integration
      deleteWebIntegration({
        integrationId: integrationId,
      })
        .then((res) => {
          // Display success message if deletion is successful
          pushTheToast({
            type: "success",
            text: "Widget deleted successfully!",
            position: "top-right",
          });
          setDeleteLoading(false); // Reset loading state
          navigate("/live-chat/settings"); // Redirect to settings page
        })
        .catch((err) => {
          // Display error message if deletion fails
          pushTheToast({
            type: "danger",
            text: "Failed to delete!",
            position: "top-right",
          });
          setDeleteLoading(false); // Reset loading state
        });
    }
  }, [integrationId]);

  // Return utility functions and state
  return {
    handleDelete,
    showDeleteModal,
    setShowDeleteModal,
    deleteLoading,
    setDeleteLoading,
    checkInstallation,
    installLoading,
    setInstallLoading,
    showModal,
    setShowModal,
  };
};
