import { axiosJSON } from "src/globals/axiosEndPoints";

export async function readTicketLinkURLData(payload: { ticket_link: string }) {
  try {
    const { data } = await axiosJSON.post(
      `/api/ticket/getTicketDataFromTransfrUrl`,
      payload
    );
    return data;
  } catch (e) {
    throw "Something went wrong!";
  }
}
