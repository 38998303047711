import { ICustomerData } from "../shopifySidebar/shopifySidebar.slice";

export interface Phone {
  countryCode: string;
  phoneNumber: string;
}

export interface OtherEmails {
  value: string;
  key: string;
}

export interface EditCustomerDetailsSlice {
  customer: ICustomerData;
}

export const initialCustomerState: ICustomerData = {
  id: "" as string,
  name: "",
  email: "",
  channels: [],
  customerProfileImageUrl: "",
  profileImageId: "",
  contacts: {
    otherEmails: [],
    phone: [],
    whatsapp: [], //Adding whatsapp field
    instagram: [],
    facebook: [],
    twitter: [],
  },
};

export const initialState: EditCustomerDetailsSlice = {
  customer: initialCustomerState,
};
