/**
 * TagDropDown Component
 *
 * This component represents a dropdown for selecting tags with support for infinite scroll and search functionality.
 * used in Audience Configure modal
 * @author @yuvaraj-busibud
 */
import React, { useEffect, useMemo, useRef, useState } from "react";
import styles from "./TagDropDown.module.scss";
import CustomSelectDropdown from "../../../CustomSelectDropdown/CustomSelectDropdown";
import useDebounce from "src/hooks/useDebounce";
import {
  fetchAllTheTags,
  resetTagsSettings,
  setTagsSearchValue,
} from "src/store/slices/settings/tags/tagsSettings.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";

interface TagOption {
  value: string | number;
  label: string;
}
interface Props {
  value: string | null | Array<string | number>;
  additionalTagOptions?: Array<TagOption>;
  handleChange: (value: Array<string | number>) => void;
  error?: string | null;
}

const TagDropDown = ({
  value,
  handleChange,
  error,
  additionalTagOptions = [],
}: Props) => {
  // State for managing selected tags
  const [selectedTags, setSelectedTags] = useState<TagOption[]>();

  // Redux dispatch hook
  const dispatch = useAppDispatch();

  // State for managing search term
  const [searchTerm, setSearchTerm] = useState("");

  // Placeholder array for options
  const options: any = [];

  // Debouncing the search term for improved performance
  const debouncedSearchTerm = useDebounce(searchTerm, 2000);

  // Reference to the input element for search
  const inputSearchRef: any = useRef(null);

  // Selecting relevant data from the Redux store using useSelector
  const { tags, tagIdList, metaData, tagsAjaxStatus } = useAppSelector(
    (state) => state.tagsSettings
  );

  // Reference to the total number of tags for infinite scroll
  const totalTagsRef = useRef(metaData.total);

  // Effect to update the totalTagsRef when metaData.total changes
  useEffect(() => {
    totalTagsRef.current = metaData.total;
  }, [metaData.total]);

  // Method to handle infinite scroll and fetch more tags
  const handleInfiniteScroll = () => {
    if (totalTagsRef.current !== 0) {
      if (totalTagsRef.current > 0) {
        dispatch(fetchAllTheTags());
      }
    }
  };

  // Effect to trigger tag search when debouncedSearchTerm changes
  useEffect(() => {
    handleSearchTags();
  }, [debouncedSearchTerm]);

  // Method to trigger the tag search with the current search term
  const handleSearchTags = () => {
    dispatch(resetTagsSettings());
    dispatch(setTagsSearchValue({ searchValue: searchTerm }));
    dispatch(fetchAllTheTags());
  };

  // Memoized tagOptions for improved performance
  const tagOptions = useMemo(() => {
    if (tags) {
      return Object.values(tags).map((option) => {
        return {
          label: option.tagName,
          value: option.id,
        };
      });
    }

    return [];
  }, [tags]);

  // Effect to update selected tags when value and tagOptions change
  useEffect(() => {
    if (value && Array.isArray(value) && tagOptions) {
      const selectedOptions = value
        .map((value) => {
          return [...tagOptions, ...additionalTagOptions].find(
            (option) => option.value == value
          );
        })
        .filter((item) => item !== undefined && item !== null);

      setSelectedTags(selectedOptions as TagOption[]);
    }
  }, [value, tagOptions]);

  return (
    <div className="ms-2">
      <CustomSelectDropdown
        selectedOption={selectedTags}
        setSelectedOption={(value) => {
          if (Array.isArray(value)) {
            handleChange(value.map((item) => item.value));
          } else {
            handleChange([value.value]);
          }
        }}
        options={tagOptions}
        menuClassName={`${styles.containDrop}`}
        placeholder="Search Tag"
        isMultiSelect={true}
        isSearchable={true}
        error={!selectedTags || selectedTags.length ? error : ""}
        handleSearch={(value: string) => {
          setSearchTerm(value);
        }}
        loadMore={handleInfiniteScroll}
      />
    </div>
  );
};

export default TagDropDown;
