import { IncomingRecieveType } from "src/services/EmailIntegration/getEmailIntegrationById";
import style from "./incomingmailsetting.module.scss";
import { useAppSelector } from "src/store/store";
import { MailTypeEnum } from "src/store/slices/emailIntegration/emailIntegration.slices";

interface Props {
  selectedType: IncomingRecieveType;
  preferred: IncomingRecieveType;
  onSelect: (selected: IncomingRecieveType) => void;
  isEmailSetup?: boolean;
}

/**
 * Component for showing incoming mail type settings
 */
const IncomingMailSetting = ({
  selectedType,
  preferred,
  onSelect,
  isEmailSetup,
}: Props) => {
  const { mailType, isEmailVerified } = useAppSelector(
    (state) => state.emailIntegration.selectedUser,
  );

  return (
    <div className={`ms-2 mt-5 ${style.container} ${isEmailSetup && "w-100"}`}>
      {/* Heading */}
      <div className={`${style.topContainer}`}>
        <p>
          How would you like to configure Saufter to receive your incoming
          emails?
        </p>
      </div>

      {/* Buttons */}
      <div className={style.bottomContainer}>
        {/* Current Host */}
        <div className="form-check">
          <input
            className={`form-check-input ${style.inputRadio}`}
            type="radio"
            name="currentHost"
            disabled={isEmailVerified}
            id="currentHost"
            checked={selectedType === "currentHost"}
            onChange={() => onSelect("currentHost")}
          />
          <label
            className={`form-check-label ${style.formLabel}`}
            htmlFor="currentHost"
          >
            I will setup email forwarding from my current email host to Saufter.
            <span className={`ps-2 ${style.link} d-none`}>
              See how it works
            </span>
            {preferred === "currentHost" && (
              <span className={`${style.preferred} ms-3`}>Preferred</span>
            )}
          </label>
        </div>

        {/* IMAP */}
        <div className="form-check">
          <input
            className={`form-check-input ${style.inputRadio}`}
            type="radio"
            name="IMAP"
            id="IMAP"
            disabled={isEmailVerified}
            checked={selectedType === "IMAP"}
            onChange={() => onSelect("IMAP")}
          />
          <label
            className={`form-check-label ${style.formLabel}`}
            htmlFor="IMAP"
          >
            I will prefer if you could integrate using my email host using IMAP
            <span className={`ps-2 ${style.link} d-none`}>
              See how it works
            </span>
            {preferred === "IMAP" && (
              <span className={`${style.preferred} ms-3`}>Preferred</span>
            )}
          </label>
        </div>

        {/** GMAIL API */}
        {/* {mailType.id === MailTypeEnum.gmail && (
          <div className="form-check">
            <input
              className={`form-check-input ${style.inputRadio}`}
              type="radio"
              name="GMAIL"
              id="GMAIL"
              // disabled={isEmailVerified}
              checked={selectedType === "gmail"}
              onChange={() => onSelect("gmail")}
            />
            <label
              className={`form-check-label ${style.formLabel}`}
              htmlFor="GMAIL"
            >
              Please receive using Gmail's API. This will only require you to
              enter the username and password for Gmail.
              <span className={`ps-2 ${style.link} d-none`}>
                See how it works
              </span>
              {preferred === "gmail" && (
                <span className={`${style.preferred} ms-3`}>Preferred</span>
              )}
            </label>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default IncomingMailSetting;
