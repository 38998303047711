import { MouseEventHandler } from "react";

import { useAppSelector } from "src/store/store";
import { InvoiceDetail } from "src/services/Billing/getInvoiceList";

import eye from "src/assets/images/eyeIcon.png";
import hoverEye from "src/assets/images/eyeHover.png";
import download from "src/assets/images/downloadIcon.png";
import hoverDownload from "src/assets/images/donwloadHover.png";
// import more from "src/assets/images/info.png";
// import hoverMore from "src/assets/images/hoverMore.png";
import clock from "src/assets/images/clockIcon.png";

import styles from "./InVoiceRow.module.scss";

interface Props {
  invoice: InvoiceDetail;
}

/**
 * Component for rendering each row in Invoice List
 */
const InVoiceRow = ({ invoice }: Props) => {
  const userTimezone = useAppSelector(
    (state) => state.globals.currentUserData?.userTimezone,
  );

  /**
   * Handler for showing Invoice
   */
  const handleShowInvoice: MouseEventHandler<HTMLElement> = (e) => {
    e.stopPropagation();
    // If we have invoice
    if (invoice.url) {
      // Open in a new tab
      window.open(invoice.url ?? "", "_blank");
    }
  };

  return (
    <div>
      {/* Table */}
      <div className={`${styles.tableHead}`}>
        {/* Date paid */}
        <div className={`${styles.dateWrapper}`}>
          <p className={`mb-0 d-flex align-items-center`}>
            <img
              src={clock}
              alt=""
              className={`${styles.clockImg}`}
            />
            <span className={`ps-1 ${styles.time}`}>
              {new Date(invoice.datePaidGMT + "Z").toLocaleString("en-IN", {
                day: "numeric",
                month: "short",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
                timeZone: userTimezone,
              })}
            </span>
          </p>
        </div>

        {/* Amount Paid */}
        <div className={`${styles.amountWrapper}`}>
          <span className={`ms-2 ${styles.price}`}>{invoice.price}</span>
        </div>

        {/* Status */}
        <div className={`${styles.statusWrapper}`}>
          <span
            className={`${styles.status} ${
              invoice.status === "unpaid"
                ? styles.unpaid
                : invoice.status === "paid"
                ? styles.paid
                : invoice.status === "draft"
                ? styles.draft
                : ""
            }`}
          >
            {invoice.status.charAt(0).toUpperCase() + invoice.status.slice(1)}
          </span>
        </div>

        {/* View Invoice */}
        {/* This only downloads PDF for now */}
        <div
          className={`${styles.invoiceWrapper} ${
            invoice.url ? "cursor-pointer" : ""
          }`}
        >
          <img
            src={eye}
            alt=""
            onMouseOver={(e) => {
              if (invoice.url) {
                e.currentTarget.src = hoverEye;
              }
            }}
            onMouseOut={(e) => (e.currentTarget.src = eye)}
            onClick={handleShowInvoice}
          />
        </div>

        {/* Download Invoice */}
        <div
          className={`${styles.downloadWrapper} ${
            invoice.url ? "cursor-pointer" : ""
          }`}
        >
          <img
            src={download}
            alt=""
            onMouseOver={(e) => {
              if (invoice.url) {
                e.currentTarget.src = hoverDownload;
              }
            }}
            onMouseOut={(e) => (e.currentTarget.src = download)}
            onClick={handleShowInvoice}
          />
        </div>

        {/* More Options - Hidden for now */}
        {/* <div className={`${styles.optionWrapper}`}>
          <span className={`${styles.listItem}`}>
            <img
              src={more}
              alt=""
              onMouseOver={(e) => (e.currentTarget.src = hoverMore)}
              onMouseOut={(e) => (e.currentTarget.src = more)}
            />
          </span>
        </div> */}
      </div>
    </div>
  );
};

export default InVoiceRow;
