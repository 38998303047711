import { useCallback, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import { v4 as uuid } from "uuid";

import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import {
  UpdateWebIntParams,
  updateWebIntegration,
} from "src/services/LiveChat/Settings/website/updateWebIntegration";

import styles from "../../LiveChatWidget.module.scss";
import { WebsiteIntegDet } from "src/services/LiveChat/Settings/website/getWebIntegrations";

interface Props {
  enabled: boolean;
  disabled: boolean;
  onChange: (val: boolean) => void;
  enabledText?: string;
  disabledText?: string;
  integrationId?: string;
  type?: "live_chat_enabled" | "ai_bot_enabled" | "whatsapp_enabled";
  liveChatType: UpdateWebIntParams["live_chat_integration_type"];
  selectedWhatsAppId?: string | number;
  setShowValidation?: React.Dispatch<
    React.SetStateAction<{
      webURL?: boolean | undefined;
      webName?: boolean | undefined;
      choosedBrand?: boolean | undefined;
      whatsappAccount?: boolean | undefined;
    }>
  >;
}

/**
 * Component for showing Toggle Button in Live Chat Settings
 */
function LiveChatEnableToggle({
  enabled,
  disabled,
  onChange,
  enabledText = "Live Chat Enabled",
  disabledText = "Live Chat Disabled",
  integrationId,
  type,
  liveChatType, //type of live chat integration "shopify" | "otherWebsite"
  selectedWhatsAppId,
  setShowValidation,
}: Props) {
  const idRef = useRef(uuid());
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Callback for handling toggle change and updating toggle state
   */
  const handleToggleChange = useCallback(
    async (value: boolean) => {
      if (integrationId && type) {
        if (
          setShowValidation &&
          type === "whatsapp_enabled" &&
          (!selectedWhatsAppId || selectedWhatsAppId === "")
        ) {
          setShowValidation((prev) => {
            return {
              ...prev,
              whatsappAccount: true,
            };
          });
          return;
        }
        // Update the toggle state
        onChange(value);
        // Initialize loader
        setIsLoading(true);
        try {
          // Initalize the payload
          const payload: UpdateWebIntParams = {
            integration_id: integrationId,
            live_chat_integration_type: liveChatType, //passing the integration type to payload
            [type]: value,
          };
          // Update the state
          await updateWebIntegration(payload);

          // Show Success Message
          pushTheToast({
            position: "top-right",
            text: value ? "Enabled successfully!" : "Disabled successfully!",
            type: "success",
          });
        } catch (err) {
          // Show error
          pushTheToast({
            position: "top-right",
            text: typeof err === "string" ? err : "Something went wrong!",
            type: "danger",
          });
          // Reset changes
          onChange(!value);
        } finally {
          // Hide Loader
          setIsLoading(false);
        }
      }
    },
    [liveChatType, integrationId, onChange, type, selectedWhatsAppId],
  );

  return (
    <div className="d-flex justify-content-start mb-2">
      <div className="form-check form-switch">
        <input
          className="form-check-input"
          type="checkbox"
          id={idRef.current}
          disabled={isLoading || disabled}
          checked={enabled}
          onChange={(e) => handleToggleChange(e.currentTarget.checked)}
        />
        {isLoading && (
          <Spinner
            className={`mx-1 my-auto`}
            variant="secondary"
            animation="border"
            size="sm"
          />
        )}
        <label
          className={`form-check-label ${styles.enableChat}`}
          htmlFor={idRef.current}
        >
          {enabled ? enabledText : disabledText}
        </label>
      </div>
    </div>
  );
}

export default LiveChatEnableToggle;
