import { axiosJSON } from "src/globals/axiosEndPoints";

interface ISignUp {
  name: string;
  password: string;
  token: string;
}

export async function signupUser(servicePayload: ISignUp) {
  if (
    servicePayload.token == "" ||
    servicePayload.password.trim() == "" ||
    servicePayload.name.trim() == ""
  ) {
    throw new Error("Name or Password not given!");
  }
  const { data } = await axiosJSON.post(
    `/api/setting/user/signup`,
    servicePayload
  );
  if (data.status == "0" || data.err || data.error) {
    throw new Error(data.message as string);
  }
  return data;
}
