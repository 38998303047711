/**
 * This file is the redux slice file used for storing KB data.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./knowledgeBase.declarations";
import knowledgeBaseReducers from "./knowledgeBase.reducers";

/**
 * Creating the slice
 */
export const knowledgeBaseSlice = createSlice({
  name: "knowledgeBase",
  initialState,
  reducers: knowledgeBaseReducers,
});

/**
 * Expoorting reducer actions.
 */
export const { updatePageStylingProperty, updateBranding, updateWording } =
  knowledgeBaseSlice.actions;

export default knowledgeBaseSlice.reducer;
