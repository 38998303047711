import { useState, useEffect, useMemo } from "react";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { fetchAllStatuses } from "src/store/slices/ticketStatus/ticketStatus.slice";
import { changeBulkTicketStatus } from "src/services/TicketService/changeBulkTicketStatus";
import { TicketStatus } from "src/services/TicketService/getAllStatuses";
interface Props {
  currentStatusName: string;
  onChange: (status: TicketStatus) => void;
  ticketId: string;
  initialFetchStatus?: boolean;
}
function useChangeTicketStatus({
  ticketId,
  onChange,
  currentStatusName,
}: Props) {
  const { allTicketStatuses } =
    useAppSelector((state) => state.ticketStatus);

  const [loading, setLoading] = useState("");
  const dispatch = useAppDispatch();

  // useEffect(() => {
  //   if (allTicketStatusesIds.length === 0) {
  //     dispatch(fetchAllStatuses());
  //   }
  // }, [allTicketStatusesIds]);

  const changeStatus = async (statusName: string) => {
    const ticketStatus = Object.values(allTicketStatuses).find(
      (status) => status.statusName === statusName
    );
    if (ticketStatus) {
      setLoading(ticketStatus.statusName);
      changeBulkTicketStatus({
        ticketIds: [ticketId],
        ticketStatusId: ticketStatus.id,
      })
        .then((res) => {
          onChange({
            id: ticketStatus.id,
            statusName: ticketStatus.statusName,
          });
        })
        .catch((err) => {
          pushTheToast({
            type: "danger",
            text: "Error changing status!",
            position: "top-right",
          });
        })
        .finally(() => {
          setLoading("");
        });
    }
  };

  const ticketStatusButtons = useMemo(() => {
    const ticketStatusBtnMapping: {
      [key: string]: {
        markAsText: { status: string; text: string; icon?: JSX.Element };
        button: { status: string; text: string; icon: JSX.Element };
      };
    } = {
      Open: {
        markAsText: {
          text: "Mark as pending",
          status: "Pending",
          icon: <i className="fa-regular fa-clock"></i>,
        },
        button: {
          text: "Close",
          status: "Closed",
          icon: <i className="fa-solid fa-check"></i>,
        },
      },
      Closed: {
        markAsText: {
          text: "Mark as pending",
          status: "Pending",
          icon: <i className="fa-regular fa-clock"></i>,
        },
        button: {
          text: "Re-open",
          status: "Open",
          icon: <i className="fa-solid fa-rotate-left"></i>,
        },
      },
      Pending: {
        markAsText: {
          text: "Mark as open",
          status: "Open",
          icon: <i className="fa-solid fa-rotate-left"></i>,
        },
        button: {
          text: "Close",
          status: "Closed",
          icon: <i className="fa-solid fa-check"></i>,
        },
      },
    };

    if (currentStatusName) {
      return ticketStatusBtnMapping[currentStatusName];
    } else {
      return ticketStatusBtnMapping.Open;
    }
  }, [currentStatusName]);
  return { loading, ticketStatusButtons, changeStatus };
}

export default useChangeTicketStatus;
