import { Modal } from "react-bootstrap";
import styles from "./DisconnectIntegration.module.scss";
import { deleteShopifyIntegration } from "src/services/Integrations/deleteShopifyIntegration";
import { IShopifyIntegrationItem } from "src/services/Integrations/getAllShopifyIntegrations";
import { useState } from "react";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import SettingToast from "src/routes/Setting/children/SettingToast/SettingToast";
import { getBrandingName } from "src/utils/utils";

interface Props {
  storeData: IShopifyIntegrationItem;
  isConnected: boolean;
  showConnecting: boolean;
  showModal: boolean;
  setIsConnected: Function;
  setShowConnecting: Function;
  setShowModal: Function;
}

const brandingName = getBrandingName();

const DisconnectIntegration = ({
  showModal,
  setShowModal,
  storeData,
  setIsConnected,
  setShowConnecting,
  isConnected,
  showConnecting,
}: Props) => {
  const onHide = () => {
    setShowModal(false);
  };

  const DisconnectIntegrationHandle = () => {
    setShowConnecting(true);
    deleteShopifyIntegration({
      integrationId: storeData.integrationId,
      is_disconnect: false,
    })
      .then((res) => {
        console.log("setIsConnected");
        setShowConnecting(false);

        pushTheToast({
          type: "success",
          text: SettingToast,
          customCompProps: {
            text: (//passing custom component instead of text to show the info with icon
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ margin: "-7px" }}
              >
                <span>
                  <i className={`fa-solid fa-circle-check me-1`}></i>
                </span>
                <span>Store disconnected</span>
              </div>
            ),
          },
          isTextComponent: true,
          position: "top-center",
        });

        setIsConnected(false);
        setShowModal(false);
      })
      .catch((err) => {
        // pushTheToast({
        //   type: "danger",
        //   text: err.message,
        //   position: "top-right",
        // });
      });
  };
  return (
    <Modal
      backdropClassName={`${styles.modalBack}`}
      show={showModal}
      onHide={onHide}
      dialogClassName={`${styles.modalDialog}`}
      contentClassName={`${styles.modalContent}`}
      centered={false}
      enforceFocus={false}
    >
      <div>
        <div className={`text-center`}>
          <h1 className={`${styles.header}`}>Disconnect Integration</h1>
        </div>
        <div className={`${styles.body} px-2`}>
          <h4 className={`${styles.bodyHead} mb-0`}>
            This will disconnect <span>{storeData.storeName} </span> from
            {` ${brandingName}.`}
          </h4>
          <div className={`text-center ${styles.btnDiv}`}>
            <button className={`${styles.keep}`} onClick={onHide}>
              No, keep it
            </button>
            <button
              className={`${styles.disconnect}`}
              onClick={DisconnectIntegrationHandle}
            >
              Yes, Disconnect
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default DisconnectIntegration;
