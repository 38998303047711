import { axiosJSON } from "src/globals/axiosEndPoints";

export type ASSIGN_MISSED_CHAT_RES_STATUS_CODE =
  | ""
  | "invalid_chat_request_id" // for this err => true
  | "already_assigned" // for this err => false
  | "invalid_user" // for this err => true
  | "chat_assigned" // for this err => false
  | "chat_not_assigned"; // for this err => false

export interface IAssignMissedChatParam {
  chatRequestId: string | number;
}

export interface IAssignMissedChatRes {
  statusCode: ASSIGN_MISSED_CHAT_RES_STATUS_CODE;
  msg: string;
}

export async function assignMissedChat(params: IAssignMissedChatParam) {
  const { data: res } = await axiosJSON.post(
    `/api/liveChat/agent/assignMissedChat`,
    params
  );

  if (res.err) {
    throw res.msg;
  }

  const ret: IAssignMissedChatRes = {
    statusCode: res.status_code,
    msg: res.msg,
  };

  return ret;
}
