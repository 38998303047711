import {
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { Modal, Form, Button, Dropdown } from "react-bootstrap";
import styles from "./EditShippingAddress.module.scss";
import "flag-icons/css/flag-icons.css";
import { ModalContext, ModalStyleClasses } from "../../../Modal/ModalContext";
import ReactSelect from "react-select";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  fetchAllCountryList,
  getShopifyCustomerDataThunk,
  updateOrderShippingAddressThunk,
} from "src/store/slices/singleOrder/order.thunks";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { IShopifyCustomerAddress } from "src/services/ShopifySidebar/Customer/getShopifyCustomerData";
import { resetShopifyCustomerData } from "src/store/slices/singleOrder/order.slice";

function EditShippingAddress(props: {
  onHide: () => void;
  /*isOpen?: boolean;
  handleModal?: Function;
  setClassNames?: (_classNames: ModalStyleClasses) => void;*/
}) {
  const dispatch = useAppDispatch();
  const { getOrderViewModel, allCountryList, shopifySingleCustomerData } =
    useAppSelector((state) => state.singleOrder);

  /*const modalContext = useContext(ModalContext);
  useEffect(() => {
    props?.setClassNames?.({ modalDialog: styles.shippingAddressModal });
    return () => {
      props?.setClassNames?.({});
    };
  }, []);*/

  var countries = [
    { key: "IN", name: "India", code: "+91" },
    { key: "AU", name: "Australia", code: "+15" },
  ];

  const [currentCountry, setCurrentCountry] = useState("");
  const [addressSelected, setAddressSelected] = useState(null as any);
  const [countrySelected, setCountrySelected] = useState(null as any);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [city, setCity] = useState("");
  const [provinceSelected, setProvinceSelected] = useState(null as any);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("");

  // list of customer available address
  const [availableCustomerAddress, setAvailableCustomerAddress] = useState<
    Array<IShopifyCustomerAddress>
  >([]);
  // react option list with complete address
  const [selectAddressOptions, setSelectAddressOptions] = useState<
    Array<string>
  >([]);

  // fetch customer address options
  const fetchCustomerAddressOptions = () => {
    if (
      shopifySingleCustomerData?.getShopifyCustomerDataAjaxStatus !==
        "pending" &&
      shopifySingleCustomerData?.getShopifyCustomerDataAjaxStatus !==
        "fulfilled" &&
      getOrderViewModel?.customerData?.id
    ) {
      dispatch(
        getShopifyCustomerDataThunk({
          customerId: getOrderViewModel?.customerData?.id,
        })
      );
    }
  };

  // when the shopify single customer data changes
  useEffect(() => {
    if (shopifySingleCustomerData?.customerData?.customerAddress) {
      // set select address list
      let customerAddresses: Array<string> = [];
      let address: string = "";
      shopifySingleCustomerData?.customerData.customerAddress.forEach(
        (addressItem) => {
          address = "";
          if (addressItem?.name && addressItem?.name != "") {
            address += addressItem.name;
          }
          if (addressItem?.address1 && addressItem?.address1 != "") {
            address += address !== "" ? ", " : "";
            address += addressItem.address1;
          }
          if (addressItem?.address2 && addressItem?.address2 != "") {
            address += address !== "" ? ", " : "";
            address += addressItem.address2;
          }
          if (addressItem?.city && addressItem?.city != "") {
            address += address !== "" ? ", " : "";
            address += addressItem.city;
          }
          if (addressItem?.city && addressItem?.city != "") {
            address += address !== "" ? ", " : "";
            address += addressItem.city;
          }
          if (addressItem?.province && addressItem?.province != "") {
            address += address !== "" ? ", " : "";
            address += addressItem.province;
          }
          if (addressItem?.zip && addressItem?.zip != "") {
            address += address !== "" ? ", " : "";
            address += addressItem.zip;
          }
          if (addressItem?.countryName && addressItem?.countryName != "") {
            address += address !== "" ? ", " : "";
            address += addressItem.countryName;
          }
          customerAddresses.push(address);
        }
      );
      // add the addresses list to react option list with complete address
      setSelectAddressOptions(customerAddresses);
      // set the available customer adderss data
      setAvailableCustomerAddress(
        shopifySingleCustomerData?.customerData.customerAddress
      );
    }
  }, [shopifySingleCustomerData]);

  // when an address is selected from dropdown
  const handleAddressSelectedChange = (selected: any) => {
    let addressDataObj: IShopifyCustomerAddress =
      availableCustomerAddress[selected.value];

    if (addressDataObj) {
      setFirstName(addressDataObj.firstName ?? "");
      setLastName(addressDataObj.lastName ?? "");
      setCompany(addressDataObj.company ?? "");
      setAddress1(addressDataObj.address1 ?? "");
      setAddress1(addressDataObj.address1 ?? "");
      setPostalCode(addressDataObj.zip ?? "");
      setCity(addressDataObj.city ?? "");
      setPhoneNumber(addressDataObj.phone ?? "");
      setCountrySelected(
        addressDataObj.country
          ? {
              label: addressDataObj.country,
              value: addressDataObj.countryCode,
            }
          : null
      );
      setProvinceSelected(
        addressDataObj.province
          ? {
              label: addressDataObj.province,
              value: addressDataObj.provinceCode,
            }
          : null
      );
    }
    setAddressSelected(selected);
  };

  // set the initial data in form
  useEffect(() => {
    if (getOrderViewModel?.shippingAddress) {
      setFirstName(getOrderViewModel?.shippingAddress?.firstName ?? "");
      setLastName(getOrderViewModel?.shippingAddress?.lastName ?? "");
      setCompany(getOrderViewModel?.shippingAddress?.company ?? "");
      setAddress1(getOrderViewModel?.shippingAddress?.address1 ?? "");
      setAddress1(getOrderViewModel?.shippingAddress?.address1 ?? "");
      setPostalCode(getOrderViewModel?.shippingAddress?.zip ?? "");
      setCity(getOrderViewModel?.shippingAddress?.city ?? "");
      setPhoneNumber(getOrderViewModel?.shippingAddress?.phone ?? "");
      setCountrySelected(
        getOrderViewModel?.shippingAddress?.country
          ? {
              label: getOrderViewModel?.shippingAddress?.country,
              value: getOrderViewModel?.shippingAddress?.countryCodeV2,
            }
          : null
      );
      setProvinceSelected(
        getOrderViewModel?.shippingAddress?.province
          ? {
              label: getOrderViewModel?.shippingAddress?.province,
              value: getOrderViewModel?.shippingAddress?.provinceCode,
            }
          : null
      );
    }
  }, [getOrderViewModel]);

  // fetch the country list
  const fetchAllCountryListOptions = () => {
    if (
      allCountryList?.fetchAllCountryListAjaxStatus !== "pending" &&
      allCountryList?.fetchAllCountryListAjaxStatus !== "fulfilled"
    ) {
      dispatch(fetchAllCountryList());
    }
  };

  // when the country is selected
  const handleCountrySelectedChange = (value: any) => {
    // reset the province
    setProvinceSelected(null);
    // set the country selected
    setCountrySelected(value);
  };

  const handleOnModalHide = () => {
    props.onHide();
  };

  const handleSaveEdit = (e: any) => {
    e.preventDefault();
    if (
      getOrderViewModel?.orderId &&
      countrySelected &&
      countrySelected.label &&
      firstName.trim().length > 0 &&
      address1.trim().length > 0 &&
      phoneNumber.trim().length > 0
    ) {
      let editShippingAddressParams: any = {
        firstName: firstName,
        lastName: lastName,
        company: company,
        address1: address1,
        address2: address2,
        city: city,
        country: countrySelected.label,
        countryCode: countrySelected.value,
        province: provinceSelected?.label ? provinceSelected?.label : null,
        provinceCode: provinceSelected?.provinceCode
          ? provinceSelected?.provinceCode
          : null,
        phone: phoneNumber,
        zip: postalCode,
      };
      dispatch(
        updateOrderShippingAddressThunk({
          orderId: getOrderViewModel?.orderId,
          shippingAddress: editShippingAddressParams,
        })
      );
      handleOnModalHide();
    } else {
      pushTheToast({
        type: "warning",
        text: "Please enter valid data!",
        position: "top-right",
      });
    }
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title className="fs-6">Edit Shipping Address</Modal.Title>
      </Modal.Header>

      <Modal.Body
        className={`d-flex flex-column justify-content-between ${styles.lead} ${styles.paymentDetail}`}
      >
        <section>
          <form>
            <div className="w-50 mt-1">
              <ReactSelect
                className={`mb-4 `}
                placeholder={"Select Address"}
                onMenuOpen={() => fetchCustomerAddressOptions()}
                isLoading={
                  shopifySingleCustomerData?.getShopifyCustomerDataAjaxStatus ===
                  "pending"
                }
                options={selectAddressOptions.map((address, index) => ({
                  label: address,
                  value: index,
                }))}
                value={addressSelected}
                onChange={(value) => {
                  handleAddressSelectedChange(value);
                }}
              />
            </div>
            <div className="row">
              <div className={`${styles.select_width} `}>
                <div className="mt-4  col-md-12">
                  <div className={` ${styles.wrap_select_arrow} `}>
                    <label
                      htmlFor="exampleSelect1"
                      className={`${styles.form_label} form-label`}
                    >
                      Country/Region
                    </label>
                    <div className={`${styles.select_width}`}>
                      <div className={`${styles.selectbox}`}>
                        <ReactSelect
                          className={`mb-4 `}
                          placeholder={"Select Country"}
                          onMenuOpen={() => fetchAllCountryListOptions()}
                          isLoading={
                            allCountryList?.fetchAllCountryListAjaxStatus ===
                            "pending"
                          }
                          options={allCountryList?.countryList?.map(
                            (country) => ({
                              label: country.countryName,
                              value: country.countryCode,
                            })
                          )}
                          value={countrySelected}
                          onChange={(value) => {
                            handleCountrySelectedChange(value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="row">
                    <div className="col-md-6 ">
                      <label
                        htmlFor="name"
                        className={`${styles.form_label}  ${styles.required} required form-label`}
                      >
                        First Name
                      </label>
                      <input
                        type="text"
                        className={`${styles.form_control} form-control border  `}
                        placeholder="First Name"
                        name="email"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        form-label
                        required
                      />
                    </div>
                    <div className="col-md-6 ">
                      <label
                        htmlFor="name"
                        className={`${styles.form_label}  ${styles.required} required form-label`}
                      >
                        Last Name
                      </label>
                      <input
                        type="text"
                        className={`${styles.form_control} form-control border`}
                        placeholder="Last Name"
                        name="pswd"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        form-label
                        required
                      />
                    </div>
                    <div className="mt-4 col-md-12">
                      <label
                        htmlFor="company"
                        className={`${styles.form_label} form-label`}
                      >
                        Company
                      </label>
                      <input
                        type="text"
                        className={`${styles.form_control} form-control border `}
                        name="company"
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                      />
                    </div>

                    <div className="mt-4 col-md-12">
                      <label
                        htmlFor="streetAndHouseNumber"
                        className={`${styles.form_label} form-label`}
                      >
                        Street and house number
                      </label>
                      <input
                        type="text"
                        className={`${styles.form_control} form-control border `}
                        name="streetAndHouseNumber"
                        value={address1}
                        onChange={(e) => setAddress1(e.target.value)}
                      />
                    </div>

                    <div className="mt-4 col-md-12">
                      <label
                        htmlFor="appartment_suite"
                        className={`${styles.form_label} form-label`}
                      >
                        Appartment, Suite, etc.
                      </label>
                      <input
                        type="text"
                        className={`${styles.form_control} form-control border `}
                        name="appartment_suite"
                        value={address2}
                        onChange={(e) => setAddress2(e.target.value)}
                      />
                    </div>
                    <div className="col-md-4 mt-4">
                      <label
                        htmlFor="name"
                        className={`${styles.form_label}  ${styles.required} required form-label`}
                      >
                        Postal Code
                      </label>
                      <input
                        type="text"
                        className={`${styles.form_control} form-control border  `}
                        name="email"
                        value={postalCode}
                        onChange={(e) => setPostalCode(e.target.value)}
                        form-label
                        required
                      />
                    </div>
                    <div className="col-md-4 mt-4">
                      <label
                        htmlFor="name"
                        className={`${styles.form_label}  ${styles.required} required form-label`}
                      >
                        City
                      </label>
                      <input
                        type="text"
                        className={`${styles.form_control} form-control border`}
                        name="pswd"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        form-label
                        required
                      />
                    </div>
                    <div className="col-md-4 mt-4">
                      <label
                        htmlFor="name"
                        className={`${styles.form_label}  ${styles.required} required form-label`}
                      >
                        Province
                      </label>
                      <div className={`${styles.selectbox}`}>
                        <ReactSelect
                          options={
                            countrySelected &&
                            allCountryList?.countryWithIdList[
                              countrySelected.value
                            ]
                              ? allCountryList?.countryWithIdList[
                                  countrySelected.value
                                ].provinces?.map((province) => ({
                                  label: province.provinceName,
                                  value: province.provinceCode,
                                }))
                              : undefined
                          }
                          value={provinceSelected}
                          onChange={(value) => {
                            setProvinceSelected(value);
                          }}
                        />{" "}
                      </div>
                    </div>
                    <div className="d-flex mt-4">
                      <div className="pe-2 w-100">
                        <label
                          htmlFor="phone"
                          className={`${styles.form_label} form-label`}
                        >
                          Phone
                        </label>
                        <input
                          type="tel"
                          className={`${styles.form_control} form-control border `}
                          id="Phone"
                          value={phoneNumber}
                          onChange={(e) => {
                            setPhoneNumber(e.target.value);
                          }}
                        />
                      </div>
                      <div className=" ps-2 flex-shrink-1 mt-4 ">
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="white"
                            id="dropdown-basic"
                            className="border"
                          >
                            {currentCountry === "" ? (
                              "Select Country"
                            ) : (
                              <span
                                className={`fi fi-${currentCountry.toLowerCase()}`}
                              ></span>
                            )}
                          </Dropdown.Toggle>

                          <Dropdown.Menu
                            onClick={(e) =>
                              setCurrentCountry((e.target as HTMLElement).id)
                            }
                          >
                            {countries.map((country) => {
                              return (
                                <Dropdown.Item
                                  id={country.key}
                                  key={country.key}
                                >
                                  {country.name} ({country.code})
                                </Dropdown.Item>
                              );
                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
      </Modal.Body>

      <Modal.Footer>
        <div
          className={`d-flex  justify-content-end  w-100 ${styles.paymentDetail} `}
        >
          <div className="d-flex ">
            <div className="me-2 ">
              <Button
                variant="outline-secondary"
                className="text-dark"
                onClick={() => props.onHide()}
              >
                Cancel
              </Button>
            </div>

            <div>
              <Button
                variant="outline-light border"
                className="text-white "
                style={{ backgroundColor: "#2b7554" }}
                onClick={(e) => handleSaveEdit(e)}
              >
                Save{" "}
              </Button>{" "}
            </div>
          </div>
        </div>
      </Modal.Footer>
    </>
  );
}
export default EditShippingAddress;
