import { useCallback, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import styles from "./AddTemplateButton.module.scss";
import CannedResponseBox from "../CannedResponseBox/CannedResponseBox";
import templateIcon from "src/assets/images/templateIcon.png";
import templateActiveIcon from "src/assets/images/templateActiveIcon.png";

interface Props {
  selectedTemplate?: (template: string) => void;
}

function AddTemplateButton({
  selectedTemplate = (template: string) => {},
}: Props) {
  const [showPopover, setShowPopover] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  /**
   * Handler for selecting template
   */
  const handleSelectedTemplate = useCallback(
    (value: string) => {
      selectedTemplate(value);
      setTimeout(() => {
        setShowPopover(false);
      }, 0);
    },
    [selectedTemplate],
  );
  return (
    <>
      <div
        className={`my-auto d-flex`}
        style={{ position: "relative" }}
      >
        <OverlayTrigger
          trigger="click"
          placement="top-end"
          // Closes the overlays on clicking outside the overlay
          rootClose={true}
          overlay={
            <Popover
              id="popover-basic"
              bsPrefix={`popover ${styles.popWrapper}`}
            >
              <Popover.Body className="p-0 h-100">
                <CannedResponseBox selectedTemplate={handleSelectedTemplate} />
              </Popover.Body>
            </Popover>
          }
          show={showPopover}
          onToggle={setShowPopover}
        >
          <div
            className={`my-auto d-flex ${styles.button} ${
              showPopover === true ? styles.open : ""
            }`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {/* <i className={`my-auto material-icons`}>&#xe06f;</i> */}
            <span className={`my-auto mx-2`}>
              +{" "}
              {/* <span>
                <i className="fa-solid fa-box"></i>
              </span>{" "} */}
              <img
                src={`${
                  isHovered || showPopover ? templateActiveIcon : templateIcon
                }`}
                alt=""
                width={10}
                height={10}
                className="mx-1"
              />
              Template
            </span>
          </div>
        </OverlayTrigger>
      </div>
    </>
  );
}

export default AddTemplateButton;
