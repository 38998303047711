import { axiosJSON } from "src/globals/axiosEndPoints";
import APIResponse from "src/services/Settings/Teams/APIReponse";

export interface IAgentAvailableAtData {
  availableDay : string;
  availableAtFrom : string;
  availableAtTo : string;
  timezone: string;
}
export interface IUpdateAvailabilityDataParams {
  agentAvailableAt? : Array<IAgentAvailableAtData>;
  canCustomerStartChatAlways?: boolean;
  canCustomerViewChatHistory?: boolean;
}

export interface IUpdateKnowledgeBasedParams{
  enabled?: boolean;
  chatWidgetTitle?: string;
  chatWidgetPlaceholder?: string;
  featuredArticlesMinimizedView?: boolean;
}


export interface IUpdateChatSettingParams{
  chatAssignment?: "auto" | "manual";
  transcriptForwarding?: string;
  fileSharingForVisitors?: boolean;
  fileSharingForAgents?: boolean;
  whenNoMessageCloseChat?: {
    enabled: boolean;
    value: number|string;
  };
  whenNoMessageMakeChatInactive?: {
    enabled: boolean;
    value: number|string;
  };
  whenAgentNotResponding?: {
    enabled: boolean;
    value: number|string;
  };
}

export interface IUpdateCustomizationParams{
    appearances?: {
        appearanceWindowColor: {
            color : string,
            value : string
        },
        liveChatThemeColor: {
            color : string,
            value : string,
        },
        widgetPosition: "left" | "right"
    },
    languageId? : number|string;
    topbarHeading? : string;
    topbarSubHeading? : string;
    statusTextWhenTeamOnline? : string;
    statusTextWhenTeamOffline? : string;
    autoreplayText? : string;
}

export interface UpdateChatSettingDataParams{
  integrationId:number|string;
  customization?: IUpdateCustomizationParams;
  availability?:IUpdateAvailabilityDataParams;
  knowledgeBased?:IUpdateKnowledgeBasedParams;
  chatSetting?:IUpdateChatSettingParams;
}



interface updateChatSettingResponse { 
  status: boolean;
}


export async function updateChatSetting(params: UpdateChatSettingDataParams) {
  
  const { data: res } = await axiosJSON.post<APIResponse<updateChatSettingResponse>>(
    "/api/chatSetting/updateChatSetting",
    params
  );

  if (res.error === true) {
    throw new Error(res.message as string);
  }

  return res.data as updateChatSettingResponse;
}
