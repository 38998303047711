/**
 * This file is the service file used for making api request.
 * It contains the deleteNotificationService function service which is explained below.
 *
 * @author Yash Aditya
 */

import { axiosJSON } from "src/globals/axiosEndPoints";
import { CurrentStatusEndPoints } from "./reportTicketList.service";

/**
 * This service is created to delete a notification using its id.
 */
const deleteNotificationService = async (param: {
  context: CurrentStatusEndPoints;
  id: string;
}) => {
  const { data: res } = await axiosJSON.post(
    `/api/reports/deleteNotification`,
    param
  );

  if (res.err || res.error) {
    throw res.msg ?? res.message ?? "Something went wrong!";
  }

  return true;
};

export default deleteNotificationService;
