import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Overlay, Spinner,OverlayTrigger, Tooltip} from "react-bootstrap";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { changeBulkTicketStatus } from "src/services/TicketService/changeBulkTicketStatus";
import {
  getAllStatuses,
  TicketStatus
} from "src/services/TicketService/getAllStatuses";
import styles from "./TicketCurrentStatus.module.scss";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { fetchAllStatuses } from "src/store/slices/ticketStatus/ticketStatus.slice";

interface Props {
  className?: string;
  currentStatus: TicketStatus;
  onChange: (status: TicketStatus) => void;
  ticketId: string;
  DDClassName?: string;
  canChange: boolean;
}

function TicketCurrentStatus({
  className = "",
  currentStatus,
  onChange,
  ticketId,
  DDClassName = "",
  canChange
}: Props) {
  // const [allStatusIds, setAllStatusIds]: any = useState([]);
  // const [allStatus, setAllStatus]: any = useState({});
  const {allTicketStatusesIds: allStatusIds, allTicketStatuses: allStatus , fetchAllStatusesState} = useAppSelector((state)=>state.ticketStatus);
  const [show, setShow] = useState(false);
  const target: any = useRef(null);
  const overlayRef: any = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const currentState = useRef({
    allStatusIds,
    fetchAllStatusesState,
    show
  });

  const dispatch = useAppDispatch();

  useMemo(()=>{
    currentState.current.allStatusIds = allStatusIds;
    currentState.current.fetchAllStatusesState = fetchAllStatusesState;
    currentState.current.show = show;
  },[ show, allStatusIds, fetchAllStatusesState])

  useEffect(() => {
    if (show && currentState.current.allStatusIds.length === 0) {
      setIsLoading(true);
      // getAllStatuses()
      //   .then((res) => {
      //     setAllStatus(res.allTicketStatuses);
      //     setAllStatusIds(res.allTicketStatusesIds);
      //     setIsLoading(false);
      //   })
      //   .catch((err) => {
      //     setIsLoading(false);
      //   });
      if (currentState.current.fetchAllStatusesState !== "pending"){
        dispatch(fetchAllStatuses()).finally(()=>{
          setIsLoading(false);
        });
      }
    }
  }, [show]);

  useEffect(()=>{
    if (fetchAllStatusesState !== "pending"){
      setIsLoading(false);
    }
  },[fetchAllStatusesState]);

  const changeStatus = (id: number) => {
    if (currentStatus.id != id) {
      setShow(false);
      setIsLoading(true);
      changeBulkTicketStatus({ ticketIds: [ticketId], ticketStatusId: id })
        .then((res) => {
          onChange({ id, statusName: allStatus[id].statusName });
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          pushTheToast({
            type: "danger",
            text: "Error changing status!",
            position: "top-right"
          });
        });
    } else {
      setShow(false);
    }
  };

  const onClickWindow = useCallback((e: any) => {
    if (target.current && overlayRef.current) {
      if (
        !target.current.contains(e.target) &&
        !overlayRef.current.contains(e.target) &&
        target.current !== e.target &&
        overlayRef.current !== e.target
      ) {
        setShow(false);
      } 
    } else if (currentState.current.show && target.current && target.current !== e.target ) {
      setShow(false);
    }
  }, []);
  useEffect(() => {
    window.addEventListener("click", onClickWindow);
    return () => {
      window.removeEventListener("click", onClickWindow);
    };
  }, [onClickWindow]);

  return (
    <>
      <span
        className={`px-2 ${styles.ticketCurrentStatus} ${
          styles[currentStatus.statusName]
        } ${canChange ? "" : "cursor-disabled"} max-content-width ${className}`}
        ref={target}
        onClick={() => {
          if (canChange) {
            setShow(!show);
          }
        }}
      >
        <i
          className={`d-inline-block my-auto fa-solid ${
            currentStatus.statusName === "Closed"
              ? "fa-check"
              : "fa-circle " + styles.smallFa
          }`}
        ></i>
        <span className={`mx-1 d-inline-block`}></span>             
         <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip className={` ${styles.toolTipCustom}`}>
                  <span> Ticket Status : {currentStatus.statusName}</span>
                  </Tooltip>
                }
              >
                {({ ref, ...triggerHandler }) => (
                  <div {...triggerHandler} ref={ref}>
                     <span>{currentStatus.statusName}</span>
                  </div>
                )}
              </OverlayTrigger>
        <span className={`mx-1 d-inline-block`}></span>
        {isLoading ? (
          <Spinner size="sm" animation="grow" style={{ color: "inherit" }} />
        ) : (
          <i className={`fa-solid m-auto fa-caret-${show ? "up" : "down"}`}></i>
        )}
      </span>
      {canChange && (
        <Overlay
          ref={overlayRef}
          target={target}
          onHide={() => {
            setShow(false);
          }}
          placement={`bottom-end`}
          show={show && !isLoading}
        >
          {({ placement, arrowProps, show: _show, popper, ...props }) => (
            <div
              {...props}
              className={`${styles.overlay} py-2 px-1 ${DDClassName}`}
            >
              <div className={`${styles.grey} mx-2 py-1 mb-2`}>
                Mark ticket status as
              </div>
              {allStatusIds.map((id: number | string, index: number) => {
                return (
                  <div
                    key={index}
                    className={`${styles.statusItem} ${
                      styles[allStatus[id].statusName]
                    } px-2 py-1 my-1`}
                    onClick={() => {
                      changeStatus(parseInt(id + ""));
                    }}
                  >
                    {allStatus[id].statusName}
                  </div>
                );
              })}
            </div>
          )}
        </Overlay>
      )}
    </>
  );
}

export default TicketCurrentStatus;
