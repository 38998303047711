import { Channel } from "pusher-js";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { RouteNames } from "src/containers/SideBar/SideBar";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { getNewTicketEvent } from "src/pusherServices/ticketEvents/newTicketEvent";
import { getSnoozeUpdatedEvent } from "src/pusherServices/ticketEvents/snoozeUpdatedEvent";
import {
  BulkMessageEventResponse,
  getTicketBulkMessageEvent,
} from "src/pusherServices/ticketEvents/ticketBulkMessageEvent ";
import { getNewTicketMessageEvent } from "src/pusherServices/ticketEvents/ticketMessageEvent";
import { getTicketUpdatedEvent } from "src/pusherServices/ticketEvents/ticketUpdatedEvent";
import { ISnoozeData } from "src/services/Snooze/getSnooze";
import {
  fetchAllTicketsIdList,
  fetchInnerTicketUpdates,
  fetchMessagesUpdates,
  fetchSnooze,
  filterInnerTicketsByActiveFilter,
  removeTicketFromSidebar,
  resetAllTicketsNav,
  resetSnoozeData,
  setActiveCustomerName,
  setRefetchCernThread,
  setSnoozeData,
  updateCustomerFeedback,
  updateMessageInfo,
  updateSideBarTicketsCustomerName,
} from "src/store/slices/innerTicket/innerTicket.slice";
import { fetchCustomerData } from "src/store/slices/shopifySidebar/shopifySidebar.slice";
import {
  fetchTicketUpdates,
  resetCachedTickets,
  totalTicketsSelectedSeletector,
} from "src/store/slices/tickets/tickets.slice";
import { fetchMultipleSidebarData } from "src/store/slices/ticketSidebar/ticketSidebar.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { generateFromDate } from "src/utils/dateLibrary";
import { convertHTMLToText, pushNotification } from "src/utils/utils";
import useSearchParamsStorage from "../useSearchParamsStorage";
import {
  UserUpdatedEventData,
  getUserUpdatedEvent,
} from "src/pusherServices/userEvents/userUpdatedEvent";
import {
  getBrandAndSignatureThunk,
  setCurrentUserData,
} from "src/store/slices/globals/globals.slice";
import { getCurrentUser } from "src/services/Settings/Users/getCurrentUser";
import { logoutUser } from "../auth/useLogin";
import {
  MessageStatusUpdatedRes,
  bindTicketMessageStatusUpdatedEvent,
} from "src/pusherServices/ticketEvents/ticketMessageStatusUpdatedEvent";
import {
  IBotEmailUpdatedResponse,
  getEmailBotFeedbackUpdatedEvent,
} from "src/pusherServices/ticketEvents/emailBotFeedbackUpdatedEvent";
import { getIntegrationUpdated } from "src/pusherServices/ticketEvents/integrationUpdatedEvent";

let ticketFilterToggleState = false;

export const setTicketFilterToggleStateForPusherEvent = (state: boolean) => {
  ticketFilterToggleState = state;
};

export const useTicketEventBinder = () => {
  const [privatePusherChannel, setPrivatePusherChannel] = useState(null as any);
  const [globalPusherChannel, setGlobalPusherChannel] = useState(null as any);
  const [searchParams, setSearchParams] = useSearchParamsStorage();
  const navigate = useNavigate();

  const searchText: any = useMemo(
    () => searchParams.get("searchText") ?? "",
    [searchParams.get("searchText")],
  );

  const canFetchTickets = useRef(true);

  const {
    allTickets,
    currentPage,
    cachedTickets,
    ticketsLoadingState,
    ticketListUpdateAjaxStatus,
    bulkActionFilters,
  } = useAppSelector((state) => state.ticket);

  const {
    activeTicketInfo,
    active,
    filters,
    messageList,
    ticketList,
    activeSnooze,
    sendCernMsgId,
  } = useAppSelector((state) => state.innerTicket);
  const totalTicketsSelected = useAppSelector(totalTicketsSelectedSeletector);

  const currentUserData = useAppSelector(
    (state) => state.globals.currentUserData,
  );

  const location = useLocation();

  const ticketRoute = `${RouteNames.tickets}`;

  const dispatch = useAppDispatch();
  const currentState = useRef({
    location: location.pathname,
    allTicketsIds: Object.keys(allTickets),
    currentUserData: currentUserData,
    activeTicketInfo: activeTicketInfo,
    innerTicketFilter: filters,
    globalSearch: searchText,
    activeTicketId: active.ticketId,
    innerTicketMessageList: messageList,
    cachedTickets: cachedTickets,
    innerTicketTicketList: ticketList,
    activeSnooze: activeSnooze,
    outerTicketUpdateAjaxStatus: ticketListUpdateAjaxStatus,
    outerTicketLoadingState: ticketsLoadingState,
    bulkActionSelected: false,
    activeCernThreadMessageId: sendCernMsgId,
  });

  useEffect(() => {
    //checking if any ticket is selected for bulk action and user scope for bulk edit
    const bulkActionSelected =
      (totalTicketsSelected !== 0 ||
        bulkActionFilters.selectedAllTickets === true) &&
      currentUserData &&
      currentUserData.scopes &&
      currentUserData.scopes.includes("bulk_edit");

    currentState.current = {
      location: location.pathname,
      allTicketsIds: Object.keys(allTickets),
      currentUserData: currentUserData,
      activeTicketInfo: activeTicketInfo,
      innerTicketFilter: filters,
      globalSearch: searchText,
      activeTicketId: active.ticketId,
      innerTicketMessageList: messageList,
      cachedTickets: cachedTickets,
      innerTicketTicketList: ticketList,
      activeSnooze: activeSnooze,
      outerTicketUpdateAjaxStatus: ticketListUpdateAjaxStatus,
      outerTicketLoadingState: ticketsLoadingState,
      bulkActionSelected: bulkActionSelected ? true : false,
      activeCernThreadMessageId: sendCernMsgId,
    };

    //checking below conditions whether we can fetch ticket update thunk
    //ticket will not be fetched if the below condition were true
    // 1) any ticket selected for bulk action
    // 2) (ticket list) currentPage > 1
    // 3) global ticket search text is not empty
    if (bulkActionSelected || currentPage > 1 || searchText?.trim()) {
      canFetchTickets.current = false;
    } else {
      canFetchTickets.current = true;
    }
  }, [
    location.pathname,
    Object.keys(allTickets),
    currentUserData,
    currentPage,
    totalTicketsSelected,
    searchText,
    activeTicketInfo,
    filters,
    messageList,
    cachedTickets,
    ticketList,
    activeSnooze,
    ticketListUpdateAjaxStatus,
    ticketsLoadingState,
    bulkActionFilters.selectedAllTickets,
    sendCernMsgId,
  ]);

  const fetchOuterTickets = useCallback(() => {
    if (
      currentState.current.outerTicketLoadingState !== "pending" &&
      currentState.current.outerTicketUpdateAjaxStatus !== "pending"
    ) {
      dispatch(resetCachedTickets());
      dispatch(fetchTicketUpdates());
      // dispatch(fetchMultipleSidebarData());//Feb 23 2024 commented sidebar refecting on pusher as it is causing lot of ajax request
    }
  }, []);

  const getAllTicketIds = (currentCachedTicket: any) => {
    let ticketIds: Array<number | string> = [];

    Object.values(currentCachedTicket).forEach((ids: any) => {
      ticketIds = [...ticketIds, ...ids];
    });

    return ticketIds;
  };

  //this function handles new ticket event and fetches ticket data
  const NewTicketEventCallback = (res: any) => {
    if (res?.ticketId) {
      const ticketId = parseInt(res.ticketId);

      if (
        currentState.current.location &&
        currentState.current.location.includes(ticketRoute)
      ) {
        //checking if current user is disabled for the current ticket brand
        if (res?.data?.disabledUserIds) {
          const disabledUserIds = res.data.disabledUserIds ?? [];

          if (
            disabledUserIds.includes(
              currentState.current.currentUserData?.userId,
            )
          ) {
            //current user doesn't have permission
            return false;
          }
        }
        //checking if user in inner ticket view
        if (currentState.current.innerTicketFilter.currentView.length !== 0) {
          //inner ticket view
          if (res?.data?.customer?.id) {
            const ticketStatusId = res.data?.ticketStatusId;
            if (
              //checking if active ticket customer id and event customer id equal or not
              currentState.current.activeTicketInfo?.customer_id ==
                res.data.customer.id &&
              //checking if global search text is empty
              currentState.current.globalSearch.trim().length === 0 &&
              //checking if event ticket status matches with inner ticket filter status
              (currentState.current.innerTicketFilter.ticketStatus == 0 ||
                currentState.current.innerTicketFilter.ticketStatus ==
                  ticketStatusId)
            ) {
              dispatch(fetchInnerTicketUpdates({ limit: 5 }));
              dispatch(fetchAllTicketsIdList());
            }
          }
        } else {
          //outer ticket view
          //checking if we can fetch tickets or not
          //checking if event ticket id is already exists in ticket view
          const allTicketsIds = getAllTicketIds(
            currentState.current.cachedTickets,
          );
          if (
            canFetchTickets.current &&
            ticketFilterToggleState === false &&
            currentState.current.currentUserData?.userId &&
            !allTicketsIds.includes(ticketId + "")
          ) {
            fetchOuterTickets();
          }
        }
      }
    }
  };

  //this function handles new ticket message event and updates the message list
  const TicketMessageEventCallback = (res: any) => {
    if (res?.messageId) {
      const messageId = parseInt(res.messageId);
      const messageUuid = res?.messageUuid;

      if (
        currentState.current.location &&
        currentState.current.location.includes(ticketRoute)
      ) {
        //checking if current user is disabled for the current message ticket brand
        if (res?.data?.disabledUserIds) {
          const disabledUserIds = res.data.disabledUserIds ?? [];

          if (
            disabledUserIds.includes(
              currentState.current.currentUserData?.userId,
            )
          ) {
            //current user doesn't have permission to this ticket message
            return false;
          }
        }

        //checking if user in inner ticket view
        if (currentState.current.innerTicketFilter.currentView.length !== 0) {
          //inner ticket view
          if (res?.data) {
            const ticketId = res.data?.ticketId;
            const customerId = res?.customerId;

            if (
              (res.data?.visibility === "cernThread" ||
                res.data?.visibility === "all") &&
              res.data?.parentMessageId ==
                currentState.current.activeCernThreadMessageId
            ) {
              dispatch(setRefetchCernThread({ refetch: true }));

              if (res?.data?.messageType !== "BotAiEscalatedNote") {
                return;
              }
            }

            if (
              //checking if global search text is empty
              currentState.current.globalSearch.trim().length === 0
            ) {
              //checking if message is already exist or not
              if (
                !currentState.current.innerTicketMessageList.includes(
                  messageId,
                ) ||
                (messageUuid &&
                  !currentState.current.innerTicketMessageList.includes(
                    messageUuid,
                  ))
              ) {
                //message not exists ...fetch the message

                let lastMessageId =
                  currentState.current.innerTicketMessageList[0];
                let splittedLastMessageId = (lastMessageId + "").split("::");
                if (splittedLastMessageId[0] === "chat") {
                  const messages =
                    currentState.current.innerTicketMessageList.filter(
                      (messageId) => !(messageId + "").includes("chat"),
                    );
                  lastMessageId = messages[0];
                }
                //checking if the received message and user active ticket id is equal
                if (currentState.current.activeTicketId == ticketId) {
                  dispatch(
                    fetchMessagesUpdates({
                      messageAfterId: parseInt(lastMessageId + ""),
                    }),
                  );

                  //checking if active ticket customer id and event message customer id equal ... if yes this message can be appended with linked conversion (other ticket messages)
                  //also checking if messageType is not event ...cause other ticket events are not showed in (other ticket messages)
                } else if (
                  currentState.current.activeTicketInfo.customer_id ==
                    customerId &&
                  //20 Dec 2022 // added condition check for customer events message...customer events are scoped with customerId instead of ticketId which need to be shown in inner ticket messages
                  (res.data?.messageType != "Event" ||
                    res.data?.isCustomerEvent == true)
                ) {
                  //fetching the message
                  dispatch(
                    fetchMessagesUpdates({
                      messageAfterId: parseInt(lastMessageId + ""),
                    }),
                  );
                }
              }
            }
          }
        } else {
          //outer ticket view
        }
      }
    }
  };

  //this function handles ticket updated event and fetches ticket data
  const TicketUpdatedEventCallback = (res: any) => {
    const ticketData: { [key: number | string]: any } = {};

    if (!res) {
      return false;
    }

    //looping through each ticket
    //filtering tickets that current user have access to
    res.tickets.map((ticket: any) => {
      //checking if current user is disabled for the current ticket brand
      if (res?.data?.disabledUserIds) {
        const disabledUserIds = res.data.disabledUserIds ?? [];

        if (
          disabledUserIds.includes(currentState.current.currentUserData?.userId)
        ) {
          return false;
        }
      }

      ticketData[ticket.ticketId] = ticket.ticketData;
    });

    //checking if user in ticket route
    if (
      currentState.current.location &&
      currentState.current.location.includes(ticketRoute)
    ) {
      //checking if user in inner ticket view
      if (currentState.current.innerTicketFilter.currentView.length !== 0) {
        //inner ticket view
        const ticketIds: Array<number> = [];
        const removableTickets: Array<number> = [];
        const addedTickets: Array<number> = [];
        let customerName = "";
        let ticketCustomerId: number | null = null;
        Object.keys(ticketData).forEach((ticketId: any) => {
          const ticket = ticketData[ticketId];

          const customerId = ticket.customer.id;
          if (
            //checking if active ticket customer id and event customer id equal or not
            currentState.current.activeTicketInfo?.customer_id == customerId
          ) {
            //checking if current ticket filter is All, if not then checking if ticket status matches current filter and ticket exists in current list
            if (
              (currentState.current.innerTicketFilter.ticketStatus === 0 ||
                currentState.current.innerTicketFilter.ticketStatus ==
                  ticket.ticketStatusId) &&
              currentState.current.innerTicketTicketList.includes(
                parseInt(ticketId + ""),
              )
            ) {
              //checking if ticket status matches current filter
              ticketIds.push(parseInt(ticketId + ""));
              customerName = ticket.customer.name;
              ticketCustomerId = customerId;
            }
            // checking if ticket status matches current filter and ticket exists not in current list
            else if (
              currentState.current.innerTicketFilter.ticketStatus ==
                ticket.ticketStatusId &&
              !currentState.current.innerTicketTicketList.includes(
                parseInt(ticketId + ""),
              )
            ) {
              addedTickets.push(parseInt(ticketId + ""));
            }
            // checking if ticket exists in current list and not matches ticket status with current filter, then this ticket must be removed from sidebar
            else if (
              currentState.current.innerTicketTicketList.includes(
                parseInt(ticketId + ""),
              ) &&
              currentState.current.innerTicketFilter.ticketStatus !== 0 &&
              currentState.current.innerTicketFilter.ticketStatus !=
                ticket.ticketStatusId
            ) {
              removableTickets.push(parseInt(ticketId + ""));
            }
          }
        });

        //checking if tickets is added/removed on the current filter, if yes, then checking the current length of existing tickets
        //if the existing tickets count length is less than infinite scroll min scrollable length then fetching the first 50 tickets again, otherwise
        //let the inifinite scroll fetch the latest values on scroll
        let refetchTicketView = false;
        //checking newly added ticket length and comparing the infinite scroll min length
        if (
          addedTickets.length &&
          currentState.current.innerTicketTicketList.length -
            removableTickets.length <
            25
        ) {
          refetchTicketView = true;
        }
        //checking newly added ticket length and comparing the infinite scroll min length
        if (
          removableTickets.length &&
          currentState.current.innerTicketTicketList.length -
            removableTickets.length <
            25
        ) {
          refetchTicketView = true;
        }

        //checking if any ticket status changed from current filter, if yes then remove that ticket from sidebar
        if (removableTickets.length) {
          dispatch(
            removeTicketFromSidebar({
              ticketIds: removableTickets,
            }),
          );
        }

        if (refetchTicketView) {
          dispatch(
            fetchInnerTicketUpdates({
              limit: 50,
            }),
          );
        }

        //to fetch the specific tickets updates
        if (
          ticketIds.length !== 0 &&
          //checking if global search text is empty
          currentState.current.globalSearch.trim().length === 0
        ) {
          dispatch(
            fetchInnerTicketUpdates({
              ticketIds: ticketIds,
              limit: ticketIds.length,
            }),
          ).finally(() => {
            dispatch(filterInnerTicketsByActiveFilter());
          });
          //checking if customer name updated event is exists in ticket updated event response
          if (res?.ticketEvents?.includes("customer_name_updated")) {
            //customer name is updated so dispatching the fetchCustomerData thunk to update the customer name
            dispatch(fetchCustomerData());
            if (customerName.length && ticketCustomerId) {
              dispatch(setActiveCustomerName({ customerName: customerName }));
              dispatch(
                updateSideBarTicketsCustomerName({
                  customerId: ticketCustomerId,
                  name: customerName,
                }),
              );
            }
          }

          //looping through each ticketId and checking if it matches the active ticket id
          /* 14 Dec 22 // commenting the snooze updation functions here, snooze is handled in different event callback
          ticketIds.every((ticketId)=>{
            //checking if active ticket id and event ticket id equal or not
            if (currentState.current.activeTicketId == ticketId) {
            
              //checking if snooze updated event is exists in ticket updated event response
              if(res?.ticketEvents?.includes("snooze_created") || res?.ticketEvents?.includes("snooze_updated")){
                //only fetch if snooze is empty
                  if(currentState.current.activeSnooze.snoozeDataAjaxStatus !== "pending" && currentState.current.activeSnooze.snoozeData.id == 0){
                    dispatch(fetchSnooze({}));
                  }

                  return false;
              }else if(res?.ticketEvents?.includes("snooze_deleted")){
                  if(currentState.current.activeSnooze.snoozeDataAjaxStatus !== "pending"){
                    dispatch(resetSnoozeData({ajaxStatus: "fulfilled"}));
                  }
              }
            }

            return true;
          });
          */
        }
      } else {
        //outer ticket view
        const ticketIds: Array<string | number> = [];

        //21 Dec 2022 , commenting existing ticket id check ..because newly updated ticket can appear in the current outer ticket filter ..so if existing ticketId check implemented then it will not fetch the new tickets
        // Object.keys(ticketData).map((ticketId: any) => {
        //   const ticket = ticketData[ticketId];

        //   const allTicketsIds = getAllTicketIds(
        //     currentState.current.cachedTickets
        //   );
        //   // console.log(allTicketsIds);

        //   //checking if ticket Id in ticket list
        //   if (allTicketsIds.includes(ticketId + "")) {
        //     //storing the ticket id in array and after this map function will be fetching the tickets data
        //     ticketIds.push(ticketId);
        //   }
        // });
        if (
          // canFetchTickets.current
          currentState.current.bulkActionSelected === false
        ) {
          //checking if this event is ticket read event
          if (
            res?.ticketEvents?.includes("ticket_read") &&
            res?.additionalInfo?.readUserId !=
              currentState.current.currentUserData?.userId
          ) {
            //it is ticket_read event and ticket is not read by current user so ignore
          } else {
            fetchOuterTickets();
          }
        }
      }
    }
  };

  //this function handles snooze updated event and fetches snooze data
  const SnoozeUpdatedEventCallback = (res: any) => {
    //checking if user in ticket route
    if (
      currentState.current.location &&
      currentState.current.location.includes(ticketRoute)
    ) {
      //checking if user in inner ticket view
      if (currentState.current.innerTicketFilter.currentView.length !== 0) {
        //inner ticket view
        //checking the required data exists in response

        if (res.ticketId && res.snoozeData) {
          const ticketId = parseInt(res.ticketId + "");

          // const customerId = parseInt(res.customer.id+"");

          //checking if global search text is empty and active ticket and event ticket id is equal or not
          if (
            currentState.current.globalSearch.trim().length === 0 &&
            currentState.current.activeTicketInfo?.ticket_id == ticketId
          ) {
            //checking if snooze updated event is exists in ticket updated event response
            if (
              res?.eventNames?.includes("snooze_created") ||
              res?.eventNames?.includes("snooze_updated")
            ) {
              const snoozeData: ISnoozeData = {
                id: res.snoozeData.id,
                ticketId: res.snoozeData.ticketId,
                tillCustomerReplies: res.snoozeData.tillCustomerReplies,
                expireAt: res.snoozeData.expireAt,
                expireAtInString: res.snoozeData.expireAtInString,
                snoozedAt: res.snoozeData.snoozedAt,
                expireAtGmt: res.snoozeData.expireAtGmt, //getting the expireAtGmt from the event response, used for showing the user based timezone datetime
              };

              if (res.snoozeData?.snoozedAtGmt) {
                const date = new Date(res.snoozeData.snoozedAtGmt + "Z");
                const convertedDateTime = date.toLocaleString("en-US", {
                  timeZone: currentState.current.currentUserData?.userTimezone,
                });

                snoozeData["snoozedAt"] = generateFromDate(
                  0,
                  new Date(convertedDateTime),
                );
              }
              if (res.snoozeData?.expireAtGmt) {
                const date = new Date(res.snoozeData.expireAtGmt + "Z");
                const convertedDateTime = date.toLocaleString("en-US", {
                  timeZone: currentState.current.currentUserData?.userTimezone,
                  hour12: false,
                });
                snoozeData["expireAt"] = generateFromDate(
                  0,
                  new Date(convertedDateTime),
                );
              }
              dispatch(
                setSnoozeData({ ticketId: ticketId, snoozeData: snoozeData }),
              );
            } else if (res?.eventNames?.includes("snooze_deleted")) {
              if (
                currentState.current.activeSnooze.snoozeDataAjaxStatus !==
                "pending"
              ) {
                dispatch(resetSnoozeData({ ajaxStatus: "fulfilled" }));
              }
            }
          }
        }
      } else {
        //outer ticket view
      }
    }
  };

  //this function handles ticket_event_bulk_new_message event and updates the message list in inner ticket
  const TicketBulkMessageEventCallback = (res: BulkMessageEventResponse) => {
    //checking if expected data object exists in response
    if (res?.data && res?.data?.length) {
      //checking if user in outer/inner ticket route
      if (
        currentState.current.location &&
        !currentState.current.location.includes(ticketRoute)
      ) {
        return false;
      }

      //checking if user in inner ticket view
      if (currentState.current.innerTicketFilter.currentView.length !== 0) {
        //inner ticket view

        //iterating over each messages from event response
        res?.data?.forEach((message) => {
          const messageId = parseInt(message.msgId + "");
          //checking if current user is disabled for the current message ticket brand
          if (message?.disabledUserIds) {
            const disabledUserIds = message?.disabledUserIds ?? [];

            if (
              disabledUserIds.includes(
                currentState.current.currentUserData?.userId + "",
              )
            ) {
              //current user doesn't have permission to this ticket message
              return false;
            }
          }

          if (message.ticketId) {
            const ticketId = message.ticketId;
            const customerId = message.customerId;
            if (
              //checking if global search text is empty
              currentState.current.globalSearch.trim().length === 0
            ) {
              //checking if message is already exist or not
              if (
                !currentState.current.innerTicketMessageList.includes(messageId)
              ) {
                //message not exists ...fetch the message

                let lastMessageId =
                  currentState.current.innerTicketMessageList[0];
                let splittedLastMessageId = (lastMessageId + "").split("::");
                if (splittedLastMessageId[0] === "chat") {
                  const messages =
                    currentState.current.innerTicketMessageList.filter(
                      (messageId) => !(messageId + "").includes("chat"),
                    );

                  lastMessageId = messages[1];
                }
                //checking if the received message and user active ticket id is equal
                if (currentState.current.activeTicketId == ticketId) {
                  dispatch(
                    fetchMessagesUpdates({
                      messageAfterId: parseInt(lastMessageId + ""),
                    }),
                  );

                  //checking if active ticket customer id and event message customer id equal ... if yes this message can be appended with linked conversion (other ticket messages)
                  //also checking if messageType is not event ...cause other ticket events are not showed in (other ticket messages)
                } else if (
                  currentState.current.activeTicketInfo.customer_id ==
                    customerId &&
                  //check for customer events message...customer events are scoped with customerId instead of ticketId which need to be shown in inner ticket messages
                  (message.msgType != "Event" ||
                    message?.isCustomerEvent == true)
                ) {
                  //fetching the message
                  dispatch(
                    fetchMessagesUpdates({
                      messageAfterId: parseInt(lastMessageId + ""),
                    }),
                  );
                }
              }
            }
          }
        }); //end of forEach loop
      } else {
        //outer ticket view
      }
    }
  };

  const UserUpdatedEventCallback = useCallback((res: UserUpdatedEventData) => {
    if (
      res.userId &&
      parseInt(res.userId + "") === currentState.current.currentUserData?.userId
    ) {
      if (res.eventNames.includes("user_session_expired")) {
        pushTheToast({
          type: "danger",
          text: "Session has expired!",
          position: "top-right",
        });
        logoutUser(() => {}, false);
        return;
      }

      //update the current user data
      getCurrentUser().then((res) => {
        dispatch(setCurrentUserData(res));
      });
    }
  }, []);

  const MessageStatusUpdatedEventCallback = useCallback(
    (res: MessageStatusUpdatedRes) => {
      if (
        currentState.current.location &&
        !currentState.current.location.includes(ticketRoute)
      ) {
        return;
      }
      //checking if user in inner ticket view
      if (currentState.current.innerTicketFilter.currentView.length !== 0) {
        //inner ticket view
        //checking the required data exists in response

        if (res.ticketId && res.messageId) {
          const ticketId = parseInt(res.ticketId + "");
          const messageId = parseInt(res.messageId + "");
          const messageInfo = res.messageInfo;

          //checking if global search text is empty and active ticket and event ticket id is equal or not
          if (
            currentState.current.globalSearch.trim().length === 0 &&
            currentState.current.activeTicketInfo?.ticket_id == ticketId
          ) {
            dispatch(
              updateMessageInfo({
                id: messageId,
                messageInfo: messageInfo,
              }),
            );
          }
        }
      } else {
        //outer ticket view
      }
    },
    [[dispatch, currentState]],
  );

  const EmailBotFeedbackUpdatedEventCallback = useCallback(
    (res: IBotEmailUpdatedResponse) => {
      // Checking if user is in Inner Ticket View
      if (
        currentState.current.location &&
        !currentState.current.location.includes(ticketRoute) &&
        currentState.current.innerTicketFilter.currentView.length === 0
      ) {
        return;
      }

      // Check if Ticket and Message ID's are available
      if (res.ticketId && res.messageId) {
        const ticketId = parseInt(res.ticketId + "");
        const messageId = parseInt(res.messageId + "");
        const customerFeedback = res.customerFeedback;
        const botInfo = res.botInfo;

        // Check that we are not searching AND
        // The active Ticket's ID matches with the ticket to be updated
        if (
          currentState.current.globalSearch.trim().length === 0 &&
          parseInt(currentState.current.activeTicketInfo?.ticket_id) ===
            ticketId
        ) {
          // Update the Message
          dispatch(
            updateCustomerFeedback({
              id: messageId,
              botInfo,
              customerFeedback,
            }),
          );
          // Get the event message
          const lastMessageId = currentState.current.innerTicketMessageList[0];
          if (lastMessageId) {
            dispatch(
              fetchMessagesUpdates({
                messageAfterId: parseInt(lastMessageId + ""),
              }),
            );
          }
        }
      }
    },
    [],
  );

  /**
   * Callback for updating email forwarding updated event
   */
  const IntegrationUpdatedCallback = useCallback(() => {
    // Used for updating brand failure data
    dispatch(getBrandAndSignatureThunk());
  }, []);

  useEffect(() => {
    if (globalPusherChannel) {
      globalPusherChannel.bind("pusher:subscription_succeeded", () => {
        //binding eventsx
        getNewTicketEvent(globalPusherChannel, NewTicketEventCallback);

        getIntegrationUpdated(globalPusherChannel, IntegrationUpdatedCallback);

        getNewTicketMessageEvent(
          globalPusherChannel,
          TicketMessageEventCallback,
        );
        getTicketUpdatedEvent(globalPusherChannel, TicketUpdatedEventCallback);

        getSnoozeUpdatedEvent(globalPusherChannel, SnoozeUpdatedEventCallback);

        //binding ticket_event_bulk_new_message event in global channel and passing callback in second param for handling the event data
        getTicketBulkMessageEvent(
          globalPusherChannel,
          TicketBulkMessageEventCallback,
        );

        getUserUpdatedEvent(globalPusherChannel, UserUpdatedEventCallback);
        bindTicketMessageStatusUpdatedEvent(
          globalPusherChannel,
          MessageStatusUpdatedEventCallback,
        );

        getEmailBotFeedbackUpdatedEvent(
          globalPusherChannel,
          EmailBotFeedbackUpdatedEventCallback,
        );
      });
    }
  }, [globalPusherChannel]);

  //   useEffect(() => {
  //     if (privatePusherChannel) {
  //       //   console.log("bind events");
  //       privatePusherChannel.bind("pusher:subscription_succeeded", () => {
  //       });
  //     }
  //   }, [privatePusherChannel]);

  const bindAllTicketEvents = (
    channel: Channel | null | undefined,
    isGlobal = false,
  ) => {
    if (channel) {
      if (isGlobal) {
        setGlobalPusherChannel(channel);
      } else {
        setPrivatePusherChannel(channel);
      }
    }
  };

  return { bindAllTicketEvents };
};
