import { Channel } from "pusher-js";

/**
 * Callback function type for handling bulk message events.
 */
export type TicketBulkMessageEventCB = (res: BulkMessageEventResponse) => void;

/**
 * Interface for the data structure of a bulk message.
 */
interface BulkMessageData {
  msgId: number | string;
  ticketId: number | string;
  customerId: number | string;
  disabledUserIds: Array<number | string>;
  msgType: string;
  isCustomerEvent?: boolean;
}

/**
 * Interface for the response structure of a bulk message event.
 */
export interface BulkMessageEventResponse {
  data: Array<BulkMessageData>;
}

// Debounce timeout variable.
let timeBoundTimeout: any = null;

/**
 * Binds a callback function to the "ticket_event_bulk_new_message" event on the provided Pusher channel.
 * This function ensures that the callback is only triggered once within a 15-second time frame to avoid rapid updates.
 *
 * @param channel The Pusher channel to bind the event to.
 * @param callback A function to be called when the event is triggered, taking the bulk message data as its parameter.
 */
export const getTicketBulkMessageEvent = (
  channel: Channel,
  callback: TicketBulkMessageEventCB
) => {
  // Binding the event listener to the channel
  channel.bind(
    "ticket_event_bulk_new_message",
    ((res) => {
      clearInterval(timeBoundTimeout);
      timeBoundTimeout = setTimeout(() => {
        callback(res);
      }, 10000);
    }) as typeof callback,
    {
      pauseOnInActiveTab: true,
    }
  );
};
