import { axiosJSON } from "src/globals/axiosEndPoints";
import {
  AllVariableTexts,
  VariableTextData,
} from "src/features/ReturnAutoWorkFlow/ReturnAutoWorkFlow.types";

/**
 * Interface representing the parameters required to fetch text variables.
 */
export interface GetTextVariablesParams {
  /**
   * A key used to identify the set of editor variables.
   */
  editorVariablesKey: string;
  /**
   * The starting index for fetching variables, useful for pagination.
   */
  start: number;
  /**
   * The maximum number of variables to fetch.
   */
  limit: number;
}

/**
 * Fetches text variables from the server based on the provided parameters.
 *
 *
 * @param params - The parameters required to fetch text variables.
 *
 * @returns A promise that resolves to an object containing the fetched variable texts.
 *
 * @throws An error if the server response contains an error.
 */
async function getTextVariablesService(params: GetTextVariablesParams) {
  const { data: res } = await axiosJSON.post(
    "/api/setting/automation/refund/getTextVariables",
    params,
  );

  if (res.err || res.error) {
    throw new Error(res.msg ?? res.message ?? "Some Error Occurred.");
  }

  const ret: AllVariableTexts = {
    variableIds: [],
    variables: {},
    hasMore: true,
  };

  // Iterate through each variable text received from the server response
  res.data.forEach((value: VariableTextData) => {
    // Convert the id to a string (in case it isn't already) and ensure consistency
    value.id = value.id + "";
    // Add the variable id to the array of variableIds
    ret.variableIds.push(value.id);
    // Store the variable text data in the variables record, using the id as the key
    ret.variables[value.id] = value;
  });

  // Determine if there are more variables to be fetched
  // If the number of variables fetched is less than the limit, set hasMore to false
  if (ret.variableIds.length < params.limit) {
    ret.hasMore = false;
  }

  return ret;
}

export default getTextVariablesService;
