import { useMemo } from "react";
import DispatchConditions from "../Children/DispatchConditions/DispatchConditions";
import DispatchIssueDetails from "../Children/DispatchIssueDetails/DispatchIssueDetails";
import DispatchIssueResponses from "../Children/DispatchIssueResponses/DispatchIssueResponses";
import DispatchRuleName from "../Children/DispatchRuleName/DispatchRuleName";
import DispatchRuleSuccess from "../Children/DispatchRuleSuccess/DispatchRuleSuccess";
import DispatchTo from "../Children/DispatchTo/DispatchTo";
import IssueDispatchCategories from "../Children/IssueDispatchCategories/IssueDispatchCategories";
import { DispatchStepsType, EDispatchSteps } from "../DispatchStepsConfig";

const DispatchRuleActiveComponent = ({
  stepsList,
  isDisabled,
  onHide,
  index,
}: {
  stepsList: Array<DispatchStepsType>;
  isDisabled: boolean;
  onHide: () => void;
  index: number;
}) => {
  const key = useMemo(() => stepsList[index].key, [stepsList, index]);

  switch (key) {
    case EDispatchSteps.RuleName:
      return <DispatchRuleName />;
    case EDispatchSteps.Conditions:
      return <DispatchConditions />;
    case EDispatchSteps.IssueDetails:
      return <DispatchIssueDetails />;
    case EDispatchSteps.IssueResponse:
      return <DispatchIssueResponses />;
    case EDispatchSteps.IssueCategories:
      return <IssueDispatchCategories />;
    case EDispatchSteps.DispatchTo:
      return <DispatchTo isDisabled={isDisabled} />;
    case EDispatchSteps.Success:
      return <DispatchRuleSuccess onHide={onHide} />;
    default:
      return <></>;
  }
};

export default DispatchRuleActiveComponent;
