import { TINY_MCE_ATTACHEMENT_SUPPORTED_TYPES } from "src/interfaces/TinyMCEContainer/ITinyMCEContainer";
import styles from "./FileSizeWarning.module.scss";
const FileSizeWarning = ({
  sizeInMB,
  errorType,
  fileName,
  onHide,
  errorText, //prop to render error text dynamically
}: {
  sizeInMB: number;
  errorType: "extensionsupport" | "sizelimit" | "invalidFileSize";
  fileName: string;
  errorText?: string; //prop to render error text dynamically
  onHide: () => void;
}) => {
  return (
    <div className="text-center">
      <span className={`${styles.caution}`}>
        <i className="fa-solid fa-triangle-exclamation"></i>
      </span>
      {errorType === "extensionsupport" && (
        <>
          <h3 className={`mb-1 ${styles.warnHead}`}>
            File format not supported!
          </h3>
          <span className={`${styles.warnSpan}`}>
            Supported formats are{" "}
            {TINY_MCE_ATTACHEMENT_SUPPORTED_TYPES.map((ext, index) => {
              if (index === TINY_MCE_ATTACHEMENT_SUPPORTED_TYPES.length - 1) {
                return ` and ${ext}`;
              } else if (
                index ===
                TINY_MCE_ATTACHEMENT_SUPPORTED_TYPES.length - 1
              ) {
                return ext;
              } else {
                return `${ext}, `;
              }
            }).join("")}
            .
          </span>
        </>
      )}
      {errorType === "sizelimit" && (
        <>
          <h3 className={`mb-1 ${styles.warnHead}`}>
            The file size is too large
          </h3>
          <span className={`d-block ${styles.warnSpan}`}>
            {/* rendering the error text from prop if exists else show the default message */}
            {errorText && errorText.trim()
              ? errorText
              : `The file size can not be more than ${sizeInMB} MB`}
          </span>
        </>
      )}

      {errorType === "invalidFileSize" && (
        <>
          <h3 className={`mb-1 ${styles.warnHead}`}>Invalid File Size</h3>
          <span className={`d-block ${styles.warnSpan}`}>
            {/* rendering the error text from prop if exists else show the default message */}
            {errorText && errorText.trim()
              ? errorText
              : `This file is 0 bytes, so it will not be attached.`}
          </span>
        </>
      )}
      {fileName.trim() && (
        <div className="mt-2">
          <p className={`mb-0 ${styles.warningPara}`}>
            {fileName.trim()} can not be uploaded
          </p>
        </div>
      )}
      <button
        className={`${styles.okBtn} px-4 py-1 mt-3`}
        onClick={onHide}
      >
        OK
      </button>
    </div>
  );
};

export default FileSizeWarning;
