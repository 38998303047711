import { axiosJSON } from "src/globals/axiosEndPoints";
import { IGetStep9to15Params } from "../step9/getStep9";

export interface step7 {
  stepData: {
    selected_auto_type:
      | "fully_automate"
      | "partially_automate"
      | "no_automation";
  };
}

export async function fetchGetStep7(params: IGetStep9to15Params) {
  const { data } = await axiosJSON.get(
    "/api/setting/automation/refund/step/7",
    { params }
  );

  if (data.error === true) {
    throw new Error("Some Error Occurred while fetching data");
  }
  // const data = await getStep7();

  return data.data;
}

// let getStep7 = function (): Promise<step7> {
//   return new Promise(function (resolve, reject) {
//     resolve({
//       stepData: {
//         selected_auto_type: "no_automation",
//       },
//     } as step7);
//   });
// };
