import { useState } from "react";
import { ScheduleData } from "src/services/LiveChat/ScheduleFollowups/getAllSchedules";
interface Props{
  schedule: ScheduleData;
}
function useScheduleBox({schedule}: Props) {
  const [showModal, setShowModal] = useState(false);
  const [deleteModal, SetDeleteModal] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState(schedule.attachments ?? []);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const onShow = (e: any) => {
    e.stopPropagation();
    setShowModal(true);
  };
  const onHide = () => {
    setShowModal(false);
  };

  const onShowModal = (e: any) => {
    e.stopPropagation();
    SetDeleteModal(true);
  };
  const onHideMod = () => {
    SetDeleteModal(false);
  };

  return {
    showModal,
    onShow,
    onHide,
    onShowModal,
    onHideMod,
    deleteModal,
    selectedFiles,
    setSelectedFiles,
    isFileUploading,
    setIsFileUploading,
  };
}

export default useScheduleBox;
