import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import {
  GetAllLinkableBrandData,
  getAllLinkableBrands,
} from "src/services/LiveChat/Settings/getAllLinkableBrands";
import { sendWidgetScriptEmail } from "src/store/slices/liveChatSetting/websiteSettings/websiteSettings.slice";
import { isEmailsValid } from "src/utils/utils";

export interface LinkableBrandData {
  id: number | string;
  name: string;
  email: string;
  imageURL?: string | null;
  isPublicAttachmentUrl?: boolean;
}

export const useFetchLinkableBrands = () => {
  const [loading, setLoading] = useState(false);
  const [allBrands, setAllBrands] = useState<LinkableBrandData[]>([]);
  const [fetchBrandError, setFetchBrandError] = useState<null | string>(null);

  const fetchData = async (integrationId: number) => {
    setLoading(true);
    try {
      const data: GetAllLinkableBrandData = await getAllLinkableBrands({integrationId: parseInt(integrationId+"")});
      setAllBrands(Object.values(data.brands));

      setLoading(false);
      return Object.values(data.brands);
      // log.debug("data fetched:", data)
    } catch (error) {
      const errorMessage = (error as Error).message;
      setFetchBrandError(errorMessage);
      setLoading(false);
    }
    return [];
  };

  return { loading, allBrands, fetchBrandError, fetchData };
};
