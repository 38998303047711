import styles from "./NewRule.module.scss";
import backArrow from "src/assets/images/backArrow2.png";
import moreInfo from "src/assets/images/moreInfo.png";
import UrlBox from "./Children/UrlBox/UrlBox";
import { Dropdown, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import rule from "src/assets/images/rule.png";
import pencil from "src/assets/images/pencil.png";
import RouteCondition from "./Children/RouteCondition/RouteCondition";
import RuleNameModal from "./Children/RuleNameModal/RuleNameModal";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  fetchAllRoutingRulesData,
  getRoutingRuleDataFromIdSelector,
  resetRoutingRulesData,
} from "src/store/slices/liveChatSetting/RoutingRules/RoutingRulesSetting.slice";
import { useCallback, useEffect, useState } from "react";
import {
  deleteRoutingRule,
  DeleteRoutingRuleParams,
} from "src/services/LiveChat/Settings/routingRules/deleteRoutingRules";

import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { v4 as uuidV4 } from "uuid";
import {
  addRoutingRuleParams,
  addRoutingRules,
} from "src/services/LiveChat/Settings/routingRules/addRoutingRules";
import {
  updateRoutingRuleParams,
  updateRoutingRules,
} from "src/services/LiveChat/Settings/routingRules/updateRoutingRules";
import { resetTeamsData } from "src/store/slices/teamSettings/teamSettings.slice";

const NewRule = () => {
  const dispatch = useAppDispatch();
  const { subSettingName, integrationId }: any = useParams();
  const [showModal, setShowModal] = useState(false);

  const onHide = useCallback(() => {
    setShowModal(false);
  }, []);

  const pageIntegrationId = integrationId
    ? decodeURIComponent(integrationId)
    : "";
  const routingRuleId = subSettingName
    ? decodeURIComponent(subSettingName)
    : "";
  const routingRuleData = useAppSelector((state) =>
    getRoutingRuleDataFromIdSelector(state, routingRuleId)
  );
  const [ruleName, setRuleName] = useState<string>("");
  const [showSaveChanges, setShowSaveChanges] = useState(false);
  const [isNewRule, setIsNewRule] = useState<boolean>(routingRuleId === "new");
  const [urlConditions, setUrlConditions] = useState<any>([]);
  const [domainConditions, setDomainConditions] = useState<any>([]);
  const [locationConditions, setLocationConditions] = useState<any>([]);
  const [ruleAction, setRuleAction] = useState<string>("routeToGroup");
  const [teamSelectedOption, setTeamSelectedOption] = useState<any>({
    label: "",
    value: "",
  });
  const [currentConditionKeys, setCurrentConditionKeys] = useState<
    Array<string>
  >([]);

  const addNewItemFlag = (tempConditions: any) => {
    const conditions = tempConditions.map((condition: any) => {
      let temp = {
        ...condition,
      };
      temp["isNewlyAdded"] = false;
      return temp;
    });

    return conditions;
  };
  useEffect(() => {
    if (routingRuleData !== null) {
      // console.log(routingRuleData);
      setRuleName(routingRuleData.ruleName);
      if (
        routingRuleData.conditions.domain !== null &&
        routingRuleData.conditions.domain !== undefined
      ) {
        setDomainConditions(addNewItemFlag(routingRuleData.conditions.domain));
      }
      if (
        routingRuleData.conditions.url !== null &&
        routingRuleData.conditions.url !== undefined
      ) {
        setUrlConditions(addNewItemFlag(routingRuleData.conditions.url));
      }
      // console.log(urlConditions);
      if (
        routingRuleData.conditions.location !== null &&
        routingRuleData.conditions.location !== undefined
      ) {
        setLocationConditions(
          addNewItemFlag(routingRuleData.conditions.location)
        );
      }
      setRuleAction(routingRuleData.ruleAction);

      if (routingRuleData.ruleAction === "routeToGroup") {
        setTeamSelectedOption({
          label: routingRuleData.teamName,
          value: routingRuleData.teamId,
        });
      }
    }
  }, [routingRuleData]);

  useEffect(() => {
    let currentConditionKeys: Array<string> = [];

    if (urlConditions.length !== 0) {
      currentConditionKeys.push("url");
    }
    if (domainConditions.length !== 0) {
      currentConditionKeys.push("domain");
    }
    if (locationConditions.length !== 0) {
      currentConditionKeys.push("location");
    }

    setCurrentConditionKeys(currentConditionKeys);
  }, [urlConditions, domainConditions, locationConditions]);

  useEffect(() => {
    if (routingRuleData !== null) {
      if (ruleAction !== routingRuleData.ruleAction) {
        setShowSaveChanges(true);
      }
    }
  }, [ruleAction]);

  let navigate = useNavigate();

  const checkConditionValueEmpty = () => {
    let status = true;
    domainConditions.map((condition: any) => {
      if (
        condition.conditionValue === null ||
        condition.conditionValue === ""
      ) {
        status = false;
      }
    });
    urlConditions.map((condition: any) => {
      if (
        condition.conditionValue === null ||
        condition.conditionValue === ""
      ) {
        status = false;
      }
    });
    locationConditions.map((condition: any) => {
      if (
        condition.conditionValue === null ||
        condition.conditionValue === ""
      ) {
        status = false;
      }
    });
    return status;
  };

  const handleShowSaveModel = () => {
    if (
      domainConditions.length === 0 &&
      urlConditions.length === 0 &&
      locationConditions.length === 0
    ) {
      pushTheToast({
        type: "warning",
        text: "Atleast one condition Should given!",
        position: "top-right",
      });
      return;
    }

    if (!checkConditionValueEmpty()) {
      pushTheToast({
        type: "warning",
        text: "Please fill all the fields!",
        position: "top-right",
      });
      return;
    }

    if (isNewRule === true) {
      setShowModal(true);
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    if (routingRuleData !== null || isNewRule === true) {
      setShowSaveChanges(false);
      // console.log(ruleName);
      if (isNewRule) {
        let payload: addRoutingRuleParams = {
          ruleName: ruleName,
          ruleAction: ruleAction,
          conditions: {
            domain: domainConditions,
            url: urlConditions,
            location: locationConditions,
          },
          integrationId: pageIntegrationId,
        };

        if (ruleAction === "routeToGroup") {
          if (
            teamSelectedOption.value === "" ||
            teamSelectedOption.value === null ||
            teamSelectedOption.value === undefined
          ) {
            pushTheToast({
              type: "warning",
              text: "please select the team to route!",
              position: "top-right",
            });
          }
          payload["teamId"] = teamSelectedOption.value;
        }

        addRoutingRules(payload)
          .then((res) => {
            dispatch(resetRoutingRulesData());
            // dispatch(fetchAllRoutingRulesData(pageIntegrationId));
            navigate("/live-chat/settings/" + pageIntegrationId + "/routeRule");
            setTimeout(() => {
              pushTheToast({
                type: "success",
                text: "Rule successfully added!",
                position: "top-right",
              });
            }, 0);
          })
          .catch((err) => {
            pushTheToast({
              type: "danger",
              text: "Something went wrong!",
              position: "top-right",
            });
            setShowSaveChanges(true);
          });
      } else {
        let payload: updateRoutingRuleParams = {
          ruleId: routingRuleId,
          ruleName: ruleName,
          ruleAction: ruleAction,
          conditions: {
            domain: domainConditions,
            url: urlConditions,
            location: locationConditions,
          },
        };

        if (ruleAction === "routeToGroup") {
          if (
            teamSelectedOption.value === "" ||
            teamSelectedOption.value === null ||
            teamSelectedOption.value === undefined
          ) {
            pushTheToast({
              type: "warning",
              text: "please select the team to route!",
              position: "top-right",
            });
          }
          payload["teamId"] = teamSelectedOption.value;
        }
        // console.log(payload);

        updateRoutingRules(payload)
          .then((res) => {
            dispatch(resetRoutingRulesData());
            // dispatch(fetchAllRoutingRulesData(pageIntegrationId));
            navigate("/live-chat/settings/" + pageIntegrationId + "/routeRule");
            setTimeout(() => {
              pushTheToast({
                type: "success",
                text: "Rule successfully updated!",
                position: "top-right",
              });
            }, 0);
          })
          .catch((err) => {
            pushTheToast({
              type: "danger",
              text: "Something went wrong!",
              position: "top-right",
            });
            setShowSaveChanges(true);
          });
      }
    }
  };

  const handleRuleNameCancel = () => {
    if (routingRuleData !== null) {
      setRuleName(routingRuleData.ruleName);
    }
  };

  const handleConditionAddNew = (type: string) => {
    let newCondition: any = {
      conditionId: uuidV4(),
      conditionValue: "",
      conditionConstraint: "contains",
    };

    if (isNewRule === false) {
      newCondition["isNewlyAdded"] = true;
    }

    if (type === "url") {
      const stateUrlConditions = [...urlConditions, newCondition];
      setUrlConditions(stateUrlConditions);
    }

    if (type === "domain") {
      const stateDomainConditions = [...domainConditions, newCondition];
      setDomainConditions(stateDomainConditions);
    }

    if (type === "location") {
      const stateLocationConditions = [...locationConditions, newCondition];
      setLocationConditions(stateLocationConditions);
    }
    setShowSaveChanges(true);
  };

  const handleConditionDelete = (condition: any, type: string) => {
    if (condition === null) {
      return;
    }

    if (type === "url") {
      const stateUrlConditions = urlConditions.filter(
        (urlCondition: any) =>
          urlCondition.conditionId !== condition.conditionId
      );
      setUrlConditions(stateUrlConditions);
    }

    if (type === "domain") {
      const stateDomainConditions = domainConditions.filter(
        (domainCondition: any) =>
          domainCondition.conditionId !== condition.conditionId
      );
      setDomainConditions(stateDomainConditions);
    }

    if (type === "location") {
      const stateLocationConditions = locationConditions.filter(
        (locationCondition: any) =>
          locationCondition.conditionId !== condition.conditionId
      );
      setLocationConditions(stateLocationConditions);
    }
    setShowSaveChanges(true);
  };

  const handleConditionBoxDelete = (type: string) => {
    if (type === "url") {
      setUrlConditions([]);
    }

    if (type === "domain") {
      setDomainConditions([]);
    }

    if (type === "location") {
      setLocationConditions([]);
    }
    setShowSaveChanges(true);
  };

  const handleConditionOnChange = (
    tempCondition: any,
    valueType: string,
    value: string,
    type: string
  ) => {
    if (tempCondition === null) {
      return;
    }

    let condition = {
      ...tempCondition,
    };

    if (valueType === "text") {
      condition.conditionValue = value;
    } else {
      condition.conditionConstraint = value;
    }

    if (type === "url") {
      const stateUrlConditions = urlConditions.map((urlCondition: any) => {
        if (urlCondition.conditionId === condition.conditionId) {
          return condition;
        } else {
          return urlCondition;
        }
      });
      setUrlConditions(stateUrlConditions);
    }

    if (type === "domain") {
      const stateDomainConditions = domainConditions.map(
        (domainCondition: any) => {
          if (domainCondition.conditionId === condition.conditionId) {
            return condition;
          } else {
            return domainCondition;
          }
        }
      );
      setDomainConditions(stateDomainConditions);
    }

    if (type === "location") {
      const stateLocationConditions = locationConditions.map(
        (locationCondition: any) => {
          if (locationCondition.conditionId === condition.conditionId) {
            return condition;
          } else {
            return locationCondition;
          }
        }
      );
      setLocationConditions(stateLocationConditions);
    }
    setShowSaveChanges(true);
  };

  const handleAddNewConditionGroup = (type: string) => {
    if (routingRuleData !== null || isNewRule === true) {
      if (type === "url") {
        if (urlConditions.length === 0) {
          handleConditionAddNew(type);
        }
      }

      if (type === "domain") {
        if (domainConditions.length === 0) {
          handleConditionAddNew(type);
        }
      }

      if (type === "location") {
        if (locationConditions.length === 0) {
          handleConditionAddNew(type);
        }
      }
    }
    setShowSaveChanges(true);
  };

  const handleCancel = () => {
    if (routingRuleData !== null) {
      setRuleName(routingRuleData.ruleName);
      if (
        routingRuleData.conditions.domain !== null &&
        routingRuleData.conditions.domain !== undefined
      ) {
        setDomainConditions(addNewItemFlag(routingRuleData.conditions.domain));
      }
      if (
        routingRuleData.conditions.url !== null &&
        routingRuleData.conditions.url !== undefined
      ) {
        setUrlConditions(addNewItemFlag(routingRuleData.conditions.url));
      }
      if (
        routingRuleData.conditions.location !== null &&
        routingRuleData.conditions.location !== undefined
      ) {
        setLocationConditions(
          addNewItemFlag(routingRuleData.conditions.location)
        );
      }
      setRuleAction(routingRuleData.ruleAction);

      if (routingRuleData.ruleAction === "routeToGroup") {
        setTeamSelectedOption({
          label: routingRuleData.teamName,
          value: routingRuleData.teamId,
        });
      }
    }
    setShowSaveChanges(false);
  };

  const handleBackBtn = (e: any) => {
    dispatch(resetRoutingRulesData());
    dispatch(resetTeamsData());
    // dispatch(fetchAllTeams());
    navigate("/live-chat/settings/" + pageIntegrationId + "/routeRule");
  };
  return (
    <div>
      <div className={`container-fluid`}>
        <div className={`row`}>
          <div className={`col-12 col-md-6`}>
            <div className={`${styles.leftSection}`}>
              {" "}
              <div className={`${styles.setHeight}`}>
                <div>
                  <div className="d-flex align-items-center">
                    <div
                      className={`${styles.backBtn}`}
                      onClick={handleBackBtn}
                    >
                      <span>
                        <i className="fa-solid fa-arrow-left"></i>
                      </span>
                    </div>
                    <span className={`${styles.ruleHead}`}>
                      {isNewRule ? "New Rule" : ruleName}
                    </span>
                    {isNewRule ? null : (
                      <img
                        className={`${styles.rulePencil} ms-2`}
                        src={pencil}
                        role={`button`}
                        alt="edit"
                        onClick={(e) => setShowModal(true)}
                      />
                    )}
                    <img
                      className={`${styles.ruleLogo}`}
                      src={rule}
                      alt="rule"
                    />
                  </div>
                </div>
                <div className={`d-flex align-items-center mt-3 mb-3  `}>
                  <span className={`${styles.customerSpan}`}>
                    When a customer meets conditions below
                  </span>

                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip className={` ${styles.toolTipCustom}`}>
                        Add the URL address or customer's location, or both as a
                        trigger for performing a particular action.
                      </Tooltip>
                    }
                  >
                    {({ ref, ...triggerHandler }) => (
                      <div {...triggerHandler} ref={ref}>
                        <img
                          className={`cursor-pointer ${styles.moreInfo}`}
                          src={moreInfo}
                          alt={``}
                        />
                      </div>
                    )}
                  </OverlayTrigger>
                </div>
                {((routingRuleData !== null && routingRuleData !== undefined) ||
                  isNewRule === true) &&
                currentConditionKeys.length !== 0 ? (
                  currentConditionKeys.map((key, index) => {
                    let placeholderText = "Enter the URL";
                    let headingText = "Customer who visits URL adresses that:";
                    let ConditionState = urlConditions;

                    switch (key) {
                      case "url":
                        placeholderText = "Enter the URL";
                        headingText = "Customer who visits URL adresses that:";
                        ConditionState = urlConditions;
                        break;
                      case "domain":
                        placeholderText = "Enter the domain";
                        headingText = "Customer who visits domain that:";
                        ConditionState = domainConditions;
                        break;
                      case "location":
                        placeholderText = "Enter the location";
                        headingText = "Customer who visits location that:";
                        ConditionState = locationConditions;
                        break;
                    }

                    if (index === 0) {
                      return (
                        <UrlBox
                          key={key}
                          conditionType={key}
                          conditions={ConditionState}
                          heading={headingText}
                          placeholder={placeholderText}
                          handleConditionDelete={handleConditionDelete}
                          handleConditionAddNew={handleConditionAddNew}
                          handleConditionOnChange={handleConditionOnChange}
                          handleConditionBoxDelete={handleConditionBoxDelete}
                        />
                      );
                    } else {
                      return (
                        <>
                          <p className={`my-2 ${styles.andPara}`}>And</p>
                          <UrlBox
                            key={key}
                            conditionType={key}
                            conditions={ConditionState}
                            heading={headingText}
                            placeholder={placeholderText}
                            // placeholder={`Enter the domain`}
                            handleConditionDelete={handleConditionDelete}
                            handleConditionBoxDelete={handleConditionBoxDelete}
                            handleConditionAddNew={handleConditionAddNew}
                            handleConditionOnChange={handleConditionOnChange}
                          />
                        </>
                      );
                    }
                  })
                ) : (
                  <div className="mt-2">
                    {" "}
                    <p className={`${styles.requiredText}`}>
                      At least one condition is required
                    </p>
                  </div>
                )}
                <div className={`mt-2 d-flex align-items-center`}>
                  <Dropdown bsPrefix={`dropdown cursor-pointer`}>
                    <Dropdown.Toggle
                      as="div"
                      bsPrefix={`${styles.addBox} me-2 dropdown-toggle`}
                    >
                      <span>
                        {" "}
                        <i className="fa-solid fa-plus"></i>
                      </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu bsPrefix={`dropdown-menu ${styles.dropBox}`}>
                      <li>
                        <span
                          className={
                            urlConditions.length === 0
                              ? `dropdown-item ${styles.dropItem2}`
                              : `dropdown-item ${styles.dropItem}`
                          }
                          onClick={(e) => handleAddNewConditionGroup("url")}
                        >
                          <span className={`me-1`}>
                            <i className="fa-solid fa-earth-asia"></i>
                          </span>
                          Customer visits URL
                        </span>
                      </li>
                      <li>
                        <span
                          className={
                            domainConditions.length === 0
                              ? `dropdown-item ${styles.dropItem2}`
                              : `dropdown-item ${styles.dropItem}`
                          }
                          onClick={(e) => handleAddNewConditionGroup("domain")}
                        >
                          <span className={`me-1`}>
                            <i className="fa-solid fa-house"></i>
                          </span>
                          Customer visits domain
                        </span>
                      </li>
                    </Dropdown.Menu>
                  </Dropdown>
                  <span className={`${styles.addElement}`}>Add Condition</span>
                </div>
              </div>
            </div>{" "}
          </div>
          <div className={`col-12 col-md-6`}>
            <div className={`${styles.rightSection}`}>
              <RouteCondition
                teamSelectedOption={teamSelectedOption}
                setTeamSelectedOption={setTeamSelectedOption}
                setRuleAction={setRuleAction}
                ruleAction={ruleAction}
              />
            </div>
          </div>
        </div>
      </div>
      {/* Action div  */}
      {(isNewRule || showSaveChanges) && (
        <div
          className={`d-flex flex-column flex-md-row justify-content-start justify-content-md-end ${styles.actionBtn}`}
        >
          <button
            className={`me-md-2 mb-1 mb-md-0 bg-white ${styles.cancelBtn}`}
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button
            className={` py-2 ${styles.saveBtn}`}
            onClick={handleShowSaveModel}
          >
            Save Changes
          </button>
        </div>
      )}
      {/* Show this modal when click on the save changes button. */}
      <Modal
        backdropClassName={`${styles.modalBack}`}
        show={showModal}
        onHide={onHide}
        dialogClassName={`${styles.modalDialog}`}
        contentClassName={`${styles.modalContent}`}
        //   centered={true}
        enforceFocus={false}
      >
        <RuleNameModal
          setShowSaveChanges={setShowSaveChanges}
          isNewRule={isNewRule}
          setRuleName={setRuleName}
          ruleName={ruleName}
          handleRuleNameCancel={handleRuleNameCancel}
          handleSubmit={handleSubmit}
          onHide={onHide}
        />
      </Modal>
    </div>
  );
};
export default NewRule;
