import React, { useCallback, useEffect, useRef, useState } from "react";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { CancelFulfillmentParams } from "src/services/ShopifySidebar/SingleOrder/FulFillments/cancelFulfillment";
import { cancelFulfillmentThunk } from "src/store/slices/singleOrder/order.thunks";
import { useAppDispatch, useAppSelector } from "src/store/store";
import EditTrackingModal from "../../EditTrackingModal/EditTrackingModal";
import styles from "./OrderItemTopRightMenu.module.scss";

const OrderItemTopRightMenu = ({
  orderItemsInStatusGroup,
  orderId,
}: {
  orderItemsInStatusGroup: any;
  orderId: number | undefined;
}) => {
  const dispatch = useAppDispatch();
  const { cancelFulfillment } = useAppSelector((state) => state.singleOrder);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const threeDotRef: any = useRef(null);
  const [showEditTrackingModal, setShowEditTrackingModal] =
    useState<boolean>(false);

  const onClickOutside = useCallback((e: any) => {
    if (threeDotRef.current) {
      if (!threeDotRef.current.contains(e.target)) {
        setShowMenu(false);
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener("click", onClickOutside);
    return () => {
      window.removeEventListener("click", onClickOutside);
    };
  }, [onClickOutside]);

  // cancel the fulfillment
  const handleCancelFulfillment = () => {
    if (cancelFulfillment?.cancelFulfillmentAjaxStatus !== "pending") {
      if (orderId && orderItemsInStatusGroup?.status?.fulfillmentId) {
        let cancelFulfillmentParams: CancelFulfillmentParams = {
          orderId: orderId,
          fulfillmentId: orderItemsInStatusGroup?.status?.fulfillmentId,
        };
        dispatch(cancelFulfillmentThunk(cancelFulfillmentParams));
        setShowMenu(false);
      } else {
        pushTheToast({
          type: "danger",
          text: "Something went wrong!",
          position: "top-right",
        });
      }
    }
  };

  return (
    <>
      {/* <div ref={threeDotRef}>
        <div className={`${styles.threeDotMenu}`}>
          <span onClick={() => setShowMenu(!showMenu)}>
            <i className="fa-solid fa-ellipsis" role="button"></i>
          </span>
        </div>
        {showMenu === true ? (
          <>
            <div className={`${styles.menuMainDiv}`}>
              <p
                className={`${styles.menuItem}`}
                onClick={() => {
                   // we don't need this now
                  // setShowEditTrackingModal(true);
                  // setShowMenu(false);
                }}
              >
                <span className={`${styles.menuItemSpan}`}>Edit tracking</span>
              </p>
              <p
                className={`${styles.menuItem}`}
                onClick={() => {
                  setShowMenu(false);
                }}
              >
                <span className={`${styles.menuItemSpan}`}>
                  Print packing slip
                </span>
              </p>
              <p
                className={`${styles.menuItem}`}
                onClick={() => {
                   // we don't need this now
                  // handleCancelFulfillment();
                }}
              >
                <span
                  className={`${styles.menuItemSpan} ${styles.menuItemCancel}`}
                >
                  Cancel fulfillment
                </span>
              </p>
            </div>
          </>
        ) : (
          <></>
        )}
      </div> */}
      {showEditTrackingModal === true ? (
        <EditTrackingModal
          orderId={orderId}
          orderItemsInStatusGroup={orderItemsInStatusGroup}
          showEditTrackingModal={showEditTrackingModal}
          setShowEditTrackingModal={setShowEditTrackingModal}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default OrderItemTopRightMenu;
