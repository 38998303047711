/**
 * This file contains the custom hook for handling KB settings.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

/* eslint-disable react-hooks/exhaustive-deps */
import { useInfiniteQuery, useQueries } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useDebounce from "src/hooks/useDebounce";
import {
  getAllArticle,
  getAllArticlePayload,
} from "src/services/KnowledgeBase/Settings/Preview/getAllArticle.service";
import { getThemeDetails } from "src/services/KnowledgeBase/Settings/Preview/getThemeDetails.service";
import {
  GetAllCategoryPayload,
  getAllCategory,
} from "src/services/KnowledgeBase/Settings/Preview/getAllCategory.service";
import helpLogo from "src/assets/images/helpCenter.png";

type Props = {
  integrationIdentifier: string;
  categoryId?: string;
};

/**
 * This hook is used to handle the state and data in the PreviewSection component.
 */
function usePreviewSection({ integrationIdentifier, categoryId }: Props) {
  // Api call for theme data
  const [themeData] = useQueries({
    queries: [
      {
        queryKey: ["getThemeDetails", integrationIdentifier],
        queryFn: () =>
          getThemeDetails({
            integrationIdentifier,
          }),
        // Handle token expiration or protection issues if needed
        onError: (data: any) => {
          // if (data?.response.data.tokenExpire) {
          //   setToken("");
          //   navigate(`/helpcenterpage/${helpCenterPageUri}`, {
          //     state: { tokenExpired: true },
          //   });
          //   return;
          // }
          // if (isProtected) {
          //   navigate(`/helpcenterpage/${helpCenterPageUri}`);
          // }
        },
      },
    ],
  });

  const [isTokenExpired, setIsTokenExpired] = useState(false);

  useEffect(() => {
    // Check for errors in the themeData and handle accordingly
    const error = themeData.error as any;
    if (error) {
      // Handle token expiration or protection issues if needed
      if (error.response !== null) {
        // if (error.response?.data?.tokenExpire) {
        //   setToken("");
        //   setIsTokenExpired(true);
        // }
      }
    }
  }, [themeData]);

  // Extract help center page URI from the current URL
  const pathArray = window.location.pathname.split("/");
  const helpCenterPageUri = pathArray[2];

  // Navigation function from React Router
  const navigate = useNavigate();

  // State for search term in articles and categories
  const [searchTerm, setSearchTerm] = useState("");

  // Initial payload for getting articles
  const [payload, setPayload] = useState<getAllArticlePayload>({
    integrationIdentifier,
    limit: 5,
    start: 0,
    categoryId: categoryId ? categoryId : undefined,
  });

  // Initial payload for getting categories
  const [categoryPayload, setCategoryPayload] = useState<GetAllCategoryPayload>(
    {
      integrationIdentifier,
      limit: 10,
      start: 0,
    }
  );

  // Api call for getting all articles
  const {
    data,
    isLoading,
    hasNextPage,
    fetchNextPage,
    refetch,
    isRefetching,
    isError,
  } = useInfiniteQuery({
    queryKey: ["getAllArticles"],
    getNextPageParam: (lastPage: any, allPages) => {
      const data = allPages.flatMap((data: any) => {
        return data.data;
      }) as any[] | any;

      const articles = categoryId
        ? data.flatMap((data: any) => data?.articles)
        : data;
      if (
        categoryId
          ? articles?.length < lastPage.metaData?.totalCount
          : data.length < lastPage.metaData?.totalCount
      ) {
        return {
          ...payload,
          start: categoryId ? articles?.length ?? 0 : data.length,
        };
      }
      return undefined;
    },
    queryFn: ({ pageParam = payload }) => {
      return getAllArticle(pageParam);
    },
    onError: (data: any) => {
      // if (data.response.data.tokenExpire) {
      //   setToken("");
      //   navigate(`/helpcenterpage/${helpCenterPageUri}`,{
      //     state: { tokenExpired: true },
      //   });
      // }
      // if (isProtected) {
      //   navigate(`/helpcenterpage/${helpCenterPageUri}`);
      // }
    },
  });

  // Api call for getting all categories
  const {
    data: categoryData,
    isLoading: categoryIsLoading,
    hasNextPage: categoryHasNextPage,
    fetchNextPage: fetchNextCategoryPage,
    refetch: refetchCategory,
    isRefetching: isCategoryRefetching,
    isError: categoryIsError,
  } = useInfiniteQuery({
    queryKey: ["getAllCategory"],
    getNextPageParam: (lastPage: any, allPages) => {
      const data = allPages.flatMap((data: any) => data.data);
      if (data.length < lastPage.metaData?.totalCount) {
        return {
          ...categoryPayload,
          start: lastPage.metaData.currentCount,
        };
      }
      return undefined;
    },
    queryFn: ({ pageParam = categoryPayload }) => {
      return getAllCategory(pageParam);
    },
    onError: (data: any) => {
      // if (data.response.data.tokenExpire) {
      //   setToken("");
      //   navigate(`/helpcenterpage/${helpCenterPageUri}`, {
      //     state: { tokenExpired: true },
      //   });
      // }
      // if (isProtected) {
      //   navigate(`/helpcenterpage/${helpCenterPageUri}`);
      // }
    },
  });

  // For Searching - Update payload and categoryPayload when the search term changes
  useEffect(() => {
    setPayload({
      ...payload,
      searchTerm: searchTerm ? searchTerm : undefined,
    });
    setCategoryPayload({
      ...categoryPayload,
      searchTerm: searchTerm ? searchTerm : undefined,
    });
  }, [searchTerm]);

  // For Searching - Trigger refetching of articles and categories when the search term changes
  const onSearch = (value: string, callback: any) => {
    refetch();
    refetchCategory();
  };

  // Articles and Categories data
  const articles = data?.pages.flatMap((data: any) => data.data);

  const categories = categoryData?.pages.flatMap((data: any) => data.data);

  // For Searching - Debounce the search term
  const debouncedSearchTerm = useDebounce(searchTerm, 1000);

  // Extract search term from URL and update the state
  const [searchParams] = useSearchParams();
  const search = searchParams.get("search");
  const inputRef: any = useRef(null);
  useEffect(
    () =>
      onSearch(searchTerm, () => {
        if (inputRef.current) {
          inputRef.current.blur();
        }
      }),
    [debouncedSearchTerm]
  );

  useEffect(() => {
    setSearchTerm(search ?? "");
  }, [search]);

  // Pair articles for rendering in groups of two
  const articlePairs: any[] = [];
  if (articles) {
    for (let i = 0; i < articles.length; i += 2) {
      if (i + 1 >= articles.length) {
        articlePairs.push([articles[i], undefined]);
      } else {
        articlePairs.push([articles[i], articles[i + 1]]);
      }
    }
  }

  // Set favicon based on theme data or default logo
  const link = document.querySelector('link[rel="icon"]');

  useEffect(() => {
    if (themeData.data?.data.favicon && helpCenterPageUri !== "") {
      link?.setAttribute("href", themeData.data.data.favicon);
    } else {
      link?.setAttribute("href", helpLogo);
    }
  }, [themeData?.data]);

  // Return the relevant data and functions from the hook
  return {
    themeData: themeData.data,
    helpCenterPageUri,
    navigate,
    articles,
    articleIsLoading: isLoading,
    articleHasError: isError,
    hasNextPage,
    fetchNextPage,
    isRefetching,
    searchTerm,
    setSearchTerm,
    onSearch,
    categories,
    categoryIsLoading,
    categoryHasError: categoryIsError,
    categoryHasNextPage,
    fetchNextCategoryPage,
    isCategoryRefetching,
    themeDataIsLoading: themeData.isLoading,
    themeDataHasError: themeData.isError,
    isTokenExpired,
    debouncedSearchTerm,
  };
}

export default usePreviewSection;
