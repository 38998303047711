import { axiosJSON } from "src/globals/axiosEndPoints";

export interface DeleteOrder {
  data: {
    orderId: number;
  };
  error: boolean;
  message: string;
}

export interface DeleteOrderParams {
  orderId: number;
}

export async function deleteOrder(params: DeleteOrderParams) {
  const { data: res }: any = await axiosJSON.delete(
    `/api/shopify/order/delete?orderId=${params.orderId}`
  );
  if (res.error === true) {
    throw new Error(res.message as string);
  }

  return res as DeleteOrder;
}
