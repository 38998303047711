import { useNavigate } from "react-router-dom";
import styles from "./AliceWonderlandCategory.module.scss";
import categoryImg from "src/assets/images/categoryImg.png";
interface Props {
  category: any;
  helpCenterPageUri: string;
  font: string;
}

const AliceInWonderlandCategory = ({
  category,
  helpCenterPageUri,
  font,
}: Props) => {
  const navigate = useNavigate();
  return (
    <div
      className={`d-flex justify-content-between align-items-center p-3 mb-3 ${styles.categoryBox} w-75`}
      onClick={() => {
        navigate(
          `/helpcenterpage/${helpCenterPageUri}/category/${category.categoryId}`
        );
      }}
      role="button"
    >
      <div className="w-75 d-flex align-items-center">
        <img src={categoryImg} alt="" className={`${styles.categoryImg}`} />
        <div className="ps-2">
          <h5
            className={`mb-0 ${styles.heading}`}
            style={{
              fontFamily: font ?? "Roboto", // font family
            }}
          >
            {category.categoryName}
          </h5>
          {/* <span className={`${styles.detail}`}>
            New to helplama? Learn the Best practice.
          </span> */}
        </div>
      </div>
      <div className="d-flex justify-content-end w-25">
        <span
          className={`${styles.detail}`}
          style={{
            fontFamily: font ?? "Roboto", // font family
          }}
        >
          {category.articleCount} Articles
        </span>
      </div>
    </div>
  );
};

export default AliceInWonderlandCategory;
