import { IDraftFlow, initialState } from "./draftFlow.slice";

function fetchAlldraftMsgFulfilled(state: IDraftFlow, { payload }: any) {
  state = { ...initialState, ticketID: state.ticketID };
  const { msgData, draftMsgHistory, remarks, unsent } = payload;
  if (msgData.draftMsg == null) {
    state = {
      ...state,
      canAddNewDraftMsg: msgData.canAddNewDraftMsg,
      getDraftMsgStatus: "fulfilled",
      addEditMsgStatus: "idle",
    };
  } else {
    const { draftStatusCycle, ...draftMsg } = msgData.draftMsg;
    state = {
      ...state,
      draftMsg,
      draftMsgHistory,
      draftRemarks: remarks === null ? {} : remarks.draftRemarks,
      draftRemarkIds: remarks === null ? [] : remarks.draftRemarkIds,
      unsentDraftMsg: unsent.unsentDraftMsg,
      remarkTotalCount: remarks === null ? 0 : remarks.metaData.totalCount,
      unsentTotalCount: unsent.metaData.totalCount,
      canAddNewDraftMsg: msgData.canAddNewDraftMsg,
      getDraftMsgStatus: "fulfilled",
      addEditMsgStatus: "idle",
      draftStatusCycle,
    };
  }
  return state;
}

function addEditDraftMsgDataFulfilled(state: IDraftFlow, { payload }: any) {
  state.addEditMsgStatus = "fulfilled";
}

function addEditRemarkDataFulfilled(state: IDraftFlow, { payload }: any) {
  state.addEditRemarkStatus = "fulfilled";
  state.draftRemarks[payload.remarkData.remarkId] = payload.remarkData;
  if (payload.editedRemarkId == undefined) {
    state.draftRemarkIds.unshift(payload.remarkData.remarkId);
    state.remarkTotalCount += 1;
  }
}

function fetchDraftMsgHistoryFulfilled(state: IDraftFlow, { payload }: any) {
  state.historyStatus = "fulfilled";
  state.draftMsgHistory = {
    ...state.draftMsgHistory,
    ...payload.draftMsgHistory,
  };
}

function fetchUnsentDraftFulfilled(state: IDraftFlow, { payload }: any) {
  state.unsentStatus = "fulfilled";
  state.unsentDraftMsg = [...state.unsentDraftMsg, ...payload.unsentDraftMsg];
  state.unsentTotalCount = payload.metaData.totalCount;
}

function fetchRemarkDataFulfilled(state: IDraftFlow, { payload }: any) {
  state.remarkStatus = "fulfilled";
  state.draftRemarks = { ...state.draftRemarks, ...payload.draftRemarks };
  state.draftRemarkIds = [...state.draftRemarkIds, ...payload.draftRemarkIds];
  state.remarkTotalCount = payload.metaData.totalCount;
}

function deleteDraftRemarkDataFulfilled(state: IDraftFlow, { payload }: any) {
  state.addEditRemarkStatus = "fulfilled";
  if (state.draftMsg) {
    delete state.draftRemarks[payload.deletedRemarkId];
    state.draftRemarkIds = state.draftRemarkIds.filter(
      (data) => data != payload.deletedRemarkId
    );
    state.remarkTotalCount -= 1;
  }
  return state;
}

export default {
  fetchAlldraftMsgFulfilled,
  addEditDraftMsgDataFulfilled,
  addEditRemarkDataFulfilled,
  deleteDraftRemarkDataFulfilled,
  fetchDraftMsgHistoryFulfilled,
  fetchUnsentDraftFulfilled,
  fetchRemarkDataFulfilled,
};
