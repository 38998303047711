import React from "react";
import styles from "./ConfirmCancelModal.module.scss";
import { Modal } from "react-bootstrap";

const ConfirmCancelModal = ({
  showModal,
  setShowModal,
  handleConfirmExit,
}: any) => {
  const handleCancelExit = () => {
    setShowModal(false);
  };

  const handleOnHideModal = () => {
    setShowModal(false);
  };

  return (
    <Modal
      backdropClassName={`${styles.modalBack}`}
      show={showModal}
      onHide={handleOnHideModal}
      dialogClassName={`${styles.modalDialog}`}
      contentClassName={`${styles.modalContent}`}
      centered={true}
      enforceFocus={false}
    >
      <div className={`${styles.mainDiv}`}>
        <p className={`${styles.mainHeadPara}`}>
          Do you want to proceed without saving changes ?
        </p>
        <p className={`${styles.subHeadPara}`}>
          This will discard all the changes that you have made
        </p>
        <div className={`${styles.btnDiv}`}>
          {" "}
          <button className={`${styles.cancelBtn}`} onClick={handleOnHideModal}>
            Cancel
          </button>{" "}
          <button
            className={`${styles.confirmBtn}`}
            onClick={() => {
              handleConfirmExit();
            }}
          >
            Confirm
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmCancelModal;
