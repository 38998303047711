import {
  createAction,
  createSlice,
  PayloadAction,
  SliceCaseReducers,
} from "@reduxjs/toolkit";
import { title } from "process";
import { v4 as uuidv4 } from "uuid";

export interface TImageProps {
  url: string;
  alternativeText: string;
}

export interface BaseButtonProps {
  action: number;
  text: string;
  value: string;
  momentSize: string;
  btnStyles: string;
  websiteUrl: string;
}

export interface UIButtonProps extends BaseButtonProps {
  id: string;
  buttonStyles: string;
  selectedAction: number;
  websiteUrl: string;
  order?: number;
}

export interface TargetedMessageSliceState2<
  BP extends BaseButtonProps = BaseButtonProps
> {
  name: string;
  integrationId: number | string;
  messageType: string;
  conditionType: string;
  messageProperties: {
    templateType: string;
    elements: {
      title: string;
      subtitle: string;
      image: TImageProps;
    };
    buttons: BP[];
  };
  active: boolean;
  rules: {
    id: string;
    ruleType: string;
    variableName: string | null;
    value: string | number;
    operator: string;
  }[];
}

const TargetedMessageIntialState = {
  name: "",
  integrationId: "",
  messageType: "",
  conditionType: "",
  messageProperties: {
    templateType: "",
    elements: {
      title: "",
      subtitle: "",
      image: {
        url: "",
        alternativeText: "",
      },
    },
    buttons: [],
  },
  active: false,
  rules: [
    {
      id: "1",
      ruleType: "messageVisibleTo",
      variableName: "test",
      value: "firstTimeVisitor",
      operator: "equals",
    },
    {
      id: "2",
      ruleType: "displayOnPageTime",
      variableName: "",
      value: 1,
      operator: "greaterOrEqual",
    },
    {
      id: "3",
      ruleType: "announmentShouldRun",
      variableName: "test",
      value: "allVistor",
      operator: "equals",
    },
    {
      id: uuidv4(),
      ruleType: "",
      variableName: "",
      value: "",
      operator: "",
    },
  ],
};

export const updateData = createAction(
  "targetedMessage/updateData",
  ({
    messageProperties,
    messageType,
    active,
    rules,
    conditionType,
  }: Partial<TargetedMessageSliceState2<UIButtonProps>>) => {
    return {
      payload: {
        messageType,
        messageProperties: {
          templateType: messageProperties?.templateType,
          elements: messageProperties?.elements,
          buttons: messageProperties?.buttons?.map((btn: UIButtonProps) => {
            return {
              action: btn.selectedAction,
              text: btn.text,
              value: " ",
              momentSize: "",
              btnStyles: btn.buttonStyles,
              websiteUrl: btn.websiteUrl,
            };
          }),
        },
        active: false,
        rules: rules?.map((rule) => {
          return {
            ruleType: rule.ruleType,
            variableName: rule.variableName,
            value: rule.value,
            operator: rule.operator,
          };
        }),
        conditionType,
      },
    };
  }
);

const targetedMessageSlice = createSlice<
  TargetedMessageSliceState2,
  SliceCaseReducers<TargetedMessageSliceState2>
>({
  name: "targetedMessageSlice",
  initialState: TargetedMessageIntialState,
  reducers: {
    UPDATE_SINGLE_VALUES: (state, action) => {
      if (action.payload.conditionType)
        state.conditionType = action.payload.conditionType;
    },
    ADD_RULES: (state, action) => {
      const { rules } = action.payload;

      if (rules) {
        rules.forEach((rule: any) => state.rules.push(rule));
      }
    },
    DELETE_RULE: (state, action) => {
      if (action.payload.rule) {
        state.rules = state.rules.filter(
          (rule) => rule.id !== action.payload.rule.id
        );
        console.log(state.rules);
      }
    },
    UPDATE_RULE: (state, action) => {
      const { ruleToUpdate } = action.payload;
      console.log({ action });

      if (ruleToUpdate) {
        const ruleIdx = state.rules.findIndex(
          (_rule) => _rule.id === ruleToUpdate.id
        );
        state.rules[ruleIdx] = { ...state.rules[ruleIdx], ...ruleToUpdate };
        console.log({ uRules: state.rules });
      }
    },
    RESET: (state, action) => {
      state.messageProperties.buttons = [];
      state.messageProperties.elements = {
        image: {
          url: "",
          alternativeText: "",
        },
        subtitle: "",
        title: "",
      };
    },
    UPDATE_NEW_TARGETED_DATA: (state, action) => {
      state.messageProperties = action.payload.messageProperties;
    },
  },
  extraReducers: {
    [updateData.type]: (
      state,
      action: PayloadAction<TargetedMessageSliceState2<UIButtonProps>>
    ) => {
      console.log({ action });
      state.messageType = action.payload.messageType;
      state.messageProperties.buttons =
        action.payload.messageProperties.buttons;
      state.messageProperties.elements =
        action.payload.messageProperties.elements;
      state.messageProperties.templateType =
        action.payload.messageProperties.templateType;
      state.rules = action.payload.rules;
      state.active = action.payload.active;
      state.conditionType = action.payload.conditionType;
    },
  },
});

// const targetedMessageSlice = createSlice<
//   TargetedMessageSliceState2,
//   {},
//   "targetedMessage"
// >({
//   name: "targetedMessage",
//   initialState: {
//     integrationId: "1",
//     conditionType: "",
//     messageType: "",
//     name: "",
//     active: false,
//     rules: [],
//     messageProperties: {
//       templateType: "",
//       elements: {
//         title: "",
//         subtitle: "",
//         image: {
//           url: "",
//           alternativeText: "",
//         },
//       },
//       buttons: [],
//     },
//   },
//   reducers: {
//     [selectImage.type]: (state: any, action: PayloadAction<TImageProps>) => {
//       console.log(action.payload);
//       state.messageProperties.elements.image.alternativeText =
//         action.payload.alternativeText;
//       state.messageProperties.elements.image.url = action.payload.url;
//     },
//   },
//   /* extraReducers(builder) {
//     // Cases for Create Targeted Message
//     builder.addCase(fetchCreateTargetedMessage.pending, (state) => {
//       state.loading = true;
//     });
//     builder.addCase(fetchCreateTargetedMessage.fulfilled, (state, action) => {
//       state.loading = false;
//       state.createTargetedMessage = action.payload;
//       state.error = "";
//     });
//     builder.addCase(fetchCreateTargetedMessage.rejected, (state, action) => {
//       state.loading = false;
//       state.createTargetedMessage = undefined;
//       state.error = action.error.message;
//     });

//     // Cases for Delete Targeted Message
//     builder.addCase(fetchDeleteTargetedMessage.pending, (state) => {
//       state.loading = true;
//     });
//     builder.addCase(fetchDeleteTargetedMessage.fulfilled, (state, action) => {
//       state.loading = false;
//       state.deleteTargetedMessage = action.payload;
//       state.error = "";
//     });
//     builder.addCase(fetchDeleteTargetedMessage.rejected, (state, action) => {
//       state.loading = false;
//       state.deleteTargetedMessage = undefined;
//       state.error = action.error.message;
//     });

//     // Cases for Duplicate Targeted Message
//     builder.addCase(fetchDuplicateTargetedMessage.pending, (state) => {
//       state.loading = true;
//     });
//     builder.addCase(
//       fetchDuplicateTargetedMessage.fulfilled,
//       (state, action) => {
//         state.loading = false;
//         state.dulicateTargetedMessage = action.payload;
//         state.error = "";
//       }
//     );
//     builder.addCase(fetchDuplicateTargetedMessage.rejected, (state, action) => {
//       state.loading = false;
//       state.dulicateTargetedMessage = undefined;
//       state.error = action.error.message;
//     });

//     //Cases for Get All Conditons > Targeted Message
//     builder.addCase(
//       fetchGetAllConditionOptionsTargeteMessage.pending,
//       (state) => {
//         state.loading = true;
//       }
//     );
//     builder.addCase(
//       fetchGetAllConditionOptionsTargeteMessage.fulfilled,
//       (state, action) => {
//         state.loading = false;
//         state.getAllConditionOptions = action.payload;
//         state.error = "";
//       }
//     );
//     builder.addCase(
//       fetchGetAllConditionOptionsTargeteMessage.rejected,
//       (state, action) => {
//         state.loading = false;
//         state.getAllConditionOptions = undefined;
//         state.error = action.error.message;
//       }
//     );

//     // Cases for Get All Targeted Message
//     builder.addCase(fetchGetAllTargetedMessage.pending, (state) => {
//       state.loading = true;
//     });
//     builder.addCase(fetchGetAllTargetedMessage.fulfilled, (state, action) => {
//       state.loading = false;
//       state.getAllTargetedMessage = action.payload;
//       state.error = "";
//     });
//     builder.addCase(fetchGetAllTargetedMessage.rejected, (state, action) => {
//       state.loading = false;
//       state.getAllTargetedMessage = undefined;
//       state.error = action.error.message;
//     });

//     // Cases for Get Targeted Message
//     builder.addCase(fetchGetTargetedMessage.pending, (state) => {
//       state.loading = true;
//     });
//     builder.addCase(fetchGetTargetedMessage.fulfilled, (state, action) => {
//       state.loading = false;
//       state.getTargetedMessage = action.payload;
//       state.error = "";
//     });
//     builder.addCase(fetchGetTargetedMessage.rejected, (state, action) => {
//       state.loading = false;
//       state.getTargetedMessage = undefined;
//       state.error = action.error.message;
//     });

//     // Cases for Update Targeted Message
//     builder.addCase(fetchUpdateTargetedMessage.pending, (state) => {
//       state.loading = true;
//     });
//     builder.addCase(fetchUpdateTargetedMessage.fulfilled, (state, action) => {
//       state.loading = false;
//       state.updateTargetedMessage = action.payload;
//       state.error = "";
//     });
//     builder.addCase(fetchUpdateTargetedMessage.rejected, (state, action) => {
//       state.loading = false;
//       state.updateTargetedMessage = undefined;
//       state.error = action.error.message;
//     });
//   }, */
// });

export const {
  RESET,
  UPDATE_SINGLE_VALUES,
  UPDATE_RULE,
  ADD_RULES,
  DELETE_RULE,
  UPDATE_NEW_TARGETED_DATA,
} = targetedMessageSlice.actions;
export default targetedMessageSlice.reducer;
