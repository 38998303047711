import { useMemo, useRef, useState } from "react";
import { AJAXSTATUS } from "src/globals/constants";
import { LiveChatCustomerAllMessage } from "src/services/LiveChat/liveChatHistory/getLiveChatCustomerAllMessages.service";
import useChatHistoryISLoader from "../../ChatHistoryISLoader/ChatHistoryISLoader";
import useErrorMessage from "../../ErrorMessage/errorMessage";
import styles from "../HistoryInnerView.module.scss";

interface HistoryInnerMsgProps {
  historyMessages: LiveChatCustomerAllMessage | null | undefined;
  fetchStatus: AJAXSTATUS;
  selectedHistoryId: null | string;
  historyMessagesIds: Array<string>;
  smallWindowChat: boolean;
}

const useHistoryInnerMsg = ({
  historyMessages,
  fetchStatus,
  selectedHistoryId,
  historyMessagesIds,
  smallWindowChat,
}: HistoryInnerMsgProps) => {
  const scrollToRef = useRef(null as any);
  const scrollTopFlag = useRef(false);
  const [scrollDependencyId, setScrollDependencyId] =
    useState(selectedHistoryId);
  const [scrollClass, setScrollClass] = useState("");
  const loader = useChatHistoryISLoader(
    fetchStatus === "pending",
    "Loading Messages...",
    styles.loading,
  );
  const errorMessage = useErrorMessage(
    fetchStatus === "rejected" ||
      (fetchStatus !== "pending" && historyMessagesIds.length === 0),
    styles.nothingFound,
  );

  useMemo(() => {
    if (selectedHistoryId) {
      scrollTopFlag.current = true;
      setScrollClass(styles.scrollSmooth);
    } else {
      scrollTopFlag.current = false;
      setScrollClass("");
    }
  }, [selectedHistoryId]);

  useMemo(() => {
    if (historyMessages?.messageIds.length) {
      scrollTopFlag.current = false;
      setScrollClass("");
    }
  }, [historyMessages?.messageIds.length]);

  useMemo(() => {
    setScrollDependencyId((prev) => {
      if (scrollTopFlag.current === true) {
        return selectedHistoryId;
      } else {
        if (prev === null) {
          return smallWindowChat
            ? historyMessages?.messageIds[
                historyMessages?.messageIds.length - 1
              ] ?? null
            : historyMessages?.messageIds[0] ?? null;
        } else {
          return (
            historyMessages?.messageIds[
              historyMessages.messageIds.length -
                historyMessages.metaData.currentCount -
                1
            ] ?? null
          );
        }
      }
    });
  }, [historyMessages, selectedHistoryId, smallWindowChat]);

  return {
    loader,
    errorMessage,
    scrollToRef,
    scrollClass,
    scrollTopFlag,
    scrollDependencyId,
    setScrollDependencyId,
  };
};

export default useHistoryInnerMsg;
