import { GetAllTeam, getAllTeam, GetAllTeamParams} from "src/services/Settings/Teams/getAllTeam";
import { GetTeam, getTeam, GetTeamParams } from "src/services/Settings/Teams/getTeam";
import { RootState } from "src/store/store";

// thunk that fetches all teams
const fetchAllTeamsThunk = async(
  payload: undefined,
  {getState}: {getState: Function}
) =>{
  try{
    const currentState: RootState = getState();
    const queryData: GetAllTeamParams = {
      start: currentState.teamSettings.teamIdList.length,
      limit: currentState.teamSettings.fetchTeamsLimit
    }
    const teamData: GetAllTeam = await getAllTeam(queryData);
    // console.log("---------------teamSettingThunk ------------ ");
    // console.log("Team data ::: "+ JSON.stringify(teamData));
    return {
      teamsData: teamData.teams,
      teamIdList: teamData.teamIds,
      count: teamData.metaData.count,
      totalCount: teamData.metaData.total
    }
  }catch(error){
    throw error;
  }
};

// thunk used to fetch all users in a team
const fetchMembersInTeamThunk = async(
  payload: number,
  {getState}: {getState: Function}
) => {
  const currentState: RootState = getState();
  const getTeamPayload: GetTeamParams = {
    teamId: payload,
    start: currentState.teamSettings.usersInTeamIdList.length,
    limit: currentState.teamSettings.fetchTeamMemberLimit,
    searchTerm: currentState.teamSettings.teamMateSearchText
  }
  if(getTeamPayload.searchTerm?.length === 0) delete getTeamPayload.searchTerm;
  const teamUsersData: GetTeam = await getTeam(getTeamPayload);

  return teamUsersData;
};


export default {
  fetchAllTeamsThunk,
  fetchMembersInTeamThunk
};