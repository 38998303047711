import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";

export type RatingReponse = {
  id: number;
  name: string; 
}[];

const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/getRatingOptions`
  : "/api/chatSetting/getAllFeedbackRatingOptions";

export async function getRatingOptions() {
  const { data: res } = await axiosJSON.get( apiEndPoint );
  if ( res.err === true ) {
    throw new Error( res.msg as string );
  }
  return res.data as RatingReponse;
}
