import { RootState } from "src/store/store";
import { isObjOrStrEmpty } from "src/utils/utils";
import { IMessageData } from "./innerTicket.slice";

export function getTicketDescriptionMessageObject(state: RootState) {
  const activeTicketInfo = state.innerTicket.activeTicketInfo;
  const messageData: IMessageData = {
    bcc: activeTicketInfo.ticket_bcc,
    cc: activeTicketInfo.ticket_cc,
    channelId: activeTicketInfo.ticket_channel,
    from: activeTicketInfo.received_email_from,
    isDraftMessage: false,
    message: activeTicketInfo.ticket_description,
    messageDateTime: activeTicketInfo.ticket_created_date,
    messageId: 0,
    messageType: "message",
    senderImgURL: null,
    senderName: activeTicketInfo.ticket_customer_name,
    sentBy: activeTicketInfo.ticket_created_by,
    subject: activeTicketInfo.ticket_subject,
    ticketId: activeTicketInfo.ticket_id,
    to: activeTicketInfo.received_email_by,
    attachments: activeTicketInfo.ticket_attachments,
  };
  return messageData;
}

export function showDateMessageId(state: RootState) {
  // let dates_Id: Array<string> = [];
  // if (state.innerTicket.messageList.length > 0) {
  //   const messageData = state.innerTicket.messageData;
  //   let pushFlag = true;
  //   Object.entries(messageData).forEach(([messageId, message], index) => {
  //     dates_Id.push(`${message.messageDateTime}__${message.messageId}`);
  //     if (pushFlag) {
  //       if (
  //         new Date(message.messageDateTime).getTime() <
  //           new Date(
  //             state.innerTicket.activeTicketInfo.ticket_created_date
  //           ).getTime() &&
  //         (state.innerTicket.messageList[index + 1] === undefined
  //           ? state.innerTicket.hasMoreMessage
  //           : new Date(
  //               messageData[
  //                 state.innerTicket.messageList[index + 1]
  //               ].messageDateTime
  //             ).getTime() >
  //             new Date(
  //               state.innerTicket.activeTicketInfo.ticket_created_date
  //             ).getTime())
  //       ) {
  //         dates_Id.push(
  //           `${state.innerTicket.activeTicketInfo.ticket_created_date}__0`
  //         );
  //         pushFlag = false;
  //       }
  //     }
  //   });
  // }
  // // dates_Id = dates_Id.sort().reverse();
  // return dates_Id.map((ele) => parseInt(ele.split("__")[1]));

  const dates_Id: Array<string> = [];
  if (state.innerTicket.messageList.length > 0) {
    const messageData = state.innerTicket.messageData;
    for (const key in messageData) {
      const ele = messageData[key];
      if (ele.messageType === "chat" && ele.chatMeta) {
        dates_Id.push(
          `${ele.messageDateTime}__chat::${ele.chatMeta.chatRequestId}`,
          // `${ele.messageDateTime}__${ele.chatMeta.chatRequestId}`
        );
      } else {
        dates_Id.push(`${ele.messageDateTime}__${ele.messageId}`);
      }
    }
  }
  if (
    state.innerTicket.activeTicketInfo.ticket_created_date != undefined &&
    state.innerTicket.activeTicketInfo.ticket_description !== undefined
  ) {
    dates_Id.push(
      `${state.innerTicket.activeTicketInfo.ticket_created_date}__0`,
    );
  }
  const ret = dates_Id
    .sort()
    // .reverse()
    .map((ele) =>
      ele.includes("__chat")
        ? ele.split("__")[1].trim()
        : isNaN(Number(ele.split("__")[1]))
          ? ele.split("__")[1]
          : parseInt(ele.split("__")[1]),
    );

  if (ret[0] === 0 && state.innerTicket.hasMoreMessage === true) {
    ret.shift();
  }
  return ret;
}

// to check a ticket is selected by id
export function isMergeTicketIdSelected(
  state: RootState,
  ticketId: string | number,
) {
  return state.innerTicket.ticketMerge.allSelectedTicketIds.includes(ticketId);
}

export function areAllMergeTicketsSelected(state: RootState): boolean {
  if (!isObjOrStrEmpty(state.innerTicket.ticketMerge.ticketList)) {
    const allTicketIds = state.innerTicket.ticketMerge.ticketList;
    const allSelectedTicketIds =
      state.innerTicket.ticketMerge.allSelectedTicketIds;
    // return true if there exists some tickets and each of them are selected
    return (
      allTicketIds.length !== 0 &&
      allTicketIds.every((t) => allSelectedTicketIds.includes(t))
    );
  } else {
    return false;
  }
}

export function totalMergeTicketsSelected(state: RootState) {
  return state.innerTicket.ticketMerge.allSelectedTicketIds.length;
}

// get the list of selected tickets for merging
export function getAllSelectedTicketIdsForMerging(state: RootState) {
  return state.innerTicket.ticketMerge.allSelectedTicketIds;
}

export function isCurrentTicketMergedWithDifferent(state: RootState) {
  if (
    state.innerTicket.activeTicketInfo &&
    state.innerTicket.activeTicketInfo.merged_parent_tickets &&
    state.innerTicket.activeTicketInfo.merged_parent_tickets.length > 0
  ) {
    return true;
  } else {
    return false;
  }
}
