// import { MouseEventHandler, useCallback } from "react";
// import { useNavigate } from "react-router-dom";
import successImg from "src/assets/images/successMsg.png";
// import { useAppSelector } from "src/store/store";
import styles from "../AddModal.module.scss";

interface Props {
  onHide: () => void;
  text: "Domain" | "Email";
  subText?: React.ReactNode;
  hideCloseButton?: boolean;
}

/**
 * Modal for showing Step Verified
 */
function HasBeenVerified({
  onHide,
  text,
  subText,
  hideCloseButton = false,
}: Props) {
  // const navigate = useNavigate();
  // const selectedUserDetail = useAppSelector(
  //   (state) => state.emailIntegration.selectedUser,
  // );

  /**
   * Click Handler for Done button
   */
  // const onClickHandler: MouseEventHandler<HTMLButtonElement> = useCallback(
  //   (e) => {
  //     e.preventDefault();
  //     if (text === "Email") {
  //       navigate(
  //         `/settings/integrations/email/${selectedUserDetail.emailIntegrationId}/verifyDomain`,
  //       );
  //     }
  //     onHide();
  //   },
  //   [selectedUserDetail.emailIntegrationId, navigate, onHide, text],
  // );

  return (
    <div className=" rounded d-flex-column justify-content-center p-1">
      {/* Close button */}
      {!hideCloseButton && (
        <div className="d-flex justify-content-end mx-2 mt-1">
          <span
            role="button"
            onClick={onHide}
          >
            <svg
              id="Component_163_1"
              data-name="Component 163 – 1"
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              viewBox="0 0 20 20"
            >
              <circle
                id="Ellipse_844"
                data-name="Ellipse 844"
                cx="10"
                cy="10"
                r="10"
                fill="#707070"
                opacity="0"
              />
              <path
                id="Icon_ionic-ios-close"
                data-name="Icon ionic-ios-close"
                d="M16.466,15.476l2.992-2.992a.7.7,0,0,0-.991-.991l-2.992,2.992-2.992-2.992a.7.7,0,1,0-.991.991l2.992,2.992-2.992,2.992a.7.7,0,1,0,.991.991l2.992-2.992,2.992,2.992a.7.7,0,1,0,.991-.991Z"
                transform="translate(-5.559 -5.562)"
                fill="#707070"
              />
            </svg>
          </span>
        </div>
      )}

      {/* Tick Mark */}
      <div className="d-flex justify-content-center mb-2">
        <div className={`d-flex justify-content-center align-items-center`}>
          <img
            src={successImg}
            alt=""
          />
        </div>
      </div>

      {/* Heading */}
      <div className={`d-flex justify-content-center mb-3 ${styles.headText}`}>
        {text} has been verified !
      </div>

      {/* SubHeading */}
      {subText && (
        <div className="fw-light small d-flex justify-content-center mb-3 text-center">
          {subText}
        </div>
      )}

      {/* Buttons */}
      <div className="d-flex justify-content-center mb-3">
        {/* Find Code */}
        {/* <div className={`  `}>
          <button className={`m-1 ${styles.blueBtn} p-2 `}>Find code</button>
        </div> */}

        {/* Done Button */}
        <div className="">
          <button
            className={`m-1 ${styles.blueBtn} ${styles.doneBtn} p-2`}
            onClick={onHide}
          >
            Done
          </button>
        </div>
      </div>
    </div>
  );
}

export default HasBeenVerified;
