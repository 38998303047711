import { PayloadAction } from "@reduxjs/toolkit";
import {
  Customer,
  CustomerAttribute,
  ITicketData,
} from "src/services/LiveChat/messageExchange/getAllCustomers";
import {
  Message,
  MessageReturnType,
} from "src/services/LiveChat/messageExchange/getMessages";
import {
  ChatWidExchMsgSlice,
  ILiveChatAgent,
  initialState,
} from "./chatWidExchMsg.declarations";

const resetChatWidExchMsg = () => {
  return initialState;
};

const setActiveCustomerInfo = (
  state: ChatWidExchMsgSlice,
  action: PayloadAction<{ customerInfo: Customer }>,
) => {
  const { customerInfo } = action.payload;

  state.activeCustomerInfo = customerInfo;
};

const updateCustomerInfo = (
  state: ChatWidExchMsgSlice,
  action: PayloadAction<{
    customerInfo: Partial<Customer>; // Partial type indicates that not all properties of Customer are required
    customerId: string | number; // The ID of the customer being updated
  }>,
) => {
  const { customerInfo } = action.payload;

  if (state.activeCustomerInfo?.customerId == action.payload.customerId) {
    // Check if the customer being updated is the active customer in the state
    state.activeCustomerInfo = {
      ...state.activeCustomerInfo, // Preserve existing properties of the active customer
      ...customerInfo, // Merge the updated customer information into the active customer
    };

    if (customerInfo?.imageURL !== undefined) {
      state.messageIdList.forEach((messageId) => {
        if (
          state.messages[messageId] &&
          state.messages[messageId].senderType === "Customer" &&
          state.messages[messageId].sentBy?.id == action.payload.customerId
        ) {
          state.messages[messageId].sentBy.imageURL = customerInfo.imageURL;
        }
      });
    }
  }
};

const setTicketData = (
  state: ChatWidExchMsgSlice,
  action: PayloadAction<{ ticketData: ITicketData }>,
) => {
  let customerInfo: any = { ...state.activeCustomerInfo };
  customerInfo.ticketData = action.payload.ticketData;
  state.activeCustomerInfo = customerInfo;
};

const setIsResolved = (
  state: ChatWidExchMsgSlice,
  action: PayloadAction<{ isResolved: boolean }>,
) => {
  let customerInfo: any = { ...state.activeCustomerInfo };
  customerInfo.isResolved = action.payload.isResolved;
  state.activeCustomerInfo = customerInfo;
};

const addNewMessageChatWidExchMsg = (
  state: ChatWidExchMsgSlice,
  action: PayloadAction<{ messageData: Message }>,
) => {
  const { messageData } = action.payload;

  if (!state.messageIdList.includes(messageData.messageId)) {
    state.messageIdList.unshift(messageData.messageId);
  }

  state.messages[messageData.messageId] = messageData;
};

const resetAssignToAgentList = (state: ChatWidExchMsgSlice) => {
  state.agentIds = [];
  state.agents = {};
  state.agentMeta.count = 0;
  state.agentMeta.total = 0;
  return state;
};

const removeAgentFromAssignToList = (
  state: ChatWidExchMsgSlice,
  action: PayloadAction<{ agentId: number }>,
) => {
  const { agentId } = action.payload;

  const index = state.agentIds.indexOf(agentId);

  if (index > -1) {
    state.agentIds.splice(index, 1);

    delete state.agents[agentId];
  }
};

const addAgentToAssignToList = (
  state: ChatWidExchMsgSlice,
  action: PayloadAction<{
    agent: ILiveChatAgent;
    checkForExistingAgent?: boolean;
  }>,
) => {
  const { agent, checkForExistingAgent } = action.payload;

  if (!state.agentIds.includes(agent.id)) {
    if (!checkForExistingAgent) {
      state.agentIds.unshift(agent.id);

      if (state.agentMeta.total === null) {
        state.agentMeta.total = 1;
      } else {
        state.agentMeta.total += 1;
      }

      state.agents[agent.id] = agent;
    }
  } else {
    state.agents[agent.id] = agent;
  }

  if (state.agentMeta.total === null || state.agentMeta.total === 0) {
    state.agentMeta.total = 1;
  }
};

const selectAgentForAssignToSelect = (
  state: ChatWidExchMsgSlice,
  action: PayloadAction<string | number>,
) => {
  const agentId = action.payload;
  if (!state.selectedAgentIds.includes(agentId)) {
    state.selectedAgentIds.push(agentId);
  }
};

const removeAgentFormAssignToSelect = (
  state: ChatWidExchMsgSlice,
  action: PayloadAction<string | number>,
) => {
  const removeAgentId = action.payload;
  state.selectedAgentIds = state.selectedAgentIds.filter(
    (agentId) => agentId !== removeAgentId,
  );
};

const selectAllAgentForAssignToSelect = (state: ChatWidExchMsgSlice) => {
  state.selectedAgentIds = state.agentIds;
};

const removeAllAgentForAssignToSelect = (
  state: ChatWidExchMsgSlice,
  action: PayloadAction<{ initialValue: Array<number | string> }>,
) => {
  const { initialValue } = action.payload;

  state.selectedAgentIds = initialValue;
};

// Reducer for Appending Message Before Sending into API
export function appendMessageBeforeSending(
  state: ChatWidExchMsgSlice,
  { payload }: { payload: Message },
) {
  state.messageIdList.unshift(payload.messageUuid + "");
  state.messages[payload.messageUuid + ""] = payload;
}

export function messageSendTryAgainPressed(state: ChatWidExchMsgSlice) {
  const tryAgainElement = document.getElementById("tryAgainBtn");
  if (tryAgainElement) {
    tryAgainElement.classList.add("d-none");
  }
}

// Function to update a message with new information, specifically used for retry attempts
const updateTryAgainMessage = (
  state: ChatWidExchMsgSlice, // State object representing a slice of the chat application's data
  action: PayloadAction<{ messageUuid: string; message: Partial<Message> }>, // Payload containing the message UUID and partial message data
) => {
  // Check if the specified message UUID exists in the current state
  if (state.messages[action.payload.messageUuid]) {
    // If the message UUID exists, update the message with new information
    state.messages[action.payload.messageUuid] = {
      ...state.messages[action.payload.messageUuid], // Preserve existing message properties
      ...action.payload.message, // Apply changes from the payload to update the message
    };
  }
};

/**
 * Clear integration failure data before trying again
 */
const clearIntegrationFailure = (
  state: ChatWidExchMsgSlice,
  action: PayloadAction<{ messageUuid: string }>, // Payload containing the message UUID and partial message data
) => {
  // Check if the specified message UUID exists in the current state
  if (
    action.payload.messageUuid &&
    state.messages[action.payload.messageUuid]
  ) {
    if (state.messages[action.payload.messageUuid].integrationFailure) {
      state.messages[action.payload.messageUuid].integrationFailure = undefined;
    }
  }
};

const updateAttributes = (
  state: ChatWidExchMsgSlice,
  action: PayloadAction<Array<CustomerAttribute>>,
) => {
  if (state.activeCustomerInfo) {
    state.activeCustomerInfo.attributes = action.payload;
  }
  return state;
};

const setActiveChatScheduledMessageCount = (
  state: ChatWidExchMsgSlice,
  action: PayloadAction<{ chatId: number; count: number }>,
) => {
  if (
    state.activeCustomerInfo &&
    parseInt(state.activeCustomerInfo.chatId + "") === action.payload.chatId
  ) {
    state.activeCustomerInfo.scheduledCount = action.payload.count;
  }
  return state;
};
// Reducer for Marking Last Message as Seen
const markMessagesSeen = (
  state: ChatWidExchMsgSlice,
  action: PayloadAction<{
    seenStatus: boolean;
    lastMessageId: string | number;
    senderType: "Agent" | "Customer" | "Bot";
    seenBy: "Agent" | "Customer" | "Bot";
  }>,
) => {
  const { seenStatus, lastMessageId, senderType, seenBy } = action.payload;

  // Check if the last message is seen and the seen by is an Customer
  if (seenStatus && seenBy === "Customer") {
    // Get an array of message IDs
    const messageIds = state.messageIdList;

    // Find the index of the last message ID in the array
    const lastMessageIndex = messageIds.findIndex(
      (messageId) => messageId === lastMessageId,
    );

    // Update the seen status of all messages before the last message
    const updatedMessages = messageIds.reduce((acc, messageId, index) => {
      if (index >= lastMessageIndex) {
        return {
          ...acc,
          [messageId]: {
            ...state.messages[messageId],
            messageSeenStatus: true,
          },
        };
      } else {
        return {
          ...acc,
          [messageId]: state.messages[messageId],
        };
      }
    }, {});

    // Update the messages property of the state object with the new object
    state.messages = updatedMessages;
  }
};

export default {
  resetChatWidExchMsg,
  addNewMessageChatWidExchMsg,
  setActiveCustomerInfo,
  setTicketData,
  setIsResolved,
  addAgentToAssignToList,
  removeAgentFromAssignToList,
  resetAssignToAgentList,
  selectAgentForAssignToSelect,
  removeAgentFormAssignToSelect,
  selectAllAgentForAssignToSelect,
  removeAllAgentForAssignToSelect,
  appendMessageBeforeSending, // Reducer for Appending Message Before Sending into API
  messageSendTryAgainPressed, // Reducer for Try Again Button in Send Message
  updateAttributes,
  setActiveChatScheduledMessageCount,
  markMessagesSeen, // Reducer for Marking Last Message as Seen
  updateTryAgainMessage,
  updateCustomerInfo, // For integration failure for try again
  clearIntegrationFailure,
};
