import { axiosJSON } from "src/globals/axiosEndPoints";
import {
  FreePlanError,
  FreePlanErrorMessage,
  IS_TEST_ACTIVE,
} from "src/globals/constants";
import { Articles } from "./getAllExternal.service";
export type ExternalSourceImportStatus =
  | "importing"
  | "completed"
  | "stopped"
  | "refreshing"
  | "reindexing"
  | "deleting"
  | "deleted";
export interface importExternalSourceResponse {
  id: number;
  status: ExternalSourceImportStatus;
  importPercentage: number;
  pagesImported?: number;
}

export interface importExternalSourceParams {
  botProfileId: number | string;
  url: string;
}

const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/botProfile/external/import`
  : "/api/botProfile/external/createImport";

export async function importExternalSource(params: importExternalSourceParams) {
  const { data: res } = await axiosJSON.post(apiEndPoint, params);

  if (res.err) {
    if (res.statusCode) {
      // If the status code is there, throw a specific error
      throw {
        statusCode: res.statusCode,
        message: res.msg ?? res.message,
      };
    } else {
      const statusCode = res["status-code"];

      if (statusCode === "external_source_already_exists") {
        throw new Error("Source url already exists");
      }

      throw new Error("Failed to import");
    }
  }

  return res.data as importExternalSourceResponse;
}
