import { axiosJSON } from "src/globals/axiosEndPoints";

export interface ISearchTemplate {
  searchTerm: string;
  start: number;
  limit: number;
}

export interface IGetTemplateResponse {
  id: number;
  template_body: string;
  template_category_id: number;
  templateHeading: string;
  categoryName: string;
  previouslyUsedTicketId: number | string | null;
}

export interface GetAllTemplatesData{
  templateIds: number[],
  templates: {[key:number] : IGetTemplateResponse},
  metaData: {
    total: number;
    count: number;
  }
}
export async function searchTemplate(apiPayload: ISearchTemplate) {
  const { data } = await axiosJSON.post(
    "/api/template/searchTemplate",
    apiPayload
  );

  // console.log(data);

  if (data.error === true) {
    throw new Error("Some Error Occurred while fetching categories");
  }

  const ret: GetAllTemplatesData = {
    templateIds: [],
    templates: {},
    metaData: {
      total: data.totalCount ?? 0,
      count: data.count ?? 0,
    },
  };

  data.templates.forEach((template: IGetTemplateResponse) => {
    const id = parseInt(template.id + "");
    ret.templates[id] = template;
    ret.templateIds.push(id);
  });

  return ret;
}
