/**
 * This file is the React component file for KB Analytics.
 * It renders instances of the ReportTicketBody component with different configurations.
 * Each instance corresponds to a specific analytics endpoint related to knowledge base.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 * @author Navjyot
 */

import { useAppSelector } from "src/store/store";
import ReportTicketBody from "../ReportTicketBody/ReportTicketBody";

// React component for KB Analytics
function KBAnalytics() {
  const ui_visibility = useAppSelector(
    (state) => state.globals.currentUserData?.ui_visibility
  );

  return (
    <div>
      {/* Articles with most read duration  */}
      {ui_visibility?.includes("knowledgeBase_articlesMostRead") && (
        <ReportTicketBody
          endPoint={"knowledgeBase/articlesMostRead"}
          title={"Articles with most read duration"}
          tooltip={""}
          tabProps={{
            showComparison: false,
            showDailyPart: false,
            showListView: true,
          }}
        />
      )}
      {/* New Articles Added by time */}
      {ui_visibility?.includes("knowledgeBase_newArticlesAddedByTime") && (
        <ReportTicketBody
          endPoint={"knowledgeBase/newArticlesAddedByTime"}
          title={"New Articles Added by time"}
          tooltip={""}
          tabProps={{
            showComparison: false,
            showDailyPart: false,
            showListView: true,
            reportDateType: "daily",
          }}
        />
      )}

      {/* Articles with the highest rating */}
      {ui_visibility?.includes("knowledgeBase_highestRatedArticles") && (
        <ReportTicketBody
          endPoint={"knowledgeBase/highestRatedArticles"}
          title={"Articles with the highest rating"}
          tooltip={""}
          tabProps={{
            showComparison: false,
            showDailyPart: false,
            showListView: true,
          }}
        />
      )}

      {/* Articles with the lowest rating */}
      {ui_visibility?.includes("knowledgeBase_lowestRatedArticles") && (
        <ReportTicketBody
          endPoint={"knowledgeBase/lowestRatedArticles"}
          title={"Articles with the lowest rating"}
          tooltip={""}
          tabProps={{
            showComparison: false,
            showDailyPart: false,
            showListView: true,
          }}
        />
      )}

      {/* Team members who have authored the most articles */}
      {ui_visibility?.includes("knowledgeBase_authorsMostArticles") && (
        <ReportTicketBody
          endPoint={"knowledgeBase/authorsMostArticles"}
          title={"Team members who have authored the most articles"}
          tooltip={""}
          tabProps={{
            showComparison: false,
            showDailyPart: false,
            showListView: true,
          }}
        />
      )}

      {/* Tickets resolved due to KB articles */}
      {ui_visibility?.includes("knowledgeBase_ticketsResolvedDueToKb") && (
        <ReportTicketBody
          endPoint={"knowledgeBase/ticketsResolvedDueToKb"}
          title={"Tickets resolved due to KB articles"}
          tooltip={""}
          tabProps={{
            showComparison: false,
            showDailyPart: false,
            showListView: true,
          }}
        />
      )}
    </div>
  );
}

export default KBAnalytics;
