import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { useReturnAutoWorkFlow } from "src/features/ReturnAutoWorkFlow/hooks/useReturnAutoWorkFlow";
import useClickAway from "src/hooks/useClickAway";
import {
  ConditionTypes,
  IVariableName,
  conditionValues,
  operatorNames,
} from "src/features/ReturnAutoWorkFlow/children/ConfigModals/store/returnModalsSliceHelpers";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  addCondition,
  addReturnMethod,
  deleteCondition,
  pushCurrentHashForSelectedTab,
  returnMethodErrorCountSelector,
  showInstruction,
  updateCondition,
  updateReturnMethod,
  validateChanges,
  validateCondition,
  validateReturnMethod,
  addSelectReasonCondition,
  deleteSelectReasonCondition,
  updateSelectReasonCondition,
  ReturnReason,
} from "../../store/slices/ReturnMethodSlice/returnMethod.slice";
import {
  fetchReturnMethodsThunk,
  updateReturnMethodThunk,
  validateReturnMethodConditions,
} from "../../store/slices/ReturnMethodSlice/returnMethod.thunk";

function useReturnMethodModal({
  onSave,
}: {
  onSave: (isSaved: boolean) => void;
}) {
  const dispatch = useAppDispatch();
  const { returnAutoWorkFlow } = useReturnAutoWorkFlow();

  const {
    returnMethods,
    selectedReturnMethodId,
    fetchAjaxStatus,
    updateAjaxStatus,
    initialHash,
    currentHash,
    conditionOptions: conditionOptionsRaw,
    defaultOptions: defaultOptionsRaw,
    itemOptions: itemOptionsRaw,
    selectReasonOptions,
  } = useAppSelector((state) => {
    return state.returnMethod;
  });

  const [selectedReturnMethod, selectedReturnMethodIndex] = useMemo(() => {
    const index = returnMethods.findIndex(
      (returnMethod) => returnMethod.returnMethodId === selectedReturnMethodId,
    );
    const returnMethod = index !== -1 ? returnMethods[index] : undefined;
    return [returnMethod, index];
  }, [returnMethods, selectedReturnMethodId]);

  const isAddBtnEnabled = useMemo(
    () => selectedReturnMethod?.isAddConditionEnabled ?? false,
    [selectedReturnMethod?.isAddConditionEnabled],
  );

  const isAddBtnSelectReasonEnabled = useMemo(() => {
    const selectedReturnMethod = returnMethods.find(
      (rtm) => rtm.returnMethodId === selectedReturnMethodId,
    );

    return selectedReturnMethod?.isAddSelectReasonConditionEnabled ?? false;
  }, [returnMethods, selectedReturnMethodId]);

  const itemOptions = useMemo(
    () => Object.values(itemOptionsRaw).map((option) => option.name),
    [itemOptionsRaw],
  );

  const conditionOptions = useMemo(
    () => Object.values(conditionOptionsRaw).map((option) => option.name),
    [conditionOptionsRaw],
  );

  const defaultOptions = useMemo(
    () => Object.values(defaultOptionsRaw).map((option) => option.name),
    [defaultOptionsRaw],
  );

  const returnReasonOptions = useMemo(
    () => Object.values(selectReasonOptions).map((option) => option.name),
    [selectReasonOptions],
  );

  const [otherTabsHasError, setOtherTabsHasError] = useState(false);

  const otherTabErrorCount = useAppSelector(returnMethodErrorCountSelector);

  useEffect(() => {
    if (otherTabErrorCount <= 0) {
      setOtherTabsHasError(false);
    } else {
      setOtherTabsHasError(true);
    }
  }, [otherTabErrorCount]);

  // All the dispatch function which will be used in this file

  const shipWithLabelInputRef = useRef(null);
  useClickAway(shipWithLabelInputRef, () => {
    dispatch(
      showInstruction({
        showInstruction: false,
        selectedId: selectedReturnMethod?.returnMethodId ?? "",
        type: "Label",
      }),
    );
  });

  useEffect(() => {
    if (!returnAutoWorkFlow.integrationId) {
      return;
    }
    // TODO
    dispatch(
      fetchReturnMethodsThunk({
        integrationId: returnAutoWorkFlow.integrationId,
        showReturnReasons: false,
      }),
    );
    // if (selectedReturnMethodId) {
    //   dispatch(
    //     validateReturnMethod({ returnMethodId: selectedReturnMethodId }),
    //   );
    // }
  }, [dispatch, returnAutoWorkFlow.integrationId]);

  const checkisAddBtnEnabled = useCallback(
    (isItems: Boolean) => {
      // Takes isItems as a parameter and returns a boolean value
      if (isItems) {
        // If isItems is true, check "isAddConditionEnabled"
        if (selectedReturnMethod !== undefined) {
          return selectedReturnMethod.isAddConditionEnabled;
        } else {
          // If selectedReturnMethod is undefined, return false
          return false;
        }
      } else {
        // If isItems is false, check "isAddOrderConditionEnabled"
        if (selectedReturnMethod !== undefined) {
          return selectedReturnMethod.isAddOrderConditionEnabled;
        } else {
          // If selectedReturnMethod is undefined, return false
          return false;
        }
      }
    },
    [selectedReturnMethod],
  );

  // all the dispatch function which is used in return method config modal

  //function to update tab name
  const dispUpdateReturnMethod = useCallback(
    (id: string, updateObj: any) => {
      dispatch(
        updateReturnMethod({
          returnMethodDetail: {
            ...returnMethods,
            returnMethodId: id,
            ...updateObj,
          },
        }),
      );
      dispatch(validateChanges());
    },
    [dispatch, returnMethods],
  );

  //function to update the current tab hash string, which is used to identify if the data is changed or not
  const dispPushCurrentHashForSelectedTab = useCallback(
    (id: string) => {
      dispatch(
        pushCurrentHashForSelectedTab({
          returnMethodId: id,
        }),
      );
      dispatch(validateChanges());
    },
    [dispatch],
  );

  //function to add new tab
  const dispAddTab = useCallback(
    (returnReasons: boolean) => {
      dispatch(addReturnMethod({ returnReasons }));
      dispatch(validateChanges());
    },
    [dispatch],
  );

  //function to validate condition of passed tab id
  const dispValidateCondition = useCallback(
    (id: string) => {
      dispatch(
        validateCondition({
          returnMethodId: id,
        }),
      );
      dispatch(validateChanges());
    },
    [dispatch],
  );

  //function to update conditionType of a condition
  const dispUpdateConditionForVariables = useCallback(
    (
      id: string,
      e: { target: { value: string } },
      condition: ConditionTypes,
    ) => {
      dispatch(
        updateCondition({
          returnMethodId: id,
          conditionToUpdate: {
            ...condition,
            variableName: e.target.value as IVariableName,
            operator: operatorNames[e.target.value as IVariableName][0],
            values: conditionValues[e.target.value as IVariableName],
          },
        }),
      );
    },
    [dispatch],
  );

  //function to update operator of a condition
  const dispUpdateConditonForOperator = useCallback(
    (
      id: string,
      e: { target: { value: string } },
      condition: ConditionTypes,
    ) => {
      dispatch(
        updateCondition({
          returnMethodId: id,
          conditionToUpdate: {
            ...condition,
            operator: e.target.value,
          },
        }),
      );
    },
    [dispatch],
  );

  //function to update condition value by its value type
  const dispUpdateConditionForValues = useCallback(
    (id: string, type: string, value: any, condition: ConditionTypes) => {
      switch (type) {
        case "tags":
          dispatch(
            updateCondition({
              returnMethodId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  tags: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;
        case "items":
          dispatch(
            updateCondition({
              returnMethodId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  items: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;
        case "day_type":
          dispatch(
            updateCondition({
              returnMethodId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  day_type: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;
        case "days":
          dispatch(
            updateCondition({
              returnMethodId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  days: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;

        case "codes":
          dispatch(
            updateCondition({
              returnMethodId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  codes: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;
        case "numbers":
          dispatch(
            updateCondition({
              returnMethodId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  numbers: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;
        case "countryName":
          dispatch(
            updateCondition({
              returnMethodId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  countryName: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;
        case "amount":
          dispatch(
            updateCondition({
              returnMethodId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  amount: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;
        case "currency":
          dispatch(
            updateCondition({
              returnMethodId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  currency: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;

        default:
          break;
      }
      dispatch(validateChanges());
    },
    [dispatch],
  );

  //function to delete a condition by its id
  const dispDeleteCondition = useCallback(
    (conId: string, id: string, isItem?: boolean) => {
      dispatch(
        deleteCondition({
          conditionId: conId,
          returnMethodId: id,
          isItemCondition: isItem,
        }),
      );
    },
    [dispatch],
  );

  const dispValidateReturnMethod = useCallback(
    (id: string) => {
      dispatch(
        validateReturnMethod({
          returnMethodId: id,
        }),
      );
    },
    [dispatch],
  );

  //20 Jan Added to show validation on modal load itself
  useMemo(() => {
    if (selectedReturnMethodId && dispValidateCondition) {
      if (selectedReturnMethodIndex !== -1 && selectedReturnMethodIndex !== 0)
        dispValidateCondition(selectedReturnMethodId);
    }
  }, [
    dispValidateCondition,
    selectedReturnMethodId,
    selectedReturnMethodIndex,
  ]);

  //function to save/submit the configuration modal data
  const dispPostConfig = useCallback(async () => {
    if (!returnAutoWorkFlow.integrationId) {
      return;
    }
    //dispatching the validation thunk and
    // then if the validation passes then calling the submit api otherwise showing warning toast
    const res = await dispatch(validateReturnMethodConditions());
    if (res.payload === 0) {
      dispatch(
        updateReturnMethodThunk({
          callback: () => {
            onSave(true);
          },
          integrationId: returnAutoWorkFlow.integrationId,
          showReturnReasons: false,
        }),
      );
    } else {
      pushTheToast({
        type: "warning",
        text: "Please fill the required fields!",
        position: "top-right",
      });
    }
  }, [dispatch, onSave, returnAutoWorkFlow.integrationId]);

  //function to add new condition for the passed tab id
  const dispAddCondition = useCallback(
    (id: string, isItem?: boolean, e?: { target: { value: string } }) => {
      dispatch(
        addCondition({
          returnMethodId: id,
          condition: e ? e.target.value : "",
          isItemCondition: isItem,
        }),
      );
      dispatch(validateChanges());
    },
    [dispatch],
  );

  //function to update conditionType of a condition
  const dispReasonUpdateConditionForVariables = useCallback(
    (
      id: string,
      e: { target: { value: string } },
      condition: ConditionTypes,
    ) => {
      dispatch(
        updateSelectReasonCondition({
          returnMethodId: id,
          conditionToUpdate: {
            ...condition,
            variableName: e.target.value as IVariableName,
            operator: operatorNames[e.target.value as IVariableName][0],
            values: conditionValues[e.target.value as IVariableName],
          },
        }),
      );
    },
    [dispatch],
  );

  //function to update operator of a condition
  const dispReasonUpdateConditonForOperator = useCallback(
    (
      id: string,
      e: { target: { value: string } },
      condition: ConditionTypes,
    ) => {
      dispatch(
        updateSelectReasonCondition({
          returnMethodId: id,
          conditionToUpdate: {
            ...condition,
            operator: e.target.value,
          },
        }),
      );
    },
    [dispatch],
  );

  //function to update condition value by its value type
  const dispReasonUpdateConditionForValues = useCallback(
    (
      id: string,
      value: any,
      condition: ReturnReason,
      isFollowUpQuestion?: boolean,
    ) => {
      if (!isFollowUpQuestion) {
        value.value = "";
      }

      dispatch(
        updateSelectReasonCondition({
          returnMethodId: id,
          conditionToUpdate: {
            ...condition,
            values: {
              return_reason: {
                ...condition.values?.return_reason,
                ...value,
              },
            },
          },
        }),
      );

      dispatch(validateChanges());
    },
    [dispatch],
  );

  //function to delete a condition by its id
  const dispReasonDeleteCondition = useCallback(
    (conId: string, id: string) => {
      dispatch(
        deleteSelectReasonCondition({
          conditionId: conId,
          returnMethodId: id,
        }),
      );
    },
    [dispatch],
  );

  //function to add new condition for the passed tab id
  const dispReasonAddCondition = useCallback(
    (id: string) => {
      dispatch(
        addSelectReasonCondition({
          returnMethodId: id,
        }),
      );
      dispatch(validateChanges());
    },
    [dispatch],
  );

  return {
    dispAddCondition,
    dispAddTab,
    dispDeleteCondition,
    dispPostConfig,
    dispPushCurrentHashForSelectedTab,
    dispUpdateConditionForValues,
    dispUpdateConditionForVariables,
    dispUpdateConditonForOperator,
    dispUpdateReturnMethod,
    dispValidateCondition,
    dispValidateReturnMethod,
    returnMethods,
    fetchAjaxStatus,
    updateAjaxStatus,
    initialHash,
    currentHash,
    selectedReturnMethod,
    selectedReturnMethodId,
    isAddBtnEnabled,
    otherTabsHasError,
    defaultOptions,
    itemOptions,
    conditionOptions,
    operatorNames,
    checkisAddBtnEnabled,
    dispReasonAddCondition,
    dispReasonDeleteCondition,
    dispReasonUpdateConditionForValues,
    dispReasonUpdateConditonForOperator,
    returnReasonOptions,
    isAddBtnSelectReasonEnabled,
    dispReasonUpdateConditionForVariables,
  };
}
export default useReturnMethodModal;
