import { useLocation, useNavigate } from "react-router-dom";
import saufter from "src/assets/images/saufter.png";
import { subSBLink } from "src/services/TicketService/linkGenerator";
import {
  setSelectedAiBtn,
  setSelectedBtn,
} from "src/store/slices/ticketSidebar/ticketSidebar.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import styles from "./CernTickets.module.scss";

const CernTickets = () => {
  const { aiClosedTicketCount, aiOpenTicketCount, selectedAiBtn, selectedBtn } =
    useAppSelector((state) => state.ticketSideBar);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const handleButtonClick = (btnType: "Open" | "Closed") => {
    // First navigate to ai_handled tabtype
    navigate(
      subSBLink({
        tableType: "ai_handled",
        search: location.search,
        pathName: location.pathname,
      }),
    );
    // Set selected btn as ai_handled
    dispatch(
      setSelectedBtn({
        tableType: "ai_handled",
      }),
    );
    //Set selectedaibtn type as respective type
    dispatch(
      setSelectedAiBtn({
        btnType: btnType,
      }),
    );
  };

  return (
    <>
      <div className={`${styles.logoWrapper}`}>
        <div className={`${styles.logo}`}>
          <img
            src={saufter}
            alt=""
            className=""
          />
        </div>
      </div>
      <div className={`mb-3 ${styles.cernTicketWrapper}`}>
        <h4 className={`saufter_h4 ${styles.cernHeader}`}>
          Cern Tickets
          <span className={`${styles.cernSubHeader}`}>-AI handled</span>
        </h4>
        <div className="d-flex align-items-center">
          <button
            className={`${styles.openBtn} ${
              selectedBtn === "ai_handled" &&
              selectedAiBtn === "Open" &&
              styles.active
            }`}
            onClick={(e) => {
              e.preventDefault();
              handleButtonClick("Open");
            }}
          >
            <div
              className={`${styles.openDot}`}
              id={`link__open__aibot`}
            ></div>
            {aiOpenTicketCount > 99 ? `99+` : `${aiOpenTicketCount ?? 0}`} open
          </button>
          <button
            className={`${styles.closebtn} ${
              selectedBtn === "ai_handled" &&
              selectedAiBtn === "Closed" &&
              styles.active
            }`}
            onClick={(e) => {
              e.preventDefault();
              handleButtonClick("Closed");
            }}
            id={`link__closed__aibot`}
          >
            <div className={`${styles.closeDot}`}></div>
            {aiClosedTicketCount > 99 ? `99+` : `${aiClosedTicketCount}`} closed
          </button>
        </div>
      </div>
    </>
  );
};

export default CernTickets;
