import { useQuery } from "@tanstack/react-query";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import {
  ConfigureCSVUploadDetails,
  EConfigureCsvActiveStep,
  EUploadSteps,
  stepsList,
} from "src/features/ReturnAutoWorkFlow/ReturnAutoWorkFlow.types";
import getConfigureCSVUploadDetails from "src/services/ReturnAutoWorkFlow/ConfigModals/getConfigureCSVUploadDetails.service";

export interface UploadNewCsvState {
  state: ConfigureCSVUploadDetails;
  originalData?: ConfigureCSVUploadDetails;
  updateState: (data: Partial<ConfigureCSVUploadDetails>) => void;
  isLoading: boolean;
  completedSteps: string[];
  setCompletedSteps: React.Dispatch<React.SetStateAction<string[]>>;
  showErrors: boolean;
  setShowErrors: React.Dispatch<React.SetStateAction<boolean>>;
  validateStep: ({
    stepType,
    state,
  }: {
    stepType: EUploadSteps;
    state?: ConfigureCSVUploadDetails;
  }) => boolean;
}

const initialState: ConfigureCSVUploadDetails = {
  activeStep: "configureUpload",
  configureUpload: {
    configureCSVQuestions: [],
  },
  importedCSV: null,
  mapAttributes: null,
  uploadCSV: null,
};

const initialContext: UploadNewCsvState = {
  state: initialState,
  updateState: () => {},
  completedSteps: [],
  setCompletedSteps: () => {},
  showErrors: false,
  setShowErrors: () => {},
  validateStep: () => false,
  isLoading: false,
};

const UploadNewCsvContext = createContext<UploadNewCsvState>(initialContext);

export const useUploadNewCsvModalContext = () => {
  const context = useContext(UploadNewCsvContext);

  if (!context) {
    throw new Error(
      "useUploadNewCsvModalContext must be used within a UploadNewCsvProvider",
    );
  }

  return context;
};

export const useUploadNewCsvModalProvider = (integrationId: string) => {
  const [state, setState] = useState<ConfigureCSVUploadDetails>({
    ...initialState,
  });

  const [completedSteps, setCompletedSteps] = useState<string[]>([
    ...initialContext.completedSteps,
  ]);

  const [showErrors, setShowErrors] = useState(false);

  const { data, isLoading } = useQuery<ConfigureCSVUploadDetails, Error>({
    queryKey: ["getConfigureCSVUploadDetails", integrationId],
    queryFn: () => {
      if (integrationId) {
        return getConfigureCSVUploadDetails({ integrationId });
      }
      throw new Error("Integration ID is missing");
    },
    onSuccess: (res) => {
      const activeStep = stepsList.find((step) => step.key == res?.activeStep);
      if (activeStep) {
        const completedStepKeys = stepsList
          .filter((step) => step.index < activeStep.index) // Filter steps before the active step
          .map((step) => step.key); // Get the keys of the completed steps
        setCompletedSteps(completedStepKeys);
      }
    },
  });

  useEffect(() => {
    if (data) {
      setState(data);
    }
  }, [data]);

  const updateState = useCallback(
    (data: Partial<ConfigureCSVUploadDetails>) => {
      setState((currentData) => ({
        ...currentData,
        ...data,
      }));
    },
    [],
  );
  // Validate steps
  const validateStep = useCallback(
    ({
      stepType,
      state,
    }: {
      stepType: EUploadSteps;
      state?: ConfigureCSVUploadDetails;
    }) => {
      if (state) {
        switch (stepType) {
          case EUploadSteps.ConfigureUpload: {
            // Retrieve questions from state
            const questions = state.configureUpload?.configureCSVQuestions;
            // Check if all questions are answered
            const isValid = questions?.every(
              (question) =>
                question?.questionValue &&
                question?.questionValue?.trim() !== "",
            );

            return isValid ?? false;
          }
          case EUploadSteps.UploadCSV: {
            let isValid = true;
            if (
              (!state?.uploadCSV?.attachmentId &&
                !state?.uploadCSV?.uploadedFileLink) ||
              (state?.uploadCSV?.uploadErrors?.errorIds &&
                state?.uploadCSV?.uploadErrors?.errorIds?.length > 0 &&
                state?.uploadCSV?.uploadErrors?.errorType !== "warning")
            ) {
              isValid = false;
              pushTheToast({
                position: "top-right",
                text: "Your import has errors!",
                type: "danger",
              });
            }
            return isValid;
          }
          case EUploadSteps.MapAttributes: {
            let isValid = true;

            // Detect duplicates in table heading IDs
            const duplicates =
              state.mapAttributes?.updatedTableHeadingIds?.reduce(
                (acc, id) => {
                  acc[id] = (acc[id] || 0) + 1;
                  return acc;
                },
                {} as Record<string, number>,
              ) ?? {}; // Provide a fallback empty object if undefined

            const isDuplicates = Object.keys(duplicates).some(
              (id) => duplicates[id] > 1,
            );

            // Check for errors and duplicates
            if (
              (state.mapAttributes &&
                Object.keys(state.mapAttributes?.mapAttributeErrors ?? {})
                  .length > 0) ||
              isDuplicates
            ) {
              isValid = false;
              pushTheToast({
                position: "top-right",
                text: "There are errors in map attributes!",
                type: "danger",
              });
            }

            return isValid;
          }
          default:
            // return true for other steps
            return true;
        }
      }
      return false;
    },
    [state],
  );

  return {
    state,
    completedSteps,
    setCompletedSteps,
    showErrors,
    setShowErrors,
    originalData: data,
    updateState,
    validateStep,
    isLoading,
    UploadNewCsvProvider: UploadNewCsvContext.Provider,
  };
};
