import { SetStateAction, useEffect, useRef, useState } from "react";
import styles from "./GreetingCondition.module.scss";
import moreInfo from "src/assets/images/moreInfo.png";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Select from "react-select";
import ConditionBox from "./Children/ConditionBox/ConditionBox";
import { v4 as uuidv4 } from "uuid";
import Calendar from "react-calendar";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  ADD_RULES,
  DELETE_RULE,
  UPDATE_RULE,
  UPDATE_SINGLE_VALUES,
} from "src/store/slices/targetedMessage/targetedMessage.slice";
import { current } from "@reduxjs/toolkit";

function GreetingCondition(props: {
  headingType: string;
  conditionType: string;
}) {
  let [count, setCount] = useState(1);

  const dispatch = useAppDispatch();

  const rules = useAppSelector(
    (state) => {
      return state.targetedMessage.rules;
    }
    /* ,
    (left, right) => {
      console.log(left, right);
      for (let i = 0; i < left.length; i++) {
        const l = left[i];
        const idx = right.findIndex((r) => r.id === l.id);
        if (idx === -1) {
          console.log("diff ");
          console.log(left, right);
          return false;
        }
      }
      console.log("same");
      return true;
    } */
  );

  const [messageVisibleTo, setMessageVisibleTo] = useState("");

  // updateData({
  //   messageType,
  //   messageProperties: {
  //     templateType,
  //     elements: {
  //       title: titleText,
  //       subtitle: subtitleTitleText,
  //       image: {
  //         url: urlText,
  //         alternativeText: urlAltText,
  //       },
  //     },
  //     buttons: triggerButtons.map((btn) => {
  //       return {
  //         btnStyles: btn.btnStyles,
  //         buttonStyles: btn.buttonStyles,
  //         action: btn.selectedAction,
  //         selectedAction: btn.selectedAction,
  //         id: btn.id,
  //         momentSize: btn.momentSize,
  //         text: btn.text,
  //         value: btn.value,
  //         websiteUrl: btn.websiteUrl,
  //       } as UIButtonProps;
  //     }),
  //   },
  // })

  const options = [
    {
      value: "Match all of these conditions",
      label: "Match all of these conditions",
    },
    {
      value: "Match one of these conditions",
      label: "Match one of these conditions",
    },
  ];

  const optionsAnnouncement = [
    {
      value: "1",
      label: "7 days from today",
    },
    {
      value: "2",
      label: "30 days from today",
    },
    {
      value: "3",
      label: "Till the specific date",
    },
  ];

  let conditionBox = rules.slice(3);
  // let [conditionBox, setConditionBox] = useState<any[]>();
  // console.log({ conditionBox });

  function addConditionBox() {
    const newConditions = [
      {
        id: uuidv4(),
        ruleType: "",
        variableName: "",
        value: "",
        operator: "",
      },
    ];
    // console.log({ newConditions });
    dispatch(ADD_RULES({ rules: newConditions }));
  }

  console.count("GreetingCondition");
  function deleteConditionBox(conditionToDelete: any) {
    const rule = conditionBox.find(
      (condition) => condition.id === conditionToDelete.id
    );
    dispatch(DELETE_RULE({ rule: rule }));
    /*  setConditionBox((addCondition) => {
      return addCondition.filter((item) => item !== id);
    }); */
  }
  const [MatchCondition, setMatchCondition] = useState(options[0]);

  useEffect(() => {
    let conditionType = "";
    if (MatchCondition.value === "Match one of these conditions") {
      conditionType = "OR";
    } else {
      conditionType = "AND";
    }
    dispatch(
      UPDATE_SINGLE_VALUES({
        conditionType,
      })
    );
  }, [MatchCondition]);

  let [selectedOption, setSelectedOption] = useState("1");

  return (
    <>
      <div className={`${styles.greetMain} `}>
        {props.headingType === "Announcement" ? (
          <div className="mt-3">
            <p className={`${styles.receivePara}`}>
              For how long this announcement should run
            </p>
            <div className=" ">
              <Select
                options={optionsAnnouncement}
                className={`${styles.selectItem}`}
                defaultValue={optionsAnnouncement[0]}

                // onChange={(e) => {
                //   setSelectedOption(e?.target.value ?? -1);
                // }}
              />
            </div>
            {selectedOption === "3" ? <Calendar /> : ""}
          </div>
        ) : (
          ""
        )}

        <div className="">
          <p className={`${styles.receivePara}`}>
            Who will receive this greeting
          </p>

          {/* Message Visible To Div */}
          <div
            className={`d-flex justify-content-between ${styles.radioWrapper} w-100`}
          >
            <div className={`form-check ${styles.radioBox}`}>
              <input
                className={`form-check-input ${styles.formInput}`}
                type="radio"
                name="flexRadioDefault"
                value="allVisitors"
                id="flexRadioDefault1"
                onChange={(e) => {
                  setMessageVisibleTo(e.target.value);
                }}
                defaultChecked={true}
              />
              <label
                className={`form-check-label ${styles.formLabel}`}
                htmlFor="flexRadioDefault1"
              >
                All Visitors
              </label>
            </div>
            <div className={`form-check ${styles.radioBox}`}>
              <input
                className={`form-check-input ${styles.formInput}`}
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
                value="firstTimeVisitor"
                onChange={(e) => {
                  setMessageVisibleTo(e.target.value);
                }}
                defaultChecked
              />
              <label
                className={`form-check-label ${styles.formLabel}`}
                htmlFor="flexRadioDefault1"
              >
                First Time Visitors
              </label>
            </div>
            <div className={`form-check ${styles.radioBox}`}>
              <input
                className={`form-check-input ${styles.formInput}`}
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
                value="returningVisitors"
                onChange={(e) => {
                  setMessageVisibleTo(e.target.value);
                }}
              />
              <label
                className={`form-check-label ${styles.formLabel}`}
                htmlFor="flexRadioDefault1"
              >
                Returning Visitors
              </label>
            </div>
          </div>
          <div>
            <p className={`${styles.receivePara}`}>
              When to display this greeting
            </p>
            <div className={`d-flex align-items-center`}>
              <span>Time on page is more than:</span>
              <span className="mx-1">
                <div className={`d-flex ${styles.timeSet}`}>
                  <div className={`${styles.timeSet}`}>
                    <span>{count}</span>
                  </div>
                  <div className={`d-flex flex-column ${styles.arrowBox}`}>
                    <i
                      className={`${styles.upArrow} fa-solid fa-sort-up`}
                      onClick={(e) => {
                        setCount(++count);
                      }}
                    ></i>
                    <i
                      className={`${styles.downArrow} fa-solid fa-caret-down`}
                      onClick={(e) => {
                        setCount(--count);
                      }}
                    ></i>
                    <span></span>
                  </div>
                </div>
              </span>
              <span>seconds</span>
              <span>
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip className={` ${styles.toolTipCustom}`}>
                      Tip:Your Google Analytics can tell you how long customers
                      stay on each page so you can set the time trigger
                      accordingly.
                    </Tooltip>
                  }
                >
                  {({ ref, ...triggerHandler }) => (
                    <div {...triggerHandler} ref={ref}>
                      <img
                        className={`${styles.moreInfo}`}
                        src={moreInfo}
                        alt={``}
                      />
                    </div>
                  )}
                </OverlayTrigger>
              </span>
            </div>
          </div>
          <div>
            <Select
              options={options}
              className={`${styles.selectItem}`}
              defaultValue={options}
              onChange={(newValue, actionMeta) => {
                if (newValue) {
                  setMatchCondition({
                    label: newValue?.label,
                    value: newValue?.value,
                  });
                }
              }}
              value={MatchCondition}
            />
          </div>

          {conditionBox.map((item: any) => {
            return (
              <ConditionBox
                key={item.id}
                condition={item}
                isFirstElement={conditionBox.indexOf(item) === 0}
                onDelete={deleteConditionBox}
              />
            );
          })}

          <div className={`mt-3 d-flex`} role="button">
            <div className={`d-block ${styles.plusBox}`}>
              {" "}
              <i className="fa-solid fa-plus"></i>
            </div>
            <div className={`${styles.addSpan} mt-1`} onClick={addConditionBox}>
              {" "}
              Add condition
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default GreetingCondition;
