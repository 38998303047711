import { useCallback, useEffect, useRef, useState } from "react";
import SearchBar from "src/components/SearchBar/SearchBar";
import {
  fetchAllBrands,
  resetBrandData,
  setBrandSearchValue,
} from "src/store/slices/settings/brands/brandSettings.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import BrandTable from "./Children/BrandTable/BrandTable";
import styles from "./SearchSection.module.scss";

const SearchSection = () => {
  const { filters, brandDataAjaxStatus, brandIds, metaData, brands } =
    useAppSelector((state) => state.brandSettings);
  const [searchValue, setSearchValue] = useState("");
  const dispatch = useAppDispatch();

  const scopes = useAppSelector(
    (state) => state.globals.currentUserData?.scopes,
  );

  const initialized = useRef(false);
  const currentState = useRef({
    brandDataAjaxStatus,
    brandIds,
    metaData,
    brands,
  });

  useEffect(() => {
    currentState.current = {
      brandDataAjaxStatus,
      brandIds,
      metaData,
      brands,
    };
  }, [brandDataAjaxStatus, brandIds, metaData, brands]);

  const handleSearch = useCallback((value: any, callback: any) => {
    if (initialized.current) {
      dispatch(setBrandSearchValue({ searchValue: value.trim() }));
      dispatch(resetBrandData());
      dispatch(fetchAllBrands());
    }
  }, []);

  useEffect(() => {
    if (!initialized.current) {
      dispatch(resetBrandData());
      dispatch(setBrandSearchValue({ searchValue: "" }));
      dispatch(fetchAllBrands());
      initialized.current = true;
    }
  }, []);

  useEffect(() => {
    if (initialized.current && brandDataAjaxStatus !== "pending") {
      // Get Search params and get search query parameter
      const searchparams = new URLSearchParams(window.location.search);
      const searchQuery = searchparams.get("searchQuery");

      // Check if we have search query
      if (searchQuery) {
        // Delete search query from params
        searchparams.delete("searchQuery");
        // Get the new search string
        const searchString = searchparams.toString();
        // Update the history stack
        window.history.pushState(
          {},
          "",
          window.location.pathname + (searchString ? "?" + searchString : ""),
        );
        // Update the state
        setSearchValue(searchQuery);
      }
    }
  }, [brandDataAjaxStatus]);

  return (
    <>
      <div>
        <SearchBar
          className={`${styles.search}`}
          inputClassName={`${styles.input}`}
          placeholder={`Search by brand name, subdomain and any other keywords...`}
          onSearch={handleSearch}
          onChange={(e: any) => {
            setSearchValue(e.target.value);
          }}
          value={searchValue}
        />
        <div>
          <span className={`pt-3 d-inline-block ${styles.res}`}>
            {filters.searchValue &&
            filters.searchValue.trim() !== "" &&
            brandDataAjaxStatus === "fulfilled"
              ? `Showing results ${brandIds.length} of ${metaData.total ?? 0}`
              : ""}
          </span>
          <BrandTable />
        </div>
      </div>
    </>
  );
};
export default SearchSection;
