import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";
import { AutomationAPIPayload } from "../createAutomation.service";

interface DeleteDispatchRuleParams {
  integrationId: string;
  configurationId: string;
}

export const deleteDispatchRuleConfigService = async ({
  payload,
  type,
}: AutomationAPIPayload<DeleteDispatchRuleParams>) => {
  const apiEndPoint = IS_TEST_ACTIVE
    ? `${process.env.REACT_APP_TEST_SITE_URL}/bugManagement/delete`
    : `/api/automation/${type}/deleteDispatchRuleConfig`;

  const { data: res } = await axiosJSON.delete(apiEndPoint, { data: payload });

  if (res.err || res.error) {
    throw res.msg || res.message || "Cannot delete the dispatch rule";
  }
};
