import { AJAXSTATUS } from "src/globals/constants";

export interface TicketGlobalSearchSlice {
  ticketData: { [ticketId: number | string]: any };
  ticketList: Array<number>;
  ticketListTotal: number | null;
  ticketDataLimit: number;
  searchText: string;
  tableType: string;
  ticketDataAjaxStatus: AJAXSTATUS;
}

export const initialState: TicketGlobalSearchSlice = {
  ticketData: {},
  ticketList: [],
  ticketListTotal: null,
  ticketDataLimit: 15,
  searchText: "",
  tableType: "",
  ticketDataAjaxStatus: "idle",
};
