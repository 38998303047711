import { useCallback, useEffect, useMemo, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import noteam from "src/assets/images/no-team.png";
import InfiniteScroll from "src/components/InfiniteScroll";
import Loader from "src/components/Loader";
import PlanUpgradeOverlay from "src/components/PlanUpgrade/PlanUpgradeOverlay/PlanUpgradeOverlay";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { addTeams } from "src/services/Settings/Teams/addTeam";
import { setSearchValue } from "src/store/slices/settings/user/userSettings.slice";
import {
  fetchAllTeams,
  resetTeamsData,
} from "src/store/slices/teamSettings/teamSettings.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { v4 as uuidv4 } from "uuid";
import styles from "./Teams.module.scss";
import TeamList from "./components/TeamList/TeamList";

function Teams() {
  const dispatch = useAppDispatch();
  const { teamData, teamIdList, metaData, fetchTeamsAjaxStatus } =
    useAppSelector((state) => state.teamSettings);

  const disabledFeatures = useAppSelector(
    (state) => state.globals.currentUserData?.disabledFeatures,
  );

  const [showModal, setShowModal] = useState(false);

  const [inputTeamName, setInputTeamName] = useState<string>("");
  const [showTeamAddLoader, setShowTeamAddLoader] = useState<boolean>(false);

  const onShow = useCallback(() => setShowModal(true), []);

  const onHide = useCallback(() => setShowModal(false), []);

  const onCancel: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.preventDefault();
      onHide();
      setInputTeamName("");
    },
    [onHide],
  );

  const handleTeamInputChange: React.ChangeEventHandler<HTMLInputElement> =
    useCallback((event) => {
      const value = event.target.value;
      setInputTeamName(value);
    }, []);

  const handleAddTeam: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      event.preventDefault();
      if (inputTeamName.trim().length === 0) {
        pushTheToast({
          type: "danger",
          text: "Not a valid team name!",
          position: "top-right",
        });
        return;
      }

      setShowTeamAddLoader(true);
      addTeams({
        teamName: inputTeamName.trim(),
      })
        .then((res) => {
          setShowTeamAddLoader(false);
          // close the modal
          onHide();
          setTimeout(() => {
            pushTheToast({
              type: "success",
              text: "Team successfully added!",
              position: "top-right",
            });
          }, 0);
          setInputTeamName("");
          dispatch(resetTeamsData());
          // fetch all the teams after adding new
          dispatch(fetchAllTeams());
        })
        .catch((err) => {
          setShowTeamAddLoader(false);
          // close the modal
          onHide();
          setInputTeamName("");
          pushTheToast({
            type: "danger",
            text: "Team might already exists!",
            position: "top-right",
          });
        });
    },
    [dispatch, inputTeamName, onHide],
  );

  useEffect(() => {
    // reset the users search value, that is set from add member to team modal
    dispatch(setSearchValue({ searchValue: "" }));
    dispatch(resetTeamsData());
    dispatch(fetchAllTeams());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInfiniteScroll = useCallback(() => {
    if (metaData.total !== null && metaData.total > 0) {
      dispatch(fetchAllTeams());
    }
  }, [dispatch, metaData.total]);

  const hasMore = useMemo(() => {
    return fetchTeamsAjaxStatus === "rejected"
      ? false
      : metaData.total === 0
        ? true
        : teamIdList.length < metaData.total;
  }, [fetchTeamsAjaxStatus, metaData.total, teamIdList.length]);

  return (
    <div className={`main__content`}>
      <div className=" ">
        <div className="col-12">
          <div className="top-btn d-flex flex-wrap justify-content-between">
            <div className="">
              <h3 className={`${styles.page_heading} mb-0`}>Teams</h3>
              <p className={`${styles.heading_tagline} m-0 pt-1`}>
                Can create teams and assign team members
              </p>
            </div>
            <div className={`${styles.add_user} pe-5`}>
              {/* <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip className={` ${styles.toolTipCustom}`}>
                    Create a new team for your organization to manage all
                    Tickets.
                  </Tooltip>
                }
              >
                {({ ref, ...triggerHandler }) => (
                  <button
                    {...triggerHandler}
                    ref={ref}
                    type="submit"
                    className={`pink-btn-submit mt-2 mt-md-0 ${styles.btn} ${styles.pink_btn_submit} `}
                    onClick={onShow}
                  >
                    Add Team
                  </button>
                )}
              </OverlayTrigger> */}
              <PlanUpgradeOverlay
                showOverlay={disabledFeatures?.includes("add_teams") ?? false}
                message="Upgrade to add more teams"
                placement="bottom"
              >
                <button
                  type="submit"
                  className={`pink-btn-submit mt-2 mt-md-0 ${styles.btn} ${styles.pink_btn_submit} `}
                  disabled={disabledFeatures?.includes("add_teams") ?? false}
                  onClick={onShow}
                >
                  Add Team
                </button>
              </PlanUpgradeOverlay>
              {/* Add team modal */}
              <Modal
                show={showModal}
                onHide={onHide}
                centered={true}
                dialogClassName={`${styles.modalDialog}`}
                contentClassName={`${styles.modalContent}`}
                backdropClassName={`${styles.backDrop}`}
              >
                <div>
                  <div>
                    <div>
                      <div
                        className={`${styles.header} d-flex  justify-content-between align-items-center px-4 mt-3 mb-4`}
                      >
                        <h5 className={`m-2 ${styles.addTeamHead}`}>
                          Add a Team
                        </h5>
                        <span
                          onClick={onHide}
                          className={`${styles.closeButton} cursor-pointer ms-2`}
                        >
                          <i className="fa-solid fa-xmark"></i>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className=" ">
                    <div className="">
                      <div className="col-12">
                        <form className="team-form">
                          <div className="mx-4 my-2">
                            <label
                              htmlFor="exampleInputPassword1"
                              className={`ms-2 mb-3 ${styles.formLabel}`}
                            >
                              Name
                            </label>
                            <div className="input-box d-flex align-items-center">
                              <input
                                data-lpignore="true"
                                type="text"
                                placeholder="Enter team name"
                                className={`form-control ms-2 ${styles.teamInput}`}
                                id="teamName"
                                name="teamInput"
                                value={inputTeamName}
                                onChange={handleTeamInputChange}
                              />
                            </div>
                          </div>

                          <div className="d-flex flex-row-reverse  mt-4 mb-4 mx-4">
                            <div className="d-flex flex-wrap align-items-center">
                              <button
                                className={`d-flex align-items-center justify-content-center ${styles.cancelButton} ${styles.pinkColor} me-2`}
                                onClick={onCancel}
                              >
                                {" "}
                                Cancel{" "}
                              </button>
                              <button
                                className={`${styles.pink_btn_submit} px-4`}
                                onClick={handleAddTeam}
                              >
                                {showTeamAddLoader === true
                                  ? "Adding .."
                                  : "Add Team"}
                                {/* <Loader
                                  className = {styles.addTeamBtnLoader}
                                /> */}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
              {/*  End of add team modal */}
            </div>
          </div>
        </div>
      </div>

      <div
        className={`${styles.page_content} px-1 px-md-2 px-lg-5 mt-2 mt-md-3 mt-md-5`}
      >
        <div className={`${styles.team_text}`}>Choose a team</div>

        <div>
          {/* {fetchTeamsAjaxStatus == "fulfilled" && teamIdList.length != 0 &&
              (teamIdList.map(function(key, index){
                return <TeamList key = {uuidv4()}  team = {teamData[parseInt(key)]}/>;
              }))
              } */}

          <InfiniteScroll
            className={`${styles.addTeamList}`}
            loadMore={handleInfiniteScroll}
            loader={
              fetchTeamsAjaxStatus === "pending" && teamIdList.length === 0 ? (
                <div className={`d-flex flex-column m-auto ${styles.loading}`}>
                  <Loader />
                  <span className="text-center">Fetching teams...</span>
                </div>
              ) : fetchTeamsAjaxStatus === "pending" ? (
                // Loading State
                <div className={`${styles.loadingText} mt-2`}>
                  <span>Loading...</span>
                  <div>
                    <Spinner
                      className={`ms-1 ${styles.spinner}`}
                      size="sm"
                      animation="border"
                    />
                  </div>
                </div>
              ) : (
                // Load More Button
                <div
                  className={`${styles.loaderText} mt-2`}
                  onClick={handleInfiniteScroll}
                >
                  <span>Load More</span>
                  <i className="ms-1 fa-solid fa-rotate-right"></i>
                </div>
              )
            }
            errorMessage={
              metaData.total === 0 &&
              (fetchTeamsAjaxStatus === "rejected" ||
                fetchTeamsAjaxStatus === "fulfilled") ? (
                <div
                  className={`${styles.no} ${styles.teams_yet} teams-yet d-flex justify-content-center align-items-center h-100 text-center`}
                >
                  <div>
                    <div>
                      <img
                        src={noteam}
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className={`${styles.no_teams}`}>
                      <h3>No teams yet</h3>
                      <h6>You haven't created any teams yet</h6>
                    </div>
                  </div>
                </div>
              ) : undefined
            }
            initialDataLoaded={false}
            hasMore={hasMore}
          >
            {teamIdList.map((key: number | string) => {
              return (
                <TeamList
                  key={uuidv4()}
                  team={teamData[parseInt(key + "")]}
                />
              );
            })}
          </InfiniteScroll>
          <hr className={`${styles.horizonLine}`}></hr>
        </div>
      </div>
    </div>
  );
}

export default Teams;
