import { MessagesData } from "src/services/LiveChat/liveChatHistory/getLiveChatCustomerAllMessages.service";
import renderAllMessages from "../../../../../Conversation/hooks/renderAllMessages";
import styles from "../../HistoryInnerView.module.scss";
import ActiveCustomer from "./children/ActiveCustomer/ActiveCustomer";
import ShowMoreFooter from "./children/Footer/ShowMoreFooter";
import Head from "./children/Head/Head";
import ResolveHead from "./children/ResolveHead/ResolveHead";
import useChatCreated from "./hooks/useChatCreated";
import ShowLessFooter from "./children/Footer/ShowLessFooter";

export interface LiveChatHistoryCustomerDataUI {
  id: string | number;
  name: string;
  email: string;
  img?: string | null;
  isPublicAttachmentUrl?: boolean;
  isAnonymousCustomer?: boolean;
}
interface Props extends MessagesData {
  scrollToRef?: React.MutableRefObject<any>;
  customerData?: LiveChatHistoryCustomerDataUI;
  hideActionBtn?: boolean;
  showGoToChat?: boolean;
}

const ChatCreated = (messageData: Props) => {
  const {
    isResolved,
    chatRequestId,
    chatStartedOnUTC,
    customerEmail,
    messages,
    messageIds,
    ticketId,
    isFetchingMore,
    setIsResolved,
    setTicketId,
    myDivRef,
    handleShowLess,
    showMoreButton,
    showMore,
    handleShowMoreButton,
    totalCount,
  } = useChatCreated(messageData);

  return (
    /* Below html for chat started 8/28 of https://xd.adobe.com/view/ada2e565-edf0-4a1d-af65-222f43311513-f8f1/screen/ed1f381b-eb66-478d-bc27-eb1507285a28/specs/ */
    <div
      ref={messageData.scrollToRef}
      className={`pt-3 pb-4 ps-4 pe-3`}
    >
      <Head startTime={chatStartedOnUTC} />
      <div className={`${styles.chatStartMain} mt-0 ${styles.chatBox}`}>
        <div
          className={`py-2 `}
          ref={myDivRef}
        >
          <ResolveHead
            chatRequestId={chatRequestId}
            isResolved={isResolved}
            customerEmail={customerEmail}
            ticketId={ticketId}
            setIsResolved={setIsResolved}
            setTicketId={setTicketId}
            hideActionBtn={messageData.hideActionBtn}
            showGoToChat={messageData.showGoToChat}
            chatStatus={messageData.chatStatus}
            isAnonymousCustomer={
              messageData.customerData?.isAnonymousCustomer ?? false
            } //Adding flag to find ananymous user
          />
          {messageData.customerData ? (
            <ActiveCustomer
              email={messageData.customerData.email}
              imageURL={messageData.customerData.img}
              name={messageData.customerData.name}
              isPublicAttachmentUrl={messageData.isPublicAttachmentUrl}
            />
          ) : null}
          {renderAllMessages(messageIds, messages, true)}
        </div>

        {totalCount < 5 ? null : (
          <div className={`${styles.footer} pt-3 pb-2`}>
            {showMore || showMoreButton ? (
              <ShowMoreFooter
                fetchMoreMessage={handleShowMoreButton}
                isFetchingMore={isFetchingMore}
                name={messageData.customerData?.name ?? ""}
                imageURL={messageData.customerData?.img}
                isPublicAttachmentUrl={messageData.isPublicAttachmentUrl}
              />
            ) : (
              <ShowLessFooter
                handleShowLess={handleShowLess}
                name={messageData.customerData?.name ?? ""}
                imageURL={messageData.customerData?.img}
                isPublicAttachmentUrl={
                  messageData.customerData?.isPublicAttachmentUrl
                }
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatCreated;
