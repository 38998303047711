import { axiosJSON } from "src/globals/axiosEndPoints";

export interface IShopifyStore {
  integrationId: number | string;
  storeName: string;
  storeUrl: string;
  displayStoreUrl: string;
}

export interface IGetLinkableShopifyStores {
  stores: { [brand: number | string]: IShopifyStore };
  storeIds: Array<number | string>;
}

export interface IGetAllLinkableBrandParam {
  integrationId: number;
}

export async function getLinkableShopifyStores(
  payload: IGetAllLinkableBrandParam,
) {
  // Make a POST request to the API endpoint to retrieve linkable Shopify stores
  const { data: res } = await axiosJSON.post(
    "/api/chatSetting/widget/getLinkableShopifyIntegrations",
    payload,
  );
  // Check if there's an error response from the API
  if (res.err === true || res.error) {
    // Throw an error with the message from the API response or a default message if not available
    throw new Error(res.msg ?? ("Something went wrong" as string));
  }
  // Initialize object to store linkable Shopify stores and their IDs
  const ret: IGetLinkableShopifyStores = {
    stores: {},
    storeIds: [],
  };
  // Iterate through the data received from the API and populate the ret object
  res.data.forEach((store: IShopifyStore) => {
    ret.stores[store.integrationId] = store;
    ret.storeIds.push(store.integrationId);
  });

  return ret;
}
