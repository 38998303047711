import { useCallback, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import Select from "react-select";
import adminAgentRoleImg from "src/assets/images/adminAgentRole.png";
import AgentRoleImg from "src/assets/images/agentRole.png";
import leadAgentRoleImg from "src/assets/images/leadAgentRole.png";
import traineeAgentRoleImg from "src/assets/images/traineeAgentRole.png";
import viewOnlyAgentRoleImg from "src/assets/images/viewOnlyAgentRole.png";
import AxiosImg from "src/components/AxiosImg";
import IntlTelInput, {
  IntlTelInputParams,
} from "src/components/IntlTelInput/IntlTelInput";
import Loader from "src/components/Loader";
import UserAvatar from "src/components/UserAvatar";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import useLinkedSocialAccouts from "src/hooks/useLinkedSocialAccouts";
import { ITimeZoneItem } from "src/services/Settings/Timezone/fetchTimeZoneList";
import { CurrentUser } from "src/services/Settings/Users/getCurrentUser";
import { Phone } from "src/store/slices/editCustomerDetails/editCustomerDetails.declarations";
import { setCurrentUserData } from "src/store/slices/globals/globals.slice";
import {
  deleteUserProfilePic,
  fetchTimeZoneListData,
  fetchUser,
  updateUser,
  updateUserProfilePic,
} from "src/store/slices/settings/profile/profileSettings.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { getClientSystemTimeZoneName } from "src/utils/dateLibrary";
import styles from "./Profile.module.scss";

function Profile() {
  const {
    userData,
    userRoles,
    userDataAjaxStatus,
    profilePicAjaxStatus,
    fetchTimezoneListAjaxStatus,
    timezoneListData,
  } = useAppSelector((state) => state.profileSettings);
  const dispatch = useAppDispatch();
  const [editImgBtn, setImgEditBtn] = useState(false);
  const [editData, setEditData] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState<Phone | null>(null);
  const [phoneError, setPhoneError] = useState(false);
  const [selectedTimezoneOptions, setSelectedTimezoneOptions] =
    useState<any>(null);
  const [useWhichTimezoneCheck, setUseWhichTimezoneCheck] = useState<string>(
    "" as "" | "auto" | "manual",
  );

  const { data: socialLogins, handleLinkSocial } = useLinkedSocialAccouts();

  useEffect(() => {
    dispatch(fetchUser());
  }, [false]);

  const initializeState = useCallback(() => {
    if (userData !== null) {
      setFirstName(userData.firstName);
      setLastName(userData.lastName);
      setPhone(
        typeof userData.phone === "string"
          ? { countryCode: "", phoneNumber: userData.phone }
          : userData.phone,
      );
      // set the user time zone type
      if (userData.userTimezoneType) {
        setUseWhichTimezoneCheck(userData.userTimezoneType);
      }
      // set user time zone
      if (userData.userTimezone) {
        setSelectedTimezoneOptions({
          value: userData.userTimezone,
          label: userData.userTimezone,
        });
      }
    }
  }, [userData]);

  useEffect(() => {
    initializeState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const handelEditImg = useCallback(() => {
    if (profilePicAjaxStatus !== "pending" && userData !== null) {
      if (userData.profileImageUrl) {
        if (editImgBtn === false) {
          setImgEditBtn(true);
        } else {
          dispatch(updateUserProfilePic());
          setImgEditBtn(false);
        }
      } else {
        dispatch(updateUserProfilePic());
      }
    }
  }, [dispatch, editImgBtn, profilePicAjaxStatus, userData]);

  const handelDeleteImg = useCallback(() => {
    if (profilePicAjaxStatus !== "pending") {
      dispatch(deleteUserProfilePic());
      setImgEditBtn(false);
    }
  }, [dispatch, profilePicAjaxStatus]);

  const handleCancelEdit = useCallback(() => {
    initializeState();
    setEditData(false);
  }, [initializeState]);

  const handelEditData = useCallback(() => {
    if (userDataAjaxStatus === "pending" || userData === null) {
      return;
    }

    if (!editData) {
      setEditData(true);
      return;
    }

    const isFirstNameUpdated = firstName !== userData.firstName;
    const isLastNameUpdated = lastName !== userData.lastName;
    const isPhoneUpdated =
      (userData.phone === null && phone !== null) ||
      typeof userData.phone === "string" ||
      userData.phone?.phoneNumber !== phone?.phoneNumber;
    const istimezoneSelectUpdated =
      useWhichTimezoneCheck !== userData.userTimezoneType;
    const isSelectedTimezonUpdated =
      selectedTimezoneOptions.value !== userData.userTimezone;

    if (
      !isFirstNameUpdated &&
      !isLastNameUpdated &&
      !isPhoneUpdated &&
      !istimezoneSelectUpdated &&
      !isSelectedTimezonUpdated
    ) {
      setEditData(false);
      return;
    }

    if (firstName.trim() === "") {
      pushTheToast({
        type: "warning",
        text: "First name can not be empty!",
        position: "top-right",
      });

      return;
    }

    if (lastName.trim() === "") {
      pushTheToast({
        type: "warning",
        text: "Last name can not be empty!",
        position: "top-right",
      });

      return;
    }

    if (
      useWhichTimezoneCheck === "manual" &&
      selectedTimezoneOptions === null
    ) {
      // If select time zone is checked, need to select a timezone
      pushTheToast({
        type: "warning",
        text: "Timezone can not be empty!",
        position: "top-right",
      });
      return;
    }

    if (phone === null || phone.phoneNumber === "") {
      // If phone is empty
      pushTheToast({
        type: "warning",
        text: "Phone cannot be empty",
        position: "top-right",
      });
      return;
    }

    if (phoneError) {
      pushTheToast({
        type: "warning",
        text: "Please check the error for phone",
        position: "top-right",
      });
      return;
    }

    dispatch(
      updateUser({
        userId: userData.userId,
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        phone: phone,
        userTimezone:
          useWhichTimezoneCheck === "manual"
            ? selectedTimezoneOptions.value
            : getClientSystemTimeZoneName(),
        userTimezoneType: useWhichTimezoneCheck,
        callback: (user: CurrentUser) => {
          // after update is success, update global user data
          dispatch(setCurrentUserData(user));
          setEditData(false);
          pushTheToast({
            type: "success",
            text: "Changes saved successfully!",
            position: "top-right",
          });
        },
      }),
    );
  }, [
    dispatch,
    editData,
    firstName,
    lastName,
    phone,
    phoneError,
    selectedTimezoneOptions,
    useWhichTimezoneCheck,
    userData,
    userDataAjaxStatus,
  ]);

  // handle timezone option select change
  function handleTimezoneOptionSelect(data: any) {
    setSelectedTimezoneOptions(data);
  }

  // handle when use browser timezone check change
  const handleUseBrowserTimezoneCheckChange = (checkStatus: any) => {
    if (checkStatus === true) {
      setUseWhichTimezoneCheck("auto");
    }
  };

  // handle when select timezone check change
  const handleSelectTimezoneCheckChange = (checkStatus: any) => {
    if (checkStatus === true) {
      setUseWhichTimezoneCheck("manual");
    }
  };

  // fetch timezone list data
  const handleFetchTimeZoneList = () => {
    if (
      fetchTimezoneListAjaxStatus !== "fulfilled" &&
      fetchTimezoneListAjaxStatus !== "pending"
    ) {
      dispatch(fetchTimeZoneListData());
    }
  };

  return (
    <div className={`main__content`}>
      <div className="row">
        <div className="col-12 ">
          <h3 className={` ${styles.page_heading} mb-0`}>Profile Settings</h3>
          <p className={` ${styles.heading_tagline} mb-0 pt-1`}>
            Set your display name, Profile picture, new password and Signature.
          </p>
        </div>
      </div>
      {userDataAjaxStatus === "pending" ? (
        <Loader />
      ) : (
        userData !== null && (
          <div className={`${styles.page_content} px-0 px-md-5`}>
            <div className="row">
              <div className="col-12">
                <div className={`${styles.main_div}`}>
                  <div
                    className={`${styles.user_image} mx-auto rounded-circle`}
                  >
                    {profilePicAjaxStatus === "pending" && (
                      <div
                        className={`${styles.userImgSpin} d-flex flex-column justify-content-center`}
                      >
                        <Spinner
                          animation="border"
                          className={`m-auto`}
                        />
                      </div>
                    )}
                    {userData.profileImageUrl === null ? (
                      <UserAvatar
                        className={`${styles.user} rounded-circle`}
                        name={`${userData.firstName} ${userData.lastName}`}
                        size={109}
                      />
                    ) : (
                      <AxiosImg
                        className={`${styles.user} rounded-circle`}
                        url={userData.profileImageUrl}
                        isDirectURL={userData?.isPublicAttachmentUrl}
                      />
                    )}
                  </div>
                  {/* user role icons */}
                  <div className={`${styles.icon} rounded-circle`}>
                    {/* User role admin */}
                    {userData.userRole && userData.userRole === "31" && (
                      <img
                        src={adminAgentRoleImg}
                        className={`${styles.role_icon} `}
                        alt="admin"
                      />
                    )}

                    {/* User role agent */}
                    {userData.userRole && userData.userRole === "32" && (
                      <img
                        src={AgentRoleImg}
                        className={`${styles.role_icon} `}
                        alt="agent"
                      />
                    )}

                    {/* User role lead agent */}
                    {userData.userRole && userData.userRole === "33" && (
                      <img
                        src={leadAgentRoleImg}
                        className={`${styles.role_icon} `}
                        alt="lead"
                      />
                    )}

                    {/* User role trainee */}
                    {userData.userRole && userData.userRole === "34" && (
                      <img
                        src={traineeAgentRoleImg}
                        className={`${styles.role_icon} `}
                        alt="trainee"
                      />
                    )}

                    {/* User role view only */}
                    {userData.userRole && userData.userRole === "43" && (
                      <img
                        src={viewOnlyAgentRoleImg}
                        className={`${styles.role_icon} `}
                        alt="view only"
                      />
                    )}
                  </div>
                </div>
                <div className=" text-center">
                  <h5 className={`${styles.agent} mt-2`}>
                    {userData.userRole
                      ? userRoles.roles[userData.userRole].role
                      : "Role Not Given"}
                  </h5>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <p className={`${styles.edit} text-center`}>
                    Edit Profile Picture
                  </p>
                  <div
                    className={`${styles.edit_icon} rounded-circle d-flex justify-content-center align-items-center`}
                    onClick={handelEditImg}
                  >
                    <i className={`fas fa-pencil-alt ${styles.pencil}`} />
                  </div>
                  {editImgBtn && (
                    <div
                      className={`mb-3 ms-2 d-flex justify-content-center align-items-center ${styles.trashCircle}`}
                    >
                      <i
                        className="fas fa-trash cursor-pointer"
                        onClick={handelDeleteImg}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="col-12">
                <div className="row">
                  <div className="col-md-6 mt-4">
                    <label
                      htmlFor="name"
                      className={`${styles.form_label}  ${styles.required} required form-label`}
                    >
                      First Name
                    </label>
                    <input
                      data-lpignore="true"
                      type="text"
                      className={`${styles.form_control} form-control border  `}
                      placeholder="First Name"
                      required
                      readOnly={!editData}
                      value={firstName}
                      onChange={(e) => {
                        setFirstName(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-md-6 mt-4">
                    <label
                      htmlFor="name"
                      className={`${styles.form_label}  ${styles.required} required form-label`}
                    >
                      Last Name
                    </label>
                    <input
                      data-lpignore="true"
                      type="text"
                      className={`${styles.form_control} form-control border`}
                      placeholder="Last Name"
                      required
                      readOnly={!editData}
                      value={lastName}
                      onChange={(e) => {
                        setLastName(e.target.value);
                      }}
                    />
                  </div>
                  <div className="mt-4 col-md-12">
                    <label
                      htmlFor="email"
                      className={`${styles.form_label} form-label`}
                    >
                      Email Address
                    </label>
                    <div className={`position-relative`}>
                      <input
                        data-lpignore="true"
                        type="email"
                        className={`${styles.form_control} form-control border `}
                        defaultValue={userData.email}
                        disabled
                      />
                      <span className={`${styles.banIcon}`}>
                        <i className="fa-solid fa-ban"></i>
                      </span>
                    </div>
                  </div>
                  <div className="mt-4 col-md-12">
                    <div>
                      <label
                        htmlFor="phone"
                        className={`${styles.form_label} form-label`}
                      >
                        Contact Number
                      </label>
                      <IntlTelInput
                        phoneNumber={phone?.phoneNumber ?? ""}
                        countryCode={phone?.countryCode ?? ""}
                        disabled={!editData}
                        showError={editData}
                        className={`${styles.form_control}`}
                        initialValidate={true}
                        onChange={(params: IntlTelInputParams) => {
                          setPhone({
                            phoneNumber: params.phoneNumber,
                            countryCode: params.countryData.dialCode,
                          });

                          setPhoneError(!params.isValidNumber);
                        }}
                      >
                        {(params) => {
                          setPhoneError(!params.isValidNumber);

                          return (
                            <small className={`${styles.errorMsg}`}>
                              {editData && params.errorMessage
                                ? params.errorMessage
                                : ""}
                            </small>
                          );
                        }}
                      </IntlTelInput>
                      {/* <input
                        data-lpignore="true"
                        type="tel"
                        className={`${styles.form_control} form-control border `}
                        placeholder="Phone"
                        readOnly={!editData}
                        value={phone}
                        onChange={(e) => {
                          setPhone(e.target.value);
                        }}
                      /> */}
                    </div>
                  </div>
                  <div className="mt-4 mb-3 col-md-12">
                    <div className={` ${styles.wrap_select_arrow} `}>
                      <label
                        htmlFor="exampleSelect1"
                        className={`${styles.form_label} form-label`}
                      >
                        Role
                      </label>
                      <div className={`${styles.select_width}`}>
                        <div
                          className={`${styles.form_control} form-control  border`}
                        >
                          {userData.userRole
                            ? userRoles.roles[userData.userRole].role
                            : "Role Not Given"}
                        </div>
                        <div className={`${styles.select_arrow}`}>
                          <div className={`${styles.arrow_up}`}></div>
                          <div className={`${styles.arrow_down}`}></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* To update timezone */}
                  <div className="mt-4 mb-3 col-md-12">
                    <div className={` ${styles.wrap_select_arrow} `}>
                      <label
                        htmlFor="exampleSelect1"
                        className={`${styles.form_label} form-label`}
                      >
                        Profile Time Zone
                      </label>
                      <div className="form-check">
                        <input
                          data-lpignore="true"
                          className={`form-check-input ${styles.radioBtn}`}
                          type="radio"
                          checked={useWhichTimezoneCheck === "auto"}
                          onClick={(e: any) => {
                            handleUseBrowserTimezoneCheckChange(
                              e.target.checked,
                            );
                          }}
                          disabled={!editData}
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                        />
                        <label
                          className={`form-check-label ${styles.radioLabel}`}
                          // htmlFor="flexRadioDefault1"
                        >
                          Automatically choose my browser or system’s timezone.
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          data-lpignore="true"
                          className={`form-check-input ${styles.radioBtn}`}
                          type="radio"
                          disabled={!editData}
                          checked={useWhichTimezoneCheck === "manual"}
                          onClick={(e: any) => {
                            handleSelectTimezoneCheckChange(e.target.checked);
                          }}
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                        />
                        <label
                          className={`form-check-label ${styles.radioLabel}`}
                          // htmlFor="flexRadioDefault1"
                        >
                          {
                            "Always use the following timezone regardless of my browser or system’s timezone."
                          }
                        </label>
                      </div>
                      <div className="my-3">
                        <Select
                          options={
                            timezoneListData && timezoneListData.data
                              ? timezoneListData.data.map(
                                  (timeZoneItem: ITimeZoneItem) => {
                                    return {
                                      label: timeZoneItem.label,
                                      value: timeZoneItem.timezone,
                                    };
                                  },
                                )
                              : []
                          }
                          placeholder="Search your timezone"
                          isLoading={fetchTimezoneListAjaxStatus === "pending"}
                          onMenuOpen={handleFetchTimeZoneList}
                          value={selectedTimezoneOptions}
                          onChange={handleTimezoneOptionSelect}
                          isSearchable={true}
                          isDisabled={
                            !editData || useWhichTimezoneCheck !== "manual"
                          }
                          styles={customStyles}
                        />
                      </div>
                    </div>
                  </div>
                  {/* Link Social accounts */}
                  {socialLogins?.length && socialLogins.length > 0 ? (
                    <div className="mt-2 mb-3 col-md-12">
                      <label
                        className={`${styles.form_label} form-label`}
                        // htmlFor="flexRadioDefault1"
                      >
                        Linked Accounts
                      </label>
                      <div className="d-flex flex-column  mt-1 pt-1">
                        {socialLogins?.map((socialLogin, index) => {
                          return (
                            <div
                              key={index}
                              className={`d-flex mb-3 align-items-center`}
                            >
                              <div className={`${styles.socialLogin}`}>
                                <img
                                  src={socialLogin.icon}
                                  alt=""
                                  className="me-2"
                                />
                                <span>{socialLogin.name}</span>
                              </div>
                              {!socialLogin.account ? (
                                <div
                                  key={index}
                                  className={`${styles.socialBtn} cursor-pointer`}
                                  onClick={() => {
                                    if (socialLogin.isPublicLink) {
                                      window.location.href = `${
                                        process.env.REACT_APP_SITE_URL ?? ""
                                      }${socialLogin.route}`;
                                    } else {
                                      handleLinkSocial(socialLogin.route);
                                    }
                                  }}
                                >
                                  + Connect
                                </div>
                              ) : (
                                <div
                                  className={`d-flex ${styles.socialAccount}`}
                                >
                                  <div className="">
                                    {socialLogin.account.avatar ? (
                                      <img
                                        src={socialLogin.account.avatar}
                                        alt=""
                                        className="me-2"
                                      />
                                    ) : (
                                      <UserAvatar
                                        name={socialLogin.account.name ?? "NA"}
                                        size={23}
                                      />
                                    )}
                                  </div>
                                  <span>
                                    {socialLogin.account.name ?? "NA"}
                                  </span>
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : null}
                  <div>
                    <button
                      type="button"
                      className={` ${styles.pink_btn_submit}  ${styles.btn} mb-3 `}
                      onClick={handelEditData}
                    >
                      {!editData ? "Edit" : "Save"}
                    </button>
                    {editData && (
                      <button
                        type="button"
                        className={`${styles.pink_btn_submit} ${styles.cancel} ${styles.btn} mx-2 mb-3 `}
                        onClick={() => {
                          handleCancelEdit();
                        }}
                      >
                        Cancel
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
}

const customStyles = {
  option: (provided: any, _: any) => ({
    ...provided,
    textAlign: "left",
    font: "normal normal 500 12px/18px Poppins",
    letterSpacing: "0px",
    color: "#000000",
    "&:hover": {
      background: "#F9F9F9 0% 0% no-repeat padding-box",
      borderRadius: "4px",
    },
    "&:active": {
      background: "#EEEEEE 0% 0% no-repeat padding-box",
    },
  }),
  control: (provided: any, _: any) => ({
    ...provided,
    margin: "0px",
    textAlign: "left",
    font: "normal normal normal 12px/18px Poppins",
    letterSpacing: "0px",
  }),
};

export default Profile;
