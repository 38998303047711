import { BsExclamationDiamondFill } from "react-icons/bs";
import styles from "./IntegrationFailureText.module.scss";
import { IntegrationFailureMetaData } from "src/types/IntegrationFailure";
import { useState } from "react";
import IntegrationFailureModal from "../../IntegrationFailureModal";

const IntegrationFailureText = ({
  integrationFailure,
}: {
  integrationFailure: IntegrationFailureMetaData | null;
}) => {
  const [showCannotSendModal, setShowCannotSendModal] = useState(false);
  return (
    <div>
      <div className={styles.integrateText}>
        <span className={`px-1 ${styles.exclamation}`}>
          <BsExclamationDiamondFill />
        </span>
        <span
          className="cursor-pointer"
          onClick={() => setShowCannotSendModal(true)}
        >
          Integration not available
        </span>
      </div>
      {integrationFailure && (
        <IntegrationFailureModal
          show={showCannotSendModal}
          onHide={() => setShowCannotSendModal(false)}
          data={integrationFailure}
        />
      )}
    </div>
  );
};

export default IntegrationFailureText;
