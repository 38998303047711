import { axiosJSON } from "src/globals/axiosEndPoints";
import {
  conditionType,
  GetOptionsConditionsParam,
} from "../Step9/step9GetOptionsConditions";

export async function fetchGetStep10ConditionsOptions(
  params: GetOptionsConditionsParam,
) {
  const { data } = await axiosJSON.get(
    "/api/setting/automation/refund/step/10/conditionOptions",
    { params },
  );

  if (data.err === true) {
    throw new Error("Some Error Occurred while fetching data");
  }

  return {
    conditions: data.data?.conditions as conditionType[],
    itemConditions: data.data?.itemConditions as conditionType[],
    defaultConditions: data?.data.defaultConditions as conditionType[],
  };
}
