import { GetConfigModalParams } from "src/features/ReturnAutoWorkFlow/children/ConfigModals/oldReturnModals.types";
import { axiosJSON } from "src/globals/axiosEndPoints";
import { ReturnMethodTab } from "./getReturnMethod.service";

interface Payload extends GetConfigModalParams {
  configTabs: ReturnMethodTab[];
}

async function updateReturnMethodService(payload: Payload) {
  const { data: res } = await axiosJSON.post(
    "/api/setting/automation/refund/step/14/configuration",
    payload,
  );

  if (res.err || res.error) {
    throw new Error(res.msg ?? res.message ?? "Some Error Occurred.");
  }
}

export default updateReturnMethodService;
