import { axiosJSON } from "src/globals/axiosEndPoints";
import { BrandData } from "./getAllBrands";

export interface BrandAgent {
  id: number | string;
  name: string;
  email: string;
  roleId: number | string;
  roleName: string;
  enabled: boolean;
  profileImageUrl: null | string;
  ticketCount?: number;
  isPublicAttachmentUrl?: boolean;
}

export interface BrandWithAgents {
  brandData: BrandData;
  users: { [brand: number | string]: BrandAgent };
  userIds: Array<number | string>;
  metaData: {
    count: number;
    total: number;
  };
}

export interface GetBrandPayload {
  id: number | string;
  signatureType: "filtered" | "unfiltered";
}

export async function getBrandById(payload: GetBrandPayload) {
  const { data: res } = await axiosJSON.post("/api/brand/getBrand", payload);

  if (res.err) {
    throw new Error(res.msg);
  }

  return res.data.brandData as BrandData;
}
