import useUTCToUserTime from "src/hooks/dateHooks/useUTCToUserTime";
import styles from "./TicketEventMsg.module.scss";

interface ITicketEventMsg {
  message: string;
  time: string;
}

const TicketEventMsg = ({ message, time }: ITicketEventMsg) => {
  const generatedTime = useUTCToUserTime({ timeInUTC: time });
  return (
    <div className={`${styles.eventMainDiv}`}>
      <div className={`d-flex justify-content-center ${styles.mainDiv}`}>
        {/* <div className={`${styles.mainDiv}`}> */}

        <p className={`${styles.mainPara}`}>
          <span className={`${styles.blueDot} mx-2`}></span>
          <span className={`${styles.eventMsgParaTxt}`}>{message},</span>
          <span className={`${styles.eventMsgParaTxtDate} px-2`}>
            {generatedTime}
          </span>
        </p>
      </div>
    </div>
  );
};

export default TicketEventMsg;
