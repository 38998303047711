/**
 * This file contains the custom hook for handling KB settings.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { useParams } from "react-router-dom";

/**
 * Custom hook for managing the ComponentModal component in KB settings.
 */
export const useCommentModal = () => {
  const { integrationId } = useParams();
  const pageIntegrationId = integrationId
    ? decodeURIComponent(integrationId)
    : "";

  // Returning the pageIntegrationId after decoding it from integrationId in params.
  return {
    pageIntegrationId,
  };
};
