import styles from "./TicketRouteModal.module.scss";
import AssignBox from "src/routes/BotSettings/Children/Automation/TicketRoute/Children/Components/AssignBox/AssignBox";
import { useQuery } from "@tanstack/react-query";
import {
  ActionGroup,
  Group,
  IAutomationData,
  ICheckListOption,
  getAutomationService,
} from "src/services/Automation/getAutomationById.service";
import { EAutomationType } from "src/routes/BotSettings/Children/AutomationList/AutomationList";
import Loader from "src/components/Loader";

const TicketRouteModal = ({
  ruleId,
  ruleName,
  onHide,
}: {
  ruleId: string;
  ruleName: string;
  onHide: () => void;
}) => {
  console.log(ruleId);
  const { data, isLoading, error } = useQuery<IAutomationData, Error>({
    queryKey: ["getAutomationService", ruleId],
    queryFn: () => {
      if (ruleId) {
        return getAutomationService({
          payload: {
            integrationId: ruleId,
          },
          type: EAutomationType.TICKET_ROUTING,
        });
      }

      throw new Error("Integration ID is missing");
    },
  });

  if (isLoading) {
    return (
      <div className={`p-3 ${styles.settingWrap}`}>
        <div
          className={`${styles.closeModal}`}
          onClick={onHide}
        >
          <i className="fa-solid fa-xmark"></i>
        </div>
        <Loader />
      </div>
    );
  }

  if (error) {
    return (
      <div className={`p-3 ${styles.settingWrap}`}>
        <div
          className={`${styles.closeModal}`}
          onClick={onHide}
        >
          <i className="fa-solid fa-xmark"></i>
        </div>
        <div className="d-flex align-items-center justify-content-center text-danger">
          {error.message ?? "Could not fetch Automation..."}
        </div>
      </div>
    );
  }

  const triggerQuestion = Object.values(data?.questionsData ?? []).find(
    (question) => question.questionKey === "triggers",
  );
  const allowedActionsQuestion = Object.values(data?.questionsData ?? []).find(
    (question) => question.questionKey === "triggeredBy",
  );
  const actionsQuestion = Object.values(data?.questionsData ?? []).find(
    (question) => question.questionKey === "actions",
  );
  const conditionGroupQuestion = Object.values(data?.questionsData ?? []).find(
    (question) => question.questionKey === "conditionGroups",
  );
  const getSelectedTriggerOption = () => {
    return (triggerQuestion?.listOfOptions as ICheckListOption[])?.find(
      (option: ICheckListOption) => {
        return option.optionKey === triggerQuestion?.questionValue;
      },
    )?.optionLabel;
  };
  const getAllowedActions = () => {
    return (allowedActionsQuestion?.listOfOptions as ICheckListOption[])
      ?.filter((option: ICheckListOption) =>
        (allowedActionsQuestion?.questionValue as string[]).includes(
          option.optionKey,
        ),
      )
      .map((option) => option.optionLabel);
  };

  return (
    <div className="position-relative">
      <div
        className={`${styles.closeModal}`}
        onClick={onHide}
      >
        <i className="fa-solid fa-xmark"></i>
      </div>
      <div className={`px-1 ${styles.contentWrapper}`}>
        <div className={`d-flex justify-content-between align-items-center`}>
          <span className={`${styles.heading}`}>
            Ticket routing - Automation Summary
          </span>
        </div>
        <div>
          <p className={`${styles.assignTicket}`}>{ruleName}</p>
          <AssignBox
            selectedTrigger={getSelectedTriggerOption() ?? ""}
            allowedActions={getAllowedActions() ?? []}
            conditionGroups={
              (conditionGroupQuestion?.conditionGroups as Group[]) ?? []
            }
            actionGroups={
              (actionsQuestion?.conditionGroups as ActionGroup[]) ?? []
            }
            integrationId={ruleId}
            showEdit={false}
          />
        </div>
      </div>
    </div>
  );
};

export default TicketRouteModal;
