import { axiosJSON } from "src/globals/axiosEndPoints";


export interface DeleteWebIntegrationParams {
  integrationId: number | string;
}

export async function deleteWebIntegration (params : DeleteWebIntegrationParams) {

    const { data:res } = await axiosJSON.delete(
        "/api/chatSetting/widget/removeIntegration",{
        params
      }
    );

    if(res.err === true )
    {
      throw new Error(res.msg as string)
    }

    return res.data; 
  } 
