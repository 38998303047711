import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";

export interface DeleteSchedulePayload {
  id: number;
}

const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/schedule/delete`
  : "/api/liveChat/agent/message/schedule/delete";

export const deleteScheduleLiveChat = async (params: DeleteSchedulePayload) => {
  const { data: res } = await axiosJSON.delete(apiEndPoint, { params });

  if (res.err) {
    throw res.msg;
  }

  return res;
};
