import styles from "../../LiveChat.module.scss";
import ToggleCustomerSB, {
  getChatIdRouteLink,
} from "../ToggleCustomerSB/ToggleCustomerSB";
import LiveChatShoppify from "../LiveShoppify/LiveChatShoppify";
import { useEffect, useMemo, useRef, useState } from "react";
import LiveCustomerSB from "../LiveCustomerSB/LiveCustomerSB";
import ChatInfo from "./Children/ChatInfo";
import ChatTabs, { LiveMainChatTabs } from "./Children/ChatTabs";
import ChatSection from "./Children/ChatSection";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { fetchCustomerData } from "src/store/slices/liveChatSetting/chatWidExchCust/chatWidExchCust.thunks";
import { Modal, Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { actions } from "src/store/slices/liveChatSetting/chatWidExchCust/chatWidExchCust.slice";
import Loader from "src/components/Loader";
import { RouteNames } from "src/containers/SideBar/SideBar";
import { useCheckErrorInConversation } from "./Children/Conversation";
import traffic_Empty from "src/assets/images/traffic_Empty.png";
import ChatNotFound from "src/components/ChatNotFound/ChatNotFound";
import BrandInfo from "./Children/BrandInfo/BrandInfo";
import useAgentStatusChannel from "src/hooks/useAgentStatusChannel";
import BotProfileInfo from "./Children/BotProfileInfo/BotProfileInfo";

const RSBComponent = ({
  showRSBDetails,
  setShowRSBDetails,
  showLSBDetails,
}: any) => {
  const { tabNameMainChat } = useParams();
  const onHide = () => {
    setShowRSBDetails(false);
  };
  if (tabNameMainChat === "history") {
    return (
      <Modal
        show={showRSBDetails}
        onHide={onHide}
        contentClassName={`${styles.modalContentHistory}`}
        centered={true}
      >
        <LiveChatShoppify
          showRSBDetails={showRSBDetails}
          setShowRSBDetails={onHide}
          showLSBDetails={showLSBDetails}
        />
      </Modal>
    );
  } else if (showRSBDetails) {
    return (
      <div
        className={`${styles.liveChatShoppify} ${
          showLSBDetails ? "" : styles.mini
        } overflow-auto px-3 py-3`}
      >
        <LiveChatShoppify
          showRSBDetails={showRSBDetails}
          setShowRSBDetails={setShowRSBDetails}
          showLSBDetails={showLSBDetails}
        />
      </div>
    );
  } else {
    return <></>;
  }
};

function MainChat() {
  const [searchText, setSearchText] = useState("");
  const { chatId, chatStatus, tabNameMainChat } = useParams();
  const [showRSBDetails, setShowRSBDetails] = useState(
    tabNameMainChat !== "history",
  );
  const [showLSBDetails, setShowLSBDetails] = useState(true);
  //custom hook to subscribe to pusher channel when live chat window initialized
  //used for detecting agent away status
  const agentStatusChannel = useAgentStatusChannel();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { activeCustomerInfo } = useAppSelector((state) => state.chatWidExhMsg);
  const [
    customerData,
    fetchCustAJAXStatus,
    loading,
    initilized,
    chatIdList,
    selectedCustomerStatus,
    selectedCustomerChatId,
    chatMetaData,
    messageIdList,
  ] = useAppSelector((state) => {
    return [
      Object.values(state.chatWidExchCust.customers),
      state.chatWidExchCust.fetchCustAJAXStatus,
      state.chatWidExchCust.loading,
      state.chatWidExchCust.initialized,
      state.chatWidExchCust.chatIdList,
      state.chatWidExchCust.selectedCustomerStatus,
      state.chatWidExchCust.selectedCustomerChatId,
      state.chatWidExchCust.metaData,
      state.chatWidExhMsg.messageIdList,
    ];
  });
  const currentState = useRef({
    selectedCustomerStatus,
    selectedCustomerChatId,
  });
  const errorInCustFetchOrLoading = useMemo(() => {
    return (
      (fetchCustAJAXStatus !== "fulfilled" &&
        chatIdList.length === 0 &&
        messageIdList.length === 0) ||
      (chatMetaData.limitReached == undefined &&
        !activeCustomerInfo &&
        messageIdList.length === 0) ||
      activeCustomerInfo == null
    );
  }, [
    fetchCustAJAXStatus,
    chatIdList,
    chatMetaData.limitReached,
    activeCustomerInfo,
    messageIdList.length,
  ]);
  const { customers } = useAppSelector((state) => state.chatWidExchCust);

  useEffect(() => {
    //resetting the filters
    dispatch(actions.resetAllFilters());
    dispatch(actions.resetCustomersData({ loading: true, initialized: false }));
    dispatch(actions.setSearchValue({ searchValue: "" }));
    if (chatStatus === "missed") {
      dispatch(actions.selectCustomerStatus({ customerStatus: "missed" }));
    } else if (chatStatus === "archived") {
      dispatch(actions.selectCustomerStatus({ customerStatus: "archived" }));
    } else {
      dispatch(actions.selectCustomerStatus({ customerStatus: "live" }));
    }
    dispatch(fetchCustomerData({ start: 0, initialFetch: true }));
    return () => {
      dispatch(
        actions.resetCustomersData({ loading: true, initialized: false }),
      );
      dispatch(actions.setSearchValue({ searchValue: "" }));
      dispatch(actions.selectCustomerStatus({ customerStatus: "live" }));
      //resetting the filters
      dispatch(actions.resetAllFilters());
    };
  }, [false]);

  useEffect(() => {
    if (chatIdList.length > 0) {
      dispatch(
        actions.resetInitializedStatus({ loading: false, initialized: true }),
      );
    }
    let currentChatStatus = initilized === false ? chatStatus : "all";
    if (chatIdList) {
      //for initial loading if there is no chat id found then it will assign the first chat in the chat list
      if (
        !chatId ||
        chatId == "all" ||
        currentChatStatus === undefined ||
        currentChatStatus === "all"
      ) {
        if (chatIdList && chatIdList.length !== 0) {
          let newChatId: any = chatId;
          if (!chatId || chatId == "all") {
            newChatId = chatIdList[0];
            dispatch(actions.setActiveChatId({ id: chatIdList[0] }));
          } else if (!currentState.current.selectedCustomerChatId) {
            // This is added to fix the state reset removing selectedCustomerChatId
            dispatch(actions.setActiveChatId({ id: chatId }));
          }

          if (currentChatStatus === undefined || currentChatStatus === "all") {
            currentChatStatus = selectedCustomerStatus;
          }

          navigate(
            chatId && chatId != "" && chatStatus && chatStatus.trim() != ""
              ? window.location.pathname.replace(
                  `chats/${chatStatus}/${chatId}`,
                  `chats/${currentChatStatus}/${newChatId}`,
                )
              : `${RouteNames.liveChat}/chats/${currentChatStatus}/${newChatId}`,
          );
        }
      } else {
        dispatch(actions.setActiveChatId({ id: chatId }));
      }
    }
    currentState.current.selectedCustomerStatus = selectedCustomerStatus;
  }, [chatIdList, selectedCustomerStatus, initilized]);

  useEffect(() => {
    if (
      chatStatus &&
      chatStatus !== "all" &&
      initilized === true &&
      currentState.current.selectedCustomerStatus !== chatStatus
    ) {
      dispatch(
        actions.resetCustomersData({ loading: false, initialized: true }),
      );
      if (chatStatus === "missed") {
        dispatch(actions.selectCustomerStatus({ customerStatus: "missed" }));
      } else if (chatStatus === "archived") {
        dispatch(actions.selectCustomerStatus({ customerStatus: "archived" }));
      } else {
        dispatch(actions.selectCustomerStatus({ customerStatus: "live" }));
      }
      dispatch(fetchCustomerData({ start: 0 }));
    }
  }, [initilized, chatStatus]);

  useEffect(() => {
    if (tabNameMainChat === "history") {
      setShowRSBDetails(false);
    } else {
      setShowRSBDetails(true);
    }
  }, [tabNameMainChat]);

  return (
    <>
      {customerData.length === 0 &&
      fetchCustAJAXStatus === "pending" &&
      loading === true ? (
        <>
          <div className="d-flex justify-content-center w-100">
            <Loader />
          </div>
        </>
      ) : (
        <>
          {(fetchCustAJAXStatus === "fulfilled" ||
            fetchCustAJAXStatus === "rejected") &&
          customerData.length === 0 &&
          initilized === false ? (
            <div className="d-flex justify-content-center align-items-center w-100">
              <ChatNotFound
                text={
                  fetchCustAJAXStatus === "rejected"
                    ? "We were unable to load this section. Please try again by reloading the page."
                    : "No Chat Found"
                }
                img={traffic_Empty}
              />
            </div>
          ) : (
            (fetchCustAJAXStatus === "fulfilled" || initilized === true) && (
              <>
                <div
                  className={`${styles.liveChatSBResponsiveFill} ${
                    showLSBDetails ? "" : styles.mini
                  }`}
                ></div>
                <div
                  className={`${styles.liveChatSB} ${
                    showLSBDetails ? "" : styles.mini
                  } d-flex`}
                >
                  <div
                    className={`m-0 w-100 ${
                      showLSBDetails ? "" : "overflow-auto"
                    }`}
                  >
                    {showLSBDetails === true ? (
                      <LiveCustomerSB
                        searchText={searchText}
                        setSearchText={setSearchText}
                      />
                    ) : (
                      <ToggleCustomerSB
                        searchText={searchText}
                        setSearchText={setSearchText}
                      />
                    )}
                  </div>
                  <div className={`m-auto w-0 h-0`}>
                    <div
                      className={`m-auto d-flex ${styles.toggleLSB} cursor-pointer`}
                      onClick={() => {
                        setShowLSBDetails(!showLSBDetails);
                      }}
                    >
                      <i
                        className={`m-auto fa-solid fa-chevron-${
                          showLSBDetails ? "left" : "right"
                        }`}
                      ></i>
                    </div>
                  </div>
                </div>
                <div
                  className={`d-flex ${styles.innerContainer} ${
                    showLSBDetails ? "" : styles.mini
                  }`}
                >
                  <div
                    className={`${styles.mainLiveChat} ${
                      showRSBDetails &&
                      tabNameMainChat !== "history" &&
                      !errorInCustFetchOrLoading &&
                      chatIdList.length !== 0
                        ? ""
                        : "w-100"
                    } ${showLSBDetails ? "" : styles.mini}`}
                  >
                    {!errorInCustFetchOrLoading ? (
                      <>
                        <div className="d-flex justify-content-between">
                          <ChatInfo />
                          <div className="d-flex flex-column mt-2">
                            <BrandInfo />
                            {/* <BotProfileInfo /> */}
                          </div>
                        </div>
                        <ChatTabs
                          showRSBDetails={
                            showRSBDetails && !errorInCustFetchOrLoading
                          }
                          setShowRSBDetails={setShowRSBDetails}
                        />
                      </>
                    ) : (
                      ""
                    )}

                    <ChatSection />
                  </div>
                  {!errorInCustFetchOrLoading && (
                    <RSBComponent
                      showRSBDetails={showRSBDetails}
                      setShowRSBDetails={setShowRSBDetails}
                      showLSBDetails={showLSBDetails}
                    />
                  )}
                </div>
              </>
            )
          )}
        </>
      )}
    </>
  );
}

export default MainChat;
