import styles from "./NoData.module.scss";

const NoData = ({ configureRule }: { configureRule: () => void }) => {
  return (
    <div className={`text-center ${styles.contentWrapper}`}>
      <h3 className={`${styles.heading}`}>
        No dispatching rules created. Start creating one.
      </h3>
      <button
        className={`me-1 ${styles.configureBtn} px-3`}
        onClick={configureRule}
      >
        <span>+ Create new dispatching rules</span>
      </button>
    </div>
  );
};

export default NoData;
