import BugCategory from "./BugCategory/BugCategory";
import Priority from "./Priority/Priority";
import StatusFilter from "./StatusFilter/StatusFilter";

interface Props {
  addFilters: (
    selectedStatus: Array<string | undefined>,
    selectedPriority: Array<string | undefined>,
    selectedCategory: Array<string>,
  ) => void;
}

const FilterSection = ({ addFilters }: Props) => {
  return (
    <div className="d-flex align-items-center flex-column flex-md-row">
      <BugCategory addFilters={addFilters} />
      <StatusFilter addFilters={addFilters} />
      <Priority addFilters={addFilters} />
    </div>
  );
};

export default FilterSection;
