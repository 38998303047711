/**
 * This file contains the custom hook for handling KB settings.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { useInfiniteQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useDebounce from "src/hooks/useDebounce";
import {
  GetAllArticleFeedbackPayload,
  getAllArticleFeedback,
} from "src/services/KnowledgeBase/Settings/Feedback/getAllArticleFeedback.service";

// The useApproveFeedback function is defined as the main hook function.
function useApproveFeedback() {
  // States And Variables
  // The useParams hook from react-router-dom is used to get the integrationId from the URL params.
  const { integrationId } = useParams();
  const pageIntegrationId = integrationId
    ? decodeURIComponent(integrationId)
    : "";
  const [searchTerm, setSearchTerm] = useState("");

  const [payload, setPayload] = useState<GetAllArticleFeedbackPayload>({
    integrationId: pageIntegrationId,
    start: 0,
    limit: 10,
  });

  // Calling API to get all feedbacks thorugh useInfiniteQuery hook for infinite scrolling
  const { data, isLoading, hasNextPage, fetchNextPage, refetch, isRefetching } =
    useInfiniteQuery({
      queryKey: ["getAllArticleFeedbacks", payload],
      queryFn: ({ pageParam = payload }) => getAllArticleFeedback(pageParam),
      getNextPageParam: (lastPage: any, allPages) => {
        const data = allPages.flatMap((data: any) => data.data); // flatMap is used to flatten the array of arrays
        // If the data length is less than the total count then return the next page param
        if (data.length < lastPage.metaData.totalCount) {
          const nextPageParam = {
            ...payload,
            start: data.length, // setting the start to the length of the data
          };
          return nextPageParam;
        }
        return null;
      },
    });

  const debouncedSearchTerm = useDebounce(searchTerm, 500); // Debouncing the search term

  // Changing the payload on debounced search term change
  useEffect(() => {
    setPayload({
      ...payload,
      searchTerm: debouncedSearchTerm ? debouncedSearchTerm : undefined,
    });
  }, [debouncedSearchTerm]);

  //  Function to call on search
  function onSearch() {
    refetch();
  }

  // Getting the feedbacks from the data and flatmapping it
  const feedbacks = data?.pages.flatMap((data: any) => data.data);

  return {
    feedbacks,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isRefetching,
    searchTerm,
    setSearchTerm,
    onSearch,
  };
}

export default useApproveFeedback;
