import axios from "axios";
import { axiosJSON } from "src/globals/axiosEndPoints";
import APIResponse from "./APIReponse";

/*export interface GetTeam {
    teamId : number;
    teamName : string;
    email : string;
    userRole : number;
    profileImageUrl : URL
    total : number;
    count : number;
}*/

interface User {
  userId: string | number,
  name: string,
  email: string,
  userRole: number,
  profileImageUrl: URL
}

export interface GetTeam {
  // users: { [userId: string | number]: User },
  users: any;
  userIds: Array<number | string>;
  teamId : number;
  teamName : string;
  count: number,
  totalCount: number
}

export interface GetTeamParams {
  teamId : number;
  start : number;
  limit : number;
  searchTerm?: string
}



export async function getTeam (params : GetTeamParams) {
  

    const { data: res }: any = await axiosJSON.post<APIResponse<GetTeam>>(
      "/api/setting/team/get",
        params
      
    );

    if(res.error === true )
    {
      throw new Error(res.message as string)
    }

    const ret: GetTeam = {
      teamId: 0,
      teamName: "",
      users: {},
      userIds: [],
      count: 0,
      totalCount: 0
    }

    ret.teamId = res.data.teamId;
    ret.teamName = res.data.teamName;
    ret.count = res.count;
    ret.totalCount = res.total;
    res.data.users.forEach((user: User) => {
      ret.users[user.userId] = user;
      ret.userIds.push(user.userId);
    });

    // // console.log("getAllMembersInTeams service res:::: ", JSON.stringify(res));
    // console.log("getAllMembersInTeams service ret:::: ", JSON.stringify(ret));

    return ret; 
  } 
