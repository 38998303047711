import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import {
  createContext,
  useContext,
  useReducer,
  Dispatch,
  ReactNode,
  useEffect,
} from "react";
import {
  IBillingDetails,
  getBillingDetails,
} from "src/services/Billing/getBillingDetails";

// Define action types as constants
export enum ActionType {
  SetBillingDetails = "SET_BILLING_DETAILS",
  SetLoading = "SET_LOADING",
  SetError = "SET_ERROR",
}

interface SetBillingDetailsAction {
  type: ActionType.SetBillingDetails;
  payload: IBillingDetails;
}

interface SetLoadingAction {
  type: ActionType.SetLoading;
  payload: boolean;
}

interface SetErrorAction {
  type: ActionType.SetError;
  payload: string | null;
}

type BillingAction =
  | SetBillingDetailsAction
  | SetLoadingAction
  | SetErrorAction;

interface BillingContextType {
  billingDetails: IBillingDetails | null;
  isLoading: boolean;
  error: string | null;
  dispatch: Dispatch<BillingAction>;
}

// Define the initial state
const initialState: BillingContextType = {
  billingDetails: null,
  isLoading: false,
  error: null,
  dispatch: () => {},
};

// Create the reducer function
const billingReducer = (
  state: BillingContextType,
  action: BillingAction
): BillingContextType => {
  switch (action.type) {
    case ActionType.SetBillingDetails:
      return { ...state, billingDetails: action.payload };
    case ActionType.SetLoading:
      return { ...state, isLoading: action.payload };
    case ActionType.SetError:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

const BillingContext = createContext<BillingContextType>(initialState);

export const useBillingContext = () => useContext(BillingContext);

interface BillingProviderProps {
  children: ReactNode;
}

export function BillingProvider({ children }: BillingProviderProps) {
  const [state, dispatch] = useReducer(billingReducer, initialState);

  const { isLoading: queryLoading } = useQuery(
    ["Setting/billingDetails"],
    getBillingDetails,
    {
      /**
       * Query function to show error
       */
      onError: (error) => {
        const err = error as null | string | AxiosError;
        dispatch({
          type: ActionType.SetError,
          payload:
            typeof err === "string" ? err : err === null ? err : err.message,
        });
      },
      /**
       * Query Function to assign data
       */
      onSuccess: (data: IBillingDetails) => {
        dispatch({ type: ActionType.SetBillingDetails, payload: data });
      },
      /**
       * Query Function to hide loader
       */
      onSettled: () => {
        dispatch({ type: ActionType.SetLoading, payload: false });
      },
    }
  );

  /**
   * Effect for initializing Loader
   */
  useEffect(() => {
    dispatch({ type: ActionType.SetLoading, payload: queryLoading });
  }, [queryLoading]);

  // // Handle query error
  // useEffect(() => {
  //   const err = queryError as null | string | AxiosError;
  //   if (err) {
  //     dispatch({
  //       type: ActionType.SetError,
  //       payload: typeof err === "string" ? err : err.message,
  //     });
  //   }
  // }, [queryError]);

  const contextValue: BillingContextType = {
    ...state,
    dispatch,
  };

  return (
    <BillingContext.Provider value={contextValue}>
      {children}
    </BillingContext.Provider>
  );
}
