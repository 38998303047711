import { useMutation } from "@tanstack/react-query";
import { useCallback, useEffect } from "react";
import { setUserTicketColumns } from "src/services/TicketService/setUserTicketColumns";
import { saveCurrentViewColumns } from "src/store/slices/globals/globals.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";

export const useSetMoreColumnsMutation = () => {
  const outerTicketColumn = useAppSelector(
    (state) => state.globals.currentUserData?.outerTicketColumn
  );
  const currentUserId = useAppSelector(
    (state) => state.globals.currentUserData?.userId
  );
  const dispatch = useAppDispatch();

  const setMoreColumnsMutation = useMutation({
    mutationFn: setUserTicketColumns,
    onSuccess: (data, variables, context) => {
      // console.log("Success: successfully updated");
    },
    onError: (error, variables, context) => {
      // console.log("Error: failed to updated column");
    },
  });

  const updateTicketColumns = useCallback(
    async ({
      tableType,
      allViewSelectedCols,
    }: {
      tableType: string;
      allViewSelectedCols: any;
    }) => {
      try {
        const payload = {
          agentId: currentUserId + "",
          columnsData: "",
        };

        const savedUserTicketColumns: {
          [key: string]: { allViewSelectedCols: any };
        } = outerTicketColumn ? JSON.parse(outerTicketColumn) : {};

        if (
          savedUserTicketColumns[tableType] &&
          JSON.stringify(
            savedUserTicketColumns[tableType].allViewSelectedCols
          ) === JSON.stringify(allViewSelectedCols)
        ) {
          return;
        }

        savedUserTicketColumns[tableType] = {
          allViewSelectedCols: allViewSelectedCols,
        };

        payload.columnsData = JSON.stringify(savedUserTicketColumns);

        dispatch(saveCurrentViewColumns(savedUserTicketColumns));

        await setMoreColumnsMutation.mutateAsync(payload);
        // Mutation completed successfully
      } catch (error) {
        // An error occurred during the mutation
      }
    },
    [currentUserId, outerTicketColumn]
  );

  return { updateTicketColumns };
};
