import { useEffect, useState } from "react";
import styles from "./Step1.module.scss";
import { useSwiper } from "swiper/react";

/**
 * Component for showing Step 1 of Ticket routing automation steps
 */
function Step1(props: { setBackBtn: Function }) {
  const swiper = useSwiper();
  const [activeIndex, setActiveIndex] = useState(0);

  swiper.on("slideChange", (swiper) => {
    setActiveIndex(swiper.activeIndex);
  });

  useEffect(() => {
    if (activeIndex == 0) {
      props.setBackBtn(true);
    } else {
      props.setBackBtn(false);
    }
  }, [activeIndex]);

  return (
    <div className={`${styles.stepWrapper}`}>
      <span className={` ${styles.stepsContent} text-center`}>
        <p className={`mb-1 ${styles.heading}`}>
          We will now begin configuring each step of the process.
        </p>
        <p>
          {" "}
          Press <span>"Next Step"</span> when ready.
        </p>
      </span>
    </div>
  );
}

export default Step1;
