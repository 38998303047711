import { axiosJSON } from "src/globals/axiosEndPoints";

export interface Tag {
  id: number | string;
  tagName: string;
  tagDescription: string;
  tagCreatedDate: Date;
  tagUpdatedDate: Date;
  tagDeleted: number;
}

export interface AllTags {
  tagIdList: Array<number | string>;
  tags: { [tagId: number | string]: Tag };
  metaData: {
    total: number;
    count: number;
  };
}

export interface GetAllTagParams {
  limit: number;
  start: number;
  searchTerm?: string;
}

export async function getAllTag(params: GetAllTagParams) {
  const { data: res }: any = await axiosJSON.get("/api/tags/getAllTags", {
    params,
  });

  if (res.error === true) {
    throw new Error(res.message as string);
  }
  const ret: AllTags = {
    tagIdList: [],
    tags: {},
    metaData: { total: res.total, count: res.count },
  };
  res.tags.forEach((tag: Tag) => {
    ret.tagIdList.push(tag.id);
    ret.tags[tag.id] = tag;
  });
  return ret;
}
