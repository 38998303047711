/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import AxiosImg from "src/components/AxiosImg";
import UserAvatar from "src/components/UserAvatar";
import { useAppDispatch, useAppSelector } from "src/store/store";
import styles from "./AssignChatFilter.module.scss";
import AssignChatToAgentList from "./Children/AssignChatToAgentList/AssignChatToAgentList";
import { actions } from "src/store/slices/liveChatSetting/chatWidExchCust/chatWidExchCust.slice";

export const renderAssingedAgentInfo = (assignedAgents: Array<any>) => {
  if (assignedAgents.length > 2) {
    const assignedAgent = assignedAgents[0];
    return (
      <>
        <span className={`position-relative ${styles.agentImg}`}>
          {assignedAgent.imgUrl && assignedAgent.imgUrl.length !== 0 ? (
            <AxiosImg
              url={assignedAgent.imgUrl}
              className={`${styles.profileImg}`}
              isDirectURL={assignedAgent.isPublicAttachmentUrl}
            />
          ) : (
            <UserAvatar
              name={
                assignedAgent.name && assignedAgent.name !== ""
                  ? assignedAgent.name
                  : "NA"
              }
              size={22}
              className={`img-fluid`}
            />
          )}
        </span>
        <span className={`position-relative ${styles.chatImg}`}>
          <span
            className={`d-flex align-items-center justify-content-center ${styles.agentImg} ${styles.agentImg2} ${styles.plusImageIcon}`}
          >
            <span>+{assignedAgents.length - 1}</span>
          </span>
        </span>
      </>
    );
  } else if (assignedAgents.length === 2) {
    return (
      <>
        {assignedAgents.map((assignedAgent: any, index: number) => {
          return (
            <span
              className={`d-block position-relative ${styles.agentImg} ${
                index !== 0 ? styles.agentImg2 : ""
              }`}
            >
              {assignedAgent.imgUrl && assignedAgent.imgUrl.length !== 0 ? (
                <AxiosImg
                  url={assignedAgent.imgUrl}
                  className={`${styles.profileImg}`}
                  isDirectURL={assignedAgent.isPublicAttachmentUrl}
                />
              ) : (
                <UserAvatar
                  name={
                    assignedAgent.name && assignedAgent.name !== ""
                      ? assignedAgent.name
                      : "NA"
                  }
                  size={28}
                  className={`img-fluid`}
                />
              )}
            </span>
          );
        })}
      </>
    );
  } else {
    const assignedAgent = assignedAgents[0];
    return (
      <span className={`position-relative ${styles.agentImg}`}>
        {assignedAgent.imgUrl && assignedAgent.imgUrl.length !== 0 ? (
          <AxiosImg
            url={assignedAgent.imgUrl}
            className={`${styles.profileImg}`}
            isDirectURL={assignedAgent.isPublicAttachmentUrl}
          />
        ) : (
          <UserAvatar
            name={
              assignedAgent.name && assignedAgent.name !== ""
                ? assignedAgent.name
                : "NA"
            }
            size={28}
            className={`img-fluid`}
          />
        )}
      </span>
    );
  }
};

function AssignChatFilter() {
  const { activeCustomerInfo, selectedAgentIds } = useAppSelector(
    (state) => state.chatWidExhMsg
  );

  const { chatStatus } = useParams();

  const [showSaveLoader, setShowSaveLoader] = useState(false);
  const [searchText, setSearchText] = useState("");
  const dispatch = useAppDispatch();

  const targetRef = useRef(null);
  const currentState = useRef({
    activeCustomerInfo,
    selectedAgentIds,
    chatStatus,
  });

  useEffect(() => {
    currentState.current = {
      activeCustomerInfo,
      selectedAgentIds,
      chatStatus,
    };
  }, [activeCustomerInfo, selectedAgentIds, chatStatus]);

  const idFilterApplied = useAppSelector(
    (state) => state.chatWidExchCust.idFilterApplied
  );

  const selectedAgentIdsForFilter = useAppSelector(
    (state) => state.chatWidExchCust.selectedAgentIdsForFilter
  );

  const selectedAgentForFilterInfo = useAppSelector((state) =>
    state.chatWidExchCust.selectedAgentIdsForFilter.flatMap((selectedId) => {
      const agent = state.chatWidExchCust.agents[selectedId];
      const agentInfo = [];
      if (agent) {
        agentInfo.push({
          email: agent.email,
          id: agent.id,
          name: agent.name,
          imgUrl: agent.imageURL,
        });
      }
      return agentInfo;
    })
  );

  const concatinateAgentNames = useCallback((assignedAgents: any) => {
    return assignedAgents
      .map((agentInfo: any) => agentInfo.name ?? "NA")
      .join(", ");
  }, []);

  function clearAllAssigneFilter() {
    dispatch(actions.removeAllAgentForAssignToFilter({ initialValue: [] }));
  }

  return (
    <div ref={targetRef} className={`py-2 ${styles.filterBtn}`}>
      <div className={`${styles.assignToMenu} d-flex `}>
        <div className={`${styles.leftSection}`}>
          <div className={`d-flex position-relative ${styles.assignee}`}>
            {selectedAgentForFilterInfo &&
            selectedAgentForFilterInfo.length !== 0 ? (
              <div className="d-flex flex-shrink-0 position-relative">
                {renderAssingedAgentInfo(selectedAgentForFilterInfo)}
              </div>
            ) : (
              <span
                className={`position-relative text-truncate ${styles.chatFilter}`}
              >
                Filter by chat assignee
              </span>
            )}
          </div>
          <span className={`my-auto ms-3 me-2 ${styles.assignedAgentsName}`}>
            {selectedAgentForFilterInfo && selectedAgentForFilterInfo.length
              ? concatinateAgentNames(selectedAgentForFilterInfo)
              : ""}
          </span>
          {selectedAgentForFilterInfo &&
            selectedAgentForFilterInfo.length !== 0 && (
              <>
                {/* show this clear all button when filter is applied  */}
                <span
                  className={`d-block ${styles.clearAll}`}
                  onClick={clearAllAssigneFilter}
                  role="button"
                >
                  <span className="pe-1">
                    <i className="fa-solid fa-xmark"></i>
                  </span>
                  Clear all
                </span>
              </>
            )}
        </div>
        {/* <div className={`${styles.moreUser}`}>
          <span>+5</span>
        </div> */}
        {/* <i
          className={`fa-solid d-block m-auto fa-caret-${
            showAgentModal ? "up" : "down"
          } ${styles.arrowSpan}`}
        ></i> */}
      </div>

      <div className={` ${styles.relative} `} style={{ height: "200px" }}>
        <AssignChatToAgentList
          targetRef={targetRef}
          searchText={searchText}
          setSearchText={setSearchText}
          showSaveLoader={showSaveLoader}
          setShowSaveLoader={setShowSaveLoader}
          // handleOnApply={handleOnApply}
          // handleCancel={handleCancel}
          setShowAgentModal={true}
        />
      </div>
      {/* <Modal
        className={`shadow-sm p-3 rounded h-auto
        // show={true}
        onHide={onHide}
        dialogClassName={`${styles.modalDialog2}`}
        contentClassName={`${styles.modalContent2}`}
        backdropClassName={`${styles.modalBackDrop}`}
        centered={true}
        enforceFocus={false}
      >
        <div className={`text-center `}>
          <h3 className={`mb-2 mb-lg-3 ${styles.modalHead}`}>
            Someone is already assigned to this conversation
          </h3>
          <span className={`d-inline-block mb-2 ${styles.modalSpan}`}>
            Would you like to add more agents to this conversation ?
          </span>
          <div
            className={`d-flex flex-column flex-md-row justify-content-center align-items-center mt-3`}
          >
            <button
              className={`bg-white me-0 me-md-4 mb-2 mb-md-0 px-4 py-1 ${styles.cancelBtn}`}
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button
              className={`border-0 px-4 py-1 ${styles.saveBtn}`}
              onClick={handleSubmit}
            >
              {showSaveLoader === true ? (
                <Spinner
                  className="my-auto mx-1"
                  animation="border"
                  color="white"
                  size="sm"
                />
              ) : (
                "Assign"
              )}
            </button>
          </div>
        </div>
      </Modal> */}
    </div>
  );
}
export default AssignChatFilter;
