import {
  createAsyncThunk,
  createSelector,
  createSlice,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import axios from "axios";
import { loadavg } from "os";
import { axiosJSON } from "src/globals/axiosEndPoints";
import {
  getBrands,
  getBrandSignature,
  BrandAndSignature,
} from "src/services/Settings/EmailSignature/getBrandSignature";
import { CurrentUser } from "src/services/Settings/Users/getCurrentUser";
import { RootState } from "src/store/store";
import { getClientSystemTimeZoneName } from "src/utils/dateLibrary";

export type GlobalsType = {
  currentUserData: CurrentUser | null;
  brandSignatureData: {
    brands: null | Array<BrandAndSignature>;
    loading: boolean;
  };
  countryList: {
    data: Array<CountryItem> | null;
    loading: boolean;
  };
  unreadChatIds: number; //Array<number|string>;
  selfServiceSidebar: {
    bugManagementTotalTicket: number;
  };
};

export interface CountryItem {
  name: string;
  dial_code: string;
  code: string;
  alpha2Code: string;
  flag_unicode: string;
}

export interface BrandSignature {
  brandSignatureValue: string;
  brandSignatureEnabled: boolean;
  loading: boolean;
}

const initialState: GlobalsType = {
  currentUserData: null,
  brandSignatureData: {
    brands: null,
    loading: false,
  },
  countryList: {
    data: [],
    loading: false,
  },
  unreadChatIds: 0, //[],
  selfServiceSidebar: {
    bugManagementTotalTicket: 0
  }
};

export const getBrandAndSignatureThunk = createAsyncThunk(
  "globals/getBrandAndSignatureThunk",
  async (payload: undefined, { dispatch, getState }) => {
    const res = await getBrands();
    return res;
  },
);

export const getCountryList = createAsyncThunk(
  "globals/getCountryList",
  async (payload: undefined, { dispatch, getState }) => {
    try {
      const { data } = await axiosJSON.get(`/api/customer/countryList`);

      return data.countryList as CountryItem[];
    } catch (error) {
      console.log(error);

      isRejectedWithValue(error);
    }
  },
);

export const getCountryListV2 = createAsyncThunk(
  "globals/getCountryListV2",
  async (payload: undefined, { dispatch, getState }) => {
    try {
      const { data } = await axiosJSON.get(`/api/customer/countryListV2`);

      return data.countryList as CountryItem[];
    } catch (error) {
      console.log(error);

      isRejectedWithValue(error);
    }
  },
);

export const globalsSlice = createSlice({
  name: "globals",
  initialState,
  reducers: {
    setCurrentUserData: (
      state,
      { payload }: { payload: CurrentUser | null },
    ) => {
      state.currentUserData = payload;
      // if userTimezoneType is auto, get the client/ browser timezone and update the global data
      /*let userData = {...payload};
      if(userData !== null && userData.userTimezoneType === "auto"){
        let clientTimezone = getClientSystemTimeZoneName();
        userData.userTimezone = clientTimezone;
      }
      state.currentUserData = userData as CurrentUser;*/
    },
    saveCurrentViewColumns: (
      state,
      { payload }: { payload: { [key: string]: any } },
    ) => {
      if (state.currentUserData) {
        state.currentUserData.outerTicketColumn = JSON.stringify(payload);
      }
    },
    setUnreadChatIdsForTitle: (
      state,
      { payload }: { payload: { chatIds: number } }, //Array<number|string>
    ) => {
      state.unreadChatIds = payload.chatIds;
    },
    updateCurrentUserData: (
      state, // The current state object
      { payload }: { payload: Partial<CurrentUser> }, // Action payload containing partial user data
    ) => {
      // Check if current user data exists in the state
      if (state.currentUserData) {
        // Update the current user data by merging existing data with the payload
        state.currentUserData = {
          ...state.currentUserData, // Preserve existing properties
          ...payload, // Apply changes from the payload
        };
      }
    },
    updateCurrentUserDataDisabledFeatures: (
      state, // The current state object
      {
        payload,
      }: { payload: { disabledFeatures: CurrentUser["disabledFeatures"] } }, // Action payload containing partial user data
    ) => {
      // Check if current user data exists in the state
      if (state.currentUserData) {
        if (!state.currentUserData.disabledFeatures) {
          state.currentUserData.disabledFeatures = payload.disabledFeatures;
        } else {
          const updatedDisabledFeatures =
            state.currentUserData.disabledFeatures;
          payload.disabledFeatures?.forEach((feature) => {
            if (!updatedDisabledFeatures.includes(feature)) {
              updatedDisabledFeatures.push(feature);
            }
          });

          state.currentUserData.disabledFeatures = updatedDisabledFeatures;
        }
      }
    },
    setbugManagementTotalTicket: (
      state,
      { payload }: { payload: number },
    ) => {
      if (state.selfServiceSidebar) {
        // Update the bug management total ticket
        state.selfServiceSidebar.bugManagementTotalTicket = payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getBrandAndSignatureThunk.fulfilled,
      (state, { payload }) => {
        state.brandSignatureData = { brands: payload, loading: false };
      },
    );
    builder.addCase(getBrandAndSignatureThunk.rejected, (state) => {
      state.brandSignatureData.loading = false;
    });

    builder.addCase(getCountryList.fulfilled, (state, { payload }) => {
      if (payload) state.countryList = { data: payload, loading: false };
    });

    builder.addCase(getCountryList.rejected, (state) => {
      state.countryList.loading = false;
    });

    builder.addCase(getCountryListV2.fulfilled, (state, { payload }) => {
      if (payload) state.countryList = { data: payload, loading: false };
    });

    builder.addCase(getCountryListV2.rejected, (state) => {
      state.countryList.loading = false;
    });
  },
});

export const getSingleBrandData = (data: any, email: string) => {
  if (data === null && !email) {
    return null;
  } else {
    let valueS = null as null | BrandAndSignature;
    data?.forEach((value: any) => {
      if (value.email === email) {
        valueS = value;
        return valueS;
      }
    });
    return valueS;
  }
};

export const {
  setCurrentUserData,
  saveCurrentViewColumns,
  setUnreadChatIdsForTitle,
  updateCurrentUserData,
  updateCurrentUserDataDisabledFeatures,
  setbugManagementTotalTicket,
} = globalsSlice.actions;

export default globalsSlice.reducer;
