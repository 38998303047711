import React from "react";
import { createRoot, Root } from "react-dom/client";
import { Popover } from "react-bootstrap";
import tinymce from "tinymce";
import { CustomAutoCompleteOptions } from "src/interfaces/TinyMCEContainer/ITinyMCEContainer";
import { Editor, EditorEvent } from "tinymce";

class AutoComplete {
  editor: Editor;
  options: CustomAutoCompleteOptions;
  query: string;
  hasFocus: boolean;
  popoverContainer: HTMLSpanElement | null;
  popoverRoot: Root | null;
  currentDelimiter: string | null;

  editorKeyUpProxy: (e: KeyboardEvent) => void;
  editorKeyDownProxy: (e: KeyboardEvent) => void;
  editorClickProxy: (e: MouseEvent) => void;
  bodyClickProxy: (e: MouseEvent) => void;
  bodyContentChange: (e: EditorEvent<any>) => void;
  rteScroll: () => void;

  constructor(
    ed: Editor,
    options: CustomAutoCompleteOptions,
    currentDelimiter: string,
  ) {
    this.editor = ed;
    this.options = options;

    this.query = "";
    this.hasFocus = true;
    this.popoverContainer = null;
    this.popoverRoot = null;
    this.currentDelimiter = currentDelimiter;

    this.editorKeyUpProxy = this.rteKeyUp.bind(this);
    this.editorKeyDownProxy = this.rteKeyDown.bind(this);
    this.editorClickProxy = this.rteClicked.bind(this);
    this.bodyClickProxy = this.rteLostFocus.bind(this);
    this.rteScroll = () => this.cleanUp(true);
    this.bodyContentChange = this.onContentChange.bind(this);

    this.renderInput();
    this.bindEvents();
  }

  renderInput() {
    if (this.currentDelimiter) {
      const rawHtml = `<span id="custom-autocomplete-1">${this.currentDelimiter}</span>`;
      this.editor.execCommand("mceInsertContent", false, rawHtml);
      this.editor.focus();
    }
  }

  bindEvents() {
    this.editor.on("keyup", this.editorKeyUpProxy);
    this.editor.on("change", this.bodyContentChange);
    this.editor.on("keydown", this.editorKeyDownProxy, true);
    this.editor.on("click", this.editorClickProxy);
    document.body.addEventListener("click", this.bodyClickProxy);
    this.editor.getWin().addEventListener("scroll", this.rteScroll);
  }

  unbindEvents() {
    this.editor.off("keyup", this.editorKeyUpProxy);
    this.editor.off("change", this.onContentChange);
    this.editor.off("keydown", this.editorKeyDownProxy);
    this.editor.off("click", this.editorClickProxy);
    document.body.removeEventListener("click", this.bodyClickProxy);
    const editorWindow = this.editor.getWin();
    if (editorWindow) {
      editorWindow.removeEventListener("scroll", this.rteScroll);
    }
  }

  rteKeyUp(e: KeyboardEvent) {
    const target = this.editor
      .getBody()
      .querySelector("#custom-autocomplete-1") as HTMLElement;
    if (!target) return;

    switch (e.key) {
      case "ArrowDown":
      case "ArrowUp":
      case "Shift":
      case "Control":
      case "Alt":
        break;
      case "Backspace":
        if (target.textContent?.trim() === "") {
          this.cleanUp(true);
        } else {
          this.lookup();
        }
        break;
      case "Enter":
        if (!this.isPopoverVisible()) {
          this.cleanUp(true);
        } else {
          e.preventDefault();
          e.stopPropagation();
        }
        break;
      case "Tab":
        // case " ":
        this.cleanUp(true);
        break;
      case "Escape":
        if (!this.isPopoverVisible()) {
          this.cleanUp(true);
        } else {
          e.preventDefault();
          e.stopPropagation();
        }
        break;
      default:
        this.lookup();
    }
  }

  rteKeyDown(e: KeyboardEvent) {
    switch (e.key) {
      case "Tab":
      case "Enter":
        if (!this.isPopoverVisible()) {
          this.cleanUp(true);
        } else {
          e.preventDefault();
          e.stopPropagation();
        }
        break;
      case "Escape":
        // e.preventDefault();
        if (!this.isPopoverVisible()) {
          this.cleanUp(true);
        } else {
          e.preventDefault();
          e.stopPropagation();
        }
        break;
    }
    e.stopPropagation();
  }

  onContentChange(e: EditorEvent<any>) {
    const target = this.editor
      ?.getBody()
      ?.querySelector("#custom-autocomplete-1") as HTMLElement;
    if (!target) this.cleanUp(false);
  }

  rteClicked(e: MouseEvent) {
    const target = e.target as HTMLElement;
    if (this.hasFocus && !this.isInsidePopover(target)) {
      this.cleanUp(true);
    }
  }

  rteLostFocus(e: MouseEvent) {
    const target = e.target as HTMLElement;
    if (this.hasFocus && !this.isInsidePopover(target)) {
      this.cleanUp(true);
    }
  }

  isInsidePopover(element: HTMLElement): boolean {
    if (!this.popoverContainer) {
      return false;
    }
    return this.popoverContainer.contains(element);
  }

  async lookup() {
    this.query =
      (
        this.editor
          ?.getBody()
          ?.querySelector("#custom-autocomplete-1") as HTMLElement
      )?.textContent
        ?.trim()
        .replace("\ufeff", "") || "";

    if (this.query) {
      try {
        const data = await this.options.delimiters[
          this.currentDelimiter!
        ].fetchOptions(this.query);
        if (!data) {
          throw new Error("no data found");
        }
        this.renderComponent(this.query, data);
      } catch (error) {
        console.error("Failed to fetch data:", error);
        this.cleanUp(false);
      }
    } else {
      this.cleanUp(false);
    }
  }

  select({
    value,
    elementSelector,
  }: {
    value: string;
    elementSelector?: string;
  }) {
    this.editor.focus();
    const span = this.editor.dom.select("span#custom-autocomplete-1");

    if (span) {
      this.editor.dom.remove(span[0]);
      if (elementSelector) {
        const element = this.editor.dom.get(elementSelector);

        if (element) {
          this.editor.dom.remove(element);
        }
      }
    }
    this.editor.execCommand("mceInsertContent", false, value);
  }

  renderComponent(match: string, data: any) {
    if (!this.popoverContainer) {
      this.popoverContainer = document.createElement("span");
      this.popoverContainer.className = "custom-autocomplete-popover";
      document.body.appendChild(this.popoverContainer);
    }

    const Component =
      this.options.delimiters[this.currentDelimiter!].renderComponent;
    const popover = (
      <Popover
        id="custom-autocomplete-popover"
        style={{
          height: "auto",
          margin: 0,
          padding: 0,
          border: "none",
          outline: "none",
        }}
        arrowProps={{ style: { display: "none" } }}
      >
        <Popover.Body
          style={{
            height: "auto",
            margin: 0,
            padding: 0,
            border: "none",
            outline: "none",
          }}
        >
          <Component
            data={data}
            match={match}
            cleanUp={(rollback: boolean) => this.cleanUp(rollback)}
            popoverContainer={this.popoverContainer}
            select={({
              value,
              elementSelector,
            }: {
              value: string;
              elementSelector?: string;
            }) => this.select({ value, elementSelector })}
            editor={this.editor}
          />
        </Popover.Body>
      </Popover>
    );

    const target = this.editor
      .getDoc()
      ?.getElementById("custom-autocomplete-1");
    if (!target) {
      console.error("Target span element not found.");
      return;
    }

    const targetRect = target.getBoundingClientRect();

    const iframeElement = this.editor.getWin().frameElement;
    if (!iframeElement) {
      console.error("Iframe element not found.");
      return;
    }

    const iframeRect = iframeElement.getBoundingClientRect();

    this.popoverContainer.style.position = "absolute";
    this.popoverContainer.style.zIndex = "130";

    const margin = 10; // Adjust this value as needed to add some space between the popover and the text
    this.popoverContainer.style.bottom = `${
      document.body.getBoundingClientRect().bottom -
      (iframeRect.top + targetRect.top) +
      margin
    }px`;
    this.popoverContainer.style.left = `${iframeRect.left + targetRect.left}px`;

    if (!this.popoverRoot) {
      this.popoverRoot = createRoot(this.popoverContainer);
    }
    this.popoverRoot.render(popover);
  }

  cleanUp(rollback: boolean) {
    this.unbindEvents();
    this.hasFocus = false;

    if (this.popoverContainer) {
      if (this.popoverRoot) {
        this.popoverRoot.unmount();
        this.popoverRoot = null;
      }
      this.popoverContainer.remove();
      this.popoverContainer = null;
    }

    if (rollback) {
      const text = this.query;
      const selection = this.editor.dom.select("span#custom-autocomplete-1");
      if (!selection.length) {
        return;
      }

      const spanElement = selection[0];
      const parent = spanElement.parentNode as Element;

      if (parent && parent.nodeName === "P") {
        const spanText = spanElement.innerHTML;
        parent.innerHTML = parent.innerHTML.replace(
          spanElement.outerHTML,
          spanText,
        );

        const node = this.editor.selection?.getNode();

        if (node) {
          const focus =
            node.offsetTop ===
            spanElement.offsetTop +
              (spanElement.offsetHeight - spanElement.clientHeight) / 2;

          if (focus) {
            if (parent) {
              this.editor.selection.select(parent);
              this.editor.selection.collapse(); // Collapse to the end
            }
          }
        }
      } else {
        const spanText = spanElement.innerHTML;
        parent.innerHTML = parent.innerHTML.replace(
          spanElement.outerHTML,
          spanText,
        );

        const node = this.editor.selection?.getNode();

        if (node) {
          const focus =
            node.offsetTop ===
            spanElement.offsetTop +
              (spanElement.offsetHeight - spanElement.clientHeight) / 2;

          if (focus) {
            if (parent) {
              this.editor.selection.select(parent);
              this.editor.selection.collapse(); // Collapse to the end
            }
          }
        }
      }
    }
  }
  //  method to get the visibility of the popover
  isPopoverVisible(): boolean {
    return (
      this.popoverContainer !== null &&
      this.popoverContainer.style.display !== "none"
    );
  }

  // method to update the visibility of the popover
  setPopoverVisibility(visible: boolean) {
    if (visible) {
      if (this.popoverContainer)
        this.popoverContainer.style.display = "inline-block";
    } else {
      if (this.popoverContainer) this.popoverContainer.style.display = "none";
    }
  }
}

tinymce.PluginManager.add("mention", function (editor) {
  let autoComplete: AutoComplete | undefined;
  let isEventBound = false;
  let autoCompleteData = editor.getParam("mentions", undefined);

  // if (!autoCompleteData) {
  //   return;
  // }

  function prevCharIsSpace() {
    const start = editor.selection.getRng().startOffset;
    const text = (editor.selection.getRng().startContainer as any).data || "";
    const charachter = text.substr(start > 0 ? start - 1 : 0, 1);

    return !!charachter.trim().length ? false : true;
  }

  function keypressHandler(e: EditorEvent<KeyboardEvent>) {
    const delimiter = e.key;
    if (
      autoCompleteData?.delimiters &&
      autoCompleteData.delimiters[delimiter] &&
      prevCharIsSpace()
    ) {
      if (
        autoComplete === undefined ||
        (autoComplete.hasFocus !== undefined && !autoComplete.hasFocus)
      ) {
        e.preventDefault();
        autoComplete = new AutoComplete(
          editor,
          {
            delimiters: autoCompleteData.delimiters,
          },
          delimiter,
        );
      }
    }
  }

  // Add a custom event to enable/disable the plugin
  editor.on("ToggleCustomMentionPlugin", function (e) {
    if (e.enabled) {
      autoCompleteData = e.autoCompleteData;
      if (!isEventBound) {
        editor.on("keypress", keypressHandler);
        isEventBound = true;
      }
    } else {
      autoCompleteData = e.autoCompleteData;
      if (isEventBound) {
        editor.off("keypress", keypressHandler);
        isEventBound = false;
      }
    }
  });
});
