import { useCallback, useMemo, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import addImapDetailsService, {
  AddImapParams,
} from "src/services/EmailIntegration/addImapDetails.service";
import addSmtpDetailsService, {
  AddSmtpParams,
} from "src/services/EmailIntegration/addSmtpDetails.service";
import {
  MailTypeEnum,
  actions,
} from "src/store/slices/emailIntegration/emailIntegration.slices";
import { useAppDispatch, useAppSelector } from "src/store/store";
import SetupComplete from "../SetupComplete/SetupComplete";
import styles from "./EmailAddress.module.scss";
import GmailStepsImap from "./children/GmailStepsImap/GmailStepsImap";

interface Props {
  type: "outgoing" | "incoming";
  isStepDone: boolean;
}

/**
 * React component for configuring email address settings.
 */
const EmailAddress = ({ type, isStepDone }: Props) => {
  const dispatch = useAppDispatch();

  const {
    outgoingMailSettings,
    incomingMailSettings,
    emailIntegrationId,
    mailType,
  } = useAppSelector((state) => state.emailIntegration.selectedUser);

  /**
   * Retrieves email settings based on the type.
   */
  const settings = useMemo(() => {
    // Outgoing Type settings defaults
    if (type === "outgoing") {
      return {
        username: outgoingMailSettings.loginDetails?.username ?? "",
        password: outgoingMailSettings.loginDetails?.password ?? "",
        host: outgoingMailSettings.smtpDetails?.smtpHost ?? "",
        port: outgoingMailSettings.smtpDetails?.smtpPort ?? "",
        useSSL: outgoingMailSettings.smtpDetails?.smtpUseSSL ?? false,
      };
    }
    // Incoming Type settings defaults
    return {
      username: incomingMailSettings.loginDetails?.username ?? "",
      password: incomingMailSettings.loginDetails?.password ?? "",
      host: incomingMailSettings.imapDetails?.imapHost ?? "",
      port: incomingMailSettings.imapDetails?.imapPort ?? "",
      useSSL: incomingMailSettings.imapDetails?.imapUseSSL ?? false,
    };
  }, [outgoingMailSettings, incomingMailSettings, type]);

  // Manages the form data
  const [formData, setFormData] = useState(settings);

  // Manages the loading state
  const [loading, setLoading] = useState(false);

  // Manages the verification failure state
  const [verifyFailed, setVerifyFailed] = useState(false);

  const [showModal, setShowModal] = useState<boolean>(false);

  /**
   * Memoized variable for showing porting details
   */
  const showPortingDetails = useMemo(() => {
    if (
      type === "incoming" &&
      (mailType.id === MailTypeEnum.gmail ||
        incomingMailSettings.imapDetails === undefined)
    ) {
      return false;
    }

    if (
      type === "outgoing" &&
      (mailType.id === MailTypeEnum.gmail ||
        outgoingMailSettings.smtpDetails === undefined)
    ) {
      return false;
    }
    return true;
  }, [
    mailType.id,
    incomingMailSettings.imapDetails,
    outgoingMailSettings.smtpDetails,
    type,
  ]);

  // Checks if the form is validated
  const isFormValidated = useMemo(() => {
    if (showPortingDetails && (formData.host === "" || formData.port === "")) {
      return false;
    }
    if (formData.password === "" || formData.username === "") {
      return false;
    }
    return true;
  }, [
    formData.host,
    formData.password,
    formData.port,
    formData.username,
    showPortingDetails,
  ]);

  /**
   * Handles form submission.
   */
  const submitHandler = useCallback(async () => {
    // Return if form is not validated
    if (!isFormValidated) {
      return;
    }
    try {
      // Initialize loader
      setLoading(true);
      if (type === "incoming") {
        let payload: AddImapParams = {
          emailIntegrationId,
          imapUsername: formData.username,
          imapPassword: formData.password,
          imapHost: formData.host,
          imapPort: formData.port + "",
          imapUseSSL: formData.useSSL,
        };

        if (mailType.id === MailTypeEnum.gmail) {
          delete payload.imapHost;
          delete payload.imapPort;
          delete payload.imapUseSSL;
        }

        const res = await addImapDetailsService(payload);

        // Check if we got auth failed status code
        if (!res) {
          setVerifyFailed(true);
          return;
        }

        // Set email is verified
        dispatch(actions.emailVerified());
      } else {
        let payload: AddSmtpParams = {
          emailIntegrationId,
          smtpUsername: formData.username,
          smtpPassword: formData.password,
          smtpHost: formData.host,
          smtpPort: formData.port + "",
          smtpUseSSL: formData.useSSL,
        };
        if (mailType.id === MailTypeEnum.gmail) {
          delete payload.smtpHost;
          delete payload.smtpPort;
          delete payload.smtpUseSSL;
        }

        const res = await addSmtpDetailsService(payload);

        // Check if we got auth failed status code
        if (!res) {
          setVerifyFailed(true);
          return;
        }

        // Set domain is verified
        dispatch(actions.domainVerified());

        setShowModal(true);
      }
    } catch (e) {
      const err = e as Error;
      pushTheToast({
        position: "top-right",
        text: err?.message ?? "Some error occured",
        type: "danger",
      });
    } finally {
      setLoading(false);
    }
  }, [
    mailType.id,
    dispatch,
    emailIntegrationId,
    formData,
    isFormValidated,
    type,
  ]);

  const onChangeHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    },
    [],
  );

  return (
    <div className={`ms-2 ${styles.container} mt-4`}>
      <div className={`pb-3`}>
        {/* Porting Details */}
        {showPortingDetails && mailType.id !== MailTypeEnum.gmail && (
          <div className="mt-3">
            {/* Porting Details Heading */}
            <div className={`${styles.topContainer} mb-3`}>
              <p>
                {type === "incoming"
                  ? "Incoming mail settings"
                  : "Outgoing mail address"}
              </p>
            </div>
            {/* Porting Details */}
            <div
              className={`d-flex flex-column flex-md-row  mt-3 ${styles.bottomContainer}`}
            >
              {/* Host Name */}
              <div className="me-2 mb-2 mb-md-0">
                <p className={`mb-2 ${styles.labelHead}`}>
                  {type === "incoming"
                    ? "IMAP server name"
                    : "SMTP server name"}
                </p>
                <input
                  type="text"
                  value={formData.host}
                  disabled={isStepDone}
                  onChange={onChangeHandler}
                  placeholder="Eg. map.secureserver.net"
                  name="host"
                  className={`${styles.inputText} ${styles.serverInput} form-control`}
                />
              </div>

              <div className="mx-2 w-100">
                {/* Port Number */}
                <p className={`mb-2 ${styles.labelHead}`}>
                  {type === "incoming" ? "IMAP port" : "SMTP port"}
                </p>
                <div className="d-flex">
                  <input
                    type="text"
                    disabled={isStepDone}
                    placeholder={type === "incoming" ? "Eg. 993" : "Eg. 465"}
                    value={formData.port}
                    onChange={onChangeHandler}
                    name="port"
                    className={`${styles.inputText} ${styles.portInput} form-control`}
                  />

                  {/* CheckBox */}
                  <div className="d-flex align-items-start ms-lg-3 ms-2 mt-2 mt-md-0">
                    <input
                      type="checkbox"
                      disabled={isStepDone}
                      checked={formData.useSSL}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          useSSL: e.target.checked,
                        }))
                      }
                      name="useSSL"
                      id="SSL"
                      className={`${styles.checkBox} form-check-input me-1`}
                    />
                    <label
                      htmlFor="SSL"
                      className="ms-2"
                      style={{ cursor: "pointer" }}
                    >
                      <p className={`${styles.sslText}`}>Use SSL</p>
                      <span className={styles.grayText}>
                        For enhanced security
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Login Details */}
        <div>
          <div className={`${styles.topContainer} mt-4`}>
            <p>Login details</p>
          </div>

          <div className={`me-3 ${styles.bottomContainer}`}>
            {/* Username */}
            <p className={`mt-4 ${styles.labelHead}`}>
              User name of your email account
            </p>
            <input
              type="text"
              disabled={isStepDone}
              placeholder="Type here ..."
              autoComplete="off"
              data-lpignore="true"
              value={formData.username}
              onChange={onChangeHandler}
              name="username"
              className={`${styles.inputText} ${styles.bigTextBox} form-control mb-3`}
            />

            {/* Password */}
            <p className={`mt-1 mb-2 ${styles.labelHead}`}>
              App password of your email account
            </p>
            <input
              type="password"
              disabled={isStepDone}
              data-lpignore="true"
              placeholder="Type here ..."
              autoComplete="new-password"
              value={formData.password}
              onChange={onChangeHandler}
              name="password"
              className={`${styles.inputText} ${styles.bigTextBox} form-control mb-4`}
            />
            {/* Button and Status */}
            <div className="d-flex flex-column flex-md-row align-items-center">
              <button
                className={`me-2 ${styles.addButton}`}
                type="button"
                disabled={isStepDone || !isFormValidated}
                onClick={submitHandler}
              >
                Add
              </button>

              {/* Loader */}
              {loading && (
                <span>
                  <Spinner
                    className="my-auto ms-2 me-2"
                    animation="border"
                    color="white"
                    size="sm"
                  />
                  adding...
                </span>
              )}

              {/* Success Authentication */}
              {isStepDone && (
                <div className="ms-1 mt-2 mt-md-0 d-flex align-items-center">
                  <div className={`${styles.successCheck}`}>
                    <span>
                      <i className="fa-solid fa-check"></i>
                    </span>
                  </div>
                  <span className={`ms-2 ${styles.statusText}`}>
                    Authenticated!
                  </span>
                </div>
              )}

              {/* Failed Authentication */}
              {!isStepDone && verifyFailed && (
                <div className="ms-1 mt-2 mt-md-0 d-flex align-items-center">
                  <div className={`${styles.failedCheck}`}>
                    <span>
                      <i className="fa-solid fa-close"></i>
                    </span>
                  </div>
                  <span className={`ms-2 ${styles.statusText}`}>
                    Authentication failed !
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {mailType.id === MailTypeEnum.gmail && <GmailStepsImap />}
      <Modal
        backdropClassName={`${styles.modalBack}`}
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName={`${styles.modalDialog}`}
        contentClassName={`${styles.modalContent}`}
        centered={true}
        enforceFocus={false}
      >
        <SetupComplete />
      </Modal>
    </div>
  );
};

export default EmailAddress;
