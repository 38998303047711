import { useParams, useSearchParams } from "react-router-dom";
import {
  checkUncheckColumn,
  fetchMoreCol,
} from "src/store/slices/tickets/tickets.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import styles from "./MoreColsListItem.module.scss";
import { Column } from "src/hooks/useTicketViews/ticketViewsConfig";

export interface MoreColsListItemProps {
  customProvided: any;
  col: Column;
  isColumnUpdated?: React.MutableRefObject<boolean>;
}

function MoreColsListItem({
  customProvided,
  col,
  isColumnUpdated,
}: MoreColsListItemProps) {
  const dispatch = useAppDispatch();
  const { viewType }: any = useParams();
  const loading = useAppSelector(
    (state) => state.ticket.moreColAjaxStatus[col.id]
  );

  const handleOnClickCheckBox = (e: any) => {
    e.stopPropagation();
    if (loading !== "pending" && loading !== "fulfilled" && !col.show) {
      dispatch(
        fetchMoreCol({
          columnId: col.id,
        })
      );
    }
    dispatch(checkUncheckColumn({ colId: col.id, viewType } as any));
    if (isColumnUpdated?.current !== undefined) {
      isColumnUpdated.current = true;
    }
  };

  return (
    <div
      ref={customProvided.innerRef}
      {...customProvided.draggableProps}
      style={{ ...customProvided.draggableProps.style, position: "static" }}
    >
      <li className={`${styles.table__li} my-1`}>
        <div>
          <div
            className={`d-inline-block me-3 ${styles.mainSquare}`}
            {...customProvided.dragHandleProps}
          >
            <div className={`d-flex`}>
              <div className={`${styles.fourSquare}`} />
              <div className={`${styles.fourSquare}`} />
            </div>
            <div className={`d-flex`}>
              <div className={`${styles.fourSquare}`} />
              <div className={`${styles.fourSquare}`} />
            </div>
          </div>

          <span role="button" onClick={handleOnClickCheckBox}>
            <label className={`switch`}>
              <input
                data-lpignore="true"
                type="checkbox"
                className={`align-middle ${styles.checkBox}`}
                checked={col.show === true ? true : false}
                onChange={(e: any) => e.stopPropagation()}
              />
              <div />
            </label>
            <span className={`ms-2 user-select-none ${styles.colItems}`}>
              {col.name}
            </span>
          </span>
        </div>
      </li>
    </div>
  );
}

export default MoreColsListItem;
