import { createContext, useReducer } from "react";
import { useAppSelector } from "src/store/store";
import { AntiRefundFollowUpQuestion } from "../../../../oldReturnModals.types";
import {
  FollowUpQuestions,
  GiveOfferType,
  RefundScenario,
  items,
} from "../../../../store/slices/AntiRefundStrategiesSlice/antiRefundStrategies.slice";

export const ScenarioStateContext = createContext<any>(null); // Creating Context for Scenario State

export const ScenarioDispatchContext = createContext<any>(null); // Creating Context for Scenario Dispatch

export type RefundScenarioState = {
  items: items[];
  tags: string[];
  itemsAndTagAllSelected: boolean;
  followUpQuestions: FollowUpQuestions[];
  additionalInformation?: {
    enabled: boolean;
    message: string;
    extendReturnPeriod: {
      enabled: boolean;
      days: number;
    };
  };
  giveOffer?: GiveOfferType & {
    enabled: boolean;
    message: string;
  };
  offerExchange?: {
    enabled: boolean;
    message: string;
    exchangeWithSameItems: boolean;
    exchangeWithOtherItems: boolean;
  };
};

function ScenarioReducer(question: string) {
  //  type RefundScenarioState = {
  //   items: items[] | string;
  //   tags: string[] | string;
  //   itemsAndTagAllSelected: boolean;
  //   followUpQuestions: FollowUpQuestions[];
  // };

  //Initial values for local state
  const intialValues = {
    items: [],
    itemsAndTagAllSelected: true,
    tags: [],
    followUpQuestions: [],
    additionalInformation: {
      enabled: false,
      message: "",
      extendReturnPeriod: {
        enabled: false,
        days: 0,
      },
    },
    giveOffer: {
      enabled: false,
      message: "",
      offerSelectedItems: [],
      offerSelectedTags: [],
      offerType: null,
      offerUnit: "percentage",
    },
    offerExchange: {
      enabled: false,
      message: "",
      exchangeWithSameItems: false,
      exchangeWithOtherItems: false,
    },
  };

  type Action =
    | {
        type: "UPDATE_SCENARIO_ITEMS";
        payload: {
          items: {
            id?: number;
            value: string;
            label: string;
            price?: {
              amount: string;
              currencyCode: string;
            };
            imgSrc?: string;
            isDisabled?: boolean;
            type: "tag" | "item";
          }[];
        };
      }
    | {
        type: "UPDATE_SCENARIO_TAGS";
        payload: {
          tags: {
            label: string;
            value: string;
            type: "tag";
          }[];
        };
      }
    | {
        type: "ITEMS_AND_TAGS_ALL_SELECTED";
        payload: {
          itemsAndTagAllSelected: boolean;
        };
      }
    | {
        type: "SELECT_ALL_FOLLOW_UP_QUESTIONS";
      }
    | {
        type: "DESELECT_ALL_FOLLOW_UP_QUESTIONS";
      }
    | {
        type: "UPDATE_FOLLOW_UP_QUESTIONS";
        payload: {
          elementId: string;
          elementTypeId: string | number;
        };
      }
    | {
        type: "REMOVE_FOLLOW_UP_QUESTIONS";
        payload: {
          elementId: string;
          elementTypeId: string | number;
        };
      }
    | {
        type: "UPDATE_FOLLOW_UP_QUESTIONS_SELECTED_VALUE";
        payload: {
          elementId: string;
          elementTypeId: string;
          selectedValue: string | string[];
          // selectedId: string | string[];
        };
      }
    | {
        type: "UPDATE_ADDITIONAL_INFO";
        payload: {
          reasonId: string | number;
          scenarioId: string;
          additionalInfo: {
            enabled?: boolean;
            message?: string;
            extendReturnPeriod?: {
              enabled: boolean;
              days: number;
            };
          };
        };
      }
    | {
        type: "UPDATE_GIVE_OFFER";
        payload: {
          reasonId: string | number;
          scenarioId: string;
          giveOffer: {
            offerType?:
              | "partial_refund_of"
              | "discount_of"
              | "offer_clearance_product_for_free"
              | "other"
              | null;
            offerSelectedItems?: string[];
            offerSelectedTags?: string[];
            enabled?: boolean;
            message?: string;
            value?: string;
            type?: string;
          };
        };
      }
    | {
        type: "UPDATE_OFFER_EXCHANGE";
        payload: {
          reasonId: string | number;
          scenarioId: string;
          offerExchange: {
            enabled?: boolean;
            message?: string;
            exchangeWithSameItems?: boolean;
            exchangeWithOtherItems?: boolean;
          };
        };
      }
    | {
        type: "CLEAR_LOCAL_STATE_FOLLOW_UP_QUESTIONS";
      }
    | {
        type: "CLEAR_LOCAL_STATE_ADDITIONAL_INFO";
      }
    | {
        type: "UPDATE_LOCAL_STATE";
        payload: {
          scenario: RefundScenario;
        };
      };

  const followUpQuestionQ11 = useAppSelector(
    (state) =>
      state.antiRefundStrategies.refundRequests.refundRequestFollowUpQuestions,
  );
  const followUpQuestionQ12 = useAppSelector(
    (state) =>
      state.antiRefundStrategiesOrders.antiRefundOrderTypesData
        .refundRequestFollowUpQuestions,
  );
  let followUpQuestions: AntiRefundFollowUpQuestion[];
  if (question === "question11") {
    followUpQuestions = followUpQuestionQ11;
  }
  if (question === "question12") {
    followUpQuestions = followUpQuestionQ12;
  }

  const reducer = (state: RefundScenarioState, action: Action): any => {
    switch (action.type) {
      case "UPDATE_SCENARIO_ITEMS":
        return {
          ...state,
          items: action.payload.items,
        };

      case "UPDATE_SCENARIO_TAGS":
        // Changed to the same type as used in antirefund strategies
        const updatedTags = action.payload.tags.map((tag) => tag.label);
        return {
          ...state,
          tags: updatedTags,
        };

      case "ITEMS_AND_TAGS_ALL_SELECTED":
        return {
          ...state,
          itemsAndTagAllSelected: action.payload.itemsAndTagAllSelected,
        };

      case "SELECT_ALL_FOLLOW_UP_QUESTIONS":
        return {
          ...state,
          followUpQuestions: followUpQuestions.map(
            (question: AntiRefundFollowUpQuestion) => {
              return {
                elementId: question.elementId,
                elementTypeId: question.elementTypeId,
                elementValue: question.elementValue,
                additionalOptions: question.additionalOptions,
                selectedValue: "",
                // selectedId: "",
              };
            },
          ),
        };

      case "DESELECT_ALL_FOLLOW_UP_QUESTIONS":
        return {
          ...state,
          followUpQuestions: [],
        };

      case "UPDATE_FOLLOW_UP_QUESTIONS":
        const element = followUpQuestions.filter(
          (question: AntiRefundFollowUpQuestion) =>
            question.elementId === action.payload.elementId &&
            question.elementTypeId === action.payload.elementTypeId,
        )[0];
        return {
          ...state,
          followUpQuestions: [
            ...state.followUpQuestions,
            {
              elementId: element.elementId,
              elementTypeId: element.elementTypeId,
              elementValue: element.elementValue,
              additionalOptions: element.additionalOptions,
              selectedValue: "",
              // selectedId: "",
            },
          ],
        };
      case "REMOVE_FOLLOW_UP_QUESTIONS":
        const elementIdx = state.followUpQuestions.findIndex(
          (question: FollowUpQuestions) =>
            question.elementId === action.payload.elementId &&
            question.elementTypeId === action.payload.elementTypeId,
        );
        return {
          ...state,
          followUpQuestions: [
            ...state.followUpQuestions.slice(0, elementIdx),
            ...state.followUpQuestions.slice(elementIdx + 1),
          ],
        };

      case "UPDATE_FOLLOW_UP_QUESTIONS_SELECTED_VALUE":
        const selectedIdx = state.followUpQuestions.findIndex(
          (question: FollowUpQuestions) =>
            question.elementId === action.payload.elementId &&
            question.elementTypeId === action.payload.elementTypeId,
        );
        const selectedValue = action.payload.selectedValue;
        // const selectedId = action.payload.selectedId;
        const followUpQuestionsSelected = [...state.followUpQuestions];
        if (selectedValue === "*") {
          const additionalOptions =
            followUpQuestionsSelected[selectedIdx].additionalOptions;
          const selectedValues = additionalOptions.map((option) => option.id);
          // const selectedIds = additionalOptions.map((option) => option.id);
          followUpQuestionsSelected[selectedIdx] = {
            ...followUpQuestionsSelected[selectedIdx],
            selectedValue: selectedValues,
            // selectedId: selectedIds,
          };
        } else {
          followUpQuestionsSelected[selectedIdx] = {
            ...followUpQuestionsSelected[selectedIdx],
            selectedValue: selectedValue,
            // selectedId: selectedId,
          };
        }

        return {
          ...state,
          followUpQuestions: followUpQuestionsSelected,
        };
      case "UPDATE_ADDITIONAL_INFO":
        const { additionalInfo } = action.payload;
        let additionalInformation = state.additionalInformation;
        if (additionalInformation === undefined) {
          additionalInformation = {
            enabled: false,
            message: "",
            extendReturnPeriod: {
              enabled: false,
              days: 0,
            },
          };
        }
        if (additionalInformation) {
          if (additionalInfo.enabled !== undefined) {
            additionalInformation = {
              ...additionalInformation,
              enabled: additionalInfo.enabled,
            };
          }
          if (additionalInfo.message !== undefined) {
            additionalInformation = {
              ...additionalInformation,
              message: additionalInfo.message,
            };
          }
          if (additionalInfo.extendReturnPeriod !== undefined) {
            additionalInformation = {
              ...additionalInformation,
              extendReturnPeriod: {
                ...additionalInformation.extendReturnPeriod,
                enabled: additionalInfo.extendReturnPeriod.enabled,
                days: additionalInfo.extendReturnPeriod.days,
              },
            };
          }
        }
        return {
          ...state,
          additionalInformation: additionalInformation,
        };
      case "UPDATE_GIVE_OFFER":
        const { giveOffer } = action.payload;
        let giveOfferSelected = state.giveOffer;
        if (giveOfferSelected === undefined) {
          giveOfferSelected = {
            enabled: false,
            message: "",
            offerSelectedItems: [],
            offerSelectedTags: [],
            offerType: null,
            offerUnit: "percentage",
          };
        }
        if (giveOfferSelected) {
          if (giveOffer?.enabled !== undefined) {
            giveOfferSelected = {
              ...giveOfferSelected,
              enabled: giveOffer.enabled,
            };
          }
          if (giveOffer?.message !== undefined) {
            giveOfferSelected = {
              ...giveOfferSelected,
              message: giveOffer.message,
            };
          }
          if (giveOffer?.offerSelectedItems !== undefined) {
            giveOfferSelected = {
              ...giveOfferSelected,
              offerSelectedItems: giveOffer.offerSelectedItems,
            };
          }
          if (giveOffer?.offerSelectedTags !== undefined) {
            giveOfferSelected = {
              ...giveOfferSelected,
              offerSelectedTags: giveOffer.offerSelectedTags,
            };
          }
          if (giveOffer?.offerType !== undefined) {
            giveOfferSelected = {
              ...giveOfferSelected,
              offerType: giveOffer.offerType,
            };
          }

          if (giveOffer?.value !== undefined) {
            giveOfferSelected = {
              ...giveOfferSelected,
              offerValue: Number(giveOffer.value),
            };
          }

          if (giveOffer?.type !== undefined) {
            giveOfferSelected = {
              ...giveOfferSelected,
              offerUnit: giveOffer.type as "fixed" | "percentage",
            };
          }
        }
        return {
          ...state,
          giveOffer: giveOfferSelected,
        };
      case "UPDATE_OFFER_EXCHANGE":
        const { offerExchange } = action.payload;
        let offerExchangeSelected = state.offerExchange;

        if (offerExchangeSelected === undefined) {
          offerExchangeSelected = {
            enabled: false,
            message: "",
            exchangeWithSameItems: false,
            exchangeWithOtherItems: false,
          };
        }

        if (offerExchangeSelected) {
          if (offerExchange.enabled !== undefined) {
            offerExchangeSelected = {
              ...offerExchangeSelected,
              enabled: offerExchange.enabled,
            };
          }
          if (offerExchange.message !== undefined) {
            offerExchangeSelected = {
              ...offerExchangeSelected,
              message: offerExchange.message,
            };
          }
          if (offerExchange.exchangeWithSameItems !== undefined) {
            offerExchangeSelected = {
              ...offerExchangeSelected,
              exchangeWithSameItems: offerExchange.exchangeWithSameItems,
            };
          }
          if (offerExchange.exchangeWithOtherItems !== undefined) {
            offerExchangeSelected = {
              ...offerExchangeSelected,
              exchangeWithOtherItems: offerExchange.exchangeWithOtherItems,
            };
          }
        }
        return {
          ...state,
          offerExchange: offerExchangeSelected,
        };
      case "CLEAR_LOCAL_STATE_FOLLOW_UP_QUESTIONS":
        return {
          ...state,
          items: [],
          itemsAndTagAllSelected: false,
          tags: [],
          followUpQuestions: [],
        };
      case "CLEAR_LOCAL_STATE_ADDITIONAL_INFO":
        return {
          ...state,
          additionalInformation: {
            enabled: false,
            message: "",
            extendReturnPeriod: {
              enabled: false,
              days: 0,
            },
          },
          giveOffer: {
            enabled: false,
            message: "",
            offerSelectedItems: [],
            offerSelectedTags: [],
            offerType: null,
            offerUnit: "percentage",
          },
          offerExchange: {
            enabled: false,
            message: "",
            exchangeWithSameItems: false,
            exchangeWithOtherItems: false,
          },
        };
      case "UPDATE_LOCAL_STATE":
        //Update local state with the scenario info
        return {
          ...state,
          followUpQuestions: action.payload.scenario.followUpQuestions ?? [],
          items: action.payload.scenario.items ?? [],
          tags: action.payload.scenario.tags ?? [],
          itemsAndTagAllSelected:
            action.payload.scenario.itemsAndTagAllSelected ?? false,
          additionalInformation: {
            enabled:
              action.payload.scenario.additionalInformation?.enabled ?? false,
            message: action.payload.scenario.additionalInformation
              ?.message as string,
            extendReturnPeriod: {
              enabled:
                action.payload.scenario.additionalInformation
                  ?.extendReturnPeriod?.enabled ?? false,
              days:
                (action.payload.scenario.additionalInformation
                  ?.extendReturnPeriod?.days as number) ?? 0,
            },
          },
          giveOffer: {
            enabled: action.payload.scenario.giveOffer?.enabled ?? false,
            message:
              (action.payload.scenario.giveOffer?.message as string) ?? "",
            offerSelectedItems:
              (action.payload.scenario.giveOffer
                ?.offerSelectedItems as number[]) ?? [],
            offerSelectedTags:
              (action.payload.scenario.giveOffer
                ?.offerSelectedTags as string[]) ?? [],
            offerType:
              (action.payload.scenario.giveOffer?.offerType as string) ?? null,
            offerValue:
              (action.payload.scenario.giveOffer?.offerValue as number) ?? null,
            offerUnit:
              (action.payload.scenario.giveOffer?.offerUnit as string) ??
              "percentage",
            name: action.payload.scenario.giveOffer?.name ?? "",
          },
          offerExchange: {
            enabled: action.payload.scenario.offerExchange?.enabled ?? false,
            message: action.payload.scenario.offerExchange?.message ?? "",
            exchangeWithOtherItems:
              action.payload.scenario.offerExchange?.exchangeWithOtherItems ??
              false,
            exchangeWithSameItems:
              action.payload.scenario.offerExchange?.exchangeWithSameItems ??
              false,
          },
        };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, intialValues);

  return {
    reducer,
    intialValues,
    state,
    dispatch,
  };
}

export default ScenarioReducer;
