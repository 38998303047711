import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AJAXSTATUS } from "src/globals/constants";
import liveChatTabQuickActionsExtraReducers from "./liveChatTabQuickActions.extraReducers";
import liveChatTabQuickActionsReducers from "./liveChatTabQuickActions.reducers";
import {
  createLiveChatTicketThunk,
  fetchLiveChatThreadThunk,
} from "./liveChatTabQuickActions.thunks";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { IntegrationFailureMetaData } from "src/types/IntegrationFailure";

export interface ILiveChatTabQuickActionsState {
  fetchLiveChatThreadAjaxStatus: AJAXSTATUS;
  threadText: string;
  createTicketFromLiveChatAjaxStatus: AJAXSTATUS;
  createdTicketId: string | number;
  integrationFailure: IntegrationFailureMetaData | null;
}

export const initialState: ILiveChatTabQuickActionsState = {
  fetchLiveChatThreadAjaxStatus: "pending",
  threadText: "",
  createTicketFromLiveChatAjaxStatus: "idle",
  createdTicketId: "",
  integrationFailure: null,
};

export const liveChatTabQuickActionsSlice = createSlice({
  name: "liveChatTabQuickActionsSlice",
  initialState: initialState,
  reducers: liveChatTabQuickActionsReducers,
  extraReducers: (builder) => {
    // for fetch thread
    builder.addCase(fetchLiveChatThreadThunk.pending, (state) => {
      state.fetchLiveChatThreadAjaxStatus = "pending";
    });
    builder.addCase(
      fetchLiveChatThreadThunk.fulfilled,
      liveChatTabQuickActionsExtraReducers.fetchLiveChatThreadFulfilled,
    );
    builder.addCase(fetchLiveChatThreadThunk.rejected, (state) => {
      state.fetchLiveChatThreadAjaxStatus = "rejected";
    });

    // for create ticket from chat
    builder.addCase(createLiveChatTicketThunk.pending, (state) => {
      state.createTicketFromLiveChatAjaxStatus = "pending";
    });
    builder.addCase(
      createLiveChatTicketThunk.fulfilled,
      liveChatTabQuickActionsExtraReducers.createTicketFromChatFulfilled,
    );
    builder.addCase(createLiveChatTicketThunk.rejected, (state, action) => {
      state.createTicketFromLiveChatAjaxStatus = "rejected";
      const payload = action.payload as any;
      if (payload === undefined) {
        pushTheToast({
          text: "Couldn't create ticket! Please reload and try again!",
          type: "danger",
          position: "top-right",
        });
      } else if (
        typeof payload === "object" &&
        payload?.statusCode === "integrationFailure"
      ) {
        state.integrationFailure = payload.failureMetaData;
      }
    });
  },
});

export const {
  resetLiveChatTabQuickActions,
  resetLiveChatTabQuickActionCreateTicket,
} = liveChatTabQuickActionsSlice.actions;

export default liveChatTabQuickActionsSlice.reducer;
