import { useNavigate } from "react-router-dom";
import styles from "./FeatureBox.module.scss";
interface Props {
  article: any;
  articleIdx: number;
  helpCenterPageUri: string;
  brandColor: string;
  font: string;
}
const FA_AliceInWonderland = ({
  article,
  articleIdx,
  helpCenterPageUri,
  brandColor,
  font,
}: Props) => {
  const navigate = useNavigate();
  return (
    <div
      className={`ps-3 pe-4 py-3 ${styles.featureBox} w-75`}
      onClick={() => {
        navigate(
          `/helpcenterpage/${helpCenterPageUri}/article/${article.articleId}`,
          {
            state: {
              path: "featuredArticle",
            },
          }
        );
      }}
    >
      <div className="d-flex justify-content-between align-items-center">
        <div className="w-75">
          <p
            className={`mb-0 ${styles.heading}`}
            style={{
              fontFamily: font ?? "Roboto", // font family
            }}
          >
            {article.articleName}{" "}
          </p>
        </div>
        <div className="w-25 d-flex justify-content-end">
          <span
            className={`d-flex align-items-center ${styles.readBtn}`}
            style={{ color: `${brandColor}` }}
          >
            <span>
              <i className="fa-solid fa-arrow-right"></i>
            </span>
            <span
              className="ps-2"
              style={{
                fontFamily: font ?? "Roboto", // font family
              }}
            >
              Read article
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default FA_AliceInWonderland;
