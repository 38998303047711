import styles from "../../CallRecording.module.scss";

function RecordTabSec() {
  return (
    <button className={`${styles.tableHead}`}>
      <span className={`me-1 ${styles.callIcon}`}>
        <i className="fa-solid fa-phone"></i>
      </span>
      Recordings
    </button>
  );
}

export default RecordTabSec;
