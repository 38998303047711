import { axiosJSON } from "src/globals/axiosEndPoints";

interface MailType {
  id: number;
  typeName: string;
}

export interface EmailIntegrationData {
  id: number;
  emailName: string;
  emailAddress: string;
  isEmailVerified: boolean;
  isDomainVerified: boolean;
  mailType: MailType;
}

export interface EmailIntegrationMetaData {
  count: number;
  total: number;
}

export interface GetAllEmailIntegrations {
  data: Array<EmailIntegrationData>;
  metaData: EmailIntegrationMetaData;
}

export interface GetAllEmailIntegrationsParams {
  start: number;
  limit: number;
}

export async function getAllEmailIntegrations(
  params: GetAllEmailIntegrationsParams
) {
  const { data } = await axiosJSON.post(
    `/api/setting/integration/getAllEmailIntegrations`,
    params
  );
  // const data: any = await getAllEmailIntegrationByPromise(params);
  if (data.err === true || data.error === true) {
    throw new Error(data.msg as string);
  }
  return data as GetAllEmailIntegrations;
}

// let getAllEmailIntegrationByPromise = function (
//   params: GetAllEmailIntegrationsParams
// ) {
//   return new Promise(function (resolve, reject) {
//     if (params) {
//       setTimeout(function () {
//         resolve({
//           data: [
//             {
//               id: 2,
//               emailName: "Custom Support Email 8",
//               emailAddress: "sreeragtest88@gmail.com",
//               isEmailVerified: false,
//               isDomainVerified: null,
//               mailType: {
//                 id: 154,
//                 typeName: "Others",
//               },
//             },

//             {
//               id: 8,
//               emailName: "Custom Support Email 8",
//               emailAddress: "sreeragtest88788@gmail.com",
//               isEmailVerified: false,
//               isDomainVerified: null,
//               mailType: {
//                 id: 154,
//                 typeName: "Others",
//               },
//             },
//           ],

//           metaData: {
//             count: 2,
//             total: 2,
//           },
//         });
//       }, 2000);
//     } else {
//       reject(new Error("Something is not right!"));
//     }
//   });
// };
