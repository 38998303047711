import UserAvatar from "src/components/UserAvatar";
import styles from "./AssignedTo.module.scss";
import { useAppSelector } from "src/store/store";
import { selectTicketCellData } from "src/store/slices/tickets/tickets.slice";
import { TableCellProps } from "..";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import AxiosImg from "src/components/AxiosImg";

const dummyImageUrl = "https://picsum.photos/200/300?random=2";

function AssignedTo({ ticketId, colKeys }: TableCellProps) {
  const ticketCellData: any = useAppSelector((state) =>
    selectTicketCellData(state, ticketId, colKeys)
  );

  return (
    <>
      <OverlayTrigger
        placement={"top"}
        overlay={
          ticketCellData[0] ? (
            <Tooltip>{ticketCellData[0]?.name ?? "NA"}</Tooltip>
          ) : (
            <></>
          )
        }
      >
        <div className={`${styles["subject"]}`}>
          <span className={`${styles["tbody"]}`}>
            {ticketCellData[0] ? (
              <>
                <span className="me-2">
                  {ticketCellData[0]?.imgURL &&
                  ticketCellData[0]?.imgURL.trim().length !== 0 ? (
                    <AxiosImg
                      className={`${styles.profileIcon}`}
                      url={ticketCellData[0].imgURL}
                      isDirectURL={ticketCellData[0]?.isPublicAttachmentUrl}
                    />
                  ) : (
                    ticketCellData[0].name !== "NA" && (
                      <UserAvatar name={ticketCellData[0].name} size={30} />
                    )
                  )}
                </span>
                <span className={`${styles["custName"]} text-break`}>
                  {ticketCellData[0].name}
                </span>
              </>
            ) : (
              <span className={`text-truncate ${styles["custName"]}`}>
                {"NA"}
              </span>
            )}
          </span>
        </div>
      </OverlayTrigger>
    </>
  );
}

export default AssignedTo;
