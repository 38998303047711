import { useCallback, useEffect, useMemo, useState } from "react";
import {
  bindPusherConnected,
  bindPusherDisconnected,
  channelSubscriber,
  channelUnsubscriber,
  unbindPusherConnected,
  unbindPusherDisconnected,
} from "src/globals/pusher";

interface Props {
  channelName: string | null;
}

interface BindEV {
  binderCallback: Function;
  resCallback: Function;
}

export const useChannel = ({ channelName }: Props) => {
  const [connectionUpdated, setConnectionUpdated] = useState("");

  const channel = useMemo(() => {
    return channelSubscriber(channelName);
  }, [channelName, connectionUpdated]);
  const unsubscribe = useCallback(() => {
    channel?.unsubscribe();
  }, [channel]);
  const bindEvents = useCallback(
    ({ binderCallback, resCallback }: BindEV) => {
      if (channel) {
        binderCallback(channel, resCallback);
      }
    },
    [channel]
  );

  /**
   * useCallback hook to create an unsubscribe function for a Pusher channel.
   * The function checks if a channelName is provided and calls channelUnsubscriber.
   */
  const unsubscribeUsingPusher = useCallback(
    () => {
      // Check if a channelName is provided before calling channelUnsubscriber
      if (channelName) {
        console.log(channelName);
        channelUnsubscriber(channelName);
      }
    },
    [channelName] // Dependency array to ensure the callback is recreated if channelName changes
  );

  // Function to handle Pusher disconnection
  const handlePusherDisconnection = useCallback(() => {
    setConnectionUpdated("disconnected");
  }, []);

  // Function to handle Pusher connection
  const handlePusherConnection = useCallback(() => {
    setConnectionUpdated("connected");
  }, []);

  // Use useMemo to ensure that the bindings and unbindings only occur once during component initialization
  useMemo(() => {
    // Bind the connection handlers to Pusher events
    bindPusherConnected(handlePusherConnection);
    bindPusherDisconnected(handlePusherDisconnection);

    // Cleanup: Unbind the connection handlers when the component unmounts
    return () => {
      unbindPusherConnected(handlePusherConnection);
      unbindPusherDisconnected(handlePusherDisconnection);
    };
  }, []); // Empty dependency array ensures that this effect runs only once during component initialization

  return { channel, unsubscribe, bindEvents, unsubscribeUsingPusher };
};
