import { ITicketChatFormSetting } from "./ticketChatFormSetting.slice";

const fetchTicketChatFormDataFulfilled = (
  state: ITicketChatFormSetting,
  { payload }: {payload: any}
) => {
  state = {
    ...state,
    ...payload,
    ticketChatFormDataAjaxStatus: "fulfilled",
  };
  return state;
};


export default { fetchTicketChatFormDataFulfilled };
