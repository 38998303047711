import { axiosJSON } from "src/globals/axiosEndPoints";

interface IFeaturedArticle{
  articleId: number|string; 
  shortTitle: string; 
  articleName:string;
}

export interface IFeaturedArticlesData{
  id: number|string;
  integrationId: number|string;
  type: "default" | "custom"; 
  domainUrl: string; 
  domainConstraint: "contains" | "isExactly";
  featuredArticles: {[key:string|number]: IFeaturedArticle};
  isDefault?: boolean
}

//interface of response data of all featured article
export interface IGetAllFeaturedArticle{
  featuredArticleIds: Array<number|string>
  featuredArticles: {[key:string|number]: IFeaturedArticlesData}
  metaData: {
    total:number;
    count: number;
    defaultExists: boolean; //if default featured article already exists 
  }
}

export interface IGetAllFeaturedArticleParams {
  integrationId: number|string,
  start?: number;
  limit?: number;
}

export async function getAllFeaturedArticles(payload: IGetAllFeaturedArticleParams) {
  const { data: res }: any = await axiosJSON.post(
    "/api/chatSetting/featuredArticles/getAll",
    payload
  );

  if (res.err === true) {
    throw new Error(res.msg as string);
  }

  return normalizeData(res.data, res.metaData);
}


function normalizeData(data: any, meta: any) {
  const featuredArticles:  {[key:string|number]: IFeaturedArticlesData} = {};
  const featuredArticleIds: Array<number | string> = [];

  data.map((featuredArticle: IFeaturedArticlesData) => {
    const id = parseInt(featuredArticle.id + "");
    featuredArticleIds.push(id);
    const featuredArticleData = featuredArticle;
    const selectedFeaturedArticles: IFeaturedArticlesData["featuredArticles"] = {};

    Object.values(featuredArticleData.featuredArticles).forEach((featuredArticle)=>{
      selectedFeaturedArticles[featuredArticle.articleId] = featuredArticle;
    })

    featuredArticleData.featuredArticles = selectedFeaturedArticles;
    featuredArticles[id] = featuredArticleData;
  });
  const metaData = { count: meta.count, total: meta.total, defaultExists: meta.defaultExists };

  return {
    featuredArticleIds,
    featuredArticles,
    metaData,
  } as IGetAllFeaturedArticle;
}

