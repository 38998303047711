import { Form, OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import Select, { GroupBase, OptionsOrGroups } from "react-select";
import moreInfo from "src/assets/images/caution.png";
import call from "src/assets/images/call.png";
import insideBox from "src/assets/images/insideBox.png";
import link from "src/assets/images/link.png";
import Message from "src/assets/images/Message.png";
import styles from "./CardWidgetButton.module.scss";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { UIButtonProps } from "src/store/slices/targetedMessage/targetedMessage.slice";
import { useLocalStorageForm } from "./useLocalStorageForm";

const options = [
  {
    value: "default",

    label: (
      <>
        <div className={`d-flex`}>
          <div className={`me-2 ${styles.colorBox}`}></div>
          <span> Default</span>
        </div>
      </>
    ),
  },

  {
    value: "primary",
    label: (
      <>
        <div className={`d-flex`}>
          <div className={`me-2 ${styles.colorBox2}`}></div>
          <span> Primary</span>
        </div>
      </>
    ),
  },
  {
    value: "danger",
    label: (
      <>
        <div className={`d-flex`}>
          <div className={`me-2 ${styles.colorBox3}`}></div>
          <span> Danger</span>
        </div>
      </>
    ),
  },
];
const CardWidgetButton = (props: {
  selectedBtn: Partial<UIButtonProps> | undefined;
  changeButtonProps: (props: {
    text?: string;
    style?: string;
    selectedAction?: number;
    websiteUrl?: string;
  }) => void;
  id: string;
  btn: UIButtonProps;
}) => {
  const [buttonData, setButtonData] = useLocalStorageForm(
    `form${props.selectedBtn?.id ?? ""}`,
    props.btn
      ? {
          selectedAction: getSelectedActionKey(props.btn.selectedAction),
          websiteUrl: props.btn.websiteUrl,
          selectedButtonStyles: props.btn.buttonStyles,
        }
      : {
          selectedAction: "",
          websiteUrl: "",
          selectedButtonStyles: "",
        }
  );

  const handleButtonDataChange = (e: React.SyntheticEvent) => {
    const target = e.target as HTMLInputElement;
    console.log(target.name, target.value);
    setButtonData({ ...buttonData, [target.name]: target.value });
    if (target.name === "buttonText") {
      props.changeButtonProps({ text: target.value });
    }
    if (target.name === "selectedButtonStyles") {
      props.changeButtonProps({ style: target.value });
    }
    if (target.name === "websiteUrl") {
      props.changeButtonProps({ websiteUrl: target.value });
    }
    if (target.name === "selectedAction") {
      let selectedActionNo: number | undefined = parseInt(
        getSelectedActionKey(target.value) as string
      );
      props.changeButtonProps({ selectedAction: selectedActionNo });
    }
  };

  return (
    <>
      <div className={`${styles.delBox}`}>
        <div className={`${styles.header}`}>
          <span>Button Style</span>
          <span>
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip className={` ${styles.toolTipCustom}`}>
                  Color depends on chat widget appearance settings for group
                  general
                </Tooltip>
              }
            >
              {({ ref, ...triggerHandler }) => (
                <div {...triggerHandler} ref={ref}>
                  <img
                    className={`${styles.moreInfo}`}
                    src={moreInfo}
                    alt={``}
                  />
                </div>
              )}
            </OverlayTrigger>
          </span>
        </div>
        <div className={`${styles.colorWrapper}  w-100`}>
          <Select
            options={options}
            name="selectedButtonStyles"
            className={`${styles.selectItem}`}
            value={options.find(
              // @ts-ignore
              (opt) => opt.value === buttonData.selectedButtonStyles
            )}
            onChange={(newValue, actionMeta) => {
              handleButtonDataChange({
                // @ts-ignore
                target: { value: newValue.value, name: actionMeta.name },
              });
            }}
          />
        </div>
        {/* Actions */}
        <div className=" ">
          <span className={`${styles.headerFont}`}>Actions</span>
          <ActionsInput
            onChange={handleButtonDataChange}
            selectedAction={buttonData.selectedAction}
          />

          {/* Button Text Input  */}
          <div className={`${styles.buttonWrapper} mt-1`}>
            <div>
              <label className={`${styles.headerFont}`}> Button Text</label>
              <input
                type={`text`}
                name="buttonText"
                className={`${styles.inputBox} mt-1 w-100 rounded`}
                placeholder="Start the chat"
                onChange={(e: any) => {
                  handleButtonDataChange(e);
                }}
                value={props.selectedBtn?.text ?? ""}
              />
            </div>
            {buttonData.selectedAction === "Open URL" ||
            buttonData.selectedAction === "Open Moment" ? (
              <div>
                <label className={`${styles.headerFont}`}> Website URL</label>
                <input
                  name="websiteUrl"
                  type={`text`}
                  className={`${styles.inputBox} w-100 rounded`}
                  placeholder="Enter URL"
                  onChange={handleButtonDataChange}
                  value={buttonData.websiteUrl}
                />
              </div>
            ) : (
              ""
            )}

            <div className={`d-flex justify-content-end`}>
              <button
                className={`${styles.doneBtn} mt-2 `}
                onClick={() => document.body.click()}
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

function getSelectedActionKey(
  value: number | string
): number | string | undefined {
  let selectedActionNo;
  if (Number.isInteger(value)) {
    switch (value) {
      case 1:
        selectedActionNo = "Send Message";
        break;
      case 2:
        selectedActionNo = "Phone Call";
        break;
      case 3:
        selectedActionNo = "Open URL";
        break;
      case 4:
        selectedActionNo = "Open Moment";
        break;
      case 5:
        selectedActionNo = "Dismiss";
        break;
    }
  } else {
    switch (value) {
      case "Send Message":
        selectedActionNo = 1;
        break;
      case "Phone Call":
        selectedActionNo = 2;
        break;
      case "Open URL":
        selectedActionNo = 3;
        break;
      case "Open Moment ":
        selectedActionNo = 4;
        break;
      case "Dismiss":
        selectedActionNo = 5;
        break;
    }
  }
  return selectedActionNo;
}

function ActionInput({ overlay, children }: { overlay: any; children: any }) {
  return (
    <OverlayTrigger trigger="hover" placement="bottom" overlay={overlay}>
      {children}
    </OverlayTrigger>
  );
}

function ActionsInput({
  actions = [
    {
      label: "Send Message",
      popover: (
        <Popover className={`${styles.popover}`} data-redBorder="true">
          <div>Sends message with button text as content</div>
        </Popover>
      ),
    },
    {
      label: "Phone Call",
      popover: (
        <Popover className={`${styles.popover}`} data-redBorder="true">
          <div>Calls provided number</div>
        </Popover>
      ),
    },
    {
      label: "Open URL",
      popover: (
        <Popover className={`${styles.popover}`} data-redBorder="true">
          <div>Opens link in new tab</div>
        </Popover>
      ),
    },
    {
      label: "Open Moment",
      popover: (
        <Popover className={`${styles.popover}`} data-redBorder="true">
          <div>Opens link inside chat widget</div>
        </Popover>
      ),
    },
    {
      label: "Dismiss",
      popover: (
        <Popover className={`${styles.popover}`} data-redBorder="true">
          <div>Hides Greeting</div>
        </Popover>
      ),
    },
  ],
  onChange,
  selectedAction,
}: {
  actions?: any;
  onChange: any;
  selectedAction: any;
}) {
  return (
    <div className="d-flex flex-wrap w-100 ">
      {actions.map((action: any, index: number) => {
        return (
          <div className=" ">
            <ActionInput overlay={action.popover}>
              <div role="button" className=" me-3 ">
                <Form.Check type="radio" className=" p-0 ">
                  <Form.Check.Input
                    hidden
                    onChange={onChange}
                    name="selectedAction"
                    type="radio"
                    value={action.label}
                    id={action.label}
                  />

                  <Form.Check.Label
                    htmlFor={action.label}
                    style={{ display: "block" }}
                  >
                    <div
                      className={`${styles.actionSubWrapper}  `}
                      act-idx={index}
                    >
                      <div
                        className={`${styles.actionDiv} ${
                          action.label === selectedAction
                            ? styles.selectedIconBox
                            : ""
                        }  text-center `}
                      >
                        <img
                          className={`${styles.actionImg} `}
                          src={Message}
                          alt={action.label}
                        />
                      </div>
                      <div className={`${styles.actionSpan}  `}>
                        {action.label}
                      </div>
                    </div>
                  </Form.Check.Label>
                </Form.Check>
              </div>
            </ActionInput>
          </div>
        );
      })}
    </div>
  );
}

export default CardWidgetButton;
