import { useMutation } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import InnerChatAttachments from "src/components/InnerChatAttachments/InnerChatAttachments";
import ParseHTML from "src/components/ParseHTML";
import TinyMCEContainer from "src/components/TinyMCEContainer";
import { checkContent } from "src/routes/SelfService/Children/BugManagement/Children/TableRow/Children/BugReportModal/BugReportModal";
import { UploadAttachmentRes } from "src/services/Attachment/uploadAttachment";
import sendEscalateMsg from "src/services/TicketService/InternalChatBot/sendEscalateMsg";
import updateEscalateMsgStatus from "src/services/TicketService/InternalChatBot/updateEscalateMsgStaus";
import {
  addMessage,
  fetchMessagesUpdates,
  IMessageData,
} from "src/store/slices/innerTicket/innerTicket.slice";
import { v4 as uuid } from "uuid";
import { convertGmtToUserTimezone } from "src/utils/dateLibrary";
import { useAppDispatch, useAppSelector } from "src/store/store";
import styles from "./EditEscalation.module.scss";

const EscalateTimer = ({ messageDateTime }: { messageDateTime: string }) => {
  //60 second timer
  const [timeLeft, setTimeLeft] = useState<number>(60);
  const userTimeZone = useAppSelector(
    (state) => state.globals.currentUserData?.userTimezone,
  );

  useEffect(() => {
    const calculateTimeLeft = () => {
      if (userTimeZone) {
        const currentTime = new Date(
          convertGmtToUserTimezone(
            new Date().toISOString().slice(0, 19),
            userTimeZone,
          ),
        );

        const second = Math.floor(
          (currentTime.getTime() - new Date(messageDateTime).getTime()) / 1000,
        );
        const remainingTime = 60 - second;
        setTimeLeft(Math.max(remainingTime, 0)); // Prevents negative values
      }
    };

    calculateTimeLeft(); // Initial calculation

    const timerId = setInterval(() => {
      setTimeLeft((prev) => Math.max(prev - 1, 0)); // Prevent negative values during countdown
    }, 1000);

    return () => clearInterval(timerId);
  }, [messageDateTime, userTimeZone]);

  return (
    <span className={`pe-1 ${styles.sendingText}`}>
      Sending escalation in {timeLeft}s
    </span>
  );
};

const EditEscalation = ({ msg }: { msg: IMessageData }) => {
  const originalMsg = msg.escalateDetails?.escalateMsg;
  const [msgStatus, setMsgStatus] = useState<string>(
    msg.escalateDetails?.escalateMsgStatus
      ? msg.escalateDetails?.escalateMsgStatus
      : "",
  );
  const [escalateMsg, setEscalateMsg] = useState(
    msg.escalateDetails?.escalateMsg ? msg.escalateDetails?.escalateMsg : "",
  );
  const [err, setErr] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const dispatch = useAppDispatch();

  //attachement state
  const [attachmentUploadData, setAttachmentUploadData] =
    useState<UploadAttachmentRes>();
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [currentAttachmentBatchNumber, setCurrentAttachmentBatchNumber] =
    useState<string>("");
  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);

  // api call to change the status of the escalate message
  const { mutate, isLoading } = useMutation({
    mutationFn: updateEscalateMsgStatus,
    onSuccess: (data) => {
      setSelectedFiles([]); // clear the attachments selected
      setCurrentAttachmentBatchNumber("");
      if (data.escalateMsgStatus === "completed") {
        setIsSuccess(true);
      } else if (data.escalateMsgStatus === "cancelled") {
        setEscalateMsg(originalMsg ?? "");
      }
      setMsgStatus(data.escalateMsgStatus);
    },
  });

  // api call to escalate message
  const { mutate: sendEscalationMessage, isLoading: isSendingEscalation } =
    useMutation({
      mutationFn: sendEscalateMsg,
      onSuccess: (data) => {
        setIsSuccess(true);
        if (data) {
          dispatch(addMessage(data));
        }
      },
    });

  const handleChangeEscalate = useCallback(
    (status: string) => {
      if (status === "cancelled") {
        mutate({ messageId: Number(msg.messageId), status });
      } else if (status === "paused") {
        mutate({ messageId: Number(msg.messageId), status });
      }
    },
    [mutate, msg.messageId],
  );

  const handleSendEscalateMsg = useCallback(() => {
    //check is there any msg or file available or not.
    if (!checkContent(escalateMsg) && selectedFiles.length === 0) {
      setErr(true);
      return;
    }
    const attachmentBatchNumbers = selectedFiles.map(function (
      value: any,
      index: any,
    ) {
      return value.batchNumber;
    });
    const payload: {
      messageId: number | string;
      message: string;
      attachmentBatchNumber?: string[];
    } = {
      messageId: msg.messageId,
      message: escalateMsg,
    };
    if (attachmentBatchNumbers.length) {
      payload.attachmentBatchNumber = attachmentBatchNumbers;
    }
    sendEscalationMessage(payload);
  }, [escalateMsg, selectedFiles]);

  if (isSuccess) return null;

  return (
    <div className="d-flex justify-content-end mb-3">
      <div className={`${styles.editBox}`}>
        <div
          className={`${styles.msgBox} ${
            (msgStatus === "completed" || msgStatus === "cancelled") &&
            "border-0"
          } ${msgStatus === "paused" && styles.msgBoxWhite}`}
        >
          {msgStatus === "paused" ? (
            <div>
              <TinyMCEContainer
                className={`${err ? styles.errEditor : ""} mt-2`}
                value={escalateMsg}
                onChange={(value: string) => {
                  setEscalateMsg(value);
                  setErr(false);
                }}
                options={{
                  placeholder: "Type your text here...",
                  min_height: 115,
                }}
                uniqueID={uuid()}
                disableAttachment={false}
                attachmentUploadData={attachmentUploadData}
                setAttachmentUploadData={setAttachmentUploadData}
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
                currentAttachmentBatchNumber={currentAttachmentBatchNumber}
                setCurrentAttachmentBatchNumber={
                  setCurrentAttachmentBatchNumber
                }
                isFileUploading={isFileUploading}
                setIsFileUploading={setIsFileUploading}
                areAttachmentsPublic={true}
              />
              <div
                className={`d-flex justify-content-between py-1 ${styles.btnContainer}`}
              >
                <button
                  className={styles.cancelEscaltion}
                  disabled={isLoading}
                  onClick={() => handleChangeEscalate("cancelled")}
                >
                  Cancel escaltion
                  {isLoading && (
                    <Spinner
                      className="ms-1"
                      size="sm"
                    />
                  )}
                </button>
                <button
                  className={`${styles.sendEscalation} d-flex align-items-center`}
                  onClick={handleSendEscalateMsg}
                  disabled={isSendingEscalation}
                >
                  Send escalation
                  {isSendingEscalation && (
                    <Spinner
                      className="ms-1"
                      size="sm"
                    />
                  )}
                </button>
              </div>
            </div>
          ) : (
            <div>
              <ParseHTML
                className={`${styles.msgText} mb-2`}
                html={escalateMsg}
              />
              {msg.attachments?.map((file: any) => (
                <InnerChatAttachments messageData={{ attachments: [file] }} />
              ))}
              {msgStatus === "completed" ? (
                <div className="d-flex justify-content-end">
                  <span className={`${styles.escalate}`}>Escalated!</span>
                </div>
              ) : msgStatus === "cancelled" ? (
                <div className="d-flex justify-content-end">
                  <span className={`${styles.escalateCancel}`}>
                    Escalation cancelled!
                  </span>
                </div>
              ) : (
                <div
                  className={`d-flex justify-content-between align-items-center`}
                >
                  <EscalateTimer messageDateTime={msg.messageDateTime} />
                  <button
                    className={`${styles.editBtn}`}
                    disabled={isLoading}
                    onClick={() => handleChangeEscalate("paused")}
                  >
                    <span>
                      <i className="fa-solid fa-pen"></i>
                    </span>
                    <span className="ps-1">Edit escalation</span>
                    {isLoading && (
                      <Spinner
                        className="ms-1"
                        size="sm"
                      />
                    )}
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
        {msgStatus === "paused" && (
          <div
            className={`d-flex justify-content-end mt-2 me-3 ${styles.editingText}`}
          >
            <span>Editing....</span>
          </div>
        )}
      </div>
      <div className="ms-3">
        {/* {msg?.senderImgURL ? (
          <AxiosImg
            url={msg.senderImgURL}
            className={`${styles.profileIcon}`}
            isDirectURL={msg.isPublicAttachmentUrl}
          />
        ) : (
          <UserAvatar
            name={msg?.senderName || "NA"}
            size={33}
          />
        )} */}
        <div className={styles.profileIcon}></div>
      </div>
    </div>
  );
};

export default EditEscalation;
