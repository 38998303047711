/**
 * This file defines a React component representing the main Custom Q&A page in the ChatBot interface.
 * It includes a search bar, an option to add a new answer, and a table displaying existing answers.
 * It also provides navigation functions and interacts with the `useCustomQa` hook for functionality.
 *
 * @author @Anubhav-busibud
 * @author @navjyot-busibud
 */
import SearchBar from "src/components/SearchBar/SearchBar";
import styles from "./Children/CustomQaTable/CustomQaTable.module.scss";
import Custom from "./Children/CustomQaTable/CustomQaTable";
import NewAnswers from "./Children/CustomQaTable/NewAnswers/NewAnswers";
import { SetStateAction, useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { selectQa } from "src/services/Bot/CustomQA/selectQa.service";
import useCustomQa from "./useCustomQa";
import { useAppSelector } from "src/store/store";
import PlanUpgrade from "src/components/PlanUpgrade/PlanUpgrade";

const CustomQA = () => {
  const {
    handleBackClick,
    handleNewAnswerClick,
    searchTerm,
    setSearchTerm,
    params,
    currentPage,
    setCurrentPage,
    metaData,
    setMetaData,
  } = useCustomQa();

  // Fetching disabledFeatures from the Redux store
  const disabledFeatures = useAppSelector(
    (state) => state.globals.currentUserData?.disabledFeatures,
  );

  return (
    <div className={`w-100 p-2 p-md-3 ${styles.mainWrapper}`}>
      <div className="d-flex flex-column flex-md-row justify-content-between align-items-md-center w-100 ">
        <div className="d-flex">
          {/* Back Button */}
          <div
            className={`cursor-pointer ${styles.backArrow}`}
            role="button"
            onClick={handleBackClick}
          >
            <span>
              <i className="fa-solid fa-arrow-left"></i>
            </span>
          </div>
          <div className="ms-2">
            <h3 className={`mb-0 ${styles.heading}`}>
              {params.subTabSection === "newAnswer"
                ? "New custom Q&A"
                : "Custom Q&A"}
            </h3>
            <span className={`d-block ${styles.subHeading}`}>
              Cern will answer as per there questions and answers here
            </span>
          </div>
        </div>
        {!(disabledFeatures && disabledFeatures.includes("bot_profile")) && (metaData?.count !== 0 && metaData?.total !== 0) && (
          <>
            {/* Search Bar and Add New Button */}
            {params.subTabSection === "newAnswer" ||
              Number.isInteger(Number(params.subTabSection)) ? null : (
              <div className="d-flex mt-2 mt-md-0 flex-column flex-md-row">
                <SearchBar
                  className={`${styles.search} px-2 me-md-2`}
                  inputClassName={`${styles.input}`}
                  placeholder={`Search page`}
                  value={searchTerm}
                  onChange={(e: {
                    target: { value: SetStateAction<string> };
                  }) => setSearchTerm(e.target.value)}
                />
                {/* New Answer button */}
                <button
                  className={`mt-2 mt-md-0 ${styles.importBtn}`}
                  onClick={handleNewAnswerClick}
                >
                  <span className="pe-1">
                    <i className="fa-solid fa-plus"></i>
                  </span>
                  New answer
                </button>
              </div>
            )}
          </>
        )}
      </div>
      {disabledFeatures && disabledFeatures.includes("bot_profile") ? (
        <div className={`${styles.upgradeBox}`}>
          <PlanUpgrade
            upgradeText={"Upgrade to paid plan"}
            subText={"To train on custom Q/A upgrade to paid plan"}
          />
        </div>
      ) : (
        <>
          {params.subTabSection === "newAnswer" ||
            Number.isInteger(Number(params.subTabSection)) ? (
            <div className="">
              <NewAnswers />
            </div>
          ) : (
            <div className={`${styles.tableMain}`}>
              <Custom
                searchTerm={searchTerm}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                metaData={metaData}
                setMetaData={setMetaData}
                status="failed"
                handleNewAnswerClick={handleNewAnswerClick}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CustomQA;
