import { axiosJSON } from "src/globals/axiosEndPoints";


export interface addArticleCategoryParams{
  categoryName : string;
  integrationId : number|string;
}

export async function addArticleCategory(params: addArticleCategoryParams) {
  const { data } = await axiosJSON.post(
    `/api/chatSetting/article/createCategory`,
    params
  );
  if (data.error === true) {
    throw new Error(data.message as string);
  }
  return data.data;
}