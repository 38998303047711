/**
 * This file defines a React component responsible for showing "Take Over" button in bot chat.
 *
 * @author @Anubhav-busibud
 * @author @navjyot-busibud
 */
import styles from "./BotChatPickBox.module.scss";

interface Prop {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  showLoader: boolean;
  botName: string;
  isConnected?: boolean; // 02 Jan added to change text when bot chat disconnected
}

const BotChatPickBox = ({
  onClick,
  showLoader,
  botName,
  isConnected,
}: Prop) => {
  return (
    <div className={`${styles.pickBox} py-4 px-2 text-center`}>
      <p className={`pt-2 px-3 ${styles.pickHead}`}>
        {/* 02 Jan added to change text when bot chat disconnected */}
        {isConnected ? `This is being handled by ` : `This was handled by `}
        {` ${botName} Bot, and the user has not requested human assistance. Take over regardless?`}
      </p>

      <div className="pt-3 pb-2">
        <button
          className={`border-0 py-2 px-4 ${styles.pickBtn}`}
          disabled={showLoader}
          onClick={onClick}
        >
          {showLoader ? "Assigning….." : "Take over"}
        </button>
      </div>
    </div>
  );
};

export default BotChatPickBox;
