import { useAppSelector } from "src/store/store";
import ReportTicketBody from "../../../ReportTicketBody/ReportTicketBody";

const CurrentStatusReports = () => {
  // Get UI Visibility
  const ui_visibility = useAppSelector(
    (state) => state.globals.currentUserData?.ui_visibility,
  );

  return (
    <div className="mb-2">
      {/* Total unresolved tickets */}
      {ui_visibility?.includes(
        "tickets_currentStatus_totalUnresolvedTickets",
      ) && (
        <ReportTicketBody
          endPoint={"tickets/currentStatus/totalUnresolvedTickets"}
          title={"Total number of unresolved tickets"}
          tooltip={
            "Total number of open or pending tickets that have not been closed yet."
          }
          tabProps={{
            showComparison: false,
            showDailyPart: false,
            showListView: true,
            showNotifyTab: true,
          }}
        />
      )}
      {/* Unresolved tickets pending for the longest time */}
      {ui_visibility?.includes(
        "tickets_currentStatus_unresolvedTicketsPendingLongest",
      ) && (
        <ReportTicketBody
          endPoint={"tickets/currentStatus/unresolvedTicketsPendingLongest"}
          title={"Unresolved tickets pending for the longest time"}
          tooltip={
            "Unresolved tickets refer to open or pending tickets that remain active and have not yet been closed, based solely on their current status since their creation. The longer a ticket remains unresolved, the greater the duration of its open status."
          }
          tabProps={{
            showComparison: false,
            showDailyPart: false,
            showListView: true,
          }}
        />
      )}
      {/* Unresolved ticket with the most back and forth */}
      {ui_visibility?.includes(
        "tickets_currentStatus_ticketsMostBackForth",
      ) && (
        <ReportTicketBody
          endPoint={"tickets/currentStatus/ticketsMostBackForth"}
          title={"Unresolved ticket with the most back and forth"}
          tooltip={
            "This graph represents the total number of messages exchanged between our team and the customer. A higher volume of exchanged messages (both sent and received) indicates more back-and-forth communication regarding the unresolved ticket."
          }
          tabProps={{
            showComparison: false,
            showDailyPart: false,
            showListView: true,
          }}
        />
      )}
      {/* Pending Tickets V/s Usual Capacity */}
      {ui_visibility?.includes("tickets_currentStatus_usualCapacity") && (
        <ReportTicketBody
          endPoint={"tickets/currentStatus/usualCapacity"}
          title={"Pending Tickets V/s Usual Capacity"}
          tooltip={
            "Usual capacity is the average daily number of messages sent through a channel. This estimate assumes that only one message will be sent for each pending ticket today."
          }
          tabProps={{
            showComparison: false,
            showDailyPart: false,
            showListView: false, //Jun 24 2024 hided list view as it is not implemented
          }}
        />
      )}
      {/* PotentiallyUrgent tickets */}
      {ui_visibility?.includes("tickets_currentStatus_potentiallyUrgent") && (
        <ReportTicketBody
          endPoint={"tickets/currentStatus/potentiallyUrgent"}
          title={"Unresolved Tickets which are potentially urgent"}
          tooltip={
            "Total number of open or pending tickets that may require urgent action, such as modifying an order."
          }
          tabProps={{
            showComparison: false,
            showDailyPart: false,
            showListView: true,
          }}
        />
      )}
      {/* Unresolved or Recently Closed Tickets From Angry Customers */}
      {ui_visibility?.includes("tickets_currentStatus_angryCustomers") && (
        <ReportTicketBody
          endPoint={"tickets/currentStatus/angryCustomers"}
          title={"Unresolved or Recently Closed Tickets From Angry Customers"}
          tooltip={
            "The unresolved or recently closed tickets (closed within the last 24 hours) involving dissatisfied customers. Dissatisfied customers are those who have provided a low satisfaction rating."
          }
          tabProps={{
            showComparison: false,
            showDailyPart: false,
            showListView: true,
          }}
        />
      )}

      {/* Unresolved or Recently Resolved Pre-Purchase Customers That Did Not Convert */}
      {ui_visibility?.includes(
        "tickets_currentStatus_notConvertedPrePurchase",
      ) && (
        <ReportTicketBody
          endPoint={"tickets/currentStatus/notConvertedPrePurchase"}
          title={
            "Unresolved or Recently Resolved Pre-Purchase Customers That Did Not Convert"
          }
          tooltip={
            "Unresolved or recently closed tickets (those closed within the last 24 hours) that involve customers with pre-purchase inquiries who did not convert."
          }
          tabProps={{
            showComparison: false,
            showDailyPart: false,
            showListView: true,
          }}
        />
      )}
      {/* Unresolved or Recently Resolved Pre-Purchase Customers That Did Convert */}
      {ui_visibility?.includes(
        "tickets_currentStatus_convertedPrePurchase",
      ) && (
        <ReportTicketBody
          endPoint={"tickets/currentStatus/convertedPrePurchase"}
          title={
            "Unresolved or Recently Resolved Pre-Purchase Customers That Did Convert"
          }
          tooltip={
            "Unresolved or recently closed tickets (those closed within the last 24 hours) that involve customers with pre-purchase inquiries who did convert."
          }
          tabProps={{
            showComparison: false,
            showDailyPart: false,
            showListView: true,
          }}
        />
      )}
    </div>
  );
};

export default CurrentStatusReports;
