/**
 * This file is the component file.
 *
 * @author Yash Aditya
 */

import { useCallback, useMemo, useState } from "react";
import { useFreqSettings } from "../../hooks/useFreqSettings";
import { notifyEnableDisableService } from "src/services/Settings/AgentNotifications/notifyEnableDisable.service";
import { Spinner } from "react-bootstrap";
import styles from "./NotifyEnable.module.scss";
import useNotifySetPermission from "../../hooks/useNotifySetPermission";
/**
 * Component representing the switch for enabling or disabling notifications.
 * Uses frequency settings and a service to toggle notification status.
 */
function NotifyEnabled() {
  // Custom hook to access frequency settings and dispatch actions
  const { freqSettings, dispatch } = useFreqSettings();
  const notifyPermission = useNotifySetPermission();

  // State to track loading status during notification toggle
  const [loading, setLoading] = useState(false);

  // Memoized value indicating whether notifications are currently enabled
  const isChecked = useMemo(
    () => freqSettings.notificationsEnabled,
    [freqSettings]
  );

  /**
   * Handler function for toggling the notification switch.
   * Updates the loading state, triggers the service call, and dispatches the updated notification status.
   */
  const handleCheckUncheck = useCallback(() => {
    setLoading(true);
    notifyEnableDisableService({
      enabled: !isChecked,
    })
      .then((res) => {
        // Dispatch action to update notification status
        dispatch("setNotificationEnabled", !isChecked);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [isChecked, dispatch]);

  return (
    <div className="d-flex justify-content-center align-items-center">
      {/* Display a loading spinner during the service call */}
      {loading && <Spinner size="sm" animation="border" className="me-2" />}
      {/* Switch input for enabling or disabling notifications */}
      <div className="form-check form-switch">
        <input
          disabled={loading || notifyPermission !== "edit"}
          className={`form-check-input ${styles.handleCheckToggle}`}
          style={{
            cursor: notifyPermission === "edit" ? "pointer" : "not-allowed",
            pointerEvents: "auto",
          }}
          type="checkbox"
          role="switch"
          id="flexSwitchCheckDefault"
          checked={isChecked}
          onChange={() => {}}
          onClick={handleCheckUncheck}
        />
      </div>
    </div>
  );
}

export default NotifyEnabled;
