import { useMemo } from "react";
import { Dropdown } from "react-bootstrap";
import useCustomFilters from "../../hooks/useCustomFilters";
import { useSegmentView } from "../../hooks/useSegmentView";
import styles from "./CustomFilters.module.scss";

const ConditionFilters = ({
  conditionFilters,
}: {
  conditionFilters: string[];
}) => {
  return (
    <>
      {conditionFilters.map((value, idx) => (
        <div
          key={`${value}_${idx}`}
          className="my-1 d-flex justify-content-start"
        >
          <div className={`${styles.listItem}`}>{value}</div>
        </div>
      ))}
    </>
  );
};

/**
 * Component for rendering custom filters dropdown based on active segment details.
 */
const CustomFilters = () => {
  const { activeSegmentDetails } = useSegmentView();
  const { customFiltersData, customFiltersFetchStatus } = useCustomFilters({
    segmentType: activeSegmentDetails.activeSegmentType + "",
    segmentId: activeSegmentDetails.activeSegmentId + "",
  });

  /**
   * Added a memoized state to fix the react leaving an extra element in dropdown when filters change.
   * Reason for this is unkown but memoized state fixes it
   */
  const filters = useMemo(
    () => customFiltersData?.customFilters ?? [],
    [customFiltersData?.customFilters],
  );

  return (
    <Dropdown>
      {/* Dropdown toggle button */}
      <Dropdown.Toggle
        id="dropdown-custom-filters-customer-segments"
        className={`${styles.filterBtn}`}
      >
        {/* Conditional rendering based on fetch status */}
        {customFiltersFetchStatus === "pending"
          ? "Loading filters..."
          : customFiltersFetchStatus === "rejected"
            ? "Error"
            : `${customFiltersData?.numberOfFilters ?? 0} filters used`}
      </Dropdown.Toggle>

      {/* Dropdown menu */}
      <Dropdown.Menu
        className={`py-1 px-1 ${styles.dropdownMenu}`}
        role="div"
      >
        {/* Rendering custom filters */}
        {filters.map((filter, idx) => {
          return (
            <div key={`${filter.groupLogicalOperator}_custom_filters_${idx}`}>
              <ConditionFilters
                conditionFilters={filter.conditionFilter}
                key={`${filter.groupLogicalOperator}_condition_filters_${idx}`}
              />
              {filter.groupLogicalOperator && (
                <div
                  key={`${filter.groupLogicalOperator}_group_operator_${idx}`}
                  className="my-1 d-flex justify-content-center"
                >
                  <div className={`${styles.listGroupItem}`}>
                    ---- {filter.groupLogicalOperator} ----
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default CustomFilters;
