/**
 * This file is the custome hook file.
 * It contains useNotifyPermission hook which is used to return the permission for report's notify.
 *
 * @author Yash Aditya
 */

import { useMemo } from "react";
import { useAppSelector } from "src/store/store";

function useNotifyPermission() {
  const scopes = useAppSelector(
    (state) => state.globals.currentUserData?.scopes
  );
  return useMemo(() => {
    if (scopes?.includes("edit_report_notification")) {
      return "edit";
    } else if (scopes?.includes("show_report_notification")) {
      return "show";
    } else {
      return "none";
    }
  }, [scopes]);
}

export default useNotifyPermission;
