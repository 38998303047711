import TabSection from "src/routes/LiveChat/Children/TabSection";

import styles from "./NewTargetedMessage.module.scss";
import TargetWidget from "../../Children/TargetWidget/TargetWidget";
import { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import DiscardModal from "./Children/DiscardModal/DiscardModal";
import NewTargetedMessageSidebar from "../../../LeftSideBarSection/NewTargetedMessageSidebar/NewTargetedMessageSidebar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { fetchGetTargetedMessage } from "src/store/slices/targetedMessage/targetedMessage.thunks";

function NewTargetedMessage() {
  const params = useParams();

  let headingType;

  if (params.settingName === "newGreeting") {
    headingType = "Greeting";
  } else {
    headingType = "Announcement";
  }

  const [showModal, setShowModal] = useState(false);

  const onShow = useCallback(() => {
    setShowModal(true);
  }, []);
  const onHide = useCallback(() => {
    setShowModal(false);
  }, []);

  const navigate = useNavigate();
  const [isSavingData, setIsSavingData] = useState(false);
  const doneSavingData = useCallback((data: any) => {
    if (data) {
      setIsSavingData(false);
      navigate(
        `/live-chat/settings/${params.integrationId}/${params.settingName}/conditions`
      );
    }
  }, []);

  function handleClick() {
    setIsSavingData(true);
  }

  const [SideBarDropdownType, SetSideBarDropDownType] = useState(1);

  function dropdownType(type: any) {
    SetSideBarDropDownType(type);
  }

  return (
    <div className={`${styles.liveWrapper} `}>
      <div className="d-flex">
        <NewTargetedMessageSidebar
          heading={headingType}
          GetDropdownType={dropdownType}
        />
        <div className="w-75">
          <TargetWidget
            sideBarType={SideBarDropdownType}
            isSavingData={isSavingData}
            doneSavingData={doneSavingData}
          />
        </div>
      </div>

      <div
        className={`d-flex justify-content-end shadow-lg pe-5 ${styles.bottomSection} `}
      >
        <div className="mt-2">
          <button
            className={`rounded bg-white border  px-3 ${styles.cancelBtn}`}
            onClick={onShow}
          >
            Cancel
          </button>
        </div>
        <Modal
          backdropClassName={`${styles.modalBack}`}
          show={showModal}
          onHide={onHide}
          dialogClassName={`${styles.modalDialog}`}
          contentClassName={`${styles.modalContent}`}
          //   centered={true}
          enforceFocus={false}
        >
          <DiscardModal onHide={onHide} />
        </Modal>
        <div className={`mt-2 `}>
          <button
            className={`rounded px-3 ms-2 ${styles.setBtn}`}
            onClick={handleClick}
          >
            {isSavingData ? "Set Conditions" : "Setting Conditions"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default NewTargetedMessage;

{
  /* <div className="d-flex" style={{ height: "90%" }}>
        <NewTargetedMessageSidebar heading={headingType} />
        <div className={` ${styles.LiveMainWrapper} `}>
          <TargetWidget />
        </div>
      </div>
      <div className="shadow-lg rounded-bottom  " style={{ height: "20%" }}>
        <div className="d-flex justify-content-end mt-3 me-5 ">
          <button
            className={`rounded bg-white border  px-3 ${styles.cancelBtn}`}
            onClick={onShow}
          >
            Cancel
          </button>
          <Modal
            backdropClassName={`${styles.modalBack}`}
            show={showModal}
            onHide={onHide}
            dialogClassName={`${styles.modalDialog}`}
            contentClassName={`${styles.modalContent}`}
            //   centered={true}
            enforceFocus={false}
          >
            <DiscardModal onHide={onHide} />
          </Modal>
          <div className={` `}>
            <button className={`rounded px-3 ms-2 ${styles.setBtn}`}>
              Set Conditions
            </button>
          </div>
        </div>
      </div> */
}
