import styles from "./DeleteKb.module.scss";
import useDeleteKb from "./useDeleteKb";
interface Props {
  onHide: () => void;
  knowledgeBaseId: string | number;
  brandName: string;
  setShowSuccessModal: (value: boolean) => void;
}
const DeleteKb = ({
  onHide,
  knowledgeBaseId,
  brandName,
  setShowSuccessModal,
}: Props) => {
  const { handleDelete, isDeleting } = useDeleteKb({
    knowledgeBaseId,
    setShowSuccessModal,
    onHide,
  });

  return (
    <div className={`w-100 ${styles.deleteWrap}`}>
      {/* Delete Modal */}
      <div className="d-flex justify-content-between align-items-center w-100">
        <p className={`mb-0 ${styles.heading}`}>Confirmation:</p>
        <span className={`cursor-pointer ${styles.delete}`} onClick={onHide}>
          <i className="fa-solid fa-xmark"></i>
        </span>
      </div>
      <span className={`pt-1 ${styles.subText}`}>
        Delete this brand and its related articles?
      </span>
      <div
        className={`d-flex justify-content-center align-items-center ${styles.buttonWrap}`}
      >
        <button className={`${styles.cancelBtn}`} onClick={onHide}>
          Cancel
        </button>
        <button className={`${styles.deleteBtn}`} onClick={handleDelete}>
          Delete
          {isDeleting && (
            <span
              className="spinner-border spinner-border-sm ms-2"
              role="status"
              aria-hidden="true"
            ></span>
          )}
        </button>
      </div>
    </div>
  );
};

export default DeleteKb;
