import { axiosJSON } from "src/globals/axiosEndPoints";
import {
  AUTOMATION_STEP_CONFIG_STATUS,
  AUTOMATION_STEP_STATUS,
  IStepQuestion,
} from "src/store/slices/initiateReturn/retrunExchange.slice";

export interface step9to15ParamsGet {
  stepData: {
    fully_automate_current_step: boolean;
    transfer_to_agent: boolean;
    confirmationMessage: string;
    stepStatus: AUTOMATION_STEP_STATUS;
    stepQuestions: Array<IStepQuestion>;
    configStatus: AUTOMATION_STEP_CONFIG_STATUS;
  };
}
export interface IGetStep9to15Params {
  integrationId: number | string;
}

export async function fetchGetStep9(params: IGetStep9to15Params) {
  const { data } = await axiosJSON.get(
    "/api/setting/automation/refund/step/9",
    { params }
  ); //passing the integration id in payload

  // const data = await getStep9();

  if (data.error === true) {
    throw new Error("Some Error Occurred while fetching data");
  }

  return data.data;
}

// let getStep9 = function (): Promise<step9to15Params> {
//   return new Promise(function (resolve, reject) {
//     resolve({
//       stepData: {
//         fully_automate_current_step: false,
//         transfer_to_agent: false,
//       },
//     } as step9to15Params);
//   });
// };
