import { useCallback } from "react";
import { useUploadNewCsvModalContext } from "../Hooks/useUploadNewCsvModalContext";
import styles from "./ConfigureUpload.module.scss";
import csvUpdateQuestions from "src/services/ReturnAutoWorkFlow/ConfigModals/csvUpdateQuestions.service";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { useParams } from "react-router-dom";

/**
 * Component that renders the first step in the upload new csv modal
 * Handles the quetion value changes
 */
const ConfigureUpload = () => {
  const { state, updateState, showErrors } = useUploadNewCsvModalContext();
  const { integrationId } = useParams();

  /**
   * Function to update the question Value
   */
  const onChangeHandler = useCallback(
    (optionKey: string, questionId: string) => {
      // Track the original configureCSVQuestions
      const originalQuestions = state.configureUpload?.configureCSVQuestions;

      // Map over the configureCSVQuestions array to update the questionValue for the matching questionId
      const updatedQuestions = state.configureUpload?.configureCSVQuestions.map(
        (question) =>
          question.questionId === questionId
            ? { ...question, questionValue: optionKey }
            : question,
      );
      // Update the state with the modified configureCSVQuestions array
      updateState({
        configureUpload: {
          configureCSVQuestions: updatedQuestions,
        },
      });
    },
    [state.configureUpload?.configureCSVQuestions, integrationId],
  );

  return (
    <div>
      <h4 className={`${styles.heading} mb-4`}>Upload csv</h4>
      {state.configureUpload?.configureCSVQuestions?.map((question) => {
        return (
          <div key={question.questionId}>
            <div>
              <h5 className={`${styles.subHeading}`}>
                {question.questionTitle}
              </h5>
              {(question?.options ?? []).map((option) => {
                return (
                  <div>
                    <div className="form-check">
                      <input
                        className={`form-check-input ${
                          showErrors && !question.questionValue
                            ? "border-danger"
                            : ""
                        }`}
                        type="radio"
                        name={question.questionId}
                        id={option.value}
                        checked={
                          question.questionValue
                            ? question.questionValue === option.value
                            : false
                        }
                        style={{ width: "14px", height: "14px" }}
                        onChange={() =>
                          onChangeHandler(option.value, question.questionId)
                        }
                      />
                      <label
                        className={`form-check-label ${styles.radioLabel}`}
                        htmlFor={option.value}
                      >
                        {option.label}
                      </label>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ConfigureUpload;
