import styles from "./Success.module.scss";
import loadingGif from "src/assets/images/loading.gif";
import doneGif from "src/assets/images/done.gif";
import { ModalBody, ModalHeader } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "src/store/store";
import { setLastActiveTableType } from "src/store/slices/innerTicket/innerTicket.slice";

function Success({
  ticketId,
  onCloseModel,
}: {
  ticketId: string;
  onCloseModel: Function;
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { tableType, viewType } = useParams();

  return (
    <>
      <ModalHeader className={`successMsg`}>
        <button
          type="button"
          className={`btn-close`}
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={(e) => {
            onCloseModel();
          }}
        />
      </ModalHeader>
      <ModalBody>
        {ticketId === "" && (
          <div className={`text-center`}>
            <div className={`${styles.loading}`}>
              <img src={loadingGif} alt="loading" />
            </div>
          </div>
        )}
        {ticketId !== "" && (
          <div className={`text-center`}>
            <div className={`${styles.successTickMain}`}>
              <img src={doneGif} alt="Done" />
            </div>
            <p className={`${styles.successLead}`}>
              Ticket has been sucessfully created!
            </p>
            <span className={`${styles.successLead}`}>
              You can now add tags and assign tickets to your team members.
            </span>
            <div className={`${styles.successTicketDiv}`}>
              <span className={`${styles.successLead} ${styles.ticketBox}`}>
                Ticket ID: <span>{ticketId}</span>
              </span>
              <div>
                <button
                  className={`${styles.successViewTicket}`}
                  onClick={(e) => {
                    if(tableType === "mentions"){
                      dispatch(setLastActiveTableType({ lastActiveTableType: tableType }));
                    }
                    onCloseModel();
                    navigate(
                      `/tickets/${tableType === "mentions" ? "all" : tableType}/${viewType}/ticket/${ticketId}/${window.location.search}`.replace(
                        "//",
                        "/"
                      )
                    );
                  }}
                >
                  View Ticket
                </button>
              </div>
            </div>
          </div>
        )}
      </ModalBody>
    </>
  );
}

export default Success;
