import React, { useCallback, useState } from "react";
import { Spinner } from "react-bootstrap";
import InfiniteScroll from "src/components/InfiniteScroll";
import { IArticleCategoryDataV2 } from "src/services/LiveChat/Settings/knowledgeBased/articleCategory/getAllArticleCategoryV2";
import { useAppSelector } from "src/store/store";
import ArticleNameRow from "../ArticleNameRow/ArticleNameRow";
import styles from "./CategoryFolder.module.scss";
import folder from "src/assets/images/folderIcon.svg";

interface Props {
  categoryData: IArticleCategoryDataV2;
  handleArticleInfiniteScroll: (categoryId: number | string) => void;
  hasMoreArticles: (categoryId: number | string) => boolean;
}
const CategoryFolder = ({
  categoryData,
  handleArticleInfiniteScroll,
  hasMoreArticles,
}: Props) => {
  return (
    <div className="d-flex mb-2">
      <span>
        {/* <i className="fa-regular fa-folder"></i> */}
        <img src={folder} alt="dots" className={`${styles.folderImg}`} />
      </span>
      <div className="w-100">
        <span className={`ps-2 d-inline-block mb-1 ${styles.category}`}>
          {categoryData.categoryName}
        </span>
        <div className={`w-100 p-0 ${styles.featureBox} ${categoryData.metaData.total >= 10 ? styles.fixedHeight : ""}`}>
          <InfiniteScroll
            className={`${styles.infScroll} px-4 py-3`}
            loadMore={() => {
              handleArticleInfiniteScroll(categoryData.categoryId);
            }}
            initialDataLoaded={false}
            loader={
              categoryData.ajaxStatus === "pending" &&
              categoryData.articlesIds.length === 0 ? (
                <div className={`${styles.loading} m-auto`}>
                  <Spinner
                    animation="border"
                    variant="primary"
                    size="sm"
                    className={`d-block m-auto mb-2`}
                  />
                  <span>Loading articles...</span>
                </div>
              ) : (
                <div className="p-2">
                  <Spinner animation="border" variant="secondary" />
                </div>
              )
            }
            hasMore={hasMoreArticles(categoryData.categoryId)}
          >
            {categoryData.articlesIds.map((articleId) => {
              const article = categoryData.articles[articleId];

              return <ArticleNameRow key={articleId} article={article} />;
            })}
          </InfiniteScroll>

          {/* <div className="form-check mb-2">
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            id="flexCheckDefault"
          />
          <label
            className={`form-check-label ${styles.formLabel}`}
            htmlFor="flexCheckDefault"
          >
            #3 tips to maintain your acme shoes
            <span className={`ps-2 ${styles.viewText}`}>View</span>
          </label>
        </div>
        <div className="form-check mb-2">
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            id="flexCheckDefault"
          />
          <label
            className={`form-check-label ${styles.formLabel}`}
            htmlFor="flexCheckDefault"
          >
            What are the costs of shipping to NYC ?
            <span className={`ps-2 ${styles.viewText}`}>View</span>
          </label>
        </div>
        <div className="form-check mb-2">
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            id="flexCheckDefault"
          />
          <label
            className={`form-check-label ${styles.formLabel}`}
            htmlFor="flexCheckDefault"
          >
            How can we claim a warranty in the order ?
            <span className={`ps-2 ${styles.viewText}`}>View</span>
          </label>
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default CategoryFolder;
