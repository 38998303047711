import { IAssignChatRes } from "src/services/LiveChat/traffic/assignChatFromTraffic.service";
import {
  ITabTypeCount,
  ITrafficListItem,
} from "src/services/LiveChat/traffic/getTrafficList.service";
import {
  ILiveChatTrafficState,
  setTrafficData,
  setTrafficTypeTabCount,
} from "./liveChatTraffic.slice";

export const fetchLiveChatTrafficFulfilled = (
  state: ILiveChatTrafficState,
  { payload }: any
) => {
  const {
    trafficItemList,
    trafficItemIdsList,
    trafficTypeTabCount,
    metaData,
  }: any = payload as any;

  state.trafficItemList = {
    ...state.trafficItemList,
    ...trafficItemList,
  };
  state.trafficItemIdsList = [
    ...state.trafficItemIdsList,
    ...trafficItemIdsList,
  ];

  state.trafficTypeTabCount.all = trafficTypeTabCount.all;
  state.trafficTypeTabCount.chatting = trafficTypeTabCount.chatting;
  state.trafficTypeTabCount.queued = trafficTypeTabCount.queued;
  state.trafficTypeTabCount.waitingForReply =
    trafficTypeTabCount.waitingForReply;

  state.metaData.count = metaData.count;
  state.metaData.total = metaData.total;
  state.fetchTrafficDataAjaxStatus = "fulfilled";
};

export const fetchLiveChatUpdatedTrafficFulfilled = (
  state: ILiveChatTrafficState,
  { payload }: any
) => {
  const { tabCount, trafficData, currentState}: any = payload as any;

  // update the tab count
  if (tabCount && state.fetchTrafficDataAjaxStatus !== "pending") {
    let trafficTabCount: ITabTypeCount = {
      all: tabCount?.all ?? 0,
      chatting: tabCount?.chatting ?? 0,
      queued: tabCount?.queued ?? 0,
      waitingForReply: tabCount?.waiting_for_reply ?? 0,
    };
    // update the tab count
    state.trafficTypeTabCount = trafficTabCount;
  }

  if (trafficData && state.fetchTrafficDataAjaxStatus !== "pending") {
    let trafficDataForActiveTabType: any = trafficData[state.trafficFilterType];
    let trafficItemListTemp: { [id: number | string]: ITrafficListItem } = {};
    let trafficItemIdsListTemp: Array<number | string> = [];

    // when there is no traffic data in redux
    if (state.trafficItemIdsList.length === 0) {
      // currently there is nothing shown, so set the latest fetchLimit data
      for (
        let i = 0;
        i < state.trafficFetchLimit && trafficDataForActiveTabType[i];
        i++
      ) {
        let trafficItem: ITrafficListItem = trafficDataForActiveTabType[i];
        if (trafficItem.action === null) {

          
          const assignedAgentIds = trafficItem?.assignedAgents &&  trafficItem?.assignedAgents.length !== 0 ? trafficItem?.assignedAgents.map((agent)=>agent.id) : [];

          // null is set when the action cannot be identified from backend

          
          if (
            trafficItem.assignedAgents &&
            trafficItem.assignedAgents.length !== 0 &&
            assignedAgentIds.includes(currentState?.globals?.currentUserData?.userId)
          ) {
            trafficItem.action = "go_to_chat";
          } else {
            trafficItem.action = "supervise";
          }
        }
        trafficItemListTemp[trafficItem.id] = trafficItem;
        trafficItemIdsListTemp.push(trafficItem.id);
      }
      let metaData: any = {
        count: trafficItemIdsListTemp.length,
        total: trafficDataForActiveTabType.length,
      };

      // update traffic data
      state.trafficItemList = trafficItemListTemp;
      state.trafficItemIdsList = trafficItemIdsListTemp;
      state.metaData = metaData;

    } else {
      // there is some data already in redux
      let a: number = Math.floor(state.trafficItemIdsList.length / 10);
      let limit: number = (a + 1) * state?.trafficFetchLimit;
      // console.log(" Else a:: ", a);
      // console.log(" Else limit:: ", limit);
      for (let i = 0; i < limit && trafficDataForActiveTabType[i]; i++) {
        let trafficItem: ITrafficListItem = trafficDataForActiveTabType[i];
        if (trafficItem.action === null) {
          // null is set when the action cannot be identified from backend
          const assignedAgentIds = trafficItem?.assignedAgents &&  trafficItem?.assignedAgents.length !== 0 ? trafficItem?.assignedAgents.map((agent)=>agent.id) : [];

          if (
            trafficItem.assignedAgents &&
            trafficItem.assignedAgents.length &&
            assignedAgentIds.includes(currentState?.globals?.currentUserData?.userId)
          ) {
            trafficItem.action = "go_to_chat";
          } else {
            trafficItem.action = "supervise";
          }
        }
        trafficItemListTemp[trafficItem.id] = trafficItem;
        trafficItemIdsListTemp.push(trafficItem.id);
      }
      let metaData: any = {
        count: trafficItemIdsListTemp.length,
        total: trafficDataForActiveTabType.length,
      };

      // set the traffic data
      state.trafficItemList = trafficItemListTemp;
      state.trafficItemIdsList = trafficItemIdsListTemp;
      state.metaData = metaData;
  
    }
  }

  state.fetchUpdatedTrafficDataAjaxStatus = "fulfilled";
};

export const assignChatFulfilled = (
  state: ILiveChatTrafficState,
  { payload }: any
) => {
  const { statusCode, msg }: IAssignChatRes = payload as IAssignChatRes;

  state.assignChatStatusCode = statusCode;

  state.assignChatAjaxStatus = "fulfilled";
};

export default {
  fetchLiveChatTrafficFulfilled,
  assignChatFulfilled,
  fetchLiveChatUpdatedTrafficFulfilled,
};
