import { createAsyncThunk } from "@reduxjs/toolkit";
import { channelSubscriber, channelUnsubscriber } from "src/globals/pusher";
import {
  verifiedEmailEvent,
  VerifyEmailEvent,
} from "src/pusherServices/emailIntegration/verifyEmail";
import {
  addEmail,
  AddEmailParam,
} from "src/services/EmailIntegration/addEmail";
import {
  deleteEmailIntegration,
  DeleteEmailIntegrationParams,
} from "src/services/EmailIntegration/deleteEmailIntegration";
import { getAllEmailIntegrations } from "src/services/EmailIntegration/getAllEmailIntegrations";
import {
  GetBrandParams,
  getBrands,
} from "src/services/EmailIntegration/getBrands";
import {
  getEmailIntegrationById,
  GetEmailIntegrationByIdParams,
} from "src/services/EmailIntegration/getEmailIntegrationById";
import {
  resendVerificationCode,
  ResendVerificationCodeParams,
} from "src/services/EmailIntegration/resendVerificationCode";
import {
  updateEmailIntegration,
  UpdateEmailIntegrationParams,
} from "src/services/EmailIntegration/updateEmailIntegration";
import {
  verifyDomainEmailAuthById,
  VerifyDomainEmailAuthParams,
} from "src/services/EmailIntegration/verifyEmailDomainAuth";
import {
  verifyEmailForwarding,
  VerifyEmailForwardingParams,
} from "src/services/EmailIntegration/verifyEmailForwarding";
import { actions } from "./emailIntegration.slices";

interface FetchGetEmailThunkI extends AddEmailParam {
  callback?: (id: number | string) => void;
}

export const fetchGetEmailThunk = createAsyncThunk(
  "fetchGetEmailThunk",
  async ({ callback, ...payload }: FetchGetEmailThunkI, { dispatch }) => {
    const res = await addEmail(payload); // async action
    if (res) {
      dispatch(actions.addEmail(res)); // sync action
    }
    setTimeout(() => {
      if (callback) {
        callback(res.emailIntegrationId);
      }
    }, 0);
    return res;
  }
);

interface FetchVerifyEmailEventParams {
  callback?: (res: VerifyEmailEvent) => void;
  navigateCallback?: () => void;
  channelName: string;
}

export const fetchGetBrands = createAsyncThunk(
  "fetchGetBrand",
  async (payload: GetBrandParams, { dispatch }) => {
    const res = await getBrands(payload);
    if (res) {
      dispatch(actions.getBrands(res)); // sync action
    }

    return res;
  }
);

export const fetchVerifyDomainAuth = createAsyncThunk(
  "fetchVerifyDomainAuth",
  async (payload: VerifyDomainEmailAuthParams, { dispatch }) => {
    const res = await verifyDomainEmailAuthById(payload);
    if (res) {
      dispatch(actions.updateDomainAuth(res));
    }
    return res;
  }
);

export const fetchGetAllEmailIntegration = createAsyncThunk(
  "fetchGetAllEmailIntegration",
  async (payload: undefined, { dispatch }) => {
    const res = await getAllEmailIntegrations({ start: 0, limit: 100 });
    if (res) {
      dispatch(actions.getAllEmailIntegration(res));
    }
    return res;
  }
);

interface FetchGetEmailIntegrationByIdI extends GetEmailIntegrationByIdParams {
  callback?: (id?: number | string) => void;
}

export const fetchGetEmailIntegrationById = createAsyncThunk(
  "fetchGetEmailIntegrationById",
  async (
    { callback, ...payload }: FetchGetEmailIntegrationByIdI,
    { dispatch }
  ) => {
    try {
      const res = await getEmailIntegrationById(payload);
      if (res) {
        dispatch(actions.getEmailIntegrationById(res));
      }
      if (!res.isEmailVerified) {
        setTimeout(() => {
          if (callback) {
            callback("email_not_verified");
          }
        }, 0);
      } else if (!res.isDomainVerified) {
        setTimeout(() => {
          if (callback) {
            callback("domain_not_verified");
          }
        }, 0);
      } else if (res.isDomainVerified && res.isEmailVerified) {
        setTimeout(() => {
          if (callback) {
            callback("verification_complete");
          }
        }, 0);
      }
      return res;
    } catch (e) {
      setTimeout(() => {
        if (callback) {
          callback();
        }
      }, 0);
      throw e;
    }
  }
);

const fetchVerifyEmailCallback = (channelName: string) => {
  return new Promise((res, rej) => {
    if (channelName) {
      const channel = channelSubscriber(channelName);
      if (channel) {
        verifiedEmailEvent(channel, (data: any) => {
          if (typeof data === "string") {
            try {
              data = JSON.parse(data);
            } catch {}
          }
          if (data.verifiedSuccessfully) {
            res("Verified");
          } else {
            rej("Not Verified");
          }
          channelUnsubscriber(channelName);
        });
        setTimeout(() => {
          channelUnsubscriber(channelName);
          rej("Event Not Found ");
        }, 30000);
      } else {
        rej("Channel Not Subscribed");
      }
    }
  });
};

export const fetchVerifyEmailForwarding = createAsyncThunk(
  "fetchVerifyEmailForwarding",
  async (payload: {
    forwardingParams: VerifyEmailForwardingParams;
    eventParams: FetchVerifyEmailEventParams;
  }) => {
    verifyEmailForwarding(payload.forwardingParams);
    return await fetchVerifyEmailCallback(payload.eventParams.channelName);
  }
);

export const fetchDeleteEmailIntegration = createAsyncThunk(
  "fetchDeleteEmailIntegration",
  async (payload: DeleteEmailIntegrationParams) => {
    const res = await deleteEmailIntegration(payload); // async action
    // if (res) {
    //   dispatch(
    //     actions.deleteEmail({
    //       ememailIntegrationId: payload.emailIntegrationId,
    //     })
    //   ); // sync action
    // }
    return res;
  }
);

interface FetchUpdateEmailIntegrationI extends UpdateEmailIntegrationParams {
  callback?: (id: number | string) => void;
}

export const fetchUpdateEmailIntegration = createAsyncThunk(
  "fetchUpdateEmailIntegration",
  async (
    { callback, ...payload }: FetchUpdateEmailIntegrationI,
    { dispatch }
  ) => {
    await updateEmailIntegration(payload);
    setTimeout(() => {
      if (callback) {
        callback(payload.emailIntegrationId);
      }
    }, 0);
    return { addressName: payload.addressName };
  }
);

export const fetchResendVerificationCode = createAsyncThunk(
  "fetchResendVerificationCode",
  async (payload: ResendVerificationCodeParams) => {
    const res = await resendVerificationCode(payload);
    return res;
  }
);
