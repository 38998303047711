import {
  IPaymentPlan,
  IPlanTypes,
} from "src/services/Billing/getPaymentPlans.service";
import styles from "./PaymentPlan.module.scss";
import FeatureItem from "../FeatureItem/FeatureItem";

const PaymentPlan = ({
  plan,
  selectedPlan,
}: {
  plan: IPaymentPlan;
  selectedPlan: IPlanTypes;
}) => {
  // Split the features array into two parts
  const leftSideFeatures = plan.features.slice(0, 5);
  const rightSideFeatures = plan.features.slice(5);
  return (
    <div className={`mb-2 mb-lg-0 ${styles.memberPlan}`}>
      <div
        className={`d-flex align-items-center mb-4 ${
          !plan.extras?.length && styles.setpadding
        }`}
      >
        <h1 className={`${styles.planValue}`}>{plan.price}</h1>
        <span className={`${styles.perMonth} ps-1`}>
          {" "}
          {selectedPlan.type === "yearly" ? "/year" : "/month"}{" "}
        </span>
        <span className={`${styles.planText}`}>{plan.shortName}</span>
      </div>

      {/* Extras */}
      {plan.extras?.map((extra, index) => {
        return (
          <div
            key={index}
            className="mb-2"
            style={{ color: extra.color ?? undefined }}
          >
            {extra.icon.includes("fa-gift") ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21.002"
                height="18.377"
                viewBox="0 0 21.002 18.377"
              >
                <path
                  id="Icon_awesome-gift"
                  data-name="Icon awesome-gift"
                  d="M1.313,19.314a1.311,1.311,0,0,0,1.313,1.313H9.189V14.064H1.313Zm10.5,1.313h6.563a1.311,1.311,0,0,0,1.313-1.313V14.064H11.814ZM19.69,7.5H17.963a3.577,3.577,0,0,0,.414-1.641,3.615,3.615,0,0,0-3.61-3.61c-1.706,0-2.81.874-4.225,2.8-1.415-1.928-2.519-2.8-4.225-2.8a3.615,3.615,0,0,0-3.61,3.61A3.526,3.526,0,0,0,3.122,7.5H1.313A1.311,1.311,0,0,0,0,8.813v3.282a.658.658,0,0,0,.656.656h19.69A.658.658,0,0,0,21,12.095V8.813A1.311,1.311,0,0,0,19.69,7.5ZM6.313,7.5a1.641,1.641,0,0,1,0-3.282c.816,0,1.419.135,3.532,3.282Zm8.454,0H11.235c2.108-3.138,2.7-3.282,3.532-3.282a1.641,1.641,0,1,1,0,3.282Z"
                  transform="translate(0 -2.25)"
                  fill="#cdbfcb"
                />
              </svg>
            ) : (
              <i
                style={{ fontSize: 18 }}
                className={`${extra.icon}`}
              ></i>
            )}

            <span className={`${styles.planExtra} ps-2 me-1`}>
              {extra.text}
            </span>
          </div>
        );
      })}
      <div>
        <h3 className={`${styles.includeText} mb-4`}>What's included</h3>
        <div className={styles.featuresWrapper}>
          {/* Left side with 5 items */}
          <div style={{ flex: 1 }}>
            {leftSideFeatures.map((feature, index) => (
              <div key={index}>
                <FeatureItem feature={feature} />
              </div>
            ))}
          </div>

          {/* Right side with the remaining items */}
          <div style={{ flex: 1 }}>
            {rightSideFeatures.map((feature, index) => (
              <div key={index + 5}>
                <FeatureItem feature={feature} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentPlan;
