import { axiosJSON } from "src/globals/axiosEndPoints";
import { ITicketMeta } from "src/interfaces/ITicket";

export type Tickets = {
  [ticketId: string]: [];
};

export interface ITicketJSON {
  [ticketId: string]: {
    [key: string]: any;
  };
}

export interface TicketsQuery {
  columns: Array<string>;
  filters: {
    searchOptions?: {
      value: string;
      keys: string[];
      isOuterTicket?: boolean; //flag used for outer ticket search optimization
    };
    options?: {
      key: string;
      compare: string;
      value: number | string; // modified for filtering by ticket ids
    }[];
    tableType: string;
    isInnerTicket?: boolean;
    viewId?: number;
    linkedTicketId?: string | number;
  };
  ticketIds?: Array<string | number>;
  start?: number;
  limit?: number;
}

export async function getTicketsV2(
  servicePayload: TicketsQuery,
  getJSON = false,
  currentView = "",
  signal?: any,
) {
  if (!servicePayload.columns.includes("ticket_id")) {
    servicePayload.columns.unshift("ticket_id");
  }
  const { data } = await axiosJSON.post(
    servicePayload.filters.linkedTicketId
      ? `/api/ticket/linkedTickets`
      : `/api/ticket/${currentView}`,
    servicePayload,
    { signal },
  );
  if (data.err === true) {
    throw new Error(data.msg as string);
  }

  const normalizedData = normalizeData(data, servicePayload.columns, getJSON);
  return normalizedData;
}

// convert incoming data to the required format
function normalizeData(
  { data: ticketResponseData, metaData }: any,
  columns: string[],
  getJSON: boolean,
): {
  tickets: Tickets | ITicketJSON;
  ticketMeta: ITicketMeta;
} {
  const tickets: Tickets = {};
  const ticketMeta: ITicketMeta = {
    count: metaData.count,
    totalCount: metaData.totalCount,
    ticketIds: [],
  };

  const ticketIdIndex = columns.findIndex((col) => col === "ticket_id");

  for (let i = 0; i < ticketResponseData.length; i++) {
    const ticketRow = ticketResponseData[i];
    const ticketId = Number(ticketRow[ticketIdIndex]);
    ticketMeta.ticketIds.push(`${ticketId}`);
    tickets[`${ticketId}`] = ticketRow;
    // log.debug({ ticketRow, ticketId, tickets, ticketMeta });
  }
  if (getJSON) {
    const ticketJSON: ITicketJSON = {};
    for (const x in tickets) {
      ticketJSON[x] = {};
      columns.forEach((col, index) => {
        ticketJSON[x][col] = tickets[x][index];
      });
    }
    return { tickets: ticketJSON, ticketMeta };
  }
  return { tickets, ticketMeta };
}
