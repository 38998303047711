import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";
import { AutomationAPIPayload } from "../createAutomation.service";

export interface GetAllDispatchRuleConfigParams {
  /**
   * Integration Id of the automation
   */
  integrationId: string;
  /**
   * Current Start of the pagination
   */
  start: number;
  /**
   * Number of items to fetch
   */
  limit: number;
}

export interface IDispatchRuleConfig {
  /**
   * Array of columns in table, each with a key and heading.
   */
  columns: {
    key: string;
    heading: string;
  }[];
  /**
   * Array of rows in the table, each with a configuration ID and other dynamic properties.
   * The properties in each row correspond to the keys defined in the columns array.
   */
  rows: {
    /**
     * Configuration Id of each dispatch rule
     */
    configurationId: string;
    /**
     * Configuration current state
     */
    configurationState: "pending" | "completed";
    /**
     * Dynamic properties based on column keys.
     */
    [x: string]: string | string[];
  }[];
  /**
   * Array of actions that can be performed on the dispatch rules.
   */
  actions: Array<"edit" | "delete">;
}

export interface GetAllDispatchRuleConfigRes {
  /**
   * The dispatch rule configuration data.
   */
  dispatchConfig: IDispatchRuleConfig;
  /**
   * Metadata about the dispatch rule configurations.
   */
  metaData: {
    /**
     * Total number of dispatch rules available.
     */
    totalCount: number;
    /**
     * Number of dispatch rules returned in the current response.
     */
    count: number;
    /**
     * Maximum number of dispatch rules that can be created. If the total count equals this limit, the creation of new dispatch rules is disabled.
     */
    maximumLimit: number;
    /**
     * "issueDispatch::categories", "issueDispatch::cernResponse"
     *
     * Key - categories, cernResponse
     * Format - "EAutomationType::Key"
     */
    disabledConfigs: string[];
  };
}

/**
 * Service to get all dispatch rule configurations.
 *
 * @param params - Parameters for the service.
 * @returns - A promise that resolves to the dispatch rule configuration response.
 * @throws Will throw an error if the API call fails.
 */
export const getAllDispatchRuleConfigService = async (
  params: AutomationAPIPayload<GetAllDispatchRuleConfigParams>,
): Promise<GetAllDispatchRuleConfigRes> => {
  const apiEndPoint = IS_TEST_ACTIVE
    ? `${process.env.REACT_APP_TEST_SITE_URL}/bugManagement/getAllDispatchRuleConfig`
    : `/api/automation/${params.type}/getAllDispatchRuleConfig`;

  const { data: res } = await axiosJSON.post(apiEndPoint, params.payload);

  if (res.err || res.error) {
    throw res.msg || res.message || "Cannot get the dispatch rule config data";
  }

  const ret: GetAllDispatchRuleConfigRes = {
    dispatchConfig: {
      ...res.data,
    },
    metaData: res.metaData,
  };

  return ret;
};
