import styles from "./ShopifyStore.module.scss";
import shopify from "src/assets/images/shopLogo.png";
import proceed from "src/assets/images/proceed.png";
import { useCallback, useState } from "react";
import DisconnectIntegration from "./Children/DisconnectIntegration/DisconnectIntegration";
import { IShopifyIntegrationItem } from "src/services/Integrations/getAllShopifyIntegrations";
import { deleteShopifyIntegration } from "src/services/Integrations/deleteShopifyIntegration";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { Spinner } from "react-bootstrap";
import { store } from "src/store/store";
import SettingToast from "src/routes/Setting/children/SettingToast/SettingToast";

interface Props {
  storeData: IShopifyIntegrationItem;
  setShowWhat: Function;
  setCurrentStoreData: Function;
}

const ShopifyStore = ({
  storeData,
  setShowWhat,
  setCurrentStoreData,
}: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [isConnected, setIsConnected] = useState(storeData.connected);
  const [showConnecting, setShowConnecting] = useState(false);
  const hanldeConnectAndDisconnect = () => {
    if (isConnected) {
      setShowModal(true);
      return;
    } else {
      setShowConnecting(true);
      deleteShopifyIntegration({
        integrationId: storeData.integrationId,
        is_disconnect: true,
      })
        .then((res) => {
          setIsConnected(true);
          setShowConnecting(false);

          pushTheToast({
            type: "success",
            text: SettingToast,
            customCompProps: {
              text: (//passing custom component instead of text to show the info with icon
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ margin: "-7px" }}
                >
                  <span>
                    <i className={`fa-solid fa-circle-check me-1`}></i>
                  </span>
                  <span>Store connected</span>
                </div>
              ),
            },
            isTextComponent: true,
            position: "top-center",
          });
        })
        .catch((err) => {
          setShowConnecting(false);
          pushTheToast({
            type: "danger",
            text: err.message,
            position: "top-right",
          });
        });
    }
  };

  const onShow = useCallback(() => {
    setShowModal(true);
  }, []);

  const onHide = useCallback(() => {
    setShowModal(false);
  }, []);
  console.log("showConnecting", showConnecting);
  return (
    <>
      <div className={`d-flex justify-content-between ${styles.box}`}>
        <div className={`d-flex align-items-center`}>
          <img className={`me-2 ${styles.fbLogo}`} src={shopify} />
          <span className={`mb-0 ${styles.fbName}`}>{storeData.storeName}</span>
        </div>
        <div className={`d-flex align-items-center`}>
          <div>
            <span className={`${styles.connection}`}>
              {" "}
              {isConnected ? "Disconnect Page" : "Connect Page"}{" "}
            </span>
          </div>

          <div className="form-check form-switch me-2">
            {showConnecting && (
              <Spinner className="mx-1 my-auto" animation="border" size="sm" />
            )}
            <input
              className={`form-check-input cursor-pointer ${styles.formCheck}`}
              type="checkbox"
              checked={isConnected}
              disabled={showConnecting === true}
              onClick={hanldeConnectAndDisconnect}
            />
          </div>
          <img
            className={`${styles.proceedImg}`}
            src={proceed}
            onClick={() => {
              setCurrentStoreData({ ...storeData, connected: isConnected });
              setShowWhat("aboutPage");
            }}
          />
        </div>
      </div>

      {/* ---Show this modal when we have to disconnect integration--- */}

      {showModal === true && (
        <DisconnectIntegration
          showModal={showModal}
          storeData={storeData}
          setShowModal={setShowModal}
          isConnected={isConnected}
          showConnecting={showConnecting}
          setIsConnected={setIsConnected}
          setShowConnecting={setShowConnecting}
        />
      )}
    </>
  );
};
export default ShopifyStore;
