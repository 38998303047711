import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import getBugByCategory, {
  resBugMeta,
} from "src/services/BugReport/getBugByCategory.service";
import { useCallback, useState } from "react";
import getBugsTicketCount from "src/services/BugReport/getBugsTicketCount.service";
import { useAppDispatch } from "src/store/store";
import { setbugManagementTotalTicket } from "src/store/slices/globals/globals.slice";

export interface PaginationParam {
  start?: number;
  limit?: number;
  filter?: {
    categoryIds?: number[];
    bugStatusKeys?: Array<"submitted" | "investigating" | "resolved">;
    priorityKeys?: Array<"normal" | "high" | "needThisFixedRightAway">;
    pendingForAction?: boolean;
  };
}

const initialPayload = {
  start: 0,
  limit: 10,
};

function useBugFilterAndPagination() {
  // const [currentPage, setCurrentPage] = useState(1);
  const [payload, setPayload] = useState<PaginationParam>({
    ...initialPayload,
    filter: {
      pendingForAction: true,
    },
  });
  const [pendingForAction, setPendingForAction] = useState(true);
  const dispatch = useAppDispatch();

  // function to get selecte filter in param and set it into the api payload
  const addFilters = (
    selectedStatus: Array<string | undefined>,
    selectedPriority: Array<string | undefined>,
    selectedCategory: Array<string>,
  ) => {
    setPayload((oldState) => {
      let filterParam = {};
      if (selectedStatus.length)
        filterParam = {
          ...filterParam,
          bugStatusKeys: [...selectedStatus],
        };
      if (selectedPriority.length)
        filterParam = {
          ...filterParam,
          priorityKeys: [...selectedPriority],
        };
      if (selectedCategory.length) {
        filterParam = {
          ...filterParam,
          categoryIds: [...selectedCategory],
        };
      }
      if (pendingForAction) {
        filterParam = {
          ...filterParam,
          pendingForAction: true,
        };
      }
      if (Object.keys(filterParam).length) {
        return {
          ...initialPayload,
          filter: {
            ...filterParam,
          },
        };
      }
      return initialPayload;
    });
  };

  const addPending = () => {
    setPayload((oldState) => {
      if (!pendingForAction) {
        return {
          ...oldState,
          filter: {
            ...oldState.filter,
            pendingForAction: true,
          },
        };
      } else {
        if (oldState.filter?.pendingForAction) {
          delete oldState.filter.pendingForAction;
          if (!Object.keys(oldState.filter).length) {
            delete oldState.filter;
          }
          return oldState;
        }
        return oldState;
      }
    });
  };

  const { data, isError, isLoading } = useInfiniteQuery({
    queryKey: ["getAllBug", payload],
    queryFn: ({ pageParam = payload }) => getBugByCategory(pageParam),
    getNextPageParam: (lastPage: resBugMeta, allPages) => {
      const data = allPages.flatMap((data: any) => data.data);
      if (data.length < lastPage.metaData?.totalCount) {
        const nextPageParam = {
          ...payload,
          start: data.length,
        };
        return nextPageParam;
      }
      return null;
    },
  });

  
    useQuery(["bugStatusList/getTotalBugTicketCount"], {
      queryFn: () => getBugsTicketCount(),
      onSuccess: (data) =>
        dispatch(setbugManagementTotalTicket(data.totalBugsTicketCount)),
      staleTime: 60000,
      cacheTime: 60000,
    });

  // get total ticket count
  const { data: totalTicketCount } = useQuery(
    ["getAllBug/getBugsTicketCount", payload],
    {
      queryFn: () => {
        let param: PaginationParam = {
          filter: {},
        };
        if (payload?.filter?.categoryIds && param.filter) {
          param.filter.categoryIds = payload?.filter.categoryIds;
        }
        if (payload?.filter?.bugStatusKeys && param.filter) {
          param.filter.bugStatusKeys = payload?.filter.bugStatusKeys;
        }
        if (payload?.filter?.priorityKeys && param.filter) {
          param.filter.priorityKeys = payload?.filter.priorityKeys;
        }
        if (pendingForAction && param.filter) {
          param.filter.pendingForAction = true;
        }
        const ticketCountPayload = Object.keys(param.filter ?? {}).length
          ? param
          : {};
        return getBugsTicketCount(ticketCountPayload);
      },
      staleTime: 60000,
      cacheTime: 60000,
    },
  );

  //Calculate the currentPage and totalPages for pagination
  const currentPage = Math.floor(payload.start! / payload.limit!) + 1;
  const totalPages = Math.ceil(
    (data?.pages[0]?.metaData?.totalCount ?? 0) / payload.limit!,
  );

  // Change the current page and trigger a refetch
  const changePage = async (page: number) => {
    setPayload({
      ...payload,
      start: (page - 1) * payload.limit!,
    });
  };

  return {
    currentPage,
    changePage,
    totalPages,
    addFilters,
    bugList: data?.pages[0],
    // hasNextPage: !data?.pages[0].metaData.limitReached,
    totalTicketCount,
    pendingForAction,
    setPendingForAction,
    addPending,
    isLoading,
    isError,
  };
}

export default useBugFilterAndPagination;
