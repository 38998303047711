import React from "react";
import styles from "./TemplateTab.module.scss";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import clock from "src/assets/images/temClock.png";
import note from "src/assets/images/tempNote.png";

const TemplateTab = ({
  whichTab,
  setWhichTab,
}: {
  whichTab: string;
  setWhichTab: Function;
}) => {
  // const { template } = useParams();

  return (
    <div className="d-flex flex-wrap">
      <NavLink
        to={` `}
        className={`${styles.tab} ${
          whichTab === "recent" ? styles.active : ""
        }`}
        onClick={() => {
          setWhichTab("recent");
        }}
        id="recentTab"
      >
        <span className="pe-1">
          <img src={clock} alt="" className={`${styles.clockImg}`} />
        </span>
        <span> Recently Used</span>
      </NavLink>
      <NavLink
        to={``}
        className={`${styles.tab}   ${
          whichTab === "all" ? styles.active : ""
        } `}
        onClick={() => {
          setWhichTab("all");
        }}
        id="allTemplateTab"
      >
        <span className="pe-1">
          <img src={note} alt="" className={`${styles.noteImg}`} />
        </span>
        <span>All Templates</span>
      </NavLink>
    </div>
  );
};

export default TemplateTab;
