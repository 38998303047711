import { useEffect, useMemo, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import Select from "react-select";
import { useFetchReturnDiscountCodes } from "src/features/ReturnAutoWorkFlow/hooks/useFetchReturnConditions";
import styles from "./ComponentStyles.module.scss";

interface Option {
  label: string;
  value: string;
}
const LIMIT = 15;

function DiscountCodesNew(props: any) {
  const {
    discountCodes: allCodes,
    fetchDiscountCodes,
    isLoading,
  } = useFetchReturnDiscountCodes();

  const discountCodes = useMemo(() => {
    return allCodes.map(({ code, title }) => {
      return {
        label: title,
        value: code,
      } as Option;
    });
  }, [allCodes]);

  const selectedValue = useMemo(() => {
    const arr: Option[] = [];

    props.codes.forEach((val: string) => {
      arr.push({
        value: val,
        label: val,
      });
    });

    return arr;
  }, [props.codes]);

  const [menuOpen, setMenuOpen] = useState(false);
  const [showPopover, setShowPopover] = useState(true);

  useEffect(() => {
    if (props.error) {
      setShowPopover(true);
    } else {
      setShowPopover(false);
    }
  }, [props.error]);

  const popover =
    props.error && props.error.length > 0 ? (
      <Popover
        id="popover-basic"
        className="rounded-3"
      >
        <Popover.Body className="d-flex flex-row">
          <div className="border px-2 bg-warning text-white me-1 rounded-3 ">
            !
          </div>
          <div className="mt-1">{props.error}</div>
        </Popover.Body>
      </Popover>
    ) : (
      <></>
    );

  return (
    <OverlayTrigger
      show={showPopover}
      placement="bottom"
      overlay={popover}
      rootClose={true}
      onToggle={(show) => {
        if (!menuOpen) {
          setShowPopover(show);
        }
      }}
      rootCloseEvent="mousedown"
    >
      <div
        className={`ms-lg-3 ${styles.thirdSelect}`}
        style={{
          maxWidth: "20rem",
          minWidth: "16rem",
        }}
        onClick={() => {
          setShowPopover(false);
        }}
      >
        <Select
          defaultValue={selectedValue.map((tag) => tag)}
          value={selectedValue.map((tag) => tag)}
          isMulti
          styles={customStyles}
          name="codes"
          options={discountCodes}
          className={`basic-multi-select ${
            props.error ? styles.borderDanger : ""
          }`}
          classNamePrefix="select"
          isClearable={false}
          placeholder="search codes"
          onChange={(e) => props.onChange(e.map((s) => s.value))}
          isLoading={isLoading === "pending"}
          onMenuScrollToBottom={() => fetchDiscountCodes(LIMIT)}
          onMenuClose={() => {
            setMenuOpen(false);
          }}
          components={{ IndicatorSeparator: null }}
          onMenuOpen={() => {
            setMenuOpen(true);
            if (isLoading !== "pending" && discountCodes.length === 0) {
              fetchDiscountCodes(LIMIT);
            }
          }}
        />
        <span className={styles.errorText}>
          {props.error && props.error.length > 0 && props.error}
        </span>
      </div>
    </OverlayTrigger>
  );
}
const customStyles = {
  control: (provided: any, _: any) => ({
    ...provided,
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: "#707070",
    fontSize: "14px !important", // Placeholder font size
  }),
  dropdownIndicator: () => ({
    display: "none", // Hide the dropdown arrow
  }),
  indicatorSeparator: () => ({
    display: "none", // Hide the separator line
  }),
};
export default DiscountCodesNew;
