import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";

export interface FormElementOptionParams {
  integrationId: number | string;
}

export interface FormElementOption {
  elementId: number;
  elementLabel: string;
  elementType: string;
}

export interface Step12GetFormElementOptions {
  data: FormElementOption[];
}

const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/getFormElementOptions`
  : "/api/setting/automation/refund/step/12/getFormElementOptions";

export async function fetchGetStep12FormElementOptions(
  params: FormElementOptionParams
) {
  const { data } = await axiosJSON.get(apiEndPoint, {
    params,
  });

  if (data.err === true) {
    throw new Error("Some Error Occurred while fetching data");
  }

  return data.data;
}
