import { useRef, useState } from "react";
import styles from "./LiveChatSendingDetails.module.scss";
import SendingInfo from "src/components/SendingInfo";
import OpenClose from "src/routes/Ticket/children/MainChat/children/SendMessage/children/OpenClose";
import { BsExclamationDiamondFill } from "react-icons/bs";

const LiveChatSendingDetails = ({
  selectedCustomerEmail,
  selectedfromEmail,
  cc,
  setCc,
  bcc,
  setBcc,
  isIntegrationFailure = false,
}: {
  selectedCustomerEmail: string;
  selectedfromEmail: any;
  cc: any;
  setCc: any;
  bcc: any;
  setBcc: any;
  isIntegrationFailure: boolean;
}) => {
  const [showSending, setShowSending] = useState(false);
  const sendingDetailsRef = useRef(null as any);
  return (
    <div
      className={`d-flex ${styles.sendingDetails} ${
        showSending ? styles.active : ""
      } justify-content-between`}
    >
      <div
        className={`${styles.sendingBlock}`}
        ref={sendingDetailsRef}
      >
        <div className="d-flex mb-1">
          <input
            data-lpignore="true"
            type="email"
            style={{
              width: 0,
              height: 0,
              position: "absolute",
              opacity: 0,
              border: "none",
            }}
          />
          <label>To:</label>
          <SendingInfo
            name="to"
            isDisabled={true}
            currentValue={selectedCustomerEmail}
            getValue={(value) => {}}
            addUser={true}
          />
        </div>
        <div className="d-flex mb-1 position-relative">
          <label>From:</label>
          <SendingInfo
            name="from"
            isDisabled={true}
            currentValue={selectedfromEmail?.email}
            getValue={(value) => {}}
            isError={isIntegrationFailure}
          />
          {isIntegrationFailure && (
            <p className={`${styles.integrateText}`}>
              <span className={`me-1 ${styles.exclamation}`}>
                <BsExclamationDiamondFill />
              </span>
              <span>Integration not available</span>
            </p>
          )}
        </div>
        <div className="d-flex mb-1">
          <label>Bcc:</label>
          <SendingInfo
            name="bcc"
            currentValue={bcc}
            getValue={(value) => {
              //   console.log("bcc::", value);
              setBcc(value);
            }}
            addUser={true}
            isMulti={true}
          />
        </div>
        <div className="d-flex mb-1">
          <label>Cc:</label>
          <SendingInfo
            name="cc"
            currentValue={cc}
            getValue={(value) => {
              setCc(value);
            }}
            addUser={true}
            isMulti={true}
          />
        </div>
      </div>
      <OpenClose
        onClick={(e: any) => {
          setShowSending(!showSending);
        }}
        show={showSending}
      />
    </div>
  );
};

export default LiveChatSendingDetails;
