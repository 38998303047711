import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import SearchBar from "src/components/SearchBar/SearchBar";
import { ETableTypeNames } from "src/services/TicketService/linkGenerator";
import {
  fetchAllTicketsIdList,
  fetchInnerTickets,
  isCurrentTicketMergedWithDifferentSelector,
  resetAllTicketsNav,
  resetInnerTicketData,
  setHasNextTicket,
  setHasPrevTicket,
  setInnerTicketActive,
  setInnerTicketFilters,
  setLastSearchURL,
} from "src/store/slices/innerTicket/innerTicket.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { getJSONSearchParam, upperCaseEachFirst } from "src/utils/utils";
import styles from "./PathHeader.module.scss";
import { checkUncheckColumn } from "src/store/slices/tickets/tickets.slice";
import { showGlobalLoader } from "src/components/GlobalLoader/GlobalLoader";

function PathHeader() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { tableType, ticketId, viewType, searchText } = useParams();
  const tableTypeName = useRef<string>("");
  const activeTicketInfo: any = useAppSelector(
    (state) => state.innerTicket.activeTicketInfo,
  );
  const {
    ticketDataAjaxStatus,
    messageDataAjaxStatus,
    lastGlobalSearchUrl,
    lastActiveTableType,
  } = useAppSelector((state) => state.innerTicket);
  const currentUser = useAppSelector((state) => state.globals.currentUserData);
  const prevNextBtnDisabled = useMemo(
    () =>
      ticketDataAjaxStatus === "pending" || messageDataAjaxStatus === "pending",
    [ticketDataAjaxStatus, messageDataAjaxStatus],
  );
  const {
    allTicketsIdList,
    allTicketsIdAjaxStatus,
    hasNext,
    hasPrev,
    prevTicketId,
    nextTicketId,
  } = useAppSelector((state) => state.innerTicket.allTicketsNav);
  const { ticketId: initialTicketId } = useAppSelector(
    (state) => state.innerTicket.filters,
  );
  const isCurrentTicketMergedWithDifferent = useAppSelector(
    isCurrentTicketMergedWithDifferentSelector,
  );
  const moreColForMerge = () => {
    dispatch(checkUncheckColumn({ colId: 9, colInd: 1, viewType } as any));
  };
  const routerSearchText = searchText ? decodeURIComponent(searchText) : "";
  const [sValue, setSValue] = useState(routerSearchText);
  // const [readOnly, setReadOnly] = useState(false);
  useEffect(() => {
    setSValue(routerSearchText);
  }, [routerSearchText]);
  // useEffect(() => {
  //   if (ticketDataAjaxStatus === "pending" && routerSearchText.trim()) {
  //     setReadOnly(true);
  //   } else {
  //     setReadOnly(false);
  //   }
  // }, [ticketDataAjaxStatus]);
  const usersViewData = useAppSelector(
    (state) => state.ticketSideBar.usersViewData,
  );

  useEffect(() => {
    let viewName = "Fetching...";

    if (tableType) {
      viewName = (ETableTypeNames as any)[tableType];

      if (viewName === undefined && tableType.includes("viewId")) {
        try {
          viewName = usersViewData[tableType.split("::")[1]].viewName ?? "";
        } catch {
          viewName = "Fetching...";
        }
      } else if (tableType === "mentions") {
        viewName = "@" + (currentUser?.firstName ?? "");
      } else if (tableType === "ai_handled") {
        viewName = "Cern Tickets";
      }
    }
    tableTypeName.current = viewName;
  }, [tableType, currentUser?.firstName]);

  useEffect(() => {
    let id = initialTicketId || ticketId;
    dispatch(setHasNextTicket({ ticketId: parseInt(id + "") }));
    dispatch(setHasPrevTicket({ ticketId: parseInt(id + "") }));
  }, [allTicketsIdAjaxStatus, allTicketsIdList, ticketId, initialTicketId]);

  return (
    <div
      className={`${styles.pathHeader} mb-3 mt-1 d-flex justify-content-between`}
    >
      <div className={`d-block d-md-flex`}>
        <div
          className={`${styles.backButton}`}
          onClick={(e) => {
            if (searchText) {
              if (
                lastGlobalSearchUrl.searchUrlIsApplied &&
                lastGlobalSearchUrl.ticketRoute
              ) {
                dispatch(
                  setLastSearchURL({
                    ticketRoute: "",
                    searchUrl: "",
                    searchUrlIsApplied: false,
                  }),
                );
                navigate(lastGlobalSearchUrl.ticketRoute);
              } else {
                navigate(-1);
              }
            } else if (isCurrentTicketMergedWithDifferent) {
              moreColForMerge();
              if (lastGlobalSearchUrl.searchRoute.trim()) {
                navigate(lastGlobalSearchUrl.searchRoute.trim());
                dispatch(
                  setLastSearchURL({ searchUrl: "", searchUrlIsApplied: true }),
                );
              } else {
                navigate(
                  `/tickets/${
                    lastActiveTableType ? lastActiveTableType : tableType
                  }/${viewType}/${window.location.search}`,
                );
              }
            } else {
              if (lastGlobalSearchUrl.searchRoute.trim()) {
                navigate(lastGlobalSearchUrl.searchRoute.trim());
                dispatch(
                  setLastSearchURL({ searchUrl: "", searchUrlIsApplied: true }),
                );
              } else {
                navigate(
                  `/tickets/${
                    lastActiveTableType ? lastActiveTableType : tableType
                  }/${viewType}/${window.location.search}`,
                );
              }
            }
          }}
        >
          <i className={`fa-solid fa-arrow-left`}></i>
        </div>
        <div className={`${styles.path} ms-2 me-1`}>
          <span
            className={`${styles.tableTypeName} me-1`}
          >{`${tableTypeName.current}`}</span>
          {` > ${upperCaseEachFirst(viewType?.replace("-", " ") + "")} ${
            searchText === undefined
              ? `> Ticket ID : ${ticketId} > Customer : ${
                  activeTicketInfo.ticket_customer_name
                    ? activeTicketInfo.ticket_customer_name
                    : ""
                }${
                  activeTicketInfo.ticket_brand?.name?.trim()
                    ? ` > Brand : ${activeTicketInfo.ticket_brand.name.trim()}`
                    : ""
                }`
              : ""
          }`}
        </div>
      </div>
      <div className={`d-flex justify-content-end`}>
        <SearchBar
          className={`${styles.search}`}
          inputClassName={`text-truncate ${styles.input}`}
          placeholder={`Search by ticket id,customer name or e-mail`}
          // readOnly={readOnly}
          onSearch={(value: any, callback: any) => {
            // callback();
            if (value.trim()) {
              navigate(
                `/tickets/${tableType}/${viewType}/ticket/${ticketId}/search/${encodeURIComponent(
                  value.trim(),
                )}/${window.location.search}`,
              );
            } else {
              navigate(
                `/tickets/${tableType}/${viewType}/ticket/${ticketId}/${window.location.search}`,
              );
            }
          }}
          value={sValue}
          onChange={(e: any) => {
            setSValue(e.target.value);
          }}
        />
        {/* All tickets navigation */}
        <div
          className={`${styles.arrow} ${styles.left} ${
            hasPrev === true && !prevNextBtnDisabled ? "" : styles.disabled
          } mx-1`}
          onClick={
            hasPrev === true && !prevNextBtnDisabled
              ? (e) => {
                  e.preventDefault();
                  let currentTicketIndex = allTicketsIdList.indexOf(
                    parseInt(ticketId + ""),
                  );
                  if (
                    currentTicketIndex === -1 ||
                    (allTicketsIdList.length - 1 !== currentTicketIndex &&
                      currentTicketIndex !== 0 &&
                      currentTicketIndex <= 2)
                  ) {
                    dispatch(fetchAllTicketsIdList());
                  }

                  navigate(
                    `/tickets/${tableType}/${viewType}/ticket/${prevTicketId}/${window.location.search}`,
                  );
                  setTimeout(() => {
                    showGlobalLoader("TicketDetails");
                  }, 0);
                  dispatch(resetInnerTicketData());
                  dispatch(
                    setInnerTicketActive({
                      ticketId: prevTicketId,
                    }),
                  );
                  dispatch(
                    setInnerTicketFilters({
                      tableType,
                      currentView: viewType,
                      customerId: 0,
                      ticketId: prevTicketId,
                    }),
                  );
                  dispatch(fetchInnerTickets());
                }
              : (e) => {
                  e.preventDefault();
                }
          }
        >
          <span>
            <i className="fa-solid fa-chevron-left"></i>
          </span>
        </div>
        <div
          className={`${styles.arrow} ${styles.right}  ${
            hasNext === true && !prevNextBtnDisabled ? "" : styles.disabled
          }`}
          onClick={
            hasNext === true && !prevNextBtnDisabled
              ? (e) => {
                  e.preventDefault();
                  let currentTicketIndex = allTicketsIdList.indexOf(
                    parseInt(ticketId + ""),
                  );
                  if (
                    currentTicketIndex === -1 ||
                    (allTicketsIdList.length - 1 !== currentTicketIndex &&
                      currentTicketIndex !== 0 &&
                      currentTicketIndex >= allTicketsIdList.length - 2)
                  ) {
                    dispatch(fetchAllTicketsIdList());
                  }

                  navigate(
                    `/tickets/${tableType}/${viewType}/ticket/${nextTicketId}/${window.location.search}`,
                  );
                  setTimeout(() => {
                    showGlobalLoader("TicketDetails");
                  }, 0);
                  dispatch(resetInnerTicketData());
                  dispatch(
                    setInnerTicketActive({
                      ticketId: nextTicketId,
                    }),
                  );
                  dispatch(
                    setInnerTicketFilters({
                      tableType,
                      currentView: viewType,
                      customerId: 0,
                      ticketId: nextTicketId,
                    }),
                  );
                  dispatch(fetchInnerTickets());
                }
              : (e) => {
                  e.preventDefault();
                }
          }
        >
          <span>
            <i className="fa-solid fa-chevron-right"></i>
          </span>
        </div>
      </div>
    </div>
  );
}

export default PathHeader;
