import { axiosJSON } from "src/globals/axiosEndPoints";

export interface IUpdateFollowUpReason {
  integrationId: string;
  reasons: {
    id: string | null;
    value: string;
    moreQuestions: {
      elementTypeId: string;
      elementId: string | null;
      additionalOptions: {
        id: string | null;
        value: string;
      }[];
      elementValue: string;
    }[];
  }[];
}

const updateFollowUpReasonsService = async (params: IUpdateFollowUpReason) => {
  const { data: res } = await axiosJSON.post(
    `/api/setting/automation/refund/returnReasons/update`,
    params,
  );

  if (res.err || res.error) {
    throw new Error(res.msg ?? res.message ?? "Some Error Occurred.");
  }
};

export default updateFollowUpReasonsService;
