import { axiosJSON } from "src/globals/axiosEndPoints";

interface Attribute {
  attributeName: string;
  attributeValue: string;
}

export interface MentionData {
  id: string;
  dropdownName: string;
  mentionValue: string;
  attributes: Attribute[];
  icon?: string;
  textColor?: string;
  bgColor?: string;
  imageUrl?: string;
  status?: string;
  popoverKey?: string;
  popoverData?: PopoverData[];
}

interface PopoverData {
  menuName: string;
  menuItems: MentionData[];
}

interface MentionCategoryDetails {
  name: string;
  key: string;
  mentionData?: MentionData[];
}

export interface UserMentionData {
  mentionCategoryDetails: MentionCategoryDetails;
  mentionData?: MentionData[];
}

const getAllMentionsData = async (payload: {
  start: number;
  limit: number;
  brandId: string | null;
}) => {
  const { data: res } = await axiosJSON.post(
    `/api/mention/getAllMentionsData`,
    payload,
  );

  if (res.err || res.error) {
    throw res.msg ?? res.message ?? "Something went wrong!";
  }

  return res.data as UserMentionData[];
};

export default getAllMentionsData;
