/**
 * This is the component file.
 * It contains the SentToAgents component function.
 *
 * @author Yash Aditya
 */

import { Modal } from "react-bootstrap";
import NotificationSend from "../NotificationSend/NotificationSend";
import styles from "../../EditNotification.module.scss";
import { useState } from "react";
import { ShowAgents } from "../ShowAgents/ShowAgents";

// React functional component for displaying the "Sent To Agents" UI
function SentToAgents() {
  // State to manage the visibility of the notification modal
  const [notification, setNotification] = useState(false);

  // Function to show the notification modal
  const showNotificationModal = () => {
    setNotification(true);
  };

  // Function to hide the notification modal
  const onNotificationHide = () => {
    setNotification(false);
  };

  return (
    <>
      {/* Clickable area to trigger the notification modal */}
      <div
        className={`me-2 mb-2 mb-md-0 cursor-pointer ${styles.sentBox}`}
        onClick={showNotificationModal}
      >
        <ShowAgents />

        {/* Dropdown icon */}
        <span className={`${styles.downArrow}`}>
          <i className="fa-solid fa-caret-down"></i>
        </span>
      </div>
      {/* Notification modal */}
      <Modal
        backdropClassName={`${styles.modalBack}`}
        show={notification}
        onHide={onNotificationHide}
        dialogClassName={`${styles.modalDialogAgent}`}
        contentClassName={`${styles.modalContentAgent}`}
        // centered={true}
        enforceFocus={false}
      >
        {/* Nested component for handling notification sending */}
        <NotificationSend onHide={onNotificationHide} />
      </Modal>
    </>
  );
}

export default SentToAgents;
