import styles from "./LocationFilter.module.scss";
import { Dropdown } from "react-bootstrap";
const LocationFilter = () => {
  return (
    <div className={`d-flex justify-content-between ${styles.wrappeRDiv}`}>
      <div>
        <span className={`${styles.allSelect}`}>All</span>
        {/* <span className={`${styles.selectCategory}`}>Unfullfilled</span>
        <span className={`${styles.selectCategory}`}>Unpaid</span>
        <span className={`${styles.selectCategory}`}>Open</span>
        <span className={`${styles.selectCategory}`}>Closed</span>
        <span className={`${styles.selectCategory}`}>Product Giveaways</span> */}
      </div>
      {/* <div>
        <div className={`d-flex align-items-center`}>
          <span className={`px-2 ${styles.location}`}>
            <i className="fa-solid fa-location-dot"></i>
          </span>
          <div>
            <Dropdown>
              <Dropdown.Toggle
                id="dropdown-basic"
                className={`${styles.allOrderDay}`}
              >
                All Locations
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  href="#/action-1"
                  className={`${styles.openDropDown}`}
                >
                  Action
                </Dropdown.Item>
                <Dropdown.Item
                  href="#/action-2"
                  className={`${styles.openDropDown}`}
                >
                  Another action
                </Dropdown.Item>
                <Dropdown.Item
                  href="#/action-3"
                  className={`${styles.openDropDown}`}
                >
                  Something else
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div> */}
    </div>
  );
};
export default LocationFilter;
