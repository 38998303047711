import { Modal } from "react-bootstrap";
import styles from "../../EditNotification.module.scss";
import AddNotification from "../AddNotification/AddNotification";
import { useState } from "react";
import { useNotifications } from "../../../../hooks/notifications/useNotifications";
import editNotifyImg from "src/assets/images/editInfo.png";
function ReportName({ showError }: { showError: boolean }) {
  const [edit, setEdit] = useState(false);
  const { reportNotifications } = useNotifications();

  // Function for show modal of edit
  const showEditModal = () => {
    setEdit(true);
  };
  // Function for hide modal of edit
  const onEditHide = () => {
    setEdit(false);
  };

  return (
    <>
      <div>
        <span className={`${styles.reportName}`}>Report Name :</span>
        <span className={`${styles.reportstatus} ms-1`}>
          {reportNotifications.editingNotificationData?.reportName
            ? reportNotifications.editingNotificationData.reportName
            : "Unnamed"}
        </span>
        {/* <span
          className={`ps-2 cursor-pointer ${styles.edit}`}
          onClick={showEditModal}
        >
          <i className="fa-solid fa-pen-to-square"></i>
        </span> */}
        <img
          src={editNotifyImg}
          alt=""
          className={`cursor-pointer ${styles.editImg}`}
          onClick={showEditModal}
        />
        <Modal
          backdropClassName={`${styles.modalBack}`}
          show={edit}
          onHide={onEditHide}
          dialogClassName={`${styles.modalDialog2}`}
          contentClassName={`${styles.modalContent2}`}
          // centered={true}
          enforceFocus={false}
        >
          <AddNotification hide={onEditHide} />
        </Modal>
      </div>
      {showError &&
      !reportNotifications.editingNotificationData?.reportName?.trim() ? (
        <div
          className="mb-1 text-danger"
          style={{ fontSize: "13px", fontWeight: "500" }}
        >
          * Please enter name
        </div>
      ) : null}
    </>
  );
}

export default ReportName;
