import AliceCategoryDetails from "../AliceCategoryDetails/AliceCategoryDetails";
import useArticleInfo from "../ArticleInfo/useArticleInfo";
import CategoryDetails from "../CategoryDetails/CategoryDetails";
import styles from "./CategoryInfo.module.scss";
import helpLogo from "src/assets/images/helpCenter.png";
import { useEffect } from "react";
import Loader from "src/components/Loader";
import Footer from "../Footer/Footer";
import usePreviewSection from "../../usePreviewSection";
import { useParams } from "react-router-dom";
import InfiniteScroll from "src/components/InfiniteScrollBothSide";
import CustomCode from "../CustomCode/CustomCode";
import { DarkLoader } from "src/components/Loader/Loader";

type Props = {
  integrationIdentifier: string;
};

function CategoryInfo({ integrationIdentifier }: Props) {
  const { categoryId } = useParams();
  const {
    themeData,
    searchTerm,
    helpCenterPageUri,
    setSearchTerm,
    navigate,
    articles,
    articleHasError,
    articleIsLoading,
    fetchNextPage,
    hasNextPage,
    isRefetching,
    onSearch,
    themeDataHasError,
    themeDataIsLoading,
  } = usePreviewSection({
    integrationIdentifier,
    categoryId,
  });

  // FavIcon
  const link = document.querySelector('link[rel="icon"]');

  useEffect(() => {
    if (themeData?.data?.favicon && helpCenterPageUri !== "") {
      link?.setAttribute("href", themeData?.data?.favicon);
    } else {
      link?.setAttribute("href", helpLogo);
    }
  }, [themeData?.data]);

  const newArticles =
    articles && articles[0]?.articles
      ? articles.flatMap((data) => data.articles)
      : [];
  const categoryName = articles && articles[0]?.categoryName;

  if (themeDataIsLoading)
    return (
      <div>
        <Loader />
      </div>
    );

  if (themeDataHasError)
    return (
      <div className={`${styles.errorMsg}`}>
        <p>Something went wrong</p>
      </div>
    );

  return (
    <div>
      <>
        <CustomCode // Custom Code for Head and Body
          customCodeBody={themeData?.data?.customCodeBody ?? ""}
          customCodeHead={themeData?.data?.customCodeHead ?? ""}
        />
        <div className={`${styles.previewMain}`}>
          <div
            className={`position-relative`}
            style={{
              backgroundColor: `${themeData?.data?.backgroundColor ?? "black"}`, // Background Color
              backgroundImage: `url(${themeData?.data?.backgroundImage ?? ""})`, // Background Image
              backgroundSize: `cover`,
              backgroundRepeat: "no-repeat",
              padding: "30px 10px 0px 10px",
              height: "232px",
            }}
          >
            <div>
              <p className="text-center mb-1 d-flex align-items-center justify-content-center">
                <img
                  src={themeData?.data?.logoImage ?? helpLogo} // Logo Image
                  alt="logo"
                  className={`${styles.helpLogo}`}
                />
                {/* Store Name */}
                <span
                  className={`ms-2 ${styles.acme}`}
                  style={{
                    fontFamily: themeData?.data?.font ?? "Roboto", // font family
                  }}
                >
                  {themeData?.data?.storeName}
                </span>
              </p>
              {/* Heading and Subheading  */}
              <h3
                className={`text-center ${styles.helpText}`}
                style={{
                  fontFamily: themeData?.data?.font ?? "Roboto", // font family
                }}
              >
                {themeData?.data?.heading}
              </h3>
              <p
                className={`text-center ${styles.subHeading}`}
                style={{
                  fontFamily: themeData?.data?.font ?? "Roboto", // font family
                }}
              >
                {themeData?.data?.subheading}
              </p>
            </div>
            {/* Search Box */}
            <div className={` w-100 text-center ${styles.searchBox22}`}>
              <input
                type="text"
                placeholder={`${themeData?.data?.placeholder}`}
                className={`${styles.inputBox}`}
                onChange={(e) => setSearchTerm(e.target.value)}
                value={searchTerm}
                style={{
                  fontFamily: themeData?.data?.font ?? "Roboto", // font family
                }}
              />
              <span className={`${styles.search}`}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                >
                  <path
                    id="Icon_material-search"
                    data-name="Icon material-search"
                    d="M25.941,23.368H24.586l-.48-.463a11.166,11.166,0,1,0-1.2,1.2l.463.48v1.355L31.944,34.5,34.5,31.944Zm-10.292,0a7.719,7.719,0,1,1,7.719-7.719A7.708,7.708,0,0,1,15.649,23.368Z"
                    transform="translate(-4.5 -4.5)"
                    fill="#8e8c8c"
                  />
                </svg>
              </span>
            </div>
          </div>

          {isRefetching || articleIsLoading ? (
            <div
              className={`d-flex justify-content-center w-100 px-3 ${styles.loaderWrapper}`}
              style={{
                backgroundColor: `${
                  themeData?.data?.themeType === "night_in_the_oscars" // Theme Type
                    ? "#000000"
                    : "#ffffff"
                }`,
                paddingTop: "99px",
              }}
            >
              {themeData?.data?.themeType === "alice_in_wonderland" ? (
                <Loader /> // Loader
              ) : (
                <DarkLoader /> // Loader
              )}
            </div>
          ) : (
            <div
              style={{
                backgroundColor: `${
                  themeData?.data?.themeType === "night_in_the_oscars"
                    ? "#000000"
                    : "#ffffff"
                }`,
                paddingTop: "99px",
              }}
            >
              {/* Paths  */}
              <div className={`${styles.categoryWrapper}`}>
                <span
                  className={`${styles.featuredArticles} ${
                    themeData?.data?.themeType === "alice_in_wonderland" &&
                    styles.aliceHeader
                  }`}
                  role="button"
                  onClick={() => {
                    navigate(`/helpcenterpage/${helpCenterPageUri}`);
                  }}
                  style={{
                    color: `${themeData?.data?.brandColor}`,
                    fontFamily: themeData?.data?.font ?? "Roboto",
                  }}
                >
                  Categories &gt;
                </span>
                <span
                  className={`${styles.articleNameRoute} ms-2 ${
                    themeData?.data?.themeType === "alice_in_wonderland" &&
                    styles.aliceSubHeader
                  }`}
                  style={{
                    fontFamily: themeData?.data?.font ?? "Roboto", // font family
                  }}
                >
                  {categoryName}
                </span>
                <InfiniteScroll
                  hasMoreBottom={hasNextPage}
                  loadMoreFromBottom={fetchNextPage}
                  className={`overflow-auto p-2 w-100 mt-3`}
                  style={{ borderRadius: "7px", height: "500px" }}
                >
                  {newArticles?.length !== 0 &&
                  themeData?.data?.themeType === "alice_in_wonderland" ? (
                    <AliceCategoryDetails // Alice Category Details
                      newArticles={newArticles}
                      categoryName={categoryName}
                      helpCenterPageUri={helpCenterPageUri}
                      font={themeData?.data?.font ?? "Roboto"}
                    />
                  ) : newArticles?.length !== 0 &&
                    themeData?.data?.themeType === "night_in_the_oscars" ? (
                    <CategoryDetails // Category Details For Night in the Oscars
                      newArticles={newArticles}
                      categoryName={categoryName}
                      helpCenterPageUri={helpCenterPageUri}
                      font={themeData?.data?.font ?? "Roboto"}
                    />
                  ) : (
                    <>
                      <div
                        className={`${styles.nodataFound}`}
                        style={{
                          color: `${
                            themeData?.data?.themeType === "night_in_the_oscars"
                              ? "#ffffff"
                              : "#000000"
                          }`,
                          fontFamily: themeData?.data?.font ?? "Roboto", // font family
                        }}
                      >
                        No article found in {categoryName} category
                      </div>
                    </>
                  )}
                </InfiniteScroll>
              </div>
            </div>
          )}
          <div
            style={{
              backgroundColor: `${
                themeData?.data?.themeType === "night_in_the_oscars"
                  ? "#000000"
                  : "#ffffff"
              }`,
            }}
          >
            <Footer
              show={themeData?.data?.helplamaBranding ? true : false}
              footerLogo={themeData?.data.footerLogo ?? ""}
              font={themeData?.data?.font ?? "Roboto"}
              helplamaBrandingText={themeData?.data?.helplamaBrandingText} //passing "helplamaBrandingText" prop to render powered by text dynamically
              nightTheme={
                themeData?.data?.themeType === "night_in_the_oscars"
                  ? true
                  : false
              }
            />
          </div>
        </div>
      </>
    </div>
  );
}

export default CategoryInfo;
