/**
 * This file defines a custom hook responsible for get, update and delete of customQA
 *
 * @author @Anubhav-busibud
 */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  useInfiniteQuery,
  QueryClient,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { getBotProfile } from "src/services/Bot/BotProfiles/getBotProfile.service";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  calculateTotalPages,
  pageSlice,
  paginationLogic,
} from "src/services/TicketService/pagination";
import {
  QuestionAns,
  getAllQuestionAnswer,
  getAllQuestionAnswerParams,
  getAllQuestionAnswerRes,
} from "src/services/Bot/CustomQA/getAllQuestionAnswer.service";
import useDebounce from "src/hooks/useDebounce";
interface Props {
  search: string;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  metaData: any;
  setMetaData: React.Dispatch<React.SetStateAction<any>>;
}
function useGetAllCustomQA({
  search,
  currentPage,
  setCurrentPage,
  metaData,
  setMetaData,
}: Props) {
  const { profileId } = useParams();

  const queryClient = useQueryClient();
  const [searchTerm, setSearchTerm] = useState(search);

  /**
   * Reference to store the debounce flag, used to track whether a search term update is debounced or not.
   */
  const debounceFlag = useRef(0);

  const debouncedSearchTerm = useDebounce(search, 500);

  const payload = useMemo(() => {
    return {
      profileId: profileId,
      pageNo: currentPage,
      searchTerm: searchTerm?.trim() ? searchTerm : undefined,
    };
  }, [searchTerm, currentPage, profileId]);

  const totalPagesRef = useRef(0);

  const currentState = useRef({
    payload: payload,
    currentPage: currentPage,
  });

  useMemo(() => {
    currentState.current.payload = payload;
    currentState.current.currentPage = currentPage;
  }, [payload, searchTerm, currentPage]);

  /**
   * Hook to manage fetching and caching of custom question-answer data for each page, and handling search term updates.
   * Additionally manages debouncing of search term updates to avoid excessive re-rendering.
   */
  useEffect(() => {
    // Check if debounce flag indicates a debounced search term update
    if (debounceFlag.current > 1) {
      // Reset current page to 1 and clear metadata when a new search term is entered
      setCurrentPage(1);
      setMetaData(null);
    } else {
      // Increment debounce flag to indicate a non-debounced search term update
      debounceFlag.current++;
    }
    // Update search term with the debounced value
    setSearchTerm(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  // Reference to store cached custom question-answer data and metadata
  const cachedDataRef = useRef<{
    data: { [key: number]: QuestionAns[] }; // Dictionary to store data for each page
    metaData: { count: number; total: number }; // Metadata including count and total number of items
  }>({ data: {}, metaData: { count: 0, total: 0 } });

  // Update cached metadata when the metadata state changes
  useMemo(() => {
    if (metaData !== null) {
      // Update cached metadata if new metadata is available
      cachedDataRef.current.metaData = metaData;
    }
  }, [metaData]);

  /**
   * Fetches data for the specified page of custom question-answer pairs.
   */
  const fetchDataForPage = useCallback(
    async (payload: any) => {
      // Define the limit of items to fetch per AJAX request
      const perAjaxFetchLimit = 6;
      // Extract page number from payload
      const pageNo = payload.pageNo;
      // Retrieve cached data
      const cachedData = cachedDataRef.current.data;
      // Calculate pagination parameters
      const { startPage, endPage, start, limit } = paginationLogic(
        pageNo,
        perAjaxFetchLimit,
        cachedDataRef.current.metaData.total,
        0
      );
      // Prepare payload for fetching data
      const sourceArticlePayload: getAllQuestionAnswerParams = {
        start: start < 0 ? 0 : start,
        limit: limit < 0 ? 0 : limit,
        botProfileId: payload.profileId,
      };

      // Include search term in payload if provided
      if (payload.searchTerm?.trim()) {
        sourceArticlePayload.searchTerm = payload.searchTerm;
      }

      // Fetch custom question-answer data
      const customQAData = await getAllQuestionAnswer(sourceArticlePayload);
      // Calculate total pages based on fetched data
      const totalPages = calculateTotalPages(
        customQAData.metaData.total,
        perAjaxFetchLimit
      );
      totalPagesRef.current = totalPages;
      let count = 0;
      // Populate cache with fetched data slices
      for (let i = startPage; i <= endPage; i++) {
        cachedData[i] = pageSlice(customQAData.data, perAjaxFetchLimit, count);
        count++;
      }
      // Update metadata in the cache
      cachedDataRef.current.metaData = customQAData.metaData;
      // Set metadata for the component
      setMetaData(customQAData.metaData);
      // Return fetched data and metadata
      return {
        data: cachedData[pageNo],
        metaData: cachedDataRef.current.metaData,
      } as getAllQuestionAnswerRes;
    },
    // Dependency array for useCallback hook
    [metaData]
  );

  const { data, isLoading, status, fetchStatus, isError, error, refetch } = useQuery({
    queryKey: ["getAllCustomQnA", payload],
    queryFn: () => {
      const dataForPage = fetchDataForPage(payload);
      return dataForPage;
    },
  });

  //function to delete custom qa data in the react query
  const handleDeleteCustomQA = useCallback(
    (customQAId: number | string) => {
      const perAjaxFetchLimit = 6;
      const updatedData = cachedDataRef.current.data[currentPage]?.filter(
        (customQA) => customQA.customQAId != customQAId
      );

      cachedDataRef.current.data[currentPage] = updatedData;

      if (
        updatedData?.length !== cachedDataRef.current.data[currentPage]?.length
      ) {
        cachedDataRef.current.metaData.total =
          cachedDataRef.current.metaData.total - 1;
        const totalPages = calculateTotalPages(
          cachedDataRef.current.metaData.total,
          perAjaxFetchLimit
        );
        totalPagesRef.current = totalPages;
      }

      let queryData: getAllQuestionAnswerRes | undefined =
        queryClient.getQueryData([
          "getAllCustomQnA",
          currentState.current.payload,
        ]);

      if (queryData) {
        const customQAsData = queryData?.data.filter(
          (customQA) => customQA.customQAId != customQAId
        );

        // 2. Update the data in the cache using queryClient.setQueryData
        queryClient.setQueryData(
          ["getAllCustomQnA", currentState.current.payload],
          {
            ...queryData,
            data: customQAsData,
            metaData: {
              ...queryData.metaData,
              total:
                customQAsData?.length !== queryData.data.length
                  ? queryData.metaData.total - 1
                  : queryData.metaData.total,
            },
          }
        );

        queryClient.invalidateQueries([
          "getAllCustomQnA",
          currentState.current.payload,
        ]);
      }
    },
    [data, payload, currentPage]
  );

  //function to update custom qa data in the react query
  const updateCustomQA = useCallback(
    (customQAId: number | string, values: Partial<QuestionAns>) => {
      const updatedData = cachedDataRef.current.data[currentPage]?.map(
        (customQA) =>
          customQA.customQAId == customQAId
            ? { ...customQA, ...values }
            : customQA
      );
      cachedDataRef.current.data[currentPage] = updatedData;

      let queryData: getAllQuestionAnswerRes | undefined =
        queryClient.getQueryData([
          "getAllCustomQnA",
          currentState.current.payload,
        ]);

      if (queryData) {
        const customQAsData = queryData?.data.map((customQA) =>
          customQA.customQAId == customQAId
            ? { ...customQA, ...values }
            : customQA
        );

        // 2. Update the data in the cache using queryClient.setQueryData
        queryClient.setQueryData(
          ["getAllCustomQnA", currentState.current.payload],
          {
            ...queryData,
            data: customQAsData,
          }
        );
      }
    },
    [data, payload, currentPage]
  );

  return {
    customQAs: data?.data,
    isLoading,
    currentPage,
    setCurrentPage,
    totalPages: totalPagesRef.current,
    fetchStatus,
    status,
    isError,
    updateCustomQA,
    handleDeleteCustomQA,
    refetchCustomQAs: refetch,
  };
}

export default useGetAllCustomQA;
