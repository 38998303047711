/**
 * This file is the service file used for making api request.
 * It contains the getAllCategoryV2 function service which is explained below.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants"; // this is used for testing purpose only for frontend.

export interface GetAllCategoryV2Params {
  integrationId: number | string;
  start: number | string;
  limit: number | string;
  onlyCategory?: boolean; // true set for onlyCategorys data get
}

export interface Category {
  categoryId: number | string;
  categoryName: string;
  createdAtGmt: string;
}

export interface GetAllCategoryV2Res {
  data: Category[];
  metaData: {
    totalCount: number | string;
    currentCount: number | string;
  };
}

/**
 * For testing frontend with own api link if backend is not prepared.
 */
const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/getAllCategoryV2`
  : "/api/chatSetting/article/getAllCategoryV2";

/**
 * This service is used to fetch all teh categories.
 */
export const getAllCategoryV2 = async (params: GetAllCategoryV2Params) => {
  const { data: res } = await axiosJSON.post(apiEndPoint, params);
  return res as GetAllCategoryV2Res;
};
