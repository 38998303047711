import { Modal } from "react-bootstrap";
import TabSection, { TabNamesLC } from "./Children/TabSection";
import styles from "./LiveChat.module.scss";
import { Route, Routes, useParams } from "react-router-dom";
import NotificationBar from "src/components/NotificationBar";

const LiveChat = () => {
  return (
    <div className={`p-0 ${styles.liveWrapper}`}>
      <NotificationBar />
      <div className={`p-3`}>
        <TabSection />
        <div className={`row m-0`}>
          <div className={`col-12 mx-0 px-0`}>
            <div className={`${styles.LiveMain} mx-1`}>
              <div className={`d-flex overflow-auto ${styles.LiveMainWrapper}`}>
                <Routes>
                  <Route
                    path={`/chats/`}
                    element={<TabNamesLC.chats.component />}
                  />
                  <Route
                    path={`/chats/:chatStatus`}
                    element={<TabNamesLC.chats.component />}
                  />
                  <Route
                    path={`/chats/:chatStatus/:chatId`}
                    element={<TabNamesLC.chats.component />}
                  />
                  <Route
                    path={`/chats/:chatStatus/:chatId/chattingTab/:tabNameMainChat`}
                    element={<TabNamesLC.chats.component />}
                  />
                  <Route
                    path={`/chats/:chatStatus/:chatId/chattingTab/:tabNameMainChat/:historyId`}
                    element={<TabNamesLC.chats.component />}
                  />
                  <Route
                    path={`/settings/`}
                    element={<TabNamesLC.settings.component />}
                  />
                  <Route
                    path={`/settings/:integrationId/:settingName`}
                    element={<TabNamesLC.settings.component />}
                  />
                  <Route
                    path={`/settings/:integrationId/:settingName/:subSettingName`}
                    element={<TabNamesLC.settings.component />}
                  />
                  <Route
                    path={`/settings/:integrationId/:settingName/:subSettingName/:grandSubSettingName`}
                    element={<TabNamesLC.settings.component />}
                  />
                  {/* <Route
                    path={`/traffic/:trafficFilterTabName`}
                    element={<TabNamesLC.traffic.component />}
                  /> */}
                </Routes>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LiveChat;
