import { Channel } from "pusher-js";

export const getSnoozeUpdatedEvent = (
  channel: Channel,
  callback: (res: any) => void
) => {
  channel.bind("ticket_event_snooze_updated", callback, {
    pauseOnInActiveTab: true,
  });
};
