/**
 * This file defines a custom hook responsible for handling form elements,
 * handling CRUD operations form elements, implementing drag-and-drop update,
 * and managing the process of saving and discarding changes in the bot configuration form.
 * 
 * @author @yuvaraj-busibud
 * @author @navjyot-busibud
 */
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useReducer, useRef, useState } from "react";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { getConfigureForm } from "src/services/Bot/BotProfiles/getConfigureForm.service";
import { getFormElementOptions } from "src/services/Bot/BotProfiles/getFormElementOptions.service";
import {
  IUpdateConfigureFormDataPayload,
  updateConfigureFormData,
} from "src/services/Bot/BotProfiles/updateConfigureFormData.service";
import { IChatFormElementOption } from "src/services/LiveChat/Settings/preChatForm/getPreChatFormData";
import { IUpdatePreChatFormElement } from "src/services/LiveChat/Settings/preChatForm/updatePreChatFormData";
import { IChatFormElement } from "src/store/slices/liveChatSetting/Forms/chatForm.declarations";
import { v4 as uuidv4 } from "uuid";
var objectHash = require("object-hash");


enum ActionTypes {
  SetFormElements = "SET_FORM_ELEMENTS",
  AddNewElement = "ADD_NEW_ELEMENT",
  DeleteElement = "DELETE_ELEMENT",
  ResetFormElements = "RESET_FORM_ELEMENTS",
  SetElementOptions = "SET_ELEMENT_OPTIONS",
}

interface IFormElementOption extends IChatFormElementOption {
  isAlreadyExists?: boolean;
}
interface IFormElement extends IChatFormElement {
  isNewlyAdded?: boolean;
  uniqueId?: string;
}
interface IConfigureFormState {
  formElements: IFormElement[];
  elementOptions: IFormElementOption[];
}

type Action =
  | { type: ActionTypes.SetElementOptions; payload: IFormElementOption[] }
  | { type: ActionTypes.SetFormElements; payload: IFormElement[] }
  | { type: ActionTypes.AddNewElement; payload: number }
  | { type: ActionTypes.DeleteElement; payload: IFormElement }
  | { type: ActionTypes.ResetFormElements; payload: IFormElement[] };

/**
 * Represents the reducer function for managing the chatbot configuration form state.
 * @function reducer
 * @param {IConfigureFormState} state - The current state of the configuration form.
 * @param {Action} action - The action to be performed on the state.
 * @returns {IConfigureFormState} - The updated state after applying the action.
 */
const reducer = (
  state: IConfigureFormState,
  action: Action
): IConfigureFormState => {
  switch (action.type) {
    case ActionTypes.SetFormElements:
      return { ...state, formElements: action.payload };
    case ActionTypes.SetElementOptions:
      return { ...state, elementOptions: action.payload };
    case ActionTypes.AddNewElement:
      const optionId = action.payload;
      const elementOption = state.elementOptions.find(
        (element) => element.elementId === optionId
      );

      const element = state.formElements.find(
        (element) => element.elementId === optionId
      );

      if (
        !elementOption ||
        (!elementOption.canHaveMultiple && element !== undefined)
      ) {
        return state;
      }

      const newElement: IFormElement = {
        elementId: elementOption.elementId,
        elementType: elementOption.elementType,
        elementKey: elementOption.elementType + "_" + uuidv4(),
        elementLabel: elementOption.elementLabel,
        elementValue: "Question",
        elementOptions: ["First Answer", "Second Answer"],
        isRequired: false,
        isDefault: false,
        isNewlyAdded: true,
        uniqueId: uuidv4(),
      };

      return { ...state, formElements: [...state.formElements, newElement] };

    case ActionTypes.DeleteElement:
      const elementToDelete = action.payload;
      const updatedFormElements = state.formElements.filter(
        (formElement) => formElement.uniqueId !== elementToDelete.uniqueId
      );
      return { ...state, formElements: updatedFormElements };

    case ActionTypes.ResetFormElements:
      const formElementList = action.payload.map(
        (element: IChatFormElement) => ({
          ...element,
          uniqueId: uuidv4(),
        })
      );

      return { ...state, formElements: formElementList };

    default:
      return state;
  }
};

// Define the initial state
const initialState: IConfigureFormState = {
  formElements: [],
  elementOptions: [],
};

const useConfigureForm = (profileId: number, onSaveComplete: ()=>void) => {
  const queryClient = useQueryClient();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isChange,setIsChange] = useState(false);
  const [initialFormElements,setInitialFormElements] = useState([] as IChatFormElement[])
  const [saveButtonText, setSaveButtonText] = useState("Save");
  const initialFormElementRef = useRef([] as IChatFormElement[]);
  // Fetch form element options
  const { data: formElementOptions, isLoading: isFormElementOptionsLoading } =
    useQuery(["bot/getFormElementOptions"], getFormElementOptions, {
      // initialData: () => {
      // // Prefetch the form element options during the initial load
      // queryClient.prefetchQuery(
      //   ["bot/getFormElementOptions"],
      //   getFormElementOptions
      // );
      // },
      onSuccess: (data) => {
        // Update form element options in the reducer when the query is successful
        dispatch({
          type: ActionTypes.SetElementOptions,
          payload: Object.values(data.formElementOptions),
        });
      },
    });

  const {
    data: configureFormData,
    isLoading:isFormDataLoading,
    refetch,
  } = useQuery(
    ["bot/configureFormData", profileId],
    () => getConfigureForm({ id: profileId }),
    {
      enabled: !isFormElementOptionsLoading,
      onSuccess: (data) => {
        let formElementList: IChatFormElement[] = data.elements.map(
          (element: IChatFormElement) => ({
            ...element,
            uniqueId: uuidv4(),
            isNewlyAdded: false,
          })
        );
        // Update pre-chat form data in the reducer when the query is successful
        dispatch({
          type: ActionTypes.SetFormElements,
          payload: formElementList,
        });
        // setInitialFormElements(formElementList)
        initialFormElementRef.current = formElementList;
      },
    }
  );

  // Mutation for updating pre-chat form data
  const updateConfigureFormDataMutation = useMutation(updateConfigureFormData, {
    onMutate: () => {
      // Invalidate the configureFormData query when a mutation occurs
      queryClient.invalidateQueries(["configureFormData", profileId]);
    },
    onSuccess: () => {
      pushTheToast({
        type: "success",
        text: "Successfully changed",
        position: "top-right",
      });
      setSaveButtonText("Saved!");
      onSaveComplete();
    },
    onError: () => {
      pushTheToast({
        type: "danger",
        text: "Failed to save!",
        position: "top-right",
      });
      setSaveButtonText("Save");
    }
  });
  // Define an API function for updating configuration data asynchronously
  const updateConfigureFormDataApi = async (payload: any) => {
    await updateConfigureFormDataMutation.mutateAsync(payload);
  };
  // prepare element options based on form element data and configuration
  const elementOptions = useMemo(() => {
    if (formElementOptions && state.formElements) {
      const options: IFormElementOption[] =
        formElementOptions.formElementOptionIds.map((optionId, index) => {
          let elementOption = formElementOptions.formElementOptions[optionId];
          let option: IFormElementOption = {
            ...elementOption,
            isAlreadyExists: false,
          };
          let element = state.formElements.filter(
            (element: IChatFormElement) => optionId === element.elementId
          );
          if (element.length === 0 || elementOption.canHaveMultiple === true) {
            option.isAlreadyExists = false;
          } else {
            option.isAlreadyExists = true;
          }

          return option;
        });
      return options;
    }
  }, [state.formElements, formElementOptions]);

  // New handlers using reducer dispatch
  const handleElementChange = (element: IFormElement) => {
    if (state.formElements === null) {
      return;
    }
    const updatedFormElements = state.formElements.map((formElement) =>
      element.uniqueId === formElement.uniqueId ? element : formElement
    );
    dispatch({
      type: ActionTypes.SetFormElements,
      payload: updatedFormElements,
    });
  };

  const handleNewElement = (optionId: number) => {
    dispatch({ type: ActionTypes.AddNewElement, payload: optionId });
  };

  const handleElementDelete = (element: IChatFormElement) => {
    if (state.formElements === null) {
      return;
    }
    dispatch({ type: ActionTypes.DeleteElement, payload: element });
  };
  //Reset the unsaved changes when clicked on discard changes
  const handleCancelButton = () => {
    if (configureFormData) {
      // const formElementList = configureFormData.elements.map((element) => {
      //   const key = uuidv4();
      //   return {
      //     ...element,
      //     uniqueId: key,
      //   };
      // });

      dispatch({
        type: ActionTypes.ResetFormElements,
        payload: initialFormElementRef.current,
      });
    }
  };

  const handleSetFormElements = (formElements: IFormElement[]) => {
    dispatch({
      type: ActionTypes.SetFormElements,
      payload: formElements,
    });
  };

  // Logic for handleSubmit to update the form element data and validation
  const handleSubmit = () => {
    if (state.formElements) {
      if (state.formElements.length === 0) {
        pushTheToast({
          type: "warning",
          text: "Atleast one element is required",
          position: "top-right",
        });
        setSaveButtonText("Save");
        return;
      }
      // Check if there are any empty elements or options
      const emptyValueElements = state.formElements.filter(
        (element) => {
          const isValueEmpty = element.elementValue.trim().length === 0
          const areOptionsEmpty = element.elementOptions.some(option => option.trim().length === 0);
          return isValueEmpty || areOptionsEmpty;
        } 
      );
      if (emptyValueElements.length > 0) {
        pushTheToast({
          type: "warning",
          text: "Please fill all the fields",
          position: "top-right",
        });
        setSaveButtonText("Save");
        return;
      }

      const payload: IUpdateConfigureFormDataPayload = {
        id: profileId,
      };

      const payloadElements = state.formElements.map((element) => {
        return {
          elementId: element.elementId,
          elementKey: element.elementKey,
          elementValue: element.elementValue,
          elementOptions: element.elementOptions,
          isRequired: element.isRequired,
          isNewlyAdded: element.isNewlyAdded,
        } as IUpdatePreChatFormElement;
      });

      payload.user_details = payloadElements;

      // Call the mutation to update configure form data
      updateConfigureFormDataMutation.mutate(payload);

      // Update the initialFormElements with newly saved 'formElements'
      setInitialFormElements(state.formElements);
      initialFormElementRef.current = state.formElements;
      
    }
  };

  // Get the loading status of the mutation
  const updateConfigLoading = updateConfigureFormDataMutation.isLoading;

  useEffect(() => {
    if (updateConfigLoading === false) {
      // Compare the hashes to determine if there have been changes in the form elements.
      if (objectHash(state.formElements) != objectHash(initialFormElements)) {
        // If there are changes, set the 'saveButtonText' to "Save" and set 'isChange' to 'true'.
        setSaveButtonText("Save");
        setIsChange(true)
      }
      else{
        setIsChange(false)
      }
    }
  }, [updateConfigLoading, initialFormElements,state.formElements]);



  return {
    configureFormData,
    formElements: state.formElements,
    elementOptions,
    handleElementChange,
    handleNewElement,
    handleElementDelete,
    handleCancelButton,
    refetch,
    updateConfigureFormDataApi,
    handleSetFormElements,
    handleSubmit,
    updateConfigLoading,
    isFormElementOptionsLoading,
    isFormDataLoading,
    saveButtonText,
    isChange,
  };
};

export default useConfigureForm;
