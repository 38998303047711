import RuleTableRow from "./Children/RuleTableRow/RuleTableRow";
import { v4 as uuidv4 } from "uuid";
import Select from "react-select";
import styles from "./RuleTable.module.scss";
import genLogo from "src/assets/images/General.png";
import addRule from "src/assets/images/AddedRule1.png";
import { useCallback, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { fetchAllTeams, resetTeamsData } from "src/store/slices/teamSettings/teamSettings.slice";
import {v4 as uuidV4 } from "uuid";
import { updateRoutingRuleParams, updateRoutingRules } from "src/services/LiveChat/Settings/routingRules/updateRoutingRules";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";

const RuleTable = ({routingRuleData,generalTeam, routingRuleIdList,metaData,handleInfiniteScroll, integrationId}:any) => {
  const [teamSelectedOption, setTeamSelectedOption] = useState<any>({label:"",value:""});
  const { teamData, fetchTeamsAjaxStatus, teamIdList, metaData: teamMetaData } =
    useAppSelector((state) => state.teamSettings);
    const dispatch = useAppDispatch();

  useAppSelector((state) => state.teamSettings);
  const [options, setOptions] = useState<any>([]);

  const initialized = useRef(false);
  const totalTeams = useRef(teamMetaData);
  useEffect(() => {
    if (generalTeam !== null) {
      // console.log(generalTeam);
     
        setTeamSelectedOption({
          label: generalTeam.teamName,
          value: generalTeam.teamId
        })

    }
  }, [generalTeam]);

  useEffect(() => {
    
    if(teamData !== null){
      let TeamOptions = (Object.keys(teamData).map(function(key, index){
        let team = teamData[parseInt(key)];
        return {label: team.teamName, value: team.teamId}
      }))

      if(TeamOptions.length === 0){
        return;
      }
      setOptions(TeamOptions);
    }

  }, [teamData ]);

  useEffect(()=>{
    totalTeams.current = {
      count: metaData.count,
      total: metaData.total
    };
  },[teamMetaData]);

  const handleLoadInitialTeam = () => {

    if(initialized.current === false ){
      dispatch(resetTeamsData());
      dispatch(fetchAllTeams());
      initialized.current = true;
    }

  }

    /* Handle infinite scroll */
    const handleTeamInfiniteScroll = useCallback(() => {
      if (fetchTeamsAjaxStatus === "fulfilled") {
        if (totalTeams.current.total !== null) {
          if (totalTeams.current.total > 0 && teamIdList.length < metaData.total) {
            dispatch(fetchAllTeams());
          }
        }    
      }
    }, []);
  

  const formatOptionLabel = ({value , label, customAbbreviation}:any) => {
    return (
    <div key={uuidV4()} className={`d-flex align-items-center`}>
      <img className={`${styles.genLogo}`} src={genLogo} alt="general" />
      <span className={`ms-1 ${styles.selectSpan}`}>{label}</span>{" "}
    </div>
    )
  };

  const handleSelectedValue = (e: any) =>{

    if(e.value !== generalTeam.teamId){

      let payload:updateRoutingRuleParams = {
        teamId: e.value,
        integrationId: integrationId,
        isGeneralTeamUpdate: true
      };

      updateRoutingRules(payload).then((res)=>{

        // dispatch(fetchAllRoutingRulesData(pageIntegrationId));
        setTimeout(() => {
          pushTheToast({
            type: "success",
            text: "successfully updated!",
            position: "top-right"
          });
        }, 0);

        setTeamSelectedOption({
          value: e.value,
          label: e.label
        });

      }).catch((err)=>{
        pushTheToast({
          type: "danger",
          text: "Something went wrong!",
          position: "top-right"
        });
      });

     
    }
  };
  return (
    <>
      <div className={`${styles.tableHead}`}>
        <div className={`${styles.widthSet1}`}>
          <span className={`${styles.headName}`}>Priority </span>
        </div>
        <div className={`${styles.widthSet2}`}>
          <span className={`${styles.headName}`}>Rule Name </span>
        </div>
        <div className={`${styles.widthSet3}`}>
          <span className={`${styles.headName}`}>Trigger </span>
        </div>
        <div className={`${styles.widthSet4}`}>
          <span className={`${styles.headName}`}>Action </span>
        </div>
      </div>
      <div>
      {
        (routingRuleIdList.map(function(ruleId:number|string, index: number){
          return <RuleTableRow key={ruleId} index={index} ruleData = {routingRuleData[parseInt(ruleId+"")]} canMoveTop={index !== 0} canMoveBottom={metaData.total !== (index+1)}/>;
        }))
      }
      <div className={`d-flex`}>
        <div className={`${styles.imgBox}`}>
          <img className={`${styles.addRuleImg}`} src={addRule} alt="more" />
        </div>
        <div className={`${styles.selectBox}`}>
          <span className={`${styles.selecthead}`}>
            If a customer doesn't match any rule, route them to
          </span>
          <Select onFocus={handleLoadInitialTeam} onChange={handleSelectedValue} onMenuScrollToBottom={handleTeamInfiniteScroll} options={options} formatOptionLabel={formatOptionLabel} value={teamSelectedOption} className={`${styles.selItem}`} />
        </div>
      </div>
      </div>
    </>
  );
};
export default RuleTable;
