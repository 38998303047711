import ReactSelect from "react-select";
import styles from "../ReportInternalFilter.module.scss";
import { useAppSelector } from "src/store/store";
import { useCallback, useMemo } from "react";
import {
  reportInternalFiltersActions,
  useReportInternalFilters,
} from "src/routes/Report/hooks/reportFilters/useReportInternalFilters";
import { useQuery } from "@tanstack/react-query";
import { getAllStatuses } from "src/services/TicketService/getAllStatuses";

const useIntTSFilter = () => {
  const { data: allTicketStatus } = useQuery(
    ["useStatusFilter/getTicketStatusList"],
    {
      queryFn: getAllStatuses,
      staleTime: 600000,
      cacheTime: 600000,
    }
  );

  const options = useMemo(() => {
    return (
      allTicketStatus?.allTicketStatusesIds.map((id) => {
        return {
          label: allTicketStatus.allTicketStatuses[id].statusName,
          value: allTicketStatus.allTicketStatuses[id],
        };
      }) ?? []
    );
  }, [allTicketStatus]);

  const { internalFilters, dispatch } = useReportInternalFilters();

  const selectedTicketStatus = useMemo(() => {
    return allTicketStatus?.allTicketStatusesIds.length
      ? (
          allTicketStatus.allTicketStatusesIds.filter((id) =>
            internalFilters.addedFilters.ticketStatus?.includes(id + "")
          ) ?? []
        ).map((id) => {
          return {
            label: allTicketStatus.allTicketStatuses[id].statusName,
            value: allTicketStatus.allTicketStatuses[id],
          };
        })
      : [];
  }, [internalFilters, allTicketStatus]);

  const onChangeTicketStatus = useCallback(
    (value: typeof selectedTicketStatus) => {
      dispatch([
        reportInternalFiltersActions.setFilters,
        { ticketStatus: value.map((data) => data.value.id + "") },
      ]);
    },
    [selectedTicketStatus]
  );
  return {
    options,
    selectedTicketStatus,
    onChangeTicketStatus,
  };
};

function TicketStatus() {
  const { options, selectedTicketStatus, onChangeTicketStatus } =
    useIntTSFilter();

  return (
    <div className="px-2 pb-2">
      <label className={`${styles.labelText}`}>Ticket Status</label>
      <ReactSelect
        isMulti
        options={options}
        value={selectedTicketStatus}
        onChange={onChangeTicketStatus as any}
        className={`${styles.text}`}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
            font: "normal normal normal 12px/18px Poppins",
            color: "#000000",
          }),
          multiValue: (base, { data }) => ({
            ...base,
            backgroundColor: '#F4F4F4', // Background color for selected values
            borderRadius: '6px',
            opacity: 1,
          }),
          multiValueLabel: (base) => ({
            ...base,
            font: "normal normal 500 12px/18px Poppins",
            letterSpacing: "0px",
            color: "#1F2223",
            paddingLeft:"12px",
          }),
          multiValueRemove: (base, { data }) => ({
            ...base,
            // color: '#000000', // Remove icon color for selected values
            fontSize: "6px",
            // ':hover': {
            //   backgroundColor: 'red', // Change the background color on hover if needed
            // },
          }),
        }}
      />
    </div>
  );
}

export default TicketStatus;
