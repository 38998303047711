import axios from "axios";
import { axiosJSON } from "src/globals/axiosEndPoints";
import APIResponse from "./APIReponse";

interface MoveRoutingRuleOrder {
  ruleId: number | string;
  status : boolean;
}

export interface MoveRoutingRuleOrderParams {
  ruleId : number|string;
  moveTo: "top" | "bottom";
}



export async function moveRoutingRuleOrder (params : MoveRoutingRuleOrderParams) {

    const { data:res } = await axiosJSON.post(
        "/api/chatSetting/routingRule/moveRuleOrder",{
        ...params
      }
    );

    if(res.error === true )
    {
      throw new Error(res.message as string)
    }

    return res.data as MoveRoutingRuleOrder;
  } 
