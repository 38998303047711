import { GoalSettingData } from "./GoalSetting.slice";

export const fetchAllGoalSettingsFulfilled = (
  state: GoalSettingData,
  { payload }: any
) => {
  const {
    goalSettingData,
    goalSettingIdList,
    count,
    totalCount,
    goalTypeOptions
  }: any = payload as any;

  state.goalSettingData = {
    ...state.goalSettingData,
    ...goalSettingData
  };
  console.log("fetchAllGoalSettingFulfilled ::: goalSettingIdList:: ", goalSettingIdList);
  state.goalSettingIdList = [
    ...state.goalSettingIdList,
    ...goalSettingIdList
  ]
  state.metaData.count = count;
  state.metaData.total = totalCount
  state.fetchGoalSettingAjaxStatus = "fulfilled";
  state.goalTypeOptions = {
    ...goalTypeOptions
  };
}

export default { fetchAllGoalSettingsFulfilled };