import { useAppSelector } from "src/store/store";
import ReportTicketBody from "../../../ReportTicketBody/ReportTicketBody";

const RevenueGenerate = () => {
  const ui_visibility = useAppSelector(
    (state) => state.globals.currentUserData?.ui_visibility
  );

  return (
    <div>
      {/* Revenue Generated By Time */}
      {ui_visibility?.includes("tickets_revenue_revenueGeneratedByTime") && (
        <ReportTicketBody
          endPoint={"tickets/revenue/revenueGeneratedByTime"}
          title={"Revenue generated by time"}
          tabProps={{
            showComparison: false,
            showDailyPart: true,
            showListView: false,
            showNotifyTab: false,
            showInnerFilters: false,
          }}
        />
      )}
    </div>
  );
};

export default RevenueGenerate;
