/**
 * This file is the service file used for making api request.
 * It contains the reportTicketGraphService function service which is explained below.
 *
 * @author Yash Aditya
 * @author Anubhav Jain
 */

import { axiosJSON } from "src/globals/axiosEndPoints";
import { FilterInParam } from "src/routes/Report/hooks/reportFilters/useReportFilters";
import {
  ReportDateType,
  ReportInternalFilters,
} from "src/routes/Report/hooks/reportFilters/useReportInternalFilters";
import { v4 as uuid } from "uuid";
/**
 * This is the default time cluster if not sent from backend.
 */
export const allTimeClusters: { [id: string]: { id: string; name: string } } = {
  "24+": { id: "24+", name: "24+ hrs" },
  "0-4": { id: "0-4", name: "0-4 hrs" },
  "12-24": { id: "12-24", name: "12-24 hrs" },
  "4-12": { id: "4-12", name: "4-12 hrs" },
};

/**
 * This is the default message cluster if not sent from backend.
 */
export const allMsgClusters: { [id: string]: { id: string; name: string } } = {
  "8+": { id: "8+", name: "8+ messages" },
  "0-2": { id: "0-2", name: "0-2 messages" },
  "2-4": { id: "2-4", name: "2-4 messages" },
  "4-8": { id: "4-8", name: "4-8 messages" },
};

export interface ReportTicketListParams {
  viewType: "listView";
  start: number;
  limit: number;
  columns?: Array<string>;
  filters?: FilterInParam; // global filters
  internalFilters?: ReportInternalFilters;
  reportDateType?: ReportDateType;
  integrationId?: string;
  botProfileId?: string;
}

export interface botDetails {
  id: number;
  name: string;
  imgUrl: string | null;
  type?: "bot" | "agent"; // To show User avater or bot image
}
export interface TicketData {
  ticket_id: string;
  is_read: boolean;
  ticket_subject: string;
  last_message: string;
  article_id: string;
  article_name: string;
  article_description: string;
  article_right_column_count?: string;
  article_count?: string; //For number of times an article used by cern
  chatId?: number;
  customer?: {
    customerId: string;
    customerName: string;
    imgUrl: string | null;
  };
  agentId?: string;
  agentName?: string;
  agentImageURL?: string;
  availabilityHours?: string;
  customerStatus?: "live" | "missed" | "archived";
  chatRating?: string | number;
  lastMessage?: string;
  lastMessageAttachmentCount?: number;
  lastMessageTime?: string;
  unreadCount?: number;
  customerResponse?: string;
  handledBy?: botDetails[];
  revenue?: string; // For revenue graph listview
  channel?: string; // For revenue graph listview
}

export interface ReportTicketList {
  ticketIds: Array<string>;
  ticketData: { [ticketId: string]: TicketData };
  metaData: {
    count: number;
    totalCount: number;
    totalArticleDuration?: string;
    backlogPercentage?: number; // For Backlog Percentage listview
    averageNumberOfTicketsCreated?: number; // For Avg number of tickets created listview
    medianFirstReplyTime?: number; // For First reply time (median and avg) over time listview
    averageFirstReplyTime?: number; // For First reply time (median and avg) over time listview
    medianFullResolutionTime?: number; // For full resolution time brackets listview
    averageFullResolutionTime?: number; // For full resolution time brackets listviews
  };
  categories?: {
    [id: string]: {
      id: string;
      name: string;
    };
  };
}

// All the api routes for different graphs and their list view
export type CurrentStatusEndPoints =
  | "tickets/currentStatus/totalUnresolvedTickets"
  | "tickets/currentStatus/ticketsMostBackForth"
  | "tickets/currentStatus/unresolvedTicketsPendingLongest"
  | "tickets/currentStatus/usualCapacity"
  | "tickets/currentStatus/potentiallyUrgent"
  | "tickets/currentStatus/angryCustomers"
  | "tickets/currentStatus/convertedPrePurchase"
  | "tickets/currentStatus/notConvertedPrePurchase"
  | "tickets/volumeActivity/averageTicketsGraph"
  | "tickets/volumeActivity/backlogPercentage"
  | "tickets/volumeActivity/responseSent"
  | "tickets/volumeActivity/ticketsClosed"
  | "tickets/volumeActivity/ticketsCreated"
  | "knowledgeBase/articlesMostRead" //Articles with most read duration
  | "knowledgeBase/newArticlesAddedByTime" //New Articles Added by time
  | "knowledgeBase/highestRatedArticles" //Articles with the highest rating
  | "knowledgeBase/lowestRatedArticles" //Articles with the lowest rating
  | "knowledgeBase/authorsMostArticles" //Team members who have authored the most articles
  | "knowledgeBase/ticketsResolvedDueToKb" //Tickets resolved due to KB articles
  | "liveChat/totalChats/totalChatReports" // Total Chats
  | "liveChat/totalChats/totalChatHeatmapReports" // Total chats heatmap
  | "liveChat/missedChats/missedChatsReports" // Missed chats
  | "liveChat/chatSatisfaction/chatSatisfactionReports" // Chat Satisfaction
  | "liveChat/chatAvailability/chatAvailabilityReports" // Chat Availability
  | "tickets/performance/ticketsFirstReply" //Performance tickets first reply
  | "tickets/performance/ticketsFullResolution" //Performance tickets full resolution
  | "tickets/performance/ticketsFirstReplyOverTime" //Performance tickets first reply over time
  | "tickets/performance/ticketsFullResolutionOverTime" //Performance tickets full resolution over time
  | "tickets/performance/averageTicketRating" //Performance average ticket rating
  | "tickets/revenue/revenueGeneratedByTime" // Revenue generated by time
  | "bot/percentageCernFoundUseful"
  | "bot/volumeOfChartsByCern"
  | "bot/chartsCernDidNotFindAnswersByTime"
  | "bot/mostUsedArticlesByCernToAnswerQuestions"
  | "bot/mostUsedCustomAnsByCernToAnswerQuestions";

/**
 * This service is dynamic for all the list views in the graphs and brings the data from backend based on the given parameters and endpoint.
 */
export const reportTicketListService = async (
  params: ReportTicketListParams,
  endPoint: CurrentStatusEndPoints,
) => {
  if (params.columns === undefined && endPoint.startsWith("tickets")) {
    params.columns = ["ticket_id", "is_read", "ticket_subject", "last_message"];
  }
  const { data: res } = await axiosJSON.post(
    `/api/reports/${endPoint}`,
    params,
  );
  if (res.err || res.error) {
    throw res.msg;
  }
  const ret: ReportTicketList = {
    ticketIds: [],
    ticketData: {},
    metaData: {
      count: res.metaData.currentCount,
      totalCount: res.metaData.totalCount,
      totalArticleDuration: res.metaData.totalArticleDuration,
      backlogPercentage: res.metaData.backlogPercentage ?? 0, // For Backlog Percentage listview
      averageNumberOfTicketsCreated:
        res.metaData.averageNumberOfTicketsCreated ?? 0, // For Avg number of tickets created listview
      medianFirstReplyTime: res.metaData.medianFirstReplyTime ?? 0, // For First reply time (median and avg) over time listview
      averageFirstReplyTime: res.metaData.averageFirstReplyTime ?? 0, // For First reply time (median and avg) over time listview
      medianFullResolutionTime: res.metaData.medianFullResolutionTime ?? 0, // For full resolution time brackets listview
      averageFullResolutionTime: res.metaData.averageFullResolutionTime ?? 0, // For full resolution time brackets listview
    },
  };

  if (res.categories?.length) {
    ret.categories = {};
    res.categories.forEach((cat: any) => {
      if (ret.categories && cat.id) {
        ret.categories[cat.id] = cat;
      } else if (ret.categories && cat.agentId) {
        ret.categories[cat.agentId] = cat;
      }
    });
  }

  res.data.forEach((value: any) => {
    if (
      endPoint.startsWith("tickets") &&
      !endPoint.includes("revenueGeneratedByTime")
    ) {
      const valueJSON = convertToJSON(value, params.columns ?? []);
      ret.ticketIds.push(`${valueJSON.ticket_id}`);
      ret.ticketData[valueJSON.ticket_id] = valueJSON;
    } else if (
      endPoint.startsWith("bot") ||
      endPoint.includes("totalChatReports") ||
      endPoint.includes("missedChatsReports") ||
      endPoint.includes("chatSatisfactionReports") ||
      endPoint.includes("chatAvailabilityReports") ||
      endPoint.includes("revenueGeneratedByTime")
    ) {
      const id = uuid(); //Adding uuid as id as chatId can be repeated
      ret.ticketIds.push(`${id}`);
      ret.ticketData[id] = value;
    } else {
      ret.ticketIds.push(`${value.article_id}`);
      ret.ticketData[value.article_id] = value;
    }
  });
  return ret;
};

const convertToJSON = (ticketData: any, columns: Array<string>) => {
  const value: any = {};
  columns.forEach((col, index) => {
    value[col] = ticketData[index];
  });
  return value as TicketData;
};

export default reportTicketListService;
