import { axiosJSON } from "src/globals/axiosEndPoints";
import log from "loglevel";
import { TicketsQuery } from "./getTicketsV2";

/* 
Demo payload json: 
{
    "ticketIds":[28,11,17,18],
    "tags":[2, 8]
}

Demo response json:
{
    "err": false,
    "msg": "Updated",
    "data": {
        "updatedTickets": [
            11,
            17,
            18
        ]
    }
}
*/

export type AddTicketTagsPayload = {
  ticketIds?: string[];
  tags: number[];
  allTickets?: boolean;
  filters?: TicketsQuery["filters"],
  isCustomerView?: boolean;
};

export async function addTicketTagsApi(servicePayload: AddTicketTagsPayload) {
  const { data } = await axiosJSON.post(
    `/api/ticket/addTagsInBulk`,
    servicePayload
  );
  if (data.err === true) {
    throw new Error(data.msg as string);
  }
  const normalizedData = normalizeData(data);
  // log.debug("add ticket tags raw response:", data);
  // log.debug("add ticket tags normalized response:", normalizedData);
  return normalizedData;
}

// convert incoming data to the required format
function normalizeData(response: any) {
  const normalizedData = response;
  return normalizedData;
}
