import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReactSelect, {
  MultiValue,
  SingleValue,
  ControlProps,
  OptionProps,
  SingleValueProps,
  components,
} from "react-select";
// import { OverlayTrigger, Popover } from "react-bootstrap";
import {
  StepQComboOption,
  StepQComboValue,
  StepQComboValueAllTypes,
} from "src/features/ReturnAutoWorkFlow/ReturnAutoWorkFlow.types";
import styles from "./ComboOptions.module.scss";
import getComboOptionsService, {
  ComboOptionsParams,
} from "src/services/ReturnAutoWorkFlow/getComboOptions.service";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { deepCompare } from "src/utils/utils";

const debounceDelay = 700;
const comboSelectOptionsLimit = 100;

/**
 * Custom hook to manage and fetch combo select options.
 *
 * @param params - The initial options and configuration for loading more options.
 * @returns An object containing the options, a flag for more options, a loading state, and a function to fetch more options.
 */
const useComboSelectOptions = ({
  options,
  loadOptions,
  ...params
}: {
  /**
   * Initial array of options with label and value.
   */
  options: Array<{
    label: string;
    value: string;
  }>;
  /**
   * Boolean flag to determine whether to load options initially.
   */
  loadOptions: boolean;
} & Omit<ComboOptionsParams, "start" | "limit">) => {
  /** State to store all loaded options. */
  const [allOptions, setAllOptions] = useState(options);
  /** State to track if there are more options to load. */
  const [hasMore, setHasMore] = useState(loadOptions);
  /** State to manage loading status. */
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Fetches additional options based on the provided parameters.
   * Prevents fetching if there are no more options or if already loading.
   */
  const fetchOptions = useCallback(() => {
    if (hasMore && !isLoading) {
      setIsLoading(true);
      getComboOptionsService({
        start:
          allOptions.length < comboSelectOptionsLimit ? 0 : allOptions.length,
        limit: comboSelectOptionsLimit,
        ...params,
      })
        .then((res) => {
          setHasMore(res.hasMore);
          setAllOptions((prevValue) => {
            const prev: typeof prevValue = [];
            const prevValues: Array<string> = [];

            for (let i = 0; i < prevValue.length; i++) {
              const v = prevValue[i];
              prev.push({ ...v });
              prevValues.push(v.value);
            }

            for (let i = 0; i < res.options.length; i++) {
              const option = res.options[i];
              if (!prevValues.includes(option.value + "")) {
                prev.push(option);
              }
            }

            return prev;
          });
        })
        .catch((err) => {
          console.error(err);
          pushTheToast({
            type: "danger",
            text: "Error fetching options...",
            position: "top-right",
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [params, allOptions, hasMore, isLoading]);

  const fetchOnOpen = useCallback(() => {
    if (
      (allOptions.length < comboSelectOptionsLimit ? 0 : allOptions.length) ===
      0
    ) {
      fetchOptions();
    }
  }, [fetchOptions]);

  return { allOptions, hasMore, isLoading, fetchOptions, fetchOnOpen };
};

/**
 * A function type representing a callback for handling changes in combo values.
 *
 * @param newValue - The new value selected or entered.
 * @param newValueKey - The key corresponding to the value in the combo.
 */
export type ComboChangeFunction = ({
  newValue,
  newValueKey,
}: {
  newValue: StepQComboValueAllTypes;
  newValueKey: string;
}) => void;

// custom styles css
const customStyles = {
  // Other styles for the React Select can be defined here
  control: (provided: any, _: any) => ({
    ...provided,
    background: "#f5f5f5 0% 0% no-repeat padding-box",
    borderColor: "#9e9e9e",
    minHeight: "37px",
    width: "100%",
    border: "1px solid transparent",
    borderRadius: "13px",
    color: "#000000",
    cursor: "pointer",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#f5f5f5" : "#fff", // Change the hover color here
    "&:hover": {
      backgroundColor: "#f5f5f5", // Change the hover color here as well
    },
    font: "normal normal normal 14px/16px Poppins",
    padding: "10px",
    color: "black",
  }),
  menuList: (provided: any) => ({
    ...provided,
    margin: "0 5px", // Add 5px margin on the left and right sides of the menu
  }),
  menuPortal: (provided: any) => ({ ...provided, zIndex: 20000 }),
};

// Interface representing an option with a value and label
interface Option {
  value: string;
  label: string;
}

// CustomControl component for rendering the control container
export const CustomControl: React.FC<ControlProps> = ({
  children,
  innerRef,
  innerProps,
  isFocused,
  className,
  isMulti,
}) => {
  return (
    <div
      ref={innerRef}
      className={`custom-control-container`}
    >
      <div
        {...innerProps}
        className={className}
      >
        {children}
      </div>
    </div>
  );
};

// CustomOption component for rendering each option in the dropdown menu
export const CustomOption: React.FC<OptionProps<Option>> = (props) => (
  <components.Option {...props}>
    <div className={`${styles.items}`}>
      <span>{props.data?.label}</span>
    </div>
  </components.Option>
);

// CustomSingleValue component for rendering the selected value in the dropdown
export const CustomSingleValue: React.FC<SingleValueProps<Option>> = (
  props,
) => (
  <components.SingleValue {...props}>
    <div className={`${styles.items}`}>
      <span className={`${styles.selectedValue}`}>{props.data?.label}</span>
    </div>
  </components.SingleValue>
);

// CustomDropdownIndicator component for rendering the dropdown indicator icon
export const CustomDropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <i
        className={`fa-solid ${styles.arrowColor} ${
          props.selectProps.menuIsOpen ? "fa-caret-up" : "fa-caret-down"
        }`}
      ></i>
    </components.DropdownIndicator>
  );
};

const CustomControlDaysWrapper = (props: any) => (
  <CustomControl
    {...props}
    className={`custom-control ${styles.brandDrop} ${styles.daysDrop}`}
  />
);

const CustomControlOrderWrapper = (props: any) => (
  <CustomControl
    {...props}
    className={`custom-control ${styles.brandDrop} ${styles.orderDrop}`}
  />
);

const CustomMenuList = (props: any) => (
  <components.MenuList
    {...props}
    className={`react-select-menulist`}
  />
);

/**
 * A React component for rendering a number input with increment and decrement buttons.
 *
 * @param value - The current numeric value.
 * @param valueKey - The key corresponding to the value.
 * @param onChange - A callback function triggered on value change.
 */
const ComboNumberBtn = ({
  value,
  valueKey,
  onChange,
}: {
  value: number;
  valueKey: string;
  onChange: ComboChangeFunction;
}) => {
  const [numValue, setNumValue] = useState(value);

  useMemo(() => {
    setNumValue(value);
  }, [value]);

  /**
   * Used for debouncing the increament change.
   */
  const timeOutIncrRef = useRef(null as any);
  /**
   * Used for debouncing the increament change.
   */
  const timeOutDecrRef = useRef(null as any);

  // const [showPopover, setShowPopover] = useState(false);
  const onIncreament = useCallback(() => {
    const newValue = numValue + 1;
    setNumValue(newValue);

    /** Clearing for debouncing effect. */
    clearTimeout(timeOutIncrRef.current);

    /**
     * Setting up debounce.
     */
    timeOutIncrRef.current = setTimeout(() => {
      onChange({ newValue: newValue, newValueKey: valueKey });
    }, debounceDelay);
  }, [numValue, valueKey, onChange]);

  const onDecreament = useCallback(() => {
    if (numValue > 1) {
      const newValue = numValue - 1;
      setNumValue(newValue);

      /** Clearing for debouncing effect. */
      clearTimeout(timeOutDecrRef.current);

      /**
       * Setting up debounce.
       */
      timeOutDecrRef.current = setTimeout(() => {
        onChange({ newValue: newValue, newValueKey: valueKey });
      }, debounceDelay);
    }
  }, [numValue, valueKey, onChange]);

  return (
    <div className={`mb-1`}>
      <div
        className={`d-flex justify-content-between align-items-center py-1 mx-lg-2 ${
          styles.daysCounter
        } ${false ? "border-danger shadow-none" : ""}`}
      >
        <div
          className="px-1"
          role="button"
        >
          <div
            className={`d-flex align-items-center justify-content-center me-2 me-md-0 ${styles.addMore}`}
            onClick={onDecreament}
            style={{
              pointerEvents: numValue <= 0 ? "none" : "auto",
              opacity: numValue <= 0 ? 0.5 : 1,
            }}
          >
            <span className="d-block">
              <i className="fa-solid fa-minus"></i>
            </span>
          </div>
        </div>

        <div className="px-1">
          {/*  Added to show popover when return window days is 0, which is not possible for now. As we are not allowing to decreament it to 0. */}
          {/* <OverlayTrigger
            show={showPopover}
            placement="bottom"
            overlay={
              <Popover
                id="popover-basic"
                className="rounded-3"
              >
                <Popover.Body className="d-flex flex-row">
                  <div className="border px-2 bg-warning text-white me-1 rounded-3 ">
                    !
                  </div>
                  <div className="mt-1">Please fill this field</div>
                </Popover.Body>
              </Popover>
            }
            rootClose={true}
            rootCloseEvent="mousedown"
            onToggle={(isShown) => {
              // Set the state variable based on the popover visibility
              setShowPopover(isShown);
            }}
          ></OverlayTrigger> */}
          <div className={`px-1 ${styles.selectItem}`}>
            {/* Display the days with a leading zero if it is a single digit */}
            {numValue < 10 ? `0${numValue}` : numValue}
          </div>
        </div>
        <div
          className="px-1"
          role="button"
        >
          <div
            className={`d-flex align-items-center justify-content-center me-2 me-md-0 ${styles.addMore}`}
            onClick={onIncreament}
          >
            <span className="d-block">
              <i className="fa-solid fa-plus"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * A React component for rendering a text input field.
 *
 * @param value - The current string value.
 * @param valueKey - The key corresponding to the value.
 * @param onChange - A callback function triggered on value change.
 * @param showErrors - A boolean to see if error can be shown.
 * @param placeholder - Placeholder.
 */
const ComboString = ({
  value,
  valueKey,
  onChange,
  showErrors,
  placeholder = "...",
}: {
  value: string;
  valueKey: string;
  onChange: ComboChangeFunction;
  showErrors: boolean;
  placeholder?: string;
}) => {
  /**
   * Handles changes in the text input field.
   *
   * @param e - The change event from the input field.
   */
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      onChange({ newValue: e.target.value, newValueKey: valueKey });
    },
    [valueKey, onChange, value],
  );

  return (
    <div className={`mb-1 me-2 d-flex align-items-center`}>
      <input
        type="text"
        placeholder={placeholder}
        className={`px-2 py-1 ${styles.textInput} ${
          !value.trim() && showErrors ? "border border-danger" : ""
        }`}
        value={value}
        onChange={handleChange}
      />
    </div>
  );
};

/**
 * A React component for rendering a number input field.
 *
 * @param value - The current number value.
 * @param valueKey - The key corresponding to the value.
 * @param onChange - A callback function triggered on value change.
 * @param showErrors - A boolean to see if error can be shown.
 * @param placeholder - Placeholder.
 */
const ComboNumber = ({
  value,
  valueKey,
  onChange,
  showErrors,
  placeholder = "00",
}: {
  value: null | number;
  valueKey: string;
  onChange: ComboChangeFunction;
  showErrors: boolean;
  placeholder?: string;
}) => {
  /**
   * Handles changes in the text input field.
   *
   * @param e - The change event from the input field.
   */
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      onChange({
        newValue: e.target.value.trim() ? parseFloat(e.target.value) : null,
        newValueKey: valueKey,
      });
    },
    [valueKey, onChange, value],
  );

  return (
    <div className={`mb-1 me-2 d-flex align-items-center`}>
      <input
        type="number"
        placeholder={placeholder}
        className={`px-2 py-1 ${styles.textInput} ${
          !value && showErrors ? "border border-danger" : ""
        }`}
        value={value ?? ""}
        onChange={handleChange}
        min={0}
      />
    </div>
  );
};

/**
 * A React component for rendering a single-select dropdown using ReactSelect.
 *
 * @param value - The current selected value.
 * @param valueKey - The key corresponding to the value.
 * @param onChange - A callback function triggered on value change.
 * @param options - An array of options to display in the dropdown.
 */
const ComboSingleSelect = ({
  value,
  valueKey,
  onChange,
  options,
  loadOptions,
  placeholder = "Select",
  ...params
}: {
  value: null | string;
  valueKey: string;
  onChange: ComboChangeFunction;
  options: Array<{
    label: string;
    value: string;
  }>;
  loadOptions: boolean;
  placeholder?: string;
  integrationId: string;
  stepId: string;
  questionId: string;
}) => {
  const { allOptions, hasMore, isLoading, fetchOptions, fetchOnOpen } =
    useComboSelectOptions({
      options,
      loadOptions,
      valueKey,
      ...params,
    });

  /**
   * Memoized selected value based on the current value and allOptions.
   */
  const selectedValue = useMemo(
    () =>
      value
        ? {
            label: allOptions.find((o) => o.value === value)?.label ?? "",
            value,
          }
        : null,
    [value, allOptions],
  );

  /**
   * Handles changes in the single-select dropdown.
   *
   * @param v - The selected option.
   */
  const handleChange = useCallback(
    (
      v: SingleValue<{
        label: string;
        value: string;
      }>,
    ) => {
      if (v?.value && selectedValue?.value !== v?.value) {
        onChange({ newValue: v.value, newValueKey: valueKey });
      }
    },
    [onChange, valueKey, selectedValue],
  );

  return (
    <div className={`mb-1`}>
      <ReactSelect
        isClearable={false}
        isSearchable={false}
        isMulti={false}
        options={allOptions}
        getOptionLabel={(o) => {
          return o.label;
        }}
        getOptionValue={(o) => {
          return o.value;
        }}
        value={selectedValue}
        onChange={handleChange as any}
        //Added for react-select to solve Z-index issue.
        menuPortalTarget={document.querySelector("body")}
        menuPlacement="auto"
        menuPosition="fixed"
        closeMenuOnScroll={(e: any) => {
          if (e?.target?.classList?.contains("react-select-menulist")) {
            return false;
          } else {
            return true;
          }
        }}
        styles={customStyles}
        components={{
          Option: CustomOption,
          SingleValue: CustomSingleValue,
          Control: CustomControlOrderWrapper,
          DropdownIndicator: CustomDropdownIndicator,
          IndicatorSeparator: null,
          MenuList: CustomMenuList,
        }}
        isLoading={isLoading}
        onMenuOpen={hasMore ? fetchOnOpen : undefined}
        onMenuScrollToBottom={hasMore ? fetchOptions : undefined}
        placeholder={placeholder}
      />
    </div>
  );
};

/**
 * A React component for rendering a multi-select dropdown using ReactSelect.
 *
 * @param value - The current selected values.
 * @param valueKey - The key corresponding to the values.
 * @param onChange - A callback function triggered on value change.
 * @param options - An array of options to display in the dropdown.
 */
const ComboMultiSelect = ({
  value,
  valueKey,
  onChange,
  options,
  loadOptions,
  placeholder = "Select",
  ...params
}: {
  value: Array<string>;
  valueKey: string;
  onChange: ComboChangeFunction;
  options: Array<{
    label: string;
    value: string;
  }>;
  loadOptions: boolean;
  placeholder?: string;
  integrationId: string;
  stepId: string;
  questionId: string;
}) => {
  const { allOptions, hasMore, isLoading, fetchOptions, fetchOnOpen } =
    useComboSelectOptions({
      options,
      loadOptions,
      valueKey,
      ...params,
    });

  /**
   * Memoized selected values based on the current value and options.
   */
  const selectedValue = useMemo(
    () => allOptions.filter((o) => value.includes(o.value)),
    [value, allOptions],
  );

  /**
   * Handles changes in the multi-select dropdown.
   *
   * @param v - The selected options.
   */
  const handleChange = useCallback(
    (
      v: MultiValue<{
        label: string;
        value: string;
      }>,
    ) => {
      if (v.length) {
        onChange({
          newValue: v.map((val) => val.value),
          newValueKey: valueKey,
        });
      }
    },
    [onChange, valueKey],
  );

  return (
    <div className={`mb-1`}>
      <ReactSelect
        isClearable={false}
        isSearchable={false}
        isMulti={true}
        options={allOptions}
        getOptionLabel={(o) => {
          return o.label;
        }}
        getOptionValue={(o) => {
          return o.value;
        }}
        value={selectedValue}
        onChange={handleChange}
        //Added for react-select to solve Z-index issue.
        menuPortalTarget={document.body}
        menuPlacement="auto"
        menuPosition="fixed"
        closeMenuOnScroll={(e: any) => {
          if (e?.target?.classList?.contains("react-select-menulist")) {
            return false;
          } else {
            return true;
          }
        }}
        styles={customStyles}
        components={{
          MenuList: CustomMenuList,
        }}
        isLoading={isLoading}
        onMenuOpen={hasMore ? fetchOnOpen : undefined}
        onMenuScrollToBottom={hasMore ? fetchOptions : undefined}
        placeholder={placeholder}
      />
    </div>
  );
};

/**
 * A React component that renders different types of combo boxes based on provided options.
 * It manages the current value state and validates the value before triggering an external change handler.
 *
 * @param comboOptions - An array of combo box options, each specifying the type, key, and options for the combo box.
 * @param value - The initial value for the combo boxes, mapped by their keys.
 * @param onChange - A function called when a valid change occurs in the combo boxes.
 */
function ComboOptions({
  comboOptions,
  value,
  onChange,
  showErrors,
  questionContainsKeyInputs,
  ...params
}: {
  comboOptions: Array<StepQComboOption>;
  value: StepQComboValue;
  onChange: (value: StepQComboValue) => void;
  showErrors: boolean;
  integrationId: string;
  stepId: string;
  questionId: string;
  questionContainsKeyInputs: boolean;
}) {
  const [thisValue, setThisValue] = useState(value);

  /**
   * Checks if all required values in the comboOptions are filled correctly.
   *
   * @param newValue - The current or updated combo values.
   * @returns boolean - True if all required values are filled; otherwise, false.
   */
  const isValueFilled = useCallback(
    (newValue: StepQComboValue) => {
      for (let i = 0; i < comboOptions.length; i++) {
        const comboOption = comboOptions[i];

        // Skip validation for 'showText' types since they don't require a value.
        if (comboOption.valueType === "showText") {
          continue;
        }

        // Ensure the valueKey exists for further validation.
        if (!comboOption.valueKey) {
          return false;
        }

        // Validate based on the valueType specified in each comboOption.
        switch (comboOption.valueType) {
          case "number": {
            console.log(newValue);
            // Check if the value is a non-zero number.
            if (
              !(
                typeof newValue[comboOption.valueKey] === "number" &&
                newValue[comboOption.valueKey]
              )
            ) {
              return false;
            }
            break;
          }
          case "numberButton": {
            // Check if the value is a non-zero number.
            if (
              !(
                typeof newValue[comboOption.valueKey] === "number" &&
                newValue[comboOption.valueKey]
              )
            ) {
              return false;
            }
            break;
          }
          case "string": {
            // Check if the value is a non-empty string.
            if (
              !(
                typeof newValue[comboOption.valueKey] === "string" &&
                (newValue[comboOption.valueKey] as string).trim()
              )
            ) {
              return false;
            }
            break;
          }
          case "singleSelect": {
            // Check if a valid option is selected (non-empty string).
            if (
              !(
                typeof newValue[comboOption.valueKey] === "string" &&
                (newValue[comboOption.valueKey] as string).trim()
              )
            ) {
              return false;
            }
            break;
          }
          case "multiSelect": {
            // Check if at least one option is selected in the multi-select.
            if (
              !(
                Array.isArray(newValue[comboOption.valueKey]) &&
                (newValue[comboOption.valueKey] as Array<string>).length
              )
            ) {
              return false;
            }
            break;
          }

          default: {
            break;
          }
        }
      }

      // Return true if all required fields are filled correctly.
      return true;
    },
    [comboOptions],
  );

  const currentState = useRef({ value, thisValue, isValueFilled, onChange });
  useMemo(() => {
    currentState.current.thisValue = thisValue;
    currentState.current.value = value;
    currentState.current.isValueFilled = isValueFilled;
    currentState.current.onChange = onChange;
  }, [value, thisValue, isValueFilled, onChange]);

  /**
   * Handles changes in combo values and updates the local state.
   * If the new value passes the validation, the external onChange handler is called.
   *
   * @param newValue - The new value to be updated.
   * @param newValueKey - The key corresponding to the updated value.
   */
  const handleOnChange: ComboChangeFunction = useCallback(
    ({ newValue, newValueKey }) => {
      setThisValue((prev) => {
        const newV = { ...prev, [newValueKey]: newValue };
        return newV;
      });
    },
    [setThisValue, onChange],
  );

  /**
   * Syncs the local state with the external value whenever the value prop changes.
   */
  useEffect(() => {
    if (!deepCompare(value, currentState.current.thisValue)) {
      setThisValue(value);
    }
  }, [value]);
  /**
   * Sync local state to outer state.
   */
  useEffect(() => {
    if (
      !deepCompare(thisValue, currentState.current.value) &&
      currentState.current.isValueFilled(thisValue)
    ) {
      currentState.current.onChange(thisValue);
    }
  }, [thisValue]);

  return (
    <div
      className={`d-flex flex-column flex-md-row align-items-center flex-wrap mb-0`}
    >
      {comboOptions.map((comboOption) => {
        return (
          <div key={comboOption.valueKey ?? comboOption.text}>
            {comboOption.valueType === "showText" && (
              <div className={`px-1 text-center mb-1 ${styles.afterText}`}>
                {comboOption.text}
              </div>
            )}
            {comboOption.valueKey ? (
              <>
                {comboOption.valueType === "numberButton" && (
                  <ComboNumberBtn
                    value={(thisValue[comboOption.valueKey] ?? 0) as number}
                    valueKey={comboOption.valueKey}
                    onChange={handleOnChange}
                  />
                )}
                {comboOption.valueType === "string" && (
                  <ComboString
                    value={(thisValue[comboOption.valueKey] ?? "") as string}
                    valueKey={comboOption.valueKey}
                    onChange={handleOnChange}
                    showErrors={showErrors}
                    placeholder={
                      comboOption.placeholder
                        ? comboOption.placeholder
                        : undefined
                    }
                  />
                )}
                {comboOption.valueType === "number" && (
                  <ComboNumber
                    value={
                      (thisValue[comboOption.valueKey] ?? null) as null | number
                    }
                    valueKey={comboOption.valueKey}
                    onChange={handleOnChange}
                    showErrors={showErrors}
                    placeholder={
                      comboOption.placeholder
                        ? comboOption.placeholder
                        : undefined
                    }
                  />
                )}
                {comboOption.valueType === "singleSelect" && (
                  <ComboSingleSelect
                    value={(thisValue[comboOption.valueKey] ?? "") as string}
                    valueKey={comboOption.valueKey}
                    onChange={handleOnChange}
                    options={comboOption.options ?? []}
                    loadOptions={comboOption.loadOptions ? true : false}
                    placeholder={
                      comboOption.placeholder
                        ? comboOption.placeholder
                        : undefined
                    }
                    {...params}
                  />
                )}
                {comboOption.valueType === "multiSelect" && (
                  <ComboMultiSelect
                    value={
                      (thisValue[comboOption.valueKey] ?? []) as Array<string>
                    }
                    valueKey={comboOption.valueKey}
                    onChange={handleOnChange}
                    options={comboOption.options ?? []}
                    loadOptions={comboOption.loadOptions ? true : false}
                    placeholder={
                      comboOption.placeholder
                        ? comboOption.placeholder
                        : undefined
                    }
                    {...params}
                  />
                )}
              </>
            ) : (
              ""
            )}
          </div>
        );
      })}
    </div>
  );
}

export default ComboOptions;
