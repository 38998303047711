import styles from "./SetConditionWidget.module.scss";
import hand from "src/assets/images/hand.png";
import hifi from "src/assets/images/hifi.gif";
import celebrate from "src/assets/images/celebrate.gif";
import done from "src/assets/images/thumsUp.gif";
import ring from "src/assets/images/ring.gif";
import moreInfo from "src/assets/images/caution.png";
import tata from "src/assets/images/tata.png";
import pinkToggle from "src/assets/images/pinkToggle.png";
import { Button, Overlay, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useRef, useState } from "react";
import { IoSend } from "react-icons/io5";
import { useAppSelector } from "src/store/store";
import { url } from "inspector";
const TargetWidget = () => {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  const targetWidgetProperties = useAppSelector((state) => {
    return state.targetedMessage;
  });

  const ExampleImages = [
    {
      data_Idx: 1,
      Styles: "",
      backGroundColor: "1492e6",
      src: hifi,
      url: "1",
      alternateText: "Waving Hand",
    },
    {
      data_Idx: 2,
      Styles: styles.gifBox2,
      backGroundColor: "ff5b5b",
      src: done,
      url: "2",
      alternateText: "Thumbs Up",
    },
    {
      data_Idx: 3,
      Styles: styles.gifBox3,
      backGroundColor: "710f43",
      src: ring,
      url: "3",
      alternateText: "Ring Bell",
    },
    {
      data_Idx: 4,
      Styles: styles.gifBox4,
      backGroundColor: "ffd285",
      src: celebrate,
      url: "4",
      alternateText: "Celebrate",
    },
  ];

  let url = targetWidgetProperties.messageProperties.elements.image.url;
  let selectedBtnStyleClass: string;
  return (
    <>
      <div
        className={`d-flex justify-content-center align-items-center ${styles.widgetWrapper} flex-column  position-fixed`}
      >
        <div className={`${styles.targetWidget}`}>
          <div className={`${styles.headerBox}`}>
            {url === "1" ? (
              <div
                className={`${styles.innerBox}`}
                style={{
                  backgroundColor:
                    "#" + `${ExampleImages[Number(url) - 1].backGroundColor}`,
                }}
              >
                <img
                  className={` ${styles.gifImg} `}
                  src={ExampleImages[Number(url) - 1].src}
                  alt=""
                />
              </div>
            ) : url === "2" ? (
              <div
                className={`${styles.innerBox}`}
                style={{
                  backgroundColor:
                    "#" + `${ExampleImages[Number(url) - 1].backGroundColor}`,
                }}
              >
                <img
                  className={` ${styles.gifImg} `}
                  src={ExampleImages[Number(url) - 1].src}
                  alt=""
                />
              </div>
            ) : url === "3" ? (
              <div
                className={`${styles.innerBox}`}
                style={{
                  backgroundColor:
                    "#" + `${ExampleImages[Number(url) - 1].backGroundColor}`,
                }}
              >
                <img
                  className={` ${styles.gifImg} `}
                  src={ExampleImages[Number(url) - 1].src}
                  alt=""
                />
              </div>
            ) : url === "4" ? (
              <div
                className={`${styles.innerBox}`}
                style={{
                  backgroundColor:
                    "#" + `${ExampleImages[Number(url) - 1].backGroundColor}`,
                }}
              >
                <img
                  className={` ${styles.gifImg} `}
                  src={ExampleImages[Number(url) - 1].src}
                  alt=""
                />
              </div>
            ) : (
              <div
                className={`${styles.innerBox}`}
                style={{
                  backgroundColor: "white",
                }}
              >
                <img className={` ${styles.addedImage} `} src={url} alt="" />
              </div>
            )}
          </div>
          <div className={`${styles.contentBox}`}>
            <div>
              <div className={`me-1 ${styles.hiiSpan}`}>
                {targetWidgetProperties.messageProperties.elements.title}
              </div>
            </div>
            <div className={`${styles.subSpan}`}>
              {targetWidgetProperties.messageProperties.elements.subtitle}
            </div>

            {targetWidgetProperties.messageProperties.buttons.map((btn) => {
              let btnStyles = btn.btnStyles;
              switch (btnStyles) {
                case "primary":
                  selectedBtnStyleClass = styles.primaryButton;
                  break;
                case "danger":
                  selectedBtnStyleClass = styles.dangerButton;
                  break;
                default:
                  selectedBtnStyleClass = "";
                  break;
              }
              return (
                <button
                  className={`${styles.checkBtn} ${selectedBtnStyleClass}`}
                >
                  <div>{btn.text}</div>
                </button>
              );
            })}
          </div>
        </div>

        <div className={`border mt-3 rounded-pill ${styles.commentBox} shadow`}>
          <input
            className={` ${styles.lead} ${styles.inputbox} rounded-pill `}
            placeholder="Type here..."
          ></input>
          <IoSend className="me-2" role="button" />
        </div>
        <div className={`d-flex  mt-4 ${styles.chatIcon}  `} role="button">
          <img className="" src={pinkToggle} alt="" />
        </div>
      </div>
    </>
  );
};
export default TargetWidget;
