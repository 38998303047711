import { useEffect } from "react";
import styles from "./DraftSent.module.scss";
import doneImg from "src/assets/images/done2.gif";

interface Props {
  onHide: () => void;
}

function DraftSent({ onHide }: Props) {
  useEffect(() => {
    let t = setTimeout(() => {
      onHide();
    }, 5000);
    return () => {
      clearTimeout(t);
    };
  }, [false]);

  return (
    <>
      <div className={`d-flex justify-content-between`}>
        <div></div>
        <div>
          <span className={`cursor-pointer`} onClick={onHide}>
            <i className="fa-solid fa-xmark"></i>
          </span>
        </div>
      </div>
      <div className={`text-center ${styles.sent}`}>
        <img src={doneImg} className={`${styles.doneDraft}`} />
        <p className={`${styles.draftSent}`}>Draft Sent!</p>
      </div>
    </>
  );
}

export default DraftSent;
