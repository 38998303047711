/**
 * ValueDropDown Component
 *
 * This component provides a customizable dropdown based on the specified type, supporting various input types such as tags, countries, browser languages, and generic text inputs.
 *
 * @author @yuvaraj-busibud
 */
import React, { useMemo, useRef, useState } from "react";
import styles from "./ValueDropDown.module.scss";
import { Dropdown } from "react-bootstrap";
import { ActionMeta, SingleValue } from "react-select";
import Select from "react-select";
import TextInput from "./Children/TextInput/TextInput";
import TagDropDown from "./Children/TagDropDown/TagDropDown";
import MultiSelectTextInput from "./Children/MultiSelectTextInput/MultiSelectTextInput";
import CountryDropdown from "./Children/CountryDropdown/CountryDropdown";
import LanguageDropdown from "./Children/LanguageDropdown/LanguageDropdown";

interface Props {
  value: string | null | Array<string | number>;
  handleChange: (value: string | null | Array<string | number>) => void;
  type: string;
  error?: string | null;
  selectedTags?: Array<any>;
}

const placeholders: { [key: string]: string } = {
  url: "Type URL...",
};
const ValueDropDown = ({
  value,
  type,
  handleChange,
  error,
  selectedTags,
  ...props
}: Props) => {
  // Switch case to render different dropdown components based on the 'type'
  switch (type) {
    case "tag":
      return (
        <TagDropDown
          value={value}
          handleChange={handleChange}
          error={error}
          additionalTagOptions={selectedTags}
        />
      );
    case "country":
      return (
        <CountryDropdown
          value={value}
          handleChange={handleChange}
          error={error}
        />
      );
    case "browserLanguage":
      return (
        <LanguageDropdown
          value={value}
          handleChange={handleChange}
          error={error}
        />
      );
    default:
      return (
        <MultiSelectTextInput
          placeholder={
            placeholders[type] !== undefined
              ? placeholders[type]
              : "Type here ..."
          }
          value={value}
          handleChange={handleChange}
          error={error}
        />
      );
  }
};

export default ValueDropDown;
