import { RoutingRulesSetting } from "./RoutingRulesSetting.slice";

export const fetchAllRoutingRulesFulfilled = (
  state: RoutingRulesSetting,
  { payload }: any
) => {
  const {
    routingRulesData,
    routingRuleIdList,
    generalTeam,
    count,
    totalCount
  }: any = payload as any;

  state.routingRuleData = {
    ...state.routingRuleData,
    ...routingRulesData
  };
  console.log("fetchAllRoutingRulesFulfilled ::: routingRuleIdList:: ", routingRuleIdList);
  console.log("fetchAllRoutingRulesFulfilled ::: generalTeam:: ", generalTeam);
  state.routingRuleIdList = [
    ...state.routingRuleIdList,
    ...routingRuleIdList
  ]
  state.metaData.count = count;
  state.metaData.total = totalCount
  state.generalTeam.teamId = generalTeam?.teamId;
  state.generalTeam.teamName = generalTeam?.teamName;
  state.fetchRoutingRulesAjaxStatus = "fulfilled";

}

export default { fetchAllRoutingRulesFulfilled };