import { axiosJSON } from "src/globals/axiosEndPoints";
import APIResponse from "./APIReponse";

interface UpdateTag {
  response: boolean;
}

export interface UpdateTagParams {
  name: string;
  description: string;
  id: number;
}

export async function updateTag(params: UpdateTagParams) {
  const { data: res } = await axiosJSON.post<APIResponse<UpdateTag>>(
    "/api/tags/updateTag",
    params
  );

  if (res.error === true) {
    throw new Error(res.message as string);
  }

  return res.data as UpdateTag;
}
