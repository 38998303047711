import AllTicketsHeader from "./AllTicketsHeader";
import AllDraftHeader from "./AllDraftHeader";
import ShowDraftStatus from "./ShowDraftStatus";
import TicketChannel from "./TicketChannel";
import TicketCustomer from "./TicketCustomer";
import TicketBrand from "./TicketBrand";
import TicketTags from "./TicketTags";
import TicketTitleExcerpt from "./TicketTitleExcerpt";
import TicketNotRepliedSince from "./TicketNotRepliedSince";
import SimpleHeader from "./SimpleHeader";
import JustShowString from "./JustShowString";
import ResponseTitleExcerpt from "./ResponseTitleExcerpt";
import LastMessageReceived from "./LastMessageReceived";
import PreDraftDetails from "./PreDraftDetails";
import EscalatedDraftDetails from "./EscalatedDraftDetails";
import ResponseRemark from "./ResponseRemark";
import AssignedTo from "./AssignedTo";
import ResponseTicketStatus from "./ResponseTicketStatus";
import ResponseTicketID from "./TicketID";
import AllCustomerHeader from "./AllCustomerHeader/AllCustomerHeader";
import AllCustomerRow from "./AllCustomerRow";
import OpenTickets from "./OpenTickets/OpenTickets";

export type TableCellProps = {
  ticketId: any;
  colKeys: any;
  colId: any;
};

export default {
  AllTicketsHeader,
  AllDraftHeader,
  ShowDraftStatus,
  TicketChannel,
  TicketCustomer,
  TicketBrand,
  TicketTags,
  TicketTitleExcerpt,
  TicketNotRepliedSince,
  SimpleHeader,
  JustShowString,
  ResponseTitleExcerpt,
  LastMessageReceived,
  PreDraftDetails,
  EscalatedDraftDetails,
  ResponseTicketStatus,
  ResponseRemark,
  ResponseTicketID,
  AssignedTo,
  AllCustomerHeader,
  AllCustomerRow,
  OpenTickets,
};
