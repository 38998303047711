import { useAppSelector } from "src/store/store";
import ReportTicketBody from "../../../ReportTicketBody/ReportTicketBody";

const ChatAvailabilityReports = () => {
  const ui_visibility = useAppSelector(
    (state) => state.globals.currentUserData?.ui_visibility,
  );

  return (
    <div className="mb-2">
      {ui_visibility?.includes(
        "liveChat_chatAvailability_chatAvailabilityReports",
      ) && (
        <ReportTicketBody
          endPoint={"liveChat/chatAvailability/chatAvailabilityReports"}
          title={"Chat Availability"}
          // tooltip={"Unresolved = Pending or Open Tickets"}
          tabProps={{
            showComparison: false,
            showDailyPart: true,
            showListView: true,
          }}
        />
      )}
    </div>
  );
};

export default ChatAvailabilityReports;
