import styles from "./User.module.scss";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import UserModal from "./UserModal/UserModal";
import SearchBar from "src/components/SearchBar/SearchBar";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  fetchAllUser,
  resetUserData,
  setSearchValue,
} from "src/store/slices/settings/user/userSettings.slice";
import InfiniteScroll from "src/components/InfiniteScroll";
import UserItem from "./UserItem";
import { getLocalLoginCustomerInfo } from "src/services/LocalStorage/loginData";
import { resetActiveTeamData } from "src/store/slices/teamSettings/teamSettings.slice";
import UserHeader from "./UserHeader/UserHeader";

function User() {
  const [showModal, setShowModal] = useState(false);
  const initialized = useRef(false);
  const dispatch = useAppDispatch();
  const loggedInUserInfo = getLocalLoginCustomerInfo();
  const [editUserId, setEditUserId] = useState(null as null | number | string);
  const { filters, userDataAjaxStatus, userIds, metaData, users, userRoles } =
    useAppSelector((state) => state.userSettings);
  const scopes = useAppSelector(
    (state) => state.globals.currentUserData?.scopes,
  );

  const disabledFeatures = useAppSelector(
    (state) => state.globals.currentUserData?.disabledFeatures,
  );

  const currentState = useRef({
    filters,
    userDataAjaxStatus,
    userIds,
    metaData,
    users,
  });

  useEffect(() => {
    currentState.current = {
      filters,
      userDataAjaxStatus,
      userIds,
      metaData,
      users,
    };
  }, [filters, userDataAjaxStatus, userIds, metaData, users]);
  const [searchText, setSearchText] = useState(filters.searchValue);

  const onShow = useCallback(() => {
    setShowModal(true);
  }, []);
  const onHide = useCallback(() => {
    setShowModal(false);
    setEditUserId(null);
  }, []);

  const handelEdit = useCallback((userId: any) => {
    setEditUserId(userId);
    onShow();
  }, []);

  const handelSearch = useCallback((value: any, callback: any) => {
    if (initialized.current) {
      dispatch(setSearchValue({ searchValue: value }));
      callback();
      dispatch(resetUserData());
      dispatch(fetchAllUser());
    }
  }, []);
  const handleInfiniteScroll = useCallback(() => {
    if (currentState.current.userDataAjaxStatus === "fulfilled") {
      dispatch(fetchAllUser());
    }
  }, []);

  useEffect(() => {
    /* UserModal component is also used in TeamPage to add a user
       From there team is auto set in modal, so reset the active team */
    dispatch(resetActiveTeamData());
    // reset the users search value, that is set from add member to team modal
    dispatch(setSearchValue({ searchValue: "" }));
    dispatch(resetUserData());
    dispatch(fetchAllUser());
    initialized.current = true;
  }, [false]);

  const renderTooltip = (props: any) => (
    <Tooltip
      id="button-tooltip"
      {...props}
    >
      {disabledFeatures?.includes("add_users")
        ? "Upgrade to create a new user"
        : "Create a new user for your organization to manage all Tickets."}
    </Tooltip>
  );

  const hasMore = useMemo(() => {
    if (userDataAjaxStatus === "pending") {
      return true;
    }

    if (userIds.length < metaData.total) {
      return true;
    }

    return false;
  }, [userDataAjaxStatus, userIds.length, metaData.total]);

  return (
    <div className={`main__content`}>
      <div className="  ">
        <div className="top-btn d-flex justify-content-between flex-wrap ">
          <div className=" d-flex flex-column ">
            <h3 className={`${styles.page_heading} mb-0`}>Users</h3>
            <p className={`m-0 pt-2 ${styles.heading_tagline} `}>
              Can add users , assign role , edit status , &amp; remove users
            </p>
          </div>
          <div className={`${styles.add_user}  pe-5 `}>
            {scopes && scopes.includes("add_user") && (
              <>
                <OverlayTrigger
                  placement="bottom"
                  trigger="hover"
                  overlay={renderTooltip}
                >
                  <button
                    className={`pink-btn-submit mt-2 mt-md-0 ${styles.btn} ${styles.pink_btn_submit} `}
                    onClick={onShow}
                    disabled={disabledFeatures?.includes("add_users")}
                  >
                    Add User
                  </button>
                </OverlayTrigger>
                <Modal
                  className={``}
                  show={showModal}
                  onHide={onHide}
                  centered={true}
                  dialogClassName={`${styles.userDialog}`}
                  contentClassName={`${styles.addUserModalContent}`}
                  enforceFocus={true}
                >
                  <UserModal
                    OnHide={onHide}
                    editUserId={editUserId}
                  />
                </Modal>
              </>
            )}
          </div>
        </div>
      </div>
      <div className={`${styles.page_content} mt-5 px-0 px-md-3 px-lg-5`}>
        <div className="">
          <div className={`${styles.users}`}>
            <div className="mb-3">
              <SearchBar
                className={`${styles.search} form-control p-2`}
                inputClassName={`${styles.input}`}
                onSearch={handelSearch}
                value={searchText}
                onChange={(e: any) => {
                  setSearchText(e.target.value);
                }}
              />
              {/* <p className={`${styles.showing}`}>
                Showing <span>1-{userIds.length}</span> of{" "}
                <span>{metaData.total}</span>
              </p> */}
            </div>
          </div>
          <div className="">
            <InfiniteScroll
              className={`table-responsive mt-4 ${styles.infScroll}`}
              loadMore={handleInfiniteScroll}
              initialDataLoaded={false}
              hasMore={hasMore}
            >
              <div className={`w-100 ${styles.userItemWrapper}`}>
                <div className={`${styles.mobileRes}`}>
                  <UserHeader />
                  {userIds.map((userId, index) => {
                    const userData = users[userId];
                    return (
                      <UserItem
                        key={userId}
                        userData={userData}
                        userRoles={userRoles}
                        handelEdit={handelEdit}
                        permission={
                          loggedInUserInfo?.userId == userId
                            ? false
                            : scopes && scopes.includes("add_user")
                              ? true
                              : false
                        }
                      />
                    );
                  })}
                </div>
              </div>
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </div>
  );
}

export default User;
