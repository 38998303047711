import { axiosJSON } from "src/globals/axiosEndPoints";

export interface operators {
  name: string;
  value: string;
}

export interface conditionType {
  id: number;
  name: string;
  valueType: string;
  operators: operators[];
}

export interface ConditionOptions {
  data: conditionType[];
}

export interface GetOptionsConditionsParam {
  integrationId: number | string; //integration id is required payload of all the conditions options
}

export async function fetchGetStep9ConditionsOptions(
  params: GetOptionsConditionsParam,
) {
  const { data } = await axiosJSON.get(
    "/api/setting/automation/refund/step/9/conditionOptions",
    { params },
  );

  if (data.err === true) {
    throw new Error("Some Error Occurred while fetching data");
  }

  return {
    conditions: data.data?.conditions as conditionType[],
    returnWindowConditions: data.data
      ?.returnWindowConditions as conditionType[],
    defaultConditions: data?.data.defaultConditions as conditionType[],
  };
}
