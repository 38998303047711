import { axiosJSON } from "src/globals/axiosEndPoints";

export interface WebsiteIntegDetParams {
  integration_id: number | string;
}

interface WhatsappDetails {
  enabled: boolean;
  id: number | string;
  phone_numbers: Array<string>;
}

export interface WebsiteIntegDet {
  integration_id: number;
  store_url: string | null;
  embedded_script: string | null; //if store_url null, then this will be definatily null,
  installed_successfully: boolean;
  live_chat_enabled: boolean; //if installed_successfully false, then this will be false
  ai_bot_enabled?: boolean;
  ai_bot_available?: boolean;
  integration_web_socket_channel_name: null | string;
  website_name?: null | string;
  brand_id?: null | string;
  brand?: null | any;
  whatsapp_details: WhatsappDetails;
  whatsapp_available?: boolean;
  domain_branding_name?: string; //domain name used to show the Powered by Helplama | Saufter text
  live_chat_integration_type?: "shopify" | "otherWebsite"; // Type of live chat integration
  check_installation_success?: boolean; // Flag indicating whether installation check was successful
  displayStoreUrl?: string; // URL of the store to be displayed
}

export const getIntegrationDetails = async (params: WebsiteIntegDetParams) => {
  const { data: res } = await axiosJSON.post(
    `/api/chatSetting/widget/getIntegrationDetails`,
    params,
  );
  if (res.err) {
    throw res.msg;
  }
  return res.data as WebsiteIntegDet;
};

export type AllWebIntegrations = Array<{
  integration_id: number;
  url: string | null;
  live_chat_enabled: boolean;
  installed: boolean;
  live_chat_integration_type: "shopify" | "otherWebsite";
}>;

export const getallWebIntegrations = async () => {
  const { data: res } = await axiosJSON.post(
    `/api/chatSetting/widget/getChatIntegrations`,
  );
  if (res.err) {
    throw res.msg;
  }
  return res.data as AllWebIntegrations;
};
