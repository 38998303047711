import styles from './ResponseTicketID.module.scss';
import { getChannelFAIcon } from "src/utils/utils";
import { useAppSelector } from "src/store/store";
import { selectTicketCellData } from "src/store/slices/tickets/tickets.slice";
import { TableCellProps } from "..";

function ResponseTicketID({ ticketId, colKeys }: TableCellProps) {
  const ticketCellData: any = useAppSelector((state) =>
    selectTicketCellData(state, ticketId, colKeys)
  );

  return (
    <div className={`${styles.ticketIDWrapper}`}>
      <span className={`${styles.ticketId}`}>{ticketCellData[0]}</span>
    </div>
  )
}

export default ResponseTicketID;