import { useAppSelector } from "src/store/store";
import { getTimeFromNow } from "src/utils/dateLibrary";
import styles from "./ShowTime.module.scss";

interface Props {
  className: string;
  dateTime: Date;
  showIcon?: boolean;
  [key: string]: any;
}

function ShowTime({ className, dateTime, showIcon = true, ...props }: Props) {
  const userTimezone = useAppSelector(
    (state) => state.globals.currentUserData?.userTimezone
  );

  return (
    <span className={`${styles.resTitleTime} ${className}`} {...props}>
      {showIcon && (
        <span className="me-1">
          <i className="fas fa-clock"></i>
        </span>
      )}
      {getTimeFromNow({
        date: dateTime,
        timeZone: userTimezone
      })}
    </span>
  );
}

export default ShowTime;
