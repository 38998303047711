import styles from "./MentionBox.module.scss";
import slack from "src/assets/images/slackBig.png";
import { AutoCompleteRenderComponentProps } from "src/interfaces/TinyMCEContainer/ITinyMCEContainer";
import botImg from "src/assets/images/botImg.png";
import MentionOptions from "../MentionOptions/MentionOptions";
import {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { v4 as uuid } from "uuid";
import {
  MentionData,
  UserMentionData,
} from "src/services/TicketService/InternalChatBot/getAllMentions";
import UserAvatar from "src/components/UserAvatar";

interface Props extends AutoCompleteRenderComponentProps {
  data: UserMentionData[];
  setMessageChannel?: React.Dispatch<SetStateAction<string>>;
}
const filterMentions = (data: UserMentionData[], match: string) => {
  match = match.replace("@", "");

  // if (match.length === 0) {
  //   return data;
  // }

  let globalIndex = 0;
  return data
    .map((category) => {
      const filteredMentionData =
        match.length === 0
          ? category.mentionData?.map(
              (mention) =>
                ({
                  ...mention,
                  uniqueId: uuid(),
                  globalIndex: globalIndex++,
                }) as MentionData & { uniqueId: string; globalIndex: number },
            )
          : category.mentionData
              ?.filter((mention) => {
                return mention.dropdownName
                  .toLowerCase()
                  .includes(match.toLowerCase());
              })
              .map(
                (mention) =>
                  ({
                    ...mention,
                    uniqueId: uuid(),
                    globalIndex: globalIndex++,
                  }) as MentionData & { uniqueId: string; globalIndex: number },
              );
      return {
        ...category,
        mentionCategoryDetails: {
          ...category.mentionCategoryDetails,
        },
        mentionData: filteredMentionData,
      };
    })
    .filter(
      (category) => category.mentionData && category.mentionData.length > 0,
    );
};

const MentionBox = ({
  cleanUp,
  data,
  match,
  select,
  popoverContainer,
  editor,
  setMessageChannel,
}: Props) => {
  const [selectedMention, setSelectedMention] = useState<MentionData | null>(
    null,
  );
  // const [selectedMentionElementId, setSelectedMentionElementId] = useState<
  //   string | null
  // >(null);
  const [baseMentionHtml, setBaseMentionHtml] = useState<string>("");
  const [highlightedIndex, setHighlightedIndex] = useState<number>(0);
  const [subMenuIndex, setSubMenuIndex] = useState<number>(-1);
  const mentionRefs = useRef<(HTMLDivElement | null)[]>([]);

  const handleMentionClick = useCallback(
    (mention: MentionData, channelName?: string) => {
      const elementId = uuid();

      const attributesString =
        mention?.attributes
          ?.map((attr) => `${attr.attributeName}="${attr.attributeValue}"`)
          ?.join(" ") ?? "";
      const mentionHtml = `<span id="${elementId}" class="mceNonEditable" style="color: ${
        mention.textColor ?? "#0b68bb"
      }; background-color: ${
        mention.bgColor ?? "#0b68bb"
      }; border-radius: 7px; font-size: 12px; padding: 0px 4px; outline: none; cursor: default; display: inline-block;" ${attributesString}>${
        mention.mentionValue
      }</span>`;

      if (setMessageChannel) {
        setMessageChannel(channelName === "slack" ? "slack" : "email");
      }

      if (mention.popoverKey) {
        setBaseMentionHtml(mentionHtml);
        setSelectedMention(mention);
        setHighlightedIndex(0);
        // setSelectedMentionElementId(elementId);
        // select({ value: mentionHtml }); // Insert the mention before showing the popover
      } else {
        // setSelectedMentionElementId(elementId);
        select({ value: mentionHtml });
        cleanUp(false);
      }
    },
    [],
  );

  // const handleBack = () => {
  //   setSelectedMention(null);
  // };

  const currentState = useRef({
    filteredData: data,
    highlightedIndex: highlightedIndex,
    subMenuIndex,
    selectedMention,
    baseMentionHtml,
  });

  const filteredData = useMemo(() => {
    const result = filterMentions(data, match);
    if (popoverContainer) {
      popoverContainer.style.display = result?.length ? "inline-block" : "none";
    }
    currentState.current.filteredData = result;
    return result;
  }, [popoverContainer, data, match]);

  useEffect(() => {
    setHighlightedIndex(0);
  }, [filteredData, match]);

  useEffect(() => {
    const { current } = currentState;
    current.highlightedIndex = highlightedIndex;
    current.subMenuIndex = subMenuIndex;
    current.selectedMention = selectedMention;
    current.baseMentionHtml = baseMentionHtml;
  }, [highlightedIndex, subMenuIndex, selectedMention, baseMentionHtml]);

  const handleOptionClick = useCallback((option: MentionData) => {
    const attributesString = option.attributes
      ?.map((attr) => `${attr.attributeName}="${attr.attributeValue}"`)
      .join(" ");
    const optionHtml = `<span> ${option.mentionValue}</span>`;
    const updatedMentionHtml = currentState.current.baseMentionHtml.replace(
      "<span",
      `<span ${attributesString}`,
    );
    select({ value: `${updatedMentionHtml}${optionHtml}` });
    cleanUp(false);
  }, []);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const { current } = currentState;
      const mentions: Array<MentionData & { key: string }> = [];
      for (let i = 0; i < current.filteredData.length; i++) {
        const data = current.filteredData[i];
        if (data.mentionData) {
          for (let j = 0; j < data.mentionData.length; j++) {
            const mention = data.mentionData[j];
            mentions.push({ ...mention, key: data.mentionCategoryDetails.key });
          }
        }
      }

      const handleArrowNavigation = (direction: "up" | "down") => {
        const increment = direction === "down" ? 1 : -1;
        const resetIndex = direction === "down" ? 0 : mentions.length - 1;

        if (current.selectedMention) {
          if (current.subMenuIndex === -1) {
            setHighlightedIndex((prevIndex) => {
              const newState = prevIndex + increment;
              return newState >= 0 &&
                newState < (current.selectedMention?.popoverData?.length ?? 0)
                ? newState
                : resetIndex;
            });
          } else {
            setSubMenuIndex((prevIndex) => {
              const newState = prevIndex + increment;
              return newState >= 0 &&
                newState <
                  ((current.selectedMention?.popoverData ?? [])[
                    current.highlightedIndex
                  ]?.menuItems.length ?? 0)
                ? newState
                : resetIndex;
            });
          }
        } else {
          setHighlightedIndex((prevIndex) => {
            const newState = prevIndex + increment;
            mentionRefs.current[newState]?.scrollIntoView({
              behavior: "instant" as any,
              block: "nearest",
            });
            return newState >= 0 && newState < mentions.length
              ? newState
              : resetIndex;
          });
        }
      };

      switch (event.key) {
        case "ArrowDown":
          event.preventDefault();
          event.stopPropagation();
          handleArrowNavigation("down");
          break;
        case "ArrowUp":
          event.preventDefault();
          event.stopPropagation();
          handleArrowNavigation("up");
          break;
        case "Enter":
          event.preventDefault();
          event.stopPropagation();
          if (current.highlightedIndex >= 0) {
            if (current.selectedMention) {
              if (current.subMenuIndex === -1) {
                setSubMenuIndex(0);
              } else {
                const selectedMenuItem =
                  current.selectedMention?.popoverData?.[
                    current.highlightedIndex
                  ]?.menuItems[current.subMenuIndex];
                if (selectedMenuItem) {
                  handleOptionClick(selectedMenuItem);
                }
              }
            } else {
              const mention = mentions[current.highlightedIndex];
              if (mention) {
                handleMentionClick(mention, mention.key);
              }
            }
          }
          break;
        case "Escape":
          event.preventDefault();
          event.stopPropagation();
          if (current.selectedMention && current.subMenuIndex !== -1) {
            setSubMenuIndex(-1);
          } else {
            cleanUp(true);
          }
          break;
        default:
          if (current.selectedMention) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
          }
          break;
      }
    },
    [handleOptionClick, handleMentionClick, cleanUp],
  );

  useEffect(() => {
    editor.on("keydown", handleKeyDown);
    return () => {
      editor.off("keydown", handleKeyDown);
    };
  }, [editor, handleKeyDown]);

  if (selectedMention && selectedMention.popoverData) {
    return (
      <MentionOptions
        key={selectedMention.id}
        data={selectedMention}
        cleanUp={cleanUp}
        match={match}
        popoverContainer={popoverContainer}
        select={({ value }) => {
          select({
            value,
          });
          cleanUp(false);
        }}
        baseMentionHtml={baseMentionHtml}
        editor={editor}
        highlightedIndex={highlightedIndex}
        subMenuIndex={subMenuIndex}
        setSubMenuIndex={setSubMenuIndex}
        setHighlightedIndex={setHighlightedIndex}
        handleOptionClick={handleOptionClick}
      />
    );
  }

  return (
    <div className={`${styles.mentionBox}`}>
      {filteredData.map((category, index) => (
        <div key={category.mentionCategoryDetails.key}>
          <div className={`d-flex align-items-center ${styles.categoryHeader}`}>
            {category.mentionCategoryDetails.key === "slack" ? (
              <img
                src={slack}
                alt="slack"
                width={26}
                height={26}
              />
            ) : category.mentionCategoryDetails.key === "saufterNote" ||
              category.mentionCategoryDetails.key === "cernAi" ? (
              <span className={styles.botImgWrapper}>
                <img
                  src={botImg}
                  alt="slack"
                  width={26}
                  height={26}
                />
              </span>
            ) : null}
            <span className={`ps-1 ${styles.categoryText}`}>
              {category.mentionCategoryDetails.name}
            </span>
          </div>
          {category.mentionData?.map((mention) => (
            <div
              key={mention.uniqueId}
              ref={(el) => (mentionRefs.current[mention.globalIndex] = el)}
              className={`px-4 mb-2 cursor-pointer ${styles.mentionItem} ${
                mention.globalIndex === highlightedIndex
                  ? styles.highlighted
                  : ""
              }`}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleMentionClick(
                  mention,
                  category.mentionCategoryDetails.key,
                );
              }}
              onMouseOver={(e) => setHighlightedIndex(mention.globalIndex)}
            >
              <div className="px-4 d-flex align-items-center mb-2">
                {mention.icon ? (
                  <span className={`${styles.mentionText}`}>
                    <i className={mention.icon}></i>
                  </span>
                ) : mention.imageUrl ? (
                  <img
                    src={mention.imageUrl}
                    alt="user img"
                    className={`${styles.userImg}`}
                  />
                ) : (
                  <UserAvatar
                    size={25}
                    name={mention.dropdownName}
                    alt="user img"
                    className={`${styles.userImg}`}
                  />
                )}
                <span className={`px-2 ${styles.userName}`}>
                  {mention.dropdownName}
                </span>
                {mention?.status === "active" && (
                  <div className={`${styles.greenDot}`}></div>
                )}
              </div>
            </div>
          ))}
        </div>
      ))}
      <div className={`px-4 ${styles.footer}`}>
        <div className="d-flex me-2">
          <span className={`${styles.arrowRotate}`}>
            <i className="fa-solid fa-arrow-right-arrow-left"></i>
          </span>
          <span className="ps-2">to navigate</span>
        </div>
        <div className="d-flex me-2">
          <span>
            <i className="fa-solid fa-reply-all"></i>
          </span>
          <span className="ps-2">to select</span>
        </div>
        <div className="d-flex">
          <span>esc to dismiss</span>
        </div>
      </div>
    </div>
  );
};

export default MentionBox;
