import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import {
  ConditionTypes,
  createNewObjectHash,
  getConditionValueOrDefault,
} from "src/store/slices/initiateReturn/Helpers/returnExchange.helpers";
import { v4 as uuid } from "uuid";
import {
  fetchPostStep15Config,
  fetchStep15GetConfig,
} from "../Thunks/returnExchangeModal.thunk";
import { step15Configuration } from "../step15Configuration.slice";

const step15Builders = (
  builder: ActionReducerMapBuilder<step15Configuration>,
) => {
  builder.addCase(
    fetchPostStep15Config.pending,
    (state: step15Configuration) => {
      state.updateAjaxStatus = "pending";
    },
  );
  builder.addCase(
    fetchPostStep15Config.fulfilled,
    (state: step15Configuration, action) => {
      state.updateAjaxStatus = "fulfilled";
      updateConfig(state, action.payload);
    },
  );
  builder.addCase(
    fetchPostStep15Config.rejected,
    (state: step15Configuration) => {
      state.updateAjaxStatus = "rejected";
      pushTheToast({
        type: "danger",
        text: "failed to update!",
        position: "top-right",
      });
    },
  );
  builder.addCase(
    fetchStep15GetConfig.pending,
    (state: step15Configuration) => {
      state.fetchAjaxStatus = "pending";
    },
  );
  builder.addCase(
    fetchStep15GetConfig.rejected,
    (state: step15Configuration) => {
      state.fetchAjaxStatus = "rejected";
      pushTheToast({
        type: "danger",
        text: "failed to fetch configuration!",
        position: "top-right",
      });
    },
  );

  builder.addCase(
    fetchStep15GetConfig.fulfilled,
    (state: step15Configuration, action) => {
      state.fetchAjaxStatus = "fulfilled";
      updateConfig(state, action.payload);
    },
  );
};

const updateConfig = (state: step15Configuration, payload: any) => {
  const {
    step15ConditionOptionsRes,
    step15ConfigRes,
    isSelectReasonEnabled,
    restockFee,
    returnReasonList,
  } = payload;

  if (step15ConditionOptionsRes) {
    state.conditionOptions = step15ConditionOptionsRes.conditions;
    state.itemOptions = step15ConditionOptionsRes.itemConditions;
    state.defaultOptions = step15ConditionOptionsRes.defaultConditions;
    state.selectReasonOptions =
      step15ConditionOptionsRes.returnReasonConditions;

    [
      ...state.conditionOptions,
      ...state.itemOptions,
      ...state.defaultOptions,
      ...state.selectReasonOptions,
    ].forEach((option) => {
      if (!state.variableNames.some((item: any) => item.id === option.id)) {
        state.variableNames.push({ name: option.name, id: option.id });
      }

      state.operatorsNames[option.name] = option.operators.map((operator) => {
        state.operatorSymbolMap[operator.value] = operator.name;
        return operator.name;
      });
      state.valueTypes[option.valueType] = null;
      state.variableNameToValueType[option.name] = option.valueType;
    });
  }

  if (step15ConfigRes) {
    const step15Config = step15ConfigRes;
    // Adding default return reasons for a state variable to check for validations based on moreQuestions
    if (returnReasonList) {
      state.returnReasonList = Object.values(returnReasonList);
    }
    if (step15Config.length > 0) {
      state.selectedAdditionalChargeId = step15Config[0].configTabId.toString();
      state.additionalCharges = step15Config.map((tab: any, idx: any) => {
        return {
          additionalChargeName: tab.configTabName,
          additionalChargeId: tab.configTabId.toString(),
          additionalChargeSequence: 1,
          error: null,
          itemConditionError: null,
          isAddConditionEnabled: true,
          isAddOrderTypeConditionEnabled: true,
          selectReturnReasonError: null,
          isAddSelectReasonConditionEnabled: isSelectReasonEnabled,
          isSelectReasonEnabled: isSelectReasonEnabled,
          returnReasonList: returnReasonList
            ? Object.values(returnReasonList)
            : [], //Assigning api response to redux state
          errorCount: 0,
          isValid: false,
          restockingFees: {
            amount: parseInt(tab?.restockingFee?.price ?? 0),
            currentState: false,
            priceType: tab?.restockingFee?.priceType ?? "fixed",
            error: null,
          },
          customShippingCharges: { amount: 0, currentState: false },
          messageForCustomer: {
            value: tab?.restockingFeeMessage ?? "",
            error: null,
          },
          // itemSequence: tab.itemSequence,
          conditions: tab.conditions.map((condition: any) => {
            const selectedVariable: any = state.variableNames.find(
              (variable: any) => variable.id == condition.conditionTypeId,
            );

            if (selectedVariable) {
              const variableName = selectedVariable.name;
              const operatorSymbol = condition.operator;
              const operator = state.operatorSymbolMap[operatorSymbol];
              const valueType = state.variableNameToValueType[variableName];
              const values = getConditionValueOrDefault(
                valueType,
                condition.conditionValue,
              );

              return {
                id: condition.id.toString(),
                variableName,
                operator,
                values,
              } as ConditionTypes;
            }
            return {} as ConditionTypes;
          }),
          selectReturnReason: tab.selectReturnReasons.map((condition: any) => {
            const selectedVariable: any = state.variableNames.find(
              (variable: any) => variable.id == condition.conditionTypeId,
            );

            if (selectedVariable) {
              const variableName = selectedVariable.name;
              const operatorSymbol = condition.operator;
              const operator = state.operatorSymbolMap[operatorSymbol];
              const values = getConditionValueOrDefault(
                "return_reason",
                condition.conditionValue,
              );

              return {
                id: condition.id.toString(),
                variableName,
                operator,
                values,
              } as ConditionTypes;
            }
            return {} as ConditionTypes;
          }),
        };
      });
    } else {
      if (step15ConditionOptionsRes.defaultConditions) {
        let conditions = step15ConditionOptionsRes.defaultConditions.map(
          (condition: any) => {
            const selectedVariable: any = state.variableNames.find(
              (variable: any) => variable.id == condition.id,
            );
            if (selectedVariable) {
              const variableName = selectedVariable.name;
              const operatorSymbol = condition.operators[0].value;
              const operator = state.operatorSymbolMap[operatorSymbol];
              const valueType = state.variableNameToValueType[variableName];
              const values = getConditionValueOrDefault(valueType, null);

              return {
                id: uuid(),
                variableName,
                operator,
                values,
              } as ConditionTypes;
            }
          },
        );

        state.additionalCharges[0].conditions = conditions;
      }

      if (restockFee) {
        let restockingFee = {
          currentState: false,
          amount: parseInt(restockFee?.price ?? 0),
          priceType: restockFee?.priceType ?? "fixed",
          error: null,
        };
        state.additionalCharges[0].restockingFees = restockingFee;
      }
    }
  }

  const hash = createNewObjectHash({
    excludeKeys: state.excludeKeysForHash,
    hashObject: state.additionalCharges,
  });

  if (hash) {
    state.initialHashOtherTab = hash;
    state.currentHashOtherTab = hash;
  }
};

export default step15Builders;
