import { Channel } from "pusher-js";

// Debounce timeout variable.
let timeBoundTimeout: any = null;

/**
 * Binds a callback function to the "ticket_event_ticket_updated" event on the provided Pusher channel.
 *
 * @param channel The Pusher channel to bind the event to.
 * @param callback A function to be called when the event is triggered, taking the updated ticket data as its parameter.
 */
export const getTicketUpdatedEvent = (
  channel: Channel,
  callback: (res: any) => void
) => {
  // Binding the event listener to the channel
  channel.bind(
    "ticket_event_ticket_updated",
    ((res) => {
      clearInterval(timeBoundTimeout);
      timeBoundTimeout = setTimeout(() => {
        callback(res);
      }, 10000);
    }) as typeof callback,
    {
      pauseOnInActiveTab: true,
    }
  );
};

/**
 * Unbinds the "ticket_event_ticket_updated" event from the provided Pusher channel.
 *
 * @param channel The Pusher channel to unbind the event from.
 */
export const getTicketUpdatedEventUnbind = (channel: Channel) => {
  // Unbind the event listener from the channel
  channel.unbind("ticket_event_ticket_updated");
};
