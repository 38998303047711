/**
 * This file is the service file used for making api request.
 * It contains the deleteKnowledgeBase function service which is explained below.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { axiosJSON } from "src/globals/axiosEndPoints";

export interface DeleteKnowledgeBasePayload {
  knowledgeBaseId: string | number;
}

/**
 * This service is used to delete the knowledge base using the KB id.
 */
export const deleteKnowledgeBase = async (
  params: DeleteKnowledgeBasePayload
) => {
  const { data: res } = await axiosJSON.delete("/api/knowledgeBase/delete", {
    params,
  });
  return res.data;
};
