import { axiosJSON } from "src/globals/axiosEndPoints";


export interface ArticleData {
  articleId : number|string,
  articleName : string,
  articleStatus : "public"|"draft",
  categoryId : number|string,
  categoryName : string,
  articleDescription : string,
  searchKeyword : string,
  createdAt : Date,
  createdAtGmt : Date,
  attachments: Array<any>
}


export interface GetArticleParams {
  articleId: number|string,
  integrationId: number|string,
}

export async function getArticle(params: GetArticleParams) {
  const { data: res }: any = await axiosJSON.post(
    "/api/chatSetting/article/get",
    params
  );

  if (res.error === true) {
    throw new Error(res.message as string);
  }

//   console.log("getAllRoutingRules service ret:: ", JSON.stringify(ret));
  return res.data as ArticleData;
}
