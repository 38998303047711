import { useEffect, useMemo, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import Select from "react-select";
import { useAppSelector } from "src/store/store";
import styles from "./SelectReturnReason.module.scss";

function SelectReturnReason(props: any) {
  const [selectedValue, setSelectedValue] = useState(null as any);
  const [menuOpen, setMenuOpen] = useState(false);

  const { returnReasonListIds, returnReasonList, returnReasonAjaxStatus } =
    useAppSelector((state) => state.returnExchange);

  const reasons = useMemo(() => {
    return returnReasonListIds.map((reasonId) => {
      return {
        value: reasonId,
        label: returnReasonList[reasonId].reasonName,
      };
    });
  }, [returnReasonListIds, returnReasonList]);

  useEffect(() => {
    if (reasons.length && props.return_reasons) {
      const returnReason = reasons.find(
        (option) => option.value === props.return_reasons,
      );
      if (returnReason) {
        setSelectedValue(returnReason);
      } else {
        setSelectedValue(null);
      }
    } else {
      setSelectedValue(null);
    }
  }, [reasons, props.return_reasons]);

  const [showPopover, setShowPopover] = useState(true);
  useEffect(() => {
    if (props.error) {
      setShowPopover(true);
    } else {
      setShowPopover(false);
    }
  }, [props.error]);

  const popover =
    props.error && props.error.length > 0 ? (
      <Popover
        id="popover-basic"
        className="rounded-3"
      >
        <Popover.Body className="d-flex flex-row">
          <div className="border px-2 bg-warning text-white me-1 rounded-3 ">
            !
          </div>
          <div className="mt-1">{props.error}</div>
        </Popover.Body>
      </Popover>
    ) : (
      <></>
    );
  return (
    <>
      <OverlayTrigger
        show={!selectedValue && showPopover}
        placement="bottom"
        overlay={popover}
        rootClose={true}
        onToggle={(show) => {
          if (!menuOpen) {
            setShowPopover(show);
          }
        }}
        // onExit={(e: any) => {
        //   setShowPopover(false);
        // }}
        rootCloseEvent="mousedown"
      >
        <div
          className={`ms-lg-3 ${styles.thirdSelect} ${
            props.error && !selectedValue ? styles.errorBorder : ""
          }`}
          style={{
            maxWidth: "20rem",
            minWidth: "16rem",
          }}
          onClick={() => setShowPopover(false)}
        >
          <Select
            styles={customStyles}
            value={selectedValue}
            isMulti={false}
            name="return_reasons"
            options={reasons}
            className="basic-multi-select"
            classNamePrefix="select"
            isClearable={false}
            onMenuClose={() => {
              setMenuOpen(false);
            }}
            onMenuOpen={() => {
              setMenuOpen(true);
            }}
            onChange={(e) =>
              props.onChange(e?.value, returnReasonList[e?.value].reasonName)
            }
            placeholder="search return reason"
            isLoading={returnReasonAjaxStatus === "pending"}
            components={{
              DropdownIndicator: null, // Custom component for rendering the dropdown indicator (null to remove it)
              IndicatorSeparator: null, // Custom component for rendering the indicator separator (null to remove it)
            }}
          />
        </div>
      </OverlayTrigger>
    </>
  );
}
//Dec 12 changed customStyles as per xd
const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    borderColor: "#9e9e9e",
    width: "100%",
    borderRadius: "5px",
    minHeight: "37px",
    border: "1px solid #707070",
    opacity: "1",
    // minWidth: "127px",
  }),

  valueContainer: (provided: any, _: any) => ({
    ...provided,
    minHeight: "24px",
    padding: "0 6px",
    font: "normal normal normal 14px/21px Poppins",
    letterSpacing: "0px",
    color: "#000000",
    opacity: "1",
  }),

  input: (provided: any, _: any) => ({
    ...provided,
    margin: "0px",
  }),

  indicatorSeparator: (provided: any, _: any) => ({
    ...provided,
    display: "none",
  }),
  indicatorsContainer: (provided: any, _: any) => ({
    ...provided,
    minHeight: "24px",
    padding: "0px !important",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#f5f5f5" : "#fff", // Change the hover color here
    "&:hover": {
      backgroundColor: "#f5f5f5", // Change the hover color here as well
      borderRadius: "4px",
    },
    padding: "10px",
    color: "black",
  }),
  menuList: (provided: any) => ({
    ...provided,
    margin: "0 5px", // Add 5px margin on the left and right sides of the menu
  }),
  menu: (provided: any) => ({
    ...provided,
    width: "100%",
    boxShadow: "0px 0px 16px #00000029",
    borderRadius: "5px",
  }),
};

export default SelectReturnReason;
