import { useParams } from "react-router-dom";
import { useAppSelector } from "src/store/store";

import BlurredUI from "../TicketViewTableRow/BlurredUI/BlurredUI";
import TicketLimiter from "../TicketLimiter/TicketLimiter";

const blurredUIKeys = Array.from({ length: 6 }, (_, index) => index + 1);

/**
 * Component for showing extra limiter page
 */
const LimiterPage = () => {
  const { viewType } = useParams();
  const currColumnList = useAppSelector((state) => state.ticket.currColumnList);
  return (
    <>
      {viewType && (
        <div className="position-relative">
          {blurredUIKeys.map((key) => (
            <BlurredUI key={key} columns={currColumnList[viewType]} />
          ))}
          <TicketLimiter isMoreData={true} />
        </div>
      )}
    </>
  );
};

export default LimiterPage;
