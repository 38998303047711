import { axiosJSON } from "src/globals/axiosEndPoints";
import { OutgoingRecieveType } from "./getEmailIntegrationById";

export interface UpdateEmailIntegration {
  data: [];
}

export interface UpdateEmailIntegrationParams {
  emailIntegrationId: number;
  addressName?: string;
  outgoingMailSettingType?: OutgoingRecieveType;
}

export async function updateEmailIntegration(
  params: UpdateEmailIntegrationParams,
) {
  const { data } = await axiosJSON.post(
    `/api/setting/integration/updateEmailIntegration`,
    params,
  );

  // if (res.errors) {
  //   throw new Error(res.errors.join(""));
  // }
  // const data = await updateEmail(params);
  // if (res.error === true) {
  //   throw new Error(res.message as string);
  // }

  if (data.err === true || data.error === true) {
    throw new Error(data.msg as string);
  }

  // return res.data as UpdateEmailForwarding;
  return data as UpdateEmailIntegration;
}

// let updateEmail = function (params: UpdateEmailIntegrationParams) {
//   return new Promise(function (resolve, reject) {
//     if (params) {
//       setTimeout(function () {
//         resolve({
//           defaultEmail: "Something Which You Have Saved on Add Email ",
//           addedEmail: params.addressName,
//         });
//       }, 2000);
//     } else {
//       reject(new Error("Something is not right!"));
//     }
//   });
// };
