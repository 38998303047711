/**
 * This file is the service file.
 *
 * @author Jayalakshmi
 * @author Yash Aditya
 */

import { axiosJSON } from "src/globals/axiosEndPoints";

// Define the payload structure for updating users to be notified
export interface UpdateUsersPayload {
  userIdsToNotify: Array<string>; // Array of user IDs to be notified
}

// Define the structure of the response from the server
interface UpdateUsersResponse {
  err: boolean; // Error indicator
  msg: string; // Message from the server
}

// Async function to add or update users to be notified
export const addOrUpdateUsersToBeNotified = async (
  params: UpdateUsersPayload // Input parameter: Payload for the update request
) => {
  // Send a POST request to the specified API endpoint
  const { data: res } = await axiosJSON.post(
    `/api/reports/notificationSettings/updateUsersToBeNotified`,
    params
  );

  // Check if there's an error in the response
  if (res.err || res.error) {
    // If there is an error, throw an exception with the error message
    throw res.msg;
  }

  // Return the response as an UpdateUsersResponse
  return res as UpdateUsersResponse;
};

export default addOrUpdateUsersToBeNotified;
