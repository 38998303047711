import { useState } from "react";
import styles from "./SelectedUserBtn.module.scss";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  getSelectedUserSelector,
  resetBulkAssignTickets,
} from "src/store/slices/ticketUsers/ticketUsers.slice";
import UserAvatar from "src/components/UserAvatar";
import AxiosImg from "src/components/AxiosImg";

const dummyImageUrl =
  "https://images.unsplash.com/photo-1541855492-581f618f69a0?ixlib=rb-1.2.1&amp;ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8bWFsZXxlbnwwfHwwfHw%3D&amp;w=1000&amp;q=80";

type Props = {
  BtnAction: Function;
};

function SelectedUserBtn({ BtnAction }: Props) {
  const selectedUser = useAppSelector(getSelectedUserSelector);
  const dispatch = useAppDispatch();

  const handleOnClick = (e: any) => {
    e.preventDefault();
    dispatch(resetBulkAssignTickets());
    BtnAction();
  };

  return (
    <div>
      <button
        onClick={handleOnClick}
        type="button"
        className={`btn me-2 ${styles.actionBtnOff}`}
      >
        <span className="me-1">
        { selectedUser?.imageUrl && selectedUser?.imageUrl.trim().length !== 0 ?
            <AxiosImg
            className={`${styles.profileIcon}`}
            url={selectedUser.imageUrl}
            isDirectURL={selectedUser.isPublicAttachmentUrl}
          />
          :
          <UserAvatar name={selectedUser.name || "NA"} size={20} />
        }
        </span>
        <span
          className={`d-inline-block text-truncate align-middle`}
          style={{ maxWidth: "80px" }}
        >
          {selectedUser.name}
        </span>
        {/* Pallavi */}
        <i className={`ms-4 fas fa-caret-down`} />
      </button>
    </div>
  );
}

export default SelectedUserBtn;
