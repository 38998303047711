import { AsyncPaginate, useComponents } from "react-select-async-paginate";
import { components } from "react-select";
import makeAnimated from "react-select/animated";
import useLoadOptions from "src/hooks/useLoadOptions";
import { useCallback, useEffect, useRef, useState } from "react";
import { isObjOrStrEmpty } from "src/utils/utils";
import {
  CLOSED_DATE_OPTIONS_API_ROUTE,
  CREATED_DATE_OPTIONS_API_ROUTE,
} from "src/globals/constants";
import { v4 as uuid } from "uuid";
import styles from "./AsyncDropdown.module.scss";

const CustomMenu = ({ children, ...props }: any) => {
  return (
    <div
      onClick={(e: any) => {
        e.stopPropagation();
      }}
    >
      <components.Menu {...props}>{children}</components.Menu>
    </div>
  );
};

const CustomControl = ({ children, ...props }: any) => {
  return (
    <div className={`${styles.customControl}`}>
      <components.Control {...props}>{children}</components.Control>
    </div>
  );
};

//component of remove icon in select options, added condition check for removable === false, only show the cross icon if removable is true
const MultiValueRemove = (props: any) => {
  //checking if selected option is view filter if yes then disabling it
  if (props.data?.viewFilter === true) {
    return (
      <components.MultiValueRemove
        {...props}
        innerProps={{ "aria-disabled": true}}
        selectProps={{ isDisabled: true }}
        children={<div className="ps-1"><i className="fa fa-ban" aria-hidden="true"></i></div>}
      />
    );
  }

  return <components.MultiValueRemove {...props} />;
};

const animatedComponents = makeAnimated();

export default function AsyncDropdown({
  loadUrl,
  value,
  onChange,
  containerTarget,
  ...props
}: any) {
  const components = useComponents({
    Menu: CustomMenu,
    Control: CustomControl,
  });
  const { getLoadOptions } = useLoadOptions();
  const [clearCache, setClearCache] = useState(true);
  const [options, setOptions]: any = useState([]);

  const handleOnChange = (inputValue: any, action: any) => {
    // console.log({ inputValue, action });

    //preventing deleting of default value
    if(action.action === "pop-value" && action.removedValue?.viewFilter === true){
      return;
    }

    onChange(inputValue, action);
    if (isObjOrStrEmpty(inputValue)) {
      setClearCache((c) => !c);
    }
  };
  useEffect(() => {
    if (options.length) {
      const valueT = (() => {
        if (typeof value === "object" && value !== null) {
          if (value.length === undefined) {
            if (value.notFetched !== true) {
              return value;
            } else {
              let v = options.filter((data: any) =>
                value.compare !== undefined
                  ? value.compare == data.compare
                  : value.id == data.id
              );
              // console.log(options, v, value);
              if (v.length > 0) {
                return v[0];
              } else {
                return value;
              }
            }
          } else {
            if (value.length > 0 ? value[0].notFetched !== true : false) {
              return value;
            } else {
              let v = options.filter((data: any) =>
                value.map((vData: any) => vData.id).includes(data.id)
              );
              if (v.length > 0) {
                return [
                  ...v,
                  ...value.filter(
                    (vD: any) =>
                      !v.map((vData: any) => vData.id).includes(vD.id)
                  ),
                ];
              } else {
                return value;
              }
            }
          }
        } else return value;
      })();
      if (JSON.stringify([valueT]) !== JSON.stringify([value])) {
        handleOnChange(valueT, props);
      }
    }
  }, [options]);

  /**
   * helper function which checks if there is any default filter which is not removable,
   * if any found then it will disable the clear button
   */
  const checkClearable = useCallback((value:any)=>{
    
    if(value && Array.isArray(value)){
      return value.some((v:any) => v.viewFilter) ? false : true;
    }

    if(value && typeof value == "object" && value?.viewFilter === true){
      return false;
    }

    return true;
  },[])

  // useEffect(() => {
  //   if (
  //     loadUrl === CLOSED_DATE_OPTIONS_API_ROUTE ||
  //     loadUrl === CREATED_DATE_OPTIONS_API_ROUTE
  //   ) {
  //     getLoadOptions(loadUrl, value)("", null)
  //       .then((res: any) => {
  //         setOptions(res.options);
  //       })
  //       .catch((err) => {});
  //   }
  // }, [false]);

  return (
    <AsyncPaginate
      cacheUniqs={[clearCache]}
      placeholder={`Select...`}
      delete={false}
      menuPortalTarget={document.body}
      menuPosition={"fixed"}
      closeMenuOnScroll={(event) => {
        return event.target === containerTarget;
    }}
      loadOptions={async (searchTerm, prevOptions) => {
        const optionsGot = await getLoadOptions(loadUrl, value)(
          searchTerm,
          prevOptions
        );
        setOptions(optionsGot.options);
        return optionsGot;
      }}
      components={{ ...animatedComponents, ...components, MultiValueRemove }}
      isClearable={checkClearable(value)}
      value={value}
      onChange={handleOnChange}
      {...props}
      styles={{
        ...(props.styles ? props.styles : {}),
        menuPortal: (provided: any) => ({ ...provided, zIndex: 99999 }),
      }}
    />
  );
}

// const customStyles = {
//   control: (provided: any, state: any) => ({
//     ...provided,
//     background: "#fff",
//     borderColor: "#9e9e9e",
//     minHeight: "24px",
//     width: "100%",
//     border: "1px solid #616D75",
//     borderRadius: "4px",
//   }),

//   valueContainer: (provided: any, _: any) => ({
//     ...provided,
//     minHeight: "24px",
//     padding: "0 6px",
//   }),

//   input: (provided: any, _: any) => ({
//     ...provided,
//     margin: "0px",
//   }),
//   indicatorsContainer: (provided: any, _: any) => ({
//     ...provided,
//     minHeight: "24px",
//     padding: "0px !important",
//   }),
//   dropdownIndicator: (provided: any, _: any) => ({
//     ...provided,
//     padding: "0px !important",
//   }),
//   clearIndicator: (provided: any, _: any) => ({
//     ...provided,
//     padding: "0px !important",
//   }),
//   multiValueLabel: (provided: any, _: any) => ({
//     ...provided,
//     padding: "0",
//     textAlign: "left",
//     font: "normal normal normal 12px/18px Poppins",
//     letterSpacing: "0px",
//     color: "#707070",
//     opacity: "1",
//   }),
//   multiValue: (provided: any, _: any) => ({
//     ...provided,
//     paddingRight: "2px",
//   }),
// };
