import styles from "./TicketView.module.scss";
import LimiterPage from "./children/LimiterPage/LimiterPage";
import TicketViewTableHeader from "./children/TicketViewTableHeader/TicketViewTableHeader";
import TicketViewTableRow from "./children/TicketViewTableRow/TicketViewTableRow";

function TicketView({
  showBlurredSection,
  showLimiterPage,
}: {
  showBlurredSection: boolean;
  showLimiterPage: boolean;
}) {
  return (
    <>
      <div className={`${styles.resTable}`} id="view">
        <TicketViewTableHeader disableBulkAction={showLimiterPage} />
        {showLimiterPage ? (
          <LimiterPage />
        ) : (
          <TicketViewTableRow showBlurredSection={showBlurredSection} />
        )}
      </div>
    </>
  );
}

export default TicketView;
