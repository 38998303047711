import React, { useState } from "react";
import styles from "./TemplateFolder.module.scss";
import TemplateTab from "./Children/TemplateTab/TemplateTab";
import TemplateMainSection from "./Children/TemplateMainSection/TemplateMainSection";
import ShowTemplate from "../SearchedTemplates/Children/ShowTemplate/ShowTemplate";
import RecentlyUsed from "./Children/recentlyUsed/RecentlyUsed";
const TemplateFolder = ({
  setTemplates,
  templates,
  selectedCategory,
  setSelectedCategory,
  setSelectedTemplateData = (template: any) => {},
  onTemplateSelect = (template: string) => {},
  whichTab,
  setWhichTab
}: {
  setTemplates: any;
  selectedCategory: any;
  templates: any;
  setSelectedCategory: any;
  setSelectedTemplateData: any;
  onTemplateSelect: any;
  whichTab: string;
  setWhichTab: (value:string)=>void;
}) => {

  return (
    <div className={`mt-3 d-flex flex-column h-100`}>
      <TemplateTab whichTab={whichTab} setWhichTab={setWhichTab} />
      {whichTab === "recent" ? (
        <>
          <RecentlyUsed
            setSelectedTemplateData={(template: any) => {
              setSelectedTemplateData(template);
            }}
            onTemplateSelect={(template: string) => {
              onTemplateSelect(template);
            }}
          />
        </>
      ) : (
        <>
          <TemplateMainSection
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            templates={templates}
            setTemplates={setTemplates}
            setSelectedTemplateData={(template: any) => {
              setSelectedTemplateData(template);
            }}
            onTemplateSelect={(template: string) => {
              onTemplateSelect(template);
            }}
          />
        </>
      )}
    </div>
  );
};

export default TemplateFolder;
