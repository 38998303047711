import styles from "./SendingAs.module.scss";
import ViewTemplateButton from "src/components/ViewTemplateButtonV2";
import { useAppSelector } from "src/store/store";
import { useEffect } from "react";
import { getChannelFAIcon } from "src/utils/utils";

export enum ESendTypes {
  Message = 1,
  Note,
}

function SendingAs({
  sendType,
  setSendType,
  selectedTemplate = (templateText: string) => {},
  showES = true,
  showingWhichModal = "",
  setShowingWhichModal = (whichModal: string) => {},
  channelName = "Email",
}: any) {
  const scopes = useAppSelector(
    (state) => state.globals.currentUserData?.scopes,
  );
  useEffect(() => {
    if (
      scopes &&
      (scopes.includes("send_message") || scopes.includes("add_draft")) &&
      showES
    ) {
      setSendType(sendType);
    } else if (scopes && scopes.includes("add_internal_note")) {
      setSendType(ESendTypes.Note);
    }
  }, [false]);
  return (
    <div
      className={`d-flex ${styles.sendingAs} justify-content-between my-1 flex-wrap`}
    >
      <div className={`my-auto d-flex`}>
        {scopes &&
          (scopes.includes("send_message") || scopes.includes("add_draft")) &&
          showES && (
            <div
              className={`my-auto d-flex ${styles.button} ${
                sendType === ESendTypes.Message ? styles.active : ""
              }`}
              onClick={(e) => {
                setSendType(ESendTypes.Message);
              }}
              id="addEmail"
            >
              <i
                className={`my-auto fa-brands ${getChannelFAIcon(channelName)}`}
              />
              {/* <i className={`my-auto fa-solid fa-envelope`} /> */}
              <span className={`my-auto mx-2`}>
                {channelName.toLowerCase() === "email" ? "E-mail" : channelName}
              </span>
              {/* <i className={`my-auto fa-solid fa-caret-down`}></i> */}
            </div>
          )}
        {scopes && scopes.includes("add_internal_note") && (
          <>
            {scopes &&
              (scopes.includes("send_message") ||
                scopes.includes("add_draft")) &&
              showES && <div className={`my-auto ${styles.vr} mx-3`}></div>}
            <div
              className={`my-auto ${styles.button} ${
                sendType === ESendTypes.Note ? styles.active : ""
              }`}
              onClick={(e) => {
                setSendType(ESendTypes.Note);
              }}
              id="addInternalNote"
            >
              <i className={`my-auto fa-solid fa-note-sticky`} />
              <span className={`my-auto mx-2`}>Add Internal Note</span>
            </div>
          </>
        )}
      </div>
      {scopes && scopes.includes("view_templates") && (
        <ViewTemplateButton
          selectedTemplate={(templateText: string) => {
            selectedTemplate(templateText);
          }}
          showingWhichModal={showingWhichModal}
          setShowingWhichModal={setShowingWhichModal}
        />
      )}
    </div>
  );
}

export default SendingAs;
