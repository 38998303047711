import { useInfiniteQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  BrandDetail,
  GetBrandParams,
  getBrands,
} from "src/services/EmailIntegration/getBrands";

interface ListOption {
  label: string;
  value: string;
  isAddBrand: boolean;
}

/**
 * Custom hook to handle logic for fetching and formatting brands options
 */
const useBrandsDropdown = (
  payload: GetBrandParams,
  onSelectBrand: (email: string, name: string, id?: number | string) => void,
  isDisabled: boolean
) => {
  // Manages Showing Add brand popup
  const [showAddBrand, setShowAddBrand] = useState(false);

  // Manages selected option
  const [selectedOption, setSelectedOption] = useState<ListOption | null>(null);

  // Manages all brands data
  const [brands, setBrands] = useState<BrandDetail[]>([]);

  // Infinite query hook to fetch pages
  const { data, isLoading, fetchNextPage, hasNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ["emailIntegration/getBrands", payload],
      queryFn: ({ pageParam = payload }) => getBrands(pageParam),
      getNextPageParam: (lastPage, allPages) => {
        const data = allPages.flatMap((page) => page.data);
        if (data.length < lastPage.metaData.totalCount) {
          const nextPageParam = {
            limit: payload.limit,
            start: data.length,
          };
          return nextPageParam;
        }
        return null;
      },
      enabled: !isDisabled,
    });

  /**
   * Fetch next page handler
   */
  const fetchNextPageHandler = () => {
    // If we have next page and currently not fetching fetch the next page
    if (hasNextPage && !isFetching) {
      fetchNextPage();
    }
  };

  /**
   * Effect for updating all brands data once we have page data
   */
  useEffect(() => {
    if (data) {
      setBrands(data.pages.flatMap((page) => page.data));
    }
  }, [data]);

  /**
   * List options formatted from brands data
   */
  const options: ListOption[] = useMemo(() => {
    return brands.map((brand) => ({
      value: brand.brandEmail,
      label: `${brand.brandName} (${brand.brandEmail})`,
      isAddBrand: false,
    }));
  }, [brands]);

  /**
   * Handler for changing brands on selecting list item
   */
  const handleBrandChange = useCallback(
    (option: ListOption | null) => {
      // Check if selected option is add brand
      if (option && option.isAddBrand) {
        // Show add brand UI
        setShowAddBrand(true);

        return false;
      } else {
        const email = option ? option.value : "";

        // Find the brand
        const brand = brands.find(
          (brand) => brand.brandEmail === option?.value
        );

        // Get the brand name
        const brandName = brand ? brand.brandName : "";

        // Call parent handler with selected brand data
        onSelectBrand(email, brandName ? brandName : "", brand?.id);

        // Set the selected option
        setSelectedOption(option);
      }
    },
    [brands, onSelectBrand]
  );

  /**
   * Handler for hiding add brand popup
   */
  const handleHideAddBrand = useCallback(() => setShowAddBrand(false), []);

  /**
   * Handler for setting new brand after adding new brand
   */
  const handleAddBrand = useCallback(
    (id: string | number, brandName: string, brandEmail: string) => {
      // Add brand to brand list
      setBrands((prev) => [
        ...prev,
        {
          id: id + "",
          brandEmail,
          brandName,
        },
      ]);

      // Set the new brand as the currently selected option
      setSelectedOption({
        isAddBrand: false,
        value: brandEmail,
        label: `${brandName} (${brandEmail})`,
      });

      // Cal parent handler with new brand data
      onSelectBrand(brandEmail, brandName, id);
    },
    [onSelectBrand]
  );

  /**
   * Varibale for showing / hiding loader
   */
  const showLoader = useMemo(() => {
    // If dropdown is disabled hide loader
    if (isDisabled) {
      return false;
    }

    // Show loader if loading or fetching data
    return isLoading || isFetching;
  }, [isDisabled, isFetching, isLoading]);

  return {
    showAddBrand,
    selectedOption,
    showLoader,
    options,
    fetchNextPageHandler,
    handleBrandChange,
    handleAddBrand,
    handleHideAddBrand,
  };
};

export default useBrandsDropdown;
