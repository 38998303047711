import { axiosJSON } from "src/globals/axiosEndPoints";
import { formatFilterDate } from "src/store/slices/ticketFilters/ticketFilters.selectors";
import { ESideBar } from "src/store/slices/tickets/tickets.slice";
import { RootState } from "src/store/store";
import {
  getTicketsCountInSideBar,
  ISidebarTicketsCountParams,
  ISidebarTicketsCountRes,
  prepareGetSidebarTicketsCountQuery,
} from "./getTicketsCountInSideBar";

export const getMultipleSidebarData = async (
  params: Array<ISidebarTicketsCountParams>,
  signal?: any,
) => {
  const { data }: { data: Array<ISidebarTicketsCountRes> } =
    await axiosJSON.post(`/api/ticket/multipleSidebarData`, params, {
      signal,
    });
  return prepareData(data);
};
const getAITicketCountData = async ({
  param,
  signal,
}: {
  signal: any;
  param: ISidebarTicketsCountParams;
}) => {
  let totalTicketCount;
  try {
    const res = await getTicketsCountInSideBar(param, signal);
    totalTicketCount = res.totalTicketCount;
  } catch (error) {
    console.error("Error fetching ticket count:", error);
    totalTicketCount = 0; // Assign default value in case of error
  }
  const ticketStatusId = param.filters.options
    ? param.filters.options[0].value
    : 0;
  return {
    name: ticketStatusId == 4 ? "ai_open" : "ai_closed",
    totalTicketCount: totalTicketCount ?? 0,
  };
};

export const getMultipleSidebarDataMultiAjax = async (params: {
  [key: string]: { signal: any; param: ISidebarTicketsCountParams };
}) => {
  try {
    let data = await Promise.all(
      Object.values(params).map((p) => {
        if (p.param.filters.tableType == "ai_handled") {
          return getAITicketCountData({ param: p.param, signal: p.signal });
        } else {
          return getTicketsCountInSideBar(p.param, p.signal);
        }
      }),
    );
    return prepareData(data);
  } catch (error) {
    console.error("Error in getMultipleSidebarDataMultiAjax:", error);
    return []; // Return empty array or handle error as appropriate for your use case
  }
};

const prepareData = (data: Array<ISidebarTicketsCountRes>) => {
  const ret: { [key: string]: ISidebarTicketsCountRes } = {};
  data.forEach((item) => {
    if (item.viewId) {
      ret["viewId::" + item.viewId] = item;
    } else if (item.name?.toLowerCase()?.includes("all")) {
      ret.all = item;
    } else if (item.name?.toLowerCase()?.includes("my")) {
      ret.my = item;
    } else if (item.name?.toLowerCase()?.includes("mentions")) {
      ret.mentions = item;
    }
    if (item.totalTicketCount) {
      if (item.name == "ai_closed") {
        ret.ai_closed = { totalTicketCount: item?.totalTicketCount };
      }
      if (item.name == "ai_open") {
        ret.ai_open = { totalTicketCount: item?.totalTicketCount };
      }
    }
  });
  return ret;
};

export const createParamMSD = ({
  ticketAppliedData,
  activeListIndex,
  sidebarFiltersApplied,
}: {
  ticketAppliedData: any;
  activeListIndex: string;
  sidebarFiltersApplied: any;
}) => {
  ticketAppliedData = ticketAppliedData ? JSON.parse(ticketAppliedData) : {};
  const ticketFilters = ticketAppliedData.ticketFilters ?? {};

  const activeChannelId: number[] =
    sidebarFiltersApplied?.activeSidebarChannelId ?? [0];
  const activeTicketStatusId: number[] =
    sidebarFiltersApplied?.activeSidebarTicketStatusId ?? [0];
  const activeDraftStatusId: [number] =
    sidebarFiltersApplied?.activeSidebarDraftStatusId ?? [0];

  const createdDateValue = ticketFilters.filterByCreatedDateValue
    ? formatFilterDate(ticketFilters.filterByCreatedDateValue)
    : null;
  const closedDateValue = ticketFilters.filterByClosedDateValue
    ? formatFilterDate(ticketFilters.filterByClosedDateValue)
    : null;
  const notRepliedSinceDateValue = ticketFilters.filterByNotRepliedSinceValue
    ? formatFilterDate(ticketFilters.filterByNotRepliedSinceValue)
    : null;

  // Format the filter date or return null if not available
  const lastUpdatedDateValue = ticketFilters.filterByLastUpdatedValue
    ? formatFilterDate(ticketFilters.filterByLastUpdatedValue)
    : null;
  const filterSearchString = "";
  const filterOptions = {
    activeChannelId,
    activeDraftStatusId,
    activeTicketStatusId,
    createdDateValue,
    closedDateValue,
    lastUpdatedDateValue,
    notRepliedSinceDateValue,
    filterSearchString,
    ...ticketFilters,
  };

  const ticketsCountQuery: any = prepareGetSidebarTicketsCountQuery({
    tableType: activeListIndex,
    filterOptions,
  });
  return ticketsCountQuery;
};
