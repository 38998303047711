import styles from "./CodeSetting.module.scss";
import useCodeSetting from "./useCodeSetting";
import arrowRight from "src/assets/images/arrowRight.png";
const CodeSetting = () => {
  const {
    dropdown,
    setDropDown,
    head,
    bodyCode,
    code,
    body,
    handleBodyChange,
    handleHeadChange,
    textStatus,
  } = useCodeSetting();

  return (
    <div className={`p-3 ${styles.settingWrapper}`}>
      <p className={`${styles.heading} saufter_h4`}>Custom code Settings</p>
      <div
        className={`d-flex justify-content-between align-items-center px-2 saufter_medium_h4 ${
          styles.dropBtn
        } ${dropdown && styles.borderSet}`}
        onClick={() => setDropDown(!dropdown)}
      >
        <span>Custom code</span>
        <span className={`${styles.arrow}`}>
          {dropdown ? (
            <img src={arrowRight} alt="" className={`${styles.arrowDown}`} />
          ) : (
            <img src={arrowRight} alt="" />
          )}
        </span>
      </div>
      {dropdown ? (
        <div className={`p-2 p-md-3 mb-2 ${styles.dropDown}`}>
          <p className={`${styles.cutomHead} saufter_h5`}>
            Custom code allows adding useful things to the markup of your
            Knowledge Base, such as third-party tracking codes, custom
            JavaScript or meta tags.
          </p>
          {/* Head Custom Code  */}
          <div>
            <h6 className={`mb-0 ${styles.headText} saufter_h5`}>HEAD</h6>
            <span className={`d-inline-block ${styles.headSpan} saufter_h6`}>
              Code here will be placed just above the closing of the {code}{" "}
              tag.
            </span>
            <div className="mt-2 mb-3">
              <textarea
                className={`form-control ${styles.textArea}`}
                id="exampleFormControlTextarea1"
                rows={3}
                onChange={handleHeadChange}
                value={head}
              ></textarea>
              <span className={`d-flex justify-content-end ${styles.saveText}`}>
                {textStatus.head}
              </span>
            </div>
          </div>

          {/* Body Custom Code */}
          <div>
            <h6 className={`mb-0 ${styles.headText} saufter_h5`}>Body</h6>
            <span className={`d-inline-block ${styles.headSpan} saufter_h6`}>
              Code here will be placed just before the closing of the {body}{" "} 
              tag.
            </span>
            <div className="mt-2 mb-3">
              <textarea
                className={`form-control ${styles.textArea}`}
                id="exampleFormControlTextarea1"
                rows={3}
                onChange={handleBodyChange}
                value={bodyCode}
              ></textarea>
              <span className={`d-flex justify-content-end ${styles.saveText}`}>
                {textStatus.body}
              </span>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default CodeSetting;
