import shopifyLogo from "src/assets/images/shopify.png";
import slackLogo from "src/assets/images/slack.png";
import sendLogo from "src/assets/images/send.png";
import { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { selectFile, validateEmail } from "src/utils/utils";
import { ModalBody, ModalHeader } from "react-bootstrap";
import SendingInfo from "src/components/SendingInfo";
import { QuillEditor } from "src/components/QuillEditor";
import styles from "./Add.module.scss";
import MessageContainer from "./MessageContainer";
import { UploadAttachmentRes } from "src/services/Attachment/uploadAttachment";
import { BsExclamationDiamondFill } from "react-icons/bs";
import IntegrationFailureModal from "src/components/IntegrationFailureModal/IntegrationFailureModal";
import { IntegrationFailureMetaData } from "src/types/IntegrationFailure";

const Add = forwardRef(function (
  {
    addTicketSubmit,
    sendAs,
    setSendAs,
    onCloseModel,
    sendType,
    setSendType,
    // attachmentBatchId,
    // setAttachmentBatchId
    attachmentUploadData,
    setAttachmentUploadData,
    selectedFiles,
    setSelectedFiles,
    currentAttachmentBatchNumber,
    setCurrentAttachmentBatchNumber,
    customerName,
    setCustomerName,
    toValue,
    setToValue,
    subjectValue,
    setSubjectValue,
    fromValue,
    setFromValue,
    cCValue,
    setCCValue,
    bCCValue,
    setBCCValue,
    showError,
    setShowError,
    isIntegrationFailure,
    integrationFailure,
    handleCannotSend,
  }: {
    addTicketSubmit: (e: any) => void;
    sendAs: string;
    setSendAs: Function;
    onCloseModel: Function;
    sendType: number;
    setSendType: Function;
    // attachmentBatchId: string;
    // setAttachmentBatchId: Function
    attachmentUploadData: any;
    setAttachmentUploadData: Function;
    selectedFiles: any;
    setSelectedFiles: Function;
    currentAttachmentBatchNumber: any;
    setCurrentAttachmentBatchNumber: Function;
    customerName: any;
    setCustomerName: (name: string) => void;
    toValue: any;
    setToValue: (name: string) => void;
    subjectValue: any;
    setSubjectValue: (name: string) => void;
    fromValue: any;
    setFromValue: (name: string) => void;
    cCValue: any;
    setCCValue: (name: string) => void;
    bCCValue: any;
    setBCCValue: (name: string) => void;
    showError: boolean;
    setShowError: any;
    isIntegrationFailure?: boolean;
    integrationFailure?: IntegrationFailureMetaData | null;
    handleCannotSend?: any;
  },
  ref: any,
) {
  return (
    <>
      <ModalHeader id="addTicketModal__header">
        <h5 className={`modal-title transferHead ${styles.transferHead}`}>
          <span className={`me-2 ticketIcon ${styles.ticketIcon}`}>
            <i className="fa-solid fa-ticket" />
          </span>
          Add Ticket:
        </h5>
        <div>
          <div className="d-flex">
            {/* <button
              type="button"
              className={`btn-close my-auto ${styles.btnClose}`}
              onClick={(e) => {
                onCloseModel();
              }}
            /> */}
            <span
              className={`${styles.btnClose}`}
              onClick={(e) => {
                onCloseModel();
              }}
              id="addTicketModal__closeBtn"
            >
              <i className="fa-solid fa-xmark"></i>
            </span>
          </div>
        </div>
      </ModalHeader>
      <ModalBody id="addTicketModal__body">
        <form
          onSubmit={addTicketSubmit}
          ref={ref}
          id="addTicketModal__form"
        >
          <input
            type="email"
            className={`${styles.formAddticket}`}
          />
          <div
            className="mb-3 row"
            id="addTicketModal__toMail"
          >
            <label className={`col-sm-1 col-form-label ${styles.formLabel}`}>
              To:
            </label>
            <div
              className={`col-sm-11 ${
                showError && !validateEmail(toValue) ? styles.redBorder : ""
              }`}
            >
              <SendingInfo
                name="to"
                addUser={true}
                currentValue={toValue}
                getValue={setToValue}
                getCustomerName={setCustomerName}
              />
            </div>
          </div>
          <div
            className="mb-3 row d-none"
            id="addTicketModal__name"
          >
            <label className={`col-sm-1 col-form-label ${styles.formLabel}`}>
              Name:
            </label>
            <div className="col-sm-11">
              <input
                data-lpignore="true"
                type="text"
                style={{ font: "normal normal 600 14px/21px Poppins" }}
                className="form-control"
                name="name"
                placeholder="Enter customer name here..."
                required={true}
                value={customerName}
                onChange={(e) => {
                  setCustomerName(e.target.value);
                }}
                readOnly={true}
              />
            </div>
          </div>
          <div
            className="mb-3 row"
            id="addTicketModal__fromMail"
          >
            <label className={`col-sm-1 col-form-label ${styles.formLabel}`}>
              From:
            </label>
            <div
              className={`col-sm-11 ${
                showError && !validateEmail(fromValue) ? styles.redBorder : ""
              }`}
            >
              <SendingInfo
                name="from"
                currentValue={fromValue}
                getValue={setFromValue}
                isError={isIntegrationFailure}
              />
              {isIntegrationFailure && (
                <p className={`${styles.integrateText}`}>
                  <span className={`me-1 ${styles.exclamation}`}>
                    <BsExclamationDiamondFill />
                  </span>
                  <span>Integration not available</span>
                </p>
              )}
            </div>
          </div>
          <div
            className="mb-3 row"
            id="addTicketModal__subject"
          >
            <label className={`col-sm-1 col-form-label ${styles.formLabel}`}>
              Subject:
            </label>
            <div className="col-sm-11">
              <input
                data-lpignore="true"
                type="text"
                className={`form-control ${
                  showError && subjectValue.trim() === "" ? "redBorder" : ""
                }`}
                name="ticket_title"
                placeholder="Enter subject here..."
                value={subjectValue}
                onChange={(e) => {
                  setSubjectValue(e.target.value);
                }}
              />
            </div>
          </div>
          <div
            className="mb-3 row"
            id="addTicketModal__bcc"
          >
            <label className={`col-sm-1 col-form-label ${styles.formLabel}`}>
              Bcc:
            </label>
            <div className="col-sm-11">
              <SendingInfo
                name="bcc"
                isMulti={true}
                addUser={true}
                currentValue={bCCValue}
                getValue={setBCCValue}
              />
            </div>
          </div>
          <div
            className="mb-3 row"
            id="addTicketModal__cc"
          >
            <label className={`col-sm-1 col-form-label ${styles.formLabel}`}>
              Cc:
            </label>
            <div className="col-sm-11">
              <SendingInfo
                name="cc"
                isMulti={true}
                addUser={true}
                currentValue={cCValue}
                getValue={setCCValue}
              />
            </div>
          </div>
          <div id="addTicketModal__msgContainer">
            <MessageContainer
              sendAs={sendAs}
              setSendAs={setSendAs}
              sendType={sendType}
              setSendType={setSendType}
              fromValue={fromValue}
              //  attachmentBatchId={attachmentBatchId}
              //  setAttachmentBatchId={setAttachmentBatchId}
              attachmentUploadData={attachmentUploadData}
              setAttachmentUploadData={setAttachmentUploadData}
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              showError={showError}
              setShowError={setShowError}
              currentAttachmentBatchNumber={currentAttachmentBatchNumber}
              setCurrentAttachmentBatchNumber={setCurrentAttachmentBatchNumber}
              handleCannotSend={handleCannotSend}
              isIntegrationFailure={isIntegrationFailure}
            />
          </div>
        </form>
      </ModalBody>
    </>
  );
});

export default Add;
