import {
  ChannelType,
  PhoneNumberData,
} from "src/services/LiveChat/startNewChat/startNewChat.services";
import styles from "./SendingInfoWrapper.module.scss";
import To from "../To/To";
import From from "../From/From";
import { IChatChannelDetail } from "src/services/LiveChat/messageExchange/getAllCustomers";
import { EChannel } from "src/enums/EChannel";

type Props = {
  userId?: string | number;
  activeMessageType: EChannel;
  showSending: boolean;
  selectedToPhone: PhoneNumberData | IChatChannelDetail | undefined;
  setSelectedToPhone: any;
  selectedFromPhone: PhoneNumberData | IChatChannelDetail | undefined;
  setSelectedFromToPhone: any;
  showError: boolean;
  canFetchInitial?: boolean;
  isDisabled?: boolean;
};

function SendingInfoWrapper({
  userId,
  activeMessageType,
  showSending,
  selectedToPhone,
  setSelectedToPhone,
  selectedFromPhone,
  setSelectedFromToPhone,
  showError,
  canFetchInitial,
  isDisabled,
}: Props) {
  return (
    <>
      <To
        userId={userId}
        activeMessageType={activeMessageType}
        selectedToPhone={selectedToPhone}
        setSelectedToPhone={setSelectedToPhone}
        showError={showError}
        canFetchInitial={canFetchInitial}
        isDisabled={isDisabled}
      />
      {showSending && (
        <>
          <From
            userId={userId}
            activeMessageType={activeMessageType}
            selectedFromPhone={selectedFromPhone}
            setSelectedFromToPhone={setSelectedFromToPhone}
            showSending={showSending}
            showError={showError}
            canFetchInitial={canFetchInitial}
            isDisabled={isDisabled}
          />
        </>
      )}
    </>
  );
}

export default SendingInfoWrapper;
