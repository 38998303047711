import LiveChatEnableToggle from "src/routes/LiveChat/Children/ChatSetting/Children/LiveChatWidget/Children/LiveChatEnableToggle/LiveChatEnableToggle";
import styles from "./StoreDetail.module.scss";
import SelectBox from "src/routes/LiveChat/Children/ChatSetting/Children/LiveChatWidget/Children/OtherWebsite/Children/SelectBox";
import {
  IStoreDetails,
  LCFieldValidation,
} from "../../../../hooks/useLiveChatToDo";
import { useAppSelector } from "src/store/store";
import { LabelComponent } from "src/routes/LiveChat/Children/ChatSetting/Children/LiveChatWidget/Children/OtherWebsite/OtherWebsite";
import { useState } from "react";

/**
 * Props for the StoreDetail component
 */
interface Props {
  /** Store details object */
  storeDetail: IStoreDetails;
  /** Integration ID */
  integrationId?: string;
  /** Field validation indicator */
  showValidation: LCFieldValidation;
  /** Function to update store details */
  updateStoreDetails: (val: Partial<IStoreDetails>) => void;
}

/**
 * Component for managing store details
 * @param props Props object containing store details, integration ID, validation status, and update function
 * @returns StoreDetail component
 */
const StoreDetail = ({
  storeDetail,
  updateStoreDetails,
  showValidation,
  integrationId,
}: Props) => {
  const { installed_successfully, store_url, embedded_script, brand } =
    useAppSelector((state) => state.websiteSettings.activeWebIntegration);

  const [choosedBrandEmail, setChoosedBrandEmail] = useState(
    brand?.email ?? "",
  );

  return (
    <div>
      {/* Wrapper */}
      <div
        className="d-flex mt-3 w-100 mt-4"
        id="storeDetail"
      >
        {/* Step Number */}
        <div className={`${styles.pointsDiv} small mt-0 me-2`}>1</div>

        {/* Step Info */}
        <div className={`${styles.rightSection}`}>
          {/* Header */}
          <div className="d-flex justify-content-between flex-wrap">
            {/* Question Name */}
            <div>
              <span className={`${styles.stepPara}`}>
                Let's begin with the basics
              </span>
            </div>

            {/* Live Chat Toggle Button */}
            <div className="d-flex flex-column">
              <LiveChatEnableToggle
                disabled={
                  !installed_successfully || store_url !== storeDetail.storeURL
                }
                enabled={storeDetail.liveChatToggle}
                integrationId={integrationId}
                type="live_chat_enabled"
                onChange={(val) => updateStoreDetails({ liveChatToggle: val })}
                liveChatType="otherWebsite"
              />
            </div>
          </div>

          {/* Website Info */}
          <div className={`w-100 my-3`}>
            {/* Store Name */}
            <div>
              <label
                htmlFor="storeName"
                className="mb-2"
              >
                <p className={`${styles.labelHead} mb-0`}>Store name:</p>
                <span className={`${styles.labelSub}`}>
                  This name will appear to your audience when they interact with
                  the live chat widget
                </span>
              </label>
              <input
                className={`w-100 form-control p-2 px-3 ${styles.storeInput} ${
                  showValidation.storeName ? styles.valdationBorder : "mb-3"
                }`}
                type="text"
                placeholder="Enter website name"
                aria-label="default input example"
                value={storeDetail.storeName}
                onChange={(e) =>
                  updateStoreDetails({ storeName: e.target.value })
                }
                id="storeName"
              />
            </div>
            {/* Store Name Validation */}
            <span
              className={`${
                showValidation.storeName ? styles.validationText : "d-none"
              } mb-3`}
            >
              Please enter website name!
            </span>

            {/* Store Website */}
            <div>
              <label
                htmlFor="storeWebsite"
                className="mb-2"
              >
                <p className={`${styles.labelHead} mb-0`}>Store Website:</p>
                <span className={`${styles.labelSub}`}>
                  This is URL where your live chat widget will be installed
                </span>
              </label>
              <input
                className={`w-100 form-control ${styles.storeInput} ${
                  showValidation.storeURL ? styles.valdationBorder : ""
                }`}
                type="text"
                placeholder="Enter website URL"
                aria-label="default input example"
                value={storeDetail.storeURL}
                onChange={(e) =>
                  updateStoreDetails({ storeURL: e.target.value })
                }
                id="storeWebsite"
              />
            </div>

            {/* Store Website Validation */}
            <span
              className={`${
                showValidation.storeURL ? styles.validationText : "d-none"
              }`}
            >
              {storeDetail.storeURL === window.origin
                ? "Widget cannot be installed on current domain"
                : "Please enter a valid URL!"}
            </span>
          </div>

          {/* Brands Dropdown */}
          <div className={`mb-3`}>
            <p className={`${styles.chosePara}`}>Brand linked</p>
            <SelectBox
              name="from"
              integrationId={integrationId}
              currentValue={choosedBrandEmail}
              getIdValue={(value) => updateStoreDetails({ brandId: value })}
              isDisabled={embedded_script !== null}
              getValue={(value) => {
                setChoosedBrandEmail(value);
              }}
              isMulti={false}
              LabelComponent={LabelComponent}
              placeholder={"Select Brand"}
              initialFetchDone={() => {
                updateStoreDetails({ brandsFetched: true });
              }}
              validationError={showValidation.brandId}
            />
            <span
              className={`${
                showValidation.brandId ? styles.validationText : "d-none"
              }`}
            >
              Please select a brand!
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoreDetail;
