import { axiosJSON } from "src/globals/axiosEndPoints";
import { BetweenRange } from "./createSegment.service";
import { SegmentFilter } from "./getSegmentFilters.service";

export interface AppliedFilters {
  filterKey: string;
  /**
   * Required in payload
   */
  value?: string | number | Array<string>;
  /**
   * Only if custom date is selected as value
   */
  valueDate?: string | BetweenRange<string | null>;
  /**
   * Not required in the payload. Only for frontend use.
   */
  selectedValue?: SegmentFilter["defaultValue"];
}

export interface CustomerSegmentParams {
  segmentType: string;
  segmentId: string;
  start: number;
  limit: number;
  columns: Array<string>; // ["*"]
  filters?: Record<string, AppliedFilters>;
  searchText?: string;
}

export interface SegmentValueItem {
  id: string;
  [columnKey: string]: any;
}

export interface AllSegmentValues {
  segmentValues: Record<string, SegmentValueItem>;
  segmentValueIds: Array<string>;
  hasMoreSegmentView: boolean;
  totalSegmentValues: number;
}

/**
 * Retrieves all segments based on the provided payload and segment type.
 *
 * @param payload - The payload containing the start, limit, and filters for the segments.
 * @param customerType - The type of segment to retrieve (company, people, reviews, accounts).
 * @returns A promise that resolves to an object containing all segments, segment IDs, and metadata.
 * @throws An error if there is an error response or if something unexpected occurs.
 */
const getSegmentValuesService = async ({
  segmentType,
  ...payload
}: CustomerSegmentParams): Promise<AllSegmentValues> => {
  if (payload.searchText?.trim() === "") {
    delete payload.searchText;
  }
  const { data: res } = await axiosJSON.post(
    `/api/segment/${segmentType}/getSegmentValues`,
    payload,
  );

  if (res.error || res.err) {
    throw new Error(res.msg ?? res.message ?? "Something Unexpected Occured!");
  }

  const ret: AllSegmentValues = {
    segmentValues: {},
    segmentValueIds: [],
    hasMoreSegmentView: false,
    totalSegmentValues: res.metaData?.totalCount ?? 0,
  };

  res.data.forEach((item: SegmentValueItem) => {
    item.id = item.id + "";
    ret.segmentValues[item.id] = item;
    ret.segmentValueIds.push(item.id);
  });

  if (ret.segmentValueIds.length !== payload.limit) {
    ret.hasMoreSegmentView = false;
  } else {
    ret.hasMoreSegmentView = true;
  }

  return ret;
};

export default getSegmentValuesService;
