import { GetConfigModalParams } from "src/features/ReturnAutoWorkFlow/children/ConfigModals/oldReturnModals.types";
import { axiosJSON } from "src/globals/axiosEndPoints";
import { AntiRefundStrategiesOrdersTab } from "./getAntiRefundStrategiesOrders.service";

interface Payload extends GetConfigModalParams {
  configTabs: AntiRefundStrategiesOrdersTab[];
}

async function updateAntiRefundStrategiesOrdersService(payload: Payload) {
  const { data: res } = await axiosJSON.post(
    "/api/setting/automation/refund/step/13/configuration",
    payload,
  );

  if (res.err || res.error) {
    throw new Error(res.msg ?? res.message ?? "Some Error Occurred.");
  }
}

export default updateAntiRefundStrategiesOrdersService;
