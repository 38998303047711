import { axiosJSON } from "src/globals/axiosEndPoints";
import { CreateDispatchRuleConfigParams } from "./createDispatchRuleConfig.service";
import { AutomationAPIPayload } from "../createAutomation.service";
import { IS_TEST_ACTIVE } from "src/globals/constants";
import { IDispatchRuleData } from "./getDispatchRuleConfigById.service";

export interface UpdateDispatchRuleConfigParams
  extends Partial<CreateDispatchRuleConfigParams> {
  configurationId: string;
}

interface Response {
  status?: "duplicate_dispatched_conditions";
  message?: string;
  data?: IDispatchRuleData;
}

export const updateDispatchRuleConfigService = async ({
  payload,
  type,
}: AutomationAPIPayload<UpdateDispatchRuleConfigParams>) => {
  const url = IS_TEST_ACTIVE
    ? `${process.env.REACT_APP_TEST_SITE_URL}/bugManagement/update`
    : `/api/automation/${type}/updateDispatchRuleConfig`;

  const { data: res } = await axiosJSON.post(url, payload);

  if (res.err || res.error) {
    if (res.status === "duplicate_dispatched_conditions") {
      return {
        status: res.status,
        message:
          res.msg ??
          res.message ??
          "The selected dispatching rules conditions are already in use. Please select different dispatched conditions",
      } as Response;
    }
    throw new Error(
      res.msg || res.message || "Cannot update the dispatch rule config",
    );
  }

  if (payload.integrationId) {
    res.data.integrationId = payload.integrationId;
  }

  return { data: res.data } as Response;
};
