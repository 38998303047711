import { IPostChatFormSetting } from "./postChatFormSetting.slice";

const fetchPostChatFormDataFulfilled = (
  state: IPostChatFormSetting,
  { payload }: {payload: any}
) => {
  state = {
    ...state,
    ...payload,
    postChatFormDataAjaxStatus: "fulfilled",
  };
  return state;
};


export default { fetchPostChatFormDataFulfilled };
