import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import Loader from "src/components/Loader";
import ParseHTML from "src/components/ParseHTML";
import QuillEditorContainer from "src/components/QuillEditorContainer";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import {
  addOrUpdateBrandSignature,
  IAddOrUpdateBrandSignaturePayload,
} from "src/services/Settings/EmailSignature/addOrUpdateBrandSignature";
import {
  BrandAndSignature,
  getBrandSignature,
} from "src/services/Settings/EmailSignature/getBrandSignature";
import styles from "./EmailSignature.module.scss";
import { v4 as uuid } from "uuid";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { getBrandAndSignatureThunk } from "src/store/slices/globals/globals.slice";
import ReactSelect from "react-select";
import {
  replaceLabelWithPlaceholder,
  replacePlaceHolderWithLabel,
  sanatizeSignature,
} from "src/utils/utils";
import VariableModal from "./Children/VariableModal/VariableModal";
import {
  getAllBrandsInfiScroll,
  getBrandSignaturePlaceholders,
  getBrandVariableValues,
  resetBrandData,
  setBrandData,
  setBrandSearchValue,
} from "src/store/slices/settings/brands/brandSettings.slice";
import { BrandSignaturePlaceholder } from "src/services/Settings/Brands/getBrandSignaturePlaceholders";
import { AsyncPaginate } from "react-select-async-paginate";
import { BrandData } from "src/services/Settings/Brands/getAllBrands";
import TinyMCEContainer from "src/components/TinyMCEContainer";
// import { removeMarginInEachPTag } from "src/utils/utils";

const EmailSignature = () => {
  const dispatch = useAppDispatch();
  const brandSignatureData = useAppSelector(
    (state) => state.globals.brandSignatureData
  );
  const { currentUserData } = useAppSelector((state) => state.globals);
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);
  const scopes = useAppSelector(
    (state) => state.globals.currentUserData?.scopes
  );
  // is enabled or not
  const [isEnabled, setIsEnabled] = useState<boolean>(false);
  const [signatureText, setSignatureText] = useState<string>("");
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [showSaveLoader, setShowSaveLoader] = useState<boolean>(false);
  const [selectedBrand, setSelectedbrand] = useState(null as null | BrandData);
  const {
    brandIds,
    brands,
    metaData,
    brandDataAjaxStatus,
    brandPlaceholderOptions,
    brandVariableValues,
  } = useAppSelector((state) => state.brandSettings);

  const currentState = useRef({
    brandIds,
    brands,
    metaData,
  });

  useEffect(() => {
    currentState.current = { brandIds, brands, metaData };
  }, [brandIds, brands, metaData]);

  const insertPlaceholderRef = useRef(null as any);

  const brandVariableLableAndValue = useMemo(() => {
    return brandVariableValues.map((variable) => {
      const placeholder = brandPlaceholderOptions.filter(
        (placeholder) => placeholder.placeholder === variable.key
      );

      if (placeholder.length) {
        return {
          id: placeholder[0].id,
          label: variable.key,
          placeholder: variable.value,
        };
      } else {
        return {
          id: "",
          label: variable.key,
          placeholder: variable.value,
        };
      }
    });
  }, [brandPlaceholderOptions, brandVariableValues]);

  // fetch the signature data
  // const fetchSignatureData = () => {
  //   getBrandSignature()
  //     .then((res) => {
  //       setIsEnabled(res.brandSignatureEnabled);
  //       if (res.brandSignatureValue === null) {
  //         setSignatureText("");
  //       } else {
  //         if (res.brandSignatureEnabled === true) {
  //           setSignatureText(res.brandSignatureValue);
  //         } else {
  //           setSignatureText("");
  //         }
  //       }
  //       setShowLoader(false);
  //     })
  //     .catch((err) => {
  //       pushTheToast({
  //         type: "danger",
  //         text: "Something went wrong!",
  //         position: "top-right",
  //       });
  //     });
  // };

  const isLoading = useMemo(() => {
    return brandDataAjaxStatus === "pending" && brandIds.length === 0;
  }, [brandDataAjaxStatus, brandIds]);

  const validateIfVariableExistsInSignature = useCallback((value: string) => {
    const element = document.createElement("div");
    element.innerHTML = value;

    // all elements with .quill-mention content class
    let moreContents = element.querySelectorAll(".quill-mention");

    if (moreContents.length) {
      return true;
    }

    return false;
  }, []);

  useEffect(() => {
    if (selectedBrand) {
      setIsEnabled(selectedBrand.enabled);
      setSignatureText(
        replacePlaceHolderWithLabel(
          selectedBrand.signature,
          brandPlaceholderOptions,
          "display: inline-block;color: #0B68BB;"
        )
      );
    } else {
      setIsEnabled(false);
      setSignatureText("");
    }
  }, [selectedBrand, brandPlaceholderOptions]);

  // useEffect(() => {
  //   setSelectedbrand((selectedBrand) => {
  //     if (selectedBrand === null) {
  //       return null;
  //     } else {
  //       let i = brandSignatureData.brands
  //         ?.map((data) => data.id)
  //         .indexOf(selectedBrand.id);
  //       if (typeof i === "number" && brandSignatureData.brands) {
  //         return brandSignatureData.brands[i];
  //       } else {
  //         return null;
  //       }
  //     }
  //   });
  //   setShowLoader(brandSignatureData.loading);
  // }, [brandIds, brands]);

  const loadBrands = async (searchTerm: string, prevOptions: any) => {
    dispatch(setBrandSearchValue({ searchValue: searchTerm }));
    const response = await dispatch(
      getAllBrandsInfiScroll({
        start: Array.isArray(prevOptions) ? prevOptions.length : 0,
      })
    );
    const data: any = response.payload;

    return {
      options: Object.values(data.brands).map((brand: any) => ({
        label: brand.name,
        value: brand,
      })),
      hasMore:
        data.metaData.total >
        Array.from(
          new Set([...currentState.current.brandIds, ...data.brandIds])
        ).length,
    };
  };

  useEffect(() => {
    dispatch(resetBrandData());
    // dispatch(getBrandAndSignatureThunk());
    dispatch(getAllBrandsInfiScroll({ start: 0 }));
    dispatch(getBrandSignaturePlaceholders());
    dispatch(getBrandVariableValues());
  }, []);

  // to handle save email signature
  const handleSaveEmailSignature = (e: any) => {
    e.preventDefault();
    onHide();
    if (selectedBrand) {
      if (isEnabled === true && signatureText.trim().length === 0) {
        pushTheToast({
          type: "warning",
          text: "Email signature can not be empty!",
          position: "top-right",
        });
      } else if (selectedBrand.signature !== signatureText) {
        const sanatizedSignature = sanatizeSignature(signatureText);

        const brandSignatureParsed = replaceLabelWithPlaceholder(
          sanatizedSignature,
          brandPlaceholderOptions
        );
        setShowSaveLoader(true);
        let payload: IAddOrUpdateBrandSignaturePayload = {
          brandSignatureEnabled: isEnabled,
          brandSignatureValue: brandSignatureParsed,
          brandId: selectedBrand.id,
        };
        addOrUpdateBrandSignature(payload)
          .then((res) => {
            // Update the selected brand's signature after the operation is completed successfully
            setSelectedbrand((prev) => {
              // Check if there is a previous selected brand and if the brandId matches
              if (prev && payload.brandId == prev?.id) {
                // If the brandId matches, update the signature for the selected brand
                return {
                  ...prev,
                  signature: brandSignatureParsed, // Update the signature with the parsed value
                };
              }
              // If no match is found, return the previous selected brand without modification
              return prev;
            });
            dispatch(
              setBrandData({
                brandId: selectedBrand.id,
                brandData: {
                  ...selectedBrand,
                  signature: brandSignatureParsed,
                  enabled: isEnabled,
                },
              })
            );
            dispatch(getBrandAndSignatureThunk());
            // dispatch(getAllBrandsInfiScroll());
            pushTheToast({
              type: "success",
              text: "Brand signature updated!",
              position: "top-right",
            });
          })
          .catch((err) => {
            pushTheToast({
              type: "danger",
              text: "Something went wrong!",
              position: "top-right",
            });
          })
          .finally(() => setShowSaveLoader(false));
      }
    }
  };

  const handleEnableDisable = (e: any) => {
    if (selectedBrand) {
      let isChecked = e.target.checked;
      setShowLoader(true);
      setIsEnabled(isChecked);
      let payload: IAddOrUpdateBrandSignaturePayload = {
        brandSignatureEnabled: isChecked,
        brandId: selectedBrand.id,
      };
      addOrUpdateBrandSignature(payload)
        .then((res) => {
          dispatch(
            setBrandData({
              brandId: selectedBrand.id,
              brandData: {
                ...selectedBrand,
                enabled: isChecked,
              },
            })
          );
          dispatch(getBrandAndSignatureThunk());
          pushTheToast({
            type: "success",
            text:
              isChecked === true
                ? "Email signature enabled!"
                : "Email signature disabled!",
            position: "top-right",
          });
          // setShowLoader(false);
        })
        .catch((err) => {
          pushTheToast({
            type: "danger",
            text: "Something went wrong!",
            position: "top-right",
          });
        })
        .finally(() => {
          setShowLoader(false);
        });
    }
  };

  const [showModal, setShowModal] = useState(false);

  const onShow = useCallback(
    (e: any) => {
      e.stopPropagation();
      if (!validateIfVariableExistsInSignature(signatureText)) {
        setShowModal(true);
      } else {
        handleSaveEmailSignature(e);
      }
      // console.log("show");
    },
    [signatureText]
  );

  const onHide = useCallback(() => {
    setShowModal(false);
    // console.log("hide");
  }, []);

  const handleAddVariableOnClick = (placeholder: BrandSignaturePlaceholder) => {
    // setBrandSignature(brandSignature+placeholder.placeholder);
    if (insertPlaceholderRef.current.addMentionRef.current.addMention) {
      insertPlaceholderRef.current.addMentionRef.current.addMention({
        id: placeholder.id,
        name: "{" + placeholder.label + "}",
        inlineStyles: "display: inline-block;color: #0B68BB;"
      });
    }
  };

  return (
    <div className={`main__content`}>
      <div className="row">
        <div className="col-12">
          <div className="mb-4 mt-2">
            <h2 className={`mb-0 ${styles.emailHead}`}>Email Signature</h2>
            <span className={styles.emailSubHead}>
              Enter your email signature & preview.
            </span>
          </div>
        </div>
      </div>
      {isLoading === true ? (
        <Loader />
      ) : (
        <div className="row">
          <div className="col-md-6">
            <p className={`mb-1 ${styles.selText}`}>
              Select brand <span className={`${styles.req}`}>* </span>
            </p>
              <div className={`${styles.selectBox}`}>
                <AsyncPaginate
                  // onMenuScrollToBottom={handleInfiniteScroll}
                  loadOptions={loadBrands}
                  menuPortalTarget={document.body}
                  isLoading={brandDataAjaxStatus === "pending"}
                  //Added for react-select to solve Z-index issue.
                  styles={customStyles}
                  value={
                    selectedBrand
                      ? { label: selectedBrand.name, value: selectedBrand }
                      : null
                  }
                  options={Object.values(brands).map((value: any) => ({
                    label: value.name,
                    value,
                  }))}
                  onChange={(value) => {
                    let brand = brands[value?.value.id + ""];
                    setSelectedbrand(brand ?? null);
                  }}
                  placeholder={"Select brand"}
                />
              </div>
            {selectedBrand && (
              <>
                <div className="form-check my-3">
                  <input
                    data-lpignore="true"
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                    checked={isEnabled}
                    disabled={
                      (scopes &&
                        scopes.includes(
                          "settings_can_edit_email_signature"
                        )) === true
                        ? false
                        : true
                    }
                    onChange={() => {}}
                    onClick={(e: any) => handleEnableDisable(e)}
                  />
                  <label
                    className={`${styles.enableSign}`} /*for="flexCheckDefault"*/
                  >
                    Enable Signature
                  </label>
                  {showLoader === true ? (
                    <Spinner
                      className="my-auto mx-1"
                      animation="border"
                      size="sm"
                    />
                  ) : null}
                </div>
                <div className={styles.emailSign}>
                  {isEnabled === true &&
                  (scopes &&
                    scopes.includes("settings_can_edit_email_signature")) ===
                    true ? (
                    <TinyMCEContainer
                      className={`${styles.editorBox}`}
                      // editorClassName={`${styles.editor}`}
                      value={signatureText}
                      onChange={(e: any) => setSignatureText(e)}
                      options={{
                        placeholder: "Please enter your email signature",
                        min_height: 200,
                        max_height: 200,
                      }}
                      uniqueID={uuid()}
                      areAttachmentsPublic={true}
                      inlineAttachmentOnly={true}
                      disableAttachment={false}
                      selectedFiles={selectedFiles}
                      setSelectedFiles={setSelectedFiles}
                      isFileUploading={isFileUploading}
                      setIsFileUploading={setIsFileUploading}
                      showAttachmentOverlay={false}
                      getMentionRef={(data) => {
                        insertPlaceholderRef.current = data;
                      }}
                    />
                  ) : (
                    <ParseHTML
                      html={replacePlaceHolderWithLabel(
                        signatureText,
                        brandPlaceholderOptions
                      )}
                      className={`${styles.editorBox} ${styles.editor} p-2`}
                      style={{ overflow: "auto" }}
                    />
                  )}
                  <div>
                    <p className={`mb-0 ${styles.variableHead}`}>
                      Add variables:
                    </p>

                    <div className="d-flex align-items-center flex-wrap">
                      {brandPlaceholderOptions.map((placeholder) => {
                        return (
                          <p
                            role="button"
                            className={`${styles.variableName} p-1`}
                            onClick={(e) => {
                              e.preventDefault();
                              handleAddVariableOnClick(placeholder);
                            }}
                          >
                            {"{" + placeholder.label + "}"}
                          </p>
                        );
                      })}
                      {/* <p className={`${styles.variableName} p-1`}>
                        {agentName}
                      </p>
                      <p className={`${styles.variableName} p-1`}>{fullName}</p>
                      <p className={`${styles.variableName} p-1`}>
                        {agentEmail}
                      </p>
                      <p className={`${styles.variableName} p-1`}>
                        {agentFirstName}
                      </p> */}
                    </div>
                  </div>
                </div>
                {isEnabled === true &&
                (scopes &&
                  scopes.includes("settings_can_edit_email_signature")) ===
                  true ? (
                  <div
                    className={`d-flex justify-content-end ${styles.actionBox}`}
                  >
                    <button
                      className={`${styles.saveBtn} mb-2`}
                      disabled={showSaveLoader === true}
                      // onClick={(e: any) => {
                      //   handleSaveEmailSignature(e);

                      // }}
                      onClick={onShow}
                    >
                      {showSaveLoader === true ? (
                        <Spinner
                          className="my-auto mx-1"
                          animation="border"
                          color="white"
                          size="sm"
                        />
                      ) : undefined}
                      Save Changes
                    </button>
                    <Modal
                      // backdropClassName={`${styles.modalBack}`}
                      show={showModal}
                      onHide={onHide}
                      dialogClassName={`${styles.modalDialog}`}
                      contentClassName={`${styles.modalContent}`}
                      centered={true}
                    >
                      <VariableModal onHide={onHide} handleOnSubmit={handleSaveEmailSignature} />
                    </Modal>
                  </div>
                ) : undefined}
              </>
            )}
          </div>
          <div className="col-md-6">
            <div className={`mb-3 mt-2 mt-md-0 ${styles.previewHeadText} `}>
              <span className="me-1">
                <i className="fa-solid fa-eye"></i>
              </span>
              <span className={`${styles.previewHead}`}>
                Preview: What your customers will see
              </span>
            </div>
            <div className={styles.previewBox}>
              <div className={styles.previewHeader}>
                <div>
                  <span className={styles.arrow}>
                    <i className="fa-solid fa-angle-left"></i>{" "}
                  </span>
                </div>
                <div>
                  <span className={`me-2 ${styles.arrow}`}>
                    <i className="fa-solid fa-angle-up"></i>
                  </span>
                  <span className={styles.arrow}>
                    <i className="fa-solid fa-angle-down"></i>
                  </span>
                </div>
              </div>
              <div className={styles.previewMain}>
                <div className="d-flex">
                  <div className={styles.profile}>
                    <span>LI</span>
                  </div>
                  <div>
                    <h4 className={styles.profileName}>Lorem Ipsum</h4>
                    <span className={styles.profileInfo}>
                      To: customer@gmail.com
                    </span>
                  </div>
                </div>
                <div className={styles.line}></div>
                <div>
                  <p className={styles.previewMessageFrom}>
                    Message by Lorem ipsum from {"["}
                    <strong> Brand name </strong>
                    {"]"}
                  </p>
                  <div className={styles.msgBox}> </div>
                  <div className={`${styles.msgBox} ${styles.box2}`}> </div>
                  <div className={`${styles.msgBox} ${styles.box3}`}> </div>
                  {isEnabled === true ? (
                    <div className={`mb-3 ${styles.sign}`}>
                      <ParseHTML
                        html={replaceLabelWithPlaceholder(
                          signatureText,
                          brandVariableLableAndValue
                        )}
                      />

                      {/* {signatureText} */}
                      {/* <p className={`${styles.sign}`}> Thanks,</p>
                      <p className={`${styles.sign}`}>Lorem Ipsum </p> */}
                    </div>
                  ) : undefined}

                  <div>
                    <button className={`${styles.replyBtn} me-3 mb-2 `}>
                      <i className={`fa-solid fa-reply mx-2`}></i>Reply
                    </button>
                    <button className={`${styles.replyBtn}`}>
                      <i className={`fa-solid fa-share mx-2`}></i>Forward
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const customStyles = {
  option: (provided: any, _: any) => ({
    ...provided,
    textAlign: "left",
    font: "normal normal normal 12px/18px Poppins",
    letterSpacing: "0px",
    color: "#000000",
  }),

  dropdownIndicator: (provided: any, _: any) => ({
    ...provided,
    color: "#000000",
  }),

  control: (provided: any, _: any) => ({
    ...provided,
    textAlign: "left",
    font: "normal normal normal 12px/18px Poppins",
    letterSpacing: "0px",
    color: "#000000",
  }),
  menuPortal: (provided: any) => ({ ...provided, zIndex: 9999 }),
};
export default EmailSignature;
