/**
 * This file is the service file used for making api request.
 * It contains the getAllCategory function service which is explained below.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { axiosKbPreview } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants"; // this is used for testing purpose only for frontend.

export interface GetAllCategoryPayload {
  start: number;
  limit: number;
  searchTerm?: string;
  integrationIdentifier: string;
  token?: string;
}

interface Category {
  categoryId: string | number;
  categoryName: string;
  createdAt: string;
  createdAtGmt: string;
  articleCount: number;
}

export interface GetAllCategoryRes {
  data: Category[];
  metaData: {
    totalCount: number;
    currentCount: number;
  };
}

/**
 * For testing frontend with own api link if backend is not prepared.
 */
const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/getAllCategory`
  : "/api/knowledgebase/helpCenterPage/";
// "/api/liveChatWidget/";

/**
 * This service is used get all the categories.
 */
export const getAllCategory = async (params: GetAllCategoryPayload) => {
  const { data: res } = await axiosKbPreview.post(
    `${apiEndPoint}${params.integrationIdentifier}/getAllCategory`,
    // `${apiEndPoint}${params.integrationIdentifier}/knowledgebase/getAllCategory`,
    {
      start: params.start,
      limit: params.limit,
      searchTerm: params.searchTerm || "",
    }
  );

  if (res.err) {
    return {
      err: res.err,
      tokenExpire: res.tokenExpire,
    };
  }

  return res as GetAllCategoryRes;
};
