import styles from "./UserInfo.module.scss";
import messenger from "src/assets/images/messenger.png";
import instagram from "src/assets/images/instagram.png";
import sms from "src/assets/images/smsIcon.png";
import whatsapp from "src/assets/images/whatsapp.png";
import { ISearchCustomerData } from "src/store/slices/innerTicket/innerTicket.slice";

interface Props {
  customerData: ISearchCustomerData;
  showCustomer: () => void;
}

const UserInfo = ({ customerData, showCustomer }: Props) => {
  return (
    <div
      className={`d-flex align-items-center justify-content-between mb-2 p-2 ${styles.userWrapper}`}
    >
      <div className="w-75">
        <h4 className={`mb-1 text-truncate ${styles.userName}`}>
          {customerData.name ?? "NA"}
        </h4>
        <span className={`text-truncate ${styles.userEmail}`}>
          {customerData.email ?? "NA"}
        </span>
        <div className="d-flex align-items-center mt-2">
          <span className={`${styles.mediaBox}`}>
            <span>
              <i className="fa-brands fa-whatsapp"></i>
            </span>
          </span>
          <span className={`${styles.mediaBox}`}>
            <img
              src={messenger}
              alt=""
            />
          </span>
          <span className={`${styles.mediaBox}`}>
            <img
              src={instagram}
              alt=""
            />
          </span>
        </div>
      </div>
      <div className="w-25 text-end">
        <span
          className={`pe-2 d-block cursor-pointer ${styles.arrowRight}`}
          onClick={showCustomer}
        >
          <i className="fa-solid fa-chevron-right"></i>
        </span>
      </div>
    </div>
  );
};

export default UserInfo;
