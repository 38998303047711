import { axiosJSON } from "src/globals/axiosEndPoints";


interface GetTicketChatFormDataParam{
  integrationId: string | number
}
type FormTypes = "preChatForm" | "postChatForm" | "ticketForm";

interface GetFormElementOptions{
  formType:  FormTypes 
}

export interface IChatFormElementOption{
  elementId : number | string;
  elementLabel : string;
  elementType : string;
  canHaveMultiple : boolean;
}

export interface IChatFormElementOptionList {
  formElementOptions : {[id: number | string] : IChatFormElementOption},
  formElementOptionIds : Array<number | string>,
}

export async function getTicketChatFormData(params: GetTicketChatFormDataParam) {
  const { data } = await axiosJSON.post(
    `/api/chatSetting/forms/getTicketForm`,
    params
  );
  if (data.error === true) {
    throw new Error(data.message as string);
  }

  return data.data;
}

export async function getFormElementOptions(params: GetFormElementOptions) {
  const { data } = await axiosJSON.get(
    `/api/chatSetting/forms/getFormElementOptions`,
    {params}
  );
  if (data.error === true) {
    throw new Error(data.message as string);
  }

  const formElementOptionIds: any = [];
  const formElementOptions: any = {};
  data.data.forEach((option: IChatFormElementOption ) => {
    formElementOptions[option.elementId] = option;
    formElementOptionIds.push(option.elementId);
  });

  return { formElementOptions, formElementOptionIds} as IChatFormElementOptionList;
}



