import { useCallback, useEffect, useId, useState } from "react";
import { Dropdown, Spinner } from "react-bootstrap";
import styles from "./FilterSelect.module.scss";
import Loader from "src/components/Loader";
import InfiniteScroll from "src/components/InfiniteScroll";
import { capitalizeFirstLetter } from "../../helper/helperFunction";

interface FilterSelectProps {
  filterName: string;
  allFilters: Record<string, { id: string; name: string }>;
  allFilterIds: string[];
  selectedFilters: string[];
  checkUncheckFilters: React.ChangeEventHandler<HTMLInputElement>;
  applyFilters?: () => void;
  cancelFilters?: () => void;
  nextPageLoad: () => void;
  isLoading?: boolean;
  isError?: boolean;
  hasNextPage?: boolean;
  fetchStatus?: string;
}

const FilterSelect = ({
  filterName,
  allFilters,
  allFilterIds,
  selectedFilters,
  checkUncheckFilters,
  applyFilters,
  cancelFilters,
  nextPageLoad,
  isError = false,
  hasNextPage = false,
  fetchStatus,
}: FilterSelectProps) => {
  const componentId = useId();
  const [ddOpen, setDdOpen] = useState(false);
  const [showLoadMore, setShowLoadMore] = useState(false);

  // fetch next page data and show loader
  const fetchNextPageHandler = useCallback(() => {
    nextPageLoad();
    setShowLoadMore(false);
  }, [nextPageLoad]);

  /**
   * Effect for changing load more button state
   */
  useEffect(() => {
    if (fetchStatus === "idle") {
      setShowLoadMore(true);
    }
  }, [fetchStatus]);

  /* Dropdown component from react-bootstrap */
  return (
    <Dropdown
      show={ddOpen}
      onToggle={setDdOpen}
    >
      {/* Dropdown toggle button */}
      <Dropdown.Toggle
        as="div"
        bsPrefix={`dropdown-toggle cursor-pointer border-0 px-2 py-1 me-2  mb-2 mb-lg-0 ${styles.filterButton}`}
      >
        {filterName}
        {selectedFilters.length ? (
          <span className={`mx-1 ${styles.selectedNum}`}>
            {String(selectedFilters.length).padStart(2, "0")}
          </span>
        ) : (
          ""
        )}
        {/* Display dropdown arrow */}
        <span className="ps-1">
          <i className={`fa-solid fa-caret-${ddOpen ? "up" : "down"}`}></i>
        </span>
      </Dropdown.Toggle>

      {/* Dropdown menu */}
      <Dropdown.Menu bsPrefix={`dropdown-menu border-0 ${styles.filterMenu}`}>
        <div className={`${styles.dropMenu}`}>
          {/* List of filters */}
          <div className={`${styles.itemWrapper}`}>
            <div>
              {fetchStatus === "fetching" && allFilterIds.length === 0 ? (
                <div className={styles.loaderMargin}>
                  <Loader />
                </div>
              ) : (
                <InfiniteScroll
                  loadMore={
                    fetchStatus === "idle" ? fetchNextPageHandler : () => {}
                  }
                  hasMore={hasNextPage}
                  initialDataLoaded={true}
                  className={`${styles.filterList}`}
                  loader={
                    <div>
                      {showLoadMore ? (
                        // Rendering the load button conditionally.
                        <div
                          className={`${styles.loaderText}`}
                          onClick={fetchNextPageHandler}
                        >
                          <span>Load More</span>
                          <i className="ms-1 fa-solid fa-rotate-right"></i>
                        </div>
                      ) : (
                        // Rendering the load text conditionally.
                        <div
                          className={`${styles.loadingText} d-flex align-items-center`}
                        >
                          <span>Loading...</span>
                          <div>
                            <Spinner
                              className={`ms-1 ${styles.spinner}`}
                              size="sm"
                              animation="border"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  }
                >
                  {isError ? (
                    <span
                      className={`d-flex align-items-center justify-content-center mt-3 ${styles.errorMessage}`}
                    >
                      Something went worng...
                    </span>
                  ) : (
                    allFilterIds.map((categoryId: any) => {
                      return (
                        <div
                          className={`${styles.specificItem}`}
                          key={categoryId}
                        >
                          <div
                            className={`form-check`}
                            key={categoryId}
                          >
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id={componentId + "selectFilter" + categoryId}
                              value={categoryId}
                              checked={selectedFilters.includes(
                                categoryId + "",
                              )}
                              onChange={checkUncheckFilters}
                            />
                            <label
                              className={`cursor-pointer form-check-label w-100 pe-2 ${styles.checktext}`}
                              id={componentId + "selectFilter" + categoryId}
                              htmlFor={
                                componentId + "selectFilter" + categoryId
                              }
                            >
                              {/* Display user information */}
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="w-100 text-truncate">
                                  <span className="ps-2">
                                    {capitalizeFirstLetter(
                                      allFilters[categoryId].name,
                                    )}
                                  </span>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>
                      );
                    })
                  )}
                </InfiniteScroll>
              )}
            </div>
          </div>
        </div>
        {/* Cancel and apply button */}
        {applyFilters || cancelFilters ? (
          <div className="d-flex justify-content-between align-items-center mt-3">
            {cancelFilters ? (
              <button
                className={`${styles.cancelBtn}`}
                onClick={() => {
                  cancelFilters();
                  setDdOpen(false);
                }}
              >
                Cancel
              </button>
            ) : (
              ""
            )}
            {applyFilters ? (
              <button
                className={`${styles.applyBtn}`}
                onClick={() => {
                  applyFilters();
                  setDdOpen(false);
                }}
              >
                Apply
              </button>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default FilterSelect;
