import { useEffect, useRef, useState } from "react";
import { Modal, Overlay } from "react-bootstrap";
import { BrandAgent } from "src/services/Settings/Brands/getBrand";
import {
  addUserToModifiedUsers,
  getBrandModifiedAssignToUsersIdsSelector,
  getBrandSelectedUserTicketCountsSelector,
  isAllSelectedBrandAgentDisabledSelector,
  isAllSelectedBrandAgentEnabledSelector,
  removeAllUsersFromBulkSelect,
  selectAllUsersFromBulkSelect,
  setChildModal,
} from "src/store/slices/settings/brands/brandSettings.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import Style from "../BrandUserBulkActionSelection/BrandUserBulkAction.module.scss";
import DisableAgentModal from "../SetAgent/Children/AgentRow/Children/DisableAgentModal/DisableAgentModal";

const BrandUserBulkAction = (props: any) => {
  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [showEnableBtn, setShowEnableBtn] = useState(false);
  const currentUserData = useAppSelector(
    (state) => state.globals.currentUserData
  );
  const { bulkSelectedUserIds, activeBrandId, users, modifiedUsersList } =
    useAppSelector((state) => state.brandSettings);

  const brandSelectedUserTicketCounts = useAppSelector(
    getBrandSelectedUserTicketCountsSelector
  );
  const isAllSelectedBrandAgentDisabled = useAppSelector(
    isAllSelectedBrandAgentDisabledSelector
  );
  const isAllSelectedBrandAgentEnabled = useAppSelector(
    isAllSelectedBrandAgentEnabledSelector
  );

  const modifiedAssignToUserIds = useAppSelector(
    getBrandModifiedAssignToUsersIdsSelector
  );

  const target = useRef(null);
  // send dispatch of clearing all ticket ids selected
  const handleOnClickCross = (e: any) => {
    e.preventDefault();
    dispatch(removeAllUsersFromBulkSelect());
  };

  const handleOnSelectAll = () => {
    dispatch(selectAllUsersFromBulkSelect());
  };

  const handleOnUnselectAll = () => {
    dispatch(removeAllUsersFromBulkSelect());
  };

  const handleSubmit = (selectedAgent: BrandAgent | null, enabled = false) => {
    let updatedAgent: any = {};

    bulkSelectedUserIds.map((userId) => {
      const user = users[userId] ?? undefined;

      let allDependentUserSelected = true;

      if (enabled == false && modifiedAssignToUserIds.includes(userId)) {
        const allUsers = Object.values(modifiedUsersList).filter(
          (user) => user.assignTo == userId
        );

        allUsers.every((user) => {
          if (!bulkSelectedUserIds.includes(user.id)) {
            allDependentUserSelected = false;
            return false;
          }
          return true;
        });
      }

      if (allDependentUserSelected) {
        let ticketCount =
          user.ticketCount && user.ticketCount !== 0
            ? parseInt(user.ticketCount + "")
            : 0;
        updatedAgent[userId] = {
          id: userId,
          enabled: enabled,
          ticketCount: ticketCount,
          assignTo:
            selectedAgent && selectedAgent.id != 0 && ticketCount !== 0
              ? selectedAgent.id
              : null,
        };
      }
    });

    dispatch(addUserToModifiedUsers(updatedAgent));
    dispatch(removeAllUsersFromBulkSelect());
    setShowModal(false);
    dispatch(setChildModal(false));
  };

  const handleOnDisable = (value: boolean) => {
    //checking if all agent disabled
    if (value) {
      //enable all
      handleSubmit(null, true);
    } else {
      //disable all
      if (brandSelectedUserTicketCounts === 0) {
        handleSubmit(null);
      } else {
        setShowModal(true);
      }
    }
  };

  useEffect(() => {
    dispatch(setChildModal(showModal));
  }, [showModal]);

  useEffect(() => {
    if (isAllSelectedBrandAgentDisabled) {
      setShowEnableBtn(true);
    } else {
      setShowEnableBtn(false);
    }
    setShowOverlay(false);
  }, [isAllSelectedBrandAgentDisabled, isAllSelectedBrandAgentEnabled]);

  return (
    <div className={`${Style.actionSection}`}>
      <div className={`${Style.actionBox}`}>
        <div className="d-flex justify-content-between">
          <p className="me-3">{bulkSelectedUserIds.length} Selected</p>
          <div className="d-flex justify-content-between flex-md-row flex-column flex-grow-1 me-3 ms-3">
            <div className="mb-1 mb-md-0">
              <span
                role={"button"}
                className={`${Style.selectAll} me-2`}
                onClick={handleOnSelectAll}
              >
                Select All
              </span>
              <span className={`${Style.verticleLine} me-2`}>|</span>
              <span
                role={"button"}
                className={`${Style.selectAll}`}
                onClick={handleOnUnselectAll}
              >
                Unselect All
              </span>
            </div>
            <div
              role={"button"}
              className={`${Style.disableAll} d-flex justify-content-center align-items-center`}
            >
              <div
                className="p-2 d-flex align-items-center"
                onClick={() => handleOnDisable(showEnableBtn)}
              >
                <div className="form-check form-switch">
                  <input
                    className={`me-1 form-check-input ${Style.switchBox} cursor-pointer`}
                    type="checkbox"
                    role="switch"
                    id={"flexSwitchCheckDefault"}
                    checked={!showEnableBtn}
                  />
                </div>
                <span className={`${Style.permitText}`}>
                  {" "}
                  {showEnableBtn === true ? "Enable All" : "Disable All"}
                </span>
              </div>
              {isAllSelectedBrandAgentDisabled === false &&
              isAllSelectedBrandAgentEnabled === false ? (
                <>
                  <div
                    ref={target}
                    className={`p-2 d-flex flex-column justify-content-center h-100`}
                    onClick={() => setShowOverlay(!showOverlay)}
                  >
                    <i
                      className={`fa-solid fa-caret-${
                        showOverlay === true ? "down" : "up"
                      }`}
                      style={{ color: "#000" }}
                    ></i>
                    <Overlay
                      target={target}
                      show={showOverlay}
                      placement="top-end"
                    >
                      {({
                        placement,
                        arrowProps,
                        show: _show,
                        popper,
                        ...props
                      }) => (
                        <div {...props} className={`mb-1 p-1 ${Style.overlay}`}>
                          <div
                            className={`btn d-flex px-2 py-1 ${Style.item}`}
                            onClick={(e) => {
                              // setSendAs(data.statusName)
                              setShowEnableBtn(!showEnableBtn);
                            }}
                          >
                            {!showEnableBtn === true
                              ? "Enable All"
                              : "Disable All"}
                          </div>
                        </div>
                      )}
                    </Overlay>
                  </div>
                </>
              ) : null}
            </div>
          </div>

          <span className={`${Style.actionClose} ms-3`}>
            {" "}
            <i className="fa fa-solid fa-xmark" onClick={handleOnClickCross} />
          </span>
        </div>
        <div className={`${Style.actionBtnsContainer}`}></div>
      </div>
      <Modal
        backdropClassName={`${Style.modalBack} d-none`}
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName={`${Style.modalDialog}`}
        contentClassName={`${Style.modalContent}`}
        centered={true}
        enforceFocus={false}
        style={{ zIndex: 1058 }}
      >
        <DisableAgentModal
          brandId={activeBrandId}
          deleteAjaxStatus={"fulfilled"}
          handleSubmit={handleSubmit}
          handleCancel={() => setShowModal(false)}
        />
      </Modal>
    </div>
  );
};
export default BrandUserBulkAction;
