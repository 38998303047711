import styles from "./BrandRow.module.scss";
import edit from "src/assets/images/chat/edit.png";
import editBrand from "src/assets/images/chat/editBrand.png";
import deleteBrandImg from "src/assets/images/chat/delete.png";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useMemo, useState } from "react";
import BrandModal from "src/components/BrandModal/BrandModal";
import DeleteBrandModal from "src/components/DeleteBrandModal/DeleteBrandModal";
import DeactivateBrand from "src/components/DeactivateBrand/DeactivateBrand";
import AxiosImg from "src/components/AxiosImg";
import UserAvatar from "src/components/UserAvatar";
import { useAppDispatch, useAppSelector } from "src/store/store";

import {
  deleteBrand,
  fetchAllBrands,
  resetBrandData,
  updateBrand,
} from "src/store/slices/settings/brands/brandSettings.slice";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { BrandData } from "src/services/Settings/Brands/getAllBrands";
import ParentModal from "src/components/BrandModal/ParentModal.module.scss";
import ParseHTML from "src/components/ParseHTML";
import { BsExclamationDiamondFill } from "react-icons/bs";
import { NavLink } from "react-router-dom";
import { fetchUser } from "src/store/slices/settings/profile/profileSettings.slice";
interface Props {
  brandId: number | string;
  brandImg: string | null;
  brandName: string;
  isDefault: boolean;
  brandEmail: string;
  signature: string;
  isActive: boolean;
  brandData: BrandData;
  isVerified: boolean;
  isPublicAttachmentUrl?: boolean;
}
const BrandRow = ({
  brandId,
  brandImg,
  brandName,
  isDefault,
  brandEmail,
  signature,
  isActive,
  isVerified,
  brandData: brandData,
}: Props) => {
  const {
    brandDataAjaxStatus,
    brandIds,
    metaData,
    brands,
    deleteBrandAjaxStatus,
    updateBrandAjaxStatus,
    childModalIsOpened,
  } = useAppSelector((state) => state.brandSettings);
  const scopes = useAppSelector(
    (state) => state.globals.currentUserData?.scopes,
  );
  const dispatch = useAppDispatch();

  const [enabled, setEnabled] = useState(isActive);
  const [showModal, setShowModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deactivateModal, setDeactivateModal] = useState(false);
  const onShow = (e: any) => {
    e.stopPropagation();
    setShowModal(true);
    // console.log("show");
  };
  const onHide = () => {
    setShowModal(false);
    // console.log("hide");
  };
  // function to show delete brand modal
  const showDeleteModal = (e: any) => {
    if (
      !isDefault &&
      scopes &&
      scopes.includes("settings_can_edit_email_signature")
    ) {
      e.stopPropagation();
      setDeleteModal(true);
    }
    // console.log("show");
  };
  // Function to hide Delete Brand modal
  const hideDeleteModal = () => {
    setDeleteModal(false);
    // console.log("hide");
  };
  // function to show deactivate brand modal
  const showDeactivateModal = (e: any) => {
    e.stopPropagation();
    setDeactivateModal(true);
    // console.log("show");
  };
  // Function to hide Deactivate Brand modal
  const hideDeactivateModal = () => {
    setDeactivateModal(false);
    // console.log("hide");
  };

  const handleOnDelete = () => {
    if (scopes && scopes.includes("settings_can_edit_email_signature")) {
      if (deleteBrandAjaxStatus !== "pending") {
        dispatch(
          deleteBrand({
            id: brandId,
            callback: () => {
              pushTheToast({
                type: "success",
                text: "brand successfully removed",
                position: "top-right",
              });
              dispatch(resetBrandData());
              dispatch(fetchAllBrands());
              dispatch(fetchUser());
              hideDeleteModal();
            },
          }),
        );
      }
    }
  };

  const handleOnEdit = () => {};

  const submitBrandUpdate = (value: boolean) => {
    if (updateBrandAjaxStatus !== "pending") {
      let toastText =
        value === true
          ? "brand successfully activated!"
          : "brand successfully deactivated!";
      //updating the brand
      dispatch(
        updateBrand({
          id: brandId,
          isActive: value,
          callback: () => {
            pushTheToast({
              type: "success",
              text: toastText,
              position: "top-right",
            });
            hideDeactivateModal();
          },
        }),
      );
      setEnabled(value);
    }
  };

  const CompleteEmailSetupURL = useMemo(() => {
    if (brandData.emailIntegrationId) {
      return `/settings/integrations/email/${brandData.emailIntegrationId}/verifyEmail`;
    } else {
      return `/settings/integrations/email`;
    }
  }, [brandData.emailIntegrationId]);

  return (
    <>
      <div>
        <div
          className={`py-2 px-4 mt-2 d-flex position-relative ${styles.tableHeader} justify-content-between`}
        >
          <div className={`${styles.showBar}`}></div>
          <div className={`${styles.brand}`}>
            <span className={`${styles.headerText}`}>
              {/* <img src={brandImg} alt="" className={`${styles.logo}`} /> */}
              {brandImg && brandImg.length !== 0 ? (
                <AxiosImg
                  url={brandImg}
                  className={`rounded-circle ${styles.logo}`}
                  isDirectURL={brandData?.isPublicAttachmentUrl}
                />
              ) : (
                <UserAvatar
                  name={brandName ?? "NA"}
                  size={32}
                />
              )}
              <span className={`ms-2 ${styles.brandName}`}>{brandName}</span>
              <span className={`ps-1 ${styles.default}`}>
                {isDefault === true ? "(By default)" : ""}{" "}
              </span>
            </span>
          </div>
          <div className={`px-2 ${styles.permission} px-4`}>
            <span className={`${styles.flag}`}>
              {" "}
              {isVerified ? "Verified" : "Not Verified"}{" "}
            </span>
          </div>
          <div className={`px-2 d-flex ${styles.email}`}>
            {brandData.isVerified === false ? (
              <span className={`${styles.caution}`}>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className={` ${styles.toolTipCustom}`}>
                      E-mail setup for this e-mail is not complete yet . Please
                      complete e-mail integration setup to send and receive
                      messages into helplama helpdesk for this e-mail
                    </Tooltip>
                  }
                >
                  {({ ref, ...triggerHandler }) => (
                    <span
                      {...triggerHandler}
                      ref={ref}
                      className={`cursor-pointer ${styles.closeBtn}`}
                    >
                      <BsExclamationDiamondFill />
                    </span>
                  )}
                </OverlayTrigger>
              </span>
            ) : null}
            <div className={`ps-1 ${styles.setupWrapper}`}>
              <span className={`text-truncate ${styles.emailText}`}>
                {" "}
                {brandEmail}{" "}
              </span>

              {brandData.isVerified === false ? (
                <NavLink to={CompleteEmailSetupURL}>
                  <div
                    className={`d-flex align-items-center ${styles.setupText}`}
                  >
                    <span>Complete email setup </span>

                    <div>
                      {" "}
                      <span className="ps-1 d-inline-flex">
                        <i className="fa-solid fa-angle-right"></i>
                      </span>
                    </div>
                  </div>
                </NavLink>
              ) : null}
            </div>
          </div>
          {/* <div className={`px-2 ${styles.sign}`}> */}
          {/* <ParseHTML
              className={`${styles.signText}`}
              html={signature ?? ""}
            /> */}
          {/* <span className={`${styles.signText}`}> {signature} </span> */}
          {/* </div> */}
          <div className={`px-2 ${styles.permission}`}>
            <span className={`${styles.flag}`}>
              {brandData?.hasPermission === true ? "Enabled" : "Disabled"}
            </span>
          </div>
          {scopes && scopes.includes("settings_can_edit_email_signature") ? (
            <div className={`px-2 ${styles.actions}`}>
              <div className={`d-flex align-items-center`}>
                {/* <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip className={` ${styles.toolTipCustom}`}>Edit</Tooltip>
                }
              >
                {({ ref, ...triggerHandler }) => (
                  <span
                    className={`d-inline-block cursor-pointer me-2 ${styles.edit}`}
                    onClick={onShow}
                  >
                    <img src={edit} ref={ref} alt="edit" />
                  </span>
                )}
              </OverlayTrigger> */}
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip className={` ${styles.toolTipCustom}`}>
                      Edit
                    </Tooltip>
                  }
                >
                  {({ ref, ...triggerHandler }) => (
                    <span
                      {...triggerHandler}
                      ref={ref}
                      className={`d-inline-block cursor-pointer me-2 ${styles.edit}`}
                      onClick={onShow}
                    >
                      <img
                        src={edit}
                        ref={ref}
                        alt="edit"
                      />
                    </span>
                  )}
                </OverlayTrigger>
                <Modal
                  backdropClassName={`${styles.modalBack} ${
                    childModalIsOpened ? `${styles.parentModalOverly}` : ""
                  }`}
                  show={showModal}
                  onHide={onHide}
                  dialogClassName={`${styles.modalDialog}`}
                  contentClassName={`${styles.modalContent}`}
                  centered={false}
                  enforceFocus={false}
                >
                  <BrandModal
                    brandData={brandData}
                    onHide={onHide}
                  />
                </Modal>
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip className={` ${styles.toolTipCustom}`}>
                      Delete
                    </Tooltip>
                  }
                >
                  {({ ref, ...triggerHandler }) => (
                    <span
                      {...triggerHandler}
                      ref={ref}
                      className={`d-inline-block cursor-pointer d-inline-block px-2 ${styles.delete}`}
                      onClick={showDeleteModal}
                    >
                      {/* <i className="fa-solid fa-trash"></i> */}
                      <img
                        src={deleteBrandImg}
                        alt="delete"
                      />
                    </span>
                  )}
                </OverlayTrigger>
                <Modal
                  backdropClassName={`${styles.modalBack}`}
                  show={deleteModal}
                  onHide={hideDeleteModal}
                  dialogClassName={`${styles.deleteDialog}`}
                  contentClassName={`${styles.deleteContent}`}
                  centered={false}
                  enforceFocus={false}
                >
                  <DeleteBrandModal
                    handleCancel={() => hideDeleteModal()}
                    handleSubmit={() => handleOnDelete()}
                    deleteAjaxStatus={deleteBrandAjaxStatus}
                  />
                </Modal>
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip className={` ${styles.toolTipCustom}`}>
                      {enabled ? "Activated" : "Deactivated"}
                    </Tooltip>
                  }
                >
                  {({ ref, ...triggerHandler }) => (
                    <div
                      className="form-check form-switch"
                      {...triggerHandler}
                      ref={ref}
                      // onClick={showDeactivateModal}
                    >
                      <input
                        className={`form-check-input cursor-pointer ${styles.switchBox}`}
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckDefault"
                        disabled={
                          isDefault ||
                          ((scopes &&
                            scopes.includes(
                              "settings_can_edit_email_signature",
                            )) === true
                            ? false
                            : true)
                        }
                        checked={enabled}
                        onChange={(e) => {
                          if (!enabled) {
                            submitBrandUpdate(true);
                          } else {
                            if (updateBrandAjaxStatus !== "pending") {
                              showDeactivateModal(e);
                            }
                          }
                        }}
                      />
                    </div>
                  )}
                </OverlayTrigger>
                <Modal
                  backdropClassName={`${styles.modalBack}`}
                  show={deactivateModal}
                  onHide={hideDeactivateModal}
                  dialogClassName={`${styles.deleteDialog}`}
                  contentClassName={`${styles.deleteContent}`}
                  centered={false}
                  enforceFocus={false}
                >
                  <DeactivateBrand
                    handleCancel={() => hideDeactivateModal()}
                    handleSubmit={() => submitBrandUpdate(false)}
                    updateBrandAjaxStatus={updateBrandAjaxStatus}
                  />
                </Modal>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};
export default BrandRow;
