import React from "react";
import { AJAXSTATUS } from "src/globals/constants";
import { useAppSelector } from "src/store/store";
import {
  convertGmtToUserTimezone,
  getTimeFromNow,
} from "src/utils/dateLibrary";
import styles from "./PickBox.module.scss";
interface Prop {
  chatId: number | string;
  customerName: string;
  waitingSinceDateTime?: string | null;
  onClick: (chatId: number | string) => void;
  ajaxStatus: AJAXSTATUS;
}

const PickBox = ({
  chatId,
  customerName,
  waitingSinceDateTime,
  onClick,
  ajaxStatus,
}: Prop) => {
  const userTimezone = useAppSelector(
    (state) => state.globals.currentUserData?.userTimezone
  );

  return (
    <div className={`${styles.pickBox} py-4 px-2 text-center`}>
      <p className={`pt-2 ${styles.pickHead}`}>
        {waitingSinceDateTime ? (
          <>
            {customerName + " is waiting in queue for "}
            <span className={`${styles.time}`}>
              {waitingSinceDateTime !== undefined &&
              waitingSinceDateTime !== null
                ? getTimeFromNow({
                    need: "time",
                    date: new Date(
                      convertGmtToUserTimezone(
                        waitingSinceDateTime,
                        userTimezone
                      )
                    ),
                    timeZone: userTimezone,
                    showAgo: false,
                    showJustNow: false,
                    secondString: {
                      secondText: " sec",
                      secondsText: " secs",
                    },
                  })
                : getTimeFromNow({
                    need: "time",
                    date: new Date(),
                    showAgo: false,
                    showJustNow: false,
                  })}
            </span>
          </>
        ) : (
          <>This chat is unassigned</>
        )}
      </p>
      <div className="pt-3 pb-2">
        {ajaxStatus == "pending" ? (
          <button
            className={`border-0 py-2 px-4 ${styles.pickBtn} ${styles.notActive}`}
            disabled={true}
          >
            Starting…..
          </button>
        ) : (
          <button
            className={`border-0 py-2 px-4 ${styles.pickBtn}`}
            onClick={(e) => {
              onClick(chatId);
            }}
          >
            Accept Chat
          </button>
        )}
      </div>
    </div>
  );
};

export default PickBox;
