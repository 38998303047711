import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";
import {
  CreateBotProfileResponse,
  CreateProfileAudienceConfig,
} from "./createBotProfile.service";
import { UserDetails } from "./getAllBotProfiles.service";

const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/botProfile/update`
  : "/api/botProfile/update";

export interface UpdateBotProfileParams {
  name?: string;
  status?: string;
  brand_id?: string;
  channels?: number[] | string[];
  introduction_message?: string;
  eligible_users?: string[];
  audience_config?: any;
  need_user_details?: boolean;
  bot_answer_strategy?: string;
  user_details?: UserDetails[];
  answer_content?: number[];
  collect_details_from_user?: boolean;
  id: number;
  move_to_next?: string;
  force?: boolean;
  email_inbound?: string;
  email_handle_ticket_assigned_to_agents?: string;
  email_automatically_close_ticket?: boolean;
  email_followup_configure?: boolean;
  email_followup_configure_model?: {
    salutation: string;
    body: string;
  };
  email_configure_model?: {
    salutation: string;
    introduction: string;
    questionRegardingResolution: string;
  };
  isConfigureAdvancedBotSettings?: boolean;
  configureAdvancedBotSettingsModel?: {
    isCustomTemperatureEnabled: boolean;
    customTemperature: number;
    optimismLevelForBotLanguage: "optimistic" | "neutral" | "cautious";
    energyLevelForBotResponse: "energetic" | "balanced" | "calm";
    isToneAdjustmentForBotResponse: boolean;
    toneForBotResponse:
      | "professional"
      | "friendly"
      | "casual"
      | "enthusiastic"
      | "empathetic";
    customToneInstructionEnabled: boolean;
    customToneInstructionMessage?: string;
    contentRelatedInstructionEnabled: boolean;
    signOffInstruction?: string;
    topicAvoidance?: string[];
    personalizeMessage?: string;
  };
  useAdditionalCustomerInformation?: boolean;
}

export const updateBotProfile = async (params: UpdateBotProfileParams) => {
  const { data: res } = await axiosJSON.post(apiEndPoint, params);
  if (res.err) {
    throw res.msg;
  }

  return res as CreateBotProfileResponse;
};
