import { useCallback, useMemo, useRef, useState } from "react";
import { Dropdown, Spinner } from "react-bootstrap";
import AxiosImg from "src/components/AxiosImg";
import SearchBar from "src/components/SearchBar/SearchBar";
import UserAvatar from "src/components/UserAvatar";
import { UserAgent } from "src/services/Automation/DispatchRulesConfigModal/getAllAgents.service";
import { ListOption } from "src/services/Automation/DispatchRulesConfigModal/getDispatchRuleConfigById.service";
import { DispatchRuleTeamData } from "src/services/Automation/DispatchRulesConfigModal/getTeamsData.service";
import { useDispatchSectionContext } from "../../Hooks/useDispatchSectionContext";
import styles from "./DispatchTo.module.scss";
import DispatchToRule from "./DispatchToRule/DispatchToRule";
import useAgentData from "./useAgentData";

interface AddNewDDProps {
  isDisabled: boolean;
  handleAddAgent: (data: UserAgent) => void;
  handleAddTeam: (data: DispatchRuleTeamData) => void;
  allAgents: Record<string, UserAgent>;
  agentIdsToFilter: string[];
  teamIdsToFilter: string[];
  allTeams: Record<string, DispatchRuleTeamData>;
  allAgentIds: string[];
  allTeamIds: string[];
  refetch: () => void;
  isAgentsLoading: boolean;
  isTeamsLoading: boolean;
}

const AddNewDD = ({
  isDisabled,
  handleAddAgent,
  handleAddTeam,
  allAgentIds,
  allAgents,
  agentIdsToFilter,
  teamIdsToFilter,
  allTeamIds,
  allTeams,
  refetch,
  isAgentsLoading,
  isTeamsLoading,
}: AddNewDDProps) => {
  const [search, setSearch] = useState("");

  // Filtering for selected agents and teams
  const { filterSelectedAgents, filterSelectedTeams } = useMemo(() => {
    const filterSelectedTeams = allTeamIds.filter(
      (id) => !teamIdsToFilter.includes(id),
    );

    const filterSelectedAgents = allAgentIds.filter(
      (id) => !agentIdsToFilter.includes(id),
    );

    return { filterSelectedTeams, filterSelectedAgents };
  }, [agentIdsToFilter, allAgentIds, allTeamIds, teamIdsToFilter]);

  // Filtering for search
  const { filteredAgents, filteredTeams } = useMemo(() => {
    const lowerSearch = search.toLowerCase();

    const filteredTeams = filterSelectedTeams.filter((id) =>
      allTeams[id].teamName.toLowerCase().includes(lowerSearch),
    );
    const filteredAgents = filterSelectedAgents.filter((id) =>
      allAgents[id].name.toLowerCase().includes(lowerSearch),
    );
    return { filteredTeams, filteredAgents };
  }, [search, filterSelectedTeams, filterSelectedAgents, allTeams, allAgents]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownArrowSpanRef = useRef<any>();
  return (
    <div className="d-flex pt-2">
      <Dropdown
        onToggle={(val) => {
          if (val === true) {
            refetch();
          }
          setIsDropdownOpen(val);
        }}
      >
        <Dropdown.Toggle
          id="dropdown-basic"
          as="div"
          disabled={isDisabled}
          bsPrefix={`dropdown-toggle cursor-pointer ${styles.dropBtn} ${
            isDropdownOpen ? styles.activeBtn : ""
          }`}
          ref={dropdownArrowSpanRef}
        >
          <div className="d-flex align-items-center">
            <div
              className={`cursor-pointer  me-2 d-flex align-items-center ${styles.addMoreBtn}`}
            >
              <span className="">
                {" "}
                <i className="fa-solid fa-plus"></i>
              </span>
            </div>

            <div className="">
              <span className={`${styles.addMoreText}`}>Add new</span>
            </div>
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu bsPrefix={`dropdown-menu ${styles.dropMenu}`}>
          <div>
            <SearchBar
              className={`${styles.search} px-2`}
              inputClassName={`${styles.input}`}
              placeholder={`Search team or agents`}
              value={search}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                setSearch(e.target.value)
              }
            />
            <div className="mt-3">
              <h4 className={`mb-0 ${styles.externalText} d-none`}>
                External user <span>+Add external user details</span>
              </h4>
              <p className={`mb-1 ${styles.noExternalText} d-none`}>
                No eternal user exists yet
              </p>
              <div>
                <h4 className={`mb-0 ${styles.externalText}`}>
                  Team{" "}
                  <a
                    href="/settings/Teams"
                    target="_blank"
                  >
                    +Configure team
                  </a>
                </h4>
                {isTeamsLoading ? (
                  <div>
                    <Spinner
                      className="mx-auto"
                      size="sm"
                    />
                  </div>
                ) : (
                  filteredTeams.map((id) => {
                    const team = allTeams[id];

                    return (
                      <Dropdown.Item
                        className={styles.userData}
                        key={team.teamId}
                        onClick={() => handleAddTeam(team)}
                      >
                        <div
                          className={`d-flex align-items-center ${styles.userData}`}
                        >
                          <UserAvatar
                            name={team.teamName}
                            size={21}
                          />
                          <span className={`${styles.userName}`}>
                            {team.teamName}
                          </span>
                        </div>
                      </Dropdown.Item>
                    );
                  })
                )}
              </div>
              <div>
                <h4 className={`mb-0 ${styles.externalText}`}>
                  Agents{" "}
                  <a
                    href="/settings/User"
                    target="_blank"
                  >
                    +Add agent
                  </a>
                </h4>
                {isAgentsLoading ? (
                  <div>
                    <Spinner
                      className="mx-auto"
                      size="sm"
                    />
                  </div>
                ) : (
                  filteredAgents.map((agentId) => {
                    const agent = allAgents[agentId];

                    return (
                      <Dropdown.Item
                        className={styles.userData}
                        key={agent.id}
                        onClick={() => handleAddAgent(agent)}
                      >
                        <div
                          className={`d-flex align-items-center justify-content-between ${styles.userData}`}
                        >
                          <div>
                            {agent.imageUrl && agent.imageUrl.trim() !== "" ? (
                              <AxiosImg
                                url={agent.imageUrl}
                                className={`rounded-circle`}
                                style={{ width: "20px", height: "20px" }}
                              />
                            ) : (
                              <UserAvatar
                                name={agent.name}
                                size={21}
                              />
                            )}
                            <span className={`${styles.userName}`}>
                              {agent.name}
                            </span>
                          </div>
                          <div className={styles.agentRole}>
                            <span>{agent.roleName}</span>
                          </div>
                        </div>
                      </Dropdown.Item>
                    );
                  })
                )}
              </div>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

function extractUniqueAvailableChannels(userAgents: UserAgent[]): ListOption[] {
  const channelMap = new Map<string, ListOption>();

  userAgents.forEach((userAgent) => {
    userAgent.availableChannels.forEach((channel) => {
      if (!channelMap.has(channel.channelId)) {
        channelMap.set(channel.channelId, {
          id: channel.channelId,
          name: channel.channelName,
        });
      }
    });
  });

  return Array.from(channelMap.values());
}

export interface DispatchToProps {
  isDisabled: boolean;
}

const DispatchTo = ({ isDisabled }: DispatchToProps) => {
  const { updateState, state, automationType, showErrors, setShowErrors } =
    useDispatchSectionContext();
  const { dispatchToDetails, integrationId } = state;

  const {
    allAgents,
    allTeams,
    allAgentIds,
    allTeamIds,
    refetch,
    isAgentsLoading,
    isTeamsLoading,
  } = useAgentData(integrationId, automationType);

  const handleAddTeam = useCallback(
    (data: DispatchRuleTeamData) => {
      const channels = extractUniqueAvailableChannels(data.userDetails);

      updateState({
        dispatchToDetails: [
          ...dispatchToDetails,
          {
            teamDetails: {
              id: data.teamId + "",
              name: data.teamName,
            },
            agentDetails: data.userDetails,
            channelDetails: channels,
          },
        ],
      });

      setShowErrors(false);
    },
    [dispatchToDetails, setShowErrors, updateState],
  );

  const handleAddAgent = useCallback(
    (data: UserAgent) => {
      const channels = extractUniqueAvailableChannels([data]);

      updateState({
        dispatchToDetails: [
          ...dispatchToDetails,
          {
            agentDetails: [data],
            channelDetails: channels,
          },
        ],
      });
      setShowErrors(false);
    },
    [dispatchToDetails, setShowErrors, updateState],
  );

  const agentIdsToFilter = useMemo(
    () =>
      dispatchToDetails
        .filter((rule) => rule.agentDetails.length === 1 && !rule.teamDetails)
        .flatMap((rule) => rule.agentDetails.map((val) => val.id + "")),
    [dispatchToDetails],
  );

  const teamIdsToFilter = useMemo(
    () =>
      dispatchToDetails.map((rule) =>
        rule.teamDetails ? rule.teamDetails.id + "" : "",
      ),
    [dispatchToDetails],
  );

  return (
    <div className={`w-100 ${styles.ruleBox}`}>
      <div className={`${styles.innerBox}`}>
        <h3 className={`${styles.heading}`}>Dispatch ticket to:</h3>
        {dispatchToDetails.length === 0 ? (
          <p className={`${styles.subHeading}`}>
            No contacts added for dispatching
          </p>
        ) : (
          dispatchToDetails.map((data, index) => {
            const availableChannelIds = data.agentDetails.flatMap(
              ({ id }) =>
                allAgents[id]?.availableChannels.map(
                  (ch) => ch.channelId + "",
                ) || [],
            );

            return (
              <DispatchToRule
                dispatchRule={data}
                index={index}
                availableChannelIds={availableChannelIds}
                allAgents={allAgents}
                allTeams={allTeams}
                key={index}
                isDisabled={isDisabled}
              />
            );
          })
        )}

        <AddNewDD
          isDisabled={isDisabled}
          refetch={refetch}
          handleAddAgent={handleAddAgent}
          handleAddTeam={handleAddTeam}
          allAgentIds={allAgentIds}
          agentIdsToFilter={agentIdsToFilter}
          teamIdsToFilter={teamIdsToFilter}
          allAgents={allAgents}
          allTeamIds={allTeamIds}
          allTeams={allTeams}
          isAgentsLoading={isAgentsLoading}
          isTeamsLoading={isTeamsLoading}
        />
        {showErrors && !dispatchToDetails.length && (
          <small className={styles.errorMsg}>
            Please select one team or agent
          </small>
        )}
      </div>
    </div>
  );
};

export default DispatchTo;
