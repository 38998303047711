import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import {
  ConditionTypes,
  createNewObjectHash,
  getConditionValueOrDefault,
} from "src/store/slices/initiateReturn/Helpers/returnExchange.helpers";
import { v4 as uuid } from "uuid";
import {
  fetchPostStep11Config,
  fetchStep11GetConfig,
} from "../Thunks/returnExchangeModal.thunk";
import { step11Config } from "../step11Config.slice";

const updateConfig = (state: step11Config, payload: any) => {
  const {
    step11ConditionOptionsRes,
    step11ConfigRes,
    defaultResolutionOfferValue,
  } = payload;
  if (step11ConditionOptionsRes) {
    state.conditionOptions = step11ConditionOptionsRes.conditions;
    state.itemOptions = step11ConditionOptionsRes.itemConditions;
    state.defaultOptions = step11ConditionOptionsRes.defaultConditions;

    [
      ...state.conditionOptions,
      ...state.itemOptions,
      ...state.defaultOptions,
    ].forEach((option) => {
      if (!state.variableNames.some((item: any) => item.id === option.id)) {
        state.variableNames.push({ name: option.name, id: option.id });
      }
      // else {
      //   state.variableNames.push(option.name);
      // }

      state.operatorsNames[option.name] = option.operators.map((operator) => {
        state.operatorSymbolMap[operator.value] = operator.name;
        return operator.name;
      });
      state.valueTypes[option.valueType] = null;
      state.variableNameToValueType[option.name] = option.valueType;
    });
  }

  if (step11ConfigRes) {
    const step11Config = step11ConfigRes;

    if (step11Config.length > 0) {
      state.selectedReturnTypeId = step11Config[0].configTabId.toString();
      state.returnTypes = step11Config.map((tab: any, idx: any) => {
        return {
          returnTypeId: tab.configTabId.toString(),
          returnTypeName: tab.configTabName,
          messageForCustomer: {
            value: tab.notEligibleResponseMessage,
            error: null,
          },
          error: null,
          itemError: null,
          isAddConditionEnabled: true,
          isAddItemConditionEnabled: true,
          errorCount: 0,
          isValid: false,
          // itemSequence: idx,
          conditions: tab.conditions.map((condition: any) => {
            const selectedVariable: any = state.variableNames.find(
              (variable: any) => variable.id == condition.conditionTypeId,
            );

            if (selectedVariable) {
              const variableName = selectedVariable.name;
              const operatorSymbol = condition.operator;
              const operator = state.operatorSymbolMap[operatorSymbol];
              const valueType = state.variableNameToValueType[variableName];
              const values = getConditionValueOrDefault(
                valueType,
                condition.conditionValue,
              );

              return {
                id: condition.id.toString(),
                variableName,
                operator,
                values,
              } as ConditionTypes;
            }
            return {} as ConditionTypes;
          }),
          ExchangeWithSameItem: tab.returnTypes.find(
            (retType: { key: string }) =>
              retType.key === "exchangeWithSameItems",
          ).enabled,
          ExchangeWithOtherItem: tab.returnTypes.find(
            (retType: { key: string }) =>
              retType.key === "exchangeWithOtherItems",
          ).enabled,
          RefundtoStoreCredit: tab.returnTypes.find(
            (retType: { key: string }) => retType.key === "refundToStoreCredit",
          ).enabled,
          RefundToOriginalPayment: tab.returnTypes.find(
            (retType: { key: string }) =>
              retType.key === "refundToOriginalPaymentMethod",
          ).enabled,
        };
      });
    } else {
      if (step11ConditionOptionsRes.defaultConditions) {
        let conditions = step11ConditionOptionsRes.defaultConditions.map(
          (condition: any) => {
            const selectedVariable: any = state.variableNames.find(
              (variable: any) => variable.id == condition.id,
            );
            if (selectedVariable) {
              const variableName = selectedVariable.name;
              const operatorSymbol = condition.operators[0].value;
              const operator = state.operatorSymbolMap[operatorSymbol];
              const valueType = state.variableNameToValueType[variableName];
              const values = getConditionValueOrDefault(valueType, null);

              // const values = productIdToDetails(Conditionvalues);

              return {
                id: uuid(),
                variableName,
                operator,
                values,
              } as ConditionTypes;
            }
          },
        );

        state.returnTypes[0].conditions = conditions;
        state.returnTypes[0].returnTypeName = "All orders and items"; //changing as per xd

        if (defaultResolutionOfferValue) {
          let resolutionOffer: any = defaultResolutionOfferValue;
          const returnType = state.returnTypes[0];
          returnType.ExchangeWithSameItem =
            resolutionOffer?.exchangeWithSameItems ?? false;
          returnType.ExchangeWithOtherItem =
            resolutionOffer?.exchangeWithOtherItems ?? false;
          returnType.RefundtoStoreCredit =
            resolutionOffer?.refundToStoreCredits ?? false;
          returnType.RefundToOriginalPayment =
            resolutionOffer?.refundToOrigialPaymentMethod ?? false;
        }
      }
    }
  }

  const hash = createNewObjectHash({
    excludeKeys: state.excludeKeysForHash,
    hashObject: state.returnTypes,
  });

  if (hash) {
    state.initialHashOtherTab = hash;
    state.currentHashOtherTab = hash;
  }
};

const step11Builders = (builder: ActionReducerMapBuilder<step11Config>) => {
  builder.addCase(fetchPostStep11Config.pending, (state: step11Config) => {
    state.updateAjaxStatus = "pending";
  });
  builder.addCase(
    fetchPostStep11Config.fulfilled,
    (state: step11Config, { payload }) => {
      state.updateAjaxStatus = "fulfilled";
      updateConfig(state, payload);
    },
  );
  builder.addCase(fetchPostStep11Config.rejected, (state: step11Config) => {
    state.updateAjaxStatus = "rejected";
    pushTheToast({
      type: "danger",
      text: "failed to update!",
      position: "top-right",
    });
  });
  builder.addCase(fetchStep11GetConfig.pending, (state: step11Config) => {
    state.fetchAjaxStatus = "pending";
  });
  builder.addCase(fetchStep11GetConfig.rejected, (state: step11Config) => {
    state.fetchAjaxStatus = "rejected";
    pushTheToast({
      type: "danger",
      text: "failed to fetch configuration!",
      position: "top-right",
    });
  });

  builder.addCase(
    fetchStep11GetConfig.fulfilled,
    (state: step11Config, { payload }) => {
      state.fetchAjaxStatus = "fulfilled";
      updateConfig(state, payload);
    },
  );
};

export default step11Builders;
