/**
 * This file defines a React component responsible for rendering a modal to confirm
 * the deletion of a bot profile. It provides functionality for handling the deletion
 * of the selected bot profile and closing the modal.
 *
 * @author @yuvaraj-busibud
 * @author @navjyot-busibud
 */
import { BotProfileResponse } from "src/services/Bot/BotProfiles/getAllBotProfiles.service";
import styles from "./DeleteBot.module.scss";
import useDeleteBotProfile from "src/routes/BotSettings/Children/BotProfiles/useDeleteBotProfile";
import { Spinner } from "react-bootstrap";
interface Props {
  selectedProfile: BotProfileResponse | undefined;
  onHide: () => void;
}
const DeleteBot = ({ selectedProfile, onHide }: Props) => {
  const { handleDeleteProfile, isError, isLoading, isSuccess } =
    useDeleteBotProfile({
      id: selectedProfile?.id,
    });

  return (
    <div>
      {!isSuccess ? (
        <div className="text-center px-4">
          <span className={`d-block mb-3 ${styles.enquiry}`}>
            <i className="fa-solid fa-circle-exclamation"></i>
          </span>
          <p className={`${styles.modalHead}`}>
            {`Are you sure to delete this bot profile "${selectedProfile?.name ?? ""
              }"`}
            ?
          </p>
          <span className={`${styles.modalSubHeading}`}>
            This will remove all the setting related to this profile and will no
            longer appear to the audiences
          </span>
          <div className="d-flex justify-content-center align-items-center mt-2 mb-2">
            <button
              className={`px-1 bg-white ${styles.cancelBtn}`}
              onClick={onHide}
            >
              Cancel
            </button>
            <button
              className={`px-1 ${styles.confirmBtn}`}
              disabled={isLoading}
              onClick={handleDeleteProfile}
            >
              {isLoading ? (
                <Spinner
                  className="my-auto mx-1"
                  animation="border"
                  color="white"
                  size="sm"
                />
              ) : (
                `Yes I'm sure`
              )}
            </button>
          </div>
        </div>
      ) : null}

      {/* if click on yes show below ui  */}
      {isSuccess ? (
        <div className="text-center">
          <div className={`mx-auto mt-2 ${styles.checkBox}`}>
            <span>
              <i className="fa-solid fa-check"></i>
            </span>
          </div>
          <p className={`${styles.deleteText}`}>Deleted!</p>
          <button
            className={`px-2 mx-auto mb-2 ${styles.okBtn}`}
            onClick={onHide}
          >
            Okay
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default DeleteBot;
