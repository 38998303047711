import { axiosJSON } from "src/globals/axiosEndPoints";
import { VariableTextData } from "src/features/ReturnAutoWorkFlow/ReturnAutoWorkFlow.types";

/**
 * Interface representing the parameters required to add a new text variable.
 */
export interface AddTextVariablesParams {
  /**
   * A key used to identify the set of editor variables.
   */
  editorVariablesKey: string;
  /**
   * The name of the variable to be added.
   */
  variableName: string;
  /**
   * An example usage of the variable text.
   */
  example: string;
}

/**
 * Adds a new text variable to the server.
 *
 * @param params - The parameters required to add the new text variable.
 * @returns A promise that resolves to the added VariableTextData.
 * @throws An error if the server response contains an error.
 */
async function addTextVariablesService(params: AddTextVariablesParams) {
  const { data: res } = await axiosJSON.post(
    "/api/setting/automation/refund/addTextVariables",
    params,
  );

  if (res.err || res.error) {
    throw new Error(res.msg ?? res.message ?? "Some Error Occurred.");
  }

  return res.data as VariableTextData;
}

export default addTextVariablesService;
