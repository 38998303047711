import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";

export interface createCustomQaParams {
  botProfileId: number | string;
  questions: string[];
  answer: string;
  status: "draft" | "public";
}

export interface createCustomQaRes {
  customQAId: number | string;
}

const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/customQa/createCustomQa`
  : "/api/botProfile/customQA/create";

export async function createCustomQa(params: createCustomQaParams) {
  const { data: res } = await axiosJSON.post(apiEndPoint, params);

  if (res.err) {
    throw new Error(res.message as string);
  }


  return res.data as createCustomQaRes;
}
