import GreetingTable from "./Children/GreetingTable/GreetingTable";
import styles from "./Greetings.module.scss";
import welcome from "src/assets/images/welcome.png";
import discount from "src/assets/images/discount.png";
import run from "src/assets/images/run.png";
const Greetings = () => {
  return (
    <div className={`overflow-auto`}>
      <div className={`${styles.tableHeader}`}>
        <div className={`${styles.widthSet1}`}>
          <span className={`${styles.headerName}`}>Name</span>
        </div>
        <div className={`${styles.widthSet2}`}>
          <span className={`${styles.headerName}`}>Status</span>
        </div>
        <div className={`${styles.widthSet3}`}>
          <span className={`${styles.headerName}`}>Displayed</span>
        </div>
        <div className={`${styles.widthSet4}`}>
          <span className={`${styles.headerName}`}>Chats</span>
        </div>
        <div className={`${styles.widthSet5}`}>
          <span className={`${styles.headerName}`}>Conversation</span>
        </div>
        <div className={`${styles.widthSet6}`}>
          <span className={`${styles.headerName}`}>SEE IN</span>
        </div>
      </div>
      <div>
        <GreetingTable
          img={welcome}
          imgText={`Welcome your customers`}
          display={0}
          chat={0}
          conversation={`-`}
        />
        <GreetingTable
          img={discount}
          imgText={`Welcome returning customers`}
          display={0}
          chat={0}
          conversation={`-`}
        />
        <GreetingTable
          img={run}
          imgText={`Run`}
          display={0}
          chat={0}
          conversation={`-`}
        />
      </div>
    </div>
  );
};
export default Greetings;
