/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useMemo, useRef } from "react";
import { BrandOption } from "./PublishModal";
import { useAppSelector } from "src/store/store";

interface Props {
  setSelectedBrand: ( brand: BrandOption ) => void;
}

function usePublishModal( { setSelectedBrand }: Props ) {
  // Use the Redux selector to get the required data from the store
  const { brandIds, metaData, brandDataAjaxStatus } = useAppSelector(
    ( state ) => state.brandSettings
  );

  // Ref to hold the current state values, used to check for changes later
  const currentState = useRef( {
    brandDataAjaxStatus,
    brandIds,
    metaData,
  } );

  // Update the ref with the latest state values using useMemo
  useMemo( () => {
    currentState.current = { brandDataAjaxStatus, brandIds, metaData };
  }, [brandDataAjaxStatus, brandIds, metaData] );

  // Callback to handle the change event of the dropdown
  const handleChange = useCallback( ( selectedOption: BrandOption ) => {
    setSelectedBrand( selectedOption );
  }, [] );

  return {
    brandIds,
    metaData,
    brandDataAjaxStatus,
    currentState,
    handleChange,
  };
}

export default usePublishModal;
