import { axiosJSON } from "src/globals/axiosEndPoints";

interface MetaData {
  count: number;
  hasNextPage: boolean;
}

export interface GetAllOrderTags {
  data: string[];
  metaData: MetaData;
}

export interface GetAllOrderTagsParams {
  limit: number;
}

export async function getAllOrderTags(payload: GetAllOrderTagsParams) {
  const { data } = await axiosJSON.post(
    "/api/shopify/getAllOrderTags",
    payload
  );

  if (data.error === true) {
    throw new Error("Some Error Occurred while fetching data");
  }

  // const data = await getAllOrderTagsFake();

  return {
    data: data.data,
    metaData: data.metaData,
  };
}

// let getAllOrderTagsFake = function (): Promise<GetAllOrderTags> {
//   return new Promise(function (resolve, reject) {
//     resolve({
//       data: ["test", "another", "tag", "Tag 8", "tag8"],
//       metaData: {
//         count: 5,
//         hasNextPage: true,
//       },
//     });
//   });
// };
