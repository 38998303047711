import { ArticleData, getArticle, GetArticleParams } from "src/services/LiveChat/Settings/knowledgeBased/article/getArticle";

// thunk that fetches all articleCategories
const fetchArticleSettingThunk = async(
  payload: {integrationId:number|string, articleId: number|string},
  {getState}: {getState: Function}
) =>{
  try{
    // const currentState: RootState = getState();
    const articleParamData : GetArticleParams = {
      integrationId: payload.integrationId,
      articleId: payload.articleId
    }

    const articleData: ArticleData = await getArticle(articleParamData);
    console.log("---------------articleCategorySettingThunk ------------ ");
    console.log("article data ::: "+ JSON.stringify(articleData));
    
    return articleData;
  }catch(error){
    throw error;
  }
};


export default { fetchArticleSettingThunk };