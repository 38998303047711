/**
 * This file is the custome hook and context file.
 * It contains useNotificationsCreate and useNotifications custome hooks.
 *
 * @author Yash Aditya
 */

import React, { useContext, useMemo, useReducer } from "react";
import { FilterInParam } from "src/routes/Report/hooks/reportFilters/useReportFilters";
import {
  ReportInternalFilters,
  ReportSidebarFilters,
} from "src/routes/Report/hooks/reportFilters/useReportInternalFilters";
import {
  CurrentStatusEndPoints,
  ReportTicketList,
} from "src/services/report/reportTicketList.service";

export interface ReportNotification {
  id: "new" | string;
  /**
   * Title or name of the report we are going to send.
   */
  reportName: string;
  notificationEnabled?: boolean;
  globalFiltersApplied?: FilterInParam;
  internalFiltersApplied?: ReportInternalFilters;
  sidebarFiltersApplied?: ReportSidebarFilters;
  sendingFormatId: string;
  createdTimeUTC?: string;
  sidebarCategories?: ReportTicketList["categories"];
}

export interface AllNotificationsData {
  notifications: {
    [id: string]: ReportNotification;
  };
  notificationIds: Array<string>;
  metaData: {
    totalCount: number;
  };
}
export interface NotificationsData extends AllNotificationsData {
  limit: 30;
}

export type NotificationActiveView = "list" | "edit";

export interface NotifyUsers {
  id: string;
  email: string;
  imageUrl?: string | null | undefined;
  name?: string | null | undefined;
}

export interface NotificationSettingsData {
  sendTo: Array<NotifyUsers>;
  sendingFrequency: {
    frequencyTypeId: string | number; // NotificationFrequencyTypeId
    type: "daily" | "weekly" | "monthly" | "yearly";
    timeValues?: Array<{
      weekDayKey?: string; // (id sent in meta data) compulsory for weekly type
      weekDayLabel?: string; // Format - XD
      monthKey?: string; // (id sent in meta data) compulsory for yearly type
      monthLabel?: string; // Format - XD
      date?: string; // (1,31) compulsory for yearly and monthly types. Format - XD
      // Will be sent when the fixedNoOfTimes will be present and it will not be 0.
      times?: Array<{
        time: string; // Format - XD
        timeZoneId: string; // TimeZone value
        timeZoneLabel: string; // TimeZone value
      }>;
      // Will be sent if fixedNoOfTimes will not be present and number of time will exceed 8.
      xNoOfTimes?: number;
      timeZoneId?: string; // TimeZoneId might be sent if times not sent
      timeZoneLabel?: string; // TimeZone value sent if timeZoneId sent
    }>;
    smallFreqLabel: string; // Used to show the place holder of the label of frequency modal.
  };
}

export interface ReportNotificationState {
  context: CurrentStatusEndPoints | "";
  editingNotificationData?: ReportNotification;
  notificationsData: NotificationsData;
  activeView: NotificationActiveView;
  notificationSettingsData?: NotificationSettingsData;
}

export interface DeleteFilterPayload {
  filterKey:
    | "globalFiltersApplied"
    | "internalFiltersApplied"
    | "sidebarFiltersApplied";
  subFilterKey: keyof FilterInParam | keyof ReportInternalFilters;
  filterId: string;
}

const reportNotifications: ReportNotificationState = {
  context: "",
  notificationsData: {
    limit: 30,
    notifications: {},
    notificationIds: [],
    metaData: {
      totalCount: 0,
    },
  },
  activeView: "list",
};

const reducerMethod = (
  state: ReportNotificationState,
  [action, payload]: [
    (value: ReportNotificationState, payload?: any) => void,
    undefined | any,
  ],
) => {
  action(state, payload);
  return { ...state };
};

const NotificationsContext = React.createContext([
  reportNotifications,
  () => {},
] as [
  ReportNotificationState,
  React.Dispatch<
    [(value: ReportNotificationState, payload?: any) => void, undefined | any]
  >,
]);

export const useNotificationsCreate = (context: CurrentStatusEndPoints) => {
  const [state, dispatch] = useReducer(reducerMethod, {
    ...reportNotifications,
    context,
  });
  return [state, dispatch, NotificationsContext.Provider] as [
    ReportNotificationState,
    typeof dispatch,
    typeof NotificationsContext.Provider,
  ];
};

export const useNotifications = () => {
  const [reportNotifications, dispatch] = useContext(NotificationsContext);
  return { reportNotifications, dispatch };
};

export const reportNotificationsActions = {
  resetState: (state: ReportNotificationState, keepContext = false) => {
    delete state.editingNotificationData;
    state.notificationsData = {
      limit: 30,
      notifications: {},
      notificationIds: [],
      metaData: {
        totalCount: 0,
      },
    };
    state.activeView = "list";
    if (keepContext === false) {
      state.context = "";
    }
    delete state.notificationSettingsData;
  },
  resetNotificationsData: (state: ReportNotificationState) => {
    state.notificationsData = {
      limit: 30,
      notifications: {},
      notificationIds: [],
      metaData: {
        totalCount: 0,
      },
    };
  },
  setAllNotifications: (
    state: ReportNotificationState,
    payload: AllNotificationsData,
  ) => {
    state.notificationsData.metaData = payload.metaData;
    state.notificationsData.notificationIds = [
      // ...state.notificationsData.notificationIds,
      ...payload.notificationIds,
    ];
    state.notificationsData.notifications = {
      // ...state.notificationsData.notifications,
      ...payload.notifications,
    };
  },
  setEditingNotificationData: (
    state: ReportNotificationState,
    payload: Partial<ReportNotification> & {
      sidebarCategories?: ReportTicketList["categories"];
    },
  ) => {
    state.editingNotificationData = {
      ...(state.editingNotificationData ?? {}),
      ...(payload as any),
    };
  },
  clearEditingNotificationData: (state: ReportNotificationState) => {
    state.editingNotificationData = undefined;
  },
  setNewEditingNotificationData: (
    state: ReportNotificationState,
    payload: {
      globalFiltersApplied: FilterInParam;
      internalFiltersApplied: ReportInternalFilters;
      sideBarFiltersApplied: ReportSidebarFilters;
      sidebarCategories?: ReportTicketList["categories"];
    },
  ) => {
    state.editingNotificationData = {
      id: "new",
      reportName: "",
      sendingFormatId: "",
      globalFiltersApplied: payload.globalFiltersApplied,
      internalFiltersApplied: payload.internalFiltersApplied,
      sidebarFiltersApplied: payload.sideBarFiltersApplied,
      sidebarCategories: payload.sidebarCategories,
    };
  },
  deleteEachAppliedFilter: (
    state: ReportNotificationState,
    payload: DeleteFilterPayload,
  ) => {
    if (
      state.editingNotificationData &&
      state.editingNotificationData[payload.filterKey]
    ) {
      const editingData: Array<string> = (state.editingNotificationData as any)[
        payload.filterKey
      ][payload.subFilterKey];
      (state.editingNotificationData as any)[payload.filterKey][
        payload.subFilterKey
      ] = editingData.filter((data) => data !== payload.filterId);
    }
  },
  changeNotificationData: (
    state: ReportNotificationState,
    payload: ReportNotification,
  ) => {
    state.notificationsData.notifications[payload.id] = {
      ...state.notificationsData.notifications[payload.id],
      ...payload,
    };
  },
  deleteNotificationData: (
    state: ReportNotificationState,
    { notificationId, count }: { notificationId: string; count: number },
  ) => {
    delete state.notificationsData.notifications[notificationId];
    state.notificationsData.notificationIds =
      state.notificationsData.notificationIds.filter(
        (id) => id !== notificationId,
      );
    state.notificationsData.metaData.totalCount = count;
  },
  setActiveView: (
    state: ReportNotificationState,
    view: NotificationActiveView,
  ) => {
    state.activeView = view;
  },
};
