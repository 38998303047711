/**
 * This file is the react component file.
 *
 * @author Yash Aditya
 */

// Importing necessary dependencies and styles
import useNotificationFiltersApplied from "../../../../hooks/notifications/useNotificationFiltersApplied";
import styles from "../../EditNotification.module.scss";

// Functional component for rendering filter boxes
function FilterBox() {
  // Destructuring values from the custom hook
  const {
    globalFiltersApplied,
    sidebarFiltersApplied,
    internalFiltersApplied,
    deleteFilter,
  } = useNotificationFiltersApplied();

  // Rendering sidebar filters
  return (
    <div className={`px-2 px-md-3 py-2 mt-2 ${styles.filterBox}`}>
      {sidebarFiltersApplied.map((fa) => {
        return (
          <div key={fa.key}>
            {/* Displaying filter name */}
            <span className={`${styles.channels}`}>{fa.name}:</span>
            {fa.filters.map((filter) => {
              return (
                <span
                  className="px-1 px-md-3 d-inline-block"
                  key={fa.key + filter.id}
                >
                  {/* Displaying filter value */}
                  <span className={`${styles.filterTag}`}>{filter.value}</span>
                  {/* Displaying delete button for the filter */}
                  <span className={`ps-2 ${styles.filterTag}`}>
                    <i
                      className="fa-solid fa-xmark cursor-pointer"
                      onClick={deleteFilter}
                      data-filter-key="sidebarFiltersApplied"
                      data-sub-filter-key={fa.key}
                      data-filter-id={filter.id}
                    ></i>
                  </span>
                </span>
              );
            })}
          </div>
        );
      })}

      {/* Displaying global filters */}
      <div>
        <span className="pe-1">
          <span className={`pe-1 ${styles.filterImg}`}>
            <i className="fa-solid fa-filter"></i>
          </span>
          {/* Displaying heading for global filters */}
          <span className={`${styles.appliedFilters}`}>Filters Applied:</span>
        </span>
        {globalFiltersApplied.map((fa) => {
          return (
            <span className="pe-1" key={fa.key}>
              {/* Displaying global filter name */}
              <span className={`${styles.textFilter}`}>{fa.name}:</span>
              {fa.filters.map((filter) => {
                return (
                  <span
                    className="px-2 d-inline-block"
                    key={fa.key + filter.id}
                  >
                    {/* Displaying global filter value */}
                    <span className={`${styles.filterTag}`}>
                      {filter.value}
                    </span>
                    {/* Displaying delete button for the filter */}
                    <span className={`ps-2 ${styles.filterTag}`}>
                      <i
                        className="fa-solid fa-xmark cursor-pointer"
                        onClick={deleteFilter}
                        data-filter-key="globalFiltersApplied"
                        data-sub-filter-key={fa.key}
                        data-filter-id={filter.id}
                      ></i>
                    </span>
                  </span>
                );
              })}
            </span>
          );
        })}
      </div>

      {/* Displaying internal filters */}
      <div>
        <span className="pe-1">
          {/* Displaying heading for internal filters */}
          <span className={`${styles.appliedFilters}`}>Other Filters:</span>
        </span>
        {internalFiltersApplied.map((fa) => {
          return (
            <span className="pe-1" key={fa.key}>
              {/* Displaying internal filter name */}
              <span className={`${styles.textFilter}`}>{fa.name}:</span>
              {fa.filters.map((filter) => {
                return (
                  <span
                    className="px-2 d-inline-block"
                    key={fa.key + filter.id}
                  >
                    {/* Displaying internal filter value */}
                    <span className={`${styles.filterTag}`}>
                      {filter.value}
                    </span>
                    {/* Displaying delete button for the filter */}
                    <span className={`ps-2 ${styles.filterTag}`}>
                      <i
                        className="fa-solid fa-xmark cursor-pointer"
                        onClick={deleteFilter}
                        data-filter-key="internalFiltersApplied"
                        data-sub-filter-key={fa.key}
                        data-filter-id={filter.id}
                      ></i>
                    </span>
                  </span>
                );
              })}
            </span>
          );
        })}
      </div>
    </div>
  );
}

export default FilterBox;
