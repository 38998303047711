import { OverlayTrigger, Tooltip } from "react-bootstrap";
import styles from "./Domain.module.scss";
import useDomain from "./useDomain";
import Loader from "src/components/Loader";
import caution from "src/assets/images/caution.png";
import arrowRight from "src/assets/images/arrowRight.png";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
const Domain = () => {
  const navigate = useNavigate();
  // Variables from useDomain hook
  const {
    dropdown,
    ownDomain,
    handleOwnDomainChange,
    setDropDown,
    domainiUrl,
    handleChangeflexRadioDefault1,
    handleChangeflexRadioDefault2,
    handleDomainUrlChange,
    selectedRadio,
    handleChangeUrl,
    updateDomaiAndSslAccessControl,
    isLoading,
    isRefetching,
    refetch,
    domainUrlLoader,
    showValidation,
  } = useDomain();

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="w-100 pt-4 px-3">
      <div className="mb-4 d-flex">
        <div>
          <div
            className={`d-flex justify-content-center align-items-center ${styles.backChat}`}
            onClick={() => navigate(-1)}
          >
            <i className="fa-solid fa-arrow-left"></i>
          </div>
        </div>
        <div>
          <h3 className={`mb-0 ${styles.heading} saufter_h3`}>
            Domain and Access / Domain & SSL
          </h3>
          <p className={`${styles.subHeading} saufter_subHeading`}>
            Let customers style the help center right inside your chat widget
            instead of starting a conversation.
          </p>
        </div>
      </div>

      {isLoading || isRefetching ? (
        <>
          <div className={`${styles.loaderWrapper}`}>
            <Loader />
          </div>
        </>
      ) : (
        <>
          <p className={`${styles.head} saufter_h4`}>Domain & SSL settings</p>
          <div
            className={`d-flex justify-content-between align-items-center px-2 saufter_medium_h4 ${
              styles.dropBtn
            } ${dropdown && styles.borderSet}`}
            onClick={() => setDropDown(!dropdown)}
          >
            <span>Domain & SSL</span>
            <span className={`${styles.arrow}`}>
              {dropdown ? (
                <img
                  src={arrowRight}
                  alt=""
                  className={`${styles.arrowDown}`}
                />
              ) : (
                <img
                  src={arrowRight}
                  alt=""
                />
              )}
            </span>
          </div>

          {dropdown ? (
            <div className={`p-2 p-md-3 mb-2 ${styles.dropDown}`}>
              <>
                <div>
                  <p className={`mb-0 ${styles.domainName} saufter_h5`}>
                    Custom Domain
                  </p>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      checked={ownDomain}
                      id="flexCheckDefault"
                      onChange={handleOwnDomainChange}
                    />
                    <label
                      className={`form-check-label ${styles.formLabel} saufter_h6`}
                      htmlFor="flexCheckDefault"
                    >
                      I would like to use my own domain
                    </label>

                    {/* Custom Domain and Url Section */}
                    {ownDomain && (
                      <div>
                        <p
                          className={`ps-2 pt-2 mb-0 ${styles.domainText} saufter_h6`}
                        >
                          Custom Domain URL
                        </p>
                        <div
                          className={`position-relative ms-0 ms-md-2 ${styles.urlBox}`}
                        >
                          <input
                            type="text"
                            className={`${styles.urlInput} ps-2 pe-1 mt-2 ${
                              showValidation
                                ? "border border-danger rounded"
                                : ""
                            }`}
                            placeholder="Help.mydomain.com"
                            value={domainiUrl}
                            onChange={handleDomainUrlChange}
                          />
                          <button
                            className={`${styles.urlBtn} border-0 d-flex w-auto ms-2`}
                            onClick={handleChangeUrl}
                            type="button"
                          >
                            <div
                              className="w-auto px-2"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Change Url{" "}
                            </div>
                            {domainUrlLoader ? (
                              <div
                                className="spinner-border spinner-border-sm mt-1"
                                role="status"
                                aria-hidden="true"
                              ></div>
                            ) : null}
                          </button>
                        </div>
                        {showValidation && (
                          <span className={`${styles.validationError} ps-2`}>
                            Please enter valid Url
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                </div>

                {/* SSL Section  */}
                {ownDomain && (
                  <div className="mt-3">
                    <p className={`mb-0 ${styles.domainName} saufter_h5`}>
                      SSL certificate
                    </p>
                    <div className="form-check">
                      <input
                        className={`form-check-input `}
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                        checked={selectedRadio === "flexRadioDefault1"}
                        onChange={handleChangeflexRadioDefault1}
                        disabled={updateDomaiAndSslAccessControl.isLoading}
                      />
                      <label
                        className={`form-check-label ${styles.formLabel} saufter_h6`}
                        htmlFor="flexRadioDefault1"
                      >
                        I would like to use Helplama’s free SSL certificate
                        service recommended
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        checked={selectedRadio === "flexRadioDefault2"}
                        onChange={handleChangeflexRadioDefault2}
                        disabled={updateDomaiAndSslAccessControl.isLoading}
                      />

                      <label
                        className={`form-check-label ${styles.formLabel} saufter_h6`}
                        htmlFor="flexRadioDefault2"
                      >
                        <div className="d-flex align-items-center">
                          <span className="pe-1">
                            I would like to use my own SSL{" "}
                          </span>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip className={` ${styles.toolTipCustom}`}>
                                <span className="">
                                  Point your domain Through CName records Please
                                  contact your developer …
                                </span>
                              </Tooltip>
                            }
                          >
                            {({ ref, ...triggerHandler }) => (
                              <div
                                {...triggerHandler}
                                ref={ref}
                              >
                                <img
                                  src={caution}
                                  alt="caution"
                                  className={`cursor-pointer ${styles.cautionImg}`}
                                />
                                {/* <span className="text-dark cursor-pointer">
                                    <i className="fa-solid fa-circle-exclamation"></i>
                                  </span> */}
                              </div>
                            )}
                          </OverlayTrigger>
                        </div>
                      </label>
                    </div>
                  </div>
                )}
              </>
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
};

export default Domain;
