/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useRef, useEffect, useCallback, useMemo } from "react";
import { useTemplates, useTemplateDispatch } from "../../TemplateContext";
import {
  Attachment,
  TemplateStep,
} from "src/services/LiveChat/ScheduleFollowups/getAllTemplates";
import { formatDateTimeString } from "src/utils/dateLibrary";

type Props = {
  step: TemplateStep;
  selectedTemplateId: string | null;
};

function useStep( { selectedTemplateId, step }: Props ) {
  const [show, setShow] = useState( false );

  const template = useTemplates();

  const dispatch = useTemplateDispatch();

  const [isFileUploading, setIsFileUploading] = useState( false );

  const idRef = useRef( {
    selectedTemplateId: selectedTemplateId,
    stepId: step.stepId,
  } );

  const updateCondition = useCallback(
    ( value: string | null ) => {
      dispatch( {
        type: "Update_SendMessageCondition",
        payload: {
          templateId: idRef.current.selectedTemplateId,
          stepId: idRef.current.stepId,
          value: value,
        },
      } );
    },
    [idRef.current.selectedTemplateId, idRef.current.stepId]
  );

  const Increment = useCallback( () => {
    dispatch( {
      type: "Update_ScheduleFollowUpValue",
      payload: {
        templateId: idRef.current.selectedTemplateId,
        stepId: idRef.current.stepId,
        value: parseInt( step.scheduleFollowUpValue + "" ) + 1,
      },
    } );
  }, [idRef.current.selectedTemplateId, idRef.current.stepId, step.scheduleFollowUpValue] );

  const Decrement = useCallback( () => {
    if ( parseInt( step.scheduleFollowUpValue + "" ) <= 1 ) return;
    dispatch( {
      type: "Update_ScheduleFollowUpValue",
      payload: {
        templateId: idRef.current.selectedTemplateId,
        stepId: idRef.current.stepId,
        value: parseInt( step.scheduleFollowUpValue + "" ) - 1,
      },
    } );
  }, [idRef.current.selectedTemplateId, idRef.current.stepId, step.scheduleFollowUpValue] );

  const handleItemClick = useCallback(
    ( value: React.SetStateAction<string> ) => {
      if ( value === "dateAndTime" ) {
        dispatch( {
          type: "Update_ScheduleFollowUpType",
          payload: {
            templateId: idRef.current.selectedTemplateId,
            stepId: idRef.current.stepId,
            value: value,
            typeValue: formatDateTimeString( new Date().toLocaleString( 'en-US' ) ),
          },
        } );
      } else {
        dispatch( {
          type: "Update_ScheduleFollowUpType",
          payload: {
            templateId: idRef.current.selectedTemplateId,
            stepId: idRef.current.stepId,
            value: value,
            typeValue:
              step.scheduleFollowUpType === "dateAndTime"
                ? 1
                : step.scheduleFollowUpValue,
          },
        } );
      }
      setShow( false );
    },
    [
      idRef.current.selectedTemplateId,
      idRef.current.stepId,
      step.scheduleFollowUpType,
      step.scheduleFollowUpValue,
    ]
  );

  const updateHeading = useCallback(
    ( value: string ) => {
      dispatch( {
        type: "Update_TemplateHeading",
        payload: {
          templateId: idRef.current.selectedTemplateId,
          stepId: idRef.current.stepId,
          value: value,
        },
      } );
    },
    [idRef.current.selectedTemplateId, idRef.current.stepId]
  );

  const updateMessage = useCallback(
    ( value: string ) => {
      dispatch( {
        type: "Update_TemplateMessage",
        payload: {
          templateId: idRef.current.selectedTemplateId,
          stepId: idRef.current.stepId,
          value: value,
        },
      } );
    },
    [idRef.current.selectedTemplateId, idRef.current.stepId]
  );

  const updateAttachments = useCallback(
    ( value: Attachment ) => {
      dispatch( {
        type: "Update_TemplateAttachments",
        payload: {
          templateId: idRef.current.selectedTemplateId,
          stepId: idRef.current.stepId,
          value: value,
        },
      } );
    },
    [idRef.current.selectedTemplateId, idRef.current.stepId]
  );

  const addRemovedAttachmentId = useCallback(
    ( value: number|string ) => {
      dispatch( {
        type: "add_RemovedAttachmentId",
        payload: {
          templateId: idRef.current.selectedTemplateId,
          stepId: idRef.current.stepId,
          value: value,
        },
      } );
    },
    [idRef.current.selectedTemplateId, idRef.current.stepId]
  );


  const addAnotherStep = useCallback( () => {
    dispatch( {
      type: "Add_Another_Step",
      payload: {
        templateId: idRef.current.selectedTemplateId,
        stepId: "",
      },
    } );
  }, [idRef.current.selectedTemplateId] );

  const updateFollowUpValue = useCallback(
    ( value: string ) => {
      dispatch( {
        type: "Update_ScheduleFollowUpValue",
        payload: {
          templateId: selectedTemplateId,
          stepId: step.stepId,
          value: value,
        },
      } );
    },
    [selectedTemplateId, step.stepId]
  );

  const seletectedFollowUpType = useMemo(
    () => ( {
      days: "Days",
      weeks: "Weeks",
      months: "Months",
      dateAndTime: "Set Date and Time",
      businessDays: "Business days",
    } ),
    []
  );

  useEffect( () => {
    idRef.current.selectedTemplateId = selectedTemplateId;
    idRef.current.stepId = step.stepId;
  }, [selectedTemplateId, step.stepId] );

  const [selectedFiles, setSelectedFiles] = useState( step.attachments ?? [] );

  useEffect( () => {
    updateAttachments( selectedFiles as any );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFiles] );

  return {
    show,
    setShow,
    template,
    dispatch,
    isFileUploading,
    setIsFileUploading,
    idRef,
    updateCondition,
    Increment,
    Decrement,
    handleItemClick,
    updateHeading,
    updateMessage,
    updateAttachments,
    seletectedFollowUpType,
    addAnotherStep,
    updateFollowUpValue,
    selectedFiles,
    setSelectedFiles,
    addRemovedAttachmentId,
  };
}

export default useStep;
