import { OverlayTrigger, Popover } from "react-bootstrap";
import styles from "./MentionOptions.module.scss";
import saufter from "src/assets/images/saufter.png";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { AutoCompleteRenderComponentProps } from "src/interfaces/TinyMCEContainer/ITinyMCEContainer";
import { MentionData } from "src/services/TicketService/InternalChatBot/getAllMentions";
interface Props extends AutoCompleteRenderComponentProps {
  data: MentionData;
  baseMentionHtml: string;
  highlightedIndex: number;
  subMenuIndex: number;
  setHighlightedIndex: (value: React.SetStateAction<number>) => void;
  setSubMenuIndex: (value: React.SetStateAction<number>) => void;
  handleOptionClick: (option: MentionData) => void;
}
const MentionOptions = ({
  cleanUp,
  data,
  match,
  select,
  baseMentionHtml,
  highlightedIndex,
  subMenuIndex,
  setHighlightedIndex,
  setSubMenuIndex,
  handleOptionClick,
}: Props) => {
  return (
    <div className={`${styles.mentionBox}`}>
      <div className={`${styles.slackWrapper}`}>
        <div className={`px-4 ${styles.slackheader}`}>
          <img
            src={saufter}
            alt="cern"
            width={26}
            height={26}
          />
          <span className={`ps-1 ${styles.slackText}`}>Cern AI</span>
        </div>
        <div className={`${styles.optionWrapper}`}>
          {data?.popoverData?.map((command, index) => {
            return (
              <OverlayTrigger
                placement="right"
                flip={true}
                key={index}
                show={index === highlightedIndex}
                overlay={
                  <Popover
                    id="popover-basic"
                    bsPrefix={`popover ${styles.popoverWrapper} cursor-pointer`}
                  >
                    <Popover.Body
                      as="div"
                      bsPrefix={`popover-body ${styles.overlayBody}`}
                    >
                      {command?.menuItems.map((subCommand, subIndex) => (
                        <div
                          className={`px-4 mb-2 ${styles.channelBox} ${
                            subMenuIndex === subIndex ? styles.selected : ""
                          }`}
                          key={subIndex}
                          onClick={() => handleOptionClick(subCommand)}
                          onMouseOver={() => setSubMenuIndex(subIndex)}
                        >
                          <span
                            className={`pe-2 ${styles.channelText} ${
                              ""
                              // subIndex === selectedSubInstruct
                              //   ? styles.selected
                              //   : ""
                            }`}
                          >
                            {subCommand.icon ? (
                              <i className={subCommand.icon}></i>
                            ) : null}
                          </span>
                          <span className={`${styles.channelText}`}>
                            {subCommand.dropdownName}
                          </span>
                        </div>
                      ))}
                    </Popover.Body>
                  </Popover>
                }
                trigger={"click"}
              >
                <div
                  className={`px-4 ${styles.options} ${
                    index === highlightedIndex ? styles.selected : ""
                  }`}
                  key={index}
                  onClick={() => {
                    setHighlightedIndex(index);
                    setSubMenuIndex(0);
                  }}
                >
                  <span>{command.menuName}</span>
                </div>
              </OverlayTrigger>
            );
          })}
        </div>
      </div>
      <div className={`px-4 ${styles.footer}`}>
        <div className="d-flex me-2">
          <span className={`${styles.arrowRotate}`}>
            <i className="fa-solid fa-arrow-right-arrow-left"></i>
          </span>
          <span className="ps-2">to navigate</span>
        </div>
        <div className="d-flex me-2">
          <span>
            <i className="fa-solid fa-reply-all"></i>
          </span>
          <span className="ps-2">to select</span>
        </div>
        <div className="d-flex">
          <span>esc to dismiss</span>
        </div>
      </div>
    </div>
  );
};

export default MentionOptions;
