import { axiosJSON } from "src/globals/axiosEndPoints";

export interface CancelFulfillment {
  data: {
    orderId: number;
    fulfillmentId: string;
    status: boolean;
    // fulfillmentId: {
    //   id: string;
    //   status: string;
    //   displayStatus: string;
    // };
  };
  error: boolean;
  message: string;
}

export interface ICancelFulfillmentRes {
    orderId: number;
    fulfillmentId: string;
    status: boolean;
}

export interface CancelFulfillmentParams {
  orderId: number;
  fulfillmentId: string;
}

export async function cancelFulfillment(params: CancelFulfillmentParams) {
  const { data: res }: any = await axiosJSON.post(
    `/api/shopify/order/fulfillment/cancel`,
    params
  );

  if (res.error === true) {
    throw new Error(res.message as string);
  }

  return res.data as ICancelFulfillmentRes;
}
