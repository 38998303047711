import { axiosJSON } from "src/globals/axiosEndPoints";
import log from 'loglevel';
import { TicketsQuery } from "./getTicketsV2";

export type AddTicketRemarksPayload = {
  ticketIds?: string[],
  remark: string,
  allTickets?: boolean;
  filters?: TicketsQuery["filters"]
}

export async function addTicketRemarks(servicePayload: AddTicketRemarksPayload) {
  const { data } = await axiosJSON.post(`/api/ticket/addRemarkInBulk`, servicePayload);
  if (data.err === true) {
    throw new Error(data.msg as string)
  }
  const normalizedData = normalizeData(data)
  // log.debug('add ticket tags raw response:', data);
  // log.debug('add ticket tags normalized response:', normalizedData);
  return normalizedData;
}


// convert incoming data to the required format
function normalizeData(response: any) {

  const normalizedData = response
  return normalizedData
}