/**
 * This file is the react component file.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 * @author Navjyot
 */

import { useEffect, useMemo, useRef } from "react";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import useReportTicketGraph from "../../hooks/useReportTicketGraph";
import { ReportTabNames } from "../../../ReportTabs/ReportTabs";
import HighchartsHeatmap from "highcharts/modules/heatmap";
import { ReportTicketBodyProps } from "../../../ReportTicketBody/ReportTicketBody";

// Initialize Highcharts Heatmap module
HighchartsHeatmap(Highcharts);

// Interface for component props
export interface GraphProps
  extends HighchartsReact.Props,
    ReportTicketBodyProps {
  activeTabName: ReportTabNames;
  setActiveTabname: React.Dispatch<React.SetStateAction<ReportTabNames>>;
}

// Component to render graph for effective rerendering.
function Graph({
  activeTabName,
  endPoint,
  title,
  tooltip,
  tabProps,
  setActiveTabname,
  ...props
}: GraphProps) {
  // Reference for the HighchartsReact component
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  // Custom hook for handling report ticket graph data
  const { reportTicketGraph, status } = useReportTicketGraph({
    endPoint,
    tabProps,
    reportDateType: tabProps.reportDateType ?? undefined,
  });

  // Memoized options for Highcharts
  const optionData = useMemo(() => {
    // Highcharts chart configuration based on reportTicketGraph data
    const options: Highcharts.Options = {
      title: {
        text: reportTicketGraph?.graphTitle.title ?? "",
        style: reportTicketGraph?.graphTitle.style ?? {
          color: "#000000",
          fontSize: "12px",
          fontWeight: "600",
        },
      },
      chart: {
        type: reportTicketGraph?.chartType ?? "column",
      },
      xAxis: {
        categories: reportTicketGraph?.categories ?? [],
        crosshair: true,
        title: {
          text: reportTicketGraph?.xAxis.title ?? "",
          style: reportTicketGraph?.xAxis.style ?? {
            font: "normal normal 500 12px/18px Poppins", //08 Dec Changed font and other styles as per xd
            letterSpacing: "0px",
            color: "#000000",
            opacity: "1",
          },
        },
        lineColor: "#EDEDED", //added to change the color of the x axis line
      },
      yAxis: {
        min: reportTicketGraph?.yAxis.min ?? 0,
        max: reportTicketGraph?.yAxis.max ?? null,
        tickPositions: reportTicketGraph?.yAxis.tickPositions ?? undefined,
        title: {
          text: reportTicketGraph?.yAxis.title ?? "",
          style: reportTicketGraph?.yAxis.style ?? {
            font: "normal normal 500 12px/18px Poppins", //08 Dec Changed font and other styles as per xd
            letterSpacing: "0px",
            color: "#000000",
            opacity: "1",
          },
          offset: 80, //changed as in knowledgebase graphs it is more
        },
        labels:
          reportTicketGraph?.yAxis.labels !== undefined
            ? ({
                enabled: reportTicketGraph?.yAxis.labels?.enabled ?? false,
                formatter: function () {
                  if (
                    reportTicketGraph?.yAxis.labels?.labelName !== undefined &&
                    this.value in reportTicketGraph?.yAxis.labels?.labelName
                  ) {
                    return reportTicketGraph?.yAxis.labels?.labelName[
                      this.value
                    ];
                  }
                },
              } as Highcharts.YAxisLabelsOptions)
            : {},
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          reportTicketGraph?.chartType === "heatmap"
            ? `<tr><td style="color:'#000';padding:0">{series.name}: </td>` +
              `<td style="padding:0"><b>{${
                reportTicketGraph?.seriesDataWholeNumber
                  ? "point.value:.0f"
                  : "point.value:.1f"
              }}</b></td></tr>` //checking if whole number flag is true if yes then setting the value to not show the decimal
            : `<tr><td style='color:{series.color};padding:0'>{series.name}: </td>` +
              `<td style="padding:0"><b>{${
                reportTicketGraph?.seriesDataWholeNumber
                  ? "point.y:.0f"
                  : "point.y:.1f"
              }}</b></td></tr>`, //checking if whole number flag is true if yes then setting the value to not show the decimal
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },
      legend: {
        itemStyle: {
          font: "normal normal normal 11px/15px Roboto", //08 Dec Added font and other styles to legend as per xd
          letterSpacing: "0px",
          color: "#707070",
        },
      },
      plotOptions: {
        heatmap: {
          pointPadding: 0.2,
          borderWidth: 0.5,
          borderColor: "#D9D9D9",
        },
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      loading: { style: { color: "#707070" } },
      colorAxis: reportTicketGraph?.colorAxis
        ? {
            min: reportTicketGraph.colorAxis.min,
            maxColor: reportTicketGraph.colorAxis.maxColor,
            minColor: reportTicketGraph.colorAxis.minColor,
            showInLegend: false,
          }
        : undefined,
      series: reportTicketGraph?.series ?? [],
      credits: {
        enabled: false, //removing the highcharts.com from the bottom of the graph
      },
    };

    // Customize loading styles based on status
    if (status === "error") {
      options.loading = {
        style: { color: "red" },
      };
    }

    return options;
  }, [reportTicketGraph, status]);

  // Effect for managing loading states in the Highcharts chart
  useEffect(() => {
    if (status === "loading") {
      chartComponentRef.current?.chart.showLoading("Loading Reports...");
    } else if (status === "error") {
      chartComponentRef.current?.chart.showLoading("Error Loading Report");
    } else if (
      (reportTicketGraph?.series ?? []).flatMap((v) => v.data).length === 0
    ) {
      chartComponentRef.current?.chart.showLoading("No Data Found");
    } else {
      chartComponentRef.current?.chart.hideLoading();
    }
  }, [reportTicketGraph, status]);

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={optionData}
        ref={chartComponentRef}
        {...props}
      />
    </div>
  );
}

export default Graph;
