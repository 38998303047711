import styles from "./AdvanceBotModal.module.scss";
import closeModal from "src/assets/images/closeModal.png";
import Select, {
  components,
  ControlProps,
  MenuProps,
  OptionProps,
  SingleValueProps,
} from "react-select";
import CustomRangeSlider from "src/components/CustomRangeSlider/CustomRangeSlider";
import { useMemo, useState } from "react";
import { ConfigureSteps } from "../../../useConfigureSteps";
import { UseMutateFunction } from "@tanstack/react-query";
import { CreateBotProfileResponse } from "src/services/Bot/BotProfiles/createBotProfile.service";
import { UpdateBotProfileParams } from "src/services/Bot/BotProfiles/updateBotProfile.service";
import useAdvancedBotConfig from "./useAdvancedBotConfig";
import { BotProfile } from "src/services/Bot/BotProfiles/getAllBotProfiles.service";
import { Spinner } from "react-bootstrap";
import CustomSelectDropdown from "../../../../ConfigureModal/Children/CustomSelectDropdown/CustomSelectDropdown";
import TaggableTextField from "src/components/TaggableTextField/TaggableTextField";
interface Props {
  onHide: () => void;
  refetchGetBot: () => void;
  onSaveComplete: () => void;
  updateBotSettings: UseMutateFunction<
    CreateBotProfileResponse,
    unknown,
    UpdateBotProfileParams,
    unknown
  >;
  configureSteps: ConfigureSteps;
}
interface Option {
  value: string;
  label: string;
}
const AdvanceBotModal = ({
  configureSteps,
  onHide,
  onSaveComplete,
  refetchGetBot,
  updateBotSettings,
}: Props) => {
  // Options for the optimism level of the bot's language
  const options: Array<{
    label: string;
    value: BotProfile["configureAdvancedBotSettingsModel"]["optimismLevelForBotLanguage"];
  }> = [
    { value: "optimistic", label: "Optimistic" },
    { value: "neutral", label: "Neutral" },
    { value: "cautious", label: "Cautious" },
  ];

  // Destructuring values and functions from the custom hook useAdvancedBotConfig
  const {
    isCustomTemperatureEnabled,
    customTemperature,
    optimismLevelForBotLanguage,
    energyLevelForBotResponse,
    isToneAdjustmentForBotResponse,
    toneForBotResponse,
    setIsCustomTemperatureEnabled,
    setCustomTemperature,
    setOptimismLevelForBotLanguage,
    setEnergyLevelForBotResponse,
    setIsToneAdjustmentForBotResponse,
    setToneForBotResponse,
    customToneInstructionEnabled,
    setCustomToneInstructionEnabled,
    customToneInstructionMessage,
    setCustomToneInstructionMessage,
    contentRelatedInstructionEnabled,
    setContentRelatedInstructionEnabled,
    signOffInstruction,
    setSignOffInstruction,
    topicAvoidance,
    setTopicAvoidance,
    personalizeMessage,
    setPersonalizeMessage,
    handleSave,
    showError,
    setShowError,
    showLoader,
    setShowSaved,
    showSaved,
  } = useAdvancedBotConfig({
    configureSteps,
    onSaveComplete,
    refetchGetBot,
    updateBotSettings,
  });

  // Handler for slider change event to update custom temperature
  const handleSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowSaved(false);
    setCustomTemperature(Number(e.target.value));
  };

  // Memoized value to check for errors in tone adjustment for bot response
  const errorToneForBotResponse = useMemo(() => {
    return (
      isToneAdjustmentForBotResponse &&
      toneForBotResponse !== "casual" &&
      toneForBotResponse !== "empathetic" &&
      toneForBotResponse !== "enthusiastic" &&
      toneForBotResponse !== "friendly" &&
      toneForBotResponse !== "professional" &&
      showError
    );
  }, [isToneAdjustmentForBotResponse, toneForBotResponse, showError]);

  // Memoized value to check for errors in energy level for bot response
  const errorEnergyLevelForBotResponse = useMemo(() => {
    return (
      energyLevelForBotResponse !== "balanced" &&
      energyLevelForBotResponse !== "calm" &&
      energyLevelForBotResponse !== "energetic" &&
      showError
    );
  }, [energyLevelForBotResponse, showError]);

  // Memoized value to get the selected optimism level option
  const selectedOptimismLevelOption = useMemo(() => {
    const option = options.find((v) => v.value === optimismLevelForBotLanguage);
    if (option) {
      return option;
    }
    return null;
  }, [options, optimismLevelForBotLanguage]);

  return (
    <div className={`${styles.modalWrapper}`}>
      <div
        className={`px-1 mb-1 d-flex justify-content-between ${styles.header}`}
      >
        <p className={`mb-0 ${styles.headerText}`}>Advanced bot settings</p>
        <img
          src={closeModal}
          alt="closeModal"
          width={11}
          height={11}
          onClick={onHide}
          className="cursor-pointer"
        />
      </div>
      <div className={`px-1 ${styles.contentWrapper}`}>
        <div className={`mb-2 ${styles.prefBox}`}>
          <h1 className={`mb-1 ${styles.heading}`}></h1>
          <div className="d-flex align-items-center justify-content-between">
            <p className={`mb-0 ${styles.checkHead}`}>Custom Temperature</p>
            <div className="form-check form-switch">
              <input
                className={`form-check-input ${styles.switchCheck}`}
                type="checkbox"
                id="flexSwitchCheckDefault"
                checked={isCustomTemperatureEnabled}
                onChange={() => {
                  setShowSaved(false);
                  setIsCustomTemperatureEnabled(!isCustomTemperatureEnabled);
                }}
              />
            </div>
          </div>
          <span className={`mb-1 ${styles.desc}`}>
            Controls the creativity of the bot's responses. Higher values
            produce more varied but unpredictable replies, lower values generate
            more consistent responses.
          </span>
          {isCustomTemperatureEnabled && (
            <div>
              <p className={`mb-1 ${styles.defaultValue}`}>
                Default: <span> 0.50</span>{" "}
              </p>

              <div className="d-flex align-items-center">
                <div className={`w-100 ${styles.rangeWrapper}`}>
                  <CustomRangeSlider
                    min={0}
                    max={1}
                    step={0.01}
                    value={customTemperature}
                    onChange={handleSliderChange}
                  />
                </div>
                <span className={`ms-2 ${styles.currentValue}`}>
                  {" "}
                  {customTemperature}
                </span>
              </div>
            </div>
          )}
        </div>
        <div className={`mb-2 ${styles.prefBox}`}>
          <h1 className={`mb-1 ${styles.heading}`}>Personality Traits</h1>
          <p className={`mb-0 ${styles.checkSubHead}`}>Optimism Level</p>
          <label
            htmlFor=""
            className={`mb-1 ${styles.selectLabel}`}
          >
            Set the overall tone of the bot’s language
          </label>
          <Select
            className={`${
              showError && !selectedOptimismLevelOption
                ? "border border-danger rounded"
                : ""
            }`}
            placeholder="Select level"
            value={selectedOptimismLevelOption}
            options={options}
            styles={customStyles}
            onChange={(value) => {
              if (value) {
                setShowSaved(false);
                setOptimismLevelForBotLanguage(value.value);
              }
            }}
          />
          {/* {showError && !selectedOptimismLevelOption && (
            <span className={`${styles.errorMsg}`}>
              * Please select an option to save settings
            </span>
          )} */}
          <div className="mt-2">
            <p className={`mb-0 ${styles.checkSubHead}`}>Energy Level</p>
            <span className={`mb-1 ${styles.selectLabel}`}>
              Define how energetic or calm the bot’s responses should be
            </span>
            <div className="form-check">
              <input
                className={`form-check-input ${styles.radioCheck} ${
                  errorEnergyLevelForBotResponse ? "border-danger" : ""
                }`}
                type="radio"
                name="energyLevelForBotResponse"
                id="energyLevelForBotResponse1"
                checked={energyLevelForBotResponse === "energetic"}
                onChange={() => {
                  setShowSaved(false);
                  setEnergyLevelForBotResponse("energetic");
                }}
              />
              <label
                className={`form-check-label ${styles.formLabel}`}
                htmlFor="energyLevelForBotResponse1"
              >
                Energetic
              </label>
            </div>
            <div className="form-check">
              <input
                className={`form-check-input ${styles.radioCheck} ${
                  errorEnergyLevelForBotResponse ? "border-danger" : ""
                }`}
                type="radio"
                name="energyLevelForBotResponse"
                id="energyLevelForBotResponse2"
                checked={energyLevelForBotResponse === "balanced"}
                onChange={() => {
                  setShowSaved(false);
                  setEnergyLevelForBotResponse("balanced");
                }}
              />
              <label
                className={`form-check-label ${styles.formLabel}`}
                htmlFor="energyLevelForBotResponse2"
              >
                Balanced
              </label>
            </div>
            <div className="form-check">
              <input
                className={`form-check-input ${styles.radioCheck} ${
                  errorEnergyLevelForBotResponse ? "border-danger" : ""
                }`}
                type="radio"
                name="energyLevelForBotResponse"
                id="energyLevelForBotResponse3"
                checked={energyLevelForBotResponse === "calm"}
                onChange={() => {
                  setShowSaved(false);
                  setEnergyLevelForBotResponse("calm");
                }}
              />
              <label
                className={`form-check-label ${styles.formLabel}`}
                htmlFor="energyLevelForBotResponse3"
              >
                Calm
              </label>
            </div>
          </div>
        </div>
        {(errorEnergyLevelForBotResponse ||
          (showError && !selectedOptimismLevelOption)) && (
          <span className={`${styles.errorMsg}`}>
            * Please select an option to save settings
          </span>
        )}
        <div className={`mb-2 ${styles.prefBox}`}>
          <h1 className={`mb-1 ${styles.heading}`}>
            Response Style Preferences
          </h1>
          <div className="d-flex align-items-center justify-content-between">
            <p className={`mb-0 ${styles.checkSubHead}`}>Tone Adjustment</p>
            <div className="form-check form-switch">
              <input
                className={`form-check-input ${styles.switchCheck}`}
                type="checkbox"
                id="flexSwitchCheckDefault"
                checked={isToneAdjustmentForBotResponse}
                onChange={() => {
                  setShowSaved(false);
                  setIsToneAdjustmentForBotResponse(
                    !isToneAdjustmentForBotResponse,
                  );
                }}
              />
            </div>
          </div>
          <span className={`${styles.desc}`}>
            Select the desired tone for the bot’s responses to align with your
            brand’s voice and customer interaction style.
          </span>
          {isToneAdjustmentForBotResponse && (
            <>
              <div className="form-check">
                <input
                  className={`form-check-input ${styles.radioCheck}  ${
                    errorToneForBotResponse ? "border-danger" : ""
                  }`}
                  type="radio"
                  name="toneForBotResponse"
                  id="toneForBotResponse1"
                  checked={toneForBotResponse == "professional"}
                  onChange={() => {
                    setShowSaved(false);

                    setToneForBotResponse("professional");
                  }}
                />
                <label
                  className={`form-check-label `}
                  htmlFor="toneForBotResponse1"
                >
                  <p className={`mb-0 ${styles.formLabel}`}>Professional</p>
                  <span className={`${styles.formDetail}`}>
                    Bot will use a formal and business-like tone, suitable for
                    official communication.
                  </span>
                </label>
              </div>
              <div className="form-check">
                <input
                  className={`form-check-input ${styles.radioCheck}  ${
                    errorToneForBotResponse ? "border-danger" : ""
                  }`}
                  type="radio"
                  name="toneForBotResponse"
                  id="toneForBotResponse2"
                  checked={toneForBotResponse == "friendly"}
                  onChange={() => {
                    setShowSaved(false);
                    setToneForBotResponse("friendly");
                  }}
                />
                <label
                  className={`form-check-label `}
                  htmlFor="toneForBotResponse2"
                >
                  <p className={`mb-0 ${styles.formLabel}`}>Friendly</p>
                  <span className={`${styles.formDetail}`}>
                    Convey warmth and approachability, making interactions more
                    personable.
                  </span>
                </label>
              </div>
              <div className="form-check">
                <input
                  className={`form-check-input ${styles.radioCheck}  ${
                    errorToneForBotResponse ? "border-danger" : ""
                  }`}
                  type="radio"
                  name="toneForBotResponse"
                  id="toneForBotResponse3"
                  checked={toneForBotResponse == "casual"}
                  onChange={() => {
                    setShowSaved(false);
                    setToneForBotResponse("casual");
                  }}
                />
                <label
                  className={`form-check-label `}
                  htmlFor="toneForBotResponse3"
                >
                  <p className={`mb-0 ${styles.formLabel}`}>Casual</p>
                  <span className={`${styles.formDetail}`}>
                    Adopt a relaxed and informal style, ideal for friendly
                    conversations.
                  </span>
                </label>
              </div>
              <div className="form-check">
                <input
                  className={`form-check-input ${styles.radioCheck}  ${
                    errorToneForBotResponse ? "border-danger" : ""
                  }`}
                  type="radio"
                  name="toneForBotResponse"
                  id="toneForBotResponse4"
                  checked={toneForBotResponse == "enthusiastic"}
                  onChange={() => {
                    setShowSaved(false);
                    setToneForBotResponse("enthusiastic");
                  }}
                />
                <label
                  className={`form-check-label `}
                  htmlFor="toneForBotResponse4"
                >
                  <p className={`mb-0 ${styles.formLabel}`}>Enthusiastic</p>
                  <span className={`${styles.formDetail}`}>
                    Infuse energy and positivity, creating an engaging
                    atmosphere.
                  </span>
                </label>
              </div>
              <div className="form-check">
                <input
                  className={`form-check-input ${styles.radioCheck} ${
                    errorToneForBotResponse ? "border-danger" : ""
                  }`}
                  type="radio"
                  name="toneForBotResponse"
                  id="toneForBotResponse5"
                  checked={toneForBotResponse == "empathetic"}
                  onChange={() => {
                    setShowSaved(false);
                    setToneForBotResponse("empathetic");
                  }}
                />
                <label
                  className={`form-check-label `}
                  htmlFor="toneForBotResponse5"
                >
                  <p className={`mb-0 ${styles.formLabel}`}>Empathetic</p>
                  <span className={`${styles.formDetail}`}>
                    Show compassion and understanding, especially in sensitive
                    situations.
                  </span>
                </label>
              </div>
            </>
          )}
        </div>
        <div className={`mb-2 ${styles.prefBox}`}>
          <h1 className={`mb-1 ${styles.heading}`}>Instructions for Cern</h1>
          <div className="mb-3">
            <div className="d-flex align-items-center justify-content-between">
              <p className={`mb-0 ${styles.checkSubHead}`}>
                Custom Tone Instructions
              </p>
              <div className="form-check form-switch">
                <input
                  className={`form-check-input ${styles.switchCheck}`}
                  type="checkbox"
                  id="flexSwitchCheckDefault"
                  checked={customToneInstructionEnabled}
                  onChange={() => {
                    setShowSaved(false);
                    setCustomToneInstructionEnabled(
                      !customToneInstructionEnabled,
                    );
                  }}
                />
              </div>
            </div>
            <span className={`${styles.desc} mb-2`}>
              Provide specific tone-related instructions for the bot's
              communication style.
            </span>
            {customToneInstructionEnabled && (
              <div>
                <textarea
                  onChange={(e) => {
                    setShowSaved(false);
                    setCustomToneInstructionMessage(e.target.value);
                  }}
                  className={`form-control ${styles.formInput} ${
                    showError && !customToneInstructionMessage?.trim()
                      ? "border border-danger"
                      : ""
                  }`}
                  value={customToneInstructionMessage}
                  placeholder="Add a custom tone instruction....."
                  rows={4}
                ></textarea>
                {showError && !customToneInstructionMessage?.trim() && (
                  <span className={`${styles.errorMsg} d-block`}>
                    * Please fill this field to save settings
                  </span>
                )}
                <span className={`${styles.subInputText}`}>
                  <strong className={`${styles.semiBold}`}>Example:</strong>{" "}
                  "Please address the customer in a professional manner, as if
                  you are a manager."
                </span>
              </div>
            )}
          </div>
          <div className="mb-2">
            <div className="d-flex align-items-center justify-content-between">
              <p className={`mb-0 ${styles.checkSubHead}`}>
                Content related instructions
              </p>
              <div className="form-check form-switch">
                <input
                  className={`form-check-input ${styles.switchCheck}`}
                  type="checkbox"
                  id="flexSwitchCheckDefault"
                  checked={contentRelatedInstructionEnabled}
                  onChange={() => {
                    setShowSaved(false);
                    setContentRelatedInstructionEnabled(
                      !contentRelatedInstructionEnabled,
                    );
                  }}
                />
              </div>
            </div>
            <span className={`${styles.desc} mb-2`}>
              Customize the bot's response content, including sign-offs, topics
              to avoid, specific guidelines, and follow-up actions to match your
              company's style and standards.
            </span>
            {contentRelatedInstructionEnabled && (
              <div>
                <div className="mb-1">
                  <p className={`mb-2 ${styles.subHeading}`}>
                    Sign-off Instructions
                  </p>
                  <span className={`d-block ${styles.subHeadingDesc} mb-2`}>
                    Specify how the bot should sign off messages
                  </span>
                  <textarea
                    onChange={(e) => {
                      setShowSaved(false);
                      setSignOffInstruction(e.target.value);
                    }}
                    className={`form-control ${styles.formInput}`}
                    value={signOffInstruction}
                    placeholder="Add a custom tone instruction....."
                    rows={4}
                  ></textarea>
                  <span className={`${styles.subInputText} mb-2`}>
                    <strong className={`${styles.semiBold}`}>Example:</strong>{" "}
                    "Best regards, Amanda."
                  </span>
                </div>
                <div className="mb-1">
                  <p className={`mb-2 ${styles.subHeading}`}>Topic Avoidance</p>
                  <span className={`d-block ${styles.subHeadingDesc} mb-2`}>
                    List topics the bot should avoid discussing
                  </span>
                  <TaggableTextField
                    value={topicAvoidance || []}
                    placeholder="Add a topic to avoid....."
                    handleChange={(value: string[]) => {
                      if (Array.isArray(value)) {
                        setShowSaved(false);
                        setTopicAvoidance(value);
                      }
                    }}
                  />
                  <span className={`${styles.subInputText} mb-2`}>
                    <strong className={`${styles.semiBold}`}>Example:</strong>{" "}
                    "Avoid pricing questions"
                  </span>
                </div>
                <div className="mb-1">
                  <p className={`mb-2 ${styles.subHeading}`}>Personalization</p>
                  <span className={`d-block ${styles.subHeadingDesc} mb-2`}>
                    Indicate how the bot should personalize messages
                  </span>
                  <input
                    type="text"
                    onChange={(e) => {
                      setShowSaved(false);
                      setPersonalizeMessage(e.target.value);
                    }}
                    className={`form-control ${styles.formInput}`}
                    value={personalizeMessage}
                    placeholder="Add an instruction....."
                  />
                  <span className={`${styles.subInputText} mb-2`}>
                    <strong className={`${styles.semiBold}`}>Example:</strong>{" "}
                    "Use customer's first name"
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        <div className="mt-2 d-flex justify-content-end flex-column align-items-end">
          <button
            className={`px-1 ${styles.saveBtn}`}
            id="saveAdvanceBotModal"
            disabled={showSaved || showLoader}
            onClick={handleSave}
          >
            {showLoader ? (
              <Spinner
                className="my-auto mx-1"
                animation="border"
                color="white"
                size="sm"
              />
            ) : (
              "Save"
            )}
          </button>
          {showSaved && (
            <div className="d-flex align-items-center">
              <div className={`${styles.successCheck}`}>
                <span>
                  <i className="fa-solid fa-check"></i>
                </span>
              </div>
              <span className={`ps-1 ${styles.savedText}`}>
                Settings saved!
              </span>
            </div>
          )}
          {showError && (
            <div className="d-flex align-items-center ">
              <span className={`pe-2 ${styles.errorImg}`}>
                <i className="fa-solid fa-circle-exclamation"></i>
              </span>

              <span className={`${styles.errorText}`}>
                Error! Unable to save settings
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    background: "#f5f5f5",
    minHeight: "32px",
    width: "100%",
    borderRadius: "6px",
    font: "normal normal normal 12px/18px Poppins",
    letterSpacing: "0.4px",
    color: "#000000",
    border: "none",
  }),

  valueContainer: (provided: any, _: any) => ({
    ...provided,
    minWidth: "180px",
    minHeight: "32px",
    font: "normal normal 500 12px/18px Poppins",
    letterSpacing: "0.4px",
    color: "#000000",
  }),

  input: (provided: any, _: any) => ({
    ...provided,
    margin: "0px",
  }),
  dropdownIndicator: (provided: any, _: any) => ({
    ...provided,
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isFocused ? "" : null,
    color: state.isFocused ? "#000000" : null,
    padding: "8px 14px",
    "&:hover": {
      backgroundColor: "#0B68BB",
      color: "#fff",
    },
    font: "normal normal 500 12px/18px Poppins",
    letterSpacing: "0.4px",
  }),
};

const topicAvoidanceDropdownStyles = {
  // Other styles for the React Select can be defined here
  control: (provided: any, state: any) => ({
    ...provided,
    minHeight: "32px",
    width: "100%",
    borderRadius: "6px",
    font: "normal normal normal 10px/16px Poppins",
    letterSpacing: "0.4px",
    color: "#000000",
    borderColor: "#707070",
  }),

  valueContainer: (provided: any, _: any) => ({
    ...provided,
    minWidth: "180px",
    minHeight: "32px",
    font: "normal normal normal 10px/16px Poppins",
    letterSpacing: "0.4px",
    color: "#000",
  }),

  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#0B68BB" : "#fff", // Change the hover color here
    color: state.isFocused ? "#fff" : "#000",
    "&:hover": {
      backgroundColor: "#0B68BB", // Change the hover color here as well
      color: "#fff",
    },
    padding: "10px",
    font: "normal normal normal 10px/16px Poppins",
  }),
  menuList: (provided: any) => ({
    ...provided,
    margin: "0 0", // Add 0 margin on the left and right sides of the menu
  }),
};

const CustomOption: React.FC<OptionProps<Option>> = (props) => (
  <components.Option {...props}>
    <div className={`${styles.items}`}>
      <span>{props.data?.label}</span>
    </div>
  </components.Option>
);

const CustomSingleValue: React.FC<SingleValueProps<Option>> = (props) => (
  <components.SingleValue {...props}>
    <div className={`${styles.items}`}>
      <span>{props.data?.label}</span>
    </div>
  </components.SingleValue>
);
export default AdvanceBotModal;
