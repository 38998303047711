import { axiosJSON } from "src/globals/axiosEndPoints";
import {
  BaseConfigModalTabType,
  SelectedConditionType,
  GetConfigModalParams,
} from "src/features/ReturnAutoWorkFlow/children/ConfigModals/oldReturnModals.types";
import { IRestockingFeeType } from "src/features/ReturnAutoWorkFlow/children/ConfigModals/store/slices/RestockingFeeSlice/restockingFee.slice";

export interface RestockingFeeTab extends BaseConfigModalTabType {
  selectReturnReasons: SelectedConditionType[];
  restockingFee: IRestockingFeeType;
  restockingFeeMessage: string;
}

async function getRestockingFeeService(params: GetConfigModalParams) {
  const { data: res } = await axiosJSON.get(
    "/api/setting/automation/refund/step/15/configuration",
    { params },
  );

  if (res.err || res.error) {
    throw new Error(res.msg ?? res.message ?? "Some Error Occurred.");
  }

  return res.data as RestockingFeeTab[];
}

export default getRestockingFeeService;
