import styles from "./UserNActionHeader.module.scss";
import userImg from "src/assets/images/user.png";
import { useAppSelector } from "src/store/store";
import UserAvatar from "src/components/UserAvatar";
import { getTimeFromNow } from "src/utils/dateLibrary";
import DownloadFile from "src/components/DownloadFile";
import { Spinner } from "react-bootstrap";
import { useState } from "react";
import AxiosImg from "src/components/AxiosImg";

const UserNActionHeader = ({
  attachment,
  messageData,
  className = "",
}: any) => {
  const channelList = useAppSelector((state) => state.innerTicket.channelList);
  const userTimezone = useAppSelector(
    (state) => state.globals.currentUserData?.userTimezone
  );
  const [loading, setLoading] = useState(false);

  return (
    <div
      className={`position-absolute top-0 w-100 h-100 ${styles.mainDiv} ${className}`}
    >
      <div>
        <div className={`d-flex flex-row`}>
          <div
            className={`d-flex flex-column rounded-circle ${styles.userImgDiv}`}
          >
            {messageData.senderImgURL &&
            messageData.senderImgURL.trim() !== "" ? (
              <AxiosImg
                className={`${styles.userImg} rounded-circle`}
                url={messageData.senderImgURL}
                isDirectURL={messageData?.isPublicAttachmentUrl}
              />
            ) : (
              <UserAvatar
                className={`${styles.userImg}`}
                // messageData.agentName is added, because draft msg has different keys
                name={messageData.senderName || messageData.agentName || "NA"}
                size={35}
              />
            )}
          </div>
          <div className={`d-flex flex-column mx-2`}>
            <div className={`d-flex flex-row ${styles.userName}`}>
              {messageData.senderName || messageData.agentName}
            </div>
            <div className={`d-flex flex-row ${styles.fileNameNTime}`}>
              {getTimeFromNow({
                date:
                  messageData.messageDateTime ||
                  messageData.messageDate ||
                  messageData.remarkDate,
                timeZone: userTimezone,
              })}{" "}
              {messageData.messageId === 0
                ? "via " + messageData.channelId
                : channelList[messageData.channelId as any]
                ? "via " + channelList[messageData.channelId as any].name
                : ""}{" "}
              - {attachment.attachmentName}
            </div>
          </div>
          {/* file action  */}
          <div
            className={`position-absolute end-0 d-flex flex-row text-center m-2 ${styles.fileActionMainDiv}`}
          >
            <DownloadFile
              url={attachment.attachmentUrl}
              fileName={attachment.attachmentName}
              className={`btn p-0`}
              loading={loading}
              setLoading={setLoading}
              stopPropagation={true}
            >
              <div
                className={`d-flex flex-column text-center ${styles.fileDownload}`}
              >
                {loading ? (
                  <Spinner
                    className={`m-auto`}
                    animation="border"
                    size="sm"
                    color="grey"
                  />
                ) : (
                  <i
                    className={`fas fa-cloud-download-alt p-1 ${styles.downloadIcon}`}
                  ></i>
                )}
              </div>
            </DownloadFile>
            {/* <div className={`d-flex flex-column mx-2 ${styles.fileForward}`}>
              <i className={`fa fa-mail-forward p-1 ${styles.forwardIcon}`}></i>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserNActionHeader;
