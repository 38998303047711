// {
//   "access_token": "................"
// }

import { axiosJSON } from "src/globals/axiosEndPoints";

export async function getAccessToken() {
  const { data } = await axiosJSON.post(`/api/getAccessToken`);
  if (data.status == "0") {
    throw new Error(data.message as string);
  }
  return data;
}
