import { axiosJSON } from "src/globals/axiosEndPoints";
import { SegmentDetails } from "./createSegment.service";

export interface UpdateSegmentParams extends SegmentDetails {
  segmentType: string;
}

async function updateSegmentService({
  segmentType,
  ...params
}: UpdateSegmentParams) {
  const { data: res } = await axiosJSON.post(
    `/api/segment/${segmentType}/update`,
    params,
  );

  if (res.statusCode === "duplicateSegmentName") {
    throw new Error("Given segment name already exists.");
  }
  if (res.error || res.err) {
    throw new Error(res.msg ?? res.message ?? "Something went wrong!");
  }

  const ret: SegmentDetails = {
    ...res.data,
    segmentType,
  };
  return ret;
}

export default updateSegmentService;
