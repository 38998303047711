import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  selectTicketCellData,
  updateTicketCellDataByKey
} from "src/store/slices/tickets/tickets.slice";
import { TableCellProps } from "..";
import TicketCurrentStatus from "src/components/TicketCurrentStatus";
import styles from "./ResponseTicketStatus.module.scss";

function ResponseTicketStatus({ ticketId, colKeys }: TableCellProps) {
  const dispatch = useAppDispatch();
  const ticketCellData: any = useAppSelector((state) =>
    selectTicketCellData(state, ticketId, colKeys)
  );
  const currentUserData = useAppSelector(
    (state) => state.globals.currentUserData
  );

  return (
    <div className={`ignoreClick ${styles.ResponseTicketMain}`}>
      {ticketCellData[0] ? (
        <TicketCurrentStatus
          className={`${getStyleForTicketStatus(
            ticketCellData[0].ticketStatusName
          )} shadow`}
          ticketId={ticketId}
          currentStatus={{
            id: ticketCellData[0].ticketStatusId,
            statusName: ticketCellData[0].ticketStatusName
          }}
          onChange={(status) => {
            dispatch(
              updateTicketCellDataByKey({
                ticketId,
                colKey: colKeys[0],
                cellData: {
                  ticketStatusId: status.id,
                  ticketStatusName: status.statusName
                }
              })
            );
          }}
          canChange={
            currentUserData &&
            currentUserData.scopes &&
            currentUserData.scopes.includes("change_status")
              ? true
              : false
          }
        />
      ) : (
        <span className={`shadow`}>
          <span className={`${styles.resDot}`}></span>
          NA
        </span>
      )}
    </div>
  );
}

const getStyleForTicketStatus = (ticketStatus: string) => {
  switch (ticketStatus.toLowerCase()) {
    case "open":
      return styles.ticketStatusOpen;
    case "closed":
      return styles.ticketStatusClosed;
    case "pending":
      return styles.ticketStatusPending;
    default:
      return styles.ticketStatusDefault;
  }
};

export default ResponseTicketStatus;
