import { useMemo, useRef, useState } from "react";
import styles from "./TimelineInfo.module.scss";
import timelineArrow from "src/assets/images/timelineArrow.png";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useAppSelector } from "src/store/store";
import Timeline from "./Timeline/Timeline";
import Loader from "src/components/Loader";
import { TimelineEvent } from "src/store/slices/shopifySidebar/shopifySidebar.slice";

function formatDateTime(
  inputDateTime: string,
  userTimezone: string | undefined,
) {
  const dateObj = new Date(inputDateTime + "Z");

  const day = dateObj.getDate();
  const month = dateObj
    .toLocaleString("en-US", { month: "short" })
    .toUpperCase();
  const time = dateObj.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    timeZone: userTimezone,
  });

  return `${day} ${month} ${time}`;
}

export const LastUpdated = () => {
  const { timelineInfo, id } = useAppSelector(
    (state) => state.shopifySidebar.customerData,
  );

  const userTimezone = useAppSelector(
    (state) => state.globals.currentUserData?.userTimezone,
  );

  if (!timelineInfo?.last_updated) {
    return <></>;
  }

  return (
    <div className={`${styles.lastUpdateWrap}`}>
      <OverlayTrigger
        placement="top"
        trigger={"hover"}
        overlay={
          <Tooltip className={`${styles.toolTipCustom}`}>
            <span>
              Last updated on{" "}
              {formatDateTime(timelineInfo.last_updated, userTimezone)}
            </span>
          </Tooltip>
        }
      >
        {({ ref, ...triggerHandler }) => (
          <div
            {...triggerHandler}
            className={`${styles.lastUpdated}`}
            ref={ref}
          >
            Last updated
          </div>
        )}
      </OverlayTrigger>
    </div>
  );
};

const TimelineInfo = () => {
  const [iconImg, setIconImg] = useState(true);
  const [showTimeline, setShowTimeline] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { timelineInfo, id } = useAppSelector(
    (state) => state.shopifySidebar.customerData,
  );
  const customerDataAJAXStatus = useAppSelector(
    (state) => state.shopifySidebar.customerDataStatus,
  );

  const showLoader = useMemo(() => {
    if (timelineInfo) {
      return false;
    }

    return customerDataAJAXStatus === "pending" ? true : false;
  }, [customerDataAJAXStatus, timelineInfo]);

  const currentState = useRef({
    lastTimelineBgColor: "#B1E3FF",
  });

  useMemo(() => {
    try {
      // Get the root element
      const r: any = document.querySelector(":root");
      if (r) {
        if (showTimeline) {
          // Set the value of variable --inner-ticket-main-chat-containerto another value (in this case "calc(100vh - 267px)")
          r.style.setProperty(
            "--inner-ticket-main-chat-container",
            "calc(100vh - 271px)",
          );
        } else {
          r.style.setProperty(
            "--inner-ticket-main-chat-container",
            "calc(100vh - 216px)",
          );
        }
      }
    } catch (e) {}
  }, [showTimeline]);

  if (showLoader) {
    return (
      <div className={`${styles.loader}`}>
        <Loader />
      </div>
    );
  }

  if (error) {
    return <div className={`${styles.error}`}>{error}</div>;
  }

  if (!timelineInfo) {
    return null;
  }

  const interleaveEvents = (minor: TimelineEvent[], major: TimelineEvent[]) => {
    const interleaved: (TimelineEvent & { type: "minor" | "major" })[] = [];
    const minorGroups: (TimelineEvent & { type: "minor" | "major" })[] = [
      { ...minor[0], type: "minor" }, // Group 1: January, February, March
      { ...minor[1], type: "minor" }, // Group 2: April, May, June
      { ...minor[2], type: "minor" }, // Group 3: July, August, September
      { ...minor[3], type: "minor" }, // Group 4: October, November, December
    ];
    const majorGroups: (TimelineEvent & { type: "minor" | "major" })[] = [
      { ...major[0], type: "major" }, // First 6 months
      { ...major[1], type: "major" }, // Last 6 months
    ];

    interleaved.push(minorGroups[0]); // minor
    interleaved.push(majorGroups[0]); // major
    interleaved.push(minorGroups[1]); // minor
    interleaved.push(minorGroups[2]); // minor
    interleaved.push(majorGroups[1]); // major
    interleaved.push(minorGroups[3]); // minor

    return interleaved;
  };

  const renderEvent = (
    event: TimelineEvent & { type: "minor" | "major" },
    index: number,
    bgColor: string,
  ) => {
    if (event.details?.length === 0) {
      return (
        <div
          key={index}
          className={`test ${styles.timelineWrapper} d-flex ${
            showTimeline ? styles.expanded : ""
          }`}
        >
          <div className={`d-flex flex-column ${styles.flexOne}`}>
            <div className="d-flex">
              <div className="position-relative d-flex align-items-center w-100">
                <div
                  style={{
                    border: bgColor,
                    backgroundColor: bgColor,
                  }}
                  className={`${styles.verticalLine} ${
                    event.type == "minor"
                      ? styles.minorEvent
                      : styles.majorEventEmpty
                  } ${showTimeline ? styles.expanded : ""}`}
                ></div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div
        key={index}
        className={`${styles.timelineWrapper} d-flex ${
          showTimeline ? styles.expanded : ""
        }`}
      >
        <div className="d-flex flex-column">
          <div className="d-flex">
            {event.details?.map((detail, detailIndex) => {
              currentState.current.lastTimelineBgColor =
                bgColor || currentState.current.lastTimelineBgColor;
              return (
                <div
                  key={detailIndex}
                  className="position-relative d-flex align-items-center justify-content-center"
                >
                  <Timeline
                    date={detail.datetime}
                    expanded={showTimeline}
                    {...detail}
                    index={detailIndex}
                    customerId={id}
                  />
                  <div
                    style={{
                      border: bgColor,
                      backgroundColor: bgColor,
                    }}
                    className={`${styles.verticalLine} ${
                      showTimeline ? styles.expanded : ""
                    } ${event.type == "minor" ? styles.minorEvent : ""}`}
                  ></div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`d-flex align-items-center position-relative justify-content-between pe-2`}
    >
      <div
        className={`d-flex align-items-center`}
        style={{
          height: `${showTimeline ? "154px" : "100px"}`,
          padding: "0 0 0 0px",
        }}
      >
        {timelineInfo.timeline && timelineInfo.timeline.length ? (
          <span className={`px-2 d-none ${styles.startYear}`}>
            {timelineInfo.timeline[0].year}
          </span>
        ) : null}
        {timelineInfo.timeline?.map((yearData) => {
          try {
            const interleavedEvents = interleaveEvents(
              yearData.events.minor,
              yearData.events.major,
            );
            return (
              <div
                key={yearData.year}
                className="d-flex align-items-center justify-content-center"
              >
                <p
                  className={`mb-0 ${styles.dateTime} ${
                    showTimeline ? styles.activeDateTime : ""
                  }`}
                >
                  {yearData.year}
                </p>
                {interleavedEvents.map((event, index) =>
                  renderEvent(event, index, yearData.bgColor || ""),
                )}
              </div>
            );
          } catch (e) {
            setError("Failed to load the resource");
            console.error("Failed to load the resource", e);
            return null;
          }
        })}
        <div className="d-flex w-100">
          <div className="position-relative d-flex align-items-center w-100">
            <div
              style={{
                borderTop: "8px dashed #B1B1B1",
                backgroundColor: "#fff",
              }}
              className={`${styles.verticalLine} w-100 ${
                showTimeline ? styles.expanded : ""
              }`}
            ></div>
          </div>
        </div>
      </div>

      <div>
        <span
          className={`cursor-pointer ms-2 ${styles.toggleBtn}`}
          onClick={() => setShowTimeline(!showTimeline)}
        >
          <i
            className={`fa-solid fa-chevron-${showTimeline ? "up" : "down"}`}
          ></i>
        </span>
      </div>
    </div>
  );
};

export default TimelineInfo;
