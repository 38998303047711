import React, { useEffect, useMemo, useState } from "react";
import styles from "./CountryDropdown.module.scss";
import CustomSelectDropdown from "../../../CustomSelectDropdown/CustomSelectDropdown";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { getCountryListV2 } from "src/store/slices/globals/globals.slice";

interface Options {
  value: string | number;
  label: string;
}
interface Props {
  value: string | null | Array<string | number>;
  handleChange: (value: Array<string | number>) => void;
  error?: string | null;
}

const CountryDropdown = ({ value, handleChange, error }: Props) => {
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const countryList = useAppSelector((state) => state.globals.countryList);

  useEffect(() => {
    if (!countryList.loading && !countryList.data?.length) {
      setLoading(true);
      dispatch(getCountryListV2()).finally(() => setLoading(false));
    }
  }, [countryList]);

  const countryOptions = useMemo(() => {
    if (countryList.data) {
      return countryList.data.map((option) => {
        return {
          label: option.name,
          value: option.code,
        };
      });
    }

    return [];
  }, [countryList.data]);

  const [selectedCountry, setSelectedCountry] = useState<Options[]>();

  useEffect(() => {
    if (value && Array.isArray(value) && countryOptions) {
      const selectedOptions = value
        .map((value) => {
          return countryOptions.find((option) => option.value == value);
        })
        .filter((item) => item !== undefined && item !== null);

      setSelectedCountry(selectedOptions as Options[]);
    }
  }, [value, countryOptions]);

  return (
    <div className="mb-1">
      <CustomSelectDropdown
        selectedOption={selectedCountry}
        setSelectedOption={(value) => {
          if (Array.isArray(value)) {
            handleChange(value.map((item) => item.value));
          } else {
            handleChange([value.value]);
          }
        }}
        options={countryOptions}
        menuClassName={`${styles.containDrop}`}
        placeholder="select country"
        isMultiSelect={true}
        isSearchable={true}
        isLoading={loading}
        error={selectedCountry?.length ? undefined : error}
      />
      {error ? (
        <span className={`${styles.errorText} text-danger`}>{error}</span>
      ) : null}
    </div>
  );
};

export default CountryDropdown;
