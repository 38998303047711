import { axiosJSON } from "src/globals/axiosEndPoints";
import APIResponse from "./APIReponse";
import { User } from "./getAllUser";

export interface CurrentUser extends User {
  tenantTimezone: string;
  websocket_channel_name?: string;
  websocket_private_channel_name?: string;
  socket_credentials?: {
    key: string;
    cluster: string;
  };
  userTimezone: string;
  disabledBrandIds: Array<number | string>;
  userTimezoneType: string;
  outerTicketColumn?: string;
  scopes: Array<
    | "add_user" //
    | "add_team" //
    | "add_ticket" //
    | "transfer_ticket" //
    | "add_view" //
    | "ticket_view" //
    | "response_view" //
    | "customer_view" //
    | "bulk_edit" //
    | "add_tags" //
    | "reassign_ticket" //
    | "change_status" //
    | "send_bulk_message" //
    | "merge_tickets" //
    | "send_message" //
    | "view_templates" //
    | "edit_templates" //
    | "add_internal_note" //
    | "add_draft" //
    | "add_draft_remark" //
    | "view_draft_remark" //
    | "change_draft_status" //
    | "view_user" //
    | "add_tags" //
    | "can_handle_draft_void" //
    | "edit_settings"
    | "edit_billing"
    | "view_billing"
    | "browse_integration"
    | "settings_can_edit_email_signature"
    // For report notify settings
    | "show_report_notification_settings"
    | "edit_report_notification_settings"
    // For report notify
    | "show_report_notification"
    | "edit_report_notification"
  > | null;
  ui_visibility?: Array<
    | "ticket_list_ticket_view"
    | "ticket_list_response_view"
    | "ticket_list_customer_view"
    | "chat_bot_view"
    | "knowledge_base_view"
    | "integration_facebook_view" // used to check if the facebook integration card should be shown in browser home page or not
    | "integration_sms_view" // used to check if the sms integration card should be shown in browser home page or not
    | "integration_whatsapp_view" // used to check if the whatsapp integration card should be shown in browser home page or not
    | "reports_view" // used to check if the reports page should be shown in browser home page or not
    | "recordings_view" // used to check if the recordings page should be shown in browser home page or not
    // Report endpoints
    | "tickets_currentStatus_totalUnresolvedTickets"
    | "tickets_currentStatus_ticketsMostBackForth"
    | "tickets_currentStatus_unresolvedTicketsPendingLongest"
    | "tickets_currentStatus_usualCapacity"
    | "tickets_currentStatus_potentiallyUrgent"
    | "tickets_currentStatus_angryCustomers"
    | "tickets_currentStatus_convertedPrePurchase"
    | "tickets_currentStatus_notConvertedPrePurchase"
    | "tickets_volumeActivity_averageTicketsGraph"
    | "tickets_volumeActivity_backlogPercentage"
    | "tickets_volumeActivity_responseSent"
    | "tickets_volumeActivity_ticketsClosed"
    | "tickets_volumeActivity_ticketsCreated"
    | "knowledgeBase_articlesMostRead"
    | "knowledgeBase_newArticlesAddedByTime"
    | "knowledgeBase_highestRatedArticles"
    | "knowledgeBase_lowestRatedArticles"
    | "knowledgeBase_authorsMostArticles"
    | "knowledgeBase_ticketsResolvedDueToKb"
    | "liveChat_totalChats_totalChatReports"
    | "liveChat_totalChats_totalChatHeatmapReports"
    | "liveChat_missedChats_missedChatsReports"
    | "liveChat_chatSatisfaction_chatSatisfactionReports"
    | "liveChat_chatAvailability_chatAvailabilityReports"
    | "tickets_performance_ticketsFirstReply"
    | "tickets_performance_ticketsFullResolution"
    | "tickets_performance_ticketsFirstReplyOverTime"
    | "tickets_performance_ticketsFullResolutionOverTime"
    | "tickets_performance_averageTicketRating"
    | "tickets_revenue_revenueGeneratedByTime"
    | "integration_shopify_view" // used to check if the shopify integration card should be shown in browser home page or not
    | "bot_percentageCernFoundUseful"
    | "bot_volumeOfChartsByCern"
    | "bot_chartsCernDidNotFindAnswersByTime"
    | "bot_mostUsedArticlesByCernToAnswerQuestions"
    | "bot_mostUsedCustomAnsByCernToAnswerQuestions"
    | "automation_view"
    | "company_segment_view" //Added to show company_segment_view
    | "user_segment_view"
    | "billing_view"
    | "inner_ticket_schedule_message"
    | "live_chat_schedule_message"
    | "automation_return_exchange_view" // Added for Shopify return admin
    | "email_bot_enabled" // To show email bot settings
    | "bug_management" // Show bug management
    | "issue_dispatch" // Show Issue dispatch
    | "forward_email_feature" // Show forward option in livechat/ticket
    | "automation_ticket_routing" // Ticket routing automation
    | "slack_integration_feature" // Shows slack integration in settings
  > | null;
  // For disabling certain features for current user
  disabledFeatures?: Array<
    | "send_email" // Send email in inner ticket view
    | "reports" // Disable all Reports
    | "bot_profile" // Disable Bot Profile
    | "knowledge_base" // Disable Knowledge Base
    | "email_integration" // Disable Email Integration
    | "add_email_integration" // Disable Add email integration
    | "facebook_integration" // Disable Facebook or Instagram Integration
    | "whatsapp_integration" // Disable Whatsapp Integration
    | "send_chat" // Send chat message in live chat
    | "add_brand" // Disable add brand in settings
    | "add_teams" // Disable add teams in settings
    | "add_users" // Disable add users in settings
    | "add_ticket" // Disable add ticket in ticket view
    | "external_source" // Disable External Source in bot profiles
  > | null;
  chatAcceptStatus?: boolean; // Optional property representing the chat acceptance status (boolean)
  agentStatusChannel?: string; // Optional property representing the agent status pusher channel (string)
  agentStatus?: {
    // Optional property representing the agent online status
    status: "online" | "away" | "offline";
    displayName: string;
  };
  showFirstLogin?: boolean;
  version?: string;
}

export async function getCurrentUser() {
  const { data: res } = await axiosJSON.get<APIResponse<CurrentUser>>(
    "/api/setting/user/getCurrentUser",
    { params: { cacheIt: true, cacheTime: 30000 } },
  );

  if (res.error === true) {
    throw new Error(res.message as string);
  }

  return res.data as CurrentUser;
}
