import { useMemo } from "react";
import Modal from "react-bootstrap/Modal";
import styles from "./CenterModal.module.scss";

function CenterModal({
  customTitleComp,
  customFooterComp,
  bodyClassName,
  ...props
}: any) {
  const CustomFooterComp = useMemo(() => customFooterComp, [customFooterComp]);
  return (
    <Modal
      {...props}
      size="lg"
      dialogClassName={`${props.dialogClassName || styles.mainDialog}`}
      contentClassName={`${props.contentClassName || styles.mainContent}`}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={props.modalStyles}
    >
      <Modal.Header className={`${styles.mainHeader}`}>
        <Modal.Title
          id="contained-modal-title-vcenter"
          as={customTitleComp || (() => <></>)}
        >
          {props.titleText} className={`${styles.mainTitle}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={`${bodyClassName}`}>{props.children}</Modal.Body>
      <Modal.Footer className={`${styles.mainFooter}`}>
        {CustomFooterComp && <CustomFooterComp setModalShow={props.onHide} />}
      </Modal.Footer>
    </Modal>
  );
}

export default CenterModal;
