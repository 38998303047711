import styles from "./Performance.module.scss";
import FilterWrapper from "../FilterWrapper/FilterWrapper";
import ReportBar from "src/components/svg/ReportBar";
import { useReportFiltersCreate } from "../../hooks/reportFilters/useReportFilters";
import ReportDatePicker from "../ReportDatePicker/ReportDatePicker";
import { reportCurrentStatusActions } from "../../hooks/reportFilters/useReportFilters";
import PerformanceReports from "./Children/PerformanceReports/PerformanceReports";

const Performance = () => {
  const [state, dispatch, Provider] = useReportFiltersCreate(); // Getting the state and dispatch from the custom hook


  return (
    // Providing the state and dispatch to the child components
    <Provider value={[state, dispatch]}>
      <div>
        <div
          className={`d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center `}
        >
          <div className={`d-flex align-items-center`}>
            <span>
              <ReportBar />
            </span>
            <span className={`ms-2 ${styles.heading}`}>Performance</span>
          </div>
          <ReportDatePicker
            startDate={state.startDate}
            endDate={state.endDate}
            onChange={([startDate, endDate]) => {
              dispatch([reportCurrentStatusActions.setStartDate, startDate]);
              dispatch([reportCurrentStatusActions.setEndDate, endDate]);
            }}
          />
        </div>
        <div>
          <FilterWrapper />
          <PerformanceReports />
        </div>
      </div>
    </Provider>
  );
};

export default Performance;
