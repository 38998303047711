import React, { useCallback, useEffect, useRef, useState } from "react";
import QuillEditorContainer from "src/components/QuillEditorContainer";
import styles from "./CustomMsgModal.module.scss";
import success from "src/assets/images/successMsg.png";
import send from "src/assets/images/send.png";
import { AJAXSTATUS } from "src/globals/constants";
import { Spinner } from "react-bootstrap";
import ParseHTML from "src/components/ParseHTML";
interface Props {
  onHide: (e: any) => void;
  script: string;
  toEmailAddress: string;
  onSendMail: (value: string) => void;
  sendMailAjaxStatus: AJAXSTATUS;
  showSuccess: boolean;
}
const CustomMsgModal = ({
  script,
  toEmailAddress,
  onHide,
  onSendMail,
  sendMailAjaxStatus,
  showSuccess,
}: Props) => {
  const messageRef = useRef(null as any);

  // useEffect(() => {
  //   //adding the script to message div
  //   if (script && messageRef.current) {
  //     messageRef.current.innerText = script; //adding as innerText otherwise it will render it as script tag
  //   }
  // }, [script]);

  //manully focusing user to editable div
  const handleOnClick = useCallback(()=>{
    if(messageRef.current){
      messageRef.current.focus();
    }
  },[messageRef.current]);

  return (
    <>
      {showSuccess === false ? (
        <div className="">
          <div className="mb-2 d-flex justify-content-between align-items-center">
            <p className={`mb-0 ${styles.heading}`}>Add custom message</p>
            <span className="cursor-pointer" onClick={onHide}>
              <i className="fa-solid fa-xmark"></i>
            </span>
          </div>
          <div className={`${styles.quillWrapper} p-3`} onClick={handleOnClick}>
            <div
              ref={messageRef}
              contentEditable={true}
              className={`${styles.editorBox}`}
            ></div>
            <div>
            {script}
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <button
              className={`btn d-flex align-items-center ${styles.sendButton} mt-2`}
              onClick={() => onSendMail(messageRef.current.innerHTML)}
              disabled={sendMailAjaxStatus === "pending"}
            >
              {sendMailAjaxStatus === "pending" ? (
                <Spinner animation="border" size="sm" className={`d-block`} />
              ) : (
                <>
                  <span>Send</span>
                  <span className="ps-2">
                    <img src={send} className={`${styles.sendMsg}`} alt="" />{" "}
                  </span>
                </>
              )}
            </button>
          </div>
        </div>
      ) : (
        <>
          {/* Following div belongs to success message Hide for now */}
          <div className="py-2 ">
            <div className="text-center">
              <img src={success} alt="" />
            </div>
            <div className="mt-2 text-center" >
              <span className={`${styles.successHead}`}>
                Helplama Live Chat Widget code is successfully sent to the
                following emails{" "}
              </span>
              <span className={`${styles.successSpan}`}>{toEmailAddress}</span>
            </div>
            <div className={`p-2 mt-3 ${styles.msgWrapper}`}>
              <code className={`${styles.code}`}>
                <ParseHTML html={messageRef.current?.innerHTML ?? ""} />
                {script}
              </code>
            </div>
            <div className="mt-3">
              <button className={`${styles.okBtn}`} onClick={onHide}>
                Ok
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CustomMsgModal;
