/**
 * This file is the redux slice declarations file used for storing KB data interfaces.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import helpLogo from "src/assets/images/helpCenter.png";

interface Styling {
  themeId: number; // For Identifying which type of theme is selected
  themeName: string; // Name of the theme
  branding: {
    logoImage: string | null; // Logo of the theme
    favicon: string | null;
    footerLogo: string | null;
    helplamaBranding: boolean;
  };
  colorsAndBackgrounds: {
    backGroundColor: string;
    brandColor: string;
    backGroundImage: string | null;
  };
  wording: {
    headline: string;
    subheading: string;
    placeholderText: string;
  };
  font: {
    mainFont: string;
  };
  language: {
    defaultLanguage: string;
    customizeTextDefault: {
      zero_article: string;
      one_article: string;
      n_article: number;
      noArticleTitle: string;
    };
  };
  customCode: {
    head: string;
    body: string;
  };
}

export interface KnowledgeBaseSlice {
  pageStyling: Styling;
}

/**
 * The default data.
 */
export const initialState: KnowledgeBaseSlice = {
  pageStyling: {
    themeId: 0,
    themeName: "Alice in Wonderland",
    branding: {
      logoImage: helpLogo,
      favicon: helpLogo,
      footerLogo: helpLogo,
      helplamaBranding: true,
    },
    colorsAndBackgrounds: {
      backGroundColor: "#0B68BB",
      brandColor: "#0B68BB",
      backGroundImage: helpLogo,
    },
    wording: {
      headline: "Hi, How can we help?",
      subheading: "Demo",
      placeholderText: "Search for answers",
    },
    font: {
      mainFont: "Roboto",
    },
    language: {
      defaultLanguage: "en",
      customizeTextDefault: {
        zero_article: "0 articles",
        one_article: "1 article",
        n_article: 0,
        noArticleTitle: "This article has no title",
      },
    },
    customCode: {
      head: "",
      body: "",
    },
  },
};
