import { useEffect, useMemo, useRef, useState } from "react";
import { findScrollContainer, sanitizeHTML } from "src/utils/utils";
import styles from "./ParseHTML.module.scss";
import { showAttachment } from "src/services/Attachment/showAttachment";

interface Props {
  html: string;
  truncate?: string;
  replaceNewLineWithBr?: boolean;
  [key: string]: any;
}

function ParseHTML({
  html,
  truncate = "",
  replaceNewLineWithBr = false,
  ...props
}: Props) {
  const seeMoreContRef = useRef("See Full Thread");
  const htmlRef: any = useRef(null);

  const { htmlPart, seeMorePart } = useMemo(() => {
    let htmlValue = sanitizeHTML(html);
    if (replaceNewLineWithBr === true) {
      htmlValue = htmlValue.replaceAll("\n", "<br>");
    }
    const div = document.createElement("div");
    div.innerHTML = htmlValue;

    const seeMoreSelector = div.querySelector(".seemore-content");

    const seeMorePart = seeMoreSelector?.innerHTML;

    if (seeMoreSelector && seeMorePart) {
      seeMoreSelector.innerHTML = "";
    }

    return {
      htmlPart: div.innerHTML,
      seeMorePart,
    };
  }, [html, replaceNewLineWithBr]);

  useEffect(() => {
    if (htmlRef.current) {
      htmlRef.current.innerHTML = htmlPart.includes(`src="cid:`)
        ? showAttachment({
            html: htmlPart,
          })
        : htmlPart;

      if (
        truncate &&
        htmlRef.current.scrollHeight > htmlRef.current.offsetHeight
      ) {
        htmlRef.current.classList.add(truncate);
      }

      try {
        seeMoreContRef.current =
          htmlRef.current.querySelector(".seemore-btn")?.innerText ??
          seeMoreContRef.current;
        htmlRef.current
          .querySelector(".seemore-btn")
          ?.addEventListener("click", (e: any) => {
            e.preventDefault();
            const parentScroll: any = findScrollContainer(e.currentTarget);
            try {
              parentScroll.style.overflow = "hidden";
            } catch {}
            if (e.currentTarget.classList.contains("hide")) {
              htmlRef.current
                .querySelector(".seemore-content")
                .classList.add("d-none");
              e.currentTarget.innerHTML = seeMoreContRef.current;
              e.currentTarget.classList.remove("hide");
            } else {
              const seeMoreEle =
                htmlRef.current.querySelector(".seemore-content");
              if (seeMorePart && seeMoreEle.innerHTML === "") {
                seeMoreEle.innerHTML = seeMorePart.includes(`src="cid:`)
                  ? showAttachment({
                      html: seeMorePart,
                    })
                  : seeMorePart;
              }
              seeMoreEle.classList.remove("d-none");
              e.currentTarget.innerHTML = "Hide";
              e.currentTarget.classList.add("hide");
            }
            try {
              parentScroll.style.overflow = "auto";
            } catch {}
          });
      } catch {}
    }
  }, [htmlPart, seeMorePart, truncate]);

  return (
    <div>
      <div
        ref={htmlRef}
        {...props}
        className={`${styles.htmlDiv} ${props.className}`}
      ></div>
      {/* <div className="d-flex ms-1 mt-1">
        <div className={`${styles.readMoreDotDiv} me-0 my-auto d-flex p-1`}>
          <i className={`${styles.readMoreDotDivI}`}></i>
          <i className={`${styles.readMoreDotDivI}`}></i>
          <i className={`${styles.readMoreDotDivI}`}></i>
        </div>
        <span
          className={`${styles.readMoreTextSpan} ms-2`}
          onClick={() => {
            setShowFullContent(!showFullContent);
          }}
        >
          {showFullContent === true ? "Read less" : "Read more"}
        </span>
      </div> */}
    </div>
  );
}

export default ParseHTML;
