import { axiosJSON } from "src/globals/axiosEndPoints";
import log from "loglevel";

export type AddViewPayload = {
  viewId?: number;
  viewName: string;
  options: { key: string; value: string }[];
};

export default async function addViewApi(servicePayload: AddViewPayload) {
  const { data } = await axiosJSON.post(
    `/api/views/${servicePayload.viewId ? "editView" : "addView"}`,
    servicePayload
  );
  if (data.err === true) {
    throw new Error(data.msg as string);
  }
  const normalizedData = normalizeData(data);
  // log.debug("add view raw response:", data);
  // log.debug("add view normalized response:", normalizedData);
  return normalizedData;
}

// convert incoming data to the required format
function normalizeData(response: any) {
  const normalizedData = response;
  return normalizedData;
}
