import { Spinner } from "react-bootstrap";
import styles from "./DeleteArticle.module.scss";
import useDeleteArticle from "./useDeleteArticle";
interface Props {
  HeadingText: string;
  subText?: string;
  submitBtnText: string;
  cancelBtnText: string;
  handleConfirm: any;
  onHide: any;
  articleId?: number | string;
  sendArticleId?: boolean;
  showDeleteLoader: boolean;
}
const DeleteArticle = ({
  HeadingText,
  subText,
  submitBtnText,
  articleId,
  sendArticleId,
  handleConfirm,
  onHide,
  showDeleteLoader,
  cancelBtnText,
}: Props) => {
  const { handleSubmit } = useDeleteArticle({ articleId, handleConfirm, sendArticleId });
  return (
    <>
      <div className="text-center">
        <p className={`${styles.delPara}`}>{HeadingText}</p>
        <span className={`${styles.delSpan}`}>{subText}</span>
        <div
          className={`d-flex align-items-center justify-content-center flex-column flex-lg-row ${styles.actionBtns}`}
        >
          <button
            className={`me-lg-4 mb-2 mb-lg-0 ${styles.cancelBtn}`}
            onClick={onHide}
          >
            {cancelBtnText}
          </button>

          <button className={`${styles.confirmBtn}`} disabled={showDeleteLoader} onClick={handleSubmit}>
            {showDeleteLoader ? (
              <Spinner
                className="my-auto mx-1"
                animation="border"
                color="white"
                size="sm"
              />
            ) : (
              submitBtnText
            )}
          </button>
        </div>
      </div>
    </>
  );
};
export default DeleteArticle;
