/**
 * This file contains the custom hook for handling KB settings.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { GetAllArticleFeedback } from "src/services/KnowledgeBase/Settings/Feedback/getAllArticleFeedback.service";
import { updateArticleFeedback } from "src/services/KnowledgeBase/Settings/Feedback/updateArticleFeedback.service";

type Props = {
  feedbackData: GetAllArticleFeedback;
};

/**
 * Custom hook for managing the FeedbackTable component in KB settings.
 */
function useFeedbackTable({ feedbackData }: Props) {
  const { integrationId } = useParams();
  const pageIntegrationId = integrationId
    ? decodeURIComponent(integrationId)
    : "";

  const [showModal, setShowModal] = useState(false);

  // Variable for showing and hiding the modal
  const onShow = (e: any) => {
    e.stopPropagation();
    setShowModal(true);
  };
  const onHide = () => {
    setShowModal(false);
  };

  // const queryClient = useQueryClient();

  // Mutation Function to update the status of the feedback
  const updateStatus = useMutation({
    mutationFn: updateArticleFeedback,
    // onSuccess: () => {
    //   queryClient.invalidateQueries(["getAllArticleFeedbacks"]);
    // },
  });

  // States for the status of the feedback
  const [commentStatus, setCommentStatus] = useState(
    feedbackData.comment_action ?? "Pending"
  );

  // States for the status of the feedback
  const [ratingStatus, setRatingStatus] = useState(
    feedbackData.ratingStatus ?? "Pending"
  );
  return {
    pageIntegrationId,
    showModal,
    onShow,
    onHide,
    updateStatus,
    commentStatus,
    setCommentStatus,
    ratingStatus,
    setRatingStatus,
  };
}

export default useFeedbackTable;
