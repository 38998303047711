import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";
import { EAutomationType } from "src/routes/BotSettings/Children/AutomationList/AutomationList";
export interface CreateAutomationParams {
  brandId: number | string;
  step_data?: {
    selected_auto_type: "fully_automate" | "partially_automate";
  };
}

export interface CreateAutomationResponse {
  integrationId: number;
}

export interface AutomationAPIPayload<T> {
  /**
   * Type of automation to send the payload for
   */
  type: EAutomationType;
  /**
   * Payload for the API Service
   */
  payload: T;
}

export const createAutomationService = async ({
  type,
  payload,
}: AutomationAPIPayload<CreateAutomationParams>) => {
  const url = IS_TEST_ACTIVE
    ? `${process.env.REACT_APP_TEST_SITE_URL}/${type}/create`
    : `/api/automation/${type}/create`;

  const { data: res } = await axiosJSON.post(url, payload);

  if (res.err || res.error) {
    throw new Error(
      res.msg || res.message || "Could not create the automation",
    );
  }

  return res.data as CreateAutomationResponse;
};
