/**
 * This file contains the custom hook for handling KB settings.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getRatingsAndComments } from "src/services/KnowledgeBase/Settings/Feedback/getRatingsAndComments.service";

/**
 * Custom hook for managing the Comments component in KB settings.
 */
function useComments() {
  // States and variables
  const { integrationId } = useParams();
  const pageIntegrationId = integrationId
    ? decodeURIComponent(integrationId)
    : "";

  //  Get Query For Ratings And Comments Data
  const getRatingsAndCommentData = useQuery({
    queryKey: ["getRatingsAndComments"],
    queryFn: () =>
      getRatingsAndComments({
        integrationId: pageIntegrationId,
      }),
  });

  // States
  const [enableComments, setEnableComments] = useState<boolean>(
    getRatingsAndCommentData.data?.commentsEnabled ?? false
  );
  const [reqApproval, setReqApproval] = useState<boolean>(
    getRatingsAndCommentData.data?.commentApprovalRequired ?? false
  );

  const [approvedComment, setApprovedComment] = useState<boolean>(
    getRatingsAndCommentData.data?.commentsType === "approved" ?? false
  );

  const [unapprovedComment, setUnapprovedComment] = useState<boolean>(
    getRatingsAndCommentData.data?.commentsType === "unApproved" ?? false
  );

  // When data is fetched or changed from the API
  useEffect(() => {
    if (getRatingsAndCommentData.data) {
      setEnableComments(getRatingsAndCommentData.data.commentsEnabled);
      setReqApproval(getRatingsAndCommentData.data.commentApprovalRequired);
      if (getRatingsAndCommentData.data.commentsType === "approved") {
        setApprovedComment(true);
        setUnapprovedComment(false);
      }
      if (getRatingsAndCommentData.data.commentsType === "unApproved") {
        setApprovedComment(false);
        setUnapprovedComment(true);
      }
    }
  }, [getRatingsAndCommentData.data]);

  return {
    enableComments,
    setEnableComments,
    reqApproval,
    setReqApproval,
    data: getRatingsAndCommentData.data,
    approvedComment,
    unapprovedComment,
    setApprovedComment,
    setUnapprovedComment,
  };
}

export default useComments;
