import styles from "./AllViewModal.module.scss";
import done2 from "src/assets/images/done2.gif";
import AsyncDropdown from "../AsyncDropdown";
import {
  AGENT_OPTIONS_API_ROUTE,
  BRAND_OPTIONS_API_ROUTE,
  CHANNEL_OPTIONS_API_ROUTE,
  CLOSED_DATE_OPTIONS_API_ROUTE,
  CREATED_DATE_OPTIONS_API_ROUTE,
  DRAFT_STATUS_OPTIONS_API_ROUTE,
  TAGS_OPTIONS_API_ROUTE,
  TICKET_STATUS_OPTIONS_API_ROUTE,
} from "src/globals/constants";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  addView,
  getView,
  resetTicketViews,
  selectAddViewValidation,
  setActiveViewId,
  setClosedOnValue,
  setCreatedOnValue,
  setFormDataByKey,
  showUnsavedChanges,
} from "src/store/slices/ticketViews/ticketViews.slice";
import {
  getTruncatedStringWithPrefixAndSuffix,
  isObjOrStrEmpty,
} from "src/utils/utils";
import { GetFilterDatePicker } from "src/routes/TicketList/children/FilterSection/children/Filter/children/FilterColDropdown/FilterColDropdown";
import Loader from "../Loader";
import {
  subSBLink,
  viewIdLink,
} from "src/services/TicketService/linkGenerator";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useCallback, useEffect, useState } from "react";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import DeleteViewModal from "../DeleteViewModal/DeleteViewModal";
import { useLocation, useNavigate } from "react-router-dom";

interface Props {
  onHide: any;
  show: boolean;
  viewId?: null | number;
}

// const initFormDataState = {
//   viewName: "",
//   brand: "",
//   channel: "",
//   tags: "",
//   createdOn: "",
//   assignedTo: "",
//   closedOn: "",
//   draftStatus: "",
//   ticketStatus: "",
// };

const AllViewModal = ({ onHide, show, viewId = null }: Props) => {
  // const [formData, setFormData] = useState(initFormDataState);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [showViewNameError, setShowViewNameError] = useState(false);
  const [showAllFilterReqError, setShowAllFilterReqError] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const viewName = useAppSelector(
    (state) => state.ticketViews.addViewFromData.viewName
  );
  const brand = useAppSelector(
    (state) => state.ticketViews.addViewFromData.brand
  );
  const channel = useAppSelector(
    (state) => state.ticketViews.addViewFromData.channel
  );
  const tags = useAppSelector(
    (state) => state.ticketViews.addViewFromData.tags
  );
  const createdOn = useAppSelector(
    (state) => state.ticketViews.addViewFromData.createdOn
  );
  const assignedTo = useAppSelector(
    (state) => state.ticketViews.addViewFromData.assignedTo
  );
  const closedOn = useAppSelector(
    (state) => state.ticketViews.addViewFromData.closedOn
  );
  const draftStatus = useAppSelector(
    (state) => state.ticketViews.addViewFromData.draftStatus
  );
  const ticketStatus = useAppSelector(
    (state) => state.ticketViews.addViewFromData.ticketStatus
  );
  const createdOnValue = useAppSelector(
    (state) => state.ticketViews.addViewFromData.createdOnValue
  );
  const closedOnValue = useAppSelector(
    (state) => state.ticketViews.addViewFromData.closedOnValue
  );
  const addViewState = useAppSelector(
    (state) => state.ticketViews.addViewState
  );
  const activeViewId = useAppSelector(
    (state) => state.ticketViews.activeViewId
  );

  const unsavedChanges = useAppSelector((state) =>
    state.ticketViews.unsavedViewFromData ? true : false
  );

  const addViewValidation = useAppSelector(selectAddViewValidation);

  const handleOnChangeInputData = (e: any) => {
    if (e.target.value.trim().length !== 0 && showViewNameError === true) {
      setShowViewNameError(false);
    }
    dispatch(setFormDataByKey({ key: e.target.name, data: e.target.value }));
  };

  const handleOnChangeDropdownData = (newData: any, action: any) => {
    setShowAllFilterReqError(false);
    dispatch(setFormDataByKey({ key: action.name, data: newData }));
    if (action.name === "createdOn" && newData === null) {
      dispatch(
        setCreatedOnValue({
          startDate: null,
          startTime: null,
          endDate: null,
          endTime: null,
        })
      );
      dispatch(setFormDataByKey({ key: action.name, data: undefined }));
    }
    if (action.name === "closedOn" && newData === null) {
      dispatch(
        setClosedOnValue({
          startDate: null,
          startTime: null,
          endDate: null,
          endTime: null,
        })
      );
      dispatch(setFormDataByKey({ key: action.name, data: undefined }));
    }
  };

  const handleOnClickAddView = (e: any) => {
    // e.preventDefault();

    if (viewName.trim().length === 0) {
      setShowViewNameError(true);
      return;
    } else {
      setShowViewNameError(false);
    }

    if (!addViewValidation) {
      setShowAllFilterReqError(true);
      pushTheToast({
        type: "danger",
        text: "Please select at least one filter !",
        position: "top-right",
      });
      return;
    }

    dispatch(addView());
  };
  const handleOnClicDiscard = (e: any) => {
    onHide(e);
    setShowViewNameError(false);
    setShowAllFilterReqError(false);
    dispatch(resetTicketViews());
  };
  const handleOnClickGoToView = (e: any) => {
    onHide(e);
    dispatch(resetTicketViews());
  };

  /**
   * Cancel Handler to close modal
   */
  const handleCloseDeleteView = useCallback(
    (isSuccess?: boolean, isClose?: boolean) => {
      // Close delete view modal
      setShowDelete(false);

      // If Clicked on close button close edit modal as well
      if (isClose) {
        onHide();
        return;
      }

      // If successfully deleted
      if (isSuccess) {
        // Hide edit Modal
        onHide();
        // Navigate to my tickets
        navigate(
          subSBLink({
            tableType: "my",
            search: location.search,
            pathName: location.pathname,
          })
        );
      }
    },
    [location.pathname, location.search, navigate, onHide]
  );

  useEffect(() => {
    dispatch(resetTicketViews());
    if (show) {
      dispatch(setActiveViewId({ activeViewId: viewId as any }));
      // if (viewId !== null) {
      dispatch(getView());
      // }
    }
  }, [viewId, show]);

  // Render the Delete View modal
  if (showDelete && viewId) {
    return (
      <DeleteViewModal
        viewId={viewId.toString()}
        show={showDelete}
        onHide={handleCloseDeleteView}
      />
    );
  }

  // Render the All View Modal
  return (
    <Modal
      show={show}
      onHide={() => {
        setShowViewNameError(false);
        setShowAllFilterReqError(false);
        onHide();
      }}
      contentClassName={`${styles.modalContent}`}
      dialogClassName={`${styles.dialogClass}`}
      backdropClassName={`${styles.backDropClass}`}
      id="addViewModal"
    >
      <div>
        <div className={`d-flex justify-content-between align-items-center`}>
          {addViewState === "idle" && (
            <h5 className={`${styles.preDraft_Title}`}>
              {viewId === null ? "Add" : "Edit"} View
            </h5>
          )}
          <span></span>
          <div>
            {viewId !== null && (
              <OverlayTrigger
                trigger="hover"
                placement="bottom"
                overlay={
                  <Tooltip className={`custom-tooltip`}>
                    <span>Delete View</span>
                  </Tooltip>
                }
              >
                <span
                  role="button"
                  className="mx-3"
                  onClick={() => setShowDelete(true)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="46"
                    height="46"
                    viewBox="0 0 46 46"
                  >
                    <defs>
                      <filter
                        id="Ellipse_1038"
                        x="0"
                        y="0"
                        width="46"
                        height="46"
                        filterUnits="userSpaceOnUse"
                      >
                        <feOffset dy="3" />
                        <feGaussianBlur stdDeviation="3" result="blur" />
                        <feFlood flood-color="#7d7d7d" flood-opacity="0.161" />
                        <feComposite operator="in" in2="blur" />
                        <feComposite in="SourceGraphic" />
                      </filter>
                    </defs>
                    <g
                      id="Group_6578"
                      data-name="Group 6578"
                      transform="translate(-877 -149)"
                    >
                      <g
                        id="Component_459_1"
                        data-name="Component 459 – 1"
                        transform="translate(886 155)"
                      >
                        <g
                          transform="matrix(1, 0, 0, 1, -9, -6)"
                          filter="url(#Ellipse_1038)"
                        >
                          <circle
                            id="Ellipse_1038-2"
                            data-name="Ellipse 1038"
                            cx="14"
                            cy="14"
                            r="14"
                            transform="translate(9 6)"
                            fill="#fff"
                          />
                        </g>
                        <g id="delete" transform="translate(9 8)">
                          <path
                            id="Path_3164"
                            data-name="Path 3164"
                            d="M.656,10.656v-8h8v8a1.3,1.3,0,0,1-.391.953A1.261,1.261,0,0,1,7.344,12H2a1.3,1.3,0,0,1-.953-.391A1.3,1.3,0,0,1,.656,10.656Zm8.688-10V2H0V.656H2.344L3,0H6.344L7,.656Z"
                            fill="red"
                          />
                        </g>
                      </g>
                    </g>
                  </svg>
                </span>
              </OverlayTrigger>
            )}
            <span
              className={`${styles.close}`}
              onClick={handleOnClicDiscard}
              id="addViewModal__closebtn"
            >
              <i className="fa-solid fa-xmark"></i>
            </span>
          </div>
        </div>
        {unsavedChanges && (
          <div
            className={`btn btn-sm p-0 text-primary`}
            onClick={() => {
              dispatch(showUnsavedChanges());
            }}
          >
            Show Unsaved Changes
          </div>
        )}
        <div className={`mt-4`}>
          {addViewState === "idle" && (
            <form
              id="addViewForm"
              onSubmit={(e) => e.preventDefault()}
              className={`${styles.formStyle}`}
            >
              <div
                className="mb-3 row align-items-center"
                id="addViewModal__viewName"
              >
                <label className={`col-3 pe-0 ${styles.mainLabelName}`}>
                  View Name<span className={`${styles.required}`}>*</span> :
                </label>
                <div className={`col-9 ps-0 ${styles.inputWrapper}`}>
                  <div>
                    <input
                      data-lpignore="true"
                      type="text"
                      className={`form-control ${styles.commonInput} ${
                        showViewNameError ? `${styles.reqInputBorder}` : ""
                      }`}
                      name="viewName"
                      value={viewName}
                      required={true}
                      onChange={handleOnChangeInputData}
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
              <div className="mb-3 row align-items-center">
                <label className={` ${styles.mainLabelName}`}>
                  View by<span className={`${styles.required}`}>*</span> :{" "}
                  <span className={`${styles.labelCaution}`}>
                    {" "}
                    ( Please select at least one option )
                  </span>
                </label>
              </div>

              <div className="ms-3 mb-3">
                <div
                  className="mb-3 row align-items-center"
                  id="addViewModal__brand"
                >
                  <label className={`col-3 pe-0 ${styles.labelName}`}>
                    Brand:
                  </label>
                  <div className={`col-9 ps-0 ${styles.inputWrapper}`}>
                    <AsyncDropdown
                      styles={
                        showAllFilterReqError
                          ? customStylesRedBorder
                          : customStyles
                      }
                      loadUrl={BRAND_OPTIONS_API_ROUTE}
                      isMulti={true}
                      placeholder=""
                      closeMenuOnSelect={false}
                      getOptionValue={(option: any) =>
                        option.id || option.compare
                      }
                      getOptionLabel={(option: any) => option.name}
                      name="brand"
                      value={brand}
                      onChange={handleOnChangeDropdownData}
                    />
                  </div>
                </div>
                <div
                  className="mb-3 row align-items-center"
                  id="addViewModal__channel"
                >
                  <label className={`col-3 pe-0 ${styles.labelName}`}>
                    Channel:
                  </label>
                  <div className={`col-9 ps-0 ${styles.inputWrapper}`}>
                    <AsyncDropdown
                      styles={
                        showAllFilterReqError
                          ? customStylesRedBorder
                          : customStyles
                      }
                      loadUrl={CHANNEL_OPTIONS_API_ROUTE}
                      isMulti={true}
                      placeholder=""
                      closeMenuOnSelect={false}
                      getOptionValue={(option: any) =>
                        option.id || option.compare
                      }
                      getOptionLabel={(option: any) => option.name}
                      name="channel"
                      value={channel}
                      onChange={handleOnChangeDropdownData}
                    />
                  </div>
                </div>
                <div
                  className="mb-3 row align-items-center"
                  id="addViewModal__tags"
                >
                  <label className={`col-3 pe-0 ${styles.labelName}`}>
                    Tags:
                  </label>
                  <div className={`col-9 ps-0 ${styles.inputWrapper}`}>
                    <AsyncDropdown
                      styles={
                        showAllFilterReqError
                          ? customStylesRedBorder
                          : customStyles
                      }
                      loadUrl={TAGS_OPTIONS_API_ROUTE}
                      isMulti={true}
                      placeholder=""
                      closeMenuOnSelect={false}
                      getOptionValue={(option: any) =>
                        option.id || option.compare
                      }
                      getOptionLabel={(option: any) => option.name}
                      name="tags"
                      value={tags}
                      onChange={handleOnChangeDropdownData}
                    />
                  </div>
                </div>
                <div
                  className="mb-3 row align-items-center"
                  id="addViewModal__createdOn"
                >
                  <label className={`col-3 pe-0 ${styles.labelName}`}>
                    Created on:
                  </label>
                  <div className={`col-9 ps-0 ${styles.inputWrapper}`}>
                    <AsyncDropdown
                      styles={
                        showAllFilterReqError
                          ? customStylesRedBorder
                          : customStyles
                      }
                      loadUrl={CREATED_DATE_OPTIONS_API_ROUTE}
                      isMulti={false}
                      placeholder=""
                      getOptionValue={(option: any) =>
                        option.id || option.compare
                      }
                      getOptionLabel={(option: any) => option.name}
                      name="createdOn"
                      value={createdOn}
                      onChange={handleOnChangeDropdownData}
                    />
                    {!isObjOrStrEmpty(createdOn) &&
                      createdOn !== null &&
                      createdOn.isValueRequired === true && (
                        <div className="mt-3">
                          <GetFilterDatePicker
                            selectedDate={
                              createdOnValue.startDate
                                ? new Date(createdOnValue.startDate)
                                : null
                            }
                            selectedTime={
                              createdOnValue.startTime
                                ? new Date(createdOnValue.startTime)
                                : null
                            }
                            onChangeDate={(date: Date) =>
                              dispatch(
                                setCreatedOnValue({
                                  ...createdOnValue,
                                  startDate: date.toISOString(),
                                })
                              )
                            }
                            onChangeTime={(date: Date) =>
                              dispatch(
                                setCreatedOnValue({
                                  ...createdOnValue,
                                  startTime: date.toISOString(),
                                })
                              )
                            }
                          />
                        </div>
                      )}

                    {!isObjOrStrEmpty(createdOn) &&
                      createdOn !== null &&
                      createdOn.compare === "between" &&
                      createdOn.isValueRequired === true && (
                        <div className="mt-1">
                          <div className="d-flex justify-content-center align-items-center p-0 mb-1">
                            To
                          </div>
                          <GetFilterDatePicker
                            selectedDate={
                              createdOnValue.endDate
                                ? new Date(createdOnValue.endDate)
                                : null
                            }
                            selectedTime={
                              createdOnValue.endTime
                                ? new Date(createdOnValue.endTime)
                                : null
                            }
                            onChangeDate={(date: Date) =>
                              dispatch(
                                setCreatedOnValue({
                                  ...createdOnValue,
                                  endDate: date.toISOString(),
                                })
                              )
                            }
                            onChangeTime={(date: Date) =>
                              dispatch(
                                setCreatedOnValue({
                                  ...createdOnValue,
                                  endTime: date.toISOString(),
                                })
                              )
                            }
                          />
                        </div>
                      )}
                  </div>
                </div>
                <div
                  className="mb-3 row align-items-center"
                  id="addViewModal__assignedTo"
                >
                  <label className={`col-3 pe-0 ${styles.labelName}`}>
                    Assigned to:
                  </label>
                  <div className={`col-9 ps-0 ${styles.inputWrapper}`}>
                    <AsyncDropdown
                      styles={
                        showAllFilterReqError
                          ? customStylesRedBorder
                          : customStyles
                      }
                      loadUrl={AGENT_OPTIONS_API_ROUTE}
                      isMulti={true}
                      placeholder=""
                      closeMenuOnSelect={false}
                      getOptionValue={(option: any) =>
                        option.id || option.compare
                      }
                      getOptionLabel={(option: any) => option.name}
                      name="assignedTo"
                      value={assignedTo}
                      onChange={handleOnChangeDropdownData}
                    />
                  </div>
                </div>
                <div
                  className="mb-3 row align-items-center"
                  id="addViewModal__closeOn"
                >
                  <label className={`col-3 pe-0 ${styles.labelName}`}>
                    Closed on:
                  </label>
                  <div className={`col-9 ps-0 ${styles.inputWrapper}`}>
                    <AsyncDropdown
                      styles={
                        showAllFilterReqError
                          ? customStylesRedBorder
                          : customStyles
                      }
                      loadUrl={CLOSED_DATE_OPTIONS_API_ROUTE}
                      isMulti={false}
                      placeholder=""
                      getOptionValue={(option: any) =>
                        option.id || option.compare
                      }
                      getOptionLabel={(option: any) => option.name}
                      name="closedOn"
                      value={closedOn}
                      onChange={handleOnChangeDropdownData}
                    />
                    {!isObjOrStrEmpty(closedOn) &&
                      closedOn !== null &&
                      closedOn.isValueRequired === true && (
                        <div className="mt-3">
                          <GetFilterDatePicker
                            selectedDate={
                              closedOnValue.startDate
                                ? new Date(closedOnValue.startDate)
                                : null
                            }
                            selectedTime={
                              closedOnValue.startTime
                                ? new Date(closedOnValue.startTime)
                                : null
                            }
                            onChangeDate={(date: Date) =>
                              dispatch(
                                setClosedOnValue({
                                  ...closedOnValue,
                                  startDate: date.toISOString(),
                                })
                              )
                            }
                            onChangeTime={(date: Date) =>
                              dispatch(
                                setClosedOnValue({
                                  ...closedOnValue,
                                  startTime: date.toISOString(),
                                })
                              )
                            }
                          />
                        </div>
                      )}

                    {!isObjOrStrEmpty(closedOn) &&
                      closedOn !== null &&
                      closedOn.compare === "between" && (
                        <div className="mt-1">
                          <div className="d-flex justify-content-center align-items-center p-0 mb-1">
                            To
                          </div>
                          <GetFilterDatePicker
                            selectedDate={
                              closedOnValue.endDate
                                ? new Date(closedOnValue.endDate)
                                : null
                            }
                            selectedTime={
                              closedOnValue.endTime
                                ? new Date(closedOnValue.endTime)
                                : null
                            }
                            onChangeDate={(date: Date) =>
                              dispatch(
                                setClosedOnValue({
                                  ...closedOnValue,
                                  endDate: date.toISOString(),
                                })
                              )
                            }
                            onChangeTime={(date: Date) =>
                              dispatch(
                                setClosedOnValue({
                                  ...closedOnValue,
                                  endTime: date.toISOString(),
                                })
                              )
                            }
                          />
                        </div>
                      )}
                  </div>
                </div>
                <div
                  className="mb-3 row align-items-center d-none"
                  id="addViewModal__draftStatus"
                >
                  <label className={`col-3 pe-0 ${styles.labelName}`}>
                    Draft status:
                  </label>
                  <div className={`col-9 ps-0 ${styles.inputWrapper}`}>
                    <AsyncDropdown
                      styles={
                        showAllFilterReqError
                          ? customStylesRedBorder
                          : customStyles
                      }
                      loadUrl={DRAFT_STATUS_OPTIONS_API_ROUTE}
                      isMulti={true}
                      placeholder=""
                      closeMenuOnSelect={false}
                      getOptionValue={(option: any) =>
                        option.id || option.compare
                      }
                      getOptionLabel={(option: any) => option.name}
                      name="draftStatus"
                      value={draftStatus}
                      onChange={handleOnChangeDropdownData}
                    />
                  </div>
                </div>
                <div
                  className="mb-3 row align-items-center"
                  id="addViewModal__ticketStatus"
                >
                  <label className={`col-3 pe-0 ${styles.labelName}`}>
                    Ticket status:
                  </label>
                  <div className={`col-9 ps-0 ${styles.inputWrapper}`}>
                    <AsyncDropdown
                      styles={
                        showAllFilterReqError
                          ? customStylesRedBorder
                          : customStyles
                      }
                      loadUrl={TICKET_STATUS_OPTIONS_API_ROUTE}
                      isMulti={true}
                      placeholder=""
                      closeMenuOnSelect={false}
                      getOptionValue={(option: any) =>
                        option.id || option.compare
                      }
                      getOptionLabel={(option: any) => option.name}
                      name="ticketStatus"
                      value={ticketStatus}
                      onChange={handleOnChangeDropdownData}
                    />
                  </div>
                </div>
              </div>
            </form>
          )}
        </div>
        {addViewState === "fulfilled" ? (
          <div className={`w-100 my-5`} id="addViewModal__successSection">
            <img src={done2} className={`${styles.imgDone} my-4`} />
            <div className={`w-100 text-center ${styles.created} my-3`}>
              View Created!
            </div>
            <a
              href={viewIdLink(activeViewId ? activeViewId : 0)}
              className={`${styles.add} ${styles.center}`}
              onClick={handleOnClickGoToView}
              id="addViewModal__viewCreated"
            >
              {
                <span>
                  {getTruncatedStringWithPrefixAndSuffix({
                    inputString: viewName,
                    limit: 18,
                    prefix: "Go To '",
                    suffix: "'",
                  })}
                </span>
              }
            </a>
          </div>
        ) : addViewState === "pending" ? (
          <div className={`w-100 my-5`}>
            <Loader className={`${styles.loading}`} />
          </div>
        ) : addViewState === "rejected" ? (
          <div className={`w-100 text-center px-2 py-5`}>
            View might be already existing!
          </div>
        ) : addViewState === "rejected2" ? (
          <div className={`w-100 text-center px-2 py-5`}>
            Error fetching data!
          </div>
        ) : (
          <div
            className={`w-100 d-flex justify-content-between ${styles.AllFooter}`}
            id="addViewModal__discard"
          >
            <button
              className={`${styles.discard}`}
              onClick={handleOnClicDiscard}
            >
              Discard
            </button>
            <button
              form="addViewForm"
              // disabled={!addViewValidation}
              className={`${styles.add}`}
              onClick={handleOnClickAddView}
              id="addViewModal__submit"
            >
              {viewId === null ? "Add" : "Save"}
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};

const customStyles = {
  control: (provided: any, _: any) => ({
    ...provided,
    borderColor: "9e9e9e",
    minHeight: "27px",
    width: "100%",
    border: "1px solid transparent",
    background: "#f5f5f5",
    font: "normal normal normal 12px/18px Poppins",
    borderRadius: "6px",
    "&:active": {
      border: "1px solid 000000",
    },
  }),

  valueContainer: (provided: any, _: any) => ({
    ...provided,
    minHeight: "27px",
    padding: "0 6px",
  }),

  input: (provided: any, _: any) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorsContainer: (provided: any, _: any) => ({
    ...provided,
    minHeight: "27px",
    padding: "0px !important",
  }),
  dropdownIndicator: (provided: any, _: any) => ({
    ...provided,
    padding: "0px !important",
    display: "none",
  }),
  MenuPortal: (provided: any, _: any) => ({
    ...provided,
    background: "FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 16px 48px 00000029",
    borderRadius: "10px",
    border: "1px solid fff",
  }),
  option: (provided: any, _: any) => ({
    ...provided,
    textAlign: "left",
    font: "normal normal 500 12px/18px Poppins",
    letterSpacing: "0px",
    color: "000000",
    background: "FFFFFF 0% 0% no-repeat padding-box",
    padding: "6px 12px",
  }),
  clearIndicator: (provided: any, _: any) => ({
    ...provided,
    padding: "0px !important",
  }),
  indicatorSeparator: (provided: any, _: any) => ({
    ...provided,
    display: "none",
  }),

  multiValueLabel: (provided: any, _: any) => ({
    ...provided,
    padding: "0",
    textAlign: "left",
    font: "normal normal normal 12px/18px Poppins",
    letterSpacing: "0px",
    color: "707070",
    opacity: "1",
  }),
  multiValue: (provided: any, _: any) => ({
    ...provided,
    paddingRight: "2px",
    background: "white",
  }),
};

const customStylesRedBorder = {
  ...customStyles,
  control: (provided: any, state: any) => ({
    ...provided,
    background: "f5f5f5 0% 0% no-repeat padding-box",
    borderColor: "#DB3030",
    minHeight: "27px",
    width: "100%",
    border: state.isFocused
      ? "1px solid FF0000 !important"
      : "1px solid FF0000 !important",
    boxShadow: "1px solid FF0000 !important",
    "&:hover": {
      border: "1px solid FF0000 !important",
    },
    borderRadius: "6px",
  }),
};

export default AllViewModal;
