import {
  createAsyncThunk,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";
import { AJAXSTATUS } from "src/globals/constants";
import { IGoalSetting, IGoalTypeOptionList } from "src/services/LiveChat/Settings/goals/getAllGoalSetting";
import { fetchAllGoalSettingsFulfilled } from "./GoalSetting.extraReducers";
import GoalSettingReducers from "./GoalSetting.reducers";
import { getGoalSettingDataFromId } from "./GoalSetting.selectors";
import GoalSettingThunk from "./GoalSetting.thunk";

// to fetch all goalSettings
export const fetchAllGoalSettingData = createAsyncThunk(
  "goalSetting/fetchAllGoalSettings",
   GoalSettingThunk.fetchAllGoalSettingsThunk
);

export interface GoalSettingData{
  goalSettingData: { [goalId: string | number]: IGoalSetting };
  goalSettingIdList: Array<number | string>;
  metaData: {
    count: number;
    total: number;
  };

  goalTypeOptions: IGoalTypeOptionList;

  fetchGoalSettingAjaxStatus: AJAXSTATUS;
  fetchGoalSettingsLimit: number;
}
// initial states for the slice
export const initialState: GoalSettingData  = {
  goalSettingData: {},
  goalSettingIdList: [],
  fetchGoalSettingAjaxStatus: "pending",
  fetchGoalSettingsLimit: 10,
  metaData: {
    count: 0,
    total: 0
  },
  goalTypeOptions: {
    goalTypeOptionKeys : [],
    goalTypeOptions: {}
  }
}

// create the slice
export const goalSettingSlice = createSlice({
  name: "goalSetting",
  initialState,
  reducers: GoalSettingReducers,
  extraReducers: (builder) => {
    // fetch all goalSettings
    builder.addCase(fetchAllGoalSettingData.fulfilled,
        fetchAllGoalSettingsFulfilled
      );
    builder.addCase(fetchAllGoalSettingData.pending,
      (state) => {
        state.fetchGoalSettingAjaxStatus = "pending";
      });
    builder.addCase(fetchAllGoalSettingData.rejected,
      (state) => {
        state.fetchGoalSettingAjaxStatus = "rejected";
      });
  }
});


// selector to get goal data from goal id
export const getGoalSettingDataFromIdSelector = createSelector(
  [getGoalSettingDataFromId],
  (goalSettingDataFromId) => goalSettingDataFromId
);


export const {
  resetGoalSettings,
  resetGoalSettingsData,
} = goalSettingSlice.actions;


export default goalSettingSlice.reducer;