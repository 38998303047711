import { useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useAutomationContext } from "src/routes/BotSettings/Children/Automation/Hooks/useAutomationContext";
import styles from "./KnowledgeBaseDisabledPopover.module.scss";

const KnowledgeBaseDisabledPopover = () => {
  const { formData } = useAutomationContext();
  const [showPopover, setShowPopover] = useState(false);

  const handleMouseEnter = () => setShowPopover(true);
  const handleMouseLeave = () => setShowPopover(false);

  return (
    <OverlayTrigger
      trigger={["hover", "focus"]}
      placement="top"
      show={showPopover}
      onToggle={(nextShow) => setShowPopover(nextShow)}
      overlay={
        <Popover
          id="popover-basic"
          bsPrefix={`popover ${styles.customPopover}`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Popover.Body>
            <h6 className={`${styles.heading}`}>
              Why is this option disabled?
            </h6>
            <span className={`text-start ${styles.subHeading}`}>
              You cannot choose 'yes' for this section since there are no
              knowledge base articles configured. Please configure them{" "}
              <a
                href={
                  formData.knowledgeBaseId
                    ? `/knowledge-base/${formData.knowledgeBaseId}/articles`
                    : `/knowledge-base`
                }
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>{" "}
              to choose yes.
            </span>
          </Popover.Body>
        </Popover>
      }
    >
      <span className={`ps-1 ${styles.info}`}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="13"
          height="13"
          viewBox="0 0 14.098 14.098"
        >
          <g
            id="Component_356_4"
            data-name="Component 356 – 4"
            transform="translate(0.5 0.5)"
          >
            <g
              id="Icon_ion-information-circle-outline"
              data-name="Icon ion-information-circle-outline"
              transform="translate(-4.5 -4.5)"
            >
              <path
                id="Path_7337"
                data-name="Path 7337"
                d="M11.049,4.5A6.549,6.549,0,1,0,17.6,11.049,6.549,6.549,0,0,0,11.049,4.5Z"
                fill="none"
                stroke={!showPopover ? "#AF0404" : "#000000"}
                strokeWidth="1"
              />
              <path
                id="Path_7338"
                data-name="Path 7338"
                d="M15.469,15.469h1.139V19.6"
                transform="translate(-5.416 -5.416)"
                fill="none"
                stroke={!showPopover ? "#AF0404" : "#000000"}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
              <path
                id="Path_7339"
                data-name="Path 7339"
                d="M14.625,23.906h3.132"
                transform="translate(-5 -9.583)"
                fill="none"
                stroke={!showPopover ? "#AF0404" : "#000000"}
                strokeLinecap="round"
                strokeWidth="1"
              />
              <path
                id="Path_7340"
                data-name="Path 7340"
                d="M16.535,9.141a.925.925,0,1,0,.925.925.925.925,0,0,0-.925-.925Z"
                transform="translate(-5.486 -2.292)"
              />
            </g>
          </g>
        </svg>
      </span>
    </OverlayTrigger>
  );
};

export default KnowledgeBaseDisabledPopover;
