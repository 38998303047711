import { GetConfigModalParams } from "src/features/ReturnAutoWorkFlow/children/ConfigModals/oldReturnModals.types";
import { axiosJSON } from "src/globals/axiosEndPoints";

interface ResponseData {
  reasonId: string;
  scenarioIds: string[];
}

interface ExtendedReturnPeriod {
  enabled: boolean;
  days: number;
}

interface AdditionalInformation {
  enabled: boolean;
  message: string;
  extendReturnPeriod: ExtendedReturnPeriod;
}

interface GiveOffer {
  enabled: boolean;
  message: string;
  offerSelectedItems: number[];
  offerSelectedTags: string[];
  offerType: string;
  offerUnit: string;
  name: string;
}

interface OfferExchange {
  enabled: boolean;
  message: string;
  exchangeWithOtherItems: boolean;
  exchangeWithSameItems: boolean;
}

interface FollowUpQuestions {
  elementId: string;
  value: string[] | string;
}

interface Scenarios {
  scenarioId?: string;
  tags: string[];
  itemIds: string[];
  additionalInformation: AdditionalInformation;
  giveOffer: GiveOffer;
  offerExchange: OfferExchange;
  followUpQuestions: FollowUpQuestions[];
}

interface RefundRequests {
  id: string;
  scenarios: Scenarios[];
}

export interface UpdateAntiRefundStrategiesPayload
  extends GetConfigModalParams {
  refundRequests: RefundRequests[];
}

async function updateAntiRefundStrategiesService(
  params: UpdateAntiRefundStrategiesPayload,
) {
  const { data: res } = await axiosJSON.post(
    "/api/setting/automation/refund/step/13/updateAntiRefundRequests",
    params,
  );

  if (res.err || res.error) {
    throw new Error(res.msg ?? res.message ?? "Some Error Occurred.");
  }

  return res.data as ResponseData[];
}

export default updateAntiRefundStrategiesService;
