import { useState } from "react";
import styles from "./SideBar.module.scss";
import { NavLink, useMatch } from "react-router-dom";
import { useAppSelector } from "src/store/store";
import { Collapse, OverlayTrigger, Tooltip } from "react-bootstrap";
function SideBar() {
  const currentUserData = useAppSelector(
    (state) => state.globals.currentUserData
  );
  const [toggleAccSettings, setToggleAccSettings] = useState<boolean>(true);
  const [toggleTeamNMangSettings, setToggleTeamNMangSettings] =
    useState<boolean>(true);
  const [toggleHelpdeskSettings, setToggleHelpdeskSettings] =
    useState<boolean>(true);
  const [toggleIntegrations, setToggleIntegrations] = useState<boolean>(true);
  const [toggleChannelIntegration, setToggleChannelIntegration] =
    useState<boolean>(true);
  const [toggleAvailability, setToggleAvailibility] = useState<boolean>(true);

  const [toggleSetRules, setToggleSetRules] = useState<boolean>(true);

  const [showOverlay, setShowOveray] = useState(false);
  const [showSettingsSubSB, setShowSettingsSubSB] = useState<boolean>(true);
  const [toggleBilling, setToggleBilling] = useState<boolean>(true);
  const handleToggleSubSideBar = () => {
    if (showSettingsSubSB === true) {
      setShowSettingsSubSB(false);
    } else {
      setShowSettingsSubSB(true);
    }
  };

  const getToggleContent = () => {
    return (
      <div
        className={`${styles.subSideBarCloseBtnDiv}`}
        onClick={() => handleToggleSubSideBar()}
      >
        <OverlayTrigger
          show={showOverlay}
          placement="top"
          overlay={
            <Tooltip className={`${styles.toolTipCustom}`}>
              <span className={`${styles.tooltipHead}`}>
                {showSettingsSubSB === true ? "Hide sidebar" : "Show sidebar"}
              </span>
            </Tooltip>
          }
        >
          {({ ref, ...triggerHandler }) => (
            <div
              {...triggerHandler}
              ref={ref}
              onMouseOver={() => {
                setShowOveray(true);
              }}
              onMouseOut={() => {
                setShowOveray(false);
              }}
            >
              <span
                className={`${styles.subSideBarCloseBtnToggleArrow} m-auto`}
              >
                {showSettingsSubSB === true ? (
                  <i className="fa-solid fa-chevron-left"></i>
                ) : (
                  <i className="fa-solid fa-chevron-right"></i>
                )}
              </span>
            </div>
          )}
        </OverlayTrigger>
      </div>
    );
  };

  return (
    <div className={`${styles.subSideToggle}`}>
      {showSettingsSubSB === true && (
        <div className={`${styles.subsideToggleInner}`}>
          <div
            className={` ${styles.sidenav_content}  border shadow-sm overflow-auto`}
          >
            <ul
              className={` ${styles.sidebar_nav} `}
              id={`${styles.sidebar_nav}`}
            >
              <li className={` ${styles.nav_item} ${styles.pink_clr}`}>
                <span>Settings</span>
                <i className="bi bi-grid" />
              </li>
              {/* End Dashboard Nav */}
              {/* <li>
            <div className={`${styles.search} d-flex`}>
              <i className={`${styles.fontAwsomeIcon} fa fa-search `} />
              <input data-lpignore="true" 
                type="text"
                className={`${styles.form_control}`}
                placeholder="Search"
              />
            </div>
          </li> */}
              <li className={`${styles["nav_item"]}`}>
                <div
                  className={` ${styles.nav_link} cursor-pointer ${styles.collapsed} ps-3`}
                  // data-bs-target="#account"
                  // data-bs-toggle="collapse"
                  aria-expanded="false"
                  onClick={(e) => setToggleAccSettings(!toggleAccSettings)}
                >
                  <span className={`w-100 ${styles.toggler}`}>
                    Account Settings
                  </span>
                  {toggleAccSettings === true ? (
                    <i className="fa fa-angle-down" />
                  ) : (
                    <i className="fa fa-angle-up" />
                  )}
                </div>
                <Collapse in={toggleAccSettings}>
                  <ul
                    id="account"
                    className={` ${styles.nav_content} ${styles.collapsed}`}
                    data-bs-parent="#sidebar_nav"
                  >
                    <li>
                      <NavLink
                        className={`${styles.a}`}
                        to="/settings/Profile"
                        style={({ isActive }) => ({
                          color: isActive ? "#fff " : "",
                          background: isActive ? "#ff8499" : "",
                        })}
                      >
                        <span>Profile Settings</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={`${styles.a}`}
                        to="/settings/ChangePassword"
                        style={({ isActive }) => ({
                          color: isActive ? "#fff" : "",
                          background: isActive ? "#ff8499" : "",
                        })}
                      >
                        <span>Change Password</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={`${styles.a}`}
                        to="/settings/EmailSignature"
                        style={({ isActive }) => ({
                          color: isActive ? "#fff" : "",
                          background: isActive ? "#ff8499" : "",
                        })}
                      >
                        <span>Email Signature</span>
                      </NavLink>
                    </li>
                  </ul>
                </Collapse>
              </li>
              {/* End Components Nav */}
              {currentUserData &&
                currentUserData.scopes &&
                (currentUserData.scopes.includes("add_user") ||
                  currentUserData.scopes.includes("add_team") ||
                  currentUserData.scopes.includes("add_ticket") ||
                  currentUserData.scopes.includes(
                    "edit_report_notification_settings"
                  ) ||
                  currentUserData.scopes.includes(
                    "show_report_notification_settings"
                  )) && (
                  <li className={`${styles["nav_item"]}`}>
                    <div
                      className={` ${styles.nav_link} cursor-pointer ${styles.collapsed} ps-3`}
                      // data-bs-target="#team"
                      // data-bs-toggle="collapse"
                      aria-expanded="false"
                      onClick={(e) =>
                        setToggleTeamNMangSettings(!toggleTeamNMangSettings)
                      }
                    >
                      <span className={`w-100 ${styles.toggler}`}>
                        Team &amp; Management
                      </span>
                      {toggleTeamNMangSettings === true ? (
                        <i className="fa fa-angle-down" />
                      ) : (
                        <i className="fa fa-angle-up" />
                      )}
                    </div>
                    <Collapse in={toggleTeamNMangSettings}>
                      <ul
                        id="team"
                        className={` ${styles.nav_content} ${styles.collapsed}`}
                        data-bs-parent="#sidebar_nav"
                      >
                        {currentUserData &&
                          currentUserData.scopes &&
                          currentUserData.scopes.includes("add_user") && (
                            <li>
                              <NavLink
                                className={`${styles.a}`}
                                to="/settings/User"
                                style={({ isActive }) => ({
                                  color: isActive ? "#fff" : "",
                                  background: isActive ? "#ff8499" : "",
                                })}
                              >
                                <span>Users</span>
                              </NavLink>
                            </li>
                          )}
                        {currentUserData &&
                          currentUserData.scopes &&
                          currentUserData.scopes.includes("add_team") && (
                            <li>
                              <NavLink
                                className={`${styles.a}`}
                                to="/settings/Teams"
                                style={({ isActive }) => ({
                                  color: isActive ? "#fff" : "",
                                  background: isActive ? "#ff8499" : "",
                                })}
                              >
                                <span>Teams</span>
                              </NavLink>
                            </li>
                          )}
                        {currentUserData &&
                          currentUserData.scopes &&
                          currentUserData.scopes.includes("add_ticket") && (
                            <li>
                              <NavLink
                                className={`${styles.a}`}
                                to="/settings/Brands"
                                style={({ isActive }) => ({
                                  color: isActive ? "#fff" : "",
                                  background: isActive ? "#ff8499" : "",
                                })}
                              >
                                <span>Brands</span>
                              </NavLink>
                            </li>
                          )}
                        {currentUserData?.scopes?.includes(
                          "edit_report_notification_settings"
                        ) ||
                        currentUserData?.scopes?.includes(
                          "show_report_notification_settings"
                        ) ? (
                          <li>
                            <NavLink
                              className={`${styles.a}`}
                              to="/settings/notification"
                              style={({ isActive }) => ({
                                color: isActive ? "#fff" : "",
                                background: isActive ? "#ff8499" : "",
                              })}
                            >
                              <span>Agent Notifications</span>
                            </NavLink>
                          </li>
                        ) : (
                          <></>
                        )}
                      </ul>
                    </Collapse>
                  </li>
                )}
              {/* End Components Nav */}
              {currentUserData &&
                currentUserData.scopes &&
                currentUserData.scopes.includes("add_tags") && (
                  <li className={`${styles.nav_item}`}>
                    <div
                      className={` ${styles.nav_link} cursor-pointer ${styles.collapsed} ps-3 d-flex `}
                      // data-bs-target="#tags"
                      // data-bs-toggle="collapse"
                      aria-expanded="false"
                      onClick={(e) =>
                        setToggleHelpdeskSettings(!toggleHelpdeskSettings)
                      }
                    >
                      <span className={`w-100 ${styles.toggler}`}>
                        Helpdesk Settings
                      </span>
                      {toggleHelpdeskSettings === true ? (
                        <i className="fa fa-angle-down" />
                      ) : (
                        <i className="fa fa-angle-up" />
                      )}
                    </div>
                    <Collapse in={toggleHelpdeskSettings}>
                      <ul
                        id="tags"
                        className={` ${styles.nav_content} ${styles.collapsed}`}
                        data-bs-parent="#sidebar_nav"
                      >
                        <li>
                          <NavLink
                            className={`${styles.a}`}
                            to="/settings/Tags"
                            style={({ isActive }) => ({
                              color: isActive ? "#fff" : "",
                              background: isActive ? "#ff8499" : "",
                            })}
                          >
                            <span>Tags</span>
                          </NavLink>
                        </li>
                      </ul>
                    </Collapse>
                  </li>
                )}

              {/* Set Rules */}
              {/* <li className={`${styles.nav_item}`}>
                <div
                  className={` ${styles.nav_link} cursor-pointer ${styles.collapsed} ps-3 d-flex justify-content-between  `}
                  // data-bs-target="#tags"
                  // data-bs-toggle="collapse"
                  aria-expanded="false"
                  onClick={(e) => setToggleSetRules(!toggleSetRules)}
                >
                  <span className={`w-100 ${styles.toggler}`}>Set Rules</span>
                  {toggleSetRules === true ? (
                    <i className="fa fa-angle-down" />
                  ) : (
                    <i className="fa fa-angle-up" />
                  )}
                </div>
                <Collapse in={toggleSetRules}>
                  <ul
                    id="tags"
                    className={` ${styles.nav_content} ${styles.collapsed}`}
                    data-bs-parent="#sidebar_nav"
                  >
                    <li>
                      <NavLink
                        className={`${styles.a}`}
                        to="/settings/Drafting"
                        style={({ isActive }) => ({
                          color: isActive ? "#fff" : "",
                          background: isActive ? "#ff8499" : "",
                        })}
                      >
                        <span className={`${styles.accodinBody}`}>
                          Drafting
                        </span>
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        className={`${styles.a}`}
                        to="/settings/DraftingNew"
                        style={({ isActive }) => ({
                          color: isActive ? "#fff" : "",
                          background: isActive ? "#ff8499" : "",
                        })}
                      >
                        <span>Automation Settings</span>
                      </NavLink>
                    </li>
                  </ul>
                </Collapse>
              </li> */}
              {/* End Components Nav */}
              {/* <li className={`${styles.nav_item}`}>
            <div
              className={` ${styles.nav_link} cursor-pointer ${styles.collapsed} ps-3 d-flex `}
              // data-bs-target="#tags"
              // data-bs-toggle="collapse"
              aria-expanded="false"
              onClick={(e) => setToggleIntegrations(!toggleIntegrations)}
            >
              <span className={`w-100 ${styles.toggler}`}>Integrations</span>
              {toggleIntegrations === true ? (
                <i className="fa fa-angle-down" />
              ) : (
                <i className="fa fa-angle-up" />
              )}
            </div>
            <Collapse in={toggleIntegrations}>
              <ul
                id="tags"
                className={` ${styles.nav_content} ${styles.collapsed}`}
                data-bs-parent="#sidebar_nav"
              >
                <li>
                  <NavLink
                    className={`${styles.a}`}
                    to="/settings/integrations/email"
                    style={({ isActive }) => ({
                      color: isActive ? "#fff" : "",
                      background: isActive ? "#ff8499" : "",
                    })}
                  >
                    <span>Email</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={`${styles.a}`}
                    to="/settings/integrations/facebook"
                    style={({ isActive }) => ({
                      color: isActive ? "#fff" : "",
                      background: isActive ? "#ff8499" : "",
                    })}
                  >
                    <span>Facebook</span>
                  </NavLink>
                </li>
              </ul>
            </Collapse>
          </li> */}
              {/* End Components Nav */}
              {/* Hided on 13-06-22 */}
              {/* Hide on 30.06.2022 */}
              {currentUserData &&
                currentUserData.ui_visibility &&
                currentUserData.ui_visibility.includes("billing_view") && 
                (
                <li className={`${styles.nav_item}`}>
                  <div
                    className={` ${styles.nav_link} cursor-pointer ${styles.collapsed} ps-3 d-flex `}
                    // data-bs-target="#tags"
                    // data-bs-toggle="collapse"
                    aria-expanded="false"
                    onClick={(e) => setToggleBilling(!toggleBilling)}
                  >
                    <span className={`w-100 ${styles.toggler}`}>Billing</span>
                    {toggleBilling === true ? (
                      <i className="fa fa-angle-down" />
                    ) : (
                      <i className="fa fa-angle-up" />
                    )}
                  </div>
                  <Collapse in={toggleBilling}>
                    <ul
                      id="Billing"
                      className={` ${styles.nav_content} ${styles.collapsed}`}
                      data-bs-parent="#sidebar_nav"
                    >
                      <li>
                        <NavLink
                          className={`${styles.a}`}
                          to="/settings/billing"
                          style={({ isActive }) => ({
                            color: isActive ? "#fff" : "",
                            background: isActive ? "#ff8499" : "",
                          })}
                        >
                          <span>Plan and Pricing</span>
                        </NavLink>
                      </li>
                    </ul>
                  </Collapse>
              </li>
              )}
              {/* End Components Nav */}
              {/* <li className={`${styles.nav_item}`}>
            <div
              className={` ${styles.nav_link} cursor-pointer ${styles.collapsed} ps-3 d-flex `}
              // data-bs-target="#tags"
              // data-bs-toggle="collapse"
              aria-expanded="false"
              onClick={(e) => setToggleIntegrations(!toggleIntegrations)}
            >
              <span className={`w-100 ${styles.toggler}`}>Integrations</span>
              {toggleIntegrations === true ? (
                <i className="fa fa-angle-down" />
              ) : (
                <i className="fa fa-angle-up" />
              )}
            </div>
            <Collapse in={toggleIntegrations}>
              <ul
                id="tags"
                className={` ${styles.nav_content} ${styles.collapsed}`}
                data-bs-parent="#sidebar_nav"
              >
                <li>
                  <NavLink
                    className={`${styles.a}`}
                    to="/settings/integrations/email"
                    style={({ isActive }) => ({
                      color: isActive ? "#fff" : "",
                      background: isActive ? "#ff8499" : "",
                    })}
                  >
                    <span>Email</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={`${styles.a}`}
                    to="/settings/integrations/facebook"
                    style={({ isActive }) => ({
                      color: isActive ? "#fff" : "",
                      background: isActive ? "#ff8499" : "",
                    })}
                  >
                    <span>Facebook</span>
                  </NavLink>
                </li>
              </ul>
            </Collapse>
          </li> */}
              {/* End Components Nav */}
              {/* Hided on 13-06-22 */}
              {/* Hide on 30.06.2022 */}
              {currentUserData &&
                currentUserData.scopes &&
                currentUserData.scopes.includes("add_tags") && (
                  <li className={`${styles.nav_item}`}>
                    <div
                      className={` ${styles.nav_link} cursor-pointer ${styles.collapsed} ps-3 d-flex `}
                      // data-bs-target="#tags"
                      // data-bs-toggle="collapse"
                      aria-expanded="false"
                      onClick={(e) =>
                        setToggleChannelIntegration(!toggleChannelIntegration)
                      }
                    >
                      <span className={`w-100 ${styles.toggler}`}>
                        Channels and Integration
                      </span>
                      {toggleChannelIntegration === true ? (
                        <i className="fa fa-angle-down" />
                      ) : (
                        <i className="fa fa-angle-up" />
                      )}
                    </div>
                    <Collapse in={toggleChannelIntegration}>
                      <ul
                        id="tags"
                        className={` ${styles.nav_content} ${styles.collapsed}`}
                        data-bs-parent="#sidebar_nav"
                      >
                        {/* <li>
                      <NavLink
                        className={`${styles.a}`}
                        // to="/settings/my-integration"
                        to="/settings/integrations"
                        style={({ isActive }) => ({
                          color: isActive ? "#fff" : "",
                          background: isActive ? "#ff8499" : "",
                        })}
                      >
                        <span>My Integrations</span>
                      </NavLink>
                      </li> */}
                        <li>
                          <NavLink
                            className={`${styles.a}`}
                            to="/settings/browse-integration"
                            style={({ isActive }) => ({
                              color: isActive ? "#fff" : "",
                              background: isActive ? "#ff8499" : "",
                            })}
                          >
                            <span>Browse Integration</span>
                          </NavLink>
                        </li>
                      </ul>
                    </Collapse>
                  </li>
                )}
              {/* <li className={`${styles.nav_item}`}>
                <div
                  className={` ${styles.nav_link} cursor-pointer ${styles.collapsed} ps-3 d-flex `}
                  // data-bs-target="#tags"
                  // data-bs-toggle="collapse"
                  aria-expanded="false"
                  onClick={(e) =>
                    setToggleAvailibility(!toggleAvailability)
                  }
                >
                  <span className={`w-100 ${styles.toggler}`}>
                   Availability settings
                  </span>
                  {toggleAvailability === true ? (
                    <i className="fa fa-angle-down" />
                  ) : (
                    <i className="fa fa-angle-up" />
                  )}
                </div>
                <Collapse in={toggleAvailability}>
                  <ul
                    id="tags"
                    className={` ${styles.nav_content} ${styles.collapsed}`}
                    data-bs-parent="#sidebar_nav"
                  >
                    <li>
                      <NavLink
                        className={`${styles.a}`}
                        to="/settings/availibility"
                        style={({ isActive }) => ({
                          color: isActive ? "#fff" : "",
                          background: isActive ? "#ff8499" : "",
                        })}
                      >
                        <span>Business Hours</span>
                      </NavLink>
                    </li>
                    
                  </ul>
                </Collapse>
              </li> */}
            </ul>
          </div>
          {showSettingsSubSB === true && getToggleContent()}
        </div>
      )}
      {showSettingsSubSB === false && getToggleContent()}
    </div>
  );
}
export default SideBar;
