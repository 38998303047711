/**
 * This file is the service file used for making api request.
 * It contains the getNotificationSettingsDataService function service which is explained below.
 *
 * @author Yash Aditya
 */

import { axiosJSON } from "src/globals/axiosEndPoints";
import { NotificationSettingsData } from "src/routes/Report/Children/NotificationContainer/hooks/notifications/useNotifications";

/**
 * This service is created to get the saved notification settings.
 */
const getNotificationSettingsDataService = async () => {
  const { data: res } = await axiosJSON.post(
    `/api/reports/getNotificationSettingsData`
  );

  if (res.err || res.error) {
    throw res.msg ?? res.message ?? "Something went wrong!";
  }

  return res.data as NotificationSettingsData;
};

export default getNotificationSettingsDataService;
