/**
 * This file contains the custom hook for handling KB settings.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import useThemeBox from "../../../ArticleSideBar/ThemeSelection/Children/ThemeBox/useThemeBox";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  ImageType,
  replaceThemeImages,
} from "src/services/KnowledgeBase/Settings/Styles/replaceThemeImages.service";
import { resetKnoweledgeBaseImages } from "src/services/KnowledgeBase/Settings/Styles/resetThemeImages.service";
import { selectFile } from "src/utils/utils";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";

/**
 * This hook is used to maintain the BrandSetting component.
 */
function useBrandSetting() {
  // States and variables
  const [dropdown, setDropDown] = useState(false);
  const [styleTheme, setStyleTheme] = useState(false);

  const { integrationId } = useParams();
  const pageIntegrationId = integrationId
    ? decodeURIComponent(integrationId)
    : "";

  // Getting data from useThemeBox
  const { data, error, isError, isLoading } = useThemeBox();

  const queryClient = useQueryClient();

  const [helplamaBranding, setHelpLamaBranding] = useState(
    data?.helplamaBranding ?? false
  );

  // useEffect to set the helplamaBranding
  useEffect(() => {
    if (data && data.helplamaBranding !== undefined) {
      setHelpLamaBranding(data.helplamaBranding);
    }
  }, [data]);

  // Getting updateKBSettings and getThemeData from useThemeBox
  const { updateKBSettings, getThemeData } = useThemeBox();

  // Mutation for replaceImage and resetImages
  const replaceImage = useMutation({
    mutationFn: replaceThemeImages,
    onSuccess: () => {
      queryClient.invalidateQueries(["getKnoweldgeBaseStyles"]);
      getThemeData.refetch();
    },
  });

  // Mutation for resetImages
  const resetImages = useMutation({
    mutationFn: resetKnoweledgeBaseImages,
    onSuccess: () => {
      queryClient.invalidateQueries(["getKnoweldgeBaseStyles"]);
      getThemeData.refetch();
    },
  });

  // Doing the mutation for handleHelplamaBranding
  const handleHelplamaBranding = (e: { target: { checked: boolean } }) => {
    setHelpLamaBranding(e.target.checked);
    if (data) {
      updateKBSettings.mutate({
        integrationId: pageIntegrationId,
        themeId: data.themeId,
        helplamaBranding: e.target.checked,
      });
    }
  };

  // Function For Handling Replace Image
  const handleReplaceImage = async (type: ImageType) => {
    // Try Catch Block
    try {
      let image = (await selectFile(["jpeg", "jpg", "png"]))[0];
      // Checking if the image is not null and the type is backgroundImage
      if (type === "backgroundImage") {
        const img = new Image();
        img.src = URL.createObjectURL(image);
        img.onload = () => {
          // Checking if the image width is less than 1200 and height is less than 280
          if (img.width < 1200 || img.height < 280) {
            // If the image width is less than 1200 and height is less than 280 then show the toast
            pushTheToast({
              text: "Background image should be at least 1200x280px",
              position: "top-right",
              type: "warning",
            });
          } else {
            // If the image width is greater than 1200 and height is greater than 280 then replace the image
            replaceImage.mutate({
              type: type,
              img: image,
              integrationId: pageIntegrationId,
              themeId: data.themeId,
            });
          }
        };
        // If the image is not backgroundImage then just replace the image
      } else {
        replaceImage.mutate({
          type: type,
          img: image,
          integrationId: pageIntegrationId,
          themeId: data.themeId,
        });
      }
    } catch (error) {
      pushTheToast({
        text: (error as any).msg,
        position: "top-right",
        type: "warning",
      });
    }
  };

  // Function For Handling Reset Image
  const handleResetImage = (type: ImageType) => {
    if (type) {
      resetImages.mutate({
        type: type,
        integrationId: pageIntegrationId,
        themeId: data.themeId,
      });
    }
  };

  const [backgroundColor, setBackgroundColor] = useState(
    data?.backgroundColor ?? "#ffffff"
  );
  const [brandColor, setBrandColor] = useState(data?.brandColor ?? "#000000");

  // UseRef for timeoutId
  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);

  // useEffect for clearing the timeoutId
  useEffect(() => {
    return () => {
      if (timeoutIdRef.current !== null) {
        clearTimeout(timeoutIdRef.current);
      }
    };
  }, []);

  const [textStatus, setTextStatus] = useState({
    backgroundColor: "",
    brandColor: "",
  });

  // Function for handling the brandColorChange
  const handleBrandColorChange = (e: any) => {
    setBrandColor(e.target.value);
    // Checking if the timeoutIdRef.current is not null then clear the timeoutId
    if (timeoutIdRef.current !== null) {
      clearTimeout(timeoutIdRef.current);
    }

    // Setting the timeoutIdRef.current to setTimeout ,
    timeoutIdRef.current = setTimeout(() => {
      setTextStatus({
        ...textStatus,
        brandColor: "Updating...",
      });

      // Doing the mutation for updateKBSettings
      updateKBSettings.mutate(
        {
          integrationId: pageIntegrationId,
          themeId: data.themeId,
          brandColor: brandColor,
        },
        {
          onSuccess: () => {
            setTextStatus({
              ...textStatus,
              brandColor: "Updated",
            });
            setTimeout(() => {
              setTextStatus({
                ...textStatus,
                brandColor: "",
              });
            }, 700);
          },
          onError: () => {
            setTextStatus({
              ...textStatus,
              brandColor: "Failed",
            });
          },
        }
      );
    }, 500);
  };

  // Function for handling the backgroundColorChange
  const handleBackgroundColorChange = (e: any) => {
    setBackgroundColor(e.target.value);
    // Checking if the timeoutIdRef.current is not null then clear the timeoutId
    if (timeoutIdRef.current !== null) {
      clearTimeout(timeoutIdRef.current);
    }

    // Setting the timeoutIdRef.current to setTimeout , for debouncing
    timeoutIdRef.current = setTimeout(() => {
      setTextStatus({
        ...textStatus,
        backgroundColor: "Updating...",
      });
      updateKBSettings.mutate(
        {
          integrationId: pageIntegrationId,
          themeId: data.themeId,
          backgroundColor: backgroundColor,
        },
        {
          onSuccess: () => {
            setTextStatus({
              ...textStatus,
              backgroundColor: "Updated",
            });
            setTimeout(() => {
              setTextStatus({
                ...textStatus,
                backgroundColor: "",
              });
            }, 700);
          },
          onError: () => {
            setTextStatus({
              ...textStatus,
              backgroundColor: "Failed",
            });
          },
        }
      );
    }, 200); //changed to decrease the time to update after selecting color
  };

  return {
    dropdown,
    setDropDown,
    styleTheme,
    setStyleTheme,
    isLoading,
    isError,
    error,
    backgroundColor,
    handleBackgroundColorChange,
    handleBrandColorChange,
    brandColor,
    data,
    handleReplaceImage,
    handleResetImage,
    handleHelplamaBranding,
    helplamaBranding,
    textStatus,
    imagesLoading: replaceImage.isLoading,
  };
}

export default useBrandSetting;
