import { useEffect, useMemo, useState } from "react";
import { Col, Row, Tab } from "react-bootstrap";
import SkeletonModalStructure from "src/components/AutomationComponents/SkeletonModalStructure/SkeletonModalStructure";
import Loader from "src/components/Loader";
import styles from "./ConfigurationModal.module.scss";
import {
  CompareDetail,
  Group,
  LogicalOperatorTypes,
} from "src/services/Automation/getAutomationById.service";
import useFetchConditionOptions from "../../../hooks/useFetchConditionOptions";
import { useParams } from "react-router-dom";
import { useConditionGroupProvider } from "../../../hooks/useConditionGroupState";
import Condition from "./Components/Condition/Condition";
import { v4 as uuidv4 } from "uuid";
import { CompareData } from "src/services/Automation/AutomationTicketRouting/getConditionOptions.service";
interface Props {
  handleCloseModal: () => void;
  conditionGroup: Group;
  groupIndex: number;
  updateConditionGroup: (newGroup: Group) => void;
  setShowErrorModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ConfigurationModal = ({
  handleCloseModal,
  conditionGroup,
  groupIndex,
  updateConditionGroup,
  setShowErrorModal,
}: Props) => {
  const { type, integrationId } = useParams();
  const { conditionOptions, conditionOptionsStatus } = useFetchConditionOptions(
    type ?? "",
    integrationId ?? "",
  );
  const { ConditionGroupProvider, ...contexValue } =
    useConditionGroupProvider();

  useMemo(() => {
    contexValue.updateState({
      conditionGroup: JSON.parse(JSON.stringify(conditionGroup)), // Deep copy to avoid reference issues
    });
  }, []);

  const handleOperatorChange = (operator: LogicalOperatorTypes) => {
    if (contexValue.conditionGroup) {
      const newGroup: Group = { ...contexValue.conditionGroup };
      newGroup.conditionsLogicalOperator = operator;
      contexValue.updateState({
        conditionGroup: newGroup,
      });
    }
  };
  function isCompareDetail(
    detail: CompareData | CompareDetail | undefined,
  ): detail is CompareDetail {
    if (detail) {
      return (detail as CompareDetail).compareType !== undefined;
    }
    return false;
  }

  const getDefaultCompareKey = () => {
    const firstCompareDetail =
      conditionOptions?.conditionOptions?.[0]?.conditionTypes?.[0]
        ?.compareDetails?.[0];
    const compareKey = isCompareDetail(firstCompareDetail)
      ? firstCompareDetail.compareType
      : undefined;
    return compareKey;
  };

  const addCondition = () => {
    if (contexValue.conditionGroup) {
      const newGroup: Group = { ...contexValue.conditionGroup };
      const conditionUUID = uuidv4();
      if (newGroup.conditions.length > 0) {
        newGroup.conditions[newGroup.conditions.length - 1].nextConditionUUID =
          conditionUUID;
      }
      newGroup.conditions.push({
        conditionUUID: conditionUUID,
        conditionId: null,
        nextConditionUUID: null,
        fieldKey: options[0].value,
        value: null,
        conditionType: null,
        compareType: getDefaultCompareKey() ?? null,
      });
      contexValue.updateState({
        conditionGroup: newGroup,
      });
    }
  };
  const options =
    conditionOptions?.conditionOptions?.map((option) => {
      return {
        label: option.fieldName,
        value: option.fieldKey,
      };
    }) ?? [];

  const handleDiscard = () => {
    contexValue.resetState();
    handleCloseModal();
  };

  return (
    <ConditionGroupProvider value={contexValue}>
      <SkeletonModalStructure
        hasTwoSection={false}
        heading={`Configure group ${groupIndex + 1}`}
        closeFunction={handleDiscard}
      >
        {conditionOptionsStatus === "pending" ? (
          <div className="h-100 w-100 d-flex align-items-center justify-content-center">
            <Loader />
          </div>
        ) : conditionOptionsStatus === "rejected" ? (
          <div className="h-100 w-100 d-flex align-items-center justify-content-center text-danger">
            Error loading content
          </div>
        ) : (
          <Tab.Container
            activeKey={""}
            onSelect={(eventKey) => {}}
          >
            <Row bsPrefix={`row`}>
              <Col>
                <div className={`${styles.contentStyles}`}>
                  {/* Match Options */}
                  <div className="d-flex align-items-center mb-3">
                    <div className="form-check d-flex align-items-center me-5">
                      <input
                        className={`form-check-input ${styles.radioCheck} ${
                          contexValue.showErrors &&
                          !contexValue?.conditionGroup
                            ?.conditionsLogicalOperator
                            ? `border border-danger`
                            : ""
                        }`}
                        type="radio"
                        name="conditionsLogicalOperator"
                        id={`conditionsLogicalOperator-${contexValue?.conditionGroup?.groupUUID}-or`}
                        checked={
                          contexValue?.conditionGroup
                            ?.conditionsLogicalOperator === "or"
                        }
                        onClick={() => {
                          handleOperatorChange("or");
                        }}
                      />
                      <label
                        className={`form-check-label ps-2 ${styles.label}`}
                        htmlFor={`conditionsLogicalOperator-${contexValue?.conditionGroup?.groupUUID}-or`}
                      >
                        Match ANY of the below
                      </label>
                    </div>
                    <div className="form-check d-flex align-items-center">
                      <input
                        className={`form-check-input ${styles.radioCheck}`}
                        type="radio"
                        name="conditionsLogicalOperator"
                        id={`conditionsLogicalOperator-${contexValue?.conditionGroup?.groupUUID}-and`}
                        checked={
                          contexValue?.conditionGroup
                            ?.conditionsLogicalOperator === "and"
                        }
                        onClick={() => {
                          handleOperatorChange("and");
                        }}
                      />
                      <label
                        className={`form-check-label ps-2 ${styles.label}`}
                        htmlFor={`conditionsLogicalOperator-${contexValue?.conditionGroup?.groupUUID}-and`}
                      >
                        Match ALL of the below
                      </label>
                    </div>
                  </div>
                  {contexValue?.conditionGroup?.conditions &&
                  contexValue?.conditionGroup?.conditions.length > 0 ? (
                    <div>
                      {contexValue?.conditionGroup?.conditions.map(
                        (condition, conditionIndex) => {
                          return (
                            <Condition
                              key={condition.conditionUUID}
                              condition={condition}
                              conditionIndex={conditionIndex}
                              groupIndex={groupIndex}
                              conditionOptions={conditionOptions}
                            />
                          );
                        },
                      )}
                    </div>
                  ) : null}
                  <div
                    onClick={addCondition}
                    className="d-flex align-items-center mt-3 pb-2 cursor-pointer"
                  >
                    <div
                      className={`d-flex align-items-center justify-content-center me-2 ${styles.addMore}`}
                    >
                      +
                    </div>
                    <div
                      className={`${styles.blueText} ms-2 `}
                      role="button"
                    >
                      Add Condition
                    </div>
                  </div>
                </div>
                {/* Footer Buttons */}
                <div
                  className={`p-3 d-flex justify-content-end ${styles.btnWrapper}`}
                >
                  <button
                    className={`${styles.cancelBtn}`}
                    onClick={handleDiscard}
                  >
                    Cancel
                  </button>
                  <button
                    className={`ms-3 ${styles.saveBtn}`}
                    onClick={() =>
                      contexValue.handleSave(
                        type ?? "",
                        updateConditionGroup,
                        setShowErrorModal,
                      )
                    }
                    id="conditionGroupSaveBtn"
                    disabled={contexValue.saveStatus === "pending"}
                  >
                    {contexValue.saveStatus === "fulfilled"
                      ? "Saved!"
                      : contexValue.saveStatus === "pending"
                        ? "Saving..."
                        : "Save"}
                  </button>
                </div>
              </Col>
            </Row>
          </Tab.Container>
        )}
      </SkeletonModalStructure>
    </ConditionGroupProvider>
  );
};

export default ConfigurationModal;
