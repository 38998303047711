/**
 * This file is the service file used for making api request.
 * It contains the resetTheme function service which is explained below.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants"; // this is used for testing purpose only for frontend.

export interface resetThemePayload {
  integrationId: number | string;
}

/**
 * For testing frontend with own api link if backend is not prepared.
 */
const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/resetTheme`
  : "/api/chatSetting/resetTheme";

/**
 * This service is used to reset the theme.
 */
export const resetTheme = async (params: resetThemePayload) => {
  const { data: res } = await axiosJSON.post(apiEndPoint, params);
  return res;
};
