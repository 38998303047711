import { useState } from "react";
import styles from "../../AgentNotification.module.scss";
import reportClock from "src/assets/images/ReportClock.png";
import SetFrequency from "../SetFrequency/SetFrequency";

function FrequencyDD() {
  const [frequency, setFrequency] = useState<boolean>(false);

  return (
    <div className={`${styles.reportWrap} p-2 mt-3`}>
      <div
        className={`px-2 py-1 d-flex justify-content-between align-items-center cursor-pointer`}
        onClick={() => setFrequency(!frequency)}
      >
        <div className="d-flex align-items-center">
          <img
            src={reportClock}
            alt=""
            className={`${styles.reportClock}`}
          />
          <span className={`ps-2 ${styles.dropHead}`}>
            Notification frequency
          </span>
        </div>
        <div className="ps-2">
          <i className={`fa-solid fa-chevron-${frequency ? "up" : "down"}`}></i>
        </div>
      </div>
      {frequency && <SetFrequency />}
    </div>
  );
}

export default FrequencyDD;
