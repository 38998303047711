import { axiosJSON } from "src/globals/axiosEndPoints";

export interface ReturnTypes {
  key: string;
  label: string;
}

export interface Step13ReturnTypeOptions {
  data: ReturnTypes[];
}

export async function fetchGetStep13ReturnTypeOptions() {
  const { data } = await axiosJSON.get(
    "/api/setting/automation/refund/step/13/getReturnTypeOptions"
  );

  if (data.err === true) {
    throw new Error("Some Error Occurred while fetching data");
  }

  return data.data;
}
