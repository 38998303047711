import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";

interface ResponseData {
  reasonId: string;
  scenarioIds: string[];
}

export interface UpdateAntiRefundRequestResponse {
  err: boolean;
  msg: string;
  data: ResponseData[];
}

interface ExtendedReturnPeriod {
  enabled: boolean;
  days: number;
}

interface AdditionalInformation {
  enabled: boolean;
  message: string;
  extendReturnPeriod: ExtendedReturnPeriod;
}

interface GiveOffer {
  enabled: boolean;
  message: string;
  offerSelectedItems: number[];
  offerSelectedTags: string[];
  offerType: string;
  offerUnit: string;
  name: string;
}

interface OfferExchange {
  enabled: boolean;
  message: string;
  exchangeWithOtherItems: boolean;
  exchangeWithSameItems: boolean;
}

interface FollowUpQuestions {
  elementId: string;
  value: string[] | string;
}
interface Scenarios {
  scenarioId?: string;
  tags: string[];
  itemIds: string[];
  additionalInformation: AdditionalInformation;
  giveOffer: GiveOffer;
  offerExchange: OfferExchange;
  followUpQuestions : FollowUpQuestions[];
}

interface RefundRequests {
  id: string;
  scenarios: Scenarios[];
}

export interface UpdateAntiRefundRequestPayload {
  integrationId: number | string;
  refundRequests: RefundRequests[];
}

const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/updateAntiRefundRequests`
  : "/api/setting/automation/refund/step/13/updateAntiRefundRequests";

export async function updateAntiRefundRequests(
  params: UpdateAntiRefundRequestPayload
) {
  const { data } = await axiosJSON.post(apiEndPoint, params);

  if (data.err) {
    throw new Error("Some Error Occurred while fetching data");
  }
  return data.data;
}
