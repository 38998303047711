import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import styles from "./ImagePreviewModal.module.scss";
import { TinyMCEUploadFile } from "src/interfaces/TinyMCEContainer/ITinyMCEContainer";
import AxiosImg from "src/components/AxiosImg";

interface Props {
  show: boolean;
  onHide: () => void;
  imgSrc: TinyMCEUploadFile;
}

function ImagePreviewModal({ ...props }: Props) {
  const [imageLoaded, setImageLoaded] = useState(false);
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      contentClassName={`${styles.modalContent}`}
      dialogClassName={`${styles.dialogClassName}`}
    >
      {props.imgSrc.attachmentURL ? ( // Checks if the image source is an attachment URL
        <AxiosImg
          url={props.imgSrc.attachmentURL}
          isLoaded={imageLoaded}
          setIsLoaded={setImageLoaded}
          className={`${styles.showImg}`}
        />
      ) : (
        <img
          className={`${styles.showImg}`}
          src={
            props.imgSrc.file
              ? (props.imgSrc.file as any).customPreviewURL ??
                URL.createObjectURL(props.imgSrc.file)
              : ""
          } // Sets the source of the image
        />
      )}
      <i
        onClick={props.onHide}
        className={`${styles.closeBtn} fa-solid fa-xmark`}
      ></i>
    </Modal>
  );
}

export default ImagePreviewModal;
