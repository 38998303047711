import { useQuery } from "@tanstack/react-query";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { EAutomationType } from "src/routes/BotSettings/Children/AutomationList/AutomationList";
import {
  IDispatchRuleData,
  getDispatchRuleConfigByIdService,
} from "src/services/Automation/DispatchRulesConfigModal/getDispatchRuleConfigById.service";
import { DispatchModalType } from "../../../DispatchModal";
import { dispatchSteps, EDispatchSteps } from "../DispatchStepsConfig";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { useAutomationContext } from "src/routes/BotSettings/Children/Automation/Hooks/useAutomationContext";
import { QuestionVisibility } from "src/services/Automation/getAutomationById.service";
import { validateDispatchRuleSteps } from "src/routes/BotSettings/Children/Automation/helpers/DispatchModal.helpers";

// export interface ExtendedCreateDispatchRuleConfigParams
//   extends CreateDispatchRuleConfigParams {
//   type: DispatchModalType["type"];
//   configurationId?: DispatchModalType["configurationId"];
//   automationType: EAutomationType;
// }

// export interface DispatchSectionState {
//   state: ExtendedCreateDispatchRuleConfigParams;
//   originalData: ExtendedCreateDispatchRuleConfigParams | null;
//   updateState: (data: Partial<ExtendedCreateDispatchRuleConfigParams>) => void;
//   refetch: () => void;
//   isLoading: boolean;
//   activeStep: number;
//   setActiveStep: React.Dispatch<React.SetStateAction<number>>;
// }

export interface DispatchSectionState {
  state: IDispatchRuleData;
  originalData?: IDispatchRuleData;
  modalType: DispatchModalType;
  setDispatchType: React.Dispatch<
    React.SetStateAction<DispatchModalType | null>
  >;
  automationType: EAutomationType;
  updateState: (data: Partial<IDispatchRuleData>) => void;
  updateData: (data: IDispatchRuleData) => void;
  isLoading: boolean;
  completedSteps: EDispatchSteps[];
  showUserSegment: boolean;
  setCompletedSteps: React.Dispatch<React.SetStateAction<EDispatchSteps[]>>;
  showErrors: boolean;
  setShowErrors: React.Dispatch<React.SetStateAction<boolean>>;
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}

const initialState: IDispatchRuleData = {
  createdAtGmt: "",
  dispatchedCount: 0,
  configurationState: "pending",
  configurationId: "",
  dispatchToDetails: [],
  integrationId: "",
  ruleName: "",
  bugCategoryDetails: [],
  bugPriorityDetails: [],
  issueDescription: [],
  userSegmentDetails: [],
};

const initialContext: DispatchSectionState = {
  state: initialState,
  automationType: EAutomationType.BUG_MANAGEMENT,
  modalType: {
    type: "create",
  },
  setDispatchType: () => {},
  updateState: () => {},
  updateData: () => {},
  completedSteps: [],
  showUserSegment: false,
  setCompletedSteps: () => {},
  showErrors: false,
  setShowErrors: () => {},
  isLoading: false,
  activeStep: 0,
  setActiveStep: () => {},
};

const DispatchSectionContext =
  createContext<DispatchSectionState>(initialContext);

export const useDispatchSectionContext = () => {
  const context = useContext(DispatchSectionContext);

  if (!context) {
    throw new Error(
      "useDispatchSectionContext must be used within a DispatchSectionProvider",
    );
  }

  return context;
};

const USER_SEGMENT_BUG_QUESTION_KEY = "allowedBugReportUserSegments";
const USER_SEGMENT_ISSUE_DISPATCH_QUESTION_KEY =
  "allowedIssueDispatchUserSegments";

interface BugConfigProviderReturn extends DispatchSectionState {
  DispatchSectionProvider: React.Provider<DispatchSectionState>;
}

export const useDispatchSectionProvider = (
  dispatchModalType: DispatchModalType,
  integrationId: string,
  automationType: EAutomationType,
  setDispatchType: React.Dispatch<
    React.SetStateAction<DispatchModalType | null>
  >,
): BugConfigProviderReturn => {
  const [activeStep, setActiveStep] = useState<number>(0);

  const { formData } = useAutomationContext();

  const showUserSegment = useMemo(() => {
    const questionKey =
      automationType === EAutomationType.BUG_MANAGEMENT
        ? USER_SEGMENT_BUG_QUESTION_KEY
        : automationType === EAutomationType.ISSUE_DISPATCH
          ? USER_SEGMENT_ISSUE_DISPATCH_QUESTION_KEY
          : "";

    const question = formData.questionsData[questionKey];

    return question.visibility !== QuestionVisibility.RelativeHidden;
  }, [formData.questionsData, automationType]);

  const [state, setState] = useState<IDispatchRuleData>({
    ...initialState,
    integrationId,
    configurationId: dispatchModalType.configurationId ?? "",
  });

  const [completedSteps, setCompletedSteps] = useState<EDispatchSteps[]>([
    ...initialContext.completedSteps,
  ]);

  const [showErrors, setShowErrors] = useState(false);

  const { data, fetchStatus } = useQuery<IDispatchRuleData, Error>({
    queryKey: [
      "getDispatchRuleConfigByIdService",
      integrationId,
      dispatchModalType,
    ],
    queryFn: () => {
      if (
        integrationId &&
        dispatchModalType.type === "edit" &&
        dispatchModalType.configurationId
      ) {
        return getDispatchRuleConfigByIdService({
          payload: {
            configurationId: dispatchModalType.configurationId,
            integrationId,
          },
          type: automationType,
        });
      }

      throw new Error("Cannot get dispatch rule. Check Params");
    },
    onError: (err) => {
      pushTheToast({
        position: "top-right",
        text:
          typeof err === "string"
            ? err
            : err.message ?? "Could not fetch dispatch rule configuration",
        type: "danger",
      });
    },
    onSuccess: (data) => {
      if (
        automationType !== EAutomationType.BUG_MANAGEMENT &&
        automationType !== EAutomationType.ISSUE_DISPATCH
      ) {
        return;
      }

      const steps: EDispatchSteps[] = [];
      dispatchSteps[automationType].forEach((val) => {
        const isValid = validateDispatchRuleSteps({
          state: data,
          automationType,
          stepType: val.key,
          validateUserSegments: showUserSegment,
        });

        if (isValid) {
          steps.push(val.key);
        }
      });

      setCompletedSteps(steps);
    },
    enabled: dispatchModalType.type === "edit",
  });

  useEffect(() => {
    if (data) {
      // The data object is deeply nested object and hence deep cloning is required here
      setState(JSON.parse(JSON.stringify(data)));
    }
  }, [data]);

  const updateState = useCallback((data: Partial<IDispatchRuleData>) => {
    setState((currentData) => {
      return {
        ...currentData,
        ...data,
      };
    });
  }, []);

  const updateData = useCallback(
    (data: IDispatchRuleData) => {
      if (dispatchModalType.type === "edit") {
        setState(data);
      }
    },
    [dispatchModalType.type],
  );

  return {
    state,
    activeStep,
    setActiveStep,
    updateData,
    showUserSegment,
    completedSteps,
    setCompletedSteps,
    showErrors,
    setShowErrors,
    automationType,
    originalData: data,
    modalType: dispatchModalType,
    setDispatchType,
    updateState,
    isLoading: fetchStatus === "fetching",
    DispatchSectionProvider: DispatchSectionContext.Provider, // Providing BugConfigContext as ConfigProvider
  };
};
