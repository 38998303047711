import { useCallback, useMemo, useState } from "react";
import { Dropdown } from "react-bootstrap";
import DatePicker from "react-datepicker";
import {
  generateDateOnly,
  isMySQLDateStringValid,
} from "src/utils/dateLibrary";
import { FieldDataDateTypes } from "src/services/Automation/AutomationTicketRouting/getConditionOptions.service";
import styles from "../ConditionValue.module.scss";

interface Props {
  value: string;
  onChange: (
    value: string,
    labels?: Record<string, string[]>,
    isSpecificTime?: boolean,
  ) => void;
  placeholder?: string;
  dateType: FieldDataDateTypes;
  error?: string;
  suffix?: string;
  isSpecificTime?: boolean;
  onSpecificTimeChange?: (isSpecificTime: boolean) => void;
}
const DateTimeSelector = ({
  value,
  onChange,
  placeholder = "Enter date",
  dateType,
  error,
  suffix,
  isSpecificTime,
  onSpecificTimeChange = () => {},
}: Props) => {
  const [showAMPMMenu, setShowAMPMMenu] = useState(false);
  const [showSelectSpecificTimeMenu, setShowSelectSpecificTimeMenu] =
    useState(false);
  const [selectSpecificTime, setSelectSpecificTime] = useState(false);

  const { date, time } = useMemo(() => {
    let parsedValue = value;

    // Reset the value if we have number input value
    if (typeof parsedValue === "number") {
      parsedValue = "";
    }

    const [date = "", time = ""] = parsedValue.split(" ");

    // If we have time initially then set the specific time true
    if (isSpecificTime !== undefined) {
      setSelectSpecificTime(isSpecificTime);
    } else {
      setSelectSpecificTime(false);
    }

    return { date, time };
  }, [value, isSpecificTime, dateType]);

  const handleDateChange = useCallback(
    (value: Date | null) => {
      if (value) {
        onChange(`${generateDateOnly(value)} ${time}`.trim());
        onSpecificTimeChange(selectSpecificTime);
      } else {
        onChange("");
      }
    },
    [onChange, onSpecificTimeChange, selectSpecificTime, time],
  );

  const handleHHChange: React.ChangeEventHandler<HTMLInputElement> =
    useCallback(
      (e) => {
        const hour = e.target.value;

        let [hh = "00", mm = "00", ss = "00"] = time.split(":");
        if (hour) {
          let intHour = parseInt(hour);
          if (intHour > 12) {
            intHour = 12;
          } else if (intHour < 1) {
            intHour = 1;
          }
          const intHH = parseInt(hh);
          const newHH = intHour + (intHH > 12 ? 12 : 0);
          hh = `${newHH < 10 ? `0${newHH}` : newHH}`;
        }

        onChange(`${date} ${hh}:${mm}:${ss}`);
      },
      [onChange, date, time],
    );

  const handleMMChange: React.ChangeEventHandler<HTMLInputElement> =
    useCallback(
      (e) => {
        const minute = e.target.value;

        let [hh = "00", mm = "00", ss = "00"] = time.split(":");
        if (minute) {
          let intMinute = parseInt(minute);
          if (intMinute > 59) {
            intMinute = 59;
          } else if (intMinute < 0) {
            intMinute = 0;
          }
          mm = intMinute < 10 ? `0${intMinute}` : `${intMinute}`;
        }

        onChange(`${date} ${hh}:${mm}:${ss}`);
      },
      [onChange, date, time],
    );

  const handleTimeChange: React.MouseEventHandler<HTMLElement> = useCallback(
    (e) => {
      const hour = e.currentTarget.getAttribute("data-hh");
      const minute = e.currentTarget.getAttribute("data-mm");
      const second = e.currentTarget.getAttribute("data-ss");
      const ampm = e.currentTarget.getAttribute("data-ampm");

      let [hh = "00", mm = "00", ss = "00"] = time.split(":");
      if (hour) {
        const intHour = parseInt(hour);
        const intHH = parseInt(hh);
        if (intHH !== intHour) {
          const newHH = intHour + (intHH > 12 ? 12 : 0);
          hh = `${newHH < 10 ? `0${newHH}` : newHH}`;
        }
      } else if (minute) {
        mm = minute;
      } else if (second) {
        ss = second;
      } else if (ampm === "am") {
        const amH = parseInt(hh);
        if (amH >= 12) {
          const newHH = amH - 12;
          hh = `${newHH < 10 ? `0${newHH}` : newHH}`;
        }
        setShowAMPMMenu(false);
      } else if (ampm === "pm") {
        const pmH = parseInt(hh);
        if (pmH < 12) {
          const newHH = pmH + 12;
          hh = `${newHH < 10 ? `0${newHH}` : newHH}`;
        }
        setShowAMPMMenu(false);
      }
      onChange(`${date} ${hh}:${mm}:${ss}`);
    },
    [onChange, date, time],
  );

  const isError = useMemo(
    () =>
      error &&
      !isMySQLDateStringValid(value, dateType.replace("Range", "") as any),
    [error, value, dateType],
  );

  return (
    <div className="d-flex flex-column">
      <div className="d-flex flex-column flex-md-row">
        <div className="">
          <DatePicker
            selected={date ? new Date(date) : null} // Use the Date object in the DatePicker
            onChange={handleDateChange}
            placeholderText={placeholder}
            className={`${isError ? styles.errBorder : ""}`}
            isClearable={false}
            dateFormat="MMM d, yyyy"
            customInput={
              <input
                type="text"
                value={date}
                placeholder={placeholder}
                className={`${styles.datePickerInput}`}
              />
            }
          />
        </div>
        <Dropdown
          show={showSelectSpecificTimeMenu}
          onToggle={(show) => setShowSelectSpecificTimeMenu(show)}
          className=""
        >
          <Dropdown.Toggle
            id="dropdown-basic"
            as="div"
            className={`${styles.timeOptionContainer} ${styles.dropText} cursor-pointer px-2 mt-2 mt-md-0 ms-md-2 align-items-center justify-content-between`}
          >
            <span>{selectSpecificTime ? "Set specific time" : "Any time"}</span>
            <span className={`ps-2 ${styles.arrowDown}`}>
              <i className="fa-solid fa-chevron-down"></i>
            </span>
          </Dropdown.Toggle>

          <Dropdown.Menu bsPrefix={`dropdown-menu px-1 ${styles.dropMenuTime}`}>
            <div>
              <span
                className={`${styles.dropItemTime} p-2 d-flex align-items-center justify-content-start`}
                onClick={() => {
                  setSelectSpecificTime(false);
                  setShowSelectSpecificTimeMenu(false);
                  onChange(
                    `${date}${isSpecificTime !== undefined ? " 00:00:00" : ""}`,
                  );
                  onSpecificTimeChange(false);
                }}
              >
                Any time
              </span>
              <span
                className={`${styles.dropItemTime} p-2 d-flex align-items-center justify-content-start`}
                onClick={() => {
                  setSelectSpecificTime(true);
                  setShowSelectSpecificTimeMenu(false);
                  onChange(`${date} 00:00:00`);
                  onSpecificTimeChange(true);
                }}
              >
                Set specific time
              </span>
            </div>
          </Dropdown.Menu>
        </Dropdown>

        <div>
          {/* Time */}
          {(dateType === "dateTime" || selectSpecificTime) && (
            <div
              className={`${
                styles.timeContainer
              } ms-2 d-flex align-items-center justify-content-between px-2 ${
                isError ? styles.errBorder : ""
              }`}
            >
              <div>
                <input
                  type="number"
                  value={(parseInt(time.split(":")[0] ?? "00") === 0
                    ? "12"
                    : parseInt(time.split(":")[0] ?? "00") > 12
                      ? parseInt(time.split(":")[0] ?? "00") - 12
                      : parseInt(time.split(":")[0] ?? "00")
                  )
                    .toString()
                    .padStart(2, "0")}
                  className={`${styles.timeBox} border-0`}
                  onChange={handleHHChange}
                  min={1}
                  max={12}
                  style={{ maxWidth: "19px" }}
                />
                <span>{" : "}</span>
                <input
                  type="number"
                  value={time.split(":")[1] ?? "00"}
                  className={`${styles.timeBox} border-0`}
                  onChange={handleMMChange}
                  min={0}
                  max={59}
                  style={{ maxWidth: "19px" }}
                />
              </div>
              <Dropdown
                show={showAMPMMenu}
                onToggle={(show) => setShowAMPMMenu(show)}
              >
                <Dropdown.Toggle
                  id="dropdown-basic"
                  as="div"
                  className={`ms-2 me-1 ps-1 pe-1 ${styles.timeDrop}`}
                >
                  {time.split(":")[0]
                    ? parseInt(time.split(":")[0]) > 11
                      ? "PM"
                      : "AM"
                    : "Select AM - PM"}
                  <span className={`ps-2 ${styles.arrowDown}`}>
                    <i className="fa-solid fa-chevron-down"></i>
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu
                  bsPrefix={`dropdown-menu ${styles.dropMenuTime}`}
                  style={{ minWidth: "53px" }}
                >
                  <div>
                    <span
                      className={`${styles.dropItemTime} d-flex align-items-center justify-content-center`}
                      data-ampm="am"
                      onClick={handleTimeChange}
                    >
                      AM
                    </span>
                    <span
                      className={`${styles.dropItemTime} d-flex align-items-center justify-content-center`}
                      data-ampm="pm"
                      onClick={handleTimeChange}
                    >
                      PM
                    </span>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
        </div>
      </div>
      {isError ? <div className={`${styles.errText} ms-2`}>{error}</div> : ""}
    </div>
  );
};

export default DateTimeSelector;
