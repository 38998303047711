import { useCallback, useEffect, useRef, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Select from "react-select";
import moreInfo from "src/assets/images/moreInfo.png";
import styles from "./RouteCondition.module.scss";
import genLogo from "src/assets/images/General.png";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  fetchAllTeams,
  resetTeamsData,
} from "src/store/slices/teamSettings/teamSettings.slice";
import InfiniteScroll from "src/components/InfiniteScroll";
import Loader from "src/components/Loader";
import { v4 as uuidV4 } from "uuid";
import { NavLink, useParams } from "react-router-dom";
import { resetRoutingRulesData } from "src/store/slices/liveChatSetting/RoutingRules/RoutingRulesSetting.slice";
interface Props {
  teamSelectedOption: {
    label: string;
    value: string | number;
  };
  setTeamSelectedOption: any;
  ruleAction: string;
  setRuleAction: any;
}
const RouteCondition = ({
  teamSelectedOption,
  setTeamSelectedOption,
  setRuleAction,
  ruleAction,
}: any) => {
  const { subSettingName } = useParams();
  const ruleId = subSettingName ? decodeURIComponent(subSettingName) : "";

  const initialized = useRef(false);
  const teamSelectedOptionInitialized = useRef(false);
  const dispatch = useAppDispatch();
  const { teamData, fetchTeamsAjaxStatus, teamIdList, metaData } =
    useAppSelector((state) => state.teamSettings);
  const [options, setOptions] = useState<any>([]);

  const totalTeams = useRef(metaData);

  useEffect(() => {
    if (teamData !== null) {
      let TeamOptions = Object.keys(teamData).map(function (key, index) {
        let team = teamData[parseInt(key)];
        return { label: team.teamName, value: team.teamId };
      });

      if (TeamOptions.length === 0) {
        return;
      }
      setOptions(TeamOptions);
      if (teamSelectedOptionInitialized.current === false && ruleId === "new") {
        setTeamSelectedOption(TeamOptions[0]);
        teamSelectedOptionInitialized.current = true;
      }
    }
  }, [teamData]);

  useEffect(() => {
    totalTeams.current = {
      count: metaData.count,
      total: metaData.total,
    };
  }, [metaData]);

  const formatOptionLabel = ({ value, label, customAbbreviation }: any) => {
    return (
      <div key={uuidV4()} className={`d-flex align-items-center`}>
        <img className={`${styles.genLogo}`} src={genLogo} alt="general" />
        <span className={`ms-1 ${styles.selectSpan}`}>{label}</span>{" "}
      </div>
    );
  };

  /* Handle infinite scroll */
  const handleInfiniteScroll = useCallback(() => {
    if (fetchTeamsAjaxStatus === "fulfilled") {
      if (totalTeams.current.total !== null) {
        if (
          totalTeams.current.total > 0 &&
          teamIdList.length < metaData.total
        ) {
          dispatch(fetchAllTeams());
        }
      }
    }
  }, []);

  useEffect(() => {
    dispatch(resetTeamsData());
    if (ruleId === "new") {
      dispatch(fetchAllTeams());
    }
  }, [false]);

  const handleSelectedValue = (e: any) => {
    setTeamSelectedOption({
      value: e.value,
      label: e.label,
    });
  };

  const handleLoadInitialTeam = () => {
    if (initialized.current === false && ruleId !== "new") {
      dispatch(resetTeamsData());
      dispatch(fetchAllTeams());
      initialized.current = true;
    }
  };

  return (
    <div>
      <div className={`d-flex align-items-center mb-3 ${styles.allignDiv}`}>
        <span className={`${styles.action}`}>Then do the following action</span>
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip className={` ${styles.toolTipCustom}`}>
              Decide whether you want to route chats to a particular group or
              turn the chat widget off when the conditions are met.
            </Tooltip>
          }
        >
          {({ ref, ...triggerHandler }) => (
            <div {...triggerHandler} ref={ref}>
              <img
                className={`cursor-pointer ${styles.moreInfo}`}
                src={moreInfo}
                alt={``}
              />
            </div>
          )}
        </OverlayTrigger>
      </div>
      <div
        className={`mb-3 py-2 px-2 px-lg-3 ${styles.choseBox} ${
          ruleAction === "routeToGroup" ? styles.activeDiv : ""
        } `}
      >
        <div className={`d-flex flex-wrap align-items-center mb-2`}>
          <div className="form-check">
            <input
              className="form-check-input"
              data-lpignore="true"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault2"
              checked={ruleAction === "routeToGroup"}
              onClick={(e) => setRuleAction("routeToGroup")}
            />
          </div>
          <h5 className={`mb-0 ${styles.radioName}`}>Route to group</h5>
          <div className={`py-1 py-md-0`}>
            <Select
              onFocus={handleLoadInitialTeam}
              onChange={handleSelectedValue}
              onMenuScrollToBottom={handleInfiniteScroll}
              options={options}
              formatOptionLabel={formatOptionLabel}
              className={`ms-0 ms-lg-3 ${styles.selItem}`}
              value={teamSelectedOption}
            />
          </div>
        </div>
        <div>
          <p className={`mb-0 ${styles.choseBoxPara}`}>
            Customers will start a chat with agents from the group you have
            picked
          </p>
          <NavLink to="/">
            <span className={`${styles.manageGroup}`}>Manage groups</span>
          </NavLink>
        </div>
      </div>
      <div
        className={`py-2 px-2 px-lg-3 ${styles.choseBox} mb-3   ${
          ruleAction === "turnChatOff" ? styles.activeDiv : ""
        }`}
      >
        <div className={`d-flex align-items-center mb-2`}>
          <div className="form-check">
            <input
              className="form-check-input"
              data-lpignore="true"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault2"
              checked={ruleAction === "turnChatOff"}
              onClick={(e) => setRuleAction("turnChatOff")}
            />
          </div>
          <h5 className={`${styles.radioName}`}>Turn chat off</h5>
        </div>
        <div>
          <p className={`${styles.choseBoxPara}`}>
            Customers won't see a chat widget at all, and you won't be able to
            see them in the Traffic section.
          </p>
        </div>
      </div>
    </div>
  );
};
export default RouteCondition;
