import { axiosJSON } from "src/globals/axiosEndPoints";

export interface UserLanguage{
  id: string | number;
  name: string;
}
export interface UserLanguageList {
  languages : {[id: number | string] : UserLanguage},
  languageIds : Array<number | string>,
}

export async function getAllLanguages() {
  const { data: res } = await axiosJSON.post(
    `/api/chatSetting/getLanguageOptions`
  );
  if (res.err) {
    throw res.msg;
  }
  const languageIds: any = [];
  const languages: any = {};
  res.data.forEach((language: UserLanguage) => {
    languages[language.id] = language;
    languageIds.push(language.id);
  });
  return { languages, languageIds } as UserLanguageList;
}
