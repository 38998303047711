import styles from "./BrandModal.module.scss";
import edit from "src/assets/images/chat/editBrand.png";
import menuDown from "src/assets/images/chat/menuDown.png";
import plus from "src/assets/images/chat/plus.png";
import {
  Modal,
  OverlayTrigger,
  Placeholder,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import Select from "react-select";
import SearchBar from "../SearchBar/SearchBar";
import SendMessage from "src/routes/Ticket/children/MainChat/children/SendMessage";
import SetAgent from "./Children/SetAgent/SetAgent";
import { BrandData } from "src/services/Settings/Brands/getAllBrands";
import { BrandAgent } from "src/services/Settings/Brands/getBrand";
import { useCallback, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  addBrand,
  fetchAllBrands,
  getAllBrandAgents,
  getAllOutboundEmails,
  getBrand,
  removeAllUsersFromBulkSelect,
  resetBrandAgentList,
  resetBrandData,
  resetBrandGeneralUserList,
  resetModifiedUsersList,
  resetOutboundEmailList,
  setActiveBrandId,
  setBrandOutboundEmailSearchValue,
  setBrandUserCurrentPage,
  setBrandUserSearchValue,
  updateBrand,
} from "src/store/slices/settings/brands/brandSettings.slice";
import { User } from "src/services/Settings/Users/getAllUser";
import BrandUserBulkAction from "./Children/BrandUserBulkActionSelection/BrandUserBulkAction";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import {
  replaceLabelWithPlaceholder,
  replacePlaceHolderWithLabel,
  selectFile,
  validateEmail,
} from "src/utils/utils";
import UserAvatar from "../UserAvatar";
import AxiosImg from "../AxiosImg";
import ReactSelect from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import InfiniteScroll from "../InfiniteScroll";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { BrandSignaturePlaceholder } from "src/services/Settings/Brands/getBrandSignaturePlaceholders";
import ParseHTML from "../ParseHTML";
import { getBrandAndSignatureThunk } from "src/store/slices/globals/globals.slice";
import AddBrandPop from "./Children/AddBrandPop";
import TinyMCEContainer from "../TinyMCEContainer";
import { FreePlanError, FreePlanErrorMessage } from "src/globals/constants";

interface Props {
  onHide: (e: any) => void;
  brandData: BrandData;
  isAdd?: boolean;
}

export type BrandReAssignUser = {
  id: number;
  name: string;
  imageUrl: string;
};

const BrandModal = ({ isAdd = false, onHide, brandData }: Props) => {
  const {
    getBrandAjaxStatus,
    getAllBrandAgentAjaxStatus,
    userIds,
    users,
    usersMeta,
    modifiedUsersList,
    bulkSelectedUserIds,
    outboundMailsIds,
    outboundMails,
    allOutboundEmailAjaxStatus,
    outboundMailsMeta,
    brandPlaceholderOptions,
    addBrandAjaxStatus,
    updateBrandAjaxStatus,
  } = useAppSelector((state) => state.brandSettings);

  const placeholderDatasetId = "placeholder-1";
  const dispatch = useAppDispatch();
  const [brandName, setBrandName] = useState(brandData.name ?? "");
  const [brandSignature, setBrandSignature] = useState(brandData.signature);
  const [brandEmail, setBrandEmail] = useState(brandData.email);
  const [searchValue, setSearchValue] = useState("");
  const [brandImage, setBrandImage] = useState<File | null>(null);
  const [brandImageUrl, setBrandImageUrl] = useState({
    isAxios: true,
    imageUrl: brandData.imageURL,
  });
  const [brandNameError, setBrandNameError] = useState("");
  const [brandEmailError, setBrandEmailError] = useState("");
  const [outboundEmailSearch, setOutboundEmailSearch] = useState("");
  const [showSearchText, setShowSearchText] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const targetRef = useRef(null as any);
  const [showAddBrand, setShowAddBrand] = useState(false);

  const scopes = useAppSelector(
    (state) => state.globals.currentUserData?.scopes,
  );
  const insertPlaceholderRef = useRef(null as any);

  const currentState = useRef({
    currentPage: usersMeta.userCurrentPage,
    allOutboundEmailAjaxStatus: allOutboundEmailAjaxStatus,
  });

  const initialized = useRef(false);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(resetBrandGeneralUserList());
    dispatch(setBrandUserSearchValue({ searchValue: "" }));
    dispatch(resetBrandAgentList());
    dispatch(removeAllUsersFromBulkSelect());
    dispatch(resetModifiedUsersList());
    dispatch(
      getAllBrandAgents({ brandId: isAdd === true ? null : brandData.id }),
    );
    dispatch(setActiveBrandId(brandData.id));
    dispatch(resetOutboundEmailList());
    dispatch(getAllOutboundEmails({}));
    dispatch(setBrandOutboundEmailSearchValue({ searchValue: "" }));

    initialized.current = true;
  }, [brandData.id]);

  useEffect(() => {
    currentState.current.currentPage = usersMeta.userCurrentPage;
    currentState.current.allOutboundEmailAjaxStatus =
      allOutboundEmailAjaxStatus;
  }, [usersMeta, allOutboundEmailAjaxStatus]);

  const dispatchCurrentPage = useCallback((currentPage: any) => {
    if (currentPage !== currentState.current.currentPage) {
      dispatch(setBrandUserCurrentPage(currentPage));
      dispatch(
        getAllBrandAgents({ brandId: isAdd === true ? null : brandData.id }),
      );
    }
  }, []);

  const handleSearch = useCallback((value: any, callback: any) => {
    if (initialized.current) {
      dispatch(resetBrandGeneralUserList());
      dispatch(setBrandUserSearchValue({ searchValue: value.trim() }));
      dispatch(
        getAllBrandAgents({ brandId: isAdd === true ? null : brandData.id }),
      );
    }
  }, []);

  const validateBrandPayload = () => {
    if (brandName.trim().length === 0) {
      pushTheToast({
        type: "warning",
        text: "Brand Name is required!",
        position: "top-right",
      });
      setBrandNameError("* Brand name is required");
      return false;
    }
    if (!validateEmail(brandEmail)) {
      pushTheToast({
        type: "warning",
        text: "Please enter a valid email address!",
        position: "top-right",
      });
      setBrandEmailError("* Please enter a valid email address");
      return false;
    }

    return true;
  };

  const pushErrorNotification = (statusCode: string) => {
    if (statusCode === "profileImageFailed") {
      pushTheToast({
        type: "danger",
        text: "Failed to upload brand profile image!",
        position: "top-right",
      });
    } else if (statusCode === "brandEmailAlreadyExists") {
      setBrandEmailError("* Brand email is already exists");
      pushTheToast({
        type: "danger",
        text: "Brand with this email address already exists!",
        position: "top-right",
      });
    } else if (statusCode === "brandNameAlreadyExists") {
      setBrandNameError("* Brand name is already exists");
      pushTheToast({
        type: "danger",
        text: "Brand name is already exists!",
        position: "top-right",
      });
    } else if (
      statusCode === FreePlanError.LIMIT_REACHED ||
      statusCode === FreePlanError.RESTRICTED
    ) {
      const code = statusCode as keyof typeof FreePlanErrorMessage;
      pushTheToast({
        text: FreePlanErrorMessage[code],
        type: "danger",
        position: "top-right",
      });
    } else {
      pushTheToast({
        type: "danger",
        text: "Something went wrong!",
        position: "top-right",
      });
    }
  };

  const handleSubmit = (e: any) => {
    if (scopes && scopes.includes("settings_can_edit_email_signature")) {
      if (!validateBrandPayload()) {
        return false;
      }
      const disableUsers: any = Object.values(modifiedUsersList).map((user) => {
        return {
          userId: user.id,
          enabled: user.enabled,
          assignTo: user.assignTo,
        };
      });
      const brandSignatureParsed = replaceLabelWithPlaceholder(
        brandSignature,
        brandPlaceholderOptions,
      );

      if (isAdd === true) {
        dispatch(
          addBrand({
            brandName: brandName,
            brandEmail: brandEmail,
            brandSignature: brandSignatureParsed,
            disabledUser: disableUsers,
            profileImage: brandImage ?? undefined,
            callback: (statusCode: string) => {
              if (statusCode.trim().length !== 0) {
                pushErrorNotification(statusCode);
              } else {
                pushTheToast({
                  type: "success",
                  text: "Brand successfully added!",
                  position: "top-right",
                });
                dispatch(resetBrandGeneralUserList());
                dispatch(resetBrandAgentList());
                dispatch(removeAllUsersFromBulkSelect());
                dispatch(resetModifiedUsersList());
                dispatch(getAllOutboundEmails({}));
                dispatch(resetBrandData());
                dispatch(fetchAllBrands());
                dispatch(resetOutboundEmailList());
                dispatch(setBrandOutboundEmailSearchValue({ searchValue: "" }));
                onHide(e);
              }
            },
          }),
        );
      } else {
        dispatch(
          updateBrand({
            id: brandData.id,
            brandName: brandName,
            // brandEmail: brandEmail,
            brandSignature: brandSignatureParsed,
            disabledUser: disableUsers,
            profileImage: brandImage ?? undefined,
            deleteProfile: brandImageUrl.imageUrl === null ? true : undefined,
            callback: (statusCode: string) => {
              if (statusCode.trim().length !== 0) {
                pushErrorNotification(statusCode);
              } else {
                pushTheToast({
                  type: "success",
                  text: "Brand successfully updated!",
                  position: "top-right",
                });
                //to update the global signature
                dispatch(getBrandAndSignatureThunk());
                onHide(e);
              }
            },
          }),
        );
      }
    }
  };

  const handleImage = async (e: any) => {
    e.persist();
    if (scopes && scopes.includes("settings_can_edit_email_signature")) {
      let image = (await selectFile(["jpeg", "jpg", "png"]))[0];

      if (image) {
        setBrandImage(image);
        setBrandImageUrl({
          isAxios: false,
          imageUrl: URL.createObjectURL(image),
        });
      }
    }
  };

  const handleRemoveImage = () => {
    if (scopes && scopes.includes("settings_can_edit_email_signature")) {
      setBrandImage(null);
      setBrandImageUrl({ isAxios: true, imageUrl: null });
    }
  };

  function getUsersList() {
    const userList = userIds.map((userId) => {
      const user = users[userId];
      const temp = { ...user };
      if (modifiedUsersList[user.id]) {
        temp.enabled = modifiedUsersList[user.id].enabled;
      }

      return temp;
    });

    return userList;
  }

  const handleInfiniteScroll = useCallback(() => {
    if (currentState.current.allOutboundEmailAjaxStatus === "fulfilled") {
      dispatch(getAllOutboundEmails({}));
    }
  }, []);

  const handleOutboundSearch = useCallback((value: any, callback: any) => {
    if (initialized.current) {
      dispatch(resetOutboundEmailList());
      dispatch(setBrandOutboundEmailSearchValue({ searchValue: value.trim() }));
      dispatch(getAllOutboundEmails({}));
    }
  }, []);

  const handleAddVariableOnClick = (placeholder: BrandSignaturePlaceholder) => {
    // setBrandSignature(brandSignature+placeholder.placeholder);
    if (insertPlaceholderRef.current.addMentionRef.current.addMention) {
      insertPlaceholderRef.current.addMentionRef.current.addMention({
        id: placeholder.id,
        name: "{" + placeholder.label + "}",
      });
    }
  };

  const onClickWindow = useCallback((e: any) => {
    if (!targetRef.current?.contains(e.target)) {
      setShowDropDown(false);
      setOutboundEmailSearch("");
    }
  }, []);

  useEffect(() => {
    window.addEventListener("click", onClickWindow);
    return () => {
      window.removeEventListener("click", onClickWindow);
    };
  }, [onClickWindow]);

  const dropDownElements = (
    <>
      <img
        src={menuDown}
        alt="down"
        className={`${styles.downArrow}`}
      />
      <ul
        className={`dropdown-menu p-2 ${styles.dropBox} ${
          showDropDown ? "show" : ""
        }`}
      >
        <li className={`mb-2`}>
          <SearchBar
            onFocus={() => {
              setShowSearchText(true);
            }}
            onBlur={() => setShowSearchText(false)}
            className={`${styles.search} px-2`}
            inputClassName={`${styles.input}`}
            placeholder={`Search email`}
            value={outboundEmailSearch}
            onChange={(e: any) => setOutboundEmailSearch(e.target.value)}
            onSearch={handleOutboundSearch}
          />
        </li>
        <InfiniteScroll
          className={`${styles.outboundDropdownInfiScroll}`}
          loadMore={handleInfiniteScroll}
          initialDataLoaded={true}
          loader={
            <Spinner
              animation="border"
              variant="secondary"
              className={`d-block`}
            />
          }
          hasMore={
            outboundMailsIds.length < outboundMailsMeta.total ||
            outboundMailsMeta.total === 0
          }
          errorMessage={
            outboundMailsMeta.total === 0 &&
            (allOutboundEmailAjaxStatus === "rejected" ||
              allOutboundEmailAjaxStatus === "fulfilled") ? (
              <>
                <li>
                  {validateEmail(outboundEmailSearch?.trim()) ? (
                    <div
                      className={`my-1 d-flex aling-items-center ${styles.addBox}`}
                      role={"button"}
                      onClick={(e: any) => {
                        // navigate("/settings/integrations/email");
                        setBrandEmail(outboundEmailSearch?.trim() ?? "");
                        setShowDropDown(false);
                        setShowAddBrand(true);
                      }}
                    >
                      <img
                        src={plus}
                        alt=""
                        className={`me-1 cursor-pointer ${styles.addEmail}`}
                      />
                      <span className={`${styles.addSpan}`}>
                        Integrate new outbound email
                      </span>
                    </div>
                  ) : (
                    <span
                      className={`dropdown-item py-2 ${styles.mailItem}`}
                      onClick={() => {}}
                    >
                      No result found
                    </span>
                  )}
                </li>
                {/* 01 Dec 22  hiding the "Integrate new outbound email" option from the outbound email list ..cause the email integration is not fully completed */}
                {/* <li>
                              <div
                                className={`my-1 d-flex aling-items-center ${styles.addBox}`}
                                role={"button"}
                                onClick={(e)=>{
                                  navigate("/settings/integrations/email");
                                }}
                              >
                                <img
                                  src={plus}
                                  alt=""
                                  className={`me-1 cursor-pointer ${styles.addEmail}`}
                                />
                                <span className={`${styles.addSpan}`}>
                                  Integrate new outbound email
                                </span>
                              </div>
                            </li>  */}
              </>
            ) : undefined
          }
        >
          {outboundMailsIds.map((outboundId) => {
            const outboundEmail = outboundMails[outboundId];

            return (
              <li
                key={outboundId}
                onClick={() => {
                  setBrandEmail(outboundEmail.emailAddress);
                  setShowDropDown(false);
                  setOutboundEmailSearch("");
                }}
              >
                <span className={`dropdown-item py-2 ${styles.mailItem}`}>
                  {outboundEmail.emailAddress}
                </span>
              </li>
            );
          })}
          {/* 01 Dec 22  hiding the "Integrate new outbound email" option from the outbound email list ..cause the email integration is not fully completed */}
          {/* <li>
                          <div
                            className={`my-1 d-flex aling-items-center ${styles.addBox}`}
                            role={"button"}
                            onClick={(e)=>{
                              navigate("/settings/integrations/email");
                            }}
                          >
                            <img
                              src={plus}
                              alt=""
                              className={`me-1 cursor-pointer ${styles.addEmail}`}
                            />
                            <span className={`${styles.addSpan}`}>
                              Integrate new outbound email
                            </span>
                          </div>
                        </li>  */}
          {/* <li>
                          <a
                            className={`dropdown-item ${styles.mailItem}`}
                            href="#"
                          >
                            Contact@helplama.com
                          </a>
                        </li>
                        <li>
                          <a
                            className={`dropdown-item ${styles.mailItem}`}
                            href="#"
                          >
                            Admin@helplama.com
                          </a>
                        </li>
                        <li>
                          <div
                            className={`my-1 d-flex aling-items-center ${styles.addBox}`}
                          >
                            <img
                              src={plus}
                              alt=""
                              className={`me-1 cursor-pointer ${styles.addEmail}`}
                            />
                            <span className={`${styles.addSpan}`}>
                              Integrate new outbound email
                            </span>
                          </div>
                        </li> */}
        </InfiniteScroll>
      </ul>
      {showAddBrand && (
        <AddBrandPop
          brandEmail={brandEmail}
          brandName={brandName}
          setBrandData={({ email, name, show, success }: any) => {
            if (success) {
              setBrandEmail(email);
              setBrandName(name);
            } else {
              setBrandEmail(brandData.email ?? "");
              setBrandName(brandData.name ?? "");
            }
            setShowAddBrand(show);
          }}
        />
      )}
    </>
  );
  return (
    <>
      <div className="container h-100">
        <div className="row h-100">
          <div className="col-12 h-100">
            <div className="px-3 pb-3 d-flex flex-column h-100">
              <div
                className={`d-flex justify-content-between pt-3 ${styles.fixHeader}`}
              >
                <div>
                  <h4 className={`${styles.heading}`}>
                    {isAdd === true ? "Add Brand :" : "Edit Brand:"}
                  </h4>
                </div>
                <div>
                  <span
                    className="cursor-pointer"
                    onClick={onHide}
                  >
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip className={` ${styles.toolTipCustom}`}>
                          Close
                        </Tooltip>
                      }
                    >
                      {({ ref, ...triggerHandler }) => (
                        <div
                          {...triggerHandler}
                          ref={ref}
                        >
                          <i className="fa-solid fa-xmark"></i>
                        </div>
                      )}
                    </OverlayTrigger>
                  </span>
                </div>
              </div>
              <div className={`${styles.contentWrapper}`}>
                <div className={`text-center mb-3`}>
                  <div
                    className={`d-inline-block position-relative ${
                      styles.editBrand
                    } ${
                      brandImageUrl.imageUrl === null
                        ? styles.uploadBrand
                        : styles.editBrand
                    } `}
                  >
                    {/* <img
                    src={logo}
                    className={`${styles.brandLogo}`}
                    alt="logo"
                    onClick={handleImage}
                  /> */}
                    {brandImageUrl.imageUrl === null ? (
                      <div
                        className={`d-flex text-center justify-content-center align-items-center  ${styles.uploadPhoto1}`}
                      >
                        <span
                          className={`px-2 ${styles.uploadText}`}
                          role={"button"}
                          onClick={handleImage}
                        >
                          Upload Logo
                        </span>
                      </div>
                    ) : brandImageUrl.isAxios ? (
                      <AxiosImg
                        className={`${styles.uploadPhoto} rounded-circle`}
                        url={brandImageUrl.imageUrl}
                      />
                    ) : (
                      <img
                        src={brandImageUrl.imageUrl}
                        className={`${styles.uploadPhoto}`}
                        alt="logo"
                      />
                    )}

                    {brandImageUrl.imageUrl !== null && (
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip className={` ${styles.toolTipCustom}`}>
                            Remove
                          </Tooltip>
                        }
                      >
                        {({ ref, ...triggerHandler }) => (
                          <img
                            {...triggerHandler}
                            ref={ref}
                            src={edit}
                            alt="close"
                            className={`cursor-pointer ${styles.closeBtn}`}
                            onClick={handleRemoveImage}
                          />
                        )}
                      </OverlayTrigger>
                    )}
                  </div>
                </div>
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <label
                        htmlFor="bName"
                        className={`form-label ${styles.labelName}`}
                      >
                        Brand Name<span className={`${styles.req}`}>*</span>
                      </label>

                      <input
                        data-lpignore="true"
                        type="text"
                        className={`form-control py-2 ${styles.inputBox} ${
                          brandNameError.length !== 0
                            ? `${styles.redBorder}`
                            : ""
                        }`}
                        placeholder="Brand"
                        aria-label="Brand Name"
                        id="bName"
                        value={brandName}
                        disabled={
                          (scopes &&
                            scopes.includes(
                              "settings_can_edit_email_signature",
                            )) === true
                            ? false
                            : true
                        }
                        onChange={(e) => {
                          setBrandNameError("");
                          setBrandName(e.target.value);
                        }}
                        required
                      />
                      <span
                        className={`d-inline-block pt-2 ${styles.errorMsg}`}
                      >
                        {brandNameError}
                      </span>
                    </div>
                    <div className="col-12 col-md-6">
                      <label
                        htmlFor="email"
                        className={`form-label ${styles.labelName}`}
                      >
                        Outbound Email
                        <span className={`${styles.req}`}>*</span>
                      </label>
                      <div className={`position-relative`}>
                        <div
                          className={`position-relative`}
                          ref={targetRef}
                        >
                          {isAdd ? (
                            <input
                              data-lpignore="true"
                              type="email"
                              className={`form-control mb-1 py-2 dropdown-toggle ${
                                styles.inputBox
                              } ${
                                brandEmailError.length !== 0
                                  ? `${styles.redBorder}`
                                  : ""
                              }`}
                              placeholder="support@brand.com"
                              aria-label="email"
                              id={`email`}
                              // onFocus={() => {
                              //   setShowSearchText(true);
                              //   setShowDropDown(true);
                              // }}
                              // onBlur={() => setShowSearchText(false)}
                              value={
                                // showSearchText === true
                                //   ? outboundEmailSearch:
                                brandEmail
                              }
                              onChange={(e) =>
                                // setOutboundEmailSearch(e.target.value)
                                setBrandEmail(e.target.value)
                              }
                              disabled={
                                brandData.isDefault ||
                                ((scopes &&
                                  scopes.includes(
                                    "settings_can_edit_email_signature",
                                  )) === true
                                  ? false
                                  : true) ||
                                !isAdd
                              }
                              autoComplete="off"
                              aria-expanded="false"
                              required
                            />
                          ) : (
                            <div
                              className={`form-control mb-1 py-2 ${
                                styles.inputBox
                              } ${styles.disabled} ${
                                brandEmailError.length !== 0
                                  ? `${styles.redBorder}`
                                  : ""
                              }`}
                            >
                              {brandEmail}
                            </div>
                          )}

                          {/* {dropDownElements} */}
                        </div>
                        <span
                          className={`d-inline-block pt-2 ${styles.errorMsg}`}
                        >
                          {brandEmailError}
                        </span>
                      </div>

                      {/* <Select options={options} /> */}
                    </div>
                    {/* <div className="col-12 mt-4">
                      <label
                        htmlFor="email"
                        className={`form-label ${styles.labelName}`}
                      >
                        Agent Signature
                      </label>
                      {(scopes &&
                        scopes.includes(
                          "settings_can_edit_email_signature"
                        )) === true ? (
                        <TinyMCEContainer
                          // key={uuid()}
                          value={replacePlaceHolderWithLabel(
                            brandSignature,
                            brandPlaceholderOptions
                          )}
                          // value={""}
                          onChange={(value: string) => {
                            // let newValue = validatePlacholderText(value);
                            setBrandSignature(value);
                          }}
                          uniqueID={""}
                          className={`${styles.signBox}`}
                          options={{
                            placeholder: "Email Signature Here",
                            max_height: 200
                            // readOnly: (isCurrentTicketMergedWithDifferent === true)
                          }}
                          additionalOptions={brandPlaceholderOptions.map(
                            (placeholder) => {
                              return {
                                label: placeholder.label,
                                onClick: (e) => {
                                  e.preventDefault();
                                  handleAddVariableOnClick(placeholder);
                                },
                                hide: false,
                              };
                            }
                          )}
                          getMentionRef={(data) => {
                            insertPlaceholderRef.current = data;
                          }}
                        />
                      ) : (
                        <ParseHTML
                          html={replacePlaceHolderWithLabel(
                            brandSignature,
                            brandPlaceholderOptions
                          )}
                          className={`${styles.editorBox} ${styles.editor} p-2`}
                          style={{ overflow: "auto" }}
                        />
                      )}
                    </div> */}
                  </div>
                  <div>
                    <SetAgent
                      searchValue={searchValue}
                      setSearchValue={(value) => setSearchValue(value)}
                      handleSearch={handleSearch}
                      totalPages={usersMeta.totalPages}
                      currentPage={usersMeta.userCurrentPage}
                      setCurrentPage={dispatchCurrentPage}
                      userAjaxStatus={getAllBrandAgentAjaxStatus}
                      users={getUsersList()}
                      onSubmit={(e: any) => handleSubmit(e)}
                      updateBrandAjaxStatus={
                        isAdd === true
                          ? addBrandAjaxStatus
                          : updateBrandAjaxStatus
                      }
                    />
                  </div>
                </form>
              </div>
              <div className={`my-2`}>
                <div>
                  {bulkSelectedUserIds.length > 0 && (
                    <div className={`mb-2 ${styles.fixFooter}`}>
                      <BrandUserBulkAction />
                    </div>
                  )}
                </div>
                <div className={`d-flex justify-content-end`}>
                  {scopes &&
                  scopes.includes("settings_can_edit_email_signature") ? (
                    <button
                      className={`border-0 py-2 px-3  ${styles.createBtn}`}
                      onClick={(e) => {
                        if (updateBrandAjaxStatus !== "pending") {
                          handleSubmit(e);
                        }
                      }}
                    >
                      {updateBrandAjaxStatus === "pending" ? (
                        <Spinner
                          className="my-auto mx-1"
                          animation="border"
                          color="white"
                          size="sm"
                        />
                      ) : (
                        "Save changes"
                      )}
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default BrandModal;
