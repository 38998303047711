import { axiosJSON } from "src/globals/axiosEndPoints";
import { Comment } from "./getBugById.service";

interface AgentDetails {
  agentId: number;
  agentName: string;
  agentProfileImgUrl: string;
}

interface AttachmentDetails {
  attachmentId: number;
  attachmentBatchNumber: string;
  attachmentName: string;
  attachmentSize: number;
  attachmentType: string;
  attachmentUrl: string;
  isPublicAttachmentUrl: boolean;
}

export interface BugCommentData {
  bugCommentId: number;
  commentMessage: string;
  agentDetails: AgentDetails;
  createdAtGmt: string;
  attachmentDetails: AttachmentDetails[];
}


const postAgentComment = async (param: Comment) => {
  const { data: res } = await axiosJSON.post(
    `/api/selfService/bugManagement/createCommentByAgent`,
    param,
  );

  if (res.err || res.error) {
    throw res.msg ?? res.message ?? "Something went wrong!";
  }

  let ret: BugCommentData;

  ret = res.data;

  return ret;
};

export default postAgentComment;
