import { getMonthNameDateAmPmTime } from "src/utils/dateLibrary";

interface IToolTipModule {
  ticketBtnName: string;
  DDData: Array<{
    key: string;
    value: string;
  }>;
  isFilterData?: boolean;
}

/**
 * Formats a date string based on the specified input format.
 *
 * @param input - The input string in the format "date type::date or null".
 * @returns A formatted date string with the date included if not "null", based on the input.
 */
const formatDate = (input: string) => {
  // Split the input string into date type and date/null
  const [dateType, dateValue] = input.split("::");
  // Check if the dateValue is "null"
  const isNull = dateValue.trim().toLowerCase() === "null";

  // Format the date type by capitalizing the first letter and replacing dashes with spaces
  const formattedDateType =
    dateType.charAt(0).toUpperCase() + dateType.slice(1).replace(/-/g, " ");

  // If dateValue is "null", return the formatted date type without including the date
  if (isNull) {
    return formattedDateType;
  }

  // If the dateType is "between," handle the start and end dates separately
  if (dateType.toLowerCase() === "between") {
    const [startDate, endDate] = dateValue.split(" to ");
    return `${formattedDateType}: ${getMonthNameDateAmPmTime(
      startDate,
      true
    )} to ${getMonthNameDateAmPmTime(endDate, true)}`;
  }

  // For other date types, format the date and include it in the result
  return `${formattedDateType}: ${getMonthNameDateAmPmTime(dateValue, true)}`;
};

/**
 * Transforms filter data based on the specified key and value.
 *
 * @param key - The filter key.
 * @param value - The filter value.
 * @returns Transformed filter data with standardized key and optional formatted value.
 */
const getFilterData = ({ key, value }: { key: string; value: string }) => {
  switch (key) {
    case "viewTBrand":
      return { key: "Brand", value };
    case "viewTChannel":
      return { key: "Channel", value };
    case "viewTTags":
      return { key: "Tags", value };
    case "viewTDraftStatus":
      return { key: "Draft Status", value };
    case "viewTTicketStatus":
      return { key: "Ticket Status", value };
    case "viewTAssignedTo":
      return { key: "Assigned To", value };
    case "viewTCreatedOn":
      return { key: "Created On", value: formatDate(value) };
    case "viewTClosedOn":
      return { key: "Closed On", value: formatDate(value) };
    default:
      return { key, value };
  }
};

/**
 * Tooltip Component used in Sidebar hover effect
 */
function ToolTipModule({
  ticketBtnName,
  DDData,
  isFilterData = false,
}: IToolTipModule) {
  return (
    <div className={`all-tickets-tooltip`}>
      {/* <h2 className={`tooltip`}>{ticketBtnName}</h2> */}
      {DDData.map((data, key) => {
        let option = data;
        // If this is filter data, then format it accordingly
        if (isFilterData) {
          option = getFilterData(data);
        }
        return (
          <div
            key={key}
            className={`d-flex mb-1`}
            id={option.value.replace(" ", "_")}
          >
            <span className="mx-1 tooltip-data-key">{option.key}:</span>
            <span>{option.value}</span>
          </div>
        );
      })}
    </div>
  );
}
export default ToolTipModule;
