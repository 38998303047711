import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";

export interface refreshCustomQa {
  botProfileId: number | string;
  customQAId: number | string;
}

const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/customQa/refreshCustomQA`
  : "/api/botProfile/customQA/refresh";

export async function refreshCustomQA(params: refreshCustomQa) {
  const { data: res } = await axiosJSON.post(apiEndPoint, params);

  if (res.error) {
    throw new Error(res.message as string);
  }

  return res.data;
}
