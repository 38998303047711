/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import {  useNavigate, useParams } from "react-router-dom";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { IUpdateKnowledgeBasedParams, updateChatSetting } from "src/services/LiveChat/Settings/updateChatSettingData";
import { fetchKnowledgeBasedData } from "src/store/slices/liveChatSetting/chatWidgetSetting/knowledgeBased/knowledgeBasedSetting.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";

function useKnowledgeBased () {
  const { integrationId } = useParams();
  const pageIntegrationId = integrationId
    ? decodeURIComponent(integrationId)
    : "";

  const {
    knowledgeBasedData,
  } = useAppSelector((state) => state.knowledgeBasedSettings);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isEnabled, setIsEnabled] = useState<boolean>(false);
  const [showSaveLoader, setShowSaveLoader] = useState<boolean>(false);

  useEffect(() => {
    dispatch(fetchKnowledgeBasedData(pageIntegrationId));
  }, []);

  useEffect(() => {
    if (knowledgeBasedData !== null) {
      setIsEnabled(knowledgeBasedData.enabled);
    }
  }, [knowledgeBasedData]);

  const handleEnable = useCallback((event: any) => {
    if (showSaveLoader) {
      return;
    }
    let isChecked = event.target.checked;
    setShowSaveLoader(true);
    let payload: IUpdateKnowledgeBasedParams = {
      enabled: isChecked,
    };

    updateChatSetting({
      integrationId: pageIntegrationId,
      knowledgeBased: payload,
    })
      .then((res) => {
        setShowSaveLoader(false);
        setIsEnabled(isChecked);
        pushTheToast({
          type: "success",
          text:
            isChecked === true
              ? "Knowledge Base Enabled!"
              : "Knowledge Base Disabled!",
          position: "top-right",
        });
      })
      .catch((err) => {
        setShowSaveLoader(false);
        pushTheToast({
          type: "danger",
          text: "Something went wrong!",
          position: "top-right",
        });
      });
  }, []);


const handleNavigate = useCallback((navigateTo: string) => {
    navigate(
      `/live-chat/settings/${pageIntegrationId}/Knowledge/${navigateTo}`
    );
  }, [navigate, pageIntegrationId]);
  
  return {
    handleEnable,
    handleNavigate,
    isEnabled,
    showSaveLoader,
  }
}

export default useKnowledgeBased;