import { useId, useState } from "react";
import { Spinner } from "react-bootstrap";
import { validateEmail } from "src/utils/utils";
import {
  EmailForwardingErrors,
  WebhookQuestionErrors,
} from "src/features/ReturnAutoWorkFlow/ReturnAutoWorkFlow.types";
import saufterLogo from "src/assets/images/saufter-logo.svg";
import errorIcon from "src/assets/images/errorCircle.png";
import successImg from "src/assets/images/successImg.png";
import copyMail from "src/assets/images/copyMail.png";
import styles from "./VerificationModals.module.scss";

/**
 * Component to display the text with a copy button.
 * @param textToCopy - The text to copy.
 * @returns A JSX element displaying the text with a copy button.
 */
export const CopyText = ({
  textToCopy,
  copyButtonNormalText = "Copy",
  copyButtonCopiedText = "Copied!",
  truncate = false,
}: {
  textToCopy: string;
  copyButtonNormalText?: string;
  copyButtonCopiedText?: string;
  truncate?: boolean;
}) => {
  return (
    <div className={`position-relative ${styles.inputBox}`}>
      <div
        className={`${truncate ? "text-truncate " : ""}px-2 ${
          styles.emailText
        }`}
      >
        {textToCopy}
      </div>
      <div className={`${styles.copyBtn}`}>
        <button
          onClick={(e) => {
            window.navigator.clipboard.writeText(textToCopy);
            const span = e.currentTarget.querySelector("span");
            if (span) {
              span.innerText = copyButtonCopiedText;
              setTimeout(() => {
                span.innerText = copyButtonNormalText;
              }, 2000);
            }
          }}
        >
          <img
            src={copyMail}
            height={10}
          />
          <span className="ps-1">{copyButtonNormalText}</span>
        </button>
      </div>
    </div>
  );
};

/**
 * This is currently applicable only for email forwarding.
 * If any other specific question needs it. It needs to be updated.
 */
const Initiate = ({
  textToCopy,
  activeEmail,
  next,
  back,
}: {
  textToCopy: string;
  activeEmail: null | string;
  next: (email: string) => void;
  back: () => void;
}) => {
  const activeEmailEleId = useId();
  const [email, setEmail] = useState(activeEmail ?? "");
  const [showError, setShowError] = useState(false);

  return (
    <div>
      <h3 className={`mb-3 pb-1 ${styles.modalHead}`}>
        Initiate a confirmation email
      </h3>
      <p className={`${styles.modalSubHead}`}>
        If you have configured your e-commerce platform to send copies of order
        confirmation, fulfillment, and delivery emails to the following email
        address, then you might have to place a sample order to ensure we
        receive an email from your platform.
      </p>
      <div className="mb-2">
        <label
          htmlFor="mail"
          className={`${styles.label}`}
        >
          Email address you need to forward to
        </label>
        <CopyText
          copyButtonNormalText="Copy email"
          textToCopy={textToCopy}
        />
      </div>
      <div>
        <div className="mb-3">
          <label
            htmlFor={`forwardEmail--${activeEmailEleId}`}
            className={`form-label ${styles.label}`}
          >
            Email address we will be receiving emails from
          </label>
          <input
            type="email"
            className={`form-control ${styles.mailInput}${
              showError && !validateEmail(email) ? " border-danger" : ""
            }`}
            id={`forwardEmail--${activeEmailEleId}`}
            placeholder="Add email address here"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            disabled={activeEmail ? true : false}
          />
        </div>
      </div>
      <p className={`${styles.footerText}`}>
        If you are sure that the ecommerce platform has sent an email to
        Saufter, please click 'next' below so that we can verify the
        configuration.
      </p>
      <div className="d-flex justify-content-center">
        <button
          className={`px-1 ${styles.backBtn}`}
          onClick={back}
        >
          Back
        </button>
        <button
          className={`${styles.nextBtn}`}
          onClick={() => {
            if (validateEmail(email)) {
              next(email);
            } else {
              setShowError(true);
            }
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

const Loading = ({
  verifyingText = "Verifying the connection",
  subText,
}: {
  verifyingText?: string;
  subText: string;
}) => {
  return (
    <div>
      <div className={`${styles.title} mx-auto`}>{}</div>
      <div className="h-0 m-auto">
        <img
          src={saufterLogo} //render saufter logo for saufter domain
          className={`${styles.loaderBG} position-relative m-auto d-block`}
          style={{
            padding: "2px",
          }}
        />
      </div>

      <Spinner
        animation="border"
        className={`${styles.loader} d-block mx-auto my-5`}
        variant="primary"
      />

      <div className={`${styles.subtitle} mx-auto my-2`}>{subText}</div>
    </div>
  );
};

const Error = ({
  errors,
  retry,
  back,
}: {
  errors: EmailForwardingErrors | WebhookQuestionErrors;
  retry: () => void;
  back: () => void;
}) => {
  return (
    <div className="px-4">
      <div className={`${styles.topIcon} mb-3 mx-auto`}>
        <img
          src={errorIcon}
          alt="error"
          width={35}
          height={35}
        />
      </div>
      <div className={`${styles.title} mb-2 mx-auto`}>{errors.heading}</div>
      <div className={`${styles.subtitle} mx-auto mb-3`}>
        {errors.subHeading}
      </div>
      <div className="d-flex justify-content-center mb-2 px-2 pt-1">
        <button
          className={`px-2 me-2 ${styles.cancelBtn}`}
          onClick={back}
        >
          Back
        </button>
        <button
          className={`px-2 ${styles.checkBtn}`}
          onClick={() => {
            retry();
          }}
        >
          Retry
        </button>
      </div>
    </div>
  );
};

const Done = ({
  heading = "Email has been verified",
  subHeading,
  done,
}: {
  heading?: string;
  subHeading?: string;
  done: () => void;
}) => {
  return (
    <div className="px-4">
      <div className={`${styles.topIcon} mt-1 mb-3 mx-auto`}>
        <img
          src={successImg}
          className={`${styles.done}`}
          alt="success"
        />
      </div>
      <div className={`${styles.title} mx-auto mb-4 pb-3`}>{heading}</div>
      {subHeading?.trim() ? (
        <div className={`${styles.subtitle} mx-auto my-2`}>{subHeading}</div>
      ) : (
        ""
      )}
      <div className="d-flex justify-content-center px-2">
        <button
          className={`px-2 ${styles.checkBtn}`}
          id="successBtn"
          onClick={done}
        >
          Done
        </button>
      </div>
    </div>
  );
};

export default { Initiate, Loading, Error, Done };
