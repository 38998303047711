import { SetStateAction, useCallback, useState } from "react";
import styles from "./AvailableTask.module.scss";
import UserAvatar from "src/components/UserAvatar";
import Pagination from "src/routes/TicketList/children/Pagination/Pagination";
import useCernInstructionPagination from "./useCernInstructionPagination";

const AvailableTask = ({
  selectInstruction,
}: {
  selectInstruction: React.Dispatch<SetStateAction<string>>;
}) => {
  const [escalate, setEscalate] = useState(true);
  const { currentPage, totalPage, changePage, cernInstruction } =
    useCernInstructionPagination();

  const handleInstructionClick = useCallback(
    (instruction: { name: string; type: number }) => {
      const [cern, msg] = instruction.name.split(/ (.+)/);
      selectInstruction(`
              <span
                class="mceNonEditable"
                style="
                  color: #0b68bb;
                  background-color: #dbebf9;
                  border-radius: 7px;
                  font-size: 12px;
                  padding: 0px 4px;
                  outline: none;
                  cursor: default;
                  display: inline-block;
                "
                data-cern-mention-instruction-type="${instruction.type}";
              >
                ${cern}
            </span>&nbsp;${msg}
            `);
    },
    [currentPage],
  );

  return (
    <div className="d-flex mb-3 justify-content-end">
      <div className={`${styles.editBox}`}>
        <div className={`${styles.msgBox} ${escalate && "border-0"}`}>
          <h4 className={`px-3 ${styles.heading}`}>
            Here are the available tasks:
          </h4>
          {cernInstruction?.map((instruction) => (
            <div className="mb-1 cursor-pointer">
              <p
                className={`px-3 pb-1 ${styles.availTask}`}
                onClick={() => handleInstructionClick(instruction)}
              >
                {instruction.name}
              </p>
            </div>
          ))}
          {totalPage > 1 && (
            <div className="px-3">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPage}
                setCurrentPage={changePage}
              />
            </div>
          )}
        </div>
      </div>
      <div className={`ms-3 ${styles.visibility}`}>
        <UserAvatar
          name="NA"
          size={33}
        />
      </div>
    </div>
  );
};

export default AvailableTask;
