import React, { useState } from "react";
import styles from "./TemplateComp.module.scss";
import ParseHTML from "src/components/ParseHTML";
import { NavLink } from "react-router-dom";
interface Props {
  // heading: string;
  // info: string;
  // category: string;
  // ticket: Number;
  template: any;
  display: string;
  onTemplateSelect: any;
  setSelectedTemplateData: any;
}
const TemplateComp = ({
  /*heading, info, category, ticket*/
  template,
  display,
  setSelectedTemplateData = (template: any) => {},
  onTemplateSelect = (template: string) => {},
}: Props) => {
  const [show, setShow] = useState(true);
  return (
    <div
      className={`p-2 pb-1 mb-2 ${styles.templateWrapper}`}
      onMouseEnter={() => {
        setShow(true);
      }}
      onMouseLeave={() => {
        setShow(false);
      }}
      id={template.templateHeading.replace(" ", "_")}
    >
      <>
        <p className={`mb-0 ${styles.heading} ${show ? styles.colChange : ""}`}>
          {template.templateHeading.length ? template.templateHeading : ""}
        </p>
      </>

      <div className={`d-flex justify-content-between align-items-start`}>
        <p className={`mb-0 ${styles.info} ${show ? "" : styles.ellipsis}`}>
          {/* {template.template_body} */}
          <ParseHTML className={``} html={template.template_body} />
        </p>
        <div className="d-flex align-items-center">
          <button
            className={`btn me-2 ${styles.insertbtn}`}
            onClick={() => {
              setSelectedTemplateData(template);
              onTemplateSelect(template.template_body);
            }}
            id={`insertBtn_${template.templateHeading.replace(" ", "_")}`}
          >
            + Insert
          </button>
          <span className={`cursor-pointer ${show ? styles.colChange : ""}`}>
            {show ? (
              <i className="fa-solid fa-angle-up"></i>
            ) : (
              <i className="fa-solid fa-angle-down"></i>
            )}
          </span>
        </div>
      </div>
      {template.previouslyUsedTicketId ? (
        <>
          {" "}
          <div className="mt-1">
            <span className={`${styles.prevData}`}>
              Previously Used In{" "}
              <span className={`${styles.ticketNum}`}>
                <NavLink
                  target={"_blank"}
                  to={`/tickets/all/ticket-view/ticket/${template.previouslyUsedTicketId}`}
                >
                  Ticket {template.previouslyUsedTicketId}
                </NavLink>
              </span>
            </span>
          </div>
        </>
      ) : (
        <div className="mb-2"></div>
      )}
    </div>
  );
};

export default TemplateComp;
