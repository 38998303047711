import styles from "./SubSB.module.scss";
import "./SubSB.css";
import loadingGif from "src/assets/images/loading.gif";
import TicketBtnModule from "./children/TicketBtnModule/TicketBtnModule";
import { Fragment, useCallback, useEffect } from "react";
import { IDDList } from "src/apis/sideBar/read/getTicketSideBar.api";
import { useState } from "react";
import AllViewModal from "src/components/AllViewModal/AllViewModal";
import {
  fetchSideBarData,
  ITicketSideBarState,
  resetTicketSideBarAllData,
} from "src/store/slices/ticketSidebar/ticketSidebar.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import AddMention from "./children/AddMention/AddMention";
import CernTickets from "./children/CernTickets/CernTickets";

export interface IDropDownList {
  ddName: string;
  ddList: IDDList;
  ddSelectedIndex: number[]; //changed filter selected index type number to array of numbers, as per new design we can select more than one filter in sidebar dropdown
}

export interface ITicketBtnListItem {
  ticketBtnName: string;
  ticketBtnCount: number;
  ddOuterList: Array<IDropDownList>;
  keyName: string;
  ticketAppliedData?: string; // to save search query from url
  listViewFilters?: {
    key: string;
    value: string;
  }[];
}

export interface ITicketBtn {
  header: string;
  margin: 1 | 2 | 3 | 4 | 5;
  ticketBtnList: Array<ITicketBtnListItem>;
  paginate: boolean;
}

function SubSB() {
  const [showSubSB, setShowSubSB] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const currentUserData = useAppSelector(
    (state) => state.globals.currentUserData,
  );
  const onShow = () => {
    setShowModal(true);
    // console.log("show");
  };
  const onHide = () => {
    setShowModal(false);
    // console.log("hide");
  };
  // const dispatch = useAppDispatch();
  const {
    ticketBtnData,
    ajaxStatus,
    selectedBtn,
    updateSidebarTicketsCountAjaxStatus,
  }: ITicketSideBarState = useAppSelector((state) => state.ticketSideBar);
  const [showOverlay, setShowOveray] = useState(false);

  const handleToggleSubSideBar = useCallback(() => {
    setShowSubSB(!showSubSB);
  }, [showSubSB, setShowSubSB]);

  const getToggleContent = () => {
    return (
      <div
        className={`${styles.subSideBarCloseBtnDiv} ${
          showSubSB ? "" : "applyZ"
        }`}
        onClick={handleToggleSubSideBar}
      >
        <OverlayTrigger
          show={showOverlay}
          placement="top"
          overlay={
            <Tooltip className={`toolTipCustom`}>
              <span className={`tooltipHead`}>
                {showSubSB === true ? "Hide sidebar" : "Show sidebar"}
              </span>
            </Tooltip>
          }
        >
          {({ ref, ...triggerHandler }) => (
            <div
              {...triggerHandler}
              ref={ref}
              onMouseOver={() => {
                setShowOveray(true);
              }}
              onMouseOut={() => {
                setShowOveray(false);
              }}
            >
              <span
                className={`${styles.subSideBarCloseBtnToggleArrow} m-auto`}
              >
                {showSubSB === true ? (
                  <i className="fa-solid fa-chevron-left"></i>
                ) : (
                  <i className="fa-solid fa-chevron-right"></i>
                )}
              </span>
            </div>
          )}
        </OverlayTrigger>
      </div>
    );
  };

  return (
    <div className={`${styles.subSideToggle}`}>
      <div
        className={`${styles.sidenavContent} flex-column ${
          showSubSB ? "d-flex" : "d-none"
        }`}
      >
        {ajaxStatus === "pending" ? (
          <div className={` mb-auto ${styles.loadingSUBSB}`}>
            <img src={loadingGif} />
          </div>
        ) : (
          <>
            <div className={`mb-auto ${styles.ticketsContent}`}>
              {ticketBtnData.map((btnData, key) => {
                return (
                  <Fragment key={key}>
                    <TicketBtnModule
                      keyIndex={key}
                      header={btnData.header}
                      margin={btnData.margin}
                      ticketBtnList={btnData.ticketBtnList}
                      selectedBtn={selectedBtn}
                      paginate={btnData.paginate}
                    />
                    {key === 0 && <AddMention />}
                  </Fragment>
                );
              })}
            </div>
          </>
        )}
        {/* Jun 11 Show email bot related buttons only when it is in ui visibility */}
        {currentUserData?.ui_visibility?.includes("email_bot_enabled") && (
          <div>
            <CernTickets />
          </div>
        )}
        {currentUserData &&
          currentUserData.scopes &&
          currentUserData.scopes.includes("add_view") && (
            <>
              <div
                className={`${styles.add_view_section} border-top`}
                id="addView__Btn"
              >
                <span
                  className={`d-inline-flex align-items-center justify-content-center rounded-circle ${styles.add_view} `}
                  onClick={onShow}
                >
                  <svg
                    width={17}
                    height={17}
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 8H1M9 16V8V16ZM9 8V0V8ZM9 8H17H9Z"
                      stroke="black"
                      strokeWidth={2}
                      strokeLinecap="round"
                    />
                  </svg>
                </span>
                <span className={`${styles.add_text}`}>Add View</span>
              </div>
              <AllViewModal
                onHide={onHide}
                show={showModal}
              />
            </>
          )}

        {showSubSB === true && getToggleContent()}
      </div>

      {showSubSB === false && getToggleContent()}
    </div>
  );
}

export default SubSB;
