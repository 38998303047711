import styles from "./Segment.module.scss";
import { useParams } from "react-router-dom";
import { EAutomationType } from "src/routes/BotSettings/Children/AutomationList/AutomationList";

interface Props {
  heading?: string;
  headingColor?: string;
  subHeading?: string;
  stepNumber: number;
  stepDone: boolean;
  showStep: boolean;
  onShow?: (val: number) => void;
  children: React.ReactNode;
}

const Segment = ({
  heading,
  headingColor,
  subHeading,
  children,
  stepNumber,
  showStep,
  onShow = () => {},
  stepDone,
}: Props) => {
  const { type } = useParams();

  return (
    <div>
      <div className="d-flex position-relative mb-2">
        <div className={`d-flex flex-column align-items-center me-2`}>
          <span
            className={`${styles.count} ${stepDone && styles.countDone} ${
              type === EAutomationType.TICKET_ROUTING ? styles.countDone : ""
            } ${
              stepDone && type === EAutomationType.TICKET_ROUTING
                ? styles.done
                : ""
            } ${
              stepDone && type != EAutomationType.TICKET_ROUTING
                ? styles.countWithShadow
                : ""
            }`}
          >
            {stepDone ? (
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
            ) : (
              <span> {stepNumber}</span>
            )}
          </span>
          <div
            className={`${styles.statusBar} ${
              stepDone && styles.statusBarOne
            } ${
              stepDone && type === EAutomationType.TICKET_ROUTING ? "mt-2" : ""
            }`}
          ></div>
        </div>

        <div className={`p-3 w-100 ${styles.stepBox}`}>
          <div
            className="cursor-pointer"
            onClick={() => onShow(stepNumber - 1)}
          >
            <h6
              style={{ color: headingColor }}
              className={`mb-0 ${styles.stepHead} ${
                type === EAutomationType.TICKET_ROUTING && showStep
                  ? styles.activeStepHead
                  : ""
              }`}
            >
              {heading}{" "}
            </h6>
            <span className={`${styles.stepSpan}`}>{subHeading}</span>
          </div>
          {showStep && children}
        </div>
      </div>
    </div>
  );
};

export default Segment;
