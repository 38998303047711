import { axiosJSON } from "src/globals/axiosEndPoints";
import { ISearchCustomerData } from "src/store/slices/innerTicket/innerTicket.slice";

export interface SearchCustomersRes{
  customersData: {
    [customerId: number|string] : ISearchCustomerData
  }
  customersIdList: Array<number | string>;
  metaData:{
    count: number;
    totalCount: number;
  }
}


export async function searchCustomers(payload: { searchTerm: string, start: number, limit: number }) {
  const { data } = await axiosJSON.post(`/api/customer/search`, payload);
  if (data.err === true) {
    throw new Error(data.msg as string)
  }

  const ret: SearchCustomersRes = {
    customersData: {},
    customersIdList:[],
    metaData: { count: data.metaData.count, totalCount: data.metaData.totalCount },
  };

  data.data.forEach((customer: ISearchCustomerData) => {
    ret.customersIdList.push(customer.id);
    ret.customersData[customer.id] = customer;
  });

  return ret;
}
