import { useEffect, useState } from "react";
import { getSingleBrandData } from "src/store/slices/globals/globals.slice";
import { useAppSelector } from "src/store/store";
import { convertTOSignature } from "../TinyMCEContainer/useEmailSignatureHandler";

// the hook isused to select the brand signature based on thebrand selected
export const useBrandSignature = (fromValue: string) => {
  const [signatureText, setSignatureText] = useState("");

  const theBrandSig = useAppSelector(
    (state) => state.globals.brandSignatureData.brands
  );
  const [brandSignatureData, setBrandSignatureData] = useState(
    getSingleBrandData(theBrandSig, fromValue)
  );
  useEffect(() => {
    setBrandSignatureData(getSingleBrandData(theBrandSig, fromValue));
  }, [fromValue, theBrandSig]);
  useEffect(() => {
    if (brandSignatureData?.enabled && brandSignatureData?.signature) {
      setSignatureText(convertTOSignature(brandSignatureData.signature));
    } else {
      setTimeout(() => {
        setSignatureText("");
      }, 0);
    }
  }, [brandSignatureData]);
  return { signatureText };
};
