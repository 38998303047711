import { ESideBar } from "src/store/slices/tickets/tickets.slice";
import { getActiveColKeysFromCols, isObjOrStrEmpty } from "src/utils/utils";
import { MoreColumnType } from "./getMoreColumns";
import { TicketsQuery } from "./getTicketsV2";
import log from "loglevel";
import { Column } from "src/hooks/useTicketViews/ticketViewsConfig";

export function prepareGetTicketsQuery({
  start,
  limit,
  allColumnList,
  tableType,
  filterOptions,
}: {
  start: number;
  limit: number;
  allColumnList: Column[];
  tableType: string;
  filterOptions: any;
}) {
  const {
    activeChannelId,
    activeDraftStatusId,
    activeTicketStatusId,
    filterByAgents,
    filterByCreatedDate,
    filterByCreatedDateValue,
    createdDateValue,
    closedDateValue,
    notRepliedSinceDateValue,
    filterByClosedDate,
    filterByClosedDateValue,
    filterByChannels,
    filterByBrands,
    filterByTags,
    filterByEmails,
    filterByAssignees,
    filterByUserType,
    filterByLastMessage,
    filterByNotRepliedSince,
    filterByNotRepliedSinceValue,
    filterByTicketStatus,
    filterByDraftStatus,
    filterByDraftedBy,
    filterSearchString,
    filterByLastUpdated,
    lastUpdatedValue,
  } = filterOptions;
  // console.log(filterByTicketStatus);
  const queryData: TicketsQuery = {
    columns: getActiveColKeysFromCols(allColumnList, tableType),
    filters: {
      tableType:
        tableType === "my"
          ? "my"
          : tableType === "mentions"
          ? "mentions"
          : tableType === "ai_handled"
          ? "ai_handled"
          : "all",
    },
    start,
    limit,
  };
  if (tableType.includes("viewId")) {
    queryData.filters.viewId = parseInt(tableType.split("::")[1]);
  }

  // log.debug("building query for fetching tickets with data:", filterOptions);

  let filterTableData: any = {};

  //checking if active channel id is set and it is not empty and it doesn't contain 0 as it is referring to all we don't have to add it to payload
  if (
    activeChannelId &&
    activeChannelId.length &&
    !activeChannelId.includes(0)
  ) {
    filterTableData["ticket_channel"] = {
      compare: "=",
      value: activeChannelId.join(","),
    };
  }
  //checking if active draft id is set and it is not empty and it doesn't contain 0 as it is referring to all we don't have to add it to payload
  if (
    activeDraftStatusId &&
    activeDraftStatusId.length &&
    !activeDraftStatusId.includes(0)
  ) {
    filterTableData["ticket_draft_status"] = {
      compare: "=",
      value: activeDraftStatusId.join(","),
    };
  }
  //checking if active ticket status id is set and it is not empty and it doesn't contain 0 as it is referring to all we don't have to add it to payload
  if (
    activeTicketStatusId &&
    activeTicketStatusId.length &&
    !activeTicketStatusId.includes(0)
  ) {
    filterTableData["ticket_status_id"] = {
      compare: "=",
      value: activeTicketStatusId.join(","),
    };
  }

  if (
    !isObjOrStrEmpty(filterByCreatedDate) &&
    !filterByCreatedDate?.viewFilter
  ) {
    // log.debug("in filterByCreatedDate");
    filterTableData["ticket_date_gmt"] = {
      compare: filterByCreatedDate.compare,
      value:
        createdDateValue === null
          ? filterByCreatedDate.defaultValue
          : createdDateValue,
    };
  }

  if (
    !isObjOrStrEmpty(filterByLastUpdated) &&
    !filterByLastUpdated?.viewFilter
  ) {
    filterTableData["ticket_modified_gmt"] = {
      compare: filterByLastUpdated.compare,
      value:
        lastUpdatedValue === null
          ? filterByLastUpdated.defaultValue
          : lastUpdatedValue,
    };
  }

  if (!isObjOrStrEmpty(filterByClosedDate) && !filterByClosedDate?.viewFilter) {
    // log.debug("in filterByClosedDate");
    filterTableData["ticket_closed_date_gmt"] = {
      compare: filterByClosedDate.compare,
      value:
        closedDateValue === null
          ? filterByClosedDate.defaultValue
          : closedDateValue,
    };
  }

  if (
    !isObjOrStrEmpty(filterByNotRepliedSince) &&
    !filterByNotRepliedSince?.viewFilter
  ) {
    filterTableData["not_replied_since"] = {
      compare: filterByNotRepliedSince.compare,
      value:
        notRepliedSinceDateValue === null
          ? filterByNotRepliedSince.defaultValue
          : notRepliedSinceDateValue,
    };
  }

  if (!isObjOrStrEmpty(filterByChannels)) {
    let value = getValue(filterByChannels, true);
    if (value.trim()) {
      if (filterTableData.hasOwnProperty("ticket_channel")) {
        // If the property already exists, update its value
        let existingValue = filterTableData["ticket_channel"].value;
        let updatedValueArray = [
          ...new Set([...existingValue.split(","), value.split(",")]),
        ];
        filterTableData["ticket_channel"].value = updatedValueArray.join(",");
      } else {
        filterTableData["ticket_channel"] = {
          compare: "=",
          value: value,
        };
      }
    }
  }
  if (!isObjOrStrEmpty(filterByBrands)) {
    let value = getValue(filterByBrands, true);
    if (value.trim()) {
      filterTableData["ticket_brand_id"] = {
        compare: "=",
        value: value,
      };
    }
  }
  if (!isObjOrStrEmpty(filterByAgents)) {
    let value = getValue(filterByAgents, true);
    if (value.trim()) {
      filterTableData["ticket_agent"] = {
        compare: "=",
        value: value,
      };
    }
  }

  if (!isObjOrStrEmpty(filterByTags)) {
    let value = getValue(filterByTags, true);
    if (value.trim()) {
      filterTableData["tags"] = {
        compare: "=",
        value: value,
      };
    }
  }
  if (!isObjOrStrEmpty(filterByEmails) && !filterByEmails?.viewFilter) {
    filterTableData["filter_email"] = {
      compare: filterByEmails.compare,
      value: filterByEmails.id,
    };
  }

  if (!isObjOrStrEmpty(filterByUserType)) {
    let value = getValue(filterByUserType, true);
    if (value.trim()) {
      filterTableData["user_type"] = {
        compare: "=",
        value: value,
      };
    }
  }
  if (!isObjOrStrEmpty(filterByLastMessage)) {
    let value = getValue(filterByLastMessage, true);
    if (value.trim()) {
      filterTableData["last_message"] = {
        compare: "=",
        value: value,
      };
    }
  }

  if (!isObjOrStrEmpty(filterByTicketStatus)) {
    let value = getValue(filterByTicketStatus, true);
    if (value.trim()) {
      if (filterTableData.hasOwnProperty("ticket_status_id")) {
        // If the property already exists, update its value
        let existingValue = filterTableData["ticket_status_id"].value;
        let updatedValueArray = [
          ...new Set([...existingValue.split(","), value.split(",")]),
        ];
        filterTableData["ticket_status_id"].value = updatedValueArray.join(",");
      } else {
        filterTableData["ticket_status_id"] = {
          compare: "=",
          value: value,
        };
      }
    }
  }
  if (!isObjOrStrEmpty(filterByDraftStatus)) {
    let value = getValue(filterByDraftStatus, true);
    if (value.trim()) {
      if (filterTableData.hasOwnProperty("ticket_draft_status")) {
        // If the property already exists, update its value
        let existingValue = filterTableData["ticket_draft_status"].value;
        let updatedValueArray = [
          ...new Set([...existingValue.split(","), value.split(",")]),
        ];
        filterTableData["ticket_draft_status"].value =
          updatedValueArray.join(",");
      } else {
        filterTableData["ticket_draft_status"] = {
          compare: "=",
          value: value,
        };
      }
    }
  }
  if (!isObjOrStrEmpty(filterByDraftedBy)) {
    let value = getValue(filterByDraftedBy, true);
    if (value.trim()) {
      filterTableData["drafted_by"] = {
        compare: "=",
        value: value,
      };
    }
  }

  if (Object.keys(filterTableData).length > 0) {
    queryData.filters.options = addKeyToAllFilters(filterTableData);
  }

  if (filterSearchString.trim().length > 0) {
    queryData.filters.searchOptions = {
      value: filterSearchString.trim(),
      keys: ["*"],
    };
  }

  // log.debug({ queryData });

  return queryData;
}
export function prepareInnerTicketQuery({
  filterOptions,
  appliedFilters,
}: {
  filterOptions: any;
  appliedFilters: any;
}) {
  const {
    activeChannelId,
    activeDraftStatusId,
    filterByAgents,
    filterByCreatedDate,
    filterByCreatedDateValue,
    createdDateValue,
    closedDateValue,
    notRepliedSinceDateValue,
    filterByClosedDate,
    filterByClosedDateValue,
    filterByChannels,
    filterByBrands,
    filterByTags,
    filterByEmails,
    filterByAssignees,
    filterByUserType,
    filterByLastMessage,
    filterByNotRepliedSince,
    filterByNotRepliedSinceValue,
    filterByDraftStatus,
    filterByDraftedBy,
    filterByLastUpdated,
    lastUpdatedValue
  } = filterOptions;

  let filterTableData: any = {};

  //checking if active channel id is set and it is not empty and it doesn't contain 0 as it is referring to all we don't have to add it to payload
  if (
    activeChannelId &&
    activeChannelId.length &&
    !activeChannelId.includes(0)
  ) {
    filterTableData["ticket_channel"] = {
      compare: "=",
      value: activeChannelId.join(","),
    };
  }
  //checking if active draft id is set and it is not empty and it doesn't contain 0 as it is referring to all we don't have to add it to payload
  if (
    activeDraftStatusId &&
    activeDraftStatusId.length &&
    !activeDraftStatusId.includes(0)
  ) {
    filterTableData["ticket_draft_status"] = {
      compare: "=",
      value: activeDraftStatusId.join(","),
    };
  }

  if (!isObjOrStrEmpty(filterByCreatedDate)) {
    // log.debug("in filterByCreatedDate");
    filterTableData["ticket_date_gmt"] = {
      compare: filterByCreatedDate.compare,
      value:
        createdDateValue === null
          ? filterByCreatedDate.defaultValue
          : createdDateValue,
    };
  }

  if (!isObjOrStrEmpty(filterByLastUpdated)) {
    filterTableData["ticket_modified_gmt"] = {
      compare: filterByLastUpdated.compare,
      value:
        lastUpdatedValue === null
          ? filterByLastUpdated.defaultValue
          : lastUpdatedValue,
    };
  }

  if (!isObjOrStrEmpty(filterByClosedDate)) {
    // log.debug("in filterByClosedDate");
    filterTableData["ticket_closed_date_gmt"] = {
      compare: filterByClosedDate.compare,
      value:
        closedDateValue === null
          ? filterByClosedDate.defaultValue
          : closedDateValue,
    };
  }

  if (!isObjOrStrEmpty(filterByNotRepliedSince)) {
    filterTableData["not_replied_since"] = {
      compare: filterByNotRepliedSince.compare,
      value:
        notRepliedSinceDateValue === null
          ? filterByNotRepliedSince.defaultValue
          : notRepliedSinceDateValue,
    };
  }

  if (!isObjOrStrEmpty(filterByChannels)) {
    let value = getValue(filterByChannels, true);
    if (value.trim()) {
      if (filterTableData.hasOwnProperty("ticket_channel")) {
        // If the property already exists, update its value
        let existingValue = filterTableData["ticket_channel"].value;
        let updatedValueArray = [
          ...new Set([...existingValue.split(","), value.split(",")]),
        ];
        filterTableData["ticket_channel"].value = updatedValueArray.join(",");
      } else {
        filterTableData["ticket_channel"] = {
          compare: "=",
          value: value,
        };
      }
    }
  }
  if (!isObjOrStrEmpty(filterByBrands)) {
    let value = getValue(filterByBrands, true);
    if (value.trim()) {
      filterTableData["ticket_brand_id"] = {
        compare: "=",
        value: value,
      };
    }
  }
  if (!isObjOrStrEmpty(filterByAgents)) {
    let value = getValue(filterByAgents, true);
    if (value.trim()) {
      filterTableData["ticket_agent"] = {
        compare: "=",
        value: value,
      };
    }
  }
  if (!isObjOrStrEmpty(filterByTags)) {
    let value = getValue(filterByTags, true);
    if (value.trim()) {
      filterTableData["tags"] = {
        compare: "=",
        value: value,
      };
    }
  }
  if (!isObjOrStrEmpty(filterByEmails) && !filterByEmails?.viewFilter) {
    filterTableData["filter_email"] = {
      compare: filterByEmails.compare,
      value: filterByEmails.id,
    };
  }

  if (!isObjOrStrEmpty(filterByUserType)) {
    let value = getValue(filterByUserType, true);
    if (value.trim()) {
      filterTableData["user_type"] = {
        compare: "=",
        value: value,
      };
    }
  }
  if (!isObjOrStrEmpty(filterByLastMessage)) {
    let value = getValue(filterByLastMessage, true);
    if (value.trim()) {
      filterTableData["last_message"] = {
        compare: "=",
        value: value,
      };
    }
  }

  if (!isObjOrStrEmpty(filterByDraftStatus)) {
    let value = getValue(filterByDraftStatus, true);
    if (value.trim()) {
      if (filterTableData.hasOwnProperty("ticket_draft_status")) {
        // If the property already exists, update its value
        let existingValue = filterTableData["ticket_draft_status"].value;
        let updatedValueArray = [
          ...new Set([...existingValue.split(","), value.split(",")]),
        ];
        filterTableData["ticket_draft_status"].value =
          updatedValueArray.join(",");
      } else {
        filterTableData["ticket_draft_status"] = {
          compare: "=",
          value: value,
        };
      }
    }
  }
  if (!isObjOrStrEmpty(filterByDraftedBy)) {
    let value = getValue(filterByDraftedBy, true);
    if (value.trim()) {
      filterTableData["drafted_by"] = {
        compare: "=",
        value: value,
      };
    }
  }

  if (Object.keys(filterTableData).length > 0) {
    return addKeyToAllFilters(filterTableData, appliedFilters);
  } else {
    return appliedFilters;
  }
}

function getValue(filterArray: [], ignoreViewFilter = true) {
  let filters: any = filterArray;
  if (ignoreViewFilter) {
    filters = filterArray.filter((filterObj: any) => !filterObj?.viewFilter);
  }
  return filters.map((filterObj: any) => filterObj.id).join(",");
}

function addKeyToAllFilters(
  filterTableData: any,
  appliedFilters?: any
): {
  key: string;
  compare: string;
  value: number;
}[] {
  const keyAddedObj = filterTableData;
  Object.entries(filterTableData).forEach(([key, value]) => {
    keyAddedObj[key].key = key;
  });
  if (appliedFilters) {
    appliedFilters.forEach((element: any) => {
      keyAddedObj[element.key] = element;
    });
  }
  return Object.values(keyAddedObj);
}

// function getColFilter()
