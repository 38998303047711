import { EReturnAutomationQuestionKeys } from "src/enums/EReturnAutomationQuestionKeys";
import {
  AutomationQuestionKeys,
  StepData,
  StepQuestion,
} from "src/features/ReturnAutoWorkFlow/ReturnAutoWorkFlow.types";
import { StepName } from "src/features/ReturnAutoWorkFlow/hooks/useReturnPreview";

export const updatePreviewDetails = ({
  questionKey,
  isRestockingFeeNotCharged,
  showReturnReasons,
}: {
  questionKey: string;
  isRestockingFeeNotCharged?: boolean;
  showReturnReasons?: boolean;
}) => {
  let selectedOrderId = "SampleOrderWithinReturnWindow";
  let activeStep: StepName = "selectOrder";
  switch (questionKey) {
    case EReturnAutomationQuestionKeys.returnWindow:
      selectedOrderId = "SampleOrderOutSideReturnWindow";
      activeStep = "selectAction";
      break;
    case EReturnAutomationQuestionKeys.returnNotEligibleResponseMessage:
      selectedOrderId = "SampleOrderOutSideReturnWindow";
      activeStep = "notReturnable";
      break;
    case EReturnAutomationQuestionKeys.isReturnPolicySameForAllItem:
      activeStep = "addReturnItems";
      break;
    case EReturnAutomationQuestionKeys.resolutionOffer:
      activeStep = "returnType";
      break;
    case EReturnAutomationQuestionKeys.selectReturnReason:
      if (showReturnReasons) {
        activeStep = "reason";
      } else {
        activeStep = "returnType";
      }
      // else if (isReturnMethodAvailable) {
      // activeStep = "returnMethod";
      // } else {
      //   activeStep = "configureNextSteps";
      // }
      break;
    case EReturnAutomationQuestionKeys.returnReasonList:
    case EReturnAutomationQuestionKeys.isReturnReasonSameForAll:
      activeStep = "reason";
      break;
    case EReturnAutomationQuestionKeys.antiReturnStrategies:
    case EReturnAutomationQuestionKeys.configureAntiReturnStrategies:
    case EReturnAutomationQuestionKeys.isReturnReasonOrFollowUpQuestionsSame:
      activeStep = "configureNextSteps";
      break;
    case EReturnAutomationQuestionKeys.returnMethodForMajorityOfOrders:
    case EReturnAutomationQuestionKeys.returnMethodDisplayMessage:
    case EReturnAutomationQuestionKeys.isReturnMethodSameForAllOrders:
      activeStep = "returnMethod";
      break;
    case EReturnAutomationQuestionKeys.isRestockingFeeChargedForMajorityOrders:
      if (isRestockingFeeNotCharged) {
        activeStep = "orderSummary";
      } else {
        activeStep = "reviewRestockingFee";
      }
      break;
    case EReturnAutomationQuestionKeys.usuallyChargedRestockingFee:
    case EReturnAutomationQuestionKeys.restockingFeeDisplayMessage:
    case EReturnAutomationQuestionKeys.isRestockingFeeSameForAll:
      activeStep = "reviewRestockingFee";
      break;
    case EReturnAutomationQuestionKeys.storeCurrency:
    case "otherQuestion":
      activeStep = "configureNextSteps";
      break;
    default:
      break;
  }
  return {
    selectedOrderId,
    activeStep,
  };
};
/**
 * Helper function to find a question by its questionKey in configured steps.
 * @param questionKey - The key of the question to find.
 * @param configuredSteps - The configured steps containing questions.
 * @returns The question with the specified questionKey or undefined if not found.
 */
export function findQuestionByKey(
  questionKey: AutomationQuestionKeys,
  configuredSteps: Record<string, StepData>,
  //stepId
): StepQuestion | undefined {
  for (const stepId in configuredSteps) {
    const step = configuredSteps[stepId];

    for (const questionId of step.stepQuestionIds) {
      const question = step.stepQuestions[questionId];

      if (question.questionKey === questionKey) {
        return question;
      }
    }
  }
  return undefined;
}

/**
 * Helper function to find a question by its questionKey in step with stepId.
 * @param questionKey - The key of the question to find.
 * @param configuredSteps - The configured steps containing questions.
 * @param stepId - The step id to search for.
 * @returns The question with the specified questionKey or undefined if not found.
 */
export function findQuestionInStepByKey(
  questionKey: AutomationQuestionKeys,
  configuredSteps: Record<string, StepData>,
  stepId: string,
): StepQuestion | undefined {
  const step = configuredSteps[stepId];

  for (const questionId of step.stepQuestionIds) {
    const question = step.stepQuestions[questionId];

    if (question.questionKey === questionKey) {
      return question;
    }
  }
  return undefined;
}

/**
 * Updates the return preview details based on the provided question and configured steps.
 *
 * @param {Object} params - The input parameters.
 * @param {StepQuestion} params.stepQuestion - The step question which is updated.
 * @param {Record<string, StepData>} [params.configuredSteps] - The steps containing questions.
 *
 * @returns {Object} - An object containing the selected order ID and the active step.
 */
export const updateReturnPreviewDetails = ({
  stepQuestion,
}: {
  stepQuestion: StepQuestion;
}) => {
  let restockingFeeQuestion: StepQuestion | undefined;
  let returnReasonsQuestion: StepQuestion | undefined;

  const showReturnReasons =
    returnReasonsQuestion?.options?.some(
      (val) =>
        val.optionKey !== "notRequired" &&
        returnReasonsQuestion?.value !== null &&
        typeof returnReasonsQuestion?.value === "string" &&
        returnReasonsQuestion.value === val.value,
    ) ?? false;

  const isRestockingFee =
    restockingFeeQuestion?.options?.some(
      (val) =>
        val.optionKey === "yes" &&
        restockingFeeQuestion?.value !== null &&
        typeof restockingFeeQuestion?.value === "string" &&
        restockingFeeQuestion.value === val.value,
    ) ?? false;

  const { selectedOrderId, activeStep } = updatePreviewDetails({
    questionKey: stepQuestion.questionKey ?? "",
    isRestockingFeeNotCharged: isRestockingFee ?? false,
    showReturnReasons: showReturnReasons ?? false,
  });

  return {
    selectedOrderId,
    activeStep,
  };
};
export const findFirstQuestionInStep = (stepData: StepData) => {
  // Get the first questionId from the stepQuestionIds array
  const firstQuestionId = stepData.stepQuestionIds[0];

  // If there's a valid firstQuestionId, return the corresponding StepQuestion from stepQuestions
  if (firstQuestionId) {
    return stepData.stepQuestions[firstQuestionId];
  }

  // Return null or undefined if no questions are found
  return null;
};
// Helper function to retrieve the question by key
export function getQuestionByKey(
  stepQuestion: StepQuestion | null,
  key: AutomationQuestionKeys,
  steps: Record<string, any>,
) {
  return stepQuestion?.questionKey === key
    ? stepQuestion
    : findQuestionByKey(key, steps);
}

// Helper function to determine boolean values
export function getBooleanValue(
  question: StepQuestion | null,
  expectedValue: string,
) {
  return question?.value === expectedValue ?? false;
}
