import React, { useCallback, useEffect, useMemo, useRef } from "react";
// import styles from "../../LiveChatWidget.module.scss";
import styles from "./OtherWebsite.module.scss";
import { useState } from "react";
import { MdOutlineContentCopy } from "react-icons/md";
import { BsDisplay } from "react-icons/bs";
import DeleteButton from "../Buttons/DeleteButton/DeleteButton";
import LiveChatEnableToggle from "../LiveChatEnableToggle/LiveChatEnableToggle";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  checkLCInstallationT,
  fetchWebIntegration,
  setInstalledWebInt,
  updateTheWebIntegration,
} from "src/store/slices/liveChatSetting/websiteSettings/websiteSettings.slice";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Modal, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import ToastModals, { ModalTypes } from "../ToastModals/ToastModals";
import { ChatWidgetInstalledEvent } from "src/pusherServices/livechat/websiteSettings";
import {
  checkLCInstallation,
  checkPostLCInstallation,
} from "src/services/LiveChat/Settings/website/checkLCInstallation";
import { validateURL } from "src/utils/validations";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { deleteWebIntegration } from "src/services/LiveChat/Settings/website/deleteWebIntegration";
import Select from "react-select";
import InstallButton from "../Buttons/InstallButton/InstallButton";
import SendingInfo, { SendingInfoLabel } from "src/components/SendingInfo";
import UserAvatar from "src/components/UserAvatar";
import AxiosImg from "src/components/AxiosImg";
import { FetchUpdateWebIntParams } from "src/store/slices/liveChatSetting/websiteSettings/websiteSettings.thunks";
import caution from "src/assets/images/caution.png";
import CustomMsgModal from "./Children/CustomMsgModal/CustomMsgModal";
import { isEmailsValid } from "src/utils/utils";
import { useWidgetScriptSendEmail } from "./Children/CustomHook/useWidgetScriptSendEmail";
import SelectBox from "./Children/SelectBox";
import { GetLinkableAccountsResponse } from "src/services/Integrations/WhatsappIntegration.service";
import WhatsappBrandsSelectBox from "./Children/WhatsappBrandsSelectBox/WhatsappBrandsSelectBox";
import { updateWebIntegration } from "src/services/LiveChat/Settings/website/updateWebIntegration";
import { useQueryClient } from "@tanstack/react-query";

const escapeHtml = (unsafe: string) => {
  return unsafe
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
};

export const LabelComponent = ({
  imgURL,
  name,
  isPublicAttachmentUrl,
  ...props
}: SendingInfoLabel) => {
  return (
    <div className={`d-flex align-items-center`}>
      {imgURL ? (
        <AxiosImg
          url={imgURL}
          isDirectURL={isPublicAttachmentUrl}
          className={`${styles.logoImg} rounded-circle`}
        />
      ) : (
        <UserAvatar
          size={30}
          name={name}
          className={`${styles.logoImg}`}
        />
      )}
      <span className={`ms-1 p-1 ${styles.selectBrand} text-truncate`}>
        {name}
      </span>
    </div>
  );
};

function OtherWebsite() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    activeWebIntAJAXStatus,
    updateNewWebIntAJAXStatus,
    activeWebIntegration,
    lcInstallationStatus,
    sendWidgetScriptEmailAJAXStatus,
  } = useAppSelector((state) => state.websiteSettings);
  const { integrationId } = useParams();
  const [webURL, setWebURL] = useState(activeWebIntegration.store_url ?? "");
  const [webName, setWebName] = useState(
    activeWebIntegration.website_name ?? "",
  );
  const [choosedBrandId, setChoosedBrandId] = useState(
    activeWebIntegration.brand?.id ? activeWebIntegration.brand.id + "" : "",
  );
  const [choosedBrandEmail, setChoosedBrandEmail] = useState(
    activeWebIntegration.brand?.email
      ? activeWebIntegration.brand.email + ""
      : "",
  );
  const [whatsappAccount, setWhatsappAccount] =
    useState<GetLinkableAccountsResponse>({
      id: activeWebIntegration.whatsapp_details?.id || "",
      phone_numbers: activeWebIntegration.whatsapp_details?.phone_numbers || [],
    });
  const [showModal, setShowModal] = useState("" as ModalTypes);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [installLoading, setInstallLoading] = useState(false);
  const [toEmailAddress, setToEmailAddress] = useState("");
  const [showInvalidEmailError, setShowInvalidEmailError] = useState("");
  const [emailSentSuccessFully, setEmailSentSuccessFully] = useState(false);
  const [copied, setCopied] = useState("");
  const [enabled, setEnabled] = useState(
    activeWebIntegration.live_chat_enabled ?? false,
  );
  //state to store ai_bot_enabled flag
  const [botEnabled, setBotEnabled] = useState(
    activeWebIntegration.ai_bot_enabled ?? false,
  );
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [whatsappEnabled, setWhatsappEnabled] = useState(
    activeWebIntegration.whatsapp_details?.enabled ?? false,
  );
  const queryClient = useQueryClient();

  const isUpdated = useMemo(() => {
    return (
      activeWebIntegration.store_url === webURL.trim() &&
      activeWebIntegration.brand?.id == choosedBrandId &&
      activeWebIntegration.website_name === webName.trim() &&
      whatsappAccount.id == (activeWebIntegration.whatsapp_details?.id ?? "")
      // whatsappEnabled ===
      //   (activeWebIntegration.whatsapp_details?.enabled ?? false) &&
      // Both of these will be updated via toggle itself and hence are not a dependency for save button
      // && enabled === (activeWebIntegration.live_chat_enabled ?? false) &&
      // botEnabled === (activeWebIntegration.ai_bot_enabled ?? false)
    );
  }, [
    activeWebIntegration,
    webURL,
    choosedBrandId,
    webName,
    whatsappAccount,
    // enabled,
    // whatsappEnabled,
    // botEnabled,
  ]);

  //function to handle send message success callback
  const onSendEmailSuccessCallback = useCallback(() => {
    //updating the state to show email sent successfully component
    setEmailSentSuccessFully(true);
  }, []);

  const { validateEmailAddresses, sendEmail } = useWidgetScriptSendEmail({
    integrationId,
    toEmailAddress,
    onSendEmailSuccessCallback,
  });

  const currentState = useRef({
    activeWebIntegration,
    integrationId,
    brandsFetched: false,
    accountFetched: false,
  });

  useEffect(() => {
    currentState.current = {
      activeWebIntegration,
      integrationId,
      brandsFetched: currentState.current.brandsFetched,
      accountFetched: currentState.current.accountFetched,
    };
  }, [activeWebIntegration, integrationId]);

  const checkInstallation = useCallback(() => {
    if (integrationId) {
      setInstallLoading(true);
      setShowModal("Installation");
      dispatch(checkLCInstallationT());
    }
  }, [integrationId]);

  //state to keep track validation for the live chat integration
  const [showValidation, setShowValidation] = useState<{
    webURL?: boolean;
    webName?: boolean;
    choosedBrand?: boolean;
    whatsappAccount?: boolean;
  }>({
    webURL: false,
    webName: false,
    choosedBrand: false,
    whatsappAccount: false,
  });

  const updateData = useCallback(() => {
    if (
      integrationId &&
      validateURL(webURL.trim()) &&
      webName.trim() &&
      choosedBrandId
    ) {
      const payload: FetchUpdateWebIntParams = {
        integration_id: integrationId,
        live_chat_integration_type: "otherWebsite",
      };
      if (activeWebIntegration.store_url !== webURL.trim()) {
        payload.url = webURL.trim();
      }
      if (activeWebIntegration.brand?.id != choosedBrandId) {
        payload.brand_id = choosedBrandId;
      }
      if (activeWebIntegration.website_name !== webName.trim()) {
        payload.website_name = webName.trim();
      }
      // No need to update these on save buttons as they will be updated on toggle.
      // if (enabled !== (activeWebIntegration.live_chat_enabled ?? false)) {
      //   payload.live_chat_enabled = enabled;
      // }

      // if (botEnabled !== (activeWebIntegration.ai_bot_enabled ?? false)) {
      //   payload.ai_bot_enabled = botEnabled;
      // }

      // if (
      //   whatsappEnabled !==
      //   (activeWebIntegration.whatsapp_details?.enabled ?? false)
      // ) {
      //   payload.whatsapp_enabled = whatsappEnabled;
      // }

      if (whatsappEnabled && !whatsappAccount.id) {
        setShowValidation((prev) => {
          return {
            ...prev,
            whatsappAccount: true,
          };
        });
        return;
      }

      if (
        whatsappAccount.id !== (activeWebIntegration.whatsapp_details.id ?? "")
      ) {
        payload.whatsappUserId =
          whatsappAccount.id !== "" ? whatsappAccount.id : null;
      }

      dispatch(updateTheWebIntegration(payload)).then(() => {
        if (payload.whatsappUserId !== undefined) {
          queryClient.invalidateQueries([`getLinkableWhatsappAccounts`]);
        }
      });
    } else if (!webName.trim()) {
      // if website name is empty
      setShowValidation({
        webURL: false,
        webName: true,
        choosedBrand: false,
      });
    } else if (!validateURL(webURL.trim())) {
      // if website url is invalid
      setShowValidation({
        webURL: true,
        webName: false,
        choosedBrand: false,
      });
    } else if (!choosedBrandId) {
      // if brand is not selected
      setShowValidation({
        webURL: false,
        webName: false,
        choosedBrand: true,
      });
    } else {
      // if all fields are empty
      pushTheToast({
        type: "warning",
        text: "Please add valid entries!",
        position: "top-right",
      });
    }
  }, [
    integrationId,
    webURL,
    webName,
    choosedBrandId,
    whatsappEnabled,
    whatsappAccount.id,
    // enabled,
    // botEnabled,
    queryClient,
  ]);

  useEffect(() => {
    setWebURL(activeWebIntegration.store_url ?? "");
    setWebName(activeWebIntegration.website_name ?? "");
    setChoosedBrandId(
      activeWebIntegration.brand?.id ? activeWebIntegration.brand.id + "" : "",
    );
    setEnabled(activeWebIntegration.live_chat_enabled ? true : false);
    setWhatsappEnabled(
      activeWebIntegration.whatsapp_details?.enabled ? true : false,
    );
    setBotEnabled(activeWebIntegration.ai_bot_enabled ? true : false);
    if (currentState.current.brandsFetched) {
      setChoosedBrandEmail(
        activeWebIntegration.brand?.email
          ? activeWebIntegration.brand.email + ""
          : "",
      );
    }
    if (currentState.current.accountFetched) {
      setWhatsappAccount(
        activeWebIntegration.whatsapp_details
          ? {
              id: activeWebIntegration.whatsapp_details.id || "",
              phone_numbers:
                activeWebIntegration.whatsapp_details.phone_numbers,
            }
          : {
              id: "",
              phone_numbers: [],
            },
      );
    }
  }, [JSON.stringify(Object.values(activeWebIntegration))]); //to prevent useEffect dependency array lenght should remain constant warning, converting array to JSON string
  useEffect(() => {
    if (
      activeWebIntegration.installed_successfully ||
      lcInstallationStatus !== "pending"
    ) {
      setInstallLoading(false);
    }
  }, [activeWebIntegration.installed_successfully, lcInstallationStatus]);

  const handleDelete = () => {
    if (integrationId) {
      setDeleteLoading(true);
      deleteWebIntegration({
        integrationId: integrationId,
      })
        .then((res) => {
          pushTheToast({
            type: "success",
            text: "Widget deleted successfully!",
            position: "top-right",
          });
          setDeleteLoading(false);
          navigate("/live-chat/settings");
          queryClient.invalidateQueries([`getLinkableWhatsappAccounts`]);
        })
        .catch((err) => {
          pushTheToast({
            type: "danger",
            text: "Failed to delete!",
            position: "top-right",
          });
          setDeleteLoading(false);
        });
    }
  };
  const [showMsgModal, setShowMsgModal] = useState(false);
  const onShow = useCallback(
    (e: any) => {
      e.stopPropagation();
      let error = validateEmailAddresses();

      if (error !== "") {
        setShowInvalidEmailError(error);
        return;
      } else {
        setShowInvalidEmailError("");
      }

      setShowMsgModal(true);
      // console.log("show");
    },
    [toEmailAddress, activeWebIntegration.embedded_script],
  );
  const onHide = () => {
    setShowMsgModal(false);
    //reset every thing
    setShowInvalidEmailError("");
    setEmailSentSuccessFully(false);
    // console.log("hide");
  };
  //function updates the toEmailAddress state on change of input value
  const handleOnChangeToAddress = useCallback((e: any) => {
    setToEmailAddress(e.target.value);
  }, []);

  return (
    <>
      <div
        className={`${styles.InstallGuideBox} pt-3 pb-4 bg-body height-auto`}
      >
        {/* <div className="text-primary">
          <BsDisplay />
          <span className="ms-1">
            Install Helplama live chat widget on the website
          </span>
        </div> */}
        {/* Point 1 */}
        <div className="d-flex mt-3 w-100">
          <div className={`${styles.pointsDiv} small mt-0 me-2`}>1</div>
          <div className={`${styles.rightSection}`}>
            <div className="d-flex justify-content-between flex-wrap">
              <div>
                <span className={`${styles.stepPara}`}>
                  Let's begin with the basics
                </span>
              </div>
              <div className={`${styles.buttonsContainer}`}>
                <LiveChatEnableToggle
                  liveChatType="otherWebsite"
                  disabled={
                    !currentState.current.activeWebIntegration
                      .installed_successfully ||
                    currentState.current.activeWebIntegration.store_url !==
                      webURL
                  }
                  enabled={enabled}
                  integrationId={integrationId}
                  type="live_chat_enabled"
                  onChange={(value: boolean) => setEnabled(value)}
                />

                {/* Whatsapp Enable/Disable Button */}
                {activeWebIntegration.whatsapp_available && (
                  <LiveChatEnableToggle
                    disabled={
                      !currentState.current.activeWebIntegration
                        .installed_successfully ||
                      currentState.current.activeWebIntegration.store_url !==
                        webURL ||
                      updateNewWebIntAJAXStatus === "pending" ||
                      !activeWebIntegration?.whatsapp_details?.id
                    }
                    enabled={whatsappEnabled}
                    integrationId={integrationId}
                    type="whatsapp_enabled"
                    onChange={(value: boolean) => {
                      setWhatsappEnabled(value);
                    }}
                    enabledText="Whatsapp Enabled"
                    disabledText="Whatsapp Disabled"
                    liveChatType="otherWebsite"
                    setShowValidation={setShowValidation}
                    selectedWhatsAppId={
                      activeWebIntegration?.whatsapp_details?.id
                    }
                  />
                )}
                {/* toggle button to show "Ai Bot Enabled" option in live chat setting */}
                {/* {activeWebIntegration.ai_bot_available ? (
                  <LiveChatEnableToggle
                    disabled={
                      !currentState.current.activeWebIntegration
                        .installed_successfully ||
                      currentState.current.activeWebIntegration.store_url !==
                        webURL
                    }
                    enabled={botEnabled}
                    type="ai_bot_enabled"
                    integrationId={integrationId}
                    onChange={(value: boolean) => setBotEnabled(value)}
                    enabledText="AI Bot Enabled"
                    disabledText="AI Bot Disabled"
                  />
                ) : (
                  <></>
                )} */}
              </div>
            </div>
            <div className={`w-100 my-3`}>
              <p className={`${styles.storeDet}`}>
                {(activeWebIntegration.embedded_script !== null) === true
                  ? "Store details"
                  : "Enter store details"}
              </p>
              <div>
                <label
                  htmlFor=""
                  className="mb-2"
                >
                  <p className={`${styles.labelHead} mb-0`}>Store name:</p>
                  <span className={`${styles.labelSub}`}>
                    This name will appear to your audience when they interact
                    with the live chat widget
                  </span>
                </label>
                <input
                  className={`w-100 form-control p-2 px-3 ${
                    styles.storeInput
                  } ${
                    showValidation.webName ? styles.valdationBorder : "mb-3"
                  }`}
                  type="text"
                  placeholder="Enter website name"
                  aria-label="default input example"
                  value={webName}
                  onChange={(e) => {
                    setShowValidation({
                      webName: false,
                    });
                    setWebName(e.target.value);
                  }}
                />
              </div>
              <span
                className={`${
                  showValidation.webName ? styles.validationText : "d-none"
                } mb-3`}
              >
                Please enter website name!
              </span>
              <div>
                <label
                  htmlFor=""
                  className="mb-2"
                >
                  <p className={`${styles.labelHead} mb-0`}>Store's Website</p>
                  <span className={`${styles.labelSub}`}>
                    This is URL where your live chat widget will be installed
                  </span>
                </label>
                <input
                  className={`w-100 form-control ${styles.storeInput} ${
                    showValidation.webURL ? styles.valdationBorder : ""
                  }`}
                  type="text"
                  placeholder="Enter website URL"
                  aria-label="default input example"
                  value={webURL}
                  onChange={(e) => {
                    setShowValidation({
                      webURL: false,
                    });
                    setWebURL(e.target.value);
                  }}
                />
              </div>
              <span
                className={`${
                  showValidation.webURL ? styles.validationText : "d-none"
                }`}
              >
                Please enter a valid URL!
              </span>
            </div>
            {/* Hiding the brand dropdown if already configured */}
            <div
              className={`mb-3 ${
                (activeWebIntegration.embedded_script !== null) === true && ""
              }`}
            >
              <p className={`${styles.chosePara}`}>
                {(activeWebIntegration.embedded_script !== null) === true
                  ? "Brand linked"
                  : "Choose a brand"}
              </p>
              <SelectBox
                name="from"
                integrationId={integrationId}
                currentValue={choosedBrandEmail}
                getIdValue={(value) => {
                  setChoosedBrandId(value);
                  setShowValidation({
                    choosedBrand: false,
                  });
                }}
                isDisabled={
                  activeWebIntegration.embedded_script !== null &&
                  activeWebIntegration.brand !== null
                }
                getValue={(value) => {
                  setChoosedBrandEmail(value);
                }}
                isMulti={false}
                LabelComponent={LabelComponent}
                placeholder={"Select Brand"}
                initialFetchDone={() => {
                  currentState.current.brandsFetched = true;
                  setChoosedBrandEmail(
                    currentState.current.activeWebIntegration.brand?.email
                      ? currentState.current.activeWebIntegration.brand.email +
                          ""
                      : "",
                  );
                }}
                validationError={showValidation.choosedBrand}
              />
              <span
                className={`${
                  showValidation.choosedBrand ? styles.validationText : "d-none"
                }`}
              >
                Please select a brand!
              </span>
              <div>
                {(activeWebIntegration.embedded_script !== null) === true ? (
                  <></>
                ) : (
                  <p className={`mt-2 ${styles.integrate}`}>
                    Helplama live chat widget will be linked to the selected
                    brand. If you want to create a brand click here.
                    <Link
                      to="/settings/Brands"
                      className={`ms-1 ${styles.link}`}
                    >
                      <span>
                        Manage Brand
                        <span>
                          <i className="fa-solid fa-link ms-1"></i>
                        </span>
                      </span>
                    </Link>
                  </p>
                )}
                {/* <p className={`mt-2 ${styles.integrate}`}>
                  Helplama live chat widget will be linked to the selected
                  brand. If you want to create a brand click here.
                  <Link to="/settings/Brands" className={`ms-1 ${styles.link}`}>
                    <span>
                      Manage Brand
                      <span>
                        <i className="fa-solid fa-link ms-1"></i>
                      </span>
                    </span>
                  </Link>
                </p> */}
              </div>
            </div>

            {/* Whatsapp Account Link */}
            {activeWebIntegration.whatsapp_available && (
              <div className="mb-3">
                <p className={`${styles.chosePara}`}>
                  {(activeWebIntegration.embedded_script !== null) === true
                    ? "Account linked"
                    : "Choose a account"}
                </p>
                <WhatsappBrandsSelectBox
                  integrationId={integrationId}
                  currentValue={whatsappAccount}
                  placeholder={"Select Account"}
                  initialFetchDone={() => {
                    currentState.current.accountFetched = true;
                  }}
                  getValue={(value) => {
                    setShowValidation({
                      ...showValidation,
                      whatsappAccount: false,
                    });
                    setWhatsappAccount(
                      !value
                        ? { id: "", phone_numbers: [] }
                        : {
                            id: value.id,
                            phone_numbers: value.phone_numbers,
                          },
                    );
                  }}
                  showValidation={showValidation.whatsappAccount ?? false}
                  additionalLinkableAccount={
                    activeWebIntegration.whatsapp_details
                      ? {
                          id: activeWebIntegration.whatsapp_details.id || "",
                          phone_numbers:
                            activeWebIntegration.whatsapp_details.phone_numbers,
                        }
                      : undefined
                  }
                />
              </div>
            )}
          </div>
        </div>
        {activeWebIntegration.embedded_script !== null && (
          <>
            {/* Point 2 */}
            <div className="d-flex mt-3">
              <div className={`${styles.pointsDiv} small mt-0 me-1`}>2</div>
              <div className={`${styles.rightSection}`}>
                <div className={`m-auto ms-1 ${styles.storeDet}`}>
                  Copy and paste this code before the closing{" "}
                  <code style={{ color: "#12C235" }}>&lt;/body&gt; </code>
                  tag on every page of your website.
                </div>

                <div className={`my-2 ${styles.code}`}>
                  <div className={`${styles.breakScript}`}>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: escapeHtml(activeWebIntegration.embedded_script)
                          .replace(/\n/g, "<br>")
                          .replace(/\t/g, "&nbsp;&nbsp;&nbsp;&nbsp;"),
                      }}
                    />
                    {/* {activeWebIntegration.embedded_script} */}
                  </div>
                  <button
                    className={`${styles.installBtn} ${
                      copied === activeWebIntegration.embedded_script
                        ? styles.copied
                        : ""
                    } mt-3 w-auto px-2`}
                    onClick={() => {
                      if (activeWebIntegration.embedded_script) {
                        window.navigator.clipboard.writeText(
                          activeWebIntegration.embedded_script,
                        );
                        setCopied(activeWebIntegration.embedded_script);
                      }
                    }}
                  >
                    {copied === activeWebIntegration.embedded_script ? (
                      <>
                        <i className="fa-solid fa-check"></i> Copied!
                      </>
                    ) : (
                      <>
                        <MdOutlineContentCopy /> Copy Code
                      </>
                    )}
                  </button>
                </div>
                <div className={`${styles.emailSend} d-none`}>
                  <span className={`px-2 py-1 mt-2 ${styles.orSpan}`}>OR</span>
                  <div>
                    <div
                      className={`mt-2 d-block d-md-flex align-items-center`}
                    >
                      <span className={`pe-1 ${styles.sendCode}`}>
                        {" "}
                        Send widget code to e-mail{" "}
                      </span>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip className={` ${styles.toolTipCustom}`}>
                            You can send this code to a developer or an
                            acquaintance who can add it to your website.
                          </Tooltip>
                        }
                      >
                        {({ ref, ...triggerHandler }) => (
                          <img
                            {...triggerHandler}
                            ref={ref}
                            src={caution}
                            alt="close"
                            className={`cursor-pointer ${styles.cautionImg}`}
                          />
                        )}
                      </OverlayTrigger>
                    </div>
                    <div>
                      <div className="mt-2 d-flex align-items-start">
                        <div className={`${styles.inputWrapper}`}>
                          <input
                            type="email"
                            className={`form-control ${styles.mailInput} ${
                              showInvalidEmailError.length === 0
                                ? ""
                                : "border-danger"
                            }`}
                            id="exampleFormControlInput1"
                            placeholder="you@example.com"
                            value={toEmailAddress}
                            onChange={handleOnChangeToAddress}
                          />
                          <span className={`${styles.errorMsg}`}>
                            {showInvalidEmailError}
                          </span>
                        </div>
                        <button
                          className={`ms-2 btn btn-primary ${styles.sendBtn}`}
                          onClick={onShow}
                        >
                          Send
                        </button>
                      </div>
                      <span className={`${styles.tip}`}>
                        You can enter as many e-mails addresses separated by a
                        comma
                      </span>
                      <Modal
                        // backdropClassName={`${styles.modalBack}`}
                        show={showMsgModal}
                        onHide={onHide}
                        dialogClassName={`${styles.msgModalDialog}`}
                        contentClassName={`${styles.msgModalContent}`}
                      >
                        <CustomMsgModal
                          script={activeWebIntegration.embedded_script}
                          showSuccess={emailSentSuccessFully}
                          sendMailAjaxStatus={sendWidgetScriptEmailAJAXStatus}
                          onSendMail={sendEmail}
                          toEmailAddress={toEmailAddress}
                          onHide={onHide}
                        />
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Point 3  */}
            <div className="d-flex mt-3">
              <div className={`${styles.pointsDiv} small mt-0 me-1`}>3</div>
              <div className={`${styles.rightSection}`}>
                <div className={`m-auto ms-1 ${styles.storeDet}`}>
                  {`Reload your website. Saufter should appear in the bottom right corner.`}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="d-flex flex-wrap justify-content-md-end">
        {activeWebIntegration.store_url?.trim() ? (
          <DeleteButton onClick={(e: any) => setShowDeleteModal(true)} />
        ) : (
          <></>
        )}
        <button
          className={`${styles.checkInstallation} d-flex mt-md-0`}
          disabled={
            (activeWebIntegration.installed_successfully && isUpdated) ||
            activeWebIntAJAXStatus === "pending" ||
            updateNewWebIntAJAXStatus === "pending"
          }
          onClick={() => {
            if (isUpdated && !activeWebIntegration.installed_successfully) {
              checkInstallation();
            } else if (!isUpdated) {
              updateData();
            }
          }}
        >
          {updateNewWebIntAJAXStatus === "pending" && (
            <Spinner
              animation="border"
              size="sm"
              className="my-auto mx-1"
            />
          )}
          <span className="d-inline-block my-auto mx-1">
            {!isUpdated && activeWebIntegration.installed_successfully
              ? "Save"
              : !isUpdated
                ? "Next"
                : activeWebIntegration.installed_successfully
                  ? "Installed"
                  : "Check Installation"}
          </span>
        </button>
      </div>
      <ToastModals
        showModal={showModal}
        setShowModal={setShowModal as any}
        loading={installLoading}
        success={activeWebIntegration.installed_successfully}
        callAgain={checkInstallation}
      />
      <Modal
        backdropClassName={`${styles.modalBack}`}
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        dialogClassName={`${styles.modalDialog}`}
        contentClassName={`${styles.modalContent}`}
        // centered={true}
        enforceFocus={false}
      >
        <div className={`container`}>
          <div className={`row`}>
            <div className={`col-12`}>
              <div className="text-center">
                <p className={`${styles.delPara}`}>
                  Are you sure you want to delete this integration?
                </p>
                <span className={`${styles.delSpan}`}>
                  All associated views and rules will be deleted
                </span>
                <div
                  className={`mt-2 pt-2 d-flex justify-content-center align-items-center flex-column flex-md-row`}
                >
                  <button
                    className={`me-md-4 ${styles.cancelBtn}`}
                    onClick={() => setShowDeleteModal(false)}
                  >
                    Cancel
                  </button>

                  <button
                    className={`mt-2 mt-md-0 ${styles.confirmBtn}`}
                    onClick={handleDelete}
                    disabled={deleteLoading}
                  >
                    {deleteLoading === true ? (
                      <Spinner
                        className="my-auto mx-1"
                        animation="border"
                        color="white"
                        size="sm"
                      />
                    ) : (
                      "Confirm"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default OtherWebsite;
