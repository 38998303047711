import { axiosJSON } from "src/globals/axiosEndPoints";
export interface ReasonQuestion {
  additionalOptions: Array<{ id: string | null; value: string }>;
  elementId: string;
  elementTypeId: string;
  elementValue: string;
}

export interface ReturnReason {
  id: string;
  reasonName: string;
  moreQuestions: ReasonQuestion[];
}

export interface GetAllReturnReasonList {
  reasonIds: Array<string>;
  reasons: { [key: string]: ReturnReason };
}

export interface GetAllReturnReasonListParams {
  integrationId: number | string;
}
export async function getAllReturnReasonList(
  payload: GetAllReturnReasonListParams,
) {
  const { data } = await axiosJSON.post(
    "/api/setting/automation/refund/step/14/getAllReturnReasonList",
    payload,
  );

  if (data.err === true) {
    throw new Error("Some Error Occurred while fetching data");
  }

  const ret: GetAllReturnReasonList = {
    reasonIds: [],
    reasons: {},
  };

  data.data.forEach((returnReason: ReturnReason) => {
    const id = returnReason.id;
    ret.reasonIds.push(returnReason.id);
    ret.reasons[id] = returnReason;
  });

  return ret;
}
