/**
 * This file is the React component file for rendering a dropdown menu with a list of articles.
 * It provides the functionality to select and filter articles.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 * @author Navjyot
 */

import styles from "./ArticleFilters.module.scss";
import { Dropdown } from "react-bootstrap";
import useArticleFilter from "./useArticleFilter";
import InfiniteScroll from "src/components/InfiniteScrollBothSide";
import { v4 as uuid } from "uuid";
import { useRef } from "react";

// Define the interface for the component's props
interface Props {
  isSegement?: boolean;
  disabled?: boolean;
}

// React component for rendering a dropdown menu with a list of articles
const ArticleFilters = ({ isSegement, disabled }: Props) => {
  // Destructuring values from the custom hook
  const {
    setDdOpen,
    ddOpen,
    dropdownArrowSpanRef,
    allArticles,
    checkUncheckStatus,
    selectAllStatus,
    selectedArticles,
    allArticleIds,
    fetchNextPage,
    hasNextPage,
    clearAllStatus,
  } = useArticleFilter();

  // Generate a unique identifier for the component
  const uuidRef = useRef(uuid());

  return (
    <div>
      {/* Dropdown component from react-bootstrap */}
      <Dropdown onToggle={setDdOpen}>
        {/* Dropdown toggle button */}
        <Dropdown.Toggle
          as="div"
          bsPrefix={`dropdown-toggle cursor-pointer border-0 px-2 py-1 me-2  mb-2 mb-lg-0 ${
            styles.filterButton
          } ${ddOpen && styles.openFilter}
          ${disabled && styles.disable} `}
        >
          All articles
          {/* Display selected article count if any */}
          {selectedArticles?.length ? (
            <>
              <span className={`mx-1 ${styles.filterCount}`}>
                {/* added condition to show 0 before count if count is less than 10 */}
                {selectedArticles.length < 10
                  ? `0${selectedArticles.length}`
                  : selectedArticles.length}
              </span>{" "}
            </>
          ) : (
            ""
          )}
          {/* Display dropdown arrow */}
          <span className="ps-1" ref={dropdownArrowSpanRef}>
            {ddOpen ? (
              <i className={`fa-solid fa-caret-up`}></i>
            ) : (
              <i className={`fa-solid fa-caret-down`}></i>
            )}
          </span>
        </Dropdown.Toggle>

        {/* Dropdown menu */}
        <Dropdown.Menu bsPrefix={`dropdown-menu border-0 ${styles.filterMenu}`}>
          <div className={`${styles.dropMenu}`}>
            {/* Select all item */}
            <span className={`${styles.dropItem}`}>
              <div
                className={`${styles.itemWrapper} ${
                  selectedArticles.length === allArticles?.length &&
                  styles.selectedText
                }`}
              >
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value="all"
                    checked={selectedArticles.length === allArticles?.length}
                    onChange={selectAllStatus}
                    id={uuidRef.current + "report-filter-status-all-article"}
                  />
                  <label
                    className={`form-check-label ${styles.checktext}`}
                    htmlFor={
                      uuidRef.current + "report-filter-status-all-article"
                    }
                  >
                    Select all
                  </label>
                </div>
              </div>
            </span>

            {/* List of articles with infinite scroll */}
            <span className={`${styles.dropItem}`}>
              <div style={{ maxHeight: "20vh" }}>
                {/* InfiniteScroll component for efficient scrolling */}
                <InfiniteScroll
                  loadMoreFromBottom={fetchNextPage}
                  hasMoreBottom={hasNextPage}
                  className={`h-100 overflow-auto`}
                >
                  {allArticleIds?.map((id: any) => {
                    return (
                      <>
                        <div
                          className={`${styles.itemWrapper} ${
                            selectedArticles.includes(id) && styles.selectOne
                          }`}
                          key={id}
                        >
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={selectedArticles.includes(id)}
                              onChange={checkUncheckStatus}
                              value={id}
                              id={uuidRef.current + "article-filter" + id}
                            />
                            <label
                              className={`form-check-label ${styles.checktext}`}
                              htmlFor={uuidRef.current + "article-filter" + id}
                            >
                              {/* Display article name */}
                              {
                                allArticles?.find(
                                  (article) => article.articleId === Number(id)
                                )?.articleName
                              }
                            </label>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </InfiniteScroll>
              </div>
            </span>
          </div>
          {/* Clear all articles button */}
          <span className={`${styles.clearSpan}`} onClick={clearAllStatus}>
            Clear all
          </span>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default ArticleFilters;
