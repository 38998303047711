import { axiosJSON } from "src/globals/axiosEndPoints";
import {
  CallerData,
  CallerTypes,
} from "src/store/slices/callRecording/callRecording.declarations";

export const getCallRecording = async (params: {
  start: number;
  limit: number;
}) => {
  const { data: res } = await axiosJSON.post(
    `/api/callRecording/fetchCallRecordings`,
    params
  );
  if (res.err) {
    throw res.msg;
  }
  const callerData: any = {};
  const callerIdList: any = [];
  res.data.forEach((caller: any) => {
    callerData[caller.callerId] = caller;
    callerIdList.push(caller.callerId);
  });
  return { callerData, callerIdList, callerDataMeta: { ...res.metaData } } as {
    callerData: { [callerId: string | number]: CallerData };
    callerIdList: Array<number | string>;
    callerDataMeta: {
      current: number;
      totalCount: number;
    };
  };
};

export const getCallerTypes = async () => {
  const { data: res } = await axiosJSON.get(`/api/callRecording/getCallTypes`);
  if (res.err || res.error) {
    throw res.message;
  }
  const allCallerTypes: any = {};
  const allCallerTypeIds: any = [];
  res.data.forEach((type: any) => {
    allCallerTypes[type.id] = type;
    allCallerTypeIds.push(type.id);
  });
  return { allCallerTypes, allCallerTypeIds } as {
    allCallerTypes: { [id: number | string]: CallerTypes };
    allCallerTypeIds: Array<number | string>;
  };
};
