/**
 * This file defines a React component responsible for rendering form elements,
 * handling CRUD operations form elements, implementing drag-and-drop functionality,
 * and managing the process of saving and discarding changes in the bot configuration form.
 * 
 * @author @yuvaraj-busibud
 * @author @navjyot-busibud
 */
import React, { useState } from "react";
import styles from "./FormModal.module.scss";
import dots from "src/assets/images/sixDots.png";
import { Dropdown, Spinner } from "react-bootstrap";
import textLogo from "src/assets/images/text.png";
import ElementText from "src/routes/LiveChat/Children/ChatSetting/Children/Forms/FormElements/Text/ElementText";
import ElementChoiceList from "src/routes/LiveChat/Children/ChatSetting/Children/Forms/FormElements/ChoiceList/ElementChoiceList";
import ElementMultiChoiceList from "src/routes/LiveChat/Children/ChatSetting/Children/Forms/FormElements/MultiChoiceList/ElementMultiChoiceList";
import ElementDropDown from "src/routes/LiveChat/Children/ChatSetting/Children/Forms/FormElements/DropDown/ElementDropDown";
import useConfigureForm from "../hooks/useConfiureForm";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Loader from "src/components/Loader";

interface Props {
  onHide: (e: any) => void;
  profileId: number;
  onSaveComplete: () => void
}

const elementTypesList: {
  [key: string]: { component: any };
} = {
  //
  information: { component: ElementText },
  email: { component: ElementText },
  text: { component: ElementText },
  longText: { component: ElementText },
  choiceList: { component: ElementChoiceList },
  multipleChoiceList: { component: ElementMultiChoiceList },
  dropDown: { component: ElementDropDown },
};

const FormModal = ({ onHide, profileId, onSaveComplete }: Props) => {
  const {
    handleNewElement,
    handleCancelButton,
    handleElementChange,
    formElements,
    elementOptions,
    handleElementDelete,
    handleSetFormElements,
    updateConfigLoading,
    handleSubmit,
    isFormElementOptionsLoading,
    isFormDataLoading,
    saveButtonText,
    isChange,
  } = useConfigureForm(profileId, onSaveComplete);
  const [showDropdown, setShowDropdown] = useState(false);
  return (
    <div>
      <div className="d-flex align-items-center pt-3">
        <div className={`${styles.closeModal}`} onClick={onHide}>
          <span className="cursor-pointer">
            <i className="fa-solid fa-xmark"></i>
          </span>
        </div>
        <h3 className={`mb-0 ms-2 ${styles.heading}`}>Configure Form</h3>
      </div>
      {(isFormElementOptionsLoading || isFormDataLoading) ? (
      <Loader />
      ) : (
      <>
      <div className={`${styles.mainContent}`}>
        {/* <div className={`p-3 mb-2 ${styles.shadowBox}`}>
          <div
            className={`d-flex justify-content-between align-items-center mb-2`}
          >
            <div>
              <img src={dots} alt="dots" className={`${styles.dots}`} />
              <span className={`ps-2 ${styles.eleName}`}>Name</span>
            </div>
            <div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                />
                <span className={`pe-2 ${styles.required}`}>Required</span>
                <span
                  className={`${styles.arrow}`}
                  onClick={() => {
                    setShowForm(!showForm);
                  }}
                >
                  <i
                    className={`fa-solid fa-chevron-${
                      showForm ? "up" : "down"
                    }`}
                  ></i>
                </span>
              </div>
            </div>
          </div>
          {showForm ? (
            <div className="mt-2">
              <div className="mb-2">
                <label
                  htmlFor="exampleFormControlInput1"
                  className={`form-label ${styles.formLabel}`}
                >
                  Label
                </label>
                <input
                  type="text"
                  className={`form-control ${styles.inputBox}`}
                  id="exampleFormControlInput1"
                  placeholder="Name"
                />
              </div>
            </div>
          ) : (
            ""
          )}
        </div> */}
        <DragDropContext
          onDragEnd={(param: any) => {
            const srcI = param.source.index;
            const desI = param.destination?.index;
            if (desI !== undefined && desI !== null) {
              const updatedList = [...formElements];
              updatedList.splice(desI, 0, updatedList.splice(srcI, 1)[0]);
              handleSetFormElements(updatedList);
            }
          }}
        >
          <Droppable droppableId="droppable-1">
            {(provided: any, _: any) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className={`${styles.overIssue} m-2`}
              >
                {formElements.map((element, i) => {
                  const ShowComp =
                    elementTypesList[element.elementType]?.component;
                  const hideDelete = formElements.length === 1;
                  if (!ShowComp) {
                    return;
                  }
                  return (
                    <Draggable
                      key={element.uniqueId}
                      draggableId={"draggable-" + element.uniqueId}
                      index={i}
                    >
                      {(provided: any, snapshot: any) => (
                        <ShowComp
                          customProvided={provided}
                          element={element}
                          handleElementDelete={handleElementDelete}
                          handleElementChange={handleElementChange}
                          hideDelete={hideDelete}
                          initialShowInput={true}
                          overflowProp={true}
                        />
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <div className={`d-flex pt-2`}>
          <Dropdown show={showDropdown} onToggle={()=>setShowDropdown(!showDropdown)}>
            <Dropdown.Toggle
              as="div"
              id="dropdown-basic"
              bsPrefix={`cursor-pointer  me-2 d-flex align-items-center dropdown-toggle ${styles.add}`}
            >
              <span className={`${styles.addBox}`}>
                {" "}
                <i className="fa-solid fa-plus"></i>
              </span>
            <span className={`${styles.addElement} ps-2`}>Add Element</span>

            </Dropdown.Toggle>

            <Dropdown.Menu bsPrefix={`dropdown-menu ${styles.dropBox}`}>
              {elementOptions?.map((option: any) => {
                return (
                  <li>
                    <span
                      onClick={(e) => {
                        handleNewElement(option.elementId);
                        if (option.isAlreadyExists !== true){
                          setShowDropdown(false);
                        }
                      }}
                      className={
                        option.isAlreadyExists === true
                          ? `dropdown-item ${styles.dropItem}`
                          : `dropdown-item ${styles.dropItem2}`
                      }
                    >
                      <img
                        src={textLogo}
                        className={`${styles.textLogo}`}
                        alt=""
                      />
                      {option.elementLabel}
                    </span>
                  </li>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
          {/* <div className="d-flex align-items-center">
            <span className={`${styles.addElement}`}>Add Element</span>
          </div> */}
        </div>
      </div>
      <div
            className={`d-flex justify-content-end align-items-center px-3 ${styles.btnWrapper}`}
          >
            <button 
              className={`me-2 ${styles.discardBtn}`} 
              onClick={handleCancelButton}
              disabled={updateConfigLoading || isChange === false}
              >
              Discard changes
            </button>
            <button
              className={`${styles.saveBtn}`}
              disabled={updateConfigLoading || saveButtonText === "Saved!" || isChange === false}
              onClick={handleSubmit}
            >
              {updateConfigLoading ? (
                <>
                  <Spinner
                    className="my-auto mx-1"
                    animation="border"
                    color="white"
                    size="sm"
                  />
                  <span>Saving...</span>
                </>
              ) : (
                saveButtonText
              )}
            </button>
          </div>
      </>
      )}
    </div>
  );
};

export default FormModal;
