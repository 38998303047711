import styles from "./TableRow.module.scss";
import {
  CSVTableVarientImage,
  VarientData,
} from "src/features/ReturnAutoWorkFlow/ReturnAutoWorkFlow.types";
import VarientImage from "../ImageUpload/VarientImage";

const TableRow = ({
  varientData,
  integrationId,
}: {
  varientData: VarientData;
  integrationId: string;
}) => {
  return (
    <div className={`d-flex align-items-center px-2 mb-2 ${styles.tableHead}`}>
      <div className="form-check ms-1">
        <input
          className="form-check-input"
          type="checkbox"
        />
      </div>
      <div className={`${styles.tableHead}`}>
        {varientData?.rowData?.map((row) => {
          return (
            <div
              className={`${styles.value} ${
                row?.type === "varientImage" ? styles.varientImg : ""
              }`}
            >
              {row?.type === "varientImage" ? (
                <div className={`${styles.tablePara} text-truncate`}>
                  <VarientImage
                    value={row?.value as CSVTableVarientImage | null}
                    productId={varientData?.varientId ?? ""}
                    integrationId={integrationId}
                  />
                </div>
              ) : (
                <div className={`${styles.tablePara} text-truncate`}>
                  {(row?.value as string) ?? ""}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TableRow;
