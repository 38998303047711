import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Loader from "src/components/Loader";
import SearchBar from "src/components/SearchBar/SearchBar";
import { useAppDispatch, useAppSelector } from "src/store/store";
import styles from "./CannedResponseBox.module.scss";
import {
  fetchAllCannedResponseInfiScroll,
  resetCannedResponse,
  setCannedResponseSearchValue,
} from "src/store/slices/liveChatSetting/cannedResponse/cannedResponse.slice";
import ShortCutMessage from "./children/ShortCutMessage/ShortCutMessage";
import InfiniteScroll from "src/components/InfiniteScrollBothSide";

interface Props{
  selectedTemplate: (value:string)=>void
}
const CannedResponseBox = ({selectedTemplate}:Props) => {
  const chatIntegrationId = useAppSelector((state)=>state.chatWidExhMsg.activeCustomerInfo?.integrationId);

  const dispatch = useAppDispatch();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const {
    cannedResponses,
    cannedResponseIdList,
    metaData,
    cannedResponseAjaxStatus,
  } = useAppSelector((state) => state.cannedResponses);

  const currentState = useRef({
    cannedResponseAjaxStatus,
    cannedResponses,
    cannedResponseIdList,
    metaData,
  });

  useEffect(() => {
    currentState.current = {
      cannedResponseAjaxStatus,
      cannedResponses,
      cannedResponseIdList,
      metaData,
    };
  }, [
    cannedResponseAjaxStatus,
    cannedResponses,
    cannedResponseIdList,
    metaData,
  ]);

  useEffect(() => {
    dispatch(resetCannedResponse());
  }, []);

  /* method to handle infinite scroll */
  const handleInfiniteScroll = useCallback(() => {
    if (
      currentState.current.metaData.total !== null &&
      currentState.current.cannedResponseAjaxStatus === "fulfilled"
    ) {
      if (currentState.current.metaData.total > 0) {
        dispatch(fetchAllCannedResponseInfiScroll(chatIntegrationId+""));
      }
    }
  }, [metaData.total]);


  const handleSearch = (value:string) => {
    dispatch(resetCannedResponse());
    dispatch(setCannedResponseSearchValue({ searchValue: value }));
    dispatch(fetchAllCannedResponseInfiScroll(chatIntegrationId+""));
  };

  const error = useMemo(() => {
    return cannedResponseIdList.length === 0 &&
      (cannedResponseAjaxStatus === "rejected" ||
        cannedResponseAjaxStatus === "fulfilled") ? (
      <div className="mt-2 text-center">
        <span className="py-1">No Result Found</span>
      </div>
    ) : undefined;
  }, [metaData.total, cannedResponseIdList, cannedResponseAjaxStatus]);

  const hasMore = useMemo(() => {
    return cannedResponseAjaxStatus === "rejected"
      ? false
      : metaData.total === null
      ? false
      : cannedResponseIdList.length < metaData.total;
  }, [cannedResponseAjaxStatus, metaData.total, cannedResponseIdList.length]);

  const loader = useMemo(() => {
    return cannedResponseAjaxStatus === "pending" &&
      cannedResponseIdList.length === 0 ? (
      <div className={`${styles.loading}`}>
        <Loader />
      </div>
    ) : undefined;
  }, [cannedResponseAjaxStatus, cannedResponseIdList.length]);

  return (
    <div className="p-1 h-100 d-flex flex-column">
      <div className="mx-2 mt-3">
        <SearchBar
          className={`${styles.search} mb-1`}
          inputClassName={`${styles.input}`}
          value={searchTerm}
          onChange={(e: any) => setSearchTerm(e.target.value)}
          placeholder={`Search shortcut or responses`}
          onSearch={(value:string)=>{
            handleSearch(value);
          }}
        />
      </div>
      <InfiniteScroll
        className={`${styles.dropDownScroll}`}
        loadMoreFromBottom={handleInfiniteScroll}
        hasMoreBottom={hasMore}
      >
        {loader ? (
          loader
        ) : error ? (
          error
        ) : (
          <div className="d-flex flex-column justify-content-between p-2 mt-0">
            {cannedResponseIdList.map((cannedResponseId, index) => {
              const cannedResponseData = cannedResponses[cannedResponseId];
              return (
                <ShortCutMessage
                  key={cannedResponseId}
                  cannedResponseId={cannedResponseId}
                  shortcutKeyText={cannedResponseData.shortcut}
                  messageText={cannedResponseData.messageText}
                  selectedTemplate={selectedTemplate}
                />
              );
            })}
          </div>
        )}
      </InfiniteScroll>
    </div>
  );
};
export default CannedResponseBox;
