import { useState, useEffect, useCallback, useRef } from "react";

import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { IUpdateKnowledgeBasedParams, updateChatSetting } from "src/services/LiveChat/Settings/updateChatSettingData";
import {
  fetchAllFeaturedArticles,
  fetchKnowledgeBasedData,
  resetFeaturedArticlesData,
} from "src/store/slices/liveChatSetting/chatWidgetSetting/knowledgeBased/knowledgeBasedSetting.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";

interface Props {
  integrationId?: number|string;
  fetchLimit?: number;
}
/**
 * custom hook to fetch featured articles and return featured article data
 */
function useFetchFeaturedArticles({ integrationId, fetchLimit = 15 }: Props) {
  const [initialFetchLoading, setInitialFetchLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [minimizedViewEnabled, setMinimizedViewEnabled] = useState(false);

  const {
    featuredArticlesIds,
    featuredArticles,
    featuredArticleMeta,
    fetchFeaturedArticlesAjaxStatus,
    knowledgeBasedData
  } = useAppSelector((state) => state.knowledgeBasedSettings);

  const currentState = useRef({
    featuredArticlesIds,
    featuredArticles,
    featuredArticleMeta,
    fetchFeaturedArticlesAjaxStatus,
    initialFetch: false
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    if(integrationId){
      //resetting previous data
      dispatch(resetFeaturedArticlesData());
      //fetching all featured articles on load
      dispatch(fetchKnowledgeBasedData(integrationId));
      dispatch(
        fetchAllFeaturedArticles({ integrationId, start: 0, limit: fetchLimit })
      );
      currentState.current.initialFetch = true;
    }

  }, [integrationId]);

  useEffect(()=>{
    if(knowledgeBasedData?.featuredArticlesMinimizedView !== undefined){
      if(knowledgeBasedData.featuredArticlesMinimizedView){
        setMinimizedViewEnabled(true);
      }else{
        setMinimizedViewEnabled(false);
      }
    }

  },[knowledgeBasedData?.featuredArticlesMinimizedView])

  const handleInfiniteScroll = useCallback(() => {
    if (integrationId && currentState.current.fetchFeaturedArticlesAjaxStatus !== "pending") {
      dispatch(
        fetchAllFeaturedArticles({
          integrationId: integrationId,
          start: currentState.current.featuredArticlesIds.length,
          limit: fetchLimit,
        })
      );
    }
  }, [integrationId, fetchLimit]);

  useEffect(() => {

    if (fetchFeaturedArticlesAjaxStatus === "pending") {
      setLoading(true);
      if(currentState.current.initialFetch === true){
        setInitialFetchLoading(true);
        currentState.current.initialFetch = false;
      }
    } else {
      setInitialFetchLoading(false);
      setLoading(false);
    }
  }, [fetchFeaturedArticlesAjaxStatus]);

  useEffect(() => {
    currentState.current = {
      featuredArticlesIds,
      featuredArticles,
      featuredArticleMeta,
      fetchFeaturedArticlesAjaxStatus,
      initialFetch: currentState.current.initialFetch
    };
  }, [
    featuredArticlesIds,
    featuredArticles,
    featuredArticleMeta,
    fetchFeaturedArticlesAjaxStatus,
  ]);

  //helper function to checks if there is more featuredArticles needs to be fetched or not , used for infinite scroll
  const hasMoreFeaturedArticles = useCallback(() => {
    return currentState.current.fetchFeaturedArticlesAjaxStatus === "rejected"
      ? false
      : currentState.current.featuredArticleMeta.total === null
      ? true
      : currentState.current.featuredArticlesIds.length < currentState.current.featuredArticleMeta.total;
  }, []);

  const handleEnableMinimizedWindow = useCallback((event: any, setShowSaveLoader:Function) => {
   
    let isChecked = event.target.checked;
    setShowSaveLoader(true);
    let payload: IUpdateKnowledgeBasedParams = {
      featuredArticlesMinimizedView: isChecked,
    };

    updateChatSetting({
      integrationId: parseInt(integrationId+""),
      knowledgeBased: payload,
    })
      .then((res) => {
        setShowSaveLoader(false);
        setMinimizedViewEnabled(isChecked);
        pushTheToast({
          type: "success",
          text:
            isChecked === true
              ? "Enabled minimised view!"
              : "Disabled minimised view!",
          position: "top-right",
        });
      })
      .catch((err) => {
        setShowSaveLoader(false);
        pushTheToast({
          type: "danger",
          text: "Something went wrong!",
          position: "top-right",
        });
      });
  },[integrationId]);

  return {
    featuredArticlesIds,
    featuredArticles,
    loading,
    hasMoreFeaturedArticles,
    handleInfiniteScroll,
    initialFetchLoading,
    handleEnableMinimizedWindow,
    minimizedViewEnabled
  };
}

export default useFetchFeaturedArticles;