import { MdOutlineContentCopy } from "react-icons/md";
import styles from "./../StoreDetail/StoreDetail.module.scss";
import { useAppSelector } from "src/store/store";
import { useCallback, useState } from "react";

/**
 * Component for managing Copy code and embed to website step
 */
const CopyCode = () => {
  const { embedded_script } = useAppSelector(
    (state) => state.websiteSettings.activeWebIntegration
  );

  /**
   * Handler for copying embedded script
   */
  const copyHandler = useCallback(async () => {
    if (embedded_script) {
      try {
        await window.navigator.clipboard.writeText(embedded_script);
        setCopied(true);
        await new Promise((res) => setTimeout(res, 3000));
      } catch (err) {
        console.error(err);
      } finally {
        setCopied(false);
      }
    }
  }, [embedded_script]);

  // Copied states
  const [copied, setCopied] = useState(false);

  return (
    <div className="d-flex mt-5" id="copyCodeWrapper">
      {/* Step Number */}
      <div className={`${styles.pointsDiv} small mt-0 me-1`}>2</div>

      {/* Step */}
      <div className={`${styles.rightSection}`}>
        {/* Message */}
        <div className={`m-auto ms-1 ${styles.storeDet}`}>
          Copy and paste this code before the closing{" "}
          <code style={{ color: "#12C235" }}>&lt;/body&gt; </code>
          tag on every page of your website.
        </div>

        {/* Script Box */}
        <div className={`my-2 ${styles.code}`}>
          <div className={`${styles.breakScript}`}>{embedded_script}</div>

          {/* Copy Button */}
          <button
            className={`${styles.installBtn} ${
              copied && styles.copied
            } mt-3 w-auto px-2`}
            onClick={copyHandler}
            id="copyCodeBtn"
          >
            {copied ? (
              <>
                <i className="fa-solid fa-check"></i> Copied!
              </>
            ) : (
              <>
                <MdOutlineContentCopy /> Copy Code
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CopyCode;
