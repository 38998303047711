import React from "react";
import styles from "./LogoutModal.module.scss";
import { Spinner } from "react-bootstrap";
interface Props {
  onHide: (e: any) => void;
  handleLogoutFromAllSession: () => void;
  isLoading: boolean;
}
const LogoutModal = ({
  onHide,
  handleLogoutFromAllSession,
  isLoading,
}: Props) => {
  return (
    <div>
      <h3 className={`text-center mb-4 ${styles.heading}`}>
        Would you like to logout this user?
      </h3>
      <div
        className={`d-flex justify-content-center align-items-center ${styles.btnWrapper}`}
      >
        <button className={`${styles.cancelBtn}`} onClick={onHide}>
          Cancel
        </button>
        <button
          className={`ms-0 ms-md-2 mt-2 mt-md-0 ${styles.confirmBtn}`}
          onClick={handleLogoutFromAllSession}
          disabled={isLoading}
        >
          {isLoading ? (
            <Spinner
              className="my-auto mx-1"
              animation="border"
              color="white"
            />
          ) : (
            "Confirm"
          )}
        </button>
      </div>
    </div>
  );
};

export default LogoutModal;
