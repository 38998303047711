import { axiosJSON } from "src/globals/axiosEndPoints";
import APIResponse from "./APIReponse";
import { FreePlanError, FreePlanErrorMessage } from "src/globals/constants";

export interface AddUser {
  userId: number | string;
  inviteUrl: null | null;
}
export type Phone = {
  countryCode: string;
  phoneNumber: string;
};
export interface AddUserParams {
  email: string;
  phone: Phone | null;
  userRole: number;
  teams: Array<number>;
}

export async function addUser(params: AddUserParams) {
  const { data: res } = await axiosJSON.post("/api/setting/user/add", params);

  if (res.error === true) {
    if (
      (res?.status_code && res?.status_code?.includes("role_not_found")) ||
      res?.status_code?.includes("team_not_found") ||
      res?.status_code?.includes("email_already_exist") ||
      res?.status_code?.includes("user_not_found")
    ) {
      throw new Error(getErrorMessage(res.status_code));
    } else if (res.statusCode === FreePlanError.USER_LIMIT_REACHED) {
      throw new Error(
        res.message ?? FreePlanErrorMessage[FreePlanError.USER_LIMIT_REACHED],
      );
    } else {
      throw new Error(res.message as string);
    }
  }

  return res.data as AddUser;
}

export function getErrorMessage(statusCode: string): string {
  switch (statusCode) {
    case "role_not_found":
      return "Role not found.";
    case "team_not_found":
      return "Team not found.";
    case "email_already_exist":
      return "Email already exists.";
    case "user_not_found":
      return "User not found.";
    default:
      return "Something went wrong";
  }
}
