import { ITagsSettings } from "./tagsSettings.slice";

export const fetchAllTagsFulfilled = (
  state: ITagsSettings,
  { payload }: any
) => {
  const {
    tags,
    tagIdList,
    metaData
  }: any = payload as any;

  state.tags = {
    ...state.tags,
    ...tags
  };
  state.tagIdList = Array.from(new Set([
    ...state.tagIdList,
    ...tagIdList
  ]));
  state.metaData.count = metaData.count;
  state.metaData.total = metaData.total
  state.tagsAjaxStatus = "fulfilled";

}
export default {fetchAllTagsFulfilled};
