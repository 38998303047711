import React from "react";
import BrandDropdown from "./BrandDropdown/BrandDropdown";
import styles from "./KbBrandModal.module.scss";
import { useMutation } from "@tanstack/react-query";
import { createKnowledgeBase } from "src/services/KnowledgeBase/createKnowledgeBase.service";
import Loader from "src/components/Loader";
interface Props {
  onHide: () => void;
  refetchAllKb: () => void;
}
const KbBrandModal = ({ onHide, refetchAllKb }: Props) => {
  const [selectedBrand, setSelectedBrand] = React.useState<any>(undefined);

  const createKb = useMutation({
    mutationFn: createKnowledgeBase,
  });

  const [showValidation, setShowValidation] = React.useState(false);

  return (
    <>
      <div className={`d-flex align-items-center justify-content-between`}>
        <h4 className={`mb-0 ${styles.modalHead}`}>Choose a brand</h4>
        <span className={`${styles.closeBtn}`} onClick={onHide}>
          <i className="fa-solid fa-xmark"></i>
        </span>
      </div>
      <div className="mt-4">
        <p className={`mb-1 ${styles.subHeading}`}>
          This will appear in your Knowledge base for the following brands:
        </p>
        <BrandDropdown
          selectedBrand={selectedBrand}
          setSelectedBrand={setSelectedBrand}
          showValidation={showValidation}
          setShowValidation={setShowValidation}
        />
        {showValidation && (
          <span className={`${styles.validationError}`}>
            Please select a Brand
          </span>
        )}
        <div className="d-flex justify-content-end mt-5">
          <button
            className={`px-3 ${styles.nextBtn}`}
            onClick={() => {
              if (selectedBrand) {
                createKb.mutate(
                  {
                    brandId: selectedBrand.value,
                  },
                  {
                    onSuccess: () => {
                      onHide();
                      refetchAllKb();
                    },
                  }
                );
              } else {
                setShowValidation(true);
              }
            }}
          >
            Next{" "}
            {createKb.isLoading && (
              <>
                <span
                  className="spinner-border spinner-border-sm ms-2"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Loading...</span>
              </>
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default KbBrandModal;
