import { RootState } from "src/store/store";

export function isArticleSelected(
  state: RootState,
  articleId: number | string
) {


  if (state.knowledgeBasedSettings.activeFeaturedArticle.featuredArticles[articleId] !== undefined) {
    return true;
  }

  return false;
}
