import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import styles from "./HelpdeskNotificationBar.module.scss";
import { useAppSelector } from "src/store/store";
import {
  getVersionFromLocalStorage,
  setVersionToLocalStorage,
} from "src/utils/utils";
import reloadImg from "src/assets/images/reloadImg.png";

/**
 * Notification component for displaying version information and providing a button to refresh the page.
 * @param {Object} props - The properties for the component.
 * @param {string} props.version - The version information to be displayed.
 */
const VersionNotification = ({ version }: { version: string }) => {
  /**
   * Handles the click event on the refresh button.
   */
  const handleOnClick = useCallback(async () => {
    if ("caches" in window) {
      // Service worker cache should be cleared with caches.delete()
      const cacheKeys = await window.caches.keys();
      await Promise.all(
        cacheKeys.map((key) => {
          window.caches.delete(key);
        }),
      );
    }

    setVersionToLocalStorage(version);
    // clear browser cache and reload page
    window.location.replace(window.location.href);
  }, [version]);

  return (
    <div className={`${styles.modalWrapper}`}>
      <h4 className={`${styles.heading}`}>New version available</h4>
      <span className={`${styles.subHeading}`}>
        You are using an outdated version of Saufter. Please click on the
        'Refresh and Update' button below to load the latest version.
      </span>
      <button
        className={`${styles.reloadBtn}`}
        id="refreshBtn"
        onClick={handleOnClick}
      >
        <img
          src={reloadImg}
          alt=""
          className="me-2"
        />
        Refresh and Update
      </button>
    </div>
  );
};

/**
 * HelpdeskNotificationBar component for displaying version notifications.
 */
const HelpdeskNotificationBar = () => {
  const [show, setShow] = useState(false);
  const currentVersion = useAppSelector(
    (state) => state.globals.currentUserData?.version,
  );
  const initialVersionRef = useRef(getVersionFromLocalStorage());

  const [localVersion, setLocalVersion] = useState(() => {
    return getVersionFromLocalStorage();
  });

  useEffect(() => {
    if (
      initialVersionRef.current &&
      currentVersion &&
      initialVersionRef.current != currentVersion
    ) {
      setShow(true);
    }

    if (!initialVersionRef.current && currentVersion) {
      initialVersionRef.current = currentVersion;
      setVersionToLocalStorage(currentVersion);
    }
  }, [localVersion, currentVersion]);

  const storageEvent = useCallback(() => {
    let localStorageVersion = getVersionFromLocalStorage();
    if (
      localStorageVersion &&
      localStorageVersion !== initialVersionRef.current
    ) {
      setShow(true);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("storage", storageEvent);
    return () => {
      window.removeEventListener("storage", storageEvent);
    };
  }, []);

  return (
    <>
      {currentVersion && show && (
        <Modal
          style={{
            zIndex: 99999,
            backdropFilter: "blur(2px)",
            WebkitBackdropFilter: "blur(2px)",
          }}
          backdropClassName={`${styles.modalBack}`}
          show={show}
          onHide={() => {}}
          dialogClassName={`${styles.modalDialog}`}
          contentClassName={`${styles.modalContent}`}
          centered={true}
        >
          <VersionNotification version={currentVersion} />
        </Modal>
      )}
    </>
  );
};

export default HelpdeskNotificationBar;
