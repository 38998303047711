import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import { useAppSelector } from "src/store/store";
import { validateEmail } from "src/utils/utils";
import { v4 as uuidV4 } from "uuid";

export const initialFields = {
  otherEmails: [],
  phoneNumber: [],
  whatsapp: [], //Adding whatsapp field
  instagram: [],
  facebook: [],
  twt: [],
};

interface DuplicateReturnType {
  hasDuplicates: boolean;
  duplicateIds: string[];
}

interface DuplicateFields {
  duplicates: Record<string, DuplicateReturnType>;
  hasDuplicates: boolean;
}

export const initialInputFieldsContext = {
  fields: new Map() as Map<any, any[]>,
  addField(field: any, fieldsKey: string) {},
  setFieldsOfKey(fields: any[], fieldsKey: string) {},
  removeField(field: any, fieldsKey: string) {},
  updateField(prevField: any, newField: any, fieldsKey: string) {},
  areFieldsSame: true,
  areDuplicateFields: {
    duplicates: {},
    hasDuplicates: false,
  } as DuplicateFields,
  primaryEmail: "",
  setPrimaryEmail: (value: string) => {},
};

export const InputFieldsContext = React.createContext(
  initialInputFieldsContext,
);

/**
 * Helper function for finding duplicates in phone numbers of Intl-tel-input
 */
function findDuplicates(numbers: any[]): DuplicateReturnType {
  const phoneNumberToIds: Record<string, string[]> = {};
  const duplicateIds: string[] = [];

  numbers.forEach((numberInfo) => {
    const phoneNumber = numberInfo.value.phoneNumber;
    const id = numberInfo.id;

    if (phoneNumberToIds[phoneNumber]) {
      // Phone number already encountered, add the current ID to duplicateIds
      duplicateIds.push(id);
      // Add the current ID to the list of IDs for this phone number
      phoneNumberToIds[phoneNumber].push(id);
    } else {
      // First time encountering this phone number, initialize the array of IDs
      phoneNumberToIds[phoneNumber] = [id];
    }
  });

  const hasDuplicates = duplicateIds.length > 0;
  return { hasDuplicates, duplicateIds };
}

/**
 * Helper for finding duplicate fields
 */
function findDuplicateFields(
  fields: any[],
  primaryEmail?: string,
): DuplicateReturnType {
  const duplicateFieldDict: Record<string, string[]> = {};
  const duplicateIds: string[] = [];

  if (primaryEmail) {
    duplicateFieldDict[primaryEmail] = ["primaryEmail"];
  }

  fields.forEach((field) => {
    const value = field.value;
    const id = field.id;

    // Check if value is already present in dictionary
    if (duplicateFieldDict[value]) {
      // Found duplicate
      duplicateIds.push(id);
      duplicateFieldDict[value].push(id);
    } else {
      // Not Encountered
      // Add to dictionary
      duplicateFieldDict[value] = [id];
    }
  });
  return { hasDuplicates: duplicateIds.length > 0, duplicateIds };
}

function useInputFields<T>(
  initialFields: any,
  /* onChanged: (
    changeType: "added" | "removed" | "updated",
    field: T & { id: string }
  ) => void */
) {
  type FieldItem = { value: T; id: string; isValid: boolean };
  type PartialFieldItem = Partial<{
    id: string;
    value: Partial<T>;
    isValid: boolean;
  }>;

  const [fields, setFields] = useState(() => {
    const fieldsMap = new Map<string, T[] | FieldItem[]>(
      Object.entries(initialFields),
    );
    fieldsMap.forEach((values, key) => {
      fieldsMap.set(
        key,
        values.map((_value) => {
          return {
            value: _value,
            id: uuidV4(),
          } as FieldItem;
        }),
      );
    });
    return fieldsMap as Map<string, FieldItem[]>;
  });

  const [oldFields, setOldFields] = useState(() => {
    const fieldsMap = new Map<string, T[] | FieldItem[]>(
      Object.entries(initialFields),
    );
    fieldsMap.forEach((values, key) => {
      fieldsMap.set(
        key,
        values.map((_value) => {
          return {
            value: _value,
            id: uuidV4(),
          } as FieldItem;
        }),
      );
    });
    return fieldsMap as Map<string, FieldItem[]>;
  });

  function addField(field: PartialFieldItem, fieldsKey: string) {
    const _fields = fields.get(fieldsKey);
    if (_fields !== undefined) {
      _fields.push({
        value: field.value,
        id: uuidV4(),
        isValid: false,
      } as FieldItem);
      fields.set(fieldsKey, _fields);
    }
    setFields(new Map(fields));
  }

  function setFieldsOfKey(fields: T[], fieldsKey: string) {
    setFields((_allFields) => {
      const _fields = _allFields.get(fieldsKey);
      if (_fields !== undefined) {
        const newFields = fields.map((value) => {
          return {
            value: value,
            id: uuidV4(),
            isValid: true,
          } as FieldItem;
        });
        _allFields.set(fieldsKey, newFields);
      }
      return new Map(_allFields);
    });
    setOldFields((_allFields) => {
      const _fields = _allFields.get(fieldsKey);
      if (_fields !== undefined) {
        const newFields = fields.map((value) => {
          return {
            value: value,
            id: uuidV4(),
            isValid: true,
          } as FieldItem;
        });
        _allFields.set(fieldsKey, newFields);
      }
      return new Map(_allFields);
    });
  }

  function removeField(field: FieldItem, fieldsKey: string) {
    setFields((_fields) => {
      let _field = _fields.get(fieldsKey);
      if (_field !== undefined) {
        _field = _field.filter((item) => item.id !== field.id);
        _fields.set(fieldsKey, _field);
      }
      return new Map(_fields);
    });
  }

  function updateField(
    prevField: FieldItem,
    newField: PartialFieldItem,
    fieldsKey: string,
  ) {
    if (prevField.id !== newField.id) {
      return;
    }

    setFields((_fields) => {
      const _field = _fields.get(fieldsKey);
      if (_field !== undefined) {
        const idx = _field.findIndex((item) => item.id === prevField.id);
        if (idx !== -1) {
          _field[idx] = { ...prevField, ...newField } as FieldItem;
          _fields.set(fieldsKey, _field);
        }
      }
      return new Map(_fields);
    });
  }

  const { email: customerEmail, primaryEmailEditable } = useAppSelector(
    (state) => state.editcustomerdetails.customer,
  );
  const [primaryEmail, setPrimaryEmail] = useState(customerEmail);

  useEffect(() => {
    setPrimaryEmail(customerEmail);
  }, [customerEmail, primaryEmailEditable]);

  const areFieldsSame = useMemo(() => {
    let isSame = true;
    const data = [...fields];
    for (let i = 0; i < data.length; i++) {
      const key = data[i][0];
      const value = data[i][1];
      const oldValue = oldFields.get(key);
      if (oldValue) {
        if (oldValue.length !== value.length) {
          isSame = false;
          break;
        } else {
          for (let j = 0; j < oldValue.length; j++) {
            if (
              JSON.stringify([oldValue[j].value]) !==
              JSON.stringify([value[j].value])
            ) {
              isSame = false;
              break;
            }
          }
          if (!isSame) {
            break;
          }
        }
      } else {
        isSame = false;
        break;
      }
    }

    if (primaryEmailEditable && primaryEmail != customerEmail) {
      isSame = false;
    }
    return isSame;
  }, [fields, oldFields, primaryEmail, customerEmail, primaryEmailEditable]);

  /**
   * Memoized Value for saving duplicate fields error state
   */
  const areDuplicateFields = useMemo(() => {
    // Initialize a map for storing duplicate states
    const duplicates: Record<string, DuplicateReturnType> = {};

    // Boolean to check if we have duplicates in any of the fields
    let hasDuplicates: boolean = false;

    // Loop over all fields to find duplicates
    fields.forEach((field, key) => {
      // Check if it is Phone number
      if (key === "phoneNumber" || key === "whatsapp") {
        // Call the handler for finding duplicate phone numbers
        const data = findDuplicates(field);
        // Assign duplicate data in the map
        duplicates[key] = data;

        // Only update it it is false so that we don't true state
        if (!hasDuplicates) {
          hasDuplicates = data.hasDuplicates;
        }
      } else {
        // Call handler for finding duplicate fields
        const data = findDuplicateFields(
          field,
          key === "otherEmails" ? primaryEmail : undefined,
        );
        // Assign duplicate data in the map
        duplicates[key] = data;

        // Only update it it is false so that we don't true state
        if (!hasDuplicates) {
          hasDuplicates = data.hasDuplicates;
        }
      }
    });

    // Return the Duplicate fields data
    return { duplicates, hasDuplicates };
  }, [fields, primaryEmail]);

  return {
    addField,
    updateField,
    removeField,
    setFieldsOfKey,
    fields,
    areFieldsSame,
    areDuplicateFields,
    primaryEmail,
    setPrimaryEmail,
  };
}

export default useInputFields;
