import { GetConfigModalParams } from "src/features/ReturnAutoWorkFlow/children/ConfigModals/oldReturnModals.types";
import { AntiRefundStrategiesReason } from "src/features/ReturnAutoWorkFlow/children/ConfigModals/store/slices/AntiRefundStrategiesSlice/antiRefundStrategies.slice";
import { axiosJSON } from "src/globals/axiosEndPoints";

async function getAntiRefundStrategiesService(params: GetConfigModalParams) {
  const { data: res } = await axiosJSON.get(
    "/api/setting/automation/refund/step/12/getRefundRequestList",
    { params },
  );

  if (res.err || res.error) {
    throw new Error(res.msg ?? res.message ?? "Some Error Occurred.");
  }

  return res.data as AntiRefundStrategiesReason[];
}

export default getAntiRefundStrategiesService;
