import styles from "./TimelineWork.module.scss";
interface TimelineWorkProps {
  type?:
    | "select"
    | "returnType"
    | "reason"
    | "returnInstruction"
    | "orderSummary"
    | "returnMethod";
}

interface StepConfig {
  steps: number[];
  activeStep: number;
  showBorderAfterLast: boolean;
  showBorderBeforeStart: boolean;
}

// Setp configurations
const stepsConfig: Record<string, StepConfig> = {
  default: {
    steps: [1, 2, 3, 4, 5, 6, 7],
    activeStep: 0,
    showBorderBeforeStart: false,
    showBorderAfterLast: false,
  },
  select: {
    steps: [1, 2, 3],
    activeStep: 1,
    showBorderBeforeStart: false,
    showBorderAfterLast: false,
  },
  returnType: {
    steps: [1, 2, 3, 4],
    activeStep: 4,
    showBorderBeforeStart: false,
    showBorderAfterLast: true,
  },
  reason: {
    steps: [2, 3, 4, 5],
    activeStep: 5,
    showBorderBeforeStart: true,
    showBorderAfterLast: true,
  },
  moreDetails: {
    steps: [3, 4, 5, 6],
    activeStep: 6,
    showBorderBeforeStart: true,
    showBorderAfterLast: true,
  },
  returnInstruction: {
    steps: [5, 6, 7, 8],
    activeStep: 8,
    showBorderBeforeStart: true,
    showBorderAfterLast: true,
  },
  returnMethod: {
    steps: [5, 6, 7, 8],
    activeStep: 8,
    showBorderBeforeStart: true,
    showBorderAfterLast: true,
  },
  orderSummary: {
    steps: [6, 7, 8, 9],
    activeStep: 9,
    showBorderBeforeStart: true,
    showBorderAfterLast: true,
  },
};

const getStepData = (type: string) => {
  const config = stepsConfig[type || "default"];
  return config?.steps?.map((step) => ({
    number: step,
    active: step === config?.activeStep,
    showBorderAfterLast: config?.showBorderAfterLast ? true : false,
    showBorderBeforeStart: config?.showBorderBeforeStart ? true : false,
  }));
};

const getStepLabel = (step: number, type?: string): string => {
  const labels: Record<number, string> = {
    1: "Select order",
    2: "Select Action",
    3: "Add return items",
    4: "Select return type",
    5: "Reason for return",
    6: "More details",
    7: "Return Summary",
    8: "Return Method",
    9: "Order Summary",
  };
  return labels[step] || "";
};

const TimelineWork = ({ type }: { type: string }) => {
  const stepData = getStepData(type);

  return (
    <div className="row my-3">
      <div className="col-12">
        <div className="d-flex align-items-center justify-content-center">
          {stepData &&
            stepData?.map((step, index) => (
              <div key={index}>
                <div className="d-flex align-items-center">
                  {/* Adding dotted line before start of steps if index is 0 */}
                  {index === 0 && step.showBorderBeforeStart && (
                    <div
                      className={`${styles.dashBorder} ${styles.clip}`}
                    ></div>
                  )}

                  <span
                    className={`d-flex justify-content-center align-items-center ${
                      styles.step
                    } ${step.active ? styles.active : ""}`}
                  >
                    {step.number}
                  </span>

                  {/* Adding dotted line after end of steps if index is last or in the middle of steps */}
                  {(index < stepData.length - 1 ||
                    step.showBorderAfterLast) && (
                    <div
                      className={`${styles.dashBorder} ${
                        index === stepData.length - 1 &&
                        step.showBorderAfterLast
                          ? styles.clip
                          : ""
                      }`}
                    ></div>
                  )}
                </div>

                <div>
                  {/* Stel label rendering with active and inactive styles */}
                  <span
                    className={`${styles.selSpan}  ${
                      step.active ? styles.active : ""
                    } ${
                      step.showBorderBeforeStart && index === 0
                        ? styles.clipMargin
                        : ""
                    }`}
                  >
                    {getStepLabel(step.number, type)}
                  </span>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default TimelineWork;
