import AddTemplateButton from "./Children/AddTemplateButton";
import DropdownGroup from "./Children/DropdownGroup";
import styles from "./LiveSendingAs.module.scss";
import AddNoteIcon from "src/assets/images/note-add.png";
import AddNoteActiveIcon from "src/assets/images/note-add-active.png";
import { useState } from "react";
import { useAppSelector } from "src/store/store";
import { SOCIAL_CHANNELS } from "src/services/LiveChat/messageExchange/getAllCustomers";
import { EChannel } from "src/enums/EChannel";
import { ReplyOption } from "src/routes/Ticket/children/MainChat/children/SendMessage/children/SendingAsV2";

export enum ESendTypes {
  Message = 1,
  Note,
}

interface Props {
  sendType: ESendTypes;
  setSendType: (sendType: ESendTypes) => void;
  showReply?: boolean;
  selectedTemplate?: (templateText: string) => void;
  showAddTemplates?: boolean;
  channel?: EChannel;
  replyOption?: ReplyOption;
  setReplyOption?: (value: ReplyOption) => void;
  disableChannel?: boolean;
  allowedChannels?: Array<EChannel>;
  setChannel?: (value: EChannel) => void;
}

function LiveSendingAs({
  sendType,
  setSendType,
  showReply = true,
  selectedTemplate = (templateText: string) => {},
  showAddTemplates = false,
  channel,
  allowedChannels = [EChannel.email],
  replyOption = ReplyOption.Reply,
  setChannel = () => {},
  setReplyOption = () => {},
  disableChannel,
}: Props) {
  const [noteIcon, setNoteIcon] = useState(AddNoteIcon);

  return (
    <div
      className={`d-flex ${styles.sendingAs} justify-content-between my-1 flex-wrap`}
    >
      <div className={`my-auto d-flex`}>
        {channel && ESendTypes.Message === sendType && (
          <DropdownGroup
            allowedChannels={allowedChannels}
            replyOption={replyOption}
            setReplyOption={setReplyOption}
            disableChannel={disableChannel}
            showReply={showReply}
            channel={channel}
            setChannel={setChannel}
          />
        )}
        {ESendTypes.Note === sendType && (
          <div
            className={`px-2 ${styles.share}`}
            onClick={(e) => {
              setSendType(ESendTypes.Message);
              setNoteIcon(AddNoteIcon);
            }}
          >
            <i
              className={`fa-sharp fa-solid fa-share fa-flip-horizontal fa-xs ${
                sendType === ESendTypes.Note ? styles.active : ""
              }`}
            ></i>
            <span
              className={`ms-1 ${
                sendType === ESendTypes.Note ? styles.active : ""
              }`}
            >
              Send Response
            </span>
          </div>
        )}
        {/* {showReply && (
          <>
            <div
              className={`my-auto d-flex ${styles.button} ${
                sendType === ESendTypes.Message ? styles.active : ""
              }`}
              onClick={(e) => {
                setSendType(ESendTypes.Message);
              }}
            >
              <span className={`my-auto mx-2`}>Reply</span>
            </div>
            <div className={`my-auto ${styles.vr} mx-3`}></div>
          </>
        )} */}

        {/* <div
          className={`my-auto ${styles.button} ${
            sendType === ESendTypes.Note ? styles.active : ""
          }`}
          onClick={(e) => {
            setSendType(ESendTypes.Note);
          }}
        >
          <i className={`my-auto fa-solid fa-note-sticky`} />
          <span className={`my-auto mx-2`}>Add Internal Note</span>
        </div> */}
      </div>
      <div className="d-flex justify-content-end align-items-center">
        <div className={`d-flex justify-content-center align-items-center`}>
          {showAddTemplates === true ? (
            <span className={`${styles.templetesBtn}`}>
              {" "}
              <AddTemplateButton selectedTemplate={selectedTemplate} />
            </span>
          ) : null}
        </div>
        <div
          className={`px-1 ${styles.note}`}
          onClick={(e) => {
            setSendType(ESendTypes.Note);
            setNoteIcon(AddNoteActiveIcon);
          }}
          onMouseEnter={() => {
            setNoteIcon(AddNoteActiveIcon);
          }}
          onMouseLeave={() => {
            setNoteIcon(AddNoteIcon);
          }}
        >
          <div
            className={`px-1 ${
              sendType === ESendTypes.Note ? styles.active : ""
            }`}
          >
            <span
              className={`${styles.noteText} ${
                sendType === ESendTypes.Note ? styles.activeText : ""
              }`}
            >
              +{" "}
            </span>
            {/* Conditionally display active note icon */}
            <img
              src={sendType === ESendTypes.Note ? AddNoteActiveIcon : noteIcon}
              className={`${styles.noteIcon}`}
              alt="note"
            />
            <span
              className={`${styles.noteText} ${
                sendType === ESendTypes.Note ? styles.activeText : ""
              }`}
            >
              {" "}
              Internal note
            </span>
          </div>
        </div>
      </div>
      {/* {showAddTemplates === true ? (
        <AddTemplateButton selectedTemplate={selectedTemplate} />
      ) : null} */}
    </div>
  );
}

export default LiveSendingAs;
