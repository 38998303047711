import { useAppSelector } from "src/store/store";
import ReportTicketBody from "../../../ReportTicketBody/ReportTicketBody";

const MissedChatReports = () => {
  const ui_visibility = useAppSelector(
    (state) => state.globals.currentUserData?.ui_visibility,
  );

  return (
    <div className="mb-2">
      {ui_visibility?.includes("liveChat_missedChats_missedChatsReports") && (
        <ReportTicketBody
          endPoint={"liveChat/missedChats/missedChatsReports"}
          title={"Missed chats"}
          // tooltip={"Unresolved = Pending or Open Tickets"}
          tabProps={{
            showComparison: false,
            showDailyPart: true,
            showListView: true,
          }}
        />
      )}
    </div>
  );
};

export default MissedChatReports;
