import { axiosJSON } from "src/globals/axiosEndPoints";
import APIResponse from "./APIReponse";

export interface Team {
  teamId: string | number;
  teamName: string;
  users: number;
}

export interface GetAllTeam {
  teams: { [teamId: string | number]: Team };
  teamIds: Array<number | string>;
  metaData: {
    count: number;
    total: number;
  };
}

export interface GetAllTeamParams {
  start: number;
  limit: number;
}

export async function getAllTeam(params: GetAllTeamParams) {
  const { data: res }: any = await axiosJSON.post<APIResponse<GetAllTeam>>(
    "/api/setting/team/getAllTeams",
    params
  );

  if (res.error === true) {
    throw new Error(res.message as string);
  }

  const ret: GetAllTeam = {
    teams: {},
    teamIds: [],
    metaData: {
      count: 0,
      total: 0
    }
  };
  ret.metaData.count = res.count;
  ret.metaData.total = res.total;
  res.teams.forEach((team: Team) => {
    ret.teams[team.teamId] = team;
    ret.teamIds.push(team.teamId);
  });
  // console.log("getAllTeams service ret:::: ", JSON.stringify(ret));
  return ret;
}
