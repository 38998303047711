/**
 * This file is the custome hook file.
 * It contains custome hooks and context api to handle filters.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import React, { useContext, useMemo, useReducer } from "react";
import { generateEndDate, generateStartDate } from "src/utils/dateLibrary";

export interface GlobalFilters {
  brands?: Array<string>;
  agentTypes?: Array<"all" | "agent" | "lead_agents" | "admin" | "support">;
  ticketStatus?: Array<string>;
  tags?: Array<string>;
  channels?: Array<string>;
  articleIds?: Array<string>;
  articleCategoryIds?: Array<string>;
  suggestedByAgents?: Array<string>;
  chatStatus?: "resolved" | "unresolved" | "all";
  isAssigned?: boolean;
  botArticleIds?: Array<string>;
  botArticleCategoryIds?: Array<string>;
  botCustomQAIds?: Array<string>;
}

export interface FilterInParam extends GlobalFilters {
  startDate?: string;
  endDate?: string;
}

export interface ReportCurrentStatus {
  startDate: null | Date;
  endDate: null | Date;
  selectedTicketStatus: Array<string>;
  selectedChannels: Array<string>;
  selectedAgentTypes: Array<
    "all" | "agent" | "lead_agents" | "admin" | "support"
  >;
  selectedTags: Array<string>;
  selectedBrands: Array<string>;
  selectedArticles: Array<string>;
  selectedCategories: Array<string>;
  suggestedByAgents: Array<string>;
  selectedChatStatus: Array<"resolved" | "unresolved">;
  isSelectedAssigned: boolean | null;
  listViewPages: { [key: string]: number };
  selectedArticlesBot: Array<string>;
  selectedCategoriesBot: Array<string>;
  selectedCustomQABot: Array<string>;
}

/**
 * Initializing filters.
 */
const reportCurrentStatus: ReportCurrentStatus = {
  startDate: new Date(generateStartDate(new Date(), 30)),
  endDate: new Date(generateEndDate()),
  selectedTicketStatus: [],
  selectedChannels: [],
  selectedAgentTypes: [],
  selectedTags: [],
  selectedBrands: [],
  selectedArticles: [],
  selectedCategories: [],
  suggestedByAgents: [],
  selectedChatStatus: [],
  isSelectedAssigned: null,
  listViewPages: {},
  selectedArticlesBot: [],
  selectedCategoriesBot: [],
  selectedCustomQABot: [],
};

/**
 * This reducer method is used with the context and the UseReducer hook in the useReportFiltersCreate hook.
 */
const reducerMethod = (
  state: ReportCurrentStatus,
  [action, payload]: [
    (value: ReportCurrentStatus, payload?: any) => void,
    undefined | any
  ]
) => {
  action(state, payload);
  return { ...state };
};

/**
 * Creating the context.
 */
const CurrentStatusContext = React.createContext([
  reportCurrentStatus,
  () => {},
] as [
  ReportCurrentStatus,
  React.Dispatch<
    [(value: ReportCurrentStatus, payload?: any) => void, undefined | any]
  >
]);

/**
 * Creating reducer and combining it with the context.
 */
export const useReportFiltersCreate = (props?: {
  globalDateApplicable?: boolean;
}) => {
  const [state, dispatch] = useReducer(
    reducerMethod,
    (() => {
      const is = {
        ...reportCurrentStatus,
      };
      if (props?.globalDateApplicable === false) {
        is.startDate = null;
        is.endDate = null;
      }
      return is;
    })()
  );

  return [state, dispatch, CurrentStatusContext.Provider] as [
    ReportCurrentStatus,
    typeof dispatch,
    typeof CurrentStatusContext.Provider
  ];
};

/**
 * Using the context in this hook and returning the dispatch to update data in reducer and da data extracted for context.
 */
export const useReportFilters = (endPoint?: string) => {
  const [currentStatus, dispatch] = useContext(CurrentStatusContext);

  const filtersForAPI = useMemo(() => {
    const f: FilterInParam = {};
    if (currentStatus.startDate) {
      f.startDate = generateStartDate(currentStatus.startDate).replace(
        "T",
        " "
      );
    }
    if (currentStatus.endDate) {
      f.endDate = generateEndDate(currentStatus.endDate).replace("T", " ");
    }
    if (currentStatus.selectedAgentTypes.length) {
      f.agentTypes = currentStatus.selectedAgentTypes;
    }
    if (currentStatus.selectedBrands.length) {
      f.brands = currentStatus.selectedBrands;
    }
    if (currentStatus.selectedTicketStatus.length) {
      f.ticketStatus = currentStatus.selectedTicketStatus;
    }
    if (currentStatus.selectedTags.length) {
      f.tags = currentStatus.selectedTags;
    }
    if (currentStatus.selectedChannels.length) {
      f.channels = currentStatus.selectedChannels;
    }
    if (currentStatus.selectedArticles.length) {
      f.articleIds = currentStatus.selectedArticles;
    }
    if (currentStatus.selectedCategories.length) {
      f.articleCategoryIds = currentStatus.selectedCategories;
    }
    if (currentStatus.suggestedByAgents.length) {
      f.suggestedByAgents = currentStatus.suggestedByAgents;
    }
    // Check if isSelectedAssigned in currentStatus is not null
    if (currentStatus.isSelectedAssigned !== null) {
      // Update the 'isAssigned' property in object 'f' with the value from currentStatus.isSelectedAssigned
      f.isAssigned = currentStatus.isSelectedAssigned;
    }

    // Check if there are selectedChatStatus values in currentStatus
    if (currentStatus.selectedChatStatus.length) {
      // Check if there are exactly 2 selected chat statuses
      if (currentStatus.selectedChatStatus.length === 2) {
        // If there are 2 selected chat statuses, set 'chatStatus' in object 'f' to "all"
        f.chatStatus = "all";
      } else {
        // If there is only 1 selected chat status, set 'chatStatus' in object 'f' to the first selected value
        f.chatStatus = currentStatus.selectedChatStatus[0];
      }
    }
    if (currentStatus.selectedArticlesBot.length) {
      f.botArticleIds = currentStatus.selectedArticlesBot;
    }
    if (currentStatus.selectedCategoriesBot.length) {
      f.botArticleCategoryIds = currentStatus.selectedCategoriesBot;
    }
    if (currentStatus.selectedCustomQABot.length) {
      f.botCustomQAIds = currentStatus.selectedCustomQABot;
    }
    if (Object.keys(f).length === 0) {
      return undefined;
    } else {
      return f;
    }
  }, [currentStatus]);

  const currentPage = useMemo(() => {
    if (endPoint && currentStatus.listViewPages.hasOwnProperty(endPoint)) {
      return currentStatus.listViewPages[endPoint];
    }
    return 1;
  }, [currentStatus, endPoint]);

  return { currentStatus, dispatch, currentPage, filtersForAPI };
};

/**
 * These are the action functions which will help to apply the the state changes in the created reducer.
 */
export const reportCurrentStatusActions = {
  resetState: (state: ReportCurrentStatus) => {
    state.startDate = new Date(generateStartDate(new Date(), 30));
    state.endDate = new Date(generateEndDate());
    state.selectedTicketStatus = [];
    state.selectedChannels = [];
    state.selectedAgentTypes = [];
    state.selectedTags = [];
    state.selectedBrands = [];
    state.selectedChatStatus = [];
    state.isSelectedAssigned = null;
  },
  setStartDate: (state: ReportCurrentStatus, payload: null | Date) => {
    state.startDate = payload;
  },
  setEndDate: (state: ReportCurrentStatus, payload: null | Date) => {
    state.endDate = payload;
  },
  setListViewPage: (
    state: ReportCurrentStatus,
    payload: {
      key: string;
      value: number;
    }
  ) => {
    state.listViewPages[payload.key] = payload.value;
  },
  resetListViewPage: (state: ReportCurrentStatus, payload: string) => {
    state.listViewPages[payload] = 1;
  },
  setFilters: (
    state: ReportCurrentStatus,
    payload: Partial<ReportCurrentStatus>
  ) => {
    state.listViewPages = {};
    if (payload.selectedTicketStatus) {
      state.selectedTicketStatus = payload.selectedTicketStatus;
    }
    if (payload.selectedChannels) {
      state.selectedChannels = payload.selectedChannels;
    }
    if (payload.selectedAgentTypes) {
      state.selectedAgentTypes = payload.selectedAgentTypes;
    }
    if (payload.selectedTags) {
      state.selectedTags = payload.selectedTags;
    }
    if (payload.selectedBrands) {
      state.selectedBrands = payload.selectedBrands;
    }
    if (payload.selectedArticles) {
      state.selectedArticles = payload.selectedArticles;
    }
    if (payload.selectedCategories) {
      state.selectedCategories = payload.selectedCategories;
    }
    if (payload.suggestedByAgents) {
      state.suggestedByAgents = payload.suggestedByAgents;
    }
    // Check if 'selectedChatStatus' is present in the payload
    if (payload.selectedChatStatus) {
      // Update the 'selectedChatStatus' property in the state with the value from the payload
      state.selectedChatStatus = payload.selectedChatStatus;
    }
    // Check if 'isSelectedAssigned' is defined in the payload
    if (payload.isSelectedAssigned !== undefined) {
      // Update the 'isSelectedAssigned' property in the state with the value from the payload
      state.isSelectedAssigned = payload.isSelectedAssigned;
    }
    if (payload.selectedArticlesBot) {
      state.selectedArticlesBot = payload.selectedArticlesBot;
    }
    if (payload.selectedCategoriesBot) {
      state.selectedCategoriesBot = payload.selectedCategoriesBot;
    }
    if (payload.selectedCustomQABot) {
      state.selectedCustomQABot = payload.selectedCustomQABot;
    }
  },
  clearOneAppliedFilter: (
    state: ReportCurrentStatus,
    payload: keyof ReportCurrentStatus
  ) => {
    if (
      payload === "endDate" ||
      payload === "startDate" ||
      payload === "isSelectedAssigned"
    ) {
      state[payload] = null;
    } else if (payload !== "listViewPages") {
      state[payload] = [];
    }
  },
};
