import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import log from "loglevel";
import { AjaxError } from "src/types/AjaxError";
import TicketService from "src/services/TicketService/TicketService";
import { DraftStatus } from "src/services/TicketService/getDraftStatusesOptions";
import { ChangeDraftStatusPayload } from "src/services/TicketService/changeDraftStatus";

type TicketMsgDraftStatusSlice = {
  allDraftStatuses: { [key: string]: DraftStatus };
  allDraftStatusesIds: number[];
  changeMsgDraftStatusState: "idle" | "pending" | "fulfilled" | "rejected";
  fetchAllMsgDraftStatusesState: "idle" | "pending" | "fulfilled" | "rejected";
};

const initialState: TicketMsgDraftStatusSlice = {
  allDraftStatuses: {},
  allDraftStatusesIds: [],
  changeMsgDraftStatusState: "idle",
  fetchAllMsgDraftStatusesState: "idle"
};

export const changeMsgDraftStatus = createAsyncThunk(
  "ticket/changeMsgDraftStatus",
  async (payload: ChangeDraftStatusPayload) => {
    try {
      const changeMsgDraftStatusResponse =
        await TicketService.changeDraftStatus(payload);
      return changeMsgDraftStatusResponse;
    } catch (error) {
      let ajaxError: AjaxError = {
        message: (error as Error).message
      };
      return ajaxError;
    }
  }
);

export const fetchAllMsgDraftStatuses = createAsyncThunk(
  "ticket/fetchAllMsgDraftStatuses",
  async (callback?: Function) => {
    try {
      const { allDraftStatuses, allDraftStatusesIds } =
        await TicketService.getDraftStatusesOptions();
      // log.debug({ allDraftStatuses, allDraftStatusesIds });
      if (callback) {
        setTimeout(() => {
          callback();
        }, 0);
      }
      return { allDraftStatuses, allDraftStatusesIds };
    } catch (error) {
      let ajaxError: AjaxError = {
        message: (error as Error).message
      };
      return ajaxError;
    }
  }
);

export const ticketMsgDraftStatusSlice = createSlice({
  name: "ticketMsgDraftStatus",
  initialState,
  reducers: {
    resetFetchMsgDraftStatusState: (state) => {
      state.fetchAllMsgDraftStatusesState = "idle";
    },
    resetChangeMsgDraftStatusState: (state) => {
      state.changeMsgDraftStatusState = "idle";
    }
  },
  extraReducers: (builder) => {
    builder.addCase(changeMsgDraftStatus.fulfilled, (state, { payload }) => {
      state.changeMsgDraftStatusState = "fulfilled";
    });
    builder.addCase(changeMsgDraftStatus.rejected, (state) => {
      state.changeMsgDraftStatusState = "rejected";
    });
    builder.addCase(changeMsgDraftStatus.pending, (state) => {
      state.changeMsgDraftStatusState = "pending";
    });

    builder.addCase(
      fetchAllMsgDraftStatuses.fulfilled,
      (state, { payload }) => {
        const { allDraftStatuses, allDraftStatusesIds } = payload as any;
        state.fetchAllMsgDraftStatusesState = "fulfilled";
        state.allDraftStatuses = allDraftStatuses;
        state.allDraftStatusesIds = allDraftStatusesIds;
      }
    );
    builder.addCase(fetchAllMsgDraftStatuses.rejected, (state) => {
      state.fetchAllMsgDraftStatusesState = "rejected";
    });
    builder.addCase(fetchAllMsgDraftStatuses.pending, (state) => {
      state.fetchAllMsgDraftStatusesState = "pending";
    });
  }
});

export const { resetFetchMsgDraftStatusState, resetChangeMsgDraftStatusState } =
  ticketMsgDraftStatusSlice.actions;

export default ticketMsgDraftStatusSlice.reducer;
