import { axiosJSON } from "src/globals/axiosEndPoints";
import log from "loglevel";

export type DraftStatus = {
  id: number;
  name: string;
};
export type DraftStatusesData = {
  allDraftStatuses: { [key: string]: DraftStatus };
  allDraftStatusesIds: number[];
};

export async function getDraftStatusesOptions() {
  const { data } = await axiosJSON.get( `/api/ticket/getMsgDraftStatusOptions` );
  if ( data.err === true ) {
    throw new Error( data.msg as string );
  }
  const normalizedData = normalizeData( data );
  // log.debug("fetch message statuss raw response:", data);
  // log.debug("fetch message statuss normalized response:", normalizedData);
  return normalizedData;
}



// convert incoming data to the required format
function normalizeData( response: any ): DraftStatusesData {
  const statusesList = response.data;
  const normalizedData: DraftStatusesData = {
    allDraftStatuses: {},
    allDraftStatusesIds: [],
  };
  statusesList.forEach( ( status: any ) => {
    normalizedData.allDraftStatuses[`${status.id}`] = {
      id: status.id,
      name: status.name,
    };
    normalizedData.allDraftStatusesIds.push( status.id );
  } );
  return normalizedData;
}
