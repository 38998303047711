import { useCallback, useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Loader from "src/components/Loader";
import getReturnAutoWorkFlowByIdService from "src/services/ReturnAutoWorkFlow/getReturnAutoWorkFlowById.service";
import { useReturnAutoWorkFlow } from "../../hooks/useReturnAutoWorkFlow";
import styles from "./ConfigModals.module.scss";
import FollowUpQuestionModal from "./NewModals/FollowUpQuestionModal/FollowUpQuestionModal";
import UploadNewCsvModal from "./NewModals/UploadNewCsvModal/UploadNewCsvModal";
import UploadedCsvModal from "./NewModals/UploadedCsvModal/UploadedCsvModal";
import AntiRefundStrategies from "./OldModals/AntiRefundStrategies/AntiRefundStrategies";
import AntiRefundStrategiesOrders from "./OldModals/AntiRefundStrategiesOrders/AntiRefundStrategiesOrders";
import NonEligibleItems from "./OldModals/NonEligibleItems/NonEligibleItems";
import NonEligibleOrders from "./OldModals/NonEligibleOrders/NonEligibleOrders";
import RestockingFee from "./OldModals/RestockingFee/RestockingFee";
import ReturnMethod from "./OldModals/ReturnMethod/ReturnMethod";
import ReturnReasonOrderAndItems from "./OldModals/ReturnReasonOrderAndItems/ReturnReasonOrderAndItems";
import ReturnTypeBasedOnOrderOrItems from "./OldModals/ReturnTypeBasedOnOrderOrItems/ReturnTypeBasedOnOrderOrItems";
import { resetConfig } from "./store/slices/AntiRefundStrategiesOrdersSlice/antiRefundStrategiesOrders.slice";
import { resetStep12Config } from "./store/slices/ReturnReasonOrderAndItems/returnReasonOrderAndItems.slice";

function ConfigModals() {
  const [onSaveLoading, setOnSaveLoading] = useState(false);

  const { returnAutoWorkFlow, dispatch: returnAutoWorkFlowDispatch } =
    useReturnAutoWorkFlow();
  const dispatch = useDispatch();
  /**
   * Boolean for showing/hiding the modal
   */
  const showModal = useMemo(
    () => returnAutoWorkFlow.activeModalConfigDetails !== null,
    [returnAutoWorkFlow.activeModalConfigDetails],
  );

  const hideModal = useCallback(() => {
    returnAutoWorkFlowDispatch("setState", {
      activeModalConfigDetails: null,
      activeModalConfigQuestionId: null,
      activeModalConfigStepId: null,
    });
  }, [returnAutoWorkFlowDispatch]);

  useEffect(() => {
    if (!returnAutoWorkFlow.integrationId) {
      return;
    }
    // Reset the configuration to its initial state
    dispatch(resetConfig());
    dispatch(resetStep12Config());
  }, [dispatch, returnAutoWorkFlow.integrationId]);

  /**
   * Object that gets styles based on configure key
   */
  const modalStyles = useMemo(() => {
    if (returnAutoWorkFlow.activeModalConfigDetails === null) {
      return null;
    }

    return {
      dialogClassName:
        styles[
          `${returnAutoWorkFlow.activeModalConfigDetails.configureKey}Dialog`
        ],
      contentClassName:
        styles[
          `${returnAutoWorkFlow.activeModalConfigDetails.configureKey}Content`
        ],
      backdropClassName:
        styles[
          `${returnAutoWorkFlow.activeModalConfigDetails.configureKey}Backdrop`
        ],
    };
  }, [returnAutoWorkFlow.activeModalConfigDetails]);

  const onSave = useCallback(async () => {
    if (
      !returnAutoWorkFlow.integrationId ||
      !returnAutoWorkFlow.activeModalConfigQuestionId ||
      !returnAutoWorkFlow.activeModalConfigStepId
    ) {
      return;
    }

    try {
      returnAutoWorkFlowDispatch("setState", {
        loadingStepsAndQuestionSaving: "pending",
      });
      const res = await getReturnAutoWorkFlowByIdService({
        integrationId: returnAutoWorkFlow.integrationId,
        steps: [
          {
            questionIds: [returnAutoWorkFlow.activeModalConfigQuestionId],
            stepId: returnAutoWorkFlow.activeModalConfigStepId,
          },
        ],
      });

      returnAutoWorkFlowDispatch("updateConfiguredStepsAndWorkFlow", res);
    } catch (err) {
      console.error(err);
    } finally {
      returnAutoWorkFlowDispatch("setState", {
        loadingStepsAndQuestionSaving: "fulfilled",
      });
    }
  }, [
    returnAutoWorkFlowDispatch,
    returnAutoWorkFlow.activeModalConfigQuestionId,
    returnAutoWorkFlow.activeModalConfigStepId,
    returnAutoWorkFlow.integrationId,
  ]);

  const hideModalAndOnSave = useCallback(() => {
    setOnSaveLoading(true);
    onSave().then(() => {
      hideModal();
      setOnSaveLoading(false);
    });
  }, [hideModal, onSave]);

  /**
   * Active Modal component based on event type
   */
  const ModalComponent = useMemo(() => {
    if (returnAutoWorkFlow.activeModalConfigDetails === null) {
      return <div></div>;
    }

    switch (returnAutoWorkFlow.activeModalConfigDetails?.configureKey) {
      case "returnMethod":
        return (
          <ReturnMethod
            onHide={hideModal}
            onSave={onSave}
          />
        );
      case "nonEligibleItems":
        return (
          <NonEligibleItems
            onHide={hideModal}
            onSave={onSave}
          />
        );
      case "antiRefundStrategiesOrders":
        return (
          <AntiRefundStrategiesOrders
            onHide={hideModal}
            onSave={onSave}
          />
        );
      case "nonEligibleOrders":
        return (
          <NonEligibleOrders
            onHide={hideModal}
            onSave={onSave}
          />
        );
      case "returnReasonOrderAndItems":
        return (
          <ReturnReasonOrderAndItems
            onHide={hideModal}
            onSave={onSave}
          />
        );
      case "returnTypeBasedOnOrderOrItems":
        return (
          <ReturnTypeBasedOnOrderOrItems
            onHide={hideModal}
            onSave={onSave}
          />
        );
      case "restockingFee":
        return (
          <RestockingFee
            onHide={hideModal}
            onSave={onSave}
          />
        );
      case "antiRefundStrategies":
        return (
          <AntiRefundStrategies
            onHide={hideModal}
            onSave={onSave}
          />
        );
      case "uploadCSV":
        if (returnAutoWorkFlow.activeModalConfigDetails?.metaData) {
          return <UploadedCsvModal onHide={hideModal} />;
        } else {
          return (
            <UploadNewCsvModal
              onHide={
                returnAutoWorkFlow.activeModalConfigDetails.status === "pending"
                  ? hideModalAndOnSave
                  : hideModal
              }
            />
          );
        }
      case "followUpQuestion":
        return (
          <FollowUpQuestionModal
            onHide={hideModal}
            onSave={onSave}
          />
        );
      default:
        return <div></div>;
    }
  }, [
    hideModal,
    hideModalAndOnSave,
    onSave,
    returnAutoWorkFlow.activeModalConfigDetails,
  ]);

  return (
    <Modal
      show={showModal}
      onHide={hideModal}
      /**
       * Adding the default stylings and then custom modal styling based on configuration key
       */
      dialogClassName={`${styles.modalDialog} ${
        modalStyles ? modalStyles.dialogClassName : ""
      }`}
      contentClassName={`${styles.modalContent} ${
        modalStyles ? modalStyles.contentClassName : ""
      }`}
      backdropClassName={`${modalStyles ? modalStyles.backdropClassName : ""}`}
      // centered={true}
      enforceFocus={false} // Needed for TinyMCE Editor
      backdrop="static" // Stops closing the modal by clicking backdrop
    >
      {onSaveLoading ? <Loader /> : ModalComponent}
    </Modal>
  );
}

export default ConfigModals;
