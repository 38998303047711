import { OverlayTrigger, Tooltip } from "react-bootstrap";
import styles from "./PlanUpgradeOverlay.module.scss";
import { Placement } from "react-bootstrap/esm/types";

const PlanUpgradeOverlay = ({
  showOverlay,
  message,
  children,
  placement = "top-start",
}: {
  showOverlay: boolean;
  message: string;
  children: React.ReactElement;
  placement?: Placement;
}) => {
  return (
    <OverlayTrigger
      trigger={["hover", "focus"]}
      placement={placement}
      overlay={
        showOverlay ? (
          <Tooltip className={`${styles.toolTipCustom}`}>
            <div className={`${styles.tooltipBody}`}>
              <span>{message}</span>
            </div>
          </Tooltip>
        ) : (
          <></>
        )
      }
    >
      {children}
    </OverlayTrigger>
  );
};

export default PlanUpgradeOverlay;
