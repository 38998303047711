import { getLocalLoginCustomerInfo } from "src/services/LocalStorage/loginData";
import { addUser, AddUserParams } from "src/services/Settings/Users/addUser";
import {
  deleteUser,
  DeleteUserParams,
} from "src/services/Settings/Users/deleteUser";
import { getAllUser } from "src/services/Settings/Users/getAllUser";
import { getUser } from "src/services/Settings/Users/getUser";
import { getUserRoles } from "src/services/Settings/Users/getUserRoles";
import {
  updateUser,
  UpdateUserParams,
} from "src/services/Settings/Users/updateUser";
import { RootState } from "src/store/store";
import {
  fetchMembersInTeam,
  resetMembersInTeamData,
} from "../../teamSettings/teamSettings.slice";
import { fetchAllUser, resetUserData } from "./userSettings.slice";
import { logoutUserFromAllSession, LogoutUserFromAllSessionPayload } from "src/services/Settings/Users/logoutUserFromAllSession";
import { deactivateUser, DeactivateUserPayload } from "src/services/Settings/Users/deactivateUser";

async function fetchAllUserThunk(
  payload: undefined,
  { getState }: { getState: Function }
) {
  const currentState: RootState = getState();
  let roles = currentState.userSettings.userRoles;
  if (roles.rolesIds.length === 0) {
    roles = await getUserRoles();
  }
  const custInfo = getLocalLoginCustomerInfo();
  if (custInfo === null) {
    throw "User not Logged In!";
  }
  const userData = await getAllUser({
    searchTerm: currentState.userSettings.filters.searchValue,
    start: currentState.userSettings.userIds.length,
    limit: currentState.userSettings.usersLimit,
  });
  return { userData, roles };
}
interface AddUser extends AddUserParams {
  callback?: () => void;
}
async function addUserThunk(
  {callback, ...payload }: AddUser,
  { getState, dispatch }: { getState: Function; dispatch: Function }
) {
  const user = await addUser(payload);
  
  setTimeout(() => {
    dispatch(resetUserData());
    dispatch(fetchAllUser());
    try {
      const currentState: RootState = getState();
      const activeTeam = currentState.teamSettings.activeTeam;
      if (activeTeam.teamId !== 0) {
        dispatch(resetMembersInTeamData());
        dispatch(fetchMembersInTeam(activeTeam.teamId));
      }
    } catch {}
    if (callback) {
      callback();
    }
  }, 0);
  return payload;
}

interface EditUser extends UpdateUserParams {
  callback?: () => void;
}

async function editUserThunk(
  { callback, ...payload }: EditUser,
  { getState, dispatch }: { getState: Function; dispatch: Function }
) {
  const update = await updateUser(payload);
  const user = await getUser({ userId: parseInt(payload.userId + "") });
  setTimeout(() => {
    if (callback) {
      callback();
    }
  }, 0);
  return user;
}

interface DeleteUser extends DeleteUserParams {
  callback?: () => void;
}

async function deleteUserThunk(
  { callback, ...payload }: DeleteUser,
  { getState, dispatch }: { getState: Function; dispatch: Function }
) {
  const del = await deleteUser(payload);
  setTimeout(() => {
    if (callback) {
      callback();
    }
  }, 0);
  return payload.userId;
}

async function logoutUserFromAllSessionThunk(
  { ...payload }: LogoutUserFromAllSessionPayload,
) {
  const del = await logoutUserFromAllSession(payload);

  return payload.userId;
}

async function deactivateUserThunk(
  { ...payload }: DeactivateUserPayload,
  { getState, dispatch }: { getState: Function; dispatch: Function }
) {
  const del = await deactivateUser(payload);

  return payload;
}

export default {
  fetchAllUserThunk,
  addUserThunk,
  editUserThunk,
  deleteUserThunk,
  logoutUserFromAllSessionThunk,
  deactivateUserThunk,
};
