import SearchBar from "src/components/SearchBar/SearchBar";
import styles from "./HelpCenterPage.module.scss";
import helpCenter from "src/assets/images/helpCenter.png";
import { Link } from "react-router-dom";
import AxiosImg from "src/components/AxiosImg";
interface Props{
  companyName: string,
  headlineText: string,
  integrationId: string|number,
  logoImgUrl?: string,
  isAxiosLink?: boolean,
  themeColour: string
};

const HelpCenterPage = ({
  companyName,
  headlineText,
  integrationId,
  logoImgUrl,
  isAxiosLink,
  themeColour = '#f9bf59'
}:Props) => {
  return (
    <div className={`${styles.helpCenter}`}>
      <div className={`${styles.blur}`}>
        <span className={`${styles.helpHead}`}>View Helpcenter Page</span>
        <div className={`${styles.pageDemo}`}>
          <div className={`${styles.pageDemoInner}`}>
            <div className={`d-flex ${styles.dotWrapper}`}>
              <div className={`me-1 ${styles.dots}`}></div>
              <div className={`me-1 ${styles.dots} ${styles.dots2}`}></div>
              <div className={`${styles.dots} ${styles.dots3}`}></div>
            </div>
            <div className={`text-center ${styles.helpBox}`} style={{ background: `${themeColour} 0% 0% no-repeat padding-box`}}>
              <span>
                {/* <img
                  className={`me-1 ${styles.helpLogo}`}
                  src={helpCenter}
                  alt="help"
                /> */}
                {logoImgUrl !== undefined && (
                isAxiosLink === true ? (
                    <AxiosImg
                      url={logoImgUrl}
                      className={`me-1 ${styles.helpLogo}`}
                    />
                  ) : (
                    <img
                      className={`me-1 ${styles.helpLogo}`}
                      src={logoImgUrl}
                    />
                  )
                 ) }
                <span className={`${styles.helpBoxHead}`}>
                  {companyName} Help Center
                </span>
              </span>
              <h3 className={`${styles.helpBoxHelp}`}>{headlineText}</h3>
            </div>
            <SearchBar
              className={`${styles.search} px-2`}
              inputClassName={`${styles.input}`}
              placeholder={`Search for answers`}
            />
            <div className={`${styles.categoryBox}`}>
              <h3 className={`${styles.category}`}>Categories</h3>
              <div className={`mb-3 ${styles.categorySub}`}></div>
              <div className={`mb-3 ${styles.categorySub}`}></div>
              <div className={`mb-3 ${styles.categorySub}`}></div>
            </div>
          </div>
        </div>
      </div>
      <div className={`${styles.middle}`}>
        <div>
         <span className={`${styles.text}`}> This is just a preview of page</span> 
         <div> <Link to='/' className={`text-decoration-none`}>Go to Helpcenter</Link></div>
       
          </div>
    </div>
    </div>
  );
};
export default HelpCenterPage;
