import { axiosJSON } from "src/globals/axiosEndPoints";

export interface IGetAllVariablesParams {
  searchTerm?: string;
  limit?: number;
  start?: number;
}

export interface IVariable {
  id: number;
  variableValue: string;
  variableName: string;
  variableKey: string;
}

export interface IGetAllVariables {
  variablesIds: number[];
  variables: { [key: number]: IVariable };
  metaData: {
    count: number;
    total: number;
  };
}

export async function getAllVariables(
  payload: IGetAllVariablesParams
): Promise<IGetAllVariables> {
  const { data } = await axiosJSON.post(
    "/api/setting/automation/refund/variable/getAll",
    payload
  );

  if (data.err === true) {
    throw new Error("Some Error Occurred while fetching data");
  }

  const ret: IGetAllVariables = {
    variables: {},
    variablesIds: [],
    metaData: data.metaData,
  };

  data.data.forEach((variable: IVariable) => {
    const id = parseInt(variable.id + "");
    ret.variables[id] = variable;
    ret.variablesIds.push(id);
  });

  return ret;
}
