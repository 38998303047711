/**
 * This file is the custom hook and context file.
 * It contains useUpdateSegmentDetailsCreate and useUpdateSegmentDetails custom hooks.
 *
 * @author Yash Aditya
 * @fileoverview Custom hooks and context for managing update segment details state.
 */

import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
  useRef,
} from "react";
import { v4 as uuidv4 } from "uuid";
import { AJAXSTATUS, ActionDispatch } from "src/globals/constants";
import { SegmentDataForUpdateI } from "src/routes/CustomerSegments/hooks/useCustomerSegments";
import {
  ConditionI,
  Group,
  SegmentDetails,
} from "src/services/CustomerSegments/createSegment.service";
import getSegmentByIdService from "src/services/CustomerSegments/getSegmentById.service";
import { ConditionOption } from "src/services/CustomerSegments/getConditionOptions.service";
import { isMySQLDateStringValid } from "src/utils/dateLibrary";

/**
 * Interface representing the state for update segment details state.
 *
 * @interface UpdateSegmentDetailsState
 */
export interface UpdateSegmentDetailsState {
  fetchingSegmentDetails: AJAXSTATUS;
  savingSegmentDetails: AJAXSTATUS;
  segmentDetails: Partial<SegmentDetails>;
  showErrors: boolean;
  segmentDetailsSaved: SegmentDetails | null;
}

/**
 * Initial state for the update segment details state.
 */
const updateSegmentDetails: UpdateSegmentDetailsState = {
  fetchingSegmentDetails: "fulfilled",
  savingSegmentDetails: "idle",
  segmentDetails: {},
  showErrors: false,
  segmentDetailsSaved: null,
};

/**
 * Reducer function to handle state modifications.
 * @function
 * @param {UpdateSegmentDetailsState} state - The current state.
 * @param {[function, any]} actionPayload - Array containing the action and payload.
 * @returns {UpdateSegmentDetailsState} The new state after applying the action.
 */
const reducerMethod = (
  state: UpdateSegmentDetailsState,
  [action, payload]: [
    (value: UpdateSegmentDetailsState, payload?: any) => void,
    undefined | any,
  ],
): UpdateSegmentDetailsState => {
  // Execute the action function with current state and payload
  action(state, payload);
  // Return a new state object to ensure immutability
  return { ...state };
};

/**
 * Context for update segment details state.
 */
const UpdateSegmentDetailsContext = createContext([
  updateSegmentDetails,
  () => {},
] as [
  UpdateSegmentDetailsState,
  ActionDispatch<typeof UpdateSegmentDetailsAction>,
]);

/**
 * Custom hook to create context with a reducer.
 */
export const useUpdateSegmentDetailsCreate = (
  segmentDataForUpdate: SegmentDataForUpdateI | null,
) => {
  // Use the reducer to manage state
  const [state, dispatch] = useReducer(reducerMethod, {
    // Initialize state with default values
    ...updateSegmentDetails,
  });

  const stateRef = useRef(state);

  useMemo(() => {
    stateRef.current = state;
  }, [state]);

  // Memoize the modified dispatch function using useCallback
  const modifiedDispatch: ActionDispatch<typeof UpdateSegmentDetailsAction> =
    useCallback(
      (action, payload) => {
        // Wrap dispatch with action and payload
        dispatch([UpdateSegmentDetailsAction[action], payload]);
      },
      [dispatch],
    );

  /**
   * Fetches the initial segment data if an update action is required.
   */
  useMemo(() => {
    if (
      segmentDataForUpdate &&
      stateRef.current.savingSegmentDetails !== "pending"
    ) {
      // Check if the action is not 'create' and segmentId is available
      if (
        segmentDataForUpdate.action !== "create" &&
        segmentDataForUpdate.segmentId &&
        !(
          segmentDataForUpdate.segmentId ===
            stateRef.current.segmentDetails.segmentId &&
          segmentDataForUpdate.segmentType ===
            stateRef.current.segmentDetails.segmentType
        )
      ) {
        modifiedDispatch("setUpdateSegmentDetails", {
          fetchingSegmentDetails: "pending",
          segmentDetails: {},
        });
        // Fetch the segment details by segmentId
        getSegmentByIdService({
          segmentType: segmentDataForUpdate.segmentType,
          segmentId: segmentDataForUpdate.segmentId,
        })
          .then((res) => {
            if (res.groupConditions.length === 0) {
              res.groupConditions.push({
                groupId: null,
                groupUUID: uuidv4(),
                nextGroupUUID: null,
                logicalOperator: null,
                conditions: [],
              });
            }
            // Set the fetched segment details and update the fetching status
            modifiedDispatch("setUpdateSegmentDetails", {
              fetchingSegmentDetails: "fulfilled",
              segmentDetails: res,
              segmentDetailsSaved: JSON.parse(JSON.stringify(res)),
            });
          })
          .catch((err) => {
            console.error(err);
            modifiedDispatch("setUpdateSegmentDetails", {
              fetchingSegmentDetails: "rejected",
            });
          });
      } else if (
        segmentDataForUpdate.action === "create" &&
        segmentDataForUpdate.segmentId === undefined &&
        segmentDataForUpdate.segmentType !==
          stateRef.current.segmentDetails.segmentType
      ) {
        modifiedDispatch("setUpdateSegmentDetails", {
          fetchingSegmentDetails: "fulfilled",
          segmentDetails: {
            groupConditions: [
              {
                groupId: null,
                groupUUID: uuidv4(),
                nextGroupUUID: null,
                logicalOperator: null,
                conditions: [],
              },
            ],
          },
        });
      }
    }
  }, [
    modifiedDispatch,
    `${segmentDataForUpdate?.action}::${segmentDataForUpdate?.segmentId}::${segmentDataForUpdate?.segmentType}`,
  ]);

  // Return the state, modified dispatch, and context provider
  return [state, modifiedDispatch, UpdateSegmentDetailsContext.Provider] as [
    UpdateSegmentDetailsState,
    ActionDispatch<typeof UpdateSegmentDetailsAction>,
    typeof UpdateSegmentDetailsContext.Provider,
  ];
};

/**
 * Custom hook to consume the update segment details state context.
 */
export const useUpdateSegmentDetails = () => {
  // Destructure context values
  const [updateSegmentDetails, dispatch] = useContext(
    UpdateSegmentDetailsContext,
  );

  // Return context values
  return { updateSegmentDetails, dispatch };
};

/**
 * Actions to modify the update segment details state state.
 */
const UpdateSegmentDetailsAction = {
  /**
   * Resets the update segment details state state.
   * @function
   * @param {UpdateSegmentDetailsState} state - The current state.
   */
  resetState: (state: UpdateSegmentDetailsState) => {
    state.fetchingSegmentDetails = "fulfilled";
    state.savingSegmentDetails = "idle";
    state.segmentDetails = {};
    state.showErrors = false;
    state.segmentDetailsSaved = null;
  },

  /**
   * Sets the update segment details state state.
   * @function
   * @param {UpdateSegmentDetailsState} state - The current state.
   * @param {Partial<UpdateSegmentDetailsState>} payload - The state to set.
   */
  setUpdateSegmentDetails: (
    state: UpdateSegmentDetailsState,
    payload: Partial<UpdateSegmentDetailsState>,
  ) => {
    if (payload.fetchingSegmentDetails !== undefined) {
      state.fetchingSegmentDetails = payload.fetchingSegmentDetails;
    }
    if (payload.savingSegmentDetails !== undefined) {
      state.savingSegmentDetails = payload.savingSegmentDetails;
    }
    if (payload.segmentDetails !== undefined) {
      state.segmentDetails = payload.segmentDetails;
    }
    if (payload.segmentDetailsSaved !== undefined) {
      state.segmentDetailsSaved =
        payload.segmentDetailsSaved === null
          ? null
          : JSON.parse(JSON.stringify(payload.segmentDetailsSaved));
    }
    if (payload.showErrors !== undefined) {
      state.showErrors = payload.showErrors;
    }
  },

  addSegmentGroup: (
    state: UpdateSegmentDetailsState,
    currentGroupIndex: number,
  ) => {
    const group: Group = {
      groupId: null,
      groupUUID: uuidv4(),
      nextGroupUUID: null,
      logicalOperator: null,
      conditions: [],
    };
    if (
      state.segmentDetails.groupConditions &&
      state.segmentDetails.groupConditions[currentGroupIndex]
    ) {
      state.segmentDetails.groupConditions[currentGroupIndex + 1] = group;
      state.segmentDetails.groupConditions[currentGroupIndex].nextGroupUUID =
        state.segmentDetails.groupConditions[currentGroupIndex + 1].groupUUID;
      state.segmentDetails.groupConditions[currentGroupIndex].logicalOperator =
        "and";
    }
  },

  deleteSegmentGroup: (state: UpdateSegmentDetailsState, groupUUID: string) => {
    if (state.segmentDetails.groupConditions?.length) {
      state.segmentDetails.groupConditions =
        state.segmentDetails.groupConditions
          .filter((value) => value.groupUUID !== groupUUID)
          .map((value, index, array) => {
            if (array[index + 1]) {
              value.nextGroupUUID = array[index + 1].groupUUID;
            } else {
              value.nextGroupUUID = null;
              value.logicalOperator = null;
            }
            return value;
          });
    }
  },

  addSegmentGroupCondition: (
    state: UpdateSegmentDetailsState,
    payload: {
      groupIndex: number;
      conditionOption: ConditionOption;
      conditionLength: number;
    },
  ) => {
    const groupCondition: ConditionI = {
      conditionId: null,
      conditionUUID: uuidv4(),
      nextConditionUUID: null,
      fieldKey: payload.conditionOption.fieldKey,
      compareType: null,
      value: null,
      logicalOperator: null,
    };
    if (
      state.segmentDetails.groupConditions &&
      state.segmentDetails.groupConditions[payload.groupIndex]
    ) {
      state.segmentDetails.groupConditions[payload.groupIndex].conditions[
        payload.conditionLength
      ] = groupCondition;

      if (payload.conditionLength !== 0) {
        state.segmentDetails.groupConditions[payload.groupIndex].conditions[
          payload.conditionLength - 1
        ].nextConditionUUID =
          state.segmentDetails.groupConditions[payload.groupIndex].conditions[
            payload.conditionLength
          ].conditionUUID;
        state.segmentDetails.groupConditions[payload.groupIndex].conditions[
          payload.conditionLength - 1
        ].logicalOperator = "and";
      }
    }
  },

  deleteSegmentGroupCondition: (
    state: UpdateSegmentDetailsState,
    payload: { groupIndex: number; fieldKey: string },
  ) => {
    if (
      state.segmentDetails.groupConditions &&
      state.segmentDetails.groupConditions[payload.groupIndex].conditions.length
    ) {
      state.segmentDetails.groupConditions[payload.groupIndex].conditions =
        state.segmentDetails.groupConditions[payload.groupIndex].conditions
          .filter((value) => value.fieldKey !== payload.fieldKey)
          .map((value, index, array) => {
            if (array[index + 1]) {
              value.nextConditionUUID = array[index + 1].conditionUUID;
            } else {
              value.nextConditionUUID = null;
              value.logicalOperator = null;
            }
            return value;
          });
    }
  },

  /**
   * Updates the segment details with the given payload.
   *
   * @param {UpdateSegmentDetailsState} state - The current state.
   * @param {Partial<SegmentDetails>} payload - Partial segment details to be merged with existing details.
   */
  updateSegmentDetails: (
    state: UpdateSegmentDetailsState,
    payload: Partial<SegmentDetails>,
  ) => {
    state.segmentDetails = { ...state.segmentDetails, ...payload };
  },

  /**
   * Updates a specific segment group with the given payload.
   *
   * @param {UpdateSegmentDetailsState} state - The current state.
   * @param {Partial<Group>} payload.data - Partial group data to be merged with the existing group.
   * @param {number} payload.index - Index of the group to be updated.
   */
  updateSegmentGroup: (
    state: UpdateSegmentDetailsState,
    payload: { data: Partial<Group>; index: number },
  ) => {
    // Create a copy of the existing group conditions array
    const sdTemp = {
      ...state.segmentDetails,
      groupConditions: [...(state.segmentDetails.groupConditions ?? [])],
    };
    // Check if the group at the specified index exists
    if (sdTemp.groupConditions[payload.index]) {
      // Merge the existing group data with the new payload
      sdTemp.groupConditions[payload.index] = {
        ...sdTemp.groupConditions[payload.index],
        ...payload.data,
      };
      state.segmentDetails = sdTemp;
    }
  },

  /**
   * Push valueIndex into a specific condition within a group.
   *
   * @param {UpdateSegmentDetailsState} state - The current state.
   * @param {number} payload.groupIndex - Index of the group containing the condition.
   * @param {number} payload.conditionIndex - Index of the condition to be updated.
   */
  pushValueIndexSegmentCondition: (
    state: UpdateSegmentDetailsState,
    payload: {
      groupIndex: number;
      conditionIndex: number;
      index: number;
    },
  ) => {
    if (state.segmentDetails.groupConditions) {
      if (
        state.segmentDetails.groupConditions[payload.groupIndex].conditions[
          payload.conditionIndex
        ]?.valueIndex
      ) {
        (
          state.segmentDetails.groupConditions[payload.groupIndex].conditions[
            payload.conditionIndex
          ].valueIndex as 1[]
        )[payload.index] = 1;
      } else {
        state.segmentDetails.groupConditions[payload.groupIndex].conditions[
          payload.conditionIndex
        ].valueIndex = [1];
      }
    }
  },
  /**
   * Push valueIndex into a specific condition within a group.
   *
   * @param {UpdateSegmentDetailsState} state - The current state.
   * @param {number} payload.groupIndex - Index of the group containing the condition.
   * @param {number} payload.conditionIndex - Index of the condition to be updated.
   */
  popValueIndexSegmentCondition: (
    state: UpdateSegmentDetailsState,
    payload: {
      groupIndex: number;
      conditionIndex: number;
      index: number;
    },
  ) => {
    if (
      state.segmentDetails.groupConditions &&
      state.segmentDetails.groupConditions[payload.groupIndex]
    ) {
      if (
        state.segmentDetails.groupConditions[payload.groupIndex].conditions[
          payload.conditionIndex
        ]?.valueIndex
      ) {
        if (
          (
            state.segmentDetails.groupConditions[payload.groupIndex].conditions[
              payload.conditionIndex
            ].valueIndex as 1[]
          )[payload.index] === 1
        ) {
          (
            state.segmentDetails.groupConditions[payload.groupIndex].conditions[
              payload.conditionIndex
            ].valueIndex as 1[]
          ).pop();
          if (
            state.segmentDetails.groupConditions[payload.groupIndex].conditions[
              payload.conditionIndex
            ].valueIndex?.length === 0
          ) {
            state.segmentDetails.groupConditions[payload.groupIndex].conditions[
              payload.conditionIndex
            ].valueIndex = undefined;
          }
        }
      }
    }
  },

  /**
   * Updates a specific condition within a group with the given payload.
   *
   * @param {UpdateSegmentDetailsState} state - The current state.
   * @param {Partial<ConditionI>} payload.data - Partial condition data to be merged with the existing condition.
   * @param {number} payload.groupIndex - Index of the group containing the condition.
   * @param {number} payload.conditionIndex - Index of the condition to be updated.
   */
  updateSegmentCondition: (
    state: UpdateSegmentDetailsState,
    payload: {
      data: Partial<ConditionI>;
      groupIndex: number;
      conditionIndex: number;
    },
  ) => {
    // Create a copy of the existing group conditions array
    const sdTemp = {
      ...state.segmentDetails,
      groupConditions: [...(state.segmentDetails.groupConditions ?? [])],
    };
    // Check if the group and condition at the specified indexes exist
    if (sdTemp.groupConditions[payload.groupIndex]?.conditions?.length) {
      if (
        sdTemp.groupConditions[payload.groupIndex].conditions[
          payload.conditionIndex
        ]
      ) {
        // Merge the existing condition data with the new payload
        sdTemp.groupConditions[payload.groupIndex].conditions[
          payload.conditionIndex
        ] = {
          ...sdTemp.groupConditions[payload.groupIndex].conditions[
            payload.conditionIndex
          ],
          ...payload.data,
        };
        state.segmentDetails = sdTemp;
      }
    }
    state.showErrors = false;
  },
};
