import { axiosJSON } from "src/globals/axiosEndPoints";
import { Step15TabType } from "./step15GetConfig";
import { GetConfigurationPayload } from "../Step9/step9PostConfig";

export async function fetchStep15ConfigPost(payload: GetConfigurationPayload) {
  const { data } = await axiosJSON.post(
    "/api/setting/automation/refund/step/15/configuration",
    { configTabs: payload.tabType, integrationId: payload.integrationId }
  );

  if (data.err === true) {
    throw new Error("Some Error Occurred while fetching data");
  }

  return data;
}
