import { axiosJSON } from "src/globals/axiosEndPoints";
import APIResponse from "./APIReponse";

interface AddTag {
  tagId: number;
}

export interface AddTagParams {
  name: string;
  description?: string;
}

export async function addTag(params: AddTagParams) {
  
  if(params.description?.length === 0){
    delete params.description;
  }
  const { data: res } = await axiosJSON.post<APIResponse<AddTag>>(
    "/api/tags/addTag",
    params
  );

  if (res.error === true) {
    throw new Error(res.message as string);
  }

  return res.data as AddTag;
}
