import { createAsyncThunk } from "@reduxjs/toolkit";
import { getShopifyCustomerData, IGetShopifyCustomerDataParams } from "src/services/ShopifySidebar/Customer/getShopifyCustomerData";
import { getTrackingData, GetTrackingDataParams } from "src/services/ShopifySidebar/getTrackingData";
import {
  cancelOrder,
  CancelOrderParams
} from "src/services/ShopifySidebar/SingleOrder/BasicOrder/cancelOrder";
import { cancelOrderReasonOption } from "src/services/ShopifySidebar/SingleOrder/BasicOrder/cancelOrderReasonOption";
import {
  deleteOrder,
  DeleteOrderParams
} from "src/services/ShopifySidebar/SingleOrder/BasicOrder/deleteOrder";
import {
  duplicateOrder,
  DuplicateOrderParams
} from "src/services/ShopifySidebar/SingleOrder/BasicOrder/duplicateOrder";
import { getAllCountryList } from "src/services/ShopifySidebar/SingleOrder/BasicOrder/getAllCountryList";
import {
  getOrderViewModel,
  GetOrderViewModelParams
} from "src/services/ShopifySidebar/SingleOrder/BasicOrder/getOrderViewModel";
import {
  markOrderAsPaid,
  MarkOrderAsPaidParams
} from "src/services/ShopifySidebar/SingleOrder/BasicOrder/markOrderAsPaid";
import {
  paymentTermOption,
  PaymentTermOptionParams
} from "src/services/ShopifySidebar/SingleOrder/BasicOrder/paymentTermOption";
import {
  sendInvoice,
  SendInvoiceParams
} from "src/services/ShopifySidebar/SingleOrder/BasicOrder/sendInvoice";
import {
  updateOrder,
  UpdateOrderParams
} from "src/services/ShopifySidebar/SingleOrder/BasicOrder/updateOrder";
import {
  cancelFulfillment,
  CancelFulfillmentParams
} from "src/services/ShopifySidebar/SingleOrder/FulFillments/cancelFulfillment";
import {
  createFulfillment,
  CreateFulfillmentParams
} from "src/services/ShopifySidebar/SingleOrder/FulFillments/createFulfillment";
import {
  getFulFillmentOrder,
  GetFulFillmentOrderParams
} from "src/services/ShopifySidebar/SingleOrder/FulFillments/getFulFillmentOrder";
import {
  holdFulfillment,
  HoldFulfillmentParams
} from "src/services/ShopifySidebar/SingleOrder/FulFillments/holdFulfillment";
import { holdFulfillmentReason } from "src/services/ShopifySidebar/SingleOrder/FulFillments/holdFulfillmentReason";
import {
  releaseFulfillment,
  ReleaseFulfillmentParams
} from "src/services/ShopifySidebar/SingleOrder/FulFillments/releaseFulfillment";
import { IUpdateTrackingParams, updateTracking } from "src/services/ShopifySidebar/SingleOrder/FulFillments/updateTracking";
import {
  calculateRefundTotalPrice,
  CalculateRefundTotalPriceParams
} from "src/services/ShopifySidebar/SingleOrder/Refund/calculateRefundTotalPrice";
import {
  createRefund,
  CreateRefundParams
} from "src/services/ShopifySidebar/SingleOrder/Refund/createRefund";
import {
  refundModel,
  RefundModelParams
} from "src/services/ShopifySidebar/SingleOrder/Refund/refundModel";

export const fetchCancelOrder = createAsyncThunk(
  "order/cancelOrder",
  (params: CancelOrderParams) => {
    return cancelOrder(params);
  }
);

export const fetchCalculateRefundTotalPrice = createAsyncThunk(
  "calculateRefundTotalPrice/get",
  (params: CalculateRefundTotalPriceParams) => {
    return calculateRefundTotalPrice(params);
  }
);

export const fetchCreateFulfillment = createAsyncThunk(
  "createFulFillment/get",
  (params: CreateFulfillmentParams) => {
    return createFulfillment(params);
  }
);

export const fetchCreateRefund = createAsyncThunk(
  "createRefund/get",
  (params: CreateRefundParams) => {
    return createRefund(params);
  }
);

export const fetchGetFulfillmentOrder = createAsyncThunk(
  "getFulfillmentOrder/get",
  (params: GetFulFillmentOrderParams) => {
    return getFulFillmentOrder(params);
  }
);

export const fetchRefundModal = createAsyncThunk(
  "refundModal/get",
  (params: RefundModelParams) => {
    return refundModel(params);
  }
);

export const fetchCancelOrderReasonOption = createAsyncThunk(
  "cancelOrderReasonOption/get",
  () => {
    return cancelOrderReasonOption();
  }
);

export const fetchDeletOrder = createAsyncThunk(
  "deleteOrder/get",
  (params: DeleteOrderParams) => {
    return deleteOrder(params);
  }
);

export const fetchDuplicateOrder = createAsyncThunk(
  "duplicateOrder/get",
  (params: DuplicateOrderParams) => {
    return duplicateOrder(params);
  }
);

export const fetchGetOrderViewModel = createAsyncThunk(
  "getOrderViewModel/get",
  (params: GetOrderViewModelParams) => {
    return getOrderViewModel(params);
  }
);

export const fetchMarkOrderAsPaid = createAsyncThunk(
  "markOrderAsPaid/get",
  (params: MarkOrderAsPaidParams) => {
    return markOrderAsPaid(params);
  }
);

export const fetchPaymentTermOption = createAsyncThunk(
  "paymentTermOption/get",
  (params: PaymentTermOptionParams) => {
    return paymentTermOption(params);
  }
);

export const fetchSendInvoice = createAsyncThunk(
  "sendInvoice/get",
  (params: SendInvoiceParams) => {
    return sendInvoice(params);
  }
);

export const fetchUpdateOrder = createAsyncThunk(
  "updateOrder/get",
  (params: UpdateOrderParams) => {
    return updateOrder(params);
  }
);

export const cancelFulfillmentThunk = createAsyncThunk(
  "singleOrder/cancelFulfillmentThunk",
  (params: CancelFulfillmentParams) => {
    return cancelFulfillment(params);
  }
);

export const fetchHoldFulfillment = createAsyncThunk(
  "holdFulfillment/get",
  (params: HoldFulfillmentParams) => {
    return holdFulfillment(params);
  }
);

export const fetchHoldFulfillmentReason = createAsyncThunk(
  "holdFulfillmentReason/get",
  () => {
    return holdFulfillmentReason();
  }
);

export const fetchReleaseFulfillment = createAsyncThunk(
  "releaseFulfillment/get",
  (params: ReleaseFulfillmentParams) => {
    return releaseFulfillment(params);
  }
);

export const addOrUpdateOrderTagsThunk = createAsyncThunk(
  "singleOrder/addOrUpdateOrderTagsThunk",
  (params: UpdateOrderParams) => {
    return updateOrder(params);
  }
);

export const updateOrderShippingAddressThunk = createAsyncThunk(
  "singleOrder/updateOrderShippingAddressThunk",
  (params: UpdateOrderParams) => {
    return updateOrder(params);
  }
);

// to update customer contact information
export const updateContactDetailsThunk = createAsyncThunk(
  "singleOrder/updateContactDetailsThunk",
  (params: UpdateOrderParams) => {
    return updateOrder(params);
  }
);

export const fetchAllCountryList = createAsyncThunk(
  "singleOrder/fetchAllCountryList",
  (params: undefined) => {
    return getAllCountryList();
  }
);

export const fetchGetTrackingData = createAsyncThunk(
  "singleOrder/getTrackingData",
  (params: GetTrackingDataParams) => {
    return getTrackingData(params);
  }
);

// to update customer order notes
export const updateCustomerOrderNotesThunk = createAsyncThunk(
  "singleOrder/updateCustomerOrderNotesThunk",
  (params: UpdateOrderParams) => {
    return updateOrder(params);
  }
);

// update tracking
export const updateTrackingThunk = createAsyncThunk(
  "singleOrder/updateTrackingThunk",
  (params:{ updateParams: IUpdateTrackingParams, onSuccessCallBack: Function}) => {
    return updateTracking(params.updateParams, params.onSuccessCallBack);
  }
)

// thunk to get a single shopify customer data
export const getShopifyCustomerDataThunk = createAsyncThunk(
  "singleOrder/getShopifyCustomerDataThunk",
  (params: IGetShopifyCustomerDataParams) => {
    return getShopifyCustomerData(params);
  }
)