import { useCallback, useMemo, useState } from "react";
import { Dropdown } from "react-bootstrap";
import styles from "./ActionTypeDropdown.module.scss";
import { IActionForModal } from "src/services/Automation/getAutomationById.service";
import { ActionOption } from "src/services/Automation/AutomationTicketRouting/getActionOptions.service";
import { useActionsState } from "../../../../../hooks/useActionsState";

interface SelectedFieldType {
  fieldKey: string;
  fieldName?: string;
}

interface Props {
  options: Array<ActionOption>;
  filteredActionOptions: Array<ActionOption>;
  conditionIndex: number;
}

const findSelectedComparisonTypeValue = ({
  selected,
  options,
}: {
  options: Array<ActionOption>;
  selected: SelectedFieldType;
}): null | ActionOption => {
  let option: null | ActionOption = null;
  for (let i = 0; i < options.length; i++) {
    const value = options[i];
    if ((value as ActionOption).fieldKey) {
      if ((value as ActionOption).fieldKey === selected.fieldKey) {
        option = value as ActionOption;
        break;
      }
    }
  }
  return option;
};

export const useSelectedField = ({ options, conditionIndex }: Props) => {
  const { conditionGroup } = useActionsState();
  const selected: SelectedFieldType | null = useMemo(() => {
    const ret = (conditionGroup?.conditions ?? [])[conditionIndex];
    if (ret?.fieldKey) {
      return {
        fieldKey: ret.fieldKey,
      };
    } else {
      return null;
    }
  }, [conditionGroup, conditionIndex]);
  const selectedFieldType = useMemo(() => {
    return selected
      ? findSelectedComparisonTypeValue({ selected, options })
      : null;
  }, [options, selected]);

  return { selectedFieldType };
};

interface PropsConditionType {
  option: ActionOption;
  selected: ActionOption | null;
  onChange: React.MouseEventHandler<HTMLElement>;
}
const ConditionType = ({ option, selected, onChange }: PropsConditionType) => {
  return (
    <div className="ps-2">
      {
        <span
          key={(option as ActionOption).fieldKey}
          className={`me-1 ${styles.dropItem}`}
          onClick={onChange}
          {...((option as ActionOption).fieldKey
            ? {
                "data-field-key": (option as ActionOption).fieldKey,
              }
            : {})}
        >
          {(option as ActionOption).fieldName}
        </span>
      }
    </div>
  );
};

const ActionTypeDropdown = ({
  options,
  conditionIndex,
  filteredActionOptions,
}: Props) => {
  const { conditionGroup, updateState, showErrors } = useActionsState();
  const { selectedFieldType } = useSelectedField({
    options,
    conditionIndex,
    filteredActionOptions,
  });
  const [showMenu, setShowMenu] = useState(false);

  const handleChange: React.MouseEventHandler<HTMLElement> = useCallback(
    (e) => {
      const fieldKey = e.currentTarget.getAttribute("data-field-key");
      if (fieldKey && conditionGroup) {
        // Create a new array by mapping over the existing conditions
        const newActions = conditionGroup.conditions.map((condition, index) => {
          if (index === conditionIndex) {
            // Update only the condition at the specified index
            return {
              ...condition,
              fieldKey: fieldKey,
              value: null,
            };
          }
          // Keep other conditions unchanged
          return condition;
        });

        updateState({
          conditionGroup: {
            ...conditionGroup,
            conditions: newActions,
          },
          showErrors: false,
          saveStatus: "idle",
        });

        setShowMenu(false);
      }
    },
    [conditionGroup, conditionIndex, updateState, setShowMenu],
  );

  return (
    <div className={`me-md-2 mb-2 mb-md-0 ${styles.resWidth}`}>
      <Dropdown
        show={showMenu}
        onToggle={(show) => setShowMenu(show)}
        className={`${styles.resWidth}`}
      >
        <Dropdown.Toggle
          id="dropdown-basic"
          as="div"
          className={`${styles.dropHead}`}
        >
          <div>
            <span>
              {selectedFieldType?.fieldKey ? (
                selectedFieldType.fieldName
              ) : (
                <span className={styles.placeHolder}>Choose action</span>
              )}
            </span>
          </div>
          <span className={`ps-2 ${styles.arrowDown}`}>
            {showMenu ? (
              <i className="fa-solid fa-caret-up"></i>
            ) : (
              <i className="fa-solid fa-caret-down"></i>
            )}
          </span>
        </Dropdown.Toggle>

        <Dropdown.Menu bsPrefix={`dropdown-menu ${styles.dropMenu}`}>
          {filteredActionOptions.map((option) => {
            return (
              <ConditionType
                key={(option as ActionOption).fieldKey}
                option={option}
                selected={selectedFieldType}
                onChange={handleChange}
              />
            );
          })}
        </Dropdown.Menu>
      </Dropdown>

      {showErrors && !selectedFieldType?.fieldKey && (
        <div className={styles.errText}>{"Please enter valid values!"}</div>
      )}
    </div>
  );
};
export default ActionTypeDropdown;
