import { axiosJSON } from "src/globals/axiosEndPoints";

interface IResetPassword {
  token: string;
  password: string;
}

export async function resetPassword(servicePayload: IResetPassword) {
  if ( servicePayload.token == "" ||
  servicePayload.password.trim() == "") {
    throw new Error("Invalid data!");
  }
  const { data } = await axiosJSON.post(`/api/resetPassword`, servicePayload);
  if (data.status == false) {
    throw new Error(data.message as string);
  }
  // contains status, error, message
  return data;
}
