import styles from "./AutoOrder.module.scss";
import OrderBox from "./Children/OrderBox/OrderBox";
import dummy1 from "src/assets/images/order1.png";
import dummy2 from "src/assets/images/order2.png";
import dummy3 from "src/assets/images/order3.png";
import dummy4 from "src/assets/images/order4.png";
import dummy5 from "src/assets/images/order5.png";
import SelectAccount from "src/routes/Setting/children/MainSetting/children/BrowseIntegration/Children/SmsIntegration/InstaIntegration/children/SelectAccount/SelectAccount";
import SelectAction from "./Children/SelectAction/SelectAction";
import TimelineWork from "./Children/TimelineWork/TimelineWork";
import ReturnItem from "./Children/ReturnItem/ReturnItem";
import ReturnType from "./Children/ReturnType/ReturnType";
import Reasons from "./Children/Reasons/Reasons";
import MoreDetails from "./Children/MoreDetails/MoreDetails";
import ReturnSummary from "./Children/ReturnSummary/ReturnSummary";
import ReturnMethod from "./Children/ReturnMethod/ReturnMethod";
import OrderSummary from "./Children/OrderSummary/OrderSummary";
import Confirmation from "./Children/OrderSummary/Confirmation/Confirmation";
import OrderStatusFlow from "./Children/OrderStatusFlow/OrderStatusFlow";
import NotEligible from "./Children/NotEligible/NotEligible";
import ReturnPolicy from "./Children/ReturnPolicy/ReturnPolicy";
import { useAppSelector } from "src/store/store";
import ReturnMethodInstructions from "./Children/ReturnMethodInstructions/ReturnMethodInstructions";

const AutoOrder = (props: { type: string }) => {
  const selectedReturnMethod = useAppSelector((state) => {
    return state.step14Config.returnMethods.find(
      (retM) =>
        retM.returnMethodId === state.step14Config.selectedReturnMethodId
    );
  });

  return (
    <>
      <div className={`row`}>
        <div className="col-12">
          <div className={`py-2 ${styles.innerSection}`}>
            <div className={`w-100 ${styles.flowSection}`}>
              <TimelineWork  type={props.type} />
            </div>

            {/* html for 11 page of 62  */}
            {/* <div>
              <p className={`${styles.heading}`}> Your orders :</p>
              <span className={`d-block px-1 my-2 py-1 ${styles.errorMsg}`}>
                *Please select a order
              </span>
              <OrderBox
                orderId={" #6979890"}
                items={"4"}
                date={" 03/07/2022"}
                orderName={"Acme T-shirt"}
                orderData={"$ 789 x 2"}
                orderData2={"SKU : 0101"}
                orderData3={"$ 30 x 2"}
                orderData4={"SKU : 0101"}
                orderTotal={"$820"}
                orderImg1={dummy1}
                orderImg2={dummy2}
                moreItem={""}
                orderName2={"Acme Shoes"}
              />
              <OrderBox
                orderId={" #6979890"}
                items={"4"}
                date={" 03/07/2022"}
                orderName={"Acme T-shirt"}
                orderData={"$ 789 x 2"}
                orderData2={"SKU : 0101"}
                orderData3={"$ 30 x 2"}
                orderData4={"SKU : 0101"}
                orderTotal={"$820"}
                orderImg1={dummy1}
                orderImg2={dummy2}
                moreItem={"8 more items"}
                orderName2={"Acme Shoes"}
              />
            </div> */}

            <div>
              {/* Select action component  */}
              {/* <SelectAction /> */}
              {/* ReturnItem component  */}
              {/* <ReturnItem /> */}
              {/* Return Type component  */}

              {selectedReturnMethod?.shipWithAnyCarrier.showInstruction ||
              selectedReturnMethod?.shipWithPrepaidLabels.showInstruction ||
              props.type == "returnInstruction" ? (
                <ReturnMethodInstructions />
              ) : props.type === "return" ? (
                <ReturnType />
              ) : props.type === "reason" ? (
                <Reasons />
              ) : props.type === "returnMethod" ? (
                <ReturnMethod />
              ) : props.type === "orderSummary" ? (
                <OrderSummary />
              ) : props.type === "moreDetails" ? (
                <MoreDetails />
              ) : props.type === "notEligible" ? (
                <NotEligible />
              ) : null}

              {/* More details component  */}
              {/* <MoreDetails /> */}
              {/* Return summary component  */}
              {/* <ReturnSummary /> */}
              {/* Return Method component  */}
              {/* <ReturnMethod /> */}
              {/* Order summary component  */}
              {/* <OrderSummary /> */}
              {/* Confirmation component  */}
              {/* <Confirmation /> */}
              {/* Not eiligible component  */}
              {/* <NotEligible /> */}
              {/* ReturnPolicy component  */}
              {/* <ReturnPolicy /> */}
            </div>
            {/* <div><OrderStatusFlow /></div> */}
          </div>
          {/* For 60/62 of xd  */}
          {/* <div
            className={`mt-1 d-flex justify-content-between aling-items-center position-sticky bottom-0 px-2`}
          >
            <button className={`py-1 px-2 ${styles.backBtn}`}>
              <span className="pe-1">
              <i className="fa-solid fa-arrow-left"></i>
              </span> Back to home </button>
          
          </div> */}
          {/* Note:-   hide this div for not eligible section  */}

          <div
            className={`mt-1 d-flex justify-content-between position-sticky bottom-0 px-2`}
          >
            <button className={`py-1 px-3 ${styles.backBtn}`}>Back</button>
            <button
              className={`border-0 py-1 px-3 text-white ${styles.nextBtn}`}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default AutoOrder;
