import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AJAXSTATUS } from "src/globals/constants";
import fetchCombinedHistoryData, {
  CombinedHistoryDataI,
} from "src/services/LiveChat/liveChatHistory/fetchCombinedHistoryData.service";
import {
  getLiveChatCustomerAllMessages,
  LiveChatCustomerAllMessagePayload,
} from "src/services/LiveChat/liveChatHistory/getLiveChatCustomerAllMessages.service";
import { LiveChatHistoryConversationPayload } from "src/services/LiveChat/liveChatHistory/getLiveChatHistoryConversation.service";

interface Props extends Omit<LiveChatHistoryConversationPayload, "customerId"> {
  customerId?: string | number;
}

const useCombinedHistory = (payload: Props) => {
  const [allHistoryData, setAllHistoryData] = useState(
    null as null | CombinedHistoryDataI,
  );
  const historySidebar = useMemo(
    () => allHistoryData?.historySidebar,
    [allHistoryData],
  );
  const historyMessages = useMemo(
    () => allHistoryData?.historyMessages,
    [allHistoryData],
  );
  const fetchingNext = useRef(false);
  const currentState = useRef({
    customerId: payload.customerId,
  });

  useMemo(() => {
    currentState.current.customerId = payload.customerId;
  }, [payload.customerId]);

  const [fetchStatus, setFetchStatus] = useState("pending" as AJAXSTATUS);
  const messagePayload = useMemo(() => {
    const p: LiveChatCustomerAllMessagePayload = {
      customerId: payload.customerId + "",
      start: 0,
      limit: 30,
      filters: {
        sortBy: payload.filters.sortBy === "oldestFirst" ? "asc" : "desc",
      },
    };
    return p;
  }, [payload]);

  const fetchLastMessages = useCallback(
    (callback?: () => void) => {
      const p = { ...messagePayload };
      p.start = 0;
      p.limit = 5;
      getLiveChatCustomerAllMessages(p)
        .then((res) => {
          if (
            parseInt(currentState.current.customerId + "") !==
            parseInt(res.metaData.customerId + "")
          ) {
            return;
          }
          setAllHistoryData((prev) => {
            if (prev) {
              const theData: CombinedHistoryDataI = {
                historySidebar: prev.historySidebar,
                historyMessages: prev.historyMessages
                  ? { ...prev.historyMessages }
                  : null,
              };
              if (theData.historyMessages) {
                if (res) {
                  theData.historyMessages.metaData = res.metaData;
                  for (let i = res.messageIds.length - 1; i >= 0; i--) {
                    const id = res.messageIds[i];
                    if (
                      theData.historyMessages &&
                      !theData.historyMessages.messageIds.includes(id)
                    ) {
                      theData.historyMessages.messageIds.unshift(id);
                    }
                  }
                  theData.historyMessages.messages = {
                    ...theData.historyMessages.messages,
                    ...res.messages,
                  };
                }
              } else {
                theData.historyMessages = res;
              }
              return theData;
            } else {
              return prev;
            }
          });
          if (callback) {
            callback();
          }
        })
        .catch((err) => {});
    },
    [messagePayload],
  );

  const fetchNextData = useCallback(() => {
    if (
      allHistoryData &&
      !allHistoryData?.historySidebar.metaData.limitReached &&
      payload.customerId &&
      fetchingNext.current === false
    ) {
      fetchingNext.current = true;
      const lastMessageId = allHistoryData?.historyMessages?.messageIds
        ? allHistoryData?.historyMessages?.messageIds[
            allHistoryData?.historyMessages?.messageIds.length - 1
          ]
        : undefined;

      const lastMessageIdParts = lastMessageId?.split("::");
      const startFromId = lastMessageIdParts
        ? lastMessageIdParts[1]
        : undefined;
      const startFromIdType = lastMessageIdParts
        ? lastMessageIdParts[0]
        : undefined;

      fetchCombinedHistoryData({
        payload: {
          ...(payload as LiveChatHistoryConversationPayload),
          start: allHistoryData?.historySidebar.conversationDataIds.length ?? 0,
        },
        messagePayload: {
          ...messagePayload,
          start: allHistoryData?.historyMessages?.messageIds?.length ?? 0,
          startFromId: startFromId,
          startFromIdType: startFromIdType as "ticket" | "chat" | "message",
        },
      })
        .then((res) => {
          if (
            parseInt(currentState.current.customerId + "") !==
            parseInt(res.customerId + "")
          ) {
            return;
          }
          setAllHistoryData((prev) => {
            if (prev) {
              const theData: CombinedHistoryDataI = {
                historySidebar: { ...prev.historySidebar },
                historyMessages: prev.historyMessages
                  ? { ...prev.historyMessages }
                  : null,
              };
              theData.historySidebar.conversationDataIds = [
                ...theData.historySidebar.conversationDataIds,
                ...res.historySidebar.conversationDataIds,
              ];
              theData.historySidebar.conversationData = {
                ...theData.historySidebar.conversationData,
                ...res.historySidebar.conversationData,
              };
              theData.historySidebar.metaData = res.historySidebar.metaData;
              if (theData.historyMessages) {
                if (res.historyMessages) {
                  theData.historyMessages.metaData =
                    res.historyMessages.metaData;
                  res.historyMessages.messageIds.forEach((id) => {
                    if (
                      theData.historyMessages &&
                      !theData.historyMessages.messageIds.includes(id)
                    ) {
                      theData.historyMessages.messageIds.push(id);
                    }
                  });
                  theData.historyMessages.messages = {
                    ...theData.historyMessages.messages,
                    ...res.historyMessages.messages,
                  };
                }
              } else {
                theData.historyMessages = res.historyMessages;
              }
              return theData;
            } else {
              return res;
            }
          });
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          fetchingNext.current = false;
        });
    }
  }, [allHistoryData, payload, messagePayload]);

  useEffect(() => {
    setAllHistoryData(null);
    setFetchStatus("pending");
    if (payload.customerId) {
      fetchCombinedHistoryData({
        payload: payload as LiveChatHistoryConversationPayload,
        messagePayload: { ...messagePayload },
      })
        .then((res) => {
          if (
            parseInt(currentState.current.customerId + "") !==
            parseInt(res.customerId + "")
          ) {
            return;
          }
          setAllHistoryData(res);
          setFetchStatus("fulfilled");
        })
        .catch((err) => {
          console.error(err);
          setFetchStatus("rejected");
        });
    }
  }, [payload.customerId, payload.filters]);

  return {
    historySidebar,
    historyMessages,
    fetchNextData,
    fetchStatus,
    fetchLastMessages,
  };
};

export default useCombinedHistory;
