import { OverlayTrigger, Popover } from "react-bootstrap";
import styles from "./CopyPateDraft.module.scss";
import { useCallback, useState } from "react";
import { useAppDispatch } from "src/store/store";
import { setCopiedAiDraft } from "src/store/slices/innerTicket/innerTicket.slice";
import { v4 as uuid } from "uuid";

const popover = (
  <Popover id="popover-basic">
    <Popover.Body bsPrefix={`popover-body ${styles.popOver}`}>
      <span className={`py-0 ${styles.copyText}`}>
        <span className="d-none"> Copy</span>

        <div className="d-flex align-items-center">
          <div className={`${styles.copyBox}`}>
            <span>
              <i className="fa-solid fa-check"></i>
            </span>
          </div>
          <span className="ps-1">Copied</span>
        </div>
      </span>
    </Popover.Body>
  </Popover>
);
const CopyPasteDraft = ({ draftMessage }: { draftMessage: string }) => {
  const [show, setShow] = useState(false);
  const dispatch = useAppDispatch();

  /**
   * Handler to copy and paste ai draft to message box
   */
  const handleCopyPaste = useCallback(() => {
    if (draftMessage) {
      // Copy message to clipboard
      navigator.clipboard.writeText(draftMessage);
      // Set draft message to redux store
      dispatch(
        setCopiedAiDraft({
          message: draftMessage,
          id: uuid(), // Adding id to trigger useEffect when user copy draft and clear editer then again copy draft
        }),
      );
      setShow(true);
      setTimeout(function () {
        setShow(false);
      }, 2000);
    }
  }, [draftMessage]);

  return (
    <div>
      <OverlayTrigger
        trigger="hover"
        placement="top"
        overlay={popover}
        show={show}
      >
        <div>
          <button
            className={`${styles.copyBtn}`}
            onClick={handleCopyPaste}
          >
            <span>Copy draft</span>
            <span className="ps-1">
              <i className="fa-regular fa-pen-to-square"></i>
            </span>
          </button>
        </div>
      </OverlayTrigger>
    </div>
  );
};

export default CopyPasteDraft;
