import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AJAXSTATUS } from "src/globals/constants";
import { ASSIGN_CHAT_RES_STATUS_CODE } from "src/services/LiveChat/traffic/assignChatFromTraffic.service";
import { ITabTypeCount, ITrafficListItem } from "src/services/LiveChat/traffic/getTrafficList.service";
import {
  assignChatFulfilled,
  fetchLiveChatTrafficFulfilled,
  fetchLiveChatUpdatedTrafficFulfilled,
} from "./liveChatTraffic.extraReducers";
import liveChatTrafficReducers from "./liveChatTraffic.reducers";
import liveChatTrafficThunks from "./liveChatTraffic.thunks";

export type TRAFFIC_FILTER_TYPE =
  | "all"
  | "chatting"
  | "queued"
  | "invited"
  | "browsing"
  | "waiting_for_reply";

export interface ILiveChatTrafficState {
  trafficItemList: { [id: number | string]: ITrafficListItem };
  trafficItemIdsList: Array<number | string>;
  fetchTrafficDataAjaxStatus: AJAXSTATUS;
  fetchUpdatedTrafficDataAjaxStatus: AJAXSTATUS;
  assignChatAjaxStatus: AJAXSTATUS;
  trafficFetchLimit: number;
  trafficFilterType: TRAFFIC_FILTER_TYPE;
  assignChatStatusCode: ASSIGN_CHAT_RES_STATUS_CODE;
  trafficTypeTabCount: ITabTypeCount;
  metaData: {
    count: number;
    total: number | null;
  };
}

// to fetch all traffics data
export const fetchLiveChatTrafficData = createAsyncThunk(
  "liveChatTraffic/fetchLiveChatTrafficData",
  liveChatTrafficThunks.fetchLiveChatTrafficDataThunk
);

export const fetchLiveChatTrafficUpdatedData = createAsyncThunk(
  "liveChatTraffic/fetchLiveChatTrafficUpdatedData",
  liveChatTrafficThunks.fetchLiveChatTrafficFullDataThunk
);

export const assignChatFromTraffic = createAsyncThunk(
  "liveChatTraffic/assignChatFromTraffic",
  liveChatTrafficThunks.assignLiveChatThunk
);

export const initialState: ILiveChatTrafficState = {
  trafficItemList: {},
  trafficItemIdsList: [],
  fetchTrafficDataAjaxStatus: "pending",
  fetchUpdatedTrafficDataAjaxStatus: "pending",
  assignChatAjaxStatus: "idle",
  trafficFetchLimit: 10,
  trafficFilterType: "all",
  assignChatStatusCode: "",
  trafficTypeTabCount: {
    all: 0,
    chatting: 0,
    queued: 0,
    waitingForReply: 0
  },
  metaData: {
    count: 0,
    total: null,
  },
};

export const liveChatTrafficSlice = createSlice({
  name: "liveChatTraffic",
  initialState: initialState,
  reducers: liveChatTrafficReducers,
  extraReducers: (builder) => {
    builder.addCase(fetchLiveChatTrafficData.pending, (state) => {
      state.fetchTrafficDataAjaxStatus = "pending";
    });

    builder.addCase(
      fetchLiveChatTrafficData.fulfilled,
      fetchLiveChatTrafficFulfilled
    );

    builder.addCase(fetchLiveChatTrafficData.rejected, (state) => {
      state.fetchTrafficDataAjaxStatus = "rejected";
    });

    builder.addCase(fetchLiveChatTrafficUpdatedData.pending, (state) => {
      state.fetchUpdatedTrafficDataAjaxStatus = "pending";
    });

    builder.addCase(
      fetchLiveChatTrafficUpdatedData.fulfilled,
      fetchLiveChatUpdatedTrafficFulfilled
    );

    builder.addCase(fetchLiveChatTrafficUpdatedData.rejected, (state) => {
      state.fetchUpdatedTrafficDataAjaxStatus = "rejected";
    });

    builder.addCase(assignChatFromTraffic.pending, (state) => {
      state.assignChatAjaxStatus = "pending";
    });

    builder.addCase(assignChatFromTraffic.fulfilled, assignChatFulfilled);

    builder.addCase(assignChatFromTraffic.rejected, (state) => {
      state.assignChatAjaxStatus = "rejected";
    });
  },
});

export const {
  resetLiveChatTrafficData,
  setTrafficFilterType,
  resetTrafficFilterType,
  resetChatAssignStatusCode,
  setAssignChatAjaxStatus,
  resetLiveChatTrafficDataExceptTabCount,
  setTrafficTypeTabCount,
  setTrafficData
} = liveChatTrafficSlice.actions;

export default liveChatTrafficSlice.reducer;
