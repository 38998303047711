import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { updateCustomerOrderNotesThunk } from "src/store/slices/singleOrder/order.thunks";
import { useAppDispatch, useAppSelector } from "src/store/store";
import styles from "./EditCustomerNote.module.scss";

const EditCustomerNote = (props: { onHide: () => void }) => {
  const dispatch = useAppDispatch();
  const { getOrderViewModel, updateOrder } = useAppSelector(
    (state) => state.singleOrder
  );
  const [customerNote, setCustomerNote] = useState<string>("");

  useEffect(() => {
    if (getOrderViewModel && getOrderViewModel?.customerNotes) {
      setCustomerNote(getOrderViewModel?.customerNotes);
    }
  }, [getOrderViewModel]);

  // handle save note submit
  const handleNoteSave = () => {
    if (
      getOrderViewModel &&
      getOrderViewModel?.orderId &&
      updateOrder?.customerOrderNotesUpdateAjaxStatus !== "pending"
    ) {
      dispatch(
        updateCustomerOrderNotesThunk({
          orderId: getOrderViewModel?.orderId,
          note: customerNote.trim(),
        })
      );
      props.onHide();
    }
  };

  return (
    <div>
      <>
        <Modal.Header closeButton>
          <Modal.Title className={`${styles.paymentDetail} text-dark fs-6`}>
            Edit Note
          </Modal.Title>
        </Modal.Header>

        <Modal.Body
          className={`d-flex flex-column justify-content-between ${styles.noteForm}`}
        >
          <div>
            <div>
              <Form className={`${styles.noteForm}`}>
                <span className="text-dark ">Notes </span>
                <Form.Control
                  as="textarea"
                  value={customerNote}
                  onChange={(e) => setCustomerNote(e.target.value)}
                  placeholder="Enter note"
                  className={`${styles.textArea}`}
                  rows={2}
                />
              </Form>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div
            className={`d-flex  justify-content-end  w-100 ${styles.noteForm} `}
          >
            <div className="d-flex ">
              <div className="me-1 ">
                <Button
                  variant="outline-secondary"
                  className="text-dark"
                  onClick={() => props?.onHide()}
                >
                  Cancel
                </Button>
              </div>

              <div>
                <Button
                  variant="outline-light border"
                  className="text-white "
                  style={{ backgroundColor: "#2b7554" }}
                  onClick={() => handleNoteSave()}
                >
                  Save{" "}
                </Button>{" "}
              </div>
            </div>
          </div>
        </Modal.Footer>
      </>
    </div>
  );
};

export default EditCustomerNote;
