import { useState } from "react";
import styles from "./BugBulkAction.module.scss";
import { Modal } from "react-bootstrap";
import MergeModal from "./Children/MergeModal/MergeModal";
import { AllCategoryNormalizedData } from "src/services/BugReport/getBugCategory.service";
import { BugData } from "src/services/BugReport/getBugByCategory.service";

interface BuldActionProps {
  selectedBugsData: BugData[];
  setSelectedBugId: React.Dispatch<React.SetStateAction<Set<number>>>;
}

const BugBulkAction = ({
  selectedBugsData,
  setSelectedBugId,
}: BuldActionProps) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className={`${styles.bulkWrappper}`}>
      <p className={`mb-2 ${styles.heading}`}>
        {" "}
        Bulk action ({selectedBugsData.length} Selected )
      </p>
      <button
        className={`${styles.mergeBtn}`}
        onClick={() => setShowModal(true)}
        id="bug_bulAction_btn"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="14.512"
          viewBox="0 0 16 14.512"
        >
          <g
            id="Iconly_Light-Outline_Message"
            data-name="Iconly/Light-Outline/Message"
            transform="translate(-1 -2)"
          >
            <g
              id="Message"
              transform="translate(1 2)"
            >
              <path
                id="Combined-Shape"
                d="M11.653,1A4.436,4.436,0,0,1,16,5.7v5.111a4.868,4.868,0,0,1-1.367,3.492,4.114,4.114,0,0,1-2.968,1.206H4.333a4.111,4.111,0,0,1-2.966-1.206A4.868,4.868,0,0,1,0,10.814V5.7A4.436,4.436,0,0,1,4.347,1Zm0,1.116H4.347A3.31,3.31,0,0,0,1.116,5.7v5.111a3.76,3.76,0,0,0,1.039,2.7,3.014,3.014,0,0,0,2.18.88h7.318s.007,0,.012,0a3.013,3.013,0,0,0,2.18-.88,3.757,3.757,0,0,0,1.039-2.7V5.7A3.31,3.31,0,0,0,11.653,2.116Zm1.173,3.445a.559.559,0,0,1-.081.785L9.438,9.034a2.264,2.264,0,0,1-2.827,0L3.273,6.348a.558.558,0,1,1,.7-.869L7.308,8.166a1.149,1.149,0,0,0,1.431,0l3.3-2.684A.557.557,0,0,1,12.826,5.561Z"
                transform="translate(0 -1)"
                fill-rule="evenodd"
              />
            </g>
          </g>
        </svg>
        <span className="ps-1">Merge similar bugs</span>
      </button>
      <Modal
        backdropClassName={`${styles.modalBack}`}
        show={showModal}
        dialogClassName={`${styles.modalDialog}`}
        contentClassName={`${styles.modalContent}`}
        centered={true}
        enforceFocus={false}
      >
        <MergeModal
          onHide={() => setShowModal(false)}
          selectedBugsData={selectedBugsData}
          setSelectedBugId={setSelectedBugId}
        />
      </Modal>
    </div>
  );
};

export default BugBulkAction;
