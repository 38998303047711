import { getFormElementOptions } from "src/services/LiveChat/Settings/preChatForm/getPreChatFormData";
import { getTicketChatFormData } from "src/services/LiveChat/Settings/ticketChatForm/getTicketChatFormData";
import { RootState } from "src/store/store";

const fetchTicketChatFormDataThunk = async (
  payload: number|string,
  { getState, dispatch }: { getState: Function; dispatch: Function }
) => {
  const currentState: RootState = getState();

  let formElementOptions = currentState.ticketChatFormSetting.formElementOptions;
  if (formElementOptions.formElementOptionIds.length === 0) {
    formElementOptions = await getFormElementOptions({formType:"ticketForm"});
  }
  const ticketChatFormData = await getTicketChatFormData({
    integrationId: payload,
  });

  return {ticketChatFormData ,formElementOptions };
};

export default { fetchTicketChatFormDataThunk};
