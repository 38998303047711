import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";
import { BotProfile } from "./getAllBotProfiles.service";

const apiEndPoint = false
  ? `${process.env.REACT_APP_TEST_SITE_URL}/botProfile/get`
  : "/api/botProfile/get";

export interface GetBotProfileParams {
  id: number;
}

export interface GetBotProfileResponse {
  data: BotProfile;
}

export const getBotProfile = async ( params: GetBotProfileParams ) => {
  const { data: res } = await axiosJSON.get( apiEndPoint, { params } );
  if ( res.err ) {
    throw res.msg;
  }

  return res.data as BotProfile;
};
