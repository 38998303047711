/**
 * This file is the base App file.
 * It contains the routes, auth and layout.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { Route, Routes, useLocation } from "react-router-dom";
import ToastContainer from "./containers/ToastContainer/ToastContainer";
import Ticket from "src/routes/Ticket/Ticket";
import TicketList from "src/routes/TicketList/TicketList";
import SideBar, { RouteNames } from "./containers/SideBar/SideBar";
import GlobalLoader, {
  globalLoaderName,
  showGlobalLoader,
} from "./components/GlobalLoader/GlobalLoader";
import Integration from "./routes/Integration";
import LogIn from "./routes/Auth/LogIn";
import SignUp from "./routes/Auth/SignUp";
import useLogin from "./hooks/auth/useLogin";
import { useEffect, useRef } from "react";
import Setting from "./routes/Setting/Setting";
import LiveChat from "./routes/LiveChat/LiveChat";
import { useAppSelector } from "./store/store";
import ResetPassword from "./routes/Auth/ResetPassword";
import Home from "./routes/Home/Home";
import LinkExpired from "./routes/Auth/LinkExpired/LinkExpired";
import CallRecording from "./routes/CallRecording/CallRecording";
import { showLastPassIcons } from "./utils/utils";
import LiveChatAssignedNotification from "./components/LiveChatAssignedNotification";
import SelfService from "./routes/SelfService/SelfService";
import KnowledgeBaseUpdated from "./routes/KnowledgeBaseUpdated/KnowledgeBaseUpdated";
import Report from "./routes/Report/Report";
import BrandBasedKB from "./routes/KnowledgeBaseUpdated/BrandBasedKB/BrandBasedKB";
import BotSettings from "./routes/BotSettings/BotSettings";
import HelpdeskNotificationBar from "./components/HelpdeskNotificationBar";
import CustomerSegments from "./routes/CustomerSegments/CustomerSegments";

function canShowSidebar() {
  return (
    !window.location.pathname.includes("login") &&
    !window.location.pathname.includes("signup") &&
    !window.location.pathname.includes("reset-password") &&
    !window.location.pathname.includes("link-expired")
  );
}
// Define the path you want to check
const specificPath = "tickets/:tableType/:viewType/ticket/:ticketId";
function canShowBotSidebar() {
  return window.location.pathname.includes(specificPath);
}
console.log(canShowBotSidebar());

const App = () => {
  const { loginData, setLoginData, initialized } = useLogin();
  const currentUserData = useAppSelector(
    (state) => state.globals.currentUserData,
  );
  const unreadChatCounts = useAppSelector(
    (state) => state.globals.unreadChatIds,
  );

  const location = useLocation();
  useEffect(() => {
    if (initialized) {
      showGlobalLoader("None");
    } else {
      showGlobalLoader("Initializing");
    }
  }, [initialized]);
  useEffect(() => {
    setTimeout(() => {
      if (globalLoaderName !== "None") {
        showGlobalLoader("None");
      }
    }, 0);
  }, [location.pathname]);
  useEffect(() => {
    showLastPassIcons(loginData?.status ? true : false);
  }, [loginData?.status]);

  const documentTitleBlinkRef = useRef(false);

  useEffect(() => {
    let interval: undefined | NodeJS.Timeout = undefined;
    if (loginData?.status) {
      if (unreadChatCounts === 0 || !unreadChatCounts) {
        const existingTitle = document.title.replace(/\s*\(\d+\)\s*/g, "");
        document.title = existingTitle;
        clearInterval(interval);
      } else {
        interval = setInterval(() => {
          const existingTitle = document.title.replace(/\s*\(\d+\)\s*/g, "");
          if (documentTitleBlinkRef.current) {
            // Create a new title with the count of unread messages
            document.title = `(${unreadChatCounts}) ${existingTitle}`;
            documentTitleBlinkRef.current = false;
          } else {
            document.title = existingTitle;
            documentTitleBlinkRef.current = true;
          }
        }, 1000);
      }
    } else {
      const existingTitle = document.title.replace(/\s*\(\d+\)\s*/g, "");
      document.title = existingTitle;
    }

    return () => {
      clearInterval(interval);
    };
  }, [loginData, unreadChatCounts]);

  return (
    <>
      <ToastContainer />
      <GlobalLoader />
      {initialized && (
        <main>
          {canShowSidebar() && loginData && <SideBar loginData={loginData} />}
          {loginData && <LiveChatAssignedNotification />}
          {loginData && <HelpdeskNotificationBar />}
          <Routes>
            {loginData && (
              <>
                <Route
                  path={`shopify-integration`}
                  element={<Integration />}
                />
                <Route
                  path={`shopify-integration/success`}
                  element={
                    <div className="mx-2 mt-5">
                      <h2>Shopify integrated successfully!</h2>
                    </div>
                  }
                />
                <Route
                  path={`/`}
                  element={<Home />}
                />
                <Route
                  path={`tickets/:tableType/:viewType`}
                  element={<TicketList />}
                />
                <Route
                  path={`tickets/:tableType/:viewType/ticket/:ticketId`}
                  element={<Ticket />}
                />
                <Route
                  path={`tickets/:tableType/:viewType/ticket/:ticketId/search/:searchText`}
                  element={<Ticket />}
                />
                <Route
                  path={`/live-chat/*`}
                  element={<LiveChat />}
                />

                {/* Routes for Knowledge Base Updated */}
                {currentUserData?.ui_visibility &&
                  currentUserData?.ui_visibility?.includes(
                    "knowledge_base_view",
                  ) && (
                    <>
                      <Route
                        path={`/knowledge-base/*`}
                        element={<BrandBasedKB />}
                      />
                      {/* Apr 4 Added this check to disable the routes when it includes in disabledFeatures */}
                      {!(
                        currentUserData.disabledFeatures &&
                        currentUserData.disabledFeatures.includes(
                          "knowledge_base",
                        )
                      ) && (
                        <Route
                          path={`/knowledge-base/:integrationId/*`}
                          element={<KnowledgeBaseUpdated />}
                        />
                      )}
                    </>
                  )}

                {Setting({ currentUserData })}

                {currentUserData?.ui_visibility?.includes("bug_management") && (
                  <Route
                    path={`/selfService/:serviceName`}
                    element={<SelfService />}
                  />
                )}

                {/* recordings route */}
                {currentUserData?.ui_visibility?.includes(
                  "recordings_view",
                ) && (
                  <Route
                    path={`/recordings`}
                    element={<CallRecording />}
                  />
                )}

                {/* bot route */}
                {currentUserData?.ui_visibility &&
                  currentUserData?.ui_visibility?.includes("chat_bot_view") && (
                    <Route
                      path={`/bot/*`}
                      element={<BotSettings />}
                    />
                  )}
                {currentUserData?.ui_visibility &&
                  currentUserData?.ui_visibility?.includes(
                    "automation_view",
                  ) && (
                    <Route
                      path={`/comingSoon/*`}
                      element={<BotSettings />}
                    />
                  )}
                <Route
                  path={`/automations/*`}
                  element={<BotSettings />}
                />

                {/* reports route */}
                {currentUserData?.ui_visibility?.includes("reports_view") && (
                  <Route
                    path={`/report/:viewType`}
                    element={<Report />}
                  />
                )}

                {/* Example Route - /customer-segments/userSegment/allPeople */}
                {(currentUserData?.ui_visibility?.includes(
                  "company_segment_view",
                ) ||
                  currentUserData?.ui_visibility?.includes(
                    "user_segment_view",
                  )) && (
                  <>
                    <Route
                      path={`${RouteNames.customerSegments}/`}
                      element={<CustomerSegments />}
                    />
                    <Route
                      path={`${RouteNames.customerSegments}/:segmentType/:segmentId`}
                      element={<CustomerSegments />}
                    />
                  </>
                )}
              </>
            )}
            <Route
              path={`signup/:payload`}
              element={<SignUp />}
            />
            <Route
              path={`reset-password/:token`}
              element={<ResetPassword />}
            />
            <Route
              path={`link-expired`}
              element={<LinkExpired />}
            />
            <Route
              path={`login`}
              element={<LogIn setLoginData={setLoginData} />}
            />
          </Routes>
        </main>
      )}
    </>
  );
};
export default App;
