import {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import {
  GetAllTemplatesPayload,
  TemplateStep,
} from "src/services/LiveChat/ScheduleFollowups/getAllTemplates";
import { templateReducer, useTemplates } from "../MainSection/TemplateContext";
import useErrorMessage from "../ErrorMessage/errorMessage";
import Loader from "src/components/Loader";
import { useGetAllTemplates } from "./useGetAllTemplates";
import { formatDateTimeString } from "src/utils/dateLibrary";
import { useAppSelector } from "src/store/store";

function useScheduleModa(scheduleMessageFor: "innerTicket" | "liveChat") {
  const params: GetAllTemplatesPayload = {
    templateType:
      scheduleMessageFor === "innerTicket"
        ? "inner_ticket_template"
        : "live_chat_template",
    start: 0,
    limit: 20,
  };

  const {
    data,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isError,
    fetchStatus,
    status,
  } = useGetAllTemplates({ payload: params });

  const userTimezone = useAppSelector(
    (state) => state.globals.currentUserData?.userTimezone
  );

  const [templates, dispatch] = useReducer(
    templateReducer,
    {} as { [key: string]: TemplateStep[] }
  );

  const {templateIds, totalCount} = useMemo(() => {
    let ids = data
      ? data.pages.flatMap((page) =>
          page.data.map((template) => parseInt(template.templateId + ""))
        )
      : [];

     // Get the total value from the last fetched page
     const totalPages = data?.pages || [];
     const lastPage = totalPages[totalPages.length - 1];
     const total = lastPage?.metaData?.totalCount ?? 0;
    return {templateIds: ids, totalCount: total};
  }, [data]);

  useEffect(() => {
    if (data) {
      const templateSteps = data?.pages
        .flatMap((page) => {
          return page.data.reduce(
            (acc: any, template: any) => ({
              ...acc,
              [template.templateId]: template.templateSteps.map(
                (stepData: any) => {
                  if (stepData.scheduleFollowUpType === "dateAndTime") {
                    const dateTime = new Date(
                      stepData.scheduleFollowUpValue + "Z"
                    );
                    const dateInUserTimezone = formatDateTimeString(
                      dateTime.toLocaleString("en-US", {
                        timeZone: userTimezone,
                      })
                    );
                    return {
                      ...stepData,
                      scheduleFollowUpValue: dateInUserTimezone,
                    };
                  } else {
                    return stepData;
                  }
                }
              ),
            }),
            {}
          );
        })
        .reduce((acc: any, obj: any) => {
          return { ...acc, ...obj };
        }, {});
      if (Object.keys(templateSteps).length === 0) {
        dispatch({
          type: "SET_TEMPLATES",
          payload: {
            new: [
              {
                stepId: "new",
                templateHeading: "New Template 1",
                templateMessage: "",
                attachments: [],
                scheduleFollowUpType: "days",
                scheduleFollowUpValue: 1,
                sendMessageCondition: "none",
              } as TemplateStep,
            ],
          },
        });
        setSelectedId("new");
      } else {
        dispatch({
          type: "SET_TEMPLATES",
          payload: JSON.parse(JSON.stringify(templateSteps)),
        });
      }
    }
  }, [data, data?.pages, userTimezone]);

  const [selectedId, setSelectedId] = useState<string | null>(null);

  const initialSelectRef = useRef(true);

  useEffect(() => {
    if (data && initialSelectRef.current) {
      let templateIds = data
        ? data.pages.flatMap((page) =>
            page.data.map((template) => template.templateId)
          )
        : [];
      setSelectedId(templateIds.length > 0 ? templateIds[0] + "" : "new");
      initialSelectRef.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const errorMessage = useErrorMessage(
    status === "error" || (status !== "success" && fetchStatus !== "fetching")
    // (status !== "loading" && Object.keys(templateSteps).length === 0)
  );

  const loader =
    status === "loading" && fetchStatus === "fetching" ? (
      <div className="m-auto w-100">
        <Loader />
      </div>
    ) : undefined;

  // const template = useTemplates();

  const templateSteps = useMemo(() => {
    return data?.pages
      .flatMap((page) => {
        return page.data.reduce(
          (acc: any, template: any) => ({
            ...acc,
            [template.templateId]: template.templateSteps,
          }),
          {}
        );
      })
      .reduce((acc: any, obj: any) => {
        return { ...acc, ...obj };
      }, {});
  }, [data]);

  const currentState = useRef({ templateSteps, selectedId });

  useMemo(() => {
    currentState.current.templateSteps = templateSteps;
  }, [templateSteps]);

  useMemo(()=>{
    currentState.current.selectedId = selectedId;
  },[selectedId])

  const handleSetSelectedId = useCallback((id: string | null) => {
    if (id !== "new") {
      if (
        id &&
        currentState.current.templateSteps &&
        currentState.current.templateSteps[id] && 
        currentState.current.selectedId !== id
      ) {
        dispatch({
          type: "Refresh_Templates",
          payload: {
            templateId: id,
            value: JSON.parse(JSON.stringify(currentState.current.templateSteps[id])),
          },
        });
      }
      setSelectedId(id);
    } else {
      setSelectedId(id);
    }
  }, []);

  const updateTemplateSteps = useCallback(
    (templateId: string, steps: TemplateStep[]) => {
      if (
        templateId && steps &&
        currentState.current.selectedId &&
        currentState.current.templateSteps &&
        currentState.current.templateSteps[templateId]
      ) {
        currentState.current.templateSteps[templateId] = steps;
      }
    },
    []
  );

  return {
    isError,
    isLoading,
    selectedId,
    setSelectedId: handleSetSelectedId,
    templates,
    dispatch,
    handleInfiniteScroll: fetchNextPage,
    hasNextPage,
    errorMessage,
    loader,
    data,
    // template,
    fetchStatus,
    templateIds,
    updateTemplateSteps,
    totalCount,
  };
}

export default useScheduleModa;
