import styles from "./MessageSchedule.module.scss";
import ScheduleBox from "./Children/ScheduleBox/ScheduleBox";
import { useScheduleMessages } from "./Children/CustomHooks/useScheduleMessages";
import InfiniteScroll from "src/components/InfiniteScrollBothSide";
import { Modal } from "react-bootstrap";
import { useState } from "react";
import DeleteSchedule from "./Children/DeleteSchedule/DeleteSchedule";
import closeModal from "src/assets/images/closeScheduleModal.png";
interface Props {
  onHide: () => void;
  scheduleMessageFor: "innerTicket" | "liveChat";
  ticketId?: number | string;
}
const MessageSchedule = ({ onHide, scheduleMessageFor, ticketId }: Props) => {
  const {
    handleInfiniteScroll,
    hasNextPage,
    schedules,
    scheduleIds,
    errorMessage,
    loader,
    updateScheduleData,
    deleteScheduleMessage,
    deleteScheduleMessageSequence,
  } = useScheduleMessages(scheduleMessageFor, ticketId);
  const [deleteLoader, setDeleteLoader] = useState(false);

  function handleDeleteSequence() {
    setDeleteLoader(true);
    setDeleteModal(false);
    setTimeout(() => {
      deleteScheduleMessageSequence()
        .then(() => onHide())
        .catch(() => setDeleteLoader(false));
    }, 0);
  }

  const [deleteModal, setDeleteModal] = useState(false);
  const onShow = (e: any) => {
    e.stopPropagation();
    setDeleteModal(true);
  };
  const onDeleteHide = () => {
    setDeleteModal(false);
  };
  return (
    <>
      <div className={`${styles.messageScheduleWrapper}`}>
        <div
          className={`d-flex justify-content-between align-items-center mb-4`}
        >
          <p className={`mb-0 ${styles.modalHead}`}>Messages Scheduled</p>
          <span className={`${styles.hide}`} onClick={onHide}>
            {/* <i className="fa-solid fa-xmark"></i> */}
            <img src={closeModal} alt="" className={`${styles.closeBtn}`} />
          </span>
        </div>
        <div className={`${styles.contentWrapper}`}>
          <InfiniteScroll
            className="p-2 mt-0 mb-auto mx-auto h-100 w-100 overflow-auto"
            loadMoreFromBottom={handleInfiniteScroll}
            hasMoreBottom={hasNextPage}
          >
            {loader ? (
              loader
            ) : errorMessage ? (
              errorMessage
            ) : (
              <div className="d-flex flex-column justify-content-between">
                {scheduleIds.map((scheduleId) => {
                  const schedule = schedules[scheduleId];
                  return (
                    <ScheduleBox
                      key={scheduleId}
                      schedule={schedule}
                      allSchedules={schedules}
                      updateScheduleData={updateScheduleData}
                      deleteSchedule={deleteScheduleMessage}
                      scheduleMessageFor={scheduleMessageFor}
                    />
                  );
                })}
              </div>
            )}
          </InfiniteScroll>
        </div>
        <div>
          <button
            className={`${styles.deleteSchedule}`}
            disabled={deleteLoader}
            onClick={onShow}
          >
            {deleteLoader === true ? "Deleting..." : "Delete schedule sequence"}
          </button>
          <Modal
            className={`${styles.modalClass}`}
            dialogClassName={`${styles.modalDialog}`}
            contentClassName={`${styles.modalContent}`}
            backdropClassName={`${styles.deleteModalBack}`}
            show={deleteModal}
            onHide={onDeleteHide}
            centered
          >
            <DeleteSchedule
              onHide={onDeleteHide}
              onSubmit={handleDeleteSequence}
            />
          </Modal>
        </div>
      </div>
    </>
  );
};

export default MessageSchedule;
