import { useCallback, useEffect, useMemo, useState } from "react";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import {
  ConditionTypes,
  IVariableName,
  getConditionValueOrDefault,
} from "src/features/ReturnAutoWorkFlow/children/ConfigModals/store/returnModalsSliceHelpers";
import {
  updateNonEligibleOrdersThunk,
  fetchNonEligibleOrdersThunk,
  validateNonEligibleOrders,
} from "../../store/slices/NonEligibleOrdersSlice/nonEligibleOrders.thunk";
import {
  addCondition,
  addOrderType,
  deleteCondition,
  pushCurrentHashForSelectedTab,
  nonEligibleOrdersErrorCountSelector,
  updateCondition,
  updateOrderType,
  updateReturnWindowCondition,
  validateChanges,
  validateCondition,
  validateOrderType,
} from "../../store/slices/NonEligibleOrdersSlice/nonEligibleOrders.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { useReturnAutoWorkFlow } from "src/features/ReturnAutoWorkFlow/hooks/useReturnAutoWorkFlow";

function useNonEligibleOrders({
  onSave,
}: {
  onSave: (isSaved: boolean) => void;
}) {
  const { returnAutoWorkFlow } = useReturnAutoWorkFlow();
  const dispatch = useAppDispatch();

  const {
    step9Configuration: {
      selectedOrderTypeId,
      orderTypes,
      operatorsNames: operators,
      variableNameToValueType: valuesTypes,
      conditionOptions: conditionOptionsRaw,
      defaultOptions: defaultOptionsRaw,
      returnWindowOptions: returnWindowOptionsRaw,
    },
    fetchAjaxStatus,
    updateAjaxStatus,
    initialHash,
    currentHash,
  } = useAppSelector((state) => {
    return state.nonEligibleOrders;
  });

  const [selectedOrder, selectedOrderIndex] = useMemo(() => {
    const index = orderTypes.findIndex(
      (order) => order.orderTypeId === selectedOrderTypeId,
    );
    const order = index !== -1 ? orderTypes[index] : undefined;
    return [order, index];
  }, [orderTypes, selectedOrderTypeId]);

  const isAddBtnEnabled = useMemo(
    () => selectedOrder?.isAddConditionEnabled ?? false,
    [selectedOrder?.isAddConditionEnabled],
  );

  const returnWindowOptions = useMemo(
    () => Object.values(returnWindowOptionsRaw).map((option) => option.name),
    [returnWindowOptionsRaw],
  );

  const conditionOptions = useMemo(
    () => Object.values(conditionOptionsRaw).map((option) => option.name),
    [conditionOptionsRaw],
  );

  const defaultOptions = useMemo(
    () => Object.values(defaultOptionsRaw).map((option) => option.name),
    [defaultOptionsRaw],
  );

  const [otherTabsHasError, setOtherTabsHasError] = useState(false);

  const otherTabErrorCount = useAppSelector(
    nonEligibleOrdersErrorCountSelector,
  );

  useEffect(() => {
    if (otherTabErrorCount <= 0) {
      setOtherTabsHasError(false);
    } else {
      setOtherTabsHasError(true);
    }
  }, [otherTabErrorCount]);

  useEffect(() => {
    if (!returnAutoWorkFlow.integrationId) {
      return;
    }

    // TODO
    dispatch(
      fetchNonEligibleOrdersThunk({
        integrationId: returnAutoWorkFlow.integrationId,
        selectedReturnWindow: "",
      }),
    );
    if (selectedOrderTypeId) {
      dispatch(validateOrderType({ orderTypeId: selectedOrderTypeId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // all the dispatch function which is used in order type config modal

  //function to update tab name
  const dispUpdateOrderType = useCallback(
    (id: string, updateObj: any) => {
      dispatch(
        updateOrderType({
          orderTypeDetail: {
            ...orderTypes,
            orderTypeId: id,
            ...updateObj,
          },
        }),
      );
      dispatch(validateChanges());
    },
    [dispatch, orderTypes],
  );

  //function to update the current tab hash string, which is used to identify if the data is changed or not
  const dispPushCurrentHashForSelectedTab = useCallback(
    (id: string) => {
      dispatch(
        pushCurrentHashForSelectedTab({
          orderTypeId: id,
        }),
      );
      dispatch(validateChanges());
    },
    [dispatch],
  );

  //function to add new tab
  const dispAddTab = useCallback(() => {
    dispatch(addOrderType());
    dispatch(validateChanges());
  }, [dispatch]);

  //function to validate condition of passed tab id
  const dispValidateCondition = useCallback(
    (id: string) => {
      dispatch(
        validateCondition({
          orderTypeId: id,
        }),
      );
      dispatch(validateChanges());
    },
    [dispatch],
  );

  //20 Jan Added to show validation on modal load itself
  useMemo(() => {
    if (selectedOrderTypeId && dispValidateCondition) {
      if (selectedOrderIndex !== -1 && selectedOrderIndex !== 0)
        dispValidateCondition(selectedOrderTypeId);
    }
  }, [dispValidateCondition, selectedOrderIndex, selectedOrderTypeId]);

  //function to update conditionType of a condition
  const dispUpdateConditionForVariables = useCallback(
    (
      id: string,
      e: { target: { value: string } },
      condition: ConditionTypes,
    ) => {
      dispatch(
        updateCondition({
          orderTypeId: id,
          conditionToUpdate: {
            ...condition,
            variableName: e.target.value as IVariableName,
            operator: operators[e.target.value as IVariableName][0],
            values: getConditionValueOrDefault(
              valuesTypes[e.target.value as IVariableName],
            ),
          },
        }),
      );
    },
    [dispatch, operators, valuesTypes],
  );

  const dispUpdateReturnWindowConditionForVariables = useCallback(
    (
      id: string,
      e: { target: { value: string } },
      condition: ConditionTypes,
    ) => {
      dispatch(
        updateReturnWindowCondition({
          orderTypeId: id,
          conditionToUpdate: {
            ...condition,
            variableName: e.target.value as IVariableName,
            operator: operators[e.target.value as IVariableName][0],
            values: getConditionValueOrDefault(
              valuesTypes[e.target.value as IVariableName],
            ),
          },
        }),
      );
      dispatch(
        validateCondition({
          orderTypeId: id,
        }),
      );
    },
    [dispatch, operators, valuesTypes],
  );

  //function to update operator of a condition
  const dispUpdateConditonForOperator = useCallback(
    (
      id: string,
      e: { target: { value: string } },
      condition: ConditionTypes,
    ) => {
      dispatch(
        updateCondition({
          orderTypeId: id,
          conditionToUpdate: {
            ...condition,
            operator: e.target.value,
          },
        }),
      );
    },
    [dispatch],
  );

  const dispUpdateReturnWindowConditonForOperator = useCallback(
    (
      id: string,
      e: { target: { value: string } },
      condition: ConditionTypes,
    ) => {
      dispatch(
        updateReturnWindowCondition({
          orderTypeId: id,
          conditionToUpdate: {
            ...condition,
            operator: e.target.value,
          },
        }),
      );
    },
    [dispatch],
  );

  //function to update condition value by its value type
  const dispUpdateConditionForValues = useCallback(
    (id: string, type: string, value: any, condition: ConditionTypes) => {
      switch (type) {
        case "day_type":
          dispatch(
            updateCondition({
              orderTypeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  day_type: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;
        case "days":
          dispatch(
            updateCondition({
              orderTypeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  days: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;
        case "tags":
          dispatch(
            updateCondition({
              orderTypeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  tags: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;
        case "codes":
          dispatch(
            updateCondition({
              orderTypeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  codes: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;
        case "numbers":
          dispatch(
            updateCondition({
              orderTypeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  numbers: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;

        case "countryName":
          dispatch(
            updateCondition({
              orderTypeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  countryName: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;
        case "amount":
          dispatch(
            updateCondition({
              orderTypeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  amount: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;
        case "currency":
          dispatch(
            updateCondition({
              orderTypeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  currency: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;

        case "items":
          dispatch(
            updateCondition({
              orderTypeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  items: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;
        default:
          break;
      }
      dispatch(validateChanges());
    },
    [dispatch],
  );

  const disUpdateReturnWindowCondition = useCallback(
    (
      id: string,
      type: string,
      value: string | number,
      condition: ConditionTypes,
    ) => {
      switch (type) {
        case "day_type":
          dispatch(
            updateReturnWindowCondition({
              orderTypeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  day_type: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;
        case "days":
          dispatch(
            updateReturnWindowCondition({
              orderTypeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  days: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;
        case "tags":
          dispatch(
            updateReturnWindowCondition({
              orderTypeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  tags: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;
        case "codes":
          dispatch(
            updateReturnWindowCondition({
              orderTypeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  codes: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;
        case "numbers":
          dispatch(
            updateReturnWindowCondition({
              orderTypeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  numbers: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;

        case "countryName":
          dispatch(
            updateReturnWindowCondition({
              orderTypeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  countryName: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;
        case "amount":
          dispatch(
            updateReturnWindowCondition({
              orderTypeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  amount: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;
        case "currency":
          dispatch(
            updateReturnWindowCondition({
              orderTypeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  currency: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;

        case "items":
          dispatch(
            updateReturnWindowCondition({
              orderTypeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  items: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;
        default:
          break;
      }
      dispatch(validateChanges());
    },
    [dispatch],
  );

  //function to delete a condition by its id
  const dispDeleteCondition = useCallback(
    (conId: string, id: string) => {
      dispatch(
        deleteCondition({
          conditionId: conId,
          orderTypeId: id,
        }),
      );
    },
    [dispatch],
  );

  const dispValidateOrderType = useCallback(
    (id: string) => {
      dispatch(validateOrderType({ orderTypeId: id }));
    },
    [dispatch],
  );

  //function to save/submit the configuration modal data
  const dispPostConfig = useCallback(async () => {
    if (!returnAutoWorkFlow.integrationId) {
      return;
    }

    //dispatching the validation thunk and
    // then if the validation passes then calling the submit api otherwise showing warning toast
    const res = await dispatch(validateNonEligibleOrders());
    if (res.payload === 0) {
      dispatch(
        updateNonEligibleOrdersThunk({
          callback: () => {
            onSave(true);
          },
          // TODO
          integrationId: returnAutoWorkFlow.integrationId,
          selectedReturnWindow: "",
        }),
      );
    } else {
      pushTheToast({
        type: "warning",
        text: "Please fill the required fields!",
        position: "top-right",
      });
    }
  }, [dispatch, onSave, returnAutoWorkFlow.integrationId]);

  //function to add new condition for the passed tab id
  const dispAddCondition = useCallback(
    (id: string, e?: { target: { value: string } }) => {
      dispatch(
        addCondition({ orderTypeId: id, condition: e ? e.target.value : "" }),
      );
      dispatch(validateChanges());
    },
    [dispatch],
  );

  return {
    dispAddCondition,
    dispAddTab,
    dispDeleteCondition,
    dispPostConfig,
    dispPushCurrentHashForSelectedTab,
    dispUpdateConditionForValues,
    dispUpdateConditionForVariables,
    dispUpdateConditonForOperator,
    dispUpdateOrderType,
    dispValidateCondition,
    dispValidateOrderType,
    orderTypes,
    fetchAjaxStatus,
    updateAjaxStatus,
    initialHash,
    currentHash,
    selectedOrder,
    selectedOrderTypeId,
    isAddBtnEnabled,
    otherTabsHasError,
    defaultOptions,
    conditionOptions,
    operators,
    returnWindowOptions,
    dispUpdateReturnWindowConditionForVariables,
    dispUpdateReturnWindowConditonForOperator,
    disUpdateReturnWindowCondition,
  };
}
export default useNonEligibleOrders;
