import { useAppSelector } from "src/store/store";
import styles from "./BrandInfo.module.scss";
import AxiosImg from "src/components/AxiosImg";
import {
  OverlayTrigger,
  Popover,
  Tooltip,
  TooltipProps,
} from "react-bootstrap";
import { RefAttributes } from "react";

function BrandInfo() {
  const brandInfo = useAppSelector(
    (state) => state.chatWidExhMsg.activeCustomerInfo?.brand
  );

  const brandLogo = brandInfo?.imgURL;

  const popover = (
    props: JSX.IntrinsicAttributes &
      TooltipProps &
      RefAttributes<HTMLDivElement>
  ) => (
    <Tooltip id="button-tooltip" {...props}>
      <div className={`p-1 w-100`}>Brand : {brandInfo?.name}</div>
    </Tooltip>
  );

  return (
    <div className="d-flex justify-content-between align-items-center w-auto">
      <OverlayTrigger trigger="hover" placement="top" overlay={popover}>
        <div className={`d-flex align-items-center ${styles.brandInfo} px-2`}>
          <div className={`${styles.brandName}`}>
            {brandInfo?.name}
          </div>
          {brandLogo && brandLogo.length !== 0 ? (
            <AxiosImg
              url={brandLogo}
              alt="Brand Logo"
              className={`${styles.profileImage} rounded-circle`}
              isDirectURL={brandInfo?.isPublicAttachmentUrl}
            />
          ) : null}
        </div>
      </OverlayTrigger>
    </div>
  );
}

export default BrandInfo;
