import AdditionalOptionList from "../AdditionalOptionList/AdditionalOptionList";

function AttachmentPicker({
  additionalOptions,
  handleAddAttachmentClick,
  editorRef,
}: any) {
  return additionalOptions && additionalOptions.length !== 0 ? (
    <AdditionalOptionList
      additionalOptions={[
        ...additionalOptions,
        {
          label: (
            <>
              Upload from your device <br />
              <span>Maximum file size 20MB</span>
            </>
          ),
          onClick: handleAddAttachmentClick,
          showLogo: true,
          hide: false,
        },
      ]}
      editorRef={editorRef}
    />
  ) : (
    <AdditionalOptionList
      additionalOptions={[
        {
          label: (
            <>
              Upload from your device <br />
              <span>Maximum file size 20MB</span>
            </>
          ),
          onClick: handleAddAttachmentClick,
          showLogo: true,
          hide: false,
        },
      ]}
      editorRef={editorRef}
    />
  );
}

export default AttachmentPicker;
