import React, { useState } from "react";
import { Modal, Button, Form, Spinner } from "react-bootstrap";

interface PhoneRegisterModalProps {
  show: boolean;
  handleClose: () => void;
  handleRegister: (pin: number | string) => void;
  error: string;
  isLoading: boolean;
}

const PhoneRegisterModal = ({
  show,
  handleClose,
  handleRegister,
  error,
  isLoading,
}: PhoneRegisterModalProps) => {
  const [pin, setPin] = useState("");

  const handlePinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPin(e.target.value);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>Two-step Verification</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="alert alert-warning">
          {
            'Go to "WhatsApp Business Manager > Settings > Two-step Verification" menu to change two-factor authentication PIN.'
          }
        </div>
        <Form.Group controlId="formPin">
          <Form.Label>6 Digit PIN</Form.Label>
          <Form.Control
            type="text"
            value={pin}
            onChange={handlePinChange}
            maxLength={6}
            placeholder="Enter 6 digit PIN"
            isInvalid={!!error}
          />
          <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={() => handleRegister(pin)}
          disabled={isLoading}
          style={{ backgroundColor: "#ff8499", borderColor: "#ff8499" }}
        >
          Verify
          {isLoading && (
            <Spinner
              className="mx-1"
              animation="border"
              size="sm"
            />
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PhoneRegisterModal;
