import React, { useContext } from "react";
import styles from "./ScheduleModal.module.scss";
import DraftSidebar from "./Children/DraftSidebar/DraftSidebar";
import MainSection from "./Children/MainSection/MainSection";
import Loader from "src/components/Loader";
import closeModal from "src/assets/images/closeScheduleModal.png";
import useScheduleModa from "./Children/CustomHooks/useScheduleModal";
import {
  ExtendedTemplateContext,
  useTemplates,
} from "./Children/MainSection/TemplateContext";
type Props = {
  onHide: () => void;
  scheduleMessageFor: "innerTicket" | "liveChat";
  ticketId?: number | string;
  scheduleWith?: string | null;
};
const ScheduleModal = ({
  onHide,
  scheduleMessageFor,
  ticketId,
  scheduleWith,
}: Props) => {
  // const {} = useScheduleModa(scheduleMessageFor);

  const template = useTemplates();
  const {
    isLoading,
    isError,
    selectedId,
    setSelectedId,
    fetchStatus,
    handleInfiniteScroll,
    hasNextPage,
    loader,
    errorMessage,
  } = useContext(ExtendedTemplateContext);

  if (!template && !selectedId) {
    return null;
  }

  if (isLoading && fetchStatus === "fetching") {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  if (isError) {
    return <div>Something went wrong...</div>;
  }

  return (
    <div className={`${styles.scheduleModal}`}>
      <div className={`d-flex align-items-start justify-content-between`}>
        <div>
          <h2 className={`mb-1 ${styles.modalHead}`}>
            Create automated followups
          </h2>
          <span className={`${styles.modalSub}`}>
            Automated follow-up messages will be sent to your customers via
            E-mail . Add a custom follow template and schedule when to send them
          </span>
        </div>

        <span>
          {/* <i className="fa-solid fa-xmark" role="button"></i> */}
          <img
            src={closeModal}
            alt=""
            className={`cursor-pointer ${styles.closeModal}`}
            onClick={onHide}
          />
        </span>
      </div>
      <div>
        <p className={`mt-2 ${styles.templateHead}`}>
          Select the template you would like to use
        </p>
        <div className="d-flex flex-wrap">
          <DraftSidebar
            selectedTemplateId={selectedId}
            setSelectedTemplateId={setSelectedId}
            handleInfiniteScroll={handleInfiniteScroll}
            hasNextPage={hasNextPage}
            loader={loader}
            errorMessage={errorMessage}
          />

          <MainSection
            selectedTemplateId={selectedId}
            onHide={onHide}
            scheduleMessageFor={scheduleMessageFor}
            setSelectedTemplateId={setSelectedId}
            ticketId={ticketId}
            scheduleWith={scheduleWith}
          />
        </div>
      </div>
    </div>
  );
};

export default ScheduleModal;
