import styles from "./ElementChoiceList.module.scss";
import dots from "src/assets/images/dots.png";
import nextLine from "src/assets/images/nextLine.png";
import { useRef, useState } from "react";
import { v4 as uuid } from "uuid";

interface Props {
  element: any;
  handleElementChange: any;
  handleElementDelete: any;
  hideDelete: boolean;
  customProvided: any;
  initialShowInput?: boolean;
}
const ElementChoiceList = ({
  element,
  handleElementChange,
  handleElementDelete,
  hideDelete,
  customProvided,
  initialShowInput = false, //flag to show element by default
}: Props) => {
  const [showForm, setShowForm] = useState(initialShowInput);
  // const focusRef = useRef<{ lastFocusIndex: null | number }>({
  //   lastFocusIndex: null,
  // });

  const showPreForm = () => {
    setShowForm(!showForm);
  };

  const handleDeleteOption = (index: number, event: any) => {
    if (index === 0) {
      return;
    }

    const options = element.elementOptions.filter(
      (option: string, i: number) => i !== index
    );

    let obj = {
      ...element,
    };
    obj.elementOptions = options;
    handleElementChange(obj);
  };

  const handleAddNewOption = () => {
    const newOptionText = "Answer";
    let obj = {
      ...element,
    };
    obj.elementOptions = [
      ...obj.elementOptions,
      newOptionText + " " + (obj.elementOptions.length + 1),
    ];
    handleElementChange(obj);
  };

  const handleChange = (eventName: string, index: number, event: any) => {
    if (element !== null) {
      if (event.target.value !== null) {
        let obj = {
          ...element,
        };
        if (eventName === "required") {
          obj.isRequired = !obj.isRequired;
        } else if (eventName === "options") {
          let options = element.elementOptions.map(
            (option: string, i: number) => {
              if (i === index) {
                return event.target.value;
              } else {
                return option;
              }
            }
          );
          obj.elementOptions = options;
          // focusRef.current.lastFocusIndex = index;
        } else {
          obj.elementValue = event.target.value;
        }
        // focusRef.current.lastFocusIndex = index;
        handleElementChange(obj);
      }
    }
  };
  return (
    <div
      ref={customProvided.innerRef}
      {...customProvided.draggableProps}
      style={{
        ...customProvided.draggableProps.style,
        position: "static",
        // overflow: "auto",
      }}
    >
      {console.log("rendered ........")}
      <div className={`py-2 px-1 px-md-2 ${styles.formBox}`}>
        <div className={`d-flex justify-content-between`}>
          <div className={`d-flex aling-items-center`}>
            <div {...customProvided.dragHandleProps}>
              <img src={dots} alt="dots" />
            </div>
            <div>
              <span className={`ms-2 ${styles.commonSpan}`}>
                {element.elementLabel}
              </span>
            </div>
          </div>
          <div className={`d-flex aling-items-center flex-wrap`}>
            <div className="form-check mx-1">
              <input
                className={`form-check-input`}
                type="checkbox"
                checked={element.isRequired}
                onChange={(e) => handleChange("required", 0, e)}
                disabled={element.isDefault === true}
              />
              <label
                className={`form-check-label ${styles.requiredField} `}
                htmlFor="flexCheckDefault"
              >
                Required
              </label>
            </div>
            <div className={`mx-2  ${styles.delElemTop}`}>
              {element.isDefault !== true && hideDelete === false ? (
                <span
                  className={`cursor-pointer ${styles.delForm}`}
                  onClick={(e) => handleElementDelete(element)}
                >
                  <i className={`fa-solid fa-trash`}></i>
                </span>
              ) : (
                <></>
              )}
            </div>
            <span onClick={showPreForm}>
              {showForm ? (
                <i className={`fa-solid fa-angle-up ${styles.downArrow}`}></i>
              ) : (
                <i className={`fa-solid fa-angle-down ${styles.downArrow}`}></i>
              )}
            </span>
          </div>
        </div>
        {showForm && (
          <>
            <div className={`mt-2`}>
              <div className="mb-2">
                <label className={`form-label ${styles.commonSpan}`}>
                  Label
                </label>
                <input
                  type="text"
                  className={`form-control ${styles.formInput}`}
                  aria-describedby="emailHelp"
                  value={element.elementValue}
                  onChange={(e) => handleChange("element", 0, e)}
                />
                {element.elementValue.trim() === "" && (
                  <span className={styles.dangerText}>
                    Field cannot be empty
                  </span>
                )}
              </div>
              {element.elementOptions.map(
                (optionValue: string, index: number) => {
                  return (
                    <div className={`d-flex`} key={index}>
                      {/* <div className={`d-flex`} key={uuid()}> */}
                      <div className={`ms-2 ${styles.nextDiv}`}>
                        <img
                          className={`${styles.nextLine}`}
                          src={nextLine}
                          alt="line"
                        />
                      </div>
                      <div className={`mb-2 ${styles.choiceDiv}`}>
                        <div>
                          <label className={`form-label ${styles.commonSpan}`}>
                            Choice #{index + 1}
                          </label>
                          <div className={`d-flex align-items-center`}>
                            <input
                              type="text"
                              className={`form-control ${styles.formInput}`}
                              aria-describedby="emailHelp"
                              onChange={(e) =>
                                handleChange("options", index, e)
                              }
                              // onFocus={(e) => {
                              //   focusRef.current.lastFocusIndex = index;
                              // }}
                              // onBlur={(e) => {
                              //   focusRef.current.lastFocusIndex = null;
                              // }}
                              value={optionValue}
                              // autoFocus={
                              //   index === focusRef.current.lastFocusIndex
                              // }
                            />
                            <div
                              className={`ms-2 cursor-pointer ${styles.delElemTop}`}
                            >
                              {index === 0 ? null : (
                                <i
                                  className="fa-solid fa-xmark"
                                  onClick={(e) => handleDeleteOption(index, e)}
                                ></i>
                              )}
                            </div>
                          </div>
                        </div>
                        <div>
                          {optionValue.trim() === "" && (
                            <span className={styles.dangerText}>
                              Field cannot be empty
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                }
              )}
            </div>
            <div className="d-flex align-items-center">
              <button
                className={`${styles.addBox} me-2`}
                id="dropdownMenuButton1"
                // data-bs-toggle="dropdown"
                aria-expanded="false"
                onClick={handleAddNewOption}
              >
                <i className="fa-solid fa-plus"></i>
              </button>
              <span className={`${styles.addElement}`}>Add answer</span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ElementChoiceList;
