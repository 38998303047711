import React, { useEffect, useMemo, useState } from "react";
import CategoryDropdown from "../../Children/CategoryDropdown/CategoryDropdown";
import styles from "./BugCategoriesView.module.scss";
import BugBulkAction from "../BugBulkAction/BugBulkAction";
import { BugData } from "src/services/BugReport/getBugByCategory.service";
import BugCategoriesFullView from "./BugCategoriesFullview/BugCategoriesFullView";
import BugCategoriesHalfView from "./BugCategoriesHalfView/BugCategoriesHalfView";

export interface BugCategoryParam {
  bugsData: Array<BugData>;
  totalBugs: number;
  categoryName: string;
  categoryId: number;
  pendingForAction: boolean;
  selectedBugId: Set<number>;
  setSelectedBugId: React.Dispatch<React.SetStateAction<Set<number>>>;
  setSelectedBugsData: React.Dispatch<React.SetStateAction<BugData[]>>;
}

interface SeeMoreProp {
  toggle: React.Dispatch<React.SetStateAction<boolean>>;
  value: boolean;
}
// see move bottom btn
const SeeMoreCom = ({ toggle, value }: SeeMoreProp) => {
  return (
    <div className="d-flex justify-content-center py-2">
      <span
        className={styles.seeMoreBtn}
        onClick={() => toggle((prevState) => !prevState)}
      >
        See {value ? "less" : "more"}
        <i
          className={`fa fa-angle-${value ? "up" : "down"} ms-1`}
          aria-hidden="true"
        ></i>
      </span>
    </div>
  );
};

const BugCategoriesView = ({
  bugsData,
  totalBugs,
  categoryName,
  categoryId,
  pendingForAction,
  selectedBugId,
  setSelectedBugId,
  setSelectedBugsData
}: BugCategoryParam) => {
  const [seeMore, setSeeMore] = useState(false);

  return (
    <CategoryDropdown heading={`${categoryName} (${totalBugs})`}>
      <div>
        {seeMore ? (
          <BugCategoriesFullView
            categoryId={categoryId}
            categoryName={categoryName}
            seeMore={seeMore}
            selectedBugId={selectedBugId}
            setSelectedBugId={setSelectedBugId}
            pendingForAction={pendingForAction}
            setSelectedBugsData={setSelectedBugsData}
          />
        ) : (
          <BugCategoriesHalfView
            bugsData={bugsData}
            categoryName={categoryName}
            selectedBugId={selectedBugId}
            setSelectedBugId={setSelectedBugId}
            setSelectedBugsData={setSelectedBugsData}
          />
        )}
        {(bugsData?.length ?? 0) > 3 && !seeMore ? (
          <SeeMoreCom
            toggle={setSeeMore}
            value={seeMore}
          />
        ) : (
          ""
        )}
      </div>
    </CategoryDropdown>
  );
};

export default BugCategoriesView;
