// import { EChannel } from "src/enums/EChannel";
// import { ITicket, ITicketMeta } from "src/interfaces/ITicket";
import { axiosJSON } from "src/globals/axiosEndPoints";
import log from "loglevel";
import {
  TicketTag,
  TicketTags
} from "src/store/slices/ticketTags/ticketTags.slice";

export async function fetchTicketsTagsApi(getOriginal?: boolean) {
  const { data } = await axiosJSON.get(`/api/tags/getTagsList`);
  if (data.err === true) {
    throw new Error(data.msg as string);
  }
  if (getOriginal) {
    return data.data;
  }
  const normalizedData = normalizeData(data);
  // log.debug("fetch ticket tags raw response:", data);
  // log.debug("fetch ticket tags normalized response:", normalizedData);
  return normalizedData;
}

// convert incoming data to the required format
function normalizeData(response: any): {
  allTags: TicketTags;
  allTagsIds: number[];
} {
  const tagsList = response.data;
  const allTags: TicketTags = {};
  const allTagsIds: number[] = [];
  tagsList.forEach((tag: any) => {
    const ticketTagId = tag.id;
    const ticket: TicketTag = {
      id: ticketTagId,
      tagName: tag.tagName
    };
    allTags[`${tag.id}`] = ticket;
    allTagsIds.push(ticketTagId);
  });
  return { allTags, allTagsIds };
}
