import {
  FollowUpQuestions,
  GiveOfferType,
} from "src/features/ReturnAutoWorkFlow/children/ConfigModals/store/slices/AntiRefundStrategiesSlice/antiRefundStrategies.slice";
import { axiosJSON } from "src/globals/axiosEndPoints";

interface updateRequestRefundSelectedItemParams {
  id: string;
  integrationId: string;
  scenarioId: string | null;
  itemIds: number[] | string[] | undefined;
  tags: string[] | undefined;
  enabled?: boolean;
  followUpQuestions?: FollowUpQuestions[];
  additionalInformation?: {
    enabled: boolean;
    message: string;
    extendReturnPeriod: {
      enabled: boolean;
      days: number;
    };
  };
  giveOffer?: GiveOfferType & {
    enabled: boolean;
    message: string;
  };
  offerExchange?: {
    enabled: boolean;
    message: string;
    exchangeWithSameItems: boolean;
    exchangeWithOtherItems: boolean;
  };
}

async function updateRefundRequestSelectedItemsService(
  params: updateRequestRefundSelectedItemParams,
) {
  const { data: res } = await axiosJSON.post(
    "/api/setting/automation/refund/step/12/updateRefundRequestSelectedItems",
    params,
  );

  if (res.err || res.error) {
    throw new Error(res.msg ?? res.message ?? "Some Error Occurred.");
  }

  return res.data;
}

export default updateRefundRequestSelectedItemsService;
