import { Dropdown } from "react-bootstrap";
import styles from "./DropDownStatus.module.scss";
import { useMutation } from "@tanstack/react-query";
import updateBugStatus from "src/services/BugReport/updateBugStatus";
import React, { SetStateAction, useEffect, useMemo, useState } from "react";
import { capitalizeFirstLetter } from "../helper/helperFunction";

interface Props {
  status: string | undefined | null;
  id: number | undefined;
  updatedStatus: (status: string) => void;
  setTimeLineStep?: React.Dispatch<SetStateAction<number>>;
}

const DropDownStaus = ({ status, id, updatedStatus, setTimeLineStep }: Props) => {
  const [currentStatus, setCurrentStatus] = useState(status);

  // api call to change the staus
  const { mutate, isLoading, isError } = useMutation({
    mutationFn: updateBugStatus,
    onSuccess: () => {
      updatedStatus(currentStatus ?? "");
    },
  });

  useEffect(() => setCurrentStatus(status), [status]);

  // handle dropdown change and call api
  const handleDropDownChange = (newStatus: string) => {
    if (newStatus === "resolved") {
      updatedStatus(newStatus);
      if(setTimeLineStep){
        setTimeLineStep(prevState => prevState + 1);
      }
      return;
    }
    setCurrentStatus(newStatus);
    if (id === undefined) return;
    mutate({ bugId: id, bugStatusKey: newStatus });
  };

  // if error show message
  if (isError) {
    return (
      <span className={`${styles.loadingText} ms-3`}>something wrong..</span>
    );
  }

  return (
    <div>
      {isLoading ? (
        <span className={`${styles.loadingText} ms-3`}>Updating...</span>
      ) : (
        <Dropdown>
          <Dropdown.Toggle
            variant=""
            id="dropdown-basic"
            className={`d-flex align-items-center ${styles.dropdownBorder}`}
            disabled={status === "resolved"}
          >
            <div
              className={`${styles.dot} ${
                currentStatus == "submitted"
                  ? styles.greyDot
                  : currentStatus === "resolved"
                    ? styles.greenDot
                    : styles.yellowDot
              }`}
            ></div>
            <span className={`px-1 ${styles.submitText}`}>
              {capitalizeFirstLetter(currentStatus ?? "")}
            </span>
          </Dropdown.Toggle>

          <Dropdown.Menu className={`py-3 ${styles.dropdownBox}`}>
            <Dropdown.Item
              className={`d-flex align-items-center ${styles.dropdownItem}`}
              onClick={() =>
                handleDropDownChange(
                  currentStatus === "submitted" ? "investigating" : "submitted",
                )
              }
            >
              {currentStatus === "submitted" ? (
                <div className="ms-3 d-flex align-items-center">
                  <div className={`${styles.dot} ${styles.yellowDot}`}></div>
                  <span className={`px-1 ${styles.submitText}`}>
                    Investigating
                  </span>
                </div>
              ) : (
                <div className="ms-3 d-flex align-items-center">
                  <div className={`${styles.dot} ${styles.greyDot}`}></div>
                  <span className={`px-1 ${styles.submitText}`}>Submitted</span>
                </div>
              )}
            </Dropdown.Item>
            <Dropdown.Item
              className={`d-flex align-items-center ${styles.dropdownItem}`}
              onClick={() =>
                handleDropDownChange(
                  currentStatus === "resolved" ? "investigating" : "resolved",
                )
              }
            >
              {currentStatus === "resolved" ? (
                <div className="ms-3 d-flex align-items-center">
                  <div className={`${styles.dot} ${styles.yellowDot}`}></div>
                  <span className={`px-1 ${styles.submitText}`}>
                    Investigating
                  </span>
                </div>
              ) : (
                <div className="ms-3 d-flex align-items-center">
                  <div className={`${styles.dot} ${styles.greenDot}`}></div>
                  <span className={`px-1 ${styles.submitText}`}>Resolved</span>
                </div>
              )}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </div>
  );
};

export default DropDownStaus;
