import { AllOutboundEmails, BrandData, GetAllBrandData } from "src/services/Settings/Brands/getAllBrands";
import { BrandWithAgents } from "src/services/Settings/Brands/getBrand";
import { BrandAllAgents } from "src/services/Settings/Brands/getBrandAgents";
import { BrandSignaturePlaceholder } from "src/services/Settings/Brands/getBrandSignaturePlaceholders";
import { IBrandSettings } from "./brandSettings.slice";
import { BrandVariableValues } from "src/services/Settings/Brands/getBrandVariableValues";

function fetchAllBrandFulfilled(
  state: IBrandSettings,
  { payload }: { payload: { brandData: GetAllBrandData; brandPlaceholders  : Array<BrandSignaturePlaceholder>, totalPages: number , cachedData: any, currentPage: number} }
) {
  state.brandDataAjaxStatus = "fulfilled";
  const { brands, brandIds, metaData } = payload.brandData;
  state.brands = brands;
  state.brandIds = payload.cachedData[payload.currentPage];
  state.metaData = metaData;
  state.brandPlaceholderOptions = payload.brandPlaceholders;
  state.totalPages = payload.totalPages;
  state.cachedData = payload.cachedData;

  if (state.currentPage === 0 && brandIds && brandIds.length) {
    state.currentPage = payload.currentPage;
  }

}

function addBrandFulfilled(state: IBrandSettings, { payload }: any) {
  state.addBrandAjaxStatus = "fulfilled";
}

function updateBrandFulfilled(
  state: IBrandSettings,
  { payload }: {payload: BrandData|undefined}
) {
  state.updateBrandAjaxStatus = "fulfilled";
  if(payload){
    state.brands[payload.id] = payload;
  }
}

function deleteBrandFulfilled(state: IBrandSettings, { payload }: any) {
  state.deleteBrandAjaxStatus = "fulfilled";
}

function getBrandFulfilled(
  state: IBrandSettings, 
  { payload }: {payload: BrandData}
  ) {
  state.getBrandAjaxStatus = "fulfilled";
  state.brands[payload.id] = payload;
  
}


function getBrandAgentsFulfilled(
  state: IBrandSettings, 
  { payload }: any
  ) {
  const {userIds, users, metaData} = payload;

  state.getBrandAgentAjaxStatus = "fulfilled";
  state.brandAgentIds =  Array.from(new Set([ ...state.brandAgentIds,
    ...userIds]));  
  state.metaData = metaData;
  
  state.brandAgents = {...state.brandAgents, ...users};
  state.brandAgentMeta.count = metaData.count;
  state.brandAgentMeta.total = metaData.total;

  return state;
  
}

function getAllBrandAgentsFulfilled(
  state: IBrandSettings, 
  { payload }: { payload :{userData: BrandAllAgents; totalPages: number }}
  ) {
  
    const { users, userIds, metaData,  } = payload.userData;
  
    state.getAllBrandAgentAjaxStatus = "fulfilled";
    state.users = {...state.users, ...users};
    state.userIds = userIds;
    state.usersMeta.count = metaData.count;
    state.usersMeta.total = metaData.total;
  
    state.usersMeta.totalPages = payload.totalPages;
  
    if (state.usersMeta.userCurrentPage === 0 && userIds && userIds.length) {
      state.usersMeta.userCurrentPage = 1;
    }
  
    return state;
  
}


function getAllOutboundEmailFulfilled(
  state: IBrandSettings, 
  { payload }: { payload : AllOutboundEmails}
  ) {
  
    const { outboundEmails, outboundEmailsIds, metaData,  } = payload;
  
    state.allOutboundEmailAjaxStatus = "fulfilled";
    state.outboundMails = {...state.outboundMails, ...outboundEmails};
    state.outboundMailsIds = Array.from(new Set([ ...state.outboundMailsIds, ...outboundEmailsIds]));  
    state.outboundMailsMeta.count = metaData.count;
    state.outboundMailsMeta.total = metaData.total;
  
    return state;
  
}

function fetchBrandSignaturePlaceholdersFulfilled(
  state: IBrandSettings,
  { payload }: { payload: { brandPlaceholders  : Array<BrandSignaturePlaceholder>} }
) {
  state.brandPlaceholderOptions = payload.brandPlaceholders;
  return state;
}
function fetchBrandSignatureVariableFulfilled(
  state: IBrandSettings,
  { payload }: { payload:  Array<BrandVariableValues> }
) {
  state.brandVariableValues = payload;
  return state;
}


function fetchAllBrandInfiScrollFulfilled(
  state: IBrandSettings,
  { payload }: { payload: GetAllBrandData; }
) {
  state.brandDataAjaxStatus = "fulfilled";
  const { brands, brandIds, metaData } = payload;
  state.brands = {...state.brands, ...brands};
  state.brandIds = Array.from(new Set([...state.brandIds, ...brandIds]));

  state.metaData = metaData;
}

export default {
  fetchAllBrandFulfilled,
  addBrandFulfilled,
  updateBrandFulfilled,
  deleteBrandFulfilled,
  getBrandFulfilled,
  getBrandAgentsFulfilled,
  getAllBrandAgentsFulfilled,
  getAllOutboundEmailFulfilled,
  fetchBrandSignaturePlaceholdersFulfilled,
  fetchBrandSignatureVariableFulfilled,
  fetchAllBrandInfiScrollFulfilled,
};
