import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

import {
  getAllVariables,
  IGetAllVariablesParams,
} from "src/services/UiAutomation/ReturnExchange/Configuration/Variables/getAllVariables";

// Define a query key for caching and identifying this specific query
export const VARIABLES_QUERY_KEY = ["automation/variables"];

// Custom hook for getting variables
const useGetVariables = (payload?: IGetAllVariablesParams) => {
  // Use the `useQuery` hook to fetch and cache variables

  const { data, refetch, isLoading } = useQuery({
    queryKey: VARIABLES_QUERY_KEY, // The query key used for caching
    queryFn: async () => {
      // The `payload` parameter is optional and provides additional options for the query
      return getAllVariables(payload ?? {});
    },
    cacheTime: 60000, // Cache time for the query result (in milliseconds)
    staleTime: 60000, // Stale time, used for automatic background refetch (in milliseconds)
  });

  // Use the `useMemo` hook to memoize the variables and variable IDs
  const variables = useMemo(() => data?.variables ?? {}, [data]); // Extract and memoize the variables data
  const variablesIds = useMemo(() => data?.variablesIds ?? [], [data]); // Extract and memoize the variable IDs

  return {
    variables, // Extracted variables data
    variablesIds, // Extracted variable IDs
    refetch, // Function for manually triggering a data refresh
    isLoading, // Boolean indicating whether the data is currently being fetched
  };
};

export default useGetVariables;
