import styles from "./SettingToast.module.scss";

const SettingToast = ({
    type,
    text,
  }:any) => {

    return (
      <div
        className={`my-1 toast text-center show align-items-center text-white border-0 rounded-pill ${styles.toastDiv}`}
        role="alert"
        style={customStyle[type]}
      >
        <div className="toast-body">{text}</div>
      </div>
    );
  };

type ICustomStyle = {
    [key: string]: any
}
const customStyle:ICustomStyle = {
  "success":{
    backgroundColor:"#FF8499"
  },
  "danger":{
    backgroundColor:"#000000"
  },
  "warning":{
    backgroundColor:"#ffc107"
  },
  "info":{
    backgroundColor:"#17a2b8"
  },
  "dark":{
    backgroundColor:"#343a40"
  },
  "primary":{
    backgroundColor:"#007bff"
  },
  "secondary":{
    backgroundColor:"#6c757d"
  }
};

export default SettingToast;