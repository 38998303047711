import { forwardRef, useContext, useState } from "react";
import ActionButton from "src/components/ActionButton";
import { MoreColsToggleStateContext } from "../../MoreCols";

const MoreColsToggle = ({ children, onClick }: any, ref: any) => {
  const [toggle, setToggle] = useState(false);
  const toggleState = useContext(MoreColsToggleStateContext);

  return (
    <ActionButton
      ref={ref}
      onClick={(e: any) => {
        e.preventDefault();
        onClick(e);
        setToggle(!toggle);
      }}
      className={`me-1`}
    >
      <span>
        <i className={`fa fa-columns me-2`} />
      </span>
      More Columns
      <span>
        <i
          className={`fa-solid fa-caret-${toggleState ? "up" : "down"} ms-2`}
        />
      </span>
      {children}
    </ActionButton>
  );
};

export default forwardRef(MoreColsToggle);
