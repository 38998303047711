import { createSelector, createSlice } from "@reduxjs/toolkit";
import { AJAXSTATUS } from "src/globals/constants";
import { GetAllDiscountCodes } from "src/services/UiAutomation/ReturnExchange/Configuration/General/getAllDiscountCodes";
import { GetAllOrderTags } from "src/services/UiAutomation/ReturnExchange/Configuration/General/getAllOrderTags";
import { GetAllProductTags } from "src/services/UiAutomation/ReturnExchange/Configuration/General/getAllProductTags";
import { ProductDetail } from "src/services/UiAutomation/ReturnExchange/Configuration/General/getAllShopifyProducts";
import { GetAllReturnReasonList } from "src/services/UiAutomation/ReturnExchange/Configuration/Step14/getAllReturnReasonList";
import { IReturnIntegrationData } from "src/services/UiAutomation/ReturnExchange/getAllReturnIntegrations";
import returnExchangeBuilders from "./returnExchange.builder";
import returnExchangeReducers from "./returnExchange.reducers";
import {
  areAllSegementCompleted,
  isQuestionAnswered,
  isSegementCompleted,
  isStepTransferedToAgent,
} from "./returnExchange.selectors";

export type AUTOMATION_STEP_STATUS =
  | "completed"
  | "pending"
  | "disabled"
  | "transfered";

export type AUTOMATION_STEP_CONFIG_STATUS = "completed" | "pending";
export interface Step7 {
  fetchStepDataAjaxStatus: AJAXSTATUS;
  stepData: {
    noAutoMate: boolean;
    partiallyAutomate: boolean;
    fullyAutoMate: boolean;
  };
  selectedBrandId?: number;
}

export interface IReturnWindowValue {
  day_type: string;
  days: number;
  after: string;
}

export interface IResolutionOffersValue {
  exchangeWithSameItems: null | boolean;
  exchangeWithOtherItems: null | boolean;
  refundToStoreCredits: null | boolean;
  refundToOrigialPaymentMethod: null | boolean;
  resolutionOfferMessage: {
    value: null | string;
    showQuestion: boolean;
  };
}

export interface IAntiReturnStategiesOption {
  offerAnExchange: null | boolean;
  offerSomeSuggestion: null | boolean;
  offerSuggestionExtendReturnDeadline: null | boolean;
  offerDiscountOnFutureOrder: null | boolean;
}

export interface IReturnReason {
  id: string;
  value: string;
  new?: boolean;
  error?: string;
}

export interface IRestockingFee {
  price: string;
  priceType: string;
}

export interface IIsConfigured {
  isConfigured: null | boolean;
}
export interface IStepQuestion {
  id: string;
  key: string;
  value:
    | number
    | null
    | boolean
    | string
    | IReturnWindowValue
    | IResolutionOffersValue
    | IAntiReturnStategiesOption
    | Array<IReturnReason>
    | Array<string>
    | IRestockingFee
    | IIsConfigured;

  showQuestion?: boolean; // whether the question need to be shown or not
  error?: string | null;
}

export interface IStepQuestions {
  [key: string]: IStepQuestion;
}

export interface stepDetails {
  fetchStepDataAjaxStatus: AJAXSTATUS;
  showStep: boolean;
  stepData: {
    fully_automate_current_step: boolean;
    transfer_to_agent: boolean;
    confirmationMessage: string;
    stepStatus: AUTOMATION_STEP_STATUS;
    stepQuestions: IStepQuestions;
    configStatus: AUTOMATION_STEP_CONFIG_STATUS;
  };
}

//interface for step preview modal values
export interface StepPreviewModalData {
  //preview modal data
  showModal: boolean;
  isConfigured: boolean;
  title: string;
  subMessage: string;
  isTransfered: boolean;
  stepType:
    | "returnInstruction"
    | "select"
    | "return"
    | "reason"
    | "returnMethod"
    | "orderSummary"
    | "moreDetails"
    | "returnType";
}
export interface IActiveSubModal {
  name: "moreQuestions" | "followupQuestions" | "arsStages";
  reasonId?: string;
}
export type ActiveModal =
  | "nonEligibleOrder"
  | "nonEligibleItems"
  | "returnTypes"
  | "returnReasons"
  | "antiRefund"
  | "antiRefundMultipleTabs"
  | "returnMethod"
  | "orderSummary";
export interface ReturnExchangeInterface {
  activeIntegrationId: number | string;
  activeIntegrationData: IReturnIntegrationData;
  isAllStepFetchedInitially: boolean;
  selectedStep: number;
  showStepGoLive: boolean;
  isBulkSaveRunning: boolean;
  initialData: any;
  step7: Step7;
  step9: stepDetails;
  step10: stepDetails;
  step11: stepDetails;
  step12: stepDetails;
  step13: stepDetails;
  step14: stepDetails;
  step15: stepDetails;
  // step16: stepDetails;
  getAllOrderTags: GetAllOrderTags;
  getAllOrderTagsAjax: AJAXSTATUS;
  getAllProductTagsAjax: AJAXSTATUS;
  getAllProductTags: GetAllProductTags;
  getAllDiscountCodesAjax: AJAXSTATUS;
  getAllDiscountCodes: GetAllDiscountCodes;
  getAllShopifyProductAjax: AJAXSTATUS;
  getAllShopifyProducts: ProductDetail[];
  getAllShopifyProductMeta: { count: number; total: number };
  integrations: { [key: number | string]: IReturnIntegrationData }; //slice object all the return integrations will be stored in this slice
  integrationIds: Array<number | string>; //contains the ids of above object "integrations"
  integrationMetaData: { total: number | string; count: number | string }; //contains total integration count, used for pagination
  fetchAllIntegrationsAjaxStatus: AJAXSTATUS; //default get All integration api status is pending, it will show the loader until state fulfilled
  addIntegrationAjaxStatus: AJAXSTATUS; //add new integration ajax status, by default it will be idle
  stepConfiguredPreviewModal: StepPreviewModalData;
  returnReasonListIds: GetAllReturnReasonList["reasonIds"];
  returnReasonList: GetAllReturnReasonList["reasons"];
  returnReasonAjaxStatus: AJAXSTATUS;
  showPreview: {
    showNotEligible: boolean;
  };
  showErrors: boolean;
  activeModal: ActiveModal | null;
  activeSubModal: IActiveSubModal | null;
}

export const initialState: ReturnExchangeInterface = {
  activeIntegrationId: 0, //active integration id of steps, which will be used to pass as integration id in step thunks
  activeIntegrationData: {
    brand: null,
    configured: false,
    integrationId: 0,
    published: false,
    RequestCategory: "",
  },
  selectedStep: 9,
  initialData: {},
  showStepGoLive: false,
  isBulkSaveRunning: false,
  isAllStepFetchedInitially: false,
  showErrors: false,
  step7: {
    fetchStepDataAjaxStatus: "pending",
    stepData: {
      noAutoMate: false,
      partiallyAutomate: false,
      fullyAutoMate: true, //by default setting fullyAutoMate to true, as it is the recommended options in new design
    },
  },

  // segment 1
  step9: {
    fetchStepDataAjaxStatus: "pending",
    showStep: true,
    stepData: {
      fully_automate_current_step: false,
      transfer_to_agent: false,
      confirmationMessage: "",
      stepStatus: "disabled",
      configStatus: "pending",
      stepQuestions: {
        // question 1
        returnWindow: {
          id: "question_1", // it was question 3
          key: "returnWindow",
          value: {
            days: 0,
            day_type: "business_days",
            after: "order_placed",
          },
          showQuestion: true,
        },
        // question 2
        // this is a new question
        returnNotEligibleResponseMessage: {
          id: "question_2",
          key: "returnNotEligibleResponseMessage",
          value: null,
          showQuestion: false,
        },
        // question 3
        isReturnPolicySameForAllOrders: {
          id: "question_3",
          key: "isReturnPolicySameForAllOrders",
          value: null,
          showQuestion: false,
        },
        // question 4
        isReturnPolicySameForAllItem: {
          id: "question_4",
          key: "isReturnPolicySameForAllItem",
          value: null,
          showQuestion: false,
        },

        // question 3
        // returnWindow: {
        //   id: "question_3",
        //   key: "returnWindow",
        //   value: {
        //     days: 0,
        //     day_type: "business_days",
        //   },
        //   showQuestion: false,
        // },

        // question 4
        // canItemNotEligibleForReturn: {
        //   id: "question_4",
        //   key: "canItemNotEligibleForReturn",
        //   value: null,
        //   showQuestion: false,
        // },

        // // question 1
        // isReturnPolicySameForAllItem: {
        //   id: "question_1",
        //   key: "isReturnPolicySameForAllItem",
        //   value: null,
        //   showQuestion: true,
        // },
        // // question 2
        // isReturnPolicySameForAllOrders: {
        //   id: "question_2",
        //   key: "isReturnPolicySameForAllOrders",
        //   value: null,
        //   showQuestion: false,
        // },
        // // question 3
        // returnWindow: {
        //   id: "question_3",
        //   key: "returnWindow",
        //   value: {
        //     days: 0,
        //     day_type: "business_days",
        //   },
        //   showQuestion: false,
        // },
        // // question 4, need to remove
        canItemNotEligibleForReturn: {
          id: "question_4",
          key: "canItemNotEligibleForReturn",
          value: null,
          showQuestion: false,
        },
      },
    },
  },

  step10: {
    fetchStepDataAjaxStatus: "pending",
    showStep: false,
    stepData: {
      configStatus: "pending",
      fully_automate_current_step: false,
      transfer_to_agent: false,
      confirmationMessage: "",
      stepStatus: "disabled",
      stepQuestions: {},
    },
  },

  //  segment 2
  step11: {
    fetchStepDataAjaxStatus: "pending",
    showStep: false,
    stepData: {
      configStatus: "pending",
      fully_automate_current_step: false,
      transfer_to_agent: false,
      confirmationMessage: "",
      stepStatus: "disabled",
      stepQuestions: {
        resolutionOffer: {
          id: "question_5",
          key: "resolutionOffer",
          value: {
            exchangeWithSameItems: null,
            exchangeWithOtherItems: null,
            refundToStoreCredits: null,
            refundToOrigialPaymentMethod: null,
            // added in v3
            resolutionOfferMessage: {
              value: null,
              showQuestion: true, // change to false when any of the option is checked
            },
          },
          showQuestion: true,
        },
        isResolutionOfferedSameForAllOrders: {
          id: "question_6",
          key: "isResolutionOfferedSameForAllOrders",
          value: null,
          showQuestion: false,
        },
      },
    },
  },

  step12: {
    fetchStepDataAjaxStatus: "pending",
    showStep: false,
    stepData: {
      configStatus: "pending",
      fully_automate_current_step: false,
      transfer_to_agent: false,
      confirmationMessage: "",
      stepStatus: "disabled",
      stepQuestions: {
        selectReturnReason: {
          id: "question_7",
          key: "selectReturnReason",
          value: null,
          showQuestion: false,
        },

        // new question added in v3
        retunReasonList: {
          id: "question_8",
          key: "retunReasonList",
          value: null,
          showQuestion: false,
        },

        // previously, it was question 8
        isReturnReasonSameForAll: {
          id: "question_9",
          key: "isReturnReasonSameForAll",
          value: null,
          showQuestion: false,
        },
      },
    },
  },

  step13: {
    fetchStepDataAjaxStatus: "pending",
    showStep: false,
    stepData: {
      configStatus: "pending",
      fully_automate_current_step: false,
      transfer_to_agent: false,
      confirmationMessage: "",
      stepStatus: "disabled",
      stepQuestions: {
        antiReturnStrategies: {
          id: "question_10",
          key: "antiReturnStrategies",
          value: {
            offerAnExchange: null,
            offerSomeSuggestion: null,
            offerSuggestionExtendReturnDeadline: null,
            offerDiscountOnFutureOrder: null,
          },
          showQuestion: false,
        },
        configureAntiReturnStrategies: {
          id: "question_11",
          key: "configureAntiReturnStrategies",
          value: {
            isConfigured: null,
          },
          showQuestion: false,
        },
        isReturnReasonOrFollowUpQuestionsSame: {
          id: "question_12",
          key: "isReturnReasonOrFollowUpQuestionsSame",
          value: null,
          showQuestion: false,
        },
      },
    },
  },

  step14: {
    fetchStepDataAjaxStatus: "pending",
    showStep: false,
    stepData: {
      configStatus: "pending",
      fully_automate_current_step: false,
      transfer_to_agent: false,
      confirmationMessage: "",
      stepStatus: "disabled",
      stepQuestions: {
        // new question added in v3
        returnMethodForMajorityOfOrders: {
          id: "question_10",
          key: "returnMethodForMajorityOfOrders",
          // value may be:
          // notRequired
          // returnRequired
          // returnRequiredWithLabels
          value: null,
          showQuestion: false,
          // showQuestion: true,
        },
        // new question added in v3
        returnMethodDisplayMessage: {
          id: "question_11",
          key: "returnMethodDisplayMessage",
          value: null,
          showQuestion: false,
        },
        // previously it was question 9
        isReturnMethodSameForAllOrders: {
          id: "question_12",
          key: "isReturnMethodSameForAllOrders",
          value: null,
          showQuestion: false,
        },
      },
    },
  },

  // step15: {
  //   fetchStepDataAjaxStatus: "pending",
  //   showStep: false,
  //   stepData: {
  //     fully_automate_current_step: false,
  //     transfer_to_agent: false,
  //     confirmationMessage: "",
  //     stepStatus: "disabled",
  //     stepQuestions: {},
  //   },
  // },

  // new step added in v3
  step15: {
    fetchStepDataAjaxStatus: "pending",
    showStep: false,
    stepData: {
      configStatus: "pending",
      fully_automate_current_step: false,
      transfer_to_agent: false,
      confirmationMessage: "",
      stepStatus: "disabled",
      stepQuestions: {
        // new question added in v3
        isRestockingFeeChargedForMajorityOrders: {
          id: "question_13",
          key: "isRestockingFeeChargedForMajorityOrders",
          value: null,
          showQuestion: false,
        },
        // new question added in v3
        usuallyChargedRestockingFee: {
          id: "question_14",
          key: "usuallyChargedRestockingFee",
          value: null,
          showQuestion: false,
        },
        // new question added in v3
        restockingFeeDisplayMessage: {
          id: "question_15",
          key: "restockingFeeDisplayMessage",
          value: null,
          showQuestion: false,
        },
        isRestockingFeeSameForAll: {
          id: "question_15",
          key: "isRestockingFeeSameForAll",
          value: null,
          showQuestion: false,
        },
      },
    },
  },

  getAllDiscountCodes: {
    data: [],
    metaData: { endCursor: "", hasNextPage: false },
  },
  getAllDiscountCodesAjax: "idle",
  getAllOrderTags: {
    data: [],
    metaData: { count: -1, hasNextPage: false },
  },
  getAllOrderTagsAjax: "idle",
  getAllProductTags: {
    data: [],
    metaData: {
      count: -1,
      hasNextPage: false,
    },
  },
  getAllProductTagsAjax: "idle",
  getAllShopifyProducts: [],
  getAllShopifyProductMeta: { count: 0, total: 0 },
  getAllShopifyProductAjax: "idle",
  integrations: {}, //default setting the integrations empty
  integrationIds: [],
  integrationMetaData: { total: 0, count: 0 },
  returnReasonListIds: [],
  returnReasonList: {},
  returnReasonAjaxStatus: "idle",
  fetchAllIntegrationsAjaxStatus: "pending", //default get All integration api status is pending, it will show the loader until state fulfilled
  addIntegrationAjaxStatus: "idle", //add new integration ajax status, by default it will be idle
  stepConfiguredPreviewModal: {
    //preview modal data
    showModal: false,
    isConfigured: false,
    title: "",
    subMessage: "",
    isTransfered: false,
    stepType: "select",
  },
  showPreview: {
    showNotEligible: false,
  },
  activeModal: null,
  activeSubModal: null,
};

export const returnExchangeSlice = createSlice({
  name: "returnExchange",
  initialState,
  // reducers: {
  // getAllOrderTagsReducer: (
  //   state: returnExchange,
  //   params: PayloadAction<{ orderTags: GetAllOrderTags }>
  // ) => {
  //   if (params.payload.orderTags) {
  //     state.getAllOrderTags = params.payload.orderTags;
  //   }
  // },
  // getAllProductTagsReducer: (
  //   state: returnExchange,
  //   params: PayloadAction<{ productTags: GetAllProductTags }>
  // ) => {
  //   if (params.payload.productTags) {
  //     state.getAllProductTags = params.payload.productTags;
  //   }
  // },
  // getAllDiscountCodesReducer: (
  //   state: returnExchange,
  //   params: PayloadAction<{ discountCodes: GetAllDiscountCodes }>
  // ) => {
  //   if (params.payload.discountCodes) {
  //     state.getAllDiscountCodes = params.payload.discountCodes;
  //   }
  // },
  // getAllShopifyProuctReducer: (
  //   state: returnExchange,
  //   params: PayloadAction<{ products: ProductDetail[] }>
  // ) => {
  //   if (params.payload.products) {
  //     state.getAllShopifyProducts = params.payload.products;
  //   }
  // },
  // },
  reducers: returnExchangeReducers,
  extraReducers: returnExchangeBuilders,
});

export default returnExchangeSlice.reducer;

//selector which will return if the question is answered or not
export const isQuestionAnsweredSelector = createSelector(
  [isQuestionAnswered],
  (isQuestionAnswered) => isQuestionAnswered,
);

export const isStepTransferedToAgentSelector = createSelector(
  [isStepTransferedToAgent],
  (isStepTransferedToAgent) => isStepTransferedToAgent,
);

export const isSegementCompletedSelector = createSelector(
  [isSegementCompleted],
  (isSegementCompleted) => isSegementCompleted,
);

export const areAllSegementCompletedSelector = createSelector(
  [areAllSegementCompleted],
  (areAllSegementCompleted) => areAllSegementCompleted,
);
export const {
  // getAllOrderTagsReducer,
  // getAllProductTagsReducer,
  // getAllDiscountCodesReducer,
  // getAllShopifyProuctReducer,
  resetAllStepsData,
  updateQuestionStepNine,
  updateQuestionStepEleven,
  updateQuestionStepTwelve,
  updateQuestionStepThirteen,
  moveToNextStep,
  setAutomateType,
  returnIntegrationData,
  setStepPreviewModalData,
  resetStepPreviewModalData,
  setActiveIntegrationId,
  setActiveIntegrationData,
  setSelectedStepId,
  updateReturnGlobalLoader,
  resetStepsData,
  setBrandAndPublishedStatus,
  updateQuestionStepFourteen,
  updateQuestionStepFifteen,
  addReturnReason,
  deleteReturnReason,
  reOrderReturnReason,
  updateReturnReason,
  updateQuestionTwelve,
  handleSingleQuestionVisibility,
  discardTheStepChanges,
  updateStepStatus,
  handleStepVisibility,
  updateStepConfigStatus,
  setShowPreviewer,
  setGoLiveVisiblity,
  addInitialData,
  validateStepQuestions,
  setShowValidationErros,
  updateReturnIntegrationData,
  updateAntiRefundModalStatus,
  setActiveModal,
} = returnExchangeSlice.actions;
