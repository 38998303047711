
import { AJAXSTATUS } from "src/globals/constants";
import { conditionType } from "src/services/Bot/BotProfiles/getConditionOptions.service";

export const ruleTypes = ["AND", "OR"] as const;
export interface AudienceConfigCondition {
  id: string;
  ruleType: typeof ruleTypes[number];
  conditionKey: string | null;
  values: string | Array<string|number> | null;
  operator: string | undefined;
  error?: string;
  isNewlyAdded?: boolean;
  selectedTags?: Array<any>
}
export interface botProfilesSlice {
  audienceConfigOptions: conditionType[];
  audienceConfigConditions: AudienceConfigCondition[];
  audienceConfigConditionAJAX: AJAXSTATUS;
  initialConfigHash: string;
  backRedirectPath: string,
}

export const initialState: botProfilesSlice = {
  audienceConfigOptions: [],
  audienceConfigConditions: [],
  audienceConfigConditionAJAX: "pending",
  initialConfigHash: "",
  backRedirectPath: "",
};
