import ParseHTML from "src/components/ParseHTML";
import styles from "./BotInternalNote.module.scss";
import logo from "src/assets/images/saufter.gif";
import { Message } from "src/services/LiveChat/messageExchange/getMessages";

/**
 * Component for rendering Bot Internal Notes
 */
const BotInternalNote = ({ messageText }: Message) => {
  return (
    <div className="d-flex align-items-center justify-content-end mb-2">
      {/* Bot Message */}
      <div className={`${styles.botBox}`}>
        {/* Leaving HTML for Message here for future use cases */}
        {/* Example Note for below Message */}
        {/* `<p>
          <span
            class="mceNonEditable"
            style="color: #0b68bb; background-color: #dbebf9; border-radius: 7px; font-size: 12px; padding: 0px 4px; outline: none; cursor: default; display: inline-block;"
            data-agent-mention-id="0"
          >
            {" "}
            @All{" "}
          </span>{" "}
          &nbsp;Bot limit reached.{" "}
          <a href="/settings/billing">Upgrade now</a> so
          that I can respond.
        </p>` */}

        {/* 
        <span className={`px-1 ${styles.all}`}>@All</span>
        <span className={`ps-1 ${styles.botText}`}>
          Bot limit reached.{" "}
          <span className={`${styles.upgradeText}`}> Upgrade now</span> so that
          I can respond.
        </span> 
        */}

        {/* Parse the note message */}
        <ParseHTML html={messageText} />
      </div>

      {/* Bot Image */}
      <div className={`${styles.imgWrapper}`}>
        <img
          src={logo}
          alt=""
        />
      </div>
    </div>
  );
};

export default BotInternalNote;
