import { Modal } from "react-bootstrap";
import KbBrandModal from "../Children/TabSection/KnowledgeSetting/Children/KbBrandModal/KbBrandModal";
import { useEffect, useState } from "react";
import styles from "../Children/TabSection/KnowledgeSetting/KnowledgeSetting.module.scss";
import KbSettings from "../Children/TabSection/KnowledgeSetting/Children/KbSettings/KbSettings";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import {
  GetAllKnowledgeBasePayload,
  KnowledgeBase,
  getAllKnowledgeBase,
} from "src/services/KnowledgeBase/getAllKnowledgeBase.service";
import Loader from "src/components/Loader";
import InfiniteScroll from "src/components/InfiniteScrollBothSide";
import SuccessModal from "../Children/SuccessModal/SuccessModal";
import { useAppSelector } from "src/store/store";
import PlanUpgrade from "src/components/PlanUpgrade/PlanUpgrade";

const BrandBasedKB = () => {
  const [showModal, setShowModal] = useState(false);

  const [addNewEnabled, setAddNewEnabled] = useState(true);

  const modalShow = () => {
    setShowModal(true);
  };
  const onHide = () => {
    setShowModal(false);
  };

  const payload: GetAllKnowledgeBasePayload = {
    limit: 10,
    start: 0,
  };

  // Fetching disabledFeatures from the Redux store
  const disabledFeatures = useAppSelector(
    (state) => state.globals.currentUserData?.disabledFeatures,
  );

  const { data, isLoading, hasNextPage, fetchNextPage, refetch } =
    useInfiniteQuery({
      queryKey: ["getAllKnowledgeBase", payload],
      queryFn: ({ pageParam = payload }) => getAllKnowledgeBase(pageParam),
      getNextPageParam: (lastPage: any, allPages) => {
        const data = allPages.flatMap((data: any) => data.data); // flatMap is used to flatten the array of arrays
        // If the data length is less than the total count then return the next page param
        if (data.length < lastPage.metaData.totalCount) {
          const nextPageParam = {
            ...payload,
            start: data.length, // setting the start to the length of the data
          };
          return nextPageParam;
        }
        return null;
      },
      keepPreviousData: true,
      enabled: !(
        (disabledFeatures && disabledFeatures?.includes("knowledge_base")) //Disable api call when the feature included in disabledFeatures
      ),
    });

  const allKB = data?.pages.flatMap((data: any) => data.data);

  const isAddNewEnabled = data?.pages.flatMap(
    (data: any) => data.metaData.addNewEnabled,
  )[0];

  useEffect(() => {
    setAddNewEnabled(isAddNewEnabled);
  }, [isAddNewEnabled]);

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [deletedBrandName, setDeletedBrandName] = useState<string>("");
  return (
    <div className={`w-100 px-4 py-3 ${styles.kbWrapper}`}>
      <div className="d-flex align-items-center justify-content-between w-100 mb-3 px-2">
        <h1 className={`mb-0 saufter_h1`}>Knowledge Base</h1>
        {(addNewEnabled ||
          !(
            disabledFeatures && disabledFeatures?.includes("knowledge_base")
          )) && (
          <button
            className={`${styles.addBtn}`}
            onClick={modalShow}
          >
            Add new
          </button>
        )}
        <Modal
          backdropClassName={`${styles.modalBack}`}
          show={showModal}
          onHide={onHide}
          dialogClassName={`${styles.modalDialog}`}
          contentClassName={`${styles.modalContent}`}
        >
          <KbBrandModal
            onHide={onHide}
            refetchAllKb={refetch}
          />
        </Modal>
      </div>
      <div>
        {disabledFeatures && disabledFeatures?.includes("knowledge_base") ? (
          <div className={`${styles.upgradeBox}`}>
            <PlanUpgrade
              upgradeText={"Upgrade to paid plan"}
              subText={
                "To add knowledge base articles on your brand upgrade to paid plan"
              }
            />
          </div>
        ) : isLoading ? (
          <div>
            <Loader />
          </div>
        ) : (
          // Infinite Scroll Component to load more data on scroll
          <div style={{ height: "83vh" }}>
            <InfiniteScroll
              loadMoreFromBottom={fetchNextPage}
              hasMoreBottom={hasNextPage}
              className={`h-100 overflow-auto py-2`}
              style={{ borderRadius: "9px" }}
            >
              {allKB?.length === 0 ? (
                <div className="text-center mt-5">
                  <h4>No Knowledge Base Found</h4>
                </div>
              ) : (
                allKB?.map((knowledgeBase: KnowledgeBase, idx) => {
                  return (
                    <div
                      className="px-2"
                      key={knowledgeBase.knowledgeBaseId}
                    >
                      <KbSettings
                        knowledgeBase={knowledgeBase}
                        key={idx}
                        setShowSuccessModal={setShowSuccessModal}
                        setDeletedBrandName={setDeletedBrandName}
                      />
                    </div>
                  );
                })
              )}
            </InfiniteScroll>
          </div>
        )}
      </div>
      {showSuccessModal && (
        <SuccessModal
          brandName={deletedBrandName}
          setShowSuccessModal={setShowSuccessModal}
        />
      )}
    </div>
  );
};

export default BrandBasedKB;
