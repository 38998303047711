import { useEffect, useState } from "react";
import styles from "./AddEmail.module.scss";
import backBtn from "src/assets/images/backArrow.png";
import { Modal } from "react-bootstrap";
import AddModal from "./Children/AddModal/AddModal";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { fetchGetAllEmailIntegration } from "src/store/slices/emailIntegration/emailIntegration.thunks";
import { GoMail } from "react-icons/go";
import { BsArrowRightCircle, BsExclamationDiamondFill } from "react-icons/bs";
import { getBrandingName } from "src/utils/utils";
import Loader from "src/components/Loader";
import PlanUpgradeOverlay from "src/components/PlanUpgrade/PlanUpgradeOverlay/PlanUpgradeOverlay";

const brandingName = getBrandingName();

function AddEmail() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // State for toggle modal
  const [showModal, setShowModal] = useState(false);

  const isAddEmailIntegrationDisabled = useAppSelector(
    (state) =>
      state.globals.currentUserData?.disabledFeatures?.includes(
        "add_email_integration",
      ) ?? false,
  );

  //   Function to show modal
  const onShow = (e: any) => {
    if (isAddEmailIntegrationDisabled) {
      return;
    }
    e.stopPropagation();
    setShowModal(true);
  };
  //   Function to hide modal
  const onHide = () => {
    setShowModal(false);
  };

  const navigateToIntigrations = () => {
    navigate("/settings/browse-integration");
  };

  const { allEmailIntegrations: getAllEmail, allEmailLoading } = useAppSelector(
    (state) => {
      return state.emailIntegration;
    },
  );

  useEffect(() => {
    dispatch(fetchGetAllEmailIntegration());
  }, []);

  return (
    <>
      <div className="pe-0 pe-md-2 pe-lg-4 ">
        <div className="d-flex flex-column flex-md-row w-100">
          <div
            className={`${styles.backWidthAndHeight} `}
            onClick={navigateToIntigrations}
            role="button"
          >
            <img
              src={backBtn}
              className={`${styles.backBtn}`}
              alt="back"
            />
          </div>

          <div className={`mt-2 mt-md-0 ${styles.contentWidth}`}>
            {/* Home page ui */}
            <div className={`d-flex justify-content-between flex-wrap`}>
              <div className="ps-2">
                <h4 className={`mb-0 ${styles.heading}`}>Email</h4>
                <span className={`${styles.subHeading}`}>
                  Connect your support email addresses and respond to your
                  customers from {`${brandingName}`}
                </span>
              </div>
              <div className="">
                <PlanUpgradeOverlay
                  showOverlay={isAddEmailIntegrationDisabled}
                  message="Upgrade to add more email integrations"
                  placement="bottom"
                >
                  <button
                    className={`mt-2 mt-md-0 ${styles.addBtn}`}
                    onClick={onShow}
                    disabled={isAddEmailIntegrationDisabled}
                  >
                    Add Email
                  </button>
                </PlanUpgradeOverlay>
              </div>
            </div>
            {allEmailLoading === "pending" && (
              <div className="w-100 d-flex justify-content-center align-items-center">
                <Loader />
              </div>
            )}

            {allEmailLoading !== "pending" && (
              <>
                <div className={`w-100 overflow-auto`}>
                  <div className={`pt-4 mt-2 ${styles.addedMail}`}>
                    {getAllEmail.length > 0 ? (
                      getAllEmail.map((email) => {
                        return (
                          <div
                            className={` ${styles.integrateBox} d-flex justify-content-between align-items-center`}
                          >
                            <div
                              className={`d-flex justify-content-start align-items-center text-truncate ${styles.mailInfo}`}
                            >
                              <div className="fs-2 ms-2">
                                <GoMail />
                              </div>

                              {email.isEmailVerified ? null : (
                                <div
                                  className={`ms-2 text-danger ${styles.exclaim}`}
                                >
                                  <BsExclamationDiamondFill />
                                </div>
                              )}

                              <div className={`pt-1 ms-2 ${styles.emailName}`}>
                                {email.emailName}
                              </div>
                              <div
                                className={`pt-1 ms-2 ${styles.emailAddress}`}
                              >
                                {email.emailAddress}
                              </div>
                            </div>
                            {/* Arrow & Button Section  */}
                            <div className="d-flex align-items-center">
                              {/* Button If Something is Pending  */}
                              {email.isEmailVerified &&
                              email.isDomainVerified ? null : (
                                <div className="py-1 ">
                                  <button
                                    className={` px-3  mx-3 py-1 px-2  ${styles.statusBtn}`}
                                    onClick={() => {
                                      return navigate(
                                        `/settings/integrations/email/${
                                          email.id
                                        }/${
                                          email.isEmailVerified
                                            ? "verifyDomain"
                                            : "verifyEmail"
                                        }`,
                                      );
                                    }}
                                  >
                                    {!email.isEmailVerified
                                      ? "Verify E-mail Forwarding"
                                      : "Complete Domain Verification"}
                                  </button>
                                </div>
                              )}

                              {/* Arrow Button  */}
                              <div
                                className={`me-2 ${styles.goTo}`}
                                onClick={() => {
                                  if (email.isEmailVerified) {
                                    return navigate(
                                      `/settings/integrations/email/${email.id}/complete`,
                                    );
                                  } else {
                                    return navigate(
                                      `/settings/integrations/email/${email.id}/verifyEmail`,
                                    );
                                  }
                                }}
                                role="button"
                              >
                                <BsArrowRightCircle />
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className={`py-3 ps-2 ${styles.integrateBox}`}>
                        <span className={` ${styles.noIntegration}`}>
                          You have no integration at the moment
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className={`ms-1 pt-3`}>
                  <span
                    className={`cursor-pointer ${
                      isAddEmailIntegrationDisabled ? "d-none" : ""
                    } ${styles.addSpan}`}
                    onClick={onShow}
                  >
                    + Add Email
                  </span>

                  <Modal
                    backdropClassName={`${styles.modalBack}`}
                    show={showModal}
                    onHide={onHide}
                    dialogClassName={`${styles.modalDialog}`}
                    contentClassName={`${styles.modalContent}`}
                    centered={true}
                    enforceFocus={false}
                  >
                    <AddModal onHide={onHide} />
                  </Modal>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default AddEmail;
