import { useCallback, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "./FilterDatePicker.module.scss";
import styles from "./FilterDatePicker.module.scss";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { Dropdown } from "react-bootstrap";
import React from "react";

function FilterDatePicker({
  startDate,
  endDate,
  onChange,
  startDateEnabled,
  endDateEnabled,
}: {
  startDate: null | Date;
  endDate: null | Date;
  onChange: (dates: any) => void;
  startDateEnabled: boolean;
  endDateEnabled: boolean;
}) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // State for abbrevated month name
  const [selectedMonth, setSelectedMonth] = useState(
    new Date().toLocaleString("en-US", { month: "long" }).substring(0, 3)
  );

  // This function is for custom dropdown toggle with abbrevated month name
  const CustomToggle = React.forwardRef<
    HTMLDivElement,
    { onClick: (e: React.MouseEvent<HTMLDivElement>) => void }
  >(({ onClick }, ref) => (
    <div
      className={`${styles.navigationHeading} border-0`}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      role="button"
    >
      {selectedMonth.slice(0, 3)}
    </div>
  ));
  return (
    // <div
    //   className={`d-flex align-items-center rangePicker_CURRENT_STATUS border border-danger`}
    // >
    <DatePicker
      selected={startDate}
      startDate={startDate}
      endDate={endDate}
      onChange={onChange}
      selectsStart={startDateEnabled}
      selectsEnd={endDateEnabled}
      dateFormat={"dd/MM/yyyy"}
      fixedHeight
      inline
      showYearDropdown
      calendarClassName={`bg-white w-100 ${styles.fitlerDatePicker}`}
      renderCustomHeader={({
        decreaseMonth,
        increaseMonth,
        date,
        changeMonth, // funciton to change the month when the month dropdown is clicked
        changeYear, // funciton to change the year when the year dropdown is clicked
      }) => {
        return (
          <div
            className={`d-flex justify-content-between bg-white ${styles.datePickerHeader} m-1 px-2 h-100`}
          >
            {/* Left Arrow */}
            <div
              className={`px-2 py-1 ${styles.navigationButton}`}
              // Event handler for the previous month button
              onClick={() => {
                const currentYear = date.getFullYear();
                const currentMonth = date.getMonth();

                // Check if the current year is greater than 1900 or if it is the year 1900 and the current month is greater than 0
                if (
                  currentYear > 1900 ||
                  (currentYear === 1900 && currentMonth > 0)
                ) {
                  // Call the decreaseMonth() function to go back to the previous month
                  decreaseMonth();

                  // Update the selected month based on the new date
                  setSelectedMonth(
                    new Date(currentYear, currentMonth - 1, 1)
                      .toLocaleString("en-US", { month: "long" })
                      .substring(0, 3)
                  );
                }
              }}
              role="button"
            >
              <span>
                <i className="fa-solid fa-chevron-left"></i>
              </span>
            </div>
            {/* Month and Year Dropdown */}
            <div className={`d-flex align-items-center justify-content-center ${styles.monthYear} ms-2`}>
              {/* Removed old select and created a dropdown menu for selecting a month */}
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic" as={CustomToggle}>
                  {selectedMonth}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {/* Render dropdown items for each month */}
                  {months.map((month, index) => (
                    <Dropdown.Item
                      key={index}
                      value={index}
                      onClick={() => {
                        setSelectedMonth(month);
                        changeMonth(Number(index));
                      }}
                    >
                      {month}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>

              {/* / This span element displays the year from the "date" variable. */}
              {/* <span className={`mx-1 ${styles.navigationHeading}`}>
                {date.getFullYear()}
              </span> */}
              {/* Year Dropdown */}
              <select
                className={`border-0 mx-1 ${styles.customSelect}`}
                value={date.getFullYear()}
                onChange={(e) => {
                  changeYear(Number(e.target.value));
                }}
                style={{ overflowY: "auto" }}
                role="button"
              >
                {/* Render options for years */}
                {Array.from(
                  { length: new Date().getFullYear() - 1900 + 1 },
                  (_, i) => new Date().getFullYear() - i
                ).map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
            {/* Right Arrow */}
            <div
              className={`px-2 py-1 ${styles.navigationButton}`}
              // Event handler for the next month button
              onClick={() => {
                const nextYear = new Date().getFullYear() + 1;
                const currentYear = date.getFullYear();
                const isLastMonthOfLastYear =
                  currentYear === nextYear - 1 && date.getMonth() === 11;

                // Check if the current year is less than the next year and it is not the last month of the last year
                if (currentYear < nextYear && !isLastMonthOfLastYear) {
                  // Call the increaseMonth() function to go to the next month
                  increaseMonth();

                  // Update the selected month based on the new date
                  setSelectedMonth(
                    new Date(currentYear, date.getMonth() + 1, 1)
                      .toLocaleString("en-US", { month: "long" })
                      .substring(0, 3)
                  );
                }
              }}
              role="button"
            >
              <i className="fa-solid fa-chevron-right"></i>
            </div>
          </div>
        );
      }}
      weekDayClassName={() => "text-center"}
      formatWeekDay={(nameOfDay) => nameOfDay.slice(0, 3)}
      dayClassName={(date) => {
        return `m-0 ${styles.fontSize}`;
      }}
      maxDate={new Date()}
      showMonthYearDropdown={true}
      yearDropdownItemNumber={8}
    ></DatePicker>
    // </div>
  );
}

export default FilterDatePicker;
