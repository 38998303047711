import { useCallback, useMemo } from "react";
import { useReturnAutoWorkFlow } from "../../../../hooks/useReturnAutoWorkFlow";
import {
  SavedStepAndQuestions,
  StepQuestion as IStepQuestion,
} from "../../../../ReturnAutoWorkFlow.types";
import StepQuestion from "../StepQuestion/StepQuestion";
import styles from "./StepDetails.module.scss";
import { questionsHaveErrorsInAStep } from "src/features/ReturnAutoWorkFlow/hooks/useSaveStepsAndQuestions";
import { useReturnPreviewState } from "src/features/ReturnAutoWorkFlow/hooks/useReturnPreview";
import {
  findFirstQuestionInStep,
  getBooleanValue,
  getQuestionByKey,
  updatePreviewDetails,
} from "src/features/ReturnAutoWorkFlow/helpers/returnPreview.helpers";
import { EReturnAutomationQuestionKeys } from "src/enums/EReturnAutomationQuestionKeys";

/**
 * StepDetails component.
 *
 * This component displays the details of a saved step including its title, subtitle,
 * and associated questions.
 *
 * @param params - The step and questions data.
 * @param params.stepSaved - The current saved step and questions data.
 * It contains only those steps and questions which are configured nd can be shown in the view.
 *
 * @returns The rendered component.
 */
function StepDetails({
  stepNumber,
  ...stepSaved
}: SavedStepAndQuestions & { stepNumber: number }) {
  const { returnAutoWorkFlow, dispatch } = useReturnAutoWorkFlow();

  /**
   * Need to find the step data from the actual config after getting the step id from the step saved data.
   */
  const stepData = useMemo(() => {
    return (returnAutoWorkFlow.configuredSteps ?? {})[stepSaved.stepId];
  }, [returnAutoWorkFlow, stepSaved]);

  const { updateContext } = useReturnPreviewState();
  const configuredSteps = useMemo(() => {
    return returnAutoWorkFlow?.configuredSteps ?? {};
  }, [returnAutoWorkFlow]);
  /**
   * Function to update return preview order and page based on updated question
   */
  const updateReturnPreview = useCallback(
    function ({ stepQuestion }: { stepQuestion: IStepQuestion | null }) {
      if (stepQuestion) {
        const restockingFeeQuestion = getQuestionByKey(
          stepQuestion,
          EReturnAutomationQuestionKeys.isRestockingFeeChargedForMajorityOrders,
          configuredSteps,
        );
        const returnReasonsQuestion = getQuestionByKey(
          stepQuestion,
          EReturnAutomationQuestionKeys.selectReturnReason,
          configuredSteps,
        );

        const isRestockingFee = restockingFeeQuestion
          ? restockingFeeQuestion?.value != null &&
            restockingFeeQuestion?.value != undefined &&
            getBooleanValue(restockingFeeQuestion, "noCharge")
          : false;
        const showReturnReasons = returnReasonsQuestion
          ? returnReasonsQuestion?.value != null &&
            returnReasonsQuestion?.value != undefined &&
            !getBooleanValue(returnReasonsQuestion, "notRequired")
          : false;

        // Get the selectedOrderId and activeStep based on the questionKey
        const { selectedOrderId, activeStep } = updatePreviewDetails({
          questionKey: stepQuestion.questionKey ?? "otherQuestion",
          showReturnReasons: showReturnReasons,
          isRestockingFeeNotCharged: isRestockingFee,
        });

        // Update the selectedOrderId and activeStep in return preview context
        updateContext({
          selectedOrderId: selectedOrderId,
          activeStep: activeStep,
        });
      }
    },
    [configuredSteps],
  );

  const stepIsActive = useMemo(() => {
    return returnAutoWorkFlow.activeStepId &&
      returnAutoWorkFlow.activeStepId + "" === stepData.stepId + ""
      ? true
      : false;
  }, [returnAutoWorkFlow, stepData]);

  /**
   * Function to dispatch the active step data.
   */
  const makeThisStepActive = useCallback(() => {
    if (!stepIsActive) {
      dispatch("changeActiveStep", stepSaved.stepId);
      const question = findFirstQuestionInStep(stepData);
      updateReturnPreview({ stepQuestion: question });
    }
  }, [dispatch, stepSaved, stepIsActive]);

  const questionsHaveErrors = useMemo(
    () => questionsHaveErrorsInAStep(stepData),
    [stepData],
  );

  /**
   * Check if stepData is undefined.
   */
  if (!stepData) {
    return <div></div>;
  }

  return (
    <div
      className={`${styles.questionContainer} ${
        questionsHaveErrors ? styles.canShowErrors : ""
      }`}
      onClick={makeThisStepActive}
    >
      <div
        className={`px-3 ${styles.selectIdBox} ${styles.stepNumber} ${
          stepData.stepStatus === "completed" ? styles.completed : ""
        } ${stepIsActive ? styles.active : ""} pb-2 pt-3 mb-2`}
        data-step-number={stepNumber}
      >
        <div
          className={`${styles.activeLine}`}
          role="button"
        >
          <div
            className={`${
              stepIsActive ? styles.selectText : styles.changeTextColor
            }`}
          >
            {stepData.stepTitle}
          </div>
          <div className={`pb-3 ${styles.selectPara}`}>
            {stepData.stepSubTitle}
          </div>
        </div>

        {stepIsActive
          ? stepSaved.questionIds.map((questionId) => {
              /**
               * Returning the question component for saved question ids wich are viewable.
               */
              return (
                <StepQuestion
                  key={questionId}
                  stepQuestion={stepData.stepQuestions[questionId]}
                  stepData={stepData}
                  updateReturnPreview={updateReturnPreview}
                />
              );
            })
          : ""}
      </div>
    </div>
  );
}

export default StepDetails;
