import { useCallback } from "react";
import { Modal } from "react-bootstrap";
import styles from "./DeleteModal.module.scss";

interface Props {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleDelete: () => void;
  warningText: string;
}

const DeleteModal = ({
  showModal,
  setShowModal,
  handleDelete,
  warningText,
}: Props) => {
  const onHide = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  return (
    <Modal
      backdropClassName={`${styles.modalBack}`}
      show={showModal}
      onHide={onHide}
      dialogClassName={`${styles.modalDialog}`}
      contentClassName={`${styles.modalContent}`}
      centered={false}
      enforceFocus={false}
    >
      <div>
        <div className={`text-center`}>
          <h1 className={`${styles.header}`}>Delete Whatsapp Integration</h1>
        </div>
        <div className={`${styles.body} px-2`}>
          <h4 className={`${styles.bodyHead} mt-2 mb-0`}>{warningText}</h4>
          <div className={`text-center ${styles.btnDiv} mt-auto mb-3 mx-auto`}>
            <button
              className={`${styles.keep}`}
              onClick={onHide}
            >
              No, keep it
            </button>
            <button
              className={`${styles.disconnect}`}
              onClick={handleDelete}
            >
              Yes, Delete
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default DeleteModal;
