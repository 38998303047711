import { useReturnAutoWorkFlow } from "src/features/ReturnAutoWorkFlow/hooks/useReturnAutoWorkFlow";
import GoLiveButton from "./children/GoLiveButton/GoLiveButton";
import styles from "./GoLiveStep.module.scss";

function GoLiveStep({ stepNumber }: { stepNumber: number }) {
  const { returnAutoWorkFlow } = useReturnAutoWorkFlow();

  return (
    <div className={`mt-3 ${styles.questionContainer}`}>
      <div
        className={`px-3 ${styles.goLiveBox} ${styles.stepNumber} ${
          returnAutoWorkFlow.published === true ? styles.completed : ""
        } mb-2`}
        data-step-number={stepNumber}
      >
        <div className={``}>
          <span className={`${styles.selectText}`}>
            {returnAutoWorkFlow.published
              ? "You're Live"
              : "You're ready to go! Go Live"}
          </span>
          <p className={`${styles.selectPara}`}>
            {`The setup is complete. Test the flow and go live with automated returns flow.`}
            {/* Hidden because the details regarding this is not given. */}
            <span className={`d-none ms-1 ${styles.test}`}>
              Test complete workflow
            </span>
          </p>
        </div>
        <div className="ms-0 ms-md-0 my-2 pb-2">
          <GoLiveButton useModal={true} />
        </div>
      </div>
    </div>
  );
}

export default GoLiveStep;
