import { IAvailabilitySetting } from "./availabilitySetting.slice";
import {v4 as uuid} from "uuid";
const fetchAvailabilityDataFulfilled = (
  state: IAvailabilitySetting,
  { payload }: {payload: any}
) => {
  state = {
    ...state,
    availabilityData: payload.availabilityData.availability,
    availableDayOptions: payload.availableDayOptions,
    availabilityDataAjaxStatus: "fulfilled",
  };

  const availableAt = state.availabilityData.agentAvailableAt.map((availableAt)=>{
    return {
      ...availableAt,
      id: uuid()
    }
  });
  state.availabilityData.agentAvailableAt = availableAt;

  return state;
};

const updateAvailabilityDataFulfilled = (
  state: IAvailabilitySetting,
  { payload }: {payload: any}
) => {
  state.updateAvailabilityDataAjaxStatus = "fulfilled";
};

export default { fetchAvailabilityDataFulfilled , updateAvailabilityDataFulfilled};
