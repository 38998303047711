import { getMonthNameDateAmPmTime } from "src/utils/dateLibrary";
import styles from "./TicketEventMsg.module.scss";
import { Modal } from "react-bootstrap";
import { useState } from "react";
import TicketRouteModal from "src/components/TicketCurrentStatus/TicketRouteModal/TicketRouteModal";
import { IMessageData } from "src/store/slices/innerTicket/innerTicket.slice";
import IntegrationFailureText from "src/components/IntegrationFailureModal/children/IntegrationFailureText/IntegrationFailureText";

interface ITicketEventMsg {
  messageData: IMessageData;
}

const TicketEventMsg = ({ messageData }: ITicketEventMsg) => {
  const [showModal, setShowModal] = useState(false);

  const onHide = () => {
    setShowModal(false);
  };

  if (
    messageData?.messageType === "event" &&
    messageData?.eventSubType &&
    messageData?.eventMetaData &&
    messageData?.eventMetaData.ruleId
  ) {
    return (
      <div
        className={`d-flex justify-content-center ${styles.mainDiv} cursor-pointer`}
        onClick={() => setShowModal(true)}
      >
        <p
          className={`${styles.mainPara} d-flex align-items-center justify-content-center`}
        >
          <span className={`${styles.blueDot} mx-2`}></span>
          <span
            className={`${styles.eventMsgParaTxt} d-flex align-items-center justify-content-center`}
          >
            {messageData.message} -{" "}
            <span className={styles.ruleName}>
              {messageData?.eventMetaData?.ruleName},
            </span>
          </span>

          <span
            className={`${styles.eventMsgParaTxtDate} px-2 d-flex align-items-center justify-content-center`}
          >
            {getMonthNameDateAmPmTime(messageData.messageDateTime)}
          </span>
          {/* modal for ticket routing ui  */}
          <Modal
            show={showModal}
            onHide={() => setShowModal(false)}
            contentClassName={`${styles.modalContent}`}
            dialogClassName={`${styles.modalDialog}`}
            backdropClassName={`${styles.backDropClass}`}
            id="ticketRouteModal"
            centered
            enforceFocus={false}
          >
            <TicketRouteModal
              ruleId={messageData?.eventMetaData?.ruleId ?? ""}
              ruleName={messageData?.eventMetaData?.ruleName ?? ""}
              onHide={onHide}
            />
          </Modal>
        </p>
      </div>
    );
  }

  return (
    <div className={`d-flex justify-content-center ${styles.mainDiv}`}>
      {/* <div className={`${styles.mainDiv}`}> */}

      <p
        className={`${styles.mainPara} d-flex align-items-center justify-content-center`}
      >
        <span className={`${styles.blueDot} mx-2`}></span>
        <span
          className={`${styles.eventMsgParaTxt} d-flex align-items-center justify-content-center`}
        >
          {messageData.message},
        </span>

        <span
          className={`${styles.eventMsgParaTxtDate} px-2 d-flex align-items-center justify-content-center`}
        >
          {getMonthNameDateAmPmTime(messageData.messageDateTime)}
        </span>

        {/* June 13 Incase of integration failure event, show integarion not available text */}
        {/* Commenting this as there will be no integration failure for ticket events */}
        {/* {messageData?.integrationFailure && (
          <span>
            <IntegrationFailureText
              integrationFailure={messageData?.integrationFailure ?? null}
            />
          </span>
        )} */}
      </p>
    </div>
  );
};

export default TicketEventMsg;
