import { axiosJSON } from "src/globals/axiosEndPoints";

export async function deleteShopifyIntegration(params: {
  integrationId: number | string;
  is_disconnect?: boolean;
}) {
  const { data: res } = await axiosJSON.post(
    `/api/integration/removeShopifyIntegration`,
    params
  );
  if (res.err || res.error) { //checking if err or error either of property exist in the response and value true 
    throw new Error("Failed to delete integration!");
  }

  return {};
}
