import {
  createAsyncThunk,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { AJAXSTATUS } from "src/globals/constants";
import { IChatFormElementOptionList } from "src/services/LiveChat/Settings/preChatForm/getPreChatFormData";
import { IPreChatFormData } from "../chatForm.declarations";
import preChatFormSettingExtraReducers from "./preChatFormSetting.extraReducers";
import preChatFormSettingReducers from "./preChatFormSetting.reducers";
import preChatFormSettingThunks from "./preChatFormSetting.thunks";

export const fetchPreChatFormData = createAsyncThunk(
  "preChatFormSetting/fetchPreChatFormData",
  preChatFormSettingThunks.fetchPreChatFormDataThunk
);

export interface IPreChatFormSetting{
  preChatFormData: IPreChatFormData;
  formElementOptions: IChatFormElementOptionList;
  preChatFormDataAjaxStatus: AJAXSTATUS;
  updatePreChatFormDataAjaxStatus: AJAXSTATUS;
}

export const initialState : IPreChatFormSetting = {
  preChatFormData: {
    formId: 0,
    enabled: false,
    integrationId: 0,
    elements: [],
  },
  formElementOptions:{
    formElementOptions: {},
    formElementOptionIds: [],
  },
  preChatFormDataAjaxStatus: "pending",
  updatePreChatFormDataAjaxStatus: "idle",
}

export const preChatFormSettingSlice = createSlice({
  name: "preChatFormSetting",
  initialState,
  reducers: preChatFormSettingReducers,
  extraReducers: (builder) => {
    builder.addCase(
      fetchPreChatFormData.fulfilled,
      preChatFormSettingExtraReducers.fetchPreChatFormDataFulfilled
    );
    builder.addCase(fetchPreChatFormData.rejected, (state) => {
      state.preChatFormDataAjaxStatus = "rejected";
      pushTheToast({
        position: "top-right",
        type: "danger",
        text: "Something went wrong!"
      });
    });
    builder.addCase(fetchPreChatFormData.pending, (state) => {
      state.preChatFormDataAjaxStatus = "pending";
    });
  }
});

export const { resetPreChatFormSetting } = preChatFormSettingSlice.actions;

export default preChatFormSettingSlice.reducer;
