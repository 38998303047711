/**
 * This file is the service file used for making api request.
 * It contains the getKnowledgeBaseBrands function service which is explained below.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants"; // this is used for testing purpose only for frontend.

export interface GetKnowledgeBaseBrandsPayload {
  start: number;
  limit: number;
}

export interface Brand {
  id: number | string;
  name: string;
  imageURL: string | null;
}

export interface GetKnowledgeBaseBrandsRes {
  data: Brand[];
  metaData: {
    totalCount: number;
    currentCount: number;
  };
}

/**
 * For testing frontend with own api link if backend is not prepared.
 */
const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/getKnowledgeBaseBrands`
  : "/api/knowledgeBase/getLinkableBrands";

/**
 * This service is used to get the KB brands data.
 */
export const getKnowledgeBaseBrands = async (
  params: GetKnowledgeBaseBrandsPayload
) => {
  const { data: res } = await axiosJSON.post(apiEndPoint, params);
  return res as GetKnowledgeBaseBrandsRes;
};
