import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";
import { AutomationAPIPayload } from "../createAutomation.service";
import { ICernReplyCustomerResponse } from "./createDispatchRuleConfig.service";

export interface ListOption {
  id: string;
  name: string;
}

export interface DispatchToDetail {
  id?: string;
  agentDetails: ListOption[];
  teamDetails?: ListOption;
  channelDetails: ListOption[];
}

export interface IssueDescriptionDetail {
  descriptionId?: string;
  isSuggestArticles?: boolean;
  description: string;
  articlesDetails?: ListOption[];
  articleConfiguredResponse?: string;
  isCernReplyToCustomerResponse?: boolean;
  cernReplyToCustomerResponse?: ICernReplyCustomerResponse[];
  categoryDetails?: ListOption[];
}

export interface IDispatchRuleData {
  configurationId: string;
  integrationId: string;
  configurationState: "pending" | "completed";
  ruleName: string;
  bugCategoryDetails?: ListOption[];
  userSegmentDetails?: ListOption[];
  bugPriorityDetails?: ListOption[];
  dispatchedCount: number;
  createdAtGmt: string;
  isAiReadIssueDescriptionForDispatch?: boolean;
  isSuggestArticles?: boolean;
  issueDescription: IssueDescriptionDetail[];
  dispatchToDetails: DispatchToDetail[];
}

interface Payload {
  integrationId: string;
  configurationId: string;
}

export const getDispatchRuleConfigByIdService = async ({
  payload,
  type,
}: AutomationAPIPayload<Payload>) => {
  const apiEndPoint = IS_TEST_ACTIVE
    ? `${process.env.REACT_APP_TEST_SITE_URL}/bugManagement/getDispatchRuleConfigById`
    : `/api/automation/${type}/getDispatchRuleConfigById`;

  const { data: res } = await axiosJSON.post(apiEndPoint, payload);

  if (res.err || res.error) {
    throw res.msg || res.message || "Cannot get the dispatch rule data";
  }

  if (payload.integrationId) {
    res.data.integrationId = payload.integrationId;
  }

  return res.data as IDispatchRuleData;
};
