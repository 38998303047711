import { useEffect, useState } from "react";

import { Editor as TinyMceEditor } from "tinymce";

interface EditorRefObject<T> {
  current: T | null;
}

interface Props {
  threadData?: any;
  editorRef: EditorRefObject<TinyMceEditor | null>;
}

const EMAIL_THREAD_CLASSNAME = "ticket-email-thread";

function useEmailThreadHandler({ threadData, editorRef }: Props) {
  const [threadText, setThreadText] = useState(threadData?.threadText ?? "");

  useEffect(() => {
    if (threadData?.threadText) {
      setThreadText(threadData.threadText);
    } else {
      setThreadText("");
    }
  }, [threadData]);

  return {
    threadText,
  };
}

export default useEmailThreadHandler;
