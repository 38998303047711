import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import TicketService from "src/services/TicketService/TicketService";
import log from "loglevel";
import { RootState } from "src/store/store";
import { AddTicketTagsPayload } from "src/services/TicketService/addTicketTags";
import { AjaxError } from "src/types/AjaxError";
import { getBulkSelectedTicketIds, getTicketListFilterQuery } from "src/store/slices/tickets/tickets.selectors";
import {
  getAllTags,
  getSelectedTagsIds,
  getUnselectedTagsIds,
} from "src/store/slices/ticketTags/ticketTags.selectors";
import { CreateTagPayload } from "src/services/TicketService/createTag";
import { setBulkUpdatedTicketCount } from "../tickets/tickets.slice";
export type TicketTag = {
  id: number;
  tagName: string;
};

export type TicketTags = {
  [key: string]: TicketTag;
};

/* 
Will map ticket to its mapped tag ids, e.g. {"1": ["32", "34", "45", "23"]} 
*/
export type TicketTagsMap = {
  [key: string]: string[];
};

type TicketTagsSlice = {
  allTags: TicketTags;
  allTagsIds: number[];
  ticketTags: TicketTagsMap;
  selectedTagsIds: number[];
  bulkAddTicketTagsState: "idle" | "pending" | "fulfilled" | "rejected";
  fetchAllTicketsTagsState: "idle" | "pending" | "fulfilled" | "rejected";
  createTagState: "idle" | "pending" | "fulfilled" | "rejected";
};

const initialState: TicketTagsSlice = {
  allTags: {},
  allTagsIds: [],
  ticketTags: {},
  selectedTagsIds: [],
  bulkAddTicketTagsState: "idle",
  fetchAllTicketsTagsState: "idle",
  createTagState: "idle",
};

export const bulkAddTicketTags = createAsyncThunk(
  "ticket/bulkAddTicketTags",
  async (payload: undefined, { getState, dispatch }: { getState: Function, dispatch: Function  }) => {
    try {
      const currentState: RootState = getState();
      const servicePayload: AddTicketTagsPayload = {
        ticketIds: getBulkSelectedTicketIds(currentState),
        tags: getSelectedTagsIds(currentState),
      };
      //checking if all tickets selected in bulk action
      if(currentState.ticket.bulkActionFilters.selectedAllTickets === true){

        //deleting the ticketIds list from payload 
        delete servicePayload['ticketIds'];

        //setting allTickets flag to true
        servicePayload["allTickets"] = true;

        const ticketsQuery = getTicketListFilterQuery(currentState);
        //adding ticket filters to payload
        servicePayload["filters"] = ticketsQuery['filters'];

        //setting if it is a customer view or not
        servicePayload["isCustomerView"] = currentState.ticket.currentView === "customer-view";
      }

      const bulkAddTicketTagsResponse = await TicketService.addTicketTagsApi(
        servicePayload
      );

      //checking if response has updatedTicket count if yes then update the count in redux to show on modal else set to 0
      if(bulkAddTicketTagsResponse?.metaData?.updatedCount){

        dispatch(setBulkUpdatedTicketCount({count: bulkAddTicketTagsResponse?.metaData?.updatedCount}));
      }else{
        dispatch(setBulkUpdatedTicketCount({count: 0}));

      }
      return bulkAddTicketTagsResponse;
    } catch (error) {
      let ajaxError: AjaxError = {
        message: (error as Error).message,
      };
      //resetting updated ticket counts to 0 for success modal
      dispatch(setBulkUpdatedTicketCount({count: 0}));
      return ajaxError;
    }
  }
);

export const createTag = createAsyncThunk(
  "ticket/createTag",
  async (payload: string, { getState, dispatch }: any) => {
    try {
      // const servicePayload: CreateTagPayload = { ticketIds: [1, 2], tags: [2, 3] }
      const servicePayload: CreateTagPayload = { name: payload };
      const createTagResponse = await TicketService.createTagApi(
        servicePayload
      );
      dispatch(fetchAllTicketsTags());
      return createTagResponse;
    } catch (error) {
      let ajaxError: AjaxError = {
        message: (error as Error).message,
      };
      return ajaxError;
    }
  }
);

export const fetchAllTicketsTags = createAsyncThunk(
  "ticket/fetchAllTicketsTags",
  async () => {
    try {
      const { allTags, allTagsIds } = await TicketService.fetchTicketsTagsApi();
      // log.debug({ allTags, allTagsIds });
      return { allTags, allTagsIds };
    } catch (error) {
      let ajaxError: AjaxError = {
        message: (error as Error).message,
      };
      return ajaxError;
    }
  }
);

export const ticketTagsSlice = createSlice({
  name: "ticketTags",
  initialState,
  reducers: {
    selectTagById: (state, action: PayloadAction<number>) => {
      const tagId = action.payload;
      state.selectedTagsIds.push(tagId);
      // log.debug(`selected tag id: ${tagId}`);
    },
    removeTagById: (state, action: PayloadAction<number>) => {
      const removeTagId = action.payload;
      state.selectedTagsIds = state.selectedTagsIds.filter(
        (TagId) => TagId !== removeTagId
      );
      // log.debug(`removed Tag id: ${removeTagId}`);
    },
    selectAllTags: (state) => {
      state.selectedTagsIds = [
        ...state.allTagsIds.map((TagId) => Number(TagId)),
      ];
      // log.debug(`selected all ${state.selectedTagsIds.length} Tags`);
    },
    removeAllTags: (state) => {
      state.selectedTagsIds = [];
      // log.debug(`removed all ${state.selectedTagsIds.length} Tags`);
    },
    resetAddBulkTags: (state) => {
      state.ticketTags = {};
      state.selectedTagsIds = [];
      state.bulkAddTicketTagsState = "idle";
    },
    resetBulkAddTicketTagsState: (state) => {
      state.bulkAddTicketTagsState = "idle";
    },
  },
  extraReducers: (builder) => {
    /* 
    Reducers for bulk action for adding tickets tags
    */
    builder.addCase(bulkAddTicketTags.fulfilled, (state, { payload }) => {
      // log.debug("bulk action payload:", payload);
      state.bulkAddTicketTagsState = "fulfilled";
    });
    builder.addCase(bulkAddTicketTags.rejected, (state) => {
      state.bulkAddTicketTagsState = "rejected";
    });
    builder.addCase(bulkAddTicketTags.pending, (state) => {
      state.bulkAddTicketTagsState = "pending";
    });

    builder.addCase(fetchAllTicketsTags.fulfilled, (state, { payload }) => {
      // log.debug("bulk action payload:", payload);
      const { allTags, allTagsIds } = payload as {
        allTags: TicketTags;
        allTagsIds: number[];
      };
      state.fetchAllTicketsTagsState = "fulfilled";
      state.allTags = allTags;
      state.allTagsIds = allTagsIds;
    });
    builder.addCase(fetchAllTicketsTags.rejected, (state) => {
      state.fetchAllTicketsTagsState = "rejected";
    });
    builder.addCase(fetchAllTicketsTags.pending, (state) => {
      state.fetchAllTicketsTagsState = "pending";
    });

    builder.addCase(createTag.fulfilled, (state) => {
      state.createTagState = "fulfilled";
    });
    builder.addCase(createTag.rejected, (state) => {
      state.createTagState = "rejected";
    });
    builder.addCase(createTag.pending, (state) => {
      state.createTagState = "pending";
    });
  },
});

export const {
  selectTagById,
  removeTagById,
  selectAllTags,
  removeAllTags,
  resetAddBulkTags,
  resetBulkAddTicketTagsState,
} = ticketTagsSlice.actions;

export const getAllTagsSelector = createSelector(
  [getAllTags],
  (getAllTags) => getAllTags
);

export const getSelectedTagsIdsSelector = createSelector(
  [getSelectedTagsIds],
  (getSelectedTagsIds) => getSelectedTagsIds
);

export const getUnselectedTagsIdsSelector = createSelector(
  [getUnselectedTagsIds],
  (getUnselectedTagsIds) => getUnselectedTagsIds
);

export default ticketTagsSlice.reducer;
