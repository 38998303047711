import styles from "./TicketTitleExcerpt.module.scss";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { selectTicketCellData } from "src/store/slices/tickets/tickets.slice";
import { TableCellProps } from "..";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useState } from "react";
import ParseHTML from "src/components/ParseHTML";
import {
  convertHTMLToExcerpt,
  convertHTMLToText,
  replaceImgTagWithFileName,
} from "src/utils/utils";
import { useParams } from "react-router-dom";
import saufterImg from "src/assets/images/saufter.gif";

// function TicketTitleExcerpt({ ticket }: { ticket: Ticket }) {

function TicketTitleExcerpt({ ticketId, colKeys }: TableCellProps) {
  const ticketCellData: any = useAppSelector((state) =>
    selectTicketCellData(state, ticketId, colKeys)
  );
  const { tableType } = useParams();
  const [showOverlay, setShowOveray] = useState(false);

  return (
    <span className="d-flex flex-column justify-content-center">
      <div className={`${styles.root} d-flex`}>
        <div
          style={{
            width: " 24px",
            height: "24px",
          }}
        ></div>
        <div className={`${styles["allTicketsTbody"]}`}>
          <div className={`row m-auto`}>
            <div className={`col-md-12`}>
              <div
                className={`${styles["tbody"]} ${
                  tableType === "mentions"
                    ? ticketCellData[5]
                      ? ""
                      : styles.read
                    : ticketCellData[3]
                    ? styles.read
                    : ""
                }`}
                onMouseOver={() => {
                  setShowOveray(true);
                }}
                onMouseOut={() => {
                  setShowOveray(false);
                }}
              >
                {ticketCellData[0] || ticketCellData[1] ? (
                  <>
                    <OverlayTrigger
                      show={showOverlay && ticketCellData[1]?.trim() !== ""}
                      placement="top"
                      overlay={
                        <Tooltip className={`${styles.toolTipCustom}`}>
                          {/* <div className={`${styles.tooltipHead}`}>
                            {ticketCellData[0]}
                          </div> */}
                          <div className={`${styles.tooltipBody}`}>
                            <ParseHTML
                              // html={replaceImgTagWithFileName({
                              //   html: ticketCellData[2]?.text ?? "",
                              //   attachments:
                              //     ticketCellData[2]?.attachments ?? [],
                              // })}
                              html={
                                tableType === "mentions"
                                  ? convertHTMLToExcerpt(
                                      ticketCellData[4] ?? ""
                                    )
                                  : ticketCellData[1]
                              }
                            />
                          </div>
                        </Tooltip>
                      }
                    >
                      {({ ref, ...triggerHandler }) => (
                        <div
                          {...triggerHandler}
                          ref={ref}
                          onMouseOver={() => {
                            setShowOveray(true);
                          }}
                          onMouseOut={() => {
                            setShowOveray(false);
                          }}
                        >
                          {/* html for ai handled ticket  */}
                          {ticketCellData[6] && ticketCellData[6] == true && (
                            <div className="d-flex align-items-center mt-2 mb-1">
                              <span className={`${styles.saufterText} ${styles.handled}`} style={{ color: "#ffffff !important"}}>
                                AI handled
                              </span>
                              <img
                                src={saufterImg}
                                alt=""
                                className={`${styles.saufterImg}`}
                              />
                            </div>
                          )}
                          {/* html for ai drafted ticket  */}
                          {ticketCellData[7] && ticketCellData[7] == true && (
                            <div className="d-flex align-items-center mt-2 mb-1">
                              <span className={`${styles.saufterText} ${styles.drafted}`} style={{ color: "#ffffff !important"}}>
                                AI Drafted
                              </span>
                              <img
                                src={saufterImg}
                                alt=""
                                className={`${styles.saufterImg}`}
                              />
                            </div>
                          )}
                          <span className={styles.mainSubjectText}>
                            {ticketCellData[0]}
                            <br />
                          </span>
                          {tableType === "mentions" ? (
                            <>
                              <ParseHTML
                                className={`${styles["subSpan"]} ${styles.ticketLastMsg}`}
                                html={convertHTMLToExcerpt(
                                  ticketCellData[4] ?? ""
                                )}
                              />
                            </>
                          ) : (
                            <>
                              {ticketCellData[1]?.trim() !== "" ? (
                                <span
                                  className={`${styles["subSpan"]} ${styles.ticketLastMsg}`}
                                >
                                  {ticketCellData[1]}
                                </span>
                              ) : (
                                <ParseHTML
                                  className={`${styles["subSpan"]} ${styles.ticketLastMsg}`}
                                  html={convertHTMLToExcerpt(
                                    replaceImgTagWithFileName({
                                      html: ticketCellData[2]?.text ?? "",
                                      attachments:
                                        ticketCellData[2]?.attachments ?? [],
                                    })
                                  )}
                                />
                              )}
                              {ticketCellData[2]?.attachments?.length > 0 && (
                                <div
                                  className={`d-flex mt-2 max-content-width ${styles.attachments}`}
                                >
                                  <i className="fa-solid fa-paperclip my-auto ms-2 me-0"></i>
                                  <span className="mx-2 my-auto">{`${
                                    ticketCellData[2].attachments.length
                                  } Attachment${
                                    ticketCellData[2].attachments.length > 1
                                      ? "s"
                                      : ""
                                  }`}</span>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      )}
                    </OverlayTrigger>
                  </>
                ) : (
                  "NA"
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </span>
  );
}

export default TicketTitleExcerpt;
