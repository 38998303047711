/**
 * This file is the service file used for making api request.
 * It contains the getgetNotifyFreqTypeService function service which is explained below.
 *
 * @author Yash Aditya
 */

// Import statements for types
import { axiosJSON } from "src/globals/axiosEndPoints";

// Type for notification frequency
export interface NotificationFrequencyType {
  id: string | number;
  label: string;
  type?: "daily" | "weekly" | "monthly" | "yearly";
  typeLabel?: string;
  fixedNoOfTimes?: number;
  handledByBackend: boolean;
}

// Type for weekdays
export interface WeekDays {
  key: "sun" | "mon" | "tue" | "wed" | "thu" | "fri" | "sat";
  label: string;
}

export interface MonthNames {
  key:
    | "jan"
    | "feb"
    | "mar"
    | "apr"
    | "may"
    | "jun"
    | "jul"
    | "aug"
    | "sep"
    | "oct"
    | "nov"
    | "dec";
  label: string;
}

// Frequency type combined

export interface ResponseFrequencyTypes {
  freqTypes: Array<NotificationFrequencyType>;
  weekDays: Array<WeekDays>;
  months: Array<MonthNames>;
}

// Function to get notification frequency types
export const getNotifyFreqTypeService = async () => {
  const { data: res } = await axiosJSON.post(
    "/api/reports/notificationSettings/getNotificationFrequencyTypes"
  );

  if (res.err || res.error) {
    throw res.msg ?? res.message;
  }

  const ret: ResponseFrequencyTypes = {
    freqTypes: res.data,
    weekDays: res.weekDays,
    months: res.months,
  };

  return ret;
};
