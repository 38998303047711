/**
 * This file contains the custom hook for handling KB settings.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { useMutation } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { resetTheme } from "src/services/KnowledgeBase/Settings/Styles/resetTheme.service";
import useThemeBox from "../../../../ThemeBox/useThemeBox";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";

// Define the interface for the component props
interface Props {
  hideResetModal: (e: any) => void;
}

/**
 * Custom hook for managing the reset modal component in KB settings.
 */
function useResetModal({ hideResetModal }: Props) {
  // Get the `getThemeData` function from the `useThemeBox` hook
  const { getThemeData } = useThemeBox();

  // Mutation hook for resetting the theme to default
  const reset = useMutation({
    mutationFn: resetTheme,
    onSuccess: () => {
      // Refetch theme data after a successful reset
      getThemeData.refetch();

      // Show a success toast notification
      pushTheToast({
        position: "top-right",
        text: "Theme reset to default successfully",
        type: "success",
      });
    },
  });

  // Get the integrationId from the route parameters
  const { integrationId } = useParams();

  /**
   * Function to reset the theme to default.
   * Triggers the reset mutation and hides the reset modal.
   */
  function resetToDefault() {
    // If integrationId is available, trigger the theme reset mutation
    if (integrationId) {
      reset.mutate({
        integrationId: integrationId,
      });
    }

    // Hide the reset modal
    hideResetModal(null);
  }

  // Return the relevant functions and state
  return {
    resetToDefault,
    reset,
  };
}

export default useResetModal;
