import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import {
  ConditionTypes,
  createNewObjectHash,
  getConditionValueOrDefault,
} from "src/features/ReturnAutoWorkFlow/children/ConfigModals/store/returnModalsSliceHelpers";
import {
  FollowUpReturnReason,
  GetAllFollowUpReturnReason,
} from "src/services/ReturnAutoWorkFlow/OldModals/General/getAllReturnReasonList.service";
import { AntiRefundStrategiesOrdersTab } from "src/services/ReturnAutoWorkFlow/OldModals/antiRefundStrategiesOrders/getAntiRefundStrategiesOrders.service";
import { AntiRefundStrategiesOrdersConditions } from "src/services/ReturnAutoWorkFlow/OldModals/antiRefundStrategiesOrders/getAntiRefundStrategiesOrdersConditions.service";
import { v4 as uuid } from "uuid";
import {
  AntiRefundStrategiesReason,
  RefundScenario,
} from "../AntiRefundStrategiesSlice/antiRefundStrategies.slice";
import { AntiRefundStrategiesOrdersState } from "./antiRefundStrategiesOrders.slice";
import {
  fetchAntiRefundStrategiesOrdersThunk,
  getAllReturnReasonListOrdersThunk,
  getRefundRequestFollowUpQuestionsOrdersThunk,
  updateAntiRefundStrategiesOrdersThunk,
} from "./antiRefundStrategiesOrders.thunk";

interface Payload {
  conditionResponse?: AntiRefundStrategiesOrdersConditions;
  configRes: AntiRefundStrategiesOrdersTab[];
  getRefundRequestList?: GetAllFollowUpReturnReason;
}

const fetchAntiRefundStrategiesOrdersThunkFullfilled = (
  state: AntiRefundStrategiesOrdersState,
  payload: Payload,
) => {
  const { conditionResponse, configRes, getRefundRequestList } = payload;

  if (getRefundRequestList) {
    if (getRefundRequestList?.reasons) {
      const reasons: { [key: string]: FollowUpReturnReason } =
        getRefundRequestList.reasons ?? [];
      const returnReasons: AntiRefundStrategiesReason[] = Object.values(
        reasons,
      ).map((obj) => {
        return {
          id: obj.id,
          reasonName: obj.reasonName,
          scenarios: [
            {
              id: uuid(),
              items: [],
              tags: [],
              enabled: false,
              itemsAndTagAllSelected: true,
              followUpQuestions: [],
              isNewScenario: true,
            },
          ],
        };
      });

      state.antiRefundOrderTypesData.refundRequestReasons = returnReasons;
    }
  }
  if (conditionResponse) {
    state.antiRefundOrderTypesData.conditionOptions =
      conditionResponse.conditions;
    state.antiRefundOrderTypesData.defaultOptions =
      conditionResponse.defaultConditions;
    [
      ...state.antiRefundOrderTypesData.conditionOptions,
      ...state.antiRefundOrderTypesData.defaultOptions,
    ].forEach((option) => {
      if (
        !state.antiRefundOrderTypesData.variableNames.some(
          (item: any) => item.id === option.id,
        )
      ) {
        state.antiRefundOrderTypesData.variableNames.push({
          name: option.name,
          id: option.id,
        });
      }

      state.antiRefundOrderTypesData.operatorsNames[option.name] =
        option.operators.map((operator) => {
          state.antiRefundOrderTypesData.operatorSymbolMap[operator.value] =
            operator.name;
          return operator.name;
        });
      state.antiRefundOrderTypesData.valueTypes[option.valueType] = null;
      state.antiRefundOrderTypesData.variableNameToValueType[option.name] =
        option.valueType;
    });
  }

  if (configRes) {
    if (configRes.length > 0) {
      state.antiRefundOrderTypesData.selectedOrderTypeId =
        configRes[0].configTabId.toString();
      state.antiRefundOrderTypesData.antiRefundOrderTypes = configRes.map(
        (tab: any, idx: any) => {
          let toggleAllOrder = true;
          // When no conditions from backend then enable all orders
          if (tab?.conditions?.length == 0) {
            toggleAllOrder = true;
          } else if (tab?.conditions?.length == 1) {
            // If there is only one condition , check if it is default condition , if yes , enable apply to all orders
            if (
              tab.conditions[0].conditionTypeId ==
              state.antiRefundOrderTypesData.defaultOptions[0].id
            ) {
              toggleAllOrder = true;
            } else {
              toggleAllOrder = false;
            }
          } else {
            toggleAllOrder = false;
          }
          let condition: any = state.antiRefundOrderTypesData.defaultOptions[0];
          //Prepare a new deault condition
          const newConditions = [
            {
              id: uuid(),
              operator:
                state.antiRefundOrderTypesData.operatorsNames[
                  condition.name
                ][0],
              ruleType: "AND",
              values: getConditionValueOrDefault(
                state.antiRefundOrderTypesData.variableNameToValueType[
                  condition.name
                ],
              ),
              variableName: condition.name,
            },
          ];
          return {
            orderTypeName: tab.configTabName,
            orderTypeId: tab.configTabId.toString(),
            error: null,
            isAddConditionEnabled: tab.toggleAllOrder ? false : true,
            applyToAllOrders: toggleAllOrder,
            errorCount: 0,
            isValid: false,
            // If conditions are empty , add a default condition
            conditions:
              tab.conditions.length == 0
                ? newConditions
                : tab.conditions.map((condition: any) => {
                    const selectedVariable: any =
                      state.antiRefundOrderTypesData.variableNames.find(
                        (variable: any) =>
                          variable.id == condition.conditionTypeId,
                      );

                    if (selectedVariable) {
                      const variableName = selectedVariable.name;
                      const operatorSymbol = condition.operator;
                      const operator =
                        state.antiRefundOrderTypesData.operatorSymbolMap[
                          operatorSymbol
                        ];
                      const valueType =
                        state.antiRefundOrderTypesData.variableNameToValueType[
                          variableName
                        ];
                      const values = getConditionValueOrDefault(
                        valueType,
                        condition.conditionValue,
                      );

                      // const values = productIdToDetails(Conditionvalues);

                      return {
                        id: condition.id.toString(),
                        variableName,
                        operator,
                        values,
                      } as ConditionTypes;
                    }
                    return {} as ConditionTypes;
                  }),
            refundRequests: {
              reasons: state.antiRefundOrderTypesData.refundRequestReasons.map(
                (reason) => {
                  // For each 'reason' in the 'refundRequestReasons' array, check if it exists in the config from backend
                  const matchingApiResponseReason = tab.refundRequests.find(
                    (apiReason: any) => apiReason.id.toString() === reason.id,
                  );

                  let addRemainScenario = false;
                  // If a matching reason is found, return it as a 'RefundReason'.
                  if (matchingApiResponseReason) {
                    const newScenarios =
                      matchingApiResponseReason.scenarios.map(
                        (scenario: any) => {
                          const areAllItemsSelected =
                            Array.isArray(scenario.items) &&
                            scenario.items.length === 1 &&
                            typeof scenario.items[0] === "string" &&
                            scenario.items[0] === "*";

                          const areAllTagsSelected =
                            Array.isArray(scenario.tags) &&
                            scenario.tags.length === 1 &&
                            scenario.tags[0] === "*";

                          if (areAllItemsSelected && areAllTagsSelected) {
                            scenario.itemsAndTagAllSelected = true;
                          } else {
                            if (scenario.items.length) {
                              scenario.items = scenario.items.map(
                                (item: any) => ({
                                  id: item.productId,
                                  label: item.productName,
                                  type: "item",
                                  value: item.productName,
                                  imgSrc: item.imageUrl,
                                  price: item.productSku,
                                }),
                              );
                            }
                            if (scenario.tags.length) {
                              scenario.tags = scenario.tags.map(
                                (tag: any) => tag,
                              );
                            }
                          }

                          if (
                            (scenario.items.length === 0 ||
                              areAllItemsSelected) &&
                            (scenario.tags.length === 0 ||
                              areAllTagsSelected) &&
                            (scenario.followUpQuestions.length === 0 ||
                              (
                                scenario.followUpQuestions as Array<any>
                              ).includes("*"))
                          ) {
                            scenario.itemsAndTagAllSelected = true;
                            addRemainScenario = true;
                            scenario.items = [];
                            scenario.tags = [];
                          }

                          if (
                            scenario.followUpQuestions.length === 1 &&
                            scenario.followUpQuestions[0] === ("*" as any)
                          ) {
                            scenario.followUpQuestions =
                              state.antiRefundOrderTypesData.refundRequestFollowUpQuestions.map(
                                (element) => ({
                                  elementId: element.elementId,
                                  elementTypeId: element.elementTypeId,
                                  elementValue: element.elementValue,
                                  additionalOptions: element.additionalOptions,
                                  selectedValue: "",
                                }),
                              );
                          }

                          scenario.followUpQuestions =
                            scenario.followUpQuestions.map(
                              (followUpQuestion: any) => {
                                let updatedFollowUpQuestion = {
                                  ...followUpQuestion,
                                };
                                if (
                                  updatedFollowUpQuestion.selectedValue
                                    .length === 1 &&
                                  updatedFollowUpQuestion.selectedValue[0] ===
                                    "*"
                                ) {
                                  updatedFollowUpQuestion.selectedValue =
                                    updatedFollowUpQuestion.additionalOptions.map(
                                      (option: any) => option.id,
                                    );
                                }
                                return updatedFollowUpQuestion;
                              },
                            );

                          return {
                            id: scenario.id,
                            enabled: scenario.enabled,
                            items:
                              scenario.items && scenario.items.length //Changing items format to use in anti refund
                                ? scenario.items
                                : [],
                            tags: scenario.tags ?? [],
                            itemsAndTagAllSelected:
                              scenario.itemsAndTagAllSelected ?? false,
                            followUpQuestions: scenario.followUpQuestions ?? [],
                            additionalInformation: {
                              enabled:
                                scenario.additionalInformation?.enabled ??
                                false,
                              message:
                                scenario.additionalInformation?.message ?? "",
                              extendReturnPeriod: {
                                enabled:
                                  scenario.additionalInformation
                                    ?.extendReturnPeriod?.enabled ?? false,
                                days:
                                  scenario.additionalInformation
                                    ?.extendReturnPeriod?.days ?? 0,
                              },
                            },
                            giveOffer: {
                              enabled: scenario.giveOffer?.enabled ?? false,
                              message: scenario.giveOffer?.message ?? "",
                              offerSelectedItems:
                                (scenario.giveOffer
                                  ?.offerSelectedItems as number[]) ?? [],
                              offerSelectedTags: scenario.giveOffer
                                ?.offerSelectedTags as string[],
                              offerType:
                                (scenario.giveOffer?.offerType as string) ??
                                "other",
                              offerValue:
                                (scenario.giveOffer?.offerValue as number) ?? 0,
                              offerUnit:
                                scenario.giveOffer?.offerUnit ?? "None",
                              name: scenario.giveOffer?.name ?? "",
                            },
                            offerExchange: {
                              enabled: scenario.offerExchange?.enabled ?? false,
                              message: scenario.offerExchange?.message ?? "",
                              exchangeWithOtherItems:
                                scenario.offerExchange
                                  ?.exchangeWithOtherItems ?? false,
                              exchangeWithSameItems:
                                scenario.offerExchange?.exchangeWithSameItems ??
                                false,
                            },
                          } as RefundScenario;
                        },
                      );
                    if (!addRemainScenario) {
                      newScenarios.push({
                        id: uuid(),
                        items: [],
                        tags: [],
                        followUpQuestions: [],
                        enabled: false,
                        itemsAndTagAllSelected: true,
                        isNewScenario: true,
                      });
                    }
                    return {
                      id: matchingApiResponseReason.id,
                      reasonName: matchingApiResponseReason.reasonName,
                      scenarios: newScenarios,
                    } as AntiRefundStrategiesReason;
                  }

                  // If no matching reason is found, create a new 'AntiRefundStrategiesReason' object using 'reason' properties.
                  return {
                    id: reason.id,
                    reasonName: reason.reasonName,
                    scenarios: reason.scenarios,
                  } as AntiRefundStrategiesReason;
                },
              ),
            },
          };
        },
      );
    } else {
      if (conditionResponse?.defaultConditions) {
        const conditions = conditionResponse.defaultConditions
          .map((condition) => {
            const selectedVariable: any =
              state.antiRefundOrderTypesData.variableNames.find(
                (variable: any) => variable.id == condition.id,
              );
            if (selectedVariable) {
              const variableName = selectedVariable.name;
              const operatorSymbol = condition.operators[0].value;
              const operator =
                state.antiRefundOrderTypesData.operatorSymbolMap[
                  operatorSymbol
                ];
              const valueType =
                state.antiRefundOrderTypesData.variableNameToValueType[
                  variableName
                ];
              const values = getConditionValueOrDefault(valueType, null);

              // const values = productIdToDetails(Conditionvalues);

              return {
                id: uuid(),
                variableName,
                operator,
                values,
              } as ConditionTypes;
            }

            return null;
          })
          .filter(Boolean) as ConditionTypes[];

        state.antiRefundOrderTypesData.antiRefundOrderTypes[0].conditions =
          conditions;
        state.antiRefundOrderTypesData.antiRefundOrderTypes[0].orderTypeName =
          "All orders and items";
        state.antiRefundOrderTypesData.antiRefundOrderTypes[0].refundRequests.reasons =
          state.antiRefundOrderTypesData.refundRequestReasons.map((reason) => {
            return {
              id: reason.id,
              reasonName: reason.reasonName,
              scenarios: reason.scenarios,
            } as AntiRefundStrategiesReason;
          });
      }
    }
  }

  const hash = createNewObjectHash({
    excludeKeys: state.excludeKeysForHash,
    hashObject: state.antiRefundOrderTypesData.antiRefundOrderTypes,
  });

  if (hash) {
    state.currentHash = hash;
    state.initialHash = hash;
  }
  // console.log("fetchStep13GetConfigThunkFulfilled -- fulfilled");
  state.fetchAjaxStatus = "fulfilled";
};

const antiRefundStrategiesOrdersBuilders = (
  builder: ActionReducerMapBuilder<AntiRefundStrategiesOrdersState>,
) => {
  builder.addCase(
    fetchAntiRefundStrategiesOrdersThunk.fulfilled,
    (state, action) => {
      state.fetchAjaxStatus = "fulfilled";
      fetchAntiRefundStrategiesOrdersThunkFullfilled(state, action.payload);
    },
  );
  builder.addCase(fetchAntiRefundStrategiesOrdersThunk.pending, (state) => {
    state.fetchAjaxStatus = "pending";
  });

  builder.addCase(fetchAntiRefundStrategiesOrdersThunk.rejected, (state) => {
    state.fetchAjaxStatus = "rejected";
  });

  builder.addCase(
    updateAntiRefundStrategiesOrdersThunk.fulfilled,
    (state, action) => {
      state.updateAjaxStatus = "fulfilled";
      fetchAntiRefundStrategiesOrdersThunkFullfilled(state, action.payload);
      pushTheToast({
        type: "success",
        text: "Configuration Saved",
        position: "top-right",
      });
    },
  );
  builder.addCase(updateAntiRefundStrategiesOrdersThunk.rejected, (state) => {
    state.updateAjaxStatus = "rejected";
    pushTheToast({
      position: "top-right",
      text: "Something went wrong while updating the anti refund request strategies",
      type: "danger",
    });
  });

  builder.addCase(updateAntiRefundStrategiesOrdersThunk.pending, (state) => {
    state.updateAjaxStatus = "pending";
  });
  builder.addCase(getAllReturnReasonListOrdersThunk.pending, (state) => {
    state.fetchAjaxStatus = "pending";
  });

  builder.addCase(getAllReturnReasonListOrdersThunk.rejected, (state) => {
    state.fetchAjaxStatus = "rejected";
  });

  builder.addCase(
    getAllReturnReasonListOrdersThunk.fulfilled,
    (state, { payload }) => {
      state.fetchAjaxStatus = "fulfilled";
      if (payload?.reasons) {
        const reasons = payload.reasons ?? [];
        const returnReasons: AntiRefundStrategiesReason[] = Object.values(
          reasons,
        ).map((obj) => {
          return {
            id: obj.id,
            reasonName: obj.reasonName,
            scenarios: [
              {
                id: uuid(),
                items: [],
                tags: [],
                enabled: false,
                itemsAndTagAllSelected: false,
                followUpQuestions: [],
              },
            ],
          };
        });

        state.antiRefundOrderTypesData.refundRequestReasons = returnReasons;
      }
    },
  );
  builder.addCase(
    getRefundRequestFollowUpQuestionsOrdersThunk.pending,
    (state) => {
      state.followUpQuestionsAjaxStatus = "pending";
    },
  );

  builder.addCase(
    getRefundRequestFollowUpQuestionsOrdersThunk.rejected,
    (state) => {
      state.followUpQuestionsAjaxStatus = "rejected";
    },
  );

  builder.addCase(
    getRefundRequestFollowUpQuestionsOrdersThunk.fulfilled,
    (state, { payload }) => {
      state.followUpQuestionsAjaxStatus = "fulfilled";
      state.antiRefundOrderTypesData.refundRequestFollowUpQuestions = payload;
    },
  );
};

export default antiRefundStrategiesOrdersBuilders;
