import { axiosJSON } from "src/globals/axiosEndPoints";
import { step9to15ParamsPost } from "../step9/postStep9";

export async function fetchPostStep13(payload: step9to15ParamsPost) {
  const { data } = await axiosJSON.post(
    "/api/setting/automation/refund/step/13",
    payload
  );

  if (data.error === true) {
    throw new Error("Some Error Occurred while fetching data");
  }

  return data;
}
