import { axiosJSON } from "src/globals/axiosEndPoints";
import {
  conditionType,
  GetOptionsConditionsParam,
} from "../Step9/step9GetOptionsConditions";
import { IS_TEST_ACTIVE } from "src/globals/constants";

const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/step12GetConditionOption`
  : "/api/setting/automation/refund/step/12/conditionOptions";

export async function fetchStep12ConditionOptions(
  params: GetOptionsConditionsParam,
) {
  const { data } = await axiosJSON.get(apiEndPoint, { params });

  if (data.err === true) {
    throw new Error("Some Error Occurred while fetching data");
  }

  return {
    conditions: data.data?.conditions as conditionType[],
    itemConditions: data.data?.itemConditions as conditionType[],
    defaultConditions: data?.data.defaultConditions as conditionType[],
  };
}
