import { axiosJSON } from "src/globals/axiosEndPoints";
import APIResponse from "./APIReponse";

interface IRoutingRuleConditionList {
  location: Array<RoutingRuleCondition>;
  url: Array<RoutingRuleCondition>;
  domain: Array<RoutingRuleCondition>;
  [key: string]: Array<RoutingRuleCondition>;
}

interface RoutingRuleCondition {
  conditionId: string | number;
  conditionValue: string;
  conditionConstraint: string;
}

interface RoutingRule {
  ruleId: number | string;
  ruleName: string;
  ruleAction: string;
  teamId: number | string;
  teamName: string;
  conditions: IRoutingRuleConditionList;
  integrationId: number | string;
}

// interface that define a single routing rule
export interface IRoutingRuleData {
  ruleId: number | string;
  ruleName: string;
  ruleAction: string;
  teamId: number | string;
  teamName: string;
  integrationId: number | string;
  conditions: IRoutingRuleConditionList;
}

export interface GetAllRoutingRules {
  rules: { [ruleId: string | number]: RoutingRule };
  ruleIds: Array<number | string>;
  generalTeam: { teamId: number | string; teamName: string };
  metaData: {
    count: number;
    total: number;
  };
}

export interface GetAllRoutingRulesParams {
  integrationId: number | string;
  start: number;
  limit: number;
}

export async function getAllRoutingRules(params: GetAllRoutingRulesParams) {
  // const { data: res }: any = await axiosJSON.post(
  //   "/api/chatSetting/routingRule/getAll",
  //   params
  // );

  const res = {
    data: {
      generalTeam: [],
      rules: [
        {
          ruleId: 1,
          ruleName: null,
          ruleAction: "routeToGroup",
          teamId: 40,
          teamName: "test",
          integrationId: 1,
          conditions: {
            domain: [
              {
                conditionId: "1",
                conditionValue: "test",
                conditionConstraint: "isExactly",
              },
              {
                conditionId: "2",
                conditionValue: "testing.ml",
                conditionConstraint: "contains",
              },
            ],
            url: [
              {
                conditionId: "3",
                conditionValue: "testing.test",
                conditionConstraint: "contains",
              },
            ],
          },
        },
      ],
    } as any,
    error: false,
    message: "",
    totalCount: 1,
    count: 1,
  } ;

  if (res.error === true) {
    throw new Error(res.message as string);
  }

  const ret: GetAllRoutingRules = {
    rules: {},
    ruleIds: [],
    generalTeam: { teamId: 0, teamName: "" },
    metaData: {
      count: 0,
      total: 0,
    },
  };
  ret.metaData.count = res.count;
  ret.metaData.total = res.totalCount;
  ret.generalTeam.teamId = res.data.generalTeam.teamId;
  ret.generalTeam.teamName = res.data.generalTeam.teamName;
  res.data.rules.forEach((rule: any) => {
    ret.rules[rule.ruleId] = rule;
    ret.ruleIds.push(rule.ruleId);
  });
  console.log("getAllRoutingRules service ret:: ", JSON.stringify(ret));
  return ret;
}
