import { useCallback, useEffect, useRef, useState } from "react";
import { Overlay, OverlayTrigger, Tooltip } from "react-bootstrap";

import { useNavigate } from "react-router-dom";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { UpdateWebIntParams, updateWebIntegration } from "src/services/LiveChat/Settings/website/updateWebIntegration";
import { fetchallWebIntegrations } from "src/store/slices/liveChatSetting/websiteSettings/websiteSettings.slice";
import styles from "./GoToSetting.module.scss";
import { useAppDispatch } from "src/store/store";

type Props = {
  user: string;
  link: string | null;
  integrationId: number | string;
  installed: boolean;
  liveChatEnabled: boolean;
};
const GoToSetting = ({
  user,
  link,
  integrationId,
  installed,
  liveChatEnabled,
}: Props) => {
  // const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const target = useRef(null);

  const [enabled, setEnabled] = useState(liveChatEnabled);
  const [blockInput, setBlockInput] = useState(false);
  const [showOverlay, setShowOveray] = useState(false);

  const handleNavigate = () => {
    navigate("/live-chat/settings/" + integrationId + "/Website");
  };

  const onHandleEnable = (value: boolean) => {
    if (blockInput) {
      return;
    }

    const payload: UpdateWebIntParams = {
      integration_id: integrationId,
      live_chat_enabled: value,
    };

    setEnabled(value);
    setBlockInput(true);
    let enabledText = value
      ? "Enabled successfully!"
      : "Disabled successfully!";
    updateWebIntegration(payload)
      .then((res) => {
        pushTheToast({
          type: "success",
          text: enabledText,
          position: "top-right",
        });
        // dispatch(fetchallWebIntegrations());
        setBlockInput(false);
      })
      .catch((err) => {
        pushTheToast({
          type: "danger",
          text: "Something went wrong",
          position: "top-right",
        });

        setEnabled(!value);
        setBlockInput(false);
      });
  };

  return (
    <div className={`${styles.setBox}`}>
      <div
        className={`d-flex justify-content-between align-items-center flex-wrap ${styles.enableDiv}`}
      >
        <div className={`d-flex align-items-center flex-wrap mb-1 mb-lg-0`}>
          <div>
            <img src={user} alt="shopify" />
          </div>
          <div>
            <span className={`ms-md-2 ${styles.shopifyText}`}>
              {link ? link : "Not Integrated!"}
            </span>
          </div>
        </div>
        <div className="d-flex mb-2">
          <OverlayTrigger
            placement="top"
            show={showOverlay}
            overlay={
              installed === false ? (
                <Tooltip className={`override-grey`}>
                  You need to install widget to enable it
                </Tooltip>
              ) : (
                <></>
              )
            }
          >
            {({ ref, ...triggerHandler }) => (
              <div
                {...triggerHandler}
                ref={ref}
                onMouseOver={(e) => setShowOveray(true)}
                onMouseLeave={(e) => setShowOveray(false)}
              >
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={"mySwitch_"+integrationId}
                    name="darkmode"
                    value="yes"
                    checked={enabled}
                    disabled={!installed}
                    onChange={(e) => onHandleEnable(!enabled)}
                  />
                  <label
                    className={`form-check-label ${styles.enableChat}`}
                    htmlFor={"mySwitch_"+integrationId}
                  >
                    {enabled === true
                      ? "Live Chat Enabled"
                      : "Live Chat Disabled"}
                  </label>
                </div>
              </div>
            )}
          </OverlayTrigger>
        </div>
      </div>
      <div className={`${styles.goTo}`} onClick={handleNavigate}>
        <span className={`${styles.goToHead}`}>Go to settings</span>
        <span className={`${styles.goToArrow}`}>
          <i className="fa-solid fa-angle-right"></i>
        </span>
      </div>
    </div>
  );
};
export default GoToSetting;
