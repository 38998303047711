import React, { useState } from "react";
import {
  removeAllTickets,
  selectAllTickets,
  setBulkActionFilters,
} from "src/store/slices/tickets/tickets.slice";
import { getActiveListName } from "src/store/slices/ticketSidebar/ticketSidebar.selectors";
import { useAppDispatch, useAppSelector } from "src/store/store";
import styles from "./BulkSelectModel.module.scss";
interface Props {
  onHide: Function;
}
function BulkSelectModel({ onHide }: Props) {
  const activeListName = useAppSelector(getActiveListName);

  //getting total ticket count and currentView from redux state "ticket"
  const { totalTicketsCount, currentView } = useAppSelector(
    (state) => state.ticket
  );

  const dispatch = useAppDispatch();

  const [selectedAllTickets, setSelectedAllTickets] = useState<
    boolean | undefined
  >();

  const getSelectAllTicketText = () => {
    if (currentView == "response-view") {
      return `Select all ${totalTicketsCount} ${
        totalTicketsCount <= 1 ? "draft" : "drafts"
      } in "${activeListName}" view`;
    } else if (currentView == "customer-view") {
      return `Select all of the last tickets from Customers in "${activeListName}" view`;
    }

    return `Select all ${totalTicketsCount} tickets in "${activeListName}" view`;
  };

  const getTextForFirstTen = () => {
    if (currentView == "response-view") {
      return `Only the ${totalTicketsCount < 10 ? totalTicketsCount : "10"} ${
        totalTicketsCount <= 1 ? "draft" : "drafts"
      } currently visible`;
    } else if (currentView == "customer-view") {
      return `Only last tickets of ${
        totalTicketsCount < 10 ? totalTicketsCount : "10"
      } customers visible`;
    }

    return `Only the ${
      totalTicketsCount < 10 ? totalTicketsCount : "10"
    } tickets currently visible`;
  };

  /**
   * function will handle the next button click
   */
  const handleSubmit = () => {
    //checking if select all has been clicked
    if (selectedAllTickets) {
      //resetting the perviosly selected tickets
      dispatch(removeAllTickets());
      //updating ticket slice to set selectedAllTickets property to true
      dispatch(setBulkActionFilters({ selectedAllTickets: true }));
    } else {
      //calling the ticket reducer to select current page tickets
      dispatch(selectAllTickets());
    }
    //hiding the modal
    onHide();
  };

  return (
    <div>
      <div
        className="d-flex justify-content-between pb-0 p-3"
        id="bulkSelectModal__body"
      >
        <h5 className={`${styles.modelHeading} ms-3`}>
          Which option would you like to select?
        </h5>
        <span
          className={`mx-1 ${styles.commomClose}`}
          data-bs-dismiss="modal"
          aria-label="Close"
          id="bulkSelectModal__closeBtn"
        >
          <span className={`${styles.close}`} onClick={() => onHide()}>
            <i className="fa-solid fa-xmark"></i>
          </span>
        </span>
      </div>
      <div className="p-3">
        <div
          className={`ms-3 my-2 d-flex align-items-center justify-content-start p-2 ${styles.selectOne}`}
          id="bulkSelectModal__selectAll"
        >
          <input
            id="selectAllRadio-BA"
            type="radio"
            onChange={() => setSelectedAllTickets(true)}
            checked={selectedAllTickets === true}
            className="mx-1 cursor-pointer"
          />
          <label
            htmlFor="selectAllRadio-BA"
            className={`${styles.textSpan} cursor-pointer ms-2`}
          >
            {getSelectAllTicketText()}
          </label>
        </div>
        <div
          className={`ms-3 my-2  mt-3 d-flex align-items-center justify-content-start p-2 ${styles.selectOne}`}
          id="bulkSelectModal__selectSpecific"
        >
          <input
            type="radio"
            id="selectedRadio-BA"
            checked={selectedAllTickets === false}
            onChange={() => setSelectedAllTickets(false)}
            className="mx-1 cursor-pointer"
          />
          <label
            htmlFor="selectedRadio-BA"
            className={`${styles.textSpan} ms-2 cursor-pointer`}
          >
            {getTextForFirstTen()}
          </label>
        </div>
      </div>
      <div className={`d-flex align-items-end flex-column px-3 pb-3`}>
        <button
          className={`btn  m-2 ${styles.btn}`}
          onClick={() => handleSubmit()}
          disabled={selectedAllTickets === undefined}
          id="bulkSelectModal__nextBtn"
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default BulkSelectModel;
