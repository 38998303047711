import { Channel } from "pusher-js";

export type ImportProgressCB = (res: any) => void;

/**
 * Subscribes to the "chatbot_external_source_status_updated" event on the specified channel
 * to receive updates on the progress of importing an external source.
 *
 * @param {Channel} channel - The Pusher channel to subscribe to.
 * @param {ImportProgressCB} callback - The callback function to handle import progress updates.
 */
export const importSourceProgress = (channel: Channel, callback: ImportProgressCB) => {
  channel.bind("chatbot_external_source_status_updated", callback);
};

/**
 * Unsubscribes from the "chatbot_external_source_status_updated" event on the specified channel,
 * stopping the reception of updates on the progress of importing an external source.
 *
 * @param {Channel} channel - The Pusher channel to unsubscribe from.
 * @param {ImportProgressCB} callback - The callback function previously used for subscription.
 */
export const unBindImportSourceProgress = (channel: Channel, callback: ImportProgressCB) => {
  channel.unbind("chatbot_external_source_status_updated", callback);
};

