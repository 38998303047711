/**
 * This file defines a React component responsible for rendering searched articles view
 *
 * @author @yuvaraj-busibud
 * @author @navjyot-busibud
 * @author @Anubhav-busibud
 */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Modal } from "react-bootstrap";
import SearchBar from "src/components/SearchBar/SearchBar";
import styles from "../ExternalActiveSource.module.scss";
import { useInfiniteQuery, useMutation } from "@tanstack/react-query";
import {
  Articles,
  GetAllExternalParams,
  getAllExternal,
} from "src/services/Bot/AnswerSources/getAllExternal.service";
import Loader from "src/components/Loader";
import InfiniteScroll from "src/components/InfiniteScrollBothSide";
import TableHeader from "../../ImportModal/Children/ArticleComp/Children/ArticleData/TableHeader/TableHeader";
import SearchData from "../../ImportModal/Children/ArticleComp/Children/SearchData/SearchData";
import useSearchExternalArticles from "../useSearchExternalArticles";
import Pagination from "src/routes/TicketList/children/Pagination/Pagination";
import { selectSourceArticle } from "src/services/Bot/AnswerSources/selectSourceArticles.service";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { FreePlanError, FreePlanErrorMessage } from "src/globals/constants";
interface Props {
  sourceId: number;
  sourceName: string;
  searchTerm: string;
}
function SearchedArticles({ searchTerm, sourceId, sourceName }: Props) {
  const {
    articles,
    isLoading,
    status,
    fetchStatus,
    totalCount,
    updateArticleValue,
    currentPage,
    setCurrentPage,
    totalPages,
    unSelectAllArticle,
  } = useSearchExternalArticles({ sourceId: sourceId, search: searchTerm });

  //mutation for select article api call
  const selectArticleMutation = useMutation({
    mutationFn: selectSourceArticle,
    onSuccess: (data, variables, context) => {
      // console.log("Success: successfully updated");
    },
    onError: (error: any) => {
      // console.log("Error: failed to updated column");
      if (
        typeof error == "object" &&
        (error.statusCode === FreePlanError.LIMIT_REACHED ||
          error.statusCode === FreePlanError.RESTRICTED)
      ) {
        const statusCode =
          error.statusCode as keyof typeof FreePlanErrorMessage;
        // If the free plan limit is exceeded, show free plan error
        pushTheToast({
          text: error.message ?? FreePlanErrorMessage[statusCode],
          type: "danger",
          position: "top-right",
        });
      } else {
        pushTheToast({
          type: "danger",
          text: "Failed to select article!",
          position: "top-right",
        });
      }
    },
  });

  //function to initite select article ajax call
  const handleSelectArticle = useCallback(
    (articleId: number | string, isSelected: boolean, sourceId: number) => {
      selectArticleMutation.mutate({
        isSelected: isSelected,
        articleId: articleId,
        sourceId: sourceId,
      });
      updateArticleValue(parseInt(articleId + ""), { isSelected: isSelected });
    },
    [],
  );

  const searchInputRef = useRef();

  const errorMessage = useMemo(() => {
    if (
      status === "error" ||
      (status !== "success" && fetchStatus !== "fetching") ||
      (status !== "loading" && articles?.length === 0)
    ) {
      return (
        <span className={`ps-2 mt-5 ${styles.noResultFound}`}>
          No Data Found
        </span>
      );
    }
  }, [status, fetchStatus, articles?.length]);

  const isAllArticleSelected = false;
  const handleSelectAllArticle = useCallback((isSelected: boolean) => {
    if (articles) {
      articles.forEach((article: Articles) => {
        handleSelectArticle(article.articleId, isSelected, article.sourceId || sourceId);
      });
    }
  }, [articles, handleSelectArticle, sourceId]);

  return (
    <div className="mt-3">
      <p className={`mb-2 ${styles.searchText}`}>
        Search results ({articles?.length ?? 0}){" "}
        {sourceId !== 0 ? `for URL ${sourceName}` : ""}
      </p>
      <TableHeader
        isAllArticleSelected={isAllArticleSelected}
        setSelectAll={handleSelectAllArticle}
        hideSelectAll={true}
      />
      <div>
        {isLoading ? (
          <div>
            <Loader />
          </div>
        ) : (
          <div>
            <div>
              {!articles?.length ? (
                <div className={`text-center mt-5 ${styles.noResultFound}`}>
                  <span>No Article found</span>
                </div>
              ) : (
                articles?.map((article: Articles) => {
                  if (!article) {
                    return null;
                  }
                  return (
                    <SearchData
                      key={article.articleId}
                      article={article}
                      sourceId={sourceId}
                      handleSelectArticle={(
                        articleId: number | string,
                        isSelected: boolean,
                      ) => {
                        handleSelectArticle(
                          articleId,
                          isSelected,
                          article.sourceId || sourceId,
                        );
                      }}
                      isAllArticleSelected={isAllArticleSelected}
                      searchTerm={searchTerm}
                    />
                  );
                })
              )}
            </div>
            <div
              className="pt-2"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {totalCount && totalCount > 1 ? (
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  setCurrentPage={(selectedPage) => {
                    setCurrentPage(selectedPage);
                  }}
                />
              ) : null}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SearchedArticles;
