import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import {
  FollowUpQuestions,
  RefundScenario,
} from "../../../../../store/slices/AntiRefundStrategiesSlice/antiRefundStrategies.slice";
import { FormElementTypes } from "../../AddStrategy/AddStrategy";
import styles from "../Scenario.module.scss";

const ScenarioExtraDetailQuestion = ({
  followUpQuestion,
}: {
  followUpQuestion: FollowUpQuestions;
}) => {
  return (
    <div className="mb-2">
      <p className={`mb-1 ${styles.question}`}>
        Q. {followUpQuestion.elementValue}
      </p>

      {followUpQuestion.elementTypeId === FormElementTypes.TEXT ||
      followUpQuestion.elementTypeId === FormElementTypes.LONG_TEXT ? (
        <div className="w-100">
          <span className={`d-block ${styles.option} text-truncate`}>
            A. {followUpQuestion.selectedValue}
          </span>
        </div>
      ) : (
        <div className="ms-3">
          <ol type="A">
            {followUpQuestion.additionalOptions?.map((option) => (
              <li
                className={`d-block ${styles.option}`}
                key={option.id}
              >
                {option.value}
              </li>
            ))}
          </ol>
        </div>
      )}
    </div>
  );
};

interface Props {
  scenario: RefundScenario;
  showSpecificEleModalBtn: () => void;
}

const ScenarioFollowUpQuestion = ({
  scenario,
  showSpecificEleModalBtn,
}: Props) => {
  const [isQuestionsMenuOpen, setQuestionsMenuOpen] = useState(false);

  return (
    <div className={`d-flex align-items-center mb-1 ${styles.itemWrapper}`}>
      <span className={`${styles.items}`}>Follow-up Questions:</span>

      <div className={`px-2 ${styles.itemBox}`}>
        {/* <img src={order1} alt="" className={`${styles.orderImg}`} /> */}
        <span className={`${styles.itemText1}`}>
          {"Q. "}
          {scenario.followUpQuestions[0].elementValue}
        </span>
      </div>
      {scenario.followUpQuestions.length > 1 && (
        <div className={`px-2 ${styles.itemBox}`}>
          {/* <img src={order2} alt="" className={`${styles.orderImg}`} /> */}
          {" , "}
          <span className={`${styles.itemText1}`}>
            {"Q. "}
            {scenario.followUpQuestions[1].elementValue}
          </span>
        </div>
      )}

      {/* See All Details */}
      <div className="ps-2 d-flex align-items-center">
        <Dropdown onToggle={(opened) => setQuestionsMenuOpen(opened)}>
          <Dropdown.Toggle
            as="span"
            id="dropdown-basic"
            bsPrefix={`${styles.editToggle}`}
          >
            <span
              className={`${styles.seeAll} ${
                isQuestionsMenuOpen ? styles.opened : ""
              }`}
            >
              see all details
            </span>
          </Dropdown.Toggle>

          <Dropdown.Menu bsPrefix={`dropdown-menu ${styles.editMenu}`}>
            <p className={`${styles.dropHead}`}>All questions and answers:</p>
            {scenario.followUpQuestions.slice(0, 3).map((question) => (
              <ScenarioExtraDetailQuestion followUpQuestion={question} />
            ))}
            <span
              className={`${styles.seeDet}`}
              onClick={showSpecificEleModalBtn}
              role="button"
            >
              See all details
            </span>
          </Dropdown.Menu>
        </Dropdown>
        <span
          className={`ps-2 ${styles.edit}`}
          role="button"
          onClick={showSpecificEleModalBtn}
        >
          Edit
        </span>
      </div>
    </div>
  );
};

export default ScenarioFollowUpQuestion;
