import { axiosJSON } from "src/globals/axiosEndPoints";

export interface IAddCategory {
  name: string;
}

export async function addCategory(apiPayload: IAddCategory) {
  const { data } = await axiosJSON.post(
    "/api/template/createCategory",
    apiPayload
  );

  // console.log(data);

  if (data.err === true || data.error === true) {
    if(data.status_code === "category_exists"){
      throw new Error("Category with given name already exists")
    }else{
      throw new Error(data.msg ?? "Failed to create category");
    }
  }

  return data;
}
