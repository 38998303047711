import { axiosJSON } from "src/globals/axiosEndPoints";

export interface IUpdateHelpCenterPageDataParams{
  integrationId:number|string;
  helpdeskUrl?: string;
  companyName?: string;
  headlineText?: string;
  logoAttachmentId? : string | number| null;
  backgroundAttachmentId?: string | number| null;
  faviconAttachmentId? : string | number| null;
  themeColor?: string;
  googleAnalyticsTrackingId?: string;
  searchEngineIndexing?: boolean;
  isPublished? : boolean;
}

export async function updateHelpCenterPageData(params: IUpdateHelpCenterPageDataParams) {
  const { data } = await axiosJSON.post(
    `/api/chatSetting/updateHelpCenterPageData`,
    params
  );
  if (data.error === true) {
    throw new Error(data.message as string);
  }
  return data.data;
}
