import { useQuery } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { AJAXSTATUS } from "src/globals/constants";
import {
  ConditionOptionsResponse,
  getConditionOptionsService,
} from "src/services/Automation/AutomationTicketRouting/getConditionOptions.service";
import { useAutomationContext } from "../../Hooks/useAutomationContext";
import { ICheckListOption } from "src/services/Automation/getAutomationById.service";
import { EAutomationType } from "../../../AutomationList/AutomationList";

/**
 * Custom hook to fetch condition options
 *
 * This hook utilizes the `useQuery` hook from `@tanstack/react-query` to fetch
 * condition options from the `getConditionOptionsService`.
 * The query result is cached for an hour (`cacheTime` and `staleTime`
 * set to 3600000 ms), ensuring that the data remains fresh for the specified duration.
 *
 * @param type - The type of the automation.
 * @returns An object containing the fetched condition options and the status of the request.
 * conditionOptions - The fetched condition options, or null if the request failed.
 * conditionOptionsStatus - The status of the request, which can be one of "fulfilled", "pending", or "rejected".
 *
 * @example
 * const { conditionOptions, conditionOptionsStatus } = useFetchConditionOptions();
 *
 * @see {@link getConditionOptionsService} for the service used to fetch condition options.
 */
function useFetchConditionOptions(type: string, integrationId: string) {
  const { formData } = useAutomationContext();

  // Get allowed entities based on a specific question key
  const allowedEntitiesQuestion = Object.values(formData.questionsData).find(
    (question) => question.questionKey === "entities",
  );

  // Memoize the allowed entities to avoid unnecessary re-calculations
  const allowedEntities = useMemo(() => {
    return (allowedEntitiesQuestion?.listOfOptions as ICheckListOption[])
      ?.filter((option: ICheckListOption) =>
        (allowedEntitiesQuestion?.questionValue as string[]).includes(
          option.optionKey,
        ),
      )
      .map((option) => parseInt(option.optionKey, 10));
  }, [formData.questionsData, allowedEntitiesQuestion?.questionValue]);

  // Define the field types mapping
  const CONDITIONS_FIELD_TYPES: Record<string, number> = {
    order: 8,
    tickets: 9,
    contacts: 10,
    company: 11,
  };

  // Fetch the condition options
  const { data: conditionOptions, status } = useQuery(
    ["useFetchConditionOptions", type, integrationId],
    {
      queryFn: async () => {
        return await getConditionOptionsService({
          type: EAutomationType.TICKET_ROUTING,
          integrationId: integrationId ?? "0",
        });
      },
      cacheTime: 3600000,
      staleTime: 3600000,
      networkMode: "always",
    },
  );

  // Filter the condition options based on allowed entities
  const filteredConditionOptions = conditionOptions?.conditionOptions?.filter(
    (option) => {
      const key = option.fieldKey;
      const conditionFieldType = CONDITIONS_FIELD_TYPES[key];
      return allowedEntities.includes(conditionFieldType);
    },
  );

  // Use a callback to find an option by its field key
  const findOptionByFieldKey = useCallback(
    (fieldKey: string) => {
      if (filteredConditionOptions)
        return (
          filteredConditionOptions?.find((v) => v.fieldKey === fieldKey) || null
        );
      return null;
    },
    [filteredConditionOptions],
  );

  return {
    conditionOptions: {
      conditionOptions: filteredConditionOptions,
    } as ConditionOptionsResponse | null,
    conditionOptionsStatus: status as AJAXSTATUS,
    findOptionByFieldKey,
  };
}

export default useFetchConditionOptions;
