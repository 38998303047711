import { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "src/store/store";
import { fetchGetEmailIntegrationById } from "src/store/slices/emailIntegration/emailIntegration.thunks";

import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import Loader from "src/components/Loader";
import backBtn from "src/assets/images/backArrow.png";
import EmailProcess from "../EmailProcess/EmailProcess";
import SetupComplete from "../SetupComplete/SetupComplete";

import styles from "./Complete.module.scss";

/**
 * Wrapper Component for Complete Step in Email Integration
 */
function Complete() {
  const [search, setSearch] = useSearchParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { integrationId } = useParams();

  /**
   * Effect for showing success state
   */
  useEffect(() => {
    if (search.get("success") === "true") {
      pushTheToast({
        type: "success",
        text: "E-mail Setup Complete !",
        position: "top-right",
      });
      search.delete("success");
      setSearch(search);
    }
  }, []);

  /**
   * Effect for redirecting user and updating user data
   */
  useEffect(() => {
    dispatch(
      fetchGetEmailIntegrationById({
        emailIntegrationId: Number(integrationId),
        callback: (status) => {
          // Callback for redirecting user
          if (status === "email_not_verified") {
            navigate(
              `/settings/integrations/email/${integrationId}/verifyEmail`,
            );
          } else if (status === "domain_not_verified") {
            navigate(
              `/settings/integrations/email/${integrationId}/verifyDomain`,
            );
          } else if (status === undefined) {
            navigate(`/settings/integrations/email/connectEmail`);
          }
        },
      }),
    );
  }, [integrationId]);

  const loader = useAppSelector((state) => {
    return state.emailIntegration.emailLoading;
  });

  return (
    <div
      className={`d-flex-column justify-content-center ${styles.contentWidth}`}
    >
      {loader === "pending" ? (
        <Loader />
      ) : (
        <>
          <div className="d-flex">
            <div
              className={`${styles.backWidth} mt-3 ps-1 `}
              role="button"
              onClick={() => {
                navigate("/settings/integrations/email");
              }}
            >
              <img
                src={backBtn}
                className={`${styles.backBtn}`}
                alt="back"
              />
            </div>
            <div className={`${styles.connectWidth}`}>
              <EmailProcess
                step={4}
                delete={false}
              />
            </div>
          </div>
          <div
            className={`${styles.connectWidth}`}
            style={{ marginLeft: "30px" }}
          >
            <SetupComplete />
          </div>
        </>
      )}
    </div>
  );
}

export default Complete;
