import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import AddTagsButton from "src/components/AddTagsButton";
import { AssignToDropDown, User } from "src/components/AssignToDropDown";
import SearchBar from "src/components/SearchBar/SearchBar";
import TicketCurrentStatus from "src/components/TicketCurrentStatus";
import UserAvatar from "src/components/UserAvatar";
import { searchCustomers } from "src/services/Customer/searchCustomers";
import {
  fetchMessagesUpdates,
  filterInnerTicketsByActiveFilter,
  resetSearchAllCustomers,
  searchAllCustomers,
  setInnerTicketActiveTags,
  setInnerTicketStatus,
  setInnerTicketUserID,
  setSearchAllCustomersSearchText,
} from "src/store/slices/innerTicket/innerTicket.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import styles from "./TicketHeader.module.scss";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import AxiosImg from "src/components/AxiosImg";
import TicketCurrentStatusV2 from "src/components/TicketCurrentStatusV2";
import { fetchAllStatuses } from "src/store/slices/ticketStatus/ticketStatus.slice";
import saufterImg from "src/assets/images/saufter.gif";
import TimelineInfo, {
  LastUpdated,
} from "./Children/TimelineInfo/TimelineInfo";
import TicketCurrentStatusDropDown from "src/components/TicketCurrentStatusV2/TicketCurrentStatusDropDown";
const AssignToUser = ({
  assignTo,
  ticketId,
  onSelect,
  canChange,
}: {
  assignTo: any;
  ticketId: string;
  onSelect: (assignTo: any) => void;
  canChange: boolean;
}) => {
  const [user, setUser]: [User, Function] = useState({
    id: 0,
    name: "NA",
    email: "NA",
  });
  const assignBtnRef: any = useRef(null);
  const [show, setShow] = useState(false);

  const onClickWindow = useCallback((e: any) => {
    if (assignBtnRef.current) {
      if (!assignBtnRef.current.contains(e.target)) {
        setShow(false);
      }
    }
  }, []);
  useEffect(() => {
    setUser({
      id: assignTo.id,
      name: assignTo.name,
      email: "NA",
      imageUrl: assignTo?.imgURL,
    });
  }, [assignTo]);
  useEffect(() => {
    window.addEventListener("click", onClickWindow);
    return () => {
      window.removeEventListener("click", onClickWindow);
    };
  }, [onClickWindow]);

  return (
    <div ref={assignBtnRef}>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip className={` ${styles.toolTipCustom}`}>
            <span>Assigned to : {user.name}</span>
          </Tooltip>
        }
      >
        {({ ref, ...triggerHandler }) => (
          <div
            {...triggerHandler}
            ref={ref}
          >
            <button
              className={`px-2 ms-3 ${styles.ProfileMain} ${
                canChange ? "" : "cursor-disabled"
              } d-flex align-items-center`}
              type="button"
              onClick={(e) => {
                if (canChange) {
                  setShow(!show);
                }
              }}
            >
              {user?.imageUrl && user?.imageUrl.trim().length !== 0 ? (
                <AxiosImg
                  className={`${styles.profileImg} m-auto`}
                  url={user.imageUrl}
                  isDirectURL={user?.isPublicAttachmentUrl}
                />
              ) : (
                <UserAvatar
                  name={user.name}
                  size={100}
                  className={`${styles.profileImg} m-auto`}
                />
              )}
              <span className={`my-auto mx-2`}>{user.name}</span>
              <span className={`${styles.arrowDrop}`}>
                <i
                  className={`fa-solid d-block m-auto fa-caret-${
                    show ? "up" : "down"
                  }`}
                ></i>
              </span>
            </button>
          </div>
        )}
      </OverlayTrigger>

      {canChange && (
        <AssignToDropDown
          selectedUser={assignTo.id}
          show={show}
          onSelect={(user) => {
            setUser(user);
            setShow(false);
            onSelect({ id: user.id, name: user.name, imgURL: user.imageUrl });
          }}
          ticketId={ticketId.split(",").map((id) => parseInt(id))}
        />
      )}
    </div>
  );
};

const TicketHeader = () => {
  const { tableType, ticketId, viewType }: any = useParams();
  // const [customerSearchText, setCustomerSearchText] = useState<string>("");
  const scrollDivRef = useRef(null as any);
  const currentUserData = useAppSelector(
    (state) => state.globals.currentUserData,
  );
  const activeTicketInfo: any = useAppSelector(
    (state) => state.innerTicket.activeTicketInfo,
  );
  // const ticketListTotal = useAppSelector(
  //   (state) => state.innerTicket.ticketListTotal,
  // );
  const dispatch = useAppDispatch();
  const { allTicketStatuses, allTicketStatusesIds, fetchAllStatusesState } =
    useAppSelector((state) => state.ticketStatus);

  const handleScrollLeft = useCallback(() => {
    if (scrollDivRef.current) {
      const scrollAmount = scrollDivRef.current.clientWidth / 3;
      scrollDivRef.current.scrollLeft -= scrollAmount; // Scroll left
    }
  }, []);
  const handleScrollRight = useCallback(() => {
    if (scrollDivRef.current) {
      const scrollAmount = scrollDivRef.current.clientWidth / 3;
      scrollDivRef.current.scrollLeft += scrollAmount; // Scroll right
    }
  }, []);

  // handle customer search
  /*const handleCustomerSearch = (searchTerm: any) => {
    dispatch(searchAllCustomers());
  };*/

  useEffect(() => {
    if (
      fetchAllStatusesState !== "pending" &&
      allTicketStatusesIds.length === 0
    ) {
      dispatch(fetchAllStatuses());
    }
  }, [fetchAllStatusesState, allTicketStatusesIds]);

  return (
    <div>
      <div
        className={`d-flex align-items-center mb-2 ${styles.ticketHeaderMain}`}
      >
        <div
          className={`mx-2 ${styles.iconBox} cursor-pointer`}
          onClick={handleScrollLeft}
        >
          <i className={`fa-solid fa-chevron-left`}></i>
        </div>
        <div
          ref={scrollDivRef}
          className={`${styles.headerContent} scrollbar-hide d-flex`}
        >
          <div
            className={`px-2 d-flex justify-content-start align-items-center`}
          >
            <div>
              {activeTicketInfo.ticket_status && (
                <TicketCurrentStatusDropDown
                  ticketId={ticketId}
                  currentStatus={{
                    id: activeTicketInfo.ticket_status?.ticketStatusId,
                    statusName: activeTicketInfo.ticket_status.ticketStatusName,
                  }}
                  onChange={(status) => {
                    dispatch(setInnerTicketStatus({ status, ticketId }));
                    dispatch(fetchMessagesUpdates());
                    dispatch(filterInnerTicketsByActiveFilter());
                  }}
                  canChange={
                    currentUserData &&
                    currentUserData.scopes &&
                    currentUserData.scopes.includes("change_status")
                      ? true
                      : false
                  }
                />
              )}
            </div>
            {/* <div>
            <h5 className={`${styles.linkConv}`}>Linked Conversations </h5>
          </div> */}
            {/* <span className={` ${styles.activeConv}`}>
            <span>{ticketListTotal}</span>
          </span> */}
            {/* AI Handled Tag */}

            {/* {activeTicketInfo.is_ai_handled && (
            <div className="d-flex align-items-center me-3">
              <span className={`${styles.saufterText}`}>AI handled</span>
              <img
                src={saufterImg}
                alt=""
                className={`${styles.saufterImg}`}
              />
            </div>
          )} */}

            <div>
              {activeTicketInfo.ticket_assigned_to && (
                <AssignToUser
                  assignTo={activeTicketInfo.ticket_assigned_to}
                  ticketId={ticketId + ""}
                  onSelect={(assignedTo) => {
                    dispatch(setInnerTicketUserID({ assignedTo, ticketId }));
                    dispatch(fetchMessagesUpdates());
                    dispatch(filterInnerTicketsByActiveFilter());
                  }}
                  canChange={
                    currentUserData &&
                    currentUserData.scopes &&
                    currentUserData.scopes.includes("reassign_ticket")
                      ? true
                      : false
                  }
                />
              )}
            </div>
            {activeTicketInfo.ticket_tags !== undefined && (
              <AddTagsButton
                ticketIds={ticketId}
                activeTags={
                  activeTicketInfo.ticket_tags
                    ? activeTicketInfo.ticket_tags
                    : []
                }
                setActiveTags={(activeTags) => {
                  dispatch(
                    setInnerTicketActiveTags({ tags: activeTags, ticketId }),
                  );
                  dispatch(fetchMessagesUpdates());
                }}
                canChange={
                  currentUserData &&
                  currentUserData.scopes &&
                  currentUserData.scopes.includes("add_tags")
                    ? true
                    : false
                }
              />
            )}
          </div>

          <TimelineInfo />
        </div>
        <div
          className={`mx-2 ${styles.iconBox} cursor-pointer`}
          onClick={handleScrollRight}
        >
          <i className={`fa-solid fa-chevron-right`}></i>
        </div>

        {/* <SearchBar
        className={`${styles.search} px-2`}
        inputClassName={`${styles.input}`}
        placeholder={`Search Customer`}
        value={customerSearchText}
        onChange={(e: any) => {
          setCustomerSearchText(e.target.value);
          }}
          onSearch={(value: any) => {
            dispatch(resetSearchAllCustomers());
            dispatch(setSearchAllCustomersSearchText({ searchText: value }));
            // handleCustomerSearch(value);
            // // console.log(value);
            }}
            /> */}
      </div>
      <LastUpdated />
    </div>
  );
};
export default TicketHeader;
