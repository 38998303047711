import styles from "./ResponseRemark.module.scss";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import ResponseRemarkModal from "src/components/TableComponents/ReponseModals/ResponseRemark/ResponseRemarkModal";
import { getChannelFAIcon } from "src/utils/utils";
import { useAppSelector } from "src/store/store";
import { selectTicketCellData } from "src/store/slices/tickets/tickets.slice";
import { TableCellProps } from "..";

function ResponseRemark({ ticketId, colKeys }: TableCellProps) {
  const [showModal, setShowModal] = useState(false);
  const ticketCellData: any = useAppSelector((state) =>
    selectTicketCellData(state, ticketId, colKeys)
  );

  const onShow = (e: any) => {
    e.stopPropagation();
    setShowModal(true);
    // console.log("show");
  };
  const onHide = () => {
    setShowModal(false);
    // console.log("hide");
  };
  return (
    <div  onClick={(e: any)=> e.stopPropagation()}>
      <div className={`${styles.PreDraftMain}`} onClick={onShow}>
        <div className={`${styles.preDraftBox}`}>
          <p className={`${styles.resAllDraft}`}>
            <span className="me-1">
              <i className="fas fa-user"></i>
            </span>
            <span> sk , 24/1/2022, 11.24pm</span>
          </p>

          <span className={`${styles.messageHead}`}>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Enim in
            dicta quae omnis unde ducimus deserunt pariatur provident vitae
            laboriosam, iure nulla tenetur tempore! Vero praesentium sunt
            aliquid qui necessitatibus.
          </span>
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={onHide}
        dialogClassName={`${styles.modalDialog}`}
        contentClassName={`${styles.modalContent}`}
        centered={true}
      >
        <ResponseRemarkModal onHide={onHide} />
      </Modal>
    </div>
  );
}

export default ResponseRemark;
