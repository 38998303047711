/**
 * This file contains the custom hook for handling KB settings.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { useParams } from "react-router-dom";
import useComments from "../../useComments";
import { useCallback, useEffect, useState } from "react";
import useRating from "../../../Rating/useRating";

type Props = {
  enableComments: boolean;
  setEnableComments: (value: boolean) => void;
  reqApproval: boolean;
  setReqApproval: (value: boolean) => void;
  approvedComment: boolean;
  unApprovedComment: boolean;
  setApprovedComment: Function;
  setUnapprovedComment: Function;
};

/**
 * Custom hook for managing the CommentSetting component in KB settings.
 */
export function useCommentSetting({
  setReqApproval,
  reqApproval,
  setEnableComments,
  enableComments,
  approvedComment,
  unApprovedComment,
  setApprovedComment,
  setUnapprovedComment,
}: Props) {
  // States And Variables
  const { integrationId } = useParams();
  const pageIntegrationId = integrationId
    ? decodeURIComponent(integrationId)
    : "";

  // Getting Data From useComments Hook
  const { data } = useComments();

  const [dropdown, setDropDown] = useState(true);
  const [selectedRadio, setSelectedRadio] = useState(
    data?.commentsType === "unApproved" ? "exampleRadios1" : "exampleRadios2"
  );

  // Getting Data From useRating Hook
  const { updateRatingAndComments } = useRating();

  useEffect(() => {
    // If Comments Are Disabled Then Disable Require Approval
    if (!enableComments) {
      setReqApproval(false);
    }

    // If Require Approval Is Disabled Then Select Unapproved Comments
    if (!reqApproval) {
      setSelectedRadio("exampleRadios1");
    }

    if (approvedComment) {
      setSelectedRadio("exampleRadios2");
    }

    if (unApprovedComment) {
      setSelectedRadio("exampleRadios1");
    }
  }, [enableComments, reqApproval, setReqApproval, selectedRadio]);

  // Handle Comment Change Function - Enable/Disable Comments
  const handleCommentChange = useCallback(
    (e: { target: { checked: boolean } }) => {
      setEnableComments(e.target.checked);
      // Api Call To Update Comments
      updateRatingAndComments.mutate({
        integrationId: pageIntegrationId,
        commentsEnabled: e.target.checked,
        commentApprovalRequired: e.target.checked === false ? false : undefined,
      });
    },
    [pageIntegrationId, updateRatingAndComments]
  );

  // Handle Radio Change Function - Select Approved/Unapproved Comments
  const handleRadioChange1 = useCallback(() => {
    setSelectedRadio("exampleRadios1");
    updateRatingAndComments.mutate({
      integrationId: pageIntegrationId,
      commentsType: "unApproved",
    });
    setUnapprovedComment(true);
    setApprovedComment(false);
  }, [pageIntegrationId, updateRatingAndComments]);

  // Handle Radio Change Function - Select Approved/Unapproved Comments
  const handleRadioChange2 = useCallback(() => {
    setSelectedRadio("exampleRadios2");
    updateRatingAndComments.mutate({
      integrationId: pageIntegrationId,
      commentsType: "approved",
    });
    setApprovedComment(true);
    setUnapprovedComment(false);
  }, [pageIntegrationId, updateRatingAndComments]);

  // Handle Require Approval Change Function - Enable/Disable Require Approval

  const handleRequireApprovalChange = useCallback(
    (e: any) => {
      setReqApproval(e.target.checked);
      updateRatingAndComments.mutate({
        integrationId: pageIntegrationId,
        commentApprovalRequired: e.target.checked,
      });
    },
    [pageIntegrationId, updateRatingAndComments]
  );

  return {
    dropdown,
    setDropDown,
    selectedRadio,
    handleRadioChange1,
    handleRadioChange2,
    handleCommentChange,
    handleRequireApprovalChange,
    updateRatingAndComments,
  };
}
