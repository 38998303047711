import { axiosJSON } from "src/globals/axiosEndPoints";

export interface IUpdateTicketParams {
  id: number;
  subject: string;
}

export async function updateTicket(params: IUpdateTicketParams) {
  const { data: res } = await axiosJSON.post(
    `/api/ticket/updateTicket`,
    params
  );

  if (res.err === true) {
    throw new Error(res.message as string);
  }

  return res;
}
