export enum EChannel {
  facebookMessenger = "messenger",
  instagram = "instagram",
  email = "email",
  twitter = "twitter",
  note = "note",
  whatsapp = "whatsapp", //Adding whatsapp channel
  sms = "sms",
  slack = "slack",
}
