import { TicketStatus } from "src/services/TicketService/getAllStatuses";
import { setSelectedStatusId } from "src/store/slices/ticketStatus/ticketStatus.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import styles from "./TicketStatusList.module.scss";

function TicketStatusList() {
  const { selectedStatusId, allTicketStatuses, allTicketStatusesIds } =
    useAppSelector((state) => state.ticketStatus);

  return (
    <ul
      role="button"
      className={`px-0 mx-0 user-select-none ${styles.changeStatusUL}`}
    >
      {allTicketStatusesIds.map((statusId) => (
        <TicketStatusListItem
          key={statusId}
          status={allTicketStatuses[statusId]}
          selected={selectedStatusId === statusId}
        />
      ))}
    </ul>
  );
}

function TicketStatusListItem(props: any) {
  const status: TicketStatus = props.status;
  const dispatch = useAppDispatch();

  const handleOnClick = (e: any) => {
    e.preventDefault();
    // status id is set here
    dispatch(setSelectedStatusId(status.id));
  };

  return (
    <>
      <li
        className={`d-flex justify-content-start ${styles.mainLi}`}
        onClick={handleOnClick}
        id={`status_${status.statusName.toLowerCase()}`}
      >
        <div
          className={`${
            props.selected ? styles.selectedStatusBar : styles.bar
          }`}
        ></div>
        <div
          className={`${
            props.selected ? styles.selectedStatusBox : styles.changeStatusbox
          }`}
        >
          <span className={`${styles.darkColor}`}>
            {status.statusName}
            {/* <span>(12)</span> */}
          </span>
        </div>
      </li>
    </>
  );
}

export default TicketStatusList;
