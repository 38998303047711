import { useCallback, useState } from "react";
import UserAvatar from "src/components/UserAvatar";
import styles from "./TicketCreated.module.scss";
import insta from "src/assets/images/insta-new.png";
import copy1 from "src/assets/images/copy1.png";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { MessagesData } from "src/services/LiveChat/liveChatHistory/getLiveChatCustomerAllMessages.service";
import AxiosImg from "src/components/AxiosImg";
import { getChannelFAIcon } from "src/utils/utils";
import useUTCToUserTime from "src/hooks/dateHooks/useUTCToUserTime";

const CopyTicket = ({
  ticketId,
  rightAlign,
}: {
  ticketId: string;
  rightAlign: boolean;
}) => {
  const [show, setShow] = useState(false);
  const [textCopied, setTextCopied] = useState(false);

  const copyButton = useCallback(() => {
    window.navigator.clipboard.writeText(ticketId + "");
    setTextCopied(true);
  }, [ticketId, setTextCopied]);

  const hideTooltip = useCallback(() => {
    setShow(false);
    setTimeout(() => {
      setTextCopied(false);
    }, 500);
  }, [setShow, setTextCopied]);

  const showTooltip = useCallback(() => {
    setShow(true);
  }, [setShow]);

  return (
    <OverlayTrigger
      placement="top"
      show={show}
      overlay={
        <Tooltip
          className={`${styles.toolTipCustom} ${
            rightAlign ? styles.right : ``
          }`}
        >
          {textCopied ? "Copied" : "Copy Ticket Id"}
        </Tooltip>
      }
    >
      {({ ref, ...triggerHandler }) => (
        <div
          {...triggerHandler}
          ref={ref}
          onMouseEnter={showTooltip}
          onMouseLeave={hideTooltip}
        >
          <span
            className={`${rightAlign ? `me-2` : `ms-2`} ${styles.copyBox}`}
            onClick={copyButton}
          >
            <img src={copy1} alt="" className={`${styles.copyImg}`} />
          </span>
        </div>
      )}
    </OverlayTrigger>
  );
};

interface Props extends MessagesData {
  scrollToRef?: React.MutableRefObject<any>;
}

const TicketCreated = (messageData: Props) => {
  const rightAlign = messageData.ticketCreatedBy === "agent";
  const createdDate = useUTCToUserTime({
    timeInUTC: messageData.createdDateUTC,
    dateOnly: true,
  });

  return (
    <div ref={messageData.scrollToRef} className={`pt-3`}>
      <div
        className={`d-flex justify-content-start ${
          rightAlign ? `pe-4 flex-row-reverse` : `ps-4`
        }`}
      >
        <div className={`d-flex flex-column align-items-center`}>
          <div className="position-relative">
            {messageData.senderImgURL ? (
              <AxiosImg
                url={messageData.senderImgURL}
                className={`${styles.profileIcon} rounded-circle`}
                isDirectURL={messageData.isPublicAttachmentUrl}
              />
            ) : (
              <UserAvatar
                className={`${styles.profileIcon}`}
                name={messageData.senderName}
                size={35}
              />
            )}
            <div className={`${styles.greenDot}`}></div>
          </div>
          <span className={`mt-1 ${styles.iconWrapper}`}>
            {/* <img src={insta} alt="socialIcon" className={`${styles.instaIcon}`} /> */}
            <i
              className={`${getChannelFAIcon(
                messageData.channel.name
              )} override`}
            ></i>
          </span>
        </div>
        <div className={`${rightAlign ? `me-2` : `ms-2`}`}>
          <div className={`d-flex ${rightAlign ? `flex-row-reverse` : ``}`}>
            <div>
              <a
                target={"_blank"}
                href={`/tickets/all/ticket-view/ticket/${messageData.ticketId}/`}
                className={`${styles.ticketId} p-2`}
              >
                Ticket ID: {messageData.ticketId}
              </a>
            </div>
            <CopyTicket
              ticketId={messageData.ticketId + ""}
              rightAlign={rightAlign}
            />
          </div>
          <div>
            <span className={`${styles.ticketCreated}`}>
              Created on {createdDate}
            </span>
            <p className={`d-flex`}>
              <span className={`${styles.ticketStatus}`}> Ticket status</span>
              <div className={`ms-2 d-flex align-items-center`}>
                <div
                  className={`me-1 ${
                    styles[`${messageData.ticketStatus.name}Dot`]
                  }`}
                ></div>
                <span
                  className={`${
                    styles[`${messageData.ticketStatus.name}Status`]
                  }`}
                >
                  {messageData.ticketStatus.name}
                </span>
              </div>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TicketCreated;
