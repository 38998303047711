import { useCallback, useState } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import styles from "./ViewTemplateButtonV2.module.scss";
import ViewTemplateV2 from "../ViewTemplateV2/ViewTemplateV2";
import templateIcon from "src/assets/images/templateIcon.png";
import templateActiveIcon from "src/assets/images/templateActiveIcon.png";

function ViewTemplateButtonV2({
  selectedTemplate = (template: string) => {},
  showingWhichModal = "",
  setShowingWhichModal = (whichModal: string) => {},
}) {
  const [showModal, setShowModal] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  // handler to set isHovered true
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  // handler to set isHovered false
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const onShow = useCallback(() => {
    if (setShowingWhichModal !== undefined) {
      setShowingWhichModal("template"); // currently showing template modal
    }
    setShowModal(true);
  }, []);
  const onHide = useCallback(() => {
    setShowingWhichModal("");
    setShowModal(false);
  }, []);

  return (
    <>
      <div className={`my-auto d-flex`}>
        <div
          className={`my-auto d-flex ${styles.button}`}
          onClick={onShow}
          id="viewTemplateBtn"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {/* <i className={`my-auto material-icons`}>&#xe06f;</i> */}
          <span className={`my-auto mx-2`}>
            +{" "}
            {/* <span>
                <i className="fa-solid fa-box"></i>
              </span>{" "} */}
            <img
              src={`${isHovered ? templateActiveIcon : templateIcon}`}
              alt=""
              width={10}
              height={10}
              className="mx-1"
            />
            Template
          </span>
        </div>
      </div>
      {/* <Modal
        backdropClassName={`${styles.modalBack}`}
        show={showModal}
        onHide={onHide}
        dialogClassName={`${styles.modalDialog}`}
        contentClassName={`${styles.modalContent}`}
        centered={true}
        enforceFocus={false}
      >
        <div className={`h-0 w-100 d-flex justify-content-end`}>
          <CloseButton
            className={`m-2 p-0`}
            style={{ height: "14px", width: "14px" }}
            onClick={onHide}
          />
        </div>
        <Template
          onTemplateSelect={(templateText: string) => {
            setShowModal(false);
            setShowingWhichModal("");
            selectedTemplate(templateText);
          }}
        />
      </Modal> */}
      <Modal
        backdropClassName={`${styles.modalBack}`}
        show={showModal}
        onHide={onHide}
        dialogClassName={`${styles.modalDialog}`}
        contentClassName={`${styles.modalContent}`}
        centered={true}
        enforceFocus={false}
        id="viewTemplateModal"
      >
        <div
          className={`h-0 w-100 d-flex justify-content-end ${styles.btnPosition}`}
          id="closeViewTemplateModal"
        >
          <span
            className={`${styles.crossBtn}`}
            onClick={onHide}
          >
            <i className="fa-solid fa-xmark"></i>
          </span>
        </div>
        <ViewTemplateV2
          onTemplateSelect={(templateText: string) => {
            setShowModal(false);
            setShowingWhichModal("");
            selectedTemplate(templateText);
          }}
        />
      </Modal>
    </>
  );
}

export default ViewTemplateButtonV2;
