import { CannedResponse } from "src/services/LiveChat/Settings/cannedResponses/getAllCannedResponse";
import { ICannedResponses } from "./cannedResponse.slice";

const fetchAllCannedResponseFulfilled = (
  state: ICannedResponses,
  { payload }: any
) => {
  const { cannedResponses, cannedResponseIdList, metaData, totalPages, cachedData }: any =
    payload as any;

  // state.cannedResponses = {
  //   ...state.cannedResponses,
  //   ...cannedResponses,
  // };
  // state.cannedResponseIdList = [
  //   ...state.cannedResponseIdList,
  //   ...cannedResponseIdList,
  // ];

  state.cannedResponses = cannedResponses;
  state.cannedResponseIdList = cannedResponseIdList;
  state.metaData.count = metaData.count;
  state.metaData.total = metaData.total;
  state.cachedCannedResponse = cachedData;

  state.cannedResponseTotalPages = totalPages;

  if (state.cannedResponseCurrentPage === 0 && cannedResponseIdList && cannedResponseIdList.length) {
    state.cannedResponseCurrentPage = 1;
  }
  if (cannedResponseIdList == undefined || cannedResponseIdList.length === 0) {
    // console.log("HIIII", allTicketsIds, cachedTickets);
    const usablePages = Object.entries(cachedData)
      .filter(([key, value]: any, index) => value.length !== 0)
      .map(([key]: any) => parseInt("" + key));
    if (usablePages.length > 0) {
      const currentPageTemp = usablePages[usablePages.length - 1];
      state.cannedResponseCurrentPage = currentPageTemp;
      state.cannedResponseIdList = cachedData[currentPageTemp];
    } else {
      state.cannedResponseCurrentPage = 0;
      state.cannedResponseIdList = [];
    }
  }

  if (state.cannedResponseCurrentPage > totalPages) {
    state.cannedResponseCurrentPage = totalPages;
  }

  state.cannedResponseAjaxStatus = "fulfilled";
};

function addCannedResponseFulfilled(state: ICannedResponses, { payload }: any) {
  state.addCannedResponseAjaxStatus = "fulfilled";
}

function editCannedResponseFulfilled(
  state: ICannedResponses,
  { payload }: { payload: CannedResponse }
) {
  state.addCannedResponseAjaxStatus = "fulfilled";
  state.cannedResponses[payload.cannedResponseId] = payload;
}

function deleteCannedResponseFulfilled(
  state: ICannedResponses,
  { payload }: any
) {
  state.deleteCannedResponseAjaxStatus = "fulfilled";
  delete state.cannedResponses[payload];
  state.cannedResponseIdList = state.cannedResponseIdList.filter(
    (data) => data + "" !== payload + ""
  );
  state.metaData.total = state.metaData.total ? state.metaData.total - 1 : null;
  return state;
}

function fetchAllCannedResponseInfiScrollFulfilled(
  state: ICannedResponses,
  { payload }: any
) {
  const { cannedResponses, cannedResponseIdList, metaData }: any =
    payload as any;

  state.cannedResponses = {
    ...state.cannedResponses,
    ...cannedResponses,
  };
  //to prevent duplicate ids in the array using Set class and converting it to array again
  state.cannedResponseIdList = Array.from(
    new Set([...state.cannedResponseIdList, ...cannedResponseIdList])
  );

  state.metaData.count = metaData.count;
  state.metaData.total = metaData.total;
  state.cannedResponseAjaxStatus = "fulfilled";
}

export default {
  fetchAllCannedResponseFulfilled,
  addCannedResponseFulfilled,
  editCannedResponseFulfilled,
  deleteCannedResponseFulfilled,
  fetchAllCannedResponseInfiScrollFulfilled,
};
