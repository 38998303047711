/**
 * This file defines a React component responsible for show the customQA table data.
 *
 * @author @Anubhav-busibud
 * @author @navjyot-busibud
 */
import { getAllQuestionAnswer } from "src/services/Bot/CustomQA/getAllQuestionAnswer.service";
import styles from "./CustomQaTable.module.scss";
import Data from "./QaTableData/QaTableData";
import { useMutation, useQuery } from "@tanstack/react-query";
import { selectQa } from "src/services/Bot/CustomQA/selectQa.service";
import { useParams } from "react-router-dom";
import Loader from "src/components/Loader";
import { useMemo } from "react";
import useGetAllCustomQA from "../../useGetAllCustomQA";
import Pagination from "src/routes/TicketList/children/Pagination/Pagination";

type Props = {
  searchTerm: string;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  metaData: any;
  setMetaData: React.Dispatch<React.SetStateAction<any>>;
  status: string;
  handleNewAnswerClick: () => void;
};

const Custom = ({
  searchTerm,
  currentPage,
  setCurrentPage,
  metaData,
  setMetaData,
  handleNewAnswerClick
}: Props) => {
  //getting the id from url
  const { profileId } = useParams();

  const payload = useMemo(() => {
    return {
      profileId: profileId,
      searchTerm: searchTerm?.trim() ? searchTerm : undefined,
    };
  }, [searchTerm, profileId]);

  const {
    customQAs,
    isLoading,
    totalPages,
    isError,
    updateCustomQA,
    handleDeleteCustomQA,
  } = useGetAllCustomQA({
    search: searchTerm,
    currentPage,
    setCurrentPage,
    metaData,
    setMetaData,
  });

  const selectQnA = useMutation({
    mutationFn: selectQa,
  });

  return (

    <div>
      {customQAs?.length !== 0 && (
        <div
          className={`mt-5 d-flex align-items-center justify-content-between px-3 ${styles.tableHead}`}
        >
          <div className={`${styles.article}`}>
            <div className="form-check">
              <input
                className={`form-check-input cursor-pointer ${styles.checkbox}`}
                type="checkbox"
                onChange={(e) => {
                  if (e.target.checked) {
                    selectQnA.mutate({
                      botProfileId: profileId || "",
                      isAllSelected: true,
                    });
                  } else {
                    selectQnA.mutate({
                      botProfileId: profileId || "",
                      isAllSelected: false,
                    });
                  }
                }}
              />
              <label className={`form-check-label ${styles.tablePara}`}>
                Question
              </label>
            </div>
          </div>
          <div className={`${styles.articleUrl}`}>
            <span className={`${styles.tablePara}`}>Status</span>
          </div>
          <div className={`${styles.botTraningStatus}`}>
            <span className={`${styles.tablePara}`}>Bot training status</span>
          </div>
          <div className={`${styles.appearance}`}>
            <span className={`${styles.tablePara}`}>No. of times appeared</span>
          </div>

          <div className={`${styles.action}`}>
            <span className={`${styles.tablePara}`}>Action</span>
          </div>
        </div>
      )}
      {isLoading && (
        <div className="">
          <Loader />
        </div>
      )}
      {isError && (
        <div
          className={`${styles.noResultFound} d-flex justify-content-center align-items-center h-100`}
        >
          Something went wrong...
        </div>
      )}

      {customQAs?.length === 0 && (
        <div
          className={`${styles.noResultFound} d-flex justify-content-center align-items-center h-100`}
        >
          <div className={styles.noResultFoundImage}> </div>
          <div className={styles.noResultFoundText}>No custom q&a added yet</div>
          <div> <button
            className={`mt-2 mt-md-0 ${styles.importBtn}`}
            onClick={handleNewAnswerClick}
          >
            <span className="pe-1">
              <i className="fa-solid fa-plus"></i>
            </span>
            New answer
          </button></div>


        </div>
      )}
      {customQAs?.map((data, index) => (
        <Data
          key={index}
          questionData={data}
          selectQnA={selectQnA}
          removeCustomQAFromList={handleDeleteCustomQA}
          updateCustomQAData={updateCustomQA}
        />
      ))}
      <div
        className="pt-2"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {totalPages > 1 && (
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            setCurrentPage={(selectedPage) => {
              setCurrentPage(selectedPage);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Custom;
