import { useCallback, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import automationSetting from "src/assets/images/automation-setting.png";
import cross from "src/assets/images/featuredArticledCross.png";
import InfiniteScroll from "src/components/InfiniteScroll";
import Loader from "src/components/Loader";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { BrandOption } from "src/routes/BotSettings/Children/BotBox/BrandModal/BrandModal";
import { setAutomateType } from "src/store/slices/initiateReturn/retrunExchange.slice";
import { useAppDispatch } from "src/store/store";
import CompleteSetup from "./CompleteSetup/CompleteSetup";
import { useCreateReturnIntegration } from "./CustomHooks/useCreateReturnIntegration";
import { useFetchReturnIntegration } from "./CustomHooks/useFetchReturnIntegration";
import styles from "./Return.module.scss";
import BrandDropdown from "./childern/BrandDropdown/BrandDropdown";
import StepsSlider from "./childern/StepsSlider/StepsSlider";

function Return() {
  const [show, setShow] = useState(false);
  const [showSelectBrandModal, setShowSelectBrandModal] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState<BrandOption | null>(null);
  const [error, setError] = useState("");
  const dispatch = useAppDispatch();

  const onHideSelectBrandModal = useCallback(() => {
    setShowSelectBrandModal(false);
  }, []);

  //using useFetchReturnIntegration custom hook to fetch all the integrations
  const {
    integrationIds,
    totalIntegrations,
    integrations,
    loading,
    hasMoreIntegrations,
    handleInfiniteScroll,
    initialFetchLoading,
  } = useFetchReturnIntegration({ fetchLimit: 10 });

  const { deleteIntegration } = useCreateReturnIntegration({});

  const handleDeleteIntegration = useCallback(
    (integrationId: number) => {
      deleteIntegration(integrationId, () => {
        pushTheToast({
          type: "success",
          text: "Deleted Successfully!",
          position: "top-right",
        });
        handleInfiniteScroll();
      });
    },
    [deleteIntegration, handleInfiniteScroll],
  );

  // Callback to handle the change event of the dropdown
  const handleChange = useCallback(
    (selectedOption: BrandOption | null) => {
      if (selectedOption) {
        dispatch(
          setAutomateType({ selectedBrandId: Number(selectedOption.value) }),
        );
        setSelectedBrand(selectedOption);
        setError("");
      }
    },
    [dispatch],
  );

  const handleSelectBrand = useCallback(() => {
    if (selectedBrand) {
      setShowSelectBrandModal(false);
      setShow(true);
    } else {
      setError("*Please select a brand");
    }
  }, [selectedBrand]);

  const stepSliderOnHide = useCallback(() => {
    setShow(false);
  }, []);

  return (
    <>
      <div className={`w-100 ${styles.contentWrapper}`}>
        {/* Add new automation step slider modal */}
        <div
          className={`d-flex justify-content-between align-items-center w-100 flex-wrap`}
        >
          <div className={`d-flex align-items-center`}>
            {/* <div className="me-2 mb-2 mb-md-0">
              <div className={`mt-1`}>
                <NavLink
                  to={`/automations/return/`}
                  className={`d-flex justify-content-center align-items-center ${styles.backChat}`}
                >
                  <i className="fa-solid fa-arrow-left"></i>
                </NavLink>
              </div>
            </div> */}
            <div className={`ms-2 ${styles.heading}`}>
              Return/Exchange An Order
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <span
              className={`px-3 ${styles.btn}`}
              onClick={() => setShowSelectBrandModal(true)}
            >
              Add new automation
            </span>
            <Modal
              show={show}
              centered={true}
              dialogClassName={`${styles.modalDialog}`}
              contentClassName={`${styles.modalContent}`}
              onHide={stepSliderOnHide}
            >
              <StepsSlider onHide={stepSliderOnHide} />
            </Modal>

            {/* Choose a brand Modal */}
            <Modal
              show={showSelectBrandModal}
              contentClassName={`${styles.modalMove}`}
              centered={true}
              enforceFocus={false}
              onHide={onHideSelectBrandModal}
            >
              <div className="">
                <div className="">
                  <div className="d-flex justify-content-between">
                    <div className={`${styles.headingModel} mb-3`}>
                      Choose a brand
                    </div>
                    <div>
                      <span
                        className="cursor-pointer"
                        onClick={onHideSelectBrandModal}
                      >
                        <img
                          src={cross}
                          alt="cross"
                          className={`${styles.cross}`}
                        />
                      </span>
                    </div>
                  </div>
                  <div className={`mt-3 ${styles.subheadingModel}`}>
                    This will appear in your live chat for the following brand:
                  </div>
                  <div className="mt-2">
                    {/* Added branddropdown as per xd */}
                    <BrandDropdown
                      selectedBrand={selectedBrand}
                      setSelectedBrand={handleChange}
                      className={error ? styles.errorStyle : ""}
                      error={error}
                    />
                  </div>
                  <div
                    className={`${styles.btnModel} d-flex justify-content-end mt-5 pt-2 me-3`}
                  >
                    <button
                      className={`${styles.btn} ${styles.publishBtn} ${
                        selectedBrand ? "" : styles.disabled
                      }`}
                      onClick={handleSelectBrand}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </div>

        {initialFetchLoading === false ? (
          <>
            {/* if no integration found then this section showed (to add new integration) */}
            {integrationIds.length === 0 && (
              <div className={`d-none mt-3 ${styles.secondSection}`}>
                <div className="m-auto  text-center">
                  <div className="my-2">
                    <img
                      src={automationSetting}
                      alt=""
                      className={`${styles.logo}`}
                    />
                  </div>
                  <div className="my-2">
                    <span className={`${styles.NoReturnText} text-center`}>
                      No Returns/Exchanges automation is live on your store
                    </span>
                  </div>
                  <div className="my-2">
                    <span className={`${styles.enableText} text-center`}>
                      Enable Returns/Exchange for your store.
                    </span>
                  </div>
                  <div className="my-2 w-100 d-flex justify-content-center">
                    <span
                      className={`px-2 ${styles.addBtn}`}
                      onClick={(e) => {
                        setShowSelectBrandModal(true);
                      }}
                    >
                      Add new automation
                    </span>
                  </div>
                </div>
              </div>
            )}

            <div
              className={`p-3 mt-3 ${styles.returnmain} ${
                totalIntegrations === 0 && styles.centerAlign
              }`}
            >
              {/* Infinite scroll */}
              <InfiniteScroll
                initialDataLoaded={true}
                className={`p-2 p-md-3`}
                loadMore={handleInfiniteScroll}
                loader={
                  integrationIds.length === 0 ? (
                    <div className={`${styles.loading} m-auto`}>
                      <Spinner
                        animation="border"
                        variant="primary"
                        size="sm"
                        className={`d-block m-auto mb-2`}
                      />
                    </div>
                  ) : undefined
                }
                errorMessage={
                  totalIntegrations === 0 && loading === false ? (
                    <div className={`${styles.nothingFound} m-auto `}>
                      <div className={`mt-3`}>
                        <div className="m-auto  text-center">
                          <div className="my-2">
                            <img
                              src={automationSetting}
                              alt=""
                              className={`${styles.logo}`}
                            />
                          </div>
                          <div className="my-2">
                            <span
                              className={`${styles.NoReturnText} text-center`}
                            >
                              No Returns/Exchanges automation is live on your
                              store
                            </span>
                          </div>
                          <div className="my-2">
                            <span
                              className={`${styles.enableText} text-center`}
                            >
                              Enable Returns/Exchanges for your store.
                            </span>
                          </div>
                          <div className="my-2 w-100 d-flex justify-content-center">
                            <span
                              className={`px-2 ${styles.addBtn}`}
                              onClick={() => setShowSelectBrandModal(true)}
                            >
                              Add new automation
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : undefined
                }
                hasMore={hasMoreIntegrations}
              >
                {/*iterating over each integration ids and rendering integration item compontent */}
                {integrationIds.map((integrationId) => {
                  const integration = integrations[integrationId];
                  return (
                    <CompleteSetup
                      key={integrationId}
                      integration={integration}
                      deleteIntegration={handleDeleteIntegration}
                    />
                  );
                })}
              </InfiniteScroll>
            </div>
          </>
        ) : (
          <div
            className={`d-flex justify-content-center w-100 ${styles.loaderHeight}`}
          >
            <Loader />
          </div>
        )}
      </div>
    </>
  );
}

export default Return;
