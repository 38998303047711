import React, { useCallback, useEffect, useState } from "react";
import styles from "./BrandDropdown.module.scss";
import UserAvatar from "src/components/UserAvatar";
import Select, {
  components,
  ControlProps,
  OptionProps,
  SingleValueProps,
} from "react-select";
import AxiosImg from "src/components/AxiosImg";
import { useInfiniteQuery } from "@tanstack/react-query";
import { getKnowledgeBaseBrands } from "src/services/KnowledgeBase/getKnowledgeBaseBrands.service";

interface BrandOption {
  value: number;
  label: string;
  imageUrl?: string | null;
}

const CustomOption: React.FC<OptionProps<BrandOption>> = (props) => (
  <components.Option {...props}>
    <div className={`d-flex align-items-center cursor-pointer ${styles.brandWrap}`}>
      {props.data.imageUrl !== null && props.data.imageUrl ? (
        <AxiosImg url={props.data.imageUrl} style={{ width: "20px", height: "20px" }} //Added this style to fix brand image size
        className={`rounded-circle`} />
      ) : (
        <UserAvatar name={props.label} size={20} />
      )}
      <span className={`ps-2 ${styles.brandName}`}>{props.label}</span>
    </div>
  </components.Option>
);

const CustomSingleValue: React.FC<SingleValueProps<BrandOption>> = (props) => (
  <components.SingleValue {...props}>
    <div className={`d-flex align-items-center`}>
      {props.data.imageUrl !== null && props.data.imageUrl ? (
        <AxiosImg url={props.data.imageUrl} size={20} style={{ width: "20px", height: "20px" }} //Added this style to fix brand image size
        className={`rounded-circle`}/>
      ) : (
        <UserAvatar name={props.data?.label ?? "NA"} size={20} />
      )}{" "}
      <span className={`ps-1 ${styles.selectBrand}`}>{props.data?.label}</span>
    </div>
  </components.SingleValue>
);

const CustomControl: React.FC<ControlProps<BrandOption>> = ({
  children,
  innerRef,
  innerProps,
  isFocused,
}) => {
  return (
    <div
      ref={innerRef}
      className={`custom-control-container ${
        isFocused ? styles.focusStyle : ""
      }`}
    >
      <div
        {...innerProps}
        className={`custom-control px-2 ${styles.brandDrop}`}
      >
        {children}
      </div>
    </div>
  );
};

const CustomDropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <i
        className={`fa-solid ${
          props.selectProps.menuIsOpen ? "fa-caret-up" : "fa-caret-down"
        }`}
      ></i>
    </components.DropdownIndicator>
  );
};

interface Props {
  selectedBrand: BrandOption | undefined;
  setSelectedBrand: (brand: BrandOption) => void;
  updateBrandChange?: Function;
  showValidation: boolean;
  setShowValidation: (showValidation: boolean) => void;
}

const BrandDropdown = ({
  selectedBrand,
  setSelectedBrand,
  updateBrandChange,
  showValidation,
  setShowValidation,
}: Props) => {
  const [brandOptions, setBrandOptions] = useState<BrandOption[]>([]);

  const payload = {
    limit: 15,
    start: 0,
  };

  const { data, isLoading, fetchNextPage, hasNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ["getKnowledgeBaseBrands", payload],
      queryFn: ({ pageParam = payload }) => getKnowledgeBaseBrands(pageParam),
      getNextPageParam: (lastPage: any, allPages) => {
        const data = allPages.flatMap((data: any) => data.data); // flatMap is used to flatten the array of arrays
        // If the data length is less than the total count then return the next page param
        if (data.length < lastPage.metaData.totalCount) {
          const nextPageParam = {
            ...payload,
            start: data.length, // setting the start to the length of the data
          };
          return nextPageParam;
        }
        return null;
      },
    });

  // Callback to handle the change event of the dropdown
  const handleChange = useCallback((selectedOption: any) => {
    setShowValidation(false);
    setSelectedBrand(selectedOption);
    if (updateBrandChange) {
      updateBrandChange(selectedOption.value);
    }
  }, []);

  const fetchNextPageOnScroll = (event: WheelEvent | TouchEvent) => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };

  // Convert brandIds and brands data into options for the dropdown
  useEffect(() => {
    const brandData = data?.pages.flatMap((data: any) => data.data);
    if (brandData) {
      const options = brandData.reduce((acc: BrandOption[], brand: any) => {
        const { id, name, imageURL } = brand;
        if (id && name) {
          const brandOption = {
            label: name,
            value: id,
            imageUrl: imageURL || undefined,
          } as BrandOption;
          return [...acc, brandOption];
        }
        return acc;
      }, []);
      setBrandOptions(options);
    }
  }, [data]);

  return (
    <Select
      className={`w-100 cursor-pointer ${styles.brandWrap} ${
        showValidation ? "border border-danger rounded" : ""
      }`}
      classNamePrefix="dropdown"
      options={brandOptions}
      value={selectedBrand}
      onChange={handleChange}
      components={{
        Option: CustomOption,
        SingleValue: CustomSingleValue,
        // Menu: CustomMenu,
        Control: CustomControl,
        DropdownIndicator: CustomDropdownIndicator,
        IndicatorSeparator: null,
      }}
      placeholder="Select Brand"
      isSearchable={false}
      isMulti={false}
      onMenuScrollToBottom={fetchNextPageOnScroll}
      isLoading={isLoading || isFetching}
    />
  );
};

export default BrandDropdown;
