import {
  IFeatureItem,
  IFeatureLimitItem,
} from "src/services/Billing/getPaymentPlans.service";
import styles from "../PaymentPlan/PaymentPlan.module.scss";

const replaceLabelWithPlaceholder = (
  value: string,
  variables: IFeatureLimitItem["variables"],
) => {
  let html = value;
  (variables ?? []).forEach((variable) => {
    html = html.replaceAll(
      variable.key,
      `<span ${variable.color ? `style="color:${variable.color}"` : ""}>${
        variable.value
      }</span>`,
    );
  });

  return <div dangerouslySetInnerHTML={{ __html: html }}></div>;
};

const FeatureItem = ({ feature }: { feature: IFeatureItem }) => {
  return (
    <div
      className={`checks ${feature.enabled ? "" : styles.inactiveitem} ${
        feature.displayName === "AI chatbot" && "aiBotHeight"
      } d-flex align-items-start mb-3`}
    >
      <div>
        <span className={`${styles.checkBox}`}>
          {feature.enabled ? (
            <i className="fa-solid fa-check"></i>
          ) : (
            <i className="fa-solid fa-close"></i>
          )}
        </span>
      </div>
      <div>
        <span className={`${styles.checkLabel}`}>{feature.displayName}</span>
        {feature.limits?.map((limit, index) => {
          if (limit.type === "tag") {
            return (
              <span
                key={index}
                className={`mt-1 ${
                  feature.displayName === "Training knowledge base"
                    ? styles.pages
                    : styles.conversation
                } ${
                  feature.displayName === "Public knowledge base" &&
                  styles.pages
                }`}
                style={{
                  color: limit.color ?? undefined,
                }}
              >
                {limit.value}
              </span>
            );
          } else if (limit.type === "additional_cost_per_unit") {
            return (
              <p
                key={index}
                className={`mt-1 ${styles.additionalCost}`}
              >
                {replaceLabelWithPlaceholder(
                  limit.description ?? "",
                  limit.variables,
                )}
              </p>
            );
          } else {
            return <p key={index}>{limit.value}</p>;
          }
        })}
      </div>
    </div>
  );
};

export default FeatureItem;
