import { Dropdown } from "react-bootstrap";
import styles from "./ForwardMessage.module.scss";
import forwardImg from "src/assets/images/forward.svg";
import { useRef, useState } from "react";
import { IMessageData } from "src/store/slices/innerTicket/innerTicket.slice";
import { highlightSearchedText } from "src/routes/TicketList/children/OuterTickets/Chidren/SearchedTickets/SearchedTickets";
import ParseHTML from "src/components/ParseHTML";
import InnerChatAttachments from "src/components/InnerChatAttachments/InnerChatAttachments";
import { getTimeFromNow } from "src/utils/dateLibrary";
import { useAppSelector } from "src/store/store";
import { DropDownMenuData } from "../SendMsgCommon/SendMsgCommon";
import { getChannelFAIcon } from "src/utils/utils";
interface Props {
  messageData: IMessageData;
  tryAgainRendering: Function;
  userTimezone?: string;
  messageChannel: string;
}
const ForwardMessage = ({
  messageData,
  tryAgainRendering,
  userTimezone,
  messageChannel,
}: Props) => {
  const [show, setShow] = useState(false);
  const dropdownRef = useRef<any>();
  const dropdownArrowSpanRef = useRef<any>();
  const rightAlign = true;
  const searchTerm = useAppSelector(
    (state) => state.ticketGlobalSearch.searchText,
  );

  return (
    <div className={`${styles.boxWrapper}`}>
      <div className="">
        <div className={`${styles.chatWrapper}`}>
          {messageData.messageStatus !== "pending" &&
          messageData.messageStatus !== "rejected" ? (
            <div className={`${styles.header}`}>
              <span className={`pe-2 ${styles.forwardText}`}>
                Forwarded to {messageData.toName || ""} ({messageData.to})
              </span>
              <img
                src={forwardImg}
                alt="forward"
                width={12}
                height={12}
              />
            </div>
          ) : null}

          <div className={`py-2 ${styles.bodyWrapper}`}>
            <ParseHTML
              className={`${styles.msgText} `}
              html={
                messageData
                  ? (highlightSearchedText(
                      messageData.message,
                      searchTerm,
                      true,
                    ) as string)
                  : ""
              }
            />
            {messageData &&
              messageData.attachments &&
              messageData.attachments.length > 0 && (
                <InnerChatAttachments messageData={messageData} />
              )}
          </div>
        </div>
        <div className="d-flex justify-content-end mt-1">
          {messageData.messageStatus === "pending" ? (
            <span className={`ps-1 ${styles.processingText}`}>
              {messageChannel
                ? "sending via " + messageChannel.toLowerCase()
                : "sending"}
              ...
            </span>
          ) : messageData.messageStatus === "rejected" ? (
            tryAgainRendering()
          ) : (
            <span
              className={`${styles.created} d-flex justify-content-end`}
              style={{ float: rightAlign ? "right" : "left" }}
            >
              <div
                className={`dropdown d-flex ${
                  rightAlign ? "justify-content-end" : "justify-content-start"
                }`}
                ref={dropdownRef}
              >
                <div>
                  <Dropdown
                    show={show}
                    onToggle={setShow}
                  >
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      as="div"
                      ref={dropdownRef}
                      bsPrefix={`dropdown-toggle cursor-pointer ${styles.sendDrop} ${styles.dropdownToggle}`}
                    >
                      {`${getTimeFromNow({
                        date: new Date(messageData.messageDateTime),
                        timeZone: userTimezone,
                      })}${
                        messageData.from
                          ? `, Via: ${
                              messageChannel === "Instagram"
                                ? "Instagram DM"
                                : messageData.from
                            }`
                          : ""
                      }`}
                      <span ref={dropdownArrowSpanRef}>
                        {" "}
                        {show ? (
                          <i className={`fa-solid fa-caret-up`}></i>
                        ) : (
                          <i className={`fa-solid fa-caret-down`}></i>
                        )}
                      </span>
                    </Dropdown.Toggle>
                    <DropDownMenuData
                      messageData={messageData}
                      labelBy={"dropdownMenuButton2"}
                    />
                  </Dropdown>
                </div>
                <div>
                  <Dropdown>
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      as="span"
                      ref={dropdownRef}
                      bsPrefix={`dropdown me-1 ${styles.insta}`}
                    >
                      <i
                        className={`d-block cursor-pointer dropdown-toggle ${getChannelFAIcon(
                          messageChannel,
                        )} override ${styles.dropdownToggle}`}
                        id={`dropdownMenuButton1`}
                        data-bs-toggle={`dropdown`}
                        aria-expanded={`false`}
                      ></i>{" "}
                    </Dropdown.Toggle>
                    <DropDownMenuData
                      messageData={messageData}
                      labelBy={"dropdownMenuButton1"}
                    />
                  </Dropdown>
                </div>
              </div>
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default ForwardMessage;
