import { axiosJSON } from "src/globals/axiosEndPoints";

const deleteSlackIntegrationService = async (payload: {
  integrationId: string;
}) => {
  const { data: res } = await axiosJSON.delete(
    "/api/setting/integration/slack/removeSlackIntegration",
    {
      data: payload,
    },
  );

  if (res.err || res.error) {
    throw new Error(res.msg ?? res.message ?? "Something went wrong!");
  }
};

export default deleteSlackIntegrationService;
