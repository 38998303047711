import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";
import { IChatFormElementOption, IChatFormElementOptionList } from "src/services/LiveChat/Settings/preChatForm/getPreChatFormData";

export interface operators {
  name: string;
  key: string;
}

export interface conditionType {
  conditionKey: number|string;
  conditionName: string;
  inputType: string;
  operators: operators[];
}

export interface ConditionOptions {
  data: conditionType[];
}


const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/botProfile/preChatForm`
  : "/api/botProfile/options/preChatForm";

export async function getFormElementOptions() {
  const { data: res } = await axiosJSON.get(apiEndPoint);

  if (res.error) {
    throw res.message;
  }

  const formElementOptionIds: any = [];
  const formElementOptions: any = {};
  res.data.forEach((option: IChatFormElementOption ) => {
    formElementOptions[option.elementId] = option;
    formElementOptionIds.push(option.elementId);
  });

  return { formElementOptions, formElementOptionIds} as IChatFormElementOptionList;
}
