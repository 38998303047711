import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { integrateShopify } from "src/apis/shopify/integrate";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";

function Integration() {
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);

  const handleIntegration = (e: any) => {
    setLoading(true);
    integrateShopify(url)
      .then((res) => {
        setLoading(false);
        window.location.replace(res as any);
      })
      .catch((err) => {
        setLoading(false);
        pushTheToast({
          type: "danger",
          text: "Unable to integrate",
          position: "top-right"
        });
      });
  };

  return (
    <div className={`mx-3 mt-5`}>
      <h3>Shopify Integration</h3>
      <input
        data-lpignore="true"
        className={`d-block p-2 mt-3 w-100`}
        value={url}
        onChange={(e) => {
          setUrl(e.target.value);
        }}
        type="text"
      />
      <button
        className={`btn btn-primary mt-2 d-flex justify-content-center`}
        onClick={handleIntegration}
      >
        {loading && (
          <Spinner
            className={`mx-2 my-auto`}
            animation="border"
            size="sm"
            variant="white"
          />
        )}
        <span className={`m-auto`}>Integrate URL</span>
      </button>
    </div>
  );
}

export default Integration;
