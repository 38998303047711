import { Dropdown } from "react-bootstrap";
import styles from "./CustomSelect.module.scss";
import { useRef, useState } from "react";
import React from "react";

interface CustomSelectProps {
  items: Array<{ key: string; value: string }>;
  selectedItem: string;
  className?: string;
  onSelect: (key: string) => void;
}

const CustomSelect: React.FC<CustomSelectProps> = ({
  items,
  selectedItem,
  className = "",
  onSelect,
}) => {
  const [ddOpen, setDdOpen] = useState(false);
  const dropdownArrowSpanRef = useRef<HTMLSpanElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  return (
    <Dropdown
      onToggle={() => setDdOpen(!ddOpen)}
      className={`${className}`}
    >
      <Dropdown.Toggle
        className={`ms-0 my-2 my-lg-0 d-flex justify-content-between ${styles.operatorSelect} ${className}`}
      >
        <span
          ref={dropdownArrowSpanRef}
          className={`${styles.selected} text-truncate`}
        >
          {selectedItem}
        </span>
        <span className="ps-2">
          {ddOpen ? (
            <i className="fa-solid fa-caret-up"></i>
          ) : (
            <i className="fa-solid fa-caret-down"></i>
          )}
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu className={`${styles.mainSelectMenu} mt-1 ${className}`}>
        {items.map((item) => (
          <Dropdown.Item
            key={item.key}
            style={{ maxHeight: "2.5rem" }}
            value={item.key}
            className={`${styles.customItem}`}
            onClick={() => onSelect(item.key)}
          >
            {item.value}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default CustomSelect;
