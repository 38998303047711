import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import objectHash from "object-hash";
import { AJAXSTATUS } from "src/globals/constants";
import { Step11Config } from "src/services/UiAutomation/ReturnExchange/Configuration/Step11/step11GetConfig";
import { conditionType } from "src/services/UiAutomation/ReturnExchange/Configuration/Step9/step9GetOptionsConditions";
import {
  addConditionHandler,
  ConditionTypes,
  createNewObjectHash,
  deleteConditionHelper,
  deleteTabHelper,
  RecursivePartial,
  updateConditionHelper,
  validateChangeHandler,
  validateConditionsHelper,
} from "src/store/slices/initiateReturn/Helpers/returnExchange.helpers";
import { v4 as uuid } from "uuid";
import step11Builders from "./Builders/step11.builder";
import { step11ConfigErrorCount } from "./returnExchange.selectors";

export interface ReturnTypeDetails {
  returnTypeId: string;
  returnTypeName: string | null | undefined;
  // returnSequence: number;
  conditions: ConditionTypes[];
  messageForCustomer: {
    value: string | undefined;
    error: string | null;
  };
  itemError: string | null;
  error: string | null;
  errorCount: number;
  isAddConditionEnabled: boolean;
  isAddItemConditionEnabled: boolean;
  isValid: boolean;

  ExchangeWithSameItem: boolean;
  ExchangeWithOtherItem: boolean;
  RefundtoStoreCredit: boolean;
  RefundToOriginalPayment: boolean;
}

// interface AllOrderAndItemTypes {
//   Id: string;
//   messageForCustomer: {
//     value: string | undefined;
//     error: string | null;
//   };
//   errorCount: number;
//   error: string | undefined;
//   ExchangeWithSameItem: boolean;
//   ExchangeWithOtherItem: boolean;
//   RefundtoStoreCredit: boolean;
//   RefundToOriginalPayment: boolean;
// }

export interface step11Config {
  selectedReturnTypeId: string | null;
  returnTypes: ReturnTypeDetails[];
  // allOrderAndItem: AllOrderAndItemTypes;
  error: string | null;
  // initialHashStaticTab: string;
  initialHashOtherTab: string;
  // currentHashStaticTab: string;
  currentHashOtherTab: string;
  isChanged: boolean;
  selectedTabIntialHash: string | null;
  selectedTabCurrentHash: string | null;
  excludeKeysForHash: string[];
  intialStep11Config: {
    returnTypes: ReturnTypeDetails[] | null;
    // allOrderAndItem: AllOrderAndItemTypes | null;
  };

  conditionOptions: conditionType[];
  itemOptions: conditionType[];
  defaultOptions: conditionType[];
  getReturnType: Step11Config;

  variableNames: any;
  operatorsNames: any;
  operatorSymbolMap: any;
  valueTypes: any;
  variableNameToValueType: any;
  fetchAjaxStatus: AJAXSTATUS;
  updateAjaxStatus: AJAXSTATUS;
}

const excludeKeys = [
  "itemError",
  "isAddConditionEnabled",
  "isAddItemConditionEnabled",
  "errorCount",
  "isValid",
];

const firstReturnTypeId = uuid();

export const initialState: step11Config = {
  selectedReturnTypeId: firstReturnTypeId,
  returnTypes: [
    {
      returnTypeId: firstReturnTypeId,
      conditions: [],
      error: null,
      itemError: null,
      errorCount: 0,
      ExchangeWithOtherItem: false,
      ExchangeWithSameItem: false,
      isAddConditionEnabled: true,
      isAddItemConditionEnabled: true,
      messageForCustomer: { value: "", error: null },
      RefundToOriginalPayment: false,
      RefundtoStoreCredit: false,
      // returnSequence: 1,
      returnTypeName: "Return Type 1",
      isValid: false,
    },
  ],
  error: "",

  // allOrderAndItem: {
  //   error: "",
  //   ExchangeWithOtherItem: true,
  //   ExchangeWithSameItem: true,
  //   RefundToOriginalPayment: true,
  //   Id: "1",
  //   messageForCustomer: { value: "", error: null },
  //   RefundtoStoreCredit: true,
  //   errorCount: 0,
  // },
  selectedTabIntialHash: "",
  selectedTabCurrentHash: "",

  initialHashOtherTab: "",

  currentHashOtherTab: "",
  excludeKeysForHash: excludeKeys,
  isChanged: false,

  intialStep11Config: {
    // allOrderAndItem: null,
    returnTypes: null,
  },

  conditionOptions: [],
  itemOptions: [],
  defaultOptions: [],
  getReturnType: { data: [] },

  variableNames: [],
  operatorsNames: {},
  operatorSymbolMap: {},
  valueTypes: {},
  variableNameToValueType: {},
  fetchAjaxStatus: "pending",
  updateAjaxStatus: "idle",
};

export const step11ConfigSlice = createSlice({
  name: "step11Config",
  initialState,
  reducers: {
    // Add Return Type
    addReturnType: (state: step11Config) => {
      const newReturTypeId = uuid();

      state.selectedReturnTypeId = newReturTypeId;

      state.returnTypes.push({
        returnTypeId: newReturTypeId,
        returnTypeName: `Return Type ${state.returnTypes.length + 1}`,
        conditions: [],
        // returnSequence: state.returnTypes.length + 1,
        messageForCustomer: { value: "", error: null },
        error: "",
        itemError: "",
        isAddConditionEnabled: true,
        isAddItemConditionEnabled: true,
        ExchangeWithSameItem: true,
        ExchangeWithOtherItem: true,
        RefundtoStoreCredit: true,
        RefundToOriginalPayment: true,
        errorCount: 0,
        isValid: false,
      });
    },

    // Update Return Type
    updateReturnType(
      state: step11Config,
      params: PayloadAction<{
        returnTypeDetail: RecursivePartial<ReturnTypeDetails>;
      }>,
    ) {
      const { returnTypeId, messageForCustomer, returnTypeName } =
        params.payload.returnTypeDetail;

      const selectedIdx = state.returnTypes.findIndex(
        (ret) => ret.returnTypeId === returnTypeId,
      );

      const selectedReturn = state.returnTypes[selectedIdx];

      selectedReturn.messageForCustomer.value =
        messageForCustomer?.value ?? selectedReturn.messageForCustomer.value;
      selectedReturn.messageForCustomer.error =
        messageForCustomer?.error ?? selectedReturn.messageForCustomer.error;
      if (selectedReturn.messageForCustomer.error !== undefined) {
        selectedReturn.messageForCustomer.error = null;
      }

      if (returnTypeName) selectedReturn.returnTypeName = returnTypeName;
    },

    // updateAllOrderAndItemType(
    //   state: step11Config,
    //   params: PayloadAction<{
    //     id: string;
    //     messageForCustomer: {
    //       value: string | undefined;
    //       error: string | null;
    //     };
    //   }>
    // ) {
    //   const messageForCustomer = params.payload.messageForCustomer;

    //   state.allOrderAndItem.messageForCustomer.value =
    //     messageForCustomer?.value ??
    //     state.allOrderAndItem.messageForCustomer.value;
    //   state.allOrderAndItem.messageForCustomer.error =
    //     messageForCustomer?.error ??
    //     state.allOrderAndItem.messageForCustomer.error;
    //   if (state.allOrderAndItem.messageForCustomer.error !== undefined) {
    //     state.allOrderAndItem.messageForCustomer.error = null;
    //   }
    // },

    // Delete Return Type
    deleteReturnType: (state, { payload }: { payload: { id: string } }) => {
      const { updatedTabs, newSelectedTabId } = deleteTabHelper({
        tabs: state.returnTypes,
        tabId: payload.id,
        selectedTabId: state.selectedReturnTypeId,
        idKey: "returnTypeId", // Specify the relevant key name here
      });

      state.returnTypes = updatedTabs;
      state.selectedReturnTypeId = newSelectedTabId;
    },

    pushInitialHash: (state) => {
      const hash = createNewObjectHash({
        excludeKeys: state.excludeKeysForHash,
        hashObject: state.returnTypes,
      });

      if (hash) {
        state.initialHashOtherTab = hash;
        state.currentHashOtherTab = hash;
      }
    },

    pushCurrentHashForSelectedTab: (
      state,
      params: PayloadAction<{ returnTypeId: string | null }>,
    ) => {
      const selectedReturnTypeIdx = state.returnTypes.findIndex(
        (rType) => rType.returnTypeId === params.payload.returnTypeId,
      );

      state.selectedTabCurrentHash = objectHash(
        state.returnTypes[selectedReturnTypeIdx],
      );
    },

    validateChanges: (state) => {
      const { initialHashOtherTab } = state;

      const { isChanged, newHash } = validateChangeHandler({
        stateObject: state.returnTypes,
        excludeKeys: state.excludeKeysForHash,
        initialHash: initialHashOtherTab,
      });

      if (newHash) {
        state.isChanged = isChanged;
        state.currentHashOtherTab = newHash;
      }
    },

    resetHashes: (state) => {
      state.currentHashOtherTab = "";
      // state.currentHashStaticTab = "";
    },

    undoConfig: (
      state,
      params: PayloadAction<{
        initialConfig: {
          returnTypes: ReturnTypeDetails[] | null;
          // allOrderItems: AllOrderAndItemTypes | null;
        };
      }>,
    ) => {
      if (params.payload.initialConfig.returnTypes !== null) {
        state.returnTypes = params.payload.initialConfig.returnTypes;

        // if (state.returnTypes.length === 0) {
        //   state.selectedReturnTypeId = "1";
        // }
      }

      // if (params.payload.initialConfig.allOrderItems !== null) {
      //   state.allOrderAndItem = params.payload.initialConfig.allOrderItems;
      // }
    },

    addCondition: (
      state: step11Config,
      params: PayloadAction<{
        returnTypeId: string;
        condition: string;
        isItemCondition?: boolean;
      }>,
    ) => {
      let selectedReturn = state.returnTypes.find(
        (returnType) => returnType.returnTypeId === params.payload.returnTypeId,
      );

      const selectedTab = addConditionHandler({
        selectedTab: selectedReturn,
        conditionOptions: params.payload?.isItemCondition
          ? state.itemOptions
          : state.conditionOptions,
        payloadCondition: params.payload.condition,
        isItemCondition: params.payload?.isItemCondition,
      });

      selectedReturn = selectedTab;

      // if (selectedReturn !== undefined) {
      //   let operator: any = "";
      //   let variableValues = null;
      //   let variableName: any = "";

      //   if (params.payload?.isItemCondition === true) {
      //     let itemCondition = state.itemOptions[0];

      //     if (params.payload.condition !== "") {
      //       let addCondition = state.itemOptions.filter(
      //         (value) => value.name === params.payload.condition,
      //       );
      //       if (addCondition.length) {
      //         itemCondition = addCondition[0];
      //       }
      //     }

      //     variableName = itemCondition.name;
      //     operator = itemCondition.operators[0].name;
      //     variableValues = getConditionValueOrDefault(itemCondition.valueType);
      //   } else {
      //     let otherCondition = state.conditionOptions[0];

      //     if (params.payload.condition !== "") {
      //       let addCondition = state.conditionOptions.filter(
      //         (value) => value.name === params.payload.condition,
      //       );
      //       if (addCondition.length) {
      //         otherCondition = addCondition[0];
      //       }
      //     }

      //     variableName = otherCondition.name;
      //     operator = otherCondition.operators[0].name;
      //     variableValues = getConditionValueOrDefault(otherCondition.valueType);
      //   }
      //   selectedReturn.conditions.push({
      //     id: uuid(),
      //     operator: operator,
      //     ruleType: "AND",
      //     values: variableValues,
      //     variableName: variableName,
      //   });

      //   if (params.payload.isItemCondition === true) {
      //     selectedReturn.isAddItemConditionEnabled = false;
      //     if (selectedReturn.itemError !== null) {
      //       selectedReturn.itemError = null;
      //     }
      //   } else {
      //     // Check if the default value exists
      //     if (
      //       selectedReturn.conditions[selectedReturn.conditions.length - 1]
      //         .values["days"]?.currentValue > 0
      //     ) {
      //       // Add new condition enabled if default value exists
      //       selectedReturn.isAddConditionEnabled = true;
      //     } else {
      //       // Add new condition disabled if no default exists
      //       selectedReturn.isAddConditionEnabled = false;
      //     }
      //     // Remove error if applied
      //     if (selectedReturn.error !== null) {
      //       selectedReturn.error = null;
      //     }
      //   }
      // }
    },

    // Update Conditon
    updateCondition(
      state: step11Config,
      params: PayloadAction<{
        returnTypeId: string;
        conditionToUpdate: RecursivePartial<ConditionTypes>;
      }>,
    ) {
      const { returnTypeId, conditionToUpdate } = params.payload;

      const selectedReturnIdx = state.returnTypes.findIndex(
        (returnType) => returnType.returnTypeId === returnTypeId,
      );

      if (selectedReturnIdx !== -1 && conditionToUpdate) {
        const selectedReturn = state.returnTypes[selectedReturnIdx];
        if (selectedReturn.messageForCustomer.error !== undefined) {
          selectedReturn.messageForCustomer.error = null;
        }
        const conditions = selectedReturn.conditions;

        const {
          updatedConditions,
          isAddOrderConditionEnabled,
          isAddItemConditionEnabled,
        } = updateConditionHelper({
          conditions,
          conditionToUpdate,
        });

        state.returnTypes[selectedReturnIdx].conditions = updatedConditions;

        if (isAddOrderConditionEnabled !== null) {
          state.returnTypes[selectedReturnIdx].isAddConditionEnabled =
            isAddOrderConditionEnabled;
        }

        if (isAddItemConditionEnabled !== null) {
          state.returnTypes[selectedReturnIdx].isAddItemConditionEnabled =
            isAddItemConditionEnabled;
        }
      }
    },

    deleteCondition: (
      state: step11Config,
      params: PayloadAction<{
        returnTypeId: string;
        conditionId: string;
        isItemCondition?: boolean;
      }>,
    ) => {
      const { returnTypeId, conditionId, isItemCondition } = params.payload;

      const selectedReturnIdx = state.returnTypes.findIndex(
        (returnType) => returnType.returnTypeId === returnTypeId,
      );
      if (selectedReturnIdx !== -1) {
        const selectedReturnType = state.returnTypes[selectedReturnIdx];
        const {
          updatedConditions,
          isAddOrderConditionEnabled,
          isAddItemConditionEnabled,
        } = deleteConditionHelper({
          conditionId: conditionId,
          conditions: selectedReturnType.conditions,
          conditionType: isItemCondition ? "item" : "order",
        });

        state.returnTypes[selectedReturnIdx].conditions = updatedConditions;
        if (isAddOrderConditionEnabled !== null) {
          state.returnTypes[selectedReturnIdx].isAddConditionEnabled =
            isAddOrderConditionEnabled;
        }
        if (isAddItemConditionEnabled !== null) {
          state.returnTypes[selectedReturnIdx].isAddItemConditionEnabled =
            isAddItemConditionEnabled;
        }
      }
    },

    setSelectedReturnTypeId(
      state: step11Config,
      params: PayloadAction<{ returnTypeId: string }>,
    ) {
      state.selectedReturnTypeId = params.payload.returnTypeId;
    },

    validateReturnType(
      state: step11Config,
      params: PayloadAction<{
        returnTypeId: string | null;
      }>,
    ) {
      const selectedReturnIdx = state.returnTypes.findIndex(
        (returnType) => returnType.returnTypeId === params.payload.returnTypeId,
      );

      let selectedReturn: any;

      selectedReturn = state.returnTypes[selectedReturnIdx];

      if (
        selectedReturn.messageForCustomer.value !== undefined &&
        selectedReturn.messageForCustomer.value.length <= 0
      ) {
        selectedReturn.messageForCustomer.error = "*Please fill out this field";
      } else {
        selectedReturn.messageForCustomer.error = null;
      }
      const errors = [
        selectedReturn.messageForCustomer.error,
        selectedReturn.error,
      ];

      selectedReturn.errorCount = errors.reduce((p: number, c: null) => {
        if (c !== null) {
          return p + 1;
        }
        return p;
      }, 0);

      if (state.returnTypes == null) {
        state.error = "Add a Return Type ";
      }
    },

    validateCondition(
      state: step11Config,
      params: PayloadAction<{
        returnTypeId: string;
      }>,
    ) {
      const selectedReturnIdx = state.returnTypes.findIndex(
        (returnType) => returnType.returnTypeId === params.payload.returnTypeId,
      );

      const selectedReturn = state.returnTypes[selectedReturnIdx];

      let itemConditionNames = Object.values(state.itemOptions).map(
        (option) => option.name,
      );

      const conditions = selectedReturn.conditions;
      if (
        conditions.filter(
          (condition) => !itemConditionNames.includes(condition.variableName),
        ).length <= 0
      ) {
        selectedReturn.error = "Atleast add one condition required";
      }

      if (
        selectedReturnIdx !== 0 &&
        conditions.filter((condition) =>
          itemConditionNames.includes(condition.variableName),
        ).length <= 0
      ) {
        selectedReturn.itemError = "Atleast add one condition required";
      }

      // Validating conditions using helper
      const {
        updatedConditions,
        isAddOrderConditionEnabled,
        isAddItemConditionEnabled,
      } = validateConditionsHelper({
        selectedTab: selectedReturn,
        step: "step11",
      });

      state.returnTypes[selectedReturnIdx].conditions = updatedConditions;
      if (isAddOrderConditionEnabled !== null) {
        state.returnTypes[selectedReturnIdx].isAddConditionEnabled =
          isAddOrderConditionEnabled;
      }

      if (isAddItemConditionEnabled !== null) {
        state.returnTypes[selectedReturnIdx].isAddItemConditionEnabled =
          isAddItemConditionEnabled;
      }

      if (
        selectedReturn.messageForCustomer.value !== undefined &&
        selectedReturn.messageForCustomer.value.length <= 0
      ) {
        selectedReturn.messageForCustomer.error = "*Please fill out this field";
      }

      const errors = conditions
        .flatMap((condition) => {
          return Object.entries(condition.values).flatMap(
            ([key, value]: [any, any]) => {
              if (value.error === undefined) return null;
              return value.error;
            },
          );
        })
        .concat([
          selectedReturn.messageForCustomer.error,
          selectedReturn.error,
          selectedReturn.itemError,
        ]);
      selectedReturn.errorCount = errors.reduce((p: number, c: null) => {
        if (c !== null && c !== undefined) {
          return p + 1;
        }
        return p;
      }, 0);

      if (selectedReturn.errorCount > 0) {
        selectedReturn.isValid = false;
      } else {
        selectedReturn.isValid = true;
      }
    },

    validateStep11(state: step11Config) {
      let itemConditionNames = Object.values(state.itemOptions).map(
        (option) => option.name,
      );

      state.returnTypes.forEach((selectedReturn, selectedReturnIdx) => {
        const conditions = selectedReturn.conditions;
        if (
          conditions.filter(
            (condition) => !itemConditionNames.includes(condition.variableName),
          ).length <= 0
        ) {
          selectedReturn.error = "Atleast add one condition required";
        }

        if (
          selectedReturnIdx !== 0 &&
          conditions.filter((condition) =>
            itemConditionNames.includes(condition.variableName),
          ).length <= 0
        ) {
          selectedReturn.itemError = "Atleast add one condition required";
        }

        // Validating conditions using helper
        const {
          updatedConditions,
          isAddOrderConditionEnabled,
          isAddItemConditionEnabled,
        } = validateConditionsHelper({
          selectedTab: selectedReturn,
          step: "step11",
        });

        state.returnTypes[selectedReturnIdx].conditions = updatedConditions;
        if (isAddOrderConditionEnabled !== null) {
          state.returnTypes[selectedReturnIdx].isAddConditionEnabled =
            isAddOrderConditionEnabled;
        }

        if (isAddItemConditionEnabled !== null) {
          state.returnTypes[selectedReturnIdx].isAddItemConditionEnabled =
            isAddItemConditionEnabled;
        }

        if (
          selectedReturn.messageForCustomer.value !== undefined &&
          selectedReturn.messageForCustomer.value.length <= 0
        ) {
          selectedReturn.messageForCustomer.error =
            "*please fill out this field";
        }

        const errors = conditions
          .flatMap(
            (condition: {
              values: { [s: string]: unknown } | ArrayLike<unknown>;
            }) => {
              return Object.entries(condition.values).flatMap(
                ([key, value]: [any, any]) => {
                  if (value.error === undefined) return null;
                  return value.error;
                },
              );
            },
          )
          .concat([
            selectedReturn.messageForCustomer.error,
            selectedReturn.error,
            selectedReturn.itemError,
          ]);
        selectedReturn.errorCount = errors.reduce((p: number, c: null) => {
          if (c !== null && c !== undefined) {
            return p + 1;
          }
          return p;
        }, 0);

        if (selectedReturn.errorCount > 0) {
          selectedReturn.isValid = false;
        } else {
          selectedReturn.isValid = true;
        }
      });
    },

    toggleReturnWithSameItem(
      state: step11Config,
      params: PayloadAction<{
        returnTypeId: string;
        value: boolean;
      }>,
    ) {
      // if (params.payload.returnTypeId === "1") {
      //   state.allOrderAndItem.ExchangeWithSameItem = params.payload.value;
      // } else {

      const selectedReturnIdx = state.returnTypes.findIndex(
        (returnType) => returnType.returnTypeId === params.payload.returnTypeId,
      );

      const selectedReturn = state.returnTypes[selectedReturnIdx];

      selectedReturn.ExchangeWithSameItem = params.payload.value;
      // }
    },

    toggleReturnWithOtherItem(
      state: step11Config,
      params: PayloadAction<{
        returnTypeId: string;
        value: boolean;
      }>,
    ) {
      // if (params.payload.returnTypeId === "1") {
      //   state.allOrderAndItem.ExchangeWithOtherItem = params.payload.value;
      // } else {
      const selectedReturnIdx = state.returnTypes.findIndex(
        (returnType) => returnType.returnTypeId === params.payload.returnTypeId,
      );

      const selectedReturn = state.returnTypes[selectedReturnIdx];

      selectedReturn.ExchangeWithOtherItem = params.payload.value;
      // }
    },

    toggleRefundtoStoreCredit(
      state: step11Config,
      params: PayloadAction<{
        returnTypeId: string;
        value: boolean;
      }>,
    ) {
      // if (params.payload.returnTypeId === "1") {
      //   state.allOrderAndItem.RefundtoStoreCredit = params.payload.value;
      // } else {
      const selectedReturnIdx = state.returnTypes.findIndex(
        (returnType) => returnType.returnTypeId === params.payload.returnTypeId,
      );

      const selectedReturn = state.returnTypes[selectedReturnIdx];

      selectedReturn.RefundtoStoreCredit = params.payload.value;
      // }
    },

    toggleRefundtoOriginalPayment(
      state: step11Config,
      params: PayloadAction<{
        returnTypeId: string;
        value: boolean;
      }>,
    ) {
      // if (params.payload.returnTypeId === "1") {
      //   state.allOrderAndItem.RefundToOriginalPayment = params.payload.value;
      // }
      //  else {
      const selectedReturnIdx = state.returnTypes.findIndex(
        (returnType) => returnType.returnTypeId === params.payload.returnTypeId,
      );

      const selectedReturn = state.returnTypes[selectedReturnIdx];

      selectedReturn.RefundToOriginalPayment = params.payload.value;
      // }
    },
    //Reset the step 11 configuration to the initial state
    resetStep11Config: (state: step11Config) => {
      return initialState;
    },
  },
  extraReducers: step11Builders,
});

export default step11ConfigSlice.reducer;

export const step11ConfigErrorCountSelector = createSelector(
  [step11ConfigErrorCount],
  (step11ConfigErrorCount) => step11ConfigErrorCount,
);

export const {
  // updateAllOrderAndItemType,
  addReturnType,
  updateReturnType,
  deleteReturnType,
  addCondition,
  updateCondition,
  deleteCondition,
  setSelectedReturnTypeId,
  validateReturnType,
  validateCondition,
  toggleReturnWithSameItem,
  toggleReturnWithOtherItem,
  toggleRefundtoStoreCredit,
  toggleRefundtoOriginalPayment,
  pushInitialHash,
  validateChanges,
  pushCurrentHashForSelectedTab,
  undoConfig,
  resetHashes,
  validateStep11,
  resetStep11Config,
} = step11ConfigSlice.actions;
