import { useEffect, useRef, useState } from "react";
import styles from "./AssignTo.module.scss";
import log from "loglevel";
import CenterModal from "src/components/CenterModal/CenterModal";
import BaseButton from "../commons/BaseButton/BaseButton";
import { useAppDispatch, useAppSelector } from "src/store/store";
import UserListItem from "./children/UserListItem";
import {
  fetchAllUsers,
  resetBulkAssignTickets,
  User,
} from "src/store/slices/ticketUsers/ticketUsers.slice";
import AddTagsSuccess from "./children/AssignToSuccess/AddTagsSuccess";
import useResetBulkActions from "src/hooks/useResetBulkActions";
import SelectedUserBtn from "./children/SelectedUserBtn/SelectedUserBtn";
import ModalFooter from "./children/ModalFooter";
import Loader from "src/components/Loader";

function AssignTo() {
  const [modalShow, setModalShow] = useState(false);
  const allUsers = useAppSelector((state) => state.ticketUsers.allUsers);
  const fetchAllUsersState = useAppSelector(
    (state) => state.ticketUsers.fetchAllUsersState
  );
  const bulkAssignTicketsState = useAppSelector(
    (state) => state.ticketUsers.bulkAssignTicketsState
  );
  const bulkAssignTicketsStateRef = useRef(bulkAssignTicketsState);

  useEffect(() => {
    bulkAssignTicketsStateRef.current = bulkAssignTicketsState;
  }, [bulkAssignTicketsState]);
  const dispatch = useAppDispatch();
  const resetBulkActions = useResetBulkActions();
  //  useEffect(() => () => resetBulkActions(), []);

  const handleOnClick = () => {
    setModalShow(true);
    // log.debug("clicked bulk assign tickets button");
    dispatch(resetBulkAssignTickets());
    dispatch(fetchAllUsers());
  };

  const handleOnHideModal = () => {
    setModalShow(false);
    if (bulkAssignTicketsStateRef.current != "idle") {
      resetBulkActions(); //resetting bulk action filters, refetching the tickets, as new action has been performed
    }
  };

  return (
    <>
      {bulkAssignTicketsState === "fulfilled" ? (
        <SelectedUserBtn BtnAction={handleOnClick} />
      ) : (
        <BaseButton
          FAIcon={"fas fa-plus"}
          BtnText={"Assign to"}
          BtnAction={handleOnClick}
          Selected={modalShow}
          id="assignToBtn"
        />
      )}
      <CenterModal
        show={modalShow}
        onHide={handleOnHideModal}
        id="assignToModal"
        customTitleComp={
          bulkAssignTicketsState === "fulfilled" ? undefined : CustomTitleComp
        }
        bodyClassName={`py-0`}
        customFooterComp={fetchAllUsersState !== "pending" && ModalFooter}
      >
        {/* {false && ( */}
        {bulkAssignTicketsState === "idle" && (
          <div className={`${styles.userListContainer}`} id="userListContainer">
            {allUsers.map((user: User) => {
              if ((user.status as any) == "active") {
                return <UserListItem key={user.id} user={user} />;
              }
            })}
          </div>
        )}

        {(bulkAssignTicketsState === "pending" ||
          fetchAllUsersState === "pending") && (
          <Loader style={{ marginTop: "-90px" }} />
        )}

        {bulkAssignTicketsState === "fulfilled" && <AddTagsSuccess />}
      </CenterModal>
    </>
  );
}

const CustomTitleComp = () => (
  <h5 className={`${styles["addTag_Title"]}`}>Assign to</h5>
);

const loaderStyles = { style: { marginTop: "-90px" } };

export default AssignTo;
