/**
 * This component represents a dropdown for selecting operators. It utilizes the CustomSelectDropdown component
 * for rendering the dropdown in audience configure form
 *
 * @author @yuvaraj-busibud
 */
import React, { useRef, useState } from "react";
import styles from "./ContainDropDown.module.scss";
import { Dropdown } from "react-bootstrap";
import { ActionMeta, SingleValue } from "react-select";
import Select from "react-select";
import CustomSelectDropdown from "../CustomSelectDropdown/CustomSelectDropdown";
interface Option {
  value: string | number;
  label: string;
}
interface Props {
  options: Option[];
  selectedOption: Option | undefined;
  setSelectedOption: (value: Option) => void;
}
// Dropdown component for selecting operators.
const ContainDropDown = ({
  options,
  selectedOption,
  setSelectedOption,
}: Props) => {
  // const options = [
  //   { value: "is", label: "is" },
  //   { value: "is not", label: "is not" },
  //   { value: "contains", label: "contains" },
  //   { value: "does not contain", label: "does not contain" },
  // ];

  return (
    <div>
      <CustomSelectDropdown
        selectedOption={selectedOption}
        setSelectedOption={(value) => {
          if (Array.isArray(value)) {
            setSelectedOption(value[0]);
          } else {
            setSelectedOption(value);
          }
        }}
        options={options}
        menuClassName={`mb-1 me-2 ${styles.containDrop}`}
      />
    </div>
  );
};

export default ContainDropDown;
