import React, { useState } from "react";
import styles from "./ShowTemplate.module.scss";
import ParseHTML from "src/components/ParseHTML";
import { highlightSearchedText } from "src/routes/TicketList/children/OuterTickets/Chidren/SearchedTickets/SearchedTickets";
import { NavLink } from "react-router-dom";

interface Props {
  template: any;
  display: string;
  setSelectedTemplateData: any;
  onTemplateSelect: any;
  searchText?: string;
}

const ShowTemplate = ({
  template,
  display,
  searchText,
  setSelectedTemplateData = (template: any) => {},
  onTemplateSelect = (template: string) => {},
}: Props) => {
  const [show, setShow] = useState(false);
  return (
    <div
      className={`mb-2 ${styles.templateWrapper}`}
      onMouseEnter={() => {
        setShow(true);
      }}
      onMouseLeave={() => {
        setShow(false);
      }}
      id={template.templateHeading.replace(" ", "_")}
    >
      <p className={`mb-0 ${styles.heading} ${show ? styles.colChange : ""}`}>
        {template.templateHeading.length ? template.templateHeading : ""}
      </p>

      <div className={`d-flex justify-content-between align-items-start`}>
        <p className={`mb-0 ${styles.info} ${show ? "" : styles.ellipsis}`}>
          {/* {template.template_body} */}
          {searchText && searchText?.trim() ? (
            <ParseHTML
              className={``}
              html={
                highlightSearchedText(
                  template.template_body,
                  searchText,
                  true,
                  styles.searchHighlight
                ) as string
              }
            />
          ) : (
            <ParseHTML className={``} html={template.template_body} />
          )}
        </p>
        <div className="d-flex align-items-center">
          <button
            className={`btn me-2 ${styles.insertbtn}`}
            onClick={() => {
              setSelectedTemplateData(template);
              onTemplateSelect(template.template_body);
            }}
          >
            + Insert
          </button>
          <span className={`cursor-pointer ${show ? styles.colChange : ""}`}>
            {show ? (
              <i className="fa-solid fa-angle-up"></i>
            ) : (
              <i className="fa-solid fa-angle-down"></i>
            )}
          </span>
        </div>
      </div>
      <div className={display}>
        <p className="mb-0">
          <span className={`${styles.catHead}`}>Category: </span>
          <span className={`${styles.refund}`}>{template.categoryName} </span>
          {template.previouslyUsedTicketId && (
            <>
              <span className={`${styles.prevData}`}>
                Previously Used In{" "}
                <span className={`${styles.ticketNum}`}>
                  <NavLink
                    target={"_blank"}
                    to={`/tickets/all/ticket-view/ticket/${template.previouslyUsedTicketId}`}
                  >
                    Ticket {template.previouslyUsedTicketId}
                  </NavLink>
                </span>
              </span>
            </>
          )}
        </p>
      </div>
    </div>
  );
};

export default ShowTemplate;
