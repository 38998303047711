import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import objectHash from "object-hash";
import { AJAXSTATUS } from "src/globals/constants";
import { ProductDetail } from "src/services/UiAutomation/ReturnExchange/Configuration/General/getAllShopifyProducts";
import { Step10Config } from "src/services/UiAutomation/ReturnExchange/Configuration/Step10/step10GetConfig";
import { conditionType } from "src/services/UiAutomation/ReturnExchange/Configuration/Step9/step9GetOptionsConditions";
import {
  addConditionHandler,
  ConditionTypes,
  createNewObjectHash,
  deleteConditionHelper,
  deleteTabHelper,
  RecursivePartial,
  updateConditionHelper,
  validateChangeHandler,
  validateConditionsHelper,
} from "src/store/slices/initiateReturn/Helpers/returnExchange.helpers";
import { store } from "src/store/store";
import { v4 as uuid } from "uuid";
import step10Builders from "./Builders/step10.builder";
import { step10ConfigErrorCount } from "./returnExchange.selectors";
export interface ItemTypeDetails {
  itemTypeId: string;
  itemTypeName: string | null | undefined;
  // itemSequence: number;
  conditions: ConditionTypes[];
  messageForCustomer: {
    value: string | undefined;
    error: string | null;
  };
  error: string | null;
  itemError: string | null;
  isAddConditionEnabled: boolean;
  isAddItemConditionEnabled: boolean;
  errorCount: number;
  isValid: boolean;
}

export interface step10Configuration {
  selectedItemTypeId: string | null;
  itemTypes: ItemTypeDetails[];
  initialHash: string;
  currentHash: string;
  excludeKeysForHash: string[];
  isChanged: boolean;
  intialStep10Config: ItemTypeDetails[] | null;
  selectedTabIntialHash: string | null;
  selectedTabCurrentHash: string | null;
  error: string | null;

  conditionOptions: conditionType[];
  defaultOptions: conditionType[];
  itemOptions: conditionType[];
  getItemType: Step10Config;

  variableNames: any;
  operatorsNames: any;
  operatorSymbolMap: any;
  valueTypes: any;
  variableNameToValueType: any;
  fetchAjaxStatus: AJAXSTATUS;
  updateAjaxStatus: AJAXSTATUS;
}

const excludeKeys = [
  "error",
  "itemError",
  "isAddConditionEnabled",
  "isAddItemConditionEnabled",
  "isValid",
  "errorCount",
];

const firstItemId = uuid();

export const initialState: step10Configuration = {
  selectedItemTypeId: firstItemId,
  itemTypes: [
    {
      itemTypeId: firstItemId,
      itemTypeName: "Item Type 1",
      conditions: [],
      messageForCustomer: { value: "", error: null },
      error: null,
      itemError: null,
      isAddConditionEnabled: true,
      isAddItemConditionEnabled: true,
      errorCount: 0,
      isValid: false,
    },
  ],
  selectedTabIntialHash: "",
  selectedTabCurrentHash: "",
  error: null,
  initialHash: "",
  currentHash: "",
  excludeKeysForHash: excludeKeys,
  isChanged: false,
  intialStep10Config: null,

  conditionOptions: [],
  itemOptions: [],
  defaultOptions: [],
  getItemType: {
    data: [],
  },
  variableNames: [],
  operatorsNames: {},
  operatorSymbolMap: {},
  valueTypes: {},
  variableNameToValueType: {},
  fetchAjaxStatus: "pending",
  updateAjaxStatus: "idle",
};

export function productIdToDetails(conditionValue: any) {
  const returnExchange = store.getState().returnExchange;
  const value = conditionValue.map((productId: string) => {
    const Id = returnExchange.getAllShopifyProducts.find(
      (product) => product.productId === Number(productId),
    )?.productId;
    const imgUrl = returnExchange.getAllShopifyProducts.find(
      (product) => product.productId === Number(productId),
    )?.imageUrl;
    const productName = returnExchange.getAllShopifyProducts.find(
      (product) => product.productId === Number(productId),
    )?.productName;

    const productPrice = returnExchange.getAllShopifyProducts.find(
      (product) => product.productId === Number(productId),
    )?.productPrice;
    const productStock = returnExchange.getAllShopifyProducts.find(
      (product) => product.productId === Number(productId),
    )?.productStock;
    const productSku = returnExchange.getAllShopifyProducts.find(
      (product) => product.productId === Number(productId),
    )?.productSku;
    const variants = returnExchange.getAllShopifyProducts.find(
      (product) => product.productId === Number(productId),
    )?.variants;

    return {
      imageUrl: imgUrl,
      productId: Id,
      productName,
      productPrice,
      productSku,
      productStock,
      variants,
    } as ProductDetail;
  });

  return value;
}

export const step10ConfigurationSlice = createSlice({
  name: "step10Configuration",
  initialState,
  reducers: {
    // Add Item Type
    addItemType: (state: step10Configuration) => {
      const newItemTypeId = uuid();

      state.selectedItemTypeId = newItemTypeId;

      state.itemTypes.push({
        itemTypeId: newItemTypeId,
        itemTypeName: `Item Type ${state.itemTypes.length + 1}`,
        conditions: [],
        // itemSequence: state.itemTypes.length + 1,
        messageForCustomer: { value: "", error: null },
        error: "",
        itemError: null,
        isAddConditionEnabled: true,
        isAddItemConditionEnabled: true,
        errorCount: 0,
        isValid: false,
      });
    },

    // Update Item Type
    updateItemType(
      state: step10Configuration,
      params: PayloadAction<{
        itemTypeDetail: RecursivePartial<ItemTypeDetails>;
      }>,
    ) {
      const { itemTypeId, messageForCustomer, itemTypeName } =
        params.payload.itemTypeDetail;

      const selectedIdx = state.itemTypes.findIndex(
        (item) => item.itemTypeId === itemTypeId,
      );
      const selectedItem = state.itemTypes[selectedIdx];

      selectedItem.messageForCustomer.value =
        messageForCustomer?.value ?? selectedItem.messageForCustomer.value;
      selectedItem.messageForCustomer.error =
        messageForCustomer?.error ?? selectedItem.messageForCustomer.error;

      if (itemTypeName) selectedItem.itemTypeName = itemTypeName;
    },

    // Delete Item Type
    deleteItemType: (state, { payload }: { payload: { id: string } }) => {
      const { updatedTabs, newSelectedTabId } = deleteTabHelper({
        tabs: state.itemTypes,
        tabId: payload.id,
        selectedTabId: state.selectedItemTypeId,
        idKey: "itemTypeId", // Specify the relevant key name here
      });

      state.itemTypes = updatedTabs;
      state.selectedItemTypeId = newSelectedTabId;
    },

    pushInitialHash: (state) => {
      const hash = createNewObjectHash({
        excludeKeys: state.excludeKeysForHash,
        hashObject: state.itemTypes,
      });
      if (hash) {
        state.initialHash = hash;
        state.currentHash = hash;
      }
    },

    pushCurrentHashForSelectedTab: (
      state,
      params: PayloadAction<{ itemTypeId: string | null }>,
    ) => {
      const selectedItemIdx = state.itemTypes.findIndex(
        (itemType) => itemType.itemTypeId === params.payload.itemTypeId,
      );

      state.selectedTabCurrentHash = objectHash(
        state.itemTypes[selectedItemIdx],
      );
    },

    validateChanges: (state) => {
      const initialHash = state.initialHash;

      const { isChanged, newHash } = validateChangeHandler({
        stateObject: state.itemTypes,
        excludeKeys: state.excludeKeysForHash,
        initialHash,
      });

      if (newHash) {
        state.isChanged = isChanged;
        state.currentHash = newHash;
      }
    },

    resetHashes: (state) => {
      state.initialHash = "";
      state.currentHash = "";
    },

    undoConfig: (
      state,
      params: PayloadAction<{ initialConfig: ItemTypeDetails[] | null }>,
    ) => {
      if (params.payload.initialConfig !== null)
        state.itemTypes = params.payload.initialConfig;
    },

    addCondition: (
      state,
      params: PayloadAction<{
        itemTypeId: string;
        condition: string;
        isItemCondition?: boolean;
      }>,
    ) => {
      let selectedItem = state.itemTypes.find(
        (itemType) => itemType.itemTypeId === params.payload.itemTypeId,
      );

      const selectedTab = addConditionHandler({
        selectedTab: selectedItem,
        conditionOptions: params.payload?.isItemCondition
          ? state.itemOptions
          : state.conditionOptions,
        payloadCondition: params.payload.condition,
        isItemCondition: params.payload?.isItemCondition,
      });

      selectedItem = selectedTab;
    },

    // Update Conditon
    updateCondition(
      state,
      params: PayloadAction<{
        itemTypeId: string;
        conditionToUpdate: RecursivePartial<ConditionTypes>;
      }>,
    ) {
      const { itemTypeId, conditionToUpdate } = params.payload;

      const selectedItemIdx = state.itemTypes.findIndex(
        (itemType) => itemType.itemTypeId === itemTypeId,
      );

      if (selectedItemIdx !== -1 && conditionToUpdate) {
        const selectedItem = state.itemTypes[selectedItemIdx];
        let defaultConditionNames = Object.values(state.defaultOptions).map(
          (option) => option.name,
        );

        const conditions = selectedItem.conditions.filter(
          (condition) =>
            !defaultConditionNames.includes(condition.variableName),
        );

        const {
          updatedConditions,
          isAddOrderConditionEnabled,
          isAddItemConditionEnabled,
        } = updateConditionHelper({
          conditions,
          conditionToUpdate,
        });

        state.itemTypes[selectedItemIdx].conditions = updatedConditions;

        if (isAddOrderConditionEnabled !== null) {
          state.itemTypes[selectedItemIdx].isAddConditionEnabled =
            isAddOrderConditionEnabled;
        }

        if (isAddItemConditionEnabled !== null) {
          state.itemTypes[selectedItemIdx].isAddItemConditionEnabled =
            isAddItemConditionEnabled;
        }
      }
    },

    deleteCondition: (
      state: step10Configuration,
      params: PayloadAction<{
        itemTypeId: string;
        conditionId: string;
        isItemCondition?: boolean;
      }>,
    ) => {
      const { itemTypeId, conditionId } = params.payload;

      const selectedItemIdx = state.itemTypes.findIndex(
        (itemType) => itemType.itemTypeId === itemTypeId,
      );
      if (selectedItemIdx !== -1) {
        const selectedItemTypes = state.itemTypes[selectedItemIdx];
        const { updatedConditions, isAddOrderConditionEnabled } =
          deleteConditionHelper({
            conditionId: conditionId,
            conditions: selectedItemTypes.conditions,
            conditionType: "order",
          });

        state.itemTypes[selectedItemIdx].conditions = updatedConditions;
        if (isAddOrderConditionEnabled !== null) {
          state.itemTypes[selectedItemIdx].isAddConditionEnabled =
            isAddOrderConditionEnabled;
        }
      }
    },

    setSelectedItemTypeId(
      state: step10Configuration,
      params: PayloadAction<{ itemTypeId: string }>,
    ) {
      state.selectedItemTypeId = params.payload.itemTypeId;
    },

    validateItemType(
      state: step10Configuration,
      params: PayloadAction<{
        itemTypeId: string;
      }>,
    ) {
      const selectedItemIdx = state.itemTypes.findIndex(
        (itemType) => itemType.itemTypeId === params.payload.itemTypeId,
      );

      const messageForCustomer =
        state.itemTypes[selectedItemIdx].messageForCustomer;
      if (messageForCustomer.value === "") {
        messageForCustomer.error = "*Please fill out this field";
      } else {
        messageForCustomer.error = null;
      }
    },

    validateCondition(
      state: step10Configuration,
      params: PayloadAction<{
        itemTypeId: string;
        isInitial?: boolean;
      }>,
    ) {
      const { isInitial = false } = params.payload;

      const selectedItemIdx = state.itemTypes.findIndex(
        (itemType) => itemType.itemTypeId === params.payload.itemTypeId,
      );

      const selectedItem = state.itemTypes[selectedItemIdx];

      let itemConditionNames = Object.values(state.itemOptions).map(
        (option) => option.name,
      );

      const conditions = selectedItem.conditions;
      if (!isInitial) {
        if (
          conditions.filter(
            (condition) => !itemConditionNames.includes(condition.variableName),
          ).length <= 0
        ) {
          selectedItem.error = "Atleast add one condition required";
        }
      }

      if (
        conditions.filter((condition) =>
          itemConditionNames.includes(condition.variableName),
        ).length <= 0
      ) {
        selectedItem.itemError = "Atleast add one condition required";
      }

      // Validating conditions using helper
      const {
        updatedConditions,
        isAddOrderConditionEnabled,
        isAddItemConditionEnabled,
      } = validateConditionsHelper({
        selectedTab: selectedItem,
        step: "step10",
      });

      state.itemTypes[selectedItemIdx].conditions = updatedConditions;
      if (isAddOrderConditionEnabled !== null) {
        state.itemTypes[selectedItemIdx].isAddConditionEnabled =
          isAddOrderConditionEnabled;
      }

      if (isAddItemConditionEnabled !== null) {
        state.itemTypes[selectedItemIdx].isAddItemConditionEnabled =
          isAddItemConditionEnabled;
      }

      if (
        selectedItem.messageForCustomer.value !== undefined &&
        selectedItem.messageForCustomer.value.length <= 0
      ) {
        selectedItem.messageForCustomer.error = "*Please fill out this field";
      }

      const errors = conditions
        .flatMap((condition) => {
          return Object.entries(condition.values).flatMap(
            ([key, value]: [any, any]) => {
              if (value.error === undefined) return null;
              return value.error;
            },
          );
        })
        .concat([
          selectedItem.messageForCustomer.error,
          selectedItem.error,
          selectedItem.itemError,
        ]);

      selectedItem.errorCount = errors.reduce((p, c) => {
        if (c !== null && c !== undefined) {
          return p + 1;
        }
        return p;
      }, 0);

      if (selectedItem.errorCount > 0) {
        selectedItem.isValid = false;
      } else {
        selectedItem.isValid = true;
      }
    },
    validateStep10(state: step10Configuration) {
      let itemConditionNames = Object.values(state.itemOptions).map(
        (option) => option.name,
      );

      state.itemTypes.forEach((selectedItem, selectedItemIdx) => {
        const conditions = selectedItem.conditions;

        if (
          conditions.filter(
            (condition) => !itemConditionNames.includes(condition.variableName),
          ).length <= 0
        ) {
          selectedItem.error = "Atleast add one condition required";
        }

        if (
          conditions.filter((condition) =>
            itemConditionNames.includes(condition.variableName),
          ).length <= 0
        ) {
          selectedItem.itemError = "Atleast add one condition required";
        }

        // Validating conditions using helper
        const {
          updatedConditions,
          isAddOrderConditionEnabled,
          isAddItemConditionEnabled,
        } = validateConditionsHelper({
          selectedTab: selectedItem,
          step: "step10",
        });

        state.itemTypes[selectedItemIdx].conditions = updatedConditions;
        if (isAddOrderConditionEnabled !== null) {
          state.itemTypes[selectedItemIdx].isAddConditionEnabled =
            isAddOrderConditionEnabled;
        }

        if (isAddItemConditionEnabled !== null) {
          state.itemTypes[selectedItemIdx].isAddItemConditionEnabled =
            isAddItemConditionEnabled;
        }

        if (
          selectedItem.messageForCustomer.value !== undefined &&
          selectedItem.messageForCustomer.value.length <= 0
        ) {
          selectedItem.messageForCustomer.error = "*Please fill out this field";
        }

        const errors = conditions
          .flatMap((condition) => {
            return Object.entries(condition.values).flatMap(
              ([key, value]: [any, any]) => {
                if (value.error === undefined) return null;
                return value.error;
              },
            );
          })
          .concat([
            selectedItem.messageForCustomer.error,
            selectedItem.error,
            selectedItem.itemError,
          ]);

        selectedItem.errorCount = errors.reduce((p, c) => {
          if (c !== null && c !== undefined) {
            return p + 1;
          }
          return p;
        }, 0);

        if (selectedItem.errorCount > 0) {
          selectedItem.isValid = false;
        } else {
          selectedItem.isValid = true;
        }
      });
    },
    //Reset the step 10 configuration to the initial state
    resetStep10Config: (state: step10Configuration) => {
      return initialState;
    },
  },
  extraReducers: step10Builders,
});

export default step10ConfigurationSlice.reducer;

export const {
  addItemType,
  updateItemType,
  deleteItemType,
  addCondition,
  updateCondition,
  deleteCondition,
  setSelectedItemTypeId,
  validateItemType,
  validateCondition,
  pushInitialHash,
  validateChanges,
  undoConfig,
  resetHashes,
  pushCurrentHashForSelectedTab,
  validateStep10,
  resetStep10Config,
} = step10ConfigurationSlice.actions;

export const step10ConfigErrorCountSelector = createSelector(
  [step10ConfigErrorCount],
  (step10ConfigErrorCount) => step10ConfigErrorCount,
);
