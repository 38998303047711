import { useCallback, useEffect, useMemo, useState } from "react";
import styles from "./FinalChat.module.scss";
import hifi from "src/assets/images/chat/hiThere.png";
import search from "src/assets/images/chat/search.png";
import chatImg1 from "src/assets/images/chat/1.png";
import chatImg2 from "src/assets/images/chat/2.png";
import chatImg3 from "src/assets/images/chat/3.png";
import defaultUserImg from "src/assets/images/chat/defaultUser.png";
import prevIcon from "src/assets/images/chat/prev.png";
import send from "src/assets/images/chat/send.png";
import cube from "src/assets/images/chat/cube.png";
import refresh from "src/assets/images/chat/refreshButton.png";
import issue from "src/assets/images/chat/issueIcon.png";
import logo from "src/assets/images/chat/bLogo.png";
import robotImg from "src/assets/images/chat/robot.png";
import threeDotsImg from "src/assets/images/chat/three dots.png";
import rightArrow from "src/assets/images/chat/rightArrow.png";
import mailIcon from "src/assets/images/chat/mailIcon.png";
import pinkToggle from "src/assets/images/chat/pinkToggle.png";
import blackToggle from "src/assets/images/chat/blackToggle.png";
import chatRatingImg from "src/assets/images/chatRating.png";
import smileyImg from "src/assets/images/chat/smiley.png";
import blackSendImg from "src/assets/images/chat/black-send.png";
import { useAppDispatch } from "src/store/store";
import ParseHTML from "src/components/ParseHTML";
import ChoiceList from "../../../Forms/FormWidgetElements/ChoiceList/ChoiceList";
import ChatRating from "../../../Forms/FormWidgetElements/ChatRating/ChatRating";
import TextBox from "../../../Forms/FormWidgetElements/TextBox/TextBox";
import MultipleChoiceList from "../../../Forms/FormWidgetElements/MultipleChoiceList/MultipleChoiceList";
import DropDown from "../../../Forms/FormWidgetElements/DropDown/DropDown";
import Information from "../../../Forms/FormWidgetElements/Information/Information";
import LongTextBox from "../../../Forms/FormWidgetElements/LongTextBox/LongTextBox";
import { v4 as uuid } from "uuid";
import hii from "src/assets/images/hifi.gif";
import { IFeaturedArticlesData } from "src/services/LiveChat/Settings/knowledgeBased/featuredArticles/getAllFeaturedArticles";
import useFinalChat from "./useFinalChat";
import { getIntegrationDetails } from "src/services/LiveChat/Settings/website/getWebIntegrations";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import saufterLogo from "src/assets/images/saufter-logo.svg";

function BackgroundCurveImage(color: string) {
  const data = `<svg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio="none" style='fill:${color};' width='388' height='125.336' viewBox='0 0 388 125.336'><g id='Group_3645' data-name='Group 3645' transform='translate(-173 -114)'><path id='Rectangle_1587' data-name='Rectangle 1587' d='M8,0H380a8,8,0,0,1,8,8V87a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V8A8,8,0,0,1,8,0Z' transform='translate(173 114)'/><path id='Path_279' data-name='Path 279' d='M2846,1376.778s4.775,9.84,48.863,23.086,133.959-.757,148.373-3.028S3234,1368.544,3234,1368.544v-5.391H2846Z' transform='translate(-2673 -1166.152)'/></g></svg>`;
  return `data:image/svg+xml;base64,${window.btoa(data)}`;
}

function ToggleIconClosedURL(color: string) {
  const data = `<svg xmlns="http://www.w3.org/2000/svg" width="55" height="55"><defs><filter id="prefix__a" x="0" y="0" width="55" height="55" filterUnits="userSpaceOnUse"><feOffset dy="3"/><feGaussianBlur stdDeviation="3" result="blur"/><feFlood flood-opacity=".161"/><feComposite operator="in" in2="blur"/><feComposite in="SourceGraphic"/></filter></defs><g data-name="Group 3646"><g filter="url(#prefix__a)"><path data-name="Rectangle 1486" d="M27.5 6A18.5 18.5 0 0146 24.5 18.5 18.5 0 0127.5 43H9V24.5A18.5 18.5 0 0127.5 6z" fill="${color}"/></g><path data-name="Icon feather-message-square" d="M35.325 28.884a1.814 1.814 0 01-1.813 1.816H22.628L19 34.325V19.814A1.814 1.814 0 0120.814 18h12.7a1.814 1.814 0 011.814 1.814z" fill="#fff"/></g></svg>`;
  return `data:image/svg+xml;base64,${window.btoa(data)}`;
}
function ToggleIconOpenURL(color: string) {
  const data = `<svg xmlns="http://www.w3.org/2000/svg" width="55" height="55"><g transform="translate(9,9)" data-name="Group 3646"><path fill="${color}" data-name="Rectangle 1589" d="M18.5 37A18.5 18.5 0 010 18.5 18.5 18.5 0 0118.5 0H37v18.5A18.5 18.5 0 0118.5 37z"/><path data-name="Icon metro-cross" d="M22.916 21.039l-3.036-3.036 3.036-3.031a.313.313 0 000-.442l-1.434-1.439a.314.314 0 00-.442 0l-3.036 3.036-3.036-3.036a.313.313 0 00-.442 0l-1.434 1.434a.313.313 0 000 .442l3.038 3.036-3.038 3.036a.313.313 0 000 .442l1.438 1.434a.313.313 0 00.442 0l3.032-3.035 3.036 3.036a.313.313 0 00.442 0l1.434-1.434a.313.313 0 000-.442z" fill="#fff"/></g></svg>`;
  return `data:image/svg+xml;base64,${window.btoa(data)}`;
}

const FormWidgetElements: {
  [key: string]: { component: any };
} = {
  email: { component: TextBox },
  text: { component: TextBox },
  longText: { component: LongTextBox },
  choiceList: { component: ChoiceList },
  multipleChoiceList: { component: MultipleChoiceList },
  dropDown: { component: DropDown },
  chatRating: { component: ChatRating },
  information: { component: Information },
};

interface Props {
  customizationData?: {
    topbarHeadingText: string;
    topbarSubHeadingText: string;
    statusTextWhenTeamOnline: {
      readonly: string;
      value: string;
    };
    statusTextWhenTeamOffline: {
      readonly: string;
      value: string;
    };
    autoreplayText: string;
    minimizedWindowColour: string;
    liveChatThemeColor: string;
    showOnlineStatusText: boolean;
    showAutoReplyText: boolean;
    selectedWidgetPosition: "left" | "right";
    submitButtonText?: string;
  };
  knowledgeBasedData?: any;
  askForEmailData?: {
    offlineMessageText?: string | null;
    offlineConfirmationText?: string | null;
    messageText?: string | null;
    confirmationText?: string | null;
    showOfflineData: boolean;
    canSendMessage?: boolean | null;
    canCustomerLeaveEmail?: boolean | null;
  };
  formData?: {
    elements: any;
    submitButtonText: string;
    formType?: string;
  };
  featuredArticles?: IFeaturedArticlesData["featuredArticles"];
  suggestedArticles?: Array<string>;
  show: {
    homePage?: boolean;
    askForEmail?: boolean;
    chatForm?: boolean;
    minimizedFeaturedArticles?: boolean;
    showSuggestedArticles?: boolean;
  };
  showStartChat?: boolean;
}

const FinalChat = ({
  customizationData,
  knowledgeBasedData,
  formData,
  askForEmailData,
  show,
  featuredArticles,
  suggestedArticles,
  showStartChat = true,
}: Props) => {
  const {
    customization,
    knowledgeBased,
    chatFormData,
    handleToggle,
    isActive,
    showConfirmationText,
    setShowConfirmationText,
    showOfflineConfirmationText,
    setShowOfflineConfirmationText,
    showSubmitedFormPreview,
    setShowSubmitedFormPreview,
    handleFormsSubmit,
    getPostChatFormCommentText,
    getArticleText,
    inputEmailForAskEmail,
    setInputEmailForAskEmail,
  } = useFinalChat({
    customizationData,
    askForEmailData,
    formData,
    knowledgeBasedData,
  });
  const [hideFeaturedArticles, setHideFeaturedArticles] = useState(false);
  const [widgetName, setWidgetName] = useState("Live Chat");
  const [brandingName, setBrandingName] = useState("Saufter"); //branding name to show powered by "helplama" or "saufter"
  const { integrationId } = useParams();
  //useQuery to get the store name from backend and showing the store name dynamically
  const { data } = useQuery(
    ["LiveChatSetting/IntegrationDetails", integrationId],
    async () => {
      const response = await getIntegrationDetails({
        integration_id: integrationId + "",
      });
      return response;
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      enabled: integrationId && integrationId != "" ? true : false,
    }
  );

  useEffect(() => {
    //updating the state with the latest store name
    if (data && data.website_name && data.website_name.trim()) {
      setWidgetName(data.website_name);
    }

    if (data?.domain_branding_name && data.domain_branding_name.trim()) {
      setBrandingName(data.domain_branding_name);
    }
  }, [data]);

  /**
   * Memoized function to determine the branding logo based on the branding name.
   * If the branding name includes "saufter" (case-insensitive), return saufterLogo; otherwise, return the default logo.
   *
   * @returns {string} The URL of the branding logo.
   */
  const brandingLogo = useMemo(() => {
    // Convert the branding name to lowercase for case-insensitive comparison
    if (brandingName.toLowerCase().includes("saufter")) {
      // Return saufterLogo if the branding name includes "saufter"
      return saufterLogo;
    }

    // Return the default logo if the branding name does not include "saufter"
    return logo;
  }, [brandingName]);

  return (
    <section
      className={`d-flex flex-column justify-content-end ${styles.finalChatPreviewTop} px-2`}
    >
      <div
        className={`d-flex flex-column justify-content-end ${styles.finalChatPreview}`}
      >
        <div
          className={`${
            isActive ? "d-flex" : "d-none"
          } flex-column justify-content-end ${styles.contentWrapper}`}
        >
          <div className={`${styles.contentTop}`}>
            {show.homePage === true ? (
              <>
                {/* -----------HOME PAGE----------- */}
                <div className={`${styles.card}`} id="chat1">
                  <div
                    className={`card-header d-flex flex-column p-3 text-white border-bottom-0`}
                    style={{
                      backgroundImage: `url(${BackgroundCurveImage(
                        customization?.liveChatThemeColor !== undefined
                          ? customization.liveChatThemeColor
                          : "#000000"
                      )})`,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      marginBottom: "14px",
                      backgroundPosition: "unset",
                      minHeight: "136px",
                      backgroundColor: "#fff",
                    }}
                  >
                    <p className="fw-bold hi-there hi-text mb-0 d-flex align-items-center justify-content-between">
                      <div>
                        <span className={`${styles.hi}`}>
                          {customization === null || customization === undefined
                            ? "Hi There!"
                            : customization.topbarHeadingText}
                        </span>
                        <img
                          className={`img-fluid ms-1 ${styles.hello}`}
                          src={hifi}
                        />
                      </div>
                      <div>
                        <span className={`${styles.moreOption}`}>
                          {" "}
                          <i className="d-none fa-solid fa-ellipsis-vertical"></i>{" "}
                        </span>
                      </div>
                    </p>
                    <i className="far fa-hand-heart"></i>
                    <p className={`acmeSupport`}>
                      {customization === null || customization === undefined
                        ? "Welcome to Acme support. How can we help you?"
                        : customization.topbarSubHeadingText}
                    </p>
                  </div>

                  <div
                    className={`mb-2 ${
                      knowledgeBased !== null && knowledgeBased !== undefined
                        ? ""
                        : "d-none"
                    } ${styles.cardBox}`}
                  >
                    <div
                      className={`search-content d-flex flex-column justify-content-start mb-1 p-2 p-md-3 ${styles.homeBox}`}
                    >
                      <p className="search-text">
                        <span className="search-related">
                          <img className={`me-1`} src={search} />
                        </span>
                        <span className={`${styles.searchPara}`}>
                          {knowledgeBased === null ||
                          knowledgeBased === undefined
                            ? "Search related queries"
                            : knowledgeBased.chatWidgetTitle}
                        </span>
                      </p>
                      <p className={`${styles.searchBox}`}>
                        <input
                          className="w-100 px-3"
                          placeholder={
                            knowledgeBased === null ||
                            knowledgeBased === undefined
                              ? "Search FAQs and Articles"
                              : knowledgeBased.chatWidgetPlaceholder
                          }
                          type="search"
                        />
                      </p>
                      {show.showSuggestedArticles === true &&
                      suggestedArticles?.length ? (
                        <>
                          <h6 className={`${styles.articles} mt-1`}>
                            Suggested Articles
                          </h6>

                          {suggestedArticles.map((articleTitle: string) => {
                            return (
                              <div
                                className={`w-100 mb-1 ${styles.questions}`}
                                key={uuid()}
                              >
                                <span
                                  className={`text-truncate d-inline-block ${styles.queText}`}
                                >
                                  {articleTitle}
                                </span>
                                <span className={`${styles.rightArrow}`}>
                                  <i className="fa-solid fa-angle-right"></i>
                                </span>
                              </div>
                            );
                          })}

                          {/* <p className={`${styles.articleQa}`}>
                            Do you deliver to California, New York and Atlanta?
                          </p>
                          <p className={`${styles.articleQa}`}>
                            I have replaced and order, my payment was processed
                            but I still can't see my orders?
                          </p> */}
                          {/* <div className={`${styles.tags}`}>
                            <ul className="blue-hover">
                              <li>
                                <a
                                  className={`${styles.tagsLi}`}
                                  href="#"
                                  id="order-tracking"
                                >
                                  Order Tracking
                                </a>
                              </li>

                              <li>
                                <a className={`${styles.tagsLi}`} href="#">
                                  Refund
                                </a>
                              </li>
                              <li>
                                <a className={`${styles.tagsLi}`} href="#">
                                  Return
                                </a>
                              </li>
                            </ul>
                          </div> */}
                        </>
                      ) : null}
                    </div>
                  </div>
                  {showStartChat === true ? (
                    <div className={`${styles.cardBox} mb-1`}>
                      <div
                        className={`start-chat d-flex flex-column justify-content-start mb-1 p-2 p-md-3 ${styles.homeBox}`}
                      >
                        <div className="images-text d-flex justify-content-start">
                          <div className="images d-flex">
                            <span
                              className={`position-relative ${styles.chatImg}`}
                            >
                              <img src={chatImg1} className="img-fluid" />
                            </span>
                            <span
                              className={`position-relative ${styles.chatImg} ${styles.chatImg2}`}
                            >
                              <img src={chatImg2} className={`img-fluid `} />
                            </span>
                            <span
                              className={`position-relative ${styles.chatImg} ${styles.chatImg3}`}
                            >
                              <img src={chatImg3} className={`img-fluid `} />{" "}
                              {customization !== null &&
                              customization !== undefined &&
                              customization.showOnlineStatusText === true ? (
                                <span className={`${styles.activeUser}`}></span>
                              ) : (
                                <span
                                  className={`${styles.offlineUser}`}
                                ></span>
                              )}
                            </span>
                          </div>
                          <div className="start-text d-flex flex-column ms-1 ms-md-3">
                            <h6 className={`${styles.chatPara}`}>
                              Start a chat
                            </h6>
                            <p className={`${styles.generally}`}>
                              {" "}
                              {customization === null ||
                              customization === undefined
                                ? ""
                                : customization.showOnlineStatusText === true
                                ? customization.statusTextWhenTeamOnline
                                    .readonly
                                : customization.statusTextWhenTeamOffline
                                    .readonly}
                              <b>
                                {/* We generally reply in{" "} */}

                                <span className={`${styles.few}`}>
                                  {" "}
                                  {customization === null ||
                                  customization === undefined
                                    ? ""
                                    : customization.showOnlineStatusText ===
                                      true
                                    ? customization.statusTextWhenTeamOnline
                                        .value
                                    : customization.statusTextWhenTeamOffline
                                        .value}
                                </span>
                              </b>
                            </p>
                          </div>
                        </div>
                        <button
                          className={`d-block ${styles.sendMsg2}`}
                          style={{
                            backgroundColor:
                              customization?.liveChatThemeColor ?? "#000",
                            borderColor:
                              customization?.liveChatThemeColor ?? "#000",
                          }}
                        >
                          {customization?.submitButtonText === undefined ||
                          customization?.submitButtonText === null
                            ? "Send Message"
                            : customization?.submitButtonText}{" "}
                          <img className={`${styles.floatEnd}`} src={send} />{" "}
                        </button>
                      </div>
                    </div>
                  ) : null}

                  {/* <div className={`mb-1 ${styles.cardBox}`}>
              <button className={`d-block send-msg btn mt-0 ${styles.greyClr}`}>
                <img src={cube} />
                Track Order Status{" "}
                <i className="fas fa-chevron-right float-end"></i>
              </button>
              <button className={`d-block send-msg btn mt-0 ${styles.greyClr}`}>
                <img src={refresh} />
                Initiate Return Order{" "}
                <i className="fas fa-chevron-right float-end"></i>
              </button>
              <button className={`d-block send-msg btn mt-0 ${styles.greyClr}`}>
                <img src={issue} />
                Report issue with the order{" "}
                <i className="fas fa-chevron-right float-end"></i>
              </button>
            </div> */}
                </div>
              </>
            ) : undefined}

            {customization?.showAutoReplyText === true ? (
              <>
                {/* <!-----------Customization Auto Reply View------------> */}
                <div
                  className={`d-flex flex-column p-0 ${styles.card}`}
                  id="customizationAutoReply"
                >
                  <div
                    style={{
                      backgroundColor:
                        customization?.liveChatThemeColor ?? "#000",
                    }}
                    className="card-header d-flex flex-column p-3 text-white border-bottom-0 header-hi ht-less"
                  >
                    <p className="fw-bold hi-there hi-text m-0">
                      <div className="images-text d-flex justify-content-between">
                        <div className="images d-flex">
                          <span
                            className={`position-relative ${styles.chatImg}`}
                          >
                            <img src={chatImg1} className="img-fluid" />
                          </span>
                          <span
                            className={`position-relative ${styles.chatImg} ${styles.chatImg2}`}
                          >
                            <img src={chatImg2} className="img-fluid" />
                          </span>
                          <span
                            className={`position-relative ${styles.chatImg} ${styles.chatImg3}`}
                          >
                            <img src={chatImg3} className="img-fluid" />{" "}
                            <span className={`${styles.activeUser}`}></span>
                          </span>
                          <div className="start-text d-flex flex-column justify-content-center ms-2">
                            <span className={`pe-1 ${styles.lead}`}>
                              {widgetName}
                            </span>
                          </div>
                        </div>
                        <div className="three-dots d-flex flex-end">
                          <img
                            className={`d-none img-fluid ${styles.dotsImg}`}
                            src={threeDotsImg}
                          />
                        </div>
                      </div>{" "}
                    </p>
                  </div>

                  <div className={`mb-auto ${styles.chats}`} id="chats">
                    <br />
                    <div className={`${styles.clearfix}`}></div>
                    <img
                      className={`${styles.user} ${styles.defaultUserImgPink}`}
                      src={defaultUserImg}
                    />
                    <p className={`${styles.userMsg}`}>
                      hi, I would like to know all the products and services you
                      offer at Acme.
                    </p>
                    <div className={`${styles.clearfix}`}></div>
                    <img
                      className={`${styles.botAvatar} ${styles.defaultUserImgGrey}`}
                      src={robotImg}
                    />
                    <p
                      className={`${styles.botMsg}`}
                      style={{ whiteSpace: "pre-line" }}
                    >
                      {customization?.autoreplayText}
                    </p>
                    <div className={`${styles.clearfix}`}></div>
                  </div>
                  <div className={`mb-0 w-100 p-2 ${styles.enterContainer}`}>
                    <div
                      className={`${styles.enterChat} d-flex align-items-center justify-content-center`}
                    >
                      <input
                        type="text"
                        placeholder="Type here..."
                        className="w-100"
                      />
                      <img
                        src={smileyImg}
                        className={`${styles.smiley} d-none`}
                      />{" "}
                      <img src={blackSendImg} className="blk-send" />
                    </div>
                  </div>
                </div>
              </>
            ) : undefined}

            {show.askForEmail === true ? (
              <>
                {/* <!-----------Forms Ask For Email Section------------> */}
                <div
                  className={`${styles.card} ${styles.askCard} d-flex flex-column`}
                  id="askForEmail"
                >
                  <div className="card-header mt-0 mb-auto d-flex flex-column p-3 text-white border-bottom-0 bg-dark header-hi ht-less">
                    <p className="fw-bold hi-there hi-text m-0">
                      <div className="images-text d-flex justify-content-between">
                        <div className="images d-flex">
                          <span
                            className={`position-relative ${styles.chatImg}`}
                          >
                            <img src={chatImg1} className="img-fluid" />
                          </span>
                          <span
                            className={`position-relative ${styles.chatImg} ${styles.chatImg2}`}
                          >
                            <img src={chatImg2} className="img-fluid" />
                          </span>
                          <span
                            className={`position-relative ${styles.chatImg} ${styles.chatImg3}`}
                          >
                            <img src={chatImg3} className="img-fluid" />{" "}
                            <span className={`${styles.activeUser}`}></span>
                          </span>
                          <div className="start-text d-flex flex-column justify-content-center ms-2">
                            <span className={`pe-1 ${styles.lead}`}>
                              {widgetName}
                            </span>
                          </div>
                        </div>
                        <div className="three-dots d-flex flex-end">
                          <img
                            className={`d-none img-fluid ${styles.dotsImg}`}
                            src={threeDotsImg}
                          />
                        </div>
                      </div>{" "}
                    </p>
                  </div>
                  <div className={`d-flex flex-column mb-0 h-100`}>
                    <div
                      className={`overflow-auto mb-auto ${styles.chats} ${styles.chatBox}`}
                      id="chats"
                    >
                      <div className={`${styles.clearfix}`}></div>
                      <br />
                      <img
                        className={`${styles.botAvatar} ${styles.defaultUserImgGrey}`}
                        src={defaultUserImg}
                      />
                      <p className={`${styles.botMsg}`}>
                        Hello, How may i help you?
                      </p>
                      <div className={`${styles.clearfix}`}></div>
                      <img
                        className={`${styles.user} ${styles.defaultUserImgPink}`}
                        src={defaultUserImg}
                      />
                      <p className={`${styles.userMsg}`}>
                        i'd would like to ask something
                      </p>
                      <div className={`${styles.clearfix}`}></div>
                      <img
                        className={`${styles.botAvatar} ${styles.defaultUserImgGrey}`}
                        src={defaultUserImg}
                      />
                      <p className={`${styles.botMsg}`}>
                        {askForEmailData?.showOfflineData === true ? (
                          showOfflineConfirmationText === false ? (
                            <>
                              <ParseHTML
                                html={
                                  askForEmailData?.offlineMessageText !==
                                    null &&
                                  askForEmailData?.offlineMessageText !==
                                    undefined
                                    ? askForEmailData?.offlineMessageText
                                    : ""
                                }
                              />

                              {/* <input
                      type="text"
                      placeholder="Email"
                      className="w-100"
                      />
                      <img src={blackSendImg} style={{height:"20px"}} className="blk-send" onClick={(e)=>setShowOfflineConfirmationText(true)} /> */}
                              {askForEmailData.canCustomerLeaveEmail ===
                              true ? (
                                <>
                                  <div className="rounded bg-white w-90 mt-2">
                                    <div className="d-flex justify-content-center w-100">
                                      <div
                                        className="d-flex align-items-center w-100"
                                        style={{
                                          border: "1px solid #A1A1A1",
                                          borderTopLeftRadius: "6px",
                                          borderBottomLeftRadius: "6px",
                                        }}
                                      >
                                        <img
                                          src={mailIcon}
                                          className="ms-2"
                                          style={{ width: "15px" }}
                                        />
                                        <input
                                          className={`${styles.askForEmailInput} ms-2 w-100 fw-light bg-white`}
                                          placeholder="Email"
                                          value={inputEmailForAskEmail}
                                          onChange={(e: any) =>
                                            setInputEmailForAskEmail(
                                              e.target.value.trim()
                                            )
                                          }
                                        />
                                      </div>
                                      <div
                                        className={`${styles.rounderRight} d-flex justify-content-center align-items-center`}
                                        style={{
                                          backgroundColor: "grey",
                                          color: "white",
                                        }}
                                        role="button"
                                        onClick={(e) =>
                                          setShowOfflineConfirmationText(true)
                                        }
                                      >
                                        <img
                                          src={rightArrow}
                                          className="py-1 px-2"
                                          style={{
                                            width: "40px !important",
                                            height: "29px",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : null}
                            </>
                          ) : (
                            <ParseHTML
                              html={
                                askForEmailData?.offlineConfirmationText !==
                                  null &&
                                askForEmailData?.offlineConfirmationText !==
                                  undefined
                                  ? askForEmailData?.offlineConfirmationText +
                                    inputEmailForAskEmail
                                  : ""
                              }
                            />
                          )
                        ) : askForEmailData?.canSendMessage === true ? (
                          <>
                            {showConfirmationText === false ? (
                              <>
                                <ParseHTML
                                  html={
                                    askForEmailData.messageText !== null &&
                                    askForEmailData.messageText !== undefined
                                      ? askForEmailData.messageText
                                      : ""
                                  }
                                />
                                {askForEmailData.canCustomerLeaveEmail ===
                                true ? (
                                  <>
                                    {/* <input
                      type="text"
                      placeholder="Email"
                      className="w-100"
                      />
                      <img src={blackSendImg} style={{height:"20px"}} className="blk-send" onClick={(e)=>setShowConfirmationText(true)} /> */}
                                    <div className="rounded bg-white w-90 mt-2">
                                      <div className="d-flex justify-content-center w-100">
                                        <div
                                          className="d-flex align-items-center w-100"
                                          style={{
                                            border: "1px solid #A1A1A1",
                                            borderTopLeftRadius: "6px",
                                            borderBottomLeftRadius: "6px",
                                          }}
                                        >
                                          <img
                                            src={mailIcon}
                                            className="ms-2"
                                            style={{ width: "15px" }}
                                          />
                                          <input
                                            className={`${styles.askForEmailInput} ms-2 w-100 fw-light bg-white`}
                                            placeholder="Email"
                                            value={inputEmailForAskEmail}
                                            onChange={(e: any) =>
                                              setInputEmailForAskEmail(
                                                e.target.value.trim()
                                              )
                                            }
                                          />
                                        </div>
                                        <div
                                          className={`${styles.rounderRight} d-flex justify-content-center align-items-center`}
                                          style={{
                                            backgroundColor: "grey",
                                            color: "white",
                                          }}
                                          role="button"
                                          onClick={(e) =>
                                            setShowConfirmationText(true)
                                          }
                                        >
                                          <img
                                            src={rightArrow}
                                            className="py-1 px-2"
                                            style={{
                                              width: "40px !important",
                                              height: "29px",
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : null}
                              </>
                            ) : (
                              <ParseHTML
                                html={
                                  askForEmailData.confirmationText !== null &&
                                  askForEmailData.confirmationText !== undefined
                                    ? askForEmailData.confirmationText +
                                      inputEmailForAskEmail
                                    : ""
                                }
                              />
                            )}
                          </>
                        ) : (
                          "Go ahead"
                        )}
                      </p>
                      <div className={`${styles.clearfix}`}></div>
                    </div>
                    <div className={`w-100 p-2 mb-0 ${styles.enterContainer}`}>
                      <div
                        className={`${styles.enterChat} d-flex align-items-center justify-content-center`}
                      >
                        <input
                          type="text"
                          placeholder="Type here..."
                          className="w-100"
                        />
                        <img
                          src={smileyImg}
                          className={`${styles.smiley} d-none`}
                        />{" "}
                        <img src={blackSendImg} className="blk-send" />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : undefined}

            {/* End of ask for email section */}

            {show.chatForm === true ? (
              <>
                {/* <!-----------Forms Pre, Post, Ticket Chat Form Section------------> */}
                <div
                  className={`${styles.card} ${styles.chatCard} d-flex flex-column`}
                  id="preChatForm"
                >
                  <div className="card-header d-flex flex-column p-3 mt-0 text-white border-bottom-0 bg-dark header-hi ht-less">
                    <p className="fw-bold hi-there hi-text m-0">
                      <div className="images-text d-flex justify-content-between">
                        <div className="images d-flex">
                          <span
                            className={`position-relative ${styles.chatImg}`}
                          >
                            <img src={chatImg1} className="img-fluid" />
                          </span>
                          <span
                            className={`position-relative ${styles.chatImg} ${styles.chatImg2}`}
                          >
                            <img src={chatImg2} className="img-fluid" />
                          </span>
                          <span
                            className={`position-relative ${styles.chatImg} ${styles.chatImg3}`}
                          >
                            <img src={chatImg3} className="img-fluid" />{" "}
                            <span className={`${styles.activeUser}`}></span>
                          </span>
                          <div className="start-text d-flex flex-column justify-content-center ms-2">
                            <span className={`pe-1 ${styles.lead}`}>
                              {widgetName}
                            </span>
                          </div>
                        </div>
                        <div className="three-dots d-flex flex-end">
                          <img
                            className={`img-fluid d-none ${styles.dotsImg}`}
                            src={threeDotsImg}
                          />
                        </div>
                      </div>{" "}
                    </p>
                  </div>

                  {showSubmitedFormPreview === true ? (
                    <>
                      <div
                        className={`d-flex align-items-center justify-content-center ${styles.bodyArea}`}
                      >
                        <div
                          className={`d-flex mt-5 mb-auto flex-column align-items-center justify-content-between py-4 px-3 ${styles.chatRatingContainer} `}
                        >
                          <div>
                            <span
                              className={`d-flex align-items-center justify-content-center ${styles.chatRatingImgSpan}`}
                            >
                              <img
                                src={chatRatingImg}
                                className={`${styles.formChatRatingImg}`}
                              />
                            </span>
                          </div>
                          {/* Below html is commented as it not same with live chat widget  */}
                          <div className="d-none">
                            <p className={`${styles.formChatRatingText} mt-2`}>
                              <textarea
                                className="rounded w-100"
                                style={{
                                  resize: "none",
                                  minHeight: "40px",
                                }}
                                placeholder="Type here..."
                                rows={2}
                              ></textarea>
                              <div className="w-100 pt-2 mb-0 enter-container">
                                <button
                                  type="submit"
                                  className="btn btn-dark w-100"
                                  onClick={(e: any) => {
                                    setShowSubmitedFormPreview(false);
                                  }}
                                >
                                  Leave a comment
                                </button>
                              </div>
                              {/* Thank you for the rating! You can also leave a comment: */}
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                        }}
                        className={`d-flex ${styles.dummyClass} flex-column mb-0`}
                      >
                        <div
                          className={`d-flex flex-column p-3 h-100 overflow-auto ${styles.bodyArea}`}
                        >
                          {chatFormData?.elements.map(
                            (element: any, index: number) => {
                              if (
                                !(element.elementType in FormWidgetElements)
                              ) {
                                return;
                              }
                              const ShowElement =
                                FormWidgetElements[element.elementType + ""]
                                  ?.component;
                              return (
                                <ShowElement
                                  key={uuid()}
                                  element={element}
                                  inputName={element.elementType + "" + index}
                                />
                              );
                            }
                          )}
                        </div>
                        <div
                          className={`w-100 p-2 mb-0 enter-container ${styles.submitBtnWrapper} `}
                        >
                          <button
                            type="submit"
                            className="btn btn-dark w-100"
                            onClick={(e) => handleFormsSubmit(e)}
                          >
                            {chatFormData?.submitButtonText === undefined
                              ? "Submit"
                              : chatFormData?.submitButtonText}
                          </button>
                        </div>
                      </form>
                    </>
                  )}
                </div>
              </>
            ) : undefined}

            {/* <!-----------Search related Queries----------> */}
            <div className="card" id="chat2" style={{ display: "none" }}>
              <div className="card-box mb-1">
                <div className="search-content search-related d-flex flex-column justify-content-start mb-1 py-3">
                  <p className="search-box d-flex align-items-center">
                    <img
                      className="previous-icon me2"
                      src="assets/img/previous icon.png"
                    />{" "}
                    <i className="fas fa-search"></i>
                    <input
                      data-lpignore="true"
                      className="w-100 px-2"
                      placeholder="Search FAQs and Articles"
                      type="search"
                    />
                  </p>
                  <p className="search-text py-2">
                    <span className=" browse-related"></span>Browse Related
                    Queries:
                  </p>
                  <div className="tags">
                    <ul>
                      <li>
                        <a href="#" id="order-tracking">
                          Order Tracking
                        </a>
                      </li>
                      <li>
                        <a href="#">FAQs</a>
                      </li>
                      <li>
                        <a href="#">Refund</a>
                      </li>
                      <li>
                        <a href="#">Return</a>
                      </li>
                      <li>
                        <a href="#">Billing</a>
                      </li>
                      <li>
                        <a href="#">Shipping</a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="faqs">
                  <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          Frequently asked questions (12)
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            <div className="ques">
                              <strong>How the track the order?</strong>
                            </div>
                            <div className="answer">
                              To check order status long in to your account and
                              go
                            </div>
                          </p>
                          <p>
                            <div className="ques">
                              <strong>
                                Do you deliver to California, New York and
                                Atlanta?
                              </strong>
                            </div>
                            <div className="answer">
                              Yes we do it will show the status of either
                              processing
                            </div>
                          </p>
                          <p>
                            <div className="ques">
                              <strong>
                                I've replaced and order, my payment was
                                processed but I still can't see my orders?
                              </strong>
                            </div>
                            <div className="answer">
                              TYes we do it will show the status of either
                              processing
                            </div>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          Order Tracking (2)
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">Lorem Ipsum</div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          Refund (5)
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">Lorem Ipsum</div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingFour">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                        >
                          Return (9)
                        </button>
                      </h2>
                      <div
                        id="collapseFour"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingFour"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">Lorem Ipsum</div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingFive">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFive"
                          aria-expanded="false"
                          aria-controls="collapseFive"
                        >
                          Shipping (8)
                        </button>
                      </h2>
                      <div
                        id="collapseFive"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingFive"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">Lorem Ipsum</div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingSix">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSix"
                          aria-expanded="false"
                          aria-controls="collapseSix"
                        >
                          Accounts
                        </button>
                      </h2>
                      <div
                        id="collapseSix"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingSix"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">Lorem Ipsum</div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingSeven">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSeven"
                          aria-expanded="false"
                          aria-controls="collapseSeven"
                        >
                          Prescription
                        </button>
                      </h2>
                      <div
                        id="collapseSeven"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingSeven"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">Lorem Ipsum</div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingEight">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseEight"
                          aria-expanded="false"
                          aria-controls="collapseEight"
                        >
                          Products
                        </button>
                      </h2>
                      <div
                        id="collapseEight"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingEight"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">Lorem Ipsum</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-----------Order Track related Queries----------> */}
            <div className="card" id="chat3" style={{ display: "none" }}>
              <div className="card-box mb-1 padding-zero">
                <div className="search-content search-related d-flex flex-column justify-content-start mb-1 pt-3 p-3">
                  <p className="search-box d-flex align-items-center">
                    <a href="#" className="previous">
                      <img
                        className="previous-icon me2"
                        src="assets/img/previous icon.png"
                      />
                    </a>
                    <i className="fas fa-search"></i>
                    <input
                      data-lpignore="true"
                      className="w-100 px-2"
                      placeholder="Search FAQs and Articles"
                      type="search"
                    />
                  </p>
                  <p className="search-text py-2">
                    <span className=" browse-related"></span>Order Tracking
                  </p>
                </div>
                <div className="order-Faq">
                  <p>
                    <div id="new_page">
                      <div className="ques">
                        <strong>How the track the order?</strong>
                      </div>
                      <div className="answer_faq">
                        To check order status long in to your account and go
                      </div>
                    </div>
                  </p>
                  <p>
                    <div id="new_page">
                      <div className="ques">
                        <strong>
                          Do you deliver to California, New York and Atlanta?
                        </strong>
                      </div>
                      <div className="answer_faq">
                        Yes we do it will show the status of either processing
                      </div>
                    </div>
                  </p>
                </div>
              </div>
            </div>

            {/* <!-----------Search related Queries (FAQ)----------> */}
            <div className="card" id="chat4" style={{ display: "none" }}>
              <div className="card-box mb-1">
                <div className="search-content search-related d-flex flex-column justify-content-start mb-1 pt-3">
                  <p className="search-box d-flex align-items-center">
                    <a href="#" className="previous">
                      <i className="fas fa-angle-left back-icon"></i>
                    </a>
                    <i className="fas fa-search"></i>
                    <input
                      data-lpignore="true"
                      className="w-100 px-2"
                      placeholder="Search FAQs and Articles"
                      type="search"
                    />
                  </p>
                  <p className="search-text-faq pt-2">
                    <span className=" browse-faq-related"></span>Frequently
                    asked Questions (12)
                  </p>
                </div>
                <div className="order-Faq">
                  <p>
                    <div className="ques">
                      <strong>How the track the order?</strong>
                    </div>
                    <div className="answer_faq">
                      To check order status long in to your account and go
                    </div>
                  </p>
                  <p>
                    <div className="ques">
                      <strong>
                        Do you deliver to California, New York and Atlanta?
                      </strong>
                    </div>
                    <div className="answer_faq">
                      Yes we do it will show the status of either processing
                    </div>
                  </p>
                  <p>
                    <div className="ques">
                      <strong>
                        I have placed an order, my payment was processed but I
                        still can't see my orders?
                      </strong>
                    </div>
                    <div className="answer_faq">
                      Yes we do it will show the status of either processing
                    </div>
                  </p>
                  <p>
                    <div className="ques">
                      <strong>How the track the order?</strong>
                    </div>
                    <div className="answer_faq">
                      Yes we do it will show the status of either processing
                    </div>
                  </p>
                  <p>
                    <div className="ques">
                      <strong>
                        Do you deliver to California, New York and Atlanta?
                      </strong>
                    </div>
                    <div className="answer_faq">
                      Yes we do it will show the status of either processing
                    </div>
                  </p>
                  <p>
                    <div className="ques">
                      <strong>
                        I have placed an order, my payment was processed but I
                        still can't see my orders?
                      </strong>
                    </div>
                    <div className="answer_faq">
                      Yes we do it will show the status of either processing
                    </div>
                  </p>
                  <p>
                    <div className="ques">
                      <strong>
                        I have placed an order, my payment was processed but I
                        still can't see my orders?
                      </strong>
                    </div>
                    <div className="answer_faq">
                      Yes we do it will show the status of either processing
                    </div>
                  </p>
                </div>
              </div>
            </div>

            {/* <!-----------Track Order-status form ------------> */}
            <div className="card" id="chat5" style={{ display: "none" }}>
              <div className="card-header d-flex flex-column p-3 text-white border-bottom-0 header-hi ht-less">
                <p className="fw-bold hi-there hi-text">
                  <div className="images-text d-flex justify-content-between">
                    <div className="images d-flex">
                      <img
                        className="img-fluid prev-icon"
                        src="assets/img/prev.png"
                      />
                      <span className="img img-1 position-relative">
                        <img src="assets/img/1.png" className="img-fluid" />
                      </span>
                      <span className="img img-2 position-relative">
                        <img src="assets/img/2.png" className="img-fluid" />
                      </span>
                      <span className="img img-3 active-green position-relative">
                        <img src="assets/img/3.png" className="img-fluid" />{" "}
                        <span className="active-user"></span>{" "}
                      </span>
                      <div className="start-text d-flex flex-column ms-3 mt-1">
                        <p className="">Acme Chat</p>
                      </div>
                    </div>
                    <div className="three-dots  d-flex flex-end">
                      <img
                        className={`img fluid ${styles.dotsImg}`}
                        src="assets/img/three dots.png"
                      />
                    </div>
                  </div>{" "}
                </p>
              </div>

              <div className="card-box mb-1">
                <div className="home-box search-content d-flex flex-column justify-content-start mb-1 px-3 py-2">
                  <div className="d-block">
                    <img src="assets/img/cube.png" /> Track Order Status{" "}
                  </div>
                  <div className="form-group py-3" id="order-history-form">
                    <div className="mb-2">
                      <input
                        data-lpignore="true"
                        type="number"
                        className="form-control"
                        placeholder="Enter order number"
                        id="ordernumber"
                      />
                    </div>
                    <div className="mb-3">
                      <input
                        data-lpignore="true"
                        type="email"
                        className="form-control"
                        placeholder="Enter E-mail Id"
                        id="email"
                        aria-describedby="emailHelp"
                      />
                    </div>

                    <button
                      type="submit"
                      className="btn btn-primary"
                      id="check-status-button"
                    >
                      Check Status{" "}
                      <i className="fas fa-long-arrow-alt-right"></i>
                    </button>
                    <div className="back-button d-flex justify-content-center mt-2">
                      <a className="back-link" href="#">
                        Back
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <!----------- Initiate Returm order form------------> */}
            <div className="card" id="chat6" style={{ display: "none" }}>
              <div className="card-header d-flex flex-column p-3 text-white border-bottom-0 header-hi ht-less">
                <p className="fw-bold hi-there hi-text">
                  <div className="images-text d-flex justify-content-between">
                    <div className="images d-flex">
                      <img
                        className="img-fluid prev-icon"
                        src="assets/img/prev.png"
                      />
                      <span className="img img-1 position-relative">
                        <img src="assets/img/1.png" className="img-fluid" />
                      </span>
                      <span className="img img-2 position-relative">
                        <img src="assets/img/2.png" className="img-fluid" />
                      </span>
                      <span className="img img-3 active-green position-relative">
                        <img src="assets/img/3.png" className="img-fluid" />{" "}
                        <span className="active-user"></span>{" "}
                      </span>
                      <div className="start-text d-flex flex-column ms-3 mt-1">
                        <p className="">Acme Chat</p>
                      </div>
                    </div>
                    <div className="three-dots  d-flex flex-end">
                      <img
                        className={`img fluid ${styles.dotsImg}`}
                        src="assets/img/three dots.png"
                      />
                    </div>
                  </div>{" "}
                </p>
              </div>

              <div className="card-box mb-1">
                <div className="home-box search-content d-flex flex-column justify-content-start mb-1 px-3 py-2">
                  <div className="d-block">
                    <img src="assets/img/refresh button.png" /> Initiate Return
                    Order
                  </div>
                  <div className="form-group py-3" id="order-history-form">
                    <div className="mb-2">
                      <input
                        data-lpignore="true"
                        type="number"
                        className="form-control"
                        placeholder="Enter order number"
                        id="ordernumber"
                      />
                    </div>
                    <div className="mb-3">
                      <input
                        data-lpignore="true"
                        type="email"
                        className="form-control"
                        placeholder="Enter E-mail Id"
                        id="email"
                        aria-describedby="emailHelp"
                      />
                    </div>

                    <button
                      type="submit"
                      className="btn btn-primary"
                      id="check-status-button"
                    >
                      Next
                      <i className="fas fa-long-arrow-alt-right"></i>
                    </button>
                    <div className="back-button d-flex justify-content-center mt-2">
                      <a className="back-link" href="#">
                        Back
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-----------Start chat with bot form------------> */}
            <div className="card" id="chat7" style={{ display: "none" }}>
              <div className="card-header d-flex flex-column p-3 text-white border-bottom-0 header-hi ht-less">
                <p className="fw-bold hi-there hi-text">
                  <div className="images-text d-flex justify-content-between">
                    <div className="images d-flex">
                      <img
                        className="img-fluid prev-icon"
                        src="assets/img/prev.png"
                      />
                      <span className="img img-1 position-relative">
                        <img src="assets/img/1.png" className="img-fluid" />
                      </span>
                      <span className="img img-2 position-relative">
                        <img src="assets/img/2.png" className="img-fluid" />
                      </span>
                      <span className="img img-3 active-green position-relative">
                        <img src="assets/img/3.png" className="img-fluid" />{" "}
                        <span className="active-user"></span>{" "}
                      </span>
                      <div className="start-text d-flex flex-column ms-3 mt-1">
                        <p className="">Acme Chat</p>
                      </div>
                    </div>
                    <div className="three-dots  d-flex flex-end">
                      <img
                        className={`img fluid ${styles.dotsImg}`}
                        src="assets/img/three dots.png"
                      />
                    </div>
                  </div>{" "}
                </p>
              </div>

              <div className="card-box mb-1">
                <div className="search-content d-flex flex-column justify-content-start">
                  <div className="form-group py-3" id="start-chat-form">
                    <div className="mb-3">
                      <input
                        data-lpignore="true"
                        type="email"
                        className="form-control"
                        placeholder="Enter E-mail Id"
                        id="email"
                        aria-describedby="emailHelp"
                      />
                    </div>
                    <div className="mb-3">
                      <textarea
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        placeholder="Enter your message"
                        rows={3}
                      ></textarea>
                    </div>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      id="check-status-button"
                    >
                      Start Chat
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-----------Return Order & Reason for Returning form ------------> */}
            <div className="card" id="chat8" style={{ display: "none" }}>
              <div className="card-header d-flex flex-column p-3 text-white border-bottom-0 header-hi ht-less">
                <p className="fw-bold hi-there hi-text">
                  <div className="images-text d-flex justify-content-between">
                    <div className="images d-flex">
                      <img
                        className="img-fluid prev-icon"
                        src="assets/img/prev.png"
                      />
                      <span className="img img-1 position-relative">
                        <img src="assets/img/1.png" className="img-fluid" />
                      </span>
                      <span className="img img-2 position-relative">
                        <img src="assets/img/2.png" className="img-fluid" />
                      </span>
                      <span className="img img-3 active-green position-relative">
                        <img src="assets/img/3.png" className="img-fluid" />{" "}
                        <span className="active-user"></span>{" "}
                      </span>
                      <div className="start-text d-flex flex-column ms-3 mt-1">
                        <p className="">Acme Chat</p>
                      </div>
                    </div>
                    <div className="three-dots  d-flex flex-end">
                      <img
                        className={`img fluid ${styles.dotsImg}`}
                        src="assets/img/three dots.png"
                      />
                    </div>
                  </div>{" "}
                </p>
              </div>

              <div className="card-box mb-1">
                <h6 className="fw-bold">Return Order</h6>
                <div className="home-box search-content mb-1 px-3 py-2">
                  <div className="mainbox-order d-flex  justify-content-between align-items-center">
                    <div className="order-image">
                      <img
                        className="img-order rounded-3"
                        src="assets/img/1.png"
                      />
                    </div>
                    <div className="order-detail mt-3 me-5">
                      <p>
                        Order Number : #6979890 <br /> SKU : 1x Acme T-shirt
                      </p>
                    </div>
                    <div className="order-price">
                      <h6 className="fw-bold"> $789</h6>
                    </div>
                  </div>
                </div>

                <div className="return-heading mt-4">
                  <h6 className="fw-bold">Reason for returning</h6>
                  <div className="form-check">
                    <input
                      data-lpignore="true"
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label className="form-check-label">Didn't fit</label>
                  </div>
                  <div className="form-check">
                    <input
                      data-lpignore="true"
                      className="form-check-input"
                      type={"checkbox"}
                      value=""
                      id="flexCheckChecked"
                    />
                    <label className="form-check-label">Quality issues</label>
                  </div>
                  <div className="form-check">
                    <input
                      data-lpignore="true"
                      className="form-check-input"
                      type={"checkbox"}
                      value=""
                      id="flexCheckChecked"
                    />
                    <label className="form-check-label">Damaged product</label>
                  </div>
                  <div className="form-check">
                    <input
                      data-lpignore="true"
                      className="form-check-input"
                      type={"checkbox"}
                      value=""
                      id="flexCheckChecked"
                    />
                    <label className="form-check-label">Other</label>
                  </div>
                  <div className="form-group">
                    <div className="my-3">
                      <textarea
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        placeholder="Please describe your reason here"
                        rows={3}
                      ></textarea>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary mt-3"
                    id="check-status-button"
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>

            {/* <!-----------Return Order condition ------------> */}
            <div className="card" id="chat9" style={{ display: "none" }}>
              <div className="card-header d-flex flex-column p-3 text-white border-bottom-0 header-hi ht-less">
                <p className="fw-bold hi-there hi-text">
                  <div className="images-text d-flex justify-content-between">
                    <div className="images d-flex">
                      <img
                        className="img-fluid prev-icon"
                        src="assets/img/prev.png"
                      />
                      <span className="img img-1 position-relative">
                        <img src="assets/img/1.png" className="img-fluid" />
                      </span>
                      <span className="img img-2 position-relative">
                        <img src="assets/img/2.png" className="img-fluid" />
                      </span>
                      <span className="img img-3 active-green position-relative">
                        <img src="assets/img/3.png" className="img-fluid" />{" "}
                        <span className="active-user"></span>{" "}
                      </span>
                      <div className="start-text d-flex flex-column ms-3 mt-1">
                        <p className="">Acme Chat</p>
                      </div>
                    </div>
                    <div className="three-dots  d-flex flex-end">
                      <img
                        className={`img fluid ${styles.dotsImg}`}
                        src="assets/img/three dots.png"
                      />
                    </div>
                  </div>{" "}
                </p>
              </div>

              <div className="card-box mb-1">
                <h6 className="fw-bold">Return Order</h6>
                <div className="home-box search-content mb-1 px-3 py-2">
                  <div className="mainbox-order d-flex  justify-content-between align-items-center">
                    <div className="order-image">
                      <img
                        className="img-order rounded-3"
                        src="assets/img/1.png"
                      />
                    </div>
                    <div className="order-detail mt-3 me-5">
                      <p>
                        Order Number : #6979890 <br /> SKU : 1x Acme T-shirt
                      </p>
                    </div>
                    <div className="order-price">
                      <h6 className="fw-bold"> $789</h6>
                    </div>
                  </div>
                  <div className="return-detais">
                    <ul className="steps">
                      <li className="step-count-1 fw-bold">Return accepted</li>
                      <li className="step-count fw-bold">
                        Return pickup accepted on 8 jan - 9 jan
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="home-box search-content mt-3 px-3 py-2">
                  <div className="mainbox-order d-flex  justify-content-between align-items-center">
                    <div className="order-image">
                      <img
                        className="img-order rounded-3"
                        src="assets/img/1.png"
                      />
                    </div>
                    <div className="order-detail mt-3 me-5">
                      <p>
                        Order Number : #6979890 <br /> SKU : 1x Acme T-shirt
                      </p>
                    </div>
                    <div className="order-price">
                      <h6 className="fw-bold"> $789</h6>
                    </div>
                  </div>
                  <div className="return-detais">
                    <ul className="steps">
                      <li className="step-count-2 fw-bold">Return accepted</li>
                      <li className="step-count-3 fw-bold">
                        Return pickup accepted on 8 jan - 9 jan
                      </li>
                      <li className="step-count fw-bold">Return complete</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-----------Track Return Order page------------> */}
            <div className="card" id="chat10" style={{ display: "none" }}>
              <div className="card-header d-flex flex-column p-3 text-white border-bottom-0 header-hi ht-less">
                <p className="fw-bold hi-there hi-text">
                  <div className="images-text d-flex justify-content-between">
                    <div className="images d-flex">
                      <img
                        className="img-fluid prev-icon"
                        src="assets/img/prev.png"
                      />
                      <span className="img img-1 position-relative">
                        <img src="assets/img/1.png" className="img-fluid" />
                      </span>
                      <span className="img img-2 position-relative">
                        <img src="assets/img/2.png" className="img-fluid" />
                      </span>
                      <span className="img img-3 active-green position-relative">
                        <img src="assets/img/3.png" className="img-fluid" />{" "}
                        <span className="active-user"></span>{" "}
                      </span>
                      <div className="start-text d-flex flex-column ms-3 mt-1">
                        <p className="">Acme Chat</p>
                      </div>
                    </div>
                    <div className="three-dots  d-flex flex-end">
                      <img
                        className={`img fluid ${styles.dotsImg}`}
                        src="assets/img/three dots.png"
                      />
                    </div>
                  </div>
                </p>
              </div>

              <div className="card-box mb-1">
                <div className="home-box search-content mb-1 px-3 py-2">
                  <div className="mainbox-order d-flex  justify-content-between align-items-center">
                    <div className="order-image">
                      <img
                        className="img-order rounded-3"
                        src="assets/img/1.png"
                      />
                    </div>
                    <div className="order-detail mt-2 me-3">
                      <p className="mb-0">
                        Order Number : #6979890 <br /> SKU : 1x Acme T-shirt
                      </p>
                      <p className="fw-bold">Delivery Expected by 16 March</p>
                    </div>
                    <div className="order-price">
                      <h6 className="fw-bold"> $789</h6>
                    </div>
                  </div>
                  <div className="order-tracking">
                    <p className="fw-bold mt-4">Order Tracking:</p>
                    <ul className="mt-0">
                      <li>Order Reached Kansas City</li>
                    </ul>
                    <img
                      className="location-map img-fluid"
                      src="assets/img/image.jpeg"
                    />
                    <p className="mt-2">
                      {" "}
                      Track Here :
                      <a href="#">https//:gey7fuyi8rufgirjfkdjvkfjgbiytujy</a>
                    </p>
                  </div>
                </div>
                <p className="fw-bold mt-4">Your Orders:</p>
                <div className="home-box search-content mb-1 px-3 py-3">
                  <div className="mainbox-order d-flex  justify-content-between align-items-center">
                    <div className="order-image">
                      <img
                        className="img-order rounded-3"
                        src="assets/img/1.png"
                      />
                    </div>
                    <div className="order-detail mt-2 me-3">
                      <p className="mb-0">
                        Order Number : #6979890 <br /> SKU : 1x Acme T-shirt
                      </p>
                      <p className="fw-bold">Delivery Expected by 16 March</p>
                    </div>
                    <div className="order-price">
                      <h6 className="fw-bold"> $789</h6>
                    </div>
                  </div>
                  <div className="buttons mt-3 d-flex justify-content-center">
                    <button
                      type="button"
                      className="btn btn-primary order-buttons"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary order-buttons"
                    >
                      Return
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary order-buttons"
                    >
                      Track
                    </button>
                  </div>
                </div>

                <div className="home-box search-content mb-1 px-3 py-3">
                  <div className="mainbox-order d-flex  justify-content-between align-items-center">
                    <div className="order-image">
                      <img
                        className="img-order rounded-3"
                        src="assets/img/1.png"
                      />
                    </div>
                    <div className="order-detail mt-2 me-3">
                      <p className="mb-0">
                        Order Number : #6979890 <br /> SKU : 1x Acme T-shirt
                      </p>
                      <p className="fw-bold">Delivery Expected by 16 March</p>
                    </div>
                    <div className="order-price">
                      <h6 className="fw-bold"> $789</h6>
                    </div>
                  </div>
                  <div className="buttons mt-3 d-flex justify-content-center">
                    <button
                      type="button"
                      className="btn btn-primary order-buttons"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary order-buttons"
                    >
                      Refund
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary order-buttons"
                    >
                      Track
                    </button>
                  </div>
                </div>

                <div className="home-box search-content mb-1 px-3 py-3">
                  <div className="mainbox-order d-flex  justify-content-between align-items-center">
                    <div className="order-image">
                      <img
                        className="img-order rounded-3"
                        src="assets/img/1.png"
                      />
                    </div>
                    <div className="order-detail mt-2 me-3">
                      <p className="mb-0">
                        Order Number : #6979890 <br /> SKU : 1x Acme T-shirt
                      </p>
                      <p className="fw-bold">Delivery Expected by 16 March</p>
                    </div>
                    <div className="order-price">
                      <h6 className="fw-bold"> $789</h6>
                    </div>
                  </div>
                  <div className="buttons mt-3 d-flex justify-content-center">
                    <button
                      type="button"
                      className="btn btn-primary order-buttons"
                    >
                      Return
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary order-buttons"
                    >
                      Refund
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary order-buttons"
                    >
                      Track
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-----------Track Return Order page------------> */}
            <div className="card" id="chat11" style={{ display: "none" }}>
              <div className="card-header d-flex flex-column p-3 text-white border-bottom-0 header-hi ht-less">
                <p className="fw-bold hi-there hi-text">
                  <div className="images-text d-flex justify-content-between">
                    <div className="images d-flex">
                      <img
                        className="img-fluid prev-icon"
                        src="assets/img/prev.png"
                      />
                      <span className="img img-1 position-relative">
                        <img src="assets/img/1.png" className="img-fluid" />
                      </span>
                      <span className="img img-2 position-relative">
                        <img src="assets/img/2.png" className="img-fluid" />
                      </span>
                      <span className="img img-3 active-green position-relative">
                        <img src="assets/img/3.png" className="img-fluid" />{" "}
                        <span className="active-user"></span>{" "}
                      </span>
                      <div className="start-text d-flex flex-column ms-3 mt-1">
                        <p className="">Acme Chat</p>
                      </div>
                    </div>
                    <div className="three-dots  d-flex flex-end">
                      <img
                        className={`img fluid ${styles.dotsImg}`}
                        src="assets/img/three dots.png"
                      />
                    </div>
                  </div>{" "}
                </p>
              </div>

              <div className="check-mark-icon">
                <div className="wrapper">
                  {" "}
                  <svg
                    className="checkmark"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 52 52"
                  >
                    <circle
                      className="checkmark__circle"
                      cx="26"
                      cy="26"
                      r="25"
                      fill="none"
                    />
                    <path
                      className="checkmark__check"
                      fill="none"
                      d="M14.1 27.2l7.1 7.2 16.7-16.8"
                    />
                  </svg>
                  <p className="fw-bold text-center">Return Accepeted!</p>
                </div>
              </div>
            </div>

            {/* <!-----------How to track order more width Queries----------> */}
            <div className="card" id="chat12" style={{ display: "none" }}>
              <div className="card-box mb-1">
                <div className="d-flex flex-column justify-content-start mb-1 pt-3">
                  <p className="search-box d-flex align-items-center fw-bold">
                    <a href="#" className="previous" id="widthsm">
                      <img
                        className="previous-icon me2"
                        src="assets/img/previous icon.png"
                      />
                    </a>
                    How to Track Order?
                  </p>
                </div>
                <div className="order-Faq">
                  <div className="order-ques mt-4">
                    <h5>How to track order</h5>
                  </div>
                  <div className="user-info d-flex mt-4">
                    <div className="user-image">
                      <img className="img-fluid user" src="assets/img/2.png" />
                    </div>
                    <div className="user-updates">
                      <h6 className="user_text mb-0">Written by Alice Brown</h6>
                      <h6 className="user_text mb-0">updated 1day ago</h6>
                    </div>
                  </div>
                  <div className="user-comment">
                    <p className="mt-3 user-content">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. It has survived not remaining
                      essentially unchanged. It was popularised in the 1960s
                      with the release of Letraset sheets containing Lorem Ipsum
                      passages, and more recently with desktop publishing
                      software like Aldus PageMaker including versions of Lorem
                      Ipsum.
                    </p>
                    <p className="mt-3 user-content">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. It but also the leap into remaining
                      essentially unchanged.{" "}
                    </p>
                  </div>
                </div>
                <div className="d-flex flex-column justify-content-start mb-1 p-1">
                  <p className="help-text">
                    Didn't find what you were looking for? The team can help
                  </p>
                  <div className="images-text d-flex justify-content-start">
                    <div className="images d-flex">
                      <span className="img img-1 position-relative">
                        <img src="assets/img/1.png" className="img-fluid" />
                      </span>
                      <span className="img img-2 position-relative">
                        <img src="assets/img/2.png" className="img-fluid" />
                      </span>
                      <span className="img img-3 active-green position-relative">
                        <img src="assets/img/3.png" className="img-fluid" />{" "}
                        <span className="active-user"></span>{" "}
                      </span>
                    </div>
                    <div className="start-text d-flex flex-column ms-3">
                      <h6 className="mb-0">Start a chat</h6>
                      <p className="reply-text">
                        {" "}
                        <img src="assets/img/clock.png" /> We generally reply in{" "}
                        <strong>few minutes</strong>
                      </p>
                    </div>
                  </div>
                  <button className="d-block send-msg btn">
                    Send Message{" "}
                    <img
                      className="float-end"
                      src="assets/img/white-send.png"
                    />{" "}
                  </button>
                </div>
              </div>
            </div>

            {/* <!-----------Track Return Order page------------> */}
            <div className="card" id="chat13" style={{ display: "none" }}>
              <div className="card-header d-flex flex-column p-3 text-white border-bottom-0 header-hi ht-less">
                <p className="fw-bold hi-there hi-text">
                  <div className="images-text d-flex justify-content-between">
                    <div className="images d-flex">
                      <img
                        className="img-fluid prev-icon"
                        src="assets/img/prev.png"
                      />
                      <span className="img img-1 position-relative">
                        <img src="assets/img/1.png" className="img-fluid" />
                      </span>
                      <span className="img img-2 position-relative">
                        <img src="assets/img/2.png" className="img-fluid" />
                      </span>
                      <span className="img img-3 active-green position-relative">
                        <img src="assets/img/3.png" className="img-fluid" />{" "}
                        <span className="active-user"></span>{" "}
                      </span>
                      <div className="start-text d-flex flex-column ms-3 mt-1">
                        <p className="">Acme Chat</p>
                      </div>
                    </div>
                    <div className="three-dots  d-flex flex-end">
                      <img
                        className={`img fluid ${styles.dotsImg}`}
                        src="assets/img/three dots.png"
                      />
                    </div>
                  </div>{" "}
                </p>
              </div>

              <div className="chats" id="chats">
                <div className="clearfix"></div>
                <img className="user" src="./assets/img/3.png" />
                <p className="userMsg">
                  hi, I would like to know all the products and services you
                  offer at Acme.
                </p>
                <div className="clearfix"></div>
                <img className="botAvatar" src="./assets/img/2.png" />
                <p className="botMsg">
                  Hi there i am Anna,
                  <br />
                  to provide you with right help can you share these Details:
                  <br />
                  Order No:
                  <br />
                  Product: <br />
                  date of Order: <br /> Looking forward to hear from you!
                </p>
                <div className="clearfix"></div>
                <img className="user" src="./assets/img/3.png" />
                <p className="userMsg">
                  hi, I would like to know all the products and services you
                  offer at Acme.
                </p>
                <div className="clearfix"></div>
                <img className="botAvatar" src="./assets/img/2.png" />
                <p className="botMsg">
                  I am facing some issues, please try again later!!!
                </p>
                <div className="clearfix"></div>
                <img className="user" src="./assets/img/3.png" />
                <p className="userMsg">
                  hi, I would like to know all the products and services you
                  offer at Acme.
                </p>
                <div className="clearfix"></div>
                <img className="botAvatar" src="./assets/img/2.png" />
                <p className="botMsg">
                  I am facing some issues, please try again later!!!
                </p>
                <div className="clearfix"></div>
                <img className="user" src="./assets/img/3.png" />
                <p className="userMsg">
                  hi, I would like to know all the products and services you
                  offer at Acme.
                </p>
                <div className="clearfix"></div>
                <img className="botAvatar" src="./assets/img/robot.png" />
                <p className="botMsg">
                  I am facing some issues, please try again later!!!
                </p>
              </div>
              <div className="w-100 p-2 enter-container">
                <div className="enter-chat d-flex align-items-center justify-content-center">
                  <input
                    data-lpignore="true"
                    type="text"
                    placeholder="Type here..."
                    className="w-100"
                  />{" "}
                  <img src="assets/img/smiley.png" className="d-none smiley" />{" "}
                  <img src="assets/img/black-send.png" className="blk-send" />
                </div>
              </div>
            </div>
          </div>
          <footer
            className={`d-flex justify-content-center align-items-center py-1 ${styles.footer}`}
          >
            <img src={brandingLogo} className="img-fluid" />{" "}
            {"Powered by " + brandingName}{" "}
            {/* showing branding name dynamically */}
          </footer>
        </div>
        {!isActive && <div className={`m-auto`}></div>}
        {/* minimized view of featured articles */}
        {isActive === false &&
        show.minimizedFeaturedArticles === true &&
        hideFeaturedArticles === false &&
        featuredArticles &&
        Object.keys(featuredArticles).length > 0 ? ( // Implemented this check to prevent displaying an empty box in case the article is deleted after configuring featured articles. This resolves the issue of an empty Featured article box appearing.
          <div className={`px-md-2 ${styles.widgetWrapper}`}>
            <div className="">
              {/* <div className={`pe-2 pt-32 mb-2 ms-auto ${styles.questBox}`}>
                <img src={hii} alt="" className={`${styles.hifi}`} />
                <span>Have Questions?</span>
              </div> */}
              <div
                id="live-chat-FAClose-button"
                className={`d-flex ${styles.closeArticle} py-1 px-2 max-content-width ms-auto me-2 mb-1`}
                // onClick={() => {
                //   setHideFeaturedArticles(true);
                // }}
              >
                <span className="me-1 my-auto">Close</span>
                <i className="my-auto fa-solid fa-xmark"></i>
              </div>
              <div className={`py-2 w-100 mb-1 ${styles.suggestQuestions}`}>
                <span
                  className={`text-truncate d-inline-block ${styles.suggsetQueText}`}
                >
                  {getArticleText(Object.values(featuredArticles), 0)}
                </span>
                <span className={`${styles.arrowRight}`}>
                  <i className="fa-solid fa-arrow-right"></i>
                </span>
              </div>
              {Object.values(featuredArticles).length >= 2 ? (
                <>
                  <div className={`py-2 w-100 mb-1 ${styles.suggestQuestions}`}>
                    <span
                      className={`text-truncate d-inline-block ${styles.suggsetQueText}`}
                    >
                      {getArticleText(Object.values(featuredArticles), 1)}
                    </span>
                    <span className={`${styles.arrowRight}`}>
                      <i className="fa-solid fa-arrow-right"></i>
                    </span>
                  </div>
                  <div
                    className={`${
                      Object.values(featuredArticles).length >= 4
                        ? "d-flex"
                        : ""
                    }`}
                  >
                    {Object.values(featuredArticles).length >= 3 ? (
                      <div
                        className={`py-2 ${
                          Object.values(featuredArticles).length >= 4
                            ? styles.questionWidth
                            : "w-100"
                        } mb-1 ${styles.suggestQuestions}`}
                      >
                        <span
                          className={`text-truncate d-inline-block ${styles.suggsetQueText}`}
                        >
                          {getArticleText(Object.values(featuredArticles), 2)}
                        </span>
                        <span className={`${styles.arrowRight}`}>
                          <i className="fa-solid fa-arrow-right"></i>
                        </span>
                      </div>
                    ) : null}
                    {Object.values(featuredArticles).length >= 4 ? (
                      <div
                        className={`py-2 w-50  mb-1 ${styles.questionPaddingRight} ${styles.suggestQuestions}`}
                      >
                        <span
                          className={`text-truncate d-inline-block ${styles.suggsetQueText}`}
                        >
                          {getArticleText(Object.values(featuredArticles), 3)}
                        </span>
                        <span className={`${styles.arrowRight}`}>
                          <i className="fa-solid fa-arrow-right"></i>
                        </span>
                      </div>
                    ) : null}
                  </div>
                </>
              ) : null}
            </div>
          </div>
        ) : (
          // Added this message to be displayed when the Featured article box is empty.
          isActive === false &&
          show.minimizedFeaturedArticles === true &&
          hideFeaturedArticles === false && (
            <span className="p-2 mx-3">Featured article not configured</span>
          )
        )}
        <div
          className={`mb-0 ${
            show.minimizedFeaturedArticles === false ? "mt-auto" : "mt-0"
          }${isActive ? " pt-2" : ""}${
            customization?.selectedWidgetPosition === "left"
              ? " me-auto ms-0"
              : " me-0 ms-auto"
          } ${styles.toggleIcon}`}
        >
          <img
            onClick={handleToggle}
            src={
              isActive
                ? ToggleIconOpenURL(
                    customization?.minimizedWindowColour ?? "#000000"
                  )
                : ToggleIconClosedURL(
                    customization?.minimizedWindowColour ?? "#000000"
                  )
            }
            className={`img-fluid cursor-pointer toggle-btn black-toggle ${styles.rotateImg}`}
            id="blk-togl"
          />
        </div>
      </div>
    </section>
  );
};
export default FinalChat;
