import { useCallback, useRef, useState } from "react";

import { useParams } from "react-router-dom";
import {
  globalLoaderProgress,
  showGlobalLoader,
} from "src/components/GlobalLoader/GlobalLoader";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import {
  IMergeTickets,
  mergeTickets,
} from "src/services/InnerTicket/MergeTickets/mergeTickets";
import { getAllSelectedTicketIdsForMerging } from "src/store/slices/innerTicket/innerTicket.selectors";
import {
  fetchInnerTicketMessages,
  fetchInnerTickets,
  resetInnerTicket,
  resetInnerTicketData,
  resetInnerTicketMessage,
  setActiveTicketInfoFromTicketData,
  setInnerTicketActive,
  setInnerTicketFilters,
} from "src/store/slices/innerTicket/innerTicket.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import styles from "./NoteNClose.module.scss";

interface Props {
  ticketId: string | number;
  selectedTicketCount: number;
  onClickBack: () => void;
  onMergeComplete: () => void;
}

const NoteNClose = ({
  ticketId,
  selectedTicketCount,
  onClickBack,
  onMergeComplete,
}: Props) => {
  const dispatch = useAppDispatch();
  const { tableType, viewType }: any = useParams();
  // get all the selected ticket id's to merge
  const selectedTicketIds = useAppSelector(getAllSelectedTicketIdsForMerging);
  
  const currentUserData = useAppSelector(
    (state) => state.globals.currentUserData
  );
  
  // make a note to be shown
  let initialNote = "";
  if (selectedTicketIds.length > 2) {
    let last = selectedTicketIds[selectedTicketIds.length - 1];
    for (let i = 0; i < selectedTicketIds.length - 1; i++) {
      initialNote += selectedTicketIds[i];
      if (i + 1 < selectedTicketIds.length - 1) initialNote += ", ";
    }
    initialNote += " and " + last;
  } else {
    initialNote = selectedTicketIds.join(" and ");
  }

  // for note check box
  const [isNoteChecked, setNoteChecked] = useState(true);
  const [showEmptyNoteError, setShowEmptyNoteError] = useState(false);
  const [noteValue, setNoteValue] = useState<string>(
    selectedTicketIds.length > 1 ? ` The tickets ${initialNote} has been merged into current Ticket ${ticketId}` : ` The ticket ${initialNote} has been merged into current Ticket ${ticketId}`
  );
  const [noteForClosedTicket, setNoteForClosedTicket] = useState<string>(
    // `The tickets ${initialNote} has been closed and has merged to Ticket Id ${ticketId}`
    ` ${`${currentUserData?.firstName} ${currentUserData?.lastName}`.trim()} closed this ticket after merging with ticket ${ticketId}`
  );
  const noteTxtRef = useRef<HTMLInputElement>(null);
  const noteCheckRef = useRef<HTMLInputElement>(null);
  const [isNoteForClosedTicketChecked, setNoteForClosedTicketChecked] =
    useState(true);
  const noteForClosedTicketRef = useRef<HTMLInputElement>(null);
  const noteForClosedTicketCheckRef = useRef<HTMLInputElement>(null);
  
  // to handle check box changes for note
  const onChangeNoteCheckBox = () => {
    setNoteChecked(noteCheckRef.current?.checked ?? false);
    validateNote();
    if (isNoteChecked) {
    }
  };

  const onChangeClosedTicketNoteCheckBox = () => {
    setNoteForClosedTicketChecked(
      noteForClosedTicketCheckRef.current?.checked ?? false
    );
  };
  
  // validate note
  const validateNote = () => {
    let note = noteTxtRef.current?.value ?? "";
    if (
      (noteCheckRef.current?.checked ?? false) === true &&
      note.length === 0
    ) {
      setShowEmptyNoteError(true);
    } else {
      setShowEmptyNoteError(false);
    }
  };

  // note text change
  const onNoteTextChange = (e: any) => {
    let note = noteTxtRef.current?.value ?? "";
    setNoteValue(note);
    validateNote();
  };

  // note text change for closed ticket
  const onClosedTicketNoteTextChange = (e: any) => {
    let note = noteForClosedTicketRef.current?.value ?? "";
    setNoteForClosedTicket(note);
  };

  const handleMergeSubmit = useCallback((e: any) => {
    e.preventDefault();
    const mergeData: IMergeTickets = {
      mergedIntoTicket: ticketId,
      mergedTicketIds: selectedTicketIds,
      // note: (noteCheckRef.current?.checked ?? false) == true ? noteValue : null,
      // mergedTicketsNote: (noteForClosedTicketCheckRef.current?.checked ?? false) == true ? noteForClosedTicket : null,
    };
    showGlobalLoader("MergeTickets");
    mergeTickets(mergeData, (progress: number) => {
      globalLoaderProgress(progress);
    })
      .then((res) => {
        if (!res.data.err) {
          dispatch(resetInnerTicket());
          dispatch(setInnerTicketActive({ ticketId }));
          dispatch(setActiveTicketInfoFromTicketData({ ticketId }));
          dispatch(
            setInnerTicketFilters({
              tableType,
              currentView: viewType,
            })
          );
          dispatch(fetchInnerTickets());
          // refetch inner tickets message data
          // dispatch(resetInnerTicketMessage());
          dispatch(fetchInnerTicketMessages());
          onMergeComplete();
          showGlobalLoader("None");
          pushTheToast({
            type: "success",
            text:
              "Merged " +
              res.data.mergedTicketsCount +
              " Ticket(s) into Ticket ID : " +
              ticketId,
            position: "top-right",
          });
        } else {
          onMergeComplete();
          showGlobalLoader("None");
          pushTheToast({
            type: "danger",
            text: "Failed to merge tickets",
            position: "top-right",
          });
        }
      })
      .catch((err) => {
        onMergeComplete();
        showGlobalLoader("None");
        pushTheToast({
          type: "danger",
          text: "Failed to merge tickets",
          position: "top-right",
        });
      });
  }, []);

  
  return (
    <div>
      {/* 1st note part */}
      <p className={`${styles.mergeHeading}`}>
        Merging {selectedTicketCount} Ticket(s) in Ticket ID : {ticketId}
      </p>
      <br />
      <p className={`${styles.noteTitlePara}`}>
       Event note will be added to current ticket once the tickets are merged :
      </p>
      <input
        data-lpignore="true"
        className={
          `${styles.inputNote} ` +
          (showEmptyNoteError === true
            ? `${styles.inputNoteError}`
            : `${styles.inputNoteBorder}`)
        }
        type="text"
        contentEditable={true}
        placeholder={`The tickets ${initialNote} has been merged into current ticket ${ticketId}`}
        value={noteValue}
        ref={noteTxtRef}
        onChange={(e) => {
          onNoteTextChange(e);
        }}
        disabled={true}
      />
      <br />
      <br />
      {/* <p>
        <div className="form-check">
          <input
            data-lpignore="true"
            className={`form-check-input`}
            style={{
              width: " 20px",
              height: "20px",
              borderRadius: "6px",
              border: "1px solid #707070",
              cursor: "pointer",
            }}
            type="checkbox"
            value=""
            id="flexCheckDefault"
            checked={isNoteChecked}
            ref={noteCheckRef}
            onChange={onChangeNoteCheckBox}
          />
          <span className={`px-2 ${styles.checkBoxTitle}`}>
            Add a note to current ticket {ticketId} about the merge
          </span>
        </div>
      </p> */}

      {/* second note part */}
      <p className={`${styles.noteTitlePara} mt-4`}>
        {/* Close tickets {initialNote} */}
        {`Event note will be added to tickets ${initialNote} and will be marked as closed :`}
      </p>
      <input
        data-lpignore="true"
        className={
          `${styles.inputNote} ` +
          (showEmptyNoteError === true
            ? `${styles.inputNoteError}`
            : `${styles.inputNoteBorder}`)
        }
        type="text"
        contentEditable={true}
        placeholder={`${`${currentUserData?.firstName} ${currentUserData?.lastName}`.trim()} closed this ticket after merging with ticket ${ticketId}`}
        value={noteForClosedTicket}
        ref={noteForClosedTicketRef}
        onChange={(e) => {
          onClosedTicketNoteTextChange(e);
        }}
        disabled={true}
      />
      <br />
      <br />
      {/* <p>
        <div className="form-check">
          <input
            data-lpignore="true"
            className={`form-check-input`}
            style={{
              width: " 20px",
              height: "20px",
              borderRadius: "6px",
              border: "1px solid #707070",
              cursor: "pointer",
            }}
            type="checkbox"
            value=""
            id="flexCheckDefault"
            checked={isNoteForClosedTicketChecked}
            ref={noteForClosedTicketCheckRef}
            onChange={onChangeClosedTicketNoteCheckBox}
          />
          <span className={`px-2 ${styles.checkBoxTitle}`}>
            Add a note to ticket {initialNote} about the merge
          </span>
        </div>
      </p> */}

      <div className={`${styles.bottomCtrlBar}`}>
        <button
          className={`btn mx-2 cancelBtn`}
          onClick={(e) => {
            onClickBack();
          }}
        >
          <span className={`mx-2 my-auto`}>
            <i className="fas fa-chevron-left" />
          </span>
          <span className={`my-auto`}>Back</span>
        </button>
        <span className={`position-absolute end-0`}>
          <button
            className={`btn mx-2 px-3 saveBtn`}
            onClick={(e) => {
              handleMergeSubmit(e);
            }}
            disabled={showEmptyNoteError === true ? true : false}
          >
            <span className={`my-auto`}>Merge</span>
            {/* <span className={`mx-2 my-auto`}></span> */}
          </button>
        </span>
      </div>
    </div>
  );
};

export default NoteNClose;
