/**
 * This file defines a React component represent sidebar in the answer source section
 * handles navigation between external source , kb articles and custom qa
 *
 * @author @yuvaraj-busibud
 * @author @navjyot-busibud
 * @author @Jayalakshmi-busibud
 * @author @Anubhav-busibud
 */
import React, { useState } from "react";
import styles from "./SourceSidebar.module.scss";
import { Accordion, NavLink, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
interface Props {
  showSettingsSubSB: boolean;
  setShowSettingsSubSB: Function;
}
const SourceSidebar = ({ setShowSettingsSubSB, showSettingsSubSB }: Props) => {
  //extracting active page info and bot profile id from url
  const { subTab, profileId } = useParams();
  const navigate = useNavigate();
  const [showOverlay, setShowOveray] = useState(false);

  //function to handle toggle of the sidebar
  const handleToggleSubSideBar = () => {
    if (showSettingsSubSB === true) {
      setShowSettingsSubSB(false);
    } else {
      setShowSettingsSubSB(true);
    }
  };
  const getToggleContent = () => {
    return (
      <div
        className={`${styles.subSideBarCloseBtnDiv}`}
        onClick={() => handleToggleSubSideBar()}
      >
        <OverlayTrigger
          show={showOverlay}
          placement="top"
          overlay={
            <Tooltip className={`${styles.toolTipCustom}`}>
              <span className={`${styles.tooltipHead}`}>
                {showSettingsSubSB === true ? "Hide sidebar" : "Show sidebar"}
              </span>
            </Tooltip>
          }
        >
          {({ ref, ...triggerHandler }) => (
            <div
              {...triggerHandler}
              ref={ref}
              onMouseOver={() => {
                setShowOveray(true);
              }}
              onMouseOut={() => {
                setShowOveray(false);
              }}
            >
              <span
                className={`${styles.subSideBarCloseBtnToggleArrow} m-auto`}
              >
                {showSettingsSubSB === true ? (
                  <i className="fa-solid fa-chevron-left"></i>
                ) : (
                  <i className="fa-solid fa-chevron-right"></i>
                )}
              </span>
            </div>
          )}
        </OverlayTrigger>
      </div>
    );
  };
  return (
    <div className={`${styles.subSideToggle}`}>
      {showSettingsSubSB === true && (
        <div className={`${styles.subsideToggleInner}`}>
          <div className={`py-3 ${styles.sidenav_content}`}>
            <div className="mb-2">
              <Accordion defaultActiveKey={"0"}>
                <Accordion.Item
                  eventKey="0"
                  className={`${styles.accordItem}`}
                >
                  <Accordion.Header className={`${styles.toggleHead}`}>
                    Sources
                  </Accordion.Header>
                  <Accordion.Body className={`${styles.accordBody}`}>
                    {/* External */}
                    <div className="d-flex">
                      <NavLink
                        className={`${styles.webBtn} ${subTab === "external" ? styles.active : ""
                          }`}
                        onClick={() => {
                          navigate(
                            `/bot/botProfiles/${profileId}/answerSource/external`,
                          );
                        }}
                      >
                        <div
                          className={`w-100 px-2 py-1 d-flex align-items-center position-relative`}
                        >
                          <div
                            className={`${subTab === "external" ? styles.bar : ""
                              }`}
                          ></div>
                          <span>External</span>
                        </div>
                      </NavLink>
                    </div>

                    {/* KB Articles */}
                    <div className="d-flex">
                      <NavLink
                        className={`${styles.webBtn} ${subTab === "kbArticles" ? styles.active : ""
                          }`}
                        onClick={() => {
                          navigate(
                            `/bot/botProfiles/${profileId}/answerSource/kbArticles`,
                          );
                        }}
                      >
                        <div
                          className={`w-100 px-2 py-1 d-flex align-items-center justify-content-between position-relative`}
                        >
                          <div
                            className={`${subTab === "kbArticles" ? styles.bar : ""
                              }`}
                          ></div>
                          <span>Knowledge base articles</span>
                        </div>
                      </NavLink>
                    </div>
                    {/* Below sections are hide because no data available for now (29.07.2023)  */}
                    {/* Custom Q&A */}
                    <div className="d-flex">
                      <NavLink
                        className={`${styles.webBtn} ${subTab === "customQa" ? styles.active : ""
                          }`}
                        onClick={() => {
                          navigate(
                            `/bot/botProfiles/${profileId}/answerSource/customQa`,
                          );
                        }}
                      >
                        <div
                          className={`w-100 px-2 py-1 d-flex align-items-center position-relative`}
                        >
                          <div
                            className={`${subTab === "customQa" ? styles.bar : ""
                              }`}
                          ></div>
                          <span>Custom Q&A</span>
                        </div>
                      </NavLink>
                    </div>

                    {/* Agnet Suggestion */}
                    {/* <div className="d-flex">
                      <NavLink
                        className={`${styles.webBtn} ${
                          subTab === "agentSuggestion" ? styles.active : ""
                        }`}
                        onClick={() => {
                          navigate(
                            `/bot/botProfiles/${profileId}/answerSource/agentSuggestion`,
                          );
                        }}
                      >
                        <div
                          className={`w-100 px-2 py-1 d-flex align-items-center position-relative`}
                        >
                          <div
                            className={`${
                              subTab === "agentSuggestion" ? styles.bar : ""
                            }`}
                          ></div>
                          <span>Agent Suggestions</span>
                        </div>
                      </NavLink>
                    </div> */}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            {showSettingsSubSB === true && getToggleContent()}
          </div>
        </div>
      )}
      {showSettingsSubSB === false && getToggleContent()}
    </div>
  );
};

export default SourceSidebar;
