import React, { useEffect, useState } from "react";
import styles from "./AgentOffline.module.scss";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { AJAXSTATUS } from "src/globals/constants";
import { updateAskForEmailData } from "src/services/LiveChat/Settings/askForEmail/updateAskForEmailData";
import {
  fetchAskForEmailData,
  resetAskForEmailSetting,
  setAgentNotAvailableFormValidationErrors,
} from "src/store/slices/liveChatSetting/Forms/askForEmail/askForEmailSetting.slice";
import cautionImg from "src/assets/images/blueCaution.png";
import { useAppDispatch, useAppSelector } from "src/store/store";
import MessageBox from "../../../AskMail/OfflineMode/Children/MessageBox";
import { Link } from "react-router-dom";
import Select from "react-select";
import dots from "src/assets/images/dots.png";
import textLogo from "src/assets/images/text.png";
import choiceImg from "src/assets/images/choiceList.png";
import dropdown from "src/assets/images/dropdown.png";
import multipleChoice from "src/assets/images/multipleChoice.png";
import info from "src/assets/images/thankYou.png";
import nextLine from "src/assets/images/nextLine.png";
import SelectBox from "../SelectBox/SelectBox";
import WithinBusinessHours from "./Children/WithinBusinessHours/WithinBusinessHours";
import { fetchAllBrands } from "src/store/slices/settings/brands/brandSettings.slice";
import {
  IUpdateAskForEmailDataParamV3,
  updateAskForEmailDataV3,
} from "src/services/LiveChat/Settings/askForEmail/updateAskForEmailDataV3";
import {
  IUpdateTicketChatFormDataParamV3,
  updateTicketChatFormDataV3,
} from "src/services/LiveChat/Settings/ticketChatForm/updateTicketChatFormDataV3";
import OutsideBusinessHours from "./Children/OutsideBusinessHours/OutsideBusinessHours";
import { ITicketChatFormDataV3 } from "src/store/slices/liveChatSetting/Forms/chatForm.declarations";
import { Spinner } from "react-bootstrap";
var objectHash = require("object-hash");
const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

interface Props {
  data: {
    canSendMessage?: boolean;
    setCanSendMessage?: any;
    sendMessageAfter?: string;
    setSendMessageAfter?: any;
    messageText?: string;
    setMessageText?: any;
    confirmationText?: string;
    setConfirmationText?: any;
    canCustomerLeaveEmail?: boolean;
    setCanCustomerLeaveEmail?: any;
    askForEmailDataAjaxStatus: AJAXSTATUS;
  };
  handleCancel: any;
  isOfflineDataChanged: boolean;
  isNotRespondDataChanged?: boolean;
  setIsOfflineDataChanged: any;
  integrationId: number | string;
  availabilityStartChatAlways: boolean;
}
const AgentOffline = ({
  integrationId,
  data,
  handleCancel,
  isOfflineDataChanged,
  setIsOfflineDataChanged,
  availabilityStartChatAlways,
}: Props) => {
  const dispatch = useAppDispatch();

  const {
    askForEmailDataAjaxStatus,
    askForEmailData,
    updateAskForEmailDataAjaxStatus,
  } = useAppSelector((state) => state.askForEmailSetting);
  const [showSaveChange, setShowSaveChanges] = useState(false);
  const [showSaveLoader, setShowSaveLoader] = useState(false);

  useEffect(() => {
    // dispatch(fetchAllBrands());
  }, []);

  const isEmailsValid = (emailList: any) => {
    for (let i = 0; i < emailList.length; i += 1) {
      const regEx = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
      if (!regEx.test(emailList[i].trim())) {
        return false;
      }
    }
    return true;
  };

  const validateTicketForm = (
    ticketFormData: ITicketChatFormDataV3,
    outsideBusinessHoursErrors: any
  ) => {
    if (ticketFormData.elements.length === 0) {
      pushTheToast({
        type: "warning",
        text: "Atleast one element is required",
        position: "top-right",
      });
      return false;
    }

    if (ticketFormData.customEmails.email.trim() !== "") {
      const emailList = ticketFormData.customEmails.email.split(",");
      const emailValidationResult = isEmailsValid(emailList);

      if (!emailValidationResult) {
        pushTheToast({
          type: "warning",
          text: "Please fill valid emails in custom emails",
          position: "top-right",
        });
        outsideBusinessHoursErrors.customEmailsErr = true;
        return false;
      }
    }

    if (ticketFormData.brandEmails.email.length === 0) {
      outsideBusinessHoursErrors.brandEmailsErr = true;
      return false;
    }

    const emptyValueElements = ticketFormData.elements.filter(
      (element) => element.elementValue.length === 0
    );

    if (emptyValueElements.length > 0) {
      return false;
    }

    return true;
  };

  const validateSubmit = (
    outsideBusinessHoursErrors: any,
    withinBusinessHoursErrors: any
  ) => {
    const withinBusinessHours =
      askForEmailData.whenAgentOffline.withinBusinessHours;
    const outsideBusinessHours =
      askForEmailData.whenAgentOffline.outsideBusinessHours;

    //validating outsideBusinessHours
    if (outsideBusinessHours.autoReply === false) {
      if (outsideBusinessHours.ticketFormConfirmationText.trim() === "") {
        outsideBusinessHoursErrors.ticketConfirmationTextErr = true;
        return false;
      }

      const valid = validateTicketForm(
        outsideBusinessHours.ticketChatFormData,
        outsideBusinessHoursErrors
      );
      if (!valid) {
        return false;
      }
    } else {
      if (outsideBusinessHours.messageText.trim() === "") {
        outsideBusinessHoursErrors.messageTextErr = true;
        return false;
      }
      if (outsideBusinessHours.confirmationText.trim() === "") {
        outsideBusinessHoursErrors.confirmationTextErr = true;
        return false;
      }
    }

    //validating outsideBusinessHours
    if (withinBusinessHours.autoReply === false) {
      if (withinBusinessHours.ticketFormConfirmationText.trim() === "") {
        withinBusinessHoursErrors.ticketConfirmationTextErr = true;
        return false;
      }

      const valid = validateTicketForm(
        withinBusinessHours.ticketChatFormData,
        withinBusinessHoursErrors
      );
      if (!valid) {
        return false;
      }
    } else {
      if (withinBusinessHours.messageText.trim() === "") {
        withinBusinessHoursErrors.messageTextErr = true;
        return false;
      }
      if (withinBusinessHours.confirmationText.trim() === "") {
        withinBusinessHoursErrors.confirmationTextErr = true;
        return false;
      }
    }

    return true;
  };
  const handleSave = () => {
    if (updateAskForEmailDataAjaxStatus !== "pending") {
      const withinBusinessHours =
        askForEmailData.whenAgentOffline.withinBusinessHours;
      const outsideBusinessHours =
        askForEmailData.whenAgentOffline.outsideBusinessHours;

      const outsideBusinessHoursErrors = {
        messageTextErr: false,
        confirmationTextErr: false,
        brandEmailsErr: false,
        customEmailsErr: false,
        ticketConfirmationTextErr: false,
      };
      const withinBusinessHoursErrors = {
        messageTextErr: false,
        confirmationTextErr: false,
        brandEmailsErr: false,
        customEmailsErr: false,
        ticketConfirmationTextErr: false,
      };

      const isPayloadValid = validateSubmit(
        outsideBusinessHoursErrors,
        withinBusinessHoursErrors
      );
      dispatch(
        setAgentNotAvailableFormValidationErrors({
          validationErrors: outsideBusinessHoursErrors,
          type: "outsideBusinessHours",
        })
      );
      dispatch(
        setAgentNotAvailableFormValidationErrors({
          validationErrors: withinBusinessHoursErrors,
          type: "withinBusinessHours",
        })
      );
      if (!isPayloadValid) {
        pushTheToast({
          type: "warning",
          text: "Please fill all the required fields",
          position: "top-right",
        });
        return;
      }

      let payload: IUpdateAskForEmailDataParamV3 = {
        integrationId: integrationId,
        whenAgentOffline: {
          outsideBusinessHours: {
            messageText: outsideBusinessHours.messageText,
            confirmationText: outsideBusinessHours.confirmationText,
            ticketFormConfirmationText:
              outsideBusinessHours.ticketFormConfirmationText,
            askForEmail: outsideBusinessHours.askForEmail,
            autoReply: outsideBusinessHours.autoReply,
            // ticketFormId: outsideBusinessHours.ticketFormId,
          },
          withinBusinessHours: {
            messageText: withinBusinessHours.messageText,
            confirmationText: withinBusinessHours.confirmationText,
            ticketFormConfirmationText:
              withinBusinessHours.ticketFormConfirmationText,
            askForEmail: withinBusinessHours.askForEmail,
            autoReply: withinBusinessHours.autoReply,
            // ticketFormId: withinBusinessHours.ticketFormId
          },
        },
      };
      setShowSaveLoader(true);
      if (outsideBusinessHours.autoReply === false) {
        let payloadElements =
          outsideBusinessHours.ticketChatFormData.elements.map(
            (element: any) => {
              return {
                elementId: element.elementId,
                elementKey: element.elementKey,
                elementValue: element.elementValue,
                elementOptions: element.elementOptions,
                isRequired: element.isRequired,
                isDefault: element.isDefault,
                isNewlyAdded: element.isNewlyAdded,
              };
            }
          );

        let ticketFormPayload: IUpdateTicketChatFormDataParamV3 = {
          integrationId: integrationId,
          formType: "outsideBusinessHours",
          elements: payloadElements,
          brandEmails: {
            enabled:
              outsideBusinessHours.ticketChatFormData.brandEmails.enabled,
            emails:
              outsideBusinessHours.ticketChatFormData.brandEmails.email.join(
                ","
              ),
          },
          customEmails: {
            enabled:
              outsideBusinessHours.ticketChatFormData.customEmails.enabled,
            emails:
              outsideBusinessHours.ticketChatFormData.customEmails.email ?? "",
          },
        };
        //updating ticket form
        updateTicketChatFormDataV3(ticketFormPayload).catch((err) => {
          pushTheToast({
            type: "danger",
            text: "Something went wrong!",
            position: "top-right",
          });
          setShowSaveLoader(false);
        });
      }

      if (withinBusinessHours.autoReply === false) {
        let payloadElements =
          withinBusinessHours.ticketChatFormData.elements.map(
            (element: any) => {
              return {
                elementId: element.elementId,
                elementKey: element.elementKey,
                elementValue: element.elementValue,
                elementOptions: element.elementOptions,
                isRequired: element.isRequired,
                isDefault: element.isDefault,
                isNewlyAdded: element.isNewlyAdded,
              };
            }
          );

        let ticketFormPayload: IUpdateTicketChatFormDataParamV3 = {
          integrationId: integrationId,
          elements: payloadElements,
          formType: "withinBusinessHours",
          brandEmails: {
            enabled: withinBusinessHours.ticketChatFormData.brandEmails.enabled,
            emails:
              withinBusinessHours.ticketChatFormData.brandEmails.email.join(
                ","
              ),
          },
          customEmails: {
            enabled:
              withinBusinessHours.ticketChatFormData.customEmails.enabled,
            emails:
              withinBusinessHours.ticketChatFormData.customEmails.email ?? "",
          },
        };
        //updating ticket form
        updateTicketChatFormDataV3(ticketFormPayload).catch((err) => {
          pushTheToast({
            type: "danger",
            text: "Something went wrong!",
            position: "top-right",
          });
          setShowSaveLoader(false);
        });
      }

      updateAskForEmailDataV3(payload)
        .then((res) => {
          pushTheToast({
            type: "success",
            text: "Successfully changed",
            position: "top-right",
          });
          dispatch(fetchAskForEmailData(integrationId));
          setIsOfflineDataChanged(false);
          setShowSaveLoader(false);
        })
        .catch((err) => {
          pushTheToast({
            type: "danger",
            text: "Something went wrong!",
            position: "top-right",
          });
          setShowSaveLoader(false);
        });
    }
  };
  const [accordionOne, setAccordionOne] = useState(false);
  const [accordionTwo, setAccordionTwo] = useState(false);
  const [mail, setMail] = useState("");

  const handleMail = (e: any) => {
    setMail(e.target.value);
  };

  const handleCancelButton = () => {
    dispatch(resetAskForEmailSetting());
    dispatch(fetchAskForEmailData(integrationId));
  };

  useEffect(() => {
    if (!showSaveChange) {
      const withinBusinessHoursJson: any = {
        ...askForEmailData.whenAgentOffline.withinBusinessHours,
      };
      const outsideBusinessHoursJson: any = {
        ...askForEmailData.whenAgentOffline.outsideBusinessHours,
      };

      delete withinBusinessHoursJson["validationErrors"];
      delete outsideBusinessHoursJson["validationErrors"];

      const withinBusinessHoursHash = objectHash(withinBusinessHoursJson);
      const outsideBusinessHoursHash = objectHash(outsideBusinessHoursJson);
      if (
        withinBusinessHoursHash ===
          askForEmailData.WhenAgentOfflineWithinBusinessHourInitialHash &&
        outsideBusinessHoursHash ===
          askForEmailData.WhenAgentOfflineOusideBusinessHourInitialHash
      ) {
        setShowSaveChanges(false);
      } else {
        setShowSaveChanges(true);
      }
    }
  }, [askForEmailData.whenAgentOffline]);

  return (
    <>
      <div className={`mt-3 px-2 py-2 ${styles.accordionHeight}`}>
        <div className={`py-3 px-3 mb-3 ${styles.offlineBox}`}>
          <div className={`d-flex justify-content-between align-items-center `}>
            <div>
              <p
                className={`mb-0 ${styles.boxHead} ${
                  accordionOne ? styles.activeCol : ""
                }`}
              >
                {" "}
                Agent(s) Offline - Within business hours
              </p>
            </div>
            <span
              className="cursor-pointer"
              onClick={() => setAccordionOne(!accordionOne)}
            >
              {accordionOne ? (
                <span
                  className={`${styles.boxHead} ${
                    accordionOne ? styles.activeCol : ""
                  }`}
                >
                  <i className="fa-solid fa-chevron-up"></i>
                </span>
              ) : (
                <span className={`${styles.boxHead} `}>
                  <i className="fa-solid fa-chevron-down"></i>
                </span>
              )}
            </span>
          </div>
          <div>
            {accordionOne ? (
              <WithinBusinessHours
                integrationId={integrationId}
                type={"withinBusinessHours"}
              />
            ) : (
              ""
            )}
          </div>
        </div>
        <div className={`py-3 px-3  ${styles.offlineBox}`}>
          <div className={`d-flex justify-content-between align-items-center `}>
            <div>
              <p
                className={`mb-0 me-2 ${styles.boxHead} ${
                  accordionTwo ? styles.activeCol : ""
                }`}
              >
                {" "}
                Agents(s) Offline - Outside business hours
              </p>
            </div>
            <span
              className="cursor-pointer"
              onClick={() => setAccordionTwo(!accordionTwo)}
            >
              {accordionTwo ? (
                <span
                  className={`${styles.boxHead} ${
                    accordionTwo ? styles.activeCol : ""
                  }`}
                >
                  <i className="fa-solid fa-chevron-up"></i>
                </span>
              ) : (
                <span className={`${styles.boxHead}`}>
                  <i className="fa-solid fa-chevron-down"></i>
                </span>
              )}
            </span>
          </div>
          <div>
            {accordionTwo ? (
              <OutsideBusinessHours
                integrationId={integrationId}
                type={"outsideBusinessHours"}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {showSaveChange === true && (
        <div
          className={`d-flex flex-column flex-md-row mt-2 justify-content-md-end`}
        >
          <button
            className={`bg-white mt-2 me-2 ${styles.cancelBtn}`}
            onClick={handleCancelButton}
          >
            Cancel
          </button>
          <button
            className={`mt-2 ${styles.saveBtn}`}
            onClick={handleSave}
            disabled={showSaveLoader}
          >
            {showSaveLoader === true ? (
              <Spinner
                className="my-auto mx-1"
                animation="border"
                color="white"
                size="sm"
              />
            ) : (
              "Save Changes"
            )}
          </button>
        </div>
      )}
    </>
  );
};

export default AgentOffline;
