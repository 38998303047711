import { axiosJSON } from "src/globals/axiosEndPoints";

export interface InvoiceDetail {
  id: string | number;
  datePaidGMT: string;
  price: string;
  url: string | null;
  status: "unpaid" | "paid" | "draft";
}

export interface IGetInvoiceDetailResponse {
  invoices: { [key: string | number]: InvoiceDetail };
  invoiceIds: Array<number | string>;
  metaData: {
    hasMore: boolean;
    currentCount: number;
  };
}

export interface GetInvoiceListPayload {
  start: number;
  limit: number;
}

export async function getInvoiceList(payload: GetInvoiceListPayload) {
  const { data: res } = await axiosJSON.post(
    `/api/setting/billing/getInvoiceList`,
    payload,
  );
  if (res.err || res.error) {
    throw new Error(res.message ?? res.msg ?? "Something went wrong");
  }

  return normalizeData(res.data, res.metaData.currentCount, payload);
}

function normalizeData(
  data: Array<InvoiceDetail>,
  currentCount: number,
  payload: GetInvoiceListPayload,
) {
  const invoices: { [key: string | number]: InvoiceDetail } = {};
  const invoiceIds: Array<number | string> = [];

  data.forEach((invoice: InvoiceDetail) => {
    const id = invoice.id + "";
    invoiceIds.push(id);
    invoices[id] = invoice;
  });

  const metaData = {
    hasMore: currentCount >= payload.start + payload.limit,
    currentCount: currentCount,
  };

  return {
    invoiceIds,
    invoices,
    metaData,
  } as IGetInvoiceDetailResponse;
}
