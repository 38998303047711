import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo, useState } from "react";
import { IAutomationParams } from "src/routes/BotSettings/Children/AutomationList/AutomationList";
import { getAllDispatchRuleConfigService } from "src/services/Automation/DispatchRulesConfigModal/getAllDispatchRuleConfig.service";

const LIMIT = 10;

const useConfigureDispatchRule = ({
  integrationId,
  type,
}: Partial<IAutomationParams>) => {
  const [currentPage, setCurrentPage] = useState(1);
  const queryClient = useQueryClient();

  const queryKey = useMemo(
    () => ["getAllDispatchRuleConfigService", integrationId, currentPage],
    [currentPage, integrationId],
  );

  const { data, isFetching, refetch } = useQuery(queryKey, {
    queryFn: () => {
      if (integrationId && type) {
        return getAllDispatchRuleConfigService({
          type,
          payload: {
            integrationId,
            limit: LIMIT,
            start: (currentPage - 1) * LIMIT,
          },
        });
      }
      throw new Error("Integration ID is missing");
    },
    // keepPreviousData: true,
    // cacheTime: 60000,
    // staleTime: 60000,
  });

  const totalPages = useMemo(() => {
    return Math.ceil((data?.metaData.totalCount ?? 0) / LIMIT);
  }, [data?.metaData]);

  const invalidateQuery = useCallback(() => {
    queryClient.invalidateQueries(queryKey);
  }, [queryClient, queryKey]);

  return {
    data: data,
    totalPages,
    currentPage,
    setCurrentPage,
    refetch,
    isFetching,
    invalidateQuery,
  };
};

export default useConfigureDispatchRule;
