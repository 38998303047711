import { axiosJSON } from "src/globals/axiosEndPoints";

export interface UpdateWebIntParams {
  live_chat_integration_type?: "otherWebsite" | "shopify";
  integration_id: number | string;
  url?: string;
  live_chat_enabled?: boolean;
  ai_bot_enabled?: boolean;
  brand_id?: number | string;
  website_name?: string;
  whatsapp_enabled?: boolean;
  whatsappUserId?: string | number | null;
}

export const updateWebIntegration = async (params: UpdateWebIntParams) => {
  const { data: res } = await axiosJSON.post(
    `/api/chatSetting/widget/update`,
    params
  );
  if (res.err) {
    throw res.msg;
  }
  return res.msg as string;
};
