import AxiosImg from "src/components/AxiosImg";
import UserAvatar from "src/components/UserAvatar";
import styles from "./BotUserStatus.module.scss";

// Define the interface for props
interface BotUserProps {
  profileImg: string;
  isPublicAttachmentUrl?: boolean;
  name: string;
  onClick: () => void;
  missedChatCount?: number;
  activeChatCount?: number;
  pendingChatCount?: number;
  altText?: string;
  showInfo?: boolean;
}

const BotUserStatus = ({
  profileImg,
  name,
  onClick,
  isPublicAttachmentUrl,
}: BotUserProps) => {
  return (
    <div
      className="d-flex justify-content-center align-items-center mb-3 position-relative"
      onClick={onClick}
    >
      {profileImg && profileImg.trim() !== "" ? (
        <AxiosImg
          className={`${styles.userName}`}
          url={profileImg}
          isDirectURL={isPublicAttachmentUrl}
        />
      ) : (
        <UserAvatar
          name={name || "NA"}
          size={30}
        />
      )}

      {/* {missedChatCount > 0 && (
          <span className={styles.pendingMsg}>{missedChatCount}</span>
        )}

        {activeChatCount > 0 && (
          <span className={styles.activeChatMsg}>{activeChatCount}</span>
        )}
        {pendingChatCount > 0 && (
          <span className={styles.pendingChatMsg}>
            <i className="fa-solid fa-hourglass-start"></i>
          </span>
        )} */}
    </div>
  );
};

export default BotUserStatus;
