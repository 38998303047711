/**
 * This file defines a React component responsible for rendering a brand modal
 * that shows a dropdown for selecting brands when creating a new bot profile.
 *
 * @author @Anubhav-busibud
 * @author @yuvaraj-busibud
 */
import { EAutomationType } from "../../AutomationList/AutomationList";
import BrandDropdown from "../../BrandDropdown/BrandDropdown";
import styles from "./BrandModal.module.scss";
import useBrandModal from "./useBrandModal";
import closeBtn from "src/assets/images/closeModal.png";
interface Props {
  onHide: () => void;
  handleNextClick: (brandId: number | string) => void;
  isCreating: boolean;
  type?: EAutomationType;
}
export interface BrandOption {
  value: number;
  label: string;
  imageUrl?: string | null;
}

const BrandModal = ({ onHide, handleNextClick, isCreating, type }: Props) => {
  const { handleChange, selectedBrand, showSetup, error, setError } =
    useBrandModal();

  return (
    <div>
      {!showSetup ? (
        <>
          <div className={`d-flex align-items-center justify-content-between`}>
            <h4 className={`mb-0 ${styles.modalHead}`}>Choose a brand</h4>
            <span
              className={`${styles.closeBtn}`}
              onClick={onHide}
              id="closeBrandModal"
            >
              <img
                src={closeBtn}
                alt="closeModal"
                width={11}
                height={11}
              />
            </span>
          </div>
          <div className="mt-4">
            <p className={`mb-1 ${styles.subHeading}`}>
              This will appear in your live chat for the following brand:
            </p>
            <BrandDropdown
              selectedBrand={selectedBrand}
              setSelectedBrand={handleChange}
              className={error ? styles.errorStyle : ""}
              error={error}
              isAutomation={type !== undefined}
              automationType={type}
            />
            <div className={`d-flex justify-content-end mt-4 pt-3`}>
              <button
                className={`${styles.nextBtn}`}
                disabled={isCreating}
                onClick={() => {
                  if (selectedBrand) {
                    handleNextClick(selectedBrand.value);
                  } else {
                    setError("*Please select a brand");
                  }
                }}
                id="nextBtn"
              >
                {isCreating ? (
                  <div>
                    Next
                    <span
                      className="spinner-border spinner-border-sm ms-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </div>
                ) : (
                  "Next"
                )}
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="text-center">
            <span className={`mx-auto mb-3 ${styles.successIcon}`}>
              <i className="fa-solid fa-check"></i>
            </span>
            <p className={`mb-0 ${styles.successHead}`}>
              The setup is completed !
            </p>
            <span className={`d-block mb-3 ${styles.successSpan}`}>
              Cern now is live for Acme brand
            </span>
            <div className="d-flex justify-content-center align-items-center">
              <button className={`me-4 ${styles.unpublishBtn}`}>
                <span className="pe-1">
                  <i className="fa-solid fa-eye"></i>
                </span>
                Unpublish
              </button>
              <button className={`${styles.okayBtn}`}>Okay</button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default BrandModal;
