import { Dispatch, SetStateAction } from "react";
import styles from "./ButtonWrapper.module.scss";
import { useReturnPreviewState } from "../../../../../../hooks/useReturnPreview";
const ButtonWrapper = ({
  showBack = true,
  showNext = true,
  onBackClick,
  onNextClick,
}: {
  showBack?: boolean;
  showNext?: boolean;
  onBackClick?: () => void;
  onNextClick?: () => void;
}) => {
  return (
    <div
      className={`mt-1 d-flex justify-content-between mb-3 ${styles.buttonWrapper}`}
    >
      {showBack && (
        <button
          className={`${styles.prevBtn}`}
          onClick={() => {
            if (onBackClick) {
              onBackClick();
            }
          }}
        >
          Back
        </button>
      )}

      {showNext && (
        <button
          className={`${styles.nextBtn}`}
          onClick={() => {
            if (onNextClick) {
              onNextClick();
            }
          }}
        >
          Next
        </button>
      )}
    </div>
  );
};

export default ButtonWrapper;
