import styles from "./LogIn.module.scss";
import helpLogo from "src/assets/images/helplama.png";
import saufterLogo from "src/assets/images/saufter-full.png";
import { Link, useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { useCallback, useEffect, useRef, useState } from "react";
import { loginUser } from "src/services/Auth/login";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import EnterEmail from "../ForgotPassword/EnterEmail/EnterEmail";
import EmailSendSuccess from "../ForgotPassword/EnterEmail/EmailSendSuccess/EmailSendSuccess";
import {
  InitialConfig,
  getInitialConfig,
} from "src/services/Auth/getInitialConfig";
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import Loader from "src/components/Loader";
import { v4 as uuid } from "uuid";
import { flushSync } from "react-dom";
import { showGlobalLoader } from "src/components/GlobalLoader/GlobalLoader";
import LoginForm from "./Children/LoginForm/LoginForm";
import CarouselPoster from "./Children/CarouselPoster/CarouselPoster.module";
import LoginHeader from "./Children/LoginHeader/LoginHeader";
import { ILoginData } from "src/hooks/auth/useLogin";

const logo = window.location.hostname.includes(
  process.env.REACT_APP_LOGO_HOST_NAME + ""
)
  ? saufterLogo
  : helpLogo;

interface Props {
  setLoginData: React.Dispatch<React.SetStateAction<any>>;
  data?: InitialConfig;
}
function LoginContent({ setLoginData, data }: Props) {
  const [loading, setLoading] = useState(false);
  const tokenRef = useRef("");
  const [emailF, setEmailF] = useState("");
  const [showModal, setShowModal] = useState<string>(
    "" as "modalEnterRegEmail" | "forgotEmailSendSuccess"
  );
  const navigate = useNavigate();

  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

  const { executeRecaptcha } = useGoogleReCaptcha();

  // function which is used to get recaptch verification token
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }
    //getting the token
    const token = await executeRecaptcha("login");
    return token;
    // Do whatever you want with the token
  }, [executeRecaptcha]);

  const handelLogInSubmit = useCallback(
    async (e: any) => {
      e.preventDefault();
      setLoading(true);
      //variable to store captcha token
      let captchaValue = null;
      //checking if recaptcha enabled
      if (data?.reCaptchaEnabled === true) {
        //getting the token
        captchaValue = await handleReCaptchaVerify();
      }

      //checking if recaptcha enabled and captchaValue empty
      if (data?.reCaptchaEnabled === true && !captchaValue) {
        pushTheToast({
          type: "danger",
          text: "reCAPTCHA verification failed. Please try again.!",
          position: "top-right",
        });
        setLoading(false);
        return;
      }
      loginUser({
        email: e.target.elements.email.value.trim(),
        password: e.target.elements.password.value.trim(),
        gRecaptchaToken: captchaValue, //passing the captcha token
      })
        .then((res: ILoginData) => {
          if (res.first_login) {
            window.localStorage.setItem("loginData", JSON.stringify(res));
            window.location.replace(
              (process.env.REACT_APP_FIRST_LOGIN_ROUTE ?? "/") +
                (window.location.hostname.includes("localhost")
                  ? `?loginData=${encodeURIComponent(JSON.stringify(res))}`
                  : "")
            );
          } else {
            flushSync(() => {
              setLoading(false);
              setLoginData(res);
            });
            showGlobalLoader("Initializing");
            if (res.action?.type === "user_redirect") {
              window.location.replace(res.action.url);
            } else {
              window.location.reload();
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          pushTheToast({
            type: "danger",
            text: "Email or password may be wrong!",
            position: "top-right",
          });
        });
    },
    [tokenRef.current, setRefreshReCaptcha, data, handleReCaptchaVerify]
  );

  useEffect(() => {
    //showing the recaptcha badge, by default it is hided
    const reCaptchaBadge = document.getElementsByClassName(
      "grecaptcha-badge"
    )[0] as HTMLElement | undefined;
    if (reCaptchaBadge) {
      reCaptchaBadge.classList.add("show");
    }
  }, [executeRecaptcha]);

  return (
    <>
      <div className={`${styles.SignUpMain} mx-auto`}>
        <section className={""} id="loginContainer">
          <div className="container">
            <div className="row">
              <LoginHeader />
            </div>
          </div>
          <div className="container pb-4">
            <div className="row" id="signUpSection">
              {/* Render the login form component */}
              <LoginForm
                handelLogInSubmit={handelLogInSubmit}
                handleForgetPassClick={(e) => {
                  setShowModal("modalEnterRegEmail");
                }}
                loading={loading}
                recaptchaDiv={
                  <div className={`mb-4 col-md-12 ${styles.effect_field}`}>
                    {/* {data?.reCaptchaEnabled ? (
                      <GoogleReCaptcha
                        onVerify={(token) => {
                          tokenRef.current = token;
                        }}
                      />
                    ) : null} */}
                  </div>
                }
                data={data}
              />
              {/* Render the carousel poster component for login page */}
              <CarouselPoster scope="login" />
            </div>
          </div>
        </section>
      </div>
      {showModal === "modalEnterRegEmail" ? (
        <EnterEmail
          showModal={showModal}
          setShowModal={setShowModal}
          emailF={emailF}
          setEmailF={setEmailF}
        />
      ) : undefined}
      {showModal === "forgotEmailSendSuccess" ? (
        <EmailSendSuccess
          showModal={showModal}
          setShowModal={setShowModal}
          emailF={emailF}
          setEmailF={setEmailF}
        />
      ) : undefined}
    </>
  );
}

const LogIn = ({ setLoginData }: any) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<InitialConfig>();

  useEffect(() => {
    const fetchInitialConfig = async () => {
      try {
        // fetching the site key and enabled flag from initial config api
        const response = await getInitialConfig();
        setData(response);
      } catch (error) {
        console.error("Error fetching initial config:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchInitialConfig();
  }, []);

  if (isLoading) {
    return (
      <div className="w-100 h-100">
        <Loader />
      </div>
    );
  } else {
    return (
      <GoogleReCaptchaProvider reCaptchaKey={data?.siteKey + ""}>
        <LoginContent setLoginData={setLoginData} data={data} />
      </GoogleReCaptchaProvider>
    );
  }
};

export default LogIn;
