import React, { useMemo, useRef, useState } from "react";
import styles from "./OtherTickets.module.scss";
import UserAvatar from "src/components/UserAvatar";
import { Dropdown } from "react-bootstrap";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import { POPPER_CONFIG } from "src/globals/constants";
import {
  IMessageData,
  setShowOtherTicketMessages,
} from "src/store/slices/innerTicket/innerTicket.slice";
import InnerChatAttachments from "src/components/InnerChatAttachments/InnerChatAttachments";
import ParseHTML from "src/components/ParseHTML";
import { highlightSearchedText } from "src/routes/TicketList/children/OuterTickets/Chidren/SearchedTickets/SearchedTickets";
import { showAttachment } from "src/services/Attachment/showAttachment";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { NavLink } from "react-router-dom";
interface Props {
  right: boolean;
  messageData: IMessageData;
}

const OtherTickets = ({ right, messageData }: Props) => {
  const dropdownRef = useRef<any>();
  // const [showDetails, setShowDetails] = useState<Boolean>(false);
  const showOtherTicketMsg = useAppSelector(
    (state) => state.innerTicket.showOtherTicketMsg,
  );

  const showOtherTicketNotes = useAppSelector(
    (state) => state.innerTicket.showOtherTicketNotes,
  );

  const dispatch = useAppDispatch();
  const handleShowOtherTicketMsg = (value: boolean) => {
    if (messageData.messageType === "Note") {
      dispatch(setShowOtherTicketMessages({ show: value, type: "note" }));
    } else {
      dispatch(setShowOtherTicketMessages({ show: value, type: "message" }));
    }
  };

  const showTicketMessage =
    messageData.messageType === "Note"
      ? showOtherTicketNotes
      : showOtherTicketMsg;

  const showMessageButtonText =
    messageData.messageType === "Note"
      ? "Show other ticket's note"
      : "Show other tickets";
  const hideMessageButtonText =
    messageData.messageType === "Note"
      ? "Hide other ticket's note"
      : "Hide other tickets";
  return (
    <div
      className={`d-flex ${false ? styles.otherMsgWrapper : ""}  ${
        right && "flex-row-reverse"
      }`}
    >
      <div
        className={`${
          right ? styles.sendMsgWrapper : styles.otherMessageContent
        }  ${messageData.messageType === "Note" ? styles.internalNote : ""}`}
      >
        <div className="d-flex justify-content-between align-items-center mb-2">
          <div>
            <NavLink
              target={"_blank"}
              to={`/tickets/all/ticket-view/ticket/${messageData.ticketId}`}
            >
              <button className={`px-2 ${styles.viewBtn}`}>
                {`View ticket ${messageData.ticketId}`}
              </button>
            </NavLink>
            {messageData.messageType === "Note" ? (
              <span className={`${styles.internalNoteText}`}>
                Internal note
              </span>
            ) : null}
          </div>
          <span
            className={`cursor-pointer ${styles.toogleBtn}`}
            onClick={() => {
              handleShowOtherTicketMsg(!showTicketMessage);
            }}
          >
            {showTicketMessage ? hideMessageButtonText : showMessageButtonText}
          </span>
        </div>
        {showTicketMessage ? (
          <>
            <div>
              <ParseHTML
                className={`mb-1 ${styles.msgInfo} ${
                  right ? styles.right : ""
                }`}
                html={messageData.message}
              />
              {/* <p className={``}>
                  lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when
                </p>
                <span className={`d-block ${styles.seeThread}`}>
                  See full thread
                </span> */}
              {messageData &&
                messageData.attachments &&
                messageData.attachments.length > 0 && (
                  <InnerChatAttachments
                    messageData={messageData}
                    // setModalShow={setModalShow}
                  />
                )}
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default OtherTickets;
