import { axiosJSON } from "src/globals/axiosEndPoints";

interface OrderItemData {
  itemId: string;
  variantId: null | string;
  itemName: null | string;
  quantity: null | number;
  sku: null | string;
  unitPrice: null | {
    amount: string;
    currencyCode: string;
  };
  totalPrice: null | {
    amount: string;
    currencyCode: string;
  };
  imageUrl: null | string;
  fulfillmentStatus: null | string;
  locationId: null | string;
  locationName: null | string;
  restockable: null | boolean;
}

interface IWarehouseLocation {
  id: string;
  name: string;
}
interface OrderItemStatus {
  locations: null | [IWarehouseLocation]
  fulfillmentStatus: null | {
    name: string;
    id: number;
    groupId: number;
  };
  locationId: null | string;
  locationName: null | string;
}

interface OrderItem {
  items: Array<OrderItemData>;
  status: OrderItemStatus;
}

export interface RefundModel {
  orderId: number;
  customer: {
    customerId: number;
    customerName: string;
    customerEmail: string;
    customerPhone: null;
  };

  orderItems: Array<OrderItem>;

  
  shipping: {
    title: string;
    amount: {
      amount: number;
      currencyCode: string;
    };
    availableRefundAmount: {
      amount: number;
      currencyCode: string;
    };
  };
  summary: {
    availableRefundAmount: {
      amount: number;
      currencyCode: string;
    };
  };

}

export interface RefundModelParams {
  orderId: number;
}

export async function refundModel(params: RefundModelParams) {
  const { data: res }: any = await axiosJSON.post(
    `/api/shopify/order/refund/get`,
    params
  );

  if (res.error === true) {
    throw new Error(res.message as string);
  }

  return res.data as RefundModel;
}
