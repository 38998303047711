import { axiosJSON } from "src/globals/axiosEndPoints";
import APIResponse from "./APIReponse";

interface ChangePasswordUserParams {
  // userId: number;
  currentPassword: string;
  newPassword: string;
}

interface ChangePasswordUser {
  userId: number;
}

export async function changePasswordUser(params: ChangePasswordUserParams) {
  const { data: res } = await axiosJSON.post<APIResponse<ChangePasswordUser>>(
    "/api/setting/user/changePassword",
    params
  );

  if (res.error === true) {
    throw new Error(res.message as string);
  }

  return res.data as ChangePasswordUser;
}
