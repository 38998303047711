import InstallationLoading from "src/routes/LiveChat/Children/ChatSetting/Children/LiveChatWidget/Children/ToastModals/InstallationLoading";
import styles from "./../StoreDetail/StoreDetail.module.scss";
import Installation from "src/routes/LiveChat/Children/ChatSetting/Children/LiveChatWidget/Children/ToastModals/Installation";

/**
 * Props for the CheckInstallation component
 */
interface Props {
  /** Function to handle checking installation */
  handleCheck: () => void;
  /** Loading indicator */
  loading: boolean;
  /** Success indicator */
  success: boolean;
  /** Function to hide the modal */
  hideModal: () => void;
}

/**
 * Component for Checking Live Chat Installation Step
 * @param props Props object containing handleCheck function, loading status, success status, and hideModal function
 * @returns CheckInstallation component
 */
const CheckInstallation = ({
  handleCheck,
  loading,
  success,
  hideModal,
}: Props) => {
  return (
    <>
      {/* Check Installation Step UI  */}
      {!loading && !success && (
        <div>
          <div className="d-flex mt-5 mb-3">
            {/* Step Number */}
            <div className={`${styles.pointsDiv} small mt-0 me-1`}>3</div>
            {/* Step Info */}
            <div className={`${styles.rightSection}`}>
              <div className={`m-auto ms-1 ${styles.storeDet}`}>
                {`Reload your website. Saufter should appear in the bottom right corner.`}
              </div>
            </div>
          </div>
          <div>
            {/* Check Installation button */}
            <button
              className={`${styles.nextBtn}`}
              onClick={handleCheck}
              id="checkInstallBtn"
            >
              Check installation
            </button>
          </div>
        </div>
      )}

      <div className="py-3">
        {/* Loading  */}
        {loading && (
          <InstallationLoading
            onHide={() => {}}
            callAgain={() => {}}
          />
        )}

        {/* Installation Completed  */}
        {success && (
          <Installation
            onHide={hideModal}
            callAgain={() => {}}
            isLiveChatSetup={true}
          />
        )}
      </div>
    </>
  );
};

export default CheckInstallation;
