/**
 * This file is a component file.
 *
 * @author Jayalakshmi
 * @author Yash Aditya
 */

import UserAvatar from "src/components/UserAvatar";
import styles from "./AgentListItem.module.scss";
import AxiosImg from "src/components/AxiosImg";
import { useEffect, useMemo, useState } from "react";
import { Spinner } from "react-bootstrap";

// Define the properties that the AgentListItem component expects
interface Props {
  userName: string;
  imgURL?: string | null;
  id: string;
  handleChange: (
    userIds: (string | number)[],
    context: "remove" | "add",
  ) => void;
  selectedValues: Array<string>;
  isLoading: boolean;
  scope: "team" | "user" | "static";
  userIds: Array<string>;
  disabled?: boolean;
}

const AgentListItem = ({
  userName,
  imgURL,
  id,
  handleChange,
  selectedValues,
  isLoading,
  scope,
  userIds,
  disabled,
}: Props) => {
  // State to track whether the checkbox is being updated
  const [isUpdating, setIsUpdating] = useState(false);

  // Memoized value to determine whether the checkbox should be checked
  const isChecked = useMemo(() => {
    let v = true;
    if (selectedValues.length >= userIds.length && userIds.length > 0) {
      userIds.forEach((id) => {
        if (!selectedValues.map((i) => i + "").includes(id + "")) {
          v = false;
        }
      });
    } else {
      v = false;
    }
    return v;
  }, [selectedValues, userIds]);

  // Effect to reset the updating state when loading is complete
  useEffect(() => {
    if (!isLoading) {
      setIsUpdating(false);
    }
  }, [isLoading]);

  return (
    <div
      className={`mt-1 py-1 d-flex align-items-start ${styles.addBox} ${
        isLoading || disabled ? styles.disabled : ""
      }`}
    >
      <div>
        {/* Checkbox for selecting the agent or team */}
        <input
          type={"checkbox"}
          className={`form-check-input ${styles.agentCheck}`}
          onChange={(e) => {
            if (userIds.length) {
              handleChange(userIds, e.currentTarget.checked ? "add" : "remove");
              setIsUpdating(true);
            }
          }}
          id={`${id}`}
          checked={isChecked}
          disabled={isLoading || disabled}
        />
      </div>

      {/* Label for displaying the agent or team information */}
      <label
        className="ps-3 d-flex align-items-start cursor-pointer"
        htmlFor={`${id}`}
      >
        <div className={`d-flex justify-content-center align-items-start`}>
          {/* Show loader until the updating is complete */}
          {isLoading && isUpdating && (
            <span className={`${styles.loader}`}>
              <Spinner
                variant="secondary"
                size="sm"
              />
            </span>
          )}

          {/* Display the user avatar or team image */}
          <span className={`me-2 ${styles.dropdownItemProfileImg}`}>
            {imgURL ? (
              <AxiosImg
                url={imgURL}
                className="w-100"
              />
            ) : (
              <UserAvatar
                name={userName}
                size={100}
                className="w-100"
              />
            )}
          </span>
          <div className="d-flex flex-column">
            {/* Display the truncated agent or team name */}
            <span className={`text-truncate mt-1 ${styles.agentName}`}>
              {userName}
            </span>
            {/* Mar 4 Disable the user who do not have access to all brands and show this validation text */}
            {disabled && disabled === true && (
              <span className={`${styles.disableText} pe-2 pt-1`}>
                A team member needs to have access to all brands to receive
                notifications.
              </span>
            )}
          </div>
        </div>
      </label>
    </div>
  );
};

export default AgentListItem;
