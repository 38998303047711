import { useQuery } from "@tanstack/react-query";
import { getBugPriority } from "src/services/BugReport/getBugPriority.service";

const useBugPriorityList = () => {
  const { data: allBugPriority } = useQuery(["bugStatusList/getBugPriority"], {
    queryFn: () => getBugPriority(),
    staleTime: 60000,
    cacheTime: 60000,
  });

  return {
    allBugPriority
  }
}

export default useBugPriorityList