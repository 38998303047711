import { Link } from "react-router-dom";
import styles from "./WidgetFooter.module.scss";
import saufterLogo from "src/assets/images/saufterImg.png";

const WidgetFooter = () => {
  return (
    <footer
      id="live-chat-widget-branding-footer"
      className={`d-flex justify-content-center align-items-center py-1 ${styles.footerPosition} mb-0`}
    >
      <span className={`${styles.poweredText} pe-1`}>Powered by</span>
      <Link
        className="powered-by-anchor"
        to="https://saufter.io"
        target="_blank"
      >
        <img
          src={saufterLogo}
          height={20}
          alt="logo"
        />
      </Link>
    </footer>
  );
};

export default WidgetFooter;
