import { getFormElementOptions, getPreChatFormData } from "src/services/LiveChat/Settings/preChatForm/getPreChatFormData";
import { RootState } from "src/store/store";

const fetchPreChatFormDataThunk = async (
  payload: number|string,
  { getState, dispatch }: { getState: Function; dispatch: Function }
) => {
  const currentState: RootState = getState();

  let formElementOptions = currentState.preChatFormSetting.formElementOptions;
  if (formElementOptions.formElementOptionIds.length === 0) {
    formElementOptions = await getFormElementOptions({formType:"preChatForm"});
  }
  const preChatFormData = await getPreChatFormData({
    integrationId: payload,
  });

  return {preChatFormData ,formElementOptions };
};

export default { fetchPreChatFormDataThunk};
