import { useCallback, useEffect, useRef, useState } from "react";
import SendingInfo from "src/components/SendingInfo";
import { ChangeCustomerProps } from "src/services/SendMessageService";
import OpenClose from "../OpenClose";
import styles from "./SendingDetails.module.scss";
import { BsExclamationDiamondFill } from "react-icons/bs";
function SendingDetails({
  ticketId,
  src,
  isInModal,
  ticket_to,
  ticket_from,
  changeTheCustomer,
  textToHighlight,
  ticket_cc,
  ticket_bcc,
  isToDisabled = false,
  isFromDisabled = false,
  isIntegrationFailed = false,
  integrationFailType,
  smallWindowChat = false,
}: {
  ticketId: string | undefined;
  src: any;
  isInModal: any;
  ticket_to: string;
  ticket_from: string;
  textToHighlight?: string;
  changeTheCustomer: (payload: ChangeCustomerProps) => void;
  ticket_cc?: string;
  ticket_bcc?: string;
  isToDisabled?: boolean;
  isFromDisabled?: boolean;
  isIntegrationFailed?: boolean;
  integrationFailType?: string;
  smallWindowChat?: boolean;
}) {
  const [showSending, setShowSending] = useState(
    isInModal || isIntegrationFailed,
  );
  const sendingDetailsRef = useRef(null as any);
  const [cc, setCc] = useState(ticket_cc ?? "");
  const [bcc, setBcc] = useState(ticket_bcc ?? "");
  const currentState = useRef({
    ticketId,
    to: ticket_to,
    ticket_cc,
    ticket_bcc,
    from: ticket_from,
  });

  useEffect(() => {
    currentState.current = {
      ticketId,
      to: ticket_to,
      ticket_cc,
      ticket_bcc,
      from: ticket_from,
    };
  }, [ticketId, ticket_to, ticket_cc, ticket_bcc, ticket_from]);

  useEffect(() => {
    if (ticket_cc !== undefined) {
      setCc(ticket_cc ?? "");
    }
    if (ticket_bcc !== undefined) {
      setBcc(ticket_bcc ?? "");
    }
  }, [ticket_cc, ticket_bcc]);
  const clearData = useCallback(() => {
    if (currentState.current.ticket_cc === undefined) {
      setCc("");
    }
    if (currentState.current.ticket_bcc === undefined) {
      setBcc("");
    }
    setShowSending(false);
  }, []);
  src.current.clearData = clearData;
  useEffect(() => {
    sendingDetailsRef.current.scrollTop = 0;
  }, [showSending]);
  return (
    <div
      className={`d-flex ${styles.sendingDetails} ${
        showSending ? styles.active : ""
      } justify-content-between`}
    >
      <div
        className={`${styles.sendingBlock} ${ticket_cc ? styles.activeCC : ""}`}
        ref={sendingDetailsRef}
      >
        <div className="d-flex mb-1">
          <input
            type="email"
            style={{
              width: 0,
              height: 0,
              opacity: 0,
              border: "none",
              padding: 0,
              margin: 0,
            }}
          />
          <label className={`${smallWindowChat && styles.smallFont}`}>To:</label>
          <SendingInfo
            name="to"
            currentValue={ticket_to ? (ticket_to as string) : ""}
            getValue={(value) => {
              if (
                currentState.current.ticketId &&
                value &&
                value !== currentState.current.to
              ) {
                changeTheCustomer({
                  ticketId: currentState.current.ticketId as any,
                  customerEmail: value,
                });
              }
            }}
            addUser={true}
            textToHighlight={textToHighlight?.trim()}
            isDisabled={isToDisabled}
            addUserZIndex={203}
          />
        </div>
        <div className="d-flex mb-1">
          <label>Cc:</label>
          <SendingInfo
            name="cc"
            currentValue={cc}
            getValue={(value) => {
              setCc(value);
              if (
                currentState.current.ticketId &&
                currentState.current.to &&
                value !== currentState.current.ticket_cc &&
                currentState.current.ticket_cc !== undefined
              ) {
                changeTheCustomer({
                  ticketId: currentState.current.ticketId as any,
                  cc: value.split(","),
                });
              }
            }}
            addUser={true}
            isMulti={true}
            textToHighlight={textToHighlight?.trim()}
            addUserZIndex={203}
          />
        </div>
        <div className="d-flex mb-1">
          <label>Bcc:</label>
          <SendingInfo
            name="bcc"
            currentValue={bcc}
            getValue={(value) => {
              setBcc(value);
            }}
            addUser={true}
            isMulti={true}
            textToHighlight={textToHighlight?.trim()}
            addUserZIndex={203}
          />
        </div>
        <div className="d-flex mb-1 position-relative">
          <label>From:</label>

          <SendingInfo
            name="from"
            addUser={false}
            isDisabled={isFromDisabled}
            isError={isIntegrationFailed && integrationFailType !== undefined}
            currentValue={ticket_from ? (ticket_from as string) : ""}
            getValue={(value) => {
              if (
                currentState.current.ticketId &&
                currentState.current.from &&
                value &&
                value !== currentState.current.from
              ) {
                changeTheCustomer({
                  ticketId: currentState.current.ticketId as any,
                  from: value,
                });
              }
            }}
            textToHighlight={textToHighlight?.trim()}
          />
          {isIntegrationFailed && integrationFailType !== undefined && (
            <p className={`${styles.integrateText}`}>
              <span className={`${styles.exclamation}`}>
                <BsExclamationDiamondFill />
              </span>
              <span>
                {integrationFailType === "brand"
                  ? " Brand unavailable"
                  : " Integration not available"}
              </span>
            </p>
          )}
        </div>
      </div>
      <OpenClose
        onClick={(e: any) => {
          setShowSending(!showSending);
        }}
        show={showSending}
      />
    </div>
  );
}

export default SendingDetails;
