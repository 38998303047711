import { Channel } from "pusher-js";

export type TicketMessageEventCB = (res: any) => void;

export const getNewTicketMessageEvent = (
  channel: Channel,
  callback: TicketMessageEventCB
) => {
  channel.bind("ticket_event_new_message", callback, {
    pauseOnInActiveTab: true,
  });
};

export const getNewTicketMessageEventUnbind = (channel: Channel) => {
  channel.unbind("ticket_event_new_message");
};
