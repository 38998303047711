import { useCallback } from "react";
import { useAppSelector } from "src/store/store";
import { IntegrationFailureMetaData } from "src/types/IntegrationFailure";

/**
 * Custom hook related to integration failure
 */
const useIntegrationFailure = () => {
  // Getting brands data from store
  const { brands } = useAppSelector(
    (state) => state.globals.brandSignatureData,
  );

  /**
   * Function to check for integrations failure for the given email
   * check for api response data if not present check for email manually
   * Return integration Failure data and isIntegrationAvailable flag
   */
  const getIntegrationFailureData = useCallback(
    ({
      integrationFailureAPIData,
      fromEmail,
    }: {
      fromEmail?: string;
      integrationFailureAPIData?: IntegrationFailureMetaData | null;
    }): {
      integrationFailure: IntegrationFailureMetaData | null;
      isIntegrationFailure: boolean;
    } => {
      let integrationFailure: IntegrationFailureMetaData | null = null;
      let isIntegrationFailure: boolean = false;
      // If failure data is from an API error, then return that first.
      if (integrationFailureAPIData) {
        integrationFailure = integrationFailureAPIData;
      } else {
        if (brands && fromEmail) {
          // Get the selected brand
          const brandData = brands.find((brand) => brand.email === fromEmail);

          // Check if we have brand data
          if (brandData) {
            // Check if it is an email integration error
            if (!brandData.isEmailActive) {
              // Return failure metadata response for email integration

              integrationFailure = {
                type: "email",
                integrationId: brandData.emailIntegrationId,
                brand: {
                  id: brandData.id,
                  email: brandData.email,
                  name: brandData.name,
                },
              } as IntegrationFailureMetaData;

              // Check if we have brand integration error
            } else if (!brandData.isActive || !brandData.isVerified) {
              // Return failure metadata response for brand integration

              integrationFailure = {
                type: "brand",
                integrationId: brandData.emailIntegrationId,
                brand: {
                  id: brandData.id,
                  email: brandData.email,
                  name: brandData.name,
                },
              } as IntegrationFailureMetaData;
            }
          }
        }
      }
      // Check if integration failure has happened
      if (!integrationFailure) {
        isIntegrationFailure = false;
      } else if (
        // Check if we have failed brand email and active ticket selected from is same as failed brand email
        integrationFailure.brand?.email &&
        integrationFailure.brand.email !== fromEmail
      ) {
        isIntegrationFailure = false;
      } else {
        isIntegrationFailure = true;
      }
      return { integrationFailure, isIntegrationFailure };
    },
    [brands],
  );

  return {
    getIntegrationFailureData,
  };
};

export default useIntegrationFailure;
