/**
 * This file is the custome hook file.
 * It contains the useReportTicketGraph hook which is explained below.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { useQuery } from "@tanstack/react-query";
import { useReportFilters } from "src/routes/Report/hooks/reportFilters/useReportFilters";
import {
  ReportDateType,
  useReportInternalFilters,
} from "src/routes/Report/hooks/reportFilters/useReportInternalFilters";
import reportTicketGraphService, {
  ReportTicketGraphParams,
} from "src/services/report/reportTicketGraph.service";
import { CurrentStatusEndPoints } from "src/services/report/reportTicketList.service";
import { ReportTabsProps } from "../../ReportTabs/ReportTabs";
import { useParams } from "react-router-dom";
import { useAppSelector } from "src/store/store";

interface Props {
  endPoint: CurrentStatusEndPoints;
  tabProps: Omit<ReportTabsProps, "activeTabName" | "setActiveTabname">;
  reportDateType?: ReportDateType;
}

/**
 * This is the custome hook created to maintain all the graphs.
 */
function useReportTicketGraph({ endPoint, tabProps, reportDateType }: Props) {
  const { filtersForAPI } = useReportFilters();
  const { internalFilters } = useReportInternalFilters();
  const { integrationId, profileId } = useParams();

  const pageIntegrationId = integrationId
    ? decodeURIComponent(integrationId)
    : "";
    
  // Fetching disabledFeatures from the Redux store
  const disabledFeatures = useAppSelector(
    (state) => state.globals.currentUserData?.disabledFeatures
  );

  const { data: reportTicketGraph, status } = useQuery(
    [
      `useReportTicketGraph/${endPoint}`,
      filtersForAPI,
      internalFilters.filters,
      endPoint,
      internalFilters.reportDateType,
      tabProps,
      pageIntegrationId,
      profileId,
    ],
    {
      /**
       * It fetches graph for different endpoints and params using reportTicketGraphService.
       */
      queryFn: async () => {
        const params: ReportTicketGraphParams = {
          viewType: tabProps.showTabs === false ? "heatmapView" : "chartView",
        };
        if (tabProps.showDailyPart) {
          params.reportDateType = internalFilters.reportDateType;
        }
        if (filtersForAPI) {
          params.filters = filtersForAPI;
        }
        if (Object.keys(internalFilters.filters).length) {
          params.internalFilters = internalFilters.filters;
        }
        if (endPoint.startsWith("knowledgeBase")) {
          params.integrationId = pageIntegrationId;
        }
        if (endPoint.startsWith("bot/") && profileId) {
          params.botProfileId = profileId;
        }
        if (reportDateType !== undefined) {
          params.reportDateType = reportDateType;
        }
        return reportTicketGraphService(params, endPoint);
      },
      cacheTime: 0,
      staleTime: 0,
      enabled: !(disabledFeatures && disabledFeatures.includes("reports"))
    }
  );
  return { reportTicketGraph, status };
}

export default useReportTicketGraph;
