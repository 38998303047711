import { AllTags, getAllTag, GetAllTagParams } from "src/services/Settings/Tags/getAllTag";
import { RootState } from "src/store/store";

// thunk that fetches all tags
const fetchAllTheTagsThunk = async(
  payload: undefined,
  {getState}: {getState: Function}
) =>{
  try{
    const currentState: RootState = getState();
    const queryData: GetAllTagParams = {
      start: currentState.tagsSettings.tagIdList.length,
      limit: currentState.tagsSettings.tagsLimit,
      searchTerm: currentState.tagsSettings.filters.searchValue
    }

    // check search term is empty or not
    if((queryData.searchTerm?.length === 0)){
      delete queryData.searchTerm;
    }
    const tagsData: AllTags = await getAllTag(queryData);
    // console.log("---------------tagSettingThunk ------------ ");
    // console.log("Tags data ::: "+ JSON.stringify(tagsData));
    return {
      tags: tagsData.tags,
      tagIdList: tagsData.tagIdList,
      metaData: tagsData.metaData
    }
  }catch(error){
    throw error;
  }
};

export default {fetchAllTheTagsThunk};
