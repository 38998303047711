import { axiosJSON } from "src/globals/axiosEndPoints";



export interface IGoalSetting{
  goalId : number | string,
  goalName : string,
  isActive : boolean,
  goalValueType : string,
  goalType : string,
  goalValue : string,
  integrationId : number | string
}

export interface GetAllGoalSetting {
  goals: { [goalId: string | number]: IGoalSetting };
  goalIds: Array<number | string>;
  metaData: {
    count: number;
    total: number;
  };
}

export interface GetAllGoalSettingParams {
  integrationId: number|string,
  start: number;
  limit: number;
}

export async function getAllGoalSettings(params: GetAllGoalSettingParams) {
  const { data: res }: any = await axiosJSON.post(
    "/api/chatSetting/goals/getAll",
    params
  );

  if (res.error === true) {
    throw new Error(res.message as string);
  }

  const ret: GetAllGoalSetting = {
    goals: {},
    goalIds: [],
    metaData: {
      count: 0,
      total: 0
    }
  };
  ret.metaData.count = res.count;
  ret.metaData.total = res.totalCount;
  res.data.forEach((goal: IGoalSetting) => {
    ret.goals[goal.goalId] = goal;
    ret.goalIds.push(goal.goalId);
  });
  console.log("getAllGoalSettings service ret:: ", JSON.stringify(ret));
  return ret;
}

export interface IGoalTypeOption{
  key : string;
  name : string;
  // type : string;
}

export interface IGoalTypeOptionList{
  goalTypeOptions: {[key: string] : IGoalTypeOption};
  goalTypeOptionKeys: Array<string>;
}

export async function getGoalTypeOptions() {
  const { data } = await axiosJSON.get(
    `/api/chatSetting/goals/getGoalTypeOptions`,
  );
  console.log(data);
  if (data.error === true) {
    throw new Error(data.message as string);
  }

  const goalTypeOptionKeys: any = [];
  const goalTypeOptions: any = {};
  data.data.forEach((option: IGoalTypeOption ) => {
    goalTypeOptions[option.key] = option;
    goalTypeOptionKeys.push(option.key);
  });

  return { goalTypeOptionKeys, goalTypeOptions} as IGoalTypeOptionList;
}
