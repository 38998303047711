import {
  EditCustomerDetailsSlice,
  initialCustomerState,
  initialState,
} from "./editCustomerDetails.declarations";
import { v4 as uuidv4 } from "uuid";
import OtherEmail from "src/routes/Ticket/children/CustomerSB/children/CustomerInfo/Children/OtherEmail/OtherEmail";
import { PayloadAction } from "@reduxjs/toolkit";
import { ICustomerData } from "../shopifySidebar/shopifySidebar.slice";
import { produce } from "immer";

const updateCustomerDetails = (
  state: EditCustomerDetailsSlice,
  { payload }: PayloadAction<Omit<ICustomerData, "channels">>,
) => {
  state.customer.id = payload.id;
  state.customer.name = payload.name;
  state.customer.email = payload.email;
  payload.contacts?.otherEmails?.forEach((item, idx) => {
    return state.customer.contacts?.otherEmails?.splice(idx, 1, item);
  });
  if (state.customer.contacts?.phone && payload.contacts?.phone) {
    state.customer.contacts.phone = payload.contacts?.phone;
  }
  payload.contacts?.instagram?.map((item, idx) => {
    return state.customer.contacts?.instagram?.splice(idx, 1, item);
  });
  payload.contacts?.facebook?.map((item, idx) => {
    return state.customer.contacts?.facebook?.splice(idx, 1, item);
  });
  payload.contacts?.twitter?.map((item, idx) => {
    return state.customer.contacts?.twitter?.splice(idx, 1, item);
  });
  state.customer.primaryEmailEditable = payload.primaryEmailEditable;
};

const editCustomerProfile = (
  state: EditCustomerDetailsSlice,
  { payload }: PayloadAction<{ customerProfileImageUrl: string }>,
) => {
  if (
    payload.customerProfileImageUrl !== undefined ||
    payload.customerProfileImageUrl !== null
  ) {
    if (payload.customerProfileImageUrl.trim() !== "") {
      const profileImageId = payload.customerProfileImageUrl.split("/");
      state.customer.profileImageId = profileImageId[3];
      state.customer.customerProfileImageUrl = payload.customerProfileImageUrl;
    }
  }
};

const removeCustomerProfile = (
  state: EditCustomerDetailsSlice,
  { payload }: { payload: { customerProfileImageUrl: string } },
) => {
  const profileImageIdUrl = payload.customerProfileImageUrl.split("/");
  const profileImageId = profileImageIdUrl[3];

  if (profileImageId === state.customer.profileImageId) {
    state.customer.profileImageId = "";
    state.customer.customerProfileImageUrl = "";
  }
};

const addContact = (
  state: EditCustomerDetailsSlice,
  {
    payload,
  }: {
    payload: { id: string; type: string; value: string; countryCode?: string };
  },
) => {
  if (state.customer.id === payload.id) {
    switch (payload.type) {
      case "otherEmails":
        state.customer.contacts?.otherEmails?.push(payload.value);
        break;
      case "phone":
        state.customer.contacts?.phone?.push({
          countryCode: payload?.countryCode ?? "",
          phoneNumber: payload.value,
        });
        // state.customer.contacts?.phone.push(payload.value);
        break;
      case "instagram":
        state.customer.contacts?.instagram.push(payload.value);
        break;
      case "facebook":
        state.customer.contacts?.facebook.push(payload.value);
        break;
      case "twitter":
        state.customer.contacts?.twitter?.push(payload.value);
        break;

      default:
        break;
    }
  }
};

const deleteContact = (
  state: EditCustomerDetailsSlice,
  {
    payload,
  }: {
    payload: { id: string | number; type: string; arr: string[] };
    // payload: {};
  },
) => {
  if (state.customer.id === payload.id) {
    switch (payload.type) {
      // case "otherEmails":
      //   if (state.customer.contacts?.otherEmails) {
      //     state.customer.contacts.otherEmails = payload.arr;
      //   }

      //   break;
      // case "phone":
      //   if (payload.idx > -1) {
      //     state.customer.contacts?.phone?.splice(payload.idx, 1);
      //   }
      //   break;
      // case "instagram":
      //   if (payload.idx > -1) {
      //     state.customer.contacts?.instagram?.splice(payload.idx, 1);
      //   }
      //   break;
      // case "facebook":
      //   if (payload.idx > -1) {
      //     state.customer.contacts?.facebook?.splice(payload.idx, 1);
      //   }
      //   break;
      // case "twitter":
      //   if (payload.idx > -1) {
      //     state.customer.contacts?.twitter?.splice(payload.idx, 1);
      //   }
      //   break;

      default:
        break;
    }
  }
};

const setCustomerData = (
  state: EditCustomerDetailsSlice,
  { payload }: PayloadAction<{ customer: ICustomerData }>,
) => {
  if (payload.customer.id !== undefined || payload.customer.id !== null) {
    state.customer.name = payload.customer.name;
    state.customer.email = payload.customer.email;
    state.customer.id = payload.customer.id;
    state.customer.primaryEmailEditable = payload.customer.primaryEmailEditable;

    // state.customer.channels = payload.customer.channels;
    state.customer.customerProfileImageUrl =
      payload.customer.customerProfileImageUrl;
    state.customer.profileImageId = payload.customer.profileImageId;
    if (payload.customer.contacts !== undefined) {
      state.customer.contacts = payload.customer.contacts;
    } else if (
      payload.customer.contacts !== undefined &&
      Array.isArray(payload.customer.contacts) &&
      (payload.customer.contacts as any[]).length === 0
    ) {
      // Check if the customer contacts property is not undefined, is an array, and has a length of 0
      // This condition indicates that there are no contacts for the customer
      state.customer.contacts = {
        // Update the customer's contacts property with an empty object
        otherEmails: [], // Initialize otherEmails as an empty array
        phone: [], // Initialize phone as an empty array
        whatsapp: [], // Initialize whatsapp as an empty array
        instagram: [], // Initialize instagram as an empty array
        facebook: [], // Initialize facebook as an empty array
        twitter: [], // Initialize twitter as an empty array
      };
    }
  }
};

export default {
  updateCustomerDetails,
  editCustomerProfile,
  removeCustomerProfile,
  addContact,
  deleteContact,
  setCustomerData,
};
