import { useEffect, useMemo, useState } from "react";
import emptyBackPng from "src/assets/images/EmptyHistory.png";
import styles from "../../ChatHistory.module.scss";

function useErrorMessage(isError: boolean, className="") {
  return useMemo(() => {
    if (isError) {
      return (
        <div className={`${className} m-auto`}>
          <img src={emptyBackPng} className={`d-block m-auto ${styles.errorImg}`} />
          <p className={`${styles.backtext} mt-2 mb-0`}>No History Available</p>
          <p className={`${styles.subBacktext} d-block mt-1`}>
            The history of all chats will appear here
          </p>
        </div>
      );
    } else {
      return undefined;
    }
  }, [isError]);
}

export default useErrorMessage;
