import { CloseButton, Modal } from "react-bootstrap";
import styles from "./SkeletonModalStructure.module.scss";

function SkeletonModalStructure(props: {
  children: React.ReactNode;
  heading: string;
  closeFunction: () => void;
  hasTwoSection: boolean;
  rightSide?: React.ReactNode;
}) {
  return (
    <div className={` ${styles.modalWrapper}`}>
      {/* Dec 13 change styles as per xd */}
      <div className="d-flex flex-wrap flex-md-nowrap">
        <Modal.Body>
          <div className={`d-flex align-items-center ${styles.heading} pb-3`}>
            {/* <CloseButton
              className={`${styles.closeButton} `}
              onClick={() => {
                props.closeFunction();
              }}
            /> */}
            <span
              className={`${styles.closeModal}`}
              onClick={() => {
                props.closeFunction();
              }}
            >
              <i className="fa-solid fa-xmark"></i>
            </span>

            <h5 className={`ms-3 mb-0 d-inline-block ${styles.modalHeading}`}>
              {props.heading}
            </h5>
          </div>
          {props.children}
        </Modal.Body>
        {props.hasTwoSection ? (
          <div className={`${styles.rightSection} `}>{props.rightSide}</div>
        ) : null}
      </div>
    </div>
  );
}

export default SkeletonModalStructure;
