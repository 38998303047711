/**
 * This file is the react component file.
 * It contains the "ComparisionModal" component for creating a segment with filters.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 * @author Navjyot
 */

import useFiltersApplied from "src/routes/Report/hooks/reportFilters/useFiltersApplied";
import styles from "./Comparision.module.scss";
import FilterWrapper from "src/routes/Report/Children/FilterWrapper/FilterWrapper";
import {
  SegmentFilter,
  reportSegmentFiltersActions,
  useReportSegmentFilters,
} from "src/routes/Report/hooks/reportFilters/useSegmentFilters";
import {
  reportCurrentStatusActions,
  useReportFilters,
} from "src/routes/Report/hooks/reportFilters/useReportFilters";
import { useCallback, useMemo, useRef, useState } from "react";
import { v4 as uuid } from "uuid";
import { CurrentStatusEndPoints } from "src/services/report/reportTicketList.service";

// Props interface for ComparisionModal
interface Props {
  onHide: (e: any) => void;
  endPoint: CurrentStatusEndPoints;
}

// Hook for managing the ComparisionModal state and logic
const useComparisionModal = ({ onHide, endPoint }: Props) => {
  // Custom hook to get applied filters
  const { filtersApplied } = useFiltersApplied({ endPoint });
  // Custom hooks for managing segment filters and report filters
  const { filtersForAPI, dispatch: dispatchRF } = useReportFilters();
  const appliedFilters = useMemo(() => {
    const af = { ...filtersForAPI };
    delete af.endDate;
    delete af.startDate;
    return af;
  }, [filtersForAPI]);
  const { segmentFilters, dispatch } = useReportSegmentFilters();
  // State for segment name, error display, and name duplication check
  const [name, setName] = useState("");
  const [showErr, setShowErr] = useState(false);

  // Check if the entered name is a duplicate
  const isNameDuplicate = useMemo(() => {
    return segmentFilters.allSegmentFilters
      .map((data) => data.name.trim().toLowerCase())
      .includes(name.trim().toLowerCase());
  }, [name, segmentFilters]);

  // Function to apply the segment filter
  const applySegmentFilter = useCallback(
    (e: any) => {
      if (
        Object.keys(appliedFilters).length > 0 &&
        !isNameDuplicate &&
        name.trim() !== ""
      ) {
        const payload: SegmentFilter = {
          name: name.trim(),
          filters: { ...appliedFilters },
        };
        dispatch([reportSegmentFiltersActions.appendSegmentFilter, payload]);
        dispatchRF([reportCurrentStatusActions.resetState, undefined]);
        setShowErr(false);
        onHide(e);
      } else {
        setShowErr(true);
      }
    },
    [appliedFilters, name, isNameDuplicate, onHide]
  );

  // Function to cancel filters
  const cancelFilters = useCallback(
    (e: any) => {
      dispatchRF([reportCurrentStatusActions.resetState, undefined]);
      onHide(e);
    },
    [onHide]
  );

  // Function to handle name change
  const changeName = useCallback((e: any) => {
    setName(e.target.value);
  }, []);

  // Return the state and functions for the ComparisionModal
  return {
    filtersApplied,
    name,
    showErr,
    isNameDuplicate,
    changeName,
    applySegmentFilter,
    cancelFilters,
  };
};

// ComparisionModal component definition
const ComparisionModal = ({ onHide, endPoint }: Props) => {
  // Destructure values from the hook
  const {
    filtersApplied,
    name,
    showErr,
    isNameDuplicate,
    changeName,
    applySegmentFilter,
    cancelFilters,
  } = useComparisionModal({ onHide, endPoint });

  // Ref for generating unique id
  const uuidRef = useRef(uuid());

  // Render the component UI
  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <p className={`mb-0 ${styles.modalHead}`}>Create a Segment</p>
        <span className="cursor-pointer" onClick={cancelFilters}>
          <i className="fa-solid fa-xmark"></i>
        </span>
      </div>
      <div>
        <div className="mb-3">
          <label
            htmlFor={uuidRef.current + "report-segment-name"}
            className={`form-label ${styles.segmentName}`}
          >
            Segment Name:
          </label>
          <input
            type="text"
            className={`form-control ${styles.formInput} ${
              showErr && (name.trim() === "" || isNameDuplicate)
                ? "redBorder"
                : ""
            }`}
            id={uuidRef.current + "report-segment-name"}
            value={name}
            onChange={changeName}
            placeholder="Add a name..."
          />
          {showErr && isNameDuplicate ? (
            <div className={`mt-1 ${styles.filterError}`}>
              * Name already exists
            </div>
          ) : (
            ""
          )}
        </div>
        <div>
          <p className={`mb-0 ${styles.segmentName}`}>Segment Filters:</p>
          <div className={`${styles.filterMain}`}>
            <FilterWrapper
              isSegement={true}
              scope={endPoint.includes("liveChat") ? "liveChat" : undefined}
            />
          </div>

          {showErr && filtersApplied.length === 0 ? (
            <div className={`mt-2 ${styles.filterError}`}>
              * Select at least one filter
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="mt-2">
          {filtersApplied.length > 0 ? (
            <p className={`${styles.segmentPara}`}>
              This segment will contain details of:
            </p>
          ) : (
            <></>
          )}
          {filtersApplied.map((data) => {
            return (
              <div
                key={data.name}
                className={`d-inline-flex px-2 py-1 mb-1 me-2 ${styles.filterBox}`}
              >
                <span className={`${styles.statusTruncate} my-auto`}>
                  {data.name} - {data.value}
                </span>
                <i
                  className="cursor-pointer ms-2 my-auto fa-solid fa-xmark"
                  onClick={data.callback}
                ></i>
              </div>
            );
          })}
        </div>
        <div className="d-flex justify-content-end mt-5 pt-5">
          <button
            className={`me-2 ${styles.cancelBtn}`}
            onClick={cancelFilters}
          >
            Cancel
          </button>
          <button className={`${styles.applyBtn}`} onClick={applySegmentFilter}>
            Apply
          </button>
        </div>
      </div>
    </>
  );
};

export default ComparisionModal;
