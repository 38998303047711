import CenterModal from "src/components/CenterModal/CenterModal";
import BaseButton from "../commons/BaseButton/BaseButton";
import styles from "./ChangeTikcetAStatus.module.scss";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/store/store";
import log from "loglevel";
import useResetBulkActions from "src/hooks/useResetBulkActions";
import TicketStatusList from "./children/TicketStatusList";
import Loader from "src/components/Loader";
import AddBulkStatusSuccess from "./children/AddBulkStatusSuccess/AddBulkStatusSuccess";
import ModalFooter from "./children/ModalFooter";
import {
  fetchAllStatuses,
  resetBulkTicketsStatuses,
} from "src/store/slices/ticketStatus/ticketStatus.slice";
import { useParams } from "react-router-dom";

function ChangeTicketStatus() {
  const { tableType } = useParams();
  const [modalShow, setModalShow] = useState(false);
  const {
    selectedStatusId,
    allTicketStatuses,
    bulkAddStatusState,
    fetchAllStatusesState,
  } = useAppSelector((state) => state.ticketStatus);

  const dispatch = useAppDispatch();
  const resetBulkActions = useResetBulkActions({ tableType: tableType });
  //  useEffect(() => () => resetBulkActions(), []);

  const bulkAddStatusStateRef = useRef(bulkAddStatusState);

  useEffect(() => {
    bulkAddStatusStateRef.current = bulkAddStatusState;
  }, [bulkAddStatusState]);

  const handleOnClick = () => {
    setModalShow(true);
    // log.debug("clicked bulk change tickets status button");
    dispatch(resetBulkTicketsStatuses());
    dispatch(fetchAllStatuses());
  };

  const handleOnHideModal = () => {
    setModalShow(false);
    if (bulkAddStatusStateRef.current !== "idle") {
      resetBulkActions(); //resetting bulk action filters, refetching the tickets, as new action has been performed
    }
  };

  return (
    <>
      <BaseButton
        FAIcon={"fas fa-plus"}
        BtnText={
          bulkAddStatusState === "fulfilled"
            ? allTicketStatuses[selectedStatusId].statusName
            : "Change ticket status"
        }
        BtnAction={handleOnClick}
        Selected={modalShow}
        style={{ maxWidth: "180px" }}
        id="changeTicketStatusBtn"
      />
      <CenterModal
        show={modalShow}
        onHide={handleOnHideModal}
        customTitleComp={
          bulkAddStatusState === "fulfilled" ? undefined : CustomTitleComp
        }
        customFooterComp={ModalFooter}
        id="changeStatusTicketModal"
      >
        {fetchAllStatusesState === "fulfilled" &&
          bulkAddStatusState !== "pending" &&
          bulkAddStatusState !== "fulfilled" && <TicketStatusList />}

        {bulkAddStatusState === "pending" && (
          <Loader style={{ marginTop: "-90px" }} />
        )}

        {fetchAllStatusesState === "pending" && (
          <Loader style={{ marginTop: "-90px" }} />
        )}

        {/* <Loader style={{ marginTop: "-90px" }} /> */}

        {bulkAddStatusState === "fulfilled" && <AddBulkStatusSuccess />}
      </CenterModal>
    </>
  );
}

const CustomTitleComp = () => (
  <div className="d-flex justify-content-between">
    <h5 className={`${styles["addTag_Title"]}`}>
      <span className={`me-2`}>Mark ticket status as </span>
    </h5>
  </div>
);

export default ChangeTicketStatus;
