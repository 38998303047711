import { BugData } from "src/services/BugReport/getBugByCategory.service";
import styles from "./TableHeader.module.scss";
import { useState } from "react";
import { BugReport } from "src/services/BugReport/getBugListByCategoryId.service";

interface TableHeaderProps {
  bugList: Array<BugData> | undefined;
  setSelectedBug: React.Dispatch<React.SetStateAction<Set<number>>>;
}

const TableHeader = ({ bugList, setSelectedBug }: TableHeaderProps) => {
  const [isSelected, SetIsSelected] = useState(false);
  const handleCheckBoxSelection = () => {
    SetIsSelected(prevStage => !prevStage)
    setSelectedBug((prevSelectedBugs) => {
      const newSelectedBugs = new Set(prevSelectedBugs);
      bugList?.map((bug) => {
        if (isSelected) {
          newSelectedBugs.delete(bug.bugId);
        } else {
          newSelectedBugs.add(bug.bugId);
        }
      });
      return newSelectedBugs;
    });
  };
  return (
    <div className={`d-flex align-items-center mb-2 ${styles.tableHeader}`}>
      <div className={`${styles.tableContentReport}`}>
        <div className="form-check d-flex align-items-center">
          <input
            className={`form-check-input ${styles.checkBox}`}
            type="checkbox"
            value=""
            checked={isSelected}
            id="flexCheckDefault"
            onChange={handleCheckBoxSelection}
          />
          <label
            className="form-check-label ms-3"
            htmlFor="flexCheckDefault"
          >
            Reporter
          </label>
        </div>
      </div>
      <div className={`${styles.tableContent}`}>
        <span>Company</span>
      </div>
      <div className={`${styles.tableContentPriority}`}>
        <span>Priority</span>
      </div>
      <div className={`${styles.tableContentDesc}`}>
        <span>Issue Description</span>
      </div>
      <div className={`${styles.tableContent}`}>
        <span>Media</span>
      </div>
      <div className={`${styles.tableContent}`}>
        <span>Category</span>
      </div>
      <div className={`${styles.tableContentStatus}`}>
        <span>Status</span>
      </div>
      <div className={`${styles.tableContent}`}>
        <span>Actions</span>
      </div>
    </div>
  );
};

export default TableHeader;
