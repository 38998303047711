import styles from "./OrderSummary.module.scss";
import refresh from "src/assets/images/chat/refreshButton.png";
import dummy1 from "src/assets/images/order1.png";
import dummy2 from "src/assets/images/order2.png";
import bag from "src/assets/images/chat/bag.png";
import gift from "src/assets/images/chat/gift.png";
import { useAppSelector } from "src/store/store";
const OrderSummary = () => {
  const selectedChargeTab = useAppSelector((state) => {
    // if (state.step15Configuration.selectedAdditionalChargeId === "1") {
    //   return state.step15Configuration.allOrderAndItemTab;
    // }
    return state.step15Configuration.additionalCharges.find(
      (adc) =>
        adc.additionalChargeId ===
        state.step15Configuration.selectedAdditionalChargeId,
    );
  });

  function checkIsNan(x: number) {
    if (isNaN(x)) {
      return 0;
    }
    return x;
  }

  return (
    <>
      <div>
        <div className={`d-flex align-items-center`}>
          <div>
            <img
              src={refresh}
              alt="refresh"
              className={`${styles.refresh}`}
            />{" "}
          </div>
          <span className={`ms-1 ${styles.heading}`}>
            Initiate Return/Exchange{" "}
          </span>
        </div>
        <div>
          <span className={`d-block py-2 ${styles.selItem}`}>
            Order Summary :
          </span>
        </div>
        <div className={`py-3 px-3 mb-2 ${styles.cardBox}`}>
          <div className="pb-2 d-flex align-items-center ">
            <div>
              <img
                src={bag}
                className={`${styles.bag}`}
                alt=""
              />
            </div>
            <div className="ps-1">
              <span className={`${styles.itemSpan}`}>
                Item(s) you will return (3) :
              </span>
            </div>
          </div>
          <div className="d-flex my-2">
            <img
              src={dummy1}
              className={`${styles.orderImg}`}
            />
            <div className={`ms-1`}>
              <span className={`d-block ${styles.orderName}`}>
                Acme White T- shirt
              </span>
              <span className={`d-block ${styles.orderData}`}>$ 789 x 1</span>
              <div className="d-flex justify-content-between">
                <span className={`d-inline-block ${styles.orderData}`}>
                  SKU : 0101
                </span>
              </div>
            </div>
          </div>
          <div className="d-flex my-2 ">
            <img
              src={dummy1}
              className={`${styles.orderImg}`}
            />
            <div className={`ms-1`}>
              <span className={`d-block ${styles.orderName}`}>
                Acme White T- shirt
              </span>
              <span className={`d-block ${styles.orderData}`}>$ 789 x 1 </span>
              <div className="d-flex justify-content-between">
                <span className={`d-inline-block ${styles.orderData}`}>
                  SKU : 0101
                </span>
              </div>
            </div>
          </div>
          <div className="d-flex my-2 ">
            <img
              src={dummy2}
              className={`${styles.orderImg}`}
            />
            <div className={`ms-1`}>
              <span className={`d-block ${styles.orderName}`}>
                Acme White T- shirt
              </span>
              <span className={`d-block ${styles.orderData}`}>$ 30 x 1 </span>
              <div className="d-flex justify-content-between">
                <span className={`d-inline-block ${styles.orderData}`}>
                  SKU : 0101
                </span>
              </div>
            </div>
          </div>
          <div>
            <div className="pb-2 pt-2 d-flex align-items-center ">
              <div>
                <img
                  src={bag}
                  className={`${styles.bag}`}
                  alt=""
                />
              </div>
              <div className="ps-1">
                <span className={`${styles.itemSpan}`}>
                  Item(s) you will receive (1) :
                </span>
              </div>
            </div>
            <div className="d-flex my-2">
              <img
                src={dummy1}
                className={`${styles.orderImg}`}
              />
              <div className={`ms-1`}>
                <span className={`d-block ${styles.orderName}`}>
                  Acme White T- shirt
                </span>
                <span className={`d-block ${styles.orderData}`}>
                  {" "}
                  $ 800 x 1
                </span>
                <div className="d-flex justify-content-between">
                  <span className={`d-inline-block ${styles.orderData}`}>
                    SKU : 0101
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="pt-1 pb-3">
            <div className={`d-flex align-items-center pb-2`}>
              <div>
                <img
                  src={gift}
                  className={`me-1 ${styles.giftBox}`}
                  alt=""
                />
              </div>
              <div>
                <h6 className={`mb-0 pe-1 ${styles.disHead}`}>
                  Offers Applied
                </h6>
              </div>
            </div>
            <p className={`mb-0 ${styles.disPara}`}>
              10 % discount on your next order instead of exchange
            </p>
          </div>
          <div className={`${styles.separation}`}></div>
          <div className={`pt-3 ${styles.orderSummary}`}>
            <h5 className={`${styles.amount}`}>Amount to be paid :</h5>
            <div className={`row mb-2`}>
              <div className={`col-9`}>
                <span className={`${styles.billText}`}>
                  Total Paid On Original Order
                </span>
              </div>
              <div className={`col-3`}>
                <span className={`${styles.billAmount}`}> $40</span>
              </div>
            </div>
            <div className={`row mb-2`}>
              <div className={`col-9`}>
                <span className={`${styles.billText}`}>
                  Total Value Removed From Order
                </span>
              </div>
              <div className={`col-3`}>
                <span className={`${styles.billAmount}`}> $20</span>
              </div>
            </div>
            <div className={`row mb-2`}>
              <div className={`col-9`}>
                <span className={`${styles.billText}`}>
                  Total Value Added To Order
                </span>
              </div>
              <div className={`col-3`}>
                <span className={`${styles.billAmount}`}>$60</span>
              </div>
            </div>
            {selectedChargeTab?.restockingFees ? (
              <div className={`row mb-2`}>
                <div className={`col-9`}>
                  <span className={`${styles.billText}`}>Restocking Fee</span>
                </div>
                <div className={`col-3`}>
                  <span className={`${styles.billAmount}`}>
                    {selectedChargeTab.restockingFees.priceType === "fixed" &&
                      `$`}
                    {""}
                    {checkIsNan(selectedChargeTab.restockingFees.amount ?? 0)}
                    {""}
                    {selectedChargeTab.restockingFees.priceType ===
                      "percentage" && `%`}
                  </span>
                </div>
              </div>
            ) : null}

            {selectedChargeTab?.customShippingCharges.currentState ? (
              <div className={`row mb-2`}>
                <div className={`col-9`}>
                  <span className={`${styles.billText}`}>
                    Custom Shipping Charges
                  </span>
                </div>
                <div className={`col-3`}>
                  <span className={`${styles.billAmount}`}>
                    {checkIsNan(selectedChargeTab.customShippingCharges.amount)}
                  </span>
                </div>
              </div>
            ) : null}

            <div className={`mb-2 ${styles.separation}`}></div>
            <div className={`row mb-2`}>
              <div className={`col-9`}>
                <span className={`${styles.extraAmount}`}>
                  Extra amount To Be Paid
                </span>
              </div>
              <div className={`col-3`}>
                <span className={`${styles.extraAmount}`}>$23</span>
              </div>
            </div>
            <div className={`row mb-2`}>
              <div className={`col-9`}>
                <span className={`${styles.extraAmount}`}>Refund amount</span>
              </div>
              <div className={`col-3`}>
                <span className={`${styles.extraAmount}`}>$0</span>
              </div>
            </div>
            <div className={`mb-2 ${styles.separation}`}></div>
            <div className={`row mb-2`}>
              <div className={`col-9`}>
                <span className={`${styles.extraAmount}`}>Total :</span>
              </div>
              <div className={`col-3`}>
                <span className={`${styles.extraAmount}`}>$23</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default OrderSummary;
