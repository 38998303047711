import React, { useCallback, useEffect, useRef } from "react";
import TableHeader from "../../TableHeader/TableHeader";
import TableRow from "../../TableRow/TableRow";
import BugBulkAction from "../../BugBulkAction/BugBulkAction";
import { BugData } from "src/services/BugReport/getBugByCategory.service";
import useBugCategoriesPagination from "../useBugCategoryPagination";
import Pagination from "src/routes/TicketList/children/Pagination/Pagination";
import styles from "./../BugCategoriesHalfView/BugCategoriesHalfView.module.scss";
import { BugReport } from "src/services/BugReport/getBugListByCategoryId.service";
import Loader from "src/components/Loader";
export interface BugCategoryParam {
  categoryName: string;
  categoryId: number;
  seeMore: boolean;
  selectedBugId: Set<number>;
  setSelectedBugId: React.Dispatch<React.SetStateAction<Set<number>>>;
  pendingForAction: boolean;
  setSelectedBugsData: React.Dispatch<React.SetStateAction<BugData[]>>;
}

const BugCategoriesFullView = ({
  categoryName,
  categoryId,
  seeMore,
  selectedBugId,
  setSelectedBugId,
  pendingForAction,
  setSelectedBugsData,
}: BugCategoryParam) => {
  const { BugList, totalPages, currentPage, changePage, isLoading, isError } =
    useBugCategoriesPagination(categoryId, pendingForAction);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth", block: "end" });
  }, [isLoading]);

  if (isError) return <h3 className="text-center">Something went wrong...</h3>;

  if (isLoading) return <Loader />;

  return (
    <div ref={ref}>
      <div className={`w-100 ${styles.halfViewWrapper}`}>
        <TableHeader
          bugList={BugList?.data}
          setSelectedBug={setSelectedBugId}
        />
        {BugList?.data.map((bug: BugData) => (
          <TableRow
            key={bug.bugId}
            bugData={bug}
            categoryName={categoryName}
            selectedBugId={selectedBugId}
            setSelectedBugId={setSelectedBugId}
            setSelectedBugsData={setSelectedBugsData}
          />
        ))}
      </div>
      <div className="p-3 mb-3">
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={changePage}
        />
      </div>
    </div>
  );
};

export default BugCategoriesFullView;
