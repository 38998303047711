import React from "react";
import useInputFields, {
  initialFields,
  InputFieldsContext,
} from "./useInputFields";

function InputFieldsContextProvider(props: { children: React.ReactNode }) {
  const {
    fields,
    addField,
    removeField,
    updateField,
    setFieldsOfKey,
    areFieldsSame,
    areDuplicateFields,
    primaryEmail,
    setPrimaryEmail,
  } = useInputFields(initialFields);
  return (
    <InputFieldsContext.Provider
      value={{
        addField,
        removeField,
        updateField,
        setFieldsOfKey,
        fields,
        areFieldsSame,
        areDuplicateFields,
        primaryEmail,
        setPrimaryEmail,
      }}
    >
      {props.children}
    </InputFieldsContext.Provider>
  );
}

export default InputFieldsContextProvider;
