import { useCallback, useEffect, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import Select from "react-select";
import {
  fetchGetAllOrderTags,
  fetchGetAllProductTags,
} from "src/store/slices/initiateReturn/returnExchange.thunk";
import { useAppDispatch, useAppSelector } from "src/store/store";
import styles from "./ComponentStyles.module.scss";

function Tags(props: any) {
  const dispatch = useAppDispatch();
  const [tagsOptions, setTagsOptions] = useState<any>([]);

  const orderTagsOptions = useAppSelector((state) =>
    state.returnExchange.getAllOrderTags.data.map((tag) => {
      return { value: tag, label: tag };
    }),
  );

  const itemTagsOptions = useAppSelector((state) =>
    state.returnExchange.getAllProductTags.data.map((tag) => {
      return { value: tag, label: tag };
    }),
  );

  const {
    getAllOrderTagsAjax,
    getAllOrderTags,
    getAllProductTags,
    getAllProductTagsAjax,
  } = useAppSelector((state) => state.returnExchange);

  var arr = [];
  for (var i = 0; i < props.tags.length; i++) {
    arr.push({
      value: props.tags[i],
      label: props.tags[i],
    });
  }

  const [showPopover, setShowPopover] = useState(true);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    if (props.error) {
      setShowPopover(true);
    } else {
      setShowPopover(false);
    }
  }, [props.error]);

  useEffect(() => {
    if (props?.isItemTag) {
      setTagsOptions(itemTagsOptions);
    } else {
      setTagsOptions(orderTagsOptions);
    }
  }, [
    props?.isItemTag,
    JSON.stringify(orderTagsOptions),
    JSON.stringify(itemTagsOptions),
  ]);

  const popover =
    props.error && props.error.length > 0 ? (
      <Popover
        id="popover-basic"
        className="rounded-3"
      >
        <Popover.Body className="d-flex flex-row">
          <span className="border px-2 bg-warning text-white me-2 rounded-3 m-auto mt-1">
            !
          </span>
          <div className="mt-1">{props.error}</div>
        </Popover.Body>
      </Popover>
    ) : (
      <></>
    );

  const handleLoadOptions = useCallback(
    (e: any) => {
      const limit = 25;

      if (props?.isItemTag) {
        if (
          getAllProductTagsAjax !== "pending" &&
          getAllProductTags.metaData.hasNextPage === true
        ) {
          dispatch(
            fetchGetAllProductTags({
              limit: getAllProductTags.metaData.count + limit,
            }),
          );
        }
      } else {
        if (
          getAllOrderTagsAjax !== "pending" &&
          getAllOrderTags.metaData.hasNextPage === true
        ) {
          dispatch(
            fetchGetAllOrderTags({
              limit: getAllOrderTags.metaData.count + limit,
            }),
          );
        }
      }
    },
    [
      getAllOrderTagsAjax,
      getAllOrderTags.metaData,
      getAllProductTags.metaData,
      getAllProductTagsAjax,
      props?.isItemTag,
    ],
  );

  return (
    <>
      <OverlayTrigger
        show={showPopover}
        placement="bottom"
        overlay={popover}
        rootClose={true}
        rootCloseEvent="mousedown"
        onToggle={(isShown) => {
          // Set the state variable based on the popover visibility
          if (!menuOpen) {
            setShowPopover(isShown);
          }
        }}
      >
        <div
          className={`ms-lg-2 ${styles.thirdSelect}`}
          onClick={() => {
            setShowPopover(false);
          }}
          style={{
            maxWidth: "20rem",
            minWidth: "16rem",
          }}
        >
          <Select
            defaultValue={arr.map((tag) => tag)}
            value={arr.map((tag) => tag)}
            isMulti
            name="tags"
            options={tagsOptions}
            styles={customStyles}
            className={`basic-multi-select ${
              props.error ? styles.borderDanger : ""
            } ${props.showValidation && "border border-danger rounded"}`}
            classNamePrefix="select"
            isClearable={false}
            placeholder="search tag"
            isLoading={
              props?.isItemTag
                ? getAllProductTagsAjax === "pending"
                : getAllOrderTagsAjax === "pending"
            }
            onChange={(e) => props.onChange(e.map((s) => s.value))}
            onMenuClose={() => {
              setMenuOpen(false);
            }}
            onMenuOpen={() => {
              setMenuOpen(true);
              if (props?.isItemTag) {
                if (
                  getAllProductTagsAjax !== "pending" &&
                  itemTagsOptions.length === 0
                ) {
                  dispatch(fetchGetAllProductTags({ limit: 25 }));
                }
              } else {
                if (
                  getAllOrderTagsAjax !== "pending" &&
                  orderTagsOptions.length === 0
                ) {
                  dispatch(fetchGetAllOrderTags({ limit: 25 }));
                }
              }
            }}
            onMenuScrollToBottom={handleLoadOptions}
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator: () => null,
            }}
          />
          <span className={styles.errorText}>
            {props.error && props.error.length > 0 && props.error}
          </span>
        </div>
      </OverlayTrigger>
    </>
  );
}
const customStyles = {
  control: (provided: any, _: any) => ({
    ...provided,
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: "#707070",
    fontSize: "14px !important", // Placeholder font size
  }),
};

export default Tags;
