import styles from "./PreviewSection.module.scss";
import StepsConfigured from "../StepsCofigured/StepConfigured";
import { useAutomationContext } from "../../../../Hooks/useAutomationContext";
import Loader from "src/components/Loader";

const PreviewSection = ({ activeIndex }: { activeIndex: number }) => {
  const { isLoading, errorMessage: fetchConfigErrorMessage } =
    useAutomationContext();

  if (isLoading) {
    return (
      <div className={`p-3 ${styles.settingWrap}`}>
        <Loader />
      </div>
    );
  }

  if (fetchConfigErrorMessage) {
    return (
      <div className={`p-3 ${styles.settingWrap}`}>
        <div className="d-flex align-items-center justify-content-center text-danger">
          {fetchConfigErrorMessage}
        </div>
      </div>
    );
  }

  return (
    <div className={`mt-4  m-auto ${styles.previewWrapper}`}>
      <div className={`mt-3 mx-auto ${styles.teamHeading} py-2`}>
        <span className={`${styles.teamActive}`}>Steps configured</span>
      </div>
      <StepsConfigured activeIndex={activeIndex} />
    </div>
  );
};

export default PreviewSection;
