import { createSelector, createSlice } from "@reduxjs/toolkit";
import { builderFunction } from "./chatWidExchMsg.builder";
import { initialState } from "./chatWidExchMsg.declarations";
import chatWidExchMsgReducers from "./chatWidExchMsg.reducers";
import {
  areAllAgentSelected,
  isAgentIdSelected,
} from "./chatWidExchMsg.selectors";

export const chatWidExchMsgSlice = createSlice({
  name: "chatWidExchMsg",
  initialState,
  reducers: chatWidExchMsgReducers,
  extraReducers: builderFunction,
});

export const {
  resetChatWidExchMsg,
  addNewMessageChatWidExchMsg,
  setActiveCustomerInfo,
  setTicketData,
  setIsResolved,
  addAgentToAssignToList,
  removeAgentFromAssignToList,
  resetAssignToAgentList,
  selectAgentForAssignToSelect,
  removeAgentFormAssignToSelect,
  selectAllAgentForAssignToSelect,
  appendMessageBeforeSending, //append message to message list before sending
  removeAllAgentForAssignToSelect,
  messageSendTryAgainPressed, //try again pressed for message
  updateAttributes,
  setActiveChatScheduledMessageCount,
  markMessagesSeen, //mark message as seen
  updateTryAgainMessage,
  updateCustomerInfo,
  clearIntegrationFailure, // For integration failure for try again
} = chatWidExchMsgSlice.actions;

export const isAgentIdSelectedForAssignToSelector = createSelector(
  [isAgentIdSelected],
  (isAgentIdSelected) => isAgentIdSelected,
);

export const areAllAgentSelectedForAssignToSelector = createSelector(
  [areAllAgentSelected],
  (areAllAgentSelected) => areAllAgentSelected,
);

export default chatWidExchMsgSlice.reducer;
