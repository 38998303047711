import { useCallback, useMemo } from "react";
import { IActionForModal } from "src/services/Automation/getAutomationById.service";
import { useActionsState } from "../../../../../hooks/useActionsState";
import { FieldDataTypes } from "src/services/Automation/AutomationTicketRouting/getActionOptions.service";
import SelectInput from "../../../ConfigurationModal/Components/ConditionValue/children/SelectInput";
import MultiSelectStringInput from "../../../ConfigurationModal/Components/ConditionValue/children/MultiSelectStringInput";
import StringTinymcInput from "../../../ConfigurationModal/Components/ConditionValue/children/StringTinymcInput";
interface Props {
  fieldKey: string;
  fieldDataType: FieldDataTypes;
  isMulti?: boolean;
  conditionIndex: number;
}

const ActionValue = ({
  fieldDataType,
  fieldKey,
  isMulti = false,
  conditionIndex,
}: Props) => {
  const { conditionGroup, updateState, showErrors } = useActionsState();

  const selectedvalue = useMemo(() => {
    const value = (conditionGroup?.conditions ?? [])[conditionIndex];
    if (value !== undefined) {
      return value;
    } else {
      return null;
    }
  }, [conditionGroup, conditionIndex]);

  const handleValueUpdate = useCallback(
    function (v: IActionForModal["value"], labels?: Record<string, string[]>) {
      if (conditionGroup) {
        // Create a new array by mapping over the existing conditions
        const newActions = conditionGroup.conditions.map((condition, index) => {
          if (index === conditionIndex) {
            // Update only the condition at the specified index
            return {
              ...condition,
              value: v,
              valuesOfSelectKeys: labels,
              fieldDataType: fieldDataType,
            };
          }
          // Keep other conditions unchanged
          return condition;
        });

        updateState({
          conditionGroup: {
            ...conditionGroup,
            conditions: newActions,
          },
          showErrors: false,
          saveStatus: "idle",
        });
      }
    },
    [updateState, conditionGroup, conditionIndex, fieldDataType],
  );

  if (fieldDataType) {
    if (fieldDataType === "string") {
      if (isMulti) {
        const value = selectedvalue?.value as Array<string> | null | undefined;
        return (
          <MultiSelectStringInput
            onChange={handleValueUpdate}
            placeholder={"Enter text here..."}
            isEmailError={false}
            value={value}
            error={showErrors ? "This field is required" : undefined}
          />
        );
      } else {
        return (
          <StringTinymcInput
            value={(selectedvalue?.value as string | null | undefined) ?? ""}
            onChange={handleValueUpdate}
            placeholder={"Enter text here..."}
            error={showErrors ? "This field is required" : undefined}
          />
        );
      }
    } else if (fieldDataType === "select") {
      return (
        <SelectInput
          fieldValues={[]}
          fieldKey={fieldKey}
          value={
            (selectedvalue?.value as string | Array<string> | undefined) ?? null
          }
          onChange={handleValueUpdate}
          valuesOfSelectKeys={selectedvalue?.valuesOfSelectKeys}
          placeholder={"Choose option"}
          isMulti={isMulti}
          error={showErrors ? "This field is required" : undefined}
          handlePush={() => {}}
          handlePop={() => {}}
          doNotFetchFromApi={false} //Fetching options from api itself
        />
      );
    }
  }
  return null;
};

export default ActionValue;
