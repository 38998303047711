import {
  GetFilterDatePicker,
  customStyles,
} from "src/routes/TicketList/children/FilterSection/children/Filter/children/FilterColDropdown/FilterColDropdown";
import Select, { MultiValue, SingleValue } from "react-select";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  SegmentFilter,
  SegmentFilterOption,
} from "src/services/CustomerSegments/getSegmentFilters.service";
import { AppliedFilters } from "src/services/CustomerSegments/getSegmentValues.service";
import { BetweenRange } from "src/services/CustomerSegments/createSegment.service";
import useFetchFiltersDD from "../../hooks/useFetchFiltersDD";

interface Props {
  // Represents the filter object containing details like type, options, etc.
  filter: SegmentFilter;
  // Holds the selected filter value(s)
  value: SegmentFilterOption | SegmentFilterOption[] | null;
  // Callback to handle change in filter selection
  handleChange: (
    value: SegmentFilterOption | SegmentFilterOption[] | null,
    filterKey: string,
  ) => void;
  // Holds date range or single date value
  dateValue: AppliedFilters["valueDate"];
  // Callback to handle change in date selection
  handleDateChange: (
    value: AppliedFilters["valueDate"],
    filterKey: string,
  ) => void;
  // Indicates whether to display validation errors
  showErrors: boolean;
}

/**
 * DatePickerForSelect component renders a date picker input for filters that require date selection.
 * It provides options to select date and time, handles validation errors, and updates date values.
 */
const DatePickerForSelect = ({
  value,
  dateValue,
  handleDateChange,
  showErrors,
  filterKey,
}: Omit<Props, "handleChange" | "filter" | "value"> & {
  value: SegmentFilterOption;
  filterKey: string;
}) => {
  // Memoized variables to manage selected date and time
  const { dateTime1, dateTime2 } = useMemo(() => {
    let dateTime1: null | string = null;
    let dateTime2: null | string = null;
    if (value.isRange) {
      const dv =
        typeof dateValue === "string"
          ? { start: dateValue, end: null }
          : (dateValue as BetweenRange<string | null> | undefined);
      if (dv?.start) {
        dateTime1 = dv.start;
      }
      if (dv?.end) {
        dateTime2 = dv.end;
      }
    } else {
      const dv =
        typeof dateValue === "object"
          ? dateValue?.start
            ? dateValue.start
            : undefined
          : (dateValue as string | undefined);
      if (dv) {
        dateTime1 = dv;
      }
    }
    return { dateTime1, dateTime2 };
  }, [dateValue, value]);

  // Callback to set selected date and time based on scope (date1, time1, date2, time2)
  const setSelectedDateTime = useCallback(
    (date: Date, scope: "date1" | "time1" | "date2" | "time2") => {
      // Formatting date and time
      const dateOnly =
        date.getFullYear() +
        "-" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + date.getDate()).slice(-2);

      const time =
        ("0" + date.getHours()).slice(-2) +
        ":" +
        ("0" + date.getMinutes()).slice(-2) +
        ":" +
        ("0" + date.getSeconds()).slice(-2);

      // Updating date and time based on scope (date1, time1, date2, time2)
      if (value.isRange) {
        if (scope === "time1") {
          let d = (dateTime1 ?? "").split(" ")[0];
          if (!d) {
            d = dateOnly;
          }
          handleDateChange(
            {
              start: `${d} ${time}`,
              end:
                typeof dateValue === "string"
                  ? dateValue
                  : (dateValue as BetweenRange<string | null> | undefined)
                      ?.end ?? null,
            },
            filterKey,
          );
        } else if (scope === "date1") {
          let t = (dateTime1 ?? "").split(" ")[1];
          if (!t) {
            t = time;
          }
          handleDateChange(
            {
              start: `${dateOnly}${t ? ` ${t}` : ""}`,
              end:
                typeof dateValue === "string"
                  ? dateValue
                  : (dateValue as BetweenRange<string | null> | undefined)
                      ?.end ?? null,
            },
            filterKey,
          );
        } else if (scope === "time2") {
          let d = (dateTime2 ?? "").split(" ")[0];
          if (!d) {
            d = dateOnly;
          }
          handleDateChange(
            {
              end: `${d} ${time}`,
              start:
                typeof dateValue === "string"
                  ? dateValue
                  : (dateValue as BetweenRange<string | null> | undefined)
                      ?.start ?? null,
            },
            filterKey,
          );
        } else if (scope === "date2") {
          let t = (dateTime2 ?? "").split(" ")[1];
          if (!t) {
            t = time;
          }
          handleDateChange(
            {
              end: `${dateOnly}${t ? ` ${t}` : ""}`,
              start:
                typeof dateValue === "string"
                  ? dateValue
                  : (dateValue as BetweenRange<string | null> | undefined)
                      ?.start ?? null,
            },
            filterKey,
          );
        }
      } else {
        if (scope === "time1") {
          let d = (dateTime1 ?? "").split(" ")[0];
          if (!d) {
            d = dateOnly;
          }
          handleDateChange(`${d} ${time}`, filterKey);
        } else if (scope === "date1") {
          let t = (dateTime1 ?? "").split(" ")[1];
          if (!t) {
            t = time;
          }
          handleDateChange(`${dateOnly}${t ? ` ${t}` : ""}`, filterKey);
        }
      }
    },
    [handleDateChange, value, dateTime1, dateTime2, dateValue, filterKey],
  );

  // Callbacks to set selected date and time for date1 and date2
  const setSelectedDate1 = useCallback(
    (date: Date) => {
      setSelectedDateTime(date, "date1");
    },
    [setSelectedDateTime],
  );
  const setSelectedTime1 = useCallback(
    (date: Date) => {
      setSelectedDateTime(date, "time1");
    },
    [setSelectedDateTime],
  );
  const setSelectedDate2 = useCallback(
    (date: Date) => {
      setSelectedDateTime(date, "date2");
    },
    [setSelectedDateTime],
  );
  const setSelectedTime2 = useCallback(
    (date: Date) => {
      setSelectedDateTime(date, "time2");
    },
    [setSelectedDateTime],
  );

  // Refs for date1 and date2 time inputs
  const date1TimeInputRef = useRef<HTMLDivElement | null>(null);
  const date2TimeInputRef = useRef<HTMLDivElement | null>(null);

  // Memoized variables to determine validation errors for date1 and date2
  const { isDate1Error, isDate2Error } = useMemo(() => {
    let isDate1Error = false;
    let isDate2Error = false;
    if ((value as SegmentFilterOption | null)?.showDateTimePicker) {
      if ((value as SegmentFilterOption | null)?.isRange) {
        if (
          typeof dateValue === "string"
            ? false
            : !(dateValue as BetweenRange<string | null> | undefined)?.start
        ) {
          isDate1Error = true;
        }
        if (!(dateValue as BetweenRange<string | null> | undefined)?.end) {
          isDate2Error = true;
        }
      } else {
        if (!dateValue) {
          isDate1Error = true;
        }
      }
    }
    return { isDate1Error, isDate2Error };
  }, [value, dateValue]);

  // Effect to scroll into view on validation error
  useEffect(() => {
    if (isDate1Error && showErrors && date1TimeInputRef.current) {
      date1TimeInputRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    } else if (isDate2Error && showErrors && date2TimeInputRef.current) {
      date2TimeInputRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [isDate1Error, isDate2Error, showErrors]);

  // Rendering date pickers based on isRange flag
  return (
    <div className="mt-3">
      <GetFilterDatePicker
        showError={showErrors && isDate1Error}
        dateRef={date1TimeInputRef}
        readOnly={false}
        selectedDate={
          (dateTime1 ?? "").split(" ")[0] ? new Date(dateTime1 + "") : null
        }
        selectedTime={
          (dateTime1 ?? "").split(" ")[1] ? new Date(dateTime1 + "") : null
        }
        onChangeDate={setSelectedDate1}
        onChangeTime={setSelectedTime1}
      />
      {value.isRange ? (
        <div className="mt-2">
          <GetFilterDatePicker
            showError={showErrors && isDate2Error}
            dateRef={date2TimeInputRef}
            readOnly={false}
            selectedDate={
              (dateTime2 ?? "").split(" ")[0] ? new Date(dateTime2 + "") : null
            }
            selectedTime={
              (dateTime2 ?? "").split(" ")[1] ? new Date(dateTime2 + "") : null
            }
            onChangeDate={setSelectedDate2}
            onChangeTime={setSelectedTime2}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

/**
 * ListFilter component renders a select dropdown filter.
 * It handles selection changes, includes a date picker if necessary, and validates user inputs.
 */
function ListFilter({
  filter,
  value,
  dateValue,
  handleChange,
  handleDateChange,
  showErrors,
}: Props) {
  // Memoized selected value based on filter type (single or multi-select)
  const selectedValue = useMemo(() => {
    return filter.isMulti
      ? (value as SegmentFilterOption[] | null)?.map((v) => ({
          label: v.name,
          value: v,
        })) ?? null
      : (value as SegmentFilterOption | null)
        ? {
            label: (value as SegmentFilterOption).name,
            value: value as SegmentFilterOption,
          }
        : null;
  }, [value, filter.isMulti]);

  const { filterDD, filterDDStatus, hasMore, fetchFilterDD } =
    useFetchFiltersDD({
      filterKey: filter.filterKey,
      options: filter?.selectOptions ?? [],
    });

  const handleOnMenuOpen = useCallback(() => {
    // fetch only if data is not already given.
    if (filterDD.length === 0) {
      fetchFilterDD();
    }
  }, [filterDD, fetchFilterDD]);

  return (
    <>
      {/* Select component to render dropdown */}
      <Select
        // Selected value(s) from dropdown
        value={selectedValue}
        // Options for dropdown based on filter's selectOptions
        options={filterDD.map((option) => ({
          label: option.name,
          value: option,
        }))}
        onMenuOpen={handleOnMenuOpen}
        // Infinite scroll only if has more data.
        onMenuScrollToBottom={hasMore ? fetchFilterDD : undefined}
        isLoading={filterDDStatus === "pending"}
        // Custom styles for the dropdown
        styles={customStyles}
        // Whether dropdown allows multiple selections
        isMulti={filter.isMulti}
        onChange={(selectedOption) => {
          // Handle change based on whether it's a multi-select or single-select dropdown
          if (filter.isMulti) {
            const option = selectedOption as MultiValue<{
              label: string;
              value: SegmentFilterOption;
            }>;
            handleChange(
              // Map selected options to their values
              option.map((v) => v.value),
              // Pass the filter key for identification
              filter.filterKey,
            );
          } else {
            const option = selectedOption as SingleValue<{
              label: string;
              value: SegmentFilterOption;
            }>;
            // Pass selected value or null if nothing selected
            handleChange(option?.value ?? null, filter.filterKey);
          }
        }}
        // Placeholder text for the dropdown
        placeholder={filter.placeholder ?? "Select"}
      />

      {/* Render DatePickerForSelect if it's a single-select and showDateTimePicker is true */}
      {!filter.isMulti &&
      (value as SegmentFilterOption | null)?.showDateTimePicker ? (
        <DatePickerForSelect
          // Current date value
          dateValue={dateValue}
          // Callback to handle date change
          handleDateChange={handleDateChange}
          // Whether to show validation errors
          showErrors={showErrors}
          // Selected value for the date picker
          value={value as SegmentFilterOption}
          // Filter key for identifying the date picker
          filterKey={filter.filterKey}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default ListFilter;
