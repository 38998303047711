import styles from "./MailLink.module.scss";
import NotResponse from "../NoResponse/NoResponse";
import OfflineMode from "../../../AskMail/OfflineMode/OfflineMode";
import { NavLink, useParams } from "react-router-dom";
import AgentOffline from "../AgentOffline/AgentOffline";
export const Links: {
  [key: string]: { name: string; component: any };
} = {
  NotRespond: { name: "NotRespond", component: NotResponse },
  offline: { name: "offline", component: AgentOffline },
};
const MailLink = () => {
  const { subSettingName, integrationId } = useParams();
  const pageIntegrationId = integrationId
    ? decodeURIComponent(integrationId)
    : "";

  return (
    <div className={`d-flex flex-column flex-wrap flex-lg-row ${styles.setUp}`}>
      {/* <span className={`me-3 ${styles.mailRes}`}>
                           </span> */}
      <NavLink
        to={`/live-chat/settings/${pageIntegrationId}/mail/${Links.NotRespond.name}`}
        className={`me-3 mb-2 mb-lg-0 ${styles.mailRes} ${
          subSettingName === Links.NotRespond.name ||
          subSettingName === undefined
            ? styles.active
            : ""
        }`}
      >
        When Not Responding - But Agent(s) Online
      </NavLink>
      <NavLink
        to={`/live-chat/settings/${pageIntegrationId}/mail/${Links.offline.name}`}
        className={`me-3 ${styles.mailRes} ${
          subSettingName === Links.offline.name ? styles.active : ""
        }`}
      >
        When Agent(s) offline
      </NavLink>
    </div>
  );
};
export default MailLink;
