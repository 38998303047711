import React from "react";
import SearchBar from "src/components/SearchBar/SearchBar";
import AgentRow from "./Children/AgentRow/AgentRow";
import styles from "./SetAgent.module.scss";
import dummy1 from "src/assets/images/chat/1.png";
import dummy2 from "src/assets/images/chat/2.png";
import dummy3 from "src/assets/images/chat/3.png";
import Pagination from "src/routes/TicketList/children/Pagination/Pagination";
import { useAppDispatch, useAppSelector } from "src/store/store";
import Loader from "src/components/Loader";
import { AJAXSTATUS } from "src/globals/constants";
import { BrandAgent } from "src/services/Settings/Brands/getBrand";
import {
  areAllUsersSelectedSelector,
  removeAllUsersFromBulkSelect,
  selectAllUsersFromBulkSelect,
} from "src/store/slices/settings/brands/brandSettings.slice";
import BrandUserBulkAction from "../BrandUserBulkActionSelection/BrandUserBulkAction";
import { Spinner } from "react-bootstrap";
interface Props {
  currentPage?: number;
  totalPages?: number;
  setCurrentPage?: (page: number) => void;
  setSearchValue: (value: string) => void;
  handleSearch: any;
  searchValue: string;
  users: any;
  userAjaxStatus: AJAXSTATUS;
  onSubmit: (e: any) => void;
  updateBrandAjaxStatus: AJAXSTATUS;
}
const SetAgent = ({
  currentPage = 0,
  totalPages = 0,
  setCurrentPage = () => {},
  searchValue,
  setSearchValue,
  handleSearch,
  users = [],
  userAjaxStatus,
  updateBrandAjaxStatus,
  onSubmit = (e: any) => {},
}: Props) => {
  const dispatch = useAppDispatch();

  const { bulkSelectedUserIds } = useAppSelector(
    (state) => state.brandSettings
  );
  const areAllUsersSelected = useAppSelector(areAllUsersSelectedSelector);
  const { userIds } = useAppSelector((state) => state.brandSettings);
  const scopes = useAppSelector(
    (state) => state.globals.currentUserData?.scopes
  );
  const handleOnChangeCheckbox = (e: any) => {
    // log.debug("toggled select all");
    if (scopes && scopes.includes("settings_can_edit_email_signature")) {
      if (areAllUsersSelected) {
        dispatch(removeAllUsersFromBulkSelect());
      } else {
        dispatch(selectAllUsersFromBulkSelect());
      }
    }
  };

  return (
    <>
      <div className={`mt-4`}>
        <p className={`${styles.heading}`}>
          Set agents who can access the E-mail of this brand
        </p>
        <div className={`py-3 px-3 ${styles.agentWrapper}`}>
          <SearchBar
            className={`${styles.search} px-2`}
            inputClassName={`${styles.input}`}
            placeholder={`Search agents by name, role...`}
            onChange={(e: any) => {
              setSearchValue(e.target.value);
            }}
            value={searchValue}
            onSearch={handleSearch}
          />
          <div className={`overflow-auto`}>
            <div className={`py-2 ps-4 pe-4 mt-4 d-flex ${styles.tableHeader}`}>
              <div className={`form-check me-2`} style={{ cursor: "pointer" }}>
                <input
                  className="form-check-input"
                  style={{
                    width: " 20px",
                    height: "20px",
                    borderRadius: "6px",
                    border: "1px solid #707070",
                    cursor: "pointer",
                  }}
                  type="checkbox"
                  value=""
                  checked={areAllUsersSelected}
                  disabled={
                    (scopes &&
                      scopes.includes("settings_can_edit_email_signature")) ===
                    true
                      ? false
                      : true
                  }
                  onChange={handleOnChangeCheckbox}
                />
              </div>
              <div className={`${styles.list}`}>
                <span className={`${styles.headerText} ps-2`}>Agent Lists</span>
              </div>
              <div className={`${styles.role} text-center`}>
                <span className={`${styles.headerText}`}>Agents Role</span>
              </div>
              <div className={`text-center ${styles.permit}`}>
                <span className={`${styles.headerText}`}>
                  Enable/Disable Email Access
                </span>
              </div>
            </div>
            {userAjaxStatus === "pending" ? (
              <Loader />
            ) : (
              users.map((user: BrandAgent) => {
                return <AgentRow key={user.id} user={user} />;
              })
            )}

            {userAjaxStatus === "fulfilled" && userIds.length !== 0 ? (
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                setCurrentPage={setCurrentPage}
              />
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};
export default SetAgent;
