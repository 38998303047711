import { useCallback, useMemo } from "react";
import getDiscountCodesService from "src/services/ReturnAutoWorkFlow/OldModals/General/getDiscountCodes.service";
import getOrderTagsService from "src/services/ReturnAutoWorkFlow/OldModals/General/getOrderTags.service";
import getProductsService from "src/services/ReturnAutoWorkFlow/OldModals/General/getProducts.service";
import { getAllProductTags } from "src/services/ReturnAutoWorkFlow/OldModals/General/getProductTags.service";
import { useReturnAutoWorkFlow } from "./useReturnAutoWorkFlow";

export const useFetchReturnProducts = () => {
  const { returnAutoWorkFlow, dispatch } = useReturnAutoWorkFlow();

  const hasMore = useMemo(() => {
    if (!returnAutoWorkFlow.allProducts) {
      return true;
    }

    return returnAutoWorkFlow.allProducts.metaData.hasMore;
  }, [returnAutoWorkFlow.allProducts]);

  const fetchProducts = useCallback(
    async (limit = 25, searchTerm?: string) => {
      if (
        !returnAutoWorkFlow.integrationId ||
        returnAutoWorkFlow.loadingAllProducts === "pending" ||
        !hasMore
      ) {
        return null;
      }

      try {
        dispatch("setState", {
          loadingAllProducts: "pending",
        });

        const res = await getProductsService({
          integrationId: returnAutoWorkFlow.integrationId,
          limit: limit,
          start: returnAutoWorkFlow.allProducts?.data.length ?? 0,
          searchTerm,
        });

        dispatch("updateProducts", res);

        dispatch("setState", {
          loadingAllProducts: "fulfilled",
        });

        return res;
      } catch (err) {
        dispatch("setState", {
          loadingAllProducts: "rejected",
        });
      }

      return null;
    },
    [
      dispatch,
      hasMore,
      returnAutoWorkFlow.allProducts?.data.length,
      returnAutoWorkFlow.integrationId,
      returnAutoWorkFlow.loadingAllProducts,
    ],
  );

  const { products, metaData } = useMemo(() => {
    if (!returnAutoWorkFlow.allProducts) {
      return {
        products: [],
        metaData: {
          count: 0,
          total: 0,
          hasMore: true,
        },
      };
    }

    return {
      products: returnAutoWorkFlow.allProducts.data,
      metaData: returnAutoWorkFlow.allProducts.metaData,
    };
  }, [returnAutoWorkFlow.allProducts]);

  return {
    loadingStatus: returnAutoWorkFlow.loadingAllProducts,
    products,
    hasMore,
    metaData,
    fetchProducts,
  };
};

export const useFetchReturnProductTags = () => {
  const { returnAutoWorkFlow, dispatch } = useReturnAutoWorkFlow();

  const hasMore = useMemo(() => {
    if (!returnAutoWorkFlow.allProductTags) {
      return true;
    }

    return returnAutoWorkFlow.allProductTags.metaData.hasNextPage;
  }, [returnAutoWorkFlow.allProductTags]);

  const fetchProductTags = useCallback(
    async (limit = 1000) => {
      if (
        !returnAutoWorkFlow.integrationId ||
        returnAutoWorkFlow.loadingAllProductTags === "pending" ||
        !hasMore
      ) {
        return null;
      }

      try {
        dispatch("setState", {
          loadingAllProductTags: "pending",
        });

        const res = await getAllProductTags({
          integrationId: returnAutoWorkFlow.integrationId,
          limit,
        });

        dispatch("updateProductTags", res);

        dispatch("setState", {
          loadingAllProductTags: "fulfilled",
        });

        return res;
      } catch (err) {
        dispatch("setState", {
          loadingAllProductTags: "rejected",
        });
      }

      return null;
    },
    [
      dispatch,
      hasMore,
      returnAutoWorkFlow.integrationId,
      returnAutoWorkFlow.loadingAllProductTags,
    ],
  );

  const { productTags, metaData } = useMemo(() => {
    if (!returnAutoWorkFlow.allProductTags) {
      return {
        productTags: [],
        metaData: {
          count: 0,
          hasNextPage: true,
        },
      };
    }

    return {
      productTags: returnAutoWorkFlow.allProductTags.data,
      metaData: returnAutoWorkFlow.allProductTags.metaData,
    };
  }, [returnAutoWorkFlow.allProductTags]);

  return {
    loadingStatus: returnAutoWorkFlow.loadingAllProductTags,
    productTags,
    hasMore,
    metaData,
    fetchProductTags,
  };
};

export const useFetchReturnOrderTags = () => {
  const { returnAutoWorkFlow, dispatch } = useReturnAutoWorkFlow();

  const hasMore = useMemo(() => {
    if (!returnAutoWorkFlow.allOrderTags) {
      return true;
    }

    return returnAutoWorkFlow.allOrderTags.metaData.hasNextPage;
  }, [returnAutoWorkFlow.allOrderTags]);

  const fetchOrderTags = useCallback(
    async (limit = 1000) => {
      if (
        !returnAutoWorkFlow.integrationId ||
        returnAutoWorkFlow.loadingAllOrderTags === "pending" ||
        !hasMore
      ) {
        return null;
      }

      try {
        dispatch("setState", {
          loadingAllOrderTags: "pending",
        });

        const res = await getOrderTagsService({
          integrationId: returnAutoWorkFlow.integrationId,
          limit,
        });

        dispatch("updateOrderTags", res);

        dispatch("setState", {
          loadingAllOrderTags: "fulfilled",
        });

        return res;
      } catch (err) {
        dispatch("setState", {
          loadingAllOrderTags: "rejected",
        });
      }

      return null;
    },
    [
      dispatch,
      hasMore,
      returnAutoWorkFlow.integrationId,
      returnAutoWorkFlow.loadingAllOrderTags,
    ],
  );

  const { orderTags, metaData } = useMemo(() => {
    if (!returnAutoWorkFlow.allOrderTags) {
      return {
        orderTags: [],
        metaData: {
          count: 0,
          hasNextPage: true,
        },
      };
    }

    return {
      orderTags: returnAutoWorkFlow.allOrderTags.data,
      metaData: returnAutoWorkFlow.allOrderTags.metaData,
    };
  }, [returnAutoWorkFlow.allOrderTags]);

  return {
    isLoading: returnAutoWorkFlow.loadingAllOrderTags,
    orderTags,
    metaData,
    fetchOrderTags,
  };
};

export const useFetchReturnDiscountCodes = () => {
  const { returnAutoWorkFlow, dispatch } = useReturnAutoWorkFlow();

  const hasMore = useMemo(() => {
    if (!returnAutoWorkFlow.allDiscountCodes) {
      return true;
    }

    return returnAutoWorkFlow.allDiscountCodes?.metaData.hasNextPage ?? false;
  }, [returnAutoWorkFlow.allDiscountCodes]);

  const fetchDiscountCodes = useCallback(
    async (limit = 25) => {
      if (
        !returnAutoWorkFlow.integrationId ||
        returnAutoWorkFlow.loadingDiscountCodes === "pending" ||
        !hasMore
      ) {
        return null;
      }

      try {
        dispatch("setState", {
          loadingDiscountCodes: "pending",
        });

        const res = await getDiscountCodesService({
          integrationId: returnAutoWorkFlow.integrationId,
          limit,
          startCursor: returnAutoWorkFlow.allDiscountCodes?.metaData.endCursor,
        });

        dispatch("updateDiscountCodes", res);

        dispatch("setState", {
          loadingDiscountCodes: "fulfilled",
        });

        return res;
      } catch (err) {
        dispatch("setState", {
          loadingDiscountCodes: "rejected",
        });
      }

      return null;
    },
    [
      dispatch,
      hasMore,
      returnAutoWorkFlow.allDiscountCodes?.metaData.endCursor,
      returnAutoWorkFlow.integrationId,
      returnAutoWorkFlow.loadingDiscountCodes,
    ],
  );

  const { discountCodes, metaData } = useMemo(() => {
    if (!returnAutoWorkFlow.allDiscountCodes) {
      return {
        discountCodes: [],
        metaData: {
          count: 0,
          hasNextPage: true,
        },
      };
    }

    return {
      discountCodes: returnAutoWorkFlow.allDiscountCodes.data,
      metaData: returnAutoWorkFlow.allDiscountCodes.metaData,
    };
  }, [returnAutoWorkFlow.allDiscountCodes]);

  return {
    isLoading: returnAutoWorkFlow.loadingDiscountCodes,
    discountCodes,
    metaData,
    fetchDiscountCodes,
  };
};
