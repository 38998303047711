import { useState, useEffect, useCallback, useRef } from "react";

import {
  fetchAllArticleCategories,
  fetchAllArticles,
  resetArticleCategoriesData,
} from "src/store/slices/liveChatSetting/chatWidgetSetting/knowledgeBased/knowledgeBasedSetting.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";

interface Props {
  integrationId?: number | string;
  fetchLimit?: number;
}
/**
 *
 */
function useFetchArticleAndCategory({ integrationId, fetchLimit = 15 }: Props) {
  const [initialFetchLoading, setInitialFetchLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    categoryData,
    categoryIdList,
    fetchCategoryAjaxStatus,
    categoryMetaData,
  } = useAppSelector((state) => state.knowledgeBasedSettings);

  const currentState = useRef({
    categoryData,
    categoryIdList,
    fetchCategoryAjaxStatus,
    categoryMetaData,
    initialFetch: false,
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (integrationId) {
      //resetting previous data
      dispatch(resetArticleCategoriesData());
      dispatch(
        fetchAllArticleCategories({
          integrationId,
          start: 0,
          limit: fetchLimit,
        })
      );
      currentState.current.initialFetch = true;
    }
  }, [integrationId]);

  const handleCategoryInfiniteScroll = useCallback(() => {
    if (
      integrationId &&
      currentState.current.fetchCategoryAjaxStatus !== "pending"
    ) {
      dispatch(
        fetchAllArticleCategories({
          integrationId: integrationId,
          start: currentState.current.categoryIdList.length,
          limit: fetchLimit,
        })
      );
    }
  }, [integrationId, fetchLimit]);

  const handleArticleInfiniteScroll = useCallback(
    (categoryId: number | string) => {
      if (
        integrationId &&
        currentState.current.categoryData[categoryId] !== undefined
      ) {
        if (
          currentState.current.categoryData[categoryId].ajaxStatus !== "pending"
        ) {
          dispatch(
            fetchAllArticles({
              integrationId: integrationId,
              categoryId: categoryId,
              start: currentState.current.categoryData[categoryId].articlesIds.length,
              limit: fetchLimit,
            })
          );
        }
      }
    },
    [integrationId, fetchLimit]
  );

  useEffect(() => {
    if (fetchCategoryAjaxStatus === "pending") {
      setLoading(true);
      if (currentState.current.initialFetch === true) {
        setInitialFetchLoading(true);
        currentState.current.initialFetch = false;
      }
    } else {
      setInitialFetchLoading(false);
      setLoading(false);
    }
  }, [fetchCategoryAjaxStatus]);

  useEffect(() => {
    currentState.current = {
      categoryData,
      categoryIdList,
      fetchCategoryAjaxStatus,
      categoryMetaData,
      initialFetch: currentState.current.initialFetch,
    };
  }, [categoryData, categoryIdList, fetchCategoryAjaxStatus, categoryMetaData]);

  const hasMoreCategory = useCallback(() => {
    return (currentState.current.fetchCategoryAjaxStatus === "rejected"
      ? false
      : currentState.current.categoryMetaData.total === null
      ? true
      : currentState.current.categoryIdList.length <
        currentState.current.categoryMetaData.total);
  }, []);

  const hasMoreArticle = useCallback((categoryId:number|string) => {

    if(currentState.current.categoryData[categoryId] !== undefined){

      return (currentState.current.categoryData[categoryId].ajaxStatus === "rejected"
      ? false
      : currentState.current.categoryData[categoryId].metaData.total === null
      ? true
      : currentState.current.categoryData[categoryId].articlesIds.length <
      currentState.current.categoryData[categoryId].metaData.total);
    }

    return false;
    }, []);

  return {
    categoryData,
    categoryIdList,
    loading,
    hasMoreCategory,
    handleCategoryInfiniteScroll,
    initialFetchLoading,
    handleArticleInfiniteScroll,
    hasMoreArticle
  };
}

export default useFetchArticleAndCategory;
