import { NavLink, useParams } from "react-router-dom";
import styles from "src/routes/LiveChat/Children/ChatSetting/ChatSetting.module.scss";
import LiveChatWidget from "../LiveChatWidget/LiveChatWidget";
import ChatPage from "../ChatPage/ChatPage";
import Customization from "../Customization/Customization";
import Availability from "../Availability/Availability";
import KnowledgeBased from "../KnowledgeBased/KnowledgeBased";
import CannedResponse from "../CannedResponse/CannedResponse";
import { Accordion, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import PreChatForm from "../Forms/PreChatForm/PreChatForm";
import AskMail from "../Forms/AskMail/AskMail";
import NotAvailable from "../Forms/NotAvailable/NotAvailable";
import PostChatForm from "../Forms/PostChatForm/PostChatForm";
import TicketForm from "../Forms/TicketForm/TicketForm";
import ChatAssignment from "../ChatSettingSection/Children/ChatAssignment/ChatAssignment";
import SetupRoute from "../ChatSettingSection/Children/SetupRoute/SetupRoute";
import ForwardChat from "../ChatSettingSection/Children/ForwardChat/ForwardChat";
import FileShare from "../ChatSettingSection/Children/FileShare/FileShare";
import ChatGoals from "../Ecommerce/Children/ChatGoals/ChatGoals";
import SalesTracker from "../Ecommerce/Children/SalesTracker/SalesTracker";
import NewRule from "../ChatSettingSection/Children/SetupRoute/Children/NewRule/NewRule";
import InactivityTimeOut from "../ChatSettingSection/Children/InactivityTimeOut/InactivityTimeOut";
import TargetedMessages from "../Engagement/TargetedMessages/TargetedMessages";
import CustomKnowledgeBased from "../KnowledgeBased/Children/CustomKnowledgeBased/CustomKnowledgeBased";
import ArticlesPath from "../KnowledgeBased/Children/KnowledgeBasedArticles/ArticlesPath";
import HelpCenterStyle from "../KnowledgeBased/Children/CreateArticle/HelpCenterStyle";

import TargetWidget from "../Engagement/Children/TargetWidget/TargetWidget";
import NewAnnouncement from "../Engagement/TargetedMessages/NewTargetedMessage/NewTargetedMessage";
import NewTargetedMessage from "../Engagement/TargetedMessages/NewTargetedMessage/NewTargetedMessage";
import GreetingCondition from "../Engagement/Children/Greetings/Children/SetCondition/Children/GreetingCondition/GreetingCondition";
import SetCondition from "../Engagement/Children/Greetings/Children/SetCondition/SetCondition";
import Greetings from "../Engagement/Children/Greetings/Greetings";
import Announcement from "../Engagement/Children/Announcement/Announcement";
import { useCallback, useState } from "react";
import { useAppSelector } from "src/store/store";
import { FormDiscard } from "../Forms/NotAvailable/Children/FormDiscard/FormDiscard";
import KnowledgeBaseUpdated from "src/routes/KnowledgeBaseUpdated/KnowledgeBaseUpdated";
import ArticleFeatures from "../KnowledgeBased/Children/KnowledgeBasedArticles/Children/ArticleFeatures/ArticleFeatures";
export const SideBarLinks: {
  [key: string]: {
    name: string;
    component: any;
    hasSubSetting?: boolean;
    subSettingKey?: string;
    showSideBar?: boolean;
  };
} = {
  Website: { name: "Website", component: LiveChatWidget },
  ChatPage: { name: "ChatPage", component: ChatPage },
  Customization: { name: "Customization", component: Customization },
  Availability: { name: "Availability", component: Availability },
  Knowledge: {
    name: "Knowledge",
    component: KnowledgeBased,
    hasSubSetting: true,
  },
  CannedResponse: { name: "CannedResponse", component: CannedResponse },
  preChat: { name: "preChat", component: PreChatForm },
  mail: { name: "mail", component: NotAvailable },
  postChat: { name: "postChat", component: PostChatForm },
  ticketForm: { name: "ticketForm", component: TicketForm },
  chatAssignment: { name: "chatAssignment", component: ChatAssignment },
  routeRule: {
    name: "routeRule",
    component: SetupRoute,
    hasSubSetting: true,
    subSettingKey: "RoutingRule",
  },
  forwardChat: { name: "forwardChat", component: ForwardChat },
  fileShare: { name: "fileShare", component: FileShare },
  inactiveTimeOut: { name: "inactiveTimeOut", component: InactivityTimeOut },
  goals: { name: "goals", component: ChatGoals },
  sales: { name: "sales", component: SalesTracker },
  targetMessage: { name: "targetMessage", component: TargetedMessages },
  newAnnouncement: {
    name: "new-announcement",
    component: NewTargetedMessage,
    hasSubSetting: true,
    subSettingKey: "conditions",
    showSideBar: false,
  },
  newGreeting: {
    name: "new-greeting",
    component: NewTargetedMessage,
    hasSubSetting: true,
    subSettingKey: "conditions",
    showSideBar: false,
  },
};
export const SubSettingLinks: {
  [key: string]: { name: string; component: any; showSidebar?: boolean };
} = {
  RoutingRule: { name: "New Rule", component: NewRule, showSidebar: false },
  HelpCenterSetting: {
    name: "Help Center Setting",
    component: HelpCenterStyle,
    showSidebar: false,
  },
  CustomKnowledgeBased: {
    name: "Custom Knowledge Base",
    component: CustomKnowledgeBased,
    showSidebar: false,
  },
  articles: {
    name: "Articles",
    component: KnowledgeBaseUpdated,
    showSidebar: false,
  },
  conditions: {
    name: "conditions",
    component: SetCondition,
    showSidebar: false,
  },
  features: {
    name: "features",
    component: ArticleFeatures,
    showSidebar: false,
  },
  greeting: { name: "greeting", component: Greetings },
  announcement: { name: "announcement", component: Announcement },
};
const LeftSideBarSection = () => {
  const [showSidebar, setShowSidebar] = useState(true);
  const [showOverlay, setShowOveray] = useState(false);
  const { integrationId } = useParams();
  const pageIntegrationId: string = integrationId
    ? decodeURIComponent(integrationId)
    : "";
  const installed_successfully = useAppSelector(
    (state) =>
      state.websiteSettings.activeWebIntegration?.installed_successfully
  );

  const { settingName } = useParams();

  let activeAccordionIndex = -1;
  if (
    settingName === SideBarLinks.Website.name ||
    settingName === SideBarLinks.ChatPage.name
  ) {
    activeAccordionIndex = 0;
  } else if (
    settingName === SideBarLinks.Customization.name ||
    settingName === SideBarLinks.Availability.name ||
    settingName === SideBarLinks.Knowledge.name
  ) {
    activeAccordionIndex = 1;
  } else if (settingName === SideBarLinks.targetMessage.name) {
    activeAccordionIndex = 3;
  } else if (
    settingName === SideBarLinks.preChat.name ||
    settingName === SideBarLinks.mail.name ||
    settingName === SideBarLinks.postChat.name ||
    settingName === SideBarLinks.ticketForm.name
  ) {
    activeAccordionIndex = 2;
  } else if (
    settingName === SideBarLinks.goals.name ||
    settingName === SideBarLinks.sales.name
  ) {
    activeAccordionIndex = 6;
  }
  const [showModal, setShowModal] = useState(false);
  const onShow = useCallback(() => {
    setShowModal(true);
  }, []);
  const onHide = useCallback(() => {
    setShowModal(false);
  }, []);
  const getToggleButton = () => {
    return (
      <div
        className={`${styles.leftSideBarCloseBtn} ${
          showSidebar === true ? "" : "applyZ"
        } ${
          showSidebar === true
            ? styles.rightWhenToggleOpen
            : styles.rightWhenToggleClose
        }`}
        onClick={() => setShowSidebar(!showSidebar)}
      >
        <OverlayTrigger
          show={showOverlay}
          placement="top"
          overlay={
            <Tooltip className={`toolTipCustom`}>
              <span className={`tooltipHead`}>
                {showSidebar === true ? "Hide sidebar" : "Show sidebar"}
              </span>
            </Tooltip>
          }
        >
          {({ ref, ...triggerHandler }) => (
            <div
              {...triggerHandler}
              ref={ref}
              onMouseOver={() => {
                setShowOveray(true);
              }}
              onMouseOut={() => {
                setShowOveray(false);
              }}
            >
              <span className={`subSideBarCloseBtnToggleArrow m-auto`}>
                {showSidebar === true ? (
                  <i className="fa-solid fa-chevron-left"></i>
                ) : (
                  <i className="fa-solid fa-chevron-right"></i>
                )}
              </span>
            </div>
          )}
        </OverlayTrigger>
      </div>
    );
  };

  return (
    <>
      {/* <div className={`${styles.subSideToggle}`}> */}
      <div
        className={`${styles.leftSideBarContent} ${
          showSidebar ? "" : "d-none"
        }`}
      >
        {/* <div className={`${styles.subsideToggleInner}`}> */}
        {/* Channel */}
        <div className={`mb-2`}>
          <NavLink
            to={`/live-chat/settings`}
            className={`d-flex justify-content-center align-items-center ${styles.backChat}`}
          >
            <i className="fa-solid fa-arrow-left"></i>
          </NavLink>
        </div>
        <div className="mb-2">
          <Accordion defaultActiveKey={activeAccordionIndex == 0 ? "0" : "1"}>
            <Accordion.Item eventKey="0" className={`${styles.accordItem}`}>
              <Accordion.Header
                className={`${styles.toggleHead} ${
                  activeAccordionIndex == 0 ? styles.activeMain : ""
                } `}
              >
                Channel
              </Accordion.Header>
              <Accordion.Body className={`${styles.accordBody}`}>
                <div className="d-flex">
                  <NavLink
                    to={`/live-chat/settings/${pageIntegrationId}/${SideBarLinks.Website.name}`}
                    className={`${styles.webBtn} ${
                      settingName === SideBarLinks.Website.name
                        ? styles.webBtnActive
                        : ""
                    } `}
                  >
                    {settingName === SideBarLinks.Website.name ? (
                      <div className={`${styles.bar}`}></div>
                    ) : (
                      ""
                    )}
                    <div
                      className={`w-100 d-flex align-items-center justify-content-between`}
                    >
                      <span> Website</span>
                      {installed_successfully && (
                        <div
                          className={`d-flex justify-content-center align-items-center me-2 ${styles.checkWrapper}`}
                        >
                          <span className={`${styles.checkSymbol}`}>
                            <i className="fa-solid fa-check"></i>
                          </span>
                        </div>
                      )}
                    </div>
                  </NavLink>
                </div>
                {/* <div>
                <NavLink
                  to={`/live-chat/settings/${pageIntegrationId}/${SideBarLinks.ChatPage.name}`}
                  className={`${styles.webBtn} ${
                    settingName === SideBarLinks.ChatPage.name
                      ? styles.webBtnActive
                      : ""
                  } `}
                >
                  {settingName === SideBarLinks.ChatPage.name ? (
                    <div className={`${styles.bar}`}></div>
                  ) : (
                    ""
                  )}
                  Chat Page
                </NavLink>
              </div> */}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        {/* Chat Widget */}
        <div className="mb-2">
          <Accordion defaultActiveKey={activeAccordionIndex == 1 ? "0" : "1"}>
            <Accordion.Item eventKey="0" className={`${styles.accordItem}`}>
              <Accordion.Header
                className={`${styles.toggleHead} ${
                  activeAccordionIndex == 1 ? styles.activeMain : ""
                }`}
              >
                Chat Widget{" "}
              </Accordion.Header>
              <Accordion.Body className={`${styles.accordBody}`}>
                <div className="d-flex">
                  <NavLink
                    to={`/live-chat/settings/${pageIntegrationId}/${SideBarLinks.Customization.name}`}
                    className={`${styles.webBtn} ${
                      settingName === SideBarLinks.Customization.name
                        ? styles.webBtnActive
                        : ""
                    } `}
                  >
                    {settingName === SideBarLinks.Customization.name ? (
                      <div className={`${styles.bar}`}></div>
                    ) : (
                      ""
                    )}
                    Customization
                  </NavLink>
                </div>
                <div>
                  <NavLink
                    to={`/live-chat/settings/${pageIntegrationId}/${SideBarLinks.Availability.name}`}
                    className={`${styles.webBtn} ${
                      settingName === SideBarLinks.Availability.name
                        ? styles.webBtnActive
                        : ""
                    } `}
                  >
                    {settingName === SideBarLinks.Availability.name ? (
                      <div className={`${styles.bar}`}></div>
                    ) : (
                      ""
                    )}
                    Availability
                  </NavLink>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        {/* Forms */}
        <div className="mb-2">
          <Accordion defaultActiveKey={activeAccordionIndex == 2 ? "0" : "1"}>
            <Accordion.Item eventKey="0" className={`${styles.accordItem}`}>
              <Accordion.Header
                className={`${styles.toggleHead} ${
                  activeAccordionIndex == 2
                    ? styles.activeMain
                    : ""
                    ? styles.activeMain
                    : ""
                }`}
              >
                Forms
              </Accordion.Header>
              <Accordion.Body className={`${styles.accordBody}`}>
                <div className="d-flex">
                  <NavLink
                    to={`/live-chat/settings/${pageIntegrationId}/${SideBarLinks.preChat.name}`}
                    className={`${styles.webBtn} ${
                      settingName === SideBarLinks.preChat.name
                        ? styles.webBtnActive
                        : ""
                    } `}
                  >
                    {settingName === SideBarLinks.preChat.name ? (
                      <div className={`${styles.bar}`}></div>
                    ) : (
                      ""
                    )}
                    Pre-Chat Form
                  </NavLink>
                </div>
                {/* <div>
                  <NavLink
                    to={`/live-chat/settings/${pageIntegrationId}/${SideBarLinks.mail.name}`}
                    className={`${styles.webBtn} ${
                      settingName === SideBarLinks.mail.name
                        ? styles.webBtnActive
                        : ""
                    } `}
                  >
                    {settingName === SideBarLinks.mail.name ? (
                      <div className={`${styles.bar}`}></div>
                    ) : (
                      ""
                    )}
                    Ask For Email
                  </NavLink>
                </div> */}
                <div>
                  <NavLink
                    to={`/live-chat/settings/${pageIntegrationId}/${SideBarLinks.mail.name}`}
                    className={`${styles.webBtn} ${
                      settingName === SideBarLinks.mail.name
                        ? styles.webBtnActive
                        : ""
                    } `}
                    onClick={onShow}
                  >
                    {settingName === SideBarLinks.mail.name ? (
                      <div className={`${styles.bar}`}></div>
                    ) : (
                      ""
                    )}
                    Agent Not Available
                  </NavLink>
                  {/* <Modal
                    backdropClassName={`${styles.modalBack}`}
                    show={showModal}
                    onHide={onHide}
                    dialogClassName={`${styles.modalDialog}`}
                    contentClassName={`${styles.modalContent}`}
                    centered={true}
                    enforceFocus={false}
                  >
                    <FormDiscard />
                  </Modal> */}
                </div>
                <div>
                  <NavLink
                    to={`/live-chat/settings/${pageIntegrationId}/${SideBarLinks.postChat.name}`}
                    className={`${styles.webBtn} ${
                      settingName === SideBarLinks.postChat.name
                        ? styles.webBtnActive
                        : ""
                    } `}
                  >
                    {settingName === SideBarLinks.postChat.name ? (
                      <div className={`${styles.bar}`}></div>
                    ) : (
                      ""
                    )}
                    Post-Chat Form
                  </NavLink>
                </div>
                {/* <div>
                  <NavLink
                    to={`/live-chat/settings/${pageIntegrationId}/${SideBarLinks.ticketForm.name}`}
                    className={`${styles.webBtn} ${
                      settingName === SideBarLinks.ticketForm.name
                        ? styles.webBtnActive
                        : ""
                    } `}
                  >
                    {settingName === SideBarLinks.ticketForm.name ? (
                      <div className={`${styles.bar}`}></div>
                    ) : (
                      ""
                    )}
                    Ticket Form
                  </NavLink>
                </div> */}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        {/* Engagement */}
        {/* <div className="mb-2">
          <Accordion defaultActiveKey={activeAccordionIndex == 3 ? "0" : "1"}>
            <Accordion.Item eventKey="0" className={`${styles.accordItem}`}>
              <Accordion.Header
                className={`${styles.toggleHead} ${
                  settingName === SideBarLinks.targetMessage.name
                    ? styles.activeMain
                    : ""
                }`}
              >
                Engagement
              </Accordion.Header>
              <Accordion.Body className={`${styles.accordBody}`}>
                <div className="d-flex">
                  <NavLink
                    to={`/live-chat/settings/${pageIntegrationId}/${SideBarLinks.targetMessage.name}`}
                    className={`${styles.webBtn} ${
                      activeAccordionIndex == 3 ? styles.webBtnActive : ""
                    } `}
                  >
                    {settingName === SideBarLinks.targetMessage.name ? (
                      <div className={`${styles.bar}`}></div>
                    ) : (
                      ""
                    )}
                    Targeted Messages
                  </NavLink>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div> */}
        {/* Canned Responses */}
        <div className="mb-2">
          <NavLink
            to={`/live-chat/settings/${pageIntegrationId}/${SideBarLinks.CannedResponse.name}`}
            className={`${styles.toggleHead2} ${
              settingName === SideBarLinks.CannedResponse.name
                ? styles.activeMain
                : ""
            }`}
          >
            Canned Responses
          </NavLink>
        </div>
        {/* Chat Settings */}
        <div className="mb-2">
          <Accordion defaultActiveKey="1">
            <Accordion.Item eventKey="0" className={`${styles.accordItem}`}>
              <Accordion.Header
                className={`${styles.toggleHead} ${
                  settingName === SideBarLinks.chatAssignment.name ||
                  settingName === SideBarLinks.routeRule.name ||
                  settingName === SideBarLinks.forwardChat.name
                    ? styles.activeMain
                    : ""
                }`}
              >
                Chat Settings
              </Accordion.Header>
              <Accordion.Body className={`${styles.accordBody}`}>
                <div>
                  <NavLink
                    to={`/live-chat/settings/${pageIntegrationId}/${SideBarLinks.chatAssignment.name}`}
                    className={`${styles.webBtn} ${
                      settingName === SideBarLinks.chatAssignment.name
                        ? styles.webBtnActive
                        : ""
                    } `}
                  >
                    {settingName === SideBarLinks.chatAssignment.name ? (
                      <div className={`${styles.bar}`}></div>
                    ) : (
                      ""
                    )}
                    Chat Assignment
                  </NavLink>
                </div>
                {/* <div>
                  <NavLink
                    to={`/live-chat/settings/${pageIntegrationId}/${SideBarLinks.routeRule.name}`}
                    className={`${styles.webBtn} ${
                      settingName === SideBarLinks.routeRule.name
                        ? styles.webBtnActive
                        : ""
                    } `}
                  >
                    {settingName === SideBarLinks.routeRule.name ? (
                      <div className={`${styles.bar}`}></div>
                    ) : (
                      ""
                    )}
                    Routing Rules
                  </NavLink>
                </div> */}
                   
                <div>
                  <NavLink
                    to={`/live-chat/settings/${pageIntegrationId}/${SideBarLinks.forwardChat.name}`}
                    className={`${styles.webBtn} ${
                      settingName === SideBarLinks.forwardChat.name
                        ? styles.webBtnActive
                        : ""
                    } `}
                  >
                    {settingName === SideBarLinks.forwardChat.name ? (
                      <div className={`${styles.bar}`}></div>
                    ) : (
                      ""
                    )}
                    Transcript Forwarding
                  </NavLink>
                </div>
                {/*
                <div>
                  <NavLink
                    to={`/live-chat/settings/${pageIntegrationId}/${SideBarLinks.fileShare.name}`}
                    className={`${styles.webBtn} ${
                      settingName === SideBarLinks.fileShare.name
                        ? styles.webBtnActive
                        : ""
                    } `}
                  >
                    {settingName === SideBarLinks.fileShare.name ? (
                      <div className={`${styles.bar}`}></div>
                    ) : (
                      ""
                    )}
                    File Sharing
                  </NavLink>
                </div>
                <div>
                  <NavLink
                    to={`/live-chat/settings/${pageIntegrationId}/${SideBarLinks.inactiveTimeOut.name}`}
                    className={`${styles.webBtn} ${
                      settingName === SideBarLinks.inactiveTimeOut.name
                        ? styles.webBtnActive
                        : ""
                    } `}
                  >
                    {settingName === SideBarLinks.inactiveTimeOut.name ? (
                      <div className={`${styles.bar}`}></div>
                    ) : (
                      ""
                    )}
                    Inactivity TimeOut
                  </NavLink>
                </div> */}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        {/* E-commerce */}
        {/* <div className="mb-2">
          <Accordion defaultActiveKey={activeAccordionIndex == 6 ? "0" : "1"}>
            <Accordion.Item eventKey="0" className={`${styles.accordItem}`}>
              <Accordion.Header
                className={`${styles.toggleHead}
             ${activeAccordionIndex == 6 ? styles.activeMain : ""}
            `}
              >
                E-commerce
              </Accordion.Header>
              <Accordion.Body className={`${styles.accordBody}`}>
                <div>
                  <NavLink
                    to={`/live-chat/settings/${pageIntegrationId}/${SideBarLinks.goals.name}`}
                    className={`${styles.webBtn} ${
                      settingName === SideBarLinks.goals.name
                        ? styles.webBtnActive
                        : ""
                    } `}
                  >
                    {settingName === SideBarLinks.goals.name ? (
                      <div className={`${styles.bar}`}></div>
                    ) : (
                      ""
                    )}
                    Goals
                  </NavLink>
                </div>
                <div>
                  <NavLink
                    to={`/live-chat/settings/${pageIntegrationId}/${SideBarLinks.sales.name}`}
                    className={`${styles.webBtn} ${
                      settingName === SideBarLinks.sales.name
                        ? styles.webBtnActive
                        : ""
                    } `}
                  >
                    {settingName === SideBarLinks.sales.name ? (
                      <div className={`${styles.bar}`}></div>
                    ) : (
                      ""
                    )}
                    Sales Tracker
                  </NavLink>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div> */}
        {/* </div> */}
        {/* {showSidebar === true && getToggleButton()} */}
      </div>

      {/* {showSidebar === false && getToggleButton()} */}
      {/* </div> */}
    </>
  );
};
export default LeftSideBarSection;
