import { Channel } from "pusher-js";

export interface ChatCountUpdatedData {
  chatId: number;
  brandId: number;
  action: "increment" | "decrement";
  timestamp: number;
  customerStatus: "live" | "missed" | "archived";
}

export type ChatCountUpdatedEventCB = (
  res: Array<ChatCountUpdatedData>,
) => void;

export const chatCountUpdatedEvent = (
  channel: Channel,
  callback: ChatCountUpdatedEventCB,
) => {
  channel.bind("live_chat_count_updated", callback);
};

export const chatCountUpdatedUnbind = (channel: Channel) => {
  channel.unbind("live_chat_count_updated");
};
