import { useInfiniteQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import getBugComments, {
  BugComments,
  BugCommentsResponse,
} from "src/services/BugReport/getBugComment";

const useBugMessageList: any = (id: number) => {
  const initialPayload = {
    start: 0,
    limit: 10,
    bugId: id,
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [bugComments, setBugComments] = useState<Array<BugComments>>();

  const { data, hasNextPage, fetchNextPage, isLoading, isError } =
    useInfiniteQuery({
      queryKey: ["bugDetails/getBugComments", initialPayload],
      queryFn: ({ pageParam = initialPayload }) => getBugComments(pageParam),
      getNextPageParam: (prevPage: BugCommentsResponse, _allPages) => {
        if (prevPage.metaData && !prevPage.metaData.limitReached) {
          return {
            bugId: initialPayload.bugId,
            limit: initialPayload.limit,
            start: currentPage * prevPage.data.length,
          };
        }
        return null;
      },
    });

  //get comments data
  useMemo(() => {
    let categoriesData: BugComments[] = [];
    data?.pages.forEach((page) => {
      categoriesData = [...categoriesData, ...page.data];
    });
    setBugComments(categoriesData.reverse());
    return categoriesData;
  }, [data]);

  const nextPageLoad = useCallback(() => {
    setCurrentPage(currentPage + 1);
    fetchNextPage();
  }, [currentPage]);

  return {
    currentPage,
    nextPageLoad,
    hasNextPage,
    isLoading,
    isError,
    bugComments,
    setBugComments,
  };
};

export default useBugMessageList;
