import React from "react";
import styles from "./Information.module.scss";


function Information({element,inputName}:any) {
  return (
    <div>
      <p className={`w-100 text-muted ${styles.fonts}`}>
        {element.elementValue}
        {/* Our agents are not available right now. Please leave a message and we'll
        get back to you. */}
      </p>
    </div>
  );
}

export default Information;
