import React, { useCallback } from "react";
import ParseHTML from "src/components/ParseHTML";
import styles from "./ShortCutMessage.module.scss";
import { getInnerText } from "src/utils/utils";

interface Props {
  cannedResponseId: string | number;
  shortcutKeyText: string;
  messageText: string;
  selectedTemplate: (value: string) => void;
}
const ShortCutMessage = ({
  cannedResponseId,
  shortcutKeyText,
  messageText,
  selectedTemplate,
}: Props) => {

  const handleOnClick = useCallback(() => {
    selectedTemplate(messageText);
  }, [selectedTemplate, messageText]);

  return (
    <div className={`${styles.AllResponseEdit}`} onClick={handleOnClick}>
      <div>
        <div className={`d-flex justify-content-between`}>
          <div className={`d-flex`}>
            <button className={`${styles.returnBtn}`}>
              <span className={`${styles.hash}`}>#</span>
              {shortcutKeyText}
            </button>
            <span className={`${styles.key}`}>Shortcut Key</span>
          </div>
        </div>
        <p className={`${styles.AllResponsePara}`}>
          <ParseHTML html={getInnerText(messageText ?? "")} />
        </p>
      </div>
    </div>
  );
};
export default ShortCutMessage;
