import { axiosJSON } from "src/globals/axiosEndPoints";

export interface BrandData {
  isVerified: boolean;
  id: number | string;
  name: string;
  email: string;
  signature: string;
  enabled: boolean;
  isDefault: boolean;
  isActive: boolean;
  imageURL: null | string;
  disabledUserIds: Array<string | number>;
  hasPermission: boolean;
  emailIntegrationId?: number | null;
}

export interface GetAllBrandData {
  brands: { [brand: number | string]: BrandData };
  brandIds: Array<number | string>;
  metaData: {
    count: number;
    total: number;
  };
}

export interface GetAllBotProfileBrandPayload {
  limit?: number;
  start?: number;
  searchTerm: string;
  type?: "all" | "agent";
  signatureType?: "filtered" | "unfiltered",
  onlyVerified?: boolean
}

export async function getAllBotProfileBrands(payload: GetAllBotProfileBrandPayload) {
  const { data: res } = await axiosJSON.post(
    "/api/botProfile/getBrandsForBotProfiles",
    payload
  );

  if (res.err === true) {
    throw new Error(res.msg as string);
  }

  const ret: GetAllBrandData = {
    brands: {},
    brandIds: [],
    metaData: {
      count: 0,
      total: 0,
    },
  };
  ret.metaData.count = res.metaData.count;
  ret.metaData.total = res.metaData.totalCount;
  res.data.forEach((brand: BrandData) => {
    ret.brands[brand.id] = brand;
    ret.brandIds.push(brand.id);
  });

  return ret;
}

