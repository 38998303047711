import { axiosJSON } from "src/globals/axiosEndPoints";

interface IDraftRemark {
  msgId: number;
  remarkId?: number | null;
  remark: string;
}

export async function addEditRemark(servicePayload: IDraftRemark) {
  let url = `/api/ticket/editDraftRemark`;
  if (servicePayload.remarkId == null) {
    delete servicePayload.remarkId;
    url = `/api/ticket/addDraftRemarks`;
  }
  const { data } = await axiosJSON.post(url, servicePayload);
  if (data.err === true) {
    throw new Error(data.msg as string);
  }
  data.data.remarkData.remarkId = data.data.editedRemarkId
    ? data.data.editedRemarkId
    : data.data.insertionId;
  return data.data;
}
