/**
 * This file is the react component file.
 * It contains the "TabFilters" and "ReportTabs" components for managing filters and tabs in reports.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 * @author Navjyot
 */

import styles from "./ReportTabs.module.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useMemo, useRef } from "react";
import ReportInternalFilter from "../ReportInternalFilter/ReportInternalFilter";
import { useReportFilters } from "../../hooks/reportFilters/useReportFilters";
import {
  ReportDateType,
  useReportInternalFilters,
} from "../../hooks/reportFilters/useReportInternalFilters";
import {
  reportNotificationsActions,
  useNotifications,
} from "../NotificationContainer/hooks/notifications/useNotifications";
import DailyPart from "./children/DailyPart/DailyPart";
import ComparisonPart from "./children/ComparisonPart/ComparisonPart";
import { CurrentStatusEndPoints } from "src/services/report/reportTicketList.service";
import { useCreateNewNotify } from "../CreateNotifyBtn/CreateNotifyBtn";
import useNotifyPermission from "../NotificationContainer/hooks/notifications/useNotifyPermission";
import { useAppSelector } from "src/store/store";

const reportSVG = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="10"
    viewBox="0 0 12.3 12.3"
  >
    <g
      id="Icon_feather-pie-chart"
      data-name="Icon feather-pie-chart"
      transform="translate(-2.423 -2.45)"
    >
      <path
        id="Path_478"
        data-name="Path 478"
        d="M13.337,11.273A5.382,5.382,0,1,1,6.228,4.245"
        transform="translate(0 -0.798)"
        fill="none"
        stroke="#000"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.1"
      />
      <path
        id="Path_479"
        data-name="Path 479"
        d="M23.382,8.382A5.382,5.382,0,0,0,18,3V8.382Z"
        transform="translate(-9.619 0)"
        fill="none"
        stroke="#000"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.1"
      />
    </g>
  </svg>
);

const listSVG = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.231"
    height="9.017"
    viewBox="0 0 16.231 9.017"
  >
    <path
      id="Icon_material-list"
      data-name="Icon material-list"
      d="M4.5,15.91H6.3v-1.8H4.5Zm0,3.607H6.3v-1.8H4.5Zm0-7.214H6.3V10.5H4.5ZM8.107,15.91H20.731v-1.8H8.107Zm0,3.607H20.731v-1.8H8.107Zm0-9.017v1.8H20.731V10.5Z"
      transform="translate(-4.5 -10.5)"
      fill="#4e4e4e"
    />
  </svg>
);

const notifySVG = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12.736"
    height="14.039"
    viewBox="0 0 12.736 14.039"
  >
    <g
      id="Icon_feather-bell"
      data-name="Icon feather-bell"
      transform="translate(-4 -2.5)"
    >
      <path
        id="Path_373"
        data-name="Path 373"
        d="M14.281,6.912a3.912,3.912,0,1,0-7.825,0c0,4.564-1.956,5.868-1.956,5.868H16.237s-1.956-1.3-1.956-5.868"
        fill="none"
        stroke="#656565"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1"
      />
      <path
        id="Path_374"
        data-name="Path 374"
        d="M17.661,31.5a1.3,1.3,0,0,1-2.256,0"
        transform="translate(-6.165 -16.111)"
        fill="none"
        stroke="#656565"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1"
      />
    </g>
  </svg>
);

interface TabFiltersProps {
  endpoint?: CurrentStatusEndPoints;
  showNotifyTab?: boolean;
  activeTabname: ReportTabNames;
  setActiveTabname: React.Dispatch<React.SetStateAction<ReportTabNames>>;
  showInnerFilters?: boolean;
  disabled?: boolean;
}

// TabFilters component definition
const TabFilters = ({
  endpoint,
  showNotifyTab,
  activeTabname,
  setActiveTabname,
  showInnerFilters,
  disabled,
}: TabFiltersProps) => {
  const createNewNotify = useCreateNewNotify({
    activeTabname,
    setActiveTabname,
  });

  return (
    <div className={`ms-2 d-flex align-items-center  ${styles.filtetWrap}`}>
      {/* ReportInternalFilter component with endpoint prop */}
      {showInnerFilters && <ReportInternalFilter endpoint={endpoint} />}
      {showNotifyTab ? (
        <>
          <div className={`ms-2 ${styles.bellWrapper}`}>
            {/* Notify Agents icon with tooltip and modal */}
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="button-tooltip-2">Notify Agents</Tooltip>}
            >
              {({ ref, ...triggerHandler }) => (
                <span
                  {...triggerHandler}
                  className={`d-inline-flex align-items-center ${
                    disabled && styles.disable
                  }`}
                  onClick={createNewNotify}
                >
                  <span
                    ref={ref}
                    className="cursor-pointer"
                  >
                    <i className="fa-solid fa-bell"></i>
                  </span>
                </span>
              )}
            </OverlayTrigger>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

// Define types for report tabs
export type ReportTabNames =
  | "chartView"
  | "listView"
  | "notify"
  | "heatmapView";

// Interface for ReportTabsProps
export interface ReportTabsProps {
  activeTabName: ReportTabNames;
  setActiveTabname: React.Dispatch<React.SetStateAction<ReportTabNames>>;
  showComparison?: boolean;
  showDailyPart?: boolean;
  showListView?: boolean;
  showNotifyTab?: boolean;
  showInnerFilters?: boolean;
  endPoint?: CurrentStatusEndPoints;
  reportDateType?: ReportDateType;
  showTabs?: boolean;
}

// ReportTabs component definition
function ReportTabs({
  activeTabName,
  setActiveTabname,
  showComparison = false,
  showDailyPart = false,
  showListView = true,
  showNotifyTab = false,
  showInnerFilters = true,
  endPoint,
  showTabs = true,
  reportDateType,
}: ReportTabsProps) {
  const notifyPermission = useNotifyPermission();
  // Custom hooks for managing filters and notifications
  const { filtersForAPI: globalFiltersApplied } = useReportFilters();
  const { internalFilters: internalFiltersApplied } =
    useReportInternalFilters();
  const { dispatch } = useNotifications();

  // Ref for storing the current state
  const currentState = useRef({
    globalFiltersApplied,
    internalFiltersApplied,
    activeTabName,
  });

  // Update the ref when filters change
  useMemo(() => {
    currentState.current.globalFiltersApplied = globalFiltersApplied;
    currentState.current.internalFiltersApplied = internalFiltersApplied;
    currentState.current.activeTabName = activeTabName;
  }, [globalFiltersApplied, internalFiltersApplied, activeTabName]);

  // Fetching disabledFeatures from the Redux store
  const disabledFeatures = useAppSelector(
    (state) => state.globals.currentUserData?.disabledFeatures,
  );
  const disabled = useMemo(() => {
    return (disabledFeatures && disabledFeatures.includes("reports")) ?? false;
  }, [disabledFeatures]);

  // Render the component UI
  return (
    <>
      <div
        className={`mx-3 mt-3 mt-md-5 d-flex flex-wrap justify-content-between ${styles.tabsMain}`}
      >
        {showTabs ? (
          <div>
            {/* Tab components for Chart View, List View, and Notify */}
            <Tab
              ticketView={"Chart View"}
              ticketIcon={reportSVG}
              tabIsActive={activeTabName === "chartView"}
              onClick={() => {
                setActiveTabname("chartView");
              }}
            />
            {showListView && (
              <Tab
                ticketView={"List View"}
                ticketIcon={listSVG}
                tabIsActive={activeTabName === "listView"}
                onClick={() => {
                  setActiveTabname("listView");
                }}
              />
            )}
            {showNotifyTab && notifyPermission !== "none" && (
              <Tab
                ticketView={"Notify"}
                ticketIcon={notifySVG}
                tabIsActive={activeTabName === "notify"}
                onClick={() => {
                  if (currentState.current.activeTabName !== "notify") {
                    // Dispatch an action to reset notification data
                    dispatch([reportNotificationsActions.resetState, true]);
                    // Set the active tab to Notify
                    setActiveTabname("notify");
                  }
                }}
              />
            )}
          </div>
        ) : (
          <div></div>
        )}

        <div className="d-flex flex-wrap flex-md-no-wrap align-items-center">
          {/* DailyPart component */}
          {showDailyPart && <DailyPart disabled={disabled} />}
          {/* ComparisonPart component */}
          {showComparison && endPoint && <ComparisonPart endPoint={endPoint} />}
          {/* FilterMain component with TabFilters */}
          {showInnerFilters && (
            <div className={`${styles.filterMain}`}>
              <TabFilters
                endpoint={endPoint}
                showNotifyTab={showNotifyTab && notifyPermission === "edit"}
                activeTabname={activeTabName}
                setActiveTabname={setActiveTabname}
                showInnerFilters={showInnerFilters}
                disabled={disabled}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

// Tab component definition
const Tab = ({
  tabIsActive,
  ticketView,
  ticketIcon,
  className = "",
  ...props
}: any) => {
  const style = {
    color: tabIsActive
      ? ticketView.tabIconColourActive
      : ticketView.tabIconColourInactive,
  };
  return (
    <button
      className={`max-content-width px-3 py-2 ${
        tabIsActive ? styles.activeTab : ""
      } ${styles.ticketTabs} ${className}`}
      {...props}
    >
      <span
        className={`mx-1`}
        style={style}
      >
        {ticketIcon}
      </span>
      {ticketView}
    </button>
  );
};

export default ReportTabs;
