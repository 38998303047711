import { NavLink } from "react-bootstrap";
import styles from "./SuggestionTabs.module.scss";
import { useState } from "react";
const SuggestionTabs = () => {
  const [activeTab, setActiveTab] = useState("All");
  const handleTab1 = () => {
    // update the state to tab1
    setActiveTab("All");
  };
  const handleTab2 = () => {
    // update the state to tab2
    setActiveTab("Published");
  };
  const handleTab3 = () => {
    // update the state to tab3
    setActiveTab("Pending");
  };
  const handleTab4 = () => {
    // update the state to tab4
    setActiveTab("Rejected");
  };
  return (
    <div className="d-flex ">
      <NavLink
        className={`px-3 ${styles.tabBtn} ${
          activeTab === "All" && styles.active
        }`}
        onClick={handleTab1}
      >
        All
      </NavLink>
      <NavLink
        className={`px-3 ${styles.tabBtn} ${
          activeTab === "Published" && styles.active
        }`}
        onClick={handleTab2}
      >
        Published
      </NavLink>
      <NavLink
        className={`px-3 ${styles.tabBtn} ${
          activeTab === "Pending" && styles.active
        }`}
        onClick={handleTab3}
      >
        Pending approval
      </NavLink>
      <NavLink
        className={`px-3 ${styles.tabBtn} ${
          activeTab === "Rejected" && styles.active
        }`}
        onClick={handleTab4}
      >
        Rejected
      </NavLink>
    </div>
  );
};

export default SuggestionTabs;
