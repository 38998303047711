import styles from "./TableHeader.module.scss";

const TableHeader = ({
  tableHeadings,
  tableHeadingIds,
  onSort,
  sortOrder,
}: {
  tableHeadings: Record<
    string,
    { tableHeadingId: string; tableHeadingName: string; isSortable: boolean }
  >;
  tableHeadingIds: string[];
  onSort: (tableHeadingId: string, order: "asc" | "desc") => void;
  sortOrder: Record<string, "asc" | "desc">;
}) => {
  return (
    <div>
      <div
        className={`d-flex align-items-center px-2 mb-2 ${styles.tableHead}`}
      >
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
          />
        </div>

        {tableHeadingIds.map((id) => {
          const heading = tableHeadings[id];
          return (
            <div className={`text-truncate ${styles.headerName} d-flex`}>
              <span className={`${styles.tablePara} d-flex align-items-center`}>
                {heading?.tableHeadingName}
              </span>
              {heading.isSortable ? (
                <div>
                  {sortOrder[id] ? (
                    <div className="d-flex flex-column ms-1 align-items-center">
                      {sortOrder[id] === "desc" ? (
                        <span
                          className={`${styles.upArrow} ${
                            sortOrder[id] === "desc" ? styles.activeSort : ""
                          } cursor-pointer`}
                          onClick={() => onSort(id, "asc")}
                        >
                          <i className="fa-solid fa-arrow-up"></i>
                        </span>
                      ) : null}
                      {sortOrder[id] === "asc" ? (
                        <span
                          className={`${styles.upArrow} ${
                            sortOrder[id] === "asc" ? styles.activeSort : ""
                          } cursor-pointer`}
                          onClick={() => onSort(id, "desc")}
                        >
                          <i className="fa-solid fa-arrow-down"></i>
                        </span>
                      ) : null}
                    </div>
                  ) : (
                    <div className="d-flex flex-column ms-1 align-items-center">
                      <span
                        className={`${styles.upArrow} cursor-pointer`}
                        onClick={() => onSort(id, "asc")}
                      >
                        <i className="fa-solid fa-angle-up"></i>
                      </span>
                      <span
                        className={`${styles.upArrow} cursor-pointer`}
                        onClick={() => onSort(id, "desc")}
                      >
                        <i className="fa-solid fa-angle-down"></i>
                      </span>
                    </div>
                  )}
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TableHeader;
