import {
  ESideBarFilterIndex,
  ETableTypeIndex,
  ETableTypes,
} from "src/services/TicketService/linkGenerator";
import { defaultDDFiltersBase } from "./ticketSidebar.selectors";
import { initialState, ITicketSideBarState } from "./ticketSidebar.slice";

function setDDSelectedIndex(
  state: ITicketSideBarState,
  {
    payload,
  }: {
    payload: {
      tableType: string;
      filterNames: any;
    };
  }
) {
  if (
    state.ticketBtnData.length &&
    ETableTypeIndex[payload.tableType as any] !== undefined &&
    payload.filterNames
  ) {
    for (let i = 0; i < 3; i++) {
      state.ticketBtnData[0].ticketBtnList[
        parseInt(ETableTypeIndex[payload.tableType as any] + "")
      ].ddOuterList[i].ddSelectedIndex =
        payload.filterNames[ESideBarFilterIndex[i]];
    }
  } else {
    if (!payload.tableType.includes("viewId")) {
      for (let i = 0; i < 3; i++) {
        state.ticketBtnData[0].ticketBtnList[
          parseInt(ETableTypeIndex[payload.tableType as any] + "")
        ].ddOuterList[i].ddSelectedIndex = defaultDDFiltersBase(state).arr[i];
      }
    }
  }
}

const selectDDIndex = (state: ITicketSideBarState, { payload }: any) => {
  if (
    state.ticketBtnData.length &&
    (state.selectedBtn === "all" || state.selectedBtn === "my")
  ) {
    const ddSelectedIndex =
      state.ticketBtnData[0].ticketBtnList[
        parseInt(ETableTypeIndex[state.selectedBtn as any] + "")
      ].ddOuterList[payload.index].ddSelectedIndex;

    if (payload?.remove === true) {
      const newDDSelectedIndex = ddSelectedIndex.filter(
        (index) => index !== parseInt(payload.selected + "")
      );

      //keeping the last checked as it is, user have to select another option in order to remove this one
      if (newDDSelectedIndex.length) {
        state.ticketBtnData[0].ticketBtnList[
          parseInt(ETableTypeIndex[state.selectedBtn as any] + "")
        ].ddOuterList[payload.index].ddSelectedIndex = newDDSelectedIndex;
      }
    } else {
      if (!ddSelectedIndex.includes(parseInt(payload.selected + ""))) {
        //checking if selected is all, if not then remove all from the selected list
        if (payload.selected == 0) {
          state.ticketBtnData[0].ticketBtnList[
            parseInt(ETableTypeIndex[state.selectedBtn as any] + "")
          ].ddOuterList[payload.index].ddSelectedIndex = [
            parseInt(payload.selected + ""),
          ];
        } else {
          const newDDSelectedIndex = ddSelectedIndex.filter(
            (selected) => selected !== 0
          );

          newDDSelectedIndex.push(parseInt(payload.selected + ""));

          state.ticketBtnData[0].ticketBtnList[
            parseInt(ETableTypeIndex[state.selectedBtn as any] + "")
          ].ddOuterList[payload.index].ddSelectedIndex = newDDSelectedIndex;
        }
      }
    }
  }
};
// save the url search query related to a particular table type
function saveTicketAppliedData(
  state: ITicketSideBarState,
  {
    payload,
  }: {
    payload: {
      tableType: string;
      ticketAppliedData: any;
    };
  }
) {
  if (payload.tableType === "mentions") {
    state.mentionsTicketAppliedData = payload.ticketAppliedData ?? "";
  } else if (!payload.tableType.includes("viewId")) {
    if (
      state.ticketBtnData.length &&
      ETableTypeIndex[payload.tableType as any] !== undefined
    ) {
      state.ticketBtnData[0].ticketBtnList[
        parseInt(ETableTypeIndex[payload.tableType as any] + "")
      ].ticketAppliedData = payload.ticketAppliedData ?? "";
    }
  } else {
    if (state.usersViewData[payload.tableType.split("::")[1]]) {
      state.usersViewData[payload.tableType.split("::")[1]].ticketAppliedData =
        payload.ticketAppliedData ?? "";
    }
  }
}
function saveTicketAppliedViewCols(
  state: ITicketSideBarState,
  {
    payload,
  }: {
    payload: {
      savedTicketAppliedData: { [key: string]: any };
    };
  }
) {
  Object.entries(payload.savedTicketAppliedData).map(
    ([tableType, ticketAppliedData]: any) => {
      if (tableType === "mentions") {
        const appliedData = state.mentionsTicketAppliedData;
        if (appliedData && appliedData.trim() !== "") {
          const json = JSON.parse(appliedData);
          json["allViewSelectedCols"] = ticketAppliedData.allViewSelectedCols;

          state.mentionsTicketAppliedData = JSON.stringify(json);
        } else {
          state.mentionsTicketAppliedData = JSON.stringify(ticketAppliedData);
        }
      } else if (!tableType.includes("viewId")) {
        if (
          state.ticketBtnData.length &&
          ETableTypeIndex[tableType as any] !== undefined
        ) {
          if (
            state.ticketBtnData[0].ticketBtnList[
              parseInt(ETableTypeIndex[tableType as any] + "")
            ]
          ) {
            const appliedData =
              state.ticketBtnData[0].ticketBtnList[
                parseInt(ETableTypeIndex[tableType as any] + "")
              ].ticketAppliedData;
            if (appliedData && appliedData.trim() !== "") {
              const json = JSON.parse(appliedData);
              json["allViewSelectedCols"] =
                ticketAppliedData.allViewSelectedCols;

              state.ticketBtnData[0].ticketBtnList[
                parseInt(ETableTypeIndex[tableType as any] + "")
              ].ticketAppliedData = JSON.stringify(json);
            } else {
              state.ticketBtnData[0].ticketBtnList[
                parseInt(ETableTypeIndex[tableType as any] + "")
              ].ticketAppliedData = JSON.stringify(ticketAppliedData);
            }
          }
        }
      } else {
        if (state.usersViewData[tableType.split("::")[1]]) {
          const appliedData =
            state.usersViewData[tableType.split("::")[1]].ticketAppliedData;
          if (appliedData && appliedData.trim() !== "") {
            const json = JSON.parse(appliedData);
            json["allViewSelectedCols"] = ticketAppliedData.allViewSelectedCols;

            state.usersViewData[tableType.split("::")[1]].ticketAppliedData =
              JSON.stringify(json);
          } else {
            state.usersViewData[tableType.split("::")[1]].ticketAppliedData =
              JSON.stringify(ticketAppliedData);
          }
        }
      }
    }
  );
}

function setSelectedBtn(
  state: ITicketSideBarState,
  { payload }: { payload: { tableType: string } }
) {
  state.selectedBtn = payload.tableType;
}

function setSelectedAiBtn(
  state: ITicketSideBarState,
  { payload }: { payload: { btnType: "Open" | "Closed" | null } }
) {
  state.selectedAiBtn = payload.btnType;
}

function setSidebarData(
  state: ITicketSideBarState,
  { payload }: { payload: any }
) {
  state = { ...state, ...payload };
}

function resetTicketSideBarAllData(state: ITicketSideBarState) {
  return { ...initialState };
}
/**
 * Reducer for updating users view manually when deleting
 */
function deleteUserView(
  state: ITicketSideBarState,
  { payload }: { payload: { viewId: string } }
) {
  // Find the ticketBtnData for Your views
  const index = state.ticketBtnData.findIndex(
    (val) => val.header === "Your views"
  );

  // Filter the deleted ticket view
  state.ticketBtnData[index].ticketBtnList = state.ticketBtnData[
    index
  ].ticketBtnList.filter((btn) => btn.keyName !== `viewId::${payload.viewId}`);

  // Delete View data
  delete state.usersViewData[payload.viewId];

  // Remove the view id from view list
  state.usersViewList = state.usersViewList.filter(
    (id) => id !== parseInt(payload.viewId)
  );

  // Update total count
  state.usersViewTotal--;
}

export default {
  setDDSelectedIndex,
  selectDDIndex,
  // saveUrlSearchQuery,
  saveTicketAppliedData,
  setSelectedBtn,
  setSelectedAiBtn,
  setSidebarData,
  resetTicketSideBarAllData,
  saveTicketAppliedViewCols,
  deleteUserView,
};
