import { axiosJSON } from "src/globals/axiosEndPoints";

export interface CustomerTicketsHistoryPayload {
  customerId: string | number;
  start: number;
  limit: number;
}

export interface CustomerTH {
  ticket_id: string | number;
  ticket_to: string;
  ticket_cc: string | null;
  ticket_bcc: string | null;
  ticket_from: string;
  ticket_channel: string;
  message_thread: any;
  ticket_subject: string;
}

export interface CustomerTicketsHistory {
  tickets: { [ticketId: string | number]: CustomerTH };
  ticketIds: Array<string | number>;
  metaData: {
    currentCount: number;
    totalCount: number;
  };
}

const getCustomerTicketsHistory = async (
  payload: CustomerTicketsHistoryPayload
) => {
  const { data: res } = await axiosJSON.post(
    `/api/liveChat/chat/getCustomerTicketsHistory`,
    payload
  );
  const ret: CustomerTicketsHistory = {
    tickets: {},
    ticketIds: [],
    metaData: res.metaData,
  };
  res.data.forEach((ele: CustomerTH) => {
    ret.tickets[ele.ticket_id] = ele;
    ret.ticketIds.push(ele.ticket_id);
  });
  return ret;
};

export default getCustomerTicketsHistory;
