import { useCallback } from "react";
import CSSideBar from "./children/CSSideBar/CSSideBar";
import SegmentView from "./children/SegmentView/SegmentView";
import { useCustomerSegmentsCreate } from "./hooks/useCustomerSegments";

function CustomerSegments() {
  const [state, dispatch, Provider] = useCustomerSegmentsCreate();

  const onHide = useCallback(() => {
    dispatch("removeUpdateSegmentDetails");
  }, [dispatch]);

  return (
    <Provider value={[state, dispatch]}>
      <CSSideBar />
      <SegmentView
        data={state.segmentDataForUpdate}
        onHide={onHide}
      />
    </Provider>
  );
}

export default CustomerSegments;
