import React from "react";
import styles from "./SearchMention.module.scss";
import UserAvatar from "src/components/UserAvatar";
import { Customer } from "src/services/LiveChat/messageExchange/getAllCustomers";
import { getTimeFromNow } from "src/utils/dateLibrary";
import { useAppSelector } from "src/store/store";
import AxiosImg from "src/components/AxiosImg";
import ParseHTML from "src/components/ParseHTML";
import { getInnerText } from "src/utils/utils";
import { useParams } from "react-router-dom";
const SearchMention = ({ name, lastMessage, email, ...customer }: Customer) => {
  const currentUser = useAppSelector((state) => state.globals.currentUserData);
  const { chatId } = useParams();
  return (
    <div
      className={`mx-2 mb-2 cursor-pointer ${
        chatId == customer.chatId
          ? styles.mentionWrapperSelected
          : styles.mentionWrapper
      }`}
    >
      <p className={`mb-0 ${styles.header}`}>
        <span>
          <i className="fa-solid fa-message"></i>
        </span>
        <span className="ms-1">
          {/* {`${customer.mentionedBy?.name} */}
          <span className="ms-1">{`${customer.mentionedBy?.name} mention you in an Internal Note`}</span>
        </span>
      </p>
      <span className={`${styles.time}`}>
        {getTimeFromNow({
          date: new Date(customer.lastMessageTime),
          timeZone: currentUser?.userTimezone,
        })}
      </span>
      <div className={`d-flex align-items-center`}>
        <div className="position-relative">
          {customer.imageURL ? (
            <AxiosImg
              url={customer.imageURL}
              className={`${styles.customerImg}`}
              isDirectURL={customer?.isPublicAttachmentUrl}
            />
          ) : (
            <UserAvatar
              name={name ?? email}
              size={30}
              className={`${styles.customerImg}`}
            />
          )}
          {customer.isCustomerOnline ? (
            <span className={`${styles.greenDot}`}></span>
          ) : (
            <></>
          )}
        </div>
        <p className={`mb-0 ps-1 ${styles.userName}`}>{name}</p>
        <p className={`ps-1 mb-0 ${styles.mail}`}>{`<${email}>`}</p>
      </div>
      <div>
        <p className={`mb-0 mt-1 d-flex align-items-center`}>
          {/* <span className={`${styles.mentionText}`}>
            @{currentUser?.firstName} {currentUser?.lastName}
          </span> */}
          {/* <span className={``}>
            {customer.lastMentionMessage}
          </span> */}
          <ParseHTML
            className={`ps-1 ${styles.userMsg}`}
            html={customer.lastMentionMessage ?? ""}
          />
        </p>
      </div>
    </div>
  );
};

export default SearchMention;
