import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { AJAXSTATUS } from "src/globals/constants";
import {
  ActionOptionsResponse,
  getActionOptions,
} from "src/services/Automation/AutomationTicketRouting/getActionOptions.service";
import { EAutomationType } from "../../../AutomationList/AutomationList";

/**
 * Custom hook to fetch action options
 *
 * This hook utilizes the `useQuery` hook from `@tanstack/react-query` to fetch
 * action options from the `getActionOptionsService`.
 * The query result is cached for an hour (`cacheTime` and `staleTime`
 * set to 3600000 ms), ensuring that the data remains fresh for the specified duration.
 *
 * @param type - The type of the automation.
 * @returns An object containing the fetched action options and the status of the request.
 * actionOptions - The fetched action options, or null if the request failed.
 * actionOptionsStatus - The status of the request, which can be one of "fulfilled", "pending", or "rejected".
 *
 * @example
 * const { actionOptions, actionOptionsStatus } = useFetchactionOptions();
 *
 * @see {@link getActionOptions} for the service used to fetch action options.
 */
function useFetchActionOptions(type: string, integrationId: string) {
  const { data: actionOptions, status: actionsFetchStatus } = useQuery(
    ["useFetchActionOptions"],
    {
      queryFn: async () => {
        return await getActionOptions(
          EAutomationType.TICKET_ROUTING,
          integrationId ?? "",
        );
      },
      cacheTime: 3600000,
      staleTime: 3600000,
      networkMode: "always",
    },
  );

  return {
    actionOptions: actionOptions
      ? actionOptions
      : (null as ActionOptionsResponse | null),
    actionOptionsStatus:
      actionsFetchStatus === "success"
        ? "fulfilled"
        : actionsFetchStatus === "loading"
          ? "pending"
          : ("rejected" as AJAXSTATUS),
  };
}

export default useFetchActionOptions;
