/* eslint-disable jsx-a11y/anchor-is-valid */
import styles from "./ArticleFolder.module.scss";
import folder from "src/assets/images/folderImg.png";
import drag from "src/assets/images/drag.png";
import dots from "src/assets/images/dots.svg";
import SubArticleFolder from "./SubArticleFolder/SubArticleFolder";
import { v4 as uuidV4 } from "uuid";
import useArticleFolder from "./useArticleFolder";
import { IArticleCategoryDataV2 } from "src/services/LiveChat/Settings/knowledgeBased/articleCategory/getAllArticleCategoryV2";
import InfiniteScroll from "src/components/InfiniteScrollBothSide";
import { Dropdown } from "react-bootstrap";
import arrowImg from "src/assets/images/blueArrow.png";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
} from "react-beautiful-dnd";

type Props = {
  category: IArticleCategoryDataV2;
  handleArticleDelete: any;
  handleCategoryDelete: any;
  handleUpdateCategory: any;
  handleArticleDuplicate: any;
  setShowSubFolder: Function;
  provided: DraggableProvided;
};

const ArticleFolder = ({
  category,
  handleArticleDelete,
  handleCategoryDelete,
  handleUpdateCategory,
  handleArticleDuplicate,
  setShowSubFolder,
  provided,
}: Props) => {
  const {
    handleArticleInfiniteScroll,
    hasMoreArticle,
    handleAddNewArticle,
    articelLoader,
    SubFolder,
    showSubFolder,
    handleOnDrag,
  } = useArticleFolder({
    categoryId: category.categoryId,
  });

  return (
    <>
      <div
        className={`d-flex justify-content-between align-items-center py-3 m-2 ${styles.knowledgeBox}`}
      >
        <div
          className="d-flex align-items-center w-100"
          onClick={() => {
            SubFolder(!showSubFolder);
            setShowSubFolder(!showSubFolder);
          }}
        >
          <span className="me-3" {...provided.dragHandleProps}>
            <img src={dots} alt="dots" />
          </span>
          <span>
            <img src={folder} alt="dots" className={`${styles.folderImg}`} />
          </span>
          <span className={`mx-2 ${styles.rightArrow}`}>
            {showSubFolder ? (
              // <i className="fa-solid fa-angle-down"></i>
              <img src={arrowImg} alt="" className={`${styles.arrowDown}`} />
            ) : (
              // <i className="fa-solid fa-angle-right"></i>
              <img src={arrowImg} alt="" />
            )}
          </span>

          <span className={`${styles.gettingStarted} saufter_medium_h4`}>
            {category.categoryName}
          </span>
        </div>
        {/* Check if current user can delete or edit the category */}
        {category.isUserCanActionPerformCategory ? (
          <div>
            <Dropdown>
              <Dropdown.Toggle
                as="div"
                id="dropdown-basic"
                bsPrefix={`dropdown-toggle cursor-pointer my-1 my-md-0 d-flex justify-content-center align-items-center ${styles.more}`}
              >
                <span>
                  <i className="fa-solid fa-ellipsis"></i>
                </span>
              </Dropdown.Toggle>

              <Dropdown.Menu bsPrefix={`dropdown-menu ${styles.addNewBox}`}>
                <li>
                  <span
                    className={`${styles.innerDropDown} saufter_h4`}
                    onClick={(e) =>
                      handleUpdateCategory(
                        category.categoryId,
                        category.categoryName
                      )
                    }
                  >
                    <span className="me-1">
                      {" "}
                      <i className="fa-solid fa-pen-to-square"></i>
                    </span>
                    Edit Name
                  </span>
                </li>
                <li>
                  <span
                    className={`${styles.innerDropDown} saufter_red_h4`}
                    onClick={(e) => handleCategoryDelete(category.categoryId)}
                  >
                    <span className={`me-1 ${styles.delete}`}>
                      <i className="fa-solid fa-trash"></i>
                    </span>
                    <span className={`${styles.delete}`}>Delete category</span>
                  </span>
                </li>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ) : (
          <></>
        )}
      </div>
      {showSubFolder && (
        <div>
          <InfiniteScroll
            className={` overflow-auto  w-100`}
            loadMoreFromBottom={handleArticleInfiniteScroll}
            hasMoreBottom={hasMoreArticle}
            style={{ maxHeight: "300px", marginTop: "29px" }}
          >
            <DragDropContext onDragEnd={(result) => handleOnDrag(result)}>
              <Droppable droppableId={category.categoryId as string}>
                {(provided, snapshot) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {category.articlesIds.map((articleId, index) => {
                      const articleData = category.articles[articleId];
                      return (
                        <Draggable
                          key={articleId}
                          draggableId={articleId + "" + category.categoryId}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              className="pe-2"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <SubArticleFolder
                                article={articleData}
                                handleArticleDelete={handleArticleDelete}
                                handleArticleDuplicate={handleArticleDuplicate}
                                categoryId={category.categoryId}
                                provided={provided}
                              />
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </InfiniteScroll>
          <div className={``}>
            <p
              className={`max-content-width d-flex align-items-center ${styles.addArticle}`}
              onClick={(e) => handleAddNewArticle(category.categoryId)}
            >
              <span className={`${styles.addIcon}`}>+</span>{" "}
              <span> Add New Article</span>
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default ArticleFolder;
