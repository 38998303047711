import styles from "./AllOrders.module.scss";
import AllOrderMoreColoumn from "./children/AllOrderMoreColuomn/AllOrderMoreColoumn";
import AllOrderHeader from "./children/AllOrderHeader.tsx/AllOrderHeader";
import AllOrderTableHeader from "./children/AllOrderTableHeader/AllOrderTableHeader";
import LocationFilter from "./children/LocationFilter/LocationFilter";
import SearchBarFilter from "./children/SearchBarFilter/SearchBarFilter";
import AllOrderSubHeader from "./children/AllOrderSubHeader/AllOrderSubHeader";
import AllOrderSubRows from "./children/AllOrderSubRow/AllOrderSubRow";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { useEffect, useState } from "react";
import {
  fetchAllOrders,
  Order,
} from "src/store/slices/allOrders/allOrders.slice";
import { CloseButton } from "react-bootstrap";
import AllOrdersContext from "./AllOrdersContext";
import AllOrdersContextProvider from "./AllOrdersContextProvider";

function AllOrders({ setShowModal, onHide }: any) {
  const dispatch = useAppDispatch();

  const [isCheckAll, setIsCheckAll] = useState(false);

  return (
    <div>
      <div className={`${styles.allOrderBody}`}>
        <div className="d-flex justify-content-between">
          <AllOrderHeader />
          <span className={`${styles.closeButton}`} onClick={onHide}>
            <i className="fa-solid fa-xmark"></i>
          </span>
        </div>
        <AllOrderMoreColoumn />
        {/* <AllOrderTableHeader  /> */}
        <LocationFilter />
        <SearchBarFilter />

        <AllOrdersContextProvider>
          <div
            style={{ height: "55vh" }}
            className={`d-flex flex-column overflow-auto ${styles.allOrderTable}`}
          >
            <AllOrderSubHeader
              isCheckAll={isCheckAll}
              setIsCheckAll={setIsCheckAll}
            />
            <AllOrderSubRows
              setShowModal={setShowModal}
              isCheckAll={isCheckAll}
              setIsCheckAll={setIsCheckAll}
            />
          </div>
        </AllOrdersContextProvider>
      </div>
    </div>
  );
}

export default AllOrders;
