/**
 * This file is the service file used for making api request.
 * It contains the getRatingsAndComments function service which is explained below.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { axiosKbPreview } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants"; // this is used for testing purpose only for frontend.

interface GetRatingAndCommentData {
  ratingShowHelpCount: boolean;
  userAllowedToRate: boolean;
  feedbackRatingType: "thumbsUpAndDown" | "emojis";
  commentsEnabled: boolean;
  commentApprovalRequired: boolean;
  commentsType: "unApproved" | "approved";
  totalArticleRating: number;
  positiveArticleRating: number;
  totalComment?: number;
  alreadyRating: boolean;
  alreadyComment: boolean;
}

export interface GetRatingsAndCommentsResponse {
  data: GetRatingAndCommentData;
  err: string;
  tokenExpire: boolean;
}

export interface getRatingsAndCommentsPayload {
  integrationIdentifier: string;
  articleId: number | string;
}

/**
 * For testing frontend with own api link if backend is not prepared.
 */
const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/`
  : "/api/knowledgebase/helpCenterPage/";

/**
 * This service is made to get the rating and comment details.
 */
export const getRatingsAndComments = async (
  params: getRatingsAndCommentsPayload
) => {
  const { data: res } = await axiosKbPreview.post(
    `${apiEndPoint}${params.integrationIdentifier}/getRatingsAndComments`,
    {
      articleId: params.articleId,
    }
  );
  return res as GetRatingsAndCommentsResponse;
};
