import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { PropsFromToggle } from "react-bootstrap/esm/DropdownToggle";
import styles from "./ChoiceList.module.scss";
import { v4 as uuidV4 } from "uuid";

function ChoiceList({ element, inputName }: any) {
  return (
    <div className={`d-flex flex-column text-muted ${styles.fonts}`}>
      <div className="mb-2">
        {/* {element.elementValue}{" "} */}
        <div className={styles.inputLabel}>{element.elementValue}</div>
        {element.isRequired ? <span className="text-danger align-top"> *</span> : null}{" "}
      </div>
      {element.elementOptions?.map((choiceText: string) => {
        return (
          <Form.Check
            key={uuidV4()}
            className={styles.formCheck}
            inline
            label={choiceText}
            required={element.isRequired === true}
            name={inputName}
            type="radio"
          />
        );
      })}
    </div>
  );
}

export default ChoiceList;
