import { axiosJSON } from "src/globals/axiosEndPoints";
import { BrandData } from "./getAllBrands";

export interface BrandSignaturePlaceholder {
  id: string|number
  label: string,
  placeholder: string
}

export async function getBrandSignaturePlaceholders() {

  const { data: res } = await axiosJSON.get("/api/brand/getSignaturePlaceholders");

  if (res.err) {
    throw new Error(res.msg);
  }

  const ret: Array<BrandSignaturePlaceholder> = [];

  return res.data as Array<BrandSignaturePlaceholder>;
}
