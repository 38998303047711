import { useCallback, useEffect, useRef, useState } from "react";
import { Overlay } from "react-bootstrap";
import styles from "../../TeamPage.module.scss";
import cfStyles from "./AddMembers.module.scss";
import user from "src/assets/images/user.png";
import UserRow from "./UserRow/UserRow";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  fetchAllUser,
  resetUserData,
  setSearchValue,
} from "src/store/slices/settings/user/userSettings.slice";
import { fetchAllUsers } from "src/services/UserService/fetchAllUsers";
import InfiniteScroll from "src/components/InfiniteScroll";
import useDebounce from "src/hooks/useDebounce";
import { v4 as uuidv4 } from "uuid";
import CreateNAddUser from "./CreateNAndUser/CreateNAddUser";

function AddMembers(props: any) {
  const [showAddMemberModal, setShowAddMemberModal] = useState(false);
  const [showCreateNewUserModal, setShowCreateNewUserModal] =
    useState<boolean>(false);
  const target = useRef(null);
  const scopes = useAppSelector(
    (state) => state.globals.currentUserData?.scopes,
  );

  const disabledFeatures = useAppSelector(
    (state) => state.globals.currentUserData?.disabledFeatures,
  );

  const initialized = useRef(false);
  const dispatch = useAppDispatch();
  const { filters, userDataAjaxStatus, userIds, metaData, users } =
    useAppSelector((state) => state.userSettings);
  const currentState = useRef({
    filters,
    userDataAjaxStatus,
    userIds,
    metaData,
    users,
  });

  useEffect(() => {
    currentState.current = {
      filters,
      userDataAjaxStatus,
      userIds,
      metaData,
      users,
    };
  }, [filters, userDataAjaxStatus, userIds, metaData, users]);

  /* Handle searching users */
  const [searchText, setSearchText] = useState(filters.searchValue);
  const debouncedSearchTerm = useDebounce(searchText, 1000);
  const inputSearchRef: any = useRef(null);
  useEffect(() => {
    handleSearchUsers();
    if (inputSearchRef.current) {
      inputSearchRef.current.blur();
    }
  }, [debouncedSearchTerm]);

  const handleSearchUsers = () => {
    dispatch(setSearchValue({ searchValue: searchText }));
    dispatch(resetUserData());
    dispatch(fetchAllUser());
  };

  /* Handle infinite scroll */
  const handleInfiniteScroll = useCallback(() => {
    if (currentState.current.userDataAjaxStatus === "fulfilled") {
      dispatch(fetchAllUser());
    }
  }, []);

  useEffect(() => {
    dispatch(resetUserData());
    // dispatch(fetchAllUser());
    initialized.current = true;
  }, [false]);

  /* Handle the click on create new user in add member modal */
  const handleShowCreateNewUserModal = (e: any) => {
    e.preventDefault();
    setShowAddMemberModal(false);
    setShowCreateNewUserModal(true);
  };

  const handleHideCreateNewUserModal = () => {
    setShowCreateNewUserModal(false);
  };

  return (
    <>
      <button
        className={`${styles.pink_btn_submit} `}
        ref={target}
        onClick={() => setShowAddMemberModal(!showAddMemberModal)}
      >
        Add members
      </button>
      <Overlay
        target={target.current}
        show={showAddMemberModal}
        placement="top"
      >
        {({ placement, show: _show, popper, ...props }) => (
          <div
            {...props}
            style={{
              position: "absolute",
              backgroundColor: "white",
              padding: "2px 0",
              // color: "black",
              borderRadius: 3,
              border: "none",
              ...props.style,
            }}
            className={`${styles.layOver}`}
          >
            <form className={`px-2 ${styles.addMemberUsers} `}>
              <div className={`${styles.addMemberSearch} d-flex my-2`}>
                <div>
                  <i className={`${styles.modalFontAwsome} fa fa-search `} />{" "}
                </div>
                <input
                  data-lpignore="true"
                  type="text"
                  ref={inputSearchRef}
                  className={`w-100 ${styles.modalSearch} `}
                  placeholder="Search User"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </div>
            </form>

            <div
              className={`${styles.layOverContainer} d-flex flex-column justify-content-between `}
            >
              {/* <div> */}
              <p className={`${styles.dropUser} px-2`}>Users</p>
              <InfiniteScroll
                className={`${cfStyles.userRowDiv} ${styles.infScroll}`}
                loadMore={handleInfiniteScroll}
                initialDataLoaded={false}
                hasMore={
                  userIds.length < metaData.total || metaData.total === 0
                }
                errorMessage={
                  metaData.total === 0 &&
                  (userDataAjaxStatus === "rejected" ||
                    userDataAjaxStatus === "fulfilled") ? (
                    <></>
                  ) : undefined
                }
              >
                {Object.keys(users).length > 0 &&
                  userIds.map((userId, index) => {
                    const userData = users[userId];
                    return (
                      <UserRow
                        key={"addUserModalRow_" + userId}
                        userData={userData}
                        setShowAddMemberModal={setShowAddMemberModal}
                      />
                    );
                  })}
              </InfiniteScroll>
              {/* </div> */}

              {scopes && scopes.includes("add_user") && (
                <div className="">
                  <span>
                    <hr></hr>
                  </span>
                  <div
                    className={`${cfStyles.createNewUserDiv} px-2`}
                    style={{ color: "#FF8499" }}
                  >
                    <button
                      className={cfStyles.createNewUserTxt}
                      onClick={(e) => handleShowCreateNewUserModal(e)}
                      disabled={disabledFeatures?.includes("add_users")}
                    >
                      + Create new users
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </Overlay>

      {/* Show the create a new user modal */}
      {showCreateNewUserModal === true ? (
        <CreateNAddUser
          //  setShowAddMemberModal = {setShowAddMemberModal}
          handleHideCreateNewUserModal={handleHideCreateNewUserModal}
        />
      ) : undefined}
    </>
  );
}

export default AddMembers;
