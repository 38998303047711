import { Modal } from "react-bootstrap";
import styles from "./NotifyErrorModal.module.scss";
import ReactDOM from "react-dom";

const NotifyErrorModal = ({ onHide }: { onHide: () => void }) => {
  return (
    <>
      {/* Modal for notifying agents */}
      <Modal
        backdropClassName={`${styles.modalBack}`}
        show={true}
        onHide={onHide}
        dialogClassName={`${styles.modalDialog}`}
        contentClassName={`${styles.modalContent}`}
        //   centered={true}
        enforceFocus={false}
      >
        <div className="text-center">
          <h4 className={`mt-4 ${styles.modalHead}`}>
            Please Select atleast one channel group
          </h4>
          <button className={`mt-2 ${styles.submitBtn}`} onClick={onHide}>
            OK
          </button>
        </div>
      </Modal>
    </>
  );
};

export const openNotifyErrorModal = () => {
  const modalContainer = document.createElement("div");
  document.body.appendChild(modalContainer);

  const onHide = () => {
    ReactDOM.unmountComponentAtNode(modalContainer);
    document.body.removeChild(modalContainer);
  };

  ReactDOM.render(<NotifyErrorModal onHide={onHide} />, modalContainer);
};
