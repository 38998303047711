import { axiosJSON } from "src/globals/axiosEndPoints";

interface InstructionDetail {
  type: number;
  name: string;
}

interface InstructionCategoryDetails {
  type: number;
  name: string;
}

interface InstructionData {
  instructionCategoryDetails: InstructionCategoryDetails;
  instructionDetails: InstructionDetail[];
}


const getCernInstruction = async (brandId: number) => {
  const { data: res } = await axiosJSON.get(`/api/aiBotInstruction/getAll?brandId=${brandId}`);

  if (res.err || res.error) {
    throw res.msg ?? res.message ?? "Something went wrong!";
  }

  return res.data as InstructionData[];
};

export default getCernInstruction;
