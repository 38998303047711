/**
 * This file contains the custom hook for handling KB settings.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { useEffect, useState } from "react";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import useDomain from "../Domain/useDomain";

/**
 * Custom hook for managing the AccessControl component in KB settings.
 */
function useAccessControl() {
  // Getting data from useDomain hook
  const {
    data,
    integrationId,
    updateDomaiAndSslAccessControl,
    isLoading,
    IpValidationError,
    setIpValidationError,
  } = useDomain();

  // State for dropdown
  const [dropdown, setDropDown] = useState(true);

  // State for password restriction
  const [passRestriction, setPassRestriction] = useState(
    data?.passwordRestriction ?? false
  );

  //  State for ip white listing
  const [ipWhiteListing, setIpWhiteListing] = useState(
    data?.isIpWhitelist ?? false
  );

  //  State for showing password
  const [showPassword, setShowPassword] = useState(false);

  // Function for toggling the show password
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // Updating the state when data is fetched or changed
  useEffect(() => {
    if (data) {
      setPassRestriction(data?.passwordRestriction);
      setIpWhiteListing(data?.isIpWhitelist);
      setPassword(data?.password);
      setIpAddress(data?.ipWhitelistList);
    }
  }, [data]);

  // State for password and ip address
  const [password, setPassword] = useState(data?.password ?? "");
  const [ipAddress, setIpAddress] = useState(data?.ipWhitelistList ?? "");

  // Handling the change of password restriction
  const handlePassRestrictionChange = (e: any) => {
    setPassRestriction(e.target.checked);
    setShowValidation({
      password: false,
    });
  };

  // Handling the change of ip white listing
  const handleIpWhiteListingChange = (e: any) => {
    setIpWhiteListing(e.target.checked);
  };

  // Handling the change of password
  const handlePasswordChange = (e: any) => {
    setShowValidation({
      password: false,
    });
    setPassword(e.target.value);
  };

  // Handling the change of ip address
  const handleIpAddressChange = (e: any) => {
    setIpValidationError(false);
    setShowValidation({
      ipAddress: false,
    });
    setIpAddress(e.target.value);
  };

  const [showValidation, setShowValidation] = useState<{
    password?: boolean;
    ipAddress?: boolean;
  }>({
    password: false,
    ipAddress: false,
  });

  // Handling the save changes
  const handleSaveChanges = () => {
    // Checking if password is empty or ip address is empty and ip white listing is true
    if (!password || (!ipAddress && ipWhiteListing) || IpValidationError) {
      if (!password && passRestriction) {
        setShowValidation({
          password: true,
        });
        return;
      } else if ((ipWhiteListing && !ipAddress) || IpValidationError) {
        setShowValidation({
          ipAddress: true,
        });
        return;
      } else {
        updateDomaiAndSslAccessControl.mutate({
          integrationId: integrationId,
          passwordRestriction: passRestriction,
          password: password,
          isIpWhitelist: ipWhiteListing,
          ipWhitelistList: ipAddress,
        });
      }
    } else {
      setShowValidation({
        password: false,
        ipAddress: false,
      });
      // Calling the update domain and ssl access control mutation
      updateDomaiAndSslAccessControl.mutate({
        integrationId: integrationId,
        passwordRestriction: passRestriction,
        password: password,
        isIpWhitelist: ipWhiteListing,
        ipWhitelistList: ipAddress,
      });
    }
  };
  return {
    dropdown,
    setDropDown,
    passRestriction,
    ipWhiteListing,
    password,
    ipAddress,
    handlePassRestrictionChange,
    handleIpWhiteListingChange,
    handlePasswordChange,
    handleIpAddressChange,
    handleSaveChanges,
    data,
    updateDomaiAndSslAccessControl,
    isLoading,
    showPassword,
    toggleShowPassword,
    showValidation,
    setShowValidation,
    IpValidationError,
  };
}

export default useAccessControl;
