import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";
import {
  findMoveToNextButtonPosition,
  shouldQuestionBeRelativeHidden,
} from "src/routes/BotSettings/Children/Automation/helpers/Automation.helpers";
import { AutomationAPIPayload } from "./createAutomation.service";
import {
  BetweenRange,
  CompareTypes,
  FieldDataTypes,
  HoursDays,
} from "./AutomationTicketRouting/getConditionOptions.service";

export interface GetBugConfigParams {
  integrationId: string;
}

// Enum for Question Types
export enum QuestionType {
  List = "list",
  MultiList = "multiList",
  Checkbox = "checkbox",
  // Text = "text",
  Radio = "radio",
  ConfigOnly = "configOnly", // No question data other than config action
  BugChannel = "bugChannel",
  BugCategories = "bugCategories",
  TextBox = "textBox", // For single line text box
  ConditionGroup = "conditionGroup", // For Ticket routing condition groups
  Action = "action", // For Ticket routing actions
  AutomationSummary = "automationSummary", // For ticket routing automation summary
}

interface DependentQuestion {
  questionKey: string;
  options: string[];
}

/**
 * Action for defining buttons
 */
export interface IAction {
  type: "configuration";
  key: "dispatchRules";
  displayName: string;
  status: "completed" | "pending";
  configurationType: string;
}

// Related Question Interface
interface IRelatedQuestion {
  relatedQuestionKey: string;
  allowedOptions: string[];
  relatedQuestionStepId: number;
}

export enum QuestionVisibility {
  Hidden = "hidden",
  Show = "show",
  RelativeHidden = "relativeHidden",
}

// Base Question Interface for common properties
interface IBaseQuestion {
  // id: number | string;
  questionKey: string;
  questionTitle: string;
  isAnswered: boolean;
  isRequired: boolean;
  questionType: QuestionType;
  relatedQuestion: IRelatedQuestion | null;
  isApiRequired?: boolean;
  isBrandRequired?: boolean;
  action?: IAction[];
  questionSubTitle?: string;
  questionInfo?: {
    text: string;
    isBold: boolean;
  }[];
  questionPlaceholder?: string;
  // Not used at the moment
  configOptionKey?: string | number;

  // Frontend Only
  dependentQuestions?: DependentQuestion[];
  visibility: QuestionVisibility;
}

// Generic Question Interface
export interface IQuestion<TValue, TOption, TQType extends QuestionType>
  extends IBaseQuestion {
  questionType: TQType;
  questionValue: TValue;
  error: AutomationError | null;
  listOfOptions?: TOption[];
  conditionGroups?: Group[] | ActionGroup[];
}

// Base Option Interface for recursive options
export interface IBaseListOption {
  id: string;
  isNew?: boolean;
  error?: boolean;
}

// Specific List Option Interfaces
export interface ISimpleListOption extends IBaseListOption {
  value: string;
}

// Specific Question Types using Generics
// export type ITextQuestion = IQuestion<string, never, QuestionType.Text>;
export type ICheckBoxQuestion = IQuestion<
  string[],
  ICheckListOption,
  QuestionType.Checkbox
>;
export type IRadioQuestion = IQuestion<
  string,
  ICheckListOption,
  QuestionType.Radio
>;
export type IConfigOnlyQuestion = IQuestion<
  never,
  never,
  QuestionType.ConfigOnly
>;
export type IListQuestion = IQuestion<
  string,
  ISimpleListOption,
  QuestionType.List
>;
export type ITextBoxQuestion = IQuestion<string, never, QuestionType.TextBox>;
export type IConditionGroupQuestion = IQuestion<
  Group[],
  never,
  QuestionType.ConditionGroup
>;
export type IActionQuestion = IQuestion<
  ActionGroup[],
  never,
  QuestionType.Action
>;
export type IAutomationSummaryQuestion = IQuestion<
  never,
  never,
  QuestionType.AutomationSummary
>;
export interface IMultiSelectListQuestion
  extends IQuestion<string[], ISimpleListOption, QuestionType.MultiList> {
  maximumLimit: number;
}
interface BugChannelQuestionValue {
  id: string | null;
  url: string;
  optionKey: string;
}
interface BugChannelOptionValue {
  optionKey: string;
  optionLabel: string;
}
export type IBugChannelQuestion = IQuestion<
  BugChannelQuestionValue[],
  BugChannelOptionValue,
  QuestionType.BugChannel
>;
export interface BugCategoryQuestionValue {
  id: string | null;
  name: string;
  description: string | null;
  isDefault: boolean;
}
export type IBugCategoriesQuestion = IQuestion<
  BugCategoryQuestionValue[],
  ISimpleListOption,
  QuestionType.BugCategories
>;

// Union Type for List Questions

// Union Type for All Questions
export type Question =
  | IListQuestion
  | IMultiSelectListQuestion
  | ICheckBoxQuestion
  // | ITextQuestion
  | IRadioQuestion
  | IConfigOnlyQuestion
  | IBugChannelQuestion
  | IBugCategoriesQuestion
  | ITextBoxQuestion
  | IConditionGroupQuestion
  | IActionQuestion
  | IAutomationSummaryQuestion;

/**
 * Options for checkboxes
 */
export interface ICheckListOption {
  optionKey: string;
  optionLabel: string;
  isDisabled?: boolean;
  isHidden?: boolean;
}

// Enum for Step Status
export enum StepStatus {
  Completed = "completed",
  Pending = "pending",
}

export type LogicalOperatorTypes = "or" | "and";

export interface CompareDetail {
  compareType: CompareTypes;
  compareName: string;
  compareKey?: string;
  fieldDataType: FieldDataTypes;
  suffix?: string;
}

export interface ConditionI {
  conditionId: string | null;
  conditionUUID: string;
  nextConditionUUID: string | null;
  fieldKey: string;
  /**
   * This is not required in payload.
   */
  fieldDataType?: FieldDataTypes;
  /**
   * This is the initial dropdown selection to see what kind of value needs to go.
   * While being sent with payload is should never be null.
   */
  compareType: CompareTypes | null;
  conditionType: string | null;
  /**
   * compareKey is given for the `compareType`. Future implementation.
   */
  compareKey?: string;
  /**
   * Format of string - "key(1)::key(2)::key(n)::lastKey" - If n=0 then only "lastKey"
   * If multiple dropdowns levels are there.
   */
  value:
    | Array<string | number>
    | string
    | number
    | null
    | BetweenRange
    | HoursDays;
  isSpecificTime?: boolean;
  /**
   * Should not be present in payload. Added just for checking validations.
   */
  valueIndex?: Array<1>;
  /**
   * This is not required in payload. It is only for response of getSegmentById API.
   * That too only for the select or multi select dropdown.
   *
   * Example - {
   *    "key1::key2::key3::lastKey": ["forkey1", "forkey2", "forkey3", "forlastKey"],
   *    "key1::key2::key3::lastKey2": ["forkey1", "forkey2", "forkey3", "forlastKey2"]
   *  }
   */
  valuesOfSelectKeys?: Record<string, Array<string>>;
}

export interface Group {
  groupId: string | null;
  groupUUID: string;
  nextGroupUUID: string | null;
  logicalOperator: LogicalOperatorTypes | null;
  conditionsLogicalOperator: LogicalOperatorTypes | null;
  conditions: ConditionI[];
  status: StepStatus;
}
export interface ActionGroup {
  groupId: string | null;
  groupUUID: string;
  nextGroupUUID: string | null;
  logicalOperator: LogicalOperatorTypes | null;
  conditionsLogicalOperator: LogicalOperatorTypes | null;
  conditions: IActionForModal[];
  status: StepStatus;
}
export interface IActionForModal {
  conditonId: string | null;
  conditionUUID: string;
  nextConditionUUID: string | null;
  fieldKey: string | null;
  /**
   * This is not required in payload.
   */
  fieldDataType?: FieldDataTypes;
  value:
    | Array<string | number>
    | string
    | number
    | null
    | BetweenRange
    | HoursDays;
  /**
   * Should not be present in payload. Added just for checking validations.
   */
  valueIndex?: Array<1>;
  /**
   * This is not required in payload. It is only for response of getSegmentById API.
   * That too only for the select or multi select dropdown.
   *
   * Example - {
   *    "key1::key2::key3::lastKey": ["forkey1", "forkey2", "forkey3", "forlastKey"],
   *    "key1::key2::key3::lastKey2": ["forkey1", "forkey2", "forkey3", "forlastKey2"]
   *  }
   */
  valuesOfSelectKeys?: Record<string, Array<string>>;
}
export interface Actions {
  actions: IActionForModal[];
  status: StepStatus;
}

/**
 * Steps to contain questions
 */
export interface IStepData {
  stepId: string;
  stepTitle: string;
  stepSubTitle: string;
  questionKeys: string[];
  isVisible: boolean;
  stepStatus: StepStatus;
}

export interface AutomationError {
  isError: boolean;
  stepIdx?: number;
  questionIdx?: number;
  optionIdx?: number;
  errorMessage?: string;
}

/**
 * All data related to automation
 */
export interface IAutomationData {
  integrationId: string;
  automationName: string;
  brand: {
    id: number | string;
    name: string;
    email: string;
    imgURL?: string | null;
  } | null;
  brandId: string;
  published: boolean;
  configured: boolean;
  hasKnowledgeBaseArticles: boolean;
  knowledgeBaseId?: string | null;
  stepsData: Record<string, IStepData>;
  stepIds: string[];
  questionsData: Record<string, Question>;
  questionIds: string[];
  /** Frontend Only */
  moveToNextQuestionKey: string | null;
  showGoLive: boolean | null;
}

interface IAutomationResponseData {
  integrationId: string;
  automationName: string;
  brand: {
    id: number | string;
    name: string;
    email: string;
    imgURL?: string | null;
  } | null;
  brandId: string;
  hasKnowledgeBaseArticles: boolean;
  knowledgeBaseId?: string | null;
  published: boolean;
  configured: boolean;
  stepData: {
    stepId: string;
    stepTitle: string;
    stepSubTitle: string;
    questions: Question[];
    stepStatus: StepStatus;
  }[];
}

export const getAutomationService = async ({
  type,
  payload,
}: AutomationAPIPayload<GetBugConfigParams>): Promise<IAutomationData> => {
  const apiEndPoint = IS_TEST_ACTIVE
    ? `${process.env.REACT_APP_TEST_SITE_URL}/${type}/get`
    : `/api/automation/${type}/getById`;

  const { data: res } = await axiosJSON.post(apiEndPoint, payload);

  if (res.err || res.error) {
    throw res.msg || res.message || "Cannot get the automation data";
  }

  const data = res.data as IAutomationResponseData;

  return updateQuestionsData(data);
};

const updateQuestionsData = (
  data: IAutomationResponseData,
): IAutomationData => {
  const ret: IAutomationData = {
    automationName: data.automationName,
    brand: data.brand,
    brandId: data.brandId,
    configured: data.configured,
    integrationId: data.integrationId,
    hasKnowledgeBaseArticles: data.hasKnowledgeBaseArticles,
    knowledgeBaseId: data.knowledgeBaseId,
    published: data.published,
    moveToNextQuestionKey: null,
    questionIds: [],
    questionsData: {},
    stepIds: [],
    stepsData: {},
    showGoLive: null,
  };

  let hasEncounteredPendingStep = false; // Flag to check if we encountered a pending step
  let allStepsCompleted: boolean = true;

  data.stepData.forEach((step, idx) => {
    const stepIdStr = step.stepId + "";
    ret.stepIds.push(stepIdStr);

    // Determine visibility based on step status and whether we've encountered a pending step
    let isVisible: boolean;

    if (idx === 0) {
      isVisible = true; // The first step is always visible
      hasEncounteredPendingStep = step.stepStatus === StepStatus.Pending;
    } else if (hasEncounteredPendingStep) {
      isVisible = false; // Subsequent steps after the first pending step are hidden
    } else if (step.stepStatus === StepStatus.Pending) {
      isVisible = true; // The first pending step is visible
      hasEncounteredPendingStep = true; // Mark that we've encountered the first pending step
    } else {
      isVisible = step.stepStatus === StepStatus.Completed; // Completed steps are visible until we encounter a pending step
    }

    const newStep: IStepData = {
      stepId: step.stepId,
      stepTitle: step.stepTitle,
      stepSubTitle: step.stepSubTitle,
      questionKeys: [],
      isVisible,
      stepStatus: step.stepStatus,
    };

    let hasEncounteredPendingQuestion = false; // Flag to track if a pending question has been encountered

    step.questions.forEach((question, questionIdx) => {
      const questionKeyStr = question.questionKey + "";
      newStep.questionKeys.push(questionKeyStr);
      ret.questionsData[questionKeyStr] = question;
      ret.questionIds.push(questionKeyStr);

      // Determine the visibility of the question
      let questionVisibility: QuestionVisibility;

      if (step.stepStatus === StepStatus.Completed) {
        // If the step is completed, questions are Show or RelativeHidden
        questionVisibility = shouldQuestionBeRelativeHidden(
          ret.questionsData,
          question,
        )
          ? QuestionVisibility.RelativeHidden
          : QuestionVisibility.Show;
      } else if (step.stepStatus === StepStatus.Pending) {
        // For pending steps, apply the following logic
        if (!hasEncounteredPendingQuestion && questionIdx === 0) {
          // First question in a pending step
          questionVisibility = shouldQuestionBeRelativeHidden(
            ret.questionsData,
            question,
          )
            ? QuestionVisibility.RelativeHidden
            : QuestionVisibility.Show;
          hasEncounteredPendingQuestion = !question.isAnswered; // Mark as pending
        } else if (!hasEncounteredPendingQuestion) {
          // Questions up to the first pending question
          questionVisibility = shouldQuestionBeRelativeHidden(
            ret.questionsData,
            question,
          )
            ? QuestionVisibility.RelativeHidden
            : QuestionVisibility.Show;
          hasEncounteredPendingQuestion = !question.isAnswered; // Mark as pending
        } else {
          // Subsequent questions after the first pending question
          questionVisibility = shouldQuestionBeRelativeHidden(
            ret.questionsData,
            question,
          )
            ? QuestionVisibility.RelativeHidden
            : QuestionVisibility.Hidden;
        }
      } else {
        // NOT USED CURRENTLY
        // Default visibility for any other scenario
        questionVisibility = QuestionVisibility.Hidden;
      }

      // Set the visibility in the question
      ret.questionsData[questionKeyStr].visibility = questionVisibility;

      // Initialize dependent questions based on relatedQuestion
      if (question.relatedQuestion) {
        const relatedQuestionKey = question.relatedQuestion.relatedQuestionKey;
        if (ret.questionsData[relatedQuestionKey]) {
          if (!ret.questionsData[relatedQuestionKey].dependentQuestions) {
            ret.questionsData[relatedQuestionKey].dependentQuestions = [];
          }

          ret.questionsData[relatedQuestionKey].dependentQuestions?.push({
            questionKey: question.questionKey,
            options: question.relatedQuestion.allowedOptions,
          });
        }
      }
    });

    ret.stepsData[stepIdStr] = newStep;
    if (newStep.stepStatus === StepStatus.Pending) {
      allStepsCompleted = false;
    }
  });

  ret.moveToNextQuestionKey = findMoveToNextButtonPosition(
    ret.stepsData,
    ret.questionsData,
  );

  if (allStepsCompleted) {
    ret.showGoLive = true;
  }

  return ret;
};
