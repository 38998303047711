import { axiosJSON } from "src/globals/axiosEndPoints";

export interface SendingFormat {
  id: string;
  name: string;
}

export interface SendingFormatData {
  sendingFormats: { [id: string]: SendingFormat };
  sendingFormatIds: Array<string>;
}

const getSendingFormatService = async () => {
  const { data: res } = await axiosJSON.post(
    `/api/reports/getSendingFormat`
  );

  if (res.err || res.error) {
    throw res.msg ?? res.message ?? "Something went wrong!";
  }

  const ret: SendingFormatData = {
    sendingFormats: {},
    sendingFormatIds: [],
  };
  res.data.forEach((value: SendingFormat) => {
    const id = `${value.id}`;
    ret.sendingFormatIds.push(id);
    value.id = id;
    ret.sendingFormats[id] = value;
  });

  return ret;
};

export default getSendingFormatService;
