/**
 * Calculate pagination parameters based on the current page, total pages, and other factors.
 * @param currentPage - The current page number.
 * @param limit - The maximum number of items per page.
 * @param totalPages - The total number of pages.
 * @param cachePageOffset - The offset for caching pages (default: 2).
 * @returns Object containing startPage, endPage, start index, and limit for pagination.
 */
export function paginationLogic(
  currentPage: number,
  limit: number,
  totalPages: number,
  cachePageOffset: number = 2,
) {
  if (totalPages === 0) {
    // If there are no total pages, assume there are no items to paginate
    return {
      startPage: 1, // Always start from page 1
      endPage: 1 + cachePageOffset, // End page extends with cache offset
      start: 0, // Start index is 0 when no items are available
      limit: limit * (1 + cachePageOffset), // Limit determined by cache offset
    };
  } else {
    // If total pages are provided

    // Ensure current page is within bounds
    if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    // Calculate start page ensuring it's within bounds
    const startPage =
      currentPage - cachePageOffset > 0 ? currentPage - cachePageOffset : 1;

    // Calculate end page ensuring it's within bounds
    const endPage =
      currentPage + cachePageOffset < totalPages
        ? currentPage + cachePageOffset
        : totalPages;

    // Calculate the actual limit considering the range of pages to cache
    const limitTemp = limit * (endPage + 1 - startPage);

    // Return pagination info
    return {
      startPage, // Start page of the visible pagination range
      endPage, // End page of the visible pagination range
      start: (startPage - 1) * limit, // Starting index of items to display
      limit: limitTemp, // Actual limit considering the pagination range
    };
  }
}
export function pageSlice(data: Array<any>, limit: number, index: number) {
  return data.slice(index * limit, index * limit + limit);
}
export function calculateTotalPages(totalCount: number, limit: number) {
  return Math.floor(totalCount / limit) + (totalCount % limit > 0 ? 1 : 0);
}
