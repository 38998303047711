import { ArticleCategoryData } from "./articleCategory.slice";

export const fetchAllArticleCategoryFulfilled = (
  state: ArticleCategoryData,
  { payload }: any
) => {
  const {
    categoryData,
    categoryIdList,
    count,
    totalCount,
    goalTypeOptions
  }: any = payload as any;

  state.categoryData = {
    ...state.categoryData,
    ...categoryData
  };
  console.log("fetchAllArticleCategoryFulfilled ::: articleCategoryIdList:: ", categoryIdList);
  state.categoryIdList = Array.from(new Set([
    ...state.categoryIdList,
    ...categoryIdList
  ]));
  state.metaData.count = count;
  state.metaData.total = totalCount
  state.fetchArticleCategoryAjaxStatus = "fulfilled";
}

export default { fetchAllArticleCategoryFulfilled };