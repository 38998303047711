import { getPostChatFormData } from "src/services/LiveChat/Settings/postChatForm/getPostChatFormData";
import { getFormElementOptions } from "src/services/LiveChat/Settings/preChatForm/getPreChatFormData";
import { RootState } from "src/store/store";

const fetchPostChatFormDataThunk = async (
  payload: number|string,
  { getState, dispatch }: { getState: Function; dispatch: Function }
) => {
  const currentState: RootState = getState();

  let formElementOptions = currentState.postChatFormSetting.formElementOptions;
  if (formElementOptions.formElementOptionIds.length === 0) {
    formElementOptions = await getFormElementOptions({formType:"postChatForm"});
  }
  const postChatFormData = await getPostChatFormData({
    integrationId: payload,
  });

  return {postChatFormData ,formElementOptions };
};

export default { fetchPostChatFormDataThunk};
