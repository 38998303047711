import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IFeaturedArticlesData } from "src/services/LiveChat/Settings/knowledgeBased/featuredArticles/getAllFeaturedArticles";
import { resetActiveFeaturedArticleData } from "src/store/slices/liveChatSetting/chatWidgetSetting/knowledgeBased/knowledgeBasedSetting.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import useFetchFeaturedArticles from "../CustomHooks/useFetchFeaturedArticles";

function useArticleFeatures() {
  const [showModal, setShowModal] = useState(false);
  const [showEnabledLoader, setEnabledLoader] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { integrationId } = useParams();
  const params = useParams();
  const pageIntegrationId = integrationId
    ? decodeURIComponent(integrationId)
    : "";
  const handleBackbtn = () => {
    navigate(
      "/live-chat/settings/" + pageIntegrationId + "/Knowledge/articles"
    );
  };

  const {
    featuredArticles,
    featuredArticlesIds,
    handleInfiniteScroll,
    hasMoreFeaturedArticles,
    initialFetchLoading,
    handleEnableMinimizedWindow,
    minimizedViewEnabled,
  } = useFetchFeaturedArticles({ integrationId });
  const [minimizedFeaturedArticles, setMinimizedFeaturedArticles] = useState<
    IFeaturedArticlesData["featuredArticles"] | undefined
  >();
  const [suggestedArticles, setSuggestedArticles] = useState<Array<string>>([]);
  const { knowledgeBasedData } = useAppSelector(
    (state) => state.knowledgeBasedSettings
  );
  //05 Dec 2023 Added state to store active article id
  const [activeFeaturedArticle, setActiveFeaturedArticle] = useState<
    IFeaturedArticlesData["id"] | undefined
  >();
  const onShow = (e: any) => {
    e.stopPropagation();
    setShowModal(true);
  };
  const onHide = () => {
    setShowModal(false);
    dispatch(resetActiveFeaturedArticleData());
  };

  const handleSelectedFeaturedArticle = useCallback(
    (featuredArticle: IFeaturedArticlesData) => {
      setActiveFeaturedArticle(featuredArticle?.id); //set active article id
      const articles = Object.values(featuredArticle.featuredArticles).map(
        (article) => {
          return article.articleName;
        }
      );
      setMinimizedFeaturedArticles(featuredArticle?.featuredArticles);
      setActiveFeaturedArticle(featuredArticle?.id); //set active article id
      setSuggestedArticles(articles);
    },
    []
  );

  // useEffect hook to handle the active selection of a featured article
  useEffect(() => {
    // Check if the featuredArticles object is available
    if (featuredArticles) {
      // Get the featured article based on the activeFeaturedArticle index or default to index 0
      let featuredArticle;
      if (activeFeaturedArticle) {
        featuredArticle = featuredArticles[activeFeaturedArticle];
      } else {
        featuredArticle = Object.values(featuredArticles)[0];
      }

      // Check if a featured article is found
      if (featuredArticle) {
        // Call the handleSelectedFeaturedArticle function with the selected featured article
        handleSelectedFeaturedArticle(featuredArticle);
      }
    }
  }, [featuredArticles, activeFeaturedArticle]);

  return {
    showModal,
    showEnabledLoader,
    setEnabledLoader,
    handleBackbtn,
    featuredArticles,
    featuredArticlesIds,
    handleInfiniteScroll,
    hasMoreFeaturedArticles,
    initialFetchLoading,
    handleEnableMinimizedWindow,
    minimizedViewEnabled,
    handleSelectedFeaturedArticle,
    minimizedFeaturedArticles,
    suggestedArticles,
    onShow,
    onHide,
    params,
    knowledgeBasedData,
    activeFeaturedArticle,
  };
}

export default useArticleFeatures;
