import styles from "./ImgUpload.module.scss";
import { FileDrop } from "react-file-drop";
import AxiosImg from "src/components/AxiosImg";
import { useRef, useState } from "react";
import {v4 as uuidV4} from "uuid";
import { AttachmentData } from "../../../../HelpCenterStyle";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";


interface Props{
  attachmentData?: AttachmentData;
  setAttachmentData: any;
  stateType: string;
}

const ImgUpload = ({attachmentData, setAttachmentData, stateType}:Props) =>{

  const imgRef = useRef<any>();
  const [dragClass, setDragClass] = useState("");
  
  const filePicker = () => {
    if(imgRef !== null){
      imgRef.current.click();
    }
  };
  
  const ALLOWED_FILE_TYPES = ".png, .jpg, .jpeg";
  const ATTACHEMENT_SUPPORTED_IMG_TYPES = ["png", "jpg", "jpeg"];
  const isFileImgSupported = (type: string) => {
    return ATTACHEMENT_SUPPORTED_IMG_TYPES.includes(type.split("/")[1].toLowerCase());
  };
  
  const handleFileRemove = () =>{
    console.log("deleted attachment");
    if(attachmentData?.isFileExist === true){
      let attachment = {
        ...attachmentData
      };
      attachment.attachmentFile = null;
      attachment.isFileExist = false;
      attachment.isDeleted = true;
      setAttachmentData(attachment);
    }
    
  }
  
  const onFileInputChange =  (files: FileList | null, e: any ) => {
console.log(files);
    if(files){
  
      const uploadableFiles = Object.values(files)
      .filter((file) => isFileImgSupported(file.type))
      
      if(uploadableFiles.length !==0){
  
        let file = uploadableFiles[0];
  
        let attachmentData: AttachmentData = {
          attachmentId: 0,
          attachmentName: file.name,
          attachmentUrl: URL.createObjectURL(file),
          isUploaded: false,
          attachmentFile: file,
          isAxiosLink: false,
          isFileExist:true,
          attachmentType: stateType,
          isDeleted: false
        };

        setAttachmentData(attachmentData);
        
      }else{
        //invalid file type
        pushTheToast({
          type: "warning",
          text: "only images supported!",
          position: "top-right"
      });
      }
    }
  
  }



    return (
        <>
          {
            (attachmentData === undefined || attachmentData.attachmentUrl === "" || attachmentData.isDeleted === true) ? (

            <FileDrop
                onTargetClick={filePicker}
                onDrop={(files,e)=>{e.preventDefault();onFileInputChange(files, e)}}
                
                onFrameDragEnter={(e) => {
                    setDragClass(styles.dragging);
                }}
                onFrameDragLeave={(e) => {
                    setDragClass("");
                }}
                onDragOver={(e) => {
                    setDragClass(styles.dragged);
                }}
                onDragLeave={(e) => {
                    setDragClass(styles.dragging);
                }}
                onFrameDrop={(e) => {
                    setDragClass("");
                }}
                className={`${styles.dnD} ${dragClass}`}
              >
               <input
                  accept={ALLOWED_FILE_TYPES}
                  value=""
                  style={{ display: "none", opacity: 0 }}
                  ref={imgRef}
                  type="file"
                  onChange={(e) => onFileInputChange(e.target.files,e)}
                />
                <span className={`${styles.dndSpan}`}>Drag and drop the image or click to upload</span>
            </FileDrop>
            ):
            <>
          <div className={`${styles.dnD}`}>

            {attachmentData.isAxiosLink === true ? (
              <AxiosImg
                url={attachmentData.attachmentUrl}
                className={`${styles.showImg}`}
              />
            ) : (
              <img
                className={`${styles.showImg}`}
                src={attachmentData.attachmentUrl}
              />
            )
            }
            <span
              className={`${styles.closeNProgress} ${styles.CloseBadgeBg}`}
              onClick={
                // isUploading === false ? (e) => removeAttachment(e) : undefined
                (e) =>handleFileRemove()
              }
            >
                <i className={`fa-solid fa-times ${styles.clossIcon}`}></i>
            </span>
            </div>
            </>
            }

          </>
    )
}

export default ImgUpload;