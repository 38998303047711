// CustomRangeSlider.tsx

import React, { useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import styles from "./CustomRangeSlider.module.scss";

interface CustomRangeSliderProps {
  min: number;
  max: number;
  step?: number;
  value: number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const CustomRangeSlider: React.FC<CustomRangeSliderProps> = ({
  min,
  max,
  step,
  value,
  onChange,
}) => {
  const rangeRef = useRef<HTMLInputElement>(null);
  const calculateBackground = () => {
    if (min > max) {
      console.error("Error: 'min' value is greater than 'max' value.");
      return `linear-gradient(to right, #A3A3A3 100%, #A3A3A3 100%)`; // Default background
    }

    const percentage = ((value - min) / (max - min)) * 100;
    return `linear-gradient(to right, #0B68BB ${percentage}%, #A3A3A3 ${percentage}%)`;
  };

  return (
    <Form.Group className={`${styles.customRangeSlider}`}>
      <Form.Range
        ref={rangeRef}
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={onChange}
        className={`${styles.rangeSlider}`}
        style={{ background: calculateBackground() }}
      />
    </Form.Group>
  );
};

export default CustomRangeSlider;
