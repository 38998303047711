import styles from "./OrderDetails.module.scss";
import OrderDetailsAction from "./children/OrderDetailsAction/OrderDetailsAction";
import AllOrderHeader from "./children/AllOrderHeader/AllOrderHeader";
import Timeline from "./children/Timeline/Timeline";
import Paid from "./children/Paid/Paid";
import Unfulfilled from "./children/Unfulfilled/Unfulfilled";
import Notes from "./children/Notes/Notes";
import Customer from "./children/Customer/Customer";
import ConversionSummary from "./children/ConversionSummary/ConversionSummary";
import FraudAnalysis from "./children/FraudAnalysis/FraudAnalysis";
import Tags from "./children/Tags/Tags";

import { useAppDispatch, useAppSelector } from "src/store/store";
import { useContext, useEffect } from "react";
import {
  fetchGetFulfillmentOrder,
  fetchGetOrderViewModel,
} from "src/store/slices/singleOrder/order.thunks";
import Loader from "src/components/Loader";
import { ModalContext } from "../Modal/ModalContext";
import { CloseButton } from "react-bootstrap";

function OrderDetails({ orderId }: { orderId: number | string }) {
  const dispatch = useAppDispatch();
  const { loading, error, getOrderViewModel } = useAppSelector(
    (state) => state.singleOrder
  );
  const modalContext = useContext(ModalContext);
  useEffect(() => {
    dispatch(fetchGetOrderViewModel({ orderId: parseInt(orderId + "") }));
  }, [orderId]);

  useEffect(() => {
    modalContext.setClassNames({ modalContent: styles.modalContent });
    modalContext.setClassNames({ modalDialog: styles.modalDialog });

    return () => {};
  }, []);
  return (
    <div className={`${styles.OrderDetailsBody}`}>
      {error ? (
        <div className={`text-center m-auto`}>Something went wrong!</div>
      ) : loading ? (
        <Loader />
      ) : (
        <>
          <div className="d-flex justify-content-between">
            <AllOrderHeader />
            {/* <CloseButton
              className={`${styles.closeButton}`}
              onClick={() => modalContext.handleModal?.call(null, undefined)}
            /> */}
            <span  className={`${styles.closeButton}`}
              onClick={() => modalContext.handleModal?.call(null, undefined)}>
              <i className="fa-solid fa-xmark"></i>
            </span>
          </div>
          <div>
            <OrderDetailsAction />
          </div>
          <div className={`row py-1 ${styles.mainContentAndSideBar} `}>
            <div className={`col-12 col-lg-9`}>
              <Unfulfilled />
              <Paid />
              <Timeline />
            </div>
            <div className={`col-12 col-lg-3`}>
              <Notes />
              <Customer />
              {/* <ConversionSummary /> */}
              {/* <FraudAnalysis /> */}
              {/* <Tags /> */}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
export default OrderDetails;
