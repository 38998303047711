import { Channel } from "pusher-js";

export type MessageReadEventCB = (res: any) => void;

export const getMessageReadEvent = (
  channel: Channel,
  callback: MessageReadEventCB
) => {
  channel.bind("message_read", callback);
};

export const getMessageReadEventUnbind = (
  channel: Channel,
) => {
  channel.unbind("message_read");
};
