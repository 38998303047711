import { Modal } from "react-bootstrap";

import AssignedViewModel from "src/routes/LiveChat/Children/MainChat/Children/ChatTabs/Children/AssignedViewModel/AssignedViewModel";
import { actions } from "src/store/slices/liveChatSetting/chatWidExchCust/chatWidExchCust.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import styles from "./LiveChatAssignedNotification.module.scss";

function LiveChatAssignedNotification() {
  const { chatAssignedNotification } = useAppSelector(
    (state) => state.chatWidExchCust
  );

  const dispatch = useAppDispatch();
  return (
    <>
    <Modal
      className={`shadow-sm p-3 rounded h-auto`}
      show={chatAssignedNotification.showModal}
      onHide={() => {
        dispatch(actions.resetChatAssignedNotification());
      }}
      dialogClassName={`${styles.modalDialog2}`}
      contentClassName={`${styles.modalContent2}`}
      backdropClassName={`${styles.modalBackDrop}`}
      centered={true}
      enforceFocus={false}
    >
      <AssignedViewModel />
    </Modal>
    </>
  );
}

export default LiveChatAssignedNotification;
