import { useQuery } from "@tanstack/react-query";

import {
  getAllVariables,
  IGetAllVariables,
  IGetAllVariablesParams,
} from "src/services/UiAutomation/ReturnExchange/Configuration/Variables/getAllVariables";
import { VARIABLES_QUERY_KEY } from "./useGetVariables";

const useSearchVariables = (params: IGetAllVariablesParams) => {
  const { data, isLoading } = useQuery({
    queryKey: [...VARIABLES_QUERY_KEY, params],
    queryFn: async () => {
      if (params.searchTerm) {
        return getAllVariables(params);
      } else {
        return {} as IGetAllVariables;
      }
    },
  });

  return {
    variables: data?.variables,
    variablesIds: data?.variablesIds,
    isLoading,
  };
};

export default useSearchVariables;
