import {
  ILiveChatTabQuickActionsState,
  initialState,
} from "./liveChatTabQuickActions.slice";

// reset live chat tab quick actions
const resetLiveChatTabQuickActions = (state: ILiveChatTabQuickActionsState) => {
  state = { ...initialState };
  return state;
};

const resetLiveChatTabQuickActionCreateTicket =  (state: ILiveChatTabQuickActionsState) => {
  state.createTicketFromLiveChatAjaxStatus = "idle";
  state.createdTicketId = "";
  state.fetchLiveChatThreadAjaxStatus = "pending";
  state.threadText = "";

  return state;
};

export default {
  resetLiveChatTabQuickActions,
  resetLiveChatTabQuickActionCreateTicket
};
