import React, { useState } from "react";
import styles from "./DeleteSchedule.module.scss";
import { Spinner } from "react-bootstrap";

type Props = {
  id: number;
  onHide: () => void;
  deleteSchedule: (id: number) => Promise<void>;
};

const DeleteSchedule = ({ onHide, deleteSchedule, id }: Props) => {
  const [loader, setLoader] = useState(false);
  function handleNo() {
    onHide();
  }

  function handleYes() {
    setLoader(true);
    setTimeout(() => {
      deleteSchedule(id)
        .then(() => onHide())
        .catch(() => setLoader(false));
    }, 0);
  }

  return (
    <div className={`${styles.deleteWrapper}`}>
      <h4 className={`${styles.header}`}>Delete Message</h4>
      <span className={`${styles.text}`}>
        Are you sure you want to delete this follow-up?
      </span>
      <div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-end align-items-center align-items-md-start">
        <button className={`${styles.cancelBtn}`} onClick={handleNo}>
          No
        </button>
        <button
          className={`ms-0 ms-md-4 mt-2 mt-md-0 ${styles.submitBtn}`}
          onClick={handleYes}
        >
          {loader ? (
            <Spinner
              className="my-auto mx-1"
              animation="border"
              color="white"
              size="sm"
            />
          ) : (
            "Yes"
          )}
        </button>
      </div>
    </div>
  );
};

export default DeleteSchedule;
