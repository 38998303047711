import { Channel } from "pusher-js";
import { useEffect, useRef, useState } from "react";
import {
  getLiveChatTrafficDataUpdationEvent,
  getLiveChatTrafficDataUpdationEventUnbind,
} from "src/pusherServices/livechat/liveChatTrafficDataUpdateEvent";
import {
  ITabTypeCount,
  ITrafficListItem,
} from "src/services/LiveChat/traffic/getTrafficList.service";
import {
  fetchLiveChatTrafficUpdatedData,
  setTrafficData,
  setTrafficTypeTabCount,
} from "src/store/slices/liveChatTraffic/liveChatTraffic.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";

export const useLiveChatTrafficBinder = () => {
  const [privatePusherChannel, setPrivatePusherChannel] = useState(null as any);
  const [globalPusherChannel, setGlobalPusherChannel] = useState(null as any);

  const dispatch = useAppDispatch();

  const {
    trafficItemIdsList,
    trafficItemList,
    trafficTypeTabCount,
    trafficFilterType,
    trafficFetchLimit,
    fetchTrafficDataAjaxStatus,
  } = useAppSelector((state) => state.liveChatTraffic);

  const { currentUserData } = useAppSelector((state) => state.globals);

  const currentState = useRef({
    trafficItemIdsList: trafficItemIdsList,
    trafficItemList: trafficItemList,
    trafficTypeTabCount: trafficTypeTabCount,
    trafficFilterType: trafficFilterType,
    trafficFetchLimit: trafficFetchLimit,
    fetchTrafficDataAjaxStatus: fetchTrafficDataAjaxStatus,
    currentUserData: currentUserData,
  });

  useEffect(() => {
    currentState.current = {
      trafficItemIdsList: trafficItemIdsList,
      trafficItemList: trafficItemList,
      trafficTypeTabCount: trafficTypeTabCount,
      trafficFilterType: trafficFilterType,
      trafficFetchLimit: trafficFetchLimit,
      fetchTrafficDataAjaxStatus: fetchTrafficDataAjaxStatus,
      currentUserData: currentUserData,
    };
  }, [
    trafficItemIdsList,
    trafficItemList,
    trafficTypeTabCount,
    trafficFilterType,
    trafficFetchLimit,
    fetchTrafficDataAjaxStatus,
    currentUserData,
  ]);

  // callbacks for traffic data updation
  const liveChatTrafficDataUpdationEventCallback = (res: any) => {

    if(res?.data?.isDataUpdated && currentState.current?.fetchTrafficDataAjaxStatus !== "pending"){
      // fetch the updated traffic data
      dispatch(fetchLiveChatTrafficUpdatedData());
    }

    /*
    // update the tab count
    if (res?.data?.tabCount && currentState.current?.fetchTrafficDataAjaxStatus !== "pending") {
      let trafficTabCount: ITabTypeCount = {
        all: res?.data?.tabCount?.all ?? 0,
        chatting: res?.data?.tabCount?.chatting ?? 0,
        queued: res?.data?.tabCount?.queued ?? 0,
        waitingForReply: res?.data?.tabCount?.waiting_for_reply ?? 0,
      };
      // update the tab count
      dispatch(setTrafficTypeTabCount({ tabCount: trafficTabCount }));
    }

    if (res?.data?.trafficData && currentState.current?.fetchTrafficDataAjaxStatus !== "pending") {
      let trafficDataForActiveTabType: any =
        res?.data?.trafficData[currentState.current?.trafficFilterType];
      let trafficItemListTemp: { [id: number | string]: ITrafficListItem } = {};
      let trafficItemIdsListTemp: Array<number | string> = [];

      // when there is no traffic data in redux
      if (currentState.current?.trafficItemIdsList.length === 0) {
        // currently there is nothing shown, so set the latest fetchLimit data
        for (
          let i = 0;
          i < currentState.current?.trafficFetchLimit && trafficDataForActiveTabType[i];
          i++
        ) {
          let trafficItem: ITrafficListItem = trafficDataForActiveTabType[i];
          if (trafficItem.action === null) {
            // null is set when the action cannot be identified from backend
            if (
              trafficItem.assignedAgent &&
              trafficItem.assignedAgent.id &&
              trafficItem.assignedAgent.id === currentState.current?.currentUserData?.userId
            ) {
              trafficItem.action = "go_to_chat";
            } else {
              trafficItem.action = "supervise";
            }
          }
          trafficItemListTemp[trafficItem.id] = trafficItem;
          trafficItemIdsListTemp.push(trafficItem.id);
        }
        let metaData: any = {
          count: trafficItemIdsListTemp.length,
          total: trafficDataForActiveTabType.length,
        };
        // set the traffic data
        dispatch(
          setTrafficData({
            trafficItemList: trafficItemListTemp,
            trafficItemIdsList: trafficItemIdsListTemp,
            metaData: metaData,
          })
        );
      } else {
        // there is some data already in redux
        let a: number = Math.floor(currentState.current?.trafficItemIdsList.length / 10);
        let limit: number = (a + 1) * currentState.current?.trafficFetchLimit;
        // console.log(" Else a:: ", a);
        // console.log(" Else limit:: ", limit);
        for (let i = 0; i < limit && trafficDataForActiveTabType[i]; i++) {
          let trafficItem: ITrafficListItem = trafficDataForActiveTabType[i];
          if (trafficItem.action === null) {
            // null is set when the action cannot be identified from backend
            if (
              trafficItem.assignedAgent &&
              trafficItem.assignedAgent.id &&
              trafficItem.assignedAgent.id === currentState.current?.currentUserData?.userId
            ) {
              trafficItem.action = "go_to_chat";
            } else {
              trafficItem.action = "supervise";
            }
          }
          trafficItemListTemp[trafficItem.id] = trafficItem;
          trafficItemIdsListTemp.push(trafficItem.id);
        }
        let metaData: any = {
          count: trafficItemIdsListTemp.length,
          total: trafficDataForActiveTabType.length,
        };
        // set the traffic data
        dispatch(
          setTrafficData({
            trafficItemList: trafficItemListTemp,
            trafficItemIdsList: trafficItemIdsListTemp,
            metaData: metaData,
          })
        );
      }
    }*/
  };

  useEffect(() => {
    if (globalPusherChannel) {
      // globalPusherChannel.unbind("pusher:subscription_succeeded");

      globalPusherChannel.bind("pusher:subscription_succeeded", () => {
        // console.log("binded livechat traffic global events");
        getLiveChatTrafficDataUpdationEvent(
          globalPusherChannel,
          liveChatTrafficDataUpdationEventCallback
        );
      });
    }
  }, [globalPusherChannel]);

  useEffect(() => {
    if (privatePusherChannel) {
    }
  }, [privatePusherChannel]);

  const bindAllTrafficEvents = (
    channel: Channel | null | undefined,
    isGlobal = false
  ) => {
    if (channel) {
      if (isGlobal) {
        setGlobalPusherChannel(channel);
      } else {
        setPrivatePusherChannel(channel);
      }
    }
  };

  return { bindAllTrafficEvents };
};
