/**
 * This is the component file.
 * It contains the NotFrequency component function.
 *
 * @author Yash Aditya
 */

import { NavLink } from "react-router-dom";
import ShowFreqData from "../ShowFreqData/ShowFreqData";
import styles from "./NotFrequency.module.scss";
import closeModal from "src/assets/images/close(3).png";
interface Props {
  onHide: any;
}

// This component is used to show the notification frequency details in a modal.
const NotFrequency = ({ onHide }: Props) => {
  return (
    <div className="p-3">
      <div className="d-flex justify-content-between">
        <h4 className={`${styles.modalHead}`}>Notification Frequency</h4>
        {/* <span
          className={`cursor-pointer ${styles.closeModal}`}
          onClick={onHide}
        >
          <i className="fa-solid fa-xmark"></i>
        </span> */}
        <img
          src={closeModal}
          alt=""
          className={`${styles.closeFredModal} cursor-pointer`}
          onClick={onHide}
        />
      </div>
      <div>
        <p className={`mb-0 ${styles.modalSub}`}>
          Total number of unresolved tickets
        </p>
        <p className={`${styles.redirectLink}`}>
          <span>To change frequency of notifications go to </span>
          <NavLink to={`/settings/notification`} className={`${styles.linkTo}`}>
            notification settings
          </NavLink>
        </p>
        <ShowFreqData />
      </div>
      <div className="d-flex justify-content-end">
        <button className={`mt-4 ${styles.okBtn}`} onClick={onHide}>
          Ok
        </button>
      </div>
    </div>
  );
};

export default NotFrequency;
