import { axiosJSON } from "src/globals/axiosEndPoints";

interface ISearchCategory {
  searchTerm: string;
  start: number;
  limit: number;
}

export interface IGetCategory {
  start: number;
  limit: number;
}

export async function searchCategory(servicePayload: ISearchCategory) {
  const { data } = await axiosJSON.post(
    "/api/template/searchCategory",
    servicePayload
  );

  // console.log(data);

  if (data.error === true) {
    throw new Error("Some Error Occurred while fetching categories");
  }

  return data;
}

export async function getViewTemplateCategories(apiPayload: IGetCategory) {
  const { data } = await axiosJSON.post(
    "/api/template/getCategories",
    apiPayload
  );

  // console.log(data);

  if (data.error === true) {
    throw new Error("Some Error Occurred while fetching categories");
  }

  return data;
}
