import { axiosJSON } from "src/globals/axiosEndPoints";
import APIResponse from "src/services/Settings/Teams/APIReponse";


export interface IUpdatePreChatFormDataParam{

  integrationId: number | string;
  enabled?: boolean;
  elements?: Array<IUpdatePreChatFormElement> ;
}

export interface IUpdatePreChatFormElement{
  elementId : string | number;
  elementKey : string;
  elementValue : string;
  elementOptions : Array<string>;
  isRequired : boolean;
  isNewlyAdded: boolean;
  isDefault?: boolean;
}



export async function updatePreChatFormData(params: IUpdatePreChatFormDataParam) {
  
  const { data: res } = await axiosJSON.post(
    "/api/chatSetting/forms/preChatFormUpdate",
    params
  );

  if (res.error === true) {
    throw new Error(res.message as string);
  }

  return res.data;
}
