import { useQuery } from "@tanstack/react-query";
import { getBugStatus } from "src/services/BugReport/getBugStatus.service";

const useBugStatusList = () => {
  const { data: allBugStatus } = useQuery(["bugStatusList/getBugStatus"], {
    queryFn: () => getBugStatus(),
    staleTime: 60000,
    cacheTime: 60000,
  });

  return{
    allBugStatus
  }
}

export default useBugStatusList