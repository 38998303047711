/**
 * This file contains the custome hook for component.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchAllArticles,
  setNewOrderOfArticles,
} from "src/store/slices/liveChatSetting/chatWidgetSetting/knowledgeBased/knowledgeBasedSetting.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import useArticleLoader from "../Loaders/ArticleLoader";
import styles from "./ArticleFolder.module.scss";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updatePriorities } from "src/services/KnowledgeBase/updatePriorities.service";
import { ArticleData } from "src/services/LiveChat/Settings/knowledgeBased/article/getArticle";

const fetchLimit = 10;

/**
 * This hook maintains the article data in state and uses redux to fetch and store data.
 */
function useArticleFolder({ categoryId }: { categoryId: number | string }) {
  const [showSubFolder, SubFolder] = useState(false);
  const { integrationId } = useParams();
  const pageIntegrationId = integrationId
    ? decodeURIComponent(integrationId)
    : "";

  const navigate = useNavigate();

  // This navigates to the create article page using the category ID.
  const handleAddNewArticle = (categoryId: number | string) => {
    navigate(
      `/knowledge-base/${pageIntegrationId}/articles/new?categoryId=${categoryId}`
    );
  };

  const dispatch = useAppDispatch();

  // Selecting the required article an dcategory data from redux.
  const {
    categoryData,
    categoryIdList,
    fetchCategoryAjaxStatus: fetchArticleCategoryAjaxStatus,
    categoryMetaData: metaData,
  } = useAppSelector((state) => state.knowledgeBasedSettings);

  // Storing data in ref to prevent the stale state.
  const currentState = useRef({
    fetchArticleCategoryAjaxStatus,
    categoryData,
    categoryIdList,
    metaData,
    initialFetch: false,
  });

  // Updting the ref when the data updates.
  useEffect(() => {
    currentState.current = {
      categoryData,
      categoryIdList,
      fetchArticleCategoryAjaxStatus,
      metaData,
      initialFetch: currentState.current.initialFetch,
    };
  }, [categoryData, categoryIdList, fetchArticleCategoryAjaxStatus, metaData]);

  // Used to fetch more data when page scroll to bottom.
  const handleArticleInfiniteScroll = useCallback(() => {
    if (
      integrationId &&
      currentState.current.categoryData[categoryId] !== undefined
    ) {
      if (
        currentState.current.categoryData[categoryId].ajaxStatus !== "pending"
      ) {
        // Dispatching the redux thunk to grab more data.
        dispatch(
          fetchAllArticles({
            integrationId: integrationId,
            categoryId: categoryId,
            start:
              currentState.current.categoryData[categoryId].articlesIds.length,
            limit: fetchLimit,
            articleType: "all",
          })
        );
      }
    }
  }, [integrationId]);

  // Checking if more articles are present in infinite scroll.
  const hasMoreArticle = useMemo(() => {
    return categoryData[categoryId].ajaxStatus === "rejected"
      ? false
      : categoryData[categoryId].metaData.total === null
      ? false
      : categoryData[categoryId].articlesIds.length === 0
      ? false
      : categoryData[categoryId].articlesIds.length <
        categoryData[categoryId].metaData.total;
  }, [categoryId, categoryData]);

  // Checking if need to show loader.
  const articelLoader = useArticleLoader(
    categoryData[categoryId].ajaxStatus === "pending" &&
      categoryData[categoryId].articlesIds.length === 0,
    styles.loading
  );

  const changeOrder = useMutation({
    mutationFn: updatePriorities,
  });

  const queryClient = useQueryClient();

  // This is on drag event use to maintain the position of articles and the c ategories.
  const handleOnDrag = useCallback(
    (result: any) => {
      const { source, destination } = result;
      if (!destination) {
        return;
      }
      if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
      ) {
        return;
      }

      // Logic to change to order.
      const categoryId = source.droppableId;
      const articleIds = categoryData[categoryId]?.articlesIds;
      if (!articleIds || articleIds === null) return;
      const newOrder = Array.from(articleIds);
      newOrder.splice(source.index, 1);
      newOrder.splice(destination.index, 0, articleIds[source.index]);

      // Saving the updated order.
      changeOrder.mutate({
        integrationId: pageIntegrationId,
        updateType: "article",
        ids: newOrder,
        categoryId: categoryId,
      });

      // Dispatching the new order to the rdeux.
      dispatch(
        setNewOrderOfArticles({
          categoryId: categoryId,
          articeIdList: newOrder,
        })
      );
    },
    [categoryData]
  );

  return {
    showSubFolder,
    SubFolder,
    handleAddNewArticle,
    hasMoreArticle,
    handleArticleInfiniteScroll,
    articelLoader,
    handleOnDrag,
  };
}

export default useArticleFolder;
