import ToolTipModule from "../ToolTipModule/ToolTipModule";
import DropDownModule from "../DropDownModule/DropDownModule";
import { ITicketBtnListItem } from "../../SubSB";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { subSBLink } from "src/services/TicketService/linkGenerator";
import {
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Overlay, OverlayTrigger, Popover } from "react-bootstrap";
import DeleteViewModal from "src/components/DeleteViewModal/DeleteViewModal";

interface ITicketBtnDropDown extends ITicketBtnListItem {
  keyIndex: string | number;
  selectedBtn: string;
}

function TicketBtnDropDown({
  keyIndex,
  ticketBtnName,
  ticketBtnCount,
  ddOuterList,
  selectedBtn,
  listViewFilters,
  keyName,
}: ITicketBtnDropDown) {
  // const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [showDelete, setShowDelete] = useState(false);
  const [showModal, setShowModal] = useState(false);
  // const key = parseInt(
  //   `${keyIndex}`.split(".")[`${keyIndex}`.split(".").length - 1]
  // );
  const DDData = ddOuterList.map((data, key) => {
    return {
      key: data.ddName,
      value: data.ddSelectedIndex
        ?.map((selectedIndex) => data.ddList[selectedIndex].name)
        .join(", "),
    };
  });

  const ticketStatusViewName = useMemo(() => {
    let status = ddOuterList[1]?.ddSelectedIndex
      ?.map((selectedIndex) => ddOuterList[1]?.ddList[selectedIndex].name)
      .join(", ");

    return status;
  }, [ddOuterList[1]?.ddList]);

  const channelViewName = useMemo(() => {
    let status = ddOuterList[0]?.ddSelectedIndex
      ?.map((selectedIndex) => ddOuterList[0]?.ddList[selectedIndex].name)
      .join(", ");

    return status;
  }, [ddOuterList[1]?.ddList]);

  /**
   * Delete View handler for opening delete modal
   */
  const handleDelete: MouseEventHandler<HTMLSpanElement> = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowModal(true);
  }, []);

  /**
   * Close delete modal handler
   */
  const handleCloseDelete = useCallback(
    (isSuccess?: boolean) => {
      // Hide modal
      setShowModal(false);
      // If successfully deleted
      if (isSuccess) {
        // Navigate to my tickets
        navigate(
          subSBLink({
            tableType: "my",
            search: location.search,
            pathName: location.pathname,
          })
        );
      }
    },
    [location.pathname, location.search, navigate]
  );

  return (
    <div className={`ticket-dropdown-content`} id={`ticketContent_${keyIndex}`}>
      {/* Adding overlay to show data on top z index */}
      <OverlayTrigger
        trigger={["hover", "focus"]}
        placement="right"
        onEnter={() => setShowDelete(true)}
        onExit={() => setShowDelete(false)}
        overlay={
          ddOuterList.length > 0 ? (
            <Popover as="div" className="custom-popover-arrow-hide hideBorder">
              <ToolTipModule ticketBtnName={ticketBtnName} DDData={DDData} />
            </Popover>
          ) : listViewFilters && listViewFilters.length > 0 ? (
            <Popover as="div" className="custom-popover-arrow-hide hideBorder">
              <ToolTipModule
                ticketBtnName={ticketBtnName}
                DDData={listViewFilters}
                isFilterData={true}
              />
            </Popover>
          ) : (
            <></>
          )
        }
      >
        <a
          href={subSBLink({
            tableType: keyName,
            search: location.search,
            pathName: location.pathname,
          })}
          className={`d-flex justify-content-between align-items-center alltickets ${
            keyName === selectedBtn ? "show" : ""
          }`}
          onClick={(e) => {
            e.preventDefault();
            navigate(
              subSBLink({
                tableType: keyName,
                search: location.search,
                pathName: location.pathname,
              })
            );
          }}
          id={`link__${keyIndex}`}
        >
          {/* 3 dots on hover */}
          {keyName.includes("viewId") && showDelete && (
            <span className="pb-1 me-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="2"
                height="8"
                viewBox="0 0 2 8"
              >
                <g
                  id="Group_6579"
                  data-name="Group 6579"
                  transform="translate(-5 -11)"
                >
                  <circle
                    id="Ellipse_1039"
                    data-name="Ellipse 1039"
                    cx="1"
                    cy="1"
                    r="1"
                    transform="translate(5 11)"
                    fill="#707070"
                  />
                  <circle
                    id="Ellipse_1040"
                    data-name="Ellipse 1040"
                    cx="1"
                    cy="1"
                    r="1"
                    transform="translate(5 14)"
                    fill="#707070"
                  />
                  <circle
                    id="Ellipse_1041"
                    data-name="Ellipse 1041"
                    cx="1"
                    cy="1"
                    r="1"
                    transform="translate(5 17)"
                    fill="#707070"
                  />
                </g>
              </svg>
            </span>
          )}
          {/* View Name */}
          <span className={`ticket-name text-truncate`}>
            {keyName.includes("viewId")
              ? ticketBtnName
              : `${
                  keyName === "my" && ticketStatusViewName === "All"
                    ? "My"
                    : `${keyName === "my" ? "My " : ""}${ticketStatusViewName}`
                } Tickets ${
                  channelViewName === "All" && ticketStatusViewName === "All"
                    ? ""
                    : `(${channelViewName})`
                }`}
          </span>
          {/* View Count */}
          <span
            className={`d-flex ${
              keyName.includes("viewId") && showDelete
                ? "justify-content-between"
                : "justify-content-end"
            } align-items-center mx-1 view-hover`}
          >
            {/* Delete button on hover */}
            {keyName.includes("viewId") && showDelete && (
              <span className="w-75 pb-1" onClick={handleDelete}>
                <svg
                  id="delete"
                  xmlns="http://www.w3.org/2000/svg"
                  width="9.344"
                  height="12"
                  viewBox="0 0 9.344 12"
                >
                  <path
                    id="Path_3165"
                    data-name="Path 3165"
                    d="M.656,10.656v-8h8v8a1.3,1.3,0,0,1-.391.953A1.261,1.261,0,0,1,7.344,12H2a1.3,1.3,0,0,1-.953-.391A1.3,1.3,0,0,1,.656,10.656Zm8.688-10V2H0V.656H2.344L3,0H6.344L7,.656Z"
                    fill="#707070"
                  />
                </svg>
              </span>
            )}
            {/* View Ticket Count */}
            <span className={`text-end count`}>{ticketBtnCount}</span>
          </span>
        </a>
      </OverlayTrigger>
      {/* Delete View Modal */}
      <DeleteViewModal
        viewId={keyName.split("::")[1]}
        show={showModal}
        onHide={handleCloseDelete}
      />
      {ddOuterList.length > 0 && (
        <div
          className={`bg-color dropdown-menu ${
            keyName === selectedBtn ? "show" : ""
          }`}
        >
          {ddOuterList.map((outerList, key) => {
            return (
              <DropDownModule
                key={key}
                keyIndex={`${keyIndex}.${key}`}
                ddName={outerList.ddName}
                ddList={outerList.ddList}
                ddSelectedIndex={outerList.ddSelectedIndex}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}

export default TicketBtnDropDown;
