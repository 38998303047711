import { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Modal, Nav, Row, Tab } from "react-bootstrap";
import AddConditionButton from "src/components/AutomationComponents/ContentSection/AddConditionButton";
import Condition from "src/components/AutomationComponents/ContentSection/Condition";
import ContentHeading from "src/components/AutomationComponents/ContentSection/ContentHeading";
import OperatorSelect from "src/components/AutomationComponents/ContentSection/OperatorSelect";
import Values from "src/components/AutomationComponents/ContentSection/Values";
import VariableSelect from "src/components/AutomationComponents/ContentSection/VariableSelect";
import DiscardAndSave from "src/components/AutomationComponents/DiscardAndSave";
import DiscardModal from "src/components/AutomationComponents/DiscardModal/DiscardModal";
import FollowUpQuestion from "src/components/AutomationComponents/IndividualComponents/FollowUpQuestion/FollowUpQuestion";
import SkeletonModalStructure from "src/components/AutomationComponents/SkeletonModalStructure/SkeletonModalStructure";
import AddMore from "src/components/AutomationComponents/TabSection/AddMore";
import AutomationTab from "src/components/AutomationComponents/TabSection/AutomationTab";
import CloseButtonForTab from "src/components/AutomationComponents/TabSection/CloseButtonForTab";
import ConditionGroup from "src/components/AutomationComponents/TabSection/ConditionGroup";
import TabError from "src/components/AutomationComponents/TabSection/TabError";
import TabName from "src/components/AutomationComponents/TabSection/TabName";
import Loader from "src/components/Loader";
import { useReturnAutoWorkFlow } from "src/features/ReturnAutoWorkFlow/hooks/useReturnAutoWorkFlow";
import AutoWorkFlow from "src/routes/AutoWorkFlow/AutoWorkFlow";
import ReturnTypeBox from "src/routes/Setting/children/MainSetting/children/SetRulesNew/childerns/childern/ConfigurationModels/ConfigureEligibilityStep4/Childern/ReturnOption/ReturnTypeBox/ReturnTypeBox";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  deleteReturnMethod,
  setSelectedReturnMethodId,
  undoConfig,
  validateChanges,
} from "../../store/slices/ReturnMethodSlice/returnMethod.slice";
import { fetchReturnMethodsThunk } from "../../store/slices/ReturnMethodSlice/returnMethod.thunk";
import styles from "./ReturnMethod.module.scss";
import useReturnMethodModal from "./useReturnMethodModal";

const ReturnMethodComponent = ({ onSave }: { onSave: () => void }) => {
  const dispatch = useAppDispatch();
  const { returnAutoWorkFlow } = useReturnAutoWorkFlow();

  // const stepQuestions = useAppSelector(
  //   (state) => state.returnExchange.step12.stepData.stepQuestions,
  // );

  // const showSelectReturn = useMemo(() => {
  //   return (
  //     stepQuestions[EReturnAutomationQuestionKeys.returnReasonList]
  //       .showQuestion === true
  //   );
  // }, [stepQuestions]);

  // TODO
  const showSelectReturn = useMemo(() => {
    if (returnAutoWorkFlow) {
    }
    return false;
  }, [returnAutoWorkFlow]);

  const {
    dispAddCondition,
    dispAddTab,
    dispDeleteCondition,
    dispPostConfig,
    dispPushCurrentHashForSelectedTab,
    dispUpdateConditionForValues,
    dispUpdateConditionForVariables,
    dispUpdateConditonForOperator,
    dispUpdateReturnMethod,
    dispValidateCondition,
    dispValidateReturnMethod,
    returnMethods,
    updateAjaxStatus,
    initialHash,
    currentHash,
    selectedReturnMethod,
    selectedReturnMethodId,
    defaultOptions,
    itemOptions,
    conditionOptions,
    operatorNames,
    checkisAddBtnEnabled,
    dispReasonAddCondition,
    dispReasonDeleteCondition,
    dispReasonUpdateConditionForValues,
    returnReasonOptions,
    dispReasonUpdateConditonForOperator,
    dispReasonUpdateConditionForVariables,
    isAddBtnSelectReasonEnabled,
  } = useReturnMethodModal({ onSave });

  return (
    <div>
      <Tab.Container
        id="left-tabs-example"
        activeKey={selectedReturnMethodId ?? ""}
        onSelect={(eventKey) => {
          if (selectedReturnMethodId !== eventKey) {
            dispatch(
              setSelectedReturnMethodId({ returnMethodId: eventKey ?? "" }),
            );
          }
        }}
      >
        <Row>
          <Col>
            <Nav
              variant="tabs"
              className={`d-flex align-items-center border-0 ${styles.tabSectionWrapper} `}
            >
              {returnMethods.map((ret, idx) => {
                return (
                  <Nav.Item
                    className=""
                    role="button"
                    key={ret.returnMethodId + "navItem"}
                  >
                    <Nav.Link
                      eventKey={ret.returnMethodId}
                      className={`py-1 px-3  text-dark shadow-sm  ${styles.tabStyles}`}
                      style={{
                        background:
                          selectedReturnMethod !== null
                            ? selectedReturnMethodId === ret.returnMethodId
                              ? ret.errorCount > 0
                                ? "#FFDCDC 0% 0% no-repeat padding-box"
                                : "#FFFFFF 0% 0% no-repeat padding-box "
                              : ret.errorCount > 0
                                ? "#FFDCDC 0% 0% no-repeat padding-box"
                                : "#f5f5f5 0% 0% no-repeat padding-box"
                            : "",
                      }}
                    >
                      <AutomationTab idx={idx}>
                        <TabName
                          id={ret.returnMethodId}
                          name={ret.returnMethodName}
                          updateType={dispUpdateReturnMethod}
                          pushCurrentHash={dispPushCurrentHashForSelectedTab}
                        />

                        <TabError errorCount={ret.errorCount} />

                        <div className="ms-1">
                          <CloseButtonForTab
                            index={idx}
                            name={ret.returnMethodName}
                            hasStaticTab={false}
                            onDelete={() => {
                              dispatch(
                                deleteReturnMethod({ id: ret.returnMethodId }),
                              );
                            }}
                          />
                        </div>
                      </AutomationTab>
                    </Nav.Link>
                  </Nav.Item>
                );
              })}
              <AddMore
                AddTab={() => dispAddTab(showSelectReturn)}
                text=" Add custom return method type"
                selectedTabId={selectedReturnMethodId}
                validateTabType={dispValidateReturnMethod}
              />
            </Nav>

            <Tab.Content
              className={` ${styles.contentStyles} pt-4 ${
                initialHash &&
                currentHash &&
                initialHash === currentHash &&
                styles.noDiscardAndSave
              }`}
            >
              {returnMethods.map((ret, idx) => {
                const allSelectedConditionNames: string[] = ret.conditions.map(
                  (condition) => condition.variableName,
                );

                const filteredConditions = conditionOptions.filter(
                  (condition) => !allSelectedConditionNames.includes(condition),
                );

                const filteredItemOptions = itemOptions.filter(
                  (condition) => !allSelectedConditionNames.includes(condition),
                );

                const filteredReasonOptions = returnReasonOptions.filter(
                  (condition) => !allSelectedConditionNames.includes(condition),
                );

                return (
                  <Tab.Pane
                    key={ret.returnMethodId + "tabPane"}
                    eventKey={ret.returnMethodId}
                    unmountOnExit={true}
                    onEnter={() => {
                      dispValidateCondition(ret.returnMethodId);
                    }}
                    onEntered={() => {
                      dispValidateCondition(ret.returnMethodId);
                    }}
                    onExited={() => {
                      dispValidateCondition(ret.returnMethodId);
                    }}
                  >
                    {/* Conditions Section  */}
                    {idx === 0 ? (
                      <ConditionGroup
                        key={ret.returnMethodId + "default_condition_main"}
                        title=""
                        initialShow={true}
                        disableHide={true}
                      >
                        <ContentHeading text="  Ask return type on" />
                        <ConditionGroup
                          key={ret.returnMethodId + "default_condition_main_1"}
                          title=""
                          initialShow={true}
                          disableHide={true}
                        >
                          <VariableSelect
                            key={ret.returnMethodId + "Default_option"}
                            id={ret.returnMethodId}
                            condition={{} as any}
                            variablesName={defaultOptions}
                            pushCurrentHash={dispPushCurrentHashForSelectedTab}
                            updateConditon={() => {}}
                            selectedVariableName={""}
                            showPlaceholder={false}
                          />
                        </ConditionGroup>
                        <span className={`${styles.description}`}>
                          To create specific return type for specific kinds of
                          orders and items,{" "}
                          <button
                            className={`${styles.clickLink}`}
                            onClick={() => dispAddTab(showSelectReturn)}
                          >
                            click here
                          </button>
                        </span>
                      </ConditionGroup>
                    ) : null}

                    {showSelectReturn && idx !== 0 ? (
                      <>
                        <ConditionGroup
                          title="Select Return Reason"
                          errorMessage={ret.selectReturnReasonError}
                        >
                          {/* <div className="d-flex align-items-md-center flex-column flex-lg-row w-100 w-md-75"> */}
                          {ret.selectReturnReason.map((condition, idx) => {
                            return (
                              <Condition
                                key={condition.id + "conditionId"}
                                id={ret.returnMethodId}
                                condition={condition}
                                deleteCondition={dispReasonDeleteCondition}
                                idx={idx}
                                validateCondition={dispValidateCondition}
                                hideDelete={idx === 0}
                              >
                                <div className={``}>
                                  <div
                                    className={`d-flex align-items-md-center flex-column flex-lg-row w-100 w-md-75`}
                                  >
                                    <VariableSelect
                                      id={ret.returnMethodId}
                                      condition={condition}
                                      variablesName={filteredReasonOptions}
                                      pushCurrentHash={
                                        dispPushCurrentHashForSelectedTab
                                      }
                                      updateConditon={
                                        dispReasonUpdateConditionForVariables
                                      }
                                      selectedVariableName={
                                        condition.variableName
                                      }
                                      errorMessage={ret.selectReturnReasonError}
                                    />
                                    <OperatorSelect
                                      condition={condition}
                                      id={ret.returnMethodId}
                                      pushCurrentHash={
                                        dispPushCurrentHashForSelectedTab
                                      }
                                      updateConditon={
                                        dispReasonUpdateConditonForOperator
                                      }
                                      operatorNames={
                                        operatorNames[condition.variableName]
                                      }
                                      selectedOperator={condition.operator}
                                    />

                                    <Values
                                      id={ret.returnMethodId}
                                      condition={condition}
                                      updateCondition={
                                        dispReasonUpdateConditionForValues
                                      }
                                      pushCurrentHash={
                                        dispPushCurrentHashForSelectedTab
                                      }
                                      useNewComponent={true}
                                    />
                                  </div>
                                  {condition.values.return_reason.reasonId ? (
                                    <div>
                                      <FollowUpQuestion
                                        reasonId={
                                          condition.values.return_reason
                                            .reasonId
                                        }
                                        error={
                                          condition.values.return_reason.error
                                        }
                                        condition={condition}
                                        operator={
                                          condition.values.return_reason
                                            .operator
                                        }
                                        onChange={(value) => {
                                          dispReasonUpdateConditionForValues(
                                            ret.returnMethodId,
                                            value,
                                            condition,
                                            true,
                                          );
                                        }}
                                      />
                                    </div>
                                  ) : null}
                                </div>
                              </Condition>
                            );
                          })}

                          <AddConditionButton
                            AddCondition={(id: string) =>
                              dispReasonAddCondition(id)
                            }
                            id={ret.returnMethodId}
                            isAddBtnEnabled={isAddBtnSelectReasonEnabled}
                            btnText="Add return reason"
                          />
                          {/* </div> */}
                        </ConditionGroup>
                      </>
                    ) : null}

                    {idx !== 0 ? (
                      <ConditionGroup
                        title="Select Items"
                        errorMessage={ret.itemConditionError}
                      >
                        <div>
                          {ret.conditions.filter((condition) =>
                            itemOptions.includes(condition.variableName),
                          ).length === 0 ? (
                            <ConditionGroup
                              key={ret.returnMethodId + "item_conditions"}
                              title=""
                              initialShow={true}
                              disableHide={true}
                            >
                              <VariableSelect
                                id={ret.returnMethodId}
                                condition={{} as any}
                                variablesName={filteredItemOptions}
                                pushCurrentHash={
                                  dispPushCurrentHashForSelectedTab
                                }
                                updateConditon={(id: any, e: any) =>
                                  dispAddCondition(id, true, e)
                                }
                                selectedVariableName={""}
                                showPlaceholder={true}
                                errorMessage={ret.itemConditionError}
                              />
                            </ConditionGroup>
                          ) : null}
                          {ret.conditions
                            .filter((condition) =>
                              itemOptions.includes(condition.variableName),
                            )
                            .map((condition, idx) => {
                              return (
                                <Condition
                                  key={condition.id + "conditionId"}
                                  id={ret.returnMethodId}
                                  condition={condition}
                                  deleteCondition={(
                                    conId: string,
                                    id: string,
                                  ) => {
                                    dispDeleteCondition(conId, id, true);
                                  }}
                                  idx={idx}
                                  validateCondition={dispValidateCondition}
                                  hideDelete={idx === 0}
                                >
                                  <VariableSelect
                                    id={ret.returnMethodId}
                                    condition={condition}
                                    variablesName={filteredItemOptions}
                                    pushCurrentHash={
                                      dispPushCurrentHashForSelectedTab
                                    }
                                    updateConditon={
                                      dispUpdateConditionForVariables
                                    }
                                    selectedVariableName={
                                      condition.variableName
                                    }
                                  />
                                  <OperatorSelect
                                    condition={condition}
                                    id={ret.returnMethodId}
                                    pushCurrentHash={
                                      dispPushCurrentHashForSelectedTab
                                    }
                                    updateConditon={
                                      dispUpdateConditonForOperator
                                    }
                                    operatorNames={
                                      operatorNames[condition.variableName]
                                    }
                                    selectedOperator={condition.operator}
                                  />

                                  <Values
                                    id={ret.returnMethodId}
                                    condition={condition}
                                    updateCondition={
                                      dispUpdateConditionForValues
                                    }
                                    pushCurrentHash={
                                      dispPushCurrentHashForSelectedTab
                                    }
                                    useNewComponent={true}
                                  />
                                </Condition>
                              );
                            })}

                          <AddConditionButton
                            AddCondition={(id: string) =>
                              dispAddCondition(id, true)
                            }
                            id={ret.returnMethodId}
                            isAddBtnEnabled={checkisAddBtnEnabled(true)}
                            btnText="Add item"
                          />
                        </div>
                      </ConditionGroup>
                    ) : null}
                    {idx !== 0 ? (
                      <ConditionGroup
                        key={ret.returnMethodId + "select_order"}
                        title="Select order types"
                        errorMessage={ret.error}
                      >
                        {ret.conditions.filter(
                          (condition) =>
                            !itemOptions.includes(condition.variableName),
                        ).length === 0 ? (
                          <ConditionGroup
                            key={ret.returnMethodId + "default_other_condition"}
                            title=""
                            initialShow={true}
                            disableHide={true}
                          >
                            <VariableSelect
                              id={ret.returnMethodId}
                              condition={{} as any}
                              variablesName={filteredConditions}
                              pushCurrentHash={
                                dispPushCurrentHashForSelectedTab
                              }
                              updateConditon={(id: any, e: any) =>
                                dispAddCondition(id, false, e)
                              }
                              selectedVariableName={""}
                              showPlaceholder={true}
                              errorMessage={ret.itemConditionError}
                            />
                          </ConditionGroup>
                        ) : null}
                        {ret.conditions
                          .filter((condition) =>
                            conditionOptions.includes(condition.variableName),
                          )
                          .map((condition, idx) => {
                            return (
                              <Condition
                                key={condition.id + "conditionId"}
                                id={ret.returnMethodId}
                                condition={condition}
                                deleteCondition={dispDeleteCondition}
                                idx={idx}
                                validateCondition={dispValidateCondition}
                              >
                                <VariableSelect
                                  id={ret.returnMethodId}
                                  condition={condition}
                                  variablesName={filteredConditions}
                                  pushCurrentHash={
                                    dispPushCurrentHashForSelectedTab
                                  }
                                  updateConditon={
                                    dispUpdateConditionForVariables
                                  }
                                  selectedVariableName={condition.variableName}
                                  showPlaceholder={false}
                                />

                                <OperatorSelect
                                  condition={condition}
                                  id={ret.returnMethodId}
                                  pushCurrentHash={
                                    dispPushCurrentHashForSelectedTab
                                  }
                                  updateConditon={dispUpdateConditonForOperator}
                                  operatorNames={
                                    operatorNames[condition.variableName]
                                  }
                                  selectedOperator={condition.operator}
                                />

                                <Values
                                  id={ret.returnMethodId}
                                  condition={condition}
                                  updateCondition={dispUpdateConditionForValues}
                                  pushCurrentHash={
                                    dispPushCurrentHashForSelectedTab
                                  }
                                  useNewComponent={true}
                                />
                              </Condition>
                            );
                          })}

                        <AddConditionButton
                          AddCondition={(id: string) =>
                            dispAddCondition(id, false)
                          }
                          id={ret.returnMethodId}
                          isAddBtnEnabled={checkisAddBtnEnabled(false)}
                        />
                      </ConditionGroup>
                    ) : null}

                    <ConditionGroup
                      title="Add Return Method(s) To Be Displayed To The Customer"
                      errorMessage={ret.returnMethodType.error}
                    >
                      <div className={`py-3 ${styles.innerBox} `}>
                        <ReturnTypeBox
                          key={ret.returnMethodId + "_notRequired"}
                          title="The customer does not have to return the order"
                          returnMethodType={"notRequired"}
                          returnMethodId={ret.returnMethodId}
                          message={ret.returnMethodType.message}
                          enabled={ret.returnMethodType.value === "notRequired"}
                          errorMessage={ret.returnMethodType.error}
                        />
                        <ReturnTypeBox
                          key={ret.returnMethodId + "_returnRequired"}
                          title="The customer would need to ship back at their own expense to our return address."
                          returnMethodType={"returnRequired"}
                          returnMethodId={ret.returnMethodId}
                          message={ret.returnMethodType.message}
                          enabled={
                            ret.returnMethodType.value === "returnRequired"
                          }
                          errorMessage={ret.returnMethodType.error}
                        />
                        <ReturnTypeBox
                          key={ret.returnMethodId + "_returnRequiredWithLabels"}
                          title="Please provide prepaid labels to the customer to ship back"
                          returnMethodType={"returnRequiredWithLabels"}
                          returnMethodId={ret.returnMethodId}
                          message={ret.returnMethodType.message}
                          enabled={
                            ret.returnMethodType.value ===
                            "returnRequiredWithLabels"
                          }
                          errorMessage={ret.returnMethodType.error}
                        />
                      </div>
                    </ConditionGroup>
                  </Tab.Pane>
                );
              })}
            </Tab.Content>
          </Col>
        </Row>
        <DiscardAndSave
          onSave={dispPostConfig}
          showLoading={updateAjaxStatus === "pending"}
          onDiscard={() => {
            if (!returnAutoWorkFlow.integrationId) {
              return;
            }

            dispatch(
              fetchReturnMethodsThunk({
                integrationId: returnAutoWorkFlow.integrationId,
                showReturnReasons: false,
              }),
            );
          }}
        />
      </Tab.Container>
    </div>
  );
};

const ReturnMethod = ({
  onHide,
  onSave = () => {},
}: {
  onHide: () => void;
  onSave?: () => void;
}) => {
  const dispatch = useAppDispatch();
  const { isChanged, intialStep14Config } = useAppSelector(
    (state) => state.returnMethod,
  );
  const fetchAjaxStatus = useAppSelector(
    (state) => state.returnMethod.fetchAjaxStatus,
  );
  const [isValidating, setIsValidating] = useState(false);
  const [showDiscardModel, setShowDiscardModal] = useState(false);

  useEffect(() => {
    if (isValidating) {
      if (isChanged) {
        setShowDiscardModal(true);
      } else {
        onHide();
      }
      setIsValidating(false);
    }
  }, [isChanged, isValidating, onHide]);

  const closeButtonFunctionality = useCallback(() => {
    dispatch(validateChanges());
    setIsValidating(true);
  }, [dispatch]);

  return (
    <div>
      <SkeletonModalStructure
        hasTwoSection={true}
        heading="Configure Return Method"
        closeFunction={closeButtonFunctionality}
        rightSide={
          fetchAjaxStatus === "pending" ? (
            <div className={`${styles.loaderHeight}`}>
              <Loader />
            </div>
          ) : (
            <div className={`${styles.loaderHeight}`}>
              <AutoWorkFlow type="returnInstruction" />
            </div>
          )
        }
      >
        {fetchAjaxStatus === "pending" ? (
          <div className={`${styles.loaderHeight}`}>
            <Loader />
          </div>
        ) : (
          <ReturnMethodComponent onSave={onSave} />
        )}
      </SkeletonModalStructure>
      <Modal
        className={`shadow-sm p-3 rounded`}
        show={showDiscardModel}
        onHide={() => setShowDiscardModal(false)}
        dialogClassName={`${styles.modalDialog2}`}
        contentClassName={`${styles.modalContent2}`}
        backdropClassName={`${styles.modalBackDrop}`}
        enforceFocus={false}
        keyboard={false}
      >
        <DiscardModal
          setShow={setShowDiscardModal}
          closeMainModel={onHide}
          onConfirm={() => {
            dispatch(undoConfig({ initialConfig: intialStep14Config }));
          }}
        />
      </Modal>
    </div>
  );
};

export default ReturnMethod;
