import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";
import { AutomationAPIPayload } from "./createAutomation.service";

export interface UpdateAutomationParams {
  integrationId: string;
  brandId?: string;
  automationName?: string;
  publish?: boolean;
}

export const updateAutomationService = async ({
  type,
  payload,
}: AutomationAPIPayload<UpdateAutomationParams>) => {
  const url = IS_TEST_ACTIVE
    ? `${process.env.REACT_APP_TEST_SITE_URL}/bugManagement/update`
    : `/api/automation/${type}/update`;

  const { data: res } = await axiosJSON.post(url, payload);
  if (res.err || res.error) {
    if (res.statusCode) {
      throw new Error(res.statusCode);
    }

    throw new res.msg() || res.message || "Cannot Update automation data";
  }

  return true;
};
