import { axiosJSON } from "src/globals/axiosEndPoints";

async function deleteReturnIntegrationService(params: {
  integrationId: string;
}) {
  const { data: res } = await axiosJSON.delete(
    "/api/setting/automation/refund/delete",
    { params },
  );

  if (res.err || res.error) {
    throw new Error(
      res.msg ?? res.message ?? "Some Error Occurred while fetching data",
    );
  }

  return true;
}

export default deleteReturnIntegrationService;
