import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "src/store/store";
import { AjaxError } from "src/types/AjaxError";
import { addViewValidationSelector } from "src/store/slices/ticketViews/ticketViews.selectors";
import TicketViewsService from "src/services/TicketViewsService";
import {
  fetchAddedViewSideBarData,
  fetchMultipleSidebarData,
  fetchSideBarData,
} from "../ticketSidebar/ticketSidebar.slice";
import { getUsersViewsById } from "src/services/SideBar/getSubSB";
import { prepareData } from "src/services/TicketViewsService/prepareQuery";
import {
  fetchTickets,
  resetCachedTickets,
  resetPagination,
} from "../tickets/tickets.slice";
import { getViewLS, removeViewLS, setViewLS } from "./ticketViews.utils";
import { Filters } from "../ticketFilters/ticketFilters.slice";
import { isObjOrStrEmpty } from "src/utils/utils";
// import log from "loglevel";

type TicketViewsSlice = {
  addViewFromData: { [key: string]: any };
  unsavedViewFromData: null | { [key: string]: any };
  activeViewId: null | number;
  addViewState: "idle" | "pending" | "fulfilled" | "rejected" | "rejected2";
};

const initialState: TicketViewsSlice = {
  addViewFromData: {
    viewName: "",
    brand: "",
    channel: "",
    tags: "",
    createdOn: "", // it will be undefined after clear all cross is clicked
    createdOnValue: {
      startDate: null,
      startTime: null,
      endDate: null,
      endTime: null,
    },
    assignedTo: "",
    closedOn: "", // it will be undefined after clear all cross is clicked
    closedOnValue: {
      startDate: null,
      startTime: null,
      endDate: null,
      endTime: null,
    },
    draftStatus: "",
    ticketStatus: "",
  },
  unsavedViewFromData: null,
  activeViewId: null,
  addViewState: "idle",
};

interface IAddViewParam {
  callback?: (viewId: number) => void;
}
export const addView = createAsyncThunk(
  "ticketViews/addView",
  async (
    payload: IAddViewParam | undefined,
    { getState, dispatch }: { getState: Function; dispatch: Function }
  ) => {
    try {
      const currentState: RootState = getState();
      const addViewQuery = TicketViewsService.prepareQuery(
        currentState.ticketViews.addViewFromData
      );
      if (currentState.ticketViews.activeViewId) {
        addViewQuery.viewId = currentState.ticketViews.activeViewId;
      }
      const addViewResponse = await TicketViewsService.addViewApi(addViewQuery);
      let viewIds = [
        addViewQuery.viewId
          ? "viewId::"+addViewQuery.viewId
          : "viewId::"+addViewResponse.data.insertionId,
      ];

      if(addViewResponse.data?.insertionId){
        dispatch(
          fetchAddedViewSideBarData(
              parseInt(addViewResponse.data.insertionId + "")
          )
        );
      }
     
      dispatch(fetchMultipleSidebarData({ views: viewIds }));

      if (
        currentState.ticketSideBar.selectedBtn.includes(
          `${currentState.ticketViews.activeViewId}`
        )
      ) {
        dispatch(resetPagination());
        dispatch(resetCachedTickets());
        dispatch(fetchTickets());
      }

      if (payload && payload.callback) {
        payload.callback(addViewResponse.data?.insertionId);
      }
      return addViewResponse.data;
    } catch (error) {
      let ajaxError: AjaxError = {
        message: (error as Error).message,
      };
      throw ajaxError;
    }
  }
);

export const getView = createAsyncThunk(
  "ticketViews/getView",
  async (
    _: undefined,
    { getState, dispatch }: { getState: Function; dispatch: Function }
  ) => {
    try {
      const currentState: RootState = getState();
      if (currentState.ticketViews.activeViewId) {
        const viewData = await getUsersViewsById({
          viewId: currentState.ticketViews.activeViewId,
        });
        const vData = prepareData(viewData);
        const unsavedData = getViewLS({
          viewId: `${currentState.ticketViews.activeViewId}`,
        });
        return { data: vData, unsavedData };
      } else {
        const data = getViewLS({
          viewId: null,
        });
        if (data) {
          return { data, unsavedData: null };
        } else {
          return { data: null, unsavedData: null };
        }
      }
    } catch (error) {
      let ajaxError: AjaxError = {
        message: (error as Error).message,
      };
      throw ajaxError;
    }
  }
);

export const ticketViewsSlice = createSlice({
  name: "ticketViews",
  initialState,
  reducers: {
    setActiveViewId: (
      state,
      { payload }: { payload: { activeViewId: number } }
    ) => {
      state.activeViewId = payload.activeViewId;
    },
    setFormDataByKey: (
      state,
      action: PayloadAction<{ key: string; data: any }>
    ) => {
      const { key, data } = action.payload;
      state.addViewFromData[key] = data;
      if (state.unsavedViewFromData) {
        state.unsavedViewFromData[key] = data;
      }
      setViewLS({
        viewId: state.activeViewId == null ? null : `${state.activeViewId}`,
        formData: {
          ...state.addViewFromData,
          ...(state.unsavedViewFromData ?? {}),
        },
      });
    },
    setCreatedOnValue: (state, action: PayloadAction<any>) => {
      state.addViewFromData.createdOnValue = action.payload;
      if (state.unsavedViewFromData) {
        state.unsavedViewFromData.createdOnValue = action.payload;
      }
      setViewLS({
        viewId: state.activeViewId == null ? null : `${state.activeViewId}`,
        formData: {
          ...state.addViewFromData,
          ...(state.unsavedViewFromData ?? {}),
        },
      });
    },
    setClosedOnValue: (state, action: PayloadAction<any>) => {
      state.addViewFromData.closedOnValue = action.payload;
      if (state.unsavedViewFromData) {
        state.unsavedViewFromData.closedOnValue = action.payload;
      }
      setViewLS({
        viewId: state.activeViewId == null ? null : `${state.activeViewId}`,
        formData: {
          ...state.addViewFromData,
          ...(state.unsavedViewFromData ?? {}),
        },
      });
    },
    showUnsavedChanges: (state) => {
      if (state.unsavedViewFromData !== null) {
        state.addViewFromData = {
          ...state.addViewFromData,
          ...state.unsavedViewFromData,
        };
        setViewLS({
          viewId: state.activeViewId == null ? null : `${state.activeViewId}`,
          formData: state.addViewFromData,
        });
        state.unsavedViewFromData = null;
      }
    },
    resetTicketViews: () => initialState,
    //reducer to add outer ticket filters data to  AddView payload slice, it used in outer ticket view filters v2
    setFormDataFromTicketFilter: (
      state,
      action: PayloadAction<{ filters: Filters }>
    ) => {
      const { filters } = action.payload;

      const {
        filterByAgents,
        filterByCreatedDate,
        filterByCreatedDateValue,
        filterByClosedDate,
        filterByClosedDateValue,
        filterByChannels,
        filterByBrands,
        filterByTags,
        filterByEmails,
        filterByAssignees,
        filterByUserType,
        filterByLastMessage,
        filterByNotRepliedSince,
        filterByNotRepliedSinceValue,
        filterByTicketStatus,
        filterByDraftStatus,
        filterByDraftedBy,
      } = filters;

      let addViewFilterdata: { [key: string]: any } = [];

      if (!isObjOrStrEmpty(filterByCreatedDate)) {
        addViewFilterdata["createdOn"] = filterByCreatedDate;
      }

      addViewFilterdata["createdOnValue"] = filterByCreatedDateValue;

      if (!isObjOrStrEmpty(filterByClosedDate)) {
        addViewFilterdata["closedOn"] = filterByClosedDate;
      }

      addViewFilterdata["closedOnValue"] = filterByClosedDateValue;

      // if (!isObjOrStrEmpty(filterByNotRepliedSince)) {
      //   addViewFilterdata["not_replied_since"] = filterByNotRepliedSince; //todo need to check if this filter is supported
      // }
      if (!isObjOrStrEmpty(filterByChannels)) {
        addViewFilterdata["channel"] = filterByChannels;
      }
      if (!isObjOrStrEmpty(filterByBrands)) {
        addViewFilterdata["brand"] = filterByBrands;
      }
      if (!isObjOrStrEmpty(filterByAgents)) {
        addViewFilterdata["assignedTo"] = filterByAgents;
      }
      if (!isObjOrStrEmpty(filterByTags)) {
        addViewFilterdata["tags"] = filterByTags;
      }
      // if (!isObjOrStrEmpty(filterByEmails)) {
      //   addViewFilterdata["filter_email"] = filterByEmails;//todo need to check this filter is supported or not
      // }

      // if (!isObjOrStrEmpty(filterByUserType)) {
      //   addViewFilterdata["user_type"] = filterByUserType;//todo need to check this filter is supported or not
      // }

      if (!isObjOrStrEmpty(filterByLastMessage)) {
        addViewFilterdata["last_message"] = filterByLastMessage; //todo need to check this filter is supported or not
      }

      if (!isObjOrStrEmpty(filterByTicketStatus)) {
        addViewFilterdata["ticketStatus"] = filterByTicketStatus;
      }
      if (!isObjOrStrEmpty(filterByDraftStatus)) {
        addViewFilterdata["draftStatus"] = filterByDraftStatus;
      }
      if (!isObjOrStrEmpty(filterByDraftedBy)) {
        addViewFilterdata["drafted_by"] = filterByDraftedBy; //todo need to check this filter is supported or not
      }

      state.addViewFromData = {
        ...initialState.addViewFromData, //keeping the default property
        ...addViewFilterdata, //updating the state with filters
      };
    },
  },
  extraReducers: (builder) => {
    /* 
    Reducers for adding view
    */
    builder.addCase(addView.fulfilled, (state, { payload }) => {
      state.addViewState = "fulfilled";
      removeViewLS({
        viewId: state.activeViewId == null ? null : `${state.activeViewId}`,
      });
      if (payload.insertionId) {
        state.activeViewId = payload.insertionId;
      }
    });
    builder.addCase(addView.rejected, (state) => {
      state.addViewState = "rejected";
    });
    builder.addCase(addView.pending, (state) => {
      state.addViewState = "pending";
    });
    builder.addCase(getView.fulfilled, (state, { payload }) => {
      state.addViewState = "idle";
      if (payload.data) {
        state.addViewFromData = { ...state.addViewFromData, ...payload.data };
      }
      state.unsavedViewFromData = payload.unsavedData;
    });
    builder.addCase(getView.rejected, (state) => {
      state.addViewState = "rejected2";
    });
    builder.addCase(getView.pending, (state) => {
      state.addViewState = "pending";
    });
  },
});

export const {
  setFormDataByKey,
  setCreatedOnValue,
  setClosedOnValue,
  resetTicketViews,
  setActiveViewId,
  showUnsavedChanges,
  setFormDataFromTicketFilter,
} = ticketViewsSlice.actions;

const getAddViewFormData = (state: any) => state.ticketViews.addViewFromData;

export const selectAddViewValidation = createSelector(
  getAddViewFormData,
  addViewValidationSelector
);

export default ticketViewsSlice.reducer;
