import { axiosJSON } from "src/globals/axiosEndPoints";
import APIResponse from "./APIReponse";

export interface DeleteUserParams {
  userId: number;
}

export async function deleteUser(params: DeleteUserParams) {
  const { data: res } = await axiosJSON.delete<APIResponse<any>>(
    `/api/setting/user/delete?userId=${params.userId}`
  );

  if (res.error === true) {
    throw new Error(res.message as string);
  }
  return res.data as any;
}
