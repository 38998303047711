import { axiosJSON } from "src/globals/axiosEndPoints";

export interface ITimeZoneListParams {
  start?: number;
  limit?: number;
  searchTerm?: string;
}

export interface ITimeZoneItem {
  label: string;
  timezone: string;
}

export interface ITimeZoneListRes {
  data: Array<ITimeZoneItem>;
  metaData: {
    count: number;
    total: number | null;
  };
}

export async function fetchTimeZoneList(params: ITimeZoneListParams) {
  const { data: res }: any = await axiosJSON.post(
    `/api/setting/user/getAllTimezones`,
    params
  );
  
  if (res.error === true) {
    throw new Error(res.message as string);
  }

  const ret: ITimeZoneListRes = {
    data: res.data,
    metaData: {
      count: res.metaData.count,
      total: res.metaData.count,
    },
  };

  return ret;
}
