import styles from "./ReturnMethod.module.scss";
import refresh from "src/assets/images/chat/refreshButton.png";

import { useAppSelector } from "src/store/store";
const ReturnMethod = () => {
  const selectedReturnMethod = useAppSelector((state) => {
    return state.step14Config.returnMethods.find(
      (retM) =>
        retM.returnMethodId === state.step14Config.selectedReturnMethodId,
    );
  });

  return (
    <>
      <div>
        <div className={`d-flex align-items-center`}>
          <div>
            <img
              src={refresh}
              alt="refresh"
              className={`${styles.refresh}`}
            />{" "}
          </div>
          <span className={`ms-1 ${styles.heading}`}>
            Initiate Return/Exchange{" "}
          </span>
        </div>
        {/* Choose Return method section      */}
        <div className={``}>
          <p className={`py-2 mb-0 ${styles.return}`}>Choose Return Method :</p>
          {/* <div>
            <span className={`d-block px-1 my-2 py-1 ${styles.errormsg}`}>
              *Please select a shipping method
            </span>
          </div> */}

          {/* Ship with any carrier of your choice */}
          {selectedReturnMethod?.shipWithAnyCarrier.currentState === true ? (
            <div className={`px-3 py-2 mb-2 ${styles.radioBox}`}>
              <div className="form-check">
                <input
                  className={`form-check-input ${styles.radioCheck}`}
                  type="radio"
                  name="return"
                  id="radio1"
                />
                <label
                  className="form-check-label"
                  htmlFor="radio1"
                >
                  <h5 className={`${styles.labelHead}`}>
                    Ship with any carrier of your choice
                  </h5>
                  <span className={`d-inline-block ${styles.labelSpan}`}>
                    You will get the shipping instructions after the request is
                    approved.
                  </span>
                </label>
              </div>
            </div>
          ) : null}

          {/* Ship with a return label */}
          {selectedReturnMethod?.shipWithPrepaidLabels.currentState === true ? (
            <div className={`px-3 py-2 mb-2 ${styles.radioBox}`}>
              <div className="form-check">
                <input
                  className={`form-check-input ${styles.radioCheck}`}
                  type="radio"
                  name="return"
                  id="radio2"
                />
                <label
                  className="form-check-label"
                  htmlFor="radio2"
                >
                  <h5 className={`${styles.labelHead}`}>
                    Ship with a return label
                  </h5>
                  <span className={`d-inline-block ${styles.labelSpan}`}>
                    You will get a returns label after we approve your request.
                  </span>
                </label>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};
export default ReturnMethod;
