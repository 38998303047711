import { axiosJSON } from "src/globals/axiosEndPoints";

export interface ConditionType {
  id: number;
  conditionTypeId: string;
  operator: string;
  conditionValue:
    | string
    | string[]
    | {
        value: string;
        currencyCode: string;
      };
}

export interface TabType {
  configTabId: number;
  configTabName: string;
  conditions: ConditionType[];
  notEligibleResponseMessage: string;
}

export interface Step9TabType extends TabType {
  returnWindow: ConditionType;
}

export interface Step9Config {
  data: TabType[];
}
export interface StepConfigParams {
  integrationId: number | string;
}

export async function fetchGetStep9Config(params: StepConfigParams) {
  const { data } = await axiosJSON.get(
    "/api/setting/automation/refund/step/9/configuration",
    { params },
  );

  if (data.err === true) {
    throw new Error("Some Error Occurred while fetching data");
  }

  return data.data;
}
