import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import {
  ConditionTypes,
  createNewObjectHash,
  getConditionValueOrDefault,
} from "src/store/slices/initiateReturn/Helpers/returnExchange.helpers";
import { v4 as uuid } from "uuid";
import {
  fetchPostStep10Config,
  fetchStep10GetConfig,
} from "../Thunks/returnExchangeModal.thunk";
import { step10Configuration } from "../step10Configuration.slices";

const updateConfig = (state: step10Configuration, payload: any) => {
  const {
    step10ConditionOptionsRes,
    step10ConfigRes,
    defaultConditionOrderDate,
  } = payload;

  if (step10ConditionOptionsRes) {
    state.conditionOptions = step10ConditionOptionsRes.conditions;
    state.itemOptions = step10ConditionOptionsRes.itemConditions;
    state.defaultOptions = step10ConditionOptionsRes.defaultConditions;
    [
      ...state.conditionOptions,
      ...state.itemOptions,
      ...state.defaultOptions,
    ].forEach((option) => {
      if (!state.variableNames.some((item: any) => item.id === option.id)) {
        state.variableNames.push({ name: option.name, id: option.id });
      }

      state.operatorsNames[option.name] = option.operators.map((operator) => {
        state.operatorSymbolMap[operator.value] = operator.name;
        return operator.name;
      });
      state.valueTypes[option.valueType] = null;
      state.variableNameToValueType[option.name] = option.valueType;
    });
  }

  if (step10ConfigRes) {
    const step10Config = step10ConfigRes;

    if (step10Config.length > 0) {
      state.selectedItemTypeId = step10Config[0].configTabId.toString();
      state.itemTypes = step10Config.map((tab: any, idx: any) => {
        return {
          itemTypeName: tab.configTabName,
          itemTypeId: tab.configTabId.toString(),
          messageForCustomer: {
            value: tab.notEligibleResponseMessage,
            error: null,
          },
          error: null,
          itemError: null,
          isAddConditionEnabled: true,
          isAddItemConditionEnabled: true,
          errorCount: 0,
          isValid: false,
          // itemSequence: tab.itemSequence,
          conditions: tab.conditions.map((condition: any) => {
            const selectedVariable: any = state.variableNames.find(
              (variable: any) => variable.id == condition.conditionTypeId,
            );

            if (selectedVariable) {
              const variableName = selectedVariable.name;
              const operatorSymbol = condition.operator;
              const operator = state.operatorSymbolMap[operatorSymbol];
              const valueType = state.variableNameToValueType[variableName];
              const values = getConditionValueOrDefault(
                valueType,
                condition.conditionValue,
              );

              // const values = productIdToDetails(Conditionvalues);

              return {
                id: condition.id.toString(),
                variableName,
                operator,
                values,
              } as ConditionTypes;
            }
            return {} as ConditionTypes;
          }),
        };
      });
    } else {
      if (step10ConditionOptionsRes.defaultConditions) {
        let conditions = step10ConditionOptionsRes.defaultConditions.map(
          (condition: any) => {
            const selectedVariable: any = state.variableNames.find(
              (variable: any) => variable.id == condition.id,
            );
            if (selectedVariable) {
              const variableName = selectedVariable.name;
              const operatorSymbol = condition.operators[0].value;
              const operator = state.operatorSymbolMap[operatorSymbol];
              const valueType = state.variableNameToValueType[variableName];
              const values = getConditionValueOrDefault(valueType, null);

              // const values = productIdToDetails(Conditionvalues);

              return {
                id: uuid(),
                variableName,
                operator,
                values,
              } as ConditionTypes;
            }
          },
        );

        state.itemTypes[0].conditions = conditions;
      }
      if (defaultConditionOrderDate) {
        const variableName = defaultConditionOrderDate.variableName;
        const valueType = state.variableNameToValueType[variableName];
        const operatorSymbol = defaultConditionOrderDate.operator;
        const operator = state.operatorSymbolMap[operatorSymbol];
        const values = getConditionValueOrDefault(
          valueType,
          defaultConditionOrderDate.values,
        );

        let condition = {
          id: uuid(),
          variableName,
          ruleType: defaultConditionOrderDate.ruleType,
          operator,
          values,
        } as ConditionTypes;

        state.itemTypes[0].conditions.push(condition);
        state.itemTypes[0].itemTypeName = "All Orders";
      }
    }
    if (step10ConfigRes) {
      state.getItemType.data = step10ConfigRes;
    }
  }
  const hash = createNewObjectHash({
    excludeKeys: state.excludeKeysForHash,
    hashObject: state.itemTypes,
  });

  if (hash) {
    state.initialHash = hash;
    state.currentHash = hash;
  }
};

const step10Builders = (
  builder: ActionReducerMapBuilder<step10Configuration>,
) => {
  builder.addCase(
    fetchPostStep10Config.pending,
    (state: step10Configuration) => {
      state.updateAjaxStatus = "pending";
    },
  );
  builder.addCase(
    fetchPostStep10Config.fulfilled,
    (state: step10Configuration, { payload }) => {
      state.updateAjaxStatus = "fulfilled";
      updateConfig(state, payload);
    },
  );
  builder.addCase(
    fetchPostStep10Config.rejected,
    (state: step10Configuration) => {
      state.updateAjaxStatus = "rejected";
      pushTheToast({
        type: "danger",
        text: "failed to update!",
        position: "top-right",
      });
    },
  );
  builder.addCase(
    fetchStep10GetConfig.pending,
    (state: step10Configuration, { payload }) => {
      state.fetchAjaxStatus = "pending";
    },
  );
  builder.addCase(
    fetchStep10GetConfig.rejected,
    (state: step10Configuration, { payload }) => {
      state.fetchAjaxStatus = "rejected";
      pushTheToast({
        type: "danger",
        text: "failed to fetch configuration!",
        position: "top-right",
      });
    },
  );
  builder.addCase(
    fetchStep10GetConfig.fulfilled,
    (state: step10Configuration, { payload }) => {
      state.fetchAjaxStatus = "fulfilled";
      updateConfig(state, payload);
    },
  );
};

export default step10Builders;
