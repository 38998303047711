import {
  deleteAttachment,
  DeleteAttachmentParam,
} from "src/services/Attachment/deleteAttachment";
import { uploadAttachment } from "src/services/Attachment/uploadAttachment";
import { v4 as uuidv4 } from "uuid";
import {
  FileUploadHandler,
  OnFileDeleteRes,
  STATUS_UPLOAD,
  TinyMCEUploadFile,
} from "src/interfaces/TinyMCEContainer/ITinyMCEContainer";

export const fileUploader = ({
  files,
  onProgress,
  onFailed,
  onSuccess,
  areAttachmentsPublic,
}: FileUploadHandler) => {
  /* To handle click on add attachment */
  const fileObjects = Object.values(files);

  /* upload each file individually */
  for (let index = 0; index < fileObjects.length; index++) {
    let fileObject = fileObjects[index];
    let dropped: any = files[index].embedded;
    if (!fileObject.file) {
      continue;
    }
    uploadAttachment(
      {
        files: [
          fileObject.file,
        ] /*currentAttachmentBatchNumber */ /*?? batchNumberTemp*/,
        dropped,
        /*batchNumber:
                    currentAttachmentBatchNumber === ""
                      ? batchNumberTemp
                      : currentAttachmentBatchNumber */ // if extra fileObjects are added, pass the current batch number
        abortControllerSignal: fileObject?.abortController?.signal,
      },
      (progressEvent: any) => {
        let progress = Math.round(
          (100 * progressEvent.loaded) / progressEvent.total,
        );
        onProgress(fileObject.id, progress);
      },
      areAttachmentsPublic,
    )
      .then((uploadRes) => {
        // check the attachment is saved or not
        if (uploadRes.attachments[0].status === true) {
          fileObject.batchNumber = uploadRes.attachments[0].batchNumber;
          fileObject.attachmentId = uploadRes.attachments[0].attachmentId;
          fileObject.attachmentName = uploadRes.attachments[0].attachmentName;
          fileObject.isPublic = false;
          fileObject.contentId = uploadRes.attachments[0].contentId;

          if (areAttachmentsPublic && uploadRes.attachments[0]?.publicURL) {
            fileObject.isPublic = true;
            fileObject.attachmentURL = uploadRes.attachments[0].publicURL;
            onSuccess(fileObject);
          } else {
            onSuccess(fileObject);
          }
        } else {
          if (
            uploadRes.attachments[0].status === false && // Check if the upload status is false
            uploadRes.attachments[0].displableError // Check if there is a displayable error message
          ) {
            onFailed(fileObject.id, uploadRes.attachments[0].displableError); // Invoke the failure callback with the error message
            return; // Exit the function to prevent further execution
          }
          onFailed(fileObject.id);
        }
      })
      .catch((uploadErr) => {
        if (uploadErr?.message == "canceled") {
          //request was cancelled
        } else {
          onFailed(fileObject.id);
        }
      });
  }
};

export const fileDeleteHandler = (file: TinyMCEUploadFile) => {
  return new Promise<OnFileDeleteRes>((resolve, reject) => {
    if (file && file.status === STATUS_UPLOAD.success && file.attachmentId) {
      const deleteAttachmentParam: DeleteAttachmentParam = {
        attachmentId: parseInt(file.attachmentId + ""),
      };

      deleteAttachment(deleteAttachmentParam)
        .then((res) => {
          resolve({ file: file, status: true });
        })
        .catch((err) => {
          reject("Failed to delete attachment");
        });
    } else {
      // file is being uploaded, so abort the ajax
      if (file.abortController) {
        file.abortController.abort();
      }
      resolve({ file: file, status: true });
    }
  });
};
