import { axiosJSON } from "src/globals/axiosEndPoints";
import { AutomationAPIPayload } from "../createAutomation.service";
import { IS_TEST_ACTIVE } from "src/globals/constants";

export interface IArticle {
  articleId: string | number;
  articleName: string;
}

interface ResponseArticle {
  articles: IArticle[];
  metaData: {
    limitReached: boolean;
  };
}

export interface DispatchRuleArticlesPayload {
  start: number;
  limit: number;
  integrationId: string;
}

export const getAllArticlesService = async ({
  payload,
  type,
}: AutomationAPIPayload<DispatchRuleArticlesPayload>) => {
  const apiEndPoint = IS_TEST_ACTIVE
    ? `${process.env.REACT_APP_TEST_SITE_URL}/bugManagement/getAllArticles`
    : `/api/automation/${type}/articles/getAll`;

  const { data: res } = await axiosJSON.post(apiEndPoint, payload);

  if (res.err || res.error) {
    throw res.msg ?? res.message ?? "Something went wrong!";
  }

  const ret: ResponseArticle = {
    articles: res.data,
    metaData: {
      limitReached: false,
    },
  };

  if (!isNaN(payload.limit)) {
    if (res.data.length < payload.limit) {
      ret.metaData.limitReached = true;
    }
  }

  return ret;
};
