import styles from "./NoEditErrorModal.module.scss";

const NoEditErrorModal = ({
  context,
  onHide,
}: {
  context: "question" | "step" | "configModal";
  onHide: () => void;
}) => {
  return (
    <div className="px-4 pt-4 pb-5">
      <span className="pt-3 d-block text-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="35"
          height="35"
          viewBox="0 0 35 35"
        >
          <g
            id="bx-error-circle"
            transform="translate(-8 -8)"
          >
            <g
              id="Group_17383"
              data-name="Group 17383"
              transform="translate(8 8)"
            >
              <path
                id="Path_7375"
                data-name="Path 7375"
                d="M25.418,8A17.5,17.5,0,1,0,43,25.5,17.479,17.479,0,0,0,25.418,8ZM25.5,39.5a14,14,0,1,1,14-14A14.016,14.016,0,0,1,25.5,39.5Z"
                transform="translate(-8 -8)"
                fill="red"
              />
              <path
                id="Path_7376"
                data-name="Path 7376"
                d="M44,28h3.5V40.25H44Zm0,14h3.5v3.5H44Z"
                transform="translate(-28.25 -19.25)"
                fill="red"
              />
            </g>
          </g>
        </svg>
      </span>
      <h4 className={`mt-3 pt-1 ${styles.modalHead}`}>Error</h4>
      <p className={`${styles.modalDesc}`}>
        {context === "question"
          ? `Value in this question once set, cannot be edited. If you need different settings, please create a new workflow.`
          : `Steps in the workflow once set, cannot be edited. If you need different settings, please create a new workflow.`}
      </p>
      <button
        id="backbtn"
        className={`mb-2  mt-1 ${styles.backBtn}`}
        onClick={onHide}
      >
        Back
      </button>
    </div>
  );
};

export default NoEditErrorModal;
