import { useAppDispatch, useAppSelector } from "src/store/store";
import styles from "./SelectTicketCheckBox.module.scss";
import {
  isMergedTicketIdSelectedSelector,
  removeSelectedMergeTicketById,
  selectMergeTicketById
} from "src/store/slices/innerTicket/innerTicket.slice";
function SelectTicketCheckBox({ ticketId }: any) {
  const isTicketSelected = useAppSelector((state) =>
    isMergedTicketIdSelectedSelector(state, ticketId)
  );
  const scopes = useAppSelector(
    (state) => state.globals.currentUserData?.scopes
  );

  const dispatch = useAppDispatch();

  const handleOnChangeCheckbox = (e: any) => {
    if (isTicketSelected) {
      dispatch(removeSelectedMergeTicketById(ticketId));
    } else {
      dispatch(selectMergeTicketById(ticketId));
    }
  };
  return (
    <span className="d-flex flex-column justify-content-center">
      <div className={`${styles.root} d-flex`}>
        <div>
          <div className="form-check" style={{ cursor: "pointer" }}>
            {scopes && scopes.includes("bulk_edit") && (
              <input
                data-lpignore="true"
                className="form-check-input ignoreClick"
                style={{
                  width: " 20px",
                  height: "20px",
                  borderRadius: "6px",
                  border: "1px solid #707070",
                  cursor: "pointer"
                }}
                type="checkbox"
                value=""
                id="flexCheckDefault"
                checked={isTicketSelected}
                onChange={handleOnChangeCheckbox}
              />
            )}
          </div>
        </div>
        <div className={`${styles["allTicketsTbody"]}`}>
          <div className={`row m-auto`}>
            <div className={`col-md-12`}>
              <p className={`${styles["tbody"]} `}>
                <span className={`${styles.ticketId}`}>{ticketId}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </span>
  );
}

export default SelectTicketCheckBox;
