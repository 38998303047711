import React, { useState, useRef, useEffect, useMemo } from "react";
import { OverlayTrigger, Popover, Spinner, Tooltip } from "react-bootstrap";
import styles from "./Timeline.module.scss";
import icon from "src/assets/images/timelineIcon.png";
import hoveredBugIcon from "src/assets/images/bugIconHover.svg";
import hoveredEyeIcon from "src/assets/images/EyeHover.svg";
import hoveredUserIcon from "src/assets/images/userIconHover.svg";
import hoveredRefundIcon from "src/assets/images/refundIconHover.svg";
import activityIconHover from "src/assets/images/activityIconHover.svg";
import bugIcon from "src/assets/images/bugIcon.svg";
import eyeIcon from "src/assets/images/Eye.svg";
import userIcon from "src/assets/images/userIcon.svg";
import refundIcon from "src/assets/images/refundIcon.svg";
import activityIcon from "src/assets/images/activityIcon.svg";
import { v4 as uuid } from "uuid";
import { formatMDYTimeStringDate } from "src/utils/dateLibrary";
import { EventDetail } from "src/store/slices/shopifySidebar/shopifySidebar.slice";
import clock from "src/assets/images/temClock.png";
import blackDot from "src/assets/images/blackDot.svg";
import { getTimelineSubEvents } from "src/services/ShopifySidebar/EditCustomerDetails/getTimelineSubEvents";
import { useQuery } from "@tanstack/react-query";
import { useAppSelector } from "src/store/store";

interface TimelineProps extends EventDetail {
  date: string | null;
  icon: string;
  description: string;
  name: string;
  eventType: string;
  expanded: boolean;
  bgColor: string;
  index: number;
  cursor?: string | null;
  customerId: string | number;
}

function getIconForTimeline(timelineIcon: string) {
  if (timelineIcon === "eye") {
    return eyeIcon;
  } else if (timelineIcon === "user") {
    return userIcon;
  } else if (timelineIcon === "activity") {
    return activityIcon;
  } else if (timelineIcon === "bug") {
    return bugIcon;
  } else if (timelineIcon === "refund") {
    return refundIcon;
  } else {
    return icon;
  }
}

function getHoveredIconForTimeline(timelineIcon: string) {
  if (timelineIcon === "eye") {
    return hoveredEyeIcon;
  } else if (timelineIcon === "user") {
    return hoveredUserIcon;
  } else if (timelineIcon === "activity") {
    return activityIconHover;
  } else if (timelineIcon === "bug") {
    return hoveredBugIcon;
  } else if (timelineIcon === "refund") {
    return hoveredRefundIcon;
  } else {
    return icon;
  }
}

const Timeline: React.FC<TimelineProps> = ({
  date,
  icon,
  description,
  name,
  eventType,
  expanded,
  subEvents,
  ticketId,
  type,
  position,
  bgColor,
  index,
  cursor,
  customerId,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const target = useRef(null);
  const [currentCursor, setCurrentCursor] = useState(cursor);
  const [hasError, setHasError] = useState(false);

  const [events, setEvents] = useState(subEvents);

  const {
    data,
    status,
    isError,
    refetch,
    isFetching: isLoading,
    // isLoading,
  } = useQuery(
    ["customer/timelineLoadMoreEvents", customerId, currentCursor],
    () => getTimelineSubEvents({ customerId, cursor: currentCursor ?? null }),
    {
      enabled: false, // Disable automatic query execution
      cacheTime: 60000,
      staleTime: 60000,
    },
  );

  const userTimezone = useAppSelector(
    (state) => state.globals.currentUserData?.userTimezone,
  );

  const mergeEvents = (newEvents: any) => {
    if (!Array.isArray(newEvents)) {
      return;
    }
    setEvents((prevEvents) => {
      const combinedEvents = [...prevEvents, ...newEvents];
      const uniqueEvents = combinedEvents.reduce((acc, event) => {
        const uniqueKey = `${event.type}-${event.id}`;
        if (!acc.some((e: any) => `${e.type}-${e.id}` === uniqueKey)) {
          acc.push(event);
        }
        return acc;
      }, []);

      return uniqueEvents;
    });
  };

  useEffect(() => {
    if (data) {
      setCurrentCursor(data.cursor);
      mergeEvents(data.event);
    }
  }, [data]);

  const handleRedirect = () => {
    if (ticketId) {
      window.open(`/tickets/all/ticket-view/ticket/${ticketId}/`);
    }
  };

  const renderContent = () => {
    try {
      return (
        <div
          className={`${styles.timelineContainer} ${
            position === "bottom" ? styles.bottom : ""
          } ${eventType === "sub" ? styles.subEvent : ""} ${
            expanded ? styles.expanded : styles.collapsed
          }`}
        >
          {eventType === "sub" ? (
            <div
              ref={target}
              onMouseOver={() => setShowTooltip(true)}
              onMouseOut={() => setShowTooltip(false)}
            >
              <OverlayTrigger
                trigger="hover"
                placement={"bottom"}
                show={showTooltip}
                overlay={
                  <Popover
                    id="popover-basic"
                    bsPrefix={`popover ${styles.popverWrapper}`}
                  >
                    <Popover.Body bsPrefix={`popover-body ${styles.popOver}`}>
                      <div className={`${styles.overlay} p-2`}>
                        <span
                          className={`${styles.overlayName}`}
                          dangerouslySetInnerHTML={{ __html: name }}
                        ></span>
                        <div
                          className={`overflow-auto p-1 ${styles.popOverScroll}`}
                        >
                          {events?.map((subEvent) => (
                            <div
                              className="d-flex align-items-center"
                              key={subEvent.id + "_" + subEvent.type}
                            >
                              <span>
                                <img
                                  src={clock}
                                  alt="clock"
                                  className={`${styles.clockImg}`}
                                />
                              </span>
                              <span className={`ms-1 ${styles.overlaytime}`}>
                                {subEvent.datetime
                                  ? formatMDYTimeStringDate(
                                      subEvent.datetime,
                                      userTimezone,
                                    )
                                  : ""}
                              </span>
                            </div>
                          ))}
                          {isError ? (
                            <span className={`${styles.error} text-danger`}>
                              Error loading more events
                            </span>
                          ) : currentCursor ? (
                            <div>
                              <span
                                className={`${styles.showMoreButton} me-2 ${styles.floatLeft}`}
                                style={{}}
                                onClick={() => refetch()}
                              >
                                Show More{" "}
                              </span>
                              {isLoading && (
                                <Spinner
                                  className={`m-auto ${styles.floatLeft}`}
                                  animation="border"
                                  size="sm"
                                />
                              )}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </Popover.Body>
                  </Popover>
                }
              >
                <span>
                  <div
                    className={`${styles.blackDot} d-flex align-items-center`}
                  >
                    <img
                      src={blackDot}
                      alt="info"
                      className={`cursor-pointer ${
                        expanded ? styles.expandImg : styles.normalImg
                      }`}
                    />
                  </div>
                </span>
              </OverlayTrigger>
            </div>
          ) : (
            <>
              {expanded ? (
                <div
                  className={`d-flex flex-column  justify-content-center ${styles.collapseUi}`}
                >
                  <div
                    className={`${styles.timelineBox} position-relative`}
                    style={{
                      border: bgColor,
                      backgroundColor: bgColor,
                    }}
                    ref={target}
                    onMouseOver={() => setShowTooltip(true)}
                    onMouseOut={() => setShowTooltip(false)}
                  >
                    <OverlayTrigger
                      trigger="hover"
                      placement="right"
                      show={showTooltip}
                      overlay={
                        <Tooltip
                          className={`${styles.toolTipCustom}`}
                          onClick={handleRedirect}
                        >
                          <div className={`${styles.overlay} p-2`}>
                            <div className="d-flex">
                              <span>
                                <img
                                  src={getHoveredIconForTimeline(icon)}
                                  alt="icon"
                                  className={`${styles.icon}`}
                                />
                              </span>
                              <span
                                className={`ps-2 ${styles.overlayName}`}
                                style={{
                                  color: bgColor,
                                }}
                              >
                                {name}
                              </span>
                            </div>

                            <div
                              className={`overflow-auto p-1 ${styles.popOverScroll}`}
                            >
                              {events?.map((subEvent) => (
                                <span
                                  className={`${styles.overlaytime}`}
                                  key={subEvent.id + "_" + subEvent.type}
                                >
                                  {subEvent.description}
                                </span>
                              ))}
                              {isError ? (
                                <span className={`${styles.error} text-danger`}>
                                  Error loading more events
                                </span>
                              ) : currentCursor ? (
                                <div>
                                  <span
                                    className={`${styles.showMoreButton} me-2 ${styles.floatLeft}`}
                                    style={{}}
                                    onClick={() => refetch()}
                                  >
                                    Show More{" "}
                                  </span>
                                  {isLoading && (
                                    <Spinner
                                      className={`m-auto ${styles.floatLeft}`}
                                      animation="border"
                                      size="sm"
                                    />
                                  )}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </Tooltip>
                      }
                    >
                      <div className="">
                        <img
                          src={getIconForTimeline(icon)}
                          alt="icon"
                          className={`${styles.icon}`}
                        />
                        <span className={`${styles.eventCount}`}>
                          {subEvents.length ? `+${subEvents.length}` : null}
                        </span>
                      </div>
                    </OverlayTrigger>
                  </div>
                  <div
                    className={`d-flex flex-column align-items-center ${styles.barWrapper}`}
                  >
                    <div
                      style={{
                        border: bgColor,
                        backgroundColor: bgColor,
                      }}
                      className={`${styles.bar}`}
                    ></div>
                    <div
                      style={{
                        border: bgColor,
                        backgroundColor: bgColor,
                      }}
                      className={`${styles.bar}`}
                    ></div>
                    <div
                      style={{
                        border: bgColor,
                        backgroundColor: bgColor,
                      }}
                      className={`${styles.bar}`}
                    ></div>
                  </div>
                  {/* <div
                    className={`${styles.collapseDot} ${styles.flexEnd}`}
                    style={{
                      border: index == 0 ? "1px solid #6D6D6D" : bgColor,
                    }}
                  ></div> */}
                </div>
              ) : (
                <div className="d-flex align-items-center">
                  <div
                    className={`${styles.timelineBox} position-relative`}
                    style={{
                      border: bgColor,
                      backgroundColor: bgColor,
                    }}
                    ref={target}
                    onMouseOver={() => setShowTooltip(true)}
                    onMouseOut={() => setShowTooltip(false)}
                  >
                    <OverlayTrigger
                      trigger="hover"
                      placement="right"
                      show={showTooltip}
                      overlay={
                        <Tooltip
                          onClick={handleRedirect}
                          className={`${styles.toolTipCustom}`}
                        >
                          <div className={`${styles.overlay} p-2`}>
                            <div className="d-flex">
                              <span>
                                <img
                                  src={getHoveredIconForTimeline(icon)}
                                  alt="icon"
                                  className={`${styles.icon}`}
                                />
                              </span>
                              <span className={`ps-2 ${styles.overlayName}`}>
                                {name}
                              </span>
                            </div>

                            <div
                              className={`overflow-auto p-1 ${styles.popOverScroll}`}
                            >
                              {events?.map((subEvent) => (
                                <span
                                  className={`${styles.overlaytime}`}
                                  key={subEvent.id + "_" + subEvent.type}
                                >
                                  {subEvent.description}
                                </span>
                              ))}
                              {isError ? (
                                <span className={`${styles.error} text-danger`}>
                                  Error loading more events
                                </span>
                              ) : currentCursor ? (
                                <div>
                                  <span
                                    className={`${styles.showMoreButton} me-2 ${styles.floatLeft}`}
                                    style={{}}
                                    onClick={() => refetch()}
                                  >
                                    Show More{" "}
                                  </span>
                                  {isLoading && (
                                    <Spinner
                                      className={`m-auto ${styles.floatLeft}`}
                                      animation="border"
                                      size="sm"
                                    />
                                  )}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </Tooltip>
                      }
                    >
                      <div className="">
                        <img
                          src={getIconForTimeline(icon)}
                          alt="icon"
                          className={`${styles.icon}`}
                        />
                        <span className={`${styles.eventCount}`}>
                          {subEvents.length ? `+${subEvents.length}` : null}
                        </span>
                      </div>
                    </OverlayTrigger>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      );
    } catch (error) {
      console.error("Error rendering Timeline component:", error);
      setHasError(true);
      return (
        <div className={`text-center ${styles.errorMsg}`}>
          Failed to load the resource
        </div>
      );
    }
  };

  return hasError ? <div>Failed to load the resource</div> : renderContent();
};

export default Timeline;
