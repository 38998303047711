import { axiosJSON } from "src/globals/axiosEndPoints";

interface ResendUserInviteParams {
  userId: number;
}

export async function resendUserInvite(params: ResendUserInviteParams) {
  const { data: res } = await axiosJSON.post(
    "/api/setting/user/resendInvite",
    params
  );

  if (res.error === true) {
    throw new Error(res.message as string);
  }

  return res.data as any;
}
