import { useEffect, useMemo, useState } from "react";
import { Dropdown, OverlayTrigger, Popover } from "react-bootstrap";
import Select from "react-select";
import { CustomDropdownIndicator } from "src/routes/Setting/children/MainSetting/children/SetRulesNew/childerns/childern/AddAutomations/AddAutomationStep1/AddAutomationStep1";
import { ReturnReason } from "src/store/slices/initiateReturn/step14Configuration.slice";
import { useAppSelector } from "src/store/store";
import { extractObjectFromEvent } from "../../ContentSection/VariableSelect";
import styles from "./FollowUpQuestion.module.scss";

interface Props {
  reasonId?: string | null;
  error?: string;
  elementId?: string;
  operator?: string;
  value?: string;
  condition: ReturnReason;
  onChange: (value: ReturnReason["values"]["return_reason"]) => void;
}
const operators = [
  { label: "is", value: "=" },
  { label: "is not", value: "!=" },
];

function FollowUpQuestion({
  reasonId,
  elementId,
  operator,
  value,
  error,
  onChange,
  condition,
}: Props) {
  const [selectedQuestion, setSelectedQuestion] = useState(null as any);
  const [selectedValue, setSelectedValue] = useState(null as any);

  const { returnReasonList, returnReasonAjaxStatus } = useAppSelector(
    (state) => state.returnExchange,
  );
  let operatorIndex;
  if (operator !== undefined) {
    operatorIndex = operators.findIndex((value) => {
      return value.label === operator;
    });
  }
  const { selectedReason, followUpQuestions } = useMemo(() => {
    let followUpQuestions: any = [];
    let selectedReason = null;
    if (reasonId) {
      selectedReason = returnReasonList[reasonId];

      if (selectedReason) {
        followUpQuestions = selectedReason.moreQuestions.map(
          (moreQuestion, index) => {
            return {
              label: (
                <div>
                  <strong className={`${styles.labelQuestion}`}>
                    Follow up question #{index + 1}
                  </strong>
                  <p className={`mb-0 ${styles.labelText}`}>
                    {moreQuestion.elementValue}
                  </p>
                </div>
              ),
              value: moreQuestion.elementId,
            };
          },
        );
      }
    }

    return {
      selectedReason,
      followUpQuestions,
    };
  }, [reasonId, returnReasonList]);

  const questionValues = useMemo(() => {
    if (selectedReason && selectedQuestion) {
      let question = selectedReason.moreQuestions.find(
        (moreQuestion) => moreQuestion.elementId === selectedQuestion?.value,
      );
      if (question) {
        return question.additionalOptions.map((additionalOption) => {
          return {
            label: additionalOption.value,
            value: additionalOption.id,
          };
        });
      }
    }
  }, [selectedReason, selectedQuestion]);

  const selectedFollowUp = useMemo(() => {
    if (selectedReason && condition.values.return_reason?.elementId) {
      return selectedReason.moreQuestions.find(
        (option) =>
          option.elementId === condition.values.return_reason?.elementId,
      );
    }
    return null;
  }, [selectedReason, condition.values]);

  useEffect(() => {
    if (selectedReason && condition.values.return_reason?.elementId) {
      let indexValue = 1;
      const selectedFollowUp = selectedReason.moreQuestions.find(
        (option, index) => {
          if (option.elementId === condition.values.return_reason?.elementId) {
            indexValue = index;
            return true;
          }
          return false;
        },
      );
      if (selectedFollowUp) {
        setSelectedQuestion({
          label: (
            <div className="p-1">
              <span className={`${styles.labelQuestion}`}>
                Follow up question #{indexValue + 1}
              </span>
              <p className={`${styles.labelText}`}>
                {selectedFollowUp.elementValue}
              </p>
            </div>
          ),
          value: selectedFollowUp.elementId,
        });
      } else {
        setSelectedQuestion(null);
      }
    } else {
      setSelectedQuestion(null);
    }
  }, [selectedReason, condition.values.return_reason?.elementId]);

  useEffect(() => {
    if (questionValues && condition.values.return_reason?.value) {
      const questionValue = questionValues.find(
        (option) => option.value === condition.values.return_reason?.value,
      );
      if (questionValue) {
        setSelectedValue(questionValue);
      } else {
        setSelectedValue(null);
      }
    } else {
      setSelectedValue(null);
    }
  }, [questionValues, condition.values.return_reason?.value]);
  const [menuOpen, setMenuOpen] = useState(false);
  const [valueMenuOpen, setValueMenuOpen] = useState(false);

  const [showPopover, setShowPopover] = useState(true);
  useEffect(() => {
    if (error) {
      setShowPopover(true);
    } else {
      setShowPopover(false);
    }
  }, [error]);
  const popover =
    error && error.length > 0 ? (
      <Popover
        id="popover-basic"
        className="rounded-3"
      >
        <Popover.Body className="d-flex flex-row">
          <div className="border px-2 bg-warning text-white me-1 rounded-3 ">
            !
          </div>
          <div className="mt-1">{error}</div>
        </Popover.Body>
      </Popover>
    ) : (
      <></>
    );

  if (!selectedReason || selectedReason.moreQuestions.length === 0) {
    return null;
  } else {
    return (
      <div className="mt-2">
        <span className={`${styles.followUpQuestion}`}>
          Choose a follow up question for this return reason :
        </span>
        <div
          className={`d-flex align-items-md-center flex-column flex-lg-row w-100 w-md-75 mt-1`}
        >
          <OverlayTrigger
            show={!selectedQuestion && showPopover}
            placement="bottom"
            overlay={popover}
            rootClose={true}
            onToggle={(show) => {
              if (!menuOpen) {
                setShowPopover(show);
              }
            }}
            // onToggle={(show) => {
            //   setShowPopover(show);
            // }}
            // onExit={(e: any) => {
            //   setShowPopover(false);
            // }}
            rootCloseEvent="mousedown"
          >
            <div
              className={`${styles.thirdSelect}`}
              style={{
                maxWidth: "20rem",
                minWidth: "16rem",
              }}
              onClick={() => setShowPopover(false)}
            >
              <Select
                placeholder={"Select follow up question"}
                isSearchable={false}
                styles={selectStyles}
                value={selectedQuestion}
                formatOptionLabel={({ label }) => <div>{label}</div>}
                isMulti={false}
                name="return_reasons"
                options={followUpQuestions}
                className={`basic-multi-select ${styles.selectReason} ${
                  !selectedQuestion && error ? styles.errorBorder : ""
                }`}
                classNamePrefix="select"
                isClearable={false}
                onChange={(e) =>
                  onChange({
                    ...condition.values.return_reason,
                    elementId: e.value ?? "",
                  })
                }
                onMenuClose={() => {
                  setMenuOpen(false);
                }}
                onMenuOpen={() => {
                  setMenuOpen(true);
                }}
                isLoading={returnReasonAjaxStatus === "pending"}
                components={{
                  DropdownIndicator: CustomDropdownIndicator, // Custom component for rendering the dropdown indicator
                  IndicatorSeparator: null, // Custom component for rendering the indicator separator (null to remove it)
                }}
              />
            </div>
          </OverlayTrigger>

          {/* Showing additional options dropdown only when the additional options exists */}
          {selectedReason &&
            selectedFollowUp &&
            selectedFollowUp.additionalOptions &&
            selectedFollowUp.additionalOptions?.length > 0 && (
              <>
                <span className={`${styles.answer} ps-2 `}>Answer </span>
                {selectedQuestion ? (
                  <>
                    <div>
                      <Dropdown>
                        {/* I have replaced the Form Select with Bootstrap Toggle because in Form Select we are using option tag and option is not customizable */}
                        <Dropdown.Toggle
                          className={`ms-0 ms-lg-2 my-2 my-lg-0 ${styles.operatorSelect} `}
                          value={operator}
                        >
                          {operatorIndex !== undefined &&
                          operatorIndex !== null &&
                          operatorIndex !== -1
                            ? operators[operatorIndex].label
                            : operators[0].label}
                        </Dropdown.Toggle>
                        {/* Rendering Options  */}
                        <Dropdown.Menu
                          className={`${styles.mainSelectMenu} mt-1`}
                        >
                          {operators.map((operator: any) => {
                            return (
                              <Dropdown.Item
                                style={{
                                  maxWidth: "11rem",
                                  // minWidth: "10rem",
                                  maxHeight: "2.5rem",
                                }}
                                value={operator.value}
                                onClick={(e) => {
                                  const event = extractObjectFromEvent(e); // This function is used to extract an object from an event.
                                  onChange({
                                    ...condition.values.return_reason,
                                    operator: event.target.value,
                                  });
                                  // props.pushCurrentHash(props.id);
                                }}
                              >
                                {operator.label}
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <OverlayTrigger
                      show={!selectedValue && showPopover}
                      placement="bottom"
                      overlay={popover}
                      rootClose={true}
                      onToggle={(show) => {
                        if (!valueMenuOpen) {
                          setShowPopover(show);
                        }
                      }}
                      // onToggle={(show) => {
                      //   setShowPopover(show);
                      // }}
                      // onExit={(e: any) => {
                      //   setShowPopover(false);
                      // }}
                      rootCloseEvent="mousedown"
                    >
                      <div
                        className={`ms-lg-3 ${styles.thirdSelect}`}
                        style={{
                          maxWidth: "20rem",
                          minWidth: "16rem",
                        }}
                        onClick={() => setShowPopover(false)}
                      >
                        <Select
                          placeholder={"Select answer"}
                          isSearchable={false}
                          value={selectedValue}
                          styles={selectStyles}
                          isMulti={false}
                          options={questionValues}
                          className={` basic-multi-select ${
                            styles.selectAnswer
                          } ${
                            !selectedValue && error ? styles.errorBorder : ""
                          }`}
                          classNamePrefix="select"
                          isClearable={false}
                          onChange={(e) =>
                            onChange({
                              ...condition.values.return_reason,
                              value: e.value ?? "",
                            })
                          }
                          isLoading={returnReasonAjaxStatus === "pending"}
                          onMenuClose={() => {
                            setValueMenuOpen(false);
                          }}
                          onMenuOpen={() => {
                            setValueMenuOpen(true);
                          }}
                          components={{
                            DropdownIndicator: CustomDropdownIndicator, // Custom component for rendering the dropdown indicator
                            IndicatorSeparator: null, // Custom component for rendering the indicator separator (null to remove it)
                          }}
                        />
                      </div>
                    </OverlayTrigger>
                  </>
                ) : null}
              </>
            )}
        </div>
      </div>
    );
  }
}
//Dec 19 added customStyles as per xd
const selectStyles = {
  control: (provided: any) => ({
    ...provided,
    border: "1px solid #707070", // Add border style here
    borderRadius: "5px", // Add border-radius here
    minHeight: "37px",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#f5f5f5" : "#fff", // Change the hover color here
    "&:hover": {
      backgroundColor: "#f5f5f5", // Change the hover color here as well
    },
    padding: "10px",
    color: "black",
  }),
  menuList: (provided: any) => ({
    ...provided,
    margin: "0 5px", // Add 5px margin on the left and right sides of the menu
  }),
  menu: (provided: any) => ({
    ...provided,
    width: "100%",
  }),
  placeholder: (provided: any) => ({
    ...provided,
    font: "normal normal normal 14px/21px Poppins",
    letterSpacing: "0px",
    color: "#707070",
  }),
};
export default FollowUpQuestion;
