import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";
import { AutomationAPIPayload } from "../createAutomation.service";
import {
  AvailableDispatchChannel,
  DispatchChannels,
  UserAgent,
} from "./getAllAgents.service";

export interface DispatchRuleTeamData {
  teamId: string;
  teamName: string;
  userDetails: Omit<UserAgent, "teamId">[];
}

interface Payload {
  integrationId: string;
  start: number;
  limit: number;
}

export const getTeamsDataService = async ({
  payload,
  type,
}: AutomationAPIPayload<Payload>) => {
  const apiEndPoint = IS_TEST_ACTIVE
    ? `${process.env.REACT_APP_TEST_SITE_URL}/bugManagement/getTeamsData`
    : `/api/automation/${type}/getTeamsData`;

  const { data: res } = await axiosJSON.post(apiEndPoint, payload);

  if (res.err || res.error) {
    throw res.msg || res.message || "Cannot delete the dispatch rule";
  }

  const ret: DispatchRuleTeamData[] = [];
  res.data.forEach((data: DispatchRuleTeamData) => {
    if (data.userDetails.length === 0) {
      return;
    }

    const userDetails = data.userDetails.map(
      (user: DispatchRuleTeamData["userDetails"][0]) => {
        let availableChannels: AvailableDispatchChannel[] = [];
        if (user.email) {
          availableChannels.push({
            channelId: DispatchChannels.Email,
            channelName: "Email",
          });
        }

        if (user.phone) {
          availableChannels.push({
            channelId: DispatchChannels.SMS,
            channelName: "SMS",
          });
        }

        return {
          ...user,
          id: user.id + "",
          availableChannels,
        };
      },
    );

    ret.push({ ...data, userDetails });
  });

  return ret;
};
