import { useNavigate, useParams } from "react-router-dom";
import PreviewSection from "./Children/Components/PrevieSection/PreviewSection";
import styles from "./TicketRouteSetting.module.scss";
import { useAutomationProvider } from "../Hooks/useAutomationContext";
import AutomationSteps from "../AutomationTabSettings/Children/ConfigureSteps/AutomationSteps";
import AxiosImg from "src/components/AxiosImg";
import UserAvatar from "src/components/UserAvatar";
import { useCallback, useState } from "react";
import { Spinner } from "react-bootstrap";
import Delete from "src/assets/images/deleteAutomation.png";
import { useDeleteAutomation } from "../Hooks/useAutomationManager";

const TicketRouteSetting = () => {
  const navigate = useNavigate();
  const { Provider: ConfigProvider, ...contextValues } =
    useAutomationProvider();
  const { integrationId } = useParams();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const onDelete = () => {
    navigate(`/automations/ticketRouting`);
  };
  const { deleteAutomation, isLoading: isDeleting } =
    useDeleteAutomation(onDelete);

  const handleDelete = useCallback(() => {
    if (integrationId != "-1") deleteAutomation(integrationId ?? "");
  }, [deleteAutomation, integrationId]);

  return (
    <div className={styles.wrapper}>
      <ConfigProvider value={contextValues}>
        <div className={`d-flex mb-3 m-e ms-1 flex-wrap ${styles.mainDiv}`}>
          {/* Left Side Section */}
          <div className={`col-12 col-md-8 ${styles.leftSideSection} p-4`}>
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex">
                <div
                  onClick={() => navigate("/automations/ticketRouting")}
                  className={`d-flex justify-content-center align-items-center ${styles.backChat}`}
                >
                  <i className="fa-solid fa-arrow-left"></i>
                </div>

                <div className="ps-2">
                  <h4 className={`${styles.botHeading}`}>Ticket Routing</h4>
                </div>
              </div>
              <div className="d-flex">
                <div
                  className={`${styles.brandBox} d-flex align-items-center justify-content-center me-2`}
                >
                  <span
                    className={`ps-1 ${styles.brandText} d-flex align-items-center justify-content-center`}
                  >
                    {contextValues?.formData?.brand ? (
                      <>
                        {/* rendering brand image here, if exist, else rendering brand name image */}
                        {contextValues?.formData?.brand?.imgURL ? (
                          <AxiosImg
                            className={`${styles.brandImage} rounded-circle`}
                            url={contextValues?.formData?.brand?.imgURL}
                          />
                        ) : (
                          <UserAvatar
                            name={contextValues?.formData?.brand?.name ?? "NA"}
                            size={20}
                          />
                        )}
                        <span className="ms-2">
                          {contextValues?.formData?.brand?.name ?? "NA"}
                        </span>
                      </>
                    ) : (
                      "---"
                    )}
                  </span>
                </div>
                <div
                  className="ms-auto me-0"
                  onClick={handleDelete}
                >
                  <span
                    className={`d-flex justify-content-center align-items-center cursor-pointer ${styles.deleteBtn}`}
                  >
                    {isDeleting ? (
                      <Spinner
                        className="my-auto mx-2"
                        animation="border"
                        size="sm"
                      />
                    ) : (
                      <img
                        src={Delete}
                        alt=" "
                        className="me-2"
                      />
                    )}
                    Delete automation
                  </span>
                </div>
              </div>
            </div>

            <div className={`mt-1 mb-3 ${styles.subText}`}>
              Automate ticket routing for your tickets
            </div>
            <div className={styles.leftSectionWrapper}>
              <div className={`${styles.description}`}>
                Please provide us with the following details to set up an
                automation system for ticket routing.
                <span className={`ms-2 ${styles.seeWorkFlow}`}>
                  See workflow
                </span>
              </div>
              <AutomationSteps
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
                showQuestionNumber={false}
              />
            </div>
          </div>
          {/* Right Side Section */}
          <div className={`col-12 col-md-4 ${styles.rightSideSection}`}>
            <PreviewSection activeIndex={activeIndex} />
          </div>
        </div>
      </ConfigProvider>
    </div>
  );
};

export default TicketRouteSetting;
