import { Fragment } from "react";
import styles from "./TicketTags.module.scss";
import { useAppSelector } from "src/store/store";
import { selectTicketCellData } from "src/store/slices/tickets/tickets.slice";
import { TableCellProps } from "..";

// function TicketTags({ ticket }: { ticket: Ticket }) {
function TicketTags({ ticketId, colKeys }: TableCellProps) {
  const ticketCellData: any = useAppSelector((state) =>
    selectTicketCellData(state, ticketId, colKeys)
  );
  return (
    <div className={`${styles.tagsParent}`}>
      <div
        className={`${styles["ticketTags"]} ${
          ticketCellData[0] ? (ticketCellData[0].length > 2 ? styles.hover : "") : ""
        }`}
      >
        {ticketCellData[0] ? (
          ticketCellData[0].map(
            (tag: { id: number; tagName: string }, key: number) => (
              <Fragment key={tag.id}>
                <div
                  className={`max-content-width mx-0 ${styles["returnOrder"]} ${
                    key === 1 ? "d-inline-block" : ""
                  }`}
                >
                  {tag.tagName}
                </div>
                {ticketCellData[0].length > 2 && key === 1 && (
                  <span className={`${styles.dot3}`}>...</span>
                )}
              </Fragment>
            )
          )
        ) : (
          <span className={`p-2 ${styles.na}`}> NA </span>
        )}
      </div>
    </div>
  );
}

export default TicketTags;
