import { useCallback, useEffect, useRef, useState } from "react";
import { Button, Form, Overlay } from "react-bootstrap";
import { useParams } from "react-router-dom";
import {
  fetchInnerTicketMessages,
  resetInnerTicketMessage,
  sendMessages,
  setActiveTicketInfoFromTicketData,
  setInnerTicketActive
} from "src/store/slices/innerTicket/innerTicket.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import styles from './MessageBox.module.scss';
import { v4 as uuid } from "uuid";
import CannedSendingAs , {ESendTypes} from "../CannedSendingAs";
import TinyMCEContainer from "src/components/TinyMCEContainer";
// import MailSendingAs ,{ESendTypes} from "../CannedSendingAs";
interface Props {
  msgHead : string;
  messageText: string;
  setMessageText: any
  showWarning?: boolean
}

function MessageBox({msgHead,messageText, setMessageText, showWarning = false} :Props) {
  const dispatch = useAppDispatch();
  // const sendingDetailsRef: any = useRef({});
  // const target = useRef(null);
  // const overlayRef = useRef(null);
  const formRef: any = useRef(null);
  // const [showOverlay, setShowOverlay] = useState(false);
  // const { tableType, ticketId, viewType }: any = useParams();
  // const [showDraftModal, setShowDraftModal] = useState(false);
  // const canAddDraft = useAppSelector(
  //   (state) => state.innerTicket.activeTicketInfo.can_add_new_draft
  // );
  // const sendStatus = useAppSelector(
  //   (state) => state.innerTicket.sendMessageAjaxStatus
  // );
  // const ticketStatusList = useAppSelector(
  //   (state) => state.innerTicket.ticketStatusList
  // );
  // const [sendType, setSendType] = useState(1);
  // const clearData = useCallback(() => {
  //   try {
  //     sendingDetailsRef.current.clearData();
  //   } catch {}
  //   setMessage("");
  // }, []);
  // const sendMessage = useCallback(
  //   (e: any, status = "Open") => {
  //     e.preventDefault();
  //     if (sendStatus !== "pending") {
  //       dispatch(
  //         sendMessages({
  //           ticketId,
  //           to: formRef.current.elements.to
  //             ? formRef.current.elements.to.value.trim()
  //             : "",
  //           cc: formRef.current.elements.cc
  //             ? formRef.current.elements.cc.value.trim()
  //             : "",
  //           bcc: formRef.current.elements.bcc
  //             ? formRef.current.elements.bcc.value.trim()
  //             : "",
  //           message: formRef.current.elements.message
  //             ? formRef.current.elements.message.value.trim()
  //             : "",
  //           messageType: ESendTypes[sendType] as any,
  //           sendAs: status
  //         })
  //       );
  //       setTimeout(() => {
  //         clearData();
  //       }, 0);
  //     }
  //   },
  //   [ticketId, sendStatus, sendType]
  // );

  // const onShowDraftModal = useCallback(() => {
  //   setShowDraftModal(true);
  // }, []);
  // const onHideDraftModal = useCallback(() => {
  //   setShowDraftModal(false);
  //   setMessage("");
  //   dispatch(resetInnerTicketMessage());
  //   dispatch(setActiveTicketInfoFromTicketData({ ticketId }));
  //   dispatch(fetchInnerTicketMessages());
  // }, []);

  // function selectedTemplateHandler(template: any) {
  //   console.log("hahah " + template);
  // }

  return (
    <div className={`mb-2 mx-auto w-100`}>
      <div className={`mb-1`}>
        <span className={`${styles.msgText}`}>{msgHead}</span>
      </div>
      <div className={`${styles.sendMessage} ${showWarning ? "redBorder rounded" : ""}`}>
        <Form 
          ref={formRef}
        >
         {/* Commented on 24/06/2022 as required unique id */}
          <TinyMCEContainer
            name="message"
            // editorClassName={`${styles.editor}`}
            value={messageText}
            onChange={(value) => {
              setMessageText(value);
            }}
            options={{
              placeholder:"Type message",
              min_height: 120,
              max_height: 120
            }}
            uniqueID={uuid()}
          />
        </Form>
      </div>
      {showWarning === true && (
        <span className={`${styles.errorMsg}`}> *The message text cannot be blank</span>
        )}
    </div>
  );
}

export default MessageBox;
