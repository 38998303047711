import { axiosJSON } from "src/globals/axiosEndPoints";

export const getBugCategory = async (params: {start: number, limit: number}) => {
  const { data: res } = await axiosJSON.post(
    `/api/selfService/bugManagement/getAllCategory`,
    params,
  );

  if (res.err || res.error) {
    throw res.msg ?? res.message ?? "Something went wrong!";
  }

  const ret = normalizeData(res, params.limit);

  return ret;
};

export type AllCategoryNormalizedData = {
  allBugCategory: Record<string, { id: string; name: string }>;
  allBugCategoryIds: number[];
  metaData?: {
    limitReached: boolean;
  };
};


// convert incoming data to the required format
function normalizeData(response: any, limit: number): AllCategoryNormalizedData {
  const statusesList = response.data
  const normalizedData: AllCategoryNormalizedData = {
    allBugCategory: {},
    allBugCategoryIds: [],
    metaData:{
      limitReached: false
    }
  };
  statusesList.forEach((category: any) => {
    normalizedData.allBugCategory[`${category.id}`] = {
      id: category.id,
      name: category.categoryName,
    };
    normalizedData.allBugCategoryIds.push(category.id);
  });
  if (!isNaN(limit)) {
    if (normalizedData.allBugCategoryIds.length < limit && normalizedData.metaData) {
      normalizedData.metaData.limitReached = true;
    }
  } 
  return normalizedData
}