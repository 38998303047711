export enum EReturnAutomationQuestionKeys {
  // segment 1
  returnWindow = "returnWindow", // question 1, previously it was question 3
  returnNotEligibleResponseMessage = "returnNotEligibleResponseMessage", // question 2

  isReturnPolicySameForAllOrders = "isReturnPolicySameForAllOrders", // question 3
  isReturnPolicySameForAllItem = "isReturnPolicySameForAllItem", // question 4

  // not needed v3
  canItemNotEligibleForReturn = "canItemNotEligibleForReturn", // question 4

  // segment 2
  resolutionOffer = "resolutionOffer", // question 5
  resolutionOfferMessage = "resolutionOfferMessage", // sub question of question 5
  isResolutionOfferedSameForAllOrders = "isResolutionOfferedSameForAllOrders", // question 6

  // segment 3
  selectReturnReason = "selectReturnReason", // question 7
  returnReasonList = "retunReasonList", // question 8
  isReturnReasonSameForAll = "isReturnReasonSameForAll", // question 9

  // segment 4
  // new question added in v3
  returnMethodForMajorityOfOrders = "returnMethodForMajorityOfOrders", // question 10
  returnMethodDisplayMessage = "returnMethodDisplayMessage", // question 11

  // in v4
  antiReturnStrategies = "antiReturnStrategies", // question 10
  configureAntiReturnStrategies = "configureAntiReturnStrategies", // question 11
  isReturnReasonOrFollowUpQuestionsSame = "isReturnReasonOrFollowUpQuestionsSame", // question 12

  // updated in v3
  isReturnMethodSameForAllOrders = "isReturnMethodSameForAllOrders", // question 12

  // new step in v3, order summary
  // question 13
  isRestockingFeeChargedForMajorityOrders = "isRestockingFeeChargedForMajorityOrders",
  // question 14
  usuallyChargedRestockingFee = "usuallyChargedRestockingFee",
  // question 15
  restockingFeeDisplayMessage = "restockingFeeDisplayMessage",
  // question 16
  isRestockingFeeSameForAll = "isRestockingFeeSameForAll",
  storeCurrency = "storeCurrency",

  // isReturnPolicySameForAllItem = "isReturnPolicySameForAllItem", // question 1
  // isReturnPolicySameForAllOrders = "isReturnPolicySameForAllOrders", // question 2
  // returnWindow = "returnWindow", // question 3
  // canItemNotEligibleForReturn = "canItemNotEligibleForReturn", // question 4

  // resolutionOffer = "resolutionOffer", // question 5
  // isResolutionOfferedSameForAllOrders = "isResolutionOfferedSameForAllOrders", // question 6

  // selectReturnReason = "selectReturnReason", // question 7
  // isReturnReasonSameForAll = "isReturnReasonSameForAll", // question 8
  // isReturnMethodSameForAllOrders = "isReturnMethodSameForAllOrders", // question 9
}

// May 31 Questions which need to be hided
export const EReturnAutomationHiddenKeys: Array<string> = [
  "isReturnPolicySameForAllOrders", // question 3
  "isReturnPolicySameForAllItem", // question 4
  "isResolutionOfferedSameForAllOrders", // question 7
  "isReturnMethodSameForAllOrders", // question 13
  "isRestockingFeeSameForAll", // question 17
];
