import { axiosJSON } from "src/globals/axiosEndPoints";

export interface IDDList {
  [key: number]: {
    name: string;
    count: number;
  };
}
export interface IDivDropDowns {
  name: string;
  content: IDDList;
}
export interface ITicketDivs {
  name: string;
  count: number;
  content: Array<IDivDropDowns>;
}
export interface ITicketsSB {
  name: string;
  content: Array<ITicketDivs>;
}

export async function getTicketSideBarApi(ignoreCache = false) {
  const { data }: { data: Array<ITicketsSB> } = await axiosJSON.get(
    `/api/ticket/sidebar`,
    { params: { cacheIt: ignoreCache ? false : true, cacheTime: 30000 } }
  );
  return data;
}
