import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";

const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/botProfile/delete`
  : "/api/botProfile/delete";

export interface BotProfileDeleteParams {
  id: number | string;
}

export interface BotProfileDeleteResponse {
  bot_profile_id: number;
}

export const deleteBotProfile = async (params: BotProfileDeleteParams) => {
  const { data: res } = await axiosJSON.delete(apiEndPoint, { params });
  if (res.err) {
    throw res.msg;
  }

  return res as BotProfileDeleteResponse;
};
