import { useCallback, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { ReasonFollowUpQuestion } from "src/features/ReturnAutoWorkFlow/children/ConfigModals/store/slices/ReturnReasonOrderAndItems/returnReasonOrderAndItems.slice";
import { useReturnAutoWorkFlow } from "src/features/ReturnAutoWorkFlow/hooks/useReturnAutoWorkFlow";
import QuestionModal, {
  QuestionModalProps,
} from "../QuestionModal/QuestionModal";
import styles from "./AskQuestion.module.scss";

interface AskQuestionProps extends Omit<QuestionModalProps, "onHide"> {
  setMoreQuestionModalHandler: (
    moreQuestions: ReasonFollowUpQuestion[],
    reasonId: string,
    reasonTypeId: string,
  ) => void;
  isFollowUpModal?: boolean;
}

const AskQuestion = ({
  reason,
  reasonTypeId,
  pushCurrentHash,
  isFollowUpModal = false,
  setMoreQuestionModalHandler,
  ...props
}: AskQuestionProps) => {
  const [showModal, setShowModal] = useState(false);
  const { dispatch: modalDispatch } = useReturnAutoWorkFlow();
  // Getting the more questions of the current reason.
  const { moreQuestions } = useMemo(() => reason.reason, [reason]);

  const reasonId = useMemo(() => reason.id, [reason]);

  //Get the Number of questions in the reason
  const noOfQuestions = useMemo(
    () => reason.reason.moreQuestions.length,
    [reason],
  );

  const onShow = useCallback(
    (e: any) => {
      e.stopPropagation();
      setShowModal(true);

      // Need to save the data of active more question in moreQuestionsModal state before rendering the questions to edit on modal.
      setMoreQuestionModalHandler(moreQuestions, reasonId, reasonTypeId);
      /**
       * Setting active submodal data in the context
       */
      modalDispatch("setState", {
        activeSubModalConfigDetails: {
          name: "moreQuestions",
          reasonId: reasonId,
        },
      });
    },
    [
      modalDispatch,
      moreQuestions,
      reasonId,
      reasonTypeId,
      setMoreQuestionModalHandler,
    ],
  );

  const onHide = useCallback(() => {
    setShowModal(false);
    /**
     * Setting active submodal data in the context
     */
    modalDispatch("setState", {
      activeSubModalConfigDetails: null,
    });
  }, [modalDispatch]);

  return (
    <div>
      <button
        className={`ms-2 px-3 ${styles.askbtn} ${
          isFollowUpModal ? styles.btnWidth : ""
        } d-flex align-items-center`}
        onClick={onShow}
      >
        {noOfQuestions > 0 ? (
          <>
            <span className={`${styles.checkLogo} mx-1 px-1`}>
              <i className="fa-solid fa-check"></i>
            </span>
            <span className="pe-2">{noOfQuestions} more questions</span>
          </>
        ) : (
          <span className="pe-2">+ Ask more questions</span>
        )}
        {/* Dec 15 changed arrow as per xd */}
        <span className={`${styles.rightArrow}`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="7.648"
            height="4.939"
            viewBox="0 0 7.648 4.939"
          >
            <path
              id="Icon_awesome-angle-down"
              data-name="Icon awesome-angle-down"
              d="M3.416,15.511.165,12.26a.571.571,0,0,1,0-.81l.54-.54a.571.571,0,0,1,.81,0l2.3,2.3,2.3-2.3a.571.571,0,0,1,.81,0l.54.54a.571.571,0,0,1,0,.81L4.225,15.511a.569.569,0,0,1-.808,0Z"
              transform="translate(0.004 -10.74)"
              fill="#fff"
            ></path>
          </svg>
        </span>
      </button>
      <Modal
        backdropClassName={`${styles.modalBack}`}
        show={showModal}
        onHide={onHide}
        dialogClassName={`${styles.modalDialog}`}
        contentClassName={`${styles.modalContent}`}
        className={`${styles.backDrop}`}
        key={reason.id}
        enforceFocus={false} // Added becuase in return preview textboxes are not focusing
      >
        <QuestionModal
          key={reason.id}
          reason={reason}
          reasonTypeId={reasonTypeId}
          onHide={onHide}
          pushCurrentHash={pushCurrentHash}
          {...props}
        />
      </Modal>
    </div>
  );
};

export default AskQuestion;
