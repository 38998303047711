import { useCallback } from "react";
import styles from "./AddMention.module.scss";
import { useNavigate } from "react-router-dom";
import { subSBLink } from "src/services/TicketService/linkGenerator";
import { useAppSelector } from "src/store/store";

const AddMention = () => {
  const navigate = useNavigate();
  const currentUser = useAppSelector((state) => state.globals.currentUserData);
  const { selectedBtn, unreadMentionsCount } = useAppSelector(
    (state) => state.ticketSideBar
  );
  const onMentionClick = useCallback((e: any) => {
    e.preventDefault();
    navigate(
      subSBLink({
        tableType: "mentions",
        search: window.location.search,
        pathName: window.location.pathname,
      })
    );
  }, []);

  return (
    <div
      className={`d-flex justify-content-between align-items-center w-100 mt-3 ${
        styles.mentionWrapper
      } ${selectedBtn === "mentions" ? styles.mentionSelected : ""}`}
      onClick={onMentionClick}
      id="addMentionWrapper"
    >
      <span
        className={`text-truncate ${styles.mention} ${
          selectedBtn === "mentions" ? styles.activeMention : ""
        }`}
      >
        @{currentUser?.firstName}
      </span>
      <span className={`${styles.mentionCount}`}>{unreadMentionsCount}</span>
    </div>
  );
};

export default AddMention;
