import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";
import { AutomationAPIPayload } from "./createAutomation.service";

export interface GetAutomationBrandsPayload {
  start: number;
  limit: number;
}

export interface Brand {
  id: string;
  name: string;
  email: string;
  imageURL?: string | null;
}

export interface GetAutomationBrandsResponse {
  brandIds: string[];
  brands: Record<string, Brand>;
  metaData: {
    hasMore: boolean;
    currentCount: number;
  };
}

/**
 * This service is used to get the KB brands data.
 */
export const getLinkableBrandsAutomation = async ({
  type,
  payload,
}: AutomationAPIPayload<GetAutomationBrandsPayload>) => {
  const apiEndPoint = IS_TEST_ACTIVE
    ? `${process.env.REACT_APP_TEST_SITE_URL}/${type}/getLinkableBrands`
    : `/api/automation/${type}/getLinkableBrands`;

  const { data: res } = await axiosJSON.post(apiEndPoint, payload);

  const ret: GetAutomationBrandsResponse = {
    brandIds: [],
    brands: {},
    metaData: {
      currentCount: 0,
      hasMore: false,
    },
  };

  res.data.forEach((item: Brand) => {
    const id = item.id + "";
    ret.brands[id] = item;
    ret.brandIds.push(id);
  });

  if (ret.brandIds.length < payload.limit) {
    ret.metaData.hasMore = false;
  } else {
    ret.metaData.hasMore = true;
  }

  ret.metaData.currentCount = payload.start + ret.brandIds.length + 1;

  return ret as GetAutomationBrandsResponse;
};
