import { useEffect, useMemo } from "react";
import { ProgressBar } from "react-bootstrap";

import ListStep, {
  IListStep,
} from "src/routes/Home/ToDoList/ListStep/ListStep";
import SetupCard from "./SetupCard/SetupCard";

import { SetupDetails } from "src/services/Home/getTodoList.service";
import useToDoList, { useCreateToDoListContext } from "./hooks/useToDoList";

import styles from "./ToDoList.module.scss";
import { useAppSelector } from "src/store/store";

interface Props {
  setupData: SetupDetails;
}

/**
 * Helper Function to get the current active step from steps list.
 * @param steps Array of steps
 * @returns Active Step number or first step
 */
const getActiveStep = (steps: IListStep[]) => {
  // Set default step
  let activeStep = 1;

  // Loop over all steps
  for (const step of steps) {
    // Assign current step to active step
    activeStep = step.number;

    // If current step is not done
    if (!step.done) {
      // Stop iteration
      break;
    }
  }

  // Return the active step
  return activeStep;
};

const SetupList = () => {
  const { setupData } = useToDoList();

  const uiVisibility = useAppSelector(
    (state) => state.globals.currentUserData?.ui_visibility,
  );

  // Memoized value to calculate if ai setup is completed or not
  const trainingBotCompleted = useMemo(
    () =>
      uiVisibility?.includes("chat_bot_view")
        ? setupData?.trainingBotSetup.isCompleted ?? false
        : true,
    [setupData?.trainingBotSetup.isCompleted, uiVisibility],
  );

  // Memoized value to calculate if ai setup is completed or not
  const trainingEmailBotCompleted = useMemo(
    () =>
      uiVisibility?.includes("email_bot_enabled")
        ? setupData?.trainingBotSetup.emailBotIsCompleted ?? false
        : true,
    [setupData?.trainingBotSetup.emailBotIsCompleted, uiVisibility],
  );

  // Memoized steps for email integration setup
  const emailsteps: IListStep[] = useMemo(() => {
    const steps = [
      {
        number: 1,
        title: "Add E-mail",
        // Check if Email setup is completed
        done: !setupData?.emailSetup?.isCompleted
          ? setupData?.emailSetup?.setupData?.isEmailAdded ?? false
          : true,
      },
      {
        number: 2,
        title: "Incoming mail settings",
        done: !setupData?.emailSetup?.isCompleted
          ? setupData?.emailSetup?.setupData?.isEmailVerified ?? false
          : true,
      },
      {
        number: 3,
        title: "Outgoing mail settings",
        done: !setupData?.emailSetup?.isCompleted
          ? setupData?.emailSetup?.setupData?.isDomainVerified ?? false
          : true,
      },
      {
        number: 4,
        title: "Authenticated!",
        done: !setupData?.emailSetup?.isCompleted
          ? setupData?.emailSetup?.setupData?.isDomainVerified ?? false
          : true,
      },
    ];

    // Check if we can show the Setup AI CERN for email bot
    if (
      uiVisibility &&
      uiVisibility.includes("chat_bot_view") &&
      uiVisibility.includes("email_bot_enabled")
    ) {
      steps.push({
        number: 5,
        title: "Setup AI cern",
        done: trainingEmailBotCompleted,
      });
    }
    return steps;
  }, [
    setupData?.emailSetup?.isCompleted,
    setupData?.emailSetup?.setupData?.isDomainVerified,
    setupData?.emailSetup?.setupData?.isEmailAdded,
    setupData?.emailSetup?.setupData?.isEmailVerified,
    trainingEmailBotCompleted,
    uiVisibility,
  ]);

  // Memoized value to calculate if step 1 for live chat is completed
  const storeDetailsCompleted: boolean = useMemo(() => {
    if (!setupData?.liveChatSetup?.setupData) {
      return false;
    }

    return (
      setupData?.liveChatSetup.setupData?.store_url !== "" &&
      setupData?.liveChatSetup.setupData?.brand.email !== "" &&
      setupData?.liveChatSetup.setupData?.website_name !== "" &&
      setupData?.liveChatSetup.setupData?.store_url !== window.origin
    );
  }, [setupData?.liveChatSetup.setupData]);

  // Memoized steps for live chat integration setup
  const liveChatsteps: IListStep[] = useMemo(() => {
    const steps = [
      {
        number: 1,
        title: "Store details",
        done: !setupData?.liveChatSetup.isCompleted
          ? storeDetailsCompleted
          : true,
      },
      {
        number: 2,
        title: "Copy code and embed to website",
        done: !setupData?.liveChatSetup.isCompleted
          ? storeDetailsCompleted &&
            (setupData?.liveChatSetup?.setupData?.installed_successfully ??
              false)
          : true,
      },
      {
        number: 3,
        title: "Check installation on your store",
        done: !setupData?.liveChatSetup.isCompleted
          ? storeDetailsCompleted &&
            (setupData?.liveChatSetup?.setupData?.installed_successfully ??
              false)
          : true,
      },
      {
        number: 4,
        title: "Complete!",
        done: !setupData?.liveChatSetup.isCompleted
          ? storeDetailsCompleted &&
            (setupData?.liveChatSetup?.setupData?.installed_successfully ??
              false)
          : true,
      },
    ];

    if (uiVisibility?.includes("chat_bot_view")) {
      steps.push({
        number: 5,
        title: "Setup AI cern",
        done: trainingBotCompleted,
      });
    }
    return steps;
  }, [
    setupData?.liveChatSetup.isCompleted,
    setupData?.liveChatSetup?.setupData?.installed_successfully,
    storeDetailsCompleted,
    trainingBotCompleted,
    uiVisibility,
  ]);

  // Memoized value to calculate to-do progress
  const progress: number = useMemo(() => {
    // Initalize completed steps
    let completedSteps = 0;

    // Check if email setup is completed
    if (
      setupData?.emailSetup.isCompleted ||
      setupData?.emailSetup.setupData?.isDomainVerified
    ) {
      completedSteps++;
    }

    // Check if live chat setup is completed
    if (
      setupData?.liveChatSetup.isCompleted ||
      setupData?.liveChatSetup.setupData?.installed_successfully
    ) {
      completedSteps++;
    }

    // Check if ai bot setup is completed
    if (trainingBotCompleted) {
      completedSteps++;
    }
    return (completedSteps / 3) * 100;
  }, [
    setupData?.emailSetup.isCompleted,
    setupData?.emailSetup.setupData?.isDomainVerified,
    setupData?.liveChatSetup.isCompleted,
    setupData?.liveChatSetup.setupData?.installed_successfully,
    trainingBotCompleted,
  ]);

  /**
   * Memoized value for active email integration step
   */
  const activeEmailStep = useMemo(
    () => getActiveStep(emailsteps),
    [emailsteps],
  );

  /**
   * Memoized value for active live chat step
   */
  const activeLiveChatStep = useMemo(
    () => getActiveStep(liveChatsteps),
    [liveChatsteps],
  );

  return (
    <div
      className={`mt-5 ${styles.todoWrapper}`}
      id="todoWrapper"
    >
      {/* Header */}
      <div className="d-flex align-items-center mb-2">
        <h3 className="mb-0 saufter_h3">To Do List</h3>
        <div className="ms-2 progressToDoWrapper">
          <ProgressBar
            className="progressBar"
            now={progress}
          />
        </div>
      </div>

      {/* To-do List */}
      <div
        className={`${styles.todoBox}`}
        id="emailSetupWrapper"
      >
        <ListStep
          id="emailSetup"
          steps={emailsteps}
          activeStep={activeEmailStep}
          integrationId={setupData?.emailSetup.setupData?.emailIntegrationId}
          botProfileId={setupData?.trainingBotSetup?.emailBotProfileId ?? ""}
        />
        <ListStep
          id="liveChatSetup"
          steps={liveChatsteps}
          activeStep={activeLiveChatStep}
          integrationId={setupData?.liveChatSetup.setupData?.integration_id}
          botProfileId={setupData?.trainingBotSetup?.botProfileId}
        />
      </div>
    </div>
  );
};

const ToDoList = ({ setupData: data }: Props) => {
  const { setupData, setSetupData, Provider } = useCreateToDoListContext();
  const currentUserData = useAppSelector(
    (state) => state.globals.currentUserData,
  );

  /**
   * Update the setup data
   */
  useEffect(() => {
    setSetupData(data);
  }, [data, setSetupData]);
  return (
    <Provider value={{ setupData, setSetupData }}>
      <div
        className={`d-flex flex-column flex-md-row  ${
          currentUserData?.ui_visibility?.includes("chat_bot_view")
            ? "justify-content-between"
            : "justify-content-start"
        }`}
      >
        {/* Email Setup Card */}
        <SetupCard id="emailSetup" />

        {/* Bot setup Card */}
        {currentUserData?.ui_visibility?.includes("chat_bot_view") ? (
          <SetupCard
            id="botSetup"
            goto={
              !setupData?.emailSetup.isCompleted
                ? "emailSetup"
                : "liveChatSetup"
            }
          />
        ) : null}

        {/* Live Chat Card */}
        <SetupCard id="liveChatSetup" />
      </div>
      <SetupList />
    </Provider>
  );
};

export default ToDoList;
