import { useCallback, useEffect, useRef, useState } from "react";
import InfiniteScroll from "src/components/InfiniteScroll";
import Loader from "src/components/Loader";
import {
  fetchAllTheTags,
  resetTagsSettings,
  setTagsSearchValue,
} from "src/store/slices/settings/tags/tagsSettings.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import NoTags from "../NoTags/NoTags";
import TagRow from "../TagRow/TagRow";
import styles from "./ShowTags.module.scss";
import { v4 as uuidv4 } from "uuid";
import useDebounce from "src/hooks/useDebounce";
import { Spinner } from "react-bootstrap";

function ShowTags() {
  const dispatch = useAppDispatch();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const debouncedSearchTerm = useDebounce(searchTerm, 2000);
  const inputSearchRef: any = useRef(null);
  const { tags, tagIdList, metaData, tagsAjaxStatus } = useAppSelector(
    (state) => state.tagsSettings
  );

  const totalTagsRef: any = useRef(metaData.total);
  useEffect(() => {
    totalTagsRef.current = metaData.total;
  }, [metaData.total]);

  useEffect(() => {
    dispatch(resetTagsSettings());
  }, []);

  /* method to handle infinite scroll */
  const handleInfiniteScroll = useCallback(() => {
    if (totalTagsRef.current !== 0) {
      if (totalTagsRef.current > 0) {
        dispatch(fetchAllTheTags());
      }
    }
  }, [metaData.total]);

  useEffect(() => {
    handleSearchTags();
    if (inputSearchRef.current) {
      inputSearchRef.current.blur();
    }
  }, [debouncedSearchTerm]);

  const handleSearchTags = () => {
    dispatch(resetTagsSettings());
    dispatch(setTagsSearchValue({ searchValue: searchTerm }));
    dispatch(fetchAllTheTags());
  };

  return (
    <>
      {/* Fluctuation of ui due to col system. So it is hide. 22/10/2022 */}
      {/* <div className="col-12"> */}
      <form className={`${styles.users}`}>
        <div className="mb-3">
          <div className={`${styles.search} d-flex`}>
            <i className={`${styles.fontAwsomeIcon} fa fa-search `} />
            <input
              type="text"
              className={`${styles.form_control}`}
              placeholder="Search Tags"
              ref={inputSearchRef}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
      </form>
      {/* </div> */}
      {/* <div className="col-12"> */}
      <div className={`overflow-auto w-100`}>
        <InfiniteScroll
          className={`${styles.tags_table}  `}
          loadMore={handleInfiniteScroll}
          loader={
            tagsAjaxStatus === "pending" && tagIdList.length === 0 ? (
              <div className={`d-flex flex-column m-auto ${styles.loading}`}>
                <Loader />
                <span className="text-center">Fetching tags...</span>
              </div>
            ) : (
              <div className="p-1">
                <Spinner animation="border" variant="secondary" />
              </div>
            )
          }
          errorMessage={
            metaData.total === 0 &&
            (tagsAjaxStatus === "rejected" ||
              tagsAjaxStatus === "fulfilled") ? (
              <NoTags />
            ) : undefined
          }
          initialDataLoaded={false}
          // hasMore={tagIdList.length < metaData.total || metaData.total === 0}
          hasMore={
            tagsAjaxStatus === "rejected"
              ? false
              : metaData.total === 0
              ? true
              : tagIdList.length < metaData.total
          }
        >
          {/* <table
            className={` table table-borderless ${styles.table_borderless} `}
          >
            <thead>
              <tr className="d-flex  justify-content-between w-100">
                <th style={{ width: "45%" }}>Tag Name</th>
                <th style={{ width: "45%" }}>Description</th>
                <th className="" style={{ width: "10%" }}>
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {tagIdList.map((tagId, index) => {
                const tagData = tags[tagId];
                return <TagRow key={uuidv4()} tagData={tagData} />;
              })}
            </tbody>
          </table> */}
          <div className="w-100 d-flex py-3">
            <div className={`${styles.tagName}`}>Tag Name</div>
            <div className={`${styles.descName}`}>Description</div>
            <div className={`${styles.actionBox}`}> Actions</div>
          </div>
          {tagIdList.map((tagId, index) => {
            const tagData = tags[tagId];
            return <TagRow key={uuidv4()} tagData={tagData} />;
          })}
        </InfiniteScroll>
      </div>
      {/* </div> */}
    </>
  );
}

export default ShowTags;
