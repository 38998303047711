import { useCallback, useEffect, useState } from "react";

interface Props {
  customizationData?: {
    topbarHeadingText: string;
    topbarSubHeadingText: string;
    statusTextWhenTeamOnline: {
      readonly: string;
      value: string;
    };
    statusTextWhenTeamOffline: {
      readonly: string;
      value: string;
    };
    autoreplayText: string;
    minimizedWindowColour: string;
    liveChatThemeColor: string;
    showOnlineStatusText: boolean;
    showAutoReplyText: boolean;
    selectedWidgetPosition: "left" | "right";
    submitButtonText?: string;
  };
  knowledgeBasedData?: any;
  askForEmailData?: {
    offlineMessageText?: string | null;
    offlineConfirmationText?: string | null;
    messageText?: string | null;
    confirmationText?: string | null;
    showOfflineData: boolean;
    canSendMessage?: boolean | null;
    canCustomerLeaveEmail?: boolean | null;
  };
  formData?: {
    elements: any;
    submitButtonText: string;
    formType?: string;
  };
}

function useFinalChat({
  customizationData,
  knowledgeBasedData,
  formData,
  askForEmailData,
}: Props) {
  const [customization, setCustomization] = useState(customizationData);
  const [knowledgeBased, setKnowledgeBased] = useState(knowledgeBasedData);
  const [chatFormData, setChatFormData] = useState(formData);

  const [isActive, setActive] = useState(false);
  const handleToggle = () => {
    setActive(!isActive);
  };

  // const dispatch = useAppDispatch();

  //ask for email section
  const [showConfirmationText, setShowConfirmationText] = useState(false);
  const [showOfflineConfirmationText, setShowOfflineConfirmationText] =
    useState(false);
  const [showSubmitedFormPreview, setShowSubmitedFormPreview] = useState(false);
  const [inputEmailForAskEmail, setInputEmailForAskEmail] = useState("");

  useEffect(() => {
    if (askForEmailData !== null) {
      setShowConfirmationText(false);
      setShowOfflineConfirmationText(false);
    }
  }, [askForEmailData]);

  useEffect(() => {
    if (customizationData !== null) {
      setCustomization(customizationData);
    }
    if (knowledgeBasedData !== null) {
      setKnowledgeBased(knowledgeBasedData);
    }
    if (formData !== null) {
      if (showSubmitedFormPreview === true) {
        setShowSubmitedFormPreview(false);
      }
      setChatFormData(formData);
    }
  }, [customizationData, knowledgeBasedData, formData]);

  const handleFormsSubmit = useCallback((e: any) => {
    e.preventDefault();
    if (formData?.formType === "postChatForm") {
      setShowSubmitedFormPreview(true);
    }
  }, []);

  const getPostChatFormCommentText = useCallback(() => {
    let leaveCommentLabel: string =
      "Thank you for the rating! You can also leave acomment:";
    chatFormData?.elements.forEach((element: any) => {
      if (element?.elementType === "chatRating" && element?.commentLabelValue) {
        leaveCommentLabel = element.commentLabelValue;
      }
    });

    return leaveCommentLabel;
  }, []);

  //helper function returns featured article text
  const getArticleText = useCallback(
    (featuredArticles: any, indexNum: number) => {
      const featuredArticle = featuredArticles.find(
        (elem: any, index: number) => index === indexNum
      );

      if (featuredArticle?.shortTitle) {
        return featuredArticle.shortTitle;
      }

      return featuredArticle?.articleName;
    },
    []
  );

  return {
    customization,
    setCustomization,
    knowledgeBased,
    setKnowledgeBased,
    chatFormData,
    setChatFormData,
    handleToggle,
    isActive,
    showConfirmationText,
    setShowConfirmationText,
    showOfflineConfirmationText,
    setShowOfflineConfirmationText,
    showSubmitedFormPreview,
    setShowSubmitedFormPreview,
    handleFormsSubmit,
    getPostChatFormCommentText,
    getArticleText,
    askForEmailData,
    inputEmailForAskEmail,
    setInputEmailForAskEmail,
  };
}

export default useFinalChat;
