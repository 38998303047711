import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useRef } from "react";
import { TinyMCEEditorProps } from "src/interfaces/TinyMCEContainer/ITinyMCEContainer";
import { fetchAllUsers } from "src/services/UserService/fetchAllUsers";

import { Editor as TinyMceEditor } from "tinymce";
import { AvatarURL } from "src/components/UserAvatar/UserAvatar";

interface Props {
  editorRef: React.MutableRefObject<TinyMceEditor | null>;
  classNameProfileImg: string;
  mentionsEnabled: boolean;
}

function useMentionsHandler({
  editorRef,
  classNameProfileImg,
  mentionsEnabled,
}: Props) {
  const { data: users } = useQuery(["userData/getAllUsers", "FOR/Mentions"], {
    queryFn: async () => {
      const users: Array<{
        id: string;
        email: string;
        imageUrl?: string | null;
        name?: string | null;
        status: string;
      }> = await fetchAllUsers({ start: 0, limit: 1000 }, true);
      return users;
    },
    staleTime: 600000,
    cacheTime: 600000,
  });
  const currentState = useRef({ mentionsEnabled, users });

  useMemo(() => {
    currentState.current.mentionsEnabled = mentionsEnabled;
    currentState.current.users = users;
  }, [mentionsEnabled, users]);

  useEffect(() => {
    const editor = editorRef.current;
    if (editor) {
      const onAction = (autocompleteApi: any, rng: Range, value: any) => {
        editorRef.current?.selection.setRng(rng);
        value = JSON.parse(value);
        editorRef.current?.insertContent(
          `
            <span
              class="mceNonEditable"
              style="
                color: #0b68bb;
                background-color: #dbebf9;
                border-radius: 7px;
                font-size: 12px;
                padding: 0px 4px;
                outline: none;
                cursor: default;
                display: inline-block;
              "
              data-agent-mention-id="${value.id}"
            >
              @${value.name ?? value.email}
            </span>
          `
        );
        autocompleteApi.hide();
      };

      editor.ui.registry.addAutocompleter(`specialchars_@`, {
        trigger: "@",
        minChars: 0,
        columns: 1,
        highlightOn: ["char_name"],
        onAction: onAction,
        fetch: async (
          pattern: string,
          maxResults: number,
          fetchOptions: Record<string, any>
        ) => {
          const results = (
            currentState.current.users && currentState.current.mentionsEnabled
              ? currentState.current.users
              : []
          )
            .filter(
              (user) =>
                (user.name ?? user.email)
                  .toLowerCase()
                  .search(pattern.toLowerCase()) !== -1 || pattern === ""
            )
            .map((user) => ({
              type: "cardmenuitem",
              value: JSON.stringify(user),
              label: user.name ?? user.email,
              items: [
                {
                  type: "cardcontainer",
                  direction: "horizontal",
                  items: [
                    {
                      type: "cardimage",
                      src:
                        user.imageUrl ??
                        AvatarURL({ name: user.name ?? user.email }),
                      alt: (user.name ?? user.email).slice(0, 1).toUpperCase(),
                      classes: [classNameProfileImg],
                    },
                    {
                      type: "cardtext",
                      text: user.name ?? user.email,
                    },
                  ],
                },
              ],
            }));
          return results as any;
        },
      });
    }
  }, [users?.length, editorRef.current]);

  return null;
}

export default useMentionsHandler;
