import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ReactContentEditable from "src/components/AutomationComponents/Other/Components/ReactContentEditable";

export interface NameDetails {
  orderName: string;
  sequence: number;
  id: string;
}

function TabName(props: {
  name: string | null | undefined;
  id: string;
  updateType: Function;
  pushCurrentHash: Function;
}) {
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip>{props.name}</Tooltip>}
    >
      {({ ref, ...triggerHandler }) => (
        <span
          {...triggerHandler}
          ref={ref}
        >
          <ReactContentEditable
            key={props.id}
            onContentChange={(value) => {
              props.updateType(props.id, {
                orderTypeName: value,
              });
            }}
            orderTypeName={props.name}
          />
        </span>
      )}
    </OverlayTrigger>
  );
}

export default TabName;
