import { IDraftFlow, IDraftMsg, initialState } from "./draftFlow.slice";

const resetDraftFlow = (state: IDraftFlow) => {
  state = { ...initialState };
  return state;
};
const setTicketId = (
  state: IDraftFlow,
  { payload: { ticketId } }: { payload: { ticketId: number | string } }
) => {
  state.ticketID = ticketId;
};

const setDraftMsg = (
  state: IDraftFlow,
  { payload }: { payload: Partial<IDraftMsg> }
) => {
  if (state.draftMsg !== null) {
    state.draftMsg = { ...state.draftMsg, ...payload };
  }
};
const setDraftFlow = (
  state: IDraftFlow,
  { payload }: { payload: Partial<IDraftFlow> }
) => {
  return { ...state, ...payload };
};

const setIsDraftEditing = (
  state: IDraftFlow,
  { payload }: { payload: Partial<IDraftFlow> }
) => {
  return { ...state, ...payload };
};

export default { resetDraftFlow, setTicketId, setDraftMsg, setDraftFlow, setIsDraftEditing };
