import React from "react";
import GoBackButton from "../GoBackButton/GoBackButton";
import NextStepButton from "../NextStepButton/NextStepButton";
import styles from "./Step2.module.scss";

function Step2() {
  return (
    <div
      className={`${styles.stepsContent} text-center justify-content-center  align-items-center`}
    >
      {/* Changed the text at both the places from xd */}
      <p className={`d-none d-md-block`}>
        <span className={`${styles.text}`}>
          Whenever a customer requests a return/exchange through your live chat
          widget they will go through a number of steps on their own that will
          help them initiate a return. <p>Press "Next Step" to continue.</p>
        </span>
      </p>
      <div className={`d-block d-md-none`}>
        <span className={`${styles.text}`}>
          Whenever a customer requests a return/exchange through your live chat
          widget they will go through a number of steps on their own that will
          help them initiate a return. <p>Press "Next Step" to continue.</p>
        </span>
      </div>
    </div>
  );
}

export default Step2;
