import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import getBugListByCategoryId from "src/services/BugReport/getBugListByCategoryId.service";
import { useBugManagementFilters } from "../../Hooks/useBugManagementFilter";
import useBugPriorityList from "../FilterSection/Priority/useBugPriorityList";
import useBugStatusList from "../FilterSection/StatusFilter/useBugStatusList";
import useBugFilterAndPagination from "../../useBugFilterAndPagination";

export interface PaginationParam {
  categoryId: number;
  start: number;
  limit: number;
  filter?: {
    bugStatusKeys?: Array<string>;
    priorityKeys?: Array<string>;
    pendingForAction?: boolean;
  };
}

function useBugCategoriesPagination(categoryId: number, pendingForAction: boolean) {
  const { allBugPriority } = useBugPriorityList();
  const { allBugStatus } = useBugStatusList();
  const { currentStatus } = useBugManagementFilters();

  // get selected status and priority to pass in the payload
  const selectedStatus = useMemo(() => {
    return currentStatus.status.map(
      (id) => allBugStatus?.allBugStatus[id]?.key ?? "",
    );
  }, [currentStatus.status]);

  const selectedPriority = useMemo(() => {
    return currentStatus.priority.map(
      (id) => allBugPriority?.allBugPriority[id].key ?? "",
    );
  }, [currentStatus.priority]);

  const initialPayload: PaginationParam = {
    categoryId,
    start: 0,
    limit: 10,
  };

  // adding selected filter status and priority to payload
  if (selectedStatus.length) {
    if(initialPayload.filter){
      initialPayload.filter.bugStatusKeys = [...selectedStatus];
    }
    else{
      initialPayload.filter = {};
      initialPayload.filter.bugStatusKeys = [...selectedStatus];
    }
  }
  
  if (selectedPriority.length) {
    if(initialPayload.filter){
      initialPayload.filter.priorityKeys = [...selectedPriority];
    }
    else{
      initialPayload.filter = {};
      initialPayload.filter.priorityKeys = [...selectedPriority];
    }
  }
  
  if (pendingForAction) {
    if(initialPayload.filter){
      initialPayload.filter.pendingForAction = true;
    }
    else{
      initialPayload.filter = {};
      initialPayload.filter.pendingForAction = true;
    }
  }

  const [payload, setPayload] = useState<PaginationParam>(initialPayload);

  const { data, isLoading, isError } = useInfiniteQuery({
    queryKey: ["getBugListByCategoryId", categoryId, payload],
    queryFn: ({ pageParam = payload }) => getBugListByCategoryId(pageParam),
    getNextPageParam: (lastPage, allPages) => {
      const data = allPages.flatMap((data: any) => data.data);
      if (data.length < lastPage.metaData?.totalCount) {
        const nextPageParam = {
          ...payload,
          start: data.length,
        };
        return nextPageParam;
      }
      return null;
    },
  });

  //Calculate the currentPage and totalPages for pagination
  const currentPage = Math.floor(payload.start! / payload.limit!) + 1;
  const totalPages = Math.ceil(
    (data?.pages[0]?.metaData?.totalCount ?? 0) / payload.limit!,
  );

  // Change the current page and trigger a refetch
  const changePage = (page: number) => {
    setPayload({
      ...payload,
      start: (page - 1) * payload.limit!,
    });
  };

  return {
    currentPage,
    totalPages,
    changePage,
    BugList: data?.pages[0],
    isLoading,
    isError,
  };
}

export default useBugCategoriesPagination;
