import styles from "./InnerChatShowDoc.module.scss";
import docIcon from "src/assets/images/attachmentTypeOtherDocs.png";
import { useState } from "react";
import DownloadFile from "src/components/DownloadFile";
import { Spinner } from "react-bootstrap";

const InnerChatShowDoc = ({ attachment, messageData }: any) => {
  const [loading, setLoading] = useState(false);

  return (
    <div className={`position-relative  ${styles.mainDiv}`}>
      <div className={`d-flex flex-row ${styles.cardDiv}`}>
        <img className={`${styles.iconImg}`} src={docIcon} />
      </div>
      <div
        className={`position-absolute top-0 text-center ${styles.downloadDiv}`}
      >
        <DownloadFile
          url={attachment.attachmentUrl}
          fileName={attachment.attachmentName}
          className={`btn p-0 m-3 ${styles.downloadBtn}`}
          loading={loading}
          setLoading={setLoading}
          stopPropagation={true}
        >
          <div className={` text-center ${styles.fileDownload}`}>
            {loading ? (
              <Spinner
                className={`m-auto`}
                animation="border"
                size="sm"
                color="grey"
              />
            ) : (
              <i
                className={`fas fa-cloud-download-alt p-1 ${styles.downloadIcon}`}
              ></i>
            )}
          </div>
        </DownloadFile>
      </div>
      <p className={`mt-1 ${styles.filename}`}>{attachment.attachmentName}</p>
    </div>
  );
};

export default InnerChatShowDoc;
