import { useCallback, useEffect, useMemo, useState } from "react";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { useReturnAutoWorkFlow } from "src/features/ReturnAutoWorkFlow/hooks/useReturnAutoWorkFlow";
import {
  ConditionTypes,
  conditionValues,
  IVariableName,
  operatorNames,
} from "src/features/ReturnAutoWorkFlow/children/ConfigModals/store/returnModalsSliceHelpers";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  addAdditionalCharge,
  addCondition,
  deleteCondition,
  pushCurrentHashForSelectedTab,
  restockingFeeErrorCountSelector,
  updateAdditionalCharge,
  updateCondition,
  updateFeesAmount,
  validateAdditionalCharge,
  validateChanges,
  validateCondition,
  updateSelectReasonCondition,
  deleteSelectReasonCondition,
  addSelectReasonCondition,
} from "../../store/slices/RestockingFeeSlice/restockingFee.slice";
import {
  fetchRestockingFeeModal,
  updateRestockingFeeModal,
  validateRestockingFeeConditions,
} from "../../store/slices/RestockingFeeSlice/restockingFee.thunk";

function useRestockingFeeModal({
  onSave,
}: {
  onSave: (isSaved: boolean) => void;
}) {
  const { returnAutoWorkFlow } = useReturnAutoWorkFlow();
  const dispatch = useAppDispatch();
  const {
    additionalCharges,
    selectedAdditionalChargeId,
    fetchAjaxStatus,
    updateAjaxStatus,
    initialHashOtherTab,
    currentHashOtherTab,
    conditionOptions: conditionOptionsRaw,
    defaultOptions: defaultOptionsRaw,
    itemOptions: itemOptionsRaw,
    selectReasonOptions,
  } = useAppSelector((state) => state.restockingFee);

  const [selectedAdditionalCharge, selectedAdditionalChargeIndex] =
    useMemo(() => {
      const index = additionalCharges.findIndex(
        (additionalCharge) =>
          additionalCharge.additionalChargeId === selectedAdditionalChargeId,
      );
      const additionalCharge =
        index !== -1 ? additionalCharges[index] : undefined;
      return [additionalCharge, index];
    }, [additionalCharges, selectedAdditionalChargeId]);

  const isAddBtnEnabled = useMemo(
    () => selectedAdditionalCharge?.isAddConditionEnabled ?? false,
    [selectedAdditionalCharge?.isAddConditionEnabled],
  );

  const isOrderTypeAddBtnEnabled = useMemo(
    () => selectedAdditionalCharge?.isAddOrderTypeConditionEnabled ?? false,
    [selectedAdditionalCharge?.isAddOrderTypeConditionEnabled],
  );

  const isAddBtnSelectReasonEnabled = useMemo(() => {
    const selectedAdditionalCharge = additionalCharges.find(
      (rtm) => rtm.additionalChargeId === selectedAdditionalChargeId,
    );

    return selectedAdditionalCharge?.isAddSelectReasonConditionEnabled ?? false;
  }, [additionalCharges, selectedAdditionalChargeId]);

  const itemOptions = useMemo(
    () => Object.values(itemOptionsRaw).map((option) => option.name),
    [itemOptionsRaw],
  );

  const conditionOptions = useMemo(
    () => Object.values(conditionOptionsRaw).map((option) => option.name),
    [conditionOptionsRaw],
  );

  const defaultOptions = useMemo(
    () => Object.values(defaultOptionsRaw).map((option) => option.name),
    [defaultOptionsRaw],
  );

  const returnReasonOptions = useMemo(
    () => Object.values(selectReasonOptions).map((option) => option.name),
    [selectReasonOptions],
  );

  const [otherTabsHasError, setOtherTabsHasError] = useState(false);

  const otherTabErrorCount = useAppSelector(restockingFeeErrorCountSelector);

  useEffect(() => {
    if (otherTabErrorCount <= 0) {
      setOtherTabsHasError(false);
    } else {
      setOtherTabsHasError(true);
    }
  }, [otherTabErrorCount]);

  useEffect(() => {
    if (returnAutoWorkFlow.integrationId) {
      dispatch(
        fetchRestockingFeeModal({
          integrationId: returnAutoWorkFlow.integrationId,
          // TODO
          isSelectReasonEnabled: false,
          // TODO
          restockingFee: { price: "", priceType: "" },
        }),
      );
    }
  }, [dispatch, returnAutoWorkFlow.integrationId]);

  const dispUpdateAdditionCharge = useCallback(
    (id: string, updateObj: any) => {
      dispatch(
        updateAdditionalCharge({
          additionalChargeDetail: {
            ...additionalCharges,
            additionalChargeId: id,
            ...updateObj,
          },
        }),
      );
      dispatch(validateChanges());
    },
    [additionalCharges, dispatch],
  );

  const dispPushCurrentHashForSelectedTab = useCallback(
    (id: string) => {
      dispatch(
        pushCurrentHashForSelectedTab({
          additionalChargeId: id,
        }),
      );
      dispatch(validateChanges());
    },
    [dispatch],
  );

  const dispAddTab = useCallback(
    (isSelectReasonEnabled: boolean) => {
      dispatch(addAdditionalCharge({ isSelectReasonEnabled }));
      dispatch(validateChanges());
    },
    [dispatch],
  );

  const dispValidateCondition = useCallback(
    (id: string) => {
      dispatch(
        validateCondition({
          additionalChargeId: id,
        }),
      );
      dispatch(validateChanges());
    },
    [dispatch],
  );

  useMemo(() => {
    if (selectedAdditionalChargeId && dispValidateCondition) {
      if (
        selectedAdditionalChargeIndex !== -1 &&
        selectedAdditionalChargeIndex !== 0
      )
        dispValidateCondition(selectedAdditionalChargeId);
    }
  }, [
    dispValidateCondition,
    selectedAdditionalChargeId,
    selectedAdditionalChargeIndex,
  ]);

  //function to update conditionType of a condition
  const dispUpdateConditionForVariables = useCallback(
    (
      id: string,
      e: { target: { value: string } },
      condition: ConditionTypes,
      isItem: boolean,
    ) => {
      dispatch(
        updateCondition({
          additionalChargeId: id,
          conditionToUpdate: {
            ...condition,
            variableName: e.target.value as IVariableName,
            operator: operatorNames[e.target.value as IVariableName][0],
            values: conditionValues[e.target.value as IVariableName],
          },
          isItemCondition: isItem,
        }),
      );
    },
    [dispatch],
  );

  //function to update operator of a condition
  const dispUpdateConditonForOperator = useCallback(
    (
      id: string,
      e: { target: { value: string } },
      condition: ConditionTypes,
      isItem: boolean,
    ) => {
      dispatch(
        updateCondition({
          additionalChargeId: id,
          conditionToUpdate: {
            ...condition,
            operator: e.target.value,
          },
          isItemCondition: isItem,
        }),
      );
    },
    [dispatch],
  );

  //function to update condition value by its value type
  const dispUpdateConditionForValues = useCallback(
    (
      id: string,
      type: string,
      value: any,
      condition: ConditionTypes,
      isItem: boolean,
    ) => {
      switch (type) {
        case "tags":
          dispatch(
            updateCondition({
              additionalChargeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  tags: {
                    currentValue: value,
                  },
                },
              },
              isItemCondition: isItem,
            }),
          );

          break;
        case "items":
          dispatch(
            updateCondition({
              additionalChargeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  items: {
                    currentValue: value,
                  },
                },
              },
              isItemCondition: isItem,
            }),
          );

          break;
        case "day_type":
          dispatch(
            updateCondition({
              additionalChargeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  day_type: {
                    currentValue: value,
                  },
                },
              },
              isItemCondition: isItem,
            }),
          );

          break;
        case "days":
          dispatch(
            updateCondition({
              additionalChargeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  days: {
                    currentValue: value,
                  },
                },
              },
              isItemCondition: isItem,
            }),
          );

          break;

        case "codes":
          dispatch(
            updateCondition({
              additionalChargeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  codes: {
                    currentValue: value,
                  },
                },
              },
              isItemCondition: isItem,
            }),
          );

          break;
        case "numbers":
          dispatch(
            updateCondition({
              additionalChargeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  numbers: {
                    currentValue: value,
                  },
                },
              },
              isItemCondition: isItem,
            }),
          );

          break;
        case "countryName":
          dispatch(
            updateCondition({
              additionalChargeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  countryName: {
                    currentValue: value,
                  },
                },
              },
              isItemCondition: isItem,
            }),
          );

          break;
        case "amount":
          dispatch(
            updateCondition({
              additionalChargeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  amount: {
                    currentValue: value,
                  },
                },
              },
              isItemCondition: isItem,
            }),
          );

          break;
        case "currency":
          dispatch(
            updateCondition({
              additionalChargeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  currency: {
                    currentValue: value,
                  },
                },
              },
              isItemCondition: isItem,
            }),
          );

          break;

        default:
          break;
      }
      dispatch(validateChanges());
    },
    [dispatch],
  );

  //function to delete a condition by its id
  const dispDeleteCondition = useCallback(
    (conId: string, id: string, isItem: boolean) => {
      dispatch(
        deleteCondition({
          conditionId: conId,
          additionalChargeId: id,
          isItemCondition: isItem,
        }),
      );
    },
    [dispatch],
  );

  const dispValidateAdditionalCharge = useCallback(
    (id: string) => {
      dispatch(
        validateAdditionalCharge({
          additionalChargeId: id,
        }),
      );
    },
    [dispatch],
  );

  //function to save/submit the configuration modal data
  const dispPostConfig = useCallback(async () => {
    if (!returnAutoWorkFlow.integrationId) {
      return;
    }

    //dispatching the validation thunk and
    // then if the validation passes then calling the submit api otherwise showing warning toast
    const res = await dispatch(validateRestockingFeeConditions());

    if (res.payload === 0) {
      dispatch(
        updateRestockingFeeModal({
          callback: () => {
            onSave(true);
          },
          // TODO
          isSelectReasonEnabled: false,
          integrationId: returnAutoWorkFlow.integrationId,
        }),
      );
    } else {
      pushTheToast({
        type: "warning",
        text: "Please fill the required fields!",
        position: "top-right",
      });
    }
  }, [dispatch, onSave, returnAutoWorkFlow.integrationId]);

  //function to add new condition for the passed tab id
  const dispAddCondition = useCallback(
    (id: string, isItem?: boolean, e?: { target: { value: string } }) => {
      dispatch(
        addCondition({
          additionalChargeId: id,
          condition: e ? e.target.value : "",
          isItemCondition: isItem,
        }),
      );
    },
    [dispatch],
  );

  const dispUpdateFeesAmount = useCallback(
    (value: number, id: string, type: string, priceType?: string) => {
      dispatch(
        updateFeesAmount({
          amount: value,
          selectedId: id,
          type,
          priceType,
        }),
      );
      dispatch(validateChanges());
    },
    [dispatch],
  );

  const dispReasonUpdateConditionForVariables = useCallback(
    (
      id: string,
      e: { target: { value: string } },
      condition: ConditionTypes,
    ) => {
      dispatch(
        updateSelectReasonCondition({
          additionalChargeId: id,
          conditionToUpdate: {
            ...condition,
            variableName: e.target.value as IVariableName,
            operator: operatorNames[e.target.value as IVariableName][0],
            values: conditionValues[e.target.value as IVariableName],
          },
        }),
      );
    },
    [dispatch],
  );

  //function to update operator of a condition
  const dispReasonUpdateConditonForOperator = useCallback(
    (
      id: string,
      e: { target: { value: string } },
      condition: ConditionTypes,
    ) => {
      dispatch(
        updateSelectReasonCondition({
          additionalChargeId: id,
          conditionToUpdate: {
            ...condition,
            operator: e.target.value,
          },
        }),
      );
    },
    [dispatch],
  );

  //function to update condition value by its value type
  const dispReasonUpdateConditionForValues = useCallback(
    (id: string, value: any, condition: any, isFollowUpQuestion?: boolean) => {
      if (!isFollowUpQuestion) {
        value.value = "";
      }

      dispatch(
        updateSelectReasonCondition({
          additionalChargeId: id,
          conditionToUpdate: {
            ...condition,
            values: {
              return_reason: {
                ...condition.values?.return_reason,
                ...value,
              },
            },
          },
        }),
      );

      dispatch(validateChanges());
    },
    [dispatch],
  );

  //function to delete a condition by its id
  const dispReasonDeleteCondition = useCallback(
    (conId: string, id: string) => {
      dispatch(
        deleteSelectReasonCondition({
          conditionId: conId,
          additionalChargeId: id,
        }),
      );
    },
    [dispatch],
  );

  //function to add new condition for the passed tab id
  const dispReasonAddCondition = useCallback(
    (id: string) => {
      dispatch(
        addSelectReasonCondition({
          additionalChargeId: id,
        }),
      );
      dispatch(validateChanges());
    },
    [dispatch],
  );

  return {
    dispAddCondition,
    dispAddTab,
    dispDeleteCondition,
    dispPostConfig,
    dispPushCurrentHashForSelectedTab,
    dispUpdateConditionForValues,
    dispUpdateConditionForVariables,
    dispUpdateConditonForOperator,
    dispUpdateAdditionCharge,
    dispValidateCondition,
    dispValidateAdditionalCharge,
    dispUpdateFeesAmount,
    additionalCharges,
    fetchAjaxStatus,
    updateAjaxStatus,
    initialHashOtherTab,
    currentHashOtherTab,
    selectedAdditionalCharge,
    selectedAdditionalChargeId,
    isAddBtnEnabled,
    isOrderTypeAddBtnEnabled,
    otherTabsHasError,
    defaultOptions,
    itemOptions,
    conditionOptions,
    operatorNames,
    returnReasonOptions,
    dispReasonAddCondition,
    dispReasonDeleteCondition,
    dispReasonUpdateConditionForValues,
    dispReasonUpdateConditonForOperator,
    dispReasonUpdateConditionForVariables,
    isAddBtnSelectReasonEnabled,
  };
}
export default useRestockingFeeModal;
