import { useCallback, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { IDispatchRuleConfig } from "src/services/Automation/DispatchRulesConfigModal/getAllDispatchRuleConfig.service";
import { useAppSelector } from "src/store/store";
import { formatMDYTimeStringDate } from "src/utils/dateLibrary";
import DeleteDispatch from "./DeleteDispatch/DeleteDispatch";
import DeleteSuccess from "./DeleteSuccess/DeleteSuccess";
import styles from "./DispatchTableRows.module.scss";
import { EAutomationType } from "src/routes/BotSettings/Children/AutomationList/AutomationList";

const DispatchTableRows = ({
  configData,
  integrationId,
  actions,
  columns,
  showEdit,
  onDelete,
  automationType,
}: {
  configData: IDispatchRuleConfig["rows"][0];
  actions: IDispatchRuleConfig["actions"];
  columns: IDispatchRuleConfig["columns"];
  integrationId: string;
  automationType: EAutomationType;
  showEdit: () => void;
  onDelete: () => void;
}) => {
  const userTimezone = useAppSelector(
    (state) => state.globals.currentUserData?.userTimezone,
  );

  const formatDate = useCallback(
    (createdAt: string) => {
      return formatMDYTimeStringDate(createdAt, userTimezone);
    },
    [userTimezone],
  );

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);

  const widthPercentage = useMemo(() => {
    if (columns.length <= 6) {
      const length = actions.length > 0 ? columns.length + 1 : columns.length;
      return 100 / length;
    }

    return;
  }, [actions.length, columns.length]);

  return (
    <div
      className={`d-flex align-items-center cursor-pointer mb-2 ${styles.tableRow}`}
    >
      {columns.map(({ key }, idx) => {
        const value = configData[key];
        if (!value) {
          return null;
        }

        const isArray = Array.isArray(value);

        if (key === "bugPriority" && isArray) {
          return (
            <div
              className={`${
                key === "bugPriority" ? styles.tableRow : styles.UserReport
              } d-flex flex-wrap justify-content-center`}
              style={{
                width: widthPercentage ? `${widthPercentage}%` : undefined,
              }}
              key={key}
            >
              {value.map((priority, idx) => {
                return (
                  <span
                    key={idx}
                    className={`mb-1 me-1 ${
                      priority.toLowerCase() === "normal"
                        ? styles.lowPriority
                        : priority.toLowerCase() === "high"
                          ? styles.mediumPriority
                          : styles.highPriority
                    }`}
                  >
                    Priority : {priority}
                  </span>
                );
              })}
            </div>
          );
        }

        if (isArray) {
          return (
            <div
              className={`${styles.eleWrapper}  ${
                idx !== 0 ? "text-center" : ""
              }`}
              style={{
                width: widthPercentage ? `${widthPercentage}%` : undefined,
              }}
              key={key}
            >
              <span className={`${styles.element}`}>
                {value.length > 1
                  ? "Multiple"
                  : value.length === 1
                    ? value[0]
                    : "-"}
              </span>
            </div>
          );
        }

        if (key === "created_at") {
          return (
            <div
              className={`${styles.createdTime}  ${
                idx !== 0 ? "text-center" : ""
              }`}
              style={{
                width: widthPercentage ? `${widthPercentage}%` : undefined,
              }}
              key={key}
            >
              <div>
                <span className={`${styles.clock}`}>
                  <i className="fa-regular fa-clock"></i>
                </span>
                <span className={`ps-1 ${styles.time}`}>
                  {formatDate(value)}
                </span>
              </div>
            </div>
          );
        }

        return (
          <div
            className={`${styles.eleWrapper}  ${
              idx !== 0 ? "text-center" : ""
            }`}
            style={{
              width: widthPercentage ? `${widthPercentage}%` : undefined,
            }}
            key={key}
          >
            <span className={`${styles.element}`}>{value}</span>
          </div>
        );
      })}

      {/* Edit Buttons */}
      {actions.length > 0 ? (
        <div
          className={`${styles.createdTime} d-flex justify-content-center`}
          style={{
            width: widthPercentage ? `${widthPercentage}%` : undefined,
          }}
        >
          <div className="d-flex">
            {actions.includes("edit") && (
              <button
                className={`${styles.editBtn}`}
                onClick={showEdit}
              >
                Edit
              </button>
            )}
            {actions.includes("delete") && (
              <button
                className={`ms-2 ${styles.deleteBtn}`}
                onClick={() => setShowDeleteModal(true)}
              >
                Delete
              </button>
            )}
          </div>
        </div>
      ) : (
        ""
      )}

      <Modal
        className={`${styles.modalBg}`}
        show={showDeleteModal}
        onHide={() => {
          setShowDeleteModal(false);
          setIsDeleted(false);
        }}
        centered={true}
        dialogClassName={`${styles.deleteModal}`}
        contentClassName={`${styles.deleteContaienr}`}
      >
        {isDeleted ? (
          <DeleteSuccess
            hideModal={() => {
              setShowDeleteModal(false);
              onDelete();
            }}
            setIsDeleted={setIsDeleted}
            dispatchRuleName={
              typeof configData["ruleName"] === "string"
                ? configData["ruleName"]
                : ""
            }
          />
        ) : (
          <DeleteDispatch
            hideModal={() => setShowDeleteModal(false)}
            configurationId={configData.configurationId}
            automationType={automationType}
            integrationId={integrationId}
            dispatchRuleName={
              typeof configData["ruleName"] === "string"
                ? configData["ruleName"]
                : ""
            }
            onDeleted={() => setIsDeleted(true)}
          />
        )}
      </Modal>
    </div>
  );
};

export default DispatchTableRows;
