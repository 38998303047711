import { axiosJSON } from "src/globals/axiosEndPoints";

interface IFeaturedArticle{
  articleId: number|string; //article id which needs to be featured
  shortTitle: string; //short title of an article that is featured
}

//interface for payload of update featured article
export interface IUpdateFeaturedArticleParam{
  featuredArticleId: number|string;
  integrationId: number|string;
  type: "default" | "custom"; 
  domainUrl?: string; //domain url is only need if type is set to custom
  domainConstraint?: "contains" | "isExactly";//domain constraint is only need if type is set to custom
  featuredArticles: Array<IFeaturedArticle>;
}

export async function updateFeaturedArticle(payload: IUpdateFeaturedArticleParam) {
  const { data } = await axiosJSON.post(
    `/api/chatSetting/featuredArticles/update`,
    payload
  );

  if (data.err === true) {
    throw new Error(data.msg as string);
  }

  return data.data;
}