import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";
import { AudienceConfig, UserDetails } from "./getAllBotProfiles.service";

const apiEndPoint = false
  ? `${process.env.REACT_APP_TEST_SITE_URL}/botProfile/create`
  : "/api/botProfile/add";

export interface CreateProfileAudienceConfig
  extends Omit<AudienceConfig, "id" | "botProfileId" | "userId"> {}

export interface CreateBotProfileParams {
  brand_id: number | string;
}

export interface CreateBotProfileResponse {
  data: { bot_profile_id: number };
  statusCode: "drafted" | "bot_with_similar_config_already_published";
  "status-code": "bot_with_similar_config_already_published";
}

export const createBotProfile = async (params: CreateBotProfileParams) => {
  const { data: res } = await axiosJSON.post(apiEndPoint, params);
  if (res.err) {
    if (res.statusCode) {
      // If the status code is there, throw a specific error
      throw {
        statusCode: res.statusCode,
        message: res.msg ?? res.message,
      };
    } else {
      throw res.msg;
    }
  }
  return res as CreateBotProfileResponse;
};
