import React, { useEffect, useState } from "react";
import styles from "./KbSettings.module.scss";
import userImg from "src/assets/images/user.jpeg";
import { useNavigate } from "react-router-dom";
import {
  GetAllKnowledgeBaseRes,
  KnowledgeBase,
} from "src/services/KnowledgeBase/getAllKnowledgeBase.service";
import {
  InfiniteData,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { updateChatSetting } from "src/services/LiveChat/Settings/updateChatSettingData";
import deleteImg from "src/assets/images/deleteKbIntegration.png";
import { Modal } from "react-bootstrap";
import DeleteKb from "./Children/DeleteKb/DeleteKb";
import AxiosImg from "src/components/AxiosImg";
import UserAvatar from "src/components/UserAvatar";
interface Props {
  knowledgeBase: KnowledgeBase;
  setShowSuccessModal: (value: boolean) => void;
  setDeletedBrandName: (value: string) => void;
}

const KbSettings = ({
  knowledgeBase,
  setShowSuccessModal,
  setDeletedBrandName,
}: Props) => {
  const integrationId = knowledgeBase.integrationId;
  const navigate = useNavigate();

  const updateKbStatus = useMutation({
    mutationFn: updateChatSetting,
  });

  const [enableKb, setEnableKb] = React.useState<boolean>(
    knowledgeBase.knowledgeBaseEnableStatus
  );

  useEffect(() => {
    setEnableKb(knowledgeBase.knowledgeBaseEnableStatus);
  }, [knowledgeBase]);
  // modal functionality
  const [showModal, setModal] = useState(false);
  const onShow = () => {
    setDeletedBrandName(knowledgeBase.brandDetails.name);
    setModal(true);
  };
  const queryClient = useQueryClient();
  const onHide = () => {
    setModal(false);
  };
  return (
    <div>
      <div className={`${styles.setBox}`}>
        <div
          className={`d-flex justify-content-between align-items-center flex-wrap ${styles.enableDiv}`}
        >
          <div className={`d-flex align-items-center flex-wrap mb-1 mb-lg-0`}>
            <div className={`${styles.imgWrapper}`}>
              {knowledgeBase.brandDetails.imageURL &&
              knowledgeBase.brandDetails.imageURL.length !== 0 ? (
                <AxiosImg
                  url={knowledgeBase.brandDetails.imageURL ?? "NA"}
                  className={`rounded-circle me-2 ${styles.logo}`}
                />
              ) : (
                <UserAvatar
                  name={knowledgeBase.brandDetails.name ?? "NA"}
                  size={25}
                  className={`me-2`}
                />
              )}
            </div>
            <div>
              <span className={`saufter_h4`}>
                {knowledgeBase.brandDetails.name}
              </span>
            </div>
          </div>
          <div className="d-flex align-items-center mb-2">
            <div>
              <div className="form-check form-switch">
                <input
                  className={`form-check-input ${styles.formCheckInput}`}
                  type="checkbox"
                  role="switch"
                  checked={enableKb}
                  onChange={() => {
                    updateKbStatus.mutate({
                      integrationId: integrationId,
                      knowledgeBased: {
                        enabled: !enableKb,
                      },
                    });
                    setEnableKb(!enableKb);
                  }}
                />
                <label
                  className={`form-check-label ${styles.enableChat} saufter_gray_h4`}
                  htmlFor={"mySwitch_"}
                >
                  {enableKb
                    ? "Knowledge Base Enabled"
                    : "Knowledge Base Disabled"}
                </label>
              </div>
            </div>
            <img
              src={deleteImg}
              alt="Delete Button"
              className={`ms-2 cursor-pointer ${styles.deleteImg}`}
              onClick={onShow}
            />
          </div>
          <Modal
            backdropClassName={`${styles.modalBack}`}
            show={showModal}
            onHide={onHide}
            dialogClassName={`${styles.modalDialog}`}
            contentClassName={`${styles.modalContent}`}
          >
            <DeleteKb
              onHide={onHide}
              knowledgeBaseId={knowledgeBase.knowledgeBaseId}
              brandName={knowledgeBase.brandDetails.name}
              setShowSuccessModal={setShowSuccessModal}
            />
          </Modal>
        </div>
        <div
          className={`${styles.goTo}`}
          onClick={() => {
            navigate(`/knowledge-base/${integrationId}/articles`);
          }}
        >
          <span className={`${styles.goToHead} saufter_blue_h4`}>
            Go to knowledge base
          </span>
          <span className={`${styles.goToArrow} saufter_blue_h4`}>
            <i className="fa-solid fa-angle-right"></i>
          </span>
        </div>
      </div>
    </div>
  );
};

export default KbSettings;
