import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { AutomationAPIPayload } from "src/services/Automation/createAutomation.service";
import {
  IAutomationData,
  getAutomationService,
} from "src/services/Automation/getAutomationById.service";
import {
  UpdateAutomationParams,
  updateAutomationService,
} from "src/services/Automation/updateAutomation.service";
import {
  UpdateQuestionsParams,
  updateQuestionsService,
} from "src/services/Automation/updateQuestions.service";
import { IAutomationParams } from "../../AutomationList/AutomationList";

export const automationInitialState: IAutomationData = {
  brand: null,
  brandId: "",
  automationName: "",
  hasKnowledgeBaseArticles: false,
  configured: false,
  integrationId: "",
  published: false,
  moveToNextQuestionKey: null,
  questionIds: [],
  questionsData: {},
  stepIds: [],
  stepsData: {},
  showGoLive: false,
};

const useAutomationUpdate = () => {
  const { type, integrationId } = useParams<IAutomationParams>();
  const [formData, setFormData] = useState<IAutomationData>(
    automationInitialState,
  );

  const {
    data,
    isLoading: isFetching,
    error,
    refetch,
  } = useQuery<IAutomationData, Error>({
    queryKey: ["getAutomationService", integrationId, type],
    queryFn: () => {
      if (integrationId && type) {
        return getAutomationService({
          payload: {
            integrationId,
          },
          type,
        });
      }

      throw new Error("Integration ID is missing");
    },
  });

  const { mutate, isLoading } = useMutation<
    boolean,
    Error,
    AutomationAPIPayload<UpdateAutomationParams>,
    AutomationAPIPayload<UpdateAutomationParams>
  >({
    mutationKey: ["updateAutomationService", type],
    mutationFn: updateAutomationService,
    onError: (err) => {
      // Show a toast notification on error
      pushTheToast({
        position: "top-right",
        type: "danger",
        text:
          err.message ??
          "Could not update the automation data. Please reload the page.",
      });
    },
  });

  const { mutate: mutateQuestions, isLoading: isSavingQuestions } = useMutation<
    boolean,
    Error,
    AutomationAPIPayload<UpdateQuestionsParams>,
    AutomationAPIPayload<UpdateQuestionsParams>
  >({
    mutationKey: ["updateQuestionsService", type],
    mutationFn: updateQuestionsService,
    onError: (err) => {
      // Show a toast notification on error
      pushTheToast({
        position: "top-right",
        type: "danger",
        text:
          typeof err === "string"
            ? err
            : err.message ??
              "Could not update the automation questions. Please reload the page.",
      });
    },
  });

  const updateConfigMutation = useCallback(
    (updateParams: AutomationAPIPayload<UpdateAutomationParams>) => {
      mutate(updateParams);
    },
    [mutate],
  );

  const updateQuestionsMutation = useCallback(
    (updateParams: AutomationAPIPayload<UpdateQuestionsParams>) => {
      mutateQuestions(updateParams);
    },
    [mutateQuestions],
  );

  useEffect(() => {
    if (data) {
      setFormData(data);
    }
  }, [data]);

  const errorMessage = useMemo(() => {
    if (error) {
      return error.message ?? "Could not fetch Automation...";
    }
    return null;
  }, [error]);

  return {
    type,
    refetch,
    integrationId,
    isFetching,
    isSaving: isLoading || isSavingQuestions,
    updateConfigMutation,
    updateQuestionsMutation,
    errorMessage,
    formData,
    setFormData,
  };
};

export default useAutomationUpdate;
