import React, { useState } from "react";
import MoreColsList from "../MoreColsList";

const MoreColsMenu = React.forwardRef<any>(
  (
    { style, className, "aria-labelledby": labeledBy }: any,
    ref,
    ...props
  ) => (
    <div
      ref={ref}
      style={style}
      className={className}
      aria-labelledby={labeledBy}
      {...props}
    >
      <MoreColsList />
    </div>
  )
);

export default MoreColsMenu;
