/**
 * This file is the Delete View Modal File.
 * It contains the modal for deleting views in outer ticket view sidebar.
 *
 * @author Ayush Gupta
 */

import { Modal, Spinner } from "react-bootstrap";
import styles from "./DeleteViewModal.module.scss";
import { useCallback, useState } from "react";
import Lottie from "lottie-light-react";
import Done from "src/assets/images/done.json";
import deleteViewService from "src/services/TicketViewsService/deleteView.service";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { useAppDispatch } from "src/store/store";
import {
  deleteUserView,
  refreshSideBarUsersViews,
} from "src/store/slices/ticketSidebar/ticketSidebar.slice";

interface Props {
  show: boolean;
  onHide: (isRedirect?: boolean, isClose?: boolean) => void;
  viewId: string;
}

/**
 * Modal Component for deleting views from outer ticket view
 */
const DeleteViewModal = ({ show, onHide, viewId }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const dispatch = useAppDispatch();

  /**
   * Submit handler for deleting View
   */
  const handleSubmit = useCallback(async () => {
    try {
      // Loading state
      setIsLoading(true);

      // Delete View
      const isViewDeleted = await deleteViewService({ viewId });

      // If view is deleted successfully
      if (isViewDeleted) {
        // Show Success state
        setIsSuccess(true);

        // Update Redux state data
        dispatch(deleteUserView({ viewId }));

        // Wait for success animation
        await new Promise((resolve) => setTimeout(resolve, 2000));

        // Hide the modal with redirect status
        onHide(true);
      } else {
        // Show toast that view not found
        pushTheToast({
          position: "top-right",
          text: `No View found with ID : ${viewId}`,
          type: "danger",
        });

        dispatch(refreshSideBarUsersViews());

        // If view not found close the modal and redirect user to my views
        onHide(true);
      }
    } catch (e: any) {
      // If error deleting view show toast with error message
      pushTheToast({
        position: "top-right",
        text: typeof e === "string" ? e : e.message,
        type: "danger",
      });
    } finally {
      // Reset States
      setIsSuccess(false);
      setIsLoading(false);
    }
  }, [dispatch, onHide, viewId]);

  /**
   * Cancel Handler for closing modal
   *
   * If isClose is true, then close button is clicked
   */
  const handleCancel = useCallback(
    (isClose?: boolean) => onHide(false, isClose),
    [onHide],
  );

  return (
    <Modal
      show={show}
      onHide={onHide}
      contentClassName={`${styles.modalContent}`}
      dialogClassName={`${styles.dialogClass}`}
      backdropClassName={`${styles.backDropClass}`}
      backdrop="static" // Prevents clicking outside the modal to close it
      id="deleteViewModal"
    >
      <div className={`container mb-2`}>
        {!isSuccess ? (
          <>
            {/* Header */}
            <div>
              <span
                className={`d-flex justify-content-end h-0 ${styles.closeBtn}`}
                onClick={() => handleCancel(true)}
              >
                <svg
                  id="Component_163_1"
                  data-name="Component 163 – 1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                >
                  <circle
                    id="Ellipse_844"
                    data-name="Ellipse 844"
                    cx="10"
                    cy="10"
                    r="10"
                    fill="#707070"
                    opacity="0"
                  />
                  <path
                    id="Icon_ionic-ios-close"
                    data-name="Icon ionic-ios-close"
                    d="M16.466,15.476l2.992-2.992a.7.7,0,0,0-.991-.991l-2.992,2.992-2.992-2.992a.7.7,0,1,0-.991.991l2.992,2.992-2.992,2.992a.7.7,0,1,0,.991.991l2.992-2.992,2.992,2.992a.7.7,0,1,0,.991-.991Z"
                    transform="translate(-5.559 -5.562)"
                    fill="#707070"
                  />
                </svg>
              </span>
            </div>
            <div className={`d-flex justify-content-center my-2 mx-3`}>
              {/* Heading */}
              <h3 className={`${styles.modalHead}`}>Do you Confirm ?</h3>
              {/* Close Button */}
            </div>
            {/* Content */}
            <div className={`px-4 text-center`}>
              {/* Sub Heading */}
              <span className={`d-inline-block py-2 ${styles.modalSub}`}>
                Are you sure you want to delete this view?
              </span>
              {/* Buttons */}
              <div
                className={` pt-4 pb-3 mb-4 d-flex justify-content-center align-items-center`}
              >
                {/* Cancel Button */}
                <button
                  className={`bg-transparent px-4 py-2 me-3 me-md-4 ${styles.cancelBtn}`}
                  onClick={() => handleCancel()}
                >
                  Cancel
                </button>
                {/* Delete Button */}
                <button
                  className={`border-0 px-4 py-2 ${styles.deleteBtn}`}
                  onClick={handleSubmit}
                >
                  {isLoading ? (
                    <Spinner
                      className="my-auto mx-1"
                      animation="border"
                      color="white"
                      size="sm"
                    />
                  ) : (
                    "Delete"
                  )}
                </button>
              </div>
            </div>
          </>
        ) : (
          <>
            {/* Success Image */}
            <div className="d-flex justify-content-center align-items-center my-3 mx-3">
              <span style={{ width: "128px", height: "128px" }}>
                <Lottie animationData={Done}></Lottie>
              </span>
            </div>
            {/* Sub Heading text */}
            <div className={`px-4 mb-4 text-center`}>
              <span className={`d-inline-block py-2 ${styles.modalSub}`}>
                The view has been deleted successfully !
              </span>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};
export default DeleteViewModal;
