import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import ReactSelect, {
  ActionMeta,
  GroupBase,
  MultiValue,
  OptionProps,
  StylesConfig,
  components,
} from "react-select";
import { useFetchReturnProducts } from "src/features/ReturnAutoWorkFlow/hooks/useFetchReturnConditions";
import styles from "./ReturnProductsSelect.module.scss";

const selectStyles: StylesConfig<Item, true, GroupBase<Item>> = {
  control: (provided) => ({
    ...provided,
    minWidth: 250,
    margin: "8px 0px",
    backgroundColor: "white",
    borderColor: "#707070",
    "&:hover": {
      borderColor: "#707070",
      boxShadow: "unset !important",
    },
  }),
  container: (provided) => ({
    ...provided,
    borderRadius: "8px",
    color: "black",
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: "500",
    padding: "0 17px",
    cursor: "pointer",
  }),
  menu: (provided) => ({
    ...provided,
    boxShadow: "none",
    border: "none",
    width: "88%",
  }),
  option: (provided, state) => ({
    ...provided,
    color: "black",
    backgroundColor: state.isFocused ? "lightGrey" : "",
  }),
};

interface Item {
  id: number;
  value: string;
  label: string;
  price: {
    amount: string;
    currencyCode: string;
  };
  imgSrc: string;
}

const Option: React.ComponentType<OptionProps<Item, true, GroupBase<Item>>> = (
  props,
) => {
  return (
    <components.Option {...props}>
      <div className="d-flex align-items-center gap-3">
        <div>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
            className="form-check-input"
            style={{
              width: "20px",
              height: "20px",
              borderRadius: "6px",
              margin: 0,
            }}
          />
        </div>
        {props.data.imgSrc === null ? (
          <></>
        ) : (
          <img
            alt=""
            src={props.data.imgSrc}
            style={{
              maxWidth: "35px",
              maxHeight: "32px",
              objectFit: "cover",
              alignSelf: "center",
              borderRadius: "5px",
            }}
          ></img>
        )}

        <div className="d-flex flex-column w-100 ">
          {props.label !== null && (
            <label className={`${styles.optionCss}`}>{props.label}</label>
          )}
          {props.data.price !== null &&
            props.data.price?.amount &&
            props.data.price?.currencyCode && (
              <label className={`${styles.price}`}>
                {props.data.price.amount} {props.data.price.currencyCode}
              </label>
            )}
        </div>
      </div>
    </components.Option>
  );
};

const LIMIT = 25;
const isMulti = true;

function usePrevious<T>(state: T): T | undefined {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = state;
  });

  return ref.current;
}

const ReturnProductsSelect = ({
  error,
  initialOptions,
  updateCondition,
  showValidation,
}: {
  updateCondition: (val: Array<number | string>) => void;
  error: string;
  initialOptions: Array<number | string>;
  showValidation?: boolean;
}) => {
  const { fetchProducts, products, loadingStatus } = useFetchReturnProducts();

  const allOptions = useMemo(() => {
    return (
      products.map((product) => {
        return {
          id: product.productId,
          imgSrc: product.imageUrl,
          label: product.productName,
          price: product.productPrice,
          value: product.productName,
        } as Item;
      }) ?? []
    );
  }, [products]);

  const [menuOpen, setMenuOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [selectedOptions, setSelectedOptions] =
    useState<Array<number | string>>(initialOptions);

  const prevSelectedOptions = usePrevious(selectedOptions);
  const [isOptionsInitial, setIsOptionsInitial] = useState(true);

  useEffect(() => {
    if (loadingStatus !== "pending" && products.length === 0) {
      fetchProducts(LIMIT);
    }
  }, [fetchProducts, loadingStatus, products.length]);

  useEffect(() => {
    if (isOptionsInitial && selectedOptions.length === 0) {
      return;
    }

    if (prevSelectedOptions?.length !== selectedOptions.length) {
      updateCondition(selectedOptions.filter((option) => option));
    }
    setIsOptionsInitial(false);
  }, [selectedOptions]);

  const onSelectChange = useCallback(
    (newValues: MultiValue<Item>, actionMeta: ActionMeta<Item>) => {
      let option: Item | undefined | null = null;

      if (actionMeta.action === "remove-value") {
        option = actionMeta.removedValue;
      } else {
        option = actionMeta.option;
      }

      // If All Selected
      if (isMulti && newValues.length && option?.value === "*") {
        if (actionMeta.action === "select-option") {
          setSelectedOptions(allOptions.map((option) => option.id));
        } else if (actionMeta.action === "deselect-option") {
          setSelectedOptions([]);
        }
      } else {
        // Multi value
        if (
          actionMeta.action === "remove-value" ||
          actionMeta.action === "deselect-option"
        ) {
          const allSelectedOptions = selectedOptions.filter(
            (optionId) => optionId != option?.id,
          );
          setSelectedOptions(allSelectedOptions);
        } else {
          if (option?.id) {
            const allSelectedOptions = Array.from(
              new Set([...selectedOptions, option?.id]),
            );
            setSelectedOptions(allSelectedOptions);
          }
        }
      }
    },
    [allOptions, selectedOptions],
  );

  const dropdownMessage = useMemo(() => {
    let dropdownMessage = "Select items";
    if (selectedOptions.length >= 1) {
      dropdownMessage = `${selectedOptions.length} items selected`;
    }
    return dropdownMessage;
  }, [selectedOptions.length]);

  const [showPopover, setShowPopover] = useState(true);

  useEffect(() => {
    if (error) {
      setShowPopover(true);
    } else {
      setShowPopover(false);
    }
  }, [error]);

  const selectedValue = useMemo(() => {
    return allOptions.filter((op) => initialOptions.includes(op.id));
  }, [allOptions, initialOptions]);

  return (
    <OverlayTrigger
      show={showPopover}
      placement="bottom"
      overlay={
        error && error.length > 0 ? (
          <Popover
            id="popover-basic"
            className="rounded-3"
          >
            <Popover.Body className="d-flex flex-row">
              <div className="border px-2 bg-warning text-white me-1 rounded-3 ">
                !
              </div>
              <div className="mt-1">{error}</div>
            </Popover.Body>
          </Popover>
        ) : (
          <></>
        )
      }
      rootClose={true}
      rootCloseEvent="mousedown"
      onToggle={(isShown) => {
        // Set the state variable based on the popover visibility
        if (!menuOpen) {
          setShowPopover(isShown);
        }
      }}
    >
      <div
        className={`ms-lg-2 ${styles.thirdSelect}`}
        onClick={() => setShowPopover(false)}
        style={{
          maxWidth: "20rem",
          minWidth: "16rem",
        }}
      >
        <Dropdown
          onToggle={(nextShow) => setMenuOpen(nextShow)}
          className={`${showValidation && "border border-danger rounded"}`}
        >
          <Dropdown.Toggle
            variant=""
            id="dropdown-basic"
            bsPrefix={`d-flex align-items-center justify-content-between ${
              styles.selectDropdown
            }  ${error ? "border border-danger" : ""}`}
          >
            <span
              className={
                selectedOptions.length !== allOptions.length &&
                selectedOptions.length === 0
                  ? styles.placeholder
                  : ""
              }
            >
              {" "}
              {dropdownMessage}{" "}
            </span>{" "}
            <span
              className={`ps-2 pe-2 ${
                menuOpen ? styles.upArrow : styles.downArrow
              }`}
            >
              {" "}
              <i className="fa fa-caret-down"></i>
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu bsPrefix={`dropdown-menu ${styles.menuBox}`}>
            <ReactSelect
              menuIsOpen={menuOpen}
              autoFocus
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              backspaceRemovesValue={false}
              controlShouldRenderValue={false}
              isClearable={false}
              components={{
                IndicatorSeparator: null,
                DropdownIndicator: null,
                Option,
              }}
              inputValue={searchInput}
              onInputChange={(inputValue, actionMeta) => {
                if (
                  actionMeta.action !== "input-blur" &&
                  actionMeta.action !== "menu-close" &&
                  actionMeta.action !== "set-value"
                ) {
                  setSearchInput(inputValue);
                }
              }}
              isMulti={isMulti}
              placeholder="Search..."
              styles={selectStyles}
              value={selectedValue}
              options={allOptions}
              onChange={(newValue, actionMeta) => {
                onSelectChange(newValue, actionMeta);
              }}
              isLoading={loadingStatus === "pending"}
              onMenuScrollToBottom={() => fetchProducts(LIMIT)}
              isOptionSelected={(option) => {
                if (selectedOptions.includes(option.id)) {
                  return true;
                }
                return false;
              }}
            />
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </OverlayTrigger>
  );
};

export default ReturnProductsSelect;
