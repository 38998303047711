import { useState } from "react";
import styles from "./ServiceSidebar.module.scss";
import { NavLink } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import { useAppSelector } from "src/store/store";
function ServiceSideBar() {
  const [toggleServiceSettings, setToggleServiceAccSettings] =
    useState<boolean>(true);
  const [showSettingsSubSB, setShowSettingsSubSB] = useState<boolean>(true);
  const totalBugsTicketCount = useAppSelector(
    (state) => state.globals.selfServiceSidebar?.bugManagementTotalTicket,
  );

  const handleToggleSubSideBar = () => {
    if (showSettingsSubSB === true) {
      setShowSettingsSubSB(false);
    } else {
      setShowSettingsSubSB(true);
    }
  };

  const getToggleContent = () => {
    return (
      <div
        className={`${styles.subSideBarCloseBtnDiv}`}
        onClick={() => handleToggleSubSideBar()}
      >
        <span className={`${styles.subSideBarCloseBtnToggleArrow} m-auto`}>
          {showSettingsSubSB === true ? (
            <i className="fa-solid fa-chevron-left"></i>
          ) : (
            <i className="fa-solid fa-chevron-right"></i>
          )}
        </span>
      </div>
    );
  };

  return (
    <div className={`${styles.subSideToggle}`}>
      {showSettingsSubSB === true && (
        <div className={`${styles.subsideToggleInner}`}>
          <div
            className={` ${styles.sidenav_content}  border shadow-sm overflow-auto`}
          >
            <ul
              className={` ${styles.sidebar_nav} `}
              id={`${styles.sidebar_nav}`}
            >
              <li className={` ${styles.nav_item} ${styles.pink_clr}`}>
                <span> Self Service</span>
                <i className="bi bi-grid" />
              </li>
              <li className={`${styles["nav_item"]}`}>
                <div
                  className={` ${styles.nav_link} cursor-pointer ${styles.collapsed} ps-3`}
                  // data-bs-target="#account"
                  // data-bs-toggle="collapse"
                  aria-expanded="false"
                  onClick={(e) =>
                    setToggleServiceAccSettings(!toggleServiceSettings)
                  }
                >
                  <span className={`w-100 ${styles.toggler}`}>
                    Self Service
                  </span>
                  {toggleServiceSettings === true ? (
                    <i className="fa fa-angle-down" />
                  ) : (
                    <i className="fa fa-angle-up" />
                  )}
                </div>
                <Collapse in={toggleServiceSettings}>
                  <ul
                    id="account"
                    className={` ${styles.nav_content} ${styles.collapsed}`}
                    data-bs-parent="#sidebar_nav"
                  >
                    {/* <li>
                      <NavLink
                        className={`${styles.a}`}
                        to="/selfService/orderModification"
                        style={({ isActive }) => ({
                          color: isActive ? "#fff " : "",
                          background: isActive ? "#ff8499" : "",
                        })}
                      >
                        <span>Order Modification</span>
                        <span>12</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={`${styles.a}`}
                        to="/selfService/return"
                        style={({ isActive }) => ({
                          color: isActive ? "#fff" : "",
                          background: isActive ? "#ff8499" : "",
                        })}
                      >
                        <span>Return/Exchange</span>
                        <span>12</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={`${styles.a}`}
                        to="/selfService/orderTracking"
                        style={({ isActive }) => ({
                          color: isActive ? "#fff" : "",
                          background: isActive ? "#ff8499" : "",
                        })}
                      >
                        <span>Order tracking</span>
                        <span>12</span>
                      </NavLink>
                    </li> */}
                    <li>
                      <NavLink
                        className={`${styles.a}`}
                        to="/selfService/bugManagement"
                        style={({ isActive }) => ({
                          color: isActive ? "#fff" : "",
                          background: isActive ? "#ff8499" : "",
                        })}
                      >
                        <span>Bug management</span>
                        <span>{totalBugsTicketCount}</span>
                      </NavLink>
                    </li>
                  </ul>
                </Collapse>
              </li>
            </ul>
          </div>
          {showSettingsSubSB === true && getToggleContent()}
        </div>
      )}
      {showSettingsSubSB === false && getToggleContent()}
    </div>
  );
}
export default ServiceSideBar;
