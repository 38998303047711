import { CurrentStatusEndPoints } from "src/services/report/reportTicketList.service";
import useReportTicketList from "../hooks/useReportTicketList";
import ChatHandleData from "./ChatHandleData/ChatHandleData";
import styles from "./ChatHandleWrapper.module.scss";
import Pagination from "src/routes/TicketList/children/Pagination/Pagination";
import FoundNoTickets from "src/components/FoundNoTickets";
import Loader from "src/components/Loader";
import { ReportDateType } from "src/routes/Report/hooks/reportFilters/useReportInternalFilters";
import { useMemo } from "react";
import { v4 as uuid } from "uuid";

interface Props {
  endPoint: CurrentStatusEndPoints;
  reportDateType?: ReportDateType;
}

const ChatHandleWrapper = ({ endPoint, reportDateType }: Props) => {
  const {
    reportTicketList,
    status,
    allSidebarFilters,
    allSidebarFilterIds,
    selectedSBFilterIds,
    selectAllSBFilters,
    checkUncheckSBFilters,
    currentPage,
    setCurrentPage,
    totalPages,
    handleTicketClick,
    isPreviousData,
    clearAllSBFilters,
  } = useReportTicketList({
    endPoint,
    reportDateType: reportDateType ?? "daily",
  });

  // Added this on change of Daily / monthly / weekly filter need to clear previous sidebar filters
  useMemo(() => {
    clearAllSBFilters();
  }, [reportDateType]);
  // Apr 23 Adding uniques id to "All" checkbox to make it unique
  const uuIdAll = uuid();

  return (
    <div className={`${styles.ticketStatShow}`}>
      <div className="d-block d-md-flex">
        {/* Left Section: Filters */}
        <div className={`${styles.leftSection}`}>
          <div className={`${styles.allChannels}`}>
            {/* Checkbox for selecting all filters */}
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value="all"
                id={"report-list-flexCheckDefault" + uuIdAll}
                checked={
                  selectedSBFilterIds.length === allSidebarFilterIds.length &&
                  allSidebarFilterIds.length !== 0
                }
                onChange={selectAllSBFilters}
              />
              {/* Label for selecting all filters */}
              <label
                className={`form-check-label ${styles.channelText} d-flex justify-content-between`}
                htmlFor={"report-list-flexCheckDefault" + uuIdAll}
              >
                <div>
                  {/* Displaying the appropriate label based on the endpoint */}
                  {endPoint.startsWith("bot")
                    ? "Time"
                    : endPoint.includes("chatSatisfactionReports") ||
                        endPoint.includes("chatAvailabilityReports")
                      ? "Time Clusters"
                      : endPoint.includes("revenueGeneratedByTime")
                        ? reportDateType === "daily"
                          ? "Daily"
                          : reportDateType === "monthly"
                            ? "Monthly"
                            : reportDateType === "weekly"
                              ? "Weekly"
                              : "Daily"
                        : ""}
                </div>
              </label>
            </div>
          </div>
          <div
            style={{ height: "300px" }}
            className="overflow-auto"
          >
            {allSidebarFilterIds.map((id) => {
              // Apr 23 Adding unique id as sidebar filter is not unique
              const uid = uuid();
              return (
                <div
                  key={id}
                  className={`${styles.timeWrapper}`}
                >
                  <div className="form-check">
                    <input
                      className="cursor-pointer form-check-input"
                      type="checkbox"
                      value={id}
                      id={"report-list-flexCheckDefault" + id + uid}
                      checked={selectedSBFilterIds.includes(id)}
                      onChange={checkUncheckSBFilters}
                    />
                    <label
                      className={`cursor-pointer form-check-label ${styles.rowText} d-flex justify-content-between`}
                      htmlFor={"report-list-flexCheckDefault" + id + uid}
                    >
                      {/* Displaying filter name */}
                      <div>{allSidebarFilters[id].name}</div>
                    </label>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {/* Right Section: Chats List */}
        <div className={`${styles.rightSection}`}>
          <div
            className={`d-flex align-items-center mb-4 ${styles.chatWrapper}`}
          >
            {!endPoint.includes("revenueGeneratedByTime") && (
              <div
                className={`${
                  endPoint.includes("chatSatisfactionReports") ||
                  endPoint.includes("chatAvailabilityReports")
                    ? styles.chatItem
                    : styles.chatItems
                }`}
              >
                <span>
                  {endPoint.startsWith("bot")
                    ? "Chats handled by cern"
                    : endPoint.includes("chatSatisfactionReports")
                    ? `Chats(${reportTicketList?.metaData?.totalCount ?? 0})`
                    : endPoint.includes("chatAvailabilityReports")
                    ? `Agents(${reportTicketList?.metaData?.totalCount ?? 0})`
                    : ""}
                </span>
              </div>
            )}

            {endPoint.includes("percentageCernFoundUseful") && (
              <div className={`${styles.resItems}`}>
                <span>Response for cern's resolution</span>
              </div>
            )}
            {(endPoint.includes("chatSatisfactionReports") ||
              endPoint.includes("chatAvailabilityReports")) && (
              <div
                className={`${
                  endPoint.includes("chatSatisfactionReports")
                    ? styles.ratingItems
                    : styles.availabilityItems
                }`}
              >
                <span>
                  {endPoint.includes("chatSatisfactionReports")
                    ? `Rating`
                    : endPoint.includes("chatAvailabilityReports")
                      ? `Availability Hours`
                      : ""}
                </span>
              </div>
            )}
            {endPoint.includes("revenueGeneratedByTime") && (
              <div className={`${styles.resItems}`}>
                <span>{`Tickets/Chats (${
                  reportTicketList?.metaData.totalCount ?? 0
                })`}</span>
              </div>
            )}
            {endPoint.includes("revenueGeneratedByTime") && (
              <div className={`${styles.handleItem}`}>
                <span>Channel</span>
              </div>
            )}
            {(endPoint.includes("percentageCernFoundUseful") ||
              endPoint.includes("revenueGeneratedByTime")) && (
              <div className={`${styles.handleItem}`}>
                <span>Handled by</span>
              </div>
            )}
            {endPoint.includes("revenueGeneratedByTime") && (
              <div className={`${styles.handleItem}`}>
                <span>Revenue</span>
              </div>
            )}
          </div>
          {!isPreviousData &&
          status === "success" &&
          reportTicketList?.ticketIds.length ? (
            <>
              <div className={`${styles.msgWrapper} pb-2`}>
                {reportTicketList?.ticketIds.map((ticket, index) => {
                  const data = reportTicketList?.ticketData[ticket];
                  return (
                    <ChatHandleData
                      endPoint={endPoint}
                      data={data}
                      onClick={handleTicketClick}
                      key={data.ticket_id}
                    />
                  );
                })}
              </div>
              {/* Pagination component */}
              <div className="mt-3">
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            </>
          ) : isPreviousData ||
            (!isPreviousData &&
              status === "loading" &&
              reportTicketList?.ticketIds.length === undefined) ? (
            // Loading indicator
            <Loader className={styles.loading} />
          ) : !isPreviousData &&
            status === "success" &&
            reportTicketList?.ticketIds.length === 0 ? (
            // No Chats found
            <FoundNoTickets
              className={styles.notFound}
              text={"No Chats Found"}
            />
          ) : (
            // Empty content for other cases
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatHandleWrapper;
