import styles from "./UserListItem.module.scss";
import { useMemo } from "react";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { selectUser } from "src/store/slices/ticketUsers/ticketUsers.slice";
import UserAvatar from "src/components/UserAvatar";
import AxiosImg from "src/components/AxiosImg";

const dummyImageUrl =
  "https://images.unsplash.com/photo-1541855492-581f618f69a0?ixlib=rb-1.2.1&amp;ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8bWFsZXxlbnwwfHwwfHw%3D&amp;w=1000&amp;q=80";

const UserListItem = ({ user }: any) => {
  const userSelected = useAppSelector(
    (state) => state.ticketUsers.userSelected
  );
  const dispatch = useAppDispatch();

  const getClassName = useMemo(() => {
    if (userSelected === user.id) {
      return `${styles.addBoxSelected}`;
    } else {
      return ` ${styles.addBox}`;
    }
  }, [userSelected === user.id]);

  const handleOnClick = (e: any) => {
    e.preventDefault();
    dispatch(selectUser(user.id));
  };
  return (
    <div
      className={`${getClassName} text-truncate`}
      onClick={handleOnClick}
      id={`userList_${user.name.toLowerCase().replace(" ", "_")}`}
    >
      <span>
        <span className={`me-2 ${styles.userImg}`}>
          {user?.imageUrl && user?.imageUrl.trim().length !== 0 ? (
            <AxiosImg
              className={`${styles.profileImg}`}
              url={user.imageUrl}
              isDirectURL={user.isPublicAttachmentUrl}
            />
          ) : (
            <UserAvatar name={user.name || "NA"} size={25} />
          )}
        </span>
        <span className={`${styles.assignName}`}>{user.name}</span>
      </span>
    </div>
  );
};

export default UserListItem;
