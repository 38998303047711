import React, { useCallback } from "react";
import styles from "./Analytics.module.scss";
import { useNavigate } from "react-router-dom";
import ReportDatePicker from "src/routes/Report/Children/ReportDatePicker/ReportDatePicker";
import FilterWrapper from "src/routes/Report/Children/FilterWrapper/FilterWrapper";
import BotAnalytics from "src/routes/Report/Children/BotAnalytics/BotAnalytics";
import {
  reportCurrentStatusActions,
  useReportFiltersCreate,
} from "src/routes/Report/hooks/reportFilters/useReportFilters";

const Analytics = () => {
  const navigate = useNavigate();
  // Callback to handle back click
  const handleBackClick = useCallback(() => {
    navigate("/bot/botProfiles");
  }, []);

  const [state, dispatch, Provider] = useReportFiltersCreate();

  return (
    <Provider value={[state, dispatch]}>
      <div className={`w-100 px-4 py-3 ${styles.mainWrapper}`}>
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-start">
            <div
              className={`${styles.backArrow}`}
              onClick={handleBackClick}
              role="button"
            >
              <span>
                <i className="fa-solid fa-arrow-left"></i>
              </span>
            </div>
            <div className="ps-2">
              <h2 className=" saufter_h3 mb-0">Cern Reports</h2>
              <span className={`saufter_h6 d-block ${styles.subHead}`}>
                Only one bot profile will be matched per user.
              </span>
            </div>
          </div>
          <div>
            <ReportDatePicker
              startDate={state.startDate}
              endDate={state.endDate}
              onChange={([startDate, endDate]) => {
                dispatch([reportCurrentStatusActions.setStartDate, startDate]);
                dispatch([reportCurrentStatusActions.setEndDate, endDate]);
              }}
            />
          </div>
        </div>
        <div>
          <FilterWrapper scope="bot" />
          <BotAnalytics />
        </div>
      </div>
    </Provider>
  );
};

export default Analytics;
