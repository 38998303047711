import { useCallback, useEffect, useRef, useState } from "react";
import { Col, Modal, Nav, Row, Tab } from "react-bootstrap";
import AddConditionButton from "src/components/AutomationComponents/ContentSection/AddConditionButton";
import Condition from "src/components/AutomationComponents/ContentSection/Condition";
import ContentHeading from "src/components/AutomationComponents/ContentSection/ContentHeading";
import MessageSection from "src/components/AutomationComponents/ContentSection/MessageSection";
import OperatorSelect from "src/components/AutomationComponents/ContentSection/OperatorSelect";
import Values from "src/components/AutomationComponents/ContentSection/Values";
import Variables, {
  addVariableOnClick,
} from "src/components/AutomationComponents/ContentSection/VariableSection";
import VariableSelect from "src/components/AutomationComponents/ContentSection/VariableSelect";
import DiscardAndSave from "src/components/AutomationComponents/DiscardAndSave";
import DiscardModal from "src/components/AutomationComponents/DiscardModal/DiscardModal";
import EnableDisableType from "src/components/AutomationComponents/IndividualComponents/EnableDisableType/EnableDisableType";
import SkeletonModalStructure from "src/components/AutomationComponents/SkeletonModalStructure/SkeletonModalStructure";
import AddMore from "src/components/AutomationComponents/TabSection/AddMore";
import AutomationTab from "src/components/AutomationComponents/TabSection/AutomationTab";
import CloseButtonForTab from "src/components/AutomationComponents/TabSection/CloseButtonForTab";
import ConditionGroup from "src/components/AutomationComponents/TabSection/ConditionGroup";
import TabError from "src/components/AutomationComponents/TabSection/TabError";
import TabName from "src/components/AutomationComponents/TabSection/TabName";
import Loader from "src/components/Loader";
import { useReturnAutoWorkFlow } from "src/features/ReturnAutoWorkFlow/hooks/useReturnAutoWorkFlow";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  deleteReturnType,
  setSelectedReturnTypeId,
  toggleRefundtoOriginalPayment,
  toggleRefundtoStoreCredit,
  toggleReturnWithOtherItem,
  toggleReturnWithSameItem,
  undoConfig,
  validateChanges,
} from "../../store/slices/ReturnTypeBasedOnOrderOrItems/returnTypeBasedOnOrderOrItems.slice";
import { fetchReturnTypeBasedOnOrderOrItemsThunk } from "../../store/slices/ReturnTypeBasedOnOrderOrItems/returnTypeBasedOnOrderOrItems.thunk";
import styles from "./ReturnTypeBasedOnOrderOrItems.module.scss";
import useReturnTypeBasedOnOrderOrItemsModal from "./useReturnTypeBasedOnOrderOrItemsModal";

const ReturnTypeComponent = ({
  onSave,
}: {
  onSave: (isSaved: boolean) => void;
}) => {
  const { returnAutoWorkFlow } = useReturnAutoWorkFlow();
  const dispatch = useAppDispatch();
  const insertPlaceholderRef = useRef(null as any);

  const {
    dispAddCondition,
    dispAddTab,
    dispDeleteCondition,
    dispPostConfig,
    dispPushCurrentHashForSelectedTab,
    dispUpdateConditionForValues,
    dispUpdateConditionForVariables,
    dispUpdateConditonForOperator,
    dispUpdateReturnType,
    dispValidateCondition,
    dispValidateReturnType,
    returnTypes,
    fetchAjaxStatus,
    updateAjaxStatus,
    initialHashOtherTab,
    currentHashOtherTab,
    selectedReturnTypeId,
    isAddBtnEnabled,
    isAddItemBtnEnabled,
    defaultOptions,
    itemOptions,
    conditionOptions,
    operatorNames,
  } = useReturnTypeBasedOnOrderOrItemsModal({ onSave });

  const [showInitial, setShowInitial] = useState(false);

  if (fetchAjaxStatus === "pending") {
    return (
      <div className={`${styles.loaderHeight}`}>
        <Loader />
      </div>
    );
  }

  return (
    <div>
      <Tab.Container
        id="left-tabs-example"
        activeKey={selectedReturnTypeId ?? "1"}
        defaultActiveKey={"1"}
        onSelect={(eventKey) => {
          if (selectedReturnTypeId !== eventKey) {
            dispatch(setSelectedReturnTypeId({ returnTypeId: eventKey ?? "" }));
          }
        }}
      >
        <Row bsPrefix={`row ${styles.rowSet}`}>
          <Col>
            <Nav
              variant="tabs"
              className={`d-flex align-items-center border-0 ${styles.tabSectionWrapper} `}
            >
              {returnTypes.map((ret, idx) => {
                return (
                  <Nav.Item
                    className=""
                    key={ret.returnTypeId + "tabItem"}
                    role="button"
                  >
                    <Nav.Link
                      eventKey={ret.returnTypeId}
                      className={`py-1 px-3  text-dark ${styles.tabStyles}`}
                      style={{
                        background:
                          selectedReturnTypeId !== null
                            ? selectedReturnTypeId === ret.returnTypeId
                              ? ret.errorCount > 0
                                ? "#FFDCDC 0% 0% no-repeat padding-box"
                                : "#FFFFFF 0% 0% no-repeat padding-box "
                              : ret.errorCount > 0
                                ? "#FFDCDC 0% 0% no-repeat padding-box"
                                : "#f5f5f5 0% 0% no-repeat padding-box"
                            : "",
                      }}
                    >
                      <AutomationTab idx={idx}>
                        <TabName
                          id={ret.returnTypeId}
                          name={ret.returnTypeName}
                          updateType={dispUpdateReturnType}
                          pushCurrentHash={dispPushCurrentHashForSelectedTab}
                        />

                        <TabError errorCount={ret.errorCount} />

                        <div className="ms-1">
                          <CloseButtonForTab
                            index={idx}
                            name={ret.returnTypeName}
                            hasStaticTab={false}
                            onDelete={() => {
                              dispatch(
                                deleteReturnType({ id: ret.returnTypeId }),
                              );
                            }}
                          />
                        </div>
                      </AutomationTab>
                    </Nav.Link>
                  </Nav.Item>
                );
              })}
              <AddMore
                AddTab={dispAddTab}
                text="Add more custom return types"
                selectedTabId={selectedReturnTypeId}
                validateTabType={dispValidateReturnType}
              />
            </Nav>

            <Tab.Content
              className={`${styles.contentStyles} pt-4  px-4 ${
                initialHashOtherTab &&
                currentHashOtherTab &&
                initialHashOtherTab === currentHashOtherTab &&
                styles.noDiscardAndSave
              }`}
            >
              {returnTypes.map((ret, idx) => {
                const allSelectedConditionNames: string[] = ret.conditions.map(
                  (condition) => condition.variableName,
                );

                const filteredConditions = conditionOptions.filter(
                  (condition) => !allSelectedConditionNames.includes(condition),
                );

                const filteredItemOptions = itemOptions.filter(
                  (condition) => !allSelectedConditionNames.includes(condition),
                );

                return (
                  <Tab.Pane
                    key={ret.returnTypeId + "tabPane"}
                    eventKey={ret.returnTypeId}
                    unmountOnExit={true}
                    onEnter={() => {
                      dispValidateCondition(ret.returnTypeId);
                    }}
                    onEntered={() => {
                      dispValidateCondition(ret.returnTypeId);
                    }}
                    onExited={() => {
                      dispValidateCondition(ret.returnTypeId);
                    }}
                  >
                    {/* Conditions Section  */}
                    <ContentHeading text="  Ask return type on" />
                    {idx === 0 ? (
                      <>
                        <div className={`py-4 ps-3 pe-2 ${styles.createBox}`}>
                          <ConditionGroup
                            key={ret.returnTypeId + "other_condition_group"}
                            title=""
                            initialShow={true}
                            disableHide={true}
                          >
                            <VariableSelect
                              key={ret.returnTypeId + "Default_option"}
                              id={ret.returnTypeId}
                              condition={{} as any}
                              variablesName={defaultOptions}
                              pushCurrentHash={
                                dispPushCurrentHashForSelectedTab
                              }
                              updateConditon={() => {}}
                              selectedVariableName={""}
                              showPlaceholder={false}
                            />
                          </ConditionGroup>
                          <span>
                            To create specific return type for specific kinds of
                            orders and items,{" "}
                            <button
                              className={`${styles.clickLink}`}
                              onClick={dispAddTab}
                            >
                              click here
                            </button>
                          </span>
                        </div>
                        <div></div>
                      </>
                    ) : (
                      <ConditionGroup
                        key={ret.returnTypeId + "other_condition_group"}
                        title=""
                        initialShow={true}
                        disableHide={true}
                        errorMessage={ret.itemError}
                      >
                        {ret.conditions.filter(
                          (condition) =>
                            !itemOptions.includes(condition.variableName),
                        ).length === 0 ? (
                          <ConditionGroup
                            key={ret.returnTypeId + "other_condition"}
                            title=""
                            initialShow={true}
                            disableHide={true}
                            errorMessage={ret.itemError}
                          >
                            <VariableSelect
                              id={ret.returnTypeId}
                              condition={{} as any}
                              variablesName={filteredConditions}
                              pushCurrentHash={
                                dispPushCurrentHashForSelectedTab
                              }
                              updateConditon={(id: any, e: any) =>
                                dispAddCondition(id, false, e)
                              }
                              selectedVariableName={""}
                              showPlaceholder={true}
                              errorMessage={ret.error}
                            />
                          </ConditionGroup>
                        ) : null}
                        {ret.conditions
                          .filter(
                            (condition) =>
                              !itemOptions.includes(condition.variableName),
                          )
                          .map((condition, idx) => {
                            return (
                              <Condition
                                key={condition.id + "other_condition"}
                                id={ret.returnTypeId}
                                condition={condition}
                                deleteCondition={dispDeleteCondition}
                                idx={idx}
                                validateCondition={dispValidateCondition}
                              >
                                <VariableSelect
                                  id={ret.returnTypeId}
                                  condition={condition}
                                  variablesName={filteredConditions}
                                  pushCurrentHash={
                                    dispPushCurrentHashForSelectedTab
                                  }
                                  updateConditon={
                                    dispUpdateConditionForVariables
                                  }
                                  selectedVariableName={condition.variableName}
                                />

                                <OperatorSelect
                                  condition={condition}
                                  id={ret.returnTypeId}
                                  pushCurrentHash={
                                    dispPushCurrentHashForSelectedTab
                                  }
                                  updateConditon={dispUpdateConditonForOperator}
                                  operatorNames={
                                    operatorNames[condition.variableName]
                                  }
                                  selectedOperator={condition.operator}
                                />

                                <Values
                                  id={ret.returnTypeId}
                                  condition={condition}
                                  updateCondition={dispUpdateConditionForValues}
                                  pushCurrentHash={
                                    dispPushCurrentHashForSelectedTab
                                  }
                                  useNewComponent={true}
                                />
                              </Condition>
                            );
                          })}
                        <div className="mb-2">
                          <AddConditionButton
                            AddCondition={dispAddCondition}
                            id={ret.returnTypeId}
                            isAddBtnEnabled={isAddBtnEnabled}
                          />
                        </div>
                      </ConditionGroup>
                    )}
                    {idx !== 0 ? (
                      <ConditionGroup
                        key={ret.returnTypeId + "item_condition_parent"}
                        title="Select Items"
                        errorMessage={ret.itemError}
                      >
                        {ret.conditions.filter((condition) =>
                          itemOptions.includes(condition.variableName),
                        ).length === 0 ? (
                          <ConditionGroup
                            key={ret.returnTypeId + "item_condition"}
                            title=""
                            initialShow={true}
                            disableHide={true}
                          >
                            <VariableSelect
                              id={ret.returnTypeId}
                              condition={{} as any}
                              variablesName={filteredItemOptions}
                              pushCurrentHash={
                                dispPushCurrentHashForSelectedTab
                              }
                              updateConditon={(id: any, e: any) =>
                                dispAddCondition(id, true, e)
                              }
                              selectedVariableName={""}
                              showPlaceholder={true}
                              errorMessage={ret.itemError}
                            />
                          </ConditionGroup>
                        ) : null}
                        <div className={``}>
                          {ret.conditions
                            .filter((condition) =>
                              itemOptions.includes(condition.variableName),
                            )
                            .map((condition, idx) => {
                              return (
                                <Condition
                                  key={"item_condition_" + condition?.id}
                                  id={ret.returnTypeId}
                                  condition={condition}
                                  deleteCondition={(
                                    conId: string,
                                    id: string,
                                  ) => {
                                    dispDeleteCondition(conId, id, true);
                                  }}
                                  idx={idx}
                                  validateCondition={dispValidateCondition}
                                >
                                  <VariableSelect
                                    id={ret.returnTypeId}
                                    condition={condition}
                                    variablesName={filteredItemOptions}
                                    pushCurrentHash={
                                      dispPushCurrentHashForSelectedTab
                                    }
                                    updateConditon={
                                      dispUpdateConditionForVariables
                                    }
                                    selectedVariableName={
                                      condition.variableName
                                    }
                                  />
                                  <OperatorSelect
                                    condition={condition}
                                    id={ret.returnTypeId}
                                    pushCurrentHash={
                                      dispPushCurrentHashForSelectedTab
                                    }
                                    updateConditon={
                                      dispUpdateConditonForOperator
                                    }
                                    operatorNames={
                                      operatorNames[condition.variableName]
                                    }
                                    selectedOperator={condition.operator}
                                  />
                                  <Values
                                    id={ret.returnTypeId}
                                    condition={condition}
                                    updateCondition={
                                      dispUpdateConditionForValues
                                    }
                                    pushCurrentHash={
                                      dispPushCurrentHashForSelectedTab
                                    }
                                    useNewComponent={true}
                                  />
                                </Condition>
                              );
                            })}
                          <div className="mb-lg-2">
                            <AddConditionButton
                              AddCondition={(id: string) =>
                                dispAddCondition(id, true)
                              }
                              id={ret.returnTypeId}
                              isAddBtnEnabled={isAddItemBtnEnabled}
                              btnText="Add item" //Adding text as per xd
                            />
                          </div>
                        </div>
                      </ConditionGroup>
                    ) : null}

                    <div
                      className={`mt-3 mb-3 cursor-pointer ${styles.enableWrap}`}
                    >
                      <div
                        className={`p-3 d-flex justify-content-between align-items-center`}
                        onClick={() => {
                          setShowInitial(!showInitial);
                        }}
                      >
                        <span
                          className={`${styles.lineText}`}
                          style={
                            showInitial
                              ? {
                                  color: "#0B68BB",
                                }
                              : {
                                  color: "#000000",
                                }
                          }
                        >
                          {" "}
                          Enable/Disable return type
                        </span>
                        <div>
                          {showInitial ? (
                            <>
                              <span
                                className={`${styles.arrowDown} ${styles.activeDrop}`}
                              >
                                <i className={`fa-solid fa-chevron-up`}></i>{" "}
                              </span>
                            </>
                          ) : (
                            <>
                              <span className={`${styles.arrowDown}`}>
                                <i className={`fa-solid fa-chevron-down`}></i>{" "}
                              </span>
                            </>
                          )}
                        </div>
                      </div>
                      {showInitial ? (
                        <>
                          {" "}
                          <div className="px-3 pb-2">
                            <EnableDisableType
                              leadName={"Exchange with same items"}
                              onChange={(value: boolean) => {
                                dispatch(
                                  toggleReturnWithSameItem({
                                    returnTypeId: ret.returnTypeId,
                                    value: value,
                                  }),
                                );
                                dispPushCurrentHashForSelectedTab(
                                  ret.returnTypeId,
                                );
                              }}
                              value={ret.ExchangeWithSameItem}
                            />
                            <EnableDisableType
                              leadName={"Exchange with other items"}
                              onChange={(value: boolean) => {
                                dispatch(
                                  toggleReturnWithOtherItem({
                                    returnTypeId: ret.returnTypeId,
                                    value: value,
                                  }),
                                );
                                dispPushCurrentHashForSelectedTab(
                                  ret.returnTypeId,
                                );
                              }}
                              value={ret.ExchangeWithOtherItem}
                            />
                            <EnableDisableType
                              leadName={
                                "Refund to Store credit (maybe gift card)"
                              }
                              onChange={(value: boolean) => {
                                dispatch(
                                  toggleRefundtoStoreCredit({
                                    returnTypeId: ret.returnTypeId,
                                    value: value,
                                  }),
                                );
                                dispPushCurrentHashForSelectedTab(
                                  ret.returnTypeId,
                                );
                              }}
                              value={ret.RefundtoStoreCredit}
                            />
                            <EnableDisableType
                              leadName={"Refund to original payment method"}
                              onChange={(value: boolean) => {
                                dispatch(
                                  toggleRefundtoOriginalPayment({
                                    returnTypeId: ret.returnTypeId,
                                    value: value,
                                  }),
                                );
                                dispPushCurrentHashForSelectedTab(
                                  ret.returnTypeId,
                                );
                              }}
                              value={ret.RefundToOriginalPayment}
                            />
                          </div>{" "}
                        </>
                      ) : (
                        ""
                      )}
                    </div>

                    <ConditionGroup
                      key={ret.returnTypeId + "message"}
                      title="Reason to be communicated if all return types are not available:"
                      errorMessage={ret?.messageForCustomer?.error}
                    >
                      <MessageSection
                        updateOrder={dispUpdateReturnType}
                        message={{
                          value: ret.messageForCustomer?.value ?? "",
                          error: ret.messageForCustomer?.error,
                        }}
                        pushCurrentHash={dispPushCurrentHashForSelectedTab}
                        validateTabType={dispValidateReturnType}
                        id={ret.returnTypeId}
                        hideHeader={true}
                        text=""
                        hideError={false}
                        insertPlaceholderRef={insertPlaceholderRef}
                      />

                      {/* Variable Section  */}
                      <Variables
                        numberOfItems={3}
                        handleAddVariableOnClick={(value) => {
                          addVariableOnClick(value, insertPlaceholderRef);
                        }}
                      />
                    </ConditionGroup>
                  </Tab.Pane>
                );
              })}
            </Tab.Content>
          </Col>
        </Row>

        <DiscardAndSave
          onSave={dispPostConfig}
          showLoading={updateAjaxStatus === "pending"}
          onDiscard={() => {
            if (!returnAutoWorkFlow.integrationId) {
              return;
            }

            dispatch(
              fetchReturnTypeBasedOnOrderOrItemsThunk({
                integrationId: returnAutoWorkFlow.integrationId,
                defaultResolutionOfferValue: "",
              }),
            );
          }}
        />
      </Tab.Container>
    </div>
  );
};

const ReturnTypeBasedOnOrderOrItems = ({
  onHide,
  onSave = () => {},
}: {
  onHide: () => void;
  onSave?: () => void;
}) => {
  const dispatch = useAppDispatch();
  const { isChanged, intialStep11Config } = useAppSelector(
    (state) => state.returnTypeBasedOnOrderOrItems,
  );
  const [isValidating, setIsValidating] = useState(false);
  const [showDiscardModel, setShowDiscardModal] = useState(false);

  useEffect(() => {
    if (isValidating) {
      if (isChanged) {
        setShowDiscardModal(true);
      } else {
        onHide();
      }
      setIsValidating(false);
    }

    return () => {};
  }, [isChanged, isValidating, onHide]);

  const closeButtonFunctionality = useCallback(() => {
    dispatch(validateChanges());
    setIsValidating(true);
  }, [dispatch]);

  return (
    <div>
      <SkeletonModalStructure
        hasTwoSection={false}
        heading="Select Return Type To Offer Based On The Type Of Order/Item"
        closeFunction={closeButtonFunctionality}
        // rightSide={<AutoWorkFlow type="return" />}
      >
        <ReturnTypeComponent onSave={onSave} />
      </SkeletonModalStructure>
      <Modal
        className={`shadow-sm p-3 rounded`}
        show={showDiscardModel}
        onHide={() => setShowDiscardModal(false)}
        dialogClassName={`${styles.modalDialog2}`}
        contentClassName={`${styles.modalContent2}`}
        backdropClassName={`${styles.modalBackDrop}`}
        enforceFocus={false}
        keyboard={false}
      >
        <DiscardModal
          setShow={setShowDiscardModal}
          closeMainModel={onHide}
          onConfirm={() => {
            undoConfig({
              initialConfig: {
                returnTypes: intialStep11Config.returnTypes,
              },
            });
          }}
        />
      </Modal>
    </div>
  );
};

export default ReturnTypeBasedOnOrderOrItems;
