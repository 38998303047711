import ReactSelect from "react-select";
import styles from "../ReportInternalFilter.module.scss";
import { useCallback, useMemo } from "react";
import {
  reportInternalFiltersActions,
  useReportInternalFilters,
} from "src/routes/Report/hooks/reportFilters/useReportInternalFilters";

const useIntAgentFilter = () => {
  // Create a memoized array of all available agents
  const allAgents = useMemo(() => {
    return [
      { id: "agent", name: "Agent" },
      { id: "lead_agents", name: "Lead Agents" },
      { id: "admin", name: "Admin" },
      { id: "support", name: "Support" },
    ];
  }, []);

  // Create a memoized array of options suitable for a selection component
  const options = useMemo(() => {
    return allAgents.map((data) => ({
      label: data.name,
      value: data,
    }));
  }, [allAgents]);

  // Use a custom hook to get internal filters and dispatch function
  const { internalFilters, dispatch } = useReportInternalFilters();

  // Create a memoized array of selected agents based on internal filters
  const selectedAgents = useMemo(() => {
    return allAgents
      .filter((data) =>
        internalFilters.addedFilters.agentTypes?.includes((data.id + "") as any)
      )
      .map((user) => ({
        label: user.name,
        value: user,
      }));
  }, [internalFilters, allAgents]);

  // Define a callback function for when the selected agents change
  const onChangeAgents = useCallback(
    (value: typeof selectedAgents) => {
      // Dispatch an action to update the internal filters with the selected agent types
      dispatch([
        reportInternalFiltersActions.setFilters,
        { agentTypes: value.map((data) => data.value.id + "") },
      ]);
    },
    [selectedAgents, dispatch]
  );

  // Return the necessary values for the component using this hook
  return {
    options,
    selectedAgents,
    onChangeAgents,
  };
};

function Agent() {
  const { options, selectedAgents, onChangeAgents } = useIntAgentFilter();

  return (
    <div className="px-2 pb-2">
      <label className={`${styles.labelText}`}>Agent</label>
      <ReactSelect
        isMulti
        options={options}
        value={selectedAgents}
        onChange={onChangeAgents as any}
        className={`${styles.text}`}
        menuShouldBlockScroll={true}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
            font: "normal normal normal 12px/18px Poppins",
            color: "#000000",
          }),
          multiValue: (base, { data }) => ({
            ...base,
            backgroundColor: '#F4F4F4', // Background color for selected values
            borderRadius: '6px',
            opacity: 1,
          }),
          multiValueLabel: (base) => ({
            ...base,
            font: "normal normal 500 12px/18px Poppins",
            letterSpacing: "0px",
            color: "#1F2223",
            paddingLeft:"12px",
          }),
          multiValueRemove: (base, { data }) => ({
            ...base,
            // color: '#000000', // Remove icon color for selected values
            fontSize: "6px",
            // ':hover': {
            //   backgroundColor: 'red', // Change the background color on hover if needed
            // },
          }),
        }}
      />
    </div>
  );
}

export default Agent;
