import { useCallback, useMemo, useRef, useState } from "react";
import {
  reportNotificationsActions,
  useNotifications,
} from "../NotificationContainer/hooks/notifications/useNotifications";
import styles from "./CreateNotifyBtn.module.scss";
import { ReportTabNames } from "../ReportTabs/ReportTabs";
import { useReportInternalFilters } from "../../hooks/reportFilters/useReportInternalFilters";
import { useReportFilters } from "../../hooks/reportFilters/useReportFilters";
import { openNotifyErrorModal } from "../NotificationContainer/children/NotifyErrorModal/NotifyErrorModal";

interface Props {
  activeTabname: ReportTabNames;
  setActiveTabname: React.Dispatch<React.SetStateAction<ReportTabNames>>;
}

export const useCreateNewNotify = ({
  activeTabname,
  setActiveTabname,
}: Props) => {
  // Custom hooks for managing filters and notifications
  const { filtersForAPI: globalFiltersApplied } = useReportFilters();
  const { internalFilters: internalFiltersApplied } =
    useReportInternalFilters();

  const { reportNotifications, dispatch } = useNotifications();

  // Ref for storing the current state
  const currentState = useRef({
    globalFiltersApplied,
    internalFiltersApplied,
  });

  // Update the ref when filters change
  useMemo(() => {
    currentState.current.globalFiltersApplied = globalFiltersApplied;
    currentState.current.internalFiltersApplied = internalFiltersApplied;
  }, [globalFiltersApplied, internalFiltersApplied]);

  const createNewNotify = useCallback(() => {
    if (
      reportNotifications.activeView === "list" ||
      activeTabname !== "notify"
    ) {
      if (
        reportNotifications.editingNotificationData?.id === "new" &&
        reportNotifications.activeView === "list"
      ) {
        dispatch([reportNotificationsActions.setActiveView, "edit"]);
      } else {
        const props = {
          globalFiltersApplied: JSON.parse(
            JSON.stringify(currentState.current.globalFiltersApplied ?? {}),
          ),
          internalFiltersApplied: JSON.parse(
            JSON.stringify(
              currentState.current.internalFiltersApplied?.filters ?? {},
            ),
          ),
          sideBarFiltersApplied: JSON.parse(
            JSON.stringify(
              currentState.current.internalFiltersApplied?.sidebarFilters ?? {},
            ),
          ),
        };
        if (Object.keys(props.sideBarFiltersApplied).length) {
          // Dispatch an action to set the editing notification data
          dispatch([
            reportNotificationsActions.setNewEditingNotificationData,
            props,
          ]);
          dispatch([reportNotificationsActions.setActiveView, "edit"]);
          setActiveTabname("notify");
        } else {
          setActiveTabname("listView");
          openNotifyErrorModal();
        }
      }
    }
  }, [dispatch, activeTabname, setActiveTabname, reportNotifications]);

  return createNewNotify;
};

function CreateNotifyBtn({ activeTabname, setActiveTabname }: Props) {
  const createNewNotify = useCreateNewNotify({
    activeTabname,
    setActiveTabname,
  });

  return (
    <div className="d-flex justify-content-md-end mb-2 mb-md-0">
      <div
        className={`px-2 py-1 d-flex align-items-center ${styles.createBox} cursor-pointer`}
        onClick={createNewNotify}
      >
        <span className={`${styles.bell}`}>
          <i className="fa-solid fa-bell"></i>
        </span>
        <span className={`ps-2 ${styles.notificationtext}`}>
          Create Notification
        </span>
      </div>
    </div>
  );
}

export default CreateNotifyBtn;
