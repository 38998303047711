import { axiosJSON } from "src/globals/axiosEndPoints";
import { Phone } from "src/store/slices/editCustomerDetails/editCustomerDetails.declarations";
import { getErrorMessage } from "./addUser";

export interface UpdateUserParams {
  userId: number;
  firstName?: string;
  lastName?: string;
  phone?: Phone | null;
  userRole?: number | string;
  teamIds?: Array<number | string>;
  userTimezone?: string;
  userTimezoneType?: string;
  chatAcceptStatus?: boolean; //to update chat accept status of an agent
}

export interface UpdateUser {
  userId: number;
}

export async function updateUser(params: UpdateUserParams) {
  if (params.firstName !== undefined || params.lastName !== undefined) {
    if (!(params.firstName && params.lastName)) {
      throw "Please define both the names!";
    }
  }
  if (
    params.userTimezoneType !== undefined &&
    (params.userTimezoneType === "manual" || params.userTimezoneType === "auto")
  ) {
    if (!(params.userTimezone && params.userTimezone !== "")) {
      throw "Timezone can not be empty!";
    }
  } else {
    if (params.userTimezone) {
      delete params.userTimezone;
    }
    if (params.userTimezoneType) {
      delete params.userTimezoneType;
    }
  }

  if (params.phone?.phoneNumber === "") {
    delete params.phone;
  }

  if ((params.userTimezoneType + "").trim() === "") {
    delete params.userTimezoneType;
  }
  const { data: res } = await axiosJSON.post(
    "/api/setting/user/update",
    params,
  );

  if (res.error === true) {
    if (
      (res?.status_code && res?.status_code?.includes("role_not_found")) ||
      res?.status_code?.includes("team_not_found") ||
      res?.status_code?.includes("email_already_exist") ||
      res?.status_code?.includes("user_not_found")
    ) {
      throw new Error(getErrorMessage(res.status_code));
    } else {
      throw new Error(res.message as string);
    }
  }

  return res.data as UpdateUser;
}
