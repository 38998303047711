import { useAppDispatch, useAppSelector } from "src/store/store";
import useResetBulkActions from "src/hooks/useResetBulkActions";
import { bulkAssignTickets } from "src/store/slices/ticketUsers/ticketUsers.slice";
import BaseFooterButtonLight from "src/components/BulkActionComponents/commons/BaseFooterButtonLight";
import BaseFooterButtonSolid from "src/components/BulkActionComponents/commons/BaseFooterButtonSolid";

const ModalFooter = ({ setModalShow }: any) => {
  const resetBulkActions = useResetBulkActions();
  const userSelected = useAppSelector(
    (state) => state.ticketUsers.userSelected
  );
  const bulkAssignTicketsState = useAppSelector(
    (state) => state.ticketUsers.bulkAssignTicketsState
  );
  const dispatch = useAppDispatch();

  const handleOnClickAddTicketTags = (e: any) => {
    e.preventDefault();
    dispatch(bulkAssignTickets());
  };

  const handleOnClickExit = (e: any) => {
    e.preventDefault();
    resetBulkActions();
  };

  const handleOnClickAddMore = (e: any) => {
    e.preventDefault();
    setModalShow(false);
    resetBulkActions(); //resetting bulk action filters, in new select all filter implementation we can't let user add more cause select all works based on filters
  };

  return (
    <>
      {bulkAssignTicketsState === "fulfilled" ? (
        <div className={`w-100 d-flex justify-content-between`}>
          <BaseFooterButtonLight onClick={handleOnClickExit} id="exitBulk">
            Exit Bulk actions
          </BaseFooterButtonLight>

          <BaseFooterButtonSolid onClick={handleOnClickAddMore} id="addMore">
            Add more
          </BaseFooterButtonSolid>
        </div>
      ) : (
        <BaseFooterButtonSolid
          onClick={handleOnClickAddTicketTags}
          style={userSelected === -1 ? { opacity: "50%" } : undefined}
          disabled={userSelected === -1 ? true : false}
          id="addBtn"
        >
          Add
        </BaseFooterButtonSolid>
      )}
    </>
  );
};

export default ModalFooter;
