import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";
import { AutomationAPIPayload } from "./createAutomation.service";

export interface SelectDDParams {
  stepId: string;
  questionKey: string;
  start: number;
  limit: number;
}

export interface SelectDDOption {
  id: string;
  value: string;
}

export const getQuestionSelectDDService = async ({
  type,
  payload,
}: AutomationAPIPayload<SelectDDParams>) => {
  const url = IS_TEST_ACTIVE
    ? `${process.env.REACT_APP_TEST_SITE_URL}/bugManagement/getQuestionSelectDD`
    : `/api/automation/${type}/getQuestionSelectDD`;

  const { data: res } = await axiosJSON.post(url, payload);

  if (res.err || res.error) {
    throw new Error(
      res.msg ||
        res.message ||
        "Could not Get the automation question Select Dropdown",
    );
  }

  return res.data as SelectDDOption[];
};
