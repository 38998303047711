/**
 * This file is the react component file.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 * @author Navjyot
 */

import { useState } from "react";
import { Modal } from "react-bootstrap";
import styles from "./ComparisonPart.module.scss";
import ComparisionModal from "../ComparisionModal/ComparisionModal";
import { useReportFiltersCreate } from "src/routes/Report/hooks/reportFilters/useReportFilters";
import { CurrentStatusEndPoints } from "src/services/report/reportTicketList.service";

// Interface for component props
interface Props {
  endPoint: CurrentStatusEndPoints;
}

// ComparisonPart component definition
function ComparisonPart({ endPoint }: Props) {
  // Custom hook for managing report filters creation state
  const [state, dispatch, Provider] = useReportFiltersCreate();
  const [showModal, setShowModal] = useState(false);

  // Handler to show the modal
  const onShow = () => {
    setShowModal(true);
  };

  // Handler to hide the modal
  const onHide = () => {
    setShowModal(false);
  };

  // Render the component UI
  return (
    <Provider value={[state, dispatch]}>
      <div className="ms-2">
        <span className={`px-2 ${styles.addComp}`} onClick={onShow}>
          + Add comparison
        </span>
        {/* Modal for creating a segment with filters */}
        <Modal
          backdropClassName={`${styles.modalBack}`}
          show={showModal}
          onHide={onHide}
          dialogClassName={`${styles.modalDialog}`}
          contentClassName={`${styles.modalContent}`}
          //   centered={true}
          enforceFocus={false}
        >
          {/* ComparisionModal component */}
          <ComparisionModal onHide={onHide} endPoint={endPoint} />
        </Modal>
      </div>
    </Provider>
  );
}

export default ComparisonPart;
