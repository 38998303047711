import hLogo from "src/assets/images/hLogo.png";
import styles from "./UnPublishModal.module.scss";

interface Props {
  handleOnOkay: () => void;
  successHeading: string;
  successMessage: string;
  publishLoader: boolean;
  handleGoLive: (e: any) => void;
}
const UnPublishModal = ({
  handleOnOkay,
  successHeading,
  successMessage,
  handleGoLive,
  publishLoader,
}: Props) => {
  return (
    <div className={styles.wrapper}>
      {!publishLoader ? (
        <div>
          <div className="text-center">
            <span className={`mx-auto mb-3 ${styles.successIcon}`}>
              <i className="fa-solid fa-check"></i>
            </span>
            <p className={`mb-0 ${styles.successHead}`}>{successHeading}</p>
            <span className={`d-block mb-3 ${styles.successSpan}`}>
              {successMessage}
            </span>
            <div className="d-flex justify-content-center align-items-center">
              <button
                className={`me-4 px-2 ${styles.unpublishBtn}`}
                onClick={handleGoLive}
              >
                <span className="pe-1">
                  <i className="fa-solid fa-eye"></i>
                </span>
                Go live
              </button>
              <button
                className={`${styles.okayBtn}`}
                onClick={handleOnOkay}
              >
                Okay
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className={`position-relative ${styles.loaderWrapper}`}>
          <span
            className="spinner-border"
            role="status"
          ></span>
          <img
            src={hLogo}
            alt=""
            className={`${styles.loaderImage} position-absolute`}
          />
        </div>
      )}
    </div>
  );
};

export default UnPublishModal;
