/**
 * This file is the React component file for displaying a list of articles with durations or other relevant information.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 * @author Navjyot
 */

import { CurrentStatusEndPoints } from "src/services/report/reportTicketList.service";
import styles from "./ArticleDurationList.module.scss";
import { ReportDateType } from "src/routes/Report/hooks/reportFilters/useReportInternalFilters";
import useReportTicketList from "src/routes/Report/Children/ReportTicketList/hooks/useReportTicketList";
import { useState } from "react";
import Loader from "src/components/Loader";
import FoundNoTickets from "src/components/FoundNoTickets";

// Define the interface for the component's props
interface Props {
  endPoint: CurrentStatusEndPoints;
  reportDateType?: ReportDateType;
}

// React component for displaying a list of articles with durations or other relevant information
const ArticleDurationList = ({ endPoint, reportDateType }: Props) => {
  // Destructuring values from the custom hook
  const { reportTicketList, status, pageIntegrationId } = useReportTicketList({
    endPoint,
    reportDateType,
  });

  // State for controlling the overlay
  const [showOverlay, setShowOverlay] = useState(false);

  const isBotReport =
    (endPoint.includes("mostUsedArticlesByCernToAnswerQuestions") ||
      endPoint.includes("mostUsedCustomAnsByCernToAnswerQuestions")) ??
    false;

  return (
    // Container div with a fixed height and scroll for the content
    // Added height conditionally for bot report as it is causing flickering issue
    <div
      style={{ height: endPoint.includes("bot") ? "400px" : "300px" }}
      className="overflow-auto"
    >
      {/* Header for the article list table */}
      <div className={`${styles.tableHeader}`}>
        <span className={`${styles.tableHead}`}>
          {/* Conditionally render the column name based on the endpoint */}
          {endPoint.includes("articlesMostRead")
            ? "Article Name"
            : endPoint.includes("ticketsResolvedDueToKb")
            ? "Article"
            : isBotReport
            ? "Articles used by cern"
            : "Article"}
        </span>
        <span
          className={`${styles.tableText} ${
            isBotReport && styles.botTableText
          }`}
        >
          {/* Conditionally render the column text based on the endpoint */}
          {endPoint.includes("articlesMostRead")
            ? `Duration(${
                reportTicketList?.metaData?.totalArticleDuration ?? 0
              })`
            : endPoint.includes("ticketsResolvedDueToKb")
            ? "Number of tickets"
            : isBotReport
            ? "Number of times used by cern"
            : "Rating"}
        </span>
      </div>

      {/* Render the article list based on the API response status */}
      {status === "success" && reportTicketList?.ticketIds.length ? (
        // If data is available, map through the ticketIds and render each article
        reportTicketList?.ticketIds.map((ticket, index) => {
          const data = reportTicketList?.ticketData[ticket];
          return (
            // Clickable div for each article, opening a new window with article details
            <div
              className={`${styles.tableBody}`}
              onClick={() => {
                window.open(
                  `${window.location.origin}/knowledge-base/${pageIntegrationId}/articles/${data.article_id}`
                );
              }}
            >
              {/* Article details content */}
              <div className={`mb-1 ${styles.tableContent}`}>
                <div className={`${styles.hoverBar}`}></div>
                <div
                  className={`${styles.contentWrapper} ${
                    isBotReport && styles.botContentWrapper
                  }`}
                >
                  <p className={`mb-0 pe-2 ${styles.contentHead}`}>
                    {/* Article name */}
                    {data.article_name}
                  </p>
                  <span className={`pe-2 ${styles.contentDesc}`}>
                    {/* Article description */}
                    {data.article_description}
                  </span>
                </div>
                <span
                  className={`${styles.contentTime} ${
                    isBotReport ? `${styles.botTableContent} ps-5` : ""
                  }`}
                >
                  {/* Additional article information | no of times cern used in case bot reports*/}
                  {isBotReport
                    ? data.article_count
                    : data.article_right_column_count ?? 0}
                </span>
              </div>
            </div>
          );
        })
      ) : status === "loading" ? (
        // Display a loader while data is being fetched
        <>
          <div className={`${styles.loaderSet}`}>
            <Loader className={styles.loading} />
          </div>
        </>
      ) : (
        // Display a message if no articles are found
        <FoundNoTickets
          className={styles.notFound}
          text={
            endPoint.includes("mostUsedCustomAnsByCernToAnswerQuestions")
              ? "No Custom Answers Found"
              : "No Articles Found"
          }
        />
      )}
    </div>
  );
};

export default ArticleDurationList;
