import { axiosJSON } from "src/globals/axiosEndPoints";

interface IDeleteRemark {
  msgId: number;
  remarkId: number;
}

export async function deleteDraftRemark(servicePayload: IDeleteRemark) {
  const { data } = await axiosJSON.post(
    `/api/ticket/deleteDraftRemark`,
    servicePayload
  );
  if (data.err === true) {
    throw new Error(data.msg as string);
  }
  return data.data;
}
