import { axiosJSON } from "src/globals/axiosEndPoints";

export async function linkSocialAccount(route: string) {
  const response = await axiosJSON.get(route);

  const { data: res } = response;

  if (res.err === true) {
    throw new Error(res.msg as string);
  }

  return res.data as { url: string };
}
