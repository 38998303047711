import styles from "../../ConfigureSteps.module.scss";
import Lottie from "lottie-light-react";
import ripple from "src/assets/images/ripple.json";
import { QuestionWrapper, RadioWrapper } from "../Wrappers/Wrappers";
import { useCallback, useState } from "react";
import { Modal } from "react-bootstrap";
import ConfigureEmailModal from "./ConfigureEmailModal/ConfigureEmailModal";
import { ConfigureSteps } from "../../useConfigureSteps";
import FollowUpEmailModal from "./FollowUpEmailModal/FollowUpEmailModal";
import { UseMutateFunction } from "@tanstack/react-query";
import { CreateBotProfileResponse } from "src/services/Bot/BotProfiles/createBotProfile.service";
import { UpdateBotProfileParams } from "src/services/Bot/BotProfiles/updateBotProfile.service";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import AdvanceBotModal from "./AdvanceBotModal/AdvanceBotModal";

// Adding move to next button to a callback to optimize
const MoveToNextButton = ({ onClick }: { onClick: any }) => {
  const buttonStyle = { width: "33px", height: "33px" };

  return (
    <div>
      <button
        className={`${styles.moveBtn}`}
        onClick={onClick}
      >
        <span style={buttonStyle}>
          <Lottie animationData={ripple} />
        </span>
        <span>Move to next question</span>
      </button>
    </div>
  );
};

/**
 * Component for managing email settings in bot configuration.
 * updateBotSettings Function to update bot settings.
 * refetchGetBot Function to refetch bot settings.
 * configureSteps Instance of ConfigureSteps for managing configuration steps.
 */
function EmailSettings({
  updateBotSettings,
  refetchGetBot,
  configureSteps,
  showGoLive,
  showLiveChatSettings,
}: {
  updateBotSettings: UseMutateFunction<
    CreateBotProfileResponse,
    unknown,
    UpdateBotProfileParams,
    unknown
  >;
  refetchGetBot: () => void;
  configureSteps: ConfigureSteps;
  showGoLive: boolean;
  showLiveChatSettings: boolean;
}) {
  const {
    state,
    currentActiveStep,
    setCurrentActiveStep,
    dispatch,
    handleMoveToNextStep,
    // isConfigureEmailCompleted,
    // isFollowupEmailCompleted,
    variablesList,
    variableIdsList,
  } = configureSteps;
  const showAudience = true;

  /**
   * Handles the change in email settings.
   */
  const handleEmailAnswersChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      // Extracting name and value from event attributes
      const name = e.target.getAttribute("name");
      let value: any = e.target.getAttribute("data-value");
      if (value === "true") {
        value = true;
      }
      if (value === "false") {
        value = false;
      }
      // Dispatches update to bot settings
      if (name && value != null) {
        dispatch({
          type: "UPDATE_ANY_DETAILS",
          payload: { key: name, value },
        });
        // Updates bot settings via API call
        updateBotSettings(
          {
            id: state.botProfile.id,
            [name]: value,
          },
          {
            onSuccess: (data) => {
              if (data.statusCode && data.statusCode !== undefined) {
                refetchGetBot();
                dispatch({
                  type: "UPDATE_STATUS",
                  payload: "draft",
                });
              }
            },
          },
        );
      }
    },
    [state, dispatch, updateBotSettings, refetchGetBot],
  );

  const handleEmailModalDataChange = useCallback(
    (key: string, value: any) => {
      dispatch({
        type: "UPDATE_ANY_DETAILS",
        payload: { key: key, value },
      });
    },
    [dispatch],
  );

  const [showEmail, setShowEmail] = useState(false);
  const [showFollowup, setShowFollowup] = useState(false);
  // function to show configure email modal
  const showEmailModal = () => {
    setShowEmail(true);
  };
  // function to hide configure email modal
  const hideEmailModal = () => {
    setShowEmail(false);
  };
  // function to show configure followup questions modal
  const showFollowupModal = () => {
    setShowFollowup(true);
  };
  // function to hide configure followup questions modal
  const hideFollowupModal = () => {
    setShowFollowup(false);
  };

  /**
   * Moves to the next step based on current email settings.
   */
  const moveToNext = useCallback(() => {
    // Logic to determine next step based on email settings
    if (state.botProfile.email_inbound + "" === "3") {
      if (state.botProfile.channels.includes("4")) {
        handleMoveToNextStep("email_inbound_option_3");
      } else {
        pushTheToast({
          text: "Please select the live chat in channels or please select any other option.",
          type: "warning",
          position: "top-right",
        });
      }
    } else if (
      state.botProfile.visibleQuestionKeys?.includes("email_configure") ||
      state.botProfile.visibleQuestionKeys?.includes("email_followup_configure")
    ) {
      if (state.botProfile.email_followup_configure != undefined) {
        handleMoveToNextStep("email_followup_configure");
      } else {
        pushTheToast({
          text: "Please select the answers...",
          type: "warning",
          position: "top-right",
        });
      }
    } else if (
      state.botProfile.visibleQuestionKeys?.includes(
        "email_automatically_close_ticket",
      )
    ) {
      handleMoveToNextStep("email_configure");
    } else if (
      state.botProfile.visibleQuestionKeys?.includes(
        "email_handle_ticket_assigned_to_agents",
      )
    ) {
      if (state.botProfile.email_automatically_close_ticket != undefined) {
        handleMoveToNextStep("email_automatically_close_ticket");
      } else {
        pushTheToast({
          text: "Please select the answers...",
          type: "warning",
          position: "top-right",
        });
      }
    } else if (
      state.botProfile.visibleQuestionKeys?.includes("email_inbound")
    ) {
      if (
        state.botProfile.email_handle_ticket_assigned_to_agents != undefined
      ) {
        handleMoveToNextStep("email_handle_ticket_assigned_to_agents");
      } else {
        pushTheToast({
          text: "Please select the answers...",
          type: "warning",
          position: "top-right",
        });
      }
    } else if (
      state.botProfile.visibleQuestionKeys?.includes("answer_content")
    ) {
      if (state.botProfile.email_inbound != undefined) {
        handleMoveToNextStep("email_inbound");
      } else {
        pushTheToast({
          text: "Please select the answers...",
          type: "warning",
          position: "top-right",
        });
      }
    }
  }, [state, handleMoveToNextStep]);

  return (
    <div
      className="d-flex position-relative mb-2"
      id={`email_${state.botProfile.id}`}
    >
      <div className={`d-flex flex-column align-items-center me-2`}>
        <div>
          <span className={`${showAudience ? styles.doneCount : styles.count}`}>
            {showAudience ? (
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
            ) : (
              <span>2</span>
            )}
          </span>
        </div>

        <div className={`${styles.statusBar} ${styles.statusBarOne}`}></div>
      </div>

      <div className={`p-3 w-100 ${styles.stepBox}`}>
        <div
          className="cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setCurrentActiveStep(currentActiveStep === 2 ? 0 : 2);
          }}
        >
          <h6 className={`mb-0 ${styles.stepHead}`}>Email</h6>
          <span className={`${styles.stepSpan}`}>
            E-mail specific bot settings.
          </span>
        </div>
        {currentActiveStep === 2 && (
          <>
            <QuestionWrapper
              question={`Q5. How would you like Cern to handle inbound emails?`}
            >
              <RadioWrapper
                name="email_inbound"
                label="Yes, Cern can respond to emails on its own."
                checked={state.botProfile.email_inbound + "" === "1"}
                onChange={handleEmailAnswersChange}
                data-value="1"
              />
              <RadioWrapper
                name="email_inbound"
                label="Cern can only draft for emails if it can, the brand's customer service team will go through the drafts and send out any that are ok to send."
                checked={state.botProfile.email_inbound + "" === "2"}
                onChange={handleEmailAnswersChange}
                data-value="2"
              />
              <RadioWrapper
                name="email_inbound"
                label="I do not want Cern to be involved with my emails."
                checked={state.botProfile.email_inbound + "" === "3"}
                onChange={handleEmailAnswersChange}
                data-value="3"
              />
              {/* June 6 Adding move to next button conditionally to make adjacent with config buttons */}
              {(state.botProfile.email_inbound + "" === "3" ||
                (state.botProfile.email_inbound + "" !== "3" &&
                  !state.botProfile.visibleQuestionKeys?.includes(
                    "email_inbound",
                  ))) && <MoveToNextButton onClick={moveToNext} />}
            </QuestionWrapper>
            {state.botProfile.email_inbound + "" !== "3" && (
              <>
                {state.botProfile.visibleQuestionKeys?.includes(
                  "email_inbound",
                ) && (
                  <QuestionWrapper
                    question={`Q6. Would you like Cern to handle tickets assigned to an agent or tickets where an agent is already talking to a customer?`}
                  >
                    <RadioWrapper
                      name="email_handle_ticket_assigned_to_agents"
                      label="Yes, handle any emails that come in, regardless of whether an agent had previously been handling the ticket or not."
                      checked={
                        state.botProfile
                          .email_handle_ticket_assigned_to_agents +
                          "" ===
                        "1"
                      }
                      onChange={handleEmailAnswersChange}
                      data-value="1"
                    />
                    <RadioWrapper
                      name="email_handle_ticket_assigned_to_agents"
                      label="No, any tickets that are assigned to an agent or were previously being handled by an agent should not be handled by Cern."
                      checked={
                        state.botProfile
                          .email_handle_ticket_assigned_to_agents +
                          "" ===
                        "2"
                      }
                      onChange={handleEmailAnswersChange}
                      data-value="2"
                    />
                    <RadioWrapper
                      name="email_handle_ticket_assigned_to_agents"
                      label="You can handle such tickets, that are assigned to an agent or were previously being handled by an agent, but please only draft for these tickets."
                      checked={
                        state.botProfile
                          .email_handle_ticket_assigned_to_agents +
                          "" ===
                        "3"
                      }
                      onChange={handleEmailAnswersChange}
                      data-value="3"
                    />
                    {/* June 6 Adding move to next button conditionally to make adjacent with config buttons */}
                    {!state.botProfile.visibleQuestionKeys?.includes(
                      "email_handle_ticket_assigned_to_agents",
                    ) && <MoveToNextButton onClick={moveToNext} />}
                  </QuestionWrapper>
                )}
                {state.botProfile.visibleQuestionKeys?.includes(
                  "email_handle_ticket_assigned_to_agents",
                ) && (
                  <QuestionWrapper
                    question={`Q7. When a customer receives a response from the bot, and reports that their issue has been resolved, would you like us to automatically close that ticket? Or, would you like us to keep it open for your review?`}
                  >
                    <RadioWrapper
                      name="email_automatically_close_ticket"
                      label="Yes"
                      checked={
                        state.botProfile.email_automatically_close_ticket ===
                        true
                      }
                      onChange={handleEmailAnswersChange}
                      data-value="true"
                    />
                    <RadioWrapper
                      name="email_automatically_close_ticket"
                      label="No"
                      checked={
                        state.botProfile.email_automatically_close_ticket ===
                        false
                      }
                      onChange={handleEmailAnswersChange}
                      data-value="false"
                    />
                    {/* June 6 Adding move to next button conditionally to make adjacent with config buttons */}
                    {!state.botProfile.visibleQuestionKeys?.includes(
                      "email_automatically_close_ticket",
                    ) && <MoveToNextButton onClick={moveToNext} />}
                  </QuestionWrapper>
                )}
                {state.botProfile.visibleQuestionKeys?.includes(
                  "email_automatically_close_ticket",
                ) && (
                  <QuestionWrapper
                    question={`Q8. Configure how the email with Cern's response will look like.`}
                  >
                    <div className="d-flex">
                      <button
                        className={`me-2 ${styles.configureBtn}`}
                        onClick={showEmailModal}
                      >
                        {state?.botProfile?.emailConfigureModelUpdated ? (
                          <span className={`${styles.checkLogo} mx-1 px-1`}>
                            <i className="fa-solid fa-check"></i>
                          </span>
                        ) : (
                          <span className={`pe-1`}>+</span>
                        )}{" "}
                        Configure E-mail
                      </button>
                      <Modal
                        backdropClassName={`${styles.modalBack}`}
                        show={showEmail}
                        onHide={hideEmailModal}
                        dialogClassName={`${styles.modalDialog}`}
                        contentClassName={`${styles.modalContent}`}
                        enforceFocus={false}
                      >
                        <ConfigureEmailModal
                          onHide={hideEmailModal}
                          profileId={state?.botProfile?.id}
                          data={
                            state?.botProfile?.emailConfigureModel ?? {
                              salutation: "",
                              introduction: "",
                              questionRegardingResolution: "",
                            }
                          }
                          handleEmailModalDataChange={
                            handleEmailModalDataChange
                          }
                          variablesList={variablesList}
                          variableIdsList={variableIdsList}
                          companyName={state?.botProfile?.brandData?.name ?? ""}
                        />
                      </Modal>
                      {/* June 6 Adding move to next button conditionally to make adjacent with config buttons */}
                      {!state.botProfile.visibleQuestionKeys?.includes(
                        "email_configure",
                      ) &&
                        state?.botProfile?.emailConfigureModelUpdated && (
                          <MoveToNextButton onClick={moveToNext} />
                        )}
                    </div>
                  </QuestionWrapper>
                )}
                {state.botProfile.visibleQuestionKeys?.includes(
                  "email_configure",
                ) && (
                  <QuestionWrapper
                    question={`Q9. If the customer indicates that Cern's response did not resolve their query, would you like to send them a follow up email, potentially asking for additional details?`}
                  >
                    <RadioWrapper
                      name="email_followup_configure"
                      label="Yes"
                      checked={
                        state.botProfile.email_followup_configure === true
                      }
                      onChange={handleEmailAnswersChange}
                      data-value="true"
                    />
                    <RadioWrapper
                      name="email_followup_configure"
                      label="No"
                      checked={
                        state.botProfile.email_followup_configure === false
                      }
                      onChange={handleEmailAnswersChange}
                      data-value="false"
                    />
                    <div className="d-flex">
                      {state.botProfile.email_followup_configure && (
                        <div>
                          <button
                            className={`me-2 ${styles.configureBtn}`}
                            onClick={showFollowupModal}
                          >
                            {state?.botProfile
                              ?.emailFollowupConfigureModelUpdated ? (
                              <span className={`${styles.checkLogo} mx-1 px-1`}>
                                <i className="fa-solid fa-check"></i>
                              </span>
                            ) : (
                              <span className={`pe-1`}>+</span>
                            )}{" "}
                            Configure Followup E-mail
                          </button>
                          <Modal
                            backdropClassName={`${styles.modalBack}`}
                            show={showFollowup}
                            onHide={hideFollowupModal}
                            dialogClassName={`${styles.modalDialog}`}
                            contentClassName={`${styles.modalContent}`}
                            enforceFocus={false}
                          >
                            <FollowUpEmailModal
                              onHide={hideFollowupModal}
                              profileId={state.botProfile.id}
                              data={
                                state?.botProfile
                                  ?.emailFollowupConfigureModel ?? {
                                  body: "",
                                  salutation: "",
                                }
                              }
                              handleEmailModalDataChange={
                                handleEmailModalDataChange
                              }
                              variablesList={variablesList}
                              variableIdsList={variableIdsList}
                              companyName={
                                state?.botProfile?.brandData?.name ?? ""
                              }
                            />
                          </Modal>
                        </div>
                      )}
                      {/* Move next button */}
                      {showGoLive ||
                      showLiveChatSettings ||
                      (state.botProfile.email_followup_configure &&
                        !state?.botProfile
                          ?.emailFollowupConfigureModelUpdated) ? null : (
                        <MoveToNextButton onClick={moveToNext} />
                      )}
                    </div>
                  </QuestionWrapper>
                )}
                {/* <QuestionWrapper
                  question={`Q8 . For post purchase emails, would you like Cern to automatically share the automation workflow links?`}
                >
                  <RadioWrapper
                    name="purchaseEmail"
                    label="Yes"
                    checked={false}
                    onChange={() => {}}
                    data-value="true"
                  />
                  <RadioWrapper
                    name="purchaseEmail"
                    label="No"
                    checked={false}
                    onChange={() => {}}
                    data-value="false"
                  />
                  {false && (
                    <div className="d-flex">
                      <div>
                        <button
                          className={`${styles.moveBtn}`}
                          onClick={moveToNext}
                        >
                          <span style={{ width: "33px", height: "33px" }}>
                            <Lottie animationData={ripple}></Lottie>
                          </span>
                          <span>Move to next question</span>
                        </button>
                      </div>
                    </div>
                  )}
                </QuestionWrapper> */}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default EmailSettings;
