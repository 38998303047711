import { useCallback, useEffect, useRef, useState } from "react";
import { Col, Modal, Nav, Row, Tab } from "react-bootstrap";
import AddConditionButton from "src/components/AutomationComponents/ContentSection/AddConditionButton";
import Condition from "src/components/AutomationComponents/ContentSection/Condition";
import ContentHeading from "src/components/AutomationComponents/ContentSection/ContentHeading";
import MessageSection from "src/components/AutomationComponents/ContentSection/MessageSection";
import OperatorSelect from "src/components/AutomationComponents/ContentSection/OperatorSelect";
import Values from "src/components/AutomationComponents/ContentSection/Values";
import Variables, {
  addVariableOnClick,
} from "src/components/AutomationComponents/ContentSection/VariableSection";
import VariableSelect from "src/components/AutomationComponents/ContentSection/VariableSelect";
import DiscardAndSave from "src/components/AutomationComponents/DiscardAndSave";
import DiscardModal from "src/components/AutomationComponents/DiscardModal/DiscardModal";
import SkeletonModalStructure from "src/components/AutomationComponents/SkeletonModalStructure/SkeletonModalStructure";
import AddMore from "src/components/AutomationComponents/TabSection/AddMore";
import AutomationTab from "src/components/AutomationComponents/TabSection/AutomationTab";
import CloseButtonForTab from "src/components/AutomationComponents/TabSection/CloseButtonForTab";
import ConditionGroup from "src/components/AutomationComponents/TabSection/ConditionGroup";
import TabError from "src/components/AutomationComponents/TabSection/TabError";
import TabName from "src/components/AutomationComponents/TabSection/TabName";
import Loader from "src/components/Loader";
import { useReturnAutoWorkFlow } from "src/features/ReturnAutoWorkFlow/hooks/useReturnAutoWorkFlow";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  deleteItemType,
  setSelectedItemTypeId,
  undoConfig,
  validateChanges,
} from "../../store/slices/NonEligibleItemsSlice/nonEligibleItems.slice";
import { fetchNonEligibleItemsThunk } from "../../store/slices/NonEligibleItemsSlice/nonEligibleItems.thunk";
import styles from "./NonEligibleItems.module.scss";
import useNonEligibleItems from "./useNonEligibleItems";

const NonEligibleItemsComponent = ({
  onSave,
}: {
  onSave: (isSaved: boolean) => void;
}) => {
  const { returnAutoWorkFlow } = useReturnAutoWorkFlow();
  const dispatch = useAppDispatch();

  const {
    dispAddCondition,
    dispAddTab,
    dispDeleteCondition,
    dispPostConfig,
    dispPushCurrentHashForSelectedTab,
    dispUpdateConditionForValues,
    dispUpdateConditionForVariables,
    dispUpdateConditonForOperator,
    dispUpdateItemType,
    dispValidateCondition,
    dispValidateItemType,
    itemTypes,
    fetchAjaxStatus,
    updateAjaxStatus,
    initialHash,
    currentHash,
    selectedItemTypeId,
    isAddBtnEnabled,
    isAddItemBtnEnabled,
    defaultOptions,
    itemOptions,
    conditionOptions,
    operators,
  } = useNonEligibleItems({ onSave });

  const insertPlaceholderRef = useRef(null as any);

  if (fetchAjaxStatus === "pending") {
    return (
      <div className={`${styles.loaderHeight}`}>
        <Loader />
      </div>
    );
  }

  return (
    <div>
      <Tab.Container
        id="left-tabs-example"
        activeKey={selectedItemTypeId ?? ""}
        onSelect={(eventKey) => {
          if (selectedItemTypeId !== eventKey) {
            dispatch(setSelectedItemTypeId({ itemTypeId: eventKey ?? "" }));
          }
        }}
      >
        <Row>
          <Col>
            <Nav
              variant="tabs"
              className={`d-flex align-items-center border-0 ${styles.tabSectionWrapper} `}
            >
              {itemTypes.map((item, idx) => {
                return (
                  <Nav.Item
                    role="button"
                    key={item.itemTypeId + "tabItem"}
                  >
                    <Nav.Link
                      eventKey={item.itemTypeId}
                      className={`py-1 px-3  text-dark shadow-sm  ${styles.tabStyles}`}
                      style={{
                        background:
                          selectedItemTypeId !== null
                            ? selectedItemTypeId === item.itemTypeId
                              ? item.errorCount > 0
                                ? "#FFDCDC 0% 0% no-repeat padding-box"
                                : "#FFFFFF 0% 0% no-repeat padding-box "
                              : item.errorCount > 0
                                ? "#FFDCDC 0% 0% no-repeat padding-box"
                                : "#f5f5f5 0% 0% no-repeat padding-box"
                            : "",
                      }}
                    >
                      <AutomationTab idx={idx}>
                        <TabName
                          id={item.itemTypeId}
                          name={item.itemTypeName}
                          updateType={dispUpdateItemType}
                          pushCurrentHash={dispPushCurrentHashForSelectedTab}
                        />

                        <TabError errorCount={item.errorCount} />

                        <div className="ms-1">
                          <CloseButtonForTab
                            index={idx}
                            name={item.itemTypeName}
                            hasStaticTab={false}
                            onDelete={() => {
                              dispatch(deleteItemType({ id: item.itemTypeId }));
                            }}
                          />
                        </div>
                      </AutomationTab>
                    </Nav.Link>
                  </Nav.Item>
                );
              })}
              <AddMore
                AddTab={dispAddTab}
                text=" Add more custom item type"
                selectedTabId={selectedItemTypeId}
                validateTabType={dispValidateItemType}
              />
            </Nav>
            <Tab.Content
              className={`shadow ${styles.contentStyles} pt-4  px-4 ${
                initialHash &&
                currentHash &&
                initialHash === currentHash &&
                styles.noDiscardAndSave
              }`}
            >
              {itemTypes.map((item, idx) => {
                const allSelectedConditionNames: string[] = item.conditions.map(
                  (condition) => condition.variableName,
                );

                const filteredConditions = conditionOptions.filter(
                  (condition) => !allSelectedConditionNames.includes(condition),
                );

                const filteredItemOptions = itemOptions.filter(
                  (condition) => !allSelectedConditionNames.includes(condition),
                );

                return (
                  <Tab.Pane
                    key={item.itemTypeId + "tabPane"}
                    eventKey={item.itemTypeId}
                    unmountOnExit={true}
                    onEnter={() => {
                      dispValidateCondition(item.itemTypeId);
                    }}
                    onEntered={() => {
                      dispValidateCondition(item.itemTypeId);
                    }}
                    onExited={() => {
                      dispValidateCondition(item.itemTypeId);
                    }}
                  >
                    {/* Conditions Section  */}
                    <ContentHeading
                      key={item.itemTypeId + "conditions"}
                      text="NON - Eligibilty item Conditions could be "
                    />
                    <ConditionGroup
                      key={item.itemTypeId + "other_condition"}
                      title=""
                      initialShow={true}
                      disableHide={true}
                      errorMessage={item.error}
                    >
                      {idx !== 0 &&
                      item.conditions.filter(
                        (condition) =>
                          !itemOptions.includes(condition.variableName),
                      ).length === 0 ? (
                        <ConditionGroup
                          key={item.itemTypeId + "other_condition_1"}
                          title=""
                          initialShow={true}
                          disableHide={true}
                        >
                          <VariableSelect
                            id={item.itemTypeId}
                            condition={{} as any}
                            variablesName={filteredConditions}
                            pushCurrentHash={dispPushCurrentHashForSelectedTab}
                            updateConditon={(id: any, e: any) =>
                              dispAddCondition(id, false, e)
                            }
                            selectedVariableName={""}
                            showPlaceholder={true}
                            errorMessage={item.error}
                          />
                        </ConditionGroup>
                      ) : null}

                      {item.conditions
                        .filter(
                          (condition) =>
                            !itemOptions.includes(condition.variableName),
                        )
                        .map((condition: any, idx) => {
                          let isDefault = defaultOptions.includes(
                            condition.variableName,
                          );
                          return (
                            <Condition
                              key={"other_condition" + condition.id}
                              id={item.itemTypeId}
                              condition={condition}
                              deleteCondition={(conId: string, id: string) => {
                                dispDeleteCondition(conId, id);
                              }}
                              idx={idx}
                              validateCondition={dispValidateCondition}
                              hideDelete={isDefault || idx === 0}
                            >
                              <VariableSelect
                                id={item.itemTypeId}
                                condition={condition}
                                variablesName={
                                  isDefault
                                    ? defaultOptions
                                    : filteredConditions
                                }
                                pushCurrentHash={
                                  dispPushCurrentHashForSelectedTab
                                }
                                updateConditon={dispUpdateConditionForVariables}
                                selectedVariableName={condition.variableName}
                                showPlaceholder={true}
                              />
                              {isDefault === false ? (
                                <>
                                  <OperatorSelect
                                    condition={condition}
                                    id={item.itemTypeId}
                                    pushCurrentHash={
                                      dispPushCurrentHashForSelectedTab
                                    }
                                    updateConditon={
                                      dispUpdateConditonForOperator
                                    }
                                    operatorNames={
                                      operators[condition.variableName]
                                    }
                                    selectedOperator={condition.operator}
                                  />

                                  <Values
                                    id={item.itemTypeId}
                                    condition={condition}
                                    updateCondition={
                                      dispUpdateConditionForValues
                                    }
                                    pushCurrentHash={
                                      dispPushCurrentHashForSelectedTab
                                    }
                                    useNewComponent={true}
                                  />
                                </>
                              ) : null}
                            </Condition>
                          );
                        })}

                      <AddConditionButton
                        AddCondition={dispAddCondition}
                        id={item.itemTypeId}
                        isAddBtnEnabled={isAddBtnEnabled}
                      />
                    </ConditionGroup>

                    <ConditionGroup
                      key={item.itemTypeId + "item_condition"}
                      title="Select Items"
                      errorMessage={item.itemError}
                    >
                      <div className={``}>
                        {item.conditions.filter((condition) =>
                          itemOptions.includes(condition.variableName),
                        ).length === 0 ? (
                          <ConditionGroup
                            key={item.itemTypeId + "other_condition"}
                            title=""
                            initialShow={true}
                            disableHide={true}
                          >
                            <VariableSelect
                              id={item.itemTypeId}
                              condition={{} as any}
                              variablesName={filteredItemOptions}
                              pushCurrentHash={
                                dispPushCurrentHashForSelectedTab
                              }
                              updateConditon={(id: any, e: any) =>
                                dispAddCondition(id, true, e)
                              }
                              selectedVariableName={""}
                              showPlaceholder={true}
                              errorMessage={item.itemError}
                            />
                          </ConditionGroup>
                        ) : null}
                        {item.conditions
                          .filter((condition) =>
                            itemOptions.includes(condition.variableName),
                          )
                          .map((condition: any, idx) => {
                            return (
                              <Condition
                                key={"item_condition" + condition.id}
                                id={item.itemTypeId}
                                condition={condition}
                                deleteCondition={(
                                  conId: string,
                                  id: string,
                                ) => {
                                  dispDeleteCondition(conId, id, true);
                                }}
                                idx={idx}
                                validateCondition={dispValidateCondition}
                              >
                                <VariableSelect
                                  id={item.itemTypeId}
                                  condition={condition}
                                  variablesName={filteredItemOptions}
                                  pushCurrentHash={
                                    dispPushCurrentHashForSelectedTab
                                  }
                                  updateConditon={
                                    dispUpdateConditionForVariables
                                  }
                                  selectedVariableName={condition.variableName}
                                />
                                <OperatorSelect
                                  condition={condition}
                                  id={item.itemTypeId}
                                  pushCurrentHash={
                                    dispPushCurrentHashForSelectedTab
                                  }
                                  updateConditon={dispUpdateConditonForOperator}
                                  operatorNames={
                                    operators[condition.variableName]
                                  }
                                  selectedOperator={condition.operator}
                                />

                                <Values
                                  id={item.itemTypeId}
                                  condition={condition}
                                  updateCondition={dispUpdateConditionForValues}
                                  pushCurrentHash={
                                    dispPushCurrentHashForSelectedTab
                                  }
                                  useNewComponent={true}
                                />
                              </Condition>
                            );
                          })}

                        <AddConditionButton
                          AddCondition={(id: string) =>
                            dispAddCondition(id, true)
                          }
                          id={item.itemTypeId}
                          isAddBtnEnabled={isAddItemBtnEnabled}
                        />
                      </div>
                    </ConditionGroup>

                    <ConditionGroup
                      key={item.itemTypeId + "message"}
                      title="Reason to be communicated to the customer"
                      errorMessage={item.messageForCustomer.error}
                    >
                      <MessageSection
                        updateOrder={dispUpdateItemType}
                        message={{
                          value: item.messageForCustomer?.value ?? "",
                          error: item.messageForCustomer?.error,
                        }}
                        pushCurrentHash={dispPushCurrentHashForSelectedTab}
                        validateTabType={dispValidateItemType}
                        id={item.itemTypeId}
                        hideHeader={true}
                        hideError={false}
                        insertPlaceholderRef={insertPlaceholderRef}
                      />
                      {/* Variable Section  */}
                      <Variables
                        numberOfItems={3}
                        handleAddVariableOnClick={(value) => {
                          addVariableOnClick(value, insertPlaceholderRef);
                        }}
                      />
                    </ConditionGroup>
                  </Tab.Pane>
                );
              })}
            </Tab.Content>
          </Col>
        </Row>
        <DiscardAndSave
          onSave={dispPostConfig}
          showLoading={updateAjaxStatus === "pending"}
          onDiscard={() => {
            if (!returnAutoWorkFlow.integrationId) {
              return;
            }

            dispatch(
              fetchNonEligibleItemsThunk({
                integrationId: returnAutoWorkFlow.integrationId,
                selectedReturnWindow: "",
              }),
            );
          }}
        />
      </Tab.Container>
    </div>
  );
};

const NonEligibleItems = ({
  onHide,
  onSave = () => {},
}: {
  onHide: () => void;
  onSave?: () => void;
}) => {
  const dispatch = useAppDispatch();
  const { isChanged, intialStep10Config } = useAppSelector(
    (state) => state.nonEligibleItems,
  );

  const [isValidating, setIsValidating] = useState(false);
  const [showDiscardModel, setshowDiscardModel] = useState(false);

  useEffect(() => {
    if (isValidating) {
      if (isChanged) {
        setshowDiscardModel(true);
      } else {
        onHide();
      }
      setIsValidating(false);
    }
  }, [isChanged, isValidating, onHide]);

  const closeButtonFunctionality = useCallback(() => {
    dispatch(validateChanges());
    setIsValidating(true);
  }, [dispatch]);

  return (
    <div>
      <SkeletonModalStructure
        hasTwoSection={false}
        heading="Add Items That Are NOT Eligible"
        closeFunction={closeButtonFunctionality}
      >
        <NonEligibleItemsComponent onSave={onSave} />
      </SkeletonModalStructure>
      <Modal
        className={`shadow-sm p-3 rounded`}
        show={showDiscardModel}
        onHide={() => setshowDiscardModel(false)}
        dialogClassName={`${styles.modalDialog2}`}
        contentClassName={`${styles.modalContent2}`}
        backdropClassName={`${styles.modalBackDrop}`}
        enforceFocus={false}
        keyboard={false}
      >
        <DiscardModal
          setShow={setshowDiscardModel}
          closeMainModel={onHide}
          onConfirm={() => {
            dispatch(undoConfig({ initialConfig: intialStep10Config }));
          }}
        />
      </Modal>
    </div>
  );
};

export default NonEligibleItems;
