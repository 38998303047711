import { useCallback, useMemo, useState } from "react";
import { AJAXSTATUS } from "src/globals/constants";
import { useCustomerSegments } from "src/routes/CustomerSegments/hooks/useCustomerSegments";
import { SegmentFilterOption } from "src/services/CustomerSegments/getSegmentFilters.service";
import getSegmentFiltersDDService from "src/services/CustomerSegments/getSegmentFiltersDD.service";

const FILTERDD_LIMIT = 100;

/**
 * Custom hook for fetching and managing segment filter dropdown data.
 *
 * @param props - Props for filter dropdown.
 * @param props.filterKey - The key associated with the filter.
 * @param props.options - Initial options for the filter dropdown.
 * @returns - Object containing filter dropdown data and related functions.
 */
function useFetchFiltersDD({
  filterKey,
  options,
}: {
  filterKey: string;
  options: Array<SegmentFilterOption>;
}) {
  const { activeSegmentDetails } = useCustomerSegments();

  // Memoizing segment details to prevent unnecessary re-renders
  const segmentId = useMemo(
    () => activeSegmentDetails.activeSegmentId + "",
    [activeSegmentDetails],
  );
  const segmentType = useMemo(
    () => activeSegmentDetails.activeSegmentType + "",
    [activeSegmentDetails],
  );

  // State to manage filter dropdown data, status, and pagination
  const [filterDD, setFilterDD] = useState([...options]);
  const [filterDDStatus, setFilterDDStatus] = useState<AJAXSTATUS>("fulfilled");
  const [hasMore, setHasMore] = useState(false);

  // Resetting filter dropdown and status when segment details or filter options change
  useMemo(() => {
    setFilterDD([...options]);
    setFilterDDStatus("fulfilled");
    setHasMore(false);
  }, [segmentType, filterKey, segmentId, options]);

  // Callback function to fetch more filter dropdown options
  const fetchFilterDD = useCallback(() => {
    if (filterDDStatus === "fulfilled") {
      getSegmentFiltersDDService({
        segmentType,
        filterKey,
        segmentId,
        start: filterDD.length,
        limit: FILTERDD_LIMIT,
      }).then((res) => {
        setFilterDD((prev) => {
          return [...prev, ...res];
        });
        if (res.length !== FILTERDD_LIMIT) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
      });
    }
  }, [filterDD, filterDDStatus, segmentType, filterKey, segmentId]);

  // Returning filter dropdown data and related functions
  return {
    filterDD,
    filterDDStatus,
    hasMore,
    fetchFilterDD,
  };
}

export default useFetchFiltersDD;
