import styles from "../CustomerEdit/CustomerEdit.module.scss";

function AddMore(props: {
  type: string;
  onClick: () => void;
  isVisible: boolean;
  isValid: boolean;
}) {
  if (props.isVisible) {
    return (
      <div className={`mb-3 d-inline-block `} onClick={props.onClick}>
        <span
          className={`${styles.addSpan} ${props.isValid ? "" : ""}`}
        >
          + Add More {props.type}s
        </span>
      </div>
    );
  }
  return null;
}

AddMore.defaultProps = {
  isValid: true,
};

export default AddMore;
