import { Channel } from "pusher-js";

export type ChatAssignedEventCB = (res: any) => void;

export const agentAssignedChatEvent = (
  channel: Channel,
  callback: ChatAssignedEventCB
) => {
  channel.bind("live_chat_multiple_agent_assigned", callback);
};

export const agentAssignedChatEventUnbind = (
  channel: Channel,
) => {
  channel.unbind("live_chat_multiple_agent_assigned");
};
