import { axiosJSON } from "src/globals/axiosEndPoints";

export interface IAddTemplate {
  template_category_id: number;
  template_body: string;
  templateHeading: string;
}

export interface IGetTemplateResponse {
  id: number;
  template_body: string;
  template_category_id: number;
  templateHeading: string;
  categoryName: string;
  previouslyUsedTicketId: number | string | null;
}

export async function addTemplate(apiPayload: IAddTemplate) {
  const { data } = await axiosJSON.post(
    "/api/template/createTemplate",
    apiPayload
  );

  // console.log(data);

  if (data.error === true) {
    throw new Error(data.message ?? "Some Error Occurred while fetching categories");
  }

  return data;
}
