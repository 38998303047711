import { IBotIndexingStatus } from "src/components/BotIndexingStatus/BotIndexingStatus";
import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";

export interface GetAllArticlesParams {
  botProfileId: number | string;
  start?: number;
  limit?: number;
  searchTerm?: string;
}

export interface Article {
  botTrainingStatus: IBotIndexingStatus;
  botStatus?: IBotIndexingStatus;
  syncedText?: string;
  failedText?: string;
  loadingText?: string;
  isSelected: boolean;
  articleId: number;
  articleUrl: string;
  articleTitle: string;
  articleBrand: string;
  noOfTimesAppeared: number;
  publishedOn: string;
  isUpdated: boolean;
}

export interface GetAllArticlesResponse {
  articles: Article[];
  metaData: {
    count: number;
    total: number;
  };
}

const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/botProfile/kbArticle/getAll`
  : "/api/botProfile/articles/getAll";

export async function getAllArticles(params: GetAllArticlesParams) {
  const { data: res } = await axiosJSON.post(apiEndPoint, params);
  //bot training status should also come from backend in res.data
  console.log(res.data);

  if (res.error) {
    throw new Error(res.message as string);
  }

  return res.data as GetAllArticlesResponse;
}
