import { FacebookIntegration } from "src/services/Integrations/getAllFacebookIntegrations";
import styles from "../../FbIntegration.module.scss";
import AxiosImg from "src/components/AxiosImg";
import UserAvatar from "src/components/UserAvatar";
import { Dropdown, Spinner } from "react-bootstrap";
import { FBPage } from "../../FbIntegration";
import BrandDropdown from "../BrandDropdown/BrandDropdown";
import useBrandModal from "src/routes/BotSettings/Children/BotBox/BrandModal/useBrandModal";
import { useDeleteFacebookIntegration } from "../../useFacebookIntegrations";
import { useCallback } from "react";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { integrateFacebook } from "src/services/Integrations/FacebookIntegration";

interface Props {
  integrationData?: FacebookIntegration;
  showWhat: string;
  setShowWhat: (value: string) => void;
  setCurrentPageData: (value: any) => void;
  showRemove?: boolean;
  handleRemove?: Function;
}
const FbSingleIntegration = ({
  integrationData,
  setShowWhat,
  showWhat,
  setCurrentPageData,
  handleRemove,
  showRemove,
}: Props) => {
  const { handleChange, selectedBrand, showSetup, error, setError } =
    useBrandModal();

  const deleteMutation = useDeleteFacebookIntegration();

  const handleIntegrateFacebook = useCallback((brandId: number) => {
    integrateFacebook({
      brand_id: brandId,
    })
      .then((res) => {
        window.location.replace(res);
      })
      .catch((err) => {
        pushTheToast({
          type: "danger",
          text: err.message,
          position: "top-right",
        });
      });
  }, []);

  return (
    <div className={`${styles.chooseBox} py-2 mb-3`}>
      <div className={`${styles.headerBottomBorder}`}>
        <div className="d-flex justify-content-between align-items-start">
          <div>
            <h3 className={`${styles.chooseBoxHead}`}>
              Choose a page associated to facebook account
            </h3>
            {integrationData && (
              <div
                className={`d-flex align-items-center ${styles.brandSection}`}
              >
                {integrationData?.brand && (
                  <div className="d-flex align-items-center me-3">
                    <span className={`${styles.brandText} pe-1`}>Brand:</span>
                    <div className="d-flex align-items-center">
                      {integrationData?.brand.imageUrl &&
                      integrationData?.brand.imageUrl.trim() !== "" ? (
                        <AxiosImg
                          url={integrationData?.brand.imageUrl}
                          className={`rounded-circle`}
                          style={{
                            width: "14px",
                            height: "14px",
                          }}
                        />
                      ) : (
                        <UserAvatar
                          name={integrationData?.brand.name}
                          size={14}
                        />
                      )}
                      <span className={`ps-1 ${styles.details}`}>
                        {integrationData?.brand.name}
                      </span>
                    </div>
                  </div>
                )}
                {integrationData?.facebookAccount && (
                  <div className={``}>
                    <div className="d-flex align-items-center">
                      <span className={`pe-1 ${styles.brandText}`}>
                        FB account:
                      </span>
                      <div className="d-flex align-items-center">
                        {integrationData?.facebookAccount.imageUrl &&
                        integrationData?.facebookAccount.imageUrl.trim() ? (
                          <AxiosImg
                            url={integrationData?.facebookAccount.imageUrl}
                            className={`rounded-circle`}
                            style={{
                              width: "14px",
                              height: "14px",
                            }}
                            isDirectURL={true}
                          />
                        ) : (
                          <UserAvatar
                            name={integrationData?.facebookAccount.name}
                            size={14}
                          />
                        )}

                        <span className={`ps-1 ${styles.details}`}>
                          {integrationData?.facebookAccount.name}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          <div>
            {integrationData ? (
              <div className={`d-flex justify-content-end mt-4`}>
                {/* <button>Delete Integration</button> */}
                {/* <div className={`dropdown`}> */}
                <div>
                  <Dropdown>
                    <Dropdown.Toggle
                      as="div"
                      id="dropdown-basic"
                      className={`dropdown-toggle cursor-pointer px-2 d-flex align-items-center ${
                        false ? `disabled` : ``
                      } ${styles.delBtn}`}
                    >
                      <span className={`me-1`}>
                        {deleteMutation.isLoading ? (
                          <Spinner
                            className="mx-1"
                            animation="border"
                            size="sm"
                          />
                        ) : (
                          <span className={`me-1`}>
                            {" "}
                            <i className="fa-solid fa-trash"></i>
                          </span>
                        )}
                      </span>{" "}
                      Delete Integration
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      bsPrefix={`dropdown-menu ${
                        deleteMutation.isLoading ? `disabled` : ``
                      } ${styles.addNewBox}`}
                    >
                      <li>
                        <div className={`${styles.isSure}`}>
                          <span className={`${styles.isSurePara}`}>
                            Are you sure?
                          </span>
                        </div>
                        <div className={`${styles.confirmDiv}`}>
                          <span className={`${styles.confirmSpan}`}>
                            Are you sure you want to delete this integration?
                            All associated views and rules will be disabled.
                          </span>
                          <div>
                            <button
                              className={`px-2 py-1 ${styles.confirmBtn}`}
                              onClick={() => {
                                if (!deleteMutation.isLoading) {
                                  deleteMutation.mutate(
                                    integrationData.integrationId,
                                  );
                                }
                              }}
                            >
                              Confirm
                            </button>
                          </div>
                        </div>
                      </li>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            ) : handleRemove && showRemove ? (
              <span
                className={`${styles.removeSpan} cursor-pointer`}
                onClick={() => handleRemove()}
              >
                Remove
              </span>
            ) : null}
          </div>
        </div>

        {/* ---Show this div when there is an integration--- */}

        {!integrationData && (
          <div>
            <div className={`${styles.brandSelectBox}`}>
              <p className={`mb-0 ${styles.heading}`}>Select brand:</p>
              <label
                htmlFor=""
                className={`${styles.label}`}
              >
                Select a brand to login with facebook.
              </label>
              <BrandDropdown
                selectedBrand={selectedBrand}
                setSelectedBrand={handleChange}
              />
              {selectedBrand && (
                <button
                  className={`mt-2 px-2 py-1 mb-4 ${styles.loginBtn}`}
                  onClick={() => handleIntegrateFacebook(selectedBrand.value)}
                >
                  <span>
                    <i className="fa-brands fa-facebook"></i>
                  </span>
                  <span className={`ps-1 ${styles.buttonText}`}>
                    Log in With Facebook
                  </span>
                </button>
              )}
            </div>
          </div>
        )}
      </div>

      {/**show pges here */}
      {integrationData && (
        <div className={`${styles.resTable} mt-4`}>
          {integrationData.pages.map((page) => {
            return (
              <FBPage
                key={page.id}
                setShowWhat={setShowWhat}
                setCurrentPageData={setCurrentPageData}
                category={page.category}
                name={page.name}
                id={page.id}
                imageUrl={page.imageUrl}
                integrated={page.integrated}
                integrationId={integrationData.integrationId}
              />
            );
          })}
          {/* show ui when No pages associated with this facebook
                      account found */}
          {integrationData && integrationData.pages.length == 0 && (
            <div className="my-3">
              {" "}
              <p className={`mb-0 ${styles.noData}`}>
                No pages associated with this facebook account found. You might
                need to create one with your facebook account.
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default FbSingleIntegration;
