import React, { useEffect, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import Select, {
  ControlProps,
  OptionProps,
  SingleValueProps,
  components,
} from "react-select";
import styles from "./CustomSelectDropdown.module.scss";

interface Option {
  value: string | number;
  label: string;
}

// Custom Option component for styling individual options
const CustomOption: React.FC<OptionProps<Option>> = (props) => (
  <components.Option {...props}>
    <div className={`${styles.items}`}>
      <span>{props.data?.label}</span>
    </div>
  </components.Option>
);

// Custom SingleValue component for styling the selected value
const CustomSingleValue: React.FC<SingleValueProps<Option>> = (props) => (
  <components.SingleValue {...props}>
    <div className={`${styles.items}`}>
      <span>{props.data?.label}</span>
    </div>
  </components.SingleValue>
);

// Custom Control component for styling the control container
const CustomControl: React.FC<ControlProps<Option>> = ({
  children,
  innerRef,
  innerProps,
  isFocused,
  className,
}) => {
  return (
    <div
      ref={innerRef}
      className={`custom-control-container w-100 ${
        isFocused ? styles.focusStyle : ""
      }`}
    >
      <div
        {...innerProps}
        className={className}
      >
        {children}
      </div>
    </div>
  );
};

// Custom DropdownIndicator component for styling the dropdown indicator
// const CustomDropdownIndicator = (props: any) => {
//   return (
//     <components.DropdownIndicator {...props}>
//       <i
//         className={`fa-solid ${
//           props.selectProps.menuIsOpen ? "fa-caret-up" : "fa-caret-down"
//         }`}
//       ></i>
//     </components.DropdownIndicator>
//   );
// };

// Custom styles for React Select components
const customStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#f5f5f5" : "#fff",
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
    padding: "10px",
  }),
  menuList: (provided: any) => ({
    ...provided,
    margin: "0 5px",
  }),
};

// Define prop types for the CustomSelectDropdown component
interface Props {
  options: Option[];
  selectedOption: Option[] | Option | undefined;
  setSelectedOption: (value: Option[] | Option) => void;
  menuClassName?: string;
  hasMore?: boolean;
  loadMore?: () => void;
  isLoading?: boolean;
  placeholder?: string;
  isSearchable?: boolean;
  isMultiSelect?: boolean;
  error?: string | null;
  handleSearch?: (value: string) => void;
}

// CustomSelectDropdown component
const CustomSelectDropdown = ({
  options,
  selectedOption,
  setSelectedOption,
  menuClassName = "",
  hasMore = false,
  loadMore = () => {},
  isLoading = false,
  placeholder = "Select a category",
  isSearchable = false,
  isMultiSelect = false,
  error,
  handleSearch = () => {},
}: Props) => {
  // Handler function for option selection
  const handleChange = (selectedOption: any) => {
    setSelectedOption(selectedOption);
  };

  // State to manage popover visibility
  const [showPopover, setShowPopover] = useState(true);

  // Effect to show/hide popover based on error
  useEffect(() => {
    if (error) {
      setShowPopover(true);
    } else {
      setShowPopover(false);
    }
  }, [error]);

  // Popover component for displaying error message
  const popover =
    error && error.length > 0 ? (
      <Popover
        id="popover-basic"
        className="rounded-3"
      >
        <Popover.Body className="d-flex flex-row">
          <div className="border px-2 bg-warning text-white me-2 rounded-3 m-auto mt-1">
            !
          </div>
          <div className="mt-1">{error}</div>
        </Popover.Body>
      </Popover>
    ) : (
      <></>
    );

  return (
    <OverlayTrigger
      show={showPopover}
      placement="bottom"
      overlay={popover}
      rootClose={true}
      rootCloseEvent="mousedown"
      onToggle={(isShown) => {
        // Set the state variable based on the popover visibility
        setShowPopover(isShown);
      }}
    >
      <div>
        <Select
          value={selectedOption}
          onChange={handleChange}
          options={options}
          className={`${styles.text}`}
          placeholder={placeholder}
          components={{
            Option: CustomOption,
            SingleValue: CustomSingleValue,
            Control: (props) => (
              <CustomControl
                {...props}
                className={`custom-control ${menuClassName} ${
                  error ? styles.borderError : ""
                }`}
              />
            ),
            DropdownIndicator: null,
            IndicatorSeparator: null,
          }}
          styles={customStyles}
          isSearchable={isSearchable}
          isMulti={isMultiSelect}
          isLoading={isLoading}
          onMenuScrollToBottom={loadMore}
          onInputChange={(inputValue) => handleSearch(inputValue)}
        />
        <span className={styles.errorText}>
          {error && error.length > 0 && error}
        </span>
      </div>
    </OverlayTrigger>
  );
};

export default CustomSelectDropdown;
