import styles from "./TagItemDisplay.module.scss";

const TagItemDisplay = ({ tag, onClickRemove }: any) => {
  const handleOnClick = (e: any) => {
    e.preventDefault();
    onClickRemove(tag);
  };
  return (
    <>
      <span className={`text-truncate ${styles.addTagEmail}`}>
        {tag.tagName !== undefined ? tag.tagName : "emptyTag"}
        {/* <span className={`${styles.addTagMark} mx-1`}>
          <i className="fas solid fa-xmark" onClick={handleOnClick} />
        </span> */}
      </span>
      <span className={`${styles.addTagMark} mx-1`}>
        <i className="fas solid fa-xmark" onClick={handleOnClick} />
      </span>
    </>
  );
};

export default TagItemDisplay;
