import objectHash from "object-hash";
import { ReturnReason } from "src/services/UiAutomation/ReturnExchange/Configuration/Step14/getAllReturnReasonList";
import {
  ConditionTypes,
  createNewObjectHash,
  getConditionValueOrDefault,
} from "src/store/slices/initiateReturn/Helpers/returnExchange.helpers";
import { v4 as uuid } from "uuid";
import { RefundReason, RefundScenario } from "./antiRefundConfig.slice";
import { IAntiRefundOrderTypesConfig } from "./antiRefundOrderTypesConfig.slice";

export const fetchStep13GetConfigThunkFulfilled = (
  state: IAntiRefundOrderTypesConfig,
  { payload }: any,
) => {
  const {
    conditionResponse,
    configRes,
    getRefundRequestList,
    allItems,
    allTags,
  } = payload;
  const allProductItems = allItems?.map((item: any) => {
    return {
      id: item.productId,
      label: item.productName,
      type: "item",
      value: item.productName,
      imgSrc: item.imageUrl,
      price: item.productSku,
    };
  });

  // console.log(configRes);
  if (getRefundRequestList) {
    if (getRefundRequestList?.reasons) {
      const reasons: { [key: string]: ReturnReason } =
        getRefundRequestList.reasons ?? [];
      const returnReasons = Object.values(reasons).map((obj) => {
        if (obj) {
          return {
            id: obj.id,
            reasonName: obj.reasonName,
            scenarios: [
              {
                id: uuid(),
                items: [],
                tags: [],
                enabled: false,
                itemsAndTagAllSelected: false,
                followUpQuestions: [],
                isNewScenario: true,
              },
            ],
          };
        }
      });
      // const followUpQuestion =
      // Object.values(reasons).map((obj) => {
      //   if (obj) {
      //     return {
      //       followupQuestions:
      //          obj.moreQuestions.map((element) => {
      //           return {
      //             elementId: element.elementId,
      //             elementTypeId: element.elementTypeId,
      //             elementValue: element.elementValue,
      //             additionalOptions: element.additionalOptions,
      //             selectedValue: "",
      //             selectedId: "",
      //           };
      //         }),
      //       }
      //     }
      // });
      if (returnReasons) {
        // state.antiRefundOrderTypesData.refundRequestFollowUpQuestions = followUpQuestion as unknown as FollowUpQuestions[];
        state.antiRefundOrderTypesData.refundRequestReasons =
          returnReasons as unknown as RefundReason[];
      }
    }
  }
  if (conditionResponse) {
    state.antiRefundOrderTypesData.conditionOptions =
      conditionResponse.conditions;
    // state.antiRefundOrderTypesData.itemOptions =
    //   conditionResponse.itemConditions;
    state.antiRefundOrderTypesData.defaultOptions =
      conditionResponse.defaultConditions;
    [
      ...state.antiRefundOrderTypesData.conditionOptions,
      // ...state.antiRefundOrderTypesData.itemOptions,
      ...state.antiRefundOrderTypesData.defaultOptions,
    ].forEach((option) => {
      if (
        !state.antiRefundOrderTypesData.variableNames.some(
          (item: any) => item.id === option.id,
        )
      ) {
        state.antiRefundOrderTypesData.variableNames.push({
          name: option.name,
          id: option.id,
        });
      }

      state.antiRefundOrderTypesData.operatorsNames[option.name] =
        option.operators.map((operator) => {
          state.antiRefundOrderTypesData.operatorSymbolMap[operator.value] =
            operator.name;
          return operator.name;
        });
      state.antiRefundOrderTypesData.valueTypes[option.valueType] = null;
      state.antiRefundOrderTypesData.variableNameToValueType[option.name] =
        option.valueType;
    });
  }

  if (configRes) {
    if (configRes.length > 0) {
      state.antiRefundOrderTypesData.selectedOrderTypeId =
        configRes[0].configTabId.toString();
      state.antiRefundOrderTypesData.antiRefundOrderTypes = configRes.map(
        (tab: any, idx: any) => {
          let toggleAllOrder = true;
          // When no conditions from backend then enable all orders
          if (tab?.conditions?.length == 0) {
            toggleAllOrder = true;
          } else if (tab?.conditions?.length == 1) {
            // If there is only one condition , check if it is default condition , if yes , enable apply to all orders
            if (
              tab.conditions[0].conditionTypeId ==
              state.antiRefundOrderTypesData.defaultOptions[0].id
            ) {
              toggleAllOrder = true;
            } else {
              toggleAllOrder = false;
            }
          } else {
            toggleAllOrder = false;
          }
          let condition: any = state.antiRefundOrderTypesData.defaultOptions[0];
          //Prepare a new deault condition
          const newConditions = [
            {
              id: uuid(),
              operator:
                state.antiRefundOrderTypesData.operatorsNames[
                  condition.name
                ][0],
              ruleType: "AND",
              values: getConditionValueOrDefault(
                state.antiRefundOrderTypesData.variableNameToValueType[
                  condition.name
                ],
              ),
              variableName: condition.name,
            },
          ];
          return {
            orderTypeName: tab.configTabName,
            orderTypeId: tab.configTabId.toString(),
            error: null,
            isAddConditionEnabled: tab.toggleAllOrder ? false : true,
            applyToAllOrders: toggleAllOrder,
            errorCount: 0,
            isValid: false,
            // If conditions are empty , add a default condition
            conditions:
              tab.conditions.length == 0
                ? newConditions
                : tab.conditions.map((condition: any) => {
                    const selectedVariable: any =
                      state.antiRefundOrderTypesData.variableNames.find(
                        (variable: any) =>
                          variable.id == condition.conditionTypeId,
                      );

                    if (selectedVariable) {
                      const variableName = selectedVariable.name;
                      const operatorSymbol = condition.operator;
                      const operator =
                        state.antiRefundOrderTypesData.operatorSymbolMap[
                          operatorSymbol
                        ];
                      const valueType =
                        state.antiRefundOrderTypesData.variableNameToValueType[
                          variableName
                        ];
                      const values = getConditionValueOrDefault(
                        valueType,
                        condition.conditionValue,
                      );

                      // const values = productIdToDetails(Conditionvalues);

                      return {
                        id: condition.id.toString(),
                        variableName,
                        operator,
                        values,
                      } as ConditionTypes;
                    }
                    return {} as ConditionTypes;
                  }),
            refundRequests: {
              reasons: state.antiRefundOrderTypesData.refundRequestReasons.map(
                (reason) => {
                  // For each 'reason' in the 'refundRequestReasons' array, check if it exists in the config from backend
                  const matchingApiResponseReason = tab.refundRequests.find(
                    (apiReason: any) => apiReason.id.toString() === reason.id,
                  );

                  // If a matching reason is found, return it as a 'RefundReason'.
                  if (matchingApiResponseReason) {
                    const newScenarios =
                      matchingApiResponseReason.scenarios.map(
                        (scenario: any) => {
                          if (
                            (scenario.items as Array<any>).includes("*") &&
                            scenario.tags.length == 1 &&
                            scenario.tags.includes("*")
                          ) {
                            // Convert response items into items array used for anti-refund
                            scenario.items = allProductItems;
                            scenario.tags = allTags?.map((tag: any) => tag);

                            scenario.itemsAndTagAllSelected = true;
                          } else {
                            // If scenario items are available
                            if (scenario.items.length) {
                              // Convert response items into items array used for anti-refund
                              scenario.items = scenario.items.map(
                                (item: any) => {
                                  return {
                                    id: item.productId,
                                    label: item.productName,
                                    type: "item",
                                    value: item.productName,
                                    imgSrc: item.imageUrl,
                                    price: item.productSku,
                                  };
                                },
                              );
                            } else {
                              scenario.items = [];
                            }
                            // If scenario tags are available
                            if (scenario.tags.length) {
                              // Convert response tags into tags array used for anti-refund
                              scenario.tags = scenario.tags.map(
                                (tag: any) => tag,
                              );
                            } else {
                              scenario.tags = [];
                            }
                          }

                          if (
                            scenario.items.length === 0 &&
                            scenario.tags.length === 0 &&
                            (scenario.followUpQuestions.length === 0 ||
                              (
                                scenario.followUpQuestions as Array<any>
                              ).includes("*"))
                          ) {
                            scenario.itemsAndTagAllSelected = true;
                            scenario.items = [];
                            scenario.tags = [];
                          }
                          if (
                            scenario.followUpQuestions.length === 1 &&
                            scenario.followUpQuestions[0] === ("*" as any)
                          ) {
                            scenario.followUpQuestions =
                              state.antiRefundOrderTypesData.refundRequestFollowUpQuestions.map(
                                (element) => {
                                  return {
                                    elementId: element.elementId,
                                    elementTypeId: element.elementTypeId,
                                    elementValue: element.elementValue,
                                    additionalOptions:
                                      element.additionalOptions,
                                    selectedValue: "",
                                    // selectedId: "",
                                  };
                                },
                              );
                          }
                          scenario.followUpQuestions.forEach(
                            (followUpQuestion: any) => {
                              if (
                                followUpQuestion.selectedValue.length === 1 &&
                                followUpQuestion.selectedValue[0] === "*"
                              ) {
                                followUpQuestion.selectedValue =
                                  followUpQuestion.additionalOptions.map(
                                    (option: any) => option.id,
                                  );
                              }
                            },
                          );
                          return {
                            id: scenario.id,
                            enabled: scenario.enabled,
                            items:
                              scenario.items && scenario.items.length //Changing items format to use in anti refund
                                ? scenario.items
                                : [],
                            tags: scenario.tags ?? [],
                            itemsAndTagAllSelected:
                              scenario.itemsAndTagAllSelected ?? false,
                            followUpQuestions: scenario.followUpQuestions ?? [],
                            additionalInformation: {
                              enabled:
                                scenario.additionalInformation?.enabled ??
                                false,
                              message:
                                scenario.additionalInformation?.message ?? "",
                              extendReturnPeriod: {
                                enabled:
                                  scenario.additionalInformation
                                    ?.extendReturnPeriod?.enabled ?? false,
                                days:
                                  scenario.additionalInformation
                                    ?.extendReturnPeriod?.days ?? 0,
                              },
                            },
                            giveOffer: {
                              enabled: scenario.giveOffer?.enabled ?? false,
                              message: scenario.giveOffer?.message ?? "",
                              offerSelectedItems:
                                (scenario.giveOffer
                                  ?.offerSelectedItems as number[]) ?? [],
                              offerSelectedTags: scenario.giveOffer
                                ?.offerSelectedTags as string[],
                              offerType:
                                (scenario.giveOffer?.offerType as string) ??
                                "other",
                              offerValue:
                                (scenario.giveOffer?.offerValue as number) ?? 0,
                              offerUnit:
                                scenario.giveOffer?.offerUnit ?? "None",
                              name: scenario.giveOffer?.name ?? "",
                            },
                            offerExchange: {
                              enabled: scenario.offerExchange?.enabled ?? false,
                              message: scenario.offerExchange?.message ?? "",
                              exchangeWithOtherItems:
                                scenario.offerExchange
                                  ?.exchangeWithOtherItems ?? false,
                              exchangeWithSameItems:
                                scenario.offerExchange?.exchangeWithSameItems ??
                                false,
                            },
                          } as RefundScenario;
                        },
                      );

                    return {
                      id: matchingApiResponseReason.id,
                      reasonName: matchingApiResponseReason.reasonName,
                      scenarios: newScenarios,
                    } as RefundReason;
                  }

                  // If no matching reason is found, create a new 'RefundReason' object using 'reason' properties.
                  return {
                    id: reason.id,
                    reasonName: reason.reasonName,
                    scenarios: reason.scenarios,
                  } as RefundReason;
                },
              ),
            },
          };
        },
      );
    } else {
      if (conditionResponse.defaultConditions) {
        let conditions = conditionResponse.defaultConditions.map(
          (condition: any) => {
            const selectedVariable: any =
              state.antiRefundOrderTypesData.variableNames.find(
                (variable: any) => variable.id == condition.id,
              );
            if (selectedVariable) {
              const variableName = selectedVariable.name;
              const operatorSymbol = condition.operators[0].value;
              const operator =
                state.antiRefundOrderTypesData.operatorSymbolMap[
                  operatorSymbol
                ];
              const valueType =
                state.antiRefundOrderTypesData.variableNameToValueType[
                  variableName
                ];
              const values = getConditionValueOrDefault(valueType, null);

              // const values = productIdToDetails(Conditionvalues);

              return {
                id: uuid(),
                variableName,
                operator,
                values,
              } as ConditionTypes;
            }
          },
        );

        state.antiRefundOrderTypesData.antiRefundOrderTypes[0].conditions =
          conditions;
        state.antiRefundOrderTypesData.antiRefundOrderTypes[0].orderTypeName =
          "All orders and items";
        state.antiRefundOrderTypesData.antiRefundOrderTypes[0].refundRequests.reasons =
          state.antiRefundOrderTypesData.refundRequestReasons.map((reason) => {
            return {
              id: reason.id,
              reasonName: reason.reasonName,
              scenarios: reason.scenarios,
            } as RefundReason;
          });
      }
    }
  }
  const hash = createNewObjectHash({
    excludeKeys: state.excludeKeysForHash,
    hashObject: state.antiRefundOrderTypesData.antiRefundOrderTypes,
  });

  if (hash) {
    state.currentHash = hash;
    state.initialHash = hash;
  }
  // console.log("fetchStep13GetConfigThunkFulfilled -- fulfilled");
  state.fetchAjaxStatus = "fulfilled";
};

export default {
  fetchStep13GetConfigThunkFulfilled,
};
