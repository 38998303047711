import { GetConfigModalParams } from "src/features/ReturnAutoWorkFlow/children/ConfigModals/oldReturnModals.types";
import { axiosJSON } from "src/globals/axiosEndPoints";

export interface GetProductsPayload extends GetConfigModalParams {
  searchTerm?: string;
  limit: number;
  start: number;
}

interface Variants {
  variantId: number;
  variantName: string;
  variantPrice: number;
  variantStock: number;
  variantSku: string;
  imageUrl: URL;
}

export interface ReturnProduct {
  productId: number;
  productName: string;
  productPrice: {
    amount: string;
    currencyCode: string;
  };
  productStock: number;
  productSku: string;
  imageUrl: string;
  variants: Variants[];
}

export interface AllProducts {
  data: ReturnProduct[];
  metaData: {
    total: number;
    count: number;
    hasMore: boolean;
  };
}

async function getProductsService(payload: GetProductsPayload) {
  const { data: res } = await axiosJSON.post(
    "/api/setting/automation/refund/product/getAllProducts",
    payload,
  );

  if (res.err || res.error) {
    throw new Error(res.msg ?? res.message ?? "Some Error Occurred.");
  }

  const ret: AllProducts = {
    data: res.data as ReturnProduct[],
    metaData: {
      total: res.total ?? 0,
      count: res.count ?? 0,
      hasMore: true,
    },
  };

  if (ret.data.length < payload.limit) {
    ret.metaData.hasMore = false;
  }

  return ret;
}

export default getProductsService;
