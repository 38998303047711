import React from "react";
import styles from "./ResetModal.module.scss";
import useResetModal from "./useResetModal";
interface Props {
  hideResetModal: (e: any) => void;
}

const ResetModal = ({ hideResetModal }: Props) => {
  const { resetToDefault } = useResetModal({ hideResetModal });

  return (
    <div>
      <h3 className={`pb-2 ${styles.resetHead} saufter_h3`}>Reset Theme</h3>
      <p className={`${styles.resetPara}`}>
        Are you sure you want to reset to the theme defaults? You will lose all
        customization you have done to this theme.
      </p>
      <div className={`pt-4 flex-wrap flex-md-row ${styles.actionBox}`}>
        <button
          className={`d-flex justify-content-center align-items-center me-3 mb-2 mb-md-0 ${styles.resetBtn}`}
          onClick={resetToDefault}
        >
          Yes, reset to default
        </button>

        <button
          className={`d-flex justify-content-center align-items-center ${styles.cancelBtn}`}
          onClick={hideResetModal}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default ResetModal;
