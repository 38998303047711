import { useInfiniteQuery } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { EAutomationType } from "src/routes/BotSettings/Children/AutomationList/AutomationList";
import {
  DispatchRuleCategory,
  GetAllIssueCategoryParam,
  getAllIssueCategoryService,
} from "src/services/Automation/DispatchRulesConfigModal/getAllCategory.service";

const INITIAL_PAYLOAD = {
  start: 0,
  limit: 100,
};

const useIssueDispatchCategories = (type: EAutomationType) => {
  // Infinite query hook to fetch pages
  const { data, isLoading, fetchNextPage, hasNextPage, isFetching, refetch } =
    useInfiniteQuery({
      queryKey: ["getAllIssueCategoryService", type, INITIAL_PAYLOAD],
      queryFn: ({ pageParam = { type, payload: INITIAL_PAYLOAD } }) =>
        getAllIssueCategoryService(pageParam),
      getNextPageParam: (lastPage, allPages) => {
        if (!lastPage.metaData.hasMore) {
          return null;
        }

        const data = allPages.flatMap((page) => page);
        const nextPageParam: GetAllIssueCategoryParam = {
          limit: INITIAL_PAYLOAD.limit,
          start: data.length,
        };

        return { type, payload: nextPageParam };
      },
    });

  /**
   * Fetch next page handler
   */
  const fetchNextPageHandler = useCallback(() => {
    // If we have next page and currently not fetching fetch the next page
    if (hasNextPage && !isFetching) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isFetching]);

  const showLoader = useMemo(() => {
    // Show loader if loading or fetching data
    return isLoading || isFetching;
  }, [isFetching, isLoading]);

  const optionsMap = useMemo(() => {
    let optionsMap: Record<string, DispatchRuleCategory> = {};
    data?.pages.forEach((page) => {
      optionsMap = { ...optionsMap, ...page.categories };
    });
    return optionsMap;
  }, [data]);

  const optionsIds = useMemo(() => {
    const ids = data?.pages.flatMap((page) => page.categoryIds) ?? [];
    return Array.from(new Set([...ids]));
  }, [data]);

  const onAddCategory = useCallback(() => {
    if (data) {
      refetch();
    }
  }, [data, refetch]);

  return {
    optionsMap,
    optionsIds,
    showLoader,
    fetchNextPageHandler,
    onAddCategory,
  };
};

export default useIssueDispatchCategories;
