import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import {
  ConditionTypes,
  createNewObjectHash,
  getConditionValueOrDefault,
} from "src/features/ReturnAutoWorkFlow/children/ConfigModals/store/returnModalsSliceHelpers";
import { GetAllFollowUpReturnReason } from "src/services/ReturnAutoWorkFlow/OldModals/General/getAllReturnReasonList.service";
import { ReturnMethodTab } from "src/services/ReturnAutoWorkFlow/OldModals/returnMethod/getReturnMethod.service";
import { ReturnMethodConditions } from "src/services/ReturnAutoWorkFlow/OldModals/returnMethod/getReturnMethodConditions.service";
import { v4 as uuid } from "uuid";
import { ReturnMethodState, ReturnReason } from "./returnMethod.slice";
import {
  fetchReturnMethodsThunk,
  updateReturnMethodThunk,
} from "./returnMethod.thunk";

const updateConfig = (
  state: ReturnMethodState,
  payload: {
    conditionsRes?: ReturnMethodConditions;
    configRes: ReturnMethodTab[];
    returnReasons: boolean;
    returnReasonList?: GetAllFollowUpReturnReason["reasons"];
  },
) => {
  const { conditionsRes, configRes, returnReasons, returnReasonList } = payload;

  if (conditionsRes) {
    state.conditionOptions = conditionsRes.conditions;
    state.itemOptions = conditionsRes.itemConditions;
    state.defaultOptions = conditionsRes.defaultConditions;
    // Adding default return reasons for a state variable to check for validations based on moreQuestions
    if (returnReasonList) {
      state.returnReasonList = Object.values(returnReasonList).map((val) => {
        return {
          id: val.id,
          value: val.reasonName,
          moreQuestions: val.moreQuestions.map((ques) => {
            return {
              ...ques,
              additionalOptions: ques.additionalOptions.map((op) => {
                return {
                  id: op.id ?? "",
                  value: op.value,
                };
              }),
            };
          }),
        };
      });
    }
    state.selectReasonOptions = conditionsRes.returnReasonConditions;

    [
      ...state.conditionOptions,
      ...state.itemOptions,
      ...state.defaultOptions,
      ...state.selectReasonOptions,
    ].forEach((option) => {
      if (!state.variableNames.some((item: any) => item.id === option.id)) {
        state.variableNames.push({ name: option.name, id: option.id });
      }

      state.operatorsNames[option.name] = option.operators.map((operator) => {
        state.operatorSymbolMap[operator.value] = operator.name;
        return operator.name;
      });
      state.valueTypes[option.valueType] = null;
      state.variableNameToValueType[option.name] = option.valueType;
    });
  }

  if (configRes) {
    const step14Config = configRes;

    if (step14Config.length > 0) {
      state.selectedReturnMethodId = step14Config[0].configTabId.toString();
      state.returnMethods = step14Config.map((tab) => {
        return {
          returnMethodName: tab.configTabName,
          returnMethodId: tab.configTabId.toString(),
          returnMethodType: {
            value: tab?.returnMethod?.returnType ?? "notRequired",
            message: tab?.returnMethod?.displayMessage ?? "",
            error: null,
          },
          isAddOrderConditionEnabled: false,
          itemConditionError: null,
          returnMethodSequence: step14Config.length,
          selectReturnReasonError: null,
          isAddSelectReasonConditionEnabled: returnReasons,
          returnReasons: returnReasons,
          returnReasonList: returnReasonList
            ? Object.values(returnReasonList)
            : [], //Assigning api response to redux state
          error: null,
          isAddConditionEnabled: true,
          errorCount: 0,
          isValid: false,
          shipWithAnyCarrier: {
            currentState: true,
            instructions: "",
            showInstruction: false,
          },
          shipWithPrepaidLabels: {
            currentState: true,
            instructions: "",
            showInstruction: false,
          },
          // itemSequence: tab.itemSequence,
          conditions: tab.conditions.map((condition: any) => {
            const selectedVariable: any = state.variableNames.find(
              (variable: any) => variable.id == condition.conditionTypeId,
            );

            if (selectedVariable) {
              const variableName = selectedVariable.name;
              const operatorSymbol = condition.operator;
              const operator = state.operatorSymbolMap[operatorSymbol];
              const valueType = state.variableNameToValueType[variableName];
              const values = getConditionValueOrDefault(
                valueType,
                condition.conditionValue,
              );

              return {
                id: condition.id.toString(),
                variableName,
                operator,
                values,
              } as ConditionTypes;
            }
            return {} as ConditionTypes;
          }),
          selectReturnReason: tab.selectReturnReasons.map((condition: any) => {
            const selectedVariable: any = state.variableNames.find(
              (variable: any) => variable.id == condition.conditionTypeId,
            );

            if (selectedVariable) {
              const variableName = selectedVariable.name;
              const operatorSymbol = condition.operator;
              const operator = state.operatorSymbolMap[operatorSymbol];
              const values = getConditionValueOrDefault(
                "return_reason",
                condition.conditionValue,
              );

              return {
                id: condition.id.toString(),
                variableName,
                operator,
                values,
              } as ReturnReason;
            }
            return {} as ReturnReason;
          }),
        };
      });
    } else {
      if (conditionsRes?.defaultConditions) {
        const conditions = conditionsRes.defaultConditions
          .map((condition) => {
            const selectedVariable: any = state.variableNames.find(
              (variable: any) => variable.id == condition.id,
            );
            if (selectedVariable) {
              const variableName = selectedVariable.name;
              const operatorSymbol = condition.operators[0].value;
              const operator = state.operatorSymbolMap[operatorSymbol];
              const valueType = state.variableNameToValueType[variableName];
              const values = getConditionValueOrDefault(valueType, null);

              return {
                id: uuid(),
                variableName,
                operator,
                values,
              } as ConditionTypes;
            }

            return null;
          })
          .filter(Boolean) as ConditionTypes[];

        state.returnMethods[0].conditions = conditions;
        state.returnMethods[0].returnMethodName = "All orders and items";
      }
    }
  }

  const hash = createNewObjectHash({
    excludeKeys: state.excludeKeysForHash,
    hashObject: state.returnMethods,
  });

  if (hash) {
    state.initialHash = hash;
    state.currentHash = hash;
  }
};

const returnMethodBuilders = (
  builder: ActionReducerMapBuilder<ReturnMethodState>,
) => {
  builder.addCase(
    updateReturnMethodThunk.pending,
    (state: ReturnMethodState) => {
      state.updateAjaxStatus = "pending";
    },
  );
  builder.addCase(
    updateReturnMethodThunk.fulfilled,
    (state: ReturnMethodState, action) => {
      state.updateAjaxStatus = "fulfilled";
      updateConfig(state, action.payload);
    },
  );
  builder.addCase(
    updateReturnMethodThunk.rejected,
    (state: ReturnMethodState) => {
      state.updateAjaxStatus = "rejected";
      pushTheToast({
        type: "danger",
        text: "failed to update!",
        position: "top-right",
      });
    },
  );
  builder.addCase(
    fetchReturnMethodsThunk.pending,
    (state: ReturnMethodState, { payload }) => {
      state.fetchAjaxStatus = "pending";
    },
  );
  builder.addCase(
    fetchReturnMethodsThunk.rejected,
    (state: ReturnMethodState, { payload }) => {
      state.fetchAjaxStatus = "rejected";
      pushTheToast({
        type: "danger",
        text: "failed to fetch configuration!",
        position: "top-right",
      });
    },
  );
  builder.addCase(
    fetchReturnMethodsThunk.fulfilled,
    (state: ReturnMethodState, action) => {
      state.fetchAjaxStatus = "fulfilled";

      updateConfig(state, action.payload);
    },
  );
};

export default returnMethodBuilders;
