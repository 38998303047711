import { useEffect, useState } from "react";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { ScheduleData } from "src/services/LiveChat/ScheduleFollowups/getAllSchedules";
import { UpdateSchedulePayload } from "src/services/LiveChat/ScheduleFollowups/updateSchedule";
import { useAppSelector } from "src/store/store";
import {
  convertUserTimeZoneToAnother,
  formatUTCDate,
  generate24TimeOnly,
} from "src/utils/dateLibrary";
import { formatDate } from "src/utils/utils";
interface Props {
  onHide: () => void;
  schedule: ScheduleData;
  updateScheduleData: (payload: UpdateSchedulePayload) => Promise<void>;
  allSchedules: { [key: string | number]: ScheduleData };
}
function useScheduleFollowUp({
  onHide,
  schedule,
  updateScheduleData,
  allSchedules,
}: Props) {
  const userTimezone = useAppSelector(
    (state) => state.globals.currentUserData?.userTimezone
  );
  const [show, setShow] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());
  const [selectedCondition, setSelectedCondition] = useState(
    schedule.condition
  );
  const [updateLoader, setUpdateLoader] = useState(false);
  const [error, setError] = useState("");

  function handleCancel() {
    // Function to handle cancellation
    onHide();
  }

  function handleUpdate() {
    // Function to handle schedule updates

    // Creating payload with schedule ID
    let payload: UpdateSchedulePayload = {
      id: schedule.id,
    };

    // Extracting scheduled datetime from the schedule and converting to Date object
    let scheduledDatetime = new Date(schedule.scheduledToUTC + "Z");

    // Checking if the selected date or time is different from the existing schedule
    if (scheduledDatetime !== startDate || scheduledDatetime !== startTime) {
      // Formatting the selected date and time
      const formattedDate = formatDate(startDate);
      const formattedTime = generate24TimeOnly(startTime);

      // Converting the selected time to UTC
      const selectedTimeInUTC = convertUserTimeZoneToAnother(
        `${formattedDate} ${formattedTime}`,
        userTimezone,
        "UTC"
      );

      // Checking if the selected time is in the future
      if (new Date(selectedTimeInUTC).getTime() > new Date().getTime()) {
        // Getting template messages related to the schedule
        const templateMessages = Object.values(allSchedules)
          .filter((message) => message.templateId === schedule.templateId)
          .sort((a, b) => a.id - b.id);

        // Finding the index of the current message in the sorted array
        const currentMessageIndex = templateMessages.findIndex(
          (message) => message.id === schedule.id
        );

        // Uncomment the following block if you need to check against the previous message
        /*
        if (currentMessageIndex) {
          const previousMessage = templateMessages[currentMessageIndex - 1];
  
          if (
            new Date(previousMessage.scheduledToUTC + "Z").getTime() <
            new Date(selectedTimeInUTC).getTime()
          ) {
            setError("*Date/time cannot be before the next message in sequence");
          }
        }
        */
      } else {
        setError("*Date/time cannot be in the past");
        return;
      }

      // Formatting the selected time in UTC for payload
      const datetime = formatUTCDate(new Date(selectedTimeInUTC));
      payload["rescheduleTo"] = datetime;
    }

    // Checking if the selected condition is different from the existing schedule
    if (selectedCondition !== schedule.condition) {
      payload["condition"] = selectedCondition;
    }

    // Checking if there are any changes in the payload
    if (Object.keys(payload).length >= 1) {
      // Initiating update process and handling success or failure
      setUpdateLoader(true);
      updateScheduleData(payload)
        .then(() => onHide())
        .catch(() => {
          setUpdateLoader(false);
        });
    } else {
      // If no changes, simply hide the modal
      onHide();
    }
  }

  // Effect to update the local state with the schedule's date and time in the user's timezone
  useEffect(() => {
    const dateTime = new Date(schedule.scheduledToUTC + "Z");
    const dateInUserTimezone = new Date(
      dateTime.toLocaleString("en-US", { timeZone: userTimezone })
    );
    setStartDate(dateInUserTimezone);
    setStartTime(dateInUserTimezone);
  }, [userTimezone, schedule]);

  return {
    show,
    setShow,
    startDate,
    setStartDate,
    handleCancel,
    handleUpdate,
    startTime,
    setStartTime,
    selectedCondition,
    setSelectedCondition,
    updateLoader,
    error,
  };
}

export default useScheduleFollowUp;
