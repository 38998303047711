import { useCallback, useState } from "react";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { resolveChat } from "src/services/LiveChat/createTicket/resolveChat.service";
import { useAppSelector } from "src/store/store";

interface ResolveHeadProps {
  chatRequestId: string | number;
  setIsResolved: React.Dispatch<React.SetStateAction<boolean>>;
  chatStatus?: string;
}
const useResolveHead = ({
  chatRequestId,
  setIsResolved,
  chatStatus,
}: ResolveHeadProps) => {
  const [isResolving, setIsResolving] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showTicketSuccess, setShowTicketSuccess] = useState(false);
  const handleResolveChat = useCallback(
    (e: any) => {
      setIsResolving(true);
      resolveChat({ chatRequestId })
        .then((res) => {
          setIsResolved(true);
          pushTheToast({
            text: `Chat is resolved${
              chatStatus !== "archived" ? " and moved to archive" : ""
            }`,
            position: "top-right",
            type: "success",
          });
        })
        .catch((err) => {
          pushTheToast({
            text: "Failed to resolve chat",
            position: "top-right",
            type: "danger",
          });
        })
        .finally(() => {
          setIsResolving(false);
        });
    },
    [chatRequestId, setIsResolved, setIsResolving, chatStatus]
  );

  const onShow = useCallback(() => {
    setShowModal(true);
  }, [setShowModal]);
  const onHide = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  return {
    isResolving,
    handleResolveChat,
    showModal,
    onShow,
    onHide,
    showTicketSuccess,
    setShowTicketSuccess,
  };
};

export default useResolveHead;
