import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import {
  ConditionTypes,
  createNewObjectHash,
  getConditionValueOrDefault,
} from "src/features/ReturnAutoWorkFlow/children/ConfigModals/store/returnModalsSliceHelpers";
import { ReturnTypeBasedOnOrderOrItemsTab } from "src/services/ReturnAutoWorkFlow/OldModals/returnTypeBasedOnOrderOrItems/getReturnTypeBasedOnOrderOrItems.service";
import { ReturnTypeBasedOnOrderOrItemsConditions } from "src/services/ReturnAutoWorkFlow/OldModals/returnTypeBasedOnOrderOrItems/getReturnTypeBasedOnOrderOrItemsConditions.service";
import { IStepQuestion } from "src/store/slices/initiateReturn/retrunExchange.slice";
import { v4 as uuid } from "uuid";
import { ReturnTypeBasedOnOrderOrItemsState } from "./returnTypeBasedOnOrderOrItems.slice";
import {
  fetchReturnTypeBasedOnOrderOrItemsThunk,
  updateReturnTypeBasedOnOrderOrItemsThunk,
} from "./returnTypeBasedOnOrderOrItems.thunk";

const updateConfig = (
  state: ReturnTypeBasedOnOrderOrItemsState,
  payload: {
    conditionsRes?: ReturnTypeBasedOnOrderOrItemsConditions;
    configRes: ReturnTypeBasedOnOrderOrItemsTab[];
    defaultResolutionOfferValue: IStepQuestion["value"];
  },
) => {
  const { conditionsRes, configRes, defaultResolutionOfferValue } = payload;
  if (conditionsRes) {
    state.conditionOptions = conditionsRes.conditions;
    state.itemOptions = conditionsRes.itemConditions;
    state.defaultOptions = conditionsRes.defaultConditions;

    [
      ...state.conditionOptions,
      ...state.itemOptions,
      ...state.defaultOptions,
    ].forEach((option) => {
      if (!state.variableNames.some((item: any) => item.id === option.id)) {
        state.variableNames.push({ name: option.name, id: option.id });
      }

      state.operatorsNames[option.name] = option.operators.map((operator) => {
        state.operatorSymbolMap[operator.value] = operator.name;
        return operator.name;
      });
      state.valueTypes[option.valueType] = null;
      state.variableNameToValueType[option.name] = option.valueType;
    });
  }

  if (configRes) {
    const step11Config = configRes;

    if (step11Config.length > 0) {
      state.selectedReturnTypeId = step11Config[0].configTabId.toString();
      state.returnTypes = step11Config.map((tab) => {
        return {
          returnTypeId: tab.configTabId.toString(),
          returnTypeName: tab.configTabName,
          messageForCustomer: {
            value: tab.notEligibleResponseMessage,
            error: null,
          },
          error: null,
          itemError: null,
          isAddConditionEnabled: true,
          isAddItemConditionEnabled: true,
          errorCount: 0,
          isValid: false,
          // itemSequence: idx,
          conditions: tab.conditions.map((condition) => {
            const selectedVariable: any = state.variableNames.find(
              (variable: any) => variable.id == condition.conditionTypeId,
            );

            if (selectedVariable) {
              const variableName = selectedVariable.name;
              const operatorSymbol = condition.operator;
              const operator = state.operatorSymbolMap[operatorSymbol];
              const valueType = state.variableNameToValueType[variableName];
              const values = getConditionValueOrDefault(
                valueType,
                condition.conditionValue,
              );

              return {
                id: condition.id.toString(),
                variableName,
                operator,
                values,
              } as ConditionTypes;
            }
            return {} as ConditionTypes;
          }),
          ExchangeWithSameItem:
            tab.returnTypes.find(
              (retType) => retType.key === "exchangeWithSameItems",
            )?.enabled ?? false,
          ExchangeWithOtherItem:
            tab.returnTypes.find(
              (retType) => retType.key === "exchangeWithOtherItems",
            )?.enabled ?? false,
          RefundtoStoreCredit:
            tab.returnTypes.find(
              (retType) => retType.key === "refundToStoreCredit",
            )?.enabled ?? false,
          RefundToOriginalPayment:
            tab.returnTypes.find(
              (retType) => retType.key === "refundToOriginalPaymentMethod",
            )?.enabled ?? false,
        };
      });
    } else {
      if (conditionsRes?.defaultConditions) {
        const conditions = conditionsRes.defaultConditions
          .map((condition) => {
            const selectedVariable: any = state.variableNames.find(
              (variable: any) => variable.id == condition.id,
            );
            if (selectedVariable) {
              const variableName = selectedVariable.name;
              const operatorSymbol = condition.operators[0].value;
              const operator = state.operatorSymbolMap[operatorSymbol];
              const valueType = state.variableNameToValueType[variableName];
              const values = getConditionValueOrDefault(valueType, null);

              return {
                id: uuid(),
                variableName,
                operator,
                values,
              } as ConditionTypes;
            }
            return null;
          })
          .filter(Boolean) as ConditionTypes[];

        state.returnTypes[0].conditions = conditions;
        state.returnTypes[0].returnTypeName = "All orders and items"; //changing as per xd

        if (defaultResolutionOfferValue) {
          let resolutionOffer: any = defaultResolutionOfferValue;
          const returnType = state.returnTypes[0];
          returnType.ExchangeWithSameItem =
            resolutionOffer?.exchangeWithSameItems ?? false;
          returnType.ExchangeWithOtherItem =
            resolutionOffer?.exchangeWithOtherItems ?? false;
          returnType.RefundtoStoreCredit =
            resolutionOffer?.refundToStoreCredits ?? false;
          returnType.RefundToOriginalPayment =
            resolutionOffer?.refundToOrigialPaymentMethod ?? false;
        }
      }
    }
  }

  const hash = createNewObjectHash({
    excludeKeys: state.excludeKeysForHash,
    hashObject: state.returnTypes,
  });

  if (hash) {
    state.initialHashOtherTab = hash;
    state.currentHashOtherTab = hash;
  }
};

const returnTypeBasedOnOrderOrItemsBuilders = (
  builder: ActionReducerMapBuilder<ReturnTypeBasedOnOrderOrItemsState>,
) => {
  builder.addCase(
    updateReturnTypeBasedOnOrderOrItemsThunk.pending,
    (state: ReturnTypeBasedOnOrderOrItemsState) => {
      state.updateAjaxStatus = "pending";
    },
  );
  builder.addCase(
    updateReturnTypeBasedOnOrderOrItemsThunk.fulfilled,
    (state: ReturnTypeBasedOnOrderOrItemsState, { payload }) => {
      state.updateAjaxStatus = "fulfilled";
      updateConfig(state, payload);
    },
  );
  builder.addCase(
    updateReturnTypeBasedOnOrderOrItemsThunk.rejected,
    (state: ReturnTypeBasedOnOrderOrItemsState) => {
      state.updateAjaxStatus = "rejected";
      pushTheToast({
        type: "danger",
        text: "failed to update!",
        position: "top-right",
      });
    },
  );
  builder.addCase(
    fetchReturnTypeBasedOnOrderOrItemsThunk.pending,
    (state: ReturnTypeBasedOnOrderOrItemsState) => {
      state.fetchAjaxStatus = "pending";
    },
  );
  builder.addCase(
    fetchReturnTypeBasedOnOrderOrItemsThunk.rejected,
    (state: ReturnTypeBasedOnOrderOrItemsState) => {
      state.fetchAjaxStatus = "rejected";
      pushTheToast({
        type: "danger",
        text: "failed to fetch configuration!",
        position: "top-right",
      });
    },
  );

  builder.addCase(
    fetchReturnTypeBasedOnOrderOrItemsThunk.fulfilled,
    (state: ReturnTypeBasedOnOrderOrItemsState, { payload }) => {
      state.fetchAjaxStatus = "fulfilled";
      updateConfig(state, payload);
    },
  );
};

export default returnTypeBasedOnOrderOrItemsBuilders;
