import { axiosJSON } from "src/globals/axiosEndPoints";
import { FieldValueInstance } from "./getConditionOptions.service";

/**
 * Interface for the parameters to be passed to the getConditionSelectDDService function.
 */
export interface ConditionSelectDDParams {
  segmentType: string;
  fieldKey: string;
  /**
   * Format of string - "key(1)::key(2)::key(n)" - If n=0 then undefined
   * This key represents the hierarchy of selections made in previous dropdowns.
   * For example, if the first dropdown value selected is key1, and the second dropdown value selected based on key1 is key2,
   * then the key for fetching the third dropdown would be "key1::key2". This chaining continues as more dropdowns are selected.
   */
  key?: string;
  searchText?: string;
  start: number;
  limit: number;
}

export interface ConditionSelectDDRes {
  data: Array<FieldValueInstance>;
  metaData?: { isCompleted: boolean };
}

/**
 * Fetches condition select dropdown data for a given segment type and parameters.
 *
 * @param params - ConditionSelectDDParams
 * @returns An array of FieldValueInstance.
 * @throws Will throw an error if the response contains an error message.
 */
async function getConditionSelectDDService({
  segmentType,
  ...params
}: ConditionSelectDDParams) {
  const { data: res } = await axiosJSON.post(
    `/api/segment/${segmentType}/getConditionSelectDD`,
    params,
  );

  if (res.error || res.err) {
    throw new Error(res.msg ?? res.message ?? "Something went wrong!");
  }

  const ret: ConditionSelectDDRes = {
    data: res.data,
    metaData: res.metaData,
  };

  return ret;
}

export default getConditionSelectDDService;
