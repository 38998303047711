import { useCallback, useEffect, useMemo, useState } from "react";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { useReturnAutoWorkFlow } from "src/features/ReturnAutoWorkFlow/hooks/useReturnAutoWorkFlow";
import {
  ConditionTypes,
  IVariableName,
  getConditionValueOrDefault,
} from "src/features/ReturnAutoWorkFlow/children/ConfigModals/store/returnModalsSliceHelpers";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  addCondition,
  addItemType,
  deleteCondition,
  nonEligibleItemsErrorCountSelector,
  pushCurrentHashForSelectedTab,
  updateCondition,
  updateItemType,
  validateChanges,
  validateCondition,
  validateItemType,
} from "../../store/slices/NonEligibleItemsSlice/nonEligibleItems.slice";
import {
  fetchNonEligibleItemsThunk,
  updateNonEligibleItemsThunk,
  validateNonEligibleItemsThunk,
} from "../../store/slices/NonEligibleItemsSlice/nonEligibleItems.thunk";

const useNonEligibleItems = ({
  onSave,
}: {
  onSave: (isSaved: boolean) => void;
}) => {
  const { returnAutoWorkFlow } = useReturnAutoWorkFlow();

  const dispatch = useAppDispatch();
  const {
    itemTypes,
    selectedItemTypeId,
    fetchAjaxStatus,
    updateAjaxStatus,
    initialHash,
    currentHash,
    operatorsNames: operators,
    variableNameToValueType: valuesTypes,
    conditionOptions: conditionOptionsRaw,
    defaultOptions: defaultOptionsRaw,
    itemOptions: itemOptionsRaw,
  } = useAppSelector((state) => {
    return state.nonEligibleItems;
  });

  const [selectedItem, selectedItemTypeIndex] = useMemo(() => {
    const index = itemTypes.findIndex(
      (itemType) => itemType.itemTypeId === selectedItemTypeId,
    );
    const itemType = index !== -1 ? itemTypes[index] : undefined;
    return [itemType, index];
  }, [itemTypes, selectedItemTypeId]);

  const isAddBtnEnabled = useMemo(
    () => selectedItem?.isAddConditionEnabled ?? false,
    [selectedItem?.isAddConditionEnabled],
  );

  const isAddItemBtnEnabled = useMemo(
    () => selectedItem?.isAddItemConditionEnabled ?? false,
    [selectedItem?.isAddItemConditionEnabled],
  );

  const itemOptions = useMemo(
    () => Object.values(itemOptionsRaw).map((option) => option.name),
    [itemOptionsRaw],
  );

  const conditionOptions = useMemo(
    () => Object.values(conditionOptionsRaw).map((option) => option.name),
    [conditionOptionsRaw],
  );

  const defaultOptions = useMemo(
    () => Object.values(defaultOptionsRaw).map((option) => option.name),
    [defaultOptionsRaw],
  );

  const [otherTabsHasError, setOtherTabsHasError] = useState(false);

  const otherTabErrorCount = useAppSelector(nonEligibleItemsErrorCountSelector);

  useEffect(() => {
    if (otherTabErrorCount <= 0) {
      setOtherTabsHasError(false);
    } else {
      setOtherTabsHasError(true);
    }
  }, [otherTabErrorCount]);

  useEffect(() => {
    if (!returnAutoWorkFlow.integrationId) {
      return;
    }

    // TODO -
    dispatch(
      fetchNonEligibleItemsThunk({
        integrationId: returnAutoWorkFlow.integrationId,
        selectedReturnWindow: "",
      }),
    );
    if (selectedItemTypeId) {
      dispatch(
        validateItemType({
          itemTypeId: selectedItemTypeId,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const dispUpdateItemType = useCallback(
    (id: string, updateObj: any) => {
      dispatch(
        updateItemType({
          itemTypeDetail: {
            ...itemTypes,
            itemTypeId: id,
            ...updateObj,
          },
        }),
      );
      dispatch(validateChanges());
    },
    [dispatch, itemTypes],
  );

  //function to update the current tab hash string, which is used to identify if the data is changed or not
  const dispPushCurrentHashForSelectedTab = useCallback(
    (id: string) => {
      dispatch(
        pushCurrentHashForSelectedTab({
          itemTypeId: id,
        }),
      );
      dispatch(validateChanges());
    },
    [dispatch],
  );

  //function to add new tab
  const dispAddTab = useCallback(() => {
    dispatch(addItemType());
    dispatch(validateChanges());
  }, [dispatch]);

  //function to validate condition of passed tab id
  const dispValidateCondition = useCallback(
    (id: string, isInitial?: boolean) => {
      dispatch(
        validateCondition({
          itemTypeId: id,
          isInitial,
        }),
      );
      dispatch(validateChanges());
    },
    [dispatch],
  );

  useMemo(() => {
    if (selectedItemTypeId) {
      dispValidateCondition(selectedItemTypeId, true);
    }
  }, [dispValidateCondition, selectedItemTypeId]);

  //20 Jan Added to show validation on modal load itself
  useMemo(() => {
    if (selectedItemTypeId && dispValidateCondition) {
      if (selectedItemTypeIndex !== -1 && selectedItemTypeIndex !== 0)
        dispValidateCondition(selectedItemTypeId);
    }
  }, [dispValidateCondition, selectedItemTypeId, selectedItemTypeIndex]);

  //function to update conditionType of a condition
  const dispUpdateConditionForVariables = useCallback(
    (
      id: string,
      e: { target: { value: string } },
      condition: ConditionTypes,
    ) => {
      dispatch(
        updateCondition({
          itemTypeId: id,
          conditionToUpdate: {
            ...condition,
            variableName: e.target.value as IVariableName,
            operator: operators[e.target.value as IVariableName][0],
            values: getConditionValueOrDefault(
              valuesTypes[e.target.value as IVariableName],
            ),
          },
        }),
      );
    },
    [dispatch, operators, valuesTypes],
  );

  function dispUpdateConditonForOperator(
    id: string,
    e: { target: { value: string } },
    condition: ConditionTypes,
  ) {
    dispatch(
      updateCondition({
        itemTypeId: id,
        conditionToUpdate: {
          ...condition,
          operator: e.target.value,
        },
      }),
    );
  }

  //function to update condition value by its value type
  const dispUpdateConditionForValues = useCallback(
    (id: string, type: string, value: any, condition: ConditionTypes) => {
      switch (type) {
        case "day_type":
          dispatch(
            updateCondition({
              itemTypeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  day_type: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;
        case "days":
          dispatch(
            updateCondition({
              itemTypeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  days: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;
        case "tags":
          dispatch(
            updateCondition({
              itemTypeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  tags: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;
        case "items":
          dispatch(
            updateCondition({
              itemTypeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  items: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;
        case "codes":
          dispatch(
            updateCondition({
              itemTypeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  codes: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;
        case "numbers":
          dispatch(
            updateCondition({
              itemTypeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  numbers: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;
        case "countryName":
          dispatch(
            updateCondition({
              itemTypeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  countryName: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;
        case "amount":
          dispatch(
            updateCondition({
              itemTypeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  amount: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;
        case "currency":
          dispatch(
            updateCondition({
              itemTypeId: id,
              conditionToUpdate: {
                ...condition,
                values: {
                  ...condition.values,
                  currency: {
                    currentValue: value,
                  },
                },
              },
            }),
          );

          break;

        default:
          break;
      }
      dispatch(validateChanges());
    },
    [dispatch],
  );

  //function to delete a condition by its id
  const dispDeleteCondition = useCallback(
    (conId: string, id: string, isItem?: boolean) => {
      dispatch(
        deleteCondition({
          conditionId: conId,
          itemTypeId: id,
          isItemCondition: isItem,
        }),
      );
    },
    [dispatch],
  );

  const dispValidateItemType = useCallback(
    (id: string) => {
      dispatch(
        validateItemType({
          itemTypeId: id,
        }),
      );
    },
    [dispatch],
  );

  //function to save/submit the configuration modal data
  const dispPostConfig = useCallback(async () => {
    if (!returnAutoWorkFlow.integrationId) {
      return;
    }
    //dispatching the validation thunk and
    // then if the validation passes then calling the submit api otherwise showing warning toast
    const res = await dispatch(validateNonEligibleItemsThunk());

    if (res.payload === 0) {
      dispatch(
        updateNonEligibleItemsThunk({
          callback: () => {
            onSave(true);
          },
          // TODO -
          integrationId: returnAutoWorkFlow.integrationId,
          selectedReturnWindow: "",
        }),
      );
    } else {
      pushTheToast({
        type: "warning",
        text: "Please fill the required fields!",
        position: "top-right",
      });
    }
  }, [dispatch, onSave, returnAutoWorkFlow.integrationId]);

  //function to add new condition for the passed tab id
  const dispAddCondition = useCallback(
    (id: string, isItem?: boolean, e?: { target: { value: string } }) => {
      dispatch(
        addCondition({
          itemTypeId: id,
          condition: e ? e.target.value : "",
          isItemCondition: isItem,
        }),
      );
      dispatch(validateChanges());
    },
    [dispatch],
  );

  return {
    dispAddCondition,
    dispAddTab,
    dispDeleteCondition,
    dispPostConfig,
    dispPushCurrentHashForSelectedTab,
    dispUpdateConditionForValues,
    dispUpdateConditionForVariables,
    dispUpdateConditonForOperator,
    dispUpdateItemType,
    dispValidateCondition,
    dispValidateItemType,
    itemTypes,
    fetchAjaxStatus,
    updateAjaxStatus,
    initialHash,
    currentHash,
    selectedItem,
    selectedItemTypeId,
    isAddBtnEnabled,
    isAddItemBtnEnabled,
    otherTabsHasError,
    defaultOptions,
    itemOptions,
    conditionOptions,
    operators,
  };
};
export default useNonEligibleItems;
