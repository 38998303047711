/**
 * This file is the React component file for Bot Analytics.
 * It renders instances of the ReportTicketBody component with different configurations.
 * Each instance corresponds to a specific analytics endpoint related to bot.
 *
 * @author Yash Aditya
 */

import { useAppSelector } from "src/store/store";
import ReportTicketBody from "../ReportTicketBody/ReportTicketBody";
import { useState } from "react";

// React component for Bot Analytics
function BotAnalytics() {
  const ui_visibility = useAppSelector(
    (state) => state.globals.currentUserData?.ui_visibility
  );

  // const [ui_visibility] = useState([
  //   "bot_percentageCernFoundUseful",
  //   "bot_volumeOfChartsByCern",
  //   "bot_chartsCernDidNotFindAnswersByTime",
  //   "bot_mostUsedArticlesByCernToAnswerQuestions",
  //   "bot_mostUsedCustomAnsByCernToAnswerQuestions",
  // ]);

  return (
    <div>
      {/* Bot percentage cern found useful */}
      {ui_visibility?.includes("bot_percentageCernFoundUseful") && (
        <ReportTicketBody
          endPoint={"bot/percentageCernFoundUseful"}
          title={"Percentage of users who found Cern's responses to be useful"}
          tooltip={""}
          tabProps={{
            showComparison: false,
            showDailyPart: true,
            showListView: true,
          }}
        />
      )}

      {/* Bot volume of charts by cern */}
      {ui_visibility?.includes("bot_volumeOfChartsByCern") && (
        <ReportTicketBody
          endPoint={"bot/volumeOfChartsByCern"}
          title={"Volume of chats handled by Cern"}
          tooltip={""}
          tabProps={{
            showComparison: false,
            showDailyPart: true,
            showListView: true,
          }}
        />
      )}

      {/* Bot charts cern did not find answers by time */}
      {ui_visibility?.includes("bot_chartsCernDidNotFindAnswersByTime") && (
        <ReportTicketBody
          endPoint={"bot/chartsCernDidNotFindAnswersByTime"}
          title={"How many chats cern could not find an answer for by time"}
          tooltip={""}
          tabProps={{
            showComparison: false,
            showDailyPart: true,
            showListView: true,
          }}
        />
      )}

      {/* Bot most used articles by cern to answer questions */}
      {ui_visibility?.includes(
        "bot_mostUsedArticlesByCernToAnswerQuestions"
      ) && (
        <ReportTicketBody
          endPoint={"bot/mostUsedArticlesByCernToAnswerQuestions"}
          title={"Most used articles by cern to answer the questions"}
          tooltip={""}
          tabProps={{
            showComparison: false,
            showDailyPart: false,
            showListView: true,
          }}
        />
      )}

      {/* Bot most used custom ans by cern to answer questions */}
      {ui_visibility?.includes(
        "bot_mostUsedCustomAnsByCernToAnswerQuestions"
      ) && (
        <ReportTicketBody
          endPoint={"bot/mostUsedCustomAnsByCernToAnswerQuestions"}
          title={
            "Most used custom answers used by cern to answer the questions"
          }
          tooltip={""}
          tabProps={{
            showComparison: false,
            showDailyPart: false,
            showListView: true,
          }}
        />
      )}
    </div>
  );
}

export default BotAnalytics;
