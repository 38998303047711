import { useEffect, useRef, useState } from "react";
import styles from "./ArticleInfo.module.scss";
import bulb from "src/assets/images/bulb.png";
import dummy from "src/assets/images/dummy1.png";
import Loader from "src/components/Loader";
import Footer from "../Footer/Footer";
import useArticleInfo from "./useArticleInfo";
import helpLogo from "src/assets/images/helpCenter.png";
import CustomCode from "../CustomCode/CustomCode";
import user from "src/assets/images/userImg.png";
import { useLocation } from "react-router-dom";
import happy from "src/assets/images/happy/happy@2x.png";
import smile from "src/assets/images/smiling/smiling@2x.png";
import sad from "src/assets/images/sad/sad@2x.png";

type Props = {
  integrationIdentifier: string;
};

const ArticleInfo = ({ integrationIdentifier }: Props) => {
  const {
    detailDesc,
    setDetailDesc,
    articleData,
    ratingData,
    themeData,
    updateRating,
    ratingText,
    loader,
    ratingMade,
    comment,
    setComment,
    submitComment,
    disableComments,
    handleSearch,
    helpCenterPageUri,
    navigate,
    searchTerm,
    giveFeedback,
    articleHasError,
    articleIsLoading,
    ratingHasError,
    ratingIsLoading,
    themeDataIsLoading,
    submitButtonLoader,
  } = useArticleInfo({ integrationIdentifier });

  // FavIcon
  const link = document.querySelector('link[rel="icon"]');

  useEffect(() => {
    if (themeData?.data?.favicon && helpCenterPageUri !== "") {
      link?.setAttribute("href", themeData?.data?.favicon);
    } else {
      link?.setAttribute("href", helpLogo);
    }
  }, [themeData?.data]);

  const location = useLocation();

  if (articleIsLoading || ratingIsLoading || themeDataIsLoading)
    return (
      <div>
        <Loader />
      </div>
    );

  if (articleHasError || ratingHasError)
    return (
      <div
        className={`${styles.errorMsg}`}
        style={{
          fontFamily: themeData?.data?.font ?? "Roboto", // font family
        }}
      >
        <p>Something went wrong</p>
      </div>
    );

  return (
    <>
      <CustomCode // CustomCode for Head and Body
        customCodeBody={themeData?.data?.customCodeBody ?? ""}
        customCodeHead={themeData?.data?.customCodeHead ?? ""}
      />
      <div className={`${styles.previewMain}`}>
        <div
          className={`position-relative`}
          style={{
            backgroundColor: `${themeData?.data?.backgroundColor ?? "black"}`, // Background Color
            backgroundImage: `url(${themeData?.data?.backgroundImage ?? ""})`, // Background Image
            backgroundSize: `cover`,
            backgroundRepeat: "no-repeat",
            padding: "30px 10px 0px 10px",
            height: "232px",
          }}
        >
          <div>
            <p className="text-center mb-1 d-flex align-items-center justify-content-center">
              <img
                src={themeData?.data?.logoImage ?? helpLogo} // Logo Image
                alt="logo"
                className={`${styles.helpLogo}`}
              />
              {/* Store Name */}
              <span
                className={`ms-2 ${styles.acme}`}
                style={{
                  fontFamily: themeData?.data?.font ?? "Roboto", // font family
                }}
              >
                {themeData?.data?.storeName}
              </span>
            </p>
            {/* Heading and Subheading */}
            <h3
              className={`text-center ${styles.helpText}`}
              style={{
                fontFamily: themeData?.data?.font ?? "Roboto", // font family
              }}
            >
              {themeData?.data?.heading}
            </h3>
            <p
              className={`text-center ${styles.subHeading}`}
              style={{
                fontFamily: themeData?.data?.font ?? "Roboto", // font family
              }}
            >
              {themeData?.data?.subheading}
            </p>
          </div>
          {/* Search Box */}
          <div className={`w-100 text-center ${styles.searchBox22}`}>
            <input
              type="text"
              placeholder={`${themeData?.data?.placeholder}`}
              className={`${styles.inputBox}`}
              onChange={handleSearch}
              value={searchTerm}
              style={{
                fontFamily: themeData?.data?.font ?? "Roboto", // font family
              }}
            />
            <span className={`${styles.search}`}>
              <i className="fa-solid fa-magnifying-glass"></i>
            </span>
          </div>
        </div>
        <div
          style={{
            backgroundColor: `${
              themeData?.data?.themeType === "night_in_the_oscars"
                ? "#000000"
                : "#ffffff"
            }`,
            paddingTop: "99px",
          }}
        >
          <div className={`${styles.specificArticle} pb-2 `}>
            {/* Path Rendering Based on State Coming from Navigate */}
            <div>
              <span
                className={`${styles.featuredArticles} ${
                  themeData?.data?.themeType === "alice_in_wonderland" &&
                  styles.aliceHeader
                }`}
                role="button"
                onClick={() => {
                  navigate(`/helpcenterpage/${helpCenterPageUri}`);
                }}
                style={{
                  color: `${themeData?.data?.brandColor}`,
                  fontFamily: themeData?.data?.font ?? "Roboto",
                }}
              >
                {location.state?.path === "category"
                  ? "Categories >"
                  : "Featured Articles > "}
              </span>
              &nbsp; &nbsp;
              {location.state?.path === "category" &&
                location.state.categoryName && (
                  <span
                    className={`ms-2 ${styles.featuredArticles} ${
                      themeData?.data?.themeType === "alice_in_wonderland" &&
                      styles.aliceHeader
                    }`}
                    role="button"
                    onClick={() => {
                      navigate(
                        `/helpcenterpage/${helpCenterPageUri}/category/${location.state?.categoryId}`
                      );
                    }}
                    style={{
                      color: `${themeData?.data?.brandColor}`,
                      fontFamily: themeData?.data?.font ?? "Roboto",
                    }}
                  >
                    {`${location.state?.categoryName ?? ""} > `}
                  </span>
                )}
              {/* Article Name */}
              <span
                className={`${styles.articleNameRoute} ms-2 ${
                  themeData?.data?.themeType === "alice_in_wonderland" &&
                  styles.aliceSubHeader
                }`}
                style={{
                  fontFamily: themeData?.data?.font ?? "Roboto", // font family
                }}
              >
                {articleData?.data?.articleName}
              </span>
            </div>
            {/* Article Details  */}
            <div className={`mb-2 ${styles.detailWrapper} mt-3`}>
              {/* Article Info */}
              <div className="d-flex justify-content-between flex-column">
                <div className="w-75">
                  <h1
                    className={`mb-0 ${styles.heading}`}
                    style={{
                      fontFamily: themeData?.data?.font ?? "Roboto", // font family
                    }}
                  >
                    {articleData?.data?.articleName}
                  </h1>
                  {/* <span className={`d-block ${styles.subHeading}`}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: articleData?.data?.articleDescription,
                    }}
                  />
                </span> */}
                </div>
                {/* User Details */}
                <div className="d-flex w-100 justify-content-start mt-2">
                  <div>
                    <div className="d-flex align-items-center">
                      {articleData?.data?.authorDetails?.imageURL ? (
                        <img
                          src={articleData?.data?.authorDetails?.imageURL}
                          alt=""
                          className={`${styles.authorImg}`}
                        />
                      ) : (
                        <span className={`${styles.userBox}`}>
                          <i className="fa-solid fa-user"></i>
                        </span>
                      )}
                      <div className="ps-1 w-100 d-flex ">
                        <span
                          className={`ms-1 mb-0 ${styles.author}`}
                          style={{
                            fontFamily: themeData?.data?.font ?? "Roboto", // font family
                          }}
                        >
                          {"Written by"}
                        </span>
                        <p
                          className={`mb-0 ${styles.author} ms-1`}
                          style={{
                            fontFamily: themeData?.data?.font ?? "Roboto", // font family
                          }}
                        >
                          {articleData?.data?.authorDetails?.name}
                        </p>
                      </div>
                    </div>
                    <span
                      className={`ps-1 ${styles.updatedDate}`}
                      style={{
                        fontFamily: themeData?.data?.font ?? "Roboto", // font family
                      }}
                    >
                      {"Updated on "}
                      <span
                        style={{
                          fontFamily: themeData?.data?.font ?? "Roboto", // font family
                        }}
                      >
                        {new Date(
                          articleData?.data?.updatedAtGmt ?? ""
                        ).toLocaleDateString("en-US", {
                          month: "long",
                          day: "numeric",
                          year: "numeric",
                        })}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              {/* Aritcle Description */}
              <div className="mt-4">
                <p
                  className={`${styles.info}`}
                  style={{
                    fontFamily: themeData?.data?.font ?? "Roboto", // font family
                  }}
                >
                  {/* Add target="_blank" attribute to all anchor tags in the
                  article description // This ensures that the hyperlinks open
                  in a new tab when clicked */}
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        articleData?.data?.articleDescription?.replace(
                          /<a /g,
                          '<a target="_blank" '
                        ) ?? "",
                    }}
                    style={{
                      fontFamily: themeData?.data?.font ?? "Roboto", // font family
                    }}
                  />
                </p>
              </div>
              {/* Rating Section */}
              <div className={`p-3 mb-3 ${styles.reviewBox}`}>
                {loader ? (
                  <Loader className={`${styles.loaderStyle}`} />
                ) : ratingData?.data?.userAllowedToRate ? (
                  <div className="py-2">
                    <p
                      className={`${styles.reviewHelp}`}
                      style={{
                        fontFamily: themeData?.data?.font ?? "Roboto", // font family
                      }}
                    >
                      {ratingText
                        ? ratingText
                        : "Did you find this article helpful?"}
                    </p>
                    <div>
                      {!ratingMade ? (
                        <>
                          {ratingData?.data.feedbackRatingType === "emojis" ? (
                            <div className="d-flex justify-content-center align-items-center">
                              <div
                                className={`text-center ${styles.emojiWrap}`}
                                role="button"
                                onClick={() => {
                                  updateRating("happy");
                                }}
                              >
                                <p className="mb-0">
                                  <img
                                    src={smile}
                                    alt=""
                                    className={`${styles.faceEmoji}`}
                                  />
                                </p>
                                <span
                                  className={`${styles.feeling}`}
                                  style={{
                                    fontFamily:
                                      themeData?.data?.font ?? "Roboto", // font family
                                  }}
                                >
                                  Happy
                                </span>
                              </div>
                              <div
                                className={`text-center ${styles.emojiWrap}`}
                                role="button"
                                onClick={() => {
                                  updateRating("neutral");
                                }}
                              >
                                <p className="mb-0">
                                  <img
                                    src={happy}
                                    alt=""
                                    className={`${styles.faceEmoji}`}
                                  />
                                </p>
                                <span
                                  className={`${styles.feeling}`}
                                  style={{
                                    fontFamily:
                                      themeData?.data?.font ?? "Roboto", // font family
                                  }}
                                >
                                  Neutral
                                </span>
                              </div>
                              <div
                                className={`text-center ${styles.emojiWrap}`}
                                role="button"
                                onClick={() => {
                                  updateRating("sad");
                                }}
                              >
                                <p className="mb-0">
                                  <img
                                    src={sad}
                                    alt=""
                                    className={`${styles.faceEmoji}`}
                                  />
                                </p>
                                <span
                                  className={`${styles.feeling}`}
                                  style={{
                                    fontFamily:
                                      themeData?.data?.font ?? "Roboto", // font family
                                  }}
                                >
                                  Sad
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div className="d-flex justify-content-center align-items-center">
                              <div
                                className="px-2 text-center"
                                role="button"
                                onClick={() => {
                                  updateRating("yes");
                                }}
                              >
                                <p className={`mb-0 ${styles.thumbs}`}>👍</p>
                                <span
                                  className={`${styles.feeling}`}
                                  style={{
                                    fontFamily:
                                      themeData?.data?.font ?? "Roboto", // font family
                                  }}
                                >
                                  Yes
                                </span>
                              </div>
                              <div
                                className="px-2 text-center"
                                role="button"
                                onClick={() => {
                                  updateRating("no");
                                }}
                              >
                                <p className={`mb-0 ${styles.thumbs}`}>👎</p>
                                <span
                                  className={`${styles.feeling}`}
                                  style={{
                                    fontFamily:
                                      themeData?.data?.font ?? "Roboto", // font family
                                  }}
                                >
                                  No
                                </span>
                              </div>
                            </div>
                          )}
                        </>
                      ) : (
                        ""
                      )}

                      {ratingData?.data.ratingShowHelpCount && (
                        <p
                          className={`text-center ${styles.helpful}`}
                          style={{
                            fontFamily: themeData?.data?.font ?? "Roboto", // font family
                          }}
                        >
                          {ratingData?.data.positiveArticleRating} out of &nbsp;
                          {ratingData?.data.totalArticleRating} people found
                          this article helpful so far
                        </p>
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {/* Comment Section  */}
                {ratingData?.data.commentsEnabled ? (
                  <div className="">
                    <p
                      className={`mb-0 ${styles.comment}`}
                      style={{
                        fontFamily: themeData?.data?.font ?? "Roboto", // font family
                      }}
                    >
                      Comments
                    </p>
                    <span
                      className={`${styles.commentCount}`}
                      style={{
                        fontFamily: themeData?.data?.font ?? "Roboto", // font family
                      }}
                    >
                      {ratingData.data.totalComment} comments
                    </span>
                    <div className="d-flex w-100 mt-4">
                      <span className={`${styles.userBox}`}>
                        <i className="fa-solid fa-user"></i>
                      </span>
                      {disableComments ? (
                        <>
                          {/* <textarea
                            name=""
                            id=""
                            rows={5}
                            className={`w-100 ms-2 p-2 ${styles.commentText} position-relative`}
                            disabled
                          ></textarea> */}
                          <div
                            className={`w-100 ms-2 p-2 ${styles.commentText} text-center `}
                            style={{
                              zIndex: 5,
                              bottom: "26%",
                              left: "39%",
                            }}
                          >
                            <span
                              className={`position-relative ${styles.caution} d-block mb-2 justify-content-center`}
                            >
                              <i className="fa-solid fa-circle-exclamation"></i>
                              <span className={`${styles.timer}`}>
                                <i className="fa-regular fa-clock"></i>
                              </span>
                            </span>
                            {ratingData.data.commentApprovalRequired ? (
                              <>
                                {ratingData.data.commentsType === "approved" ? (
                                  <p
                                    className={`mb-0 ${styles.commentPara}`}
                                    style={{
                                      fontFamily:
                                        themeData?.data?.font ?? "Roboto", // font family
                                    }}
                                  >
                                    Your comment has been approved!
                                  </p>
                                ) : ratingData.data.commentsType ===
                                  "unApproved" ? (
                                  <>
                                    <p
                                      className={`mb-0 ${styles.commentPara}`}
                                      style={{
                                        fontFamily:
                                          themeData?.data?.font ?? "Roboto", // font family
                                      }}
                                    >
                                      Your comment has been sent for approval!
                                    </p>
                                    <span
                                      className={`${styles.commentSpan}`}
                                      style={{
                                        fontFamily:
                                          themeData?.data?.font ?? "Roboto", // font family
                                      }}
                                    >
                                      Once approved will be published
                                      immediately...
                                    </span>
                                  </>
                                ) : (
                                  ""
                                )}
                              </>
                            ) : (
                              <p
                                className={`mb-0 ${styles.commentPara}`}
                                style={{
                                  fontFamily: themeData?.data?.font ?? "Roboto", // font family
                                }}
                              >
                                Your comment has been published!
                              </p>
                            )}
                          </div>
                        </>
                      ) : (
                        <textarea
                          name=""
                          id=""
                          rows={5}
                          className={`w-100 ms-2 p-2 ${styles.commentText}`}
                          onChange={(e) => {
                            setComment(e.target.value);
                          }}
                          value={comment}
                          style={{
                            fontFamily: themeData?.data?.font ?? "Roboto", // font family
                          }}
                        ></textarea>
                      )}
                    </div>
                    <div className="mt-2 text-end">
                      <button
                        className={`${styles.submitBtn}`}
                        onClick={submitComment}
                        disabled={giveFeedback.isLoading || disableComments}
                      >
                        <div
                          className="d-flex align-items-center justify-content-center"
                          style={{
                            fontFamily: themeData?.data?.font ?? "Roboto", // font family
                          }}
                        >
                          {disableComments ? "Submitted" : "Submit"}
                          {submitButtonLoader && (
                            <span
                              className=" ms-2 spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          )}
                        </div>
                      </button>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            backgroundColor: `${
              themeData?.data?.themeType === "night_in_the_oscars"
                ? "#000000"
                : "#ffffff"
            }`,
          }}
        >
          <Footer
            show={themeData?.data?.helplamaBranding ? true : false}
            footerLogo={themeData?.data.footerLogo ?? ""}
            font={themeData?.data?.font ?? "Roboto"}
            helplamaBrandingText={themeData?.data?.helplamaBrandingText} //passing "helplamaBrandingText" prop to render powered by text dynamically
            nightTheme={
              themeData?.data?.themeType === "night_in_the_oscars"
                ? true
                : false
            }
          />
        </div>
      </div>
    </>
  );
};

export default ArticleInfo;
