import { createSlice } from "@reduxjs/toolkit";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import {
  fetchCancelOrder,
  fetchCreateFulfillment,
  fetchCreateRefund,
  fetchCalculateRefundTotalPrice,
  fetchGetFulfillmentOrder,
  fetchRefundModal,
  fetchCancelOrderReasonOption,
  fetchDeletOrder,
  fetchDuplicateOrder,
  fetchGetOrderViewModel,
  fetchMarkOrderAsPaid,
  fetchPaymentTermOption,
  fetchSendInvoice,
  fetchUpdateOrder,
  cancelFulfillmentThunk,
  fetchHoldFulfillment,
  fetchHoldFulfillmentReason,
  fetchReleaseFulfillment,
  addOrUpdateOrderTagsThunk,
  updateOrderShippingAddressThunk,
  fetchAllCountryList,
  fetchGetTrackingData,
  updateContactDetailsThunk,
  updateCustomerOrderNotesThunk,
  updateTrackingThunk,
  getShopifyCustomerDataThunk,
} from "./order.thunks";

import orderReducers from "./order.reducers";

import { OrderSliceState as _OrderSliceState } from "./OrderSliceState";

const initialState: _OrderSliceState =  {
  loading: false,
  error: undefined,
  cancelOrder: undefined,
  cancelOrderReasonOption: undefined,
  deleteOrder: undefined,
  duplicateOrder: undefined,
  getOrderViewModel: undefined,
  markOrderAsPaid: undefined,
  paymentTermOption: undefined,
  sendInvoice: undefined,
  updateOrder: {
    tagAddOrUpdateAjaxStatus: "idle",
    contactDetailsUpdateAjaxStatus: "idle",
    orderShippingAddressUpdateAjaxStatus: "idle",
    customerOrderNotesUpdateAjaxStatus:"idle",
    updateOrder: undefined,
  },
  cancelFulfillment: {
    cancelFulfillmentAjaxStatus: "idle",
    cancelFulfillmentRes: undefined
  },
  createFulfillment: undefined,
  getFulfillment: undefined,
  holdFulfillment: undefined,
  holdFulfillmentReason: undefined,
  releaseFulfillment: undefined,
  calculateRefundTotalPrice: {
    loading: false,
    error: undefined,
    calculatedRefundTotalPrice: undefined,
  },
  createRefund: undefined,
  refundModel: undefined,
  allCountryList: {
    fetchAllCountryListAjaxStatus: "idle",
    countryList: [],
    countryWithIdList: {},
  },
  getTrackingData: {
    loading: false,
    trackingDataAjaxStatus: "pending",
    trackingData: undefined,
  },
  updateTracking:{
    updateTrackingAjaxStatus: "idle",
    updateTrackingRes: undefined
  },
  shopifySingleCustomerData: {
    getShopifyCustomerDataAjaxStatus: "idle",
    customerData: undefined
  }
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: orderReducers,
  extraReducers: (builder) => {
    // Cases for Cancel Order
    builder.addCase(fetchCancelOrder.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchCancelOrder.fulfilled, (state, action) => {
      state.loading = false;
      state.cancelOrder = action.payload;
      state.error = "";
    });
    builder.addCase(fetchCancelOrder.rejected, (state, action) => {
      state.loading = false;
      state.cancelOrder = undefined;
      state.error = action.error.message;
    });

    // Cases for CancelOrderReasonOption
    builder.addCase(fetchCancelOrderReasonOption.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchCancelOrderReasonOption.fulfilled, (state, action) => {
      state.loading = false;
      state.cancelOrderReasonOption = action.payload;
      state.error = "";
    });
    builder.addCase(fetchCancelOrderReasonOption.rejected, (state, action) => {
      state.loading = false;
      state.cancelOrderReasonOption = undefined;
      state.error = action.error.message;
    });

    //Cases for Delete Order
    builder.addCase(fetchDeletOrder.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchDeletOrder.fulfilled, (state, action) => {
      state.loading = false;
      state.deleteOrder = action.payload;
      state.error = "";
    });
    builder.addCase(fetchDeletOrder.rejected, (state, action) => {
      state.loading = false;
      state.deleteOrder = undefined;
      state.error = action.error.message;
    });

    // Cases for Duplicate Order
    builder.addCase(fetchDuplicateOrder.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchDuplicateOrder.fulfilled, (state, action) => {
      state.loading = false;
      state.duplicateOrder = action.payload;
      state.error = "";
    });
    builder.addCase(fetchDuplicateOrder.rejected, (state, action) => {
      state.loading = false;
      state.duplicateOrder = undefined;
      state.error = action.error.message;
    });

    // Cases for Get Order View Model
    builder.addCase(fetchGetOrderViewModel.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchGetOrderViewModel.fulfilled, (state, action) => {
      state.loading = false;
      state.getOrderViewModel = action.payload;
      state.error = "";
    });
    builder.addCase(fetchGetOrderViewModel.rejected, (state, action) => {
      state.loading = false;
      state.getOrderViewModel = undefined;
      state.error = action.error.message;
    });

    // Cases for Mark Order As Paid
    builder.addCase(fetchMarkOrderAsPaid.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchMarkOrderAsPaid.fulfilled, (state, action) => {
      state.loading = false;
      state.markOrderAsPaid = action.payload;
      state.error = "";
    });
    builder.addCase(fetchMarkOrderAsPaid.rejected, (state, action) => {
      state.loading = false;
      state.markOrderAsPaid = undefined;
      state.error = action.error.message;
    });

    // Cases for Payment Term Option
    builder.addCase(fetchPaymentTermOption.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchPaymentTermOption.fulfilled, (state, action) => {
      state.loading = false;
      state.paymentTermOption = action.payload;
      state.error = "";
    });
    builder.addCase(fetchPaymentTermOption.rejected, (state, action) => {
      state.loading = false;
      state.paymentTermOption = undefined;
      state.error = action.error.message;
    });
    // Cases for Send Invoice

    builder.addCase(fetchSendInvoice.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSendInvoice.fulfilled, (state, action) => {
      state.loading = false;
      state.sendInvoice = action.payload;
      state.error = "";
    });
    builder.addCase(fetchSendInvoice.rejected, (state, action) => {
      state.loading = false;
      state.sendInvoice = undefined;
      state.error = action.error.message;
    });

    // Cases for Update Order
    builder.addCase(fetchUpdateOrder.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchUpdateOrder.fulfilled, (state, action) => {
      state.loading = false;
      state.updateOrder = {
        // tagAddOrUpdateAjaxStatus: "fulfilled",
        updateOrder: action.payload,
      };
      state.error = "";
    });
    builder.addCase(fetchUpdateOrder.rejected, (state, action) => {
      state.loading = false;
      state.updateOrder = {
        // tagAddOrUpdateAjaxStatus: "rejected",
        updateOrder: undefined,
      };
      state.error = action.error.message;
    });

    // Cases for Cancel Fulfillment
    builder.addCase(cancelFulfillmentThunk.pending, (state) => {
      state.cancelFulfillment.cancelFulfillmentAjaxStatus = "pending";
    });
    builder.addCase(cancelFulfillmentThunk.fulfilled, (state, action) => {
      state.cancelFulfillment.cancelFulfillmentRes = action.payload;
      pushTheToast({
        type: "success",
        text: "Fulfillment cancelled, will reflect soon  !!!",
        position: "top-right",
      });
      state.cancelFulfillment.cancelFulfillmentAjaxStatus = "fulfilled";
    });
    builder.addCase(cancelFulfillmentThunk.rejected, (state, action) => {
      state.cancelFulfillment.cancelFulfillmentRes = undefined;
      state.cancelFulfillment.cancelFulfillmentAjaxStatus = "rejected";
      pushTheToast({
        type: "danger",
        text: "Failed to cancel fulfillment  !!!",
        position: "top-right",
      });
    });

    // Cases for Create Fulfillment
    builder.addCase(fetchCreateFulfillment.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchCreateFulfillment.fulfilled, (state, action) => {
      state.loading = false;
      state.createFulfillment = action.payload;
      state.error = "";
    });
    builder.addCase(fetchCreateFulfillment.rejected, (state, action) => {
      state.loading = false;
      state.createFulfillment = undefined;
      state.error = action.error.message;
    });

    // Cases for Get Fulfillment Order
    builder.addCase(fetchGetFulfillmentOrder.pending, (state) => {
      state.loading = true;
      state.getFulfillment = undefined;
    });
    builder.addCase(fetchGetFulfillmentOrder.fulfilled, (state, action) => {
      state.loading = false;
      state.getFulfillment = action.payload;
      state.error = "";
    });
    builder.addCase(fetchGetFulfillmentOrder.rejected, (state, action) => {
      state.loading = false;
      state.getFulfillment = undefined;
      state.error = action.error.message;
    });

    // Cases for Hold Fulfillment

    builder.addCase(fetchHoldFulfillment.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchHoldFulfillment.fulfilled, (state, action) => {
      state.loading = false;
      state.holdFulfillment = action.payload;
      state.error = "";
    });
    builder.addCase(fetchHoldFulfillment.rejected, (state, action) => {
      state.loading = false;
      state.holdFulfillment = undefined;
      state.error = action.error.message;
    });

    // Cases for Hold Fulfillment Reasono
    builder.addCase(fetchHoldFulfillmentReason.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchHoldFulfillmentReason.fulfilled, (state, action) => {
      state.loading = false;
      state.holdFulfillmentReason = action.payload;
      state.error = "";
    });
    builder.addCase(fetchHoldFulfillmentReason.rejected, (state, action) => {
      state.loading = false;
      state.holdFulfillmentReason = undefined;
      state.error = action.error.message;
    });

    // Cases for Release Fulfillment
    builder.addCase(fetchReleaseFulfillment.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchReleaseFulfillment.fulfilled, (state, action) => {
      state.loading = false;
      state.releaseFulfillment = action.payload;
      state.error = "";
    });
    builder.addCase(fetchReleaseFulfillment.rejected, (state, action) => {
      state.loading = false;
      state.releaseFulfillment = undefined;
      state.error = action.error.message;
    });

    // Cases for Calculate Refund Total Price
    builder.addCase(fetchCalculateRefundTotalPrice.pending, (state) => {
      state.calculateRefundTotalPrice.loading = true;
    });
    builder.addCase(
      fetchCalculateRefundTotalPrice.fulfilled,
      (state, action) => {
        state.calculateRefundTotalPrice.loading = false;
        state.calculateRefundTotalPrice.calculatedRefundTotalPrice =
          action.payload;
        state.calculateRefundTotalPrice.error = "";
      }
    );
    builder.addCase(
      fetchCalculateRefundTotalPrice.rejected,
      (state, action) => {
        pushTheToast({
          type: "danger",
          text: "Failed to calculate total refund amount !!!",
          position: "top-right",
        });
        state.calculateRefundTotalPrice.loading = false;
        state.calculateRefundTotalPrice.calculatedRefundTotalPrice = undefined;
        state.calculateRefundTotalPrice.error = action.error.message;
      }
    );
    // Cases for Create Refund
    builder.addCase(fetchCreateRefund.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchCreateRefund.fulfilled, (state, action) => {
      state.loading = false;
      state.createRefund = action.payload;
      state.error = "";
    });
    builder.addCase(fetchCreateRefund.rejected, (state, action) => {
      state.loading = false;
      state.createRefund = undefined;
      state.error = action.error.message;
    });

    // Cases for RefundModel
    builder.addCase(fetchRefundModal.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchRefundModal.fulfilled, (state, action) => {
      state.loading = false;
      state.refundModel = action.payload;
      state.error = "";
    });
    builder.addCase(fetchRefundModal.rejected, (state, action) => {
      state.loading = false;
      state.refundModel = undefined;
      state.error = action.error.message;
    });

    // Cases for add or remove tags
    builder.addCase(addOrUpdateOrderTagsThunk.pending, (state) => {
      state.updateOrder.tagAddOrUpdateAjaxStatus = "pending";
    });
    builder.addCase(addOrUpdateOrderTagsThunk.fulfilled, (state, action) => {
      state.updateOrder.tagAddOrUpdateAjaxStatus = "fulfilled";
      // console.log("addOrUpdateOrderTagsThunk payload::: ", JSON.stringify(action.payload));
      if (state.getOrderViewModel && action.payload.tags) {
        state.getOrderViewModel.tags = action.payload.tags;
      }
    });
    builder.addCase(addOrUpdateOrderTagsThunk.rejected, (state, action) => {
      state.updateOrder.tagAddOrUpdateAjaxStatus = "rejected";
    });

    // Cases for updating order shipping address
    builder.addCase(updateOrderShippingAddressThunk.pending, (state) => {
      state.updateOrder.orderShippingAddressUpdateAjaxStatus = "pending";
    });
    builder.addCase(
      updateOrderShippingAddressThunk.fulfilled,
      (state, action) => {
        state.updateOrder.orderShippingAddressUpdateAjaxStatus = "fulfilled";
        // console.log("updateOrderShippingAddressThunk payload::: ", JSON.stringify(action.payload));
        /*if (state.getOrderViewModel && action.payload.shippingAddress) {
          state.getOrderViewModel.shippingAddress = {
            id: state.getOrderViewModel?.shippingAddress?.id,
            name: state.getOrderViewModel?.shippingAddress?.name,
            firstName: action.payload.shippingAddress.firstName,
            lastName: action.payload.shippingAddress.lastName,
            address1: action.payload.shippingAddress.address1,
            address2: action.payload.shippingAddress.address2,
            company: action.payload.shippingAddress.company,
            city: action.payload.shippingAddress.city,
            country: action.payload.shippingAddress.country,
            countryCodeV2: action.payload.shippingAddress.countryCode,
            province: action.payload.shippingAddress.province,
            provinceCode: action.payload.shippingAddress.provinceCode,
            phone: action.payload.shippingAddress.phone,
            zip: action.payload.shippingAddress.zip,
          };*/

          pushTheToast({
            type: "success",
            text: "Shipping address updated, will reflect soon  !!!",
            position: "top-right",
          });
        // }
      }
    );
    builder.addCase(
      updateOrderShippingAddressThunk.rejected,
      (state, action) => {
        pushTheToast({
          type: "danger",
          text: "Failed to update shipping address !!!",
          position: "top-right",
        });
        state.updateOrder.orderShippingAddressUpdateAjaxStatus = "rejected";
      }
    );

    // Cases for updating contact information
    builder.addCase(updateContactDetailsThunk.pending, (state) => {
      state.updateOrder.contactDetailsUpdateAjaxStatus = "pending";
    });
    builder.addCase(updateContactDetailsThunk.fulfilled, (state, action) => {
      state.updateOrder.contactDetailsUpdateAjaxStatus = "fulfilled";
      pushTheToast({
        type: "success",
        text: "Contact information updated, will reflect soon !!!",
        position: "top-right",
      });
    });
    builder.addCase(updateContactDetailsThunk.rejected, (state, action) => {
      pushTheToast({
        type: "danger",
        text: "Failed to update contact information !!!",
        position: "top-right",
      });
      state.updateOrder.contactDetailsUpdateAjaxStatus = "rejected";
    });

    // Cases for updating customer order notes
    builder.addCase(updateCustomerOrderNotesThunk.pending, (state) => {
      state.updateOrder.customerOrderNotesUpdateAjaxStatus = "pending";
    });
    builder.addCase(updateCustomerOrderNotesThunk.fulfilled, (state, action) => {
      state.updateOrder.customerOrderNotesUpdateAjaxStatus = "fulfilled";
      pushTheToast({
        type: "success",
        text: "Notes saved, will reflect soon !!!",
        position: "top-right",
      });
    });
    builder.addCase(updateCustomerOrderNotesThunk.rejected, (state, action) => {
      pushTheToast({
        type: "danger",
        text: "Failed to update notes !!!",
        position: "top-right",
      });
      state.updateOrder.customerOrderNotesUpdateAjaxStatus = "rejected";
    });

    // Cases for fetching all country lists
    builder.addCase(fetchAllCountryList.pending, (state) => {
      state.allCountryList.fetchAllCountryListAjaxStatus = "pending";
    });
    builder.addCase(fetchAllCountryList.fulfilled, (state, action) => {
      // console.log("fetchAllCountryList slice action payload::: ", JSON.stringify(action.payload));
      state.allCountryList.countryList = action.payload.countryList;
      state.allCountryList.countryWithIdList = action.payload.countryWithIdList;
      state.allCountryList.fetchAllCountryListAjaxStatus = "fulfilled";
    });
    builder.addCase(fetchAllCountryList.rejected, (state, action) => {
      state.allCountryList.fetchAllCountryListAjaxStatus = "rejected";
    });

    // get tracking data
    builder.addCase(fetchGetTrackingData.pending, (state) => {
      state.getTrackingData.loading = true;
      state.getTrackingData.trackingData = undefined;
      state.getTrackingData.trackingDataAjaxStatus = "pending";
    });
    builder.addCase(fetchGetTrackingData.fulfilled, (state, action) => {
      state.getTrackingData.loading = false;
      state.getTrackingData.trackingData = action.payload;
      state.getTrackingData.trackingDataAjaxStatus = "fulfilled";
    });
    builder.addCase(fetchGetTrackingData.rejected, (state, action) => {
      state.getTrackingData.loading = false;
      state.getTrackingData.trackingData = undefined;
      state.getTrackingData.trackingDataAjaxStatus = "rejected";
    });

    // update tracking data
    builder.addCase(updateTrackingThunk.pending, (state) => {
     state.updateTracking.updateTrackingAjaxStatus = "pending";
    });
    builder.addCase(updateTrackingThunk.fulfilled, (state, action) => {
      state.updateTracking.updateTrackingRes = action.payload;
      pushTheToast({
        type: "success",
        text: "Tracking details updated, will reflect soon !!!",
        position: "top-right",
      });
      state.updateTracking.updateTrackingAjaxStatus = "fulfilled";
    });
    builder.addCase(updateTrackingThunk.rejected, (state, action) => {
      state.updateTracking.updateTrackingRes = undefined;
      pushTheToast({
        type: "danger",
        text: "Failed to update tracking details !!!",
        position: "top-right",
      });
      state.updateTracking.updateTrackingAjaxStatus = "rejected";
    });

     // for getShopifyCustomerDataThunk, for fetching a single customer data
  builder.addCase(getShopifyCustomerDataThunk.pending, (state, action) => {
    state.shopifySingleCustomerData.getShopifyCustomerDataAjaxStatus = "pending";
  });
  builder.addCase(getShopifyCustomerDataThunk.fulfilled, (state, action) => {
    state.shopifySingleCustomerData.customerData = action.payload;
    state.shopifySingleCustomerData.getShopifyCustomerDataAjaxStatus = "fulfilled";
  });
  builder.addCase(getShopifyCustomerDataThunk.rejected, (state, action) => {
    state.shopifySingleCustomerData.customerData = undefined;
    state.shopifySingleCustomerData.getShopifyCustomerDataAjaxStatus = "rejected";
  })
  },

});

export const {
  resetShopifyCustomerData,
  resetGetFulfillmentData,
  resetRefundModalData
} = orderSlice.actions;

export default orderSlice.reducer;
