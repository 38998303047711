import styles from "./DraftSidebar.module.scss";
import InfiniteScroll from "src/components/InfiniteScrollBothSide";
import useScheduleModa from "../CustomHooks/useScheduleModal";
import useDraftSidebar from "./useDraftSidebar";

type Props = {
  selectedTemplateId: string | null;
  setSelectedTemplateId: (value: string | null) => void;
  handleInfiniteScroll: () => void;
  hasNextPage?: boolean;
  loader?: JSX.Element;
  errorMessage?: JSX.Element;
};

const DraftSidebar = ({
  selectedTemplateId,
  setSelectedTemplateId,
  handleInfiniteScroll,
  hasNextPage,
  loader,
  errorMessage,
}: Props) => {
  const {
    templates,
    addNewTemplate,
    activeStyles,
    selecteTemplate,
    newTemplateNames,
  } = useDraftSidebar({
    selectedTemplateId,
    setSelectedTemplateId,
  });

  if (!templates) {
    return null;
  }

  return (
    <div className={` ${styles.sidebarWrapper}`}>
      {/* <p className={`mb-0 ${styles.templateHead}`}>Template Category :</p> */}
      <div className="w-100">
        <span
          className={`${styles.newDraft}`}
          role="button"
          onClick={addNewTemplate}
        >
          Draft new
        </span>
        <InfiniteScroll
          className="overflow-auto"
          style={{ height: "calc(100vh - 308px)" }}
          loadMoreFromBottom={handleInfiniteScroll}
          hasMoreBottom={hasNextPage}
        >
          {loader ? (
            loader
          ) : errorMessage ? (
            errorMessage
          ) : (
            <>
              {newTemplateNames.map((template) => {
                return (
                  <span
                    className={`ms-2 ${styles.categoryBtn} ${
                      activeStyles && template.id == selectedTemplateId
                        ? styles.activeCategory
                        : ""
                    }`}
                    key={template.id}
                    role="button"
                    onClick={() => {
                      selecteTemplate(template.id);
                    }}
                  >
                    <div
                      className={`${styles.bar} ${
                        activeStyles && template.id === selectedTemplateId
                          ? styles.activeBar
                          : ""
                      }`}
                    ></div>
                    <span className={`ms-1 ${styles.iconBox}`}>
                      <i className="fa-solid fa-envelopes-bulk"></i>
                    </span>
                    <span className={`ms-2 ${styles.temName}`}>
                      {" "}
                      {template.name}
                    </span>
                  </span>
                );
              })}
            </>
          )}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default DraftSidebar;
