import { Dropdown } from "react-bootstrap";
import { ConditionTypes } from "src/store/slices/initiateReturn/Helpers/returnExchange.helpers";
import { ReturnReason } from "src/store/slices/initiateReturn/step14Configuration.slice";
import { extractObjectFromEvent } from "../VariableSelect";
import styles from "./OperatorSelect.module.scss";

function OperatorSelect(props: {
  id: string;
  condition: ConditionTypes | ReturnReason;
  updateConditon: Function;
  pushCurrentHash: Function;
  operatorNames: any;
  selectedOperator: string | string[] | undefined;
}) {
  return (
    <Dropdown>
      {/* I have replaced the Form Select with Bootstrap Toggle because in Form Select we are using option tag and option is not customizable */}
      <Dropdown.Toggle
        className={`ms-0 ms-lg-2 my-2 my-lg-0 ${styles.operatorSelect} `}
      >
        {/* Showing first value from array of variables names when selected variable is "" */}
        {props.selectedOperator !== ""
          ? props.selectedOperator
          : props.operatorNames[0]}
      </Dropdown.Toggle>
      {/* Rendering Options  */}
      <Dropdown.Menu className={`${styles.mainSelectMenu} mt-1`}>
        {props.operatorNames.map((operator: any) => {
          return (
            <Dropdown.Item
              key={operator}
              style={{
                maxWidth: "11rem",
                // minWidth: "10rem",
                maxHeight: "2.5rem",
              }}
              className={`${styles.item}`}
              value={operator}
              onClick={(e) => {
                const event = extractObjectFromEvent(e); // This function is used to extract an object from an event.
                props.updateConditon(props.id, event, props.condition); // This function is used to update the condition.
                props.pushCurrentHash(props.id); // This function is used to push the current hash.
              }}
            >
              {operator}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default OperatorSelect;
