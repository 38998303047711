import { useCallback, useState } from "react";
import { Spinner } from "react-bootstrap";
import Loader from "src/components/Loader";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import {
  getCreateDispatchPayload,
  getUpdateDispatchPayload,
  validateDispatchRuleSteps,
} from "src/routes/BotSettings/Children/Automation/helpers/DispatchModal.helpers";
import { EAutomationType } from "src/routes/BotSettings/Children/AutomationList/AutomationList";
import { createDispatchRuleConfigService } from "src/services/Automation/DispatchRulesConfigModal/createDispatchRuleConfig.service";
import { updateDispatchRuleConfigService } from "src/services/Automation/DispatchRulesConfigModal/updateDispatchRuleConfig.service";
import { DispatchModalType } from "../../DispatchModal";
import DispatchRulesHeader from "./Children/DispatchRulesHeader/DispatchRulesHeader";
import DuplicateRuleModal from "./Children/DuplicateRuleModal/DuplicateRuleModal";
import DispatchRuleActiveComponent from "./DispatchRuleActiveComponent/DispatchRuleActiveComponent";
import styles from "./DispatchSection.module.scss";
import { dispatchSteps, DispatchStepsType } from "./DispatchStepsConfig";
import {
  useDispatchSectionContext,
  useDispatchSectionProvider,
} from "./Hooks/useDispatchSectionContext";

const DispatchComponent = ({
  onHide,
  onEdit,
  stepsList,
}: {
  onHide: (configId: string | null) => void;
  onEdit: () => void;
  stepsList: DispatchStepsType[];
}) => {
  const [loadingSpinner, setLoadingSpinner] = useState<"prev" | "next" | null>(
    null,
  );
  const [createdConfigId, setCreatedConfigId] = useState<string | null>(null);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);

  const {
    activeStep,
    automationType,
    setDispatchType,
    showUserSegment,
    setActiveStep,
    updateState,
    modalType,
    setShowErrors,
    setCompletedSteps,
    updateData,
    state,
    isLoading,
    originalData,
  } = useDispatchSectionContext();

  const onGoToAllRules = useCallback(() => {
    onHide(createdConfigId);
  }, [createdConfigId, onHide]);

  const updateActiveStep = useCallback(
    (isNext: boolean) => {
      setActiveStep((prev) => {
        if (!isNext && prev === 0) {
          onGoToAllRules();
          return prev;
        }

        if (isNext && prev === stepsList.length - 1) {
          return prev;
        }

        const step = stepsList[prev];

        const userSegmentIndex = isNext
          ? step?.skipUserSegmentNextIndex
          : step?.skipUserSegmentPrevIndex;

        if (showUserSegment === false && userSegmentIndex !== undefined) {
          return userSegmentIndex;
        }

        const skipIssueDetailIndex = isNext
          ? step?.skipIssueDetailsNextIndex
          : step?.skipIssueDetailsPrevIndex;

        if (
          state.isAiReadIssueDescriptionForDispatch === false &&
          skipIssueDetailIndex !== undefined
        ) {
          return skipIssueDetailIndex;
        }

        return isNext ? prev + 1 : prev - 1;
      });
    },
    [
      onGoToAllRules,
      setActiveStep,
      showUserSegment,
      state.isAiReadIssueDescriptionForDispatch,
      stepsList,
    ],
  );

  const createDispatchRule = useCallback(async () => {
    try {
      const payload = getCreateDispatchPayload({
        state,
        automationType,
        showUserSegment,
      });

      setLoadingSpinner("next");

      const response = await createDispatchRuleConfigService({
        payload,
        type: automationType,
      });

      if (response.configurationId) {
        setCreatedConfigId(response.configurationId);
        setDispatchType({
          configurationId: response.configurationId,
          type: "edit",
        });
        updateState({
          configurationId: response.configurationId,
        });
        setActiveStep(stepsList.length - 1);
      } else if (response.status && response.message) {
        if (response.status === "duplicate_dispatched_conditions") {
          setShowDuplicateModal(true);
        } else {
          pushTheToast({
            position: "top-right",
            text: response.message,
            type: "danger",
          });
        }
      }
    } catch (err) {
      const error = err as Error;
      pushTheToast({
        position: "top-right",
        text: error.message ?? "Something Unexpected Occured!",
        type: "danger",
      });
    } finally {
      setLoadingSpinner(null);
    }
  }, [
    automationType,
    setActiveStep,
    setDispatchType,
    showUserSegment,
    state,
    stepsList.length,
    updateState,
  ]);

  const updateDispatchRule = useCallback(
    async (isNext = true) => {
      const step = stepsList[activeStep];

      if (!step) {
        return;
      }

      const payload = getUpdateDispatchPayload({
        automationType,
        originalData,
        state,
        showUserSegment,
        stepType: step.key,
      });

      if (!payload) {
        updateActiveStep(isNext);
        return;
      }

      try {
        // Initialize loader
        setLoadingSpinner(isNext ? "next" : "prev");

        // Update the dispatch rules
        const res = await updateDispatchRuleConfigService({
          payload,
          type: automationType,
        });

        // Update the data with response the dispatch rule after update
        if (res.data) {
          updateData(res.data);
        }

        // Call the on edit handler for parent component
        onEdit();

        // Update the active step
        updateActiveStep(isNext);
      } catch (err) {
        const error = err as Error;
        pushTheToast({
          position: "top-right",
          text: error.message ?? "Something Unexpected Occured!",
          type: "danger",
        });
      } finally {
        setLoadingSpinner(null);
      }
    },
    [
      activeStep,
      automationType,
      onEdit,
      originalData,
      showUserSegment,
      state,
      stepsList,
      updateActiveStep,
      updateData,
    ],
  );

  const handleRouting = useCallback(
    (isNext = true) => {
      const step = stepsList[activeStep];

      if (!step) {
        return;
      }

      // Check validity
      const isValid = validateDispatchRuleSteps({
        stepType: step.key,
        state,
        automationType,
        validateUserSegments: showUserSegment,
      });

      // Update completed steps
      setCompletedSteps((prev) => {
        const hasKey = prev.includes(step.key);
        if (isValid) {
          if (!hasKey) {
            prev.push(step.key);
          }
        } else {
          if (hasKey) {
            prev = prev.filter((val) => val !== step.key);
          }
        }

        return [...prev];
      });

      // Update errors if not valid
      if (!isValid) {
        setShowErrors(true);
        return;
      }

      // Call update dispatch rule for edit
      if (modalType.type === "edit") {
        updateDispatchRule(isNext);
        return;
      }

      // If it's last configuration step as last step is success
      if (activeStep === stepsList.length - 2) {
        createDispatchRule();
        return;
      }

      // Next Click on create handler
      setActiveStep((prev) => {
        const step = stepsList[prev];

        if (
          showUserSegment === false &&
          step?.skipUserSegmentNextIndex !== undefined
        ) {
          return step.skipUserSegmentNextIndex;
        }

        if (
          state.isAiReadIssueDescriptionForDispatch === false &&
          step?.skipIssueDetailsNextIndex !== undefined
        ) {
          return step.skipIssueDetailsNextIndex;
        }

        if (prev === stepsList.length - 1) {
          return prev;
        }

        return prev + 1;
      });
    },
    [
      activeStep,
      automationType,
      createDispatchRule,
      modalType.type,
      setActiveStep,
      setCompletedSteps,
      setShowErrors,
      showUserSegment,
      state,
      stepsList,
      updateDispatchRule,
    ],
  );

  // Show the loader for get by id
  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <DispatchRulesHeader steps={stepsList} />

      {/* Navigation Buttons */}
      <div className="text-center mt-4">
        <button
          className={`me-2 ${styles.prevBtn}`}
          id="modal_prev_btn"
          disabled={loadingSpinner !== null}
          onClick={() => {
            if (!state.configurationId) {
              setActiveStep((prev) => {
                if (prev === 0) {
                  onGoToAllRules();
                  return prev;
                }

                const step = stepsList[prev];

                if (
                  showUserSegment === false &&
                  step?.skipUserSegmentPrevIndex !== undefined
                ) {
                  return step.skipUserSegmentPrevIndex;
                }

                if (
                  state.isAiReadIssueDescriptionForDispatch === false &&
                  step?.skipIssueDetailsPrevIndex !== undefined
                ) {
                  return step.skipIssueDetailsPrevIndex;
                }

                return prev - 1;
              });
            } else {
              handleRouting(false);
            }
          }}
        >
          <span className="pe-1">
            {loadingSpinner === "prev" ? (
              <Spinner
                size="sm"
                animation="border"
              />
            ) : (
              <i className="fa-solid fa-arrow-left"></i>
            )}
          </span>
          <span>Previous</span>
        </button>
        {activeStep < stepsList.length - 1 && (
          <button
            className={`${styles.prevBtn}`}
            id="modal_next_btn"
            onClick={() => handleRouting(true)}
            disabled={loadingSpinner !== null}
          >
            <span>Next</span>
            <span className="ps-1">
              {loadingSpinner === "next" ? (
                <Spinner
                  size="sm"
                  animation="border"
                />
              ) : (
                <i className="fa-solid fa-arrow-right"></i>
              )}
            </span>
          </button>
        )}
      </div>

      {/* Steps */}
      <div className={`mt-3  ${styles.ruleBox}`}>
        <DispatchRuleActiveComponent
          stepsList={stepsList}
          index={activeStep}
          isDisabled={loadingSpinner !== null}
          onHide={onGoToAllRules}
        />
        <DuplicateRuleModal
          showModal={showDuplicateModal}
          setShowModal={setShowDuplicateModal}
        />
      </div>
    </div>
  );
};

const DispatchSection = ({
  onHide,
  onEdit,
  dispatchType,
  integrationId,
  setDispatchType,
  automationType,
}: {
  onHide: (configId: string | null) => void;
  onEdit: () => void;
  setDispatchType: React.Dispatch<
    React.SetStateAction<DispatchModalType | null>
  >;
  dispatchType: DispatchModalType;
  integrationId: string;
  automationType: EAutomationType;
}) => {
  const { DispatchSectionProvider, ...contextValues } =
    useDispatchSectionProvider(
      dispatchType,
      integrationId,
      automationType,
      setDispatchType,
    );

  // If it's not an allowed automation return no JSX
  if (
    automationType !== EAutomationType.BUG_MANAGEMENT &&
    automationType !== EAutomationType.ISSUE_DISPATCH
  ) {
    return <></>;
  }

  /**
   * Gets the current automations steps list
   */
  const stepsList = dispatchSteps[automationType];

  if (!stepsList || stepsList.length === 0) {
    return <></>;
  }

  return (
    <DispatchSectionProvider value={contextValues}>
      <DispatchComponent
        onHide={onHide}
        onEdit={onEdit}
        stepsList={stepsList}
      />
    </DispatchSectionProvider>
  );
};

export default DispatchSection;
