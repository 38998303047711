import useSaveStepsAndQuestions from "src/features/ReturnAutoWorkFlow/hooks/useSaveStepsAndQuestions";
import ComboOptions from "../genericAnswers/ComboOptions/ComboOptions";
import {
  StepQComboValue,
  StepQuestion,
  StringListValueType,
} from "src/features/ReturnAutoWorkFlow/ReturnAutoWorkFlow.types";
import SelectButtons from "../genericAnswers/SelectButtons/SelectButtons";
import CheckOptions from "../genericAnswers/CheckOptions/CheckOptions";
import RadioOptions from "../genericAnswers/RadioOptions/RadioOptions";
import StringList from "../genericAnswers/StringList/StringList";
import TextBox from "../genericAnswers/TextBox/TextBox";
import { useCallback } from "react";
import ConfigButton from "../ConfigButton/ConfigButton";
import { Spinner } from "react-bootstrap";
import NonValue from "../genericAnswers/NonValue/NonValue";
import TextArea from "../genericAnswers/TextArea/TextArea";
import styles from "../../../StepDetails/StepDetails.module.scss";
import MoveToNext from "../MoveToNext/MoveToNext";

function GenericQuestion({
  stepId,
  questionId,
  updateReturnPreview,
}: {
  stepId: string;
  questionId: string;
  updateReturnPreview: ({
    stepQuestion,
  }: {
    stepQuestion: StepQuestion | null;
  }) => void;
}) {
  const {
    integrationId,
    dispatch,
    questionNumber,
    stepQuestion,
    questionIsValid,
    showExtraValue,
    questionContainsKeyInputs,
    modalConfigDetails,
    savingStepAndQuestionData,
    showErrors,
    questionHaveUpdates,
    saveStepAndQuestionData,
  } = useSaveStepsAndQuestions({
    stepId,
    questionId,
  });

  const handleComboChange = useCallback(
    function (value: StepQComboValue) {
      saveStepAndQuestionData({
        questionDataValue: {
          comboValue: value,
        },
      });
      updateReturnPreview({
        stepQuestion: { ...stepQuestion, comboValue: value },
      });
    },
    [saveStepAndQuestionData, stepQuestion, questionId, stepId],
  );
  const handleValueChange = useCallback(
    function (value: StepQuestion["value"]) {
      if (
        stepQuestion.isEditable === false &&
        (stepQuestion.valueType === "singleSelectButtons" ||
          stepQuestion.valueType === "multiSelectButtons")
      ) {
        dispatch("setState", { activeStaticModal: "noEditErrorQuestionModal" });
      } else {
        saveStepAndQuestionData({ questionDataValue: { value } });
      }
      updateReturnPreview({ stepQuestion: { ...stepQuestion, value: value } });
    },
    [dispatch, saveStepAndQuestionData, stepQuestion, questionId, stepId],
  );
  const handleNonValueChange = useCallback(
    function (value: string) {
      saveStepAndQuestionData({ questionDataValue: { extraValue: value } });
      updateReturnPreview({ stepQuestion });
    },
    [saveStepAndQuestionData, stepQuestion, questionId, stepId],
  );
  const handleStringListChange = useCallback(
    function (value: Array<StringListValueType>) {
      saveStepAndQuestionData({
        questionDataValue: { stringListValue: value },
      });
      updateReturnPreview({ stepQuestion });
    },
    [saveStepAndQuestionData, stepQuestion, questionId, stepId],
  );

  return (
    <div
      className={`pb-3 ${styles.query} ${
        questionIsValid ? styles.activeLine : ""
      } ${showErrors && !questionIsValid ? styles.errorLine : ""}`}
    >
      <div className={`mb-2 mt-2 ${styles.questionsHead}`}>
        Q{questionNumber}. {stepQuestion.questionTitle}
      </div>
      {stepQuestion.valueType === "combo" && stepQuestion.comboOptions ? (
        <ComboOptions
          comboOptions={stepQuestion.comboOptions}
          value={stepQuestion.comboValue ?? {}}
          onChange={handleComboChange}
          showErrors={showErrors}
          integrationId={integrationId}
          stepId={stepId}
          questionId={questionId}
          questionContainsKeyInputs={questionContainsKeyInputs}
        />
      ) : (
        <></>
      )}
      {(stepQuestion.valueType === "multiSelectButtons" ||
        stepQuestion.valueType === "singleSelectButtons") &&
      stepQuestion.multiSelectButtonOptions ? (
        <SelectButtons
          options={stepQuestion.multiSelectButtonOptions}
          value={(stepQuestion.value ?? null) as string | string[] | null}
          onChange={handleValueChange}
          isMulti={stepQuestion.valueType === "multiSelectButtons"}
          showLink={
            stepQuestion.showLinkToNavigateToIntegrationSettings ? true : false
          }
        />
      ) : (
        <></>
      )}
      {stepQuestion.valueType === "multiCheck" && stepQuestion.options ? (
        <CheckOptions
          options={stepQuestion.options}
          value={(stepQuestion.value ?? []) as Array<string>}
          onChange={handleValueChange}
          showErrors={showErrors}
        />
      ) : (
        <></>
      )}
      {showExtraValue ? (
        <NonValue
          value={stepQuestion.extraValue ?? ""}
          onChange={handleNonValueChange}
          showErrors={showErrors}
          heading={stepQuestion.extraTextQuestion?.heading ?? ""}
          subHeading={stepQuestion.extraTextQuestion?.label ?? ""}
          placeholder={
            stepQuestion.extraTextQuestion?.placeholder
              ? stepQuestion.extraTextQuestion?.placeholder
              : undefined
          }
        />
      ) : (
        <></>
      )}
      {stepQuestion.valueType === "singleCheck" && stepQuestion.options ? (
        <RadioOptions
          options={stepQuestion.options}
          value={(stepQuestion.value ?? "") as string}
          onChange={handleValueChange}
          showErrors={showErrors}
        />
      ) : (
        <></>
      )}
      {stepQuestion.valueType === "stringList" ? (
        <StringList
          value={stepQuestion.stringListValue ?? []}
          onChange={handleStringListChange}
          showErrors={showErrors}
          stringListAddButtonLabel={
            stepQuestion.stringListAddButtonLabel
              ? stepQuestion.stringListAddButtonLabel
              : undefined
          }
          placeholder={
            stepQuestion.placeholder ? stepQuestion.placeholder : undefined
          }
        />
      ) : (
        <></>
      )}
      {stepQuestion.valueType === "html" ? (
        <TextBox
          value={(stepQuestion.value ?? "") as string}
          onChange={handleValueChange}
          showErrors={showErrors}
          editorVariableKey={stepQuestion.editorVariablesKey ?? null}
          allowAddVariable={
            stepQuestion.allowAddEditorVariablesKey ? true : false
          }
          placeholder={
            stepQuestion.placeholder ? stepQuestion.placeholder : undefined
          }
        />
      ) : (
        <></>
      )}
      {stepQuestion.valueType === "string" ? (
        <TextArea
          value={(stepQuestion.value ?? "") as string}
          onChange={handleValueChange}
          showErrors={showErrors}
          placeholder={
            stepQuestion.placeholder ? stepQuestion.placeholder : undefined
          }
        />
      ) : (
        <></>
      )}
      {savingStepAndQuestionData === "question" ? (
        <div>
          <Spinner size="sm" />
        </div>
      ) : (
        ""
      )}
      {/* ConfigButton contains move to next as well. */}
      {modalConfigDetails ? (
        <ConfigButton
          stepId={stepId}
          questionId={stepQuestion.questionId}
        />
      ) : (
          stepQuestion.showMoveToNext === "save"
            ? questionHaveUpdates
            : stepQuestion.showMoveToNext
        ) ? (
        /** If config button is not available to render, directly render move to next. */
        <div className="ms-0 my-2">
          <MoveToNext
            stepId={stepId}
            questionId={questionId}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default GenericQuestion;
