import { useEffect, useState } from "react";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import styles from "./RuleNameModal.module.scss";
// Props for the Modal
interface Props {
  ruleName: string;
  isNewRule: boolean;
  setRuleName: any;
  onHide: any;
  handleSubmit: any;
  setShowSaveChanges: any;
  handleRuleNameCancel: any;
}
// To hide the modal
const RuleNameModal = ({ ruleName,isNewRule, setRuleName, handleSubmit, onHide ,setShowSaveChanges,handleRuleNameCancel}: Props) => {

  const handleOnSubmit = ()=>{
    if(ruleName === ""){
      pushTheToast({
        type: "warning",
        text: "Rule Name cannot be empty!",
        position: "top-right"
      });
    }else{
      if(isNewRule === true){
          handleSubmit();
      }
      onHide();
    }
    setShowSaveChanges(true);
  }

  const handleCancel = ()=>{

    if(isNewRule === false){
      handleRuleNameCancel();
    }
  };

  return (
    <>
      <div className={`d-flex justify-content-between mb-2`}>
        <span className={`${styles.heading}`}>Routing rule name</span>
        <span className={`${styles.closeBtn}`} onClick={onHide}>
          <i className="fa-solid fa-xmark"></i>
        </span>
      </div>
      <input
        className={`${styles.ruleInput}`}
        type="text"
        value={ruleName}
        placeholder="Type here ..."
        onChange={(e)=>setRuleName(e.target.value)}
      />
      <div className="my-2 d-flex justify-content-end">
        <button className={`${styles.cancelBtn}`} onClick={(e)=>{handleCancel();onHide()}}>Cancel</button>
        <button className={`${styles.saveBtn}`} onClick={handleOnSubmit}>Save</button>
      </div>
    </>
  );
};
export default RuleNameModal;
