import { EAutomationType } from "src/routes/BotSettings/Children/AutomationList/AutomationList";

/**
 * Enumeration for the different dispatch steps.
 * These are the keys used to map steps to their corresponding components.
 */
export enum EDispatchSteps {
  RuleName = "ruleName",
  Conditions = "conditions",
  IssueDetails = "issueDetails",
  IssueResponse = "issueResponse",
  IssueCategories = "issueCategories",
  DispatchTo = "dispatchTo",
  Success = "success",
}

/**
 * Configuration object for each dispatch step.
 *
 * @template T - The step key.
 * @template X - The props type for the component.
 */
export interface DispatchStepsType {
  /**
   * Key representing the step in the dispatch flow.
   */
  key: EDispatchSteps;

  /**
   * Name of the step, used for display purposes.
   */
  name: string;

  /**
   * Skip the step when user segment is not selected. This represents the index to move to on next
   */
  skipUserSegmentNextIndex?: number;

  /**
   * Skip the step when user segment is not selected. This represents the index to move to on prev
   */
  skipUserSegmentPrevIndex?: number;

  /**
   * Skip the related issue details step. This represents the index to move to on next
   */
  skipIssueDetailsNextIndex?: number;

  /**
   * Skip the related issue details step. This represents the index to move to on previous
   */
  skipIssueDetailsPrevIndex?: number;
}

type AllowedAutomationType =
  | EAutomationType.BUG_MANAGEMENT
  | EAutomationType.ISSUE_DISPATCH;

/**
 * Configuration mapping for different automation types.
 * Each type maps to a list of steps, with associated components and props.
 */
export const dispatchSteps: Record<
  AllowedAutomationType,
  Array<DispatchStepsType>
> = {
  [EAutomationType.ISSUE_DISPATCH]: [
    {
      key: EDispatchSteps.RuleName,
      name: "Rule name",
      skipUserSegmentNextIndex: 2,
    },
    {
      key: EDispatchSteps.Conditions,
      name: "Dispatching conditions",
    },
    {
      key: EDispatchSteps.IssueDetails,
      name: "Issue details",
      skipIssueDetailsNextIndex: 5,
      skipUserSegmentPrevIndex: 0,
    },
    {
      key: EDispatchSteps.IssueResponse,
      name: "Issue responses",
    },
    {
      key: EDispatchSteps.IssueCategories,
      name: "Categorize issues",
    },
    {
      key: EDispatchSteps.DispatchTo,
      name: "Dispatch to",
      skipIssueDetailsPrevIndex: 2,
    },
    {
      key: EDispatchSteps.Success,
      name: "Done!",
    },
  ],
  [EAutomationType.BUG_MANAGEMENT]: [
    {
      key: EDispatchSteps.RuleName,
      name: "Rule name",
    },
    {
      key: EDispatchSteps.Conditions,
      name: "Dispatching conditions",
    },
    {
      key: EDispatchSteps.IssueDetails,
      name: "Issue details",
    },
    {
      key: EDispatchSteps.DispatchTo,
      name: "Dispatch to",
    },
    {
      key: EDispatchSteps.Success,
      name: "Done!",
    },
  ],
};
