import React from "react";
import styles from "./ReadStatus.module.scss";
interface Props {
  read: boolean;
}
const ReadStatus = ({ read }: Props) => {
  return (
    <div>
      {read ? (
        <>
          <div className="d-flex align-items-center ms-2">
            <span className={`${styles.seenBox}`}>
              <i className="fa-solid fa-check"></i>
            </span>
            <span className="ps-1">Seen</span>
          </div>
        </>
      ) : (
        <div className="d-flex align-items-center ms-2">
          <span className={`${styles.unseenBox}`}>
            <i className="fa-solid fa-check"></i>
          </span>
          <span className="ps-1">Not seen yet</span>
        </div>
      )}
    </div>
  );
};

export default ReadStatus;
