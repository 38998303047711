import styles from "./ThemeBox.module.scss";
import helpLogo from "src/assets/images/helpCenter.png";
import useThemeBox from "./useThemeBox";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import Loader from "src/components/Loader";
import AxiosImg from "src/components/AxiosImg";
import themeDefaultBackground from "src/assets/images/themeDefaultBackground.png";
import { useMemo } from "react";
import saufterLogo from "src/assets/images/saufter-logo.svg";
import helplamaLogo from "src/assets/images/chat/bLogo.png";

const ThemeBox = () => {
  const {
    data,
    parentStyle,
    categoryStyle,
    isLoading,
    updateKBSettings,
    isError,
    isFetching,
  } = useThemeBox();

  const backgroundImage = useMemo(() => {
    if (data?.backgroundImage) {
      return data?.backgroundImage;
    } else if (data?.themeType === "night_in_the_oscars") {
      return themeDefaultBackground;
    }
    return "";
  }, [data?.backgroundImage, data?.themeType]);

  if (isError) {
    pushTheToast({
      position: "top-right",
      text: "Something went wrong",
      type: "danger",
    });

    return (
      <>
        <div className={`p-2 ${styles.themeWrapper}`}>
          <span className={`${styles.errorMsg}`}>Something Went Wrong</span>
        </div>
      </>
    );
  }

  return (
    <div
      className={`p-2 p-md-3 p-lg-4 ${styles.themeWrapper}`}
      style={{
        filter:
          isLoading || updateKBSettings.isLoading || isFetching
            ? "blur(3px)"
            : "",
        position: "relative",
      }}
    >
      {isLoading || updateKBSettings.isLoading || isFetching ? (
        <div
          style={{
            position: "absolute",
            zIndex: 1,
            top: "50%",
            left: "50%",
          }}
        >
          <div className="spinner-border text-secondary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : null}
      <div
        className={`pt-3 ${styles.previewBox}`}
        style={{
          backgroundColor: `${
            data?.themeType === "night_in_the_oscars" ? "#393939" : "#ffffff"
          }`,
        }}
      >
        <div className="px-3 d-flex align-items-center">
          <div className={`${styles.dots}`}></div>
          <div className={`${styles.dots} ${styles.red}`}></div>
          <div className={`${styles.dots} ${styles.yellow}`}></div>
        </div>
        <div
          className={`mt-2 text-center d-flex flex-column justify-content-center align-items-center ${styles.previewHeader}`}
          style={{
            backgroundColor: data?.backgroundColor ?? "#0b68bb",
            backgroundImage: `url(${backgroundImage})`, //added default background image
            backgroundSize: "cover",
          }}
        >
          <p className="mb-1">
            {data?.logoImage ? (
              <AxiosImg
                url={data?.logoImage}
                className={`${styles.helpLogo}`}
              />
            ) : (
              <img src={helpLogo} alt="" className={`${styles.helpLogo}`} />
            )}
            <span className={`ms-2 ${styles.acme}`} style={parentStyle}>
              {data?.storeName}
            </span>
          </p>
          <h4 className={`mb-1 ${styles.helpText}`} style={parentStyle}>
            {data?.heading}
          </h4>
          <p className={`${styles.previewName}`} style={parentStyle}>
            {data?.subheading}
          </p>
        </div>

        <div>
          <div className={`d-flex align-items-center ps-4 ${styles.searchBox}`}>
            <span className={`${styles.searchText}`} style={parentStyle}>
              <i className="fa-solid fa-magnifying-glass"></i>
            </span>
            <span className={`ps-2 ${styles.searchText}`}>
              {data?.placeholder}
            </span>
          </div>
        </div>
        <div className={`mt-3 ${styles.categoryBox}`}>
          <p
            className={`${styles.categoryText}`}
            style={{ ...parentStyle, ...categoryStyle }}
          >
            Categories
          </p>
          {data?.themeType === "night_in_the_oscars" ? (
            <>
              <div className="row">
                <div className="col-12 col-md-6 pe-1">
                  <div className={`${styles.category} bg-white`}></div>
                </div>
                <div className="col-12 col-md-6 ps-1">
                  <div className={`${styles.category}`}></div>
                </div>
                <div className="col-12 col-md-6 pe-1">
                  <div className={`${styles.category}`}></div>
                </div>
                <div className="col-12 col-md-6 ps-1">
                  <div className={`${styles.category}`}></div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={`${styles.categoryAlice}`}></div>
              <div className={`${styles.categoryAlice}`}></div>

              <div className={`${styles.categoryAlice}`}></div>
            </>
          )}
        </div>
        <div className={`text-center mt-4 mb-1 ${styles.footer}`}>
          {data?.helplamaBranding && (
            <p
              className={`mb-1 ${styles.brand} d-flex align-items-center justify-content-center`}
            >
              {/* Nov 29 hided the footer logo and showing the saufter logo */}
              <span
                className={`${
                  data?.themeType === "night_in_the_oscars"
                    ? styles.nightThemeFooter
                    : styles.alicethemeFooter
                }`}
              >
                Powered by
              </span>
              <div
                className={`mx-1 ${
                  data?.themeType === "night_in_the_oscars" &&
                  styles.nightThemeFooterImg
                }`}
              >
                <img
                  src={saufterLogo}
                  alt=""
                  className={`${styles.helpLogo}`}
                />
              </div>

              {/* {data?.footerLogo ? (
                <AxiosImg
                  url={data?.footerLogo}
                  className={`${styles.helpLogo}`}
                />
              ) : (
                <img src={helpLogo} alt="" className={`${styles.helpLogo}`} />
              )} */}
              {/*rendering the "Powered by" text from backend */}
              <span
                className={`${styles.invertCss} ${
                  data?.themeType === "night_in_the_oscars"
                    ? styles.nightThemeFooterLogo
                    : styles.aliceThemeFooterLogo
                }`}
              >
                {data?.helplamaBrandingText ?? "Saufter.io"}
              </span>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ThemeBox;
