import { useMutation } from "@tanstack/react-query";
import { useCallback } from "react";
import { updateUser } from "src/services/Settings/Users/updateUser";
interface Props {
  userId?: number | string;
}
/**
 * Custom React hook for managing chat acceptance status changes.
 * @param userId - The ID of the user for whom chat acceptance status is being updated.
 * @returns Object with loading, success, error flags, mutation function, and status update handler.
 */
function useChangeChatAcceptStatus({ userId }: Props) {
  // Use the useMutation hook to define the updateChatAcceptStatusMutation
  const updateChatAcceptStatusMutation = useMutation({
    mutationFn: updateUser, // The mutation function to be called
    mutationKey: ["updateChatAcceptStatus", userId], // Unique key for the mutation
  });

  // useCallback to memoize the handleStatusUpdate function
  const handleStatusUpdate = useCallback(
    (status: boolean) => {
      // Check if userId is provided before initiating the mutation
      if (userId) {
        // Call the mutate function to update chat acceptance status
        updateChatAcceptStatusMutation.mutate({
          userId: parseInt(userId + ""), // Convert userId to integer if it's a string
          chatAcceptStatus: status,
        });
      }
    },
    [userId]
  );

  // Extract loading, success, and error flags from the updateChatAcceptStatusMutation
  const isLoading = updateChatAcceptStatusMutation.isLoading;
  const isSuccess = updateChatAcceptStatusMutation.isSuccess;
  const isError = updateChatAcceptStatusMutation.isError;

  // Return an object with relevant values for the component using this hook
  return {
    isLoading,
    isSuccess,
    isError,
    updateChatAcceptStatusMutation,
    handleStatusUpdate,
  };
}

export default useChangeChatAcceptStatus;
