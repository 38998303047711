import styles from "./Home.module.scss";
import devider from "src/assets/images/devider.png";
import { NavLink } from "react-router-dom";
import Loader from "src/components/Loader";
import { homeLink } from "src/services/TicketService/linkGenerator";
import { useAppSelector } from "src/store/store";
import { ProgressBar } from "react-bootstrap";
import ToDoList from "./ToDoList/ToDoList";
import useHomePage from "./hooks/useHomePage";

/**
 * Progress Bar to show percentage of tickets closed
 */
const ClosedTicketsPercent = () => {
  // Get progress of closed tickets
  const progress = useAppSelector((state) => {
    // Get dropdown list from sidebar data
    const ddList =
      state.ticketSideBar.ticketBtnData[0]?.ticketBtnList[0]?.ddOuterList[1]
        .ddList;

    // Get total ticket count
    const totalTicketCount = ddList ? ddList[0]?.count ?? 0 : 0;
    // Get closed ticket count
    const closedTicketCount = ddList ? ddList[5]?.count ?? 0 : 0;

    // Return the percentage of tickets closed if we have total tickets
    return totalTicketCount !== 0
      ? (closedTicketCount / totalTicketCount) * 100
      : 0;
  });

  return (
    <div className="d-flex justify-content-end align-items-center w-50">
      <span className={`${styles.closeTicket} pe-2`}>
        {progress.toFixed(2)}% tickets closed
      </span>
      <div className="w-25 progressWrapper">
        <ProgressBar
          className="progressBar"
          now={progress}
        />
      </div>
    </div>
  );
};

const Home = () => {
  const {
    currentUserData,
    loading,
    showSocialMediaBlock,
    homePageData,
    setupData,
  } = useHomePage();

  return (
    <div className={`${styles.homePage}`}>
      <div className={`${styles.headBox}`}>
        <div className="d-flex align-items-center">
          <h1 className={`mb-0 saufter_h1 w-50`}>
            {currentUserData &&
              `Hi ${
                currentUserData.firstName.toUpperCase()[0]
              }${currentUserData.firstName.toLowerCase().slice(1)}!`}
          </h1>
          {homePageData && <ClosedTicketsPercent />}
        </div>

        <span className={`saufter_gray_h4 ${styles.saufterHeading}`}>
          Hope you're doing great
        </span>
      </div>
      {loading ? (
        <Loader />
      ) : homePageData ? (
        <>
          {setupData && <ToDoList setupData={setupData} />}
          <div className={setupData ? "mt-5" : ""}>
            <h1 className={`mb-0 saufter_h3 `}>Overview</h1>
            <span className={`saufter_medium_h4  ${styles.overviewSub}`}>
              View an overview of open tickets, social media requests, live chat
              revenue, missed live chat.
            </span>
            <div
              className={`${styles.overview}`}
              id="home_section"
            >
              <div className={`${styles.homeCard} ${styles.card1}`}>
                <h4 className={`${styles.cardHeader} saufter_h4`}>
                  Total Number of Open Tickets
                </h4>
                <div className={`d-flex align-items-center`}>
                  <h2 className={`${styles.numOfTick}`}>
                    {homePageData.totalOpenTickets}
                  </h2>
                  <NavLink
                    className={`${styles.viewLink}`}
                    to={homeLink()}
                    id="home_section__viewBtn"
                  >
                    View details
                  </NavLink>
                </div>
              </div>
              {showSocialMediaBlock ? (
                <div className={`${styles.homeCard} ${styles.card2}`}>
                  <h4 className={`${styles.cardHeader} saufter_h4`}>
                    Total Pending Social Media Requests
                  </h4>
                  <div
                    className={`d-flex align-items-center justify-content-between`}
                  >
                    {/** Messenger count */}
                    {homePageData.socialMediaRequestCount?.messenger !==
                    undefined ? (
                      <div className={`d-flex align-items-center`}>
                        <i
                          className={`${styles.messengerLogo} fa-brands fa-facebook-messenger`}
                        ></i>
                        <span className={`ms-2 ${styles.numOfTick}`}>
                          {homePageData.socialMediaRequestCount?.messenger}
                        </span>
                      </div>
                    ) : null}

                    {/** Divided line */}
                    {homePageData.socialMediaRequestCount?.instagram !==
                      undefined &&
                    homePageData.socialMediaRequestCount?.messenger !==
                      undefined ? (
                      <div>
                        <img
                          className={`${styles.devider}`}
                          src={devider}
                          alt={`msg`}
                        />
                      </div>
                    ) : null}

                    {/** Instagram count */}
                    {homePageData.socialMediaRequestCount?.instagram !==
                    undefined ? (
                      <div className={`d-flex align-items-center`}>
                        <i
                          className={`${styles.instaLogo} fa-brands fa-instagram`}
                        ></i>
                        <span className={`ms-2  ${styles.numOfTick}`}>{0}</span>
                      </div>
                    ) : null}
                    <div>
                      {/* <NavLink className={`${styles.viewLink2}`} to={homeLink()}>
                  View details
                </NavLink> */}
                    </div>
                  </div>
                </div>
              ) : null}

              <div className={`${styles.homeCard} ${styles.card3}`}>
                <h4 className={`${styles.cardHeader} saufter_h4`}>
                  Live chat Revenue in Past 24 Hours
                </h4>
                <div className={`d-flex align-items-center`}>
                  <h2 className={`${styles.numOfTick}`}>$0</h2>
                </div>
              </div>
              <div className={`${styles.homeCard} ${styles.card4}`}>
                <h4 className={`${styles.cardHeader} saufter_h4`}>
                  Missed Live Chat Requests Past 24 hrs
                </h4>
                <div className={`d-flex align-items-center`}>
                  <h2 className={`${styles.numOfTick}`}>0</h2>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      {/* {!loading && (
        <div className={`text-center`}>
          <img className={`${styles.heroImg}`} src={heroImg} alt={``} />
        </div>
      )} */}
    </div>
  );
};
export default Home;
