import {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  FileUploadHandler,
  getEmbeddedImageTemplate,
  getEmbeddedVideoTemplate,
  isFileAttachmentSupportedTinyMCE,
  isFileImgSupportedTinyMCE,
  OnFileDeleteRes,
  STATUS_UPLOAD,
  TinyMCEUploadFile,
  TINY_MCE_ATTACHEMENT_SUPPORTED_TYPES,
  TINY_MCE_UPLOAD_FILE_SIZE_LIMIT_MB,
  TINY_MCE_ATTACHEMENT_SUPPORTED_IMG_TYPES,
  TINY_MCE_ATTACHEMENT_SUPPORTED_VIDEO_TYPES,
} from "src/interfaces/TinyMCEContainer/ITinyMCEContainer";
import { getTypeFromFileName, selectFile } from "src/utils/utils";
import { v4 as uuidv4 } from "uuid";
import { Editor as TinyMceEditor } from "tinymce";
import { flushSync } from "react-dom";

interface Props {
  fileUploadHandler: ({
    files,
    onProgress,
    onFailed,
    onSuccess,
    areAttachmentsPublic,
  }: FileUploadHandler) => void;
  areAttachmentsPublic: boolean;
  onFileDelete: (file: TinyMCEUploadFile) => Promise<OnFileDeleteRes>;
  uploadFiles: TinyMCEUploadFile[];
  setUploadFiles: Function;
  inlineAttachmentOnly: boolean;
  setIsFileUploading: Function;
  isFileUploading: boolean;
  editorRef: React.RefObject<TinyMceEditor | null>;
  disableAttachment: boolean;
  allowedEmbbedTypes: Array<"image" | "video" | "gif">;
  compareOverAllAttachmentSize: boolean;
}

interface addFileToUploaded {
  file: File;
  uploadStatus: number;
  embedded: boolean;
  insertInEditor?: boolean;
}

//interface for the error warning modal props
interface FileWarningModalProp {
  show: boolean;
  type: string;
  fileName: string;
  errorText?: string; //prop to render error text dynamically
}

//contant to store allowed file size in bytes, later used in the code for overall file size checks
const ALLOWED_FILES_SIZE = TINY_MCE_UPLOAD_FILE_SIZE_LIMIT_MB * 1024 * 1024;
/**
 * custom hook to handle select, upload and delete attachments
 */
function useFileUpload({
  fileUploadHandler, //handler function which will be called to upload a attachment
  areAttachmentsPublic, //whether if it is a public or private attachment
  onFileDelete, //handler function which will be called on deleting of a attachment
  uploadFiles, //state variable which contains all the uploaded file object
  setUploadFiles, //state updation function to update selectedFile state
  inlineAttachmentOnly = false, //only inline | both
  setIsFileUploading, //function to set overall upload status
  isFileUploading, //overall upload status, boolean
  editorRef, //useRef which contains tinymce editor instance, used to insert embeded contents
  disableAttachment, //flag which denotes attachment are disabled or not, if disabled then file uploader will not called
  allowedEmbbedTypes, //allowed embbed types editer video or image, it will keep it in the attachment list but it will not embed if the type is not given in the array
  compareOverAllAttachmentSize = true, //flag to check overall attachment size limit
}: Props) {
  // const [uploadFiles, setUploadFiles] = useState<{
  //   [key: number | string]: TinyMCEUploadFile;
  // }>({});
  const currentState = useRef<{
    uploadFiles: { [key: string]: TinyMCEUploadFile };
    disableAttachment?: boolean;
    allowedEmbbedTypes: Props["allowedEmbbedTypes"];
  }>({ uploadFiles: {}, disableAttachment, allowedEmbbedTypes });
  //state variable which stores the file warning modal data
  const [showFileWarning, setShowFileWarning] = useState<FileWarningModalProp>({
    show: false, //flag to show modal
    type: "", //warning type "extensionsupport" | "sizelimit"
    fileName: "",
    errorText: "", // to render error text dynamically
  });

  const parseSelectedFiles = (files: any) => {
    if (files) {
      const parsedFiles: { [key: string]: TinyMCEUploadFile } = {};
      Object.entries(files).forEach(([key, value]: any) => {
        //assuming if the object contains attachmentBatchNumber property it is dump of attachments array of api response
        if (value?.attachmentBatchNumber) {
          let file: TinyMCEUploadFile = {
            id: value?.contentId ?? value?.attachmentId,
            attachmentId: value?.attachmentId,
            attachmentName: value?.attachmentName,
            embedded: value?.embedded,
            progress: 100,
            status: STATUS_UPLOAD.success,
            batchNumber: value.attachmentBatchNumber,
            attachmentURL: value?.attachmentUrl,
            isPublic: value?.isPublic,
            attachmentType: value?.attachmentType,
            attachmentSize: value.attachmentSize,
          };

          parsedFiles[file.id] = file;
        } else {
          parsedFiles[value.id] = value;
        }
      });

      return parsedFiles;
    }

    return {};
  };

  useMemo(() => {
    //updating the useRef hook value with update uploadFiles state value
    currentState.current.uploadFiles = parseSelectedFiles(uploadFiles);
    // setUploadFiles(currentState.current.uploadFiles);
    //getting the file object that is not uploaded
    const fileInProgress = Object.values(uploadFiles).filter(
      (file) =>
        file.status === STATUS_UPLOAD.initialized ||
        file.status === STATUS_UPLOAD.uploading,
    );

    //checking if all the files are uploaded
    if (fileInProgress.length) {
      //not all files uploaded so setting the overall file uploading state to upload in progress
      setIsFileUploading(true);
    } else {
      //all the files uploaded, updating the overall file uploading status to uploaded
      setIsFileUploading(false);
    }
    currentState.current.disableAttachment = disableAttachment;
    currentState.current.allowedEmbbedTypes = allowedEmbbedTypes;
  }, [uploadFiles, disableAttachment, allowedEmbbedTypes]);

  /**
   *  helper function which will create TinyMCEUploadFile object from addFileToUploaded object
   **/
  const processFileAdd = useCallback((files: addFileToUploaded[]) => {
    //declaring variable to store the new files
    let fileToUpload: { [key: number | string]: TinyMCEUploadFile } = {};

    //checking if attachment is disabled
    if (currentState.current.disableAttachment) {
      return {}; //attachment disabled so return
    }

    //looping through each files
    for (let i = 0; i < files.length; i++) {
      const id = uuidv4(); //creating unique id for the file
      const abortController = new AbortController(); //creating instance of abort controller

      //variable to store editor notification manager instance if loader needs to be shown
      let notification = null;

      //checking if passed object needs to be inserted in editor
      if (files[i].insertInEditor) {
        //checking if passed object file type is in currentState.current.allowedEmbbedTypes and is image
        if (
          currentState.current.allowedEmbbedTypes.includes("image") &&
          files[i].file.type.includes("image/")
        ) {
          //generating the img tag template
          const image = getEmbeddedImageTemplate(id, files[i].file, {
            height: "250px",
            width: "250px",
          });

          //inserting the generated image tag to editor
          editorRef.current?.insertContent(image.content);

          //by default editor will pickup all the base64 src images and try to upload, so let the editor handle this file uploading and skipping this loop
          continue;
        }
        //checking if passed object file type is in currentState.current.allowedEmbbedTypes and type video
        else if (
          currentState.current.allowedEmbbedTypes.includes("video") &&
          files[i].file.type.includes("video/")
        ) {
          //showing the progress bar on editor that video is uploading and will handle the uploading in upload success
          notification = editorRef.current?.notificationManager.open({
            text: "Video is uploading",
            progressBar: true,
            icon: "info",
          });
        }
      }

      fileToUpload = {
        ...fileToUpload,
        [id]: {
          id,
          file: files[i].file,
          progress: 0,
          abortController: abortController,
          status: files[i].uploadStatus,
          embedded:
            files[i].embedded === true
              ? (files[i].file.type.includes("video/") &&
                  currentState.current.allowedEmbbedTypes.includes("video")) ||
                (files[i].file.type.includes("image/") &&
                  currentState.current.allowedEmbbedTypes.includes("image"))
              : files[i].embedded,
          notificationBar: notification,
          attachmentSize: files[i].file.size,
        },
      };
    }

    return fileToUpload;
  }, []);

  /**
   * Function to add file to upload or add to selectedFiles
   */
  const setUploadFile = useCallback(
    (files: addFileToUploaded[]) => {
      //checking if attachment disabled
      if (currentState.current.disableAttachment) {
        return; //return attachment disabled
      }

      // Check if a comparison of overall attachment size is needed
      if (compareOverAllAttachmentSize) {
        // Calculate the total size of all currently selected files
        let totalSize = 0;

        // Iterate through the files and add their sizes to the total
        files.forEach((file) => {
          totalSize += file.file.size;
        });

        // Get existing attachments from the current state and add their sizes to the total
        const existingAttachments = Object.values(
          currentState.current.uploadFiles,
        );

        existingAttachments.forEach((file) => {
          if (file.file) {
            // If the file has a 'file' property, add its size to the total
            totalSize += file.file.size;
          } else if (file.attachmentSize) {
            // If the file has an 'attachmentSize' property, add its size to the total
            totalSize += file.attachmentSize;
          }
        });

        // Check if the total size exceeds the allowed limit (20MB)
        if (totalSize > ALLOWED_FILES_SIZE) {
          const totalFiles = existingAttachments.length + files.length;
          // If the limit is exceeded, show a warning modal
          setShowFileWarning({
            show: true,
            fileName: files[0].file.name, // Assuming files is not empty
            type: "sizelimit",
            errorText:
              totalFiles <= 1
                ? undefined
                : `The combined total size must be less than ${TINY_MCE_UPLOAD_FILE_SIZE_LIMIT_MB}MB. Please resize or compress your files, or reduce the number of files, and try uploading again.`,
          });
          return; // Stop further processing since the limit is exceeded
        }
      }

      //looping through each files and checking the size limit
      for (let i = 0; i < files.length; i++) {
        if (files[i].file.size === 0) {
          //file size is invalid
          setShowFileWarning({
            show: true,
            fileName: files[i].file.name,
            type: "invalidFileSize",
            errorText:
              "This file is 0 bytes, so it will not be attached." +
              (files.length > 1
                ? "Please re-attach your files again without it"
                : ""),
          });
          return;
        }

        if (
          files[i].file.size >
          TINY_MCE_UPLOAD_FILE_SIZE_LIMIT_MB * 1024 * 1024
        ) {
          // throw {
          //   msg: `File size should be less than ${TINY_MCE_UPLOAD_FILE_SIZE_LIMIT_MB} MB.`,
          //   status: "sizelimit",
          //   fileName: files[i].name,
          // };

          //limit exceed, updating state to show warning modal
          setShowFileWarning({
            show: true,
            fileName: files[i].file.name,
            type: "sizelimit",
          });
          return;
        }
        if (
          !isFileAttachmentSupportedTinyMCE(
            getTypeFromFileName(files[i].file.name),
            currentState.current.allowedEmbbedTypes,
          )
        ) {
          // throw {
          //   msg: "File type is not supported.",
          //   status: "extensionsupport",
          //   fileName: files[i].name,
          // };
          //file type is not supported, updating state to show warning modal
          setShowFileWarning({
            show: true,
            fileName: files[i].file.name,
            type: "extensionsupport",
          });
          return;
        }
      }

      setUploadFiles((prev: any) => {
        const prevUploadFiles = parseSelectedFiles(prev);
        //adding new files with exisiting state value
        let newState: { [key: number | string]: TinyMCEUploadFile } = {
          ...prevUploadFiles,
          ...processFileAdd(files),
        };
        setTimeout(() => {
          startUplaoding();
        });
        return Object.values(newState);
      });

      //updating the file selectFiles state
      // flushSync(() => {

      // });
    },
    [compareOverAllAttachmentSize], //added compareOverAllAttachmentSize in the dependency
  );

  /**
   * Helper function which will set to uploaded file progress percentage
   */
  const setUploadProgress = useCallback(
    (id: number | string, progress: number) => {
      if (!currentState.current.uploadFiles[id]) {
        return;
      }
      // flushSync(() => {
      //updating file progress percentage to state
      setUploadFiles((prev: any) => {
        const prevUploadFiles = parseSelectedFiles(prev);
        return Object.values({
          ...prevUploadFiles,
          [id]: {
            ...prevUploadFiles[id],
            progress: progress,
          },
        });
      });
      // });

      //checking if video file and notification progress bar updation
      if (currentState.current.uploadFiles[id]?.notificationBar) {
        //updating the value showed on progressBar for progress percentage
        currentState.current.uploadFiles[id].notificationBar.progressBar.value(
          progress,
        );
      }
    },
    [],
  );

  /**
   * Helper function which will update state that file has been successfully uploaded
   */
  const successUploadFile = useCallback((file: TinyMCEUploadFile) => {
    //checking if progressBar has been initialized for this file
    if (currentState.current.uploadFiles[file.id]?.notificationBar) {
      //closing the progressBar
      currentState.current.uploadFiles[file.id].notificationBar.close();
    }

    // flushSync(() => {
    //updating file uploaded status in state
    setUploadFiles((prev: any) => {
      const prevUploadFiles = parseSelectedFiles(prev);
      return Object.values({
        ...prevUploadFiles,
        [file.id]: {
          ...prevUploadFiles[file.id],
          ...file,
          status: STATUS_UPLOAD.success,
          progress: 0,
        },
      });
    });
    // });

    //checking sucessfully uploaded file is video file and is embeded
    if (
      file.file?.type.includes("video") &&
      file.embedded === true &&
      file.isPublic === true &&
      file.attachmentURL &&
      currentState.current.allowedEmbbedTypes.includes("video")
    ) {
      //generating video tag template string and inserting it to the editor
      editorRef.current?.insertContent(
        getEmbeddedVideoTemplate(file.id + "", file.attachmentURL, {
          height: "250px",
          width: "250px",
        }),
      );
    }
  }, []);

  const getFileType = useCallback((file: TinyMCEUploadFile) => {
    let fileType = "";
    if (file.file && file.file.type) {
      fileType = file.file.type;
    } else if (file && file.attachmentType) {
      fileType = file.attachmentType;
    }
    return fileType;
  }, []);

  /**
   * Helper function which will update file status to failed
   */
  const failedToUploadFile = useCallback((id: number | string) => {
    //checking if the file exists
    if (currentState.current.uploadFiles[id]) {
      // flushSync(() => {
      //updating file upload status to failed
      setUploadFiles((prev: any) => {
        const prevUploadFiles = parseSelectedFiles(prev);

        return Object.values({
          ...prevUploadFiles,
          [id]: {
            ...prevUploadFiles[id],
            status: STATUS_UPLOAD.failed,
            progress: 0,
          },
        });
      });
      // });

      removeUploadFiles([currentState.current.uploadFiles[id]], false);
    }

    //checking if this file has initialized progressBar in editor
    if (currentState.current.uploadFiles[id]?.notificationBar) {
      //closing the progressBar
      currentState.current.uploadFiles[id].notificationBar.close();
    }

    if (
      currentState.current.uploadFiles[id] &&
      !currentState.current.uploadFiles[id].embedded
    ) {
      currentState.current.uploadFiles[id].notificationBar =
        editorRef.current?.notificationManager.open({
          text:
            "Failed to upload: " +
            currentState.current.uploadFiles[id].file?.name,
          type: "error",
        });
    }
  }, []);

  /**
   * Helper method will set the passed fileIds status to uploading
   */
  const setFileUploading = useCallback((ids: Array<number | string>) => {
    setUploadFiles((prev: any) => {
      //storing all the uploaded files from currentState useRef to local variable for updating its status
      let selectedFiles = { ...parseSelectedFiles(prev) };
      //looping through each object and updating the status
      ids.map((id) => {
        if (selectedFiles[id] !== undefined) {
          selectedFiles[id].status = STATUS_UPLOAD.uploading;
        }
      });
      return Object.values(selectedFiles);
    });

    //updating the state
    // flushSync(() => {
    // setUploadFiles(Object.values(selectedFiles));
    // });
  }, []);

  /**
   * Helper method to reset the selected files state
   */
  const resetUploadFiles = useCallback(() => {
    //updating the state with empty object
    // flushSync(() => {
    setUploadFiles([]);
    // });
  }, []);

  /**
   * helper method to remove files from selectedFiles state
   */
  const removeUploadFiles = useCallback(
    (files: TinyMCEUploadFile[], closeNotification = true) => {
      //looping through each file object from param files array
      files.map((file) => {
        //checking if file exists
        const uploadFile = currentState.current.uploadFiles[file.id];
        if (uploadFile) {
          //calling the onFileDelete handler to process the api calls
          onFileDelete(uploadFile)
            .then((res) => {
              //checking the delete status
              if (res.status === true) {
                if (res.file.embedded) {
                  //removing embedded attachment from editor if exists
                  const element = editorRef.current?.dom.select(
                    `img[data-temp-id="${res.file.id}"]`,
                  )[0];
                  if (element) {
                    editorRef.current?.dom.remove(element);
                  } else if (res.file.contentId) {
                    const attachmentElement = editorRef.current?.dom.select(
                      `img[content-id="${res.file.contentId}"]`,
                    )[0];
                    if (attachmentElement) {
                      editorRef.current?.dom.remove(attachmentElement);
                    }
                  }
                }

                //success
                //checking if file has progressBar initialized on editor
                if (res.file.notificationBar && closeNotification) {
                  //closing the progressBar
                  res.file.notificationBar.close();
                }

                setUploadFiles((prev: any) => {
                  const currentUploadedFiles = {
                    ...parseSelectedFiles(prev),
                  };
                  //checking if the file is exists in current selectedFiles state
                  if (currentUploadedFiles[res.file.id]) {
                    //deleting the file
                    delete currentUploadedFiles[res.file.id];
                  }
                  return Object.values(currentUploadedFiles);
                });
              } else {
                //push toast if needed to show failed to delete attachment
              }
            })
            .catch((err) => {
              //push toast if needed to show failed to delete attachment
            });
        }
      });
    },
    [],
  );

  /**
   * TinyMCE images_upload_handler callback function
   *
   * handles the uploading of file to server
   */
  const imageUploadHandler = useCallback(
    (blobInfo: any, progress: any) =>
      new Promise<string>((resolve, reject) => {
        //checking if attachment is disabled
        if (currentState.current.disableAttachment) {
          //attachment disabled
          reject({ message: "Attachment is disabled", remove: true });
          return;
        }
        const imgElement = editorRef.current
          ?.getDoc()
          .querySelector(`img[src="${blobInfo.blobUri()}"]`);

        if (imgElement) {
          let id = imgElement.getAttribute("data-temp-id");
          let contentId = imgElement.getAttribute("content-id");

          //to prevent loading image uploaded to server and also prevent already uploaded image trying to uploading again issue
          if (
            imgElement.getAttribute("data-img-loading") === "true" ||
            (id && currentState.current.uploadFiles[id] !== undefined) ||
            (contentId &&
              currentState.current.uploadFiles[contentId] !== undefined)
          ) {
            if (
              id &&
              currentState.current.uploadFiles[id] === undefined &&
              contentId &&
              currentState.current.uploadFiles[contentId] === undefined
            ) {
              reject({ remove: true });
            } else {
              reject();
            }
            reject();
            return;
          }
        }

        //creating file object from blob for uploading it to the server
        var file = new File([blobInfo.blob()], blobInfo.filename(), {
          type: "image/" + getTypeFromFileName(blobInfo.filename()),
        });

        if (
          !TINY_MCE_ATTACHEMENT_SUPPORTED_IMG_TYPES.includes(
            getTypeFromFileName(file.name),
          )
        ) {
          reject({ remove: true });
          return;
        }

        // Check if a comparison of overall attachment size is needed
        if (compareOverAllAttachmentSize) {
          // Calculate the total size of the currently selected file
          let totalSize = file.size;

          // Get existing attachments from the current state and add their sizes to the total
          const existingAttachments = Object.values(
            currentState.current.uploadFiles,
          );

          existingAttachments.forEach((file) => {
            if (file.file) {
              // If the file has a 'file' property, add its size to the total
              totalSize += file.file.size;
            } else if (file.attachmentSize) {
              // If the file has an 'attachmentSize' property, add its size to the total
              totalSize += file.attachmentSize;
            }
          });

          // Check if the total size exceeds the allowed limit (20MB)
          if (totalSize > ALLOWED_FILES_SIZE) {
            const totalFiles = existingAttachments.length + 1;
            // If the limit is exceeded, show a warning modal
            setShowFileWarning({
              show: true,
              fileName: file.name, // Assuming 'file' has a 'name' property
              type: "sizelimit",
              errorText:
                totalFiles <= 1
                  ? undefined
                  : `The combined total size must be less than ${TINY_MCE_UPLOAD_FILE_SIZE_LIMIT_MB}MB. Please resize or compress your files, or reduce the number of files, and try uploading again.`,
            });

            // Reject the file upload with an error message
            reject({
              message: `The combined total size must be less than ${TINY_MCE_UPLOAD_FILE_SIZE_LIMIT_MB}MB. Please resize or compress your files, or reduce the number of files, and try uploading again.`,
              remove: true, // Indicate that the file should be removed from the upload queue
            });
            return; // Stop further processing since the limit is exceeded
          }
        }

        if (file.size === 0) {
          //file size is invalid
          setShowFileWarning({
            show: true,
            fileName: file.name,
            type: "invalidFileSize",
            errorText: "This file is 0 bytes, so it will not be attached.",
          });
          return;
        }

        //checking the file size limit
        if (file.size > ALLOWED_FILES_SIZE) {
          //limit exceed, updating the state to show waring modal
          setShowFileWarning({
            show: true,
            fileName: file.name,
            type: "sizelimit",
          });
          //removing the image from editor
          reject({
            message: `File size should be less than ${TINY_MCE_UPLOAD_FILE_SIZE_LIMIT_MB} MB.`,
            remove: true,
          });
          return;
        }
        //checking the supported file type
        if (
          !isFileAttachmentSupportedTinyMCE(
            getTypeFromFileName(blobInfo.filename()),
            currentState.current.allowedEmbbedTypes,
          )
        ) {
          //file type not supported, updating the state to show waring modal
          setShowFileWarning({
            show: true,
            fileName: file.name,
            type: "extensionsupport",
          });
          //deleting the image from editor
          reject({ message: "File type is not supported.", remove: true });
          return;
        }

        (file as any).customPreviewURL = URL.createObjectURL(blobInfo.blob());
        //creating instance of TinyMCEUploadFile from File object
        const files = processFileAdd([
          {
            file,
            uploadStatus: currentState.current.allowedEmbbedTypes.includes(
              "image",
            )
              ? STATUS_UPLOAD.uploading
              : STATUS_UPLOAD.initialized,
            embedded: currentState.current.allowedEmbbedTypes.includes("image")
              ? true
              : false,
          },
        ]);

        //checking if currentState.current.allowedEmbbedTypes contains image, if not then removing the image editor and keeping it in selected file list
        if (!currentState.current.allowedEmbbedTypes.includes("image")) {
          reject({ message: "image embedding is disabled.", remove: true });
          return;
        }

        //adding the new file instance to existing state files
        setUploadFiles((prev: any) => {
          return Object.values({ ...parseSelectedFiles(prev), ...files });
        });

        if (imgElement && files) {
          imgElement.setAttribute(
            "style",
            "width: 250px; height:auto; max-width: 100%;",
          );
          imgElement.setAttribute(
            "data-temp-id",
            Object.values(files)[0].id + "",
          );
        }

        //call the file upload handler to upload the image
        fileUploadHandler({
          areAttachmentsPublic,
          files: Object.values(files),
          onProgress: (id, number) => {
            //updating the state, for upload progress percentage
            setUploadProgress(id, number);
            if (progress) {
              progress(number);
            }
          },
          onFailed: (id: number | string, message?: string) => {
            //failed to upload
            //updating image file status to failed
            failedToUploadFile(id);
            if (message) {
              reject({ message: message, remove: true });
              return;
            }
            reject({
              message: `${
                currentState.current.uploadFiles[id]?.file
                  ? currentState.current.uploadFiles[id].file?.name
                  : ""
              }`,
              remove: true,
            });
          },
          onSuccess: (file: TinyMCEUploadFile) => {
            //successfully uploaded
            //adding content-id

            // Set the content-id attribute on the img element
            //using getDoc() to access the htmlElement of tinymce iframe
            const imgElement = editorRef.current
              ?.getDoc()
              .querySelector(`img[src="${blobInfo.blobUri()}"]`);
            if (imgElement) {
              imgElement.setAttribute("content-id", file.contentId + "");
              if (file.isPublic) {
                //keeping the existing implement in quill for public attachment
                imgElement.setAttribute("public-img", "true");
              }
            }

            let imageUrl = "";
            //checking if image url is public
            if (file.isPublic) {
              //checking if attachment url exists
              if (file.attachmentURL) {
                //replacing the image src with public url
                imageUrl = file.attachmentURL;
              } else {
                //creating local blob url and updating image src temprorily..private attachment should be handled here...
                imageUrl = URL.createObjectURL(blobInfo.blob());
              }
            } else {
              //creating local blob url and updating image src temprorily...rivate attachment should be handled here...
              imageUrl = URL.createObjectURL(blobInfo.blob());
            }

            successUploadFile(file);
            resolve(imageUrl);
          },
        });
      }),
    [editorRef, currentState, compareOverAllAttachmentSize], //added compareOverAllAttachmentSize in the dependency
  );

  /**
   * function handles callback of onDrop event in tinyMCE editor
   */
  const handleOnFileDrop = useCallback(
    (event: any, editor: any) => {
      //getting all the dropped files from event
      let files: File[] = event?.dataTransfer?.files
        ? [...event?.dataTransfer?.files]
        : [];

      //checking if attachment is disabled
      if (currentState.current.disableAttachment) {
        //here push to that attachment is disabled
        return;
      }

      //filtering images from dropped file list
      const imageFiles = files.filter((file) =>
        TINY_MCE_ATTACHEMENT_SUPPORTED_IMG_TYPES.includes(
          getTypeFromFileName(file.name),
        ),
      );

      //checking if any image has been dropped
      if (imageFiles.length == 0) {
        // Preventing 'Dropped file type is not supported' notification pop up
        event.preventDefault();
        event.stopImmediatePropagation();
        event.stopPropagation();
      }

      //NOTE: ignoring the image files cause the dropped image files are handled by images_upload_handler callback

      //local variable to store uploadable files
      let uploadableFiles: addFileToUploaded[] = [];

      //getting all video file objects
      const videoFiles = files.filter((file) =>
        TINY_MCE_ATTACHEMENT_SUPPORTED_VIDEO_TYPES.includes(
          getTypeFromFileName(file.name),
        ),
      );

      //checking if any video file has been dropped
      if (videoFiles.length) {
        //adding video files objects to uploadable files array with embedded:true and insertInEditor: true, which will insert the content to the editor
        uploadableFiles = videoFiles.map((file) => {
          return {
            file: file,
            uploadStatus: STATUS_UPLOAD.initialized,
            embedded: currentState.current.allowedEmbbedTypes.includes("video"),
            insertInEditor:
              currentState.current.allowedEmbbedTypes.includes("video"),
          };
        });
      }

      //checking if other files exists
      const otherFiles = files.filter((file) => {
        if (
          !TINY_MCE_ATTACHEMENT_SUPPORTED_IMG_TYPES.includes(
            getTypeFromFileName(file.name),
          ) &&
          !TINY_MCE_ATTACHEMENT_SUPPORTED_VIDEO_TYPES.includes(
            getTypeFromFileName(file.name),
          )
        ) {
          return true;
        }

        return false;
      });

      //checking if other files exists and checking if inline attachment only flag not set to true
      //other files will not be uploaded if inlineAttachmentOnly set to true
      if (otherFiles.length && inlineAttachmentOnly !== true) {
        uploadableFiles = [
          ...uploadableFiles,
          ...otherFiles.map((file) => {
            return {
              file: file,
              uploadStatus: STATUS_UPLOAD.initialized,
              embedded: false,
            };
          }),
        ];

        //adding files to upload and selected files list
        setUploadFile(uploadableFiles);
      } else {
        //checking if uploadable files has atleast one file
        if (uploadableFiles.length) {
          //adding files to upload and selected files list
          setUploadFile(uploadableFiles);
        }
      }
    },
    [inlineAttachmentOnly],
  );

  /**
   * Helper function to select file from local and add it to editor
   */
  const selectFileFromLocal = useCallback(
    (
      allowedFileTypes: any = TINY_MCE_ATTACHEMENT_SUPPORTED_TYPES,
      embedded = true,
    ) => {
      //checking if attachment is disabled
      if (currentState.current.disableAttachment) {
        //here push to that attachment is disabled
        return;
      }

      //selecting files from local
      selectFile(allowedFileTypes, true)
        .then((files) => {
          //checking the selected files length
          if (files.length) {
            //files exists
            //getting selected files
            const selectedFiles = Object.values(files);
            //checking if it is inline attachment only
            if (inlineAttachmentOnly) {
              //adding files to upload and selected files list
              //marking the image and video as embedded attachments as this was inline attachment only
              setUploadFile(
                selectedFiles.map((file) => {
                  return {
                    file: file,
                    embedded:
                      file.type.includes("image/") ||
                      file.type.includes("video/"),
                    uploadStatus: STATUS_UPLOAD.initialized,
                    insertInEditor: true,
                  };
                }),
              );
            } else {
              //adding files to upload and selected files list
              setUploadFile(
                selectedFiles.map((file) => {
                  return {
                    file: file,
                    embedded: embedded
                      ? file.type.includes("image/") ||
                        file.type.includes("video/")
                      : false,
                    uploadStatus: STATUS_UPLOAD.initialized,
                  };
                }),
              );
            }
          }
        })
        .catch((err) => {
          //some error occurred
          if (err.status !== "cancelled") {
            setShowFileWarning({
              show: true,
              fileName: err.fileName,
              type: err.status,
            });
          }
        });
    },
    [],
  );

  const handleFilePickerCallback = (
    callback: Function,
    value: any,
    meta: any,
  ) => {
    //  // Provide file and text for the link dialog
    //  if (meta.filetype == 'file') {
    //   //need to check if file type is enabled
    //   selectFile(TINY_MCE_ATTACHEMENT_SUPPORTED_TYPES as any, true)
    //   .then((files) => {
    //     // if (files.length) {
    //     //   const selectedFiles = Object.values(files);
    //     //   if (inlineAttachmentOnly) {
    //     //     setUploadFile(selectedFiles, STATUS_UPLOAD.initialized, true);
    //     //   } else {
    //     //     setUploadFile(selectedFiles, STATUS_UPLOAD.initialized);
    //     //   }
    //     // }
    //   })
    //   .catch((err) => {
    //     if (err.status !== "cancelled") {
    //       // setShowModal(err.status as string);
    //       // setModalFileName(err.fileName);
    //     }
    //   });
    //   callback('mypage.html', { text: 'My text' });
    // }
    // // Provide image and alt text for the image dialog
    // if (meta.filetype == 'image') {
    //   //need to check if image type is enabled
    //   selectFile(TINY_MCE_ATTACHEMENT_SUPPORTED_TYPES as any, true)
    //   .then((files) => {
    //     if (files.length) {
    //       const selectedFiles = files[0];
    //     }
    //   })
    //   .catch((err) => {
    //     if (err.status !== "cancelled") {
    //       // setShowModal(err.status as string);
    //       // setModalFileName(err.fileName);
    //     }
    //   });
    // }
    // // Provide alternative source and posted for the media dialog
    // if (meta.filetype == 'media') {
    //   callback('movie.mp4', { source2: 'alt.ogg', poster: 'image.jpg' });
    // }
  };

  const deleteAttachmentByFileId = ({
    id,
    attachmentURL,
  }: {
    id?: string | null;
    attachmentURL?: string | null;
  }) => {
    let file: null | TinyMCEUploadFile = null;

    if (id) {
      file = currentState.current.uploadFiles[id.trim()];
    }

    if (file === null && attachmentURL) {
      let tempFile = Object.values(currentState.current.uploadFiles).filter(
        (file) => file.attachmentURL === attachmentURL,
      );
      if (tempFile.length) {
        file = tempFile[0];
      }
    }

    if (file) {
      removeUploadFiles([file]);
    }
  };

  //function to handle uploading file to backend
  const startUplaoding = useCallback(() => {
    if (currentState.current.disableAttachment) {
      //here push to that attachment is disabled
      return;
    }

    //getting files that are added to state object to upload
    const fileToUpload = Object.values(currentState.current.uploadFiles).filter(
      (file) => file.status === STATUS_UPLOAD.initialized,
    );

    //checking if any file remaning to upload
    if (fileToUpload.length) {
      //updating files status, files has been passed for uploading
      setFileUploading(fileToUpload.map((file) => file.id));

      //calling the file upload handler to upload the files
      fileUploadHandler({
        files: fileToUpload,
        onProgress: setUploadProgress,
        onFailed: failedToUploadFile,
        onSuccess: successUploadFile,
        areAttachmentsPublic,
      });
    }
  }, [areAttachmentsPublic]);

  //commented out useEffect logic of uploading files as it causing some wired behavior, this login is handled in startUploading function
  //useEffect which will call the file upload handler on new files insert
  // useEffect(() => {
  //   //checking if attachment is disabled
  //   if (disableAttachment) {
  //     //here push to that attachment is disabled
  //     return;
  //   }

  //   //getting files that are added to state object to upload
  //   const fileToUpload = Object.values(uploadFiles).filter(
  //     (file) => file.status === STATUS_UPLOAD.initialized
  //   );

  //   //checking if any file remaning to upload
  //   if (fileToUpload.length) {
  //     //updating files status, files has been passed for uploading
  //     setFileUploading(fileToUpload.map((file) => file.id));

  //     //calling the file upload handler to upload the files
  //     fileUploadHandler({
  //       files: fileToUpload,
  //       onProgress: setUploadProgress,
  //       onFailed: failedToUploadFile,
  //       onSuccess: successUploadFile,
  //       areAttachmentsPublic,
  //     });
  //   }
  // }, [
  //   Object.values(uploadFiles).length,
  //   setUploadFile,
  //   setUploadProgress,
  //   successUploadFile,
  //   failedToUploadFile,
  //   disableAttachment,
  // ]);

  //defining the return values of this hook
  return {
    isFileUploading,
    setUploadFile,
    setUploadProgress,
    successUploadFile,
    failedToUploadFile,
    resetUploadFiles,
    removeUploadFiles,
    imageUploadHandler,
    handleOnFileDrop,
    selectFileFromLocal,
    handleFilePickerCallback,
    showFileWarning,
    setShowFileWarning,
    deleteAttachmentByFileId,
    parseSelectedFiles,
  };
}

export default useFileUpload;
