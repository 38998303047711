import styles from "./RevenueReport.module.scss";
// import FilterWrapper from "../FilterWrapper/FilterWrapper";
import RevenueGenerate from "./Children/RevenueGenerate/RevenueGenerate";
import ReportBar from "src/components/svg/ReportBar";
import ReportDatePicker from "../ReportDatePicker/ReportDatePicker";
import {
  reportCurrentStatusActions,
  useReportFiltersCreate,
} from "../../hooks/reportFilters/useReportFilters";

const RevenueReport = () => {
  const [state, dispatch, Provider] = useReportFiltersCreate();

  return (
    <Provider value={[state, dispatch]}>
      <div>
        <div
          className={`d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center `}
        >
          <div className={`d-flex align-items-center`}>
            <span>
              <ReportBar />
            </span>
            <span className={`ms-2 ${styles.heading}`}>Revenue</span>
          </div>
          <ReportDatePicker
            startDate={state.startDate}
            endDate={state.endDate}
            onChange={([startDate, endDate]) => {
              dispatch([reportCurrentStatusActions.setStartDate, startDate]);
              dispatch([reportCurrentStatusActions.setEndDate, endDate]);
            }}
          />
        </div>
        <div>
          {/* <FilterWrapper scope="revenue" /> */}
          <RevenueGenerate />
        </div>
      </div>
    </Provider>
  );
};

export default RevenueReport;
