import {
  createAsyncThunk,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";
import { AJAXSTATUS } from "src/globals/constants";
import { IArticleCategoryData } from "src/services/LiveChat/Settings/knowledgeBased/articleCategory/getAllArticleCategory";
import { fetchAllArticleCategoryFulfilled } from "./articleCategory.extraReducers";
import articleCategoryReducers from "./articleCategory.reducers";
import articleCategoryThunk from "./articleCategory.thunk";

export const fetchAllCategoryData = createAsyncThunk(
  "categorySetting/fetchAllArticleCategory",
   articleCategoryThunk.fetchAllArticleCategoryThunk
);

export interface ArticleCategoryData{
  categoryData: { [categoryId: string | number]: IArticleCategoryData };
  categoryIdList: Array<number | string>;
  filters: {
    searchValue: string,
  },
  metaData: {
    count: number;
    total: number | null;
  };

  fetchArticleCategoryAjaxStatus: AJAXSTATUS;
  fetchArticleCategoryLimit: number;
}
// initial states for the slice
export const initialState: ArticleCategoryData  = {
  categoryData: {},
  categoryIdList: [],
  fetchArticleCategoryAjaxStatus: "pending",
  fetchArticleCategoryLimit: 10,
  filters: {
    searchValue: "",
  },
  metaData: {
    count: 0,
    total: null
  },

}

// create the slice
export const articleCategorySlice = createSlice({
  name: "articleCategory",
  initialState,
  reducers: articleCategoryReducers,
  extraReducers: (builder) => {
    // fetch all categorys
    builder.addCase(fetchAllCategoryData.fulfilled,
        fetchAllArticleCategoryFulfilled
      );
    builder.addCase(fetchAllCategoryData.pending,
      (state) => {
        state.fetchArticleCategoryAjaxStatus = "pending";
      });
    builder.addCase(fetchAllCategoryData.rejected,
      (state) => {
        state.fetchArticleCategoryAjaxStatus = "rejected";
      });
  }
});


export const {
  resetArticleCategories,
  resetCategorySearchValue,
  setCategorySearchValue,
} = articleCategorySlice.actions;


export default articleCategorySlice.reducer;