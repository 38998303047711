import { useState } from "react";
import PlanModal from "./Children/PlanModal/PlanModal";
import styles from "./PlanUpgrade.module.scss";
import planUpgrade from "src/assets/images/planUpgrade.png";
const UpgradeButton = (
  props: React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > & { buttonText?: string },
) => {
  // Destructure buttonText from props and set default value
  const { buttonText = "Upgrade plan", ...rest } = props;

  return (
    <button {...rest}>
      <span>
        <img
          src={planUpgrade}
          alt="UpgradeButton"
          className={`${styles.upgradeImg}`}
        />
      </span>
      <span className="ps-1">{buttonText}</span>
    </button>
  );
};

const PlanUpgrade = ({
  upgradeText,
  subText,
  upgradeButtonText = "Upgrade plan",
  showOnlyButton = false,
}: {
  upgradeText?: string;
  subText?: string;
  showOnlyButton?: boolean;
  upgradeButtonText?: string;
}) => {
  const [modal, setModal] = useState<boolean>(false);

  const onClickHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setModal(true);
  };

  return (
    <>
      {showOnlyButton ? (
        <UpgradeButton
          className={`mx-2 ${styles.upgradeBtnOnly}`}
          id="upgradeBtn"
          onClick={onClickHandler}
          buttonText={upgradeButtonText}
        />
      ) : (
        <div className={`${styles.upgradeWrap}`}>
          {/* Heading */}
          <h3 className={`${styles.heading}`}>
            {upgradeText ? upgradeText : "Upgrade to view"}
          </h3>

          {/* Sub Text */}
          <span className={`d-block ${styles.subHeading}`}>
            {subText ? subText : "To see the insights, upgrade to paid plan"}
          </span>

          {/* Upgrade Plan Button */}
          <UpgradeButton
            className={`mx-auto ${styles.upgradeBtn}`}
            id="upgradeBtn"
            onClick={onClickHandler}
            buttonText={upgradeButtonText}
          />
        </div>
      )}
      {/* Plan Modal */}
      <PlanModal
        showModal={modal}
        hideModal={() => setModal(false)}
      />
    </>
  );
};

export default PlanUpgrade;
