import { FollowUpReturnReason } from "src/features/ReturnAutoWorkFlow/children/ConfigModals/store/slices/ReturnReasonOrderAndItems/returnReasonOrderAndItems.slice";
import { axiosJSON } from "src/globals/axiosEndPoints";

export interface AllFollowUpReasons {
  allReasons: Record<string, FollowUpReturnReason>;
  allReasonIds: string[];
}

interface PayloadFollowUpReason {
  id: string;
  value: string;
  moreQuestions: {
    elementTypeId: string;
    elementId: string;
    additionalOptions: {
      id: string;
      value: string;
    }[];
    elementValue: string;
  }[];
}

const getAllFollowUpReasonsService = async (params: {
  integrationId: string;
}): Promise<AllFollowUpReasons> => {
  const { data: res } = await axiosJSON.post(
    `/api/setting/automation/refund/returnReasons/getAll`,
    params,
  );

  if (res.err || res.error) {
    throw new Error(res.msg ?? res.message ?? "Some Error Occurred.");
  }

  return normalizeFollowUpReasons(res.data);
};

const normalizeFollowUpReasons = (reasons: PayloadFollowUpReason[]) => {
  const ret: AllFollowUpReasons = {
    allReasonIds: [],
    allReasons: {},
  };

  reasons.forEach((reason) => {
    ret.allReasonIds.push(reason.id);
    ret.allReasons[reason.id] = {
      id: reason.id,
      reason: {
        value: reason.value,
        moreQuestions: reason.moreQuestions,
      },
    };
  });

  return ret;
};

export default getAllFollowUpReasonsService;
