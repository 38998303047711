import { RootState } from "src/store/store";

export function getSelectedTagsIds(state: RootState) {
  const selectedTagsIds: number[] = state.ticketTags.selectedTagsIds
  return selectedTagsIds;
}

export function getUnselectedTagsIds(state: RootState) {
  const allTags = state.ticketTags.allTagsIds
  const selectedTagsIds = state.ticketTags.selectedTagsIds
  const unselectedTagsIds: number[] = allTags.filter(i => !selectedTagsIds.includes(i));
  return unselectedTagsIds;
}

export function getAllTags(state: RootState) {
  const allTags = state.ticketTags.allTags
  const allTagsIds = state.ticketTags.allTagsIds
  return { allTags, allTagsIds }
}