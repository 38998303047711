import styles from "./SendingAs.module.scss";
import { useAppSelector } from "src/store/store";
import { useEffect, useState } from "react";
import DropdownGroup from "src/routes/LiveChat/Children/MainChat/Children/LiveSendingAs/Children/DropdownGroup";
import AddNoteIcon from "src/assets/images/note-add.png";
import AddNoteActiveIcon from "src/assets/images/note-add-active.png";
import { EChannel } from "src/enums/EChannel";
import ViewTemplateButtonV2 from "src/components/ViewTemplateButtonV2";

export enum ESendTypes {
  Message = 1,
  Note,
}

export enum ReplyOption {
  Reply = "Reply",
  Forward = "Forward",
}

interface SendingAsProps {
  sendType: ESendTypes;
  setSendType: (type: ESendTypes) => void;
  replyOption: ReplyOption;
  setReplyOption: (option: ReplyOption) => void;
  selectedTemplate?: (templateText: string) => void;
  showES?: boolean;
  showingWhichModal?: string;
  setShowingWhichModal?: (whichModal: string) => void;
  channelName: EChannel;
  setChannelName: (value: EChannel) => void;
  allowedChannels: EChannel[];
}

function SendingAs({
  sendType,
  setSendType,
  replyOption,
  setReplyOption,
  selectedTemplate = (templateText) => {},
  showES = true,
  showingWhichModal = "",
  setShowingWhichModal = (whichModal) => {},
  channelName = EChannel.email,
  setChannelName = (value) => {},
  allowedChannels = [EChannel.email],
}: SendingAsProps) {
  const scopes = useAppSelector(
    (state) => state.globals.currentUserData?.scopes,
  );
  useEffect(() => {
    if (
      scopes &&
      (scopes.includes("send_message") || scopes.includes("add_draft")) &&
      showES
    ) {
      setSendType(sendType);
    } else if (scopes && scopes.includes("add_internal_note")) {
      setSendType(ESendTypes.Note);
    }
  }, [false]);
  const [noteIcon, setNoteIcon] = useState(false);
  return (
    <div
      className={`d-flex ${styles.sendingAs} justify-content-between my-1 flex-wrap`}
    >
      <div className={`my-auto d-flex`}>
        {scopes &&
          (scopes.includes("send_message") || scopes.includes("add_draft")) &&
          showES &&
          (ESendTypes.Note === sendType ? (
            <div
              className={`px-2 ${styles.share} ${
                ""
                // sendType === ESendTypes.NOTE ? styles.active : ""
              }`}
              onClick={(e) => {
                setSendType(ESendTypes.Message);
              }}
            >
              <i
                className={`fa-sharp fa-solid fa-share fa-flip-horizontal fa-xs ${
                  sendType === ESendTypes.Note ? styles.active : ""
                }`}
              ></i>
              <span
                className={`ms-1 ${
                  sendType === ESendTypes.Note ? styles.active : ""
                }`}
              >
                Send Response
              </span>
            </div>
          ) : (
            <div className="d-flex align-items-center">
              <DropdownGroup
                replyOption={replyOption}
                setReplyOption={(option) => {
                  setReplyOption(option);
                  if (option == ReplyOption.Forward) {
                    setChannelName(EChannel.email);
                  }
                }}
                showReply={true}
                channel={channelName}
                setChannel={setChannelName}
                allowedChannels={allowedChannels}
                disableChannel={replyOption == ReplyOption.Forward}
              />
            </div>
          ))}
      </div>
      <div className="d-flex justify-content-end align-items-center">
        <div className={`d-flex justify-content-center align-items-center`}>
          {scopes && scopes.includes("view_templates") && (
            <span className={`${styles.templetesBtn}`}>
              {" "}
              <ViewTemplateButtonV2
                selectedTemplate={(templateText: string) => {
                  selectedTemplate(templateText);
                }}
                showingWhichModal={showingWhichModal}
                setShowingWhichModal={setShowingWhichModal}
              />
            </span>
          )}
        </div>
        {scopes && scopes.includes("add_internal_note") && (
          <div
            className={`px-1 ${styles.note}`}
            onClick={(e) => {
              setSendType(ESendTypes.Note);
            }}
            onMouseEnter={() => {
              setNoteIcon(true);
            }}
            onMouseLeave={() => {
              setNoteIcon(false);
            }}
          >
            <div
              className={`px-1 ${
                sendType === ESendTypes.Note ? styles.active : ""
              }`}
            >
              <span
                className={`${styles.noteText} ${
                  sendType === ESendTypes.Note ? styles.activeText : ""
                }`}
              >
                +{" "}
              </span>
              {/* Conditionally display active note icon */}
              <img
                src={
                  sendType === ESendTypes.Note || noteIcon === true
                    ? AddNoteActiveIcon
                    : AddNoteIcon
                }
                className={`${styles.noteIcon}`}
                alt="note"
              />
              <span
                className={`${styles.noteText} ${
                  sendType === ESendTypes.Note ? styles.activeText : ""
                }`}
              >
                {" "}
                Internal note
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SendingAs;