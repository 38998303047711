import { Fragment, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import DraftStatus from "src/components/DraftStatus";
import {
  fetchAlldraftMsg,
  setDraftFlow,
} from "src/store/slices/draftFlow/draftFlow.slice";
import { updateTicketCellDataByKey } from "src/store/slices/tickets/tickets.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { getDraftTextColor } from "src/utils/utils";
import ShowTime from "../../../ShowTime";
import styles from "./ViewDraftStatus.module.scss";

function ViewDraftStatus({
  ticketId,
  onAnyChange,
}: {
  ticketId: number | string;
  onAnyChange: () => void;
}) {
  const dispatch = useAppDispatch();
  const draftMsg = useAppSelector((state) => state.draftFlow.draftMsg);
  const draftStatusCycle = useAppSelector(
    (state) => state.draftFlow.draftStatusCycle
  );

  return (
    <div
      className={`d-flex justify-content-between my-2 px-3 py-2 ${styles.draftStatusRoot}`}
    >
      <div className={`my-auto d-flex flex-wrap mb-1`}>
        <span className={styles.draftStatusHead}>Draft Status:</span>
        <span className={`mx-2`}></span>
        {draftStatusCycle.map((data, index) => {
          return (
            <Fragment key={index}>
              <OverlayTrigger
                overlay={
                  <Tooltip className={`override-grey`}>
                    <span>
                      {data.draftMsgStatusName}
                      {", "}
                      {
                        <ShowTime
                          className={`d-inline-block`}
                          dateTime={new Date(data.draftMsgStatusDate)}
                          showIcon={false}
                          style={{ color: "#ffffff" }}
                        />
                      }
                    </span>
                  </Tooltip>
                }
              >
                <span
                  className={`my-auto ${styles.statusHistory}`}
                  style={{
                    borderColor: getDraftTextColor(data.draftMsgStatusName),
                    backgroundColor: getDraftTextColor(data.draftMsgStatusName),
                  }}
                ></span>
              </OverlayTrigger>
              <span className={`my-auto ${styles.statusLine}`}></span>
            </Fragment>
          );
        })}
        {draftMsg !== null && (
          <>
            <span
              className={`my-auto ${styles.statusHistory}`}
              style={{
                borderColor: getDraftTextColor(draftMsg.draftMsgStatusName),
                backgroundColor: getDraftTextColor(draftMsg.draftMsgStatusName),
              }}
            ></span>
            <span className={`mx-2 ${styles.draftStatusValue}`}>
              {draftMsg.draftMsgStatusName}
              {", "}
              {
                <ShowTime
                  className={`d-inline-block`}
                  dateTime={new Date(draftMsg.messageDate)}
                  showIcon={false}
                />
              }
            </span>
          </>
        )}
      </div>
      <div className={`my-auto mb-1`}>
        {draftMsg !== null &&
          draftMsg.messageId !== null &&
          draftMsg.draftMsgStatusId !== null && (
            <DraftStatus
              className={`max-content-width important`}
              ticketId={ticketId}
              onStatusChange={(status) => {
                if (status === "pending") {
                  dispatch(setDraftFlow({ getDraftMsgStatus: "pending" }));
                } else if (status === "rejected") {
                  dispatch(setDraftFlow({ getDraftMsgStatus: "idle" }));
                } else {
                  onAnyChange();
                  dispatch(fetchAlldraftMsg());
                }
              }}
              currentStatus={{
                draftStatusValue: draftMsg.draftMsgStatusName,
                draftStatusId: draftMsg.draftMsgStatusId,
              }}
              messageId={draftMsg.messageId}
            />
          )}
      </div>
    </div>
  );
}

export default ViewDraftStatus;
