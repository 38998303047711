import { axiosJSON } from "src/globals/axiosEndPoints";

export interface ICreateReturnIntegrationParams {
  step_data: {
    selected_auto_type:
      | "fully_automate"
      | "partially_automate",
    };
    brandId: number
  }

export interface ICreateReturnIntegrationData {
  integrationId: number|string;
}

export async function createReturnIntegration(
  payload: ICreateReturnIntegrationParams
) {
  const { data } = await axiosJSON.post(
    "/api/setting/automation/refund/create",
    payload
  );

  if (data.err === true) {
    throw new Error("Some Error Occurred while fetching data");
  }

  return data.data as ICreateReturnIntegrationData;
}