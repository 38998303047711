import React from "react";
import styles from "./SaveModal.module.scss";
import successImg from "src/assets/images/successMsg.gif";
import closeModal from "src/assets/images/closeModal.png";
interface Props {
  onHide: (e: any) => void;
}
export const SaveModal = ({ onHide }: Props) => {
  return (
    <div>
      <div className="d-flex justify-content-end mb-2">
        <span className="cursor-pointer" onClick={onHide}>
          <img src={closeModal} alt="" />
        </span>
      </div>
      <div
        className={`d-flex justify-content-center align-items-center ${styles.successBox}`}
      >
        <img src={successImg} alt="" />
      </div>
      <p className={`mt-3 text-center ${styles.modalText}`}>Changes saved!!</p>
    </div>
  );
};
