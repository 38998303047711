import { BillingProvider } from "./BillingContext";
import BillingSection from "./Children/BillingSection/BillingSection";

const Billing = () => {
  return (
    <BillingProvider>
      <BillingSection />
    </BillingProvider>
  );
};

export default Billing;
