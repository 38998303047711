import styles from "./FbIntegration.module.scss";
import backArrow from "src/assets/images/backArrow.png";
import { Dropdown, Modal, Spinner } from "react-bootstrap";
import FbConnectModal from "./children/FbConnectModal/FbConnectModal";
import fbLogo from "src/assets/images/browseFb.png";
import proceed from "src/assets/images/proceed.png";
import { useCallback, useEffect, useState } from "react";
import AboutIntegration from "./children/AboutIntegration/AboutIntegration";
import FbPageSetUp from "./children/FbPageSetup/FbPageSetup";
import FbInstaMyIntegration from "./children/FbInstaMyIntegration/FbInstaMyIntegration";
import {
  deleteFacebookIntegration,
  FacebookIntegrationData,
  FacebookPage,
  getFacebookPages,
  integrateFacebook,
  integrateFacebookPage,
  IntegrationFacebookPageParams,
  isFacebookIntegrated,
  subscribeFacebookPage,
  unsubscribeFacebookPage,
} from "src/services/Integrations/FacebookIntegration";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "src/components/Loader";
import ConfirmCancelModal from "../ConfirmCancelModal/ConfirmCancelModal";
import useBrandModal from "src/routes/BotSettings/Children/BotBox/BrandModal/useBrandModal";
import UserAvatar from "src/components/UserAvatar";
import { FacebookPageInfo } from "src/services/Integrations/getFacebookPageWebhooks";
import AxiosImg from "src/components/AxiosImg";
import { useAppDispatch } from "src/store/store";
import { refetchSideBarData } from "src/store/slices/ticketSidebar/ticketSidebar.slice";
import BrandDropdown from "./children/BrandDropdown/BrandDropdown";
import {
  useGetAllFacebookIntegrations,
  useUpdateFacebookIntegrationPage,
} from "./useFacebookIntegrations";
import InfiniteScroll from "src/components/InfiniteScrollBothSide";
import FbSingleIntegration from "./children/FbSingleIntegration/FbSingleIntegration";
interface FBPageProps extends FacebookPage {
  setShowWhat: Function;
  setCurrentPageData: Function;
  integrationId: string | number;
}

export const FBPage = ({
  id,
  integrated,
  category,
  name,
  imageUrl,
  setShowWhat,
  setCurrentPageData,
  integrationId,
}: FBPageProps) => {
  const [imgURL, setImgURL] = useState<string | null | undefined>(imageUrl);
  const updatePageMutation = useUpdateFacebookIntegrationPage();

  return (
    <div
      className={`d-flex justify-content-between align-items-center ${styles.rowDiv}`}
    >
      <div className={`d-flex align-items-center`}>
        <img
          className={`${styles.fbLogo}`}
          src={imgURL && imgURL !== "" ? imgURL : fbLogo}
          onError={() => {
            setImgURL(fbLogo);
          }}
        />
        <span className={`ps-3 ${styles.fbName}`}>{name}</span>
      </div>
      <div className={`d-flex align-items-center`}>
        <span className={`d-block me-3 ${styles.pageStatus}`}>
          {integrated ? "Disconnect Page" : "Connect Page"}
        </span>
        {updatePageMutation.isLoading && (
          <Spinner
            className="mx-1"
            animation="border"
            size="sm"
          />
        )}
        <div className="form-check form-switch me-3">
          <input
            data-lpignore="true"
            className={`form-check-input ${styles.formCheck}`}
            type="checkbox"
            checked={integrated}
            onChange={() => {}}
            onClick={(e) => {
              if (!updatePageMutation.isLoading) {
                updatePageMutation.mutate({
                  integrated,
                  id,
                  integrationId,
                });
              }
            }}
          />
        </div>
        <img
          className={`${styles.proceedImg}`}
          src={proceed}
          onClick={() => {
            setCurrentPageData({
              id: id,
              integrated: integrated,
              category: category,
              name: name,
              imageUrl: imageUrl,
            });
            setShowWhat("aboutPage");
          }}
        />
      </div>
    </div>
  );
};

const FbIntegration = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [showWhat, setShowWhat] = useState<string>(
    "listPages" as "listPages" | "aboutPage" | "setupPage" | "",
  );
  const [currentPageData, setCurrentPageData] = useState<FacebookPage>();
  const [integrationData, setIntegrationData] = useState(
    null as null | FacebookIntegrationData,
  );
  const [addPageLoader, setAddPageLoader] = useState(false); //state for add page loader
  const [showNewIntegrationBox, setShowNewIntegrationBox] = useState(false);

  const {
    handleInfiniteScroll,
    hasNextPage,
    integrationIds,
    integrations,
    status,
    isLoading,
    hasMore,
    metaData,
  } = useGetAllFacebookIntegrations();
  const onShow = useCallback(() => {
    setShowModal(true);
    // console.log("clicked");
  }, []);
  const onHide = useCallback(() => {
    setShowModal(false);
  }, []);

  // to add new page
  //function handle onClick event for add new page
  const addNewPage = (integrationId: number | string) => {
    if (!integrationId) {
      return;
    }
    //updating state to show add page loader
    setAddPageLoader(true);
    const payload: IntegrationFacebookPageParams = {
      integration_id: parseInt(integrationId + ""),
    };
    //calling the integrate page api
    integrateFacebookPage(payload)
      .then((res) => {
        setAddPageLoader(false);
        //redirecting user with api response url
        window.location.replace(res);
      })
      .catch((err) => {
        setAddPageLoader(false);
        pushTheToast({
          type: "danger",
          text: err,
          position: "top-right",
        });
      });
  };

  useEffect(() => {
    if (!isLoading && integrationIds.length === 0) {
      setShowNewIntegrationBox(true);
    }
  }, [isLoading, integrationIds]);

  return (
    <>
      {/* Show the fb pages list */}
      {showWhat === "listPages" && (
        <div className={`${styles.fbMain}`}>
          <div className={``}>
            <div
              className={`d-flex justify-content-between align-items-center`}
            >
              <div className={`d-flex align-items-center`}>
                <div className="edit-icons">
                  <button
                    style={{
                      border: "none",
                      background: "none",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      navigate("/settings/browse-integration");
                    }}
                  >
                    {/* <svg
                      style={{ transform: "rotateZ(180deg)" }}
                      width="29"
                      height="29"
                      viewBox="0 0 29 29"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.625 6.04191V22.9586C3.625 23.5995 3.87961 24.2142 4.33283 24.6674C4.78604 25.1206 5.40073 25.3752 6.04167 25.3752H22.9583C23.5993 25.3752 24.214 25.1206 24.6672 24.6674C25.1204 24.2142 25.375 23.5995 25.375 22.9586V6.04191C25.375 5.40097 25.1204 4.78628 24.6672 4.33307C24.214 3.87986 23.5993 3.62524 22.9583 3.62524H6.04167C5.40073 3.62524 4.78604 3.87986 4.33283 4.33307C3.87961 4.78628 3.625 5.40097 3.625 6.04191ZM8.45833 13.2919H14.5V8.45858L20.5417 14.5002L14.5 20.5419V15.7086H8.45833V13.2919Z"
                        stroke="black"
                        strokeWidth="2"
                      />
                      <path
                        d="M22.9584 14.5L14.7876 7.25V13.2917H7.78406V15.7083H14.7876V21.75L22.9584 14.5Z"
                        fill="black"
                      />
                    </svg> */}
                    <img
                      src={backArrow}
                      alt="backArrow"
                      height={25}
                    />
                  </button>
                </div>
                <div className={`${styles.fbMainDiv}`}>
                  <h1 className={`${styles.fb}`}>Facebook</h1>
                </div>
              </div>
              {/* -----Show this div when Integration successfully added!----- */}

              {showToast && (
                <div className={`${styles.successMsg}`}>
                  <span>
                    <i className={`fa-solid fa-circle-check me-1`}></i>
                  </span>
                  <span>Integration successfully added!</span>
                </div>
              )}
            </div>
            {/* Choose an account  */}
            <div className={` ${styles.chooseBoxMain}`}>
              <InfiniteScroll
                className={`p-2 mt-0 mb-auto mx-auto h-100 w-100`}
                hasMoreBottom={hasMore}
                loadMoreFromBottom={handleInfiniteScroll}
              >
                {isLoading ? (
                  <Loader />
                ) : (
                  <>
                    {integrationIds.map((id) => {
                      return (
                        <FbSingleIntegration
                          key={id}
                          integrationData={integrations[id]}
                          showWhat={showWhat}
                          setShowWhat={setShowWhat}
                          setCurrentPageData={setCurrentPageData}
                        />
                      );
                    })}

                    {showNewIntegrationBox ? (
                      <FbSingleIntegration
                        key={"new"}
                        showWhat={showWhat}
                        setShowWhat={setShowWhat}
                        setCurrentPageData={setCurrentPageData}
                        showRemove={integrationIds.length ? true : false}
                        handleRemove={() => setShowNewIntegrationBox(false)}
                      />
                    ) : (
                      <div className={`d-block mt-2`}>
                        {/* if not integrated show log in with facebook */}
                        {integrationIds.length > 0 && (
                          <p className={`${styles.addInt}`}>
                            <span
                              className={`cursor-pointer`}
                              onClick={() =>
                                metaData?.allowMultipleIntegrations
                                  ? setShowNewIntegrationBox(true)
                                  : addNewPage(
                                      integrationIds[integrationIds.length - 1],
                                    )
                              }
                            >
                              + Add New
                            </span>
                            {/* loader for add new page button */}
                            {addPageLoader === true && (
                              <Spinner
                                className="my-auto ms-2"
                                animation="border"
                                size="sm"
                              />
                            )}
                          </p>
                        )}
                      </div>
                    )}
                  </>
                )}
              </InfiniteScroll>
            </div>
          </div>
        </div>
      )}

      {/* Show the about page */}
      {showWhat === "aboutPage" && (
        <AboutIntegration
          showWhat={showWhat}
          setShowWhat={setShowWhat}
          currentPageDatas={currentPageData}
        />
      )}

      {/* Show the setup page */}
      {/* {showWhat === "setupPage" && (
        <FbPageSetUp
          setShowWhat={setShowWhat}
          refreshData={fetchFacebookIntegrationAndPages}
        />
      )} */}
    </>
  );
};
export default FbIntegration;
