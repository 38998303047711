import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import ParseHTML from "src/components/ParseHTML";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { deleteCannedResponse } from "src/services/LiveChat/Settings/cannedResponses/deleteCannedResponse";
import { updateCannedResponse, UpdateCannedResponseParams } from "src/services/LiveChat/Settings/cannedResponses/updateCannedResponse";
import {
  fetchAllCannedResponse,
  resetCannedResponse,
} from "src/store/slices/liveChatSetting/cannedResponse/cannedResponse.slice";
import { useAppDispatch } from "src/store/store";
import CannedResponseForm from "../CannedResponseForm/CannedResponseForm";
import CannedSendMessage from "../CannedSendMessage";
import styles from "./AllResponseEdit.module.scss";
import DeleteResponse from "./Children/DeleteResponse/DeleteResponse";

interface Props {
  cannedResponseId: string | number;
  shortcutKeyText: string;
  messageText: string;
  title: string;
}
const AllResponseEdit = ({
  cannedResponseId,
  shortcutKeyText,
  messageText,
  title,
}: Props) => {


  const [showEdit, setShowEdit] = useState(false);
  const [showDel, setShowDel] = useState(false);
  const [shortcutText, setShortcutText] = useState(shortcutKeyText);
  const [message, setMessage] = useState(messageText);
  const [titleText, setTitleText] = useState(title);
  const dispatch = useAppDispatch();

  const [deleteLoader, setDeleteLoader] = useState(false);

  const { integrationId } = useParams();
  const pageIntegrationId = integrationId
    ? decodeURIComponent(integrationId)
    : "";

  useEffect(() => {
    setMessage(messageText);
    setShortcutText(shortcutKeyText);
  }, [messageText, shortcutKeyText]);

  const handleCannedResponseDelete = (e: any) => {
    setDeleteLoader(true);
    deleteCannedResponse({
      cannedResponseId: cannedResponseId,
    })
      .then((res) => {
        pushTheToast({
          type: "success",
          text: "Response successfully deleted",
          position: "top-right",
        });
        dispatch(resetCannedResponse());
        dispatch(fetchAllCannedResponse(pageIntegrationId));
        setDeleteLoader(false);
      })
      .catch((e) => {
        setDeleteLoader(false);
        pushTheToast({
          type: "danger",
          text: "something went wrong",
          position: "top-right",
        });
      });
  };

  const [showModal, setShowModal] = useState(false);

  const onShow = (e: any) => {
    e.stopPropagation();
    setShowModal(true);
    // console.log("show");
  };
  const onHide = () => {
    setShowModal(false);
    // console.log("hide");
  };

  const handleSaveChanges = (e: any) => {
    setShowEdit(false);
    setShowDel(false);
    e.preventDefault();
    const updateCannedResponseParams: UpdateCannedResponseParams = {
      cannedResponseId: cannedResponseId,
      integrationId: pageIntegrationId,
      shortcut: shortcutText,
      messageText: message,
      title: titleText,
    };
    if (shortcutText != "" && message != "" && title != "") {
      updateCannedResponse(updateCannedResponseParams)
        .then((res) => {
          pushTheToast({
            type: "success",
            text: "Saved Changes",
            position: "top-right",
          });
          setShortcutText("");
          setMessage("");
          dispatch(resetCannedResponse());
          dispatch(fetchAllCannedResponse(pageIntegrationId));
        })
        .catch((e) => {
          pushTheToast({
            type: "danger",
            text: "something went wrong",
            position: "top-right",
          });
        });
    } else {
      pushTheToast({
        type: "warning",
        text: "Fields cannot be empty while updating",
        position: "top-right",
      });
    };
  }

  const cancelChanges = (e: any) => {
    e.preventDefault();
    setShowDel(false);
    setShowEdit(false);
    setShortcutText(shortcutKeyText);
    setMessage(messageText);
    setTitleText(title);
  }

  return (
    <div className={`${styles.AllResponseEdit}`}>
      {/* ---By default show this div---- */}
      {showEdit === false && showDel === false ? (
        <div>
          <div
            className={`d-flex flex-column flex-md-row justify-content-between`}
          >
            <div className={`d-flex flex-column flex-md-row`}>
              <div className="pe-2">
                <span className={`pe-2 ${styles.titleText}`}>Title</span>
                <span className={`px-2 ${styles.titleData}`}>{title}</span>
              </div>
              <div className="pe-2">
                <span className={`pe-2 ${styles.titleText}`}>Shortcut key</span>
                <span className={`px-2 ${styles.titleData}`}>#{shortcutKeyText}</span>
              </div>
            </div>
            <div>
              <button
                className={`${styles.edit}`}
                onClick={(e) => {
                  setShowEdit(true);
                  setShowDel(false);
                }}
              >
                Edit
              </button>
              <button className={`${styles.edit}`} onClick={onShow}>
                Delete
              </button>
              <Modal
                // backdropClassName={`${styles.modalBack}`}
                show={showModal}
                onHide={onHide}
                dialogClassName={`${styles.modalDialog}`}
                contentClassName={`${styles.modalContent}`}
              >
                <DeleteResponse onHide={onHide} handleCannedResponseDelete={handleCannedResponseDelete} deleteLoader={deleteLoader} />
              </Modal>
            </div>
          </div>
          <p className={`mb-0 ${styles.AllResponsePara}`}>
            <ParseHTML html={messageText !== null ? messageText : "NA"} showReadMoreLess={true} />
          </p>
        </div>
      ) : null}
      {/* ---Show this div when we have to delete response---- */}
      {showDel === true && (
        <div className={`${styles.resAction}`}>
          <button
            className={`${styles.delRes}`}
            onClick={handleCannedResponseDelete}
          >
            Delete this reponse
          </button>
          <button
            className={`${styles.cancelRes}`}
            onClick={(e) => {
              setShowDel(false);
              setShowEdit(false);
            }}
          >
            cancel
          </button>
        </div>
      )}
      {/* ---Show this div when we have to edit and update response---- */}
      {showEdit === true && showDel === false ? (
        <div className={``}>
          <div className={`${styles.editForm}`}>
            <input
              data-lpignore="true"
              className={`mb-3 ${styles.inputText}`}
              type={"text"}
              placeholder="Response title*"
              value={titleText}
              onChange={(e) => setTitleText(e.target.value)}
            />
            <input
              data-lpignore="true"
              className={`mb-3 ${styles.inputText}`}
              type={"text"}
              placeholder="create shortcut"
              value={shortcutText}
              onChange={(e) => {
                const sanitizedValue = e.target.value.replace(/\s/g, ''); // Remove spaces
                setShortcutText(sanitizedValue);
              }}
            />
            <CannedSendMessage message={message} setMessage={setMessage} textareaBorder={`addBorder`} />
            <div className={`pt-2 flex-column flex-md-row ${styles.actionBtnDiv}`}>
              <div>
                <button
                  className={`me-2 mb-2 mb-md-0 ${styles.cancelBtn}`}
                  onClick={(e) => { cancelChanges(e) }}
                >
                  Cancel
                </button>{" "}
              </div>
              <div>
                <button
                  className={`${styles.saveBtn}`}
                  onClick={(e) => handleSaveChanges(e)}
                >
                  {" "}
                  save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default AllResponseEdit;
