import { useCallback, useMemo, useRef, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import InfiniteScroll from "src/components/InfiniteScrollBothSide";
import Loader from "src/components/Loader";
import SearchBar from "src/components/SearchBar/SearchBar";
import {
  SegmentDataForUpdateI,
  SegmentTypes,
} from "../../hooks/useCustomerSegments";
import UpdateSegmentDetails from "../UpdateSegmentDetails/UpdateSegmentDetails";
import CustomFilters from "./Children/CustomFilters/CustomFilters";
import Filters from "./Children/Filters/Filters";
import MoreCols from "./Children/MoreCols/MoreCols";
import TableHeader from "./Children/TableHeader/TableHeader";
import TableRow from "./Children/TableRow/TableRow";
import styles from "./SegmentView.module.scss";
import { useSegmentView, useSegmentViewCreate } from "./hooks/useSegmentView";
import UserTimeline from "../UserTimeline/UserTimeline";

const getSegmentCountName = (
  segmentType: SegmentTypes | null,
  isMulti = false,
) => {
  switch (segmentType) {
    case "companySegment":
      return isMulti ? `companies` : `company`;
    case "userSegment":
      return isMulti ? `users` : `user`;
    default:
      return "";
  }
};

const SegmentTable = () => {
  const {
    segmentView,
    activeSegmentDetails,
    dispatch,
    customerDispatch,
    fetchMoreSegmentView,
  } = useSegmentView();

  const [searchValueText, setSearchValueText] = useState(
    segmentView.searchText,
  );

  const searchInit = useRef(false);

  const totalCount = useMemo(() => {
    return `${segmentView.totalSegmentValues} ${getSegmentCountName(
      activeSegmentDetails.activeSegmentType,
      segmentView.totalSegmentValues > 1,
    )}`;
  }, [segmentView, activeSegmentDetails]);

  const onSearch = useCallback(
    (value: string) => {
      if (searchInit.current) {
        dispatch("setSegmentView", { searchText: value });

        // Need to put it in set time out because untill this function will stop execution the state will be stale.
        setTimeout(() => {
          fetchMoreSegmentView();
        }, 0);
      } else {
        searchInit.current = true;
      }
    },
    [dispatch, fetchMoreSegmentView],
  );

  // const [showModal, setShowModal] = useState(true);
  return (
    <div>
      <div className="d-flex flex-column flex-md-row justify-content-between align-items-md-center">
        <div className="d-flex align-items-md-center flex-column flex-md-row">
          <h3
            className={`saufter_h4 mb-0 me-2 ${styles.companyHead}`}
            // onClick={() => setShowModal(true)}
          >
            {segmentView.segmentValuesFetching === "pending" ? (
              <Spinner size="sm" />
            ) : (
              `${totalCount}`
            )}
          </h3>
          <MoreCols />
          {activeSegmentDetails.isCustomSegment ? (
            <CustomFilters />
          ) : (
            <Filters />
          )}
          {/* <button
            className={`mx-md-1 ${styles.msgBtn}`}
            id="newMsgBtn"
          >
            <span className="me-2">
              <i className="fa-solid fa-paper-plane"></i>
            </span>
            <span>New message</span>
          </button> */}
        </div>
        <div>
          {activeSegmentDetails.segmentTypeName !== "All" && (
            <button
              className={`mt-2 mt-md-0 ${styles.segmentBtn}`}
              id={`segmentBtn`}
              onClick={() => {
                if (
                  activeSegmentDetails.activeSegmentId &&
                  activeSegmentDetails.activeSegmentType
                ) {
                  customerDispatch("setUpdateSegmentDetails", {
                    action: activeSegmentDetails.isCustomSegment
                      ? "update"
                      : "duplicate",
                    segmentId: activeSegmentDetails.activeSegmentId,
                    segmentType: activeSegmentDetails.activeSegmentType,
                  });
                }
              }}
            >
              <span className="pe-1">
                <i className="fa-solid fa-chart-pie"></i>
              </span>
              <span>
                {activeSegmentDetails.isCustomSegment
                  ? "Edit segment"
                  : "Duplicate segment"}
              </span>
            </button>
          )}
        </div>
      </div>
      <SearchBar
        className={`${styles.search} px-2 mt-2 mb-3`}
        inputClassName={`${styles.input}`}
        placeholder={`search by customer name , E-mail and any other keyword`}
        value={searchValueText}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
          setSearchValueText(e.target.value)
        }
        onSearch={onSearch}
      />

      <div className={`${styles.segementTable}`}>
        <div className={`${styles.ticketHeader}`}>
          <TableHeader />
        </div>
        {segmentView.segmentValuesFetching === "rejected" &&
        segmentView.segmentValueIds.length === 0 ? (
          <div className="d-flex justify-content-center align-content-center align-items-center pt-4 text-danger">
            Error Loading Content...
          </div>
        ) : segmentView.segmentValuesFetching === "pending" &&
          segmentView.localSegmentFetching !== "pending" ? (
          <div className="d-flex justify-content-center align-content-center align-items-center">
            <Loader />
          </div>
        ) : segmentView.segmentValueIds.length === 0 ? (
          <div
            className={`mt-3 d-flex align-items-center justify-content-center ${styles.noChatFound}`}
          >
            <span> No Items Found</span>
          </div>
        ) : (
          <InfiniteScroll
            loadMoreFromBottom={fetchMoreSegmentView}
            hasMoreBottom={segmentView.hasMoreSegmentView}
            initialDataLoaded={true}
            className={`${styles.segmentsWrapper}`}
            infiniteLoader={
              segmentView.localSegmentFetching === "fulfilled" ? (
                // Rendering the load button conditionally.
                <div
                  className={`${styles.loaderText}`}
                  onClick={fetchMoreSegmentView}
                >
                  <span>Load More</span>
                  <i className="ms-1 fa-solid fa-rotate-right"></i>
                </div>
              ) : segmentView.localSegmentFetching === "pending" ? (
                // Rendering the load text conditionally.
                <div className={`${styles.loadingText}`}>
                  <span>Loading...</span>
                  <div>
                    <Spinner
                      className={`ms-1 ${styles.spinner}`}
                      size="sm"
                      animation="border"
                    />
                  </div>
                </div>
              ) : undefined
            }
          >
            {segmentView.segmentValueIds.map((id) => {
              return (
                <TableRow
                  key={id}
                  id={id}
                />
              );
            })}
          </InfiniteScroll>
        )}
      </div>
    </div>
  );
};

const SegmentViewC = () => {
  const { segmentView, activeSegmentDetails, fetchMoreSegmentView } =
    useSegmentView();

  useMemo(fetchMoreSegmentView, [
    `${activeSegmentDetails.activeSegmentId}::${activeSegmentDetails.activeSegmentType}::${segmentView.moreColsFetching}`,
  ]);

  return (
    <div
      className={`${styles.customerWrap}`}
      id="customerWrapper"
    >
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-start">
          <div
            className={`me-3 cursor-pointer ${styles.backbtn}`}
            id="goBckBtn"
          >
            <span>
              <i className="fa-solid fa-arrow-left"></i>
            </span>
          </div>
          <div>
            <h1 className={`saufter_h1 mb-0 ${styles.heading}`}>
              {activeSegmentDetails.segmentTypeName}/
              {activeSegmentDetails.segmentName}
            </h1>
            {activeSegmentDetails.segmentDescription && (
              <span className={`${styles.description}`}>
                {activeSegmentDetails.segmentDescription}
              </span>
            )}
          </div>
        </div>
        {/* <div>
          <button
            className={`${styles.addUserBtn}`}
            id="addBtn"
          >
            <span className="pe-1">
              <i className="fa-solid fa-plus"></i>
            </span>
            <span>Add New User</span>
          </button>
        </div> */}
      </div>

      <div className={`mt-4 p-4 ${styles.companyDetails}`}>
        {segmentView.moreColsFetching === "rejected" ||
        activeSegmentDetails.allSegmentsLoading === "rejected" ? (
          <div className="d-flex justify-content-center align-content-center align-items-center pt-4 text-danger">
            Error Loading Content...
          </div>
        ) : segmentView.moreColsFetching === "pending" &&
          activeSegmentDetails.allSegmentsLoading !== "fulfilled" ? (
          <div className="d-flex justify-content-center align-content-center align-items-center">
            <Loader />
          </div>
        ) : (
          <SegmentTable />
        )}
      </div>
      {/* <UserTimeline /> */}
    </div>
  );
};

const SegmentView = ({
  onHide,
  data,
}: {
  onHide: () => void;
  data: SegmentDataForUpdateI | null;
}) => {
  const [state, dispatch, Provider] = useSegmentViewCreate();

  return (
    <Provider value={[state, dispatch]}>
      <SegmentViewC />

      {/* Create Segment Modal */}
      <Modal
        backdropClassName={`${styles.modalBack}`}
        show={data !== null ? true : false}
        onHide={onHide}
        dialogClassName={`${styles.modalDialog}`}
        contentClassName={`${styles.modalContent}`}
      >
        <UpdateSegmentDetails />
      </Modal>
    </Provider>
  );
};

export default SegmentView;
