/**
 * This file defines a React component representing single row condition in Audience configure modal
 *
 * @author @yuvaraj-busibud
 */
import React, { useRef, useState } from "react";
import styles from "./Condition.module.scss";
import { Dropdown } from "react-bootstrap";
import Select from "react-select";
import { useQuery } from "@tanstack/react-query";
import { getConditionOptions } from "src/services/Bot/BotProfiles/getConditionOptions.service";
import Loader from "src/components/Loader";
import ConditionDropDown from "../ConditionDropDown/ConditionDropDown";
import ContainDropDown from "../ContainDropDown/ContainDropDown";
import ValueDropDown from "../ValueDropDown/ValueDropDown";
import { AudienceConfigCondition } from "src/store/slices/botProfiles/botProfiles.declarations";
import { useCondition } from "../hooks/useCondition";
import { useAppSelector } from "src/store/store";
const options = [
  { value: "Buy", label: "Buy" },
  { value: "Motorcycles", label: "Motorcycles" },
  { value: "lorem", label: "lorem ipsum" },
  { value: "dummy", label: "dummy" },
  { value: "dummy2", label: "dummy2" },
];
const phoneOptions = [
  { value: "9822334455", label: "9822334455" },
  { value: "9823459568", label: "9823459568" },
  { value: "9123456789", label: "9123456789" },
  { value: "8600468993", label: "8600468993" },
  { value: "9134985634", label: "9134985634" },
];
interface Props {
  index: number;
  condition: AudienceConfigCondition;
  setIschange: any;
}

const Condition = ({ condition, index, setIschange }: Props) => {
  const {
    conditionOptions,
    operatorOptions,
    selectedCondition,
    handleUpdateConditionType,
    handleUpdateOperator,
    handleUpdateValue,
    selectedOperator,
    handleDeleteCondition,
    conditionValue,
  } = useCondition({ condition, setIschange });

  const audienceConfigConditions = useAppSelector(
    (state) => state.botProfiles.audienceConfigConditions
  );

  return (
    <>
      {index !== 0 && (
        <div className="d-flex justify-content-center align-items-center">
          <span className={`px-2 my-2 ${styles.and}`}>AND</span>
        </div>
      )}
      <div
        className={`p-3 d-flex justify-content-between align-items-center ${styles.innerBox}`}
      >
        <div className="d-flex align-items-start flex-column flex-md-row flex-md-wrap w-100">
          <div className={`me-md-2 ${styles.mobDrop}`}>
            <ConditionDropDown
              options={conditionOptions}
              selectedOption={selectedCondition}
              setSelectedOption={handleUpdateConditionType}
              error={condition.error}
            />
          </div>
          {selectedCondition !== undefined ? (
            <>
              <div className={`${styles.mobDrop}`}>
                <ContainDropDown
                  options={operatorOptions}
                  selectedOption={selectedOperator}
                  setSelectedOption={handleUpdateOperator}
                />
              </div>
              <div className={`${styles.mobDrop}`}>
                <ValueDropDown
                  handleChange={handleUpdateValue}
                  type={selectedCondition.inputType}
                  value={conditionValue}
                  error={condition.error}
                  selectedTags={condition.selectedTags}
                />
              </div>
            </>
          ) : null}
        </div>
        <div>
          {audienceConfigConditions.length > 1 || selectedCondition ? (
            <span
              className="ps-2 cursor-pointer"
              onClick={handleDeleteCondition}
            >
              <i className="fa-solid fa-trash"></i>
            </span>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Condition;
