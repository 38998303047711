import useGetAutomationData from "src/features/ReturnAutoWorkFlow/hooks/useGetAutomationData";
import {
  IAddedItem,
  StepName,
  useReturnPreviewState,
} from "../../../../../../hooks/useReturnPreview";
import ButtonWrapper from "../ButtonWrapper/ButtonWrapper";
import WidgetFooter from "../WidgetFooter/WidgetFooter";
import styles from "./StagePreview.module.scss";

const StagePreview = () => {
  const { selectedOrder, updateState } = useReturnPreviewState();
  const { arsResponse } = useGetAutomationData();
  // Helper function to find the ACS offer stage which is accepted
  const findAcceptedStage = (addedItems: Record<string, IAddedItem>) => {
    let nextPage: StepName = "arsInformation"; // Default page if none are accepted
    const updatedItems = Object.keys(addedItems ?? {}).reduce(
      (acc, key: string) => {
        if (addedItems && addedItems[key]) {
          // Check the flags to determine the next page
          if (
            arsResponse?.isInformationAvailable &&
            addedItems[key].dontReturnOrExchangeInfo === true
          ) {
            nextPage = "arsInformation";
            // Set dontReturnOrExchangeInfo to false for each item
            acc[key] = {
              ...addedItems[key],
              dontReturnOrExchangeInfo: false,
            };
          } else if (
            arsResponse?.isOffersAvailable &&
            addedItems[key].dontReturnOrExchangeOffers === true
          ) {
            nextPage = "arsOffers";
            // Set dontReturnOrExchangeInfo to false for each item
            acc[key] = {
              ...addedItems[key],
              dontReturnOrExchangeOffers: false,
            };
          } else if (
            arsResponse?.isExchangeAvailable &&
            addedItems[key].dontReturnOrExchange === true
          ) {
            nextPage = "arsExchange";
            // Set dontReturnOrExchangeInfo to false for each item
            acc[key] = {
              ...addedItems[key],
              dontReturnOrExchange: false,
            };
          }
        }
        return acc;
      },
      {} as Record<string, IAddedItem>,
    );
    return { updatedItems, nextPage };
  };

  const handleSeeOtherStages = () => {
    if (selectedOrder && selectedOrder?.addedItems) {
      // Use the helper function to find the accepted stage and update flags
      const { updatedItems, nextPage } = findAcceptedStage(
        selectedOrder.addedItems,
      );

      updateState({
        selectedOrder: {
          ...selectedOrder,
          addedItems: updatedItems,
          activeStep: nextPage,
        },
      });
    }
  };

  const handleBack = () => {
    // Find the last offer which is accepted and redirect to that page
    let lastOfferPage: StepName = "arsInformation";
    if (selectedOrder?.addedItems) {
      const addedItemsArray = Object.values(selectedOrder.addedItems);

      for (const item of addedItemsArray) {
        if (
          arsResponse?.isInformationAvailable &&
          item.dontReturnOrExchangeInfo === true
        ) {
          lastOfferPage = "arsInformation";
          break;
        } else if (
          arsResponse?.isOffersAvailable &&
          item.dontReturnOrExchangeOffers === true
        ) {
          lastOfferPage = "arsOffers";
          break;
        } else if (
          arsResponse?.isExchangeAvailable &&
          item.dontReturnOrExchange === true
        ) {
          lastOfferPage = "arsExchange";
          break;
        }
      }
    }

    if (selectedOrder) {
      updateState({
        selectedOrder: {
          ...selectedOrder,
          activeStep: lastOfferPage,
        },
      });
    }
  };

  return (
    <div>
      <div className={`${styles.widgetBody}`}>
        <div className={`mt-5 ${styles.previewWrapper}`}>
          <p className={`mb-2 ${styles.heading}`}>
            It seems you accepted an anti-refund offer and could not see the
            other offers in the preview.
          </p>
          <span
            className={`d-block cursor-pointer ${styles.subHeading}`}
            onClick={handleSeeOtherStages}
          >
            See other stages
          </span>
        </div>
      </div>
      <ButtonWrapper
        showNext={false}
        onBackClick={handleBack}
      />
      <WidgetFooter />
    </div>
  );
};

export default StagePreview;
