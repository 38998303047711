import AxiosImg from "src/components/AxiosImg";
import ParseHTML from "src/components/ParseHTML";
import ShowRelativeTime from "src/components/ShowRelativeTime";
import UserAvatar from "src/components/UserAvatar";
import InnerChatAttachments from "src/components/InnerChatAttachments/InnerChatAttachments";
import { Message } from "src/services/LiveChat/messageExchange/getMessages";
import styles from "./MessageOut.module.scss";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  sendLiveMsg,
  SendLiveMsgP,
} from "src/store/slices/liveChatSetting/chatWidExchMsg/chatWidExchMsg.thunks";
import {
  clearIntegrationFailure,
  messageSendTryAgainPressed,
} from "src/store/slices/liveChatSetting/chatWidExchMsg/chatWidExchMsg.slice";
import { useMemo, useCallback } from "react";
import {
  formatDateTimeString,
  getMonthNameDateAmPmTime,
  getTodayRelativeTime,
} from "src/utils/dateLibrary";
import botImg from "src/assets/images/botImg.png";
import tryAgainIcon from "src/assets/images/tryAgainIcon.png";
import IntegrationFailureText from "src/components/IntegrationFailureModal/children/IntegrationFailureText/IntegrationFailureText";
import { EChannel } from "src/enums/EChannel";

const MessageOut = ({
  messageText,
  sentBy,
  sendTimeGmt,
  attachments,
  sendTime,
  messageId,
  messageType,
  scheduled,
  senderType,
  messageSeenStatus, // true: message seen, false: message not seen
  messageChannel,
  to,
}: Message) => {
  const dispatch = useAppDispatch();
  const fetchMessageAjaxStatus = useAppSelector(
    (state) => state.chatWidExhMsg.fetchMsgAJAXStatus,
  );
  const sendMessageAjaxStatus = useAppSelector(
    (state) => state.chatWidExhMsg.sendMsgAJAXStatus,
  );
  const activeCustomerInfo = useAppSelector(
    (state) => state.chatWidExhMsg.activeCustomerInfo,
  );
  const userTimezone = useAppSelector(
    (state) => state.globals.currentUserData?.userTimezone,
  );

  const messageStatus = useAppSelector((state) =>
    messageId
      ? state.chatWidExhMsg.messages[messageId]?.messageStatus
      : undefined,
  );
  const integrationFailureApiData = useAppSelector((state) =>
    messageId
      ? state.chatWidExhMsg.messages[messageId]?.integrationFailure
      : undefined,
  );

  // This is used to send the message again when the user clicks on the "Try Again" button
  const tryAgainMessagePayload: SendLiveMsgP = useMemo(() => {
    return {
      chat_id: activeCustomerInfo ? activeCustomerInfo.chatId : "",
      message: messageText,
      messageType: messageType as any,
      attachmentBatchNumber: attachments?.map((attachment) => {
        return attachment.attachmentBatchNumber || attachment.batchNumber;
      }),
      attachments: attachments,
      uuid: messageId as string,
      channel: messageChannel,
      to:
        messageChannel === EChannel.email ? to?.email ?? undefined : undefined,
    };
  }, [
    messageChannel,
    activeCustomerInfo,
    messageText,
    fetchMessageAjaxStatus,
    messageId,
    sendMessageAjaxStatus,
    to,
  ]);

  // This is the function that is called when the user clicks on the "Try Again" button
  const handleSendTryAgainMessage = useCallback(() => {
    const unsentMessage = JSON.parse(
      localStorage.getItem("unsentMessagesLiveChat") || "[]",
    );

    const unsentMessageIndex = unsentMessage.findIndex(
      (msg: any) => msg.uuid === messageId,
    );

    if (unsentMessageIndex !== -1) {
      unsentMessage.splice(unsentMessageIndex, 1);
      localStorage.setItem(
        "unsentMessagesLiveChat",
        JSON.stringify(unsentMessage),
      );
    }

    // clearing previous integration failure before trying again
    dispatch(
      clearIntegrationFailure({
        messageUuid: messageId as string,
      }),
    );
    // messageStatus = "pending";
    dispatch(
      sendLiveMsg({
        ...tryAgainMessagePayload,
        tryAgain: true,
      }),
    );
    // dispatch(messageSendTryAgainPressed());//commented this dispatch as it is not needed, try again is hided based on message status
  }, [dispatch, messageId, tryAgainMessagePayload]);

  const statusRendering = (status: boolean) => {
    if (status) {
      if (messageStatus === "pending") {
        return (
          <span className={`d-flex justify-content-end mt-1 ${styles.sending}`}>
            sending...
          </span>
        );
      } else {
        return "";
      }
    } else {
      if (scheduled?.createdAtUTC) {
        return (
          <span className={`${styles.time}`}>
            {`Scheduled by ${
              scheduled.scheduledAgent.name ?? "NA"
            } on ${getMonthNameDateAmPmTime(
              formatDateTimeString(
                new Date(scheduled.createdAtUTC + "Z").toLocaleString("en-US", {
                  timeZone: userTimezone,
                }),
              ),
            )}`}
          </span>
        );
      } else if (sendTimeGmt?.trim()) {
        return (
          <ShowRelativeTime
            dateTimeUTC={sendTimeGmt}
            messageType="out"
            messageSeenStatus={messageSeenStatus}
          />
        ); // in: message received, out: message sent passed as prop // true: message seen, false: message not seen
      } else {
        return "";
      }
    }
  };

  // This is the function that is called when the message fails to send due to a network error
  const handleSendFailedMessage = useCallback(() => {
    if (tryAgainMessagePayload) {
      const unsentMessagesString = localStorage.getItem(
        "unsentMessagesLiveChat",
      );
      const unsentMessages = unsentMessagesString
        ? JSON.parse(unsentMessagesString)
        : [];
      // Check if the array already contains a message with the same messageId
      const existingMessageIndex = unsentMessages.findIndex(
        (msg: any) => msg.uuid === messageId,
      );
      if (existingMessageIndex === -1) {
        // If the array does not contain a message with the same messageId, push the new message into the array
        unsentMessages.push({
          ...tryAgainMessagePayload,
          tryAgain: true,
        });
        // Convert the updated array into a string and store in localStorage
        localStorage.setItem(
          "unsentMessagesLiveChat",
          JSON.stringify(unsentMessages),
        );
      }
    }
  }, [messageId, tryAgainMessagePayload]);

  const tryAgainRendering = () => {
    try {
      handleSendFailedMessage();
    } catch (error) {
      console.error("Failed to handle send failed message:", error);
    }
    return (
      <div className={`small text-secondary d-flex`}>
        <span className={`${styles.failedText}`}>Failed....</span>
        {/* Show integration failure text if the message failed due to integration failure */}
        {integrationFailureApiData && (
          <span className="d-flex">
            <span className={`ms-1 ${styles.tryText}`}>
              {integrationFailureApiData?.brand?.email}
            </span>
            <IntegrationFailureText
              integrationFailure={integrationFailureApiData}
            />
          </span>
        )}
        <button
          id="tryAgain"
          className={` border-0 bg-white ${styles.tryText}`}
          onClick={() => {
            handleSendTryAgainMessage();
          }}
        >
          <img
            src={tryAgainIcon}
            alt=""
            className="me-1"
          />
          Try again
        </button>
      </div>
    );
  };

  return (
    <>
      <div className={`d-flex justify-content-end mb-2 ${styles.msgOut} `}>
        <div className={`${styles.msgWrapper}`}>
          <div className={`${styles.msgText}`}>
            <ParseHTML
              html={messageText}
              //  replaceNewLineWithBr={true}
            />

            {attachments && attachments.length > 0 && (
              <div className="mt-2">
                <InnerChatAttachments
                  messageData={{
                    attachments: attachments,
                    senderName: sentBy.name,
                    messageDateTime: sendTime,
                    messageId: messageId,
                  }}
                />
              </div>
            )}
          </div>

          <div
            className={`d-flex justify-content-start align-items-center ms-1 mt-2`}
            style={{ marginRight: "11px" }}
          >
            {/* Conditional Rendering For Status Like Pending & Rejected */}
            {messageStatus === "pending"
              ? statusRendering(true)
              : messageStatus === "rejected"
                ? tryAgainRendering()
                : statusRendering(false)}
          </div>
        </div>
        {senderType === "Bot" || messageType === "botMessage" ? (
          <img
            src={botImg}
            className={`rounded-circle ${styles.profileImage}`}
          />
        ) : sentBy?.imageURL && sentBy?.imageURL.length !== 0 ? (
          <AxiosImg
            url={sentBy?.imageURL}
            className={`rounded-circle ${styles.profileImage}`}
            isDirectURL={sentBy?.isPublicAttachmentUrl}
          />
        ) : (
          <div>
            <UserAvatar
              name={sentBy?.name ?? "NA"}
              size={32}
            />
          </div>
        )}
      </div>
    </>
  );
};
export default MessageOut;
