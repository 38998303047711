import { axiosJSON } from "src/globals/axiosEndPoints";

export interface SendInvoice {
  data: {
    orderId: number;
    isEmailSent: boolean;
  };
  error: boolean;
  message: string;
}

export interface SendInvoiceParams {
  orderId: number;
  from: string;
  to: string;
  subject: string;
  customMessage: string;
  bcc: [string];
}

export async function sendInvoice(params: SendInvoiceParams) {
  const { data: res }: any = await axiosJSON.post(
    `/api/shopify/order/sendInvoice`,
    params
  );

  if (res.error === true) {
    throw new Error(res.message as string);
  }

  return res.data as SendInvoice;
}
