import { useAppSelector } from "src/store/store";
import { selectTicketCellData } from "src/store/slices/tickets/tickets.slice";
import { TableCellProps } from "..";
import styles from "./TicketNotRepliedSince.module.scss";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const KeyNameMap: { [x: string]: string } = {
  not_replied_since: "Not replied since",
  last_message_date: "Last message at",
  ticket_created_date: "Created on",
  ticket_modified_date: "Last updated at",
};

function TicketNotRepliedSince({ ticketId, colKeys, colId }: TableCellProps) {
  const ticketCellData: any = useAppSelector((state) =>
    selectTicketCellData(state, ticketId, colKeys),
  );

  return (
    <>
      <div className={`${styles["subject"]}`}>
        <span className={`${styles["tbody"]} ${styles["notReplied"]}`}>
          {ticketCellData[0] ? (
            <>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip className={` ${styles.toolTipCustom}`}>
                    <span>
                      {KeyNameMap[colKeys[0]] ?? ""}{" "}
                      {new Date(ticketCellData[0]).toLocaleString("en-US", {
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                      })}{" "}
                      {/* Mar 14 2023 updated timestamp string to show 12hours format and removed seconds in formatted time */}
                    </span>
                  </Tooltip>
                }
              >
                {({ ref, ...triggerHandler }) => (
                  <div
                    {...triggerHandler}
                    ref={ref}
                  >
                    <span className={`me-1`}>
                      <i className={`fas fa-clock`} />
                    </span>
                    <span>
                      {new Date(ticketCellData[0]).toLocaleString("en-US", {
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                      })}{" "}
                      {/* Mar 14 2023 updated timestamp string to show 12hours format and removed seconds in formatted time*/}
                    </span>
                  </div>
                )}
              </OverlayTrigger>
            </>
          ) : (
            <>
              <span className={`me-1`}>
                <i className={`fas fa-clock`} />
              </span>
              <span>NA</span>
            </>
          )}
        </span>
      </div>
    </>
  );
}

export default TicketNotRepliedSince;
