import { axiosJSON } from "src/globals/axiosEndPoints";

// export interface HoldFulfillmentReason {
// cancelOrderReasonOption: Array<{
//   name: string;
//   key: string;
// }>;
// }

export interface HoldFulfillmentReason {
  error: boolean;
  message: string;
  data: Array<{
    name: string;
    id: string;
  }>;
}

export async function holdFulfillmentReason() {
  const { data: res }: any = await axiosJSON.post(
    `/api/shopify/order/fulfillment/holdReasonOptions`
  );
  if (res.error === true) {
    throw new Error(res.message as string);
  }

  return res.data as HoldFulfillmentReason;
}
