import styles from "./AllCustomerHeader.module.scss";
import {
  areAllTicketsSelectedSelector,
  removeAllTickets,
  selectAllTickets,
  setBulkActionFilters
} from "src/store/slices/tickets/tickets.slice";
import { useAppSelector, useAppDispatch } from "src/store/store";
import log from "loglevel";

function AllCustomerHeader() {
  const areAllTicketsSelected = useAppSelector(areAllTicketsSelectedSelector);
  const dispatch = useAppDispatch();
  const scopes = useAppSelector(
    (state) => state.globals.currentUserData?.scopes
  );
  const {bulkActionFilters, totalTicketsCount} = useAppSelector(
    (state) => state.ticket
  );

  const handleOnChangeCheckbox = (e: any) => {
    // log.debug("toggled select all");
    //checking if all ticket already toggled or select all ticket is true, if yes then removing the ticket ids from bulk select list
    if (areAllTicketsSelected || bulkActionFilters.selectedAllTickets) {
      dispatch(removeAllTickets());
      dispatch(setBulkActionFilters({selectedAllTickets: false})); //resetting the select all ticket to false
    } else {

      //checking if total ticket count is less than or equal to 10, if yes selecting the ticket directly
      if(totalTicketsCount <= 10){
        dispatch(selectAllTickets());
      }else{
        //dispatching select all ticket modal to show 
        dispatch(setBulkActionFilters({showSelectAllTicketModal: true}));
      }
    }
  };

  return (
    <>
      <div className="form-check" style={{ cursor: "pointer" }}>
        {scopes && scopes.includes("bulk_edit") && (
          <input
            className="form-check-input"
            data-lpignore="true"
            style={{
              width: " 20px",
              height: "20px",
              borderRadius: "6px",
              border: "1px solid #0B68BB",
              cursor: "pointer"
            }}
            type="checkbox"
            value=""
            id="flexCheckDefault"
            checked={areAllTicketsSelected || bulkActionFilters.selectedAllTickets}
            onChange={handleOnChangeCheckbox}
          />
        )}
      </div>
      <div className={`${styles["allTickets"]}`}>
        <div className={`row m-auto`}>
          <div className={`col-md-12`}>
            <span className={`${styles["thead"]} ms-2`}>All Customer </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllCustomerHeader;
