import styles from "./AllCustomerRow.module.scss";
import UserAvatar from "src/components/UserAvatar";
import { TableCellProps } from "..";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { selectTicketCellData } from "src/store/slices/tickets/tickets.slice";

export default function AllCustomerRow({ ticketId, colKeys }: TableCellProps) {
  const ticketCellData: any = useAppSelector((state) =>
    selectTicketCellData(state, ticketId, colKeys)
  );

  return (
    <div className={`d-flex`}>
      <div
        style={{
          width: " 24px",
          height: "24px",
        }}
      ></div>
      <div className={`${styles.AllCust}`}>
        <span className={`${styles["AllCustDiv"]}`}>
          <span className="me-2">
            <UserAvatar name={ticketCellData[0] || "NA"} size={30} />
          </span>
          <span className={`${styles["custName"]}`}>
            {ticketCellData[0] ?? "NA"}
          </span>
        </span>
      </div>
    </div>
  );
}
