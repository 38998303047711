import styles from "./AddDraft.module.scss";
import Button from "react-bootstrap/esm/Button";
import Editor from "../Editor";
import { AddDH } from "../DraftHeader";
import { useEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { addEditDraftMsgData } from "src/store/slices/draftFlow/draftFlow.slice";
import { v4 as uuid } from "uuid";
import { findAndReplaceCID } from "src/utils/utils";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";

interface Props {
  add: AddDH;
  setAdd: (add: any) => void;
  escalateMessage?: string;
  escalateSelectedFiles?: any;
  ticketId: number | string;
  clearEscalateMessge: () => void;
  clearEscalateSelectedFiles?: ([]) => void;
  onAnyChange: () => void;
  // moved the states to parent component and getting it as a prop
  draft: string; // A variable 'draft' with type 'string'.
  remark: string; // A variable 'remark' with type 'string'.
  selectedFilesDraft: Array<any>; // An array 'selectedFilesDraft' containing values of any type.
  selectedFilesRemark: Array<any>; // An array 'selectedFilesRemark' containing values of any type.

  // Declare functions with their parameter and return types.
  setDraft: (value: any) => void; // A function 'setDraft' that takes a parameter of any type and doesn't return a value (void).
  setRemark: (value: any) => void; // A function 'setRemark' that takes a parameter of any type and doesn't return a value (void).
  setSelectedFilesDraft: (value: any) => void; // A function 'setSelectedFilesDraft' that takes a parameter of any type and doesn't return a value (void).
  setSelectedFilesRemark: (value: any) => void; // A function 'setSelectedFilesRemark' that takes a parameter of any type and doesn't return a value (void).
}

function AddDraft({
  add,
  setAdd,
  escalateMessage,
  escalateSelectedFiles,
  ticketId,
  clearEscalateMessge,
  clearEscalateSelectedFiles,
  onAnyChange,
  draft,
  remark,
  selectedFilesDraft,
  selectedFilesRemark,
  setDraft,
  setRemark,
  setSelectedFilesDraft,
  setSelectedFilesRemark,
}: Props) {
  const dispatch = useAppDispatch();

  // const selectedFilesDraftRef = useRef(selectedFilesDraft);
  // const selectedFilesRemarkRef = useRef(selectedFilesRemark);

  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);
  const errRef: any = useRef(null);
  const valueDR = useRef(add === "Add Draft" ? draft : remark);
  valueDR.current = add === "Add Draft" ? draft : remark;
  const addRef = useRef(add);
  useEffect(() => {
    addRef.current = add;
  }, [add]);

  const scopes = useAppSelector(
    (state) => state.globals.currentUserData?.scopes
  );
  const currentState = useRef({ scopes, ticketId });
  useMemo(() => {
    currentState.current = { scopes, ticketId };
  }, [scopes, ticketId]);

  // const handelChange = (value: string) => {
  //   // console.log(addRef.current);
  //   setTimeout(() => {
  //   // console.log(addRef.current);
  //   }, 0);
  //   if (addRef.current === "Add Draft") {
  //     setDraft(value);
  //   } else {
  //     setRemark(value);
  //   }
  // };

  // callback for clearing escalate message and selected attachments in inner ticket
  const clearEscalateMsgNAttachments = () => {
    // clear escalate message
    clearEscalateMessge();
    // clear escalate attachments
    if (clearEscalateSelectedFiles !== undefined) {
      clearEscalateSelectedFiles([]);
    }
  };

  return (
    <>
      {add === "Add Draft" && (
        <Editor
          showView={true}
          options={{
            placeholder: "Type here...",
            max_height: 330,
            min_height: 330,
          }}
          value={draft}
          onChange={setDraft}
          uniqueID={uuid()}
          selectedFiles={selectedFilesDraft}
          setSelectedFiles={setSelectedFilesDraft}
          isFileUploading={isFileUploading}
          setIsFileUploading={setIsFileUploading}
          autosavePrefix={`HELPDESK_DRAFTS_${ticketId}`}
        />
      )}
      {add === "Add Remark" && (
        <Editor
          showView={false}
          options={{
            placeholder: "Type here...",
            max_height: 330,
            min_height: 330,
          }}
          value={remark}
          onChange={setRemark}
          uniqueID={uuid()}
          selectedFiles={selectedFilesRemark}
          setSelectedFiles={setSelectedFilesRemark}
          isFileUploading={isFileUploading}
          setIsFileUploading={setIsFileUploading}
          autosavePrefix={`HELPDESK_REMARKS_${ticketId}`}
        />
      )}
      <div ref={errRef} className={`${styles.red}`}>
        *Please add your{" "}
        {add.split(" ")[add.split(" ").length - 1].toLowerCase()}
      </div>
      <div className={`d-flex justify-content-between mx-0 my-3`}>
        {add === "Add Remark" &&
        scopes &&
        scopes.includes("add_draft_remark") ? (
          <Button
            type="submit"
            className={`d-flex my-auto mx-0 ${styles.sendBtn}`}
            disabled={isFileUploading === true}
            onClick={(e) => {
              setAdd("Add Draft");
            }}
          >
            <span className={`my-auto`}>Back</span>
          </Button>
        ) : (
          <div></div>
        )}
        <button
          type="submit"
          className={`btn d-flex my-auto mx-0 ${
            scopes && scopes.includes("add_draft") ? "" : "cursor-disabled"
          } ${styles.sendBtn}`}
          disabled={isFileUploading === true}
          onClick={(e) => {
            if (scopes && scopes.includes("add_draft")) {
              if (valueDR.current.trim() === "") {
                if (errRef.current) {
                  errRef.current.style.visibility = "visible";
                  setTimeout(() => {
                    try {
                      errRef.current.style.visibility = "hidden";
                    } catch {}
                  }, 5000);
                }
              } else if (
                addRef.current === "Add Draft" &&
                scopes &&
                scopes.includes("add_draft_remark")
              ) {
                setAdd("Add Remark");
              } else {
                const payload: any = {
                  msgId: null,
                  msgContent: findAndReplaceCID(draft),
                  remark: findAndReplaceCID(remark),
                  draftAttachmentBatchNumbers: selectedFilesDraft
                    ? selectedFilesDraft.map(function (value: any, index: any) {
                        return value.batchNumber;
                      })
                    : [],
                  remarkAttachmentBatchNumbers: selectedFilesRemark
                    ? selectedFilesRemark.map(function (
                        value: any,
                        index: any
                      ) {
                        return value.batchNumber;
                      })
                    : [],
                  // callback: clearEscalateMessge,
                  callback: () => {
                    onAnyChange();
                    clearEscalateMsgNAttachments();
                  },
                };
                if (
                  scopes && scopes.includes("add_draft_remark") ? false : true
                ) {
                  delete payload.remark;
                }
                // Dispatch an action 'addEditDraftMsgData' with the provided 'payload'.
                dispatch(addEditDraftMsgData(payload))
                  .then((res: any) => {
                    // Check if the response has an error or the requestStatus is "rejected".
                    if (res.error || res.meta?.requestStatus === "rejected") {
                      // If there's an error, show a danger toast notification for a failed draft save.
                      pushTheToast({
                        type: "danger",
                        text: "Failed to save draft!",
                        position: "top-right",
                      });
                    }
                  })
                  .catch((err) => {
                    // If there's an error (e.g., network error), show a danger toast notification for a failed draft save.
                    pushTheToast({
                      type: "danger",
                      text: "Failed to save draft!",
                      position: "top-right",
                    });
                  });
              }
            }
          }}
        >
          <span className={`my-auto`}>
            {add === "Add Remark" ||
            (scopes && scopes.includes("add_draft_remark") ? false : true)
              ? "Submit Draft"
              : "Next"}
          </span>
        </button>
      </div>
    </>
  );
}

export default AddDraft;
