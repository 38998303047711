import { useCallback, useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import InfiniteScroll from "src/components/InfiniteScroll";
import SearchBar from "src/components/SearchBar/SearchBar";
import {
  fetchAllTicketsIdList,
  fetchInnerTickets,
  resetAllTicketsNav,
  resetInnerTicketData,
  setInnerTicketActive,
  setInnerTicketFilters,
  setSearchTextTicketSb,
} from "src/store/slices/innerTicket/innerTicket.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import TicketListRow from "./children/TicketListRow/TicketListRow";
import styles from "./TicketSB.module.scss";
import emptyBackPng from "src/assets/images/emptyBack.png";
import { getTicketIdRouteLink } from "src/services/TicketService/linkGenerator";
import BotUserStatus from "../MainChat/children/InternalBotChat/Children/BotUserStatus/BotUserStatus";

function TicketSB({ minimizeSB }: { minimizeSB: boolean }) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  let { tableType, ticketId, viewType }: any = useParams();
  ticketId = parseInt(ticketId + "");

  const [lastActiveTicketId, setLastActiveTicketId] = useState<number>();
  const {
    ticketStatusList,
    filters,
    ticketList,
    ticketData,
    ticketListTotal,
    ticketDataAjaxStatus,
  } = useAppSelector((state) => state.innerTicket);
  const currentState = useRef({
    ticketListTotal,
    ticketDataAjaxStatus,
    ticketId,
    filters: filters,
  });
  useEffect(() => {
    currentState.current = {
      ticketListTotal,
      ticketDataAjaxStatus,
      ticketId,
      filters: filters,
    };
  }, [ticketListTotal, ticketDataAjaxStatus, ticketId, filters]);
  const handelTicketStatusChange = useCallback((value: any) => {
    if (currentState.current.ticketDataAjaxStatus === "fulfilled") {
      dispatch(
        setInnerTicketFilters({
          ticketStatus: value,
        }),
      );
    }
  }, []);
  const handleInfiniteScroll = useCallback(() => {
    if (
      currentState.current.ticketListTotal !== null &&
      currentState.current.ticketDataAjaxStatus === "fulfilled"
    ) {
      if (currentState.current.ticketListTotal > 0) {
        dispatch(fetchInnerTickets());
      }
    }
  }, [ticketListTotal]);
  useEffect(() => {
    dispatch(setInnerTicketActive({ ticketId }));
    if (!currentState.current.filters.searchText.trim()) {
      setLastActiveTicketId(ticketId);
    }
  }, [ticketId]);
  useEffect(() => {
    if (filters.ticketId) {
      setLastActiveTicketId(filters.ticketId);
    }
  }, [filters.ticketId]);
  useEffect(() => {
    dispatch(resetInnerTicketData());
    dispatch(setInnerTicketActive({ ticketId: currentState.current.ticketId }));
    dispatch(
      setInnerTicketFilters({
        tableType,
        currentView: viewType,
      }),
    );
    dispatch(fetchInnerTickets());
    dispatch(resetAllTicketsNav());
    dispatch(fetchAllTicketsIdList());

    return () => {
      dispatch(
        setInnerTicketFilters({
          currentView: "",
        }),
      );
    };
  }, [filters.ticketStatus, filters.searchText]);

  if (minimizeSB) {
    return (
      <div className={`py-4 px-3 ${styles.userData}`}>
        {ticketData &&
          ticketList &&
          ticketList.map((id, index) => (
            <BotUserStatus
              key={index}
              profileImg={ticketData[id]?.customer_url}
              isPublicAttachmentUrl={ticketData[id]?.isPublicAttachmentUrl}
              name={ticketData[id]?.ticket_customer_name}
              onClick={() => {
                let redirectTableType = tableType;
                if (
                  tableType === "mentions" &&
                  !ticketData[id].last_mention_message
                ) {
                  redirectTableType = "all";
                }
                navigate(
                  getTicketIdRouteLink({
                    ticketId: id + "",
                    tableType: redirectTableType + "",
                    viewType: viewType + "",
                  }) + `/${window.location.search}`,
                );
              }}
            />
          ))}
      </div>
    );
  }

  return (
    <div className={`${styles.ticketSB}`}>
      <div className="d-flex align-items-center px-2">
        <h5 className={`${styles.linkConv}`}>Linked Conversations</h5>

        <span className={` ${styles.activeConv}`}>
          <span>{ticketListTotal}</span>
        </span>
      </div>
      <div className={`px-2`}>
        <SearchBar
          className={`${styles.search}`}
          inputClassName={`${styles.input}`}
          placeholder={`Search Chats`}
          value={searchValue}
          onSearch={(value: string) => {
            if (value === "" && lastActiveTicketId) {
              navigate(
                getTicketIdRouteLink({
                  ticketId: lastActiveTicketId + "",
                  tableType: tableType + "",
                  viewType: viewType + "",
                }) + `/${window.location.search}`,
              );
            }
            dispatch(setSearchTextTicketSb({ searchText: value }));
          }}
          onChange={(e: any) => {
            setSearchValue(e.target.value);
          }}
        />
      </div>
      <div
        className={`${styles.tabSec} d-flex justify-content-between my-1 py-1 px-2`}
      >
        <div
          className={`${styles.tab} ${
            filters.ticketStatus === 0 ? styles.active : ""
          }`}
          onClick={() => {
            handelTicketStatusChange(0);
          }}
        >
          All
        </div>
        {Object.values(ticketStatusList).map((data, key) => {
          return (
            <div
              key={key}
              className={`${styles.tab} ${
                filters.ticketStatus === data.id ? styles.active : ""
              }`}
              onClick={() => {
                handelTicketStatusChange(data.id);
              }}
            >
              {data.statusName}
            </div>
          );
        })}
      </div>
      <InfiniteScroll
        hideClassDisplayFlex={true}
        className={`p-2 mx-1 ${styles.contentSB}`}
        loadMore={handleInfiniteScroll}
        initialDataLoaded={true}
        loaderClass={`${ticketList.length === 0 ? styles.loaderHeight : ""}`}
        loader={
          ticketDataAjaxStatus === "pending" && ticketList.length === 0 ? (
            <div
              className={`${styles.loading} ${
                ticketList.length === 0 ? styles.loaderCenter : ""
              } m-auto`}
            >
              <Spinner
                animation="border"
                variant="primary"
                size="sm"
                className={`d-block m-auto mb-2`}
              />
              <span>Loading coversations...</span>
            </div>
          ) : undefined
        }
        errorMessage={
          ticketListTotal === 0 || ticketDataAjaxStatus === "rejected" ? (
            <div
              className={`${styles.nothingFound} ${styles.loaderCenter} m-auto`}
            >
              <img
                src={emptyBackPng}
                className={`d-block m-auto mb-2`}
              />
              <span>
                {ticketDataAjaxStatus === "rejected"
                  ? "We were unable to load this section. Please try again by reloading the page."
                  : "No Conversation Found"}{" "}
              </span>
            </div>
          ) : undefined
        }
        hasMore={
          ticketDataAjaxStatus === "rejected"
            ? false
            : ticketListTotal === null
              ? true
              : ticketList.length < ticketListTotal
        }
      >
        {ticketList.map((id, index) => {
          return (
            <TicketListRow
              key={index}
              id={id}
              profileImg={ticketData[id].customer_url}
              isPublicAttachmentUrl={ticketData[id]?.isPublicAttachmentUrl}
              name={ticketData[id].ticket_customer_name}
              description={ticketData[id].ticket_subject}
              time={ticketData[id].ticket_created_date}
              platform={ticketData[id].ticket_channel}
              isAiHandled={ticketData[id].is_ai_handled}
              isDraftMessage={ticketData[id].is_ai_drafted}
              status={ticketData[id].ticket_status} //ticketStatusId, ticketStatusName
              active={id === ticketId}
              onClick={() => {
                let redirectTableType = tableType;
                if (
                  tableType === "mentions" &&
                  !ticketData[id].last_mention_message
                ) {
                  redirectTableType = "all";
                }
                navigate(
                  getTicketIdRouteLink({
                    ticketId: id + "",
                    tableType: redirectTableType + "",
                    viewType: viewType + "",
                  }) + `/${window.location.search}`,
                );
              }}
            />
          );
        })}
      </InfiniteScroll>
    </div>
  );
}

export default TicketSB;
