import { useAppDispatch, useAppSelector } from "src/store/store";
import GoalRow from "./Children/GoalRow/GoalRow";
import styles from "./GoalTable.module.scss";
import {v4 as uuidV4 } from "uuid";
import InfiniteScroll from "src/components/InfiniteScroll";
import Loader from "src/components/Loader";
import { Modal } from "react-bootstrap";
import DeleteGoal from "./Children/GoalRow/DeleteGoal/DeleteGoal";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteGoalSetting, DeleteGoalSettingParams } from "src/services/LiveChat/Settings/goals/deleteGoalSetting";
import { fetchAllGoalSettingData, resetGoalSettingsData } from "src/store/slices/liveChatSetting/Goals/GoalSetting.slice";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";

interface Props{
  integrationId: number|string;
  handleInfiniteScroll: any,
  fetchGoalSettingAjaxStatus:any,
  goalSettingData:any,
  metaData: any,
  goalSettingIdList: any;
};
const GoalTable = ({integrationId,handleInfiniteScroll,fetchGoalSettingAjaxStatus,metaData,goalSettingData,goalSettingIdList}:Props) => {

  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedGoalId, setSelectedGoalId] = useState<number|string>(0);

  const handleDeleteModel = ({goalId}:any)=>{
    // console.log(goalId);
    setSelectedGoalId(goalId);
    setShowModal(true);
  };


  const onHide = useCallback(() => {
    setShowModal(false);
    setSelectedGoalId(0);
  }, []);

 const navigate = useNavigate();
  const dispatch = useAppDispatch();

    // function that handle delete of team
  const handleDelete = () => {

    if(selectedGoalId === 0 || selectedGoalId === null || selectedGoalId === undefined){
      pushTheToast({
        type: "danger",
        text: "Something went wrong!",
        position: "top-right"
      });
      return;
    }

    let deletePayload: DeleteGoalSettingParams = {
      goalId: selectedGoalId,
      integrationId: integrationId
    };
    // delete the team
    deleteGoalSetting(deletePayload)
      .then((res) => {
        dispatch(resetGoalSettingsData());
        dispatch(fetchAllGoalSettingData(integrationId));
        setTimeout(() => {
          pushTheToast({
            type: "success",
            text: "Goal deleted successfully!",
            position: "top-right"
          });
        }, 0);
        onHide();
      })
      .catch((err) => {
        pushTheToast({
          type: "danger",
          text: "Goal deletion failed!",
          position: "top-right"
        });
      });
  };

 
  return (
    <>
      <div className={`${styles.tableHead}`}>
        <div className={`d-flex ${styles.widthSet1}`}>
          <span className={`${styles.headerName}`}>
            ACTIVE
            <span className={`ms-2 ${styles.downArrow}`}>
              <i className="fa-solid fa-sort-down"></i>
            </span>
          </span>
        </div>
        <div className={`d-flex  ${styles.widthSet2}`}>
          <span className={`${styles.headerName}`}>
            NAME
            <span className={`ms-2 ${styles.downArrow}`}>
              <i className="fa-solid fa-sort-down"></i>
            </span>
          </span>
        </div>
        <div className={`d-flex justify-content-center ${styles.widthSet3}`}>
          <span className={`${styles.headerName}`}>SEE IN</span>
        </div>
      </div>
      <div>
      <InfiniteScroll
          // className={`mt-4 ${styles.infScroll}`}
          loadMore={handleInfiniteScroll}
          loader={
            fetchGoalSettingAjaxStatus === "pending" && Object.keys(goalSettingData).length === 0 ? (
              <div className={`d-flex flex-column m-auto ${styles.loading}`}>
                <Loader />
              </div>
            ) : undefined
          }
          initialDataLoaded={false}
          hasMore = {
            fetchGoalSettingAjaxStatus === "rejected"
            ? false
            : metaData.total === 0
            ? true
            : goalSettingIdList.length < metaData.total
          }
        >
        {
          Object.keys(goalSettingData).map((key ,index:number)=>{
            return <GoalRow key={uuidV4()} goalData={goalSettingData[key]} handleDelete={handleDeleteModel} />
          })
        }
        </InfiniteScroll>

      </div>
      <Modal
        backdropClassName={`${styles.modalBack}`}
        show={showModal}
        onHide={onHide}
        dialogClassName={`${styles.modalDialog}`}
        contentClassName={`${styles.modalContent}`}
        //   centered={true}
        enforceFocus={false}
      >
        <DeleteGoal showDeleteText={true} goalId={selectedGoalId} sendGoalId={true} handleConfirm={handleDelete} onHide={onHide} />
      </Modal>
    </>
  );
};
export default GoalTable;
