import { useAppDispatch, useAppSelector } from "src/store/store";
import MoreColsListItem from "../MoreColsListItem";
import styles from "./MoreColsList.module.scss";
import {
  resetMoreColumns,
  setMoreColsSearchValue,
  reorderCurrColumnList,
  getSelectedColIdIndexForAllViewsSelector,
} from "src/store/slices/tickets/tickets.slice";
import { useState, useEffect, useRef } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Column } from "src/hooks/useTicketViews/ticketViewsConfig";
import log from "loglevel";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useSetMoreColumnsMutation } from "../CustomHooks/useSetMoreColumnsMutation";

function MoreColsList() {
  const { tableType, viewType }: any = useParams();
  const [colList, setColList] = useState<Column[]>([]);

  const moreColsSearchValue = useAppSelector(
    (state) => state.ticket.moreColsSearchValue
  );
  const ticketsLoadingState = useAppSelector(
    (state) => state.ticket.ticketsLoadingState
  );
  const currColumnList = useAppSelector((state) => state.ticket.currColumnList);
  const dispatch = useAppDispatch();

  const handleOnClickRestoreCols = (e: any) => {
    dispatch(resetMoreColumns(viewType));
  };

  const allViewSelectedCols = useAppSelector(
    getSelectedColIdIndexForAllViewsSelector
  );

  const isColumnUpdated = useRef(false);

  const { updateTicketColumns } = useSetMoreColumnsMutation();

  useEffect(()=>{

    if (allViewSelectedCols && tableType && isColumnUpdated.current) {
      updateTicketColumns({
        tableType: tableType,
        allViewSelectedCols: allViewSelectedCols,
      });
      isColumnUpdated.current = false;
    }

  },[tableType, allViewSelectedCols]);

  useEffect(() => {
    let cols = currColumnList[viewType].filter(
      (col: Column) => col.hideInMoreCols !== true
    );
    if (moreColsSearchValue.trim().length > 0) {
      // log.debug({ currColumnList: currColumnList[viewType] });
      cols = cols.filter(
        (col: Column) =>
          col.name
            .trim()
            .toLowerCase()
            .includes(moreColsSearchValue.trim().toLowerCase()) === true
      );
    }
    setColList(cols);
  }, [currColumnList, moreColsSearchValue, viewType]);

  const handleSearchInputChange = (e: any) => {
    dispatch(setMoreColsSearchValue(e.target.value));
  };

  return (
    <ul
      className={`list-unstyled mb-0 ${styles.coloumn_dropdown}`}
      aria-labelledby="table"
    >
      {ticketsLoadingState === "fulfilled" && (
        <>
          <li className={`${styles.table__li}`}>
            <div
              role="button"
              className={`${styles.restoreCols}`}
              onClick={handleOnClickRestoreCols}
            >
              Restore default columns
            </div>
          </li>
          <li>
            <div className={`w-100 d-flex px-2 my-1`}>
              <span className={`${styles.searchTicket2} w-0`}>
                <i className={`fas fa-search`} />
              </span>
              <input
                data-lpignore="true"
                type="text"
                className={`${styles["col-search"]} w-100`}
                placeholder="Search columns"
                value={moreColsSearchValue}
                onChange={handleSearchInputChange}
              />
            </div>
          </li>
          <DragDropContext
            onDragEnd={(param: any) => {
              const srcI = param.source.index;
              const desI = param.destination?.index;
              if (desI) {
                const updatedList = [...colList];
                updatedList.splice(desI, 0, updatedList.splice(srcI, 1)[0]);
                setColList(updatedList);
                dispatch(reorderCurrColumnList({ viewType, srcI, desI } as any));
              }
            }}
          >
            <Droppable droppableId="droppable-1">
              {(provided: any, _: any) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {colList.map((col: Column, i) => (
                    <Draggable
                      key={col.id}
                      draggableId={"draggable-" + col.id}
                      index={i}
                    >
                      {(provided: any, snapshot: any) => (
                        <MoreColsListItem customProvided={provided} col={col} isColumnUpdated={isColumnUpdated}/>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </>
      )}
    </ul>
  );
}

export default MoreColsList;
