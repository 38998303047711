import Lottie from "lottie-light-react";
import { useCallback, useState } from "react";
import ripple from "src/assets/images/ripple.json";
import { useAutomationContext } from "src/routes/BotSettings/Children/Automation/Hooks/useAutomationContext";
import { EAutomationType } from "src/routes/BotSettings/Children/AutomationList/AutomationList";
import { useDispatchSectionContext } from "../../Hooks/useDispatchSectionContext";
import styles from "./DispatchIssueDetails.module.scss";
import IssueDescriptionComp, {
  IssueDispatchInfoPopover,
} from "./IssueDescriptionComp/IssueDescriptionComp";
import SuggestArticles from "./SuggestArticles/SuggestArticles";
import KnowledgeBaseDisabledPopover from "./KnowledgeBaseDisabledPopover/KnowledgeBaseDisabledPopover";

const DispatchIssueDetails = () => {
  const { formData } = useAutomationContext();
  const { updateState, state, automationType, showErrors, setShowErrors } =
    useDispatchSectionContext();
  const {
    issueDescription,
    isAiReadIssueDescriptionForDispatch,
    isSuggestArticles: isSuggestArticlesGlobal,
  } = state;

  const [showSuggestArticlesStep, setShowSuggestArticlesStep] = useState(
    isSuggestArticlesGlobal !== undefined,
  );

  const handleChangeSuggestArticle = useCallback(
    (checked: boolean) => {
      updateState({
        isSuggestArticles: checked,
      });
      setShowErrors(false);
    },
    [setShowErrors, updateState],
  );

  const handleIssueDescYes = useCallback(() => {
    if (issueDescription.length === 0) {
      updateState({
        isAiReadIssueDescriptionForDispatch: true,
        issueDescription: [{ description: "" }],
      });
    } else {
      updateState({
        isAiReadIssueDescriptionForDispatch: true,
      });
    }
    setShowErrors(false);
  }, [issueDescription.length, setShowErrors, updateState]);

  const handleDescriptionChange = useCallback(
    (index: number, value: string) => {
      const newDescription = [...issueDescription];
      newDescription[index].description = value;
      updateState({ issueDescription: newDescription });
      setShowErrors(false);
    },
    [issueDescription, setShowErrors, updateState],
  );

  const handleDescriptionDelete = useCallback(
    (index: number) => {
      const newDescription = [
        ...issueDescription.slice(0, index),
        ...issueDescription.slice(index + 1),
      ];

      updateState({ issueDescription: newDescription });
      setShowErrors(false);
    },
    [issueDescription, setShowErrors, updateState],
  );

  return (
    <div className={`w-100 ${styles.ruleBox}`}>
      <div className={`mt-4 mb-4 ${styles.selectBox}`}>
        <p className={`mb-4 ${styles.heading}`}>
          Take actions if the dispatching condition matches:
        </p>
        <div className={`mb-2 ${styles.innerBox}`}>
          <h3 className={`${styles.formHead}`}>
            Would you like Cern AI to read the issue description provided by the
            customer and only dispatch cases which match the description?{" "}
            <IssueDispatchInfoPopover />
          </h3>

          <div className="form-check">
            <input
              className={`form-check-input ${styles.radioBox}`}
              type="radio"
              name="issueDecription"
              id="issueDescriptionYes"
              onChange={handleIssueDescYes}
              checked={isAiReadIssueDescriptionForDispatch === true}
            />
            <label
              className={`form-check-label ${styles.formLabel}`}
              htmlFor="issueDescriptionYes"
            >
              Yes
            </label>
          </div>
          <div className="form-check">
            <input
              className={`form-check-input ${styles.radioBox}`}
              type="radio"
              name="issueDecription"
              id="issueDescriptionNo"
              onChange={() => {
                updateState({
                  isAiReadIssueDescriptionForDispatch: false,
                });
                setShowErrors(false);
              }}
              checked={isAiReadIssueDescriptionForDispatch === false}
            />
            <label
              className={`form-check-label ${styles.formLabel}`}
              htmlFor="issueDescriptionNo"
            >
              No
            </label>
          </div>

          {isAiReadIssueDescriptionForDispatch ? (
            <div>
              <h3 className={`${styles.formHead}`}>
                Please provide a description of the issue(s) that you would like
                Cern to dispatch under this rule? <IssueDispatchInfoPopover />
              </h3>
              {issueDescription.map((data, index) => (
                <IssueDescriptionComp
                  index={index}
                  key={index}
                  description={data.description}
                  handleChange={handleDescriptionChange}
                  handleDelete={
                    index !== 0 ? handleDescriptionDelete : undefined
                  }
                  error={
                    showErrors &&
                    (data.description === "" || data.description.trim() === "")
                  }
                />
              ))}

              <div
                className="d-flex pt-2"
                onClick={() => {
                  updateState({
                    issueDescription: [
                      ...issueDescription,
                      { description: "", articlesDetails: [] },
                    ],
                  });
                  setShowErrors(false);
                }}
              >
                <div className="">
                  <div
                    className={`cursor-pointer  me-2 d-flex align-items-center ${styles.addMoreBtn}`}
                  >
                    <span className="">
                      {" "}
                      <i className="fa-solid fa-plus"></i>
                    </span>
                  </div>
                </div>
                <div className="cursor-pointer">
                  <span className={`${styles.addMoreText}`}>
                    Add more issue description
                  </span>
                </div>
              </div>
              {automationType === EAutomationType.BUG_MANAGEMENT &&
                !showSuggestArticlesStep && (
                  <button
                    className={`mt-3 ${styles.moveBtn}`}
                    onClick={() => setShowSuggestArticlesStep(true)}
                  >
                    <span style={{ width: "33px", height: "33px" }}>
                      <Lottie animationData={ripple}></Lottie>
                    </span>
                    <span> Move to next step</span>
                  </button>
                )}
            </div>
          ) : (
            ""
          )}

          {showErrors && isAiReadIssueDescriptionForDispatch === undefined && (
            <small className={`${styles.errorMsg}`}>
              Please select one option
            </small>
          )}
        </div>
        {automationType === EAutomationType.BUG_MANAGEMENT &&
        showSuggestArticlesStep === true &&
        isAiReadIssueDescriptionForDispatch ? (
          <div className={`${styles.innerBox}`}>
            <div className="mb-3">
              <h3 className={`${styles.formHead}`}>
                After the user provides a description of an issue, would you
                like Cern to suggest specific articles from your knowledge base?
              </h3>

              <div className="form-check">
                <input
                  className={`form-check-input ${styles.radioBox}`}
                  type="radio"
                  name="isSuggestArticles"
                  id="articleYes"
                  disabled={formData.hasKnowledgeBaseArticles === false}
                  checked={isSuggestArticlesGlobal === true}
                  onClick={() => handleChangeSuggestArticle(true)}
                />
                <label
                  className={`form-check-label ${styles.formLabel}`}
                  htmlFor="articleYes"
                >
                  Yes
                  {formData.hasKnowledgeBaseArticles === false && (
                    <KnowledgeBaseDisabledPopover />
                  )}
                </label>
              </div>
              <div className="form-check">
                <input
                  className={`form-check-input ${styles.radioBox}`}
                  type="radio"
                  name="isSuggestArticles"
                  id="articleNo"
                  checked={isSuggestArticlesGlobal === false}
                  onClick={() => handleChangeSuggestArticle(false)}
                />
                <label
                  className={`form-check-label ${styles.formLabel}`}
                  htmlFor="articleNo"
                >
                  No
                </label>
              </div>
            </div>
            {isSuggestArticlesGlobal ? (
              <div>
                <h3 className={`${styles.formHead}`}>
                  Please select knowlege-base articles for individual issue
                  descriptions?
                </h3>
                {issueDescription.map((description, index) => {
                  if (!description.description.trim().length) {
                    return null;
                  }

                  return (
                    <SuggestArticles
                      key={index}
                      index={index}
                      description={description.description}
                    />
                  );
                })}
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default DispatchIssueDetails;
