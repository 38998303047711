import { useCallback, useEffect, useMemo, useState } from "react";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import {
  checkLCInstallationT,
  fetchWebIntegration,
  resetWebsiteSettings,
  updateTheWebIntegration,
} from "src/store/slices/liveChatSetting/websiteSettings/websiteSettings.slice";
import { FetchUpdateWebIntParams } from "src/store/slices/liveChatSetting/websiteSettings/websiteSettings.thunks";
import { useAppDispatch, useAppSelector } from "src/store/store";
import useToDoList from "./useToDoList";
import { ILiveChatSetupDetails } from "src/services/Home/getTodoList.service";

/**
 * Represents the details of a store for Live Chat integration.
 */
export interface IStoreDetails {
  liveChatToggle: boolean;
  storeName: string;
  storeURL: string;
  brandId: string;
  brandsFetched: boolean;
}

/**
 * Represents validation status for Live Chat fields.
 */
export interface LCFieldValidation {
  storeURL: boolean;
  storeName: boolean;
  brandId: boolean;
}

/**
 * Initial validation state for Live Chat fields.
 */
const initialValidation: LCFieldValidation = {
  storeURL: false,
  storeName: false,
  brandId: false,
};

/**
 * Custom hook for Live Chat To Do functionality.
 * @param integrationId The ID of the Live Chat integration.
 * @param stepNumber The current step number.
 * @param onHide Function to hide the component.
 * @returns Object containing Live Chat To Do functionality.
 */
const useLiveChatToDo = ({
  stepNumber,
  onHide,
  integrationId,
}: {
  stepNumber: number;
  onHide: () => void;
  integrationId?: string;
}) => {
  const dispatch = useAppDispatch();
  const [activeStep, setActiveStep] = useState(stepNumber);
  const [storeDetail, setStoreDetail] = useState<IStoreDetails | null>(null);
  const [installLoading, setInstallLoading] = useState(false);
  const [showValidation, setShowValidation] =
    useState<LCFieldValidation>(initialValidation);

  const {
    activeWebIntAJAXStatus,
    updateNewWebIntAJAXStatus,
    activeWebIntegration,
    createdIntId,
    createNewWebIntAJAXStatus,
  } = useAppSelector((state) => state.websiteSettings);

  const { updateLiveChatStep } = useToDoList();

  /**
   * Effect for initalizing the integration data
   */
  useEffect(() => {
    if (integrationId) {
      // Reset the slice data
      dispatch(resetWebsiteSettings());
      // Get the slice data
      dispatch(
        fetchWebIntegration({
          integration_id: integrationId,
          navigateCallBack: () => {
            pushTheToast({
              position: "top-right",
              text: "Integration not found!",
              type: "danger",
            });
            onHide();
          },
        }),
      );
    } else if (createdIntId) {
      // Reset the slice data
      dispatch(resetWebsiteSettings());
      // Get the slice data
      dispatch(
        fetchWebIntegration({
          integration_id: createdIntId,
          navigateCallBack: () => {
            pushTheToast({
              position: "top-right",
              text: "Integration not found!",
              type: "danger",
            });
            onHide();
          },
        }),
      );
    }
  }, [createdIntId, dispatch, integrationId, onHide]);

  /**
   * Effect for initalizing store detail when active web integration data is updated
   */
  useEffect(() => {
    setStoreDetail({
      liveChatToggle: activeWebIntegration.live_chat_enabled ?? false,
      storeName: activeWebIntegration.website_name ?? "",
      storeURL: activeWebIntegration.store_url ?? "",
      brandId: activeWebIntegration.brand?.id
        ? activeWebIntegration.brand.id + ""
        : "",
      brandsFetched: false,
    });
  }, [
    activeWebIntegration.brand?.id,
    activeWebIntegration.live_chat_enabled,
    activeWebIntegration.store_url,
    activeWebIntegration.website_name,
  ]);

  /**
   * Updates the store details.
   * @param val Partial store details to update.
   */
  const updateStoreDetails = useCallback((val: Partial<IStoreDetails>) => {
    setShowValidation(initialValidation);
    setStoreDetail((data) => {
      if (data) {
        return {
          ...data,
          ...val,
        };
      }
      return null;
    });
  }, []);

  /**
   * Checks Live Chat installation.
   */
  const checkInstallation = useCallback(() => {
    if (integrationId) {
      // Initialize check LC install loader
      setInstallLoading(true);

      // Dispatch event for checking live chat installation
      // This itself  will dispatch pusher event and call an API once event is recieved
      // The state after that is updated in the useEffect to update step
      dispatch(checkLCInstallationT()).then((res: any) => {
        if (res.error || res.meta?.requestStatus === "rejected") {
          // Show error if could not validate installation
          pushTheToast({
            position: "top-right",
            text: "Widget Installation not found!",
            type: "danger",
          });
          // Update the live chat step data
          updateLiveChatStep({ installed_successfully: false });
          // Hide the install loader - (Step 3)
          setInstallLoading(false);
        } else {
          // Update the live chat step data
          updateLiveChatStep({ installed_successfully: true });
          // Go to next step
          setActiveStep(4);
          // Hide the install loader - (Step 3)
          setInstallLoading(false);
        }
      });
    }
  }, [dispatch, integrationId, updateLiveChatStep]);

  /**
   * Checks if the store name is valid.
   */
  const isNameValid = useMemo(
    () => storeDetail?.storeName.trim() !== "",
    [storeDetail?.storeName],
  );

  /**
   * Checks if the store URL is valid.
   */
  const isURLValid = useMemo(() => {
    // Check if we have store url in store details
    if (!storeDetail?.storeURL) {
      return false;
    }

    // Check if we have a valid URL
    let storeUrl;
    try {
      storeUrl = new URL(storeDetail.storeURL.trim());
    } catch (error) {
      return false;
    }

    // Check if entered store url is same as origin
    if (storeUrl.origin === window.origin) {
      return false;
    }

    // Store URL is valid
    return true;
  }, [storeDetail?.storeURL]);

  /**
   * Checks if the brand ID is valid.
   */
  const isBrandIdValid = useMemo(
    () => storeDetail?.brandId !== "",
    [storeDetail?.brandId],
  );

  /**
   * Updates the data for Live Chat integration.
   */
  const updateData = useCallback(() => {
    // Check validity of data
    if (
      integrationId &&
      storeDetail &&
      isNameValid &&
      isBrandIdValid &&
      isURLValid
    ) {
      // Initialize params
      const payload: FetchUpdateWebIntParams = {
        integration_id: integrationId,
      };

      // Check if store URL is changed, add to the payload
      if (activeWebIntegration.store_url !== storeDetail?.storeURL.trim()) {
        payload.url = storeDetail?.storeURL.trim();
      }

      // Check if brand id is changed, add to the payload
      if (activeWebIntegration.brand?.id + "" !== storeDetail?.brandId) {
        payload.brand_id = storeDetail?.brandId;
      }

      // Check if store name is changed, add to the payload
      if (activeWebIntegration.website_name !== storeDetail?.storeName.trim()) {
        payload.website_name = storeDetail?.storeName.trim();
      }

      // If nothing is updated return
      if (!payload.url && !payload.brand_id && !payload.website_name) {
        return;
      }

      // Dispatch update action with payload
      dispatch(updateTheWebIntegration(payload)).then((res: any) => {
        if (!res.error && res.meta?.requestStatus !== "rejected") {
          // Build the update setup details data
          const updateData: Partial<ILiveChatSetupDetails> = {
            store_url: payload.url,
            website_name: payload.website_name,
          };

          // Update the live chat setup details
          // Pass the updated data and set installed successfully to false
          updateLiveChatStep({ ...updateData, installed_successfully: false });

          setActiveStep(2);
        }
      });
    } else {
      // Show validation for data
      setShowValidation({
        brandId: !isBrandIdValid,
        storeName: !isNameValid,
        storeURL: !isURLValid,
      });
    }
  }, [
    activeWebIntegration.brand?.id,
    activeWebIntegration.store_url,
    activeWebIntegration.website_name,
    dispatch,
    integrationId,
    isBrandIdValid,
    isNameValid,
    isURLValid,
    storeDetail,
    updateLiveChatStep,
  ]);

  /**
   * Handles the next step in the Live Chat To Do flow.
   */
  const handleNextClick = useCallback(async () => {
    // Check validation for the data of integration
    if (!isBrandIdValid || !isNameValid || !isURLValid) {
      setShowValidation({
        brandId: !isBrandIdValid,
        storeName: !isNameValid,
        storeURL: !isURLValid,
      });
      return;
    }

    // If it is first step call the update function and return
    // Step is updated after the response of update call with API status calls
    if (activeStep === 1) {
      updateData();
      return;
    }

    // Update active step
    setActiveStep((prev) => {
      // If step is 4, do nothing
      if (prev === 4) return prev;

      // Increase step by 1
      return prev + 1;
    });
  }, [activeStep, isBrandIdValid, isNameValid, isURLValid, updateData]);

  /**
   * Determines if the next button should be disabled.
   */
  const disableNext = useMemo(() => {
    if (!isBrandIdValid || !isNameValid || !isURLValid) {
      return true;
    }

    return false;
  }, [isBrandIdValid, isNameValid, isURLValid]);

  /**
   * Full page loader state for API calls related to live chat integration
   */
  const isLoading = useMemo(
    () =>
      activeWebIntAJAXStatus === "pending" ||
      createNewWebIntAJAXStatus === "pending" ||
      updateNewWebIntAJAXStatus === "pending",
    [
      activeWebIntAJAXStatus,
      createNewWebIntAJAXStatus,
      updateNewWebIntAJAXStatus,
    ],
  );

  return {
    activeStep,
    storeDetail,
    loading: isLoading,
    installLoading,
    disableNext,
    showValidation,
    updateStoreDetails,
    handleNext: handleNextClick,
    checkInstallation,
  };
};

export default useLiveChatToDo;
