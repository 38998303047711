import styles from "./SlackEscalateMst.module.scss";
import slack from "src/assets/images/slack.png";
import saufterLogo from "src/assets/images/saufter.gif";
import { useState } from "react";
import ParseHTML from "src/components/ParseHTML";
import { Dropdown } from "react-bootstrap";
import { getTimeFromNow } from "src/utils/dateLibrary";
import { useAppSelector } from "src/store/store";
import { DropDownMenuData } from "../SendMsgCommon/SendMsgCommon";
import InnerChatAttachments from "src/components/InnerChatAttachments/InnerChatAttachments";

const SlackEscalateMsg = ({ messageData }: { messageData: any }) => {
  const [dropShow, setDropShow] = useState(false);
  const userTimezone = useAppSelector(
    (state) => state.globals.currentUserData?.userTimezone,
  );
  return (
    <div className={`${styles.slackWrapper}`}>
      <div>
        <div className={`d-flex`}>
          <div>
            <div className={`${styles.msgBox}`}>
              <div className={`${styles.msgText}`}>
                <div className=",b-0">
                  <ParseHTML html={messageData.message} />
                </div>
              </div>
              {messageData.attachments?.length ? (
                <div className="mt-2">
                  <InnerChatAttachments
                    messageData={{ attachments: messageData.attachments }}
                  />
                </div>
              ) : (
                ""
              )}
              {messageData.messageType === "BotAiEscalatedNote" && (
                <div className="d-flex justify-content-end">
                  <span className={`${styles.escalate}`}>Escalated!</span>
                </div>
              )}
            </div>
            <div className="d-flex justify-content-end mt-1">
              <div>
                <Dropdown onToggle={setDropShow}>
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    as="div"
                    bsPrefix={`dropdown-toggle cursor-pointer ${styles.sendDrop} ${styles.dropdownToggle}`}
                  >
                    {`${getTimeFromNow({
                      date: new Date(
                        messageData.messageDateTime ??
                          messageData.messageDateTimeUTC,
                      ),
                      timeZone: userTimezone,
                    })}`}
                    <span>
                      {" "}
                      {dropShow ? (
                        <i className={`fa-solid fa-caret-up`}></i>
                      ) : (
                        <i className={`fa-solid fa-caret-down`}></i>
                      )}
                    </span>
                  </Dropdown.Toggle>
                  <DropDownMenuData
                    messageData={messageData}
                    labelBy={"dropdownMenuButton2"}
                  />
                </Dropdown>
              </div>
            </div>
          </div>
          <div className="ms-3 d-flex flex-column align-items-center">
            <img
              src={saufterLogo}
              className={`${styles.profileIcon}`}
              alt="saufter logo"
            />
            <Dropdown>
              <Dropdown.Toggle
                id="dropdown-basic"
                as="span"
                // ref={dropdownRef}
                bsPrefix={`dropdown me-1 ${styles.insta} d-flex align-items-center justify-content-center cursor-pointer`}
              >
                <img
                  src={slack}
                  alt="slack channel"
                  width={12}
                  height={12}
                />
              </Dropdown.Toggle>
              {messageData && (
                <DropDownMenuData
                  messageData={messageData}
                  labelBy={"dropdownMenuButton1"}
                />
              )}
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlackEscalateMsg;
