/**
 * MultiSelectTextInput Component
 *
 * This component represents a multi-select input for entering and displaying multiple tags
 * used in Audience configure form
 *
 * @author @yuvaraj-busibud
 */
import React, { useEffect, useMemo, useRef, useState } from "react";
import styles from "./MultiSelectTextInput.module.scss";

interface Props {
  placeholder: string;
  value: string | null | Array<string | number>;
  handleChange: (value: Array<string | number>) => void;
  type?: "text" | "email";
  error?: string | null;
  customStyleClass?: {
    tagInputContainer: string;
    tagsContainer: string;
    tag: string;
    removeTag: string;
    tagInput: string;
    errorText: string;
    errorBorder: string;
  };
}
const MultiSelectTextInput = ({
  placeholder,
  value,
  handleChange,
  type = "text",
  customStyleClass,
  error,
}: Props) => {
  // State for managing tags and the current input value
  const [tags, setTags] = useState<Array<string | number>>([]);

  // Reference to the input element for managing focus
  const inputRef = useRef<HTMLDivElement>(null);

  // Ref to track whether tags have changed during input
  const tagChanged = useRef(false);

  // Effect to update tags when the 'value' prop changes
  useEffect(() => {
    if (value && Array.isArray(value)) {
      setTags(value);
    } else {
      setTags([]);
    }
  }, [value]);

  // Effect to handle tag changes and call the 'handleChange' callback
  useEffect(() => {
    if (tagChanged.current) {
      handleChange(tags);
      tagChanged.current = false;
    }
  }, [tags]);

  // Handler for input change events
  const handleInputChange = (inputValue: string) => {
    if (
      inputValue.trim().length !== 0 &&
      inputValue.trim().length !== inputValue.length
    ) {
      // Splitting input value into an array of tags
      const tagsArray = inputValue.split(" ");
      const lastElement = tagsArray[tagsArray.length - 1];

      // Checking if there is more than one tag or if the last tag has leading/trailing spaces
      if (
        tagsArray.length > 1 ||
        lastElement?.trim().length !== lastElement.length
      ) {
        tagChanged.current = true;
        // Updating tags with unique values
        setTags((prev) => {
          let uniqueTags = new Set([
            ...prev,
            ...tagsArray.map((tag) => tag.trim()).filter(Boolean),
          ]);
          return Array.from(uniqueTags);
        });
        // Clearing the input field
        if (inputRef.current) {
          inputRef.current.innerHTML = "";
        }
      } else {
        // If there is only one tag, updating the innerHTML of the inputRef
        if (inputRef.current) {
          inputRef.current.innerHTML = lastElement.trim();
        }
      }
    }
  };

  // Handler to remove a tag
  const removeTag = (tag: string | number) => {
    tagChanged.current = true;
    // Updating tags by filtering out the removed tag
    setTags((prevTags) => prevTags.filter((t) => t !== tag));
    // Focusing on the input element
    inputRef.current?.focus();
  };

  const styledClass = useMemo(
    () => (customStyleClass ? customStyleClass : styles),
    [customStyleClass],
  );

  return (
    <div>
      <div
        className={`${styledClass.tagInputContainer} ${
          error
            ? customStyleClass
              ? customStyleClass.errorBorder
              : "border border-danger"
            : "mb-1"
        }`}
        onClick={() => {
          inputRef.current?.focus();
        }}
      >
        <div className={`${styledClass.tagsContainer}`}>
          {tags.map((tag, index) => (
            <div
              key={index}
              className={`${styledClass.tag}`}
            >
              {tag}
              <span
                className={`${styledClass.removeTag}`}
                onClick={() => removeTag(tag)}
              >
                &#x2715;
              </span>
            </div>
          ))}
          <div
            className={`${styledClass.tagInput}`}
            contentEditable
            ref={inputRef}
            onInput={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(event.target.innerText)
            }
            onBlur={(e) => handleInputChange(e.target.innerText + " ")}
            placeholder={tags.length ? "" : placeholder}
          >
            {/* {currentTag} */}
          </div>
        </div>
      </div>
      {error ? (
        <span className={`${styledClass.errorText} text-danger`}>{error}</span>
      ) : null}
    </div>
  );
};

export default MultiSelectTextInput;
