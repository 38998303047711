import styles from "./ShowIntegration.module.scss";
import { EIntegrationTypes } from "src/enums/EIntegrationTypes";
import shopifyLogo from "src/assets/images/shopLogo.png";
import fbLogo from "src/assets/images/browseFb.png";
import instaLogo from "src/assets/images/browseInsta.png";
import { useNavigate } from "react-router-dom";

interface ILogoRouteList {
  integrationLogo: Array<any>;
  integrationRoute: string;
}

const ShowIntegration = ({ myIntegrationItemData }: any) => {
  const navigate = useNavigate();

  const logoRouteList: { [key: string | number]: ILogoRouteList } = {
    [EIntegrationTypes.INTEGRATION_TYPE_SHOPIFY]: {
      integrationLogo: [shopifyLogo],
      integrationRoute: "/settings/integrations/shopify",
    },
    [EIntegrationTypes.INTEGRATION_TYPE_FACEBOOK]: {
      integrationLogo: [fbLogo, instaLogo],
      integrationRoute: "/settings/integrations/facebook",
    },
  };

  return (
    <div
      className={`d-flex justify-content-between py-1 px-3 ${styles.showintMain}`}
      onClick={(e) => {
        navigate(
          logoRouteList[myIntegrationItemData.integrationTypeId]
            .integrationRoute
        );
      }}
    >
      <div>
        {logoRouteList[myIntegrationItemData.integrationTypeId] &&
          logoRouteList[myIntegrationItemData.integrationTypeId]
            .integrationLogo &&
          logoRouteList[
            myIntegrationItemData.integrationTypeId
          ].integrationLogo.map((logo) => {
            return <img className={`me-2 ${styles.shopifyImg}`} src={logo} />;
          })}

        <span className={styles.integrationTypeName}>
          {myIntegrationItemData.integrationTypeName}
        </span>
      </div>
      <div>
        <span>
          <i className="fa-solid fa-angle-right"></i>
        </span>
      </div>
    </div>
  );
};
export default ShowIntegration;
