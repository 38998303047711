import { useCallback, useMemo, useState } from "react";
import { Spinner } from "react-bootstrap";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import processCardService from "src/services/Billing/processCard.service";
import styles from "./SuccessModal.module.scss";

export type BillingStatus =
  | "payment_success"
  | "card_success"
  | "payment_cancel"
  | "card_cancel"
  | "payment_failed"
  | "card_failed"
  | "upgrade_success"
  | "upgrade_failed"
  | "upgrade_cancel";

interface Props {
  status: BillingStatus | "";
  isSuccess: boolean;
  onHide: (status?: "payment" | "upgrade") => void;
}

/**
 * Modal Component for showing Success Text in Billing UI
 */
const SuccessModal = ({ onHide, status, isSuccess }: Props) => {
  const message = useMemo(() => {
    switch (status) {
      case "payment_success":
      case "upgrade_success":
        return "Payment Successful !";
      case "card_success":
        return "Card Details Saved Successfully !";
      case "payment_cancel":
      case "upgrade_cancel":
        return "Payment Cancelled";
      case "card_cancel":
        return "Changes to your card details have been cancelled.";
      case "payment_failed":
      case "upgrade_failed":
        return "Payment failed";
      case "card_failed":
        return "Card details could not be saved.";
      case "":
      default:
        return status;
    }
  }, [status]);

  const [loader, setLoader] = useState(false);

  const handleTryAgain = useCallback(async () => {
    // Process Card Details
    if (status === "card_failed") {
      try {
        setLoader(true);
        await processCardService();
        onHide();
      } catch (e) {
        const err = e as Error;
        pushTheToast({
          position: "top-right",
          text: err?.message ?? "Cannot Edit Billing Details at the moment",
          type: "danger",
        });
      } finally {
        setLoader(false);
      }
    } else if (status === "upgrade_failed") {
      // Open Upgrade Plan Modal
      onHide("upgrade");
    }
  }, [onHide, status]);

  return (
    <div>
      {/* Close Button */}
      <div>
        <span
          className={`${styles.closeModal}`}
          onClick={() => onHide()}
        >
          <i className="fa-solid fa-xmark"></i>
        </span>
      </div>

      {/* Success UI */}
      <div className={`${styles.successWrapper}`}>
        <div className="text-center">
          {/* Check Mark */}
          <div
            className={`${styles.successDiv} ${
              !isSuccess && status !== "payment_failed" && styles.cautionDiv
            }`}
          >
            <span className="d-flex align-items-center justify-content-center">
              {" "}
              {status === "payment_failed" ? (
                <i className="fa-solid fa-xmark"></i>
              ) : isSuccess ? (
                <i className="fa-solid fa-check"></i>
              ) : (
                <i className="fa-solid fa-triangle-exclamation"></i>
              )}
            </span>
          </div>

          {/* Success Text */}
          <p className={`mt-4 ${styles.successHead}`}>{message}</p>

          {/* Try Again Button */}
          {(status === "card_failed" || status === "upgrade_failed") && (
            <button
              className={`${styles.tryBtn}`}
              onClick={handleTryAgain}
              disabled={loader}
            >
              Try again
              {loader && (
                <Spinner
                  size="sm"
                  animation="border"
                  className="mx-2"
                />
              )}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default SuccessModal;
