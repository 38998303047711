import { PayloadAction } from "@reduxjs/toolkit";
import {
  initialState,
  IBrandSettings,
  ModifiedBrandUser,
} from "./brandSettings.slice";
import { BrandData } from "src/services/Settings/Brands/getAllBrands";

const resetBrandSettings = (state: IBrandSettings) => {
  state = { ...initialState };
  return state;
};

const resetBrandData = (state: IBrandSettings) => {
  state.brands = {};
  state.brandIds = [];
  state.cachedData = {};
  state.currentPage = 0;
  state.metaData = {
    count: 0,
    total: 0,
  };
  state.brandDataAjaxStatus = initialState.brandDataAjaxStatus;
};

const setBrandSearchValue = (
  state: IBrandSettings,
  { payload }: { payload: { searchValue: string } },
) => {
  state.filters.searchValue = payload.searchValue;
};

const setBrandCurrentPage = (
  state: IBrandSettings,
  { payload }: { payload: number },
) => {
  state.currentPage = payload;
  return state;
};

const resetBrandListPagination = (state: IBrandSettings) => {
  state.currentPage = 0;
  state.totalPages = 0;
  return state;
};

const resetBrandUserListPagination = (state: IBrandSettings) => {
  state.usersMeta.totalPages = 0;
  state.usersMeta.userCurrentPage = 0;
  return state;
};

const resetBrandGeneralUserList = (state: IBrandSettings) => {
  state.userIds = [];
  state.users = {};
  state.usersMeta.count = 0;
  state.usersMeta.total = 0;
  state.usersMeta.totalPages = 0;
  state.usersMeta.userCurrentPage = 0;
  state.childModalIsOpened = false;
  return state;
};

const resetBrandAgentList = (state: IBrandSettings) => {
  state.brandAgentIds = [];
  state.brandAgents = {};
  state.brandAgentMeta.count = 0;
  state.brandAgentMeta.total = 0;
  return state;
};

const setBrandUserCurrentPage = (
  state: IBrandSettings,
  { payload }: { payload: number },
) => {
  state.usersMeta.userCurrentPage = payload;
  return state;
};

const setBrandUserSearchValue = (
  state: IBrandSettings,
  { payload }: { payload: { searchValue: string } },
) => {
  state.userSearchValue = payload.searchValue;
};

const selectUserForBulkSelect = (
  state: IBrandSettings,
  action: PayloadAction<string | number>,
) => {
  const agentId = action.payload;
  if (!state.bulkSelectedUserIds.includes(agentId)) {
    state.bulkSelectedUserIds.push(agentId);
  }
};

const removeUserFromBulkSelect = (
  state: IBrandSettings,
  action: PayloadAction<string | number>,
) => {
  const removeUserId = action.payload;
  state.bulkSelectedUserIds = state.bulkSelectedUserIds.filter(
    (userId) => userId !== removeUserId,
  );
};

const selectAllUsersFromBulkSelect = (
  state: IBrandSettings,
  {
    payload = { selectAdmins: false },
  }: { payload: { selectAdmins?: boolean } | undefined },
) => {
  const userIdsWithoutAdmins = payload.selectAdmins
    ? state.userIds
    : state.userIds.filter(
        (userId) => state.users[userId].roleName !== "Admin",
      );
  state.bulkSelectedUserIds = userIdsWithoutAdmins;
};

const removeAllUsersFromBulkSelect = (state: IBrandSettings) => {
  state.bulkSelectedUserIds = [];
};

const setActiveBrandId = (
  state: IBrandSettings,
  { payload }: { payload: number | string },
) => {
  state.activeBrandId = payload;
  return state;
};

const addUserToModifiedUsers = (
  state: IBrandSettings,
  { payload }: { payload: { [id: number | string]: ModifiedBrandUser } },
) => {
  state.modifiedUsersList = { ...state.modifiedUsersList, ...payload };

  return state;
};

const resetModifiedUsersList = (state: IBrandSettings) => {
  state.modifiedUsersList = {};

  return state;
};

const setChildModal = (
  state: IBrandSettings,
  { payload }: { payload: boolean },
) => {
  state.childModalIsOpened = payload;

  return state;
};

const setBrandOutboundEmailSearchValue = (
  state: IBrandSettings,
  { payload }: { payload: { searchValue: string } },
) => {
  state.outBoundEmailSearchValue = payload.searchValue;
};

const resetOutboundEmailList = (state: IBrandSettings) => {
  state.outboundMails = {};
  state.outboundMailsIds = [];
  state.metaData = { count: 0, total: 0 };
};

const setBrandCachedPage = (
  state: IBrandSettings,
  { payload }: { payload: { pageNo: number } },
) => {
  state.currentPage = payload.pageNo;
  if (state.cachedData[payload.pageNo]) {
    state.brandIds = state.cachedData[payload.pageNo];
  }
};

const setBrandData = (
  state: IBrandSettings,
  { payload }: { payload: { brandId: number | string; brandData: BrandData } },
) => {
  if (payload.brandId) {
    state.brands[payload.brandId] = payload.brandData;
  }
};
export default {
  resetBrandSettings,
  setBrandCurrentPage,
  resetBrandData,
  setBrandSearchValue,
  resetBrandListPagination,
  resetBrandUserListPagination,
  resetBrandGeneralUserList,
  setBrandUserCurrentPage,
  setBrandUserSearchValue,
  removeUserFromBulkSelect,
  selectAllUsersFromBulkSelect,
  removeAllUsersFromBulkSelect,
  setActiveBrandId,
  resetBrandAgentList,
  addUserToModifiedUsers,
  resetModifiedUsersList,
  selectUserForBulkSelect,
  setChildModal,
  setBrandOutboundEmailSearchValue,
  resetOutboundEmailList,
  setBrandCachedPage,
  setBrandData,
};
