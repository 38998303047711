import { useEffect, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import styles from "./ComponentStyles.module.scss";

function Counter(props: any) {
  const [showPopover, setShowPopover] = useState(true);

  useEffect(() => {
    if (props.error) {
      setShowPopover(true);
    } else {
      setShowPopover(false);
    }
  }, [props.error]);

  const popover =
    props.error && props.error.length > 0 && props.type !== "days" ? (
      <Popover
        id="popover-basic"
        className="rounded-3"
      >
        <Popover.Body className="d-flex flex-row">
          <div className="border px-2 bg-warning text-white me-1 rounded-3 ">
            !
          </div>
          <div className="mt-1">{props.error}</div>
        </Popover.Body>
      </Popover>
    ) : (
      <></>
    );

  return (
    <>
      {props.type === "days" ? (
        <div className={`ms-lg-3 ${styles.lastText} align-items-center`}>
          Last
        </div>
      ) : null}

      <OverlayTrigger
        show={showPopover}
        placement="bottom"
        overlay={popover}
        rootClose={true}
        onToggle={(isShown) => {
          // if (props.error && showPopover) {
          setShowPopover(isShown);
          // }
        }}
        // onExit={(e: any) => {
        //   setShowPopover(false);
        // }}
        rootCloseEvent="mousedown"
      >
        <div>
          <div
            className={`d-flex justify-content-between align-items-center py-1 ms-lg-3 ${
              styles.counterWrap
            } ${
              props.error &&
              props.error.length > 0 &&
              props.type === "days" &&
              "border-danger"
            }`}
          >
            {props.count > 1 ? (
              <div
                className="px-3"
                role="button"
                onClick={(e) => {
                  props.onChange(props.count - 1);
                }}
              >
                <div
                  className={`d-flex align-items-center justify-content-center ${styles.addMore}`}
                >
                  -
                </div>
              </div>
            ) : (
              <div className="px-3">
                <div
                  className={`d-flex align-items-center justify-content-center ${styles.disableBtn}`}
                >
                  -
                </div>
              </div>
            )}

            <div className={` px-3 ${styles.count}`}>
              {/* Display the count with a leading zero if it is a single digit */}
              {props.count < 10 ? `0${props.count}` : props.count}
            </div>

            <div
              className="px-3"
              role="button"
              onClick={(e) => {
                props.onChange(props.count + 1);
              }}
            >
              <div
                className={`d-flex align-items-center justify-content-center ${styles.addMore}`}
              >
                +
              </div>
            </div>
          </div>
          <span className={styles.errorText}>
            {props.error && props.error.length > 0 && props.error}
          </span>
        </div>
      </OverlayTrigger>

      {/* <div className="ms-lg-3 mt-2">
        {props.variableName === "Order Date" ||
        props.variableName === "Order Delivery Date"
          ? "days"
          : "numbers"}
      </div> */}
    </>
  );
}

export default Counter;
