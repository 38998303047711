import styles from "./Step2.module.scss";

/**
 * Component for showing Step 2 of Modify Order automation steps
 */
function Step2() {
  return (
    <>
      <div className={`${styles.stepWrapper}`}>
        <div
          className={`${styles.stepsContent} text-center justify-content-center  align-items-center`}
        >
          <p className={`d-none d-md-block px-2 px-md-5`}>
            <span className={`${styles.text}`}>
              Whenever a customer submits a support ticket through your
              platform, they will go through a series of steps that guide them
              in providing essential information.
              <p>
                Press <span className={`${styles.nextStep}`}> "Next Step"</span>{" "}
                to continue.
              </p>
            </span>
          </p>
          <div className={`d-block d-md-none px-2 px-md-5`}>
            <span className={`${styles.text}`}>
              Whenever a customer requests Modify or Cancel Order through your
              live chat widget they will go through a number of steps on their
              own that will help them initiate a return.{" "}
              <p>
                Press <span className={`${styles.nextStep}`}> "Next Step"</span>
                to continue.
              </p>
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default Step2;
