import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AJAXSTATUS } from "src/globals/constants";
import { fetchAllOrdersFulfilled } from "./allOrders.extraReducers";
import allOrdersThunks from "./allOrders.thunks";
import allOrdersReducers from "./allOrders.reducers";

// to fetch all the orders
export const fetchAllOrders = createAsyncThunk(
  "allOrders/fetchAllOrders",
  allOrdersThunks.fetchAllOrdersThunk
);

interface OrderTotal {
  amount: number;
  currencyCode: string;
}

export interface Order {
  orderId: number;
  orderName: string;
  createdAt: Date;
  customerName: string;
  orderTotal: OrderTotal;
  paymentStatus: string;
  fulfillmentStatus: string;
  items: number;
  deliveryMethod: null;
  tags: [];
  isChecked?: boolean;
}

interface Customer {
  id: string | number;
  name: string;
  email: string;
  phone: string;
}

export interface IGetAllOrders {
  customer: Customer;
  orders: Array<Order>;
  total: number | null;
  count: number;
  currentPage: number;
  totalPage: number | null;
  serachTerm: string;
  sortBy: string;
  isDesc: boolean;
  fetchOrdersLimit: number;
  fetchAllOrdersAjaxStatus: AJAXSTATUS;
  error: boolean;
}

export const initialState: IGetAllOrders = {
  customer: {
    id: "",
    name: "",
    email: "",
    phone: "",
  },
  orders: [],
  total: null,
  count: 0,
  currentPage: 1,
  // currentPage: 0,
  totalPage: null,
  serachTerm: "",
  sortBy: "ORDER_DATE",
  isDesc: true,
  fetchOrdersLimit: 10,
  fetchAllOrdersAjaxStatus: "pending",
  error: false,
};

const allOrdersSlice = createSlice({
  name: "allOrders",
  initialState: initialState,
  reducers: allOrdersReducers,
  extraReducers: (builder) => {
    builder.addCase(fetchAllOrders.pending, (state) => {
      state.fetchAllOrdersAjaxStatus = "pending";
    });
    builder.addCase(fetchAllOrders.fulfilled, fetchAllOrdersFulfilled);
    builder.addCase(fetchAllOrders.rejected, (state, action) => {
      state.fetchAllOrdersAjaxStatus = "rejected";
    });
  },
});

export const {
  resetAllOrders,
  setCurrentPage,
  setSearchTerm,
  resetAllOrdersSpecificData,
  setSortBy,
} = allOrdersSlice.actions;

export default allOrdersSlice.reducer;
