import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  bulkAddTicketTags,
  getSelectedTagsIdsSelector,
} from "src/store/slices/ticketTags/ticketTags.slice";
import useResetBulkActions from "src/hooks/useResetBulkActions";
import BaseFooterButtonLight from "src/components/BulkActionComponents/commons/BaseFooterButtonLight";
import BaseFooterButtonSolid from "src/components/BulkActionComponents/commons/BaseFooterButtonSolid";
import styles from "./ModalFooter.module.scss";

const ModalFooter = ({ setModalShow }: any) => {
  const resetBulkActions = useResetBulkActions();
  const selectedTagsIds = useAppSelector(getSelectedTagsIdsSelector);
  const bulkAddTicketTagsState = useAppSelector(
    (state) => state.ticketTags.bulkAddTicketTagsState
  );
  const { selectedAllTickets } = useAppSelector(
    (state) => state.ticket.bulkActionFilters
  );
  const dispatch = useAppDispatch();

  const handleOnClickAddTicketTags = (e: any) => {
    e.preventDefault();
    dispatch(bulkAddTicketTags());
  };

  const handleOnClickExit = (e: any) => {
    e.preventDefault();
    resetBulkActions();
  };

  const handleOnClickAddMore = (e: any) => {
    e.preventDefault();
    setModalShow(false);
    resetBulkActions(); //resetting bulk action filters, in new select all filter implementation we can't let user add more cause select all works based on filters
  };

  return (
    <>
      {bulkAddTicketTagsState === "fulfilled" ? (
        <div
          className={`w-100 d-flex justify-content-between`}
          id="addTagModalFooter"
        >
          <BaseFooterButtonLight
            onClick={handleOnClickExit}
            id="addTagModalExitBtn"
          >
            Exit Bulk actions
          </BaseFooterButtonLight>

          <BaseFooterButtonSolid
            onClick={handleOnClickAddMore}
            id="addTagModalAddMoreBtn"
          >
            Add more
          </BaseFooterButtonSolid>
        </div>
      ) : (
        <BaseFooterButtonSolid
          onClick={handleOnClickAddTicketTags}
          style={
            selectedTagsIds.length === 0 && selectedAllTickets === false
              ? { opacity: "50%" }
              : undefined
          }
          disabled={
            selectedTagsIds.length === 0 && selectedAllTickets === false
              ? true
              : false
          } //added condition to check for selectAll flag and selectTickectIds count to enable/disable button
          id="addTagModalBtn"
        >
          Add
        </BaseFooterButtonSolid>
      )}
    </>
  );
};

export default ModalFooter;
