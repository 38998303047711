import {axiosJSON} from "src/globals/axiosEndPoints";
import {IS_TEST_ACTIVE} from "src/globals/constants";

export interface CannedResponse {
  cannedResponseId: string | number;
  integrationId: string | number;
  shortcut: string;
  messageText: string;
  title: string;
}

export interface getAllCannedResponse {
  cannedResponseIdList: Array<number | string>;
  cannedResponses: {[cannedResponseId: number | string]: CannedResponse};
  metaData: {
    total: number | null;
    count: number;
  };
}

export interface GetAllCannedResponseParams {
  integrationId: string | number;
  start: number;
  limit: number;
  searchTerm?: string;
  searchShortCutOnly?: boolean;
}

const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/cannedResponse/getAll`
  : "/api/chatSetting/cannedResponse/getAll";

export async function getAllCannedResponse(params: GetAllCannedResponseParams) {
  const {data: res} = await axiosJSON.post(apiEndPoint, params);

  if (res.error === true) {
    throw new Error(res.message as string);
  }
  const result: getAllCannedResponse = {
    cannedResponseIdList: [],
    cannedResponses: {},
    metaData: {total: res.totalCount ?? 0, count: res.count ?? 0},
  };

  res.data.map((cannedResponse: CannedResponse) => {
    result.cannedResponseIdList.push(cannedResponse.cannedResponseId);
    result.cannedResponses[cannedResponse.cannedResponseId] = cannedResponse;
  });

  return result;
}
