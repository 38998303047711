import styles from "./UpdaBotProfile.module.scss";
import planUpgrade from "src/assets/images/planUpgrade.png";
const UpdaBotProfile = ({ showModal }: { showModal: () => void }) => {
  return (
    <div className={`text-center ${styles.upgradeWrapper}`}>
      <div>
        <h2 className={`${styles.heading}`}>
          Want to add a different bot trained for a specific audience? Upgrade
          to add multiple profiles.
        </h2>
        <button
          className={`mt-2 ${styles.upgradeBtn}`}
          onClick={showModal}
        >
          <span>
            <img
              src={planUpgrade}
              alt="UpgradeButton"
              className={`${styles.upgradeImg}`}
            />
          </span>
          <span className="ps-2">Upgrade plan</span>
        </button>
      </div>
    </div>
  );
};

export default UpdaBotProfile;
