import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { axiosFormData, axiosJSON } from "src/globals/axiosEndPoints";

export interface AddEmailRes {
  emailIntegrationId: number;
  defaultEmail: string;
  emailToBeAddedName: string;
  emailToBeAddedAddress: string;
  isEmailAdded: boolean;
  isEmailVerified: boolean;
  emailIntegrationChannel: string;
  isIncommingGmailVerified?: boolean;
}

export interface AddEmailParam {
  emailType: string;
  addressName: string;
  emailAddress: string;
}

export async function addEmail(params: AddEmailParam) {
  const { data } = await axiosJSON.post(
    `/api/setting/integration/addEmailIntegration`,
    params,
  );
  // const data: any = await addEmailPromise(params);
  // console.log("Delete attachment:: res:: ", JSON.stringify(data));
  if (data.err === true || data.error === true) {
    pushTheToast({
      type: "danger",
      text: data.msg,
      position: "top-right",
    });
    throw new Error(data.msg as string);
  }
  return data.data as AddEmailRes;
}

// let addEmailPromise = function (params: AddEmailParam) {
//   return new Promise(function (resolve, reject) {
//     if (params) {
//       setTimeout(function () {
//         resolve({
//           defaultEmail: params.emailAddress,
//           addedEmail: params.emailAddress,
//         });
//       }, 2000);
//     } else {
//       reject(new Error("Something is not right!"));
//     }
//   });
// };
