import React, { useCallback, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import {
  addArticleIdToFeatured,
  isArticleSelectedSelector,
  removeArticleIdRemoveFeatured,
} from "src/store/slices/liveChatSetting/chatWidgetSetting/knowledgeBased/knowledgeBasedSetting.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import styles from "./ArticleNameRow.module.scss";
import { useParams } from "react-router-dom";
interface Props {
  article: any;
}
const ArticleNameRow = ({ article }: Props) => {
  const dispatch = useAppDispatch();

  const isArticleSelected = useAppSelector((state) =>
    isArticleSelectedSelector(state, article.articleId)
  );
  const [showOverlay, setShowOverlay] = useState<boolean>(false);

  const { featuredArticleSelectLimit, activeFeaturedArticle } = useAppSelector(
    (state) => state.knowledgeBasedSettings
  );
  const handleOnClick = useCallback(() => {
    if (isArticleSelected) {
      dispatch(removeArticleIdRemoveFeatured({ articleId: article.articleId }));
    } else {
      if (
        Object.values(activeFeaturedArticle.featuredArticles).length <
        featuredArticleSelectLimit
      ) {
        dispatch(
          addArticleIdToFeatured({
            articleId: article.articleId,
            articleName: article.articleName,
            shortTitle: article.shortTitle,
          })
        );
      } else {
        setShowOverlay(true);
      }
    }
  }, [isArticleSelected, article, activeFeaturedArticle.featuredArticles]);

  const { integrationId } = useParams();
  const pageIntegrationId = integrationId
    ? decodeURIComponent(integrationId)
    : "";

  const handleViewArticle = useCallback(() => {
    window.open(
      `/knowledge-base/${pageIntegrationId}/articles/${article.articleId}`
    );
  }, [article]);

  return (
    <div>
      <div className="form-check mb-2" onBlur={() => setShowOverlay(false)}>
        <OverlayTrigger
          placement="bottom"
          trigger={"focus"}
          overlay={
            showOverlay === true ? (
              <Tooltip className={`text-start`}>
                <div className="text-start p-2">
                  <span className="d-inline-block">
                    {" "}
                    Only 4 articles can be added as featured . Remove any other
                    article to add this one{" "}
                  </span>
                </div>
              </Tooltip>
            ) : (
              <></>
            )
          }
        >
          {({ ref, ...triggerHandler }) => (
            <>
            <div className="d-flex align-items-center">
              <div {...triggerHandler} ref={ref} className="mr-1">
                <input
                  className={`form-check-input ${styles.check}`}
                  type="checkbox"
                  value=""
                  id={"selectArticle" + article.articleId}
                  checked={isArticleSelected}
                  onChange={handleOnClick}
                />
              </div>
              <label
                className={`form-check-label ${styles.formLabel} mb-0`} // Added mb-0 to remove bottom margin
                htmlFor={"selectArticle" + article.articleId}
              >
                {article.articleName}
                {/* <span className={`ps-2 ${styles.viewText}`}>View</span> */}
              </label>

              <span
                className={`${styles.viewButton} ms-4`}
                role="button"
                onClick={handleViewArticle}
              >
                View
              </span>
            </div>
            </>
          )}
        </OverlayTrigger>
      </div>
    </div>
  );
};
export default ArticleNameRow;
