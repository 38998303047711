import { TheSendingFormat } from "../../../NotificationBox/Children/SendingFormat/SendingFormat";
import { useQuery } from "@tanstack/react-query";
import { useState, useCallback } from "react";
import getSendingFormatService from "src/services/report/getSendingFormat.service";
import {
  reportNotificationsActions,
  useNotifications,
} from "../../../../hooks/notifications/useNotifications";
import styles from "../../EditNotification.module.scss";

const useEditSendingFormat = () => {
  const { data: allSendingFormat, status } = useQuery(
    ["report/getSendingFormatService"],
    {
      queryFn: getSendingFormatService,
      staleTime: 600000,
      cacheTime: 600000,
    }
  );

  const [formatLoading, setFormatLoading] = useState(false);

  const { reportNotifications, dispatch } = useNotifications();

  const changeSendingFormat = useCallback((e: any) => {
    dispatch([
      reportNotificationsActions.setEditingNotificationData,
      {
        sendingFormatId: e.currentTarget.getAttribute("data-sending-format-id"),
      },
    ]);
  }, []);

  return {
    reportNotifications,
    allSendingFormat,
    formatLoading,
    changeSendingFormat,
  };
};

function EditSendingFormat({ showError }: { showError: boolean }) {
  const {
    reportNotifications,
    allSendingFormat,
    formatLoading,
    changeSendingFormat,
  } = useEditSendingFormat();

  return (
    <div className="mt-0 d-flex flex-column">
      <div className="d-flex flex-wrap align-items-center">
        <span className={`pe-2 ${styles.sendText}`}>Send</span>
        <div>
          {reportNotifications.editingNotificationData ? (
            <TheSendingFormat
              changeSendingFormat={changeSendingFormat}
              formatLoading={formatLoading}
              notificationData={reportNotifications.editingNotificationData}
              allSendingFormat={allSendingFormat}
            />
          ) : (
            <></>
          )}
        </div>
        <span className={`ps-2 ${styles.sendText}`}>in the alert</span>
      </div>
      {showError &&
        !reportNotifications.editingNotificationData?.sendingFormatId && (
          <div
            className="mb-1 text-danger"
            style={{ fontSize: "13px", fontWeight: "500" }}
          >
            * Please select sending format
          </div>
        )}
    </div>
  );
}

export default EditSendingFormat;
