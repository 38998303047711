import {
  EReturnAutomationHiddenKeys,
  EReturnAutomationQuestionKeys,
} from "src/enums/EReturnAutomationQuestionKeys";
import { RootState } from "src/store/store";
import {
  IAntiReturnStategiesOption,
  IIsConfigured,
  IResolutionOffersValue,
  IReturnReason,
} from "./retrunExchange.slice";

export const step9ConfigErrorCount = (state: RootState) => {
  let errorCount = 0;
  state.step9Configuration.step9Configuration.orderTypes.forEach(
    (orderType) => {
      if (orderType.errorCount !== 0) {
        errorCount += 1;
      }
    },
  );

  return errorCount;
};

export const step10ConfigErrorCount = (state: RootState) => {
  let errorCount = 0;
  state.step10Configuration.itemTypes.forEach((item) => {
    if (item.errorCount !== 0) {
      errorCount += 1;
    }
  });

  return errorCount;
};

export const step11ConfigErrorCount = (state: RootState) => {
  let errorCount = 0;
  state.step11Config.returnTypes.forEach((returnType) => {
    if (returnType.errorCount !== 0) {
      errorCount += 1;
    }
  });

  return errorCount;
};

export const step12ConfigErrorCount = (state: RootState) => {
  let errorCount = 0;
  state.step12Config.reasonTypes.forEach((reason) => {
    if (reason.errorCount !== 0) {
      errorCount += 1;
    }
  });

  return errorCount;
};

export const step14ConfigErrorCount = (state: RootState) => {
  let errorCount = 0;
  state.step14Config.returnMethods.forEach((reason) => {
    if (reason.errorCount !== 0) {
      errorCount += 1;
    }
  });

  return errorCount;
};

export const step15ConfigErrorCount = (state: RootState) => {
  let errorCount = 0;
  state.step15Configuration.additionalCharges.forEach((reason) => {
    if (reason.errorCount !== 0) {
      errorCount += 1;
    }
  });

  return errorCount;
};

// helper function for get the status if the question is answered
export const isQuestionAnswered = (state: RootState, questionKey: string) => {
  //getting all the questions in single object
  const questions = {
    ...state.returnExchange.step9.stepData.stepQuestions,
    ...state.returnExchange.step10.stepData.stepQuestions,
    ...state.returnExchange.step11.stepData.stepQuestions,
    ...state.returnExchange.step12.stepData.stepQuestions,
    ...state.returnExchange.step13.stepData.stepQuestions,
    ...state.returnExchange.step14.stepData.stepQuestions,
    ...state.returnExchange.step15.stepData.stepQuestions,
  };

  //checking if the question key exists in the questions object
  if (questions[questionKey] === undefined) {
    return false; //not exist, return false
  }

  //getting the question for the passed key
  const question = questions[questionKey];

  //vaidating if the key is answered
  switch (questionKey) {
    // question 1
    case EReturnAutomationQuestionKeys.returnWindow:
      let returnWindow: any = question.value;

      if (returnWindow && returnWindow.days && returnWindow.day_type) {
        // question is answered
        return true;
      }
      break;

    // question 2 , new question
    case EReturnAutomationQuestionKeys.returnNotEligibleResponseMessage:
      // for question.value trim need to be applied
      if (
        (question.value as string) &&
        (question.value as string).trim().length > 0
      ) {
        // question is answered
        return true;
      }

      break;

    // question 3
    case EReturnAutomationQuestionKeys.isReturnPolicySameForAllOrders:
      if (question.value === true || question.value === false) {
        // question is answered
        return true;
      }

      break;

    // question 4
    case EReturnAutomationQuestionKeys.isReturnPolicySameForAllItem:
      if (question.value === true || question.value === false) {
        // show question two
        return true;
      }
      break;

    // question 4, not needed in v3
    case EReturnAutomationQuestionKeys.canItemNotEligibleForReturn:
      if (question.value === true || question.value === false) {
        return true;
      }
      break;

    // question 5
    case EReturnAutomationQuestionKeys.resolutionOffer:
      let resolutionOffers: any = question.value;
      if (
        resolutionOffers &&
        (resolutionOffers.exchangeWithOtherItems ||
          resolutionOffers.exchangeWithSameItems ||
          resolutionOffers.refundToOrigialPaymentMethod ||
          resolutionOffers.refundToStoreCredits)
      ) {
        return true;
      }
      break;

    // sub question of question 5
    case EReturnAutomationQuestionKeys.resolutionOfferMessage:
      let resolutionOffersData: IResolutionOffersValue =
        question.value as IResolutionOffersValue;
      if (
        resolutionOffersData &&
        resolutionOffersData.resolutionOfferMessage &&
        resolutionOffersData.resolutionOfferMessage.value != null &&
        resolutionOffersData.resolutionOfferMessage.value.length !== 0
      ) {
        return true;
      }
      break;

    // question 6
    case EReturnAutomationQuestionKeys.isResolutionOfferedSameForAllOrders:
      if (question.value === true || question.value === false) {
        return true;
      }
      break;

    // question 7
    case EReturnAutomationQuestionKeys.selectReturnReason:
      if (question.value as string) {
        return true;
      }
      break;

    // question 7
    case EReturnAutomationQuestionKeys.returnReasonList:
      if (question.value as Array<IReturnReason>) {
        return true;
      }
      break;

    // question 8
    case EReturnAutomationQuestionKeys.isReturnReasonSameForAll:
      if (question.value === true || question.value === false) {
        return true;
      }
      break;

    case EReturnAutomationQuestionKeys.returnMethodForMajorityOfOrders:
      if (question.value as string) {
        return true;
      }
      break;

    // question 11 is answered or not
    case EReturnAutomationQuestionKeys.returnMethodDisplayMessage:
      if (question.value as string) {
        return true;
      }
      break;

    // question 12 is answered or not
    case EReturnAutomationQuestionKeys.isReturnMethodSameForAllOrders:
      if (question.value === true || question.value === false) {
        return true;
      }
      break;

    // question 13
    case EReturnAutomationQuestionKeys.isRestockingFeeChargedForMajorityOrders:
      if (question.value === true || question.value === false) {
        return true;
      }
      break;

    case EReturnAutomationQuestionKeys.usuallyChargedRestockingFee:
      if (question.value != null && (question.value as any)?.price !== 0) {
        return true;
      }
      break;

    case EReturnAutomationQuestionKeys.restockingFeeDisplayMessage:
      if (question.value) {
        return true;
      }
      break;

    case EReturnAutomationQuestionKeys.isRestockingFeeSameForAll:
      if (question.value === true || question.value === false) {
        return true;
      }
      break;

    // new questions in v4
    // question 10
    case EReturnAutomationQuestionKeys.antiReturnStrategies:
      let antiReturnStrategies: IAntiReturnStategiesOption =
        question.value as IAntiReturnStategiesOption;
      if (
        antiReturnStrategies &&
        (antiReturnStrategies.offerAnExchange ||
          antiReturnStrategies.offerSomeSuggestion ||
          antiReturnStrategies.offerSuggestionExtendReturnDeadline ||
          antiReturnStrategies.offerDiscountOnFutureOrder)
      ) {
        return true;
      }
      break;

    // question 11
    case EReturnAutomationQuestionKeys.configureAntiReturnStrategies:
      let configureAntiReturnStrategies: IIsConfigured =
        question.value as IIsConfigured;
      if (
        configureAntiReturnStrategies &&
        configureAntiReturnStrategies.isConfigured
      ) {
        return true;
      }
      break;

    // question 12
    case EReturnAutomationQuestionKeys.isReturnReasonOrFollowUpQuestionsSame:
      if (question.value === true || question.value === false) {
        return true;
      }
      break;
  }
  return false;
};

export const isStepTransferedToAgent = (state: RootState, stepId: number) => {
  let allStepIds: number[] = [9, 10, 11, 12, 13, 14];
  let transfered = false;

  allStepIds.forEach((iteratedStepId) => {
    if (iteratedStepId < stepId) {
      if (
        iteratedStepId === 9 &&
        state.returnExchange.step9.stepData.transfer_to_agent === true
      ) {
        transfered = true;
        return;
      } else if (
        iteratedStepId === 12 &&
        state.returnExchange.step12.stepData.transfer_to_agent === true
      ) {
        transfered = true;
        return;
      } else if (
        iteratedStepId === 11 &&
        state.returnExchange.step11.stepData.transfer_to_agent === true
      ) {
        transfered = true;
        return;
      } else if (
        iteratedStepId === 13 &&
        state.returnExchange.step13.stepData.transfer_to_agent === true
      ) {
        transfered = true;
        return;
      } else if (
        iteratedStepId === 14 &&
        state.returnExchange.step14.stepData.transfer_to_agent === true
      ) {
        transfered = true;
        return;
      } else if (
        iteratedStepId === 15 &&
        state.returnExchange.step15.stepData.transfer_to_agent === true
      ) {
        transfered = true;
        return;
      }
    }
  });

  return transfered;
};

const isSegment1Completed = (state: RootState) => {
  const questions = {
    ...state.returnExchange.step9.stepData.stepQuestions,
  };

  let question = questions[EReturnAutomationQuestionKeys.returnWindow];
  // question 1
  let returnWindow: any = question?.value;

  if (returnWindow && returnWindow.days && returnWindow.day_type) {
    // question is answered
  } else {
    return false;
  }

  // question 2 , new question
  question =
    questions[EReturnAutomationQuestionKeys.returnNotEligibleResponseMessage];
  if (question?.value) {
    // question is answered
  } else {
    return false;
  }

  // question 3
  question =
    questions[EReturnAutomationQuestionKeys.isReturnPolicySameForAllOrders];

  if (!EReturnAutomationHiddenKeys.includes(question.key)) {
    if (question?.value === true || question?.value === false) {
      // question is answered

      if (
        question.value === false &&
        state.returnExchange.step9.stepData.configStatus === "pending"
      ) {
        return false;
      }
    } else {
      return false;
    }
  }

  // question 4
  question =
    questions[EReturnAutomationQuestionKeys.isReturnPolicySameForAllItem];

  if (!EReturnAutomationHiddenKeys.includes(question.key)) {
    if (question?.value === true || question?.value === false) {
      // show question two
      if (
        question.value === false &&
        state.returnExchange.step10.stepData.configStatus === "pending"
      ) {
        return false;
      }
    } else {
      return false;
    }
  }

  return true;
};

const isSegment2Completed = (state: RootState) => {
  const questions = {
    ...state.returnExchange.step11.stepData.stepQuestions,
  };

  let question = questions[EReturnAutomationQuestionKeys.resolutionOffer];

  // question 5
  let resolutionOffers: any = question?.value;
  if (
    resolutionOffers &&
    (resolutionOffers.exchangeWithOtherItems ||
      resolutionOffers.exchangeWithSameItems ||
      resolutionOffers.refundToOrigialPaymentMethod ||
      resolutionOffers.refundToStoreCredits)
  ) {
    // return true;
  } else {
    if (
      resolutionOffers &&
      resolutionOffers[EReturnAutomationQuestionKeys.resolutionOfferMessage] &&
      resolutionOffers[EReturnAutomationQuestionKeys.resolutionOfferMessage]
        ?.value
    ) {
    } else {
      return false;
    }
  }

  // question 6
  question =
    questions[
      EReturnAutomationQuestionKeys.isResolutionOfferedSameForAllOrders
    ];

  if (!EReturnAutomationHiddenKeys.includes(question.key)) {
    if (question?.value === true || question?.value === false) {
      // return true;
      if (
        question.value === false &&
        state.returnExchange.step11.stepData.configStatus === "pending"
      ) {
        return false;
      }
    } else {
      return false;
    }
  }

  return true;
};

const isSegment3Completed = (state: RootState) => {
  const questions = {
    ...state.returnExchange.step12.stepData.stepQuestions,
  };

  // question 7
  let question = questions[EReturnAutomationQuestionKeys.selectReturnReason];
  if (question?.value as string) {
  } else {
    return false;
  }

  // question 8
  question = questions[EReturnAutomationQuestionKeys.returnReasonList];
  //Validate returnReasonList only if question is visible
  if (question.showQuestion === true) {
    if (
      question?.value &&
      Array.isArray(question.value) &&
      question?.value.length
    ) {
    } else {
      return false;
    }
  }

  // question 9
  question = questions[EReturnAutomationQuestionKeys.isReturnReasonSameForAll];
  // if(question.showQuestion === true){
  if (question?.value === true || question?.value === false) {
    if (
      question.value === false &&
      state.returnExchange.step12.stepData.configStatus === "pending"
    ) {
      return false;
    }
  } else {
    return false;
  }
  // }
  return true;
};

const isSegment4Completed = (state: RootState) => {
  const questions = {
    ...state.returnExchange.step14.stepData.stepQuestions,
  };

  // question 7
  let question =
    questions[EReturnAutomationQuestionKeys.returnMethodForMajorityOfOrders];
  if (question?.value as string) {
    // return true;
  } else {
    return false;
  }

  // question 11 is answered or not
  question =
    questions[EReturnAutomationQuestionKeys.returnMethodDisplayMessage];
  if (question?.value as string) {
    // return true;
  } else {
    return false;
  }

  // question 12 is answered or not
  question =
    questions[EReturnAutomationQuestionKeys.isReturnMethodSameForAllOrders];

  if (!EReturnAutomationHiddenKeys.includes(question.key)) {
    if (question?.value === true || question?.value === false) {
      if (
        question.value === false &&
        state.returnExchange.step14.stepData.configStatus === "pending"
      ) {
        return false;
      }
    } else {
      return false;
    }
  }

  return true;
};

const isSegment5Completed = (state: RootState) => {
  const questions = {
    ...state.returnExchange.step15.stepData.stepQuestions,
  };

  let question =
    questions[
      EReturnAutomationQuestionKeys.isRestockingFeeChargedForMajorityOrders
    ];
  // question 13
  if (question?.value === true || question?.value === false) {
    if (question.value === false) {
      return true;
    }
  } else {
    return false;
  }

  // question 14
  question =
    questions[EReturnAutomationQuestionKeys.usuallyChargedRestockingFee];
  if (question.value != null && (question.value as any)?.price !== 0) {
    // return true;
  } else {
    return false;
  }

  //question 15
  question =
    questions[EReturnAutomationQuestionKeys.restockingFeeDisplayMessage];
  if (question.value) {
    // return true;
  } else {
    return false;
  }

  // question 16
  question = questions[EReturnAutomationQuestionKeys.isRestockingFeeSameForAll];

  if (!EReturnAutomationHiddenKeys.includes(question.key)) {
    if (question?.value === true || question?.value === false) {
      if (
        question.value === false &&
        state.returnExchange.step15.stepData.configStatus === "pending"
      ) {
        return false;
      }
    } else {
      return false;
    }
  }

  return true;
};

// Helper to check anti refund strategies step completed or not
// Please note added "7" just for frontend , as it is included in the middle and is optional based on reasons question value
const isSegment7Completed = (state: RootState) => {
  const questions = {
    ...state.returnExchange.step13.stepData.stepQuestions,
  };

  let question = questions[EReturnAutomationQuestionKeys.antiReturnStrategies];
  // step anti refund strategies question 1
  if (question?.value != null || question?.value != undefined) {
    let antiReturnStrategies: IAntiReturnStategiesOption =
      question.value as IAntiReturnStategiesOption;
    if (
      antiReturnStrategies &&
      (antiReturnStrategies.offerAnExchange ||
        antiReturnStrategies.offerSomeSuggestion ||
        antiReturnStrategies.offerSuggestionExtendReturnDeadline ||
        antiReturnStrategies.offerDiscountOnFutureOrder)
    ) {
      // return true;
    } else {
      return false;
    }
  } else {
    return false;
  }

  // step anti refund strategies question 2
  question =
    questions[EReturnAutomationQuestionKeys.configureAntiReturnStrategies];
  if (question?.value != null || question?.value != undefined) {
    let configureAntiReturnStrategies: IIsConfigured =
      question.value as IIsConfigured;
    if (
      configureAntiReturnStrategies &&
      configureAntiReturnStrategies.isConfigured
    ) {
    } else {
      return false;
    }
  } else {
    return false;
  }

  // step anti refund strategies question 3
  question =
    questions[
      EReturnAutomationQuestionKeys.isReturnReasonOrFollowUpQuestionsSame
    ];
  if (question.value === true || question.value === false) {
    if (
      question.value === false &&
      state.returnExchange.step13.stepData.configStatus === "pending"
    ) {
      return false;
    }
  } else {
    return false;
  }
  return true;
};

export const isSegementCompleted = (state: RootState, segmentId: number) => {
  switch (segmentId) {
    case 1:
      return isSegment1Completed(state);
    case 2:
      return isSegment2Completed(state);
    case 3:
      return isSegment3Completed(state);
    case 4:
      return isSegment4Completed(state);
    case 5:
      return isSegment5Completed(state);
    // Added this imaginary step number for antirefund strategies step
    case 7:
      return isSegment7Completed(state);
  }
};

export const areAllSegementCompleted = (state: RootState) => {
  if (!isSegment1Completed(state)) {
    return false;
  }

  //checking if step is transfered after this
  if (state.returnExchange.step9.stepData.transfer_to_agent === true) {
    //then all the step are completed
    return true;
  }

  if (!isSegment2Completed(state)) {
    return false;
  }

  //checking if step is transfered after this
  if (state.returnExchange.step11.stepData.transfer_to_agent === true) {
    //then all the step are completed
    return true;
  }

  if (!isSegment3Completed(state)) {
    return false;
  }

  if (state.returnExchange.step13.stepData.transfer_to_agent === true) {
    //then all the step are completed
    return true;
  }

  const question7Answer =
    state.returnExchange.step12.stepData.stepQuestions[
      EReturnAutomationQuestionKeys.selectReturnReason
    ]?.value;
  // Checking for anti refund strategies
  if (question7Answer !== "notRequired") {
    if (!isSegment7Completed(state)) {
      return false;
    }
  }

  if (!isSegment4Completed(state)) {
    return false;
  }

  if (state.returnExchange.step14.stepData.transfer_to_agent === true) {
    //then all the step are completed
    return true;
  }

  if (!isSegment5Completed(state)) {
    return false;
  }

  return true;
};
