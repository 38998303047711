import { Channel } from "pusher-js";
import { Message } from "src/services/LiveChat/messageExchange/getMessages";

export type ConnectedCustomerRecEventCB = (res: any) => void;

export const getLiveChatUpdatedEvent = (
  channel: Channel,
  callback: ConnectedCustomerRecEventCB
) => {
  channel.bind("live_chat_updated", callback);
};

export const getLiveChatUpdatedEventUnbind = (
  channel: Channel,
) => {
  channel.unbind("live_chat_updated");
};
