import AxiosImg from "src/components/AxiosImg";
import InnerChatAttachments from "src/components/InnerChatAttachments/InnerChatAttachments";
import ParseHTML from "src/components/ParseHTML";
import ShowRelativeTime from "src/components/ShowRelativeTime";
import UserAvatar from "src/components/UserAvatar";
import { Message } from "src/services/LiveChat/messageExchange/getMessages";
import styles from "./MessageIn.module.scss";
import botImg from "src/assets/images/botImg.png";
import { useAppSelector } from "src/store/store";

const MessageIn = ({
  messageText,
  sentBy,
  sendTimeGmt,
  sendTime,
  attachments,
  messageId,
  senderType,
  messageType,
  messageSeenStatus, // true: message seen, false: message not seen
}: Message) => {
  // Get Customer Name from store and update the info
  const activeCustomerInfo = useAppSelector(
    (state) => state.chatWidExhMsg.activeCustomerInfo,
  );

  return (
    <>
      <div
        className={`d-flex mb-4 ${styles.msgIn}`}
        style={{ marginRight: "58px" }}
      >
        {/* checking if messaeg type or senderType is bot, if yes showing the bot image  */}
        {senderType === "Bot" || messageType === "botMessage" ? (
          <img
            src={botImg}
            className={`rounded-circle ${styles.profileImage}`}
          />
        ) : senderType === "Customer" &&
          activeCustomerInfo?.imageURL &&
          activeCustomerInfo.imageURL.length !== 0 ? (
          <AxiosImg
            url={activeCustomerInfo.imageURL}
            className={`rounded-circle ${styles.profileImage}`}
            isDirectURL={sentBy?.isPublicAttachmentUrl}
          />
        ) : sentBy?.imageURL && sentBy?.imageURL.length !== 0 ? (
          <AxiosImg
            url={sentBy.imageURL}
            className={`rounded-circle ${styles.profileImage}`}
            isDirectURL={sentBy?.isPublicAttachmentUrl}
          />
        ) : (
          <div>
            <UserAvatar
              name={
                (senderType === "Customer"
                  ? activeCustomerInfo?.name
                  : sentBy?.name) ?? "NA"
              }
              size={33}
            />
          </div>
        )}
        <div className={`${styles.msgwrapper}`}>
          <div className={`${styles.msgText}`}>
            <ParseHTML
              html={messageText ?? ""}
              // replaceNewLineWithBr={true}
            />

            {attachments && attachments.length > 0 && (
              <div className="mt-2">
                <InnerChatAttachments
                  messageData={{
                    attachments: attachments,
                    senderName: sentBy.name,
                    messageDateTime: sendTime,
                    messageId: messageId,
                  }}
                />
              </div>
            )}
          </div>

          <div className={`d-flex justify-content-end me-1 mt-2`}>
            {sendTimeGmt?.trim() ? (
              <ShowRelativeTime
                dateTimeUTC={sendTimeGmt}
                messageType="in"
              /> // in: message received, out: message sent passed as prop
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default MessageIn;
