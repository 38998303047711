import { NavLink } from "react-router-dom";
import FinalChat from "../../Customization/Children/FinalChat/FinalChat";
import styles from "./NotAvailable.module.scss";
// import NotRespond from "./NotRespond/NotRespond";
// import OfflineMode from "./OfflineMode/OfflineMode";
import MailLink, { Links } from "./Children/MailLink/MailLink";
import { useParams } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { fetchAskForEmailData } from "src/store/slices/liveChatSetting/Forms/askForEmail/askForEmailSetting.slice";
import Loader from "src/components/Loader";
const NotAvailable = () => {
  const {
    askForEmailData,
    askForEmailDataAjaxStatus,
    showTicketForm,
    showOutsideBusinessHours,
  } = useAppSelector((state) => state.askForEmailSetting);

  const dispatch = useAppDispatch();

  const { subSettingName, integrationId } = useParams();

  const pageIntegrationId = integrationId
    ? decodeURIComponent(integrationId)
    : "";

  const ShowComp =
    subSettingName === undefined
      ? Links["NotRespond"]?.component
      : Links[subSettingName + ""]?.component;
  const [messageText, setMessageText] = useState<null | string>("");
  const [confirmationText, setConfirmationText] = useState<null | string>("");
  const [canSendMessage, setCanSendMessage] = useState<null | boolean>(false);
  const [sendMessageAfter, setSendMessageAfter] = useState<
    null | string | number
  >("");
  const [canCustomerLeaveEmail, setCanCustomerLeaveEmail] = useState<
    null | boolean
  >(false);

  //when offline
  const [offlineMessageText, setOfflineMessageText] = useState<null | string>(
    ""
  );
  const [offlineConfirmationText, setOfflineConfirmationText] = useState<
    null | string
  >("");

  const [isOfflineDataChanged, setIsOfflineDataChanged] = useState(false);
  const [isNotRespondDataChanged, setIsNotRespondDataChanged] = useState(false);

  const [availabilityStartChatAlways, setAvailabilityStartChatAlways] =
    useState<boolean>(false);

  useEffect(() => {
    if (askForEmailData !== null) {
      if (
        askForEmailData.whenAgentNotResponding.messageText === messageText &&
        askForEmailData.whenAgentNotResponding.confirmationText ===
          confirmationText &&
        askForEmailData.whenAgentNotResponding.canSendMessage ===
          canSendMessage &&
        askForEmailData.whenAgentNotResponding.sendMessageAfter ===
          sendMessageAfter &&
        askForEmailData.whenAgentNotResponding.canCustomerLeaveEmail ===
          canCustomerLeaveEmail
      ) {
        setIsNotRespondDataChanged(false);
      } else {
        setIsNotRespondDataChanged(true);
      }
    }
  }, [
    messageText,
    confirmationText,
    canSendMessage,
    sendMessageAfter,
    canCustomerLeaveEmail,
  ]);

  useEffect(() => {
    dispatch(fetchAskForEmailData(pageIntegrationId));
  }, []);

  useEffect(() => {
    if (askForEmailData !== null) {
      setMessageText(askForEmailData.whenAgentNotResponding?.messageText);
      setConfirmationText(
        askForEmailData.whenAgentNotResponding?.confirmationText
      );
      setCanSendMessage(askForEmailData.whenAgentNotResponding?.canSendMessage);
      setSendMessageAfter(
        askForEmailData.whenAgentNotResponding?.sendMessageAfter
      );
      setCanCustomerLeaveEmail(
        askForEmailData.whenAgentNotResponding?.canCustomerLeaveEmail
      );

      setAvailabilityStartChatAlways(
        askForEmailData.availability?.canCustomerStartChatAlways
      );
    }
  }, [askForEmailData]);

  const handleCancel = () => {
    if (askForEmailData !== null) {
      setMessageText(askForEmailData.whenAgentNotResponding?.messageText);
      setConfirmationText(
        askForEmailData.whenAgentNotResponding?.confirmationText
      );
      setCanSendMessage(askForEmailData.whenAgentNotResponding?.canSendMessage);
      setSendMessageAfter(
        askForEmailData.whenAgentNotResponding?.sendMessageAfter
      );
      setCanCustomerLeaveEmail(
        askForEmailData.whenAgentNotResponding?.canCustomerLeaveEmail
      );
    }
  };

  const getOfflineDataCanCustomerLeaveEmail = useCallback((): boolean => {
    if (showOutsideBusinessHours === true) {
      if (
        askForEmailData.whenAgentOffline.outsideBusinessHours.askForEmail ===
        true
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      if (
        askForEmailData.whenAgentOffline.withinBusinessHours.askForEmail ===
        true
      ) {
        return true;
      } else {
        return false;
      }
    }
  }, [
    showOutsideBusinessHours,
    askForEmailData.whenAgentOffline.outsideBusinessHours.askForEmail,
    askForEmailData.whenAgentOffline.withinBusinessHours.askForEmail,
  ]);

  return (
    <div>
      <div className={`d-flex flex-wrap`}>
        <div className={`${styles.customLeft}`}>
          <div className={`pe-2`}>
            <h1 className={`mb-3 ${styles.mailHead}`}>Agent Not Available</h1>
            {/* <p className={`${styles.mailSub}`}>Set up ask for email</p> */}
            <div className={`w-100 ${styles.setUp}`}>
              <MailLink />
            </div>
          </div>

          {askForEmailDataAjaxStatus === "pending" ? (
            <Loader />
          ) : (
            <>
              {/* When offline  */}
              {/* <OfflineMode /> */}
              {/* When not responding */}
              {/* <NotRespond /> */}
              <div className={`mt-1`}>
                {ShowComp && (
                  <ShowComp
                    data={{
                      canSendMessage: canSendMessage,
                      setCanSendMessage: setCanSendMessage,
                      sendMessageAfter: sendMessageAfter,
                      setSendMessageAfter: setSendMessageAfter,
                      messageText: messageText,
                      setMessageText: setMessageText,
                      confirmationText: confirmationText,
                      setConfirmationText: setConfirmationText,
                      canCustomerLeaveEmail: canCustomerLeaveEmail,
                      setCanCustomerLeaveEmail: setCanCustomerLeaveEmail,
                      offlineMessageText: offlineMessageText,
                      setOfflineMessageText: setOfflineMessageText,
                      offlineConfirmationText: offlineConfirmationText,
                      setOfflineConfirmationText: setOfflineConfirmationText,
                      askForEmailDataAjaxStatus: askForEmailDataAjaxStatus,
                    }}
                    handleCancel={handleCancel}
                    isOfflineDataChanged={isOfflineDataChanged}
                    setIsOfflineDataChanged={setIsOfflineDataChanged}
                    isNotRespondDataChanged={isNotRespondDataChanged}
                    setIsNotRespondDataChanged={setIsNotRespondDataChanged}
                    integrationId={pageIntegrationId}
                    availabilityStartChatAlways={availabilityStartChatAlways}
                  />
                )}
              </div>
            </>
          )}
        </div>
        {/* Custom Right  */}
        <div className={`${styles.customRight}`}>
          <FinalChat
            askForEmailData={{
              messageText: messageText,
              confirmationText: confirmationText,
              canSendMessage: canSendMessage,
              canCustomerLeaveEmail:
                Links[subSettingName + ""]?.name === "offline"
                  ? getOfflineDataCanCustomerLeaveEmail()
                  : canCustomerLeaveEmail,
              showOfflineData: Links[subSettingName + ""]?.name === "offline",
              offlineMessageText:
                showOutsideBusinessHours === true
                  ? askForEmailData.whenAgentOffline.outsideBusinessHours
                      .messageText
                  : askForEmailData.whenAgentOffline.withinBusinessHours
                      .messageText,
              offlineConfirmationText:
                showOutsideBusinessHours === true
                  ? askForEmailData.whenAgentOffline.outsideBusinessHours
                      .confirmationText
                  : askForEmailData.whenAgentOffline.withinBusinessHours
                      .confirmationText,
            }}
            show={{ askForEmail: !showTicketForm, chatForm: showTicketForm }}
            formData={{
              elements:
                showOutsideBusinessHours === true
                  ? askForEmailData.whenAgentOffline.outsideBusinessHours
                      .ticketChatFormData.elements
                  : askForEmailData.whenAgentOffline.withinBusinessHours
                      .ticketChatFormData.elements,
              submitButtonText: "Submit",
              formType: "ticketForm",
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default NotAvailable;
