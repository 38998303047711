/**
 * TextBox Component
 *
 * This component provides a text input area with optional validation error message. It is used for configuring and displaying messages or text content.
 *
 * @author @navjyot-busibud
 * @author @Anubhav-busibud
 */
import { useNavigate } from "react-router-dom";
import styles from "../../ConfigureSteps.module.scss";
import { useEffect, useRef, useState } from "react";

interface Props {
  value: string | undefined;
  dispatch: Function;
}
const TextBox = ({ value, dispatch }: Props) => {
  const [text, setText] = useState(value ?? "");
  const editableRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (value && editableRef.current) {
      editableRef.current.innerHTML = value;
    }
  }, [text]);

  return (
    <>
      <div
        // cols={30}
        // rows={5}
        ref={editableRef}
        contentEditable={true}
        className={`w-100 ${styles.textArea} ${
          !value || value?.length <= 0 ? styles.err : ""
        }`}
        // value={text}
        onInput={(e) => {
          // setText(e.currentTarget.textContent ?? "");
          dispatch({
            type: "UPDATE_INTRODUCTION_MESSAGE",
            payload:
              e.currentTarget.textContent?.trim() === ""
                ? ""
                : e.currentTarget.innerHTML,
          });
        }}
      ></div>
      {!value || value.length <= 0 ? (
        <>
          <span className={`${styles.errorMsg}`}>
            * Please enter a message here
          </span>
        </>
      ) : null}
    </>
  );
};

export default TextBox;
