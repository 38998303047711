import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./editCustomerDetails.declarations";
import editCustomerDetailReducers from "./editCustomerDetails.reducers";

export const editCustomerDetailSlice = createSlice({
  name: "editCustomerDetails",
  initialState,
  reducers: editCustomerDetailReducers,
});

export const {
  editCustomerProfile,
  updateCustomerDetails,
  removeCustomerProfile,
  addContact,
  deleteContact,
  setCustomerData,
} = editCustomerDetailSlice.actions;

export default editCustomerDetailSlice.reducer;