/**
 * This file is the service file used for making api request.
 * It contains the getAnalyticsAndSeo function service which is explained below.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants"; // this is used for testing purpose only for frontend.

export interface getAnalyticsAndSeoPayload {
  integrationId: number | string;
}

export interface AnalyticsAndSeoRes {
  isGoogleAnalyticsEnabled: boolean;
  isGoogleAnalyticsVersion4: boolean;
  trackingId: string;
  isVisibleinPublicSearch: boolean;
  pageTitle: string;
  metaDescription: string;
  openGraphTitle: string;
  openGraphDescription: string;
  openGraphImage: string | null;
}

/**
 * For testing frontend with own api link if backend is not prepared.
 */
const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/getAnalyticsAndSeo`
  : "/api/chatSetting/getAnalyticsAndSeo";

/**
 * This is the service made to get the alanytics and SEO saved data.
 */
export const getAnalyticsAndSeo = async (params: getAnalyticsAndSeoPayload) => {
  const { data: res } = await axiosJSON.post(apiEndPoint, params);
  return res.data as AnalyticsAndSeoRes;
};
