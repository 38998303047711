/**
 * This file contains the custom hook for managing article-related data.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { getBrandDetailsByIntegrationId } from "src/services/KnowledgeBase/getBrandDetailsByIntegrationId.service";

/**
 * This hook is used with the articles component.
 */
function useArticles() {
  // State for controlling whether to show subfolders
  const [showSubFolder, setShowSubFolder] = useState(false);

  // Get articleId from URL parameters
  const { articleId } = useParams();
  // Decode articleId if it exists
  const convertedArticleId = articleId ? decodeURIComponent(articleId) : "";

  // Get integrationId from URL parameters
  const { integrationId } = useParams();
  // Decode integrationId if it exists
  const pageIntegrationId = integrationId
    ? decodeURIComponent(integrationId)
    : "";

  // Use the useQuery hook for fetching brand details
  const {
    data: brandDetails,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["getBrandDetails", pageIntegrationId],
    queryFn: () =>
      // Function for fetching brand details
      getBrandDetailsByIntegrationId({ integrationId: pageIntegrationId }),
  });

  // Return the necessary data as an object
  return {
    convertedArticleId,
    showSubFolder,
    setShowSubFolder,
    brandDetails,
    brandDetailsLoading: isLoading,
    brandDetailsError: isError,
  };
}

export default useArticles;
