import { useQuery } from "@tanstack/react-query";
import { AJAXSTATUS } from "src/globals/constants";
import getSegmentTypesService, {
  IAllSegmentTypesData,
} from "src/services/CustomerSegments/getSegmentTypes.service";

function useFetchSegments() {
  const { data: allSegmentsData, status } = useQuery(["useFetchSegments"], {
    queryFn: getSegmentTypesService,
    cacheTime: 3600000,
    staleTime: 3600000,
    networkMode: "always",
  });

  return {
    allSegmentsData: allSegmentsData ? allSegmentsData : null,
    allSegmentsStatus:
      status === "success"
        ? "fulfilled"
        : status === "loading"
          ? "pending"
          : "rejected",
  } as {
    allSegmentsData: IAllSegmentTypesData | null;
    allSegmentsStatus: AJAXSTATUS;
  };
}

export default useFetchSegments;
