import { CSVTableVarientImage } from "src/features/ReturnAutoWorkFlow/ReturnAutoWorkFlow.types";
import uploadImage from "src/services/ReturnAutoWorkFlow/ConfigModals/uploadImage.service";

export interface IUploadFile {
  file: File;
  id: string;
  isPublic?: boolean;
  imageId?: string | number; //after uploading img id should be assigned
  imageURL?: string;
  name?: string;
  abortController?: AbortController; //abort controller for calling the upload
}

//interface for file upload hander function
export interface FileUploadHandler {
  files: IUploadFile[];
  onProgress: (fileId: number | string, value: number) => void;
  onFailed: (fileId: number | string) => void;
  onSuccess: ({
    varientImgData,
  }: {
    varientImgData: CSVTableVarientImage;
  }) => void;
  setIsFileUploading: React.Dispatch<React.SetStateAction<boolean>>;
  integrationId: string;
  productId: string;
}

export const fileUploader = ({
  files,
  onProgress,
  onFailed,
  onSuccess,
  setIsFileUploading,
  integrationId,
  productId,
}: FileUploadHandler) => {
  /* To handle click on add attachment */
  const fileObjects = Object.values(files);

  if (Object.values(files).length > 1) {
    return;
  }
  /* upload each file individually */
  for (let index = 0; index < fileObjects.length; index++) {
    let fileObject = fileObjects[index];
    if (!fileObject.file) {
      continue;
    }
    setIsFileUploading(true);
    uploadImage(
      {
        file: fileObject.file,
        integrationId: integrationId,
        productId: productId,
        abortControllerSignal: fileObject?.abortController?.signal,
      },
      (progressEvent: any) => {
        let progress = Math.round(
          (100 * progressEvent.loaded) / progressEvent.total,
        );
        onProgress(fileObject.id, progress);
      },
    )
      .then((uploadRes) => {
        if (uploadRes) {
          const uploadedFile: CSVTableVarientImage = {
            imageId: uploadRes?.attachmentId,
            imageURL: uploadRes?.attachmentUrl,
            name: fileObject.file?.name,
          };
          onSuccess({ varientImgData: uploadedFile });
        } else {
          onFailed(fileObject.id);
        }
      })
      .catch((uploadErr) => {
        if (uploadErr?.message == "canceled") {
          //request was cancelled
        } else {
          onFailed(fileObject.id);
        }
      })
      .finally(() => {
        setIsFileUploading(false);
      });
  }
};
