import {
  BaseConfigModalTabType,
  GetConfigModalParams,
} from "src/features/ReturnAutoWorkFlow/children/ConfigModals/oldReturnModals.types";
import { axiosJSON } from "src/globals/axiosEndPoints";

interface MoreQuestion {
  elementTypeId: string;
  additionalOptions: {
    id: string;
    value: string;
  }[];
  elementValue: string;
  elementId: string;
}

export interface ReturnReasonType {
  id: string;
  value: string;
  moreQuestions: MoreQuestion[];
}

export interface ReturnReasonOrderAndItemsTab extends BaseConfigModalTabType {
  reasons: ReturnReasonType[];
  explainWhyTheyReturn: "notRequired" | "required" | "requiredAndCustom" | null;
  /**
   * For selecting all orders. This will take priority over the order conditions.
   */
  applicableForAllOrders: boolean;
  /**
   * For selecting all Items. This will take priority over the item conditions.
   */
  applicableForAllItems: boolean;
}

async function getReturnReasonOrderAndItemsService(
  params: GetConfigModalParams,
) {
  const { data: res } = await axiosJSON.get(
    "/api/setting/automation/refund/step/12/configuration",
    { params },
  );

  if (res.err || res.error) {
    throw new Error(res.msg ?? res.message ?? "Some Error Occurred.");
  }

  return res.data as ReturnReasonOrderAndItemsTab[];
}

export default getReturnReasonOrderAndItemsService;
