import styles from "../../../../EmailForwarding/EmailForwarding.module.scss";
import saufterLogo from "src/assets/images/saufter-logo.svg";

const showSaufterLogo = window.location.hostname.includes(
  process.env.REACT_APP_LOGO_HOST_NAME + ""
);

function VerifyingTheConnection({ text, subText }: any) {
  return (
    <div className=" rounded d-flex-column justify-content-center h-100   mb-3">
      <div className="d-flex justify-content-center align-item-center position-relative">
        <span
          className={`${styles.loader} position-absolute `}
          style={{ top: "22%", bottom: "50%" }}
        >
          {" "}
        </span>
        <img
          src={
            showSaufterLogo
              ? saufterLogo
              : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQg8GuU5qEa9f1xlwfJ2sOKjeHMx_CtQsAxekC4-k6OuQ&s"
          } //render saufter logo for saufter domain
          alt=""
          style={{
            height: "36px",
            padding: showSaufterLogo ? "2px" : undefined, //added padding for saufter logo to avoid loader overlapping
          }}
          className="mt-3"
        />
      </div>
      <div className=" fw-bold d-flex justify-content-center mt-4 text-center">
        Verifying the {text}
      </div>
      <div className="fw-light  text-muted small d-flex justify-content-center m-3 text-center">
        {subText}
      </div>
    </div>
  );
}

export default VerifyingTheConnection;
