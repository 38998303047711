/**
 * This component represents a dropdown for selecting conditions. It utilizes the CustomSelectDropdown component
 * for rendering the dropdown in audience configure form
 *
 * @author @yuvaraj-busibud
 */
import React, { useRef, useState } from "react";
import styles from "./ConditionDropDown.module.scss";
import CustomSelectDropdown from "../CustomSelectDropdown/CustomSelectDropdown";

interface ConditionOption {
  value: string | number;
  label: string;
}
interface Props {
  options: ConditionOption[];
  selectedOption: ConditionOption | undefined;
  setSelectedOption: (value: ConditionOption) => void;
  error?: string | null;
}
// Dropdown component for selecting conditions.
const ConditionDropDown = ({
  options,
  selectedOption,
  setSelectedOption,
  error,
}: Props) => {
  return (
    <div>
      <CustomSelectDropdown
        selectedOption={selectedOption}
        setSelectedOption={(value) => {
          if (Array.isArray(value)) {
            setSelectedOption(value[0]);
          } else {
            setSelectedOption(value);
          }
        }}
        options={options}
        menuClassName={`mb-1 ${styles.brandDrop}`}
        error={selectedOption ? "" : error}
      />
    </div>
  );
};

export default ConditionDropDown;
