import React, { useCallback, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import {
  deleteTag,
  DeleteTagParam,
} from "src/services/Settings/Tags/deleteTag";
import {
  fetchAllTheTags,
  resetTagsSettings,
} from "src/store/slices/settings/tags/tagsSettings.slice";
import { useAppDispatch } from "src/store/store";
import styles from "./DeleteTagModal.module.scss";

function DeleteTagModal({
  tagData,
  showDeleteTagModal,
  setShowDeleteTagModal,
}: any) {
  const dispatch = useAppDispatch();
  const [showDeleteLoader, setShowDeleteLoader] = useState<boolean>(false);
  const onHide = useCallback(() => {
    setShowDeleteTagModal(false);
  }, []);

  const handleCancelDelete = (e: any) => {
    e.preventDefault();
    onHide();
  };

  const handleDeleteTag = (e: any) => {
    e.preventDefault();
    setShowDeleteLoader(true);
    const deleteTagParam: DeleteTagParam = {
      id: tagData.id,
    };
    deleteTag(deleteTagParam)
      .then((res) => {
        setShowDeleteLoader(false);
        dispatch(resetTagsSettings());
        dispatch(fetchAllTheTags());
        onHide();
        pushTheToast({
          type: "success",
          text: "Tag deleted successfully!",
          position: "top-right",
        });
      })
      .catch((err) => {
        setShowDeleteLoader(false);
        onHide();
        pushTheToast({
          type: "danger",
          text: "Failed to delete tag!",
          position: "top-right",
        });
      });
  };

  return (
    <Modal
      show={showDeleteTagModal}
      onHide={onHide}
      centered={true}
      contentClassName={`${styles.modalContent}`}
    >
      <div className="">
        <div>
          <div>
            <div
              className={`${styles.header} d-flex  justify-content-between mx-4 mt-2 mb-2`}
            >
              <h5 className={`m-2 ${styles.deleteHead}`}>Delete Tag</h5>
              <button
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close "
                className={`${styles.closeButton} btn-close ms-2 me-0 me-lg-3 mt-2`}
                onClick={onHide}
              >
                {" "}
              </button>
            </div>
          </div>
          <hr></hr>
        </div>

        <div>
          <div className="mt-4 mx-2">
            <div className="col-12 ">
              <div className={`d-flex justify-content-between mx-4 mt-4 mb-2`}>
                <span className={styles.deleteTagTxt}>
                  Are you sure you want to delete this Tag ?
                </span>
              </div>
              <div className="d-flex flex-wrap justify-content-start justify-content-md-start flex-row-reverse mt-4 mb-3 me-1 p-2 ">
                <div className="">
                  <button
                    type="submit"
                    className={`me-0 me-md-2 d-flex justify-content-center align-items-center ${styles.deleteBtn} `}
                    onClick={(e) => handleDeleteTag(e)}
                  >
                    {showDeleteLoader === true ? (
                      <Spinner
                        className="my-auto mx-1"
                        animation="border"
                        color="white"
                        size="sm"
                      />
                    ) : undefined}
                    Yes Delete
                  </button>
                </div>
                <div className="">
                  <button
                    type="submit"
                    className={`d-flex justify-content-center align-items-center me-0 me-md-3 ${styles.cancelButton}  `}
                    onClick={(e) => handleCancelDelete(e)}
                  >
                    No, Keep it
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default DeleteTagModal;
