import UserAvatar from "src/components/UserAvatar";
import { highlightSearchedText } from "src/routes/TicketList/children/OuterTickets/Chidren/SearchedTickets/SearchedTickets";
import { useAppSelector } from "src/store/store";
import { getTimeFromNow } from "src/utils/dateLibrary";
import { getChannelFAIcon } from "src/utils/utils";
import styles from "./TicketListRow.module.scss";
import AxiosImg from "src/components/AxiosImg";
import Status from "src/routes/LiveChat/Children/MainChat/Children/ChatHistoryUpdated/Children/HistoryUpdatedTickets/Children/Status";
import saufterImg from "src/assets/images/saufter.gif";
function TicketListRow({
  id,
  profileImg,
  name,
  description,
  time,
  platform,
  status,
  isAiHandled,
  isDraftMessage,
  active,
  onClick,
  isPublicAttachmentUrl,
}: {
  id: string | number;
  profileImg: string;
  name: string;
  description: string;
  time: string;
  platform: string;
  status: any;
  isAiHandled: boolean;
  isDraftMessage: boolean;
  active: boolean;
  isPublicAttachmentUrl?: boolean;
  onClick: (e: any) => void;
}) {
  const userTimezone = useAppSelector(
    (state) => state.globals.currentUserData?.userTimezone,
  );
  const scopes = useAppSelector(
    (state) => state.globals.currentUserData?.scopes,
  );

  // Search Term from the global search
  const searchTerm = useAppSelector(
    (state) => state.ticketGlobalSearch.searchText,
  );

  return (
    <>
      <Status
        status={status.ticketStatusName}
        displayBtn="d-flex"
        ticketId={id}
        canChangeStatus={
          scopes && scopes.includes("change_status") ? true : false
        }
      />
      <div
        className={`${styles.ticketListRow} ${
          active && styles.active
        } d-flex  p-2 my-1`}
        onClick={onClick}
      >
        <div className={`mx-0 mt-0 ${styles.imgWrapper}`}>
          {profileImg && profileImg.trim() !== "" ? (
            <AxiosImg
              className={`${styles.profileIcon}`}
              url={profileImg}
              isDirectURL={isPublicAttachmentUrl}
            />
          ) : (
            <UserAvatar
              name={name || "NA"}
              size={30}
            />
          )}
        </div>
        <div className={`w-100 px-1 pt-1`}>
          <div className="d-flex">
            <div className={`${styles.name}`}>
              {highlightSearchedText(name, searchTerm)}
            </div>
            <div className={`ms-1 ${styles.iconWrap}`}>
              <i
                className={`d-block ${styles.brandWidth} ${getChannelFAIcon(
                  platform,
                )} ticketIcons`}
              ></i>
            </div>
          </div>

          <div className={`${styles.desc}`}>
            {highlightSearchedText(description, searchTerm)}
          </div>
          <div className={`d-flex flex-wrap`}>
            <div className={`d-flex ${styles.time}`}>
              <div className={`my-auto ms-2 me-1 ${styles.timeWidth}`}>
                {/* {new Date(time).toLocaleTimeString()} */}
                {getTimeFromNow({
                  date: new Date(time),
                  timeZone: userTimezone,
                })}
              </div>
            </div>
            {/* <div className={`mx-1`}></div>
          <div
            className={`${styles.status} ${styles[status.ticketStatusName]}`}
          >
            <i className={`fa-solid fa-circle my-auto mx-1`}></i>
            <span className={`m-auto`}>{status.ticketStatusName}</span>
          </div> */}
          </div>
          {/* AI Handled Tag */}
          {isAiHandled && (
            <div className="d-flex align-items-center mt-2">
              <span className={`${styles.saufterText} ${styles.handled}`}>
                AI handled
              </span>
              <img
                src={saufterImg}
                alt=""
                className={`${styles.saufterImg}`}
              />
            </div>
          )}
          {/* AI Drafted Tag */}
          {isDraftMessage && (
            <div className="d-flex align-items-center mt-2">
              <span className={`${styles.saufterText} ${styles.drafted}`}>
                AI Drafted
              </span>
              <img
                src={saufterImg}
                alt=""
                className={`${styles.saufterImg}`}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default TicketListRow;
