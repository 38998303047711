/**
 * This file defines a custom hook responsible for handle knowledgebase articles checkbox onchange event
 *
 * @author @yuvaraj-busibud
 * @author @navjyot-busibud
 * @author @Jayalakshmi-busibud
 */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Article,
  getAllArticles,
} from "src/services/Bot/AnswerSources/getAllKbArticles.service";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  SelectArticlesParams,
  SelectArticlesResponse,
  selectArticles,
} from "src/services/Bot/AnswerSources/selectKbArticles.service";
import { useParams } from "react-router-dom";
import { getAllArticle } from "src/services/KnowledgeBase/Settings/Preview/getAllArticle.service";

interface Props {
  articles: Article[];
  updateArticlebotTrainingStatus: (data: SelectArticlesResponse) => void;
}

function useArticleBrand({ articles, updateArticlebotTrainingStatus }: Props) {
  // const initiallySelectedArticleIds = (articles !== undefined && articles.length>0) ? articles
  // .filter((article) => article.isSelected)
  // .map((article) => article.articleId) : [];
  let initiallySelectedArticleIds: any = [];
  const { profileId } = useParams();
  const botProfileId = parseInt(profileId ? profileId : "");
  const [selectedArticles, setSelectedArticles] = useState<number[]>([]);
  useEffect(() => {
    initiallySelectedArticleIds =
      articles !== undefined && articles.length > 0
        ? articles
            .filter((article) => article.isSelected)
            .map((article) => article.articleId)
        : [];
    if (initiallySelectedArticleIds.length > 0)
      setSelectedArticles(initiallySelectedArticleIds);
  }, [articles?.length]);
  // const [payload, setPayload] = useState<{
  //   botProfileId:number | undefined;
  //   selectedArticleIds: number[];
  //   unselectedArticleIds: number[];
  //   allArticles: boolean;
  // }>({
  //   botProfileId: botProfileId,
  //   selectedArticleIds: [],
  //   unselectedArticleIds: [],
  //   allArticles: false,
  // });

  //Mutation for update select articles
  const selectArticlesMutation = useMutation({
    mutationFn: selectArticles,
    onSuccess: (data, variables, context) => {
      // console.log("Success: successfully updated");
      updateArticlebotTrainingStatus(data);
    },
  });

  const selectArticlesApi = async (payload: any) => {
    await selectArticlesMutation.mutateAsync(payload);
  };

  // Callback to handle change in selectedArticles
  const handleChange = useCallback(
    (articleIds: any, isSelected: any) => {
      if (botProfileId === undefined || selectedArticles === undefined) {
        return;
      }

      let updatedSelectedArticles = [...selectedArticles];

      if (isSelected) {
        updatedSelectedArticles.push(...articleIds);
      } else {
        updatedSelectedArticles = updatedSelectedArticles.filter(
          (id: any) => !articleIds.includes(id),
        );
        if (articleIds.length === 0) {
          updatedSelectedArticles = [];
        }
      }
      updatedSelectedArticles = [...new Set(updatedSelectedArticles)];
      const allArticles = updatedSelectedArticles.length === articles.length;
      if (allArticles) {
        articleIds = [];
      }
      // Update the payload
      const payload = {
        botProfileId: botProfileId,
        selectedArticleIds: isSelected ? articleIds : [],
        unselectedArticleIds: isSelected ? [] : articleIds,
        allArticles: allArticles,
      };
      if (allArticles !== undefined) {
        selectArticlesApi(payload);
      }
    },
    [selectedArticles, articles],
  );

  //Callback to handle select or unselect all articles
  const handleSelectAll = useCallback(
    (e: { stopPropagation: () => void }) => {
      e.stopPropagation();
      if (articles == undefined) return;
      if (articles.length == 0) return;
      console.log(selectedArticles);
      if (selectedArticles.length === articles?.length) {
        console.log(articles)
        setSelectedArticles([]);
        handleChange([], false);
      } else {
        setSelectedArticles(
          articles?.map((article) => {
            return article.articleId;
          }),
        );
        handleChange(
          articles?.map((article) => {
            return article.articleId;
          }),
          true,
        );
      }
    },
    [selectedArticles, articles],
  );

  return {
    selectedArticles,
    setSelectedArticles,
    handleSelectAll,
    handleChange,
  };
}

export default useArticleBrand;
