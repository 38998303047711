import { useCallback, useMemo, useState } from "react";
import { UploadedCSVDetails } from "src/features/ReturnAutoWorkFlow/ReturnAutoWorkFlow.types";
import useSaveStepsAndQuestions from "src/features/ReturnAutoWorkFlow/hooks/useSaveStepsAndQuestions";
import MoveToNext from "../MoveToNext/MoveToNext";
import penBlue from "src/assets/images/PenBlue.png";
import PendingImg from "src/assets/images/PendingImg.png";
import fileImg from "src/assets/images/file.svg";
import styles from "./ConfigButton.module.scss";

const FileDetails = ({ metaData }: { metaData: UploadedCSVDetails }) => {
  return (
    <div
      className={`d-flex justify-content-between px-2 my-1 ${styles.fileBox}`}
    >
      <div>
        <span className={`${styles.label}`}>File last uploaded:</span>
        <img
          src={fileImg}
          alt="file"
          className="mx-1"
        />
        <span className={`${styles.desc}`}>
          {metaData.lastFileUploadedName}
        </span>
      </div>
      {metaData.lastFileUploadedTimeUTC ? (
        <div>
          <span className={`${styles.label}`}>At:</span>
          <span className={`ps-1 ${styles.desc}`}>
            {new Date(
              metaData.lastFileUploadedTimeUTC + "Z",
            ).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "long",
              year: "numeric",
            })}
          </span>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

function ConfigButton({
  stepId,
  questionId,
}: {
  stepId: string;
  questionId: string;
}) {
  const { dispatch, stepQuestion, modalConfigDetails, questionHaveUpdates } =
    useSaveStepsAndQuestions({
      stepId,
      questionId,
    });

  const [disabled, setDisabled] = useState(
    modalConfigDetails?.status === "completed",
  );

  useMemo(() => {
    setDisabled(modalConfigDetails?.status === "completed");
  }, [modalConfigDetails]);

  const renderModal = useCallback(() => {
    dispatch("setState", {
      activeModalConfigDetails: modalConfigDetails,
      activeModalConfigStepId: stepId,
      activeModalConfigQuestionId: questionId,
    });
  }, [modalConfigDetails]);

  const editConfig = useCallback(() => {
    if (modalConfigDetails?.editableAfterCompleted === false) {
      dispatch("setState", { activeStaticModal: "noEditErrorModal" });
    } else {
      setDisabled(false);
    }
  }, [modalConfigDetails, dispatch]);

  if (!modalConfigDetails) {
    return <></>;
  }

  return (
    <div className="mt-4">
      {modalConfigDetails.labelTextBeforeBtn ? (
        <div className={`${styles.btnTopLabel} mb-1`}>
          {modalConfigDetails.labelTextBeforeBtn}
        </div>
      ) : (
        ""
      )}
      {modalConfigDetails.configureKey === "uploadCSV" &&
      modalConfigDetails.metaData ? (
        <FileDetails
          metaData={modalConfigDetails.metaData as UploadedCSVDetails}
        />
      ) : (
        ""
      )}
      <div className="d-flex">
        {modalConfigDetails.configureKey === "uploadCSV" &&
        modalConfigDetails.metaData ? (
          <button
            className={`${styles.viewBtn} px-3 me-2`}
            disabled={false}
            onClick={renderModal}
          >
            <div>
              <span className={`${styles.configBtn}`}>
                {modalConfigDetails.btnText}
              </span>
            </div>
          </button>
        ) : (
          <button
            className={`${styles.nextStepButton} btn px-3 me-2`}
            disabled={disabled}
            style={{
              backgroundColor: modalConfigDetails.btnBackgroundColor?.trim()
                ? modalConfigDetails.btnBackgroundColor.trim()
                : undefined,
            }}
            onClick={renderModal}
          >
            <div>
              {modalConfigDetails.status === "completed" && (
                <span
                  className={`${styles.checkLogo} px-1 mx-1 d-inline-flex justify-content-center align-items-center`}
                >
                  <i className="fa-solid fa-check"></i>
                </span>
              )}
              <span className={`${styles.configBtn}`}>
                {modalConfigDetails.btnText}
              </span>
              {modalConfigDetails.btnSubText ? (
                <span className={`${styles.configBtnSpan}`}>
                  {modalConfigDetails.btnSubText}
                </span>
              ) : (
                ""
              )}
            </div>
          </button>
        )}
        {(
          stepQuestion.showMoveToNext === "save"
            ? questionHaveUpdates
            : stepQuestion.showMoveToNext
        ) ? (
          <MoveToNext
            stepId={stepId}
            questionId={questionId}
          />
        ) : (
          ""
        )}
      </div>
      {modalConfigDetails.configureKey === "uploadCSV" &&
      modalConfigDetails.metaData ? (
        ""
      ) : (
        <div className={`mx-2 my-2 mb-3`}>
          {disabled && modalConfigDetails.configureKey !== "uploadCSV" ? (
            <span
              className={`${styles.editText}`}
              role={"button"}
              onClick={editConfig}
            >
              <img
                className={`${styles.brushImg} me-2`}
                src={penBlue}
                alt="msg"
              />
              <span>Edit configuration</span>
            </span>
          ) : modalConfigDetails.status === "pending" ? (
            <div className={`${styles.pending} mt-1`}>
              <img
                src={PendingImg}
                className="me-2"
                alt=""
              />
              <span>Pending</span>
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
}

export default ConfigButton;
