import { useCallback, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "src/store/store";
import { IntegrationFailureMetaData } from "src/types/IntegrationFailure";
import styles from "./IntegrationFailureModal.module.scss";

interface Props {
  show: boolean;
  onHide: () => void;
  data: IntegrationFailureMetaData;
}

/**
 * IntegrationFailureModal component displays a modal dialog for integration failure scenarios
 *
 * @param props - Component properties as defined in the interface
 *
 * @returns React component representing the IntegrationFailureModal
 */
const IntegrationFailureModal = ({ show, onHide, data }: Props) => {
  // Navigate function from useNavigate hook
  const navigate = useNavigate();

  // Get the current scope
  const scopes = useAppSelector(
    (state) => state.globals.currentUserData?.scopes,
  );

  // Check the admin access
  const hasAdminAccess = useMemo(() => {
    // If we don't have admin access data then check the scope
    if (data.hasAdminAccess === undefined) {
      return scopes?.includes("settings_can_edit_email_signature") ?? false;
    }

    return data.hasAdminAccess;
  }, [data.hasAdminAccess, scopes]);

  /**
   * Helper function to navigate to appropriate settings page based on integration type
   */
  const navigationHelper = useCallback(() => {
    switch (data.type) {
      case "brand":
        // If we have brand email, then add it search params query
        // else open all brands page
        if (data.brand?.email) {
          navigate(`/settings/Brands?searchQuery=${data.brand.email}`);
        } else {
          navigate(`/settings/Brands`);
        }
        break;

      case "email":
        // If we have integration id open Email integration settings page
        // else open all email integrations page
        if (data.integrationId) {
          // Routes to last page as email integration automatically routes to correct page
          navigate(
            `/settings/integrations/email/${data.integrationId}/complete`,
          );
        } else {
          navigate(`/settings/integrations/email`);
        }
        break;

      case "whatsapp":
        if (data.liveChatIntegrationId) {
          navigate(`/live-chat/settings/${data.liveChatIntegrationId}/Website`);
        } else {
          navigate("/settings/integrations/whatsapp");
        }
        break;

      case "facebook":
        navigate("/settings/integrations/facebook");
        break;

      case "liveChat":
        // If we have integration id open LC integration settings page
        // else open all live chat integrations page
        if (data.integrationId) {
          navigate(`/live-chat/settings/${data.integrationId}/Website`);
        } else {
          navigate(`/live-chat/settings`);
        }
        break;

      default:
        break;
    }
  }, [
    data.brand?.email,
    data.integrationId,
    data.type,
    data.liveChatIntegrationId,
    navigate,
  ]);

  const channelName = useMemo(() => {
    return `${data.type[0].toUpperCase()}${data.type.slice(1)}`;
  }, [data.type]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      dialogClassName={`${styles.cannotSendModalDialog}`}
      contentClassName={`${styles.cannotSendModalContent}`}
      centered={true}
      backdropClassName={`${styles.cannotSendModalbackDrop}`}
      backdrop="static"
      style={{ zIndex: 1999 }} //Added to show in modals
    >
      {/* Modal wrapper */}
      <div className={`${styles.modalWrapper}`}>
        {/* Message */}
        <p className={`${styles.heading}`}>
          {hasAdminAccess
            ? `The ${channelName} integration ${
                data.brand?.name ? `for ${data.brand.name}` : ""
              } is currently not available. Please check the integration settings to make sure it is active.`
            : `The ${channelName} integration ${
                data.brand?.name ? `for ${data.brand.name}` : ""
              } is currently not available. Please contact your administrator to resolve the issue.`}
        </p>

        {/* Buttons */}
        <div
          className={`d-flex justify-content-${
            hasAdminAccess ? "between" : "end"
          } align-items-center`}
        >
          <button
            className={`${hasAdminAccess ? styles.closeBtn : styles.submitBtn}`}
            id={`failModalClose${hasAdminAccess ? "Admin" : ""}Btn`}
            onClick={onHide}
          >
            Close
          </button>

          {hasAdminAccess && (
            <button
              className={`${styles.submitBtn}`}
              id={`failModalCheck${data.type}Btn`}
              onClick={navigationHelper}
            >
              Check {data.type} integration
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default IntegrationFailureModal;
