import { useCallback, useEffect, useRef } from "react";
import Loader from "src/components/Loader";
import Pagination from "src/routes/TicketList/children/Pagination/Pagination";
import { fetchAllBrands, setBrandCachedPage, setBrandCurrentPage } from "src/store/slices/settings/brands/brandSettings.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import styles from "./BrandTable.module.scss";
import BrandRow from "./Children/BrandRow/BrandRow";

const BrandTable = () => {

  const {  filters, brandDataAjaxStatus, brandIds, metaData, brands, currentPage, totalPages, cachedData} =
  useAppSelector((state) => state.brandSettings);

  const dispatch = useAppDispatch();

  const currentState = useRef({cachedData: cachedData, currentPage : currentPage, brandDataAjaxStatus: brandDataAjaxStatus});

  const scopes = useAppSelector(
    (state) => state.globals.currentUserData?.scopes
  );
  
  useEffect(()=>{
    currentState.current = {cachedData: cachedData,currentPage: currentPage, brandDataAjaxStatus: brandDataAjaxStatus};
  },[currentPage, brandDataAjaxStatus, cachedData])

  const dispatchCurrentPage = useCallback((currentPage: any) => {
    // console.log(currentState.current.cachedData, currentPage)
    if (currentState.current.cachedData[currentPage]) {

      dispatch(setBrandCurrentPage(currentPage));
      dispatch(setBrandCachedPage({ pageNo: currentPage }));

    } else {

      if(currentPage !== currentState.current.currentPage && currentState.current.brandDataAjaxStatus !== "pending"){
        dispatch(setBrandCurrentPage(currentPage));
        dispatch(fetchAllBrands());
      }
      
    }
  }, []);

  return (
    <>
      <div className={`overflow-auto mb-2`}>
        <div className={`py-2 px-4 mt-2 d-flex ${styles.tableHeader} justify-content-between`}>
          <div className={`${styles.brand}`}>
            <span className={`${styles.headerText}`}>Brands</span>
          </div>
          <div className={`${styles.permission} px-4`}>
            <span className={`${styles.headerText}`}>Verification Status</span>
          </div>
          <div className={`px-2 ${styles.email}`}>
            <span className={`${styles.headerText}`}>Outbound Emails</span>
          </div>
          {/* <div className={`px-2 ${styles.sign}`}>
            <span className={`${styles.headerText}`}>Agent Signature</span>
          </div> */}
          <div className={`px-2 ${styles.permission}`}>
            <span className={`${styles.headerText}`}>Permission</span>
          </div>
          {scopes && scopes.includes("settings_can_edit_email_signature") ? (
          <div className={`px-2 ${styles.actions}`}>
            <span className={`${styles.headerText}`}>Actions</span>
          </div>
          ):null}
        </div>
        {brandDataAjaxStatus === "pending" ? (
          <Loader />
        ): (

        <div className={`pt-3`}>
        {brandIds.map((brandId:number|string)=>{
          const brandData = brands[brandId];
          return <BrandRow
                  key={brandId}
                  brandId={brandId}
                  brandImg={brandData.imageURL}
                  brandName={brandData.name}
                  isDefault={brandData.isDefault}
                  brandEmail={brandData.email}
                  signature={brandData.signature}
                  isActive={brandData.isActive}
                  brandData={brandData}
                  isVerified={brandData.isVerified}
                  />
        })}
        </div>
        )}

      </div>
      { brandDataAjaxStatus !== "pending" && brandIds.length !== 0 ? (
        <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={dispatchCurrentPage}
        />
        ): null}
    </>
  );
};
export default BrandTable;
