import {
  createAsyncThunk,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";
import { AJAXSTATUS } from "src/globals/constants";
import articleSettingExtraReducers from "./articleSetting.extraReducers";
import articleSettingReducers from "./articleSetting.reducers";
import articleSettingThunk from "./articleSetting.thunk";

export const fetchArticleSetting = createAsyncThunk(
  "articleSetting/fetchArticleSetting",
    articleSettingThunk.fetchArticleSettingThunk
);

interface IArticleData{
  articleId : number|string,
  articleName : string,
  articleStatus : "public"|"draft",
  categoryId : number|string,
  categoryName : string,
  articleDescription : string,
  searchKeyword : string,
  createdAt : any,
  createdAtGmt : any,
  attachments: Array<any>,
};

export interface IArticleSetting {
  articleData: IArticleData,
  articleSettingAjaxStatus: AJAXSTATUS,
};


export const initialState: IArticleSetting = {
  articleData:{
    articleId : 0,
    articleName : "",
    articleStatus : "draft",
    categoryId : 0,
    categoryName : "",
    articleDescription : "",
    searchKeyword : "",
    createdAt : "",
    createdAtGmt : "",
    attachments: [],
  },
  articleSettingAjaxStatus: "pending"
};

export const articleSettingSlice = createSlice({
  name: "articleSetting",
  initialState,
  reducers: articleSettingReducers,
  extraReducers: (builder) => {
    builder.addCase(
      fetchArticleSetting.fulfilled,
      articleSettingExtraReducers.fetchArticleSettingFulfilled
    );
    builder.addCase(fetchArticleSetting.rejected, (state) => {
      state.articleSettingAjaxStatus = "rejected";
    });
    builder.addCase(fetchArticleSetting.pending, (state) => {
      state.articleSettingAjaxStatus = "pending";
    });

  }
});

export const { resetArticleSetting } = articleSettingSlice.actions;

export default articleSettingSlice.reducer;
