import {useMemo} from "react";
import {Spinner} from "react-bootstrap";

function useChatHistoryISLoader(
  showLoader: boolean,
  text = "Loading Chats...",
  className?: string
) {
  return useMemo(() => {
    if (showLoader) {
      return (
        <div className={`m-auto max-content-width ${className}`}>
          <Spinner
            animation="border"
            variant="primary"
            size="sm"
            className={`d-block m-auto mb-2`}
          />
          <span>{text}</span>
        </div>
      );
    } else {
      return undefined;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showLoader]);
}
export default useChatHistoryISLoader;
