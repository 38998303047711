import { axiosJSON } from "src/globals/axiosEndPoints";

export interface IGetAgentInfoParam {
  ids: Array<string | number>;
}

interface AgentInfo{
  id: number|string;
  name: string;
  email: string,
  imageURL: null
}
export interface IGetAgentInfoRes {
  err: boolean;
  msg: string
  data: Array<AgentInfo>
}

export async function getAllAgentInfo(params: IGetAgentInfoParam) {
  const { data: res } = await axiosJSON.post(
    `/api/liveChat/agent/getAgentInfo`,
    params
  );

  if (res.err) {
    throw res.msg;
  }

  return res.data as Array<AgentInfo>;
}
