import styles from "./CheckoutOffers.module.scss";
import gift from "src/assets/images/gift.png";
import orderImg from "src/assets/images/order1.png";
import brandImg from "src/assets/images/brandImg.png";
import TimeLine from "../Timeline/Timeline";
import {
  StepName,
  useReturnPreviewState,
} from "../../../../../../hooks/useReturnPreview";
import ButtonWrapper from "../ButtonWrapper/ButtonWrapper";
import WidgetFooter from "../WidgetFooter/WidgetFooter";
import { useState } from "react";
import useGetAutomationData, {
  IReturnReason,
} from "src/features/ReturnAutoWorkFlow/hooks/useGetAutomationData";
import { useReturnAutoWorkFlow } from "src/features/ReturnAutoWorkFlow/hooks/useReturnAutoWorkFlow";

const CheckoutOffers = () => {
  const [showError, setShowError] = useState<boolean>(false);
  const { selectedOrder, orders, updateState, ...contextValues } =
    useReturnPreviewState();
  const {
    arsResponse,
    getAllReturnTypes,
    getAllReasons,
    isReturnReasonsAvailable,
    explainAnswer,
  } = useGetAutomationData();
  const { allReturnTypes } = getAllReturnTypes();
  const allReasons = getAllReasons();
  const { returnAutoWorkFlow } = useReturnAutoWorkFlow();
  const handleBack = () => {
    let backPage: StepName = "addReturnItems";
    // Check if any item's selected reason has moreDetails length > 0
    const hasMoreDetails = Object.values(selectedOrder?.addedItems ?? {}).some(
      (data) => {
        if (
          returnAutoWorkFlow.activeModalConfigDetails?.configureKey ===
          "returnReasonOrderAndItems"
        ) {
          const reason = (allReasons as IReturnReason[])?.find(
            (reason) => reason.id === data.addedReasonId,
          );
          return (
            reason &&
            reason.moreQuestions &&
            reason.moreQuestions.length > 0 &&
            explainAnswer === "requiredAndCustom"
          );
        }
        return false;
      },
    );
    // calculating backPage
    if (arsResponse?.isInformationAvailable) {
      backPage = "arsInformation";
    } else if (hasMoreDetails) {
      backPage = "moreDetails";
    } else if (isReturnReasonsAvailable) {
      backPage = "reason";
    } else {
      backPage = "returnType";
    }
    if (selectedOrder)
      updateState({
        ...contextValues,
        selectedOrder: {
          ...selectedOrder,
          activeStep: backPage,
        },
      });
  };
  const handleNext = () => {
    if (selectedOrder) {
      const addedItemsLength = Object.keys(
        selectedOrder?.addedItems ?? {},
      ).length;
      const selectOffersLength = Object.values(
        selectedOrder?.addedItems ?? {},
      ).filter((data) => data.dontReturnOrExchangeOffers != undefined).length;

      if (selectOffersLength > 0 && addedItemsLength === selectOffersLength) {
        // Check if at least one item has dontReturnOrExchangeOffers set to true
        const hasDontReturnOrExchangeOffers = Object.values(
          selectedOrder.addedItems ?? {},
        ).some((item) => item.dontReturnOrExchangeOffers === true);
        if (hasDontReturnOrExchangeOffers) {
          updateState({
            ...contextValues,
            selectedOrder: {
              ...selectedOrder,
              activeStep: "antiRefundStagePreview",
            },
          });
        } else {
          // If the next offer exists , set that active page
          let nextPage: StepName = "configureNextSteps";
          let prevStep: StepName = "arsOffers";

          if (arsResponse?.isExchangeAvailable) {
            if (
              arsResponse?.returnTypes &&
              arsResponse?.returnTypes?.length > 0
            ) {
              nextPage = "arsReturnTypes";
            } else {
              nextPage = "arsExchange";
            }
          }
          updateState({
            ...contextValues,
            selectedOrder: {
              ...selectedOrder,
              activeStep: nextPage,
              prevStepForConfig: prevStep,
            },
          });
        }
      } else {
        setShowError(true);
        return;
      }
    }
  };
  const handleOnChange = (
    dontReturnOrExchangeOffers: boolean,
    itemId?: string,
  ) => {
    if (itemId && selectedOrder) {
      setShowError(false);
      let updatedItems = selectedOrder?.addedItems ?? {};
      if (Object.keys(updatedItems).length > 0) {
        if (updatedItems[itemId]) {
          updatedItems[itemId].dontReturnOrExchangeOffers =
            dontReturnOrExchangeOffers;
        }
      }
      updateState({
        ...contextValues,
        selectedOrder: {
          ...selectedOrder,
          addedItems: updatedItems,
        },
      });
    }
  };

  return (
    <div>
      <div className={`${styles.widgetBody}`}>
        <TimeLine activeStep={selectedOrder?.activeStep ?? "selectOrder"} />
        <div className="mt-2">
          <div className="d-flex align-items-center mb-3">
            <img
              src={gift}
              alt=""
              width={21}
              height={20}
            />
            <span className={`ps-1 ${styles.heading}`}>Checkout offers!</span>
          </div>
          {showError && (
            <span className={`p-1 mb-1 ${styles.errorMsg}`}>
              * Please add required details for this item
            </span>
          )}
          <div className={`mb-2 ${styles.orderBox}`}>
            <p className={`mb-0 ${styles.offerHead}`}>
              1 offer available on this request!
            </p>
            {arsResponse?.offers.map((offerData) => {
              const itemData = selectedOrder?.itemsInOrder?.find(
                (value) => value.id + "" === offerData.itemId + "",
              );
              const item = (selectedOrder?.addedItems ?? {})[offerData.itemId];
              const returnType = allReturnTypes?.find(
                (returnType) => returnType.type == item?.selectedReturnType,
              );
              return (
                <div>
                  <span className={`d-block ${styles.offferSubHead}`}>
                    Return Type : <span>{returnType?.name ?? ""}</span>
                  </span>
                  <p className={`mb-1 mt-2 ${styles.orderHeading}`}>
                    {itemData?.name}
                  </p>
                  <div className={`d-flex mb-1`}>
                    <div className={`${styles.cart}`}>
                      <img
                        src={orderImg}
                        alt="order"
                        className={`${styles.orderImg}`}
                      />
                    </div>
                    <div className={`ms-2`}>
                      <span className={`${styles.orderName}`}>
                        {itemData?.unitPrice?.currencyCode}
                        {itemData?.unitPrice?.amount} x {item?.quantity}
                      </span>
                      <span className={`${styles.orderDesc}`}>
                        SKU : {itemData?.sku}
                      </span>
                    </div>
                  </div>
                  <span className={`${styles.moreItem}`}>+2 items</span>
                  {offerData.offers.map((offer) => {
                    return (
                      <div>
                        <div
                          className={`py-3 px-4 my-1 d-flex ${styles.offerBox}`}
                          style={{
                            background: `${offer?.backgroundColor} 0% 0% no-repeat padding-box`,
                          }}
                        >
                          <div className={`${styles.leftSection}`}>
                            <div>
                              <span className={`${styles.brand}`}>
                                <img
                                  src={brandImg}
                                  alt=""
                                  className={`${styles.brandImg}`}
                                />
                              </span>
                            </div>
                            <span
                              className={`pt-2 ${styles.percent} ${
                                offer.offerId == "offer002" && styles.clearance
                              }`}
                            >
                              {offer?.sideTitle ?? ""}
                            </span>
                            <p className={`${styles.off}`}>
                              {offer?.sideSubTitle ?? ""}
                            </p>
                          </div>
                          <div className={`ps-3 ${styles.rightSection}`}>
                            <p className={`mt-2 ${styles.offertext}`}>
                              {offer?.text ?? ""}
                            </p>
                            <label
                              className={`px-3 py-2 ${styles.applyBtn}`}
                              htmlFor={`${offerData.itemId}--${offer.offerId}--apply`}
                            >
                              Apply OFFER!
                            </label>
                          </div>
                        </div>
                        <div className="form-check">
                          <input
                            className={`form-check-input ${styles.radioCheck} ${
                              showError && "border-danger"
                            }`}
                            type="radio"
                            name="checkoutOne"
                            id={`${offerData.itemId}--${offer.offerId}--apply`}
                            checked={item?.dontReturnOrExchangeOffers === true}
                            onClick={() => {
                              handleOnChange(true, itemData?.id);
                            }}
                          />
                          <label
                            className={`form-check-label ${styles.radioLabel}`}
                            htmlFor={`${offerData.itemId}--${offer.offerId}--apply`}
                          >
                            Apply the offer
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className={`form-check-input ${styles.radioCheck} ${
                              showError && "border-danger"
                            }`}
                            type="radio"
                            name="checkoutOne"
                            id={`${offerData.itemId}--${offer.offerId}--cancel`}
                            checked={item?.dontReturnOrExchangeOffers === false}
                            onClick={() => {
                              handleOnChange(false, itemData?.id);
                            }}
                          />
                          <label
                            className={`form-check-label ${styles.radioLabel}`}
                            htmlFor={`${offerData.itemId}--${offer.offerId}--cancel`}
                          >
                            Proceed with return
                          </label>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <ButtonWrapper
        onBackClick={handleBack}
        onNextClick={handleNext}
      />
      <WidgetFooter />
    </div>
  );
};

export default CheckoutOffers;

{
  /* <div className={`mb-2 ${styles.orderBox}`}>
  <p className={`mb-0 ${styles.offerHead}`}>
    1 offer available on this request!
  </p>
  <span className={`d-block ${styles.offferSubHead}`}>
    Return Type : <span>Refund to original source</span>
  </span>
  <p className={`mb-1 mt-2 ${styles.orderHeading}`}>
    Full top bottom set<span className="ps-1"></span>
  </p>

  <div className={`d-flex mb-1`}>
    <div className={`${styles.cart}`}>
      <img
        src={orderImg}
        alt="order"
        className={`${styles.orderImg}`}
      />
    </div>
    <div className={`ms-2`}>
      <span className={`${styles.orderName}`}>$ 789 x 2</span>
      <span className={`${styles.orderDesc}`}>SKU : 0101</span>
    </div>
  </div>
  <span className={`${styles.moreItem}`}>+2 items</span>
  <div className={`py-3 px-4 my-1 d-flex ${styles.saleBox}`}>
    <div className={`${styles.leftSection} w-50`}>
      <div>
        <span className={`${styles.brand}`}>
          <img
            src={brandImg}
            alt=""
            className={`${styles.brandImg}`}
          />
        </span>
      </div>
      <span className={`pt-2 ${styles.percent}`}></span>
      <p className={`${styles.off}`}>Clearance sale!</p>
    </div>
    <div className={`ps-3 ${styles.rightSection}`}>
      <p className={`mt-2 ${styles.offertext}`}>
        Get a free item from clearance sale instead of this exchange
      </p>
      <label
        className={`px-3 py-2 ${styles.applyBtn}`}
        htmlFor=""
      >
        Apply OFFER!
      </label>
      `
    </div>
  </div>
  <div className="form-check">
    <input
      className={`form-check-input ${styles.radioCheck}`}
      type="radio"
      name="checkoutTwo"
      id="checkout3"
    />
    <label
      className={`form-check-label ${styles.radioLabel}`}
      htmlFor="checkout3"
    >
      Apply the offer
    </label>
  </div>
  <div className="form-check">
    <input
      className={`form-check-input ${styles.radioCheck}`}
      type="radio"
      name="checkoutTwo"
      id="checkout4"
    />
    <label
      className={`form-check-label ${styles.radioLabel}`}
      htmlFor="checkout4"
    >
      Proceed with return
    </label>
  </div>
</div>; */
}
