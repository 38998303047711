import styles from "./AddTagsBtn.module.scss";

function AddTagsBtn({ ...props }: any) {
  return (
    <div className={`px-2 py-1 user-select-none ${styles.root}`} {...props}>
      <span className={`${styles.plusIconCont} me-2`}>
        <i className={`fas fa-plus m-2`} />
      </span>
      Add Tags
    </div>
  );
}

export default AddTagsBtn;
