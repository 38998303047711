import { Spinner } from "react-bootstrap";
import AxiosImg from "src/components/AxiosImg";
import UserAvatar from "src/components/UserAvatar";
import { useAppSelector } from "src/store/store";
import styles from "../../../../HistoryInnerView.module.scss";

type Props = {
  imageURL?: string | null;
  name: string;
  isFetchingMore: any;
  fetchMoreMessage: any;
  isPublicAttachmentUrl?: boolean;
};

function ShowMoreFooter({
  isFetchingMore,
  fetchMoreMessage,
  name,
  imageURL,
  isPublicAttachmentUrl,
}: Props) {
  return (
    <div className="d-flex px-4 mb-3">
      <div className={`${styles.imgWrapper} my-auto`}>
        {imageURL ? (
          <AxiosImg url={imageURL} className={`rounded-circle`} isDirectURL={isPublicAttachmentUrl} />
        ) : (
          <UserAvatar name={name ?? "NA"} size={32} />
        )}
      </div>
      <div className={`ms-2 my-auto d-flex`}>
        <span
          className={`my-auto d-flex justify-content-center align-items-center ${styles.seeMore}`}
        >
          <i className="fa-solid fa-ellipsis"></i>
        </span>
        <span
          onClick={fetchMoreMessage}
          className={`ps-2 my-auto d-flex justify-content-center align-items-center cursor-pointer ${styles.readChat}`}
        >
          <span>Read more</span>
          {isFetchingMore ? (
            <Spinner
              className={`${styles.readMoreChatSpinner} ms-1`}
              animation="border"
              size="sm"
            />
          ) : (
            <span>
              <i className="ms-1 fa-solid fa-chevron-down"></i>
            </span>
          )}
        </span>
      </div>
    </div>
  );
}

export default ShowMoreFooter;
