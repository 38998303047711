import { GetTrackingData } from "src/services/ShopifySidebar/getTrackingData";
import { OrderSliceState } from "./OrderSliceState";

// reset shopify single customer data
const resetShopifyCustomerData = (state: OrderSliceState) => {
  state.shopifySingleCustomerData.getShopifyCustomerDataAjaxStatus = "idle";
  state.shopifySingleCustomerData.customerData = undefined;
};

// reset get fulfillment data
const resetGetFulfillmentData = (state: OrderSliceState) => {
  state.getFulfillment = undefined;
};

// reset refund modal data
const resetRefundModalData = (state: OrderSliceState) => {
  state.refundModel = undefined;
};

export default { resetShopifyCustomerData, resetGetFulfillmentData, resetRefundModalData };
