import SetConditionWidget from "../../../../../TargetWidget/SetConditionWidget/SetConditionWidget";

const WidgetSection = () => {
  return (
    <>
      <SetConditionWidget />
    </>
  );
};
export default WidgetSection;
