import {
  StepData,
  StepQuestion as StepQuestionI,
} from "../../../../ReturnAutoWorkFlow.types";
import GenericQuestion from "./children/GenericQuestion/GenericQuestion";
import EmailForwarding from "./children/specificQuestions/EmailForwarding/EmailForwarding";
import StripeQuestion from "./children/specificQuestions/StripeQuestion/StripeQuestion";
import WebhookVerify from "./children/specificQuestions/WebhookVerify/WebhookVerify";

/**
 * StepQuestion component.
 *
 * This component displays the title of a step question.
 *
 * @param props - The component props.
 * @param props.stepQuestion - The question, which is viewable.
 * @param props.stepData - The data of the step.
 *
 * @returns The rendered component.
 */
function StepQuestion({
  stepQuestion,
  stepData,
  updateReturnPreview,
}: {
  stepQuestion: StepQuestionI;
  stepData: StepData;
  updateReturnPreview: ({
    stepQuestion,
  }: {
    stepQuestion: StepQuestionI | null;
  }) => void;
}) {
  return (
    <div>
      {stepQuestion.specificQuestionType === "emailVerification" ? (
        <EmailForwarding
          stepId={stepData.stepId}
          questionId={stepQuestion.questionId}
          updateReturnPreview={updateReturnPreview}
        />
      ) : stepQuestion.specificQuestionType === "stripeDetails" ? (
        <StripeQuestion
          stepId={stepData.stepId}
          questionId={stepQuestion.questionId}
          updateReturnPreview={updateReturnPreview}
        />
      ) : stepQuestion.specificQuestionType === "webhookQuestion" ? (
        <WebhookVerify
          stepId={stepData.stepId}
          questionId={stepQuestion.questionId}
          updateReturnPreview={updateReturnPreview}
        />
      ) : (
        <GenericQuestion
          stepId={stepData.stepId}
          questionId={stepQuestion.questionId}
          updateReturnPreview={updateReturnPreview}
        />
      )}
    </div>
  );
}

export default StepQuestion;
