import React, { useCallback, useState } from "react";
import styles from "./EditNotification.module.scss";
import FilterBox from "./Children/FilterBox/FilterBox";
import ReportName from "./Children/ReportName/ReportName";
import EditSendingFormat from "./Children/EditSendingFormat/EditSendingFormat";
import SentToAgents from "./Children/SentToAgents/SentToAgents";
import ShowFrequency from "./Children/ShowFrequency/ShowFrequency";
import {
  ReportNotification,
  reportNotificationsActions,
  useNotifications,
} from "../../hooks/notifications/useNotifications";
import addNotificationService, {
  ReportNotificationParams,
} from "src/services/report/addNotification.service";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { Spinner } from "react-bootstrap";
import updateNotificationService, {
  UpdateReportNotificationParams,
} from "src/services/report/updateNotification.service";
import { CurrentStatusEndPoints } from "src/services/report/reportTicketList.service";
import NotificationModal from "../NotificationModal/NotificationModal";
import {
  reportInternalFiltersActions,
  useReportInternalFilters,
} from "src/routes/Report/hooks/reportFilters/useReportInternalFilters";

const generatePayload = (
  payload: ReportNotification,
  context: CurrentStatusEndPoints,
) => {
  if (!payload.reportName.trim()) {
    throw new Error("Report name missing.");
  }
  if (!payload.sendingFormatId) {
    throw "Sending format not selected.";
  }
  if (payload.id === "new") {
    return {
      addOrUpdateService: addNotificationService,
      payload: {
        context: context,
        reportName: payload.reportName,
        globalFiltersApplied: payload.globalFiltersApplied,
        internalFiltersApplied: payload.internalFiltersApplied,
        sidebarFiltersApplied: payload.sidebarFiltersApplied,
        sendingFormatId: payload.sendingFormatId,
      } as ReportNotificationParams,
    };
  } else {
    return {
      addOrUpdateService: updateNotificationService,
      payload: {
        context: context,
        id: payload.id,
        reportName: payload.reportName,
        globalFiltersApplied: payload.globalFiltersApplied,
        internalFiltersApplied: payload.internalFiltersApplied,
        sidebarFiltersApplied: payload.sidebarFiltersApplied,
        sendingFormatId: payload.sendingFormatId,
      } as UpdateReportNotificationParams,
    };
  }
};

const useEditNotification = () => {
  const { reportNotifications, dispatch } = useNotifications();
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const { dispatch: dispatchIF } = useReportInternalFilters();

  const seeAllnotification = useCallback(() => {
    dispatch([reportNotificationsActions.setActiveView, "list"]);
  }, [dispatch]);

  const clearEditingNotificationData = useCallback(() => {
    dispatch([reportNotificationsActions.clearEditingNotificationData, {}]);
    dispatchIF([reportInternalFiltersActions.setSidebarFilters, null]);
  }, [dispatch]);

  const saveNotification = useCallback(() => {
    // Check if the component is not in a loading state
    if (!loading) {
      // Check if editingNotificationData and context are available
      if (
        reportNotifications.editingNotificationData?.id &&
        reportNotifications.context
      ) {
        try {
          // Generate payload for the notification
          const { payload, addOrUpdateService } = generatePayload(
            reportNotifications.editingNotificationData,
            reportNotifications.context,
          );

          // Hide any previous errors
          setShowError(false);

          // Set loading state to true, indicating an ongoing operation
          setLoading(true);

          // Call the addOrUpdateService with the generated payload
          addOrUpdateService(payload as any)
            .then((res) => {
              // After successfully adding/updating the notification, refresh the list of notifications
              seeAllnotification();
              clearEditingNotificationData();
            })
            .catch((err) => {
              // Display a toast with an error message if there is an issue
              pushTheToast({
                type: "danger",
                text: "Error adding notification",
                position: "top-right",
              });
            })
            .finally(() => {
              // Regardless of success or failure, set loading state back to false
              setLoading(false);
            });
        } catch {
          // If an error occurs during payload generation, set showError to true
          setShowError(true);
        }
      } else {
        // Display a warning toast if not all required data is available
        pushTheToast({
          type: "warning",
          text: "Everything needs to be selected",
          position: "top-right",
        });
      }
    }
  }, [reportNotifications, seeAllnotification, loading]);

  return {
    saveNotification,
    loading,
    seeAllnotification,
    showError,
    reportNotifications,
    clearEditingNotificationData,
  };
};

const EditNotification = () => {
  const {
    saveNotification,
    loading,
    seeAllnotification,
    showError,
    reportNotifications,
    clearEditingNotificationData,
  } = useEditNotification();
  // State to manage opening and closing of preview modal
  const [showModal, setShowModal] = useState(false);

  // Preview Modal Handler
  const toggleModal = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal]);

  return (
    <div>
      <div className="">
        <div
          className={`d-flex flex-wrap justify-content-between align-items-center`}
        >
          <h3 className={`${styles.mainHead}`}>
            Total number of unresolved tickets
          </h3>
          <span
            className={`${styles.seeNote}`}
            onClick={() => {
              clearEditingNotificationData();
              seeAllnotification();
            }}
          >
            See all notifications
          </span>
        </div>
        <div className={`px-3 py-3 mt-4 mb-3 ${styles.editWrapper}`}>
          <h3 className={`mb-0  ${styles.heading}`}>Edit Notification:</h3>
          <ReportName showError={showError} />
          <div className="mt-1 d-flex flex-wrap justify-content-between align-items-center">
            <EditSendingFormat showError={showError} />
            <div className="d-flex align-items-center flex-wrap mt-0">
              <SentToAgents />
              <ShowFrequency />
            </div>
          </div>
          <FilterBox />
          <div className="mt-4 pt-3 d-flex">
            <button
              className={`me-2 py-1 ${styles.previewBtn}`}
              onClick={toggleModal}
            >
              Preview
            </button>
            <button
              className={`py-1 ${styles.saveBtn} d-flex`}
              style={{ cursor: loading ? "default" : "pointer" }}
              onClick={saveNotification}
            >
              {loading && (
                <Spinner
                  className="m-auto me-1"
                  animation="border"
                  size="sm"
                />
              )}
              <span className="m-auto">{loading ? "Saving" : "Save"}</span>
            </button>
          </div>
        </div>
      </div>
      {/* Preview Modal */}
      {reportNotifications.editingNotificationData &&
      reportNotifications.context ? (
        <NotificationModal
          onHide={toggleModal}
          showModal={showModal}
          notificationData={reportNotifications.editingNotificationData}
          context={reportNotifications.context}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default EditNotification;
