import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { PasswordMustContain } from "src/routes/Auth/ResetPassword";
import { changePasswordUser } from "src/services/Settings/Users/changePasswordUser";
import { passwordValidate } from "src/utils/utils";
import styles from "./ChangePassword.module.scss";
import pass from "src/assets/images/6.png";
import showPassImg from "src/assets/images/7.png";

function ChangePassword() {
  const [currentPass, setCurrentPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [reNewPass, setReNewPass] = useState("");
  const [currentPassShow, setCurrentPassShow] = useState(false);
  const [newPassShow, setNewPassShow] = useState(false);
  const [reNewPassShow, setReNewPassShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showCross, setShowCross] = useState(false);
  const [focusedEle, setFocusedEle] = useState(
    "pass" as "pass" | "re-pass" | "curr-pass"
  );

  const submitPassChange = () => {
    if (
      reNewPass !== newPass ||
      !passwordValidate(newPass) ||
      !passwordValidate(currentPass)
    ) {
      setShowCross(true);
      pushTheToast({
        type: "warning",
        text: "Please verify passwords!",
        position: "top-right",
      });
    } else {
      setLoading(true);
      changePasswordUser({
        currentPassword: currentPass,
        newPassword: newPass,
      })
        .then(() => {
          setLoading(false);
          pushTheToast({
            type: "success",
            text: "Password changed successfully!",
            position: "top-right",
          });
        })
        .catch(() => {
          setLoading(false);
          pushTheToast({
            type: "danger",
            text: "Please enter correct current password!",
            position: "top-right",
          });
        });
    }
  };
  return (
    <div className={`main__content`}>
      <div className=" ">
        <div className="">
          <h3 className={`${styles.page_heading}  `}>Change Password</h3>
          <p className={`${styles.heading_tagline}  `}>
            Enter your current password to confirm your identity, then the new
            password you would like to set instead.
          </p>
        </div>
      </div>

      <div className={`px-0 px-md-2 px-lg-5 mt-2 mt-md-5`}>
        <div className="row">
          <div className="col-md-6 col-sm-12 col-12">
            <div className={`${styles.password_form}`}>
              <div className="mt-3 ">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`${styles.form_label} form-label ${styles.required} required`}
                >
                  Current Password
                </label>
                <div
                  className={`${styles.input_box} ${
                    showCross && !passwordValidate(currentPass)
                      ? styles.notValid
                      : ""
                  } d-flex align-items-center justify-content-between border ps-2 col-md-12`}
                >
                  <input data-lpignore="true" 
                    type={currentPassShow ? "text" : "password"}
                    placeholder="Enter your current password"
                    className={`form-control ${styles.form_control}`}
                    value={currentPass}
                    onChange={(e) => {
                      setCurrentPass(e.target.value);
                    }}
                    onFocus={() => {
                      setFocusedEle("curr-pass");
                    }}
                  />
                  <img
                    src={currentPassShow ? `${showPassImg}` : `${pass}`}
                    alt="tick"
                    className={`${styles.eyeIcon} me-2 toggle-password`}
                    onClick={() => {
                      setCurrentPassShow(!currentPassShow);
                    }}
                  />
                </div>
              </div>
              <div className="mt-4 col-md-12">
                <label
                  htmlFor="exampleInputPassword1"
                  className={`${styles.form_label} form-label ${styles.required} required`}
                >
                  New Password
                </label>
                <div
                  className={`${styles.input_box} ${
                    showCross &&
                    !passwordValidate(newPass) &&
                    newPass.trim() !== ""
                      ? styles.notValid
                      : ""
                  } d-flex align-items-center justify-content-between border ps-2`}
                >
                  <input data-lpignore="true" 
                    type={newPassShow ? "text" : "password"}
                    placeholder="Enter your new password"
                    className={`form-control ${styles.form_control}`}
                    value={newPass}
                    onChange={(e) => {
                      setNewPass(e.target.value);
                    }}
                    onFocus={() => {
                      setFocusedEle("pass");
                    }}
                  />
                  <img
                    src={newPassShow ? `${showPassImg}` : `${pass}`}
                    alt="tick"
                    className={`${styles.eyeIcon} me-2 toggle-password`}
                    onClick={() => {
                      setNewPassShow(!newPassShow);
                    }}
                  />
                </div>
              </div>
              <div className="mt-4 mb-3 col-md-12">
                <label
                  htmlFor="exampleInputPassword1"
                  className={`${styles.form_label} form-label ${styles.required} required`}
                >
                  Confirm Password
                </label>
                <div
                  className={`${styles.input_box} ${
                    showCross &&
                    (newPass !== reNewPass || !passwordValidate(reNewPass)) &&
                    reNewPass.trim() !== ""
                      ? styles.notValid
                      : ""
                  } d-flex align-items-center justify-content-between border ps-2`}
                >
                  <input data-lpignore="true" 
                    type={reNewPassShow ? "text" : "password"}
                    placeholder="Re-enter password"
                    className={`form-control ${styles.form_control}`}
                    value={reNewPass}
                    onChange={(e) => {
                      setReNewPass(e.target.value);
                    }}
                    onFocus={() => {
                      setFocusedEle("re-pass");
                    }}
                  />
                  <img
                    src={reNewPassShow ? `${showPassImg}` : `${pass}`}
                    alt="tick"
                    className={`${styles.eyeIcon} me-2 toggle-password`}
                    onClick={() => {
                      setReNewPassShow(!reNewPassShow);
                    }}
                  />
                </div>
                {showCross && newPass !== reNewPass && reNewPass.trim() !== "" && (
                  <>
                    <div className={`${styles.notMatched}`}>
                      The password does not match
                    </div>
                  </>
                )}
              </div>
              <div>
                <button
                  className={` ${styles.pink_btn_submit}  ${styles.btn} d-flex p-2`}
                  onClick={submitPassChange}
                >
                  {loading && (
                    <Spinner
                      className="my-auto mx-1"
                      animation="border"
                      color="white"
                      size="sm"
                    />
                  )}
                  <span className="my-auto mx-1">
                    {loading ? "Updating..." : "Update Password"}
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-12 col-12">
            <PasswordMustContain
              password={
                focusedEle === "pass"
                  ? newPass
                  : focusedEle === "curr-pass"
                  ? currentPass
                  : reNewPass
              }
              showCross={showCross}
              className={`mt-3`}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
