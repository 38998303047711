import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { MessagesData } from "src/services/LiveChat/liveChatHistory/getLiveChatCustomerAllMessages.service";
import {
  getAllMessages,
  Message,
} from "src/services/LiveChat/messageExchange/getMessages";

const useChatCreated = (messageData: MessagesData) => {
  const {
    chatRequestId,
    chatStartedOnUTC,
    lastMessageTimeUTC,
    chatStatus,
    initialMessages,
    customerEmail,
  } = useMemo(() => {
    const {
      chatRequestId,
      chatStartedOnUTC,
      lastMessageTimeUTC,
      chatStatus,
      messages,
      customerEmail,
    } = messageData;
    return {
      chatRequestId,
      chatStartedOnUTC,
      lastMessageTimeUTC,
      chatStatus,
      initialMessages: messages,
      customerEmail,
    };
  }, [messageData]);
  const [isResolved, setIsResolved] = useState(messageData.isResolved);
  const [ticketId, setTicketId] = useState(messageData.ticketId);

  const totalCount = useMemo(
    () => messageData.messagesTotalCount ?? 0,
    [messageData]
  );
  const [isFetchingMore, setIsFetchingMore] = useState(false);

  const getFromInitial = useCallback(() => {
    const messageIds: Array<number | string> = [];
    initialMessages.forEach((msg) => {
      messageIds.push(msg.messageId);
    });
    return messageIds;
  }, [initialMessages]);
  const getFromInitialObj = useCallback(() => {
    const messagesObj: { [id: number | string]: Message } = {};
    initialMessages.forEach((msg) => {
      messagesObj[msg.messageId] = msg;
    });
    return messagesObj;
  }, [initialMessages]);

  const [messages, setMessages] = useState(
    getFromInitialObj() as { [id: number | string]: Message }
  );
  const [messageIds, setMessageIds] = useState(
    getFromInitial() as Array<number | string>
  );
  const [hasMoreData, setHasMoreData] = useState(
    totalCount > messageIds.length
  );

  const fetchMoreMessage = useCallback(() => {
    const lastMsgId = messageIds[messageIds.length - 1];
    // Checking is last message id exists
    if (lastMsgId) {
      setIsFetchingMore(true);
      getAllMessages({
        chatRequestId,
        lastMessageId: lastMsgId,
        scrollDirection: "new",
        limit: 200,
        sortBy: "asc",
        getActiveCustomer: false,
        context: "history",
        tabContext: chatStatus,
      })
        .then((res) => {
          setMessages((msg) => ({ ...msg, ...res.messages }));
          setMessageIds((ids) => [...ids, ...res.messageIdList]);
          // Setting has more based on returned flag.
          setHasMoreData(res.metaData.limitReachedNew ? false : true);
        })
        .catch((err) => {})
        .finally(() => {
          setIsFetchingMore(false);
        });
    }
  }, [chatRequestId, messageIds.length]);

  const myDivRef = useRef<HTMLDivElement>(null);

  const [divHeight, setDivHeight] = useState<number | null>(null);

  useLayoutEffect(() => {
    if (myDivRef.current) {
      const height = myDivRef.current.offsetHeight;
      setDivHeight(height);
    }
  }, []);

  const [showMoreButton, setShowMoreButton] = useState(true);

  let showMore = useMemo(() => {
    if (totalCount > messageIds.length) {
      setShowMoreButton(true);
      return true;
    } else if (totalCount === messageIds.length) {
      setShowMoreButton(false);
      return false;
    }
  }, [totalCount, messageIds]);

  const handleShowLess = useCallback(() => {
    if (myDivRef.current) {
      myDivRef.current.style.height = `${divHeight}px`;
      myDivRef.current.scrollIntoView({ behavior: "smooth" });
      myDivRef.current.style.overflow = "hidden";
    }
    setShowMoreButton(true);
  }, [divHeight]);

  const handleShowMoreButton = useCallback(() => {
    if (totalCount === messageIds.length) {
      setShowMoreButton(false);
      if (myDivRef.current) {
        myDivRef.current.style.height = `auto`;
        myDivRef.current.style.overflow = "visible";
      }
    } else {
      setShowMoreButton(false);
      fetchMoreMessage();
      if (myDivRef.current) {
        myDivRef.current.style.height = `auto`;
        myDivRef.current.style.overflow = "visible";
      }
    }
  }, [totalCount, messageIds, setShowMoreButton, fetchMoreMessage, myDivRef]);

  return {
    isResolved,
    chatRequestId,
    chatStartedOnUTC,
    lastMessageTimeUTC,
    chatStatus,
    customerEmail,
    messages,
    messageIds,
    ticketId,
    hasMoreData,
    isFetchingMore,
    setIsResolved,
    setTicketId,
    myDivRef,
    handleShowLess,
    showMore,
    showMoreButton,
    handleShowMoreButton,
    totalCount,
  };
};

export default useChatCreated;
