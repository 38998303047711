/**
 * This file contains the custom hook for handling KB settings.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import useThemeBox from "../../../ArticleSideBar/ThemeSelection/Children/ThemeBox/useThemeBox";
import { useAppDispatch } from "src/store/store";
import { updateWording } from "src/store/slices/knowledgeBase/knowledgeBase.slice";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getAllLanguages } from "src/services/LiveChat/Settings/getAllLanguages";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { resetKnoweledgeBaseLanguageDefault } from "src/services/KnowledgeBase/Settings/Styles/resetThemeLanguageDefault.service";
import { resetCustomiseTextDefaultInTheme } from "src/services/KnowledgeBase/Settings/Styles/resetCustomiseTextDefaultInTheme";

/**
 * Custom hook for managing message settings in Knowledge Base.
 * Handles mutations, state, and interactions related to message settings.
 */
function useMessageSetting() {
  // Mutation Function for resetLanguageDefault
  const resetLanguageDefault = useMutation({
    mutationFn: resetKnoweledgeBaseLanguageDefault,
    mutationKey: ["resetKnoweledgeBaseLanguageDefault"],
    onSuccess: () => {
      pushTheToast({
        position: "top-right",
        text: "Language reset to default",
        type: "success",
      });
      getThemeData.refetch();
    },
  });

  // Mutation Function for resetCustomiseTextDefault
  const resetCustomiseTextDefault = useMutation({
    mutationFn: resetCustomiseTextDefaultInTheme,
  });

  // States and variables
  const [dropdown, setDropDown] = useState(false);
  const [styleTheme, setStyleTheme] = useState(false);
  const [font] = useState(false);
  const [language, setLanguage] = useState(false);
  const [customize, setCustomize] = useState(false);

  const { integrationId } = useParams();
  const pageIntegrationId = integrationId
    ? decodeURIComponent(integrationId)
    : "";

  // Getting data from useThemeBox
  const { data: themeData, updateKBSettings, getThemeData } = useThemeBox();

  const [headline, setHeadline] = useState(
    themeData?.heading || "How can we help you?"
  );
  const [subHeading, setSubHeading] = useState(themeData?.subheading);
  const [placeholderText, setPlaceholderText] = useState(
    themeData?.placeholder || "Search for answers"
  );

  const [zeroArticle, setZeroArticle] = useState(themeData?.zero_article ?? "");

  const [oneArticle, setOneArticle] = useState(themeData?.one_article ?? "");

  const [articleCount, setArticleCount] = useState(
    themeData?.articleCount ?? ""
  );

  const [noTitleArticleText, setNoTitleArticleText] = useState(
    themeData?.noTitleArticleText ?? ""
  );

  const dispatch = useAppDispatch();

  const [textChanged, setTextChanged] = useState(true);

  // Ref for timeout so that we can do the mutation after given time interval
  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    return () => {
      if (timeoutIdRef.current !== null) {
        clearTimeout(timeoutIdRef.current);
      }
    };
  }, []);

  // Functon for handling headline changes and setting the timeout for mutation
  const handleHeadlineChanges = (e: any) => {
    setTextChanged(true);
    dispatch(
      updateWording({
        property: "headline",
        value: e.target.value,
      })
    );
    setHeadline(e.target.value);

    if (timeoutIdRef.current !== null) {
      clearTimeout(timeoutIdRef.current);
    }
    timeoutIdRef.current = setTimeout(() => {
      setTextStatus({
        ...textStatus,
        headline: "Updating...",
      });
      updateKBSettings.mutate(
        {
          integrationId: pageIntegrationId,
          themeId: themeData.themeId,
          heading: e.target.value,
        },
        {
          onSuccess: () => {
            setTextStatus({
              ...textStatus,
              headline: "Updated",
            });
            setTimeout(() => {
              setTextStatus({
                ...textStatus,
                headline: "",
              });
            }, 700);
          },
          onError: () => {
            setTextStatus({
              ...textStatus,
              headline: "Failed",
            });
          },
        }
      );
    }, 500);
  };

  // State for Status of the text
  const [textStatus, setTextStatus] = useState({
    headline: "",
    subheading: "",
    placeholder: "",
    zeroArticle: "",
    oneArticle: "",
    articleCount: "",
    noTitleArticleText: "",
  });

  // Functon for handling subheadline changes and setting the timeout for mutation , sort of debouncing
  const handleSubHeadlineChanges = (e: any) => {
    setTextChanged(true);
    dispatch(
      updateWording({
        property: "subheading",
        value: e.target.value,
      })
    );
    setSubHeading(e.target.value);
    if (timeoutIdRef.current !== null) {
      clearTimeout(timeoutIdRef.current);
    }
    timeoutIdRef.current = setTimeout(() => {
      setTextStatus({
        ...textStatus,
        subheading: "Updating...",
      });
      updateKBSettings.mutate(
        {
          integrationId: pageIntegrationId,
          themeId: themeData.themeId,
          subheading: e.target.value,
        },
        {
          onSuccess: () => {
            setTextStatus({
              ...textStatus,
              subheading: "Updated",
            });
            setTimeout(() => {
              setTextStatus({
                ...textStatus,
                subheading: "",
              });
            }, 700);
          },
          onError: () => {
            setTextStatus({
              ...textStatus,
              subheading: "Failed",
            });
          },
        }
      );
    }, 500);
  };

  // Functon for handling search input changes and setting the timeout for mutation , sort of debouncing
  const handleSearchInputChanges = (e: any) => {
    setTextChanged(true);
    dispatch(
      updateWording({
        property: "placeholderText",
        value: e.target.value,
      })
    );
    setPlaceholderText(e.target.value);
    if (timeoutIdRef.current !== null) {
      clearTimeout(timeoutIdRef.current);
    }
    timeoutIdRef.current = setTimeout(() => {
      setTextStatus({
        ...textStatus,
        placeholder: "Updating...",
      });
      updateKBSettings.mutate(
        {
          integrationId: pageIntegrationId,
          themeId: themeData.themeId,
          placeholder: e.target.value,
        },
        {
          onSuccess: () => {
            setTextStatus({
              ...textStatus,
              placeholder: "Updated",
            });
            setTimeout(() => {
              setTextStatus({
                ...textStatus,
                placeholder: "",
              });
            }, 700);
          },
          onError: () => {
            setTextStatus({
              ...textStatus,
              placeholder: "Failed",
            });
          },
        }
      );
    }, 500);
  };

  // Functon for handling zero article changes and setting the timeout for mutation , sort of debouncing
  const handleZeroArticleChanges = (e: any) => {
    setZeroArticle(e.target.value);
    if (timeoutIdRef.current !== null) {
      clearTimeout(timeoutIdRef.current);
    }
    timeoutIdRef.current = setTimeout(() => {
      setTextStatus({
        ...textStatus,
        zeroArticle: "Updating...",
      });
      updateKBSettings.mutate(
        {
          integrationId: pageIntegrationId,
          themeId: themeData.themeId,
          zeroArticle: e.target.value,
        },
        {
          onSuccess: () => {
            setTextStatus({
              ...textStatus,
              zeroArticle: "Updated",
            });
            setTimeout(() => {
              setTextStatus({
                ...textStatus,
                zeroArticle: "",
              });
            }, 700);
          },
          onError: () => {
            setTextStatus({
              ...textStatus,
              zeroArticle: "Failed",
            });
          },
        }
      );
    }, 500);
  };

  // Functon for handling one article changes and setting the timeout for mutation , sort of debouncing
  const handleOneArticleChanges = (e: any) => {
    setOneArticle(e.target.value);
    if (timeoutIdRef.current !== null) {
      clearTimeout(timeoutIdRef.current);
    }
    timeoutIdRef.current = setTimeout(() => {
      setTextStatus({
        ...textStatus,
        oneArticle: "Updating...",
      });
      updateKBSettings.mutate(
        {
          integrationId: pageIntegrationId,
          themeId: themeData.themeId,
          oneArticle: e.target.value,
        },
        {
          onSuccess: () => {
            setTextStatus({
              ...textStatus,
              oneArticle: "Updated",
            });
            setTimeout(() => {
              setTextStatus({
                ...textStatus,
                oneArticle: "",
              });
            }, 700);
          },
          onError: () => {
            setTextStatus({
              ...textStatus,
              oneArticle: "Failed",
            });
          },
        }
      );
    }, 500);
  };

  // Functon for handling article count changes and setting the timeout for mutation , sort of debouncing
  const handleArticleCountChanges = (e: any) => {
    setArticleCount(e.target.value);
    if (timeoutIdRef.current !== null) {
      clearTimeout(timeoutIdRef.current);
    }
    timeoutIdRef.current = setTimeout(() => {
      setTextStatus({
        ...textStatus,
        articleCount: "Updating...",
      });
      updateKBSettings.mutate(
        {
          integrationId: pageIntegrationId,
          themeId: themeData.themeId,
          articleCount: e.target.value,
        },
        {
          onSuccess: () => {
            setTextStatus({
              ...textStatus,
              articleCount: "Updated",
            });
            setTimeout(() => {
              setTextStatus({
                ...textStatus,
                articleCount: "",
              });
            }, 700);
          },
          onError: () => {
            setTextStatus({
              ...textStatus,
              articleCount: "Failed",
            });
          },
        }
      );
    }, 500);
  };

  // Functon for handling no title article text changes and setting the timeout for mutation , sort of debouncing
  const handleNoTitleArticleTextChanges = (e: any) => {
    setNoTitleArticleText(e.target.value);
    if (timeoutIdRef.current !== null) {
      clearTimeout(timeoutIdRef.current);
    }
    timeoutIdRef.current = setTimeout(() => {
      setTextStatus({
        ...textStatus,
        noTitleArticleText: "Updating...",
      });
      updateKBSettings.mutate(
        {
          integrationId: pageIntegrationId,
          themeId: themeData.themeId,
          noTitleArticleText: e.target.value,
        },
        {
          onSuccess: () => {
            setTextStatus({
              ...textStatus,
              noTitleArticleText: "Updated",
            });
            setTimeout(() => {
              setTextStatus({
                ...textStatus,
                noTitleArticleText: "",
              });
            }, 700);
          },
          onError: () => {
            setTextStatus({
              ...textStatus,
              noTitleArticleText: "Failed",
            });
          },
        }
      );
    }, 500);
  };

  // Font Options
  const fontOptions = [
    { value: "Roboto", label: "Roboto" },
    { value: "Source sans pro", label: "Source sans pro" },
    { value: "Poppins", label: "Poppins" },
    { value: "Pt sans", label: "Pt sans" },
    { value: "Pt serif", label: "Pt serif" },
    { value: "Lato", label: "Lato" },
  ];

  // State for selected option
  const [selectedOption, setSelectedOption] = useState(
    {
      value: themeData?.font || fontOptions[0].value,
      label: themeData?.font || fontOptions[0].label,
    } ?? fontOptions[0]
  );

  // Function for handling the change in font
  function handleChange(e: any) {
    setSelectedOption(e);
    if (themeData) {
      updateKBSettings.mutate({
        integrationId: pageIntegrationId,
        themeId: themeData.themeId,
        font: e.value,
      });
    }
  }

  // Query for getting all the languages
  const { data, isLoading } = useQuery({
    queryKey: ["languageOptions"],
    queryFn: getAllLanguages,
  });

  // State for selected language
  const [selectedLanguage, setSelectedLanguage] = useState({
    value: "English",
    label: "English",
  });

  // Language Options
  const languageOptions = data?.languageIds.map((item: any) => {
    return {
      value: data.languages[item].name,
      label: data.languages[item].name,
    };
  });

  // Function for handling the change in language
  function handleLanguageChange(e: any) {
    setSelectedLanguage(e);
    if (themeData) {
      updateKBSettings.mutate({
        integrationId: pageIntegrationId,
        themeId: themeData.themeId,
        language: e.value,
      });
    }
  }

  // Function for saving the changes
  const handelSaveChanges = () => {
    // if(headline === "" || subHeading === "" || placeholderText === ""){
    //   pushTheToast({
    //     position: "top-right",
    //     text : "Please Fill Wording Fields",
    //     type: "warning"
    //   })
    //   return;
    // }
    // setTextChanged(false);
    if (themeData) {
      updateKBSettings.mutate({
        integrationId: pageIntegrationId,
        themeId: themeData.themeId,
        heading: headline,
        subheading: subHeading,
        placeholder: placeholderText,
      });
    }
  };

  // State for reset button clicked
  const [resetButtonClicked, setResetButtonClicked] = useState(false);

  // Function for handling the reset default button
  const handleResetDefaultButton = () => {
    setResetButtonClicked(true);
    resetLanguageDefault.mutate({
      integrationId: pageIntegrationId,
      themeId: themeData.themeId,
    });
  };

  // Function for handling the reset default button
  const handleCustomTextDefaultReset = (
    type: "zeroArticle" | "oneArticle" | "articleCount" | "noTitleArticleText"
  ) => {
    // Using switch case for handling the type
    switch (type) {
      case "zeroArticle":
        // Setting the text status
        setTextStatus({
          ...textStatus,
          zeroArticle: "Resetting...",
        });
        // Calling the resetCustomiseTextDefault mutation
        return resetCustomiseTextDefault.mutate(
          {
            integrationId: pageIntegrationId,
            type: "zeroArticle",
          },
          {
            onSuccess: () => {
              setZeroArticle("");
              setTextStatus({
                ...textStatus,
                zeroArticle: "Reset",
              });
              // Setting the timeout for removing the text status
              setTimeout(() => {
                setTextStatus({
                  ...textStatus,
                  zeroArticle: "",
                });
              }, 700);
            },
            // Handling the error
            onError: () => {
              setTextStatus({
                ...textStatus,
                zeroArticle: "Failed",
              });
              setTimeout(() => {
                setTextStatus({
                  ...textStatus,
                  zeroArticle: "",
                });
              }, 700);
            },
          }
        );
      case "oneArticle":
        // Setting the text status
        setTextStatus({
          ...textStatus,
          oneArticle: "Resetting...",
        });
        // Calling the resetCustomiseTextDefault mutation
        return resetCustomiseTextDefault.mutate(
          {
            integrationId: pageIntegrationId,
            type: "oneArticle",
          },
          {
            onSuccess: () => {
              setOneArticle("");
              setTextStatus({
                ...textStatus,
                oneArticle: "Reset",
              });
              // Setting the timeout for removing the text status
              setTimeout(() => {
                setTextStatus({
                  ...textStatus,
                  oneArticle: "",
                });
              }, 700);
            },
            // Handling the error
            onError: () => {
              setTextStatus({
                ...textStatus,
                oneArticle: "Failed",
              });
              setTimeout(() => {
                setTextStatus({
                  ...textStatus,
                  oneArticle: "",
                });
              }, 700);
            },
          }
        );
      case "articleCount":
        // Setting the text status
        setTextStatus({
          ...textStatus,
          articleCount: "Resetting...",
        });
        return resetCustomiseTextDefault.mutate(
          {
            integrationId: pageIntegrationId,
            type: "articleCount",
          },
          {
            // Handling the success
            onSuccess: () => {
              setArticleCount("");
              setTextStatus({
                ...textStatus,
                articleCount: "Reset",
              });
              // Setting the timeout for removing the text status
              setTimeout(() => {
                setTextStatus({
                  ...textStatus,
                  articleCount: "",
                });
              }, 700);
            },
            // Handling the error
            onError: () => {
              setTextStatus({
                ...textStatus,
                articleCount: "Failed",
              });
              setTimeout(() => {
                setTextStatus({
                  ...textStatus,
                  articleCount: "",
                });
              }, 700);
            },
          }
        );
      case "noTitleArticleText":
        // Setting the text status
        setTextStatus({
          ...textStatus,
          noTitleArticleText: "Resetting...",
        });
        // Calling the resetCustomiseTextDefault mutation
        return resetCustomiseTextDefault.mutate(
          {
            integrationId: pageIntegrationId,
            type: "noTitleArticleText",
          },
          {
            // Handling the success
            onSuccess: () => {
              setNoTitleArticleText("");
              setTextStatus({
                ...textStatus,
                noTitleArticleText: "Reset",
              });
              // Setting the timeout for removing the text status
              setTimeout(() => {
                setTextStatus({
                  ...textStatus,
                  noTitleArticleText: "",
                });
              }, 700);
            },
            // Handling the error
            onError: () => {
              setTextStatus({
                ...textStatus,
                noTitleArticleText: "Failed",
              });
              setTimeout(() => {
                setTextStatus({
                  ...textStatus,
                  noTitleArticleText: "",
                });
              }, 700);
            },
          }
        );
    }
  };

  return {
    dropdown,
    setDropDown,
    styleTheme,
    setStyleTheme,
    font,
    language,
    setLanguage,
    customize,
    setCustomize,
    textChanged,
    handleHeadlineChanges,
    handleSubHeadlineChanges,
    handleSearchInputChanges,
    handleZeroArticleChanges,
    handleOneArticleChanges,
    handleArticleCountChanges,
    handleNoTitleArticleTextChanges,
    selectedOption,
    handleChange,
    isLoading,
    selectedLanguage,
    languageOptions,
    handleLanguageChange,
    handelSaveChanges,
    resetButtonClicked,
    handleResetDefaultButton,
    headline,
    subHeading,
    placeholderText,
    updateKBSettings,
    fontOptions,
    zeroArticle,
    oneArticle,
    articleCount,
    noTitleArticleText,
    resetLanguageDefault,
    textStatus,
    resetCustomiseTextDefault,
    handleCustomTextDefaultReset,
  };
}

export default useMessageSetting;
