/**
 * This file is the Delete View Service File.
 * It contains the service for deleting views from outer ticket view
 *
 * @author Ayush Gupta
 */

import { axiosJSON } from "src/globals/axiosEndPoints";

interface Payload {
  viewId: string;
}

/**
 * Service for deleting view using viewId
 */
const deleteViewService = async (payload: Payload) => {
  const { data: res } = await axiosJSON.post("/api/views/deleteView", payload);

  // If response has error throw the error with error message
  if (res.err || res.error) {
    if (res.status === "no_view_found") {
      return false;
    }

    throw res.msg ?? res.message ?? "Something went wrong!";
  }
  return true;
};

export default deleteViewService;
