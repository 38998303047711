import { axiosJSON } from "src/globals/axiosEndPoints";


export interface addArticleParams{
  articleName : string;
  categoryId : number|string;
  integrationId : number|string;
  articleStatus : string,
  attachmentBatchNumber? : string[] | null;
  articleDescription? :  string;
  searchKeyword? :  string;
}

export async function addArticle(params: addArticleParams) {
  const { data } = await axiosJSON.post(
    `/api/chatSetting/article/create`,
    params
  );
  if (data.error === true) {
    throw new Error(data.message as string);
  }
  return data.data;
}