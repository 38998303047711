import { axiosJSON } from "src/globals/axiosEndPoints";


export interface duplicateArticleParams{
  articleId : string|number;
  integrationId : number|string;
  articleName :string 
}

export async function duplicateArticle(params: duplicateArticleParams) {
  const { data } = await axiosJSON.post(
    `/api/chatSetting/article/duplicate`,
    params
  );
  if (data.error === true) {
    throw new Error(data.message as string);
  }
  return data.data;
}