import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import messenger from "src/assets/images/messenger.png";
import instagram from "src/assets/images/instagram.png";
import sms from "src/assets/images/smsIcon.png";
import whatsapp from "src/assets/images/whatsapp.png";
import emailIcon from "src/assets/images/envelop.png";
import styles from "./DropdownGroup.module.scss";
import whatsappImg from "src/assets/images/Whastapp.svg";
import { useEffect, useMemo, useState } from "react";
import { ReplyOption } from "src/routes/Ticket/children/MainChat/children/SendMessage/children/SendingAsV2";
import { EChannel } from "src/enums/EChannel";
import { useAppSelector } from "src/store/store";
interface Props {
  replyOption: ReplyOption;
  setReplyOption: (value: ReplyOption) => void;
  showReply: boolean;
  channel?: EChannel;
  disableChannel?: boolean;
  allowedChannels: Array<EChannel>;
  setChannel: (value: EChannel) => void;
}

interface ChannelOption {
  id: EChannel;
  label: string;
  icon: JSX.Element;
}

type ChannelOptions = {
  [key in EChannel]: ChannelOption;
};

const channelOptions: Partial<ChannelOptions> = {
  [EChannel.email]: {
    id: EChannel.email,
    label: "Email",
    icon: (
      <img
        src={emailIcon}
        alt="emailIcon"
        className={`${styles.email}`}
      />
    ),
  },
  [EChannel.whatsapp]: {
    id: EChannel.whatsapp,
    label: "Whatsapp",
    icon: (
      <img
        src={whatsappImg}
        alt="Whatsapp"
        className={`${styles.icons}`}
      />
    ),
  },
  [EChannel.facebookMessenger]: {
    id: EChannel.facebookMessenger,
    label: "Messenger",
    icon: (
      <img
        src={messenger}
        alt="Messenger"
        className={`${styles.icons}`}
      />
    ),
  },
  [EChannel.instagram]: {
    id: EChannel.instagram,
    label: "Instagram DM",
    icon: (
      <img
        src={instagram}
        alt="instagram"
        className={`${styles.icons}`}
      />
    ),
  },
  // [EChannel.sms]: {
  //   id: EChannel.sms,
  //   label: "SMS",
  //   icon: (
  //     <img
  //       src={sms}
  //       alt=""
  //       className={`${styles.icons}`}
  //     />
  //   ),
  // },
  // [EChannel.twitter]: {
  //   id: EChannel.twitter,
  //   label: "Twitter",
  //   icon: (
  //     <img
  //       src={sms}
  //       alt=""
  //       className={`${styles.icons}`}
  //     />
  //   ),
  // },
  // [EChannel.note]: {
  //   id: EChannel.note,
  //   label: "note",
  //   icon: (
  //     <img
  //       src={sms}
  //       alt=""
  //       className={`${styles.icons}`}
  //     />
  //   ),
  // },
};

function DropdownGroup({
  channel,
  allowedChannels,
  disableChannel,
  replyOption,
  setReplyOption,
  showReply = false,
  setChannel,
}: Props) {
  // Select the UI visibility settings for the current user from the global state
  const uiVisibility = useAppSelector(
    (state) => state.globals.currentUserData?.ui_visibility,
  );

  // Memoize the reply options based on the UI visibility settings
  const replyOptions = useMemo(() => {
    // If the user has access to the forward email feature, include "Forward" in the options
    if (uiVisibility?.includes("forward_email_feature")) {
      return ["Reply", "Forward"];
    }

    // Otherwise, only include "Reply"
    return ["Reply"];
  }, [uiVisibility]);

  // Memoize the available channels based on the UI visibility settings
  const channels = useMemo(() => {
    return Object.values(channelOptions).filter((option) => {
      // Include WhatsApp if the user has access to the WhatsApp integration
      if (option.id === EChannel.whatsapp) {
        return uiVisibility?.includes("integration_whatsapp_view");
      }
      // Include Facebook Messenger and Instagram if the user has access to the Facebook integration
      else if (
        option.id === EChannel.facebookMessenger ||
        option.id === EChannel.instagram
      ) {
        return uiVisibility?.includes("integration_facebook_view");
      }
      // Include all other channels by default
      return true;
    });
  }, [uiVisibility]);
  // Initialize state for channel options
  // const [channels, setChannels] = useState<Array<ChannelOption>>([]);

  const [showChannel, setShowChannel] = useState(false);

  // Handle reply option change
  const handleReplyChange = (option: any) => {
    setReplyOption(option);
  };

  // Initialize state for the currently selected channel, defaulting to the whatsapp channel
  const [selectedChannel, setSelectedChannel] = useState(
    channel && channelOptions[channel as keyof typeof channelOptions]
      ? channelOptions[channel as keyof typeof channelOptions]
      : channelOptions.email,
  );

  // Handle channel change
  const handleChannelChange = (channel: ChannelOption | undefined) => {
    if (channel) {
      setChannel(channel.id);
    }
  };

  useEffect(() => {
    if (channelOptions[channel as keyof typeof channelOptions]) {
      setSelectedChannel(
        channelOptions[channel as keyof typeof channelOptions],
      );
    }
  }, [channel, allowedChannels]);
  return (
    <>
      <div
        className={`my-auto me-2 d-flex align-items-center px-1 ${styles.group}`}
      >
        {/* Channels */}
        {/* <span className={`${styles.channelText}`}>Channel:</span> */}
        <Dropdown
          show={showChannel}
          onSelect={(eventKey) =>
            handleChannelChange(
              channelOptions[eventKey as EChannel],
              // channelOptions.find((channel) => channel.label === eventKey),
            )
          }
          onToggle={() => !disableChannel && setShowChannel(!showChannel)}
        >
          <Dropdown.Toggle
            id="channel-dropdown"
            as="div"
            bsPrefix={`dropdown-toggle px-3 ${
              disableChannel ? "" : styles.cursorPointer
            } ${styles.dropBtn} ${styles.channelBtn}`}
            disabled={disableChannel} // Add this line to disable the dropdown
          >
            <div className="d-flex align-items-center">
              <div className={`me-1 ${styles.selectedChannel}`}>
                <span className="d-block">{selectedChannel?.icon ?? ""}</span>
              </div>
              <span className={`${styles.optionText}`}>
                {selectedChannel?.label ?? ""}
              </span>
            </div>

            <span className="ms-1">
              <i className="fa-solid fa-angle-down"></i>
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu className={`dropdown-menu ${styles.dropMenuMedia}`}>
            <p className={`mb-2 ${styles.channelHeading}`}>Select Channels:</p>
            {channels.map((channel) => (
              // <Dropdown.Item
              //   key={channel.id}
              //   eventKey={channel.id}
              //   disabled={!allowedChannels.includes(channel.id)}
              //   bsPrefix={`dropdown-item d-flex align-items-center px-2 ${
              //     styles.dropMediaItem
              //   } ${selectedChannel?.id === channel.id ? styles.active : ""}`}
              // >
              //   <span className={`${styles.bar}`}></span>
              //   <span className={`${styles.channelImg}`}>{channel.icon}</span>

              //   <span className={`ps-2`}>{channel.label}</span>
              // </Dropdown.Item>

              <Dropdown.Item
                key={channel.id}
                eventKey={channel.id}
                disabled={!allowedChannels.includes(channel.id)}
                bsPrefix={`dropdown-item d-flex align-items-center px-2 w-100 ${
                  styles.dropMediaItem
                } ${selectedChannel?.id === channel.id ? styles.active : ""} ${
                  !allowedChannels.includes(channel.id) && styles.disabledItem
                }`}
                style={{
                  pointerEvents: !allowedChannels.includes(channel.id)
                    ? "auto"
                    : "auto",
                }}
              >
                {/* Overlay for Disabled Items */}
                {!allowedChannels.includes(channel.id) ? (
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id={`tooltip-disabled-${channel.id}`}>
                        {/* tooltip when channel is disabled for customer */}
                        <span className={`${styles.toopTipDetails}`}>
                          {" "}
                          {`${channel.label} is not available for this customer`}
                        </span>
                      </Tooltip>
                    }
                  >
                    <div className="d-flex align-items-center">
                      <span className={`${styles.bar}d-none`}></span>
                      <span className={`${styles.channelImg}`}>
                        {channel.icon}
                      </span>
                      <span className={`ps-2`}>{channel.label}</span>
                    </div>
                  </OverlayTrigger>
                ) : (
                  <div className="d-flex align-items-center">
                    <span className={`${styles.bar}`}></span>
                    <span className={`${styles.channelImg}`}>
                      {channel.icon}
                    </span>
                    <span className={`ps-2`}>{channel.label}</span>
                  </div>
                )}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>

        {/* Reply options */}
        {showReply && (
          <Dropdown
            onSelect={(eventKey) =>
              handleReplyChange(
                replyOptions.find((option) => option === eventKey),
              )
            }
          >
            <Dropdown.Toggle
              id="reply-dropdown"
              as="div"
              bsPrefix={`dropdown-toggle px-3 ms-1 ${styles.dropBtn} ${styles.cursorPointer}`}
            >
              <span className={`${styles.optionText}`}>{replyOption}</span>
              <span className="ms-1">
                <i className="fa-solid fa-angle-down"></i>
              </span>
            </Dropdown.Toggle>
            {/* <Dropdown.Menu className={`dropdown-menu ${styles.dropMenu}`}>
              {replyOptions.map((option) => (
                <Dropdown.Item
                  eventKey={option}
                  bsPrefix={`dropdown-item ${styles.dropItem} ${
                    option === replyOption ? styles.active : ""
                  } ${
                    selectedChannel?.label !== "Email" &&
                    option !== "Reply" &&
                    styles.disabledItem
                  }`}
                  style={{
                    pointerEvents:
                      selectedChannel?.label !== "Email" && option !== "Reply"
                        ? "none"
                        : "auto",
                  }}
                >
                  <OverlayTrigger
                    key={option}
                    placement="bottom-start"
                    overlay={
                      selectedChannel?.label !== "Email" &&
                      option !== "Reply" ? (
                        <Tooltip id={`tooltip-${option}`}>
                          <span className={`${styles.toopTipDetails}`}>
                            Conversations can only be forwarded via Email.
                            Please choose Email as the channel.
                          </span>
                        </Tooltip>
                      ) : (
                        <></>
                      )
                    }
                  >
                    <div style={{ width: "maxContent" }}>
                      <span
                        className={`${
                          selectedChannel?.label !== "Email" &&
                          option !== "Reply"
                            ? ""
                            : styles.bar
                        }`}
                      ></span>
                      <span>{option}</span>
                    </div>
                  </OverlayTrigger>
                </Dropdown.Item>
              ))}
            </Dropdown.Menu> */}
            <Dropdown.Menu className={`dropdown-menu ${styles.dropMenu}`}>
              {replyOptions.map((option) => (
                <OverlayTrigger
                  key={option}
                  placement="bottom-start"
                  overlay={
                    selectedChannel?.label !== "Email" && option !== "Reply" ? (
                      <Tooltip id={`tooltip-${option}`}>
                        {/* tooltip when selected channel is not email  */}
                        <span className={`${styles.toopTipDetails}`}>
                          Conversations can only be forwarded via Email. Please
                          choose Email as the channel.
                        </span>
                      </Tooltip>
                    ) : (
                      <></>
                    )
                  }
                >
                  <div style={{ pointerEvents: "auto" }}>
                    <Dropdown.Item
                      eventKey={option}
                      bsPrefix={`dropdown-item d-flex align-items-center mb-1 ${
                        styles.dropItem
                      } ${option === replyOption ? styles.active : ""} ${
                        selectedChannel?.label !== "Email" && option !== "Reply"
                          ? styles.disabledItem
                          : ""
                      }`}
                      style={{
                        pointerEvents:
                          selectedChannel?.label !== "Email" &&
                          option !== "Reply"
                            ? "none"
                            : "auto",
                      }}  
                    >
                      <div style={{ width: "max-content" }}>
                        <span
                          className={`${
                            selectedChannel?.label !== "Email" &&
                            option !== "Reply"
                              ? ""
                              : styles.bar
                          }`}
                        ></span>
                        <span>{option}</span>
                      </div>
                    </Dropdown.Item>
                  </div>
                </OverlayTrigger>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>
    </>
  );
}

export default DropdownGroup;
