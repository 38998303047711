import { axiosJSON } from "src/globals/axiosEndPoints";
export interface IntegrateWhatsappParam {
  integration_id?: string | number;
}
export async function integrateWhatsapp(params?: IntegrateWhatsappParam) {
  const { data: res } = await axiosJSON.get(
    "/api/setting/integration/whatsapp/start",
    { params },
  );
  if (res.err || res.error) {
    throw res.msg ?? res.message ?? "Something went wrong!";
  }
  return res.url;
}

// -----------------------------------------------------------------------------------------------------------

export interface WhatsappIntegrationData {
  name: string;
}

// Check if Whatsapp is integrated
export async function isWhatsappIntegrated() {
  const { data: res } = await axiosJSON.get(
    "/api/setting/integration/whatsapp/isIntegrated",
  );
  if (res.err || res.error) {
    throw res.msg ?? res.message ?? "Something went wrong!";
  }
  return res.data as WhatsappIntegrationData;
}

// -----------------------------------------------------------------------------------------------------------

interface PhoneNumbers {
  number: string;
  name: string;
  id: string | number;
}

export interface WhatsappAccount {
  id: string | number;
  integrated: boolean;
  imageUrl: string; // default theme will be whatsapp logo image
  phoneNumbers: Array<PhoneNumbers>;
  registered?: boolean;
}

// Get Whatsapp Accounts
export async function getWhatsappAccounts() {
  const { data: res } = await axiosJSON.get(
    "/api/setting/integration/whatsapp/getAccounts",
  );
  if (res.err || res.error) {
    if (res.statusCode === "not_integrated") {
      return {
        accounts: [] as Array<WhatsappAccount>,
      };
    }
    throw res.msg ?? res.message ?? "Something went wrong!";
  }

  return {
    accounts: res.data as Array<WhatsappAccount>,
    integrationId: res.data.integrationId,
  };
}

// -----------------------------------------------------------------------------------------------------------

export interface SubscribeWhatsappAccountParams {
  integration_id: string | number;
  subscribe: boolean;
  pin?: string | number;
}

// Subscribe Whatsapp Account
export async function subscribeWhatsappAccount(
  params: SubscribeWhatsappAccountParams,
) {
  const { data: res } = await axiosJSON.post(
    "/api/setting/integration/whatsapp/subscribeAccount",
    params,
  );
  if (res.err) {
    throw res.msg;
  }
  return res.msg;
}

// -----------------------------------------------------------------------------------------------------------

export interface GetLinkableAccountsResponse {
  id: string | number;
  phone_numbers: Array<string>;
}

export interface getLinkableAccountsData {
  accounts: { [account: string | number]: GetLinkableAccountsResponse };
  accountIds: Array<string | number>;
}

export async function getLinkableAccounts() {
  const { data: res } = await axiosJSON.get(
    "/api/setting/integration/whatsapp/getLinkableAccounts",
  );
  if (res.err || res.error) {
    throw res.msg ?? res.message ?? "Something went wrong!";
  }

  const ret: getLinkableAccountsData = {
    accounts: {},
    accountIds: [],
  };

  res.data.forEach((account: GetLinkableAccountsResponse) => {
    ret.accounts[account.id] = account;
    ret.accountIds.push(account.id);
  });

  return ret;
}

// ----------------------------------------------------------------------------------------------

export interface LinkWhatsappAccountParams {
  liveChatIntegrationId: string | number;
  whatsappUserId: string | number;
}

export async function linkWhatsappAccount(params: LinkWhatsappAccountParams) {
  const { data: res } = await axiosJSON.post(
    "/api/setting/integration/whatsapp/linkAccount",
    params,
  );
  if (res.err || res.error) {
    throw res.msg ?? res.message ?? "Something went wrong!";
  }
  return res.msg;
}
interface DeleteWhatsappIntegrationParam {
  integrationId: number;
}
// Delete WhatsApp Account Service
export const deleteWhatsAppAccountService = async (
  params: DeleteWhatsappIntegrationParam,
) => {
  // Calling delete API.
  const { data: res } = await axiosJSON.delete(
    "/api/setting/integration/whatsapp/deleteAccount",
    { params },
  );

  // Checking if any error.
  if (res.err || res.error) {
    throw new Error(res.msg ?? res.message ?? "Something went wrong!");
  }

  // Return message.
  return res.msg;
};
