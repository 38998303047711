import { useEffect, useRef, useState } from "react";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import ImgUpload from "./children/ImgUpload/ImgUpload";
import styles from "./HelpCenterSetting.module.scss";
import {v4 as uuidV4 } from "uuid";
import { AttachmentData } from "../../HelpCenterStyle";
import CustomColorPicker from "./children/ColorPicker/CustomColorPicker";
import visibleEyeImg from "src/assets/images/visibleEye.png";
import inVisibleEyeImg from "src/assets/images/invisibleEye.png";
interface Props{
  helpCenterData:{
    helpdeskUrl: any;
    companyName: string;
    headlineText: string;
    themeColor: string;
    googleAnalyticsTrackingId: string;
    searchEngineIndexing: boolean;
    isPublished: boolean;
    logoAttachmentData?: AttachmentData;
    backgroundAttachmentData?: AttachmentData;
    faviconAttachmentData?: AttachmentData;
  },
  setHelpCenterData:any
  integrationId: any;
}

const HelpCenterSetting = ({helpCenterData, setHelpCenterData,integrationId}:Props) => {

  const [dragClass, setDragClass] = useState("");
  const [searchEngineEnabled, setSearchEngineEnabled] = useState();

  return (
    <div className={` overflow-auto ${styles.helpCenterSettings}`}>
      <h2 className={`${styles.heading}`}>Helpcenter settings</h2>
      <div className="mb-4">
        <span className={`${styles.urlHead}`}>Helpdesk URL</span>
        <div className={`d-flex w-100`}>
          <input type={`text`} placeholder={`Getting started`} className={`${styles.inputUrl}`} value={helpCenterData.helpdeskUrl.domainName} onChange={(e)=>{setHelpCenterData.setHelpdeskUrl({domainName:e.target.value, extension:helpCenterData.helpdeskUrl.extension })}} />
          <input type={`text`} readOnly={true}  className={`${styles.inputDomain}`} value={helpCenterData.helpdeskUrl.extension} onChange={(e)=>{setHelpCenterData.setHelpdeskUrl({domainName:helpCenterData.helpdeskUrl.domainName, extension:e.target.value })}}/>
        </div>
      </div>
      <div>
        <p className={`${styles.apperance}`}>Apperance </p>
        <div className="mb-2">
          <span className={`${styles.urlHead}`}>Company Name</span>
          <input type={"text"} className={`${styles.inputBox}`} value={helpCenterData.companyName} onChange={(e)=>{setHelpCenterData.setCompanyName(e.target.value)}}/>
        </div>
        <div  className="mb-2">
          <span className={`${styles.urlHead}`}>Headline text</span>
          <input type={"text"} className={`${styles.inputBox}`} value={helpCenterData.headlineText} onChange={(e)=>{setHelpCenterData.setHeadlineText(e.target.value)}}/>
        </div>
        <div  className="mb-2">
          <span className={`${styles.urlHead}`}>Logo</span>
           {/* <span className={`${styles.dndSpan}`}>Drag and drop the image or click to upload</span> */}
           <ImgUpload key={uuidV4()} attachmentData={helpCenterData.logoAttachmentData} setAttachmentData={setHelpCenterData.setLogoAttachmentData} stateType='logo'/>
        </div>
        <div className="mb-2">
          <span className={`${styles.urlHead}`}>Background Image</span>
          <ImgUpload key={uuidV4()} attachmentData={helpCenterData.backgroundAttachmentData} setAttachmentData={setHelpCenterData.setBackgroundAttachmentData} stateType='background'/>
          
        </div>
        <div  className="mb-2">
          <span className={`${styles.urlHead}`}>Favicon</span>
            <ImgUpload key={uuidV4()} attachmentData={helpCenterData.faviconAttachmentData} setAttachmentData={setHelpCenterData.setFaviconAttachmentData} stateType='favicon'/>
           {/* <span className={`${styles.dndSpan}`}>Drag and drop the image or click to upload</span> */}
        </div>
        <div  className="mb-2">
          <span className={`${styles.urlHead}`}>Theme Colour</span>
          <CustomColorPicker color={helpCenterData.themeColor} onChange={(e:any)=>setHelpCenterData.setThemeColor(e.hex)}  />
        </div>
      </div>
      <div>
        <p className={`${styles.apperance} mt-3 mb-2`}>Google Analytics </p>
        <div className="mb-2">
          <span className={`${styles.urlHead}`}>Tracking Id</span>
          <input type={"text"} className={`${styles.inputBox}`} value={helpCenterData.googleAnalyticsTrackingId} onChange={(e)=>{setHelpCenterData.setGoogleAnalyticsTrackingId(e.target.value)}}/>
        </div>
      </div>
      <div>
        <p className={`${styles.apperance} mt-3 mb-2`}>Search Engine Indexing </p>
        <div className="mb-2">
          <span className={`${styles.dndSpan}`}>Show Helpcenter in search result</span>
          <div className=" form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id="mySwitch"
              name="darkmode"
              checked={searchEngineEnabled}
              onClick={(e)=>setSearchEngineEnabled}
            />
          </div>
        </div>
      </div>
      <div>
        <div className="mt-2 d-flex align-items-center">
          { helpCenterData.isPublished === true ? <>
            <img src={inVisibleEyeImg} className={`${styles.visibilityImg} me-2`}/> 
             <span className={`${styles.publishSpan}`} role={"button"} onClick={(e)=>setHelpCenterData.setIsPublished(false)}>Unpublish Helpcenter</span>
            </>
            :
            <>
            <img src={visibleEyeImg} className={`${styles.visibilityImg} me-2`} /> 
            <span className={`${styles.publishSpan} ${styles.publishSpanGreen}`} role={"button"} onClick={(e)=>setHelpCenterData.setIsPublished(true)}>Publish Helpcenter</span>
            </>
          }
        </div>
      </div>
    </div>
  );
};
export default HelpCenterSetting;
