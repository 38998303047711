import React, { useState } from "react";
import TinyMCEContainer from "src/components/TinyMCEContainer";
import styles from "./AddArticle.module.scss";
import { v4 as uuid } from "uuid";
import { isHTMLEmpty, trimPDIVBR } from "src/utils/utils";

interface Props {
  headingText: string;
  setHeadingText: any;
  message: string;
  setMessage: any;
  currentAttachmentBatchNumber: any;
  setCurrentAttachmentBatchNumber: any;
  TotalTextLimit: number;
  setSelectedFiles: (values: any) => void;
  selectedFiles: any;
  showError: boolean;
  setShowError: any;
}

const AddArticle = ({
  headingText,
  setHeadingText,
  message,
  setMessage,
  currentAttachmentBatchNumber,
  setCurrentAttachmentBatchNumber,
  TotalTextLimit,
  selectedFiles,
  setSelectedFiles,
  showError,
  setShowError,
}: Props) => {
  // const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);

  return (
    <div>
      <div className={`pe-5 ${styles.leftContent} ${styles.fitBox}`}>
        <div className={`d-flex justify-content-between flex-wrap`}>
          <input
            type={"text"}
            className={`mb-1 mb-md-3 ps-0 ps-lg-2 ${styles.createHead} ${
              showError && headingText.length === 0 ? styles.errorInput : ""
            }`}
            placeholder={`Give title to article`}
            value={headingText}
            onChange={(e) => {
              if (e.target.value.length <= TotalTextLimit) {
                setHeadingText(e.target.value);
              }
              setShowError(false);
            }}
          />
          {/* added trim to remove extra spaces and check if headingText is empty ,if yes ,will show message*/}
          {showError && headingText.trim().length === 0 && (
            <span className={`${styles.errorSpan}`}>
              *Please enter an article name!
            </span>
          )}
          <span className={`${styles.lines}`}>
            {headingText.length}/{TotalTextLimit}
          </span>
        </div>
        <div>
          <TinyMCEContainer
            name="message"
            className={`${styles.message} ${
              showError &&
              isHTMLEmpty(trimPDIVBR(message.trim())) &&
              "border border-danger"
            } mt-2`} //Added validation to show red border when the messge is empty
            // editorClassName={`${styles.editor}`}
            value={message}
            onChange={(value) => {
              setMessage(value);
              setShowError(false);
            }}
            options={{
              placeholder: "Enter your message here ...",
              max_height: "100%" as any,
            }}
            // enable the attachment picker
            disableAttachment={false}
            // disableFormatingsAndEmbedding={false}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            isFileUploading={isFileUploading}
            setIsFileUploading={setIsFileUploading}
            currentAttachmentBatchNumber={currentAttachmentBatchNumber}
            setCurrentAttachmentBatchNumber={setCurrentAttachmentBatchNumber}
            uniqueID={uuid()}
            showCustomFilePicker={true}
            inlineAttachmentOnly={true}
            allowedEmbbedTypes={["video", "image", "gif"]}
            showAttachmentOverlay={false}
            areAttachmentsPublic={true}
          />
          {/* Nov 30 2023 Adding validation for the message */}
          {showError && isHTMLEmpty(trimPDIVBR(message.trim())) && (
            <span className={`${styles.errorSpan}`}>
              *Please enter a message!
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddArticle;
