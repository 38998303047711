import { useEffect, useRef, useState } from "react";
import { ActionMeta, GroupBase } from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import { customStyles } from "src/routes/TicketList/children/FilterSection/children/Filter/children/FilterColDropdown/FilterColDropdown";
import { highlightSearchedText } from "src/routes/TicketList/children/OuterTickets/Chidren/SearchedTickets/SearchedTickets";
import { useAppSelector } from "src/store/store";
import { combineArrayOfObjectsBasedOnKey } from "src/utils/utils";

interface IReactSelectAsync {
  fetchFunction: (
    searrchTerm: string,
    loadOptions: any,
    additional: any
  ) => Promise<{
    options: Array<object>;
    hasMore: boolean;
    additional?: any;
  }>;
  onChange: (newValue: any) => void;
  className: string;
  placeholder: string;
  isMulti?: boolean;
  currentValue?: string;
}

function SecectAsyncData({
  fetchFunction,
  onChange,
  className,
  enableCache = true,
  currentValue = "",
  isMulti = false,
  textToHighlight,
  ...props
}: any) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [value, setValue]: any = useState(
    currentValue ? currentValue.split(",") : []
  );
  const currentOptions: any = useRef([]);
  const [currentValueData, setCurrentValueData]: any = useState([]);
  const currentValueDataRef: any = useRef(currentValueData);
  useEffect(() => {
    const currentEmailMap: any = {};
    currentOptions.current.forEach((data: any) => {
      currentEmailMap[data.value.email] = data;
    });
    const valueObj = value.map((email: string) => {
      if (currentEmailMap[email]) {
        return currentEmailMap[email];
      } else {
        return {
          label: email,
          value: { email },
        };
      }
    });
    const selected = currentValueDataRef.current.filter((data: any) =>
      value.includes(data.value.email)
    );
    const selectedOptions = currentOptions.current.filter(
      (data: any) => value.includes(data.value.email) && !data.component
    );
    setCurrentValueData(
      combineArrayOfObjectsBasedOnKey(
        [selectedOptions, selected, valueObj],
        "value",
        "email"
      )
    );
  }, [value.join(",")]);
  useEffect(() => {
    currentValueDataRef.current = currentValueData;
    setValue(currentValueData.map((data: any) => data.value.email));
  }, [currentValueData]);
  useEffect(() => {
    setValue(currentValue ? currentValue.split(",") : []);
  }, [currentValue]);

  const formatOptionLabel = ({ label }: { label: string }) => {
    if (typeof label === "string") {
      const highlightedLabel = highlightSearchedText(label, textToHighlight);
      return highlightedLabel;
    } else {
      return label;
    }
  };


  return (
    <div className={`${className}`}>
      <AsyncPaginate
        openMenuOnClick
        menuPortalTarget={document.body}
        onMenuOpen={() => {
          setMenuOpen(true);
        }}
        onMenuClose={() => {
          setMenuOpen(false);
        }}
        value={currentValueData}
        loadOptions={async (searchTerm, loadOptions, additional) => {
          const data = await fetchFunction(searchTerm, loadOptions, additional);
          currentOptions.current = data.options;
          return data;
        }}
        isOptionDisabled={(option: any) => {
          if (option.disabled) {
            return true;
          } else {
            let d = false;
            currentValueDataRef.current.forEach((item: any) => {
              if (item.value.email === option.value.email) {
                d = true;
                return true;
              }
            });
            return d;
          }
        }}
        onChange={(newValueArr: any) => {
          if (newValueArr.length) {
            // console.log(newValueArr);
            const newValue = newValueArr[newValueArr.length - 1];
            // console.log(newValue);

            if (newValue.component === true) {
              onChange([
                {
                  addUser: newValue.value.email,
                  addUserWithName: newValue.value.name,
                  callback: (valueData: any) => {
                    if (isMulti) {
                      // console.log(
                      //   JSON.parse(JSON.stringify(currentValueDataRef.current)),
                      //   valueData
                      // );
                      setCurrentValueData([
                        ...currentValueDataRef.current,
                        valueData,
                      ]);
                      onChange([...currentValueDataRef.current, valueData]);
                    } else {
                      setCurrentValueData([valueData]);
                      onChange([valueData]);
                    }
                  },
                },
              ]);
            } else {
              if (isMulti) {
                setCurrentValueData(newValueArr);
                onChange(newValueArr);
              } else {
                setCurrentValueData([newValue]);
                onChange([newValue]);
              }
            }
          } else {
            setCurrentValueData([]);
            onChange([]);
          }
        }}
        isMulti={true}
        menuIsOpen={menuOpen}
        isClearable={
          props.isClearable === undefined ? false : props.isClearable
        }
        cacheUniqs={enableCache ? [menuOpen] : []}
        {...props}
        styles={{
          ...(props.styles ? props.styles : {}),
          menuPortal: (provided: any) => ({ ...provided, zIndex: 9999999 }),
          menu: (provided: any) => ({ ...provided, zIndex: 9999999 }),
        }}
        formatOptionLabel={formatOptionLabel}
      />
    </div>
  );
}

export default SecectAsyncData;
