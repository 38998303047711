import { useCallback, useMemo, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  fetchGetEmailThunk,
  fetchUpdateEmailIntegration,
} from "src/store/slices/emailIntegration/emailIntegration.thunks";
import { useAppDispatch, useAppSelector } from "src/store/store";
import BrandsDropdown from "./BrandsDropdown/BrandsDropdown";
import styles from "./ConnectAccount.module.scss";

interface Props {
  defaultEmail?: string;
  defaultName?: string;
  isIntegrated: boolean;
  navigateCallback?: (id?: string) => void;
  isEmailTodo?: boolean;
}
const ConnectAccount = ({
  defaultEmail,
  defaultName,
  isIntegrated,
  navigateCallback,
  isEmailTodo = false,
}: Props) => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const [name, setName] = useState(defaultName ?? "");

  const { selectedEmailType, selectedUser } = useAppSelector(
    (state) => state.emailIntegration,
  );

  const [selectedBrand, setselectedBrand] = useState(defaultEmail ?? "");

  const [savingChanges, setSavingChanges] = useState(false);

  const [loading, setLoading] = useState(false);

  /**
   * Check for disable connect button or not
   */
  const disableConnectBtn = useMemo(() => {
    if (selectedBrand.length > 0 && name.length > 0) {
      return false;
    }
    return true;
  }, [name.length, selectedBrand.length]);

  /**
   * Submit Form Handler
   */
  const submitEmailForm = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      // Check if Button is disabled or not
      if (!disableConnectBtn) {
        // Check if email is integrated
        if (isIntegrated) {
          // Check if we have same name
          if (name === defaultName) {
            if (navigateCallback) {
              navigateCallback();
            } else {
              // Go to next step
              navigate(
                `/settings/integrations/email/${selectedUser.emailIntegrationId}/verifyEmail`,
              );
            }
          } else {
            setSavingChanges(true);
            // Update name if not same
            dispatch(
              fetchUpdateEmailIntegration({
                emailIntegrationId: selectedUser.emailIntegrationId,
                addressName: name,
              }),
            ).finally(() => setSavingChanges(false));
          }
        } else {
          // If email not integrated add new integration
          setLoading(true);
          dispatch(
            fetchGetEmailThunk({
              addressName: name,
              emailAddress: selectedBrand,
              emailType: selectedEmailType,
              callback: (id) => {
                setLoading(false);
                if (navigateCallback) {
                  navigateCallback(id + "");
                } else {
                  navigate(`/settings/integrations/email/${id}/verifyEmail`);
                }
              },
            }),
          );
        }
      }
    },
    [
      defaultName,
      disableConnectBtn,
      dispatch,
      isIntegrated,
      name,
      navigate,
      navigateCallback,
      selectedBrand,
      selectedEmailType,
      selectedUser.emailIntegrationId,
    ],
  );

  const submitButtonText = useMemo(() => {
    if (isIntegrated) {
      if (name === defaultName) {
        return "Next";
      } else {
        return "Save Changes";
      }
    }
    return "Connect this email account";
  }, [defaultName, isIntegrated, name]);

  return (
    <>
      <div
        className={`pt-4 mt-2 ms-lg-2 me-0 me-lg-4 `}
        style={{ width: isEmailTodo ? "98.5%" : "90%" }}
      >
        <h3 className={`mb-0 ${isEmailTodo ? styles.emailHeading: styles.heading}`}>
          Add an email address
        </h3>
        <span className={`${styles.subHeading}`}>
          You can connect your email to Helplama by setting up email forwarding.
          Follow the steps below to get started.
        </span>
        <div>
          <form
            className={`mt-3 ${styles.formBox}`}
            onSubmit={submitEmailForm}
          >
            <BrandsDropdown
              isDisabled={isIntegrated}
              onSelectBrand={(email, name) => {
                setselectedBrand(email);
                if (name.length > 0) {
                  setName(`${name} support`);
                }
              }}
              defaultEmail={defaultEmail}
            />
            {selectedBrand && (
              <div className="mb-3">
                <label
                  htmlFor="fName"
                  className={`form-label ${styles.labelName}`}
                >
                  Address name
                </label>
                <input
                  type="text"
                  className={`form-control ${styles.formInput}`}
                  id="fName"
                  aria-describedby="fname"
                  placeholder="Enter Name"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  value={name}
                />
                <div
                  id="fName"
                  className={`form-text ${styles.formText}`}
                >
                  {` The name that customers will see when they receive emails from you. Cannot contain these characters: @,;<>[] `}
                </div>
              </div>
            )}
            <div
              className={`d-flex jusify-content-center justify-content-md-end`}
            >
              <button
                type="submit"
                className={`${styles.connectBtn}`}
                disabled={savingChanges || disableConnectBtn}
              >
                {submitButtonText}
                {loading && (
                  <Spinner
                    className="my-auto mx-2"
                    animation="border"
                    size="sm"
                  />
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ConnectAccount;
