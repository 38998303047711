import styles from "./JustShowString.module.scss";
import { selectTicketCellData } from "src/store/slices/tickets/tickets.slice";
import { TableCellProps } from "..";
import { useAppSelector } from "src/store/store";

const JustShowString = ({ colKeys, ticketId }: TableCellProps) => {
  const ticketCellData: any = useAppSelector((state) =>
    selectTicketCellData(state, ticketId, colKeys)
  );

  return (
    <span className={`${styles.justString}`}>
      <div className={`${styles["subjectJustShowString"]} text-center`}>
        <span className={`${styles["tbodyJustShowString"]}`}>
          {ticketCellData[0] !== "" && ticketCellData[0] !== null ? ticketCellData[0] : "NA"}
        </span>
      </div>
    </span>
  );
};

export default JustShowString;
