import { axiosJSON } from "src/globals/axiosEndPoints";
import { CustomerDetails } from "./getBugByCategory.service";

export interface CustomerInfo {
  bugId: number;
  customerDetails: CustomerDetails;
}

interface ApiResponse {
  err: boolean;
  data: CustomerInfo[];
  metaData: {
    totalCount: number;
  };
  msg: string;
}

const getLatestReportedBugs = async () => {
  const { data: res } = await axiosJSON.get(
    `/api/selfService/bugManagement/getLatestBugsReportedCustomer`,
  );

  if (res.err || res.error) {
    throw res.msg ?? res.message ?? "Something went wrong!";
  }

  return res as ApiResponse;
};

export default getLatestReportedBugs;
