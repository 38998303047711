import { useCallback, useMemo, useRef, useState } from "react";
import styles from "./ChatStatusFilter.module.scss";
import { Dropdown } from "react-bootstrap";
import {
  useReportFilters,
  reportCurrentStatusActions,
} from "src/routes/Report/hooks/reportFilters/useReportFilters";
import { v4 as uuid } from "uuid";

const useChatStatusFilter = () => {
  // Get the filters dispatch and state
  const { currentStatus, dispatch } = useReportFilters();

  // All chat status types
  const chatStatusTypes = useMemo(() => {
    return [
      { id: "resolved", name: "Resolved" },
      { id: "unresolved", name: "Unresolved" },
    ];
  }, []);

  // Changes the selected chat status on state change
  const selectedChatStatus = useMemo(() => {
    return currentStatus.selectedChatStatus;
  }, [currentStatus]);

  // Clears all chat status filters applied
  const clearAllChatStatus = useCallback(() => {
    dispatch([
      reportCurrentStatusActions.setFilters,
      { selectedChatStatus: [] },
    ]);
  }, []);

  // Check or uncheck the filter and fire a dispatch action to update state.
  const checkUncheckChatStatus = useCallback(
    (e: any) => {
      if (e.target.checked) {
        dispatch([
          reportCurrentStatusActions.setFilters,
          { selectedChatStatus: [...selectedChatStatus, e.target.value] },
        ]);
      } else {
        dispatch([
          reportCurrentStatusActions.setFilters,
          {
            selectedChatStatus: selectedChatStatus.filter(
              (id) => id !== e.target.value
            ),
          },
        ]);
      }
    },
    [selectedChatStatus]
  );

  return {
    selectedChatStatus,
    chatStatusTypes,
    clearAllChatStatus,
    checkUncheckChatStatus,
  };
};

interface Props {
  isSegment?: boolean;
  disabled?: boolean;
}
const ChatStatusFilter = ({ isSegment, disabled }: Props) => {
  const {
    chatStatusTypes,
    selectedChatStatus,
    checkUncheckChatStatus,
    clearAllChatStatus,
  } = useChatStatusFilter();
  // State for dropdown open status
  const [ddOpen, setDdOpen] = useState(false);
  // Used to manage the state of arrow as dropdown opens
  const dropdownArrowSpanRef = useRef<any>();
  // Unique id for filter
  const uuidRef = useRef(uuid());

  return (
    <div className="me-2 mb-2 mb-lg-0">
      <Dropdown onToggle={setDdOpen}>
        <Dropdown.Toggle
          as="div"
          id="dropdown-chat-status-filter"
          bsPrefix={`dropdown-toggle cursor-pointer border-0 px-2 py-1 ${
            isSegment === true ? styles.segementBtn : styles.filterButton
          } ${disabled && styles.disable} `}
        >
          Chat status
          {selectedChatStatus.length ? (
            <span className={`mx-1 ${styles.filterCount}`}>
              {/* added condition to show 0 before count if count is less than 10 */}
              {selectedChatStatus.length < 10
                ? `0${selectedChatStatus.length}`
                : selectedChatStatus.length}
            </span>
          ) : (
            ""
          )}
          <span className="ps-1" ref={dropdownArrowSpanRef}>
            {ddOpen ? (
              <i className={`fa-solid fa-caret-up`}></i>
            ) : (
              <i className={`fa-solid fa-caret-down`}></i>
            )}
          </span>
        </Dropdown.Toggle>

        <Dropdown.Menu bsPrefix={`dropdown-menu border-0 ${styles.filterMenu}`}>
          {chatStatusTypes.map((data) => {
            return (
              <span key={data.id} className={`${styles.dropItem}`}>
                <div
                  className={`${styles.itemWrapper} ${
                    selectedChatStatus.includes(data.id as any) &&
                    styles.selectedOne
                  }`}
                >
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={data.id}
                      id={
                        uuidRef.current +
                        "reports-filter-chat-status-type" +
                        data.id
                      }
                      checked={selectedChatStatus.includes(data.id as any)}
                      onChange={checkUncheckChatStatus}
                    />
                    <label
                      className={`form-check-label ${styles.checktext}`}
                      htmlFor={
                        uuidRef.current +
                        "reports-filter-chat-status-type" +
                        data.id
                      }
                    >
                      {data.name}
                    </label>
                  </div>
                </div>
              </span>
            );
          })}
          <span className={`${styles.clearSpan}`} onClick={clearAllChatStatus}>
            Clear all
          </span>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default ChatStatusFilter;
