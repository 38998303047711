import { time } from "console";
import React, { useContext, useEffect, useState } from "react";
import { Button, CloseButton, Modal } from "react-bootstrap";
import { ModalContext } from "../../../Modal/ModalContext";

import styles from "../../Order.module.scss";
import style from "./OrderDetailTrackingModal.module.scss";
import OrderDetailsModal from "../OrderDetailsModal";
import Timeline, {
  TimelineItem,
  TimelineItemActive,
} from "./OrderTrackingTimeline/Timeline";
function OrderDetailTrackingModal(props: { getTrackingData?: any }) {
  const modalContext = useContext(ModalContext);

  const [OrderPlaced, setOrderPlaced] = useState<any>();
  const [OrderFulfilled, setOrderFulfilled] = useState<any>();
  const [Shipped, setShipped] = useState<any>();
  const [Delivered, setDelivered] = useState<any>();
  const [Return, setReturn] = useState<any>();
  const [Refund, setRefund] = useState<any>();
  const [latestStatusKey, setLatestStatusKey] = useState<string>("");
  console.log({
    OrderPlaced,
    OrderFulfilled,
    Shipped,
    Delivered,
    Return,
    Refund,
  });

  useEffect(() => {
    modalContext.setClassNames({ modalContent: styles.DetailModalContent });

    return () => {};
  }, []);

  useEffect(() => {
    if (props.getTrackingData && props.getTrackingData?.trackingDetails) {
      for (let i = 0; i < props.getTrackingData?.trackingDetails?.length; i++) {
        if (props.getTrackingData?.trackingDetails[i].key === "orderPlaced") {
          setOrderPlaced(props.getTrackingData?.trackingDetails[i]);
        } else if (
          props.getTrackingData?.trackingDetails[i].key === "orderFulfilled"
        ) {
          setOrderFulfilled(props.getTrackingData?.trackingDetails[i]);
        } else if (
          props.getTrackingData?.trackingDetails[i].key === "orderShipped"
        ) {
          setShipped(props.getTrackingData?.trackingDetails[i]);
        } else if (
          props.getTrackingData?.trackingDetails[i].key === "orderDelivered"
        ) {
          setDelivered(props.getTrackingData?.trackingDetails[i]);
        } else if (
          props.getTrackingData?.trackingDetails[i].key === "orderReturn"
        ) {
          setReturn(props.getTrackingData?.trackingDetails[i]);
        } else if (
          props.getTrackingData?.trackingDetails[i].key === "orderRefund"
        ) {
          setRefund(props.getTrackingData?.trackingDetails[i]);
        }
      }

      if (
        props.getTrackingData?.trackingDetails[
          props.getTrackingData?.trackingDetails?.length - 1
        ]
      ) {
        let latestStatus =
          props.getTrackingData?.trackingDetails[
            props.getTrackingData?.trackingDetails?.length - 1
          ];
        setLatestStatusKey(latestStatus.key);
      }
    }
  }, [props.getTrackingData]);

  const TrackingData = props.getTrackingData;

  const getStatusDate = (statusDate: string) => {
    let date = new Date(statusDate).toLocaleDateString("en-us", {
      hour: "2-digit",
      minute: "2-digit",
      year: "numeric",
      month: "short",
      day: "numeric",
    });
    return date;
  };

  function getTimelineOrderTrackingItem(
    itemIdx: number,
    item: OrderTrackingItem,
    itemName: string,
    itemKey: string,
    latestStatusIndex: number
  ): TimelineItem {
    const timelineItem: TimelineItem = {
      title: <p className="">{itemName}</p>,
      subtitle: <></>,
      content: <></>,
    };
    if (item === null || item === undefined) return timelineItem;
    if (item.name !== undefined && item.name !== null) {
      timelineItem.title = <p className="">{item.name}</p>;
    }
    timelineItem.subtitle = <></>;

    let createdAt = "",
      description = "",
      events: JSX.Element[] = [];
    if (item.createdAt !== undefined && item.createdAt !== null) {
      createdAt = item.createdAt;
      if (item.events !== undefined && item.events !== null) {
        if (item.events.length > 0) {
          item.events.forEach((e) =>
            events.push(
              <p className="mb-0">
                {e.description}|{e.createdAt}|{e.location}
              </p>
            )
          );
        }
      }
    }

    if (latestStatusIndex > itemIdx) {
      if (itemIdx >= 4) {
        timelineItem.dotActiveType = "danger";
        timelineItem.lineActiveType = "danger";
      } else if (itemIdx == 3) {
        if (latestStatusIndex >= 4) {
          timelineItem.dotActiveType = "success";
          timelineItem.lineActiveType = "danger";
        }
      } else {
        timelineItem.dotActiveType = "success";
        timelineItem.lineActiveType = "success";
      }
    } else if (latestStatusIndex === itemIdx) {
      if (itemIdx >= 4) {
        timelineItem.dotActiveType = "danger active";
      } else timelineItem.dotActiveType = "success active";
    } else {
    }

    timelineItem.content = (
      <div className={`${style.itemSubTitle}`}>
        {events.map((e) => e)}
        {createdAt}
      </div>
    );
    return timelineItem;
  }

  const orderTrackingItems = new Map<
    string,
    { name: string; item: OrderTrackingItem; idx: number }
  >([
    ["orderPlaced", { name: "Order Placed", item: OrderPlaced, idx: 0 }],
    [
      "orderFulfilled",
      { name: "Order Fulfilled ", item: OrderFulfilled, idx: 1 },
    ],
    ["orderShipped", { name: "Shipped", item: Shipped, idx: 2 }],
    ["orderDelivered", { name: "Delivered", item: Delivered, idx: 3 }],
    ["orderReturn", { name: "Return", item: Return, idx: 4 }],
    ["orderRefund", { name: "Refund", item: Refund, idx: 5 }],
  ]);

  let timelineItems: TimelineItem[] = [];
  const latestStatusIndex = orderTrackingItems.get(latestStatusKey)?.idx ?? -1;
  orderTrackingItems.forEach((val, key, map) => {
    timelineItems.push(
      getTimelineOrderTrackingItem(
        val.idx,
        val.item,
        val.name,
        key,
        latestStatusIndex
      )
    );
  });

  return (
    <div className="">
      <div className="d-none justify-content-end me-3 mt-3">
        <CloseButton
          onClick={() => modalContext?.handleModal?.call(null, undefined)}
        />
      </div>
      <Modal.Body className="d-flex flex-column  m-3">
        <div className="d-flex justify-content-between ">
          <div>
            <i
              className={`fa-solid fa-arrow-left ${styles.backBtn}`}
              onClick={() => modalContext?.handleModal?.call(null, undefined)}
            ></i>
          </div>
          <div className={`text-center ms-4 ${style.modalHead}`}>
            {TrackingData?.orderName} Order Tracking Details
          </div>

          <div className={`fs-6  mt-1 ${styles.insideModalFonts}`}>
            View order :{" "}
            <u
              className={`link-primary ${styles.orderIdLink}`}
              onClick={() => {
                modalContext.handleModal(
                  <OrderDetailsModal orderId={TrackingData?.orderId} />
                );
              }}
            >
              {TrackingData?.orderName}{" "}
              <i className="fa-solid fa-arrow-up-right-from-square ms-1"></i>
            </u>
          </div>
        </div>
        <div
          className={`d-flex flex-column mt-4  ${styles.timelineWidth} w-100  `}
        >
          <Timeline timelineItems={timelineItems} />
          {/* TimeLine for OrderPlaced */}
          {/* <ul
            className={`${styles.timeline}  ${
              latestStatusKey === "orderPlaced"
                ? styles.timelineActive
                : latestStatusKey === "orderFulfilled" ||
                  latestStatusKey === "orderShipped" ||
                  latestStatusKey === "orderDelivered"
                ? styles.timeline_Shipped
                : undefined
            }`}
          >
            <li className="d-flex flex-row  justify-content-between ms-3 ">
              <div className="text-sm">
                <small className="fw-bold fs-6">Order Placed</small>
                <br />
                <div
                  className={`fw-light  text-muted  ${styles.insideModalFonts} `}
                >
                  {OrderPlaced?.events[0]?.description}.. <br />
                  {getStatusDate(OrderPlaced?.createdAt)}
                </div>
              </div>
            </li>

            {OrderPlaced?.events
              ?.slice(1, OrderPlaced?.events?.length)
              .map((Item: any) => {
                return (
                  <>
                    <li className="d-flex flex-row  justify-content-between ms-3 ">
                      <div className="text-sm">
                        <div
                          className={`fw-light  text-muted  ${styles.insideModalFonts} `}
                        >
                          {Item.description} <br />
                          {getStatusDate(Item.createdAt)}
                        </div>
                      </div>
                    </li>
                  </>
                );
              })}
          </ul> */}

          {/* TimeLine for OrderFulfilled */}
          {/* <ul
            className={`${styles.timeline}  ${
              latestStatusKey === "orderFulfilled"
                ? styles.timelineShippedActive
                : latestStatusKey === "orderShipped" ||
                  latestStatusKey === "orderDelivered"
                ? styles.timeline_Shipped
                : undefined
            } `}
          >
            <li className="d-flex flex-row  justify-content-between ms-3 ">
              <div className="text-sm">
                <small className="fw-bold fs-6">Order Fulfilled</small>
              </div>
            </li>
            {OrderFulfilled?.events?.map((Item: any) => {
              return (
                <>
                  <li className="d-flex flex-row  justify-content-between ms-3 ">
                    <div className="text-sm">
                      <div
                        className={`fw-light  text-muted  ${styles.insideModalFonts} `}
                      >
                        {Item.description} <br />
                        {getStatusDate(Item.createdAt)}
                      </div>
                    </div>
                  </li>
                </>
              );
            })}
          </ul> */}

          {/* TimeLine for Shipped */}
          {/* <ul
            className={`${styles.timeline} ${
              latestStatusKey === "orderShipped"
                ? styles.timelineShippedActive
                : latestStatusKey === "orderDelivered"
                ? styles.timelineDelivered
                : undefined
            } `}
          >
            <li className="d-flex flex-row  justify-content-between ms-3 ">
              <div className="text-sm">
                <small className="fw-bold fs-6">Shipped</small>
              </div>
            </li>
            {Shipped?.events?.map((Item: any) => {
              return (
                <>
                  
                  <li className="d-flex flex-row  justify-content-between ms-3 ">
                    <div className="text-sm">
                      <div
                        className={`fw-light  text-muted  ${styles.insideModalFonts} `}
                      >
                        {Item.description} <br />
                        {getStatusDate(Item.createdAt)}
                      </div>
                    </div>
                  </li>
                </>
              );
            })}
          </ul> */}

          {/* TimeLine for Delivered */}
          {/* <ul
            className={`${styles.timeline}  ${
              latestStatusKey === "orderDelivered"
                ? styles.timelineDeliveredActive
                : latestStatusKey === "orderReturn"
                ? styles.timelineRefund
                : undefined
            } `}
          >
            <li className="d-flex flex-row  justify-content-between ms-3 ">
              <div className="text-sm">
                <small className="fw-bold fs-6">Delivered</small>
              </div>
            </li>
            {Delivered?.events.map((List: any) => {
              return (
                <li className="d-flex flex-row  justify-content-between ms-3 ">
                  <div className="text-sm">
                    <div
                      className={`fw-light  text-muted  ${styles.insideModalFonts} `}
                    >
                      {List?.description} at {List?.location}
                      <br />
                      {getStatusDate(List?.createdAt)}
                    </div>
                  </div>
                  <div className="text-sm"></div>
                </li>
              );
            })}
          </ul> */}

          {/* TimeLine for Return */}
          {/* <ul
            className={`${styles.timeline} ${
              latestStatusKey === "orderReturn"
                ? styles.timelineReturnActive
                : undefined
            }`}
          >
            <li className="d-flex flex-row  justify-content-between ms-3 ">
              <div className="text-sm">
                <small className="fw-bold fs-6">Return</small>
              </div>
            </li>

            {Return?.events.map((List: any) => {
              return (
                <li className="d-flex flex-row  justify-content-between ms-3 ">
                  <div className="text-sm">
                    <div
                      className={`fw-light  text-muted  ${styles.insideModalFonts} `}
                    >
                      {List?.description} at {List?.location}
                      <br />
                      {getStatusDate(List?.createdAt)}
                    </div>
                  </div>
                  <div className="text-sm"></div>
                </li>
              );
            })}
          </ul> */}

          {/* TimeLine for Refund */}
          {/* <ul
            className={`${styles.timeline}  ${
              latestStatusKey === "orderRefund"
                ? styles.timelineRefundActive
                : undefined
            }`}
          >
            <li className="d-flex flex-row  justify-content-between ms-3 ">
              <div className="text-sm">
                <small className="fw-bold fs-6">Refund</small>
              </div>
            </li>

            {Refund?.events.map((List: any) => {
              return (
                <li className="d-flex flex-row  justify-content-between ms-3 ">
                  <div className="text-sm">
                    <div
                      className={`fw-light  text-muted  ${styles.insideModalFonts} `}
                    >
                      {List?.description} at {List?.location}
                      <br />
                      {getStatusDate(List?.createdAt)}
                    </div>
                  </div>
                  <div className="text-sm"></div>
                </li>
              );
            })}
          </ul> */}
        </div>
      </Modal.Body>
    </div>
  );
}
type IEvent = TimelineItemActive & { description: string; createdAt: string };

function getOrderEvents(orderPlaced?: {
  events?: { description: string; createdAt: string }[];
}) {
  if (orderPlaced && orderPlaced.events) {
    return orderPlaced.events
      .slice(0, orderPlaced.events.length)
      .map((item) => {
        return item as IEvent;
      });
  } else {
    return [] as IEvent[];
  }
}

export interface OrderTrackingItemEvent {
  description: string;
  location: string;
  createdAt: string;
}

export interface OrderTrackingItem {
  name: string;
  key: string;
  createdAt: string;
  events: OrderTrackingItemEvent[];
}
export default OrderDetailTrackingModal;
