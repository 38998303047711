import { useState } from "react";
import HomeIcon from "src/assets/images/HomeIcon2.png";
import saufterLogo from "src/assets/images/saufter-full.png";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import styles from "./TransferToAgentModel.module.scss";

function TransferToAgentModel({
  onCancel,
  onSubmit,
  confirmationMsg = " Thanks for submitting your request! We are raising it with our team and our team will reach back to you with an update shortly.",
}: {
  onCancel: () => void;
  onSubmit: (message: string) => void;
  confirmationMsg?: string;
}) {
  const [confirmationMessage, setConfirmationMessage] =
    useState<string>(confirmationMsg);

  return (
    <div className={`d-flex flex-wrap`}>
      <div className={`col-md-7 ${styles.leftSideSection}`}>
        <div className={`p-3`}>
          <div className="p-2">
            <div>
              <h2 className={`${styles.heading}`}>
                Do you want to Transfer to an agent after this step?
              </h2>
              <p className={`${styles.para}`}>
                Once the customer has entered the input for this step and
                clicked 'Next', they will be shown the following screen and a
                ticket will be created for your team to review.
              </p>
            </div>
            <div>
              <span className={`${styles.label}`}>Confirmation message:</span>
              <textarea
                className={`form-control ${styles.textarea} w-100 p-2`}
                id="exampleFormControlTextarea1"
                rows={11}
                value={confirmationMessage}
                onChange={(e) => {
                  setConfirmationMessage(e.target.value);
                }}
              ></textarea>
            </div>
            <div>
              <div className="d-flex justify-content-between mt-3 pt-2">
                <div>
                  <button
                    className={`bg-white px-4 py-2 ${styles.cancelBtn}`}
                    onClick={onCancel}
                  >
                    Cancel
                  </button>
                </div>
                <div>
                  <button
                    className={`border-0 px-4 py-2 ${styles.saveBtn}`}
                    onClick={() => {
                      if (confirmationMessage.trim().length) {
                        onSubmit(confirmationMessage);
                      } else {
                        pushTheToast({
                          type: "warning",
                          text: "Confirmation message cannot be empty!",
                          position: "top-right",
                        });
                      }
                    }}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`col-md-5 ${styles.rightSideSection}`}>
        <div className={`${styles.widgetSection} p-3 w-100`}>
          <div className={`p-2 pb-0`}>
            <div className={`${styles.innerSection} pt-3 px-4 `}>
              <div className="d-flex pb-5">
                <div>
                  <img
                    src={HomeIcon}
                    alt=""
                  />
                </div>
                <div className={``}>
                  <span className={` mx-2 ${styles.widgetHeading}`}>
                    Initiate return order
                  </span>
                </div>
              </div>
              <div className={`m-auto py-5 my-5`}>
                <div>
                  <div className={`mb-3 ${styles.bgCheckIcon}`}>
                    <span className={`${styles.checkIcon}`}>
                      <i className="fa-solid fa-check"></i>
                    </span>
                  </div>
                </div>
                <div>
                  <p className={`${styles.innerText} text-center`}>
                    {confirmationMessage}
                  </p>
                </div>
              </div>

              <div
                className={`d-flex justify-content-center align-items-center  py-1 pt-5 ${styles.footer}`}
              >
                <span className={`ps-1 ${styles.powered}`}>Powered by</span>
                <a
                  className={`ms-1 ${styles.logo}`}
                  href="https://saufter.io"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={saufterLogo}
                    height="20"
                    alt="logo"
                  />
                </a>
                {/* <img
                  src={saufterLogo}
                  alt="logo"
                  className={`${styles.logo}`}
                />
                <span className={`ps-1 ${styles.powered}`}>
                  Powered by Saufter
                </span> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TransferToAgentModel;
