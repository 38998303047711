import { useEffect, useMemo, useState } from "react";
import { ILoginData } from "./useLogin";
import loginRedirectService from "src/services/Auth/loginRedirect.service";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { useNavigate } from "react-router-dom";

const getToken = () => {
  // Parse the current URL's search parameters
  const urlSearchParams = new URLSearchParams(window.location.search);

  // Get the value of the "login-redirect-token" parameter
  const token = urlSearchParams.get("login-redirect-token");

  // If you want to remove the "login-redirect-token" parameter from the URL
  urlSearchParams.delete("login-redirect-token");

  // Create a new URL without the "login-redirect-token" parameter
  const newUrl = `${window.location.pathname}?${urlSearchParams.toString()}`;

  // Use pushState to update the URL without triggering a page reload
  window.history.pushState({}, "", newUrl);

  return token;
};

//keeping the existing loginData from localstorage (used to restore the existing access token once loginRedirectService fullfilled with specific action type)
//currently link_account actionType uses this to restore the existing access token and navigate to user setting page
//we are saving this here as lastLoginData is set to null to avoid redirecting user to dashboard in useLogin hook, which will reset the storage once setInitializedRedirect true
let lastLoginData: any = JSON.parse(
  window.localStorage.getItem("loginData") as any,
);

function useLoginRedirect() {
  const token = useMemo(() => getToken(), []);
  const [initializedRedirect, setInitializedRedirect] = useState(
    token ? false : true,
  );

  const [loginDataRedirect, setLoginDataRedirect] = useState(
    null as null | ILoginData,
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      loginRedirectService({ token })
        .then((res) => {
          //Dec 30 If action type is login set login data
          if (res && res.actionType === "login") {
            if (res.first_login) {
              window.localStorage.setItem("loginData", JSON.stringify(res));
              window.location.replace(
                (process.env.REACT_APP_FIRST_LOGIN_ROUTE ?? "/") +
                  (window.location.hostname.includes("localhost")
                    ? `?loginData=${encodeURIComponent(JSON.stringify(res))}`
                    : ""),
              );
            } else {
              setLoginDataRedirect(res);
            }
          } else if (res && res.actionType === "signup") {
            //Dec 30 If action type is sign up,show toast messaage and redirect to login page
            navigate("/login");
            setTimeout(() => {
              pushTheToast({
                type: "success",
                text: "Signed up successfully!",
                position: "top-right",
              });
            }, 0);
          } else if (res && res.actionType === "link_account") {
            //Dec 30 If action type is sign up,show toast messaage and redirect to login page
            setLoginDataRedirect(lastLoginData);
            navigate("/settings/Profile");
            setTimeout(() => {
              pushTheToast({
                type: "success",
                text: "Account successfully linked!",
                position: "top-right",
              });
            }, 0);
          }
          setInitializedRedirect(true);
        })
        .catch((err: any) => {
          console.error(err);
          setInitializedRedirect(true);
          //If there is any error during login or signup,show a toast message
          pushTheToast({
            type: "danger",
            text: err.message,
            position: "top-right",
          });
        });
    }
  }, [token]);

  return { initializedRedirect, loginDataRedirect };
}

export default useLoginRedirect;
