import UserAvatar from "src/components/UserAvatar";
import styles from "./BlurredUI.module.scss";
import { Column } from "src/hooks/useTicketViews/ticketViewsConfig";
import { useAppSelector } from "src/store/store";

/**
 * Component for showing All tickets Column
 */
const AllTickets = () => {
  return (
    <span className="d-flex flex-column justify-content-center">
      <div className={`${styles.root} d-flex`}>
        <div
          style={{
            width: " 24px",
            height: "24px",
          }}
        ></div>
        <div className={styles.allTicketsTbody}>
          <div className={`row m-auto`}>
            <div className={`col-md-12`}>
              <div className={styles.tbody}>NA</div>
            </div>
          </div>
        </div>
      </div>
    </span>
  );
};

/**
 * Component for showing Brands Column
 */
const Brand = () => {
  return (
    <div className={`${styles.subject}`}>
      <span className={`${styles.tbody}`}>
        <span className="me-2">
          <UserAvatar name={"NA"} size={30} />
        </span>
        <span className={`${styles.brandName}`}>NA</span>
      </span>
    </div>
  );
};

/**
 * Component for showing Channel Column
 */
const Channel = () => {
  return (
    <div className="d-flex flex-column justify-content-center">
      <div className={`${styles.channel} `}>
        <span className={`${styles.tbody}`}>
          <span className={`me-1 px-4 fb`}>
            <i className={`fa-brands override fa-solid fa-envelope`} />
          </span>
        </span>
      </div>
    </div>
  );
};

/**
 * Component for showing Customer Column
 */
const Customer = () => {
  return (
    <div className={`d-flex`}>
      <div
        style={{
          width: " 24px",
          height: "24px",
        }}
      ></div>
      <div className={`${styles.AllCust}`}>
        <span className={`${styles.AllCustDiv}`}>
          <span className="me-2">
            <UserAvatar name={"NA"} size={30} />
          </span>
          <span className={`${styles.custName}`}>NA</span>
        </span>
      </div>
    </div>
  );
};

/**
 * Component for showing Tags Column
 */
const Tags = () => {
  return (
    <div className={`${styles.tagsParent}`}>
      <div className={`${styles.ticketTags}`}>
        <span className={`p-2 ${styles.na}`}> NA </span>
      </div>
    </div>
  );
};

/**
 * Component for showing Ticket not replied since Column
 */
const TicketNotRepliedSince = () => {
  return (
    <div className={`${styles.notRepliedSubject}`}>
      <span className={`${styles.tbody} ${styles.notReplied}`}>
        <span className={`me-1`}>
          <i className={`fas fa-clock`} />
        </span>
        <span>NA</span>
      </span>
    </div>
  );
};

/**
 * Component for showing Last Message recieved Column
 */
const LastMessageReceived = () => {
  return (
    <div className={`${styles.LastMessageHead}`}>
      <span className={`${styles.msgHead}`}>NA</span>
    </div>
  );
};

/**
 * Component for showing Multiple Columns that have some text data
 */
const JustShowString = () => {
  return (
    <span className={`${styles.justString}`}>
      <div className={`${styles.subjectJustShowString} text-center`}>
        <span className={`${styles.tbodyJustShowString}`}>NA</span>
      </div>
    </span>
  );
};

/**
 * Component for showing Ticket ID column
 */
const ResponseTicketID = () => {
  return (
    <div className={`${styles.ticketIDWrapper}`}>
      <span className={`${styles.ticketId}`}>1234</span>
    </div>
  );
};

/**
 * Component for showing Assigned To Column
 */
const AssignedTo = () => {
  return (
    <div className={`${styles.assignedToSubject}`}>
      <span className={`${styles.tbody}`}>
        <span className={`text-truncate ${styles.custName}`}>NA</span>
      </span>
    </div>
  );
};

/**
 * Component for showing Ticket Status Column
 */
const ResponseTicketStatus = () => {
  return (
    <div className={`ignoreClick ${styles.ResponseTicketMain}`}>
      {/* Status Button */}
      <span
        className={`px-2 ${styles.ticketCurrentStatus} ${styles.ticketStatusOpen} max-content-width`}
      >
        <i
          className={`d-inline-block my-auto fa-solid fa-circle ${styles.smallFa}`}
        ></i>
        <span className={`mx-1 d-inline-block`}></span>
        <div>
          <span>Open</span>
        </div>
        <span className={`mx-1 d-inline-block`}></span>
        <i className={`fa-solid m-auto fa-caret-down`}></i>
      </span>
    </div>
  );
};

/**
 * Component for showing Draft Status Column
 */
const ShowDraftStatus = () => {
  return (
    <div className={`ignoreClick ${styles.draftStatusMain}`}>
      <button className={`${styles.showDraftButton}`}>
        <span className={`d-flex`}>
          <span className={`my-auto mx-1`}>Open</span>
          <i className={`my-auto mx-1 fa-solid fa-caret-down ms-2`} />
        </span>
      </button>
    </div>
  );
};

/**
 * Component for showing Open tickets Column
 */
const OpenTickets = () => {
  return (
    <div className={`${styles.openTicket}`}>
      <span>00</span>
    </div>
  );
};

/**
 * Map for getting correct compoent based on column id
 */
const allColumnComponents: Record<number, JSX.Element> = {
  1: <AllTickets />,
  2: <Channel />,
  3: <Customer />,
  4: <Tags />,
  5: <TicketNotRepliedSince />,
  6: <AllTickets />,
  7: <LastMessageReceived />,
  8: <JustShowString />,
  9: <ResponseTicketID />,
  10: <JustShowString />,
  11: <JustShowString />,
  12: <AssignedTo />,
  13: <TicketNotRepliedSince />,
  14: <TicketNotRepliedSince />,
  15: <TicketNotRepliedSince />,
  16: <ResponseTicketStatus />,
  17: <JustShowString />,
  23: <ShowDraftStatus />,
  24: <Customer />,
  25: <OpenTickets />,
  26: <Brand />,
};

/**
 * Renders Limiter background Ticket Row for showing blurred UI
 */
const BlurredUI = ({ columns }: { columns: Column[] }) => {
  // Scope to match the UI with rest of table in case bulk edit is hidden
  const scopes = useAppSelector(
    (state) => state.globals.currentUserData?.scopes
  );

  return (
    <>
      {/* Bulk Tick */}
      <div className={styles.table_row}>
        {scopes && scopes.includes("bulk_edit") && (
          <input
            className={`form-check-input ${styles.checkbox_row}`}
            data-lpignore="true"
            type="checkbox"
            disabled
          />
        )}
      </div>

      {/* Table Row */}
      <div className={`d-flex py-2 ${styles.table_body}`}>
        {columns.map((col, idx) => {
          if (col.show === true) {
            return <div key={idx}>{allColumnComponents[col.id]}</div>;
          }
          return <div key={idx} style={{ display: "none" }} />;
        })}
      </div>
    </>
  );
};

export default BlurredUI;
