import {
  ChannelType,
  PhoneNumberData,
  WhatsappToResponse,
  getWhatsappToData,
} from "src/services/LiveChat/startNewChat/startNewChat.services";
import styles from "./To.module.scss";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Options } from "intl-tel-input";
import intlTelInput from "intl-tel-input";
import PhoneDropdown from "../PhoneDrodpown/PhoneDropdown";
import SelectAsyncData from "src/components/ReactSelectUtilities/SecectAsyncData/SecectAsyncData";
import { getCountryCodeFromISO2 } from "src/components/IntlTelInput/IntlTelInput";
import { IChatChannelDetail } from "src/services/LiveChat/messageExchange/getAllCustomers";
import { EChannel } from "src/enums/EChannel";

type Props = {
  userId?: string | number;
  activeMessageType: EChannel;
  selectedToPhone: PhoneNumberData | IChatChannelDetail | undefined;
  setSelectedToPhone: any;
  showError: boolean;
  canFetchInitial?: boolean;
  isDisabled?: boolean;
};

function To({
  userId,
  activeMessageType,
  selectedToPhone,
  setSelectedToPhone,
  showError,
  canFetchInitial = false,
  isDisabled = false,
}: Props) {
  const phoneNumberRef = useRef<any>(null);
  const iti = useRef<any>(null);

  useEffect(() => {
    if (activeMessageType === "whatsapp") {
      const options: Options = {
        utilsScript:
          "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.12/js/utils.js",
        formatOnDisplay: true,
      };

      if (phoneNumberRef) {
        iti.current = intlTelInput(phoneNumberRef.current, options);
        iti.current.flagsContainer.lastChild.style.position = "fixed";
        iti.current.flagsContainer.lastChild.style.zIndex = "99";
        iti.current.flagsContainer.lastChild.style.width = "25%";
        iti.current.flagsContainer.style.background =
          "#FFFFFF 0% 0% no-repeat padding-box";
        iti.current.flagsContainer.style.border = "1px solid #C3C3C3";
        iti.current.flagsContainer.style.borderRadius = "6px";
      }
    }
  }, [activeMessageType]);

  interface Option {
    label: string;
    value: PhoneNumberData;
  }
  const fetchWhatsAppTo = useCallback(
    async (searchTerm: string, loadOptions: any, additional: any) => {
      const limit = 25;
      const { data, metaData }: WhatsappToResponse = await getWhatsappToData({
        searchTerm,
        start: additional ? additional.page : 0,
        limit,
        channelType: activeMessageType,
        customerId: userId ?? "",
      });
      const retValue: any = data.map((values, key) => {
        return {
          label: `${values.phoneNumber}`,
          value: values,
        };
      });

      return {
        options: retValue,
        hasMore: metaData.currentCount === limit,
        additional: {
          page: additional
            ? additional.page - metaData.currentCount
            : metaData.totalCount,
        },
      };
    },
    [],
  );

  useEffect(() => {
    if (
      selectedToPhone &&
      "countryCode" in selectedToPhone &&
      selectedToPhone.countryCode
    ) {
      const iso2 = getCountryCodeFromISO2(
        selectedToPhone.countryCode.toString(),
      );
      if (iso2) {
        iti.current?.setCountry(iso2);
      }
    }
  }, [selectedToPhone && (selectedToPhone as any).countryCode]);

  return (
    <>
      <div className="d-flex mb-1">
        <label>To:</label>
        <div
          className={`d-flex flex-column justify-content-center w-100 ${
            styles.formInput
          } ${
            showError && selectedToPhone === undefined && "border border-danger"
          }`}
        >
          <div className="position-relative d-flex align-items-center">
            <div
              className={`${styles.flagsContainer} ${
                activeMessageType !== "whatsapp" ? "d-none" : ""
              }`}
            >
              <input
                type="tel"
                className={`${styles.telInput} m-0 w-0 p-0`}
                ref={phoneNumberRef}
                disabled
              />
            </div>
            <div
              className={`${styles.dropdown} ${
                activeMessageType !== "whatsapp"
                  ? "ms-1 w-100"
                  : `${styles.hasFlag}`
              }`}
            >
              <SelectAsyncData
                isMulti={false}
                styles={customStyles}
                currentValue={
                  selectedToPhone && "phoneNumber" in selectedToPhone
                    ? selectedToPhone.phoneNumber
                    : selectedToPhone?.value && activeMessageType == "instagram"
                      ? "@" + selectedToPhone?.value
                      : selectedToPhone?.value
                }
                isDisabled={isDisabled}
                className={`w-100`}
                placeholder={""}
                components={{
                  IndicatorSeparator: null,
                }}
                fetchFunction={fetchWhatsAppTo}
                onChange={(newValueArr: any) => {
                  if (newValueArr.length) {
                    const newValue: Option =
                      newValueArr[newValueArr.length - 1];

                    setSelectedToPhone(newValue.value);
                  }
                }}
                textToHighlight={""}
                name={"whatsappTo"}
                canFetchInitial={canFetchInitial}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
const customStyles = {
  control: (
    provided: any,
    { isFocused, isSelected }: { isFocused: any; isSelected: any },
  ) => ({
    ...provided,
    background: "#f5f5f5 0% 0% no-repeat padding-box",
    minHeight: "24px",
    width: "100%",
    border: "none",
    "&:hover": {
      border: "none", // Add this line to explicitly set border to none on hover
    },
    ...(isFocused && {
      border: "none", // Remove border when selected
    }),
    ...(isSelected && {
      border: "none", // Remove border when selected
    }),
    "&:focus": {
      border: "none", // Remove default focus outline
    },
  }),

  valueContainer: (provided: any, _: any) => ({
    ...provided,
    minHeight: "24px",
    padding: "0px !important",
    fontSize: "12px",
  }),

  input: (provided: any, _: any) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorsContainer: (provided: any, _: any) => ({
    ...provided,
    minHeight: "24px",
    padding: "0px !important",
    marginRight: "6px",
  }),
  dropdownIndicator: (provided: any, _: any) => ({
    ...provided,
    padding: "0px !important",
  }),
  MenuPortal: (provided: any, _: any) => ({
    ...provided,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    // boxShadow: "0px 16px 48px #00000029",
    // borderRadius: "10px",
    // border: "1px solid #fff",
    border: "none",
  }),
  option: (provided: any, _: any) => ({
    ...provided,
    textAlign: "left",
    font: "normal normal 500 12px/18px Poppins",
    letterSpacing: "0px",
    color: "#000000",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    // padding: "6px 12px",
    "&:hover": {
      background: "#fff5f6 0% 0% no-repeat padding-box",
    },
  }),
  clearIndicator: (provided: any, _: any) => ({
    ...provided,
    padding: "0px !important",
  }),
  multiValueLabel: (provided: any, _: any) => ({
    ...provided,
    padding: "0",
    textAlign: "left",
    font: "normal normal normal 12px/18px Poppins",
    letterSpacing: "0px",
    color: "#707070",
    opacity: "1",
  }),
  multiValue: (provided: any, _: any) => ({
    ...provided,
    paddingRight: "2px",
    background: "white",
  }),
  placeholder: (provided: any) => ({ ...provided, fontSize: "12px" }),
};

export default To;
