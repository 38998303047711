import styles from "./UrlBox.module.scss";
import Select from "react-select";
import { RoutingRuleCondition } from "src/store/slices/liveChatSetting/RoutingRules/RoutingRulesSetting.slice";
import InputBox from "../inputBox/inputBox";
interface Props {
  heading: string;
  conditionType: string;
  conditions: Array<any>;
  placeholder: string;
  handleConditionDelete: any;
  handleConditionAddNew: any;
  handleConditionOnChange: any;
  handleConditionBoxDelete: any;
}
const UrlBox = ({
  heading,
  conditionType,
  conditions,
  placeholder,
  handleConditionDelete,
  handleConditionAddNew,
  handleConditionOnChange,
  handleConditionBoxDelete,
}: Props) => {
  return (
    <div className={`p-2 p-md-3 ${styles.urlBox}`}>
      <div className={`d-flex justify-content-between align-items-center`}>
        <span className={`${styles.urlHead}`}> {heading}</span>
        <span className={`${styles.urlHead}`}>
          <i
            className="fa-solid fa-trash"
            onClick={(e) => handleConditionBoxDelete(conditionType)}
            role={"button"}
          ></i>
        </span>
      </div>
      {conditions &&
        conditions.map((condition) => {
          if (condition === undefined || condition === null) {
            return;
          }
          return (
            <InputBox
              key={condition.conditionId}
              condition={condition}
              conditionType={conditionType}
              placeholder={placeholder}
              handleConditionDelete={handleConditionDelete}
              handleConditionOnChange={handleConditionOnChange}
            />
          );
        })}
      <div className={`mt-2 d-flex align-items-center`}>
        <span className={`${styles.addBox} me-2`}>
          <i
            className="fa-solid fa-plus"
            role={`button`}
            onClick={(e) => handleConditionAddNew(conditionType)}
          ></i>
        </span>
        <span className={`${styles.addElement}`} role={`button`}>
          Add another URL
        </span>
      </div>
    </div>
  );
};
export default UrlBox;
