import { GetConfigModalParams } from "src/features/ReturnAutoWorkFlow/children/ConfigModals/oldReturnModals.types";
import { axiosJSON } from "src/globals/axiosEndPoints";

export interface GetDiscountCodesParams extends GetConfigModalParams {
  limit: number;
  startCursor?: string;
}

export interface DiscountCodeData {
  title: string;
  code: string;
}

export interface GetDiscountCodes {
  data: DiscountCodeData[];
  metaData: {
    hasNextPage: boolean;
    endCursor: string;
  };
}

async function getDiscountCodesService(payload: GetDiscountCodesParams) {
  const { data: res } = await axiosJSON.post(
    "/api/setting/automation/refund/discount/getAllCodes",
    payload,
  );

  if (res.err || res.error) {
    throw new Error(res.msg ?? res.message ?? "Some Error Occurred.");
  }

  const ret: GetDiscountCodes = {
    data: res.data,
    metaData: {
      hasNextPage: res.metaData?.hasNextPage ?? false,
      endCursor: res.metaData?.endCursor ?? "",
    },
  };

  return ret;
}

export default getDiscountCodesService;
