import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";
import { AutomationAPIPayload } from "./createAutomation.service";

export interface DeleteAutomationParams {
  integrationId: number | string;
}

export const deleteAutomationService = async ({
  type,
  payload,
}: AutomationAPIPayload<DeleteAutomationParams>) => {
  const url = IS_TEST_ACTIVE
    ? `${process.env.REACT_APP_TEST_SITE_URL}/${type}/delete`
    : `/api/automation/${type}/delete`;

  const { data: res } = await axiosJSON.delete(url, { data: payload });

  if (res.err || res.error) {
    throw new Error(
      res.msg || res.message || "Could not delete the automation",
    );
  }
};
