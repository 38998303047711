import styles from "./BrowseHomePage.module.scss";
import SearchBar from "src/components/SearchBar/SearchBar";
import BrowseCard from "../BrowseCard/BrowseCard";
import logo from "src/assets/images/browseShop.png";
import emailLogo from "src/assets/images/browseIntEmail.png";
import sms from "src/assets/images/browseSms.png";
import whatsapp from "src/assets/images/whatsapp.png";
// import slack from "src/assets/images/integrateSlack.png";
import slack from "src/assets/images/slackImg.png";
import fb from "src/assets/images/browseFb.png";
import insta from "src/assets/images/browseInsta.png";
import { EIntegrationTypes } from "src/enums/EIntegrationTypes";
import { useEffect, useState } from "react";
import Loader from "src/components/Loader";
import {
  getMyIntegrations,
  IMyIntegration,
} from "src/services/Integrations/myIntegrations";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { v4 as uuidv4 } from "uuid";
import noResultsFoundImg from "src/assets/images/noResultFound.png";
import { useAppSelector } from "src/store/store";
import { getBrandingName } from "src/utils/utils";

const brandingName = getBrandingName();

/**
 * This is function which returns the object of IBorwseCardList items.
 **/
const channelCardList = ({
  showFb = false, // used to check if we can show facebook.
  showSMS = false, // used to check if we can show SMS.
  showEmail = false, // used to check if we can show Email.
  showWhatsapp = false,
  showSlack = false,
  disabledFeatures,
}: {
  showFb?: boolean;
  showSMS?: boolean;
  showEmail?: boolean;
  showWhatsapp?: boolean;
  showSlack?: boolean;
  disabledFeatures?: Array<string>;
}) => {
  /**
   * Variable created to store all the browse integration social cards.
   **/
  const ret: { [key: string | number]: IBorwseCardList } = {};

  if (showFb) {
    ret[EIntegrationTypes.INTEGRATION_TYPE_FACEBOOK] = {
      integrationTypeId: EIntegrationTypes.INTEGRATION_TYPE_FACEBOOK,
      cardName: "Facebook/Instagram",
      cardSubHead: "Engage with billions of people on Facebook.",
      integrationImage: [fb, insta],
      integrationTo: "/settings/integrations/facebook",
      connectionCount: 0,
      disabled:
        (disabledFeatures &&
          disabledFeatures.includes("facebook_integration")) ??
        false, // Show plan upgrade when facebook is icluded in disabledFeatures
    };
  }
  if (showEmail) {
    ret[EIntegrationTypes.INTEGRATION_TYPE_EMAIL] = {
      integrationTypeId: EIntegrationTypes.INTEGRATION_TYPE_EMAIL,
      cardName: "Email",
      cardSubHead:
        "Send and receive e-mails and always stay on top of your customer experience",
      integrationImage: [emailLogo],
      integrationTo: "/settings/integrations/email",
      connectionCount: 0,
      disabled:
        (disabledFeatures && disabledFeatures.includes("email_integration")) ??
        false,
    };
  }
  if (showSMS) {
    ret[EIntegrationTypes.INTEGRATION_TYPE_SMS] = {
      integrationTypeId: EIntegrationTypes.INTEGRATION_TYPE_SMS,
      cardName: "Sms",
      cardSubHead:
        "Send and receive text messages and always stay on top of your customer experience",
      integrationImage: [sms],
      integrationTo: "",
      connectionCount: 0,
      disabled: false,
    };
  }

  if (showWhatsapp) {
    ret[EIntegrationTypes.INTEGRATION_TYPE_WHATSAPP] = {
      integrationTypeId: EIntegrationTypes.INTEGRATION_TYPE_WHATSAPP,
      cardName: "Whatsapp",
      cardSubHead:
        "Send and receive whatsapp messages and always stay on top of your customer experience",
      integrationImage: [whatsapp],
      integrationTo: "/settings/integrations/whatsapp",
      connectionCount: 0,
      disabled:
        (disabledFeatures &&
          disabledFeatures.includes("whatsapp_integration")) ??
        false,
    };
  }
  if (showSlack) {
    ret[EIntegrationTypes.INTEGRATION_TYPE_SLACK] = {
      integrationTypeId: EIntegrationTypes.INTEGRATION_TYPE_SLACK,
      cardName: "Slack",
      cardSubHead:
        "Connect your helpdesk with Slack to manage customer queries in real-time.",
      integrationImage: [slack],
      integrationTo: "/settings/integrations/slack",
      connectionCount: 0,
    };
  }

  return ret;
};
/**
 * This is function which returns the object of IBorwseCardList items.
 **/
const eCommerceCardList = ({
  showShopify = false, // used to check if we can show shopify.
}) => {
  /**
   * Variable created to store all the browse integration eCommerce.
   **/
  const ret: { [key: string | number]: IBorwseCardList } = {};

  //show shopify based on ui visibilty
  if (showShopify) {
    ret[EIntegrationTypes.INTEGRATION_TYPE_SHOPIFY] = {
      integrationTypeId: EIntegrationTypes.INTEGRATION_TYPE_SHOPIFY,
      cardName: "Shopify",
      cardSubHead: `Add ${brandingName} support to Shopify based stores`,
      integrationImage: [logo],
      integrationTo: "/settings/integrations/shopify",
      connectionCount: 0,
    };
  }
  return ret;
};

interface IBorwseCardList {
  cardName: string; // the card name
  cardSubHead: string; // the card sub heading
  integrationTypeId: string | number; // the integration type id
  integrationImage: Array<any>; // image of the social channel
  integrationTo: string;
  connectionCount: number; // how many connections are added in integration.
  disabled?: boolean; // To show plan upgrade
}

const BrowseHomePage = () => {
  // Fetching disabledFeatures from the Redux store
  const disabledFeatures = useAppSelector((state) => {
    return state.globals.currentUserData?.disabledFeatures;
  });

  const ui_visibility = useAppSelector(
    (state) => state.globals.currentUserData?.ui_visibility,
  );
  // object that contains all ecommerce cards
  let [eCommerceBrowseCardList, setECommerceBrowseCardList] = useState<{
    [key: string | number]: IBorwseCardList;
  }>(
    // calling the function to get the IBorwseCardList
    eCommerceCardList({
      showShopify: ui_visibility?.includes("integration_shopify_view"),
    }),
  );

  // object that contains all channel cards
  let [channelsBrowseCardList, setChannelsBrowseCardList] = useState<{
    [key: string | number]: IBorwseCardList;
  }>(
    // calling the function to get the IBorwseCardList
    channelCardList({
      showEmail: true,
      showFb: ui_visibility?.includes("integration_facebook_view"),
      disabledFeatures: disabledFeatures ?? [],
      showWhatsapp: ui_visibility?.includes("integration_whatsapp_view"),
      showSlack: ui_visibility?.includes("slack_integration_feature"),
      // showSlack: true
    }),
  );

  // state for ecommerce card list
  const [eCommerceBrowseCardListState, setECommerceBrowseCardListState] =
    useState<{ [key: string | number]: IBorwseCardList }>(
      eCommerceBrowseCardList,
    );

  // state for channels card list
  const [channelsBrowseCardListState, setChannelsBrowseCardListState] =
    useState<{ [key: string | number]: IBorwseCardList }>(
      channelsBrowseCardList,
    );

  // for showing loader
  const [loading, setLoading] = useState<boolean>(false);

  // for search
  const [browseSearchText, setBrowseSearchText] = useState<string>("");

  // handle the search
  const handleOnSearchBrowseIntegration = (searchTerm: string) => {
    searchTerm = searchTerm.toLowerCase().trim();
    let channelsSearchResults: { [key: string | number]: IBorwseCardList } = {};
    let eCommerceSearchResults: { [key: string | number]: IBorwseCardList } =
      {};

    Object.keys(eCommerceBrowseCardList).map((key, index) => {
      let cardItem: IBorwseCardList = eCommerceBrowseCardList[key];
      if (cardItem.cardName.toLowerCase().includes(searchTerm) === true) {
        eCommerceSearchResults[key] = cardItem;
      }
    });

    Object.keys(channelsBrowseCardList).map((key, index) => {
      let cardItem: IBorwseCardList = channelsBrowseCardList[key];
      if (cardItem.cardName.toLowerCase().includes(searchTerm) === true) {
        channelsSearchResults[key] = cardItem;
      }
    });

    setECommerceBrowseCardListState(eCommerceSearchResults);
    setChannelsBrowseCardListState(channelsSearchResults);
  };

  // fetch the connection count of each integration card
  useEffect(() => {
    setLoading(true);
    getMyIntegrations()
      .then((res) => {
        // res data that contains count of integrated things
        let integratedCardData: { [key: string | number]: IMyIntegration } =
          res.integrations;

        let eCommerceBrowseCardListTemp: {
          [key: string | number]: IBorwseCardList;
        } = eCommerceBrowseCardList;

        let channelsBrowseCardListTemp: {
          [key: string | number]: IBorwseCardList;
        } = channelsBrowseCardList;

        // iterate through integrated card data res
        Object.keys(integratedCardData).map((integrationTypeId) => {
          // update the connection count
          if (eCommerceBrowseCardListTemp[integrationTypeId]) {
            eCommerceBrowseCardListTemp[integrationTypeId].connectionCount =
              integratedCardData[integrationTypeId].integratedCount;
          }

          // update the connection count
          if (channelsBrowseCardListTemp[integrationTypeId]) {
            channelsBrowseCardListTemp[integrationTypeId].connectionCount =
              integratedCardData[integrationTypeId].integratedCount;
          }
        });

        setECommerceBrowseCardList(eCommerceBrowseCardListTemp);
        setChannelsBrowseCardList(channelsBrowseCardListTemp);

        // update the states
        setECommerceBrowseCardListState(eCommerceBrowseCardListTemp);
        setChannelsBrowseCardListState(channelsBrowseCardListTemp);

        setBrowseSearchText("");
        setLoading(false);
      })
      .catch((err) => {
        pushTheToast({
          type: "danger",
          text: err.message,
          position: "top-right",
        });
        setLoading(false);
      });
  }, [false]);

  return (
    <div className={`${styles.browseMain}`}>
      <h1 className={`${styles.browseHead}`}>Browse Integration</h1>
      {/* Nov 24 search bar for browse integration page */}
      <div>
        <SearchBar
          className={`${styles.search} ${
            loading === false &&
            browseSearchText.trim() !== "" &&
            Object.keys(eCommerceBrowseCardListState).length === 0 &&
            Object.keys(channelsBrowseCardListState).length === 0
              ? styles.noSearchResultFoundBorder
              : ``
          } px-2`}
          inputClassName={`${styles.input}`}
          placeholder={`Search`}
          value={browseSearchText}
          onChange={(e: any) => {
            setBrowseSearchText(e.target.value);
          }}
          onSearch={(value: any) => {
            if (loading === false) handleOnSearchBrowseIntegration(value);
          }}
        />
      </div>

      {/* Show loader */}
      {loading === true && <Loader />}

      {/* if no results found */}
      {loading === false &&
        Object.keys(eCommerceBrowseCardListState).length === 0 &&
        Object.keys(channelsBrowseCardListState).length === 0 && (
          <div
            className={`${styles.noIntegrationCard} d-flex justify-content-center align-items-center h-100 text-center`}
          >
            <div>
              <div className="images">
                <img
                  src={noResultsFoundImg}
                  className={`img-fluid ${styles.nothingFoundImg}`}
                />
              </div>
              <div className={styles.nothingFoundTextDiv}>
                <h3 className={styles.nothingFoundText}>
                  No search result found
                </h3>
              </div>
            </div>
          </div>
        )}

      {/* Show the ecommerce platform cards*/}
      {loading === false && //removed hardcoded boolean to show shopify integration
        Object.keys(eCommerceBrowseCardListState).length > 0 && (
          <>
            <div className={`mt-4`}>
              <h2 className={`${styles.ecomHead}`}>Ecommerce Platforms</h2>
              <div className={`container-fluid`}>
                <div className={`row`}>
                  {Object.keys(eCommerceBrowseCardListState).map(
                    (integrationTypeId) => {
                      let cardItem: IBorwseCardList =
                        eCommerceBrowseCardListState[integrationTypeId];
                      return (
                        <BrowseCard
                          key={uuidv4()}
                          cardName={cardItem.cardName}
                          cardSubHead={cardItem.cardSubHead}
                          integrationImage={cardItem.integrationImage}
                          integrationTo={cardItem.integrationTo}
                          connectionCount={cardItem.connectionCount}
                        />
                      );
                    },
                  )}
                </div>
              </div>
            </div>
          </>
        )}

      {/* Show the channels cards */}
      {loading === false &&
        Object.keys(channelsBrowseCardListState).length > 0 && (
          <>
            <div className={`mt-4`}>
              <h2 className={`${styles.ecomHead}`}>Channels</h2>
              <div className={`container-fluid`}>
                <div className={`row`}>
                  {Object.keys(channelsBrowseCardListState).map(
                    (integrationTypeId) => {
                      let cardItem: IBorwseCardList =
                        channelsBrowseCardListState[integrationTypeId];
                      return (
                        <BrowseCard
                          key={uuidv4()}
                          cardName={cardItem.cardName}
                          cardSubHead={cardItem.cardSubHead}
                          integrationImage={cardItem.integrationImage}
                          integrationTo={cardItem.integrationTo}
                          connectionCount={cardItem.connectionCount}
                          disabled={cardItem.disabled ?? false}
                        />
                      );
                    },
                  )}
                </div>
              </div>
            </div>
          </>
        )}
    </div>
  );
};

export default BrowseHomePage;
