import styles from "./TypeCard.module.scss";
import caution from "src/assets/images/caution.png";
import { useAppSelector } from "src/store/store";

interface Props {
  orderTitle: string;
  orderImg: string;
  orderData1: string;
  orderData2: string;
  showComment: string;
}

const TypeCard = ({
  orderTitle,
  orderImg,
  orderData1,
  orderData2,
  showComment,
}: Props) => {
  const selectedTab = useAppSelector((state) => {
    // if (state.step11Config.selectedReturnTypeId === "1") {
    //   return state.step11Config.allOrderAndItem;
    // }
    return state.step11Config.returnTypes.find(
      (item) => item.returnTypeId === state.step11Config.selectedReturnTypeId
    );
  });

  return (
    <>
      <div className="pb-2 ">
        <div>
          {/* <span className={`d-inline-block px-1 my-2 py-1 ${styles.errormsg}`}>
            *Please select a return type
          </span> */}
        </div>
        <div>
          <div className={`px-3 py-2 mt-2 ${styles.cardBox}`}>
            <div>
              <span className={`${styles.orderName}`}>{orderTitle} </span>
              <div className="d-flex mb-3">
                <img src={orderImg} className={`${styles.orderImg}`} />
                <div className="ms-1">
                  <span className={`d-block ${styles.orderData}`}>
                    {orderData1}
                  </span>

                  <span className={`d-block ${styles.orderData}`}>
                    {orderData2}
                  </span>
                </div>
              </div>
            </div>
            <div className="form-check">
              <input
                className={`form-check-input ${styles.radioCheck}`}
                type="radio"
                name="item"
                id="item1"
                disabled={!selectedTab?.ExchangeWithSameItem}
              />
              <label
                className={`form-check-label ${styles.labelItem}`}
                htmlFor="item1"
              >
                Exchange with same item
              </label>
            </div>
            <div className="form-check">
              <input
                className={`form-check-input ${styles.radioCheck}`}
                type="radio"
                name="item"
                id="item2"
                disabled={!selectedTab?.ExchangeWithOtherItem}
              />
              <label
                className={`form-check-label ${styles.labelItem}`}
                htmlFor="item"
              >
                Exchange with other items
              </label>
            </div>
            <div className="form-check">
              <input
                className={`form-check-input ${styles.radioCheck}`}
                type="radio"
                name="item"
                id="item3"
                disabled={!selectedTab?.RefundtoStoreCredit}
              />
              <label
                className={`form-check-label ${styles.labelItem}`}
                htmlFor="item"
              >
                Refund to Store credit
              </label>
            </div>
            <div className="form-check ">
              <input
                className={`form-check-input ${styles.radioCheck}`}
                type="radio"
                name="item"
                id="item4"
                disabled={!selectedTab?.RefundToOriginalPayment}
              />
              <label
                className={`form-check-label ${styles.labelItem}`}
                htmlFor="item"
              >
                Refund to original payment method
              </label>
            </div>

            {!selectedTab?.ExchangeWithSameItem ? (
              <div
                className={`${showComment} my-1 py-1 px-2 ${styles.cautionBox} `}
              >
                <img
                  src={caution}
                  alt=""
                  className={`mt-1 ${styles.caution}`}
                />
                <span className="ps-1">
                  Exchange with same item is not available right now as it is
                  out of stock.{" "}
                </span>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default TypeCard;
