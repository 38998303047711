import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import InfiniteScroll from "src/components/InfiniteScroll";
import Loader from "src/components/Loader";
import SearchBar from "src/components/SearchBar/SearchBar";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import useDebounce from "src/hooks/useDebounce";
import Pagination from "src/routes/TicketList/children/Pagination/Pagination";
import { AddCannedResponseParams } from "src/services/LiveChat/Settings/cannedResponses/addCannedResponse";
import {
  addCannedResponse,
  fetchAllCannedResponse,
  resetCannedResponse,
  setCannedResponseSearchValue,
  setCurrentPage,
} from "src/store/slices/liveChatSetting/cannedResponse/cannedResponse.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import styles from "./CannedResponse.module.scss";
import AllResponseEdit from "./children/AllResponseEdit/AllResponseEdit";
import CannedResponseForm from "./children/CannedResponseForm/CannedResponseForm";

const CannedResponse = () => {
  const { integrationId } = useParams();
  const pageIntegrationId = integrationId
    ? decodeURIComponent(integrationId)
    : "";

  const dispatch = useAppDispatch();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const debouncedSearchTerm = useDebounce(searchTerm, 2000);
  const inputSearchRef: any = useRef(null);
  const {
    cannedResponses,
    cannedResponseIdList,
    metaData,
    cannedResponseAjaxStatus,
  } = useAppSelector((state) => state.cannedResponses);

  const currentState = useRef({
    cannedResponseAjaxStatus,
    cannedResponses,
    cannedResponseIdList,
    metaData,
  });

  useEffect(() => {
    currentState.current = {
      cannedResponseAjaxStatus,
      cannedResponses,
      cannedResponseIdList,
      metaData,
    };
  }, [
    cannedResponseAjaxStatus,
    cannedResponses,
    cannedResponseIdList,
    metaData,
  ]);

  useEffect(() => {
    dispatch(resetCannedResponse());
  }, []);

  /* method to handle infinite scroll */
  const handleInfiniteScroll = useCallback(() => {
    // console.log("loadMore");
    if (
      currentState.current.metaData.total !== null &&
      currentState.current.cannedResponseAjaxStatus === "fulfilled"
    ) {
      if (currentState.current.metaData.total > 0) {
        dispatch(fetchAllCannedResponse(pageIntegrationId));
      }
    }
  }, [metaData.total]);

  useEffect(() => {
    handleSearch();
    if (inputSearchRef.current) {
      inputSearchRef.current.blur();
    }
  }, [debouncedSearchTerm]);

  const handleSearch = () => {
    dispatch(resetCannedResponse());
    dispatch(setCannedResponseSearchValue({ searchValue: searchTerm }));
    dispatch(fetchAllCannedResponse(pageIntegrationId));
  };

  const currentPage = useAppSelector((state) => state.cannedResponses.cannedResponseCurrentPage);
  const totalPages = useAppSelector((state) => state.cannedResponses.cannedResponseTotalPages);

  const dispatchCurrentPage = useCallback((currentPage: any) => {
    dispatch(setCurrentPage(currentPage));
    dispatch(fetchAllCannedResponse(pageIntegrationId));
  }, []);

  return (
    <div className={`${styles.CannedResponse}`}>
      <h2 className={`mb-0 ${styles.cannedResHead}`}>Canned Responses</h2>
      <span className={`d-inline-block pb-3 ${styles.cannedResSub}`}>
        Canned responses are pre-made messages you can recall easily during
        chat.
      </span>
      <div className="mt-3">
        <CannedResponseForm />
      </div>
      <div className={`mt-5`}>
        <h2 className={`mb-0 ${styles.cannedResHead}`}>All Responses</h2>
        <span className={`${styles.cannedResSub}`}>
          Canned responses are pre-made messages you can recall easily during
          chat.
        </span>
        <div className="mt-4">
          <SearchBar
            className={`${styles.search}`}
            inputClassName={`${styles.input}`}
            ref={inputSearchRef}
            value={searchTerm}
            onChange={(e: any) => setSearchTerm(e.target.value)}
            placeholder={`Search shortcut or responses`}
          />

          {/* <InfiniteScroll
            loadMore={handleInfiniteScroll}
            loader={
              cannedResponseAjaxStatus === "pending" &&
                Object.keys(cannedResponseIdList).length === 0 ? (
                <div className={`d-flex flex-column m-auto ${styles.loading}`}>
                  <Loader />
                  <span className="text-center">
                    Fetching canned responses...
                  </span>
                </div>
              ) : undefined
            }
            initialDataLoaded={false}
            errorMessage={
              metaData.total === null &&
                (cannedResponseAjaxStatus === "rejected" ||
                  cannedResponseAjaxStatus === "fulfilled") ? (
                <div className="text-center d-none mt-5">
                  <h5 className="py-1">Canned Response Empty</h5>
                </div>
              ) : undefined
            }
            hasMore={
              cannedResponseAjaxStatus === "rejected"
                ? false
                : metaData.total === null
                  ? false
                  : cannedResponseIdList.length < metaData.total
            }
          >
            {cannedResponseIdList.map((cannedResponseId, index) => {
              const cannedResponseData = cannedResponses[cannedResponseId];
              return (
                <AllResponseEdit
                  cannedResponseId={cannedResponseId}
                  shortcutKeyText={cannedResponseData.shortcut}
                  messageText={cannedResponseData.messageText}
                  title={cannedResponseData.title}
                />
              );
              // return <AllResponseEdit cannedResponseData={cannedResponseData} />;
            })}
          </InfiniteScroll> */}
          {/* <AllResponseEdit shortcutKeyText={`Hi`} messageText={`Testing Message Text`} cannedResponseId={"1"} title={`Hello`} /> */}
          {cannedResponseAjaxStatus === "pending" ? (
            <div className={`d-flex flex-column m-auto ${styles.loading}`}>
              <Loader />
            </div>
          ) : cannedResponseIdList.map((cannedResponseId, index) => {
            const cannedResponseData = cannedResponses[cannedResponseId];
            return (
              <AllResponseEdit
                cannedResponseId={cannedResponseId}
                shortcutKeyText={cannedResponseData.shortcut}
                messageText={cannedResponseData.messageText}
                title={cannedResponseData.title}
              />
            );
            // return <AllResponseEdit cannedResponseData={cannedResponseData} />;
          })}
          <div className="mt-3">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              setCurrentPage={dispatchCurrentPage}
            />
          </div>
          {/* <AllResponseEdit shortcutKeyText={`Hi`} messageText={`Hi there How can I help you today ?`} cannedResponseId={"1"} title={`Hello`} /> */}
          {/* <AllResponseEdit shortcutText={`Hi`} message={`Hi there How can I help you today ?`} /> */}
          {/* <AllResponseEdit shortcutText= {`Transfer`} message={`Let me forward you to my colleague who will be able to help.`} /> */}
        </div>
      </div>
    </div>
  );
};
export default CannedResponse;
