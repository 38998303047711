import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { fetchGetEmailIntegrationById } from "src/store/slices/emailIntegration/emailIntegration.thunks";
import { actions } from "src/store/slices/emailIntegration/emailIntegration.slices";

const useConnectEmailProcess = ({
  integrationId,
  navigateCallBack,
}: {
  integrationId?: string;
  navigateCallBack?: () => void;
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { emailLoading: loader, selectedUser } = useAppSelector(
    (state) => state.emailIntegration,
  );

  /**
   * Effect for updating email integration data
   */
  useEffect(() => {
    // If already have the integration
    if (integrationId) {
      // Get Added Email data
      dispatch(
        fetchGetEmailIntegrationById({
          emailIntegrationId: Number(integrationId),
          // Callback for redirecting user
          callback: (status) => {
            if (status === undefined) {
              if (navigateCallBack) {
                navigateCallBack();
              } else {
                navigate(`/settings/integrations/email/connectEmail`);
              }
            }
          },
        }),
      );
    }
    return () => {
      if (integrationId) {
        // Reset loader state
        dispatch(actions.setverifyEmailLoadingIdle());
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrationId, navigateCallBack]);

  return { loader, selectedUser, navigate };
};

export default useConnectEmailProcess;
