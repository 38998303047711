import { useMemo, useState } from "react";
import { SegmentColumn } from "src/services/CustomerSegments/getSegmentColumns.service";
import { getTimeFromNow } from "src/utils/dateLibrary";
import { useSegmentView } from "../../hooks/useSegmentView";
import styles from "./TableRow.module.scss";

const TableRow = ({ id }: { id: string }) => {
  const { segmentView } = useSegmentView();

  const segmentValue = useMemo(() => {
    return segmentView.segmentValues[id];
  }, [segmentView, id]);

  const [hover, setHover] = useState<boolean>(false);

  const columns = useMemo(() => {
    const columns: SegmentColumn[] = [];
    segmentView.allColumnKeys.forEach((key) => {
      const column = segmentView.allColumns[key];
      if (column?.selected || column?.required) {
        columns.push(column);
      }
    });
    return columns;
  }, [segmentView]);

  return (
    <div
      className={` mb-2 position-relative ${styles.tableHeader}`}
      onMouseOver={() => {
        setHover(true);
      }}
      onMouseOut={() => {
        setHover(false);
      }}
    >
      <div className={`${styles.hoverBar}`}></div>
      {columns.map((column, index) => {
        const segmentColValue = segmentValue[column.key];
        const isKnownType =
          typeof segmentColValue === "number" ||
          (typeof segmentColValue === "string" &&
            segmentColValue.trim() !== "") ||
          typeof segmentColValue === "boolean";

        return (
          <div
            className={`${styles.headerElement} ${
              hover ? styles.hoverClass : ""
            } ${index === 0 ? styles.firstEle : ""} ${
              index === columns.length - 1 ? styles.lastEle : ""
            }`}
          >
            <div className={`pe-2 ${styles.tableText}`}>
              <span className={`${!isKnownType ? styles.clock : ""}`}>
                {isKnownType
                  ? column.key === "lastSeen"
                    ? getTimeFromNow({
                        date: new Date(segmentValue[column.key]),
                        showJustNow: false,
                        need: "both",
                      })
                    : `${segmentValue[column.key]}`
                  : "Unknown"}
              </span>
            </div>
          </div>
        );
      })}
      {/* <div className={`${styles.headerElement}`}>
        <div className={`${styles.tableText}`}>
          <span>
            <i className="fa-regular fa-building"></i>
          </span>
          <span className="ps-1">{name} </span>
        </div>
      </div> */}
    </div>
  );
};

export default TableRow;
