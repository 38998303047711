/**
 * This file defines a React component representing the main content area for the active sources
 * in the ChatBot interface. It includes different tabs for external sources, knowledge base articles,
 * custom Q&A, and agent suggestions. It also includes a sidebar for managing source settings.
 * 
 * @author @navjyot-busibud
 * @author @Anubhav-busibud
 */
import ExternalActiveSource from "./Children/External/ExternalActiveSource";
import SourceSidebar from "./Children/ImportModal/Children/SourceSidebar/SourceSidebar";
import { useParams } from "react-router-dom";
import KBArticleSource from "./Children/KBArticleSource/KBArticleSource";
import CustomQA from "./Children/CustomQA/CustomQA";
import AgentSuggestion from "./Children/AgentSuggestion/AgentSuggestion";
import { useState } from "react";
const ActiveSource = () => {
  const { subTab } = useParams();
  const [showSettingsSubSB, setShowSettingsSubSB] = useState<boolean>(true);

  return (
    <>
      <div className="d-flex align-items-start w-100">
        <div>
          <SourceSidebar
            setShowSettingsSubSB={setShowSettingsSubSB}
            showSettingsSubSB={showSettingsSubSB}
          />
        </div>
        {subTab === "external" && <ExternalActiveSource showSideBar={!showSettingsSubSB} />}
        {subTab === "kbArticles" && <KBArticleSource />}
        {subTab === "customQa" && <CustomQA />}
        {subTab === "agentSuggestion" && <AgentSuggestion />}
      </div>
    </>
  );
};

export default ActiveSource;
