import {
  createAsyncThunk,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { AJAXSTATUS } from "src/globals/constants";
import { ITimeZoneListRes } from "src/services/Settings/Timezone/fetchTimeZoneList";
import { CurrentUser } from "src/services/Settings/Users/getCurrentUser";
import { UserRoleList } from "src/services/Settings/Users/getUserRoles";
import profileSettingsExtraReducers from "./profileSettings.extraReducers";
import profileSettingsReducers from "./profileSettings.reducers";
import profileSettingsThunks from "./profileSettings.thunks";

export const fetchUser = createAsyncThunk(
  "profileSettings/fetchUser",
  profileSettingsThunks.fetchUserThunk
);
export const updateUser = createAsyncThunk(
  "profileSettings/updateUser",
  profileSettingsThunks.updateUserThunk
);
export const updateUserProfilePic = createAsyncThunk(
  "profileSettings/updateUserProfilePic",
  profileSettingsThunks.updateUserProfilePicThunk
);
export const deleteUserProfilePic = createAsyncThunk(
  "profileSettings/deleteUserProfilePic",
  profileSettingsThunks.deleteUserProfilePicThunk
);

export const fetchTimeZoneListData = createAsyncThunk(
  "profileSettings/fetchTimeZoneListData",
  profileSettingsThunks.fetchTimeZoneListDataThunk
);

export interface IProfileSettings {
  userData: null | CurrentUser;
  userDataAjaxStatus: AJAXSTATUS;
  profilePicAjaxStatus: AJAXSTATUS;
  fetchTimezoneListAjaxStatus: AJAXSTATUS;
  timezoneListData: ITimeZoneListRes;
  fetchTimeZoneListLimit: number;
  timeZoneListSearchTerm: string;
  userRoles: UserRoleList;
}

export const initialState: IProfileSettings = {
  userData: null,
  userDataAjaxStatus: "pending",
  profilePicAjaxStatus: "idle",
  fetchTimezoneListAjaxStatus: "idle",
  timeZoneListSearchTerm: "",
  fetchTimeZoneListLimit: 10,
  timezoneListData: {
    data: [],
    metaData: {
      count: 0,
      total: null
    }
  },
  userRoles: {
    roles: {},
    rolesIds: []
  }
};

export const profileSettingsSlice = createSlice({
  name: "profileSettings",
  initialState,
  reducers: profileSettingsReducers,
  extraReducers: (builder) => {
    builder.addCase(
      fetchUser.fulfilled,
      profileSettingsExtraReducers.fetchUserFulfilled
    );
    builder.addCase(fetchUser.rejected, (state) => {
      state.userDataAjaxStatus = "rejected";
    });
    builder.addCase(fetchUser.pending, (state) => {
      state.userDataAjaxStatus = "pending";
    });

    builder.addCase(
      updateUser.fulfilled,
      profileSettingsExtraReducers.updateUserFulfilled
    );
    builder.addCase(updateUser.rejected, (state) => {
      pushTheToast({
        type: "danger",
        text: "Failed to save changes!",
        position: "top-right",
      });
      state.userDataAjaxStatus = "rejected";
    });
    builder.addCase(updateUser.pending, (state) => {
      state.userDataAjaxStatus = "pending";
    });

    builder.addCase(
      updateUserProfilePic.fulfilled,
      profileSettingsExtraReducers.updateUserProfilePicFulfilled
    );
    builder.addCase(updateUserProfilePic.rejected, (state) => {
      state.profilePicAjaxStatus = "rejected";
    });
    builder.addCase(updateUserProfilePic.pending, (state) => {
      state.profilePicAjaxStatus = "pending";
    });

    builder.addCase(
      deleteUserProfilePic.fulfilled,
      profileSettingsExtraReducers.deleteUserProfilePicFulfilled
    );
    builder.addCase(deleteUserProfilePic.rejected, (state) => {
      state.profilePicAjaxStatus = "rejected";
    });
    builder.addCase(deleteUserProfilePic.pending, (state) => {
      state.profilePicAjaxStatus = "pending";
    });

    builder.addCase(
      fetchTimeZoneListData.fulfilled,
      profileSettingsExtraReducers.fetchTimeZoneListDataFulfilled
    );
    builder.addCase(fetchTimeZoneListData.rejected, (state) => {
      state.fetchTimezoneListAjaxStatus = "rejected";
    });
    builder.addCase(fetchTimeZoneListData.pending, (state) => {
      state.fetchTimezoneListAjaxStatus = "pending";
    });
  }
});

export const { resetProfileSettings } = profileSettingsSlice.actions;

export default profileSettingsSlice.reducer;
