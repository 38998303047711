import { axiosJSON } from "src/globals/axiosEndPoints";

export interface CreateRefund {
  orderId: number;
  refundId: {
    id: string;
  };
}

export interface CreateRefundParams {
  orderId: number;
  shippingAmount?: number;
  reason?: string;
  refundAmount?: number; // optional, can directly refund amount without selecting item
  isNotifyCustomer?: boolean;
  refundItems: Array<{
    itemId: string; // shopify lineItem Id
    quantity: number;
    restock: boolean;
    locationId: string; // shopify location id (warehouse)
  }>;
}

export async function createRefund(params: CreateRefundParams) {
  const { data: res }: any = await axiosJSON.post(
    `/api/shopify/order/refund/create`,
    params
  );

  if (res.error === true) {
    throw new Error(res.message as string);
  }

  return res.data as CreateRefund;
}
