import { createAsyncThunk } from "@reduxjs/toolkit";
import { getValueFromConditionType } from "src/features/ReturnAutoWorkFlow/children/ConfigModals/store/returnModalsSliceHelpers";
import getAllReturnReasonListService from "src/services/ReturnAutoWorkFlow/OldModals/General/getAllReturnReasonList.service";
import getReturnMethodService, {
  ReturnMethodTab,
} from "src/services/ReturnAutoWorkFlow/OldModals/returnMethod/getReturnMethod.service";
import getReturnMethodConditionsService from "src/services/ReturnAutoWorkFlow/OldModals/returnMethod/getReturnMethodConditions.service";
import updateReturnMethodService from "src/services/ReturnAutoWorkFlow/OldModals/returnMethod/updateReturnMethod.service";
import { RootState } from "src/store/store";
import { SelectedConditionType } from "../../../oldReturnModals.types";
import { ReturnModalsThunkPayload } from "../../returnModalsStore";
import { validateReturnMethodState } from "./returnMethod.slice";

export const fetchReturnMethodsThunk = createAsyncThunk(
  "fetchReturnMethods",
  async (payload: { integrationId: string; showReturnReasons: boolean }) => {
    const integrationId = payload.integrationId;

    const [conditionsRes, configRes, returnReasonList] = await Promise.all([
      getReturnMethodConditionsService({ integrationId }),
      getReturnMethodService({ integrationId }),
      getAllReturnReasonListService({ integrationId }), // Adding to get default return reasons for a state variable to check for validations based on moreQuestions
    ]);

    return {
      conditionsRes,
      configRes,
      returnReasons: payload.showReturnReasons,
      returnReasonList: returnReasonList.reasons ?? [],
    };
  },
);

interface UpdateReturnMethodPayload extends ReturnModalsThunkPayload {
  integrationId: string;
  showReturnReasons: boolean;
}

export const updateReturnMethodThunk = createAsyncThunk(
  "updateReturnMethod",
  async (payload: UpdateReturnMethodPayload, { getState }) => {
    const state = getState() as RootState;

    const returnMethods = state.returnMethod.returnMethods;

    const integrationId = payload.integrationId;
    const configTabs = returnMethods.map((returnMethod) => {
      const operatorSymbolMap = state.returnMethod.operatorSymbolMap;

      return {
        configTabId: Number(returnMethod.returnMethodId),
        configTabName: returnMethod.returnMethodName,

        conditions: returnMethod.conditions.map((condition) => {
          const selectedVariable = state.returnMethod.variableNames.find(
            (variable: any) => variable.name === condition.variableName,
          );

          if (selectedVariable) {
            return {
              conditionId: Number(condition.id),
              conditionTypeId: selectedVariable.id,

              conditionValue: getValueFromConditionType(condition.values),
              operator: Object.keys(operatorSymbolMap).find((key) => {
                if (
                  (condition.operator === "are less than" ||
                    condition.operator === "is less than") &&
                  key === "<"
                ) {
                  return true;
                }
                return operatorSymbolMap[key] === condition.operator;
              }),
            } as SelectedConditionType;
          } else {
            return {
              conditionId: Number(condition.id),
              conditionTypeId: -1,
            };
          }
        }),
        selectReturnReasons: returnMethod.selectReturnReason?.map(
          (condition) => {
            const selectedVariable = state.returnMethod.variableNames.find(
              (variable: any) => variable.name === condition.variableName,
            );

            if (selectedVariable) {
              return {
                conditionId: Number(condition.id),
                conditionTypeId: selectedVariable.id,

                conditionValue: getValueFromConditionType(condition.values),
                operator: Object.keys(operatorSymbolMap).find((key) => {
                  if (
                    (condition.operator === "are less than" ||
                      condition.operator === "is less than") &&
                    key === "<"
                  ) {
                    return true;
                  }
                  return operatorSymbolMap[key] === condition.operator;
                }),
              } as SelectedConditionType;
            } else {
              return {
                conditionId: Number(condition.id),
                conditionTypeId: -1,
              };
            }
          },
        ),
        returnMethod: {
          returnType: returnMethod.returnMethodType.value,
          displayMessage: returnMethod.returnMethodType.message,
        },
      } as ReturnMethodTab;
    });

    await updateReturnMethodService({ integrationId, configTabs });

    setTimeout(() => {
      if (payload.callback) {
        payload.callback();
      }
    }, 0);

    const configRes = await getReturnMethodService({ integrationId });

    return {
      configRes,
      returnReasons: payload.showReturnReasons,
    };
  },
);

export const validateReturnMethodConditions = createAsyncThunk(
  "validateReturnMethodConditions",
  async (_: void, { getState, dispatch }) => {
    await dispatch(validateReturnMethodState());

    const state = getState() as RootState;

    let errorCount = 0;
    state.returnMethod.returnMethods.forEach((data) => {
      if (data.errorCount !== 0) {
        errorCount += 1;
      }
    });

    return errorCount;
  },
);
