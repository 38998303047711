import { axiosJSON } from "src/globals/axiosEndPoints";

export interface GetFacebookPageWebhooksPayload {
  pageId: string | number;
  webhooks: Array<{ key: string; enabled: boolean }>;
  enabled?: boolean;
}
export async function updateFacebookPageWebhook(
  payload: GetFacebookPageWebhooksPayload,
) {
  const { data: res } = await axiosJSON.post(
    `/api/setting/integration/facebook/updatePageWebhooks`,
    payload,
  );
  if (res.err) {
    throw "Something went wrong!";
  }

  return res.data;
}
