import { GetConfigModalParams } from "src/features/ReturnAutoWorkFlow/children/ConfigModals/oldReturnModals.types";
import { axiosJSON } from "src/globals/axiosEndPoints";
import { ReturnTypeBasedOnOrderOrItemsTab } from "./getReturnTypeBasedOnOrderOrItems.service";

export type ReturnTypeBasedOnOrderOrItemsUpdateTab = Omit<
  ReturnTypeBasedOnOrderOrItemsTab,
  "returnTypes"
> & {
  returnTypes: {
    exchangeWithSameItems: boolean;
    exchangeWithOtherItems: boolean;
    refundToStoreCredit: boolean;
    refundToOriginalPaymentMethod: boolean;
  };
};

interface Payload extends GetConfigModalParams {
  configTabs: ReturnTypeBasedOnOrderOrItemsUpdateTab[];
}

async function updateReturnOrderOrItemsService(payload: Payload) {
  const { data: res } = await axiosJSON.post(
    "/api/setting/automation/refund/step/11/configuration",
    payload,
  );

  if (res.err || res.error) {
    throw new Error(res.msg ?? res.message ?? "Some Error Occurred.");
  }
}

export default updateReturnOrderOrItemsService;
