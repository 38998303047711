// import { getTicketsApi } from "./getTickets"
import { addTicketTagsApi } from "./addTicketTags";
import {
  prepareGetTicketsQuery,
  prepareInnerTicketQuery,
} from "./prepareGetTicketsQuery";
import { fetchTicketsTagsApi } from "./fetchTicketsTags";
import { createTagApi } from "./createTag";
import { assignTicketsTo } from "./assignTicketsTo";
import { addTicketRemarks } from "./addTicketRemarks";
import { changeBulkTicketStatus } from "./changeBulkTicketStatus";
import { getAllStatuses } from "./getAllStatuses";
import { sendBulkMessage } from "./sendBulkMessage";
import { getMoreColumns } from "./getMoreColumns";
import { fetchFilterColData } from "./fetchFilterColData";
import { getTicketsV2 } from "./getTicketsV2";
import { getDraftStatusesOptions } from "./getDraftStatusesOptions";
import { changeDraftStatus } from "./changeDraftStatus";

const TicketService = {
  // getTicketsApi,
  addTicketTagsApi,
  prepareGetTicketsQuery,
  fetchTicketsTagsApi,
  createTagApi,
  assignTicketsTo,
  addTicketRemarks,
  changeBulkTicketStatus,
  getAllStatuses,
  sendBulkMessage,
  getMoreColumns,
  fetchFilterColData,
  getTicketsV2,
  getDraftStatusesOptions,
  changeDraftStatus,
  prepareInnerTicketQuery,
};

export default TicketService;
