import { useAppSelector } from "src/store/store";
import {
  totalTicketsSelectedSeletector,
  updatedTicketsCountSeletector,
} from "src/store/slices/tickets/tickets.slice";
import BaseSuccessModalBody from "src/components/BulkActionComponents/commons/BaseSuccessModalBody/BaseSuccessModalBody";
import { BsExclamationDiamondFill } from "react-icons/bs";
import styles from "./AddSendMessageSuccess.module.scss";
import { AJAXSTATUS } from "src/globals/constants";
import IntegrationFailureText from "src/components/IntegrationFailureModal/children/IntegrationFailureText/IntegrationFailureText";
const AddSendMessageSuccess = ({
  bulkSendMessageState,
}: {
  bulkSendMessageState: AJAXSTATUS;
}) => {
  const { integrationFailureTickets, integrationFailureTicketsCount } =
    useAppSelector((state) => state.ticketSendMessage);
  const updatedTicketsCount = useAppSelector(updatedTicketsCountSeletector);

  return (
    <>
      {bulkSendMessageState === "fulfilled" && (
        <BaseSuccessModalBody id="successMsg">
          Sent message in {updatedTicketsCount} ticket(s)!
        </BaseSuccessModalBody>
      )}
      {bulkSendMessageState === "rejected" && integrationFailureTickets && (
        <div className="text-center">
          <span className={`pb-2 ${styles.exclamation}`}>
            <BsExclamationDiamondFill />
          </span>
          <p className={`text-center ${styles.failedText}`}>
            {`Failed to send in ${integrationFailureTicketsCount} tickets from the following emails.`}
          </p>

          {integrationFailureTickets &&
            integrationFailureTickets.map((ticket, index) => {
              return (
                <div key={index}>
                  <p className={`mb-1 ${styles.email}`}>
                    {ticket.failureMetaData.brand?.email}
                  </p>
                </div>
              );
            })}
          <IntegrationFailureText
            integrationFailure={integrationFailureTickets[0].failureMetaData}
          />
        </div>
      )}
    </>
  );
};

export default AddSendMessageSuccess;
