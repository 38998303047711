import { Channel } from "pusher-js";

export type ChatAssignedEventCB = (res: any) => void;

export const chatOnlineStatusUpdatedEvent = (
  channel: Channel,
  callback: ChatAssignedEventCB
) => {
  channel.bind("live_chat_update_online_status", callback);
};

export const chatOnlineStatusUpdatedEventUnbind = (
  channel: Channel,
) => {
  channel.unbind("live_chat_update_online_status");
};
