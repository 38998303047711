import styles from "../../AgentOffline.module.scss";
import { useEffect, useRef, useState } from "react";
import textLogo from "src/assets/images/text.png";
import { fetchTicketChatFormData } from "src/store/slices/liveChatSetting/Forms/ticketChatForm/ticketChatFormSetting.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { v4 as uuidv4 } from "uuid";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import {
  IUpdateTicketChatFormDataParam,
  updateTicketChatFormData,
} from "src/services/LiveChat/Settings/ticketChatForm/updateTicketChatFormData";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import MessageBox from "../../../../../AskMail/OfflineMode/Children/MessageBox";
import { useParams } from "react-router-dom";
import ElementText from "../../../../../FormElements/Text/ElementText";
import ElementMultiChoiceList from "../../../../../FormElements/MultiChoiceList/ElementMultiChoiceList";
import ElementDropDown from "../../../../../FormElements/DropDown/ElementDropDown";
import { IChatFormElementOptionList } from "src/services/LiveChat/Settings/ticketChatForm/getTicketChatFormData";
import { AJAXSTATUS } from "src/globals/constants";
import { IUpdateTicketChatFormDataParamV3 } from "src/services/LiveChat/Settings/ticketChatForm/updateTicketChatFormDataV3";
import SelectBox from "../../../SelectBox/SelectBox";
import { ITicketChatFormDataV3 } from "src/store/slices/liveChatSetting/Forms/chatForm.declarations";
import {
  AddFormNewElement,
  removeFormElement,
  setBrandEmailData,
  setCustomEmailData,
  setFormElements,
  setShowOutsideBusinessForm,
  setShowTicketForm,
  setTicketFormConfirmationTextData,
  updateFormElement,
} from "src/store/slices/liveChatSetting/Forms/askForEmail/askForEmailSetting.slice";
import { Dropdown } from "react-bootstrap";
import style from "./TicketForm.module.scss";
interface Props {
  // ticketFormId: string | number;
  // setTicketFormId: any;
  // ticketChatFormData: ITicketChatFormDataV3;
  // ticketChatFormDataAjaxStatus: AJAXSTATUS;
  // // formElements: any;
  // elementOptions: any;
  // enabledBrandEmails: any;
  // brandEmails: any;
  // enabledCustomEmail: any;
  // customEmail: any;
  // confirmationText: any;
  // setFormElements: any;
  // setElementOptions: any;
  // setBrandEmailsEnabled: any;
  // setBrandEmails: any;
  // setEnabledCustomEmail: any;
  // setCustomEmail: any;
  // setConfirmationText: any;
  // handleElementDelete: (element: any) => void;
  type: "withinBusinessHours" | "outsideBusinessHours";
}
const TicketForm = ({
  // formElements,
  // enabledBrandEmails,
  // brandEmails,
  // enabledCustomEmail,
  // customEmail,
  // confirmationText,
  // setFormElements,
  // setBrandEmailsEnabled,
  // setConfirmationText,
  type = "withinBusinessHours",
}: Props) => {
  const dispatch = useAppDispatch();

  const {
    elements: formElements,
    brandEmails,
    customEmails,
  } = useAppSelector((state) =>
    type == "withinBusinessHours"
      ? state.askForEmailSetting.askForEmailData.whenAgentOffline
          .withinBusinessHours.ticketChatFormData
      : state.askForEmailSetting.askForEmailData.whenAgentOffline
          .outsideBusinessHours.ticketChatFormData
  );

  const confirmationText = useAppSelector((state) =>
    type == "withinBusinessHours"
      ? state.askForEmailSetting.askForEmailData.whenAgentOffline
          .withinBusinessHours.ticketFormConfirmationText
      : state.askForEmailSetting.askForEmailData.whenAgentOffline
          .outsideBusinessHours.ticketFormConfirmationText
  );

  const { customEmailsErr, ticketConfirmationTextErr, brandEmailsErr } =
    useAppSelector((state) =>
      type == "withinBusinessHours"
        ? state.askForEmailSetting.askForEmailData.whenAgentOffline
            .withinBusinessHours.validationErrors
        : state.askForEmailSetting.askForEmailData.whenAgentOffline
            .outsideBusinessHours.validationErrors
    );

  const { showTicketForm, showOutsideBusinessHours } = useAppSelector(
    (state) => state.askForEmailSetting
  );

  const { integrationId } = useParams();
  const pageIntegrationId = integrationId
    ? decodeURIComponent(integrationId)
    : "";

  const [elementOptions, setElementOptions] = useState<any>([]);
  const [customEmailEnabled, setCustomEmailEnabled] = useState(
    brandEmails.enabled
  );
  const [brandEmailEnabled, setBrandEmailEnabled] = useState(
    brandEmails.enabled
  );
  const [ticketFormConfirmationText, setTicketFormConfirmationText] =
    useState(confirmationText);
  const elementTypesList: {
    [key: string]: { component: any };
  } = {
    email: { component: ElementText },
    text: { component: ElementText },
    longText: { component: ElementText },
    choiceList: { component: ElementMultiChoiceList },
    multipleChoiceList: { component: ElementMultiChoiceList },
    information: { component: ElementText },
    dropDown: { component: ElementDropDown },
  };

  const allBrands = useAppSelector((state) => state.brandSettings.brands);

  const allOptions: any = []; // = Object.values(allBrands).map((brand) => {
  //   return {
  //     id: brand.id,
  //     label: brand.name,
  //     price: brand.email,
  //     value: brand.email,
  //   };
  // });
  const initialSelecteOptions: any[] = [];

  brandEmails?.email?.forEach((email: string) => {
    initialSelecteOptions.push({
      id: "",
      label: "",
      price: "",
      value: email,
    });
  });

  const formElementOptions = useAppSelector(
    (state) =>
      state.askForEmailSetting.askForEmailData.whenAgentOffline
        .formElementOptions
  );

  useEffect(() => {
    dispatch(setCustomEmailData({ enabled: customEmailEnabled, type }));
    dispatch(setBrandEmailData({ enabled: brandEmailEnabled, type }));
  }, [customEmailEnabled, brandEmailEnabled]);

  useEffect(() => {
    if (formElementOptions !== null && formElements !== null) {
      const options = formElementOptions.formElementOptionIds.map(
        (optionId, index) => {
          let elementOption = formElementOptions.formElementOptions[optionId];
          let option = {
            ...elementOption,
            isAlreadyExists: false,
          };
          let element = formElements.filter(
            (element: any) => optionId === element.elementId
          );
          if (element.length === 0 || elementOption.canHaveMultiple === true) {
            option.isAlreadyExists = false;
          } else {
            option.isAlreadyExists = true;
          }

          return option;
        }
      );
      setElementOptions(options);
    }
  }, [formElements, formElementOptions]);

  const showWidgetTicketForm = () => {
    if (!showTicketForm) {
      dispatch(setShowTicketForm({ value: true }));
    }

    if (type == "outsideBusinessHours") {
      if (showOutsideBusinessHours === false) {
        dispatch(setShowOutsideBusinessForm({ value: true }));
      }
    } else {
      if (showOutsideBusinessHours === true) {
        dispatch(setShowOutsideBusinessForm({ value: false }));
      }
    }
  };

  const handleElementChange = (element: any) => {
    if (element === null) {
      return;
    }
    dispatch(updateFormElement({ element, type }));
    showWidgetTicketForm();
  };

  const handleNewElement = (optionId: any) => {
    dispatch(AddFormNewElement({ optionId: optionId, type }));
    showWidgetTicketForm();

    // if (optionId !== null) {
    //   let elementOption = elementOptions.filter(
    //     (element: any) => optionId === element.elementId
    //   );

    //   if (elementOption.length === 0) {
    //     return;
    //   }

    //   elementOption = elementOption[0];

    //   if (
    //     elementOption.canHaveMultiple === false &&
    //     elementOption.isAlreadyExists === true
    //   ) {
    //     return;
    //   }

    //   if (
    //     ["choiceList", "multipleChoiceList", "dropDown"].includes(
    //       elementOption.elementType
    //     )
    //   ) {
    //     let newElement = {
    //       elementId: elementOption.elementId,
    //       elementType: elementOption.elementType,
    //       elementKey: elementOption.elmentType + "_" + uuidv4(),
    //       elementLabel: elementOption.elementLabel,
    //       elementValue: "Question",
    //       elementOptions: ["Answer 1", "Answer 2"],
    //       isRequired: false,
    //       isDefault: false,
    //       uniqueId: uuidv4(),
    //       isNewlyAdded: true,
    //     };

    //     const stateFormElements = [...formElements, newElement];
    //     setFormElements(stateFormElements);
    //   } else {
    //     let newElement = {
    //       elementId: elementOption.elementId,
    //       elementType: elementOption.elementType,
    //       elementKey: elementOption.elementType + "_" + uuidv4(),
    //       elementLabel: elementOption.elementLabel,
    //       elementValue: "Question",
    //       elementOptions: [],
    //       isRequired: false,
    //       isDefault: false,
    //       uniqueId: uuidv4(),
    //       isNewlyAdded: true,
    //     };
    //     const stateFormElements = [...formElements, newElement];
    //     setFormElements(stateFormElements);
    //   }
    // }
  };

  // const handleSubmit = () => {
  //   if (ticketChatFormDataAjaxStatus !== "pending" && formElements !== null) {
  //     if (formElements.length === 0) {
  //       pushTheToast({
  //         type: "warning",
  //         text: "Atleast one element is required",
  //         position: "top-right",
  //       });
  //     }

  //     let payload: IUpdateTicketChatFormDataParamV3 = {
  //       integrationId: pageIntegrationId,
  //     };

  //     payload.brandEmails = { enabled: enabledCustomEmail, email: brandEmails };

  //     if (enabledCustomEmail === true) {
  //       //validate input
  //       if (customEmail.trim() === "") {
  //         pushTheToast({
  //           type: "warning",
  //           text: "Custom Email Cannot be empty!",
  //           position: "top-right",
  //         });
  //         return;
  //       }
  //     }

  //     const emailList = customEmail.split(",");
  //     const emailValidationResult = isEmailsValid(emailList);

  //     if (!emailValidationResult) {
  //       pushTheToast({
  //         type: "warning",
  //         text: "Custom Email is invalid!",
  //         position: "top-right",
  //       });
  //       return;
  //     }

  //     payload.customEmails = {
  //       enabled: enabledCustomEmail,
  //       email: customEmail,
  //     };

  //     const emptyValueElements = formElements.filter(
  //       (element: any) => element.elementValue.length === 0
  //     );

  //     if (emptyValueElements.length > 0) {
  //       pushTheToast({
  //         type: "warning",
  //         text: "Please fill all the fields",
  //         position: "top-right",
  //       });
  //     }

  //     let payloadElements = formElements.map((element: any) => {
  //       return {
  //         elementId: element.elementId,
  //         elementKey: element.elementKey,
  //         elementValue: element.elementValue,
  //         elementOptions: element.elementOptions,
  //         isRequired: element.isRequired,
  //         isDefault: element.isDefault,
  //         isNewlyAdded: element.isNewlyAdded,
  //       };
  //     });

  //     payload.elements = payloadElements;

  //     updateTicketChatFormData(payload)
  //       .then((res) => {
  //         pushTheToast({
  //           type: "success",
  //           text: "Successfully changed",
  //           position: "top-right",
  //         });
  //         // dispatch(fetchTicketChatFormData(pageIntegrationId));
  //       })
  //       .catch((err) => {
  //         pushTheToast({
  //           type: "danger",
  //           text: "Something went wrong!",
  //           position: "top-right",
  //         });
  //       });
  //     // setShowSaveLoader(false);
  //   }
  // };

  const handleElementDelete = (element: any) => {
    dispatch(removeFormElement({ element, type }));
    showWidgetTicketForm();
  };

  useEffect(() => {
    dispatch(
      setTicketFormConfirmationTextData({
        value: ticketFormConfirmationText,
        type,
      })
    );
  }, [ticketFormConfirmationText]);

  return (
    <>
      {/* Show below ui for radio check of send ticket form  */}
      <div className="mt-2">
        <h4 className={`${styles.ticketFormHead}`}>
          Send ticket form messages to
        </h4>
        <div className="d-flex align-items-center">
          <div className="form-check">
            <input
              className={`form-check-input ${style.checkBox}`}
              type="checkbox"
              value=""
              id="flexCheckDefault"
              checked={brandEmailEnabled}
              onChange={(e) => setBrandEmailEnabled(!brandEmailEnabled)}
            />
          </div>
          <div className={`${styles.selectOpt}`}>
            <SelectBox
              // allOptions={allOptions}
              updateSelectedOption={(selectedOptions: any) => {
                dispatch(
                  setBrandEmailData({
                    email: selectedOptions.map((option: any) => option.value),
                    type,
                  })
                );
              }}
              intialSelectedOptions={initialSelecteOptions}
              showWarning={brandEmailsErr}
            />
            {brandEmailsErr == true && (
              <span className={`${styles.errorMsg}`}>
                {" "}
                *Add atleast 1 e-mail for this form to work
              </span>
            )}
          </div>
        </div>
        <div className="form-check mt-2">
          <input
            className={`form-check-input ${style.checkBox}`}
            type="checkbox"
            value=""
            id="customMail"
            required
            checked={customEmailEnabled}
            onChange={(e) => {
              setCustomEmailEnabled(!customEmailEnabled);
            }}
          />
          <div>
            <label className={`form-check-label ${styles.mailLable}`}>
              Custom email address
            </label>
            <input
              type="email"
              className={`form-control mt-1 ${style.inputBox}`}
              id="email"
              placeholder="youremail@company.com"
              value={customEmails.email}
              required
              onChange={(e) =>
                dispatch(setCustomEmailData({ email: e.target.value, type }))
              }
            />
            <span className={`d-inline-block ${styles.mailSpan}`}>
              You can separate many addresses with a comma.
            </span>
          </div>
        </div>
      </div>
      <div className="mt-3 mb-3">
        <h3 className={`mb-1 ${styles.customForm}`}>
          Customize your ticket form
        </h3>
        <span className={`${styles.mailSpan}`}>
          Your customers can leave messages when you're not available. These
          messages are saved as tickets.
        </span>
      </div>
      <DragDropContext
        onDragEnd={(param: any) => {
          const srcI = param.source.index;
          const desI = param.destination?.index;
          if (desI !== undefined && desI !== null) {
            const updatedList = [...formElements];
            updatedList.splice(desI, 0, updatedList.splice(srcI, 1)[0]);
            dispatch(setFormElements({ elementList: updatedList, type }));
          }
        }}
      >
        <Droppable droppableId="droppable-1">
          {(provided: any, _: any) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className={`${styles.overIssue}`}
            >
              {formElements.map((element: any, i: number) => {
                const ShowComp =
                  elementTypesList[element.elementType].component;
                const hideDelete = formElements.length === 1;
                return (
                  <Draggable
                    key={element.uniqueId}
                    draggableId={"draggable-" + element.uniqueId}
                    index={i}
                  >
                    {(provided: any, snapshot: any) => (
                      <ShowComp
                        customProvided={provided}
                        element={element}
                        handleElementDelete={handleElementDelete}
                        handleElementChange={handleElementChange}
                        hideDelete={hideDelete}
                      />
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div className={`d-flex pt-2`}>
        <Dropdown bsPrefix="dropdown">
          <Dropdown.Toggle
            as="div"
            id="dropdown-basic"
            bsPrefix={`dropdown-toggle cursor-pointer px-2 ${styles.addBox}`}
          >
            <span>
              {" "}
              <i className="fa-solid fa-plus"></i>
            </span>
          </Dropdown.Toggle>

          <Dropdown.Menu bsPrefix={`dropdown-menu ${styles.dropBox}`}>
            {elementOptions.map((option: any) => {
              return (
                <li key={uuidv4()}>
                  <span
                    onClick={(e) => {
                      handleNewElement(option.elementId);
                    }}
                    className={
                      option.isAlreadyExists === true
                        ? `dropdown-item ${styles.dropItem}`
                        : `dropdown-item ${styles.dropItem2}`
                    }
                  >
                    <img
                      src={textLogo}
                      className={`${styles.textLogo}`}
                      alt=""
                    />
                    {option.elementLabel}
                  </span>
                </li>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
        <div className="d-flex align-items-center">
          <span className={`ps-2 ${styles.addElement}`}>Add Element</span>
        </div>
      </div>

      <div className="mt-2">
        <MessageBox
          msgHead={`Confirmation text`}
          messageText={ticketFormConfirmationText}
          setMessageText={(value: string) => {
            setTicketFormConfirmationText(value);
          }}
          showWarning={ticketConfirmationTextErr}
        />
      </div>
    </>
  );
};
export default TicketForm;
