import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";

/**
 * Interface representing a question with an ID and a value type.
 */
export interface Question {
  /** The unique identifier for the question */
  questionId: string;

  /** The action to take with the question value, either replace or append */
  questionValue: string; // "replace" | "append"
}

/**
 * Interface representing the parameters required for updating
 * question values in step 1 of the upload CSV modal.
 */
export interface UpdateQuestionsParams {
  /** The unique identifier for the integration */
  integrationId: string;

  /** An array of questions to be updated */
  questions: Question[];
}

/**
 * Updates question values in step 1 of the upload CSV modal.
 *
 * Sends a request to update the questions associated with a particular integration.
 *
 * @param {UpdateQuestionsParams} params - The parameters required for updating questions.
 * @returns {Promise<any>} - A promise that resolves to the response data.
 * @throws Will throw an error if the API response contains an error.
 */
async function csvUpdateQuestions(params: UpdateQuestionsParams): Promise<any> {
  const apiEndPoint = IS_TEST_ACTIVE
    ? `${process.env.REACT_APP_TEST_SITE_URL}/csv/updateQuestions`
    : `/api/setting/automation/refund/product/csv/updateQuestions`;

  const { data: res } = await axiosJSON.post(apiEndPoint, params);

  if (res.err || res.error) {
    throw new Error(res.msg ?? res.message ?? "Some Error Occurred.");
  }

  return res.data;
}

export default csvUpdateQuestions;
