/**
 * This file defines a React component representing a bot row
 * It includes functionalities for editing, deleting, and navigating to the settings page of a bot profile.
 *
 * @author @yuvaraj-busibud
 * @author @navjyot-busibud
 * @author @Anubhav-busibud
 */
import React, { useState } from "react";
import styles from "./BotBox.module.scss";
import UserAvatar from "src/components/UserAvatar";
import {
  BotProfile,
  BotProfileResponse,
  GetAllBotProfilesResponse,
} from "src/services/Bot/BotProfiles/getAllBotProfiles.service";
import { useNavigate } from "react-router-dom";
import AxiosImg from "src/components/AxiosImg";
import { Modal, OverlayTrigger, Popover } from "react-bootstrap";
import Lottie from "lottie-light-react";
import liveDot from "src/assets/images/liveDot.json";
import DeleteBot from "./ChatBot/Children/DeleteBot/DeleteBot";

interface Props {
  botProfile: BotProfileResponse;
  handleDelete: (botProfile: BotProfileResponse) => void;
  // setSelectedBotId: (id: string | number) => void;
  // setShowSettings: (value: boolean) => void;
}
const BotBox = ({ botProfile, handleDelete }: Props) => {
  const { name, channels, status, brandData, eligibleUsers } = botProfile;
  const [isHovered, setIsHovered] = useState(false);
  // Filter out empty channels and eligible users
  const filterdChannels = channels?.filter((channel: any) => channel !== "");
  const filterdEligibleUsers = eligibleUsers?.filter(
    (user: any) => user !== "",
  );

  const navigate = useNavigate();

  /**
   * Navigates to the settings page of the selected bot profile.
   * @param {string | number} id - The ID of the bot profile.
   */
  function handleEditButton(id: string | number) {
    // setSelectedBotId(id);
    // setShowSettings(true);
    navigate(`/bot/botProfiles/${id}/settings`);
  }

  /**
   * Handles redirection to the test or preview URL based on the bot status.
   */
  const handleTestAndPreviewRedirect = () => {
    if (status === "draft") {
      if (botProfile.botTestURL) {
        window.open(botProfile.botTestURL, "_blank");
      }
    } else {
      if (botProfile.botPreviewURL) {
        window.open(botProfile.botPreviewURL, "_blank");
      }
    }
  };

  const popover = (
    <Popover>
      <Popover.Body className={`${styles.newClass}`}>
        {filterdChannels?.length > 0 &&
          filterdChannels.slice(3, filterdChannels.length).map((channel) => (
            <span className={`mb-1 ms-0 ${styles.channels}`}>
              <i className="fa-solid fa-earth-americas pe-1"></i> {channel.name}
            </span>
          ))}
      </Popover.Body>
    </Popover>
  );

  return (
    <div className={`mb-2 ${styles.wrapper} w-100`}>
      <div className="d-flex flex-column flex-md-row justify-content-between align-items-md-center mb-1">
        <div className={`d-flex align-items-center`}>
          <p className="mb-0 d-flex">
            <span>
              <i className="fa-solid fa-ellipsis-vertical"></i>
            </span>
            <span>
              <i className="fa-solid fa-ellipsis-vertical"></i>
            </span>
          </p>
          <span className={`${styles.botName} ms-3 `}>{name}</span>
          {status === "draft" ? (
            <>
              <div className="ps-2 pe-2">
                <span className={`${styles.draft}`}>Draft</span>
              </div>
            </>
          ) : brandData ? (
            <div className="px-2 d-flex align-items-center">
              {/* <div className={`${styles.greenDot}`}></div> */}
              <span
                style={{
                  height: 20,
                  width: 20,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Lottie animationData={liveDot}></Lottie>
              </span>
              <p className="mb-0 pe-2 d-flex align-items-center">
                <span className={`${styles.liveText}`}>Live on</span>
                <div className="px-1">
                  {brandData?.imgURL && brandData.imgURL.trim() !== "" ? (
                    <AxiosImg
                      url={brandData.imgURL}
                      className={`rounded-circle`}
                      style={{ width: "20px", height: "20px" }}
                    />
                  ) : (
                    <UserAvatar
                      name={brandData.name ?? "NA"}
                      size={20}
                    />
                  )}
                </div>

                <span className={`${styles.liveText}`}>{brandData.name}</span>
              </p>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="d-flex flex-column flex-md-row align-items-md-center mt-1 mt-md-0">
          {status === "draft" ? (
            <>
              {botProfile.isFullyConfigured && botProfile.botTestURL ? (
                <button
                  className={`me-md-2 ${styles.previewBtn}`}
                  onClick={handleTestAndPreviewRedirect}
                >
                  <span className="pe-1">
                    <i className="fa-solid fa-play"></i>
                  </span>
                  Test
                </button>
              ) : null}
            </>
          ) : (
            <>
              <button
                className={`me-md-2 ${styles.previewBtn} ${
                  botProfile.botPreviewURL ? "" : "d-none"
                }`}
                onClick={handleTestAndPreviewRedirect}
              >
                <span className="pe-1">
                  <i className="fa-solid fa-eye"></i>
                </span>
                Preview
              </button>
            </>
          )}
          <button
            className={`mt-2 mt-md-0 ${styles.editBtn}`}
            onClick={() => {
              handleEditButton(botProfile.id);
            }}
          >
            <span className="pe-1">
              <i className="fa-solid fa-pen-to-square"></i>
            </span>
            Edit
          </button>
          <span
            className={`ms-2 ${styles.deleteBot} cursor-pointer`}
            onClick={() => handleDelete(botProfile)}
          >
            <i className="fa-solid fa-trash"></i>
          </span>
        </div>
      </div>
      <div>
        {/* Channels */}

        <div className="d-flex flex-wrap align-items-center mb-2">
          <p className={`mb-1 ${styles.appears}`}>Appears on channels:</p>
          {filterdChannels?.length > 0 &&
            filterdChannels.slice(0, 3).map((channel) => (
              <span className={`mb-1 ${styles.channels}`}>
                <i className="fa-solid fa-earth-americas pe-1"></i>{" "}
                {channel.name}
              </span>
            ))}
          {filterdChannels.length > 3 && (
            <OverlayTrigger
              trigger="hover"
              placement="bottom"
              overlay={popover}
            >
              <span className={`mb-1 ${styles.channels}`}>
                <span className={`${styles.more}`}>
                  +{filterdChannels.length - 3} more
                </span>
              </span>
            </OverlayTrigger>
          )}
        </div>

        {/* Audience */}

        <div className="d-flex flex-wrap align-items-center mb-2">
          <p className={`mb-1 ${styles.appears}`}>Appears to audience:</p>
          {filterdEligibleUsers?.length > 0 && (
            <span className={`mb-1 ${styles.channels}`}>
              <i className="fa-solid fa-earth-americas pe-1"></i>{" "}
              {filterdEligibleUsers.map((user) => user.name).join(", ")}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default BotBox;
