import { ESendTypes } from "src/routes/LiveChat/Children/MainChat/Children/LiveSendingAs";
import { axiosJSON } from "src/globals/axiosEndPoints";
import { validateEmail } from "src/utils/utils";

export interface IAddTicketApi {
  to: string;
  name: string;
  cc?: string;
  bcc?: string;
  from: string;
  ticket_title: string;
  ticket_description?: string;
  internalNote?: string; // currently passing only ticket_description for both description and internal note
  sendAs: string;
  sendType?: number; // no need to send it to backend
  attachmentBatchNumber?: Array<string>; // for saving the batch id of attachment
  isAddExternal?: boolean;
  linkedTicketId?: string | number;
}
export async function addTicketApi(ticketData: IAddTicketApi) {
  const ccArr = (ticketData.cc + "")
    .split(",")
    .filter((value) => value !== ticketData.to && value !== "undefined");
  const bccArr = (ticketData.bcc + "")
    .split(",")
    .filter(
      (value) =>
        value !== ticketData.to &&
        value !== "undefined" &&
        !ccArr.includes(value),
    );
  ticketData.cc = ccArr.join(",");
  ticketData.bcc = bccArr.join(",");
  // console.log("AddTicketApi:: payload::: ", JSON.stringify(ticketData));
  if (ticketData.cc === "") {
    delete ticketData.cc;
  }
  if (ticketData.bcc === "") {
    delete ticketData.bcc;
  }
  if (!validateEmail(ticketData.to)) {
    throw "Please select a valid To Email!";
  }
  if (ticketData.name.length === 0) {
    throw "Please enter a valid Name!";
  }
  if (!validateEmail(ticketData.from)) {
    throw "Please select a valid Brand!";
  }
  if (
    ticketData.ticket_description === undefined ||
    ticketData.ticket_description?.length === 0
  ) {
    throw "The message text can not be empty!";
  } else {
    // not empty
    if (ticketData.sendType === ESendTypes.Note) {
      // set ticket internal note
      ticketData.internalNote = ticketData.ticket_description;
      // delete ticket description
      delete ticketData.ticket_description;
      // delete the sendType
      delete ticketData.sendType;
    } else {
      delete ticketData.internalNote;
      delete ticketData.sendType;
    }
  }
  if (ticketData.ticket_title.length === 0) {
    throw "The subject can not be empty!";
  }

  if (ticketData.attachmentBatchNumber?.length === 0) {
    delete ticketData.attachmentBatchNumber;
  }
  // console.log(
  //   "AddTicketApi:: payload after processing::: ",
  //   JSON.stringify(ticketData)
  // );

  const { data } = await axiosJSON.post(`/api/ticket`, ticketData);

  const isError =
    data.err ?? data.error ?? data.id?.err ?? data.id?.error ?? false;
  const statusCode = data.statusCode ?? data.id.statusCode ?? "";
  const message =
    data.msg ??
    data.message ??
    data.id.msg ??
    data.id.message ??
    "Something went wrong!";

  if (isError) {
    // Check specific cases for errors
    if (statusCode) {
      // If the attachment size limit is exceeded, throw a specific error
      if (statusCode === "attachmentSizeLimitExceeded") {
        throw {
          statusCode: "attachmentSizeLimitExceeded",
          message: "Attachment file size exceeded",
        };
      } else if (statusCode === "integrationFailure") {
        throw {
          statusCode: "integrationFailure",
          message: message,
          failureMetaData: data.failureMetaData,
        };
      } else if (statusCode) {
        throw {
          statusCode: statusCode,
          message: message,
        };
      }
    } else {
      throw "Something went wrong!";
    }
  }

  return data;
}
