import axios from "axios";
import { axiosJSON } from "src/globals/axiosEndPoints";
import APIResponse from "./APIReponse";

interface DeleteTeam {
    teamId : number;
}

export interface DeleteTeamParams {
  teamId : number;
}



export async function deletTeam (params : DeleteTeamParams) {
  

    const { data:res } = await axiosJSON.delete<APIResponse<DeleteTeam>>(
      "/api/setting/team/delete",{
        params
      }
    );

    if(res.error === true )
    {
      throw new Error(res.message as string)
    }

    return res.data as DeleteTeam 
  } 
