import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "src/store/store";
import { AjaxError } from "src/types/AjaxError";
import TicketService from "src/services/TicketService/TicketService";
import {
  filtersValidated,
  formatFilterDate,
  getCreatedDateValue,
  getTotalFiltersAppliedCount,
  showCreatedDateSelectRange,
} from "./ticketFilters.selectors";
import { getUsersViewsById } from "src/services/SideBar/getSubSB";
import {
  prepareData,
  prepareDataIntoFilters,
} from "src/services/TicketViewsService/prepareQuery";
import { getFilterValues, isObjOrStrEmpty } from "src/utils/utils";

export type TicketFilterCol = {
  key: string;
  name: string;
  showFilter: boolean;
  isMultiSelect: boolean;
  options?: [];
};

export type FilterByDate = {
  startDate: string | null;
  startTime: string | null;
  endDate: string | null;
  endTime: string | null;
  selectsRange: boolean;
};

export type Filters = {
  filterByCreatedDate: any;
  filterByClosedDate: any;
  filterByCreatedDateValue: FilterByDate;
  filterByClosedDateValue: FilterByDate;
  filterByChannels: [];
  filterByBrands: [];
  filterByAgents: [];
  filterByTags: [];
  filterByEmails: [];
  filterByAssignees: [];
  filterByUserType: [];
  filterByLastMessage: [];
  filterByNotRepliedSince: any;
  filterByNotRepliedSinceValue: FilterByDate;
  filterByTicketStatus: [];
  filterByDraftStatus: [];
  filterByDraftedBy: [];
  filterByLastUpdated: any;
  filterByLastUpdatedValue: FilterByDate;
};

export type TicketFiltersSlice = {
  filtersColData: TicketFilterCol[];
  filters: Filters;
  activeFilters: Filters;
  searchFilterColsDataValue: string;
  fetchTicketFiltersColDataState: "idle" | "pending" | "fulfilled" | "rejected";
  fetchCurrentViewFilters: "idle" | "pending" | "fulfilled" | "rejected";
  currentViewFilters: Filters; //object to store current view filters and used to show in outer ticket filter v2
  filterErrors: { [key: string]: { showError: boolean; msg?: string } };
};

const defaultDateValue: FilterByDate = {
  startDate: null,
  startTime: new Date(new Date().setHours(0, 0, 0, 0)).toISOString(), //by default startTime set to 12:00 AM
  endDate: null,
  endTime: new Date(new Date().setHours(0, 0, 0, 0)).toISOString(), //by default endTime set to 12:00 AM
  selectsRange: false,
};

export const initialFilters: Filters = {
  filterByCreatedDate: [],
  filterByClosedDate: [],
  filterByCreatedDateValue: defaultDateValue,
  filterByClosedDateValue: defaultDateValue,
  filterByChannels: [],
  filterByBrands: [],
  filterByAgents: [],
  filterByTags: [],
  filterByEmails: [],
  filterByAssignees: [],
  filterByUserType: [],
  filterByLastMessage: [],
  filterByNotRepliedSince: [],
  filterByNotRepliedSinceValue: defaultDateValue,
  filterByTicketStatus: [],
  filterByDraftStatus: [],
  filterByDraftedBy: [],
  filterByLastUpdated: {
    name: "Last 30 days",
    compare: "last-30-days",
    isValueRequired: false,
    defaultValue: "null",
    showDateTimePicker: false,
  },
  filterByLastUpdatedValue: defaultDateValue,
};

const initialState: TicketFiltersSlice = {
  filtersColData: [],
  filters: initialFilters,
  activeFilters: initialFilters,
  currentViewFilters: initialFilters, //object to store current view filters and used to show in outer ticket filter v2
  searchFilterColsDataValue: "",
  fetchTicketFiltersColDataState: "idle",
  fetchCurrentViewFilters: "idle", //ajax status of current viewid filters
  filterErrors: {},
};

export const fetchTicketFiltersColData = createAsyncThunk(
  "ticket/fetchTicketFiltersColData",
  async () => {
    try {
      const filtersColData = await TicketService.fetchFilterColData();
      return filtersColData;
    } catch (error) {
      let ajaxError: AjaxError = {
        message: (error as Error).message,
      };
      return ajaxError;
    }
  }
);

export const getCurrentViewData = createAsyncThunk(
  "ticket/getView",
  async (
    payload: number,
    { getState, dispatch }: { getState: Function; dispatch: Function }
  ) => {
    try {
      if (payload) {
        const viewId = payload;
        const viewData = await getUsersViewsById({
          viewId: viewId,
        });
        const vData = prepareDataIntoFilters(viewData);

        return { data: vData };
      }

      return { data: null };
    } catch (error) {
      let ajaxError: AjaxError = {
        message: (error as Error).message,
      };
      throw ajaxError;
    }
  }
);

export const ticketFiltersSlice = createSlice({
  name: "ticketFiltersSlice",
  initialState,
  reducers: {
    resetFilters: (
      state,
      {
        payload,
      }: {
        payload: {
          resetActiveFilters: boolean;
          removeFilterByLastUpdated: boolean;
        };
      }
    ) => {
      state.filters.filterByCreatedDate = [];
      state.filters.filterByClosedDate = [];
      state.filters.filterByCreatedDateValue = defaultDateValue;
      state.filters.filterByClosedDateValue = defaultDateValue;
      state.filters.filterByChannels = [];
      state.filters.filterByBrands = [];
      state.filters.filterByAgents = [];
      state.filters.filterByTags = [];
      state.filters.filterByEmails = [];
      state.filters.filterByAssignees = [];
      state.filters.filterByUserType = [];
      state.filters.filterByLastMessage = [];
      state.filters.filterByNotRepliedSince = [];
      state.filters.filterByNotRepliedSinceValue = defaultDateValue;
      state.filters.filterByTicketStatus = [];
      state.filters.filterByDraftStatus = [];
      state.filters.filterByDraftedBy = [];
      state.filters.filterByLastUpdated = payload.removeFilterByLastUpdated
        ? []
        : {
            name: "Last 30 days",
            compare: "last-30-days",
            isValueRequired: false,
            defaultValue: "null",
            showDateTimePicker: false,
          };
      state.filters.filterByLastUpdatedValue = defaultDateValue;
      if (payload.resetActiveFilters) {
        state.activeFilters = state.filters;
      }
      state.searchFilterColsDataValue = "";
    },
    setSearchFilterColsDataValue: (state, action: PayloadAction<string>) => {
      state.searchFilterColsDataValue = action.payload;
    },
    setFilterByAgents: (state, { payload }) => {
      state.filters.filterByAgents = payload;
    },
    setFilterByCreatedDate: (state, { payload }) => {
      if (payload === null) {
        state.filters.filterByCreatedDate = [];
        state.filters.filterByCreatedDateValue = defaultDateValue;
      } else {
        state.filters.filterByCreatedDate = payload;
        if (payload.compare === "between") {
          state.filters.filterByCreatedDateValue.selectsRange = true;
        } else {
          state.filters.filterByCreatedDateValue.selectsRange = false;
        }
      }
      state.filterErrors["filterByCreatedDateValue"] = {
        showError: false,
        msg: "",
      };
    },
    setFilterByCreatedStartDate: (
      state,
      { payload }: { payload: string | null }
    ) => {
      state.filters.filterByCreatedDateValue.startDate = payload;
    },
    setFilterByCreatedStartTime: (
      state,
      { payload }: { payload: string | null }
    ) => {
      state.filters.filterByCreatedDateValue.startTime = payload;
    },
    setFilterByCreatedEndDate: (
      state,
      { payload }: { payload: string | null }
    ) => {
      state.filters.filterByCreatedDateValue.endDate = payload;
    },
    setFilterByCreatedEndTime: (
      state,
      { payload }: { payload: string | null }
    ) => {
      state.filters.filterByCreatedDateValue.endTime = payload;
    },
    setFilterByCreatedDateRange: (state, { payload }: { payload: boolean }) => {
      state.filters.filterByCreatedDateValue.selectsRange = payload;
    },
    setFilterByLastUpdated: (state, { payload }) => {
      // Reset if no payload
      if (payload === null) {
        state.filters.filterByLastUpdated = [];
        state.filters.filterByLastUpdatedValue = defaultDateValue;
      } else {
        // Assign payload values
        state.filters.filterByLastUpdated = payload;
        // If comparison type is between
        if (payload.compare === "between") {
          // Show range
          state.filters.filterByLastUpdatedValue.selectsRange = true;
        } else {
          // Hide range
          state.filters.filterByLastUpdatedValue.selectsRange = false;
        }
      }
      // Remove any errors
      state.filterErrors["filterByLastUpdatedValue"] = {
        showError: false,
        msg: "",
      };
    },
    setFilterByLastUpdatedStartDate: (
      state,
      { payload }: { payload: string | null }
    ) => {
      state.filters.filterByLastUpdatedValue.startDate = payload;
    },
    setFilterByLastUpdatedStartTime: (
      state,
      { payload }: { payload: string | null }
    ) => {
      state.filters.filterByLastUpdatedValue.startTime = payload;
    },
    setFilterByLastUpdatedEndDate: (
      state,
      { payload }: { payload: string | null }
    ) => {
      state.filters.filterByLastUpdatedValue.endDate = payload;
    },
    setFilterByLastUpdatedEndTime: (
      state,
      { payload }: { payload: string | null }
    ) => {
      state.filters.filterByLastUpdatedValue.endTime = payload;
    },
    setFilterByLastUpdatedDateRange: (
      state,
      { payload }: { payload: boolean }
    ) => {
      state.filters.filterByLastUpdatedValue.selectsRange = payload;
    },
    setFilterByClosedDate: (state, { payload }) => {
      if (payload === null) {
        state.filters.filterByClosedDate = [];
        state.filters.filterByClosedDateValue = defaultDateValue;
      } else {
        state.filters.filterByClosedDate = payload;
        if (payload.compare === "between") {
          state.filters.filterByClosedDateValue.selectsRange = true;
        } else {
          state.filters.filterByClosedDateValue.selectsRange = false;
        }
      }
      state.filterErrors["filterByClosedDateValue"] = {
        showError: false,
        msg: "",
      };
    },
    setFilterByClosedStartDate: (
      state,
      { payload }: { payload: string | null }
    ) => {
      state.filters.filterByClosedDateValue.startDate = payload;
    },
    setFilterByClosedStartTime: (
      state,
      { payload }: { payload: string | null }
    ) => {
      state.filters.filterByClosedDateValue.startTime = payload;
    },
    setFilterByClosedEndDate: (
      state,
      { payload }: { payload: string | null }
    ) => {
      state.filters.filterByClosedDateValue.endDate = payload;
    },
    setFilterByClosedEndTime: (
      state,
      { payload }: { payload: string | null }
    ) => {
      state.filters.filterByClosedDateValue.endTime = payload;
    },
    setFilterByClosedDateRange: (state, { payload }: { payload: boolean }) => {
      state.filters.filterByClosedDateValue.selectsRange = payload;
    },
    setFilterByChannels: (state, { payload }) => {
      state.filters.filterByChannels = payload;
    },
    setFilterByBrands: (state, { payload }) => {
      state.filters.filterByBrands = payload;
    },
    setFilterByTags: (state, { payload }) => {
      state.filters.filterByTags = payload;
    },
    setFilterByEmails: (state, { payload }) => {
      if (payload === null) {
        state.filters.filterByEmails = [];
      } else {
        state.filters.filterByEmails = payload;
      }
    },
    setFilterByAssignees: (state, { payload }) => {
      state.filters.filterByAssignees = payload;
    },
    setFilterByUserType: (state, { payload }) => {
      state.filters.filterByUserType = payload;
    },
    setFilterByLastMessage: (state, { payload }) => {
      state.filters.filterByLastMessage = payload;
    },
    setFilterByNotRepliedSince: (state, { payload }) => {
      if (payload === null) {
        state.filters.filterByNotRepliedSince = [];
        state.filters.filterByNotRepliedSinceValue = defaultDateValue;
      } else {
        state.filters.filterByNotRepliedSince = payload;
      }
      state.filterErrors["filterByNotRepliedSinceValue"] = {
        showError: false,
        msg: "",
      };
    },
    setFilterByNotRepliedSinceDate: (
      state,
      { payload }: { payload: string | null }
    ) => {
      state.filters.filterByNotRepliedSinceValue.startDate = payload;
    },
    setFilterByNotRepliedSinceTime: (
      state,
      { payload }: { payload: string | null }
    ) => {
      state.filters.filterByNotRepliedSinceValue.startTime = payload;
    },

    setFilterByTicketStatus: (state, { payload }) => {
      state.filters.filterByTicketStatus = payload;
    },
    setFilterByDraftStatus: (state, { payload }) => {
      state.filters.filterByDraftStatus = payload;
    },
    setFilterByDraftedBy: (state, { payload }) => {
      state.filters.filterByDraftedBy = payload;
    },
    setFiltersToActiveFilters: (state) => {
      const activeFilters = {
        ...state.filters,
        filterByChannels: getFilterValues(state.filters.filterByChannels),
        filterByBrands: getFilterValues(state.filters.filterByBrands),
        filterByAgents: getFilterValues(state.filters.filterByAgents),
        filterByTags: getFilterValues(state.filters.filterByTags),
        filterByUserType: getFilterValues(state.filters.filterByUserType),
        filterByLastMessage: getFilterValues(state.filters.filterByLastMessage),
        filterByTicketStatus: getFilterValues(
          state.filters.filterByTicketStatus
        ),
        filterByDraftStatus: getFilterValues(state.filters.filterByDraftStatus),
        filterByDraftedBy: getFilterValues(state.filters.filterByDraftedBy),
      };

      //to prevent apply filter triggering fetch even if the filter is not changed issue
      if (
        JSON.stringify(activeFilters) !== JSON.stringify(state.activeFilters)
      ) {
        state.activeFilters = activeFilters;
      }
    },
    setActiveFiltersToFilters: (state) => {
      state.filters = {
        ...state.activeFilters,
        filterByChannels: [
          ...state.activeFilters.filterByChannels,
          ...state.currentViewFilters.filterByChannels,
        ],
        filterByBrands: [
          ...state.activeFilters.filterByBrands,
          ...state.currentViewFilters.filterByBrands,
        ],
        filterByAgents: [
          ...state.activeFilters.filterByAgents,
          ...state.currentViewFilters.filterByAgents,
        ],
        filterByTags: [
          ...state.activeFilters.filterByTags,
          ...state.currentViewFilters.filterByTags,
        ],
        filterByUserType: [
          ...state.activeFilters.filterByUserType,
          ...state.currentViewFilters.filterByUserType,
        ],
        filterByLastMessage: [
          ...state.activeFilters.filterByLastMessage,
          ...state.currentViewFilters.filterByLastMessage,
        ],
        filterByTicketStatus: [
          ...state.activeFilters.filterByTicketStatus,
          ...state.currentViewFilters.filterByTicketStatus,
        ],
        filterByDraftStatus: [
          ...state.activeFilters.filterByDraftStatus,
          ...state.currentViewFilters.filterByDraftStatus,
        ],
        filterByDraftedBy: [
          ...state.activeFilters.filterByDraftedBy,
          ...state.currentViewFilters.filterByDraftedBy,
        ],
      };

      if (!isObjOrStrEmpty(state.currentViewFilters.filterByClosedDate)) {
        state.filters.filterByClosedDate =
          state.currentViewFilters.filterByClosedDate;
        state.filters.filterByClosedDateValue =
          state.currentViewFilters.filterByClosedDateValue;
      }

      if (!isObjOrStrEmpty(state.currentViewFilters.filterByCreatedDate)) {
        state.filters.filterByCreatedDate =
          state.currentViewFilters.filterByCreatedDate;
        state.filters.filterByCreatedDateValue =
          state.currentViewFilters.filterByCreatedDateValue;
      }

      // Assign last updated filter to active filters
      // if (!isObjOrStrEmpty(state.currentViewFilters.filterByLastUpdated)) {
      //   state.filters.filterByLastUpdated =
      //     state.currentViewFilters.filterByLastUpdated;
      //   state.filters.filterByLastUpdatedValue =
      //     state.currentViewFilters.filterByLastUpdatedValue;
      // }
    },
    setFiltersFromURL: (state, { payload }) => {
      state.activeFilters = payload;
      state.filters = payload;
    },
    setCurrentViewFilters: (state, { payload }) => {
      state.currentViewFilters = payload;

      //filter out if the same filter applied in view filter
      let filterByTicketStatus: any =
        state.activeFilters.filterByTicketStatus.filter((filter: any) => {
          let isExist = state.currentViewFilters.filterByTicketStatus.filter(
            (viewFilter: any) => viewFilter.id == filter.id
          );

          if (isExist.length) {
            return false;
          }

          return true;
        });

      if (
        filterByTicketStatus.length !==
        state.activeFilters.filterByTicketStatus.length
      ) {
        state.activeFilters.filterByTicketStatus = filterByTicketStatus;
      }

      let filterByDraftStatus: any =
        state.activeFilters.filterByDraftStatus.filter((filter: any) => {
          let isExist = state.currentViewFilters.filterByDraftStatus.filter(
            (viewFilter: any) => viewFilter.id == filter.id
          );

          if (isExist.length) {
            return false;
          }

          return true;
        });

      if (
        filterByDraftStatus.length !==
        state.activeFilters.filterByDraftStatus.length
      ) {
        state.activeFilters.filterByDraftStatus = filterByDraftStatus;
      }

      let filterByChannels: any = state.activeFilters.filterByChannels.filter(
        (filter: any) => {
          let isExist = state.currentViewFilters.filterByChannels.filter(
            (viewFilter: any) => viewFilter.id == filter.id
          );

          if (isExist.length) {
            return false;
          }

          return true;
        }
      );

      if (
        filterByChannels.length !== state.activeFilters.filterByChannels.length
      ) {
        state.activeFilters.filterByChannels = filterByChannels;
      }
    },
    resetCurrentViewFilters: (state) => {
      state.currentViewFilters = initialFilters;
    },
    resetOnlyOtherFilters: (state) => {
      state.filters = state.currentViewFilters;
      state.activeFilters = state.currentViewFilters;
      return state;
    },
    //reducer function to reset active filters, it used on outer ticket filter v2
    removeActiveFilter: (state, { payload }) => {
      switch (payload) {
        case "ticket_date_gmt":
          state.activeFilters.filterByCreatedDate = [];
          state.activeFilters.filterByCreatedDateValue = defaultDateValue;
          break;
        // Remove the last updated filter
        case "ticket_modified_gmt":
          state.activeFilters.filterByLastUpdated = [];
          state.activeFilters.filterByLastUpdatedValue = defaultDateValue;
          break;
        case "ticket_closed_date_gmt":
          state.activeFilters.filterByClosedDate = [];
          state.activeFilters.filterByClosedDateValue = defaultDateValue;
          break;
        case "ticket_channel":
          state.activeFilters.filterByChannels = [];
          break;
        case "ticket_brand_id":
          state.activeFilters.filterByBrands = [];
          break;
        case "ticket_agent":
          state.activeFilters.filterByAgents = [];
          break;
        case "tags":
          state.activeFilters.filterByTags = [];
          break;
        case "filter_email":
          state.activeFilters.filterByEmails = [];
          break;
        case "user_type":
          state.activeFilters.filterByUserType = [];
          break;
        case "last_message":
          state.activeFilters.filterByLastMessage = [];
          break;
        case "not_replied_since":
          state.activeFilters.filterByNotRepliedSince = [];
          state.activeFilters.filterByNotRepliedSinceValue = defaultDateValue;
          break;
        case "ticket_status_id":
          state.activeFilters.filterByTicketStatus = [];
          break;
        case "ticket_draft_status":
          state.activeFilters.filterByDraftStatus = [];
          break;
        case "drafted_by":
          state.activeFilters.filterByDraftedBy = payload;
          break;
      }
    },
    validateFilters: (state) => {
      // validate created date filter
      if (!isObjOrStrEmpty(state.filters.filterByCreatedDate)) {
        if (state.filters.filterByCreatedDate.isValueRequired === true) {
          const dateValue = JSON.parse(
            JSON.stringify(state.filters.filterByCreatedDateValue)
          );
          // log.debug({ dateValueeeeeeeeeee: dateValue });
          if (formatFilterDate(dateValue) === null) {
            state.filterErrors["filterByCreatedDateValue"] = {
              showError: true,
            };
          } else {
            state.filterErrors["filterByCreatedDateValue"] = {
              showError: false,
            };
          }
        }
      } else {
        state.filterErrors["filterByCreatedDateValue"] = { showError: false };
      }

      // Validate the last updated filter
      if (!isObjOrStrEmpty(state.filters.filterByLastUpdated)) {
        // Check if value is required and needs to be validated
        if (state.filters.filterByLastUpdated.isValueRequired === true) {
          // Get the date value and parse it
          const dateValue = JSON.parse(
            JSON.stringify(state.filters.filterByLastUpdatedValue)
          );
          // If formatted date is not valid
          if (formatFilterDate(dateValue) === null) {
            // Show error
            state.filterErrors["filterByLastUpdatedValue"] = {
              showError: true,
            };
          } else {
            // No error to be shown
            state.filterErrors["filterByLastUpdatedValue"] = {
              showError: false,
            };
          }
        }
      } else {
        state.filterErrors["filterByLastUpdatedValue"] = { showError: false };
      }

      // validate closed date filter
      if (!isObjOrStrEmpty(state.filters.filterByClosedDate)) {
        if (state.filters.filterByClosedDate.isValueRequired === true) {
          const dateValue = JSON.parse(
            JSON.stringify(state.filters.filterByClosedDateValue)
          );
          if (formatFilterDate(dateValue) === null) {
            state.filterErrors["filterByClosedDateValue"] = { showError: true };
          } else {
            state.filterErrors["filterByClosedDateValue"] = {
              showError: false,
            };
          }
        }
      } else {
        state.filterErrors["filterByClosedDateValue"] = { showError: false };
      }

      // validate not replied since date filter
      if (!isObjOrStrEmpty(state.filters.filterByNotRepliedSince)) {
        if (state.filters.filterByNotRepliedSince.isValueRequired === true) {
          const dateValue = JSON.parse(
            JSON.stringify(state.filters.filterByNotRepliedSinceValue)
          );
          if (formatFilterDate(dateValue) === null) {
            state.filterErrors["filterByNotRepliedSinceValue"] = {
              showError: true,
            };
          } else {
            state.filterErrors["filterByNotRepliedSinceValue"] = {
              showError: false,
            };
          }
        }
      } else {
        state.filterErrors["filterByNotRepliedSinceValue"] = {
          showError: false,
        };
      }
    },
  },
  extraReducers: (builder) => {
    /* 
    Reducers for bulk action for assigning tickets user
    */
    builder.addCase(
      fetchTicketFiltersColData.fulfilled,
      (state, { payload }) => {
        state.filtersColData = payload as any;
        state.fetchTicketFiltersColDataState = "fulfilled";
      }
    );
    builder.addCase(fetchTicketFiltersColData.rejected, (state) => {
      state.fetchTicketFiltersColDataState = "rejected";
    });
    builder.addCase(fetchTicketFiltersColData.pending, (state) => {
      state.fetchTicketFiltersColDataState = "pending";
    });
    /* 
    Reducers for fetching current views filters (custom view)
    */
    builder.addCase(getCurrentViewData.fulfilled, (state, { payload }) => {
      const res = payload as any;
      if (res.data) {
        // Build initial state filters for views
        const intialFiltersCustomView = { ...initialFilters };
        intialFiltersCustomView.filterByLastUpdated = [];

        // Spread the custom views initial filters along with view filters.
        state.currentViewFilters = { ...intialFiltersCustomView, ...res.data };

        // Remove the default state last updated filter from active filters
        state.filters.filterByLastUpdated = [];
        state.filters.filterByLastUpdatedValue = defaultDateValue;
        state.activeFilters = state.filters;
      }
      state.fetchTicketFiltersColDataState = "fulfilled";
    });
    builder.addCase(getCurrentViewData.rejected, (state) => {
      state.fetchTicketFiltersColDataState = "rejected";
    });
    builder.addCase(getCurrentViewData.pending, (state) => {
      state.fetchTicketFiltersColDataState = "pending";
    });
  },
});

export const {
  setSearchFilterColsDataValue,
  resetFilters,
  setFilterByCreatedDate,

  setFilterByCreatedStartDate,
  setFilterByCreatedStartTime,
  setFilterByCreatedEndDate,
  setFilterByCreatedEndTime,
  setFilterByCreatedDateRange,

  setFilterByLastUpdated,
  setFilterByLastUpdatedStartDate,
  setFilterByLastUpdatedStartTime,
  setFilterByLastUpdatedEndDate,
  setFilterByLastUpdatedEndTime,
  setFilterByLastUpdatedDateRange,

  setFilterByClosedDate,

  setFilterByClosedStartDate,
  setFilterByClosedStartTime,
  setFilterByClosedEndDate,
  setFilterByClosedEndTime,
  setFilterByClosedDateRange,

  setFilterByNotRepliedSinceDate,
  setFilterByNotRepliedSinceTime,

  setFilterByChannels,
  setFilterByBrands,
  setFilterByAgents,
  setFilterByTags,
  setFilterByAssignees,
  setFilterByEmails,
  setFilterByUserType,
  setFilterByLastMessage,
  setFilterByNotRepliedSince,
  setFilterByTicketStatus,
  setFilterByDraftStatus,
  setFilterByDraftedBy,
  setActiveFiltersToFilters,
  setFiltersToActiveFilters,
  setFiltersFromURL,
  removeActiveFilter, //to reset active filter by key used in filter v2 outer ticket
  resetOnlyOtherFilters, //to reset only other filters
  setCurrentViewFilters,
  validateFilters,
} = ticketFiltersSlice.actions;

export const getCreatedDateValueSelector = createSelector(
  [getCreatedDateValue],
  (getCreatedDateValue) => getCreatedDateValue
);

export const showCreatedDateSelectRangeSelector = createSelector(
  [showCreatedDateSelectRange],
  (showCreatedDateSelectRange) => showCreatedDateSelectRange
);

export const filtersValidatedSelector = createSelector(
  (state: RootState) => state.ticketFilter.filters,
  filtersValidated
);

export const getTotalFiltersAppliedCountSelector = createSelector(
  (state: RootState) => state.ticketFilter.filters,
  getTotalFiltersAppliedCount
);

export const getTotalActiveFiltersAppliedCountSelector = createSelector(
  (state: RootState) => state.ticketFilter.activeFilters,
  getTotalFiltersAppliedCount
);

export const selectFilterSearchValue = (state: RootState) =>
  state.ticketFilter.searchFilterColsDataValue;

export default ticketFiltersSlice.reducer;
