import {
  ActionGroup,
  IActionForModal,
  IActionQuestion,
  StepStatus,
} from "src/services/Automation/getAutomationById.service";
import styles from "./ActionQuestion.module.scss";
import { useAutomationContext } from "../../../../Hooks/useAutomationContext";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import pen from "src/assets/images/PenBlue.png";
import pendingImg from "src/assets/images/pendingIcon.png";
import ActionsModal from "../ActionsModal/ActionsModal";
import {
  EAutomationType,
  IAutomationListParams,
} from "src/routes/BotSettings/Children/AutomationList/AutomationList";
import { useParams } from "react-router-dom";

interface QuestionsProps {
  questionData: IActionQuestion;
  stepId: string;
  setShowErrorModal?: React.Dispatch<React.SetStateAction<boolean>>;
  updateActiveStep?: (val: number) => void;
}

const ActionQuestion = ({
  questionData,
  stepId,
  setShowErrorModal,
  updateActiveStep,
}: QuestionsProps) => {
  const { updateQuestions, saveQuestionsData, formData } =
    useAutomationContext();
  const [isActive, setIsActive] = useState(
    questionData?.conditionGroups &&
      questionData?.conditionGroups[0]?.status === "pending",
  );
  const [isModalVisible, setModalVisible] = useState(false);
  const { type } = useParams<IAutomationListParams>();

  const updateConditionGroup = (newGroup: ActionGroup) => {
    if (type == EAutomationType.TICKET_ROUTING && formData?.published) {
      if (setShowErrorModal) setShowErrorModal(true);
      return;
    }
    if (questionData?.conditionGroups?.length) {
      // Find the group with the matching groupUUID
      const newGroups = [...questionData.conditionGroups];
      const groupIndex = newGroups.findIndex(
        (group) => group.groupUUID === newGroup?.groupUUID,
      );

      // Update the logical operator if the group is found
      if (groupIndex !== -1) {
        newGroups[groupIndex] = newGroup;
        const allGroupsCompleted = newGroups.every(
          (group) => group.status === StepStatus.Completed,
        );
        // Update and save the new groups
        updateQuestions({
          ...questionData,
          conditionGroups: newGroups as ActionGroup[],
          isAnswered: allGroupsCompleted,
        });
        saveQuestionsData({
          stepId,
          stepQuestions: {
            [questionData.questionKey]: newGroups as ActionGroup[],
          },
        });

        // Added static stepId for updating automation summary question, update here if changed on backend
        const automationSummaryQuestion = Object.values(
          formData.questionsData,
        ).find((question) => question.questionKey === "automationSummary");
        if (automationSummaryQuestion)
          updateQuestions({
            ...automationSummaryQuestion,
            isAnswered: allGroupsCompleted,
          });
      }
    }
  };

  return (
    <div>
      <div
        className={`d-flex align-items-center justify-content-between ${styles.configureBtnWrapper}`}
      >
        {/* Configuration Button */}
        <div
          d-flex
          flex-column
        >
          <div className="d-flex align-items-center me-2">
            <button
              className={`${styles.nextStepButton} btn px-3 ${
                !isActive && styles.disabled
              }`}
              onClick={() => setModalVisible(true)}
            >
              <div>
                <p className="mb-0">
                  <span className={`d-block ${styles.configBtn}`}>
                    {questionData?.conditionGroups &&
                      questionData?.conditionGroups[0]?.status ===
                        "completed" && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 22 22"
                        >
                          <defs>
                            <filter
                              id="Path_3051"
                              x="0"
                              y="0"
                              width="22"
                              height="22"
                              filterUnits="userSpaceOnUse"
                            >
                              <feOffset dy="3" />
                              <feGaussianBlur
                                stdDeviation="6.5"
                                result="blur"
                              />
                              <feFlood flood-color="#c1c1c1" />
                              <feComposite
                                operator="in"
                                in2="blur"
                              />
                              <feComposite in="SourceGraphic" />
                            </filter>
                          </defs>
                          <g
                            id="Group_5944"
                            data-name="Group 5944"
                          >
                            <g filter="url(#Path_3051)">
                              <g
                                id="Path_3051-2"
                                data-name="Path 3051"
                                fill="none"
                              >
                                <path
                                  d="M11,0A11,11,0,1,1,0,11,11,11,0,0,1,11,0Z"
                                  stroke="none"
                                />
                                <path
                                  d="M 11 2 C 6.037380218505859 2 2 6.037380218505859 2 11 C 2 15.96261978149414 6.037380218505859 20 11 20 C 15.96261978149414 20 20 15.96261978149414 20 11 C 20 6.037380218505859 15.96261978149414 2 11 2 M 11 0 C 17.07513046264648 0 22 4.924869537353516 22 11 C 22 17.07513046264648 17.07513046264648 22 11 22 C 4.924869537353516 22 0 17.07513046264648 0 11 C 0 4.924869537353516 4.924869537353516 0 11 0 Z"
                                  stroke="none"
                                  fill="#fff"
                                />
                              </g>
                            </g>
                            <path
                              id="Icon_material-done"
                              data-name="Icon material-done"
                              d="M8.437,14.716l-2.5-2.5-.834.834,3.337,3.337,7.15-7.15L14.752,8.4Z"
                              fill="#fff"
                              stroke="#fff"
                              stroke-width="1"
                            />
                          </g>
                        </svg>
                      )}
                    <span className="ms-2">{`Configure action`}</span>
                  </span>
                </p>
              </div>
            </button>
          </div>
          {/* Edit Configuration */}
          {questionData?.conditionGroups &&
            questionData?.conditionGroups[0]?.status === "completed" && (
              <div
                className={`mt-2 ${styles.editText}`}
                role="button"
                onClick={() => setIsActive(true)}
              >
                <img
                  src={pen}
                  alt=""
                />
                <span className="ps-1">Edit configuration</span>
              </div>
            )}
          {/* Pending configuration */}
          {questionData?.conditionGroups &&
            questionData?.conditionGroups[0]?.status === "pending" && (
              <div className="d-flex align-items-center pt-2">
                <img
                  src={pendingImg}
                  alt=""
                  className={`${styles.pendingImg}`}
                />
                <span className={`ps-1 ${styles.pendingText}`}>Pending</span>
              </div>
            )}
        </div>
        {/* Modal */}
        <Modal
          show={isModalVisible}
          onHide={() => setModalVisible(false)}
          contentClassName={`${styles.modalContent}`}
          dialogClassName={`${styles.dialogClass}`}
          centered={false}
          enforceFocus={false}
        >
          <ActionsModal
            handleCloseModal={() => setModalVisible(false)}
            conditionGroups={
              (questionData.conditionGroups as ActionGroup[]) ?? []
            }
            updateConditionGroup={updateConditionGroup}
            setShowErrorModal={setShowErrorModal}
          />
        </Modal>
      </div>
    </div>
  );
};

export default ActionQuestion;
