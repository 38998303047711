import styles from "./AboutIntegration.module.scss";
import dummy1 from "src/assets/images/dummy1.png";
import fbLogo from "src/assets/images/browseFb.png";
import backArrow from "src/assets/images/backArrow.png";
import CheckConditions from "./Children/CheckConditions/CheckConditions";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import {
  deleteFacebookIntegration,
  subscribeFacebookPage,
  unsubscribeFacebookPage,
} from "src/services/Integrations/FacebookIntegration";
import { useCallback, useEffect, useState } from "react";
import { Dropdown, Spinner } from "react-bootstrap";
import ConfirmCancelModal from "../../../ConfirmCancelModal/ConfirmCancelModal";
import { useFacebookPageInfo } from "./useFacebookPageInfo";
import { WebhookInfo } from "src/services/Integrations/getFacebookPageWebhooks";
import { v4 as uuid } from "uuid";
import { refetchSideBarData } from "src/store/slices/ticketSidebar/ticketSidebar.slice";
import { useAppDispatch } from "src/store/store";
import UserAvatar from "src/components/UserAvatar";
import AxiosImg from "src/components/AxiosImg";

const AboutIntegration = ({ showWhat, setShowWhat, currentPageDatas }: any) => {
  const [showConfirmExitModal, setShowConfirmExitModal] =
    useState<boolean>(false);

  const [currentPageData, setCurrentPageData] = useState(currentPageDatas);
  const [imgURL, setImgURL] = useState<string>();
  const handleGoBack = () => {
    setShowWhat("listPages");
  };

  useEffect(() => {
    if (currentPageData?.imageUrl) {
      setImgURL(currentPageData.imageUrl);
    }
  }, [currentPageData?.imageUrl]);

  // const handleSaveChanges = () => {
  //   if (isConnected !== currentPageData.integrated) {
  //     if (currentPageData.integrated) {
  //       setShowSaveLoader(true);
  //       unsubscribeFacebookPage({ page_id: currentPageData.id })
  //         .then((res) => {
  //           let currentData = currentPageData;
  //           currentData.integrated = isConnected;
  //           setCurrentPageData(currentData);
  //           setShowSaveLoader(false);
  //           pushTheToast({
  //             type: "success",
  //             text: "Page disconnected",
  //             position: "top-right",
  //           });
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //           pushTheToast({
  //             type: "danger",
  //             text: "Something went wrong",
  //             position: "top-right",
  //           });
  //           setIsConnected(currentPageData.integrated);
  //           setShowSaveLoader(false);
  //         });
  //     } else {
  //       setShowSaveLoader(true);
  //       subscribeFacebookPage({ page_ids: [currentPageData.id] })
  //         .then((res) => {
  //           let currentData = currentPageData;
  //           currentData.integrated = isConnected;
  //           setCurrentPageData(currentData);
  //           pushTheToast({
  //             type: "success",
  //             text: "Page connected",
  //             position: "top-right",
  //           });
  //           setShowSaveLoader(false);
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //           pushTheToast({
  //             type: "danger",
  //             text: "Something went wrong",
  //             position: "top-right",
  //           });
  //           setIsConnected(currentPageData.integrated);
  //           setShowSaveLoader(false);
  //         });
  //     }
  //   }
  // };

  const {
    data,
    updateWebhook,
    handleSaveChanges,
    hasDataChanged,
    isSaveLoading,
    allWebhookDisabled,
    isConnected,
    setIsConnected,
    showToggleError,
    setShowToggleError,
  } = useFacebookPageInfo({
    pageId: currentPageData?.id,
    currentPageData: currentPageData,
    setCurrentPageData,
  });

  return (
    <>
      <div className={styles.aboutIntMain}>
        <div className={`d-flex justify-content-between align-items-center`}>
          <div className={`d-flex align-items-center`}>
            <div
              className="edit-icons"
              style={{ cursor: "pointer" }}
            >
              <button
                style={{ border: "none", background: "none" }}
                onClick={() => {
                  if (isConnected !== currentPageData.integrated) {
                    setShowConfirmExitModal(true);
                  } else {
                    handleGoBack();
                  }
                }}
              >
                {/* <svg
                  style={{ transform: "rotateZ(180deg)" }}
                  width="29"
                  height="29"
                  viewBox="0 0 29 29"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.625 6.04191V22.9586C3.625 23.5995 3.87961 24.2142 4.33283 24.6674C4.78604 25.1206 5.40073 25.3752 6.04167 25.3752H22.9583C23.5993 25.3752 24.214 25.1206 24.6672 24.6674C25.1204 24.2142 25.375 23.5995 25.375 22.9586V6.04191C25.375 5.40097 25.1204 4.78628 24.6672 4.33307C24.214 3.87986 23.5993 3.62524 22.9583 3.62524H6.04167C5.40073 3.62524 4.78604 3.87986 4.33283 4.33307C3.87961 4.78628 3.625 5.40097 3.625 6.04191ZM8.45833 13.2919H14.5V8.45858L20.5417 14.5002L14.5 20.5419V15.7086H8.45833V13.2919Z"
                    stroke="black"
                    strokeWidth="2"
                  />
                  <path
                    d="M22.9584 14.5L14.7876 7.25V13.2917H7.78406V15.7083H14.7876V21.75L22.9584 14.5Z"
                    fill="black"
                  />
                </svg> */}
                <img
                  src={backArrow}
                  alt="backArrow"
                  height={25}
                />
              </button>
            </div>
            <div className={`${styles.fbMainDiv}`}>
              <h1 className={`${styles.fb}`}>{currentPageData.name}</h1>
            </div>
          </div>
          {/* -----Show this div when Integration successfully added!----- */}

          {/* <div className={`${styles.successMsg}`}>
          <span><i className={`fa-solid fa-circle-check me-1`}></i></span>
          <span>Integration successfully added!</span>
        </div> */}
          <div>
            <button
              className={`ms-1 px-2 py-2 ${styles.addBtn}`}
              onClick={handleSaveChanges}
              disabled={!hasDataChanged}
            >
              <span className={`me-1`}>
                {isSaveLoading == true ? (
                  <Spinner
                    className="mx-1"
                    animation="border"
                    size="sm"
                  />
                ) : (
                  <></>
                )}
              </span>{" "}
              Save changes
            </button>
          </div>
        </div>
        <div className={`${styles.aboutMain}`}>
          <h3 className={`${styles.aboutIntegrate}`}>About this page</h3>
          <div className={`${styles.aboutBox}`}>
            <div
              className={`d-flex flex-column flex-md-row justify-content-between align-items-start align-items-ms-center ${styles.box}`}
            >
              <div className={`d-flex`}>
                <div>
                  {" "}
                  <img
                    className={styles.fbPageImg}
                    src={imgURL && imgURL !== "" ? imgURL : fbLogo}
                    onError={() => {
                      setImgURL(fbLogo);
                    }}
                  />{" "}
                </div>
                <div className={`ms-3 mt-0`}>
                  <p className={`${styles.fbName} mt-0`}>
                    {currentPageData.name}
                  </p>
                  <div className={`d-flex flex-column ${styles.brandSection}`}>
                    {data.brand && (
                      <div className="d-flex align-items-center me-3">
                        <span className={`${styles.brandText} pe-1`}>
                          Brand:
                        </span>
                        <div className="d-flex align-items-center">
                          {data?.brand.imageUrl &&
                          data?.brand.imageUrl.trim() !== "" ? (
                            <AxiosImg
                              url={data?.brand.imageUrl}
                              className={`rounded-circle`}
                              style={{
                                width: "14px",
                                height: "14px",
                              }}
                            />
                          ) : (
                            <UserAvatar
                              name={data?.brand.name}
                              size={14}
                            />
                          )}
                          <span className={`ps-1 ${styles.details}`}>
                            {data.brand.name}
                          </span>
                        </div>
                      </div>
                    )}
                    <div className={``}>
                      {data.facebookAccount && (
                        <div className="d-flex align-items-center">
                          <span className={`pe-1 ${styles.brandText}`}>
                            FB account:
                          </span>
                          <div className="d-flex align-items-center">
                            {data?.facebookAccount?.imageUrl &&
                            data?.facebookAccount?.imageUrl.trim() ? (
                              <AxiosImg
                                url={data?.facebookAccount?.imageUrl}
                                className={`rounded-circle`}
                                style={{
                                  width: "14px",
                                  height: "14px",
                                }}
                                isDirectURL={true}
                              />
                            ) : (
                              <UserAvatar
                                name={data?.facebookAccount.name}
                                size={14}
                              />
                            )}
                            <span className={`ps-1 ${styles.details}`}>
                              {data.facebookAccount.name}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* <span className={`${styles.pageInstall} d-none`}>
                    Page installed on:{" "}
                    <span className={`ms-1 ${styles.installName}`}> Sam</span>
                  </span> */}
                </div>
              </div>
              <div className={`d-flex align-items-center d-none`}>
                <div>
                  <span className={`${styles.discSpan}`}>
                    {isConnected === true && !allWebhookDisabled
                      ? "Disconnect Page"
                      : "Connect Page"}
                  </span>
                </div>
                <div className="form-check form-switch me-2">
                  <input
                    data-lpignore="true"
                    className={`form-check-input ${styles.formCheck}`}
                    type="checkbox"
                    checked={isConnected === true && !allWebhookDisabled}
                    onChange={() => {}}
                    onClick={(e) => {
                      if (allWebhookDisabled) {
                        setShowToggleError(true);
                        pushTheToast({
                          type: "warning",
                          text: "Please select at least one channel!",
                          position: "top-right",
                        });
                        return;
                      }
                      setIsConnected(!isConnected);
                    }}
                  />
                </div>
              </div>
            </div>
            {data?.webhooks && data.webhooks.length !== 0 ? (
              <div
                className={`d-flex flex-column flex-md-row ${styles.conditionBox}`}
              >
                {
                  <>
                    {data.webhooks
                      .reduce(
                        (
                          pairs: Array<WebhookInfo[]>,
                          webhookInfo,
                          idx,
                          arr,
                        ) => {
                          if (idx % 2 === 0) {
                            const pair = arr.slice(idx, idx + 2);
                            pairs.push(pair);
                          }
                          return pairs;
                        },
                        [],
                      )
                      .map((pair, pairIdx) => (
                        <div
                          className={`w-100 w-md-50`}
                          key={uuid()}
                        >
                          {pair.map((webhookInfo) => (
                            <CheckConditions
                              key={webhookInfo.key}
                              webhookInfo={webhookInfo}
                              updateWebhook={updateWebhook}
                              showErrorBorder={showToggleError}
                            />
                          ))}
                        </div>
                      ))}
                  </>
                }
                {/* <CheckConditions
                    name={`Facebook post, comments and ads comments`}
                  />
                <div className={`w-100 w-md-50`}>
                  <CheckConditions name={`Instagram comments`} />
                  <CheckConditions name={`Instagram direct messages`} />
                </div> */}
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {/* Confirm exit */}
      {showConfirmExitModal === true && (
        <ConfirmCancelModal
          showModal={showConfirmExitModal}
          setShowModal={setShowConfirmExitModal}
          handleConfirmExit={handleGoBack}
        />
      )}
    </>
  );
};
export default AboutIntegration;
