import { axiosJSON } from "src/globals/axiosEndPoints";
import { ILoginData } from "src/hooks/auth/useLogin";
import { validateEmail } from "src/utils/utils";

interface ILogIn {
  email: string;
  password: string;
  gRecaptchaToken?: string | null; //for the recaptcha token
}

export async function loginUser(servicePayload: ILogIn) {
  if (!validateEmail(servicePayload.email) && !servicePayload.password) {
    throw new Error("Email or Password not given!");
  }
  const { data } = await axiosJSON.post(`/api/login`, servicePayload);
  if (data.status == "0") {
    throw new Error(data.message as string);
  }
  if (process.env.REACT_APP_FORCE_FIRST_LOGIN === "true") {
    data.first_login = true;
  }
  return data as ILoginData;
}
