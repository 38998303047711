import styles from "./Availability.module.scss";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { useCallback, useEffect, useState } from "react";
import {
  fetchAvailabilityData,
  IAgentAvailableAtData,
  resetAvailabilitySetting,
  updateAvailabilityData,
} from "src/store/slices/liveChatSetting/chatWidgetSetting/availability/availabilitySetting.slice";
import AgentTimeSlot from "./children/AgentTimeSlot";
import { v4 as uuidv4 } from "uuid";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import {
  IUpdateAvailabilityDataParams,
  updateChatSetting,
} from "src/services/LiveChat/Settings/updateChatSettingData";
import { Modal, Spinner } from "react-bootstrap";
import { Link, NavLink, useParams, useSearchParams } from "react-router-dom";
import FinalChat from "../Customization/Children/FinalChat/FinalChat";
import { fetchTimeZoneListData } from "src/store/slices/settings/profile/profileSettings.slice";
import {
  convertGmtToUserTimezone,
  getDayAndTime,
  isDateStringValid,
} from "src/utils/dateLibrary";
import { setSelectedBtn } from "src/store/slices/ticketSidebar/ticketSidebar.slice";
const Availability = () => {
  const { availabilityData, availableDayOptions, availabilityDataAjaxStatus } =
    useAppSelector((state) => state.availabilitySettings);
  const { customizationData } = useAppSelector(
    (state) => state.customizationSettings
  );

  const { timezoneListData, fetchTimezoneListAjaxStatus } = useAppSelector(
    (state) => state.profileSettings
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const previewTeamQuery = searchParams.get("preview");

  const userTimezone = useAppSelector(
    (state) => state.globals.currentUserData?.userTimezone
  );
  const dispatch = useAppDispatch();

  const { integrationId } = useParams();
  const pageIntegrationId = integrationId
    ? decodeURIComponent(integrationId)
    : "";

  // const [canCustomerStartChatAlways, setCanCustomerStartChatAlways] =
  //   useState<boolean>(false);
  // const [canCustomerViewChatHistory, setCanCustomerViewChatHistory] =
  //   useState<boolean>(false);
  const [agentAvailableAtData, setAgentAvailableAtData] = useState<
    Array<IAgentAvailableAtData>
  >([]);
  const [showSaveBtn, setShowSaveBtn] = useState(false);
  const [showSaveLoader, setShowSaveLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const onHide = () => {
    setShowModal(false);
  };
  const [showOnlineStatusText, setShowOnlineStatusText] = useState(true);

  useEffect(() => {
    dispatch(fetchAvailabilityData(pageIntegrationId));
    handleFetchTimeZoneList();
  }, []);

  useEffect(() => {
    if (availabilityData !== null) {
      // if (availabilityData.canCustomerStartChatAlways !== null) {
      //   setCanCustomerStartChatAlways(
      //     availabilityData.canCustomerStartChatAlways
      //   );
      // }
      // if (availabilityData.canCustomerViewChatHistory !== null) {
      //   setCanCustomerViewChatHistory(
      //     availabilityData.canCustomerViewChatHistory
      //   );
      // }
      setAgentAvailableAtData(availabilityData.agentAvailableAt);
    }
  }, [availabilityData]);

  useEffect(()=>{
    if(previewTeamQuery && previewTeamQuery === "offlineTeam"){
      setShowOnlineStatusText(false);
    }
  },[previewTeamQuery])

  useEffect(() => {
    if (availabilityData !== null) {
      const availableAt = availabilityData.agentAvailableAt.map(
        (availableAt) => {
          return {
            ...availableAt,
            id: null,
          };
        }
      );

      const payloadAvailableAtData = agentAvailableAtData.map((availableAt) => {
        return {
          ...availableAt,
          id: null,
        };
      });

      if (
        // availabilityData.canCustomerStartChatAlways ===
        //   canCustomerStartChatAlways &&
        // availabilityData.canCustomerViewChatHistory ===
        //   canCustomerViewChatHistory &&
        JSON.stringify(availableAt) === JSON.stringify(payloadAvailableAtData)
      ) {
        setShowSaveBtn(false);
      } else {
        setShowSaveBtn(true);
      }
    }
  }, [
    // canCustomerStartChatAlways,
    // canCustomerViewChatHistory,
    agentAvailableAtData,
  ]);

  const handleEditAvailabilityData = () => {
    if (availabilityDataAjaxStatus !== "pending" && availabilityData !== null) {
      if (agentAvailableAtData === null) {
        pushTheToast({
          type: "danger",
          text: "something went wrong!",
          position: "top-right",
        });
        return false;
      }
      setShowSaveLoader(true);
      const agentAvailableAtList = agentAvailableAtData.map((availableAt) => {
        return {
          availableAtFrom: availableAt.availableAtFrom,
          availableAtTo: availableAt.availableAtTo,
          availableDay: availableAt.availableDay,
          timezone: availableAt.timezone,
        };
      });
      let payload: IUpdateAvailabilityDataParams = {
        agentAvailableAt: agentAvailableAtList,
        // canCustomerStartChatAlways: canCustomerStartChatAlways,
        // canCustomerViewChatHistory: canCustomerViewChatHistory,
      };
      updateChatSetting({
        integrationId: pageIntegrationId,
        availability: payload,
      })
        .then((res) => {
          pushTheToast({
            type: "success",
            text: "Changes Saved !",
            position: "top-right",
          });
          setShowSaveBtn(false);
          setShowSaveLoader(false);
          dispatch(fetchAvailabilityData(pageIntegrationId));
        })
        .catch((err) => {
          pushTheToast({
            type: "danger",
            text: "Something went wrong!",
            position: "top-right",
          });
          setShowSaveBtn(true);
          setShowSaveLoader(false);
        });
    }
  };

  const handleCancelButton = () => {
    if (availabilityData !== null) {
      // setCanCustomerStartChatAlways(
      //   availabilityData.canCustomerStartChatAlways
      // );
      // setCanCustomerViewChatHistory(
      //   availabilityData.canCustomerViewChatHistory
      // );
      const availableAtData = availabilityData.agentAvailableAt.map(
        (availableAt) => {
          return {
            ...availableAt,
            id: uuidv4(),
          };
        }
      );
      setAgentAvailableAtData(availableAtData);
      setShowSaveBtn(false);
      onHide();

      pushTheToast({
        position: "top-right",
        type: "success",
        text: "Changes Discarded !",
      });
    }
  };
  const handleAddAgentTimeSlot = () => {
    if (availableDayOptions.availableDayOptionKeys.length === 0) {
      pushTheToast({
        position: "top-right",
        type: "danger",
        text: "Something went wrong!",
      });
      return false;
    }
    const availableDay =
      availableDayOptions.availableDayOptions[
        Object.keys(availableDayOptions.availableDayOptions)[0]
      ]["key"];
    const previousElement =
      agentAvailableAtData[agentAvailableAtData.length - 1];
    const newSlot = {
      id: uuidv4(),
      availableDay: availableDay,
      availableAtTo: "00:00",
      availableAtFrom: "01:00",
      timezone:
        previousElement !== undefined
          ? previousElement.timezone
          : timezoneListData.data[0].timezone ?? "America/Los_Angeles",
    };
    setAgentAvailableAtData((state: any) => {
      const list = [...state, newSlot];
      return list;
    });
  };

  const handleFetchTimeZoneList = () => {
    if (
      fetchTimezoneListAjaxStatus !== "fulfilled" &&
      fetchTimezoneListAjaxStatus !== "pending"
    ) {
      dispatch(fetchTimeZoneListData());
    }
  };

  const getAvailabilityDate = useCallback(
    (datetimeGMT: string) => {
      if (!isDateStringValid(datetimeGMT)) {
        return "Mon, 10:40 am";
      }

      const convertDateTime = convertGmtToUserTimezone(
        datetimeGMT,
        userTimezone
      );
      const datetime = new Date(convertDateTime);
      return getDayAndTime(datetime);
    },
    [userTimezone]
  );

  return (
    <div className="d-flex flex-wrap">
      <div className={` ${styles.Availability} `}>
        <div>
          <h1 className={`${styles.heading}`}>Availabilty</h1>
          <p className={`${styles.subHeading}`}>
            Add the business hours when the store will be online. You can
            configure what to send when the team is offline in
            <NavLink
              to={`/live-chat/settings/${pageIntegrationId}/mail/offline`}
              className="mx-1"
            >
              agent not available
            </NavLink>
            setting.
          </p>
        </div>
        <div>
          <div>
            <p className={`${styles.timeheading} mt-1`}>
              Set business hours to expect a reply :
            </p>
          </div>
          {agentAvailableAtData.map(
            (agentAvailableAt: IAgentAvailableAtData, index: number) => {
              return (
                <AgentTimeSlot
                  key={agentAvailableAt.id}
                  index={index}
                  availableDayOptions={availableDayOptions}
                  agentAvailableAtData={agentAvailableAt}
                  setAgentAvailableAtData={setAgentAvailableAtData}
                />
              );
            }
          )}
        </div>
        <div className="mb-5 mt-2">
          <p className={`${styles.addHours}`}>
            <span className="me-1">
              <i className="fa-solid fa-plus"></i>
            </span>
            <span onClick={handleAddAgentTimeSlot}>Add more hours</span>
          </p>
        </div>
        <Modal
          backdropClassName={`${styles.modalBack}`}
          show={showModal}
          onHide={onHide}
          dialogClassName={`${styles.modalDialog}`}
          contentClassName={`${styles.modalContent}`}
          //   centered={true}
          enforceFocus={false}
        >
          <>
            <div className={`container`}>
              <div className={`row`}>
                <div className={`col-12`}>
                  <div className="text-center">
                    <p className={`${styles.delPara}`}>
                      Are you sure you want to cancel the changes you have made?
                    </p>
                    <span className={`${styles.delSpan}`}></span>
                    <div
                      className={`mt-4 pt-2 d-flex justify-content-center align-items-center flex-column flex-md-row`}
                    >
                      <button
                        className={`me-md-4 ${styles.cancelModalBtn}`}
                        onClick={onHide}
                      >
                        No
                      </button>
                      <button
                        className={`mt-2 mt-md-0 ${styles.confirmBtn}`}
                        onClick={handleCancelButton}
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </Modal>
        {showSaveBtn && (
          <div
            className={`mt-4 text-start text-md-end bg-white ${styles.btnSave}`}
          >
            <button
              type="button"
              className={`mb-2 mb-md-0 ${styles.cancelBtn}`}
              onClick={(e) => setShowModal(true)}
            >
              Cancel
            </button>
            <button
              className={`${styles.saveBtn}`}
              disabled={showSaveLoader === true}
              onClick={handleEditAvailabilityData}
            >
              {showSaveLoader === true ? (
                <Spinner
                  className="my-auto mx-1"
                  animation="border"
                  color="white"
                  size="sm"
                />
              ) : (
                "Save changes"
              )}
            </button>
          </div>
        )}
      </div>
      <div className="col-md-5 mt-2">
        <div className={`mt-4 mx-auto ${styles.teamHeading} py-2`}>
          <div className="d-flex justify-content-center">
            <div>
              <div className={`${styles.teamOnline}`}>
                <span
                  className={
                    showOnlineStatusText === true
                      ? `${styles.teamActive} ${styles.teamText}`
                      : `${styles.teamText}`
                  }
                  onClick={() => {
                    setShowOnlineStatusText(true);
                  }}
                >
                  Team Online
                </span>
              </div>
            </div>
            <div className={styles.border}></div>
            <div>
              <div className={`${styles.teamOffline}`}>
                <span
                  className={
                    showOnlineStatusText === false
                      ? `${styles.teamActive} ${styles.teamText}`
                      : `${styles.teamText}`
                  }
                  onClick={() => {
                    setShowOnlineStatusText(false);
                  }}
                >
                  Team Offline
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          {/* Live-chat-widget */}
          <FinalChat
            customizationData={{
              topbarHeadingText:
                customizationData?.topbarHeading ?? "Hi There !",
              topbarSubHeadingText:
                customizationData?.topbarSubHeading ??
                "Welcome to Acme support. How can we help you?",
              statusTextWhenTeamOnline: {
                readonly: "we generally reply in",
                value: "few minutes",
              },
              statusTextWhenTeamOffline: {
                readonly: "We'll be back at",
                value: getAvailabilityDate(
                  customizationData.statusTextWhenTeamOffline.value
                ),
              },
              autoreplayText: customizationData?.autoreplayText ?? "",
              showOnlineStatusText: showOnlineStatusText,
              minimizedWindowColour:
                customizationData?.appearances?.appearanceWindowColor?.value,
              liveChatThemeColor:
                customizationData?.appearances?.liveChatThemeColor?.value,
              showAutoReplyText: false,
              selectedWidgetPosition:
                customizationData?.appearances?.widgetPosition != null
                  ? customizationData?.appearances.widgetPosition == "right"
                    ? "right"
                    : "left"
                  : "right",
              submitButtonText:
                showOnlineStatusText === true
                  ? "Start a chat"
                  : "Send a message",
            }}
            show={{ homePage: true }}
          />
        </div>
      </div>

      {/* 
        commented V1 Availiablity
      <h3 className={`${styles.availHead}`}>
          Choose when customers can start a chat
        </h3>
        <div
          className={`${styles.choseBox} mb-3  ${status === "manual" ? styles.activeDiv : ""
            }`}
        >
          <div className={`d-flex align-items-center mb-2`}>
            <div className="form-check">
              <input
                className={`form-check-input`}
                data-lpignore="true"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
                onChange={(e) => {
                  setCanCustomerStartChatAlways(true);
                }}
                checked={canCustomerStartChatAlways}
                onClick={(e) => setStatus("manual")}
              />
            </div>
            <h5 className={`${styles.always}`}>Always</h5>
          </div>
          <div>
            <p className={`${styles.choseBoxPara}`}>
              Customers can still send a message even if you’re away
            </p>
            <p className={`${styles.choseBoxPara}`}>
              Full chat history is available for both agents and customers
            </p>
          </div>
        </div>
        <div
          className={`${styles.choseBox}  ${status === "auto" ? styles.activeDiv : ""
            }`}
        >
          <div className={`d-flex align-items-center mb-2`}>
            <div className="form-check">
              <input
                data-lpignore="true"
                className={`form-check-input`}
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
                onChange={(e) => {
                  setCanCustomerStartChatAlways(false);
                }}
                checked={!canCustomerStartChatAlways}
                onClick={(e) => setStatus("auto")}
              />
            </div>
            <h5 className={`${styles.always}`}>
              Only when agents are accepting chats
            </h5>
          </div>
          <div>
            <p className={`${styles.choseBoxPara}`}>
              When your team is not available, customers can fill out the{" "}
              <Link
                to={`/live-chat/settings/${pageIntegrationId}/ticketForm`}
                className={`text-decoration-none`}
              >
                ticket form
              </Link>
            </p>
            <p className={`${styles.choseBoxPara} d-flex`}>
              <div className="form-check">
                <input
                  data-lpignore="true"
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                  onChange={(e) => {
                    setCanCustomerViewChatHistory(!canCustomerViewChatHistory);
                  }}
                  checked={canCustomerViewChatHistory}
                />
              </div>
              Customers can see chat history
            </p>
          </div>
        </div> */}
    </div>
  );
};
export default Availability;
