import { axiosJSON } from "src/globals/axiosEndPoints";

export interface ISetUserTicketColumnPayload {
  agentId: number|string;
  columnsData: string;
}

export async function setUserTicketColumns(payload: ISetUserTicketColumnPayload) {
  const { data: res } = await axiosJSON.post(
    `/api/ticket/setUserTicketColumns`,
    payload
  );

  if (res.err === true) {
    throw new Error(res.msg as string);
  }

  return res;
}
