import { axiosJSON } from "src/globals/axiosEndPoints";
import { MailType } from "src/store/slices/emailIntegration/emailIntegration.slices";

export interface DomainAuthData {
  isVerified: boolean;
  recordType: string;
  host: string;
  value: string;
  currentValue: null;
}

export type IncomingRecieveType = "currentHost" | "IMAP" | "gmail";

export interface IncomingMailSettings {
  receiveType: IncomingRecieveType;
  imapDetails?: {
    imapHost: string;
    imapPort: number | string;
    imapUseSSL: boolean;
  };
  loginDetails?: {
    username: string;
    password: string;
  };
  preferredReceiveType: IncomingRecieveType;
}

export type OutgoingRecieveType =
  | "currentHost"
  | "gmail"
  | "currentHostWithDNS"
  | "SMTP";

export interface OutgoingMailSettings {
  outgoingType: OutgoingRecieveType;
  smtpDetails?: {
    smtpHost: string;
    smtpPort: number | string;
    smtpUseSSL: boolean;
  };
  loginDetails?: {
    username: string;
    password: string;
  };
  outgoingEmailAddress?: string;
  preferredReceiveType: OutgoingRecieveType;
  integrations?: {[key: string]: { verified?: boolean}}
}

export interface GetEmailIntegrationById {
  id: number;
  emailName: string;
  emailAddress: string;
  // Step 1 Completed
  isEmailAdded: boolean;
  // Step 2 Completed
  isEmailVerified: boolean;
  // Step 3 Completed
  isDomainVerified: boolean;
  // Step 2 completed but can we go to step 3
  isDomainAddedForAuth: boolean;
  // Step 3 table data
  domainAuthData: Array<DomainAuthData>;
  emailIntegrationChannel: string;
  mailType: MailType;
  // Incoming Email Default mail
  defaultEmail: string;
  incomingMailSettings: IncomingMailSettings;
  outgoingMailSettings: OutgoingMailSettings;
  isIncommingGmailVerified?: boolean;
}

export interface GetEmailIntegrationByIdParams {
  emailIntegrationId: number;
}

export async function getEmailIntegrationById(
  params: GetEmailIntegrationByIdParams,
) {
  const { data } = await axiosJSON.post(
    `/api/setting/integration/getEmailIntegrationById`,
    params,
  );

  if (data.err === true || data.error === true) {
    throw new Error(data.msg as string);
  }

  return data.data as GetEmailIntegrationById;
}
