import { axiosJSON } from "src/globals/axiosEndPoints";

export interface GetHelpCenterPageDataParams{
  integrationId:number|string;
}

export async function getHelpCenterPageData(params: GetHelpCenterPageDataParams) {
  const { data } = await axiosJSON.post(
    `/api/chatSetting/getHelpCenterPageData`,
    params
  );
  if (data.error === true) {
    throw new Error(data.message as string);
  }
  return data.data;
}