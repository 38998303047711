import { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { deleteGoalSetting, DeleteGoalSettingParams } from "src/services/LiveChat/Settings/goals/deleteGoalSetting";
import { updateGoalSetting, updateGoalSettingParams } from "src/services/LiveChat/Settings/goals/updateGoalSetting";
import { fetchAllGoalSettingData, resetGoalSettingsData } from "src/store/slices/liveChatSetting/Goals/GoalSetting.slice";
import { useAppDispatch } from "src/store/store";
import DeleteGoal from "./DeleteGoal/DeleteGoal";
import styles from "./GoalRow.module.scss";

interface Props{
  goalData: any;
  handleDelete: any;
}
const GoalRow = ({goalData, handleDelete}:Props) => {

    const [showModal, setShowModal] = useState<boolean>(false);
    const [isActive, setIsActive] = useState(false);
    const navigate = useNavigate();
  
    const onShow = useCallback(() => {
      setShowModal(true);
    }, []);


  const handleNavigation = ()=>{
    if(goalData !== null){
      navigate('/live-chat/settings/'+goalData.integrationId+'/goals/'+goalData.goalId);
    }
  }
  useEffect(()=>{
    if(goalData !== null){
      setIsActive(goalData.isActive);
    }
  },[goalData]);


  const handleOnChangeActive = () => {

    if(goalData !== null){


      let payload: updateGoalSettingParams = {
        goalId: goalData.goalId,
        integrationId: goalData.integrationId,
        isActive : !isActive
      };


      // delete the team
      updateGoalSetting(payload)
        .then((res) => {
          // setTimeout(() => {
          //   pushTheToast({
          //     type: "success",
          //     text: "Goal deleted successfully!",
          //     position: "top-right"
          //   });
          // }, 0);
        setIsActive(!isActive);
        })
        .catch((err) => {
          pushTheToast({
            type: "danger",
            text: "somethiing went wrong!",
            position: "top-right"
          });
        });
    }
  };

  return (
    <>
      <div className={`${styles.tableRow}`}>
        <div className={`d-flex ${styles.widthSet1}`}>
          <div className="form-check form-switch me-2">
            <input className={`form-check-input`} type="checkbox" checked={isActive} onChange={handleOnChangeActive}/>
          </div>
        </div>
        <div className={`d-flex  ${styles.widthSet2}`}>
          <span className={`${styles.rowName}`}>{goalData.goalName}</span>
        </div>
        <div className={`d-flex ${styles.widthSet3}`}>
          <div className={`d-flex justify-content-between w-100`}>
            <div>
              <span className={`${styles.seeSpan}`}>Reports</span>
              <span className="mx-2">|</span>
              <span className={`${styles.seeSpan}`}>Archives</span>
            </div>
            <div>
              <div className="dropdown">
                <button
                  className={`dropdown-toggle ${styles.dropBtn}`}
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="fa-solid fa-ellipsis"></i>
                </button>
                <ul
                  className={`dropdown-menu ${styles.dropBox}`}
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <a className={`dropdown-item ${styles.details}`} href="#" onClick={handleNavigation}>
                      Details
                    </a>
                  </li>
                  <li>
                    <a className={`dropdown-item ${styles.delete}`} href="#" onClick={(e)=>handleDelete({goalId:goalData.goalId})}>
                      Delete
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </>
  );
};
export default GoalRow;
