import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import {
  IUpdateChatSettingParams,
  updateChatSetting,
} from "src/services/LiveChat/Settings/updateChatSettingData";
import { fetchChatSettingData } from "src/store/slices/liveChatSetting/chatWidgetSetting/chatSetting/chatSetting.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import styles from "./ForwardChat.module.scss";
const ForwardChat = () => {
  const [emails, setEmails] = useState<string>("");
  const dispatch = useAppDispatch();
  const [checkEmail, setCheckEmail] = useState<boolean>(false);

  const { integrationId } = useParams();
  const pageIntegrationId = integrationId
    ? decodeURIComponent(integrationId)
    : "";

  useEffect(() => {
    if (
      chatSettingData !== null &&
      chatSettingData.transcriptForwarding !== emails
    ) {
      setShowSaveChange(true);
    } else {
      setShowSaveChange(false);
    }
  }, [emails]);

  const { chatSettingData, chatSettingDataAjaxStatus } = useAppSelector(
    (state) => state.liveChatSetting
  );
  const [showSaveChange, setShowSaveChange] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    dispatch(fetchChatSettingData(pageIntegrationId));
  }, [false]);

  useEffect(() => {
    if (chatSettingData !== null) {
      setEmails(chatSettingData.transcriptForwarding);
    }
  }, [chatSettingData]);

  const isEmailsValid = (emailList: any) => {
    for (let i = 0; i < emailList.length; i += 1) {
      const regEx = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
      if (!regEx.test(emailList[i].trim())) {
        return false;
      }
    }
    return true;
  };

  const handleSave = () => {
    if (chatSettingData !== null && chatSettingDataAjaxStatus !== "pending") {
      setLoader(true);

      //validing emails
      let trimedEmails = emails.trim();

      if (trimedEmails !== "") {
        let emailList = trimedEmails.split(",");
        if (!isEmailsValid(emailList)) {
          pushTheToast({
            type: "warning",
            text: "Please Enter Valid Emails!",
            position: "top-right",
          });
          setCheckEmail(true);
          setLoader(false);
          return;
        }
      }

      let payload: IUpdateChatSettingParams = {
        transcriptForwarding: emails,
      };

      updateChatSetting({
        integrationId: pageIntegrationId,
        chatSetting: payload,
      })
        .then((res) => {
          pushTheToast({
            type: "success",
            text: "Successfully changed",
            position: "top-right",
          });
          setLoader(false);
          setCheckEmail(false);
          dispatch(fetchChatSettingData(pageIntegrationId));
          setShowSaveChange(false);
        })
        .catch((err) => {
          pushTheToast({
            type: "danger",
            text: "Something went wrong!",
            position: "top-right",
          });
        });
    }
  };

  const handleDiscard = () => {
    if (chatSettingData !== null) {
      setEmails(chatSettingData.transcriptForwarding + "");
    } else {
      setEmails("");
    }
    setShowSaveChange(false);
  };

  const style = {
    border: checkEmail === true ? "1px solid #A70B0B" : "1px solid #000",
  };

  return (
    <div className={`${styles.forwardChat}`}>
      <h1 className={`mb-3 ${styles.forwardHeading}`}>Transcript Forwarding</h1>
      <h2 className={`${styles.forwardHead}`}>
        Forward All Chat Transcript To E-Mail:
      </h2>
      <p className={`${styles.forwardSpan}`}>
        You can enter as many e-mail addresses separated by a comma
      </p>
      <input
        placeholder={`you@example.com`}
        data-lpignore="true"
        className={`w-100 ${styles.forwardInput}`}
        type={"text"}
        value={emails === null || emails === undefined ? "" : emails}
        onChange={(e) => {
          setEmails(e.target.value);
          setCheckEmail(false);
        }}
        style={style}
      />
      {checkEmail === true ? (
        <span className={`text-danger ms-2 ${styles.textvalidation}`}>
          Please enter a valid Email
        </span>
      ) : (
        ""
      )}
      {/* <span className={`text-danger ms-2 ${styles.textvalidation}`}>Please enter a valid Email</span> */}
      <div>
        <button
          className={`px-3 py-1 ${styles.addRuleBtn} mt-3`}
          disabled={showSaveChange === false}
          onClick={handleSave}
        >
          {loader === true ? (
            <Spinner
              className="my-auto mx-1"
              animation="border"
              color="white"
              size="sm"
            />
          ) : (
            "Save changes"
          )}
        </button>
        {showSaveChange && (
          <button
            className={`px-3 py-1 ${styles.discardBtn} mt-3 ms-0 ms-md-1`}
            onClick={handleDiscard}
          >
            Discard
          </button>
        )}
      </div>
    </div>
  );
};

export default ForwardChat;
