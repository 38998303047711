import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  getTicketsV2,
  TicketsQuery,
} from "src/services/TicketService/getTicketsV2";

const initialPayload: TicketsQuery = {
  start: 0,
  limit: 20,
  filters: { tableType: "mentions" },
  columns: [
    "ticket_id",
    "ticket_subject",
    "last_message",
    "last_message_formatted",
    "last_message_date_gmt",
    "last_message_date",
    "is_read",
    "last_mention_message",
    "unread_mention_count",
    "ticket_brand",
    "ticket_channel",
    "ticket_customer_details",
  ],
};

function useChatPagination({
  tableType,
  linkedTicketId,
  callbackRefs,
}: {
  tableType: "mentions" | "all";
  linkedTicketId?: string | number;
  callbackRefs?: React.MutableRefObject<{
    addTicket: (ticketData: any) => void;
  }>;
}) {
  const [payload, setPayload] = useState({
    ...initialPayload,
    filters: { tableType, linkedTicketId },
  });
  const [chats, setChats] = useState<any[]>([]);

  useMemo(() => {
    setPayload({ ...initialPayload, filters: { tableType, linkedTicketId } });
    setChats([]);
  }, [tableType, linkedTicketId]);

  const { data, isError, isLoading, status } = useQuery(
    ["internalChatBot/getMentionTicket", payload],
    {
      queryFn: async () => {
        const res = await getTicketsV2(payload, true, "ticket-view");
        return res;
      },
      staleTime: 60000,
      cacheTime: 60000,
    },
  );

  const addTicketData = useCallback((ticketData: any) => {
    setChats((prev) => {
      const allTicketIds = prev.map((data) => data.ticket_id + "");
      if (!allTicketIds.includes(ticketData.ticket_id + "")) {
        return [ticketData, ...prev];
      } else {
        return prev;
      }
    });
  }, []);

  useMemo(() => {
    if (callbackRefs) {
      callbackRefs.current.addTicket = addTicketData;
    }
  }, [addTicketData]);

  useEffect(() => {
    if (data && status === "success") {
      const fetchTicketData: any[] = (data?.ticketMeta?.ticketIds ?? [])
        .map((id) => (data.tickets ?? {})[id])
        .filter((v) => v);
      setChats((prevState) => [...prevState, ...fetchTicketData]);
    }
  }, [data, status]);

  //Calculate the currentPage and totalPages for pagination
  const currentPage = Math.floor(payload.start! / payload.limit!) + 1;

  // Change the current page and trigger a refetch
  const changePage = useCallback(() => {
    setPayload({
      ...payload,
      start: (payload.start ?? 0) + (payload.limit ?? 0),
    });
  }, [payload]);

  return {
    currentPage,
    changePage,
    chats,
    hasNextPage: data?.ticketMeta.count === payload.limit,
    isLoading,
    isError,
    totalChats: data?.ticketMeta?.totalCount,
  };
}

export default useChatPagination;
