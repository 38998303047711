import { useEffect, useMemo, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import AllViewModal from "src/components/AllViewModal/AllViewModal";
import SearchBar from "src/components/SearchBar/SearchBar";
import { setSearchData } from "src/store/slices/ticketGlobalSearch/ticketGlobalSearch.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import AddTicket from "../AddTicket/AddTicket";
import TransferTicket from "../TransferTicket/TransferTicket";
import "./Header.css";

function Header({ count, name }: { count: string; name: string }) {
  const dispatch = useAppDispatch();
  const currentUserData = useAppSelector(
    (state) => state.globals.currentUserData
  );
  const { tableType }: any = useParams();
  const searchText = useAppSelector(
    (state) => state.ticketGlobalSearch.searchText
  );
  const [searchValue, setSearchValue] = useState(searchText ?? "");
  const currentState = useRef({
    searchText,
    tableType,
  });

  useEffect(() => {
    currentState.current = {
      searchText,
      tableType,
    };
  }, [searchText, tableType]);

  useEffect(() => {
    setSearchValue(searchText);
  }, [searchText]);

  const [show, setShow] = useState(false);
  const viewId = tableType.includes("viewId")
    ? parseInt(tableType.split("::")[1])
    : null;
  const onHide = () => {
    setShow(false);
  };

  return (
    <div>
      <div className="btnBox" id="headerSection">
        <div className="countNameMainDiv">
          <span className="ticketButton_head viewNameText">{name}</span>
          <span className="countSpan">
            <span className="ticketButton_sub">{count}</span>

            {viewId && (
              <>
                <span
                  className="me-1 ticketButton_icon"
                  role="button"
                  onClick={(e) => setShow(true)}
                >
                  <svg
                    id="Iconly_Bold_Edit-Square"
                    data-name="Iconly/Bold/Edit-Square"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                  >
                    <g id="Edit-Square">
                      <path
                        id="Path_245"
                        data-name="Path 245"
                        d="M11.732.008a4.259,4.259,0,0,1,3.141,1.111,4.3,4.3,0,0,1,1.119,3.149h0v7.464a4.3,4.3,0,0,1-1.111,3.149,4.277,4.277,0,0,1-3.149,1.111H4.268a4.265,4.265,0,0,1-3.149-1.111A4.265,4.265,0,0,1,.008,11.732h0V4.268A4.265,4.265,0,0,1,1.119,1.119,4.265,4.265,0,0,1,4.268.008h7.464ZM11.524,3.66a1.264,1.264,0,0,0-1.79,0h0L9.2,4.2a.212.212,0,0,0,0,.3l.044.043.2.2.4.4.483.483c.1.1.168.169.173.176a.541.541,0,0,1,.144.368.527.527,0,0,1-.527.527.5.5,0,0,1-.352-.144h0L8.424,5.219a.174.174,0,0,0-.24,0h0L4.372,9.031a1.439,1.439,0,0,0-.424.991h0L3.9,11.916a.374.374,0,0,0,.1.272.381.381,0,0,0,.272.112H6.154a1.479,1.479,0,0,0,1.031-.424h0l5.378-5.394a1.269,1.269,0,0,0,0-1.782h0Z"
                        transform="translate(0 0)"
                        fill="#707070"
                      />
                    </g>
                  </svg>
                </span>
                <AllViewModal show={show} onHide={onHide} viewId={viewId} />
              </>
            )}
          </span>
        </div>
        <div
          className={`d-flex align-items-center flex-wrap buttonDiv`}
          id="btnWrapper"
        >
          <SearchBar
            id={`inner-ticket-search`}
            className={`search px-2 me-2 mb-2`}
            inputClassName={`searchInput`}
            placeholder={`Search by ticket id, customer name or email`}
            onSearch={(value: any) => {
              dispatch(
                setSearchData({
                  searchText: value,
                  tableType: currentState.current.tableType,
                })
              );
            }}
            onChange={(e: any) => {
              setSearchValue(e.target.value);
            }}
            value={searchValue}
          />
          {currentUserData &&
            currentUserData.scopes &&
            currentUserData.scopes.includes("transfer_ticket") && (
              <TransferTicket />
            )}
          {currentUserData &&
            currentUserData.scopes &&
            currentUserData.scopes.includes("add_ticket") && <AddTicket />}
        </div>
      </div>
    </div>
  );
}

export default Header;
