import { RootState } from "src/store/store";

export function isAgentIdSelected(state: RootState, agentId: number|string) {
  return state.brandSettings.bulkSelectedUserIds.includes(agentId);
}

export function areAllUsersSelected(state: RootState): boolean {
  if (state.brandSettings.userIds.length > 0) {
    const allUserIds = state.brandSettings.userIds;
    const allSelectedUserIds = state.brandSettings.bulkSelectedUserIds;

    return (
      allUserIds.length !== 0 &&
      allUserIds.every((u) => allSelectedUserIds.includes(u))
    );
  } else {
    return false;
  }
}

export function getBrandSelectedUserTicketCounts(state: RootState): number {
    const allUserIds = state.brandSettings.bulkSelectedUserIds;

    let totalTicketsCount = 0;

    allUserIds.forEach((userId)=>{
      if(state.brandSettings.users[userId] !== undefined){
        //checking if user status is enabled
        const user = state.brandSettings.users[userId];

        let ticketCount = user.ticketCount ?? 0;

        totalTicketsCount += parseInt(ticketCount+"");

      }
    });

    return totalTicketsCount;
}

export function isAllSelectedBrandAgentDisabled(state: RootState): boolean {
  const allUserIds = state.brandSettings.bulkSelectedUserIds;

  let isAllDisabled = true;

  let checkedUserIds:Array<string|number> = [];

  allUserIds.forEach((userId)=>{

    let alreadyCheckedOnModifiedUser = false;
    //checking the modified user list first
    if(state.brandSettings.modifiedUsersList[userId] !== undefined){
      checkedUserIds.push(userId);
      alreadyCheckedOnModifiedUser = true;
      if(state.brandSettings.modifiedUsersList[userId].enabled === true){
        isAllDisabled = false;
        return false;
      }
    }

    if((!alreadyCheckedOnModifiedUser) && (!checkedUserIds.includes(userId))){

      //checking the api response user list
      if(state.brandSettings.users[userId] !== undefined){
        const user = state.brandSettings.users[userId];
        
        if(user.enabled === true){
          isAllDisabled = false;
          return false;
        }
        
      }
    }
  });

  
  return isAllDisabled;
}
export function isAllSelectedBrandAgentEnabled(state: RootState): boolean {
  const allUserIds = state.brandSettings.bulkSelectedUserIds;

  let isAllEnabled = true;

  let checkedUserIds:Array<string|number> = [];

  allUserIds.forEach((userId)=>{

    let alreadyCheckedOnModifiedUser = false;
    //checking the modified user list first
    if(state.brandSettings.modifiedUsersList[userId] !== undefined){
      checkedUserIds.push(userId);
      alreadyCheckedOnModifiedUser = true;
      if(state.brandSettings.modifiedUsersList[userId].enabled === false){
        isAllEnabled =  false;
        return false;
      }
    }

    if((!alreadyCheckedOnModifiedUser) && (!checkedUserIds.includes(userId))){

      //checking the api response user list
      if(state.brandSettings.users[userId] !== undefined){
        const user = state.brandSettings.users[userId];
        
        if(user.enabled === false){
          isAllEnabled = false;
          return false;
        }
        
      }
    }
  });

  
  return isAllEnabled;
}

export function getBrandReassignableAgentIds(state: RootState): Array<number|string> {
  
  //getting bulk selected users
  const selectedUserIds = state.brandSettings.bulkSelectedUserIds;
 
  //getting disabled user ids from modified list
  let disabledModifiedUser = Object.values(state.brandSettings.modifiedUsersList).filter((user)=>{
    if(user.enabled === false){
      return true;
    }
      return false;
  });

  let disabledModifiedUserIds = disabledModifiedUser.map((user)=>user.id);
  //getting disabled user ids from api response
  let alreadyDisabledUserIds: Array<string|number> = [];
  if(state.brandSettings.brands[state.brandSettings.activeBrandId]){
    alreadyDisabledUserIds = state.brandSettings.brands[state.brandSettings.activeBrandId].disabledUserIds; 
  }else{
    alreadyDisabledUserIds = [];
  }

  const disabledUserIds:any =  Array.from(new Set([
    ...disabledModifiedUserIds, ...selectedUserIds, ...alreadyDisabledUserIds]));  

  return disabledUserIds;
}

export function getBrandModifiedAssignToUsersIds (state: RootState): Array<number|string> {
  const assignToUsers = Object.values(state.brandSettings.modifiedUsersList).filter((user)=>user.assignTo !== null && user.ticketCount !== 0);


  return assignToUsers.map((user)=>user.assignTo) as any;

};