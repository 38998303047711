import React from "react";
import styles from "./AlarmTime.module.scss";
interface Props {
  log: string;
  dateTime: string;
  active: boolean;
  onClick: React.MouseEventHandler<HTMLDivElement>;
}
const AlarmTime = ({ log, dateTime, onClick, active }: Props) => {
  return (
    <div
      onClick={onClick}
      className={`d-flex justify-content-between my-2 px-2 py-3 ${
        styles.hoverDiv
      } ${active ? styles.active : ""}`}
    >
      <p className={`${styles.log} mb-0`}>{log}</p>
      <p className={`${styles.dateTime} mb-0`}>{dateTime}</p>
    </div>
  );
};
export default AlarmTime;
