import { axiosJSON } from "src/globals/axiosEndPoints";
import { ConditionType, StepConfigParams } from "../Step9/step9GetConfig";

interface ReturnMethodType {
  name: string;
  key: string;
  enabled: boolean;
  value: string;
}

export interface Step14TabType {
  configTabId: number;
  configTabName: string;
  conditions: ConditionType[];
  notEligibleResponseMessage: string;
  returnMethods: [ReturnMethodType, ReturnMethodType];
}

export interface Step14Config {
  data: Step14TabType[];
}

export async function fetchGetStep14Config(params: StepConfigParams) {
  const { data } = await axiosJSON.get(
    "/api/setting/automation/refund/step/14/configuration",
    { params }
  );

  if (data.err === true) {
    throw new Error("Some Error Occurred while fetching data");
  }

  return data.data;
}
