import { generate24TimeOnly, generateDateOnly } from "src/utils/dateLibrary";
import { formatDate, isObjOrStrEmpty } from "src/utils/utils";
import { AddViewPayload } from "./addView.axios";

export default function prepareQuery(rawData: any) {
  const {
    viewName,
    brand,
    channel,
    tags,
    createdOn,
    createdOnValue,
    assignedTo,
    closedOn,
    closedOnValue,
    draftStatus,
    ticketStatus,
  } = rawData;
  const addViewPayload: AddViewPayload = { viewName, options: [] };

  if (!isObjOrStrEmpty(brand)) {
    addViewPayload.options.push({
      key: "viewTBrand",
      value: getValue(brand),
    });
  }

  if (!isObjOrStrEmpty(channel)) {
    addViewPayload.options.push({
      key: "viewTChannel",
      value: getValue(channel),
    });
  }

  if (!isObjOrStrEmpty(tags)) {
    addViewPayload.options.push({
      key: "viewTTags",
      value: getValue(tags),
    });
  }

  if (!isObjOrStrEmpty(draftStatus)) {
    addViewPayload.options.push({
      key: "viewTDraftStatus",
      value: getValue(draftStatus),
    });
  }

  if (!isObjOrStrEmpty(ticketStatus)) {
    addViewPayload.options.push({
      key: "viewTTicketStatus",
      value: getValue(ticketStatus),
    });
  }

  if (!isObjOrStrEmpty(assignedTo)) {
    addViewPayload.options.push({
      key: "viewTAssignedTo",
      value: getValue(assignedTo),
    });
  }

  if (!isObjOrStrEmpty(createdOn)) {
    let value = "";
    if (["today", "last-7-days", "last-30-days"].includes(createdOn.compare)) {
      value = `${createdOn.compare}::null`;
    } else if (["after", "before"].includes(createdOn.compare)) {
      let date = generateDateOnly(new Date(createdOnValue.startDate));
      let time =
        createdOnValue.startTime == null
          ? "00:00:00"
          : generate24TimeOnly(new Date(createdOnValue.startTime));
      value = `${createdOn.compare}::${date} ${time}`;
    } else {
      // for "between" selected value
      let startDate = generateDateOnly(new Date(createdOnValue.startDate));
      let startTime =
        createdOnValue.startTime == null
          ? "00:00:00"
          : generate24TimeOnly(new Date(createdOnValue.startTime));
      let endDate = generateDateOnly(new Date(createdOnValue.endDate));
      let endTime =
        createdOnValue.endTime == null
          ? "00:00:00"
          : generate24TimeOnly(new Date(createdOnValue.endTime));
      value = `${createdOn.compare}::${startDate} ${startTime} to ${endDate} ${endTime}`;
    }
    addViewPayload.options.push({
      key: "viewTCreatedOn",
      value,
    });
  }

  if (!isObjOrStrEmpty(closedOn)) {
    let value = "";
    if (["today", "last-7-days", "last-30-days"].includes(closedOn.compare)) {
      value = `${closedOn.compare}::null`;
    } else if (["after", "before"].includes(closedOn.compare)) {
      let date = generateDateOnly(new Date(closedOnValue.startDate));
      let time =
        closedOnValue.startTime == null
          ? "00:00:00"
          : generate24TimeOnly(new Date(closedOnValue.startTime));
      value = `${closedOn.compare}::${date} ${time}`;
    } else {
      // for "between" selected value
      let startDate = generateDateOnly(new Date(closedOnValue.startDate));
      let startTime =
        closedOnValue.startTime == null
          ? "00:00:00"
          : generate24TimeOnly(new Date(closedOnValue.startTime));
      let endDate = generateDateOnly(new Date(closedOnValue.endDate));
      let endTime =
        closedOnValue.endTime == null
          ? "00:00:00"
          : generate24TimeOnly(new Date(closedOnValue.endTime));
      value = `${closedOn.compare}::${startDate} ${startTime} to ${endDate} ${endTime}`;
    }
    addViewPayload.options.push({
      key: "viewTClosedOn",
      value,
    });
  }

  return addViewPayload;
}

export function prepareData(query: any) {
  const ret: any = {
    viewName: query.viewName,
  };
  query.options.forEach((element: any) => {
    if (element.key === "viewTBrand") {
      ret.brand = element.idNName.map((value: any) => {
        return {
          id: parseInt(value.id),
          name: value.name,
          notFetched: true,
        };
      });
    }
    if (element.key === "viewTChannel") {
      ret.channel = element.idNName.map((value: any) => {
        return {
          id: parseInt(value.id),
          name: value.name,
          notFetched: true,
        };
      });
    }
    if (element.key === "viewTTags") {
      ret.tags = element.idNName.map((value: any) => {
        return {
          id: parseInt(value.id),
          name: value.name,
          notFetched: true,
        };
      });
    }
    if (element.key === "viewTDraftStatus") {
      ret.draftStatus = element.idNName.map((value: any) => {
        return {
          id: parseInt(value.id),
          name: value.name,
          notFetched: true,
        };
      });
    }
    if (element.key === "viewTTicketStatus") {
      ret.ticketStatus = element.idNName.map((value: any) => {
        return {
          id: parseInt(value.id),
          name: value.name,
          notFetched: true,
        };
      });
    }
    if (element.key === "viewTAssignedTo") {
      ret.assignedTo = element.idNName.map((value: any) => {
        return {
          id: parseInt(value.id),
          name: value.name,
          notFetched: true,
        };
      });
    }
    if (element.key === "viewTCreatedOn") {
      let on = element.value.split("::");
      ret.createdOn = {
        compare: on[0],
        notFetched: true,
        name: on[0]
          .split("-")
          .map((str: any) => str[0].toUpperCase() + str.slice(1))
          .join(" "),
        isValueRequired: "not",
        defaultValue: "not",
        showDateTimePicker: "not",
      };
      ret.createdOnValue = {
        startDate: null,
        startTime: null,
        endDate: null,
        endTime: null,
      };
      if (on[1] !== "null") {
        let dates = on[1].split(" to ");
        ret.createdOnValue.startDate = new Date(dates[0]).toISOString();
        ret.createdOnValue.startTime = new Date(dates[0]).toISOString();
        if (dates.length === 2) {
          ret.createdOnValue.endDate = new Date(dates[1]).toISOString();
          ret.createdOnValue.endTime = new Date(dates[1]).toISOString();
        }
      }
    }
    if (element.key === "viewTClosedOn") {
      let on = element.value.split("::");
      ret.closedOn = {
        compare: on[0],
        notFetched: true,
        name: on[0]
          .split("-")
          .map((str: any) => str[0].toUpperCase() + str.slice(1))
          .join(" "),
        isValueRequired: "not",
        defaultValue: "not",
        showDateTimePicker: "not",
      };
      ret.closedOnValue = {
        startDate: null,
        startTime: null,
        endDate: null,
        endTime: null,
      };
      if (on[1] !== "null") {
        let dates = on[1].split(" to ");
        ret.closedOnValue.startDate = new Date(dates[0]).toISOString();
        ret.closedOnValue.startTime = new Date(dates[0]).toISOString();
        if (dates.length === 2) {
          ret.closedOnValue.endDate = new Date(dates[1]).toISOString();
          ret.closedOnValue.endTime = new Date(dates[1]).toISOString();
        }
      }
    }
  });
  return ret;
}

export function prepareDataIntoFilters(query: any) {
  const ret: any = {
    // viewName: query.viewName,
  };
  query.options.forEach((element: any) => {
    if (element.key === "viewTBrand") {
      ret.filterByBrands = element.idNName.map((value: any) => {
        return {
          id: parseInt(value.id),
          name: value.name,
          compare: "=",
          isValueRequired: true,
          viewFilter: true,
        };
      });
    }
    if (element.key === "viewTChannel") {
      ret.filterByChannels = element.idNName.map((value: any) => {
        return {
          id: parseInt(value.id),
          name: value.name,
          compare: "=",
          isValueRequired: true,
          viewFilter: true,
        };
      });
    }
    if (element.key === "viewTTags") {
      ret.filterByTags = element.idNName.map((value: any) => {
        return {
          id: parseInt(value.id),
          name: value.name,
          compare: "=",
          isValueRequired: true,
          viewFilter: true,
        };
      });
    }
    if (element.key === "viewTDraftStatus") {
      ret.filterByDraftStatus = element.idNName.map((value: any) => {
        return {
          id: parseInt(value.id),
          name: value.name,
          compare: "=",
          isValueRequired: true,
          viewFilter: true,
        };
      });
    }
    if (element.key === "viewTTicketStatus") {
      ret.filterByTicketStatus = element.idNName.map((value: any) => {
        return {
          id: parseInt(value.id),
          name: value.name,
          compare: "=",
          isValueRequired: true,
          viewFilter: true,
        };
      });
    }
    if (element.key === "viewTAssignedTo") {
      ret.filterByAgents = element.idNName.map((value: any) => {
        return {
          id: parseInt(value.id),
          name: value.name,
          compare: "=",
          isValueRequired: true,
          viewFilter: true,
        };
      });
    }
    if (element.key === "viewTCreatedOn") {
      let on = element.value.split("::");
      ret.filterByCreatedDate = {
        compare: on[0],
        isValueRequired: false,
        name: on[0]
          .split("-")
          .map((str: any) => str[0].toUpperCase() + str.slice(1))
          .join(" "),
        defaultValue: "null",
        showDateTimePicker: false,
        viewFilter: true,
      };
      ret.filterByCreatedDateValue = {
        startDate: null,
        startTime: null,
        endDate: null,
        endTime: null,
        selectsRange: false
      };
      if (on[1] !== "null") {
        ret.filterByCreatedDate.isValueRequired = true;
        ret.filterByCreatedDate.showDateTimePicker = true;
        
        let dates = on[1].split(" to ");
        ret.filterByCreatedDateValue.startDate = new Date(dates[0]).toISOString();
        ret.filterByCreatedDateValue.startTime = new Date(dates[0]).toISOString();
        if (dates.length === 2) {
          ret.filterByCreatedDateValue.selectsRange = true;
          ret.filterByCreatedDateValue.endDate = new Date(dates[1]).toISOString();
          ret.filterByCreatedDateValue.endTime = new Date(dates[1]).toISOString();
        }
      }
    }
    if (element.key === "viewTClosedOn") {
      let on = element.value.split("::");
      ret.filterByClosedDate = {
        compare: on[0],
        isValueRequired: false,
        name: on[0]
          .split("-")
          .map((str: any) => str[0].toUpperCase() + str.slice(1))
          .join(" "),
        defaultValue: "null",
        showDateTimePicker: false,
        viewFilter: true,
      };
      ret.filterByClosedDateValue = {
        startDate: null,
        startTime: null,
        endDate: null,
        endTime: null,
      };
      if (on[1] !== "null") {
        ret.filterByClosedDate.isValueRequired = true;
        ret.filterByClosedDate.showDateTimePicker = true;
        let dates = on[1].split(" to ");
        ret.filterByClosedDateValue.startDate = new Date(dates[0]).toISOString();
        ret.filterByClosedDateValue.startTime = new Date(dates[0]).toISOString();
        if (dates.length === 2) {
          ret.filterByClosedDateValue.selectsRange = true;
          ret.filterByClosedDateValue.endDate = new Date(dates[1]).toISOString();
          ret.filterByClosedDateValue.endTime = new Date(dates[1]).toISOString();
        }
      }
    }
  });
  return ret;
}
const getValue = (arrOfObjs: []) => {
  return arrOfObjs.map((obj: any) => obj.id).join();
};
