/**
 * This file is the custom hook file.
 * It contains useTeamsAndUsers custom hook.
 *
 * @author Jayalakshmi
 * @author Yash Aditya
 */

// Importing necessary dependencies and services
import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getUsersAndTeams } from "src/services/Settings/AgentNotifications/getUsersAndTeams.service";
import { getUsersToBeNotified } from "src/services/Settings/AgentNotifications/getUsersToBeNotified.service";
import {
  UpdateUsersPayload,
  addOrUpdateUsersToBeNotified,
} from "src/services/Settings/AgentNotifications/addOrUpdateUsersToBeNotified.service";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { AJAXSTATUS } from "src/globals/constants";
import useNotifySetPermission from "./useNotifySetPermission";

// Custom hook to manage teams and users state and actions
function useTeamsAndUsers() {
  const notifyPermission = useNotifySetPermission();
  // Creating states
  const [ddOpen, setDdOpen] = useState(false);
  const [ddLoading, setDdLoading] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([] as Array<string>);
  const [searchText, setSearchText] = useState("");
  const [statusSelectedUsers, setStatusSelectedUsers] = useState(
    "pending" as AJAXSTATUS,
  );
  const [loadingUpdateUsers, setLoadingUpdateUsers] = useState(false);

  // Fetch users and teams data using useQuery hook
  const { data: userAndTeams, status: userAndTeamsStatus } = useQuery(
    ["useTeamsAndUsers/getUsersAndTeams"],
    {
      queryFn: getUsersAndTeams,
      staleTime: 600000,
      cacheTime: 600000,
      // Handle error during data retrieval
      onError: (err) => {
        pushTheToast({
          type: "danger",
          text: "Something Went Wrong",
          position: "top-right",
        });
      },
    },
  );

  // Memoized array of selected agents to be displayed
  const showSelectedAgents = useMemo(() => {
    const ssa: Array<{
      label: string;
      imgURL?: string;
    }> = [];
    if (userAndTeams) {
      userAndTeams.teamIds.forEach((id) => {
        const user = userAndTeams.teams[id];
        if (user) {
          const userIds = user.userIds;
          let v = true;
          if (selectedUsers.length >= userIds.length && userIds.length > 0) {
            userIds.forEach((id) => {
              if (!selectedUsers.map((i) => i + "").includes(id + "")) {
                v = false;
              }
            });
          } else {
            v = false;
          }
          if (v) {
            ssa.push({ label: user.name, imgURL: user.imageUrl });
          }
        }
      });

      selectedUsers.forEach((id) => {
        if (userAndTeams.users[id]) {
          ssa.push({
            label:
              userAndTeams.users[id].name?.trim() ??
              userAndTeams.users[id].email,
            imgURL: userAndTeams.users[id].imageUrl?.trim()
              ? userAndTeams.users[id].imageUrl + ""
              : undefined,
          });
        }
      });
    }

    return ssa;
  }, [selectedUsers, userAndTeams]);

  // Memoized array of user IDs to render based on search criteria
  const agentIdsToRender = useMemo(() => {
    const ids: Array<string> = [];
    userAndTeams?.userIds?.forEach((id) => {
      const user = userAndTeams?.users[id];
      if (user) {
        if (
          user.role === "leadAgent" &&
          ((user.name ?? "")
            .toLowerCase()
            .includes(searchText.trim().toLowerCase()) ||
            user.email
              .toLowerCase()
              .includes(searchText.trim().toLowerCase()) ||
            (user.id + "")
              .toLowerCase()
              .includes(searchText.trim().toLowerCase()))
        ) {
          ids.push(user.id + "");
        }
      }
    });
    return ids;
  }, [searchText.trim(), userAndTeams]);

  // Memoized array of user IDs to render based on search criteria
  const adminIdsToRender = useMemo(() => {
    const ids: Array<string> = [];
    userAndTeams?.userIds?.forEach((id) => {
      const user = userAndTeams?.users[id];
      if (user) {
        if (
          user.role === "admin" &&
          ((user.name ?? "")
            .toLowerCase()
            .includes(searchText.trim().toLowerCase()) ||
            user.email
              .toLowerCase()
              .includes(searchText.trim().toLowerCase()) ||
            (user.id + "")
              .toLowerCase()
              .includes(searchText.trim().toLowerCase()))
        ) {
          ids.push(user.id + "");
        }
      }
    });
    return ids;
  }, [searchText.trim(), userAndTeams]);

  // Memoized array of team IDs to render based on search criteria
  const teamIdsToRender = useMemo(() => {
    const ids: Array<string> = [];
    userAndTeams?.teamIds?.forEach((id) => {
      const team = userAndTeams?.teams[id];
      if (team) {
        if (
          team.name.toLowerCase().includes(searchText.trim().toLowerCase()) ||
          (team.id + "").toLowerCase().includes(searchText.trim().toLowerCase())
        ) {
          ids.push(team.id + "");
        }
      }
    });
    return ids;
  }, [searchText.trim(), userAndTeams]);

  // Filter out disabled users
  const activeUserIds = useMemo(() => {
    // Combine agentIdsToRender and adminIdsToRender
    const allIdsToRender = [...agentIdsToRender, ...adminIdsToRender];

    return allIdsToRender.filter((userId) => {
      const user = userAndTeams?.users[userId];
      return user && !user.disabled;
    });
  }, [searchText.trim(), userAndTeams]);

  // Determine the overall fetch status based on individual statuses
  const fetchStatus: AJAXSTATUS = useMemo(() => {
    if (
      statusSelectedUsers === "fulfilled" &&
      userAndTeamsStatus === "success"
    ) {
      return "fulfilled";
    } else if (
      statusSelectedUsers === "rejected" ||
      userAndTeamsStatus === "error"
    ) {
      return "rejected";
    } else {
      return "pending";
    }
  }, [statusSelectedUsers, userAndTeamsStatus]);

  // Use useCallback to fetch users to be notified and set initial selected users and teams
  const fetchUsersSelected = useCallback(
    (callback: (value: AJAXSTATUS) => void) => {
      callback("pending");
      getUsersToBeNotified()
        .then((data) => {
          // Set selected users state based on the retrieved data
          setSelectedUsers(data);
          callback("fulfilled");
        })
        .catch((err) => {
          // Display a toast notification for the error
          pushTheToast({
            type: "danger",
            text: "Something Went Wrong",
            position: "top-right",
          });
          callback("rejected");
        });
    },
    [],
  );

  // Effect hook to fetch users to be notified on component mount
  useEffect(() => {
    fetchUsersSelected(setStatusSelectedUsers);
  }, [false]);
  // Run once on component mount

  // Callback that executes when selecting or unselecting users/teams
  const handleUpdate = useCallback(
    (userIds: Array<string | number>, context: "remove" | "add") => {
      setLoadingUpdateUsers(true);
      // Prepare payload
      const payload: UpdateUsersPayload = {
        userIdsToNotify: [...selectedUsers],
      };

      // Code to modify the payload based on the context
      userIds.forEach((id) => {
        const user = userAndTeams?.users[id];
        if (user && !user.disabled) {
          //Ignore disabled users ids
          if (context === "add") {
            if (!payload.userIdsToNotify.includes(id + "")) {
              payload.userIdsToNotify.push(id + "");
            }
          } else if (context === "remove") {
            const indexToRemove = payload.userIdsToNotify.indexOf(id + "");
            if (indexToRemove !== -1) {
              payload.userIdsToNotify.splice(indexToRemove, 1);
            }
          }
        }
      });

      // API call to add or update user/team
      addOrUpdateUsersToBeNotified(payload)
        .then((res) => {
          setSelectedUsers(payload.userIdsToNotify);
        })
        .catch((err) => {
          // Display a toast notification for the error
          pushTheToast({
            type: "danger",
            text: "Something Went Wrong",
            position: "top-right",
          });
        })
        .finally(() => {
          setLoadingUpdateUsers(false);
        });
    },
    [selectedUsers],
  );

  // Define a callback function to handle search
  const handleSearch = useCallback((e: any) => {
    const searchTerm = e.target.value;
    setSearchText(searchTerm);
  }, []);

  // Handle on toggle
  const handleOnToggle = useCallback(
    (value: boolean) => {
      if (!ddLoading && notifyPermission === "edit") {
        if (value) {
          fetchUsersSelected((status) => {
            if (status === "pending") {
              setDdLoading(true);
            } else {
              setDdLoading(false);
              setDdOpen(true);
            }
          });
        } else {
          setDdOpen(false);
        }
      }
    },
    [setDdOpen, fetchUsersSelected, ddLoading, setDdLoading, notifyPermission],
  );

  // Return the necessary states and actions
  return {
    selectedUsers,
    handleUpdate,
    searchText,
    handleSearch,
    loadingUpdateUsers,
    fetchStatus,
    userAndTeams,
    ddOpen,
    handleOnToggle,
    ddLoading,
    showSelectedAgents,
    agentIdsToRender,
    adminIdsToRender,
    teamIdsToRender,
    activeUserIds,
    notifyPermission,
  };
}

export default useTeamsAndUsers;
