import { axiosJSON } from "src/globals/axiosEndPoints";

export interface IShopifyIntegrationItem {
  integrationId: number;
  storeUrl: string;
  storeName: string;
  createdAt: string;
  createdAtGmt: string;
  updatedAt: string;
  updatedAtGmt: string;
  connected: boolean;
}

export async function getAllShopifyIntegrations() {
  const { data: res } = await axiosJSON.get(
    `/api/integration/getAllShopifyIntegrations`
  );
  if (res.err) {
    throw "Something went wrong!";
  }
  const integrationsList: { [integrationId: number]: IShopifyIntegrationItem } =
    {};
  const integrationIdsList: Array<number> = [];
  res.data.forEach((integration: IShopifyIntegrationItem) => {
    integrationsList[integration.integrationId] = integration;
    integrationIdsList.push(integration.integrationId);
  });
  return { integrationsList, integrationIdsList, metaData: res.metaData };
}
