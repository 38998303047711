import { useEffect, useRef } from "react";
import { Spinner } from "react-bootstrap";
import styles from "./InfiniteScroll.module.scss";

interface Props {
  className?: string;
  children?: any;
  loadMore?: () => void;
  loader?: any;
  errorMessage?: any;
  hasMore?: boolean;
  root?: any;
  initialDataLoaded: boolean;
  disableScrollingClass?: boolean;
  [key: string]: any;
  hideClassDisplayFlex?: boolean;
  loaderClass?: string;
}

function InfiniteScroll({
  className,
  children,
  loadMore = () => {},
  loader = <Spinner animation="border" variant="secondary" />,
  errorMessage = "",
  hasMore,
  root,
  initialDataLoaded,
  disableScrollingClass = false,
  hideClassDisplayFlex = false,
  loaderClass = "",
  ...props
}: Props) {
  const rootRef: any = useRef(null);
  const intersectionRef: any = useRef(null);
  const intersected: any = useRef(null);
  useEffect(() => {
    const options = {
      root: root ? root.current : rootRef.current,
      rootMargin: "0px",
      threshold: 0,
    };
    const observer = new IntersectionObserver(() => {
      if (intersected.current === null) {
        intersected.current = initialDataLoaded;
      } else if (intersected.current === true) {
        intersected.current = false;
        if (hasMore) {
          loadMore();
        }
      } else {
        intersected.current = true;
      }
    }, options);
    observer.observe(intersectionRef.current);
    return () => {
      if (intersectionRef.current) {
        observer.unobserve(intersectionRef.current);
        intersected.current = null;
      }
    };
  }, [hasMore, loadMore]);
  return (
    <div
      className={`${
        root === undefined
          ? disableScrollingClass === true
            ? ""
            : styles.infiniteScroll
          : ""
      } ${styles.contentVisibility} ${className} ${hideClassDisplayFlex ? "" : "d-flex flex-column"}`}
      {...props}
      ref={root ?? rootRef}
    >
      {errorMessage ? <>{errorMessage}</> : children ?? <></>}
      {hasMore && errorMessage === "" && (
        <div className={`max-content-width m-auto ${loaderClass}`}>{loader}</div>
      )}
      <div
        style={{ height: "0px", marginBottom: "10px" }}
        ref={intersectionRef}
      ></div>
    </div>
  );
}

export default InfiniteScroll;
