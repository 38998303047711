/**
 * This is a service file.
 *
 * @author Yash Aditya<yash@zinosi.com>
 */

import { axiosJSON } from "src/globals/axiosEndPoints";

export type ComingSoonFeatureTypes =
  | "bot"
  | "warranty"
  | "tracking"
  | "monitoring"
  | "return"
  | "modification"
  | "actionable_insights";


// Defining an asynchronous function reqCmngSnFeatureService with a parameter 'params'
async function reqCmngSnFeatureService( params: {
  context: Array<ComingSoonFeatureTypes>;
} ) {
  // Making a POST request to the "api/comingSoon/requestFeature" endpoint with the provided 'params'
  const { data: res } = await axiosJSON.post(
    "/api/comingSoon/requestFeature",
    params
  );

  // Checking for errors in the response
  if ( res.err || res.error ) {
    // Throwing an error with a fallback message if 'msg' or 'message' is not present in the response
    throw res.msg ?? res.message ?? "Something went wrong!";
  }

  // Returning 'true' to indicate that the feature request was successful
  return true;
}

// Exporting the reqCmngSnFeatureService function as the default export of the module
export default reqCmngSnFeatureService;
