import {
  TicketGlobalSearchSlice,
  initialState,
} from "./ticketGlobalSearch.declarations";

const resetTicketGlobalSearch = () => {
  return initialState;
};

const resetTicketGlobalSearchData = (state: TicketGlobalSearchSlice) => {
  return {
    ...initialState,
    searchText: state.searchText,
    tableType: state.tableType,
  };
};

const setSearchData = (
  state: TicketGlobalSearchSlice,
  { payload }: { payload: { searchText: string; tableType: string } }
) => {
  state.searchText = payload.searchText;
  state.tableType = payload.tableType;
  return state;
};

export default {
  resetTicketGlobalSearch,
  setSearchData,
  resetTicketGlobalSearchData,
};
