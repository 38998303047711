import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";
import { AJAXSTATUS } from "src/globals/constants";
import getSegmentColumnsService, {
  SegmentColumnsResponse,
} from "src/services/CustomerSegments/getSegmentColumns.service";

function useFetchColumns(segmentType: string, segmentId?: string) {
  const {
    data: columns,
    status,
    refetch,
  } = useQuery(["useFetchColumns", segmentType, segmentId], {
    queryFn: async () => {
      return segmentType
        ? await getSegmentColumnsService({
            segmentType,
            segmentId,
          })
        : undefined;
    },
    cacheTime: 3600000,
    staleTime: 3600000,
    networkMode: "always",
  });

  const refetchColumns = useCallback(async () => {
    const res = await refetch();

    return {
      columns: (res.data ? res.data : null) as SegmentColumnsResponse | null,
      columnsStatus:
        res.status === "success"
          ? "fulfilled"
          : res.status === "loading"
            ? "pending"
            : ("rejected" as AJAXSTATUS),
    };
  }, [refetch]);

  return {
    columns: (columns ? columns : null) as SegmentColumnsResponse | null,
    columnsStatus:
      status === "success"
        ? "fulfilled"
        : status === "loading"
          ? "pending"
          : ("rejected" as AJAXSTATUS),
    refetchColumns,
  };
}

export default useFetchColumns;
