import { Spinner } from "react-bootstrap";
import styles from "./DeleteRuleModal.module.scss";
import { useDeleteAutomation } from "src/routes/BotSettings/Children/Automation/Hooks/useAutomationManager";
import { useCallback } from "react";

const DeleteRuleModal = ({
  integrationId,
  onHide,
  onDelete,
}: {
  integrationId: string;
  onHide: () => void;
  onDelete: (integrationId: string) => void;
}) => {
  const { deleteAutomation, isLoading: isDeleting } =
    useDeleteAutomation(onDelete);

  const handleDelete = useCallback(() => {
    if (integrationId != "-1") deleteAutomation(integrationId);
  }, [deleteAutomation, integrationId]);

  return (
    <div>
      <h3 className={`mb-3 ${styles.modalHead}`}>Delete rule</h3>
      <p className={`mb-0 ${styles.modalSubHead}`}>
        Are you sure you want to delete the rule?
      </p>
      <div className="d-flex justify-content-end mt-5">
        <button
          className={`px-1 me-3 ${styles.cancelBtn}`}
          onClick={onHide}
          disabled={isDeleting}
        >
          Cancel
        </button>
        <button
          className={`ms-1 ${styles.deleteBtn}`}
          onClick={handleDelete}
          disabled={isDeleting}
        >
          Delete
          {isDeleting && (
            <Spinner
              className="mx-2"
              size="sm"
              animation="border"
            />
          )}
        </button>
      </div>
    </div>
  );
};

export default DeleteRuleModal;
