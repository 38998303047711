import { TicketGlobalSearchSlice } from "./ticketGlobalSearch.declarations";

const fetchAllSearchTicketsFulfilled = (
  state: TicketGlobalSearchSlice,
  { payload }: any
) => {
  const { ticketData, ticketList, ticketListTotal }: any = payload as any;
  state.ticketData = {
    ...state.ticketData,
    ...ticketData,
  };
  //to prevent duplicate ticketIds using Set class
  state.ticketList = Array.from(new Set([...state.ticketList, ...ticketList]));
  state.ticketListTotal = ticketListTotal;
  state.ticketDataAjaxStatus = "fulfilled";
};

export default { fetchAllSearchTicketsFulfilled };
