import styles from "./DeleteGoal.module.scss";
interface Props {
  showDeleteText: boolean;
  handleConfirm: any;
  onHide: any;
  goalId?: number | string;
  sendGoalId?: boolean;
}
const DeleteGoal = ({
  showDeleteText,
  goalId,
  sendGoalId,
  handleConfirm,
  onHide,
}: Props) => {
  const handleSubmit = () => {
    if (sendGoalId === true) {
      handleConfirm(goalId);
    } else {
      handleConfirm();
    }
  };
  return (
    <>
      <div className={`container`}>
        <div className={`row`}>
          <div className={`col-12`}>
            <div className="text-center">
              <p className={`${styles.delPara}`}>
                {showDeleteText === true
                  ? "Are you sure you want to delete Goal?"
                  : "Do you want to proceed without saving changes?"}
              </p>
              <span className={`${styles.delSpan}`}>
                {showDeleteText === true
                  ? "This goal will be permanently deleted and all its statistics will be lost."
                  : "This will discard all the changes that you have made"}
              </span>
              <div
                className={`d-flex align-items-center justify-content-center flex-column flex-md-row mt-4 pt-2`}
              >
                <button
                  className={`me-md-4 bg-white ${styles.cancelBtn}`}
                  onClick={onHide}
                >
                  Cancel
                </button>
                <button
                  className={`mt-2 mt-md-0 ${styles.confirmBtn}`}
                  onClick={handleSubmit}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DeleteGoal;
