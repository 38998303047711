import { axiosJSON } from "src/globals/axiosEndPoints";

export interface IAddOrUpdateBrandSignaturePayload {
  brandSignatureEnabled?: boolean;
  brandSignatureValue?: string;
  brandId?: number | string;
}
export async function addOrUpdateBrandSignature(
  payload: IAddOrUpdateBrandSignaturePayload
) {
  const { data: res } = await axiosJSON.post(
    "/api/setting/addOrUpdateBrandSignature",
    payload
  );

  if (res.error === true) {
    throw new Error(res.message as string);
  }

  return res.data;
}
