import { useAppSelector } from "src/store/store";
import SetupRow from "./Children/SetupRow/SetupRow";
import styles from "./SetupTable.module.scss";
const SetupTable = () => {
  const selectedUserDetail = useAppSelector(
    (state) => state.emailIntegration.selectedUser
  );

  return (
    <>
      <div className="w-100 overflow-auto">
        <div
          className={`d-flex justify-content-between px-2 py-3 ${styles.tableHeader}`}
        >
          <div className={`${styles.status}`}>
            <span className={`${styles.headerText}`}> Status</span>
          </div>
          <div className={`${styles.record}`}>
            <span className={`${styles.headerText}`}> Record type</span>
          </div>
          <div className={`${styles.host}`}>
            <span className={`${styles.headerText}`}> Host</span>
          </div>
          <div className={`${styles.value} mx-3`}>
            <span className={`${styles.headerText}`}> Value</span>
          </div>
          <div className={`${styles.cValue}`}>
            <span className={`${styles.headerText}`}> Current Values</span>
          </div>
        </div>
        <div className={`${styles.rowData}`}>
          {selectedUserDetail.domainAuthData.map((data) => {
            return (
              <SetupRow
                status={data.isVerified}
                record={data.recordType}
                host={data.host}
                value={data.value}
                cValue={
                  data.currentValue === null ? "Not Found" : data.currentValue
                }
              />
            );
          })}

          {/* <SetupRow
            status={"Not Verified"}
            record={"TXT"}
            host={"mailo._domainkey.zinosi.com"}
            cValue={"Not found "}
            value={
              "k=rsa; p=MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC/t+h/UYRW/jGZD7Mag2OXdZzvAf7bzEqXLQKgF5SqWmysJLK0f6Nwk8hKhn9515/2MlAp7paRX2gdRg/wXsyH59kZ5eTFBXMo8t0INlSfo1jyDOTJk2JSN17HYEpCWpDe3vQ9yOUbpCHJOZKhScsqrEkHju+8ZvCc3lUwgrK2nQIDAQAB"
            }
            // value={"skjnfalkjsdnfajklsndfkjadnsjaksnkjsn"}
          /> */}
        </div>
      </div>
    </>
  );
};
export default SetupTable;
