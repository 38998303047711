import { useEffect, useMemo, useRef } from "react";
import { RoutesColumn } from "src/store/slices/tickets/tickets.slice";
import allTicketViews, { allColumns } from "./ticketViewsConfig";
import { useAppSelector } from "src/store/store";
import { useParams } from "react-router-dom";
import { arrayMoveIndex } from "src/utils/utils";

function useTicketViews() {
  const { tableType }: any = useParams();

  const outerTicketColumn = useAppSelector(
    (state) => state.globals.currentUserData?.outerTicketColumn
  );

  const SavedUserTicketColumns: { [key: string]: any } = useMemo(() => {
    return outerTicketColumn ? JSON.parse(outerTicketColumn) : {};
  }, [outerTicketColumn]);

  // it contains the prepared list of all columns and the default columns
  const colList = useRef(
    (() => {
      const defaultColumnsList: RoutesColumn = {};
      for (let i = 0; i < allTicketViews.length; i++) {
        const view = allTicketViews[i];
        const defaultColsIds = view.defaultColumnsList;
        const dCols: any = [];
        const moreCols: any = [];

        for (let j = 0; j < defaultColsIds.length; j++) {
          let id = defaultColsIds[j];
          let dCol = allColumns.find((col) => col.id === id);
          dCols.push({ ...dCol, show: true });
        }

        for (let j = 0; j < allColumns.length; j++) {
          let col = allColumns[j];
          if (!defaultColsIds.includes(col.id)) {
            moreCols.push({ ...col, show: false });
          }
        }

        const cols = [];
        cols.push(...dCols);
        cols.push(...moreCols);

        defaultColumnsList[view.routeString] = cols;
      }
      return defaultColumnsList;
    })()
  );

  const currentColumnsList = useMemo(() => {
    if (
      tableType &&
      SavedUserTicketColumns &&
      colList.current &&
      SavedUserTicketColumns[tableType] &&
      SavedUserTicketColumns[tableType].allViewSelectedCols
    ) {
      const allViewSelectedCols =
        SavedUserTicketColumns[tableType].allViewSelectedCols;

      const viewColumns = { ...colList.current };

      for (const viewType in allViewSelectedCols) {
        const ticketColumns = allViewSelectedCols[viewType];

        let arr = [...viewColumns[viewType]];
        ticketColumns.forEach((col: any, i: number) => {
          if ((col + "").includes("::")) {
            const c = (col + "").split("::");
            const ind1 = parseInt(c[1]);
            const ind = arr.findIndex((val) => val.id == parseInt(c[0]));
            arr = arrayMoveIndex({ arr, oldIndex: ind, newIndex: ind1 });
            ticketColumns[i] = parseInt(c[0]);
          } else {
            ticketColumns[i] = parseInt(col);
          }
        });
        arr.forEach((col, i) => {
          const colCopy = { ...col }; // Make a shallow copy of the object
          if (!col.hideInMoreCols) {
            if (ticketColumns.includes(col.id)) {
              colCopy.show = true;
            } else {
              colCopy.show = false;
            }
            arr[i] = colCopy;
          }
        });
        viewColumns[viewType] = arr;
      }

      return viewColumns;
    } else {
      return colList.current;
    }
  }, [tableType, colList.current, SavedUserTicketColumns]);

  return {
    ticketViews: allTicketViews,
    allColumnList: allColumns,
    defaultColumnsList: colList.current,
    currentColumnsList: currentColumnsList,
  };
}

export default useTicketViews;
