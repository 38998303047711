/**
 * This file defines a React component responsible for displaying available brands for bot profiles
 *
 * @author @navjyot-busibud
 * @author @yuvaraj-busibud
 */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styles from "./BrandDropdown.module.scss";
import UserAvatar from "src/components/UserAvatar";
import Select, {
  components,
  ControlProps,
  OptionProps,
  SingleValueProps,
} from "react-select";
import AxiosImg from "src/components/AxiosImg";
import { useInfiniteQuery } from "@tanstack/react-query";
import { getAllBotProfileBrands } from "src/services/Bot/BotProfiles/getAllBotProfileBrands";
import { getLinkableBrandsAutomation } from "src/services/Automation/getLinkableBrandsAutomation.service";
import { EAutomationType } from "../AutomationList/AutomationList";
import { useParams } from "react-router-dom";

interface BrandOption {
  value: number;
  label: string;
  imageUrl?: string | null;
}

const CustomOption: React.FC<OptionProps<BrandOption>> = (props) => (
  <components.Option {...props}>
    <div className={`d-flex align-items-center cursor-pointer `}>
      {props.data.imageUrl !== null && props.data.imageUrl ? (
        <AxiosImg
          url={props.data.imageUrl}
          size={20}
          style={{ width: "20px", height: "20px" }}
          className={`rounded-circle`}
        />
      ) : (
        <UserAvatar
          name={props.label}
          size={20}
        />
      )}
      <span className={`ps-2 ${styles.brandName}`}>{props.label}</span>
    </div>
  </components.Option>
);

const CustomSingleValue: React.FC<SingleValueProps<BrandOption>> = (props) => (
  <components.SingleValue {...props}>
    <div className={`d-flex align-items-center`}>
      {props.data.imageUrl !== null && props.data.imageUrl ? (
        <AxiosImg
          url={props.data.imageUrl}
          size={20}
          style={{ width: "20px", height: "20px" }}
          className={`rounded-circle`}
        />
      ) : (
        <UserAvatar
          name={props.data?.label ?? "NA"}
          size={20}
        />
      )}{" "}
      <span className={`ps-1 ${styles.selectBrand}`}>{props.data?.label}</span>
    </div>
  </components.SingleValue>
);

const CustomControl: React.FC<ControlProps<BrandOption>> = ({
  children,
  innerRef,
  innerProps,
  isFocused,
}) => {
  return (
    <div
      ref={innerRef}
      className={`custom-control-container ${
        isFocused ? styles.focusStyle : ""
      }`}
    >
      <div
        {...innerProps}
        className={`custom-control px-2 ${styles.brandDrop}`}
      >
        {children}
      </div>
    </div>
  );
};

const CustomDropdownIndicator = (props: any) => {
  const { type } = useParams();
  return (
    <components.DropdownIndicator {...props}>
      <i
        className={`fa-solid ${
          props.selectProps.menuIsOpen ? "fa-caret-up" : "fa-caret-down"
        }`}
        style={{
          color:
            type === EAutomationType.TICKET_ROUTING ? "#555555" : "inherit",
        }}
      ></i>
    </components.DropdownIndicator>
  );
};

interface Props {
  selectedBrand: BrandOption | undefined;
  setSelectedBrand: (brand: BrandOption) => void;
  isAutomation?: boolean;
  automationType?: string;
  updateBrandChange?: Function;
  className?: string;
  error?: string;
  isDisabled?: boolean;
}

const BrandDropdown = ({
  selectedBrand,
  setSelectedBrand,
  updateBrandChange,
  isAutomation = false,
  automationType,
  className,
  isDisabled = false,
  error,
}: Props) => {
  const [brandOptions, setBrandOptions] = useState<BrandOption[]>([]);

  const payload = useMemo(() => {
    if (isAutomation && automationType) {
      return {
        type: automationType,
        payload: {
          limit: 15,
          start: 0,
        },
      };
    }

    return {
      limit: 15,
      start: 0,
      searchTerm: "",
      onlyVerified: true,
    };
  }, [automationType, isAutomation]);

  const queryFn = useMemo(
    () =>
      isAutomation && automationType
        ? getLinkableBrandsAutomation
        : getAllBotProfileBrands,
    [automationType, isAutomation],
  );

  const { data, isLoading, fetchNextPage, hasNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ["getVerifiedBrands", payload],
      queryFn: ({ pageParam = payload }) => queryFn(pageParam),
      getNextPageParam: (lastPage: any, allPages) => {
        const data = allPages.reduce((acc: any, page: any) => {
          const { brands } = page;
          const brandIds = Object.keys(brands);
          return [...acc, ...brandIds];
        }, []);
        if (data.length < lastPage.metaData?.total) {
          if (isAutomation && automationType) {
            return {
              type: automationType,
              payload: {
                ...payload,
                start: data.length,
              },
            };
          }

          const nextPageParam = {
            ...payload,
            start: data.length,
          };
          return nextPageParam;
        }
        return null;
      },
    });

  // Callback to handle the change event of the dropdown
  const handleChange = useCallback((selectedOption: any) => {
    setSelectedBrand(selectedOption);
    if (updateBrandChange) {
      updateBrandChange(selectedOption.value);
    }
  }, []);

  // Fetch the next page only when there is a hasNextPage and no ongoing fetch
  const fetchNextPageOnScroll = (event: WheelEvent | TouchEvent) => {
    if (hasNextPage && !isFetching) {
      fetchNextPage();
    }
  };

  // Convert brandIds and brands data into options for the dropdown
  useEffect(() => {
    const brandData = data?.pages.flatMap((data: any) => data);

    if (brandData) {
      const options = brandData.reduce((acc: BrandOption[], brand: any) => {
        const { brands } = brand;

        if (brands) {
          const brandIds = Object.keys(brands);

          const brandOptions = brandIds.map((brandId: string | number) => {
            const brand = brands[brandId];
            return {
              label: brand.name,
              value: brand.id,
              imageUrl: brand.imageURL,
            } as BrandOption;
          });

          return [...acc, ...brandOptions];
        }

        return acc;
      }, []);

      setBrandOptions(options);
    }
  }, [data]);

  // Fetch initial data on component mount
  // useEffect(() => {
  //   dispatch(getAllBrandsInfiScroll({ start: 0 }));
  // }, []);

  return (
    <>
      <Select
        className={`w-100 cursor-pointer ${className ?? ""}`}
        classNamePrefix="dropdown"
        options={brandOptions}
        value={selectedBrand}
        isDisabled={isDisabled}
        onChange={handleChange}
        components={{
          Option: CustomOption,
          SingleValue: CustomSingleValue,
          // Menu: CustomMenu,
          Control: CustomControl,
          DropdownIndicator: CustomDropdownIndicator,
          IndicatorSeparator: null,
        }}
        placeholder="Select Brand"
        isSearchable={false}
        isMulti={false}
        onMenuScrollToBottom={fetchNextPageOnScroll}
        isLoading={isLoading || isFetching}
        menuPortalTarget={document.body} // This ensures the dropdown is rendered correctly - added for fix dropdown issue in stepslider
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }} // Adjust z-index - added for fix dropdown issue in stepslider
      />
      <span className="text-danger small">{error}</span>
    </>
  );
};

export default BrandDropdown;
