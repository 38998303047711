import styles from "./ScheduleFollowUp.module.scss";
import DatePicker from "react-datepicker";
import useScheduleFollowUp from "./useScheduleFollowUp";
import SendMessageIf from "../SendMessageIf/SendMessageIf";
import { UpdateSchedulePayload } from "src/services/LiveChat/ScheduleFollowups/updateSchedule";
import { forwardRef, useState } from "react";
import { ScheduleData } from "src/services/LiveChat/ScheduleFollowups/getAllSchedules";
import { Spinner } from "react-bootstrap";
type Props = {
  onHide: () => void;
  updateScheduleData: (payload: UpdateSchedulePayload) => Promise<void>;
  schedule: ScheduleData;
  allSchedules: { [key: string | number]: ScheduleData };
};
export const CustomDatePicker = forwardRef(
  ({ value, onClick, faIcon, open }: any, ref: any) => (
    <div
      className={`d-flex align-items-center justify-content-between p-0 ${styles.dateDiv}`}
      onClick={onClick}
      ref={ref}
    >
      <span>
        <i className={`fa-regular ${faIcon} m-auto mx-1 me-2`} />
        {value}
      </span>
      <span>
        <i className={`fa-solid fa-chevron-${open ? "up" : "down"}`}></i>
      </span>
    </div>
  )
);

const ScheduleFollowUp = ({
  onHide,
  updateScheduleData,
  schedule,
  allSchedules,
}: Props) => {
  const {
    startDate,
    setStartDate,
    handleCancel,
    handleUpdate,
    startTime,
    setStartTime,
    selectedCondition,
    setSelectedCondition,
    updateLoader,
    error,
  } = useScheduleFollowUp({
    onHide,
    schedule: schedule,
    updateScheduleData,
    allSchedules,
  });
  const [isTimePickerOpen, setIsTimePickerOpen] = useState(false);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  return (
    <div className={`${styles.rescheduleBox}`}>
      <h3 className={`mb-3 ${styles.header}`}>Reschedule follow up</h3>
      <div className={`d-flex align-items-center flex-wrap mb-3`}>
        <div
          className={`me-3 mb-2 ${styles.datePick} cursor-pointer ${
            error ? "border border-danger " : ""
          } px-2`}
        >
          <DatePicker
            selected={startDate}
            onChange={(date: any) => setStartDate(date)}
            dateFormat="MMM d, yyyy"
            closeOnScroll={true}
            onCalendarOpen={() => setIsDatePickerOpen(true)}
            onCalendarClose={() => setIsDatePickerOpen(false)}
            customInput={
              <CustomDatePicker
                faIcon={"fa-calendar-days"}
                open={isDatePickerOpen}
              />
            }
            wrapperClassName={styles.wrapperDatepicker}
          />
        </div>
        <div
          className={`me-3 mb-2 cursor-pointer ${styles.customBtn2} ${
            error ? "border border-danger" : ""
          }  px-2`}
        >
          <DatePicker
            selected={startTime}
            onChange={(date: any) => setStartTime(date)}
            showTimeSelect
            showTimeSelectOnly
            onCalendarOpen={() => setIsTimePickerOpen(true)}
            onCalendarClose={() => setIsTimePickerOpen(false)}
            dateFormat="h:mm aa"
            customInput={
              <CustomDatePicker faIcon={"fa-clock"} open={isTimePickerOpen} />
            }
            wrapperClassName={styles.wrapperDatepicker}
          />
        </div>
        {error ? (
          <div className="mb-2">
            <span className={`${styles.errorMsg} py-1 px-3`}>{error}</span>
          </div>
        ) : null}
      </div>
      <div className={`${styles.sendMessageBox}`}>
        <SendMessageIf
          selected={selectedCondition}
          onSelect={(value) => setSelectedCondition(value ?? ("none" as any))}
        />
      </div>
      <div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-end align-items-center align-items-md-start mt-3 pt-3">
        <button className={`${styles.cancelBtn}`} onClick={handleCancel}>
          Cancel
        </button>
        <button
          className={`ms-0 ms-md-3 mt-2 mt-md-0 ${styles.submitBtn}`}
          onClick={handleUpdate}
          disabled={updateLoader}
        >
          {updateLoader ? (
            <Spinner
              className="my-auto mx-1"
              animation="border"
              color="white"
              size="sm"
            />
          ) : (
            "Update"
          )}
        </button>
      </div>
    </div>
  );
};

export default ScheduleFollowUp;
