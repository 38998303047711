import React, { useCallback, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AxiosImg from "src/components/AxiosImg";
import UserAvatar from "src/components/UserAvatar";
import { Conversation } from "src/services/LiveChat/liveChatHistory/getLiveChatHistoryConversation.service";
import { Customer } from "src/services/LiveChat/messageExchange/getAllCustomers";
import { useAppSelector } from "src/store/store";
import Status from "./Children/Status";
import styles from "./HistoryUpdatedTickets.module.scss";
import { getTimeFromNow } from "src/utils/dateLibrary";

interface Props {
  coversationDetails: Conversation;
  customerData: Customer | null;
  id: string;
  selectedHistory: null | Conversation;
  setSlectedHistory: React.Dispatch<React.SetStateAction<Conversation | null>>;
  scrollRef?: React.MutableRefObject<null>;
}

const HistoryUpdatedTickets = ({
  coversationDetails,
  customerData,
  id,
  selectedHistory,
  setSlectedHistory,
  scrollRef,
}: Props) => {
  const isActive = useMemo(() => {
    return id === selectedHistory?.id;
  }, [id, selectedHistory]);

  const handleClick = useCallback(() => {
    setSlectedHistory(coversationDetails);
  }, [coversationDetails]);

  return (
    <>
      <div
        ref={scrollRef}
        className={`pt-2 ${styles.ticketWrapper}`}
        onClick={handleClick}
      >
        {coversationDetails.conversationType === "ticket" ? (
          <>
            <Status
              status={coversationDetails.ticketStatus?.name}
              displayBtn="d-none"
              ticketId={coversationDetails.ticketId + ""}
            />
          </>
        ) : (
          <>
            {/* Chat */}
            <div className={`d-flex align-items-center status`}>
              <span className={`${styles.chatText}`}>
                Chat
                <span className="px-1">
                  <i className="fa-regular fa-message"></i>
                </span>
              </span>
            </div>
          </>
        )}

        {/* Message Content */}
        <div
          className={`d-flex p-2 mb-1 position-relative ${styles.historyCard} ${
            isActive ? styles.active : ""
          }`}
        >
          <div className={`${styles.imgWrapper}`}>
            {customerData?.imageURL && customerData?.imageURL.length !== 0 ? (
              <AxiosImg
                url={customerData?.imageURL}
                className={`${styles.dummyImg}`}
                isDirectURL={customerData.isPublicAttachmentUrl}
              />
            ) : (
              <UserAvatar
                name={customerData?.name ?? "NA"}
                size={20}
              />
            )}
          </div>
          {/* Message Excerpt */}
          <div className={`ms-1`}>
            <h6 className={`mb-0 mt-1 ${styles.userName}`}>
              {customerData?.name}
            </h6>
            <div className={`w-100`}>
              <p className={`mb-0 text-truncate ${styles.userComment}`}>
                {coversationDetails.lastMessageExcerpt}
              </p>
            </div>
            {/* Use below divs for different operartion like insta , only time , msg with time  */}
            <div className={`d-flex flex-wrap`}>
              <span className={`${styles.timeSpan}`}>
                {getTimeFromNow({
                  date: new Date(coversationDetails.lastMessageSentTimeUTC),
                })}
              </span>
            </div>
            <div className={`d-none ${styles.timeSpan}`}>
              <span>11/05/2022 07:09pm</span>
            </div>
            <div className={`d-none flex-wrap`}>
              <span className={`${styles.timeSpan}`}>
                <i className="fa-solid fa-envelope"></i>
                <span className={`ms-1`}>2w ago</span>
              </span>
              <div className={`ms-2 d-flex align-items-center`}>
                <div className={`me-1 ${styles.pendingDot}`}></div>
                <span className={`${styles.pendingStatus}`}>status</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HistoryUpdatedTickets;
