import styles from "./NotEligible.module.scss";
import refresh from "src/assets/images/chat/refreshButton.png";
import regret from "src/assets/images/chat/regret.gif";
import caution from "src/assets/images/caution.png";
const NotEligible = () => {
  return (
    <>
      <div>
        <div className={`d-flex align-items-center`}>
          <div>
            <img
              src={refresh}
              alt="refresh"
              className={`${styles.refresh}`}
            />{" "}
          </div>
          <span className={`ms-1 ${styles.heading}`}>
            Initiate Return/Exchange{" "}
          </span>
        </div>
        <div className="text-center pt-4">
          <img
            src={caution}
            alt="caution"
            className={` ${styles.caution}`}
          />
          <p className={`py-2 ${styles.regretMsg}`}>
            We're sorry this order is already shipped we cannot initiate return
            or exchange for this order .
          </p>
          <button className={`px-3 py-2 ${styles.backBtn}`}>Go back</button>
          {/* <div>
            <img src={regret} alt="regret" className={`${styles.regretImg}`} />
          </div> */}
        </div>
      </div>
    </>
  );
};
export default NotEligible;
