import { axiosJSON } from "src/globals/axiosEndPoints";

export interface HomePageData {
  totalOpenTickets: number;
  totalUserOpenTickets: number;
  socialMediaRequestCount?: {
    instagram?: number;
    twitter?: number;
    messenger?: number;
  };
  missedLiveChatRequestCount: number;
  liveChatRevenue: number;
}

export async function getHomePageData() {
  const { data: res } = await axiosJSON.get(`/api/getHomePageData`);
  if (res.error) {
    throw res.message;
  }
  return res.data as HomePageData;
}
