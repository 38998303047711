import { GetAllUser, User } from "src/services/Settings/Users/getAllUser";
import { UserRoleList } from "src/services/Settings/Users/getUserRoles";
import { IUserSettings } from "./userSettings.slice";

function fetchAllUserFulfilled(
  state: IUserSettings,
  { payload }: { payload: { userData: GetAllUser; roles: UserRoleList } }
) {
  state.userDataAjaxStatus = "fulfilled";
  state.userRoles = payload.roles;
  const { users, userIds, metaData } = payload.userData;
  state.users = { ...state.users, ...users };
  //to prevent duplicate using Set class
  state.userIds = Array.from(new Set([...state.userIds, ...userIds]));
  state.metaData = metaData;
}

function addUserFulfilled(state: IUserSettings, { payload }: any) {
  state.addUserAjaxStatus = "fulfilled";
}

function editUserFulfilled(
  state: IUserSettings,
  { payload }: { payload: User }
) {
  state.addUserAjaxStatus = "fulfilled";
  state.users[payload.userId] = payload;
}

function deleteUserFulfilled(state: IUserSettings, { payload }: any) {
  state.deleteUserAjaxStatus = "fulfilled";
  delete state.users[payload];
  state.userIds = state.userIds.filter((data) => data + "" !== payload + "");
  state.metaData.total = state.metaData.total - 1;
  return state;
}

function logoutUserFromAllSessionFulfilled(
  state: IUserSettings,
  { payload }: { payload: number| {err: boolean, msg: string} }
) {
  if (typeof payload === "number"  && state.users[payload]) {
    state.users[payload] = {
      ...state.users[payload],
      hasLoggedInSessions: false,
    };
  }
}

function deactivateUserFulfilled(
  state: IUserSettings,
  { payload }: { payload: {userId?: number|string, isActivate?: boolean, err?: boolean, msg?: string}}
) {

  if(payload.err === true){
    return state;
  }
  if (payload.userId && state.users[payload.userId]) {
    state.users[payload.userId] = {
      ...state.users[payload.userId],
      hasLoggedInSessions: false,
      isDeactivated: payload.isActivate ? false : true,
    };
  }
}
export default {
  fetchAllUserFulfilled,
  addUserFulfilled,
  editUserFulfilled,
  deleteUserFulfilled,
  logoutUserFromAllSessionFulfilled,
  deactivateUserFulfilled,
};
