import { useInfiniteQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { GetAllTemplatesData, ISearchTemplate, searchTemplate } from "src/services/Templates/searchTemplates";
import { IGetTemplateResponse } from "src/services/Templates/viewTemplates";

export const useSearchTemplates = (payload: ISearchTemplate) => {
  const {
    status,
    error,
    data,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isError,
    fetchStatus,
  } = useInfiniteQuery({
    queryKey: ["InnerTicket/searchTemplates", payload],
    queryFn: ({ pageParam = payload }) => searchTemplate(pageParam),
    getNextPageParam: (
      prevPage: GetAllTemplatesData,
      allPages: GetAllTemplatesData[]
    ) => {
      const data = allPages.flatMap((page) => page.templateIds);
      if (data.length < prevPage.metaData.total) {
        return {
          ...payload,
          start: data.length,
        };
      }
      return null;
    },
    enabled: payload.searchTerm.trim() !== "",
  });

  const templates = useMemo(() => {
    let templateData: { [key: number | string]: IGetTemplateResponse } = {};
    data?.pages.forEach((page) => {
      templateData = { ...templateData, ...page.templates };
    });
    return templateData;
  }, [data]);

  const templateIds = useMemo(() => {
    const ids = data?.pages.flatMap((page) => page.templateIds) ?? [];
    return Array.from(new Set([...ids]));
  }, [data]);

  return {
    status,
    error,
    templates,
    templateIds,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isError,
    fetchStatus,
  };
};
