/**
 * This file defines a custom hook responsible for delete bot profile
 * @author @yuvaraj-busibud
 */
import {
  InfiniteData,
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { createBotProfile } from "src/services/Bot/BotProfiles/createBotProfile.service";
import { deleteBotProfile } from "src/services/Bot/BotProfiles/deleteBotProfile.service";
import {
  BotProfileResponse,
  GetAllBotProfilesResponse,
  getAllBotProfiles,
} from "src/services/Bot/BotProfiles/getAllBotProfiles.service";
import { updatePriorities } from "src/services/Bot/BotProfiles/updatePriorities.service";
import { getAllBrandsInfiScroll } from "src/store/slices/settings/brands/brandSettings.slice";
import { useAppDispatch } from "src/store/store";
interface Props {
  id: number | string | undefined;
}

function useDeleteBotProfile({ id }: Props) {
  const queryClient = useQueryClient();

  const payload = {
    start: 0,
    limit: 15,
  };
  // Mutation for updating the order
  const deleteBotProfileMutation = useMutation({
    mutationFn: deleteBotProfile,
    mutationKey: ["deleteBotProfile", id],
    onSuccess: (data, variables, context) => {
      // Get the current data from the cache
      const botProfiles = queryClient.getQueryData<
        InfiniteData<GetAllBotProfilesResponse>
      >(["getAllProfiles", payload]);
      if (botProfiles) {
        // Find the updated schedule in the list and remove it
        const updatedBotProfiles = botProfiles.pages.map((page) => {
          const newPages = page.data.filter(
            (botProfile) => botProfile.id != variables.id
          );
          return {
            ...page,
            data: newPages,
            metaData: {
              total: page.metaData.total - 1,
              count: newPages.length,
            },
          };
        });

        // Update the cache with the new data
        queryClient.setQueryData<InfiniteData<GetAllBotProfilesResponse>>(
          ["getAllProfiles", payload],
          {
            pages: updatedBotProfiles,
            pageParams: botProfiles.pageParams,
          }
        );
      }
    },
    onError: ()=>{
      pushTheToast({
        type: "danger",
        text: "Failed to delete profile",
        position: "top-right",
      })
    }
  });

  const handleDeleteProfile = useCallback(() => {
    if ( id){
      deleteBotProfileMutation.mutate({ id: id });
    }
  }, [id]);

  const isLoading = deleteBotProfileMutation.isLoading;
  const isSuccess = deleteBotProfileMutation.isSuccess;
  const isError = deleteBotProfileMutation.isError;

  return {
    handleDeleteProfile,
    isLoading,
    isSuccess,
    isError,
  };
}

export default useDeleteBotProfile;
