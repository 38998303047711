import {axiosJSON} from "src/globals/axiosEndPoints";

interface AddCannedResponse {
  cannedResponesId: string | number;
  shortcut: string;
  messageText: string;
  integrationId: number;
}

export interface AddCannedResponseParams {
  integrationId: string | number;
  shortcut: string;
  messageText: string;
  title: string;
}

export async function addCannedResponse(params: AddCannedResponseParams) {
  const {data: res} = await axiosJSON.post(
    "/api/chatSetting/cannedResponse/create",
    params
  );

  if (res.error === true) {
    throw new Error(res.message as string);
  }

  return res.data as AddCannedResponse;
}
