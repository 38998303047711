import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { AJAXSTATUS } from "src/globals/constants";
import { AllTags } from "src/services/Settings/Tags/getAllTag";
import { fetchAllTagsFulfilled } from "./tagsSettings.extraReducers";
import tagsSettingsReducers from "./tagsSettings.reducers";
import tagsSettingsThunks from "./tagsSettings.thunks";

interface TagsFilters {
  searchValue: string;
}

export interface ITagsSettings extends AllTags {
  tagsLimit: number;
  filters: TagsFilters;
  tagsAjaxStatus: AJAXSTATUS;
  addTagAjaxStatus: AJAXSTATUS;
}

// to fetch all the tags
export const fetchAllTheTags = createAsyncThunk(
  "tagsSettings/fetchAllTheTags",
  tagsSettingsThunks.fetchAllTheTagsThunk
);

export const initialState: ITagsSettings = {
  tags: {},
  tagIdList: [],
  metaData: {
    count: 0,
    total: 0,
  },
  tagsLimit: 25,
  filters: {
    searchValue: "",
  },
  tagsAjaxStatus: "pending",
  addTagAjaxStatus: "idle",
};

export const tagsSettingsSlice = createSlice({
  name: "tagsSettings",
  initialState,
  reducers: tagsSettingsReducers,
  extraReducers: (builder) => {
    builder.addCase(fetchAllTheTags.fulfilled, fetchAllTagsFulfilled);
    builder.addCase(fetchAllTheTags.pending, (state) => {
      state.tagsAjaxStatus = "pending";
    });
    builder.addCase(fetchAllTheTags.rejected, (state) => {
      state.tagsAjaxStatus = "rejected";
    });
  },
});

export const { resetTagsSettings, setTagsSearchValue, resetTagsSearchValue } = tagsSettingsSlice.actions;

export default tagsSettingsSlice.reducer;
