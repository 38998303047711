import { Modal } from "react-bootstrap";
import styles from "./DuplicateRuleModal.module.scss";
import dangerExclamation from "src/assets/images/dangerExclamation.png";

const DuplicateRuleModal = ({
  showModal,
  setShowModal,
}: {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
}) => {
  return (
    <Modal
      className={`${styles.modalBack}`}
      show={showModal}
      onHide={() => setShowModal(false)}
      centered
      dialogClassName={`${styles.modalDialog}`}
      contentClassName={`${styles.modalContent}`}
    >
      <div
        className={`d-flex flex-column my-auto justify-content-end align-items-center ${styles.modalWrapper}`}
      >
        <span className={`my-3 d-block ${styles.dangerIcon}`}>
          <img
            src={dangerExclamation}
            className="img-fluid"
            alt=""
          />
        </span>
        <span className={`mb-2 ${styles.heading}`}>Already exists</span>
        <p className={`mb-3 ${styles.subHeading}`}>
          This issue dispatch with same already exists. .Change conditions to
          create the issue dispatch rule.
        </p>
        <button
          className={`${styles.goBackBtn}`}
          onClick={() => setShowModal(false)}
        >
          Go back
        </button>
      </div>
    </Modal>
  );
};

export default DuplicateRuleModal;
