import styles from "./AddModal.module.scss";
import gmail from "src/assets/images/gmail.png";
import msg from "src/assets/images/Email-msg.png";
import outlook from "src/assets/images/outlook.png";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "src/store/store";
import {
  EmailTypes,
  actions,
} from "src/store/slices/emailIntegration/emailIntegration.slices";

// Props to hide the modal
interface Props {
  onHide: () => void;
  isEmailSetup?: boolean;
  navCallback?: () => void;
}
const AddModal = ({ onHide, isEmailSetup = false, navCallback }: Props) => {
  // Function to hide the modal
  const hideModal = useCallback(() => {
    onHide();
  }, [onHide]);

  const [selected, setSelected] = useState(true);

  const [emailType, setEmailType] = useState<EmailTypes>("others");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const addNewEmail = () => {
    dispatch(actions.resetSelecteduser());
    dispatch(actions.updateEmailType(emailType));
    if (isEmailSetup && navCallback) {
      navCallback();
    } else {
      navigate(`/settings/integrations/email/connectEmail`);
    }
  };

  const handleChange = useCallback(
    (value: EmailTypes) => setEmailType(value),
    [],
  );

  return (
    <>
      <div>
        <span
          className={`cursor-pointer`}
          style={{ float: "right" }}
          onClick={hideModal}
          id={`emailModalClose`}
        >
          <i className="fa-solid fa-xmark"></i>
        </span>
        <div className={`d-flex align-items-center`}>
          <h3 className={`${styles.modalHead}`}>Connect your support email</h3>
        </div>
        <div>
          <p className={`${styles.choose}`}>
            Choose the type of email account you want to add:
          </p>
          <div className="mb-4">
            <div className={`form-check py-3 mb-2 ${styles.radioBox}`}>
              <div className={`ps-3`}>
                <input
                  className={`form-check-input ${styles.radioCheck}`}
                  type="radio"
                  name="integration"
                  id="flexRadioDefaultEIGmail"
                  onClick={() => setSelected(true)}
                  value="gmail"
                  checked={emailType === "gmail"}
                  onChange={() => handleChange("gmail")}
                />
                <label
                  className="form-check-label cursor-pointer"
                  htmlFor="flexRadioDefaultEIGmail"
                >
                  <img
                    src={gmail}
                    alt=""
                    className={`me-1 ${styles.accountImg}`}
                  />
                  <span className={`${styles.label}`}>Gmail</span>
                </label>
              </div>
            </div>
            <div className={`d-none form-check py-3 mb-2 ${styles.radioBox}`}>
              <div className={`ps-3`}>
                <input
                  className={`form-check-input ${styles.radioCheck}`}
                  type="radio"
                  name="integration"
                  id="flexRadioDefaultEIOutlook"
                  onClick={() => setSelected(true)}
                  value="outlook"
                  checked={emailType === "outlook"}
                  onChange={(e) => handleChange("outlook")}
                />
                <label
                  className="form-check-label cursor-pointer"
                  htmlFor="flexRadioDefaultEIOutlook"
                >
                  <img
                    src={outlook}
                    alt=""
                    className={`me-1 ${styles.outlookImg}`}
                  />
                  <span className={`${styles.label}`}>Outlook</span>
                </label>
              </div>
            </div>
            <div className={`form-check py-3 mb-2 ${styles.radioBox}`}>
              <div className={`ps-3`}>
                <input
                  className={`form-check-input ${styles.radioCheck}`}
                  type="radio"
                  name="integration"
                  id="flexRadioDefaultEIOthers"
                  onClick={() => setSelected(true)}
                  value="others"
                  onChange={(e) => handleChange("others")}
                  checked={emailType === "others"}
                />
                <label
                  className={`form-check-label cursor-pointer`}
                  htmlFor="flexRadioDefaultEIOthers"
                >
                  <img
                    src={msg}
                    alt=""
                    className={`me-1 ${styles.msgImg}`}
                  />
                  <span className={`${styles.label}`}> Others</span>
                </label>
              </div>
            </div>
          </div>
          <div
            className={`pt-2 d-flex justify-content-between align-items-center ${
              isEmailSetup && styles.buttonWrap
            }`}
          >
            <button
              className={`${styles.cancelBtn}`}
              onClick={hideModal}
              id="cancelBtn"
            >
              Cancel
            </button>

            <button
              className={`px-2 py-1   ${styles.connectBtn}`}
              disabled={selected ? false : true}
              onClick={addNewEmail}
              id="connectBtn"
            >
              Connect Email
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddModal;
