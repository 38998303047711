/**
 * This file is the custome hook file.
 * It contains useIntFiltersApplied custome hook to show internal filters.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { useQuery } from "@tanstack/react-query";
import { Key, useCallback, useMemo } from "react";
import { fetchTicketsTagsApi } from "src/services/TicketService/fetchTicketsTags";
import { getAllStatuses } from "src/services/TicketService/getAllStatuses";
import { TicketTags } from "src/store/slices/ticketTags/ticketTags.slice";
import { useAppSelector } from "src/store/store";
import {
  reportInternalFiltersActions,
  useReportInternalFilters,
} from "./useReportInternalFilters";
import { fetchAllUsers } from "src/services/UserService/fetchAllUsers";
import { getDraftStatusesOptions } from "src/services/TicketService/getDraftStatusesOptions";
import useAllCategory from "../../Children/FilterWrapper/children/AllCategory/useAllCategory";
import { getRatingOptions } from "src/services/TicketService/getRatingOptions";
import { getAllCategoriesForReports } from "src/services/report/botReportsStaticData.service";
import { useParams } from "react-router-dom";

/**
 * This hook is created to find the applied filters and show them.
 * useQuery is used at different places to fetch the filter data.
 */
function useIntFiltersApplied() {
  const { profileId } = useParams();
  const { internalFilters, dispatch } = useReportInternalFilters();
  const { data: allTicketStatus } = useQuery(
    ["useStatusFilter/getTicketStatusList"],
    {
      queryFn: getAllStatuses,
      staleTime: 600000,
      cacheTime: 600000,
    },
  );

  const { data: allUsers } = useQuery(["userData/getAllUsers"], {
    queryFn: async () => {
      const users: Array<{
        id: string;
        email: string;
        imageUrl?: string | null;
        name?: string | null;
        status: string;
      }> = await fetchAllUsers({ start: 0, limit: 1000 }, true);
      return users;
    },
    staleTime: 600000,
    cacheTime: 600000,
  });

  const { data: allDraftStatus } = useQuery(
    ["draftStatus/getDraftStatusesOptions"],
    {
      queryFn: getDraftStatusesOptions,
      staleTime: 600000,
      cacheTime: 600000,
    },
  );

  const { data: allTags } = useQuery(["useTagFilter/fetchTicketsTagsApi"], {
    queryFn: async () =>
      fetchTicketsTagsApi() as Promise<{
        allTags: TicketTags;
        allTagsIds: number[];
      }>,
    staleTime: 600000,
    cacheTime: 600000,
  });
  const allBrands = useAppSelector(
    (state) => state.globals.brandSignatureData.brands,
  );

  // 23 Apr 2024 Commenting this api call as related filter is hided for now
  // const { data: botCategories } = useQuery(
  //   ["botCategories/getAllCategoriesForReports", profileId],
  //   {
  //     queryFn: async () => {
  //       if (profileId) {
  //         return getAllCategoriesForReports({ botId: profileId });
  //       }
  //     },
  //     enabled: profileId ? true : false,
  //     staleTime: 600000,
  //     cacheTime: 600000,
  //   }
  // );

  const allChatRatings = useMemo(() => {
    return [
      { id: "1", name: "Like" },
      { id: "0", name: "Dislike" },
    ];
  }, []);

  /**
   * Clearing any filter.
   */
  const clearAFilter = useCallback(
    (key: keyof typeof internalFilters.filters) => {
      dispatch([reportInternalFiltersActions.clearOneAppliedFilter, key]);
    },
    [],
  );

  const { allCategories } = useAllCategory();

  const { data: ratings } = useQuery(["ratings/getRatingOptions"], {
    queryFn: getRatingOptions,
    staleTime: 600000,
    cacheTime: 600000,
  });

  const agentTypes = useMemo(() => {
    return [
      { id: "agent", name: "Agent" },
      { id: "lead_agents", name: "Lead Agents" },
      { id: "admin", name: "Admin" },
      { id: "support", name: "Support" },
    ];
  }, []);

  const chatStatusTypes = useMemo(() => {
    return [
      { id: "resolved", name: "Resolved" },
      { id: "unresolved", name: "Unresolved" },
    ];
  }, []);

  /**
   * Finding applied filters by computing
   */
  const intFiltersApplied = useMemo(() => {
    const fa: Array<{ name: string; value: string; callback: () => void }> = [];
    if (internalFilters.filters.brands?.length) {
      fa.push({
        name: "Brands",
        value: (allBrands ?? [])
          .filter(
            (data) =>
              internalFilters.filters.brands?.includes((data.id + "") as any),
          )
          .map((data) => data.name)
          .join(", "),
        callback: () => {
          clearAFilter("brands");
        },
      });
    }
    if (internalFilters.filters.ticketStatus?.length) {
      fa.push({
        name: "Ticket Status",
        value:
          allTicketStatus?.allTicketStatusesIds
            .filter(
              (data) =>
                internalFilters.filters.ticketStatus?.includes(
                  (data + "") as any,
                ),
            )
            .map((data) => allTicketStatus.allTicketStatuses[data].statusName)
            .join(", ") ?? "",
        callback: () => {
          clearAFilter("ticketStatus");
        },
      });
    }
    if (internalFilters.filters.assignedTo?.length) {
      fa.push({
        name: "Assigned To",
        value: (allUsers ?? [])
          ?.filter(
            (data) =>
              internalFilters.filters.assignedTo?.includes(
                (data.id + "") as any,
              ),
          )
          .map((data) => data.name ?? data.email)
          .join(", "),
        callback: () => {
          clearAFilter("assignedTo");
        },
      });
    }
    if (internalFilters.filters.tags?.length) {
      fa.push({
        name: "Ticket Tags",
        value:
          allTags?.allTagsIds
            .filter(
              (data) =>
                internalFilters.filters.tags?.includes((data + "") as any),
            )
            .map((data) => allTags.allTags[data].tagName)
            .join(", ") ?? "",
        callback: () => {
          clearAFilter("tags");
        },
      });
    }
    // 29 Apr 2024 Commenting this as the related filter is not implemented on backend
    // if (internalFilters.filters.draftedBy?.length) {
    //   fa.push({
    //     name: "Drafted By",
    //     value: (allUsers ?? [])
    //       ?.filter((data) =>
    //         internalFilters.filters.draftedBy?.includes((data.id + "") as any)
    //       )
    //       .map((data) => data.name ?? data.email)
    //       .join(", "),
    //     callback: () => {
    //       clearAFilter("draftedBy");
    //     },
    //   });
    // }
    if (internalFilters.filters.chatRatings?.length) {
      fa.push({
        name: "Chat Rating",
        value: (allChatRatings ?? [])
          ?.filter(
            (data) =>
              internalFilters.filters.chatRatings?.includes(
                (data.id + "") as any,
              ),
          )
          .map((data) => data.name ?? "")
          .join(", "),
        callback: () => {
          clearAFilter("chatRatings");
        },
      });
    }
    // 23 Apr 2024 Commenting this as related filter is hided for now
    // if (internalFilters.filters.botArticleCategoryIds?.length) {
    //   fa.push({
    //     name: "Article Categories",
    //     value: (botCategories ?? [])
    //       ?.filter((data) =>
    //         internalFilters.filters.botArticleCategoryIds?.includes((data.categoryId + "") as any)
    //       )
    //       .map((data) => data.categoryName ?? "")
    //       .join(", "),
    //     callback: () => {
    //       clearAFilter("botArticleCategoryIds");
    //     },
    //   });
    // }
    if (internalFilters.filters.draftStatus?.length) {
      fa.push({
        name: "Draft Status",
        value:
          allDraftStatus?.allDraftStatusesIds
            .filter(
              (id) =>
                internalFilters.filters.draftStatus?.includes((id + "") as any),
            )
            .map((id) => allDraftStatus?.allDraftStatuses[id].name)
            .join(", ") ?? "",
        callback: () => {
          clearAFilter("draftStatus");
        },
      });
    }
    if (internalFilters.filters.articleCategoryIds?.length) {
      fa.push({
        name: "Article Category",
        value:
          allCategories
            ?.filter(
              (data) =>
                internalFilters.filters.articleCategoryIds?.includes(
                  (data.categoryId + "") as any,
                ),
            )
            .map((data) => data.categoryName)
            .join(", ") ?? "",
        callback: () => {
          clearAFilter("articleCategoryIds");
        },
      });
    }
    // 25 Apr 2024 Commenting this as related filter is hided for now
    // if (internalFilters.filters.articleRating?.length) {
    //   fa.push({
    //     name: "Article Rating",
    //     value:
    //       ratings
    //         ?.filter(
    //           (data) =>
    //             internalFilters.filters.articleRating?.includes(
    //               (data.id + "") as any,
    //             ),
    //         )
    //         .map((data) => data.name)
    //         .join(", ") ?? "",
    //     callback: () => {
    //       clearAFilter("articleRating");
    //     },
    //   });
    // }
    if (internalFilters.filters.authorIds?.length) {
      fa.push({
        name: "Authored By",
        value: (allUsers ?? [])
          ?.filter(
            (data) =>
              internalFilters.filters.authorIds?.includes(
                (data.id + "") as any,
              ),
          )
          .map((data) => data.name ?? data.email)
          .join(", "),
        callback: () => {
          clearAFilter("authorIds");
        },
      });
    }
    if (internalFilters.filters.ratings?.length) {
      fa.push({
        name: "Ratings",
        value: (ratings ?? [])
          ?.filter(
            (data) =>
              internalFilters.filters.ratings?.includes((data.id + "") as any),
          )
          .map((data) => data.name)
          .join(", "),
        callback: () => {
          clearAFilter("ratings");
        },
      });
    }
    if (internalFilters.filters.agentTypes?.length) {
      fa.push({
        name: "Agent",
        value: agentTypes
          .filter(
            (data) =>
              internalFilters.filters.agentTypes?.includes(
                (data.id + "") as any,
              ),
          )
          .map((data) => data.name)
          .join(", "),
        callback: () => {
          clearAFilter("agentTypes");
        },
      });
    }
    if (internalFilters.filters.chatStatus?.length) {
      fa.push({
        name: "Chat Status",
        value: internalFilters.filters.chatStatus,
        callback: () => {
          clearAFilter("chatStatus");
        },
      });
    }
    return fa;
  }, [
    internalFilters,
    allBrands,
    allTicketStatus,
    allUsers,
    allTags,
    allDraftStatus,
    allCategories,
    ratings,
    chatStatusTypes,
    agentTypes,
  ]);

  return { intFiltersApplied };
}

export default useIntFiltersApplied;
