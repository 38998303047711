import { IPreChatFormSetting } from "./preChatFormSetting.slice";

const fetchPreChatFormDataFulfilled = (
  state: IPreChatFormSetting,
  { payload }: {payload: any}
) => {
  state = {
    ...state,
    ...payload,
    preChatFormDataAjaxStatus: "fulfilled",
  };
  return state;
};


export default { fetchPreChatFormDataFulfilled };
