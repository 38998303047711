/**
 * This file defines a React component responsible for showing delete confirmation modal
 *
 * @author @Anubhav-busibud
 * @author @navjyot-busibud
 */
import styles from "./DeleteQA.module.scss";
import { Spinner } from "react-bootstrap";
interface Props {
  question: string;
  onHide: () => void;
  isLoading?: boolean;
  handleDeleteQA: () => void;
  deletedSuccess?: boolean;
  setDeletedSuccess: (value: boolean) => void;
}

const DeleteQA = ({
  question,
  onHide,
  handleDeleteQA,
  isLoading,
  deletedSuccess,
  setDeletedSuccess,
}: Props) => {
  return (
    <div>
      {!deletedSuccess ? (
        <div className="text-center px-4">
          <span className={`d-block mb-3 ${styles.enquiry}`}>
            <i className="fa-solid fa-circle-exclamation"></i>
          </span>
          <p className={`${styles.modalHead}`}>
            Are you sure you want to delete the following question?
            <span>{question}</span>
          </p>
          <span className={`${styles.modalSubHeading}`}>
            This will delete all the data related to this Question/Answer
            combination.
          </span>
          <div className="d-flex justify-content-center align-items-center mt-2 mb-2">
            <button
              className={`px-1 bg-white ${styles.cancelBtn}`}
              onClick={(e) => {
                e.stopPropagation();
                onHide();
              }}
            >
              Cancel
            </button>
            <button
              className={`px-1 ${styles.confirmBtn}`}
              disabled={isLoading}
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteQA();
              }}
            >
              {isLoading ? (
                <Spinner
                  className="my-auto mx-1"
                  animation="border"
                  color="white"
                  size="sm"
                />
              ) : (
                `Yes I'm sure`
              )}
            </button>
          </div>
        </div>
      ) : (
        <div className="text-center">
          <div className={`mx-auto mt-2 ${styles.checkBox}`}>
            <span>
              <i className="fa-solid fa-check"></i>
            </span>
          </div>
          <p className={`${styles.deleteText}`}>Deleted!</p>
          <button
            className={`px-2 mx-auto mb-2 ${styles.okBtn}`}
            onClick={(e) => {
              e.stopPropagation();
              onHide();
              setDeletedSuccess(false);
            }}
          >
            Okay
          </button>
        </div>
      )}
    </div>
  );
};

export default DeleteQA;
