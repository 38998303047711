import { axiosJSON } from "src/globals/axiosEndPoints";

interface Params {
  start?: number;
  limit?: number;
  filter?: {
    categoryIds?: number[];
    bugStatusKeys?: Array<"submitted" | "investigating" | "resolved">;
    priorityKeys?: Array<"normal" | "high" | "needThisFixedRightAway">;
  };
}

interface BugTotalCount{
        totalBugsTicketCount: number;
    }

const getBugsTicketCount = async (param?: Params) => {
  const { data: res } = await axiosJSON.post(
    `/api/selfService/bugManagement/getBugsTicketCount`,
    param,
  );

  if (res.err || res.error) {
    throw res.msg ?? res.message ?? "Something went wrong!";
  }

  let ret: BugTotalCount

  ret = res.data;

  return ret;
};

export default getBugsTicketCount;
