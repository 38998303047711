import styles from "./ViewDraft.module.scss";
import RemarkComp from "../RemarkComp";
import ViewDraftStatus from "../ViewDraftStatus";
import DraftMsg from "../DraftMsg";
import DraftHeader from "../DraftHeader";
import Editor from "../Editor";
import sendLogo from "src/assets/images/send.png";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { useEffect, useRef, useState } from "react";
import {
  addEditRemarkData,
  fetchRemarkData,
} from "src/store/slices/draftFlow/draftFlow.slice";
import InfiniteScroll from "src/components/InfiniteScroll";
import { v4 as uuid } from "uuid";
import { findAndReplaceCID } from "src/utils/utils";

function ViewDraft({
  ticketId,
  onAnyChange,
}: {
  ticketId: number | string;
  onAnyChange: () => void;
}) {
  const dispatch = useAppDispatch();
  const draftMsg = useAppSelector((state) => state.draftFlow.draftMsg);
  const scopes = useAppSelector(
    (state) => state.globals.currentUserData?.scopes
  );
  const currentState = useRef({ scopes, ticketId });
  useEffect(() => {
    currentState.current = { scopes, ticketId };
  }, [scopes, ticketId]);

  const draftRemarks = useAppSelector((state) => state.draftFlow.draftRemarks);
  const draftRemarkIds = useAppSelector(
    (state) => state.draftFlow.draftRemarkIds
  );
  const remarkTotalCount = useAppSelector(
    (state) => state.draftFlow.remarkTotalCount
  );
  const draftMsgHistory = useAppSelector(
    (state) => state.draftFlow.draftMsgHistory
  );
  const isDraftMsgEditing = useAppSelector(
    (state) => state.draftFlow.isDraftEditing
  );
  const [remark, setRemark] = useState("");

  const [selectedFilesAddRemark, setSelectedFilesAddRemark] = useState<any>([]);
  const [isFileUploadingAddRemark, setIsFileUploadingAddRemark] =
    useState<boolean>(false);

  const clearAddRemarksInputData = () => {
    setSelectedFilesAddRemark([]);
  };

  return (
    <>
      <ViewDraftStatus ticketId={ticketId} onAnyChange={onAnyChange} />
      <div
        className={`${
          isDraftMsgEditing === true ? `` : styles.draftMsgNRemarkMainDiv
        }`}
      >
        <>
          {draftMsg && (
            <>
              <InfiniteScroll
                loadMore={() => {
                  dispatch(
                    fetchRemarkData({ msgId: draftMsg.messageId as any })
                  );
                }}
                hasMore={
                  isDraftMsgEditing === true
                    ? false
                    : draftRemarkIds.length < remarkTotalCount
                }
                className={`${styles.remarksH} ${
                  isDraftMsgEditing === false ? styles.infiniteScrollStyle : ``
                }`}
                // draft message and draft message edit components are showing inside inifinite scroll,
                // so while edit draft message we don't need scroll bars from infinite scroll
                disableScrollingClass={isDraftMsgEditing === true}
                initialDataLoaded={true}
              >
                {/* Show the draft message and edit draft message */}
                {draftMsg !== null && (
                  <DraftMsg
                    draftMsgHistory={draftMsgHistory}
                    onAnyChange={onAnyChange}
                    {...draftMsg}
                  />
                )}{" "}
                {/* End of show draft message */}
                {/* Show the draft remarks */}
                {isDraftMsgEditing !== true &&
                  scopes &&
                  scopes.includes("view_draft_remark") && (
                    <>
                      <DraftHeader add={"Remarks Thread"} className={`mt-2`} />
                      {draftRemarkIds.map((value, index) => {
                        return (
                          <RemarkComp
                            key={index}
                            msgId={draftMsg.messageId as any}
                            {...draftRemarks[value]}
                          />
                        );
                      })}
                    </>
                  )}{" "}
                {/*  End of show draft remarks*/}
              </InfiniteScroll>
            </>
          )}
        </>
      </div>

      {isDraftMsgEditing !== true &&
        scopes &&
        scopes.includes("view_draft_remark") &&
        scopes.includes("add_draft_remark") && (
          <Editor
            showView={false}
            options={{
              placeholder: "Type here...",
              min_height: 120,
              max_height: 120,
            }}
            value={remark}
            onChange={(value) => {
              setRemark(value);
            }}
            autosavePrefix={`HELPDESK_REMARKS_${ticketId}`}
            // editorClassName={`${styles.editor}`}
            editorClassName={`${styles.editor1}`}
            selectedFiles={selectedFilesAddRemark}
            setSelectedFiles={setSelectedFilesAddRemark}
            isFileUploading={isFileUploadingAddRemark}
            setIsFileUploading={setIsFileUploadingAddRemark}
            uniqueID={uuid()}
            buttons={
              <>
                <button
                  className={`btn d-flex my-auto mx-2 saveBtn`}
                  disabled={isFileUploadingAddRemark === true}
                  onClick={(e) => {
                    remark.length &&
                      dispatch(
                        addEditRemarkData({
                          remarkId: null,
                          remark: findAndReplaceCID(remark),
                          attachmentBatchNumber: selectedFilesAddRemark
                            ? selectedFilesAddRemark.map(function (
                                value: any,
                                index: any
                              ) {
                                return value.batchNumber;
                              })
                            : [],
                          callback: clearAddRemarksInputData, // clear the attachments selected after adding remark
                        })
                      );
                    setTimeout(() => {
                      setRemark("");
                    }, 0);
                  }}
                >
                  <span className={`my-auto`}>Add Remark</span>
                  <span className={`mx-2 my-auto`}></span>
                  <img src={sendLogo} className={`my-auto`} />
                </button>
              </>
            }
          />
        )}
    </>
  );
}

export default ViewDraft;
