import { axiosJSON } from "src/globals/axiosEndPoints";

export interface UserRole {
  id: string | number;
  role: string;
}

export type UserRoleList = {
  roles: { [id: number | string]: UserRole };
  rolesIds: Array<number | string>;
};

export async function getUserRoles() {
  const { data: res } = await axiosJSON.get(
    `/api/setting/user/getUserRoleList`
  );
  if (res.err) {
    throw res.msg;
  }
  const roles: any = {};
  const rolesIds: any = [];
  res.data.forEach((role: UserRole) => {
    roles[role.id] = role;
    rolesIds.push(role.id);
  });
  return { roles, rolesIds } as UserRoleList;
}
