import { axiosJSON } from "src/globals/axiosEndPoints";
import { BugData } from "./getBugByCategory.service";

interface BugResolveAgentDetails {
  agentId: number;
  agentName: string;
  agentProfileImgUrl: string | null;
}

export interface BugResolve {
  bugResolveDateGmt: string;
  bugResolveAgentDetails: BugResolveAgentDetails;
}

const updateBugResolution = async (param: {
  bugId: number;
  resolutionMessage: string;
}) => {
  const { data: res } = await axiosJSON.post(
    `/api/selfService/bugManagement/bugResolution`,
    param,
  );

  if (res.err || res.error) {
    throw res.msg ?? res.message ?? "Something went wrong!";
  }

  let ret: BugResolve;

  ret = res.data;

  return ret;
};

export default updateBugResolution;
