import React, { useCallback, useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { addTag, AddTagParams } from "src/services/Settings/Tags/addTag";
import { Tag } from "src/services/Settings/Tags/getAllTag";
import {
  updateTag,
  UpdateTagParams,
} from "src/services/Settings/Tags/updateTag";
import {
  fetchAllTheTags,
  resetTagsSettings,
} from "src/store/slices/settings/tags/tagsSettings.slice";
import { useAppDispatch } from "src/store/store";
import styles from "../AddTag.module.scss";

function AddTagModal({
  showModal,
  setShowModal,
  editTagData,
}: {
  showModal: boolean;
  setShowModal: any;
  editTagData: null | Tag;
}) {
  const dispatch = useAppDispatch();
  // const [showModal, setShowModal] = useState(false);
  const [tagName, setTagName] = useState<string>("");
  const [tagDescription, setTagDescription] = useState<string>("");
  const [showEditAddLoader, setShowEditAddLoader] = useState<boolean>(false);

  const onShow = useCallback(() => {
    setShowModal(true);
  }, []);
  const onHide = useCallback(() => {
    setTagName("");
    setTagDescription("");
    setShowEditAddLoader(false);
    setShowModal(false);
  }, []);

  useEffect(() => {
    if (editTagData !== null) {
      setTagName(editTagData.tagName);
      setTagDescription(editTagData.tagDescription);
    }
  }, [editTagData]);

  /* Function to handle tag name change */
  const hanldeTagNameChange = (e: any) => {
    e.preventDefault();
    setTagName(e.target.value);
  };

  /* Function to handle tag description change */
  const hanldeTagDescChange = (e: any) => {
    e.preventDefault();
    setTagDescription(e.target.value);
  };

  /* Function to handle add tag submit*/
  const hanldeAddOrEditTagSubmit = (e: any) => {
    e.preventDefault();
    setShowEditAddLoader(true);
    if (tagName.length > 0) {
      if (editTagData === null) {
        const addTagParams: AddTagParams = {
          name: tagName.trim(),
          description: tagDescription.trim(),
        };
        addTag(addTagParams)
          .then((res) => {
            dispatch(resetTagsSettings());
            dispatch(fetchAllTheTags());
            onHide();
            pushTheToast({
              type: "success",
              text: "Tag added successfully!",
              position: "top-right",
            });
          })
          .catch((err) => {
            setShowEditAddLoader(false);
            pushTheToast({
              type: "danger",
              text: "Failed to add tag!",
              position: "top-right",
            });
          });
      } else {
        const updateTagParams: UpdateTagParams = {
          id: parseInt(editTagData.id + ""),
          name: tagName.trim(),
          description: tagDescription.trim(),
        };
        updateTag(updateTagParams)
          .then((res) => {
            dispatch(resetTagsSettings());
            dispatch(fetchAllTheTags());
            onHide();
            pushTheToast({
              type: "success",
              text: "Tag updated successfully!",
              position: "top-right",
            });
          })
          .catch((err) => {
            setShowEditAddLoader(false);
            pushTheToast({
              type: "danger",
              text: "Failed to update tag!",
              position: "top-right",
            });
          });
      }
    } else {
      setShowEditAddLoader(false);
      pushTheToast({
        type: "warning",
        text: "Tag name is required!",
        position: "top-right",
      });
    }
  };

  return (
    <Modal
      show={showModal}
      onHide={onHide}
      centered={true}
      dialogClassName={`${styles.modalDialog}`}
      contentClassName={`${styles.modalContent}`}
      backdropClassName={`${styles.backDrop}`}
    >
      <div className="">
        <div>
          <div>
            <div
              className={`${styles.header} d-flex  justify-content-between align-items-center px-4 `}
            >
              <h5 className={`m-2 mb-0 ${styles.addHead}`}>
                {editTagData === null ? "Add a Tag" : "Edit Tag"}
              </h5>
              {/* <button
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close "
                className={`${styles.closeButton} btn-close ms-2 me-0`}
                onClick={onHide}
              >
                {" "}
              </button> */}
              <span
                onClick={onHide}
                className={`cursor-pointer ${styles.closeButton} ms-1`}
              >
                <i className="fa-solid fa-xmark"></i>
              </span>
            </div>
          </div>
        </div>

        <div>
          <div className="mt-4">
            <div className="col-12">
              <form className="team-form">
                <div className="mx-4 my-2">
                  <label
                    htmlFor="exampleInputPassword1"
                    className={`${styles.formLabel} form-label ms-2`}
                  >
                    {" "}
                    Tag Name
                  </label>
                  <div className="input-box d-flex align-items-center">
                    <input
                      data-lpignore="true"
                      type="text"
                      placeholder="Name"
                      value={tagName}
                      onChange={(e) => hanldeTagNameChange(e)}
                      className={`form-control ms-2 ${styles.tagInput}`}
                      id="teamName"
                    />
                  </div>
                </div>

                <div className="mx-4 mt-3">
                  <label
                    htmlFor="exampleInputPassword1"
                    className={`${styles.formLabel} form-label ms-2`}
                  >
                    {" "}
                    Description (Optional)
                  </label>
                  <div className="input-box d-flex align-items-center">
                    <input
                      data-lpignore="true"
                      type="text"
                      placeholder="Description"
                      className={`form-control ms-2 ${styles.tagInput}`}
                      id="teamName"
                      value={tagDescription}
                      onChange={(e) => hanldeTagDescChange(e)}
                    />
                  </div>
                </div>

                <div className="d-flex flex-row-reverse mt-4 mb-3 me-3 p-2 ">
                  <div className="add-user add-team ">
                    <button
                      type="submit"
                      className={`d-flex justify-content-center align-items-center ${styles.pink_btn_submit}  `}
                      onClick={(e) => hanldeAddOrEditTagSubmit(e)}
                    >
                      {showEditAddLoader === true ? (
                        <Spinner
                          className="my-auto mx-1"
                          animation="border"
                          color="white"
                          size="sm"
                        />
                      ) : undefined}

                      {editTagData === null ? "Add Tag" : "Update Tag"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default AddTagModal;
