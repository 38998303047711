import { axiosJSON } from "src/globals/axiosEndPoints";
import APIResponse from "./APIReponse";

interface AddTeam {
    teamId : number;
}

interface AddTeamParams {
    teamName : string;
}



export async function addTeams (params : AddTeamParams) {
  

    const { data : res }: any = await axiosJSON.post<APIResponse<AddTeam>>(
      "/api/setting/team/add",
        params
    );

    if(res.error === true )
    {
      throw new Error(res.message as string)
    }

    return res.data as AddTeam 
  } 
