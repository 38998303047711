import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useRef } from "react";
import { AJAXSTATUS } from "src/globals/constants";
import { useCustomerSegments } from "src/routes/CustomerSegments/hooks/useCustomerSegments";
import getCustomFiltersService from "src/services/CustomerSegments/getCustomFilters.service";

/**
 * Custom hook for fetching and managing custom filters data based on segment type and ID.
 *
 * @param options - Options for custom filters.
 * @param options.segmentType - The type of segment.
 * @param options.segmentId - The ID of the segment.
 * @returns Object containing custom filters data and fetch status.
 */
function useCustomFilters({
  segmentType,
  segmentId,
}: {
  segmentType: string;
  segmentId: string;
}) {
  const { segmentDataForUpdate } = useCustomerSegments();

  // Using react-query's useQuery hook to fetch data
  const {
    data: customFiltersData,
    status,
    refetch: refetchCustomFilter,
  } = useQuery(["useCustomFilters", segmentType, segmentId], {
    queryFn: async () => {
      return segmentType
        ? await getCustomFiltersService({
            segmentType,
            segmentId,
          })
        : undefined;
    },
    cacheTime: 1000,
    staleTime: 1000,
    networkMode: "always",
  });

  // Keeping track of the current status using useRef
  const currentStatus = useRef({ status });

  useMemo(() => {
    currentStatus.current.status = status;
  }, [status]);

  // useEffect to refetch custom filters when segmentDataForUpdate changes and status is "success"
  useEffect(() => {
    if (
      segmentDataForUpdate === null &&
      currentStatus.current.status === "success"
    ) {
      refetchCustomFilter();
    }
  }, [segmentDataForUpdate]);

  // Mapping status to customFiltersFetchStatus based on AJAXSTATUS
  return {
    customFiltersData,
    customFiltersFetchStatus:
      status === "success"
        ? "fulfilled"
        : status === "loading"
          ? "pending"
          : ("rejected" as AJAXSTATUS),
  };
}

export default useCustomFilters;
