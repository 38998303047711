import styles from "./TargetWidget.module.scss";
import hand from "src/assets/images/hand.png";
import hifi from "src/assets/images/hifi.gif";
import celebrate from "src/assets/images/celebrate.gif";
import done from "src/assets/images/thumsUp.gif";
import ring from "src/assets/images/ring.gif";
import moreInfo from "src/assets/images/caution.png";
import tata from "src/assets/images/tata.png";
import send from "src/assets/images/widgetSend.png";
import deleteImg from "src/assets/images/deleteBox.png";
import { v4 as uuidv4 } from "uuid";
import {
  Button,
  Overlay,
  OverlayTrigger,
  Popover,
  Tooltip,
} from "react-bootstrap";
import { useCallback, useEffect, useRef, useState } from "react";
import DelBox from "./Children/DelBox/CardWidgetButton";
import { useParams } from "react-router-dom";
import { isTemplateLiteralToken } from "typescript";
import CardWidgetButton from "./Children/DelBox/CardWidgetButton";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  UIButtonProps,
  updateData,
  UPDATE_NEW_TARGETED_DATA,
} from "src/store/slices/targetedMessage/targetedMessage.slice";
import Announcement from "../Announcement/Announcement";
import { useSelector } from "react-redux";
import Triggers from "./Triggers";

function getLocalStorage(key: any, initialValue = null) {
  if (typeof window === "undefined") {
    return initialValue;
  }
  try {
    const item = window.localStorage.getItem(key);
    return item ? JSON.parse(item) : initialValue;
  } catch (error) {
    console.log(error);
    return initialValue;
  }
}

function clearLocalStorage(key: any) {
  try {
    if (typeof window !== "undefined") {
      window.localStorage.removeItem(key);
    }
  } catch (error) {
    console.log(error);
  }
}

const TargetWidget = (props: {
  sideBarType: number;
  doneSavingData: (data: any) => void;
  isSavingData: boolean;
}) => {
  const params = useParams();

  let initialSelectedIcon;
  if (params.settingName === "newAnnouncement") {
    initialSelectedIcon = 4;
  } else {
    initialSelectedIcon = 1;
  }

  const [selectedIcon, setSelectedIcon] = useState(initialSelectedIcon);

  const getImageurl = useAppSelector((state) => {
    return state.targetedMessage.messageProperties.elements.image.url;
  });

  // useEffect(() => {
  //   if (getImageurl)

  //     };
  //   setUrlText(getImageurl);
  // }, []);

  const [urlAltText, setUrlAltText] = useState<string>("");

  const getImageurlalt = useAppSelector((state) => {
    return state.targetedMessage.messageProperties.elements.image
      .alternativeText;
  });

  const [exampleImages, setExampleImages] = useState([
    {
      data_Idx: 1,
      Styles: "",
      backGroundColor: "1492e6",
      src: hifi,
      url: "1",
      alternateText: "Waving Hand",
    },
    {
      data_Idx: 2,
      Styles: styles.gifBox2,
      backGroundColor: "ff5b5b",
      src: done,
      url: "2",
      alternateText: "Thumbs Up",
    },
    {
      data_Idx: 3,
      Styles: styles.gifBox3,
      backGroundColor: "710f43",
      src: ring,
      url: "3",
      alternateText: "Ring Bell",
    },
    {
      data_Idx: 4,
      Styles: styles.gifBox4,
      backGroundColor: "ffd285",
      src: celebrate,
      url: "4",
      alternateText: "Celebrate",
    },
  ]);

  if (getImageurl.length > 1) {
    exampleImages[4] = {
      data_Idx: exampleImages.length + 1,
      Styles: styles.addedImage,
      backGroundColor: "white",
      src: getImageurl,
      url: getImageurl,
      alternateText: urlAltText,
    };
  }

  useEffect(() => {
    if (getImageurl.length === 1) {
      setSelectedIcon(Number(getImageurl));
    } else if (getImageurl.length >= 1) {
      setSelectedIcon(5);
    }
  }, [getImageurl]);

  useEffect(() => {
    setUrlText(exampleImages[selectedIcon - 1].url);
    setUrlAltText(exampleImages[selectedIcon - 1].alternateText);
  }, [selectedIcon]);

  const [urlText, setUrlText] = useState(exampleImages[selectedIcon - 1]?.url);

  function handleChangeUrl(e: any) {
    setUrlText(e.target.value);
  }

  function handleChangeAltText(e: any) {
    setUrlAltText(e.target.value);
  }

  // Example Images Popover
  const popover = (
    <Popover className={`${styles.popover}`}>
      <div className={` ${styles.dropMenu} `}>
        <span className={` ${styles.example}`}>Examples</span>
        <div
          className={`d-flex justify-content-between mt-1`}
          onClick={(e: any) => {
            const target = e.target as HTMLElement;
            let dataIdx: string | null;
            if (target.classList.contains(styles.gifBox)) {
              dataIdx = target.getAttribute("data-idx");
            } else {
              dataIdx = target.parentElement?.getAttribute("data-idx") ?? null;
            }

            const idx: number = dataIdx !== null ? parseInt(dataIdx) : -1;

            if (idx > 0) {
              setSelectedIcon(idx);
            }
          }}
        >
          {exampleImages.slice(0, 4).map((Item) => {
            return (
              <div
                className={`${styles.gifBox}   ${Item.Styles} ${
                  selectedIcon === Item.data_Idx ? styles.selectedGiftBox : ""
                }`}
                role="button"
                data-idx={Item.data_Idx}
                onClick={() => {
                  document.body.click();
                }}
              >
                <img src={Item.src} alt="" className={`${styles.gifImg}`} />
              </div>
            );
          })}
        </div>
        <div>
          {/* Image Url Input Box  */}
          <div className={`my-1`}>
            <label className={`${styles.label}`}>Image URL</label>
            <input
              name="Imageurl"
              type="text"
              className={`${styles.inputBoxOverlay}`}
              alt=""
              value={urlText}
              onChange={(e: any) => handleChangeUrl(e)}
            />
          </div>

          {/* Alternate Text Input Box  */}
          <div className={`my-1`}>
            <div className={`d-flex align-items-center mb-1`}>
              <label className={`me-1 ${styles.label}`}>Alternative text</label>
              <span>
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip className={` ${styles.toolTipCustom}`}>
                      Alternative text is essential for people with disabilities
                      who use screen recorders y{" "}
                    </Tooltip>
                  }
                >
                  {({ ref, ...triggerHandler }) => (
                    <div {...triggerHandler} ref={ref}>
                      <img
                        className={`${styles.moreInfo}`}
                        src={moreInfo}
                        alt={``}
                      />
                    </div>
                  )}
                </OverlayTrigger>
              </span>
            </div>

            <input
              name="ImageAltText"
              className={`${styles.inputBoxOverlay}`}
              type="text"
              alt=""
              value={urlAltText}
              onChange={(e: any) => handleChangeAltText(e)}
            />
          </div>

          {/* Add Button  */}
          <div className={`mt-2 ${styles.btnDiv}`}>
            <button
              onClick={() => {
                exampleImages[4] = {
                  data_Idx: exampleImages.length + 1,
                  Styles: styles.addedImage,
                  backGroundColor: "white",
                  src: urlText,
                  url: urlText,
                  alternateText: urlAltText,
                };
                setExampleImages(exampleImages);
                setSelectedIcon(5);
                document.body.click();
              }}
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </Popover>
  );

  const [showInputTitle, setShowInputTitle] = useState(false);

  const [showInputSubTitle, setShowInputSubTitle] = useState(false);

  function handleClickTitle() {
    setShowInputTitle(true);
  }

  function handleClickSubTitle() {
    setShowInputSubTitle(true);
  }

  const buttons = useAppSelector((state) => {
    return state.targetedMessage.messageProperties.buttons.map((btn) => {
      return {
        selectedAction: btn.action,
        text: btn.text,
        buttonStyles: btn.btnStyles,
        id: uuidv4(),
        websiteUrl: btn.websiteUrl,
      };
    });
  });

  const getTitle = useAppSelector((state) => {
    return state.targetedMessage.messageProperties.elements.title;
  });

  const getSubtitleText = useAppSelector((state) => {
    return state.targetedMessage.messageProperties.elements.subtitle;
  });

  let [triggerButtons, setTriggerButtons] = useState<Partial<UIButtonProps>[]>(
    buttons.length <= 0
      ? [
          {
            id: uuidv4(),
            text: "Check it out ",
            buttonStyles: "default",
            selectedAction: 1,
            websiteUrl: "",
            order: 1,
          },
        ]
      : buttons
  );

  let [selectedBtnId, setSelectedBtnId] = useState(triggerButtons[0]?.id ?? "");

  function addTriggerButton(ev: any) {
    setTriggerButtons((currentButtons) => {
      const lastBtn = currentButtons[currentButtons.length - 1];
      let nextOrder = 1;
      if (lastBtn) {
        const currenrOrder = lastBtn?.order ?? 0;
        nextOrder = currenrOrder + 1;
      }
      return [
        ...currentButtons,
        {
          id: uuidv4(),
          // text: "Button ".concat(nextOrder.toString()),
          text: "Check it out ",
          buttonStyles: "default",
          selectedAction: 1,
          websiteUrl: "",
          order: nextOrder,
        },
      ];
    });
  }

  const removeTriggerButton = useCallback(function _removeNewButton(ev: any, id: any) {
    clearLocalStorage(`form${id}`);
    setTriggerButtons((currentButtons) =>
      currentButtons.filter((btn) => btn.id !== id)
    );
  }, []);

  function AddButton() {
    setTriggerButtons((addButton) => [
      ...addButton,
      {
        id: uuidv4(),
        text: "Check it Out ",
        buttonStyles: "primary",
        selectedAction: 1,
        websiteUrl: "",
      },
    ]);
  }

  function changeButtonProps(props: {
    text?: string;
    style?: string;
    selectedAction?: number;
    websiteUrl?: string;
  }) {
    const selectedBtnIdx = triggerButtons.findIndex(
      (btn) => btn.id === selectedBtnId
    );
    // console.log({ selectedBtnIdx, props });
    if (selectedBtnIdx !== -1) {
      const selectedBtn = triggerButtons[selectedBtnIdx];
      selectedBtn.text = props?.text ?? selectedBtn.text;
      selectedBtn.buttonStyles = props?.style ?? selectedBtn.buttonStyles;

      selectedBtn.btnStyles = props?.style ?? selectedBtn.buttonStyles;
      selectedBtn.selectedAction =
        props?.selectedAction ?? selectedBtn.selectedAction;
      selectedBtn.websiteUrl = props?.websiteUrl ?? selectedBtn.websiteUrl;

      setTriggerButtons((addButton) => {
        addButton[selectedBtnIdx] = selectedBtn;
        return [...addButton];
      });
    }
  }

  function handleTrashButton(id: any) {
    setTriggerButtons((addButton) => {
      return addButton.filter((item) => item !== id);
    });
  }

  const buttonPopover = (
    <Popover className={`${styles.buttonPopover} shadow`}>
      {/* <CardWidgetButton
        selectedBtn={triggerButtons.find((btn) => btn.id === selectedBtnId)}
        changeButtonProps={changeButtonProps}
      /> */}
    </Popover>
  );

  const [titleText, setTitleText] = useState(getTitle === "" ? "" : getTitle);

  const [subtitleTitleText, setSubtitleTitleText] = useState(
    getSubtitleText === "" ? "" : getSubtitleText
  );

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (props.isSavingData) {
      let messageType;
      if (params.settingName === "newAnnouncement") {
        messageType = "Announcement";
      } else {
        messageType = "Greeting";
      }

      let templateType;
      if (props.sideBarType === 1) {
        templateType = "Card";
      } else if (props.sideBarType === 2) {
        templateType = "Quick Replies";
      } else {
        templateType = "Plain Text";
      }
      // dispatch(
      //   updateData({
      //     messageType,
      //     messageProperties: {
      //       templateType,
      //       elements: {
      //         title: titleText,
      //         subtitle: subtitleTitleText,
      //         image: {
      //           url: urlText,
      //           alternativeText: urlAltText,
      //         },
      //       },
      //       buttons: triggerButtons.map((btn) => {
      //         return {
      //           btnStyles: btn.btnStyles,
      //           buttonStyles: btn.buttonStyles,
      //           action: btn.selectedAction,
      //           selectedAction: btn.selectedAction,
      //           id: btn.id,
      //           momentSize: btn.momentSize,
      //           text: btn.text,
      //           value: btn.value,
      //           websiteUrl: btn.websiteUrl,
      //         } as UIButtonProps;
      //       }),
      //     },
      //   })
      // );
      dispatch(
        UPDATE_NEW_TARGETED_DATA({
          messageProperties: {
            templateType: templateType,
            elements: {
              title: titleText,
              subtitle: subtitleTitleText,
              image: {
                url: urlText,
                alternativeText: urlAltText,
              },
            },
            buttons: triggerButtons.map((btn) => {
              return {
                btnStyles: btn.btnStyles,
                buttonStyles: btn.buttonStyles,
                action: btn.selectedAction,
                selectedAction: btn.selectedAction,
                id: btn.id,
                momentSize: btn.momentSize,
                text: btn.text,
                value: btn.value,
                websiteUrl: btn.websiteUrl,
              } as UIButtonProps;
            }),
          },
        })
      );
      props.doneSavingData({ buttons: triggerButtons });
    }
    return () => {};
  }, [props.isSavingData]);

  return (
    <>
      <div>
        {props.sideBarType === 1 ? (
          <>
            <div
              className={`d-flex justify-content-center align-items-center flex-column ${styles.widgetWrapper}`}
            >
              <div className={`${styles.targetWidget}`}>
                <div className={`${styles.headerBox}`}>
                  {params.settingName === "newAnnouncement" ? (
                    <>
                      <div
                        className={`${styles.innerBox}   `}
                        role="button"
                        style={{
                          backgroundColor:
                            "#" +
                            `${
                              exampleImages[selectedIcon - 1].backGroundColor
                            }`,
                        }}
                      >
                        <OverlayTrigger
                          trigger="click"
                          placement="right"
                          overlay={popover}
                          rootClose={true}
                        >
                          {selectedIcon === 5 ? (
                            <img
                              className={`${styles.addedImage} `}
                              src={urlText}
                              alt=""
                            />
                          ) : (
                            <img
                              className={`${styles.handImg} `}
                              src={exampleImages[selectedIcon - 1]?.src}
                              alt=""
                            />
                          )}
                        </OverlayTrigger>

                        <div
                          className={`btn-group dropend ${styles.trashBox} `}
                        >
                          <button
                            type="button"
                            className={`btn  ${styles.deleteBox} `}
                            aria-expanded="false"
                          >
                            <span>
                              <i className="fa-solid fa-trash"></i>
                            </span>
                          </button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {selectedIcon === 5 ? (
                        <div className={`${styles.innerBox} `} role="button">
                          <OverlayTrigger
                            trigger="click"
                            placement="right"
                            overlay={popover}
                            rootClose={true}
                          >
                            <img
                              className={`${styles.addedImage} `}
                              src={urlText}
                              alt=""
                            />
                          </OverlayTrigger>

                          <div className={`btn-group  ${styles.trashBox}`}>
                            <button
                              type="button"
                              className={`btn dropdown-toggle ${styles.deleteBox}`}
                              aria-expanded="false"
                            >
                              <span>
                                <i className="fa-solid fa-trash"></i>
                              </span>
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div
                          className={`${styles.innerBox} `}
                          role="button"
                          style={{
                            backgroundColor:
                              "#" +
                              `${
                                exampleImages[selectedIcon - 1].backGroundColor
                              }`,
                          }}
                        >
                          <OverlayTrigger
                            trigger="click"
                            placement="right"
                            overlay={popover}
                            rootClose={true}
                          >
                            <img
                              className={`${styles.handImg} `}
                              src={exampleImages[selectedIcon - 1]?.src}
                              alt=""
                            />
                          </OverlayTrigger>

                          <div className={`btn-group  ${styles.trashBox}`}>
                            <button
                              type="button"
                              className={`btn dropdown-toggle ${styles.deleteBox}`}
                              aria-expanded="false"
                            >
                              <span>
                                <i className="fa-solid fa-trash"></i>
                              </span>
                            </button>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div className={`${styles.contentBox} `}>
                  {showInputTitle ? (
                    <>
                      <input
                        type="text"
                        placeholder="Title"
                        className={`${styles.inputBox}`}
                        onChange={(e: any) => {
                          setTitleText(e.target.value);
                        }}
                        value={titleText}
                      />
                    </>
                  ) : (
                    <>
                      {titleText === "" ? (
                        <div>
                          <span
                            className={`me-1 ${styles.hiiSpan}`}
                            onClick={handleClickTitle}
                            role="button"
                          >
                            Hi 👋
                          </span>
                        </div>
                      ) : (
                        <div>
                          <span
                            className={`me-1 ${styles.hiiSpan}`}
                            onClick={handleClickTitle}
                            role="button"
                          >
                            {titleText}
                          </span>
                        </div>
                      )}
                    </>
                  )}
                  {showInputSubTitle ? (
                    <>
                      <input
                        type="text"
                        placeholder="Subtitle"
                        className={`${styles.inputBox}`}
                        onChange={(e: any) => {
                          setSubtitleTitleText(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      {subtitleTitleText === "" ? (
                        <div
                          className={`${styles.subSpan}`}
                          onClick={handleClickSubTitle}
                          role="button"
                        >
                          make sure to check out the exciting new features we’ve
                          just released 🔥
                        </div>
                      ) : (
                        <div
                          className={`${styles.subSpan}`}
                          onClick={handleClickSubTitle}
                          role="button"
                        >
                          {subtitleTitleText}
                        </div>
                      )}
                    </>
                  )}

                  <Triggers
                    buttons={triggerButtons as UIButtonProps[]}
                    addButton={addTriggerButton}
                    removeButton={removeTriggerButton}
                    setSelectedBtnId={setSelectedBtnId}
                    changeButtonProps={changeButtonProps}
                    selectedBtn={triggerButtons.find(
                      (btn) => btn.id === selectedBtnId
                    )}
                  />
                </div>
              </div>
              <div className={`${styles.sendBox}`}>
                <div>
                  <input
                    className={`${styles.sendInput}`}
                    placeholder={`Type here..`}
                    type="text"
                  />
                  <div>
                    <img
                      className={`${styles.sendImg}`}
                      src={send}
                      alt="send"
                    />
                    <img
                      className={`${styles.delImg}`}
                      src={deleteImg}
                      alt="delete"
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : props.sideBarType === 2 ? (
          <>
            <div
              className={`d-flex justify-content-center align-items-center flex-column ${styles.widgetWrapper}`}
            >
              <div className={`${styles.targetWidget}`}>
                <div className={`${styles.contentBox}`}>
                  <span>
                    <span className={`me-1 ${styles.hiiSpan}`}>
                      How can I help you?
                    </span>
                  </span>

                  <button className={`${styles.addBtn}`}>
                    <span>+ Add Button</span>
                  </button>
                </div>
              </div>

              <div className={`${styles.sendBox} text-center`}>
                <button className={` ${styles.addMsgInpBtn}  `}>
                  <span>+ Add Message Input</span>
                </button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              className={`d-flex justify-content-center align-items-center flex-column ${styles.widgetWrapper}`}
            >
              <div className={`${styles.targetWidget}`}>
                <div className={`${styles.contentBox}`}>
                  <span>
                    <span className={`me-1 ${styles.hiiSpan}`}>
                      Hey 👋 What brings you here today?
                    </span>
                  </span>
                </div>
              </div>
              <div className={`${styles.sendBox}`}>
                <div>
                  <input
                    className={`${styles.sendInput}`}
                    placeholder={`Type here..`}
                    type="text"
                  />
                  <div>
                    <img
                      className={`${styles.sendImg}`}
                      src={send}
                      alt="send"
                    />
                    <img
                      className={`${styles.delImg}`}
                      src={deleteImg}
                      alt="delete"
                    />
                  </div>
                </div>
                {/* <button className={`${styles.addMsgBtn}`}>+ Add Button</button> */}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default TargetWidget;
