import styles from "./TrackerDetails.module.scss";
const TrackerDetails = () => {
  return (
    <>
      <div className={`overflow-auto`}>
        <div className={`${styles.trackerMain}`}>
          <div className={`d-flex justify-content-between`}>
            <div className={`${styles.tableHead}`}>Name</div>
            <div className={`${styles.tableHead}`}>Type</div>
            <div className={`${styles.tableHead}`}>Value</div>
            <div className={`${styles.tableHead}`}>Conversion window</div>
          </div>
          <div className={`d-flex justify-content-between`}>
            <div className={`${styles.tableRow}`}>Same value</div>
            <div className={`${styles.tableRow}`}>Fixed</div>
            <div className={`${styles.tableRow}`}>10 USD</div>
            <div className={`${styles.tableRow}`}>30 days</div>
          </div>
        </div>
      </div>
    </>
  );
};
export default TrackerDetails;
