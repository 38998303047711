import styles from "./GlobalLoader.module.scss";
import loadingGif from "src/assets/images/loading.gif";
import processingGif from "src/assets/images/processing.gif";
import helpLogo from "src/assets/images/hLogo.png";
import saufterLogo from "src/assets/images/saufter.gif";
import { useCallback, useEffect, useRef, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { allOngoingAxiosRequests } from "src/globals/axiosEndPoints";

const logo = window.location.hostname.includes(
  process.env.REACT_APP_LOGO_HOST_NAME + ""
)
  ? saufterLogo
  : helpLogo;

type LoaderNames =
  | "Normal"
  | "TransferTicket"
  | "ProgressBar"
  | "None"
  | "TicketDetails"
  | "MergeTickets"
  | "Initializing";
type ShowGlobalLoaderFun = (loaderName: LoaderNames) => void;
type GlobalLoaderProgressFun = (progress: number) => void;

export let showGlobalLoader: ShowGlobalLoaderFun = () => {};
export let globalLoaderProgress: GlobalLoaderProgressFun = () => {};

export let globalLoaderName: LoaderNames = "None";

const Loaders = {
  Normal: () => {
    return <img src={loadingGif} />;
  },
  TransferTicket: () => {
    const [now, setNow] = useState(0);
    useEffect(() => {
      globalLoaderProgress = setNow;
      return () => {
        globalLoaderProgress = () => {};
      };
    }, [false]);
    return (
      <div className={`d-flex flex-column`}>
        <div className={`d-flex`}>
          <img src={processingGif} className={`my-auto mx-2`} />
          <span className={`my-auto mx-2`}>
            Transfering ticket to Helplama Helpdesk
          </span>
        </div>
        <ProgressBar animated className={`${styles.bar} my-4`} now={now} />
      </div>
    );
  },
  TicketDetails: () => {
    const [now, setNow] = useState(0);
    useEffect(() => {
      globalLoaderProgress = setNow;
      return () => {
        globalLoaderProgress = () => {};
      };
    }, [false]);
    return (
      <div className={`d-flex flex-column`}>
        <div className={`d-flex`}>
          <img
            src={processingGif}
            className={`my-auto mx-2 ${styles.process}`}
          />
          <div className={`my-auto mx-2 d-flex flex-column`}>
            <img src={logo} className={`mx-auto my-2 ${styles.logo}`} />
            <span>Getting Ticket details for you...</span>
            <ProgressBar animated className={`${styles.bar} my-4`} now={now} />
          </div>
        </div>
      </div>
    );
  },
  ProgressBar: () => {
    const [now, setNow] = useState(0);
    useEffect(() => {
      globalLoaderProgress = setNow;
      return () => {
        globalLoaderProgress = () => {};
      };
    }, [false]);
    return <ProgressBar animated className={`${styles.bar}`} now={now} />;
  },
  None: () => {
    return <div></div>;
  },
  MergeTickets: () => {
    const [now, setNow] = useState(0);
    useEffect(() => {
      globalLoaderProgress = setNow;
      return () => {
        globalLoaderProgress = () => {};
      };
    }, [false]);
    return (
      <div className={`d-flex flex-column`}>
        <div className={`d-flex`}>
          <img
            src={processingGif}
            className={`my-auto mx-2 ${styles.process}`}
          />
          <div className={`my-auto mx-2 d-flex flex-column`}>
            <img src={logo} className={`mx-auto my-2 ${styles.logo}`} />
            <span>Merging and Updating Tickets for you...</span>
            <ProgressBar animated className={`${styles.bar} my-4`} now={now} />
          </div>
        </div>
      </div>
    );
  },
  Initializing: () => {
    return (
      <div className={`d-flex flex-column`}>
        <div className={`d-flex`}>
          <img
            src={processingGif}
            className={`my-auto mx-2 ${styles.process}`}
          />
          <div className={`my-auto mx-2 d-flex flex-column`}>
            <img src={logo} className={`mx-auto my-2 ${styles.logo}`} />
            <span>Initializing...</span>
            <ProgressBar animated className={`${styles.bar} my-4`} now={100} />
          </div>
        </div>
      </div>
    );
  },
};

function GlobalLoader() {
  // Declaring state variable to manage loader visibility and a ref to hold the timeout reference
  const [loaderName, setLoaderName] = useState("None" as LoaderNames);
  const timeoutRef = useRef(null as any);

  // Function to handle auto-hiding of the loader based on ongoing Axios requests
  const handleAutoHide = useCallback((currentLoaderName: LoaderNames) => {
    // Clearing any existing timeout
    clearInterval(timeoutRef.current);

    // Checking if a loader is currently active
    if (currentLoaderName !== "None") {
      let timeoutCount = 0;
      // Setting an interval to periodically check the ongoing Axios requests
      timeoutRef.current = setInterval(() => {
        // If there are no ongoing requests, increment the timeout counter
        if (allOngoingAxiosRequests === 0) {
          timeoutCount++;
          // If no requests for a certain period, hide the loader
          if (timeoutCount === 5) {
            setLoaderName("None");
          }
        } else {
          // Resetting the timeout counter if there are ongoing requests
          timeoutCount = 0;
        }
      }, 1000);
    }
  }, []);

  useEffect(() => {
    showGlobalLoader = (loaderName) => {
      handleAutoHide(loaderName);
      setLoaderName(loaderName);
    };

    return () => {
      showGlobalLoader = () => {};
      globalLoaderName = "None";
    };
  }, [false]);
  useEffect(() => {
    globalLoaderName = loaderName;
  }, [loaderName]);
  const Component = Loaders[loaderName];
  return (
    <div
      className={`${styles.loader} ${styles[loaderName]} ${
        loaderName !== "None" ? "d-flex" : "d-none"
      }`}
    >
      <Component />
    </div>
  );
}

export default GlobalLoader;
