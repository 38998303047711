import { axiosJSON } from "src/globals/axiosEndPoints";

export interface IAssignedAgent {
  id: number | string;
  name: string;
  email: string;
}

export interface ITabTypeCount {
  all: number;
  chatting: number;
  queued: number;
  waiting_for_reply: number;
}

export interface ITrafficDataItem {
  id: number;
  customerName: string;
  customerEmail: string;
  action: string | null;
  status: string;
  assignedAgent: IAssignedAgent;
}

export interface ITrafficUpdatedListRes {
  tabCount: ITabTypeCount;
  trafficData: {
    all: Array<ITrafficDataItem>;
    chatting: Array<ITrafficDataItem>;
    queued: Array<ITrafficDataItem>;
    waiting_for_reply: Array<ITrafficDataItem>;
  };
}

export async function getLiveChatTrafficFullList(
  params: undefined,
  signal?: any
) {
  const { data: res } = await axiosJSON.post(
    `/api/liveChat/traffic/getFullList`,
    params,
    { signal }
  );

  console.log("getLiveChatTrafficFullList res :: ", res);

  if (res.err) {
    throw res.msg;
  }

  const ret: ITrafficUpdatedListRes = {
    tabCount: {
      all: 0,
      chatting: 0,
      queued: 0,
      waiting_for_reply: 0,
    },
    trafficData: {
      all: [],
      chatting: [],
      queued: [],
      waiting_for_reply: [],
    },
  };

  ret.tabCount.all = res.tabCount.all;
  ret.tabCount.chatting = res.tabCount.chatting;
  ret.tabCount.queued = res.tabCount.queued;
  ret.tabCount.waiting_for_reply = res.tabCount.waiting_for_reply;

  ret.trafficData.all = res.trafficData.all;
  ret.trafficData.chatting = res.trafficData.chatting;
  ret.trafficData.queued = res.trafficData.queued;
  ret.trafficData.waiting_for_reply = res.trafficData.waiting_for_reply;

  return ret;
}
