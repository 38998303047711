import React, { useState } from "react";
import styles from "./DeleteSchedule.module.scss";
import { Spinner } from "react-bootstrap";
interface Props {
  onHide: () => void;
  onSubmit: () => void;
}
const DeleteSchedule = ({ onHide, onSubmit }: Props) => {
  return (
    <div className="py-3 px-4">
      <h3 className={`${styles.heading}`}>Delete Message</h3>
      <span className={`${styles.subHeading}`}>
        Are you sure you want to delete this follow-up?
      </span>
      <div className="d-flex justify-content-end mt-5">
        <button className={`me-4 ${styles.cancelBtn}`} onClick={onHide}>
          No
        </button>
        <button className={`${styles.submitBtn}`} onClick={onSubmit}>Yes</button>
      </div>
    </div>
  );
};

export default DeleteSchedule;
