import { ITimeZoneListRes } from "src/services/Settings/Timezone/fetchTimeZoneList";
import { UpdateProfileImageUser } from "src/services/Settings/Users/updateProfileImageUser";
import { IProfileSettings } from "./profileSettings.slice";

function fetchUserFulfilled(state: IProfileSettings, { payload }: any) {
  state.userDataAjaxStatus = "fulfilled";
  state.userRoles = payload.roles;
  state.userData = payload.user;
}

function updateUserFulfilled(
  state: IProfileSettings,
  { payload }: { payload: any }
) {
  state.userDataAjaxStatus = "fulfilled";
  state.userData = payload;
}

function updateUserProfilePicFulfilled(
  state: IProfileSettings,
  { payload }: { payload: UpdateProfileImageUser }
) {
  state.profilePicAjaxStatus = "fulfilled";
  if (state.userData) {
    state.userData.profileImageId = payload.profileImageId;
    state.userData.profileImageUrl = payload.profileImageUrl;
  }
}
function deleteUserProfilePicFulfilled(
  state: IProfileSettings,
  { payload }: any
) {
  state.profilePicAjaxStatus = "fulfilled";
  if (state.userData) {
    state.userData.profileImageId = null;
    state.userData.profileImageUrl = null;
  }
}

function fetchTimeZoneListDataFulfilled(
  state: IProfileSettings,
  {payload}: {payload: ITimeZoneListRes}
){
  // state.timezoneListData.data = [...state.timezoneListData.data, ...payload.data];
  state.timezoneListData.data = payload.data;
  state.timezoneListData.metaData.count = payload.metaData.count;
  state.timezoneListData.metaData.total = payload.metaData.total;
  state.fetchTimezoneListAjaxStatus = "fulfilled";
}

export default {
  fetchUserFulfilled,
  updateUserFulfilled,
  updateUserProfilePicFulfilled,
  deleteUserProfilePicFulfilled,
  fetchTimeZoneListDataFulfilled
};
