import { axiosJSON } from "src/globals/axiosEndPoints";
import { IUpdatePreChatFormElement } from "src/services/LiveChat/Settings/preChatForm/updatePreChatFormData";
import APIResponse from "src/services/Settings/Teams/APIReponse";


export interface IUpdateConfigureFormDataPayload{
  id: number | string;
  user_details?: Array<IUpdatePreChatFormElement> ;
}


export async function updateConfigureFormData(params: IUpdateConfigureFormDataPayload) {
  
  const { data: res } = await axiosJSON.post(
    "/api/botProfile/update",
    params
  );

  if (res.err === true) {
    throw new Error(res.msg as string);
  }

  return res.data;
}
