import {
  useState,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
} from "react";
import styles from "./Step4.module.scss";
import { BrandOption } from "src/routes/BotSettings/Children/BotBox/BrandModal/BrandModal";
import { useParams } from "react-router-dom";
import BrandDropdown from "src/routes/BotSettings/Children/BrandDropdown/BrandDropdown";
import { useSwiper } from "swiper/react";

interface Props {
  setStepsData: Dispatch<SetStateAction<any>>;
  showError: boolean;
  setShowError: Dispatch<SetStateAction<boolean>>;
  setHideBtn: any;
}

/**
 * Component for showing Step 4 of Modify Order automation steps
 */
function Step4({ setStepsData, showError, setShowError, setHideBtn }: Props) {
  // States for brands
  const [selectedBrand, setSelectedBrand] = useState<BrandOption>();

  /**
   * Change handler for brand dropdown
   */
  const handleChange = useCallback((selectedOption: BrandOption) => {
    // Clear the error
    setShowError(false);
    // Update brand dropdown value
    setSelectedBrand(selectedOption);
    // Update steps form data
    setStepsData((prev: any) => {
      return {
        ...prev,
        brandId: selectedOption?.value,
      };
    });
  }, []);
  const { type } = useParams();
  const swiper = useSwiper();
  const [activeIndex, setActiveIndex] = useState(-1);

  swiper.on("slideChange", (swiper) => {
    setActiveIndex(swiper.activeIndex);
  });

  // fetch the step data
  useEffect(() => {
    if (activeIndex === 2) {
      setHideBtn(true);
    } else {
      setHideBtn(false);
    }
  }, [activeIndex, setHideBtn]);
  if (activeIndex === 2) {
    return (
      <>
        <div className="p-3 mt-3">
          <div className="w-75 mx-auto">
            {/* Heading */}
            <h3 className={`ms-5 ${styles.heading}`}>Choose a brand</h3>
            <div className={`mt-2 ms-5 ${styles.subheadingModel}`}>
              This will appear in your support ticket system for the following
              brand:
            </div>
            <div className="pt-2 ms-5 w-75">
              {/* Brands Dropdown */}
              <BrandDropdown
                selectedBrand={selectedBrand}
                setSelectedBrand={handleChange}
                className={showError ? styles.errorStyle : ""}
                error={showError ? "Please select a brand" : ""}
                isAutomation={type !== undefined}
                automationType={type}
              />
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return null;
  }
}

export default Step4;
