import { useAppSelector } from "src/store/store";
import ReportTicketBody from "../../../ReportTicketBody/ReportTicketBody";

const ChatSatisfactionReports = () => {
  const ui_visibility = useAppSelector(
    (state) => state.globals.currentUserData?.ui_visibility,
  );

  return (
    <div className="mb-2">
      {ui_visibility?.includes(
        "liveChat_chatSatisfaction_chatSatisfactionReports",
      ) && (
        <ReportTicketBody
          endPoint={"liveChat/chatSatisfaction/chatSatisfactionReports"}
          title={"Chat Satisfaction"}
          // tooltip={"Unresolved = Pending or Open Tickets"}
          tabProps={{
            showComparison: false,
            showDailyPart: true,
            showListView: true,
          }}
        />
      )}
    </div>
  );
};

export default ChatSatisfactionReports;
