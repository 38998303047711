import { axiosJSON } from "src/globals/axiosEndPoints";

export type ASSIGN_CHAT_RES_STATUS_CODE =
  | ""
  | "invalid_chat_request_id" // for this err => true
  | "invalid_user" // for this err => true
  | "limit_reached" // for this err => false
  | "chat_assigned" // for this err => false
  | "chat_not_assigned"; // for this err => false

export interface IAssignMultipleAgentsParam {
  chatId: string | number;
  agentIds: Array<string | number>;
}

interface AgentInfo{
  id: string|number;
  name: string;
  imgUrl: string|null;
}
export interface IAssignMultipleAgentsRes {
  chatAssignedTo: Array<AgentInfo>;
  agentsAdded: Array<number|string>;
  agentsRemoved: Array<number|string>;
}

export async function assignMultipleAgents(params: IAssignMultipleAgentsParam) {
  const { data: res } = await axiosJSON.post(
    `/api/liveChat/agent/multipleAssignChat`,
    params
  );

  if (res.err) {
    throw res.msg;
  }

  return res.data as IAssignMultipleAgentsRes;
}
