import styles from "./TicketViewTableHeader.module.scss";
import { useAppSelector } from "src/store/store";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { allColumnComponents } from "src/hooks/useTicketViews/ticketViewsConfig";

function TicketViewTableHeader({
  disableBulkAction,
}: {
  disableBulkAction: boolean;
}) {
  const { viewType } = useParams();

  const currColumnList = useAppSelector((state) => state.ticket.currColumnList);

  const getHeaderComp = () => {
    if (viewType === undefined) return <></>;
    return currColumnList[viewType].map((col) => {
      const HeaderCell = allColumnComponents[col.id].headerComponent;
      if (col.show === true) {
        return (
          <HeaderCell
            key={uuidv4()}
            header={col.name}
            disableBulkAction={col.id === 1 ? disableBulkAction : undefined}
            className={`${
              styles[col.name.toLowerCase().replace(" ", "_").replace(" ", "_")]
            }`}
          />
        );
      } else {
        return <div key={uuidv4()} style={{ display: "none" }} />;
      }
    });
  };

  return (
    <div>
      <div className={`d-flex ${styles["table_head"]} py-2`}>
        {getHeaderComp()}
      </div>
    </div>
  );
}

export default TicketViewTableHeader;
