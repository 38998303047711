import { axiosJSON } from "src/globals/axiosEndPoints";

interface BrandData {
  id: number | string;
  name: string;
  email: string;
  imageURL?: string|null;
  isPublicAttachmentUrl?: boolean;
}

export interface GetAllLinkableBrandData {
  brands: { [brand: number | string]: BrandData };
  brandIds: Array<number | string>;
}

export interface IGetAllLinkableBrandParam{
  integrationId: number;
}

export async function getAllLinkableBrands(payload: IGetAllLinkableBrandParam) {
  const { data: res } = await axiosJSON.post("/api/chatSetting/widget/getLinkableBrands", payload);

  if (res.err === true) {
    throw new Error(res.msg as string);
  }

  const ret: GetAllLinkableBrandData = {
    brands: {},
    brandIds: [],
  };
  res.data.forEach((brand: BrandData) => {
    ret.brands[brand.id] = brand;
    ret.brandIds.push(brand.id);
  });

  return ret;
}
