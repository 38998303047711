import { IHelpCenterSetting } from "./helpCenterSetting.slice";

const fetchHelpCenterDataFulfilled = (
  state: IHelpCenterSetting,
  { payload }: {payload: any}
) => {
  state = {
    ...state,
    helpCenterData: payload,
    helpCenterDataAjaxStatus: "fulfilled",
  };

  return state;
};

const updateHelpCenterDataFulfilled = (
  state: IHelpCenterSetting,
  { payload }: {payload: any}
) => {
  state.updateHelpCenterDataAjaxStatus = "fulfilled";
};

export default { fetchHelpCenterDataFulfilled, updateHelpCenterDataFulfilled };
