import styles from "./TicketID.module.scss";
const TicketID = ({
  ticketId,
  date,
  right
}: {
  ticketId: number | string;
  date: string;
  right: boolean;
}) => {
  return (
    <div className={`d-flex flex-column`}>
      <div className={`d-flex ${right && "flex-row-reverse"}`}>
        <span className={`${styles.TicketId} mx-0 my-auto`}>
          Ticket ID: {ticketId}
        </span>
        <span className={`mx-2 my-auto`}></span>
        <span className={`${styles.ticketStatus} mx-0 my-auto`}>
          Current ticket
        </span>
      </div>
      <div className={`d-flex my-2 ${right && "flex-row-reverse"}`}>
        <span className={`${styles.created} mx-1`}>
          Created On {new Date(date).toLocaleDateString()}
        </span>
        <span className={`mx-auto`}></span>
      </div>
    </div>
  );
};
export default TicketID;
