import styles from "./WebHookStepsVideoModal.module.scss";

const WebHookStepsVideoModal = ({ url }: { url: string }) => {
  return (
    <div>
      <div className={`d-flex justify-content-between align-items-center`}>
        <h3 className={`mb-0 ${styles.modalHead}`}>
          Setting Up Webhooks in Shopify:
        </h3>
        <span className={`${styles.closeButton}`}>
          <i className="fa-solid fa-xmark"></i>
        </span>
      </div>
      <div>
        <video
          src={url}
          height={410}
        ></video>
      </div>
    </div>
  );
};

export default WebHookStepsVideoModal;
