import { useContext, useEffect, useMemo, useState } from "react";
import styles from "../CustomerEdit/CustomerEdit.module.scss";
import envelop from "src/assets/images/envelop.png";
import { useAppSelector } from "src/store/store";
import { validateEmail } from "src/utils/utils";
import { InputFieldsContext } from "../CustomerEdit/useInputFields";

function PrimaryEmail() {
  const { email, primaryEmailEditable } = useAppSelector(
    (state) => state.editcustomerdetails.customer,
  );

  const { primaryEmail, setPrimaryEmail } = useContext(InputFieldsContext);

  const emailValid = useMemo(() => {
    if (primaryEmailEditable && primaryEmail) {
      return validateEmail(primaryEmail);
    }
    return true;
  }, [primaryEmailEditable, primaryEmail]);

  return (
    <div className="mb-3">
      <label
        // htmlFor="name"
        className={`d-flex align-items-center form-label ${styles.labelName}`}
      >
        <div
          className={`d-flex justify-content-center align-items-center ${styles.msgBg}`}
        >
          <img
            src={envelop}
            className={`${styles.envelop}`}
            alt="email"
          />
        </div>{" "}
        <span className={`ps-1`}>Primary E-mail</span>
      </label>
      <input
        type="email"
        data-lpignore="true"
        className={`form-control ${styles.formInput} ${
          !emailValid ? "border-danger" : ""
        }`}
        id="name"
        aria-describedby="emailHelp"
        placeholder="please enter email"
        required
        name="Email"
        readOnly={primaryEmailEditable ? false : true}
        onChange={(e) => setPrimaryEmail(e.target.value ?? "")}
        value={primaryEmailEditable ? primaryEmail : email}
      />
      {!emailValid && (
        <small className={`${styles.errorMsg}`}>
          Please enter a valid email address
        </small>
      )}
    </div>
  );
}

export default PrimaryEmail;
