import styles from "./WhatsappChatPickBox.module.scss"

function WhatsappChatPickBox() {
  return (
    <div className={`${styles.pickBox} h-100 text-center d-flex align-items-center justify-content-center`}>
      <p className={`pt-2 ${styles.pickHead}`}>
        {"This chat is a whatsapp chat "}
      </p>
    </div>
  )
}

export default WhatsappChatPickBox