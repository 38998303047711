import UserAvatar from "src/components/UserAvatar";
import { useAppSelector } from "src/store/store";
import styles from "./AllOrderHeader.module.scss";

const AllOrderHeader = () => {
  const { customer } = useAppSelector((state) => state.allOrders);

  return (
    <div className={`${styles.allOrderHeader} `}>
      <div className={`${styles.profileIcon}`}>
        <UserAvatar
          size={21}
          name={customer?.name ?? "N A"}
          className={`${styles.allOrderImg}`}
        />
        <span className={`${styles.profileName}`}>
          {customer?.name ?? "NA"}
        </span>
      </div>
      {customer.email ? (
        <div className={`${styles.mailTo} `}>
          <span className={`${styles.messeanger}`}>
            <span>
              <i className={`fa-solid fa-envelope`}></i>
            </span>
            <span className={`ms-1`}>{customer?.email}</span>
          </span>
        </div>
      ) : (
        ""
      )}

      {customer?.phone ? (
        <div className={`${styles.callTo}`}>
          <span className={`${styles.messeanger}`}>
            <span>
              <i className={`fa-solid fa-phone`}></i>
            </span>
            <span className={`ms-2`}>{customer?.phone}</span>
          </span>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
export default AllOrderHeader;
