import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";

const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/botProfile/updatePriorities`
  : "/api/botProfile/updatePriorities";

export interface UpdatePrioritiesParams {
  ids: number[];
}

export interface UpdatePrioritiesResponse {
  err: boolean;
  msg: string;
}

export const updatePriorities = async (params: UpdatePrioritiesParams) => {
  const { data: res } = await axiosJSON.post(apiEndPoint, params);
  if (res.err) {
    throw res.msg;
  }
  return res.data as UpdatePrioritiesResponse;
};
