import { useCallback, useState, useEffect } from "react";
import { Modal, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { resolveChat } from "src/services/LiveChat/createTicket/resolveChat.service";
import {
  resetChatWidExchMsg,
  setIsResolved,
} from "src/store/slices/liveChatSetting/chatWidExchMsg/chatWidExchMsg.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import styles from "../../../../LiveChat.module.scss";
import style2 from "./ChatTabs.module.scss";
import CreateTicket from "./Children/CreateTicket/CreateTicket";
import SuccessMsg from "./Children/CreateTicket/SuccessMsg/SuccessMsg";
import { actions } from "src/store/slices/liveChatSetting/chatWidExchCust/chatWidExchCust.slice";
import {
  fetchCustomerData,
  fetchLiveChatSidebar,
} from "src/store/slices/liveChatSetting/chatWidExchCust/chatWidExchCust.thunks";
import { resetLiveChatTabQuickActionCreateTicket } from "src/store/slices/liveChatSetting/liveChatTabQuickActions/liveChatTabQuickActions.slice";
import { useRef } from "react";
import ReactSelect from "react-select";
import { truncate } from "fs";
import AssignedViewModel from "./Children/AssignedViewModel/AssignedViewModel";
import AssignChatTo from "./Children/AssignChatTo/AssignChatTo";
import UserDetailModal from "./Children/UserDetailModal/UserDetailModal";
import { SOCIAL_CHANNELS } from "src/services/LiveChat/messageExchange/getAllCustomers";
import profileIcon from "src/assets/images/profileIcon.png";
import profileActiveIcon from "src/assets/images/profileActiveIcon.png";
import { clearGetAllCustomersCache } from "src/services/LiveChat/messageExchange/getAllCustomers";

export type LiveMainChatTabs = "conversation" | "history";

interface Props {
  showRSBDetails: boolean;
  setShowRSBDetails: (showRSBDetails: boolean) => void;
}

const ProfileButton = ({ showRSBDetails, setShowRSBDetails }: Props) => {
  return (
    <>
      <div
        className={`me-2 ${styles.user} ${showRSBDetails ? styles.active : ""}`}
        onClick={() => {
          setShowRSBDetails(!showRSBDetails);
        }}
      >
        <span>
          <img
            src={`${showRSBDetails ? profileActiveIcon : profileIcon}`}
            alt=""
            width={14}
            height={14}
          />
        </span>
      </div>
    </>
  );
};

function ChatTabs({ showRSBDetails, setShowRSBDetails }: Props) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  let { chatId: currentChatId, tabNameMainChat, chatStatus } = useParams();
  const selectedCustomerStatus = useAppSelector(
    (state) => state.chatWidExchCust.selectedCustomerStatus,
  );
  const { createTicketFromLiveChatAjaxStatus } = useAppSelector(
    (state) => state.liveChatTabQuickActions,
  );

  const [onSelect, setOnSelect] = useState<any>();

  const {
    fetchCustAJAXStatus,
    selectedCustomerChatId,
    customers,
    chatIdList,
    chatAssignedNotification,
  } = useAppSelector((state) => state.chatWidExchCust);

  const [resolveAndCreateTicket, setResolveAndCreateTicket] =
    useState<boolean>(false);

  const [isResolveLoading, setIsResolveLoading] = useState<boolean>(false);

  const { activeCustomerInfo } = useAppSelector((state) => state.chatWidExhMsg);
  const [createdTicketId, setCreatedTicketId] = useState<number | string>(0);
  const [showTicketSuccess, setShowTicketSuccess] = useState(false);
  // if (selectedCustomerStatus === "archived") {
  //   tabNameMainChat = "history";
  // }
  const [showModal, setShowModal] = useState(false);
  const currentState = useRef({
    customers,
    chatIdList,
    selectedCustomerStatus,
    selectedCustomerChatId,
    chatStatus,
  });

  const onShow = useCallback(() => {
    setShowModal(true);
    setShowTicketSuccess(false);
    setCreatedTicketId(0);
  }, []);

  const onHide = useCallback(() => {
    // setResolveAndCreateTicket(false);
    setShowModal(false);
    setShowTicketSuccess(false);
    setCreatedTicketId(0);
  }, []);

  // when chat request id changes
  useEffect(() => {
    // reset tabs quick actions data
    dispatch(resetLiveChatTabQuickActionCreateTicket());
  }, [currentChatId]);

  useEffect(() => {
    currentState.current.customers = customers;
    currentState.current.chatIdList = chatIdList;
    currentState.current.selectedCustomerStatus = selectedCustomerStatus;
    currentState.current.selectedCustomerChatId = selectedCustomerChatId;
    currentState.current.chatStatus = chatStatus;
  }, [
    customers,
    chatIdList,
    selectedCustomerStatus,
    selectedCustomerChatId,
    chatStatus,
  ]);

  // function to handle resolve chat
  const handleResolveChat = (e: any) => {
    setIsResolveLoading(true);
    if (currentChatId) {
      const payloadChatId = currentChatId;
      resolveChat({ chatRequestId: currentChatId })
        .then((res) => {
          dispatch(fetchLiveChatSidebar());
          // update is resolved or not
          // refresh sidebar data
          // if (fetchCustAJAXStatus !== "pending") {
          // dispatch(resetChatWidExchMsg());
          // dispatch(
          //   actions.resetCustomersData({ loading: false, initialized: true })
          // );
          // dispatch(fetchCustomerData({ start: 0 }));

          //checking if there is any other chat exists in same tab if yes then will delete the resolved chat from current list ..
          //if there will be no chat left after removing resolved chat ..then redirect user to archived
          if (
            currentState.current.selectedCustomerStatus !== "archived" &&
            (currentState.current.chatIdList.length === 0 ||
              (currentState.current.chatIdList.length === 1 &&
                currentState.current.chatIdList.includes(
                  parseInt(payloadChatId),
                )))
          ) {
            clearGetAllCustomersCache();

            navigate(`/live-chat/chats/archived/${payloadChatId}`);
            if (currentState.current.selectedCustomerChatId == payloadChatId) {
              dispatch(setIsResolved({ isResolved: true }));
            }
          } else if (
            currentState.current.selectedCustomerStatus !== "archived"
          ) {
            if (payloadChatId) {
              let previousChatId: number | null = null;

              const chatIds = currentState.current.chatIdList.filter(
                (chatId, index) => {
                  if (payloadChatId != chatId) {
                    return true;
                  } else {
                    previousChatId =
                      index === 0
                        ? null
                        : parseInt(
                            currentState.current.chatIdList[index - 1] + "",
                          );
                    return false;
                  }
                },
              );

              dispatch(
                actions.removeCustomerFromChatList({
                  chatId: parseInt(payloadChatId),
                }),
              );

              // Clear the cache for all customers api call to refetch on changing tabs
              clearGetAllCustomersCache();

              const redirectChatId =
                previousChatId && previousChatId !== null
                  ? previousChatId
                  : chatIds[0];

              navigate(
                chatStatus && chatStatus !== undefined
                  ? window.location.pathname.replace(
                      `chats/${chatStatus}/${payloadChatId}`,
                      `chats/${chatStatus}/${redirectChatId}`,
                    )
                  : `/live-chat/chats/${currentState.current.selectedCustomerStatus}/${redirectChatId}`,
              );
            }
          } else {
            if (payloadChatId) {
              const customer = currentState.current?.customers[payloadChatId];
              if (customer) {
                dispatch(
                  actions.setCustomerData({
                    customer: { ...customer, isResolved: true },
                    checkForExistingCust: true,
                  }),
                );
              }
            }
            if (currentState.current.selectedCustomerChatId == payloadChatId) {
              dispatch(setIsResolved({ isResolved: true }));
            }
          }

          // }
          pushTheToast({
            text: `Chat is resolved${
              currentState.current.chatStatus !== "archived"
                ? " and moved to archive"
                : ""
            }`,
            position: "top-right",
            type: "success",
          });
          setIsResolveLoading(false);
        })
        .catch((err) => {
          pushTheToast({
            text: "Failed to resolve chat",
            position: "top-right",
            type: "danger",
          });
          setIsResolveLoading(false);
        });
    }
  };

  return (
    <div
      className={`d-flex flex-wrap align-items-center ${styles.chatHistory}`}
    >
      <div className={`d-flex align-items-center ms-0 mt-3 me-auto`}>
        <div
          className={`d-inline-flex align-items-center me-3 ${
            styles.chatLead
          } ${
            tabNameMainChat === "conversation" || tabNameMainChat == undefined
              ? styles.activeBtn
              : ""
          }`}
          onClick={() => {
            navigate(
              window.location.pathname.replace(
                `chats/${chatStatus}/${currentChatId}${
                  tabNameMainChat ? `/chattingTab/${tabNameMainChat}` : ""
                }`,
                `chats/${chatStatus}/${currentChatId}/chattingTab/conversation`,
              ),
            );
          }}
        >
          Conversation
        </div>
        {/* It is commented for now on 10/10/2022. Bugs/Updates given by the Yuvaraj */}
        <div
          className={`${styles.chatLead} ${
            tabNameMainChat === "history" ? styles.activeBtn : ""
          } d-inline-flex align-items-center`}
          onClick={() => {
            navigate(
              window.location.pathname.replace(
                `chats/${chatStatus}/${currentChatId}${
                  tabNameMainChat ? `/chattingTab/${tabNameMainChat}` : ""
                }`,
                `chats/${chatStatus}/${currentChatId}/chattingTab/history`,
              ),
            );
            setShowRSBDetails(false);
          }}
        >
          <span>History</span>
          {activeCustomerInfo?.historyCount &&
          activeCustomerInfo.historyCount > 0 ? (
            <span className={`ms-1 ${style2.ticketCount} px-2`}>
              <i className="fa-solid fa-clock-rotate-left pe-1"></i>
              {`${activeCustomerInfo?.historyCount}`}
            </span>
          ) : (
            <></>
          )}
        </div>
      </div>

      {/* 2/2/2023 Code for new Feature */}
      {activeCustomerInfo?.chatStatus === "" ||
      activeCustomerInfo?.chatStatus === "chat_in_progress_ai" ||
      (activeCustomerInfo?.chatAssignedTo &&
        activeCustomerInfo?.chatAssignedTo?.length !== 0) ? (
        <div className="d-flex me-2 ms-auto align-items-center flex-wrap">
          <span className={`mx-1 ${style2.assignTo}`}>Assigned to:</span>
          <div className={`ms-1`}>
            <AssignChatTo />
          </div>
        </div>
      ) : null}

      {activeCustomerInfo &&
        activeCustomerInfo.chatChannelType &&
        SOCIAL_CHANNELS.includes(activeCustomerInfo.chatChannelType) && (
          <div className={`d-flex mx-0 align-items-center pt-2`}>
            {!activeCustomerInfo.isResolved ? (
              <>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className={` ${styles.toolTipCustom}`}>
                      Resolve
                    </Tooltip>
                  }
                >
                  {({ ref, ...triggerHandler }) => (
                    <div
                      {...triggerHandler}
                      className={`me-2 ${
                        activeCustomerInfo.chatStatus === "chat_in_progress_ai" // checking if the chat is in progress or not if yes then we will disable the button style
                          ? styles.disabledCheckBox
                          : styles.checkBox
                      } `}
                      ref={ref}
                      onClick={(e) => {
                        if (
                          activeCustomerInfo.chatStatus ===
                          "chat_in_progress_ai" // checking if the chat is in progress or not if yes then we will disable the button
                        ) {
                          return;
                        } else {
                          handleResolveChat(e);
                        }
                      }}
                    >
                      {isResolveLoading ? (
                        <>
                          {" "}
                          <Spinner
                            className="my-auto mx-1"
                            animation="border"
                            color="black"
                            size="sm"
                          />
                        </>
                      ) : (
                        <>
                          <span>
                            <i
                              className={`${
                                activeCustomerInfo.chatStatus ===
                                "chat_in_progress_ai" // checking if the chat is in progress or not if yes then we are disabling the icon
                                  ? style2.disableIconFonts
                                  : style2.iconFonts
                              } fa-solid fa-check-double`}
                            ></i>
                          </span>
                        </>
                      )}
                    </div>
                  )}
                </OverlayTrigger>
              </>
            ) : (
              <>
                {" "}
                <div className="me-2 mb-2">
                  <span className={`${style2.ticketCreatedText}`}>
                    <i
                      className={`${style2.iconFonts} fa-solid fa-check-double`}
                    ></i>{" "}
                    Resolved
                  </span>
                </div>
              </>
            )}

            <ProfileButton
              showRSBDetails={showRSBDetails}
              setShowRSBDetails={setShowRSBDetails}
            />
          </div>
        )}

      {activeCustomerInfo &&
        activeCustomerInfo?.isResolved !== undefined &&
        !SOCIAL_CHANNELS.includes(activeCustomerInfo.chatChannelType + "") && (
          <div className={`d-flex mx-0 align-items-center pt-2`}>
            {!activeCustomerInfo?.ticketData ? (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip className={` ${styles.toolTipCustom}`}>
                    Create Ticket
                  </Tooltip>
                }
              >
                {({ ref, ...triggerHandler }) => (
                  <div
                    {...triggerHandler}
                    className={`me-2 ${
                      activeCustomerInfo.chatStatus === "chat_in_progress_ai" // checking if the chat is in progress or not if yes then we will disable the button style
                        ? styles.disabledCheckBox
                        : styles.checkBox
                    } `}
                    ref={ref}
                    onClick={() => {
                      if (
                        activeCustomerInfo.chatStatus === "chat_in_progress_ai" // checking if the chat is in progress or not if yes then we will disable the button
                      ) {
                        return;
                      } else {
                        setResolveAndCreateTicket(false);
                        onShow();
                      }
                    }}
                  >
                    <span>
                      <i
                        className={`${
                          activeCustomerInfo.chatStatus ===
                          "chat_in_progress_ai" // checking if the chat is in progress or not if yes then we are disabling the icon
                            ? style2.disableIconFonts
                            : style2.iconFonts
                        } fa-solid fa-ticket`}
                      ></i>
                    </span>
                  </div>
                )}
              </OverlayTrigger>
            ) : (
              <div className="me-2 mb-2">
                {activeCustomerInfo?.isResolved ? (
                  <>
                    <span className={`${style2.ticketCreatedText}`}>
                      <i
                        className={`${style2.iconFonts} fa-solid fa-check-double`}
                      ></i>{" "}
                      +{" "}
                    </span>
                    <span className={`${style2.ticketCreatedText}`}>
                      <i
                        className={`${style2.iconFonts} fa-solid fa-ticket`}
                      ></i>{" "}
                      Resolved
                    </span>
                  </>
                ) : (
                  <>
                    <span className={`${style2.ticketCreatedText}`}>
                      <i
                        className={`${style2.iconFonts} fa-solid fa-ticket`}
                      ></i>{" "}
                      Ticket Created
                    </span>
                  </>
                )}
              </div>
            )}

            <Modal
              backdropClassName={`${styles.modalBack}`}
              show={showModal}
              onHide={onHide}
              dialogClassName={`${style2.modalDialog}`}
              contentClassName={`${style2.modalContent}`}
              enforceFocus={false}
              centered={true}
            >
              {showTicketSuccess === true ? (
                <>
                  <SuccessMsg
                    createdTicketId={createdTicketId}
                    onHide={onHide}
                    resolveAndCreateTicket={resolveAndCreateTicket}
                  />
                </>
              ) : (
                <CreateTicket
                  onHide={onHide}
                  resolve={resolveAndCreateTicket}
                  isAnonymouseUser={
                    activeCustomerInfo.isAnonymousCustomer ?? false
                  } //Adding flag to isAnonymouseUser
                  heading={
                    resolveAndCreateTicket == true
                      ? "Resolve and create a ticket:"
                      : "Create a ticket:"
                  }
                  subHeading={
                    resolveAndCreateTicket == true
                      ? "The chat will be marked as resolved and a ticket will be created."
                      : "A ticket will be created for this chat."
                  }
                  submitBtn={
                    resolveAndCreateTicket == true
                      ? "Resolve and create a ticket"
                      : "Create a ticket"
                  }
                  chatId={activeCustomerInfo.chatId}
                  selectedCustomerEmail={activeCustomerInfo.email}
                  onSuccessCallback={(createdTicketId: number | string) => {
                    setShowTicketSuccess(true);
                    setCreatedTicketId(createdTicketId);
                  }}
                />
              )}
            </Modal>

            {!activeCustomerInfo?.isResolved ? (
              <>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className={` ${styles.toolTipCustom}`}>
                      Resolve
                    </Tooltip>
                  }
                >
                  {({ ref, ...triggerHandler }) => (
                    <div
                      {...triggerHandler}
                      className={`me-2 ${
                        activeCustomerInfo.chatStatus === "chat_in_progress_ai" // checking if the chat is in progress or not if yes then we will disable the button style
                          ? styles.disabledCheckBox
                          : styles.checkBox
                      } `}
                      ref={ref}
                      onClick={(e) => {
                        if (
                          activeCustomerInfo.chatStatus ===
                          "chat_in_progress_ai" // checking if the chat is in progress or not if yes then we will disable the button
                        ) {
                          return;
                        } else {
                          handleResolveChat(e);
                        }
                      }}
                    >
                      {isResolveLoading ? (
                        <>
                          {" "}
                          <Spinner
                            className="my-auto mx-1"
                            animation="border"
                            color="black"
                            size="sm"
                          />
                        </>
                      ) : (
                        <>
                          <span>
                            <i
                              className={`${
                                activeCustomerInfo.chatStatus ===
                                "chat_in_progress_ai" // checking if the chat is in progress or not if yes then we are disabling the icon
                                  ? style2.disableIconFonts
                                  : style2.iconFonts
                              } fa-solid fa-check-double`}
                            ></i>
                          </span>
                        </>
                      )}
                    </div>
                  )}
                </OverlayTrigger>
              </>
            ) : (
              <>
                {" "}
                {!activeCustomerInfo?.ticketData && (
                  <div className="me-2 mb-2">
                    <span className={`${style2.ticketCreatedText}`}>
                      <i
                        className={`${style2.iconFonts} fa-solid fa-check-double`}
                      ></i>{" "}
                      Resolved
                    </span>
                  </div>
                )}
              </>
            )}

            {!activeCustomerInfo?.isResolved &&
              !activeCustomerInfo?.ticketData && ( // Condition to check if the user is anonymous or not if user is anonymous then we will not show the button
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className={` ${styles.toolTipCustom}`}>
                      Resolve and create ticket
                    </Tooltip>
                  }
                >
                  {({ ref, ...triggerHandler }) => (
                    <div
                      {...triggerHandler}
                      className={`me-2 ${
                        activeCustomerInfo.chatStatus === "chat_in_progress_ai" // checking if the chat is in progress or not if yes then we will disable the button style
                          ? styles.disabledCheckBox2
                          : styles.checkBox2
                      } `}
                      ref={ref}
                      onClick={() => {
                        if (
                          activeCustomerInfo.chatStatus ===
                          "chat_in_progress_ai" // checking if the chat is in progress or not if yes then we will disable the button
                        ) {
                          return;
                        } else {
                          setResolveAndCreateTicket(true);
                          onShow();
                        }
                      }}
                    >
                      <span>
                        <i
                          className={`${
                            activeCustomerInfo.chatStatus ===
                            "chat_in_progress_ai" // checking if the chat is in progress or not if yes then we are disabling the icon
                              ? style2.disableIconFonts
                              : style2.iconFonts
                          } fa-solid fa-check-double`}
                        ></i>
                      </span>
                      <span className="mx-1">
                        <i
                          className={`${
                            activeCustomerInfo.chatStatus ===
                            "chat_in_progress_ai" // checking if the chat is in progress or not if yes then we are disabling the icon
                              ? style2.disableIconFonts
                              : style2.iconFonts
                          } fa-solid fa-plus`}
                        ></i>
                      </span>
                      <span>
                        {" "}
                        <i
                          className={`${
                            activeCustomerInfo.chatStatus ===
                            "chat_in_progress_ai" // checking if the chat is in progress or not if yes then we are disabling the icon
                              ? style2.disableIconFonts
                              : style2.iconFonts
                          } fa-solid fa-ticket`}
                        ></i>
                      </span>
                    </div>
                  )}
                </OverlayTrigger>
              )}

            <ProfileButton
              showRSBDetails={showRSBDetails}
              setShowRSBDetails={setShowRSBDetails}
            />
            {/* It is commented for now on 10/10/2022. Bugs/Updates given by the Yuvaraj */}
            {/* <div className={`d-flex align-items-center ${styles.moreOption}`}>
          <span>
            <i className="fa-solid fa-ellipsis-vertical"></i>
          </span>
        </div> */}
          </div>
        )}
    </div>
  );
}

export default ChatTabs;
