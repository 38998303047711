import styles from "../../SnoozeModal.module.scss";
import snooze1 from "src/assets/images/snoozImg.png";
import close from "src/assets/images/close.png";
import snoozeSuccess from "src/assets/images/snoozeSuccess.png";
interface Props {
  onHide: () => void;
  reOpenStr: string;
  handelOnPending: (e: any) => void;
}

function Snoozed({ onHide, reOpenStr, handelOnPending }: Props) {
  return (
    <>
      <div className={`${styles.Header}`}>
        <div className="d-flex align-items-center">
          <img
            src={snooze1}
            alt="Snooze button icon"
            className={`${styles.snoozeImg}`}
          />
          <span className={`${styles.snoozeText}`}>Snooze</span>
        </div>
        <div>
          <img
            className={`${styles.closeBtn}`}
            src={close}
            alt="close"
            onClick={onHide}
          />
        </div>
      </div>
      <div className={`${styles.content2} text-center`}>
        <div className={`mt-2 mb-3`}>
          <img
            className={`${styles.successSnooze}`}
            src={snoozeSuccess}
          />
        </div>
        <p className={`${styles.successLead}`}>
          Tickets <strong>snoozed</strong> successfully and moved to{" "}
          <strong>pending</strong>.
        </p>
        <p className={`${styles.successSpan}`}>{reOpenStr}</p>
        <button
          className={`${styles.pendingBtn}`}
          onClick={handelOnPending}
        >
          View Pending
        </button>
      </div>
    </>
  );
}

export default Snoozed;
