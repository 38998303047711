import {
  addCannedResponse,
  AddCannedResponseParams,
} from "src/services/LiveChat/Settings/cannedResponses/addCannedResponse";
import {
  deleteCannedResponse,
  DeleteCannedResponseParam,
} from "src/services/LiveChat/Settings/cannedResponses/deleteCannedResponse";
import {
  getAllCannedResponse,
  GetAllCannedResponseParams,
} from "src/services/LiveChat/Settings/cannedResponses/getAllCannedResponse";
import {
  updateCannedResponse,
  UpdateCannedResponseParams,
} from "src/services/LiveChat/Settings/cannedResponses/updateCannedResponse";
import {
  calculateTotalPages,
  pageSlice,
  paginationLogic,
} from "src/services/TicketService/pagination";
import { RootState } from "src/store/store";
import {
  fetchAllCannedResponse,
  resetCannedResponse,
} from "./cannedResponse.slice";

// thunk that fetches all cannedresponses
const fetchAllCannedResponseThunk = async (
  payload: string | number,
  { getState }: { getState: Function }
) => {
  try {
    const currentState: RootState = getState();
    if (
      currentState.cannedResponses.cachedCannedResponse[
        currentState.cannedResponses.cannedResponseCurrentPage
      ]
    ) {
      return {
        cannedResponses: currentState.cannedResponses.cannedResponses,
        cannedResponseIdList:
          currentState.cannedResponses.cachedCannedResponse[
            currentState.cannedResponses.cannedResponseCurrentPage
          ],
        metaData: currentState.cannedResponses.metaData,
        totalPages: currentState.cannedResponses.cannedResponseTotalPages,
        cachedData: currentState.cannedResponses.cachedCannedResponse,
      };
    } else {
      const currentPage =
        currentState.cannedResponses.cannedResponseCurrentPage === 0
          ? 1
          : currentState.cannedResponses.cannedResponseCurrentPage;
console.log(currentPage)
      const { startPage, endPage, start, limit } = paginationLogic(
        currentPage,
        currentState.cannedResponses.cannedResponsePerPageLimit,
        currentState.cannedResponses.cannedResponseTotalPages,
        5
      );

      const queryData: GetAllCannedResponseParams = {
        integrationId: payload,
        start: start,
        limit: limit,

        searchTerm: currentState.cannedResponses.filters.searchValue,
      };

      // check search term is empty or not
      if (queryData.searchTerm?.length === 0) {
        delete queryData.searchTerm;
      }
      const cannedResponseData: getAllCannedResponse =
        await getAllCannedResponse(queryData);

      const totalPages = calculateTotalPages(
        cannedResponseData.metaData.total ?? 0,
        currentState.cannedResponses.cannedResponsePerPageLimit
      );

      const cachedData: { [pageNo: string | number]: Array<number | string> } =
        {};
      let count = 0;
      for (let i = startPage; i <= endPage; i++) {
        cachedData[i] = pageSlice(
          cannedResponseData.cannedResponseIdList,
          currentState.cannedResponses.cannedResponsePerPageLimit,
          count
        );
        count++;
      }

      return {
        cannedResponses: cannedResponseData.cannedResponses,
        cannedResponseIdList: cachedData[currentPage],
        metaData: cannedResponseData.metaData,
        totalPages,
        cachedData,
      };
    }
  } catch (error) {
    throw error;
  }
};

async function addCannedResponseThunk(
  payload: AddCannedResponseParams,
  { getState, dispatch }: { getState: Function; dispatch: Function }
) {
  const cannedResponse = await addCannedResponse(payload);
  setTimeout(() => {
    dispatch(resetCannedResponse());
    dispatch(fetchAllCannedResponse(payload.integrationId));
  }, 0);
  return payload;
}

interface editCannedResponse extends UpdateCannedResponseParams {
  callback?: () => void;
}

const editCannedResponseThunk = async (
  { callback, ...payload }: editCannedResponse,
  { getState }: { getState: Function }
) => {
  const cannedResponse = await updateCannedResponse(payload);
  setTimeout(() => {
    if (callback) {
      callback();
    }
  }, 0);

  return cannedResponse;
};

interface DeleteCannedResponse extends DeleteCannedResponseParam {
  callback?: () => void;
}

async function deleteCannedResponseThunk(
  { callback, ...payload }: DeleteCannedResponse,
  { getState }: { getState: Function }
) {
  const result = await deleteCannedResponse(payload);
  setTimeout(() => {
    if (callback) {
      callback();
    }
  }, 0);
  return result.cannedResponesId;
}

// thunk that fetches all cannedresponses
const fetchAllCannedResponseInfiScrollThunk = async (
  payload: string | number,
  { getState }: { getState: Function }
) => {
  try {
    const currentState: RootState = getState();
    const queryData: GetAllCannedResponseParams = {
      integrationId: payload,
      start: currentState.cannedResponses.cannedResponseIdList.length,
      limit: currentState.cannedResponses.cannedResponseLimit,
      searchTerm: currentState.cannedResponses.filters.searchValue,
    };

    // check search term is empty or not
    if (queryData.searchTerm?.length === 0) {
      delete queryData.searchTerm;
    }
    const cannedResponseData: getAllCannedResponse = await getAllCannedResponse(
      queryData
    );
    // console.log("---------------canned Response Thunks ------------ ");
    // console.log(
    //   "cannedResponse data ::: " + JSON.stringify(cannedResponseData)
    // );
    return {
      cannedResponses: cannedResponseData.cannedResponses,
      cannedResponseIdList: cannedResponseData.cannedResponseIdList,
      metaData: cannedResponseData.metaData,
    };
  } catch (error) {
    throw error;
  }
};

export default {
  fetchAllCannedResponseThunk,
  addCannedResponseThunk,
  editCannedResponseThunk,
  deleteCannedResponseThunk,
  fetchAllCannedResponseInfiScrollThunk,
};
