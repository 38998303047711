import { useCallback, useEffect, useRef, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import styles from "./AdditionalOptionList.module.scss";
import { v4 as uuidv4 } from "uuid";
import { Editor as TinyMceEditor } from "tinymce";

export interface AdditionalOption {
  label: any;
  hide: boolean;
  showLogo?: boolean;
  onClick: (e: any) => void;
}

function AdditionalOptionList({
  additionalOptions,
  editorRef,
}: {
  additionalOptions?: Array<AdditionalOption>;
  editorRef: React.MutableRefObject<TinyMceEditor | null>;
}) {
  const plusBtnTargetRef = useRef(null);
  const [showOverlay, setShowOverlay] = useState<boolean>(false);

  const handlePlusBtnClick = (e: any) => {
    e.preventDefault();
    setShowOverlay(!showOverlay);
  };

  const onClickWindow = useCallback((e: any) => {
    if (e.target !== plusBtnTargetRef.current) {
      setShowOverlay(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("click", onClickWindow);
    if (editorRef.current?.contentWindow) {
      editorRef.current.contentWindow.addEventListener("click", onClickWindow);
    }

    return () => {
      window.removeEventListener("click", onClickWindow);
      if (editorRef.current?.contentWindow) {
        editorRef.current.contentWindow.removeEventListener(
          "click",
          onClickWindow
        );
      }
    };
  }, [onClickWindow, editorRef.current]);

  return (
    <OverlayTrigger
      show={showOverlay && additionalOptions?.length ? true : false}
      key="top-start"
      placement="top-start"
      overlay={
        <Popover
          className={`${styles.fileUploadPopOver} quill-file-upload-popover`}
          id={`popover-positioned-top-start`}
        >
          <Popover.Body className={`${styles.popOverBody} p-0`}>
            {additionalOptions &&
              additionalOptions.map((additionalOption: AdditionalOption) => {
                if (additionalOption.hide === false) {
                  return (
                    <button
                      key={uuidv4()}
                      onClick={(e: any) => additionalOption.onClick(e)}
                      // disabled={isFileUploading}
                      className={`d-flex align-items-center p-2 mx-1 mb-1 ${styles.pickFileFromPcBtn}`}
                    >
                      {additionalOption.showLogo &&
                      additionalOption.showLogo === true ? (
                        <span className={`mt-1 m-auto`}>
                          <i
                            className={`pe-2 fa-solid fa-laptop ${styles.pcIcon}`}
                          ></i>
                        </span>
                      ) : null}
                      <div>{additionalOption.label}</div>
                    </button>
                  );
                }
              })}
          </Popover.Body>
        </Popover>
      }
    >
      <i
        className={`fa-solid fa-plus d-block m-auto`}
        ref={plusBtnTargetRef}
        onClick={(e: any) => handlePlusBtnClick(e)}
      ></i>
    </OverlayTrigger>
  );
}

export default AdditionalOptionList;
