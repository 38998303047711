/**
 * This file is the custom hook and context file.
 * It contains useFetchReturnAutoWorkFlow custom hook.
 *
 * @author Yash Aditya
 * @fileoverview Custom hook is for fetching return auto work flow state.
 */

import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { AJAXSTATUS } from "src/globals/constants";
import getReturnAutoWorkFlowByIdService from "src/services/ReturnAutoWorkFlow/getReturnAutoWorkFlowById.service";

/**
 * Custom hook to fetch return auto workflow details using integration ID from route parameters.
 *
 * This hook utilizes `useQuery` from `@tanstack/react-query` to fetch data from a service and provides memoized results
 * and loading status.
 *
 * @returns An object containing:
 * - `returnAutoWorkFlowDetails`: The fetched return auto workflow details or `null` if not yet loaded or failed.
 * - `returnAutoWorkFlowLoadingStatus`: The loading status of the fetch operation, which can be "fulfilled", "pending", or "rejected".
 *
 * @example
 * const { returnAutoWorkFlowDetails, returnAutoWorkFlowLoadingStatus } = useFetchReturnAutoWorkFlow();
 */
function useFetchReturnAutoWorkFlow() {
  // Get integration ID from route parameters
  const { integrationId } = useParams();

  const { data, status } = useQuery(
    ["useFetchReturnAutoWorkFlow", integrationId],
    {
      queryFn: async () => {
        if (integrationId) {
          return await getReturnAutoWorkFlowByIdService({
            integrationId,
          });
        } else {
          return undefined;
        }
      },
      cacheTime: 0,
      staleTime: 0,
      networkMode: "always",
    },
  );

  /**
   * Memoized return auto workflow details.
   * It will be `data` if the fetch operation was successful, otherwise `null`.
   */
  const returnAutoWorkFlowDetails = useMemo(
    () => (data ? (status === "success" ? data : null) : null),
    [data, status],
  );

  /**
   * Memoized AJAX status based on the query status.
   * It can be "fulfilled" if the fetch operation was successful, "pending" if it is still loading, or "rejected" if it failed.
   */
  const returnAutoWorkFlowLoadingStatus: AJAXSTATUS = useMemo(
    () =>
      status === "success"
        ? "fulfilled"
        : status === "loading"
          ? "pending"
          : "rejected",
    [status],
  );

  return {
    integrationId,
    returnAutoWorkFlowDetails,
    returnAutoWorkFlowLoadingStatus,
  };
}

export default useFetchReturnAutoWorkFlow;
