import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";
import { getLinkedSocialAccounts } from "src/services/Settings/Users/getLinkedSocialAccounts";
import { linkSocialAccount } from "src/services/Settings/Users/linkSocialAccount";
interface Props {
  userId?: number | string;
}

function useLinkedSocialAccouts() {
  //query to fetch queued chats
  const { isLoading, error, data } = useQuery(
    ["User/getLinkedSocialAccounts"],
    async () => {
      const response = await getLinkedSocialAccounts();
      return response;
    }
  );

  const handleLinkSocial = useCallback(async (route: string) => {
    await linkSocialAccount(route).then((res)=>{
      window.location.href = res.url;
    }).catch(() => {});
  }, []);
  // Return an object with relevant values for the component using this hook
  return {
    data,
    isLoading,
    handleLinkSocial,
  };
}

export default useLinkedSocialAccouts;
