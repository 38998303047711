import { axiosJSON } from "src/globals/axiosEndPoints";

interface PaymentDetails {
  discount: {
    title: null | string;
    amount: null | string;
    currencyCode: null | string;
  };
  subtotal: {
    amount: null | string;
    currencyCode: null | string;
  };
  subtotalItemCount: null | number;
  // shipping: null | string;
  shipping: null | {
    title: string | null;
    amount:  {
      amount: null | string;
      currencyCode: null | string;
    };
  };
  tax: Array<{
    title: null | string;
    amount: null | string;
    currencyCode: null | string;
  }>;
  totalPrice: {
    amount: null | string;
    currencyCode: null | string;
  };
  amountPaidByCustomer: {
    amount: null | string;
    currencyCode: null | string;
  };
  refunded: null | string;
}
interface Address {
  id?: null | string;
  name?: null | string;
  firstName: null | string;
  lastName: null | string;
  address1: null | string;
  address2: null | string;
  city: null | string;
  phone: null | string;
  company: null | string;
  province: null | string;
  provinceCode: null | string;
  country: null | string;
  countryCodeV2: null | string;
  zip: null | string;
}

interface OrderItemData {
  itemId: string;
  variantId: null | string;
  itemName: null | string;
  quantity: null | number;
  sku: null | string;
  unitPrice: null | {
    amount: string;
    currencyCode: string;
  };
  totalPrice: null | {
    amount: string;
    currencyCode: string;
  };
  imageUrl: null | string;
  fulfillmentStatus: null | string;
  locationId: null | string;
  locationName: null | string;
}

interface OrderItemStatus {
  fulfillmentStatus: null | {
    name: string;
    id: number;
    groupId: number;
  };
  name: null | string;
  fulfillmentId: null | string;
  fulfillmentOrderId: null | string;
  trackingInfo: null | {
    url: null | string;
    company: null | string;
    number: null | string;
  };
  locationId: null | string;
  locationName: null | string;
  itemCount: null | number;
  supportedActions: Array<string>;
}

interface OrderItem {
  items: Array<OrderItemData>;
  status: OrderItemStatus;
}

export interface GetOrderViewModel {
  orderId: number;
  orderName: null | string;
  customerData: null | {
    id: number;
    name: string;
    email: string;
  };
  paymentStatus: null | {
    id: number;
    name: string;
  };
  fulfillmentStatus: {
    name: string;
    id: number;
    groupId: number;
  };
  orderCancelled: boolean;
  createdDate: string;
  createdDateGMT: string;
  orderItems: Array<OrderItem>;
  contactInformation: {
    email: null | string;
    phone: null | string;
  };
  paymentDetails: PaymentDetails;
  orderTimeline: Array<Array<string>>;
  customerNotes: null | string;
  billingAddress: Address;
  shippingAddress: Address;
  tags: Array<string>;
}

export interface GetOrderViewModelParams {
  orderId: number;
}

export async function getOrderViewModel(params: GetOrderViewModelParams) {
  const { data: res }: any = await axiosJSON.post(
    `/api/local/shopify/getOrderData`,
    params
  );
  if (res.error === true) {
    throw new Error(res.message as string);
  }

  return res.data as GetOrderViewModel;
}
