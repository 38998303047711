import {createSelector} from "@reduxjs/toolkit";
import {RootState} from "src/store/store";

export function isAgentIdSelected(state: RootState, agentId: number | string) {
  return state.chatWidExhMsg.selectedAgentIds.includes(agentId);
}

export function areAllAgentSelected(state: RootState): boolean {
  if (state.chatWidExhMsg.agentIds.length > 0) {
    const allAgentIds = state.chatWidExhMsg.agentIds;
    const allSelectedAgentIds = state.chatWidExhMsg.selectedAgentIds;

    return (
      allAgentIds.length !== 0 &&
      allAgentIds.every((u) => allSelectedAgentIds.includes(u))
    );
  } else {
    return false;
  }
}
