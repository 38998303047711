import styles from "./AutoWorkFlow.module.scss";
import Automation from "./Children/Automation/Automation";
import IssuesOrder from "./Children/IssuesOrder/IssuesOrder";
import toggle from "src/assets/images/chat/blackToggle.png";
const AutoWorkFlow = (props: { type: string }) => {
  return (
    <>
      <section className={` ${styles.sectionWidth}`}>
        <div className="container">
          <div className={`row`}>
            {/* <div className={`col-12 col-md-6 col-lg-8`}></div> */}
            <div className="col-12">
              <div className={`${styles.contentWrapper}`} id="width">
                {/* -----------HOME PAGE----------- */}
                <div className={`${styles.card}`} id="chat1">
                  {/* <Automation /> */}
                  <IssuesOrder type={props.type} />
                </div>
              </div>
              {/* <div className={`d-flex justify-content-end cursor-pointer ${styles.toggleDiv}`}>
                <img src={toggle} alt="" />
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default AutoWorkFlow;
