/**
 * This file is the React component file for handling article categories in a report.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 * @author Navjyot
 */

// Importing necessary dependencies
import ReactSelect from "react-select";
import styles from "../ReportInternalFilter.module.scss";
import { useCallback, useMemo } from "react";
import {
  reportInternalFiltersActions,
  useReportInternalFilters,
} from "src/routes/Report/hooks/reportFilters/useReportInternalFilters";
import useAllCategory from "../../FilterWrapper/children/AllCategory/useAllCategory";

// Custom hook for managing article categories
const useArticleCategory = () => {
  const { allCategories, categoryIds } = useAllCategory();

  // Memoized options for React Select
  const options = useMemo(() => {
    return (
      categoryIds?.map((id) => {
        return {
          label:
            allCategories?.find((cat) => cat.categoryId === Number(id))
              ?.categoryName ?? "",
          value:
            allCategories?.find((cat) => cat.categoryId === Number(id))
              ?.categoryId + "" ?? "",
        };
      }) ?? []
    );
  }, [allCategories, categoryIds]);

  const { internalFilters, dispatch } = useReportInternalFilters();

  // Memoized selected users based on internal filters
  const selectedUsers = useMemo(() => {
    return allCategories?.length
      ? (
          allCategories.filter((data) =>
            internalFilters.addedFilters.articleCategoryIds?.includes(
              data.categoryId + ""
            )
          ) ?? []
        ).map((user) => {
          return {
            label: user.categoryName,
            value: user.categoryId + "",
          };
        })
      : [];
  }, [internalFilters, allCategories]);

  // Callback function for handling user selection changes
  const onChangeUsers = useCallback(
    (value: typeof selectedUsers) => {
      dispatch([
        reportInternalFiltersActions.setFilters,
        { articleCategoryIds: value.map((data) => data.value + "") },
      ]);
    },
    [selectedUsers]
  );
  return {
    options,
    selectedUsers,
    onChangeUsers,
  };
};

// React component for handling Article Category
function ArticleCategory() {
  const { options, selectedUsers, onChangeUsers } = useArticleCategory();

  return (
    <div className="px-2 pb-2">
      <label className={`${styles.labelText}`}>Article Category</label>
      <ReactSelect
        isMulti
        options={options}
        value={selectedUsers}
        onChange={onChangeUsers as any}
        className={`${styles.text}`}
        menuShouldBlockScroll={true}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
            font: "normal normal normal 12px/18px Poppins",
            color: "#000000",
          }),
          multiValue: (base, { data }) => ({
            ...base,
            backgroundColor: '#F4F4F4', // Background color for selected values
            borderRadius: '6px',
            opacity: 1,
          }),
          multiValueLabel: (base) => ({
            ...base,
            font: "normal normal 500 12px/18px Poppins",
            letterSpacing: "0px",
            color: "#1F2223",
            paddingLeft:"12px",
          }),
          multiValueRemove: (base, { data }) => ({
            ...base,
            // color: '#000000', // Remove icon color for selected values
            fontSize: "6px",
            // ':hover': {
            //   backgroundColor: 'red', // Change the background color on hover if needed
            // },
          }),
        }}
      />
    </div>
  );
}

export default ArticleCategory;
