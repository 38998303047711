import { axiosJSON } from "src/globals/axiosEndPoints";
import log from "loglevel";

export type MoreColumnType = Array<{
  key: string;
  name: string;
  show: boolean;
}>;

export async function getMoreColumns(): Promise<MoreColumnType> {
  const { data }: { data: MoreColumnType } = await axiosJSON.get(
    `/api/ticket/ticketViewColumns`
  );
  // log.debug("Fetched more column data:", data);
  return data;
}
