import { axiosJSON } from "src/globals/axiosEndPoints";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";

export interface VerifyEmailForwarding {
  err: boolean;
  msg: string;
  data: any;
}

export interface VerifyEmailForwardingParams {
  emailIntegrationId: number;
}

export async function verifyEmailForwarding(
  params: VerifyEmailForwardingParams
) {
  const { data } = await axiosJSON.post(
    `/api/setting/integration/verifyEmailForwarding`,
    params
  );
  // const data = await verifyEmailPromise(params);
  // if (res.errors) {
  //   throw new Error(res.errors.join(""));
  // }

  // if (res.error === true) {
  //   throw new Error(res.message as string);
  // }

  if (data.err === true || data.error === true) {
    pushTheToast({
      type: "danger",
      text: data.msg,
      position: "top-right",
    });
    throw new Error(data.msg as string);
  }

  return data.data as VerifyEmailForwarding;
  // return res.data as VerifyEmailForwarding;
}

// let verifyEmailPromise = function (params: VerifyEmailForwardingParams) {
//   return new Promise(function (resolve, reject) {
//     if (params) {
//       setTimeout(function () {
//         resolve({
//           isEmailVerified: true,
//         });
//       }, 2000);
//     } else {
//       reject(new Error("Something is not right!"));
//     }
//   });
// };
