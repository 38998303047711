import { axiosJSON } from "src/globals/axiosEndPoints";
import { StepConfigParams, TabType } from "../Step9/step9GetConfig";

export interface Step10Config {
  data: TabType[];
}
export async function fetchGetStep10Config(params: StepConfigParams) {
  const { data } = await axiosJSON.get(
    "/api/setting/automation/refund/step/10/configuration",
    { params }
  );

  if (data.err === true) {
    throw new Error("Some Error Occurred while fetching data");
  }

  return data.data;
}
