import styles from "./ToastContainer.module.scss";
import { useCallback, useEffect, useState } from "react";
import { v4 as uuid } from "uuid";

interface toastPushInterface {
  id?: string | number,
  type:
    | "success"
    | "danger"
    | "warning"
    | "info"
    | "dark"
    | "primary"
    | "secondary";
  // text: string;
  text: any; 
  isTextComponent?: boolean;
  customCompProps?: any;
  position:
    | "center"
    | "top-center"
    | "top-left"
    | "top-right"
    | "bottom-left"
    | "bottom-right"
    | "bottom-center";
}
interface toastPushDataInterface {
  type:
    | "success"
    | "danger"
    | "warning"
    | "info"
    | "dark"
    | "primary"
    | "secondary";
  // text: string;
  text: any;
  isTextComponent?: boolean;
  customCompProps?: any;
  keyToast: number;
  deleteToast: Function;
}

export let pushTheToast: (arg: toastPushInterface) => void = ({
  type,
  text,
  position,
  isTextComponent = false,
  customCompProps = false,
}: toastPushInterface) => {};

const MainToast = ({
  type,
  text,
  keyToast,
  isTextComponent,
  deleteToast,
  customCompProps,
}: toastPushDataInterface) => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      deleteToast(keyToast);
    }, 5000);
    return () => {
      clearTimeout(timeout);
    };
  }, [deleteToast, keyToast]);

  if(isTextComponent && text){

    const CustomToast = text;

    return <CustomToast {...customCompProps} type={type} />
   
  }else{

    return (
      <div
        className={`my-1 toast show align-items-center text-white bg-${type} border-0`}
        role="alert"
      >
        <div className="d-flex">
          <div className="toast-body">{text}</div>
          <button
            type="button"
            className="btn-close btn-close-white me-2 m-auto"
            aria-label="Close"
            onClick={(e: any) => {
              deleteToast(keyToast);
            }}
          ></button>
        </div>
      </div>
    );
   
  }

};

const groupArrayOfObjects = (list:Array<any>, key:string) => {
  return list.reduce(function(rv:any, x:any) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

const ToastContainer = () => {
  const [toastPush, setToastPush] = useState([] as Array<toastPushInterface>);
  const deleteToast = useCallback((keyToast: number) => {
    setToastPush((toastPush) => {
      return toastPush.filter((val, key) => val.id !== keyToast);
    });
  }, []);
  const pushToast = useCallback((theToast: toastPushInterface) => {
    theToast.id = uuid(); //generating new unique id for new toast 

    setToastPush((value) => {
      if (value.length > 0) {
        return [value[value.length - 1], theToast];
      } else {
        return [theToast];
      }
    });
    // setToastPush((value)=>[...value, theToast])
  }, []);
  useEffect(() => {
    pushTheToast = pushToast as any;
  }, [pushToast]);


  return (
    <div>
      {
         Object.entries(groupArrayOfObjects(toastPush, "position")).map(([positionType, toastArray ]:any, index:any)=>{

          return (
             <div className={`${styles["main-toast"]} ${styles[positionType]}`} key={uuid()}> 
              {toastArray.map((data:any, key:any) => {
                return (
                  <MainToast
                    type={data.type}
                    text={data.text}
                    key={uuid()}
                    isTextComponent={data.isTextComponent}
                    keyToast={data.id}
                    customCompProps={data.customCompProps}
                    deleteToast={deleteToast}
                  />
                );
              })}
            </div>
         )
        })
          
      }

    </div>
  );
};
export default ToastContainer;
