import { IBotIndexingStatus } from "src/components/BotIndexingStatus/BotIndexingStatus";
import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";

export interface SelectArticlesParams {
  sourceId: number;
  articleId?: number | string;
  isSelected?: boolean;
  isAllSelected?: boolean;
}

export interface TrainingStatus {
  articleId: number;
  botTrainingStatus: string;
}

export interface SelectArticlesResponseExternal {
  msg: string;
  trainingStatuses: {
    articleId: number;
    botTrainingStatus: IBotIndexingStatus;
  }[];
}

const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/botProfile/kbArticle/select`
  : "/api/botProfile/external/selectSourceArticle";

export async function selectSourceArticle(params: SelectArticlesParams) {
  const { data: res } = await axiosJSON.post(apiEndPoint, params);

  // console.log("res", res);

  if (res.err) {
    if (res.statusCode) {
      // If the status code is there, throw a specific error
      throw {
        statusCode: res.statusCode,
        message: res.msg ?? res.message,
      };
    } else {
      throw new Error(res.message as string);
    }
  }

  return res.data as SelectArticlesResponseExternal;
}
