import { useAppSelector } from "src/store/store";
import { getTodayRelativeTime } from "src/utils/dateLibrary";
import styles from "./ShowRelativeTime.module.scss";
import ReadStatus from "./Children/ReadStatus/ReadStatus";

interface Props {
  className?: string;
  dateTimeUTC: string;
  showIcon?: boolean;
  [key: string]: any;
  messageType?: "in" | "out";  // in: message received, out: message sent
  messageSeenStatus?: boolean;  // true: message seen, false: message not seen
}

function ShowRelativeTime({
  className = "",
  dateTimeUTC,
  showIcon = false,
  messageType,  // in: message received, out: message sent
  messageSeenStatus,  // true: message seen, false: message not seen
  ...props
}: Props) {
  const userTimezone = useAppSelector(
    (state) => state.globals.currentUserData?.userTimezone
  );

  return (
    <span
      className={`d-flex align-items-center ${styles.time} ${className}`}
      {...props}
    >
      {showIcon && (
        <span className="me-1">
          <i className="fas fa-clock"></i>
        </span>
      )}
      {dateTimeUTC && userTimezone
        ? getTodayRelativeTime({
            time: new Date(dateTimeUTC + "Z"),
            timeZone: userTimezone,
          })
        : ""}
      {/* If message is out type then only show the message seen status */}
      {messageType === "out" && (
        <ReadStatus read={messageSeenStatus ?? false} />
      )}
    </span>
  );
}

export default ShowRelativeTime;
