import React, { Dispatch, SetStateAction, useState } from "react";
import UserAvatar from "src/components/UserAvatar";
import AxiosImg from "src/components/AxiosImg";
import GeneralTooltip from "src/components/GeneralTooltip/GeneralTooltip";
import styles from "./ActionBox.module.scss";
import { useQuery } from "@tanstack/react-query";
import getLatestReportedBugs, { CustomerInfo } from "src/services/BugReport/getLatedtReportedBugs.service";
import { CustomerDetails } from "src/services/BugReport/getBugByCategory.service";

interface Props {
  addPending: (  ) => void;
  pendingForAction: boolean;
  setPendingForAction: Dispatch<SetStateAction<boolean>>;
}

const RenderCustomerImg = ({
  customerData,
  translate,
}: {
  customerData?: CustomerDetails
  translate: boolean;
}) => {
  return (
    <span className={`${translate ? styles.imgWrap : ""}`}>
      {customerData?.imageUrl ? (
        <AxiosImg
          className={`${styles.profileImg}`}
          url={customerData?.imageUrl}
          size={22}
        />
      ) : customerData?.customerName ? (
        <UserAvatar
          className={`${styles.profileImg}`}
          name={customerData?.customerName ?? "N A"}
          size={22}
        />
      ) : (
        <></>
      )}
    </span>
  );
};

const ActionBox = ({
  addPending,
  pendingForAction,
  setPendingForAction,
}: Props) => {
  const handleShowAllClick = () => {
    setPendingForAction(false);
    addPending()
  };

  const handlePendingClick = () => {
    setPendingForAction(true);
    addPending()
  };

  const { data: latestReportedBugs } = useQuery(["bugActionBox/getLatestReportedBugs"], {
    queryFn: () => getLatestReportedBugs(),
    staleTime: 60000,
    cacheTime: 60000,
  });

  return (
    <div className="d-flex justify-content-between mt-4 pt-3 mb-4">
      <div className="d-flex align-items-center flex-wrap">
        {latestReportedBugs?.data.length ? (
          <GeneralTooltip
            title={`${latestReportedBugs?.metaData?.totalCount} user${
              latestReportedBugs?.metaData?.totalCount > 1 ? "s" : ""
            } reported bugs`}
            className={`${styles.tooltip}`}
          >
            <div className="d-flex align-items-center">
              {latestReportedBugs.data.slice(0, 3).map((value: CustomerInfo, index) => (
                <RenderCustomerImg
                  key={value.bugId}
                  customerData={value.customerDetails}
                  translate={index > 0}
                />
              ))}
            </div>
          </GeneralTooltip>
        ) : (
          <></>
        )}
        <div className="d-flex align-items-center flex-wrap">
          <button
            className={`ms-0 ms-md-2 mb-2 mb-md-0 mt-2 mt-md-0 px-2 ${styles.showBtn}`}
            data-view-type="showAllCustomers"
            data-active={pendingForAction ? "false" : "true"}
            onClick={handleShowAllClick}
            id="bug_showAll_btn"
          >
            Show all
          </button>
          <button
            className={`mx-md-2 ms-0 mb-2 mb-md-0 ${styles.pendingBtn}`}
            data-view-type="showPendingForAction"
            onClick={handlePendingClick}
            data-active={pendingForAction ? "true" : "false"}
            id="bug_pending_btn"
          >
            Pending for action
          </button>
          {/* <button
            className={`${styles.pendingBtn}`}
            data-view-type="showTicketsOnly"
            onClick={() => handleViewChange("showTicketsOnly")}
          >
            Show only tickets
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default ActionBox;
