import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Col,
  Modal,
  Nav,
  OverlayTrigger,
  Popover,
  Row,
  Tab,
} from "react-bootstrap";
import ReactSelect from "react-select";
import AddConditionButton from "src/components/AutomationComponents/ContentSection/AddConditionButton";
import Condition from "src/components/AutomationComponents/ContentSection/Condition";
import ContentHeading from "src/components/AutomationComponents/ContentSection/ContentHeading";
import MessageSection from "src/components/AutomationComponents/ContentSection/MessageSection";
import OperatorSelect from "src/components/AutomationComponents/ContentSection/OperatorSelect";
import Values from "src/components/AutomationComponents/ContentSection/Values";
import Variables, {
  addVariableOnClick,
} from "src/components/AutomationComponents/ContentSection/VariableSection";
import VariableSelect from "src/components/AutomationComponents/ContentSection/VariableSelect";
import DiscardAndSave from "src/components/AutomationComponents/DiscardAndSave";
import DiscardModal from "src/components/AutomationComponents/DiscardModal/DiscardModal";
import FollowUpQuestion from "src/components/AutomationComponents/IndividualComponents/FollowUpQuestion/FollowUpQuestion";
import SkeletonModalStructure from "src/components/AutomationComponents/SkeletonModalStructure/SkeletonModalStructure";
import AddMore from "src/components/AutomationComponents/TabSection/AddMore";
import AutomationTab from "src/components/AutomationComponents/TabSection/AutomationTab";
import CloseButtonForTab from "src/components/AutomationComponents/TabSection/CloseButtonForTab";
import ConditionGroup from "src/components/AutomationComponents/TabSection/ConditionGroup";
import TabError from "src/components/AutomationComponents/TabSection/TabError";
import TabName from "src/components/AutomationComponents/TabSection/TabName";
import Loader from "src/components/Loader";
import { useReturnAutoWorkFlow } from "src/features/ReturnAutoWorkFlow/hooks/useReturnAutoWorkFlow";
import AutoWorkFlow from "src/routes/AutoWorkFlow/AutoWorkFlow";
import { CustomDropdownIndicator } from "src/routes/Setting/children/MainSetting/children/SetRulesNew/childerns/childern/AddAutomations/AddAutomationStep1/AddAutomationStep1";
import { ConditionTypes } from "src/features/ReturnAutoWorkFlow/children/ConfigModals/store/returnModalsSliceHelpers";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  deleteAdditionalCharge,
  setSelectedAdditionalChargeId,
  undoConfig,
  validateChanges,
} from "../../store/slices/RestockingFeeSlice/restockingFee.slice";
import { fetchRestockingFeeModal } from "../../store/slices/RestockingFeeSlice/restockingFee.thunk";
import styles from "./RestockingFee.module.scss";
import useRestockingFeeModal from "./useRestockingFeeModal";

const RestockingFeeComponent = ({ onSave }: { onSave: () => void }) => {
  const dispatch = useAppDispatch();
  const { returnAutoWorkFlow } = useReturnAutoWorkFlow();

  // const stepQuestions = useAppSelector(
  //   (state) => state.returnExchange.step12.stepData.stepQuestions,
  // );

  // const hideSelectReturn = useMemo(() => {
  //   return (
  //     stepQuestions[EReturnAutomationQuestionKeys.selectReturnReason]
  //       .value === "notRequired"
  //   );
  // }, [stepQuestions]);

  // TODO
  const hideSelectReturn = useMemo(() => {
    if (returnAutoWorkFlow) {
    }
    return false;
  }, [returnAutoWorkFlow]);

  const insertPlaceholderRef = useRef(null as any);
  const [showPopover, setShowPopover] = useState(true);

  const {
    dispAddCondition,
    dispAddTab,
    dispDeleteCondition,
    dispPostConfig,
    dispPushCurrentHashForSelectedTab,
    dispUpdateConditionForValues,
    dispUpdateConditionForVariables,
    dispUpdateConditonForOperator,
    dispUpdateAdditionCharge,
    dispValidateCondition,
    dispValidateAdditionalCharge,
    dispUpdateFeesAmount,
    additionalCharges,
    fetchAjaxStatus,
    updateAjaxStatus,
    initialHashOtherTab,
    currentHashOtherTab,
    selectedAdditionalCharge,
    selectedAdditionalChargeId,
    isAddBtnEnabled,
    isOrderTypeAddBtnEnabled,
    defaultOptions,
    itemOptions,
    conditionOptions,
    operatorNames,
    returnReasonOptions,
    dispReasonAddCondition,
    dispReasonDeleteCondition,
    dispReasonUpdateConditionForValues,
    dispReasonUpdateConditonForOperator,
    dispReasonUpdateConditionForVariables,
    isAddBtnSelectReasonEnabled,
  } = useRestockingFeeModal({ onSave });

  useMemo(() => {
    let hasError = false;
    additionalCharges.forEach((ret) => {
      if (ret.restockingFees.error) {
        hasError = true;
      }
    });
    setShowPopover(hasError);
  }, [additionalCharges]);

  if (fetchAjaxStatus === "pending") {
    return (
      <div className={`${styles.loaderHeight}`}>
        <Loader />
      </div>
    );
  }

  return (
    <div>
      <Tab.Container
        id="left-tabs-example"
        activeKey={selectedAdditionalChargeId ?? "1"}
        defaultActiveKey={"1"}
        onSelect={(eventKey) => {
          if (selectedAdditionalChargeId !== eventKey) {
            dispatch(
              setSelectedAdditionalChargeId({
                additionalChargeId: eventKey ?? "",
              }),
            );
          }
        }}
      >
        <Row>
          <Col>
            <Nav
              variant="tabs"
              className={`d-flex align-items-center border-0 ${styles.tabSectionWrapper} `}
            >
              {additionalCharges.map((ret, idx) => {
                return (
                  <Nav.Item
                    className=""
                    role="button"
                    key={ret.additionalChargeId + "tabItem"}
                  >
                    <Nav.Link
                      eventKey={ret.additionalChargeId}
                      className={`py-1 px-3  text-dark shadow-sm  ${styles.tabStyles}`}
                      style={{
                        background:
                          selectedAdditionalCharge !== null
                            ? selectedAdditionalChargeId ===
                              ret.additionalChargeId
                              ? ret.errorCount > 0
                                ? "#FFDCDC 0% 0% no-repeat padding-box"
                                : "#FFFFFF 0% 0% no-repeat padding-box "
                              : ret.errorCount > 0
                                ? "#FFDCDC 0% 0% no-repeat padding-box"
                                : "#f5f5f5 0% 0% no-repeat padding-box"
                            : "",
                      }}
                    >
                      <AutomationTab idx={idx}>
                        <TabName
                          id={ret.additionalChargeId}
                          name={ret.additionalChargeName}
                          updateType={dispUpdateAdditionCharge}
                          pushCurrentHash={dispPushCurrentHashForSelectedTab}
                        />

                        <TabError errorCount={ret.errorCount} />

                        <div className="ms-1">
                          <CloseButtonForTab
                            index={idx}
                            name={ret.additionalChargeName}
                            hasStaticTab={false}
                            onDelete={() => {
                              dispatch(
                                deleteAdditionalCharge({
                                  id: ret.additionalChargeId,
                                }),
                              );
                            }}
                          />
                        </div>
                      </AutomationTab>
                    </Nav.Link>
                  </Nav.Item>
                );
              })}
              <AddMore
                AddTab={() => dispAddTab(!hideSelectReturn)}
                text="Add custom restocking fee type"
                selectedTabId={selectedAdditionalChargeId}
                validateTabType={dispValidateAdditionalCharge}
              />
            </Nav>

            <Tab.Content
              className={`shadow ${styles.contentStyles} pt-4 ${
                initialHashOtherTab &&
                currentHashOtherTab &&
                initialHashOtherTab === currentHashOtherTab &&
                styles.noDiscardAndSave
              }`}
            >
              {additionalCharges.map((ret, idx) => {
                const allSelectedConditionNames: string[] = ret.conditions.map(
                  (condition) => condition.variableName,
                );

                const filteredConditions = conditionOptions.filter(
                  (condition) => !allSelectedConditionNames.includes(condition),
                );

                const filteredItemOptions = itemOptions.filter(
                  (condition) => !allSelectedConditionNames.includes(condition),
                );

                const filteredReasonOptions = returnReasonOptions.filter(
                  (condition) => !allSelectedConditionNames.includes(condition),
                );

                return (
                  <Tab.Pane
                    key={ret.additionalChargeId + "tabPane"}
                    eventKey={ret.additionalChargeId}
                    unmountOnExit={true}
                    onEnter={() => {
                      dispValidateCondition(ret.additionalChargeId);
                    }}
                    onEntered={() => {
                      dispValidateCondition(ret.additionalChargeId);
                    }}
                    onExited={() => {
                      dispValidateCondition(ret.additionalChargeId);
                    }}
                  >
                    {/* Conditions Section  */}
                    {idx === 0 ? (
                      <ConditionGroup
                        key={ret.additionalChargeId + "restocking_fee"}
                        title="Ask restocking fee on"
                        initialShow={true}
                      >
                        <div
                          className={`py-3 mt-3 ps-3 pe-2 ${styles.createBox}`}
                        >
                          <VariableSelect
                            key={ret.additionalChargeId + "Default_option"}
                            id={ret.additionalChargeId}
                            condition={{} as any}
                            variablesName={defaultOptions}
                            pushCurrentHash={dispPushCurrentHashForSelectedTab}
                            updateConditon={() => {}}
                            selectedVariableName={""}
                            showPlaceholder={false}
                          />
                        </div>
                        <span className={`${styles.createText}`}>
                          To create specific restocking fee for specific kinds
                          of reason or orders and items,
                          <button
                            className={`ps-1 ${styles.clickLink}`}
                            onClick={() => dispAddTab(!hideSelectReturn)}
                          >
                            click here
                          </button>
                        </span>
                      </ConditionGroup>
                    ) : null}
                    {/* 12 Dec added as per xd */}
                    {idx !== 0 && (
                      <ContentHeading
                        text="Ask restocking fee when "
                        className="ms-2"
                      />
                    )}

                    {!hideSelectReturn && idx !== 0 ? (
                      <>
                        <ConditionGroup
                          title="Select Return Reason"
                          errorMessage={ret.selectReturnReasonError}
                        >
                          {/* <div className="d-flex align-items-md-center flex-column flex-lg-row w-100 w-md-75"> */}
                          {ret.selectReturnReason.length === 0 ? (
                            <ConditionGroup
                              key={
                                ret.additionalChargeId + "select_return_reason"
                              }
                              title=""
                              initialShow={true}
                              disableHide={true}
                            >
                              <VariableSelect
                                id={ret.additionalChargeId}
                                condition={{} as any}
                                variablesName={filteredReasonOptions}
                                pushCurrentHash={
                                  dispPushCurrentHashForSelectedTab
                                }
                                updateConditon={dispReasonAddCondition}
                                selectedVariableName={""}
                                showPlaceholder={true}
                                errorMessage={ret.selectReturnReasonError}
                              />
                            </ConditionGroup>
                          ) : null}
                          {ret.selectReturnReason.map((condition, idx) => {
                            return (
                              <Condition
                                key={condition.id + "conditionId"}
                                id={ret.additionalChargeId}
                                condition={condition}
                                deleteCondition={dispReasonDeleteCondition}
                                idx={idx}
                                validateCondition={dispValidateCondition}
                                hideDelete={idx === 0}
                              >
                                <div className={``}>
                                  <div
                                    className={`d-flex align-items-md-center flex-column flex-lg-row w-100 w-md-75`}
                                  >
                                    <VariableSelect
                                      id={ret.additionalChargeId}
                                      condition={condition}
                                      variablesName={filteredReasonOptions}
                                      pushCurrentHash={
                                        dispPushCurrentHashForSelectedTab
                                      }
                                      updateConditon={
                                        dispReasonUpdateConditionForVariables
                                      }
                                      selectedVariableName={
                                        condition.variableName
                                      }
                                      errorMessage={ret.selectReturnReasonError}
                                    />
                                    <OperatorSelect
                                      condition={condition}
                                      id={ret.additionalChargeId}
                                      pushCurrentHash={
                                        dispPushCurrentHashForSelectedTab
                                      }
                                      updateConditon={
                                        dispReasonUpdateConditonForOperator
                                      }
                                      operatorNames={
                                        operatorNames[condition.variableName]
                                      }
                                      selectedOperator={condition.operator}
                                    />

                                    <Values
                                      id={ret.additionalChargeId}
                                      condition={condition}
                                      updateCondition={
                                        dispReasonUpdateConditionForValues
                                      }
                                      pushCurrentHash={
                                        dispPushCurrentHashForSelectedTab
                                      }
                                      useNewComponent={true}
                                    />
                                  </div>
                                  {condition.values.return_reason.reasonId ? (
                                    <div>
                                      <FollowUpQuestion
                                        reasonId={
                                          condition.values.return_reason
                                            .reasonId
                                        }
                                        error={
                                          condition.values.return_reason.error
                                        }
                                        condition={condition}
                                        operator={
                                          condition.values.return_reason
                                            .operator
                                        }
                                        onChange={(value) => {
                                          dispReasonUpdateConditionForValues(
                                            ret.additionalChargeId,
                                            value,
                                            condition,
                                            true,
                                          );
                                        }}
                                      />
                                    </div>
                                  ) : null}
                                </div>
                              </Condition>
                            );
                          })}

                          <AddConditionButton
                            AddCondition={(id: string) =>
                              dispReasonAddCondition(id)
                            }
                            id={ret.additionalChargeId}
                            isAddBtnEnabled={isAddBtnSelectReasonEnabled}
                            btnText="Add return reason"
                          />
                          {/* </div> */}
                        </ConditionGroup>
                      </>
                    ) : null}

                    {idx !== 0 ? (
                      <>
                        <ConditionGroup
                          title="Select Items"
                          key={ret.additionalChargeId + "select_items"}
                          errorMessage={ret.itemError}
                        >
                          <div>
                            {ret.conditions.filter((condition) =>
                              itemOptions.includes(condition.variableName),
                            ).length === 0 ? (
                              <ConditionGroup
                                key={ret.additionalChargeId + "item_conditions"}
                                title=""
                                initialShow={true}
                                disableHide={true}
                              >
                                <VariableSelect
                                  id={ret.additionalChargeId}
                                  condition={{} as any}
                                  variablesName={filteredItemOptions}
                                  pushCurrentHash={
                                    dispPushCurrentHashForSelectedTab
                                  }
                                  updateConditon={(id: any, e: any) =>
                                    dispAddCondition(id, true, e)
                                  }
                                  selectedVariableName={""}
                                  showPlaceholder={true}
                                  errorMessage={ret.itemError}
                                />
                              </ConditionGroup>
                            ) : null}
                            {ret.conditions
                              .filter((condition) =>
                                itemOptions.includes(condition.variableName),
                              )
                              .map((condition, idx) => {
                                return (
                                  <Condition
                                    key={"itemCondition" + condition.id}
                                    id={ret.additionalChargeId}
                                    condition={condition as any}
                                    deleteCondition={(
                                      conId: string,
                                      id: string,
                                    ) => {
                                      dispDeleteCondition(conId, id, true);
                                    }}
                                    idx={idx}
                                    validateCondition={dispValidateCondition}
                                    hideDelete={idx === 0}
                                  >
                                    <VariableSelect
                                      id={ret.additionalChargeId}
                                      condition={condition as any}
                                      variablesName={filteredItemOptions}
                                      pushCurrentHash={
                                        dispPushCurrentHashForSelectedTab
                                      }
                                      updateConditon={(
                                        id: string,
                                        e: { target: { value: string } },
                                        condition: ConditionTypes,
                                      ) => {
                                        dispUpdateConditionForVariables(
                                          id,
                                          e,
                                          condition,
                                          true,
                                        );
                                      }}
                                      selectedVariableName={
                                        condition.variableName
                                      }
                                      showPlaceholder={true}
                                      errorMessage={ret.itemError}
                                    />
                                    <OperatorSelect
                                      condition={condition as any}
                                      id={ret.additionalChargeId}
                                      pushCurrentHash={
                                        dispPushCurrentHashForSelectedTab
                                      }
                                      updateConditon={(
                                        id: string,
                                        e: { target: { value: string } },
                                        condition: ConditionTypes,
                                      ) => {
                                        dispUpdateConditonForOperator(
                                          id,
                                          e,
                                          condition,
                                          true,
                                        );
                                      }}
                                      operatorNames={
                                        operatorNames[condition.variableName]
                                      }
                                      selectedOperator={condition.operator}
                                    />

                                    <Values
                                      id={ret.additionalChargeId}
                                      condition={condition as any}
                                      updateCondition={(
                                        id: string,
                                        type: string,
                                        value: any,
                                        condition: ConditionTypes,
                                      ) => {
                                        dispUpdateConditionForValues(
                                          id,
                                          type,
                                          value,
                                          condition,
                                          true,
                                        );
                                      }}
                                      pushCurrentHash={
                                        dispPushCurrentHashForSelectedTab
                                      }
                                      useNewComponent={true}
                                    />
                                  </Condition>
                                );
                              })}

                            <AddConditionButton
                              AddCondition={(id: string) => {
                                dispAddCondition(id, true);
                              }}
                              id={ret.additionalChargeId}
                              isAddBtnEnabled={isAddBtnEnabled}
                            />
                          </div>
                        </ConditionGroup>

                        <ConditionGroup
                          key={ret.additionalChargeId + "select_order"}
                          title="Select order types"
                          errorMessage={ret.error}
                        >
                          {ret.conditions.filter(
                            (condition) =>
                              !itemOptions.includes(condition.variableName),
                          ).length === 0 ? (
                            <ConditionGroup
                              key={ret.additionalChargeId + "other_conditions"}
                              title=""
                              initialShow={true}
                              disableHide={true}
                            >
                              <VariableSelect
                                id={ret.additionalChargeId}
                                condition={{} as any}
                                variablesName={filteredConditions}
                                pushCurrentHash={
                                  dispPushCurrentHashForSelectedTab
                                }
                                updateConditon={(id: any, e: any) =>
                                  dispAddCondition(id, false, e)
                                }
                                selectedVariableName={""}
                                showPlaceholder={true}
                                errorMessage={ret.error}
                              />
                            </ConditionGroup>
                          ) : null}
                          {ret.conditions
                            .filter((condition) =>
                              conditionOptions.includes(condition.variableName),
                            )
                            .map((condition, idx) => {
                              return (
                                <Condition
                                  key={"otherCondition" + condition.id}
                                  id={ret.additionalChargeId}
                                  condition={condition as any}
                                  deleteCondition={(
                                    conId: string,
                                    id: string,
                                  ) => {
                                    dispDeleteCondition(conId, id, false);
                                  }}
                                  idx={idx}
                                  validateCondition={dispValidateCondition}
                                  hideDelete={idx === 0}
                                >
                                  <VariableSelect
                                    id={ret.additionalChargeId}
                                    condition={condition as any}
                                    variablesName={filteredConditions}
                                    pushCurrentHash={
                                      dispPushCurrentHashForSelectedTab
                                    }
                                    updateConditon={(
                                      id: string,
                                      e: { target: { value: string } },
                                      condition: ConditionTypes,
                                    ) => {
                                      dispUpdateConditionForVariables(
                                        id,
                                        e,
                                        condition,
                                        false,
                                      );
                                    }}
                                    selectedVariableName={
                                      condition.variableName
                                    }
                                    errorMessage={ret.error}
                                  />

                                  <OperatorSelect
                                    condition={condition as any}
                                    id={ret.additionalChargeId}
                                    pushCurrentHash={
                                      dispPushCurrentHashForSelectedTab
                                    }
                                    updateConditon={(
                                      id: string,
                                      e: { target: { value: string } },
                                      condition: ConditionTypes,
                                    ) => {
                                      dispUpdateConditonForOperator(
                                        id,
                                        e,
                                        condition,
                                        false,
                                      );
                                    }}
                                    operatorNames={
                                      operatorNames[condition.variableName]
                                    }
                                    selectedOperator={condition.operator}
                                  />

                                  <Values
                                    id={ret.additionalChargeId}
                                    condition={condition as any}
                                    updateCondition={(
                                      id: string,
                                      type: string,
                                      value: any,
                                      condition: ConditionTypes,
                                    ) => {
                                      dispUpdateConditionForValues(
                                        id,
                                        type,
                                        value,
                                        condition,
                                        false,
                                      );
                                    }}
                                    pushCurrentHash={
                                      dispPushCurrentHashForSelectedTab
                                    }
                                    useNewComponent={true}
                                  />
                                </Condition>
                              );
                            })}

                          <AddConditionButton
                            AddCondition={(id: string) => {
                              dispAddCondition(id, false);
                            }}
                            id={ret.additionalChargeId}
                            isAddBtnEnabled={isOrderTypeAddBtnEnabled}
                          />
                        </ConditionGroup>
                      </>
                    ) : null}

                    <ConditionGroup
                      title="Configure Restocking Fee"
                      errorMessage={ret.restockingFees.error}
                    >
                      {/* Dec 12 Added as per xd */}
                      <div className={`${styles.addText}`}>
                        Add the restocking fee for this order type:
                      </div>
                      <div
                        className={`d-flex flex-column flex-md-row align-items-center flex-wrap mb-0`}
                      >
                        <OverlayTrigger
                          show={showPopover}
                          placement="bottom"
                          overlay={
                            <Popover
                              id="popover-basic"
                              className="rounded-3"
                            >
                              <Popover.Body className="d-flex flex-row">
                                <div className="border px-2 bg-warning text-white me-1 rounded-3">
                                  !
                                </div>
                                <div className="mt-1">
                                  Please enter a restocking fee.
                                </div>
                              </Popover.Body>
                            </Popover>
                          }
                          rootClose={true}
                          rootCloseEvent="mousedown"
                          // defaultShow={showPopover} // Set the defaultShow property based on the state variable
                          onToggle={(isShown) => {
                            // Set the state variable based on the popover visibility
                            setShowPopover(isShown);
                          }}
                        >
                          <div className={`me-2`}>
                            <input
                              type="number"
                              placeholder="00"
                              className={`px-2 py-1 ${styles.restockInput} ${
                                ret.restockingFees.error ? "border-danger" : ""
                              }`}
                              value={ret.restockingFees.amount}
                              onChange={(e) => {
                                dispUpdateFeesAmount(
                                  parseInt(e.target.value),
                                  ret.additionalChargeId,
                                  "Restocking",
                                  ret.restockingFees.priceType,
                                );
                                dispPushCurrentHashForSelectedTab(
                                  ret.additionalChargeId,
                                );
                                dispValidateAdditionalCharge(
                                  ret.additionalChargeId,
                                );
                              }}
                            />
                          </div>
                        </OverlayTrigger>
                        <div className={`col-12 col-md-4 mb-1 mb-md-0`}>
                          <ReactSelect
                            options={restockingFeeOptions as any}
                            styles={customStyles}
                            value={
                              restockingFeeOptions.filter(
                                (value) =>
                                  ret.restockingFees.priceType === value.value,
                              )[0] ?? restockingFeeOptions[0]
                            }
                            onChange={(value: any) => {
                              dispUpdateFeesAmount(
                                ret.restockingFees.amount,
                                ret.additionalChargeId,
                                "Restocking",
                                value.value,
                              );
                              dispPushCurrentHashForSelectedTab(
                                ret.additionalChargeId,
                              );
                              dispValidateAdditionalCharge(
                                ret.additionalChargeId,
                              );
                            }}
                            components={{
                              DropdownIndicator: CustomDropdownIndicator, // Custom component for rendering the dropdown indicator
                              IndicatorSeparator: null, // Custom component for rendering the indicator separator (null to remove it)
                            }}
                          />
                        </div>
                      </div>
                    </ConditionGroup>
                    <ConditionGroup
                      title="What messaging would you like to provide when communicating the restocking fee?"
                      errorMessage={ret.messageForCustomer.error}
                    >
                      <div>
                        <MessageSection
                          updateOrder={dispUpdateAdditionCharge}
                          message={{
                            value: ret.messageForCustomer?.value ?? "",
                            error: ret.messageForCustomer?.error,
                          }}
                          pushCurrentHash={dispPushCurrentHashForSelectedTab}
                          validateTabType={dispValidateAdditionalCharge}
                          id={ret.additionalChargeId}
                          hideError={false}
                          insertPlaceholderRef={insertPlaceholderRef}
                          text=""
                          hideHeader={true}
                        />

                        {/* Variable Section  */}
                        <Variables
                          numberOfItems={3}
                          handleAddVariableOnClick={(value) => {
                            addVariableOnClick(value, insertPlaceholderRef);
                          }}
                        />
                      </div>
                    </ConditionGroup>
                  </Tab.Pane>
                );
              })}
            </Tab.Content>
          </Col>
        </Row>
        <DiscardAndSave
          onSave={dispPostConfig}
          showLoading={updateAjaxStatus === "pending"}
          onDiscard={() => {
            if (!returnAutoWorkFlow.integrationId) {
              return;
            }

            dispatch(
              fetchRestockingFeeModal({
                integrationId: returnAutoWorkFlow.integrationId,
                isSelectReasonEnabled: false,
                restockingFee: {
                  price: "",
                  priceType: "",
                },
              }),
            );
          }}
        />
      </Tab.Container>
    </div>
  );
};

const restockingFeeOptions = [
  {
    label: "[%] of Order Value",
    value: "percentage",
  },
  { label: "USD", value: "fixed" },
];
//Dec 12 changed customStyles as per xd
const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    borderColor: "#9e9e9e",
    width: "172px",
    borderRadius: "5px",
    minHeight: "37px",
    border: "1px solid #707070",
    opacity: "1",
    // minWidth: "127px",
  }),

  valueContainer: (provided: any, _: any) => ({
    ...provided,
    minHeight: "24px",
    padding: "0 6px",
    font: "normal normal normal 14px/21px Poppins",
    letterSpacing: "0px",
    color: "#000000",
    opacity: "1",
  }),

  input: (provided: any, _: any) => ({
    ...provided,
    margin: "0px",
  }),

  indicatorSeparator: (provided: any, _: any) => ({
    ...provided,
    display: "none",
  }),
  indicatorsContainer: (provided: any, _: any) => ({
    ...provided,
    minHeight: "24px",
    padding: "0px !important",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#f5f5f5" : "#fff", // Change the hover color here
    "&:hover": {
      backgroundColor: "#f5f5f5", // Change the hover color here as well
    },
    padding: "10px",
    color: "black",
  }),
  menuList: (provided: any) => ({
    ...provided,
    margin: "0 5px", // Add 5px margin on the left and right sides of the menu
  }),
  menu: (provided: any) => ({
    ...provided,
    width: "max-content",
  }),
};

const RestockingFee = ({
  onHide,
  onSave = () => {},
}: {
  onHide: () => void;
  onSave?: () => void;
}) => {
  const dispatch = useAppDispatch();
  const { isChanged, fetchAjaxStatus, intialStep15Config } = useAppSelector(
    (state) => state.restockingFee,
  );

  const [isValidating, setIsValidating] = useState(false);
  const [showDiscardModel, setShowDiscardModal] = useState(false);

  useEffect(() => {
    if (isValidating) {
      if (isChanged) {
        setShowDiscardModal(true);
      } else {
        onHide();
      }
      setIsValidating(false);
    }
  }, [isChanged, isValidating, onHide]);

  const onClose = useCallback(() => {
    dispatch(validateChanges());
    setIsValidating(true);
  }, [dispatch]);

  return (
    <div>
      <SkeletonModalStructure
        hasTwoSection={true}
        heading="Configure Restocking Fee"
        closeFunction={onClose}
        rightSide={
          fetchAjaxStatus === "pending" ? (
            <>
              <div className={`${styles.loaderHeight}`}>
                <Loader />
              </div>
            </>
          ) : (
            <AutoWorkFlow type="orderSummary" />
          )
        }
      >
        <RestockingFeeComponent onSave={onSave} />
      </SkeletonModalStructure>
      <Modal
        className={`shadow-sm p-3 rounded`}
        show={showDiscardModel}
        onHide={() => setShowDiscardModal(false)}
        dialogClassName={`${styles.modalDialog2}`}
        contentClassName={`${styles.modalContent2}`}
        backdropClassName={`${styles.modalBackDrop}`}
        enforceFocus={false}
        keyboard={false}
      >
        <DiscardModal
          setShow={setShowDiscardModal}
          closeMainModel={onHide}
          onConfirm={() => {
            dispatch(undoConfig({ initialConfig: intialStep15Config }));
          }}
        />
      </Modal>
    </div>
  );
};

export default RestockingFee;
