import React, { useCallback, useMemo, useRef, useState } from "react";
import ChooseTemplate from "./Children/ChooseTemplate/ChooseTemplate";
import styles from "./ViewTemplateV2.module.scss";
import SearchBar from "../SearchBar/SearchBar";
import TemplateFolder from "./Children/TemplateFolder/TemplateFolder";
import SearchedTemplates from "./Children/SearchedTemplates/SearchedTemplates";
import CreateTemp from "./Children/CreateTemp/CreateTemp";
import { IGetTemplateResponse } from "src/services/Templates/viewTemplates";
import { searchTemplate } from "src/services/Templates/searchTemplates";
import { updatePreviouslyUsedTicketInTemplate } from "src/services/Templates/updatePreviouslyUsedTicketInTemplate";
import { useAppSelector } from "src/store/store";
import Loader from "../Loader";
import { useSearchTemplates } from "./Children/CustomHook/useSearchTemplates";

const ViewTemplateV2 = ({ onTemplateSelect = (template: string) => {} }) => {
  const activeTicketId = useAppSelector((state) => {
    return state.innerTicket.activeTicketInfo.ticket_id;
  });
  const scopes = useAppSelector(
    (state) => state.globals.currentUserData?.scopes
  );

  const [templates, setTemplates] = useState<IGetTemplateResponse[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<any>({});
  const [selectedTemplateData, setSelectedTemplateData] = useState<any>({});
  const [currentView, setCurrentView] = useState<string>("view");
  const [showSearchLoader, setShowSearchLoader] = useState<boolean>(true);
  const [templateSearchText, setTemplateSearchText] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const [whichTab, setWhichTab] = useState<string>("recent");

  const [searchTemplateMeta, setSearchTemplateMeta] = useState<any>({
    start: null,
    count: null,
    totalCount: null,
  });
  const searchTemplateMetaRef = useRef<any>({});
  const templateSearchTextRef = useRef<string>("");

  templateSearchTextRef.current = templateSearchText;
  searchTemplateMetaRef.current = searchTemplateMeta;

  const filters = useMemo(() => {
    return {
      start: 0,
      limit: 25,
      searchTerm: searchText,
    };
  }, [searchText]);

  const {
    status,
    templateIds,
    templates: searchedTemplates,
    hasNextPage,
    fetchNextPage,
    fetchStatus,
    isLoading,
  } = useSearchTemplates(filters);

  // used to handle when the template is used
  const updateTemplateUsedInTicket = (template: any) => {
    if (activeTicketId) {
      // console.log(selectedTemplateData);
      updatePreviouslyUsedTicketInTemplate({
        ticketId: activeTicketId,
        // templateId: selectedTemplateData.id,
        templateId: template.id,
      })
        .then((res) => {
          // console.log("Template used in ticket updated");
        })
        .catch((err) => {
          // console.log("Failed to update template used in ticket");
        });
    }
  };

  const handleOnChangeView = useCallback((value: string) => {
    if (value === "allTemplates") {
      setCurrentView("view");
      setWhichTab("all");
    } else {
      setCurrentView("view");
    }
  }, []);

  const errorMessage = useMemo(() => {
    if (
      status === "error" ||
      (status !== "success" && fetchStatus !== "fetching") ||
      (status !== "loading" && templateIds.length === 0)
    ) {
      return (
        <span className={`ps-2 ${styles.noResultFound}`}>No result found</span>
      );
    }
  }, [status, fetchStatus, templateIds.length]);
  return (
    <div className="h-100" id="viewTemplateModalBody">
      {/* <div className="d-none"> */}
      <div className="h-100 d-flex flex-column">
        {currentView === "view" && (
          <>
            {scopes && scopes.includes("edit_templates") ? (
              <ChooseTemplate
                currentView={currentView}
                setCurrentView={setCurrentView}
              />
            ) : null}
            <div className="position-relative">
              <SearchBar
                className={`w-100 ${styles.search} px-2 mt-1`}
                inputClassName={` ${styles.input}`}
                placeholder={`Search templates by name or text`}
                onChange={(e: any) => {
                  if (templates.length) {
                    // setTemplates([]);
                  }
                  setTemplateSearchText(e.target.value);
                }}
                value={templateSearchText}
                onSearch={(value: string) => setSearchText(value)}
              />
            </div>

            {templateSearchText.trim() !== "" ? (
              <>
                {templateIds.length === 0 && isLoading === true ? (
                  <div style={{ height: "60vh" }} className="mt-3">
                    <Loader />
                  </div>
                ) : (
                  <>
                    {/* Component for searched templates  */}
                    <SearchedTemplates
                      templates={Object.values(searchedTemplates)}
                      searchText={templateSearchText}
                      setSelectedTemplateData={(template: any) => {
                        setSelectedTemplateData(template);
                        // console.log(`ViewTemplateV2 TemplateFolder template: `);
                        // console.log(template);
                        updateTemplateUsedInTicket(template);
                      }}
                      onTemplateSelect={(templateText: string) => {
                        // updateTemplateUsedInTicket();
                        onTemplateSelect(templateText);
                      }}
                      hasMore={hasNextPage}
                      loadMore={fetchNextPage}
                      errorMessage={errorMessage}
                    />
                  </>
                )}
              </>
            ) : (
              <>
                {/* For template folder component  */}
                <TemplateFolder
                  selectedCategory={selectedCategory}
                  setSelectedCategory={setSelectedCategory}
                  templates={templates}
                  setTemplates={setTemplates}
                  setSelectedTemplateData={(template: any) => {
                    setSelectedTemplateData(template);
                    // console.log(`ViewTemplateV2 TemplateFolder template: `);
                    // console.log(template);
                    updateTemplateUsedInTicket(template);
                  }}
                  onTemplateSelect={(templateText: string) => {
                    // updateTemplateUsedInTicket();
                    onTemplateSelect(templateText);
                  }}
                  setWhichTab={setWhichTab}
                  whichTab={whichTab}
                />
              </>
            )}
          </>
        )}
      </div>
      {currentView === "create" && (
        <>
          <div>
            <CreateTemp
              currentView={currentView}
              setCurrentView={handleOnChangeView}
              setAllTemplateSelectedCategory={setSelectedCategory}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ViewTemplateV2;
