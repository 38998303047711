import { useQuery } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { getChannelList } from "src/services/InnerTicket/getChannelList";
import { fetchTicketsTagsApi } from "src/services/TicketService/fetchTicketsTags";
import { getAllStatuses } from "src/services/TicketService/getAllStatuses";
import { TicketTags } from "src/store/slices/ticketTags/ticketTags.slice";
import { useAppSelector } from "src/store/store";
import {
  SegmentFilter,
  reportSegmentFiltersActions,
  useReportSegmentFilters,
} from "./useSegmentFilters";
import { GlobalFilters } from "./useReportFilters";

function useSegmentFiltersApplied() {
  const { segmentFilters, dispatch } = useReportSegmentFilters();
  const { data: allTicketStatus } = useQuery(
    ["useStatusFilter/getTicketStatusList"],
    {
      queryFn: getAllStatuses,
      staleTime: 600000,
      cacheTime: 600000,
    }
  );

  const { data: allChannels } = useQuery(["useChannelFilter/getChannelList"], {
    queryFn: async () => {
      return getChannelList({ context: "reports" });
    },
    staleTime: 600000,
    cacheTime: 600000,
  });

  const { data: allTags } = useQuery(["useTagFilter/fetchTicketsTagsApi"], {
    queryFn: async () =>
      fetchTicketsTagsApi() as Promise<{
        allTags: TicketTags;
        allTagsIds: number[];
      }>,
    staleTime: 600000,
    cacheTime: 600000,
  });
  const allBrands = useAppSelector(
    (state) => state.globals.brandSignatureData.brands
  );
  const agentTypes = useMemo(() => {
    return [
      { id: "agent", name: "Agent" },
      { id: "lead_agents", name: "Lead Agents" },
      { id: "admin", name: "Admin" },
      { id: "support", name: "Support" },
    ];
  }, [false]);

  const clearAFilter = useCallback(
    (payload: { key: keyof GlobalFilters; index: number }) => {
      dispatch([reportSegmentFiltersActions.clearOneAppliedFilter, payload]);
    },
    []
  );

  const oneFilterApplied = useCallback(
    (eachFilter: SegmentFilter, filterIndex: number) => {
      const fa: Array<{ name: string; value: string; callback: () => void }> =
        [];
      if (eachFilter.filters.ticketStatus?.length) {
        fa.push({
          name: "Ticket Status",
          value:
            allTicketStatus?.allTicketStatusesIds
              .filter((data) =>
                eachFilter.filters.ticketStatus?.includes((data + "") as any)
              )
              .map((data) => allTicketStatus.allTicketStatuses[data].statusName)
              .join(", ") ?? "",
          callback: () => {
            clearAFilter({ key: "ticketStatus", index: filterIndex });
          },
        });
      }
      if (eachFilter.filters.channels?.length) {
        fa.push({
          name: "Channels",
          value: Object.keys(allChannels ?? {})
            .filter((data) =>
              eachFilter.filters.channels?.includes((data + "") as any)
            )
            .map((data) => (allChannels ? allChannels[data].name : ""))
            .join(", "),
          callback: () => {
            clearAFilter({ key: "channels", index: filterIndex });
          },
        });
      }
      if (eachFilter.filters.agentTypes?.length) {
        fa.push({
          name: "Agents",
          value: agentTypes
            .filter((data) =>
              eachFilter.filters.agentTypes?.includes((data.id + "") as any)
            )
            .map((data) => data.name)
            .join(", "),
          callback: () => {
            clearAFilter({ key: "agentTypes", index: filterIndex });
          },
        });
      }
      if (eachFilter.filters.tags?.length) {
        fa.push({
          name: "Tags",
          value:
            allTags?.allTagsIds
              .filter((data) =>
                eachFilter.filters.tags?.includes((data + "") as any)
              )
              .map((data) => allTags.allTags[data].tagName)
              .join(", ") ?? "",
          callback: () => {
            clearAFilter({ key: "tags", index: filterIndex });
          },
        });
      }
      if (eachFilter.filters.brands?.length) {
        fa.push({
          name: "Brands",
          value:
            allBrands
              ?.filter((data) =>
                eachFilter.filters.brands?.includes((data.id + "") as any)
              )
              .map((data) => data.name)
              .join(", ") ?? "",
          callback: () => {
            clearAFilter({ key: "brands", index: filterIndex });
          },
        });
      }
      // Check if chatStatus filter exists in eachFilter
      if (eachFilter.filters.chatStatus) {
        // Push an object representing the Chat Status filter to the 'fa' array
        fa.push({
          name: "Chat Status",
          value: eachFilter.filters.chatStatus,
          // Provide a callback function to clear this specific filter
          callback: () => {
            clearAFilter({ key: "chatStatus", index: filterIndex });
          },
        });
      }
      // Check if isAssigned filter is defined in eachFilter
      if (eachFilter.filters.isAssigned !== undefined) {
        // Push an object representing the Assignment filter to the 'fa' array
        fa.push({
          name: "Assignment",
          value: eachFilter.filters.isAssigned + "",
          // Provide a callback function to clear this specific filter
          callback: () => {
            clearAFilter({ key: "isAssigned", index: filterIndex });
          },
        });
      }
      return { name: eachFilter.name, filters: fa };
    },
    [
      segmentFilters,
      allTicketStatus,
      allBrands,
      allChannels,
      allTags,
      agentTypes,
    ]
  );

  const segmentFiltersApplied = useMemo(() => {
    const allFA: Array<{
      name: string;
      filters: Array<{ name: string; value: string; callback: () => void }>;
    }> = [];
    segmentFilters.allSegmentFilters.forEach((eachFilter, index) => {
      allFA.push(oneFilterApplied(eachFilter, index));
    });
    return allFA;
  }, [
    segmentFilters,
    allTicketStatus,
    allBrands,
    allChannels,
    allTags,
    agentTypes,
    oneFilterApplied,
  ]);

  return { segmentFiltersApplied };
}

export default useSegmentFiltersApplied;
