import antiRefundStrategiesOrdersSlice from "./slices/AntiRefundStrategiesOrdersSlice/antiRefundStrategiesOrders.slice";
import antiRefundStrategiesSlice from "./slices/AntiRefundStrategiesSlice/antiRefundStrategies.slice";
import nonEligibleItemsSlice from "./slices/NonEligibleItemsSlice/nonEligibleItems.slice";
import nonEligibleOrdersSlice from "./slices/NonEligibleOrdersSlice/nonEligibleOrders.slice";
import restockingFeeSlice from "./slices/RestockingFeeSlice/restockingFee.slice";
import returnMethodSlice from "./slices/ReturnMethodSlice/returnMethod.slice";
import returnReasonOrderAndItemsSlice from "./slices/ReturnReasonOrderAndItems/returnReasonOrderAndItems.slice";
import returnTypeBasedOnOrderOrItemsSlice from "./slices/ReturnTypeBasedOnOrderOrItems/returnTypeBasedOnOrderOrItems.slice";

const returnModalSlices = {
  restockingFee: restockingFeeSlice,
  returnReasonOrderAndItems: returnReasonOrderAndItemsSlice,
  antiRefundStrategies: antiRefundStrategiesSlice,
  antiRefundStrategiesOrders: antiRefundStrategiesOrdersSlice,
  returnTypeBasedOnOrderOrItems: returnTypeBasedOnOrderOrItemsSlice,
  nonEligibleItems: nonEligibleItemsSlice,
  nonEligibleOrders: nonEligibleOrdersSlice,
  returnMethod: returnMethodSlice,
};

export default returnModalSlices;

export interface ReturnModalsThunkPayload {
  callback?: () => void;
}
