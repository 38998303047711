import SearchBar from "src/components/SearchBar/SearchBar";
import Generalinfo from "../Generalinfo/Generalinfo";
import LiveCart from "../LiveCart/LiveCart";
import styles from "./LiveChatShoppify.module.scss";
import close from "src/assets/images/close(3).png";
import LiveOrder from "../LiveOrder/LiveOrder";
import { useAppDispatch, useAppSelector } from "src/store/store";
import CustomerAttributeData from "./Children/CustomerAttritbute/CustomerAttributeData";
import { useCallback, useEffect, useRef, useState } from "react";
import React from "react";
import UserInfo from "./Children/UserInfo/UserInfo";
import Shopify from "src/routes/Ticket/children/CustomerSB/children/Shopify/Shopify";
import {
  resetSearchAllCustomers,
  searchAllCustomers,
  setSearchAllCustomersSearchText,
} from "src/store/slices/innerTicket/innerTicket.slice";
import {
  fetchCustomerData,
  resetShopifySidebar,
  setCustomerId,
} from "src/store/slices/shopifySidebar/shopifySidebar.slice";
import InfiniteScroll from "src/components/InfiniteScroll";
import { Spinner } from "react-bootstrap";
import noCustomerFoundImg from "src/assets/images/chat/noCustomerFound.png";

const LiveChatShoppify = ({
  showRSBDetails,
  setShowRSBDetails,
  showLSBDetails,
}: any) => {
  const handleOnClose = useCallback(() => {
    setShowRSBDetails(false);
  }, []);

  const [customerSearch, setCustomerSearch] = useState(""); // This is for searching customer
  const [selectedSearchCustomer, setSelectedSearchCustomer] = useState<
    number | null
  >(null);

  const dispatch = useAppDispatch();

  const {
    searchTerm,
    searchCustomersAjaxStatus,
    customersData,
    customersIdList,
    metaData,
  } = useAppSelector((state) => state.innerTicket.searchCustomersData);

  const { activeCustomerInfo } = useAppSelector((state) => state.chatWidExhMsg);

  const currentState = useRef({
    searchCustomersAjaxStatus,
    customersData,
    customersIdList,
    metaData,
    initialCustomer: true,
    activeCustomerInfo,
  });

  useEffect(() => {
    // if search term is not empty
    if (searchTerm.trim().length > 0) {
      // when search term is changed and not  empty, show search results
      dispatch(searchAllCustomers());
    } else {
      dispatch(resetShopifySidebar());
      // search term is empty
      dispatch(setCustomerId({ id: Number(activeCustomerInfo?.customerId) })); // customer id related to inner ticket is set
      dispatch(fetchCustomerData());
    }
  }, [searchTerm]);

  const handleInfiniteScroll = useCallback(() => {
    if (
      currentState.current.metaData.totalCount !== null &&
      currentState.current.searchCustomersAjaxStatus === "fulfilled"
    ) {
      if (currentState.current.metaData.totalCount > 0) {
        dispatch(searchAllCustomers());
      }
    }
  }, [metaData.totalCount]);

  return (
    <div
      className={`${styles.liveChatShoppifyContainer} ${
        showLSBDetails ? styles.leftSideBarOpened : ""
      } w-100`}
    >
      {/* <span onClick={handleOnClose} className={`${styles.backArrow}`}>
        <i className="fa-solid fa-arrow-right"></i>
      </span> */}
      <div className="d-flex justify-content-between mb-2">
        <div className={`w-85 me-2 ${styles.searchBox}`}>
          <SearchBar
            className={`${styles.search}`}
            inputClassName={`${styles.input}`}
            placeholder={`Search Customer`}
            onChange={(e: any) => {
              // This is for searching customer
              setCustomerSearch(e.target.value);
            }}
            value={customerSearch} // This is for searching customer
            onSearch={(value: any) => {
              // Check if we have any search customer selected
              if (selectedSearchCustomer !== null) {
                // Reset the state if selected
                setSelectedSearchCustomer(null);
              }
              dispatch(resetSearchAllCustomers());
              dispatch(setSearchAllCustomersSearchText({ searchText: value }));
              // handleCustomerSearch(value);
              // // console.log(value);
            }}
          />
        </div>
        <div
          className={`w-15 ${styles.crossBox}`}
          role={"button"}
          onClick={handleOnClose}
        >
          <img
            src={close}
            alt="cross"
          />
        </div>
      </div>
      <div>
        {/* Show search results */}
        {searchTerm.length !== 0 ? (
          <>
            {selectedSearchCustomer === null ? (
              <InfiniteScroll
                className={`p-2 ${styles.contentSB}`}
                loadMore={handleInfiniteScroll}
                loader={
                  searchCustomersAjaxStatus === "pending" &&
                  customersIdList.length === 0 ? (
                    <div className={`${styles.loading} m-auto`}>
                      <Spinner
                        animation="border"
                        variant="primary"
                        size="sm"
                        className={`d-block m-auto mb-2`}
                      />
                      <span className={styles.serachingCustomerText}>
                        Searching Customer...
                      </span>
                    </div>
                  ) : undefined
                }
                initialDataLoaded={true}
                errorMessage={
                  metaData.totalCount === 0 ||
                  searchCustomersAjaxStatus === "rejected" ? (
                    <div className={`${styles.nothingFound} m-auto`}>
                      <img
                        src={noCustomerFoundImg}
                        className={`d-block m-auto mb-2 ${styles.nothingFoundImg}`}
                      />
                      <span className={`${styles.nothingFoundText}`}>
                        No customer found
                      </span>
                    </div>
                  ) : undefined
                }
                hasMore={
                  searchCustomersAjaxStatus === "rejected"
                    ? false
                    : metaData.totalCount === null
                    ? true
                    : customersIdList.length < metaData.totalCount
                }
              >
                {customersIdList.map((id, index) => {
                  return (
                    <UserInfo
                      key={id}
                      customerData={customersData[id]}
                      showCustomer={() => setSelectedSearchCustomer(id)}
                    />
                  );
                })}
              </InfiniteScroll>
            ) : (
              <Generalinfo
                customerId={selectedSearchCustomer + ""}
                isSearchedCustomer={true}
              />
            )}
          </>
        ) : (
          <Generalinfo
            customerId={activeCustomerInfo?.customerId}
            customerName={activeCustomerInfo?.name}
            customerEmail={activeCustomerInfo?.email}
            imageURL={activeCustomerInfo?.imageURL}
            chatChannelToDetail={activeCustomerInfo?.chatChannelToDetail}
            chatChannelType={activeCustomerInfo?.chatChannelType}
            customerMeta={activeCustomerInfo?.customerMeta}
          />
        )}

        {/* <CustomerAttributeData /> */}
        <Shopify />
      </div>
    </div>
  );
};
export default LiveChatShoppify;
