import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllAgentInfo } from "src/services/LiveChat/messageExchange/getAgentInfo";
import {
  GetAllCustomers,
  getAllCustomers,
} from "src/services/LiveChat/messageExchange/getAllCustomers";
import {
  GetAssignToAgentParam,
  getAssignToAgents,
} from "src/services/LiveChat/messageExchange/getAssignToAgents";
import { getCustomerByChatId } from "src/services/LiveChat/messageExchange/getCustomerByChatId";
import { getLiveChatSidebar } from "src/services/LiveChat/messageExchange/getLiveChatSidebar";
import { markLiveChatMessageAsSeen } from "src/services/LiveChat/messageExchange/markMessageAsSeen";
import { RootState, useAppSelector } from "src/store/store";
import { setActiveCustomerInfo } from "../chatWidExchMsg/chatWidExchMsg.slice";
import { LiveChatStatusTypes } from "./chatWidExchCust.declarations";
import {
  convertUserTimeZoneToAnother,
  formatUTCDate,
} from "src/utils/dateLibrary";
import { formatDate } from "src/utils/utils";
import { getLiveChatIntegratedBrands } from "src/services/LiveChat/messageExchange/getLiveChatIntegratedBrands";
import { setSearchTerm } from "../../allOrders/allOrders.slice";
import axios from "axios";
import { setUnreadChatIdsForTitle } from "../../globals/globals.slice";
import { updateSidebarCounts } from "./chatWidExchCust.reducers";
import { ChatCountUpdatedData } from "src/pusherServices/livechat/chatCountUpdatedEvent";

let chatController: { controller: AbortController; key: string }[] = [];

const getChatController = () => chatController;

async function fetchCustomerDataThunk(
  payload: {
    start: number | string;
    initialFetch?: boolean;
  },
  {
    getState,
    rejectWithValue,
    dispatch,
  }: { getState: Function; rejectWithValue: Function; dispatch: Function },
) {
  const controller = new AbortController();

  try {
    const currentState: RootState = getState();
    const searchTerm = currentState.chatWidExchCust.filters.searchValue?.trim();
    const showMentions =
      currentState.chatWidExchCust.filters.showMentions ?? false;
    const customerStatus = searchTerm || showMentions ? "all" : "";
    const selectedAgentForAssignToFilterLength =
      currentState.chatWidExchCust.selectedAgentIdsForFilter.length;
    const agentListLength = currentState.chatWidExchCust.agentMeta.total;
    const selectedBrandListLength =
      currentState.chatWidExchCust.selectedBrandIdsForFilter.length;
    const brandListLength =
      currentState.globals.brandSignatureData.brands?.length;
    const filterStartDate = currentState.chatWidExchCust.filterStartDate;
    const filterEndDate = currentState.chatWidExchCust.filterEndDate;

    if (filterEndDate) {
      filterEndDate.setHours(23, 59, 59, 999); // Set to end of the day (23:59:59.999)
    }

    if (filterStartDate) {
      filterStartDate.setHours(0, 0, 0, 0); // Set to start of the day (00:00:00.000)
    }
    // To Check if all status are selected or not
    const allStatusFilterSelected =
      currentState.chatWidExchCust.statusAllSelected;

    const userTimezone = currentState.globals.currentUserData?.userTimezone;
    const requestPayload: GetAllCustomers = {
      searchTerm,
      status: searchTerm
        ? ["live", "missed", "archived", "queued"]
        : currentState.chatWidExchCust.selectedCustomerStatus === "live"
          ? ["live", "queued"]
          : [currentState.chatWidExchCust.selectedCustomerStatus],
      start: payload.start,
      limit: currentState.chatWidExchCust.fetchCustomerLimit,
      initialFetch:
        payload.initialFetch && payload.initialFetch == true ? true : false,
      filters:
        customerStatus === "all"
          ? undefined
          : selectedAgentForAssignToFilterLength === 0 && brandListLength === 0
            ? undefined
            : {
                // Check if all agent id's are selected in selectedAgentIdsForFilter
                // If yes, then onlyAssignedChats should be true
                onlyAssignedChats: selectedAgentForAssignToFilterLength
                  ? selectedAgentForAssignToFilterLength === agentListLength
                    ? true
                    : false
                  : false,
                assignedAgentsId:
                  selectedAgentForAssignToFilterLength === agentListLength ||
                  selectedAgentForAssignToFilterLength === 0
                    ? undefined
                    : currentState.chatWidExchCust.selectedAgentIdsForFilter,
                brandIds:
                  selectedBrandListLength === brandListLength ||
                  selectedBrandListLength === 0
                    ? undefined
                    : currentState.chatWidExchCust.selectedBrandIdsForFilter,
                chatResolvedStatus:
                  currentState.chatWidExchCust.selectedStatusForFilter.length >
                    0 || allStatusFilterSelected
                    ? currentState.chatWidExchCust.selectedStatusForFilter
                    : undefined,
                startDate: filterStartDate
                  ? formatUTCDate(filterStartDate)
                  : undefined,
                endDate: filterEndDate
                  ? formatUTCDate(filterEndDate)
                  : undefined,
              },
    };

    // Mentions are not applied when search is there
    if (showMentions && searchTerm.trim() === "") {
      if (requestPayload.filters) {
        requestPayload.filters.showMentions = true;
      } else {
        requestPayload.filters = { showMentions: true };
      }
    }
    if (requestPayload.initialFetch) {
      delete requestPayload.filters;
    }

    let payloadKey = `${JSON.stringify(requestPayload ?? {})}`;

    // Compare the new payload with the previous one
    chatController.forEach(({ controller, key }) => {
      if (key !== payloadKey) {
        controller.abort();
      }
    });

    chatController.push({ controller, key: payloadKey });

    const data = await getAllCustomers(requestPayload, controller.signal);

    return data;
  } catch (e) {
    if (axios.isCancel(e)) {
      return rejectWithValue(true);
    } else {
      console.error(e);
      throw e;
    }
  } finally {
    chatController = getChatController().filter(
      ({ controller: c }) => c !== controller,
    );
  }
}

async function fetchCustomerDataByChatIdThunk(
  {
    payload,
    isActive = false,
    chatStatus,
    callback,
  }: {
    payload: number | string;
    isActive?: boolean;
    chatStatus?: LiveChatStatusTypes;
    callback?: any;
  },
  { getState, dispatch }: { getState: Function; dispatch: Function },
) {
  try {
    const { data, metaData } = await getCustomerByChatId({ chatId: payload });

    const currentState: RootState = getState();

    const activeChatId = currentState.chatWidExchCust.selectedCustomerChatId;
    if (activeChatId == payload || isActive) {
      dispatch(setActiveCustomerInfo({ customerInfo: data }));
    }
    // Getting userTimeZone and sending in payload to check if chat will under date filter
    const userTimezone = currentState.globals.currentUserData?.userTimezone;

    // if (metaData?.sidebar?.liveChatIds) {
    //   dispatch(
    //     setUnreadChatIdsForTitle({
    //       chatIds: metaData.sidebar.liveChatIds,
    //     })
    //   );
    // }

    if (callback) {
      callback(data, metaData);
    }

    return { data, metaData, chatStatus, userTimezone };
  } catch (e) {
    console.error(e);
    throw e;
  }
}

async function markMessageAsSeenThunk(
  payload: {
    lastMessageId: number | string;
    chatId: number | string;
  },
  { getState }: { getState: Function },
) {
  try {
    const data = await markLiveChatMessageAsSeen(payload);
    return data;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

async function fetchLiveChatSidebarThunk(
  payload: undefined,
  { dispatch, getState }: { dispatch: Function; getState: Function },
) {
  try {
    const data = await getLiveChatSidebar({ count: true });
    const currentState: RootState = getState();

    const chatUpdatedCountQueue =
      currentState.chatWidExchCust.chatUpdatedCountQueue;
    const sidebar = updateSidebarCounts(
      { ...data },
      chatUpdatedCountQueue,
      currentState.globals.currentUserData?.disabledBrandIds ?? [],
    );
    if (sidebar?.liveChatIds !== undefined && sidebar?.liveChatIds !== null) {
      dispatch(
        setUnreadChatIdsForTitle({
          chatIds: sidebar.liveChatIds,
        }),
      );
    }
    return { ...sidebar, sidebarFromApi: data };
  } catch (e) {
    console.error(e);
    throw e;
  }
}

interface GetAgentInfoByIdsParam {
  ids: Array<number | string>;
  callback?: (values: any) => void;
}

async function fetchAgentsInfoByIdsThunk(
  payload: GetAgentInfoByIdsParam,
  { getState }: { getState: Function },
) {
  try {
    const data = await getAllAgentInfo({ ids: payload.ids });

    setTimeout(() => {
      if (payload.callback) {
        payload.callback(data);
      }
    }, 0);

    return data;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export interface GetAssignToAgentP {
  searchTerm?: string;
  callback?: () => {};
}
async function getAssignToAgentsListThunk(
  { callback, ...payload }: GetAssignToAgentP,
  { getState, dispatch }: { getState: Function; dispatch: Function },
) {
  const currentState: RootState = getState();

  let agentListPayload: GetAssignToAgentParam = {
    // chatId: currentState.chatWidExchCust.selectedCustomerChatId,
    start: currentState.chatWidExchCust.agentIds.length,
    limit: currentState.chatWidExchCust.agentFetchLimit,
  };

  if (payload.searchTerm && payload.searchTerm.trim().length !== 0) {
    agentListPayload.searchTerm = payload.searchTerm;
  }
  const data = await getAssignToAgents(agentListPayload);

  setTimeout(() => {
    if (callback) {
      callback();
    }
  }, 0);

  return data;
}

export const fetchLiveChatIntegratedBrandsThunk = createAsyncThunk(
  "chatWidExchCust/fetchLiveChatIntegratedBrandsThunk",
  async function fetchLiveChatIntegratedBrands(
    payload: { searchTerm?: string; callback?: Function },
    { getState }: { getState: Function },
  ) {
    try {
      const data = await getLiveChatIntegratedBrands({
        start: 0,
        limit: 800,
        searchTerm: payload.searchTerm,
      });

      setTimeout(() => {
        if (payload.callback) {
          payload.callback(data);
        }
      }, 0);

      return data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
);

export default {
  fetchCustomerDataThunk,
  fetchCustomerDataByChatIdThunk,
  markMessageAsSeenThunk,
  fetchLiveChatIntegratedBrandsThunk,
};

export const fetchCustomerData = createAsyncThunk(
  "chatWidExchCust/fetchCustomerData",
  fetchCustomerDataThunk,
);

export const fetchCustomerDataByChatId = createAsyncThunk(
  "chatWidExchCust/fetchCustomerDataByChatId",
  fetchCustomerDataByChatIdThunk,
);

export const markMessageAsSeen = createAsyncThunk(
  "chatWidExchCust/markMessageAsSeen",
  markMessageAsSeenThunk,
);

export const fetchLiveChatSidebar = createAsyncThunk(
  "chatWidExchCust/fetchLiveChatSidebar",
  fetchLiveChatSidebarThunk,
);

export const fetchAgentsInfoByIds = createAsyncThunk(
  "chatWidExchCust/fetchAgentsInfoByIds",
  fetchAgentsInfoByIdsThunk,
);

export const fetchAllAssignToAgentsListForFiltering = createAsyncThunk(
  "chatWidExchMsg/fetchAllAssignToAgentsList",
  getAssignToAgentsListThunk,
);
