/**
 * This file contains the custom hook for handling KB settings.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { useCallback, useEffect, useState } from "react";
import useRating from "../../useRating";
import { useParams } from "react-router-dom";

type Props = {
  allowUsers: boolean;
  setAllowUsers: (value: boolean) => void;
  showCount: boolean;
  setShowCount: (value: boolean) => void;
  selectedRadio: string | undefined;
  setSelectedRadio: (value: string | undefined) => void;
  setEmojiClick: (value: boolean) => void;
  reqApproval: boolean;
  setReqApproval: (value: boolean) => void;
  selectedRatingApproval: string;
  setSelectedRatingApproval: (value: string) => void;
};

/**
 * Custom hook for managing the RatingSetting component in KB settings.
 */
function useRatingSetting({
  setSelectedRadio,
  selectedRadio,
  allowUsers,
  setAllowUsers,
  setShowCount,
  showCount,
  setEmojiClick,
  reqApproval,
  setReqApproval,
  selectedRatingApproval,
  setSelectedRatingApproval,
}: Props) {
  const [dropdown, setDropDown] = useState(true);

  useEffect(() => {
    if (!selectedRadio) {
      setSelectedRadio("flexRadioDefault1"); // Check the first radio button by default
    }
  }, [allowUsers, selectedRadio, setSelectedRadio]);

  // Getting Update Rating and Comments Mutation
  const { updateRatingAndComments } = useRating();

  const { integrationId } = useParams();
  const pageIntegrationId = integrationId
    ? decodeURIComponent(integrationId)
    : "";

  // Handling the change of allow users and updating the mutation
  const handleChangeAllowUsers = useCallback(() => {
    setAllowUsers(!allowUsers);
    setEmojiClick(false);
    updateRatingAndComments.mutate({
      integrationId: pageIntegrationId,
      userAllowedToRate: !allowUsers,
    });
  }, [allowUsers, pageIntegrationId, updateRatingAndComments]);

  // Handling the change of radio button and updating the mutation
  const handleRadioChange1 = useCallback(() => {
    setSelectedRadio("flexRadioDefault1");
    setEmojiClick(false);
    updateRatingAndComments.mutate({
      integrationId: pageIntegrationId,
      feedbackRatingType: "emojis",
    });
  }, [pageIntegrationId, updateRatingAndComments]);

  // Handling the change of radio button and updating the mutation
  const handleRadioChange2 = useCallback(() => {
    setEmojiClick(false);
    setSelectedRadio("flexRadioDefault2");
    updateRatingAndComments.mutate({
      integrationId: pageIntegrationId,
      feedbackRatingType: "thumbsUpAndDown",
    });
  }, [pageIntegrationId, updateRatingAndComments]);

  // Handling the change of show count and updating the mutation
  const handleCountChange = useCallback(() => {
    setShowCount(!showCount);
    setEmojiClick(false);
    updateRatingAndComments.mutate({
      integrationId: pageIntegrationId,
      ratingShowHelpCount: !showCount,
    });
  }, [pageIntegrationId, showCount, updateRatingAndComments]);

  // Handle Radio Change Function - Select Approved/Unapproved Rating
  const handleApprovalRatingChange = useCallback(
    (approvedStatus: "approved" | "unApproved") => {
      setSelectedRatingApproval(approvedStatus);
      updateRatingAndComments.mutate({
        integrationId: pageIntegrationId,
        ratingApprovalType: approvedStatus,
      });
    },
    [pageIntegrationId, updateRatingAndComments]
  );

  // Handle Require Approval Change Function - Enable/Disable Require Approval

  const handleRequireApprovalChange = useCallback(
    (e: any) => {
      setReqApproval(e.target.checked);
      updateRatingAndComments.mutate({
        integrationId: pageIntegrationId,
        ratingApprovalRequired: e.target.checked,
      });
    },
    [pageIntegrationId, updateRatingAndComments]
  );

  return {
    dropdown,
    setDropDown,
    handleChangeAllowUsers,
    handleRadioChange1,
    handleRadioChange2,
    handleCountChange,
    updateRatingAndComments,
    handleApprovalRatingChange,
    handleRequireApprovalChange,
  };
}

export default useRatingSetting;
