import {
  IAutomationData,
  IStepData,
  Question,
  QuestionType,
  QuestionVisibility,
  StepStatus,
} from "src/services/Automation/getAutomationById.service";

export const updateVisibilityAndStatus = (
  automationData: IAutomationData,
  updateRelatedQuestionVisibility = true,
  isMoveToNext = false,
): IAutomationData => {
  let hasEncounteredPendingStep = false; // Flag to check if we encountered a pending step
  let showGoLive: boolean | null = true;

  // Update step and question visibility and status
  automationData.stepIds.forEach((stepId, stepIdx) => {
    const step = automationData.stepsData[stepId];

    // Determine step visibility
    if (!step.isVisible && isMoveToNext) {
      if (stepIdx === 0) {
        step.isVisible = true; // The first step is always visible
        hasEncounteredPendingStep = step.stepStatus === StepStatus.Pending;
      } else if (hasEncounteredPendingStep) {
        step.isVisible = false; // Subsequent steps after the first pending step are hidden
      } else if (step.stepStatus === StepStatus.Pending) {
        step.isVisible = true; // The first pending step is visible
        hasEncounteredPendingStep = true; // Mark that we've encountered the first pending step
      } else {
        step.isVisible = step.stepStatus === StepStatus.Completed; // Completed steps are visible until we encounter a pending step
      }
    }

    let allVisibleQuestionsAnswered = true; // Flag to check if all visible questions are answered
    let hasEncounteredPendingQuestion = false; // Flag to track if a pending question has been encountered

    // Update question visibility
    step.questionKeys.forEach((questionKey, questionIdx) => {
      const question = automationData.questionsData[questionKey];
      // Determine the visibility of the question
      let questionVisibility: QuestionVisibility;

      // Determine question visibility
      if (step.stepStatus === StepStatus.Completed) {
        questionVisibility = shouldQuestionBeRelativeHidden(
          automationData.questionsData,
          question,
        )
          ? QuestionVisibility.RelativeHidden
          : QuestionVisibility.Show;
      } else if (step.stepStatus === StepStatus.Pending) {
        if (!hasEncounteredPendingQuestion && questionIdx === 0) {
          // First question in a pending step
          questionVisibility = shouldQuestionBeRelativeHidden(
            automationData.questionsData,
            question,
          )
            ? QuestionVisibility.RelativeHidden
            : QuestionVisibility.Show;
          hasEncounteredPendingQuestion = !question.isAnswered; // Mark as pending
        } else if (!hasEncounteredPendingQuestion) {
          // Questions up to the first pending question
          questionVisibility = shouldQuestionBeRelativeHidden(
            automationData.questionsData,
            question,
          )
            ? QuestionVisibility.RelativeHidden
            : QuestionVisibility.Show;
          hasEncounteredPendingQuestion = !question.isAnswered; // Mark as pending
        } else {
          // Subsequent questions after the first pending question
          questionVisibility = shouldQuestionBeRelativeHidden(
            automationData.questionsData,
            question,
          )
            ? QuestionVisibility.RelativeHidden
            : QuestionVisibility.Hidden;
        }
      } else {
        // Default visibility for any other scenario
        questionVisibility = QuestionVisibility.Hidden;
      }

      // Check if all visible questions are answered
      if (
        questionVisibility === QuestionVisibility.Show &&
        !question.isAnswered
      ) {
        allVisibleQuestionsAnswered = false;
      }

      // If the question is already shown and this is not a relatively hidden question. Don't hide it
      if (
        question.visibility === QuestionVisibility.Show &&
        questionVisibility !== QuestionVisibility.RelativeHidden
      ) {
        return;
      }

      if (
        updateRelatedQuestionVisibility === false &&
        question.visibility === QuestionVisibility.RelativeHidden
      ) {
        return;
      }

      automationData.questionsData[questionKey] = {
        ...question,
        visibility: questionVisibility,
      };
    });

    // Update step status based on question answers
    if (step.isVisible) {
      step.stepStatus = allVisibleQuestionsAnswered
        ? StepStatus.Completed
        : StepStatus.Pending;
    }

    // Save the updated step back to the data
    automationData.stepsData[stepId] = step;
    if (step.stepStatus === StepStatus.Pending) {
      showGoLive = false;
    }
  });

  if (automationData.showGoLive === null) {
    showGoLive = null;
  }

  return {
    ...automationData,
    showGoLive,
  };
};

/**
 * Helper function to determine if a question should be RelativeHidden based on its related questions.
 */
export const shouldQuestionBeRelativeHidden = (
  questionsData: Record<string, Question>,
  question: Question,
): boolean => {
  if (!question.relatedQuestion) {
    return false; // If no relatedQuestion, it cannot be RelativeHidden
  }

  const relatedQuestion =
    questionsData[question.relatedQuestion.relatedQuestionKey];

  if (!relatedQuestion) {
    return false; // If the related question does not exist, it cannot be RelativeHidden
  }

  switch (relatedQuestion.questionType) {
    // case QuestionType.Text:
    case QuestionType.Radio:
    case QuestionType.List:
      // For Radio and List, check if the single value is one of the allowed options
      return !question.relatedQuestion.allowedOptions.includes(
        relatedQuestion.questionValue,
      );

    // case QuestionType.Checkbox:
    case QuestionType.MultiList:
      // For MultiList, check if any of the selected values are in the allowed options
      const multiListValues = relatedQuestion.questionValue;
      return !multiListValues.some(
        (value) => question.relatedQuestion?.allowedOptions.includes(value),
      );

    default:
      return false;
  }
};

export const findMoveToNextButtonPosition = (
  stepsData: Record<string, IStepData>,
  questionsData: Record<string, Question>,
  isPendingStepRequired = false,
): string | null => {
  // Get the list of step IDs
  const stepIds = Object.keys(stepsData);

  // Traverse steps from bottom to top
  for (let i = stepIds.length - 1; i >= 0; i--) {
    const stepId = stepIds[i];
    const step = stepsData[stepId];

    // Only consider visible steps
    if (!step.isVisible) {
      continue;
    }

    // Traverse questions in the step from bottom to top
    const questionKeys = step.questionKeys;

    for (let j = questionKeys.length - 1; j >= 0; j--) {
      const questionKey = questionKeys[j];
      const question = questionsData[questionKey];

      // Skip the overall last question
      // if (i === stepIds.length - 1 && j === questionKeys.length - 1) {
      //   continue;
      // }

      // Stop at the first question that is shown
      if (question.visibility === QuestionVisibility.Show) {
        // if (isPendingStepRequired && question.isAnswered) {
        //   continue;
        // }
        return questionKey;
      }
    }
  }

  // If no suitable question is found, return null
  return null;
};

export const findMoveToNextButtonPositionAlternate = ({
  stepIds,
  stepsData,
  questionsData,
  currentQuestionKey,
  currentStepId,
}: {
  stepIds: string[];
  stepsData: Record<string, IStepData>;
  questionsData: Record<string, Question>;
  currentQuestionKey: string;
  currentStepId: string;
}): {
  moveToNextQuestionKey: string | null;
  visibleStepId: string | null;
  visibleQuestionId: string | null;
  showGoLive: boolean | null;
  activeStepIndex: number | null;
} => {
  let moveToNextQuestionKey: string | null = null;
  let visibleStepId: string | null = null;
  let visibleQuestionId: string | null = null;
  let showGoLive: boolean | null = null;
  let activeStepIndex: number | null = null;

  const currentStep = stepsData[currentStepId];

  if (!currentStep) {
    return {
      moveToNextQuestionKey,
      visibleStepId,
      visibleQuestionId,
      showGoLive,
      activeStepIndex,
    };
  }

  const index = currentStep.questionKeys.findIndex(
    (val) => val === currentQuestionKey,
  );

  if (index === -1) {
    return {
      moveToNextQuestionKey,
      visibleStepId,
      visibleQuestionId,
      showGoLive,
      activeStepIndex,
    };
  }

  const findNextStep = () => {
    stepIds.forEach((val, idx) => {
      if (val === currentStepId) {
        if (idx + 1 <= stepIds.length - 1) {
          visibleStepId = stepsData[stepIds[idx + 1]].stepId;
          moveToNextQuestionKey = stepsData[visibleStepId].questionKeys[0];
          activeStepIndex = idx + 1;
        } else {
          showGoLive = true;
          activeStepIndex = stepIds.length;
        }
      }
    });
  };

  // Make next step visible that is not currently visible
  if (index + 1 > currentStep.questionKeys.length - 1) {
    findNextStep();
  } else {
    // Make next question visible that is not currently visible
    for (let i = index + 1; i <= currentStep.questionKeys.length - 1; i++) {
      const question = questionsData[currentStep.questionKeys[i]];

      // Start searching through all questions from index + 1
      if (question.visibility === QuestionVisibility.Hidden) {
        // Show the hidden question and stop the loop
        visibleQuestionId = question.questionKey;
        moveToNextQuestionKey = question.questionKey;

        break;
      } else if (question.visibility === QuestionVisibility.RelativeHidden) {
        // If we have a relative hidden question check if it can be shown
        const shouldRemainRelativeHidden = shouldQuestionBeRelativeHidden(
          questionsData,
          question,
        );

        // If it can be shown then show it and exit the loop
        if (!shouldRemainRelativeHidden) {
          visibleQuestionId = question.questionKey;
          moveToNextQuestionKey = question.questionKey;

          break;
        }
      }
    }

    // If we did not find any question in current step to make visible go to next step
    if (visibleQuestionId === null) {
      findNextStep();
    }
  }

  return {
    moveToNextQuestionKey,
    visibleStepId,
    visibleQuestionId,
    showGoLive,
    activeStepIndex,
  };
};
