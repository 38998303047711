import { axiosJSON } from "src/globals/axiosEndPoints";
import APIResponse from "src/services/Settings/Teams/APIReponse";
import { IUpdateTicketChatFormElement } from "./updateTicketChatFormData";


export interface IUpdateTicketChatFormDataParamV3{

  integrationId: number | string;
  // enabled?: boolean;
  formType: "withinBusinessHours"| "outsideBusinessHours",
  elements?: Array<IUpdateTicketChatFormElement> ;
  brandEmails?: {enabled: boolean, emails: string};
  customEmails?: {enabled: boolean, emails: string};
}


export async function updateTicketChatFormDataV3(params: IUpdateTicketChatFormDataParamV3) {
  
  const { data: res } = await axiosJSON.post(
    "/api/chatSetting/forms/updateTicketForm",
    params
  );

  if (res.error === true) {
    throw new Error(res.message as string);
  }

  return res.data;
}
