import styles from "./PreDraftDetails.module.scss";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import PreDraftModal from "src/components/TableComponents/ReponseModals/PreDraftModal/PreDraftModal";
import { getChannelFAIcon } from "src/utils/utils";
import { useAppSelector } from "src/store/store";
import { selectTicketCellData } from "src/store/slices/tickets/tickets.slice";
import { TableCellProps } from "..";

function PreDraftDetails({ ticketId, colKeys }: TableCellProps) {
  const [showModal, setShowModal] = useState(false);
  const ticketCellData: any = useAppSelector((state) =>
    selectTicketCellData(state, ticketId, colKeys)
  );

  const onShow = (e: any) => {
    e.stopPropagation();
    setShowModal(true);
    // console.log("show");
  };
  const onHide = () => {
    setShowModal(false);
    // console.log("hide");
  };

  return (
    <div onClick={(e: any)=> e.stopPropagation()}>
      <div className={`${styles.PreDraftMain}`} onClick={onShow}>
        <div className={`${styles.preDraftBox}`}>
          <p className={`${styles.resAllDraft}`}>
            <span className="me-1">
              <i className="fas fa-user"></i>
            </span>
            <span> sk , 24/1/2022, 11.24pm</span>
          </p>

          <span className={`${styles.messageHead}`}>Order details:</span>
          <br />
          <span className={`${styles.subSpan}`}> order no:1556456</span>
          <br />
          <span className={`${styles.subSpan}`}>Tracking ID: https://..</span>
          <br />
          {/* <span className={`${styles.subSpan}`}>E-mail details</span><br />
              <span className={`${styles.subSpan}`}>settlement:normal</span><br />
              <span className={`${styles.subSpan}`}>Other ticket:2</span> */}
        </div>
      </div>
      <Modal
        // backdropClassName={`${styles.modalBack}`}
        show={showModal}
        onHide={onHide}
        dialogClassName={`${styles.modalDialog}`}
        contentClassName={`${styles.modalContent}`}
        centered={true}
      >
        <PreDraftModal onHide={onHide} />
      </Modal>
    </div>
  );
}

export default PreDraftDetails;
