import { Spinner } from "react-bootstrap";
import { AJAXSTATUS } from "src/globals/constants";
import styles from "./DeleteModal.module.scss";
interface Props{
  handleCancel: (e:any)=>void,
  handleSubmit: (e:any)=>void,
  isLoading: boolean,
  confirmText: string
}
const DeleteModal = ({confirmText, handleCancel, handleSubmit, isLoading}: Props) => {
  return (
    <>
      <div className={`container`}>
        <div className={`row`}>
          <div className={`col-12`}>
            <div className={`px-4 pt-4 pb-3 text-center`}>
              <h3 className={`${styles.modalHead}`}>
              {/* Are you sure you want to delete this brand? */}
              {confirmText}
              </h3>
              {/* <span className={`d-inline-block py-2 ${styles.modalSub}`}>
              You will be able to recreate the brand again, if you want.
              </span> */}
              <div
                className={` pt-4 pb-3 d-flex flex-column flex-md-row justify-content-center align-items-center align-items-md-center`}
              >
                <button
                  className={`bg-transparent  me-0 mb-2 mb-md-0 me-md-5 ${styles.cancelBtn}`}
                  onClick={handleCancel}
                >
                  Cancel
                </button>
                <button className={`border-0 px-4 py-2 ${styles.confirmBtn}`} onClick={handleSubmit}>

                {isLoading ? (
                  <Spinner
                    className="my-auto mx-1"
                    animation="border"
                    color="white"
                    size="sm"
                  />
                ): "Confirm"}
                  
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DeleteModal;
