import { axiosJSON } from "src/globals/axiosEndPoints";

export interface DeleteBrandPayload {
  id: string | number;
}

export async function deleteBrand(payload: DeleteBrandPayload) {
  const { data: res } = await axiosJSON.post("/api/brand/deleteBrand", payload);

  if (res.err) {
    throw new Error(res.msg);
  }
  return res.data;
}
